import { FILTER } from "../admin/model/ScoreConfigModel";
import { SelectorModel } from "../common/model/SelectorModel";
import { IRestService } from "@services/RestService";
import { IMonacoConfig } from "../common/MonacoInterface";
import { HandleError } from '../common/util/HandleError';

export interface IScoreService {
    getFieldData: (filter: FILTER) => Promise<SelectorModel[]>;
}

export class ScoreService {
    static $inject: string[] = ['$injector', '$http'];
    private RestService: IRestService;
    private config: IMonacoConfig;
    private fatturoTokenEndpount: string;
    private tokenMonacao: string;
    private tokenFatturo: string;

    public constructor($injector: ng.Injectable<any>) {
        this.RestService = $injector.get('RestService');
        this.config = $injector.get('config');
        this.fatturoTokenEndpount = "/Users/TokenByToken";
        this.tokenMonacao = $injector.get('SessionService').getToken();
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    getUrlHelper(): string {
        const baseRoute = '/helper';
        const urlProduct = this.config.helperUrl + baseRoute;
        return urlProduct;
    }

    getUrlLoginFatturo(): string {
        const baseRoute = '/identity/api/v1';
        const urlfatturoApi = this.config.fatturoApi + baseRoute;
        return urlfatturoApi;
    }

    getUrlMainFatturo(): string {
        const baseRoute = '/main/api/v1';
        const urlfatturoApi = this.config.fatturoApi + baseRoute;
        return urlfatturoApi;
    }

    public async getFieldData(filter: FILTER): Promise<SelectorModel[]> {
        let data: SelectorModel[] = [];
        try {
            if (!filter) throw Error('filter is null');
            if (filter && filter.MICROSERVICE && filter.MICROSERVICE.ID == '3') {
                const options = {
                    method: 'POST',
                    timeout: 10000,
                    body: JSON.stringify({ token: this.tokenMonacao }),
                    headers: {
                        "Content-Type": "application/json",
                    },
                };
                const result = await fetch(`${this.getUrlLoginFatturo() + this.fatturoTokenEndpount}`, options);
                const dataTokenFatturo = await result.json()
                if (dataTokenFatturo && dataTokenFatturo.access_token) {
                    this.tokenFatturo = dataTokenFatturo.access_token;
                }
            }

            if (filter.HTTP_METHOD && filter.ENDPOINT && filter.HTTP_METHOD.ID === '1') { // GET
                let queryString = null;
                if (filter.FILTER) {
                    const params = JSON.stringify(filter.FILTER);
                    queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
                }

                if (filter && filter.MICROSERVICE && filter.MICROSERVICE.ID == '3') {
                    const options = {
                        method: 'GET',
                        timeout: 10000,
                        headers: {
                            "Authorization": "Bearer " + this.tokenFatturo,
                            "pragma": "no-cache",
                            "cache-control": "no-cache",
                        },
                    };
                    const resultado = await fetch(`${this.getUrlMainFatturo()}${filter.ENDPOINT}${(queryString ? queryString : '')}`, options);
                    const dataFatturo = await resultado.json();
                    if (dataFatturo) {
                        for (var dt of dataFatturo) {
                            const dta = {
                                ID: dt.id,
                                NAME: dt.name,
                                CODE: dt.code
                            }
                            data.push(dta);
                        }
                    }
                } else {
                    const result = await this.RestService.getObjectAsPromise(`${(filter.MICROSERVICE.ID == '1' ? this.getUrlHelper() : this.getUrlProduct())}${filter.ENDPOINT}${(queryString ? queryString : '')}`, 10000, null, false);
                    if (result && result.data) {
                        data = result.data;
                    }
                }
            } else if (filter.HTTP_METHOD && filter.ENDPOINT && filter.HTTP_METHOD.ID === '2') { // POST
                if (filter && filter.MICROSERVICE && filter.MICROSERVICE.ID == '3') {
                    const options = {
                        method: 'POST',
                        timeout: 10000,
                        body: JSON.stringify({ token: this.tokenMonacao }),
                        headers: {
                            "Content-Type": "application/json",                        
                            "Authorization": "Bearer " + this.tokenFatturo,
                        },
                    };
                    const result = await fetch(`${this.getUrlMainFatturo()}${filter.ENDPOINT}`, options);
                    const dataFatturo = await result.json();
                    if (dataFatturo) {
                        data = dataFatturo;
                    }
                } else {
                    const result = await this.RestService.newObjectPromise(`${(filter.MICROSERVICE.ID == '1' ? this.getUrlHelper() : this.getUrlProduct())}${filter.ENDPOINT}`, (filter.FILTER ? JSON.parse(filter.FILTER) : {}), 3000, false);
                    if (result && result.data) {
                        data = result.data;
                    }
                }
            }
        } catch (ex) {
            HandleError.exception(ex);
        } finally {
            return data;
        }
    }
}
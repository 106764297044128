import * as angular from 'angular';
import moment = require("moment");
import { IBootstrapControllerScope } from "../bootstrap/controller/BootstrapController";
import { IMonacoRequest } from "@services/GridFormService";
import { SelectorModel } from "./model/SelectorModel";
import { NotificationMailBox } from "@models/interface/integration/NotificationMailBox";
import { NotificationType } from "@models/interface/integration/NotificationType";
import { ExternalService } from "@services/ExternalService";

export class NotificationBoxItem {
    id: string | object;
    header: string;
    message?: string;
    icon?: string;
    color?: string;
    link?: string;
    filter?: object;
    datetime: string;
}

export class NotificationBox {
    private static instance: NotificationBox;
    private list: NotificationBoxItem[];
    private rawNotifications: NotificationMailBox[];
    private ExternalService: ExternalService;

    public static Instance($injector: ng.Injectable<any>): NotificationBox {
        return this.instance || (this.instance = new NotificationBox($injector));
    }

    private constructor($injector: ng.Injectable<any>) {
        this.ExternalService = $injector.get('ExternalService');
    }

    public async init(scope: IBootstrapControllerScope) {
        this.list = [];
        this.rawNotifications = [];
        await this.getNotificationBoxByUser(scope);
    }

    public async getNotificationBoxByUser(scope: IBootstrapControllerScope) {
        try {
            const externalReq: IMonacoRequest = {
                route: `/notificationMailBox/listNotificationBox`,
                data: { "read": scope.viewReadNotifications },
                timeout: 120000
            }

            const result = await this.ExternalService.post<any>(externalReq);
            if (result && result.data && result.data.data) {
                this.rawNotifications = result.data.data;
                for (const notification of this.rawNotifications) {

                    const id = notification._id;
                    const header = notification.HEADER;
                    const message = notification.DESCRIPTION;
                    const color = (notification.NOTIFICATION_TYPE) ? `text-${notification.NOTIFICATION_TYPE.COLOR}` : "text-black";
                    const icon = (notification.NOTIFICATION_TYPE) ? `fa-${notification.NOTIFICATION_TYPE.ICON}` : "fa-bell";
                    const link = notification.LINK;
                    const filter = JSON.parse(notification.FILTER);
                    const datetime = moment(notification.DATE).format("DD/MM/YYYY HH:mm:ss")

                    const item: NotificationBoxItem = {
                        id,
                        header,
                        message,
                        color,
                        icon,
                        link,
                        filter,
                        datetime
                    };
                    this.list.push(item);
                }

            }
            if(scope.notificationBoxItemList && scope.notificationBoxItemList.length !== this.list.length) {
                const notificationIcon = angular.element("#notificationIcon");
                // Necessary to remake the shake animation on change
                if (notificationIcon) {
                    notificationIcon.hide();
                    setTimeout(() => {
                        notificationIcon.show();
                    });                    
                }
            }
            scope.notificationBoxItemList = this.list;
            scope.rawNotifications = this.rawNotifications;
            scope.$applyAsync();
        } catch (e) {
            console.error(e);
        }
    }

    public async create(header: string, message?: string, icon?: string, color?: string, link?: string, filter?: object,
        notificationType?: NotificationType, user?: SelectorModel, user_cell?: SelectorModel) {
        if (!this.list) return;

        const _filter = JSON.stringify(filter);

        const notificationMailBox: NotificationMailBox = {
            _id: null,
            HEADER: header,
            DESCRIPTION: (message) ? message : null,
            NOTIFICATION_TYPE: (notificationType) ? notificationType : null,
            LINK: (link) ? link : null,
            FILTER: (_filter) ? _filter : null,
            USER: (user) ? user : null,
            USER_CELL: (user_cell) ? user_cell : null,
            READ: false,
            DATE: null,
        }

        const externalReq: IMonacoRequest = {
            route: `/notificationMailBox/insert`,
            data: { data: notificationMailBox },
            timeout: 120000
        }
        const result = await this.ExternalService.post<any>(externalReq);

        let notificationCreated: NotificationMailBox = null;
        if (result && result.data && result.data.data) {
            notificationCreated = result.data.data;
            this.rawNotifications.push(notificationCreated);
        }

        const item: NotificationBoxItem = <NotificationBoxItem>{
            id: notificationCreated._id || null,
            header,
            message: (message) ? message : null,
            color: (color) ? color : "text-black",
            icon: (icon) ? icon : "fa-bell",
            link: (link) ? link : null,
            filter: (filter) ? filter : null,
            datetime: moment().format("DD/MM/YYYY HH:mm:ss")
        };

        this.list.push(item);
    }
}

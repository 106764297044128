import { IModalOptions, IModalService } from '@services/ModalService';
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { ProductService } from "@services/ProductService";
import { OperationalService } from "@services/OperationalService";
import { IVoyage } from "@models/interface/operational/voyage/Voyage";
import { ISelectorModel } from 'WBA-Model/dist/mongo/SelectorModel';
import { ERoutingPointType, EProviderTypeId } from "@enums/GenericData";

interface IVoyageInsertModalScope extends IFormServiceScope {
    modalOptions: IModalOptions;
    model: IVoyage;
    voyageModalId: number;
    shipList: ISelectorModel[];
    routingPointList: ISelectorModel[];
    providerList: ISelectorModel[];

    getShipListByName: (search: string) => Promise<void>;
    getRoutingPointListByName: (search: string) => Promise<void>;
    getProviderListByName: (search: string, withoutDuplicity: boolean) => Promise<void>;
    addCarrier: () => void;
    removeShipOwner: (shipOwnerIndex: number) => void;
    registerVoyage: () => Promise<boolean>;
}

export class VoyageInsertModalController extends FormService2 {
    static $inject: string[] = ['$injector', '$scope'];
    private scope: IVoyageInsertModalScope;
    private $q: ng.IQService;
    private timeout: ng.ITimeoutService
    private ModalService: IModalService;
    private ProductService: ProductService;
    private OperationalService: OperationalService;

    constructor($injector: ng.Injectable<any>, $scope: IVoyageInsertModalScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.ModalService = $injector.get('ModalService');
        this.$q = $injector.get('$q');
        this.timeout = $injector.get('$timeout');
        this.ProductService = $injector.get('ProductService');
        this.OperationalService = $injector.get('OperationalService');
    }

    async $onInit(): Promise<void> {
        try {
            this.block();
            this.init("voyageInsertModalForm", null, null);
            await this.initModel();
            this.initScopeFunctions();
            await this.initDependencies();
            this.loadRegisterForm(false);
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    private initScopeFunctions(): void {
        if (!this.scope.model.CARRIER_INFORMATION) {
            this.scope.model.CARRIER_INFORMATION = [];
            this.scope.model.CARRIER_INFORMATION.push({
                PROVIDER: null,
                VOYAGE_NUMBER: null
            });
        }
       
        this.scope.getShipListByName = async (search: string): Promise<void> => {
            return await this.getShipListByName(search);
        }

        this.scope.getRoutingPointListByName = async (search: string): Promise<void> => {
            return await this.getRoutingPointListByName(search);
        }

        this.scope.getProviderListByName = async (search: string, withoutDuplicity: boolean): Promise<void> => {
            return await this.getProviderListByName(search, withoutDuplicity);
        }

        this.scope.addCarrier = (): void => {
            this.addCarrier();
        }

        this.scope.removeShipOwner = (shipOwnerIndex: number) => {
            this.removeShipOwner(shipOwnerIndex);
        }

        this.scope.registerVoyage = async (): Promise<boolean> => {
            const success = await this.registerVoyage();
            if (success) {
                this.ModalService.closeModal(this.scope.voyageModalId);
                this.scope.voyageModalId = 0;
                this.initModel();
            }
            return true;
        }
    }

    async initDependencies(): Promise<any> {
        try {
            const self: VoyageInsertModalController = this;
            return new Promise(function (resolve, reject) {
                self.$q.all([

                ]).then((result: any) => {

                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async initModel(): Promise<void> {
        this.scope.model = {
            ID: null,
            ACTIVE: true,
            DATA_ORIGIN: null,
            FORECAST_DATE: null,
            PORT_ORIGIN: null,
            CARRIER_INFORMATION: null,
            MARITIME_SERVICE: null,
            VOYAGE_NUMBER: null,
            SHIP: null,
            TEUS: null,
            _id: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null
        };

    }

    private async getShipListByName(search: string): Promise<void> {
        let result: ISelectorModel[] = [];
        try {
            if (search && search.length >= 3) {
                this.block();

                const ships = await this.ProductService.post({ route: '/ship/list/custom', data: { search, provider: [] } });
                if (ships && ships.data && ships.data.data) result = ships.data.data.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.IMO } });

            }
        } catch (ex) {
            this.handleError(ex);
        }
        finally {
            this.scope.shipList = result;
            this.unblock();
        }
    }

    private async getRoutingPointListByName(search: string): Promise<void> {
        let result: ISelectorModel[] = [];
        try {
            if (search && search.length >= 3) {
                this.block();

                const routingPoints = await this.ProductService.post({ route: '/routingPoint/list/custom/', data: { name: search, types: [ERoutingPointType.PORT] } });
                if (routingPoints && routingPoints.data && routingPoints.data.data && routingPoints.data.data.data) result = routingPoints.data.data.data.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, DISPLAY_NAME: x.DISPLAY_NAME } });

            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.scope.routingPointList = result;
            this.unblock();
        }
    }

    private async getProviderListByName(search: string, withoutDuplicity: boolean): Promise<void> {
        let result: ISelectorModel[] = [];
        this.block();
        try {
            const providers = await this.ProductService.post({ route: '/provider/list/custom', data: { search: search, types: [EProviderTypeId.SHIPOWNER] } });
            if (providers && providers.data && providers.data.data) result = providers.data.data.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.SCAC_IATA } });

            if(withoutDuplicity){
                const voyageProvider = this.scope.model.CARRIER_INFORMATION;
                if(voyageProvider.length){
                    voyageProvider.forEach(voyageProvider => {
                        const index = result.findIndex(provider => voyageProvider.PROVIDER && provider.ID == voyageProvider.PROVIDER.ID);
                        if (index > -1) result.splice(index, 1);
                    })
                }
            }

        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.scope.providerList = result;
            this.unblock();
        }
    }

    private addCarrier() {
        try {
            if (!this.scope.model.CARRIER_INFORMATION) this.scope.model.CARRIER_INFORMATION = [];
            this.scope.model.CARRIER_INFORMATION.push({
                PROVIDER: null,
                VOYAGE_NUMBER: null
            });

            this.timeout(() => {
                const index = this.scope.model.CARRIER_INFORMATION.length - 1;
                this.scope.selectorValidity('ship' + index);
                this.scope.selectorValidity('travel' + index);
            });

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private removeShipOwner(shipOwnerIndex: number) {
        try {
            if (this.scope.model.CARRIER_INFORMATION.length > 1) {
                this.scope.model.CARRIER_INFORMATION.splice(shipOwnerIndex, 1);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async registerVoyage(): Promise<boolean> {
        let success = true;
        try {
            const hasInvalid = this.checkPendingFields();
            if (!hasInvalid) {
                success = false;
            }

            if (success) {
                this.block();
                const voyageResponse = await this.OperationalService.post('/voyage/insert', { data: this.scope.model });
                if (voyageResponse && voyageResponse.data && voyageResponse.data.data) {
                    const voyage: IVoyage = voyageResponse.data.data;
                    this.scope.modalOptions.ok(voyage);
                }
                const msg = this.getTranslate('GENERAL.REGISTRATION_SUCCESSFULLY_REGISTERED');
                this.notifySuccess(msg);
            }

        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return success
        }
    }
}

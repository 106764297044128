import angular = require('angular');
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { FormService2, IFormServiceScope } from '@services/FormService2';
import { IModalService } from '@services/ModalService';
import { IRestService } from '@services/RestService';
import { OperationalService } from '@services/OperationalService';
import { ProductService } from '@services/ProductService';
import { IMonacoRequest } from '@services/GridFormService';
import { ISessionService } from '@services/SessionService';
import { IFinopService } from '@services/FinopService';
import { IViewLog } from "@models/interface/common/IViewLog";
import { Invoice } from '@models/interface/finop/Invoice';
import { ASSIGNEE, MINIMUM } from '@models/interface/finop/NewProfitShare';
import { APPLICATION } from '@models/interface/finop/NewCharge';
import { IApplicationList } from '@models/interface/product/ApplicationModel';
import { IEquipmentList } from '@models/interface/product/Offer';
import { INewProcessProfitShareComposition, INewProcessManagementModel, IManagementCancelRequestResult } from '@models/interface/operational/NewProcessTabsModel';
import { ISelectorModel, SelectorModel } from '@models/mongo/SelectorModel';
import { CHARGE, PROFIT_SHARE, ENTITY } from '@models/interface/operational/NewProcess';
import { EPaymentNatureId, ETransactionId, EForwardedById, EApplicationComplementId, EOperation, EProcessSituation, EInvoiceStatus } from '@enums/GenericData';
import { INewProcessScope, ECollapseState } from './NewProcessController';
import { ILegalPersonListCustomFilter } from '../../product/model/LegalPersonModel';
import { PermissionService } from '../../app/services/PermissionService';
import { IInvoiceParameter } from '../../common/model/ModelParameter';
import { HelperService } from "@services/HelperService";
import { EForwardingSituation } from "@enums/GenericData";

interface INewProcessProfitShareModalScope extends IFormServiceScope {
    profitShare: PROFIT_SHARE;
    oldProfitShare: PROFIT_SHARE;
    compositionCharges: INewProcessProfitShareComposition[];
    oldCompositionCharges: INewProcessProfitShareComposition[];
    forwardedByList: ISelectorModel[];
    transactionList: ISelectorModel[];
    holderList: ASSIGNEE[];
    applicationList: IApplicationList[];
    equipmentList: IEquipmentList[];
    feetList: ISelectorModel[];
    applyProfitShareModal: () => Promise<void>;
    closeProfitShareModal: () => Promise<void>;
    recalculate: () => Promise<void>;
    addCommissionedMinimum: (commissionedIndex: number) => void;
    removeCommissionedMinimum: (commissionedIndex: number, minimumIndex: number) => Promise<void>;
    forwardedByFilter: (forwardedBy: ISelectorModel) => boolean;
    transactionFilter: (transaction: ISelectorModel) => boolean;
    equipFilter: (commissionedIndex: number) => Function;
    minimumFeetFilter: (commissionedIndex: number) => Function;
    isTransactionOther: (transaction: ISelectorModel) => boolean;
    isApplicationEquipment: (application: APPLICATION) => boolean;
    changeTransaction: (commissionedIndex: number) => Promise<void>;
    changeApplication: (commissionedIndex: number) => void;
    changeEquipType: (commissionedIndex: number, minimumIndex: number) => void;
    changeFeet: (commissionedIndex: number, minimumIndex: number) => void;
    updateRuleCompanyPercent: () => void;
    updateAmount: () => Promise<void>;
    getApplicationListByName: (search: string) => Promise<void>;
    getHolderListByName: (search: string) => Promise<void>;
}

interface IInvoicePaymentConditions {
    AGENT: string;
    INCOME: string;
    OUTCOME: string;
}

interface ICancellationAuditProcessReasonModal {
    reason: ISelectorModel;
}

interface INewProcessManagementScope extends ng.IScope {
    log: IViewLog;
    model: INewProcessManagementModel;
    oldModel: INewProcessManagementModel;
    processNumber: string;
    invoicePaymentConditions: IInvoicePaymentConditions;
    paymentInvoices: Invoice[];
    receiptInvoices: Invoice[];
    agentInvoices: Invoice[];
    financialSummary: any; // TODO create interface
    forwardingSituationList: ISelectorModel[];
    currencyList: ISelectorModel[];
    cancellationReasonList: ISelectorModel[];
    cancellationAuditProcessReasonModal: ICancellationAuditProcessReasonModal;
    sectorList: ISelectorModel[];
    reasonList: ISelectorModel[];
    internalResponsibleList: ISelectorModel[];
    processPermissionDisabled: boolean;
    resultAnalysisPermissionDisabled: boolean;
    isMandatoryFieldResultsAnalysis(): boolean;
    isCollapseIn(): boolean;
    collapseManagement: () => void;
    hasChangesInClosureProcess: () => boolean;
    refreshProcessFinancialStatus: () => void;
    goToInvoice: (invoiceNumber: string) => void;
    changeCheckValues: () => Promise<void>;
    changeBillingRelease: () => Promise<void>;
    cancellationAuditProcess: () => Promise<void>;
    openProfitShareDetails: () => void;
    getCurrencyListByName: (search: string) => Promise<void>;
    getSectorListByName: (search: string) => Promise<void>;
    disablePanelDueToPreProcessStatus: () => boolean;
    disablePanelDueToForwardingSituationStatus: () => boolean;
    addResultsAnalysis: () => void;
    removeResultsAnalysis: (index: number) => void;
}

export class NewProcessManagementController {
    static $inject: string[] = ['$scope', '$injector'];
    private $scope: INewProcessManagementScope;
    private $newProcessScope: INewProcessScope;
    private $timeout: ng.ITimeoutService;
    private $q: ng.IQService;
    private $filter: ng.FilterFactory;
    private $sce: angular.ISCEService;
    private $compile: angular.ICompileService;
    private formService: FormService2;
    private modalService: IModalService
    private restService: IRestService;
    private sessionService: ISessionService;
    private permissionService: PermissionService;
    private operationalService: OperationalService;
    private finopService: IFinopService;
    private productService: ProductService;
    private modalProfitShareId: number;
    private modalCancellationAuditProcessReasonId: number;
    private helperService: HelperService;

    constructor($scope: INewProcessManagementScope, $injector: ng.Injectable<any>) {
        this.$scope = $scope;
        this.$newProcessScope = <INewProcessScope>$scope.$parent.$parent;
        this.$timeout = this.$newProcessScope.$timeout;
        this.$q = this.$newProcessScope.$q;
        this.$filter = this.$newProcessScope.$filter;
        this.$sce = this.$newProcessScope.$sce;
        this.$compile = this.$newProcessScope.$compile;
        this.formService = this.$newProcessScope.formService;
        this.modalService = this.$newProcessScope.modalService;
        this.restService = this.$newProcessScope.restService;
        this.sessionService = this.$newProcessScope.sessionService;
        this.productService = this.$newProcessScope.productService;
        this.operationalService = this.$newProcessScope.operationalService;
        this.finopService = this.$newProcessScope.finopService;
        this.permissionService = new PermissionService($scope, $injector);
        this.helperService = $injector.get('HelperService');

        this.initScopeFunctions();
    }

    async $onInit(): Promise<void> {
        try {
            this.initModel();
            await this.initDependencies();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initModel(): void {
        this.$scope.model = {
            FORWARDING_SITUATION: null,
            PAYMENT_SITUATION: null,
            RECEIPT_SITUATION: null,
            AGENT_SETTLEMENT_SITUATION: null,
            AUDIT: null,
            RESULTS_ANALYSIS: null,

        };
        this.$scope.oldModel = angular.copy(this.$scope.model);
    }

    private initAuditModel(): void {
        if (this.$scope.model.AUDIT == null) {
            this.$scope.model.AUDIT = {
                CHECK_VALUE: {
                    AUDIT_USER: null,
                    STATUS: false,
                    DATE: null,
                },
                BILLING_RELEASE: {
                    AUDIT_USER: null,
                    STATUS: false,
                    DATE: null,
                },
                CANCELLATION: {
                    CANCEL_USER: null,
                    STATUS: false,
                    REASON: null,
                    DATE: null,
                }
            }
        }
    }

    private initScopeFunctions(): void {

        this.$scope.isCollapseIn = (): boolean => {
            return this.$newProcessScope.collapseState.panel == ECollapseState.MANAGEMENT && !this.$newProcessScope.collapseState.released;
        }

        this.$scope.collapseManagement = (): void => {
            this.collapseManagement();
        }

        this.$scope.hasChangesInClosureProcess = (): boolean => {
            return this.$newProcessScope.hasChanges(this.$scope.model ? this.$scope.model.FORWARDING_SITUATION : null, this.$scope.oldModel ? this.$scope.oldModel.FORWARDING_SITUATION : null);
        }

        this.$scope.refreshProcessFinancialStatus = async () => {
            return this.refreshProcessFinancialStatus();
        }
        this.$scope.goToInvoice = (invoiceNumber: string) => {
            this.sessionService.openTab('app.finop.invoice.register', <IInvoiceParameter>{ INVOICE_NUMBER: invoiceNumber });
        }

        this.$scope.changeCheckValues = async () => {
            const text = this.$scope.model.AUDIT.CHECK_VALUE.STATUS ? 'OPERATIONAL.DO_YOU_WANT_TO_RESET_THIS_FILE_AS_VALUES_CHECKED' : 'OPERATIONAL.DO_YOU_WANT_TO_SET_THIS_FILE_AS_VALUES_CHECKED';
            const confirm = await this.modalService.showModalConfirmation({}, {
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate(text, { prop: this.$scope.processNumber }),
                actionButtonText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.NO'
            });
            if (!confirm) {
                this.$scope.model.AUDIT.CHECK_VALUE.STATUS = !this.$scope.model.AUDIT.CHECK_VALUE.STATUS;
                return
            }

            try {
                this.formService.block();

                this.initAuditModel();

                const timeout = 10000;
                const request = {
                    status: this.$scope.model.AUDIT.CHECK_VALUE.STATUS,
                    auditField: 'CHECK_VALUE',
                    timeout: timeout,
                    processNumber: this.$scope.processNumber,
                };

                const rc = await this.operationalService.post("/management/audit/update", request, 30000);
                if (rc.status === 200) {
                    if (rc.data.data != null) {
                        this.$scope.model.AUDIT.CHECK_VALUE.AUDIT_USER = rc.data.data.AUDIT_USER;
                        this.$scope.model.AUDIT.CHECK_VALUE.DATE = rc.data.data.DATE;

                        this.$newProcessScope.model.FORWARDING_SITUATION.SITUATION.ID = "8";
                        this.$newProcessScope.model.FORWARDING_SITUATION.SITUATION.NAME = this.formService.getTranslate('OPERATIONAL.VALUE_CHECK');
                        this.$newProcessScope.model.FORWARDING_SITUATION.DATE = rc.data.data.DATE;
                    }

                    this.$scope.$applyAsync();

                    const msg = this.formService.getTranslate('OPERATIONAL.VALUE_CHECK_STATUS_SUCESSFULLY_UPDATED');
                    this.formService.notifySuccess(msg);
                } else {
                    const msg = this.formService.getTranslate('OPERATIONAL.FAILED_TO_UPDATE_VALUE_CHECK');
                    this.formService.notifyError(msg);
                }

                this.$scope.$applyAsync();

                this.formService.unblock();
            } catch (ex) {
                this.$scope.model.AUDIT.CHECK_VALUE.STATUS = !this.$scope.model.AUDIT.CHECK_VALUE.STATUS;
                this.formService.handleError(ex);
            }
        }

        this.$scope.changeBillingRelease = async () => {
            const text = this.$scope.model.AUDIT.BILLING_RELEASE.STATUS ? 'OPERATIONAL.DO_YOU_WANT_TO_RESET_THIS_FILE_AS_BILLING_ALLOWED' : 'OPERATIONAL.DO_YOU_WANT_TO_SET_THIS_FILE_AS_BILLING_ALLOWED';
            const confirm = await this.modalService.showModalConfirmation({}, {
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate(text, { prop: this.$scope.processNumber }),
                actionButtonText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.NO'
            });
            if (!confirm) {
                this.$scope.model.AUDIT.BILLING_RELEASE.STATUS = !this.$scope.model.AUDIT.BILLING_RELEASE.STATUS;
                return;
            }

            try {
                this.formService.block();

                this.initAuditModel();

                const timeout = 10000;

                const request = {
                    status: this.$scope.model.AUDIT.BILLING_RELEASE.STATUS,
                    auditField: 'BILLING_RELEASE',
                    timeout: timeout,
                    processNumber: this.$scope.processNumber,
                };

                const rc = await this.operationalService.post("/management/audit/update", request, 30000);
                if (rc.status === 200) {
                    if (rc.data.data != null) {
                        this.$scope.model.AUDIT.BILLING_RELEASE.AUDIT_USER = rc.data.data.AUDIT_USER;
                        this.$scope.model.AUDIT.BILLING_RELEASE.DATE = rc.data.data.DATE;

                        this.$newProcessScope.model.FORWARDING_SITUATION.SITUATION.ID = "3";
                        const billingAllowed = this.formService.getTranslate('OPERATIONAL.BILLING_ALLOWED');
                        this.$newProcessScope.model.FORWARDING_SITUATION.SITUATION.NAME = billingAllowed;
                        this.$newProcessScope.model.FORWARDING_SITUATION.DATE = rc.data.data.DATE;

                        // updateChildOperation('saveClosureSituation'); TODO Hammes I don't know what is going on.
                    }

                    this.$scope.$applyAsync();

                    const msg = this.formService.getTranslate('OPERATIONAL.BILLING_ALLOWED_STATUS_SUCESSFULLY_UPDATED');
                    this.formService.notifySuccess(msg);
                } else {
                    const msg = this.formService.getTranslate('OPERATIONAL.FAILED_TO_UPDATE_THE_BILLING_ALLOWED_STATUS');
                    this.formService.notifyError(msg);
                }

                this.$scope.$applyAsync();

                this.formService.unblock();
            } catch (ex) {
                this.formService.handleError(ex);
            }
        }

        this.$scope.openProfitShareDetails = () => {
            this.openProfitShareDetails();
        }

        this.$scope.getCurrencyListByName = async (search: string): Promise<void> => {
            let currencyList: ISelectorModel[] = [];
            if (search && search.length >= 2) currencyList = await this.getCurrencyListByName(search);
            this.$scope.currencyList = currencyList;
        }

        this.$scope.cancellationAuditProcess = async () => {
            this.cancellationAuditProcess();
        }

        this.$scope.getSectorListByName = async (search: string): Promise<void> => {
            let sectorList: ISelectorModel[] = [];
            if (search && search.length >= 2) sectorList = await this.getSectorListByName(search);
            this.$scope.sectorList = sectorList;
        }

        this.$scope.isMandatoryFieldResultsAnalysis = (): boolean => {
            let result = [];
            const resultAnalysis = this.$scope.model.RESULTS_ANALYSIS;
            if (resultAnalysis) {
                resultAnalysis.forEach(item => {
                    if (item.ACTIVE) {

                        if (item.REASON && typeof item.REASON !== "undefined") {
                            result.push(true);
                        } else {
                            result.push(false);
                        }

                        if (item.SECTOR && typeof item.SECTOR !== "undefined") {
                            result.push(true);
                        } else {
                            result.push(false);
                        }

                        if (item.REMARKS && item.REMARKS.length > 0 && typeof item.REMARKS !== "undefined") {
                            result.push(true);
                        } else {
                            result.push(false);
                        }

                        if (item.VALUE && typeof item.VALUE !== "undefined") {
                            result.push(true);
                        } else {
                            result.push(false);
                        }

                        if (item.INTERNAL_RESPONSIBLE && typeof item.INTERNAL_RESPONSIBLE !== "undefined") {
                            result.push(true);
                        } else {
                            result.push(false);
                        }

                        if (item.USER) {
                            result.push(true);
                        }
                    }
                });
            }

            return result.some(item => item == true);
        }

        this.$scope.disablePanelDueToPreProcessStatus = (): boolean => {
            const situation = this.$newProcessScope && this.$newProcessScope.model && this.$newProcessScope.model.SITUATION && this.$newProcessScope.model.SITUATION.ID;
            let result = false;

            switch (situation) {
                case EProcessSituation.PRE_PROCESS:
                    break;
                default:
                    result = true;
            }
            return result;
        }

        this.$scope.disablePanelDueToForwardingSituationStatus = (): boolean => {
            const situation = this.$scope && this.$scope.model && this.$scope.model.FORWARDING_SITUATION && this.$scope.model.FORWARDING_SITUATION.SITUATION ? this.$scope.model.FORWARDING_SITUATION.SITUATION.ID : null;
            let result = false;

            switch (situation) {
                case EForwardingSituation.CANCELLED:
                case EForwardingSituation.FINISHED:
                    result = true;
                    break;
                default:
                    result = false;
            }
            return result;
        }

        this.$scope.addResultsAnalysis = (): void => {
            this.addResultsAnalysis();
        }

        this.$scope.removeResultsAnalysis = (index: number): void => {
            this.removeResultsAnalysis(index);
        }
    }

    async initDependencies(): Promise<any> {
        const self: NewProcessManagementController = this;

        this.initCollapseEvents();

        this.$scope.$on('processManagementSave', () => {
            this.saveClosureSituation();
        });

        this.$scope.$watch(() => this.$newProcessScope.model.PROCESS_NUMBER, () => {
            this.$scope.processNumber = this.$newProcessScope.model.PROCESS_NUMBER;
        });

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericList('forwarding_situation'),
                self.getGenericList('cancellation_reason'),
                self.getGenericList("process_result_analysis_reasons"),
                self.getAllUsers()
            ]).then(async (result: any) => {
                self.$scope.forwardingSituationList = result[0] && result[0].length ? result[0].map(forwardingSituation => { return { ID: forwardingSituation.ID, NAME: forwardingSituation.NAME, optional: ["CODE"] } }) : [];
                self.$scope.cancellationReasonList = result[1];
                self.$scope.reasonList = result[2];
                self.$scope.internalResponsibleList = result[3] ? result[3].map(item => { return { ID: item._id, NAME: item.displayName, CODE: item.ID } }) : [];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private initCollapseEvents() {
        this.$scope.$on('processManagementCollapse', () => {
            this.collapseManagement();
        });

        const addOnExpandEvent = (selector: string, listener: (event: JQuery.Event) => void) => {
            const elm = angular.element(selector);
            if (!elm[0]) return;
            elm.on('shown.bs.collapse', listener);
        };

        const addOnCollapseEvent = (selector: string, listener: (event: JQuery.Event) => void) => {
            const elm = angular.element(selector);
            if (!elm[0]) return;
            elm.on('hidden.bs.collapse', listener);
        };

        addOnCollapseEvent('#collapseManagement', async (event: JQuery.Event) => {
            if (event.target != event.currentTarget) return;
            angular.element("#collapseManagementHeading").attr('aria-expanded', 'false');
        });

        addOnExpandEvent('#collapseManagement', async (event: JQuery.Event) => {
            if (event.target != event.currentTarget) return;
            await this.getProcessTabsManagement();
            await this.buildInvoiceControl();
            angular.element("#collapseManagementHeading").attr('aria-expanded', 'true');
            // update collapse state
            this.$newProcessScope.collapseState = { panel: ECollapseState.MANAGEMENT, released: false, nextState: null };
            this.$newProcessScope.repositionPanels(ECollapseState.MANAGEMENT, true);
            this.$newProcessScope.disableElements(this.$newProcessScope.operation == EOperation.VIEW);

            this.$timeout(() => {

                addOnExpandEvent('#collapseAudit', (_event: JQuery.Event) => {
                    this.$timeout(() => {
                        this.navigateBetweenIds("collapseAudit");
                    });
                });

                addOnExpandEvent('#collapseClosingProcess', async (_event: JQuery.Event) => {
                    const result = await this.permissionService.isRoleAllowed("PROCESSCLOSURESITUATION");
                    if (result == false) {
                        this.$scope.processPermissionDisabled = true;
                    }
                    this.$timeout(() => {
                        this.navigateBetweenIds("collapseClosingProcess");
                    });
                });

                addOnExpandEvent('#collapseFinancialSummary', async (_event: JQuery.Event) => {
                    try {
                        const result = await this.permissionService.isRoleAllowed("FINANCIALSUMMARYEDIT");
                        if (result == false) {
                            this.$scope.resultAnalysisPermissionDisabled = true;
                        }
                        this.formService.block();
                        const summary = await this.getFinancialSummary(this.$scope.processNumber);
                        this.$scope.financialSummary = summary;
                        this.navigateBetweenIds("collapseFinancialSummary");
                    } catch (ex) {
                        this.formService.handleError(ex);
                    } finally {
                        this.formService.unblock();
                    }
                });
            });
        });
    }

    private async saveClosureSituation(): Promise<boolean> {
        let success = true;
        try {
            if (await this.permissionService.isRoleAllowed("PROCESSCLOSURESITUATION") === false && await this.permissionService.isRoleAllowed("FINANCIALSUMMARYEDIT") === false) {
                this.permissionService.showBlockMessage();
                this.formService.unblock();
                return;
            }

            const lessThanDezCharactersInRemark = this.lessThanDezCharactersInRemark();
            const isMandatoryFieldResultsAnalysis = this.$scope.isMandatoryFieldResultsAnalysis();
            if (isMandatoryFieldResultsAnalysis || lessThanDezCharactersInRemark) {
                const validResultsAnalysis = this.$newProcessScope.hasInvalidRequiredElements('collapseAnalysis');
                if (validResultsAnalysis || lessThanDezCharactersInRemark) return;
            }

            const timeout = 30000;
            const request = {
                processNumber: this.$newProcessScope.model.PROCESS_NUMBER,
                data: {
                    FORWARDING_SITUATION: this.$scope.model.FORWARDING_SITUATION,
                    PAYMENT_SITUATION: this.$scope.model.PAYMENT_SITUATION,
                    RECEIPT_SITUATION: this.$scope.model.RECEIPT_SITUATION,
                    AGENT_SETTLEMENT_SITUATION: this.$scope.model.AGENT_SETTLEMENT_SITUATION,
                    RESULTS_ANALYSIS: this.$scope.model.RESULTS_ANALYSIS,
                },
            };

            const result = await this.operationalService.post("/management/closure/situation", request, timeout);
            if (result.status === 200 && result.data && result.data.data) {
                await this.getProcessTabsManagement();
                this.$scope.oldModel = angular.copy(this.$scope.model);
                const summary = await this.getFinancialSummary(this.$scope.processNumber);
                this.$scope.financialSummary = summary;
                const msg = this.formService.getTranslate('OPERATIONAL.FORWARDING_STATUS_SUCESSFULLY_UPDATED');
                this.formService.notifySuccess(msg);
            } else {
                success = false;
                const msg = this.formService.getTranslate('OPERATIONAL.FAILED_TO_UPDATE_THE_FORWARDING_STATUS');
                this.formService.notifyError(msg);
            }

            this.formService.unblock();
        } catch (ex) {
            success = false;
            this.formService.handleError(ex);
        }
        return success;
    }

    private addResultsAnalysis(): void {
        try {
            if (!this.$scope.model.RESULTS_ANALYSIS) this.$scope.model.RESULTS_ANALYSIS = [];

            const resultAnalysis = {
                REASON: null,
                SECTOR: null,
                REMARKS: null,
                USER: this.$scope.$parent['user']['displayName'],
                DATE: new Date(),
                ACTIVE: true,
                VALUE: null,
                INTERNAL_RESPONSIBLE: null
            };
            this.$scope.model.RESULTS_ANALYSIS.push(resultAnalysis);

        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeResultsAnalysis(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');

            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.$scope.model.RESULTS_ANALYSIS && this.$scope.model.RESULTS_ANALYSIS.length > 0) {
                this.formService.block();
                if (this.$scope.model.RESULTS_ANALYSIS.length == 1) this.$scope.model.RESULTS_ANALYSIS = null;
                else this.$scope.model.RESULTS_ANALYSIS.splice(index, 1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getProcessInvoices() {
        const result = await this.operationalService.get(`/process/getInvoices/process/${this.$scope.processNumber}`, null, 30000);

        return result && result.data;
    }

    private async cancellationAuditProcess() {
        const processSituation = this.$newProcessScope.model.SITUATION ? this.$newProcessScope.model.SITUATION.ID : null;
        try {
            if (await this.permissionService.isRoleAllowed("PROCESSCANCEL") === false) {
                this.$scope.model.AUDIT.CANCELLATION.STATUS = false;
                this.permissionService.showBlockMessage();
                this.$scope.$applyAsync();
                return;
            }

            const cancellationReasonInformed = await this.getCancellationAuditProcessReason();
            if (!cancellationReasonInformed) this.$scope.model.AUDIT.CANCELLATION.STATUS = false;
            else {
                this.initAuditModel();

                if (this.$scope.model.AUDIT && this.$scope.model.AUDIT.CANCELLATION && this.$scope.model.AUDIT.CANCELLATION.STATUS) {
                    // Select reason
                    if (!this.$scope.cancellationAuditProcessReasonModal || (this.$scope.cancellationAuditProcessReasonModal && !this.$scope.cancellationAuditProcessReasonModal.reason)) {
                        this.$scope.model.AUDIT.CANCELLATION.STATUS = false;
                        this.formService.notifyError(`${this.formService.getTranslate('OPERATIONAL.SELECT_A_REASON_TO_CANCEL_THIS_FILE')} ${this.$scope.processNumber}.`);
                        this.$scope.$applyAsync();
                        return;
                    }

                    // Process situation Loaded/Discharged
                    if ((processSituation === "3") || (processSituation === "4")) {
                        this.$scope.model.AUDIT.CANCELLATION.STATUS = false;
                        const msg = this.formService.getTranslate('OPERATIONAL.IT_IS_NOT_POSSIBLE_TO_CANCEL_A_LOADED_OR_DISCHARGED_FILE');
                        this.formService.notifyError(msg);
                        this.$scope.$applyAsync();
                        return;
                    }
                    // Dialog confirm
                    const checked = await this.modalService.showModalConfirmation({}, {
                        actionButtonText: 'GENERAL.CONFIRM',
                        headerText: 'GENERAL.CONFIRM_ACTION',
                        bodyText: `${this.formService.getTranslate('OPERATIONAL.DO_YOU_WANT_TO_CANCEL_THIS_FILE', { prop: this.$scope.processNumber })}<br/><br/>
                                    ${this.formService.getTranslate('OPERATIONAL.THE_FOLLOWING_ACTIONS_WILL_BE_PERFORMED')}<br/><br/>
                                    <div class="alert alert-danger"><ul>				
                                    <li>${this.formService.getTranslate('OPERATIONAL.THE_OPERATIONAL_STATUS_WILL_BE_CHANGED_TO_CANCELED')}</li>
                                    <li>${this.formService.getTranslate('OPERATIONAL.NON_CONCLUDED_TASKS_WILL_BE_DELETED')}<br/></li>
                                    <li>${this.formService.getTranslate('OPERATIONAL.RECEIPT_SITUATION_WILL_BE_CHANGED_TO_CANCELED')}<br/></li>
                                    <li>${this.formService.getTranslate('OPERATIONAL.PAYMENT_SITUATION_WILL_BE_CHANGED_TO_CANCELED')}<br/></li>
                                    <li>${this.formService.getTranslate('OPERATIONAL.AGENT_SETTLEMENT_STATUS_WILL_BE_CHANGED_TO_CANCELED')}<br/></li>
                                    <li>${this.formService.getTranslate('OPERATIONAL.ALL_THE_CHARGES_WILL_BE_CHANGED_TO_NO_BILLING')}<br/></li>
                                    <li>${this.formService.getTranslate('OPERATIONAL.ALL_THE_INVOICES_WILL_BE_DELETED')}<br/></li>
                                    <li>${this.formService.getTranslate('OPERATIONAL.THE_FINANCIAL_SUMMARY_WILL_NOT_SHOW_RESULTS')}<br/></li>
                                    </ul></div>
                                    <b>${this.formService.getTranslate('OPERATIONAL.THIS_OPERATION_CANNOT_BE_REVERSED')}</b><br/>
                                    `
                    });
                    if (checked) {
                        this.formService.block();

                        const timeout = 1800000;

                        const request = {
                            processNumber: this.$scope.processNumber,
                            reason: this.$scope.cancellationAuditProcessReasonModal ? this.$scope.cancellationAuditProcessReasonModal.reason : null,
                            timeout: timeout
                        };

                        const rc = await this.operationalService.post("/management/audit/cancel", request, timeout);
                        const msgError = this.formService.getTranslate('OPERATIONAL.FAILED_TO_CANCEL_THIS_FILE', { prop: this.$scope.processNumber });
                        if (rc.status === 200 && rc.data && rc.data.data) {
                            const cancelResultData: IManagementCancelRequestResult = rc.data.data;
                            if (cancelResultData && cancelResultData.FORWARDING_SITUATION && cancelResultData.FORWARDING_SITUATION.DATE) cancelResultData.FORWARDING_SITUATION.DATE = new Date(cancelResultData.FORWARDING_SITUATION.DATE);
                            this.$newProcessScope.model.SITUATION = cancelResultData.SITUATION;
                            this.$scope.model.AGENT_SETTLEMENT_SITUATION = cancelResultData.AGENT_SETTLEMENT_SITUATION;
                            this.$scope.model.AUDIT = cancelResultData.AUDIT;
                            this.$scope.model.FORWARDING_SITUATION = cancelResultData.FORWARDING_SITUATION;
                            this.$scope.model.PAYMENT_SITUATION = cancelResultData.PAYMENT_SITUATION;
                            this.$scope.model.RECEIPT_SITUATION = cancelResultData.RECEIPT_SITUATION;
                            this.$scope.oldModel = angular.copy(this.$scope.model);
                            this.formService.unblock();
                            const msgSucess = this.formService.getTranslate('OPERATIONAL.FILE_SUCESSFULLY_CANCELED', { prop: this.$scope.processNumber });
                            this.formService.notifySuccess(msgSucess);
                            this.$newProcessScope.viewProcess(this.$newProcessScope.model);
                        } else this.formService.notifyError(msgError);
                    }
                    else {
                        this.$scope.model.AUDIT.CANCELLATION.STATUS = false;
                        this.$scope.model.AUDIT.CANCELLATION.REASON = null;
                    }
                }
                else {
                    this.$scope.model.AUDIT.CANCELLATION.STATUS = true;
                    const msg = this.formService.getTranslate('OPERATIONAL.IT_IS_NOT_POSSIBLE_TO_REVERSE_THE_CANCELLATION_OF_A_FILE');
                    this.formService.notifyError(msg);
                }
            }
            this.$scope.$applyAsync();
        } catch (ex) {
            this.$scope.model.AUDIT.CANCELLATION.STATUS = false;
            this.$scope.model.AUDIT.CANCELLATION.REASON = null;
            this.$scope.$applyAsync();
            this.formService.handleError(ex);
        }
    }

    private async getCancellationAuditProcessReason(): Promise<boolean> {
        let proceed = false;
        this.$scope.cancellationAuditProcessReasonModal = { reason: null };

        var listIntegratedInvoices = '';
        try {
            if (this.$newProcessScope.model.SITUATION.ID != EProcessSituation.PRE_PROCESS) {
                const rc = await this.getProcessInvoices();
                if (rc.data !== "") {
                    for (const item of rc.data) {
                        if (item.STATUS && item.STATUS.ID != EInvoiceStatus.ONGOING) {
                            if (listIntegratedInvoices == '') {
                                listIntegratedInvoices = item.INVOICE_NUMBER;
                            } else {
                                listIntegratedInvoices += `, ${item.INVOICE_NUMBER}`;
                            }
                        }
                    }
                }
            }

            let body = `
            <div class="row">
                <div class="col-lg-12">
                    <ui-select name="cancellationReason" id="cancellationReason"
                        ng-model="cancellationAuditProcessReasonModal.reason"
                        theme="bootstrap"
                        ng-change="selectorValidity(this.$select.ngModel.$name);"
                        ng-disabled="selectorDisabled(this.$select.ngModel.$name) || operation == 'view'"
                        ng-click="selectorFocus(this.$select.searchInput[0]);"
                        skip-focusser="true" required>
                        <ui-select-match placeholder="{{'GENERAL.UI_SELECT.SELECT' | translate }}">
                            {{$select.selected.NAME}}
                        </ui-select-match>
                        <ui-select-choices
                            repeat="item in cancellationReasonList | filter: $select.search track by $index">
                            <div
                                ng-bind-html="item.NAME | highlight: $select.search">
                            </div>
                        </ui-select-choices>
                        <ui-select-no-choice>
                            {{'GENERAL.UI_SELECT.EMPTY_SELECTOR_MESSAGE' | translate }}
                        </ui-select-no-choice>
                    </ui-select>
                </div>
            </div>
            `;

            if (listIntegratedInvoices != '') {
                body +=
                    `<div class="row">
                    <div class="col-lg-12">
                        <div class="modal-body modal-overflow ng-binding ng-scope"> 
                            <i tooltip-placement="auto top" tooltip-append-to-body="true" class="fa icon fa-exclamation-triangle text-warning"></i>
                            ${this.formService.getTranslate('GENERAL.WARNING_INVOICES_LOCKED')}  <b>${listIntegratedInvoices}</b> <br>
                            ${this.formService.getTranslate('GENERAL.CHECK_INVOICES_FINANCIAL_TEAM')} <!-- ngIf: isSankhya --> 
                        </div>
                    </div>
                </div>`
            }

            const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                {
                    modalID: this.modalCancellationAuditProcessReasonId,
                    scope: this.$scope,
                    formService: 'register',
                    size: 'md',
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing" && closed) {
                            if (!this.$scope.cancellationAuditProcessReasonModal || (this.$scope.cancellationAuditProcessReasonModal && !this.$scope.cancellationAuditProcessReasonModal.reason)) {
                                event.preventDefault();
                                const msg = this.formService.getTranslate('BASIC_DATA.IT_IS_NECESSARY_TO_INFORM_THE_REASON_TO_CONTINUE');
                                this.formService.notifyError(msg);
                            }
                        }
                    }
                },
                {
                    closeButtonText: 'GENERAL.CANCEL',
                    actionButtonText: 'REGISTRATION.APPLY',
                    headerText: 'OPERATIONAL.CANCELATION_REASON',
                    bodyText: this.$sce.trustAsHtml(body),

                }
            );

            modalInstance.rendered.then(() => {
                const cancellationReasonSelect = angular.element("#cancellationReason");
                if (cancellationReasonSelect) this.$compile(cancellationReasonSelect)(this.$scope);
            });

            proceed = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
        return proceed;
    }

    private async getFinancialSummary(processNumber: string): Promise<any> {
        return new Promise((resolve, reject) => {
            const timeout = 30000;
            this.operationalService.get(`/management/summary/${processNumber}`, null, timeout).then((result) => {
                resolve(result.data ? result.data.data : {});
            }, (e) => {
                reject(e);
            });
        });
    }

    private async getCurrencyListByName(search?: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            const currencies = await this.productService.post({ route: "/currency/list/custom", data: { name: search } });
            if (currencies && currencies.data && currencies.data.data) result = currencies.data.data.map(currency => { return { ID: currency.ID, NAME: currency.NAME, CODE: currency.INITIALS } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getApplicationListByName(search: string): Promise<IApplicationList[]> {
        let result: IApplicationList[] = [];
        try {
            if (!this.$newProcessScope.model.PRODUCT) throw Error('Product is null.');
            this.formService.block();
            const products = [this.$newProcessScope.model.PRODUCT.ID];
            const typeCargos = this.$newProcessScope.model.CARGO_TYPE ? [this.$newProcessScope.model.CARGO_TYPE.ID] : [];
            const application = await this.productService.post({ route: "/application/list/custom", data: { search, products, typeCargos, percent: false } });
            if (application && application.data && application.data.data) result = application.data.data.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PERCENT: x.PERCENT, APPLICATION_COMPLEMENT: x.APPLICATION_COMPLEMENT, INTERNAL_SEQUENCE: x.INTERNAL_SEQUENCE, FREE_TYPING_AMOUNT_CHARGE: x.FREE_TYPING_AMOUNT_CHARGE, CT_WITHOUT_DOC: x.CT_WITHOUT_DOC } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private getPeopleByTransaction(processNumber: string, transactionid: string): Promise<any> {
        return this.operationalService.get(`/charge/transaction/${processNumber}/${transactionid}`, null, 30000);
    }

    private async getPeople(legalPersonFilter: ILegalPersonListCustomFilter): Promise<ASSIGNEE[]> {
        let result: ASSIGNEE[] = [];
        try {
            const transform = (returnedData: ENTITY[]): ASSIGNEE[] => returnedData.map(x => ({ ID: x.ID, NAME: x.NAME, CODE: x.CODE, ID_LEGAL_PERSON: x.ID_LEGAL_PERSON.toString(), ID_PHYSICAL_PERSON: null, NETWORK: x.NETWORK, CORPORATE_NAME: x.TRADING_NAME, TAXPAYER_NUMBER: (x.ADDRESS && x.ADDRESS.COUNTRY && x.ADDRESS.COUNTRY.ID == '1') ? x.TAXPAYER_NUMBER : null }));

            this.formService.block();
            const request: IMonacoRequest = {
                data: legalPersonFilter,
                route: `/legalPerson/list/custom/operational`,
                timeout: 10000,
            };
            const response = await this.productService.post(request);

            this.formService.unblock();
            result = (response && response.data && response.data.data) ? transform(response.data.data) : [];

            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    };

    /* private async getPhysicalPersonListByName(physicalPersonFilter?: IPhysicalPersonListCustomFilter): Promise<ISelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const physicalPersons = await this.productService.post({ route: "/physicalPerson/list/custom", data: physicalPersonFilter });
            if (physicalPersons && physicalPersons.data && physicalPersons.data.data) result = physicalPersons.data.data.map(physicalPerson => { return { ID: physicalPerson.ID, NAME: physicalPerson.NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    } */

    private async buildInvoiceControl() {
        try {
            this.$scope.paymentInvoices = null;
            this.$scope.receiptInvoices = null;
            this.$scope.agentInvoices = null;
            const processInvoices: Invoice[] = await this.getProcessInvoice(this.$scope.processNumber);
            if (processInvoices && processInvoices.length) {
                const agentInvoices = processInvoices.filter(invoice => invoice.TRANSACTION && invoice.TRANSACTION.some(x => (x.ID == ETransactionId.LOCAL_AGENT || x.ID == ETransactionId.EXTERNAL_AGENT || x.ID == ETransactionId.OTHER_AGENT)));
                const paymentInvoices = processInvoices.filter(invoice => invoice.NATURE.ID == EPaymentNatureId.PAYMENT && !agentInvoices.some(agntInv => agntInv.INVOICE_NUMBER == invoice.INVOICE_NUMBER));
                const receivingInvoices = processInvoices.filter(invoice => invoice.NATURE.ID == EPaymentNatureId.RECEIVING && !agentInvoices.some(agntInv => agntInv.INVOICE_NUMBER == invoice.INVOICE_NUMBER));

                this.$scope.invoicePaymentConditions = {
                    AGENT: agentInvoices.filter(inv => (inv.COMPOSITION)).map(inv => inv.COMPOSITION.PAYMENT_CONDITION.NAME).filter((v, i, a) => a.indexOf(v) === i).join(', '),
                    INCOME: paymentInvoices.filter(inv => (inv.COMPOSITION)).map(inv => inv.COMPOSITION.PAYMENT_CONDITION.NAME).filter((v, i, a) => a.indexOf(v) === i).join(', '),
                    OUTCOME: receivingInvoices.filter(inv => (inv.COMPOSITION)).map(inv => inv.COMPOSITION.PAYMENT_CONDITION.NAME).filter((v, i, a) => a.indexOf(v) === i).join(', '),
                }
                this.$scope.paymentInvoices = paymentInvoices.map(inv => {
                    // formatting
                    if (inv['INVOICE_CURRENCIES_VALUES']) {
                        inv['INVOICE_CURRENCIES_VALUES'] = String(inv['INVOICE_CURRENCIES_VALUES'])
                            .split('; ')
                            .map(val => {
                                const sep = val.split(' ');
                                sep[1] = this.$filter('number')(sep[1], 2);
                                return sep.join(' ');
                            })
                            .join('; ');
                    }
                    if (inv['INVOICE_CURRENCIES_FACTORS']) {
                        inv['INVOICE_CURRENCIES_FACTORS'] = String(inv['INVOICE_CURRENCIES_FACTORS'])
                            .split('; ')
                            .map(val => {
                                const sep = val.split(' ');
                                sep[1] = this.$filter('number')(sep[1], 6);
                                return sep.join(' ');
                            })
                            .join('; ');
                    }
                    return inv;
                });
                this.$scope.receiptInvoices = receivingInvoices.map(inv => {
                    // formatting
                    if (inv['INVOICE_CURRENCIES_VALUES']) {
                        inv['INVOICE_CURRENCIES_VALUES'] = String(inv['INVOICE_CURRENCIES_VALUES'])
                            .split('; ')
                            .map(val => {
                                const sep = val.split(' ');
                                sep[1] = this.$filter('number')(sep[1], 2);
                                return sep.join(' ');
                            })
                            .join('; ');
                    }
                    if (inv['INVOICE_CURRENCIES_FACTORS']) {
                        inv['INVOICE_CURRENCIES_FACTORS'] = String(inv['INVOICE_CURRENCIES_FACTORS'])
                            .split('; ')
                            .map(val => {
                                const sep = val.split(' ');
                                sep[1] = this.$filter('number')(sep[1], 6);
                                return sep.join(' ');
                            })
                            .join('; ');
                    }
                    return inv;
                });
                this.$scope.agentInvoices = agentInvoices.map(inv => {
                    // formatting
                    if (inv['INVOICE_CURRENCIES_VALUES']) {
                        inv['INVOICE_CURRENCIES_VALUES'] = String(inv['INVOICE_CURRENCIES_VALUES'])
                            .split('; ')
                            .map(val => {
                                const sep = val.split(' ');
                                sep[1] = this.$filter('number')(sep[1], 2);
                                return sep.join(' ');
                            })
                            .join('; ');
                    }
                    if (inv['INVOICE_CURRENCIES_FACTORS']) {
                        inv['INVOICE_CURRENCIES_FACTORS'] = String(inv['INVOICE_CURRENCIES_FACTORS'])
                            .split('; ')
                            .map(val => {
                                const sep = val.split(' ');
                                sep[1] = this.$filter('number')(sep[1], 6);
                                return sep.join(' ');
                            })
                            .join('; ');
                    }
                    return inv;
                });

            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async collapseManagement() {
        try {
            if (this.$newProcessScope.collapseState.released || this.$newProcessScope.collapseState.panel == ECollapseState.MANAGEMENT) {
                const collapseManagement = angular.element("#collapseManagement");
                if (collapseManagement) {
                    const isCollapsed = angular.element("#collapseManagementHeading").attr("aria-expanded") == "true";
                    if (isCollapsed) {
                        if (this.$newProcessScope.hasChanges(this.$scope.model, this.$scope.oldModel)) {
                            const close = this.formService.getTranslate('GENERAL.CLOSE');
                            const management = this.formService.getTranslate('GENERAL.MANAGEMENT');
                            const confirm = await this.$newProcessScope.modalSaveConfirmation(management, close);
                            if (confirm && !await this.saveClosureSituation()) return;
                        }
                        this.$newProcessScope.collapseState.released = true;
                    }
                    collapseManagement['collapse']('toggle');
                    if (isCollapsed) this.$newProcessScope.repositionPanels(ECollapseState.MANAGEMENT);
                }
            } else {
                this.$newProcessScope.collapseState.nextState = ECollapseState.MANAGEMENT;
                this.$newProcessScope.releaseCollapse();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getProcessInvoice(processNumber): Promise<Invoice[]> {
        try {
            let invoices: Invoice[] = [];
            const getInvoiceResponse = await this.finopService.get({ route: `/invoice/process/${processNumber}/false` });
            if (getInvoiceResponse && getInvoiceResponse.data) invoices = getInvoiceResponse.data.data;
            return invoices;
        } catch (ex) {
            this.formService.handleError(ex);
        }

    }

    private async getProcessTabsManagement(): Promise<void> {
        this.formService.block();
        try {
            const managementTab = await this.operationalService.get(`/process/panel/management/${this.$scope.processNumber}`, null, 30000);
            if (managementTab && managementTab.data && managementTab.data.data) {
                this.$scope.model = managementTab.data.data;
                if (this.$scope.model.FORWARDING_SITUATION && this.$scope.model.FORWARDING_SITUATION.DATE) this.$scope.model.FORWARDING_SITUATION.DATE = new Date(this.$scope.model.FORWARDING_SITUATION.DATE);
                if (this.$scope.model.RESULTS_ANALYSIS) {
                    this.$scope.model.RESULTS_ANALYSIS.forEach(result => {
                        if (result.DATE) {
                            result.DATE = new Date(result.DATE);
                        }
                    });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.oldModel = angular.copy(this.$scope.model);
            if (this.$newProcessScope.operation == 'edit') {
                this.$timeout(() => {
                    this.formService.loadEditForm();
                }, 100);
            }
            this.formService.unblock();
        }
    }

    private async openProfitShareDetails(): Promise<void> {
        this.modalProfitShareId = this.modalService.newModal();
        const profitShare: PROFIT_SHARE = await this.getProfitShare();
        const msg = this.formService.getTranslate('OPERATIONAL.THIS_FILE_DOES_NOT_HAVE_PROFIT_SHARE');
        if (!profitShare) return this.formService.notifyInfo(msg);
        const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
            {
                modalID: this.modalProfitShareId,
                scope: this.$scope,
                formService: this.$newProcessScope.operation,
                template: require("../view/NewProcessManagementProfitShareModal.html"),
                size: 'full modal-overflow',
                keyboard: false
            },
            null
        );
        modalInstance.rendered.then(() => {
            const addOnExpandEvent = (selector: string, listener: (event: JQuery.Event) => void) => {
                const elm = angular.element(selector);
                if (!elm[0]) return;
                elm.on('shown.bs.collapse', listener);
            };

            addOnExpandEvent('#collapseComposition', async (_event: JQuery.Event) => {
                await this.initProfitShareCompositionPanel();
                this.$timeout(() => {
                    this.modalNavigateBetweenIds("collapseComposition");
                });
            });
        });
        this.buildProfitShareModalScope(profitShare);
    }

    private async buildProfitShareModalScope(profitShare: PROFIT_SHARE): Promise<void> {
        try {
            const self: NewProcessManagementController = this;
            const modalScope: INewProcessProfitShareModalScope = await this.modalService.getModalScope(this.modalProfitShareId);
            modalScope.profitShare = profitShare;
            modalScope.oldProfitShare = angular.copy(modalScope.profitShare);
            const situation = this.$newProcessScope && this.$newProcessScope.model && this.$newProcessScope.model.FORWARDING_SITUATION && this.$newProcessScope.model.FORWARDING_SITUATION.SITUATION ? this.$newProcessScope.model.FORWARDING_SITUATION.SITUATION.ID : null;
            modalScope.disableElements(this.$newProcessScope.operation == EOperation.VIEW || situation == "7" || situation == "5");

            // get generics
            new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getGenericList("forwarded_by"),
                    self.getGenericList("transaction"),
                    self.getGenericList("equipment_size"),
                    self.getEquipmentList()
                ]).then(async (result: any) => {
                    modalScope.forwardedByList = result[0];
                    modalScope.transactionList = result[1];
                    modalScope.feetList = result[2];
                    modalScope.equipmentList = result[3];
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });

            modalScope.applyProfitShareModal = async () => {
                if (!modalScope.compositionCharges) await this.initProfitShareCompositionPanel();
                if (await this.saveProcessTabManagementProfitShare(modalScope.profitShare, modalScope.compositionCharges)) {
                    this.modalService.closeModal(this.modalProfitShareId);
                    this.modalProfitShareId = 0;
                }
            }

            modalScope.closeProfitShareModal = async (): Promise<void> => {
                if (this.$newProcessScope.hasChanges(JSON.stringify(modalScope.profitShare), JSON.stringify(modalScope.oldProfitShare)) || this.$newProcessScope.hasChanges(JSON.stringify(modalScope.compositionCharges), JSON.stringify(modalScope.oldCompositionCharges))) {
                    const close = this.formService.getTranslate('GENERAL.CLOSE');
                    const confirm = await this.$newProcessScope.modalSaveConfirmation(close, close);
                    if (confirm && !await this.saveProcessTabManagementProfitShare(modalScope.profitShare, modalScope.compositionCharges)) return;
                }
                this.modalService.closeModal(this.modalProfitShareId);
                this.modalProfitShareId = 0;
            }

            modalScope.recalculate = async (): Promise<void> => {
                modalScope.profitShare = await this.recalculateProfitShare(modalScope.profitShare, modalScope.compositionCharges);
            }

            modalScope.addCommissionedMinimum = (commissionedIndex: number): void => {
                try {
                    if (!commissionedIndex && commissionedIndex != 0) throw Error('commissionedIndex is null');
                    if (modalScope.profitShare.COMMISSIONED && modalScope.profitShare.COMMISSIONED.length && modalScope.profitShare.COMMISSIONED[commissionedIndex]) {
                        if (!modalScope.profitShare.COMMISSIONED[commissionedIndex].MINIMUM) modalScope.profitShare.COMMISSIONED[commissionedIndex].MINIMUM = [];
                        const minimum: MINIMUM = {
                            EQUIPMENT: null,
                            FEET: null,
                            UNITARY: null,
                            AMOUNT: null,
                            TOTAL: null
                        }
                        modalScope.profitShare.COMMISSIONED[commissionedIndex].MINIMUM.push(minimum);
                    }
                } catch (ex) {
                    this.formService.handleError(ex);
                }
            }

            modalScope.removeCommissionedMinimum = async (commissionedIndex: number, minimumIndex: number): Promise<void> => {
                try {
                    if (!commissionedIndex && commissionedIndex != 0) throw Error('commissionedIndex is null');
                    if (!minimumIndex && minimumIndex != 0) throw Error('minimumIndex is null');
                    const modal = await this.modalService.showModalConfirmation({}, {
                        actionButtonText: 'GENERAL.CONFIRM',
                        headerText: 'GENERAL.CONFIRM_ACTION',
                        closeButtonText: 'GENERAL.CLOSE',
                        bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
                    });
                    if (!modal) return;

                    if (modalScope.profitShare.COMMISSIONED && modalScope.profitShare.COMMISSIONED.length && modalScope.profitShare.COMMISSIONED[commissionedIndex] &&
                        modalScope.profitShare.COMMISSIONED[commissionedIndex].MINIMUM && modalScope.profitShare.COMMISSIONED[commissionedIndex].MINIMUM.length && modalScope.profitShare.COMMISSIONED[commissionedIndex].MINIMUM[minimumIndex]) {
                        this.formService.block();
                        modalScope.profitShare.COMMISSIONED[commissionedIndex].MINIMUM.splice(minimumIndex, 1);
                    }
                } catch (ex) {
                    this.formService.handleError(ex);
                } finally {
                    this.formService.unblock();
                }
            }

            modalScope.forwardedByFilter = (forwardedBy: ISelectorModel): boolean => {
                return forwardedBy && forwardedBy.ID != EForwardedById.COMPANY;
            }

            modalScope.transactionFilter = (transaction: ISelectorModel): boolean => {
                return transaction && (transaction.ID == ETransactionId.LOCAL_AGENT || transaction.ID == ETransactionId.EXTERNAL_AGENT || transaction.ID == ETransactionId.OTHER_AGENT || transaction.ID == ETransactionId.OTHER);
            }

            modalScope.equipFilter = (commissionedIndex: number): Function => {
                if (!commissionedIndex && commissionedIndex != 0) throw Error("commissionedIndex is null!");
                const commissioned = modalScope.profitShare.COMMISSIONED[commissionedIndex];
                return function (commissionedEquip: ISelectorModel) {
                    return commissioned && (!commissioned.MINIMUM || (commissioned.MINIMUM && (!commissioned.MINIMUM.some(commissionedMinimum => commissionedMinimum.EQUIPMENT && commissionedMinimum.EQUIPMENT.some(equipment => equipment.ID == commissionedEquip.ID)))));
                }
            }

            modalScope.minimumFeetFilter = (commissionedIndex: number): Function => {
                if (!commissionedIndex && commissionedIndex != 0) throw Error("commissionedIndex is null!");
                const commissioned = modalScope.profitShare.COMMISSIONED && modalScope.profitShare.COMMISSIONED.length ? modalScope.profitShare.COMMISSIONED[commissionedIndex] : null;
                return function (commissionedFeet: ISelectorModel) {
                    return commissioned && (!commissioned.MINIMUM || (commissioned.MINIMUM && (!commissioned.MINIMUM.some(commissionedMinimum => commissionedMinimum.FEET && commissionedMinimum.FEET.some(feet => feet.ID == commissionedFeet.ID)))));
                }
            }

            modalScope.isTransactionOther = (transaction: ISelectorModel): boolean => {
                return transaction && transaction.ID == ETransactionId.OTHER;
            }

            modalScope.isApplicationEquipment = (application: APPLICATION): boolean => {
                return application && application.APPLICATION_COMPLEMENT && application.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.EQUIPMENT;
            }

            modalScope.changeTransaction = async (commissionedIndex: number) => {
                if (!commissionedIndex && commissionedIndex != 0) return this.formService.notifyError("commissionedIndex is null.");
                if (modalScope.profitShare.COMMISSIONED && modalScope.profitShare.COMMISSIONED.length && modalScope.profitShare.COMMISSIONED[commissionedIndex].ASSIGNEE_TYPE && modalScope.profitShare.COMMISSIONED[commissionedIndex].ASSIGNEE_TYPE.ID != ETransactionId.OTHER) {
                    const result = await this.getPeopleByTransaction(this.$scope.processNumber, modalScope.profitShare.COMMISSIONED[commissionedIndex].ASSIGNEE_TYPE.ID);
                    modalScope.profitShare.COMMISSIONED[commissionedIndex].ASSIGNEE = result && result.data ? result.data.data : null;
                    this.$timeout(() => {
                        modalScope.selectorValidity('profitShareHolder' + commissionedIndex);
                    }, 100);
                }
            }

            modalScope.changeApplication = (commissionedIndex: number): void => {
                try {
                    if (!commissionedIndex && commissionedIndex != 0) throw Error('commissionedIndex is null');

                    const commissioned = modalScope.profitShare.COMMISSIONED && modalScope.profitShare.COMMISSIONED.length ? modalScope.profitShare.COMMISSIONED[commissionedIndex] : null;
                    if (commissioned) {
                        commissioned.MINIMUM = [];
                        if (commissioned.MINIMUM_APPLICATION && commissioned.MINIMUM_APPLICATION.APPLICATION_COMPLEMENT) {
                            commissioned.MINIMUM.push(
                                {
                                    EQUIPMENT: null,
                                    FEET: null,
                                    UNITARY: null,
                                    AMOUNT: null,
                                    TOTAL: null
                                }
                            );
                        }
                    }
                } catch (ex) {
                    this.formService.handleError(ex);
                }
            }

            modalScope.changeEquipType = (commissionedIndex: number, minimumIndex: number): void => {
                try {
                    if (!commissionedIndex && commissionedIndex != 0) throw Error('commissionedIndex is null');
                    if (!minimumIndex && minimumIndex != 0) throw Error('minimumIndex is null');

                    const commissioned = modalScope.profitShare.COMMISSIONED && modalScope.profitShare.COMMISSIONED.length ? modalScope.profitShare.COMMISSIONED[commissionedIndex] : null;
                    if (commissioned.MINIMUM && commissioned.MINIMUM[minimumIndex] && commissioned.MINIMUM[minimumIndex].EQUIPMENT && commissioned.MINIMUM[minimumIndex].EQUIPMENT.length) {
                        commissioned.MINIMUM[minimumIndex].FEET = null;
                    }
                } catch (ex) {
                    this.formService.handleError(ex);
                }
            }

            modalScope.changeFeet = (commissionedIndex: number, minimumIndex: number): void => {
                try {
                    if (!commissionedIndex && commissionedIndex != 0) throw Error('commissionedIndex is null');
                    if (!minimumIndex && minimumIndex != 0) throw Error('minimumIndex is null');

                    const minimum = modalScope.profitShare.COMMISSIONED[commissionedIndex].MINIMUM ? modalScope.profitShare.COMMISSIONED[commissionedIndex].MINIMUM[minimumIndex] : null;
                    if (minimum && minimum.FEET && minimum.FEET.length) minimum.EQUIPMENT = null;
                } catch (ex) {
                    this.formService.handleError(ex);
                }
            }

            modalScope.updateRuleCompanyPercent = (): void => {
                let comissionedTotalPercent = 0;
                if (modalScope.profitShare.COMMISSIONED) for (const comissioned of modalScope.profitShare.COMMISSIONED) { comissionedTotalPercent += comissioned.PERCENTUAL; }
                if (modalScope.profitShare.COMPANY_COMMISSION) modalScope.profitShare.COMPANY_COMMISSION.PERCENTUAL = (100 - comissionedTotalPercent) < 0 ? 0 : (100 - comissionedTotalPercent);
            }

            modalScope.updateAmount = async (): Promise<void> => {
                modalScope.profitShare = await this.recalculateProfitShare(modalScope.profitShare, modalScope.compositionCharges);
            }

            modalScope.getApplicationListByName = async (search: string): Promise<void> => {
                let applicationList: IApplicationList[] = [];
                if (search && search.length >= 2) {
                    applicationList = await this.getApplicationListByName(search);
                }
                modalScope.applicationList = applicationList;
            }

            modalScope.getHolderListByName = async (search: string) => {
                let holderList: ASSIGNEE[] = [];
                if (search && search.length >= 3) {
                    const legalPersonList = await this.getPeople({ specializations: [], search: search })
                    if (legalPersonList && legalPersonList.length) holderList = holderList.concat(legalPersonList);
                }
                modalScope.holderList = holderList;
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async initProfitShareCompositionPanel() {
        try {
            this.formService.block();
            const modalScope: INewProcessProfitShareModalScope = await this.modalService.getModalScope(this.modalProfitShareId);
            modalScope.compositionCharges = await this.buildProfitShareComposition(modalScope.profitShare);
            modalScope.oldCompositionCharges = angular.copy(modalScope.compositionCharges);
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getEquipmentList(): Promise<ISelectorModel[]> {
        let equipmentList: ISelectorModel[] = [];
        try {
            const processInfoResponse = await this.requestProcessInfo(this.$scope.processNumber);
            if (processInfoResponse && processInfoResponse.data && processInfoResponse.data.data && processInfoResponse.data.data.CARGO_DETAIL && processInfoResponse.data.data.CARGO_DETAIL.EQUIPMENT_LIST)
                equipmentList = processInfoResponse.data.data.CARGO_DETAIL.EQUIPMENT_LIST.filter(equipmentList => equipmentList.EQUIPMENT).map(equipmentList => { return { ID: equipmentList.EQUIPMENT.ID, NAME: equipmentList.EQUIPMENT.NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return equipmentList;
        }
    }

    private async recalculateProfitShare(profitShare: PROFIT_SHARE, compositionCharges: INewProcessProfitShareComposition[]): Promise<PROFIT_SHARE> {
        try {
            this.formService.block();

            if (compositionCharges && compositionCharges.length) profitShare.COMPOSITION_PAIR_REFERENCE = compositionCharges.filter(x => x.SELECTED).map(x => x.CHARGE_PAIR_NUMBER);

            const calculateResponse = await this.operationalService.post("/profitshare/calculate", { processNumber: this.$scope.processNumber, profitShare: profitShare }, 30000);
            if (calculateResponse && calculateResponse.status == 200 && calculateResponse.data) profitShare = calculateResponse.data.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return profitShare;
        }
    }

    private requestProcessInfo(number: string): Promise<any> {
        const timeout = 120000;
        return this.operationalService.get(`/charge/process/processinfo/${number}/${timeout}`, timeout);
    }

    private async saveProcessTabManagementProfitShare(profitShare: PROFIT_SHARE, compositionCharges: INewProcessProfitShareComposition[]): Promise<boolean> {
        let success: boolean = false;
        try {
            if (profitShare && !this.$newProcessScope.hasInvalidRequiredElements('newProcessManagementProfitShareModal')) {
                this.formService.block();

                profitShare.COMPOSITION_PAIR_REFERENCE = compositionCharges && compositionCharges.length ? compositionCharges.filter(x => x.SELECTED).map(x => x.CHARGE_PAIR_NUMBER) : null;

                const updateResponse = await this.operationalService.post("/profitshare/update", { processNumber: this.$scope.processNumber, profitShare: profitShare }, 30000);
                if (updateResponse && updateResponse.status == 200) {
                    success = true;
                    const msg = this.formService.getTranslate('OPERATIONAL.PROFIT_SHARE_SUCESSFULLY_UPDATED');
                    this.formService.notifySuccess(msg);
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return success;
        }
    }

    private async getProfitShare(): Promise<PROFIT_SHARE> {
        let profitShare: PROFIT_SHARE = null;
        this.formService.block();
        try {
            const profitShareResult = await this.operationalService.get(`/profitshare/${this.$scope.processNumber}`, null, 30000);
            if (profitShareResult && profitShareResult.data) profitShare = profitShareResult.data.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return profitShare;
        }
    }

    private async buildProfitShareComposition(profitShare: PROFIT_SHARE): Promise<INewProcessProfitShareComposition[]> {
        const compositions: INewProcessProfitShareComposition[] = [];
        try {
            let charges: CHARGE[] = null;
            const chargeTab = await this.operationalService.get(`/process/panel/charge/${this.$newProcessScope.model.PROCESS_NUMBER}`, 30000);
            if (chargeTab && chargeTab.data && chargeTab.data.data) charges = chargeTab.data.data.CHARGE;
            if (charges && charges.length) {
                for (const charge of charges) {
                    compositions.push({
                        SELECTED: profitShare && profitShare.COMPOSITION_PAIR_REFERENCE && profitShare.COMPOSITION_PAIR_REFERENCE.some(pair => pair == charge.CHARGE_PAIR_NUMBER),
                        CHARGE: charge.CHARGE,
                        CHARGE_PAIR_NUMBER: charge.CHARGE_PAIR_NUMBER,
                        CHARGE_VALUE: charge.INCOME.CURRENCY && charge.OUTCOME.CURRENCY && charge.INCOME.CURRENCY.ID !== charge.OUTCOME.CURRENCY.ID ? null : (charge.INCOME.TOTAL - charge.OUTCOME.TOTAL),
                        OUTCOME: charge.OUTCOME ? {
                            APPLICATION: charge.OUTCOME.APPLICATION,
                            EQUIPMENT: charge.OUTCOME.EQUIPMENT,
                            VEHICLE_TYPE: charge.OUTCOME.VEHICLE_TYPE,
                            WEIGHT_RANGE: charge.OUTCOME.WEIGHT_RANGE,
                            AMOUNT: charge.OUTCOME.AMOUNT,
                            CURRENCY: charge.OUTCOME.CURRENCY,
                            TOTAL: charge.OUTCOME.TOTAL,
                        } : null,
                        INCOME: charge.INCOME ? {
                            APPLICATION: charge.INCOME.APPLICATION,
                            EQUIPMENT: charge.INCOME.EQUIPMENT,
                            VEHICLE_TYPE: charge.INCOME.VEHICLE_TYPE,
                            WEIGHT_RANGE: charge.INCOME.WEIGHT_RANGE,
                            AMOUNT: charge.INCOME.AMOUNT,
                            CURRENCY: charge.INCOME.CURRENCY,
                            TOTAL: charge.INCOME.TOTAL
                        } : null
                    });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
        return compositions;
    }

    private async refreshProcessFinancialStatus(): Promise<void> {
        try {
            this.formService.block();

            if (await this.permissionService.isRoleAllowed("PROCESSCLOSUREREFRESH") === false) {
                this.permissionService.showBlockMessage();
                this.formService.unblock();
                return;
            }

            const process = this.$newProcessScope.model.PROCESS_NUMBER;
            const processToRefresh = [process]

            const finopReq: IMonacoRequest = {
                data: {
                    processToRefresh: processToRefresh
                },
                route: '/process/refresh',
                timeout: 120000,
            }
            const refresh = await this.finopService.post(finopReq);

            if (refresh) {
                const managementTab = await this.operationalService.get(`/process/panel/management/${this.$scope.processNumber}`, null, 30000);
                if (managementTab && managementTab.data && managementTab.data.data) {
                    this.$scope.model.AGENT_SETTLEMENT_SITUATION = managementTab.data.data.AGENT_SETTLEMENT_SITUATION;
                    this.$scope.model.FORWARDING_SITUATION = managementTab.data.data.FORWARDING_SITUATION;
                    this.$scope.model.PAYMENT_SITUATION = managementTab.data.data.PAYMENT_SITUATION;
                    this.$scope.model.RECEIPT_SITUATION = managementTab.data.data.RECEIPT_SITUATION;
                    if (this.$scope.model.RESULTS_ANALYSIS) {
                        this.$scope.model.RESULTS_ANALYSIS.forEach(result => {
                            if (result.DATE) {
                                result.DATE = new Date(result.DATE);
                            }
                        });
                    }
                    if (this.$scope.model.FORWARDING_SITUATION && this.$scope.model.FORWARDING_SITUATION.DATE) this.$scope.model.FORWARDING_SITUATION.DATE = new Date(this.$scope.model.FORWARDING_SITUATION.DATE);
                    this.$scope.oldModel = angular.copy(this.$scope.model);
                }
            }
            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getGenericList(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async getAllUsers() {
        const result = await this.helperService.post('/user/list/', null, 10000);

        const userData = result?.data?.data?.data ?? [];
        const usersWithTrueStatus = userData.filter((user) => user.status === true);
        return usersWithTrueStatus;
    }

    private navigateBetweenIds(to: string): boolean {
        if (!to) return false;
        this.$timeout(function () {
            const element = $("#" + to);
            if (element.length == 0) return;
            const position = $("#" + to).offset().top + $('.app-content-body').scrollTop() - 230;
            $('.app-content-body').animate({
                scrollTop: position
            }, 500);
            return true;
        });
        return false;
    };

    private modalNavigateBetweenIds(to: string): boolean {
        if (!to) return false;
        this.$timeout(function () {
            const element = $("#" + to);
            if (element.length == 0) return;
            const position = $("#" + to).offset().top + $('.modal-body').scrollTop() - 230;
            $('.modal-body').animate({
                scrollTop: position
            }, 500);
            return true;
        });
        return false;
    };

    private async getSectorListByName(search?: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            const sectors = await this.productService.post({ route: "/sector/list/custom", data: { name: search } });
            if (sectors && sectors.data && sectors.data.data) result = sectors.data.data.filter(item => item.TYPE.ID == '2').map(sector => { return { ID: sector.ID, NAME: sector.NAME, CODE: sector.DISPLAY_NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private lessThanDezCharactersInRemark(): boolean {
        const resultsAnalysis = this.$scope.model.RESULTS_ANALYSIS;
        let result: boolean = false;
        if (resultsAnalysis && resultsAnalysis.length) {
            resultsAnalysis.forEach(item => {
                if (item.ACTIVE && item.REMARKS && item.REMARKS.length < 10) {
                    result = true;
                }
            });
            if (result) this.formService.handleError(this.formService.getTranslate("REGISTRATION.MESSAGES.ERROR.REMARK_MIN_LENGTH", { qty: 10 }));
            return result;
        }
    }
}
export abstract class NumberUtil {

    public static formatDecimals(value: number, decimals: number): number {
        if (!value) return null;
        if (!decimals) return value;
        try {
            return parseFloat(value.toFixed(decimals));
        } catch (ex) {
            throw ex;
        }
    }
}

import { IHttpResponse } from 'angular';
import { IMonacoRequest } from '@services/GridFormService';
import * as config from '../bootstrap/Config';
import * as Address from '../communication/Address';

export class TrackingAWBService {
    public static $inject: string[] = ['$injector'];
    private $injector: ng.Injectable<any>;
    private $http: ng.IHttpService;
    private timeout: number;
    private route: string;
    private unavailableError: string;

    constructor($injector: ng.Injectable<any>) {
        this.$injector = $injector;
        this.$http = $injector.get('$http');
        this.timeout = 180000; //3minutes
        this.unavailableError = "Serviço WBA-TrackingAWB indisponível no momento.";

        if (config.default.environment === 'prod') {
            this.route = `${Address.monacoAddressProd.TRACKINGAWB}/api/v1/trackingawb`;
        } else if (config.default.environment === 'qa') {
            this.route = `${Address.monacoAddressQa.TRACKINGAWB}/api/v1/trackingawb`;
        } else { //dev
            this.route = `${Address.monacoAddressLocal.TRACKINGAWB}/api/v1/trackingawb`;
        }
    }

    public get $route(): string {
        return this.route;
    }

    public async get<T = any>(request: IMonacoRequest): Promise<IHttpResponse<IHttpResponse<T>>> {
        try {
            if (!request || !request.route) return null;

            const operation = await this.$http<IHttpResponse<T>>({
                method: 'GET',
                url: `${this.route}${request.route}`,
                cache: false,
                timeout: (request.timeout) ? request.timeout : this.timeout,
            }).catch(err => {
                throw (err.status && err.status == -1) ? new Error(this.unavailableError) : err;
            });
            const response = await operation;
            return response;
        } catch (ex) {
            throw ex;
        }
    }

    public async post<T = any>(request: IMonacoRequest): Promise<IHttpResponse<IHttpResponse<T>>> {
        try {
            if (!request || !request.route) return null;

            const operation = await this.$http<IHttpResponse<T>>({
                method: 'POST',
                url: `${this.route}${request.route}`,
                cache: false,
                timeout: (request.timeout) ? request.timeout : this.timeout,
                data: request.data,
            }).catch(err => {
                throw (err.status && err.status == -1) ? new Error(this.unavailableError) : err;
            });
            const response = await operation;
            return response;
        } catch (ex) {
            throw ex;
        }
    }
}

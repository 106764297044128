import * as angular from 'angular';
import config from './Config';
import { ISessionService } from '@services/SessionService';

async function loadAppModule($injector): Promise<void> {
    try {
        const $ocLazyLoad = $injector.get("$ocLazyLoad");
        const module = await import('../app/AppModule');
        $ocLazyLoad.load(module.APP_MODULE);
    }
    catch (ex) {
        throw new Error("Failed to load APP Module: " + ex);
    }
}

const storageFutureState = {
    name: 'storage.**',
    url: '/storage',
    data: {
        requiresAuth: false
    },
    lazyLoad: ($transition$) => {
        const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
        return import('../storage/StorageModule')
            .then(module => {
                $ocLazyLoad.load(module.STORAGE_MODULE)
            })
            .catch(err => {
                throw new Error("Failed to load STORAGE Module: " + err);
            });
    }
}

const viewDataManagerFutureState = {
    name: 'viewDataManager.**',
    url: '/viewDataManager',
    data: {
        requiresAuth: false
    },
    lazyLoad: ($transition$) => {
        const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
        return import('../viewDataManager/ViewDataManagerModule')
            .then(module => {
                $ocLazyLoad.load(module.VIEW_DATAMANAGER_MODULE)
            })
            .catch(err => {
                throw new Error("Failed to load STORAGE Module: " + err);
            });
    }
}

const appFutureState = {
    name: 'app.**',
    url: '/app',
    data: {
        requiresAuth: false
    },
    lazyLoad: ($transition$) => {
        const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
        return import('../app/AppModule')
            .then(module => {
                $ocLazyLoad.load(module.APP_MODULE)
            })
            .catch(err => {
                throw new Error("Failed to load APP Module: " + err);
            });
    }
}

routeConfig.$inject = ['$locationProvider', '$urlRouterProvider', '$uiRouterProvider'];
function routeConfig($locationProvider, $urlRouterProvider, $uiRouterProvider) {
    $locationProvider.hashPrefix(config.hashPrefix);

    if (config.debugRoute) $uiRouterProvider.trace.enable(1);

    $urlRouterProvider.otherwise(async function ($injector) {
        const SessionService: ISessionService = $injector.get('SessionService');
        if (SessionService.isAuthenticated()) await SessionService.redirectToApp();
        else await SessionService.redirectToLogin();
    });

    const $stateRegistry = $uiRouterProvider.stateRegistry;
    $stateRegistry.register(storageFutureState);
    $stateRegistry.register(viewDataManagerFutureState);
    $stateRegistry.register(appFutureState);
}

export default function initRoute(moduleName: string): void {
    angular.module(moduleName).config(routeConfig);
}
import * as angular from 'angular';

const commercialState = {
    name: 'app.commercial',
    url: '/commercial',
    abstract: true,
    data: {
        /* Solução de carregar os termos por módulo
        i18n: ['general', 'registration', 'route', 'product', 'financial', 'basic_data', 'entity', 'operational'],
        */
        requiresAuth: true,
        permissions: {
            except: 'EXTERNAL',
            redirectTo: 'app.denied'
        }
    }
}

const commercialMenu = {
    name: 'app.commercial.menu',
    url: '/menu',
    views: {
        'content@app': {
            template: require("./view/commercialMenu.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.COMMERCIAL',
        parent: 'app.commercial'
    }
}

// Ofertas
const offerState = {
    name: 'app.commercial.offer',
    url: '/offer',
    views: {
        'content@app': {
            template: require("./view/offerRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.OFFER',
        parent: 'app.commercial.menu'
    }
}

// Pré-Ofertas Combinadas
const combinedPreOfferState = {
    name: 'app.commercial.combinedPreOffer',
    url: '/combinedPreOffer',
    views: {
        'content@app': {
            template: require("./view/combinedPreOfferRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.PRE_OFFER',
        parent: 'app.commercial.menu'
    }
}

const accountState = {
    name: 'app.commercial.account',
    url: '/account',
    views: {
        'content@app': {
            template: require("./view/accountRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.ACCOUNT',
        parent: 'app.commercial.menu'
    }
}

const accountBulkChangeSalesPersonState = {
    name: 'app.commercial.account.bulkChangeSalesPerson',
    url: '/bulkChangeSalesPerson',
    views: {
        'content@app': {
            template: require("./view/accountBulkChangeSalesPersonWizard.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.ACCOUNT_BULK_CHANGE_SALES_PERSON',
        parent: 'app.commercial.menu'
    }
}

routeConfig.$inject = ['$uiRouterProvider'];
function routeConfig($uiRouterProvider) {
    const $stateRegistry = $uiRouterProvider.stateRegistry;
    $stateRegistry.register(commercialState);
    $stateRegistry.register(commercialMenu);

    $stateRegistry.register(offerState);
    $stateRegistry.register(combinedPreOfferState);
    $stateRegistry.register(accountState);
    $stateRegistry.register(accountBulkChangeSalesPersonState);

}

export default function initRoute(moduleName: string): void {
    angular.module(moduleName).config(routeConfig);
}
import * as angular from 'angular';

import initRoute from './RouteConfig';
import { GRID_FORM_MODULE } from '../gridForm/GridFormModule';

import { TaskMenuController } from './controllers/TaskMenuController';
import { ExternalService } from '@services/ExternalService';
import { ProductService } from '@services/ProductService';

const TASK_MODULE = angular
    .module('task', [GRID_FORM_MODULE.name])
    .controller('TaskMenuController', TaskMenuController)
    .service('ExternalService', ExternalService)
    .service('ProductService', ProductService);

initRoute(TASK_MODULE.name);

export { TASK_MODULE };
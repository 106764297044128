import * as angular from 'angular';
import * as moment from 'moment';
import { IViewLog } from "@models/interface/common/IViewLog";


interface IScopeLogViewer {
  log: IViewLog;
  searchLog: Function;
  isNumber: Function;
  navObjWithString: Function;
  checkEmptyObject: Function;
}

export function deepLogViewerDirective() {

  let ddo: any = {};
  ddo.restrict = "E";
  ddo.transclude = true;

  ddo.replace = true;
  ddo.$scope = true;
  ddo.template = require("../view/template/deep-log-viewer.html");

  ddo.scope = {
    log: '='
  };

  ddo.link = function ($scope: IScopeLogViewer) {

    $scope.searchLog = function () {
      let changes = angular.copy($scope.log.originalList);

      if ($scope.log.searchQuery.length >= 3) {

        $scope.log.showCloseButton = true;

        let results = new Array();

        for (let i = 0; i < changes.length; i++) {

          let str = JSON.stringify(changes[i]).toLowerCase();

          let index = str.search($scope.log.searchQuery.toLowerCase());

          let hasText = index >= 0;
          let hasDate = moment(changes[i].DATE).format('L').toString().search($scope.log.searchQuery) >= 0;

          if (hasText || hasDate) {
            results.push(changes[i]);
          }

        }

        $scope.log.list = results;
      } else {
        $scope.log.list = changes;

      }
    }

    $scope.isNumber = function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    }

    $scope.navObjWithString = function (object: any, path: string): any {
      path = path.replace(/\[(\w+)\]/g, '.$1');
      path = path.replace(/^\./, '');
      let a = path.split('.');
      for (let i = 0, n = a.length; i < n; ++i) {
        let k = a[i];
        if (k in object) {
          object = object[k];
        } else {
          return;
        }
      }
      return object;
    }

    $scope.checkEmptyObject = function (obj: object) {
      return Object.keys(obj).length === 0
    }

  }

  return ddo;
}
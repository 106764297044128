import angular = require("angular");
import { IModalInstanceService } from "angular-ui-bootstrap";
import { SSEService } from "@appServices/SSEService";
import { IGridFormServiceScope, GridFormService, IMonacoRequestLog, IGridFormController } from '@services/GridFormService';
import { IMonacoColumnDef } from '@services/GridService2';
import { ISessionService } from "@services/SessionService";
import { IModalService } from "@services/ModalService";
import { IRestService } from '@services/RestService';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { ICountryModel } from '@models/interface/product/CountryModel';
import { IDocumentError } from '@models/interface/common/IDocumentError';
import { EOperation } from "@enums/GenericData";
import { GridColumnBuilder } from '../../common/GridColumnBuilder';
import { BrowserTitle } from "../../common/BrowserTitle";
import { SelectorModel } from "../../common/model/SelectorModel";
import { ILinkParameter } from "../../common/model/ModelParameter";

interface ICountryScope extends IGridFormServiceScope {
    model: ICountryModel;
    gridOptions: uiGrid.IGridOptions;
    scopeBeforeSave: ICountryModel;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    tradeLaneList: SelectorModel[];
    countryImgList: SelectorModel[];
    filterCountryImg: string;
    selectedCountry: SelectorModel;
    user: any;
    sessionService: ISessionService;
    editCountry: (country: ICountryModel) => Promise<void>;
    viewCountry: (country: ICountryModel) => Promise<void>;
    viewLogCountry: (country: ICountryModel) => Promise<void>;
    copyCountry: (country: ICountryModel) => Promise<void>;
    getCountryImgByInitials(initials: string): void;
    getTradeLaneListByName(query: string): Promise<void>;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
    goToTradeLane: (tradeLanes: SelectorModel[]) => void;
    countryImgSelection: () => void;
    filterCountryImgList: (country: SelectorModel) => boolean;
    hasAnySelected: () => boolean;
    setSelectedCountry: (country: SelectorModel) => void;
    isSelectedCountry: (countryId: string) => boolean;
}

export class CountryRegisterController extends GridFormService implements IGridFormController {
    private RestService: IRestService;
    private ModalService: IModalService;
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: ICountryScope;
    private $timeout: ng.ITimeoutService;
    private SSEService: SSEService;
    private gridName: string;
    private modalID: number;

    constructor($injector: ng.Injectable<any>, $scope: ICountryScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$timeout = $injector.get('$timeout');
        this.RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.gridName = 'GRID_COUNTRY';
        this.modalID = null;
    }

    getUrlProduct() {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    async $onInit?() {
        try {
            this.$baseUrl = this.getUrlProduct();

            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.$scope.filterCountryImg = null;
            this.$scope.countryImgList = this.getCountryImgList();

            this.initForm(this, 'form', 'country', 'GENERAL.MENU.COUNTRY', true);
            await this.initGrid('gridCountry', '/country/list', true, true, null, true, true);
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.getCountryImgByInitials = (initials: string): void => {
            this.getCountryImgByInitials(initials);
        }

        //tradelane
        this.$scope.getTradeLaneListByName = async (search: string): Promise<void> => {
            return await this.getTradeLaneListByName(search);
        }

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }

        this.$scope.goToTradeLane = (tradeLanes: SelectorModel[]): void => {
            this.$scope.sessionService.openTab("app.registration.tradelane", <ILinkParameter>{ ID: this.$scope.getCONCAT(tradeLanes, null, "ID") });
        }

        this.$scope.countryImgSelection = (): void => {
            this.countryImgSelection();
        }

        this.$scope.filterCountryImgList = (country: SelectorModel): boolean => {
            return (!country || !this.$scope.filterCountryImg) ? true : (country.NAME.indexOf(this.$scope.filterCountryImg) > -1);
        }

        this.$scope.hasAnySelected = (): boolean => {
            return this.$scope.selectedCountry != null;
        }

        this.$scope.setSelectedCountry = (country: SelectorModel) => {
            this.$scope.selectedCountry = country;
        }

        this.$scope.isSelectedCountry = (countryId: string): boolean => {
            return this.$scope.selectedCountry && countryId && this.$scope.selectedCountry.ID == countryId;
        }

        this.$scope.editCountry = async (country: ICountryModel): Promise<void> => {
            let blockedObject = {
                ID: country.ID,
                NAME: country.NAME,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || country.ID !== this.$scope.model.ID) this.$scope.view(country);
                } else if (this.$scope.operation !== EOperation.EDIT || country.ID !== this.$scope.model.ID) {
                    this.$scope.edit(country);
                }
            };
        }

        this.$scope.viewCountry = async (country: ICountryModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(country);
        }

        this.$scope.viewLogCountry = async (country: ICountryModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(country);
        }

        this.$scope.copyCountry = async (country: ICountryModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(country);
        }
    }

    private async countryImgSelection(): Promise<void> {
        try {
            this.$scope.selectedCountry = this.$scope.model.COUNTRY_IMG;
            this.$scope.filterCountryImg = this.$scope.model.INITIALS;
            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    scope: this.$scope,
                    template: require("../view/modals/countryImgSelection.html"),
                    size: 'lg'
                }, {
                headerText: "GENERAL.SELECT_IMG",
                closeButtonText: "GENERAL.CLOSE",
                actionButtonText: "REGISTRATION.APPLY"
            });
            const approved = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });
            if (approved) this.$scope.model.COUNTRY_IMG = this.$scope.selectedCountry;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getCountryImgByInitials(initials: string): void {
        try {
            if (initials && initials.length == 2) {
                const country = this.$scope.countryImgList && this.$scope.countryImgList.length ? this.$scope.countryImgList.find(country => country.NAME == initials) : null;
                if (country) this.$scope.model.COUNTRY_IMG = country;
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getTradeLaneListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();
                const tradeLanes = await this.RestService.newObjectPromise(`${this.$baseUrl}/tradelane/list/custom/`, { name: search }, 30000, false);
                if (tradeLanes) result = tradeLanes.map(tradelane => { return { ID: tradelane.ID, NAME: tradelane.NAME, CODE: tradelane.CODE } });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.tradeLaneList = result;
        }
    }

    initModel() {
        this.$scope.model = {
            _id: null,
            ID: null,
            NAME_INTL: null,
            FISCAL: false,
            INITIALS: null,
            NAME: null,
            TRADE_LANE: null,
            WEIGHT_LIMIT: null,
            ACTIVE: true,
            INTEGRATION_ID: null,
            COUNTRY_IMG: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            SEARCH_FIELDS: null,
            DOCUMENT_ERROR: null
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewCountry(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editCountry(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyCountry(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogCountry(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + copy + viewLog + modalIntegration),

            enableCellEdit: true,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    private buildColumns(columns: Array<string>) {
        let gridColumns: Array<IMonacoColumnDef> = [];

        const colInitials: IMonacoColumnDef = { name: 'INITIALS', displayName: 'GENERAL.CODE', width: 80 };
        const colCountryName: IMonacoColumnDef = { name: 'NAME', displayName: 'GENERAL.NAME', width: 200 };
        const colNameIntl: IMonacoColumnDef = { name: 'NAME_INTL', displayName: 'GENERAL.NAME_INTL', width: 200 };
        const colFiscal: IMonacoColumnDef = { name: 'FISCAL', displayName: 'REGISTRATION.TAX_HAVEN', width: 100, cellFilter: 'YesOrNo' };
        const colTradeLane: IMonacoColumnDef = { name: "TRADE_LANE.CODE", displayName: "ROUTE.TRADE_LANE", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TRADE_LANE, null, "CODE")}}</div>' };
        const colWeightLimit: IMonacoColumnDef = { name: 'WEIGHT_LIMIT', displayName: 'PRODUCT.WEIGHT_LIMITE', width: 150, cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.WEIGHT_LIMIT | number: 2}}<div>' };
        const colActive: IMonacoColumnDef = { name: 'ACTIVE', displayName: 'GENERAL.ACTIVE', width: 70, cellFilter: 'YesOrNo' };
        const colIntegrationID: IMonacoColumnDef = { name: 'INTEGRATION_ID', displayName: 'GENERAL.INTEGRATION_ID', width: 150 };
        const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
        const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
        const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

        columns.forEach(column => {
            switch (column) {
                case 'INITIALS':
                    gridColumns.push(colInitials);
                    break;
                case 'NAME':
                    gridColumns.push(colCountryName);
                    break;
                case 'NAME_INTL':
                    gridColumns.push(colNameIntl);
                    break;
                case 'WEIGHT_LIMIT':
                    gridColumns.push(colWeightLimit);
                    break;
                case 'FISCAL':
                    gridColumns.push(colFiscal);
                    break;
                case 'TRADE_LANE':
                    gridColumns.push(colTradeLane);
                    break;
                case 'ACTIVE':
                    gridColumns.push(colActive);
                    break;
                case 'INTEGRATION_ID':
                    gridColumns.push(colIntegrationID);
                    break;
                case 'ID':
                    gridColumns.push(colId);
                    break;
                case 'CREATED_AT':
                    gridColumns.push(colCreatedAt);
                    break;
                case 'UPDATED_AT':
                    gridColumns.push(colUpdatedAt);
                    break;
            }
        });

        return gridColumns;
    }

    async register(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");

            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }


    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")} (${this.$scope.model.NAME})`;
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.handleError(ex);
            this.SSEService.closeEvents();
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")} (${this.$scope.model.NAME})`;
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.handleError(ex);
            this.SSEService.closeEvents();
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            await this.clearFields(this.$scope.model, ['CONCATENATED']);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        return {
            route: `/country/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "GENERAL.MENU.INTEGRATION" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number, idSubject: string): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/country/sendSync`, { "idCountry": id, "idSubject": idSubject }, 120000, false);
                if (syncRequest) success = true;
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_SENDING_REQUEST');
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const legalPersonData = await this.getCountryById(id);
                    if (row && legalPersonData && legalPersonData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = legalPersonData.DOCUMENT_ERROR;
                        documentError = legalPersonData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async getCountryById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/country/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private getCountryImgList(): SelectorModel[] {
        return [
            { ID: "1", NAME: "AD", CODE: "flag-icon-ad" },
            { ID: "2", NAME: "AE", CODE: "flag-icon-ae" },
            { ID: "3", NAME: "AF", CODE: "flag-icon-af" },
            { ID: "4", NAME: "AG", CODE: "flag-icon-ag" },
            { ID: "5", NAME: "AI", CODE: "flag-icon-ai" },
            { ID: "6", NAME: "AL", CODE: "flag-icon-al" },
            { ID: "7", NAME: "AM", CODE: "flag-icon-am" },
            { ID: "8", NAME: "AO", CODE: "flag-icon-ao" },
            { ID: "9", NAME: "AQ", CODE: "flag-icon-aq" },
            { ID: "10", NAME: "AR", CODE: "flag-icon-ar" },
            { ID: "11", NAME: "AS", CODE: "flag-icon-as" },
            { ID: "12", NAME: "AT", CODE: "flag-icon-at" },
            { ID: "13", NAME: "AU", CODE: "flag-icon-au" },
            { ID: "14", NAME: "AW", CODE: "flag-icon-aw" },
            { ID: "15", NAME: "AX", CODE: "flag-icon-ax" },
            { ID: "16", NAME: "AZ", CODE: "flag-icon-az" },
            { ID: "17", NAME: "BA", CODE: "flag-icon-ba" },
            { ID: "18", NAME: "BB", CODE: "flag-icon-bb" },
            { ID: "19", NAME: "BD", CODE: "flag-icon-bd" },
            { ID: "20", NAME: "BE", CODE: "flag-icon-be" },
            { ID: "21", NAME: "BF", CODE: "flag-icon-bf" },
            { ID: "22", NAME: "BG", CODE: "flag-icon-bg" },
            { ID: "23", NAME: "BH", CODE: "flag-icon-bh" },
            { ID: "24", NAME: "BI", CODE: "flag-icon-bi" },
            { ID: "25", NAME: "BJ", CODE: "flag-icon-bj" },
            { ID: "26", NAME: "BL", CODE: "flag-icon-bl" },
            { ID: "27", NAME: "BM", CODE: "flag-icon-bm" },
            { ID: "28", NAME: "BN", CODE: "flag-icon-bn" },
            { ID: "29", NAME: "BO", CODE: "flag-icon-bo" },
            { ID: "30", NAME: "BQ", CODE: "flag-icon-bq" },
            { ID: "31", NAME: "BR", CODE: "flag-icon-br" },
            { ID: "32", NAME: "BS", CODE: "flag-icon-bs" },
            { ID: "33", NAME: "BT", CODE: "flag-icon-bt" },
            { ID: "34", NAME: "BV", CODE: "flag-icon-bv" },
            { ID: "35", NAME: "BW", CODE: "flag-icon-bw" },
            { ID: "36", NAME: "BY", CODE: "flag-icon-by" },
            { ID: "37", NAME: "BZ", CODE: "flag-icon-bz" },
            { ID: "38", NAME: "CA", CODE: "flag-icon-ca" },
            { ID: "39", NAME: "CC", CODE: "flag-icon-cc" },
            { ID: "40", NAME: "CD", CODE: "flag-icon-cd" },
            { ID: "41", NAME: "CF", CODE: "flag-icon-cf" },
            { ID: "42", NAME: "CG", CODE: "flag-icon-cg" },
            { ID: "43", NAME: "CH", CODE: "flag-icon-ch" },
            { ID: "44", NAME: "CI", CODE: "flag-icon-ci" },
            { ID: "45", NAME: "CK", CODE: "flag-icon-ck" },
            { ID: "46", NAME: "CL", CODE: "flag-icon-cl" },
            { ID: "47", NAME: "CM", CODE: "flag-icon-cm" },
            { ID: "48", NAME: "CN", CODE: "flag-icon-cn" },
            { ID: "49", NAME: "CO", CODE: "flag-icon-co" },
            { ID: "50", NAME: "CR", CODE: "flag-icon-cr" },
            { ID: "51", NAME: "CU", CODE: "flag-icon-cu" },
            { ID: "52", NAME: "CV", CODE: "flag-icon-cv" },
            { ID: "53", NAME: "CW", CODE: "flag-icon-cw" },
            { ID: "54", NAME: "CX", CODE: "flag-icon-cx" },
            { ID: "55", NAME: "CY", CODE: "flag-icon-cy" },
            { ID: "56", NAME: "CZ", CODE: "flag-icon-cz" },
            { ID: "57", NAME: "DE", CODE: "flag-icon-de" },
            { ID: "58", NAME: "DJ", CODE: "flag-icon-dj" },
            { ID: "59", NAME: "DK", CODE: "flag-icon-dk" },
            { ID: "60", NAME: "DM", CODE: "flag-icon-dm" },
            { ID: "61", NAME: "DO", CODE: "flag-icon-do" },
            { ID: "62", NAME: "DZ", CODE: "flag-icon-dz" },
            { ID: "63", NAME: "EC", CODE: "flag-icon-ec" },
            { ID: "64", NAME: "EE", CODE: "flag-icon-ee" },
            { ID: "65", NAME: "EG", CODE: "flag-icon-eg" },
            { ID: "66", NAME: "EH", CODE: "flag-icon-eh" },
            { ID: "254", NAME: "EN", CODE: "flag-icon-gb-eng" },
            { ID: "67", NAME: "ER", CODE: "flag-icon-er" },
            { ID: "68", NAME: "ES", CODE: "flag-icon-es" },
            { ID: "69", NAME: "ET", CODE: "flag-icon-et" },
            { ID: "70", NAME: "FI", CODE: "flag-icon-fi" },
            { ID: "71", NAME: "FJ", CODE: "flag-icon-fj" },
            { ID: "72", NAME: "FK", CODE: "flag-icon-fk" },
            { ID: "73", NAME: "FM", CODE: "flag-icon-fm" },
            { ID: "74", NAME: "FO", CODE: "flag-icon-fo" },
            { ID: "75", NAME: "FR", CODE: "flag-icon-fr" },
            { ID: "76", NAME: "GA", CODE: "flag-icon-ga" },
            { ID: "77", NAME: "GB", CODE: "flag-icon-gb" },
            { ID: "78", NAME: "GD", CODE: "flag-icon-gd" },
            { ID: "79", NAME: "GE", CODE: "flag-icon-ge" },
            { ID: "80", NAME: "GF", CODE: "flag-icon-gf" },
            { ID: "81", NAME: "GG", CODE: "flag-icon-gg" },
            { ID: "82", NAME: "GH", CODE: "flag-icon-gh" },
            { ID: "83", NAME: "GI", CODE: "flag-icon-gi" },
            { ID: "84", NAME: "GL", CODE: "flag-icon-gl" },
            { ID: "85", NAME: "GM", CODE: "flag-icon-gm" },
            { ID: "86", NAME: "GN", CODE: "flag-icon-gn" },
            { ID: "87", NAME: "GP", CODE: "flag-icon-gp" },
            { ID: "88", NAME: "GQ", CODE: "flag-icon-gq" },
            { ID: "89", NAME: "GR", CODE: "flag-icon-gr" },
            { ID: "90", NAME: "GS", CODE: "flag-icon-gs" },
            { ID: "91", NAME: "GT", CODE: "flag-icon-gt" },
            { ID: "92", NAME: "GU", CODE: "flag-icon-gu" },
            { ID: "93", NAME: "GW", CODE: "flag-icon-gw" },
            { ID: "94", NAME: "GY", CODE: "flag-icon-gy" },
            { ID: "95", NAME: "HK", CODE: "flag-icon-hk" },
            { ID: "96", NAME: "HM", CODE: "flag-icon-hm" },
            { ID: "97", NAME: "HN", CODE: "flag-icon-hn" },
            { ID: "98", NAME: "HR", CODE: "flag-icon-hr" },
            { ID: "99", NAME: "HT", CODE: "flag-icon-ht" },
            { ID: "101", NAME: "HU", CODE: "flag-icon-hu" },
            { ID: "102", NAME: "ID", CODE: "flag-icon-id" },
            { ID: "103", NAME: "IE", CODE: "flag-icon-ie" },
            { ID: "104", NAME: "IL", CODE: "flag-icon-il" },
            { ID: "105", NAME: "IM", CODE: "flag-icon-im" },
            { ID: "106", NAME: "IN", CODE: "flag-icon-in" },
            { ID: "107", NAME: "IO", CODE: "flag-icon-io" },
            { ID: "108", NAME: "IQ", CODE: "flag-icon-iq" },
            { ID: "109", NAME: "IR", CODE: "flag-icon-ir" },
            { ID: "110", NAME: "IS", CODE: "flag-icon-is" },
            { ID: "111", NAME: "IT", CODE: "flag-icon-it" },
            { ID: "112", NAME: "JE", CODE: "flag-icon-je" },
            { ID: "113", NAME: "JM", CODE: "flag-icon-jm" },
            { ID: "114", NAME: "JO", CODE: "flag-icon-jo" },
            { ID: "115", NAME: "JP", CODE: "flag-icon-jp" },
            { ID: "116", NAME: "KE", CODE: "flag-icon-ke" },
            { ID: "117", NAME: "KG", CODE: "flag-icon-kg" },
            { ID: "118", NAME: "KH", CODE: "flag-icon-kh" },
            { ID: "119", NAME: "KI", CODE: "flag-icon-ki" },
            { ID: "120", NAME: "KM", CODE: "flag-icon-km" },
            { ID: "121", NAME: "KN", CODE: "flag-icon-kn" },
            { ID: "122", NAME: "KP", CODE: "flag-icon-kp" },
            { ID: "123", NAME: "KR", CODE: "flag-icon-kr" },
            { ID: "124", NAME: "KW", CODE: "flag-icon-kw" },
            { ID: "125", NAME: "KY", CODE: "flag-icon-ky" },
            { ID: "126", NAME: "KZ", CODE: "flag-icon-kz" },
            { ID: "127", NAME: "LA", CODE: "flag-icon-la" },
            { ID: "128", NAME: "LB", CODE: "flag-icon-lb" },
            { ID: "129", NAME: "LC", CODE: "flag-icon-lc" },
            { ID: "130", NAME: "LI", CODE: "flag-icon-li" },
            { ID: "131", NAME: "LK", CODE: "flag-icon-lk" },
            { ID: "132", NAME: "LR", CODE: "flag-icon-lr" },
            { ID: "133", NAME: "LS", CODE: "flag-icon-ls" },
            { ID: "134", NAME: "LT", CODE: "flag-icon-lt" },
            { ID: "135", NAME: "LU", CODE: "flag-icon-lu" },
            { ID: "136", NAME: "LV", CODE: "flag-icon-lv" },
            { ID: "137", NAME: "LY", CODE: "flag-icon-ly" },
            { ID: "138", NAME: "MA", CODE: "flag-icon-ma" },
            { ID: "139", NAME: "MC", CODE: "flag-icon-mc" },
            { ID: "140", NAME: "MD", CODE: "flag-icon-md" },
            { ID: "141", NAME: "ME", CODE: "flag-icon-me" },
            { ID: "142", NAME: "MF", CODE: "flag-icon-mf" },
            { ID: "143", NAME: "MG", CODE: "flag-icon-mg" },
            { ID: "144", NAME: "MH", CODE: "flag-icon-mh" },
            { ID: "145", NAME: "MK", CODE: "flag-icon-mk" },
            { ID: "146", NAME: "ML", CODE: "flag-icon-ml" },
            { ID: "147", NAME: "MM", CODE: "flag-icon-mm" },
            { ID: "148", NAME: "MN", CODE: "flag-icon-mn" },
            { ID: "149", NAME: "MO", CODE: "flag-icon-mo" },
            { ID: "150", NAME: "MP", CODE: "flag-icon-mp" },
            { ID: "151", NAME: "MQ", CODE: "flag-icon-mq" },
            { ID: "152", NAME: "MR", CODE: "flag-icon-mr" },
            { ID: "153", NAME: "MS", CODE: "flag-icon-ms" },
            { ID: "154", NAME: "MT", CODE: "flag-icon-mt" },
            { ID: "155", NAME: "MU", CODE: "flag-icon-mu" },
            { ID: "156", NAME: "MV", CODE: "flag-icon-mv" },
            { ID: "157", NAME: "MW", CODE: "flag-icon-mw" },
            { ID: "158", NAME: "MX", CODE: "flag-icon-mx" },
            { ID: "159", NAME: "MY", CODE: "flag-icon-my" },
            { ID: "160", NAME: "MZ", CODE: "flag-icon-mz" },
            { ID: "161", NAME: "NA", CODE: "flag-icon-na" },
            { ID: "162", NAME: "NC", CODE: "flag-icon-nc" },
            { ID: "163", NAME: "NE", CODE: "flag-icon-ne" },
            { ID: "164", NAME: "NF", CODE: "flag-icon-nf" },
            { ID: "165", NAME: "NG", CODE: "flag-icon-ng" },
            { ID: "166", NAME: "NI", CODE: "flag-icon-ni" },
            { ID: "255", NAME: "NIR", CODE: "flag-icon-gb-nir" },
            { ID: "167", NAME: "NL", CODE: "flag-icon-nl" },
            { ID: "168", NAME: "NO", CODE: "flag-icon-no" },
            { ID: "169", NAME: "NP", CODE: "flag-icon-np" },
            { ID: "170", NAME: "NR", CODE: "flag-icon-nr" },
            { ID: "171", NAME: "NU", CODE: "flag-icon-nu" },
            { ID: "172", NAME: "NZ", CODE: "flag-icon-nz" },
            { ID: "173", NAME: "OM", CODE: "flag-icon-om" },
            { ID: "174", NAME: "PA", CODE: "flag-icon-pa" },
            { ID: "175", NAME: "PE", CODE: "flag-icon-pe" },
            { ID: "176", NAME: "PF", CODE: "flag-icon-pf" },
            { ID: "177", NAME: "PG", CODE: "flag-icon-pg" },
            { ID: "178", NAME: "PH", CODE: "flag-icon-ph" },
            { ID: "179", NAME: "PK", CODE: "flag-icon-pk" },
            { ID: "180", NAME: "PL", CODE: "flag-icon-pl" },
            { ID: "181", NAME: "PM", CODE: "flag-icon-pm" },
            { ID: "182", NAME: "PN", CODE: "flag-icon-pn" },
            { ID: "183", NAME: "PR", CODE: "flag-icon-pr" },
            { ID: "184", NAME: "PS", CODE: "flag-icon-ps" },
            { ID: "185", NAME: "PT", CODE: "flag-icon-pt" },
            { ID: "186", NAME: "PW", CODE: "flag-icon-pw" },
            { ID: "187", NAME: "PY", CODE: "flag-icon-py" },
            { ID: "188", NAME: "QA", CODE: "flag-icon-qa" },
            { ID: "189", NAME: "RE", CODE: "flag-icon-re" },
            { ID: "190", NAME: "RO", CODE: "flag-icon-ro" },
            { ID: "191", NAME: "RS", CODE: "flag-icon-rs" },
            { ID: "192", NAME: "RU", CODE: "flag-icon-ru" },
            { ID: "193", NAME: "RW", CODE: "flag-icon-rw" },
            { ID: "194", NAME: "SA", CODE: "flag-icon-sa" },
            { ID: "195", NAME: "SB", CODE: "flag-icon-sb" },
            { ID: "196", NAME: "SC", CODE: "flag-icon-sc" },
            { ID: "256", NAME: "SCT", CODE: "flag-icon-gb-sct" },
            { ID: "197", NAME: "SD", CODE: "flag-icon-sd" },
            { ID: "198", NAME: "SE", CODE: "flag-icon-se" },
            { ID: "199", NAME: "SG", CODE: "flag-icon-sg" },
            { ID: "201", NAME: "SH", CODE: "flag-icon-sh" },
            { ID: "202", NAME: "SI", CODE: "flag-icon-si" },
            { ID: "203", NAME: "SJ", CODE: "flag-icon-sj" },
            { ID: "204", NAME: "SK", CODE: "flag-icon-sk" },
            { ID: "205", NAME: "SL", CODE: "flag-icon-sl" },
            { ID: "206", NAME: "SM", CODE: "flag-icon-sm" },
            { ID: "207", NAME: "SN", CODE: "flag-icon-sn" },
            { ID: "208", NAME: "SO", CODE: "flag-icon-so" },
            { ID: "209", NAME: "SR", CODE: "flag-icon-sr" },
            { ID: "210", NAME: "SS", CODE: "flag-icon-ss" },
            { ID: "211", NAME: "ST", CODE: "flag-icon-st" },
            { ID: "212", NAME: "SV", CODE: "flag-icon-sv" },
            { ID: "213", NAME: "SX", CODE: "flag-icon-sx" },
            { ID: "214", NAME: "SY", CODE: "flag-icon-sy" },
            { ID: "215", NAME: "SZ", CODE: "flag-icon-sz" },
            { ID: "216", NAME: "TC", CODE: "flag-icon-tc" },
            { ID: "217", NAME: "TD", CODE: "flag-icon-td" },
            { ID: "218", NAME: "TF", CODE: "flag-icon-tf" },
            { ID: "219", NAME: "TG", CODE: "flag-icon-tg" },
            { ID: "220", NAME: "TH", CODE: "flag-icon-th" },
            { ID: "221", NAME: "TJ", CODE: "flag-icon-tj" },
            { ID: "222", NAME: "TK", CODE: "flag-icon-tk" },
            { ID: "223", NAME: "TL", CODE: "flag-icon-tl" },
            { ID: "224", NAME: "TM", CODE: "flag-icon-tm" },
            { ID: "225", NAME: "TN", CODE: "flag-icon-tn" },
            { ID: "226", NAME: "TO", CODE: "flag-icon-to" },
            { ID: "227", NAME: "TR", CODE: "flag-icon-tr" },
            { ID: "228", NAME: "TT", CODE: "flag-icon-tt" },
            { ID: "229", NAME: "TV", CODE: "flag-icon-tv" },
            { ID: "230", NAME: "TW", CODE: "flag-icon-tw" },
            { ID: "231", NAME: "TZ", CODE: "flag-icon-tz" },
            { ID: "232", NAME: "UA", CODE: "flag-icon-ua" },
            { ID: "233", NAME: "UG", CODE: "flag-icon-ug" },
            { ID: "234", NAME: "UM", CODE: "flag-icon-um" },
            { ID: "235", NAME: "UN", CODE: "flag-icon-un" },
            { ID: "236", NAME: "US", CODE: "flag-icon-us" },
            { ID: "237", NAME: "UY", CODE: "flag-icon-uy" },
            { ID: "238", NAME: "UZ", CODE: "flag-icon-uz" },
            { ID: "239", NAME: "VA", CODE: "flag-icon-va" },
            { ID: "240", NAME: "VC", CODE: "flag-icon-vc" },
            { ID: "241", NAME: "VE", CODE: "flag-icon-ve" },
            { ID: "242", NAME: "VG", CODE: "flag-icon-vg" },
            { ID: "243", NAME: "VI", CODE: "flag-icon-vi" },
            { ID: "244", NAME: "VN", CODE: "flag-icon-vn" },
            { ID: "245", NAME: "VU", CODE: "flag-icon-vu" },
            { ID: "246", NAME: "WF", CODE: "flag-icon-wf" },
            { ID: "257", NAME: "WLS", CODE: "flag-icon-gb-wls" },
            { ID: "247", NAME: "WS", CODE: "flag-icon-ws" },
            { ID: "248", NAME: "XK", CODE: "flag-icon-xk" },
            { ID: "249", NAME: "YE", CODE: "flag-icon-ye" },
            { ID: "250", NAME: "YT", CODE: "flag-icon-yt" },
            { ID: "251", NAME: "ZA", CODE: "flag-icon-za" },
            { ID: "252", NAME: "ZM", CODE: "flag-icon-zm" },
            { ID: "253", NAME: "ZW", CODE: "flag-icon-zw" },
        ];
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'INITIALS',
                LABEL: 'GENERAL.CODE'
            },
            {
                PROPERTY: 'CODE',
                LABEL: 'GENERAL.CODE'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'NAME_INTL',
                LABEL: 'GENERAL.NAME_INTL'
            },
            {
                PROPERTY: 'TAX',
                LABEL: 'GENERAL.CHARGE'
            },
            {
                PROPERTY: 'FISCAL',
                LABEL: 'REGISTRATION.TAX_HAVEN'
            },
            {
                PROPERTY: 'TRADE_LANE',
                LABEL: 'ROUTE.TRADE_LANE'
            },
            {
                PROPERTY: 'WEIGHT_LIMIT',
                LABEL: 'PRODUCT.WEIGHT_LIMITE'
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
            {
                PROPERTY: 'INTEGRATION_ID',
                LABEL: 'GENERAL.INTEGRATION_ID'
            },
            {
                PROPERTY: 'COUNTRY_IMG',
                LABEL: 'REGISTRATION.FLAG'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            }
        ];
        return props;
    }
} 
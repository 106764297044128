import { HandleError } from "../../common/util/HandleError";

/**
 * @description Componente Formulário de Linhas dinâmicas Adiciona e Remove a linha (cria dependência de implementar métodos na controller (multiLineAdd e multiLineRemove))
 * @throws {TypeError} Caso não implemente os métodos "multiLineAdd" e "multiLineRemove" na controller em que foi implementada esta diretiva
 * 
 * Design Ex.: <multi-line>
 *              <line> 
 *                <column></column>
 *                <column></column>
 *                <column></column>
 *              </line>
 *             </multi-line>
 * 
 * @returns {object} Objecto de Diretiva
 */
export function multiLineDirective() {
  let ddo: any = {};
  ddo.restrict = "E";
  ddo.transclude = true;

  ddo.replace = true;
  ddo.$scope = true;
  ddo.template = require("../view/template/multi-line.html");

  ddo.scope = {
    id: "@",
    columns: "@",
    add: "@",
    args: "=",
    operation: "=",
    onlyView: "="
  };

  ddo.link = function ($scope) {

    $scope.multiLineAdd = () => {

      try {

        if ($scope.add) {

          const args = ($scope.args) ? $scope.args : null;
          const fnTeste: () => void = $scope.$parent[$scope.add]
          if (fnTeste)
            if (args) fnTeste.call(this, ...args);
            else fnTeste.call(this);
          else
            throw new Error('Você precisa implementar o atributo add na diretiva e implementar esta função!');

        } else {
          throw new Error('Você precisa implementar o atributo add na diretiva e implementar esta função!');
        }

      } catch (ex) {
        HandleError.exception(ex);
      }

    }


  }

  return ddo;
}


/**
 * @description Elemento linha, filho da diretiva "multiLine"
 * 
 * @returns {object} Objecto de Diretiva
 */
export function lineDirective() {
  let ddo: any = {};

  ddo.restrict = "E";
  ddo.require = "^multiLine";
  ddo.transclude = true;
  ddo.replace = true;
  ddo.template = `<tr ng-transclude class='template'></tr>`;

  return ddo;
}

/**
 * @description Elemento coluna, filho da diretiva "line"
 * 
 * @returns {object} Objecto de Diretiva
 */
export function columnDirective() {
  let ddo: any = {};

  ddo.restrict = "E";
  ddo.transclude = true;
  ddo.replace = true;
  ddo.require = ['^line', '^multiLine'];
  ddo.template = `<td style='vertical-align: middle;'>
                    <div class="table-form-group">
                      <ng-transclude></ng-transclude>
                    </div>
                  </td>`;

  return ddo;
}
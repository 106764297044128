import { IRestService } from "@services/RestService";
import { INotificationService } from "@services/NotificationService";
import { PERMISSION } from "@models/interface/product/UserGroupModel";
import { Mutex, MutexInterface } from 'async-mutex';
import { SelectorModel } from "../../common/model/SelectorModel";
import { IMonacoConfig } from '../../common/MonacoInterface';
import { HelperService } from "@services/HelperService";

export const PermissionActionCustom: SelectorModel = { ID: "0", NAME: "Custom", CODE: "custom" };
export const PermissionActionInsert: SelectorModel = { ID: "1", NAME: "Inserção", CODE: "insert" };
export const PermissionActionEdit: SelectorModel = { ID: "2", NAME: "Edição", CODE: "edit" };
export const PermissionActionSave: SelectorModel = { ID: "3", NAME: "Gravação", CODE: "save" };
export const PermissionActionDelete: SelectorModel = { ID: "4", NAME: "Exclusão", CODE: "delete" };
export const PermissionActionView: SelectorModel = { ID: "5", NAME: "Visualização", CODE: "view" };
export const PermissionActionCopy: SelectorModel = { ID: "6", NAME: "Cópia", CODE: "copy" };

export class PermissionServiceMenuCriteriaFactory {
    private static _instance: PermissionServiceMenuCriteriaFactory;
    private static _mutex: Mutex;
    private static _list: PERMISSION[];
    private static _restService: IRestService;
    private static _helperService: HelperService;
    private static _window: ng.IWindowService;
    private static _scope: ng.IScope;

    public static Instance($scope: ng.IScope, $injector: ng.Injectable<any>): PermissionServiceMenuCriteriaFactory {
        return this._instance || (this._instance = new this($scope, $injector));
    }

    private constructor($scope: ng.IScope, $injector: ng.Injectable<any>) {
        PermissionServiceMenuCriteriaFactory._restService = $injector.get('RestService');
        PermissionServiceMenuCriteriaFactory._helperService = $injector.get('HelperService');
        PermissionServiceMenuCriteriaFactory._mutex = new Mutex();
        PermissionServiceMenuCriteriaFactory._list = null;
        PermissionServiceMenuCriteriaFactory._window = $injector.get('$window');
        PermissionServiceMenuCriteriaFactory._scope = $scope;
    }

    public async getCriteriaList(): Promise<PERMISSION[]> {
        let release: MutexInterface.Releaser = null;
        try {
            release = await PermissionServiceMenuCriteriaFactory._mutex.acquire();
            try {
                const user: any = PermissionServiceMenuCriteriaFactory._scope['user'];

                const reloadRole = sessionStorage.getItem("UserRole");

                if (reloadRole !== user.email || PermissionServiceMenuCriteriaFactory._list === null) {
                    sessionStorage.setItem("UserRole", user.email);
                    const rc = await PermissionServiceMenuCriteriaFactory._helperService.get('/userGroup/permission/list', null, 12000);
                    PermissionServiceMenuCriteriaFactory._list = (rc.data) ? rc.data.data : null;
                }

                return PermissionServiceMenuCriteriaFactory._list;
            } catch (ex) {
                throw ex;
            }
        } finally {
            await release();
        }
    }
}

export class PermissionService {
    private restService: IRestService;
    private notificationService: INotificationService;
    private timeout: number;
    private injector: ng.Injectable<any>;
    private scope: ng.IScope;
    private config: IMonacoConfig;
    // This variable set the way that module handle with access (if it's true, user needs to has explicit role revoked inside group)
    private permissiveMode: boolean = false;
    private helperService: HelperService;

    constructor($scope: ng.IScope, $injector: ng.Injectable<any>) {
        this.scope = $scope;
        this.injector = $injector;
        this.restService = $injector.get('RestService');
        this.notificationService = $injector.get('NotificationService');
        this.config = $injector.get('config');
        this.helperService = $injector.get('HelperService');
        // Enable all access to developer and qa environment.
        if (this.config.environment === 'dev') this.permissiveMode = false;

        this.timeout = 120000;
    }

    public async isFormActionAllowed(formName: string, action: SelectorModel): Promise<boolean> {
        try {
            if (!formName || !action) return this.permissiveMode;

            const { data: rc } = await this.helperService.get('/generic/value/user_role_module/true', null, 120000);
            const moduleList: SelectorModel[] = (rc && rc.data) ? rc.data : [];

            let module: SelectorModel = null;

            if (moduleList && moduleList.length > 0) {
                module = moduleList.find(x => x.NAME === formName);
            }

            if (module) {
                const allowed: boolean = await this.isActionAllowed(module, action);
                return allowed;
            }
            return this.permissiveMode;
        } catch (ex) {
            return this.permissiveMode
        }
    }

    public async isActionAllowed(module: SelectorModel, action: SelectorModel): Promise<boolean> {
        try {
            if (!module || !action) return this.permissiveMode;

            const request = {
                module: module,
                action: action,
                timeout: this.timeout
            };

            const rc = await this.helperService.post(`/permission/action`, request, this.timeout);
            if (rc.status !== 200) return this.permissiveMode;

            return (rc.data) ? rc.data.data : this.permissiveMode;
        } catch (ex) {
            return this.permissiveMode;
        }
    }

    public async isRoleAllowed(role: string): Promise<boolean> {
        try {
            if (!role) return this.permissiveMode;

            const request = {
                role: role,
                timeout: this.timeout
            };

            const rc = await this.helperService.post(`/permission/role`, request, this.timeout);
            if (rc.status !== 200) return this.permissiveMode;

            return (rc.data) ? rc.data.data : this.permissiveMode;
        } catch (ex) {
            return this.permissiveMode
        }
    }

    public async isMenuRoleAllowed(role: string): Promise<boolean> {
        try {
            if (!role) return this.permissiveMode;

            let result: boolean = this.permissiveMode;
            const factory = PermissionServiceMenuCriteriaFactory.Instance(this.scope, this.injector);

            const permissionList: PERMISSION[] = await factory.getCriteriaList();

            if (permissionList && permissionList.length > 0) {
                const permission: PERMISSION = permissionList.find(x => x.ROLE === role);
                result = (permission) ? permission.ALLOW : this.permissiveMode;
            }

            console.log("ROLE: " + role + " PERMISSION: " + result);

            return result;
        } catch (ex) {
            return this.permissiveMode
        }
    }

    public showBlockMessage(): void {
        this.notificationService.error("<b>Você não tem permissão para executar esta ação. Entre em contato com o seu gestor!!!</b>");
    }
}

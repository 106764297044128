
import * as angular from 'angular';
import { CompareUtils } from './util/CompareUtils';
import { NotificationConstants } from './util/NotificationConstants';

smartLockDirective.$inject = ['$rootScope'];

export function smartLockDirective($rootScope) {

    let firstVal;
    let lastVal;
    let hasChanged = false;

    let ddo: any = {};

    ddo.restrict = 'A';

    ddo.scope = { model: '=' };

    ddo.link = ($scope, $element, $attr, controller, trasncludeFn) => {

        $rootScope.back = () => {

            if ($rootScope.change) {
                let result = confirm(NotificationConstants.CHANGE_ROUTE);

                if (result) {
                    $scope.$parent.operation = 'list';
                    $rootScope.change = false;
                } else {
                    return false;
                }

            } else {
                $scope.$parent.operation = 'list';
            }

        }

        $scope.$watch(() => {

            if (firstVal) lastVal = angular.copy($scope.model);

            if (lastVal) {
                let object = new CompareUtils();
                object.deepDiff(firstVal, lastVal);
                $rootScope.change = object.hasChanged();
            }

            window.onbeforeunload = (e) => { };

            if ($rootScope.change) {
                window.onbeforeunload = (e) => {
                    e = e || window.event;
                    if (e) e.returnValue = NotificationConstants.CHANGE_ROUTE;
                    return NotificationConstants.CHANGE_ROUTE;
                };
            }

        });

        $scope.$parent.$watch("operation", (newVal: string, oldVal: string) => {

            if (newVal == 'edit') firstVal = angular.copy($scope.model);
            if (newVal == 'list') {
                $rootScope.change = false;
                firstVal = undefined;
                lastVal = undefined;
            }

        });

    }

    return ddo;

}

function toHash(value) {
    var hash = 0, i, chr;
    if (value.length === 0) return hash;
    for (i = 0; i < value.length; i++) {
        chr = value.charCodeAt(i);
        hash = ((hash << 5) - hash) + chr;
        hash |= 0; // Convert to 32bit integer
    }
    return hash;
};

import { SelectorModel } from "../../common/model/SelectorModel";

interface IOperation extends ng.IScope {
    operation: string;
}

interface IMonacoUiCheckbox extends ng.IScope {
    positive: SelectorModel,
    negative: SelectorModel,
}

monacoUiCheckbox.$inject = []
export function monacoUiCheckbox(): ng.IDirective {

    const ddo: ng.IDirective = {
        restrict: 'E',
        scope: {
            id: '@',
            model: '=model',
            title: '@',
        },
        template: require("../view/template/monaco-ui-checkbox.html"),
        link: ($scope: IMonacoUiCheckbox) => {

            $scope.positive = {
                ID: '1',
                NAME: 'Sim'
            }

            $scope.negative = {
                ID: '2',
                NAME: 'Não'
            }


        }
    }

    return ddo;
}

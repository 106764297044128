import * as angular from "angular";
import { IColumnDef, IGridOptions } from "ui-grid";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IModalService } from "@services/ModalService";
import { DataOperationalService } from "@services/DataOperationalService";
import { IDocumentError } from "@models/interface/common/IDocumentError";
import { GenericModel } from "../model/GenericModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { IMonacoColumnDef } from "@services/GridService2";
import { ISessionService } from "@services/SessionService";
import { IGenericModel } from '@models/common/IGenericModel';
import { EOperation } from "@enums/GenericData";
import { SSEService } from '@appServices/SSEService';
import { HelperService } from "@services/HelperService";

interface IGenericScope extends IGridFormServiceScope {
    gridOptions: IGridOptions;
    model: IGenericModel;
    typeList: any;
    newIdentifier: string,
    modalError: string;
    modalSuccess: string;
    scopeBeforeSave: GenericModel;
    user: any;
    sessionService: ISessionService;
    modalAddType: () => void;
    addNewType: (type: string) => void;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
    // action functions
    viewGenericRegister: (genericRegister: IGenericModel) => Promise<void>;
    editGenericRegister: (genericRegister: IGenericModel) => void;
    copyGenericRegister: (genericRegister: IGenericModel) => Promise<void>;
}

export class GenericRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IGenericScope;
    private $timeout: ng.ITimeoutService
    private $q: ng.IQService;
    private ModalService: IModalService;
    private modalID: number;
    private dataOperationalService: DataOperationalService;
    private gridName: string;
    private SSEService: SSEService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IGenericScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$timeout = $injector.get('$timeout');
        this.$q = $injector.get('$q');
        this.$scope.sessionService = $injector.get('SessionService');
        this.ModalService = $injector.get('ModalService');
        this.dataOperationalService = $injector.get('DataOperationalService');
        this.gridName = 'GRID_GENERIC_REGISTER';
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.helperService = $injector.get('HelperService');
    }

    private getUrlHelper(): string {
        const baseRoute = '/helper';
        const urlProduct = this.config.helperUrl + baseRoute;
        return urlProduct;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlHelper();
            this.initForm(this, 'form', 'generic', 'GENERAL.MENU.GENERIC', true);
            await this.initGrid(this.gridName, '/generic/list', true);
            await this.getGenericTypeList();
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.modalAddType = () => {
            this.modalAddType();
        }

        this.$scope.addNewType = (type) => {
            this.addNewType(type);
        }

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }

        this.$scope.viewGenericRegister = async (genericRegister: IGenericModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.fetchData(genericRegister.ID, EOperation.VIEW);
        }

        this.$scope.editGenericRegister = (genericRegister: IGenericModel): void => {
            this.editGenericRegister(genericRegister);
        }

        this.$scope.copyGenericRegister = async (genericRegister: IGenericModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(genericRegister);
            this.fetchData(genericRegister.ID, EOperation.COPY);
        }
    }

    private async modalAddType(): Promise<void> {
        const self: GenericRegisterController = this;
        await this.ModalService.showModalInfo(
            {
                template: require("../view/genericType.html"),
                scope: self.$scope,
            },
            {
                actionButtonText: 'GENERAL.CLOSE',
                headerText: 'REGISTRATION.ADD_GENERIC_IDENTIFIER',
            });
        this.$scope.modalError = null;
        this.$scope.modalSuccess = null;
        this.$scope.newIdentifier = null;
    }

    private async addNewType(type: string): Promise<void> {
        try {
            this.block();
            const request = {
                data: {
                    TYPE: type
                }
            };
            const result = await this.registerNewGenericType(request);
            if (result.status === 200) {
                await this.getGenericTypeList();
                const elementMatch = $("[name='genericTypeSelector']").find('span.ui-select-toggle');
                this.$scope.modalError = null;
                this.$scope.modalSuccess = this.formService.getTranslate('REGISTRATION.GENERIC_IDENTIFIER_SUCCESSFULLY_REGISTERED');
                this.$scope.model.IDENTIFIER = result.data.data.TYPE;

                elementMatch.removeClass('ng-invalid').addClass('ng-valid');
            }
        } catch (ex) {
            this.$scope.modalSuccess = null;
            if (ex.status && ex.status === 409) {
                this.$scope.modalError = this.formService.getTranslate('REGISTRATION.GENERIC_IDENTIFIER_NAME_IN_USE');
            } else {
                if (ex.status && ex.status === -1) {
                    this.$scope.modalError = this.formService.getTranslate('REGISTRATION.SERVER_NOT_RESPOND');
                } else this.$scope.modalError = this.formService.getTranslate('REGISTRATION.ERROR_OCURRED', { prop: ex.message });
            }
        }
        await this.$scope.$applyAsync();
        this.unblock();
    }

    private async getGenericTypeList(limit?: number): Promise<void> {
        let result = [];
        if (limit) {
            const { data: request } = await this.helperService.get(`/generic/type/list/${limit}`, null, 10000);
            result = request && request.data ? request.data : [];
        }
        else {
            const { data: request } = await this.helperService.get(`/generic/type/list`, null, 10000);
            result = request && request.data ? request.data : [];
        }
        this.$scope.typeList = result;
    }

    private registerNewGenericType(data: any): Promise<any> {
        return this.helperService.post(`/generic/type/insert`, data, 10000);
    }

    private async getGenericById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const { data: generic } = await this.helperService.get(`/generic/getById/${id}`, null, 30000);
            return generic && generic.data ? generic.data : null;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    initGridColumns(columns: string[]): IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewGenericRegister(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editGenericRegister(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyGenericRegister(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="(row.entity.DOCUMENT_ERROR && row.entity.DOCUMENT_ERROR.length) ? 'text-danger' : 'text-green'" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: 100,
            cellTemplate: (view + edit + copy + modalIntegration),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };

        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 90 };
            const colIdentifier: IMonacoColumnDef = { name: "IDENTIFIER", displayName: "REGISTRATION.GENERIC_IDENTIFIER", width: 300 };
            const colCode: IMonacoColumnDef = { name: "CODE", displayName: "GENERAL.GENERIC_CODE", width: 115 };
            const colValue: IMonacoColumnDef = { name: "VALUE", displayName: "GENERAL.GENERIC_VALUE", width: 300 };
            const colAlternative: IMonacoColumnDef = { name: "ALTERNATIVE", displayName: "GENERAL.GENERIC_ALTERNATIVE_VALUE", width: 415 };
            const colOrder: IMonacoColumnDef = { name: "ORDER", displayName: "GENERAL.GENERIC_ORDER", width: 300 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 120, cellFilter: "YesOrNo", filterCellFiltered: true };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'IDENTIFIER':
                        columnDefs.push(colIdentifier);
                        break;
                    case 'CODE':
                        columnDefs.push(colCode);
                        break;
                    case 'VALUE':
                        columnDefs.push(colValue);
                        break;
                    case 'ALTERNATIVE':
                        columnDefs.push(colAlternative);
                        break;
                    case 'ORDER':
                        columnDefs.push(colOrder);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                };
            }

            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            IDENTIFIER: null,
            VALUE: null,
            CODE: null,
            ALTERNATIVE: null,
            ORDER: null,
            ACTIVE: true,
            DOCUMENT_ERROR: null
        };
    }

    initDependencies(): Promise<any> {
        const self: GenericRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([]).then((result: any) => {
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    async register(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} (${this.$scope.model.ID})`;
            BrowserTitle.$id = this.$scope.model.VALUE;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} (${this.$scope.model.ID})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.VALUE;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async editGenericRegister(genericRegister: IGenericModel): Promise<void> {
        let blockedObject = {
            ID: genericRegister.ID,
            NAME: genericRegister.ID.toString(),
            EMAIL: this.$scope.user['email'],
            FORM_NAME: this.gridName
        };
        this.SSEService.closeEvents();
        this.SSEService.setBlockedObject(blockedObject);
        this.SSEService.initEvents();
        this.SSEService.events.onmessage = async (event) => {
            const parsedData = JSON.parse(event.data);
            if (!parsedData.status) {
                const result = await this.SSEService.generate(parsedData);
                if (result && !result.status) {
                    this.$rootScope.refreshPage();
                    return;
                }
                if (this.$scope.operation !== EOperation.VIEW || genericRegister.ID !== this.$scope.model.ID) this.fetchData(genericRegister.ID, EOperation.VIEW);
            } else if (this.$scope.operation !== EOperation.EDIT || genericRegister.ID !== this.$scope.model.ID) {
                this.fetchData(genericRegister.ID, EOperation.EDIT);
            }
        };
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        const operation = this.$scope.operation === 'register' ? 'insert' : 'update';
        return {
            route: `/generic/${operation}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    private async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const data = await this.getGenericById(id);
            if (data) {
                const model = angular.copy(data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);

            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "Integration Operation/Produto" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                const rc = await this.dataOperationalService.post(`/sync/generic`, { "id": [id] }, 120000);
                if (rc && rc.data && rc.data.data && rc.status == 200) success = true;
            }
        } catch (ex) {
            const msgError = this.formService.getTranslate('GENERAL.ERROR_SENDING_REQUEST');
            this.formService.handleError(msgError);
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const legalPersonData = await this.getGenericById(id);
                    if (row && legalPersonData && legalPersonData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = legalPersonData.DOCUMENT_ERROR;
                        documentError = legalPersonData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            const msgError = this.formService.getTranslate('GENERAL.ERROR_DURING_REQUEST');
            this.formService.handleError(msgError);
        } finally {
            return documentError;
        }
    }
}

import * as angular from "angular";
import { IConsolidatedModel, IExternalAgent } from "WBA-Model/dist/interface/operational/consolidated/Consolidated";
import { ISelectorModel } from "WBA-Model/dist/mongo/SelectorModel";
import { IMonacoRequest } from "@services/GridFormService";
import { DataProcessService } from "@services/DataProcessService";
import { IModalOptions, IModalService } from "@services/ModalService";
import { HelperService } from "@services/HelperService";
import { ProductService } from "@services/ProductService";
import { OperationalService } from "@services/OperationalService";
import { IFormServiceScope, FormService2 } from "@services/FormService2";
import { PermissionService } from '@appServices/PermissionService';
import { ValidateUtil } from "../../common/util/ValidateUtil";
import { IDateOptions } from "../../common/model/DateOptions";
import { EOperation, EConsolidatedStatus } from '@enums/GenericData';
import { IConsolidatedScope } from "./ConsolidatedRegisterController";

export interface IConsolidatedWizardModalScope extends IFormServiceScope {
    // Model
    model: IConsolidatedModel;
    oldModel: IConsolidatedModel;

    // Modal
    modalOptions: IModalOptions;

    // List
    flightList: ISelectorModel[];
    consolidatedStatusList: ISelectorModel[];
    agentList: IExternalAgent[];

    // Date Options
    dateOptionsEta: IDateOptions;
    amountProcess: number;

    // Services
    $q: ng.IQService;
    $filter: ng.FilterFactory;
    $sce: angular.ISCEService;
    $timeout: ng.ITimeoutService;
    formService: FormService2;
    operationalService: OperationalService;
    dataProcessService: DataProcessService;
    productService: ProductService;
    helperService: HelperService;

    // Field Changes
    specEtdDateChange: () => void;
    hasChanges: () => boolean;

    // Validation
    validateAwbNumber: () => void;

    // Search
    getAgentListByName: (search: string) => Promise<void>;
    getFlightListByName: (search: string) => Promise<void>;

    // Request Consolidated
    updateConsolidated: (closeModal: boolean) => Promise<void>;

    // Modal
    closeModal: (result: boolean) => void;

    hasInvalidRequiredElements: (elementId: string) => boolean;
    openConsolidatedEditModal: () => Promise<void>;

    disabledConsolidated: () => boolean;
    mensagemCannotChangeConsolStatus: () => void;
    getConsolidatedById: (consolidatedId: number) => Promise<IConsolidatedModel>;
}

export class ConsolidatedWizardModalController extends FormService2 {
    static $inject: string[] = ['$injector', '$scope'];
    private scope: IConsolidatedWizardModalScope;
    private PermissionService: PermissionService;
    private ModalService: IModalService;
    private $consolidatedScope: IConsolidatedScope;
    private mustUpdateGridAfterClose: boolean;

    constructor($injector: ng.Injectable<any>, $scope: IConsolidatedWizardModalScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.scope.$q = $injector.get('$q');
        this.scope.dataProcessService = $injector.get('DataProcessService');
        this.scope.helperService = $injector.get('HelperService');
        this.scope.productService = $injector.get('ProductService');
        this.scope.operationalService = $injector.get('OperationalService');
        this.ModalService = $injector.get('ModalService');
        this.PermissionService = new PermissionService(this.scope, $injector);
        this.$consolidatedScope = <IConsolidatedScope>$scope.$parent.$parent;
        this.initDateOptions();
    }

    private initDateOptions() {
        this.scope.dateOptionsEta = <IDateOptions>{
            formatYear: 'yy',
            minDate: (this.scope.model.ETD_DATE) ? this.scope.model.ETD_DATE : null,
            maxDate: null,
            startingDay: 1
        }
    }

    public async $onInit(): Promise<void> {
        try {
            this.block();
            this.init("consolidatedWizardModalForm", null, null);
            this.initialize();
            this.unblock();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private async initialize(): Promise<void> {
        this.mustUpdateGridAfterClose = false;
        this.initDependencies();
        this.initScopeFunctions();
        await this.updateAwbNumber();
        this.scope.oldModel = angular.copy(this.scope.model);
    }

    public async initDependencies(): Promise<boolean> {
        const self: ConsolidatedWizardModalController = this;

        const promises = {
            consolidatedStatus: self.getGenericValue("consolidated_status"),
        };

        return new Promise((resolve, reject) => {
            self.scope.$q.all(promises).then((result) => {
                self.scope.consolidatedStatusList = result.consolidatedStatus;
                resolve(true);
            }).catch((ex) => {
                reject(ex);
            });
        });
    }

    public initScopeFunctions(): void {
        // Search
        this.scope.getAgentListByName = (search: string) => this.getAgentListByName(search);
        this.scope.getFlightListByName = (search: string) => this.getFlightListByName(search);

        // Field Changes
        this.scope.specEtdDateChange = () => this.specEtdDateChange();
        this.scope.hasChanges = () => {
            return this.hasChanges();
        }

        // Validation
        this.scope.validateAwbNumber = () => this.validateAwbNumber();

        // Request Consolidated
        this.scope.updateConsolidated = (closeModal: boolean) => this.updateConsolidated(closeModal);

        // Modal
        this.scope.closeModal = (result: boolean) => this.closeModal(result);

        this.scope.hasInvalidRequiredElements = (elementId: string) => {
            return this.hasInvalidRequiredElements(elementId);
        }

        this.scope.openConsolidatedEditModal = () => {
            return this.openConsolidatedEditModal();
        }

        this.scope.disabledConsolidated = () => {
            return this.disabledConsolidated();
        }

        this.scope.mensagemCannotChangeConsolStatus = () => {
            this.mensagemCannotChangeConsolStatus();
        }

        this.scope.getConsolidatedById = async (consolidatedId: number): Promise<IConsolidatedModel> => {
            return this.getConsolidatedById(consolidatedId)
        }
    }

    private async getAgentListByName(search: string): Promise<void> {
        try {
            if (search && search.length >= 3) {
                this.block();

                const agentSelectorList: IExternalAgent[] = [];
                const request: IMonacoRequest = {
                    data: {
                        search,
                        products: (this.scope.model.PRODUCT && this.scope.model.PRODUCT.ID) ? [this.scope.model.PRODUCT.ID] : []
                    },
                    route: `/agent/list/custom`,
                    timeout: 30000,
                };

                const rc = await this.scope.productService.post(request, false);
                const agentList = (rc && rc.data && rc.data.data && rc.data.data.data) ? rc.data.data.data : null;

                for (const item of agentList) {
                    const agentSelector: IExternalAgent = {
                        ID: item.ID,
                        NAME: item.NAME,
                        CODE: item.NETWORK_ID,
                        LEGAL_PERSON_ID: item.LEGAL_PERSON_ID,
                        LEGAL_PERSON_NAME: item.LEGAL_PERSON_NAME,
                        NETWORK_ID: item.NETWORK_ID
                    }

                    agentSelectorList.push(agentSelector);
                }

                this.scope.agentList = agentSelectorList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getFlightListByName(search: string): Promise<void> {
        try {
            if (search && search.length >= 3) {
                this.block();

                const flightSelectorList: ISelectorModel[] = [];
                const request: IMonacoRequest = {
                    data: {
                        flightCode: search,
                        isSpecific: false
                    },
                    route: `/airFlight/list/custom`,
                    timeout: 30000,
                };

                const rc = await this.scope.operationalService.post(request.route, request.data, request.timeout);
                const agentList = (rc && rc.data && rc.data.data && rc.data.data.data) ? rc.data.data.data : null;

                for (const item of agentList) {
                    const agentSelector: ISelectorModel = {
                        ID: item.ID,
                        NAME: item.FLIGHT_CODE,
                        CODE: item.CODE
                    }

                    flightSelectorList.push(agentSelector);
                }

                this.scope.flightList = flightSelectorList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getGenericValue(typeGeneric: string, alternative?: boolean): Promise<ISelectorModel[]> {
        try {
            const timeout: number = 10000;
            const route: string = (alternative) ? `/generic/value/${typeGeneric}/${alternative}` : `/generic/value/${typeGeneric}`;

            const rc = await this.scope.helperService.get(route, null, timeout);
            const result: ISelectorModel[] = (rc && rc.data && rc.data.data) ? rc.data.data : [];

            return result;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private validateAwbNumber(): void {
        try {
            if (this.scope.model.AWB_NUMBER) {
                const validation: boolean = ValidateUtil.isAWBNumber(this.scope.model.AWB_NUMBER);
                if (!validation) {
                    this.handleError(this.getTranslate('REGISTRATION.INVALID_AWB_NUMBER'));
                    this.scope.model.AWB_NUMBER = null;
                } else {
                    this.updateAwbNumber();
                }
            } else {
                this.scope.model.CONSOLIDATED_EVENT.CARGO_AI_TRACKING = null;
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async updateAwbNumber(): Promise<void> {
        try {
            this.block();

            const request: IMonacoRequest = {
                data: {
                    awbNumber: this.scope.model.AWB_NUMBER
                },
                route: `/consolidatedEvent/cargoAITracking`,
                timeout: 12000
            }
            const rc = await this.scope.dataProcessService.post(request.route, request.data, request.timeout);
            const resultOperation = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            if (resultOperation) {
                this.scope.model.CONSOLIDATED_EVENT.CARGO_AI_TRACKING = resultOperation;
            } else {
                this.scope.model.CONSOLIDATED_EVENT.CARGO_AI_TRACKING = null;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private specEtdDateChange(): void {
        try {
            this.scope.dateOptionsEta.minDate = this.scope.model.ETD_DATE;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private hasChanges(): boolean {
        return !angular.equals(this.scope.model, this.scope.oldModel);
    }

    private async updateConsolidated(closeModal: boolean): Promise<void> {
        try {
            this.block();
            const permission = await this.PermissionService.isRoleAllowed("BUYBACK");
            if (!permission) return this.PermissionService.showBlockMessage();

            const hasInvalid = this.hasInvalidRequiredElements("consolidatedWizardModalForm");
            if (hasInvalid) return;

            const request: IMonacoRequest = {
                data: {
                    data: this.scope.model,
                    oldData: this.scope.oldModel
                },
                route: `/consolidated/update`,
                timeout: 12000
            }

            const result = await this.scope.dataProcessService.post(request.route, request.data, request.timeout);
            if (!closeModal && result && result.status == 200) {
                this.mustUpdateGridAfterClose = true;
                this.scope.oldModel = angular.copy(this.scope.model);
                this.notifySuccess(this.getTranslate("OPERATIONAL.UPDATE_CONSOLIDATED_SUCCESS"));
            }

            if (closeModal) this.scope.modalOptions.ok(true);
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async closeModal(result: boolean): Promise<void> {
        try {
            if (this.hasChanges()) {
                const confirm = await this.ModalService.showModalConfirmation({}, {
                    headerText: "GENERAL.CLOSE",
                    bodyText: this.getTranslate("REGISTRATION.MESSAGES.ERROR.UPDATE_NOT_SAVED"),
                    actionButtonText: "REGISTRATION.SAVE_CONTINUE",
                    closeButtonText: "GENERAL.CLOSE"
                });
                if (confirm) return this.updateConsolidated(true);
            }
            this.scope.modalOptions.ok({ mustUpdateGrid: this.mustUpdateGridAfterClose, result});
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private hasInvalidRequiredElements(elementId: string): boolean {
        if (!elementId) return false;
        const isInvalid = FormService2.hasRequiredElements('#' + elementId);
        if (isInvalid) this.scope.formService.notifyError(this.scope.formService.getTranslate("GENERAL.ALL_FIELDS_MANDATORY"));
        return isInvalid;
    }

    private async openConsolidatedEditModal(): Promise<void> {
        try {
            const hasInvalid = this.hasInvalidRequiredElements("consolidatedWizardModalForm");
            if (hasInvalid) return;

            const model = JSON.parse(JSON.stringify(this.scope.model));

            const modalId: number = this.ModalService.newModal();
            const modalInstance = await this.ModalService.showModalInfo(
                {
                    modalID: modalId,
                    template: require("../view/modal/consolidatedRegisterModal.html"),
                    formService: EOperation.EDIT,
                    size: 'lg',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: 'OPERATIONAL.CONSOL'
                },
                {
                    model
                }
            );

            const modalScope = await this.ModalService.getModalScope(modalId);
            await modalScope.$applyAsync();

            const apply = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (apply) {
                await this.repurchase(model);
                this.scope.$applyAsync();
            }

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async repurchase(model: IConsolidatedModel): Promise<void> {
        try {
            const modalID = this.ModalService.newModal();
            const modalInstance = await this.ModalService.showModalInfo(
                {
                    template: require("../view/wizardRepurchase.html"),
                    modalID: modalID,
                    formService: EOperation.EDIT,
                    size: 'vlg modal-overflow',
                    scope: this.scope
                },
                {
                    actionButtonText: "GENERAL.CLOSE",
                    headerText: 'GENERAL.REPURCHASE',
                }, {
                consolidatedId: model.ID,
                isConsolidated: true,
                isReprocessingConsolidated: true,
                consolidatedModel: model,
                consolidatedOldModel: this.scope.model,
                shipmentDate: model.ETD_DATE
            });

            const modalScope = await this.ModalService.getModalScope(modalID);
            await modalScope.$applyAsync();

            const apply = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (apply) {
                this.ModalService.closeModal(this.$consolidatedScope.consolidatedModalId);
                await this.$consolidatedScope.editConsolidated(this.scope.model);
            }

        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getConsolidatedById(consolidatedId: number): Promise<IConsolidatedModel> {
        try {
            if (!consolidatedId) this.notifyError('Missing consolidatedId in getConsolidatedById');
            this.block();

            const timeout: number = 120000;

            const rc = await this.scope.dataProcessService.get(`/consolidated/getById/${consolidatedId}`, timeout);
            const result: IConsolidatedModel = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            return result;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    disabledConsolidated = () => {
        try {
            return (this.scope.model.SITUATION && this.scope.model.SITUATION.ID == EConsolidatedStatus.FINISHED);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    mensagemCannotChangeConsolStatus = () => {
        try {
            const situations = [EConsolidatedStatus.FINISHED, EConsolidatedStatus.APPROVED];

            if (this.scope.model.SITUATION && situations.some(situation => this.scope.model.SITUATION.ID == situation) && !this.scope.model.AWB_NUMBER) {
                this.scope.model.SITUATION = this.scope.consolidatedStatusList.find(item => item.ID == EConsolidatedStatus.OPEN);
                this.notifyWarning(this.getTranslate("OPERATIONAL.CANNOT_CHANGE_CONSOL_STATUS"));
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }
}
import * as angular from "angular";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IAgentModel } from "../model/AgentModel";
import { SelectorModel } from "../../common/model/SelectorModel";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { ISessionService } from "@services/SessionService";
import { ILegalPersonModel, ILegalPersonExchangeData, ILegalPersonSelector, ILegalPersonAddress } from "../model/LegalPersonModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { SSEService } from "../../app/services/SSEService";
import { ILinkParameter } from "../../common/model/ModelParameter";
import { StringUtil } from "../../common/util/StringUtil";
import { IModalService } from "@services/ModalService";
import { IDocumentError } from "@models/interface/common/IDocumentError";
import { EOperation } from "@enums/GenericData";
import { HelperService } from "@services/HelperService";
import { IFileItem, IFormData, ISuccessItemResponse, IUploadItem, IUploader } from "WBA-Model/dist/interface/common/IMonacoUpload";
import { fileUploader as FileUploader } from 'angular-file-upload';

interface IAgentScope extends IGridFormServiceScope {
    model: IAgentModel;
    log: IViewLog;
    productList: SelectorModel[];
    houseFormList: SelectorModel[];
    situationList: SelectorModel[];
    legalPersonList: ILegalPersonSelector[];
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IAgentModel;
    user: any;
    sessionService: ISessionService;
    uploader: IUploader;
    editAgent: (agent: IAgentModel) => Promise<void>;
    viewAgent: (agent: IAgentModel) => Promise<void>;
    viewLogAgent: (agent: IAgentModel) => Promise<void>;
    copyAgent: (agent: IAgentModel) => Promise<void>;
    getLegalPersonListByName: (search: string) => Promise<void>;
    goToLegalPerson: (id: number) => void;
    limitLines: (value: string, limit: number) => string;
    selectLegalPerson: (legalPerson: ILegalPersonModel) => void;
    correspondenceGenerate: () => void;
    openModalIntegration: (id: number, documentErrorList: IDocumentError[], name: string) => void;
    removeUpload: (model: IUploadItem) => boolean;
    disableSituation: () => boolean;
}

export class AgentRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IAgentScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private SSEService: SSEService;
    private gridName: string;
    private ModalService: IModalService;
    private modalID: number;
    private helperService: HelperService;
    private formName: string;
    private fileUploader: FileUploader;

    constructor($injector: ng.Injectable<any>, $scope: IAgentScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.gridName = 'GRID_AGENT';
        this.modalID = 0;
        this.ModalService = $injector.get('ModalService');
        this.helperService = $injector.get('HelperService');
        this.fileUploader = $injector.get('FileUploader');
        this.formName = 'agent';
    }

    async $onInit(): Promise<void> {
        try {
            this.$scope.uploader = this.getFileUploaderDefault();
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', this.formName, 'GENERAL.MENU.AGENT', true);
            await this.initGrid(this.gridName, '/agent/list', true, true, null, true, true);

            const sessionParameter = this.$gridService.$sessionParameter;
            if (sessionParameter && sessionParameter.data) this.callSessionFunctions(sessionParameter.data);

        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.getLegalPersonListByName = async (search: string) => {
            let legalPersonSelectorList: ILegalPersonSelector[] = [];
            if (search && search.length >= 3) {
                legalPersonSelectorList = await this.getLegalPersonListByName(search);
            }
            this.$scope.legalPersonList = legalPersonSelectorList;
        }

        this.$scope.goToLegalPerson = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.legalPerson", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.limitLines = (value: string, limit: number) => {
            return StringUtil.limitLines(value, limit);
        }

        this.$scope.selectLegalPerson = (legalPerson: ILegalPersonModel) => {
            if (this.$scope.model.NAME == null || this.$scope.model.NAME == "") this.$scope.model.NAME = legalPerson.SHORT_NAME;
        }

        this.$scope.correspondenceGenerate = async () => {
            try {
                const legalPerson = this.$scope.model.LEGAL_PERSON
                if (!legalPerson) throw Error(this.formService.getTranslate("PRODUCT.SELECT_LEGAL_PERSON_FIRST"));

                const legalPersonAddress: ILegalPersonAddress = await this.getLegalPersonAddressById(legalPerson.ID)

                if (legalPersonAddress) {
                    const idFiscal = legalPersonAddress.ID_FISCAL;
                    const fantasyName = legalPersonAddress.FANTASY_NAME;
                    const city = legalPersonAddress.ADDRESS.CITY;

                    const publicPlace = legalPersonAddress.ADDRESS.PUBLIC_PLACE;
                    const number = legalPersonAddress.ADDRESS.NUMBER;
                    const zipCode = legalPersonAddress.ADDRESS.ZIP_CODE;
                    const complement = legalPersonAddress.ADDRESS.COMPLEMENT;
                    const district = legalPersonAddress.ADDRESS.DISTRICT;

                    let string = '';
                    if (publicPlace && publicPlace != '') string += `${publicPlace}`;
                    if (number && number !== '') string += `${string !== '' ? ' ' : ''}${number}`;
                    if (complement && complement !== '') string += `${string !== '' ? ' ' : ''}${complement}`;
                    if (district && district !== '') string += `${string !== '' ? ', ' : ''}${district}`;

                    let address = `${fantasyName}` + "\n"
                    if (string !== '') address += string + "\n";
                    address += `${city.NAME}, ${city.PROVINCE.CODE} - ${city.PROVINCE.COUNTRY.NAME}` + (zipCode && zipCode !== '' ? ` CEP: ${zipCode}` : '');
                    address += "\n" + `${legalPersonAddress.TYPE.NAME}: ${idFiscal}`

                    if (!publicPlace || !district) this.formService.notifyWarning(this.formService.getTranslate("REGISTRATION.MESSAGES.ERROR.MISSING_ADDRESS_IN_LEGAL_PERSON_TO_GENERATE_CORRESPONDENCE"));
                    this.$scope.model.CORRESPONDENCE = address;
                } else throw Error(this.formService.getTranslate("REGISTRATION.MESSAGES.ERROR.LEGAL_PERSON_NOT_FOUND"));
            } catch (ex) {
                this.formService.handleError(ex);
            }
        }

        this.$scope.editAgent = async (agent: IAgentModel): Promise<void> => {
            let blockedObject = {
                ID: agent.ID,
                NAME: agent.NAME,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || agent.ID !== this.$scope.model.ID) this.fetchData(agent.ID, EOperation.VIEW);
                } else if (this.$scope.operation !== EOperation.EDIT || agent.ID !== this.$scope.model.ID) {
                    this.fetchData(agent.ID, EOperation.EDIT);
                }
            };
        }

        this.$scope.viewAgent = async (agent: IAgentModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.fetchData(agent.ID, EOperation.VIEW);
        }

        this.$scope.viewLogAgent = async (agent: IAgentModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(agent);
        }

        this.$scope.copyAgent = async (agent: IAgentModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.fetchData(agent.ID, EOperation.COPY);
        }

        this.$scope.openModalIntegration = (id: number, documentErrorList: IDocumentError[]) => {
            this.openModalIntegration(id, documentErrorList);
        }

        this.$scope.removeUpload = (model: IUploadItem): boolean => {
            return this.removeUpload(model);
        }

        this.$scope.uploader.onSuccessItem = (item: IFileItem, response: ISuccessItemResponse): void => {
            this.onSuccessItem(item, response);
        }

        this.$scope.disableSituation = (): boolean => {
            if (this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.find(product => product.ID == "IM")) return !this.$scope.model.APPOINTMENT_LETTER;
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewAgent(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editAgent(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogAgent(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyAgent(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy + modalIntegration),
            enableCellEdit: true,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };

        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }
    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colName: IMonacoColumnDef = { name: "NAME", displayName: "GENERAL.NAME", width: 300 };
            const colScac: IMonacoColumnDef = { name: "SCAC_CODE", displayName: "GENERAL.SCAC_CODE", width: 200 };
            const colCorporateName: IMonacoColumnDef = { name: "LEGAL_PERSON.CORPORATE_NAME", displayName: "ENTITY.CORPORATE_NAME", width: 300 };
            const colAgreement: IMonacoColumnDef = { name: "AGREEMENT", displayName: "Agreement", width: 150, cellFilter: "YesOrNo" };
            const colAgreementValidity: IMonacoColumnDef = { name: "AGREEMENT_VALIDITY", displayName: "PRODUCT.AGREEMENT_VALIDITY", width: 250, cellFilter: 'date:\'dd/MM/yyyy\'' };
            const colAppointmentLetter: IMonacoColumnDef = { name: "APPOINTMENT_LETTER", displayName: "PRODUCT.APPOINTMENT_LETTER", width: 150, cellFilter: "YesOrNo" };
            const colAppointmentLetterValidity: IMonacoColumnDef = { name: "APPOINTMENT_LETTER_VALIDITY", displayName: "PRODUCT.APPOINTMENT_LETTER_VALIDITY", width: 250, cellFilter:'date:\'dd/MM/yyyy\'' };
            const colCorporateCityName: IMonacoColumnDef = { name: "LEGAL_PERSON.ADDRESS.CITY.NAME", displayName: "GENERAL.MENU.CITY", width: 300 };
            const colCorporateProvinceName: IMonacoColumnDef = { name: "LEGAL_PERSON.ADDRESS.PROVINCE.NAME", displayName: "GENERAL.MENU.PROVINCE", width: 300 };
            const colCorporateCountryName: IMonacoColumnDef = { name: "LEGAL_PERSON.ADDRESS.COUNTRY.NAME", displayName: "GENERAL.MENU.COUNTRY", width: 300 };
            const colCorporateNewtorkName: IMonacoColumnDef = { name: "LEGAL_PERSON.ADDRESS.NETWORK.NAME", displayName: "GENERAL.MENU.NETWORK", width: 300 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT", displayName: "BASIC_DATA.PRODUCT", width: 300, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PRODUCT, null, "ID")}}</div>' };
            const colDocumentHouseForm: IMonacoColumnDef = { name: "DOCUMENT_HOUSE_FORM.NAME", displayName: "PRODUCT.DOCUMENT_HOUSE_FORM", width: 250 };
            const colShipperMaster: IMonacoColumnDef = { name: "SHIPPER_MASTER", displayName: "ENTITY.SHIPPER_MASTER", width: 250 };
            const colCneeMaster: IMonacoColumnDef = { name: "CNEE_MASTER", displayName: "ENTITY.CONSIGNEE_MASTER", width: 250 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 100, cellFilter: "YesOrNo" };
            const colNotifyMaster: IMonacoColumnDef = { name: "NOTIFY_MASTER", displayName: "ENTITY.NOTIFY_MASTER", width: 250 };
            const colSituation: IMonacoColumnDef = { name: "SITUATION.NAME", displayName: "GENERAL.SITUATION", width: 150 };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'ID_LEGAL_PERSON':
                        columnDefs.push(colCorporateName);
                        break;
                    case 'NAME':
                        columnDefs.push(colName);
                        break;
                    case 'SCAC_CODE':
                        columnDefs.push(colScac);
                        break;
                    case 'LEGAL_PERSON.ADDRESS.CITY.NAME':
                        columnDefs.push(colCorporateCityName);
                        break;
                    case 'LEGAL_PERSON.ADDRESS.PROVINCE.NAME':
                        columnDefs.push(colCorporateProvinceName);
                        break;
                    case 'LEGAL_PERSON.ADDRESS.COUNTRY.NAME':
                        columnDefs.push(colCorporateCountryName);
                        break;
                    case 'LEGAL_PERSON.ADDRESS.NETWORK.NAME':
                        columnDefs.push(colCorporateNewtorkName);
                        break;
                    case 'AGREEMENT':
                        columnDefs.push(colAgreement);
                        break;
                    case 'AGREEMENT_VALIDITY':
                        columnDefs.push(colAgreementValidity);
                        break;
                    case 'APPOINTMENT_LETTER':
                        columnDefs.push(colAppointmentLetter);
                        break;
                    case 'APPOINTMENT_LETTER_VALIDITY':
                        columnDefs.push(colAppointmentLetterValidity);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'DOCUMENT_HOUSE_FORM':
                        columnDefs.push(colDocumentHouseForm);
                        break;
                    case 'SHIPPER_MASTER':
                        columnDefs.push(colShipperMaster);
                        break;
                    case 'CNEE_MASTER':
                        columnDefs.push(colCneeMaster);
                        break;
                    case 'NOTIFY_MASTER':
                        columnDefs.push(colNotifyMaster);
                        break;
                    case 'SITUATION':
                        columnDefs.push(colSituation);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            ID_LEGAL_PERSON: null,
            LEGAL_PERSON: null,
            PRODUCT: null,
            NAME: null,
            SCAC_CODE: null,
            SHIPPER_MASTER: null,
            CNEE_MASTER: null,
            NOTIFY_MASTER: null,
            REMARKS_MASTER: null,
            CORRESPONDENCE: null,
            ACTIVE: true,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            SEARCH_FIELDS: null,
            DOCUMENT_ERROR: null,
            AGREEMENT: false,
            AGREEMENT_VALIDITY: null,
            APPOINTMENT_LETTER: false,
            APPOINTMENT_LETTER_VALIDITY: null,
            OBSERVATION: null,
            FILES: null,
            FILES_NEW: null,
            FILES_REMOVE: null,
            DOCUMENT_HOUSE_FORM: null,
            SITUATION: null
        };
    }

    initDependencies(): Promise<any> {
        const self: AgentRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getProductList(),
                self.getHouseFormList(),
                self.getSituationList()
            ]).then((result: any) => {
                self.$scope.productList = result[0];
                self.$scope.houseFormList = result[1].data;
                self.$scope.situationList = result[2].data;
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private callSessionFunctions(data: object): void {
        try {
            if (!data) return;

            const legalPersonExchangeData = <ILegalPersonExchangeData>data;

            switch (legalPersonExchangeData.OPERATION) {
                case "register":
                    this.$scope.register(legalPersonExchangeData.MODEL);
                    break;
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlDataManager = this.config.productUrl + baseRoute;
        return urlDataManager;
    }

    private async getLegalPersonListByName(name: string): Promise<ILegalPersonSelector[]> {
        try {
            let result = [];
            if (name && name.length >= 3) {
                this.formService.block();
                const legalPersons = await this.RestService.newObjectPromise(`${this.$baseUrl}/legalPerson/list/custom`, { specializations: [], search: name }, 30000, false);
                if (legalPersons) result = legalPersons.map(legalPerson => { 
                    return { 
                        ID: legalPerson.ID, 
                        SHORT_NAME: legalPerson.SHORT_NAME, 
                        CORPORATE_NAME: legalPerson.CORPORATE_NAME,
                        ADDRESS: legalPerson.ADDRESS
                    }
                });
            }

            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        }
        finally {
            this.formService.unblock();
        }
    }

    private getHouseFormList(): Promise<SelectorModel[]> {
        return this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/agent/genericFields/document_type_form`, 30000, null, false);
    }

    private getSituationList(): Promise<SelectorModel[]> {
        return this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/agent/genericFields/agent_situation`, 30000, null, false);
    }

    private async openModalIntegration(id: number, documentErrorList: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            this.ModalService.showModalIntegrationRedundance({ integrationId: id, documentErrorList: documentErrorList, messageDefault: { awaitingRequest: 'Aguarde, enviando requisição...', successRequest: 'Registros enviados para fila de processamento.', requestError: 'Ocorreu um erro ao enviar a requisição.', awaitingGridSync: 'Aguarde, processando sincronização...', successGridSync: 'Sincronização realizada com sucesso.', gridSyncError: 'Ocorreu um erro durante a sincronização.', logCopyMessage: 'Mensagem de log copiada para o clipboard.' }, fnSync: this.sendSync, headerText: 'Erros de Integração - Agentes', actionButtonText: 'Sincronizar agora', closeButtonText: 'Fechar' });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number, idSubject: string): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                this.formService.block();
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/agent/sendSync`, { "idAgent": id, "idSubject": idSubject }, 120000, false);
                if (syncRequest) success = true;
            }
        } catch (ex) {
            this.formService.handleError(this.formService.getTranslate("GENERAL.ERROR_SENDING_REQUEST"));
        } finally {
            this.formService.unblock();
            return success;
        }
    }

    private async getLegalPersonAddressById(id: string): Promise<ILegalPersonAddress> {
        this.formService.block();
        try {
            if (!id) throw Error('Missing id in getLegalPersonAddressById!');

            const request = await this.RestService.getObjectAsPromise(`${this.$baseUrl}/legalPerson/getAddress/${id}`, 30000, null, false);
            const result = request && request.data && request.data[0] ? request.data[0] : null;

            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'agent_files_bucket', formName: this.formName, folder: this.formName };
            return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData] });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    getFisFilesGenericRoute(): string {
        const baseRoute = '/fis/filesGeneric';
        const uploadRoute = this.config.fisUrl + baseRoute;
        return uploadRoute;
    }

	private onSuccessItem(item: IFileItem, response: ISuccessItemResponse): void {
        try {
            if (!item) throw Error('item parameter is null');
            if (!response) throw Error('response parameter is null');

            if (response.data && response.data.length > 0) {
                if (!this.$scope.model.FILES_NEW) this.$scope.model.FILES_NEW = [];

                for (let item of response.data) {
                    const file: IUploadItem = {
                        FILE_DISPLAY_NAME: item.displayFileName,
                        FILE_NAME: item.fileName,
                        FILE_TYPE: item.fileType,
                        FILE_PATH: item.filePath,
                        FILE_URL: item.fileUrl,
                        FILE_HASH: item.fileHash,
                        FILE_SIZE: item.fileSize,
                        FORM_NAME: item.formName
                    }
                    this.$scope.model.FILES_NEW.push(file)
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async register(params: IAgentModel): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.SSEService.closeEvents();
            if (params && params.LEGAL_PERSON && params.LEGAL_PERSON.CORPORATE_NAME) {
                const pj = await this.getLegalPersonListByName(params.LEGAL_PERSON.CORPORATE_NAME);
                if (pj && pj.length > 0) {
                    this.$scope.model.NAME = pj[0].SHORT_NAME;
                    this.$scope.model.LEGAL_PERSON = pj[0];
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} (${this.$scope.model.NAME})`;
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} (${this.$scope.model.NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === "edit" ? "update" : "insert";

        return {
            route: `/agent/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: this.$scope.scopeBeforeSave
        };
    }

    async copy(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            await this.clearFields(this.$scope.model, ['CONCATENATED']);
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                if (this.$scope.uploader.queue && this.$scope.uploader.queue.length > 0) {
                    const errorMessage = this.formService.getTranslate('GENERAL.FILES_IN_THE_QUEUE');
                    this.formService.handleError(errorMessage);
                }
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
            {
                PROPERTY: 'LEGAL_PERSON',
                LABEL: 'ENTITY.CORPORATE_NAME'
            },
            {
                PROPERTY: 'SHIPPER_MASTER',
                LABEL: 'ENTITY.SHIPPER_MASTER'
            },
            {
                PROPERTY: 'CNEE_MASTER',
                LABEL: 'ENTITY.CONSIGNEE_MASTER'
            },
            {
                PROPERTY: 'NOTIFY_MASTER',
                LABEL: 'ENTITY.NOTIFY_MASTER'
            },
            {
                PROPERTY: 'REMARKS_MASTER',
                LABEL: 'ENTITY.REMARKS_MASTER'
            },
            {
                PROPERTY: 'CORRESPONDENCE',
                LABEL: 'ENTITY.POSTAL_ADDRESS'
            },
            {
                PROPERTY: 'ROUTING_POINT',
                LABEL: 'ROUTE.ROUTING_POINT'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'SCAC_CODE',
                LABEL: 'GENERAL.SCAC_CODE'
            },
            {
                PROPERTY: 'CODE',
                LABEL: 'GENERAL.CODE'
            },
            {
                PROPERTY: 'PRODUCT',
                LABEL: 'BASIC_DATA.PRODUCT'
            },
            {
                PROPERTY: 'SHORT_NAME',
                LABEL: 'ENTITY.SHORT_NAME'
            },
            {
                PROPERTY: 'CORPORATE_NAME',
                LABEL: 'ENTITY.CORPORATE_NAME'
            },
            {
                PROPERTY: 'FANTASY_NAME',
                LABEL: 'ENTITY.TRADE_NAME'
            },
            {
                PROPERTY: 'ID',
                LABEL: 'REGISTRATION.IDENTIFICATION'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            }
        ]
        return props;
    }

    private removeUpload(model: IUploadItem): boolean {
        let result = false;
        try {
            if (!model) throw Error('item parameter is null');

            const isAdminOrCoordinator = (this.$scope.user.roles && (this.$scope.user.roles.hasOwnProperty('ADMIN') || this.$scope.user.roles.hasOwnProperty('COORDINATOR')));

            if (isAdminOrCoordinator) {
                result = true;
            } else {
                if (this.$scope.model.FILES_NEW && this.$scope.model.FILES_NEW.length > 0) {
                    const index = this.$scope.model.FILES_NEW.findIndex(file => { return file.FILE_HASH == model.FILE_HASH });
                    if (index > -1) {
                        this.$scope.model.FILES_NEW.splice(index, 1);
                        result = true;
                    }
                }
            }

            if (result) {
                if (!this.$scope.model.FILES_REMOVE) this.$scope.model.FILES_REMOVE = [];
                this.$scope.model.FILES_REMOVE.push(model);
            } else {
                throw Error('You don\'t have permission to remove this file');
            }
        } catch (ex) {
            result = false;
            this.handleError(ex);
        } finally {
            return result;
        }
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/agent/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }
}

import angular = require('angular');
import moment = require('moment');
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { fileUploader as FileUploader } from 'angular-file-upload';
import { IModalService, IModalOptions } from '@services/ModalService';
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { IRestService } from "@services/RestService";
import { ISessionService } from "@services/SessionService";
import { ITariffFreightOfferListCustomFilter, ITariffFreightExchangeData } from "@models/interface/product/TariffFreightModel";
import { IMoveTypeModel, IParam } from "@models/interface/product/MoveTypeModel";
import { IWeightRangeSelector, IWeightRangeListCustomFilter } from '@models/interface/product/WeightRangeModel';
import { IProductConfigurationModel, IProductConfigurationSecure } from '@models/interface/product/ProductConfigurationModel';
import { IEquipmentSelector } from '@models/interface/product/EquipmentModel';
import { IUploader, IUploadItem, IFileItem, ISuccessItemResponse } from '@models/interface/common/IMonacoUpload';
import { IFormData } from '@models/interface/product/Upload';
import { IOfferOptionChargeWeightRange } from '@models/interface/quotation/OfferOptionChargeWeightRange';
import { IApplicationList } from '@models/interface/product/ApplicationModel';
import { IChargeNameListCustomFilter } from "@models/interface/product/ChargeNameModel";
import { ECargoTypeId, EProductId, EChargeOriginId, EPaymentNatureId, EEquipSpecId, EDirectionId, EOperation, EProcessTypeId } from "@enums/GenericData";
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { ETypeRuleId, ERouteEventType } from "@enums/MoveTypeModel";
import { IMonacoConfig, IMonacoScope } from '../../common/MonacoInterface';
import { ILinkParameter, IOfferWizardFinish } from "../../common/model/ModelParameter";
import { SelectorModel } from "../../common/model/SelectorModel";
import { IOfferWizardModalModel, IRoute, ISpecRoute, IMoveType, IOfferTab, IRouteTab, ICargoTab, ISpecTab, ITransitTime, ILegsAir, ILegsMaritime, ILegsDomestic, IOfferWizardAgentResult, IOfferWizardRequestRouteAccount, IOfferWizardAccountResult, IOfferWizardRequestRouteAgent, IRouteAgentOriginDestination, IOfferWizardRequestIncoterm, IOfferWizardIncotermResult } from "../model/OfferOldWizardModalModel";
import { IFCLCargo, IOfferEquipment, IFTLCargo, IAirCargo, ICargo, IDangerousCargo, IBBROROCargo, IOfferOptionCharge, IOfferProfitShareDetail, IOfferProfitShareDetailComposition, IOfferOptionProfitShareDetailShareItem, IOfferExchangeData, IOfferRequestOperationWM, IOfferRequestOperationChargeable } from "../../commercial/model/OfferModel";
import { IRecipientListCustomFilter, EOfferOpportunitySituation } from "../model/OfferOpportunityModel";
import { ILegalPersonListCustomFilter } from "../../registration/model/LegalPersonModel";
import { IAccountExchangeData } from "../model/AccountModel";
import { ITariffDomesticExchangeData } from "../../product/model/TariffDomesticModel";
import { ITariffLocalExchangeData } from '../../product/model/TariffLocalModel';
import { IAgentListCustomFilter } from '../../registration/model/AgentModel';
import { IBrokerListCustomFilter } from '../../registration/model/BrokerModel';
import { NumberUtil } from "../../common/util/NumberUtil";
import { IRoutingPointSelector } from "@models/interface/product/RoutingPoint";
import { OfferHelper } from './util/OfferHelper';
import { OfferOldNewAlternativeHelper } from './util/OfferOldNewAlternativeHelper';
import { HelperService } from "@services/HelperService";
import { ISelectorModel } from 'WBA-Model/dist/mongo/SelectorModel';

export const enum EValidationResultId {
    FILTERS_BASE = "1",
    TYPE_CARGO = "2",
    TARIFF_FREIGHT_RECEIVING = "3",
    AGENTS = "4",
    ACCOUNT_REQUIREMENT = "5",
    TARIFF_DET_DEM_PAYMENT = "6",
    TARIFF_DET_DEM_RECEIVING = "7",
    TARIFF_LOCAL = "8",
    COMMODITY = "9",
    TARIFF_DOMESTIC_ORIGIN = "10",
    TARIFF_DOMESTIC_DESTINATION = "11"
}

const enum ETabStep {
    OFFER = 0,
    ROUTE = 1,
    CARGO = 2,
    SPECIFITICY = 3,
    OPTION = 4,
    OFFER_PREVIEW = 5,
    CONFIRMATION = 6
}

const enum EInputTypes {
    MANUAL = 0,
    TARIFF_FREIGHT_CONTRACT = 1,
    TARIFF_FREIGHT = 2,
    REPLICATE_OFFER = 3,
    PRE_OFFER = 4,
}

interface IUiTabSteps {
    current: number
    percent: number
    lastStep: number
}

interface IOfferWizardBasicInfo {
    ID: number;
    CONCATENATED: string;
    PAYMENT_NATURE: SelectorModel;
    PRODUCT: SelectorModel;
    TYPE_CARGO: SelectorModel;
    PROVIDER: SelectorModel;
    ACCOUNTS: SelectorModel[];
    VALIDITY_START: Date;
}

interface IOfferWizardBasicInfoSelection {
    ACCOUNT: SelectorModel;
}

interface ISelectionModalControl {
    isAccountMandatory: boolean;
    accountSelectionList: SelectorModel[];
}

interface IOfferOptionProvider {
    ID: number;
    ID_LEGAL_PERSON: number;
    NAME: string;
    ACTIVE: boolean;
    LEGAL_PERSON: IOfferOptionLegalPerson;
}

interface IOfferOptionLegalPerson {
    ID: number;
    SHORT_NAME: string;
    CORPORATE_NAME: string;
    FANTASY_NAME: string;
    ACTIVE: boolean;
}

interface IOfferOptionAccountRequirement {
    ID: number;
    ACCOUNT_TYPE: SelectorModel;
    CONCATENATED: string;
    QUALIFICATION: SelectorModel;
    KAM_AVAILABLE: boolean;
    SITUATION: SelectorModel;
    FORWARDED_BY: SelectorModel;
    CORPORATE_BRANCH: SelectorModel;
    INSIDE_SALES_PERSON: SelectorModel;
    SPONSOR: SelectorModel;
    SALES_PERSON: SelectorModel;
    SALES_HOLDER: SelectorModel;
    RESPONSIBLE_PRODUCT: SelectorModel;
}

interface IOfferOptionAccount {
    ID: number;
    ID_LEGAL_PERSON: number;
    NAME: string;
    ACTIVE: boolean;
    LEGAL_PERSON: IOfferOptionLegalPerson;
    ACCOUNT_REQUIREMENT: IOfferOptionAccountRequirement;
}

export interface IOfferOptionRoutingPoint {
    EVENT: string;
    TYPE: string;
    ID: number;
    ID_CITY: number;
    CODE: string;
    NAME: string;
    ACTIVE: boolean;
    TRADE_LANE: SelectorModel;
    TRADE_GROUP: SelectorModel;
    CITY: {
        ID: number;
        INITIALS: string;
        NAME: string;
        ID_PROVINCE: number;
        ACTIVE: boolean;
    },
    PROVINCE: {
        ID: number;
        INITIALS: string;
        NAME: string;
        ID_COUNTRY: number;
        ACTIVE: boolean;
    },
    COUNTRY: {
        ID: number,
        INITIALS: string,
        NAME: string,
        ACTIVE: boolean,
        COUNTRY_IMG: SelectorModel
    },
    TARIFF_LOCAL_ORIGIN: {
        TARIFF_LOCAL_ORIGIN_RECEIVING: SelectorModel;
        TARIFF_LOCAL_ORIGIN_PAYMENT: SelectorModel;
    },
    TARIFF_LOCAL_DESTINATION: {
        TARIFF_LOCAL_DESTINATION_RECEIVING: SelectorModel;
        TARIFF_LOCAL_DESTINATION_PAYMENT: SelectorModel;
    }
}

interface IOfferOptionDomesticOriginDestination {
    ROUTING_POINT: IOfferOptionRoutingPoint;
    MOVE_TYPE: SelectorModel;
    ID_TARIFF_FREIGHT: number;
    ID_TARIFF_DOMESTIC: number;
    ID_ROUTING_POINT: number;
    LOGISTIC_SOLUTION: SelectorModel[];
}

interface IOfferOptionFreight {
    MOVE_TYPE: SelectorModel;
    ID_TARIFF_FREIGHT: number;
    ID_TARIFF_DOMESTIC: number;
    ID_ROUTING_POINT: number;
    LOGISTIC_SOLUTION: SelectorModel[];
    ROUTING_POINT: IOfferOptionRoutingPoint;
}

interface IOfferOptionDomestic {
    ORIGIN: IOfferOptionDomesticOriginDestination,
    DESTINATION: IOfferOptionDomesticOriginDestination
}

export interface IOfferOptionPaymentListResult {
    SHOW: boolean;
    ROUTES: IOfferOptionPaymentRoute[];
}

interface IOfferOptionProcessStatus {
    ID: string;
    NAME: string;
    ERROR: boolean;
    HEART_BEAT: Date;
    RESTARTS: number;
    START: Date;
    END: Date;
}

export interface IOfferOptionPaymentRoute {
    MOVE_TYPE: IMoveType;
    ORIGIN: IRoutingPointSelector;
    DESTINATION: IRoutingPointSelector;
    PROCESS_STATUS: IOfferOptionProcessStatus;
    OFFER_OPTION: IOfferOption[];
}

interface IOfferOptionRoute {
    FREIGHT: IOfferOptionFreight;
    DOMESTIC: IOfferOptionDomestic;
}

export interface IOfferOptionIndicators {
    ESTIMATED_GROSS_PROFIT: number;
    TOTAL_PAYMENT: number;
    TOTAL_RECEIVING: number;
    TOTAL_PROFIT_SHARE: number;
    TOTAL_TEUS: number;
    TOTAL_WEIGHT: number;
    TOTAL_VEHICLES: number;
    TOTAL_FILE: number;
    EGP_TOTAL_TEUS: number;
    EGP_TOTAL_WEIGHT: number;
    EGP_TOTAL_VEHICLES: number;
    EGP_TOTAL_FILE: number;
    CTWD: number;
    CTWD_FILE: number;
    CTWD_WEIGHT: number;
    CTWD_VEHICLES: number;
    EGP_TOTAL_CTWD: number;
}

interface IFreeTimeDetails {
    PERIOD: number;
    FROM: number;
    UNTIL: number;
    DAYS: number;
    CURRENCY: SelectorModel;
    PER_DIEM_TYPE: SelectorModel;
    FREE_TIME_TYPE: SelectorModel;
    TABLE_PER_DIEM: SelectorModel;
    ORIGINAL_VALUE: number;
    FIXED_ADDITIONAL: number;
    PERCENTAGE_ADDITIONAL: number;
    TOTAL_VALUE: number;
}

interface IGenerateOpportunityModel {
    FILES: IUploadItem[];
    FILES_NEW: IUploadItem[];
    SITUATION: SelectorModel;
    RECIPIENT: SelectorModel;
    REASON: SelectorModel[];
    MESSAGE: string;
}

interface IGenerateOpportunityModalScope extends IFormServiceScope {
    model: IGenerateOpportunityModel;
    offerOpportunityRecipientList: SelectorModel[];
    getRecipientListByName: (search: string) => Promise<void>;
    generateOpportunity: () => void;
    removeUpload: (model: IUploadItem) => Promise<boolean>;
}

export interface IOfferOptionAux extends IOfferOption {
    SHOW_DETAILS?: boolean;
    MOVE_TYPE?: IMoveType;
    ORIGIN?: IRoutingPointSelector;
    DESTINATION?: IRoutingPointSelector;
    PROCESS_STATUS?: IOfferOptionProcessStatus;
    CONCATENATED_COMPLEMENT?: string;
}

export interface IOfferOptionInvalid {
    _id?: string;
    SHOW: boolean;
    MOVE_TYPE: IMoveType;
    PRODUCT: SelectorModel;
    PROVIDER: IOfferOptionProvider;
    ORIGIN: SelectorModel;
    DESTINATION: SelectorModel;
    ID_TARIFF_FREIGHT: number;
    ID_TARIFF_FREIGHT_CONTRACT: number;
    TARIFF_FREIGHT_CONCATENATED: string;
    CHARGES: IOfferOptionCharge[];
    VALIDATION_RESULT: IOfferOptionValidationResult;
}

export interface IDetDem {
    TARIFF_TYPE: SelectorModel;
    TYPE: string;
    EQUIPMENT: IEquipmentSelector[];
    PAYMENT_NATURE: SelectorModel;
    FREE_TIME: number;
    IMO: boolean;
    FREE_TIME_DETAILS: IFreeTimeDetails[];
    INDEX: number;
}

export interface ISummaryPaymentReceiving {
    DET: number;
    DEM: number;
    COMBINED: number;
}
export interface ISummary {
    EQUIPMENT: IEquipmentSelector[];
    SUMMARY_PAYMENT: ISummaryPaymentReceiving;
    SUMMARY_RECEIVING: ISummaryPaymentReceiving;
}
export interface IDetDemOriginDestination {
    ID_PAYMENT: number;
    PAYMENT_DATA_ORIGIN: ISelectorModel;
    CONCATENATED_PAYMENT: string;
    ID_RECEIVING: number;
    RECEIVING_DATA_ORIGIN: ISelectorModel;
    CONCATENATED_RECEIVING: string;
    SUMMARY: ISummary[];
    DETAILS: IDetDem[];
}
export interface ITariffDetDemDetails {
    DET_DEM_ORIGIN: IDetDemOriginDestination;
    DET_DEM_DESTINATION: IDetDemOriginDestination;
}
export interface ISessionParameter {
    param: object;
    data?: object;
}

export interface IOfferOptionValidationResult {
    FILTERS_BASE: boolean;
    TYPE_CARGO: boolean;
    AGENTS: boolean;
    TARIFF_LOCAL: boolean;
    TARIFF_DET_DEM_PAYMENT: boolean;
    TARIFF_DET_DEM_RECEIVING: boolean;
    TARIFF_FREIGHT_RECEIVING: boolean;
    ACCOUNT_REQUIREMENT: boolean;
    COMMODITY: boolean;
    TARIFF_DOMESTIC_ORIGIN: boolean;
    TARIFF_DOMESTIC_DESTINATION: boolean;
}

export interface IOfferOption {
    _id: string;
    ID_OFFER_WIZARD_FILTER: number;
    ID_OFFER_PROCESS: number;
    ID_TARIFF_FREIGHT_CONTRACT: number;
    ID_TARIFF_FREIGHT_CONTRACT_RECEIVING: number;
    ID_TARIFF_FREIGHT: number;
    ID_TARIFF_FREIGHT_RECEIVING: number;
    ID_TARIFF_DOMESTIC_ORIGIN: number;
    ID_TARIFF_DOMESTIC_DESTINATION: number;
    TARIFF_FREIGHT_CONCATENATED: string;
    TARIFF_FREIGHT_CONTRACT_CONCATENATED: string;
    TARIFF_FREIGHT_RECEIVING_CONCATENATED: string;
    TARIFF_DOMESTIC_ORIGIN_CONCATENATED: string;
    TARIFF_DOMESTIC_DESTINATION_CONCATENATED: string;
    OFFER_CODE_EXHIBITION: string;
    CURRENT_CURRENCY: SelectorModel;
    SERVICE_TYPE: SelectorModel;
    MARITIME_SERVICE: SelectorModel[];
    AIR_SERVICE: SelectorModel[];
    TRANSIT_TIME: ITransitTime[];
    SITUATION: SelectorModel;
    PAYMENT_NATURE: SelectorModel;
    PRODUCT: SelectorModel;
    TYPE_CARGO: SelectorModel;
    VALIDITY_START: Date;
    VALIDITY_END: Date;
    ON_VALIDITY: boolean;
    ACTIVE: boolean;
    PROVIDER: IOfferOptionProvider;
    AGENT: SelectorModel;
    ACCOUNT: IOfferOptionAccount;
    ACTIVE_TARIFF_FREIGHT: boolean;
    PUP: IOfferOptionRoute;
    OTFS: IOfferOptionRoute;
    POLAOL: IOfferOptionRoute;
    PODAOD: IOfferOptionRoute;
    DTFS: IOfferOptionRoute;
    PLD: IOfferOptionRoute;
    IS_VALID: boolean;
    VALIDATION_RESULT: IOfferOptionValidationResult;
    ID: number;
    SCORE: number;
    TOOLTIP: string;
    SELECTED: boolean;
    CHARGES: IOfferOptionCharge[];
    MASTER_MODALITY: SelectorModel;
    HOUSE_MODALITY: SelectorModel;
    INDICATORS: IOfferOptionIndicators;
    DET_DEM_DETAILS: ITariffDetDemDetails;
    WEIGHT_RANGE: IOfferOptionChargeWeightRange[];
}

export interface IOfferOptionConfirmation {
    _id: string;
    SELECTED: boolean;
    OFFER_CODE_EXHIBITION: string;
    CONCATENATED: string;
    MASTER_MODALITY: SelectorModel;
    HOUSE_MODALITY: SelectorModel;
    OPEN_DATE: string;
    ACCOUNT: string;
    PROVIDER: string;
    MOVE_TYPE: string;
    ORIGIN: string;
    DESTINATION: string;
    SERVICE: ITransitTime[];
    CONTRIBUTION: number;
    CONTRIBUTION_WDOC: number;
    CONTRIBUTION_MASK: number;
    ESTIMATED_GROSS_PROFIT: number;
    CONCATENATED_COMPLEMENT: string;
}

interface IOfferOptionLinkOption {
    _id: string;
    OFFER: string;
    HIERARCHY: SelectorModel;
    CONCATENATED_COMPLEMENT: string;
}

interface IOfferOptionHierarchy {
    _id: string;
    HIERARCHY: string;
}

interface IOfferOptionPaymentRequestHierarchy {
    OFFER_WIZARD_FILTER_ID: number;
    OFFER_OPTIONS_HIERARCHY: IOfferOptionHierarchy[];
}

interface IOfferOptionPaymentHierarchyResult {
    data: boolean | string;
}

export interface IRouteDetails {
    type: string;
    showType: boolean;
    typeRowspan: number;
    event: string;
    name: string;
    tariffPayment: string;
    tariffPaymentId: number;
    tariffContractPaymentId: number;
    tariffReceiving: string;
    tariffReceivingId: number;
    tariffContractReceivingId: number;
    tariffLocalDestination: {
        tariffLocalDestinationReceiving: SelectorModel;
        tariffLocalDestinationPayment: SelectorModel;
    },
    tariffLocalOrigin: {
        tariffLocalOriginReceiving: SelectorModel;
        tariffLocalOriginPayment: SelectorModel;
    }
}

interface IOfferConfirmationList {
    ID: number,
    OFFER_CODE_EXHIBITION: string;
    CONCATENATED: string;
    LOAD_REF: string;
    ERROR: string;
}

interface IProfile {
    selectedProfile: SelectorModel;
    profileList: SelectorModel[];
}

interface IOfferWizardModalProfileState {
    OFFER_TAB: IOfferTab;
    ROUTE_TAB: IRouteTab;
    CARGO_TAB: ICargoTab;
    SPEC_TAB: ISpecTab;
}

interface IOfferWizardModalScope extends IFormServiceScope {
    modalOptions: IModalOptions;
    model: IOfferWizardModalModel;
    scopeBeforeSave: IOfferWizardModalModel;
    uploader: IUploader;
    steps: IUiTabSteps;
    idTariffFreightContract: number;
    idTariff: number;
    idPaymentNature: number;
    idOffer: number;
    idOfferOpportunity: number;
    inputType: number;
    isManual: boolean;
    basicInfoList: IOfferWizardBasicInfo;
    basicInfoSelection: IOfferWizardBasicInfoSelection;
    selectionModalControl: ISelectionModalControl;
    offerOptions: IOfferOptionAux[];
    invalidOfferOptions: IOfferOptionInvalid[];
    optionRoutesError: IOfferOptionPaymentRoute[];
    selectedOptions: IOfferOptionConfirmation[];
    linkOptions: IOfferOptionLinkOption[];
    hasAnyOption: boolean;
    productList: SelectorModel[];
    cargoTypeList: SelectorModel[];
    accountList: SelectorModel[];
    exporterList: SelectorModel[];
    importerList: SelectorModel[];
    providerList: SelectorModel[];
    incotermList: SelectorModel[];
    offerTypeList: SelectorModel[];
    processTypeList: SelectorModel[];
    moveTypeList: IMoveTypeModel[];
    buyingRateList: SelectorModel[];
    sellingRateList: SelectorModel[];
    commodityList: SelectorModel[];
    routingPointList: SelectorModel[];
    currencyList: SelectorModel[];
    equipmentList: IEquipmentSelector[];
    vehicleTypeList: SelectorModel[];
    weightRangeList: SelectorModel[];
    weightRangeFullList: IWeightRangeSelector[];
    packageTypeList: SelectorModel[];
    equipSpecList: SelectorModel[];
    typePaymentList: SelectorModel[];
    agentList: SelectorModel[];
    brokerList: SelectorModel[];
    insuranceBrokerList: SelectorModel[];
    unClassList: SelectorModel[];
    packingGroupList: SelectorModel[];
    productConfigurationList: IProductConfigurationModel[];
    offerOpportunitySituationList: SelectorModel[];
    offerOpportunityReasonList: SelectorModel[];
    ordinationList: SelectorModel[];
    hierarchyList: SelectorModel[];
    paymentApplicationList: SelectorModel[];
    receivingApplicationList: SelectorModel[];
    applicationList: SelectorModel[];
    chargeNameList: SelectorModel[];
    equipmentProviderList: SelectorModel[];
    offerOptionInconsistencyList: SelectorModel[];
    isCargoCalculatedFieldsDisabled: boolean;
    isCargoListGrossWeightFieldsDisabled: boolean;
    searchOfferOptionPaymentListMaxAttempt: number;
    searchOfferOptionPaymentListCurrentAttempt: number;
    isSearchingOfferOptionPaymentList: boolean;
    isSearchOfferOptionPaymentListCanceled: boolean;
    offerOptionPaymentListCount: number;
    routeDetails: IRouteDetails[];
    defaultProductSecure: SelectorModel[];
    defaultProfitShareTypeCargo: SelectorModel[];
    confirmationList: IOfferConfirmationList[];
    confirmationErrorList: IOfferConfirmationList[];
    sectionList: SelectorModel[];
    profile: IProfile;
    currentState: IOfferWizardModalProfileState;
    dateOptionsAccountRequest: {
        formatYear: string
        maxDate: Date
        minDate: Date
        startingDay: number
    };
    disableFieldCargo: boolean;

    //general methods
    calculateCargoCBW: (index: number) => void;
    calculateCargoGrossWeightTotal: (index: number) => void;
    calculateGrossWeightTotal: () => void;
    calculateVolumeTotal: () => void;
    buildRoutesHtml: (offerOption: IOfferOption) => string;
    buildOfferOptionInconsistencyHtml: (validationResult: IOfferOptionValidationResult) => string;
    buildOfferOptionErrorTooltip: (error: string[]) => string;
    buildDetDemDetailDirection: (product: string, tariffDetDem: ITariffDetDemDetails) => ITariffDetDemDetails;
    openRouteDetails: (offerOption: IOfferOption) => void;
    openTransitTimeDetails: (transitTime: ITransitTime) => void;
    openProfitShareDetails: (profitShareDetail: IOfferProfitShareDetail) => void;
    openPaymentReceivingChargesDetails: (currentCurrency: SelectorModel, charges: IOfferOptionCharge[], indicators: IOfferOptionIndicators) => void;
    openChargesPerWeightRangeModal: (chargeWeightRangeList: IOfferOptionChargeWeightRange[]) => void;
    openLinkOptions: () => void;
    openTariffDetDemDetails: (tariffDetDem: ITariffDetDemDetails, tariffContract: IOfferOption) => void;
    updateCargoCalculateFieldsDisabledControl: (amount: number) => void;
    showGenerateOpportunityModal: () => void;
    reprocessOfferOption: () => void;
    cancelOfferOption: () => void;
    applyLinkOptionModal: (apply: boolean) => void;
    addProfileModal: () => void;
    addProfile: (profileName: string, modalContext) => void;
    removeProfile: (profileName: string) => void;
    saveProfile: (profileName: string) => void;
    loadProfile: (profile: SelectorModel) => void;
    enableTotalGrossWeight: () => void;

    //navigation
    back: () => void;
    next: () => void;
    finish: () => void;
    tabClick: (tabIndex: number) => void;
    goToIncoterm: (id?: number) => void;
    goToAccount: (id?: number) => void;
    goToExporter: (id?: number) => void;
    goToImporter: (id?: number) => void;
    goToProvider: (id?: number) => void;
    goToAgent: (id?: number) => void;
    goToWeightRange: (weightRanges: SelectorModel[]) => void;
    goToCommodity: (commodities: SelectorModel[]) => void;
    goToTariff: (type: string, tariffId: number, tariffContractId: number) => void;
    goToAccountRequirement: (accountRequirementId: number) => void;
    goToOffer: (offerCodeExhibition: string, offerId: number) => void;

    //gets
    getAccountListByName: (search: string) => Promise<void>;
    getExporterListByName: (search: string) => Promise<void>;
    getImporterListByName: (search: string) => Promise<void>;
    getProviderListByName: (search: string) => Promise<void>;
    getBuyingRateListByName: (search: string) => Promise<void>;
    getSellingRateListByName: (search: string) => Promise<void>;
    getCommodityListByName: (search: string) => Promise<void>;
    getSectionList: () => Promise<void>;
    getRoutingPointListByName: (name: string, params: IParam[], directionId: string) => Promise<void>;
    getCurrencyListByName: (search: string) => Promise<void>;
    getEquipmentListByNameOrCode: (search: string) => Promise<void>;
    getAgentListByName: (search: string, networkId: number) => Promise<void>;
    getBrokerListByName: (search: string) => Promise<void>;
    getInsuranceBrokerListByName: (search: string) => Promise<void>;
    getApplicationListByName: (product: SelectorModel[], typeCargo: SelectorModel[], search: string) => Promise<void>;
    getChargeNameListByName: (search: string, products?: SelectorModel[], paramTypeCargo?: SelectorModel[]) => Promise<void>;
    getAndUpdateRouteAccountValues: (routeIndex: number, masterDirect?: boolean) => Promise<void>;
    getAndUpdateOptionList: () => void;
    getOfferOptionInvalidCharge: (offerOption: IOfferOptionInvalid) => void;
    getEquipmentProviderListByName: (search: string) => Promise<void>;

    //filters
    routingPointRouteFilter: (index: number, field: string) => Function;
    offerOptionChargeTypeFilter: (charge: IOfferOptionCharge) => boolean;

    //field changes
    weightBreakdownChange: (value: boolean) => Promise<void>;
    productChange: () => void;
    cargoTypeChange: () => void;
    offerTypeChange: () => void;
    grossWeightChange: () => void;
    cubicWeightChange: () => void;
    weightRangeChange: () => void;
    tariffChange: (tariffSelected: SelectorModel, tariffType: string) => void;
    specDangerousCargoChange: () => void;
    specSecureChange: () => void;
    specRouteAgentChange: (index: number) => void;
    ordinationChange: (optionOrdination: SelectorModel) => void;
    chargeNameChange: (optionChargeName: SelectorModel) => void;
    cargoAirChargeableWeightChange: (amount: number) => void;
    cargoAirOverChargeableWeightChange: (amount: number) => void;
    cargoListGrossWeightFieldsControl: (grossWeightTotal: number) => void;

    //multilines control
    addRoute: () => void;
    removeRoute: (index: number) => void;
    copyRoute: (index: number) => void;
    addCargoFCL: () => void;
    removeCargoFCL: (index: number) => void;
    addCargoFTL: () => void;
    removeCargoFTL: (index: number) => void;
    addCargoBBRORO: () => void;
    removeCargoBBRORO: (index: number) => void;
    addCargoAir: () => void;
    removeCargoAir: (index: number) => void;
    addCargo: () => void;
    removeCargo: (index: number) => void;
    addDangerousCargo: () => void;
    removeDangerousCargo: (index: number) => void;
    launchCargoPolLb: () => void;
    applySelectionModal: (apply: boolean) => Promise<void>;
    selectAll: (clickedIn?: boolean) => void;
    selectAllOfferOption: (clickedIn?: boolean) => void;
    selectAllOfferOptionConfirmation: (clickedIn?: boolean) => void;

    //check methods
    isTabEnabled: (tabIndex: number) => boolean;
    isOfferTypeStudyOrSpot: () => boolean;
    isLclAmountDisabled: () => boolean;
    isAirChargeableWeightDisabled: () => boolean;
    isProfileActive: () => void;
    isCargoTotalGrossWeightDisabled: () => boolean;
    isCargoInOffer: () => boolean;
}

interface IOfferWizardRequestBasicInfoFilters {
    ID_TARIFF_FREIGHT_CONTRACT: number;
    ID_TARIFF_FREIGHT: number;
}

interface IOfferWizardRequestOriginDestinationFilters {
    ID_TARIFF_FREIGHT_CONTRACT_PAYMENT: number;
    ID_TARIFF_FREIGHT_CONTRACT_RECEIVING: number;
    ID_TARIFF_FREIGHT_PAYMENT: number;
    ID_TARIFF_FREIGHT_RECEIVING: number;
}

interface IOfferWizardRequestEquipmentFilters {
    ID_TARIFF_FREIGHT_CONTRACT_PAYMENT: number,
    ID_TARIFF_FREIGHT_CONTRACT_RECEIVING: number,
    ID_TARIFF_FREIGHT_PAYMENT: number,
    ID_TARIFF_FREIGHT_RECEIVING: number,
    TYPE_CARGO: string;
}

export class OfferOldWizardModalRegisterController extends FormService2 {
    static $inject: string[] = ['$injector', '$scope', 'RestService', 'ModalService', 'config'];
    private scope: IOfferWizardModalScope;
    private rootScope: IMonacoScope;
    private $q: ng.IQService;
    private sce: angular.ISCEService;
    private $compile: angular.ICompileService;
    private $filterService: ng.FilterFactory;
    private modalService: IModalService;
    private restService: IRestService;
    private config: IMonacoConfig;
    private sessionService: ISessionService;
    private fileUploader: FileUploader;
    private modalOpportunityId: number;
    private modalSelectionId: number;
    private modalCargoPolLbId: number;
    private modalLinkOptionId: number;
    private OfferWizardFilterId: number;
    private productUrl: string;
    private quotationUrl: string;
    private timeout: ng.ITimeoutService;
    private persistName: string;
    private loadRefPayment: Date;
    private loadRefReceiving: Date;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IOfferWizardModalScope) {
        super($injector, $scope);

        this.scope = $scope;
        this.rootScope = $injector.get('$rootScope');
        this.$q = $injector.get('$q');
        this.sce = $injector.get('$sce');
        this.$compile = $injector.get('$compile');
        this.$filterService = $injector.get('$filter');
        this.modalService = $injector.get('ModalService');
        this.restService = $injector.get('RestService');
        this.sessionService = $injector.get('SessionService');
        this.fileUploader = $injector.get('FileUploader');
        this.config = $injector.get('config');
        this.timeout = $injector.get('$timeout');
        this.helperService = $injector.get('HelperService');
        this.persistName = "offerOldWizard";
        this.scope.dateOptionsAccountRequest = {
            formatYear: 'yy',
            maxDate: new Date(),
            minDate: new Date(1970, 1, 1),
            startingDay: 1
        };
    }

    async initDependencies(): Promise<any> {
        const self: OfferOldWizardModalRegisterController = this;

        self.scope.profile = { selectedProfile: null, profileList: [] };
        self.scope.hierarchyList = [
            { ID: "1", NAME: "A" },
            { ID: "2", NAME: "B" },
            { ID: "3", NAME: "C" },
            { ID: "4", NAME: "D" },
            { ID: "5", NAME: "E" },
            { ID: "6", NAME: "F" },
            { ID: "7", NAME: "G" },
            { ID: "8", NAME: "H" },
            { ID: "9", NAME: "I" },
            { ID: "10", NAME: "J" },
            { ID: "11", NAME: "K" },
            { ID: "12", NAME: "L" },
            { ID: "13", NAME: "M" },
            { ID: "14", NAME: "N" },
            { ID: "15", NAME: "O" },
            { ID: "16", NAME: "P" },
            { ID: "17", NAME: "Q" },
            { ID: "18", NAME: "R" },
            { ID: "19", NAME: "S" },
            { ID: "20", NAME: "T" },
            { ID: "21", NAME: "U" },
            { ID: "22", NAME: "V" },
            { ID: "23", NAME: "W" },
            { ID: "24", NAME: "X" },
            { ID: "25", NAME: "Y" },
            { ID: "26", NAME: "Z" },
        ];

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericValue("product"),
                self.getGenericValue("type_cargo"),
                self.getGenericValue("offer_type"),
                self.getGenericValue("process_type"),
                self.getMoveTypeList(),
                self.getGenericValue("vehicle_type"),
                self.getGenericValue("package_type"),
                self.getGenericValue("equip_spec"),
                self.getGenericValue("type_payment"),
                self.getGenericValue("un_class"),
                self.getGenericValue("packing_group"),
                self.getProductConfigurationList(),
                self.getGenericAlternativeAsCodeList("offer_option_inconsistency"),
                self.getGenericValue("offer_opportunity_situation"),
                self.getGenericValue("offer_opportunity_reason"),
            ]).then(async (result: any) => {
                self.scope.productList = result[0];
                self.scope.cargoTypeList = result[1];
                self.scope.offerTypeList = result[2];
                self.scope.processTypeList = result[3];
                self.scope.moveTypeList = result[4].data ? result[4].map(moveType => { return { ID: moveType.ID, NAME: moveType.NAME, CODE: moveType.CODE, PARAMS: moveType.PARAMS, APPLY_ROAD: moveType.APPLY_ROAD, ORIGIN_TYPE: moveType.ORIGIN_TYPE, DESTINATION_TYPE: moveType.DESTINATION_TYPE } }) : null;
                self.scope.vehicleTypeList = result[5];
                self.scope.packageTypeList = result[6];
                self.scope.equipSpecList = result[7];
                self.scope.typePaymentList = result[8];
                self.scope.unClassList = result[9];
                self.scope.packingGroupList = result[10];
                self.scope.productConfigurationList = result[11].data;
                self.scope.offerOptionInconsistencyList = result[12];
                self.scope.offerOpportunitySituationList = result[13];
                self.scope.offerOpportunityReasonList = result[14];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    $onInit = async () => {
        try {
            this.modalOpportunityId = 0;
            this.modalSelectionId = 0;
            this.modalCargoPolLbId = 0;
            this.modalLinkOptionId = 0;
            this.init("offerOldWizardModalForm", null, null);
            this.initScopeFunctions();
            this.productUrl = this.getUrlProduct();
            this.quotationUrl = this.getUrlQuotation();
            await this.initDependencies();
            await this.initModel();
            await this.initControl();
            await this.initList();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    getFisFilesGenericRoute(): string {
        const baseRoute = '/fis/filesGeneric';
        const uploadRoute = this.config.fisUrl + baseRoute;
        return uploadRoute;
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        return this.config.productUrl + baseRoute;
    }

    private getUrlQuotation(): string {
        const baseRoute = '/quotation';
        return this.config.quotationUrl + baseRoute;
    }

    private initScopeFunctions(): void {
        this.scope.getEmptySelectorMsg = () => { return this.getEmptySelectorMsg() };
        this.scope.calculateCargoCBW = (index: number): void => {
            if (!index && index !== 0) throw this.notifyError('index is null');
            const cargoListModel = this.scope.model.CARGO_TAB ? this.scope.model.CARGO_TAB.CARGO_LIST : null;
            if (cargoListModel && cargoListModel.length > 0 && cargoListModel[index]) {
                const cbw = OfferHelper.calculateCargoCBW(cargoListModel[index]);
                this.scope.model.CARGO_TAB.CARGO_LIST[index].CUBIC_WEIGHT = cbw ? parseFloat(cbw.toFixed(3)) : cbw;
                this.calculateCBWTotal();
                if (this.scope.model.CARGO_TAB.AIR) this.calculateOperationChargeable();
                if (this.scope.model.OFFER_TAB && this.scope.model.OFFER_TAB.TYPE_CARGO && (this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.BREAK_BULK || this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.LCL || this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.RO_RO)) this.calculateOperationWM();
            }
        }
        this.scope.calculateCargoGrossWeightTotal = (index: number): void => {
            if (!index && index !== 0) throw this.notifyError('index is null');
            const cargoListModel = this.scope.model.CARGO_TAB ? this.scope.model.CARGO_TAB.CARGO_LIST : null;
            if (cargoListModel && cargoListModel.length > 0 && cargoListModel[index] && cargoListModel[index].GROSS_WEIGHT_UNITARY > 0) {
                const grossWeightTotal = OfferHelper.calculateCargoGrossWeightTotal(cargoListModel[index]);
                this.scope.model.CARGO_TAB.CARGO_LIST[index].GROSS_WEIGHT = grossWeightTotal ? parseFloat(grossWeightTotal.toFixed(4)) : grossWeightTotal;
            }
            this.calculateGrossWeightTotal();
            this.calculateCBWTotal();
            if (this.scope.model.CARGO_TAB.AIR) this.calculateOperationChargeable();
            if (this.scope.model.OFFER_TAB && this.scope.model.OFFER_TAB.TYPE_CARGO && (this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.BREAK_BULK || this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.LCL || this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.RO_RO)) this.calculateOperationWM();
            this.updateAirApplication();
        }
        this.scope.calculateGrossWeightTotal = (): void => {
            this.calculateGrossWeightTotal();
            this.calculateCBWTotal();
            if (this.scope.model.CARGO_TAB.AIR) this.calculateOperationChargeable();
            if (this.scope.model.OFFER_TAB && this.scope.model.OFFER_TAB.TYPE_CARGO && (this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.BREAK_BULK || this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.LCL || this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.RO_RO)) this.calculateOperationWM();
            this.updateAirApplication();
        }
        this.scope.calculateVolumeTotal = (): void => {
            this.calculateVolumeTotal();
        }
        this.scope.buildRoutesHtml = (offerOption: IOfferOption) => {
            return this.buildRoutesHtml(offerOption);
        }
        this.scope.buildOfferOptionInconsistencyHtml = (validationResult: IOfferOptionValidationResult) => {
            return this.buildOfferOptionInconsistencyHtml(validationResult);
        }
        this.scope.buildOfferOptionErrorTooltip = (errors: string[]) => {
            return this.buildOfferOptionErrorTooltip(errors);
        }
        this.scope.buildDetDemDetailDirection = (product: string, tariffDetDem: ITariffDetDemDetails): ITariffDetDemDetails => {
            return this.buildDetDemDetailDirection(product, tariffDetDem);
        }
        this.scope.openRouteDetails = (offerOption: IOfferOption) => {
            this.openRouteDetails(offerOption);
        }
        this.scope.openTransitTimeDetails = (transitTime: ITransitTime) => {
            this.openTransitTimeDetails(transitTime);
        }
        this.scope.openProfitShareDetails = (profitShareDetail: IOfferProfitShareDetail) => {
            this.openProfitShareDetails(profitShareDetail);
        }
        this.scope.openPaymentReceivingChargesDetails = (currentCurrency: SelectorModel, charges: IOfferOptionCharge[], indicators: IOfferOptionIndicators) => {
            this.openPaymentReceivingChargesDetails(currentCurrency, charges, indicators);
        }
        this.scope.openChargesPerWeightRangeModal = (chargeWeightRangeList: IOfferOptionChargeWeightRange[]) => {
            this.openChargesPerWeightRangeModal(chargeWeightRangeList);
        }
        this.scope.openLinkOptions = () => {
            this.openLinkOptions();
        }
        this.scope.openTariffDetDemDetails = (tariffDetDem: ITariffDetDemDetails, tariffContract: IOfferOption) => {
            this.openTariffDetDemDetails(tariffDetDem, tariffContract);
        }
        this.scope.updateCargoCalculateFieldsDisabledControl = (amount) => {
            this.updateCargoCalculateFieldsDisabledControl(amount);
        }
        this.scope.showGenerateOpportunityModal = () => {
            this.showGenerateOpportunityModal();
        }
        this.scope.reprocessOfferOption = () => {
            this.reprocessOfferOption();
        }
        this.scope.cancelOfferOption = () => {
            this.cancelOfferOption();
        }
        this.scope.applyLinkOptionModal = (apply: boolean) => {
            this.applyLinkOptionModal(apply);
        }
        this.scope.addProfileModal = () => {
            this.addProfileModal();
        }
        this.scope.addProfile = (profileName: string, modalContext) => {
            this.addNewProfile(profileName, modalContext);
        }
        this.scope.removeProfile = (name: string) => {
            this.removeProfile(name);
        }
        this.scope.saveProfile = (name: string) => {
            this.saveProfile(name);
        }
        this.scope.loadProfile = (selectedProfile: SelectorModel) => {
            this.loadProfileData(selectedProfile);
        }
        this.scope.enableTotalGrossWeight = () => {
            this.enableTotalGrossWeight();
        }
        this.scope.back = (): void => {
            const lastStep = this.scope.steps.lastStep;
            if (this.scope.steps.current == lastStep) return;
            if (this.scope && this.scope.steps && this.scope.steps.current > ETabStep.OFFER) {
                this.scope.steps.current--;
                this.loadRegisterForm(false);
            }
        }
        this.scope.next = async (): Promise<void> => {
            const lastStep = this.scope.steps.lastStep;
            // Validate Fields
            let isValid = this.checkPendingFields("offerOldWizardModalForm");
            if (!isValid) return;

            if (this.scope && this.scope.steps && angular.isDefined(this.scope.steps.current)) {
                // Validate Tabs
                if (this.scope.steps.current == ETabStep.ROUTE && !(await this.validateRouteTab())) return;
                if (this.scope.steps.current == ETabStep.CARGO && !(this.validateCargoTab())) return;
                if (this.scope.steps.current == ETabStep.SPECIFITICY && !(await this.validateSpecTab())) return;
                if (this.scope.steps.current == ETabStep.OPTION && !(this.validateOptionTab())) return;
                // Execute a function before go to next step
                if (this.scope.steps.current == ETabStep.OFFER_PREVIEW && !(await this.generateOffer())) return;
                // Advance Tab / finish
                if (lastStep == this.scope.steps.current) return this.scope.finish();
                else if (this.scope.steps.current < ETabStep.CONFIRMATION) {
                    this.scope.steps.current++;
                }
                // Init Tabs                
                if (this.scope.steps.current == ETabStep.ROUTE) this.initRouteTab();
                else if (this.scope.steps.current == ETabStep.CARGO) this.initCargoTab();
                else if (this.scope.steps.current == ETabStep.SPECIFITICY) this.initSpecTab();
                else if (this.scope.steps.current == ETabStep.OPTION) this.initOptionTab();
                else if (this.scope.steps.current == ETabStep.OFFER_PREVIEW) this.initPreviewTab();
                this.loadRegisterForm(false);
            }
        }
        this.scope.finish = (): void => {
            this.finishOfferWizard();
        }
        this.scope.tabClick = (tabIndex: number): void => {
            if (!this.scope.steps || tabIndex == null || this.scope.isSearchingOfferOptionPaymentList) return;
            const previousStep = this.scope.steps.current - 1;
            const nextStep = this.scope.steps.current + 1;
            if (previousStep == tabIndex) this.scope.back();
            else if (nextStep == tabIndex) this.scope.next();
        }
        this.scope.goToIncoterm = (id?: number): void => {
            this.sessionService.openTab("app.registration.incoterm", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.scope.goToAccount = (id?: number): void => {
            this.sessionService.openTab("app.commercial.account", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.scope.goToExporter = (id?: number) => {
            this.sessionService.openTab("app.registration.legalPerson", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.scope.goToImporter = (id?: number) => {
            this.sessionService.openTab("app.registration.legalPerson", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.scope.goToProvider = (id: number): void => {
            this.sessionService.openTab("app.registration.provider", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.scope.goToAgent = (id: number): void => {
            this.sessionService.openTab("app.registration.agent", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.scope.goToWeightRange = (weightRanges: SelectorModel[]): void => {
            this.sessionService.openTab("app.registration.weightRange", <ILinkParameter>{ ID: this.getCONCAT(weightRanges, null, "ID") });
        }
        this.scope.goToCommodity = (commodities: SelectorModel[]): void => {
            this.sessionService.openTab("app.registration.commodity", <ILinkParameter>{ ID: this.getCONCAT(commodities, null, "ID") });
        }
        this.scope.goToTariff = (type: string, tariffId: number, tariffContractId: number) => {
            if (!type) throw Error('type is null');
            if (!tariffId && tariffId != 0) throw Error('tariffId is null');

            const contractIdOrTariffId = typeof tariffContractId == 'number' ? tariffContractId : tariffId;
            if (type == ERouteEventType.TARIFF_FREIGHT) {
                const endpoint = `${this.config.productUrl}/product/tariffFreight/getCacheById/${contractIdOrTariffId}`;
                this.sessionService.openTabByValidity(endpoint, "app.product.tariffFreight", <ILinkParameter>{ ID: contractIdOrTariffId ? contractIdOrTariffId.toString() : contractIdOrTariffId }, <ITariffFreightExchangeData>{ OPERATION: "view", ID: contractIdOrTariffId ? contractIdOrTariffId.toString() : null, ID_TARIFF: tariffId });
            }
            else if (type == ERouteEventType.TARIFF_DOMESTIC_ORIGIN || type == ERouteEventType.TARIFF_DOMESTIC_DESTINATION) {
                const endpoint = `${this.config.productUrl}/product/tariffDomestic/getCacheById/${contractIdOrTariffId}`;
                this.sessionService.openTabByValidity(endpoint, "app.product.tariffDomestic", <ILinkParameter>{ ID: contractIdOrTariffId ? contractIdOrTariffId.toString() : null }, <ITariffDomesticExchangeData>{ OPERATION: "view", ID: contractIdOrTariffId ? contractIdOrTariffId.toString() : null });
            }
            else if (type == ERouteEventType.TARIFF_LOCAL_DESTINATION || type == ERouteEventType.TARIFF_LOCAL_ORIGIN) {
                const endpoint = `${this.config.productUrl}/product/tariffLocal/getCacheById/${contractIdOrTariffId}`;
                this.sessionService.openTabByValidity(endpoint, "app.product.tariffLocal", <ILinkParameter>{ ID: tariffId ? tariffId.toString() : null }, <ITariffLocalExchangeData>{ OPERATION: "view", ID: tariffId ? tariffId.toString() : null });
            }
        }
        this.scope.goToAccountRequirement = (accountRequirementId: number) => {
            if (!accountRequirementId && accountRequirementId != 0) throw Error('accountRequirementId is null');
            this.sessionService.openTab("app.commercial.account", <ILinkParameter>{ ID: "" }, <IAccountExchangeData>{ OPERATION: "edit", ID: this.scope.model.OFFER_TAB && this.scope.model.OFFER_TAB.ACCOUNT ? parseInt(this.scope.model.OFFER_TAB.ACCOUNT.ID) : null, ID_REQUIREMENT: accountRequirementId });
        }
        this.scope.goToOffer = (offerCodeExhibition: string, offerId: number) => {
            if (!offerId) throw Error('offerId is null');
            if (!offerCodeExhibition) throw Error('concat is null');
            this.sessionService.openTab("app.commercial.offer", <IOfferWizardFinish>{ OFFER_CODE_EXHIBITION: offerCodeExhibition }, <IOfferExchangeData>{ OPERATION: EOperation.VIEW, ID: offerId });
        }
        this.scope.getAccountListByName = async (search: string) => {
            let accountList: SelectorModel[] = [];
            if (search && search.length >= 3) accountList = await this.getAccountListByName(search);
            this.scope.accountList = accountList;
        }
        this.scope.getExporterListByName = async (search: string): Promise<void> => {
            let exporterList: SelectorModel[] = [];
            if (search && search.length >= 3) exporterList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.EXPORTER], search: search });
            this.scope.exporterList = exporterList;
        }
        this.scope.getImporterListByName = async (search: string): Promise<void> => {
            let importerList: SelectorModel[] = [];
            if (search && search.length >= 3) importerList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.IMPORTER], search: search });
            this.scope.importerList = importerList;
        }
        this.scope.getProviderListByName = async (search: string): Promise<void> => {
            let providerList: SelectorModel[] = [];
            if (search && search.length >= 3) providerList = await this.getProviderListByName(search);
            this.scope.providerList = providerList;
        }
        this.scope.getBuyingRateListByName = async (search: string): Promise<void> => {
            let buyingRateList: SelectorModel[] = [];
            const product = this.scope.model.OFFER_TAB.PRODUCT ? [this.scope.model.OFFER_TAB.PRODUCT.ID] : [];
            const typeCargo = this.scope.model.OFFER_TAB.TYPE_CARGO ? [this.scope.model.OFFER_TAB.TYPE_CARGO.ID] : [];
            const account = this.scope.model.OFFER_TAB.ACCOUNT ? [parseInt(this.scope.model.OFFER_TAB.ACCOUNT.ID)] : [];
            const provider = this.scope.model.OFFER_TAB.PROVIDER ? [parseInt(this.scope.model.OFFER_TAB.PROVIDER.ID)] : [];
            const refDate = this.scope.model.OFFER_TAB.LOAD_REF ? this.scope.model.OFFER_TAB.LOAD_REF : null;
            if (search && search.length >= 2) {
                if (!this.scope.model.OFFER_TAB.PRODUCT) {
                    this.notifyError(this.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                } else if (!this.scope.model.OFFER_TAB.TYPE_CARGO) {
                    this.notifyError(this.getTranslate('PRODUCT.SELECT_CARGO_TYPE_FIRST'));
                } else if (!this.scope.model.OFFER_TAB.ACCOUNT) {
                    this.notifyError(this.getTranslate('PRODUCT.SELECT_CARGO_TYPE_FIRST'));
                } else buyingRateList = await this.getTariffFreightCustomOfferListByName({ search: search, paymentNature: [EPaymentNatureId.PAYMENT], products: product, typeCargo: typeCargo, accounts: account, providers: provider, refDate: refDate });
            }
            this.scope.buyingRateList = buyingRateList;
        }
        this.scope.getSellingRateListByName = async (search: string): Promise<void> => {
            let sellingRateList: SelectorModel[] = [];
            const product = this.scope.model.OFFER_TAB.PRODUCT ? [this.scope.model.OFFER_TAB.PRODUCT.ID] : [];
            const typeCargo = this.scope.model.OFFER_TAB.TYPE_CARGO ? [this.scope.model.OFFER_TAB.TYPE_CARGO.ID] : [];
            const account = this.scope.model.OFFER_TAB.ACCOUNT ? [parseInt(this.scope.model.OFFER_TAB.ACCOUNT.ID)] : [];
            const provider = this.scope.model.OFFER_TAB.PROVIDER ? [parseInt(this.scope.model.OFFER_TAB.PROVIDER.ID)] : [];
            const refDate = this.scope.model.OFFER_TAB.LOAD_REF ? this.scope.model.OFFER_TAB.LOAD_REF : null;
            if (search && search.length >= 2) {
                if (!this.scope.model.OFFER_TAB.PRODUCT) {
                    this.notifyError(this.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                } else if (!this.scope.model.OFFER_TAB.TYPE_CARGO) {
                    this.notifyError(this.getTranslate('PRODUCT.SELECT_CARGO_TYPE_FIRST'));
                } else if (!this.scope.model.OFFER_TAB.ACCOUNT) {
                    this.notifyError(this.getTranslate("PRODUCT.SELECT_ACCOUNT_FIRST"));
                } else sellingRateList = await this.getTariffFreightCustomOfferListByName({ search: search, paymentNature: [EPaymentNatureId.RECEIVING], products: product, typeCargo: typeCargo, accounts: account, providers: provider, refDate: refDate });
            }
            this.scope.sellingRateList = sellingRateList;
        }
        this.scope.getCommodityListByName = async (search: string): Promise<void> => {
            let commodityList: SelectorModel[] = [];
            if (search && search.length >= 2) commodityList = await this.getCommodityListByName(search);
            this.scope.commodityList = commodityList;
        }
        this.scope.getSectionList = async (): Promise<void> => {
            if (this.scope.model && this.scope.model.OFFER_TAB) {
                if (this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_PAYMENT || this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_RECEIVING) {
                    const sections: SelectorModel[] = await this.restService.newObjectPromise(`${this.productUrl}/tariffFreight/list/commoditySection`, { ID_TARIFF_FREIGHT_CONTRACT: this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_PAYMENT ? this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_PAYMENT.ID : null, ID_TARIFF_FREIGHT_CONTRACT_RECEIVING: this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_RECEIVING ? this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_RECEIVING.ID : null }, 30000, false);
                    if (sections && sections.length > 0) {
                        this.scope.sectionList = sections;
                        this.scope.$applyAsync();
                    } else await this.setDefaultCommoditySectionList();

                } else {
                    await this.setDefaultCommoditySectionList();
                }
            }
        }
        this.scope.getRoutingPointListByName = async (name: string, params: IParam[], directionId: string): Promise<void> => {
            let routingPointList: IRoutingPointSelector[] = [];
            if (name && name.length >= 2) {
                if (!params) return this.notifyError("params is null");
                if (!directionId) return this.notifyError("directionId is null");
                const directionParams: IParam[] = params && params.length > 0 ? params.filter(param => param.DIRECTION && param.DIRECTION.ID == directionId) : null;
                let types: string[] = [];
                if (directionParams) {
                    if (directionId == EDirectionId.ORIGIN) {
                        for (let i = 0; i < directionParams.length; i++) {
                            if (directionParams[i] && directionParams[i].TYPE_RULE && directionParams[i].TYPE_RULE.ID == ETypeRuleId.MANDATORY) {
                                types = directionParams[i].TYPE_LOCAL_EVENT && directionParams[i].TYPE_LOCAL_EVENT.length > 0 ? directionParams[i].TYPE_LOCAL_EVENT.map(local => local.ID) : null;
                                break;
                            }
                        }
                    } else if (directionId == EDirectionId.DESTINATION) {
                        for (let i = directionParams.length; i >= 0; i--) {
                            if (directionParams[i] && directionParams[i].TYPE_RULE && directionParams[i].TYPE_RULE.ID == ETypeRuleId.MANDATORY) {
                                types = directionParams[i].TYPE_LOCAL_EVENT && directionParams[i].TYPE_LOCAL_EVENT.length > 0 ? directionParams[i].TYPE_LOCAL_EVENT.map(local => local.ID) : null;
                                break;
                            }
                        }
                    }
                }
                routingPointList = await this.getRoutingPointListByName(name, types);
            }
            this.scope.routingPointList = routingPointList;
        }
        this.scope.getCurrencyListByName = async (search: string): Promise<void> => {
            let currencyList: SelectorModel[] = [];
            if (search && search.length >= 2) currencyList = await this.getCurrencyListByName(search);
            this.scope.currencyList = currencyList;
        }
        this.scope.getEquipmentListByNameOrCode = async (search: string) => {
            let equipmentList: IEquipmentSelector[] = [];
            if (search && search.length >= 2) equipmentList = await this.getEquipmentListByNameOrCode(search);
            this.scope.equipmentList = equipmentList;
        }
        this.scope.getAgentListByName = async (search: string, networkId: number): Promise<void> => {
            let agentList: SelectorModel[] = [];
            if (search && search.length >= 3) agentList = await this.getAgentListByName({ search: search, products: this.scope.model.OFFER_TAB.PRODUCT ? [this.scope.model.OFFER_TAB.PRODUCT.ID] : [], networks: networkId ? [networkId] : [] });
            this.scope.agentList = agentList;
        }
        this.scope.getBrokerListByName = async (search: string) => {
            let brokerList: SelectorModel[] = [];
            if (search && search.length >= 3) brokerList = await this.getBrokerListByName({ products: [], search: search });
            this.scope.brokerList = brokerList;
        }
        this.scope.getInsuranceBrokerListByName = async (search: string) => {
            let insuranceBrokerList: SelectorModel[] = [];
            if (search && search.length >= 3) insuranceBrokerList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.INSURANCE_BROKER], search: search });
            this.scope.insuranceBrokerList = insuranceBrokerList;
        }
        this.scope.getApplicationListByName = async (product: SelectorModel[], typeCargo: SelectorModel[], search: string): Promise<void> => {
            this.scope.applicationList = await this.getApplicationListByName(product, typeCargo, search);
        }
        this.scope.getChargeNameListByName = async (search: string, products?: SelectorModel[], paramTypeCargo?: SelectorModel[]) => {
            let chargeNameList: SelectorModel[] = [];
            if (!paramTypeCargo) return this.notifyError("paramTypeCargo is null");
            if (!products) return this.notifyError("products is null");
            products = this.scope.defaultProductSecure ? this.scope.defaultProductSecure : products;
            paramTypeCargo = this.scope.defaultProfitShareTypeCargo ? this.scope.defaultProfitShareTypeCargo : paramTypeCargo;

            if (search && search.length >= 2) chargeNameList = await this.getChargeNameListByName({ search, types: ['6'], products: products && products.length > 0 ? products.map(product => product.ID) : null, paramTypeCargo: paramTypeCargo && paramTypeCargo.length > 0 ? paramTypeCargo.map(typeCargo => typeCargo.ID) : null });
            this.scope.chargeNameList = chargeNameList;
        }
        this.scope.getAndUpdateRouteAccountValues = async (routeIndex: number, masterDirect?: boolean): Promise<void> => {
            this.getAndUpdateRouteAccountValues(routeIndex, masterDirect);
        }
        this.scope.getAndUpdateOptionList = () => {
            this.getAndUpdateOptionList();
        }
        this.scope.getOfferOptionInvalidCharge = (offerOption: IOfferOptionInvalid) => {
            this.getOfferOptionInvalidCharge(offerOption);
        }
        this.scope.getEquipmentProviderListByName = async (search: string): Promise<void> => {
            let equipmentProviderList: SelectorModel[] = [];
            if (search && search.length >= 3) equipmentProviderList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.EQUIPMENT_PROVIDER], search: search });
            this.scope.equipmentProviderList = equipmentProviderList;
        }
        this.scope.routingPointRouteFilter = (index: number, field: string) => {
            return this.routingPointRouteFilter(index, field);
        }
        this.scope.offerOptionChargeTypeFilter = (charge: IOfferOptionCharge) => {
            return (!charge.CHARGE_NAME) || (charge.CHARGE_NAME && !charge.CHARGE_NAME.TYPE) || (charge.CHARGE_NAME && charge.CHARGE_NAME.TYPE && charge.CHARGE_NAME.TYPE.ID != EChargeOriginId.COMISSION);
        }
        this.scope.weightBreakdownChange = async (value: boolean): Promise<void> => {
            const cargoAirModel = this.scope.model.CARGO_TAB && this.scope.model.CARGO_TAB.AIR ? this.scope.model.CARGO_TAB.AIR : null;
            if (!cargoAirModel) throw this.notifyError('Air Cargo was not found.');
            if (value) {
                cargoAirModel.WEIGHT_RANGE = angular.copy(this.scope.weightRangeList);
                cargoAirModel.CHARGEABLE_WEIGHT = null;
            } else {
                const confirm = await this.modalService.showModalConfirmation({}, {
                    actionButtonText: "GENERAL.YES",
                    closeButtonText: "GENERAL.NO",
                    headerText: "GENERAL.CONFIRM_ACTION",
                    bodyText: this.getTranslate("PRODUCT.RESET_WEIGHT_RANGES")
                });
                if (!confirm) return;
                cargoAirModel.WEIGHT_RANGE = null;
            }
            this.calculateOperationChargeable(value);
        }
        this.scope.grossWeightChange = () => {
            if (this.scope.model.OFFER_TAB && this.scope.model.OFFER_TAB.TYPE_CARGO) {
                if (this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.AIR) this.calculateOperationChargeable();
                else if (this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.BREAK_BULK || this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.LCL || this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.RO_RO) this.calculateOperationWM();
            }
        }
        this.scope.cubicWeightChange = () => {
            if (this.scope.model.OFFER_TAB && this.scope.model.OFFER_TAB.TYPE_CARGO) {
                if (this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.AIR) this.calculateOperationChargeable();
                else if (this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.BREAK_BULK || this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.LCL || this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.RO_RO) this.calculateOperationWM();
            }
        }
        this.scope.weightRangeChange = () => {
            this.updateWeightRangeBase();
        }
        this.scope.productChange = (): void => {
            this.productChange();
        }
        this.scope.cargoTypeChange = (): void => {
            this.resetBuyingSellingRate();
            this.resetCargoTabModel();
        }
        this.scope.offerTypeChange = () => {
            if (!this.scope.isOfferTypeStudyOrSpot()) this.scope.model.OFFER_TAB.ACCOUNT_REQUEST = null;
            else this.scope.model.OFFER_TAB.ACCOUNT_REQUEST = this.getTodayOnlyDate();
        }
        this.scope.tariffChange = async (tariffSelected: SelectorModel, tariffType: string): Promise<void> => {
            this.tariffChange(tariffSelected, tariffType);
        }
        this.scope.specDangerousCargoChange = (): void => {
            this.specDangerousCargoChange();
        }
        this.scope.specSecureChange = (): void => {
            this.specSecureChange();
        }
        this.scope.specRouteAgentChange = (index: number): void => {
            this.specRouteAgentChange(index);
        }
        this.scope.ordinationChange = (optionOrdination: SelectorModel) => {
            if (optionOrdination) {
                this.scope.offerOptions = this.scope.offerOptions.sort((x, y) => {
                    return x.INDICATORS[optionOrdination.CODE] < y.INDICATORS[optionOrdination.CODE] ? 1 : -1;
                });
            }
        }
        this.scope.chargeNameChange = (optionChargeName: SelectorModel): void => {
            this.chargeNameChange(optionChargeName);
        }
        this.scope.cargoAirChargeableWeightChange = (amount: number) => {
            this.updateCargoCalculateFieldsDisabledControl(amount);
            this.updateWeightBreakBasedOverChargeableWeightOrGrossWeight([amount > this.scope.model.CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT ? amount : this.scope.model.CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT]);
            this.updateAirApplication();
        }
        this.scope.cargoAirOverChargeableWeightChange = (amount: number) => {
            if (!this.scope.model.CARGO_TAB.AIR.WEIGHT_BREAKDOWN) this.updateWeightBreakBasedOverChargeableWeightOrGrossWeight([this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT > amount ? this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT : amount]);
            if (this.scope.model.CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT && this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.AIR) this.updateWeightBreakBasedOverChargeableWeightOrGrossWeight([this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT > amount ? this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT : amount]);

            this.updateAirApplication();
        }
        this.scope.cargoListGrossWeightFieldsControl = (grossWeightTotal: number) => {
            this.scope.isCargoListGrossWeightFieldsDisabled = this.scope.isCargoTotalGrossWeightDisabled() ? false : grossWeightTotal !== null && angular.isDefined(grossWeightTotal);
        }
        this.scope.addRoute = (): void => {
            this.addRoute();
        }
        this.scope.removeRoute = (index: number): void => {
            this.removeRoute(index);
        }
        this.scope.copyRoute = (index: number): void => {
            this.copyRoute(index);
        }
        this.scope.addCargoFCL = (): void => {
            this.addCargoFCL();
        }
        this.scope.removeCargoFCL = (index: number): void => {
            this.removeCargoFCL(index);
        }
        this.scope.addCargoFTL = (): void => {
            this.addCargoFTL();
        }
        this.scope.removeCargoFTL = (index: number): void => {
            this.removeCargoFTL(index);
        }
        this.scope.addCargoBBRORO = (): void => {
            this.addCargoBBRORO();
        }
        this.scope.removeCargoBBRORO = (index: number): void => {
            this.removeCargoBBRORO(index);
        }
        this.scope.addCargo = (): void => {
            this.addCargo();
        }
        this.scope.removeCargo = (index: number): void => {
            this.removeCargo(index);
        }
        this.scope.addDangerousCargo = (): void => {
            this.addDangerousCargo();
        }
        this.scope.removeDangerousCargo = (index: number): void => {
            this.removeDangerousCargo(index);
        }
        this.scope.launchCargoPolLb = async (): Promise<void> => {
            this.launchCargoPolLb();
        }
        this.scope.applySelectionModal = async (apply: boolean) => {
            await this.applySelectionModal(apply);
        }
        this.scope.selectAll = (clickedIn?: boolean) => this.selectAll(clickedIn);
        this.scope.selectAllOfferOption = (clickedIn?: boolean) => this.selectAllOfferOption(clickedIn);
        this.scope.selectAllOfferOptionConfirmation = (clickedIn?: boolean) => this.selectAllOfferOptionConfirmation(clickedIn);
        this.scope.isTabEnabled = (tabIndex: number): boolean => {
            if (!this.scope.steps || tabIndex == null) return false;
            const previousStep = this.scope.steps.current - 1;
            const nextStep = this.scope.steps.current + 1;
            return (previousStep == tabIndex || nextStep == tabIndex);
        }
        this.scope.isOfferTypeStudyOrSpot = () => {
            return this.scope.model && this.scope.model.OFFER_TAB && this.scope.model.OFFER_TAB.OFFER_TYPE && (this.scope.model.OFFER_TAB.OFFER_TYPE.ID == '1' || this.scope.model.OFFER_TAB.OFFER_TYPE.ID == '2');
        }
        this.scope.isLclAmountDisabled = (): boolean => {
            return ((this.scope.model.CARGO_TAB.CARGO_LIST && this.scope.model.CARGO_TAB.CARGO_LIST.length > 0) || this.scope.model.CARGO_TAB.VOLUME || this.scope.model.CARGO_TAB.GROSS_WEIGHT || this.scope.model.CARGO_TAB.CUBIC_WEIGHT) ? true : false;
        }
        this.scope.isAirChargeableWeightDisabled = (): boolean => {
            return ((this.scope.model.CARGO_TAB.CARGO_LIST && this.scope.model.CARGO_TAB.CARGO_LIST.length > 0) || (this.scope.model.CARGO_TAB && this.scope.model.CARGO_TAB.AIR && this.scope.model.CARGO_TAB.AIR.WEIGHT_BREAKDOWN) || this.scope.model.CARGO_TAB.VOLUME || this.scope.model.CARGO_TAB.GROSS_WEIGHT || this.scope.model.CARGO_TAB.CUBIC_WEIGHT) ? true : false;
        }
        this.scope.isProfileActive = () => {
            return this.scope.inputType == EInputTypes.MANUAL;
        }
        this.scope.isCargoTotalGrossWeightDisabled = (): boolean => {
            const cargoList = this.scope.model && this.scope.model.CARGO_TAB ? this.scope.model.CARGO_TAB.CARGO_LIST : null;
            return cargoList && cargoList.findIndex(cargo => (cargo.GROSS_WEIGHT_UNITARY || cargo.GROSS_WEIGHT_UNITARY == 0) || (cargo.GROSS_WEIGHT || cargo.GROSS_WEIGHT == 0)) >= 1;
        }
        this.scope.isCargoInOffer = (): boolean => {
            if (this.scope.model.CARGO_TAB.CARGO_LIST && this.scope.model.CARGO_TAB.CARGO_LIST.length >= 1) return this.scope.disableFieldCargo = true;
            else return this.scope.disableFieldCargo = false;
        }
    }

    private async getOfferOptionInvalidCharge(offerOption: IOfferOptionInvalid) {
        try {
            if (offerOption.SHOW && (!offerOption.CHARGES || offerOption.CHARGES.length == 0)) {
                this.block();
                const result = await this.restService.getObjectAsPromise(`${this.productUrl}/offerOldWizard/offerOptionInvalid/charge/${offerOption.ID_TARIFF_FREIGHT}`, 30000, null, false);
                if (result && result.data) offerOption.CHARGES = result.data;
                else offerOption.CHARGES = [];
                this.scope.$applyAsync();
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getCommoditySectionList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/hscode_section`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async setDefaultCommoditySectionList(): Promise<void> {
        try {
            this.block();
            const sections = await this.getCommoditySectionList();
            this.scope.sectionList = sections;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    async initModel(): Promise<void> {
        this.scope.model = {
            _id: null,
            ID_OPPORTUNITY: this.scope.idOfferOpportunity ? this.scope.idOfferOpportunity : null,
            REPROCESS: false,
            OFFER_TAB: {
                PRODUCT: null,
                TYPE_CARGO: null,
                LOAD_REF: this.getTodayOnlyDate(),
                ACCOUNT: null,
                EXPORTER: null,
                IMPORTER: null,
                NOTIFY: null,
                PROVIDER: null,
                INCOTERM: null,
                OFFER_TYPE: this.scope.offerTypeList.length > 0 ? this.scope.offerTypeList[0] : null,
                PROCESS_TYPE: this.scope.processTypeList.length > 0 ? this.scope.processTypeList[0] : null,
                CURRENCY: null,
                VALUE: null,
                ACCOUNT_REQUEST: this.getTodayOnlyDate(),
                TARIFF_FREIGHT_CONTRACT_PAYMENT: null,
                TARIFF_FREIGHT_CONTRACT_RECEIVING: null,
                ID_TARIFF_FREIGHT_PAYMENT: null,
                ID_TARIFF_FREIGHT_RECEIVING: null,
                COMMODITY: null,
                COMMODITY_SECTION: null,
                NEW_ALTERNATIVE: false,
                ID_OFFER: null
            },
            ROUTE_TAB: {
                ROUTES: null
            },
            CARGO_TAB: {
                BB_RORO: null,
                FCL: null,
                FTL: null,
                AIR: null,
                LCL_APPLICATION: null,
                LCL_AMOUNT: null,
                VOLUME: 0,
                GROSS_WEIGHT: 0,
                CUBIC_WEIGHT: 0,
                CARGO_LIST: null,
                STACKABLE: true,
                STANDARD_EQUIPMENT: null,
                CARGO_SOC: false,
                EQUIPMENT_PROVIDER: null,
                ID_EQUIPMENT_PROVIDER: null,
                FLEXITANK: false,
            },
            SPEC_TAB: {
                ROUTE: null,
                IS_DANGEROUS_CARGO: false,
                DANGEROUS_CARGO: null,
                IS_INSURANCE: false,
                SECURE: null,
                IS_REFRIGERATED_CARGO: false,
                REFRIGERATED_CARGO: null,
                IS_CARGO_TREATMENT: false,
                CARGO_TREATMENT: null
            },
            PREVIEW_TAB: {
                CONCATENATED_COMPLEMENT: null,
                MODALITY_FH: null,
                MODALITY_FM: null
            },
            FINISH_TAB: {
                OFFER_CODE_EXHIBITION: null,
                CONCATENATED: null,
                LOAD_REF: null
            },
            COMPLETED: false,
            CREATED_BY: null,
            MODIFIED_BY: null,
            CREATED_AT: null,
            MODIFIED_AT: null
        };
    }

    private async initControl() {
        this.scope.basicInfoList = null;
        this.scope.basicInfoSelection = {
            ACCOUNT: null
        };
        this.scope.selectionModalControl = { accountSelectionList: null, isAccountMandatory: null };
        this.scope.steps = { current: 0, percent: 0, lastStep: 6 };
        this.scope.searchOfferOptionPaymentListMaxAttempt = 60;
    }

    private async initList() {
        this.checkInputType();
        this.checkTurnOnProfile();
        if (this.scope.inputType == EInputTypes.TARIFF_FREIGHT_CONTRACT || this.scope.inputType == EInputTypes.TARIFF_FREIGHT) {
            await this.initForContractOrTariff();
            this.loadRegisterForm(false);
        }
        if (this.scope.inputType == EInputTypes.REPLICATE_OFFER) {
            await this.initForReplicateModel();
            this.loadRegisterForm(false);
        }
        if (this.scope.inputType == EInputTypes.PRE_OFFER) {
            await this.initForPreOfferModel();
            this.loadRegisterForm(false);
        }
    }

    private checkInputType() {
        if (!this.scope.inputType && this.scope.idTariffFreightContract) {
            this.scope.inputType = EInputTypes.TARIFF_FREIGHT_CONTRACT;
        } else if (!this.scope.inputType && this.scope.idTariff) {
            this.scope.inputType = EInputTypes.TARIFF_FREIGHT;
        } else if (!this.scope.inputType && this.scope.idOffer) {
            this.scope.inputType = EInputTypes.REPLICATE_OFFER;
        } else if (!this.scope.inputType && this.scope.idOfferOpportunity) {
            this.scope.inputType = EInputTypes.PRE_OFFER;
        } else {
            this.scope.inputType = EInputTypes.MANUAL;
        }
    }

    private async checkTurnOnProfile() {
        if (this.scope.inputType == EInputTypes.MANUAL) {
            await this.loadProfileList(this.persistName);
        }
    }

    private async initForContractOrTariff() {
        if (this.scope.idPaymentNature) {
            if (this.scope.idPaymentNature.toString() == EPaymentNatureId.PAYMENT) this.scope.model.OFFER_TAB.ID_TARIFF_FREIGHT_PAYMENT = this.scope.idTariff;
            else if (this.scope.idPaymentNature.toString() == EPaymentNatureId.RECEIVING) this.scope.model.OFFER_TAB.ID_TARIFF_FREIGHT_RECEIVING = this.scope.idTariff;
        } else {
            this.scope.model.OFFER_TAB.ID_TARIFF_FREIGHT_PAYMENT = null;
            this.scope.model.OFFER_TAB.ID_TARIFF_FREIGHT_RECEIVING = null;
        }
        const productBefore = angular.copy(this.scope.model.OFFER_TAB.PRODUCT);
        this.scope.basicInfoList = await this.getOfferWizardBasicInfo(this.scope.idTariffFreightContract, this.scope.idTariff);
        if (!this.scope.basicInfoList) {
            this.notifyError('It was not possible to find the basic tariff data associated with opening the screen.');
        } else {
            if (this.scope.selectionModalControl) this.scope.selectionModalControl.accountSelectionList = this.scope.basicInfoList.ACCOUNTS;
            if (this.scope.basicInfoList.ACCOUNTS && this.scope.basicInfoList.ACCOUNTS.length > 1 && !this.scope.model.OFFER_TAB.ACCOUNT) {
                if (this.scope.selectionModalControl) this.scope.selectionModalControl.isAccountMandatory = true;
                const confirmed = await this.openSelectionModal();
                if (confirmed) this.scope.model.OFFER_TAB.ACCOUNT = this.scope.basicInfoSelection.ACCOUNT;
            } else if (this.scope.basicInfoList.ACCOUNTS && this.scope.basicInfoList.ACCOUNTS.length) {
                this.scope.model.OFFER_TAB.ACCOUNT = this.scope.basicInfoList.ACCOUNTS[0];
            }
            this.scope.model.OFFER_TAB.PRODUCT = this.scope.basicInfoList.PRODUCT;
            this.scope.model.OFFER_TAB.TYPE_CARGO = this.scope.basicInfoList.TYPE_CARGO;
            this.scope.model.OFFER_TAB.LOAD_REF = this.getLoadRefDate(this.scope.basicInfoList.VALIDITY_START, this.scope.basicInfoList.PAYMENT_NATURE.ID);
            if (!this.scope.model.OFFER_TAB.PROVIDER) this.scope.model.OFFER_TAB.PROVIDER = this.scope.basicInfoList.PROVIDER;
            if (this.scope.basicInfoList.PAYMENT_NATURE) {
                if (this.scope.basicInfoList.PAYMENT_NATURE.ID == EPaymentNatureId.PAYMENT) this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_PAYMENT = { ID: this.scope.basicInfoList.ID.toString(), NAME: this.scope.basicInfoList.CONCATENATED };
                else if (this.scope.basicInfoList.PAYMENT_NATURE.ID == EPaymentNatureId.RECEIVING) this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_RECEIVING = { ID: this.scope.basicInfoList.ID.toString(), NAME: this.scope.basicInfoList.CONCATENATED };
            }
        }
        if (JSON.stringify(productBefore) != JSON.stringify(this.scope.model.OFFER_TAB.PRODUCT)) this.productChange(true);

        // Call the method to retrieve commodities list, based on tariff freight contract id.        
        this.scope.commodityList = await this.getCommodityListByName();
        // Call the method to retrieve the section list, based on tariff freight contract id.
        await this.scope.getSectionList();
        this.scope.$applyAsync();
    }

    private async initForReplicateModel() {
        const offerModel = await this.restService.getObjectAsPromise(`${this.productUrl}/offerOldWizard/offerToWizard/${this.scope.idOffer}`, 30000, null, false);
        if (offerModel && offerModel.data && offerModel.data[0]) {
            this.scope.model.OFFER_TAB = offerModel.data[0].OFFER_TAB;
            this.scope.model.ROUTE_TAB = offerModel.data[0].ROUTE_TAB;
            this.scope.model.CARGO_TAB = offerModel.data[0].CARGO_TAB;
            this.scope.model.SPEC_TAB = offerModel.data[0].SPEC_TAB;
            this.scope.model.COMPLETED = offerModel.data[0].COMPLETED;
            this.scope.model.CREATED_BY = offerModel.data[0].CREATED_BY;
            this.scope.model.MODIFIED_BY = offerModel.data[0].MODIFIED_BY;
            this.scope.model.CREATED_AT = offerModel.data[0].CREATED_AT;
            this.scope.model.MODIFIED_AT = offerModel.data[0].MODIFIED_AT;

            // LCL_AMOUNT format decimals
            if (this.scope.model.CARGO_TAB && this.scope.model.CARGO_TAB.LCL_AMOUNT) this.scope.model.CARGO_TAB.LCL_AMOUNT = NumberUtil.formatDecimals(this.scope.model.CARGO_TAB.LCL_AMOUNT, 3);

        }
        this.productChange(true, true);
        this.scope.$applyAsync();
    }

    private async initForPreOfferModel() {
        const offerModel = await this.restService.getObjectAsPromise(`${this.productUrl}/opportunity/offerOpportunityToWizard/${this.scope.idOfferOpportunity}`, 30000, null, false);
        if (offerModel && offerModel.data) {
            this.scope.model.OFFER_TAB = offerModel.data.OFFER_TAB;
            this.scope.model.ROUTE_TAB = offerModel.data.ROUTE_TAB;
            this.scope.model.CARGO_TAB = offerModel.data.CARGO_TAB;
            this.scope.model.SPEC_TAB = offerModel.data.SPEC_TAB;
            this.scope.model.COMPLETED = offerModel.data.COMPLETED;
            this.scope.model.CREATED_BY = offerModel.data.CREATED_BY;
            this.scope.model.CREATED_AT = offerModel.data.CREATED_AT;

            // LCL_AMOUNT format decimals
            if (this.scope.model.CARGO_TAB && this.scope.model.CARGO_TAB.LCL_AMOUNT) this.scope.model.CARGO_TAB.LCL_AMOUNT = NumberUtil.formatDecimals(this.scope.model.CARGO_TAB.LCL_AMOUNT, 3);
        }
        this.productChange(true, true);
        this.scope.$applyAsync();
    }

    private tariffChange(tariffSelected: SelectorModel, tariffType: string) {
        if (tariffSelected) {
            this.scope.idTariffFreightContract = parseInt(tariffSelected.ID);
            this.initList();
        } else {
            if (tariffType == EPaymentNatureId.PAYMENT) this.scope.model.OFFER_TAB.ID_TARIFF_FREIGHT_PAYMENT = null;
            else if (tariffType == EPaymentNatureId.RECEIVING) this.scope.model.OFFER_TAB.ID_TARIFF_FREIGHT_RECEIVING = null;
        }
    }

    private enableTotalGrossWeight() {
        const cargoList = this.scope.model.CARGO_TAB.CARGO_LIST;
        if (cargoList) {
            this.scope.model.CARGO_TAB.GROSS_WEIGHT = null;
            for (const cargo of cargoList) {
                cargo.GROSS_WEIGHT_UNITARY = null;
                cargo.GROSS_WEIGHT = null;
            }
            angular.element("#cargoGrossWeight").focus();
        }
    }

    private specDangerousCargoChange() {
        if (this.scope.model.SPEC_TAB.IS_DANGEROUS_CARGO) {
            this.scope.model.SPEC_TAB.DANGEROUS_CARGO = [{ UNITY: null, CLASS: null, PACKING_GROUP: null }];
        } else this.scope.model.SPEC_TAB.DANGEROUS_CARGO = null;
    }

    private async specSecureChange() {
        if (this.scope.model.SPEC_TAB) {
            if (this.scope.model.SPEC_TAB.IS_INSURANCE) {
                const offerProduct = this.scope.model.OFFER_TAB && this.scope.model.OFFER_TAB.PRODUCT ? this.scope.model.OFFER_TAB.PRODUCT.ID : null;
                // Note: the product configuration registration will always have at most one registration.
                try {
                    this.block();
                    let result = null
                    result = await this.getProductConfigurationList();
                    if (result && result.data) this.scope.productConfigurationList = result.data;
                } catch (ex) {
                    this.handleError(ex);
                } finally {
                    this.unblock();
                }
                const productConfigurationSecure: IProductConfigurationSecure = this.scope.productConfigurationList && this.scope.productConfigurationList.length > 0 && this.scope.productConfigurationList[0].SECURE && this.scope.productConfigurationList[0].SECURE.length > 0 ? this.scope.productConfigurationList[0].SECURE.find(secure => secure.PRODUCT.some(product => product.ID == offerProduct)) : null;
                const productConfigurationTypeCargo: SelectorModel[] = this.scope.productConfigurationList && this.scope.productConfigurationList.length > 0 && this.scope.productConfigurationList[0].PROFIT_SHARE_APPLICATION && this.scope.productConfigurationList[0].PROFIT_SHARE_APPLICATION.length > 0 ? this.scope.productConfigurationList[0].PROFIT_SHARE_APPLICATION.find(profitShare => profitShare.TYPE_CARGO).TYPE_CARGO : null;
                const secureObject = {
                    CURRENCY: null,
                    INSURED_AMOUNT: null,
                    INSURANCE_PREMIUM: null,
                    INSURANCE_RECEIVING_MIN: null,
                    COMISSION_WITHOUT_PRIZE: null,
                    INSURANCE_PAYMENT_MIN: null,
                    INSURANCE_BROKER: null,
                    ID_CHARGE_NAME_EXHIBITION: null,
                    CHARGE_NAME_EXHIBITION: null,
                    APPLICATION_PAYMENT: null,
                    APPLICATION_RECEIVING: null
                };
                if (productConfigurationSecure) {
                    secureObject.INSURANCE_PREMIUM = productConfigurationSecure.INSURANCE_PREMIUM;
                    secureObject.INSURANCE_RECEIVING_MIN = productConfigurationSecure.INSURANCE_RECEIVING_MIN;
                    secureObject.COMISSION_WITHOUT_PRIZE = productConfigurationSecure.STANDARD_COMISSION_WITHOUT_PRIZE;
                    secureObject.INSURANCE_PAYMENT_MIN = productConfigurationSecure.INSURANCE_PAYMENT_MIN;
                    secureObject.INSURANCE_BROKER = productConfigurationSecure.INSURANCE_BROKER;
                    secureObject.ID_CHARGE_NAME_EXHIBITION = productConfigurationSecure.CHARGE_NAME_EXHIBITION.ID;
                    secureObject.CHARGE_NAME_EXHIBITION = productConfigurationSecure.CHARGE_NAME_EXHIBITION;
                    secureObject.APPLICATION_PAYMENT = productConfigurationSecure.APPLICATION_PAYMENT;
                    secureObject.APPLICATION_RECEIVING = productConfigurationSecure.APPLICATION_RECEIVING;
                    this.scope.defaultProductSecure = productConfigurationSecure.PRODUCT;
                }

                if (productConfigurationTypeCargo) {
                    this.scope.defaultProfitShareTypeCargo = productConfigurationTypeCargo
                }

                this.scope.model.SPEC_TAB.SECURE = secureObject;
            }
            else this.scope.model.SPEC_TAB.SECURE = null;
        }
    }

    private specRouteAgentChange(index: number): void {
        if (index == null) return this.notifyError('index is null');
        if (this.scope.model.SPEC_TAB && this.scope.model.SPEC_TAB.ROUTE && this.scope.model.SPEC_TAB.ROUTE.length && this.scope.model.SPEC_TAB.ROUTE[index]) {
            const route = this.scope.model.SPEC_TAB.ROUTE[index];
            route.MASTER_MODALITY = null;
            route.HOUSE_MODALITY = null;
            this.timeout(() => {
                this.scope.selectorValidity("specRouteMasterModality" + index);
                this.scope.selectorValidity("specRouteHouseModality" + index);
            }, 200);
            if (!route.LOCAL_AGENT && !route.EXTERNAL_AGENT && !route.OTHER_AGENT) route.AGENT_NETWORK_ID = null;
            else if (route.LOCAL_AGENT && !route.EXTERNAL_AGENT && !route.OTHER_AGENT) route.AGENT_NETWORK_ID = parseInt(route.LOCAL_AGENT.CODE);
            else if (route.EXTERNAL_AGENT && !route.LOCAL_AGENT && !route.OTHER_AGENT) route.AGENT_NETWORK_ID = parseInt(route.EXTERNAL_AGENT.CODE);
            else if (route.OTHER_AGENT && !route.LOCAL_AGENT && !route.EXTERNAL_AGENT) route.AGENT_NETWORK_ID = parseInt(route.OTHER_AGENT.CODE);
        }
    }

    private async getAndUpdateRouteAccountValues(routeIndex: number, masterDirect?: boolean) {
        try {
            if (routeIndex == null) return this.notifyError('routeIndex is null');
            const route = this.scope.model.SPEC_TAB && this.scope.model.SPEC_TAB.ROUTE && this.scope.model.SPEC_TAB.ROUTE.length && this.scope.model.SPEC_TAB.ROUTE[routeIndex] ? this.scope.model.SPEC_TAB.ROUTE[routeIndex] : null;
            const offerTabModel = this.scope.model.OFFER_TAB ? this.scope.model.OFFER_TAB : null;
            if (!route) this.notifyError('route not found');
            if (offerTabModel && this.scope.model.SPEC_TAB) {
                this.block();
                const filter: IOfferWizardRequestRouteAccount = {
                    ID_ACCOUNT: offerTabModel.ACCOUNT ? parseInt(offerTabModel.ACCOUNT.ID) : null, PROCESS_TYPE: offerTabModel.PROCESS_TYPE ? offerTabModel.PROCESS_TYPE.ID : null, ID_ROUTING_POINT_ORIGIN: route.ORIGIN ? parseInt(route.ORIGIN.ID) : null, ID_ROUTING_POINT_DESTINATION: route.DESTINATION ? parseInt(route.DESTINATION.ID) : null, ID_PRODUCT: offerTabModel.PRODUCT ? offerTabModel.PRODUCT.ID : null, ID_INCOTERM: offerTabModel.INCOTERM ? parseInt(offerTabModel.INCOTERM.ID) : null, MASTER_DIRECT: masterDirect != null ? masterDirect : route.MASTER_DIRECT, ID_NETWORK: route.AGENT_NETWORK_ID, ID_EXPORTER: offerTabModel.EXPORTER ? parseInt(offerTabModel.EXPORTER.ID) : null, ID_IMPORTER: offerTabModel.IMPORTER ? parseInt(offerTabModel.IMPORTER.ID) : null,
                    ID_EXTERNAL_AGENT: route.EXTERNAL_AGENT ? parseInt(route.EXTERNAL_AGENT.ID) : null,
                    ID_PROVIDER: offerTabModel.PROVIDER ? parseInt(offerTabModel.PROVIDER.ID) : null,
                    ID_TYPE_CARGO: offerTabModel.TYPE_CARGO ? offerTabModel.TYPE_CARGO.ID : null,
                };
                const accountResult: IOfferWizardAccountResult = await this.getRouteAccountValues(filter);
                let masterModality = null;
                let houseModality = null;
                let accountRequirementId = null;
                let accountRequirementConcatenated = null;
                if (accountResult) {
                    accountRequirementId = accountResult.ID;
                    accountRequirementConcatenated = accountResult.CONCATENATED;
                    if (accountResult.MASTER_MODALITY) masterModality = accountResult.MASTER_MODALITY.MODALITY ? { ID: accountResult.MASTER_MODALITY.MODALITY.ID, NAME: accountResult.MASTER_MODALITY.MODALITY.NAME } : null;
                    if (accountResult.HOUSE_MODALITY) houseModality = accountResult.HOUSE_MODALITY ? { ID: accountResult.HOUSE_MODALITY.MODALITY.ID, NAME: accountResult.HOUSE_MODALITY.MODALITY.NAME } : null;
                }
                if (!masterModality || !houseModality) {
                    const incoterm = await this.getIncotermValues({ ID_INCOTERM: this.scope.model.OFFER_TAB && this.scope.model.OFFER_TAB.INCOTERM ? parseInt(this.scope.model.OFFER_TAB.INCOTERM.ID) : null });
                    if (!masterModality) masterModality = incoterm.INCOME;
                    if (!houseModality) houseModality = incoterm.OUTCOME;
                }
                route.MASTER_MODALITY = masterModality;
                route.HOUSE_MODALITY = houseModality;
                route.ACCOUNT_REQUIREMENT_ID = accountRequirementId;
                route.ACCOUNT_REQUIREMENT_CONCATENATED = accountRequirementConcatenated;
                this.timeout(() => {
                    this.scope.selectorValidity("specRouteExternalAgent" + routeIndex);
                    this.scope.selectorValidity("specRouteMasterModality" + routeIndex);
                    this.scope.selectorValidity("specRouteHouseModality" + routeIndex);
                }, 500);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.scope.$applyAsync();
            this.unblock();
        }
    }

    private async getGenericValue(type: string, alternative?: boolean): Promise<any> {
        if (alternative) {
            const { data: generic } = await this.helperService.get(`/generic/value/${type}/${alternative}`, null, 10000);
            return generic && generic.data ? generic.data : [];
        } else {
            const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
            return generic && generic.data ? generic.data : [];
        }
    }

    private async getGenericAlternativeAsCodeList(identifier: string): Promise<SelectorModel[]> {
        try {
            let resultList = new Array<SelectorModel>();

            const { data: generic } = await this.helperService.get(`/generic/getByIdentifier/${identifier}`, null, 15000);

            if ((generic.status !== 200) || (!generic.data)) return null;

            let selectorList = generic && generic.data ? generic.data : [];

            for (const item of selectorList) resultList.push({ ID: item.CODE, NAME: item.VALUE, CODE: item.ALTERNATIVE });

            if (resultList.length > 0) {
                resultList = resultList.sort((x, y) => x.ID < y.ID ? -1 : 1);
            }

            return resultList;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getMoveTypeList(): Promise<IMoveTypeModel[]> {
        let moveTypeList: IMoveTypeModel[] = [];
        const result = await this.restService.getObjectAsPromise(`${this.productUrl}/moveType/list/full`, 30000, null, false);
        if (result && result.data) moveTypeList = result.data;
        return moveTypeList;
    }

    private getProductConfigurationList(): Promise<SelectorModel[]> {
        return this.restService.newObjectPromise(`${this.productUrl}/productConfiguration/list`, {}, 30000, false);
    }

    private selectAll(clickedIn?: boolean): void {
        const checkboxAll = document.getElementsByName('selectAll')[0] as HTMLInputElement;
        if (!clickedIn) checkboxAll.checked = !checkboxAll.checked;

        if (this.scope.model.ROUTE_TAB && this.scope.model.ROUTE_TAB.ROUTES && this.scope.model.ROUTE_TAB.ROUTES.length > 0) {
            for (let i = 0; i < this.scope.model.ROUTE_TAB.ROUTES.length; i++) {
                this.scope.model.ROUTE_TAB.ROUTES[i].SELECTED = checkboxAll.checked;
            }
        }
    }

    private selectAllOfferOption(clickedIn?: boolean): void {
        try {
            const checkboxAll = document.getElementsByName('selectAllOfferOption')[0] as HTMLInputElement;
            if (!clickedIn) checkboxAll.checked = !checkboxAll.checked;

            if (this.scope.offerOptions && this.scope.offerOptions.length > 0) {
                for (let offerIndex = 0; offerIndex < this.scope.offerOptions.length; offerIndex++) {
                    this.scope.offerOptions[offerIndex].SELECTED = checkboxAll.checked;
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private selectAllOfferOptionConfirmation(clickedIn?: boolean): void {
        try {
            const checkboxAll = document.getElementsByName('selectAllOfferOptionConfirmation')[0] as HTMLInputElement;
            if (!clickedIn) checkboxAll.checked = !checkboxAll.checked;

            if (this.scope.selectedOptions && this.scope.selectedOptions.length > 0) {
                for (let offerIndex = 0; offerIndex < this.scope.selectedOptions.length; offerIndex++) {
                    this.scope.selectedOptions[offerIndex].SELECTED = checkboxAll.checked;
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getRouteDetailsRowSpan(rowSpan: number, routes: IOfferOptionRoutingPoint[], index: number): number {
        if (routes && routes.length > 0 && (index || index == 0) && routes[index]) {
            const type = routes[index].TYPE;
            if (routes[index + 1] && routes[index + 1].TYPE == type) {
                rowSpan++;
                rowSpan = this.getRouteDetailsRowSpan(rowSpan, routes, index + 1);
            }
        }
        return rowSpan;
    }

    private async productChange(keepBuyingSellingRate?: boolean, isProfileLoad?: boolean): Promise<void> {
        if (!isProfileLoad) {
            this.resetIncoterm();
            this.resetCargoTabModel();
            if (!keepBuyingSellingRate) this.resetBuyingSellingRate();
            if (this.scope.model.ROUTE_TAB) this.scope.model.ROUTE_TAB.ROUTES = null;
            if (this.scope.model.SPEC_TAB) this.scope.model.SPEC_TAB.IS_INSURANCE = false;
        }
        this.scope.incotermList = await this.getIncotermListByName(null);
        if (this.scope.model.OFFER_TAB && this.scope.model.OFFER_TAB.PRODUCT) {
            const isRoad = this.scope.model.OFFER_TAB.PRODUCT.ID == EProductId.ROAD_IMPORT || this.scope.model.OFFER_TAB.PRODUCT.ID == EProductId.ROAD_EXPORT;
            const moveTypeList = await this.getMoveTypeList();
            if (moveTypeList) this.scope.moveTypeList = moveTypeList.filter(moveType => (isRoad && moveType.APPLY_ROAD) || moveType.PARAMS && (!moveType.PARAMS.some(params => params.PRODUCT) || moveType.PARAMS.some(params => params.PRODUCT && params.PRODUCT.some(paramsProduct => paramsProduct.ID == this.scope.model.OFFER_TAB.PRODUCT.ID))));
            this.updateWeightRangeList({ search: "", products: [this.scope.model.OFFER_TAB.PRODUCT.ID] });
        }
    }

    private async chargeNameChange(optionChargeName: SelectorModel): Promise<void> {
        if (optionChargeName) {
            await this.getApplicationListByChargeNameId(optionChargeName);
            if (this.scope.model.SPEC_TAB && this.scope.model.SPEC_TAB.IS_INSURANCE)
                this.scope.model.SPEC_TAB.SECURE.ID_CHARGE_NAME_EXHIBITION = parseInt(optionChargeName.ID)
        }
    }

    private async openRouteDetails(offerOption: IOfferOption): Promise<void> {
        const routes: IOfferOptionRoutingPoint[] = this.getOfferOptionRoutes(offerOption);
        const routeDetails: IRouteDetails[] = [];
        let lastRoute = null;

        for (let index = 0; index < routes.length; index++) {
            const route = routes[index];

            if (route.TYPE == ERouteEventType.TARIFF_FREIGHT) {
                let rowspan = !lastRoute || lastRoute && (lastRoute.TYPE != route.TYPE) ? this.getRouteDetailsRowSpan(1, routes, index) : 0;

                routeDetails.push({
                    type: route.TYPE,
                    showType: !lastRoute || (lastRoute && lastRoute.TYPE != route.TYPE),
                    typeRowspan: rowspan,
                    event: route.EVENT,
                    name: route.CODE,
                    tariffPayment: offerOption.TARIFF_FREIGHT_CONCATENATED,
                    tariffPaymentId: offerOption.ID_TARIFF_FREIGHT,
                    tariffContractPaymentId: offerOption.ID_TARIFF_FREIGHT_CONTRACT,
                    tariffReceiving: offerOption.TARIFF_FREIGHT_RECEIVING_CONCATENATED,
                    tariffReceivingId: offerOption.ID_TARIFF_FREIGHT_RECEIVING,
                    tariffContractReceivingId: offerOption.ID_TARIFF_FREIGHT_CONTRACT_RECEIVING,
                    tariffLocalDestination: {
                        tariffLocalDestinationReceiving: route.TARIFF_LOCAL_DESTINATION.TARIFF_LOCAL_DESTINATION_RECEIVING,
                        tariffLocalDestinationPayment: route.TARIFF_LOCAL_DESTINATION.TARIFF_LOCAL_DESTINATION_PAYMENT
                    },
                    tariffLocalOrigin: {
                        tariffLocalOriginReceiving: route.TARIFF_LOCAL_ORIGIN.TARIFF_LOCAL_ORIGIN_RECEIVING,
                        tariffLocalOriginPayment: route.TARIFF_LOCAL_ORIGIN.TARIFF_LOCAL_ORIGIN_PAYMENT
                    }
                });

                lastRoute = route;
            } else if (route.TYPE == ERouteEventType.TARIFF_DOMESTIC_DESTINATION) {
                let rowspan = !lastRoute || lastRoute && (lastRoute.TYPE != route.TYPE) ? this.getRouteDetailsRowSpan(1, routes, index) : 0;

                routeDetails.push({
                    type: route.TYPE,
                    showType: !lastRoute || (lastRoute && lastRoute.TYPE != route.TYPE),
                    typeRowspan: rowspan,
                    event: route.EVENT,
                    name: route.CODE,
                    tariffPayment: offerOption.TARIFF_DOMESTIC_DESTINATION_CONCATENATED,
                    tariffPaymentId: offerOption.ID_TARIFF_DOMESTIC_DESTINATION,
                    tariffContractPaymentId: offerOption.ID_TARIFF_DOMESTIC_DESTINATION,
                    tariffReceiving: offerOption.TARIFF_DOMESTIC_DESTINATION_CONCATENATED,
                    tariffReceivingId: offerOption.ID_TARIFF_DOMESTIC_DESTINATION,
                    tariffContractReceivingId: offerOption.ID_TARIFF_DOMESTIC_DESTINATION,
                    tariffLocalDestination: {
                        tariffLocalDestinationReceiving: route.TARIFF_LOCAL_DESTINATION.TARIFF_LOCAL_DESTINATION_RECEIVING,
                        tariffLocalDestinationPayment: route.TARIFF_LOCAL_DESTINATION.TARIFF_LOCAL_DESTINATION_PAYMENT
                    },
                    tariffLocalOrigin: {
                        tariffLocalOriginReceiving: route.TARIFF_LOCAL_ORIGIN.TARIFF_LOCAL_ORIGIN_RECEIVING,
                        tariffLocalOriginPayment: route.TARIFF_LOCAL_ORIGIN.TARIFF_LOCAL_ORIGIN_PAYMENT
                    }
                });
            } else if (route.TYPE == ERouteEventType.TARIFF_DOMESTIC_ORIGIN) {
                let rowspan = !lastRoute || lastRoute && (lastRoute.TYPE != route.TYPE) ? this.getRouteDetailsRowSpan(1, routes, index) : 0;

                routeDetails.push({
                    type: route.TYPE,
                    showType: !lastRoute || (lastRoute && lastRoute.TYPE != route.TYPE),
                    typeRowspan: rowspan,
                    event: route.EVENT,
                    name: route.CODE,
                    tariffPayment: offerOption.TARIFF_DOMESTIC_ORIGIN_CONCATENATED,
                    tariffPaymentId: offerOption.ID_TARIFF_DOMESTIC_ORIGIN,
                    tariffContractPaymentId: offerOption.ID_TARIFF_DOMESTIC_ORIGIN,
                    tariffReceiving: offerOption.TARIFF_DOMESTIC_ORIGIN_CONCATENATED,
                    tariffReceivingId: offerOption.ID_TARIFF_DOMESTIC_ORIGIN,
                    tariffContractReceivingId: offerOption.ID_TARIFF_DOMESTIC_ORIGIN,
                    tariffLocalDestination: {
                        tariffLocalDestinationReceiving: route.TARIFF_LOCAL_DESTINATION.TARIFF_LOCAL_DESTINATION_RECEIVING,
                        tariffLocalDestinationPayment: route.TARIFF_LOCAL_DESTINATION.TARIFF_LOCAL_DESTINATION_PAYMENT
                    },
                    tariffLocalOrigin: {
                        tariffLocalOriginReceiving: route.TARIFF_LOCAL_ORIGIN.TARIFF_LOCAL_ORIGIN_RECEIVING,
                        tariffLocalOriginPayment: route.TARIFF_LOCAL_ORIGIN.TARIFF_LOCAL_ORIGIN_PAYMENT
                    }
                });
            }
        }
        this.scope.routeDetails = routeDetails;
        this.modalService.showModalInfo(
            {
                scope: this.scope,
                template: require('../view/modals/offerOldWizardModalRouteDetails.html'),
                size: 'lg'
            },
            {
                actionButtonText: "GENERAL.CLOSE",
                headerText: "BASIC_DATA.ROUTING_DETAILS"
            }
        );
    }

    private async openTransitTimeDetails(transitTime: ITransitTime): Promise<void> {
        if (!transitTime) return this.notifyError("transitTime is null");

        let html = "";
        let transitTimeDetails = `
            <table class="table light-gray-border table-bordered m-b-none" style="${transitTime.TRANSIT_TIME_AIR ? 'width: 900px;' : ''}">
                <thead>
                    <th>${this.getTranslate("BASIC_DATA.SERVICE")}</th>
                    <th>${this.getTranslate("BASIC_DATA.PROVIDER")}</th>
                    <th>${this.getTranslate("GENERAL.MOVEMENT_TYPE")}</th>
                    <th>${this.getTranslate("BASIC_DATA.ROUTING_DETAILS")}</th>
                    <th>${this.getTranslate("GENERAL.TRANSIT_TIME")}</th>
                    ${transitTime.TRANSIT_TIME_AIR ? `<th>${this.getTranslate("REGISTRATION.FREQUENCY")}</th>` : ''}
                </thead>
                <tbody>
        `;

        if (transitTime.TRANSIT_TIME_DOMESTIC_ORIGIN) transitTimeDetails += this.buildTransitTimeDetailsTableRows(transitTime.TRANSIT_TIME_DOMESTIC_ORIGIN.LOGISTIC_SOLUTION, null, null, transitTime.TRANSIT_TIME_DOMESTIC_ORIGIN.LEGS_DOMESTIC, transitTime.TRANSIT_TIME_DOMESTIC_ORIGIN.PROVIDER || transitTime.TRANSIT_TIME_DOMESTIC_ORIGIN.LEGAL_PERSON_AGENT);
        if (transitTime.TRANSIT_TIME_AIR) transitTimeDetails += this.buildTransitTimeDetailsTableRows(`${transitTime.TRANSIT_TIME_AIR.LOGISTIC_SOLUTION}(${transitTime.TRANSIT_TIME_AIR.TRANSSHIPMENT ? 'T' : 'D'})`, transitTime.TRANSIT_TIME_AIR.LEGS_AIR, null, null, transitTime.TRANSIT_TIME_AIR.PROVIDER);
        if (transitTime.TRANSIT_TIME_MARITIME) transitTimeDetails += this.buildTransitTimeDetailsTableRows(`${transitTime.TRANSIT_TIME_MARITIME.LOGISTIC_SOLUTION}(${transitTime.TRANSIT_TIME_MARITIME.TRANSSHIPMENT ? 'T' : 'D'})`, null, transitTime.TRANSIT_TIME_MARITIME.LEGS_MARITIME, null, transitTime.TRANSIT_TIME_MARITIME.PROVIDER);
        if (transitTime.TRANSIT_TIME_DOMESTIC_DESTINATION) transitTimeDetails += this.buildTransitTimeDetailsTableRows(transitTime.TRANSIT_TIME_DOMESTIC_DESTINATION.LOGISTIC_SOLUTION, null, null, transitTime.TRANSIT_TIME_DOMESTIC_DESTINATION.LEGS_DOMESTIC, transitTime.TRANSIT_TIME_DOMESTIC_DESTINATION.PROVIDER || transitTime.TRANSIT_TIME_DOMESTIC_DESTINATION.LEGAL_PERSON_AGENT);

        transitTimeDetails += `
            <tr>
                <td colspan="${transitTime.TRANSIT_TIME_AIR ? '5' : '4'}" style="text-align: right;">
                    <strong>${this.getTranslate("GENERAL.TOTAL_TRANSIT_TIME")}</strong>
                </td>
                <td><strong>${transitTime.TRANSIT_TIME_AIR ? transitTime.TRANSIT_TIME_MIN + '-' + transitTime.TRANSIT_TIME_MAX : transitTime.TRANSIT_TIME_MIN}</strong></td>
            </tr>
        `;
        transitTimeDetails += "</tbody></table>";
        html = `
            <div class="row">
                <div class="col-lg-12">
                    ${transitTimeDetails}
                </div>
            </div>
        `;

        this.modalService.showModalInfo(
            {
                scope: this.scope,
                size: transitTime.TRANSIT_TIME_AIR ? 'lg' : ''
            },
            {
                actionButtonClass: 'btn-default',
                actionButtonText: "GENERAL.CLOSE",
                headerText: "GENERAL.TRANSIT_TIME",
                bodyText: this.sce.trustAsHtml(html)
            });
    }

    private buildTransitTimeDetailsTableRows(logisticSolution: string, legsAir: ILegsAir[], legsMaritime: ILegsMaritime[], legsDomestic: ILegsDomestic[], provider?: SelectorModel): string {
        let tableRows = "";
        if (legsAir && legsAir.length > 0) {
            for (let index = 0; index < legsAir.length; index++) {
                const leg = legsAir[index];
                const transitTimeMin = leg.TRANSIT_TIME_MIN != null ? leg.TRANSIT_TIME_MIN : 'Incomplete';
                tableRows += `
                    <tr>
                        <td style="width: 20%;">${logisticSolution}</td>
                        <td style="width: 20%;">${provider ? provider.NAME : ''}</td>
                        <td style="width: 20%;">${leg.ORIGIN_TYPE && leg.DESTINATION_TYPE ? leg.ORIGIN_TYPE.NAME + '/' + leg.DESTINATION_TYPE.NAME : ''}</td>
                        <td style="width: 20%;">${leg.ROUTING_POINT_ORIGIN && leg.ROUTING_POINT_DESTINATION ? leg.ROUTING_POINT_ORIGIN.CODE + ' X ' + leg.ROUTING_POINT_DESTINATION.CODE : ''}</td>
                        <td style="width: 20%;">${leg.TRANSIT_TIME_MAX ? transitTimeMin + ' - ' + leg.TRANSIT_TIME_MAX : transitTimeMin}</td>
                        <td style="width: 20%;">${leg.FREQUENCY ? leg.FREQUENCY : ""}</td>
                    </tr>
                `;
            }
        } else if (legsMaritime && legsMaritime.length > 0) {
            for (let index = 0; index < legsMaritime.length; index++) {
                const leg = legsMaritime[index];
                tableRows += `<tr>
                    <td style="width: 20%;">${logisticSolution}</td>
                    <td style="width: 20%;">${provider ? provider.NAME : ''}</td>
                    <td style="width: 20%;">${leg.TYPE_ORIGIN && leg.TYPE_DESTINATION ? leg.TYPE_ORIGIN.NAME + '/' + leg.TYPE_DESTINATION.NAME : ''}</td>
                    <td style="width: 20%;">${leg.ROUTING_POINT_ORIGIN && leg.ROUTING_POINT_DESTINATION ? leg.ROUTING_POINT_ORIGIN.CODE + ' X ' + leg.ROUTING_POINT_DESTINATION.CODE : ''}</td>
                    <td style="width: 20%;">${leg.TRANSIT_TIME ? leg.TRANSIT_TIME : 'Incomplete'}</td>
                </tr>`;
            }
        } else if (legsDomestic && legsDomestic.length > 0) {
            for (let index = 0; index < legsDomestic.length; index++) {
                const leg = legsDomestic[index];
                const transitTimeMin = leg.TRANSIT_TIME_MIN != null ? leg.TRANSIT_TIME_MIN : 'Incomplete';
                tableRows += `
                    <tr>
                        <td style="width: 20%;">${logisticSolution}</td>
                        <td style="width: 20%;">${provider ? provider.NAME : ''}</td>
                        <td style="width: 20%;">${leg.TYPE_LOCAL_ORIGIN && leg.TYPE_LOCAL_DESTINATION ? leg.TYPE_LOCAL_ORIGIN.NAME + '/' + leg.TYPE_LOCAL_DESTINATION.NAME : ''}</td>
                        <td style="width: 20%;">${leg.ROUTING_POINT_ORIGIN && leg.ROUTING_POINT_DESTINATION ? leg.ROUTING_POINT_ORIGIN.CODE + ' X ' + leg.ROUTING_POINT_DESTINATION.CODE : ''}</td>
                        <td style="width: 20%;">${leg.TRANSIT_TIME_MAX ? transitTimeMin + ' - ' + leg.TRANSIT_TIME_MAX : transitTimeMin}</td>
                    </tr>
                `;
            }
        }
        return tableRows;
    }

    private async openProfitShareDetails(profitShareDetail: IOfferProfitShareDetail) {
        if (!profitShareDetail) return;

        const chargeTable = this.buildProfitShareDetailChargeTable(profitShareDetail.COMPOSITION_ORIGINAL, profitShareDetail.COMPOSITION, profitShareDetail.PROFIT_LOSS);
        const profitShareTable = this.buildProfitShareDetailProfitShareTable(profitShareDetail.SHARE ? profitShareDetail.SHARE.SHARE_ITEM : null, profitShareDetail.SHARE != null ? profitShareDetail.SHARE.TOTAL : null, profitShareDetail.SHARE != null ? profitShareDetail.SHARE.SHARE_VALUE : null);

        const html = `
            <div class="tab-content">
                <div class="row">
                    <div class="col-lg-12">
                        <label>${this.getTranslate("PRODUCT.AGREEMENT")}: <span id="profitShareDetailAgreement" class="cursor-hand text-u-l">${profitShareDetail.AGREEMENT}</span></label><br>
                        <label>${this.getTranslate("BASIC_DATA.CHARGE")}: ${profitShareDetail.CHARGE_NAME ? profitShareDetail.CHARGE_NAME.NAME : ''}</label><br>
                        <label>${this.getTranslate("FINANCIAL.PROFIT_SHARE_CURRENCY")}: ${profitShareDetail.CURRENCY ? profitShareDetail.CURRENCY.CODE : ''}</label>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="panel panel-default">
                            <div class="panel-heading">${this.getTranslate("FINANCIAL.PROFIT_SHARE_DETAILS")}</div>
                            <div class="panel-body">
                                <div class="row"><div class="col-lg-12 table-responsive">${profitShareTable}</div></div>                                
                            </div>
                        </div>                        
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="panel panel-default">
                            <div class="panel-heading collapse-heading clickable-div" data-toggle="collapse"
                            data-target="#collapseShareTable" aria-controls="collapseShareTable"">${this.getTranslate("FINANCIAL.COMPOSITION_CHARGES")}</div>
                            <div id="collapseShareTable" class="panel-body collapse toggle-me"
											role="tabpanel" tabindex="-1">
                                <div class="row"><div class="col-lg-12 table-responsive">${chargeTable}</div></div>                                
                            </div>
                        </div>                        
                    </div>
                </div>
            </div>
        `;

        const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
            {
                scope: this.scope,
                size: 'full monaco-wizard'
            },
            {
                actionButtonClass: 'btn-default',
                actionButtonText: "GENERAL.CLOSE",
                headerText: "FINANCIAL.PROFIT_SHARE_DETAILS",
                bodyText: this.sce.trustAsHtml(html)
            }
        );

        await modalInstance.rendered.then(() => {
            angular.element("#profitShareDetailAgreement").bind('click', () => { this.sessionService.openTab("app.product.paramsProfitShare", <ILinkParameter>{ ID: profitShareDetail && profitShareDetail.ID ? profitShareDetail.ID.toString() : profitShareDetail.ID }); });
        });

    }

    private async openPaymentReceivingChargesDetails(currentCurrency: SelectorModel, charges: IOfferOptionCharge[], indicators: IOfferOptionIndicators) {
        if (!currentCurrency) return this.notifyError("currentCurrency is null.");
        if (!charges) return this.notifyError("charges is null.");
        if (!indicators) return this.notifyError("indicators is null.");

        const chargeTable = this.buildPaymentReceivingChargeDetailsTable(charges);
        const entranceTable = this.buildEntranceTable(indicators);
        const indicatorTable = this.buildIndicatorTable(currentCurrency, indicators);

        const html = `
            <div class="tab-content">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="panel panel-default">
                            <div class="panel-heading">${this.getTranslate("FINANCIAL.OFFER_TOTALS")}</div>
                            <div class="panel-body">
                                <div class="table-responsive">${entranceTable}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="panel panel-default">
                            <div class="panel-heading">${this.getTranslate("GENERAL.INDICATORS")}</div>
                            <div class="panel-body">
                                <div class="table-responsive">${indicatorTable}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="panel panel-default">
                            <div class="panel-heading collapse-heading clickable-div" data-toggle="collapse"
                            data-target="#collapseChargeTable" aria-controls="collapseChargeTable">${this.getTranslate("GENERAL.CHARGES")}</div>
                            <div id="collapseChargeTable" class="panel-body collapse toggle-me"
											role="tabpanel" tabindex="-1">
                                <div class="table-responsive">${chargeTable}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        `;

        const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
            {
                scope: this.scope,
                size: 'full monaco-wizard'
            },
            {
                actionButtonClass: 'btn-default',
                actionButtonText: "GENERAL.CLOSE",
                headerText: "FINANCIAL.INDICATORS_DETAILS",
                bodyText: this.sce.trustAsHtml(html)
            }
        );

        // compile tooltips
        modalInstance.rendered.then(() => {
            const tooltipTotalPayment = angular.element("#tooltipTotalPayment");
            const tooltipTotalReceiving = angular.element("#tooltipTotalReceiving");
            const tooltipTotalProfitShare = angular.element("#tooltipTotalProfitShare");
            const tooltipContribution = angular.element("#tooltipContribution");
            const tooltipModal = angular.element(".tooltipModal");
            if (tooltipTotalPayment) this.$compile(tooltipTotalPayment)(this.scope);
            if (tooltipTotalReceiving) this.$compile(tooltipTotalReceiving)(this.scope);
            if (tooltipTotalProfitShare) this.$compile(tooltipTotalProfitShare)(this.scope);
            if (tooltipContribution) this.$compile(tooltipContribution)(this.scope);
            if (tooltipModal) this.$compile(tooltipModal)(this.scope);
        });

    }

    private async openChargesPerWeightRangeModal(chargeWeightRangeList: IOfferOptionChargeWeightRange[]): Promise<void> {
        try {
            const modalID = this.modalService.newModal();
            await this.modalService.showChargesPerWeightRangeModal({ modalID: modalID, actionButtonClass: 'btn-default', offerOptionChargeWeightRangeList: chargeWeightRangeList, isViewMode: true });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async openSelectionModal(): Promise<boolean> {
        this.scope.basicInfoSelection = {
            ACCOUNT: null
        };
        this.modalSelectionId = this.modalService.newModal();
        const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
            {
                modalID: this.modalSelectionId,
                scope: this.scope,
                formService: 'register',
                template: require('../view/modals/offerWizardSelectionModal.html'),
                size: 'lg'
            },
            {
                actionButtonText: "REGISTRATION.APPLY",
                closeButtonText: "GENERAL.CANCEL",
                headerText: "GENERAL.SELECT_INFORMATION_WANT_APPLY"
            }
        );
        const modalResponse = await modalInstance.result.then(function (result) {
            return result.$value;
        }, function (result) {
            return result.$value;
        });
        return modalResponse;
    }

    private buildProfitShareDetailChargeTable(compositionOriginalList: IOfferProfitShareDetailComposition[], compositionList: IOfferProfitShareDetailComposition[], profitLoss: number): string {
        let chargeTableRows = "";
        if (compositionOriginalList.length != compositionList.length) throw this.notifyError('Inconsistency was found in the compositions, please contact an administrator.');
        else if (compositionOriginalList && compositionOriginalList.length) {
            for (const index in compositionOriginalList) {
                const compositionOriginal = compositionOriginalList[index];
                const composition = compositionList && compositionList.length ? compositionList[index] : null;

                let paymentApplicationTd = composition && composition.PAYMENT_CHARGE && composition.PAYMENT_CHARGE.CHARGE && composition.PAYMENT_CHARGE.CHARGE.APPLICATION ? composition.PAYMENT_CHARGE.CHARGE.APPLICATION.CODE : '';
                if (composition && composition.PAYMENT_CHARGE && composition.PAYMENT_CHARGE.CHARGE && composition.PAYMENT_CHARGE.CHARGE.EQUIPMENT) paymentApplicationTd += `(${composition.PAYMENT_CHARGE.CHARGE.EQUIPMENT.CODE})`;
                let receivingApplicationTd = composition && composition.RECEIVING_CHARGE && composition.RECEIVING_CHARGE.CHARGE && composition.RECEIVING_CHARGE.CHARGE.APPLICATION ? composition.RECEIVING_CHARGE.CHARGE.APPLICATION.CODE : '';
                if (composition && composition.RECEIVING_CHARGE && composition.RECEIVING_CHARGE.CHARGE && composition.RECEIVING_CHARGE.CHARGE.EQUIPMENT) receivingApplicationTd += `(${composition.RECEIVING_CHARGE.CHARGE.EQUIPMENT.CODE})`;
                chargeTableRows += `
                <tr>
                    <td>${composition && composition.CHARGE_NAME && composition.CHARGE_NAME.TYPE ? composition.CHARGE_NAME.TYPE.NAME : ''}</td>
                    <td>${composition && composition.CHARGE_NAME ? composition.CHARGE_NAME.CODE : ''}</td>
                    <td>${paymentApplicationTd}</td>
                    <td>${compositionOriginal.PAYMENT_CHARGE ? compositionOriginal.PAYMENT_CHARGE.AMOUNT : ''}</td>
                    <td>${compositionOriginal.PAYMENT_CHARGE && compositionOriginal.PAYMENT_CHARGE.CURRENCY ? compositionOriginal.PAYMENT_CHARGE.CURRENCY.CODE : ''}</td>
                    <td>${compositionOriginal.PAYMENT_CHARGE != null ? this.$filterService('number')(compositionOriginal.PAYMENT_CHARGE.VALUE_MIN, 2) : ''}</td>
                    <td>${compositionOriginal.PAYMENT_CHARGE != null ? this.$filterService('number')(compositionOriginal.PAYMENT_CHARGE.VALUE_UNITARY, 2) : ''}</td>
                    <td>${compositionOriginal.PAYMENT_CHARGE != null ? this.$filterService('number')(compositionOriginal.PAYMENT_CHARGE.TOTAL, 2) : ''}</td>
                    <td>${composition && composition.PAYMENT_CHARGE != null ? this.$filterService('number')(composition.PAYMENT_CHARGE.FACTOR, 4) : ''}</td>
                    <td>${composition && composition.PAYMENT_CHARGE != null ? this.$filterService('number')(composition.PAYMENT_CHARGE.TOTAL, 2) : ''}</td>
                    <td>${receivingApplicationTd}</td>
                    <td>${compositionOriginal.RECEIVING_CHARGE ? compositionOriginal.RECEIVING_CHARGE.AMOUNT : ''}</td>
                    <td>${compositionOriginal.RECEIVING_CHARGE && compositionOriginal.RECEIVING_CHARGE.CURRENCY ? compositionOriginal.RECEIVING_CHARGE.CURRENCY.CODE : ''}</td>
                    <td>${compositionOriginal.RECEIVING_CHARGE != null ? this.$filterService('number')(compositionOriginal.RECEIVING_CHARGE.VALUE_MIN, 2) : ''}</td>
                    <td>${compositionOriginal.RECEIVING_CHARGE != null ? this.$filterService('number')(compositionOriginal.RECEIVING_CHARGE.VALUE_UNITARY, 2) : ''}</td>
                    <td>${compositionOriginal.RECEIVING_CHARGE != null ? this.$filterService('number')(compositionOriginal.RECEIVING_CHARGE.TOTAL, 2) : ''}</td>
                    <td>${composition && composition.RECEIVING_CHARGE != null ? this.$filterService('number')(composition.RECEIVING_CHARGE.FACTOR, 4) : ''}</td>
                    <td>${composition && composition.RECEIVING_CHARGE != null ? this.$filterService('number')(composition.RECEIVING_CHARGE.TOTAL, 2) : ''}</td>
                    <td>${composition && composition.CHARGE_VALUE != null ? this.$filterService('number')(composition.CHARGE_VALUE, 2) : ''}</td>
                </tr>`;
            }
        }

        chargeTableRows += `
            <tr>
                <td colspan="18" style="text-align: right;">
                    <strong>${this.getTranslate("FINANCIAL.TOTAL_PROFIT_LOSS")}</strong>
                </td>
                <td><strong>${this.$filterService('number')(profitLoss, 2)}</strong></td>
            </tr>
        `;

        let chargeTable = `
            <table class="table light-gray-border table-bordered m-b-none">
                <thead>
                    <tr>
                        <th rowspan="3" class="centered v-middle">${this.getTranslate("FINANCIAL.CHARGE_TYPE")}</th>
                        <th rowspan="3" class="centered v-middle">${this.getTranslate("GENERAL.CHARGE")}</th>
                        <th colspan="8" class="centered">${this.getTranslate("PRODUCT.BUYING")}</th>
                        <th colspan="8" class="centered">${this.getTranslate("PRODUCT.SELLING")}</th>
                        <th rowspan="3" class="centered v-middle">${this.getTranslate("FINANCIAL.TOTAL_SHARE")}</th>
                    <tr>
                    <tr>
                        <th class="centered">${this.getTranslate("FINANCIAL.CHARGE_BASIS")}</th>
                        <th class="centered">${this.getTranslate("GENERAL.QUANTITY")}</th>
                        <th class="centered">${this.getTranslate("GENERAL.CURRENCY")}</th>
                        <th class="centered">${this.getTranslate("BASIC_DATA.MINIMUM")}</th>
                        <th class="centered">${this.getTranslate("BASIC_DATA.UNIT")}</th>
                        <th class="centered">${this.getTranslate("GENERAL.TOTAL")}</th>
                        <th class="centered">${this.getTranslate("BASIC_DATA.EXCHANGE_RATE")}</th>
                        <th class="centered">${this.getTranslate("FINANCIAL.TOTAL_BRL")}</th>
                        <th class="centered">${this.getTranslate("FINANCIAL.CHARGE_BASIS")}</th>
                        <th class="centered">${this.getTranslate("GENERAL.QUANTITY")}</th>
                        <th class="centered">${this.getTranslate("GENERAL.CURRENCY")}</th>
                        <th class="centered">${this.getTranslate("BASIC_DATA.MINIMUM")}</th>
                        <th class="centered">${this.getTranslate("BASIC_DATA.UNIT")}</th>
                        <th class="centered">${this.getTranslate("GENERAL.TOTAL")}</th>
                        <th class="centered">${this.getTranslate("BASIC_DATA.EXCHANGE_RATE")}</th>
                        <th class="centered">${this.getTranslate("FINANCIAL.TOTAL_BRL")}</th>
                    </tr>
                </thead>
                <tbody>${chargeTableRows}</tbody>
            </table>
        `;
        return chargeTable;
    }

    private buildProfitShareDetailProfitShareTable(shareItemList: IOfferOptionProfitShareDetailShareItem[], total: number, shareValue: number): string {
        let profitShareTableRows = "";
        if (shareItemList && shareItemList.length) {
            for (const shareItem of shareItemList) {
                const isCompany = shareItem.PROFIT_SHARE && shareItem.PROFIT_SHARE.ID == "1";
                if (shareItem.APPLICATION_PARAM && shareItem.APPLICATION_PARAM.length) {
                    for (let index = 0; index < shareItem.APPLICATION_PARAM.length; index++) {
                        const applicationParam = shareItem.APPLICATION_PARAM[index];
                        profitShareTableRows += '<tr>';
                        if (index == 0) {
                            profitShareTableRows += `
                                <td rowspan="${shareItem.APPLICATION_PARAM.length}">${shareItem.PROFIT_SHARE ? shareItem.PROFIT_SHARE.NAME : ''}</td>
                                <td rowspan="${shareItem.APPLICATION_PARAM.length}">${!isCompany && shareItem.PERCENTUAL_ORIGINAL != null ? this.$filterService('number')(shareItem.PERCENTUAL_ORIGINAL, 2) : ''}<input type="hidden" value="${shareItem.PERCENTUAL_CALCULATED}"></input></td>
                                <td>${!isCompany && applicationParam.MIN_APPLICATION != null ? this.$filterService('number')(applicationParam.MIN_APPLICATION, 2) : ''}</td>
                                <td>${!isCompany && applicationParam.APPLICATION ? applicationParam.APPLICATION.NAME : ''}</td>
                                <td>${!isCompany && applicationParam.EQUIPMENT ? applicationParam.EQUIPMENT.CODE : ''}</td>
                                <td>${!isCompany && applicationParam.FEET ? applicationParam.FEET.NAME : ''}</td>
                                <td>${!isCompany && applicationParam.AMOUNT}</td>
                                <td>${!isCompany && applicationParam.TOTAL_MIN_APPLICATION != null ? this.$filterService('number')(applicationParam.TOTAL_MIN_APPLICATION, 2) : ''}</td>
                                <td rowspan="${shareItem.APPLICATION_PARAM.length}">${!isCompany && shareItem.TOTAL_MIN_UNITARY != null ? this.$filterService('number')(shareItem.TOTAL_MIN_UNITARY, 2) : ''}</td>
                                <td rowspan="${shareItem.APPLICATION_PARAM.length}">${!isCompany && shareItem.MIN_FILE != null ? this.$filterService('number')(shareItem.MIN_FILE, 2) : ''}</td>
                                <td rowspan="${shareItem.APPLICATION_PARAM.length}">${!isCompany && shareItem.PERCENTUAL_RESULT != null ? this.$filterService('number')(shareItem.PERCENTUAL_RESULT, 2) : ''}</td>
                                <td rowspan="${shareItem.APPLICATION_PARAM.length}">${shareItem.SHARE != null ? this.$filterService('number')(shareItem.SHARE, 2) : shareItem.SHARE}</td>
                            `;
                        } else {
                            profitShareTableRows += `
                                <td>${!isCompany && applicationParam.MIN_APPLICATION != null ? this.$filterService('number')(applicationParam.MIN_APPLICATION, 2) : ''}</td>
                                <td>${!isCompany && applicationParam.APPLICATION ? applicationParam.APPLICATION.NAME : ''}</td>
                                <td>${!isCompany && applicationParam.EQUIPMENT ? applicationParam.EQUIPMENT.CODE : ''}</td>
                                <td>${!isCompany && applicationParam.FEET ? applicationParam.FEET.NAME : ''}</td>
                                <td>${!isCompany && applicationParam.AMOUNT}</td>
                                <td>${!isCompany && applicationParam.TOTAL_MIN_APPLICATION != null ? this.$filterService('number')(applicationParam.TOTAL_MIN_APPLICATION, 2) : ''}</td>
                            `;
                        }
                        profitShareTableRows += '</tr>';
                    }
                } else {
                    profitShareTableRows += `
                        <tr>
                            <td>${shareItem.PROFIT_SHARE ? shareItem.PROFIT_SHARE.NAME : ''}</td>
                            <td>${!isCompany && shareItem.PERCENTUAL_ORIGINAL != null ? this.$filterService('number')(shareItem.PERCENTUAL_ORIGINAL, 2) : ''}<input type="hidden" value="${shareItem.PERCENTUAL_CALCULATED}"></input></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>${!isCompany && shareItem.TOTAL_MIN_UNITARY != null ? this.$filterService('number')(shareItem.TOTAL_MIN_UNITARY, 2) : ''}</td>
                            <td>${!isCompany && shareItem.MIN_FILE != null ? this.$filterService('number')(shareItem.MIN_FILE, 2) : ''}</td>
                            <td>${!isCompany && shareItem.PERCENTUAL_RESULT != null ? this.$filterService('number')(shareItem.PERCENTUAL_RESULT, 2) : ''}</td>
                            <td>${shareItem.SHARE != null ? this.$filterService('number')(shareItem.SHARE, 2) : shareItem.SHARE}</td>
                        </tr>`;
                }
            }
        }

        profitShareTableRows += `
            <tr>
                <td colspan="11" style="text-align: right;">
                    <strong>${this.getTranslate("FINANCIAL.TOTAL_PROFIT_LOSS")}</strong>
                </td>
                <td><strong>${total != null ? this.$filterService('number')(total, 2) : ''}</strong></td>
            </tr>
            <tr>
                <td colspan="11" style="text-align: right;">
                    <strong>${this.getTranslate("FINANCIAL.TOTAL_PROFIT_SHARE")}</strong>
                </td>
                <td><strong>${shareValue != null ? this.$filterService('number')(shareValue, 2) : ''}</strong></td>
            </tr>
        `;

        let profitShareTable = `
            <table class="table light-gray-border table-bordered m-b-none">
                <thead>
                    <tr>    
                        <th>${this.getTranslate("PRODUCT.COMMISSIONED")}</th>
                        <th>${this.getTranslate("REGISTRATION.PERCENTAGE")}</th>
                        <th>${this.getTranslate("FINANCIAL.BASIS_MIN_UNIT")}</th>
                        <th>${this.getTranslate("FINANCIAL.CHARGE_BASIS")}</th>
                        <th>${this.getTranslate("GENERAL.EQUIPMENT")}</th>
                        <th>${this.getTranslate("GENERAL.EQUIPMENT_SIZE")}</th>
                        <th>${this.getTranslate("GENERAL.QUANTITY")}</th>
                        <th>${this.getTranslate("FINANCIAL.BASIS_MIN_SUBTOTAL")}</th>
                        <th>${this.getTranslate("FINANCIAL.BASIS_MIN_TOTAL")}</th>
                        <th>${this.getTranslate("FINANCIAL.FILE_MINIMUM")}</th>
                        <th>${this.getTranslate("FINANCIAL.PERC_RESULT")}</th>
                        <th>${this.getTranslate("FINANCIAL.SHARE")}</th>
                    </tr>
                </thead>
                <tbody>${profitShareTableRows}</tbody>
            </table>
        `;
        return profitShareTable;
    }

    private buildPaymentReceivingChargeDetailsTable(charges: IOfferOptionCharge[]): string {
        let chargeTableRows = "";
        if (charges && charges.length) {
            for (const charge of charges) {
                let paymentApplicationTd = charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.CHARGE && charge.PAYMENT_CHARGE.CHARGE.APPLICATION ? charge.PAYMENT_CHARGE.CHARGE.APPLICATION.CODE : '';
                if (charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.CHARGE && charge.PAYMENT_CHARGE.CHARGE.EQUIPMENT) paymentApplicationTd += `(${charge.PAYMENT_CHARGE.CHARGE.EQUIPMENT.CODE})`;
                let receivingApplicationTd = charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.CHARGE && charge.RECEIVING_CHARGE.CHARGE.APPLICATION ? charge.RECEIVING_CHARGE.CHARGE.APPLICATION.CODE : '';
                if (charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.CHARGE && charge.RECEIVING_CHARGE.CHARGE.EQUIPMENT) receivingApplicationTd += `(${charge.RECEIVING_CHARGE.CHARGE.EQUIPMENT.CODE})`;
                let chargeTableName = charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.TABLE ? charge.PAYMENT_CHARGE.TABLE.NAME : '';
                let paymentChargeFactor = charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.FACTOR ? this.$filterService('number')(charge.PAYMENT_CHARGE.FACTOR, 4) : '';
                let paymentChargeSpread = charge.PAYMENT_CHARGE && (charge.PAYMENT_CHARGE.SPREAD || charge.PAYMENT_CHARGE.SPREAD == 0) ? this.$filterService('number')(charge.PAYMENT_CHARGE.SPREAD, 2) : '';
                chargeTableRows += `
                <tr>
                    <td>${charge.CHARGE_NAME && charge.CHARGE_NAME.TYPE ? charge.CHARGE_NAME.TYPE.NAME : ''}</td>
                    <td>${charge.CHARGE_NAME ? charge.CHARGE_NAME.CODE : ''}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.CHARGE && charge.PAYMENT_CHARGE.CHARGE.CHARGE_NAME_EXHIBITION ? charge.PAYMENT_CHARGE.CHARGE.CHARGE_NAME_EXHIBITION.CODE : ''}</td>
                    <td>${paymentApplicationTd}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.CURRENCY ? charge.PAYMENT_CHARGE.CURRENCY.CODE : ''}</td>
                    <td>${charge.PAYMENT_CHARGE ? charge.PAYMENT_CHARGE.AMOUNT : ''}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.VALUE_MIN != null ? this.$filterService('number')(charge.PAYMENT_CHARGE.VALUE_MIN, 2) : ''}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.VALUE_UNITARY != null ? this.$filterService('number')(charge.PAYMENT_CHARGE.VALUE_UNITARY, 2) : ''}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.TOTAL != null ? this.$filterService('number')(charge.PAYMENT_CHARGE.TOTAL, 2) : ''}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.MODALITY ? charge.PAYMENT_CHARGE.MODALITY.NAME : ''}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.TRANSACTION ? charge.PAYMENT_CHARGE.TRANSACTION.NAME : ''} ${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.TRANSACTION_CONTRACT && charge.PAYMENT_CHARGE.TRANSACTION != charge.PAYMENT_CHARGE.TRANSACTION_CONTRACT ? `<i class="fa fa-info-circle text-cyano tooltipModal" aria-hidden="true" tooltip-placement="auto top" uib-tooltip="{{ 'FINANCIAL.TRANSACTION_CONTRACT' | translate }}: ` + (charge.PAYMENT_CHARGE.TRANSACTION_CONTRACT ? charge.PAYMENT_CHARGE.TRANSACTION_CONTRACT.NAME : '') + '" tooltip-append-to-body="true"></i>' : ''}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.HOLDER ? charge.PAYMENT_CHARGE.HOLDER.NAME : ''} ${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.TRANSACTION_CONTRACT && charge.PAYMENT_CHARGE.TRANSACTION != charge.PAYMENT_CHARGE.TRANSACTION_CONTRACT ? `<i class="fa fa-info-circle text-cyano tooltipModal" aria-hidden="true" tooltip-placement="auto top" uib-tooltip="{{ 'FINANCIAL.HOLDER_CONTRACT' | translate }}: ` + (charge.PAYMENT_CHARGE.HOLDER_CONTRACT ? charge.PAYMENT_CHARGE.HOLDER_CONTRACT.NAME : '') + '" tooltip-append-to-body="true"></i>' : ''}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.TOTAL_FACTOR != null ? this.$filterService('number')(charge.PAYMENT_CHARGE.TOTAL_FACTOR, 4) : ''} ${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.CONTRACT && charge.PAYMENT_CHARGE.CURRENCY.ID != charge.PAYMENT_CHARGE.CURRENT_CURRENCY.ID ? `<i class="fa fa-info-circle text-cyano tooltipModal" aria-hidden="true" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.TABLE_SPREAD' | translate: { tableName: '${chargeTableName}', factor: '${paymentChargeFactor.toString()}', spread: '${paymentChargeSpread.toString()}'} }}" tooltip-append-to-body="true"></i>` : ''}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.TOTAL_CURRENT_CURRENCY != null ? this.$filterService('number')(charge.PAYMENT_CHARGE.TOTAL_CURRENT_CURRENCY, 2) : ''}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.CHARGE_COMPOSITION && charge.PAYMENT_CHARGE.CHARGE_COMPOSITION.length ? this.getCONCAT(charge.PAYMENT_CHARGE.CHARGE_COMPOSITION, null, 'CODE') : ''}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.CONTRACT && charge.PAYMENT_CHARGE.CONTRACT.CONTRACT_NUMBER ? charge.PAYMENT_CHARGE.CONTRACT.CONTRACT_NUMBER : ''}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.CHARGE && charge.RECEIVING_CHARGE.CHARGE.CHARGE_NAME_EXHIBITION ? charge.RECEIVING_CHARGE.CHARGE.CHARGE_NAME_EXHIBITION.CODE : ''}</td>
                    <td>${receivingApplicationTd}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.CURRENCY ? charge.RECEIVING_CHARGE.CURRENCY.CODE : ''}</td>
                    <td>${charge.RECEIVING_CHARGE ? charge.RECEIVING_CHARGE.AMOUNT : ''}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.VALUE_MIN != null ? this.$filterService('number')(charge.RECEIVING_CHARGE.VALUE_MIN, 2) : ''}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.VALUE_UNITARY != null ? this.$filterService('number')(charge.RECEIVING_CHARGE.VALUE_UNITARY, 2) : ''}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.TOTAL != null ? this.$filterService('number')(charge.RECEIVING_CHARGE.TOTAL, 2) : ''}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.MODALITY ? charge.RECEIVING_CHARGE.MODALITY.NAME : ''}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.TRANSACTION ? charge.RECEIVING_CHARGE.TRANSACTION.NAME : ''} ${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.TRANSACTION_CONTRACT && charge.RECEIVING_CHARGE.TRANSACTION != charge.RECEIVING_CHARGE.TRANSACTION_CONTRACT ? `<i class="fa fa-info-circle text-cyano tooltipModal" aria-hidden="true" tooltip-placement="auto top" uib-tooltip="{{ 'FINANCIAL.TRANSACTION_CONTRACT' | translate }}:  ` + (charge.RECEIVING_CHARGE.TRANSACTION_CONTRACT ? charge.RECEIVING_CHARGE.TRANSACTION_CONTRACT.NAME : '') + '" tooltip-append-to-body="true"></i>' : ''}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.HOLDER ? charge.RECEIVING_CHARGE.HOLDER.NAME : ''} ${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.TRANSACTION_CONTRACT && charge.RECEIVING_CHARGE.TRANSACTION != charge.RECEIVING_CHARGE.TRANSACTION_CONTRACT ? `<i class="fa fa-info-circle text-cyano tooltipModal" aria-hidden="true" tooltip-placement="auto top" uib-tooltip="{{ 'FINANCIAL.HOLDER_CONTRACT' | translate }}: ` + (charge.RECEIVING_CHARGE.HOLDER_CONTRACT ? charge.RECEIVING_CHARGE.HOLDER_CONTRACT.NAME : '') + '" tooltip-append-to-body="true"></i>' : ''}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.FACTOR != null ? this.$filterService('number')(charge.RECEIVING_CHARGE.TOTAL_FACTOR, 4) : ''} ${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.CONTRACT && charge.RECEIVING_CHARGE.CURRENCY.ID != charge.RECEIVING_CHARGE.CURRENT_CURRENCY.ID ? `<i class="fa fa-info-circle text-cyano tooltipModal" aria-hidden="true" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.TABLE_SPREAD' | translate: { tableName: '${chargeTableName}', factor: '${paymentChargeFactor.toString()}', spread: '${paymentChargeSpread.toString()}'} }}" tooltip-append-to-body="true"></i>` : ''}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.TOTAL_CURRENT_CURRENCY != null ? this.$filterService('number')(charge.RECEIVING_CHARGE.TOTAL_CURRENT_CURRENCY, 2) : ''}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.CHARGE_COMPOSITION && charge.RECEIVING_CHARGE.CHARGE_COMPOSITION.length ? this.getCONCAT(charge.RECEIVING_CHARGE.CHARGE_COMPOSITION, null, 'CODE') : ''}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.CONTRACT && charge.RECEIVING_CHARGE.CONTRACT.CONTRACT_NUMBER ? charge.RECEIVING_CHARGE.CONTRACT.CONTRACT_NUMBER : ''}</td>
                    <td>${charge.EXCHANGE_RATE_INDEX != null ? this.$filterService('number')(charge.EXCHANGE_RATE_INDEX, 2) + '%' : ''}</td>
                    <td>${charge.CHARGE_VALUE != null ? this.$filterService('number')(charge.CHARGE_VALUE, 2) : ''}</td>
                    <td>${charge.EXCHANGE_BALANCE != null ? this.$filterService('number')(charge.EXCHANGE_BALANCE, 2) : ''}</td>
                    <td>${charge.CHARGE_VALUE_CURRENT_CURRENCY != null ? this.$filterService('number')(charge.CHARGE_VALUE_CURRENT_CURRENCY, 2) : ''}</td>
                </tr>`;
            }
        }

        const chargeTable = `
            <table class="table light-gray-border table-bordered m-b-none">
                <thead>
                    <tr>
                        <th rowspan="3" class="centered v-middle">${this.getTranslate("FINANCIAL.CHARGE_TYPE")}</th>    
                        <th rowspan="3" class="centered v-middle">${this.getTranslate("GENERAL.CHARGE")}</th>
                        <th colspan="14" class="centered">${this.getTranslate("PRODUCT.BUYING")}</th>
                        <th colspan="14" class="centered">${this.getTranslate("PRODUCT.SELLING")}</th>
                        <th rowspan="3" class="centered v-middle">${this.getTranslate("FINANCIAL.IDC")}</th>
                        <th rowspan="3" class="centered v-middle">${this.getTranslate("FINANCIAL.CHARGE_DIFFERENCE")}</th>
                        <th rowspan="3" class="centered v-middle">${this.getTranslate("FINANCIAL.CHARGE_EXCHANGE_DIFFERENCE")}</th>
                        <th rowspan="3" class="centered v-middle">${this.getTranslate("FINANCIAL.CHARGE_DIFFERENCE_BRL")}</th>
                    <tr>
                    <tr>
                        <th class="centered">${this.getTranslate("BASIC_DATA.CHARGE_DISPLAY")}</th>
                        <th class="centered">${this.getTranslate("FINANCIAL.CHARGE_BASIS")}</th>
                        <th class="centered">${this.getTranslate("GENERAL.CURRENCY")}</th>
                        <th class="centered">${this.getTranslate("GENERAL.QUANTITY")}</th>
                        <th class="centered">${this.getTranslate("BASIC_DATA.MINIMUM")}</th>
                        <th class="centered">${this.getTranslate("BASIC_DATA.UNIT")}</th>
                        <th class="centered">${this.getTranslate("GENERAL.TOTAL")}</th>
                        <th class="centered">${this.getTranslate("FINANCIAL.CHARGE_PAYMENT_METHOD")}</th>
                        <th class="centered">${this.getTranslate("GENERAL.HOLDER_TYPE")}</th>
                        <th class="centered">${this.getTranslate("GENERAL.HOLDER")}</th>
                        <th class="centered">${this.getTranslate("BASIC_DATA.EXCHANGE_RATE")}</th>
                        <th class="centered">${this.getTranslate("FINANCIAL.TOTAL_BRL")}</th>
                        <th class="centered">${this.getTranslate("GENERAL.COMPOSITION")}</th>
                        <th class="centered">${this.getTranslate("OPERATIONAL.FINANCIAL_CONTRACT")}</th>
                        <th class="centered">${this.getTranslate("BASIC_DATA.CHARGE_DISPLAY")}</th>
                        <th class="centered">${this.getTranslate("FINANCIAL.CHARGE_BASIS")}</th>
                        <th class="centered">${this.getTranslate("GENERAL.CURRENCY")}</th>
                        <th class="centered">${this.getTranslate("GENERAL.QUANTITY")}</th>
                        <th class="centered">${this.getTranslate("BASIC_DATA.MINIMUM")}</th>
                        <th class="centered">${this.getTranslate("BASIC_DATA.UNIT")}</th>
                        <th class="centered">${this.getTranslate("GENERAL.TOTAL")}</th>
                        <th class="centered">${this.getTranslate("FINANCIAL.CHARGE_PAYMENT_METHOD")}</th>
                        <th class="centered">${this.getTranslate("GENERAL.HOLDER_TYPE")}</th>
                        <th class="centered">${this.getTranslate("GENERAL.HOLDER")}</th>
                        <th class="centered">${this.getTranslate("BASIC_DATA.EXCHANGE_RATE")}</th>
                        <th class="centered">${this.getTranslate("FINANCIAL.TOTAL_BRL")}</th>
                        <th class="centered">${this.getTranslate("GENERAL.COMPOSITION")}</th>
                        <th class="centered">${this.getTranslate("OPERATIONAL.FINANCIAL_CONTRACT")}</th>
                    </tr>
                </thead>
                <tbody>${chargeTableRows}</tbody>
            </table>
        `;
        return chargeTable;
    }

    private buildEntranceTable(indicators: IOfferOptionIndicators) {
        const typeCargo = this.scope.model.OFFER_TAB ? this.scope.model.OFFER_TAB.TYPE_CARGO : null;
        if (!typeCargo) throw Error('type cargo is null');
        let contributionLabel = "";
        let contributionCode = "";
        let contributionTooltip = "";
        let contributionMask = 0;
        if (typeCargo.ID == ECargoTypeId.FCL) {
            contributionLabel = this.getTranslate("BASIC_DATA.TOTAL_TEU");
            contributionCode = "TOTAL_TEUS";
            contributionTooltip = this.getTranslate("PRODUCT.EQUIP_QTY_TYPE_TOOLTIP_OFFER ");
        }
        else if (typeCargo.ID == ECargoTypeId.AIR) {
            contributionLabel = this.getTranslate("GENERAL.TOTAL_TONS");
            contributionCode = "TOTAL_WEIGHT";
            contributionTooltip = this.getTranslate("PRODUCT.TON_TOOLTIP_OFFER");
            contributionMask = 4;
        }
        else if (typeCargo.ID == ECargoTypeId.ROAD) {
            contributionLabel = this.getTranslate("PRODUCT.TOTAL_VEHICLES");
            contributionCode = "TOTAL_VEHICLES";
            contributionTooltip = this.getTranslate("PRODUCT.VEHICLE_QTY_TOOLTIP_OFFER");
        }
        else if (typeCargo.ID == ECargoTypeId.BREAK_BULK || typeCargo.ID == ECargoTypeId.LCL || typeCargo.ID == ECargoTypeId.RO_RO) {
            contributionLabel = this.getTranslate("PRODUCT.TOTAL_FILE");
            contributionCode = "TOTAL_FILE";
            contributionTooltip = this.getTranslate("PRODUCT.FIX_PER_OPTION_TOOLTIP_OFFER");
        }
        const entranceTable = `
            <table class="table light-gray-border table-bordered m-b-none">
                <thead>
                    <tr>
                        <th>${this.getTranslate("GENERAL.PARTICULARS")}</th>
                        <th>${this.getTranslate("FINANCIAL.VALUE")}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>${this.getTranslate("FINANCIAL.TOTAL_COST_BRL")}</td>
                        <td>
                            ${indicators && indicators.TOTAL_PAYMENT != null ? this.$filterService('number')(indicators.TOTAL_PAYMENT, 2) : ''}
                            <i id="tooltipTotalPayment" class="fa fa-info-circle text-cyano" aria-hidden="true"
                                tooltip-placement="auto top"
                                uib-tooltip="{{ 'PRODUCT.FIX_TOTAL_PAYMENT_TOOLTIP_OFFER ' | translate }}"
                                tooltip-append-to-body="true">
                            </i>
                        </td>
                    </tr>
                    <tr>
                        <td>${this.getTranslate("FINANCIAL.TOTAL_REVENUE_BRL")}</td>
                        <td>
                            ${indicators && indicators.TOTAL_RECEIVING != null ? this.$filterService('number')(indicators.TOTAL_RECEIVING, 2) : ''}
                            <i id="tooltipTotalReceiving" class="fa fa-info-circle text-cyano" aria-hidden="true"
                                tooltip-placement="auto top"
                                uib-tooltip="{{ 'PRODUCT.FIX_TOTAL_PROFISHARE_TOOLTIP_OFFER' | translate }}"
                                tooltip-append-to-body="true">
                            </i>
                        </td>
                    </tr>
                    <tr>
                        <td>${this.getTranslate("FINANCIAL.TOTAL_PROFIT_SHARE_AGENT_BRL")}</td>
                        <td>
                            ${indicators && indicators.TOTAL_PROFIT_SHARE != null ? this.$filterService('number')(indicators.TOTAL_PROFIT_SHARE, 2) : ''}
                            <i id="tooltipTotalProfitShare" class="fa fa-info-circle text-cyano" aria-hidden="true"
                                tooltip-placement="auto top"
                                uib-tooltip="{{ 'GENERAL.SUM_PROFIT_SHARE' | translate }}"
                                tooltip-append-to-body="true">
                            </i>
                        </td>
                    </tr>
                    <tr>
                        <td>${contributionLabel}</td>
                        <td>
                            ${indicators[contributionCode] != null ? this.$filterService('number')(indicators[contributionCode], contributionMask) : ''}
                            <i id="tooltipContribution" class="fa fa-info-circle text-cyano" aria-hidden="true"
                                tooltip-placement="auto top"
                                uib-tooltip="${contributionTooltip}"
                                tooltip-append-to-body="true">
                            </i>
                        </td>
                    </tr>
                </tbody>
            </table>
        `;
        return entranceTable;
    }

    private buildIndicatorTable(currentCurrency: SelectorModel, indicators: IOfferOptionIndicators) {
        const typeCargo = this.scope.model.OFFER_TAB ? this.scope.model.OFFER_TAB.TYPE_CARGO : null;
        if (!typeCargo) throw Error('type cargo is null');
        const productId = this.scope.model.OFFER_TAB.PRODUCT.ID ? this.scope.model.OFFER_TAB.PRODUCT.ID : null;
        let contributionLabel = "";
        let contributionInitials = "";
        let contributionRecipe = "";
        let contributionCode = "";
        let contributionMask = 2;
        let wdLabel = "";
        let wdInitials = "";
        let wdRecipe = "";
        let wdCode = "";
        if ((productId == EProductId.MARITIME_EXPORT || productId == EProductId.MARITIME_IMPORT) && typeCargo.ID == ECargoTypeId.FCL) {
            contributionLabel = this.getTranslate("FINANCIAL.CT_TEU_FORMULA");
            contributionInitials = "CT TEU";
            contributionRecipe = "GP/Teus";
            contributionCode = "EGP_TOTAL_TEUS";

            wdLabel = this.getTranslate("FINANCIAL.CT_TEU_WITHOUT_DOCS");
            wdInitials = "CT File w/d";
            wdRecipe = "CT sem docs/Teus";
            wdCode = "CTWD";
        }
        else if ((productId == EProductId.MARITIME_EXPORT || productId == EProductId.MARITIME_IMPORT) && typeCargo.ID == ECargoTypeId.LCL) {
            contributionLabel = this.getTranslate("FINANCIAL.CT_FILE");
            contributionInitials = "CT/File";
            contributionRecipe = "GP/File";
            contributionCode = "EGP_TOTAL_FILE";

            wdLabel = this.getTranslate("FINANCIAL.CT_FILE_WITHOUT_DOCS");
            wdInitials = "File";
            wdRecipe = "CT without doc/File";
            wdCode = "CTWD_FILE";
        }
        else if ((productId == EProductId.AIR_EXPORT || productId == EProductId.AIR_IMPORT) && typeCargo.ID == ECargoTypeId.AIR) {
            contributionLabel = this.getTranslate("PRODUCT.CONTRIBUTION_BY_TON");
            contributionInitials = "CT/Ton";
            contributionRecipe = "GP/Tons";
            contributionCode = "EGP_TOTAL_WEIGHT";

            wdLabel = this.getTranslate("PRODUCT.CT_WD_TON");
            wdInitials = "Ton";
            wdRecipe = "CT without doc/Tons";
            wdCode = "CTWD_WEIGHT";
        }
        else if ((productId == EProductId.ROAD_EXPORT || productId == EProductId.ROAD_IMPORT) && typeCargo.ID == ECargoTypeId.ROAD) {
            contributionLabel = this.getTranslate("PRODUCT.CONTRIBUTION_VEHICLE");
            contributionRecipe = "CT/Veículo";
            contributionInitials = "C/V";
            contributionCode = "EGP_TOTAL_VEHICLES";

            wdLabel = this.getTranslate("PRODUCT.CT_WD_BY_VEHICLE");
            wdInitials = "Veículo";
            wdRecipe = "CT without doc/Veículo";
            wdCode = "CTWD_VEHICLES";
        }
        else if (typeCargo.ID == ECargoTypeId.BREAK_BULK || typeCargo.ID == ECargoTypeId.RO_RO) {
            contributionLabel = this.getTranslate("FINANCIAL.CT_FILE");
            contributionInitials = "C/F";
            contributionRecipe = "GP/File";
            contributionCode = "EGP_TOTAL_FILE";

            wdLabel = this.getTranslate("FINANCIAL.CT_FILE_WITHOUT_DOCS");
            wdInitials = "File";
            wdRecipe = "CT without doc /File";
            wdCode = "CTWD_FILE";
        }
        const indicatorTable = `
            <table class="table light-gray-border table-bordered m-b-none">
                <thead>
                    <tr>
                        <th>${this.getTranslate("GENERAL.INDICATOR")}</th>
                        <th>${this.getTranslate("FINANCIAL.FORMULA")}</th>
                        <th>${this.getTranslate("GENERAL.EXAMPLE")}</th>
                        <th>${this.getTranslate("GENERAL.CURRENCY")}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>${this.getTranslate("FINANCIAL.ESTIMATED_GROSS_PROFIT")}</td>
                        <td>${this.getTranslate("FINANCIAL.GP_FORMULA")}</td>
                        <td>${indicators && indicators.ESTIMATED_GROSS_PROFIT != null ? this.$filterService('number')(indicators.ESTIMATED_GROSS_PROFIT, 2) : ''}</td>
                        <td>${currentCurrency ? currentCurrency.CODE : ''}</td>
                    </tr>
                    <tr>
                        <td>${contributionLabel}</td>
                        <td>${contributionRecipe}</td>
                        <td>${indicators[contributionCode] != null ? this.$filterService('number')(indicators[contributionCode], contributionMask) : ''}</td>
                        <td>${currentCurrency ? currentCurrency.CODE : ''}</td>
                    </tr>
                    <tr>
                        <td>${wdLabel}</td>
                        <td>${wdRecipe}</td>
                        <td>${indicators[wdCode] != null ? this.$filterService('number')(indicators[wdCode], contributionMask) : ''}</td>
                        <td>${currentCurrency ? currentCurrency.CODE : ''}</td>
                    </tr>
                </tbody>
            </table>
        `;
        return indicatorTable;
    }

    private async openLinkOptions(): Promise<void> {
        const selectedOptions: IOfferOptionConfirmation[] = this.scope.selectedOptions && this.scope.selectedOptions.length ? this.scope.selectedOptions.filter(option => option.SELECTED) : [];
        if (!selectedOptions || selectedOptions && selectedOptions.length < 2) return this.notifyError(this.getTranslate("GENERAL.AT_LEAST_LINK"));

        let hierarchyIndex = 0;
        this.scope.linkOptions = selectedOptions.map(option => {
            hierarchyIndex++;
            const hierarchy = this.scope.hierarchyList && this.scope.hierarchyList.length ? this.scope.hierarchyList.find(hierarchy => hierarchy.ID == hierarchyIndex.toString()) : null;
            return { _id: option._id, OFFER: option.OFFER_CODE_EXHIBITION, HIERARCHY: hierarchy, CONCATENATED_COMPLEMENT: option.CONCATENATED_COMPLEMENT }
        });
        this.modalLinkOptionId = this.modalService.newModal();

        const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
            {
                scope: this.scope,
                size: 'width-60',
                modalID: this.modalLinkOptionId,
                formService: 'register',
                template: require("../view/modals/offerWizardLinkOptionModal.html")
            },
            {
                actionButtonClass: 'btn-success',
                actionButtonText: "GENERAL.SAVE",
                closeButtonText: "GENERAL.CLOSE",
                headerText: "PRODUCT.OFFER_LINK"
            }
        );

        modalInstance.rendered.then(() => {
            if (this.scope.linkOptions && this.scope.linkOptions.length) {
                this.timeout(() => {
                    for (const index in this.scope.linkOptions) {
                        this.scope.selectorValidity("linkOptionHierarchy" + index);
                    }
                }, 500);

            }
        });

        modalInstance.closed.then(() => {
            this.scope.linkOptions = null;
        });
    }

    private async applyLinkOptionModal(apply: boolean): Promise<void> {
        try {
            let success = true;
            if (apply) {
                this.block();
                const hasInvalid = await this.hasInvalidRequiredElements('offerWizardLinkOptionForm');
                if (hasInvalid) return;
                const filter: IOfferOptionPaymentRequestHierarchy = { OFFER_WIZARD_FILTER_ID: this.OfferWizardFilterId, OFFER_OPTIONS_HIERARCHY: this.scope.linkOptions && this.scope.linkOptions.length ? this.scope.linkOptions.map(option => { return { _id: option._id, HIERARCHY: option.HIERARCHY ? option.HIERARCHY.NAME : null, CONCATENATED_COMPLEMENT: option.CONCATENATED_COMPLEMENT } }) : null };
                const resultOperation: IOfferOptionPaymentHierarchyResult = await this.restService.newObjectPromise(`${this.quotationUrl}/offerOptionPayment/hierarchy`, filter, 30000, false);
                this.unblock();
                if (resultOperation && typeof resultOperation.data == "string") {
                    success = false;
                    this.notifyError(resultOperation.data);
                }
                else {
                    const resultOperation: IOfferOptionPaymentListResult = await this.restService.newObjectPromise(`${this.quotationUrl}/offerOptionPayment/list`, { id: this.scope.model._id }, 30000, false);
                    if (resultOperation) {
                        if (resultOperation.SHOW) {
                            // filter offer_option - only is_valid = true.
                            resultOperation.ROUTES = resultOperation.ROUTES && resultOperation.ROUTES.length > 0 ? resultOperation.ROUTES.map(route => { route.OFFER_OPTION = route.OFFER_OPTION && route.OFFER_OPTION.length > 0 ? route.OFFER_OPTION.filter(offerOption => offerOption.IS_VALID) : null; return route }) : null;
                            this.scope.optionRoutesError = resultOperation.ROUTES && resultOperation.ROUTES.length > 0 ? resultOperation.ROUTES.filter(route => route.PROCESS_STATUS && route.PROCESS_STATUS.ERROR) : null;
                            this.scope.offerOptions = resultOperation.ROUTES && resultOperation.ROUTES.length > 0 ? this.buildOfferOptions(resultOperation.ROUTES.filter(route => !route.PROCESS_STATUS || (route.PROCESS_STATUS && !route.PROCESS_STATUS.ERROR))) : null;
                            this.scope.hasAnyOption = this.scope.offerOptions && this.scope.offerOptions.length > 0;
                            this.updateConfirmationOptionList(true);
                            const checkboxAll = document.getElementsByName('selectAllOfferOptionConfirmation')[0] as HTMLInputElement;
                            checkboxAll.checked = false;
                            this.scope.$applyAsync();
                        }
                    }
                    this.notifySuccess("Hierarchies successfully generated.");
                }
            }
            if (success) {
                this.modalService.closeModal(this.modalLinkOptionId, apply);
                this.modalLinkOptionId = 0;
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getTodayOnlyDate(): Date {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return today;
    }

    private async applySelectionModal(apply: boolean): Promise<void> {
        try {
            if (apply) {
                const hasInvalid = await this.hasInvalidRequiredElements('offerWizardSelectionForm');
                if (hasInvalid) return;
            }
            this.modalService.closeModal(this.modalSelectionId, apply);
            this.modalSelectionId = 0;
            this.timeout(() => {
                this.loadRegisterForm(false);
            });

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async validateRouteTab(): Promise<boolean> {
        const routeTabModel = this.scope.model.ROUTE_TAB;
        let valid = false;
        if (routeTabModel && routeTabModel.ROUTES && routeTabModel.ROUTES.some(route => route.SELECTED)) valid = true;
        if (!valid) this.notifyError('You must have at least one route selected to continue.');
        if (valid) valid = await this.createOfferWizardFilter(false);
        return valid;
    }

    private validateCargoTab(): boolean {
        const offerTabModel = this.scope.model.OFFER_TAB;
        const cargoTabModel = this.scope.model.CARGO_TAB;
        let valid = false;
        if (offerTabModel && offerTabModel.TYPE_CARGO && offerTabModel.TYPE_CARGO.ID == ECargoTypeId.AIR && cargoTabModel && cargoTabModel.AIR) {
            if (cargoTabModel.AIR.OVER_CHARGEABLE_WEIGHT != null && cargoTabModel.AIR.OVER_CHARGEABLE_WEIGHT <= cargoTabModel.AIR.CHARGEABLE_WEIGHT)
                this.notifyError('Over chargeable weight cannot be less than or equal to the quantity.');
            else if (!this.isAirWeightRangeValid())
                this.notifyError('The range informed does not correspond to the chargeable weight and/or over chargeable weight informed.');
            else valid = true;
        } else if (offerTabModel && offerTabModel.TYPE_CARGO && offerTabModel.TYPE_CARGO.ID == ECargoTypeId.FCL && cargoTabModel && (!cargoTabModel.FCL || cargoTabModel.FCL && cargoTabModel.FCL.length == 0)) {
            this.notifyError('You must have at least one equipment to continue.');
        } else if (offerTabModel && offerTabModel.TYPE_CARGO && offerTabModel.TYPE_CARGO.ID == ECargoTypeId.ROAD && cargoTabModel && (!cargoTabModel.FTL || cargoTabModel.FTL && cargoTabModel.FTL.length == 0)) {
            this.notifyError('You must have at least one truck type to continue.');
        } else valid = true;
        return valid;
    }

    private isAirWeightRangeValid(): boolean {
        let valid = false;
        const chargeableWeight = this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT;
        const overChargeableWeight = this.scope.model.CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT;
        const weightRangeSelected: IWeightRangeSelector[] = this.scope.weightRangeFullList && this.scope.weightRangeFullList.length ? this.scope.weightRangeFullList.filter(weightRange => this.scope.model.CARGO_TAB.AIR.WEIGHT_RANGE.some(weightRangeToVerify => weightRangeToVerify.ID == weightRange.ID)) : null;
        for (let index = 0; index < weightRangeSelected.length; index++) {
            const weightRange = weightRangeSelected[index];
            const isChargeableWeightValid = chargeableWeight ? OfferOldNewAlternativeHelper.betweenNumber(chargeableWeight, weightRange.MIN_WEIGHT, weightRange.MAX_WEIGHT) : false;
            const isOverChargeableWeightValid = overChargeableWeight ? OfferOldNewAlternativeHelper.betweenNumber(overChargeableWeight, weightRange.MIN_WEIGHT, weightRange.MAX_WEIGHT) : false;
            if (isChargeableWeightValid || isOverChargeableWeightValid) {
                valid = true;
                break;
            }
        }
        return valid;
    }

    private async validateSpecTab(): Promise<boolean> {
        let valid = true;
        try {
            this.block();
            if (valid) await this.updateOfferWizardFilter();
            if (!this.scope.model.SPEC_TAB.ROUTE || this.scope.model.SPEC_TAB.ROUTE.length < 0) {
                this.notifyError('Wait for the requirements to be loaded.');
                valid = false;
            }
        } catch (ex) {
            this.handleError(ex);
            valid = false;
        } finally {
            this.unblock();
            return valid;
        }
    }

    private validateOptionTab(): boolean {
        let valid = false;
        if (this.scope.offerOptions && this.scope.offerOptions.some(option => option.SELECTED)) valid = true;
        if (!valid) this.notifyError('You must have at least one option selected to continue.');
        return valid;
    }

    private async initRouteTab() {
        try {
            this.block();
            if (this.scope.model.ROUTE_TAB && (this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_PAYMENT || this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_RECEIVING)) {
                if (this.scope.inputType != EInputTypes.REPLICATE_OFFER && this.scope.inputType != EInputTypes.PRE_OFFER) {
                    this.scope.model.ROUTE_TAB.ROUTES = await this.getOfferWizardOriginDestination();
                }
                if (this.scope.inputType == EInputTypes.PRE_OFFER) {
                    if (this.scope.model.ROUTE_TAB && this.scope.model.ROUTE_TAB.ROUTES && this.scope.model.ROUTE_TAB.ROUTES.length) this.scope.model.ROUTE_TAB.ROUTES[0].SELECTED = true;
                }
                this.scope.$applyAsync();
            }
            if (!this.scope.model.ROUTE_TAB.ROUTES || (this.scope.model.ROUTE_TAB.ROUTES && this.scope.model.ROUTE_TAB.ROUTES.length == 0)) this.addRoute();
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.updateCargoCalculatedFields();
            this.loadRegisterForm(false);
            this.unblock();
        }
    }

    private async initCargoTab() {
        try {
            this.block();
            if (this.scope.profile && this.scope.profile.selectedProfile) {
                this.scope.model.CARGO_TAB = {
                    BB_RORO: this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.BREAK_BULK || this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.RO_RO ? this.scope.currentState.CARGO_TAB.BB_RORO : null,
                    FCL: this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.FCL ? this.scope.currentState.CARGO_TAB.FCL : null,
                    FTL: this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.ROAD ? this.scope.currentState.CARGO_TAB.FTL : null,
                    AIR: this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.AIR ? this.scope.currentState.CARGO_TAB.AIR : null,
                    LCL_APPLICATION: this.scope.currentState.CARGO_TAB.LCL_APPLICATION,
                    LCL_AMOUNT: this.scope.currentState.CARGO_TAB.LCL_AMOUNT,
                    VOLUME: this.scope.currentState.CARGO_TAB.VOLUME,
                    GROSS_WEIGHT: this.scope.currentState.CARGO_TAB.GROSS_WEIGHT,
                    CUBIC_WEIGHT: this.scope.currentState.CARGO_TAB.CUBIC_WEIGHT,
                    CARGO_LIST: this.scope.currentState.CARGO_TAB.CARGO_LIST,
                    STACKABLE: this.scope.currentState.CARGO_TAB.STACKABLE,
                    STANDARD_EQUIPMENT: this.scope.currentState.CARGO_TAB.STANDARD_EQUIPMENT,
                    CARGO_SOC: this.scope.currentState.CARGO_TAB.CARGO_SOC,
                    EQUIPMENT_PROVIDER: this.scope.currentState.CARGO_TAB.EQUIPMENT_PROVIDER,
                    ID_EQUIPMENT_PROVIDER: this.scope.currentState.CARGO_TAB.ID_EQUIPMENT_PROVIDER,
                    FLEXITANK: this.scope.currentState.CARGO_TAB.FLEXITANK,
                };
            }
            if (this.scope.model.CARGO_TAB) {
                if (this.scope.inputType != EInputTypes.REPLICATE_OFFER) {
                    const regularEquipment = this.scope.equipSpecList ? this.scope.equipSpecList.find(equip => equip.ID == EEquipSpecId.REGULAR) : null;
                    this.scope.model.CARGO_TAB.STANDARD_EQUIPMENT = regularEquipment;
                    if (this.scope.model.OFFER_TAB.TYPE_CARGO) {
                        // Aéreo.
                        if (this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.AIR && (!this.scope.model.CARGO_TAB.AIR || this.scope.model.CARGO_TAB.AIR && !this.scope.model.CARGO_TAB.AIR.APPLICATION)) {
                            const taxedWeightInternalSequence = "003";
                            const application = await this.restService.newObjectPromise(`${this.getUrlProduct()}/application/list/custom`, { internalSequence: taxedWeightInternalSequence }, 15000, false);
                            let result: IApplicationList = null;
                            if (application && application.length > 0) result = { ID: application[0].ID, NAME: application[0].NAME, CODE: application[0].CODE, PERCENT: application[0].PERCENT, APPLICATION_COMPLEMENT: application[0].APPLICATION_COMPLEMENT, INTERNAL_SEQUENCE: application[0].INTERNAL_SEQUENCE, FREE_TYPING_AMOUNT_CHARGE: application[0].FREE_TYPING_AMOUNT_CHARGE, CT_WITHOUT_DOC: application[0].CT_WITHOUT_DOC, NAME_INTL: application[0].NAME_INTL };
                            if (!result) this.notifyError(this.getTranslate('GENERAL.MESSAGES.NOT_POSSIBLE_TO_FIND_CHARGEABLE_WEIGHT_IN_APPLICATION'));
                            const air: IAirCargo = {
                                ORIGINAL_AIR_CARGO: null,
                                WEIGHT_BREAKDOWN: false,
                                APPLICATION: result,
                                CHARGEABLE_WEIGHT: 0,
                                OVER_CHARGEABLE_WEIGHT: null,
                                WEIGHT_RANGE: null,
                                WEIGHT_RANGE_DEFAULT: null,
                                CHARGEABLE_WEIGHT_VALID: null,
                                WEIGHT_RANGE_BASE: null
                            };
                            this.scope.model.CARGO_TAB.AIR = air;
                            this.scope.$applyAsync();
                            await this.calculateOperationChargeable();
                            this.updateWeightRangeBase();
                        }
                        else if (this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.FCL) {
                            this.scope.model.CARGO_TAB.FCL = [];
                            const offerEquipmentList = await this.getOfferEquipmentList();
                            if (offerEquipmentList && offerEquipmentList.length > 0) {
                                for (const equipment of offerEquipmentList) this.scope.model.CARGO_TAB.FCL.push({ ORIGINAL: null, EQUIPMENT: equipment.EQUIPMENT, AMOUNT: equipment.AMOUNT });
                            }
                            if (!this.scope.model.CARGO_TAB.FCL || (this.scope.model.CARGO_TAB.FCL && this.scope.model.CARGO_TAB.FCL.length == 0)) this.addCargoFCL();
                        }
                        else if (this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.BREAK_BULK || this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.LCL || this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.RO_RO) {
                            const wmInternalSequence = "015";
                            const application = await this.restService.newObjectPromise(`${this.getUrlProduct()}/application/list/custom`, { internalSequence: wmInternalSequence }, 15000, false);
                            let result: IApplicationList = null;
                            if (application && application.length > 0) result = { ID: application[0].ID, NAME: application[0].NAME, CODE: application[0].CODE, INTERNAL_SEQUENCE: application[0].INTERNAL_SEQUENCE, NAME_INTL: application[0].NAME_INTL };
                            if (!result) this.notifyError('It was not possible to find the Cubed Weight unit in the application register.');
                            this.scope.model.CARGO_TAB.LCL_APPLICATION = result;
                            this.scope.model.CARGO_TAB.LCL_AMOUNT = 1;
                            await this.calculateOperationWM();
                            this.scope.$applyAsync();
                        }
                        else if (this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.ROAD && (!this.scope.model.CARGO_TAB.FTL || (this.scope.model.CARGO_TAB.FTL && this.scope.model.CARGO_TAB.FTL.length == 0))) {
                            this.addCargoFTL();
                        }
                    }
                } else if (this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.AIR) {
                    this.updateWeightRangeBase();
                    this.updateCargoCalculateFieldsDisabledControl(this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT);
                }
            }
        } catch (ex) {
            this.handleError(ex);
            this.scope.steps.current--;
        } finally {
            this.scope.cargoListGrossWeightFieldsControl(this.scope.model.CARGO_TAB.GROSS_WEIGHT);
            this.unblock();
        }
    }

    private async initSpecTab() {
        try {
            if (this.scope.profile.selectedProfile && this.scope.currentState.SPEC_TAB.ROUTE && this.scope.currentState.SPEC_TAB.ROUTE.length) this.scope.model.SPEC_TAB = this.scope.currentState.SPEC_TAB;
            await this.getAndUpdateRouteAgentValues();
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private initOptionTab() {
        try {
            this.block();
            this.updateOrdinationList();
            this.getAndUpdateOptionList();
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private initPreviewTab() {
        try {
            this.updateConfirmationOptionList();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async createOfferWizardFilter(reprocess?: boolean): Promise<boolean> {
        let success = true;
        try {
            this.block();
            this.updateWeightRangeBase();
            this.loadAirCargo();
            const formatedData = angular.copy(this.scope.model);
            formatedData.REPROCESS = reprocess;
            if (formatedData.CARGO_TAB && formatedData.CARGO_TAB.AIR && !formatedData.CARGO_TAB.AIR.CHARGEABLE_WEIGHT) formatedData.CARGO_TAB.AIR.CHARGEABLE_WEIGHT = null;
            if (formatedData.CARGO_TAB && formatedData.CARGO_TAB.EQUIPMENT_PROVIDER && formatedData.CARGO_TAB.EQUIPMENT_PROVIDER.ID) formatedData.CARGO_TAB.ID_EQUIPMENT_PROVIDER = parseInt(formatedData.CARGO_TAB.EQUIPMENT_PROVIDER.ID);
            if (formatedData.ROUTE_TAB && formatedData.ROUTE_TAB.ROUTES) formatedData.ROUTE_TAB.ROUTES = formatedData.ROUTE_TAB.ROUTES.filter(route => route.SELECTED);
            if (formatedData.SPEC_TAB && formatedData.SPEC_TAB.ROUTE && formatedData.SPEC_TAB.ROUTE.length) formatedData.SPEC_TAB.ROUTE = formatedData.SPEC_TAB.ROUTE.map(route => {
                return {
                    NAME: route.NAME ? route.NAME : null,
                    ORIGIN: route.ORIGIN ? route.ORIGIN : null,
                    DESTINATION: route.DESTINATION ? route.DESTINATION : null,
                    MOVE_TYPE: route.MOVE_TYPE ? route.MOVE_TYPE : null,
                    MASTER_DIRECT: route.MASTER_DIRECT,
                    LOCAL_AGENT: route.LOCAL_AGENT ? route.LOCAL_AGENT : null,
                    EXTERNAL_AGENT: route.EXTERNAL_AGENT ? route.EXTERNAL_AGENT : null,
                    OTHER_AGENT: route.OTHER_AGENT ? route.OTHER_AGENT : null,
                    AGENT_NETWORK_ID: route.AGENT_NETWORK_ID ? route.AGENT_NETWORK_ID : null,
                    MASTER_MODALITY: route.MASTER_MODALITY ? route.MASTER_MODALITY : null,
                    HOUSE_MODALITY: route.HOUSE_MODALITY ? route.HOUSE_MODALITY : null,
                    ACCOUNT_REQUIREMENT_ID: route.ACCOUNT_REQUIREMENT_ID ? route.ACCOUNT_REQUIREMENT_ID : null,
                    ACCOUNT_REQUIREMENT_CONCATENATED: route.ACCOUNT_REQUIREMENT_CONCATENATED ? route.ACCOUNT_REQUIREMENT_CONCATENATED : null
                }
            });
            const resultOperation: IOfferWizardModalModel = await this.restService.newObjectPromise(`${this.quotationUrl}/filter/insert`, { data: formatedData, oldData: this.scope.scopeBeforeSave }, 30000, false);
            if (resultOperation && resultOperation._id && resultOperation._id) {
                this.scope.model._id = resultOperation._id;
                this.scope.scopeBeforeSave = formatedData;
            }
        } catch (ex) {
            success = false;
            this.handleError(ex);
        } finally {
            this.unblock();
            return success;
        }
    }

    private async updateOfferWizardFilter(): Promise<void> {
        try {
            if (this.scope.model.CARGO_TAB && this.scope.model.CARGO_TAB.AIR)
                this.calculateAirHigherAmount();

            const formatedData = angular.copy(this.scope.model);
            if (formatedData.SPEC_TAB.SECURE && !formatedData.SPEC_TAB.SECURE.ID_CHARGE_NAME_EXHIBITION)
                formatedData.SPEC_TAB.SECURE = null;
            if (formatedData.ROUTE_TAB && formatedData.ROUTE_TAB.ROUTES) formatedData.ROUTE_TAB.ROUTES = formatedData.ROUTE_TAB.ROUTES.filter(route => route.SELECTED);
            if (!formatedData.OFFER_TAB.COMMODITY_SECTION) formatedData.OFFER_TAB.COMMODITY_SECTION = null;
            if (formatedData.CARGO_TAB.EQUIPMENT_PROVIDER) formatedData.CARGO_TAB.ID_EQUIPMENT_PROVIDER = parseInt(formatedData.CARGO_TAB.EQUIPMENT_PROVIDER.ID);
            const resultOperation = await this.restService.newObjectPromise(`${this.quotationUrl}/filter/update`, { data: formatedData, oldData: this.scope.scopeBeforeSave }, 30000, false);
            if (!resultOperation) throw this.notifyError('There was an error in the offer filter update routine, please contact an administrator.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getAndUpdateRouteAgentValues(): Promise<void> {
        try {
            this.block();
            // Check if the input type is replication from another offer. If it is, then backup the data for later.
            let backUpSpecTab: ISpecTab = null;
            if (this.scope.inputType == EInputTypes.REPLICATE_OFFER || this.scope.inputType == EInputTypes.PRE_OFFER) {
                backUpSpecTab = angular.copy(this.scope.model.SPEC_TAB);
            }

            this.scope.model.SPEC_TAB.ROUTE = null;
            const result: ISpecRoute[] = [];
            const offerTabModel = this.scope.model.OFFER_TAB ? this.scope.model.OFFER_TAB : null;
            const selectedRoutes = this.scope.model.ROUTE_TAB && this.scope.model.ROUTE_TAB.ROUTES ? this.scope.model.ROUTE_TAB.ROUTES.filter(route => route.SELECTED) : null;
            if (offerTabModel && this.scope.model.SPEC_TAB && selectedRoutes && selectedRoutes.length > 0) {
                const originDestination: IRouteAgentOriginDestination[] = [];
                selectedRoutes.forEach(element => {
                    originDestination.push({ DESTINATION: element.DESTINATION, ORIGIN: element.ORIGIN, MOVE_TYPE: element.MOVE_TYPE });
                });
                const filter: IOfferWizardRequestRouteAgent = {
                    ORIGIN_DESTINATION: originDestination,
                    PRODUCT: offerTabModel.PRODUCT ? offerTabModel.PRODUCT.ID : null,
                    TYPE_CARGO: offerTabModel.TYPE_CARGO ? offerTabModel.TYPE_CARGO.ID : null,
                    PROCESS_TYPE: offerTabModel.PROCESS_TYPE ? offerTabModel.PROCESS_TYPE.ID : null,
                    COMMODITY_SECTION: offerTabModel.COMMODITY ? offerTabModel.COMMODITY.map(commodity => commodity.ID) : [],
                    ACCOUNT: offerTabModel.ACCOUNT ? parseInt(offerTabModel.ACCOUNT.ID) : null
                };
                const resultOperation: IOfferWizardAgentResult[] = await this.restService.newObjectPromise(`${this.productUrl}/offerOldWizard/agent`, filter, 30000, false);
                if (resultOperation && resultOperation.length > 0) {

                    for (const element of resultOperation) {
                        let networkId = null;
                        if (element.LOCAL_AGENT && element.LOCAL_AGENT.ID_NETWORK) networkId = element.LOCAL_AGENT.ID_NETWORK;
                        else if (element.EXTERNAL_AGENT && element.EXTERNAL_AGENT.ID_NETWORK) networkId = element.EXTERNAL_AGENT.ID_NETWORK;
                        else if (element.OTHER_AGENT && element.OTHER_AGENT.ID_NETWORK) networkId = element.OTHER_AGENT.ID_NETWORK;
                        let name = element.ORIGIN_DESTINATION && element.ORIGIN_DESTINATION.ORIGIN ? element.ORIGIN_DESTINATION.ORIGIN.CODE : "";
                        if (element.ORIGIN_DESTINATION && element.ORIGIN_DESTINATION.DESTINATION) name += ` x ${element.ORIGIN_DESTINATION.DESTINATION.CODE}`;

                        if (!networkId && this.scope.inputType == EInputTypes.REPLICATE_OFFER && backUpSpecTab) {
                            const finded = backUpSpecTab.ROUTE.find(item => {
                                return element.ORIGIN_DESTINATION && element.ORIGIN_DESTINATION.MOVE_TYPE && element.ORIGIN_DESTINATION.ORIGIN && element.ORIGIN_DESTINATION.DESTINATION && item.MOVE_TYPE.ID === element.ORIGIN_DESTINATION.MOVE_TYPE.ID && item.ORIGIN.ID === element.ORIGIN_DESTINATION.ORIGIN.ID && item.DESTINATION.ID === element.ORIGIN_DESTINATION.DESTINATION.ID
                            })
                            if (finded && finded.AGENT_NETWORK_ID) networkId = finded.AGENT_NETWORK_ID
                        }

                        const route = {
                            NAME: name,
                            ORIGIN: element.ORIGIN_DESTINATION ? element.ORIGIN_DESTINATION.ORIGIN : null,
                            DESTINATION: element.ORIGIN_DESTINATION ? element.ORIGIN_DESTINATION.DESTINATION : null,
                            MOVE_TYPE: element.ORIGIN_DESTINATION ? element.ORIGIN_DESTINATION.MOVE_TYPE : null,
                            MASTER_DIRECT: element.MASTER_DIRECT,
                            LOCAL_AGENT: element.LOCAL_AGENT ? { ID: element.LOCAL_AGENT.ID, NAME: element.LOCAL_AGENT.NAME, CODE: element.LOCAL_AGENT.ID_NETWORK ? element.LOCAL_AGENT.ID_NETWORK.toString() : null, LEGAL_PERSON_ID: element.LOCAL_AGENT.LEGAL_PERSON_ID } : null,
                            EXTERNAL_AGENT: element.EXTERNAL_AGENT ? { ID: element.EXTERNAL_AGENT.ID, NAME: element.EXTERNAL_AGENT.NAME, CODE: element.EXTERNAL_AGENT.ID_NETWORK ? element.EXTERNAL_AGENT.ID_NETWORK.toString() : null, LEGAL_PERSON_ID: element.EXTERNAL_AGENT.LEGAL_PERSON_ID } : null,
                            OTHER_AGENT: element.OTHER_AGENT ? { ID: element.OTHER_AGENT.ID, NAME: element.OTHER_AGENT.NAME, CODE: element.OTHER_AGENT.ID_NETWORK ? element.OTHER_AGENT.ID_NETWORK.toString() : null, LEGAL_PERSON_ID: element.OTHER_AGENT.LEGAL_PERSON_ID } : null,
                            AGENT_NETWORK_ID: networkId,
                            MASTER_MODALITY: null,
                            HOUSE_MODALITY: null,
                            ACCOUNT_REQUIREMENT_ID: null,
                            ACCOUNT_REQUIREMENT_CONCATENATED: null
                        };
                        const filter: IOfferWizardRequestRouteAccount = {
                            ID_ACCOUNT: offerTabModel.ACCOUNT ? parseInt(offerTabModel.ACCOUNT.ID) : null, PROCESS_TYPE: offerTabModel.PROCESS_TYPE ? offerTabModel.PROCESS_TYPE.ID : null, ID_ROUTING_POINT_ORIGIN: route.ORIGIN ? parseInt(route.ORIGIN.ID) : null, ID_ROUTING_POINT_DESTINATION: route.DESTINATION ? parseInt(route.DESTINATION.ID) : null, ID_PRODUCT: offerTabModel.PRODUCT ? offerTabModel.PRODUCT.ID : null, ID_INCOTERM: offerTabModel.INCOTERM ? parseInt(offerTabModel.INCOTERM.ID) : null, MASTER_DIRECT: route.MASTER_DIRECT, ID_NETWORK: route.AGENT_NETWORK_ID, ID_EXPORTER: offerTabModel.EXPORTER ? parseInt(offerTabModel.EXPORTER.ID) : null, ID_IMPORTER: offerTabModel.IMPORTER ? parseInt(offerTabModel.IMPORTER.ID) : null,
                            ID_EXTERNAL_AGENT: route.EXTERNAL_AGENT ? parseInt(route.EXTERNAL_AGENT.ID) : null,
                            ID_PROVIDER: offerTabModel.PROVIDER ? parseInt(offerTabModel.PROVIDER.ID) : null,
                            ID_TYPE_CARGO: offerTabModel.TYPE_CARGO ? offerTabModel.TYPE_CARGO.ID : null,
                        };
                        const accountResult: IOfferWizardAccountResult = await this.getRouteAccountValues(filter);
                        let masterModality = null;
                        let houseModality = null;
                        let accountRequirementId = null;
                        let accountRequirementConcatenated = null;
                        if (accountResult) {
                            accountRequirementId = accountResult.ID;
                            accountRequirementConcatenated = accountResult.CONCATENATED;
                            if (accountResult.MASTER_MODALITY) masterModality = accountResult.MASTER_MODALITY.MODALITY;
                            if (accountResult.HOUSE_MODALITY) houseModality = accountResult.HOUSE_MODALITY.MODALITY;
                        }
                        if (!masterModality || !houseModality) {
                            const incoterm = await this.getIncotermValues({ ID_INCOTERM: this.scope.model.OFFER_TAB && this.scope.model.OFFER_TAB.INCOTERM ? parseInt(this.scope.model.OFFER_TAB.INCOTERM.ID) : null });
                            if (!masterModality) masterModality = incoterm.INCOME;
                            if (!houseModality) houseModality = incoterm.OUTCOME;
                        }
                        route.MASTER_MODALITY = masterModality;
                        route.HOUSE_MODALITY = houseModality;
                        route.ACCOUNT_REQUIREMENT_ID = accountRequirementId;
                        route.ACCOUNT_REQUIREMENT_CONCATENATED = accountRequirementConcatenated;
                        result.push(route);
                    }
                }
                if (result && result.length > 0) {
                    this.scope.model.SPEC_TAB.ROUTE = [];
                    for (let index in result) {
                        this.scope.model.SPEC_TAB.ROUTE.push(result[index]);
                        this.timeout(() => {
                            this.scope.selectorValidity("specRouteLocalAgent" + index);
                            this.scope.selectorValidity("specRouteExternalAgent" + index);
                            this.scope.selectorValidity("specRouteMasterModality" + index);
                            this.scope.selectorValidity("specRouteHouseModality" + index);
                        }, 200);
                    }
                }

                if ((this.scope.inputType == EInputTypes.REPLICATE_OFFER || this.scope.inputType == EInputTypes.PRE_OFFER) && (this.scope.model.SPEC_TAB.ROUTE && (this.scope.model.SPEC_TAB.ROUTE != null && this.scope.model.SPEC_TAB.ROUTE.length > 0))) {
                    this.scope.model.SPEC_TAB.ROUTE[0].EXTERNAL_AGENT = this.scope.model.SPEC_TAB.ROUTE[0].EXTERNAL_AGENT && this.scope.model.SPEC_TAB.ROUTE[0].EXTERNAL_AGENT ? this.scope.model.SPEC_TAB.ROUTE[0].EXTERNAL_AGENT : backUpSpecTab.ROUTE[0].EXTERNAL_AGENT ? backUpSpecTab.ROUTE[0].EXTERNAL_AGENT : null;
                    this.scope.model.SPEC_TAB.ROUTE[0].LOCAL_AGENT = this.scope.model.SPEC_TAB.ROUTE[0].LOCAL_AGENT && this.scope.model.SPEC_TAB.ROUTE[0].LOCAL_AGENT ? this.scope.model.SPEC_TAB.ROUTE[0].LOCAL_AGENT : backUpSpecTab.ROUTE[0].LOCAL_AGENT;
                    this.scope.model.SPEC_TAB.ROUTE[0].OTHER_AGENT = this.scope.model.SPEC_TAB.ROUTE[0].OTHER_AGENT && this.scope.model.SPEC_TAB.ROUTE[0].LOCAL_AGENT ? this.scope.model.SPEC_TAB.ROUTE[0].OTHER_AGENT : backUpSpecTab.ROUTE[0].OTHER_AGENT;
                    this.scope.model.SPEC_TAB.ROUTE[0].AGENT_NETWORK_ID = this.scope.model.SPEC_TAB.ROUTE[0].AGENT_NETWORK_ID && this.scope.model.SPEC_TAB.ROUTE[0].AGENT_NETWORK_ID ? this.scope.model.SPEC_TAB.ROUTE[0].AGENT_NETWORK_ID : backUpSpecTab.ROUTE[0].AGENT_NETWORK_ID;
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getIncotermValues(filter: IOfferWizardRequestIncoterm): Promise<IOfferWizardIncotermResult> {
        if (!filter) this.notifyError('filter is null');
        let result: IOfferWizardIncotermResult = null;
        try {
            result = await this.restService.newObjectPromise(`${this.productUrl}/offerOldWizard/incoterm`, filter, 30000, false);
        } catch (ex) {
            this.handleError(ex);
        }
        return result;
    }

    private async getRouteAccountValues(filter: IOfferWizardRequestRouteAccount): Promise<IOfferWizardAccountResult> {
        if (!filter) this.notifyError('filter is null');
        let result: IOfferWizardAccountResult = null;
        try {
            result = await this.restService.newObjectPromise(`${this.productUrl}/offerOldWizard/account`, filter, 30000, false);
        } catch (ex) {
            this.handleError(ex);
        }
        return result;
    }

    private initCountGetOptionList() {
        if (!this.scope.isSearchOfferOptionPaymentListCanceled) {
            this.timeout(() => {
                this.scope.offerOptionPaymentListCount += 5;
                if (this.scope.offerOptionPaymentListCount < 100) this.initCountGetOptionList();
                else if (!this.scope.isSearchOfferOptionPaymentListCanceled) this.timeout(() => { this.getAndUpdateOptionList() }, 1000);
            }, 100);
        }
    }

    private updateConfirmationOptionList(sortByHierarchy?: boolean): void {
        const selectedOptions: IOfferOptionConfirmation[] = [];
        try {
            if (this.scope.offerOptions && this.scope.offerOptions.length > 0) {
                for (const offerOption of this.scope.offerOptions) {
                    if (offerOption.SELECTED) {
                        let contribution: number = null;
                        let contributionWdoc: number = null;
                        let contributionMask: number = 0;
                        if (offerOption.INDICATORS) {
                            if (offerOption.TYPE_CARGO.ID == ECargoTypeId.FCL) {
                                contribution = offerOption.INDICATORS.EGP_TOTAL_TEUS;
                                contributionWdoc = offerOption.INDICATORS.CTWD;
                            }
                            else if (offerOption.TYPE_CARGO.ID == ECargoTypeId.AIR) {
                                contribution = offerOption.INDICATORS.EGP_TOTAL_WEIGHT;
                                contributionWdoc = offerOption.INDICATORS.CTWD_WEIGHT;
                                contributionMask = 4;
                            }
                            else if (offerOption.TYPE_CARGO.ID == ECargoTypeId.ROAD) {
                                contribution = offerOption.INDICATORS.EGP_TOTAL_VEHICLES;
                                contributionWdoc = offerOption.INDICATORS.CTWD_VEHICLES;
                            } else if (offerOption.TYPE_CARGO.ID == ECargoTypeId.BREAK_BULK || offerOption.TYPE_CARGO.ID == ECargoTypeId.LCL || offerOption.TYPE_CARGO.ID == ECargoTypeId.RO_RO) {
                                contribution = offerOption.INDICATORS.EGP_TOTAL_FILE;
                                contributionWdoc = offerOption.INDICATORS.CTWD_FILE;
                            }
                        }
                        selectedOptions.push({
                            _id: offerOption._id,
                            SELECTED: true,
                            OFFER_CODE_EXHIBITION: offerOption.OFFER_CODE_EXHIBITION,
                            CONCATENATED: offerOption.TARIFF_FREIGHT_CONCATENATED,
                            MASTER_MODALITY: offerOption.MASTER_MODALITY,
                            HOUSE_MODALITY: offerOption.HOUSE_MODALITY,
                            OPEN_DATE: this.scope.model.OFFER_TAB && this.scope.model.OFFER_TAB.LOAD_REF ? moment(this.scope.model.OFFER_TAB.LOAD_REF).format("DD/MM/YYYY") : "",
                            ACCOUNT: this.scope.model.OFFER_TAB && this.scope.model.OFFER_TAB.ACCOUNT ? this.scope.model.OFFER_TAB.ACCOUNT.NAME : "",
                            PROVIDER: offerOption.PROVIDER ? offerOption.PROVIDER.NAME : "",
                            MOVE_TYPE: offerOption.MOVE_TYPE ? offerOption.MOVE_TYPE.CODE : "",
                            ORIGIN: offerOption.ORIGIN ? offerOption.ORIGIN.DISPLAY_NAME : "",
                            DESTINATION: offerOption.DESTINATION ? offerOption.DESTINATION.DISPLAY_NAME : "",
                            SERVICE: offerOption.TRANSIT_TIME,
                            CONTRIBUTION: contribution,
                            CONTRIBUTION_WDOC: contributionWdoc,
                            CONTRIBUTION_MASK: contributionMask,
                            ESTIMATED_GROSS_PROFIT: offerOption.INDICATORS ? offerOption.INDICATORS.ESTIMATED_GROSS_PROFIT : null,
                            CONCATENATED_COMPLEMENT: offerOption.CONCATENATED_COMPLEMENT
                        });
                    }
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.scope.selectedOptions = sortByHierarchy ? selectedOptions.sort((x, y) => {
                return x.OFFER_CODE_EXHIBITION.charAt(x.OFFER_CODE_EXHIBITION.length - 1) < y.OFFER_CODE_EXHIBITION.charAt(y.OFFER_CODE_EXHIBITION.length - 1) || x.OFFER_CODE_EXHIBITION.substring(0, x.OFFER_CODE_EXHIBITION.length - 1) < y.OFFER_CODE_EXHIBITION.substring(0, y.OFFER_CODE_EXHIBITION.length - 1) ? -1 : 1;
            }) : selectedOptions;
        }
    }

    private updateOrdinationList(): void {
        const typeCargo = this.scope.model.OFFER_TAB ? this.scope.model.OFFER_TAB.TYPE_CARGO : null;
        if (!typeCargo) throw Error('type cargo is null');
        let contributionCode = "";
        if (typeCargo.ID == ECargoTypeId.FCL) contributionCode = "EGP_TOTAL_TEUS";
        else if (typeCargo.ID == ECargoTypeId.AIR) contributionCode = "EGP_TOTAL_WEIGHT";
        else if (typeCargo.ID == ECargoTypeId.ROAD) contributionCode = "EGP_TOTAL_VEHICLES";
        else if (typeCargo.ID == ECargoTypeId.BREAK_BULK || typeCargo.ID == ECargoTypeId.LCL || typeCargo.ID == ECargoTypeId.RO_RO) contributionCode = "EGP_TOTAL_FILE";
        this.scope.ordinationList = [{ ID: "1", NAME: "GP Estimado", CODE: "ESTIMATED_GROSS_PROFIT" }, { ID: "2", NAME: "Contribuição", CODE: contributionCode }, { ID: "3", NAME: "Total Pagamento", CODE: "TOTAL_PAYMENT" }, { ID: "4", NAME: "Total Recebimento", CODE: "TOTAL_RECEIVING" }];
    }

    private async getAndUpdateOptionList(): Promise<void> {
        try {
            this.scope.isSearchOfferOptionPaymentListCanceled = false;
            this.scope.optionRoutesError = null;
            this.scope.offerOptions = null;
            if (!this.scope.isSearchingOfferOptionPaymentList) this.scope.searchOfferOptionPaymentListCurrentAttempt = 0;
            this.scope.offerOptions = [];
            const resultOperation: IOfferOptionPaymentListResult = await this.restService.newObjectPromise(`${this.quotationUrl}/offerOptionPayment/list`, { id: this.scope.model._id }, 30000, false);
            if (resultOperation) {
                if (resultOperation.SHOW) {
                    const validRoutes: IOfferOptionPaymentRoute[] = [];
                    const invalidRoutes: IOfferOptionPaymentRoute[] = [];
                    if (resultOperation.ROUTES && resultOperation.ROUTES.length) {
                        for (const route of resultOperation.ROUTES) {
                            const validOfferOptions: IOfferOption[] = [];
                            const invalidOfferOptions: IOfferOption[] = [];
                            if (route.OFFER_OPTION && route.OFFER_OPTION.length) {
                                for (const offerOption of route.OFFER_OPTION) {
                                    if (offerOption.IS_VALID) validOfferOptions.push(offerOption);
                                    else invalidOfferOptions.push(offerOption);
                                }
                            }
                            validRoutes.push({ PROCESS_STATUS: route.PROCESS_STATUS, MOVE_TYPE: route.MOVE_TYPE, ORIGIN: route.ORIGIN, DESTINATION: route.DESTINATION, OFFER_OPTION: validOfferOptions });
                            invalidRoutes.push({ PROCESS_STATUS: route.PROCESS_STATUS, MOVE_TYPE: route.MOVE_TYPE, ORIGIN: route.ORIGIN, DESTINATION: route.DESTINATION, OFFER_OPTION: invalidOfferOptions });
                        }
                    }
                    this.scope.optionRoutesError = validRoutes && validRoutes.length > 0 ? validRoutes.filter(route => route.PROCESS_STATUS && route.PROCESS_STATUS.ERROR) : null;
                    this.scope.offerOptions = validRoutes && validRoutes.length ? this.buildOfferOptions(validRoutes.filter(route => !route.PROCESS_STATUS || (route.PROCESS_STATUS && !route.PROCESS_STATUS.ERROR))) : null;
                    this.scope.invalidOfferOptions = invalidRoutes && invalidRoutes.length ? this.buildInvalidOfferOptions(invalidRoutes.filter(route => !route.PROCESS_STATUS || (route.PROCESS_STATUS && !route.PROCESS_STATUS.ERROR))) : null;
                    this.scope.hasAnyOption = this.scope.offerOptions && this.scope.offerOptions.length > 0;
                    this.scope.isSearchingOfferOptionPaymentList = false;
                    this.scope.searchOfferOptionPaymentListCurrentAttempt = 0;
                    this.scope.$applyAsync();
                } else if (this.scope.searchOfferOptionPaymentListCurrentAttempt == this.scope.searchOfferOptionPaymentListMaxAttempt) {
                    this.scope.isSearchingOfferOptionPaymentList = false;
                    this.scope.$applyAsync();
                    return this.notifyError("Limit number of attempts has been reached, contact an administrator or try again.")
                } else {
                    this.scope.isSearchingOfferOptionPaymentList = true;
                    this.scope.searchOfferOptionPaymentListCurrentAttempt++;
                    this.scope.offerOptionPaymentListCount = 0;
                    this.scope.$applyAsync();
                    this.timeout(() => { this.initCountGetOptionList() }, 1000);
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async calculateOperationWM(): Promise<void> {
        let result: number = 0;
        try {
            this.block();
            if (this.scope.model.CARGO_TAB && (this.scope.model.CARGO_TAB.GROSS_WEIGHT || !isNaN(this.scope.model.CARGO_TAB.GROSS_WEIGHT) && (this.scope.model.CARGO_TAB.CUBIC_WEIGHT || !isNaN(this.scope.model.CARGO_TAB.CUBIC_WEIGHT)))) {
                const filter: IOfferRequestOperationWM = { GROSS_WEIGHT: this.scope.model.CARGO_TAB.GROSS_WEIGHT, CUBIC_WEIGHT: this.scope.model.CARGO_TAB.CUBIC_WEIGHT };
                const resultOperation = await this.restService.newObjectPromise(`${this.productUrl}/calculationOperations/wm`, filter, 30000, false);
                if (resultOperation && typeof resultOperation == 'number') result = resultOperation;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            if (this.scope.model.CARGO_TAB) this.scope.model.CARGO_TAB.LCL_AMOUNT = result && result > 1 ? result : 1;
            this.unblock();
        }
    }

    private async calculateOperationChargeable(keepWeightRange?: boolean): Promise<void> {
        let result: number = 1;
        try {
            this.block();
            if (this.scope.model.CARGO_TAB && this.scope.model.CARGO_TAB.CUBIC_WEIGHT) {
                const filter: IOfferRequestOperationChargeable = { CUBIC_WEIGHT: this.scope.model.CARGO_TAB.CUBIC_WEIGHT };
                const resultOperation = await this.restService.newObjectPromise(`${this.productUrl}/calculationOperations/chargeable`, filter, 30000, false);
                if (resultOperation && typeof resultOperation == 'number') result = resultOperation;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            if (this.scope.model.CARGO_TAB && this.scope.model.CARGO_TAB.AIR) {
                this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT = this.scope.model.CARGO_TAB.GROSS_WEIGHT && this.scope.model.CARGO_TAB.GROSS_WEIGHT > result ? OfferHelper.calculateAirChargeableWeight(this.scope.model.CARGO_TAB.GROSS_WEIGHT) : result;
                if (!keepWeightRange) this.updateWeightBreakBasedOverChargeableWeightOrGrossWeight([this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT > this.scope.model.CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT ? this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT : this.scope.model.CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT]);
                this.updateAirApplication();
            }
            this.unblock();
        }
    }

    private updateWeightRangeBase(): void {
        const self: OfferOldWizardModalRegisterController = this;
        if (this.scope.model.CARGO_TAB.AIR && this.scope.model.CARGO_TAB.AIR.WEIGHT_RANGE) {
            const weightRangeSelected: IWeightRangeSelector[] = this.scope.weightRangeFullList && this.scope.weightRangeFullList.length ? this.scope.weightRangeFullList.filter(weightRange => this.scope.model.CARGO_TAB.AIR.WEIGHT_RANGE.some(weightRangeToVerify => weightRangeToVerify.ID == weightRange.ID)) : null;
            // Pega o que for maior entre quantidade e sobretaxado para fazer a validação.
            const chargeableWeightToVerify = this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT && this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT > this.scope.model.CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT ? this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT : this.scope.model.CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT;
            const lowestRange = weightRangeSelected && weightRangeSelected.length ? weightRangeSelected.reduce(function (prev, current) {
                if (chargeableWeightToVerify && chargeableWeightToVerify > 1) {
                    const isBetweenChargeableWeight = OfferOldNewAlternativeHelper.betweenNumber(chargeableWeightToVerify, prev.MIN_WEIGHT, prev.MAX_WEIGHT);
                    return (prev.MIN_WEIGHT < current.MIN_WEIGHT && isBetweenChargeableWeight) ? prev : current;
                }
                return (prev.MIN_WEIGHT < current.MIN_WEIGHT) ? prev : current;
            }) : null;
            this.scope.model.CARGO_TAB.AIR.WEIGHT_RANGE_BASE = lowestRange;
            this.timeout(() => { this.scope.selectorValidity("cargoAirWeightRangeBase"); });
        }
    }

    private async getOfferWizardOriginDestination(): Promise<IRoute[]> {
        let result: IRoute[];
        try {
            const filters: IOfferWizardRequestOriginDestinationFilters = {
                ID_TARIFF_FREIGHT_CONTRACT_PAYMENT: this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_PAYMENT ? parseInt(this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_PAYMENT.ID) : null,
                ID_TARIFF_FREIGHT_CONTRACT_RECEIVING: this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_RECEIVING ? parseInt(this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_RECEIVING.ID) : null,
                ID_TARIFF_FREIGHT_PAYMENT: this.scope.model.OFFER_TAB.ID_TARIFF_FREIGHT_PAYMENT ? this.scope.model.OFFER_TAB.ID_TARIFF_FREIGHT_PAYMENT : null,
                ID_TARIFF_FREIGHT_RECEIVING: this.scope.model.OFFER_TAB.ID_TARIFF_FREIGHT_RECEIVING ? this.scope.model.OFFER_TAB.ID_TARIFF_FREIGHT_RECEIVING : null
            };
            const routeOriginDestinationList = await this.restService.newObjectPromise(`${this.productUrl}/offerOldWizard/originDestination`, filters, 30000, false);
            if (routeOriginDestinationList) result = routeOriginDestinationList.map(route => { return { MOVE_TYPE: route.MOVE_TYPE, ORIGIN: route.ORIGIN, DESTINATION: route.DESTINATION, SELECTED: true } });
        } catch (ex) {
            this.scope.modalOptions.ok(false);
            this.handleError(ex);
        } finally {
            return result;
        }
    }

    private async getOfferWizardBasicInfo(contractId: number, tariffId: number): Promise<IOfferWizardBasicInfo> {
        let result: IOfferWizardBasicInfo;
        this.block();
        try {
            if (contractId) {
                const filters: IOfferWizardRequestBasicInfoFilters = { ID_TARIFF_FREIGHT_CONTRACT: contractId, ID_TARIFF_FREIGHT: tariffId };
                const routeBasicInfoList = await this.restService.newObjectPromise(`${this.productUrl}/offerOldWizard/basic`, filters, 30000, false);
                if (routeBasicInfoList) result = { ID: routeBasicInfoList.ID, CONCATENATED: routeBasicInfoList.CONCATENATED, ACCOUNTS: routeBasicInfoList.ACCOUNTS, PAYMENT_NATURE: routeBasicInfoList.PAYMENT_NATURE, PRODUCT: routeBasicInfoList.PRODUCT, PROVIDER: routeBasicInfoList.PROVIDER, TYPE_CARGO: routeBasicInfoList.TYPE_CARGO, VALIDITY_START: routeBasicInfoList.VALIDITY_START };
            }
        } catch (ex) {
            this.scope.modalOptions.ok(false);
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private calculateVolumeTotal(): void {
        this.scope.model.CARGO_TAB.VOLUME = OfferHelper.calculateVolumeTotal(this.scope.model.CARGO_TAB.CARGO_LIST);
    }

    private calculateGrossWeightTotal(): void {
        this.scope.model.CARGO_TAB.GROSS_WEIGHT = OfferHelper.calculateGrossWeightTotal(this.scope.model.CARGO_TAB.CARGO_LIST);
    }

    private calculateCBWTotal(): void {
        this.scope.model.CARGO_TAB.CUBIC_WEIGHT = OfferHelper.calculateCBWTotal(this.scope.model.CARGO_TAB.CARGO_LIST);
    }

    private resetOfferTabModel = (): void => {
        if (this.scope.model && this.scope.model.OFFER_TAB) {
            this.scope.model.OFFER_TAB = {
                PRODUCT: null,
                TYPE_CARGO: null,
                LOAD_REF: this.getTodayOnlyDate(),
                ACCOUNT: null,
                EXPORTER: null,
                IMPORTER: null,
                NOTIFY: null,
                PROVIDER: null,
                INCOTERM: null,
                OFFER_TYPE: this.scope.offerTypeList.length > 0 ? this.scope.offerTypeList[0] : null,
                PROCESS_TYPE: this.scope.processTypeList.length > 0 ? this.scope.processTypeList[0] : null,
                CURRENCY: null,
                VALUE: null,
                ACCOUNT_REQUEST: null,
                TARIFF_FREIGHT_CONTRACT_PAYMENT: null,
                TARIFF_FREIGHT_CONTRACT_RECEIVING: null,
                ID_TARIFF_FREIGHT_PAYMENT: null,
                ID_TARIFF_FREIGHT_RECEIVING: null,
                COMMODITY: null,
                COMMODITY_SECTION: null,
                NEW_ALTERNATIVE: false,
                ID_OFFER: null
            }
        }
    }

    private resetRouteTabModel = (): void => {
        if (this.scope.model && this.scope.model.ROUTE_TAB) {
            this.scope.model.ROUTE_TAB = {
                ROUTES: null
            };
        }
    }

    private resetCargoTabModel = (): void => {
        if (this.scope.model && this.scope.model.CARGO_TAB) {
            const regularEquip = this.scope.equipSpecList && this.scope.equipSpecList.length ? this.scope.equipSpecList.find(obj => obj.ID == EEquipSpecId.REGULAR) : null;
            this.scope.model.CARGO_TAB = {
                BB_RORO: null,
                FCL: null,
                FTL: null,
                AIR: null,
                LCL_APPLICATION: null,
                LCL_AMOUNT: null,
                VOLUME: 0,
                GROSS_WEIGHT: 0,
                CUBIC_WEIGHT: 0,
                CARGO_LIST: null,
                STACKABLE: true,
                STANDARD_EQUIPMENT: regularEquip ? regularEquip : null,
                CARGO_SOC: false,
                EQUIPMENT_PROVIDER: null,
                ID_EQUIPMENT_PROVIDER: null,
                FLEXITANK: false
            };
        }
    }

    private resetIncoterm = (): void => {
        if (this.scope.model && this.scope.model.OFFER_TAB) {
            this.scope.model.OFFER_TAB.INCOTERM = null;
            this.timeout(() => { this.scope.selectorValidity("incoterm"); });
        }
    }

    private resetBuyingSellingRate = (): void => {
        if (this.scope.model && this.scope.model.OFFER_TAB) {
            this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_PAYMENT = null;
            this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_RECEIVING = null;
        }
    }

    private addRoute(): void {
        try {
            if (!this.scope.model.ROUTE_TAB.ROUTES) this.scope.model.ROUTE_TAB.ROUTES = [];
            const route: IRoute = {
                SELECTED: true,
                MOVE_TYPE: null,
                ORIGIN: null,
                DESTINATION: null
            }
            this.scope.model.ROUTE_TAB.ROUTES.push(route);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async removeRoute(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: `${this.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")}`
            });
            if (!modal) return;

            if (this.scope.model.ROUTE_TAB.ROUTES && this.scope.model.ROUTE_TAB.ROUTES.length > 0) {
                this.block();
                this.scope.model.ROUTE_TAB.ROUTES.splice(index, 1);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async copyRoute(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');

            if (this.scope.model.ROUTE_TAB && this.scope.model.ROUTE_TAB.ROUTES && this.scope.model.ROUTE_TAB.ROUTES.length > 0 && this.scope.model.ROUTE_TAB.ROUTES[index]) {
                this.block();
                const copy = angular.copy(this.scope.model.ROUTE_TAB.ROUTES[index]);
                this.scope.model.ROUTE_TAB.ROUTES.push(copy);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private addCargoBBRORO(): void {
        try {
            if (!this.scope.model.CARGO_TAB.BB_RORO) this.scope.model.CARGO_TAB.BB_RORO = [];
            const ftl: IBBROROCargo = {
                ORIGINAL: null,
                EQUIPMENT: null,
                AMOUNT: 1
            }
            this.scope.model.CARGO_TAB.BB_RORO.push(ftl);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async removeCargoBBRORO(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: this.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")
            });
            if (!modal) return;

            if (this.scope.model.CARGO_TAB.BB_RORO && this.scope.model.CARGO_TAB.BB_RORO.length > 0) {
                this.block();
                this.scope.model.CARGO_TAB.BB_RORO.splice(index, 1);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private addCargoFCL(): void {
        try {
            if (!this.scope.model.CARGO_TAB.FCL) this.scope.model.CARGO_TAB.FCL = [];
            const fcl: IFCLCargo = {
                ORIGINAL: null,
                EQUIPMENT: null,
                AMOUNT: 1
            }
            this.scope.model.CARGO_TAB.FCL.push(fcl);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async removeCargoFCL(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: this.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")
            });
            if (!modal) return;

            if (this.scope.model.CARGO_TAB.FCL && this.scope.model.CARGO_TAB.FCL.length > 0) {
                this.block();
                this.scope.model.CARGO_TAB.FCL.splice(index, 1);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private addCargoFTL(): void {
        try {
            if (!this.scope.model.CARGO_TAB.FTL) this.scope.model.CARGO_TAB.FTL = [];
            const ftl: IFTLCargo = {
                VEHICLE_TYPE: null,
                AMOUNT: 1,
                FREE_TIME_PUP_MASTER: null,
                FREE_TIME_PUP_HOUSE: null,
                ORIGIN_FREE_TIME_CUSTOMS_CLEARANCE_MASTER: null,
                ORIGIN_FREE_TIME_CUSTOMS_CLEARANCE_HOUSE: null,
                ORIGIN_FREE_TIME_BORDER_CROSS_MASTER: null,
                ORIGIN_FREE_TIME_BORDER_CROSS_HOUSE: null,
                DESTINATION_FREE_TIME_CUSTOMS_CLEARANCE_MASTER: null,
                DESTINATION_FREE_TIME_CUSTOMS_CLEARANCE_HOUSE: null,
                DESTINATION_FREE_TIME_BORDER_CROSS_MASTER: null,
                DESTINATION_FREE_TIME_BORDER_CROSS_HOUSE: null,
                FREE_TIME_DELIVERY_MASTER: null,
                FREE_TIME_DELIVERY_HOUSE: null,
            }
            this.scope.model.CARGO_TAB.FTL.push(ftl);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async removeCargoFTL(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: this.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")
            });
            if (!modal) return;

            if (this.scope.model.CARGO_TAB.FTL && this.scope.model.CARGO_TAB.FTL.length > 0) {
                this.block();
                this.scope.model.CARGO_TAB.FTL.splice(index, 1);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private updateCargoCalculatedFields() {
        if (this.scope.model.CARGO_TAB) {
            if (this.scope.model.CARGO_TAB.CARGO_LIST && this.scope.model.CARGO_TAB.CARGO_LIST.length > 0) {
                this.scope.isCargoCalculatedFieldsDisabled = true;
            } else {
                this.scope.isCargoCalculatedFieldsDisabled = false;
            }
        }
    }

    private addCargo(): void {
        try {
            if (!this.scope.model.CARGO_TAB.CARGO_LIST) this.scope.model.CARGO_TAB.CARGO_LIST = [];
            const cargo: ICargo = {
                PACKAGE: null,
                VOLUME: null,
                LENGTH: null,
                WIDTH: null,
                HEIGHT: null,
                CUBIC_WEIGHT: null,
                GROSS_WEIGHT_UNITARY: null,
                GROSS_WEIGHT: null,
                UUID: null
            }
            this.scope.model.CARGO_TAB.CARGO_LIST.push(cargo);
            this.updateCargoCalculatedFields();
            const lastIndex = this.scope.model.CARGO_TAB.CARGO_LIST.length - 1;
            this.timeout(() => {
                this.scope.selectorValidity("cargoPackage" + lastIndex);
                this.scope.setDirtyFields(['cargoVolume' + lastIndex, 'cargoLength' + lastIndex, 'cargoWidth' + lastIndex, 'cargoHeight' + lastIndex, 'cargoCBW' + lastIndex, 'cargoGrossWeight' + lastIndex]);
            }, 100);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async removeCargo(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: this.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")
            });
            if (!modal) return;

            if (this.scope.model.CARGO_TAB.CARGO_LIST && this.scope.model.CARGO_TAB.CARGO_LIST.length > 0) {
                this.block();
                this.scope.model.CARGO_TAB.CARGO_LIST.splice(index, 1);
                this.calculateVolumeTotal();
                this.calculateGrossWeightTotal();
                this.calculateCBWTotal();
                if (this.scope.model.CARGO_TAB.AIR) this.calculateOperationChargeable();
                if (this.scope.model.OFFER_TAB && this.scope.model.OFFER_TAB.TYPE_CARGO && (this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.BREAK_BULK || this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.LCL || this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.RO_RO)) this.calculateOperationWM();
                this.updateCargoCalculatedFields();
                this.updateAirApplication();
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private addDangerousCargo(): void {
        try {
            if (!this.scope.model.SPEC_TAB.DANGEROUS_CARGO) this.scope.model.SPEC_TAB.DANGEROUS_CARGO = [];
            const dangerousCargo: IDangerousCargo = {
                UNITY: null,
                CLASS: null,
                PACKING_GROUP: null
            }
            this.scope.model.SPEC_TAB.DANGEROUS_CARGO.push(dangerousCargo);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async removeDangerousCargo(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: this.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")
            });
            if (!modal) return;

            if (this.scope.model.SPEC_TAB.DANGEROUS_CARGO && this.scope.model.SPEC_TAB.DANGEROUS_CARGO.length > 0) {
                this.block();
                this.scope.model.SPEC_TAB.DANGEROUS_CARGO.splice(index, 1);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async launchCargoPolLb(): Promise<void> {
        try {

            if (!this.modalCargoPolLbId) this.modalCargoPolLbId = this.modalService.newModal();

            const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                {
                    modalID: this.modalCargoPolLbId,
                    formService: 'register',
                    template: require("../view/modals/offerCargoPollbModal.html"),
                    size: 'full modal-overflow'
                },
                {
                    closeButtonText: 'GENERAL.CLOSE',
                    actionButtonText: 'REGISTRATION.APPLY',
                    headerText: `GENERAL.CARGO_DETAILS`
                }
            );

            const cargoListConverted: ICargo[] = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (cargoListConverted && this.scope.model.CARGO_TAB) {
                this.scope.model.CARGO_TAB.CARGO_LIST = this.scope.model.CARGO_TAB.CARGO_LIST ? this.scope.model.CARGO_TAB.CARGO_LIST.concat(cargoListConverted) : cargoListConverted;
                if (this.scope.model.CARGO_TAB.CARGO_LIST && this.scope.model.CARGO_TAB.CARGO_LIST.length > 0) {
                    angular.element('#collapseCargoList')["collapse"]("show");
                    this.timeout(() => {
                        this.loadRegisterForm(false);
                        this.calculateVolumeTotal();
                        this.scope.model.CARGO_TAB.GROSS_WEIGHT = OfferHelper.calculateGrossWeightTotal(this.scope.model.CARGO_TAB.CARGO_LIST);
                        this.scope.model.CARGO_TAB.CUBIC_WEIGHT = OfferHelper.calculateCBWTotal(this.scope.model.CARGO_TAB.CARGO_LIST);
                        if (this.scope.model.CARGO_TAB.AIR) this.calculateOperationChargeable();
                        if (this.scope.model.OFFER_TAB.TYPE_CARGO && (this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.BREAK_BULK || this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.LCL || this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.RO_RO)) this.calculateOperationWM();
                        this.updateAirApplication();
                        this.scope.cargoListGrossWeightFieldsControl(this.scope.model.CARGO_TAB.GROSS_WEIGHT);
                    });
                }
            }
            this.updateCargoCalculatedFields();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getAccountListByName(search?: string): Promise<SelectorModel[]> {
        let result = [];
        try {
            this.block();
            const accounties = await this.restService.newObjectPromise(`${this.productUrl}/account/list/custom`, { search: search }, 30000, false);
            if (accounties) result = accounties.map(account => { return { ID: account.ID, NAME: account.NAME, CODE: account.LEGAL_PERSON ? account.LEGAL_PERSON.ID_FISCAL : null } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getLegalPersonListByName(filter: ILegalPersonListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        try {
            this.block();
            const legalPersons = await this.restService.newObjectPromise(`${this.getUrlProduct()}/legalPerson/list/custom`, filter, 30000, false);
            if (legalPersons) result = legalPersons.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async updateWeightRangeList(filter: IWeightRangeListCustomFilter): Promise<void> {
        let result: IWeightRangeSelector[] = [];
        try {
            const weightRangeList = await this.restService.newObjectPromise(`${this.getUrlProduct()}/weightRange/list/custom`, filter, 30000, false);
            if (weightRangeList) result = weightRangeList.map(x => { return { ID: x.ID, NAME: x.NAME, MIN_WEIGHT: x.MIN_WEIGHT, MAX_WEIGHT: x.MAX_WEIGHT } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.scope.weightRangeList = result ? result.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE } }) : [];
            this.scope.weightRangeFullList = result;
            this.scope.$applyAsync();
        }
    }

    private async getProviderListByName(search?: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.block();
            const providers = await this.restService.newObjectPromise(`${this.getUrlProduct()}/provider/list/custom`, { search: search }, 30000, false);
            if (providers && providers.length > 0) result = providers.map(provider => { return { ID: provider.ID, NAME: provider.NAME, CODE: provider.SCAC_IATA } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getIncotermListByName(search: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            const products = this.scope.model.OFFER_TAB.PRODUCT ? [this.scope.model.OFFER_TAB.PRODUCT.ID] : [];
            const incoterms = await this.restService.newObjectPromise(`${this.getUrlProduct()}/incoterm/list/custom`, { search, products }, 30000, false);
            if (incoterms && incoterms.length > 0) result = incoterms.map(incoterm => { return { ID: incoterm.ID, NAME: incoterm.NAME, CODE: incoterm.INITIALS } });

        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getCommodityListByName(search?: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        let commodities = null;
        try {
            if (search && search.length >= 2) {
                this.block();
                if (!this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_PAYMENT && !this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_RECEIVING) {
                    commodities = await this.restService.newObjectPromise(`${this.getUrlProduct()}/commodity/list/custom/`, { name: search }, 30000, false);
                } else {
                    commodities = await this.restService.newObjectPromise(`${this.productUrl}/tariffFreight/list/commodity`, { search, ID_TARIFF_FREIGHT_CONTRACT: this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_PAYMENT ? this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_PAYMENT.ID : null, ID_TARIFF_FREIGHT_CONTRACT_RECEIVING: this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_RECEIVING ? this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_RECEIVING.ID : null }, 30000, false);
                }
                if (commodities && commodities.length > 0) result = commodities.map(commodity => { return { ID: commodity.ID, NAME: commodity.NAME, HS_CODE: commodity.HS_CODE } });
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getRoutingPointListByName(name: string, types: string[]): Promise<IRoutingPointSelector[]> {
        let result: IRoutingPointSelector[] = [];
        try {
            this.block();
            const routingPoints: IRoutingPointSelector[] = await this.restService.newObjectPromise(`${this.getUrlProduct()}/routingPoint/list/custom/`, { name, types }, 30000, false);
            if (routingPoints && routingPoints.length > 0) {
                result = routingPoints.map(routingPoint => {
                    return {
                        ID: routingPoint.ID,
                        NAME: routingPoint.NAME,
                        CODE: routingPoint.CODE,
                        DISPLAY_NAME: routingPoint.DISPLAY_NAME,
                        COUNTRY: routingPoint.COUNTRY
                    }
                });
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getCurrencyListByName(search?: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            const currencies = await this.restService.newObjectPromise(`${this.productUrl}/currency/list/custom`, { name: search }, 30000, false);
            if (currencies) result = currencies.map(currency => { return { ID: currency.ID, NAME: currency.NAME, CODE: currency.INITIALS } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getEquipmentListByNameOrCode(search?: string): Promise<IEquipmentSelector[]> {
        let result: IEquipmentSelector[] = [];
        this.block();
        try {
            const equipments = await this.restService.newObjectPromise(`${this.productUrl}/equipment/list/custom`, { search: search, products: [] }, 30000, false);
            if (equipments) result = equipments.map(equipment => { return { ID: equipment.ID, NAME: equipment.NAME, CODE: equipment.CODE, TEU: equipment.TEU, FEET: equipment.FEET } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getAgentListByName(filter: IAgentListCustomFilter): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.block();
            const agents = await this.restService.newObjectPromise(`${this.getUrlProduct()}/agent/list/custom`, filter, 30000, false);
            result = agents ? agents.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.NETWORK_ID, LEGAL_PERSON_ID: x.LEGAL_PERSON_ID } }) : [];
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getBrokerListByName(brokerFilter?: IBrokerListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        this.block();
        try {
            const brokers = await this.restService.newObjectPromise(`${this.getUrlProduct()}/broker/list/custom`, brokerFilter, 30000, false);
            if (brokers) result = brokers.map(broker => { return { ID: broker.ID, NAME: broker.NAME } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getApplicationListByName(product: SelectorModel[], typeCargo: SelectorModel[], search: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        let products = [];
        let typeCargos = [];
        try {
            if (search && search.length >= 2) {
                if (!product) throw Error(this.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                if (!typeCargo) throw Error('Select type cargo first!');
                this.block();
                products = product.map(item => item.ID);
                typeCargos = typeCargo.map(item => item.ID);
                const application = await this.restService.newObjectPromise(`${this.getUrlProduct()}/application/list/custom`, { search, products, typeCargos }, 10000, false);
                if (application) {
                    result = application.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PERCENT: x.PERCENT, APPLICATION_COMPLEMENT: x.APPLICATION_COMPLEMENT, INTERNAL_SEQUENCE: x.INTERNAL_SEQUENCE, FREE_TYPING_AMOUNT_CHARGE: x.FREE_TYPING_AMOUNT_CHARGE, CT_WITHOUT_DOC: x.CT_WITHOUT_DOC, NAME_INTL: x.NAME_INTL } });
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getChargeNameListByName(filter: IChargeNameListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        this.block();
        try {
            const charges = await this.restService.newObjectPromise(`${this.getUrlProduct()}/chargeName/list/custom/exhibition`, filter, 30000, false);
            if (charges) result = charges.map(charge => { return { ID: charge.ID, NAME: charge.NAME, CODE: charge.CODE } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getApplicationListByChargeNameId(optionChargeName: SelectorModel): Promise<void> {
        try {
            this.block();
            if (!optionChargeName) return this.notifyError("optionChargeName is null");
            if (optionChargeName) {
                const application = await this.restService.getObjectAsPromise(`${this.getUrlProduct()}/application/list/custom/charge_name/${optionChargeName.ID}`, 10000, null, false);
                if (application && application.data && application.data.CHARGE_NAME.PARAMS) {
                    const paymentApplicationList = application.data.CHARGE_NAME.PARAMS.map(x => {
                        return {
                            ID: x.PAYMENT.ID,
                            NAME: x.PAYMENT.NAME,
                            CODE: x.PAYMENT.CODE,
                            PERCENT: x.PAYMENT.PERCENT,
                            APPLICATION_COMPLEMENT: x.PAYMENT.APPLICATION_COMPLEMENT,
                            INTERNAL_SEQUENCE: x.PAYMENT.INTERNAL_SEQUENCE,
                            FREE_TYPING_AMOUNT_CHARGE: x.PAYMENT.FREE_TYPING_AMOUNT_CHARGE,
                            CT_WITHOUT_DOC: x.PAYMENT.CT_WITHOUT_DOC,
                            NAME_INTL: x.PAYMENT.NAME_INTL
                        }
                    });
                    const receivingApplicationList = application.data.CHARGE_NAME.PARAMS.map(x => {
                        return {
                            ID: x.RECEIVING.ID,
                            NAME: x.RECEIVING.NAME,
                            CODE: x.RECEIVING.CODE,
                            PERCENT: x.RECEIVING.PERCENT,
                            APPLICATION_COMPLEMENT: x.RECEIVING.APPLICATION_COMPLEMENT,
                            INTERNAL_SEQUENCE: x.RECEIVING.INTERNAL_SEQUENCE,
                            FREE_TYPING_AMOUNT_CHARGE: x.RECEIVING.FREE_TYPING_AMOUNT_CHARGE,
                            CT_WITHOUT_DOC: x.RECEIVING.CT_WITHOUT_DOC,
                            NAME_INTL: x.RECEIVING.NAME_INTL
                        }
                    });
                    if (paymentApplicationList && paymentApplicationList.length > 0) this.scope.model.SPEC_TAB.SECURE.APPLICATION_PAYMENT = paymentApplicationList[0];
                    if (receivingApplicationList && receivingApplicationList.length > 0) this.scope.model.SPEC_TAB.SECURE.APPLICATION_RECEIVING = receivingApplicationList[0];
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getOfferEquipmentList(): Promise<IOfferEquipment[]> {
        let result: IOfferEquipment[] = [];
        this.block();
        try {
            const filters: IOfferWizardRequestEquipmentFilters = { ID_TARIFF_FREIGHT_CONTRACT_PAYMENT: this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_PAYMENT ? parseInt(this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_PAYMENT.ID) : null, ID_TARIFF_FREIGHT_CONTRACT_RECEIVING: this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_RECEIVING ? parseInt(this.scope.model.OFFER_TAB.TARIFF_FREIGHT_CONTRACT_RECEIVING.ID) : null, ID_TARIFF_FREIGHT_PAYMENT: this.scope.model.OFFER_TAB.ID_TARIFF_FREIGHT_PAYMENT ? this.scope.model.OFFER_TAB.ID_TARIFF_FREIGHT_PAYMENT : null, ID_TARIFF_FREIGHT_RECEIVING: this.scope.model.OFFER_TAB.ID_TARIFF_FREIGHT_RECEIVING ? this.scope.model.OFFER_TAB.ID_TARIFF_FREIGHT_RECEIVING : null, TYPE_CARGO: this.scope.model.OFFER_TAB.TYPE_CARGO ? this.scope.model.OFFER_TAB.TYPE_CARGO.ID : null };
            const equipments = await this.restService.newObjectPromise(`${this.productUrl}/offerOldWizard/equipment`, filters, 30000, false);
            if (equipments) result = equipments.map(equipment => { return { AMOUNT: equipment.AMOUNT, EQUIPMENT: equipment.EQUIPMENT } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getTariffFreightCustomOfferListByName(filter: ITariffFreightOfferListCustomFilter): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.block();
            const tariffContracts = await this.restService.newObjectPromise(`${this.getUrlProduct()}/tariffFreight/list/custom/offer`, filter, 30000, false);
            if (tariffContracts && tariffContracts.length > 0) result = tariffContracts.map(tariffContract => { return { ID: tariffContract.ID, NAME: tariffContract.CONCATENATED, CODE: tariffContract.CONTRACT } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getRecipientListByName(filter: IRecipientListCustomFilter): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.block();
            const recipients = await this.restService.newObjectPromise(`${this.getUrlProduct()}/user/list/custom`, filter, 30000, false);
            if (recipients && recipients.length > 0) result = recipients.map(recipient => { return { ID: recipient.ID, NAME: recipient.displayName, CODE: recipient.email } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private updateCargoCalculateFieldsDisabledControl(amount: number) {
        this.scope.isCargoCalculatedFieldsDisabled = amount && amount > 0 && amount != 1;
    }

    private calculateAirHigherAmount(): void {
        this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT_VALID = this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT > this.scope.model.CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT ? this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT : this.scope.model.CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT;
    }

    private cancelOfferOption() {
        this.scope.isSearchOfferOptionPaymentListCanceled = true;
        this.scope.isSearchingOfferOptionPaymentList = false;
        this.scope.searchOfferOptionPaymentListCurrentAttempt = 0;
    }

    private routingPointRouteFilter = (index: number, field: string): Function => {
        const fieldValue = field == 'origin' ? this.scope.model.ROUTE_TAB.ROUTES[index].DESTINATION : this.scope.model.ROUTE_TAB.ROUTES[index].ORIGIN;
        return function (routingPoint: SelectorModel) {
            return !fieldValue || (fieldValue && fieldValue.ID != routingPoint.ID);
        }
    }

    private buildOfferOptionErrorTooltip(errors: string[]): string {
        let tooltip = "";
        if (errors && errors.length) {
            for (const reason of errors) {
                if (tooltip) tooltip += "<br>";
                tooltip += reason;
            }
        }
        return tooltip;
    }

    private async showGenerateOpportunityModal() {
        this.scope.uploader = this.getFileUploaderDefault();
        this.scope.uploader.onSuccessItem = (item: IFileItem, response: ISuccessItemResponse): void => {
            this.onSuccessItemOpportunity(item, response);
        }
        this.modalOpportunityId = this.modalService.newModal();
        this.modalService.showModalInfo(
            {
                modalID: this.modalOpportunityId,
                scope: this.scope,
                formService: 'register',
                template: require('../view/modals/offerWizardOpportunityModal.html'),
                size: 'lg',
                events: async (event: angular.IAngularEvent) => {
                    if (event.name == "modal.closing") this.scope.uploader.destroy();
                }
            },
            {
                actionButtonText: "PRODUCT.CREATE_PRE_OFFER",
                closeButtonText: "GENERAL.CANCEL",
                headerText: "GENERAL.MENU.PRE_OFFER"
            }
        );
        const modalScope = await this.buildGenerateOpportunityModalScope();

        this.timeout(() => {
            modalScope.selectorValidity("opportunitySituation");
        });
    }

    private async buildGenerateOpportunityModalScope(): Promise<IGenerateOpportunityModalScope> {
        const modalScope: IGenerateOpportunityModalScope = await this.modalService.getModalScope(this.modalOpportunityId);

        modalScope.model = {
            SITUATION: this.scope.offerOpportunitySituationList && this.scope.offerOpportunitySituationList.length ? this.scope.offerOpportunitySituationList.find(situation => situation.ID == EOfferOpportunitySituation.AWAITING_ACTION) : null,
            RECIPIENT: null,
            REASON: null,
            MESSAGE: null,
            FILES: null,
            FILES_NEW: null
        };

        modalScope.getRecipientListByName = async (search: string) => {
            let recipientList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                recipientList = await this.getRecipientListByName({ search: search, groups: null, roles: null });
            }
            modalScope.offerOpportunityRecipientList = recipientList;
        }

        modalScope.generateOpportunity = () => {
            this.generateOpportunity(modalScope.model);
        };

        modalScope.removeUpload = (model: IUploadItem): Promise<boolean> => {
            return this.removeOpportunityUpload(model);
        }

        return modalScope;
    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'offer_opportunity_files', formName: "offerWizardModal", folder: "offerWizardModal" };
            return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData] });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async removeOpportunityUpload(model: IUploadItem): Promise<boolean> {
        const modalScope: IGenerateOpportunityModalScope = await this.modalService.getModalScope(this.modalOpportunityId);
        let result = false;
        try {
            if (!model) throw Error('item parameter is null');

            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: this.getTranslate("GENERAL.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_ATTACHMENT")
            });
            if (!modal) return;


            if (modalScope.model.FILES_NEW && modalScope.model.FILES_NEW.length > 0) {
                const index = modalScope.model.FILES_NEW.findIndex(file => { return file.FILE_HASH == model.FILE_HASH });
                if (index > -1) {
                    modalScope.model.FILES_NEW.splice(index, 1);
                    result = true;
                }
            }
        } catch (ex) {
            result = false;
            this.handleError(ex);
        } finally {
            return result;
        }
    }

    private async onSuccessItemOpportunity(item: IFileItem, response: ISuccessItemResponse): Promise<void> {
        try {
            const modalScope: IGenerateOpportunityModalScope = await this.modalService.getModalScope(this.modalOpportunityId);
            if (!item) throw Error('item parameter is null');
            if (!response) throw Error('response parameter is null');

            if (response.data && response.data.length > 0) {
                if (!modalScope.model.FILES_NEW) modalScope.model.FILES_NEW = [];

                for (let item of response.data) {
                    const file: IUploadItem = {
                        FILE_DISPLAY_NAME: item.displayFileName,
                        FILE_NAME: item.fileName,
                        FILE_TYPE: item.fileType,
                        FILE_PATH: item.filePath,
                        FILE_URL: item.fileUrl,
                        FILE_HASH: item.fileHash,
                        FILE_SIZE: item.fileSize,
                        FORM_NAME: item.formName
                    }
                    modalScope.model.FILES_NEW.push(file)
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async generateOpportunity(opportunityModel: IGenerateOpportunityModel): Promise<void> {
        try {
            const hasInvalid = await this.hasInvalidRequiredElements('offerWizardOpportunityForm');
            if (hasInvalid) return;
            const requestData = { idOfferWizardFilter: this.scope.model._id, situation: opportunityModel.SITUATION, recipient: opportunityModel.RECIPIENT, reasons: opportunityModel.REASON, message: opportunityModel.MESSAGE, files: opportunityModel.FILES_NEW && opportunityModel.FILES_NEW.length ? { FILES_NEW: opportunityModel.FILES_NEW } : null };
            const generateOpportunity = await this.restService.newObjectPromise(`${this.productUrl}/opportunity/generate`, requestData, 30000, false);
            if (generateOpportunity) this.notifySuccess("Successfully generated opportunity.");
            else return this.notifyError("An error occurred while generating the opportunity.");
            this.modalService.closeModal(this.modalOpportunityId, true);
            this.modalOpportunityId = 0;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async reprocessOfferOption() {
        await this.createOfferWizardFilter(true);
        this.initOptionTab();
    }

    private updateWeightBreakBasedOverChargeableWeightOrGrossWeight(amounts: number[]): void {
        const weightRangeList = [];
        try {
            if (amounts && amounts.length > 0 && this.scope.weightRangeFullList && this.scope.weightRangeFullList.length > 0) {
                for (let i = 0; i < this.scope.weightRangeFullList.length; i++) {
                    const weightRange = this.scope.weightRangeFullList[i];
                    if (weightRange) {
                        for (const amount of amounts) {
                            if (amount != null && !weightRangeList.some(weightRangeObj => weightRangeObj.ID == weightRange.ID) && OfferOldNewAlternativeHelper.betweenNumber(amount, weightRange.MIN_WEIGHT, weightRange.MAX_WEIGHT)) {
                                weightRangeList.push({ ID: weightRange.ID, NAME: weightRange.NAME, CODE: weightRange.CODE });
                                break;
                            }
                        }
                    }
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.scope.model.CARGO_TAB.AIR.WEIGHT_RANGE = weightRangeList;
            this.updateWeightRangeBase();
        }
    }

    private async updateAirApplication(): Promise<void> {
        if (this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.AIR) {
            const chargeableWeight = this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT;
            const overChargeableWeight = this.scope.model.CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT;
            const taxedWeightInternalSequence = overChargeableWeight && overChargeableWeight > chargeableWeight ? "019" : "003";
            if (this.scope.model.CARGO_TAB.AIR.APPLICATION && this.scope.model.CARGO_TAB.AIR.APPLICATION.INTERNAL_SEQUENCE !== taxedWeightInternalSequence) {
                const application = await this.restService.newObjectPromise(`${this.getUrlProduct()}/application/list/custom`, { internalSequence: taxedWeightInternalSequence }, 15000, false);
                let result: IApplicationList = null;
                if (application && application.length > 0) result = { ID: application[0].ID, NAME: application[0].NAME, CODE: application[0].CODE, PERCENT: application[0].PERCENT, APPLICATION_COMPLEMENT: application[0].APPLICATION_COMPLEMENT, INTERNAL_SEQUENCE: application[0].INTERNAL_SEQUENCE, FREE_TYPING_AMOUNT_CHARGE: application[0].FREE_TYPING_AMOUNT_CHARGE, CT_WITHOUT_DOC: application[0].CT_WITHOUT_DOC, NAME_INTL: application[0].NAME_INTL };
                if (!result) this.notifyError(this.getTranslate('GENERAL.MESSAGES.NOT_POSSIBLE_TO_FIND_CHARGEABLE_WEIGHT_IN_APPLICATION'));
                this.scope.model.CARGO_TAB.AIR.APPLICATION = result;
                this.scope.$applyAsync();
            }
        }
    }

    private buildOfferOptions(optionRoutes: IOfferOptionPaymentRoute[]): IOfferOptionAux[] {
        const offerOptions: IOfferOptionAux[] = [];
        try {
            if (optionRoutes && optionRoutes.length > 0) {
                for (let routeIndex = 0; routeIndex < optionRoutes.length; routeIndex++) {
                    const optionRoute = optionRoutes[routeIndex];
                    if (optionRoute && optionRoute.OFFER_OPTION && optionRoute.OFFER_OPTION.length > 0) {
                        for (let offerIndex = 0; offerIndex < optionRoute.OFFER_OPTION.length; offerIndex++) {
                            const offerOption = optionRoute.OFFER_OPTION[offerIndex];
                            const newOfferOption: IOfferOptionAux = angular.copy(offerOption);
                            const routes: IOfferOptionRoutingPoint[] = this.getOfferOptionRoutes(offerOption);
                            if (routeIndex == 0 && offerIndex == 0) this.OfferWizardFilterId = offerOption.ID_OFFER_WIZARD_FILTER;
                            newOfferOption.SELECTED = true;
                            newOfferOption.SHOW_DETAILS = false;
                            newOfferOption.TOOLTIP = this.buildTooltipRoutes(routes);
                            newOfferOption.MOVE_TYPE = optionRoute.MOVE_TYPE;
                            newOfferOption.ORIGIN = optionRoute.ORIGIN;
                            newOfferOption.DESTINATION = optionRoute.DESTINATION;
                            newOfferOption.PROCESS_STATUS = optionRoute.PROCESS_STATUS;
                            if (this.scope.selectedOptions)
                                if (!this.scope.selectedOptions.find(selected => selected._id == newOfferOption._id))
                                    newOfferOption.SELECTED = false;
                            offerOptions.push(newOfferOption);
                        }
                    }
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            return offerOptions;
        }
    }

    private buildInvalidOfferOptions(optionRoutes: IOfferOptionPaymentRoute[]): IOfferOptionInvalid[] {
        const offerOptions: IOfferOptionInvalid[] = [];
        try {
            if (optionRoutes && optionRoutes.length > 0) {
                for (let routeIndex = 0; routeIndex < optionRoutes.length; routeIndex++) {
                    const optionRoute = optionRoutes[routeIndex];
                    if (optionRoute && optionRoute.OFFER_OPTION && optionRoute.OFFER_OPTION.length > 0) {
                        for (let offerIndex = 0; offerIndex < optionRoute.OFFER_OPTION.length; offerIndex++) {
                            const offerOption = optionRoute.OFFER_OPTION[offerIndex];
                            offerOptions.push({
                                SHOW: false,
                                MOVE_TYPE: optionRoute.MOVE_TYPE,
                                PRODUCT: offerOption.PRODUCT,
                                PROVIDER: offerOption.PROVIDER,
                                ORIGIN: optionRoute.ORIGIN,
                                DESTINATION: optionRoute.DESTINATION,
                                ID_TARIFF_FREIGHT: offerOption.ID_TARIFF_FREIGHT,
                                ID_TARIFF_FREIGHT_CONTRACT: offerOption.ID_TARIFF_FREIGHT_CONTRACT,
                                TARIFF_FREIGHT_CONCATENATED: offerOption.TARIFF_FREIGHT_CONCATENATED,
                                VALIDATION_RESULT: offerOption.VALIDATION_RESULT,
                                CHARGES: null
                            });
                        }
                    }
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            return offerOptions;
        }
    }

    private buildTooltipRoutes(routes: IOfferOptionRoutingPoint[]): string {
        try {
            let tooltip = "";
            if (routes && routes.length > 0) {
                for (let routeIndex = 0; routeIndex < routes.length; routeIndex++) {
                    const route = routes[routeIndex];
                    if (routeIndex == 0) {
                        tooltip += route.NAME;
                    }
                    else if (((routeIndex + 1) == routes.length)) {
                        tooltip += " - " + route.NAME;
                    } else tooltip += " - " + route.NAME;
                }
            }
            return tooltip;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private buildRoutesHtml(offerOption: IOfferOption): string {
        try {
            const routes: IOfferOptionRoutingPoint[] = this.getOfferOptionRoutes(offerOption);
            let routesHtml = "";
            const routesSeparator = "<label class='cursor-hand text-strong text-dark padding-xs-horizontal v-middle'>-</label>";
            for (let index = 0; index < routes.length; index++) {
                const route = routes[index];
                if (index == 0) {
                    routesHtml += `<label class="cursor-hand text-strong text-dark" style="vertical-align: sub;">${route.CODE}</label>`
                    if (route.COUNTRY && route.COUNTRY.COUNTRY_IMG) routesHtml += `<span style="margin-left: 2px;"><i class="flag-icon monaco-flag ${route.COUNTRY.COUNTRY_IMG.CODE}"></i></span>`;
                }
                else if (((index + 1) == routes.length)) {
                    routesHtml += `${routesSeparator} <label class="cursor-hand text-strong text-dark" style="vertical-align: sub;">${route.CODE}</label>`;
                    if (route.COUNTRY && route.COUNTRY.COUNTRY_IMG) routesHtml += `<span style="margin-left: 2px;"><i class="flag-icon monaco-flag ${route.COUNTRY.COUNTRY_IMG.CODE}"></i></span>`;
                }
            }
            return this.sce.trustAsHtml(routesHtml);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private buildOfferOptionInconsistencyHtml(validationResult: IOfferOptionValidationResult): string {
        try {
            const validationMessages: string[] = [];
            if (!this.scope.offerOptionInconsistencyList || this.scope.offerOptionInconsistencyList.length == 0) validationMessages.push("Offer option inconsistency not found.");
            else {
                if (!validationResult.FILTERS_BASE) {
                    const filtersBaseGeneric = this.scope.offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.FILTERS_BASE);
                    if (filtersBaseGeneric) validationMessages.push(`${filtersBaseGeneric.CODE} - ${filtersBaseGeneric.NAME}`);
                }
                if (!validationResult.TYPE_CARGO) {
                    const typeCargoGeneric = this.scope.offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.TYPE_CARGO);
                    if (typeCargoGeneric) validationMessages.push(`${typeCargoGeneric.CODE} - ${typeCargoGeneric.NAME}`);
                }
                if (!validationResult.TARIFF_FREIGHT_RECEIVING) {
                    const typeCargoGeneric = this.scope.offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.TARIFF_FREIGHT_RECEIVING);
                    if (typeCargoGeneric) validationMessages.push(`${typeCargoGeneric.CODE} - ${typeCargoGeneric.NAME}`);
                }
                if (!validationResult.AGENTS) {
                    const agentsGeneric = this.scope.offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.AGENTS);
                    if (agentsGeneric) validationMessages.push(`${agentsGeneric.CODE} - ${agentsGeneric.NAME}`);
                }
                if (!validationResult.ACCOUNT_REQUIREMENT) {
                    const accountRequirementGeneric = this.scope.offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.ACCOUNT_REQUIREMENT);
                    if (accountRequirementGeneric) validationMessages.push(`${accountRequirementGeneric.CODE} - ${accountRequirementGeneric.NAME}`);
                }
                if (!validationResult.TARIFF_DET_DEM_PAYMENT) {
                    const tariffDetDemPaymentGeneric = this.scope.offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.TARIFF_DET_DEM_PAYMENT);
                    if (tariffDetDemPaymentGeneric) validationMessages.push(`${tariffDetDemPaymentGeneric.CODE} - ${tariffDetDemPaymentGeneric.NAME}`);
                }
                if (!validationResult.TARIFF_DET_DEM_RECEIVING) {
                    const tariffDetDemReceivingGeneric = this.scope.offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.TARIFF_DET_DEM_RECEIVING);
                    if (tariffDetDemReceivingGeneric) validationMessages.push(`${tariffDetDemReceivingGeneric.CODE} - ${tariffDetDemReceivingGeneric.NAME}`);
                }
                if (!validationResult.TARIFF_LOCAL) {
                    const tariffLocalGeneric = this.scope.offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.TARIFF_LOCAL);
                    if (tariffLocalGeneric) validationMessages.push(`${tariffLocalGeneric.CODE} - ${tariffLocalGeneric.NAME}`);
                }
                if (!validationResult.COMMODITY) {
                    const commodityGeneric = this.scope.offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.COMMODITY);
                    if (commodityGeneric) validationMessages.push(`${commodityGeneric.CODE} - ${commodityGeneric.NAME}`);
                }
                if (!validationResult.TARIFF_DOMESTIC_ORIGIN) {
                    const tariffDomesticOriginGeneric = this.scope.offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.TARIFF_DOMESTIC_ORIGIN);
                    if (tariffDomesticOriginGeneric) validationMessages.push(`${tariffDomesticOriginGeneric.CODE} - ${tariffDomesticOriginGeneric.NAME}`);
                }
                if (!validationResult.TARIFF_DOMESTIC_DESTINATION) {
                    const tariffDomesticDestinationGeneric = this.scope.offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.TARIFF_DOMESTIC_DESTINATION);
                    if (tariffDomesticDestinationGeneric) validationMessages.push(`${tariffDomesticDestinationGeneric.CODE} - ${tariffDomesticDestinationGeneric.NAME}`);
                }
            }
            return this.getCONCAT(validationMessages);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getTariffLocal = (route, offerOption) => {
        if (!route) return;
        if (!offerOption) return;

        const TARIFF_LOCAL_ORIGIN = {
            TARIFF_LOCAL_ORIGIN_RECEIVING: {
                ID: (offerOption.ID_TARIFF_LOCAL_ORIGIN_RECEIVING) ? offerOption.ID_TARIFF_LOCAL_ORIGIN_RECEIVING : null,
                NAME: (offerOption.TARIFF_LOCAL_ORIGIN_RECEIVING_CONCATENATED) ? offerOption.TARIFF_LOCAL_ORIGIN_RECEIVING_CONCATENATED : null,
                CODE: ERouteEventType.TARIFF_LOCAL_ORIGIN
            },
            TARIFF_LOCAL_ORIGIN_PAYMENT: {
                ID: (offerOption.ID_TARIFF_LOCAL_ORIGIN_PAYMENT) ? offerOption.ID_TARIFF_LOCAL_ORIGIN_PAYMENT : null,
                NAME: (offerOption.TARIFF_LOCAL_ORIGIN_PAYMENT_CONCATENATED) ? offerOption.TARIFF_LOCAL_ORIGIN_PAYMENT_CONCATENATED : null,
                CODE: ERouteEventType.TARIFF_LOCAL_ORIGIN
            },
        }

        const TARIFF_LOCAL_DESTINATION = {
            TARIFF_LOCAL_DESTINATION_RECEIVING: {
                ID: (offerOption.ID_TARIFF_LOCAL_DESTINATION_RECEIVING) ? offerOption.ID_TARIFF_LOCAL_DESTINATION_RECEIVING : null,
                NAME: (offerOption.TARIFF_LOCAL_DESTINATION_RECEIVING_CONCATENATED) ? offerOption.TARIFF_LOCAL_DESTINATION_RECEIVING_CONCATENATED : null,
                CODE: ERouteEventType.TARIFF_LOCAL_DESTINATION
            },
            TARIFF_LOCAL_DESTINATION_PAYMENT: {
                ID: (offerOption.ID_TARIFF_LOCAL_DESTINATION_PAYMENT) ? offerOption.ID_TARIFF_LOCAL_DESTINATION_PAYMENT : null,
                NAME: (offerOption.TARIFF_LOCAL_DESTINATION_PAYMENT_CONCATENATED) ? offerOption.TARIFF_LOCAL_DESTINATION_PAYMENT_CONCATENATED : null,
                CODE: ERouteEventType.TARIFF_LOCAL_DESTINATION
            },
        }

        route.TARIFF_LOCAL_ORIGIN = TARIFF_LOCAL_ORIGIN;
        route.TARIFF_LOCAL_DESTINATION = TARIFF_LOCAL_DESTINATION;
    }

    private getOfferOptionRoutes(offerOption: IOfferOption): IOfferOptionRoutingPoint[] {
        try {
            const routes: IOfferOptionRoutingPoint[] = [];

            if (offerOption.PUP) {
                let pupRoute = null;
                if (offerOption.PUP.FREIGHT && offerOption.PUP.FREIGHT.ROUTING_POINT) {
                    pupRoute = offerOption.PUP.FREIGHT.ROUTING_POINT;
                    pupRoute.TYPE = ERouteEventType.TARIFF_FREIGHT;
                }
                else if (offerOption.PUP.DOMESTIC && offerOption.PUP.DOMESTIC.ORIGIN && offerOption.PUP.DOMESTIC.ORIGIN.ROUTING_POINT) {
                    pupRoute = offerOption.PUP.DOMESTIC.ORIGIN.ROUTING_POINT;
                    pupRoute.TYPE = ERouteEventType.TARIFF_DOMESTIC_ORIGIN;
                }
                if (pupRoute) {
                    pupRoute.EVENT = ERouteEventType.PICK_UP;
                    this.getTariffLocal(pupRoute, offerOption.PUP);
                    routes.push(pupRoute);
                }
            }
            if (offerOption.OTFS) {
                let otfsRoute = null;
                if (offerOption.OTFS.FREIGHT && offerOption.OTFS.FREIGHT.ROUTING_POINT) {
                    otfsRoute = offerOption.OTFS.FREIGHT.ROUTING_POINT;
                    otfsRoute.TYPE = ERouteEventType.TARIFF_FREIGHT;
                }
                else if (offerOption.OTFS.DOMESTIC && offerOption.OTFS.DOMESTIC.ORIGIN && offerOption.OTFS.DOMESTIC.ORIGIN.ROUTING_POINT) {
                    otfsRoute = offerOption.OTFS.DOMESTIC.ORIGIN.ROUTING_POINT;
                    otfsRoute.TYPE = ERouteEventType.TARIFF_DOMESTIC_ORIGIN;
                }
                if (otfsRoute) {
                    otfsRoute.EVENT = ERouteEventType.ORIGIN_TERMINAL_FREIGHT_STATION;
                    this.getTariffLocal(otfsRoute, offerOption.OTFS);
                    routes.push(otfsRoute);
                }
            }
            if (offerOption.POLAOL) {
                let polaolRoute = null;
                if (offerOption.POLAOL.FREIGHT && offerOption.POLAOL.FREIGHT.ROUTING_POINT) {
                    polaolRoute = offerOption.POLAOL.FREIGHT.ROUTING_POINT;
                    polaolRoute.TYPE = ERouteEventType.TARIFF_FREIGHT;
                }
                else if (offerOption.POLAOL.DOMESTIC && offerOption.POLAOL.DOMESTIC.ORIGIN && offerOption.POLAOL.DOMESTIC.ORIGIN.ROUTING_POINT) {
                    polaolRoute = offerOption.POLAOL.DOMESTIC.ORIGIN.ROUTING_POINT;
                    polaolRoute.TYPE = ERouteEventType.TARIFF_DOMESTIC_ORIGIN;
                }
                if (polaolRoute) {
                    polaolRoute.EVENT = `${ERouteEventType.PORT_OF_LOAD}/${ERouteEventType.AIRPORT_OF_ORIGIN}`;
                    this.getTariffLocal(polaolRoute, offerOption.POLAOL);
                    routes.push(polaolRoute);
                }
            }
            if (offerOption.PODAOD) {
                let podaodRoute = null;
                if (offerOption.PODAOD.FREIGHT && offerOption.PODAOD.FREIGHT.ROUTING_POINT) {
                    podaodRoute = offerOption.PODAOD.FREIGHT.ROUTING_POINT;
                    podaodRoute.TYPE = ERouteEventType.TARIFF_FREIGHT;
                }
                else if (offerOption.PODAOD.DOMESTIC && offerOption.PODAOD.DOMESTIC.DESTINATION && offerOption.PODAOD.DOMESTIC.DESTINATION.ROUTING_POINT) {
                    podaodRoute = offerOption.PODAOD.DOMESTIC.DESTINATION.ROUTING_POINT;
                    podaodRoute.TYPE = ERouteEventType.TARIFF_DOMESTIC_DESTINATION;
                }
                if (podaodRoute) {
                    podaodRoute.EVENT = `${ERouteEventType.PORT_OF_DESTINATION}/${ERouteEventType.AIRPORT_OF_DESTINATION}`;
                    this.getTariffLocal(podaodRoute, offerOption.PODAOD);
                    routes.push(podaodRoute);
                }
            }
            if (offerOption.DTFS) {
                let dtfsRoute = null;
                if (offerOption.DTFS.FREIGHT && offerOption.DTFS.FREIGHT.ROUTING_POINT) {
                    dtfsRoute = offerOption.DTFS.FREIGHT.ROUTING_POINT;
                    dtfsRoute.TYPE = ERouteEventType.TARIFF_FREIGHT;
                }
                else if (offerOption.DTFS.DOMESTIC && offerOption.DTFS.DOMESTIC.DESTINATION && offerOption.DTFS.DOMESTIC.DESTINATION.ROUTING_POINT) {
                    dtfsRoute = offerOption.DTFS.DOMESTIC.DESTINATION.ROUTING_POINT;
                    dtfsRoute.TYPE = ERouteEventType.TARIFF_DOMESTIC_DESTINATION;
                }
                if (dtfsRoute) {
                    dtfsRoute.EVENT = ERouteEventType.DESTINATION_TERMINAL_FREIGHT_STATION;
                    this.getTariffLocal(dtfsRoute, offerOption.DTFS);
                    routes.push(dtfsRoute);
                }
            }
            if (offerOption.PLD) {
                let pldRoute = null;
                if (offerOption.PLD.FREIGHT && offerOption.PLD.FREIGHT.ROUTING_POINT) {
                    pldRoute = offerOption.PLD.FREIGHT.ROUTING_POINT;
                    pldRoute.TYPE = ERouteEventType.TARIFF_FREIGHT;
                }
                else if (offerOption.PLD.DOMESTIC && offerOption.PLD.DOMESTIC.DESTINATION && offerOption.PLD.DOMESTIC.DESTINATION.ROUTING_POINT) {
                    pldRoute = offerOption.PLD.DOMESTIC.DESTINATION.ROUTING_POINT;
                    pldRoute.TYPE = ERouteEventType.TARIFF_DOMESTIC_DESTINATION;
                }
                if (pldRoute) {
                    pldRoute.EVENT = ERouteEventType.PLACE_OF_DELIVERY;
                    this.getTariffLocal(pldRoute, offerOption.PLD);
                    routes.push(pldRoute);
                }
            }
            return routes;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async hasInvalidRequiredElements(elementId: string): Promise<boolean> {
        if (!elementId) return false;
        const isInvalid = FormService2.hasRequiredElements('#' + elementId);
        if (isInvalid) this.notifyError(this.getTranslate("GENERAL.ALL_FIELDS_MANDATORY"));
        return isInvalid;
    }

    private async openTariffDetDemDetails(tariffDetDem: ITariffDetDemDetails, tariffContract: IOfferOption) {
        try {
            if (!tariffDetDem) return this.notifyError("tariffDetDem is null");
            if (!tariffContract) return this.notifyError("tariff contract is null");
            if (!tariffContract.ID_TARIFF_FREIGHT_CONTRACT) return this.notifyError("tariff contract id is null");

            let htmlOrigin = '';
            let htmlDestination = '';
            const tariffFreightContractId = tariffContract.ID_TARIFF_FREIGHT_CONTRACT;

            if (tariffDetDem.DET_DEM_ORIGIN) {
                const originTable = this.buildDetDemDetails(tariffDetDem.DET_DEM_ORIGIN, 'origin');

                htmlOrigin += `<div class="row">
            <div class="col-lg-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <div class="row">
                            <div class="col-lg-3 text-left text-strong" style="font-size: 2.5rem;">${this.getTranslate("BASIC_DATA.ORIGIN")}</div>
                            <div class="col-lg-8 text-left">
                                <span class="cursor-hand text-strong openTariffDetDemOriginPayment"
                                tooltip-placement="auto top" tooltip-append-to-body="true">${this.getTranslate("PRODUCT.BUYING_TARIFF")}: ${tariffDetDem.DET_DEM_ORIGIN.CONCATENATED_PAYMENT}
                                    <i class="fa fa-link text-cyano link-icon"></i>
                                </span>`;

                if (tariffDetDem.DET_DEM_ORIGIN.CONCATENATED_RECEIVING) {
                    htmlOrigin += `<br>
                                <span class="cursor-hand text-strong openTariffDetDemOriginReceiving"
                                tooltip-placement="auto top" tooltip-append-to-body="true">${this.getTranslate("PRODUCT.SELLING_TARIFF")}: ${tariffDetDem.DET_DEM_ORIGIN.CONCATENATED_RECEIVING}
                                    <i class="fa fa-link text-cyano link-icon"></i>
                                </span>`;
                }
                htmlOrigin += `</div>
                            <div class="col-lg-1 text-right">`;
                for (const summary of tariffDetDem.DET_DEM_ORIGIN.SUMMARY) {
                    for (const equipment of summary.EQUIPMENT) {
                        htmlOrigin += `${equipment.NAME}`;
                    }
                    htmlOrigin += ` =
                    <span class="text-strong" > ${summary.SUMMARY_PAYMENT && summary.SUMMARY_PAYMENT.COMBINED ? summary.SUMMARY_PAYMENT.COMBINED : summary.SUMMARY_PAYMENT.DET && summary.SUMMARY_PAYMENT.DEM ? summary.SUMMARY_PAYMENT.DET + '+' + summary.SUMMARY_PAYMENT.DEM : ''} / ${summary.SUMMARY_RECEIVING.COMBINED ? summary.SUMMARY_RECEIVING.COMBINED : summary.SUMMARY_RECEIVING.DET && summary.SUMMARY_RECEIVING.DEM ? summary.SUMMARY_RECEIVING.DET + '+' + summary.SUMMARY_RECEIVING.DEM : ''}</span >
                                <br>`;
                }
                htmlOrigin += `</div>
                        </div>                        
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 table-responsive">${originTable}</div>
                </div>
            </div>`;
            }

            if (tariffDetDem.DET_DEM_DESTINATION) {
                const destinationTable = this.buildDetDemDetails(tariffDetDem.DET_DEM_DESTINATION, 'destination');
                htmlDestination += `<div class="row">
            <div class="col-lg-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <div class="row">
                            <div class="col-lg-3 text-left text-strong" style="font-size: 2.5rem;">${this.getTranslate("BASIC_DATA.DESTINATION")}</div>
                            <div class="col-lg-8 text-left">
                                <span class="cursor-hand text-strong openTariffDetDemDestinationPayment"
                                tooltip-placement="auto top" tooltip-append-to-body="true">${this.getTranslate("PRODUCT.BUYING_TARIFF")}: ${tariffDetDem.DET_DEM_DESTINATION.CONCATENATED_PAYMENT}
                                    <i class="fa fa-link text-cyano link-icon"></i>
                                </span>`;
                if (tariffDetDem.DET_DEM_DESTINATION.CONCATENATED_RECEIVING) {
                    htmlDestination += `<br>
                                <span class="cursor-hand text-strong openTariffDetDemDestinationReceiving"
                                tooltip-placement="auto top" tooltip-append-to-body="true">${this.getTranslate("PRODUCT.SELLING_TARIFF")}: ${tariffDetDem.DET_DEM_DESTINATION.CONCATENATED_RECEIVING}
                                    <i class="fa fa-link text-cyano link-icon"></i>
                                </span>`;
                }
                htmlDestination += `</div>
                    <div class="col-lg-1 text-right">`;
                for (const summary of tariffDetDem.DET_DEM_DESTINATION.SUMMARY) {
                    for (const equipment of summary.EQUIPMENT) {
                        htmlDestination += `${equipment.NAME} `;
                    }
                    htmlDestination += ` =
                    <span class="text-strong" > ${summary.SUMMARY_PAYMENT && summary.SUMMARY_PAYMENT.COMBINED ? summary.SUMMARY_PAYMENT.COMBINED : summary.SUMMARY_PAYMENT.DET && summary.SUMMARY_PAYMENT.DEM ? summary.SUMMARY_PAYMENT.DET + '+' + summary.SUMMARY_PAYMENT.DEM : ''} / ${summary.SUMMARY_RECEIVING.COMBINED ? summary.SUMMARY_RECEIVING.COMBINED : summary.SUMMARY_RECEIVING.DET && summary.SUMMARY_RECEIVING.DEM ? summary.SUMMARY_RECEIVING.DET + '+' + summary.SUMMARY_RECEIVING.DEM : ''}</span >
                        <br>`;
                }
                htmlDestination += `</div>
                        </div>
                        </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12 table-responsive" > ${destinationTable} </div>
                                </div>
                                </div>`;
            }

            let html = ``;
            html += htmlOrigin;
            html += htmlDestination;

            const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                {
                    scope: this.scope,
                    size: 'full monaco-wizard'
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: "GENERAL.CLOSE",
                    headerText: "PRODUCT.DET_DEM",
                    bodyText: this.sce.trustAsHtml(html)
                });

            await modalInstance.rendered.then(() => {
                angular.element(".openTariffDetDemOriginPayment").bind('click', () => { this.openTabForTariffFreight(tariffDetDem, tariffFreightContractId, EDirectionId.ORIGIN, EPaymentNatureId.PAYMENT) });
                angular.element(".openTariffDetDemOriginReceiving").bind('click', () => { this.openTabForTariffFreight(tariffDetDem, tariffFreightContractId, EDirectionId.ORIGIN, EPaymentNatureId.RECEIVING) });
                angular.element(".openTariffDetDemDestinationPayment").bind('click', () => { this.openTabForTariffFreight(tariffDetDem, tariffFreightContractId, EDirectionId.DESTINATION, EPaymentNatureId.PAYMENT) });
                angular.element(".openTariffDetDemDestinationReceiving").bind('click', () => { this.openTabForTariffFreight(tariffDetDem, tariffFreightContractId, EDirectionId.DESTINATION, EPaymentNatureId.RECEIVING) });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private openTabForTariffFreight(tariffDetDem: ITariffDetDemDetails, tariffFreightContractId: number, direction: string, paymentNature: string) {
        try {
            if (!tariffDetDem) return this.notifyError("tariff D&D is null");
            if (!tariffFreightContractId) return this.notifyError("tariff freight contract is null");
            if (!direction) return this.notifyError("direction is null");
            if (!paymentNature) return this.notifyError("payment nature is null");

            const detDemOriginDestination: IDetDemOriginDestination = direction == EDirectionId.ORIGIN ? tariffDetDem.DET_DEM_ORIGIN : tariffDetDem.DET_DEM_DESTINATION;
            const propertyId = paymentNature == EPaymentNatureId.PAYMENT ? 'ID_PAYMENT' : 'ID_RECEIVING';
            const propertyDataOrigin = paymentNature == EPaymentNatureId.PAYMENT ? 'PAYMENT_DATA_ORIGIN' : 'RECEIVING_DATA_ORIGIN';
            const contractId = typeof tariffFreightContractId == 'number' ? tariffFreightContractId : '';
            const id: number = detDemOriginDestination[propertyId];
            let link: string = null;

            switch (detDemOriginDestination[propertyDataOrigin].ID) {
                case "1":
                case "3":
                    link = 'app.product.tariffFreight';
                    break;
                case "2":
                    link = 'app.product.tariffDetDem';
                    break;
                default:
                    link = "";
                    break;
            }
            this.sessionService.openTab(link, <ILinkParameter>{ ID: contractId ? contractId.toString() : contractId });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private buildDetDemDetails(tariffDetDemOriginDestination: IDetDemOriginDestination, direction: string) {
        let originDetailRow = ``;
        let freeTimeDetailRow = ``;
        let htmlOriginStart = `<div class="row">
        <div class="col-lg-12">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <div class="row">
                        <div class="col-lg-3 text-strong text-center"> ${this.getTranslate("BASIC_DATA.EQUIPMENT")} </div>
                        <div class="col-lg-3 text-strong text-center"> ${this.getTranslate("GENERAL.TYPE")} </div>
                        <div class="col-lg-3 text-strong text-center"> ${this.getTranslate("BASIC_DATA.PAYMENT_NATURE")} </div>
                        <div class="col-lg-3 text-strong text-center"> ${this.getTranslate("BASIC_DATA.FREE_TIME")} </div>
                    </div>
                </div>
                <div class="panel panel-body" style="margin-bottom: 0px;">`;
        if (tariffDetDemOriginDestination) {
            for (let index = 0; index < tariffDetDemOriginDestination.DETAILS.length; index++) {
                const detail = tariffDetDemOriginDestination.DETAILS[index];
                originDetailRow += `
                        <div class="row">
                            <div class="col-lg-3 text-center">${this.getCONCAT(detail.EQUIPMENT)}</div>
                            <div class="col-lg-3 text-center">${detail.TYPE}</div>
                            <div class="col-lg-3 text-center">${detail.PAYMENT_NATURE.ID == '1' ? ' - Pagamento' : ' + Recebimento'}</div>
                            <div class="col-lg-3 text-center">${detail.FREE_TIME} </div>`;

                if (detail.FREE_TIME_DETAILS && detail.FREE_TIME_DETAILS.length > 0) {
                    originDetailRow += `<div class="panel-heading collapse-heading clickable-div collapsed" data-toggle="collapse" data-target="#freeTimeDetails_${direction}_${index}" aria-controls="collapseShareTable" aria-expanded="false"></div>`;
                }

                originDetailRow += `</div>`;

                originDetailRow += `<div id="freeTimeDetails_${direction}_${index}" class="toggle-me collapse" role="tabpanel" tabindex="-1" aria-expanded="false" style="height: 30px; margin-bottom:0px;">
                        <div class="row">
                            <div class="col-lg-12 table-responsive">
                                <table class="table gray-border table-striped no-border-inside monaco-custom-table m-b-sm m-t-sm" style="width: 100%;">
                                    <thead>
                                        <tr>
                                            <th class="text-center">${this.getTranslate("PRODUCT.FT_PERIOD")}</th>
                                            <th class="text-center">${this.getTranslate("PRODUCT.VALIDITY_START")}</th>
                                            <th class="text-center">${this.getTranslate("PRODUCT.VALIDITY_END")}</th>
                                            <th class="text-center">${this.getTranslate("GENERAL.DAYS")}</th>
                                            <th class="text-center">${this.getTranslate("GENERAL.CURRENCY")}</th>
                                            <th class="text-center">${this.getTranslate("PRODUCT.PER_DIEM_TYPE")}</th>
                                            <th class="text-center">${this.getTranslate("FINANCIAL.ORIGINAL_VALUE")}</th>
                                            <th class="text-center">${this.getTranslate("FINANCIAL.FIXED_ADDITIONAL")}</th>
                                            <th class="text-center">${this.getTranslate("FINANCIAL.PERCENTAGE_ADDITIONAL")}</th>
                                            <th class="text-center">${this.getTranslate("FINANCIAL.ACTUAL_TOTAL_AMOUNT")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                `;

                if (detail.FREE_TIME_DETAILS && detail.FREE_TIME_DETAILS.length > 0) {
                    for (let freeTimeInfos = 0; freeTimeInfos < detail.FREE_TIME_DETAILS.length; freeTimeInfos++) {
                        freeTimeDetailRow += `<tr>
                                                <td class="text-center">${detail.FREE_TIME_DETAILS[freeTimeInfos].PERIOD}</td>
                                                <td class="text-center">${detail.FREE_TIME_DETAILS[freeTimeInfos].FROM}</td>
                                                <td class="text-center">${detail.FREE_TIME_DETAILS[freeTimeInfos].UNTIL}</td>
                                                <td class="text-center">${detail.FREE_TIME_DETAILS[freeTimeInfos].DAYS}</td>
                                                <td class="text-center">${detail.FREE_TIME_DETAILS[freeTimeInfos].CURRENCY.NAME}</td>
                                                <td class="text-center">${detail.FREE_TIME_DETAILS[freeTimeInfos].PER_DIEM_TYPE.NAME}</td>
                                                <td class="text-right">${this.$filterService('number')(detail.FREE_TIME_DETAILS[freeTimeInfos].ORIGINAL_VALUE, 2)}</td>
                                                <td class="text-right">${this.$filterService('number')(detail.FREE_TIME_DETAILS[freeTimeInfos].FIXED_ADDITIONAL, 2)}</td>
                                                <td class="text-right">${this.$filterService('number')(detail.FREE_TIME_DETAILS[freeTimeInfos].PERCENTAGE_ADDITIONAL, 2)}</td>
                                                <td class="text-right">${this.$filterService('number')(detail.FREE_TIME_DETAILS[freeTimeInfos].TOTAL_VALUE, 2)}</td>
                                              </tr>`;
                    }
                }
                originDetailRow += freeTimeDetailRow;
                originDetailRow += `
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>`;
                freeTimeDetailRow = ``;
            }
        }
        htmlOriginStart += originDetailRow;
        htmlOriginStart += `
                            </div>
                        </div>
                </div>`;
        return htmlOriginStart;
    }

    private buildDetDemDetailDirection(product: string, tariffDetDem: ITariffDetDemDetails): ITariffDetDemDetails {
        let result = null;
        if (tariffDetDem) {
            if (product == 'IM') {
                result = tariffDetDem.DET_DEM_DESTINATION.SUMMARY;
            } else if (product == 'EM') {
                result = tariffDetDem.DET_DEM_ORIGIN.SUMMARY;
            }
        }
        return result;
    }

    private finishOfferWizard() {
        try {
            let params = this.scope.confirmationList && this.scope.confirmationList.length ? this.scope.confirmationList.map(x => x.OFFER_CODE_EXHIBITION.toString()).join(';') : null;
            const sessionParameter = <ISessionParameter>{
                param: { OFFER_CODE_EXHIBITION: params },
            };
            sessionStorage.setItem("app.commercial.offer", JSON.stringify(sessionParameter));
            this.scope.modalOptions.ok(false);
            window.location.reload();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private buildCurrentState(): IOfferWizardModalProfileState {
        return {
            OFFER_TAB: this.scope.model.OFFER_TAB,
            ROUTE_TAB: this.scope.model.ROUTE_TAB,
            CARGO_TAB: this.scope.model.CARGO_TAB,
            SPEC_TAB: this.scope.model.SPEC_TAB
        }
    }

    private async addProfileModal(): Promise<void> {
        await this.modalService.showModalInfo(
            {
                template: require("../../app/view/template/monaco-data-table-profile-modal.html"),
                scope: this.scope, //passed current scope to the modal
            },
            {
                actionButtonText: "GENERAL.CLOSE",
                headerText: "GENERAL.PROFILE_ADD",
            });
    }

    private async addNewProfile(profileName: string, modalContext): Promise<void> {
        try {

            if (!profileName) return;

            this.block();

            //save the new profile on the db
            this.scope.currentState = this.buildCurrentState();
            await this.saveProfileSetting(profileName, this.scope.currentState);
            const newProfileIndex = this.scope.profile.profileList.length + 1;
            this.scope.profile.profileList.push({ ID: newProfileIndex.toString(), NAME: profileName });
            this.scope.profile.selectedProfile = this.scope.profile.profileList[newProfileIndex - 1];

            //close the modal
            modalContext.ok();

            this.unblock();

        } catch (ex) {
            this.resetBlock();
            this.handleError(ex);
        }
    }

    private async removeProfile(profileName: string): Promise<void> {
        try {
            if (!profileName) return;

            const checked = await this.modalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: this.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")
            });
            if (checked) {
                const removed = await this.rootScope.removeSetting(this.persistName, profileName);
                if (!removed) return this.handleError(`Failed to remove data table profile: ${profileName}`);

                //load the last profile of the list                        
                const profileIndex = this.scope.profile.profileList.findIndex(profile => profile.NAME === profileName);
                if (this.scope.profile.profileList && this.scope.profile.profileList.length > 0) this.scope.profile.profileList.splice(profileIndex, 1);
                const lastProfileIndex = (this.scope.profile.profileList.length > 0) ? (this.scope.profile.profileList.length - 1) : null;
                if (lastProfileIndex != null) {
                    this.scope.profile.selectedProfile = angular.copy(this.scope.profile.profileList[lastProfileIndex]);
                } else this.scope.profile.selectedProfile = null;

                await this.loadProfileData(this.scope.profile.selectedProfile);
            }

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async saveProfile(profileName: string): Promise<void> {
        try {
            if (!profileName) throw new Error('profileName is NULL');

            const profileData = this.buildCurrentState();
            await this.saveProfileSetting(profileName, profileData);

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async loadProfileList(settingId: string) {
        const profileList = await this.rootScope.getSetting(settingId);
        const profileListKeys = profileList ? Object.keys(profileList) : null;
        const noneProfile = { ID: "1", NAME: "Nenhum" };
        if (profileListKeys && profileListKeys.length > 0) {

            this.scope.profile.profileList = [noneProfile];

            //populate profile list selector
            for (let i = 0; i < profileListKeys.length; i++) {
                this.scope.profile.profileList.push({ ID: (i + 2).toString(), NAME: profileListKeys[i] });
            }

            //select the last profile on the list
            const lastProfileIndex = (this.scope.profile.profileList.length > 0) ? (this.scope.profile.profileList.length - 1) : null;
            const lastProfile = (lastProfileIndex != null) ? this.scope.profile.profileList[lastProfileIndex] : null;
            if (lastProfile && profileList[lastProfile.NAME]) {
                await this.loadProfileData(lastProfile);
                this.scope.profile.selectedProfile = lastProfile;
            }
        }
    }

    private async loadProfileData(profile: SelectorModel): Promise<void> {
        try {
            if (profile) {
                // 1 = Nenhum
                if (profile.ID == "1") {
                    if (this.scope.steps.current == ETabStep.OFFER) this.resetOfferTabModel();
                    if (this.scope.steps.current == ETabStep.ROUTE) {
                        this.resetRouteTabModel();
                        this.initRouteTab();
                    }
                    if (this.scope.steps.current == ETabStep.CARGO) {
                        this.resetCargoTabModel();
                        this.initCargoTab();
                    }
                } else {
                    const profileName = profile.NAME;
                    const loadedProfile = await this.rootScope.getSetting(this.persistName, profileName);
                    if (loadedProfile && loadedProfile[profileName]) {
                        this.scope.currentState = loadedProfile[profileName];
                        // Offer tab load
                        this.scope.model.OFFER_TAB = this.scope.currentState.OFFER_TAB;
                        this.productChange(true, true);

                        // Route tab load
                        this.scope.model.ROUTE_TAB = this.scope.currentState.ROUTE_TAB;

                        // Cargo tab load
                        await this.initCargoTab();
                    }
                }
            } else {
                await this.initModel();
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.loadRegisterForm(false);
        }
    }

    private async saveProfileSetting(profileName: string, profileData: any): Promise<void> {
        try {
            if (!profileName) throw new Error('profileName is NULL');
            if (!profileData) throw new Error('profileData is NULL');

            const saved = await this.rootScope.updateSetting(this.persistName, profileData, profileName);
            if (!saved) return this.handleError(`Failed to save monaco data table profile: ${profileName}`);

            //update the current state                    
            this.scope.currentState = profileData;

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async generateOffer(): Promise<boolean> {
        let offerOptions = [];
        let valid = true;
        const offerConfirmationList: IOfferConfirmationList[] = [];
        const offerConfirmationErrorList: IOfferConfirmationList[] = [];
        try {
            this.block();
            if (this.scope.selectedOptions && this.scope.selectedOptions.length > 0) {
                for (let offerIndex = 0; offerIndex < this.scope.selectedOptions.length; offerIndex++) {
                    if (this.scope.selectedOptions[offerIndex].SELECTED) {
                        offerOptions.push({
                            "id": this.scope.selectedOptions[offerIndex]._id,
                            "CONCATENATED_COMPLEMENT": this.scope.selectedOptions[offerIndex].CONCATENATED_COMPLEMENT ? this.scope.selectedOptions[offerIndex].CONCATENATED_COMPLEMENT : ''
                        });
                    }
                }
                if (offerOptions.length > 0) {
                    const generatedOffer = await this.restService.newObjectPromise(`${this.quotationUrl}/offerOptionPayment/generate-offer`, { OFFER_OPTIONS: offerOptions }, 30000, false);
                    if (generatedOffer && generatedOffer.length > 0) {
                        for (const offer of generatedOffer) {
                            if (offer.ERROR) {
                                offerConfirmationErrorList.push({
                                    ID: offer.ID,
                                    CONCATENATED: offer.CONCATENATED,
                                    OFFER_CODE_EXHIBITION: offer.OFFER_CODE_EXHIBITION,
                                    LOAD_REF: moment(offer.LOAD_REF).format("DD/MM/YYYY"),
                                    ERROR: offer.ERROR
                                });
                            } else {
                                offerConfirmationList.push({
                                    ID: offer.ID,
                                    CONCATENATED: offer.CONCATENATED,
                                    OFFER_CODE_EXHIBITION: offer.OFFER_CODE_EXHIBITION,
                                    LOAD_REF: moment(offer.LOAD_REF).format("DD/MM/YYYY"),
                                    ERROR: ''
                                });
                            }
                        }
                        this.scope.confirmationList = offerConfirmationList;
                        this.scope.confirmationErrorList = offerConfirmationErrorList;
                    } else {
                        this.notifyError("Offers was not correctly generated.");
                        valid = false;
                    }
                } else {
                    this.notifyInfo('No offer was selected.');
                    valid = false;
                }
            }
        } catch (ex) {
            this.handleError(ex);
            valid = false;
        } finally {
            this.unblock();
            return valid;
        }
    }

    private getLoadRefDate(loadRefData: Date, paymentNature: string) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (paymentNature == '1' && !this.loadRefPayment) {
            this.loadRefPayment = loadRefData;
        }

        if (paymentNature == '2' && !this.loadRefReceiving) {
            this.loadRefReceiving = loadRefData;
        }

        if (this.loadRefPayment && !this.loadRefReceiving) {
            if (moment(this.loadRefPayment).isBefore(today)) {
                return today;
            } else {
                return this.loadRefPayment;
            }
        }

        if (this.loadRefReceiving && !this.loadRefPayment) {
            if (moment(this.loadRefReceiving).isBefore(today)) {
                return today;
            } else {
                return this.loadRefReceiving;
            }
        }
        if (this.loadRefPayment && this.loadRefReceiving) {
            if (moment(this.loadRefPayment).isBefore(this.loadRefReceiving)) {
                return this.loadRefReceiving;
            } else {
                return this.loadRefPayment;
            }
        }

        if (moment(loadRefData).isBefore(today)) {
            return today;
        } else {
            return loadRefData;
        }
    }

    private loadAirCargo() {
        const airCargo = this.scope.model.CARGO_TAB.AIR;
        if (airCargo) {
            const air: IAirCargo = {
                ORIGINAL_AIR_CARGO: airCargo.ORIGINAL_AIR_CARGO ? airCargo.ORIGINAL_AIR_CARGO : null,
                WEIGHT_BREAKDOWN: airCargo.WEIGHT_BREAKDOWN ? airCargo.WEIGHT_BREAKDOWN : false,
                APPLICATION: airCargo.APPLICATION ? airCargo.APPLICATION : null,
                CHARGEABLE_WEIGHT: airCargo.CHARGEABLE_WEIGHT ? airCargo.CHARGEABLE_WEIGHT : 0,
                OVER_CHARGEABLE_WEIGHT: airCargo.OVER_CHARGEABLE_WEIGHT ? airCargo.OVER_CHARGEABLE_WEIGHT : null,
                WEIGHT_RANGE: airCargo.WEIGHT_RANGE ? airCargo.WEIGHT_RANGE : null,
                WEIGHT_RANGE_DEFAULT: airCargo.WEIGHT_RANGE_DEFAULT ? airCargo.WEIGHT_RANGE_DEFAULT : null,
                CHARGEABLE_WEIGHT_VALID: airCargo.CHARGEABLE_WEIGHT_VALID ? airCargo.CHARGEABLE_WEIGHT_VALID : null,
                WEIGHT_RANGE_BASE: airCargo.WEIGHT_RANGE_BASE ? airCargo.WEIGHT_RANGE_BASE : null
            };
            if (airCargo.APPLICATION && !airCargo.APPLICATION.NAME_INTL) airCargo.APPLICATION.NAME_INTL = null;

            this.scope.model.CARGO_TAB.AIR = air;
        }
    }
}

import * as angular from 'angular';
import { IColumnDefOf } from 'ui-grid';
import { IRestService } from '@services/RestService';
import { IGridFormController } from '@services/GridFormService';
import { GridFormService } from "@services/GridFormService";
import { ProductService } from "@services/ProductService";
import { IMonacoRequest } from "@services/GridFormService";
import { IViewLog } from "@models/interface/common/IViewLog";
import { ISLADeadlineModel, ISLADeadlineScope } from './../interface/ISLADeadline';
import { GridColumnBuilder, GridColumnBuilderConstants } from '../../common/GridColumnBuilder';
import { RouteConstants } from '../../common/util/RouteConstants';
import { BrowserTitle } from '../../common/BrowserTitle';
import { OperationalService } from '@services/OperationalService';

/**
* @description Controller para SLA Deadline
* 
* @author Ríder Cantuária <rider.cantuaria@allog.com.br>
* @since 1.2.0
*
* @requires Entities/Models/StopOversModel
* @requires Factories/GridFactory
*/
export class SLADeadlineRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: ISLADeadlineScope;
    private $timeout: ng.ITimeoutService
    private $q: ng.IQService;
    private RestService: IRestService;
    private throttle;
    private ProductService : ProductService;
    private OperationalService: OperationalService;

    constructor($injector: ng.Injectable<any>, $scope: ISLADeadlineScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$timeout = $injector.get('$timeout');
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.ProductService = $injector.get('ProductService');
        this.OperationalService = $injector.get('OperationalService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlOperational();

            this.initForm(this, 'form', 'slaDeadline', 'GENERAL.MENU.SLA_DEADLINE', true);

            this.block();

            this.$scope.formOperation = 'SLA DeadLine';
            this.$scope.showClient = true;
            this.setCopyable(false);

            await this.initGrid('sla-deadline', RouteConstants.SLA_LIST, true, true);

            this.unblock();

        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
        //$interval.cancel(updateDataTask);
    }


    initModel(): void {

        this.$scope.model = {
            _id: null,
            ID: null,
            SLADEADLINE_NUMBER: null,
            CLIENT: null,
            VGM: null,
            VGM_WORKING_DAY: false,
            DRAFT_IMO: null,
            DRAFT_IMO_WORKING_DAY: false,
            DRAFT: null,
            DRAFT_WORKING_DAY: false,
            RELEASE: null,
            RELEASE_WORKING_DAY: false,
            STATUS: true,
            EXPORTER: null
        };

    }

    /**
    * Faz o tratamento para exibição dos dados (configurações padrão), utiliza trhottle pattern    
    */
    initScopeFunctions(): void {
        this.$scope.configureDeadlines = () => {
            clearTimeout(this.throttle);

            this.block();
            this.throttle = setTimeout(() => {
                this.configureDeadlines();
                this.unblock();
            }, 500);

        }

        this.$scope.viewLog = (entity: ISLADeadlineModel) => {
            this.viewLog(entity);
        }

        this.$scope.toggleStatus = (entity: ISLADeadlineModel) => {
            this.toggleStatus(entity);
        }


        this.$scope.searchClients = (query: string) => {
            this.searchClients(query);
        }

        this.$scope.searchExporter = (query: string) => {
            this.searchExporter(query);
        }

        this.$scope.enableClient = () => {

            this.$scope.showClient = true;
            this.$scope.requiredClient = true;
            this.$scope.requiredExporter = true;
            this.$scope.operation = 'register';

            this.$scope.model._id = null;
            this.$scope.model.ID = null;
            this.$scope.model.SLADEADLINE_NUMBER = null;

        }

        this.$scope.changeStatus = (client: object, exporter: object) => {
            this.changeStatus(client, exporter)
        }

    };


    initGridColumns(columns) {

        let builder = new GridColumnBuilder(columns);


        const buttons: Array<string> = [GridColumnBuilderConstants.BTN_VIEW, GridColumnBuilderConstants.BTN_EDIT, GridColumnBuilderConstants.BTN_VIEWLOG, GridColumnBuilderConstants.BTN_STATUS];
        builder.includeActionBar(buttons);

        let code: IColumnDefOf<any> = {};
        code.name = 'SLADEADLINE_NUMBER';
        code.displayName = 'GENERAL.CODE';
        code.width = 100;
        code.filter = { condition: this.gridService.filterSelectObject };

        let customer: IColumnDefOf<any> = {};
        customer.name = 'CLIENT.NAME';
        customer.displayName = 'BASIC_DATA.CLIENT';
        customer.width = 200;
        customer.filter = { condition: this.gridService.filterSelectObject };

        let exporter: IColumnDefOf<any> = {};
        exporter.name = 'EXPORTER.NAME';
        exporter.displayName = 'BASIC_DATA.SHIPPER';
        exporter.width = 200;
        exporter.filter = { condition: this.gridService.filterSelectObject };

        let vgm: IColumnDefOf<any> = {};
        vgm.name = 'VGM';
        vgm.displayName = 'OPERATIONAL.VGM';
        vgm.width = 80;
        vgm.filter = { condition: this.gridService.filterSelectObject };

        let imoDraft: IColumnDefOf<any> = {};
        imoDraft.name = 'DRAFT_IMO';
        imoDraft.displayName = 'OPERATIONAL.DRAFT_IMO';
        imoDraft.width = 80;
        imoDraft.filter = { condition: this.gridService.filterSelectObject };

        let regularDraft: IColumnDefOf<any> = {};
        regularDraft.name = 'DRAFT';
        regularDraft.displayName = 'OPERATIONAL.DRAFT';
        regularDraft.width = 80;
        regularDraft.filter = { condition: this.gridService.filterSelectObject };

        let releaseDraft: IColumnDefOf<any> = {};
        releaseDraft.name = 'RELEASE';
        releaseDraft.displayName = 'OPERATIONAL.DEADLINE_RELEASE';
        releaseDraft.width = 80;
        releaseDraft.filter = { condition: this.gridService.filterSelectObject };

        builder.addColumn(code)
            .addColumn(customer)
            .addColumn(exporter)
            .addColumn(vgm)
            .addColumn(imoDraft)
            .addColumn(regularDraft)
            .addColumn(releaseDraft);

        return builder.$columnDefs;
    }

    private changeStatus(client: object, exporter: object) {
        if (client && !exporter) this.$scope.requiredExporter = false;
        if (exporter && !client) this.$scope.requiredClient = false;
    }

    private async configureDeadlines() {

        this.$scope.operation = 'edit';
        this.$scope.showClient = false;
        this.$scope.requiredClient = false;
        this.$scope.requiredExporter = false;

        await this.getDefaultSLADeadline();

    }

    private async getDefaultSLADeadline() {
        try {
            // let defaultDeadlines = await this.RestService.getObjectAsPromise(RouteConstants.SLA_DEFAULT);
            let defaultDeadlines = await this.OperationalService.get('/slaDeadline/default', null, 12000);

            this.$scope.model = Object.assign(this.$scope.model, defaultDeadlines.data.data);

            this.$scope.$apply();

        } catch (errorResponse) {
            this.handleError(errorResponse);
        }
    }


    /**
     * Busca Cliente pela query
     * 
     * @param term 
     */
    private async searchClients(query: string) {
        let result = [];

        try {
            this.block();
            //se tem dados traz eles

                const productReq: IMonacoRequest = {
                    route: `/legalPerson/list/custom/operational`,
                    data: { specializations:['23'], search: query }
                }
                const legalPersons = await this.ProductService.post<any>(productReq);
                if (legalPersons && legalPersons.data) {
                    result = legalPersons.data.data ? legalPersons.data.data.map(x => {
                        return {ID: x.ID, NAME: x.NAME}
                    }) : [];
                }

                this.$scope.clients = result;

            return 
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }


    }

    private async searchExporter(query: string) {
        let result = [];

        try {
            this.block();
            //se tem dados traz eles

            const productReq: IMonacoRequest = {
                route: `/legalPerson/list/custom/operational`,
                data: { specializations:['4'], search: query }
            }
            const legalPersons = await this.ProductService.post<any>(productReq);
            if (legalPersons && legalPersons.data) {
                result = legalPersons.data.data ? legalPersons.data.data.map(x => {
                    return {ID: x.ID, NAME: x.NAME}
                }) : [];
            }

            this.$scope.exporters = result;

            return
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }


    }

    private async toggleStatus(deadline: ISLADeadlineModel): Promise<void> {
        try {
            this.block();

            await this.requestToggle(deadline._id, deadline.STATUS);
            await this.updateGrid();

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async viewLog(deadline: ISLADeadlineModel) {

        this.block();

        let log: IViewLog = {
            operation: 'history',
            number: deadline._id,
            list: [],
            show: true,
            searchQuery: '',
            originalList: [],
        }

        await this.requestHistory(log.number).then(result => {

            log.list = result;
            log.originalList = angular.copy(log.list);

            this.$scope.log = log;

            //hack para renderizar e pegar o offset
            angular.element('#log-viewer').removeClass('ng-hide');

            //forcar a busca na view
            const position = angular.element('#log-viewer').offset().top + $('.app-content-body').scrollTop() - 105;

            //anima
            $('.app-content-body').animate({
                scrollTop: position
            }, 500);

            this.unblock();

        }).catch(ex => {
            this.handleError(ex);
        });

    };

    private async requestHistory(number: string) {
        const result = await this.OperationalService.get(`/slaDeadline/viewLog/${number}`, number, 12000);

        return result.data.data;
    }

    private async requestToggle(_id: string, currentStatus: boolean) {
        let request = {
            deadlineId: _id,
            currentStatus: currentStatus,
            timeout: 10000
        }

        const result = await this.OperationalService.post('/slaDeadline/toggleStatus', request, 12000);
        return result.data.data;
    }

    private getUrlOperational(): string {
        const baseRoute = '/operational';
        const urlOperational = this.config.operationalUrl + baseRoute;

        return urlOperational;
    }

    /*****************
     * HOOKS
     *****************/
    async register() {
        this.$scope.showClient = true;
        await this.getDefaultSLADeadline();

        this.$scope.enableClient();
    }

    async edit() {
        this.$scope.showClient = true;
        BrowserTitle.$id = this.$scope.model.SLADEADLINE_NUMBER;
    }

    async cancel() {
        this.$scope.showClient = true;
    }

    async view() {
        BrowserTitle.$id = this.$scope.model.SLADEADLINE_NUMBER;
    }

    async notifyClick(model: ISLADeadlineModel): Promise<any> {
        return (model.SLADEADLINE_NUMBER === 'DEFAULT') ? null : this.$scope.view(model);
    }

}

import * as angular from "angular";
import * as moment from 'moment';
import { SSEService } from '@appServices/SSEService';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog, } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IModalService } from '@services/ModalService';
import { ISessionService } from "@services/SessionService";
import { ICustomLogProperties, IViewLog } from "@models/interface/common/IViewLog";
import { IParamsProfitShareModel, IParamsProfitShareOriginDestination, IParamsProfitShareRules, IParamsProfitShareRulesProfitShare, IParamsProfitShareRulesProfitShareEquipType } from "@models/interface/product/ParamsProfitShareModel";
import { IRoutingPointSelector } from "@models/interface/product/RoutingPoint";
import { IEquipmentSelector } from "@models/interface/product/EquipmentModel";
import { IApplicationList } from "@models/interface/product/ApplicationModel";
import { EForwardedById, EOperation } from "@enums/GenericData";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { SelectorModel } from "../../common/model/SelectorModel";
import { ILinkParameter, IEquipmentParameter } from "../../common/model/ModelParameter";
import { BrowserTitle } from "../../common/BrowserTitle";
import { ValidateUtil } from "../../common/util/ValidateUtil";
import { StringUtil } from "../../common/util/StringUtil";
import { HelperService } from "@services/HelperService";

interface IResponseData {
    result: boolean;
    data: string;
}

interface IResponse {
    data: IResponseData;
    message: string;
    reason: string;
    status: number
}

interface IParamsProfitShareScope extends IGridFormServiceScope {
    form: ng.IFormController;
    model: IParamsProfitShareModel;
    log: IViewLog;
    panelTitle: string;
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IParamsProfitShareModel;
    productList: SelectorModel[];
    typeCargoList: SelectorModel[];
    currencyList: SelectorModel[];
    hierarchyList: number[];
    forwardedByList: SelectorModel[];
    eventList: SelectorModel[];
    applicationList: IApplicationList[];
    chargeNameBaseList: SelectorModel[];
    chargeNameList: SelectorModel[];
    networkList: SelectorModel[];
    agentList: SelectorModel[];
    accountList: SelectorModel[];
    countryList: SelectorModel[];
    routingPointList: IRoutingPointSelector[];
    equipmentList: IEquipmentSelector[];
    feetList: SelectorModel[];
    situationList: SelectorModel[];
    user: any;
    sessionService: ISessionService;
    editParamsProfitShare: (paramsProfitShare: IParamsProfitShareModel) => Promise<void>;
    viewParamsProfitShare: (paramsProfitShare: IParamsProfitShareModel) => Promise<void>;
    viewLogParamsProfitShare: (paramsProfitShare: IParamsProfitShareModel) => Promise<void>;
    copyParamsProfitShare: (paramsProfitShare: IParamsProfitShareModel) => Promise<void>;
    getCurrencyListByName: (search: string) => Promise<void>;
    getChargeNameListByName: (search: string) => Promise<void>;
    getChargeNameListForComposition: (search: string) => Promise<void>;
    getNetworkListByName: (search: string) => Promise<void>;
    getAgentListByName: (search: string) => Promise<void>;
    getAccountListByName: (search: string) => Promise<void>;
    getCountryList: (search: string) => Promise<void>;
    getRoutingPointListByName: (search: string) => Promise<void>;
    getEquipmentList: (search: string) => Promise<void>;
    getApplicationListByName: (index: number, search: string) => Promise<void>;
    getCONCATInformation: (data: IParamsProfitShareOriginDestination[], field: string) => string;
    getGridRules: (data: IParamsProfitShareRules[]) => string;
    getGridRulesSimple: (data: IParamsProfitShareRules[]) => string;
    addOriginDestination: () => void;
    removeOriginDestination: (index: number) => void;
    addRule: () => void;
    removeRule: (index: number) => void;
    duplicateRule: (index: number) => void;
    addProfitShare: (ruleIndex: number) => void;
    removeProfitShare: (ruleIndex: number, profitShareIndex: number) => void;
    duplicateProfitShare: (ruleIndex: number, profitShareIndex: number) => void;
    addProfitShareEquipType: (ruleIndex: number, profitShareIndex: number) => void;
    removeProfitShareEquipType: (ruleIndex: number, profitShareIndex: number, equipTypeIndex: number) => void;
    goToCurrency: (id: number) => void;
    goToChargeName: (id: number) => void;
    goToNetwork: () => void;
    goToAgent: () => void;
    goToAccount: () => void;
    goToEquipment: (equipmentType: IParamsProfitShareRulesProfitShareEquipType, param: string) => void;
    goToCountry: (originDestination: IParamsProfitShareOriginDestination, type: string) => void;
    goToApplication: (id: number) => void;
    goToRoutingPoint: (originDestination: IParamsProfitShareOriginDestination, type: string) => void;
    changeCurrency: (index: number) => void;
    changeApplication: (ruleIndex: number, profitShareIndex: number) => void;
    changeEquipType: (ruleIndex: number, profitShareIndex: number, equipTypeIndex: number) => void;
    changeFeet: (ruleIndex: number, profitShareIndex: number, equipTypeIndex: number) => void;
    updateProduct: () => void;
    updateRuleCompanyPercent: (ruleIndex: number) => void;
    formatedScoreDate: () => string;
    compositionChange: () => void;
    productListFilter: () => Function;
    hierarchyFilter: (ruleIndex: number) => Function;
    equipTypeFilter: (ruleIndex: number, profitShareIndex: number) => Function;
    equipFeetFilter: (ruleIndex: number, profitShareIndex: number) => Function;
    forwardedByFilter: (forwardedBy: SelectorModel) => boolean;
    resetApplication: () => void;
    isMaritimeModal: () => boolean;
    isAirModal: () => boolean;
    checkDateValidity: (initialDate: Date, finalDate: Date) => void;
    generateConcatenated: () => void;
    setCopy: (value: boolean) => void;
    fetchData: (id: number, action: string) => Promise<void>;
}

export class ParamsProfitShareRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IParamsProfitShareScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private $timeout: ng.ITimeoutService;
    private ModalService: IModalService;
    private selectedProducts: string[];
    private gridName: string;
    private SSEService: SSEService;
    private isCopy: boolean;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IParamsProfitShareScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.selectedProducts = [];
        this.gridName = 'GRID_PARAMS_PROFIT_SHARE';
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.helperService = $injector.get('HelperService');
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();

            this.$scope.currencyList = [];

            this.initForm(this, 'form', 'paramsProfitShare', 'FINANCIAL.PROFIT_SHARE', true);
            await this.initGrid(this.gridName, '/paramsProfitShare/list', true, true, null, true, true);
            this.SSEService.closeEvents();
            this.setCopy(false);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initDependencies(): Promise<any> {
        const self: ParamsProfitShareRegisterController = this;

        this.initDateFields();
        this.$scope.hierarchyList = [1, 2, 3, 4];

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getProductList(),
                self.getTypeCargoList(),
                self.getForwardedByList(),
                self.getEventList(),
                self.getFeetList(),
                self.getSituationList()
            ]).then(async (result: any) => {
                self.$scope.productList = result[0];
                self.$scope.typeCargoList = result[1];
                self.$scope.forwardedByList = result[2];
                self.$scope.eventList = self.filterEventList(result[3]);
                self.$scope.feetList = result[4];
                self.$scope.situationList = result[5];

                if (self.$scope.productList && self.$scope.productList.length) self.$scope.productList = self.$scope.productList.filter(product => product.ID != 'IR' && product.ID != 'ER');
                if (!self.$scope.model.RULES || self.$scope.model.RULES.length == 0) self.addRule();

                if (self.$scope.eventList && self.$scope.eventList.length > 0 && !self.$scope.model.VALIDITY_EVENT) {
                    self.$scope.model.VALIDITY_EVENT = self.$scope.eventList.find(event => event.ID == '4');
                }
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editParamsProfitShare = async (paramsProfitShare: IParamsProfitShareModel): Promise<void> => {
            let blockedObject = {
                ID: paramsProfitShare.ID,
                NAME: paramsProfitShare.CONCATENATED,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || paramsProfitShare.ID !== this.$scope.model.ID) this.$scope.fetchData(paramsProfitShare.ID, EOperation.VIEW);
                } else if (this.$scope.operation !== EOperation.EDIT || paramsProfitShare.ID !== this.$scope.model.ID) {
                    this.$scope.fetchData(paramsProfitShare.ID, EOperation.EDIT);
                }
            };
        }

        this.$scope.viewParamsProfitShare = async (paramsProfitShare: IParamsProfitShareModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(paramsProfitShare.ID, EOperation.VIEW);
        }

        this.$scope.viewLogParamsProfitShare = async (paramsProfitShare: IParamsProfitShareModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(paramsProfitShare);
        }

        this.$scope.copyParamsProfitShare = async (paramsProfitShare: IParamsProfitShareModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(paramsProfitShare.ID, EOperation.COPY);
        }

        this.$scope.getCurrencyListByName = async (search: string): Promise<void> => {
            return await this.getCurrencyListByName(search);
        }

        this.$scope.getChargeNameListByName = async (search: string): Promise<void> => {
            return await this.getChargeNameListByName(search);
        }

        this.$scope.getChargeNameListForComposition = async (search: string): Promise<void> => {
            return await this.getChargeNameListForComposition(search);
        }

        this.$scope.getNetworkListByName = async (search: string): Promise<void> => {
            return await this.getNetworkListByName(search);
        }

        this.$scope.getAgentListByName = async (search: string): Promise<void> => {
            return await this.getAgentListByName(search);
        }

        this.$scope.getAccountListByName = async (search: string): Promise<void> => {
            return await this.getAccountListByName(search);
        }

        this.$scope.getCountryList = async (search: string): Promise<void> => {
            return await this.getCountryList(search);
        }

        this.$scope.getRoutingPointListByName = async (search: string): Promise<void> => {
            return await this.getRoutingPointListByName(search);
        }

        this.$scope.getEquipmentList = async (search: string): Promise<void> => {
            return await this.getEquipmentList(search);
        }

        this.$scope.getApplicationListByName = async (index: number, search: string): Promise<void> => {
            return await this.getApplicationListByName(index, search);
        }

        this.$scope.addOriginDestination = (): void => {
            this.addOriginDestination();
        }

        this.$scope.removeOriginDestination = (index: number): void => {
            this.removeOriginDestination(index);
        }

        this.$scope.addRule = (): void => {
            this.addRule();
        }

        this.$scope.removeRule = (index: number): void => {
            this.removeRule(index);
        }

        this.$scope.duplicateRule = (index: number): void => {
            this.duplicateRule(index);
        }

        this.$scope.addProfitShare = (ruleIndex: number): void => {
            this.addProfitShare(ruleIndex);
        }

        this.$scope.removeProfitShare = (ruleIndex: number, profitShareIndex: number): void => {
            this.removeProfitShare(ruleIndex, profitShareIndex);
        }

        this.$scope.duplicateProfitShare = (ruleIndex: number, profitShareIndex: number): void => {
            this.duplicateProfitShare(ruleIndex, profitShareIndex);
        }

        this.$scope.addProfitShareEquipType = (ruleIndex: number, profitShareIndex: number): void => {
            this.addProfitShareEquipType(ruleIndex, profitShareIndex);
        }

        this.$scope.removeProfitShareEquipType = (ruleIndex: number, profitShareIndex: number, equipTypeIndex: number): void => {
            this.removeProfitShareEquipType(ruleIndex, profitShareIndex, equipTypeIndex);
        }

        this.$scope.changeCurrency = (index: number): void => {
            this.changeCurrency(index);
        }

        this.$scope.changeApplication = (ruleIndex: number, profitShareIndex: number): void => {
            this.changeApplication(ruleIndex, profitShareIndex);
        }

        this.$scope.changeEquipType = (ruleIndex: number, profitShareIndex: number, equipTypeIndex: number): void => {
            this.changeEquipType(ruleIndex, profitShareIndex, equipTypeIndex);
        }

        this.$scope.changeFeet = (ruleIndex: number, profitShareIndex: number, equipTypeIndex: number): void => {
            this.changeFeet(ruleIndex, profitShareIndex, equipTypeIndex);
        }

        this.$scope.updateProduct = (): void => {
            this.updateProduct();
        }

        this.$scope.updateRuleCompanyPercent = (ruleIndex: number): void => {
            this.updateRuleCompanyPercent(ruleIndex);
        }

        this.$scope.formatedScoreDate = (): string => {
            return this.formatedScoreDate();
        }

        this.$scope.compositionChange = (): void => {
            if (!this.$scope.model.COMPOSITION || (this.$scope.model.COMPOSITION && this.$scope.model.COMPOSITION.length == 0 || this.$scope.model.COMPOSITION.findIndex(composition => composition.ID == this.$scope.model.PROFIT_SHARE_CURRENCY_LINK.ID) < 0)) this.$scope.model.PROFIT_SHARE_CURRENCY_LINK = null;
        }

        this.$scope.productListFilter = (): Function => {
            return this.productListFilter();
        }

        this.$scope.hierarchyFilter = (ruleIndex: number): Function => {
            return this.hierarchyFilter(ruleIndex);
        }

        this.$scope.equipTypeFilter = (ruleIndex: number, profitShareIndex: number): Function => {
            return this.equipTypeFilter(ruleIndex, profitShareIndex);
        }

        this.$scope.equipFeetFilter = (ruleIndex: number, profitShareIndex: number): Function => {
            return this.equipFeetFilter(ruleIndex, profitShareIndex);
        }

        this.$scope.forwardedByFilter = (forwardedBy: SelectorModel): boolean => {
            const selectedProfitShareIds: string[] = [];
            for (const rule of this.$scope.model.RULES) {
                for (const profitShare of rule.PROFIT_SHARES) {
                    if (profitShare.PROFIT_SHARE && profitShare.PROFIT_SHARE.ID)
                        selectedProfitShareIds.push(profitShare.PROFIT_SHARE.ID);
                }
            }
            return (
                forwardedBy &&
                forwardedBy.ID !== EForwardedById.COMPANY &&
                forwardedBy.ID !== null &&
                !selectedProfitShareIds.includes(forwardedBy.ID)
            )
        }

        this.$scope.resetApplication = () => {
            this.resetApplication();
        }

        this.$scope.isMaritimeModal = () => {
            return this.$scope.model && this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.some(product => product.ID == 'IM' || product.ID == 'EM');
        }

        this.$scope.isAirModal = () => {
            return this.$scope.model && this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.some(product => product.ID == 'IA' || product.ID == 'EA');
        }

        this.$scope.goToCurrency = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.currency", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToChargeName = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.chargeName", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToNetwork = () => {
            this.$scope.sessionService.openTab("app.registration.network", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.NETWORK, null, 'ID') });
        }

        this.$scope.goToAgent = () => {
            this.$scope.sessionService.openTab("app.registration.agent", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.LEGAL_PERSON_AGENT, null, 'ID') });
        }

        this.$scope.goToAccount = () => {
            this.$scope.sessionService.openTab("app.commercial.account", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.ACCOUNT, null, 'ID') });
        }

        this.$scope.goToApplication = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.application", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToEquipment = (equipmentType: IParamsProfitShareRulesProfitShareEquipType, param: string) => {
            if (param == "type") this.$scope.sessionService.openTab("app.registration.equipment", <ILinkParameter>{ ID: this.$scope.getCONCAT(equipmentType.TYPE, null, "ID") });
            else if (param == "feet") this.$scope.sessionService.openTab("app.registration.equipment", <IEquipmentParameter>{ 'FEET.NAME': this.$scope.getCONCAT(equipmentType.FEET, null, null) });
        }

        this.$scope.goToCountry = (originDestination: IParamsProfitShareOriginDestination, type: string) => {
            this.goToCountry(originDestination, type);
        }

        this.$scope.goToRoutingPoint = (originDestination: IParamsProfitShareOriginDestination, type: string) => {
            this.goToRoutingPoint(originDestination, type);
        }

        this.$scope.getCONCATInformation = (data: IParamsProfitShareOriginDestination[], field: string): string => {
            return this.getCONCATInformation(data, field);
        }

        this.$scope.getGridRules = (data: IParamsProfitShareRules[]): string => {
            return this.getGridRules(data);
        }

        this.$scope.getGridRulesSimple = (data: IParamsProfitShareRules[]): string => {
            return this.getGridRulesSimple(data);
        }

        this.$scope.checkDateValidity = (initialDate: Date, finalDate: Date): void => {
            this.checkDateValidity(initialDate, finalDate);
        }

        this.$scope.generateConcatenated = (): void => {
            this.generateConcatenated();
        }

        this.$scope.setCopy = (value: boolean): void => {
            this.setCopy(value);
        }

        this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
            this.fetchData(id, action);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<a ng-click="grid.appScope.viewParamsProfitShare(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true"><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editParamsProfitShare(row.entity)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true"><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogParamsProfitShare(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true"><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.setCopy(true);grid.appScope.copyParamsProfitShare(row.entity)" class="text-orange copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true"><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const colActions: IMonacoColumnDef = {
            name: "acoes", displayName: "GENERAL.ACTIONS",
            width: 105,
            cellTemplate: `<div class="text-center view-btn-action-bar"> ${view} ${edit} ${viewLog} ${copy} </div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };

        gridColumns.addColumn(colActions);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colAgreement: IMonacoColumnDef = { name: "AGREEMENT", displayName: "PRODUCT.AGREEMENT", width: 150 };
            const colConcatenated: IMonacoColumnDef = { name: "CONCATENATED", displayName: "GENERAL.CONCATENATED", width: 250 };
            const colChargeName: IMonacoColumnDef = { name: "CHARGE_NAME.NAME", displayName: "BASIC_DATA.CHARGE", width: 150 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PRODUCT, null, "ID")}}</div>' };
            const colForwarded: IMonacoColumnDef = { name: "FORWARDED.NAME", displayName: "GENERAL.FORWARDED_BY", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.FORWARDED, null, "NAME")}}</div>' };
            const colTypeCargo: IMonacoColumnDef = { name: "TYPE_CARGO.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TYPE_CARGO, null, "NAME")}}</div>' };
            const colNetwork: IMonacoColumnDef = { name: "NETWORK.NAME", displayName: "BASIC_DATA.EXTERNAL_AGENT_NETWORK", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.NETWORK, null, "NAME")}}</div>' };
            const colAgent: IMonacoColumnDef = { name: "LEGAL_PERSON_AGENT.NAME", displayName: "BASIC_DATA.OVERSEAS_AGENT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.LEGAL_PERSON_AGENT, null, "NAME")}}</div>' };
            const colAccount: IMonacoColumnDef = { name: "ACCOUNT.NAME", displayName: "BASIC_DATA.CLIENT", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNT, null, "NAME")}}</div>' };
            const colCountryOrigin: IMonacoColumnDef = { name: "ORIGIN_DESTINATION.COUNTRY_ORIGIN.CODE", displayName: "BASIC_DATA.ORIGIN_COUNTRY", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCATInformation(row.entity.ORIGIN_DESTINATION, "COUNTRY_ORIGIN")}}</div>' };
            const colCountryDestination: IMonacoColumnDef = { name: "ORIGIN_DESTINATION.COUNTRY_DESTINATION.CODE", displayName: "BASIC_DATA.DESTINATION_COUNTRY", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCATInformation(row.entity.ORIGIN_DESTINATION, "COUNTRY_DESTINATION")}}</div>' };
            const colRoutingPointOrigin: IMonacoColumnDef = { name: "ORIGIN_DESTINATION.ROUTING_POINT_ORIGIN.CODE", displayName: "BASIC_DATA.ORIGIN", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCATInformation(row.entity.ORIGIN_DESTINATION, "ROUTING_POINT_ORIGIN")}}</div>' };
            const colRoutingPointDestination: IMonacoColumnDef = { name: "ORIGIN_DESTINATION.ROUTING_POINT_DESTINATION.CODE", displayName: "BASIC_DATA.DESTINATION", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCATInformation(row.entity.ORIGIN_DESTINATION, "ROUTING_POINT_DESTINATION")}}</div>' };
            const colRules: IMonacoColumnDef = { name: "RULES", displayName: "PRODUCT.PERCENT_RULES", width: 200, cellTemplate: '<div class="grid-padding" ng-bind-html="grid.appScope.getGridRulesSimple(row.entity.RULES)"></div>' };
            const colValidityEvent: IMonacoColumnDef = { name: "VALIDITY_EVENT.NAME", displayName: "GENERAL.VALIDITY_BASIS", width: 100 };
            const colValidityStart: IMonacoColumnDef = { name: "DISPLAY_VALIDITY_START", displayName: "PRODUCT.VALIDITY_START", width: 120, cellFilter: 'date:\'dd/MM/yyyy\'' };
            const colValidityEnd: IMonacoColumnDef = { name: "DISPLAY_VALIDITY_END", displayName: "PRODUCT.VALIDITY_END", width: 120, cellFilter: 'date:\'dd/MM/yyyy\'' };
            const colComposition: IMonacoColumnDef = { name: "COMPOSITION.CODE", displayName: "GENERAL.COMPOSITION", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.COMPOSITION, null, "CODE")}}</div>' };
            const colFullRules: IMonacoColumnDef = { name: "FULL_RULES", visible: false, displayName: "PRODUCT.PROFIT_SHARE_RULES", width: 1200, cellTemplate: '<div class="grid-padding" ng-bind-html="grid.appScope.getGridRules(row.entity.RULES)"></div>' };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 80, cellFilter: "YesOrNo" };
            const colScore: IMonacoColumnDef = { name: "SCORE", displayName: "BASIC_DATA.SCORE", width: 100 };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 120, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 120, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'AGREEMENT':
                        columnDefs.push(colAgreement);
                        break;
                    case 'CONCATENATED':
                        columnDefs.push(colConcatenated);
                        break;
                    case 'CHARGE_NAME':
                        columnDefs.push(colChargeName);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'FORWARDED':
                        columnDefs.push(colForwarded);
                        break;
                    case 'TYPE_CARGO':
                        columnDefs.push(colTypeCargo);
                        break;
                    case 'NETWORK':
                        columnDefs.push(colNetwork);
                        break;
                    case 'LEGAL_PERSON_AGENT':
                        columnDefs.push(colAgent);
                        break;
                    case 'ACCOUNT':
                        columnDefs.push(colAccount);
                        break;
                    case 'ORIGIN_DESTINATION':
                        columnDefs.push(colCountryOrigin);
                        columnDefs.push(colRoutingPointOrigin);
                        columnDefs.push(colCountryDestination);
                        columnDefs.push(colRoutingPointDestination);
                        break;
                    case 'RULES':
                        columnDefs.push(colRules);
                        break;
                    case 'VALIDITY_EVENT':
                        columnDefs.push(colValidityEvent);
                        break;
                    case 'DISPLAY_VALIDITY_START':
                        columnDefs.push(colValidityStart);
                        break;
                    case 'DISPLAY_VALIDITY_END':
                        columnDefs.push(colValidityEnd);
                        break;
                    case 'COMPOSITION':
                        columnDefs.push(colComposition);
                        break;
                    case 'FULL_RULES':
                        columnDefs.push(colFullRules);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'SCORE':
                        columnDefs.push(colScore);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            AGREEMENT: null,
            CHARGE_NAME: null,
            ID_CHARGE_NAME: null,
            PRODUCT: null,
            FORWARDED: null,
            TYPE_CARGO: null,
            NETWORK: null,
            LEGAL_PERSON_AGENT: null,
            ACCOUNT: null,
            ORIGIN_DESTINATION: null,
            RULES: null,
            VALIDITY_EVENT: null,
            DISPLAY_VALIDITY_START: null,
            VALIDITY_START: null,
            DISPLAY_VALIDITY_END: null,
            VALIDITY_END: null,
            CONCATENATED: null,
            OBSERVATION: null,
            COMPOSITION: null,
            ID_PROFIT_SHARE_CURRENCY_LINK: null,
            PROFIT_SHARE_CURRENCY_LINK: null,
            ACTIVE: true,
            SITUATION: null,
            SCORE: null,
            SCORE_DATE: null,
            SCORE_ERROR: null,
            SCORE_RULE: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
        };
    }

    async register(): Promise<void> {
        try {
            this.addOriginDestination();
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            if (this.$scope.situationList && this.$scope.situationList.length > 0) {
                const releasedSituation = this.$scope.situationList.find(situation => situation.ID == "1");
                if (releasedSituation) this.$scope.model.SITUATION = releasedSituation;
            }

            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            BrowserTitle.$id = this.$scope.model.AGREEMENT;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
            this.$scope.panelTitle = this.$scope.model.CONCATENATED;
            this.initDateFields();

            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');
            this.$scope.panelTitle = this.$scope.model.CONCATENATED;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.AGREEMENT;
            const allProducts = this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.length ? this.$scope.model.PRODUCT.map(x => `${x.NAME.toUpperCase().split(' ')[0].substr(0, 1)}${x.NAME.toUpperCase().split(' ')[1].substr(0, 1)}`) : [];
            this.selectedProducts = allProducts;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            await this.clearFields(this.$scope.model, ['SCORE', 'SCORE_DATE', 'SCORE_RULE', 'SCORE_ERROR']);

            this.initDateFields();
            const allProducts = this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.length ? this.$scope.model.PRODUCT.map(x => `${x.NAME.toUpperCase().split(' ')[0].substr(0, 1)}${x.NAME.toUpperCase().split(' ')[1].substr(0, 1)}`) : [];
            this.selectedProducts = allProducts;

            if (this.$scope.model.ORIGIN_DESTINATION && this.$scope.model.ORIGIN_DESTINATION.length > 0) {
                for (const originDestination of this.$scope.model.ORIGIN_DESTINATION) {
                    originDestination._id = null;
                    originDestination.ID = null;
                }
            }
            if (this.$scope.model.RULES && this.$scope.model.RULES.length > 0) {
                for (const rule of this.$scope.model.RULES) {
                    rule._id = null;
                    rule.ID = null;
                }
            }

            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        this.setCopy(false);
        return {
            route: `/paramsProfitShare/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
        this.setCopy(false);
    }

    async saveSuccess(returnedData: IResponse): Promise<void> {
        if (returnedData && returnedData.data && returnedData.data.result === false && returnedData.data.data) {
            this.formService.notifyInfo(returnedData.data.data);
        }
    }

    async save(): Promise<boolean> {
        this.$scope.model.SCORE = null;
        this.$scope.model.SCORE_DATE = null;
        this.$scope.model.SCORE_RULE = null;
        this.$scope.model.SCORE_ERROR = null;
        this.SSEService.closeEvents();
        this.setCopy(false);

        return true;
    }

    private setCopy(value: boolean): void {
        this.isCopy = value;
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTypeCargoList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_cargo`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getEventList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/event`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getFeetList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/equipment_size`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getSituationList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/registration_situation`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getForwardedByList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/forwarded_by`, null);
        return generic && generic.data ? generic.data : [];
    }

    private filterEventList(eventList: SelectorModel[]): SelectorModel[] {
        return eventList.filter(event => event.ID == '4' || event.ID == '7');
    }

    private async getChargeNameListForComposition(search: string, findAll: boolean = false): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 3 || findAll) {
                this.formService.block();
                if (!this.$scope.model.PRODUCT || this.$scope.model.PRODUCT.length == 0) {
                    if (!findAll) {
                        const msg = this.formService.getTranslate('PRODUCT.SELECT_PRODUCT_FIRST')
                        this.$formService.notifyError(msg);
                    }
                }
                else {
                    const products = this.$scope.model.PRODUCT.map(product => { return product.ID });
                    const chargeNames = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/chargeName/list/custom/`, { agentComission: true, products }, 30000, false);
                    result = chargeNames ? chargeNames.map(register => { return { ID: register.ID, NAME: register.NAME, CODE: register.CODE, CHARGE_NAME_EXHIBITION_DEFAULT: register.CHARGE_NAME_EXHIBITION_DEFAULT } }) : [];
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.chargeNameList = result;
            this.formService.unblock();
        }
    }

    private async getChargeNameListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 3) {
                this.formService.block();
                if (!this.$scope.model.PRODUCT || this.$scope.model.PRODUCT.length == 0) {
                    const msg = this.formService.getTranslate('PRODUCT.SELECT_PRODUCT_FIRST')
                    this.$formService.notifyError(msg);
                } else if (!this.$scope.model.TYPE_CARGO || this.$scope.model.TYPE_CARGO.length == 0) {
                    const msg = this.formService.getTranslate('PRODUCT.SELECT_CARGO_TYPE_FIRST')
                    this.$formService.notifyError(msg);
                } else {
                    const chargeNames = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/chargeName/list/custom/`, { search: search, products: this.$scope.model.PRODUCT.map(product => product.ID), types: ['4'], paramTypeCargo: this.$scope.model.TYPE_CARGO.map(type => type.ID) }, 30000, false);
                    result = chargeNames ? chargeNames.map(register => { return { ID: register.ID, NAME: register.NAME, CODE: register.CODE, CHARGE_NAME_EXHIBITION_DEFAULT: register.CHARGE_NAME_EXHIBITION_DEFAULT } }) : [];
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.chargeNameBaseList = result;
            this.formService.unblock();
        }
    }

    private async getNetworkListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 3) {
                this.formService.block();

                const networks = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/network/listByLegalPersonSpecType`, { name: search, types: ['12'] }, 30000, false);
                result = networks ? networks.map(register => { return { ID: register.ID, NAME: register.NAME } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.networkList = result;
            this.formService.unblock();
        }
    }

    private async getAgentListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 3) {
                if (!this.$scope.model.PRODUCT || this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.length == 0) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                if (!this.$scope.model.NETWORK || this.$scope.model.NETWORK && this.$scope.model.NETWORK.length == 0) throw Error(this.formService.getTranslate("PRODUCT.SELECT_NETWORK_FIRST"));
                this.block();
                const products = this.$scope.model.PRODUCT.map(product => { return product.ID });
                const networks = this.$scope.model.NETWORK.map(network => { return network.ID });
                const agents = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/agent/list/custom`, { search, products, networks }, 30000, false);
                result = agents ? agents.map(i => { return { ID: i.ID, NAME: i.NAME } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.agentList = result;
            this.formService.unblock();
        }
    }

    private async getAccountListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 3) {
                this.formService.block();
                const accounts = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/account/list/custom`, { search: search }, 30000, false);
                result = accounts ? accounts.map(register => { return { ID: register.ID, NAME: register.NAME, CODE: register.LEGAL_PERSON ? register.LEGAL_PERSON.SHORT_NAME : null } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.accountList = result;
            this.formService.unblock();
        }
    }

    private async getCountryList(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const countryList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/country/list/custom`, { search }, 10000, false);
                result = countryList ? countryList.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.countryList = result;
            this.formService.unblock();
        }
    }

    private async getRoutingPointListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                const msg = this.formService.getTranslate('PRODUCT.SELECT_PRODUCT_FIRST')
                if (!this.$scope.model.PRODUCT) return this.formService.handleError(msg);
                this.formService.block();
                const types: string[] = this.$scope.model.PRODUCT.some(product => product.ID == "IM" || product.ID == "EM") ? ["2"] : this.$scope.model.PRODUCT.some(product => product.ID == "IA" || product.ID == "EA") ? ["4"] : [];
                const routingPoints = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/routingPoint/list/custom/`, { name: search, types }, 30000, false);
                result = routingPoints ? routingPoints.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, DISPLAY_NAME: x.DISPLAY_NAME } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.routingPointList = result;
            this.formService.unblock();
        }
    }

    private async getEquipmentList(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                if (!this.$scope.model.PRODUCT || this.$scope.model.PRODUCT.length == 0) {
                    const msg = this.formService.getTranslate('PRODUCT.SELECT_PRODUCT_EQUIPMENTS_FIRST')
                    this.$formService.notifyError(msg);
                }
                else {
                    this.formService.block();
                    const products = this.$scope.model.PRODUCT.map(product => { return product.ID });
                    const equipments = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/equipment/list/custom`, { search, products }, 30000, false);
                    result = equipments ? equipments.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, TEU: x.TEU, FEET: x.FEET } }) : [];
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.equipmentList = result;
            this.formService.unblock();
        }
    }

    private async getApplicationListByName(index: number, search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                if (!this.$scope.model.PRODUCT) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                if (!index && index != 0) return this.formService.handleError("index is null!");
                this.block();
                const products = this.$scope.model.PRODUCT.map(item => item.ID);
                const typeCargos = this.$scope.model.TYPE_CARGO && this.$scope.model.TYPE_CARGO.length ? this.$scope.model.TYPE_CARGO.map(item => item.ID) : [];
                const application = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/application/list/custom`, { search, products, typeCargos, percent: false }, 10000, false);
                if (application) {
                    result = application.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PERCENT: x.PERCENT, APPLICATION_COMPLEMENT: x.APPLICATION_COMPLEMENT, INTERNAL_SEQUENCE: x.INTERNAL_SEQUENCE, FREE_TYPING_AMOUNT_CHARGE: x.FREE_TYPING_AMOUNT_CHARGE, CT_WITHOUT_DOC: x.CT_WITHOUT_DOC, NAME_INTL: x.NAME_INTL } });
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            this.$scope.applicationList = result;
        }
    }

    private async getCurrencyListByName(query: string): Promise<void> {
        let result = [];
        try {
            if (query && query.length >= 2) {
                this.block();
                const currencies = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/currency/list/custom`, { name: query }, 10000, false);
                if (currencies) result = await currencies.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } });
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.$scope.currencyList = result;
            this.unblock();
        }
    }

    private addOriginDestination(): void {
        try {
            const originDestination: IParamsProfitShareOriginDestination = {
                _id: null,
                ID: null,
                ID_PARAMS_PROFIT_SHARE: null,
                COUNTRY_ORIGIN: null,
                ROUTING_POINT_ORIGIN: null,
                COUNTRY_DESTINATION: null,
                ROUTING_POINT_DESTINATION: null,
            }

            if (!this.$scope.model.ORIGIN_DESTINATION) this.$scope.model.ORIGIN_DESTINATION = [];
            this.$scope.model.ORIGIN_DESTINATION.push(angular.copy(originDestination));

            this.$timeout(() => {
                const currentIndex = this.$scope.model.ORIGIN_DESTINATION.length - 1;
                this.$scope.selectorValidity('originDestionationCountryOrigin' + currentIndex);
                this.$scope.selectorValidity('originDestionationCountryDestination' + currentIndex);
                this.$scope.selectorValidity('originDestionationRPOrigin' + currentIndex);
                this.$scope.selectorValidity('originDestionationRPDestination' + currentIndex);
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeOriginDestination(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.$scope.model.ORIGIN_DESTINATION && this.$scope.model.ORIGIN_DESTINATION.length > 0) {
                this.formService.block();
                this.$scope.model.ORIGIN_DESTINATION.splice(index, 1);
                this.formService.unblock();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private addRule(): void {
        try {
            if (!this.$scope.model.RULES) this.$scope.model.RULES = [];

            const company = this.$scope.forwardedByList ? this.$scope.forwardedByList.find(forwarded => forwarded.ID == EForwardedById.COMPANY) : null;

            const rule: IParamsProfitShareRules = {
                _id: null,
                ID: null,
                ID_CURRENCY: null,
                CURRENCY: null,
                PROFIT_LOSS_INITIAL: null,
                PROFIT_LOSS_FINAL: null,
                PROFIT_SHARES: company ? [{ HIERARCHY: 1, PROFIT_SHARE: company, MIN_PERCENT: 100, MIN_FILE: null, APPLICATION: null, EQUIP_TYPE: null }] : null
            }
            this.$scope.model.RULES.push(angular.copy(rule));

            this.$timeout(() => {
                const currentIndex = this.$scope.model.RULES.length - 1;
                this.$scope.selectorValidity('ruleCurrency' + currentIndex);
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeRule(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.$scope.model.RULES && this.$scope.model.RULES.length > 0) {
                this.formService.block();
                this.$scope.model.RULES.splice(index, 1);
                this.formService.unblock();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async duplicateRule(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            if (this.$scope.model.RULES && this.$scope.model.RULES.length > 0 && this.$scope.model.RULES[index]) {
                this.formService.block();
                const copyRule = angular.copy(this.$scope.model.RULES[index]);
                copyRule._id = null;
                this.$scope.model.RULES.push(copyRule);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private addProfitShare(ruleIndex: number): void {
        try {
            let reduction: number = 1;
            if (!ruleIndex && ruleIndex != 0) throw Error('ruleIndex is null');
            if (this.$scope.model.RULES && this.$scope.model.RULES.length && this.$scope.model.RULES[ruleIndex]) {
                if (!this.$scope.model.RULES[ruleIndex].PROFIT_SHARES) this.$scope.model.RULES[ruleIndex].PROFIT_SHARES = [];
                const company = this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.find(profitShare => profitShare.PROFIT_SHARE && profitShare.PROFIT_SHARE.ID == EForwardedById.COMPANY);
                if (company) company.HIERARCHY = null;
                const availableHierarchies = this.$scope.hierarchyList.filter(hierarchy => this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.findIndex(profitShare => profitShare.HIERARCHY == hierarchy) < 0);
                const profitShare: IParamsProfitShareRulesProfitShare = {
                    HIERARCHY: availableHierarchies[0],
                    PROFIT_SHARE: null,
                    MIN_PERCENT: null,
                    MIN_FILE: null,
                    APPLICATION: null,
                    EQUIP_TYPE: null
                }

                if (this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.length) {
                    this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.splice(this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.length - 1, 0, angular.copy(profitShare))
                    reduction = 2;
                } else {
                    this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.push(angular.copy(profitShare));
                }
                this.updateRuleCompanyHierarchy(ruleIndex);

                this.$timeout(() => {
                    const currentIndex = this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.length - reduction;
                    this.$scope.selectorValidity(`ruleProfitShareHierarchy${ruleIndex}_${currentIndex}`);
                    this.$scope.selectorValidity(`ruleProfitShare${ruleIndex}_${currentIndex}`);
                    this.$scope.selectorValidity(`ruleProfitShareApplication${ruleIndex}_${currentIndex}`);
                });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeProfitShare(ruleIndex: number, profitShareIndex: number): Promise<void> {
        try {
            if (!ruleIndex && ruleIndex != 0) throw Error('ruleIndex is null');
            if (!profitShareIndex && profitShareIndex != 0) throw Error('profitShareIndex is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.$scope.model.RULES && this.$scope.model.RULES.length && this.$scope.model.RULES[ruleIndex] &&
                this.$scope.model.RULES[ruleIndex].PROFIT_SHARES && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.length && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex]) {
                this.formService.block();
                this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.splice(profitShareIndex, 1);
                this.updateRuleCompanyHierarchy(ruleIndex);
                this.updateRuleCompanyPercent(ruleIndex);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async duplicateProfitShare(ruleIndex: number, profitShareIndex: number): Promise<void> {
        try {
            if (!ruleIndex && ruleIndex != 0) throw Error('ruleIndex is null');
            if (!profitShareIndex && profitShareIndex != 0) throw Error('profitShareIndex is null');
            if (this.$scope.model.RULES && this.$scope.model.RULES.length && this.$scope.model.RULES[ruleIndex] &&
                this.$scope.model.RULES[ruleIndex].PROFIT_SHARES && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.length && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex]) {
                this.formService.block();
                const company = this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.find(profitShare => profitShare.PROFIT_SHARE && profitShare.PROFIT_SHARE.ID == EForwardedById.COMPANY);
                if (company) company.HIERARCHY = null;
                const availableHierarchies = this.$scope.hierarchyList.filter(hierarchy => this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.findIndex(profitShare => profitShare.HIERARCHY == hierarchy) < 0);
                const profitShareCopy = angular.copy(this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex]);
                profitShareCopy.HIERARCHY = availableHierarchies && availableHierarchies.length ? availableHierarchies[0] : null;

                if (this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.length) this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.splice(this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.length - 1, 0, angular.copy(profitShareCopy));
                else this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.push(angular.copy(profitShareCopy));
                this.updateRuleCompanyHierarchy(ruleIndex);
                this.updateRuleCompanyPercent(ruleIndex);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private addProfitShareEquipType(ruleIndex: number, profitShareIndex: number): void {
        try {
            if (!ruleIndex && ruleIndex != 0) throw Error('ruleIndex is null');
            if (!profitShareIndex && profitShareIndex != 0) throw Error('profitShareIndex is null');
            if (this.$scope.model.RULES && this.$scope.model.RULES.length && this.$scope.model.RULES[ruleIndex] &&
                this.$scope.model.RULES[ruleIndex].PROFIT_SHARES && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.length && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex]) {
                if (!this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE) this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE = [];
                const equipType: IParamsProfitShareRulesProfitShareEquipType = {
                    TYPE: null,
                    FEET: null,
                    MIN_APPLICATION: null
                }
                this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE.push(angular.copy(equipType));

                this.$timeout(() => {
                    const currentIndex = this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE.length - 1;
                    this.$scope.selectorValidity(`ruleProfitShareEquipType${ruleIndex}_${profitShareIndex}_${currentIndex}`);
                    this.$scope.selectorValidity(`ruleProfitShareEquipTypeFeet${ruleIndex}_${profitShareIndex}_${currentIndex}`);
                });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeProfitShareEquipType(ruleIndex: number, profitShareIndex: number, equipTypeIndex: number): Promise<void> {
        try {
            if (!ruleIndex && ruleIndex != 0) throw Error('ruleIndex is null');
            if (!profitShareIndex && profitShareIndex != 0) throw Error('profitShareIndex is null');
            if (!equipTypeIndex && equipTypeIndex != 0) throw Error('equipTypeIndex is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.$scope.model.RULES && this.$scope.model.RULES.length && this.$scope.model.RULES[ruleIndex] &&
                this.$scope.model.RULES[ruleIndex].PROFIT_SHARES && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.length && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex] &&
                this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE.length && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex]) {
                this.formService.block();
                this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE.splice(equipTypeIndex, 1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private changeCurrency(index: number): void {
        try {
            if (!index && index != 0) throw Error('index is null');
            if (this.$scope.model.RULES && this.$scope.model.RULES.length > 0 && this.$scope.model.RULES[index] && !this.$scope.model.RULES[index].CURRENCY && this.$scope.model.RULES[index].PROFIT_SHARES && this.$scope.model.RULES[index].PROFIT_SHARES.length > 0) {
                this.$scope.model.RULES[index].PROFIT_LOSS_INITIAL = null;
                this.$scope.model.RULES[index].PROFIT_LOSS_FINAL = null;
                for (let i = 0; i < this.$scope.model.RULES[index].PROFIT_SHARES.length; i++) {
                    const profitShare = this.$scope.model.RULES[index].PROFIT_SHARES[i];
                    profitShare.MIN_FILE = null;
                    profitShare.APPLICATION = null;
                    profitShare.EQUIP_TYPE = null;
                    this.$timeout(() => { this.$scope.selectorValidity('application' + index + i); });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private changeApplication(ruleIndex: number, profitShareIndex: number): void {
        try {
            if (!ruleIndex && ruleIndex != 0) throw Error('ruleIndex is null');
            if (!profitShareIndex && profitShareIndex != 0) throw Error('profitShareIndex is null');
            if (this.$scope.model.RULES && this.$scope.model.RULES.length > 0 && this.$scope.model.RULES[ruleIndex] && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.length && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex]) {
                this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE = [];
                if (this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].APPLICATION && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].APPLICATION.APPLICATION_COMPLEMENT) {
                    this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE.push(
                        {
                            TYPE: null,
                            FEET: null,
                            MIN_APPLICATION: null
                        }
                    );
                }

            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private changeEquipType(ruleIndex: number, profitShareIndex: number, equipTypeIndex: number): void {
        try {
            if (!ruleIndex && ruleIndex != 0) throw Error('ruleIndex is null');
            if (!profitShareIndex && profitShareIndex != 0) throw Error('profitShareIndex is null');
            if (!equipTypeIndex && equipTypeIndex != 0) throw Error('equipTypeIndex is null');
            if (this.$scope.model.RULES && this.$scope.model.RULES.length && this.$scope.model.RULES[ruleIndex] &&
                this.$scope.model.RULES[ruleIndex].PROFIT_SHARES && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.length && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex] &&
                this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE.length && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex]) {
                if (this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex].TYPE && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex].TYPE.length) {
                    this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex].FEET = null;
                }
                this.$scope.selectorValidity(`ruleProfitShareEquipType${ruleIndex}_${profitShareIndex}_${equipTypeIndex}`);
                this.$scope.selectorValidity(`ruleProfitShareEquipTypeFeet${ruleIndex}_${profitShareIndex}_${equipTypeIndex}`);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private changeFeet(ruleIndex: number, profitShareIndex: number, equipTypeIndex: number): void {
        try {
            if (!ruleIndex && ruleIndex != 0) throw Error('ruleIndex is null');
            if (!profitShareIndex && profitShareIndex != 0) throw Error('profitShareIndex is null');
            if (!equipTypeIndex && equipTypeIndex != 0) throw Error('equipTypeIndex is null');
            if (this.$scope.model.RULES && this.$scope.model.RULES.length && this.$scope.model.RULES[ruleIndex] &&
                this.$scope.model.RULES[ruleIndex].PROFIT_SHARES && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.length && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex] &&
                this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE.length && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex]) {
                if (this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex].FEET && this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex].FEET.length) {
                    this.$scope.model.RULES[ruleIndex].PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex].TYPE = null;
                }
                this.$scope.selectorValidity(`ruleProfitShareEquipType${ruleIndex}_${profitShareIndex}_${equipTypeIndex}`);
                this.$scope.selectorValidity(`ruleProfitShareEquipTypeFeet${ruleIndex}_${profitShareIndex}_${equipTypeIndex}`);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private updateProduct(): void {
        try {
            this.startComposition();
            const isEmptyBefore = !this.selectedProducts || this.selectedProducts.length == 0;
            const isAirBefore = this.selectedProducts && this.selectedProducts.length && this.selectedProducts.some(product => product == "EA" || product == "IA") ? true : false;
            const isMaritimeBefore = this.selectedProducts && this.selectedProducts.length && this.selectedProducts.some(product => product == "EM" || product == "IM") ? true : false;
            const allProducts = this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.length ? this.$scope.model.PRODUCT.map(x => `${x.NAME.toUpperCase().split(' ')[0].substr(0, 1)}${x.NAME.toUpperCase().split(' ')[1].substr(0, 1)}`) : [];
            if (allProducts && allProducts.length) this.selectedProducts = allProducts;
            if (this.$scope.model.ORIGIN_DESTINATION && this.$scope.model.ORIGIN_DESTINATION.length) {
                const isAir = this.selectedProducts && this.selectedProducts.length && this.selectedProducts.some(product => product == "EA" || product == "IA") ? true : false;
                const isMaritime = this.selectedProducts && this.selectedProducts.length && this.selectedProducts.some(product => product == "EM" || product == "IM") ? true : false;
                if (!isEmptyBefore &&
                    (isAir && !isAirBefore) ||
                    (isMaritime && !isMaritimeBefore)) {
                    for (const originDestination of this.$scope.model.ORIGIN_DESTINATION) {
                        originDestination.ROUTING_POINT_ORIGIN = null;
                        originDestination.ROUTING_POINT_DESTINATION = null;
                    }
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private updateRuleCompanyHierarchy(ruleIndex: number) {
        if (this.$scope.model.RULES && this.$scope.model.RULES.length && this.$scope.model.RULES[ruleIndex]) {
            const company = this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.find(profitShare => profitShare.PROFIT_SHARE && profitShare.PROFIT_SHARE.ID == EForwardedById.COMPANY);
            if (company) {
                company.HIERARCHY = null;
                const availableHierarchies = this.$scope.hierarchyList.filter(hierarchy => this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.findIndex(profitShare => profitShare.HIERARCHY == hierarchy) < 0);
                company.HIERARCHY = availableHierarchies[0];
            }
        }
    }

    private updateRuleCompanyPercent(ruleIndex: number) {
        if (this.$scope.model.RULES && this.$scope.model.RULES.length && this.$scope.model.RULES[ruleIndex]) {
            const company = this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.find(profitShare => profitShare.PROFIT_SHARE && profitShare.PROFIT_SHARE.ID == EForwardedById.COMPANY);
            if (company) {
                let profitShareTotalPercent = 0;
                if (this.$scope.model.RULES[ruleIndex].PROFIT_SHARES) {
                    for (const profitShare of this.$scope.model.RULES[ruleIndex].PROFIT_SHARES) {
                        if (!profitShare.PROFIT_SHARE || profitShare.PROFIT_SHARE && profitShare.PROFIT_SHARE.ID != EForwardedById.COMPANY) profitShareTotalPercent += profitShare.MIN_PERCENT;
                    }
                }
                company.MIN_PERCENT = (100 - profitShareTotalPercent) < 0 ? 0 : (100 - profitShareTotalPercent);
            }
        }
    }

    private formatedScoreDate(): string {
        try {
            if (this.$scope.model.SCORE_DATE) {
                return moment(this.$scope.model.SCORE_DATE).format('DD/MM/YYYY HH:mm:ss');
            }
            return null;
        } catch (ex) {
            this.handleError(ex);
            return null;
        }
    }

    private productListFilter = (): Function => {
        const selectedProduct = this.$scope.model ? this.$scope.model.PRODUCT : null;
        const isAir = selectedProduct && selectedProduct.length && (selectedProduct[0].ID == "EA" || selectedProduct[0].ID == "IA");
        const isMaritime = selectedProduct && selectedProduct.length && (selectedProduct[0].ID == "EM" || selectedProduct[0].ID == "IM");
        const isRoad = selectedProduct && selectedProduct.length && (selectedProduct[0].ID == "ER" || selectedProduct[0].ID == "IR");
        return function (product: SelectorModel) {
            if (!selectedProduct || selectedProduct && selectedProduct.length == 0) return true;
            if (isAir && (product.ID == "EA" || product.ID == "IA")) return true;
            if (isMaritime && (product.ID == "EM" || product.ID == "IM")) return true;
            if (isRoad && (product.ID == "ER" || product.ID == "IR")) return true;
            return false;
        }
    }

    private hierarchyFilter = (ruleIndex: number): Function => {
        if (!ruleIndex && ruleIndex != 0) throw Error("index is null!");
        const rule = this.$scope.model.RULES[ruleIndex];
        return function (hierarchy: number) {
            return rule && (!rule.PROFIT_SHARES || (rule.PROFIT_SHARES && !rule.PROFIT_SHARES.some(profitShare => profitShare.HIERARCHY == hierarchy)));
        }
    }

    private equipTypeFilter = (ruleIndex: number, profitShareIndex: number): Function => {
        if (!ruleIndex && ruleIndex != 0) throw Error("ruleIndex is null!");
        if (!profitShareIndex && profitShareIndex != 0) throw Error("profitShareIndex is null!");
        const rule = this.$scope.model.RULES[ruleIndex];
        const profitShare = rule.PROFIT_SHARES[profitShareIndex];
        return function (equipType: SelectorModel) {
            return profitShare && (!profitShare.EQUIP_TYPE || (profitShare.EQUIP_TYPE && (!profitShare.EQUIP_TYPE.some(profitShareEquipType => profitShareEquipType.TYPE && profitShareEquipType.TYPE.some(feet => feet.ID == equipType.ID)))));
        }
    }

    private equipFeetFilter = (ruleIndex: number, profitShareIndex: number): Function => {
        if (!ruleIndex && ruleIndex != 0) throw Error("ruleIndex is null!");
        if (!profitShareIndex && profitShareIndex != 0) throw Error("profitShareIndex is null!");
        const rule = this.$scope.model.RULES[ruleIndex];
        const profitShare = rule.PROFIT_SHARES[profitShareIndex];
        return function (equipFeet: SelectorModel) {
            return profitShare && (!profitShare.EQUIP_TYPE || (profitShare.EQUIP_TYPE && (!profitShare.EQUIP_TYPE.some(profitShareEquipType => profitShareEquipType.FEET && profitShareEquipType.FEET.some(feet => feet.ID == equipFeet.ID)))));
        }
    }

    private resetApplication() {
        if (this.$scope.model.RULES && this.$scope.model.RULES.length) {
            for (const rule of this.$scope.model.RULES) {
                if (rule.PROFIT_SHARES && rule.PROFIT_SHARES.length) {
                    for (const profitShare of rule.PROFIT_SHARES) {
                        profitShare.APPLICATION = null;
                    }
                }
            }
        }
    }

    private async startComposition(): Promise<void> {
        try {
            const compositionIdsBefore = this.$scope.model.COMPOSITION ? this.$scope.model.COMPOSITION.map(composition => composition.ID) : null;
            await this.getChargeNameListForComposition(null, true);
            this.$scope.model.COMPOSITION = this.$scope.chargeNameList;
            const compositionIdsAfter = this.$scope.model.COMPOSITION ? this.$scope.model.COMPOSITION.map(composition => composition.ID) : null;
            if (!angular.equals(compositionIdsBefore, compositionIdsAfter)) this.$formService.notifyInfo("Field composition has been updated!");
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getCONCATInformation(data: IParamsProfitShareOriginDestination[], field: string): string {
        let concatData = '';
        try {
            if (data && data.length) {
                concatData = data.map(item => {
                    if (item && item[field] && item[field].length) {
                        let subConcatData = '';
                        subConcatData = item[field].map(value => {
                            return (value && value.CODE) ? value.CODE : '';
                        }).join('; ');
                        return subConcatData;
                    }
                }).join('; ');
            }

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return concatData;
        }
    }

    // This method is used for construct HTML to show data on Grid.
    private getGridRules(data: IParamsProfitShareRules[]): string {
        let result = '';
        try {
            let count = 0;
            let aux = '';
            if (data && data.length > 0) {
                for (const rule of data) {
                    // Orderby PROFIT_SHARES.HIERARCHY asc 
                    rule.PROFIT_SHARES.sort((a, b) => a.HIERARCHY > b.HIERARCHY ? 1 : -1);

                    count++;
                    result += `${aux}<b>Regra ${count}:</b> (`;
                    if (rule.CURRENCY && rule.CURRENCY.CODE) {
                        result += `<i>Moeda:</i> ${rule.CURRENCY.CODE}, `;
                    }

                    let auxProfitShare = '';
                    if (rule.PROFIT_SHARES && rule.PROFIT_SHARES.length > 0) {
                        for (const profitShare of rule.PROFIT_SHARES) {
                            if (profitShare.PROFIT_SHARE && profitShare.PROFIT_SHARE.NAME) {
                                result += `${auxProfitShare}<b>${profitShare.PROFIT_SHARE.NAME}:</b> `;
                            }
                            if (profitShare.MIN_FILE) {
                                result += `<i><u>Min. Processo</u></i>: ${profitShare.MIN_FILE}, `;
                            }
                            /*
                            if (profitShare.MIN_APPLICATION) {
                                result += `<i><u>Min. Aplicação</u></i>: ${profitShare.MIN_APPLICATION}, `;
                            }
                            */
                            if (profitShare.APPLICATION && profitShare.APPLICATION.NAME) {
                                result += `<i><u>Aplicação</u></i>: ${profitShare.APPLICATION.NAME}, `;
                            }
                            if (profitShare.EQUIP_TYPE) {
                                result += `<i><u>Tipo Equip</u></i>: ${this.$scope.getCONCAT(profitShare.EQUIP_TYPE, null, 'CODE')}, `;
                            }
                            if (profitShare.MIN_PERCENT) {
                                result += `<i><u>%</u></i>: ${profitShare.MIN_PERCENT}`;
                            }

                            auxProfitShare = ', ';
                        }
                    }
                    result += `)`;
                    aux = '; ';
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return result;
        }
    }

    // This method is used for construct HTML to show data on Grid.
    private getGridRulesSimple(data: IParamsProfitShareRules[]): string {
        let result = '';
        try {
            let aux = '';
            if (data && data.length > 0) {
                for (const rule of data) {
                    let aux2 = '';
                    result += aux;

                    if (rule.PROFIT_SHARES && rule.PROFIT_SHARES.length > 0) {
                        for (const profitShare of rule.PROFIT_SHARES) {
                            if (profitShare.MIN_PERCENT) {
                                result += `${aux2}${profitShare.MIN_PERCENT}`;
                                aux2 = '/';
                            }
                        }
                    }
                    aux = '; ';
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return result;
        }
    }

    private initDateFields(): void {
        try {
            this.$scope.model.DISPLAY_VALIDITY_START = (this.$scope.model.DISPLAY_VALIDITY_START != null) ? new Date(this.$scope.model.DISPLAY_VALIDITY_START) : null;
            this.$scope.model.VALIDITY_START = (this.$scope.model.VALIDITY_START != null) ? new Date(this.$scope.model.VALIDITY_START) : null;
            this.$scope.model.DISPLAY_VALIDITY_END = (this.$scope.model.DISPLAY_VALIDITY_END != null) ? new Date(this.$scope.model.DISPLAY_VALIDITY_END) : null;
            this.$scope.model.VALIDITY_END = (this.$scope.model.VALIDITY_END != null) ? new Date(this.$scope.model.VALIDITY_END) : null;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private goToCountry(originDestination: IParamsProfitShareOriginDestination, type: string) {
        try {
            if (!originDestination) throw Error('originDestination is null');
            if (!type) throw Error('type is null');

            if (type == 'ORIGIN') {
                this.$scope.sessionService.openTab("app.registration.country", <ILinkParameter>{ ID: this.$scope.getCONCAT(originDestination.COUNTRY_ORIGIN, null, 'ID') });
            } else if (type == 'DESTINATION') {
                this.$scope.sessionService.openTab("app.registration.country", <ILinkParameter>{ ID: this.$scope.getCONCAT(originDestination.COUNTRY_DESTINATION, null, 'ID') });
            } else {
                throw Error('type is invalid');
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private goToRoutingPoint(originDestination: IParamsProfitShareOriginDestination, type: string) {
        try {
            if (!originDestination) throw Error('originDestination is null');
            if (!type) throw Error('type is null');

            if (type == 'ORIGIN') {
                this.$scope.sessionService.openTab("app.registration.routingPoint", <ILinkParameter>{ ID: this.$scope.getCONCAT(originDestination.ROUTING_POINT_ORIGIN, null, 'ID') });
            } else if (type == 'DESTINATION') {
                this.$scope.sessionService.openTab("app.registration.routingPoint", <ILinkParameter>{ ID: this.$scope.getCONCAT(originDestination.ROUTING_POINT_DESTINATION, null, 'ID') });
            } else {
                throw Error('type is invalid');
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private checkDateValidity(initialDate: Date, finalDate: Date): void {
        let isValid = false;
        if (!initialDate || typeof initialDate == "string") return;
        if (!finalDate || typeof finalDate == "string") return;
        isValid = ValidateUtil.isValidDateRange(initialDate, finalDate);
        if (!isValid) {
            this.$scope.model.DISPLAY_VALIDITY_END = null;
        }
    }

    private generateConcatenated(): void {
        try {
            const separator = " | ";
            let concatenated = "";

            if (this.$scope.model.NETWORK && this.$scope.model.NETWORK.length) {
                const networks = this.$scope.getCONCAT(this.$scope.model.NETWORK, null, null, null, null, ',')
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, networks) : concatenated.concat(networks);
            }

            if (this.$scope.model.LEGAL_PERSON_AGENT && this.$scope.model.LEGAL_PERSON_AGENT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.LEGAL_PERSON_AGENT.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(this.$scope.model.LEGAL_PERSON_AGENT.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PRODUCT.map(obj => { return obj.ID }).join(',')) : concatenated.concat(this.$scope.model.PRODUCT.map(obj => { return obj.ID }).join(','));
            }

            if (this.$scope.model.TYPE_CARGO && this.$scope.model.TYPE_CARGO.length) {
                const typeCargos = this.$scope.getCONCAT(this.$scope.model.TYPE_CARGO);
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, typeCargos) : concatenated.concat(typeCargos);
            }

            if (this.$scope.model.ACCOUNT && this.$scope.model.ACCOUNT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.ACCOUNT.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(this.$scope.model.ACCOUNT.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.ORIGIN_DESTINATION && this.$scope.model.ORIGIN_DESTINATION.length) {
                let origin = "";
                let destination = "";
                for (const originDestination of this.$scope.model.ORIGIN_DESTINATION) {
                    if (originDestination.COUNTRY_ORIGIN) origin = origin.length ? origin.concat(",", originDestination.COUNTRY_ORIGIN.map(obj => { return obj.CODE }).join(',')) : origin.concat(originDestination.COUNTRY_ORIGIN.map(obj => { return obj.CODE }).join(','));
                    if (originDestination.ROUTING_POINT_ORIGIN) origin = origin.length ? origin.concat(",", originDestination.ROUTING_POINT_ORIGIN.map(obj => { return obj.CODE }).join(',')) : origin.concat(originDestination.ROUTING_POINT_ORIGIN.map(obj => { return obj.CODE }).join(','));
                    if (originDestination.COUNTRY_DESTINATION) destination = destination.length ? destination.concat(",", originDestination.COUNTRY_DESTINATION.map(obj => { return obj.CODE }).join(',')) : destination.concat(originDestination.COUNTRY_DESTINATION.map(obj => { return obj.CODE }).join(','));
                    if (originDestination.ROUTING_POINT_DESTINATION) destination = destination.length ? destination.concat(",", originDestination.ROUTING_POINT_DESTINATION.map(obj => { return obj.CODE }).join(',')) : destination.concat(originDestination.ROUTING_POINT_DESTINATION.map(obj => { return obj.CODE }).join(','));
                }
                if (origin && origin.length) concatenated = concatenated.length > 0 ? concatenated.concat(separator, origin) : concatenated.concat(origin);
                if (destination && destination.length) concatenated = concatenated.length > 0 ? concatenated.concat(separator, destination) : concatenated.concat(destination);
            }

            if (this.$scope.model.AGREEMENT && this.$scope.model.AGREEMENT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.AGREEMENT) : concatenated.concat(this.$scope.model.AGREEMENT);
            }

            if (this.$scope.model.DISPLAY_VALIDITY_START) {
                const validity = this.$scope.model.DISPLAY_VALIDITY_END ? moment(this.$scope.model.DISPLAY_VALIDITY_START).format('DD/MM/YY') + '-' + moment(this.$scope.model.DISPLAY_VALIDITY_END).format('DD/MM/YY') : moment(this.$scope.model.DISPLAY_VALIDITY_START).format('DD/MM/YY');
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, validity) : concatenated.concat(validity);
            }

            this.$scope.model.CONCATENATED = StringUtil.formatConcatenatedChars(concatenated);

            if (this.$scope.operation == EOperation.VIEW) this.$scope.panelTitle = this.$scope.model.CONCATENATED;
            if (this.$scope.operation == EOperation.EDIT) this.$scope.panelTitle = this.$scope.model.CONCATENATED;

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: "AGREEMENT",
                LABEL: "PRODUCT.AGREEMENT"
            },
            {
                PROPERTY: "PRODUCT",
                READ: "NAME",
                LABEL: "BASIC_DATA.PRODUCT"
            },
            {
                PROPERTY: "FORWARDED",
                READ: "NAME",
                LABEL: "GENERAL.FORWARDED_BY"
            },
            {
                PROPERTY: "TYPE_CARGO",
                READ: "NAME",
                LABEL: "BASIC_DATA.CARGO_TYPE"
            },
            {
                PROPERTY: "RULES",
                LABEL: "PRODUCT.PROFIT_SHARE_RULES"
            },
            {
                PROPERTY: "VALIDITY_EVENT",
                LABEL: "GENERAL.VALIDITY_BASIS"
            },
            {
                PROPERTY: "DISPLAY_VALIDITY_START",
                LABEL: "PRODUCT.VALIDITY_START"
            },
            {
                PROPERTY: "DISPLAY_VALIDITY_END",
                LABEL: "PRODUCT.VALIDITY_END"
            },
            {
                PROPERTY: "OBSERVATION",
                LABEL: "GENERAL.REMARKS"
            },
            {
                PROPERTY: "CURRENCY",
                LABEL: "GENERAL.CURRENCY"
            },
            {
                PROPERTY: "PROFIT_SHARES",
                LABEL: "PRODUCT.RULES"
            },
            {
                PROPERTY: "PROFIT_SHARE",
                LABEL: "PRODUCT.COMMISSIONED"
            },
            {
                PROPERTY: "MIN_FILE",
                LABEL: "FINANCIAL.FILE_MINIMUM"
            },
            {
                PROPERTY: "MIN_APPLICATION",
                LABEL: "FINANCIAL.BASIS_MIN_UNIT"
            },
            {
                PROPERTY: "APPLICATION",
                LABEL: "FINANCIAL.CHARGE_BASIS"
            },
            {
                PROPERTY: "EQUIP_TYPE",
                LABEL: "GENERAL.EQUIPMENT_TYPE"
            },
            {
                PROPERTY: "MIN_PERCENT",
                LABEL: "REGISTRATION.PERCENTAGE"
            },
            {
                PROPERTY: "CHARGE_NAME",
                LABEL: "BASIC_DATA.CHARGE"
            },
            {
                PROPERTY: "NETWORK",
                READ: 'NAME',
                LABEL: "BASIC_DATA.EXTERNAL_AGENT_NETWORK"
            },
            {
                PROPERTY: "NAME",
                LABEL: "GENERAL.NAME"
            },
            {
                PROPERTY: "ACCOUNT",
                LABEL: "BASIC_DATA.CLIENT"
            },
            {
                PROPERTY: "LEGAL_PERSON_AGENT",
                LABEL: "BASIC_DATA.OVERSEAS_AGENT"
            },
            {
                PROPERTY: "ORIGIN_DESTINATION",
                LABEL: "BASIC_DATA.ROUTING_DETAILS"
            },
            {
                PROPERTY: "COUNTRY_ORIGIN",
                LABEL: "BASIC_DATA.ORIGIN_COUNTRY"
            },
            {
                PROPERTY: "ROUTING_POINT_ORIGIN",
                LABEL: "BASIC_DATA.ORIGIN"
            },
            {
                PROPERTY: "COUNTRY_DESTINATION",
                LABEL: "BASIC_DATA.DESTINATION_COUNTRY"
            },
            {
                PROPERTY: "ROUTING_POINT_DESTINATION",
                LABEL: "BASIC_DATA.DESTINATION"
            },
            {
                PROPERTY: "COMPOSITION",
                LABEL: "GENERAL.COMPOSITION"
            },
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE"
            },
            {
                PROPERTY: "CODE",
                LABEL: "GENERAL.CODE"
            },
            {
                PROPERTY: 'ID',
                LABEL: 'REGISTRATION.IDENTIFICATION'
            },
            {
                PROPERTY: "SCORE",
                LABEL: "BASIC_DATA.SCORE"
            },
            {
                PROPERTY: "SCORE_DATE",
                LABEL: "GENERAL.SCORE_CALCULATION_DATA"
            },
            {
                PROPERTY: "SCORE_ERROR",
                LABEL: "GENERAL.SCORE_CALCULATION_ERROR"
            },
            {
                PROPERTY: "PROFIT_LOSS_INITIAL",
                LABEL: "GENERAL.FROM"
            },
            {
                PROPERTY: "PROFIT_LOSS_FINAL",
                LABEL: "GENERAL.TO"
            },
            {
                PROPERTY: "SITUATION",
                LABEL: "GENERAL.SITUATION"
            },
            {
                PROPERTY: "TYPE",
                LABEL: "GENERAL.TYPE"
            },
            {
                PROPERTY: "FEET",
                LABEL: "GENERAL.SIZE"
            },
            {
                PROPERTY: "PROFIT_SHARE_CURRENCY_LINK",
                LABEL: "FINANCIALCHARGE_PROFIT_LINK_CURRENCY"
            },
            {
                PROPERTY: "HIERARCHY",
                LABEL: "REGISTRATION.HIERARCHY"
            },
            {
                PROPERTY: 'CONCATENATED',
                LABEL: 'GENERAL.CONCATENATED'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            }
        ];
        return props;
    }

    private async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/paramsProfitShare/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                if (request.data.RULES && request.data.RULES.length > 0) {
                    // Orderby ID_CURRENCY and PROFIT_LOSS_INITIAL asc
                    request.data.RULES.sort((a: IParamsProfitShareRules, b: IParamsProfitShareRules) => a.ID_CURRENCY > b.ID_CURRENCY || a.PROFIT_LOSS_INITIAL > a.PROFIT_LOSS_INITIAL ? 1 : -1);

                    for (const rule of request.data.RULES) {
                        // Orderby PROFIT_SHARES.HIERARCHY asc 
                        rule.PROFIT_SHARES.sort((a: IParamsProfitShareRulesProfitShare, b: IParamsProfitShareRulesProfitShare) => a.HIERARCHY > b.HIERARCHY ? 1 : -1);
                    }
                }
                const model = angular.copy(request.data);
                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

}
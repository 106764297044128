import * as angular from "angular";
import * as moment from "moment";
import { IColumnDef, IGridOptions } from "ui-grid";
import { fileUploader as FileUploader } from 'angular-file-upload';
import { SSEService } from '@appServices/SSEService';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog, } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IModalService } from '@services/ModalService';
import { ISessionService } from "@services/SessionService";
import { ICustomLogProperties, IViewLog } from "@models/interface/common/IViewLog";
import { ITariffFreightExchangeData } from "@models/interface/product/TariffFreightModel";
import { INewFreightContract } from "@models/interface/product/NewFreightContract";
import { IUploader, IUploadItem, IFileItem, IFormData, ISuccessItemResponse } from "@models/interface/common/IMonacoUpload";
import { IEquipmentSelector } from "@models/interface/product/EquipmentModel";
import { IProviderSelector } from "@models/interface/product/ProviderModel";
import { EPaymentNatureId, EOperation, ETariffFreightPerDiemType, ETariffTypeDetDem, ESituationId, EEventType } from "@enums/GenericData";
import { EProductId, EProviderTypeId, ECargoTypeId } from "@enums/GenericData";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { SelectorModel } from "../../common/model/SelectorModel";
import { ILinkParameter, ILinkFreightRoutes, ILinkFreightRoutesParameter } from "../../common/model/ModelParameter";
import { StringUtil } from "../../common/util/StringUtil";
import { IFloatingMenu } from "../../common/interface/IFloatingMenu";
import { ValidateUtil } from "../../common/util/ValidateUtil";
import { IEquipmentFreeTime } from "WBA-Model/dist/interface/product/TariffFreight";
import { ISelectorModel } from "WBA-Model/dist/mongo/SelectorModel";
import { HelperService } from "@services/HelperService";
import { ProductService } from "@services/ProductService";
import { ITablePerDiemQueryFields } from "WBA-Model/dist/interface/product/TablePerDiem";
import { ICommodity } from "WBA-Model/dist/interface/product/NewFreightContract";
import { ILegalPersonListCustomFilter, ILegalPersonModelListCustom } from "../model/LegalPersonModel";
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { BrowserTitle } from "../../common/BrowserTitle";
import { IMonacoRequest } from "@services/GridFormService";
import { IFreightWizard } from "WBA-Model/dist/interface/product/FreightWizard";

interface ITariffFreightScope extends IGridFormServiceScope {
    form: ng.IFormController;
    gridOptions: IGridOptions;
    model: INewFreightContract;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: INewFreightContract;
    menuFloating: IFloatingMenu;
    paymentNatureList: SelectorModel[];
    contractTypeList: SelectorModel[];
    productList: SelectorModel[];
    cargoTypeList: SelectorModel[];
    providerList: IProviderSelector[];
    networkList: SelectorModel[];
    accountList: SelectorModel[];
    exporterList: SelectorModel[];
    importerList: SelectorModel[];
    eventList: SelectorModel[];
    situationList: SelectorModel[];
    hsCodeSectionList: SelectorModel[];
    commodityList: SelectorModel[];
    uploader: IUploader;
    user: any;
    sessionService: ISessionService;
    isAirProduct: boolean;
    isMaritimeProduct: boolean;
    detDemTypeList: SelectorModel[];
    tablePerDiemList: SelectorModel[];
    equipmentFreeTimeList: SelectorModel[];
    perDiemTypeList: SelectorModel[];
    freeTimeTypeList: SelectorModel[];
    hasDetDemOrigin: boolean;
    hasDetDemDestination: boolean;
    editFreightContract: (freightContract: INewFreightContract, idFreightContract?: number) => Promise<void>;
    viewFreightContract: (freightContract: INewFreightContract, idFreightContract?: number) => Promise<void>;
    viewLogFreightContract: (freightContract: INewFreightContract) => Promise<void>;
    openFreightWizardModal: (freightContract: INewFreightContract) => void;
    addFreightRoute: (freightContract: INewFreightContract) => void;
    getGridViewProvider: (provider: IProviderSelector) => string;
    getProviderListByName: (search: string) => Promise<void>;
    getNetworkListByName: (search: string) => Promise<void>;
    getAccountListByName: (search: string) => Promise<void>;
    getExporterListByName: (search: string) => Promise<void>;
    getImporterListByName: (search: string) => Promise<void>;
    getCommodityListByName: (search: string) => Promise<void>;
    getEquipmentListForDetDem: (search: string) => Promise<void>;
    getTablePerDiemList: (direction: string, tariffTypeId: string) => Promise<void>;
    addCommodity: () => void;
    hideRemove: () => boolean;
    removeCommodity: (index: number) => void;
    orderCommodityByException: () => void;
    commodityChange: (commodity: ICommodity) => void;
    transactionChange: () => void;
    limitLines: (value: string, limit: number) => string;
    formatConcatenatedChars: (value: string) => string;
    removeUpload: (model: IUploadItem) => Promise<boolean>;
    updateProduct: (selected: SelectorModel) => void;
    copyTariffFreightContract: (freightContract: INewFreightContract) => Promise<void>;
    goToProvider: () => void;
    goToNetwork: () => void;
    goToAccount: () => void;
    goToTablePerDiem: (tablePerDiemId: number) => void;
    goToLegalPerson: (ids: string) => void;
    goToCommodity: (id: number) => void;

    isCommodityDisabled: (commodity: ICommodity, fieldName: string) => boolean;
    updateProvider: () => void;
    collapseHeader: (elementId: string, state?: string, navigate?: boolean) => void;
    setCopy: (value: boolean) => void;
    checkDateValidity: (initialDate: Date, finalDate: Date) => void;
    initDetDem: () => void;
    detDemOriginChange: () => void;
    detDemDestinationChange: () => void;
    generateConcatenated: () => void;
    addEquipmentFreeTime: (originDestination: number) => void;
    checkFreeTimeSplittedDetention: (transactionId: string, originDestination: number, index: number) => void;
    checkFreeTimeSplittedDemurrage: (transactionId: string, originDestination: number, index: number) => void;
    checkFreeTimeCombined: (transactionId: string, originDestination: number, index: number) => void;
    checkPerDiemTypeChange: (originDestination: number) => void;
    initFreeTimeTable: (originDestination: number) => void;
    checkReadOnly: (originDestination: number, perDiemTypeId: string) => boolean;
    removeDetDemEquipment: (index: number, property: string) => void;
    goToNewFreightContract: () => void;
}

interface IResponseData {
    result: boolean;
    data: string;
}

interface IResponse {
    data: IResponseData;
    message: string;
    reason: string;
    status: number;
}

export class NewFreightContractRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: ITariffFreightScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private $timeout: ng.ITimeoutService
    private ModalService: IModalService;
    private collapseState: string;
    private fileUploader: FileUploader;
    private formName: string;
    private gridName: string;
    private isCopy: boolean;
    private SSEService: SSEService;
    private helperService: HelperService;
    private productService: ProductService;

    constructor($injector: ng.Injectable<any>, $scope: ITariffFreightScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.fileUploader = $injector.get('FileUploader');
        this.formName = 'newFreightContract';
        this.gridName = 'GRID_NEW_TARIFF_FREIGHT'
        this.collapseState = "hide";
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.helperService = $injector.get('HelperService');
        this.productService = $injector.get('ProductService');
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    getFisFilesGenericRoute(): string {
        const baseRoute = '/fis/filesGeneric';
        const uploadRoute = this.config.fisUrl + baseRoute;
        return uploadRoute;
    }

    async $onInit(): Promise<void> {
        try {
            this.$scope.uploader = this.getFileUploaderDefault();
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.setCopy(false);
            this.$scope.hasDetDemOrigin = false;
            this.$scope.hasDetDemDestination = false;

            this.initForm(this, 'form', this.formName, 'GENERAL.MENU.FREIGHT_CONTRACT', true);
            await this.initGrid(this.gridName, '/newFreightContract/list', true, true, null, true, true);
            const sessionParameter = this.$gridService.$sessionParameter;
            this.SSEService.closeEvents();
            if (sessionParameter && sessionParameter.data) await this.callSessionFunctions(sessionParameter.data);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initDependencies(): Promise<any> {
        const self: NewFreightContractRegisterController = this;
        this.initDateFields();

        const promises = {
            payment_nature: self.getGenericList('payment_nature'),
            product: self.getGenericList('product'),
            type_cargo: self.getGenericList('type_cargo'),
            contract_type: self.getGenericList('contract_type'),
            event: self.getGenericList('event'),
            registration_situation: self.getGenericList('registration_situation'),
            hscode_section: self.getGenericList('hscode_section'),
            tariff_freight_per_diem_type: self.getGenericList('tariff_freight_per_diem_type'),
            dd_type_tariff: self.getGenericList('dd_type_tariff'),
            tariff_freight_free_time_type: self.getGenericList('tariff_freight_free_time_type'),
        };

        return new Promise((resolve, reject) => {
            self.$q.all(promises).then((result) => {
                self.$scope.paymentNatureList = result.payment_nature;
                self.$scope.productList = result.product;
                self.$scope.cargoTypeList = result.type_cargo;
                self.$scope.contractTypeList = result.contract_type;
                self.$scope.eventList = result.event;
                self.$scope.situationList = result.registration_situation;
                self.$scope.hsCodeSectionList = result.hscode_section;
                self.$scope.commodityList = result.hscode_section;
                self.$scope.detDemTypeList = result.dd_type_tariff;
                self.$scope.perDiemTypeList = result.tariff_freight_per_diem_type;
                self.$scope.freeTimeTypeList = result.tariff_freight_free_time_type;
                this.getDefaultSituation(self)
                this.getDefaultValidityBasis(self)

                resolve(true);
            }).catch((ex) => {
                reject(ex);
            });
        });
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editFreightContract = async (freightContract: INewFreightContract): Promise<void> => {
            let blockedObject = {
                ID: freightContract.ID,
                NAME: freightContract.CONCATENATED && freightContract.CONCATENATED !== '' ? freightContract.CONCATENATED : this.gridName + " - " + freightContract.ID,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || freightContract.ID !== this.$scope.model.ID) this.fetchData(freightContract.ID, EOperation.VIEW);
                } else if (this.$scope.operation !== EOperation.EDIT || freightContract.ID !== this.$scope.model.ID) {
                    this.fetchData(freightContract.ID, EOperation.EDIT);
                }
            };
        }

        this.$scope.viewFreightContract = async (freightContract: INewFreightContract): Promise<void> => {
            this.SSEService.closeEvents();
            this.fetchData(freightContract.ID, EOperation.VIEW);
        }

        this.$scope.viewLogFreightContract = async (freightContract: INewFreightContract): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(freightContract);
        }

        this.$scope.copyTariffFreightContract = async (freightContract: INewFreightContract): Promise<void> => {
            this.SSEService.closeEvents();
            this.fetchData(freightContract.ID, EOperation.COPY);
        }

        this.$scope.addFreightRoute = (freightContract: INewFreightContract): void => {
            this.$scope.sessionService.openTab('app.product.freightRoutes', null, <ILinkFreightRoutesParameter>{ OPERATION: EOperation.NEW, ID: (freightContract.ID) ? freightContract.ID.toString() : null, ALLOG_FREIGHT_CONTRACT: (freightContract.ALLOG_FREIGHT_CONTRACT) ? freightContract.ALLOG_FREIGHT_CONTRACT : null });
        }

        this.$scope.openFreightWizardModal = (freightContract: INewFreightContract): void => {
            this.openFreightWizardModal(freightContract);
        }

        this.$scope.addCommodity = (): void => {
            this.addCommodity();
        }

        this.$scope.hideRemove = (): boolean => {
            return this.hideRemove();
        }

        this.$scope.removeCommodity = (index: number): void => {
            this.removeCommodity(index);
        }

        this.$scope.orderCommodityByException = (): void => {
            this.orderCommodityByException();
        }

        this.$scope.commodityChange = (commodity: ICommodity) => {
            this.commodityChange(commodity);
        }

        this.$scope.transactionChange = (): void => {
            this.transactionChange();
        }

        this.$scope.getProviderListByName = async (search: string): Promise<void> => {
            await this.getProviderListByName(search);
        }

        this.$scope.getNetworkListByName = async (search: string): Promise<void> => {
            await this.getNetworkListByName(search);
        }

        this.$scope.getAccountListByName = async (search: string): Promise<void> => {
            await this.getAccountListByName(search);
        }
        this.$scope.getExporterListByName = async (search: string): Promise<void> => {
            await this.getExporterListByName(search);
        }
        this.$scope.getImporterListByName = async (search: string): Promise<void> => {
            await this.getImporterListByName(search);
        }

        this.$scope.getCommodityListByName = async (search: string): Promise<void> => {
            await this.getCommodityListByName(search);
        }

        this.$scope.getEquipmentListForDetDem = async (search: string): Promise<void> => {
            await this.getEquipmentListForDetDem(search);
        }

        this.$scope.getTablePerDiemList = async (direction: string, tariffTypeId: string): Promise<void> => {
            await this.getTablePerDiemList(direction, tariffTypeId);
        }

        this.$scope.goToProvider = () => {
            this.$scope.sessionService.openTab("app.registration.provider", <ILinkParameter>{ ID: (this.$scope.model.PROVIDER ? this.$scope.model.PROVIDER.ID.toString() : null) });
        }

        this.$scope.goToNetwork = () => {
            this.$scope.sessionService.openTab("app.registration.network", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.EXTERNAL_AGENT_NETWORK, null, 'ID') });
        }

        this.$scope.goToAccount = () => {
            this.$scope.sessionService.openTab("app.commercial.account", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.ACCOUNT, null, 'ID') });
        }

        this.$scope.goToTablePerDiem = (tablePerDiemId: number) => {
            this.$scope.sessionService.openTab("app.product.tablePerDiem", <ILinkParameter>{ ID: tablePerDiemId.toString() });
        }

        this.$scope.goToLegalPerson = (ids: string): void => {
            this.$scope.sessionService.openTab("app.registration.legalPerson", <ILinkParameter>{ ID: ids });
        }

        this.$scope.goToCommodity = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.commodity", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.isCommodityDisabled = (commodity: ICommodity, fieldName: string): boolean => {
            return this.isCommodityDisabled(commodity, fieldName);
        }

        this.$scope.limitLines = (value: string, limit: number): string => {
            return StringUtil.limitLines(value, limit);
        }

        this.$scope.formatConcatenatedChars = (value: string) => {
            return StringUtil.formatConcatenatedChars(value);
        }

        this.$scope.removeUpload = (model: IUploadItem): Promise<boolean> => {
            return this.removeUpload(model);
        }

        this.$scope.uploader.onSuccessItem = (item: IFileItem, response: ISuccessItemResponse): void => {
            this.onSuccessItem(item, response);
        }

        this.$scope.updateProduct = (selected: SelectorModel): void => {
            this.updateProduct(selected);
        }

        this.$scope.getGridViewProvider = (provider: IProviderSelector): string => {
            let gridValue = "";
            if (provider && provider.TYPE) {
                if (provider.TYPE.ID == EProviderTypeId.SHIPOWNER || provider.TYPE.ID == EProviderTypeId.AIRLINE) gridValue = provider.CODE;
                else if (provider.TYPE.ID == EProviderTypeId.SHIPPING_COMPANY) gridValue = provider.NAME;
            }
            return gridValue;
        }

        this.$scope.updateProvider = (): void => {
            this.updateProvider();
        }

        this.$scope.collapseHeader = (elementId: string, state?: string, navigate?: boolean) => {
            this.collapseHeader(elementId, state, navigate);
        }

        this.$scope.setCopy = (value: boolean): void => {
            this.setCopy(value);
        }

        this.$scope.checkDateValidity = (initialDate: Date, finalDate: Date): void => {
            this.checkDateValidity(initialDate, finalDate);
        }

        this.$scope.addEquipmentFreeTime = (originDestination: number) => {
            this.addEquipmentFreeTime(originDestination);
        }

        this.$scope.checkFreeTimeSplittedDemurrage = (transactionId: string, originDestination: number, index: number) => {
            this.checkFreeTimeSplittedDemurrage(transactionId, originDestination, index);
        }

        this.$scope.checkFreeTimeSplittedDetention = (transactionId: string, originDestination: number, index: number) => {
            this.checkFreeTimeSplittedDetention(transactionId, originDestination, index);
        }

        this.$scope.checkFreeTimeCombined = (transactionId: string, originDestination: number, index: number) => {
            this.checkFreeTimeCombined(transactionId, originDestination, index);
        }

        this.$scope.initDetDem = () => {
            this.initDetDem();
        }

        this.$scope.detDemOriginChange = () => {
            this.detDemOriginChange();
        }

        this.$scope.detDemDestinationChange = () => {
            this.detDemDestinationChange();
        }

        this.$scope.generateConcatenated = (): void => {
            this.generateConcatenated();
        }

        this.$scope.checkPerDiemTypeChange = (originDestination: number) => {
            this.checkPerDiemTypeChange(originDestination);
        }

        this.$scope.initFreeTimeTable = (originDestination: number) => {
            this.initFreeTimeTable(originDestination);
        }

        this.$scope.checkReadOnly = (originDestination: number, perDiemTypeId: string) => {
            return this.checkReadOnly(originDestination, perDiemTypeId);
        }
        this.$scope.removeDetDemEquipment = (index: number, property: string) => {
            this.removeDetDemEquipment(index, property);
        }

        this.$scope.goToNewFreightContract = (): void => {
            this.$scope.sessionService.openTab("app.product.freightRoutes", <ILinkFreightRoutes>{ "FREIGHT_CONTRACT.ALLOG_FREIGHT_CONTRACT": this.$scope.model.ALLOG_FREIGHT_CONTRACT });
        }

    }

    initGridColumns(columns: string[]): IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<a ng-click="grid.appScope.viewFreightContract(row.entity)" class="text-info v-middle" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true"><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editFreightContract(row.entity)" class="text-especial v-middle edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true"><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogFreightContract(row.entity)" class="text-green v-middle log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true"><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.setCopy(true);grid.appScope.copyTariffFreightContract(row.entity)" class="text-orange v-middle copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true"><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const addFreightRoute = `<a ng-click="row.entity.ACTIVE && grid.appScope.addFreightRoute(row.entity)" ng-disabled="!row.entity.ACTIVE" class="text-green v-middle log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'ROUTE.ADD_ROUTE' | translate }}" tooltip-append-to-body="true"><i class="fa fa-plus icon"></i></a>&nbsp;&nbsp;`;
        const openFreightWizardModal = `<a ng-click="grid.appScope.openFreightWizardModal(row.entity)" class="text-darkslategrey v-middle log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'PRODUCT.OPEN_MASS_TARIFF_UPDATE' | translate }}" tooltip-append-to-body="true"><i class="fa fa-bolt icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes", displayName: "GENERAL.ACTIONS",
            width: 140,
            cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${viewLog} ${addFreightRoute} ${openFreightWizardModal} ${copy}</div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };

        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }
        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colTransaction: IMonacoColumnDef = { name: "TRANSACTION.NAME", displayName: "BASIC_DATA.TRANSACTION", width: 150 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 100 };
            const colCargoType: IMonacoColumnDef = { name: "CARGO_TYPE.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 150 };
            const colAllogContract: IMonacoColumnDef = { name: "ALLOG_FREIGHT_CONTRACT", displayName: "BASIC_DATA.ALLOG_FREIGHT_CONTRACT", width: 150 };
            const colContract: IMonacoColumnDef = { name: "FREIGHT_CONTRACT", displayName: "BASIC_DATA.FREIGHT_CONTRACT", width: 150 };
            const colDescription: IMonacoColumnDef = { name: "CONTRACT_DESCRIPTION", displayName: "OPERATIONAL.CONTRACT_DESCRIPTION", width: 200 };
            const colContractType: IMonacoColumnDef = { name: "FREIGHT_CONTRACT_TYPE.NAME", displayName: "PRODUCT.FREIGHT_CONTRACT_TYPE", width: 150 };
            const colProvider: IMonacoColumnDef = { name: "PROVIDER.CODE", displayName: "BASIC_DATA.PROVIDER", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getGridViewProvider(row.entity.PROVIDER)}}</div>' };
            const colNetwork: IMonacoColumnDef = { name: "EXTERNAL_AGENT_NETWORK.NAME", displayName: "BASIC_DATA.EXTERNAL_AGENT_NETWORK", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.EXTERNAL_AGENT_NETWORK, null, "NAME")}}</div>' };
            const colAccount: IMonacoColumnDef = { name: "ACCOUNT.NAME", displayName: "BASIC_DATA.CLIENT", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNT, null, "NAME")}}</div>' };
            const colExporter: IMonacoColumnDef = { name: "SHIPPER.ID", displayName: "BASIC_DATA.SHIPPER", width: 200, cellTemplate: '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.SHIPPER, null, "NAME")}}</div>' };
            const colImporter: IMonacoColumnDef = { name: "CONSIGNEE.ID", displayName: "BASIC_DATA.CONSIGNEE", width: 200, cellTemplate: '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.CONSIGNEE, null, "NAME")}}</div>' };
            const colConcatenated: IMonacoColumnDef = { name: "CONCATENATED", displayName: "GENERAL.CONCATENATED", width: 250 };
            const colConcatenatedComplement: IMonacoColumnDef = { name: "CONCATENATED_COMPLEMENT", displayName: "GENERAL.CONCATENATED_COMPLEMENT", width: 250 };
            const colCommoditySection: IMonacoColumnDef = { name: "COMMODITY.SECTION.NAME", displayName: "GENERAL.COMMODITY_GROUP_CONDITION", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.COMMODITY, "SECTION", null, false, true)}}</div>' };
            const colCommodityCondition: IMonacoColumnDef = { name: "COMMODITY.CONDITION.NAME", displayName: "GENERAL.COMMODITY_CONDITION", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.COMMODITY, "CONDITION", null, false, true)}}</div>' };
            const colCommodityException: IMonacoColumnDef = { name: "COMMODITY.EXCEPTION.NAME", displayName: "GENERAL.COMMODITY_EXCEPTION", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.COMMODITY, "EXCEPTION", null, false, true)}}</div>' };
            const colValidityBasis: IMonacoColumnDef = { name: "VALIDITY_BASIS.NAME", displayName: "GENERAL.VALIDITY_BASIS", width: 200 };
            const colValidityStart: IMonacoColumnDef = { name: "VALIDITY_START", displayName: "PRODUCT.VALIDITY_START", width: 150, cellFilter: "simpleDate" };
            const colValidityEnd: IMonacoColumnDef = { name: "VALIDITY_END", displayName: "PRODUCT.VALIDITY_END", width: 150, cellFilter: "simpleDate" };
            const colScore: IMonacoColumnDef = { name: "SCORE", displayName: "BASIC_DATA.SCORE", width: 100 };
            const colSituation: IMonacoColumnDef = { name: "SITUATION.NAME", displayName: "GENERAL.SITUATION", width: 150 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 90, cellFilter: "YesOrNo" };
            const colValidityExpired: IMonacoColumnDef = { name: "VALIDITY_EXPIRED", displayName: "GENERAL.EXPIRED", width: 100, cellFilter: "YesOrNo" };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "GENERAL.ID", width: 80 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'CONCATENATED':
                        columnDefs.push(colConcatenated);
                        break;
                    case 'TRANSACTION':
                        columnDefs.push(colTransaction);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'CARGO_TYPE':
                        columnDefs.push(colCargoType);
                        break;
                    case 'ALLOG_FREIGHT_CONTRACT':
                        columnDefs.push(colAllogContract);
                        break;
                    case 'FREIGHT_CONTRACT':
                        columnDefs.push(colContract);
                        break;
                    case 'CONTRACT_DESCRIPTION':
                        columnDefs.push(colDescription);
                        break;
                    case 'FREIGHT_CONTRACT_TYPE':
                        columnDefs.push(colContractType);
                        break;
                    case 'PROVIDER':
                        columnDefs.push(colProvider);
                        break;
                    case 'EXTERNAL_AGENT_NETWORK':
                        columnDefs.push(colNetwork);
                        break;
                    case 'ACCOUNT':
                        columnDefs.push(colAccount);
                        break;
                    case 'SHIPPER':
                        columnDefs.push(colExporter);
                        break;
                    case 'CONSIGNEE':
                        columnDefs.push(colImporter);
                        break;
                    case 'VALIDITY_BASIS':
                        columnDefs.push(colValidityBasis);
                        break;
                    case 'VALIDITY_START':
                        columnDefs.push(colValidityStart);
                        break;
                    case 'VALIDITY_END':
                        columnDefs.push(colValidityEnd);
                        break;
                    case 'CONCATENATED_COMPLEMENT':
                        columnDefs.push(colConcatenatedComplement);
                        break;
                    case 'SITUATION':
                        columnDefs.push(colSituation);
                        break;
                    case 'COMMODITY':
                        columnDefs.push(colCommoditySection);
                        columnDefs.push(colCommodityCondition);
                        columnDefs.push(colCommodityException);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'SCORE':
                        columnDefs.push(colScore);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                    case 'FREIGHT_CONTRACT':
                        columnDefs.push(colContract);
                        break;

                    case 'VALIDITY_EXPIRED':
                        columnDefs.push(colValidityExpired);
                        break;
                };
            }

            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            ACCOUNT: null,
            ACTIVE: true,
            ALLOG_FREIGHT_CONTRACT: null,
            CARGO_TYPE: null,
            COMMODITY: null,
            CONCATENATED: null,
            CONCATENATED_COMPLEMENT: null,
            CONSIGNEE: null,
            CONTRACT_DESCRIPTION: null,
            DET_DEM_DESTINATION: null,
            DET_DEM_ORIGIN: null,
            EXTERNAL_AGENT_NETWORK: null,
            FILES: null,
            FILES_NEW: null,
            FILES_REMOVE: null,
            FREIGHT_CONTRACT: null,
            FREIGHT_CONTRACT_TYPE: null,
            OBSERVATION: null,
            PRODUCT: null,
            PROVIDER: null,
            ID_PROVIDER: null,
            SHIPPER: null,
            SITUATION: null,
            TRANSACTION: null,
            VALIDITY_BASIS: null,
            VALIDITY_END: null,
            VALIDITY_START: null,
            SCORE: null,
            SCORE_RULE: null,
            SCORE_DATE: null,
            SCORE_ERROR: null,
            DOCUMENT_ERROR: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
        };
    }

    async saveSuccess(returnedData: IResponse): Promise<void> {
        if (returnedData && returnedData.data && returnedData.data.result === false && returnedData.data.data) {
            this.formService.notifyInfo(returnedData.data.data);
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.disableElements(false);
            this.initPanels();
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        let proceed: boolean = true;
        try {
            if (this.$scope.uploader.queue && this.$scope.uploader.queue.length > 0) {
                proceed = false;
                this.handleError('GENERAL.FILES_IN_THE_QUEUE');
            }

            proceed = !this.hasHiddenPendingFields();

            if (proceed) {
                this.SSEService.closeEvents();
            }
            this.setCopy(false);
        } catch (ex) {
            this.handleError(ex);
            proceed = false;
        }
        return proceed;
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")}`;
            BrowserTitle.$id = this.$scope.model.CONCATENATED;
            await this.updateControl();
            this.initDateFields();
            this.$scope.disableElements(true);
            this.orderCommodityByException();
            if (this.$scope.model.CONCATENATED) this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
            this.$scope.hasDetDemOrigin = this.$scope.model.DET_DEM_ORIGIN ? true : false;
            this.$scope.hasDetDemDestination = this.$scope.model.DET_DEM_DESTINATION ? true : false;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")}`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.CONCATENATED;
            this.$scope.disableElements(false);
            await this.updateControl();
            this.orderCommodityByException();
            if (this.$scope.model.CONCATENATED) this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
            this.$scope.hasDetDemOrigin = this.$scope.model.DET_DEM_ORIGIN ? true : false;
            this.$scope.hasDetDemDestination = this.$scope.model.DET_DEM_DESTINATION ? true : false;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            await this.clearFields(this.$scope.model, ['CONCATENATED', 'SCORE', 'SCORE_DATE', 'SCORE_RULE', 'SCORE_ERROR']);
            this.$scope.model.DET_DEM_ORIGIN = null;
            this.$scope.hasDetDemOrigin = false
            this.$scope.model.DET_DEM_DESTINATION = null;
            this.$scope.hasDetDemDestination = false
            if (this.$scope.model.COMMODITY && this.$scope.model.COMMODITY.length > 0) {
                for (const commodity of this.$scope.model.COMMODITY) {
                    commodity.ID = null;
                }
            }
            this.$scope.model.FILES = null;
            this.$scope.model.FILES_NEW = null;
            this.$scope.model.FILES_REMOVE = null;
            this.$scope.model.ALLOG_FREIGHT_CONTRACT = null;
            await this.updateControl();
            this.initDateFields();
            this.initPanels();
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }


    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
        this.setCopy(false);
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        this.setCopy(false);
        return {
            route: `/newFreightContract/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 30000
        };
    }

    private hasHiddenPendingFields(): boolean {

        let hasPendingFields = false;

        if (this.$scope.model.COMMODITY && this.$scope.model.COMMODITY.length > 0) {
            hasPendingFields = this.$scope.model.COMMODITY.some(commodity => {
                return !commodity.SECTION && !commodity.CONDITION && !commodity.EXCEPTION
            });
            if (hasPendingFields) this.formService.notifyError(this.formService.getTranslate('PRODUCT.ENTER_CONDITION_OR_EXCEPTION_COMMODITY'));
        }
        hasPendingFields = this.checkRulesForDetDem();

        return hasPendingFields;
    }

    private checkRulesForDetDem(): boolean {
        let hasPendingFields: boolean;

        // Find equipments from D&D on routes.
        let equipOnDetDemOrigin: ISelectorModel[] = [];
        let equipOnDetDemDestination: ISelectorModel[] = [];

        if (this.$scope.model.DET_DEM_ORIGIN && this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME && this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME.length > 0) {
            this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME.forEach(equipFreeTime => {
                if (equipFreeTime && equipFreeTime.EQUIPMENT.length > 0) {
                    equipFreeTime.EQUIPMENT.forEach(equip => {
                        const equipItem: ISelectorModel = {
                            ID: equip.ID,
                            NAME: equip.NAME,
                        };
                        equipOnDetDemOrigin.push(equipItem);
                    });
                }
            });
        }

        if (this.$scope.model.DET_DEM_DESTINATION && this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME && this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME.length > 0) {
            this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME.forEach(equipFreeTime => {
                if (equipFreeTime && equipFreeTime.EQUIPMENT.length > 0) {
                    equipFreeTime.EQUIPMENT.forEach(equip => {
                        const equipItem: ISelectorModel = {
                            ID: equip.ID,
                            NAME: equip.NAME,
                        };
                        equipOnDetDemDestination.push(equipItem);
                    });
                }
            });
        }

        // Validations for DetDem based on selected product.
        if (this.$scope.model.TRANSACTION.ID == EPaymentNatureId.PAYMENT && this.$scope.model.PRODUCT.ID == EProductId.MARITIME_IMPORT && this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.FCL) {

            if (!this.$scope.model.DET_DEM_DESTINATION) {
                hasPendingFields = true;
                const msg = "Please inform Detention & Demurrage for Destination.";
                this.formService.notifyError(msg);
            }

            if (this.$scope.model.DET_DEM_DESTINATION && (!this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME || this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME.length == 0)) {
                hasPendingFields = true;
                const msg = "Please inform at least one equipment for Detention & Demurrage at Destination panel.";
                this.formService.notifyError(msg);
            }
        }

        if (this.$scope.model.TRANSACTION.ID == EPaymentNatureId.PAYMENT && this.$scope.model.PRODUCT.ID == EProductId.MARITIME_EXPORT && this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.FCL) {
            if (!this.$scope.model.DET_DEM_ORIGIN) {
                hasPendingFields = true;
                const msg = "Please inform Detention & Demurrage for Origin.";
                this.formService.notifyError(msg);
            }

            if (this.$scope.model.DET_DEM_ORIGIN && (!this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME || this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME.length == 0)) {
                hasPendingFields = true;
                const msg = "Please inform at least one equipment for Detention & Demurrage at Origin panel.";
                this.formService.notifyError(msg);
            }
        }
        return hasPendingFields;
    }

    private initDetDem() {
        if (this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.FCL) {
            let errorMessage: string = '';
            if (!this.$scope.model.PRODUCT || !this.$scope.model.PRODUCT.ID) {
                errorMessage += (errorMessage ? ', ' : '') + 'product';
            }
            if (!this.$scope.model.TRANSACTION || !this.$scope.model.TRANSACTION.ID) {
                errorMessage += (errorMessage ? ', ' : '') + 'payment nature';
            }
            if (errorMessage) {
                this.$scope.model.CARGO_TYPE = null;
                return this.formService.notifyError(`Select ${errorMessage} first`);
            }

            if (this.$scope.model.PRODUCT.ID == EProductId.MARITIME_IMPORT) {
                this.$scope.hasDetDemDestination = true;
                this.$scope.model.DET_DEM_DESTINATION = null;
                this.detDemDestinationChange();
            }

            if (this.$scope.model.PRODUCT.ID == EProductId.MARITIME_EXPORT) {
                this.$scope.hasDetDemOrigin = true;
                this.$scope.model.DET_DEM_ORIGIN = null;
                this.detDemOriginChange();
            }

            this.collapseHeader("collapseDetDem", "show", false);
        } else {
            this.$scope.hasDetDemOrigin = false;
            this.$scope.model.DET_DEM_ORIGIN = null;
            this.$scope.hasDetDemDestination = false;
            this.$scope.model.DET_DEM_DESTINATION = null;
        }
    }

    private async detDemOriginChange() {
        try {
            const perDiemTypeTable = this.$scope.perDiemTypeList.find(x => x.ID == ETariffFreightPerDiemType.TABLE);
            if (this.$scope.hasDetDemOrigin) {
                this.$scope.model.DET_DEM_ORIGIN = {
                    DET_DEM_TYPE: null,
                    PER_DIEM_TYPE: null,
                    FREE_TIME_TYPE: null,
                    FIXED_ADDITIONAL: null,
                    PERCENTAGE_ADDITIONAL: null,
                    TABLE_PER_DIEM: null,
                    EQUIPMENT_FREE_TIME: null
                };

                if (this.$scope.model.PRODUCT.ID == EProductId.MARITIME_EXPORT && this.$scope.model.TRANSACTION.ID == EPaymentNatureId.PAYMENT) {
                    this.$scope.model.DET_DEM_ORIGIN.PER_DIEM_TYPE = perDiemTypeTable;
                }
            } else {
                const msg = "Tem certeza que deseja remover os dados de Detention e Demurrage da Origem?";
                const modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: "GENERAL.CONFIRM",
                    closeButtonText: "GENERAL.NO",
                    headerText: "GENERAL.CONFIRM_ACTION",
                    bodyText: msg
                });
                this.$scope.hasDetDemOrigin = !modal;
                if (!modal) return;
                this.$scope.model.DET_DEM_ORIGIN = null;
            }

            if (this.$scope.model.DET_DEM_ORIGIN && this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE && this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE.ID == ETariffTypeDetDem.COMBINED) {
                this.$timeout(() => {
                    this.$scope.setDirtyFields([`combinedDetDemHouseFreeTimeOrigin`]);
                }, 100);
            }

            if (this.$scope.model.DET_DEM_ORIGIN && this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE && this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE.ID == ETariffTypeDetDem.DET_DEM) {
                this.$timeout(() => {
                    this.$scope.setDirtyFields([`splittedDetentionHouseFreeTimeOrigin`, `splittedDemurrageHouseFreeTimeOrigin`]);
                }, 100);
            }

            this.$timeout(() => {
                this.$scope.setDirtyFields([`det_dem_fixed_additional_origin`, `det_dem_percentage_additional_origin`]);
                this.$scope.selectorValidity(`det_dem_type_origin`);
                this.$scope.selectorValidity(`per_diem_type_origin`);
                this.$scope.selectorValidity(`free_time_type_origin`);
                this.$scope.selectorValidity(`det_dem_tablePerDiem_origin`);
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async detDemDestinationChange() {
        const perDiemTypeTable = this.$scope.perDiemTypeList.find(x => x.ID == ETariffFreightPerDiemType.TABLE);
        if (this.$scope.hasDetDemDestination) {
            this.$scope.model.DET_DEM_DESTINATION = {
                DET_DEM_TYPE: null,
                PER_DIEM_TYPE: null,
                FREE_TIME_TYPE: null,
                FIXED_ADDITIONAL: null,
                PERCENTAGE_ADDITIONAL: null,
                TABLE_PER_DIEM: null,
                EQUIPMENT_FREE_TIME: null
            };

            if (this.$scope.model.PRODUCT.ID == EProductId.MARITIME_IMPORT && this.$scope.model.TRANSACTION.ID == EPaymentNatureId.PAYMENT) {
                this.$scope.model.DET_DEM_DESTINATION.PER_DIEM_TYPE = perDiemTypeTable;
            }
        } else {
            const msg = "Tem certeza que deseja remover os dados de Detention e Demurrage do Destino?";
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: msg
            });

            this.$scope.hasDetDemDestination = !modal;
            if (!modal) return;
            this.$scope.model.DET_DEM_DESTINATION = null;
        }

        if (this.$scope.model.DET_DEM_DESTINATION && this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE && this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE.ID == ETariffTypeDetDem.COMBINED) {
            this.$timeout(() => {
                this.$scope.setDirtyFields([`combinedDetDemHouseFreeTimeDestination`]);
            }, 100);
        }
        if (this.$scope.model.DET_DEM_DESTINATION && this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE && this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE.ID == ETariffTypeDetDem.DET_DEM) {
            this.$timeout(() => {
                this.$scope.setDirtyFields([`splittedDetentionHouseFreeTimeDestination`, `splittedDemurrageHouseFreeTimeDestination`]);
            }, 100);
        }

        this.$timeout(() => {
            this.$scope.setDirtyFields([`det_dem_fixed_additional_destination`, `det_dem_percentage_additional_destination`]);
            this.$scope.selectorValidity(`det_dem_type_destination`);
            this.$scope.selectorValidity(`per_diem_type_destination`);
            this.$scope.selectorValidity(`free_time_type_destination`);
            this.$scope.selectorValidity(`det_dem_tablePerDiem_destination`);
        }, 100);
    }

    private checkPerDiemTypeChange(originDestination: number): void {
        if (originDestination == 1 && this.$scope.model.DET_DEM_ORIGIN && this.$scope.model.DET_DEM_ORIGIN.PER_DIEM_TYPE) {
            switch (this.$scope.model.DET_DEM_ORIGIN.PER_DIEM_TYPE.ID) {
                case '1':
                    this.$scope.model.DET_DEM_ORIGIN.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_ORIGIN.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM = null;
                    break;
                case '2':
                    this.$scope.model.DET_DEM_ORIGIN.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_ORIGIN.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM = null;
                    break;
                case '3':
                    this.$scope.model.DET_DEM_ORIGIN.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM = null;
                    break;
                case '4':
                    this.$scope.model.DET_DEM_ORIGIN.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM = null;
                    break;
                case '5':
                    this.$scope.model.DET_DEM_ORIGIN.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_ORIGIN.PERCENTAGE_ADDITIONAL = null;
                    break;
                default:
                    this.formService.notifyError('No Per Diem Type ID at Route Origin selected.');
                    break;
            }
            this.$timeout(() => {
                this.$scope.setDirtyFields([`det_dem_fixed_additional_origin`, `det_dem_percentage_additional_origin`]);
                this.$scope.selectorValidity(`det_dem_type_origin`);
                this.$scope.selectorValidity(`per_diem_type_origin`);
                this.$scope.selectorValidity(`free_time_type_origin`);
                this.$scope.selectorValidity(`det_dem_tablePerDiem_origin`);
            }, 100);
        } else if (originDestination == 2 && this.$scope.model.DET_DEM_DESTINATION.PER_DIEM_TYPE) {
            switch (this.$scope.model.DET_DEM_DESTINATION.PER_DIEM_TYPE.ID) {
                case '1':
                    this.$scope.model.DET_DEM_DESTINATION.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_DESTINATION.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM = null;
                    break;
                case '2':
                    this.$scope.model.DET_DEM_DESTINATION.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_DESTINATION.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM = null;
                    break;
                case '3':
                    this.$scope.model.DET_DEM_DESTINATION.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM = null;
                    break;
                case '4':
                    this.$scope.model.DET_DEM_DESTINATION.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM = null;
                    break;
                case '5':
                    this.$scope.model.DET_DEM_DESTINATION.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_DESTINATION.PERCENTAGE_ADDITIONAL = null;
                    break;
                default:
                    this.formService.notifyError('No Per Diem Type ID at Route Destination selected.');
                    break;
            }
            this.$timeout(() => {
                this.$scope.setDirtyFields([`det_dem_fixed_additional_destination`, `det_dem_percentage_additional_destination`]);
                this.$scope.selectorValidity(`det_dem_type_destination`);
                this.$scope.selectorValidity(`per_diem_type_destination`);
                this.$scope.selectorValidity(`free_time_type_destination`);
                this.$scope.selectorValidity(`det_dem_tablePerDiem_destination`);
            }, 100);
        }
    }

    private initFreeTimeTable(originDestination: number): void {
        const freeTimeItem: IEquipmentFreeTime = {
            COMBINED_DET_DEM_HOUSE_FREE_TIME: null,
            COMBINED_DET_DEM_MASTER_FREE_TIME: null,
            EQUIPMENT: [],
            SPLITTED_DEMURRAGE_HOUSE_FREE_TIME: null,
            SPLITTED_DEMURRAGE_MASTER_FREE_TIME: null,
            SPLITTED_DETENTION_HOUSE_FREE_TIME: null,
            SPLITTED_DETENTION_MASTER_FREE_TIME: null
        };

        if (originDestination == 1) {
            if (this.$scope.model.DET_DEM_ORIGIN && !this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME) {
                this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME = [];
                this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME.push(freeTimeItem);
            }
        } else if (originDestination == 2) {
            if (this.$scope.model.DET_DEM_DESTINATION && !this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME) {
                this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME = [];
                this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME.push(freeTimeItem);
            }
        }

        if (this.$scope.model.DET_DEM_ORIGIN && this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE && this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE.ID == ETariffTypeDetDem.COMBINED) {
            this.$timeout(() => {
                this.$scope.setDirtyFields([`combinedDetDemHouseFreeTimeOrigin`]);
            });
        }

        if (this.$scope.model.DET_DEM_ORIGIN && this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE && this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE.ID == ETariffTypeDetDem.DET_DEM) {
            this.$timeout(() => {
                this.$scope.setDirtyFields([`splittedDetentionHouseFreeTimeOrigin`, `splittedDemurrageHouseFreeTimeOrigin`]);
            });
        }

        if (this.$scope.model.DET_DEM_DESTINATION && this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE && this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE.ID == ETariffTypeDetDem.COMBINED) {
            this.$timeout(() => {
                this.$scope.setDirtyFields([`combinedDetDemHouseFreeTimeDestination`]);
            });
        }

        if (this.$scope.model.DET_DEM_DESTINATION && this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE && this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE.ID == ETariffTypeDetDem.DET_DEM) {
            this.$timeout(() => {
                this.$scope.setDirtyFields([`splittedDetentionHouseFreeTimeDestination`, `splittedDemurrageHouseFreeTimeDestination`]);
            });
        }
    }

    private checkReadOnly(originDestination: number, perDiemTypeId: string): boolean {
        let readOnly = false;

        try {
            if (this.$scope.model) {
                if (originDestination == 1) {
                    if (!this.$scope.model.TRANSACTION || !this.$scope.model.DET_DEM_ORIGIN || !this.$scope.model.DET_DEM_ORIGIN.PER_DIEM_TYPE) {
                        readOnly = true;
                    }

                    if (this.$scope.model.TRANSACTION && this.$scope.model.TRANSACTION.ID == EPaymentNatureId.RECEIVING && this.$scope.model.DET_DEM_ORIGIN
                        && this.$scope.model.DET_DEM_ORIGIN.PER_DIEM_TYPE && this.$scope.model.DET_DEM_ORIGIN.PER_DIEM_TYPE.ID
                        && this.$scope.model.DET_DEM_ORIGIN.PER_DIEM_TYPE.ID != perDiemTypeId) {
                        readOnly = true;
                    }
                } else if (originDestination == 2) {
                    if (!this.$scope.model.TRANSACTION || !this.$scope.model.DET_DEM_DESTINATION || !this.$scope.model.DET_DEM_DESTINATION.PER_DIEM_TYPE) {
                        readOnly = true;
                    }

                    if (this.$scope.model.TRANSACTION && this.$scope.model.TRANSACTION.ID == EPaymentNatureId.RECEIVING && this.$scope.model.DET_DEM_DESTINATION
                        && this.$scope.model.DET_DEM_DESTINATION.PER_DIEM_TYPE && this.$scope.model.DET_DEM_DESTINATION.PER_DIEM_TYPE.ID
                        && this.$scope.model.DET_DEM_DESTINATION.PER_DIEM_TYPE.ID != perDiemTypeId) {
                        readOnly = true;
                    }
                }
                if (this.$scope.model.TRANSACTION && this.$scope.model.TRANSACTION.ID == EPaymentNatureId.PAYMENT) {
                    readOnly = true;
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
        return readOnly;
    }

    private async callSessionFunctions(data: object): Promise<void> {
        try {
            if (!data) return;
            const tariffFreightExchangeData = <ITariffFreightExchangeData>data;
            let freightContractModel: INewFreightContract = null;
            for (const gridData of this.$scope.gridOptions.data) {
                if (tariffFreightExchangeData.ID != null && gridData.ID == tariffFreightExchangeData.ID) {
                    freightContractModel = gridData;
                    break;
                }
            }
            switch (tariffFreightExchangeData.OPERATION) {
                case EOperation.VIEW: if (freightContractModel) this.$scope.viewFreightContract(freightContractModel, tariffFreightExchangeData.ID_TARIFF);
                    break;
                case EOperation.EDIT: {
                    if (freightContractModel) {
                        await this.$scope.editFreightContract(freightContractModel, tariffFreightExchangeData.ID_TARIFF);
                    }
                    break;
                }
                case EOperation.NEW: {
                    if (!tariffFreightExchangeData.ID) this.$rootScope.breadCrumb.action();
                    break;
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }
    private async getGenericList(type: string, alternative: boolean = false): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}/${alternative}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async getProviderListByName(search: string): Promise<void> {
        let result: IProviderSelector[] = [];
        try {
            if (search && search.length >= 3) {

                if (!this.$scope.model.PRODUCT || !this.$scope.model.PRODUCT.ID) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                this.formService.block();

                let types = [];
                if (this.$scope.model.PRODUCT.ID == "IM" || this.$scope.model.PRODUCT.ID == "EM") {
                    types = ['1'];
                } else if (this.$scope.model.PRODUCT.ID == "IA" || this.$scope.model.PRODUCT.ID == "EA") {
                    types = ['2'];
                } else {
                    types = ['3'];
                }

                const products = [this.$scope.model.PRODUCT.ID];
                const providers = await this.productService.post({ route: '/provider/list/custom', data: { search, products, types }, timeout: 30000 });

                if (providers && providers.data && providers.data.data && providers.data.data.length > 0) {
                    result = providers.data.data.map(provider => { return { ID: provider.ID, NAME: provider.NAME, CODE: provider.SCAC_IATA, TYPE: provider.TYPE } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.providerList = result;
            this.formService.unblock();
        }
    }

    private async getNetworkListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 3) {
                this.formService.block();
                const networks = await this.productService.post({ route: '/network/listByLegalPersonSpecType', data: { name: search, types: ['12'] }, timeout: 30000 });
                if (networks && networks.data && networks.data.data && networks.data.data.data && networks.data.data.data.length > 0) {
                    result = networks.data.data.data.map(account => { return { ID: account.ID, NAME: account.NAME } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.networkList = result;
            this.formService.unblock();
        }
    }

    private async getAccountListByName(search: string): Promise<void> {
        try {
            if (search && search.length >= 3) {
                this.block();

                const request: IMonacoRequest = {
                    data: { search },
                    route: `/account/list/custom`,
                    timeout: 30000,
                };

                const rc = await this.productService.post(request, false);
                const accountList = (rc && rc.data && rc.data.data && rc.data.data.data) ? rc.data.data.data : null;

                this.$scope.accountList = accountList.map(account => { return { ID: account.ID, NAME: account.NAME, CODE: account.LEGAL_PERSON.SHORT_NAME } });;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getExporterListByName(search: string): Promise<void> {
        let result = [];
        try {
            const exporterList = [];
            const legalPersonList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.EXPORTER], search: search });
            if (legalPersonList) legalPersonList.map(legalPerson => { exporterList.push({ ID: legalPerson.ID, NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME }) });
            result = exporterList;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.exporterList = result;
        }
    }

    private async getImporterListByName(search: string): Promise<void> {
        let result = [];
        try {
            const importerList = [];
            const legalPersonList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.IMPORTER], search: search });
            if (legalPersonList) legalPersonList.map(legalPerson => { importerList.push({ ID: legalPerson.ID, NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME }) });
            result = importerList;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.importerList = result;
        }
    }

    private async getLegalPersonListByName(legalPersonFilter: ILegalPersonListCustomFilter): Promise<ILegalPersonModelListCustom[]> {
        let result = [];
        try {
            if (legalPersonFilter.search && legalPersonFilter.search.length >= 3) {
                this.formService.block();
                const legalPerson = await this.productService.post({ route: '/legalPerson/list/custom', data: { ...legalPersonFilter, sysConvertIdToString: false }, timeout: 30000 });
                if (legalPerson && legalPerson.data && legalPerson.data.data && legalPerson.data.data.length > 0) {
                    result = legalPerson.data.data
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCommodityListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();
                const commodities = await this.productService.post({ route: '/commodity/list/custom/', data: { name: search, sysConvertIdToString: false }, timeout: 30000 });
                if (commodities && commodities.data && commodities.data.data && commodities.data.data.length > 0) {
                    result = commodities.data.data.map(commodity => { return { ID: commodity.ID, NAME: commodity.NAME, CODE: commodity.HS_CODE } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.commodityList = result
            this.formService.unblock();
        }
    }

    private async getEquipmentListForDetDem(search: string): Promise<void> {
        let result: IEquipmentSelector[] = [];
        try {
            if (search && search.length >= 2) {
                if (!this.$scope.model.PRODUCT) {
                    throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                }

                this.formService.block();

                const products = [this.$scope.model.PRODUCT.ID];
                const equipmentList = await this.productService.post({ route: '/equipment/list/custom', data: { search, products }, timeout: 30000 });
                if (equipmentList && equipmentList.data && equipmentList.data.data && equipmentList.data.data && equipmentList.data.data.length > 0) {
                    result = equipmentList.data.data.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, TEU: x.TEU, FEET: x.FEET } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.equipmentFreeTimeList = result;
        }
    }

    private async getTablePerDiemList(direction: string, tariffTypeId: string): Promise<void> {
        let result: SelectorModel[] = [];
        this.formService.block();
        try {
            if (this.$scope.model.TRANSACTION) {
                const getDefault: boolean = this.$scope.model.TRANSACTION.ID == EPaymentNatureId.RECEIVING ? false : null;
                const filterEquipmentProvider: object = this.$scope.model.TRANSACTION.ID == EPaymentNatureId.PAYMENT ? { ID_EQUIPMENT_PROVIDER: { $eq: null } } : null;
                const data: ITablePerDiemQueryFields = {
                    TRANSACTION: this.$scope.model.TRANSACTION.ID,
                    DIRECTION: direction,
                    PROVIDER: this.$scope.model.PROVIDER ? [parseInt(this.$scope.model.PROVIDER.ID)] : null,
                    COUNTRY_CODE: 'BR',
                    ID_TABLE_TYPE: tariffTypeId,
                    ...filterEquipmentProvider,
                    ACTIVE: true,
                    DEFAULT: getDefault,
                };

                const tablesPerDiem = await this.productService.post({ route: '/tablePerDiem/listByFullName', data: { data }, timeout: 30000 });
                if (tablesPerDiem && tablesPerDiem.data && tablesPerDiem.data.data && tablesPerDiem.data.data && tablesPerDiem.data.data.length > 0) {
                    result = tablesPerDiem.data.data.map(tablePerDiem => { return { ID: tablePerDiem.ID, NAME: tablePerDiem.CONCATENATED, CODE: null } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.tablePerDiemList = result;
        }
    }

    private addEquipmentFreeTime(originDestination: number): void {
        try {

            let modelProperty = '';
            if (originDestination == 1) modelProperty = 'DET_DEM_ORIGIN';
            if (originDestination == 2) modelProperty = 'DET_DEM_DESTINATION';

            const msg = 'Select Tariff Type first.';
            if (!this.$scope.model[`${modelProperty}`] || !this.$scope.model[`${modelProperty}`].DET_DEM_TYPE) return this.formService.notifyError(msg);

            const equipmentDetDemFreeTime: IEquipmentFreeTime = {
                EQUIPMENT: null,
                COMBINED_DET_DEM_HOUSE_FREE_TIME: null,
                COMBINED_DET_DEM_MASTER_FREE_TIME: null,
                SPLITTED_DEMURRAGE_HOUSE_FREE_TIME: null,
                SPLITTED_DEMURRAGE_MASTER_FREE_TIME: null,
                SPLITTED_DETENTION_HOUSE_FREE_TIME: null,
                SPLITTED_DETENTION_MASTER_FREE_TIME: null,
            };

            if (!this.$scope.model[`${modelProperty}`].EQUIPMENT_FREE_TIME) this.$scope.model[`${modelProperty}`].EQUIPMENT_FREE_TIME = [];
            this.$scope.model[`${modelProperty}`].EQUIPMENT_FREE_TIME.push(equipmentDetDemFreeTime);

            if (originDestination == 1 && this.$scope.model[`${modelProperty}`].DET_DEM_TYPE.ID == ETariffTypeDetDem.COMBINED) {
                this.$timeout(() => {
                    this.$scope.setDirtyFields([`combinedDetDemHouseFreeTimeOrigin`]);
                }, 100);
            }

            if (originDestination == 1 && this.$scope.model[`${modelProperty}`].DET_DEM_TYPE.ID == ETariffTypeDetDem.DET_DEM) {
                this.$timeout(() => {
                    this.$scope.setDirtyFields([`splittedDetentionHouseFreeTimeOrigin`, `splittedDemurrageHouseFreeTimeOrigin`]);
                }, 100);
            }

            if (originDestination == 2 && this.$scope.model[`${modelProperty}`].DET_DEM_TYPE.ID == ETariffTypeDetDem.COMBINED) {
                this.$timeout(() => {
                    this.$scope.setDirtyFields([`combinedDetDemHouseFreeTimeDestination`]);
                }, 100);
            }

            if (originDestination == 2 && this.$scope.model[`${modelProperty}`].DET_DEM_TYPE.ID == ETariffTypeDetDem.DET_DEM) {
                this.$timeout(() => {
                    this.$scope.setDirtyFields([`splittedDetentionHouseFreeTimeDestination`, `splittedDemurrageHouseFreeTimeDestination`]);
                }, 100);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private checkFreeTimeSplittedDetention(transactionId: string, originDestination: number, index: number): void {
        let master: number;
        let house: number;
        if (transactionId == EPaymentNatureId.PAYMENT) {
            if (originDestination == 1) {
                master = this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_MASTER_FREE_TIME;
                house = this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_HOUSE_FREE_TIME;
            } else if (originDestination == 2) {
                master = this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_MASTER_FREE_TIME;
                house = this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_HOUSE_FREE_TIME;
            }

            if (house > master) {
                const msg = "Não é possível informar um valor maior para o House no Free Time de Splitted Detention.";
                this.formService.notifyError(msg);

                if (originDestination == 1) {
                    this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_HOUSE_FREE_TIME = null;
                } else if (originDestination == 2) {
                    this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_HOUSE_FREE_TIME = null;
                }
            }
        }
    }

    private checkFreeTimeSplittedDemurrage(transactionId: string, originDestination: number, index: number): void {
        let master: number;
        let house: number;
        if (transactionId == EPaymentNatureId.PAYMENT) {
            if (originDestination == 1) {
                master = this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_MASTER_FREE_TIME;
                house = this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_HOUSE_FREE_TIME;
            } else if (originDestination == 2) {
                master = this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_MASTER_FREE_TIME;
                house = this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_HOUSE_FREE_TIME;
            }
            if (house > master) {
                if (originDestination == 1) {
                    this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_HOUSE_FREE_TIME = null;
                } else if (originDestination == 2) {
                    this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_HOUSE_FREE_TIME = null;
                }
                const msg = "Não é possível informar um valor maior para o House no Free Time de Splitted Demurrage.";
                this.formService.notifyError(msg);
            }
        }
    }

    private checkFreeTimeCombined(transactionId: string, originDestination: number, index: number): void {
        let master: number;
        let house: number;
        if (transactionId == EPaymentNatureId.PAYMENT) {
            if (originDestination == 1) {
                master = this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_MASTER_FREE_TIME;
                house = this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_HOUSE_FREE_TIME;
            } else if (originDestination == 2) {
                master = this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_MASTER_FREE_TIME;
                house = this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_HOUSE_FREE_TIME;
            }

            if (house > master) {
                if (originDestination == 1) {
                    this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_HOUSE_FREE_TIME = null;
                } else if (originDestination == 2) {
                    this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_HOUSE_FREE_TIME = null;
                }
                const msg = "Não é possível informar um valor maior para o House no Free Time Combined.";
                this.formService.notifyError(msg);
            }
        }
    }

    private generateConcatenated(): void {
        try {
            const separator = " | ";
            let concatenated = "";

            if (this.$scope.model.PROVIDER && this.$scope.model.PROVIDER.TYPE) {
                if ((this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.SHIPOWNER || this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.AIRLINE) && this.$scope.model.PROVIDER.CODE && this.$scope.model.PROVIDER.CODE.length) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PROVIDER.CODE) : concatenated.concat(this.$scope.model.PROVIDER.CODE);
                } else if (this.$scope.model.PROVIDER.NAME && this.$scope.model.PROVIDER.NAME.length) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PROVIDER.NAME) : concatenated.concat(this.$scope.model.PROVIDER.NAME);
                }
            }

            if (this.$scope.model.EXTERNAL_AGENT_NETWORK && this.$scope.model.EXTERNAL_AGENT_NETWORK.length) {
                const networks = this.$scope.getCONCAT(this.$scope.model.EXTERNAL_AGENT_NETWORK, null, null, null, null, ',')
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, networks) : concatenated.concat(networks);
            }

            if (this.$scope.model.ACCOUNT && this.$scope.model.ACCOUNT.length) {
                const account = this.$scope.getCONCAT(this.$scope.model.ACCOUNT, null, "CODE", null, null, ',')
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, account) : concatenated.concat(account);
            }

            if (this.$scope.model.SHIPPER && this.$scope.model.SHIPPER.length) {
                const networks = this.$scope.getCONCAT(this.$scope.model.SHIPPER, null, "NAME", null, null, ',')
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, networks) : concatenated.concat(networks);
            }

            if (this.$scope.model.CONSIGNEE && this.$scope.model.CONSIGNEE.length) {
                const networks = this.$scope.getCONCAT(this.$scope.model.CONSIGNEE, null, "NAME", null, null, ',')
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, networks) : concatenated.concat(networks);
            }

            const concatenatedContract = this.concatContracts();
            concatenated = concatenated.length > 0 ? concatenated.concat(separator, concatenatedContract) : concatenated.concat(concatenatedContract);

            if (this.$scope.model.CARGO_TYPE && this.$scope.model.CARGO_TYPE.NAME && this.$scope.model.CARGO_TYPE.NAME.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CARGO_TYPE.NAME) : concatenated.concat(this.$scope.model.CARGO_TYPE.NAME);
            }

            if (this.$scope.model.FREIGHT_CONTRACT_TYPE && this.$scope.model.FREIGHT_CONTRACT_TYPE.NAME && this.$scope.model.FREIGHT_CONTRACT_TYPE.NAME.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.FREIGHT_CONTRACT_TYPE.NAME) : concatenated.concat(this.$scope.model.FREIGHT_CONTRACT_TYPE.NAME);
            }

            if (this.$scope.model.TRANSACTION && this.$scope.model.TRANSACTION.ID && this.$scope.model.TRANSACTION.ID.length > 0) {
                let paymentNature = null;
                if (this.$scope.model.TRANSACTION.ID === EPaymentNatureId.PAYMENT) {
                    paymentNature = 'PGTO';
                } else if (this.$scope.model.TRANSACTION.ID === EPaymentNatureId.RECEIVING) {
                    paymentNature = 'RCTO';
                }
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, paymentNature) : concatenated.concat(paymentNature);
            }

            if (this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.ID && this.$scope.model.PRODUCT.ID.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PRODUCT.ID) : concatenated.concat(this.$scope.model.PRODUCT.ID);
            }

            if (this.$scope.model.CONCATENATED_COMPLEMENT && this.$scope.model.CONCATENATED_COMPLEMENT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CONCATENATED_COMPLEMENT) : concatenated.concat(this.$scope.model.CONCATENATED_COMPLEMENT);
            }

            if (this.$scope.model.ALLOG_FREIGHT_CONTRACT && this.$scope.model.ALLOG_FREIGHT_CONTRACT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.ALLOG_FREIGHT_CONTRACT) : concatenated.concat(this.$scope.model.ALLOG_FREIGHT_CONTRACT);
            }

            if (this.$scope.model.VALIDITY_START) {
                const validity = this.$scope.model.VALIDITY_END ? moment(this.$scope.model.VALIDITY_START).format('DD/MM/YY') + '-' + moment(this.$scope.model.VALIDITY_END).format('DD/MM/YY') : moment(this.$scope.model.VALIDITY_START).format('DD/MM/YY');
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, validity) : concatenated.concat(validity);
            }

            this.$scope.model.CONCATENATED = StringUtil.formatConcatenatedChars(concatenated);

            if (this.$scope.menuFloating) this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private concatContracts() {
        let concatenatedContract = "";

        if (this.$scope.model.FREIGHT_CONTRACT && this.$scope.model.FREIGHT_CONTRACT.length) {
            concatenatedContract = this.$scope.model.FREIGHT_CONTRACT;
        }

        if (this.$scope.model.CONTRACT_DESCRIPTION && this.$scope.model.CONTRACT_DESCRIPTION.length) {
            concatenatedContract = concatenatedContract.concat(" ", this.$scope.model.CONTRACT_DESCRIPTION)
        }

        return concatenatedContract;
    }

    private isCommodityDisabled(commodity: ICommodity, fieldName: string): boolean {
        let disabled = false;
        try {
            if (!commodity) throw Error('commodity is null');
            if (!fieldName) throw Error('fieldName is null');
            if (
                (fieldName == 'section' && (commodity.CONDITION || commodity.EXCEPTION)) ||
                (fieldName == 'condition' && (commodity.SECTION || commodity.EXCEPTION)) ||
                (fieldName == 'exception' && (commodity.SECTION || commodity.CONDITION))
            ) disabled = true;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return disabled;
        }
    }

    private addCommodity(): void {
        try {
            if (!this.$scope.model.COMMODITY) this.$scope.model.COMMODITY = [];
            const commodityToAdd: ICommodity = {
                _id: null,
                ID: null,
                ID_NEW_FREIGHT_CONTRACT: null,
                SECTION: null,
                CONDITION: null,
                ID_CONDITION: null,
                EXCEPTION: null,
                ID_EXCEPTION: null
            }
            this.$scope.model.COMMODITY.push(angular.copy(commodityToAdd));

            this.$timeout(() => {
                const currentIndex = this.$scope.model.COMMODITY.length - 1;
                this.$scope.selectorValidity('section' + currentIndex);
                this.$scope.selectorValidity('condition' + currentIndex);
                this.$scope.selectorValidity('exception' + currentIndex);
            });
            this.orderCommodityByException();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private hideRemove(): boolean {
        let disabled = false;
        try {
            if (this.$scope.model.COMMODITY && this.$scope.model.COMMODITY.length == 1) {
                if (this.$scope.model.COMMODITY[0].CONDITION || this.$scope.model.COMMODITY[0].EXCEPTION || this.$scope.model.COMMODITY[0].SECTION) {
                    disabled = true;
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return disabled;
        }
    }

    private async removeDetDemEquipment(index: number, property: string) {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: `${this.formService.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")}`
            });
            if (!modal) return;

            if (this.$scope.model[property] && this.$scope.model[property].EQUIPMENT_FREE_TIME.length > 0) {
                this.formService.block();
                this.$scope.model[property].EQUIPMENT_FREE_TIME.splice(index, 1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async removeCommodity(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: `${this.formService.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")}`
            });
            if (!modal) return;

            if (this.$scope.model.COMMODITY && this.$scope.model.COMMODITY.length > 0) {
                this.formService.block();
                this.$scope.model.COMMODITY.splice(index, 1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private orderCommodityByException(): void {
        if (this.$scope.model && this.$scope.model.COMMODITY && this.$scope.model.COMMODITY.length > 0) {
            this.$scope.model.COMMODITY = this.$scope.model.COMMODITY.sort((a, b) => {
                const firstCommodity = a.EXCEPTION ? a.EXCEPTION.NAME.toLowerCase() : "";
                const secondCommodity = b.EXCEPTION ? b.EXCEPTION.NAME.toLowerCase() : "";
                return firstCommodity > secondCommodity ? 1 : firstCommodity < secondCommodity ? -1 : 0;
            });
        }
    }

    private commodityChange(commodity: ICommodity): void {
        if (!commodity) throw Error('commodity parameter is null');

        if (commodity.SECTION) {
            commodity.CONDITION = null;
            commodity.EXCEPTION = null;
        } else if (commodity.CONDITION) {
            commodity.SECTION = null;
            commodity.EXCEPTION = null;
        } else if (commodity.EXCEPTION) {
            commodity.SECTION = null;
            commodity.CONDITION = null;
        }
    }

    private initDateFields(): void {
        try {
            if (this.$scope.model) {
                this.$scope.model.VALIDITY_START = (this.$scope.model.VALIDITY_START != null) ? new Date(this.$scope.model.VALIDITY_START) : null;
                this.$scope.model.VALIDITY_END = (this.$scope.model.VALIDITY_END != null) ? new Date(this.$scope.model.VALIDITY_END) : null;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'tariff_files', formName: this.formName, folder: this.formName };
            return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData] });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeUpload(model: IUploadItem): Promise<boolean> {
        let result = false;
        try {
            if (!model) throw Error('item parameter is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: `${this.formService.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")}`
            });
            if (!modal) return;

            const isAdminOrCoordinator = (this.$scope.user.roles && (this.$scope.user.roles.hasOwnProperty('ADMIN') || this.$scope.user.roles.hasOwnProperty('COORDINATOR')));

            if (isAdminOrCoordinator) {
                result = true;
            } else {
                if (this.$scope.model.FILES_NEW && this.$scope.model.FILES_NEW.length > 0) {
                    const index = this.$scope.model.FILES_NEW.findIndex(file => { return file.FILE_HASH == model.FILE_HASH });
                    if (index > -1) {
                        this.$scope.model.FILES_NEW.splice(index, 1);
                        result = true;
                    }
                }
            }

            if (result) {
                if (!this.$scope.model.FILES_REMOVE) this.$scope.model.FILES_REMOVE = [];
                this.$scope.model.FILES_REMOVE.push(model);
            } else {
                throw Error('You don\'t have permission to remove this file');
            }
        } catch (ex) {
            result = false;
            this.handleError(ex);
        } finally {
            return result;
        }
    }

    private onSuccessItem(item: IFileItem, response: ISuccessItemResponse): void {
        try {
            if (!item) throw Error('item parameter is null');
            if (!response) throw Error('response parameter is null');

            if (response.data && response.data.length > 0) {
                if (!this.$scope.model.FILES_NEW) this.$scope.model.FILES_NEW = [];

                for (let item of response.data) {
                    const file: IUploadItem = {
                        FILE_DISPLAY_NAME: item.displayFileName,
                        FILE_NAME: item.fileName,
                        FILE_TYPE: item.fileType,
                        FILE_PATH: item.filePath,
                        FILE_URL: item.fileUrl,
                        FILE_HASH: item.fileHash,
                        FILE_SIZE: item.fileSize,
                        FORM_NAME: item.formName
                    }
                    this.$scope.model.FILES_NEW.push(file)
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async updateProduct(selected: SelectorModel): Promise<void> {
        try {
            if (!selected) throw Error('selected is null');

            this.$scope.model.PROVIDER = null;
            this.$timeout(() => this.$scope.selectorValidity("provider"));
            await this.updateControl();

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private updateProvider(): void {
        if (this.$scope.model && this.$scope.model.DET_DEM_ORIGIN && this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM && this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM.ID) this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM = null;
        if (this.$scope.model && this.$scope.model.DET_DEM_DESTINATION && this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM && this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM.ID) this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM = null;
    }

    private collapseHeader(elementId: string, state?: string, navigate?: boolean): void {
        if (elementId === "collapseAll") {
            this.collapseState = this.collapseState == "hide" ? "show" : "hide";
            $('.toggle-me')["collapse"](state ? state : this.collapseState);
        }
        else if (elementId != "registerBody") {
            $("#" + elementId)["collapse"](state ? state : 'toggle');
        }
        if (navigate && state != "hide") this.$scope.navigateBetweenIds(elementId);
    }

    private checkDateValidity(initialDate: Date, finalDate: Date): void {
        let isValid = false;
        if (!initialDate || typeof initialDate == "string") return;
        if (!finalDate || typeof finalDate == "string") return;
        isValid = ValidateUtil.isValidDateRange(initialDate, finalDate);
        if (!isValid) {
            this.$scope.model.VALIDITY_END = null;
        }
    }

    private getDefaultSituation(self: NewFreightContractRegisterController): void {
        if (self.$scope.situationList && self.$scope.situationList.length > 0 && !self.$scope.model.SITUATION) {
            self.$scope.model.SITUATION = self.$scope.situationList.find(situation => situation.ID == ESituationId.RELEASED);
        }
    }

    private getDefaultValidityBasis(self: NewFreightContractRegisterController): void {
        if (self.$scope.eventList && self.$scope.eventList.length > 0 && !self.$scope.model.VALIDITY_BASIS) {
            self.$scope.model.VALIDITY_BASIS = self.$scope.eventList.find(event => event.ID == EEventType.LOAD);
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: "EQUIPMENT_FREE_TIME	",
                LABEL: "TARIFF_DET_DEM.ADD_EQUIPMENT_FREE_TIME"
            },
            {
                PROPERTY: "TABLE_PER_DIEM	",
                LABEL: "GENERAL.TABLE"
            },
            {
                PROPERTY: "PERCENTAGE_ADDITIONAL	",
                LABEL: "PRODUCT.PD_PERC_ADDITIONAL"
            },
            {
                PROPERTY: "FIXED_ADDITIONAL	",
                LABEL: "PRODUCT.PD_FIXED_ADDITIONAL"
            },
            {
                PROPERTY: "FREE_TIME_TYPE	",
                LABEL: "PRODUCT.FREE_TIME_TYPE"
            },
            {
                PROPERTY: "DET_DEM_TYPE	",
                LABEL: "PRODUCT.TARIFF_TYPE"
            },
            {
                PROPERTY: "DET_DEM_DESTINATION",
                LABEL: "PRODUCT.DET_DEM_DESTINATION"
            },
            {
                PROPERTY: "DET_DEM_ORIGIN",
                LABEL: "PRODUCT.DET_DEM_ORIGIN"
            },
            {
                PROPERTY: "CONTRACT_DESCRIPTION",
                LABEL: "OPERATIONAL.CONTRACT_DESCRIPTION"
            },
            {
                PROPERTY: "CONSIGNEE",
                LABEL: "BASIC_DATA.CONSIGNEE"
            },
            {
                PROPERTY: "SHIPPER",
                LABEL: "BASIC_DATA.SHIPPER"
            },
            {
                PROPERTY: "DESCRIPTION",
                LABEL: "GENERAL.DESCRIPTION"
            },
            {
                PROPERTY: "ALLOG_FREIGHT_CONTRACT",
                LABEL: "BASIC_DATA.ALLOG_FREIGHT_CONTRACT"
            },
            {
                PROPERTY: "CONCATENATED_COMPLEMENT",
                LABEL: "GENERAL.CONCATENATED_COMPLEMENT"
            },
            {
                PROPERTY: "CONCATENATED",
                LABEL: "GENERAL.CONCATENATED"
            },
            {
                PROPERTY: "FREIGHT_CONTRACT",
                LABEL: "PRODUCT.FREIGHT_CONTRACT"
            },
            {
                PROPERTY: "TRANSACTION",
                LABEL: "BASIC_DATA.TRANSACTION"
            },
            {
                PROPERTY: "FREIGHT_CONTRACT_TYPE",
                LABEL: "PRODUCT.FREIGHT_CONTRACT_TYPE"
            },
            {
                PROPERTY: "PRODUCT",
                LABEL: "BASIC_DATA.PRODUCT"
            },
            {
                PROPERTY: "CARGO_TYPE",
                LABEL: "BASIC_DATA.CARGO_TYPE"
            },
            {
                PROPERTY: "PROVIDER",
                LABEL: "BASIC_DATA.PROVIDER "
            },
            {
                PROPERTY: "ACCOUNT",
                LABEL: "BASIC_DATA.CLIENT"
            },
            {
                PROPERTY: "EXTERNAL_AGENT_NETWORK",
                LABEL: "BASIC_DATA.EXTERNAL_AGENT_NETWORK"
            },
            {
                PROPERTY: "NAME",
                LABEL: "GENERAL.NAME"
            },
            {
                PROPERTY: "CODE",
                LABEL: "ENTITY.SHORT_NAME"
            },
            {
                PROPERTY: "COMMODITY",
                LABEL: "GENERAL.COMMODITY"
            },
            {
                PROPERTY: "SECTION",
                LABEL: "GENERAL.COMMODITY_GROUP_CONDITION"
            },
            {
                PROPERTY: "CONDITION",
                LABEL: "GENERAL.COMMODITY_CONDITION"
            },
            {
                PROPERTY: "EXCEPTION",
                LABEL: "GENERAL.COMMODITY_EXCEPTION"
            },
            {
                PROPERTY: "VALIDITY_BASIS",
                LABEL: "GENERAL.VALIDITY_BASIS"
            },
            {
                PROPERTY: "VALIDITY_START",
                LABEL: "PRODUCT.VALIDITY_START"
            },
            {
                PROPERTY: "VALIDITY_END",
                LABEL: "PRODUCT.VALIDITY_END"
            },
            {
                PROPERTY: "SITUATION",
                LABEL: "GENERAL.SITUATION"
            },
            {
                PROPERTY: "OBSERVATION",
                LABEL: "GENERAL.REMARKS"
            },
            {
                PROPERTY: "FILES",
                LABEL: "REGISTRATION.FILES"
            },
            {
                PROPERTY: "FILE_DISPLAY_NAME",
                LABEL: "REGISTRATION.FILE_ATTACHMENT_NAME"
            },
            {
                PROPERTY: "FILE_TYPE",
                LABEL: "REGISTRATION.FILE_TYPE"
            },
            {
                PROPERTY: "FILE_URL",
                LABEL: "REGISTRATION.FILE_URL"
            },
            {
                PROPERTY: "SCORE",
                LABEL: "BASIC_DATA.SCORE"
            },
            {
                PROPERTY: "SCORE_DATE",
                LABEL: "GENERAL.SCORE_CALCULATION_DATA"
            },
            {
                PROPERTY: "SCORE_ERROR",
                LABEL: "GENERAL.SCORE_CALCULATION_ERROR"
            },
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE"
            },
            {
                PROPERTY: "VALIDITY_EXPIRED",
                LABEL: "GENERAL.VALIDITY_EXPIRED"
            },
            {
                PROPERTY: "TARIFF_FREIGHT",
                LABEL: "ROUTE.ROUTES"
            },
            {
                PROPERTY: "CREATED_AT",
                LABEL: "GENERAL.CREATED_AT"
            },
            {
                PROPERTY: "CREATED_BY",
                LABEL: "GENERAL.CREATED_BY"
            },
            {
                PROPERTY: "UPDATED_AT",
                LABEL: "GENERAL.UPDATED_AT"
            },
            {
                PROPERTY: "UPDATED_BY",
                LABEL: "GENERAL.UPDATED_BY"
            }
        ];
        return props;
    }

    private getMenuFloatingDefault(): IFloatingMenu {
        return {
            tooltipPlacement: "auto bottom",
            textTooltip: "PRODUCT.TARIFF_DATA",
            infos: [
                { text: "Tarifário", class: "text-rouge font-bold" }
            ],
            options: [
                { click: "collapseHeader", args: ['collapseBasicData', 'toggle', true], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.BASIC_DATA", iconClass: "fa fa-address-card", iconBodyClass: "text-brown" },
                { click: "collapseHeader", args: ['collapseUploads', 'toggle', true], tooltipPlacement: "auto bottom", textTooltip: "REGISTRATION.FILES", iconClass: "fa fa-file", iconBodyClass: "text-orange" },
                { click: "goToNewFreightContract", tooltipPlacement: "auto bottom", textTooltip: "ROUTE.ROUTES", iconClass: "fa fa-external-link-square", iconBodyClass: "text-cyano" },
                { click: "collapseHeader", args: ['collapseAll'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.COLLAPSE_EXPAND_ALL", iconClass: "fa fa-expand", iconBodyClass: "text-danger" }
            ],
            btnActiveDisabled: false
        };
    }

    private async updateControl() {
        const product = this.$scope.model.PRODUCT;
        this.$scope.isAirProduct = product && (product.ID == EProductId.AIR_IMPORT || product.ID == EProductId.AIR_EXPORT);
        this.$scope.isMaritimeProduct = product && (product.ID == EProductId.MARITIME_IMPORT || product.ID == EProductId.MARITIME_EXPORT);
    }

    private initPanels(open: string[] = ['collapseBasicData']): void {
        try {
            const panels = document.getElementsByClassName("toggle-me");
            if (panels && panels.length > 0) {
                for (const item of open) {
                    $("#" + item)["collapse"]("show");
                }
                for (let i = 0; i < panels.length; i++) {
                    const panel = panels[i];
                    if (open.indexOf(panel.id) === -1) {
                        $("#" + panel.id)["collapse"]("hide");
                    }
                }
            }
            this.$scope.navigateBetweenIds(open[0]);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private setCopy(value: boolean): void {
        this.isCopy = value;
    }

    private transactionChange(): void {
        try {
            if (this.$scope.model.TRANSACTION) {
                this.$scope.model.DET_DEM_ORIGIN = null;
                this.$scope.model.DET_DEM_DESTINATION = null;
                this.$scope.hasDetDemDestination = false;
                this.$scope.hasDetDemOrigin = false;
            }
        } catch (ex) {
            this.formService.notifyError(ex);
        }
    }

    private async getTariffFreightById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/newFreightContract/getById/${id}`, 30000, null, false);
            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.getTariffFreightById(id);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) await this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) await this.$scope.copy(model);
                else await this.$scope.view(model);

            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openFreightWizardModal(freightContract: INewFreightContract): Promise<void> {
        try {
            if (!freightContract) return;
            this.block();

            const timeout: number = 120000;
            const freightContractId = (freightContract.ID) ? freightContract.ID : null;

            const request: IMonacoRequest = {
                data: { idFreightContract: freightContractId },
                route: `/freightWizard/freightContractToWizard`,
                timeout
            }
            const rc = await this.productService.post(request, false);
            const freightWizard: IFreightWizard = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            const modalId = this.ModalService.newModal();
            await this.ModalService.showModalInfo(
                {
                    modalID: modalId,
                    template: require("../view/wizard/freight/freightWizardModal.html"),
                    formService: 'register',
                    size: 'full modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                },
                {
                    model: freightWizard,
                }
            );
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }
}

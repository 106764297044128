import * as angular from "angular";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog, } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { ParamsDetDemModel, ParamsDetDemReference } from "../model/ParamsDetDemModel";
import { SelectorModel } from "../../common/model/SelectorModel";
import { IProviderListCustomFilter } from "@models/interface/product/ProviderModel";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { IEventParameter, ILinkParameter } from "../../common/model/ModelParameter";
import { ISessionService } from "@services/SessionService";
import { BrowserTitle } from "../../common/BrowserTitle";
import { IEquipmentSelector } from "@models/interface/product/EquipmentModel";
import { ValidateUtil } from "../../common/util/ValidateUtil";
import { SSEService } from '@appServices/SSEService';
import { OperationalService } from "@services/OperationalService";
import { HelperService } from "@services/HelperService";

interface IParamsDetDemScope extends IGridFormServiceScope {
    form: ng.IFormController;
    model: ParamsDetDemModel;
    log: IViewLog;
    productList: SelectorModel[];
    natureList: SelectorModel[];
    legalPersonList: SelectorModel[];
    equipmentList: IEquipmentSelector[];
    countryList: SelectorModel[];
    ruleList: SelectorModel[];
    portTypeList: SelectorModel[];
    chargeList: SelectorModel[];
    accountList: SelectorModel[];
    followUpReferenceTypeList: SelectorModel[];
    referenceStartList: SelectorModel[];
    referenceEndList: SelectorModel[];
    shipownerList: SelectorModel[];
    chargeNameList: SelectorModel[];
    typeList: SelectorModel[];
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: ParamsDetDemModel;
    getEquipmentListByNameOrCode: (search: string) => Promise<void>;
    getStartMaritimeEventListByName: (search: string) => Promise<void>;
    getEndMaritimeEventListByName: (search: string) => Promise<void>;
    getCountryListByNameOrInitials: (search: string) => Promise<void>;
    getShipownerListByName: (search: string) => Promise<void>;
    getChargeNameListByName: (search: string) => Promise<void>;
    getAccountListByName: (search: string) => Promise<void>;
    goToEvent: (eventNumber: string) => void;
    goToChargeName: (id: number) => void;
    goToCountry: (id: number) => void;
    goToProvider: (id: number) => void;
    goToAccount: (id: number) => void;
    goToEquipment: (equipment: SelectorModel[]) => void;
    typeReferenceStartChange: () => void;
    typeReferenceEndChange: () => void;
    checkDateValidity: (initialDate: Date, finalDate: Date) => void;
    validateStartReferenceValue: () => void;
}

export class ParamsDetDemRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IParamsDetDemScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private sessionService: ISessionService;
    private SSEService: SSEService;
    private operationalService: OperationalService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IParamsDetDemScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.operationalService = $injector.get('OperationalService');
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();

            this.initForm(this, 'form', 'paramsDetDem', 'GENERAL.MENU.DETENTION_DEMURRAGE_PARAMETERIZATION', true);
            await this.initGrid('gridParamsDetDem', '/paramsDetDem/list', true, true, null, true, null);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.getEquipmentListByNameOrCode = async (search: string) => {
            let equipmentList: IEquipmentSelector[] = [];
            if (search && search.length >= 3) equipmentList = await this.getEquipmentListByNameOrCode(search);
            this.$scope.equipmentList = equipmentList;
        }

        this.$scope.getStartMaritimeEventListByName = async (search: string) => {
            let referenceStartList: ParamsDetDemReference[] = [];

            if (search && search.length >= 3) {
                referenceStartList = await this.getMaritimeEventListByName(search);
            }
            this.$scope.referenceStartList = referenceStartList;
        }

        this.$scope.getEndMaritimeEventListByName = async (search: string) => {
            await this.validateStartReferenceValue(search);

            let referenceEndList: ParamsDetDemReference[] = [];
            const internalSeq = this.$scope.model.REFERENCE_START.INTERNAL_SEQUENCE;

            if (search && search.length >= 3) {
                referenceEndList = await this.getMaritimeEventListByName(search, internalSeq);
            }
            this.$scope.referenceEndList = referenceEndList;
        }

        this.$scope.getCountryListByNameOrInitials = async (search: string) => {
            let countryList: SelectorModel[] = [];
            if (search && search.length >= 2) countryList = await this.getCountryListByNameOrInitials(search);
            this.$scope.countryList = countryList;
        }

        this.$scope.getShipownerListByName = async (search: string) => {
            let shipownerList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                const filter: IProviderListCustomFilter = { search: search, types: ["1"] };
                shipownerList = await this.getProviderListByName(filter); 8
            }
            this.$scope.shipownerList = shipownerList;
        }

        this.$scope.getChargeNameListByName = async (search: string) => {
            let chargeNameList: SelectorModel[] = [];
            if (search && search.length >= 3) chargeNameList = await this.getChargeNameListByName(search);
            this.$scope.chargeNameList = chargeNameList;
        }

        this.$scope.getAccountListByName = async (search: string) => {
            let accountList: SelectorModel[] = [];
            if (search && search.length >= 3) accountList = await this.getAccountListByName(search);
            this.$scope.accountList = accountList;
        }

        this.$scope.goToEvent = (eventNumber: string) => {
            this.sessionService.openTab("app.registration.event", <IEventParameter>{ EVENT_NUMBER: eventNumber });
        }

        this.$scope.goToChargeName = (id: number) => {
            this.sessionService.openTab("app.registration.chargeName", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToCountry = (id: number) => {
            this.sessionService.openTab("app.registration.country", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToProvider = (id: number): void => {
            this.sessionService.openTab("app.registration.provider", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToAccount = (id: number): void => {
            this.sessionService.openTab("app.commercial.account", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToEquipment = (equipments: SelectorModel[]): void => {
            this.sessionService.openTab("app.registration.equipment", <ILinkParameter>{ ID: this.$scope.getCONCAT(equipments, null, "ID") });
        }

        this.$scope.checkDateValidity = (initialDate: Date, finalDate: Date): void => {
            this.checkDateValidity(initialDate, finalDate);
        }
    }

    initGridColumns(columns: string[]) {
        const gridColumns = new GridColumnBuilder([]);

        gridColumns.includeActionBar([
            GridColumnBuilderConstants.BTN_VIEW,
            GridColumnBuilderConstants.BTN_EDIT,
            GridColumnBuilderConstants.BTN_COPY,
            GridColumnBuilderConstants.BTN_VIEWLOG
        ], 25);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 120 };
            const colNature: IMonacoColumnDef = { name: "NATURE.NAME", displayName: "BASIC_DATA.PAYMENT_NATURE", width: 200 };
            const colServiceProvider: IMonacoColumnDef = { name: "SERVICE_PROVIDER.NAME", displayName: "BASIC_DATA.PROVIDER", width: 300 };
            const colAccount: IMonacoColumnDef = { name: "ACCOUNT.NAME", displayName: "BASIC_DATA.CLIENT", width: 300 };
            const colEquipment: IMonacoColumnDef = { name: "EQUIPMENT.CODE", displayName: "BASIC_DATA.EQUIPMENT", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.EQUIPMENT, null, "CODE")}}</div>' };
            const colCountry: IMonacoColumnDef = { name: "COUNTRY.NAME", displayName: "ROUTE.COUNTRY", width: 250 };
            const colRule: IMonacoColumnDef = { name: "RULE.NAME", displayName: "REGISTRATION.RULE", width: 200 };
            const colProgressive: IMonacoColumnDef = { name: "PROGRESSIVE", displayName: "PRODUCT.PROGRESSIVE", width: 120, cellFilter: "YesOrNo" };
            const colPortType: IMonacoColumnDef = { name: "PORT_TYPE.NAME", displayName: "PRODUCT.WAY", width: 150 };
            const colCharge: IMonacoColumnDef = { name: "CHARGE.NAME", displayName: "BASIC_DATA.CHARGE", width: 200 };
            const colReferenceStart: IMonacoColumnDef = { name: "REFERENCE_START.NAME", displayName: "GENERAL.REFERENCE_START", width: 250 };
            const colStartRule: IMonacoColumnDef = { name: "START_RULE", displayName: "GENERAL.REFERENCE_RULE_D_START", width: 180 };
            const colReferenceEnd: IMonacoColumnDef = { name: "REFERENCE_END.NAME", displayName: "GENERAL.REFERENCE_END", width: 250 };
            const colEndRule: IMonacoColumnDef = { name: "END_RULE", displayName: "GENERAL.REFERENCE_RULE_D_END", width: 180 };
            const colInitialValidity: IMonacoColumnDef = { name: "DISPLAY_INITIAL_VALIDITY", displayName: "PRODUCT.VALIDITY_START", width: 150, cellFilter: "simpleDate" };
            const colFinalValidity: IMonacoColumnDef = { name: "DISPLAY_FINAL_VALIDITY", displayName: "PRODUCT.VALIDITY_END", width: 150, cellFilter: "simpleDate" };
            const colType: IMonacoColumnDef = { name: "TYPE.NAME", displayName: "GENERAL.TYPE", width: 150 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 100, cellFilter: "YesOrNo" };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'NATURE':
                        columnDefs.push(colNature);
                        break;
                    case 'SERVICE_PROVIDER':
                        columnDefs.push(colServiceProvider);
                        break;
                    case 'ACCOUNT':
                        columnDefs.push(colAccount);
                        break;
                    case 'EQUIPMENT':
                        columnDefs.push(colEquipment);
                        break;
                    case 'COUNTRY':
                        columnDefs.push(colCountry);
                        break;
                    case 'RULE':
                        columnDefs.push(colRule);
                        break;
                    case 'PROGRESSIVE':
                        columnDefs.push(colProgressive);
                        break;
                    case 'PORT_TYPE':
                        columnDefs.push(colPortType);
                        break;
                    case 'CHARGE':
                        columnDefs.push(colCharge);
                        break;
                    case 'REFERENCE_START':
                        columnDefs.push(colReferenceStart);
                        break;
                    case 'START_RULE':
                        columnDefs.push(colStartRule);
                        break;
                    case 'REFERENCE_END':
                        columnDefs.push(colReferenceEnd);
                        break;
                    case 'END_RULE':
                        columnDefs.push(colEndRule);
                        break;
                    case 'DISPLAY_INITIAL_VALIDITY':
                        columnDefs.push(colInitialValidity);
                        break;
                    case 'DISPLAY_FINAL_VALIDITY':
                        columnDefs.push(colFinalValidity);
                        break;
                    case 'TYPE':
                        columnDefs.push(colType);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            ACTIVE: true,
            PRODUCT: null,
            NATURE: null,
            ID_SERVICE_PROVIDER: null,
            SERVICE_PROVIDER: null,
            ID_ACCOUNT: null,
            ACCOUNT: null,
            EQUIPMENT: null,
            ID_COUNTRY: null,
            COUNTRY: null,
            RULE: null,
            PROGRESSIVE: true,
            PORT_TYPE: null,
            ID_CHARGE: null,
            CHARGE: null,
            REFERENCE_START: null,
            START_RULE: null,
            REFERENCE_END: null,
            END_RULE: null,
            INITIAL_VALIDITY: null,
            DISPLAY_INITIAL_VALIDITY: null,
            FINAL_VALIDITY: null,
            DISPLAY_FINAL_VALIDITY: null,
            TYPE: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null
        };
    }

    initDependencies(): Promise<any> {
        const self: ParamsDetDemRegisterController = this;

        $('.numberRule')["mask"]('#', {
            reverse: true,
            translation: {
                '#': {
                    pattern: /-|\d/,
                    recursive: true
                }
            },
            onChange: function (value, event) {
                if (event) {
                    if (value && value.length > 1 && value.charAt(0) != "-") event.target.value = value.substring(1);
                    else event.target.value = value.replace(/(?!^)-/g, '').replace(/^,/, '').replace(/^-,/, '-');
                }
            }
        });

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getProductList(),
                self.getNatureList(),
                self.getRuleList(),
                self.getPortTypeList(),
                self.getFollowUpReferenceTypeList(),
                self.getTypeList(),
            ]).then((result: any) => {
                self.$scope.productList = result[0];
                self.$scope.natureList = result[1];
                self.$scope.ruleList = result[2];
                self.$scope.portTypeList = result[3];
                self.$scope.followUpReferenceTypeList = self.followUpReferenceTypeListCustomFilter(result[4]);
                self.$scope.typeList = result[5];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: "ACTIVE", LABEL: "GENERAL.ACTIVE" },
            { PROPERTY: "NATURE", LABEL: "BASIC_DATA.PAYMENT_NATURE" },
            { PROPERTY: "EQUIPMENT", LABEL: "BASIC_DATA.EQUIPMENT" },
            { PROPERTY: "NAME", LABEL: "GENERAL.NAME" },
            { PROPERTY: "RULE", LABEL: "REGISTRATION.RULE" },
            { PROPERTY: "PROGRESSIVE", LABEL: "PRODUCT.PROGRESSIVE" },
            { PROPERTY: "PORT_TYPE", LABEL: "PRODUCT.WAY" },
            { PROPERTY: "REFERENCE_START", LABEL: "GENERAL.REFERENCE_START" },
            { PROPERTY: "START_RULE", LABEL: "GENERAL.REFERENCE_RULE_D_START" },
            { PROPERTY: "REFERENCE_END", LABEL: "GENERAL.REFERENCE_END" },
            { PROPERTY: "END_RULE", LABEL: "GENERAL.REFERENCE_RULE_D_END" },
            { PROPERTY: "DISPLAY_INITIAL_VALIDITY", LABEL: "PRODUCT.VALIDITY_START" },
            { PROPERTY: "DISPLAY_FINAL_VALIDITY", LABEL: "PRODUCT.VALIDITY_END" },
            { PROPERTY: "PRODUCT", LABEL: "BASIC_DATA.PRODUCT" },
            { PROPERTY: "SERVICE_PROVIDER", LABEL: "BASIC_DATA.PROVIDER" },
            { PROPERTY: "COUNTRY", LABEL: "ROUTE.COUNTRY" },
            { PROPERTY: "ACCOUNT", LABEL: "BASIC_DATA.CLIENT" },
            { PROPERTY: "CHARGE", LABEL: "BASIC_DATA.CHARGE" },
            { PROPERTY: 'CREATED_AT', LABEL: 'GENERAL.CREATED_AT' },
            { PROPERTY: 'CREATED_BY', LABEL: 'GENERAL.CREATED_BY' },
            { PROPERTY: 'UPDATED_AT', LABEL: 'GENERAL.UPDATED_AT' },
            { PROPERTY: 'UPDATED_BY', LABEL: 'GENERAL.UPDATED_BY' },
            { PROPERTY: 'CHARGE_NAME_EXHIBITION_DEFAULT', LABEL: 'BASIC_DATA.CHARGE_DISPLAY' },
            { PROPERTY: 'SCAC_IATA', LABEL: 'GENERAL.SCAC_CODE' },
            { PROPERTY: 'LEGAL_PERSON_NAME', LABEL: 'ENTITY.LEGAL_PERSON' },
            { PROPERTY: 'NAME_INTL', LABEL: 'GENERAL.NAME_INTL' },
            { PROPERTY: 'TYPE', LABEL: 'GENERAL.TYPE' }
        ]
        return props;
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlDataManager = this.config.productUrl + baseRoute;
        return urlDataManager;
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getNatureList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/payment_nature`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getRuleList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/network_days`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getPortTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/port_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getFollowUpReferenceTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/followup_reference_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/det_dem_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private followUpReferenceTypeListCustomFilter(data: SelectorModel[]): SelectorModel[] {
        let filteredList: SelectorModel[] = [];
        if (data) {
            filteredList = data.filter(obj => obj.ID !== "1" && obj.ID !== "3");
        }
        return filteredList;
    }

    private async getEquipmentListByNameOrCode(search?: string): Promise<IEquipmentSelector[]> {
        let result: IEquipmentSelector[] = [];
        try {
            if (!this.$scope.model.PRODUCT) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
            this.formService.block();
            const products = [this.$scope.model.PRODUCT.ID];
            const equipments = await this.RestService.newObjectPromise(`${this.$baseUrl}/equipment/list/custom`, { search, products }, 30000, false);
            if (equipments) result = equipments.map(equipment => { return { ID: equipment.ID, NAME: equipment.NAME, CODE: equipment.CODE, TEU: equipment.TEU, FEET: equipment.FEET } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCountryListByNameOrInitials(search?: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const countries = await this.RestService.newObjectPromise(`${this.$baseUrl}/country/list/custom`, { search }, 30000, false);
            if (countries) result = countries.map(country => { return { ID: country.ID, NAME: country.NAME, CODE: country.INITIALS } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getProviderListByName(filter?: IProviderListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const providers: SelectorModel[] = await this.RestService.newObjectPromise(`${this.$baseUrl}/provider/list/custom`, filter, 30000, false);
            if (providers) result = providers;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getMaritimeEventListByName(search?: string, internalSeq?: number): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const events = await this.operationalService.post('/events/list/custom', { search, modalGroups: ["2"], paramsDetDem: true, internalSeq });
            const detDemEvents = events && events.data && events.data.data ? events.data.data : null;

            if (detDemEvents) result = detDemEvents.map(event => {
                return {
                    ID: event.ID,
                    NAME: event.EVENT.NAME,
                    CODE: event.EVENT_NUMBER,
                    INTERNAL_SEQUENCE: event.INTERNAL_SEQUENCE
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async validateStartReferenceValue(search: string): Promise<void> {
        if (!search) return
        try {
            if (!this.$scope.model.REFERENCE_START) {
                throw Error(this.formService.getTranslate(
                    "GENERAL.SELECT_PROP_FIRST",
                    { prop: this.formService.getTranslate("GENERAL.REFERENCE_START") }
                ));
            }
            this.formService.block();
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getChargeNameListByName(search?: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const charges = await this.RestService.newObjectPromise(`${this.$baseUrl}/chargeName/list/custom`, { search: search }, 30000, false);
            if (charges) result = charges.map(charge => { return { ID: charge.ID, NAME: charge.NAME, CODE: charge.CODE, CHARGE_NAME_EXHIBITION_DEFAULT: charge.CHARGE_NAME_EXHIBITION_DEFAULT } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getAccountListByName(search?: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const accounties = await this.RestService.newObjectPromise(`${this.$baseUrl}/account/list/custom`, { search: search }, 30000, false);
            if (accounties) result = accounties.map(account => { return { ID: account.ID, NAME: account.NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private initFormOperation() {
        BrowserTitle.$id = this.getBrowserTitle();
        this.formatDateStrings();
    }

    private getBrowserTitle(): string {
        let browserTitle = "";
        try {
            if (this.$scope.model.PRODUCT) browserTitle += browserTitle.length > 0 ? ` | ${this.$scope.model.PRODUCT.ID}` : this.$scope.model.PRODUCT.ID;
            if (this.$scope.model.NATURE) browserTitle += browserTitle.length > 0 ? ` | ${this.$scope.model.NATURE.NAME}` : this.$scope.model.NATURE.NAME;
            if (this.$scope.model.SERVICE_PROVIDER) browserTitle += browserTitle.length > 0 ? ` | ${this.$scope.model.SERVICE_PROVIDER.NAME}` : this.$scope.model.SERVICE_PROVIDER.NAME;
            if (this.$scope.model.ACCOUNT) browserTitle += browserTitle.length > 0 ? ` | ${this.$scope.model.ACCOUNT.NAME}` : this.$scope.model.ACCOUNT.NAME;
            if (this.$scope.model.EQUIPMENT && this.$scope.model.EQUIPMENT.length > 0) browserTitle += browserTitle.length > 0 ? ` | ${this.$scope.model.EQUIPMENT.map(obj => { return obj.NAME }).join(',')}` : this.$scope.model.EQUIPMENT.map(obj => { return obj.NAME }).join(',');
            if (this.$scope.model.COUNTRY) browserTitle += browserTitle.length > 0 ? ` | ${this.$scope.model.COUNTRY.NAME}` : this.$scope.model.COUNTRY.NAME;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return browserTitle;
        }
    }

    private formatDateStrings(): void {
        this.$scope.model.DISPLAY_INITIAL_VALIDITY = (this.$scope.model.DISPLAY_INITIAL_VALIDITY != null) ? new Date(this.$scope.model.DISPLAY_INITIAL_VALIDITY) : null;
        this.$scope.model.DISPLAY_FINAL_VALIDITY = (this.$scope.model.DISPLAY_FINAL_VALIDITY != null) ? new Date(this.$scope.model.DISPLAY_FINAL_VALIDITY) : null;
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.initFormOperation();
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} (${this.getBrowserTitle()})`;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.initFormOperation();
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} (${this.getBrowserTitle()})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.initFormOperation();
            this.SSEService.closeEvents();
            await this.clearFields(this.$scope.model);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === "edit" ? "update" : "insert";

        return {
            route: `/paramsDetDem/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: this.$scope.scopeBeforeSave
        };
    }

    private checkDateValidity(initialDate: Date, finalDate: Date): void {
        let isValid = false;
        if (!initialDate || typeof initialDate == "string") return;
        if (!finalDate || typeof finalDate == "string") return;
        isValid = ValidateUtil.isValidDateRange(initialDate, finalDate);
        if (!isValid) {
            this.$scope.model.DISPLAY_FINAL_VALIDITY = null;
        }
    }
}

import { IGridRow, IColumnDef } from "ui-grid";
import { GridFormService, IGridFormController, IGridFormServiceScope } from "@services/GridFormService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IModalService } from "@services/ModalService";
import { ISessionService } from '@services/SessionService';
import { ProductService } from "@services/ProductService";
import { ITariffTransactionManagement } from "@models/interface/product/TariffTransactionManagement";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { ILinkParameter, IOfferWizardFinish, IAutoRatingParameter } from "../../common/model/ModelParameter";
import { ETariffOrigin, ETariffTransactionManagementSituationId, EOperation } from '@enums/GenericData';
import { SSEService } from '@appServices/SSEService';
import { ISelectorModel } from "@models/mongo/SelectorModel";
import { ITariffLocalExchangeData } from "../model/TariffLocalModel";
import { IInlandRoutesExchangeData } from "WBA-Model/dist/interface/product/InlandRoutes";
import { IFreightRoutesExchangeData } from "WBA-Model/dist/interface/product/FreightRoutes";

export interface IMonacoRequest<T = any> {
    route?: string;
    operation?: string;
    data?: T;
    timeout?: number;
    user?: any;
}

interface ITariffTransactionManagementScope extends IGridFormServiceScope {
    model: ITariffTransactionManagement;
    selectedRows: ITariffTransactionManagement[];
    actionsDisabled: Boolean;
    executeCellAction: Function;

    clearSelections: () => Promise<void>;
    goToOffer: (offerCodeExhibition: string, offerId: number) => void;
    goToTariff: (tariffType: string, tariffID: string) => void;
    goToAutoRatingParam: (idAutoRatingParam: number) => void;
    approveAutoRating: (model: ITariffTransactionManagement) => void;
    disapproveAutoRating: (model: ITariffTransactionManagement) => void;
    viewLogTariffTransactionManagement: (model: ITariffTransactionManagement) => void;

    form: ng.IFormController;
}

export class TariffTransactionManagementRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ["$injector", "$scope", "$element"];
    private $element: JQLite;
    private $scope: ITariffTransactionManagementScope;
    private selectedRows: ITariffTransactionManagement[];
    private ModalService: IModalService;
    private SessionService: ISessionService;
    private ProductService: ProductService;
    private SSEService: SSEService;

    modalID: any;

    constructor($injector: ng.Injectable<any>, $scope: ITariffTransactionManagementScope, $element: JQLite) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$element = $element;
        this.ModalService = $injector.get('ModalService');
        this.SessionService = $injector.get('SessionService');
        this.ProductService = $injector.get('ProductService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
    }

    initScopeFunctions(): void {
        this.$scope.clearSelections = async () => {
            await this.clearSelections();
        }

        this.$scope.goToOffer = (offerCodeExhibition: string) => {
            if (!offerCodeExhibition) throw new Error('offerCodeExhibition is null');
            this.SessionService.openTab("app.commercial.offer", <IOfferWizardFinish>{ OFFER_CODE_EXHIBITION: offerCodeExhibition });
        }

        this.$scope.goToTariff = (tariffOriginID: string, tariffID: string) => {
            if (!tariffOriginID) throw new Error('tariffOrigin is null');
            if (!tariffID) throw new Error('tariffID is null');
            switch (tariffOriginID) {
                case ETariffOrigin.TARIFF_LOCAL:
                    const localEndpoint = `${this.config.productUrl}/product/tariffLocal/getCacheById/${tariffID}`;
                    this.SessionService.openTabByValidity(localEndpoint, "app.product.tariffLocal", <ILinkParameter>{ ID: tariffID ? tariffID.toString() : null }, <ITariffLocalExchangeData>{ OPERATION: EOperation.VIEW, ID: tariffID });
                    break;
                case ETariffOrigin.INLAND_ROUTES:
                    this.SessionService.openTab('app.product.inlandRoutes', <ILinkParameter>{ ID: tariffID ? tariffID.toString() : tariffID }, <IInlandRoutesExchangeData>{ OPERATION: EOperation.VIEW, ID: tariffID ? tariffID.toString() : tariffID });
                    break;
                case ETariffOrigin.FREIGHT_ROUTES:
                    this.SessionService.openTab('app.product.freightRoutes', <ILinkParameter>{ ID: tariffID ? tariffID.toString() : tariffID }, <IFreightRoutesExchangeData>{ OPERATION: EOperation.VIEW, ID: tariffID ? tariffID.toString() : tariffID });
                    break;
            }

        }

        this.$scope.goToAutoRatingParam = (idAutoRatingParam: number) => {
            if (!idAutoRatingParam) throw new Error('idAutoRatingParam is null');
            this.SessionService.openTab("app.product.autoRatingParam", <IAutoRatingParameter>{ ID: idAutoRatingParam.toString() });
        }

        this.$scope.approveAutoRating = async () => {
            this.approveAutoRating(this.$scope.model);
        }

        this.$scope.disapproveAutoRating = async () => {
            this.disapproveAutoRating(this.$scope.model);
        }

        this.$scope.viewLogTariffTransactionManagement = (tariffTransaction: ITariffTransactionManagement): void => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(tariffTransaction, tariffTransaction._id.toString());
        }
    }

    async $onInit(): Promise<void> {
        try {
            this.selectedRows = [];
            this.$scope.selectedRows = [];
            this.$baseUrl = this.config.productUrl + '/product';
            this.initForm(this, "form", "tariffTransactionManagement", "Tariff Transaction Management", false);
            this.block();
            this.$scope.executeCellAction = this.executeCellAction.bind(this);

            // enable multi row selection
            this.$gridService.setSelectable(true);

            // Limit to 50 selectable rows. After this limit callback will be executed
            this.$gridService.setSelectableRowsLimitCallback(25, (limitReached, selectedRowsLength) => {
                this.$scope.actionsDisabled = limitReached;
                const customBreadcrumbArea = this.$element.find('.breadcrum-custom-area');
                customBreadcrumbArea.toggleClass('ng-hide', !limitReached);
                customBreadcrumbArea.html(limitReached ? `<h4 class='selection-limit-reached'>Selection has exceeded the limit of 25 lines. You have ${selectedRowsLength} rows selected.</h4>` : '');
            });

            // init grid
            this.$gridService.$gridOptions.enableFullRowSelection = false;
            await this.initGrid('tariffTransactionManagement', '/tariffTransactionManagement/list', true, true, 120000, true, true);
            this.$gridService.setBackgroundUpdate(120000, [this.selectionReapply, this]);

            this.monitoringFilterChanges();

            // register grid multiple rows selection callback
            this.$gridService.$gridApi.selection.on.rowSelectionChanged(this.$scope, this.selectedRowCallback.bind(this));
            this.$gridService.$gridApi.selection.on.rowSelectionChangedBatch(this.$scope, this.selectedRowBatchCallback.bind(this));

            this.unblock();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    executeCellAction(actionName: string, entity: any) {
        const actionToByPassDisableRule = ['approveAutoRating', 'disapproveAutoRating']
        if (this.$scope.actionsDisabled && !actionToByPassDisableRule.includes(actionName)) {
            this.$formService.notifyInfo(`Selection has exceeded the limit of 50 lines`);
        } else if (this[actionName] != null && typeof this[actionName] === 'function') {
            this[actionName].call(this, entity);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);
        const columnDefs: Array<IColumnDef> = gridColumns.$columnDefs;

        const approve = `<span><a ng-disabled="row.entity.SITUATION.ID == ${ETariffTransactionManagementSituationId.APPROVED} || row.entity.SITUATION.ID == ${ETariffTransactionManagementSituationId.CANCELED}" ng-click="grid.appScope.executeCellAction(\'approveAutoRating\', row.entity)" class="text-green" tooltip-placement="auto top" uib-tooltip="Aprovar" tooltip-append-to-body="true" ><i class="fa fa-check icon"></i></a>&nbsp;&nbsp;`;
        const disapprove = `<span><a ng-disabled="row.entity.SITUATION.ID == ${ETariffTransactionManagementSituationId.APPROVED} || row.entity.SITUATION.ID == ${ETariffTransactionManagementSituationId.CANCELED}" ng-click="grid.appScope.executeCellAction(\'disapproveAutoRating\', row.entity)" class="text-danger" tooltip-placement="auto top" uib-tooltip="Reprovar" tooltip-append-to-body="true" ><i class="fa fa-ban icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogTariffTransactionManagement(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;

        const actions =
            `<div class="text-center">
            ${approve}
            ${disapprove}
            ${viewLog}
        </div>`;

        const statusColDef: IMonacoColumnDef = {
            name: "acoes",
            minWidth: 75,
            maxWidth: 75,
            cellTemplate: (actions),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            allowCellFocus: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true,
            visible: true,
        }
        columnDefs.push(statusColDef);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colIdTariffTransaction: IMonacoColumnDef = { name: "ID_TARIFF_TRANSACTION", displayName: "PRODUCT.AUTO_RATING_TRANSACTION_ID", width: 200 };
            const colIdException: IMonacoColumnDef = { name: "ID_AUTO_RATING_PARAM", displayName: "PRODUCT.AUTO_RATING_ID_EXCEPTION", width: 200, cellTemplate: '<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="Click view auto rating param" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToAutoRatingParam(row.entity.ID_AUTO_RATING_PARAM)">{{row.entity.ID_AUTO_RATING_PARAM}}</a></div>' };
            const colCurrentCharge: IMonacoColumnDef = { name: "CURRENT_CHARGE", displayName: "GENERAL.CURRENT_CHARGE", width: 80, cellFilter: "YesOrNo" };
            const colAction: IMonacoColumnDef = { name: "ACTION.NAME", displayName: "GENERAL.ACTION", width: 100 };
            const colSituation: IMonacoColumnDef = { name: "SITUATION.NAME", displayName: "GENERAL.SITUATION", width: 150 };
            const colType: IMonacoColumnDef = { name: "TYPE.NAME", displayName: "GENERAL.TYPE", width: 100 };
            const colOriginType: IMonacoColumnDef = { name: "ORIGIN_TYPE.NAME", displayName: "BASIC_DATA.TRANSACTION", width: 100 };
            const colChargeName: IMonacoColumnDef = { name: "CHARGE_NAME_EXHIBITION.CODE", displayName: "BASIC_DATA.DISPLAY_CHARGE", width: 200 };
            const colOriginTransaction: IMonacoColumnDef = { name: "ORIGIN_TRANSACTION.NAME", displayName: "PRODUCT.TRANSACTION_ORIGIN", width: 200 };
            const colOffer: IMonacoColumnDef = { name: "OFFER_CODE_EXHIBITION", displayName: "BASIC_DATA.OFFER", width: 200, cellTemplate: '<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="Click view offer {{row.entity.OFFER_CODE_EXHIBITION}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToOffer(row.entity.OFFER_CODE_EXHIBITION)">{{row.entity.OFFER_CODE_EXHIBITION}}</a></div>' };
            const colTariff: IMonacoColumnDef = { name: "TARIFF_CONCATENATED", displayName: "GENERAL.TARIFF", width: 200, cellTemplate: `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="Click to view tariff" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="row.entity.ID_TARIFF_LOCAL ? grid.appScope.goToTariff(row.entity.ORIGIN_TRANSACTION.ID, row.entity.ID_TARIFF_LOCAL) : row.entity.ID_INLAND_ROUTES ? grid.appScope.goToTariff(row.entity.ORIGIN_TRANSACTION.ID, row.entity.ID_INLAND_ROUTES) : row.entity.ID_FREIGHT_ROUTES ? grid.appScope.goToTariff(row.entity.ORIGIN_TRANSACTION.ID, row.entity.ID_FREIGHT_ROUTES) : null"> {{row.entity.TARIFF_CONCATENATED}}</a></div>` };
            const colOldValue: IMonacoColumnDef = { name: "OLD_VALUE", displayName: "GENERAL.OLD_VALUE", width: 200 };
            const colNewValue: IMonacoColumnDef = { name: "NEW_VALUE", displayName: "GENERAL.NEW_VALUE", width: 200 };
            const colVariation: IMonacoColumnDef = { name: "VARIATION", displayName: "PRODUCT.AUTO_RATING_VARIATION", width: 200 };
            const colPerson: IMonacoColumnDef = { name: "PERSON.NAME", displayName: "GENERAL.HOLDER", width: 200 };
            const colExporter: IMonacoColumnDef = { name: "EXPORTERS.NAME", displayName: "BASIC_DATA.SHIPPER", width: 200, visible: false, cellTemplate: '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.EXPORTERS, null, "NAME")}}</div>' };
            const colImporter: IMonacoColumnDef = { name: "IMPORTERS.NAME", displayName: "BASIC_DATA.CONSIGNEE", width: 200, visible: false, cellTemplate: '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.IMPORTERS, null, "NAME")}}</div>' };
            const colProvider: IMonacoColumnDef = { name: "PROVIDER.NAME", displayName: "BASIC_DATA.PROVIDER", width: 200, visible: false };
            const colAccount: IMonacoColumnDef = { name: "ACCOUNT.NAME", displayName: "GENERAL.ACCOUNT", width: 200 };
            const colTypeCargo: IMonacoColumnDef = { name: "TYPE_CARGO.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 200, visible: false };
            const colSalesPerson: IMonacoColumnDef = { name: "SALES_PERSON.NAME", displayName: "BASIC_DATA.SALES_EXECUTIVE", width: 200, visible: false };
            const colAuditedBy: IMonacoColumnDef = { name: "AUDITED_BY.NAME", displayName: "GENERAL.AUDITED_BY", width: 200, visible: false };
            const colAuditedAt: IMonacoColumnDef = { name: "AUDITED_AT", displayName: "GENERAL.AUDITED_ON", width: 200, cellFilter: 'date:\'dd/MM/yyyy\'', visible: false };
            const colCreatedByJob: IMonacoColumnDef = { name: "CREATED_BY_JOB", displayName: "GENERAL.CREATED_BY_JOB", width: 200, cellFilter: "YesOrNo" };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 200, cellFilter: 'date:\'dd/MM/yyyy\'' };
            const colOldValidityStart: IMonacoColumnDef = { name: "OLD_VALIDITY_START_ORIGINAL", displayName: "PRODUCT.OLD_VALIDITY_START", width: 180, cellFilter: 'date:\'dd/MM/yyyy\'' };
            const colOldValidityEnd: IMonacoColumnDef = { name: "OLD_VALIDITY_END_ORIGINAL", displayName: "PRODUCT.OLD_VALIDITY_END", width: 180, cellFilter: 'date:\'dd/MM/yyyy\'' };
            const colNewValidityStart: IMonacoColumnDef = { name: "NEW_VALIDITY_START_ORIGINAL", displayName: "PRODUCT.NEW_VALIDITY_START", width: 180, cellFilter: 'date:\'dd/MM/yyyy\'' };
            const colNewValidityEnd: IMonacoColumnDef = { name: "NEW_VALIDITY_END_ORIGINAL", displayName: "PRODUCT.NEW_VALIDITY_END", width: 180, cellFilter: 'date:\'dd/MM/yyyy\'' };
            const colResponsible: IMonacoColumnDef = { name: "CREATED_BY.NAME", displayName: "PRODUCT.RESPONSIBLE", width: 150 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'ID_TARIFF_TRANSACTION':
                        columnDefs.push(colIdTariffTransaction);
                        break;
                    case 'ID_AUTO_RATING_PARAM':
                        columnDefs.push(colIdException);
                        break;
                    case 'CURRENT_CHARGE':
                        columnDefs.push(colCurrentCharge);
                        break;
                    case 'ACTION':
                        columnDefs.push(colAction);
                        break;
                    case 'SITUATION':
                        columnDefs.push(colSituation);
                        break;
                    case 'TYPE':
                        columnDefs.push(colType);
                        break;
                    case 'ORIGIN_TYPE':
                        columnDefs.push(colOriginType);
                        break;
                    case 'CHARGE_NAME_EXHIBITION':
                        columnDefs.push(colChargeName);
                        break;
                    case 'ORIGIN_TRANSACTION':
                        columnDefs.push(colOriginTransaction);
                        break;
                    case 'OFFER_CODE_EXHIBITION':
                        columnDefs.push(colOffer);
                        break;
                    case 'TARIFF_CONCATENATED':
                        columnDefs.push(colTariff);
                        break;
                    case 'OLD_VALUE':
                        columnDefs.push(colOldValue);
                        break;
                    case 'NEW_VALUE':
                        columnDefs.push(colNewValue);
                        break;
                    case 'VARIATION':
                        columnDefs.push(colVariation);
                        break;
                    case 'PERSON':
                        columnDefs.push(colPerson);
                        break;
                    case 'EXPORTERS':
                        columnDefs.push(colExporter);
                        break;
                    case 'IMPORTERS':
                        columnDefs.push(colImporter);
                        break;
                    case 'PROVIDER':
                        columnDefs.push(colProvider);
                        break;
                    case 'ACCOUNT':
                        columnDefs.push(colAccount);
                        break;
                    case 'TYPE_CARGO':
                        columnDefs.push(colTypeCargo);
                        break;
                    case 'SALES_PERSON':
                        columnDefs.push(colSalesPerson);
                        break;
                    case 'AUDITED_BY':
                        columnDefs.push(colAuditedBy);
                        break;
                    case 'AUDITED_AT':
                        columnDefs.push(colAuditedAt);
                        break;
                    case 'CREATED_BY_JOB':
                        columnDefs.push(colCreatedByJob);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'OLD_VALIDITY_START_ORIGINAL':
                        columnDefs.push(colOldValidityStart);
                        break;
                    case 'OLD_VALIDITY_END_ORIGINAL':
                        columnDefs.push(colOldValidityEnd);
                        break;
                    case 'NEW_VALIDITY_START_ORIGINAL':
                        columnDefs.push(colNewValidityStart);
                        break;
                    case 'NEW_VALIDITY_END_ORIGINAL':
                        columnDefs.push(colNewValidityEnd);
                        break;
                    case 'CREATED_BY':
                        columnDefs.push(colResponsible);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    public async selectionReapply(): Promise<void> {
        const rows = this.$gridService.$gridApi.core.getVisibleRows(this.$gridService.$gridApi.grid);
        const selectedRows = this.$gridService.$gridSelectedRows;

        if (selectedRows && selectedRows.length > 0) {
            const updatedRows: IGridRow[] = [];
            for (const row of selectedRows) {
                const foundRow = rows.find(x => x.entity._id == row._id);
                if (foundRow) {
                    foundRow.setSelected(true);
                    updatedRows.push(foundRow);
                }
            }
            this.selectedRowBatchCallback(updatedRows);
        }
    }

    private async clearSelections(): Promise<void> {
        this.$scope.selectedRows = [];
        this.$gridService.$gridApi.selection.clearSelectedRows();

        if (this.$scope.log) this.$scope.log.show = false;
    }

    private monitoringFilterChanges() {
        this.$gridService.$gridApi.core.on.filterChanged(this.$scope, this.clearSelections.bind(this));
    }

    private selectedRowCallback(row: IGridRow): void {
        try {
            this.block();

            const tariffTransactions: ITariffTransactionManagement = row.entity;

            const i = this.selectedRows.findIndex(x => x._id === tariffTransactions._id);

            if (row.isSelected && i === -1)
                this.selectedRows.push(tariffTransactions);
            else
                this.selectedRows.splice(i, 1);

            // update scope
            this.$scope.selectedRows = this.selectedRows.slice();

            if (this.selectedRows.length > 1 && this.$scope.log) this.$scope.log.show = false;

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private selectedRowBatchCallback(rows: IGridRow[]): void {
        try {
            rows = this.$gridService.$gridApi.selection.getSelectedGridRows();
            this.block();

            this.selectedRows = rows.filter(x => x.isSelected).map(x => x.entity);
            if (this.selectedRows.length > 1 && this.$scope.log) this.$scope.log.show = false;

            this.$scope.selectedRows = this.selectedRows.slice();

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID_TARIFF_TRANSACTION: null,
            UUID: null,
            ID_OFFER: null,
            ID_AUTO_RATING_PARAM: null,
            ID_TARIFF_LOCAL: null,
            ID_TARIFF_FREIGHT: null,
            ID_TARIFF_DOMESTIC: null,
            ID_TARIFF_DET_DEM: null,
            ID_TARIFF_SECUNDARY: null,
            ID_INLAND_ROUTES: null,
            ID_FREIGHT_ROUTES: null,
            ID_TARIFF_LOCAL_CHARGE: null,
            ID_OFFER_CHARGE: null,
            ID_OFFER_CHARGE_OUT_DATE: null,
            ID_INLAND_CHARGE: null,
            ID_FREIGHT_CHARGE: null,
            CURRENT_CHARGE: null,
            TARIFF_NATURE: null,
            ACTION: null,
            SITUATION: null,
            TYPE: null,
            ORIGIN_TYPE: null,
            CHARGE_NAME_EXHIBITION: null,
            ORIGIN_TRANSACTION: null,
            OFFER_CODE_EXHIBITION: null,
            TARIFF_CONCATENATED: null,
            ACCOUNT: null,
            OLD_VALUE: null,
            NEW_VALUE: null,
            VARIATION: null,
            PERSON: null,
            EXPORTERS: null,
            IMPORTERS: null,
            PROVIDER: null,
            TYPE_CARGO: null,
            SALES_PERSON: null,
            EQUIPMENT: null,
            VEHICLE_TYPE: null,
            DIRECTION: null,
            AUDITED_BY: null,
            AUDITED_AT: null,
            CREATED_BY_JOB: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            NEW_VALIDITY_START_ORIGINAL: null,
            NEW_VALIDITY_END_ORIGINAL: null,
            OLD_VALIDITY_END_ORIGINAL: null,
            OLD_VALIDITY_START_ORIGINAL: null,
        }
    }

    private canBeApprovedDisapproved(situation: ISelectorModel) {
        if (!situation || situation.ID == ETariffTransactionManagementSituationId.APPROVED || situation.ID == ETariffTransactionManagementSituationId.CANCELED) {
            return false
        }
        return true
    }

    private async approveAutoRating(model: ITariffTransactionManagement): Promise<void> {
        try {
            let tariffTransactions: ITariffTransactionManagement[] = [];

            if (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) {
                tariffTransactions = this.$scope.selectedRows.filter(tariffTransaction => this.canBeApprovedDisapproved(tariffTransaction.SITUATION));
            } else {
                if (this.canBeApprovedDisapproved(model.SITUATION)) tariffTransactions.push(model);
            }

            if (!tariffTransactions || tariffTransactions.length === 0) return this.handleError(this.formService.getTranslate('Selecione transações não aprovadas para aprovação.'));

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.CLOSE',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate('Deseja confirmar a aprovação das transações selecionadas?')
            });
            if (!modal) return;

            this.block();

            const productReq: IMonacoRequest = {
                data: {
                    filters: [...tariffTransactions]
                },
                route: '/tariffTransactionManagement/approve',
                timeout: 60000,
            }

            const result = await this.ProductService.post(productReq);
            if (!result || !result.data) return this.handleError(result);

            await this.clearSelections();
            await this.updateGrid();

            this.unblock()
            this.$formService.notifySuccess(this.formService.getTranslate('GENERAL.UPDATE_SUCESSFULLY_PERFORMED'));

        } catch (ex) {
            this.handleError(ex);
            await this.clearSelections();
            await this.updateGrid();
        }
    }

    private async disapproveAutoRating(model: ITariffTransactionManagement): Promise<void> {
        try {
            let tariffTransactions: ITariffTransactionManagement[] = [];

            if (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) {
                tariffTransactions = this.$scope.selectedRows.filter(tariffTransaction => this.canBeApprovedDisapproved(tariffTransaction.SITUATION));
            } else {
                if (this.canBeApprovedDisapproved(model.SITUATION)) tariffTransactions.push(model);
            }

            if (!tariffTransactions || tariffTransactions.length === 0) return this.handleError(this.formService.getTranslate('Selecione transações não aprovadas para reprovação.'));

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.CLOSE',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate('Deseja confirmar a reprovação das transações selecionadas?')
            });
            if (!modal) return;

            this.block();

            const productReq: IMonacoRequest = {
                data: {
                    filters: [...tariffTransactions]
                },
                route: '/tariffTransactionManagement/disapprove',
                timeout: 60000,
            }

            const result = await this.ProductService.post(productReq);
            if (!result || !result.data) return this.handleError(result);

            await this.clearSelections();
            await this.updateGrid();

            this.unblock()
            this.$formService.notifySuccess(this.formService.getTranslate('GENERAL.UPDATE_SUCESSFULLY_PERFORMED'));

        } catch (ex) {
            this.handleError(ex);
            await this.clearSelections();
            await this.updateGrid();
        }
    }
}

export enum TARGET_PARAM_TYPE {
    DATE = 1,
    YESORNO = 2,
    GENERIC = 3
}
export enum TARGET_TYPE_IDENTIFIER {
    EVENT = 1,
    PROCESS = 2,
    DRAFT = 3,
    DEADLINE = 4,
    INVOICE = 5,
    PROCESS_DOCUMENT = 6,
    TASK = 7
}

import { IApplicationList } from "WBA-Model/dist/interface/product/ApplicationModel";
import { ISelectorModel } from "WBA-Model/dist/mongo/SelectorModel";

export enum EGridConsolidated { GRID_CONSOLIDATED = "GRID_CONSOLIDATED" };

export enum EConsolidatedTariffFields {
    TARIFF_LOCAL_ORIGIN = "1",
    TARIFF_LOCAL_DESTINATION = "2",
    TARIFF_FREIGHT = "3"
}

export interface ITariffList {
    ID: number;
    NAME: string;
}

export interface IApplicationSelectorList extends IApplicationList {
    INTEGRATION_ID: string;
    PRODUCT: ISelectorModel[];
    TYPE_CARGO: ISelectorModel[];
    ALLOWS_APPORTIONMENT: boolean;
    APPORTIONMENT_PARAM: ISelectorModel;
}

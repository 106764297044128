import angular = require('angular');
import { IModalInstanceService } from "angular-ui-bootstrap";
import { IRestService } from "@services/RestService";
import { FormService2, IFormServiceScope } from '@services/FormService2';
import { IModalService } from '@services/ModalService';
import { ISessionService } from '@services/SessionService';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IPercentualComposition } from '@models/interface/product/OfferCharge';
import { ITariffFreightExchangeData } from '@models/interface/product/TariffFreightModel';
import { EApplicationComplementId, EProductId, ETransactionId, EChargeOriginId, EOperation, EDataOriginTypeId } from '@enums/GenericData';
import { IOfferPaymentReceiving, IOfferPaymentReceivingCharge, IOfferContract, IOfferChargeByIdResponse, IOfferChargeOutDate, IOfferChargeOutDateModel } from '../model/OfferModel';
import { IOfferScope, ECollapseState } from './OfferRegisterController';
import { ITableOptions } from "../../app/directives/monaco-data-table";
import { SelectorModel } from "../../common/model/SelectorModel";
import { ILinkParameter } from '../../common/model/ModelParameter';
import { ITariffLocalExchangeData } from '../../product/model/TariffLocalModel';
import { ITariffDomesticExchangeData } from '../../product/model/TariffDomesticModel';
import { IOfferChangeConfirmation } from '@models/interface/product/OfferChangeConfirmation';
import { ISelectorModel } from '@models/mongo/SelectorModel';
import { ProductService } from '@services/ProductService';
import { HelperService } from "@services/HelperService";
import { EApplicationId } from "@enums/GenericData";
import { OfferChargeHelperController } from '../../common/OfferChargeHelperController';

interface IOfferChargeCollapseParams {
    _id?: string;
}

interface IOfferChargeOutDateScope extends angular.IScope {
    log: IViewLog;
    model: IOfferChargeOutDateModel;
    oldModel: IOfferChargeOutDateModel;
    chargesTableOptions: ITableOptions;
    chargesTableList: IOfferChargeOutDate[];
    chargeOriginList: SelectorModel[];
    customLogProperties: ICustomLogProperties[];
    reasonModal: IReasonUpdateModal;
    offerChangeConfirmation: IOfferChangeConfirmation[];
    eventList: SelectorModel[];
    collapseOutDateCharges: () => void;
    viewLog: (allLogs: boolean, uuid?: string) => void;
    isCollapseIn(): boolean;
    openChargesPerWeightRangeModal: () => void;
    openPreviewInvoices: () => void;
    showInvoiceOriginalValues(invoice: IOfferInvoice, type: string): string
    showInvoiceFactors(invoice: IOfferInvoice, types: string[]): string
    viewLogConfirmation: () => void;
}

interface IChargeOutDateModalScope extends IFormServiceScope {
    currentIndex: number;
    currentDisplayIndex: number;
    charge: IOfferChargeByIdResponse;
    oldCharge: IOfferChargeByIdResponse;
    operation: string;
    weightRangeActiveId: string;
    isPaymentWeightRangeApplication: boolean;
    isReceivingWeightRangeApplication: boolean;
    chargesOutDate: IOfferChargeOutDate[];
    isOutDateCharge: boolean;
    isManualCharge: boolean;
    isReceivingChargeNegotiated: boolean;
    goToTariffLocal: (id: number) => void;
    goToTariffDomestic: (id: number) => void;
    goToTariffFreight: (id: number) => void;
    isNotApplicableComplement: (applicationComplement: SelectorModel) => boolean;
    isWeightRangeComplement: (applicationComplement: SelectorModel) => boolean;
    isEquipmentComplement: (applicationComplement: SelectorModel) => boolean;
    isVehicleComplement: (applicationComplement: SelectorModel) => boolean;
    isAirProduct: () => boolean;
    isTransactionOther: (transaction: SelectorModel) => boolean;
    hasPreviousCharge: (currentIndex: number) => boolean;
    hasNextCharge: (currentIndex: number) => boolean;
    previousCharge: (currentIndex: number) => void;
    nextCharge: (currentIndex: number) => void;
    closeChargeModal: () => void;
    prepareTextComposition: (item: IPercentualComposition, complete: boolean) => string;
    setReceivingNegotiated: (receivingCharge: IOfferPaymentReceiving) => void;
    isApplicationScob: (internalSequence: string) => boolean;
    saveCharge: (currentDisplayIndex: number) => void;
    isNegotiated: (receivingCharge: IOfferPaymentReceiving) => boolean;
}

interface IOfferInvoice {
    _id: string;
    ID_OFFER: number;
    TYPE: number;
    CONTRACT: IOfferContract;
    HOLDER: SelectorModel;
    INVOICE: string;
    MODALITY: SelectorModel;
    TOTAL_CURRENT_CURRENCY: number;
    TRANSACTION: SelectorModel[];
    OFFER_CHARGE_PAYMENT: IOfferChargeOutDate[];
    OFFER_CHARGE_RECEIVING: IOfferChargeOutDate[];
}

interface IPreviewInvoiceModalScope extends angular.IScope {
    invoices: IOfferInvoice[];
    editViewCharge: (_id: string) => void;
    goToContract: (id: number) => void;
    showInvoiceFactors: (invoice: IOfferInvoice, types: string[]) => string;
}

interface IReasonUpdateModal {
    DESCRIPTION: string[];
    REASON_OBSERVATION: string;
    CHANGE_REASON: ISelectorModel;
}

export class OfferChargeOutDateRegisterController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IOfferChargeOutDateScope;
    private $offerScope: IOfferScope;
    private $q: ng.IQService;
    private $filter: ng.FilterFactory;
    private $compile: angular.ICompileService;
    private $timeout: ng.ITimeoutService;
    private restService: IRestService;
    private $sce: angular.ISCEService;
    private formService: FormService2;
    private productService: ProductService;
    private modalService: IModalService;
    private sessionService: ISessionService;
    private modalChargeId: number;
    private propertiesToIgnore: string[];
    private modalPreviewInvoiceId: number;
    private helperService: HelperService;
    private offerChargeHelper: OfferChargeHelperController = null;
    private $injector: ng.Injectable<any>;

    constructor($injector: ng.Injectable<any>, $scope: IOfferChargeOutDateScope) {
        this.$scope = $scope;
        this.$injector = $injector;
        this.$offerScope = <IOfferScope>$scope.$parent.$parent;
        this.$q = $injector.get('$q');
        this.$sce = $injector.get('$sce');
        this.$filter = $injector.get('$filter');
        this.$compile = $injector.get('$compile');
        this.$timeout = $injector.get('$timeout');
        this.restService = $injector.get('RestService');
        this.productService = $injector.get('ProductService');
        this.helperService = $injector.get('HelperService');
        this.formService = this.$offerScope.formService;
        this.modalService = this.$offerScope.modalService;
        this.sessionService = this.$offerScope.sessionService;
        this.modalChargeId = 0;
        this.modalPreviewInvoiceId = 0;
        this.propertiesToIgnore = ["DISPLAY_INDEX", "selected", "uniqueIdControl"];
        this.initScopeFunctions();
    }

    async $onInit(): Promise<void> {
        try {
            this.offerChargeHelper = new OfferChargeHelperController(this.$injector, this.$offerScope);

            this.$scope.chargesTableOptions = this.getChargesTableOptions();
            this.initModel();
            await this.initDependencies();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initModel(): void {
        this.$scope.model = {
            CHARGES: null
        };
        this.$scope.oldModel = angular.copy(this.$scope.model);
    }

    private initScopeFunctions(): void {
        this.$scope.collapseOutDateCharges = () => {
            this.collapseOutDateCharges();
        }

        this.$scope.openChargesPerWeightRangeModal = () => {
            this.openChargesPerWeightRangeModal();
        }

        this.$scope.openPreviewInvoices = () => {
            this.openPreviewInvoices();
        }

        this.$scope.viewLog = (allLogs: boolean, uuid?: string) => {
            this.viewLog(allLogs, uuid);
        }

        this.$scope.showInvoiceOriginalValues = (invoice: IOfferInvoice, type: string): string => {
            return this.showInvoiceOriginalValues(invoice, type);
        }

        this.$scope.viewLogConfirmation = () => {
            this.viewLogConfirmation();
        }
    }

    async initDependencies(): Promise<any> {
        const self: OfferChargeOutDateRegisterController = this;

        this.initCollapseEvents();

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericValue("charge_origin"),
                self.getGenericValue("event"),
            ]).then(async (result: any) => {
                self.$scope.chargeOriginList = result[0];
                self.$scope.eventList = result[2];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private initCollapseEvents() {
        this.$scope.$on('offerChargeOutDateCollapse', (params, customParams: IOfferChargeCollapseParams) => {
            this.collapseOutDateCharges(customParams);
        });

        const collapseCharge = angular.element("#collapseOutDateCharges");
        if (collapseCharge) {
            collapseCharge.on('shown.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#previewInvoicesOutDate").show();
                    angular.element("#collapseChargeOutDateHeading").attr('aria-expanded', 'true');
                    // update collapse state
                    this.$offerScope.collapseState = { panel: ECollapseState.CHARGES_OUT_DATE, released: false, nextState: null };
                    this.$offerScope.repositionPanels('chargesOutDateRow', true);
                    await this.getOfferTabsCharge();
                    this.$timeout(() => {
                        const $table = angular.element("#chargesOutDateTable");
                        $table.bootstrapTable('multiSort', [{ sortName: "CHARGE_NAME.TYPE.ORDER", sortOrder: "asc" }, { sortName: "PAYMENT_CHARGE.TOTAL", sortOrder: "desc" }]);
                    }, 500);
                }
            });
            collapseCharge.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#previewInvoicesOutDate").hide();
                    angular.element("#collapseChargeOutDateHeading").attr('aria-expanded', 'false');
                }
            });
        }
    }

    private async getGenericValue(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async collapseOutDateCharges(customParams?: IOfferChargeCollapseParams) {
        try {
            if (this.$offerScope.collapseState.released || this.$offerScope.collapseState.panel == ECollapseState.CHARGES_OUT_DATE) {
                const collapseCharge = angular.element("#collapseOutDateCharges");
                if (collapseCharge) {
                    const isCollapsed = angular.element("#collapseChargeOutDateHeading").attr("aria-expanded") == "true";
                    const product = this.$offerScope.model.PRODUCT;
                    if (isCollapsed) {
                        if (product && (product.ID == EProductId.AIR_EXPORT || product.ID == EProductId.AIR_IMPORT)) angular.element("#chargesOutDateWeightRangeCharge").hide();
                        angular.element("#filesTariffOutDate").hide();
                        angular.element("#detailTariffOutDate").hide();
                        angular.element("#previewInvoicesOutDate").hide();
                        this.$offerScope.collapseState.released = true;
                    } else {
                        if (product && (product.ID == EProductId.AIR_EXPORT || product.ID == EProductId.AIR_IMPORT)) angular.element("#chargesOutDateWeightRangeCharge").show();
                        angular.element("#filesTariffOutDate").show();
                        angular.element("#detailTariffOutDate").show();
                        angular.element("#previewInvoicesOutDate").show();
                        if (customParams && customParams._id) {
                            await this.getOfferTabsCharge();
                            const chargeIndex = this.$scope.model.CHARGES && this.$scope.model.CHARGES.length ? this.$scope.model.CHARGES.findIndex(charge => charge._id == customParams._id) : -1;
                            if (chargeIndex >= 0) this.$timeout(() => { this.editCharge(chargeIndex, chargeIndex); }, 1000);
                        }
                    }
                    if (this.$scope.chargesTableOptions.crudButtons && this.$scope.chargesTableOptions.crudButtons.add) this.$scope.chargesTableOptions.crudButtons.add.disabled = this.$offerScope.operation == EOperation.VIEW;
                    if (this.$scope.chargesTableOptions.crudButtons && this.$scope.chargesTableOptions.crudButtons.edit) {
                        this.$scope.chargesTableOptions.crudButtons.edit.label = this.formService.getTranslate(this.$offerScope.operation == EOperation.VIEW ? "GENERAL.GRID.VIEW" : "GENERAL.GRID.EDIT");
                        this.$scope.chargesTableOptions.crudButtons.edit.icon = this.$offerScope.operation == EOperation.VIEW ? "fa fa-search" : null;
                    }
                    collapseCharge['collapse']('toggle');
                    if (isCollapsed) this.$offerScope.repositionPanels('chargesOutDateRow');
                }
            } else {
                this.$offerScope.collapseState.nextState = ECollapseState.CHARGES_OUT_DATE;
                this.$offerScope.releaseCollapse();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async openChargesPerWeightRangeModal(): Promise<void> {
        try {
            const modalID = this.modalService.newModal();
            const modalInstance: IModalInstanceService = await this.modalService.showChargesPerWeightRangeModal({ modalID: modalID, offerId: this.$offerScope.model.ID });
            const apply: boolean = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (apply) await this.getOfferTabsCharge();
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async openPreviewInvoices(): Promise<void> {
        try {
            this.modalPreviewInvoiceId = this.modalService.newModal();

            const modalInstance = await this.modalService.showModalInfo(
                {
                    modalID: this.modalPreviewInvoiceId,
                    scope: this.$scope,
                    formService: EOperation.VIEW,
                    template: require("../view/modals/offerPreviewInvoicesModal.html"),
                    size: 'vlg modal-overflow'
                },
                null
            );
            await this.buildPreviewInvoiceModalScope();

            modalInstance.rendered.then(async () => {
                const modalScope: IPreviewInvoiceModalScope = await this.modalService.getModalScope(this.modalPreviewInvoiceId);
                const tooltipModal = angular.element(".tooltipModalTop");
                if (tooltipModal) this.$compile(tooltipModal)(modalScope);
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async buildPreviewInvoiceModalScope(): Promise<IPreviewInvoiceModalScope> {
        const modalScope: IPreviewInvoiceModalScope = await this.modalService.getModalScope(this.modalPreviewInvoiceId);
        modalScope.invoices = await this.getOfferTabsPreviewInvoice();

        modalScope.editViewCharge = (_id: string) => {
            // 1 - close preview invoice modal
            this.modalService.closeModal(this.modalPreviewInvoiceId);
            this.modalPreviewInvoiceId = 0;
            // 2 - release charges panel
            this.collapseOutDateCharges();
            // 3 - open charge panel
            this.$offerScope.$broadcast("offerChargeOutDateCollapse", { _id });
        }

        modalScope.goToContract = (id: number) => {
            this.sessionService.openTab("app.finop.contract.financialContract", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        modalScope.showInvoiceFactors = (invoice: IOfferInvoice, types: string[]): string => {
            return this.showInvoiceFactors(invoice, types);
        }

        return modalScope;
    }

    private async getOfferTabsPreviewInvoice(): Promise<IOfferInvoice[]> {
        let invoices: IOfferInvoice[] = [];
        this.formService.block();
        try {
            const invoiceTab = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/management/invoice/${this.$offerScope.operation}/${this.$offerScope.model.ID}`, 30000, null, false);
            if (invoiceTab && invoiceTab.data) invoices = invoiceTab.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return invoices;
        }
    }

    private showInvoiceOriginalValues(invoice: IOfferInvoice, type: string): string {
        try {
            if (!invoice) throw Error('invoice is null');
            if (!type) throw Error('type is null');
            if (type != 'PAYMENT' && type != "RECEIVING") throw Error('type is invalid');
            let lista = [];
            let result = '';
            if (invoice[`OFFER_CHARGE_${type}`]) {
                for (const charge of invoice[`OFFER_CHARGE_${type}`]) {
                    if (charge[`${type}_CHARGE`] && charge[`${type}_CHARGE`].CURRENCY) {
                        const has = lista.find(item => item.CURRENCY == charge[`${type}_CHARGE`].CURRENCY.CODE);
                        if (has) {
                            has.VALUE += charge[`${type}_CHARGE`].TOTAL;
                        } else {
                            lista.push({ CURRENCY: charge[`${type}_CHARGE`].CURRENCY.CODE, VALUE: charge[`${type}_CHARGE`].TOTAL });
                        }
                    }
                }
            }

            for (const item of lista) {
                result += `${item.CURRENCY} ${this.$filter("number")(item.VALUE, 2)};`
            }
            result = result.slice(0, -1);
            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private showInvoiceFactors(invoice: IOfferInvoice, types: string[]): string {
        try {
            if (!invoice) throw Error('invoice is null');
            if (!types) throw Error('type is null');

            let lista = [];
            let result = '';
            for (const type of types) {
                if (invoice[`OFFER_CHARGE_${type}`]) {
                    for (const charge of invoice[`OFFER_CHARGE_${type}`]) {
                        if (charge[`${type}_CHARGE`] && charge[`${type}_CHARGE`].CURRENCY) {
                            if (charge[`${type}_CHARGE`].CURRENCY.CODE != 'BRL') {
                                const has = lista.find(item => charge[`${type}_CHARGE`].CURRENCY && item.CURRENCY == charge[`${type}_CHARGE`].CURRENCY.CODE);
                                if (!has) {
                                    lista.push({ CURRENCY: charge[`${type}_CHARGE`].CURRENCY.CODE, VALUE: (charge[`${type}_CHARGE`].TOTAL_FACTOR ? charge[`${type}_CHARGE`].TOTAL_FACTOR : 1), TABLE: (charge[`${type}_CHARGE`].TABLE ? charge[`${type}_CHARGE`].TABLE.NAME : ''), FACTOR: charge[`${type}_CHARGE`].FACTOR, SPREAD: charge[`${type}_CHARGE`].SPREAD });
                                }
                            }

                        }
                    }
                }
            }
            for (const item of lista) {
                result += `${item.CURRENCY} ${this.$filter("number")(item.VALUE, 4)} <i class="fa fa-info-circle text-cyano tooltipModalTop" tooltip-append-to-body="true" aria-hidden="true" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.TABLE_WITH_FACTOR_SPREAD' | translate: {table: '${item.TABLE}', factor: '${this.$filter("number")(item.FACTOR, 4)}', spread: '${this.$filter("number")(item.SPREAD, 2)}'} }}"></i>;`
            }
            result = result.slice(0, -1);
            return this.$sce.trustAsHtml(result);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async viewLog(allLogs: boolean, uuid?: string) {
        try {
            if (!allLogs && !uuid) {
                const msgError = this.formService.getTranslate('GENERAL.NO_CHARGE_SELECTED_CHECK_LOGS');
                this.formService.notifyError(msgError);
                return;
            }
            this.formService.block();
            let log: IViewLog = {
                operation: 'history',
                number: this.$offerScope.model.ID.toString(),
                list: [],
                show: true,
                showCloseButton: false,
                searchQuery: '',
                originalList: [],
            }

            this.requestHistory(log.number, uuid).then(result => {
                log.list = result.data;
                log.originalList = angular.copy(log.list);
                this.$scope.log = log;
                this.$scope.customLogProperties = this.getCustomLogProperties();
                angular.element('#log-viewer').removeClass('ng-hide');
                this.formService.unblock();
            }).catch(ex => {
                this.formService.handleError(ex);
            });

            const modalId = this.modalService.newModal();
            await this.modalService.showModalConfirmation(
                {
                    modalID: modalId,
                    scope: this.$scope,
                    template: require('../view/modals/offerLog.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG"
                }
            );
            this.modalService.closeModal(modalId);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private requestHistory(id: string, uuid?: string): Promise<any> {
        return this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/chargeOutDate/viewLog/${id}${uuid ? '/' + uuid : ''}`, 10000, null, false);
    }

    private async getOfferTabsCharge(): Promise<void> {
        this.formService.block();
        try {
            const chargeTab = await this.productService.get({ route: `/offer/tabs/chargeOutDate/${this.$offerScope.operation}/${this.$offerScope.model.ID}` });

            if (chargeTab && chargeTab.data && chargeTab.data.data) {
                for (const charge of chargeTab.data.data) {
                    if (charge.CHARGE_NAME && charge.CHARGE_NAME.TYPE) {
                        const chargeOrigin = this.$scope.chargeOriginList && this.$scope.chargeOriginList.length ? this.$scope.chargeOriginList.find(chargeOrigin => chargeOrigin.ID == charge.CHARGE_NAME.TYPE.ID) : null;
                        if (chargeOrigin) charge.CHARGE_NAME.TYPE.ORDER = chargeOrigin.ORDER;
                    }
                }
                this.$scope.model.CHARGES = chargeTab.data.data;
            } else {
                this.$scope.model.CHARGES = [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.oldModel = angular.copy(this.$scope.model);
            this.$scope.chargesTableOptions.load(null, true);
            this.formService.unblock();
        }
    }

    private getChargesTableOptions(): ITableOptions {
        return {
            persistName: "offerOutDateCharges",
            pagination: false,
            search: true,
            advancedSearch: true,
            showSearchClearButton: true,
            clickToSelect: true,
            singleSelect: true,
            showLoading: true,
            showColumns: true,
            showColumnsSearch: true,
            showColumnsToggleAll: true,
            showFullscreen: true,
            showToggle: false,
            showMultiSort: true,
            showMultiSortButton: true,
            height: '100%',
            filterControl: false,
            fixedColumns: true,
            fixedNumber: 3,
            sortable: true,
            serverSort: false,
            showDateFilter: true,
            crudButtons: {
                edit: { fn: async (index: number, displayIndex: number) => { this.editCharge(index, displayIndex); }, name: "editCharge" },
            },
            customToolbarButtons: [
                { fn: async () => { this.viewLog(true) }, label: "", name: "log", class: "btn btn-xs btn-success", icon: "fa fa-history", title: "", disabled: this.$offerScope.operation == EOperation.VIEW },
                { fn: async () => { this.offerChargeHelper.viewErrorLog() }, label: "", name: "log", class: "btn btn-xs btn-danger", icon: "fa fa-exclamation-triangle", title: this.formService.getTranslate("PRODUCT.AUTO_RATING_ERRORS_LOG"), disabled: this.$offerScope.operation == EOperation.VIEW }
            ],
            formatAddLevel: () => this.formService.getTranslate("GENERAL.ADD_LEVEL"),
            formatCancel: () => this.formService.getTranslate("GENERAL.CLOSE"),
            formatColumn: () => this.formService.getTranslate("GENERAL.COLUMNS"),
            formatDeleteLevel: () => this.formService.getTranslate("GENERAL.REMOVE_LEVEL"),
            formatMultipleSort: () => this.formService.getTranslate("GENERAL.MULTIPLE_SORT"),
            formatOrder: () => this.formService.getTranslate("GENERAL.ORDER"),
            formatSort: () => this.formService.getTranslate("GENERAL.SORT"),
            formatSortBy: () => this.formService.getTranslate("GENERAL.SORT_BY"),
            formatSortOrders: () => { return { asc: this.formService.getTranslate("GENERAL.ASCENDING_SORT"), desc: this.formService.getTranslate("GENERAL.DESCENDING_SORT") } },
            formatThenBy: () => this.formService.getTranslate("GENERAL.AND_BY"),
            headerStyle: (column: BootstrapTableColumn) => {
                let headerStyleObj = {};
                if (column.field.search("PAYMENT_CHARGE") >= 0 || column.field.search("CHARGE_NAME.DISPLAY_PAYMENT") >= 0) headerStyleObj = { css: { 'background': "#f2dede" } };
                else if (column.field.search("RECEIVING_CHARGE") >= 0 || column.field.search("CHARGE_NAME.DISPLAY_RECEIVING") >= 0) headerStyleObj = { css: { 'background': "#dff0d8" } };
                return headerStyleObj;
            },
            rowStyle(row: IOfferChargeOutDate, index: number): any {
                let style = {};
                if (row.PAYMENT_CHARGE && row.PAYMENT_CHARGE.CHARGE && row.PAYMENT_CHARGE.CHARGE.APPLICATION && row.PAYMENT_CHARGE.CHARGE.APPLICATION.ID == EApplicationId.SCOB &&
                    row.RECEIVING_CHARGE && row.RECEIVING_CHARGE.CHARGE && row.RECEIVING_CHARGE.CHARGE.APPLICATION && row.RECEIVING_CHARGE.CHARGE.APPLICATION.ID == EApplicationId.SCOB) {
                    style = { css: { 'color': "#D3D3D3" } };
                }
                return style;
            },
            onCheck: (row, element): boolean => {
                angular.element(".logindividual").removeClass("disabled");
                angular.element(".logindividual").removeClass("btn-not-clickable");
                return true;
            },
            onUncheck: (row): boolean => {
                angular.element(".logindividual").addClass("disabled");
                angular.element(".logindividual").addClass("btn-not-clickable");
                return true;
            },
            showColumnsRename: [
                { dataField: "DATA_ORIGIN_TYPE", title: this.formService.getTranslate("GENERAL.TYPE") },
                { dataField: "CHARGE_NAME.TYPE.ORDER", title: this.formService.getTranslate("BASIC_DATA.CHARGE") },
                { dataField: "PAYMENT_CHARGE.CHARGE.DATA_ORIGIN_TYPE", title: this.formService.getTranslate("GENERAL.TYPE_C") },
                { dataField: "PAYMENT_CHARGE.CHARGE.CHARGE_NAME_EXHIBITION", title: this.formService.getTranslate("BASIC_DATA.DISPLAY_CHARGE") },
                { dataField: "PAYMENT_CHARGE.CHARGE", title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS_C") },
                { dataField: "PAYMENT_CHARGE.CURRENCY", title: this.formService.getTranslate("GENERAL.CURRENCY_C") },
                { dataField: "PAYMENT_CHARGE.AMOUNT", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.QUANTITY_C") },
                { dataField: "PAYMENT_CHARGE.VALUE_UNITARY", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY_C") },
                { dataField: "PAYMENT_CHARGE.VALUE_MIN", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM_C") },
                { dataField: "PAYMENT_CHARGE.TOTAL", title: this.formService.getTranslate("GENERAL.TOTAL_C") },
                { dataField: "PAYMENT_CHARGE.MODALITY", title: this.formService.getTranslate("FINANCIAL.CHARGE_PAYMENT_METHOD_C") },
                { dataField: "PAYMENT_CHARGE.TRANSACTION", title: this.formService.getTranslate("GENERAL.HOLDER_TYPE_C") },
                { dataField: "PAYMENT_CHARGE.HOLDER", title: this.formService.getTranslate("GENERAL.HOLDER_C") },
                { dataField: "PAYMENT_CHARGE.TABLE", title: this.formService.getTranslate("FINANCIAL.EXCHANGE_RATE_INDEX_B") },
                { dataField: "PAYMENT_CHARGE.SPREAD", title: this.formService.getTranslate("FINANCIAL.SPREAD_B") },
                { dataField: "PAYMENT_CHARGE.FACTOR", title: this.formService.getTranslate("BASIC_DATA.EXCHANGE_RATE_C") },
                { dataField: "PAYMENT_CHARGE.TOTAL_CURRENT_CURRENCY", title: this.formService.getTranslate("FINANCIAL.TOTAL_BRL_C") },
                { dataField: "PAYMENT_CHARGE.INVOICE", title: this.formService.getTranslate("FINANCIAL.GENERATE_INVOICE_B") },
                { dataField: "PAYMENT_CHARGE.INVOICE_VALIDITY_INITIAL", title: this.formService.getTranslate("PRODUCT.VALIDITY_START_C") },
                { dataField: "PAYMENT_CHARGE.INVOICE_VALIDITY_FINAL", title: this.formService.getTranslate("PRODUCT.VALIDITY_END_C") },
                { dataField: "PAYMENT_CHARGE.SPECIFICITY", title: this.formService.getTranslate("GENERAL.SPECIFICITIES_B") },
                { dataField: "CHARGE_NAME.DISPLAY_PAYMENT", title: this.formService.getTranslate("FINANCIAL.SHOWN_FOR_C") },
                { dataField: "PAYMENT_CHARGE.CHARGE_COMPOSITION", title: this.formService.getTranslate("GENERAL.COMPOSITION_C") },
                { dataField: "PAYMENT_CHARGE.TARIFF", title: this.formService.getTranslate("GENERAL.TARIFF_B") },
                { dataField: "PAYMENT_CHARGE.CONTRACT", title: this.formService.getTranslate("GENERAL.CONTRACT_B") },
                { dataField: "PAYMENT_CHARGE.OBSERVATION", title: this.formService.getTranslate("GENERAL.REMARKS_C") },
                { dataField: "RECEIVING_CHARGE.CHARGE.DATA_ORIGIN_TYPE", title: this.formService.getTranslate("GENERAL.TYPE_V") },
                { dataField: "RECEIVING_CHARGE.CHARGE.CHARGE_NAME_EXHIBITION", title: this.formService.getTranslate("BASIC_DATA.DISPLAY_CHARGE") },
                { dataField: "RECEIVING_CHARGE.CHARGE", title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS_V") },
                { dataField: "RECEIVING_CHARGE.CURRENCY", title: this.formService.getTranslate("GENERAL.CURRENCY_V") },
                { dataField: "RECEIVING_CHARGE.AMOUNT", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.QUANTITY_V") },
                { dataField: "RECEIVING_CHARGE.VALUE_ABBREVIATIONS.UNITARY", title: this.formService.getTranslate("GENERAL.UNITARY_V") },
                { dataField: "RECEIVING_CHARGE.VALUE_UNITARY", title: this.formService.getTranslate("BASIC_DATA.UNIT") },
                { dataField: "RECEIVING_CHARGE.VALUE_MIN", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM_V") },
                { dataField: "RECEIVING_CHARGE.TOTAL", title: this.formService.getTranslate("GENERAL.TOTAL_V") },
                { dataField: "RECEIVING_CHARGE.MODALITY", title: this.formService.getTranslate("FINANCIAL.CHARGE_PAYMENT_METHOD_V") },
                { dataField: "RECEIVING_CHARGE.TRANSACTION", title: this.formService.getTranslate("GENERAL.HOLDER_TYPE_V") },
                { dataField: "RECEIVING_CHARGE.HOLDER", title: this.formService.getTranslate("GENERAL.HOLDER_V") },
                { dataField: "RECEIVING_CHARGE.TABLE", title: this.formService.getTranslate("FINANCIAL.EXCHANGE_RATE_INDEX_S") },
                { dataField: "RECEIVING_CHARGE.SPREAD", title: this.formService.getTranslate("FINANCIAL.SPREAD_S") },
                { dataField: "RECEIVING_CHARGE.FACTOR", title: this.formService.getTranslate("BASIC_DATA.EXCHANGE_RATE_V") },
                { dataField: "RECEIVING_CHARGE.TOTAL_CURRENT_CURRENCY", title: this.formService.getTranslate("FINANCIAL.TOTAL_BRL_C") },
                { dataField: "RECEIVING_CHARGE.INVOICE", title: this.formService.getTranslate("FINANCIAL.GENERATE_INVOICE_S") },
                { dataField: "RECEIVING_CHARGE.INVOICE_VALIDITY_INITIAL", title: this.formService.getTranslate("PRODUCT.VALIDITY_START_V") },
                { dataField: "RECEIVING_CHARGE.INVOICE_VALIDITY_FINAL", title: this.formService.getTranslate("PRODUCT.VALIDITY_END_V") },
                { dataField: "RECEIVING_CHARGE.SPECIFICITY", title: this.formService.getTranslate("GENERAL.SPECIFICITIES_S") },
                { dataField: "CHARGE_NAME.DISPLAY_RECEIVING", title: this.formService.getTranslate("FINANCIAL.SHOWN_FOR_V") },
                { dataField: "RECEIVING_CHARGE.CHARGE_COMPOSITION", title: this.formService.getTranslate("GENERAL.COMPOSITION_V") },
                { dataField: "RECEIVING_CHARGE.TARIFF", title: this.formService.getTranslate("GENERAL.TARIFF_S") },
                { dataField: "RECEIVING_CHARGE.CONTRACT", title: this.formService.getTranslate("GENERAL.CONTRACT_S") },
                { dataField: "RECEIVING_CHARGE.OBSERVATION", title: this.formService.getTranslate("GENERAL.REMARKS_V") },
                { dataField: "CHARGE_VALUE", title: this.formService.getTranslate("FINANCIAL.CHARGE_DIFFERENCE") },
                { dataField: "CHARGE_VALUE_CURRENT_CURRENCY", title: this.formService.getTranslate("PRODUCT.WIZARD_DATA_PREOFFER_OPTIONS") },
            ],
            onPostBody: (chargeList: IOfferChargeOutDate[]) => {
                for (const index in chargeList) { chargeList[index].DISPLAY_INDEX = parseInt(index); };
                this.$scope.chargesTableList = chargeList;
                angular.element(".logindividual").css('background-color', 'orange');
                angular.element(".logindividual").css('border-color', 'orange');
                angular.element(".loggeral").css('background-color', 'orange');
                angular.element(".loggeral").css('border-color', 'orange');
                return true;
            },
            columns: [
                { field: 'selected', title: this.formService.getTranslate("GENERAL.UI_SELECT.SELECT"), checkbox: true, showSelectTitle: true, cellStyle: () => { return { css: { width: '80px' } } } },
                { field: 'DATA_ORIGIN_TYPE', title: this.formService.getTranslate("GENERAL.TYPE"), formatter: (data) => data ? data.ID : data, sortable: false, searchable: true, visible: false },
                { field: 'CHARGE_NAME.TYPE.ORDER', title: this.formService.getTranslate("BASIC_DATA.CHARGE"), formatter: (value, row) => this.buildEllipsizeEl(row.CHARGE_NAME ? row.CHARGE_NAME.NAME : null), sortable: true, searchable: true, class: 'charge-name-col' },
                { field: 'PAYMENT_CHARGE.DATA_ORIGIN_TYPE', title: this.formService.getTranslate(" GENERAL.TYPE_C"), formatter: (data) => data ? data.ID : data, sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.CHARGE.CHARGE_NAME_EXHIBITION', title: this.formService.getTranslate("BASIC_DATA.DISPLAY_CHARGE"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'PAYMENT_CHARGE.CHARGE', title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS"), formatter: (data: IOfferPaymentReceivingCharge) => this.chargeTableApplicationFormatter(data), sortable: true, searchable: true },
                { field: 'PAYMENT_CHARGE.CURRENCY', title: this.formService.getTranslate("GENERAL.CURRENCY"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'PAYMENT_CHARGE.AMOUNT', title: this.formService.getTranslate("GENERAL.QUANTITY"), sortable: true, searchable: true },
                { field: 'PAYMENT_CHARGE.VALUE_UNITARY', title: this.formService.getTranslate("BASIC_DATA.UNIT"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'PAYMENT_CHARGE.VALUE_MIN', title: this.formService.getTranslate("BASIC_DATA.MINIMUM"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'PAYMENT_CHARGE.TOTAL', title: this.formService.getTranslate("GENERAL.TOTAL"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'CHARGE_NAME.DISPLAY_PAYMENT', title: this.formService.getTranslate("FINANCIAL.SHOWN_FOR"), formatter: (data) => data ? this.$offerScope.getCONCAT(data, null, "NAME") : data, sortable: true, searchable: true },
                { field: 'PAYMENT_CHARGE.MODALITY', title: this.formService.getTranslate("FINANCIAL.CHARGE_PAYMENT_METHOD"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.TRANSACTION', title: this.formService.getTranslate("GENERAL.HOLDER_TYPE"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.HOLDER', title: this.formService.getTranslate("GENERAL.HOLDER"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.TABLE', title: this.formService.getTranslate("GENERAL.EXCHANGE_RATE_INDEX"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.SPREAD', title: this.formService.getTranslate("FINANCIAL.SPREAD"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.FACTOR', title: this.formService.getTranslate("BASIC_DATA.EXCHANGE_RATE"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.TOTAL_CURRENT_CURRENCY', title: this.formService.getTranslate("FINANCIAL.TOTAL_BRL"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.INVOICE', title: this.formService.getTranslate("FINANCIAL.GENERATE_INVOICE"), formatter: (data) => this.$filter("YesOrNo")(data), sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.VALIDITY_START', title: this.formService.getTranslate("PRODUCT.VALIDITY_START"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true, visible: true },
                { field: 'PAYMENT_CHARGE.VALIDITY_END', title: this.formService.getTranslate("PRODUCT.VALIDITY_END"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true, visible: true },
                { field: 'PAYMENT_CHARGE.SPECIFICITY', title: this.formService.getTranslate("GENERAL.SPECIFICITIES"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.CHARGE_COMPOSITION', title: this.formService.getTranslate("GENERAL.COMPOSITION"), formatter: (data) => data ? this.$offerScope.getCONCAT(data) : data, sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.CONTRACT', title: this.formService.getTranslate("BASIC_DATA.FREIGHT_CONTRACT"), formatter: (data) => data ? data.CONTRACT_NUMBER : data, sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.OBSERVATION', title: this.formService.getTranslate("GENERAL.REMARKS"), sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.DATA_ORIGIN_TYPE', title: this.formService.getTranslate(" GENERAL.TYPE_V"), formatter: (data) => data ? data.ID : data, sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.CHARGE.CHARGE_NAME_EXHIBITION', title: this.formService.getTranslate("BASIC_DATA.DISPLAY_CHARGE"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'RECEIVING_CHARGE.CHARGE', title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS"), formatter: (data: IOfferPaymentReceivingCharge) => this.chargeTableApplicationFormatter(data), sortable: true, searchable: true },
                { field: 'RECEIVING_CHARGE.CURRENCY', title: this.formService.getTranslate("GENERAL.CURRENCY"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'RECEIVING_CHARGE.AMOUNT', title: this.formService.getTranslate("GENERAL.QUANTITY"), sortable: true, searchable: true },
                { field: 'RECEIVING_CHARGE.VALUE_UNITARY', title: this.formService.getTranslate("BASIC_DATA.UNIT"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'RECEIVING_CHARGE.VALUE_MIN', title: this.formService.getTranslate("BASIC_DATA.MINIMUM"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'RECEIVING_CHARGE.TOTAL', title: this.formService.getTranslate("GENERAL.TOTAL"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'CHARGE_NAME.DISPLAY_RECEIVING', title: this.formService.getTranslate("FINANCIAL.SHOWN_FOR"), formatter: (data) => data ? this.$offerScope.getCONCAT(data, null, "NAME") : data, sortable: true, searchable: true, },
                { field: 'RECEIVING_CHARGE.MODALITY', title: this.formService.getTranslate("FINANCIAL.CHARGE_PAYMENT_METHOD"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.TRANSACTION', title: this.formService.getTranslate("GENERAL.HOLDER_TYPE"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.HOLDER', title: this.formService.getTranslate("GENERAL.HOLDER"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.TABLE', title: this.formService.getTranslate("GENERAL.EXCHANGE_RATE_INDEX"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.SPREAD', title: this.formService.getTranslate("FINANCIAL.SPREAD"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.FACTOR', title: this.formService.getTranslate("BASIC_DATA.EXCHANGE_RATE"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.TOTAL_CURRENT_CURRENCY', title: this.formService.getTranslate("FINANCIAL.TOTAL_BRL"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.INVOICE', title: this.formService.getTranslate("FINANCIAL.GENERATE_INVOICE"), formatter: (data) => this.$filter("YesOrNo")(data), sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.VALIDITY_START', title: this.formService.getTranslate("PRODUCT.VALIDITY_START"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true, visible: true },
                { field: 'RECEIVING_CHARGE.VALIDITY_END', title: this.formService.getTranslate("PRODUCT.VALIDITY_END"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true, visible: true },
                { field: 'RECEIVING_CHARGE.SPECIFICITY', title: this.formService.getTranslate("GENERAL.SPECIFICITIES"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.CHARGE_COMPOSITION', title: this.formService.getTranslate("GENERAL.COMPOSITION"), formatter: (data) => data ? this.$offerScope.getCONCAT(data) : data, sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.CONTRACT', title: this.formService.getTranslate("BASIC_DATA.FREIGHT_CONTRACT"), formatter: (data) => data ? data.CONTRACT_NUMBER : data, sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.OBSERVATION', title: this.formService.getTranslate("GENERAL.REMARKS"), sortable: true, searchable: true, visible: false },
                { field: 'CHARGE_VALUE', title: this.formService.getTranslate("FINANCIAL.CHARGE_DIFFERENCE"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: true },
                { field: 'CHARGE_VALUE_CURRENT_CURRENCY', title: this.formService.getTranslate("PRODUCT.WIZARD_DATA_PREOFFER_OPTIONS"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: true }
            ],
        };
    }

    private buildEllipsizeEl(value: string) {
        return `
            <span
                style="max-width: 100%;"
                class="ellipsize td-tooltip"
                ellipsis-tooltip
                tooltip-placement="auto top"
                uib-tooltip="${value}"
                tooltip-enable="true"
                tooltip-append-to-body="true">
                ${value}
            </span>
        `;
    }

    private chargeTableApplicationFormatter(charge: IOfferPaymentReceivingCharge): string {
        let display = "";
        if (charge && charge.APPLICATION) {
            display += charge.APPLICATION.CODE;
            if (charge.APPLICATION.APPLICATION_COMPLEMENT) {
                if (charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE && charge.WEIGHT_RANGE) display += ` (${charge.WEIGHT_RANGE.NAME})`;
                if (charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.EQUIPMENT && charge.EQUIPMENT) display += ` (${charge.EQUIPMENT.CODE})`;
                if (charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.VEHICLE && charge.VEHICLE_TYPE) display += ` (${charge.VEHICLE_TYPE.NAME})`;
            }
        }
        return display;
    }

    private async editCharge(index: number, displayIndex: number): Promise<void> {
        try {
            this.$offerScope.lastScroll = angular.element('.app-content-body').scrollTop();
            if (!index && index != 0) throw Error('index is null');
            const charge: IOfferChargeOutDate = this.$scope.model.CHARGES && this.$scope.model.CHARGES.length ? this.$scope.model.CHARGES[index] : null;
            if (!charge) throw Error('charge is null');

            this.modalChargeId = this.modalService.newModal();
            const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                {
                    modalID: this.modalChargeId,
                    scope: this.$scope,
                    formService: this.$offerScope.operation,
                    template: require("../view/modals/offerChargeModal.html"),
                    size: 'full',
                    keyboard: true,
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing") {
                            if (reason.toString() == "escape key press") event.preventDefault();
                            if (!closed) {
                                const modalScope: IChargeOutDateModalScope = await this.modalService.getModalScope(this.modalChargeId);
                                await modalScope.closeChargeModal();
                            }
                        }
                    }
                },
                null
            );
            modalInstance.rendered.then(async () => {
                const chargeUpdated = await this.getChargeOutDate(charge._id);
                if (chargeUpdated) {
                    const modalScope = await this.buildChargeModalScope(index, displayIndex, chargeUpdated);
                    modalScope.$applyAsync();

                    angular.element('.collapseDetails').on('shown.bs.collapse', (event: JQuery.Event) => {
                        if (event.target == event.currentTarget) {
                            angular.element("#collapsePaymentPanelHeading").attr('aria-expanded', 'true');
                            angular.element("#collapseReceivingPanelHeading").attr('aria-expanded', 'true');
                        }
                    });

                    angular.element('.collapseDetails').on('hidden.bs.collapse', (event: JQuery.Event) => {
                        if (event.target == event.currentTarget) {
                            angular.element("#collapsePaymentPanelHeading").attr('aria-expanded', 'false');
                            angular.element("#collapseReceivingPanelHeading").attr('aria-expanded', 'false');
                        }
                    });
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getChargeOutDate(chargeId: string): Promise<IOfferChargeByIdResponse> {
        if (!chargeId) throw Error("chargeId is null.");
        this.formService.block();
        let chargeOutDateById = null;
        try {
            chargeOutDateById = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/chargeOutDate/get/${chargeId}`, 30000, null, false);

            if (!chargeOutDateById || (chargeOutDateById && !chargeOutDateById.data)) {
                const msgError = this.formService.getTranslate('FINANCIAL.CHARGE_NO_LONGER_EXISTS');
                this.formService.notifyError(msgError);
                this.modalService.closeModal(this.modalChargeId);
                this.modalChargeId = 0;
                angular.element('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
                this.getOfferTabsCharge();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            const charge = chargeOutDateById ? chargeOutDateById.data : chargeOutDateById;
            charge.RECEIVING_CHARGE.VALIDITY_START_DISPLAY = charge.RECEIVING_CHARGE.VALIDITY_START ? charge.RECEIVING_CHARGE.VALIDITY_START : charge.RECEIVING_CHARGE.VALIDITY_START_ORIGINAL;
            charge.RECEIVING_CHARGE.VALIDITY_END_DISPLAY = charge.RECEIVING_CHARGE.VALIDITY_END ? charge.RECEIVING_CHARGE.VALIDITY_END : charge.RECEIVING_CHARGE.VALIDITY_END_OVALIDITY_START_ORIGINAL;
            charge.PAYMENT_CHARGE.VALIDITY_START_DISPLAY = charge.PAYMENT_CHARGE.VALIDITY_START ? charge.PAYMENT_CHARGE.VALIDITY_START : charge.PAYMENT_CHARGE.VALIDITY_START_ORIGINAL;
            charge.PAYMENT_CHARGE.VALIDITY_END_DISPLAY = charge.PAYMENT_CHARGE.VALIDITY_END ? charge.PAYMENT_CHARGE.VALIDITY_END : charge.PAYMENT_CHARGE.VALIDITY_END_OVALIDITY_START_ORIGINAL;

            return chargeOutDateById ? chargeOutDateById.data : chargeOutDateById;
        }
    }

    private async buildChargeModalScope(index: number, displayIndex: number, charge: IOfferChargeByIdResponse): Promise<IChargeOutDateModalScope> {
        try {
            const modalScope: IChargeOutDateModalScope = await this.modalService.getModalScope(this.modalChargeId);
            const operationBefore = angular.copy(modalScope.operation);
            const vehicleTypeGeneric = await this.getGenericValue("vehicle_type");
            modalScope.isManualCharge = charge.DATA_ORIGIN_TYPE ? charge.DATA_ORIGIN_TYPE.ID == EDataOriginTypeId.MANUAL ? true : false : true;
            if (modalScope.isManualCharge || modalScope.charge && modalScope.charge.RECEIVING_CHARGE && modalScope.charge.RECEIVING_CHARGE.NEGOTIATED) modalScope.isReceivingChargeNegotiated = true;

            if (charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.WEIGHT_RANGE_CHARGE) charge.PAYMENT_CHARGE.WEIGHT_RANGE_CHARGE = charge.PAYMENT_CHARGE.WEIGHT_RANGE_CHARGE.sort((x, y) => x.WEIGHT_RANGE.ID < y.WEIGHT_RANGE.ID ? -1 : 1);
            if (charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.WEIGHT_RANGE_CHARGE) charge.RECEIVING_CHARGE.WEIGHT_RANGE_CHARGE = charge.RECEIVING_CHARGE.WEIGHT_RANGE_CHARGE.sort((x, y) => x.WEIGHT_RANGE.ID < y.WEIGHT_RANGE.ID ? -1 : 1);

            this.updateChargeValidityDisplay(charge);

            modalScope.isOutDateCharge = true;
            modalScope.currentIndex = index;
            modalScope.currentDisplayIndex = displayIndex;
            modalScope.charge = angular.copy(charge);
            modalScope.oldCharge = angular.copy(charge);
            modalScope.isPaymentWeightRangeApplication = charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.CHARGE && charge.PAYMENT_CHARGE.CHARGE.APPLICATION && charge.PAYMENT_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT && charge.PAYMENT_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
            modalScope.isReceivingWeightRangeApplication = charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.CHARGE && charge.RECEIVING_CHARGE.CHARGE.APPLICATION && charge.RECEIVING_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT && charge.RECEIVING_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;

            if (charge.HAS_CURRENT_REFERENCE || modalScope.charge && modalScope.charge.CHARGE_NAME && modalScope.charge.CHARGE_NAME.TYPE && (modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.COMISSION)) modalScope.operation = EOperation.VIEW;

            modalScope.goToTariffLocal = (id: number) => {
                const endpoint = `${this.$offerScope.productUrl}/tariffLocal/getCacheById/${id}`;
                this.sessionService.openTabByValidity(endpoint, "app.product.tariffLocal", <ILinkParameter>{ ID: id ? id.toString() : id }, <ITariffLocalExchangeData>{ OPERATION: EOperation.VIEW, ID: id ? id.toString() : id });
            }

            modalScope.goToTariffDomestic = (id: number) => {
                const endpoint = `${this.$offerScope.productUrl}/tariffDomestic/getCacheById/${id}`;
                this.sessionService.openTabByValidity(endpoint, "app.product.tariffDomestic", <ILinkParameter>{ ID: id ? id.toString() : id }, <ITariffDomesticExchangeData>{ OPERATION: EOperation.VIEW, ID: id ? id.toString() : id });
            }

            modalScope.goToTariffFreight = (id: number) => {
                const endpoint = `${this.$offerScope.productUrl}/tariffFreight/getCacheById/${id}`;
                this.sessionService.openTabByValidity(endpoint, "app.product.tariffFreight", <ILinkParameter>{ ID: id ? id.toString() : id }, <ITariffFreightExchangeData>{ OPERATION: EOperation.VIEW, ID: id ? id.toString() : id });
            }

            modalScope.isNotApplicableComplement = (applicationComplement: SelectorModel): boolean => {
                return !applicationComplement || (applicationComplement && applicationComplement.ID == EApplicationComplementId.NOT_APPLICABLE);
            }

            modalScope.isWeightRangeComplement = (applicationComplement: SelectorModel): boolean => {
                return applicationComplement && applicationComplement.ID == EApplicationComplementId.WEIGHT_RANGE;
            }

            modalScope.isEquipmentComplement = (applicationComplement: SelectorModel): boolean => {
                return applicationComplement && applicationComplement.ID == EApplicationComplementId.EQUIPMENT;
            }

            modalScope.isVehicleComplement = (applicationComplement: SelectorModel): boolean => {
                return applicationComplement && applicationComplement.ID == EApplicationComplementId.VEHICLE;
            }

            modalScope.isAirProduct = (): boolean => {
                return this.$offerScope.model.PRODUCT && (this.$offerScope.model.PRODUCT.ID == EProductId.AIR_EXPORT || this.$offerScope.model.PRODUCT.ID == EProductId.AIR_IMPORT);
            }

            modalScope.isTransactionOther = (transaction: SelectorModel): boolean => {
                return transaction && transaction.ID == ETransactionId.OTHER;
            }

            modalScope.isApplicationScob = (internalSequence: string): boolean => {
                return internalSequence && internalSequence == '001';
            }

            modalScope.hasPreviousCharge = (currentDisplayIndex: number) => {
                return currentDisplayIndex > 0;
            }

            modalScope.hasNextCharge = (currentDisplayIndex: number) => {
                return (currentDisplayIndex || currentDisplayIndex == 0) && this.$scope.chargesTableList && (this.$scope.chargesTableList.length - 1 > currentDisplayIndex);
            }

            modalScope.previousCharge = (currentDisplayIndex: number) => {
                this.previousCharge(currentDisplayIndex, operationBefore);
            }

            modalScope.nextCharge = (currentDisplayIndex: number) => {
                this.nextCharge(currentDisplayIndex, operationBefore);
            }

            modalScope.closeChargeModal = () => {
                this.closeChargeModal();
            }

            modalScope.prepareTextComposition = (item: IPercentualComposition, complete: boolean): string => {
                return this.prepareTextComposition(item, complete);
            }

            modalScope.isNegotiated = (receivingCharge: IOfferPaymentReceiving): boolean => {
                return this.isNegotiated(receivingCharge);
            }

            return modalScope;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private isNegotiated(receivingCharge: IOfferPaymentReceiving): boolean {
        return (receivingCharge && receivingCharge.NEGOTIATED)
    }

    private updateChargeValidityDisplay(charge: IOfferChargeOutDate) {
        charge.RECEIVING_CHARGE.VALIDITY_START_DISPLAY =
            charge.RECEIVING_CHARGE.VALIDITY_START || charge.RECEIVING_CHARGE.VALIDITY_START_ORIGINAL;
        charge.RECEIVING_CHARGE.VALIDITY_END_DISPLAY =
            charge.RECEIVING_CHARGE.VALIDITY_END || charge.RECEIVING_CHARGE.VALIDITY_END_ORIGINAL;
        charge.PAYMENT_CHARGE.VALIDITY_START_DISPLAY =
            charge.PAYMENT_CHARGE.VALIDITY_START || charge.PAYMENT_CHARGE.VALIDITY_START_ORIGINAL;
        charge.PAYMENT_CHARGE.VALIDITY_END_DISPLAY =
            charge.PAYMENT_CHARGE.VALIDITY_END || charge.PAYMENT_CHARGE.VALIDITY_END_ORIGINAL;
    }

    /* Offer Charge Modal - Functions - Início */
    private async previousCharge(currentDisplayIndex: number, operationBefore: string) {
        const modalScope: IChargeOutDateModalScope = await this.modalService.getModalScope(this.modalChargeId);
        const previousChargeIndex = this.$scope.model.CHARGES && this.$scope.model.CHARGES.length ? this.$scope.model.CHARGES.findIndex(charge => charge.DISPLAY_INDEX == (currentDisplayIndex - 1)) : -1;
        if (previousChargeIndex >= 0) {
            if (this.$offerScope.hasChanges(JSON.stringify(modalScope.charge), JSON.stringify(modalScope.oldCharge)) && !(modalScope.charge && modalScope.charge.CHARGE_NAME && modalScope.charge.CHARGE_NAME.TYPE && (modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.COMISSION || modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.INSURANCE))) {
                const confirm = await this.$offerScope.modalSaveConfirmation("REGISTRATION.BACK", "GENERAL.CANCEL");
                if (!confirm) return;
                else this.getOfferTabsCharge();
            }
            modalScope.currentIndex = previousChargeIndex;
            modalScope.currentDisplayIndex--;

            const chargeUpdated = await this.getChargeOutDate(this.$scope.model.CHARGES[previousChargeIndex]._id);
            if (chargeUpdated) {
                modalScope.charge = angular.copy(chargeUpdated);
                modalScope.oldCharge = angular.copy(chargeUpdated);
                if (modalScope.charge && modalScope.charge.CHARGE_NAME && modalScope.charge.CHARGE_NAME.TYPE && (modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.COMISSION || modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.INSURANCE)) modalScope.operation = EOperation.VIEW;
                else modalScope.operation = operationBefore;
                modalScope.disableElements(chargeUpdated.PAYMENT_CHARGE.CHARGE.APPLICATION.INTERNAL_SEQUENCE == "001" || modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.INSURANCE, null, ["chargePaymentApplicationWeightRange", "chargePaymentApplicationEquipment", "chargePaymentVehicleType", "chargePaymentAmount", "chargePaymentCurrency", "chargePaymentValueUnitary", "chargePaymentValueMin", "chargePaymentModality", "chargePaymentTransaction", "chargePaymentHolder", "chargePaymentInvoice", "chargeNameExhibitionPayment", "chargePaymentExhibition", "chargePaymentComposition", "chargePaymentSpecificity", "chargePaymentContract", "chargePaymentTable", "chargePaymentObservation"]);
                modalScope.disableElements(chargeUpdated.RECEIVING_CHARGE.CHARGE.APPLICATION.INTERNAL_SEQUENCE == "001" || modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.INSURANCE, null, ["chargeReceivingApplicationWeightRange", "chargeReceivingApplicationEquipment", "chargeReceivingVehicleType", "chargeReceivingAmount", "chargeReceivingCurrency", "chargeReceivingValueUnitary", "chargeReceivingValueMin", "chargeReceivingModality", "chargeReceivingTransaction", "chargeReceivingHolder", "chargeReceivingInvoice", "chargeNameExhibitionReceiving", "chargeReceivingExhibition", "chargeReceivingComposition", "chargeReceivingSpecificity", "chargeReceivingContract", "chargeReceivingTable", "chargeReceivingObservation"]);
                modalScope.isPaymentWeightRangeApplication = chargeUpdated.PAYMENT_CHARGE && chargeUpdated.PAYMENT_CHARGE.CHARGE && chargeUpdated.PAYMENT_CHARGE.CHARGE.APPLICATION && chargeUpdated.PAYMENT_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT && chargeUpdated.PAYMENT_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
                modalScope.isReceivingWeightRangeApplication = chargeUpdated.RECEIVING_CHARGE && chargeUpdated.RECEIVING_CHARGE.CHARGE && chargeUpdated.RECEIVING_CHARGE.CHARGE.APPLICATION && chargeUpdated.RECEIVING_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT && chargeUpdated.RECEIVING_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
            }
        }
    }

    private async nextCharge(currentDisplayIndex: number, operationBefore: string) {
        const modalScope: IChargeOutDateModalScope = await this.modalService.getModalScope(this.modalChargeId);
        const nextChargeIndex = this.$scope.model.CHARGES && this.$scope.model.CHARGES.length ? this.$scope.model.CHARGES.findIndex(charge => charge.DISPLAY_INDEX == (currentDisplayIndex + 1)) : -1;
        if (nextChargeIndex >= 0) {
            const propertiesToIgnore = this.propertiesToIgnore;
            const chargeToCompare = angular.copy(modalScope.charge);
            for (const property of propertiesToIgnore) {
                delete chargeToCompare[property];
            }
            const oldChargeToCompare = angular.copy(modalScope.oldCharge);
            for (const property of propertiesToIgnore) {
                delete oldChargeToCompare[property];
            }
            if (this.$offerScope.hasChanges(JSON.stringify(modalScope.charge), JSON.stringify(modalScope.oldCharge)) && !(modalScope.charge && modalScope.charge.CHARGE_NAME && modalScope.charge.CHARGE_NAME.TYPE && (modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.COMISSION || modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.INSURANCE))) {
                const confirm = await this.$offerScope.modalSaveConfirmation("GENERAL.NEXT", "GENERAL.CANCEL");
                if (!confirm) return;
                else this.getOfferTabsCharge();
            }
            modalScope.currentIndex = nextChargeIndex;
            modalScope.currentDisplayIndex++;

            const chargeUpdated = await this.getChargeOutDate(this.$scope.model.CHARGES[nextChargeIndex]._id);
            if (chargeUpdated) {
                modalScope.charge = angular.copy(chargeUpdated);
                modalScope.oldCharge = angular.copy(chargeUpdated);
                if (modalScope.charge && modalScope.charge.CHARGE_NAME && modalScope.charge.CHARGE_NAME.TYPE && (modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.COMISSION || modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.INSURANCE)) modalScope.operation = EOperation.VIEW;
                else modalScope.operation = operationBefore;
                modalScope.disableElements(chargeUpdated.PAYMENT_CHARGE.CHARGE.APPLICATION.INTERNAL_SEQUENCE == "001" || modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.INSURANCE, null, ["chargePaymentApplicationWeightRange", "chargePaymentApplicationEquipment", "chargePaymentVehicleType", "chargePaymentAmount", "chargePaymentCurrency", "chargePaymentValueUnitary", "chargePaymentValueMin", "chargePaymentModality", "chargePaymentTransaction", "chargePaymentHolder", "chargePaymentInvoice", "chargeNameExhibitionPayment", "chargePaymentExhibition", "chargePaymentComposition", "chargePaymentSpecificity", "chargePaymentContract", "chargePaymentTable", "chargePaymentObservation"]);
                modalScope.disableElements(chargeUpdated.RECEIVING_CHARGE.CHARGE.APPLICATION.INTERNAL_SEQUENCE == "001" || modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.INSURANCE, null, ["chargeReceivingApplicationWeightRange", "chargeReceivingApplicationEquipment", "chargeReceivingVehicleType", "chargeReceivingAmount", "chargeReceivingCurrency", "chargeReceivingValueUnitary", "chargeReceivingValueMin", "chargeReceivingModality", "chargeReceivingTransaction", "chargeReceivingHolder", "chargeReceivingInvoice", "chargeNameExhibitionReceiving", "chargeReceivingExhibition", "chargeReceivingComposition", "chargeReceivingSpecificity", "chargeReceivingContract", "chargeReceivingTable", "chargeReceivingObservation"]);
                modalScope.isPaymentWeightRangeApplication = chargeUpdated.PAYMENT_CHARGE && chargeUpdated.PAYMENT_CHARGE.CHARGE && chargeUpdated.PAYMENT_CHARGE.CHARGE.APPLICATION && chargeUpdated.PAYMENT_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT && chargeUpdated.PAYMENT_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
                modalScope.isReceivingWeightRangeApplication = chargeUpdated.RECEIVING_CHARGE && chargeUpdated.RECEIVING_CHARGE.CHARGE && chargeUpdated.RECEIVING_CHARGE.CHARGE.APPLICATION && chargeUpdated.RECEIVING_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT && chargeUpdated.RECEIVING_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
            }
        }
    }

    private async closeChargeModal() {
        const modalScope: IChargeOutDateModalScope = await this.modalService.getModalScope(this.modalChargeId);
        if (this.$offerScope.hasChanges(JSON.stringify(modalScope.charge), JSON.stringify(modalScope.oldCharge)) && !(modalScope.charge.CHARGE_NAME && modalScope.charge.CHARGE_NAME.TYPE && (modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.COMISSION || modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.INSURANCE))) {
            const confirm = await this.$offerScope.modalSaveConfirmation("GENERAL.CLOSE", "GENERAL.CLOSE");
            if (!confirm) return;
            else this.getOfferTabsCharge();
        }
        this.modalService.closeModal(this.modalChargeId);
        this.modalChargeId = 0;
        angular.element('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
    }

    private prepareTextComposition(item: IPercentualComposition, complete: boolean): string {
        try {
            let result = "";
            if (item) {
                result = item.CHARGE_NAME_EXHIBITION ? item.CHARGE_NAME_EXHIBITION.CODE : '';

                if (result !== "") result += item.APPLICATION ? " - " + item.APPLICATION.CODE : '';
                else result += item.APPLICATION ? item.APPLICATION.CODE : '';

                if (item.EQUIPMENT || item.WEIGHT_RANGE || item.VEHICLE_TYPE) {
                    result += '(' + (item.EQUIPMENT ? item.EQUIPMENT.CODE : (item.WEIGHT_RANGE ? item.WEIGHT_RANGE.NAME : item.VEHICLE_TYPE.NAME)) + ')';
                }

                if (complete) result += ' - ' + this.$filter('number')(item.TOTAL, 2) + ' (' + item.CURRENCY.CODE + ')';

                return result;
            }
            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    /* Offer Charge Modal - Functions - Fim */

    private async viewLogConfirmation(): Promise<void> {
        try {
            this.formService.block();
            const retrieveLog = await this.productService.get({ route: `/offerChangeConfirmation/tabs/charge/getOfferChangeConfirmation/offer/${this.$offerScope.model.ID}` });
            this.formService.unblock();
            if (retrieveLog && retrieveLog.data && retrieveLog.data.data && retrieveLog.data.data.length) {
                this.$scope.offerChangeConfirmation = retrieveLog.data.data;
                const modalId = this.modalService.newModal();
                await this.modalService.showModalConfirmation(
                    {
                        modalID: modalId,
                        scope: this.$scope,
                        template: require("../view/modals/offerChangeConfirmationLogModal.html"),
                        size: 'vlg modal-overflow'
                    },
                    {
                        closeButtonText: 'GENERAL.CLOSE'
                    }
                );
            } else this.formService.notifyInfo(this.formService.getTranslate("REGISTRATION.NO_RECORD_FOUND"));
        } catch (ex) {
            this.formService.handleError(ex);
            this.formService.unblock();
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: "PAYMENT", LABEL: "FINANCIAL.DEBIT" },
            { PROPERTY: "RECEIVING", LABEL: "FINANCAL.CREDIT" },
            { PROPERTY: "NAME", LABEL: "GENERAL.NAME" },
            { PROPERTY: "CODE", LABEL: "GENERAL.CODE" },
            { PROPERTY: "CHARGE_VALUE", LABEL: "FINANCIAL.CHARGE_DIFFERENCE" },
            { PROPERTY: "CHARGE_NAME", LABEL: "BASIC_DATA.CHARGE" },
            { PROPERTY: "PAYMENT_CHARGE", LABEL: "FINANCIAL.PAYMENT_CHARGE" },
            { PROPERTY: "RECEIVING_CHARGE", LABEL: "FINANCIAL.RECEIPT_CHARGE" },
            { PROPERTY: "OFFER_INVOICE", LABEL: "FINANCIAL.INVOICE_NUMBER" },
            { PROPERTY: "AMOUNT", LABEL: "GENERAL.QUANTITY" },
            { PROPERTY: "VALUE_UNITARY", LABEL: "FINANCIAL.UNITARY_VALUE" },
            { PROPERTY: "VALUE_MIN", LABEL: "GENERAL.MINIMUM_VALUE" },
            { PROPERTY: "TOTAL", LABEL: "GENERAL.TOTAL" },
            { PROPERTY: "CURRENCY", LABEL: "GENERAL.CURRENCY" },
            { PROPERTY: "CHARGE", LABEL: " " },
            { PROPERTY: "CONTRACT_NUMBER", LABEL: "GENERAL.CONTRACT_NUMBER" },
            { PROPERTY: "CONCATENATED", LABEL: "GENERAL.CONCATENATED" },
            { PROPERTY: "TARIFF_FREIGHT_CONCATENATED", LABEL: "PRODUCT.FREIGHT_TARIFF_CONCATENATED" },
            { PROPERTY: "TARIFF_LOCAL_CONCATENATED", LABEL: "PRODUCT.LOCAL_TARIFF_CONCATENATED" },
            { PROPERTY: "TARIFF_DOMESTIC_CONCATENATED", LABEL: "PRODUCT.DOMESTIC_TARIFF_CONCATENATED" },
            { PROPERTY: "APPLICATION", LABEL: "FINANCIAL.CHARGE_BASIS" },
            { PROPERTY: "WEIGHT_RANGE", LABEL: "GENERAL.WEIGHT_RANGE" },
            { PROPERTY: "EQUIPMENT", LABEL: "BASIC_DATA.EQUIPMENT" },
            { PROPERTY: "VEHICLE_TYPE", LABEL: "GENERAL.VEHICLE_TYPE" },
            { PROPERTY: "PAYMENT_MIN", LABEL: "FINANCIAL.MINIMUM_PAYMENT " },
            { PROPERTY: "PAYMENT_UNITARY", LABEL: "FINANCIAL.UNITARY_PAYMENT" },
            { PROPERTY: "RECEIVING_MIN", LABEL: "FINANCIAL.MINIMUM_RECEIPT " },
            { PROPERTY: "RECEIVING_UNITARY", LABEL: "FINANCIAL.UNITARY_RECEIPT" },
            { PROPERTY: "GROUP", LABEL: "GENERAL.GROUP" },
            { PROPERTY: "SPECIFICITY", LABEL: "GENERAL.SPECIFICITIES" },
            { PROPERTY: "RATE_TYPE", LABEL: "GENERAL.ERP_NEGOTIATE_TYPE" },
            { PROPERTY: "HOLDER_TYPE", LABEL: "GENERAL.HOLDER_TYPE" },
            { PROPERTY: "LEGAL_PERSON_HOLDER", LABEL: "ENTITY.LEGAL_PERSON" },
            { PROPERTY: "PHYSICAL_PERSON_HOLDER", LABEL: "GENERAL.PHYSICAL_PERSON" },
            { PROPERTY: "SHORT_NAME", LABEL: "GENERAL.HOLDER" },
            { PROPERTY: "COMPOSITION", LABEL: "GENERAL.COMPOSITION" },
            { PROPERTY: "CHARGE_NAME_EXHIBITION", LABEL: "BASIC_DATA.CHARGE_DISPLAY" },
            { PROPERTY: "ERROR", LABEL: "OPERATIONAL.HAS_ERROR" },
            { PROPERTY: "REASON", LABEL: "OPERATIONAL.FAULT_REASON" },
            { PROPERTY: "PERCENTUAL_COMPOSITION", LABEL: "FINANCIAL.PERCENTAGE_COMPOSITION" },
            { PROPERTY: "PERCENTUAL_COMPOSITION_CHARGE_NAME_EXHIBITION", LABEL: "GENERAL.COMPOSITION" },
            { PROPERTY: "MODALITY", LABEL: "FINANCIAL.CHARGE_PAYMENT_METHOD" },
            { PROPERTY: "TRANSACTION", LABEL: "BASIC_DATA.TRANSACTION" },
            { PROPERTY: "TRANSACTION_CONTRACT", LABEL: "FINANCIAL.TRANSACTION_CONTRACT" },
            { PROPERTY: "HOLDER", LABEL: "GENERAL.HOLDER" },
            { PROPERTY: "ORIGIN", LABEL: "BASIC_DATA.ORIGIN" },
            { PROPERTY: "HOLDER_CONTRACT", LABEL: "FINANCIAL.HOLDER_CONTRACT" },
            { PROPERTY: "TABLE", LABEL: "Tabela" },
            { PROPERTY: "SPREAD", LABEL: "FINANCIAL.SPREAD" },
            { PROPERTY: "FACTOR", LABEL: "BASIC_DATA.EXCHANGE_RATE" },
            { PROPERTY: "TOTAL_FACTOR", LABEL: "FINANCIAL.TOTAL_FACTOR" },
            { PROPERTY: "TOTAL_CURRENT_CURRENCY", LABEL: "FINANCIAL.TOTAL_LOCAL_CURRENCY" },
            { PROPERTY: "CURRENT_CURRENCY", LABEL: "FINANCIAL.LOCAL_CURRENCY" },
            { PROPERTY: "CONTRACT", LABEL: "BASIC_DATA.FREIGHT_CONTRACT" },
            { PROPERTY: "CHARGE_COMPOSITION", LABEL: "GENERAL.COMPOSITION_CHARGE" },
            { PROPERTY: "INVOICE", LABEL: "FINANCIAL.GENERATE_INVOICE" },
            { PROPERTY: "OBSERVATION", LABEL: "GENERAL.REMARKS" },
            { PROPERTY: "VALIDITY_START", LABEL: "PRODUCT.VALIDITY_START" },
            { PROPERTY: "VALIDITY_END", LABEL: "PRODUCT.VALIDITY_END" },
            { PROPERTY: "CHARGE_VALUE_CURRENT_CURRENCY", LABEL: "FINANCIAL.DIFFERENCE_CHARGE_LOCAL_CURRENCY" },
            { PROPERTY: "EXCHANGE_RATE_INDEX", LABEL: "FINANCIAL.IDC" },
            { PROPERTY: "EXCHANGE_BALANCE", LABEL: "FINANCIAL.DIFFERENCE_EXCHANGE_RATE" },
            { PROPERTY: "BALANCE_AMOUNT", LABEL: "FINANCIAL.BALANCE" },
            { PROPERTY: "PROFIT_SHARE", LABEL: "FINANCIAL.PROFIT_SHARE" },
            { PROPERTY: "DATA_ORIGIN_TYPE", LABEL: "PRODUCT.ORIGIN_DATA" },
        ];
        return props;

    }
}

import * as angular from "angular";
import { IGridRow, IColumnDef } from "ui-grid";
import { GridFormService, IGridFormController, IGridFormServiceScope } from "@services/GridFormService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IModalService } from "@services/ModalService";
import { ISessionService } from '@services/SessionService';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IntegrationTask } from "@models/interface/integration/IntegrationTask";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { IProcessParameter, ITaskParamParameter, IInvoiceParameter } from '../../common/model/ModelParameter';
import { EOperation } from "@enums/GenericData";

export interface IMonacoRequest<T = any> {
    route?: string;
    operation?: string;
    data?: T;
    timeout?: number;
    user?: any;
}

interface IDataModal extends ng.IScope {
    model: IntegrationTask;
    dataIntegration: string;
    payload?: string;
}

interface IIntegrationTaskScope extends IGridFormServiceScope {
    model: IntegrationTask;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    selectedRows: IntegrationTask[];
    clearSelections: () => Promise<void>;
    showActionsSelected: (row: IntegrationTask, action: string) => void;
    start: (model: IntegrationTask) => void;
    pause: (model: IntegrationTask) => void;
    cancelIntegration: (model: IntegrationTask) => void;
    viewData: (model: IntegrationTask) => void;
    goToProcess: (process: string) => void;
    goToInvoice: (process: string) => void;
    goToTaskParam: (integrationTaskParam: string) => void;
    form: ng.IFormController;
}

export class IntegrationTaskRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ["$injector", "$scope", "$element"];
    private $scope: IIntegrationTaskScope;
    private selectedRows: IntegrationTask[];
    private ModalService: IModalService;
    private SessionService: ISessionService;
    modalID: any;

    constructor($injector: ng.Injectable<any>, $scope: IIntegrationTaskScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.ModalService = $injector.get('ModalService');
        this.SessionService = $injector.get('SessionService');
    }

    initScopeFunctions(): void {
        this.$scope.showActionsSelected = (row: IntegrationTask, action: string) => {
            try {
                let show: boolean = true;
                let hide: boolean = false;
                let integrationTasks: IntegrationTask[] = [row];

                if ((this.$scope.selectedRows && this.$scope.selectedRows.length > 0)) integrationTasks = this.$scope.selectedRows;

                if (action == 'start') {
                    for (let integrationTask of integrationTasks) {
                        if (integrationTask.STATUS && integrationTask.STATUS.ID == '2' || integrationTask.STATUS.ID == '4') {
                            show = true;
                        } else hide = true;
                    }
                } else if (action == 'pause') {
                    for (let integrationTask of integrationTasks) {
                        if (integrationTask.STATUS && integrationTask.STATUS.ID == '0' || integrationTask.STATUS.ID == '3' || integrationTask.STATUS.ID == '5') {
                            show = true;
                        } else hide = true;
                    }
                } else if (action == 'cancel') {
                    for (let integrationTask of integrationTasks) {
                        if (integrationTask.STATUS && integrationTask.STATUS.ID == '0' || integrationTask.STATUS.ID == '2' || integrationTask.STATUS.ID == '3' || integrationTask.STATUS.ID == '4' || integrationTask.STATUS.ID == '5') {
                            show = true;
                        } else hide = true;
                    }
                } else return false;

                if (hide) show = false;

                return show;
            } catch (ex) {
                this.handleError(ex);
            }
        }

        this.$scope.clearSelections = async () => {
            await this.clearSelections();
        }

        this.$scope.start = (model: IntegrationTask) => {
            this.start(model);
        }

        this.$scope.pause = (model: IntegrationTask) => {
            this.pause(model);
        }

        this.$scope.cancelIntegration = (model: IntegrationTask) => {
            this.cancelIntegration(model);
        }

        this.$scope.viewData = (model: IntegrationTask) => {
            this.viewData(model);
        }

        this.$scope.goToProcess = (process: string) => {
            this.SessionService.openTab('app.operational.newProcess.list', <IProcessParameter>{ PROCESS_NUMBER: process });
        }

        this.$scope.goToInvoice = (invoice: string) => {
            this.SessionService.openTab("app.finop.invoice.register", <IInvoiceParameter>{ INVOICE_NUMBER: invoice });
        }

        this.$scope.goToTaskParam = (param: string) => {
            this.SessionService.openTab('app.task.param', <ITaskParamParameter>{ TASK_NUMBER: param });
        }
    }

    async $onInit(): Promise<void> {
        try {
            this.selectedRows = [];
            this.$scope.selectedRows = [];
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.$baseUrl = this.config.externalUrl + '/external';
            this.initForm(this, "form", "integrationTask", "GENERAL.MENU.INTEGRATION", false);
            this.block();

            // enable multi row selection
            this.$gridService.setSelectable(true);

            // init grid
            await this.initGrid('gridIntegrationTask', '/integrationTask/list', true, true, 120000, true, true);
            this.$gridService.setBackgroundUpdate(120000, [this.selectionReapply, this]);

            this.monitoringFilterChanges();

            // register grid multiple rows selection callback
            this.$gridService.$gridApi.selection.on.rowSelectionChanged(this.$scope, this.selectedRowCallback.bind(this));
            this.$gridService.$gridApi.selection.on.rowSelectionChangedBatch(this.$scope, this.selectedRowBatchCallback.bind(this));

            this.unblock();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const columnDefs: Array<IColumnDef> = gridColumns.$columnDefs;

        //situations
        const status = `<span ng-show="true" tooltip-placement="auto top" uib-tooltip="{{row.entity.VALIDATION_RESULT.HAS_ERRORS ? 'REGISTRATION.ERROR' : row.entity.VALIDATION_RESULT.HAS_WARNINGS ? 'REGISTRATION.ALERT' : 'GENERAL.NO_CRITICAL' | translate }}" tooltip-append-to-body="true" ><i class="fa" ng-class="row.entity.VALIDATION_RESULT.HAS_ERRORS ? 'fa-exclamation-triangle text-danger' : row.entityVALIDATION_RESULT.HAS_WARNINGS ? 'fa-exclamation-triangle text-warning' : 'fa-check text-green'"></i>&nbsp;&nbsp;</span>`
        const start = `<span ng-show="grid.appScope.showActionsSelected(row.entity, \'start\');"><a ng-click="grid.appScope.start(row.entity);"   class="text-blue"  tooltip-placement="auto top" uib-tooltip="{{'GENERAL.RETRY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-play text-default"></i></a>&nbsp;&nbsp;</span>`;
        const pause = `<span ng-show="grid.appScope.showActionsSelected(row.entity, \'pause\');"><a ng-click="grid.appScope.pause(row.entity);"   class="text-black" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.PAUSE' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pause text-default"></i></a>&nbsp;&nbsp;</span>`;
        const cancel = `<span ng-show="grid.appScope.showActionsSelected(row.entity, \'cancel\');"><a ng-click="grid.appScope.cancelIntegration(row.entity);"  class="text-red"   tooltip-placement="auto top" uib-tooltip="{{'GENERAL.CANCEL' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-ban text-default"></i></a>&nbsp;&nbsp;</span>`;
        const viewData = `<span ng-show="true"><a ng-click="grid.appScope.viewData(row.entity)" class="text-black" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.INTEGRATION_DETAILS' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-code icon"></i></a>&nbsp;&nbsp;</span>`;
        const history = `<span ng-show="true"><a ng-click="grid.appScope.viewLog(row.entity)" class="text-green" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;</span>`;
        //actions       
        const actions = `<div class="text-center pull-left" style="padding-left: 10px;">${status}${pause}${start}${cancel}${viewData}${history}</div>`;

        columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: 120,
            cellTemplate: (actions),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });
        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            // visible
            const colReference: IMonacoColumnDef = { name: "REFERENCE", displayName: "REGISTRATION.REFERENCE", width: 150, cellTemplate: '<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="Clique para visualizar o processo {{row.entity.REFERENCE}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToProcess(row.entity.REFERENCE)">{{row.entity.REFERENCE}}</a></div>' };
            const colInvoice: IMonacoColumnDef = { name: "TASK.INVOICE_NUMBER", displayName: "GENERAL.INVOICES", width: 150, cellTemplate: '<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="Clique para visualizar a fatura {{row.entity.TASK.INVOICE_NUMBER}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToInvoice(row.entity.TASK.INVOICE_NUMBER)">{{row.entity.TASK.INVOICE_NUMBER}}</a></div>' };
            const colTask: IMonacoColumnDef = { name: "TASK.PARAMS.TASK_NUMBER", displayName: "GENERAL.TASKS", width: 300, cellTemplate: '<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="Clique para visualizar a Atividade {{row.entity.TASK.PARAMS.TASK_NUMBER}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToTaskParam(row.entity.TASK.PARAMS.TASK_NUMBER)">{{row.entity.TASK.PARAMS.TASK_NUMBER}} - {{row.entity.TASK.PARAMS.TASK.NAME}}</a></div>' };
            const colStatus: IMonacoColumnDef = { name: "STATUS.NAME", displayName: "OPERATIONAL.STATUS", width: 180 };
            const colCreatedDate: IMonacoColumnDef = { name: "CREATED_DATE", displayName: "GENERAL.CREATED_AT", width: 140, cellFilter: "datetime" };
            const colCreatedBy: IMonacoColumnDef = { name: "CREATED_BY.NAME", displayName: "GENERAL.CREATED_BY", width: 180 };
            const colUpdatedDate: IMonacoColumnDef = { name: "UPDATED_DATE", displayName: "GENERAL.UPDATED_AT", width: 140, cellFilter: "datetime" };
            const colUpdatedBy: IMonacoColumnDef = { name: "UPDATED_BY.NAME", displayName: "GENERAL.UPDATED_BY", width: 180 };
            const colOrigin: IMonacoColumnDef = { name: "INTEGRATION_ORIGIN.NAME", displayName: "REGISTRATION.DATA_ORIGIN", width: 250, cellTemplate: "<div class='grid-padding' >{{grid.appScope.getCONCAT(row.entity.INTEGRATION_ORIGIN)}}</div>" };
            const colError: IMonacoColumnDef = { name: "VALIDATION_RESULT.ERRORS", displayName: "REGISTRATION.ERROR", width: 400, cellTemplate: "<div class='grid-padding' >{{grid.appScope.getCONCAT(row.entity.VALIDATION_RESULT.ERRORS)}}</div>" };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'REFERENCE':
                        columnDefs.push(colReference);
                        break;
                    case 'TASK':
                        columnDefs.push(colInvoice);
                        columnDefs.push(colTask);
                        break;
                    case 'STATUS':
                        columnDefs.push(colStatus);
                        break;
                    case 'CREATED_DATE':
                        columnDefs.push(colCreatedDate);
                        break;
                    case 'CREATED_BY':
                        columnDefs.push(colCreatedBy);
                        break;
                    case 'UPDATED_DATE':
                        columnDefs.push(colUpdatedDate);
                        break;
                    case 'UPDATED_BY':
                        columnDefs.push(colUpdatedBy);
                        break;
                    case 'INTEGRATION_ORIGIN':
                        columnDefs.push(colOrigin);
                        break;
                    case 'VALIDATION_RESULT':
                        columnDefs.push(colError);
                        break;
                };
            }

            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    public async selectionReapply(): Promise<void> {
        const rows = this.$gridService.$gridApi.core.getVisibleRows(this.$gridService.$gridApi.grid);
        const selectedRows = this.$gridService.$gridSelectedRows;

        if (selectedRows && selectedRows.length > 0) {
            const updatedRows: IGridRow[] = [];
            for (const row of selectedRows) {
                const foundRow = rows.find(x => x.entity._id == row._id);
                if (foundRow) {
                    foundRow.setSelected(true);
                    updatedRows.push(foundRow);
                }
            }
            this.selectedRowBatchCallback(updatedRows);
        }
    }

    private async start(model: IntegrationTask): Promise<void> {
        try {
            let integrationTasksToUpdate: IntegrationTask[] = [model];
            if (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) integrationTasksToUpdate = this.$scope.selectedRows;
            const msg = this.formService.getTranslate('GENERAL.SELECT_THE_INTEGRATIONS_TO_APPLY_STATUS');
            if (!integrationTasksToUpdate || integrationTasksToUpdate.length == 0 || !integrationTasksToUpdate[0]) return this.handleError(msg);
            const textBody = (integrationTasksToUpdate.length > 1) ? `GENERAL.ARE_YOU_SURE_YOU_WANT_TO_START_THE_SELECTED_INTEGRATIONS` : `GENERAL.ARE_YOU_SURE_YOU_WANT_TO_START`
            const confirmed = await this.ModalService.showModalConfirmation({}, {
                bodyText: this.formService.getTranslate(textBody, { prop: integrationTasksToUpdate[0].TASK.TASK.NAME }),
                actionButtonText: 'OPERATIONAL.START',
                closeButtonText: 'GENERAL.CLOSE',
                headerText: (integrationTasksToUpdate.length > 1) ? this.formService.getTranslate('GENERAL.MULTIPLES') + " - " + this.formService.getTranslate('OPERATIONAL.START') : this.formService.getTranslate('GENERAL.INTEGRATION_START', { prop: integrationTasksToUpdate[0].TASK.TASK.NAME })
            });

            if (!confirmed) return;

            this.block();

            const timeout = 120000;

            for (let integrationTask of integrationTasksToUpdate) {
                const baseUrl = this.config.externalUrl + '/external';
                const result = await this.httpRequest.getObjectAsPromise(`${baseUrl}${`/integrationTask/start/${integrationTask._id}/${timeout}`}`, timeout, null, false);

                if (!result || !result.data) return this.handleError(result);
            }

            await this.clearSelections();
            await this.updateGrid();
            const msgError = this.formService.getTranslate('GENERAL.REGISTERS_SUCESSFULLY_UPDATED');
            this.$formService.notifySuccess(msgError);

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async pause(model: IntegrationTask): Promise<void> {
        try {
            let integrationTasksToUpdate: IntegrationTask[] = [model];
            if (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) integrationTasksToUpdate = this.$scope.selectedRows;
            const msg = this.formService.getTranslate('GENERAL.SELECT_THE_INTEGRATIONS_TO_APPLY_STATUS');
            if (!integrationTasksToUpdate || integrationTasksToUpdate.length == 0 || !integrationTasksToUpdate[0]) return this.handleError(msg);
            const textBody = (integrationTasksToUpdate.length > 1) ? `GENERAL.ARE_YOU_SURE_YOU_WANT_TO_PAUSE_THE_SELECTED_INTEGRATIONS` : `GENERAL.ARE_YOU_SURE_YOU_WANT_TO_PAUSE`;
            const buttonText = this.formService.getTranslate('GENERAL.PAUSE');
            const textHeader = (integrationTasksToUpdate.length > 1) ? `GENERAL.MULTIPLES` : `GENERAL.INTEGRATION_PAUSE`
            const confirmed = await this.ModalService.showModalConfirmation({}, {
                bodyText: this.formService.getTranslate(textBody, { prop: integrationTasksToUpdate[0].TASK.TASK.NAME }),
                actionButtonText: buttonText,
                closeButtonText: 'GENERAL.CLOSE',
                headerText: this.formService.getTranslate(textHeader, { prop: (integrationTasksToUpdate.length > 1) ? buttonText : integrationTasksToUpdate[0].TASK.TASK.NAME }),
            });

            if (!confirmed) return;

            this.block();

            const timeout = 120000;
            for (let integrationTask of integrationTasksToUpdate) {
                const baseUrl = this.config.externalUrl + '/external';
                const result = await this.httpRequest.getObjectAsPromise(`${baseUrl}${`/integrationTask/pause/${integrationTask._id}/${timeout}`}`, timeout, null, false);

                if (!result || !result.data) return this.handleError(result);
            }

            await this.clearSelections();
            await this.updateGrid();
            const msgSuccess = this.formService.getTranslate('GENERAL.REGISTERS_SUCESSFULLY_UPDATED');
            this.$formService.notifySuccess(msgSuccess);

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async cancelIntegration(model: IntegrationTask): Promise<void> {
        try {
            let integrationTasksToUpdate: IntegrationTask[] = [model];
            if (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) integrationTasksToUpdate = this.$scope.selectedRows;
            const msg = this.formService.getTranslate('GENERAL.SELECT_THE_INTEGRATIONS_TO_APPLY_STATUS');
            if (!integrationTasksToUpdate || integrationTasksToUpdate.length == 0 || !integrationTasksToUpdate[0]) return this.handleError(msg);

            const textBody = (integrationTasksToUpdate.length > 1) ? `GENERAL.ARE_YOU_SURE_YOU_WANT_TO_CANCEL_THE_SELECTED_INTEGRATIONS` : `GENERAL.ARE_YOU_SURE_YOU_WANT_TO_CANCEL`;
            const buttonText = this.formService.getTranslate('GENERAL.CANCEL');
            const textHeader = (integrationTasksToUpdate.length > 1) ? `GENERAL.MULTIPLES` : `GENERAL.INTEGRATION_CANCEL`;
            const confirmed = await this.ModalService.showModalConfirmation({}, {
                bodyText: this.formService.getTranslate(textBody, { prop: integrationTasksToUpdate[0].TASK.TASK.NAME }),
                actionButtonText: buttonText,
                closeButtonText: 'GENERAL.CLOSE',
                headerText: this.formService.getTranslate(textHeader, { prop: (integrationTasksToUpdate.length > 1) ? buttonText : integrationTasksToUpdate[0].TASK.TASK.NAME }),
            });

            if (!confirmed) return;

            this.block();

            const timeout = 120000;
            for (let integrationTask of integrationTasksToUpdate) {
                const baseUrl = this.config.externalUrl + '/external';
                const result = await this.httpRequest.getObjectAsPromise(`${baseUrl}${`/integrationTask/cancel/${integrationTask._id}/${timeout}`}`, timeout, null, false);

                if (!result || !result.data) return this.handleError(result);
            }

            await this.clearSelections();
            await this.updateGrid();
            const msgSuccess = this.formService.getTranslate('GENERAL.REGISTERS_SUCESSFULLY_UPDATED');
            this.$formService.notifySuccess(msgSuccess);

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async viewData(model: IntegrationTask): Promise<void> {
        try {
            this.block();

            const modalID = this.ModalService.newModal();
            this.ModalService.showModalInfo(
                {
                    modalID: modalID,
                    formService: EOperation.VIEW,
                    template: require("../view/integrationTaskData.html"),
                    size: 'lg'
                },
                {
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: `${this.formService.getTranslate('GENERAL.INTEGRATION_DETAILS')} ${model.REFERENCE} - ${model.INTEGRATION_ORIGIN.map(x => x.NAME).join(' , ')}`
                }
            );

            const modalScope: IDataModal = await this.ModalService.getModalScope(modalID);
            const modelData: string = JSON.stringify(model.DATA);
            const modelPayload: string = JSON.stringify(model.PAYLOAD);
            modalScope.model = model;
            modalScope.dataIntegration = (modelData) ? JSON.parse(modelData) : null;
            modalScope.payload = (modelPayload) ? JSON.parse(modelPayload) : null;
            await modalScope.$applyAsync();

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async clearSelections(): Promise<void> {
        this.$scope.selectedRows = [];
        this.$gridService.$gridApi.selection.clearSelectedRows();

        if (this.$scope.log) this.$scope.log.show = false;
    }

    private monitoringFilterChanges() {
        this.$gridService.$gridApi.core.on.filterChanged(this.$scope, this.clearSelections.bind(this));
    }

    private selectedRowCallback(row: IGridRow): void {
        try {
            this.block();

            const integrationTask: IntegrationTask = row.entity;

            const i = this.selectedRows.findIndex(x => x._id === integrationTask._id);

            if (row.isSelected && i === -1)
                this.selectedRows.push(integrationTask);
            else
                this.selectedRows.splice(i, 1);

            // update scope
            this.$scope.selectedRows = this.selectedRows.slice();

            if (this.selectedRows.length > 1 && this.$scope.log) this.$scope.log.show = false;

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private selectedRowBatchCallback(rows: IGridRow[]): void {
        try {
            rows = this.$gridService.$gridApi.selection.getSelectedGridRows();
            this.block();

            this.selectedRows = rows.filter(x => x.isSelected).map(x => x.entity);
            if (this.selectedRows.length > 1 && this.$scope.log) this.$scope.log.show = false;

            this.$scope.selectedRows = this.selectedRows.slice();

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            REFERENCE: null,
            TASK: null,
            CREATED_BY: null,
            UPDATED_BY: null,
            CREATED_DATE: null,
            UPDATED_DATE: null,
            STATUS: null,
            DATA: null,
            PAYLOAD: null,
            INTEGRATION_ORIGIN: null,
            RETURNED_DATA: null,
            ERROR_MESSAGE: null,
            VALIDATION_RESULT: null
        }
    }

    async viewLog(model: IntegrationTask): Promise<void> {
        try {
            this.block();
            const msgError = this.formService.getTranslate('GENERAL.THERE_IS_NO_LOG_FOR_THIS_MESSAGE');
            try {
                this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.GRID.LOG')} - ${model._id}`;

                let log: IViewLog = {
                    operation: 'history',
                    number: model._id.toString(),
                    list: [],
                    show: true,
                    searchQuery: '',
                    originalList: [],
                }

                const timeout: number = 120000;

                let request: IMonacoRequest = {
                    route: `/integrationTask/viewLog/${model._id.toString()}/${timeout}`,
                    timeout: timeout
                };

                const baseUrl = this.config.externalUrl + '/external';
                const result = await this.httpRequest.getObjectAsPromise(`${baseUrl}${request.route}`, request.timeout, null, false);
                if (!result || !result.data) return this.handleWarning(msgError);

                log.list = result.data;
                log.originalList = angular.copy(log.list);
                this.$scope.log = log;
                angular.element('#log-viewer').removeClass('ng-hide');
                const position = angular.element('#log-viewer').offset().top + $('.app-content-body').scrollTop() - 105;

                $('.app-content-body').animate({
                    scrollTop: position
                }, 500);

            } catch (ex) {
                this.handleWarning(msgError);
            }
        } finally {
            this.unblock();
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: 'STATUS',
                LABEL: 'OPERATIONAL.STATUS'
            }
        ]
        return props;
    }
}

import angular = require('angular');
import { IGridOptions } from "ui-grid";
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { GridFormService, IGridFormController, IGridFormServiceScope } from "@services/GridFormService";
import { IMonacoColumnDef } from "@services/GridService2";
import { OperationalService } from "@services/OperationalService";
import { ExternalService } from "@services/ExternalService";
import { IModalService } from "@services/ModalService";
import { IEquipmentList, ICargoDetail, IBooking } from "@models/interface/operational/Booking";
import { IBookingGridModel } from "@models/interface/operational/BookingModel";
import { IDocumentError } from '@models/interface/common/IDocumentError';
import { ICustomLogProperties } from '@models/interface/common/IViewLog';
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { INewIntegrationRedundanceModalScope } from "../../commercial/controller/NewIntegrationRedundanceModalController";

interface IBookingListScope extends IGridFormServiceScope {
    gridOptions: IGridOptions;
    form: ng.IFormController;
    customLogProperties: ICustomLogProperties[];
    concatEquipmentToGrid: (EQUIPMENT_LIST: IEquipmentList[]) => string;
    concatCommodityToGrid: (CARGO_DETAIL: ICargoDetail[]) => string;
    viewBooking: (bookingId: number) => void;
    amendBooking: (bookingId: number) => void;
    openModalIntegration: (id: number, integrationReduncance: IDocumentError[]) => void;
    openDifferencesModal: (model: IBookingGridModel) => void;
    updateBooking: (id: number, inttraReferenceNumber: number) => Promise<void>;
}

export class BookingListController extends GridFormService implements IGridFormController {
    static $inject: string[] = ["$injector", "$scope"];
    private $scope: IBookingListScope;
    private $timeout: ng.ITimeoutService;
    private operationalService: OperationalService;
    private externalService: ExternalService;
    private ModalService: IModalService;
    private modalID: number;

    constructor($injector: ng.Injectable<any>, $scope: IBookingListScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$timeout = $injector.get("$timeout");
        this.operationalService = $injector.get('OperationalService');
        this.ModalService = $injector.get('ModalService');
        this.externalService = $injector.get('ExternalService');
    }

    initScopeFunctions(): void {
        this.$scope.concatEquipmentToGrid = (EQUIPMENT_LIST: IEquipmentList[]) => {
            return this.concatEquipmentToGrid(EQUIPMENT_LIST);
        }

        this.$scope.concatCommodityToGrid = (CARGO_DETAIL: ICargoDetail[]) => {
            return this.concatCommodityToGrid(CARGO_DETAIL);
        }

        this.$scope.viewBooking = (bookingId: number) => {
            this.openWizard(true, false, bookingId);
        }

        this.$scope.amendBooking = (bookingId: number) => {
            this.openWizard(false, true, bookingId);
        }

        this.$scope.openModalIntegration = (entity: any) => {
            return this.openModalIntegration(entity);
        }

        this.$scope.openDifferencesModal = (model: IBookingGridModel) => {
            this.openDifferencesModal(model);
        }

        this.$scope.updateBooking = async (id: number, inttraReferenceNumber: number) => {
            this.updateBooking(id, inttraReferenceNumber);
        }
    }

    private async updateBookingGridRow(id: number) {
        try {
            if (id && angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const bookingData = await this.getBookingById(id);
                    if (row && bookingData) {
                        row.AWAITING_INTTRA_CONFIRMATION = bookingData.AWAITING_INTTRA_CONFIRMATION;
                        row.HAS_DIFFS = bookingData.HAS_DIFFS;
                        row.HAS_CRITICAL_DIFFS = bookingData.HAS_CRITICAL_DIFFS;
                        row.CARRIER_BOOKING_NUMBER = bookingData.CARRIER_BOOKING_NUMBER;
                        row.BOOKING_SITUATION = bookingData.BOOKING_SITUATION;
                        row.INTTRA_SITUATION = bookingData.INTTRA_SITUATION;
                    }
                }, 1000);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async updateBooking(id: number, inttraReferenceNumber: number) {
        try {
            if (id && inttraReferenceNumber) {
                this.block();
                const request = await this.operationalService.post("/booking/viewBookingInttra", { inttraReferenceNumber: inttraReferenceNumber }, 30000);
                if (request && request.data) {
                    const msg = this.formService.getTranslate('OPERATIONAL.UPDATE_BOOKING_SUCCESS');
                    this.formService.notifySuccess(msg);
                    await this.updateBookingGridRow(id);
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.operationalService.$route;
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.$rootScope.setBreadCrumb("GENERAL.MENU.BOOKING", () => { this.openWizard(); });
            this.initForm(this, "form", "booking", "GENERAL.MENU.BOOKING", false);
            await this.initGrid('gridBooking', '/booking/list', true, true, 120000, true, true);
            const self: BookingListController = this;
            this.$scope.$on('updateBookingGridRow', function (event, params) {
                if (event && event.name == "updateBookingGridRow" && params) self.updateBookingGridRow(params.bookingId);
            });
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.$rootScope.clearBreadCrumb();
        super.$onDestroy();
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewBooking(row.entity.ID)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const amend = `<a ng-disabled="row.entity.INTTRA_SITUATION == 'DECLINE' ||  row.entity.INTTRA_SITUATION == 'CANCEL' || !row.entity.INTTRA_REFERENCE_NUMBER" ng-click="(row.entity.INTTRA_SITUATION == 'DECLINE' || row.entity.INTTRA_SITUATION == 'CANCEL') ? '' : grid.appScope.amendBooking(row.entity.ID)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const updateBooking = `<a ng-click="grid.appScope.updateBooking(row.entity.ID, row.entity.INTTRA_REFERENCE_NUMBER)" tooltip-placement="auto top" uib-tooltip="{{'OPERATIONAL.UPDATE_BOOKING' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-exchange text-info"></i></a>&nbsp;&nbsp;`;
        const differencesModal = `<a ng-click="!row.entity.AWAITING_INTTRA_CONFIRMATION && grid.appScope.openDifferencesModal(row.entity)" ng-class="{ 'cursor-default': row.entity.AWAITING_INTTRA_CONFIRMATION }" tooltip-placement="auto top" uib-tooltip="{{row.entity.HAS_CRITICAL_DIFFS ? 'OPERATIONAL.HAS_CRITICAL_DIFFERENCES' : row.entity.AWAITING_INTTRA_CONFIRMATION ? 'OPERATIONAL.BOOKING_REQUESTED' : 'OPERATIONAL.NO_CRITICAL_DIFFERENCES' | translate }}" tooltip-append-to-body="true" ><i class="fa" ng-class="row.entity.HAS_CRITICAL_DIFFS ? 'fa-exclamation-triangle text-danger' : row.entity.AWAITING_INTTRA_CONFIRMATION ? 'fa-exclamation-triangle text-warning' : 'fa-check text-green'"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a 
                                ng-click="grid.appScope.openModalIntegration(row.entity)"
                                ng-class="(row.entity.VALIDATION_RESULT && row.entity.VALIDATION_RESULT.HAS_ERRORS) ? 'text-danger' : (row.entity.VALIDATION_RESULT && row.entity.VALIDATION_RESULT.HAS_WARNINGS) ? 'text-warning' : 'text-green'"
                                tooltip-placement="auto top" uib-tooltip="{{'OPERATIONAL.BOOKING_INTEGRATION' | translate }}" tooltip-append-to-body="true"><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLog(row.entity, row.entity._id)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;

        gridColumns.$columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: 140,
            cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${amend} ${updateBooking} ${differencesModal} ${modalIntegration} ${viewLog}</div>`,
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colFile: IMonacoColumnDef = { name: "FILE.NAME", displayName: "OPERATIONAL.FILE_NUMBER", width: 150 };
            const colCustomer: IMonacoColumnDef = { name: "CUSTOMER.NAME", displayName: "BASIC_DATA.CLIENT", width: 150 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.NAME", displayName: "BASIC_DATA.PRODUCT", width: 150 };
            const colCarrierBooking: IMonacoColumnDef = { name: "CARRIER_BOOKING_NUMBER", displayName: "OPERATIONAL.CARRIER_BOOKING", width: 150 };
            const colInttraReference: IMonacoColumnDef = { name: "INTTRA_REFERENCE_NUMBER", displayName: "OPERATIONAL.INTTRA_REFERENCE", width: 150 };
            const colCarrier: IMonacoColumnDef = { name: "CARRIER.NAME", displayName: "BASIC_DATA.SEA_CARRIER", width: 150 };
            const colService: IMonacoColumnDef = { name: "SERVICE", displayName: "BASIC_DATA.SERVICE", width: 150 };
            const colPreCarriageETD: IMonacoColumnDef = { name: "PRE_CARRIAGE.ETD", displayName: "OPERATIONAL.PRE_CARRIAGE_ETD", width: 150, cellFilter: 'date:\'dd/MM/yyyy\'' };
            const colPreCarriageETA: IMonacoColumnDef = { name: "PRE_CARRIAGE.ETA", displayName: "OPERATIONAL.PRE_CARRIAGE_ETA", width: 150, cellFilter: 'date:\'dd/MM/yyyy\'' };
            const colVessel: IMonacoColumnDef = { name: "MAIN_CARRIAGE.VESSEL.NAME", displayName: "BASIC_DATA.VESSEL", width: 150 };
            const colVoy: IMonacoColumnDef = { name: "MAIN_CARRIAGE.VOYAGE", displayName: "OPERATIONAL.VOYAGE", width: 150 };
            const colMainCarriageLoading: IMonacoColumnDef = { name: "MAIN_CARRIAGE.LOADING_DATE", displayName: "OPERATIONAL.MAIN_CARRIAGE_LOADING_DATE", width: 150, cellFilter: 'date:\'dd/MM/yyyy\'' };
            const colMainCarriageDischarge: IMonacoColumnDef = { name: "MAIN_CARRIAGE.DISCHARGE_DATE", displayName: "OPERATIONAL.MAIN_CARRIAGE_DISCHARGE_DATE", width: 150, cellFilter: 'date:\'dd/MM/yyyy\'' };
            const colOnCarriageETD: IMonacoColumnDef = { name: "ON_CARRIAGE.ETD", displayName: "OPERATIONAL.ON_CARRIAGE_ETD", width: 150, cellFilter: 'date:\'dd/MM/yyyy\'' };
            const colOnCarriageETA: IMonacoColumnDef = { name: "ON_CARRIAGE.ETA", displayName: "OPERATIONAL.ON_CARRIAGE_ETA", width: 150, cellFilter: 'date:\'dd/MM/yyyy\'' };
            const colMove: IMonacoColumnDef = { name: "MOVE_TYPE.CODE", displayName: "GENERAL.MOVEMENT_TYPE", width: 150 };
            const colStart: IMonacoColumnDef = { name: "PLACE_CARRIER_RECEIPT.CODE", displayName: "OPERATIONAL.START", width: 150 };
            const colEtd: IMonacoColumnDef = { name: "EARLIEST_DEPARTURE_DATE", displayName: "OPERATIONAL.ETD", width: 150, cellFilter: 'date:\'dd/MM/yyyy\'' };
            const colEnd: IMonacoColumnDef = { name: "PLACE_CARRIER_DELIVERY.CODE", displayName: "OPERATIONAL.END", width: 150 };
            const colEta: IMonacoColumnDef = { name: "LATEST_DELIVERY_DATE", displayName: "OPERATIONAL.ETA", width: 150, cellFilter: 'date:\'dd/MM/yyyy\'' };
            const colCargoType: IMonacoColumnDef = { name: "CARGO_TYPE.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 150 };
            const colEquipment: IMonacoColumnDef = { name: "EQUIPMENT_LIST", displayName: "GENERAL.EQUIPMENT", width: 150, cellTemplate: '<div class="grid-padding">{{grid.appScope.concatEquipmentToGrid(row.entity.EQUIPMENT_LIST)}}</div>' };
            const colTeus: IMonacoColumnDef = { name: "TOTAL_TEU", displayName: "GENERAL.TEUS", width: 150 };
            const colOog: IMonacoColumnDef = { name: "OOG", displayName: "GENERAL.OOG", width: 150, cellFilter: 'YesOrNo' };
            const colHsCode: IMonacoColumnDef = { name: "CARGO_DETAIL", displayName: "BASIC_DATA.HS_CODE", width: 150, cellTemplate: '<div class="grid-padding">{{grid.appScope.concatCommodityToGrid(row.entity.CARGO_DETAIL)}}</div>' };
            const colBookingSituation: IMonacoColumnDef = { name: "BOOKING_SITUATION", displayName: "OPERATIONAL.BOOKING_SITUATION", width: 150 };
            const colLoadingSituation: IMonacoColumnDef = { name: "LOADING_SITUATION", displayName: "OPERATIONAL.LOADING_SITUATION", width: 150 };
            const colInttraSituation: IMonacoColumnDef = { name: "INTTRA_SITUATION", displayName: "OPERATIONAL.INTTRA_STATUS", width: 150 };
            const colLastUpdate: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.LAST_UPDATE", width: 150, cellFilter: 'date:\'dd/MM/yyyy\'' };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 150 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'FILE':
                        columnDefs.push(colFile);
                        break;
                    case 'CUSTOMER':
                        columnDefs.push(colCustomer);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'CARRIER_BOOKING_NUMBER':
                        columnDefs.push(colCarrierBooking);
                        break;
                    case 'INTTRA_REFERENCE_NUMBER':
                        columnDefs.push(colInttraReference);
                        break;
                    case 'CARRIER':
                        columnDefs.push(colCarrier);
                        break;
                    case 'SERVICE':
                        columnDefs.push(colService);
                        break;
                    case 'PRE_CARRIAGE':
                        columnDefs.push(colPreCarriageETD);
                        columnDefs.push(colPreCarriageETA);
                        break;
                    case 'MAIN_CARRIAGE':
                        columnDefs.push(colVessel);
                        columnDefs.push(colVoy);
                        columnDefs.push(colMainCarriageLoading);
                        columnDefs.push(colMainCarriageDischarge);
                        break;
                    case 'ON_CARRIAGE':
                        columnDefs.push(colOnCarriageETD);
                        columnDefs.push(colOnCarriageETA);
                        break;
                    case 'MOVE_TYPE':
                        columnDefs.push(colMove);
                        break;
                    case 'PLACE_CARRIER_RECEIPT':
                        columnDefs.push(colStart);
                        break;
                    case 'EARLIEST_DEPARTURE_DATE':
                        columnDefs.push(colEtd);
                        break;
                    case 'PLACE_CARRIER_DELIVERY':
                        columnDefs.push(colEnd);
                        break;
                    case 'LATEST_DELIVERY_DATE':
                        columnDefs.push(colEta);
                        break;
                    case 'CARGO_TYPE':
                        columnDefs.push(colCargoType);
                        break;
                    case 'EQUIPMENT':
                        columnDefs.push(colEquipment);
                        break;
                    case 'TOTAL_TEU':
                        columnDefs.push(colTeus);
                        break;
                    case 'OOG':
                        columnDefs.push(colOog);
                        break;
                    case 'CARGO_DETAIL':
                        columnDefs.push(colHsCode);
                        break;
                    case 'BOOKING_SITUATION':
                        columnDefs.push(colBookingSituation);
                        break;
                    case 'LOADING_SITUATION':
                        columnDefs.push(colLoadingSituation);
                        break;
                    case 'INTTRA_SITUATION':
                        columnDefs.push(colInttraSituation);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colLastUpdate);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {

    }

    private concatEquipmentToGrid = (EQUIPMENT_LIST: IEquipmentList[]): string => {
        try {
            let equipmentView: string = "";
            if (EQUIPMENT_LIST) {
                EQUIPMENT_LIST.forEach(equipment => {
                    if (equipmentView.length) equipmentView += ", ";
                    const infoEquip = equipment.EQUIPMENT.CODE ? equipment.EQUIPMENT.CODE : equipment.EQUIPMENT.ISO;
                    equipmentView += `${equipment.QUANTITY} x ${infoEquip}`;
                });
            }
            return equipmentView;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getBookingById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const bookingResult = await this.operationalService.post(`/booking/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000);
            return bookingResult.data && bookingResult.data.data && bookingResult.data.data.data ? bookingResult.data.data.data[0] : null;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private concatCommodityToGrid = (CARGO_DETAIL: ICargoDetail[]): string => {
        try {
            let commodityView: string = "";
            if (CARGO_DETAIL) {
                CARGO_DETAIL.forEach(cargoDetail => {
                    if (cargoDetail.COMMODITY) {
                        cargoDetail.COMMODITY.forEach(commodity => {
                            if (commodityView.length) commodityView += ", ";
                            commodityView += commodity.CODE;
                        });
                    }
                });
            }
            return commodityView;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openWizard(isViewMode?: boolean, isAmendMode?: boolean, bookingId?: number): Promise<void> {
        try {
            const bookingToView = (isViewMode || isAmendMode) && bookingId ? await this.getFullBookingById(bookingId) : null;
            if (!isViewMode || (isViewMode || isAmendMode) && bookingToView) {
                this.modalID = this.ModalService.newModal();
                const formMode = isViewMode ? 'view' : isAmendMode ? 'edit' : 'register';
                const wizardTextMode = isViewMode ? 'GENERAL.VIEWING' : isAmendMode ? 'GENERAL.EDITING' : 'GENERAL.NEW';
                const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                    {
                        modalID: this.modalID,
                        template: require("../view/modal/bookingWizard.html"),
                        formService: formMode,
                        size: 'full modal-overflow',
                    },
                    {
                        actionButtonClass: 'btn-default',
                        actionButtonText: 'GENERAL.CANCEL',
                        headerText: this.formService.getTranslate(wizardTextMode)
                    },
                    {
                        isViewMode: isViewMode,
                        isAmendMode: isAmendMode,
                        bookingToView: bookingToView
                    }
                );
                const modalScope = await this.ModalService.getModalScope(this.modalID);
                await modalScope.$applyAsync();

                if (!isViewMode && !isAmendMode) {
                    const apply = await modalInstance.result.then(function (result) {
                        return result.$value;
                    }, function (result) {
                        return result.$value;
                    });

                    if (apply) {
                        const msg = this.formService.getTranslate('OPERATIONAL.BOOKING_REGISTERED');
                        this.formService.notifySuccess(msg);
                        this.formService.block();
                        await this.updateGrid();
                    }
                }

                if (isAmendMode) {
                    const apply = await modalInstance.result.then(function (result) {
                        return result.$value;
                    }, function (result) {
                        return result.$value;
                    });

                    if (apply) {
                        const msg = this.formService.getTranslate('OPERATIONAL.BOOKING_AMENDED');
                        this.formService.notifySuccess(msg);
                        this.formService.block();
                        await this.updateGrid();
                    }
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getFullBookingById(bookingId: number): Promise<IBooking> {
        let booking: IBooking = null;
        try {
            this.block();
            const result = await this.operationalService.get<any>(`/booking/getById/${bookingId}`, null);
            if (result && result.data && result.data.data) booking = result.data.data;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return booking;
        }
    }

    private async openDifferencesModal(model: IBookingGridModel): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    template: require("../view/modal/bookingDifferencesModal.html"),
                    formService: 'register',
                    size: 'full modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CANCEL',
                    headerText: `${this.formService.getTranslate('OPERATIONAL.BOOKING_VALIDATION')}: ${model.FILE && model.FILE.NAME ? model.FILE.NAME + '|' : ''} ${model.CARRIER_BOOKING_NUMBER} | ${model.INTTRA_REFERENCE_NUMBER}`
                },
                {
                    bookingId: model.ID
                }
            );

            const modalScope = await this.ModalService.getModalScope(this.modalID);
            await modalScope.$applyAsync();

            const apply = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (apply) {
                const msg = this.formService.getTranslate('OPERATIONAL.BOOKING_REGISTERED');
                this.formService.notifySuccess(msg);
                this.formService.block();
                await this.updateGrid();
            }

        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async openModalIntegration(entity: IBookingGridModel): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const id: number = entity.ID;
            const documentErrorList: IDocumentError[] = entity.DOCUMENT_ERROR;
            let header: string = this.formService.getTranslate('GENERAL.DOCUMENT_ERRORS');
            header += entity.INTTRA_REFERENCE_NUMBER ? ' - ' + entity.INTTRA_REFERENCE_NUMBER : entity.CARRIER_BOOKING_NUMBER ? ' - ' + entity.CARRIER_BOOKING_NUMBER : entity.FILE.ID ? ' - ' + entity.FILE.NAME : entity.ID ? ' - ' + entity.ID : '';
            this.ModalService.showModalIntegrationRedundance({ integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, headerText: header });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                this.formService.block();
                const reprocessBookingRequest = await this.externalService.post({ route: '/inttra/reprocessBooking', data: { "idBooking": id } });
                if (reprocessBookingRequest) {
                    success = true;
                    const modalScope: INewIntegrationRedundanceModalScope = await this.ModalService.getModalScope(this.modalID);
                    if (modalScope) {
                        if (!modalScope.messageDefault) modalScope.messageDefault = { awaitingRequest: null, successRequest: null, awaitingGridSync: null, successGridSync: null, gridSyncError: null, logCopyMessage: null, requestError: null };
                        if (reprocessBookingRequest.data.data.HAS_ERRORS || reprocessBookingRequest.data.data.HAS_WARNINGS) {
                            success = false;
                            modalScope.messageDefault.requestError = reprocessBookingRequest.data.data.HAS_ERRORS ? reprocessBookingRequest.data.data.ERRORS : reprocessBookingRequest.data.data.WARNINGS;
                        } else modalScope.messageDefault.requestError = null;
                    }
                }
            }
        } catch (ex) {
            const msg = this.formService.getTranslate('GENERAL.ERROR_SENDING_REQUEST');
            this.formService.handleError(msg);
        } finally {
            this.formService.unblock();
            return success;
        }
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            { PROPERTY: "FILE", LABEL: "OPERATIONAL.FILE_NUMBER" },
            { PROPERTY: "PRODUCT", LABEL: "BASIC_DATA.PRODUCT" },
            { PROPERTY: "CUSTOMER", LABEL: "BASIC_DATA.CLIENT" },
            { PROPERTY: "CARRIER", LABEL: "BASIC_DATA.SEA_CARRIER" },
            { PROPERTY: "CONTRACT_NUMBER", LABEL: "BASIC_DATA.FREIGHT_CONTRACT" },
            { PROPERTY: "CARRIER_BOOKING_NUMBER", LABEL: "OPERATIONAL.BOOKING" },
            { PROPERTY: "FORWARDER", LABEL: "ENTITY.FOWARDER" },
            { PROPERTY: "FORWARDER_REFERENCE_NUMBER", LABEL: "OPERATIONAL.FORWARDER_REFERENCE" },
            { PROPERTY: "MOVE_TYPE", LABEL: "BASIC_DATA.MOVE_TYPE" },
            { PROPERTY: "PLACE_CARRIER_RECEIPT", LABEL: "BASIC_DATA.PLACE_OF_RECEIPT" },
            { PROPERTY: "PORT_OF_LOAD", LABEL: "BASIC_DATA.LOADING_PORT" },
            { PROPERTY: "EARLIEST_DEPARTURE_DATE", LABEL: "OPERATIONAL.EARLIEST_DEPARTURE_DATE" },
            { PROPERTY: "PORT_OF_DISCHARGE", LABEL: "BASIC_DATA.DISCHARGE_PORT" },
            { PROPERTY: "PLACE_CARRIER_DELIVERY", LABEL: "BASIC_DATA.DELIVERY_PLACE" },
            { PROPERTY: "LATEST_DELIVERY_DATE", LABEL: "OPERATIONAL.ESTIMATED_DELIVERY_DATE" },
            { PROPERTY: "IS_DANGER_CARGO", LABEL: "BASIC_DATA.DANGEROUS_CARGO" },
            { PROPERTY: "DANGER_CARGO", LABEL: "BASIC_DATA.DANGEROUS_CARGO" },
            { PROPERTY: "CARGO_DETAIL", LABEL: "GENERAL.CARGO_DETAILS" },
            { PROPERTY: "PAYER_INFORMATION", LABEL: "FINANCIAL.PAYER" },
            { PROPERTY: "OOG", LABEL: "GENERAL.OOG" },
            { PROPERTY: "EQUIPMENT_LIST", LABEL: "BASIC_DATA.EQUIPMENT" },
            { PROPERTY: "TOTAL_TEU", LABEL: "BASIC_DATAT.TOTAL_TEU" },
            { PROPERTY: "PRE_CARRIAGE", LABEL: "OPERATIONAL.PRE_CARRIAGE" },
            { PROPERTY: "DISCHARGE_DATE", LABEL: "OPERATIONAL.MAIN_CARRIAGE_DISCHARGE_DATE" },
            { PROPERTY: "LOADING_DATE", LABEL: "OPERATIONAL.MAIN_CARRIAGE_LOADING_DATE" },
            { PROPERTY: "SERVICE", LABEL: "BASIC_DATA.SERVICE" },
            { PROPERTY: "ON_CARRIAGE", LABEL: "OPERATIONAL.ON_CARRIAGE" },
            { PROPERTY: "DOCUMENT_ERROR", LABEL: "GENERAL.DOCUMENT_ERRORS" },
            { PROPERTY: "BOOKING_SITUATION", LABEL: "OPERATIONAL.BOOKING_SITUATION" },
            { PROPERTY: "LOADING_SITUATION", LABEL: "OPERATIONAL.LOADING_SITUATION" },
            { PROPERTY: "INTTRA_SITUATION", LABEL: "OPERATIONAL.INTTRA_STATUS" },
            { PROPERTY: "COMMENTS", LABEL: "GENERAL.REMARKS" },
            { PROPERTY: "PARTNER_EMAIL_NOTIFICATION", LABEL: "OPERATIONAL.PARTNER_EMAIL_NOTIFICATION" },
            { PROPERTY: "NOTIFY_ME", LABEL: "OPERATIONAL.NOTIFY_ME" },
            { PROPERTY: "CREATED_AT", LABEL: "GENERAL.CREATED_AT" },
            { PROPERTY: "CREATED_BY", LABEL: "GENERAL.CREATED_BY" },
            { PROPERTY: "UPDATED_AT", LABEL: "GENERAL.UPDATED_AT" },
            { PROPERTY: "UPDATED_BY", LABEL: "GENERAL.UPDATED_BY" },
            { PROPERTY: "VALIDATION_RESULT", LABEL: "OPERATIONAL.BOOKING_VALIDATION" }
        ]
        return props;
    }

}

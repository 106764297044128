import { IMonacoController, IMonacoConfig, IMonacoScope } from "../../common/MonacoInterface";
import { SelectorModel } from "../../common/model/SelectorModel";
import { IRestService } from "@services/RestService";
import { HandleError } from "../../common/util/HandleError";
import { IGridSpreadsheet } from "@models/interface/product/GridSpreadsheet";

interface IExcelTemplateModel {
    selectedTemplate: IGridSpreadsheet;
}

interface IExcelTemplateModalScope extends ng.IScope {
    modalOptions: any;
    modalID: number;
    templateList: IGridSpreadsheet[];
    model: IExcelTemplateModel;
    gridName: string;
}

export class ExcelTemplateModalController implements IMonacoController {

    public static $inject: string[] = ['$scope', '$injector'];
    private $scope: IExcelTemplateModalScope;
    private RestService: IRestService;
    private config: IMonacoConfig;
    private rootScope: IMonacoScope;
    private blockUI: ng.blockUI.BlockUIService;

    constructor($scope, $injector: ng.Injectable<any>) {
        this.$scope = $scope;
        this.rootScope = $injector.get('$rootScope');
        this.config = $injector.get('config');
        this.RestService = $injector.get('RestService');
        this.blockUI = $injector.get('blockUI');
    }

    getUrlProduct() {
        try {
            const baseRoute = '/product';
            const urlProduct = this.config.productUrl + baseRoute;
            return urlProduct;
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    async $onInit(): Promise<void> {
        this.initModel();
        this.initDependencies();
    }

    $onDestroy() { }

    private async initModel() {
        this.$scope.model = {
            selectedTemplate: null
        }
    }

    async initDependencies(): Promise<boolean> {
        try {
            this.$scope.templateList = await this.getExcelTemplateList();
            this.$scope.model.selectedTemplate = await this.getSelectedTemplateBySetting();
            this.$scope.$applyAsync();
            return true;
        } catch (ex) {
            HandleError.exception(`Falha ao carregar lista de dependências. ${JSON.stringify(ex)}`);
        }
    }

    private async getExcelTemplateList(): Promise<IGridSpreadsheet[]> {
        let result = [];
        try {
            const templates = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/gridSpreadsheet/list/custom`, { gridName: this.$scope.gridName }, 120000, false);
            if (templates) result = templates.map(x => { return { ID: x.ID, TEMPLATE: x.TEMPLATE, DEFAULT: x.DEFAULT, DISPLAY_NAME: x.DISPLAY_NAME } });
        } catch (ex) {
            HandleError.exception(ex);
        }
        return result;
    }

    private async getSelectedTemplateBySetting(): Promise<IGridSpreadsheet> {
        try {
            const result = await this.rootScope.getSetting('GRID_SELECTED_TEMPLATE');
            let selected: IGridSpreadsheet;

            if (result && this.$scope.templateList) {
                const gridTemplates = result.find(x => x.GRID_NAME == this.$scope.gridName);
                if (gridTemplates) {
                    selected = this.$scope.templateList.find(x => x.DISPLAY_NAME == gridTemplates.TEMPLATE);
                }
            }
            if (!selected && this.$scope.templateList) {
                selected = this.$scope.templateList.find(x => x.DEFAULT);
            }
            return selected ? selected : null;
        } catch (ex) {
            HandleError.exception(ex);
        } finally {
            this.blockUI.stop();
        }
    }

}
import * as angular from "angular";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { ISectorModel } from "../model/SectorModel";
import { SelectorModel } from "../../common/model/SelectorModel";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { BrowserTitle } from "../../common/BrowserTitle";
import { SSEService } from "@appServices/SSEService"
import { ISessionService } from "@services/SessionService";
import { EOperation } from "@enums/GenericData";
import { HelperService } from "@services/HelperService";
import { IDocumentError } from '@models/interface/common/IDocumentError';
import { IModalService } from "@services/ModalService";


interface ISectorScope extends IGridFormServiceScope {
    model: ISectorModel;
    log: IViewLog;
    typeList: SelectorModel[];
    gridOptions: uiGrid.IGridOptions;
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: ISectorModel;
    user: any;
    sessionService: ISessionService;
    editSector: (sector: ISectorModel) => Promise<void>;
    viewSector: (sector: ISectorModel) => Promise<void>;
    viewLogSector: (sector: ISectorModel) => Promise<void>;
    copySector: (sector: ISectorModel) => Promise<void>;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
}

export class SectorRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: ISectorScope;
    private $timeout: ng.ITimeoutService
    private $q: ng.IQService;
    private RestService: IRestService;
    private sessionService: ISessionService;
    private SSEService: SSEService;
    private gridName: string;
    private helperService: HelperService;
    private modalID: number;
    private ModalService: IModalService;

    constructor($injector: ng.Injectable<any>, $scope: ISectorScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.RestService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.gridName = "gridSector"
        this.helperService = $injector.get('HelperService');
        this.ModalService = $injector.get('ModalService');
        this.modalID = null;
    }

    getUrlProduct() {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();

            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'sector', 'GENERAL.MENU.SECTOR', true);
            await this.initGrid('gridSector', '/sector/list', true, true, null, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editSector = async (sector: ISectorModel): Promise<void> => {
            let blockedObject = {
                ID: sector.ID,
                NAME: sector.DISPLAY_NAME,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || sector.ID !== this.$scope.model.ID) this.$scope.view(sector);
                } else if (this.$scope.operation !== EOperation.EDIT || sector.ID !== this.$scope.model.ID) {
                    this.$scope.edit(sector);
                }
            };
        }

        this.$scope.viewSector = async (sector: ISectorModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(sector);
        }

        this.$scope.viewLogSector = async (sector: ISectorModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(sector);
        }

        this.$scope.copySector = async (sector: ISectorModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(sector);
        }

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<a ng-click="grid.appScope.viewSector(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true"><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editSector(row.entity)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true"><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogSector(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true"><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.setCopy(true);grid.appScope.copySector(row.entity)" class="text-orange copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true"><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes", displayName: "GENERAL.ACTIONS",
            width: 125,
            cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${viewLog} ${copy} ${modalIntegration}</div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };

        gridColumns.addColumn(colActions);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colType: IMonacoColumnDef = { name: 'TYPE.NAME', displayName: 'GENERAL.TYPE', width: 80 };
            const colName: IMonacoColumnDef = { name: "DISPLAY_NAME", displayName: "GENERAL.NAME", width: 400 };
            const colNameIntl: IMonacoColumnDef = { name: "NAME_INTL", displayName: "GENERAL.NAME_INTL", width: 400 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 100, cellFilter: "YesOrNo" };
            const colIntegrationID: IMonacoColumnDef = { name: 'INTEGRATION_ID', displayName: 'GENERAL.INTEGRATION_ID', width: 150 };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'TYPE':
                        columnDefs.push(colType);
                        break;
                    case 'DISPLAY_NAME':
                        columnDefs.push(colName);
                        break;
                    case 'NAME_INTL':
                        columnDefs.push(colNameIntl);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'INTEGRATION_ID':
                        columnDefs.push(colIntegrationID);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            ACTIVE: true,
            TYPE: null,
            NAME: null,
            DISPLAY_NAME: null,
            NAME_INTL: null,
            INTEGRATION_ID: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            DOCUMENT_ERROR: null,
            SEARCH_FIELDS: null
        };
    }

    initDependencies(): Promise<any> {
        const self: SectorRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getSectorType()
            ]).then((result: any) => {
                self.$scope.typeList = result[0];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getSectorType(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/sector_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "GENERAL.MENU.INTEGRATION" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number, idSubject: string): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/sector/sendSync`, { "idSector": id, "idSubject": idSubject }, 120000, false);
                if (syncRequest) success = true;
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_SENDING_REQUEST');
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const legalPersonData = await this.getSectorById(id);
                    if (row && legalPersonData && legalPersonData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = legalPersonData.DOCUMENT_ERROR;
                        documentError = legalPersonData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async getSectorById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/sector/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} (${this.$scope.model.DISPLAY_NAME})`;
            BrowserTitle.$id = this.$scope.model.DISPLAY_NAME;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} (${this.$scope.model.DISPLAY_NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.DISPLAY_NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        return {
            route: `/sector/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: "TYPE",
                LABEL: "GENERAL.TYPE"
            },
            {
                PROPERTY: "NAME",
                LABEL: "GENERAL.NAME"
            },
            {
                PROPERTY: "DISPLAY_NAME",
                LABEL: "GENERAL.NAME"
            },
            {
                PROPERTY: "NAME_INTL",
                LABEL: "GENERAL.NAME_INTL"
            },
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE"
            },
            {
                PROPERTY: "INTEGRATION_ID",
                LABEL: "GENERAL.INTEGRATION_ID"
            },
            {
                PROPERTY: "CREATED_AT",
                LABEL: "GENERAL.CREATED_AT"
            },
            {
                PROPERTY: "CREATED_BY",
                LABEL: "GENERAL.CREATED_BY"
            },
            {
                PROPERTY: "UPDATED_AT",
                LABEL: "GENERAL.UPDATED_AT"
            },
            {
                PROPERTY: "UPDATED_BY",
                LABEL: "GENERAL.UPDATED_BY"
            }
        ];
        return props;

    }
}

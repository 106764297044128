import * as angular from 'angular';

const crmState = {
    name: 'app.crm',
    url: '/crm',
    abstract: true,
    data: {
        requiresAuth: true,
        permissions: {
            except: 'EXTERNAL',
            redirectTo: 'app.denied'
        }
    }
}

const peopleState = {
    name: 'app.crm.people',
    url: '/people',
    abstract: true
}

const peopleRequirementState = {
    name: 'app.crm.people.requirement',
    url: '/requirement',
    abstract: true
}

const peopleRequirementWizardState = {
    name: 'app.crm.people.requirement.wizard',
    url: '/:source/:operation/:requirements',
    views: {
        'content@app': {
            require: require("./view/peopleRequirementsRegister.html")
        },
    },
    params: {
        operation: undefined,
        requirements: undefined,
        source: undefined
    },
    ncyBreadcrumb: {
        label: 'Cadastro de Requisitos',
        parent: function ($scope) {
            return $scope.from;
        }
    }
}

const peopleRequirementListState = {
    name: 'app.crm.people.requirement.list',
    url: '/list',
    views: {
        'content@app': {
            template: require("./view/peopleRequirementsList.html")
        },
    },
    ncyBreadcrumb: {
        label: 'Requisitos',
        parent: 'app.crm.people.requirement'
    }
}

const peopleRequirementRegisterState = {
    name: 'app.crm.people.requirement.register',
    url: '/register',
    views: {
        'content@app': {
            template: require("./view/peopleRequirementsRegister.html")
        },
    },
    params: {
        operation: 'register',
        requirements: undefined,
    },
    ncyBreadcrumb: {
        label: 'Cadastro de Requisitos',
        parent: function ($scope) {
            return $scope.from;
        }
    }
}

const peopleRequirementViewState = {
    name: 'app.crm.people.requirement.view',
    url: '/view/:requirement?',
    views: {
        'content@app': {
            template: require("./view/peopleRequirementsRegister.html")
        },
    },
    params: {
        requirement: undefined,
        operation: "view"
    },
    ncyBreadcrumb: {
        label: 'Visualização do Requisito',
        parent: 'app.crm.people.requirement'
    }
}

const peopleRequirementEditState = {
    name: 'app.crm.people.requirement.edit',
    url: '/edit/:requirement?',
    views: {
        'content@app': {
            template: require("./view/peopleRequirementsRegister.html")
        },
    },
    params: {
        requirement: undefined,
        operation: "edit"
    },
    ncyBreadcrumb: {
        label: 'Edição do Requisito',
        parent: 'app.crm.people.requirement'
    }
}

const quotationState = {
    name: 'app.crm.quotation',
    url: '/quotation',
    abstract: true
}

const quotationListState = {
    name: 'app.crm.quotation.list',
    url: '/list',
    views: {
        'content@app': {
            template: require("./view/quotationList.html")
        },
    },
    ncyBreadcrumb: {
        label: 'Propostas',
        parent: function ($scope) {
            return $scope.from;
        }
    }
}

const offerState = {
    name: 'app.crm.offer',
    url: '/offer',
    abstract: true
}

const quotationWizardState = {
    name: 'app.crm.quotation.wizard',
    url: '/wizard',
    views: {
        'content@app': {
            template: require("./view/quotationWizard.html")
        },
    },
    params: {
        proposta: undefined,
        compare: undefined
    },
    ncyBreadcrumb: {
        label: '{{breadCrumb.label}}',
        parent: function ($scope) {
            return $scope.from;
        }
    }
}

routeConfig.$inject = ['$uiRouterProvider'];
function routeConfig($uiRouterProvider) {
    const $stateRegistry = $uiRouterProvider.stateRegistry;
    $stateRegistry.register(crmState);
    $stateRegistry.register(quotationState);
    $stateRegistry.register(quotationListState);
    $stateRegistry.register(offerState);
    $stateRegistry.register(quotationWizardState);
    $stateRegistry.register(peopleState);
    $stateRegistry.register(peopleRequirementState);
    $stateRegistry.register(peopleRequirementWizardState);
    $stateRegistry.register(peopleRequirementListState);
    $stateRegistry.register(peopleRequirementRegisterState);
    $stateRegistry.register(peopleRequirementViewState);
    $stateRegistry.register(peopleRequirementEditState);
}

export default function initRoute(moduleName: string): void {
    angular.module(moduleName).config(routeConfig);
}
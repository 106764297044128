import { IGridRow, IColumnDef } from "ui-grid";
import { GridFormService, IGridFormController, IGridFormServiceScope } from "@services/GridFormService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IModalService } from "@services/ModalService";
import { ISessionService } from '@services/SessionService';
import { ExternalService } from "@services/ExternalService";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { NotificationMailBox } from "@models/interface/integration/NotificationMailBox";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";

export interface IMonacoRequest<T = any> {
    route?: string;
    operation?: string;
    data?: T;
    timeout?: number;
    user?: any;
}

interface INotificationMailBoxScope extends IGridFormServiceScope {
    model: NotificationMailBox;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    selectedRows: NotificationMailBox[];
    clearSelections: () => Promise<void>;
    readCheck: (model: NotificationMailBox) => void;
    goToLink: (link: string, filter: string) => void;
    form: ng.IFormController;
}

export class NotificationMailBoxRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ["$injector", "$scope", "$element"];
    private $scope: INotificationMailBoxScope;
    private selectedRows: NotificationMailBox[];
    private ModalService: IModalService;
    private SessionService: ISessionService;
    private ExternalService: ExternalService;
    modalID: any;

    constructor($injector: ng.Injectable<any>, $scope: INotificationMailBoxScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.ModalService = $injector.get('ModalService');
        this.SessionService = $injector.get('SessionService');
        this.ExternalService = $injector.get('ExternalService');
    }

    initScopeFunctions(): void {
        this.$scope.clearSelections = async () => {
            await this.clearSelections();
        }

        this.$scope.readCheck = (model: NotificationMailBox) => {
            this.readCheck(model);
        }

        this.$scope.goToLink = (link: string, filter: string) => {
            this.SessionService.openTab(link, JSON.parse(filter));
        }
    }

    async $onInit(): Promise<void> {
        try {
            this.selectedRows = [];
            this.$scope.selectedRows = [];
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.$baseUrl = this.config.externalUrl + '/external';
            this.initForm(this, "form", "notificationMailBox", "{{ 'OPERATIONAL.PROCESSING_INTEGRATION' | translate }}", false);
            this.block();

            // enable multi row selection
            this.$gridService.setSelectable(true);

            // init grid
            await this.initGrid('gridNotificationMailBox', '/notificationMailBox/list', true, true, 120000, true, true);
            this.$gridService.setBackgroundUpdate(120000, [this.selectionReapply, this]);

            this.monitoringFilterChanges();

            // register grid multiple rows selection callback
            this.$gridService.$gridApi.selection.on.rowSelectionChanged(this.$scope, this.selectedRowCallback.bind(this));
            this.$gridService.$gridApi.selection.on.rowSelectionChangedBatch(this.$scope, this.selectedRowBatchCallback.bind(this));

            this.unblock();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);
        const columnDefs: Array<IColumnDef> = gridColumns.$columnDefs;

        //situations
        const readCheck = `<span ng-show="true"><a ng-click="grid.appScope.readCheck(row.entity)" class="text-black" tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.EMAIL_READ' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-check-square-o icon"></i></a>&nbsp;&nbsp;</span>`;
        //actions       
        const actions = `<div class="text-center pull-left" style="padding-left: 10px;">${readCheck}</div>`;

        columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: '2%',
            cellTemplate: (actions),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });
        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            // visible
            const colUserCell: IMonacoColumnDef = { name: "USER_CELL.NAME", displayName: "REGISTRATION.RESPONSIBLE_CELL", width: 150 };
            const colHeader: IMonacoColumnDef = { name: "HEADER", displayName: "GENERAL.DESCRIPTION", width: 400, cellTemplate: '<div class="text-{{row.entity.NOTIFICATION_TYPE.COLOR}} ui-grid-cell-contents ng-binding ng-scope"><i class="fa fa-{{row.entity.NOTIFICATION_TYPE.ICON}}" aria-hidden="true"></i>&nbsp;{{row.entity.HEADER}}</div>' };
            const colDate: IMonacoColumnDef = { name: "DATE", displayName: "GENERAL.DATE", width: 140, cellFilter: "datetime" };
            const colRead: IMonacoColumnDef = { name: "READ", displayName: "OPERATIONAL.EMAIL_READ", width: 140, cellFilter: "YesOrNo" };
            const colLink: IMonacoColumnDef = { name: "LINK", displayName: "GENERAL.LINK", width: 150, cellTemplate: `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{ 'REGISTRATION.CLICK_VIEW' | translate }}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToLink(row.entity.LINK, row.entity.FILTER)">{{ 'REGISTRATION.CLICK_HERE' | translate }}</a></div>` };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'USER_CELL':
                        columnDefs.push(colUserCell);
                        break;
                    case 'HEADER':
                        columnDefs.push(colHeader);
                        break;
                    case 'LINK':
                        columnDefs.push(colLink);
                        break;
                    case 'DATE':
                        columnDefs.push(colDate);
                        break;
                    case 'READ':
                        columnDefs.push(colRead);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    public async selectionReapply(): Promise<void> {
        const rows = this.$gridService.$gridApi.core.getVisibleRows(this.$gridService.$gridApi.grid);
        const selectedRows = this.$gridService.$gridSelectedRows;

        if (selectedRows && selectedRows.length > 0) {
            const updatedRows: IGridRow[] = [];
            for (const row of selectedRows) {
                const foundRow = rows.find(x => x.entity._id == row._id);
                if (foundRow) {
                    foundRow.setSelected(true);
                    updatedRows.push(foundRow);
                }
            }
            this.selectedRowBatchCallback(updatedRows);
        }
    }

    private async readCheck(model: NotificationMailBox): Promise<void> {
        try {
            let NotificationMailBoxsToUpdate: NotificationMailBox[] = [model];
            if (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) NotificationMailBoxsToUpdate = this.$scope.selectedRows;
            if (!NotificationMailBoxsToUpdate || NotificationMailBoxsToUpdate.length == 0 || !NotificationMailBoxsToUpdate[0]) return this.handleError(this.formService.getTranslate("OPERATIONAL.STATUS_INTEGRATION"));

            const confirmed = await this.ModalService.showModalConfirmation({}, {
                bodyText: (NotificationMailBoxsToUpdate.length > 1) ? this.formService.getTranslate("OPERATIONAL.CHECK_READ") : `${this.formService.getTranslate("OPERATIONAL.INTEGRATION_START")} ${NotificationMailBoxsToUpdate[0].DESCRIPTION}?`,
                actionButtonText: "OPERATIONAL.START",
                headerText: (NotificationMailBoxsToUpdate.length > 1) ? this.formService.getTranslate("OPERATIONAL.EMAIL_READ") : `${this.formService.getTranslate("OPERATIONAL.EMAIL_READ")} ${NotificationMailBoxsToUpdate[0].DESCRIPTION}`,
            });

            if (!confirmed) return;

            this.block();

            for (let notificationMailBox of NotificationMailBoxsToUpdate) {
                notificationMailBox.READ = true;
            }

            const externalReq: IMonacoRequest = {
                route: `/notificationMailBox/update`,
                data: { data: NotificationMailBoxsToUpdate },
                timeout: 120000
            }
            const result = await this.ExternalService.post<any>(externalReq);
            if (!result || !result.data) return this.handleError(result);

            await this.clearSelections();
            await this.updateGrid();

            this.$formService.notifySuccess(this.formService.getTranslate("GENERAL.UPDATE_SUCESSFULLY_PERFORMED"));

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async clearSelections(): Promise<void> {
        this.$scope.selectedRows = [];
        this.$gridService.$gridApi.selection.clearSelectedRows();

        if (this.$scope.log) this.$scope.log.show = false;
    }

    private monitoringFilterChanges() {
        this.$gridService.$gridApi.core.on.filterChanged(this.$scope, this.clearSelections.bind(this));
    }

    private selectedRowCallback(row: IGridRow): void {
        try {
            this.block();

            const NotificationMailBox: NotificationMailBox = row.entity;

            const i = this.selectedRows.findIndex(x => x._id === NotificationMailBox._id);

            if (row.isSelected && i === -1)
                this.selectedRows.push(NotificationMailBox);
            else
                this.selectedRows.splice(i, 1);

            // update scope
            this.$scope.selectedRows = this.selectedRows.slice();

            if (this.selectedRows.length > 1 && this.$scope.log) this.$scope.log.show = false;

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private selectedRowBatchCallback(rows: IGridRow[]): void {
        try {
            rows = this.$gridService.$gridApi.selection.getSelectedGridRows();
            this.block();

            this.selectedRows = rows.filter(x => x.isSelected).map(x => x.entity);
            if (this.selectedRows.length > 1 && this.$scope.log) this.$scope.log.show = false;

            this.$scope.selectedRows = this.selectedRows.slice();

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            USER: null,
            USER_CELL: null,
            HEADER: null,
            DESCRIPTION: null,
            LINK: null,
            FILTER: null,
            DATE: null,
            NOTIFICATION_TYPE: null,
            READ: null,
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: 'STATUS',
                LABEL: 'OPERATIONAL.STATUS'
            }
        ]
        return props;
    }
}

import * as angular from 'angular';

const dataManagerState = {
    name: 'app.datamanager',
    url: '/datamanager',
    abstract: true,
    data: {
        i18n: ['general', 'registration', 'route', 'product', 'financial', 'basic_data', 'entity', 'operational'],
        requiresAuth: true,
        permissions: {
            except: 'EXTERNAL',
            redirectTo: 'app.denied'
        }
    }
}

const dataManagerMenu = {
    name: 'app.datamanager.menu',
    url: '/menu',
    views: {
        'content@app': {
            template: require("./view/dataManagerMenu.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.REPORT',
        parent: 'app.datamanager'
    }
}

const dataManagerReportState = {
    name: 'app.datamanager.report',
    url: '/report',
    views: {
        'content@app': {
            template: require("./view/dataManagerReport.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    data: {
        permissions: {
            redirectTo: 'app.denied'
        }
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.REPORT',
        parent: 'app.datamanager.menu'
    }
}

const dataManagerSchedulingState = {
    name: 'app.datamanager.scheduling',
    url: '/scheduling',
    views: {
        'content@app': {
            template: require("./view/dataManagerScheduling.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    data: {
        permissions: {
            redirectTo: 'app.denied'
        }
    },
    ncyBreadcrumb: {
        label: 'Agendamentos',
        parent: 'app.datamanager.menu'
    }
}

routeConfig.$inject = ['$uiRouterProvider'];
function routeConfig($uiRouterProvider) {
    const $stateRegistry = $uiRouterProvider.stateRegistry;

    $stateRegistry.register(dataManagerState);
    $stateRegistry.register(dataManagerMenu);
    $stateRegistry.register(dataManagerReportState);
    $stateRegistry.register(dataManagerSchedulingState);
}

export default function initRoute(moduleName: string): void {
    angular.module(moduleName).config(routeConfig);
}

import * as angular from 'angular';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog, IMonacoRequest } from '@services/GridFormService';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IResponsibleReasonSetting } from '@models/interface/operational/ResponsibleReasonSetting';
import { GridColumnBuilder } from '../../common/GridColumnBuilder';
import { IMonacoColumnDef } from '@services/GridService2';
import { BrowserTitle } from "../../common/BrowserTitle";
import { SelectorModel } from '../../common/model/SelectorModel';
import { IRestService } from '@services/RestService';
import { ISessionService } from '@services/SessionService';
import { SSEService } from '@appServices/SSEService';
import { EOperation } from '@enums/GenericData';
import { OperationalService } from '@services/OperationalService';
import { HelperService } from "@services/HelperService";

interface IResponsibleReasonSettingScope extends IGridFormServiceScope {
    model: IResponsibleReasonSetting;
    log: IViewLog;
    scopeBeforeSave: IResponsibleReasonSetting;
    customLogProperties: ICustomLogProperties[];
    specializationList: SelectorModel[];
    modifiedTypeList: SelectorModel[];
    user: any;
    sessionService: ISessionService;
    editResponsibleReasonSetting: (responsibleReasonSetting: IResponsibleReasonSetting) => Promise<void>;
    viewResponsibleReasonSetting: (responsibleReasonSetting: IResponsibleReasonSetting) => Promise<void>;
    viewLogResponsibleReasonSetting: (responsibleReasonSetting: IResponsibleReasonSetting) => Promise<void>;
    copyResponsibleReasonSetting: (responsibleReasonSetting: IResponsibleReasonSetting) => Promise<void>;
}

export class ResponsibleReasonSettingRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IResponsibleReasonSettingScope;
    private RestService: IRestService;
    private $q: ng.IQService;
    private SSEService: SSEService;
    private gridName: string;
    private operationalService: OperationalService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IResponsibleReasonSettingScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.operationalService = $injector.get('OperationalService');
        this.gridName = 'GRID_RESPONSIBLE_SETTING';
        this.SSEService = new SSEService($injector, $scope);
        this.helperService = $injector.get('HelperService');
    }
    
    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.operationalService.$route;

            this.$scope.customLogProperties = this.getCustomLogProperties();

            this.initForm(this, 'form', 'responsibleReasonSetting', 'GENERAL.MENU.RESPONSIBLE_REASON_SETTINGS', true);
            await this.initGrid(this.gridName, '/responsibleReasonSetting/list', true, true, null, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editResponsibleReasonSetting = async (iResponsibleReasonSetting: IResponsibleReasonSetting): Promise<void> => {
            let blockedObject = {
                ID: iResponsibleReasonSetting.ID,
                NAME: null,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || iResponsibleReasonSetting.ID !== this.$scope.model.ID) this.$scope.view(iResponsibleReasonSetting);
                } else if (this.$scope.operation !== EOperation.EDIT || iResponsibleReasonSetting.ID !== this.$scope.model.ID) {
                    this.$scope.edit(iResponsibleReasonSetting);
                }
            };
        }

        this.$scope.viewResponsibleReasonSetting = async (responsible: IResponsibleReasonSetting): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(responsible);
        }

        this.$scope.viewLogResponsibleReasonSetting = async (responsible: IResponsibleReasonSetting): Promise<void> => {
            this.SSEService.closeEvents();
            this.viewLogResponsibleReasonSetting(responsible);
        }

        this.$scope.copyResponsibleReasonSetting = async (event: IResponsibleReasonSetting): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(event);
        }

    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            MODIFIED_TYPE: null,
            SPECIALIZATION: null,
            ACTIVE: true,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewResponsibleReasonSetting(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editResponsibleReasonSetting(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogResponsibleReasonSetting(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyResponsibleReasonSetting(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };

        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    private buildColumns(columns: string[]): IMonacoColumnDef[] {
        let gridColumns: Array<IMonacoColumnDef> = [];

        const colSpecialization: IMonacoColumnDef = { name: 'SPECIALIZATION.NAME', displayName: 'GENERAL.SPECIALIZATION', width: 350 };
        const colModifiedType: IMonacoColumnDef = { name: 'MODIFIED_TYPE', displayName: 'OPERATIONAL.MODIFIED_TYPE', width: 550, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.MODIFIED_TYPE)}}</div>' };
        const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
        const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 100, cellFilter: "YesOrNo" };
        const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
        const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

        columns.forEach(column => {
            switch (column) {
                case 'SPECIALIZATION':
                    gridColumns.push(colSpecialization);
                    break;
                case 'MODIFIED_TYPE':
                    gridColumns.push(colModifiedType);
                    break;
                case 'ACTIVE':
                    gridColumns.push(colActive);
                    break;
                case 'ID':
                    gridColumns.push(colId);
                    break;
                case 'CREATED_AT':
                    gridColumns.push(colCreatedAt);
                    break;
                case 'UPDATED_AT':
                    gridColumns.push(colUpdatedAt);
                    break;
            }
        });

        return gridColumns;
    }

    initDependencies(): Promise<any> {
        const self: ResponsibleReasonSettingRegisterController = this;
        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericList('specialization'),
                self.getGenericList('modified_type'),
                
            ]).then((result: any) => {
                self.$scope.specializationList = result[0];
                self.$scope.modifiedTypeList = result[1];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getGenericList(type): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} (${this.$scope.model.SPECIALIZATION.NAME})`;
            BrowserTitle.$id = this.$scope.model.SPECIALIZATION.NAME;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} (${this.$scope.model.SPECIALIZATION.NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.SPECIALIZATION.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        const operation = this.$scope.operation === 'register' ? 'insert' : 'update';
        return {
            route: `/responsibleReasonSetting/${operation}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    private async viewLogResponsibleReasonSetting(event: IResponsibleReasonSetting): Promise<void> {
        try {
            this.formService.block();
            let route = `/responsibleReasonSetting/log/${event._id.toString()}`;
            const request = await this.operationalService.get(route, null);

            const log: IViewLog = {
                operation: 'history',
                number: event._id.toString(),
                list: [],
                show: true,
                showCloseButton: false,
                searchQuery: '',
                originalList: [],
            }

            log.list = request.data.data;
            log.originalList = angular.copy(log.list);
            this.$scope.log = log;
            this.$scope.customLogProperties = this.getCustomLogProperties();

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'SPECIALIZATION',
                LABEL: 'GENERAL.SPECIALIZATION'
            },
            {
                PROPERTY: 'MODIFIED_TYPE',
                LABEL: 'OPERATIONAL.MODIFIED_TYPE'
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            }
        ]
        return props;
    }
}

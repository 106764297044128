import * as angular from "angular";
import * as moment from 'moment';
import { IColumnDef } from "ui-grid";
import { IMonacoController, IMonacoScope, IMonacoConfig } from "../common/MonacoInterface";
import { IGridServiceScope, GridService2, IGridController, ICustomButton } from '@services/GridService2';
import { IFormServiceScope, FormService2 } from '@services/FormService2';
import { IRestService } from "@services/RestService";
import { SelectorModel } from "../common/model/SelectorModel";
import { IGridDataReturn } from "../common/interface/IGridDataReturn";
import { IViewLog } from "@models/interface/common/IViewLog";
import { BrowserTitle } from "../common/BrowserTitle";
import { PermissionService, PermissionActionInsert, PermissionActionView, PermissionActionEdit, PermissionActionSave, PermissionActionDelete, PermissionActionCopy } from "../app/services/PermissionService";

export interface IMonacoRequest<T = any> {
    route?: string;
    operation?: string;
    data?: T;
    timeout?: number;
    user?: any;
}

export interface IMonacoRequestLog<T = any> extends IMonacoRequest<T> {
    oldData?: any;
}

export interface IGridFormServiceScope extends IGridServiceScope, IFormServiceScope, IGridFormController {
    template: string;
    formatDate: (date: Date) => string;
    viewLog?: (entity: any, id?: number | string) => any;
    log?: IViewLog;
};

export interface IGridFormController extends IMonacoController, IGridController {
    //export interface IGridFormController {
    initScopeFunctions: () => void;
    initModel: () => void;
    initGridColumns: (columns: Array<string>) => Array<IColumnDef>;
    getEmptySelectorMsg: () => string;
    getCONCAT: (value: any[], customProp?: string, objToReturn?: string, returnUnique?: boolean, considerMultiline?: boolean, customSeparator?: string, limitCharacter?: boolean) => string;

    initDependencies?: () => Promise<boolean>;
    register?: (data?: any) => Promise<void>;
    view?: (model: any) => Promise<void>;
    edit?: (model: any, loadDependencies?: boolean, updateModelInScope?: boolean) => Promise<void>;
    delete?: (model: any) => Promise<boolean>;
    request?: () => Promise<IMonacoRequest>;
    save?: () => Promise<boolean>;
    saveSuccess?: (returnedData?: any) => Promise<void>;
    cancel?: () => Promise<void>;
    copy?: (model: any) => Promise<void>;
};

export abstract class GridFormService<T = any> {
    protected $injector: ng.Injectable<any>;
    protected $rootScope: IMonacoScope;
    protected httpRequest: IRestService;
    protected formService: FormService2;
    protected gridService: GridService2<T>;
    protected config: IMonacoConfig;
    protected permissionService: PermissionService;
    protected $translate;
    private controllerScope: IGridFormServiceScope;
    private controllerContext: any;
    private modelName: string;
    private registerMsg: string;
    private updateMsg: string;
    private useBreadCrumb: boolean;
    public externalSearch: boolean;
    private baseUrl: string;
    private name: string;

    constructor($injector: ng.Injectable<any>, $scope: IGridFormServiceScope) {
        this.$injector = $injector;
        this.$rootScope = $injector.get('$rootScope');
        this.httpRequest = $injector.get('RestService');
        this.$translate = $injector.get('$translate');
        this.config = $injector.get('config');
        this.controllerScope = $scope;

        this.formService = new FormService2($injector, $scope);
        this.gridService = new GridService2<T>($injector, $scope);
        this.permissionService = new PermissionService($scope, $injector);

        this.baseUrl = this.config.restfulBaseUrl;
        this.gridService.$baseUrl = this.baseUrl;

        this.registerMsg = '<b>Cadastro registrado</b> com sucesso';
        this.updateMsg = '<b>Cadastro atualizado</b> com sucesso';

        //set template
        $scope.template = 'gridFormTemplate';
    }

    $onDestroy() {
        BrowserTitle.$id = null;
        if (this.useBreadCrumb) this.$rootScope.clearBreadCrumb();
        if (this.gridService) this.gridService.destruct();
    }

    protected get $baseUrl(): string {
        return this.baseUrl;
    }

    protected set $baseUrl(value: string) {
        this.baseUrl = value;
        this.gridService.$baseUrl = value;
    }

    protected get $formService() {
        return this.formService;
    }

    protected get $gridService() {
        return this.gridService;
    }

    protected initForm(controllerContext: any, formName: string, modelName: string, menuName: string, initBreadCrumb: boolean): void {

        this.controllerContext = controllerContext;
        this.gridService.$controllerContext = this.controllerContext;
        this.name = modelName;
        this.modelName = modelName;
        this.useBreadCrumb = initBreadCrumb;

        this.registerScope();
        this.formService.init(formName, menuName, initBreadCrumb);
        this.setCopyable(true);
        this.setDeletable(false);
    }


    protected setNotifyMessages(register, update): void {
        this.registerMsg = register;
        this.updateMsg = update;
    }

    /* UNUSED - UNFINISHED (plans for a better initGrid())
    protected async configGrid(gridConfig: IGridConfig<T>): Promise<any> {
        try {
            this.$gridService.$controllerContext = this.controllerContext; // TODO LUNELLI: This should be in constructor
            await this.gridService.configGrid(gridConfig);
        } catch (ex) {
            throw ex;
        }
    } */

    protected async initGrid(gridName: string, gridRoute: string, enableProfile: boolean, externalFilter: boolean = false, timeout?: number, externalPagination: boolean = false, localSort?: boolean, excelRoute?: string, customButton?: ICustomButton): Promise<any> {
        try {
            this.formService.block();

            //set timeout for request grid data if available, default is 15000
            if (timeout) this.gridService.setRequestGridDataTimeout(timeout);

            const externalSearch = (externalFilter || externalPagination);
            const result: IGridDataReturn = await this.gridService.requestGridData(gridRoute, externalSearch);
            if (!result || !result.columns) return this.handleLoadError(`${this.controllerContext.constructor.name}: Failed to get grid data from server`);
            if (!this.controllerContext.initGridColumns) return this.handleLoadError(`${this.controllerContext.constructor.name}: initGridColumns is not registered in controller`);

            const gridData = result.data;
            const gridColumns = this.controllerContext.initGridColumns(result.columns);

            this.gridService.$gridOptions.enablePaginationNavigation = !externalPagination;
            this.gridService.$gridOptions.enablePaginationTotal = !externalPagination;
            await this.gridService.init(gridName, gridRoute, gridColumns, gridData, enableProfile, externalFilter, externalPagination, localSort, excelRoute, customButton);

            this.formService.unblock();
            this.externalSearch = (externalFilter || externalPagination);

            return result.data;

        } catch (ex) {
            throw ex;
        }
    }

    protected enableGridBackgroundUpdate(interval?: number): void {
        try {
            this.$gridService.setBackgroundUpdate(interval);

        } catch (ex) {
            this.formService.handleLoadError(ex);
        }
    }

    private registerScope(): void {
        const self: GridFormService = this;

        this.controllerContext.initModel();

        this.controllerContext.initScopeFunctions();

        //basic crud operation functions
        this.controllerScope.view = (model) => {
            return self.handleView(model);
        }
        this.controllerScope.edit = (model, loadDependencies, updateModelInScope) => {
            return self.handleEdit(model, loadDependencies, updateModelInScope);
        }
        this.controllerScope.register = (data) => {
            return self.handleRegister(data);
        }
        this.controllerScope.delete = (model): Promise<any> => {
            return self.handleDelete(model);
        }
        this.controllerScope.request = () => {
            return self.controllerContext.request();
        }
        this.controllerScope.save = (): Promise<any> => {
            return self.handleSave();
        }
        this.controllerScope.cancel = () => {
            return self.handleCancel();
        }
        this.controllerScope.copy = (model) => {
            return self.handleCopy(model);
        }
        this.controllerScope.viewLog = (model, id) => {
            return self.handleViewLog(model, id);
        }
        this.controllerScope.getEmptySelectorMsg = (): string => {
            return self.getEmptySelectorMsg();
        }
        this.controllerScope.getCONCAT = (value: any[], customProp?: string, objToReturn?: string, returnUnique: boolean = false, considerMultiline: boolean = false, customSeparator?: string, limitCharacter?: boolean): string => {
            return self.getCONCAT(value, customProp, objToReturn, returnUnique, considerMultiline, customSeparator, limitCharacter);
        }
        //grid-form functions
        this.controllerScope.formatDate = (date) => {
            return self.formatDate(date);
        }

        this.controllerContext.decodeSelectorToCheckBox = (selector: SelectorModel): boolean => {
            if (selector && selector.ID == '1') return true;

            return false;
        }

    }

    private formatDate(date: Date): string {
        if (date) return moment(date).format('DD/MM/YYYY');
        else return '';
    }

    private async handleInitDependencies(): Promise<void> {
        try {
            if (this.controllerContext.initDependencies) {
                try {
                    const result = await this.controllerContext.initDependencies();
                    if (!result) return this.formService.handleLoadError(`${this.controllerContext.constructor.name}: Failed to load controller dependencies`);

                } catch (initDependenciesEx) {
                    return this.formService.handleLoadError(`${this.controllerContext.constructor.name}: Failed to load controller dependencies: ${(initDependenciesEx.message) ? initDependenciesEx.message : initDependenciesEx}`);
                }
            }

        } catch (ex) {
            this.formService.handleLoadError(ex);
        }
    }

    //pos-load-register hook
    private async handleRegister(data?: any): Promise<void> {
        try {
            if (!await this.checkPermission(PermissionActionInsert)) return;

            this.formService.block();

            //this.controllerScope.operation = 'register';
            this.controllerContext.initModel();

            // hide log if exists
            this.hideViewLog();

            //call the controller init dependencies logic
            await this.handleInitDependencies();

            //call the controller register logic
            if (this.controllerContext.register) {
                await this.controllerContext.register(data);
                await this.controllerScope.$applyAsync();
            }

            //load form 
            //TODO: test it, changed position to ensure hook synchronism and avoid breaking form disabled elements
            this.formService.loadRegisterForm(true);

            BrowserTitle.$id = null;

            if (this.gridService && this.gridService.$gridApi && this.gridService.$gridApi.selection) this.gridService.$gridApi.selection.clearSelectedRows();

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleLoadError(ex);
        }
    }

    //pos-load-view hook
    private async handleView(model: any): Promise<void> {
        try {
            if (!await this.checkPermission(PermissionActionView)) return;

            this.formService.block();
            //this.controllerScope.operation = 'view';
            this.controllerContext.initModel();
            this.controllerScope['model'] = angular.copy(model);

            // hide log if exists
            this.hideViewLog();

            //call the controller view logic
            if (this.controllerContext.view) {
                await this.controllerContext.view(model);
                await this.controllerScope.$applyAsync();
            }

            //load form
            //TODO: test it, changed position to ensure hook synchronism and avoid breaking form disabled elements
            this.formService.loadViewForm();

            this.gridService.setFixedRow(model._id);

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleLoadError(ex);
        }
    }

    //post-load edit hook
    private async handleEdit(model: any, loadDependencies: boolean = true, updateModelInScope: boolean = true): Promise<void> {
        try {
            if (!await this.checkPermission(PermissionActionEdit)) return;

            this.formService.block();
            //set operation
            //this.controllerScope.operation = 'edit';
            //init model
            this.controllerContext.initModel();
            //set model to scope
            if (typeof model !== 'undefined' && updateModelInScope) this.controllerScope['model'] = angular.copy(model);

            //init depenencies
            if (loadDependencies)
                await this.handleInitDependencies();

            // hide log if exists
            this.hideViewLog();

            //call the controller edit logic
            if (this.controllerContext.edit) {
                await this.controllerContext.edit(model);
                await this.controllerScope.$applyAsync();
            }

            //load form
            //TODO: test it, changed position to ensure hook synchronism and avoid breaking form disabled elements
            this.formService.loadEditForm();

            //call the controller postEdit logic
            if (this.controllerContext.postEdit) {
                await this.controllerContext.postEdit(model);
                await this.controllerScope.$applyAsync();
            }

            this.gridService.setFixedRow(model._id);

            this.formService.unblock();

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    //pre-save hook
    private async handleSave(): Promise<void> {
        try {
            //if (!await this.checkPermission(PermissionActionSave)) return;

            if (!this.formService.checkPendingFields()) return;

            this.formService.block();

            //call the controller save logic
            if (this.controllerContext.save) {
                const saveCallback = await this.controllerContext.save();

                if (!saveCallback) {
                    return this.formService.unblock();
                }
            }

            //set request
            let request: IMonacoRequest = {
                route: `/${this.modelName}/insert`,
                operation: this.controllerScope['operation'],
                data: angular.copy(this.controllerScope['model']),
                timeout: 15000
            };

            if (this.controllerContext.request) {
                const requestController = await this.controllerContext.request();

                if (!requestController) return this.formService.handleError(`Request object is ${typeof requestController}`);

                request = Object.assign(request, requestController);
            }

            //try/catch to intercept 400 bad requests status
            try {
                //exec the http request
                //const result = await this.httpRequest.newObject(request.route, request, request.timeout);
                const $http: ng.IHttpService = this.$injector.get('$http');
                const result = await $http.post(`${this.baseUrl}${request.route}`, request, { timeout: request.timeout, cache: false });
                if (!result || !result.data) return this.formService.handleError('Failed to save');
                this.controllerScope['newRegister'] = result.data['data'];

                if (!this.controllerScope['newRegister']) return this.formService.handleError('Returned data from server is null', () => {
                    return this.handleSave();
                });

                if (this.controllerContext.saveSuccess) await this.controllerContext.saveSuccess(result.data);

            } catch (ex) {
                this.formService.handleError(ex);
            }

            //only execute if sucess/new register is received
            if (this.controllerScope['newRegister']) {
                //update grid rows
                await this.updateGrid();

                /*  update newRegister with grid refreshed data to keep transaction control (MODIFIED_DATE). 
                    this is necessary because not all insert/update functions return the updated object. 
                    if '_id' not present, then data located in subdocument array. */
                if (this.controllerScope['newRegister']['_id'])
                    this.controllerScope['newRegister'] = this.gridService.findDataById(this.controllerScope['newRegister']['_id']);

                this.controllerScope['showForm'] = false;
                this.controllerScope['model'] = null;
                this.controllerScope['error'] = null;
                this.controllerScope['operation'] = null;

                await this.handleNotifyClick(request);
            }

            // Unblock the user interface
            if (this.useBreadCrumb) this.$rootScope.showBreadCrumb();

            BrowserTitle.$id = null;

            if (this.gridService && this.gridService.$gridApi && this.gridService.$gridApi.selection) this.gridService.$gridApi.selection.clearSelectedRows();

            this.unblock();

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async handleViewLog(model: any, id: number | string): Promise<void> {
        try {
            // call the controller viewLog logic if exists
            if (this.controllerContext.viewLog) {
                await this.controllerContext.viewLog(model, id);
            } else {
                this.formService.block();

                this.controllerScope['showForm'] = false;
                this.controllerScope['operation'] = 'viewLog';
                this.controllerScope['newRegister'] = null;

                if (!model && model.ID) {
                    return this.formService.handleError('ID for request log is null!');
                }

                const idLog = id ? id : model.ID.toString();
                let log: IViewLog = {
                    operation: 'history',
                    number: idLog,
                    list: [],
                    show: true,
                    searchQuery: '',
                    originalList: [],
                }

                //set request
                let request: IMonacoRequest = {
                    route: `/${this.modelName}/viewLog/${idLog}`,
                    timeout: 15000
                };

                const result = await this.httpRequest.getObjectAsPromise(`${this.baseUrl}${request.route}`, request.timeout, null, false);
                if (!result || !result.data) return this.formService.handleError('Failed to get log!');

                log.list = result.data;
                log.originalList = angular.copy(log.list);
                this.controllerScope.log = log;
                angular.element('#log-viewer').removeClass('ng-hide');
                const position = angular.element('#log-viewer').offset().top + $('.app-content-body').scrollTop() - 105;
                $('.app-content-body').animate({
                    scrollTop: position
                }, 500);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.controllerScope.$applyAsync();
        }
    }

    private async checkNotifyClick(): Promise<void> {
        const response = this.controllerScope['newRegister'];
        return this.controllerContext.notifyClick ? await this.controllerContext.notifyClick(response) : await this.handleView(response);
    }

    private async handleNotifyClick(request): Promise<void> {
        try {
            this.formService.block();

            //notify message
            if (request.operation === 'register') {
                //TODO: FIX THE IGNOTE_CLICK LOGIC
                this.formService.notifySuccess(this.registerMsg);
            }
            else {


                //TODO: FIX THE IGNOTE_CLICK LOGIC
                this.formService.notifySuccess(this.updateMsg);
            }

            this.formService.unblock();

        } catch (ex) {
            this.formService.handleError(ex);
        }

    }

    //pre-delete hook
    private async handleDelete(model: any): Promise<void> {
        try {
            if (!await this.checkPermission(PermissionActionDelete)) return;

            //call the controller save logic
            if (this.controllerContext.delete) {
                const deleteCallback = await this.controllerContext.delete();

                console.log('deleteCallback', deleteCallback);

                if (!deleteCallback) return this.formService.unblock();
            }

            this.formService.block();

            //set operation
            this.controllerScope.operation = 'delete';

            let id = null;
            if (model.hasOwnProperty('_id')) {
                id = model['_id'];
            } else if (model.hasOwnProperty('ID')) {
                id = model['ID'];
            }
            //set request
            let request: IMonacoRequest = {
                route: `/${this.modelName}/delete/${id}`,
                timeout: 15000
            };

            if (this.controllerContext.request) {
                const requestController = await this.controllerContext.request();
                if (!requestController) return this.formService.handleError(`Request object is ${typeof requestController}`);
                request = Object.assign(request, requestController);
            }
            //exec the http request
            const result = await this.httpRequest.deleteObject(`${this.baseUrl}${request.route}`, request.timeout, false);
            if (!result || !result.data) return this.formService.handleError('Failed to delete');
            //update grid rows
            this.updateGrid();
            await this.controllerScope.$applyAsync();
            this.controllerScope['showForm'] = false;
            this.controllerScope['model'] = null;
            this.controllerScope['error'] = null;
            //notify message
            this.formService.notifySuccess('<b>Registro deletado com sucesso.</b>');
            // Unblock the user interface
            if (this.useBreadCrumb) this.$rootScope.showBreadCrumb();

            BrowserTitle.$id = null;

            if (this.gridService && this.gridService.$gridApi && this.gridService.$gridApi.selection) this.gridService.$gridApi.selection.clearSelectedRows();

            this.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    //pre cancel event
    private async handleCancel(): Promise<void> {
        try {
            this.formService.block();

            //call the controller cancel logic
            if (this.controllerContext.cancel) {
                await this.controllerContext.cancel();
                await this.controllerScope.$applyAsync();
            }
            this.formService.cancel();

            BrowserTitle.$id = null;

            if (this.gridService && this.gridService.$gridApi && this.gridService.$gridApi.selection) {
                this.gridService.$gridApi.selection.clearSelectedRows();
                this.gridService.clearFixedRow();
            }

            this.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    //pos-copy hook
    private async handleCopy(model: any): Promise<void> {
        try {
            if (!await this.checkPermission(PermissionActionCopy)) return;

            //call handleRegister
            await this.handleRegister(model);

            this.formService.block();

            //set copied model to current model
            this.controllerScope['model'] = angular.copy(model);
            //delete _id, ID and _NUMBER
            if (this.controllerScope['model']['$$hashKey']) delete this.controllerScope['model']['$$hashKey'];
            if (this.controllerScope['model']['_id']) this.controllerScope['model']['_id'] = null;
            if (this.controllerScope['model']['ID']) this.controllerScope['model']['ID'] = null;
            if (this.controllerScope['model'][`${this.modelName.toUpperCase()}_NUMBER`]) this.controllerScope['model'][`${this.modelName.toUpperCase()}_NUMBER`] = null;

            //call the controller copy logic
            if (this.controllerContext.copy) {
                await this.controllerContext.copy();
                await this.controllerScope.$applyAsync();
            }

            BrowserTitle.$id = null;

            if (this.gridService && this.gridService.$gridApi && this.gridService.$gridApi.selection) this.gridService.$gridApi.selection.clearSelectedRows();

            this.unblock();
        } catch (ex) {
            this.formService.handleLoadError(ex);
        }
    }

    protected async clearFields(model: object, otherFields?: string[]) {
        if (!model) return;

        const fixedFields = ['_id'];

        const removes = otherFields ? fixedFields.concat(otherFields) : fixedFields;

        for (const fieldValue in model) {
            if (angular.isArray(model[fieldValue])) {
                for (const item of model[fieldValue]) {
                    await this.clearFields(item, otherFields);
                }
            } else if (typeof model[fieldValue] == 'object' && model[fieldValue] != null) {
                await this.clearFields(model[fieldValue], otherFields);
            }
            else if (removes.indexOf(fieldValue) != -1 && model[fieldValue]) {
                model[fieldValue] = null;
            }
        }
    }

    protected async updateGrid(): Promise<void> {
        try {
            const gridRoute = this.gridService.$gridRoute;
            const result: IGridDataReturn = await this.gridService.requestGridData(gridRoute, this.externalSearch);
            if (!result) return this.handleLoadError(`${this.controllerContext.constructor.name}: Failed to get grid data from server`);
            await this.gridService.setGridData(result.data);
        } catch (ex) {
            this.formService.handleLoadError(ex);
        }
    }


    protected decodeBoolean(value: boolean): string {
        if (value) {
            return 'Sim';
        } else {
            return 'Não';
        }
    }

    protected formateDateToExport(dateString: string): string {
        if (!dateString) return '';
        const date = dateString.split('-');
        const day = date[2].substring(0, 2);
        const month = date[1];
        const year = date[0];
        return day + '/' + month + '/' + year;
    }

    protected checkDateFieldConsistency(elementName: string, elementLimitName: string) {
        this.formService.checkDateFieldConsistency(elementName, elementLimitName);
    }

    protected dateColumnCellFilter(columnName: string): string {
        return this.gridService.dateColumnCellFilter(columnName);
    }

    protected block(): void {
        this.formService.block();
    };

    protected resetBlock(): void {
        this.formService.resetBlock();
    };

    protected unblock(): void {
        this.formService.unblock();
    };

    protected handleError(error: any, errorCallback?: Function, options?: object): void {
        this.formService.handleError(error, errorCallback, options);
    }

    protected handleWarning(message: string, warningCallback?: Function, options?: any): void {
        this.formService.handleWarning(message, warningCallback, options);
    }

    protected handleLoadError(error: any): void {
        this.formService.handleLoadError(error);
    }

    protected loadViewForm(): void {
        this.formService.loadViewForm();
    }

    protected loadEditForm(): void {
        this.formService.loadEditForm();
    }

    protected loadRegisterForm(validateAll: boolean): void {
        this.formService.loadRegisterForm(validateAll);
    }

    protected setCopyable(visible: boolean) {
        this.formService.setCopyable(visible);
    }

    protected setDeletable(visible: boolean) {
        this.formService.setDeletable(visible);
    }

    public getEmptySelectorMsg(): string {
        return this.formService.getEmptySelectorMsg();
    }

    public getCONCAT(value: any[], customProp?: string, objToReturn?: string, returnUnique: boolean = false, considerMultiline: boolean = false, customSeparator?: string, limitCharacter?: boolean): string {
        return this.formService.getCONCAT(value, customProp, objToReturn, returnUnique, considerMultiline, customSeparator, limitCharacter);
    }

    private hideViewLog(): void {
        if (this.controllerScope.log) {
            this.controllerScope.log.show = false;
        }
    }

    private async checkPermission(action: SelectorModel): Promise<boolean> {
        if (await this.permissionService.isFormActionAllowed(this.name, action) === false) {
            this.permissionService.showBlockMessage();
            return false;
        }
        return true;
    }
}

import * as angular from 'angular';
import * as Address from '../../communication/Address';
import { IFormServiceScope, FormService2 } from "@services/FormService2";
import { IGridOptions } from 'ui-grid';
import { GridFormService, IGridFormServiceScope, IGridFormController, IMonacoRequestLog, IMonacoRequest } from "@services/GridFormService";
import { IMonacoColumnDef } from '@services/GridService2';
import { ISessionService } from '@services/SessionService';
import { DataProcessService } from '@services/DataProcessService';
import { IProcessDocumentModel as IProcessDocument, INCONSISTENCY_CHARGE_DOCUMENT } from "@models/interface/operational/ProcessDocument";
import { ICustomLogProperties, IViewLog } from "@models/interface/common/IViewLog";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { IProcessParameter, IDraftParameter } from '../../common/model/ModelParameter';
import { SSEService } from '@appServices/SSEService';
import { IModalService, IFileReferenceDisabledFields, IFileReferencePreSetFields } from '@services/ModalService';
import { HelperService } from "@services/HelperService";
import { ProductService } from '@services/ProductService';
import { OperationalService } from '@services/OperationalService';
import { SelectorModel } from '../../common/model/SelectorModel';
import { EProductId, EOperation, EReportCategoryName, EProcessDocumentTypeId, EProcessDocumentType } from "@enums/GenericData";
import { EProcessDocumentSituationId, } from '@models/interface/operational/GenericData';
import { IFileUpload } from '@models/interface/common/IFileUpload';
import { Process } from "@models/interface/operational/NewProcess";
import { IRestService } from "@services/RestService";
import { IDownloadParamsReturn } from '@services/DownloadFilesGenericService';
import { REFERENCE } from '@models/interface/operational/NewProcess';
import { IFiles } from '../../app/controllers/FileReferenceModalController';
import { Process as IProcess } from 'WBA-Model/dist/interface/operational/NewProcess';
import { DraftViewModel, DRAFT as IDraft } from '@models/interface/operational/DraftViewModel';

interface IProcessDocumentScope extends IGridFormServiceScope {
    gridOptions: IGridOptions;
    $timeout: ng.ITimeoutService;
    model: IProcessDocument;
    scopeBeforeSave: IProcessDocument;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    processDocumentSituationList: SelectorModel[];
    documentReleaseList: SelectorModel[];
    inconsistencyList: SelectorModel[];
    inconsistencyPrefList: SelectorModel[];
    inconsistencyReleaseList: SelectorModel[];
    masterList: string[];
    processes: SelectorModel[];
    fileReferenceModalID: number;
    referenceModalID: number;
    lastScroll: number;
    process: Process;
    oldProcess: Process;
    processDocumentStatusList: SelectorModel[];
    releaseTypeList: SelectorModel[];

    editProcessDocument: (processDocument: IProcessDocument) => Promise<void>;
    viewProcessDocument: (processDocument: IProcessDocument) => Promise<void>;
    addProcessDocument: (processDocument: IProcessDocument) => Promise<void>;
    goToDraft: (draftID: string) => void;
    goToProcess: (process: string) => void;
    goToDraftByProcessNumber: (processNumber: string) => void;
    fetchData: (id: number, action: string) => Promise<void>;
    openViewEditModal: (model: IProcessDocument) => void;
    hasInvalidRequiredElements: (elementId: string) => boolean;
    modalSaveConfirmation: (headerText: string, closeButtonText: string) => Promise<boolean>;
    hasChanges: (newObj: Object, oldObj: Object, propertiesToIgnore?: string[]) => boolean;
    getProcessSelector: (number: string) => void;
    changeProtocolModel: (processDocument: IProcessDocument) => void;
    getConcatUniqueTerm: (uniqueTerm: Array<IFileUpload>) => string;
    getUniqueTermTooltip: (uniqueTerm: Array<IFileUpload>) => Promise<string>;
    showReferencesModal: (processDocument: IProcessDocument) => Promise<void>;
    activeInactiveProcessDocument: (processDocument: IProcessDocument) => Promise<void>;
}

interface INewProcessDocumentModal extends IFormServiceScope {
    model: IProcessDocument;
    oldModel: IProcessDocument;
    processes: SelectorModel[];
    process: IProcess;
    type: SelectorModel;
    legalPersonAccountRequirement: INCONSISTENCY_CHARGE_DOCUMENT;

    processDocumentTypeList: () => SelectorModel[];
    getProcessSelector: (processNumber: string) => void;
    getProcessData: (number: string) => Promise<void>;
    applyProcessDocument: () => Promise<void>;
    closeProcessDocumentModal: () => Promise<void>;
}

interface IProcessDocumentModal extends IFormServiceScope {
    model: IProcessDocument;
    oldModel: IProcessDocument;
    type: SelectorModel;
    processDocumentSituationList: SelectorModel[];
    documentReleaseList: SelectorModel[];
    inconsistencyList: SelectorModel[];
    inconsistencyPrefList: SelectorModel[];
    inconsistencyReleaseList: SelectorModel[];
    processDocumentStatusList: SelectorModel[];
    releaseTypeList: SelectorModel[];
    masterList: SelectorModel[];
    cityList: string[];
    getCityListByNameOrInitials: (search?: string) => void;
    getMasterList: () => void;
    processDocumentTypeList: () => SelectorModel[];
    setDefaultSituation: () => void;
    applyProcessDocument: () => Promise<void>;
    closeProcessDocumentModal: () => Promise<void>;
    checkCorrectionSuspended: () => void;
    suspended: boolean;
    correction: boolean;
    awbNumberExchange: {
        modelID: number;
        awbSelectedValue: any;
        awbNumberList: Array<SelectorModel>;
    }

    applyAwbNumberExchange: () => void;
    goToAWBStock: () => void;
}

interface IProtocolExchangeScope extends IFormServiceScope {
    protocolExchange: {
        modelID: number;
        protocolSelectedValue: any;
        protocolList: Array<IDraft>;
    }

    applyProtocolExchange: () => void;
}

interface IReferenceModalScope extends IFormServiceScope {
    references: REFERENCE[];
    processNumber: string;
    oldReferences: REFERENCE[];
    operation: string;
    closeReferenceModal: () => Promise<void>;
    applyReferenceModal: (close?: boolean) => void;
    viewFile: (file: REFERENCE) => void;
    downloadFile: (file: REFERENCE) => Promise<void>;
    copyFileLinkToClipboard: (file: REFERENCE) => void;
    viewProcessReferenceModal: (file: REFERENCE) => Promise<void>;
    editProcessReferenceModal: (index: number) => Promise<void>;
    fileReferenceModal: () => void;
    removeReference: (index: number) => void;
    mainFileChange: (index: number) => void;
}

export class NewProcessDocumentRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private scope: IProcessDocumentScope;
    private $q: ng.IQService;
    private SSEService: SSEService;
    private gridName: string;
    private DataProcessService: DataProcessService;
    private SessionService: ISessionService;
    private ModalService: IModalService;
    private HelperService: HelperService;
    private ProductService: ProductService;
    private OperationalService: OperationalService;
    private RestService: IRestService;

    private downloadRoute: string;
    private fileReferenceModalID: number;

    private modalID: number;

    constructor($injector: ng.Injectable<any>, $scope: IProcessDocumentScope) {
        super($injector, $scope);

        this.scope = $scope;
        this.$q = $injector.get('$q');
        this.scope.$timeout = $injector.get('$timeout');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.SessionService = $injector.get('SessionService');
        this.DataProcessService = $injector.get('DataProcessService');
        this.ModalService = $injector.get('ModalService');
        this.HelperService = $injector.get('HelperService');
        this.ProductService = $injector.get('ProductService');
        this.OperationalService = $injector.get('OperationalService');
        this.RestService = $injector.get('RestService');

        if (this.config.environment === 'prod') {
            this.downloadRoute = `${Address.monacoAddressProd.FIS}/api/v1/fis/filesGeneric/download`;
        } else if (this.config.environment === 'qa') {
            this.downloadRoute = `${Address.monacoAddressQa.FIS}/api/v1/fis/filesGeneric/download`;
        } else { //dev
            this.downloadRoute = `${Address.monacoAddressLocal.FIS}/api/v1/fis/filesGeneric/download`;
        }
        this.gridName = 'processDocument';
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.DataProcessService.$route;
            this.scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'processDocument', 'GENERAL.MENU.PROCESS_KNOWLEDGE', true);
            this.initScopeFunctions();
            await this.initDependencies();
            this.initModel();
            await this.initGrid(this.gridName, '/processDocument/list', true, true, 120000, true, true);
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initDependencies(): Promise<any> {
        const self: NewProcessDocumentRegisterController = this;

        const promises = {
            process_document_situation: self.getGenericValue("process_document_situation"),
            document_release: self.getGenericValue("document_release"),
            inconsistency_process_doc: self.getGenericValue("inconsistency_process_doc"),
            type_payment: self.getGenericValue("type_payment"),
            inconsistency_charge_release: self.getGenericValue("inconsistency_charge_release"),
            process_document_status: self.getGenericValue("process_document_status"),
            release_type: self.getGenericValue("release_type"),

        };

        return new Promise((resolve, reject) => {
            self.$q.all(promises).then((result) => {
                self.scope.processDocumentSituationList = result.process_document_situation;
                self.scope.documentReleaseList = result.document_release;
                self.scope.inconsistencyList = result.inconsistency_process_doc;
                self.scope.inconsistencyPrefList = result.type_payment;
                self.scope.inconsistencyReleaseList = result.inconsistency_charge_release;
                self.scope.processDocumentStatusList = result.process_document_status;
                self.scope.releaseTypeList = result.release_type;

                resolve(true);
            }).catch((ex) => {
                reject(ex);
            });
        });
    }

    initScopeFunctions(): void {
        this.scope.viewLog = (entity) => this.viewLog(entity);
        this.scope.editProcessDocument = (model: IProcessDocument) => this.editProcessDocument(model);
        this.scope.viewProcessDocument = (model: IProcessDocument) => this.viewProcessDocument(model);
        this.scope.addProcessDocument = (model: IProcessDocument) => this.addProcessDocument(model);
        this.scope.goToDraft = (draftID: string) => this.goToDraft(draftID);
        this.scope.goToDraftByProcessNumber = (processNumber: string) => this.goToDraftByProcessNumber(processNumber);
        this.scope.goToProcess = (process: string) => this.goToProcess(process);
        this.scope.openViewEditModal = (model: IProcessDocument) => this.openViewEditModal(model);
        this.scope.hasInvalidRequiredElements = (elementId: string) => {
            return this.hasInvalidRequiredElements(elementId);
        }
        this.scope.modalSaveConfirmation = (headerText: string, closeButtonText: string) => this.modalSaveConfirmation(headerText, closeButtonText)

        this.scope.hasChanges = (newObj: Object, oldObj: Object, propertiesToIgnore?: string[]) => {
            if (propertiesToIgnore) {
                const newAux = newObj ? angular.copy(newObj) : null;
                const oldAux = oldObj ? angular.copy(oldObj) : null;
                for (const property of propertiesToIgnore) {
                    if (newAux && newAux[property]) {
                        delete newAux[property];
                    }
                    if (oldAux && oldAux[property]) {
                        delete oldAux[property];
                    }
                }
                return !angular.equals(JSON.stringify(newAux), JSON.stringify(oldAux));
            }
            return !angular.equals(newObj, oldObj);
        }
        this.scope.changeProtocolModel = (processDocument: IProcessDocument) => this.changeProtocolModel(processDocument);
        this.scope.getConcatUniqueTerm = (uniqueTerm: IFileUpload[]) => this.getConcatUniqueTerm(uniqueTerm);
        this.scope.getUniqueTermTooltip = (uniqueTerm: IFileUpload[]) => this.getUniqueTermTooltip(uniqueTerm);
        this.scope.showReferencesModal = (processDocument: IProcessDocument) => this.showReferencesModal(processDocument);
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);
        const add = `<a tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.ADD_TRANSPORT_BILL' | translate }} {{row.entity.PROCESS_NUMBER}}" tooltip-append-to-body="true" ng-click="grid.appScope.addProcessDocument(row.entity)"><i class="fa fa-plus-circle icon text-primary"></i>&nbsp;&nbsp</a>`;
        const view = `<a ng-click="grid.appScope.viewProcessDocument(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true"><i class="fa fa fa-search icon"></i>&nbsp;&nbsp</a>`;
        const edit = `<a ng-click="grid.appScope.editProcessDocument(row.entity)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true"><i class="fa fa-pencil icon"></i>&nbsp;&nbsp</a>`;
        const changeProtocol = `<a ng-disabled="!row.entity.ACTIVE" ng-class="{'text-muted': !row.entity.ACTIVE}" tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.LINK_PROTOCOL' | translate }}" tooltip-append-to-body="true" ng-click="row.entity.ACTIVE ? grid.appScope.changeProtocolModel(row.entity) : ''" class="fa fa-id-card-o text-brown"></i>&nbsp;&nbsp</a>`;
        const upload = `<a tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.ADD_ATTACHMENT' | translate }}" tooltip-append-to-body="true"  ng-click="grid.appScope.showReferencesModal(row.entity)"><i class="fa fa-paperclip text-brown"></i>&nbsp;&nbsp</a>`;
        const viewLog = `<a ng-click="grid.appScope.viewLog(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true"><i class="fa fa-history icon"></i>&nbsp;&nbsp</a>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes", displayName: "GENERAL.ACTIONS",
            width: 180,
            cellTemplate: `<div class="text-center view-btn-action-bar">${add} ${view} ${edit} ${changeProtocol} ${upload} ${viewLog}</div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };

        gridColumns.addColumn(colActions);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colActiveInactive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE_YES_NO", width: 150, cellFilter: 'YesOrNo', cellTemplate: `<div class="text-center" style="margin-top: 4px;"><i class="fa icon" ng-class="(row.entity.ACTIVE) ? 'fa-check text-green' : 'fa-ban text-danger'"></i></div>` };
            const colInntraCode: IMonacoColumnDef = { name: "PROCESS_DOCUMENT_NUMBER", displayName: "GENERAL.CODE", width: 150 };
            const colProcessNumber: IMonacoColumnDef = { name: "PROCESS_NUMBER", displayName: "OPERATIONAL.FILE_NUMBER", width: 150, cellTemplate: '<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="Click view process {{row.entity.PROCESS_NUMBER}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToProcess(row.entity.PROCESS_NUMBER)">{{row.entity.PROCESS_NUMBER}}</a></div>' };
            const colType: IMonacoColumnDef = { name: "TYPE", displayName: "GENERAL.TYPE", width: 95 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.NAME", displayName: "BASIC_DATA.PRODUCT", width: 150 };
            const colMaster: IMonacoColumnDef = { name: "MASTER.NAME", displayName: "FINANCIAL.MASTER", width: 150 };
            const colKnowledge: IMonacoColumnDef = { name: "DOCUMENT", displayName: "GENERAL.MENU.PROCESS_KNOWLEDGE", width: 150, cellTemplate: `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW_DRAFT' | translate }}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToDraftByProcessNumber(row.entity.PROCESS_NUMBER)">{{row.entity.DOCUMENT}}</a></div>` };
            const colProtocol: IMonacoColumnDef = { name: "DRAFT_NUMBER", displayName: "OPERATIONAL.PROTOCOL", width: 165, cellTemplate: `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW_DRAFT' | translate }}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToDraft(row.entity.DRAFT_NUMBER)">{{row.entity.DRAFT_NUMBER}}</a></div>` };
            const colTotalHouse: IMonacoColumnDef = { name: "DOCUMENT_COUNT_HOUSE", displayName: "OPERATIONAL.TOTAL_HOUSE", width: 130 };
            const colTotalMaster: IMonacoColumnDef = { name: "DOCUMENT_COUNT_MASTER", displayName: "OPERATIONAL.TOTAL_MASTER", width: 130 };
            const colEstimatedLoadingDate: IMonacoColumnDef = { name: "PROCESS.ESTIMATED_LOAD", displayName: "OPERATIONAL.ESTIMATED_LOADING_DATE", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colActualLoadinDate: IMonacoColumnDef = { name: "PROCESS.LOAD", displayName: "OPERATIONAL.ACTUAL_LOADING_DATE", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colEstimatedDischargeDate: IMonacoColumnDef = { name: "PROCESS.ESTIMATED_DISCHARGE", displayName: "OPERATIONAL.ESTIMATED_DISCHARGE_DATE", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colFileOperationalStatus: IMonacoColumnDef = { name: "PROCESS.SITUATION.NAME", displayName: "OPERATIONAL.FILE_OPERATIONAL_STATUS", width: 200 };
            const colActualDischargeDate: IMonacoColumnDef = { name: "PROCESS.DISCHARGE", displayName: "OPERATIONAL.ACTUAL_DISCHARGE_DATE", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colClientQualification: IMonacoColumnDef = { name: "CUSTOMER_QUALIFICATION.NAME", displayName: "GENERAL.CLIENT_QUALIFICATION", width: 200 };
            const colDestinationPort: IMonacoColumnDef = { name: "PROCESS.DESTINATION.NAME", displayName: "BASIC_DATA.DESTINATION_PORT", width: 200, cellTemplate: '<div class="ui-grid-cell-contents ng-binding ng-scope">{{row.entity.PROCESS.DESTINATION.CODE}} - {{row.entity.PROCESS.DESTINATION.NAME}}</a></div>' };
            const colLocalAgent: IMonacoColumnDef = { name: "PROCESS.LOCAL_AGENT.NAME", displayName: "BASIC_DATA.LOCAL_AGENT", width: 200 };
            const colOverseasAgent: IMonacoColumnDef = { name: "PROCESS.EXTERNAL_AGENT.NAME", displayName: "BASIC_DATA.OVERSEAS_AGENT", width: 200 };
            const colRepresentatives: IMonacoColumnDef = { name: "PROCESS.DESTINATION_REPRESENTATIVE.NAME", displayName: "GENERAL.REPRESENTATIVES", width: 200 };
            const colBooking: IMonacoColumnDef = { name: "PROCESS.BOOKING", displayName: "OPERATIONAL.BOOKING", width: 200 };
            const colClient: IMonacoColumnDef = { name: "CUSTOMER.CORPORATE_NAME", displayName: "BASIC_DATA.CLIENT", width: 200 };
            const colClientNetwork: IMonacoColumnDef = { name: "CUSTOMER.NETWORK.NAME", displayName: "GENERAL.CLIENT_NETWORK", width: 200 };
            const colConsignee: IMonacoColumnDef = { name: "PROCESS.IMPORTER.NAME", displayName: "BASIC_DATA.CONSIGNEE", width: 200 };
            const colShipper: IMonacoColumnDef = { name: "PROCESS.EXPORTER.NAME", displayName: "BASIC_DATA.SHIPPER", width: 200 };
            const colProvider: IMonacoColumnDef = { name: "PROCESS.SERVICE_PROVIDER.NAME", displayName: "BASIC_DATA.PROVIDER", width: 200 };
            const colReceiptConditions: IMonacoColumnDef = { name: "PROCESS.INCOME_PAYMENT_CONDITION_ARR", displayName: "FINANCIAL.RECEIPT_CONDITIONS", width: 200, cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.PROCESS.INCOME_PAYMENT_CONDITION_ARR, null, null, true)}}</div>' };
            const colReceiptSituation: IMonacoColumnDef = { name: "PROCESS.RECEIPT_SITUATION.SITUATION.NAME", displayName: "FINANCIAL.RECEIPT_SITUATION", width: 200 };
            const colStraightBl: IMonacoColumnDef = { name: "PROCESS.MASTER_DIRECT", displayName: "BASIC_DATA.STRAIGHT_BL", width: 150, cellFilter: 'YesOrNo' };
            const colDraftReceipt: IMonacoColumnDef = { name: "DRAFT_RECEIVED", displayName: "OPERATIONAL.DRAFT_RECEIPT_DATE", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colClientApproval: IMonacoColumnDef = { name: "CLIENT_APPROVAL", displayName: "OPERATIONAL.CLIENT_APPROVAL", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colAllogApproval: IMonacoColumnDef = { name: "ALLOG_APPROVAL", displayName: "OPERATIONAL.ALLOG_APPROVAL", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colReceiptDateByEmail: IMonacoColumnDef = { name: "ORIGINAL_COPY_RECEIVED", displayName: "OPERATIONAL.ORIGINALS_RECEIPT_DATE_BY_EMAIL", width: 260, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colIssuanceDate: IMonacoColumnDef = { name: "ISSUEANCE", displayName: "OPERATIONAL.ISSUANCE_DATE", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colIssuancePlace: IMonacoColumnDef = { name: "LOCAL_ISSUEANCE", displayName: "OPERATIONAL.ISSUANCE_PLACE", width: 150 };
            const colAvailability: IMonacoColumnDef = { name: "AVAILABILITY", displayName: "OPERATIONAL.AVAILABILITY", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colIssuanceType: IMonacoColumnDef = { name: "EMISSION_TYPE.NAME", displayName: "OPERATIONAL.ISSUANCE_TYPE", width: 150 };
            const colReleaseType: IMonacoColumnDef = { name: "RELEASE_TYPE.NAME", displayName: "OPERATIONAL.RELEASE_TYPE", width: 230 };
            const colSendReleaseDate: IMonacoColumnDef = { name: "SENT_DATE", displayName: "OPERATIONAL.SEND_RELEASE_DATE", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colCourierCia: IMonacoColumnDef = { name: "COURIER_CIA", displayName: "OPERATIONAL.COURIER_CIA", width: 150 };
            const colOriginalsCopies: IMonacoColumnDef = { name: "ORIGINAL_COPY", displayName: "OPERATIONAL.ORIGINALS_COPIES", width: 150 };
            const colOriginalsReceiptRetained: IMonacoColumnDef = { name: "ORIGINALS_RECEIPT_RETAINED", displayName: "OPERATIONAL.ORIGINALS_RECEIPT_RETAINED", width: 250 };
            const colSendReleasePerson: IMonacoColumnDef = { name: "PEOPLE_RELEASE", displayName: "OPERATIONAL.SEND_RELEASE_PERSON", width: 250 };
            const colCourierSendDate: IMonacoColumnDef = { name: "COURIER_SENT", displayName: "OPERATIONAL.COURIER_SEND_DATE", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colCourierReceivedInBrazil: IMonacoColumnDef = { name: "COURIER_RECEIVED_BRAZIL", displayName: "OPERATIONAL.COURIER_RECEIVED_IN_BRAZIL", width: 210, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colCourierReceivedAtDestination: IMonacoColumnDef = { name: "COURIER_RECEIVED_ALLOG", displayName: "OPERATIONAL.COURIER_RECEIVED_AT_DESTINATION", width: 210, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colDocumentReceivedByFinop: IMonacoColumnDef = { name: "COURIER_RECEIVED_FINOP", displayName: "OPERATIONAL.DOCUMENT_RECEIVED_BY_FINOP", width: 210, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colCeMercante: IMonacoColumnDef = { name: "CE_MERCANTE", displayName: "OPERATIONAL.CE_MERCANTE", width: 170 };
            const colDesconsolidationDate: IMonacoColumnDef = { name: "DECONSOLIDATION", displayName: "OPERATIONAL.DESCONSOLIDATION_DATE", width: 170, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colLoiReceiveDate: IMonacoColumnDef = { name: "LOI_RECEIVED", displayName: "OPERATIONAL.LOI_RECEIVE_DATE", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colLoiSendDate: IMonacoColumnDef = { name: "LOI_SENT", displayName: "OPERATIONAL.LOI_SEND_DATE", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUniqueTerm: IMonacoColumnDef = { name: "UNIQUE_TERM", displayName: "OPERATIONAL.UNIQUE_TERM", width: 150, cellFilter: 'YesOrNo' };
            const colUniqueTermFile: IMonacoColumnDef = { name: "UNIQUE_TERM_FILE", displayName: "OPERATIONAL.UNIQUE_TERM_FILE", width: 200, cellTemplate: `<div class="ui-grid-cell-contents ng-binding ng-scope" tooltip-append-to-body="true" uib-tooltip="{{grid.appScope.getUniqueTermTooltip(row.entity.UNIQUE_TERM_FILE)}}" ng-bind-html="grid.appScope.getConcatUniqueTerm(row.entity.UNIQUE_TERM_FILE)"></div>` };
            const colReleaseDate: IMonacoColumnDef = { name: "RELEASE", displayName: "OPERATIONAL.RELEASE_DATE", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colReleasedTo: IMonacoColumnDef = { name: "RELEASED_TO", displayName: "OPERATIONAL.RELEASED_TO", width: 150 };
            const colSiscomesCargoReleased: IMonacoColumnDef = { name: "SISCARGA_MANTRA", displayName: "OPERATIONAL.SISCOMEX_CARGO_RELEASED_AIMED_MANTRA", width: 190, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colRemarks: IMonacoColumnDef = { name: "OBSERVATION", displayName: "GENERAL.REMARKS", width: 150 };
            const colDeclareDestinationThcOnHbl: IMonacoColumnDef = { name: "DESTINATION_THC.NAME", displayName: "OPERATIONAL.DECLARE_DESTINATION_THC_ON_HBL", width: 250 };
            const colCargoType: IMonacoColumnDef = { name: "CARGO_TYPE.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 200 };
            const colBranch: IMonacoColumnDef = { name: "BRANCH.NAME", displayName: "BASIC_DATA.BRANCH", width: 200 };
            const colProcessType: IMonacoColumnDef = { name: "PROCESS_TYPE.NAME", displayName: "BASIC_DATA.FILE_TYPE", width: 200 };
            const colForwardedBy: IMonacoColumnDef = { name: "FORWARDED_BY.NAME", displayName: "GENERAL.FORWARDED_BY", width: 200 };
            const colSalesExecutive: IMonacoColumnDef = { name: "SALES_PERSON.NAME", displayName: "BASIC_DATA.SALES_EXECUTIVE", width: 200 };
            const colCommercialBranch: IMonacoColumnDef = { name: "PROCESS.COMMERCIAL_UNITY.NAME", displayName: "BASIC_DATA.COMMERCIAL_BRANCH", width: 200 };
            const colSituationField: IMonacoColumnDef = { name: "SITUATION.NAME", displayName: "GENERAL.SITUATION", width: 200 };
            const colCreatedBy: IMonacoColumnDef = { name: "CREATED_BY", displayName: "GENERAL.CREATED_BY", width: 180 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_DATE", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedBy: IMonacoColumnDef = { name: "MODIFIED_BY", displayName: "GENERAL.MODIFIED_BY", width: 180 };
            const colUpdatedAt: IMonacoColumnDef = { name: "MODIFIED_DATE", displayName: "GENERAL.MODIFIED_DATE", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colAvailabilityExp: IMonacoColumnDef = { name: "AVAILABILITY_EXP", displayName: "OPERATIONAL.AVAILABILITY_SHIPPER", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colAvailabilityImp: IMonacoColumnDef = { name: "AVAILABILITY_IMP", displayName: "OPERATIONAL.AVAILABILITY_CONSIGNEE", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colCustomerReference: IMonacoColumnDef = { name: "CUSTOMER_REFERENCE", displayName: "OPERATIONAL.CLIENT_REFERENCE", width: 150 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'MASTER':
                        columnDefs.push(colMaster);
                        break;
                    case 'DRAFT_NUMBER':
                        columnDefs.push(colProtocol);
                        break;
                    case 'PROCESS_DOCUMENT_NUMBER':
                        columnDefs.push(colInntraCode);
                        break;
                    case 'PROCESS_NUMBER':
                        columnDefs.push(colProcessNumber);
                        break;
                    case 'DOCUMENT':
                        columnDefs.push(colKnowledge);
                        break;
                    case 'DRAFT_RECEIVED':
                        columnDefs.push(colDraftReceipt);
                        break;
                    case 'CLIENT_APPROVAL':
                        columnDefs.push(colClientApproval);
                        break;
                    case 'ALLOG_APPROVAL':
                        columnDefs.push(colAllogApproval);
                        break;
                    case 'ORIGINAL_COPY_RECEIVED':
                        columnDefs.push(colReceiptDateByEmail);
                        break;
                    case 'ISSUEANCE':
                        columnDefs.push(colIssuanceDate);
                        break;
                    case 'LOCAL_ISSUEANCE':
                        columnDefs.push(colIssuancePlace);
                        break;
                    case 'AVAILABILITY':
                        columnDefs.push(colAvailability);
                        break;
                    case 'EMISSION_TYPE':
                        columnDefs.push(colIssuanceType);
                        break;
                    case 'RELEASE_TYPE':
                        columnDefs.push(colReleaseType);
                        break;
                    case 'SENT_DATE':
                        columnDefs.push(colSendReleaseDate);
                        break;
                    case 'COURIER_CIA':
                        columnDefs.push(colCourierCia);
                        break;
                    case 'ORIGINAL_COPY':
                        columnDefs.push(colOriginalsCopies);
                        break;
                    case 'ORIGINALS_RECEIPT_RETAINED':
                        columnDefs.push(colOriginalsReceiptRetained);
                        break;
                    case 'PEOPLE_RELEASE':
                        columnDefs.push(colSendReleasePerson);
                        break;
                    case 'COURIER_SENT':
                        columnDefs.push(colCourierSendDate);
                        break;
                    case 'COURIER_RECEIVED_BRAZIL':
                        columnDefs.push(colCourierReceivedInBrazil);
                        break;
                    case 'COURIER_RECEIVED_ALLOG':
                        columnDefs.push(colCourierReceivedAtDestination);
                        break;
                    case 'COURIER_RECEIVED_FINOP':
                        columnDefs.push(colDocumentReceivedByFinop);
                        break;
                    case 'CE_MERCANTE':
                        columnDefs.push(colCeMercante);
                        break;
                    case 'DECONSOLIDATION':
                        columnDefs.push(colDesconsolidationDate);
                        break;
                    case 'RELEASE':
                        columnDefs.push(colReleaseDate);
                        break;
                    case 'RELEASED_TO':
                        columnDefs.push(colReleasedTo);
                        break;
                    case 'SISCARGA_MANTRA':
                        columnDefs.push(colSiscomesCargoReleased);
                        break;
                    case 'OBSERVATION':
                        columnDefs.push(colRemarks);
                        break;
                    case 'LOI_RECEIVED':
                        columnDefs.push(colLoiReceiveDate);
                        break;
                    case 'LOI_SENT':
                        columnDefs.push(colLoiSendDate);
                        break;
                    case 'DESTINATION_THC':
                        columnDefs.push(colDeclareDestinationThcOnHbl);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'TYPE':
                        columnDefs.push(colType);
                        break;
                    case 'DOCUMENT_COUNT_HOUSE':
                        columnDefs.push(colTotalHouse);
                        break;
                    case 'DOCUMENT_COUNT_MASTER':
                        columnDefs.push(colTotalMaster);
                        break;
                    case 'PROCESS.ESTIMATED_LOAD':
                        columnDefs.push(colEstimatedLoadingDate);
                        break;
                    case 'PROCESS.LOAD':
                        columnDefs.push(colActualLoadinDate);
                        break;
                    case 'PROCESS.ESTIMATED_DISCHARGE':
                        columnDefs.push(colEstimatedDischargeDate);
                        break;
                    case 'PROCESS.SITUATION':
                        columnDefs.push(colFileOperationalStatus);
                        break;
                    case 'PROCESS.DISCHARGE':
                        columnDefs.push(colActualDischargeDate);
                        break;
                    case 'PROCESS.DESTINATION':
                        columnDefs.push(colDestinationPort);
                        break;
                    case 'PROCESS.LOCAL_AGENT':
                        columnDefs.push(colLocalAgent);
                        break;
                    case 'PROCESS.EXTERNAL_AGENT':
                        columnDefs.push(colOverseasAgent);
                        break;
                    case 'PROCESS.DESTINATION_REPRESENTATIVE':
                        columnDefs.push(colRepresentatives);
                        break;
                    case 'PROCESS.BOOKING':
                        columnDefs.push(colBooking);
                        break;
                    case 'PROCESS.IMPORTER':
                        columnDefs.push(colConsignee);
                        break;
                    case 'PROCESS.EXPORTER':
                        columnDefs.push(colShipper);
                        break;
                    case 'PROCESS.SERVICE_PROVIDER':
                        columnDefs.push(colProvider);
                        break;
                    case 'PROCESS.INCOME_PAYMENT_CONDITION_ARR':
                        columnDefs.push(colReceiptConditions);
                        break;
                    case 'PROCESS.RECEIPT_SITUATION.SITUATION':
                        columnDefs.push(colReceiptSituation);
                        break;
                    case 'PROCESS.MASTER_DIRECT':
                        columnDefs.push(colStraightBl);
                        break;
                    case 'PROCESS.COMMERCIAL_UNITY':
                        columnDefs.push(colCommercialBranch);
                        break;
                    case 'CUSTOMER_QUALIFICATION':
                        columnDefs.push(colClientQualification);
                        break;
                    case 'CUSTOMER':
                        columnDefs.push(colClient);
                        columnDefs.push(colClientNetwork);
                        break;
                    case 'UNIQUE_TERM':
                        columnDefs.push(colUniqueTerm);
                        break;
                    case 'UNIQUE_TERM_FILE':
                        columnDefs.push(colUniqueTermFile);
                        break;
                    case 'BRANCH':
                        columnDefs.push(colBranch);
                        break;
                    case 'PROCESS_TYPE':
                        columnDefs.push(colProcessType);
                        break;
                    case 'CARGO_TYPE':
                        columnDefs.push(colCargoType);
                        break;
                    case 'FORWARDED_BY':
                        columnDefs.push(colForwardedBy);
                        break;
                    case 'SALES_PERSON':
                        columnDefs.push(colSalesExecutive);
                        break;
                    case 'CREATED_BY':
                        columnDefs.push(colCreatedBy);
                        break;
                    case 'CREATED_DATE':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'MODIFIED_BY':
                        columnDefs.push(colUpdatedBy);
                        break;
                    case 'MODIFIED_DATE':
                        columnDefs.push(colUpdatedAt);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActiveInactive);
                        break;
                    case 'SITUATION':
                        columnDefs.push(colSituationField);
                        break;
                    case 'AVAILABILITY_EXP':
                        columnDefs.push(colAvailabilityExp);
                        break;
                    case 'AVAILABILITY_IMP':
                        columnDefs.push(colAvailabilityImp);
                        break;
                    case 'CUSTOMER_REFERENCE':
                        columnDefs.push(colCustomerReference);
                        break;
                }
            }

            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.scope.model = {
            _id: null,
            ID: null,
            REFERENCE_ID: null,
            PROCESS_DOCUMENT_NUMBER: null,
            PROCESS_NUMBER: null,
            DOCUMENT: null,
            TYPE: null,
            COURIER_CIA: null,
            CE_MERCANTE: null,
            PROTOCOL_RETIFICATION: null,
            OBSERVATION: null,
            ORIGINAL_COPY: null,
            ORIGINALS_RECEIPT_RETAINED: null,
            RELEASED_TO: null,
            MASTER: null,
            EMISSION_TYPE: null,
            RELEASE_TYPE: null,
            PEOPLE_RELEASE: null,
            DRAFT_RECEIVED: null,
            CLIENT_APPROVAL: null,
            ALLOG_APPROVAL: null,
            ORIGINAL_COPY_RECEIVED: null,
            ISSUEANCE: null,
            LOCAL_ISSUEANCE: null,
            AVAILABILITY: null,
            COURIER_RECEIVED_BRAZIL: null,
            COURIER_SENT: null,
            COURIER_RECEIVED_ALLOG: null,
            COURIER_RECEIVED_FINOP: null,
            DECONSOLIDATION: null,
            LOI_RECEIVED: null,
            LOI_SENT: null,
            REQUEST_DATE: null,
            DONE: null,
            SENT_DATE: null,
            SISCARGA_MANTRA: null,
            RELEASE: null,
            DRAFT_NUMBER: null,
            AWB_NUMBER: null,
            AWB_PREFIX: null,
            INCONSISTENCY: null,
            INCONSISTENCY_CHARGE_DOCUMENT: null,
            DESTINATION_THC: null,
            PRODUCT: null,
            CARGO_TYPE: null,
            BRANCH: null,
            CUSTOMER_QUALIFICATION: null,
            PROCESS_TYPE: null,
            FORWARDED_BY: null,
            SALES_PERSON: null,
            CUSTOMER: null,
            UNIQUE_TERM: null,
            UNIQUE_TERM_FILE: null,
            SUSPENDED: false,
            CORRECTION: false,
            DELIVERY_DATE: null,
            AVAILABILITY_IMP: null,
            AVAILABILITY_EXP: null,
            CREATED_DATE: null,
            CREATED_BY: null,
            MODIFIED_DATE: null,
            MODIFIED_BY: null,
            DOCUMENT_COUNT_HOUSE: null,
            DOCUMENT_COUNT_MASTER: null,
            PROCESS: null,
            ACTIVE: true,
            SITUATION: null
        };
    }

    private async getGenericValue(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.HelperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async viewLog(entity: IProcessDocument): Promise<void> {
        try {
            this.block();

            let log: IViewLog = {
                operation: 'GENERAL.GRID.LOG',
                number: entity.PROCESS_DOCUMENT_NUMBER,
                list: [],
                show: true,
                searchQuery: '',
                originalList: [],
            }
            this.scope.customLogProperties = this.getCustomLogProperties();

            const response = await this.getProcessDocumentLog(log.number);

            if (response && response.data && response.data.data) {

                log.list = response.data.data;
                log.originalList = angular.copy(log.list);

                this.scope.log = log;

                angular.element('#log-viewer').removeClass('ng-hide');
                const position = angular.element('#log-viewer').offset().top + $('.app-content-body').scrollTop() - 105;
                $('.app-content-body').animate({ scrollTop: position }, 500);

            }

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getProcessDocumentLog(logNumber: string) {
        return this.DataProcessService.get(`/processDocument/viewlog/${logNumber}`, null);
    }

    private getProcessDocumentByProcessNumber(processNumber: string) {
        return this.DataProcessService.get(`/processDocument/number/${processNumber}`, null);
    }

    private getProcessDraftByProcessNumber(processNumber: string) {
        return this.DataProcessService.get(`/processDocument/draft/${processNumber}`, null);
    }

    private getAccountRequirements(accountId: string) {
        return this.ProductService.get({ route: "/account/requirement/" + accountId });
    }

    private updateProcessDocument(data: IProcessDocument, oldData: IProcessDocument) {
        return this.DataProcessService.post(`/processDocument/update`, { data, oldData }, 30000);
    }

    private getProcessByNumber(number: string): Promise<any> {
        const timeout = 120000;
        return this.OperationalService.get(`/process/byNumber/${number}/${timeout}`, null, timeout);
    }

    private async getAWBNumbersList(processNumber: string) {
        try {
            const request = { processnumber: processNumber };
            const awbnumberList = await this.OperationalService.get<any>(`/awbstock/list/${processNumber}`, request);
            return awbnumberList;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private insertProcessDocument(data, oldData) {
        delete data.DOCUMENT_COUNT_HOUSE;
        delete data.DOCUMENT_COUNT_MASTER;
        delete data.PROCESS;
        delete data.SINGLE_TERM;
        data._id = null;

        return this.DataProcessService.post(`/processDocument/insert`, { data, oldData }, 30000);
    }

    private goToProcess(process: string): void {
        this.SessionService.openTab('app.operational.newProcess.list', <IProcessParameter>{ PROCESS_NUMBER: process });
    }

    private goToDraft(draftID: string): void {
        this.SessionService.openTab('app.operational.draftRegister', <IDraftParameter>{ "DRAFT.DRAFT_NUMBER": draftID });
    }

    private goToDraftByProcessNumber(processNumber: string): void {
        this.SessionService.openTab('app.operational.draftRegister', <IDraftParameter>{ PROCESS_NUMBER: processNumber });
    }

    async register(): Promise<void> {
        try {
            this.scope.operation = EOperation.NEW;
            this.scope.scopeBeforeSave = null;
            this.scope.formOperation = 'Novo Cadastro';
            this.openNewProcessDocumentModal()
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async addProcessDocument(model: IProcessDocument): Promise<void> {
        try {
            if (model && model.PRODUCT && model.PRODUCT.CODE == EProductId.AIR_EXPORT) model.LOCAL_ISSUEANCE = await this.getLocalIssueanceToProductTypeEA(model.BRANCH.NAME);

            this.scope.model = angular.copy(model);
            this.scope.operation = EOperation.EDIT;
            this.scope.scopeBeforeSave = null;
            this.scope.formOperation = 'Novo Cadastro';
            this.openNewProcessDocumentModal()
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async viewProcessDocument(model: IProcessDocument): Promise<void> {
        try {
            this.scope.operation = EOperation.VIEW;
            this.scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW");
            this.openViewEditModal(model)

        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async editProcessDocument(model: IProcessDocument): Promise<void> {
        try {
            this.scope.operation = EOperation.EDIT;
            this.scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT");
            this.scope.scopeBeforeSave = angular.copy(this.scope.model);
            this.openViewEditModal(model)

        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.scope.operation == EOperation.NEW || this.scope.operation == EOperation.EDIT) {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.scope.operation === EOperation.EDIT ? "update" : "insert";

        return {
            route: `/processDocument/${route}`,
            data: angular.copy(this.scope.model),
            oldData: this.scope.scopeBeforeSave
        };
    }

    private async openNewProcessDocumentModal(): Promise<void> {
        try {
            this.block();

            this.modalID = this.ModalService.newModal();
            this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    template: require("../view/modal/newProcessDocumentModal.html"),
                    formService: this.scope.operation,
                    size: 'lg modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: this.scope.formOperation
                },
                null
            );
            await this.buildNewProcessDocumentModalScope(this.scope.model);

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openViewEditModal(model: IProcessDocument): Promise<void> {
        const productType = model.PRODUCT.ID;
        if (productType == EProductId.AIR_EXPORT || productType == EProductId.ROAD_EXPORT || productType == EProductId.MARITIME_EXPORT) {
            await this.openExportModal(model)
        } else if (productType == EProductId.AIR_IMPORT || productType == EProductId.ROAD_IMPORT || productType == EProductId.MARITIME_IMPORT) {
            await this.openImportModal(model)
        }
    }

    private async openImportModal(model: IProcessDocument): Promise<void> {
        try {
            this.block();

            this.modalID = this.ModalService.newModal();
            this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    template: require("../view/modal/importProcessDocument.html"),
                    formService: this.scope.operation,
                    size: 'full modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: this.scope.formOperation
                },
                null
            );
            await this.buildProcessDocumentModalScope(model);

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async changeProtocolModel(processDocument: IProcessDocument) {
        try {
            this.block();
            this.modalID = this.ModalService.newModal();
            this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    formService: EOperation.EDIT,
                    template: require("../view/modal/processDocumentProtocolChangeModal.html"),
                    size: 'md'
                },
                {
                    actionButtonText: "GENERAL.CLOSE",
                    headerText: `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")}: ${processDocument.PROCESS_DOCUMENT_NUMBER} - ${processDocument.PROCESS_NUMBER}`
                }
            );
            await this.buildProcessDocumentChangeProtocolModalScope(processDocument);

            this.unblock();
        } catch (ex) {
            this.handleError(ex)
        }

    }

    private async showReferencesModal(processDocument: IProcessDocument): Promise<void> {
        try {
            this.block();

            this.modalID = this.ModalService.newModal();
            const result = await this.getProcessByNumber(processDocument.PROCESS_NUMBER);
            if (!result || !result.data || (result.data && !result.data.data)) this.formService.handleError(this.formService.getTranslate("GENERAL.FAILED_TO_GET_FILE_INFORMATION"));
            this.scope.process = result.data.data[0];
            this.scope.oldProcess = JSON.parse(JSON.stringify(this.scope.process));
            this.scope.operation = EOperation.EDIT;

            this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    template: require('../view/NewProcessReferencesModal.html'),
                    formService: this.scope.operation,
                    size: 'vlg',
                    keyboard: true,
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing") {
                            if (reason.toString() == "escape key press") event.preventDefault();
                        }
                    }
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: 'OPERATIONAL.REFERENCES_AND_ATTACHMENTS'
                },
                null
            );
            await this.buildReferenceModalScope(this.scope.process.REFERENCE, this.scope.operation, processDocument);
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async buildNewProcessDocumentModalScope(model: IProcessDocument): Promise<INewProcessDocumentModal> {
        const modalScope: INewProcessDocumentModal = await this.ModalService.getModalScope(this.modalID);
        modalScope.model = angular.copy(model);
        modalScope.oldModel = null;

        modalScope.processDocumentTypeList = () => this.processDocumentTypeList();

        modalScope.getProcessSelector = async (processNumber: string) => {
            modalScope.processes = await this.getProcessSelector(processNumber);
        };

        modalScope.getProcessData = async (processNumber: string) => {
            modalScope.process = await this.getProcessData(processNumber);
            if (modalScope.process && modalScope.process.ACCOUNT_REQUIREMENT) {
                modalScope.legalPersonAccountRequirement = await this.getLegalPersonAccountRequirement(modalScope.process.ACCOUNT_REQUIREMENT.ID.toString());
            } else modalScope.legalPersonAccountRequirement = null;

            if (modalScope.legalPersonAccountRequirement) {
                modalScope.model.INCONSISTENCY_CHARGE_DOCUMENT = modalScope.legalPersonAccountRequirement;
            }
        };

        modalScope.applyProcessDocument = async () => {
            const processId = this.scope.operation === EOperation.NEW ? modalScope.process.PRODUCT.ID : modalScope.model.PRODUCT.ID;
            const branchName = this.scope.operation === EOperation.NEW ? modalScope.process.BRANCH.NAME : modalScope.model.BRANCH.NAME;
            if (await this.newProcessDocument(modalScope.model, modalScope.oldModel, modalScope.type, processId, branchName)) this.closeModal();
        };

        modalScope.closeProcessDocumentModal = async () => {
            if (this.scope.hasChanges(JSON.stringify(modalScope.model), JSON.stringify(modalScope.oldModel))) {
                const confirm = await this.scope.modalSaveConfirmation("GENERAL.CLOSE", "GENERAL.CLOSE");
                if (confirm) return;
            }
            this.closeModal();
        };

        await modalScope.$applyAsync();

        return modalScope;
    }

    private async buildProcessDocumentModalScope(model: IProcessDocument): Promise<IProcessDocumentModal> {

        const modalScope: IProcessDocumentModal = await this.ModalService.getModalScope(this.modalID);

        modalScope.model = angular.copy(model);
        modalScope.oldModel = angular.copy(model);
        modalScope.correction = modalScope.model.CORRECTION;
        modalScope.suspended = modalScope.model.SUSPENDED;

        modalScope.checkCorrectionSuspended = () => {
            modalScope.correction = modalScope.model.CORRECTION;
            modalScope.suspended = modalScope.model.SUSPENDED;
        };

        const typeList = this.processDocumentTypeList();
        modalScope.type = typeList.find(type => type.NAME == model.TYPE);

        modalScope.processDocumentSituationList = this.scope.processDocumentSituationList;
        modalScope.documentReleaseList = this.scope.documentReleaseList;
        modalScope.inconsistencyList = this.scope.inconsistencyList;
        modalScope.inconsistencyPrefList = this.scope.inconsistencyPrefList;
        modalScope.inconsistencyReleaseList = this.scope.inconsistencyReleaseList;
        modalScope.processDocumentStatusList = this.scope.processDocumentStatusList;
        modalScope.releaseTypeList = this.scope.releaseTypeList;
        modalScope.masterList = await this.getMasterList(modalScope.model);

        modalScope.getMasterList = async () => {
            if (modalScope.model.MASTER) modalScope.model.MASTER = null;
            modalScope.masterList = await this.getMasterList(modalScope.model);
        };
        modalScope.processDocumentTypeList = () => this.processDocumentTypeList();

        modalScope.getCityListByNameOrInitials = async (search?: string) => {
            modalScope.cityList = await this.getCityListByNameOrInitials(search);
        };

        modalScope.applyProcessDocument = async () => {
            if (await this.saveProcessDocument(modalScope.model, modalScope.oldModel, modalScope.type)) {
                this.closeModal();
            }
        };

        modalScope.closeProcessDocumentModal = async () => {
            if (this.scope.hasChanges(JSON.stringify(modalScope.model), JSON.stringify(modalScope.oldModel))) {
                const confirm = await this.scope.modalSaveConfirmation("GENERAL.CLOSE", "GENERAL.CLOSE");
                if (confirm) return;
            }
            this.closeModal();
        };

        const awbNumber = modalScope.model.AWB_NUMBER ? modalScope.model.AWB_NUMBER : null;
        modalScope.awbNumberExchange = {
            modelID: null,
            awbSelectedValue: null,
            awbNumberList: []
        }

        modalScope.awbNumberExchange.awbSelectedValue = { ID: null, NAME: awbNumber }
        const result = (process) ? await this.getAWBNumbersList(modalScope.model.PROCESS_NUMBER) : null;
        for (let register of result.data.data) {
            if (register.AWB_NUMBER) {
                let document = { ID: register.ID, NAME: register.AWB_NUMBER };
                modalScope.awbNumberExchange.awbNumberList.push(document);
            }
        }

        modalScope.applyAwbNumberExchange = async () => {
            if (!modalScope.awbNumberExchange.awbSelectedValue) {
                modalScope.model.AWB_NUMBER = null;
            } else {
                const newDocument = modalScope.awbNumberExchange.awbSelectedValue.NAME;
                modalScope.model.DOCUMENT = newDocument;
                modalScope.model.AWB_NUMBER = newDocument;
            }
        };

        modalScope.goToAWBStock = async () => {
            this.SessionService.openTab("app.operational.awbstock");
        }

        await modalScope.$applyAsync();

        return modalScope;
    }

    private async buildProcessDocumentChangeProtocolModalScope(processDocument: IProcessDocument): Promise<IProtocolExchangeScope> {
        const modalScope: IProtocolExchangeScope = await this.ModalService.getModalScope(this.modalID);

        modalScope.protocolExchange = {
            modelID: this.modalID,
            protocolList: [],
            protocolSelectedValue: null
        }
        const inactiveDraftId = '6';
        const result = await this.getProcessDraftByProcessNumber(processDocument.PROCESS_NUMBER);
        if (result && result.data && result.data.data) {
            const draftList: DraftViewModel[] = result.data.data;
            modalScope.protocolExchange.protocolList = draftList.map((draft) => {
                if (processDocument.TYPE == EProcessDocumentType.MASTER && draft.DRAFT && draft.DRAFT.DRAFT_NUMBER != processDocument.DRAFT_NUMBER && draft.DRAFT.STATUS.ID != inactiveDraftId) return draft.DRAFT
                if (processDocument.TYPE == EProcessDocumentType.HOUSE && draft.DRAFT && draft.DRAFT.DRAFT_NUMBER != processDocument.DRAFT_NUMBER && draft.DRAFT.STATUS.ID != inactiveDraftId) return draft.DRAFT
            });
        }

        modalScope.applyProtocolExchange = async () => {
            try {
                if (modalScope.protocolExchange.protocolSelectedValue && modalScope.protocolExchange.protocolSelectedValue.DRAFT_NUMBER) {
                    this.block();

                    const draftSelected = modalScope.protocolExchange.protocolSelectedValue;
                    const oldProcessDocument = angular.copy(processDocument);
                    const newProtocol = draftSelected.DRAFT_NUMBER;

                    if (!processDocument.ACTIVE) throw this.formService.notifyWarning('Unable to apply Protocol to inactive Process Document');
                    processDocument.DRAFT_NUMBER = newProtocol;

                    // Update row process knowledge
                    let res = await this.updateProcessDocument(processDocument, oldProcessDocument);
                    if (!res || res.status !== 200) throw this.formService.getTranslate("GENERAL.IT_IS_NOT_POSSIBLE_TO_UPDATE_THE_DRAFT_NUMBER");

                    await this.$gridService.handleBackgroundUpdate();
                    this.scope.$applyAsync();

                    this.unblock();
                    this.ModalService.closeModal(modalScope.protocolExchange.modelID);
                }
            } catch (ex) {
                this.handleError(ex);
                this.unblock();
                this.ModalService.closeModal(modalScope.protocolExchange.modelID);
            }
        }

        modalScope.$applyAsync();
        return modalScope;
    }

    private async buildReferenceModalScope(references: REFERENCE[], operation: string, processDocument: IProcessDocument): Promise<IReferenceModalScope> {
        const modalScope: IReferenceModalScope = await this.ModalService.getModalScope(this.modalID);
        modalScope.operation = operation;
        modalScope.references = angular.copy(references);
        modalScope.oldReferences = angular.copy(references);
        modalScope.processNumber = this.scope.process.PROCESS_NUMBER;
        this.scope.model = angular.copy(processDocument);

        modalScope.viewFile = (file: REFERENCE): void => {
            if (file) window.open(file.LINK, "_blank");
        }

        modalScope.downloadFile = async (file: REFERENCE): Promise<void> => {
            if (file) {
                const linkBroken: string[] = file.LINK.split('/');
                const hash: string = linkBroken[linkBroken.length - 1];
                this.downloadFileFromGoogleStorage(hash);
            }
        }

        modalScope.copyFileLinkToClipboard = (file: REFERENCE) => {
            if (file) {
                const elmAux = document.createElement("textarea");
                document.body.appendChild(elmAux);
                elmAux.value = file.LINK;
                elmAux.select();
                document.execCommand("copy");
                document.body.removeChild(elmAux);
                this.formService.notifySuccess("Link do arquivo foi copiado para o clipboard.");
            }
        }

        modalScope.viewProcessReferenceModal = async (file: REFERENCE) => {
            const disabledFields: IFileReferenceDisabledFields = {
                documentType: true,
                documentValue: true,
                fileGroup: true,
                fileSpecs: true,
                name: true,
                templateType: true,
                documentSequence: true,
                configuration: true,
                productType: true,
                telephone: true,
                technicalManager: true,
                email: true,
            };
            const extensionRemoveList: string[] = file.NAME ? file.NAME.split('.') : null;
            const extension: string = (extensionRemoveList && extensionRemoveList.length > 0) ? extensionRemoveList[extensionRemoveList.length - 1] : null;
            const fileModel: IFiles = {
                FILES: file.LINK && file.LINK.length ? [
                    {
                        FILE_DISPLAY_NAME: file.NAME ? file.NAME : file.DOCUMENT_VALUE,
                        FILE_NAME: file.NAME,
                        FILE_TYPE: extension,
                        FILE_PATH: null,
                        FILE_URL: file.LINK,
                        FILE_HASH: null,
                        FORM_NAME: "processReference"
                    }
                ] : []
            };
            await this.fileReferenceModal(file, null, disabledFields, true, fileModel);
        }

        modalScope.editProcessReferenceModal = async (index: number) => {
            const file = modalScope.references[index];
            const extensionRemoveList: string[] = file.NAME ? file.NAME.split('.') : null;
            const extension: string = (extensionRemoveList && extensionRemoveList.length > 0) ? extensionRemoveList[extensionRemoveList.length - 1] : null;
            const fileModel: IFiles = {
                FILES: file.LINK && file.LINK.length ? [
                    {
                        FILE_DISPLAY_NAME: file.NAME ? file.NAME : file.DOCUMENT_VALUE,
                        FILE_NAME: file.NAME,
                        FILE_TYPE: extension,
                        FILE_PATH: null,
                        FILE_URL: file.LINK,
                        FILE_HASH: null,
                        FORM_NAME: "processReference"
                    }
                ] : []
            };
            const modalResponse = await this.fileReferenceModal(angular.copy(file), null, null, true, fileModel, true);
            if (modalResponse) {
                modalScope.references.splice(index, 1, modalResponse[0]);
                modalScope.oldReferences = angular.copy(modalScope.references);
                const msg = this.formService.getTranslate('BASIC_DATA.REFERENCE_SUCESSFULLY_UPDATED');
                this.formService.notifySuccess(msg);
                modalScope.$applyAsync();
            }
        }

        modalScope.fileReferenceModal = async () => {
            const modalResponse = await this.fileReferenceModal(null);
            //update process view table
            if (modalResponse) {
                const newReferences = modalResponse;
                if (!modalScope.references) modalScope.references = [];
                for (const newReference of newReferences) {
                    modalScope.references.splice(0, 0, newReference);
                    const msg = this.formService.getTranslate('BASIC_DATA.REFERENCE_SUCESSFULLY_ADDED');
                    this.formService.notifySuccess(msg);
                }
            }
        }

        modalScope.applyReferenceModal = async (close?: boolean) => {
            this.applyReference(modalScope.references, processDocument, close);
        };

        modalScope.closeReferenceModal = async (): Promise<void> => {
            modalScope.oldReferences = this.scope.oldProcess.REFERENCE
            if (this.scope.hasChanges(modalScope.references, modalScope.oldReferences)) {
                const close = this.formService.getTranslate('GENERAL.CLOSE');
                const confirm = await this.scope.modalSaveConfirmation(close, close);
                if (confirm && !this.applyReference(modalScope.references)) return;
            }
            this.ModalService.closeModal(this.modalID);
            this.modalID = 0;
            $('.app-content-body').animate({ scrollTop: this.scope.lastScroll }, 0);
        }

        modalScope.removeReference = async (index: number) => {
            if (!index && index != 0) throw Error('index is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (modalScope.references && modalScope.references.length > 0) modalScope.references.splice(index, 1);
        }

        modalScope.mainFileChange = (index: number) => {
            if (!index && index != 0) throw Error('index is null');

            const changedReference = modalScope.references && modalScope.references.length ? modalScope.references[index] : null;
            const mainFileReferences = modalScope.references && modalScope.references.length ? modalScope.references.filter(reference => reference.MAIN_FILE && reference.REFERENCE_ID != changedReference.REFERENCE_ID) : [];
            if (changedReference && changedReference.MAIN_FILE && mainFileReferences.length) {
                mainFileReferences.forEach(reference => {
                    reference.MAIN_FILE = false;
                });
            }
        }
        await modalScope.$applyAsync();

        return modalScope;
    }

    private async modalSaveConfirmation(headerText: string, closeButtonText: string): Promise<boolean> {
        return await this.ModalService.showModalConfirmation({}, {
            headerText: headerText,
            bodyText: this.formService.getTranslate('REGISTRATION.MESSAGES.ERROR.UPDATE_NOT_SAVED'),
            actionButtonText: "GENERAL.SAVE",
            closeButtonText: closeButtonText
        });
    }

    private async closeModal(): Promise<void> {
        this.ModalService.closeModal(this.modalID);
        this.modalID = 0;
    }

    private async getMasterList(model: IProcessDocument): Promise<SelectorModel[]> {
        let masterList = []
        if (model.TYPE == EProcessDocumentType.HOUSE) {
            this.block();
            const documentsResult = await this.getProcessDocumentByProcessNumber(model.PROCESS_NUMBER)
            const documentsList: IProcessDocument[] = (documentsResult && documentsResult.data && documentsResult.data.data) ? documentsResult.data.data : [];
            this.unblock();
            if (documentsList && documentsList.length) {
                masterList = documentsList.filter(document => document.TYPE == EProcessDocumentType.MASTER && document.ACTIVE).map(document => {
                    return { ID: document.ID, NAME: document.DOCUMENT }
                });
            }
        } else {
            if (model.DOCUMENT != null) masterList.push({ ID: model.ID, NAME: model.DOCUMENT });
        }
        return masterList;
    }

    private async openExportModal(model: IProcessDocument): Promise<void> {
        try {
            this.block();

            this.modalID = this.ModalService.newModal();
            this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    template: require("../view/modal/exportProcessDocument.html"),
                    formService: this.scope.operation,
                    size: 'full modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: this.scope.formOperation
                },
                null
            );

            await this.buildProcessDocumentModalScope(model);
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async saveProcessDocument(processDocument: IProcessDocument, oldProcessDocument: IProcessDocument, type: SelectorModel): Promise<boolean> {
        let success: boolean = false;
        try {
            this.block();
            const isInvalid = this.scope.hasInvalidRequiredElements('processDocumentModalForm')
            if (isInvalid) return;
            this.setMaster(type, processDocument);

            const rc = await this.updateProcessDocument(processDocument, oldProcessDocument);
            if (rc.status === 200) {
                success = true;
                await this.updateGrid();
                const msgSuccess = "Conhecimento do Processo atualizado com sucesso.";
                this.formService.notifySuccess(msgSuccess);
                const response = (rc && rc.data && rc.data.data) ? rc.data.data : null;

                if (response.error) this.handleError(response.error)
                else if (response.message) this.formService.notifySuccess(response.message)
            }

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.unblock();
            return success
        }
    }

    private async newProcessDocument(processDocument: IProcessDocument, oldProcessDocument: IProcessDocument, type: SelectorModel, productId: string, branchName: string): Promise<boolean> {
        let success: boolean = false;
        try {
            this.formService.block();

            const isInvalid = this.scope.hasInvalidRequiredElements('processDocumentModalForm')
            if (isInvalid) return;

            this.masterExist(type, processDocument);
            this.setMaster(type, processDocument);

            if (productId == EProductId.AIR_EXPORT) processDocument.LOCAL_ISSUEANCE = await this.getLocalIssueanceToProductTypeEA(branchName);

            const rc = await this.insertProcessDocument(processDocument, oldProcessDocument);
            if (rc.status === 200) {
                success = true;
                await this.updateGrid();
                const msgSuccess = "Conhecimento do Processo inserido com sucesso.";
                this.formService.notifySuccess(msgSuccess);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return success
        }
    }

    private async fileReferenceModal(file: REFERENCE, preSetFields?: IFileReferencePreSetFields, disabledFields?: IFileReferenceDisabledFields, uploadDisabled?: boolean, fileModel?: IFiles, isEdit?: boolean) {
        try {
            this.fileReferenceModalID = this.ModalService.newModal();
            if (!this.scope.model.DOCUMENT) return this.formService.handleError('É necessário inserir um valor de Waybill antes de gerar o documento.');

            const modalResponse = await this.ModalService.showModalFileReference(
                {
                    modalID: this.fileReferenceModalID,

                    categoryName: [
                        EReportCategoryName.Process,
                        EReportCategoryName.ISFCAN,
                        EReportCategoryName.ISFUSA,
                        EReportCategoryName.PreAdvise,
                        EReportCategoryName.AMS,
                        EReportCategoryName.BillOfLading,
                        EReportCategoryName.BillOfLadingForPrint,
                        EReportCategoryName.BillOfLadingCover,
                        EReportCategoryName.Memorandum,
                        EReportCategoryName.AirWaybill,
                        EReportCategoryName.AirLabel,
                        EReportCategoryName.Air_Manifest_EN
                    ],
                    blNumber: [{ number: this.scope.model.DOCUMENT, PROCESS_NUMBER: this.scope.process.PROCESS_NUMBER }],
                    process: [this.scope.process],
                    processDocument: this.scope.model,
                    fileOnly: true,
                    isEdit: isEdit,
                    preSetFields: preSetFields,
                    disabledFields: disabledFields,
                    uploadDisabled: uploadDisabled,
                    file: file,
                    fileModel: fileModel,
                }
            );
            return modalResponse;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private hasInvalidRequiredElements(elementId: string): boolean {
        if (!elementId) return false;
        const isInvalid = FormService2.hasRequiredElements('#' + elementId);
        if (isInvalid) this.formService.notifyError(this.formService.getTranslate("GENERAL.ALL_FIELDS_MANDATORY"));
        return isInvalid;
    }

    private async getCityListByNameOrInitials(search?: string, fullName: boolean = false): Promise<string[]> {
        let result = [];
        this.formService.block();
        try {
            if (search && search.length >= 3) {
                result = await this.getStringCityCountryList(search)
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getLegalPersonAccountRequirement(requirementId: string): Promise<INCONSISTENCY_CHARGE_DOCUMENT> {
        this.formService.block();
        let result: INCONSISTENCY_CHARGE_DOCUMENT = null;
        try {
            const requirements = await this.getAccountRequirements(requirementId);
            if (requirements && requirements.data && requirements.data.data) {
                const requirement = requirements.data.data
                if (requirement.CHARGE_PREFERENCE_INCONSISTENCY || requirement.CHARGE_RELEASE_INCONSISTENCY) {
                    result = {
                        PREFERENCE: requirement.CHARGE_PREFERENCE_INCONSISTENCY,
                        RELEASE: requirement.CHARGE_RELEASE_INCONSISTENCY,
                    }
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getStringCityCountryList(search: string, fullName: boolean = false) {
        let result = [];
        const cities = await this.ProductService.post({ route: '/city/list/custom', data: { name: search, fullName } });
        if (cities && cities.data && cities.data.data) result = cities.data.data.map(city => { return city.NAME + ' - ' + city.PROVINCE.COUNTRY.NAME });
        return result;
    }

    private async getStringCityCountryById(id: number) {
        let result = '';
        const city = await this.ProductService.get({ route: `/city/getById/${id}` });
        if (city && city.data && city.data.data) result = `${city.data.data.NAME} - ${city.data.data.PROVINCE.COUNTRY.NAME}`;
        return result;
    }

    private async getLocalIssueanceToProductTypeEA(branchName: string) {
        let result = '';
        const name = branchName;
        this.formService.block();
        try {
            const legalPersonResponse = await this.ProductService.post({
                route: "/legalPerson/list/custom",
                data: {
                    ...{ search: name, specializations: [] },
                    sysConvertIdToString: false,
                },
            });
            const legalPerson =
                legalPersonResponse && legalPersonResponse.data && legalPersonResponse.data.data
                    ? legalPersonResponse.data.data.map((legalPerson) => {
                        return {
                            ID_CITY: legalPerson.ADDRESS.ID_CITY,
                        };
                    })
                    : '';
            const cityName = await this.getStringCityCountryById(legalPerson[0].ID_CITY);
            result = cityName ? cityName : '';
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private processDocumentTypeList(): Array<SelectorModel> {
        const master: SelectorModel = {
            ID: EProcessDocumentTypeId.MASTER,
            NAME: EProcessDocumentType.MASTER
        }

        const house: SelectorModel = {
            ID: EProcessDocumentTypeId.HOUSE,
            NAME: EProcessDocumentType.HOUSE
        }

        return [master, house];
    }

    private async getProcessListSelector(processNumber: string): Promise<any> {
        let result = [];
        try {
            this.block();
            const timeout = 120000;

            if (processNumber && processNumber.length >= 3) {
                const request = {
                    data: {
                        forwardingSituationToExclude: [], // finalizado ou check de valores
                        term: processNumber,
                        timeout,
                    },
                    route: `/process/selector`,
                    timeout,
                }
                const processes = await this.OperationalService.post(request.route, request.data, request.timeout);
                if (processes && processes.data && processes.data.data && Array.isArray(processes.data.data)) {
                    result = processes.data.data.map(process => process.NAME);
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getProcessSelector(processNumber: string): Promise<SelectorModel[]> {
        this.scope.processes = [];
        try {
            if (processNumber.length >= 3) {
                const result = await this.getProcessListSelector(processNumber);
                if (result) {
                    return result
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getProcessData(processNumber: string): Promise<IProcess> {
        try {
            const result = await this.getProcessByNumber(processNumber);
            if (result && result.data && result.data.data) {
                const process = result.data.data[0];
                this.scope.$applyAsync();
                return process
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async masterExist(type: SelectorModel, processDocument: IProcessDocument) {
        try {
            if (type.NAME == EProcessDocumentType.HOUSE && processDocument.PROCESS_NUMBER) {
                this.block();
                const documents = await this.getProcessDocumentByProcessNumber(processDocument.PROCESS_NUMBER);
                if (!documents || !documents.data || !documents.data.data) return this.handleError(this.formService.getTranslate("GENERAL.FAILED_TO_GET_FILE_INFORMATION"));
                this.unblock();

                let masterExist = false;

                for (let document of documents.data.data) {
                    if (document.TYPE == EProcessDocumentType.MASTER) masterExist = true;
                }

                if (!masterExist) return this.handleError(this.formService.getTranslate("GENERAL.IT_IS_NECESSARY_TO_HAVE_A_MASTER_BILL_TO_INCLUDE_A_HOUSE_BILL"));
            }

            return (true);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async setMaster(type: SelectorModel, processDocument: IProcessDocument) {
        try {
            processDocument.TYPE = type.NAME;
            return (true);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getConcatUniqueTerm(uniqueTerm: IFileUpload[]): string {
        try {
            let string = '';
            if (!uniqueTerm || uniqueTerm.length === 0) return string;

            for (let index = 0; index < uniqueTerm.length; index++) {
                string += `<a target="_blank" class="text-u-l" href="${uniqueTerm[index].FILE_URL}">${uniqueTerm[index].FILE_DISPLAY_NAME}</a> ${uniqueTerm.length > 1 && index !== (uniqueTerm.length - 1) ? ", " : ""}`;
            }

            return string;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getUniqueTermTooltip(uniqueTerm: IFileUpload[]): Promise<string> {
        return !uniqueTerm || uniqueTerm.length === 0 ? '' : this.formService.getTranslate("GENERAL.GRID.VIEW_ATTACHMENT");
    }

    public async downloadFileFromGoogleStorage(hash: string): Promise<void> {
        try {
            this.formService.block();
            if (!hash) return null;

            const operation = await this.RestService.getObjectAsPromise(`${this.downloadRoute}/${hash}`, 30000, null, false);
            const response: angular.IHttpResponse<any> = operation;
            if (response && response.data) {
                const resultFile = <IDownloadParamsReturn>response.data;

                const file = resultFile.file;
                if (!resultFile.buffer) {
                    throw Error('Failed to get file information');
                }
                const buffer = resultFile.buffer.data;
                if (!file || !buffer) {
                    throw Error('Failed to get file information');
                }
                const fileName = file.fileName;
                const fileBuffer = new Uint8Array(buffer);
                const fileType = this.getContentType(file.fileType);
                const fileBlob = new Blob([fileBuffer], { type: fileType });
                const fileURL = window.URL.createObjectURL(fileBlob);

                let link = document.createElement('a');
                link.href = fileURL;
                link.download = fileName;
                link.click();
            } else {
                throw Error('Error on download file')
            }

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async applyReference(references: REFERENCE[], processDocument?: IProcessDocument, close?: boolean): Promise<boolean> {
        let success = false;
        try {
            this.formService.block();
            const updateReferenceResponse = await this.OperationalService.post('/process/updateReference', { processNumber: this.scope.process.PROCESS_NUMBER, data: { REFERENCE: references } }, 30000);
            if (updateReferenceResponse && updateReferenceResponse.status == 200) {
                success = true;

                const newReferenceId = references.find(reference => !this.scope.oldProcess.REFERENCE.find(oldReference => oldReference.REFERENCE_ID == reference.REFERENCE_ID));
                // if newReferenceId is true, then update the processDocument
                if (newReferenceId) {
                    const oldProcessDocument = angular.copy(processDocument);
                    processDocument.REFERENCE_ID = newReferenceId.REFERENCE_ID;
                    await this.updateProcessDocument(processDocument, oldProcessDocument);
                }

                // if reference_id is not found, then remove reference_id
                if (!references.find(reference => reference.REFERENCE_ID == processDocument.REFERENCE_ID)) {
                    const oldProcessDocument = angular.copy(processDocument);
                    processDocument.REFERENCE_ID = null;
                    await this.updateProcessDocument(processDocument, oldProcessDocument);
                }

                this.scope.process.REFERENCE = references;
                this.scope.oldProcess.REFERENCE = angular.copy(references);
            }
            if (close) {
                this.ModalService.closeModal(this.fileReferenceModalID);
                this.fileReferenceModalID = 0;
                $('.app-content-body').animate({ scrollTop: this.scope.lastScroll }, 0);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            if (success) {
                const msg = this.formService.getTranslate('BASIC_DATA.REFERENCE_SUCESSFULLY_UPDATED');
                this.formService.notifySuccess(msg);
            }
            return success;
        }
    }

    private getContentType(fileType: string): string {
        switch (fileType.toLowerCase()) {
            case 'arc':
                return 'application/octet-stream';
            case 'bin':
                return 'application/octet-stream';
            case 'avi':
                return 'video/x-msvideo';
            case 'azw':
                return 'application/vnd.amazon.ebook';
            case 'gif':
                return 'image/gif';
            case 'htm':
                return 'text/html';
            case 'html':
                return 'text/html';
            case 'ico':
                return 'image/x-icon';
            case 'ics':
                return 'text/calendar';
            case 'js':
                return 'application/javascript';
            case 'json':
                return 'application/json';
            case 'mpeg':
                return 'video/mpeg';
            case 'jpeg':
                return 'image/jpeg';
            case 'jpg':
                return 'image/jpg';
            case 'png':
                return 'image/png';
            case 'pdf':
                return 'application/pdf';
            case 'rar':
                return 'application/x-rar-compressed';
            case 'zip':
                return 'application/zip';
            case '7z':
                return 'application/x-7z-compressed';
            case 'ppt':
                return 'application/vnd.ms-powerpoint';
            case 'csv':
                return 'text/csv';
            case 'doc':
                return 'application/msword';
            case 'xls':
                return 'application/vnd.ms-excel';
            case 'xlsx':
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            case 'vsd':
                return 'application/vnd.visio';
            case 'ts':
                return 'application/typescript';
            case 'rtf':
                return 'application/rtf';
            default:
                return 'text/plain';
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: 'PROCESS_DOCUMENT_NUMBER',
                LABEL: 'GENERAL.CODE'
            },
            {
                PROPERTY: 'PROCESS_NUMBER',
                LABEL: 'OPERATIONAL.FILE_NUMBER'
            },
            {
                PROPERTY: 'TYPE',
                LABEL: 'GENERAL.TYPE'
            },
            {
                PROPERTY: 'PRODUCT',
                LABEL: 'BASIC_DATA.PRODUCT'
            },
            {
                PROPERTY: 'MASTER',
                LABEL: 'FINANCIAL.MASTER'
            },
            {
                PROPERTY: 'DOCUMENT',
                LABEL: 'GENERAL.MENU.PROCESS_KNOWLEDGE'
            },
            {
                PROPERTY: 'DRAFT_NUMBER',
                LABEL: 'OPERATIONAL.PROTOCOL'
            },
            {
                PROPERTY: 'CUSTOMER_QUALIFICATION',
                LABEL: 'GENERAL.CLIENT_QUALIFICATION'
            },
            {
                PROPERTY: 'CUSTOMER',
                LABEL: 'BASIC_DATA.CLIENT'
            },
            {
                PROPERTY: 'DRAFT_RECEIVED',
                LABEL: 'OPERATIONAL.DRAFT_RECEIPT_DATE'
            },
            {
                PROPERTY: 'CLIENT_APPROVAL',
                LABEL: 'OPERATIONAL.CLIENT_APPROVAL'
            },
            {
                PROPERTY: 'ALLOG_APPROVAL',
                LABEL: 'OPERATIONAL.ALLOG_APPROVAL'
            },
            {
                PROPERTY: 'ISSUEANCE',
                LABEL: 'OPERATIONAL.ISSUANCE_DATE'
            },
            {
                PROPERTY: 'LOCAL_ISSUEANCE',
                LABEL: 'OPERATIONAL.ISSUANCE_PLACE'
            },
            {
                PROPERTY: 'AVAILABILITY',
                LABEL: 'OPERATIONAL.AVAILABILITY'
            },
            {
                PROPERTY: 'EMISSION_TYPE',
                LABEL: 'OPERATIONAL.ISSUANCE_TYPE'
            },
            {
                PROPERTY: 'RELEASE_TYPE',
                LABEL: 'OPERATIONAL.RELEASE_TYPE"'
            },
            {
                PROPERTY: 'SENT_DATE',
                LABEL: 'OPERATIONAL.SEND_RELEASE_DATE'
            },
            {
                PROPERTY: 'COURIER_CIA',
                LABEL: 'OPERATIONAL.COURIER_CIA'
            },
            {
                PROPERTY: 'ORIGINAL_COPY_RECEIVED',
                LABEL: 'OPERATIONAL.ORIGINALS_RECEIPT_DATE_BY_EMAIL'
            },
            {
                PROPERTY: 'ORIGINAL_COPY',
                LABEL: 'OPERATIONAL.ORIGINALS_COPIES'
            },
            {
                PROPERTY: 'ORIGINALS_RECEIPT_RETAINED',
                LABEL: 'OPERATIONAL.ORIGINALS_RECEIPT_RETAINED'
            },
            {
                PROPERTY: 'PEOPLE_RELEASE',
                LABEL: 'OPERATIONAL.SEND_RELEASE_PERSON'
            },
            {
                PROPERTY: 'COURIER_SENT',
                LABEL: 'OPERATIONAL.COURIER_SEND_DATE'
            },
            {
                PROPERTY: 'COURIER_RECEIVED_BRAZIL',
                LABEL: 'OPERATIONAL.COURIER_RECEIVED_IN_BRAZIL'
            },
            {
                PROPERTY: 'COURIER_RECEIVED_ALLOG',
                LABEL: 'OPERATIONAL.COURIER_RECEIVED_AT_DESTINATION'
            },
            {
                PROPERTY: 'COURIER_RECEIVED_FINOP',
                LABEL: 'OPERATIONAL.DOCUMENT_RECEIVED_BY_FINOP'
            },
            {
                PROPERTY: 'CE_MERCANTE',
                LABEL: 'OPERATIONAL.CE_MERCANTE'
            },
            {
                PROPERTY: 'DECONSOLIDATION',
                LABEL: 'OPERATIONAL.DESCONSOLIDATION_DATE'
            },
            {
                PROPERTY: 'LOI_RECEIVED',
                LABEL: 'OPERATIONAL.LOI_RECEIVE_DATE'
            },
            {
                PROPERTY: 'LOI_SENT',
                LABEL: 'OPERATIONAL.LOI_SEND_DATE'
            },
            {
                PROPERTY: 'SITUATION',
                LABEL: 'GENERAL.SITUATION'
            },
            {
                PROPERTY: 'UNIQUE_TERM',
                LABEL: 'OPERATIONAL.UNIQUE_TERM'
            },
            {
                PROPERTY: 'UNIQUE_TERM_FILE',
                LABEL: 'OPERATIONAL.UNIQUE_TERM_FILE'
            },
            {
                PROPERTY: 'RELEASE',
                LABEL: 'OPERATIONAL.RELEASE_DATE'
            },
            {
                PROPERTY: 'RELEASED_TO',
                LABEL: 'OPERATIONAL.RELEASED_TO'
            },
            {
                PROPERTY: 'SISCARGA_MANTRA',
                LABEL: 'OPERATIONAL.SISCOMEX_CARGO_RELEASED_AIMED_MANTRA'
            },
            {
                PROPERTY: 'OBSERVATION',
                LABEL: 'GENERAL.REMARKS'
            },
            {
                PROPERTY: 'DESTINATION_THC',
                LABEL: 'OPERATIONAL.DECLARE_DESTINATION_THC_ON_HBL'
            },
            {
                PROPERTY: 'CARGO_TYPE',
                LABEL: 'BASIC_DATA.CARGO_TYPE'
            },
            {
                PROPERTY: 'BRANCH',
                LABEL: 'BASIC_DATA.BRANCH'
            },
            {
                PROPERTY: 'PROCESS_TYPE',
                LABEL: 'OPERATIONAL.FILE_TYPE'
            },
            {
                PROPERTY: 'FORWARDED_BY',
                LABEL: 'GENERAL.FORWARDED_BY'
            },
            {
                PROPERTY: 'SALES_PERSON',
                LABEL: 'BASIC_DATA.SALES_EXECUTIVE'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'CREATED_DATE',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'MODIFIED_BY',
                LABEL: 'GENERAL.MODIFIED_BY'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            },
            {
                PROPERTY: 'MODIFIED_DATE',
                LABEL: 'GENERAL.MODIFIED_DATE'
            },
            {
                PROPERTY: 'UPDATED_DATE',
                LABEL: 'GENERAL.MODIFIED_DATE'
            },
            {
                PROPERTY: 'INCONSISTENCY',
                LABEL: 'GENERAL.INCONSISTENCY'
            },
            {
                PROPERTY: 'AVAILABILITY_EXP',
                LABEL: 'OPERATIONAL.AVAILABILITY_SHIPPER'
            },
            {
                PROPERTY: 'AVAILABILITY_IMP',
                LABEL: 'OPERATIONAL.AVAILABILITY_CONSIGNEE'
            },
            {
                PROPERTY: 'DELIVERY_DATE',
                LABEL: 'OPERATIONAL.DELIVERY_DATE'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
            {
                PROPERTY: 'ID',
                LABEL: 'REGISTRATION.IDENTIFICATION'
            },
            {
                PROPERTY: 'CODE',
                LABEL: 'GENERAL.CODE'
            },
            {
                PROPERTY: 'ORDER',
                LABEL: 'GENERAL.ORDER'
            },
            {
                PROPERTY: 'AVAILABILITY_EXP',
                LABEL: 'OPERATIONAL.AVAILABILITY_SHIPPER'
            },
            {
                PROPERTY: 'AVAILABILITY_IMP',
                LABEL: 'OPERATIONAL.AVAILABILITY_CONSIGNEE'
            },
        ];
        return props;
    }

}
import * as angular from 'angular';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from '@services/GridFormService';
import { IRestService } from '@services/RestService';
import { ExternalService } from "@services/ExternalService";
import { ProductService } from "@services/ProductService";
import { IMonacoColumnDef } from '@services/GridService2';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IErpProjectSetup } from '../model/ErpProjectSetupModel';
import { GridColumnBuilder, GridColumnBuilderConstants } from '../../common/GridColumnBuilder';
import { SelectorModel } from '../../common/model/SelectorModel';
import { BrowserTitle } from "../../common/BrowserTitle";
import { ILegalPersonSelector } from "../../registration/model/LegalPersonModel";
import { ISelectorModel } from 'WBA-Model/dist/mongo/SelectorModel';
import { HelperService } from "@services/HelperService";

interface IErpProjectSetupScope extends IGridFormServiceScope {
    model: IErpProjectSetup;
    log: IViewLog;
    productList: SelectorModel[];
    erpProjectList: SelectorModel[];
    corporateBranchList: SelectorModel[];
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IErpProjectSetup;
    getErpProjectListByName: (search: string) => Promise<void>;
    getCorporateBranchListByName: (search: string) => Promise<void>;
}

export class ErpProjectSetupRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IErpProjectSetupScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private ExternalService: ExternalService;
    private ProductService: ProductService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IErpProjectSetupScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.ExternalService = $injector.get('ExternalService');
        this.ProductService = $injector.get('ProductService');
        this.helperService = $injector.get('HelperService'); 
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.config.fmsUrl + '/fms';
            this.$scope.customLogProperties = this.getCustomLogProperties();

            this.initForm(this, 'form', 'erpProjectSetup', 'GENERAL.MENU.ERP_PROJECT', true);
            await this.initGrid('gridErpProjectSetup', '/erpProjectSetup/list', true, true, null, true, true);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initScopeFunctions(): void {

        this.$scope.getCorporateBranchListByName = async (search: string) => {
            let corporateBranchList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                corporateBranchList = await this.getCorporateBranchListByName(search, this.$scope.model.PRODUCT);
            }
            this.$scope.corporateBranchList = corporateBranchList;
        }

        this.$scope.getErpProjectListByName = async (search: string) => {
            let erpProjectList: SelectorModel[] = [];
            if (search && search.length >= 2) erpProjectList = await this.getErpProjectListByName(search);
            this.$scope.erpProjectList = erpProjectList;
        }

    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        gridColumns.includeActionBar([
            GridColumnBuilderConstants.BTN_VIEW,
            GridColumnBuilderConstants.BTN_EDIT,
            GridColumnBuilderConstants.BTN_COPY,
            GridColumnBuilderConstants.BTN_VIEWLOG
        ], 25);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colCorporateBranch: IMonacoColumnDef = { name: 'CORPORATE_BRANCH.NAME', displayName: 'BASIC_DATA.BRANCH', width: 150 };
            const colProduct: IMonacoColumnDef = { name: 'PRODUCT.NAME', displayName: 'BASIC_DATA.PRODUCT', width: 200 };
            const colErpProject: IMonacoColumnDef = { name: 'ERP_PROJECT.NAME', displayName: 'REGISTRATION.PROJECT_ERP', width: 200 };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 150, cellFilter: "YesOrNo" };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'CORPORATE_BRANCH':
                        columnDefs.push(colCorporateBranch);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'ERP_PROJECT':
                        columnDefs.push(colErpProject);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            CORPORATE_BRANCH: null,
            PRODUCT: null,
            ERP_PROJECT: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            ACTIVE: true
        };
    }

    initDependencies(): Promise<any> {
        const self: ErpProjectSetupRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericList('product'),
            ]).then((result: any) => {
                self.$scope.productList = result[0]
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getGenericList(type: string, alternative: boolean = false): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}/${alternative}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async getErpProjectListByName(search: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const projects = await this.ExternalService.post({ route: "/sankhya/project/list", data: { search: search } });
            if (projects && projects.data) {
                result = projects.data.data
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCorporateBranchListByName(search?: string, product?: ISelectorModel): Promise<ILegalPersonSelector[]> {
        let result = [];
        this.formService.block();
        try {
            const corporateBranchs = await this.ProductService.post({ route: `/corporateBranch/list/custom`, data: { search: search, products: product ? [product.ID] : null } });
            if (corporateBranchs && corporateBranchs.data && corporateBranchs.data.data) result = corporateBranchs.data.data.map(corporateBranch => { return { ID: corporateBranch.ID, NAME: corporateBranch.NAME, CODE: corporateBranch.CODE } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
            BrowserTitle.$id = '';
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = '';
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === 'register' ? 'insert' : 'update';
        return {
            route: `/erpProjectSetup/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };

    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'CORPORATE_BRANCH',
                LABEL: 'BASIC_DATA.BRANCH'
            },
            {
                PROPERTY: 'PRODUCT',
                LABEL: 'BASIC_DATA.PRODUCT'
            },
            {
                PROPERTY: 'ERP_PROJECT',
                LABEL: 'REGISTRATION.PROJECT_ERP'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            }
        ];
        return props;
    }
}
import * as angular from "angular";
import * as moment from 'moment';
import { IColumnDef, IGridOptions } from "ui-grid";
import { IGridFormController, IGridFormServiceScope, GridFormService } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { ISessionService } from "@services/SessionService";
import { IProviderSelector } from "@models/interface/product/ProviderModel";
import { IApplicationList } from "@models/interface/product/ApplicationModel";
import { IUploader, IFormData } from "@models/interface/common/IMonacoUpload";
import { ICustomLogProperties } from "@models/interface/common/IViewLog";
import { EPaymentNatureId, EProviderTypeId, EOperation, EApplicationComplementId } from "@enums/GenericData";
import { ILinkParameter } from "../../common/model/ModelParameter";
import { ITariffLocalModel, ITariffLocalExchangeData } from "../model/TariffLocalModel";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { SelectorModel } from "../../common/model/SelectorModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { StringUtil } from "../../common/util/StringUtil";
import { IFloatingMenu } from "../../common/interface/IFloatingMenu";
import { fileUploader as FileUploader } from "angular-file-upload";
import { ITableOptions } from "src/ts/app/directives/monaco-data-table";
import { ICharge } from "../model/TariffLocalModel";

interface IChargeTableFilter {
    SHOW_ONLY_CURRENT_CHARGES: boolean;
    LOAD_REFERENCE: Date;
}

interface ITariffLocalHistoryScope extends IGridFormServiceScope {
    model: ITariffLocalModel;
    sessionService: ISessionService;
    uploader: IUploader;
    gridOptions: IGridOptions;
    menuFloating: IFloatingMenu;
    // charges
    chargesTableOptions: ITableOptions;
    chargesListDisplay: ICharge[];
    chargeTableControl: {
        data: ICharge[];
        chargeFilter: IChargeTableFilter;
    };
    showOnlyCurrentChargesChange: () => void;
    chargeFilterLoadReferenceChange: () => void;
    collapseHeader: (elementId: string, state?: string, navigate?: boolean) => void;
    generateConcatenated: () => void;
    viewTariffLocal: (tariffLocal: ITariffLocalModel, idTariffLocal?: number) => Promise<void>;
    getGridViewProvider: (providers: IProviderSelector[]) => string;
    copyToValidTariffs: (tariffFreight: ITariffLocalModel) => Promise<void>;
    // go to functions
    goToRoutingPoint: (ids: string) => void;
    goToProvider: () => void;
    goToNetwork: () => void;
    goToAgent: () => void;
    goToAccount: () => void;
    goToLegalPerson: (ids: string) => void;
    goToServiceType: (id: number) => void;
}

export class TariffLocalHistoryRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: ITariffLocalHistoryScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private collapseState: string;
    private formName: string;
    private gridName: string;
    private fileUploader: FileUploader;
    private $filter: ng.FilterFactory;

    constructor($injector: ng.Injectable<any>, $scope: ITariffLocalHistoryScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.formName = 'tariffLocalHistory';
        this.gridName = 'GRID_TARIFF_LOCAL_HISTORY';
        this.collapseState = "hide";
        this.fileUploader = $injector.get('FileUploader');
        this.$filter = $injector.get('$filter');
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    getFisFilesGenericRoute(): string {
        const baseRoute = '/fis/filesGeneric';
        const uploadRoute = this.config.fisUrl + baseRoute;
        return uploadRoute;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.uploader = this.getFileUploaderDefault();
            this.$scope.chargesTableOptions = this.getChargesTableOptions();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.chargeTableControl = { data: null, chargeFilter: { SHOW_ONLY_CURRENT_CHARGES: false, LOAD_REFERENCE: null } };
            this.$scope.$watch('model.CHARGE', (newValue: ICharge[], oldValue: ICharge[]) => {
                if (newValue != oldValue) {
                    this.$scope.chargeTableControl.data = angular.copy(newValue);
                    this.applyChargeFilterTable();
                }
            });

            this.initForm(this, 'form', this.formName, 'GENERAL.MENU.TARIFF_LOCAL', false);
            await this.initGrid(this.gridName, '/tariffLocalHistory/list', true, true, null, true, true);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initGridColumns(columns: string[]): IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<a ng-click="grid.appScope.viewTariffLocal(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true"><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyToValidTariffs(row.entity)" class="text-orange v-middle copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true"><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: 75,
            cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${copy}</div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };

        gridColumns.addColumn(colActions);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) {
            column.filter = column.filter
                ? column.filter
                : { condition: this.$gridService.filterSelectObject };

            gridColumns.addColumn(column)
        }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colSituation: IMonacoColumnDef = { name: "SITUATION.NAME", displayName: "GENERAL.SITUATION", width: 100 };
            const colConcatenated: IMonacoColumnDef = { name: "CONCATENATED", displayName: "GENERAL.CONCATENATED", width: 250 };
            const colVersion: IMonacoColumnDef = { name: "VERSION", displayName: "GENERAL.VERSION", width: 100 };
            const colPaymentNature: IMonacoColumnDef = { name: "PAYMENT_NATURE.NAME", displayName: "BASIC_DATA.TRANSACTION", width: 120 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 90 };
            const colDirection: IMonacoColumnDef = { name: "DIRECTION.NAME", displayName: "PRODUCT.WAY", width: 100 };
            const colCargoType: IMonacoColumnDef = { name: "TYPE_CARGO.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 130 };
            const colServiceType: IMonacoColumnDef = { name: "SERVICE_TYPE.NAME", displayName: "BASIC_DATA.SERVICE_TYPE", width: 130 };
            const colLocal: IMonacoColumnDef = { name: "LOCAL.DISPLAY_NAME", displayName: "GENERAL.LOCAL", width: 180, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.LOCAL, null, "DISPLAY_NAME")}}</div>' };
            const colProvider: IMonacoColumnDef = { name: "PROVIDERS.CODE", displayName: "BASIC_DATA.PROVIDER", width: 120, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getGridViewProvider(row.entity.PROVIDERS)}}</div>' };
            const colNetwork: IMonacoColumnDef = { name: "NETWORKS.NAME", displayName: "BASIC_DATA.EXTERNAL_AGENT_NETWORK", width: 160, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.NETWORKS, null, "NAME")}}</div>' };
            const colAgent: IMonacoColumnDef = { name: "LEGAL_PERSON_AGENTS.NAME", displayName: "BASIC_DATA.AGENT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.LEGAL_PERSON_AGENTS, null, "NAME")}}</div>' };
            const colAccount: IMonacoColumnDef = { name: "ACCOUNTS.NAME", displayName: "BASIC_DATA.CLIENT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNTS, null, "NAME")}}</div>' };
            const colAccountFantasyName: IMonacoColumnDef = { name: "ACCOUNTS.FANTASY_NAME", displayName: "GENERAL.CUSTOMER_COMMERCIAL_NAME", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNTS, null, "FANTASY_NAME")}}</div>' };
            const colExporter: IMonacoColumnDef = { name: "EXPORTERS.NAME", displayName: "BASIC_DATA.SHIPPER", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.EXPORTERS, null, "NAME")}}</div>' };
            const colExporterFantasyName: IMonacoColumnDef = { name: "EXPORTERS.FANTASY_NAME", displayName: "GENERAL.SHIPPER_COMMERCIAL_NAME", width: 210, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.EXPORTERS, null, "FANTASY_NAME")}}</div>' };
            const colImporter: IMonacoColumnDef = { name: "IMPORTERS.NAME", displayName: "BASIC_DATA.CONSIGNEE", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.IMPORTERS, null, "NAME")}}</div>' };
            const colImporterFantasyName: IMonacoColumnDef = { name: "IMPORTERS.FANTASY_NAME", displayName: "GENERAL.CNEE_COMMERCIAL_NAME", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.IMPORTERS, null, "FANTASY_NAME")}}</div>' };
            const colScore: IMonacoColumnDef = { name: "SCORE", displayName: "BASIC_DATA.SCORE", width: 100 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 80, cellFilter: "YesOrNo" };
            const colValidityExpired: IMonacoColumnDef = { name: "VALIDITY_EXPIRED", displayName: "GENERAL.EXPIRED", width: 150, cellFilter: "YesOrNo" };
            const colValidityStartContract: IMonacoColumnDef = { name: "DISPLAY_VALIDITY_START", displayName: "PRODUCT.VALIDITY_START", width: 150, cellFilter: "simpleDate" };
            const colValidityEndContract: IMonacoColumnDef = { name: "DISPLAY_VALIDITY_END", displayName: "PRODUCT.VALIDITY_END", width: 150, cellFilter: "simpleDate" };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'SITUATION':
                        columnDefs.push(colSituation);
                        break;
                    case 'CONCATENATED':
                        columnDefs.push(colConcatenated);
                        break;
                    case 'VERSION':
                        columnDefs.push(colVersion);
                        break;
                    case 'PAYMENT_NATURE':
                        columnDefs.push(colPaymentNature);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'DIRECTION':
                        columnDefs.push(colDirection);
                        break;
                    case 'TYPE_CARGO':
                        columnDefs.push(colCargoType);
                        break;
                    case 'SERVICE_TYPE':
                        columnDefs.push(colServiceType);
                        break;
                    case 'LOCAL':
                        columnDefs.push(colLocal);
                        break;
                    case 'PROVIDERS':
                        columnDefs.push(colProvider);
                        break;
                    case 'NETWORKS':
                        columnDefs.push(colNetwork);
                        break;
                    case 'LEGAL_PERSON_AGENTS':
                        columnDefs.push(colAgent);
                        break;
                    case 'ACCOUNTS':
                        columnDefs.push(colAccount, colAccountFantasyName);
                        break;
                    case 'EXPORTERS':
                        columnDefs.push(colExporter, colExporterFantasyName);
                        break;
                    case 'IMPORTERS':
                        columnDefs.push(colImporter, colImporterFantasyName);
                        break;
                    case 'DISPLAY_VALIDITY_START':
                        columnDefs.push(colValidityStartContract);
                        break;
                    case 'DISPLAY_VALIDITY_END':
                        columnDefs.push(colValidityEndContract);
                        break;
                    case 'SCORE':
                        columnDefs.push(colScore);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'VALIDITY_EXPIRED':
                        columnDefs.push(colValidityExpired);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            CONCATENATED: null,
            CONCATENATED_COMPLEMENT: null,
            VERSION: null,
            PAYMENT_NATURE: null,
            PRODUCT: null,
            DIRECTION: null,
            TYPE_CARGO: null,
            TYPE_LOCAL: null,
            LOCAL: null,
            PROVIDERS: null,
            NETWORKS: null,
            LEGAL_PERSON_AGENTS: null,
            ACCOUNTS: null,
            EXPORTERS: null,
            IMPORTERS: null,
            SERVICE_TYPE: null,
            CHARGE: null,
            OBSERVATION: null,
            SCORE: null,
            SCORE_RULE: null,
            SCORE_DATE: null,
            SCORE_ERROR: null,
            VALIDITY_START: null,
            DISPLAY_VALIDITY_START: null,
            VALIDITY_END: null,
            DISPLAY_VALIDITY_END: null,
            EVENT: null,
            FREIGHT_BASIS: true,
            FILES: null,
            FILES_REMOVE: null,
            FILES_NEW: null,
            SITUATION: null,
            DETAILS: null,
            ACTIVE: true,
            VALIDITY_EXPIRED: true,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            DOCUMENT_ERROR: null
        };
    }

    initScopeFunctions(): void {
        this.$scope.viewTariffLocal = async (tariffLocal: ITariffLocalModel): Promise<void> => {
            this.fetchData(tariffLocal.ID, EOperation.VIEW);
        }

        this.$scope.copyToValidTariffs = async (tariff: ITariffLocalModel): Promise<void> => {
            this.$scope.sessionService.openTab('app.product.tariffLocal', null, <ITariffLocalExchangeData>{ OPERATION: "register", ID: tariff.ID ? tariff.ID.toString() : null });
        }

        this.$scope.getGridViewProvider = (providers: IProviderSelector[]): string => {
            const providerList: SelectorModel[] = [];
            if (providers) {
                for (const provider of providers) {
                    if (provider.TYPE) providerList.push({ ID: provider.ID, NAME: provider.TYPE.ID == EProviderTypeId.SHIPOWNER || provider.TYPE.ID == EProviderTypeId.AIRLINE ? provider.CODE : provider.NAME });
                }
            }
            return this.$scope.getCONCAT(providerList);
        }

        this.$scope.collapseHeader = (elementId: string, state?: string, navigate?: boolean): void => {
            this.collapseHeader(elementId, state, navigate);
        }

        this.$scope.generateConcatenated = (): void => {
            this.generateConcatenated();
        }

        this.$scope.goToRoutingPoint = (ids: string): void => {
            this.$scope.sessionService.openTab("app.registration.routingPoint", <ILinkParameter>{ ID: ids });
        }

        this.$scope.goToProvider = (): void => {
            this.$scope.sessionService.openTab("app.registration.provider", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.PROVIDERS, null, 'ID') });
        }

        this.$scope.goToNetwork = (): void => {
            this.$scope.sessionService.openTab("app.registration.network", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.NETWORKS, null, 'ID') });
        }

        this.$scope.goToAgent = (): void => {
            this.$scope.sessionService.openTab("app.registration.agent", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.LEGAL_PERSON_AGENTS, null, 'ID') });
        }

        this.$scope.goToAccount = (): void => {
            this.$scope.sessionService.openTab("app.commercial.account", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.ACCOUNTS, null, 'ID') });
        }

        this.$scope.goToLegalPerson = (ids: string): void => {
            this.$scope.sessionService.openTab("app.registration.legalPerson", <ILinkParameter>{ ID: ids });
        }

        this.$scope.goToServiceType = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.servicetype", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.showOnlyCurrentChargesChange = () => {
            if (this.$scope.chargeTableControl && this.$scope.chargeTableControl.chargeFilter) {
                this.$scope.chargeTableControl.chargeFilter.LOAD_REFERENCE = new Date();
                this.applyChargeFilterTable();
            }
        }

        this.$scope.chargeFilterLoadReferenceChange = () => {
            const loadReference = moment(this.$scope.chargeTableControl.chargeFilter.LOAD_REFERENCE, 'DD/MM/YYYY');
            if (!this.$scope.chargeTableControl.chargeFilter.LOAD_REFERENCE || loadReference.isValid()) this.applyChargeFilterTable();
        }
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.getTariffLocalHistoryById(id);

            if (request && request.data) {
                const model = angular.copy(request.data);

                this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.disableElements(true);
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
            BrowserTitle.$id = this.$scope.model.CONCATENATED;
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
            }
            this.initPanels(['collapseBasicData']);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            await this.clearFields(this.$scope.model, ['CONCATENATED', 'SCORE', 'SCORE_DATE', 'SCORE_RULE', 'SCORE_ERROR']);
            if (this.$scope.model.CHARGE && this.$scope.model.CHARGE.length > 0) {
                for (const charge of this.$scope.model.CHARGE) {
                    charge.ID = null;
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private initPanels = (panelsToShow?: string[], panelToNavigate?: string) => {
        try {
            const panels = document.getElementsByClassName("toggle-me");
            if (panels) {
                for (let i = 0; i < panels.length; i++) {
                    const panel = panels[i];
                    if (panelsToShow && panelsToShow.findIndex(obj => obj === panel.id) >= 0) {
                        if (!panel.classList.contains('in')) {
                            $("#" + panel.id)["collapse"]("show");
                        }
                    } else if (panel.classList.contains('in')) {
                        $("#" + panel.id)["collapse"]("hide");
                    }
                }
            }
            if (panelToNavigate) this.$scope.navigateBetweenIds(panelToNavigate);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getTariffLocalHistoryById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();

        try {
            const result = await this.RestService.getObjectAsPromise(
                `${this.getUrlProduct()}/tariffLocalHistory/getById/${id}`,
                30000,
                null,
                false
            );

            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private collapseHeader(elementId: string, state?: string, navigate?: boolean): void {
        if (elementId === "collapseAll") {
            this.collapseState = this.collapseState == "hide" ? "show" : "hide";
            $('.toggle-me')["collapse"](state ? state : this.collapseState);
        } else if (elementId != "registerBody") {
            $("#" + elementId)["collapse"](state ? state : 'toggle');
        }
        if (navigate && state != "hide") this.$scope.navigateBetweenIds(elementId);
    }

    private generateConcatenated(): void {
        try {
            const separator = " | ";
            let concatenated = "";

            if (this.$scope.model.ACCOUNTS && this.$scope.model.ACCOUNTS.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.ACCOUNTS.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(this.$scope.model.ACCOUNTS.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.PROVIDERS && this.$scope.model.PROVIDERS.length) {
                concatenated = concatenated.length > 0 ?
                    concatenated.concat(separator, this.$scope.model.PROVIDERS.map(obj => { if (obj.TYPE && (obj.TYPE.ID == EProviderTypeId.SHIPOWNER || obj.TYPE.ID == EProviderTypeId.AIRLINE)) return obj.CODE; else return obj.NAME; }).join(',')) :
                    concatenated.concat(this.$scope.model.PROVIDERS.map(obj => { if (obj.TYPE && (obj.TYPE.ID == EProviderTypeId.SHIPOWNER || obj.TYPE.ID == EProviderTypeId.AIRLINE)) return obj.CODE; else return obj.NAME; }).join(','));
            }

            if (this.$scope.model.NETWORKS && this.$scope.model.NETWORKS.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.NETWORKS.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(this.$scope.model.NETWORKS.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.LEGAL_PERSON_AGENTS && this.$scope.model.LEGAL_PERSON_AGENTS.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.LEGAL_PERSON_AGENTS.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(this.$scope.model.LEGAL_PERSON_AGENTS.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.IMPORTERS && this.$scope.model.IMPORTERS.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.IMPORTERS.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(this.$scope.model.IMPORTERS.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.EXPORTERS && this.$scope.model.EXPORTERS.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.EXPORTERS.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(this.$scope.model.EXPORTERS.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID && this.$scope.model.PAYMENT_NATURE.ID.length > 0) {
                let paymentNature = null;
                if (this.$scope.model.PAYMENT_NATURE.ID === EPaymentNatureId.PAYMENT) {
                    paymentNature = 'PGTO';
                } else if (this.$scope.model.PAYMENT_NATURE.ID === EPaymentNatureId.RECEIVING) {
                    paymentNature = 'RCTO';
                }
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, paymentNature) : concatenated.concat(paymentNature);
            }

            if (this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.ID && this.$scope.model.PRODUCT.ID.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PRODUCT.ID) : concatenated.concat(this.$scope.model.PRODUCT.ID);
            }

            if (this.$scope.model.DIRECTION && this.$scope.model.DIRECTION.NAME && this.$scope.model.DIRECTION.NAME.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.DIRECTION.NAME) : concatenated.concat(this.$scope.model.DIRECTION.NAME);
            }

            if (this.$scope.model.TYPE_CARGO && this.$scope.model.TYPE_CARGO.NAME && this.$scope.model.TYPE_CARGO.NAME.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.TYPE_CARGO.NAME) : concatenated.concat(this.$scope.model.TYPE_CARGO.NAME);
            }

            if (this.$scope.model.LOCAL && this.$scope.model.LOCAL.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.LOCAL.map(obj => { return obj.CODE }).join(',')) : concatenated.concat(this.$scope.model.LOCAL.map(obj => { return obj.CODE }).join(','));
            }

            if (this.$scope.model.CONCATENATED_COMPLEMENT && this.$scope.model.CONCATENATED_COMPLEMENT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CONCATENATED_COMPLEMENT) : concatenated.concat(this.$scope.model.CONCATENATED_COMPLEMENT);
            }

            if (this.$scope.model.VERSION) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, 'V' + this.$scope.model.VERSION) : concatenated.concat('V' + this.$scope.model.VERSION);
            }

            if (this.$scope.model.DISPLAY_VALIDITY_START) {
                const validity = this.$scope.model.DISPLAY_VALIDITY_END ? moment(this.$scope.model.DISPLAY_VALIDITY_START).format('DD/MM/YY') + '-' + moment(this.$scope.model.DISPLAY_VALIDITY_END).format('DD/MM/YY') : moment(this.$scope.model.DISPLAY_VALIDITY_START).format('DD/MM/YY');
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, validity) : concatenated.concat(validity);
            }

            this.$scope.model.CONCATENATED = StringUtil.formatConcatenatedChars(concatenated);

            if (this.$scope.menuFloating) this.$scope.menuFloating.infos = [{ text: `${this.$scope.model.CONCATENATED}`, class: "text-rouge font-bold" }];
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getMenuFloatingDefault(): IFloatingMenu {
        return {
            tooltipPlacement: "auto bottom",
            textTooltip: "PRODUCT.TARIFF_DATA",
            infos: [
                { text: "Tarifário", class: "text-rouge font-bold" }
            ],
            btnActiveDisabled: false
        };
    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'tariff_files', formName: this.formName, folder: this.formName };
            return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData] });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getChargeApplicationComplementValue(applicationComplement: IApplicationList, charge: ICharge): string {
        let complementValue: string = "";
        if (applicationComplement.ID == EApplicationComplementId.EQUIPMENT) complementValue = this.$scope.getCONCAT(charge.EQUIPMENT, null, "CODE");
        else if (applicationComplement.ID == EApplicationComplementId.WEIGHT_RANGE) complementValue = this.$scope.getCONCAT(charge.WEIGHT_RANGE);
        else if (applicationComplement.ID == EApplicationComplementId.VEHICLE) complementValue = this.$scope.getCONCAT(charge.VEHICLE_TYPE);
        return complementValue;
    }

    private applyChargeFilterTable() {
        if (this.$scope.model) {
            let chargeList = angular.copy(this.$scope.model.CHARGE);
            const loadReference = moment(moment(this.$scope.chargeTableControl.chargeFilter.LOAD_REFERENCE).format('YYYY-MM-DD'));
            if (loadReference.isValid() && this.$scope.chargeTableControl.chargeFilter.SHOW_ONLY_CURRENT_CHARGES) {
                chargeList = chargeList.filter(charge => {
                    const start = moment(moment(charge.VALIDITY_START).format('YYYY-MM-DD'));
                    const end = moment(moment(charge.VALIDITY_END).format('YYYY-MM-DD'));
                    return (loadReference.isBetween(start, end) || loadReference.isSame(start) || loadReference.isSame(end));
                });
            }
            this.$scope.chargeTableControl.data = chargeList;
            this.$scope.chargesTableOptions.load(null, true);
        }
    }

    private getChargesTableOptions(): ITableOptions {
        return {
            persistName: "tariffLocalCharges",
            pagination: false,
            search: true,
            advancedSearch: false,
            showSearchClearButton: true,
            clickToSelect: false,
            singleSelect: false,
            showLoading: true,
            showColumns: true,
            showColumnsSearch: true,
            showColumnsToggleAll: true,
            showFullscreen: true,
            showToggle: false,
            showMultiSort: true,
            showMultiSortButton: true,
            height: '100%',
            filterControl: false,
            fixedColumns: true,
            fixedNumber: 3,
            sortable: true,
            serverSort: false,
            formatAddLevel: () => this.formService.getTranslate("GENERAL.ADD_LEVEL"),
            formatCancel: () => this.formService.getTranslate("GENERAL.CLOSE"),
            formatColumn: () => this.formService.getTranslate("GENERAL.COLUMNS"),
            formatDeleteLevel: () => this.formService.getTranslate("GENERAL.REMOVE_LEVEL"),
            formatMultipleSort: () => this.formService.getTranslate("GENERAL.MULTIPLE_SORT"),
            formatOrder: () => this.formService.getTranslate("GENERAL.ORDER"),
            formatSort: () => this.formService.getTranslate("GENERAL.SORT"),
            formatSortBy: () => this.formService.getTranslate("GENERAL.SORT_BY"),
            formatSortOrders: () => { return { asc: this.formService.getTranslate("GENERAL.ASCENDING_SORT"), desc: this.formService.getTranslate("GENERAL.DESCENDING_SORT") } },
            formatThenBy: () => this.formService.getTranslate("GENERAL.AND_BY"),
            headerStyle: (column: BootstrapTableColumn) => {
                let headerStyleObj = {};
                if (column.field.search("PAYMENT") >= 0) headerStyleObj = { css: { 'background': "#f2dede" } };
                else if (column.field.search("RECEIVING") >= 0) headerStyleObj = { css: { 'background': "#dff0d8" } };
                return headerStyleObj;
            },
            showColumnsRename: [
                { dataField: "CHARGE_NAME_EXHIBITION", title: this.formService.getTranslate("BASIC_DATA.CHARGE") },
                { dataField: "APPLICATION", title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS") },
                { dataField: "APPLICATION.APPLICATION_COMPLEMENT", title: this.formService.getTranslate("PRODUCT.BASIS_COMPLEMENT") },
                { dataField: "TARIFF_LOCAL_PAYMENT.CURRENCY", title: this.formService.getTranslate("GENERAL.CURRENCY") },
                { dataField: "TARIFF_LOCAL_PAYMENT.UNITARY", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY_C") },
                { dataField: "TARIFF_LOCAL_PAYMENT.MIN", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM_C") },
                { dataField: "TARIFF_LOCAL_RECEIVING.CURRENCY", title: this.formService.getTranslate("GENERAL.CURRENCY") },
                { dataField: "TARIFF_LOCAL_RECEIVING.UNITARY", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY_V") },
                { dataField: "TARIFF_LOCAL_RECEIVING.MIN", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM_V") },
                { dataField: "VALIDITY_START", title: this.formService.getTranslate("PRODUCT.VALIDITY_START") },
                { dataField: "VALIDITY_END", title: this.formService.getTranslate("PRODUCT.VALIDITY_END") },
                { dataField: "EVENT", title: this.formService.getTranslate("GENERAL.VALIDITY_BASIS") },
                { dataField: "RATE_TYPE", title: this.formService.getTranslate("GENERAL.TYPE") }
            ],
            onPostBody: (chargeList: ICharge[]) => {
                for (const index in chargeList) { chargeList[index].DISPLAY_INDEX = parseInt(index); };
                this.$scope.chargesListDisplay = chargeList;
                return true;
            },
            columns: [
                { field: 'CHARGE_NAME_EXHIBITION', title: this.formService.getTranslate("BASIC_DATA.CHARGE"), formatter: (data) => data ? `${data.CODE} - ${data.NAME}` : data, sortable: true, searchable: true },
                { field: 'APPLICATION', title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'APPLICATION.APPLICATION_COMPLEMENT', title: this.formService.getTranslate("PRODUCT.BASIS_COMPLEMENT"), formatter: (application: IApplicationList, charge: ICharge) => this.getChargeApplicationComplementValue(application, charge), sortable: true, searchable: true },
                { field: 'TARIFF_LOCAL_PAYMENT.CURRENCY', title: this.formService.getTranslate("GENERAL.CURRENCY"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'TARIFF_LOCAL_PAYMENT.UNITARY', title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'TARIFF_LOCAL_PAYMENT.MIN', title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'TARIFF_LOCAL_RECEIVING.CURRENCY', title: this.formService.getTranslate("GENERAL.CURRENCY"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'TARIFF_LOCAL_RECEIVING.UNITARY', title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'TARIFF_LOCAL_RECEIVING.MIN', title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'VALIDITY_START', title: this.formService.getTranslate("PRODUCT.VALIDITY_START"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true },
                { field: 'VALIDITY_END', title: this.formService.getTranslate("PRODUCT.VALIDITY_END"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true },
                { field: 'EVENT', title: this.formService.getTranslate("GENERAL.VALIDITY_BASIS"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true },
                { field: 'RATE_TYPE', title: this.formService.getTranslate("GENERAL.TYPE"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true }
            ],
        };
    }
}

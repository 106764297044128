import * as angular from "angular";
import { NotificationConstants } from "../../common/util/NotificationConstants";
import { StringUtil } from "../../common/util/StringUtil";

monacoInsertOption.$inject = ['$timeout', '$compile']
export function monacoInsertOption($timeout, $compile) {
    let ddo: any = {};

    ddo.restrict = "A";
    ddo.removeSpecialChars = "="

    ddo.link = (scope, el, attrs) => {

        const hideInChoices: boolean = angular.isDefined(attrs.hideInChoices);

        $timeout(function () {
            if (!hideInChoices) {
                const templateChoices = `<li class="custom-insert-choices"></li>`;
                el.find('ul.ui-select-choices').append(templateChoices);
            }
            const templateNoChoices = `<li class="custom-insert-no-choices"></li>`;
            el.find('ul.ui-select-no-choice').append(templateNoChoices);
        });

        scope.$watch('$select.search', (newValue: any, oldValue: any) => {
            if (attrs.removeSpecialChars !== undefined) {
                newValue = StringUtil.removeSpecialChars(newValue);
                scope.$select.search = newValue;
            }
            const optionChoice = `<div class="custom-insert-option ui-select-choices-row"><span class="ui-select-choices-row-inner link ellipsis compile">{{'${NotificationConstants.INSERT_SELECTOR_MSG}' | translate}} <b>${newValue}</b></span> </div>`;
            el.find('li.custom-insert-choices').html(optionChoice);
            const optionNoChoice = `<div class="custom-insert-option"><span class="link ellipsis compile">{{'${NotificationConstants.INSERT_SELECTOR_MSG}' | translate}} <b>${scope.$select.search}</b></span> </div>`;
            el.find('li.custom-insert-no-choices').html(optionNoChoice);

            if (newValue == "") el.find('li.custom-insert-no-choices').html('');

            el.find('div.custom-insert-option').bind('click', function () {
                if (attrs.fnInsertShortcut && scope.$parent[attrs.fnInsertShortcut]) scope.$parent[attrs.fnInsertShortcut].apply(null, [newValue]);
            });

            const elementsToCompile = angular.element(".compile");
            if (elementsToCompile) $compile(elementsToCompile)(scope);
        });
    }

    return ddo;
}

import * as angular from 'angular';

const taskState = {
    name: 'app.task',
    url: '/task',
    abstract: true,
    data: {
        requiresAuth: false,
    }
}

const taskMenutState = {
    name: 'app.task.menu',
    url: '/menu',
    views: {
        'content@app': {
            template: require("./view/taskMenu.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    ncyBreadcrumb: {
        label: 'Atividades',
        parent: 'app.task'
    }
}

const oldTaskListState = {
    name: 'app.task.old',
    url: '/old',
    views: {
        'content@app': {
            template: require("./view/oldTaskList.html")
        },
    },
    ncyBreadcrumb: {
        label: 'Agenda de Atividades (Antiga)',
        parent: 'app.task.menu'
    }
}

routeConfig.$inject = ['$uiRouterProvider'];
function routeConfig($uiRouterProvider) {
    const $stateRegistry = $uiRouterProvider.stateRegistry;

    $stateRegistry.register(taskState);
    $stateRegistry.register(taskMenutState);
    $stateRegistry.register(oldTaskListState);
}

export default function initRoute(moduleName: string): void {
    angular.module(moduleName).config(routeConfig);
}
import * as angular from 'angular';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from '@services/GridFormService';
import { IRestService } from '@services/RestService';
import { IMonacoColumnDef } from '@services/GridService2';
import { IViewLog, ICustomLogProperties } from '@models/interface/common/IViewLog';
import { NotificationType } from '@models/interface/integration/NotificationType';
import { SelectorModel } from '../../common/model/SelectorModel';
import { GridColumnBuilder } from '../../common/GridColumnBuilder';
import { ISessionService } from '@services/SessionService';
import { SSEService } from '@appServices/SSEService';
import { EOperation } from '@enums/GenericData';
import { HelperService } from "@services/HelperService";

interface INotificationTypeScope extends IGridFormServiceScope {
    model: NotificationType;
    scopeBeforeSave: NotificationType;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    colorList: string[];
    iconList: string[];
    user: any;
    sessionService: ISessionService;
    editNotificationType: (notificationType: NotificationType) => Promise<void>;
    viewNotificationType: (notificationType: NotificationType) => Promise<void>;
    viewLogNotificationType: (notificationType: NotificationType) => Promise<void>;
    copyNotificationType: (notificationType: NotificationType) => Promise<void>;
}

export class NotificationTypeRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: INotificationTypeScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private SSEService: SSEService;
    private gridName: string;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: INotificationTypeScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$baseUrl = this.config.externalUrl + '/external';
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope);
        this.gridName = 'GRID_NOTIFICATION_TYPE';
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'notificationType', 'GENERAL.MENU.NOTIFICATION_TYPE', true);
            await this.initGrid(this.gridName, '/notificationType/list', true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editNotificationType = async (notificationType: NotificationType): Promise<void> => {
            let blockedObject = {
                ID: notificationType.ID,
                NAME: notificationType.NAME,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || notificationType.ID !== this.$scope.model.ID) this.$scope.view(notificationType);
                } else if (this.$scope.operation !== EOperation.EDIT || notificationType.ID !== this.$scope.model.ID) {
                    this.$scope.edit(notificationType);
                }
            };
        }

        this.$scope.viewNotificationType = async (notificationType: NotificationType): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(notificationType);
        }

        this.$scope.viewLogNotificationType = async (notificationType: NotificationType): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(notificationType);
        }

        this.$scope.copyNotificationType = async (notificationType: NotificationType): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(notificationType);
        }
    }

    initDependencies(): Promise<any> {
        const self: NotificationTypeRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getIconList(),
                self.getColorList()
            ]).then((result: any) => {
                self.$scope.iconList = result[0].map((icon) => { return icon.ID; });
                self.$scope.colorList = result[1].map((color) => { return color.ID; });
                resolve(true);
            }).catch((ex) => {
                reject(ex);
            });
        });
    }

    private async getIconList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/notificationTypeIcon`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getColorList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/notificationTypeColor`, null);
        return generic && generic.data ? generic.data : [];
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewNotificationType(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editNotificationType(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogNotificationType(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyNotificationType(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy),
            enableCellEdit: true,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };

        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colDescription: IMonacoColumnDef = { name: 'NAME', displayName: 'GENERAL.DESCRIPTION', width: 400, field: 'NAME' };
            const colIcon: IMonacoColumnDef = {
                name: 'ICON',
                displayName: 'REGISTRATION.NOTIFICATION_ICON',
                width: 150,
                field: 'ICON',
                cellTemplate: '<div class="ui-grid-cell-contents ng-binding ng-scope">{{row.entity.ICON}} <i class="fa fa-{{row.entity.ICON}}" aria-hidden="true"></i></div>',
            };
            const colColor: IMonacoColumnDef = {
                name: 'COLOR',
                displayName: 'REGISTRATION.NOTIFICATION_COLOR',
                width: 150,
                field: 'COLOR',
                cellTemplate: '<div class="ui-grid-cell-contents ng-binding ng-scope">{{row.entity.COLOR}}&nbsp;<i class="fa fa-circle text-{{row.entity.COLOR}}" aria-hidden="true"></div>',
            };
            const colNotifyCell: IMonacoColumnDef = { name: 'NOTIFY_CELL', displayName: 'REGISTRATION.NOTIFY_CELL', width: 200, cellFilter: 'YesOrNo' };
            const colNotifyUser: IMonacoColumnDef = { name: 'NOTIFY_USER', displayName: ' REGISTRATION.NOTIFY_USER', width: 200, cellFilter: 'YesOrNo' };
            const colNofityErrorOnly: IMonacoColumnDef = { name: 'NOTIFY_ERROR_ONLY', displayName: 'REGISTRATION.NOTIFY_ERROR_ONLY', width: 200, cellFilter: 'YesOrNo' };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'NAME':
                        columnDefs.push(colDescription);
                        break;
                    case 'ICON':
                        columnDefs.push(colIcon);
                        break;
                    case 'COLOR':
                        columnDefs.push(colColor);
                        break;
                    case 'NOTIFY_CELL':
                        columnDefs.push(colNotifyCell);
                        break;
                    case 'NOTIFY_USER':
                        columnDefs.push(colNotifyUser);
                        break;
                    case 'NOTIFY_ERROR_ONLY':
                        columnDefs.push(colNofityErrorOnly);
                        break;
                }
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            NAME: null,
            ICON: null,
            COLOR: null,
            NOTIFY_CELL: false,
            NOTIFY_USER: false,
            NOTIFY_ERROR_ONLY: false,
        };
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} (${this.$scope.model.NAME})`;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} (${this.$scope.model.NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === 'edit' ? 'update' : 'insert';
        const errorMsg = this.formService.getTranslate("REGISTRATION.NOTIFICATION_MESSAGE");
        if (!this.$scope.model.NOTIFY_CELL &&
            !this.$scope.model.NOTIFY_ERROR_ONLY &&
            !this.$scope.model.NOTIFY_USER) throw Error(errorMsg);

        return {
            route: `/NotificationType/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: this.$scope.scopeBeforeSave,
        };


    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.DESCRIPTION',
            },
            {
                PROPERTY: 'ICON',
                LABEL: 'REGISTRATION.NOTIFICATION_ICON',
            },
            {
                PROPERTY: 'COLOR',
                LABEL: 'REGISTRATION.NOTIFICATION_COLOR',
            },
            {
                PROPERTY: 'NOTIFY_CELL',
                LABEL: 'REGISTRATION.NOTIFY_CELL',
            },
            {
                PROPERTY: 'NOTIFY_USER',
                LABEL: 'REGISTRATION.NOTIFY_USER',
            },
            {
                PROPERTY: 'NOTIFY_ERROR_ONLY',
                LABEL: 'REGISTRATION.NOTIFY_ERROR_ONLY',
            },
        ];
        return props;
    }
}

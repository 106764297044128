import angular = require("angular");
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IMonacoColumnDef } from '@services/GridService2';
import { IServiceLevelModel } from "@models/interface/product/ServiceLevelModel";
import { IRestService } from "@services/RestService";
import { SelectorModel } from "../../common/model/SelectorModel";
import { ISessionService } from "@services/SessionService";
import { ILinkParameter } from "../../common/model/ModelParameter";
import { BrowserTitle } from "../../common/BrowserTitle";
import { EProductId, EProviderTypeId, EOperation } from "@enums/GenericData";
import { SSEService } from '@appServices/SSEService';
import { IDocumentError } from "WBA-Model/dist/interface/common/IDocumentError";
import { IModalService } from '@services/ModalService';
import { IGridOptions } from "ui-grid";
import { HelperService } from "@services/HelperService";

interface IServiceLevelScope extends IGridFormServiceScope {
    model: IServiceLevelModel;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IServiceLevelModel;
    providerList: Array<SelectorModel>;
    productList: Array<SelectorModel>;
    user: any;
    sessionService: ISessionService;
    gridOptions: IGridOptions;
    editServiceLevel: (serviceLevel: IServiceLevelModel) => Promise<void>;
    viewServiceLevel: (serviceLevel: IServiceLevelModel) => Promise<void>;
    viewLogServiceLevel: (serviceLevel: IServiceLevelModel) => Promise<void>;
    copyServiceLevel: (serviceLevel: IServiceLevelModel) => Promise<void>;
    getProviderListByName: (search: string) => Promise<void>;
    goToProvider: (id: number) => void;
    openModalIntegration: (id: number, documentErrorList: IDocumentError[], name: string) => void;
}

export class ServiceLevelRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IServiceLevelScope;
    private RestService: IRestService;
    private $q: ng.IQService;
    private sessionService: ISessionService;
    private SSEService: SSEService;
    private gridName: string;
    private modalID: number;
    private ModalService: IModalService;
    private $timeout: ng.ITimeoutService
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IServiceLevelScope) {
        super($injector, $scope);
        this.RestService = $injector.get('RestService');
        this.$q = $injector.get("$q");
        this.$scope = $scope;
        this.$scope.sessionService = $injector.get('SessionService');
        this.$timeout = $injector.get('$timeout');     
        this.SSEService = new SSEService($injector, $scope);
        this.gridName = 'gridServiceLevel';
        this.ModalService = $injector.get('ModalService');
        this.modalID = null;
        this.helperService = $injector.get('HelperService');
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editServiceLevel = async (serviceLevel: IServiceLevelModel): Promise<void> => {
            let blockedObject = {
                ID: serviceLevel.ID,
                NAME: serviceLevel.DISPLAY_NAME,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || serviceLevel.ID !== this.$scope.model.ID) this.$scope.view(serviceLevel);
                } else if (this.$scope.operation !== EOperation.EDIT || serviceLevel.ID !== this.$scope.model.ID) {
                    this.$scope.edit(serviceLevel);
                }
            };
        }

        this.$scope.viewServiceLevel = async (serviceLevel: IServiceLevelModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(serviceLevel);
        }

        this.$scope.viewLogServiceLevel = async (serviceLevel: IServiceLevelModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(serviceLevel);
        }

        this.$scope.copyServiceLevel = async (serviceLevel: IServiceLevelModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(serviceLevel);
        }

        this.$scope.getProviderListByName = async (search: string): Promise<void> => {
            let providerList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                providerList = await this.getProviderListByName(search);
            }
            this.$scope.providerList = providerList;
        }
        this.$scope.goToProvider = (id: number): void => {
            this.sessionService.openTab("app.registration.provider", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.openModalIntegration = (id: number, documentErrorList: IDocumentError[]) => {
            this.openModalIntegration(id, documentErrorList);
        }
    };

    private async getProviderListByName(name: string): Promise<SelectorModel[]> {
        try {
            if (!this.$scope.model.PRODUCT) {
                throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
            }
            let result = [];
            if (name && name.length >= 3) {
                this.formService.block();
                const providers = await this.RestService.newObjectPromise(`${this.$baseUrl}/provider/list/custom`, { types: [EProviderTypeId.AIRLINE], search: name }, 30000, false);
                if (providers) result = providers.map(provider => { return { ID: provider.ID, NAME: provider.NAME, CODE: provider.SCAC_IATA } });
            }
            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    async $onInit?(): Promise<void> {
        try {
            this.$baseUrl = this.config.productUrl + '/product';
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.$scope.providerList = [];
            this.initForm(this, "form", "servicelevel", "GENERAL.MENU.SERVICE_LEVEL", true);
            await this.initGrid('gridServiceLevel', '/servicelevel/list', true, true, null, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initModel(): void {
        try {
            this.$scope.model = {
                _id: null,
                ID: null,
                ACTIVE: true,
                PRODUCT: null,
                NAME: null,
                DISPLAY_NAME: null,
                PROVIDER: null,
                ID_PROVIDER: null,
                CREATED_AT: null,
                CREATED_BY: null,
                UPDATED_AT: null,
                UPDATED_BY: null,
                SEARCH_FIELDS: null,
                DOCUMENT_ERROR: null
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        try {
            const gridColumns = new GridColumnBuilder([]);

            const view = `<a ng-click="grid.appScope.viewServiceLevel(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true"><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
            const edit = `<a ng-click="grid.appScope.editServiceLevel(row.entity)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true"><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
            const viewLog = `<a ng-click="grid.appScope.viewLogServiceLevel(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true"><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
            const copy = `<a ng-click="grid.appScope.setCopy(true);grid.appScope.copyServiceLevel(row.entity)" class="text-orange copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true"><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
            const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

            const colActions: IMonacoColumnDef = {
                name: "acoes", displayName: "GENERAL.ACTIONS",
                width: 175,
                cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${viewLog} ${copy} ${modalIntegration}</div>`,
                enableFiltering: false,
                enableSorting: false,
                enableHiding: false,
                enableColumnMoving: false,
                enableColumnResizing: false,
                pinnedLeft: true,
                enablePinning: false
            };

            gridColumns.addColumn(colActions);

            const newColumnDefs = this.buildColumns(columns);
            for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

            return gridColumns.$columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private buildColumns(columns: Array<string>): Array<IMonacoColumnDef> {
        try {
            let gridColumns: Array<IMonacoColumnDef> = [];

            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 70, cellFilter: "YesOrNo" };
            const colProviderName: IMonacoColumnDef = { name: "PROVIDER.NAME", displayName: "BASIC_DATA.PROVIDER", width: 350, cellTemplate: '<div class="grid-padding" >{{row.entity.PROVIDER.CODE}}</div>' };
            const colServiceName: IMonacoColumnDef = { name: "DISPLAY_NAME", displayName: "GENERAL.NAME_INTL", width: 300 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT", displayName: "BASIC_DATA.PRODUCT", cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PRODUCT, null, "ID")}}</div>', width: 150 };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            columns.forEach(column => {
                switch (column) {
                    case "PROVIDER":
                        gridColumns.push(colProviderName);
                        break;
                    case "DISPLAY_NAME":
                        gridColumns.push(colServiceName);
                        break;
                    case "PRODUCT":
                        gridColumns.push(colProduct);
                        break;
                    case "ACTIVE":
                        gridColumns.push(colActive);
                        break;
                    case "ID":
                        gridColumns.push(colId);
                        break;
                    case 'CREATED_AT':
                        gridColumns.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        gridColumns.push(colUpdatedAt);
                        break;
                }
            })

            return gridColumns;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initDependencies(): Promise<any> {
        try {
            const self: ServiceLevelRegisterController = this;

            return new Promise(function (resolve, reject) {
                self.$q.all([self.getProductList()]).then((result: any) => {
                    self.$scope.productList = result[0].map((x) => {
                        if (x.ID === EProductId.AIR_EXPORT || x.ID === EProductId.AIR_IMPORT) return { ID: x.ID, NAME: x.NAME, CODE: null }
                    });
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('REGISTRATION.NEW_SERVICE_LEVEL');
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} (${this.$scope.model.DISPLAY_NAME})`;
            BrowserTitle.$id = this.$scope.model.DISPLAY_NAME;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} (${this.$scope.model.DISPLAY_NAME})`;
            BrowserTitle.$id = this.$scope.model.DISPLAY_NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        let proceed: boolean = true;
        try {
            this.SSEService.closeEvents();
            return proceed;
        } catch (ex) {
            this.handleError(ex);
            return false;
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        try {
            const route = this.$scope.operation === 'register' ? 'insert' : 'update';
            return {
                route: `/servicelevel/${route}`,
                data: angular.copy(this.$scope.model),
                oldData: angular.copy(this.$scope.scopeBeforeSave),
                timeout: 15000
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "Integration Product/Operation" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                this.formService.block();
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/servicelevel/sendSync`, { "idServiceLevel": id }, 120000, false);
                if (syncRequest) success = true;
            }
        } catch (ex) {
            this.formService.handleError(`${this.formService.getTranslate("REGISTRATION.MESSAGES.ERROR.ERROR_SENDING_REQUEST")}`);
        } finally {
            this.formService.unblock();
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const serviceLevelData = await this.getServiceLevelById(id);
                    if (row && serviceLevelData && serviceLevelData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = serviceLevelData.DOCUMENT_ERROR;
                        documentError = serviceLevelData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async getServiceLevelById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/servicelevel/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private getCustomLogProperties(): Array<ICustomLogProperties> {
        try {
            const props: Array<ICustomLogProperties> =
                [
                    {
                        PROPERTY: "PROVIDER",
                        LABEL: "BASIC_DATA.PROVIDER"
                    },
                    {
                        PROPERTY: "NAME",
                        LABEL: "GENERAL.NAME"
                    },
                    {
                        PROPERTY: "DISPLAY_NAME",
                        LABEL: "GENERAL.NAME_INTL"
                    },
                    {
                        PROPERTY: "PRODUCT",
                        LABEL: "BASIC_DATA.PRODUCT",
                        READ: "CODE"
                    },
                    {
                        PROPERTY: "ACTIVE",
                        LABEL: "GENERAL.ACTIVE"
                    },
                    {
                        PROPERTY: 'ID',
                        LABEL: 'REGISTRATION.IDENTIFICATION'
                    },
                    {
                        PROPERTY: 'CREATED_AT',
                        LABEL: 'GENERAL.CREATED_AT'
                    },
                    {
                        PROPERTY: 'CREATED_BY',
                        LABEL: 'GENERAL.CREATED_BY'
                    },
                    {
                        PROPERTY: 'UPDATED_AT',
                        LABEL: 'GENERAL.UPDATED_AT'
                    },
                    {
                        PROPERTY: 'UPDATED_BY',
                        LABEL: 'GENERAL.UPDATED_BY'
                    }
                ]
            return props
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getProductList(): Promise<SelectorModel[]> {
        try {
            const { data: generic } = await this.helperService.get('/generic/value/product', null, 10000);
            return generic && generic.data ? generic.data : [];
        } catch (ex) {
            this.handleError(ex);
        }
    }
}

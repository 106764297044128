'use strict';

RegularAuthService.$inject = ['$http', 'config'];
export function RegularAuthService($http, config) {
    this.timeout = 30000;

    this.login = function (email, password) {
        return $http({
            method: 'POST',
            url: config.helperUrl + '/helper/auth/local',
            data: { email: email, password: password, timeout: this.timeout },
            skipAuthorization: true,
            cache: false,
            timeout: this.timeout
        });
    }

    this.forgotPassword = function (email) {
        return $http({
            method: 'POST',
            url: config.restfulBaseUrl + '/auth/forgot',
            data: { email: email },
            skipAuthorization: true,
            cache: false,
            timeout: this.timeout
        });
    }

    //please check UserRouter.ts
    this.register = function (user) {
        return $http({
            method: 'POST',
            url: config.restfulBaseUrl + '/user/register',
            data: user,
            skipAuthorization: true,
            cache: false,
            timeout: this.timeout
        });
    }

    this.checkEmail = function (email) {
        return $http({
            method: 'GET',
            url: config.restfulBaseUrl + '/user/email/' + email,
            skipAuthorization: true,
            cache: false,
            timeout: this.timeout
        });
    }

    this.activate = function (url) {
        return $http({
            method: 'GET',
            url: config.restfulBaseUrl + '/user/activation/' + url,
            skipAuthorization: true,
            cache: false,
            timeout: 30000
        });
    }

    this.resendActivationEmail = function (email) {
        return $http({
            method: 'GET',
            url: config.restfulBaseUrl + '/user/activation/email/' + email,
            skipAuthorization: true,
            cache: false,
            timeout: this.timeout
        });
    }

}

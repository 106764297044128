import * as angular from 'angular';
import * as uuid from 'uuid';
import { FormService2, IFormServiceScope } from '@services/FormService2';
import { IFreightWizardFilter, IField, IType } from 'WBA-Model/dist/interface/product/FreightWizardFilter';
import { IFreightWizardRoute, IChargeHistory, IHistory as IHistoryFreightWizardRoute } from 'WBA-Model/dist/interface/product/FreightWizardRoute';
import { IError, IFreightWizardConfirmation } from 'WBA-Model/dist/interface/product/FreightWizard';
import { INewFreightContract } from 'WBA-Model/dist/interface/product/NewFreightContract';
import { IChargeNameList } from 'WBA-Model/dist/interface/product/ChargeNameModel';
import { ISelectorModel } from 'WBA-Model/dist/mongo/SelectorModel';
import { IHistory, EHistoryOperation, IHistoryOldNewValue, EHistoryType } from 'WBA-Model/dist/interface/product/FreightWizard';
import { IFreightRoutesCharge } from 'WBA-Model/dist/interface/product/FreightRoutes';
import { IApplicationList } from '@models/interface/product/ApplicationModel';
import { IMonacoRequest } from '@services/GridFormService';
import { ProductService } from '@services/ProductService';
import { IRestService } from '@services/RestService';
import { IModalService, IModalOptions } from "@services/ModalService";
import { ISessionService } from "@services/SessionService";
import { EChargeOriginId, EProductId, EOperation, EPaymentNatureId, EProviderTypeId } from '@enums/GenericData';
import { IStatusFreightWizardRoutes, EStatusTooltipText, EStatusColor, IFreightWizardUiTabSteps, EFreightWizardTabStep, freightWizardFilterActions, EFreightWizardFilterActionsID, IChargeColStyle, IDateOptions, EHistoryColor, ISearchBehaviorControl } from '../model/FreightWizardModel';
import { DateUtil } from '../../common/util/DateUtil';
import { IMonacoConfig } from '../../common/MonacoInterface';
import { StringUtil } from '../../common/util/StringUtil';
import { ILinkParameter } from '../../common/model/ModelParameter';
import { HelperService } from "@services/HelperService";

const enum EValueModificationProperties {
    CHARGE_PAYMENT_CURRENCY = "CHARGE_PAYMENT_CURRENCY",
    CHARGE_PAYMENT_UNITARY = "CHARGE_PAYMENT_UNITARY",
    CHARGE_PAYMENT_MIN = "CHARGE_PAYMENT_MIN",
    CHARGE_RECEIVING_CURRENCY = "CHARGE_RECEIVING_CURRENCY",
    CHARGE_RECEIVING_UNITARY = "CHARGE_RECEIVING_UNITARY",
    CHARGE_RECEIVING_MIN = "CHARGE_RECEIVING_MIN"
}

const enum EVigencyModificationProperties {
    CHARGE_VALIDITY_END = "CHARGE_VALIDITY_END",
    CHARGE_VALIDITY_START = "CHARGE_VALIDITY_START",
    FREIGHT_ROUTE_VALIDITY_END = "FREIGHT_ROUTE_VALIDITY_END",
    FREIGHT_CONTRACT_VALIDITY_END = "FREIGHT_CONTRACT_VALIDITY_END"
}

interface IFreightWizardModalScope extends IFormServiceScope {
    // Model
    model: IFreightWizardFilter;
    selectedRoutesModel: IFreightWizardFilter;
    oldModel: IFreightWizardFilter;

    // Preview and Confirmation
    displayPreview: IFreightWizardFilter;
    confirmationTabSearchControl: ISearchBehaviorControl;
    errorsOnFilter: IFreightWizardConfirmation[];
    confirmationOptions: IFreightWizardConfirmation[];
    hasAnyConfirmationOption: boolean;

    // History
    isHistoryViewMode: boolean;

    // Steps
    steps: IFreightWizardUiTabSteps;

    // Modal
    modalOptions: IModalOptions;

    // Enum
    EFreightWizardFilterActionsID: typeof EFreightWizardFilterActionsID;
    EFreightWizardTabStep: typeof EFreightWizardTabStep;
    EHistoryColor: typeof EHistoryColor;
    EHistoryType: typeof EHistoryType;

    // Charge Column Responsive Style
    chargeColStyle: IChargeColStyle;

    // List
    currencyList: ISelectorModel[];
    massActionTypeList: ISelectorModel[];
    massActionFieldList: ISelectorModel[];
    freightWizardFilterActionsList: ISelectorModel[];
    equipmentAndWeightList: ISelectorModel[];
    chargeNameList: IChargeNameList[];
    chargeApplicationList: IApplicationList[];

    // Variable Init Value
    initSelectedRoutes: boolean;

    // Date Options
    dateOptionsfreightContractValidity: IDateOptions;
    dateOptionsNewVigency: IDateOptions;
    dateOptionsCutDate: IDateOptions;
    useRouteValidityDate: boolean;

    // Navigation
    goToFreightRoute: (routeId: string) => void;
    goToFreightContract: (contractId: string) => void;

    // Search
    getChargeNameListByName: (search: string) => Promise<void>;

    // Multilines Control
    selectAllCheckboxes: (name: string) => void;
    hasFreightWizardRouteSelected: () => boolean;
    hasFreightWizardRouteDisplayedCharges: () => boolean;
    getStatusFreightWizardRoutes: (freightWizardRoute: IFreightWizardRoute) => IStatusFreightWizardRoutes;
    getConcatenatedBasisComplement: (array: object[], name: string) => string;
    getFreightWizardRouteSelected: () => IFreightWizardRoute[];
    getFreightWizardRouteSelectedAppliedFilter: (charge: IChargeHistory[]) => IChargeHistory[];
    clearFields: () => void;

    // Steps
    backStep: () => void;
    nextStep: () => void;
    tabClick: (tabIndex: number) => void;
    isTabEnabled: (tabIndex: number) => boolean;

    // Modal
    cancelModal: () => void;
    finishModal: () => void;
    confirmModal: () => void;

    // Field Changes
    specChangeCheckboxContract: () => void;
    specChangeRouteValidityEnd: (freightWizardRoute: IFreightWizardRoute) => void;
    specChangeRouteCheckbox: (freightWizardRoute: IFreightWizardRoute) => void;
    specChangeRouteUpdateDateCheckbox: (freightWizardRoute: IFreightWizardRoute) => void;
    specChangeContractValidityDate: () => void;
    specChangeChargeValue: (chargeHistory: IChargeHistory, route: IFreightWizardRoute, field: string) => void;
    specChangeApplyFilter: () => void;
    specChangeAction: () => Promise<void>;
    specActionNewVigency: () => void;
    specCheckboxUseRouteValidityDate: (useRouteValidityDate: boolean) => void;

    // Handler
    onHandleAlterChargeVigencyValue: () => void;
    onHandleAlterChargeVigency: () => void;
    onHandleAlterChargeValue: () => void;
    onHandleChargeConfirmationStyle: (charge: IChargeHistory, field: IHistoryFreightWizardRoute<any>) => object;
    onShowHistory: (history: IHistory) => void;
    onDeleteHistory: (history: IHistory) => void;

    // Preview And Confirmation
    getAndUpdateConfirmationList: () => Promise<void>;
    getFreightContractConfirmationLength: () => number;
    getFreightRoutesConfirmationLength: () => number;
    cancelConfirmationSearch: () => void;
    viewOptionErrors: (errors: IError[]) => void;
    hasAnySearchControlInProgress: () => boolean;
    hasAnyConfirmationOptionWithError: () => boolean;
    retryConfirmationRequest: () => Promise<void>;
    retryGenerateAllFreightWizard: (options: IFreightWizardConfirmation[]) => void;
    retryUpdateFreightWizard: (options: IFreightWizardConfirmation) => void;
}

export class FreightWizardModalController extends FormService2 {
    public static $inject: string[] = ['$injector', '$scope'];
    private scope: IFreightWizardModalScope;
    private $q: ng.IQService;
    private timeout: ng.ITimeoutService;
    private config: IMonacoConfig;
    private ProductService: ProductService;
    private ModalService: IModalService;
    private $RestService: IRestService;
    private $sce: angular.ISCEService;
    private $SessionService: ISessionService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IFreightWizardModalScope) {
        super($injector, $scope);

        this.scope = $scope;
        this.$q = $injector.get('$q');
        this.config = $injector.get('config');
        this.ProductService = $injector.get('ProductService');
        this.ModalService = $injector.get('ModalService');
        this.$RestService = $injector.get('RestService');
        this.timeout = $injector.get('$timeout');
        this.$sce = $injector.get('$sce');
        this.$SessionService = $injector.get('SessionService');
        this.helperService = $injector.get('HelperService');

        this.initDateOptions();
    }

    private initDateOptions() {
        this.scope.dateOptionsfreightContractValidity = <IDateOptions>{
            formatYear: 'yy',
            minDate: DateUtil.addDays(this.scope.model.FREIGHT_CONTRACT.VALIDITY_START, 1),
            maxDate: new Date(2030, 1, 1),
            startingDay: 1
        };

        this.scope.dateOptionsNewVigency = <IDateOptions>{
            formatYear: 'yy',
            minDate: this.scope.model.FREIGHT_CONTRACT.VALIDITY_START,
            maxDate: this.scope.model.FREIGHT_CONTRACT.VALIDITY_END,
            startingDay: 1
        };

        this.scope.dateOptionsCutDate = <IDateOptions>{
            formatYear: 'yy',
            minDate: this.scope.model.FREIGHT_CONTRACT.VALIDITY_START,
            maxDate: this.scope.model.FREIGHT_CONTRACT.VALIDITY_END,
            startingDay: 1
        }
        this.scope.useRouteValidityDate = false;
    }

    async $onInit(): Promise<void> {
        try {
            this.block();
            this.initializate();
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async initializate(): Promise<void> {
        await this.initDependencies();

        this.init("freightWizardModalForm", null, null);
        this.initFreightWizardSteps();
        this.initFreightWizardEnum();
        this.initScopeFunctions();
        this.initEvents();
        this.initModel();
        this.initSelectedRoutes();
        this.initSearchControl();
    }

    private async initDependencies(): Promise<void> {
        const self: FreightWizardModalController = this;

        const promises = {
            freightWizardFilterActionsList: self.getFreightWizardFilterActionsList(),
            currencyList: self.getCurrencyList(),
            equipmentAndWeightList: self.getEquipmentAndWeightList(),
            chargeApplicationList: self.getChargeApplicationList(),
            massActionTypeList: self.getMassActionTypeList(),
            massActionFieldList: self.getMassActionFieldList()
        };

        return new Promise((resolve, reject) => {
            self.$q.all(promises).then((result) => {
                self.scope.freightWizardFilterActionsList = result.freightWizardFilterActionsList;
                self.scope.currencyList = result.currencyList;
                self.scope.equipmentAndWeightList = result.equipmentAndWeightList;
                self.scope.chargeApplicationList = result.chargeApplicationList;
                self.scope.massActionTypeList = result.massActionTypeList;
                self.scope.massActionFieldList = result.massActionFieldList;

                resolve();
            }).catch((ex) => {
                reject(ex);
            });
        });
    }

    private initScopeFunctions(): void {
        // Navigation
        this.scope.goToFreightRoute = (routeId: string) => this.goToFreightRoute(routeId);
        this.scope.goToFreightContract = (contractId: string) => this.goToFreightContract(contractId);

        // Search
        this.scope.getChargeNameListByName = (search: string) => this.getChargeNameListByName(search);

        // Multilines Control
        this.scope.getStatusFreightWizardRoutes = (freightWizardRoute: IFreightWizardRoute): IStatusFreightWizardRoutes => this.getStatusFreightWizardRoutes(freightWizardRoute);
        this.scope.getFreightWizardRouteSelected = (): IFreightWizardRoute[] => this.getFreightWizardRouteSelected();
        this.scope.getFreightWizardRouteSelectedAppliedFilter = (charge: IChargeHistory[]): IChargeHistory[] => this.getFreightWizardRouteSelectedAppliedFilter(charge);
        this.scope.selectAllCheckboxes = (name) => this.selectAllCheckboxes(name);
        this.scope.hasFreightWizardRouteSelected = () => this.hasFreightWizardRouteSelected();
        this.scope.hasFreightWizardRouteDisplayedCharges = () => this.hasFreightWizardRouteDisplayedCharges();
        this.scope.getConcatenatedBasisComplement = (array: object[], name: string) => this.getCONCAT(array, null, name);
        this.scope.clearFields = () => this.clearFields();

        // Steps
        this.scope.nextStep = () => this.nextStep();
        this.scope.backStep = () => this.backStep();
        this.scope.tabClick = (tabIndex: number) => this.tabClick(tabIndex);
        this.scope.isTabEnabled = (tabIndex: number) => this.isTabEnabled(tabIndex);

        // Modal
        this.scope.cancelModal = () => this.cancelModal();
        this.scope.finishModal = () => this.finishModal();
        this.scope.confirmModal = () => this.confirmModal();

        // Field Changes
        this.scope.specChangeCheckboxContract = () => this.specChangeCheckboxContract();
        this.scope.specChangeRouteValidityEnd = (freightWizardRoute: IFreightWizardRoute) => this.specChangeRouteValidityEnd(freightWizardRoute);
        this.scope.specChangeRouteCheckbox = (freightWizardRoute: IFreightWizardRoute) => this.specChangeRouteCheckbox(freightWizardRoute);
        this.scope.specChangeRouteUpdateDateCheckbox = (freightWizardRoute: IFreightWizardRoute) => this.specChangeRouteUpdateDateCheckbox(freightWizardRoute);
        this.scope.specChangeContractValidityDate = () => this.specChangeContractValidityDate();
        this.scope.specChangeApplyFilter = () => this.specChangeApplyFilter();
        this.scope.specChangeChargeValue = (chargeHistory: IChargeHistory, route: IFreightWizardRoute, field: string) => this.specChangeChargeValue(chargeHistory, route, field);
        this.scope.specChangeAction = () => this.specChangeAction();
        this.scope.specActionNewVigency = () => this.specActionNewVigency();
        this.scope.specCheckboxUseRouteValidityDate = (useRouteValidityDate: boolean) => this.specCheckboxUseRouteValidityDate(useRouteValidityDate);

        // Handler
        this.scope.onHandleAlterChargeVigencyValue = () => this.onHandleAlterChargeVigencyValue();
        this.scope.onHandleAlterChargeVigency = () => this.onHandleAlterChargeVigency();
        this.scope.onHandleAlterChargeValue = () => this.onHandleAlterChargeValue();
        this.scope.onHandleChargeConfirmationStyle = (charge: IChargeHistory, field: IHistoryFreightWizardRoute<any>) => this.onHandleChargeConfirmationStyle(charge, field);
        this.scope.onShowHistory = (history: IHistory) => this.onShowHistory(history);
        this.scope.onDeleteHistory = (history: IHistory) => this.onDeleteHistory(history);

        // Preview and Confirmation
        this.scope.getAndUpdateConfirmationList = () => this.getAndUpdateConfirmationList();
        this.scope.getFreightContractConfirmationLength = () => this.getFreightContractConfirmationLength();
        this.scope.getFreightRoutesConfirmationLength = () => this.getFreightRoutesConfirmationLength();
        this.scope.cancelConfirmationSearch = () => this.cancelConfirmationSearch();
        this.scope.viewOptionErrors = (errors: IError[]) => this.viewOptionErrors(errors);
        this.scope.hasAnySearchControlInProgress = () => this.hasAnySearchControlInProgress();
        this.scope.retryConfirmationRequest = () => this.retryConfirmationRequest();
        this.scope.hasAnyConfirmationOptionWithError = () => this.hasAnyConfirmationOptionWithError();
        this.scope.retryGenerateAllFreightWizard = (options: IFreightWizardConfirmation[]) => this.retryGenerateAllFreightWizard(options);
        this.scope.retryUpdateFreightWizard = (options: IFreightWizardConfirmation) => this.retryUpdateFreightWizard(options);
    }

    private initModel(): void {
        this.scope.selectedRoutesModel = angular.copy(this.scope.model);
        this.scope.oldModel = angular.copy(this.scope.model);
        this.setChargeColStyle();
    }

    private initFreightWizardSteps(): void {
        this.scope.steps = {
            current: EFreightWizardTabStep.ROUTES_TAB,
            lastStep: EFreightWizardTabStep.CONFIRMATION,
            firstStep: EFreightWizardTabStep.ROUTES_TAB,
            percent: 0
        };
    }

    private initFreightWizardEnum(): void {
        this.scope.EFreightWizardFilterActionsID = EFreightWizardFilterActionsID;
        this.scope.EFreightWizardTabStep = EFreightWizardTabStep;
        this.scope.EHistoryType = EHistoryType;
        this.scope.EHistoryColor = EHistoryColor;
    }

    private initEvents(): void {
        angular.element(window).on('resize', () => this.setChargeColStyle());
    }

    private initSelectedRoutes(): void {
        try {
            if (this.scope.initSelectedRoutes) {
                angular.element(document.getElementsByName('selectAll')[0]).prop('checked', true);
                this.selectAllCheckboxes('selectAll');
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private initSearchControl(): void {
        this.scope.confirmationTabSearchControl = { isSearchTabDataListCanceled: false, isSearchingTabDataList: false, searchTabDataError: null, searchTabDataListCount: null, searchTabDataCurrentAttempt: null, searchTabDataMaxAttempt: 5 };
    }

    private setChargeColStyle(): void {
        const winWidth: number = angular.element(window).width();
        const chargeColStyle: IChargeColStyle = {
            chargeName: null,
            application: null,
            validityStart: null,
            validityEnd: null,
            weightAndEquipment: null,
            paymentCurrency: null,
            paymentUnitary: null,
            paymentMin: null,
            receivingCurrency: null,
            receivingUnitary: null,
            receivingMin: null,
            fontSize: null
        };

        if (winWidth >= 1480) {
            chargeColStyle.chargeName = { "width": "10%" };
            chargeColStyle.application = { "width": "10%" };
            chargeColStyle.weightAndEquipment = { "width": "13%" };
            chargeColStyle.validityStart = { "width": "7%" };
            chargeColStyle.validityEnd = { "width": "7%" };
            chargeColStyle.receivingCurrency = { "width": "7%" };
            chargeColStyle.receivingUnitary = { "width": "7%" };
            chargeColStyle.receivingMin = { "width": "7%" };
            chargeColStyle.paymentCurrency = { "width": "7%" };
            chargeColStyle.paymentUnitary = { "width": "7%" };
            chargeColStyle.paymentMin = { "width": "7%" };
            chargeColStyle.fontSize = { "font-size": "12px" };
        } else if (winWidth >= 1280) {
            chargeColStyle.chargeName = { "width": "10%" };
            chargeColStyle.application = { "width": "10%" };
            chargeColStyle.weightAndEquipment = { "width": "13%" };
            chargeColStyle.validityStart = { "width": "7%" };
            chargeColStyle.validityEnd = { "width": "7%" };
            chargeColStyle.receivingCurrency = { "width": "7%" };
            chargeColStyle.receivingUnitary = { "width": "7%" };
            chargeColStyle.receivingMin = { "width": "7%" };
            chargeColStyle.paymentCurrency = { "width": "7%" };
            chargeColStyle.paymentUnitary = { "width": "7%" };
            chargeColStyle.paymentMin = { "width": "7%" };
            chargeColStyle.fontSize = { "font-size": "10px" };
        } else if (winWidth >= 900) {
            chargeColStyle.chargeName = { "width": "10%" };
            chargeColStyle.application = { "width": "8%" };
            chargeColStyle.weightAndEquipment = { "width": "12%" };
            chargeColStyle.validityStart = { "width": "7%" };
            chargeColStyle.validityEnd = { "width": "7%" };
            chargeColStyle.receivingCurrency = { "width": "7%" };
            chargeColStyle.receivingUnitary = { "width": "7%" };
            chargeColStyle.receivingMin = { "width": "7%" };
            chargeColStyle.paymentCurrency = { "width": "7%" };
            chargeColStyle.paymentUnitary = { "width": "7%" };
            chargeColStyle.paymentMin = { "width": "7%" };
            chargeColStyle.fontSize = { "font-size": "9px" };
        }

        this.scope.chargeColStyle = chargeColStyle;
    }

    private async getCurrencyList(): Promise<ISelectorModel[]> {
        try {
            this.block();

            const selectorModelList: ISelectorModel[] = [];
            const request: IMonacoRequest = {
                route: `/currency/list/custom`,
                timeout: 30000,
            };

            const rc = await this.ProductService.post(request, false);
            const currencyList = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            for (const item of currencyList) {
                const selectorModel: ISelectorModel = {
                    ID: item.ID,
                    NAME: item.NAME,
                    CODE: item.INITIALS
                }

                selectorModelList.push(selectorModel);
            }

            return selectorModelList;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private getFreightWizardFilterActionsList(): ISelectorModel[] {
        return [
            { ID: freightWizardFilterActions.MODIFY_CHARGE_VALUES.ID, NAME: this.getTranslate(freightWizardFilterActions.MODIFY_CHARGE_VALUES.NAME) },
            { ID: freightWizardFilterActions.MODIFY_CHARGE_VALIDITY.ID, NAME: this.getTranslate(freightWizardFilterActions.MODIFY_CHARGE_VALIDITY.NAME) },
            { ID: freightWizardFilterActions.MODIFY_CHARGE_VALIDITY_AND_VALUES.ID, NAME: this.getTranslate(freightWizardFilterActions.MODIFY_CHARGE_VALIDITY_AND_VALUES.NAME) },
            { ID: freightWizardFilterActions.ADD_CHARGE.ID, NAME: this.getTranslate(freightWizardFilterActions.ADD_CHARGE.NAME) },
        ];
    }

    private getMassActionFieldList(): ISelectorModel[] {
        return [
            { ID: 'PAYMENT_MIN', NAME: this.getTranslate("PRODUCT.MIN_PAYMENT") },
            { ID: 'PAYMENT_UNITARY', NAME: this.getTranslate("PRODUCT.UNIT_PAYMENT") },
            { ID: 'RECEIVING_MIN', NAME: this.getTranslate("PRODUCT.MIN_RECEIPT") },
            { ID: 'RECEIVING_UNITARY', NAME: this.getTranslate("PRODUCT.UNIT_RECEIPT") },
            { ID: "ALL_FIELDS", NAME: this.getTranslate("GENERAL.ALL_OPTIONS") }
        ];
    }

    private getMassActionTypeList(): ISelectorModel[] {
        return [
            { ID: 'INCREASE', NAME: this.getTranslate("GENERAL.INCREASE") },
            { ID: 'REDUCE', NAME: this.getTranslate("GENERAL.REDUCE") },
            { ID: 'FIXED_VALUE', NAME: this.getTranslate("GENERAL.FIXED_VALUE") }
        ];
    }

    private async getEquipmentAndWeightList(): Promise<ISelectorModel[]> {
        try {
            this.block();

            let selectorModelList: ISelectorModel[] = [];
            const product: string = this.scope.model.FREIGHT_CONTRACT.PRODUCT.ID;
            const request: IMonacoRequest = {
                data: { products: [product] },
                timeout: 30000,
            };
            if (product == EProductId.AIR_EXPORT || product == EProductId.AIR_IMPORT ||
                product == EProductId.MARITIME_IMPORT || product == EProductId.MARITIME_EXPORT) {

                switch (product) {
                    case EProductId.AIR_EXPORT:
                    case EProductId.AIR_IMPORT:
                        request.route = `weightRange/list/custom`;
                        break;

                    case EProductId.MARITIME_EXPORT:
                    case EProductId.MARITIME_IMPORT:
                        request.route = `equipment/list/custom`;
                        break;
                }

                const equipmentList = await this.$RestService.newObjectPromise(`${this.config.productUrl}/product/${request.route}`, request.data, request.timeout, false);

                for (const item of equipmentList) {
                    const equipmentAndWeight: ISelectorModel = {
                        ID: item.ID,
                        NAME: item.NAME,
                    }

                    selectorModelList.push(equipmentAndWeight);
                }
            } else if (product == EProductId.ROAD_EXPORT || product == EProductId.ROAD_IMPORT || product == EProductId.ROAD_NATIONAL) {
                const vehicleList = await this.helperService.get(`/generic/value/vehicle_type`, null, 10000);
                selectorModelList = selectorModelList.concat(vehicleList.data.data)
            }

            return selectorModelList;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getChargeNameListByName(search: string): Promise<void> {
        try {
            if (search && search.length >= 2) {
                this.block();

                const selectorModelList: IChargeNameList[] = [];
                const request: IMonacoRequest = {
                    data: {
                        search,
                        types: [EChargeOriginId.FREIGHT],
                        products: [this.scope.model.FREIGHT_CONTRACT.PRODUCT.ID],
                        paramTypeCargo: []
                    },
                    route: `${this.config.productUrl}/product/chargeName/list/custom/exhibition`,
                    timeout: 30000,
                };

                const chargeList = await this.$RestService.newObjectPromise(request.route, request.data, request.timeout, false);

                for (const item of chargeList) {
                    const selectorModel: IChargeNameList = {
                        ID: item.ID,
                        NAME: item.NAME,
                        CODE: item.CODE,
                        PARAMS: item.PARAMS
                    }

                    selectorModelList.push(selectorModel);
                }

                this.scope.chargeNameList = selectorModelList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getChargeApplicationList(): Promise<IApplicationList[]> {
        try {
            this.block();

            const chargeApplicationList: IApplicationList[] = [];
            const request: IMonacoRequest = {
                data: {
                    typeCargos: [this.scope.model.FREIGHT_CONTRACT.CARGO_TYPE.ID],
                    products: [this.scope.model.FREIGHT_CONTRACT.PRODUCT.ID],
                },
                route: `/application/list/custom`,
                timeout: 30000,
            };

            const rc = await this.ProductService.post(request, false);
            const currencyList = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            for (const item of currencyList) {
                const applicationModel: IApplicationList = {
                    ID: item.ID,
                    NAME: item.NAME,
                    CODE: item.CODE,
                    PERCENT: item.PERCENT,
                    APPLICATION_COMPLEMENT: item.APPLICATION_COMPLEMENT,
                    INTERNAL_SEQUENCE: item.INTERNAL_SEQUENCE,
                    FREE_TYPING_AMOUNT_CHARGE: item.FREE_TYPING_AMOUNT_CHARGE,
                    CT_WITHOUT_DOC: item.CT_WITHOUT_DOC,
                    NAME_INTL: item.NAME_INTL
                }

                chargeApplicationList.push(applicationModel);
            }

            return chargeApplicationList;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async cancelModal(): Promise<void> {
        try {
            const currentStep: EFreightWizardTabStep = this.scope.steps.current;

            switch (currentStep) {
                case EFreightWizardTabStep.ROUTES_TAB:
                    if (this.hasFreightWizardRouteSelectedUpdateDate()) {
                        const modalConfirmationCancelModal = await this.showModalConfirmation(this.getTranslate('PRODUCT.ARE_YOU_SURE_YOU_WANT_TO_DISCARD_CHANGES'));
                        if (!modalConfirmationCancelModal) return;
                        this.scope.modalOptions.ok(false);
                    }
                    break;
                case EFreightWizardTabStep.VIGENCY_TAB:
                case EFreightWizardTabStep.PREVIEW:
                    if (this.hasFreightWizardRouteSelected()) {
                        const modalConfirmationCancelModal = await this.showModalConfirmation(this.getTranslate('PRODUCT.ARE_YOU_SURE_YOU_WANT_TO_DISCARD_CHANGES'));
                        if (!modalConfirmationCancelModal) return;
                        this.scope.steps.current = EFreightWizardTabStep.ROUTES_TAB;
                        this.scope.model = this.scope.selectedRoutesModel;
                    };
                    break;
                case EFreightWizardTabStep.CONFIRMATION:
                    const modalConfirmationCancelModal = await this.showModalConfirmation(this.getTranslate('PRODUCT.ARE_YOU_SURE_YOU_WANT_TO_DISCARD_CHANGES'));
                    if (!modalConfirmationCancelModal) return;
                    this.scope.modalOptions.ok(false);
                    break;
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async showModalConfirmation(bodyText: string): Promise<boolean> {
        try {
            const modalConfirmationCancelModal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.CLOSE',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: bodyText
            });

            return modalConfirmationCancelModal;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private finishModal(): void {
        this.scope.modalOptions.ok(true);
    }

    private confirmModal(): void {
        try {
            const routes = this.scope.confirmationOptions.map(confirmationOption => (confirmationOption.CONFIRMATION && confirmationOption.CONFIRMATION.ID_ROUTE) ? confirmationOption.CONFIRMATION.ID_ROUTE.toString() : null);
            this.goToFreightRoute(this.getCONCAT(routes));

            this.finishModal();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private goToFreightRoute(routeId: string): void {
        this.$SessionService.openTab("app.product.freightRoutes", <ILinkParameter>{ ID: (routeId) ? routeId.toString() : null });
    }

    private goToFreightContract(contractId: string): void {
        this.$SessionService.openTab("app.product.newFreightContract", <ILinkParameter>{ ID: (contractId) ? contractId.toString() : null });
    }

    private getStatusFreightWizardRoutes(freightWizardRoute: IFreightWizardRoute): IStatusFreightWizardRoutes {
        try {
            if (!freightWizardRoute) return;

            const isFreightRoutes: boolean = (freightWizardRoute.FREIGHT_ROUTE) ? true : false;
            const freightContract: INewFreightContract = (this.scope.model.FREIGHT_CONTRACT) ? this.scope.model.FREIGHT_CONTRACT : null;

            switch (isFreightRoutes) {
                case DateUtil.dateIsAfter(new Date(), new Date(freightWizardRoute.VALIDITY_END.NEW_VALUE)):
                    return <IStatusFreightWizardRoutes>{
                        color: EStatusColor.ALERT,
                        tooltipText: EStatusTooltipText.ALERT
                    };

                case DateUtil.dateIsAfter(new Date(freightContract.VALIDITY_END), new Date(freightWizardRoute.VALIDITY_END.NEW_VALUE)):
                    return <IStatusFreightWizardRoutes>{
                        color: EStatusColor.WARNING,
                        tooltipText: EStatusTooltipText.WARNING
                    };

                default:
                    return <IStatusFreightWizardRoutes>{
                        color: EStatusColor.VALID
                    };
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private selectAllCheckboxes(select: string): void {
        try {
            if (!this.scope.model.FREIGHT_WIZARD_ROUTES) return;
            if (!select) return;

            const checkboxAll: HTMLInputElement = document.getElementsByName(select)[0] as HTMLInputElement;
            const checkboxValue: boolean = checkboxAll.checked;

            for (const route of this.scope.model.FREIGHT_WIZARD_ROUTES) {
                switch (select) {
                    case 'selectAll':
                        this.selectCheckBox(checkboxValue, route);
                        break;
                    case 'selectAllUpdateDate':
                        this.selectUpdateDateCheckBox(checkboxValue, route)
                        break;
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private selectCheckBox(checkboxValue: boolean, route: IFreightWizardRoute): void {
        try {
            const checkBoxAllUpdateDate: JQuery<HTMLElement> = angular.element(document.getElementsByName('selectAllUpdateDate')[0]);

            if (!checkboxValue) {
                route.SELECTED_UPDATE_DATE = checkboxValue;
                checkBoxAllUpdateDate.prop('checked', false);
            }
            route.SELECTED = checkboxValue;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private selectUpdateDateCheckBox(checkboxValue: boolean, route: IFreightWizardRoute): void {
        try {
            if (!route.SELECTED) return;

            route.SELECTED_UPDATE_DATE = checkboxValue;
            route.VALIDITY_END.NEW_VALUE = (this.scope.model.UPDATE_VALIDITY_DATES && this.scope.model.VALIDITY_END && route.SELECTED && route.SELECTED_UPDATE_DATE) ? this.scope.model.VALIDITY_END.NEW_VALUE : route.FREIGHT_ROUTE.DISPLAY_VALIDITY_END;
            route.VALIDITY_END.DISABLED_FIELD = checkboxValue;
            this.generateConcatenatedFreightRoute(route);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private hasFreightWizardRouteDisplayedCharges(): boolean {
        try {
            return this.scope.oldModel.FREIGHT_WIZARD_ROUTES && this.scope.oldModel.FREIGHT_WIZARD_ROUTES.some(route => route.CHARGES_HISTORY.length);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private hasFreightWizardRouteSelected(): boolean {
        try {
            return this.scope.model.FREIGHT_WIZARD_ROUTES && this.scope.model.FREIGHT_WIZARD_ROUTES.some(route => route.SELECTED);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getFreightWizardRouteSelected(): IFreightWizardRoute[] {
        try {
            return this.scope.model.FREIGHT_WIZARD_ROUTES && this.scope.model.FREIGHT_WIZARD_ROUTES.filter(route => route.SELECTED === true);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getFreightWizardRouteSelectedAppliedFilter(charge: IChargeHistory[]): IChargeHistory[] {
        try {
            return charge && charge.filter(charge =>
                this.handleApplyFilterCharge(charge, this.scope.model.FILTER.CURRENCY, 'currency') &&
                this.handleApplyFilterCharge(charge, this.scope.model.FILTER.CHARGE_NAME_EXHIBITION, 'chargeNameExhibition') &&
                this.handleApplyFilterCharge(charge, this.scope.model.FILTER.REFERENCE_DATE, 'dateReference') &&
                this.handleApplyFilterCharge(charge, this.scope.model.FILTER.APPLICATION, 'application') &&
                this.handleApplyFilterCharge(charge, this.scope.model.FILTER.EQUIPMENT, 'basisComplement')
            )
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private filterByModifyVigency(): void {
        try {
            this.scope.oldModel.FREIGHT_WIZARD_ROUTES = angular.copy(this.getFreightWizardRouteSelected());
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private filterByModifyChargeValues(): void {
        try {
            for (const route of this.scope.oldModel.FREIGHT_WIZARD_ROUTES) {
                route.CHARGES_HISTORY = route.CHARGES_HISTORY.filter(charge => charge.VALIDITY_END.DISABLED_FIELD || charge.VALIDITY_START.DISABLED_FIELD);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private handleApplyFilterCharge(charge: IChargeHistory, filter: any, nameFilter: string): boolean {
        try {
            if (!filter) return true;
            if (filter.length == 0) return true;

            switch (nameFilter) {
                case 'currency':
                    return filter.some(currency =>
                        currency.CODE.toString() === (charge.FREIGHT_ROUTES_PAYMENT.CURRENCY && charge.FREIGHT_ROUTES_PAYMENT.CURRENCY.NEW_VALUE && charge.FREIGHT_ROUTES_PAYMENT.CURRENCY.NEW_VALUE.CODE.toString()) ||
                        currency.CODE.toString() === (charge.FREIGHT_ROUTES_RECEIVING.CURRENCY && charge.FREIGHT_ROUTES_RECEIVING.CURRENCY.NEW_VALUE && charge.FREIGHT_ROUTES_RECEIVING.CURRENCY.NEW_VALUE.CODE.toString())
                    );

                case 'basisComplement':
                    return filter.some(basisComplement =>
                        (charge.EQUIPMENT && charge.EQUIPMENT.some(equip => equip.ID.toString() == basisComplement.ID.toString())) ||
                        (charge.VEHICLE_TYPE && charge.VEHICLE_TYPE.some(equip => equip.ID.toString() == basisComplement.ID.toString())) ||
                        (charge.WEIGHT_RANGE && charge.WEIGHT_RANGE.some(weightRange => weightRange.ID.toString() == basisComplement.ID.toString()))
                    );

                case 'chargeNameExhibition':
                    return filter.some(chargeName => chargeName.ID.toString() === (charge.CHARGE_NAME_EXHIBITION && charge.CHARGE_NAME_EXHIBITION.ID.toString()));

                case 'dateReference':
                    return DateUtil.dateIsBetween(filter, charge.VALIDITY_START && charge.VALIDITY_START.NEW_VALUE, charge.VALIDITY_END && charge.VALIDITY_END.NEW_VALUE);

                case 'application':
                    return filter.some(application => application.ID.toString() === (charge.APPLICATION && charge.APPLICATION.ID.toString()));
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private generateConcatenatedFreightContract(): void {
        try {
            const separator = " | ";
            let concatenated = "";

            if (this.scope.model.FREIGHT_CONTRACT.PROVIDER && this.scope.model.FREIGHT_CONTRACT.PROVIDER.TYPE) {
                if ((this.scope.model.FREIGHT_CONTRACT.PROVIDER.TYPE.ID == EProviderTypeId.SHIPOWNER || this.scope.model.FREIGHT_CONTRACT.PROVIDER.TYPE.ID == EProviderTypeId.AIRLINE) && this.scope.model.FREIGHT_CONTRACT.PROVIDER.CODE && this.scope.model.FREIGHT_CONTRACT.PROVIDER.CODE.length) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.scope.model.FREIGHT_CONTRACT.PROVIDER.CODE) : concatenated.concat(this.scope.model.FREIGHT_CONTRACT.PROVIDER.CODE);
                } else if (this.scope.model.FREIGHT_CONTRACT.PROVIDER.NAME && this.scope.model.FREIGHT_CONTRACT.PROVIDER.NAME.length) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.scope.model.FREIGHT_CONTRACT.PROVIDER.NAME) : concatenated.concat(this.scope.model.FREIGHT_CONTRACT.PROVIDER.NAME);
                }
            }

            if (this.scope.model.FREIGHT_CONTRACT.EXTERNAL_AGENT_NETWORK && this.scope.model.FREIGHT_CONTRACT.EXTERNAL_AGENT_NETWORK.length) {
                const networks = this.getCONCAT(this.scope.model.FREIGHT_CONTRACT.EXTERNAL_AGENT_NETWORK, null, null, null, null, ',')
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, networks) : concatenated.concat(networks);
            }

            if (this.scope.model.FREIGHT_CONTRACT.ACCOUNT && this.scope.model.FREIGHT_CONTRACT.ACCOUNT.length) {
                const account = this.getCONCAT(this.scope.model.FREIGHT_CONTRACT.ACCOUNT, null, "CODE", null, null, ',')
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, account) : concatenated.concat(account);
            }

            if (this.scope.model.FREIGHT_CONTRACT.SHIPPER && this.scope.model.FREIGHT_CONTRACT.SHIPPER.length) {
                const networks = this.getCONCAT(this.scope.model.FREIGHT_CONTRACT.SHIPPER, null, "NAME", null, null, ',')
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, networks) : concatenated.concat(networks);
            }

            if (this.scope.model.FREIGHT_CONTRACT.CONSIGNEE && this.scope.model.FREIGHT_CONTRACT.CONSIGNEE.length) {
                const networks = this.getCONCAT(this.scope.model.FREIGHT_CONTRACT.CONSIGNEE, null, "NAME", null, null, ',')
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, networks) : concatenated.concat(networks);
            }

            if (this.scope.model.FREIGHT_CONTRACT.FREIGHT_CONTRACT && this.scope.model.FREIGHT_CONTRACT.FREIGHT_CONTRACT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.scope.model.FREIGHT_CONTRACT.FREIGHT_CONTRACT) : concatenated.concat(this.scope.model.FREIGHT_CONTRACT.FREIGHT_CONTRACT);
            }

            if (this.scope.model.FREIGHT_CONTRACT.CARGO_TYPE && this.scope.model.FREIGHT_CONTRACT.CARGO_TYPE.NAME && this.scope.model.FREIGHT_CONTRACT.CARGO_TYPE.NAME.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.scope.model.FREIGHT_CONTRACT.CARGO_TYPE.NAME) : concatenated.concat(this.scope.model.FREIGHT_CONTRACT.CARGO_TYPE.NAME);
            }

            if (this.scope.model.FREIGHT_CONTRACT.FREIGHT_CONTRACT_TYPE && this.scope.model.FREIGHT_CONTRACT.FREIGHT_CONTRACT_TYPE.NAME && this.scope.model.FREIGHT_CONTRACT.FREIGHT_CONTRACT_TYPE.NAME.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.scope.model.FREIGHT_CONTRACT.FREIGHT_CONTRACT_TYPE.NAME) : concatenated.concat(this.scope.model.FREIGHT_CONTRACT.FREIGHT_CONTRACT_TYPE.NAME);
            }

            if (this.scope.model.FREIGHT_CONTRACT.TRANSACTION && this.scope.model.FREIGHT_CONTRACT.TRANSACTION.ID && this.scope.model.FREIGHT_CONTRACT.TRANSACTION.ID.length > 0) {
                let paymentNature = null;
                if (this.scope.model.FREIGHT_CONTRACT.TRANSACTION.ID === EPaymentNatureId.PAYMENT) {
                    paymentNature = 'PGTO';
                } else if (this.scope.model.FREIGHT_CONTRACT.TRANSACTION.ID === EPaymentNatureId.RECEIVING) {
                    paymentNature = 'RCTO';
                }
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, paymentNature) : concatenated.concat(paymentNature);
            }

            if (this.scope.model.FREIGHT_CONTRACT.PRODUCT && this.scope.model.FREIGHT_CONTRACT.PRODUCT.ID && this.scope.model.FREIGHT_CONTRACT.PRODUCT.ID.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.scope.model.FREIGHT_CONTRACT.PRODUCT.ID) : concatenated.concat(this.scope.model.FREIGHT_CONTRACT.PRODUCT.ID);
            }

            if (this.scope.model.FREIGHT_CONTRACT.CONCATENATED_COMPLEMENT && this.scope.model.FREIGHT_CONTRACT.CONCATENATED_COMPLEMENT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.scope.model.FREIGHT_CONTRACT.CONCATENATED_COMPLEMENT) : concatenated.concat(this.scope.model.FREIGHT_CONTRACT.CONCATENATED_COMPLEMENT);
            }

            if (this.scope.model.FREIGHT_CONTRACT.VALIDITY_START) {
                const validity = this.scope.model.FREIGHT_CONTRACT.VALIDITY_END ? DateUtil.formatDate(this.scope.model.FREIGHT_CONTRACT.VALIDITY_START, 'DD/MM/YY') + '-' + DateUtil.formatDate(this.scope.model.FREIGHT_CONTRACT.VALIDITY_END, 'DD/MM/YY') : DateUtil.formatDate(this.scope.model.FREIGHT_CONTRACT.VALIDITY_START, 'DD/MM/YY');
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, validity) : concatenated.concat(validity);
            }

            this.scope.model.FREIGHT_CONTRACT.CONCATENATED = StringUtil.formatConcatenatedChars(concatenated);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private generateConcatenatedFreightRoute(route: IFreightWizardRoute): void {
        try {
            const separator = " | ";
            let concatenated = "";

            if (route.FREIGHT_ROUTE.FREIGHT_CONTRACT.PROVIDER && route.FREIGHT_ROUTE.FREIGHT_CONTRACT.PROVIDER.TYPE) {
                if ((route.FREIGHT_ROUTE.FREIGHT_CONTRACT.PROVIDER.TYPE.ID == EProviderTypeId.SHIPOWNER || route.FREIGHT_ROUTE.FREIGHT_CONTRACT.PROVIDER.TYPE.ID == EProviderTypeId.AIRLINE) && route.FREIGHT_ROUTE.FREIGHT_CONTRACT.PROVIDER.CODE && route.FREIGHT_ROUTE.FREIGHT_CONTRACT.PROVIDER.CODE.length) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, route.FREIGHT_ROUTE.FREIGHT_CONTRACT.PROVIDER.CODE) : concatenated.concat(route.FREIGHT_ROUTE.FREIGHT_CONTRACT.PROVIDER.CODE);
                } else if (route.FREIGHT_ROUTE.FREIGHT_CONTRACT.PROVIDER.NAME && route.FREIGHT_ROUTE.FREIGHT_CONTRACT.PROVIDER.NAME.length) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, route.FREIGHT_ROUTE.FREIGHT_CONTRACT.PROVIDER.NAME) : concatenated.concat(route.FREIGHT_ROUTE.FREIGHT_CONTRACT.PROVIDER.NAME);
                }
            }

            if (route.FREIGHT_ROUTE.FREIGHT_CONTRACT.FREIGHT_CONTRACT && route.FREIGHT_ROUTE.FREIGHT_CONTRACT.FREIGHT_CONTRACT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, route.FREIGHT_ROUTE.FREIGHT_CONTRACT.FREIGHT_CONTRACT) : concatenated.concat(route.FREIGHT_ROUTE.FREIGHT_CONTRACT.FREIGHT_CONTRACT);
            }

            if (route.FREIGHT_ROUTE.FREIGHT_CONTRACT.ACCOUNT && route.FREIGHT_ROUTE.FREIGHT_CONTRACT.ACCOUNT.length) {
                const account = this.getCONCAT(route.FREIGHT_ROUTE.FREIGHT_CONTRACT.ACCOUNT, null, "CODE", null, null, ',')
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, account) : concatenated.concat(account);
            }

            if (route.FREIGHT_ROUTE.FREIGHT_CONTRACT.CONTRACT_DESCRIPTION && route.FREIGHT_ROUTE.FREIGHT_CONTRACT.CONTRACT_DESCRIPTION.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, route.FREIGHT_ROUTE.FREIGHT_CONTRACT.CONTRACT_DESCRIPTION) : concatenated.concat(route.FREIGHT_ROUTE.FREIGHT_CONTRACT.CONTRACT_DESCRIPTION);
            }

            if (route.FREIGHT_ROUTE.DETAILS && route.FREIGHT_ROUTE.DETAILS.length) {
                const detail = this.getCONCAT(route.FREIGHT_ROUTE.DETAILS, null, "NAME", null, null, ',')
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, detail) : concatenated.concat(detail);
            }

            if (route.FREIGHT_ROUTE.CONCATENATED_ROUTES) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, route.FREIGHT_ROUTE.CONCATENATED_ROUTES) : concatenated.concat(route.FREIGHT_ROUTE.CONCATENATED_ROUTES);
            }

            if (route.FREIGHT_ROUTE.DET_DEM_ORIGIN && route.FREIGHT_ROUTE.DET_DEM_ORIGIN.DET_DEM_TYPE && route.FREIGHT_ROUTE.DET_DEM_ORIGIN.DET_DEM_TYPE.NAME) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, route.FREIGHT_ROUTE.DET_DEM_ORIGIN.DET_DEM_TYPE.NAME) : concatenated.concat(route.FREIGHT_ROUTE.DET_DEM_ORIGIN.DET_DEM_TYPE.NAME);
            }

            if (route.FREIGHT_ROUTE.DET_DEM_DESTINATION && route.FREIGHT_ROUTE.DET_DEM_DESTINATION.DET_DEM_TYPE && route.FREIGHT_ROUTE.DET_DEM_DESTINATION.DET_DEM_TYPE.NAME) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, route.FREIGHT_ROUTE.DET_DEM_DESTINATION.DET_DEM_TYPE.NAME) : concatenated.concat(route.FREIGHT_ROUTE.DET_DEM_DESTINATION.DET_DEM_TYPE.NAME);
            }

            if (route.FREIGHT_ROUTE.CONCATENATED_COMPLEMENT) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, route.FREIGHT_ROUTE.CONCATENATED_COMPLEMENT) : concatenated.concat(route.FREIGHT_ROUTE.CONCATENATED_COMPLEMENT);
            }

            if (route.VALIDITY_END) {
                const validity = route.VALIDITY_END ? DateUtil.formatDate(route.VALIDITY_START, 'DD/MM/YY') + '-' + DateUtil.formatDate(route.VALIDITY_END.NEW_VALUE, 'DD/MM/YY') : DateUtil.formatDate(route.VALIDITY_START, 'DD/MM/YY');
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, validity) : concatenated.concat(validity);
            }

            route.FREIGHT_ROUTE.CONCATENATED = StringUtil.formatConcatenatedChars(concatenated);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private hasFreightWizardRouteSelectedUpdateDate(): boolean {
        try {
            return this.scope.model.FREIGHT_WIZARD_ROUTES && !this.scope.model.FREIGHT_WIZARD_ROUTES.every(e => !e.VALIDITY_END.DISABLED_FIELD);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private specChangeCheckboxContract(): void {
        try {
            if (!this.scope.model.FREIGHT_WIZARD_ROUTES) return;
            if (this.scope.model.UPDATE_VALIDITY_DATES) return;

            for (const freighWizardRoutes of this.scope.model.FREIGHT_WIZARD_ROUTES) {
                freighWizardRoutes.VALIDITY_END.NEW_VALUE = (this.scope.model.UPDATE_VALIDITY_DATES && this.scope.model.VALIDITY_END && freighWizardRoutes.SELECTED && freighWizardRoutes.SELECTED_UPDATE_DATE) ? this.scope.model.VALIDITY_END.NEW_VALUE : freighWizardRoutes.VALIDITY_END.NEW_VALUE;
                this.specChangeRouteValidityEnd(freighWizardRoutes);

                if (!this.scope.model.UPDATE_VALIDITY_DATES) {
                    const checkBoxAllUpdateDate: JQuery<HTMLElement> = angular.element(document.getElementsByName('selectAllUpdateDate')[0]);
                    freighWizardRoutes.SELECTED_UPDATE_DATE = this.scope.model.UPDATE_VALIDITY_DATES;
                    freighWizardRoutes.VALIDITY_END.NEW_VALUE = freighWizardRoutes.VALIDITY_END.OLD_VALUE;
                    checkBoxAllUpdateDate.prop('checked', this.scope.model.UPDATE_VALIDITY_DATES);
                }

                this.generateConcatenatedFreightRoute(freighWizardRoutes);
            }

            if (!this.scope.model.UPDATE_VALIDITY_DATES) {
                this.scope.model.VALIDITY_END.NEW_VALUE = null;
                this.scope.model.FREIGHT_CONTRACT.VALIDITY_END = this.scope.model.VALIDITY_END.OLD_VALUE;

                this.generateConcatenatedFreightContract();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private specChangeRouteValidityEnd(freightWizardRoute: IFreightWizardRoute): void {
        try {
            if (!freightWizardRoute) return;

            const sameDate = DateUtil.dateIsSame(freightWizardRoute.VALIDITY_END.NEW_VALUE, freightWizardRoute.VALIDITY_END.OLD_VALUE);

            if (!sameDate) {
                this.generateConcatenatedFreightRoute(freightWizardRoute);
                freightWizardRoute.VALIDITY_END.DISABLED_FIELD = true;
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private specChangeRouteCheckbox(freightWizardRoute: IFreightWizardRoute): void {
        try {
            if (!freightWizardRoute) return;

            freightWizardRoute.SELECTED = !freightWizardRoute.SELECTED;
            if (!freightWizardRoute.SELECTED) freightWizardRoute.SELECTED_UPDATE_DATE = false;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private specChangeRouteUpdateDateCheckbox(freightWizardRoute: IFreightWizardRoute): void {
        try {
            if (!freightWizardRoute) return;

            freightWizardRoute.SELECTED_UPDATE_DATE = !freightWizardRoute.SELECTED_UPDATE_DATE;
            freightWizardRoute.VALIDITY_END.NEW_VALUE = (!freightWizardRoute.SELECTED_UPDATE_DATE) ? freightWizardRoute.FREIGHT_ROUTE.DISPLAY_VALIDITY_END : this.scope.model.VALIDITY_END.NEW_VALUE;
            freightWizardRoute.VALIDITY_END.DISABLED_FIELD = (freightWizardRoute.SELECTED_UPDATE_DATE) ? true : false;
            this.generateConcatenatedFreightRoute(freightWizardRoute);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private specChangeContractValidityDate(): void {
        try {
            this.scope.model.FREIGHT_CONTRACT.VALIDITY_END = this.scope.model.VALIDITY_END.NEW_VALUE;
            this.generateConcatenatedFreightContract();

            const routes = this.scope.model.FREIGHT_WIZARD_ROUTES && this.scope.model.FREIGHT_WIZARD_ROUTES.length ? this.getFreightWizardRouteSelected() : [];
            for (const route of routes) {
                if (!route.SELECTED_UPDATE_DATE) continue;

                route.VALIDITY_END.NEW_VALUE = this.scope.model.VALIDITY_END.NEW_VALUE;
                route.VALIDITY_END.DISABLED_FIELD = true;
                this.generateConcatenatedFreightRoute(route);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private specActionNewVigency(): void {
        try {
            if (!this.scope.model.ACTION.ALTER_VIGENCY.NEW_VIGENCY) {
                this.scope.dateOptionsCutDate.maxDate = null;
            }

            if (DateUtil.dateIsAfter(this.scope.model.ACTION.ALTER_VIGENCY.CUT_DATE, this.scope.model.ACTION.ALTER_VIGENCY.NEW_VIGENCY)) {
                this.scope.model.ACTION.ALTER_VIGENCY.CUT_DATE = null;
            }

            this.scope.dateOptionsCutDate.maxDate = DateUtil.substractDays(this.scope.model.ACTION.ALTER_VIGENCY.NEW_VIGENCY, 1);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private specChangeApplyFilter(): void {
        try {
            this.block();
            const freightWizardRouteSelected = this.getFreightWizardRouteSelected();
            this.scope.oldModel.FREIGHT_WIZARD_ROUTES = angular.copy(freightWizardRouteSelected);
            this.clearActionFields();
            this.specChangeAction();
            this.scope.useRouteValidityDate = false;
            for (const route of this.scope.oldModel.FREIGHT_WIZARD_ROUTES) {
                route.CHARGES_HISTORY = this.getFreightWizardRouteSelectedAppliedFilter(route.CHARGES_HISTORY);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async specChangeAction(): Promise<void> {
        try {
            if (!this.scope.model.ACTION) return;
            this.scope.isHistoryViewMode = false;
            this.disableAllHistoriesSelected();

            switch (this.scope.model.ACTION.ID) {
                case EFreightWizardFilterActionsID.ADD_CHARGE:
                    await this.openAddCharge();
                    break;

                case EFreightWizardFilterActionsID.MODIFY_CHARGE_VALUES:
                    this.filterByModifyChargeValues();
                    break

                case EFreightWizardFilterActionsID.MODIFY_CHARGE_VALIDITY_AND_VALUES:
                case EFreightWizardFilterActionsID.MODIFY_CHARGE_VALIDITY:
                    this.filterByModifyVigency();
                    break;
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private isChargeVigencyChanged(charge: IChargeHistory): boolean {
        try {
            if (!charge) return false;
            if (!charge.HAS_CHANGES) return false;
            if (charge.VALIDITY_START.DISABLED_FIELD || charge.VALIDITY_END.DISABLED_FIELD) return true;

            return false;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private specCheckboxUseRouteValidityDate(useRouteValidityDate: boolean): void {
        try {
            this.scope.useRouteValidityDate = !useRouteValidityDate;
            if (this.scope.model.ACTION && this.scope.model.ACTION.ALTER_VIGENCY && this.scope.model.ACTION.ALTER_VIGENCY.NEW_VIGENCY) this.scope.model.ACTION.ALTER_VIGENCY.NEW_VIGENCY = null;
            this.scope.dateOptionsCutDate.maxDate = null;

            const allDatesAreEqual = this.scope.oldModel.FREIGHT_WIZARD_ROUTES.every(obj => {
                return obj.VALIDITY_END.NEW_VALUE.getTime() === this.scope.oldModel.FREIGHT_WIZARD_ROUTES[0].VALIDITY_END.NEW_VALUE.getTime();
            });

            if (allDatesAreEqual && this.scope.useRouteValidityDate && this.scope.model.ACTION) {
                this.scope.model.ACTION.ALTER_VIGENCY = {
                    CUT_DATE: this.scope.model.ACTION.ALTER_VIGENCY ? this.scope.model.ACTION.ALTER_VIGENCY.CUT_DATE : null,
                    NEW_VIGENCY: this.scope.oldModel.FREIGHT_WIZARD_ROUTES[0].VALIDITY_END.NEW_VALUE
                }
            };

            for (const route of this.scope.oldModel.FREIGHT_WIZARD_ROUTES) {
                for (const charge of route.CHARGES_HISTORY) {
                    if (charge.VALIDITY_END.DISABLED_FIELD) continue;

                    if (this.scope.useRouteValidityDate) {
                        charge.VALIDITY_END.NEW_VALUE = route.VALIDITY_END.NEW_VALUE;
                        charge.USE_ROUTE_VALIDITY_DATE = true;
                    } else {
                        charge.VALIDITY_END.NEW_VALUE = charge.VALIDITY_END.OLD_VALUE;
                        charge.USE_ROUTE_VALIDITY_DATE = false;
                    }
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private specChangeChargeValue(chargeHistory: IChargeHistory, freightWizardRoute: IFreightWizardRoute, field: string): void { //TODO: possivel refatoração
        try {
            if (!chargeHistory) return;
            if (!freightWizardRoute) return;
            if (!field) return;

            const route = this.scope.model.FREIGHT_WIZARD_ROUTES.find(route => route.FREIGHT_ROUTE.ID == freightWizardRoute.FREIGHT_ROUTE.ID);

            for (const chargeChanged of route.CHARGES_HISTORY) {
                if (chargeChanged._id !== chargeHistory._id) continue;
                let charge: IChargeHistory = chargeChanged;

                let valueChange = false;
                let oldNewValue: IHistoryOldNewValue = null;
                let oldValue: any = null;
                let newValue: any = null;
                let fieldName: string = null;
                let propertyUpdatedLabel: string = null;

                switch (field) {
                    case 'VALIDITY_START':
                        if (DateUtil.dateIsSame(charge.VALIDITY_START.OLD_VALUE, chargeHistory.VALIDITY_START.NEW_VALUE)) return;
                        if (!DateUtil.isValidDate(chargeHistory.VALIDITY_START.NEW_VALUE)) return;

                        chargeHistory.VALIDITY_START.DISABLED_FIELD = true;
                        charge.VALIDITY_START = chargeHistory.VALIDITY_START;
                        charge.HAS_CHANGES = true;

                        oldValue = chargeHistory.VALIDITY_START.OLD_VALUE;
                        newValue = chargeHistory.VALIDITY_START.NEW_VALUE;
                        fieldName = 'PRODUCT.VALIDITY_START';
                        propertyUpdatedLabel = '';

                        oldNewValue = this.createOldNewValue(charge, EVigencyModificationProperties.CHARGE_VALIDITY_START, oldValue, newValue);
                        this.addHistory(oldNewValue, this.getTranslate('PRODUCT.CUSTOM_HISTORY_MESSAGE.MANUAL_CHARGE_CHANGE', { field_name: this.getTranslate('PRODUCT.VALIDITY_END'), charge_name: charge.CHARGE_NAME_EXHIBITION.NAME, old_value: DateUtil.formatDate(chargeHistory.VALIDITY_END.OLD_VALUE, 'LL'), new_value: DateUtil.formatDate(chargeHistory.VALIDITY_END.NEW_VALUE, 'LL'), route_name: route.FREIGHT_ROUTE.CONCATENATED }), true, EHistoryType.FREIGHT_ROUTES_CHARGES);

                        break;

                    case 'VALIDITY_END':
                        if (DateUtil.dateIsSame(charge.VALIDITY_END.OLD_VALUE, chargeHistory.VALIDITY_END.NEW_VALUE)) return;
                        if (!DateUtil.isValidDate(chargeHistory.VALIDITY_END.NEW_VALUE)) return;

                        chargeHistory.VALIDITY_END.DISABLED_FIELD = true;
                        charge.VALIDITY_END = chargeHistory.VALIDITY_END;
                        charge.HAS_CHANGES = true;

                        oldValue = chargeHistory.VALIDITY_END.OLD_VALUE;
                        newValue = chargeHistory.VALIDITY_END.NEW_VALUE;
                        fieldName = 'PRODUCT.VALIDITY_END';
                        propertyUpdatedLabel = '';

                        oldNewValue = this.createOldNewValue(charge, EVigencyModificationProperties.CHARGE_VALIDITY_END, oldValue, newValue);

                        this.addHistory(oldNewValue, this.getTranslate('PRODUCT.CUSTOM_HISTORY_MESSAGE.MANUAL_CHARGE_CHANGE', { field_name: this.getTranslate('PRODUCT.VALIDITY_END'), charge_name: charge.CHARGE_NAME_EXHIBITION.NAME, old_value: DateUtil.formatDate(oldValue, 'LL'), new_value: DateUtil.formatDate(newValue, 'LL'), route_name: route.FREIGHT_ROUTE.CONCATENATED }), true, EHistoryType.FREIGHT_ROUTES_CHARGES);
                        break;

                    case 'PAYMENT_CURRENCY':
                        valueChange = true;

                        if (charge.FREIGHT_ROUTES_PAYMENT.CURRENCY.OLD_VALUE &&
                            charge.FREIGHT_ROUTES_PAYMENT.CURRENCY &&
                            charge.FREIGHT_ROUTES_PAYMENT &&
                            charge.FREIGHT_ROUTES_PAYMENT.CURRENCY.OLD_VALUE.ID == chargeHistory.FREIGHT_ROUTES_PAYMENT.CURRENCY.NEW_VALUE.ID) return;

                        chargeHistory.FREIGHT_ROUTES_PAYMENT.CURRENCY.DISABLED_FIELD = true;
                        charge.FREIGHT_ROUTES_PAYMENT.CURRENCY = chargeHistory.FREIGHT_ROUTES_PAYMENT.CURRENCY;

                        oldValue = charge.FREIGHT_ROUTES_PAYMENT.CURRENCY.OLD_VALUE ? charge.FREIGHT_ROUTES_PAYMENT.CURRENCY.OLD_VALUE.NAME : null;
                        newValue = charge.FREIGHT_ROUTES_PAYMENT.CURRENCY.NEW_VALUE;
                        fieldName = 'FINANCIAL.PAYMENT';
                        propertyUpdatedLabel = 'GENERAL.CURRENCY';

                        oldNewValue = this.createOldNewValue(charge, EValueModificationProperties.CHARGE_PAYMENT_CURRENCY, oldValue, newValue);

                        break;

                    case 'PAYMENT_UNITARY':
                        valueChange = true;

                        if (chargeHistory.FREIGHT_ROUTES_PAYMENT.UNITARY.NEW_VALUE == charge.FREIGHT_ROUTES_PAYMENT.UNITARY.OLD_VALUE) return;
                        if (!chargeHistory.FREIGHT_ROUTES_RECEIVING.CURRENCY.NEW_VALUE) {
                            chargeHistory.FREIGHT_ROUTES_PAYMENT.UNITARY.NEW_VALUE = null;
                            return this.notifyError('É necessário definir a moeda antes de definir o valor unitário')
                        }
                        chargeHistory.FREIGHT_ROUTES_PAYMENT.UNITARY.DISABLED_FIELD = true;
                        charge.FREIGHT_ROUTES_PAYMENT.UNITARY = chargeHistory.FREIGHT_ROUTES_PAYMENT.UNITARY;

                        oldValue = charge.FREIGHT_ROUTES_PAYMENT.UNITARY.OLD_VALUE;
                        newValue = charge.FREIGHT_ROUTES_PAYMENT.UNITARY.NEW_VALUE;
                        fieldName = 'FINANCIAL.PAYMENT';
                        propertyUpdatedLabel = 'GENERAL.UNITY';

                        oldNewValue = this.createOldNewValue(charge, EValueModificationProperties.CHARGE_PAYMENT_UNITARY, oldValue, newValue);

                        break;

                    case 'PAYMENT_MIN':
                        valueChange = true;

                        if (chargeHistory.FREIGHT_ROUTES_PAYMENT.MIN.NEW_VALUE == charge.FREIGHT_ROUTES_PAYMENT.MIN.OLD_VALUE) return;
                        if (!chargeHistory.FREIGHT_ROUTES_PAYMENT.CURRENCY.NEW_VALUE) {
                            chargeHistory.FREIGHT_ROUTES_PAYMENT.MIN.NEW_VALUE = null;
                            return this.notifyError('É necessário definir a moeda antes de definir o valor mínimo')
                        }
                        chargeHistory.FREIGHT_ROUTES_PAYMENT.MIN.DISABLED_FIELD = true;
                        charge.FREIGHT_ROUTES_PAYMENT.MIN = chargeHistory.FREIGHT_ROUTES_PAYMENT.MIN;

                        oldValue = charge.FREIGHT_ROUTES_PAYMENT.MIN.OLD_VALUE;
                        newValue = charge.FREIGHT_ROUTES_PAYMENT.MIN.NEW_VALUE;
                        fieldName = 'FINANCIAL.PAYMENT';
                        propertyUpdatedLabel = 'GENERAL.MINIMUM';

                        oldNewValue = this.createOldNewValue(charge, EValueModificationProperties.CHARGE_PAYMENT_MIN, oldValue, newValue);

                        break;

                    case 'RECEIVING_CURRENCY':
                        valueChange = true;

                        if (charge.FREIGHT_ROUTES_RECEIVING.CURRENCY.OLD_VALUE &&
                            charge.FREIGHT_ROUTES_RECEIVING.CURRENCY &&
                            charge.FREIGHT_ROUTES_RECEIVING &&
                            charge.FREIGHT_ROUTES_RECEIVING.CURRENCY.OLD_VALUE.ID == chargeHistory.FREIGHT_ROUTES_RECEIVING.CURRENCY.NEW_VALUE.ID) return;

                        chargeHistory.FREIGHT_ROUTES_RECEIVING.CURRENCY.DISABLED_FIELD = true;
                        charge.FREIGHT_ROUTES_RECEIVING.CURRENCY = chargeHistory.FREIGHT_ROUTES_RECEIVING.CURRENCY;

                        oldValue = charge.FREIGHT_ROUTES_RECEIVING.CURRENCY.OLD_VALUE ? charge.FREIGHT_ROUTES_RECEIVING.CURRENCY.OLD_VALUE.NAME : null;
                        newValue = charge.FREIGHT_ROUTES_RECEIVING.CURRENCY.NEW_VALUE;
                        fieldName = 'FINANCIAL.RECEINVING';
                        propertyUpdatedLabel = 'GENERAL.CURRENCY';

                        oldNewValue = this.createOldNewValue(charge, EValueModificationProperties.CHARGE_RECEIVING_CURRENCY, oldValue, newValue);

                        break;

                    case 'RECEIVING_UNITARY':
                        valueChange = true;

                        if (chargeHistory.FREIGHT_ROUTES_RECEIVING.UNITARY.NEW_VALUE == charge.FREIGHT_ROUTES_RECEIVING.UNITARY.OLD_VALUE) return;
                        if (!chargeHistory.FREIGHT_ROUTES_RECEIVING.CURRENCY.NEW_VALUE) {
                            chargeHistory.FREIGHT_ROUTES_RECEIVING.UNITARY.NEW_VALUE = null;
                            return this.notifyError('É necessário definir a moeda antes de definir o valor unitário')
                        }
                        chargeHistory.FREIGHT_ROUTES_RECEIVING.UNITARY.DISABLED_FIELD = true;
                        charge.FREIGHT_ROUTES_RECEIVING.UNITARY = chargeHistory.FREIGHT_ROUTES_RECEIVING.UNITARY;

                        oldValue = charge.FREIGHT_ROUTES_RECEIVING.UNITARY.OLD_VALUE;
                        newValue = charge.FREIGHT_ROUTES_RECEIVING.UNITARY.NEW_VALUE;
                        fieldName = 'FINANCIAL.RECEINVING';
                        propertyUpdatedLabel = 'GENERAL.UNITY';

                        oldNewValue = this.createOldNewValue(charge, EValueModificationProperties.CHARGE_RECEIVING_UNITARY, oldValue, newValue);

                        break;

                    case 'RECEIVING_MIN':
                        valueChange = true;

                        if (chargeHistory.FREIGHT_ROUTES_RECEIVING.MIN.NEW_VALUE == charge.FREIGHT_ROUTES_RECEIVING.MIN.OLD_VALUE) return;
                        if (!chargeHistory.FREIGHT_ROUTES_RECEIVING.CURRENCY.NEW_VALUE) {
                            chargeHistory.FREIGHT_ROUTES_RECEIVING.MIN.NEW_VALUE = null;
                            return this.notifyError('É necessário definir a moeda antes de definir o valor mínimo')
                        }
                        chargeHistory.FREIGHT_ROUTES_RECEIVING.MIN.DISABLED_FIELD = true;
                        charge.FREIGHT_ROUTES_RECEIVING.MIN = chargeHistory.FREIGHT_ROUTES_RECEIVING.MIN;

                        oldValue = chargeHistory.FREIGHT_ROUTES_RECEIVING.MIN.OLD_VALUE
                        newValue = chargeHistory.FREIGHT_ROUTES_RECEIVING.MIN.NEW_VALUE
                        fieldName = 'FINANCIAL.RECEINVING';
                        propertyUpdatedLabel = 'GENERAL.MINIMUM';

                        oldNewValue = this.createOldNewValue(charge, EValueModificationProperties.CHARGE_RECEIVING_MIN, oldValue, newValue);

                        break;
                }
                const translatedFieldName = fieldName ? this.getTranslate(fieldName) : "";
                const translatePropertyLabel = propertyUpdatedLabel ? this.getTranslate(propertyUpdatedLabel) : "";

                if (valueChange) {
                    if (this.isDuplicatedCharge(charge, route)) {
                        if (this.isCurrencyChange(oldNewValue)) {
                            newValue = this.adjustCurrencyChangeName(newValue);
                        }
                        this.addHistory(oldNewValue, this.getTranslate('PRODUCT.CUSTOM_HISTORY_MESSAGE.MANUAL_CHARGE_CHANGE', { field_name: `${translatedFieldName} ${translatePropertyLabel}`, charge_name: charge.CHARGE_NAME_EXHIBITION.NAME, old_value: oldValue, new_value: newValue, route_name: route.FREIGHT_ROUTE.CONCATENATED }), true, EHistoryType.FREIGHT_ROUTES_CHARGES);
                    } else {
                        const history: IHistory = { ID: uuid.v4(), NAME: null, DATE: new Date(), OLD_NEW_VALUE: [], SELECTED: false, IS_SELECTABLE: true, HISTORY_TYPE: EHistoryType.FREIGHT_ROUTES_CHARGES };
                        this.duplicateFreightRouteCharge(charge, route, history, field, true)
                        this.applyChargeHistoryToModel(history, this.getFreightWizardRouteSelected());

                        const duplicatedCharge = route.CHARGES_HISTORY.find(e => e.ID_CHARGE_PARENT == charge.ID);
                        if (duplicatedCharge) {
                            oldNewValue.CHARGE_ID = duplicatedCharge._id.toString();
                            if (this.isCurrencyChange(oldNewValue)) {
                                newValue = this.adjustCurrencyChangeName(newValue);
                            }
                            this.addHistory(oldNewValue, this.getTranslate('PRODUCT.CUSTOM_HISTORY_MESSAGE.MANUAL_CHARGE_CHANGE', { field_name: `${translatedFieldName} ${translatePropertyLabel}`, charge_name: charge.CHARGE_NAME_EXHIBITION.NAME, old_value: oldValue, new_value: newValue, route_name: route.FREIGHT_ROUTE.CONCATENATED }), true, EHistoryType.FREIGHT_ROUTES_CHARGES);
                        }
                    }
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private isDuplicatedCharge(charge: IChargeHistory, route: IFreightWizardRoute) {
        return charge.ID_CHARGE_PARENT && route.CHARGES_HISTORY.find(e => e.ID == charge.ID_CHARGE_PARENT)
    }

    private adjustCurrencyChangeName(newValue: any): string {
        return newValue.NAME;
    }

    private isCurrencyChange(oldNewValue: IHistoryOldNewValue): boolean {
        return (oldNewValue.PROPERTY == EValueModificationProperties.CHARGE_RECEIVING_CURRENCY || oldNewValue.PROPERTY == EValueModificationProperties.CHARGE_RECEIVING_CURRENCY)
    }

    private createOldNewValue(charge: IChargeHistory, property: string, oldValue: any, newValue: any): IHistoryOldNewValue<any> {
        try {
            return {
                FREIGHT_ROUTE_ID: charge.ID_FREIGHT_ROUTES.toString(),
                CHARGE_ID: charge._id.toString(),
                PROPERTY: property,
                NEW_VALUE: newValue,
                OLD_VALUE: oldValue,
                IS_NEW: false,
                OPERATION: EHistoryOperation.UPDATE,
                CHARGE: null
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private addHistory(oldNewValue: IHistoryOldNewValue<any>, name: string, isSelectable: boolean, historyType: EHistoryType): void {
        try {

            const history: IHistory = {
                ID: uuid.v4(),
                NAME: name,
                SELECTED: false,
                DATE: new Date(),
                OLD_NEW_VALUE: [oldNewValue],
                IS_SELECTABLE: isSelectable,
                HISTORY_TYPE: historyType,
            }

            this.scope.model.HISTORY.push(history);
            this.applyChargeHistoryToModel(history, this.getFreightWizardRouteSelected());

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private tabClick(tabIndex: number): void {
        try {
            if (!this.scope.steps || tabIndex == null) return;
            if (this.scope.steps.current === EFreightWizardTabStep.CONFIRMATION) return;

            const previousStep: number = this.scope.steps.current - 1;
            const nextStep: number = this.scope.steps.current + 1;

            if (previousStep == tabIndex) this.scope.backStep();
            else if (nextStep == tabIndex) this.scope.nextStep();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private isTabEnabled(tabIndex: number): boolean {
        try {
            if (!this.scope.steps || tabIndex == null) return false;
            if (this.scope.steps.current === EFreightWizardTabStep.CONFIRMATION) return false;

            const previousStep: number = this.scope.steps.current - 1;
            const nextStep: number = this.scope.steps.current + 1;

            return (previousStep == tabIndex || nextStep == tabIndex);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async backStep(): Promise<void> {
        try {
            const currentStep: EFreightWizardTabStep = this.scope.steps.current;

            switch (currentStep) {
                case EFreightWizardTabStep.VIGENCY_TAB:
                    const modalConfirmationCancelModal = await this.showModalConfirmation(this.getTranslate('PRODUCT.ARE_YOU_SURE_YOU_WANT_TO_DISCARD_CHANGES'));
                    if (!modalConfirmationCancelModal) return;
                    break;

                case EFreightWizardTabStep.CONFIRMATION:
                    this.scope.model.FREIGHT_WIZARD_ROUTES.forEach(routes => {
                        routes.CHARGES_HISTORY = routes.CHARGES_HISTORY.filter((charge) => {
                            if (!charge.TEMPORARY) return charge;
                        });
                    })
            }

            this.back();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async nextStep(): Promise<void> {
        try {
            if (!this.checkPendingFields("freightWizardModalForm")) return;
            const currentStep: EFreightWizardTabStep = this.scope.steps.current;
            this.scope.isHistoryViewMode = false;

            switch (currentStep) {
                case EFreightWizardTabStep.ROUTES_TAB:
                    await this.validateRoutesTab();
                    break;

                case EFreightWizardTabStep.VIGENCY_TAB:
                    await this.validateVigencyTab();
                    break;

                case EFreightWizardTabStep.PREVIEW:
                    await this.validatePreviewTab();
                    break;

                case EFreightWizardTabStep.CONFIRMATION:
                    await this.validateConfirmationTab();
                    break;
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private next(): void {
        try {
            if (this.scope.steps.current == this.scope.steps.lastStep) return;
            this.scope.steps.current++;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private back(): void {
        try {
            if (this.scope.steps.current == this.scope.steps.firstStep) return;
            this.scope.steps.current--;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async validateRoutesTab(): Promise<void> {
        try {
            if (!this.hasFreightWizardRouteSelectedUpdateDate()) {
                const modalConfirmationNoVigency = await this.showModalConfirmation(this.getTranslate('PRODUCT.CONFIRM_WITHOUT_VIGENCY_SELECTED_ROUTES'));
                if (!modalConfirmationNoVigency) return;
            }

            if (this.hasFreightWizardRouteSelected()) {
                this.scope.selectedRoutesModel = angular.copy(this.scope.model);
                this.scope.oldModel = angular.copy(this.scope.model);
                this.scope.oldModel.FREIGHT_WIZARD_ROUTES = angular.copy(this.getFreightWizardRouteSelected());

                if (this.scope.model.VALIDITY_END.NEW_VALUE) {
                    const oldNewValue = {
                        FREIGHT_ROUTE_ID: null,
                        CHARGE_ID: null,
                        PROPERTY: EVigencyModificationProperties.FREIGHT_CONTRACT_VALIDITY_END,
                        NEW_VALUE: this.scope.model.VALIDITY_END.NEW_VALUE,
                        OLD_VALUE: this.scope.model.VALIDITY_END.OLD_VALUE,
                        IS_NEW: false,
                        OPERATION: EHistoryOperation.UPDATE,
                        HISTORY_TYPE: EHistoryType.FREIGHT_CONTRACT,
                        CHARGE: null
                    }

                    this.addHistory(oldNewValue, this.getTranslate('PRODUCT.CUSTOM_HISTORY_MESSAGE.CONTRACT_FREIGHT_VALIDITY_CHANGE', { old_contract_vigency_end_date: DateUtil.formatDate(this.scope.model.VALIDITY_END.OLD_VALUE, 'LL'), new_contract_vigency_end_date: DateUtil.formatDate(this.scope.model.VALIDITY_END.NEW_VALUE, 'LL'), freight_contract_name: this.scope.model.FREIGHT_CONTRACT.CONCATENATED }), false, EHistoryType.FREIGHT_CONTRACT);
                }

                this.updateRoutes();
                this.block();
                this.next();
                this.timeout(() => this.unblock(), 1000);

                this.scope.dateOptionsNewVigency.minDate = new Date(this.scope.model.FREIGHT_CONTRACT.VALIDITY_START);
                this.scope.dateOptionsNewVigency.maxDate = new Date(this.scope.model.FREIGHT_CONTRACT.VALIDITY_END);
                this.scope.dateOptionsCutDate.minDate = new Date(this.scope.model.FREIGHT_CONTRACT.VALIDITY_START);
                this.scope.dateOptionsCutDate.maxDate = new Date(this.scope.model.FREIGHT_CONTRACT.VALIDITY_END);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async validateVigencyTab(): Promise<void> {
        try {
            this.scope.displayPreview = angular.copy(this.scope.model);
            this.scope.displayPreview.FREIGHT_WIZARD_ROUTES = angular.copy(this.getFreightWizardRouteSelected());
            this.scope.oldModel.FREIGHT_WIZARD_ROUTES = angular.copy(this.getFreightWizardRouteSelected());
            this.scope.model.ACTION = null;

            this.specCheckboxUseRouteValidityDate(true);
            this.getDisplayConfirmationCharges(this.scope.displayPreview);
            this.sortDisplayConfirmationCharges(this.scope.displayPreview);
            this.disableAllHistoriesSelected();

            this.next();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async validatePreviewTab() {
        try {
            await this.generateFreightWizardStages();
            await this.getAndUpdateConfirmationList();

            if (this.scope.model._id) this.next();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async validateConfirmationTab(): Promise<void> {
        try {
            const routes = this.scope.confirmationOptions.map(confirmationOption => (confirmationOption.CONFIRMATION && confirmationOption.CONFIRMATION.ID_ROUTE) ? confirmationOption.CONFIRMATION.ID_ROUTE.toString() : null);
            this.goToFreightRoute(this.getCONCAT(routes));

            this.finishModal();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getFreightContractConfirmationLength(): number {
        try {
            const confirmOptions = this.scope.confirmationOptions.filter(confirm => confirm.CONFIRMATION.ID_CONTRACT != null);

            return confirmOptions && confirmOptions.length ? confirmOptions.length : 0;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getFreightRoutesConfirmationLength(): number {
        try {
            const confirmOptions = this.scope.confirmationOptions.filter(confirm => confirm.CONFIRMATION.ID_ROUTE != null);

            return confirmOptions && confirmOptions.length ? confirmOptions.length : 0;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getAndUpdateConfirmationList(): Promise<void> {
        this.initConfirmationSearchControl();

        const request: IMonacoRequest = {
            route: `/freightWizard/confirmation/${this.scope.model._id}`,
            timeout: 30000,
        };

        const resultOperation = await this.ProductService.get(request);
        if (resultOperation && resultOperation.data && resultOperation.data.data) {
            const result = resultOperation.data.data;
            if (result.SHOW) {
                this.scope.errorsOnFilter = result.PROCESS_WIZARD_ERROR_LIST;
                if (this.scope.errorsOnFilter && this.scope.errorsOnFilter.length) {
                    this.notifyError(this.getTranslate("GENERAL.ERROR_SENDING_REQUEST"));
                }
                this.scope.confirmationOptions = result.OPTIONS_CONFIRMATION;
                this.scope.hasAnyConfirmationOption = this.scope.confirmationOptions && this.scope.confirmationOptions.length > 0;
                this.scope.confirmationTabSearchControl.isSearchingTabDataList = false;
                this.scope.confirmationTabSearchControl.searchTabDataCurrentAttempt = 0;
                this.scope.$applyAsync();
            } else if (this.scope.confirmationTabSearchControl.searchTabDataCurrentAttempt == this.scope.confirmationTabSearchControl.searchTabDataMaxAttempt) {
                this.scope.confirmationTabSearchControl.isSearchingTabDataList = false;
                this.scope.$applyAsync();
                return this.notifyError(this.getTranslate("GENERAL.LIMIT_ATTEMPTS"))
            } else {
                this.scope.confirmationTabSearchControl.isSearchingTabDataList = true;
                this.scope.confirmationTabSearchControl.searchTabDataCurrentAttempt++;
                this.scope.confirmationTabSearchControl.searchTabDataListCount = 0;
                this.scope.$applyAsync();
                this.timeout(() => { this.initCountGetConfirmationTabDataList() }, 1000);
            }
        }
    }

    private initCountGetConfirmationTabDataList(): void {
        if (!this.scope.confirmationTabSearchControl.isSearchTabDataListCanceled) {
            this.timeout(() => {
                this.scope.confirmationTabSearchControl.searchTabDataListCount += 5;
                if (this.scope.confirmationTabSearchControl.searchTabDataListCount < 100) this.initCountGetConfirmationTabDataList();
                else if (!this.scope.confirmationTabSearchControl.isSearchTabDataListCanceled) this.timeout(() => { this.getAndUpdateConfirmationList() }, 1000);
            }, 100);
        }
    }

    private cancelConfirmationSearch() {
        this.scope.confirmationTabSearchControl.isSearchTabDataListCanceled = true;
        this.scope.confirmationTabSearchControl.isSearchingTabDataList = false;
        this.scope.confirmationTabSearchControl.searchTabDataCurrentAttempt = 0;
    }

    private updateRoutes() {
        try {
            const history: IHistory = { ID: uuid.v4(), NAME: null, DATE: new Date(), OLD_NEW_VALUE: [], SELECTED: false, IS_SELECTABLE: false, HISTORY_TYPE: EHistoryType.FREIGHT_ROUTES };
            const routesChanged = this.scope.model.FREIGHT_WIZARD_ROUTES.filter(route => route.VALIDITY_END.DISABLED_FIELD);

            if (routesChanged && routesChanged.length) {
                let routesConcatenated: string[] = []
                for (const route of routesChanged) {
                    if (route.SELECTED_UPDATE_DATE) {
                        routesConcatenated = routesConcatenated.concat(route.FREIGHT_ROUTE.CONCATENATED);
                        this.addHistoryOldNewValue(history, route.ID.toString(), null, "FREIGHT_ROUTE_VALIDITY_END", route.VALIDITY_END.NEW_VALUE, route.VALIDITY_END.OLD_VALUE, false, EHistoryOperation.UPDATE, null, null);
                    } else {
                        const history: IHistory = { ID: uuid.v4(), NAME: null, DATE: new Date(), OLD_NEW_VALUE: [], SELECTED: false, IS_SELECTABLE: false, HISTORY_TYPE: EHistoryType.FREIGHT_ROUTES };
                        this.addHistoryOldNewValue(history, route.ID.toString(), null, "FREIGHT_ROUTE_VALIDITY_END", route.VALIDITY_END.NEW_VALUE, route.VALIDITY_END.OLD_VALUE, false, EHistoryOperation.UPDATE, null, this.getTranslate('PRODUCT.CUSTOM_HISTORY_MESSAGE.ROUTE_FREIGHT_VIGENCY_MANUAL', { old_route_vigency_end_date: DateUtil.formatDate(route.VALIDITY_END.OLD_VALUE, "LL"), new_route_vigency_end_date: DateUtil.formatDate(route.VALIDITY_END.NEW_VALUE, "LL"), route_name: route.FREIGHT_ROUTE.CONCATENATED }));
                        this.scope.model.HISTORY.push(history);
                    }
                }

                const routesName = routesConcatenated.join('<br>');
                history.NAME = this.getTranslate('PRODUCT.CUSTOM_HISTORY_MESSAGE.ROUTE_FREIGHT_VIGENCY_CHANGE_TO_CONTRACT', { contract_vigency_end_date: DateUtil.formatDate(this.scope.model.VALIDITY_END.NEW_VALUE, "LL"), routes_names: routesName });
                if (history.OLD_NEW_VALUE.length) this.scope.model.HISTORY.push(history);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getDisplayConfirmationCharges(freightWizardFilter: IFreightWizardFilter) {
        try {
            if (!freightWizardFilter) return;

            for (const route of freightWizardFilter.FREIGHT_WIZARD_ROUTES) {
                route.CHARGES_HISTORY.forEach(charge => {
                    if (charge.HAS_CHANGES && !charge.IS_NEW && !charge.TEMPORARY) {
                        const copiedCharge = this.getChargeToChargeHistory(angular.copy(charge));
                        Object.assign(copiedCharge, { ID: uuid.v4(), ID_CHARGE_PARENT: charge.ID, TEMPORARY: true });

                        route.CHARGES_HISTORY.push(copiedCharge);
                    }
                });
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sortDisplayConfirmationCharges(freightWizardFilter: IFreightWizardFilter) {
        try {
            if (!freightWizardFilter) return;

            for (const route of freightWizardFilter.FREIGHT_WIZARD_ROUTES) {
                const displayConfirmationCharges = [];

                const displayCharge = route.CHARGES_HISTORY.filter(charge => charge.ID && !charge.TEMPORARY && !charge.ID_CHARGE_PARENT);
                const newDisplayCharge = route.CHARGES_HISTORY.filter(charge => charge.IS_NEW && charge.ID_CHARGE_PARENT === null);

                displayCharge.forEach(displayCharge => {
                    const findedMatchOld = route.CHARGES_HISTORY.find(charge => charge.TEMPORARY && displayCharge.ID === charge.ID_CHARGE_PARENT);
                    const findedCutDate = route.CHARGES_HISTORY.find(charge => charge.IS_NEW && displayCharge.ID === charge.ID_CHARGE_PARENT);

                    if (findedMatchOld) displayConfirmationCharges.push(findedMatchOld);
                    displayConfirmationCharges.push(displayCharge);
                    if (findedCutDate) displayConfirmationCharges.push(findedCutDate);
                })

                if (newDisplayCharge && newDisplayCharge.length) displayConfirmationCharges.push(...newDisplayCharge);
                route.CHARGES_HISTORY = displayConfirmationCharges;
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getChargeToChargeHistory(charge: IChargeHistory, disabledField: boolean = false): IChargeHistory {
        try {
            if (!charge) return null;

            return <IChargeHistory>{
                ...charge,
                FREIGHT_ROUTES_PAYMENT: {
                    ...charge.FREIGHT_ROUTES_PAYMENT,
                    CURRENCY: {
                        OLD_VALUE: charge.FREIGHT_ROUTES_PAYMENT.CURRENCY.OLD_VALUE,
                        NEW_VALUE: (disabledField) ? charge.FREIGHT_ROUTES_PAYMENT.CURRENCY.NEW_VALUE : charge.FREIGHT_ROUTES_PAYMENT.CURRENCY.OLD_VALUE,
                        DISABLED_FIELD: (disabledField) ? false : charge.FREIGHT_ROUTES_PAYMENT.CURRENCY.DISABLED_FIELD
                    },
                    UNITARY: {
                        OLD_VALUE: charge.FREIGHT_ROUTES_PAYMENT.UNITARY.OLD_VALUE,
                        NEW_VALUE: (disabledField) ? charge.FREIGHT_ROUTES_PAYMENT.UNITARY.NEW_VALUE : charge.FREIGHT_ROUTES_PAYMENT.UNITARY.OLD_VALUE,
                        DISABLED_FIELD: (disabledField) ? false : charge.FREIGHT_ROUTES_PAYMENT.UNITARY.DISABLED_FIELD
                    },
                    MIN: {
                        OLD_VALUE: charge.FREIGHT_ROUTES_PAYMENT.MIN.OLD_VALUE,
                        NEW_VALUE: (disabledField) ? charge.FREIGHT_ROUTES_PAYMENT.MIN.NEW_VALUE : charge.FREIGHT_ROUTES_PAYMENT.MIN.OLD_VALUE,
                        DISABLED_FIELD: (disabledField) ? false : charge.FREIGHT_ROUTES_PAYMENT.MIN.DISABLED_FIELD
                    },
                },
                FREIGHT_ROUTES_RECEIVING: {
                    ...charge.FREIGHT_ROUTES_RECEIVING,
                    CURRENCY: {
                        OLD_VALUE: charge.FREIGHT_ROUTES_RECEIVING.CURRENCY.OLD_VALUE,
                        NEW_VALUE: (disabledField) ? charge.FREIGHT_ROUTES_RECEIVING.CURRENCY.NEW_VALUE : charge.FREIGHT_ROUTES_RECEIVING.CURRENCY.OLD_VALUE,
                        DISABLED_FIELD: (disabledField) ? false : charge.FREIGHT_ROUTES_PAYMENT.CURRENCY.DISABLED_FIELD
                    },
                    UNITARY: {
                        OLD_VALUE: charge.FREIGHT_ROUTES_RECEIVING.UNITARY.OLD_VALUE,
                        NEW_VALUE: (disabledField) ? charge.FREIGHT_ROUTES_RECEIVING.UNITARY.NEW_VALUE : charge.FREIGHT_ROUTES_RECEIVING.UNITARY.OLD_VALUE,
                        DISABLED_FIELD: (disabledField) ? false : charge.FREIGHT_ROUTES_RECEIVING.UNITARY.DISABLED_FIELD
                    },
                    MIN: {
                        OLD_VALUE: charge.FREIGHT_ROUTES_RECEIVING.MIN.OLD_VALUE,
                        NEW_VALUE: (disabledField) ? charge.FREIGHT_ROUTES_RECEIVING.MIN.NEW_VALUE : charge.FREIGHT_ROUTES_RECEIVING.MIN.OLD_VALUE,
                        DISABLED_FIELD: (disabledField) ? false : charge.FREIGHT_ROUTES_RECEIVING.MIN.DISABLED_FIELD
                    },
                },
                VALIDITY_START: {
                    OLD_VALUE: charge.VALIDITY_START.OLD_VALUE,
                    NEW_VALUE: (disabledField) ? charge.VALIDITY_START.NEW_VALUE : charge.VALIDITY_START.OLD_VALUE,
                    DISABLED_FIELD: (disabledField) ? false : charge.VALIDITY_START.DISABLED_FIELD
                },
                VALIDITY_END: {
                    OLD_VALUE: charge.VALIDITY_END.OLD_VALUE,
                    NEW_VALUE: (disabledField) ? charge.VALIDITY_END.NEW_VALUE : charge.VALIDITY_END.OLD_VALUE,
                    DISABLED_FIELD: (disabledField) ? false : charge.VALIDITY_END.DISABLED_FIELD
                },
                IS_NEW: (disabledField) ? false : charge.IS_NEW
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sortValidityEnd(a: IFreightWizardRoute, b: IFreightWizardRoute): number {
        const dataA = new Date(a.VALIDITY_END.NEW_VALUE);
        const dataB = new Date(b.VALIDITY_END.NEW_VALUE);
        return dataA.getTime() - dataB.getTime();
    }

    private getLowestRouteValidity(): Date {
        const routes = this.getFreightWizardRouteSelected();
        routes.sort(this.sortValidityEnd);
        return routes[0].VALIDITY_END.NEW_VALUE;
    }

    private async openAddCharge() {
        const lowestRouteValidityEnd = this.getLowestRouteValidity();

        const charge: IFreightRoutesCharge = {
            _id: null,
            ID: null,
            ID_FREIGHT_ROUTES: null,
            DISPLAY_INDEX: null,
            EVENT: null,
            ID_CHARGE_NAME_EXHIBITION: null,
            CHARGE_NAME_EXHIBITION: null,
            APPLICATION: null,
            EQUIPMENT: null,
            WEIGHT_RANGE: null,
            FLAT: null,
            VEHICLE_TYPE: null,
            FREIGHT_ROUTES_PAYMENT: {
                ID_CURRENCY: null,
                CURRENCY: null,
                UNITARY: null,
                MIN: null,
                FREE_OF_CHARGE: this.scope.model.FREIGHT_CONTRACT.TRANSACTION && this.scope.model.FREIGHT_CONTRACT.TRANSACTION.ID == EPaymentNatureId.RECEIVING ? true : false,
                HOLDER_TYPE: null,
                ID_LEGAL_PERSON_HOLDER: null,
                ID_PHYSICAL_PERSON_HOLDER: null,
                LEGAL_PERSON_HOLDER: null,
                PHYSICAL_PERSON_HOLDER: null,
                WEIGHT_RANGE_CHARGE: null
            },
            FREIGHT_ROUTES_RECEIVING: {
                ID_CURRENCY: null,
                CURRENCY: null,
                UNITARY: null,
                MIN: null,
                FREE_OF_CHARGE: false,
                HOLDER_TYPE: null,
                ID_LEGAL_PERSON_HOLDER: null,
                ID_PHYSICAL_PERSON_HOLDER: null,
                LEGAL_PERSON_HOLDER: null,
                PHYSICAL_PERSON_HOLDER: null,
                WEIGHT_RANGE_CHARGE: null
            },
            DOCUMENT_ERROR: null,
            VALIDITY_START: null,
            VALIDITY_END: null
        };

        const modal = this.ModalService.newModal();
        const modalInstance = await this.ModalService.showModalInfo(
            {
                modalID: modal,
                formService: 'register',
                template: require("../view/modals/freightRoutesChargeModal.html"),
                size: 'vlg'
            },
            {
                actionButtonClass: 'btn-default',
                actionButtonText: 'GENERAL.CLOSE',
                headerText: 'REGISTRATION.FILES_WIZARD'
            },
            {
                freightContract: this.scope.model.FREIGHT_CONTRACT,
                charge: charge,
                lowestRouteValidityEnd: lowestRouteValidityEnd
            }
        );

        modalInstance.closed.then(() => this.scope.model.ACTION = null)

        const resultModalInstance = await modalInstance.result.then((result) => result.$value, (result) => result.$value);

        if (resultModalInstance) {
            const operation: EOperation = resultModalInstance.operation;
            const charge: IChargeHistory = this.parseChargeToChargeHistory(resultModalInstance.charge);
            const history: IHistory = { ID: uuid.v4(), NAME: null, DATE: new Date(), OLD_NEW_VALUE: [], SELECTED: false, IS_SELECTABLE: true, HISTORY_TYPE: EHistoryType.FREIGHT_ROUTES_CHARGES };

            switch (operation) {
                case EOperation.NEW:
                    this.handleAddNewCharge(charge, history);
            }
        }
    }

    private handleAddNewCharge(charge: IChargeHistory, history: IHistory) {
        try {
            if (!charge) return;

            const routeNames = [];
            for (const routes of this.getFreightWizardRouteSelected()) {
                charge.ID_FREIGHT_ROUTES = routes.ID;
                charge._id = uuid.v4();
                routeNames.push(routes.FREIGHT_ROUTE.CONCATENATED);

                this.addHistoryOldNewValue(history, charge.ID_FREIGHT_ROUTES.toString(), charge._id.toString(), "ADD_CHARGE", null, null, true, EHistoryOperation.CREATE, charge, null);
            }

            const fieldValue = `${this.getTranslate('FINANCIAL.PAYMENT')} ${this.getTranslate('GENERAL.MINIMUM')}: ${(charge.FREIGHT_ROUTES_PAYMENT.MIN.NEW_VALUE) ? charge.FREIGHT_ROUTES_PAYMENT.MIN.NEW_VALUE : this.getTranslate('GENERAL.EMPTY')}, ${this.getTranslate('FINANCIAL.PAYMENT')} ${this.getTranslate('GENERAL.UNITY')}: ${(charge.FREIGHT_ROUTES_PAYMENT.UNITARY.NEW_VALUE) ? charge.FREIGHT_ROUTES_PAYMENT.UNITARY.NEW_VALUE : this.getTranslate('GENERAL.EMPTY')}, ${this.getTranslate('FINANCIAL.RECEINVING')} ${this.getTranslate('GENERAL.UNITY')}: ${(charge.FREIGHT_ROUTES_RECEIVING.UNITARY.NEW_VALUE) ? charge.FREIGHT_ROUTES_RECEIVING.UNITARY.NEW_VALUE : this.getTranslate('GENERAL.EMPTY')}, ${this.getTranslate('FINANCIAL.RECEINVING')} ${this.getTranslate('GENERAL.MINIMUM')}: ${(charge.FREIGHT_ROUTES_RECEIVING.MIN.NEW_VALUE) ? charge.FREIGHT_ROUTES_RECEIVING.MIN.NEW_VALUE : this.getTranslate('GENERAL.EMPTY')}`
            history.NAME = this.getTranslate('PRODUCT.CUSTOM_HISTORY_MESSAGE.ADD_NEW_CHARGE', { charge_name: charge.CHARGE_NAME_EXHIBITION.NAME, fields_value: fieldValue, routes_names: routeNames.join('<br> ') })
            if (history.OLD_NEW_VALUE.length) this.scope.model.HISTORY.push(history);
            this.applyChargeHistoryToModel(history, this.scope.model.FREIGHT_WIZARD_ROUTES);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private parseChargeToChargeHistory(charge: IFreightRoutesCharge): IChargeHistory {
        try {
            if (!charge) return null;

            return <IChargeHistory>{
                _id: charge._id,
                ID: charge.ID,
                ID_CHARGE_PARENT: null,
                FREIGHT_ROUTES_PAYMENT: {
                    ID_CURRENCY: charge.FREIGHT_ROUTES_PAYMENT.ID_CURRENCY,
                    CURRENCY: {
                        OLD_VALUE: null,
                        NEW_VALUE: charge.FREIGHT_ROUTES_PAYMENT.CURRENCY,
                        DISABLED_FIELD: true
                    },
                    UNITARY: {
                        OLD_VALUE: null,
                        NEW_VALUE: charge.FREIGHT_ROUTES_PAYMENT.UNITARY,
                        DISABLED_FIELD: true
                    },
                    MIN: {
                        OLD_VALUE: null,
                        NEW_VALUE: charge.FREIGHT_ROUTES_PAYMENT.MIN,
                        DISABLED_FIELD: true
                    },
                    FREE_OF_CHARGE: charge.FREIGHT_ROUTES_PAYMENT.FREE_OF_CHARGE,
                    HOLDER_TYPE: charge.FREIGHT_ROUTES_PAYMENT.HOLDER_TYPE,
                    ID_LEGAL_PERSON_HOLDER: charge.FREIGHT_ROUTES_PAYMENT.ID_LEGAL_PERSON_HOLDER,
                    ID_PHYSICAL_PERSON_HOLDER: charge.FREIGHT_ROUTES_PAYMENT.ID_PHYSICAL_PERSON_HOLDER,
                    LEGAL_PERSON_HOLDER: charge.FREIGHT_ROUTES_PAYMENT.LEGAL_PERSON_HOLDER,
                    PHYSICAL_PERSON_HOLDER: charge.FREIGHT_ROUTES_PAYMENT.PHYSICAL_PERSON_HOLDER,
                    WEIGHT_RANGE_CHARGE: charge.FREIGHT_ROUTES_PAYMENT.WEIGHT_RANGE_CHARGE,
                },
                FREIGHT_ROUTES_RECEIVING: {
                    ID_CURRENCY: charge.FREIGHT_ROUTES_RECEIVING.ID_CURRENCY,
                    CURRENCY: {
                        OLD_VALUE: null,
                        NEW_VALUE: charge.FREIGHT_ROUTES_RECEIVING.CURRENCY,
                        DISABLED_FIELD: true
                    },
                    UNITARY: {
                        OLD_VALUE: null,
                        NEW_VALUE: charge.FREIGHT_ROUTES_RECEIVING.UNITARY,
                        DISABLED_FIELD: true
                    },
                    MIN: {
                        OLD_VALUE: null,
                        NEW_VALUE: charge.FREIGHT_ROUTES_RECEIVING.MIN,
                        DISABLED_FIELD: true
                    },
                    FREE_OF_CHARGE: charge.FREIGHT_ROUTES_RECEIVING.FREE_OF_CHARGE,
                    HOLDER_TYPE: charge.FREIGHT_ROUTES_RECEIVING.HOLDER_TYPE,
                    ID_LEGAL_PERSON_HOLDER: charge.FREIGHT_ROUTES_RECEIVING.ID_LEGAL_PERSON_HOLDER,
                    ID_PHYSICAL_PERSON_HOLDER: charge.FREIGHT_ROUTES_RECEIVING.ID_PHYSICAL_PERSON_HOLDER,
                    LEGAL_PERSON_HOLDER: charge.FREIGHT_ROUTES_RECEIVING.LEGAL_PERSON_HOLDER,
                    PHYSICAL_PERSON_HOLDER: charge.FREIGHT_ROUTES_RECEIVING.PHYSICAL_PERSON_HOLDER,
                    WEIGHT_RANGE_CHARGE: charge.FREIGHT_ROUTES_RECEIVING.WEIGHT_RANGE_CHARGE,
                },
                VALIDITY_START: {
                    OLD_VALUE: null,
                    NEW_VALUE: charge.VALIDITY_START,
                    DISABLED_FIELD: true
                },
                VALIDITY_END: {
                    OLD_VALUE: null,
                    NEW_VALUE: charge.VALIDITY_END,
                    DISABLED_FIELD: true
                },
                ID_FREIGHT_ROUTES: charge.ID_FREIGHT_ROUTES,
                ID_CHARGE_NAME_EXHIBITION: charge.ID_CHARGE_NAME_EXHIBITION,
                CHARGE_NAME_EXHIBITION: charge.CHARGE_NAME_EXHIBITION,
                APPLICATION: charge.APPLICATION,
                EQUIPMENT: charge.EQUIPMENT,
                WEIGHT_RANGE: charge.WEIGHT_RANGE,
                VEHICLE_TYPE: charge.VEHICLE_TYPE,
                FLAT: charge.FLAT,
                EVENT: charge.EVENT,
                IS_NEW: true,
                HAS_CHANGES: false,
                USE_ROUTE_VALIDITY_DATE: false
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async generateFreightWizardStages(): Promise<void> {
        try {
            this.block();

            const freightWizardFilter = angular.copy(this.scope.model);

            const request: IMonacoRequest = {
                data: { freightWizardFilter: freightWizardFilter },
                route: `/freightWizard/stages/generate`,
                timeout: 30000,
            };

            const rc = await this.ProductService.post(request, false);
            const result = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            this.scope.model._id = (result && result._id) ? result._id : null;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private hasAnyConfirmationOptionWithError(): boolean {
        return this.scope.confirmationOptions && this.scope.confirmationOptions.length ? this.scope.confirmationOptions.some(option => option.ERRORS && option.ERRORS.length) : false;
    }

    private retryGenerateAllFreightWizard(options: IFreightWizardConfirmation[]): void {
        if (options && options.length) {
            const optionsWithError = options.filter(option => option.ERRORS && option.ERRORS.length);

            if (optionsWithError && optionsWithError.length) {
                for (const option of optionsWithError) {
                    this.retryUpdateFreightWizard(option);
                }
            }
        }
    }

    private async retryUpdateFreightWizard(options: IFreightWizardConfirmation) {
        try {
            if (!options) return;
            this.block();

            const request: IMonacoRequest = {
                data: { data: options },
                route: `/freightWizard/stages/retryGenerate`,
                timeout: 30000,
            };

            const resultOperation = await this.ProductService.post(request);
            if (resultOperation && resultOperation.data && resultOperation.data.data) {
                this.unblock();
                await this.getAndUpdateConfirmationList();
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async retryConfirmationRequest(): Promise<void> {
        try {
            this.block();

            const request: IMonacoRequest = {
                data: { freightWizardFilterId: this.scope.model._id },
                route: `/freightWizard/stages/retryGenerate`,
                timeout: 30000,
            };

            const resultOperation = await this.ProductService.post(request);
            if (resultOperation && resultOperation.data && resultOperation.data.data) {
                this.unblock();
                await this.getAndUpdateConfirmationList();
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private initConfirmationSearchControl(): void {
        const confirmationTabSearchControl = this.scope.confirmationTabSearchControl;
        confirmationTabSearchControl.isSearchTabDataListCanceled = false;

        if (!confirmationTabSearchControl.isSearchingTabDataList) {
            confirmationTabSearchControl.searchTabDataListCount = 0;
            confirmationTabSearchControl.searchTabDataCurrentAttempt = 0;
            confirmationTabSearchControl.isSearchingTabDataList = true;
        }
    }

    private onHandleChargeConfirmationStyle(charge: IChargeHistory, field: IHistoryFreightWizardRoute<any>): object {
        try {
            if (!charge) return;
            const style: { [key: string]: string } = {};

            if (charge && charge.IS_NEW) {
                style['background-color'] = "#4682B4";
            } else if (charge && !charge.TEMPORARY && charge.HAS_CHANGES) {
                style['background-color'] = "lightcyan";
            } else {
                style['background-color'] = "#f5f5f5";
            }

            if (field && field.DISABLED_FIELD) {
                style['color'] = "#FF8C00";
                style['font-weight'] = "bold";
            }

            return style;
        } catch (ex) {
            this.handleError(ex);
            return {};
        }
    }

    private onShowHistory(history: IHistory): void {
        try {
            if (!history) return;
            if (!history.IS_SELECTABLE) return;

            history.SELECTED = !history.SELECTED;

            if (history.SELECTED) {
                const histories = angular.copy(this.scope.model.HISTORY);
                const selectedRoutes = angular.copy(this.getFreightWizardRouteSelected());

                for (const route of selectedRoutes) {
                    route.CHARGES_HISTORY = route.CHARGES_HISTORY.map(charge => this.getChargeToChargeHistory(charge, true));
                }

                for (const oldNewValue of history.OLD_NEW_VALUE) {
                    const findedFreightRoute: IFreightWizardRoute = selectedRoutes.find(route => route.FREIGHT_ROUTE.ID.toString() === oldNewValue.FREIGHT_ROUTE_ID);
                    const findedCharge: IChargeHistory = findedFreightRoute && findedFreightRoute.CHARGES_HISTORY.find(charge => charge._id === oldNewValue.CHARGE_ID);

                    switch (oldNewValue.OPERATION) {
                        case EHistoryOperation.CREATE:
                            const chargeIndex = findedFreightRoute.CHARGES_HISTORY.indexOf(findedCharge);
                            findedFreightRoute.CHARGES_HISTORY.splice(chargeIndex, 1);
                            this.create(oldNewValue, findedFreightRoute);
                            break;

                        case EHistoryOperation.UPDATE:
                            this.update(findedCharge, oldNewValue, findedFreightRoute);
                            break;
                    }
                }

                this.scope.oldModel.FREIGHT_WIZARD_ROUTES = angular.copy(selectedRoutes);
                this.scope.oldModel.HISTORY = histories;
                this.scope.isHistoryViewMode = true;
                this.scope.model.ACTION = null;
            } else {
                this.scope.oldModel.FREIGHT_WIZARD_ROUTES = angular.copy(this.getFreightWizardRouteSelected());
                this.scope.isHistoryViewMode = false;
            }

            this.disableAllHistoriesSelected(history);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private disableAllHistoriesSelected(exceptionHistory?: IHistory): void {
        try {
            for (const history of this.scope.model.HISTORY) {
                if (!exceptionHistory || exceptionHistory && exceptionHistory.ID !== history.ID) {
                    history.SELECTED = false;
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private undoCreate(charge: IChargeHistory, history: IHistoryOldNewValue, freightRoute: IFreightWizardRoute): void {
        try {
            if (!charge) return;
            if (!history) return;
            if (!freightRoute) return;
            if (!charge.IS_NEW) return;

            const index: number = freightRoute.CHARGES_HISTORY.findIndex(charge => charge._id === history.CHARGE_ID);
            const chargeBase: IChargeHistory = freightRoute.CHARGES_HISTORY.find(charge => charge.ID == history.CHARGE.ID_CHARGE_PARENT);
            if (chargeBase) chargeBase.HIDE = false;

            if (index !== -1) {
                freightRoute.CHARGES_HISTORY.splice(index, 1);

                for (const histories of this.scope.model.HISTORY) {
                    histories.OLD_NEW_VALUE = histories.OLD_NEW_VALUE.filter(oldNewValue => oldNewValue.CHARGE_ID !== history.CHARGE_ID);
                    if (!histories.OLD_NEW_VALUE.length) {
                        const index = this.scope.model.HISTORY.indexOf(histories);
                        if (index !== -1) {
                            this.scope.model.HISTORY.splice(index, 1);
                        }
                    }
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private undoUpdate(charge: IChargeHistory, history: IHistoryOldNewValue, freightRoute: IFreightWizardRoute): void {
        try {
            if (!history) return;

            switch (history.PROPERTY) {
                case EVigencyModificationProperties.FREIGHT_CONTRACT_VALIDITY_END:
                    this.scope.model.FREIGHT_CONTRACT.VALIDITY_END = new Date(history.OLD_VALUE);
                    this.scope.selectedRoutesModel.FREIGHT_CONTRACT.VALIDITY_END = new Date(history.OLD_VALUE);
                    this.scope.selectedRoutesModel.VALIDITY_END.NEW_VALUE = null;
                    this.scope.selectedRoutesModel.UPDATE_VALIDITY_DATES = false;
                    this.generateConcatenatedFreightContract();
                    this.scope.selectedRoutesModel.FREIGHT_CONTRACT.CONCATENATED = this.scope.model.FREIGHT_CONTRACT.CONCATENATED;
                    break;

                case EVigencyModificationProperties.FREIGHT_ROUTE_VALIDITY_END:
                    const route = this.scope.selectedRoutesModel.FREIGHT_WIZARD_ROUTES.find(route => route.ID === freightRoute.ID);
                    freightRoute.VALIDITY_END.NEW_VALUE = history.OLD_VALUE;
                    freightRoute.VALIDITY_END.DISABLED_FIELD = false;
                    route.VALIDITY_END.NEW_VALUE = history.OLD_VALUE;
                    route.VALIDITY_END.DISABLED_FIELD = false;
                    this.generateConcatenatedFreightRoute(freightRoute);
                    route.FREIGHT_ROUTE.CONCATENATED = freightRoute.FREIGHT_ROUTE.CONCATENATED;
                    route.SELECTED_UPDATE_DATE = false;
                    break;

                case EVigencyModificationProperties.CHARGE_VALIDITY_START:
                    charge.VALIDITY_START.NEW_VALUE = history.OLD_VALUE;
                    charge.VALIDITY_START.DISABLED_FIELD = false;
                    charge.HAS_CHANGES = false;
                    break;

                case EVigencyModificationProperties.CHARGE_VALIDITY_END:
                    charge.VALIDITY_END.NEW_VALUE = history.OLD_VALUE;
                    charge.VALIDITY_END.DISABLED_FIELD = false;
                    charge.HAS_CHANGES = false;
                    break;

                case EValueModificationProperties.CHARGE_PAYMENT_CURRENCY:
                    charge.FREIGHT_ROUTES_PAYMENT.CURRENCY.NEW_VALUE = history.OLD_VALUE;
                    charge.FREIGHT_ROUTES_PAYMENT.CURRENCY.DISABLED_FIELD = false;
                    charge.HAS_CHANGES = false;
                    break;

                case EValueModificationProperties.CHARGE_PAYMENT_UNITARY:
                    charge.FREIGHT_ROUTES_PAYMENT.UNITARY.NEW_VALUE = history.OLD_VALUE;
                    charge.FREIGHT_ROUTES_PAYMENT.UNITARY.DISABLED_FIELD = false;
                    charge.HAS_CHANGES = false;
                    break;

                case EValueModificationProperties.CHARGE_PAYMENT_MIN:
                    charge.FREIGHT_ROUTES_PAYMENT.MIN.NEW_VALUE = history.OLD_VALUE;
                    charge.FREIGHT_ROUTES_PAYMENT.MIN.DISABLED_FIELD = false;
                    charge.HAS_CHANGES = false;
                    break;

                case EValueModificationProperties.CHARGE_RECEIVING_CURRENCY:
                    charge.FREIGHT_ROUTES_RECEIVING.CURRENCY.NEW_VALUE = history.OLD_VALUE;
                    charge.FREIGHT_ROUTES_RECEIVING.CURRENCY.DISABLED_FIELD = false;
                    charge.HAS_CHANGES = false;
                    break;

                case EValueModificationProperties.CHARGE_RECEIVING_UNITARY:
                    charge.FREIGHT_ROUTES_RECEIVING.UNITARY.NEW_VALUE = history.OLD_VALUE;
                    charge.FREIGHT_ROUTES_RECEIVING.UNITARY.DISABLED_FIELD = false;
                    charge.HAS_CHANGES = false;
                    break;

                case EValueModificationProperties.CHARGE_RECEIVING_MIN:
                    charge.FREIGHT_ROUTES_RECEIVING.MIN.NEW_VALUE = history.OLD_VALUE;
                    charge.FREIGHT_ROUTES_RECEIVING.MIN.DISABLED_FIELD = false;
                    charge.HAS_CHANGES = false;
                    break;
            }

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private update(charge: IChargeHistory, history: IHistoryOldNewValue, findedFreightRoute: IFreightWizardRoute): void {
        try {
            if (!history) return;

            switch (history.PROPERTY) {
                case EVigencyModificationProperties.FREIGHT_CONTRACT_VALIDITY_END:
                    this.scope.model.FREIGHT_CONTRACT.VALIDITY_END = new Date(history.NEW_VALUE);
                    this.scope.selectedRoutesModel.FREIGHT_CONTRACT.VALIDITY_END = new Date(history.NEW_VALUE);
                    this.generateConcatenatedFreightContract();
                    break;

                case EVigencyModificationProperties.FREIGHT_ROUTE_VALIDITY_END:
                    findedFreightRoute.VALIDITY_END.NEW_VALUE = history.NEW_VALUE;
                    this.generateConcatenatedFreightRoute(findedFreightRoute);
                    break;

                case EVigencyModificationProperties.CHARGE_VALIDITY_START:
                    charge.VALIDITY_START.NEW_VALUE = history.NEW_VALUE;
                    charge.VALIDITY_START.DISABLED_FIELD = true;
                    charge.HAS_CHANGES = true;
                    break;

                case EVigencyModificationProperties.CHARGE_VALIDITY_END:
                    charge.VALIDITY_END.NEW_VALUE = history.NEW_VALUE;
                    charge.VALIDITY_END.DISABLED_FIELD = true;
                    charge.HAS_CHANGES = true;
                    break;

                case EValueModificationProperties.CHARGE_PAYMENT_CURRENCY:
                    charge.FREIGHT_ROUTES_PAYMENT.CURRENCY.NEW_VALUE = history.NEW_VALUE;
                    charge.FREIGHT_ROUTES_PAYMENT.CURRENCY.DISABLED_FIELD = true;
                    charge.HAS_CHANGES = true;
                    break;

                case EValueModificationProperties.CHARGE_PAYMENT_UNITARY:
                    charge.FREIGHT_ROUTES_PAYMENT.UNITARY.NEW_VALUE = history.NEW_VALUE;
                    charge.FREIGHT_ROUTES_PAYMENT.UNITARY.DISABLED_FIELD = true;
                    charge.HAS_CHANGES = true;
                    break;

                case EValueModificationProperties.CHARGE_PAYMENT_MIN:
                    charge.FREIGHT_ROUTES_PAYMENT.MIN.NEW_VALUE = history.NEW_VALUE;
                    charge.FREIGHT_ROUTES_PAYMENT.MIN.DISABLED_FIELD = true;
                    charge.HAS_CHANGES = true;
                    break;

                case EValueModificationProperties.CHARGE_RECEIVING_CURRENCY:
                    charge.FREIGHT_ROUTES_RECEIVING.CURRENCY.NEW_VALUE = history.NEW_VALUE;
                    charge.FREIGHT_ROUTES_RECEIVING.CURRENCY.DISABLED_FIELD = true;
                    charge.HAS_CHANGES = true;
                    break;

                case EValueModificationProperties.CHARGE_RECEIVING_UNITARY:
                    charge.FREIGHT_ROUTES_RECEIVING.UNITARY.NEW_VALUE = history.NEW_VALUE;
                    charge.FREIGHT_ROUTES_RECEIVING.UNITARY.DISABLED_FIELD = true;
                    charge.HAS_CHANGES = true;
                    break;

                case EValueModificationProperties.CHARGE_RECEIVING_MIN:
                    charge.FREIGHT_ROUTES_RECEIVING.MIN.NEW_VALUE = history.NEW_VALUE;
                    charge.FREIGHT_ROUTES_RECEIVING.MIN.DISABLED_FIELD = true;
                    charge.HAS_CHANGES = true;
                    break;
            }

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private create(history: IHistoryOldNewValue, freightRoute: IFreightWizardRoute): void {
        try {
            if (!history) return;

            if (history.IS_NEW) {
                freightRoute.CHARGES_HISTORY.push(history.CHARGE);
                this.resetExistingChargeValidity(freightRoute, history);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private resetExistingChargeValidity(freightRoute: IFreightWizardRoute, history: IHistoryOldNewValue): void {
        try {
            const oldCharge = freightRoute.CHARGES_HISTORY.find(charge => charge.ID == history.CHARGE.ID_CHARGE_PARENT);
            if (oldCharge && oldCharge.VALIDITY_END.OLD_VALUE) oldCharge.VALIDITY_END.NEW_VALUE = oldCharge.VALIDITY_END.OLD_VALUE;
            oldCharge.HIDE = history.CHARGE.HIDE;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async onDeleteHistory(history: IHistory): Promise<void> {
        try {
            if (!history) return;

            const modalConfirmationCancelModal = await this.showModalConfirmation(this.getTranslate('PRODUCT.ARE_YOU_SURE_YOU_WANT_TO_DISCARD_CHANGES'));
            if (!modalConfirmationCancelModal) return;

            const historyOldNewValue: IHistoryOldNewValue[] = history.OLD_NEW_VALUE;
            const modelRoutes: IFreightWizardRoute[] = this.scope.model.FREIGHT_WIZARD_ROUTES;
            const modelHistories: IHistory[] = this.scope.model.HISTORY;
            const otherHistories = modelHistories.filter(e => e.ID !== history.ID);

            const valueModificationProperties: any[] = [
                EValueModificationProperties.CHARGE_PAYMENT_CURRENCY,
                EValueModificationProperties.CHARGE_PAYMENT_UNITARY,
                EValueModificationProperties.CHARGE_PAYMENT_MIN,
                EValueModificationProperties.CHARGE_RECEIVING_CURRENCY,
                EValueModificationProperties.CHARGE_RECEIVING_UNITARY,
                EValueModificationProperties.CHARGE_RECEIVING_MIN
            ];

            const updatedHistories = otherHistories.filter((other: IHistory) =>
                other.OLD_NEW_VALUE.some((oldNew: any) =>
                    oldNew.OPERATION == EHistoryOperation.UPDATE
                )
            );
            const hasValueModification = (history: IHistory) => history.OLD_NEW_VALUE.some((oldNew) => valueModificationProperties.includes(oldNew.PROPERTY));

            const isUpdatedHistoriesValueModified = updatedHistories.some(hasValueModification);

            if (isUpdatedHistoriesValueModified && !hasValueModification(history)) {
                this.handleError(this.getTranslate('PRODUCT.ERROR_DELETE_VIGENCY_ADD_HISTORY'));
                return;
            }
            for (const oldNewValue of historyOldNewValue) {
                const findedFreightRoute = modelRoutes.find(route => route.FREIGHT_ROUTE.ID.toString() === oldNewValue.FREIGHT_ROUTE_ID);
                const findedCharge = findedFreightRoute && findedFreightRoute.CHARGES_HISTORY.find(charge => charge._id === oldNewValue.CHARGE_ID);

                switch (oldNewValue.OPERATION) {
                    case EHistoryOperation.CREATE:
                        if (findedCharge) this.undoCreate(findedCharge, oldNewValue, findedFreightRoute)
                        break;

                    case EHistoryOperation.UPDATE:
                        this.undoUpdate(findedCharge, oldNewValue, findedFreightRoute);
                        break;
                }
            }

            const histories = angular.copy(this.scope.model.HISTORY.filter(e => e.ID !== history.ID));
            this.scope.model = angular.copy(this.scope.selectedRoutesModel);
            this.scope.model.HISTORY = histories;
            this.scope.isHistoryViewMode = false;

            for (const model of this.scope.model.HISTORY) {
                this.applyChargeHistoryToModel(model, this.scope.model.FREIGHT_WIZARD_ROUTES)
            }

            this.scope.oldModel.FREIGHT_WIZARD_ROUTES = angular.copy(this.getFreightWizardRouteSelected());
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private applyNewVigencyAndCutDate(charge: IChargeHistory, cutDate: Date, newVigency: Date, history: IHistory, route: IFreightWizardRoute): boolean {
        try {
            if (!charge) return false;
            if (!history) return false;
            if (charge.VALIDITY_END.DISABLED_FIELD) return false;
            if (!this.isValidDateByRouteVigency(newVigency, route)) return false
            if (!this.isValidDateByRouteVigency(cutDate, route)) return false

            if (this.applyNewVigency(charge, newVigency, history, route) && this.applyCutDate(charge, cutDate, history, route)) return true;
            else return false;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private applyCutDate(charge: IChargeHistory, cutDate: Date, history: IHistory, route: IFreightWizardRoute): boolean {
        try {
            if (!charge) return false;
            if (!this.isValidDateByRouteVigency(cutDate, route)) return false

            const chargeCopied = angular.copy(charge);
            Object.assign(chargeCopied, {
                ID: uuid.v4(),
                _id: uuid.v4(),
                ID_CHARGE_PARENT: charge.ID,
                IS_NEW: true,
                VALIDITY_START: {
                    ...charge.VALIDITY_START,
                    NEW_VALUE: DateUtil.addDays(cutDate, 1),
                    DISABLED_FIELD: true
                },
                VALIDITY_END: {
                    ...angular.copy(charge.VALIDITY_END),
                    DISABLED_FIELD: true
                },
            });

            Object.assign(charge, {
                HAS_CHANGES: true,
                VALIDITY_END: {
                    ...charge.VALIDITY_END,
                    NEW_VALUE: cutDate,
                    DISABLED_FIELD: true
                }
            })

            this.addHistoryOldNewValue(history, chargeCopied.ID_FREIGHT_ROUTES.toString(), chargeCopied._id.toString(), "ADD_CHARGE", null, null, true, EHistoryOperation.CREATE, chargeCopied, null);
            this.addHistoryOldNewValue(history, charge.ID_FREIGHT_ROUTES.toString(), charge._id.toString(), "CHARGE_VALIDITY_END", cutDate, charge.VALIDITY_END.OLD_VALUE, false, EHistoryOperation.UPDATE, charge, null);

            return true;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private applyNewVigency(charge: IChargeHistory, newVigency: Date, history: IHistory, route: IFreightWizardRoute): boolean {
        try {
            if (!charge) return false;
            if (charge.VALIDITY_END.DISABLED_FIELD) return false;
            if (!this.isValidDateByRouteVigency(newVigency, route)) return false

            charge.VALIDITY_END.NEW_VALUE = newVigency;
            charge.VALIDITY_END.DISABLED_FIELD = true;
            charge.HAS_CHANGES = true;

            this.addHistoryOldNewValue(history, charge.ID_FREIGHT_ROUTES.toString(), charge._id.toString(), "CHARGE_VALIDITY_END", charge.VALIDITY_END.NEW_VALUE, charge.VALIDITY_END.OLD_VALUE, false, EHistoryOperation.UPDATE, charge, `Alteração em massa: Data de Nova vigencia: ${DateUtil.formatDate(newVigency, 'LL')}`);
            return true;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private addHistoryOldNewValue(history: IHistory, freightRouteId: string, chargeId: string, property: string, newValue: any, oldValue: any, isNew: boolean, operation: EHistoryOperation, charge: IChargeHistory, historyName: string): void {
        try {
            const oldNewValue: IHistoryOldNewValue = {
                FREIGHT_ROUTE_ID: freightRouteId,
                CHARGE_ID: chargeId,
                PROPERTY: property,
                NEW_VALUE: newValue,
                OLD_VALUE: oldValue,
                IS_NEW: isNew,
                OPERATION: operation,
                CHARGE: charge,
            }

            history.NAME = historyName;
            history.OLD_NEW_VALUE.push(oldNewValue);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private onHandleAlterChargeVigencyValue(): void {
        try {
            if (!this.checkPendingFields("freightWizardModal")) return;

            const isRouteCheckboxSelected = this.scope.useRouteValidityDate;
            const field: IField = this.scope.model.ACTION && this.scope.model.ACTION.ALTER_VALUES && this.scope.model.ACTION.ALTER_VALUES.FIELD;
            const type: IType = this.scope.model.ACTION && this.scope.model.ACTION.ALTER_VALUES && this.scope.model.ACTION.ALTER_VALUES.TYPE;
            const value: number = this.scope.model.ACTION && this.scope.model.ACTION.ALTER_VALUES && this.scope.model.ACTION.ALTER_VALUES.VALUE;
            const cutDate: Date = this.scope.model.ACTION && this.scope.model.ACTION.ALTER_VIGENCY && this.scope.model.ACTION.ALTER_VIGENCY.CUT_DATE;

            this.alterVigency(cutDate, field, isRouteCheckboxSelected);
            this.alterValue(field, value, type);

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private onHandleAlterChargeVigency(): void {
        try {
            if (!this.checkPendingFields("freightWizardModal")) return;

            const isRouteCheckboxSelected = this.scope.useRouteValidityDate;
            const field: IField = this.scope.model.ACTION && this.scope.model.ACTION.ALTER_VALUES && this.scope.model.ACTION.ALTER_VALUES.FIELD;
            const cutDate: Date = this.scope.model.ACTION && this.scope.model.ACTION.ALTER_VIGENCY && this.scope.model.ACTION.ALTER_VIGENCY.CUT_DATE;

            this.alterVigency(cutDate, field, isRouteCheckboxSelected);

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private onHandleAlterChargeValue(): void {
        try {
            if (!this.checkPendingFields("freightWizardModal")) return;

            const field: IField = this.scope.model.ACTION && this.scope.model.ACTION.ALTER_VALUES && this.scope.model.ACTION.ALTER_VALUES.FIELD;
            const type: IType = this.scope.model.ACTION && this.scope.model.ACTION.ALTER_VALUES && this.scope.model.ACTION.ALTER_VALUES.TYPE;
            const value: number = this.scope.model.ACTION && this.scope.model.ACTION.ALTER_VALUES && this.scope.model.ACTION.ALTER_VALUES.VALUE;

            this.alterValue(field, value, type);

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private duplicateCharge(charge: IChargeHistory, route: IFreightWizardRoute, history: IHistory, isManual: boolean = false) {
        if (!charge) return false;
        if (!route) return false;

        if (!charge.VALIDITY_END.DISABLED_FIELD || !charge.VALIDITY_START.DISABLED_FIELD) {
            const routeFinded = this.scope.model.FREIGHT_WIZARD_ROUTES.find(_route => _route.ID == route.ID);
            const chargeFinded = routeFinded.CHARGES_HISTORY.filter(e => e.ID_CHARGE_PARENT == charge.ID);

            if (!chargeFinded.length) {
                history = { ID: uuid.v4(), NAME: null, DATE: new Date(), OLD_NEW_VALUE: [], SELECTED: false, IS_SELECTABLE: true, HISTORY_TYPE: EHistoryType.FREIGHT_ROUTES_CHARGES };

                const chargeCopied = angular.copy(charge);
                Object.assign(chargeCopied, {
                    ID: uuid.v4(),
                    _id: uuid.v4(),
                    ID_CHARGE_PARENT: charge.ID,
                    IS_NEW: true,
                    VALIDITY_START: {
                        ...charge.VALIDITY_START,
                        NEW_VALUE: DateUtil.addDays(charge.VALIDITY_END.OLD_VALUE, 1),
                        DISABLED_FIELD: true
                    },
                    VALIDITY_END: {
                        ...angular.copy(charge.VALIDITY_END),
                        DISABLED_FIELD: true
                    },
                });

                Object.assign(charge, {
                    VALIDITY_END: {
                        ...charge.VALIDITY_END,
                        NEW_VALUE: charge.VALIDITY_END.OLD_VALUE,
                        DISABLED_FIELD: true
                    },
                    HIDE: isManual
                });

                const fieldValue = `${this.getTranslate('FINANCIAL.PAYMENT')} ${this.getTranslate('GENERAL.MINIMUM')}: ${(chargeCopied.FREIGHT_ROUTES_PAYMENT.MIN.NEW_VALUE) ? chargeCopied.FREIGHT_ROUTES_PAYMENT.MIN.NEW_VALUE : this.getTranslate('GENERAL.EMPTY')}, ${this.getTranslate('FINANCIAL.PAYMENT')} ${this.getTranslate('GENERAL.UNITY')}: ${(chargeCopied.FREIGHT_ROUTES_PAYMENT.UNITARY.NEW_VALUE) ? chargeCopied.FREIGHT_ROUTES_PAYMENT.UNITARY.NEW_VALUE : this.getTranslate('GENERAL.EMPTY')}, ${this.getTranslate('FINANCIAL.RECEINVING')} ${this.getTranslate('GENERAL.UNITY')}: ${(chargeCopied.FREIGHT_ROUTES_RECEIVING.UNITARY.NEW_VALUE) ? chargeCopied.FREIGHT_ROUTES_RECEIVING.UNITARY.NEW_VALUE : this.getTranslate('GENERAL.EMPTY')}, ${this.getTranslate('FINANCIAL.RECEINVING')} ${this.getTranslate('GENERAL.MINIMUM')}: ${(chargeCopied.FREIGHT_ROUTES_RECEIVING.MIN.NEW_VALUE) ? chargeCopied.FREIGHT_ROUTES_RECEIVING.MIN.NEW_VALUE : this.getTranslate('GENERAL.EMPTY')}`
                this.addHistoryOldNewValue(history, chargeCopied.ID_FREIGHT_ROUTES.toString(), chargeCopied._id.toString(), "ADD_CHARGE", null, null, true, EHistoryOperation.CREATE, chargeCopied, this.getTranslate('PRODUCT.CUSTOM_HISTORY_MESSAGE.ADD_NEW_CHARGE', { charge_name: charge.CHARGE_NAME_EXHIBITION.NAME, fields_value: fieldValue, routes_names: [routeFinded.FREIGHT_ROUTE.CONCATENATED].join('<br> ') }));

                this.applyChargeHistoryToModel(history, this.getFreightWizardRouteSelected());
                if (isManual) this.scope.model.HISTORY.push(history);

                return true;
            }
        }
    }

    private duplicateChargeStrategy(charge: IChargeHistory, fieldId: string): boolean {
        try {
            switch (fieldId) {
                case 'PAYMENT_MIN':
                    charge.FREIGHT_ROUTES_PAYMENT.MIN.NEW_VALUE = charge.FREIGHT_ROUTES_PAYMENT.MIN.OLD_VALUE;
                    break;

                case 'PAYMENT_UNITARY':
                    charge.FREIGHT_ROUTES_PAYMENT.UNITARY.NEW_VALUE = charge.FREIGHT_ROUTES_PAYMENT.UNITARY.OLD_VALUE;
                    break;

                case 'RECEIVING_MIN':
                    charge.FREIGHT_ROUTES_RECEIVING.MIN.NEW_VALUE = charge.FREIGHT_ROUTES_RECEIVING.MIN.OLD_VALUE;
                    break;

                case 'RECEIVING_UNITARY':
                    charge.FREIGHT_ROUTES_RECEIVING.UNITARY.NEW_VALUE = charge.FREIGHT_ROUTES_RECEIVING.UNITARY.OLD_VALUE;
                    break;

                case 'RECEIVING_CURRENCY':
                    charge.FREIGHT_ROUTES_RECEIVING.CURRENCY.NEW_VALUE = charge.FREIGHT_ROUTES_RECEIVING.CURRENCY.OLD_VALUE;
                    break;

                case 'PAYMENT_CURRENCY':
                    charge.FREIGHT_ROUTES_PAYMENT.CURRENCY.NEW_VALUE = charge.FREIGHT_ROUTES_PAYMENT.CURRENCY.OLD_VALUE;
                    break;

                case 'ALL_FIELDS':
                    charge.FREIGHT_ROUTES_PAYMENT.MIN.NEW_VALUE = charge.FREIGHT_ROUTES_PAYMENT.MIN.OLD_VALUE;
                    charge.FREIGHT_ROUTES_PAYMENT.UNITARY.NEW_VALUE = charge.FREIGHT_ROUTES_PAYMENT.UNITARY.OLD_VALUE;
                    charge.FREIGHT_ROUTES_RECEIVING.MIN.NEW_VALUE = charge.FREIGHT_ROUTES_RECEIVING.MIN.OLD_VALUE;
                    charge.FREIGHT_ROUTES_RECEIVING.UNITARY.NEW_VALUE = charge.FREIGHT_ROUTES_RECEIVING.UNITARY.OLD_VALUE;
                    break;
            }

            return true;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private restoreOriginalChargeValues(history: IHistory): void {
        for (const hist of history.OLD_NEW_VALUE) {
            if (hist.CHARGE && hist.CHARGE.IS_NEW) continue
            hist.NEW_VALUE = hist.OLD_VALUE;
        }
    }

    private clearFields(): void {
        this.clearFilterFields();
        this.clearActionFields();
        this.specCheckboxUseRouteValidityDate(true);

        this.scope.useRouteValidityDate = false;
    }

    private clearFilterFields(): void {
        this.scope.model.FILTER = {
            EQUIPMENT: null,
            CURRENCY: null,
            CHARGE_NAME_EXHIBITION: null,
            REFERENCE_DATE: null,
            APPLICATION: null,
        };
    }

    private clearActionFields(): void {
        if (this.scope.model.ACTION) {
            this.scope.model.ACTION.ALTER_VIGENCY = {
                CUT_DATE: null,
                NEW_VIGENCY: null,
            };
            this.scope.model.ACTION.ALTER_VALUES = {
                FIELD: null,
                TYPE: null,
                VALUE: null,
            };
        }
    }

    private applyAlterValues(charge: IChargeHistory, field: IField, type: IType, value: number, history: IHistory): boolean {
        try {
            switch (field.ID) {
                case 'PAYMENT_MIN':
                    charge.HAS_CHANGES = true;
                    charge.FREIGHT_ROUTES_PAYMENT.MIN = this.applyAlterValuesByType(charge.FREIGHT_ROUTES_PAYMENT.MIN, field, type, value, history);
                    this.addHistoryOldNewValue(history, charge.ID_FREIGHT_ROUTES.toString(), charge._id.toString(), EValueModificationProperties.CHARGE_PAYMENT_MIN, charge.FREIGHT_ROUTES_PAYMENT.MIN.NEW_VALUE, charge.FREIGHT_ROUTES_PAYMENT.MIN.OLD_VALUE, false, EHistoryOperation.UPDATE, charge, `teste`);
                    break;

                case 'PAYMENT_UNITARY':
                    charge.HAS_CHANGES = true;
                    charge.FREIGHT_ROUTES_PAYMENT.UNITARY = this.applyAlterValuesByType(charge.FREIGHT_ROUTES_PAYMENT.UNITARY, field, type, value, history);
                    this.addHistoryOldNewValue(history, charge.ID_FREIGHT_ROUTES.toString(), charge._id.toString(), EValueModificationProperties.CHARGE_PAYMENT_UNITARY, charge.FREIGHT_ROUTES_PAYMENT.UNITARY.NEW_VALUE, charge.FREIGHT_ROUTES_PAYMENT.UNITARY.OLD_VALUE, false, EHistoryOperation.UPDATE, charge, 'teste');
                    break;

                case 'RECEIVING_MIN':
                    charge.HAS_CHANGES = true;
                    charge.FREIGHT_ROUTES_RECEIVING.MIN = this.applyAlterValuesByType(charge.FREIGHT_ROUTES_RECEIVING.MIN, field, type, value, history);
                    this.addHistoryOldNewValue(history, charge.ID_FREIGHT_ROUTES.toString(), charge._id.toString(), EValueModificationProperties.CHARGE_RECEIVING_MIN, charge.FREIGHT_ROUTES_RECEIVING.MIN.NEW_VALUE, charge.FREIGHT_ROUTES_RECEIVING.MIN.OLD_VALUE, false, EHistoryOperation.UPDATE, charge, 'teste');
                    break;

                case 'RECEIVING_UNITARY':
                    charge.HAS_CHANGES = true;
                    charge.FREIGHT_ROUTES_RECEIVING.UNITARY = this.applyAlterValuesByType(charge.FREIGHT_ROUTES_RECEIVING.UNITARY, field, type, value, history);
                    this.addHistoryOldNewValue(history, charge.ID_FREIGHT_ROUTES.toString(), charge._id.toString(), EValueModificationProperties.CHARGE_RECEIVING_UNITARY, charge.FREIGHT_ROUTES_RECEIVING.UNITARY.NEW_VALUE, charge.FREIGHT_ROUTES_RECEIVING.UNITARY.OLD_VALUE, false, EHistoryOperation.UPDATE, charge, 'teste');
                    break;

                case 'ALL_FIELDS':
                    charge.HAS_CHANGES = true;
                    charge.FREIGHT_ROUTES_PAYMENT.MIN = this.applyAlterValuesByType(charge.FREIGHT_ROUTES_PAYMENT.MIN, field, type, value, history);
                    charge.FREIGHT_ROUTES_RECEIVING.UNITARY = this.applyAlterValuesByType(charge.FREIGHT_ROUTES_RECEIVING.UNITARY, field, type, value, history);
                    charge.FREIGHT_ROUTES_RECEIVING.MIN = this.applyAlterValuesByType(charge.FREIGHT_ROUTES_RECEIVING.MIN, field, type, value, history);
                    charge.FREIGHT_ROUTES_PAYMENT.UNITARY = this.applyAlterValuesByType(charge.FREIGHT_ROUTES_PAYMENT.UNITARY, field, type, value, history);
                    this.addHistoryOldNewValue(history, charge.ID_FREIGHT_ROUTES.toString(), charge._id.toString(), EValueModificationProperties.CHARGE_PAYMENT_MIN, charge.FREIGHT_ROUTES_PAYMENT.MIN.NEW_VALUE, charge.FREIGHT_ROUTES_PAYMENT.MIN.OLD_VALUE, false, EHistoryOperation.UPDATE, charge, `teste`);
                    this.addHistoryOldNewValue(history, charge.ID_FREIGHT_ROUTES.toString(), charge._id.toString(), EValueModificationProperties.CHARGE_PAYMENT_UNITARY, charge.FREIGHT_ROUTES_PAYMENT.UNITARY.NEW_VALUE, charge.FREIGHT_ROUTES_PAYMENT.UNITARY.OLD_VALUE, false, EHistoryOperation.UPDATE, charge, 'teste');
                    this.addHistoryOldNewValue(history, charge.ID_FREIGHT_ROUTES.toString(), charge._id.toString(), EValueModificationProperties.CHARGE_RECEIVING_MIN, charge.FREIGHT_ROUTES_RECEIVING.MIN.NEW_VALUE, charge.FREIGHT_ROUTES_RECEIVING.MIN.OLD_VALUE, false, EHistoryOperation.UPDATE, charge, 'teste');
                    this.addHistoryOldNewValue(history, charge.ID_FREIGHT_ROUTES.toString(), charge._id.toString(), EValueModificationProperties.CHARGE_RECEIVING_UNITARY, charge.FREIGHT_ROUTES_RECEIVING.UNITARY.NEW_VALUE, charge.FREIGHT_ROUTES_RECEIVING.UNITARY.OLD_VALUE, false, EHistoryOperation.UPDATE, charge, 'teste');
                    break;
            }

            return true;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private applyAlterValuesByType(historyValue: IHistoryFreightWizardRoute<number>, field: IField, type: IType, value: number, history: IHistory): IHistoryFreightWizardRoute<number> {
        try {
            if (!historyValue || !field || !type || !history) return;

            switch (type.ID) {
                case 'INCREASE':
                    historyValue.NEW_VALUE = historyValue.OLD_VALUE ? angular.copy(value + historyValue.OLD_VALUE) : historyValue.OLD_VALUE;
                    break;

                case 'REDUCE':
                    historyValue.NEW_VALUE = historyValue.OLD_VALUE ? angular.copy(historyValue.OLD_VALUE - value) : historyValue.OLD_VALUE;
                    break;

                case 'FIXED_VALUE':
                    historyValue.NEW_VALUE = value;
                    break;
            }

            historyValue.DISABLED_FIELD = true;
            return historyValue;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private applyChargeHistoryToModel(history: IHistory, routes: IFreightWizardRoute[]): void {
        try {
            if (!history) return;
            this.block();

            const historyOldNewValue: IHistoryOldNewValue[] = history.OLD_NEW_VALUE;
            const modelRoutes: IFreightWizardRoute[] = routes;

            for (const oldNewValue of historyOldNewValue) {
                const findedFreightRoute: IFreightWizardRoute = modelRoutes.find(route => route.FREIGHT_ROUTE.ID.toString() === oldNewValue.FREIGHT_ROUTE_ID);
                const findedCharge: IChargeHistory = findedFreightRoute && findedFreightRoute.CHARGES_HISTORY.find(charge => charge._id === oldNewValue.CHARGE_ID);

                switch (oldNewValue.OPERATION) {
                    case EHistoryOperation.CREATE:
                        this.create(oldNewValue, findedFreightRoute)
                        break;

                    case EHistoryOperation.UPDATE:
                        this.update(findedCharge, oldNewValue, findedFreightRoute);
                        break;
                }
            }

            this.scope.oldModel.FREIGHT_WIZARD_ROUTES = angular.copy(routes);
            this.clearFields();
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private hasAnySearchControlInProgress(): boolean {
        return this.scope.confirmationTabSearchControl && this.scope.confirmationTabSearchControl.isSearchingTabDataList;
    }

    private isValidDateByRouteVigency(newVigency: Date, route: IFreightWizardRoute): boolean {
        if (DateUtil.dateIsBetween(newVigency, route.VALIDITY_START, route.VALIDITY_END.NEW_VALUE)) return true
        this.notifyWarning(`A data ${DateUtil.formatDate(newVigency, 'DD/MM/YY')} está fora da vigência da rota ${route.FREIGHT_ROUTE.CONCATENATED}.`)
        return false
    }

    private applyToHistory(history: IHistory, save: any, isRouteCheckboxSelected: boolean, field: IField, cutDate: Date, value: number, type: IType): void {
        if (history.OLD_NEW_VALUE.length) {
            const routesName = Array.from(new Set(save.routesNames)).join('<br>');
            const chargesName = Array.from(new Set(save.chargeNames)).join(', ');

            switch (save.NAME) {
                case 'NEW_VIGENCY_AND_CUT_DATE':
                    history.NAME = (isRouteCheckboxSelected) ? this.getTranslate('PRODUCT.CUSTOM_HISTORY_MESSAGE.MASS_CUT_NEW_VIGENCY_USE_ROUTE_CHANGE', { charges_names: chargesName, routes_names: routesName, cut_date: DateUtil.formatDate(cutDate, 'LL') }) : this.getTranslate('PRODUCT.CUSTOM_HISTORY_MESSAGE.MASS_CUT_NEW_VIGENCY_CHANGE', { charges_names: chargesName, routes_names: routesName, cut_date: DateUtil.formatDate(cutDate, 'LL'), new_vigency_date: DateUtil.formatDate(this.scope.model.ACTION.ALTER_VIGENCY.NEW_VIGENCY, 'LL') })
                    break;

                case 'NEW_VIGENCY':
                    history.NAME = (isRouteCheckboxSelected) ? this.getTranslate('PRODUCT.CUSTOM_HISTORY_MESSAGE.MASS_NEW_VIGENCY_USE_ROUTE_CHANGE', { charges_names: chargesName, routes_names: routesName }) : this.getTranslate('PRODUCT.CUSTOM_HISTORY_MESSAGE.MASS_NEW_VIGENCY_CHANGE', { charges_names: chargesName, routes_names: routesName, new_vigency_date: DateUtil.formatDate(this.scope.model.ACTION.ALTER_VIGENCY.NEW_VIGENCY, 'LL') });
                    break;

                case 'CUT_DATE':
                    history.NAME = this.getTranslate('PRODUCT.CUSTOM_HISTORY_MESSAGE.MASS_CUT_DATE_CHANGE', { charges_names: chargesName, routes_names: routesName, cut_date: DateUtil.formatDate(cutDate, 'LL') });
                    break;

                case 'INCREASE':
                    history.NAME = this.getTranslate('PRODUCT.CUSTOM_HISTORY_MESSAGE.MASS_CHANGE_FIELD_VALUE', { charges_names: chargesName, routes_names: routesName, field: field.NAME, value: value, type: type.NAME })
                    break;

                case 'REDUCE':
                    history.NAME = this.getTranslate('PRODUCT.CUSTOM_HISTORY_MESSAGE.MASS_CHANGE_FIELD_VALUE', { charges_names: chargesName, routes_names: routesName, field: field.NAME, value: value, type: type.NAME })
                    break;

                case 'FIXED_VALUE':
                    history.NAME = this.getTranslate('PRODUCT.CUSTOM_HISTORY_MESSAGE.MASS_CHANGE_FIELD_VALUE', { charges_names: chargesName, routes_names: routesName, field: field.NAME, value: value, type: type.NAME })
                    break;
            }

            this.scope.isHistoryViewMode = false;
            this.disableAllHistoriesSelected();
        } else {
            this.notifyWarning(this.getTranslate('GENERAL.NO_CHANGES_HAVE_BEEN_MADE'));
        }

    }

    private duplicateFreightRouteCharge(charge: IChargeHistory, route: IFreightWizardRoute, history: IHistory, fieldId: string, isManual: boolean = false): void {
        this.duplicateCharge(charge, route, history, isManual);
        this.duplicateChargeStrategy(charge, fieldId);

        this.restoreOriginalChargeValues(history);
    }

    private alterVigency(cutDate: Date, field: IField, isRouteCheckboxSelected: boolean): void {
        let save = { NAME: null, routesNames: [], chargeNames: [] };
        let history: IHistory = { ID: uuid.v4(), NAME: null, DATE: new Date(), OLD_NEW_VALUE: [], SELECTED: false, IS_SELECTABLE: true, HISTORY_TYPE: EHistoryType.FREIGHT_ROUTES_CHARGES };

        for (const route of this.scope.oldModel.FREIGHT_WIZARD_ROUTES) {
            save.routesNames.push(route.FREIGHT_ROUTE.CONCATENATED);
            const newVigency: Date = (isRouteCheckboxSelected) ? route.VALIDITY_END.NEW_VALUE : this.scope.model.ACTION && this.scope.model.ACTION.ALTER_VIGENCY && this.scope.model.ACTION.ALTER_VIGENCY.NEW_VIGENCY;
            for (const charge of route.CHARGES_HISTORY) {
                save.chargeNames.push(charge.CHARGE_NAME_EXHIBITION.NAME);
                if (newVigency && cutDate) {
                    if (this.applyNewVigencyAndCutDate(charge, cutDate, newVigency, history, route)) {
                        save.NAME = "NEW_VIGENCY_AND_CUT_DATE";
                    };
                } else if (newVigency) {
                    if (this.applyNewVigency(charge, newVigency, history, route)) {
                        save.NAME = "NEW_VIGENCY";
                    }
                } else if (cutDate) {
                    if (this.applyCutDate(charge, cutDate, history, route)) {
                        save.NAME = "CUT_DATE";
                    }
                }
            }
        }
        this.applyToHistory(history, save, isRouteCheckboxSelected, field, cutDate, null, null);
        this.scope.model.HISTORY.push(history);
        this.applyChargeHistoryToModel(history, this.getFreightWizardRouteSelected());
    }

    private alterValue(field: IField, value: number, type: IType): void {
        const save = { NAME: null, routesNames: [], chargeNames: [] };
        const history = { ID: uuid.v4(), NAME: null, DATE: new Date(), OLD_NEW_VALUE: [], SELECTED: false, IS_SELECTABLE: true, HISTORY_TYPE: EHistoryType.FREIGHT_ROUTES_CHARGES };

        for (const route of this.scope.oldModel.FREIGHT_WIZARD_ROUTES) {
            save.routesNames.push(route.FREIGHT_ROUTE.CONCATENATED);
            for (const charge of route.CHARGES_HISTORY) {
                save.chargeNames.push(charge.CHARGE_NAME_EXHIBITION.NAME);
                if (this.isChargeVigencyChanged(charge) && field && type && value) {
                    if (this.applyAlterValues(charge, field, type, value, history)) {
                        save.NAME = type.ID;
                        if (!this.chargeAlreadyDuplicated(route, charge)) {
                            this.duplicateFreightRouteCharge(charge, route, history, field.ID)
                        }
                    };
                }
            }
        }
        this.applyToHistory(history, save, null, field, null, value, type);
        this.scope.model.HISTORY.push(history);
        this.applyChargeHistoryToModel(history, this.getFreightWizardRouteSelected());
    }

    private chargeAlreadyDuplicated(route: IFreightWizardRoute, charge: IChargeHistory): boolean {
        const _charge = route.CHARGES_HISTORY.filter(e => e.ID_CHARGE_PARENT == charge.ID);
        if (_charge.length) return true
        return false
    }

    private viewOptionErrors(errors: IError[]) {
        if (errors && errors.length) {
            let errorTrs = "";
            for (const error of errors) {
                errorTrs += `
                    <tr>
                        <td>${error.SUBJECT}</td>
                        <td>${error.REASON}</td>
                    </tr>
                `;
            }
            const errorsTable = `
                <table class="table gray-border table-bordered m-b-none table-responsive">
                    <thead>
                        <th width="30%">${this.getTranslate("REGISTRATION.SUBJECT")}</th>
                        <th width="70%">${this.getTranslate("GENERAL.REASON")}</th>
                    </thead>
                    <tbody>
                        ${errorTrs}
                    </tbody>
                </table>
            `;
            const html = `
                <div class="row">
                    <div class="col-lg-12">
                        ${errorsTable}
                    </div>
                </div>
            `;

            this.ModalService.showModalInfo({ size: 'vlg' }, {
                actionButtonText: 'GENERAL.CLOSE',
                actionButtonClass: 'btn-danger',
                headerText: 'GENERAL.ERRORS',
                bodyText: this.$sce.trustAsHtml(html)
            });
        }
    }
}
import angular = require("angular");
import { StringUtil } from '../../common/util/StringUtil';
import { ILightDataTableOptions } from "../../app/components/lightDataTable/LightDataTableController";
import { IMonacoRequest } from "@services/GridFormService";
import { IStopoverModalScope, WizardStopoverController } from "./WizardStopoverController";
import { ISelectorModel } from "@models/mongo/SelectorModel";
import { FormService2 } from "@services/FormService2";
import { HelperService } from "@services/HelperService";

export class WizardMaritimeServicesPanelController {

    private scope: IStopoverModalScope;
    private parent: WizardStopoverController;
    private formService: FormService2;
    private helperService: HelperService;

    constructor(scope: IStopoverModalScope, parent: WizardStopoverController, $injector: ng.Injectable<any>) {
        this.scope = scope;
        this.parent = parent;
        this.init();
        this.helperService = $injector.get('HelperService');
    }

    private init() {
        this.parent.tabsValidators[`${this.parent.TABS.MARITIME_SERVICE}`] = () => this.validateMaritimeServiceTab();
        this.scope.maritimeServiceTableOptions = this.getLightDataTableOptions();
        this.scope.getEmptySelectorMsg = () => { return this.parent.getEmptySelectorMsg() };
    }

    public async getMaritimeServices(): Promise<void> {
        const request: IMonacoRequest = {
            timeout: 10000,
            route: '/maritimeService/list/operational',
            data: { products: this.scope.processProduct && this.scope.processProduct.CODE ? [this.scope.processProduct.CODE] : [] },
        };

        const result = await this.parent.productService.post(request);

        if (result.data.data.data && result.data.data.data.length) {
            const list = angular.copy(result.data.data.data);
            this.scope.maritimeServiceLightDataTableRows = this.getMaritimeServiceTableData(list);
            this.scope.maritimeServiceList = list.map(s => ({ ID: s.ID, NAME: s.LOGISTIC_SOLUTION }));
        }
    }

    public async getMultipleGenericList(genericTypes: string[]): Promise<void> {
        try {
            if (!genericTypes) return null;
            const formatGeneric = genericTypes.map(item => StringUtil.formatString(item));

            let resultData = null;
            const rc = await this.helperService.post('/generic/value', { types: formatGeneric }, 10000);
            if (rc.status === 200 && rc.data && rc.data.data) {
                const result = rc.data.data;
                resultData = result.filter(item => item.GENERICS != null);
            }

            if (resultData && resultData.length) {
                // getting the product from list
                const product = resultData.find(a => a.IDENTIFIER === 'product') || {};
                // Putting only EM or IM in the list as available options
                this.scope.productList = product.GENERICS.filter(generic => generic.ID === 'EM' || generic.ID === 'IM');
            } else {
                this.scope.productList = [];
            }
        } catch (ex) {
            this.parent.handleError(ex);
        }
    }

    private async getProvidersList(data: any): Promise<ISelectorModel[]> {
        const transform = (providers) => providers.map(provider => { return { ID: provider.ID, NAME: provider.NAME, CODE: provider.SCAC_IATA } });
        let result: ISelectorModel[] = [];
        try {
            if (data.term && data.term.length >= 3) {
                delete data.term;
                const request: IMonacoRequest = {
                    route: `/provider/list/custom/`,
                    timeout: 10000,
                    data,
                };
                const routingPoints = await this.parent.productService.post(request);
                result = routingPoints.data && routingPoints.data.data ? transform(routingPoints.data.data) : [];
            }
        } catch (ex) {
            this.parent.handleError(ex);
        } finally {
            this.scope.$applyAsync();
            return result;
        }
    }

    private getLightDataTableOptions(): ILightDataTableOptions {
        return {
            columns: [{ ID: 'NAME', LABEL: 'BASIC_DATA.SERVICE', STYLE: { width: '25%' } },
            { ID: 'TRADE_ORIGIN', LABEL: 'BASIC_DATA.ORIGIN_TRADE_LANE', STYLE: { width: '25%' } },
            { ID: 'TRADE_DESTINATION', LABEL: 'BASIC_DATA.DESTINATION_TRADE_LANE', STYLE: { width: '25%' } },
            { ID: 'PROVIDERS', LABEL: 'BASIC_DATA.SEA_CARRIER', STYLE: { width: '25%' } }],
            filters: [{
                value: null,
                id: 'service',
                label: 'OPERATIONAL.MARITIME_SERVICE',
                placeholder: 'GENERAL.SELECT_A_SERVICE',
                obtainFilterExp: (filterId: string) => this.obtainFilterExp(filterId),
                obtainFilterItems: () => {
                    return this.scope.maritimeServiceList ? this.scope.maritimeServiceList : [];
                },
            }, {
                value: null,
                id: 'product',
                label: 'BASIC_DATA.PRODUCT',
                placeholder: 'GENERAL.SELECT_A_PRODUCT',
                obtainFilterExp: (filterId: string) => this.obtainFilterExp(filterId),
                obtainFilterItems: () => {
                    return this.scope.productList ? this.scope.productList : [];
                },
            }, {
                value: null,
                id: 'provider',
                label: 'BASIC_DATA.SEA_CARRIER',
                placeholder: 'GENERAL.TYPE_3_DIGITS_AT_LEAST',
                onRefresh: (search) => this.updateProvidersList(search),
                obtainFilterExp: (filterId: string) => this.obtainFilterExp(filterId),
                obtainFilterItems: () => {
                    return this.scope.providersList ? this.scope.providersList : [];
                },
            }, {
                value: null,
                id: 'origin',
                label: 'BASIC_DATA.ORIGIN',
                placeholder: 'GENERAL.TYPE_3_DIGITS_AT_LEAST',
                onRefresh: (search) => this.updateRoutingPointList(search),
                obtainFilterExp: (filterId: string) => this.obtainFilterExp(filterId),
                obtainFilterItems: () => {
                    return this.scope.routingPointList ? this.scope.routingPointList : [];
                },
            }, {
                value: null,
                id: 'destination',
                label: 'BASIC_DATA.DESTINATION',
                placeholder: 'GENERAL.TYPE_3_DIGITS_AT_LEAST',
                onRefresh: (search) => this.updateRoutingPointList(search),
                obtainFilterExp: (filterId: string) => this.obtainFilterExp(filterId),
                obtainFilterItems: () => {
                    return this.scope.routingPointList ? this.scope.routingPointList : [];
                },
            },
            ],
            onRowChecked: (_index, row) => this.onMaritimeServiceChecked(row),
        }
    }

    private getMaritimeServiceTableData(rawList) {
        return rawList.map(s => ({
            selected: false,
            _raw: s,
            NAME: s.LOGISTIC_SOLUTION,
            PROVIDERS: s.PROVIDERS && s.PROVIDERS.length ? s.PROVIDERS.map(a => a.CODE).join(", ") : '',
            TRADE_ORIGIN: s.TRADE_ORIGIN && s.TRADE_ORIGIN.length ? s.TRADE_ORIGIN.map(a => a.NAME).join(", ") : '',
            TRADE_DESTINATION: s.TRADE_DESTINATION && s.TRADE_DESTINATION.length ? s.TRADE_DESTINATION.map(a => a.NAME).join(", ") : '',
        }));
    }

    private async updateProvidersList(search: string): Promise<void> {
        this.scope.providersList = await this.getProvidersList({ term: search, search, types: ['1'] });
    }

    private async updateRoutingPointList(search: string): Promise<void> {
        this.scope.routingPointList = await this.parent.getPort(search);
    }

    private async onMaritimeServiceChecked(row): Promise<void> {
        this.scope.voyageBaseData.voyage.SERVICE = null;
        if (row.selected === true) {
            this.scope.voyageBaseData.voyage.SERVICE = row._raw;
            this.scope.voyageBaseData.voyage.VESSEL_INFORMATION = [];
        }
    }

    private async validateMaritimeServiceTab(): Promise<boolean> {
        try {
            if (!this.scope.maritimeServiceLightDataTableRows.find(s => s.selected === true)) {
                throw this.formService.getTranslate("REGISTRATION.SELECT_A_SERVICE");
            }
            return true;
        } catch (ex) {
            this.parent.handleError(ex);
        }
    }

    private obtainFilterExp(filterId): string {
        const filter = this.scope.maritimeServiceTableOptions.filters.find(f => f.id === filterId);
        switch (filterId) {
            case 'destination':
                return `row._raw.DESTINATION && (row._raw.DESTINATION.find(destination => destination.ROUTING_POINT_DESTINATION.CODE === '${filter.value.CODE}') != null || row._raw.DIRECT.find(direct => direct.DIRECTION.ID == '2' && direct.ROUTING_POINT.CODE === '${filter.value.CODE}'))`;
            case 'provider':
                return `row._raw.PROVIDERS && row._raw.PROVIDERS.find(provider => provider.CODE === '${filter.value.CODE}') != null`;
            case 'origin':
                return `row._raw.ORIGIN && (row._raw.ORIGIN.find(origin => origin.ROUTING_POINT_ORIGIN.CODE === '${filter.value.CODE}') != null || row._raw.DIRECT.find(direct => direct.DIRECTION.ID == '1' && direct.ROUTING_POINT.CODE === '${filter.value.CODE}'))`;
            case 'service':
                return `row._raw.LOGISTIC_SOLUTION === '${filter.value.NAME}'`;
            case 'product':
                return `row._raw.PRODUCT.ID === '${filter.value.ID}'`;
            default:
                return 'true';
        }
    }
}
import angular = require('angular');
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { FileUploader } from 'angular-file-upload';
import { IColumnDef, IGridOptions } from "ui-grid";
import { SSEService } from '@appServices/SSEService';
import { IGridFormServiceScope, GridFormService, IGridFormController } from "@services/GridFormService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IModalService, IModalOptions } from '@services/ModalService';
import { IRestService } from "@services/RestService";
import { FormService2 } from '@services/FormService2';
import { ISessionService } from '@services/SessionService';
import { ITariffFreightExchangeData } from '@models/interface/product/TariffFreightModel';
import { IUploader, IFileItem, ISuccessItemResponse, IUploadItem } from '@models/interface/common/IMonacoUpload';
import { IFormData } from '@models/interface/product/Upload';
import { ERouteEventType } from '@enums/MoveTypeModel';
import { EChargeOriginId, EOperation } from '@enums/GenericData';
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { IOfferOpportunityModel, IOfferOpportunityGridModel, IOfferOpportunityFollowUp, IRecipientListCustomFilter } from "../model/CombinedPreOfferModel";
import { IFloatingMenu } from "../../common/interface/IFloatingMenu";
import { BrowserTitle } from '../../common/BrowserTitle';
import { ILinkParameter, IOfferParameter } from '../../common/model/ModelParameter';
import { IAccountExchangeData } from '../model/AccountModel';
import { EValidationResultId, IOfferOption, IOfferOptionAux, IOfferOptionInvalid, IOfferOptionRoutingPoint, IOfferOptionValidationResult, ITariffDetDemDetails } from '../controller/OfferWizardModalRegisterController';
import { IOfferOptionCharge } from '../model/OfferModel';
import { SelectorModel } from '../../common/model/SelectorModel';
import { IOfferExchangeData } from '../../product/model/OfferModel';
import { HelperService } from "@services/HelperService";

export interface IOfferNotificationModal {
    STATUS: boolean,
    OFFER_NOTIFICAION: boolean;
}

interface ICommodity {
    ID: number;
    NAME: string;
    HS_CODE: string;
}

interface IOpportunityFollowUpModalScope {
    followUp: IOfferOpportunityFollowUp;
    operation: string;
    headerInfo: {
        REQUESTER: SelectorModel;
        CREATED_AT: Date;
        ACTUAL_RESPONSIBLE: SelectorModel;
        ACCOUNT: SelectorModel;
        PRODUCT: SelectorModel;
        SITUATION: SelectorModel;
        REASON: SelectorModel[];
    }
}

export interface IOfferOpportunityScope extends IGridFormServiceScope {
    showOfferOpportunityForm: boolean;
    gridOptions: IGridOptions;
    modalOptions: IModalOptions;
    model: IOfferOpportunityModel;
    followUp: IOfferOpportunityFollowUp;
    lastFollowUp: IOfferOpportunityFollowUp;
    previousFollowUp: IOfferOpportunityFollowUp[];
    uploader: IUploader;
    uploaderFollowUp: IUploader;
    menuFloating: IFloatingMenu;
    lastScroll: number;
    sessionService: ISessionService;
    offerOpportunitySituationList: SelectorModel[];
    offerOpportunityResponsibleList: SelectorModel[];
    offerOptionInconsistencyList: SelectorModel[];
    user: any;
    collapseElements: Array<string>;
    hasOfferCombinedWizardPermission: boolean;
    editOpportunity: (opportunity: IOfferOpportunityModel) => Promise<void>;
    viewOpportunity: (opportunity: IOfferOpportunityModel) => Promise<void>;
    replicateOfferFromModel: (offerOpportunityId: number) => Promise<void>;
    hasInvalidRequiredElements: (elementId: string) => boolean;
    hasChanges: (newObj: Object, oldObj: Object, propertiesToIgnore?: string[]) => boolean;
    modalSaveConfirmation: (headerText: string, closeButtonText: string) => Promise<boolean>;
    collapseHeader: (elementId: string, parentElementId?: string) => void;
    collapseOfferOptionDetails: (offerOption: IOfferOptionAux) => void;
    isCollapseInFollowUp: () => boolean;
    isCollapseInAttachment: () => boolean;
    isCollapseInOffer: () => boolean;
    isCollapseInRoute: () => boolean;
    isCollapseInCargo: () => boolean;
    isCollapseInSpec: () => boolean;
    isCollapseInOption: () => boolean;
    viewFollowUpModal: (followUp: IOfferOpportunityFollowUp) => void;
    goToAgent: (id?: number) => void;
    goToAccountRequirement: (accountRequirementId: number) => void;
    goToTariffFreight: (tariffId: number, tariffContractId: number) => void;
    getResponsibleListByName: (search: string) => Promise<void>;
    offerOptionChargeTypeFilter: (charge: IOfferOptionCharge) => boolean;
    buildRoutesHtml: (offerOption: IOfferOption) => string;
    buildDetDemDetailDirection: (product: string, tariffDetDem: ITariffDetDemDetails) => ITariffDetDemDetails;
    buildOfferOptionErrorTooltip: (error: string[]) => string;
    buildOfferOptionInconsistencyHtml: (validationResult: IOfferOptionValidationResult) => string;
    removeUpload: (model: IUploadItem) => Promise<boolean>;
    concatCommodities: (commodities: Array<ICommodity>) => string;
    fetchData: (id: number, action: string) => Promise<void>;
}

export class CombinedPreOfferRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IOfferOpportunityScope;
    private $q: ng.IQService;
    private $sce: angular.ISCEService;
    private $timeout: ng.ITimeoutService;
    private restService: IRestService;
    private modalService: IModalService;
    private fileUploader: FileUploader;
    private modalFollowUpId: number;
    private quotationCombinedUrl: string;
    private SSEService: SSEService;
    private gridName: string;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IOfferOpportunityScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$sce = $injector.get('$sce');
        this.$timeout = $injector.get('$timeout');
        this.restService = $injector.get('RestService');
        this.modalService = $injector.get('ModalService');
        this.fileUploader = $injector.get('FileUploader');
        this.$scope.template = 'gridTemplate';
        this.modalFollowUpId = 0;
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope);
        this.helperService = $injector.get('HelperService');
        this.gridName = 'GRID_COMBINED_PRE_OFFER';
    }

    async $onInit(): Promise<void> {
        try {
            this.$scope.collapseElements = ['collapseRoutesInvalid', 'collapseRoutesOptions', 'collapseOption', 'collapseSpec', 'collapseCargo', 'collapseRoute', 'collapseOffer', 'collapseOfferHistory', 'collapseAttachment'];
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.quotationCombinedUrl = this.getUrlQuotationCombined();
            this.gridService.$baseUrl = this.quotationCombinedUrl;
            this.initForm(this, 'form', 'combinedPreOffer', 'GENERAL.MENU.PRE_OFFER', false);
            this.$scope.hasOfferCombinedWizardPermission = await this.permissionService.isRoleAllowed("OFFERCOMBINEDGENERATEWIZARD");
            await this.initGrid(this.gridName, '/preOffer/list', true, true, 30000, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    $onDestroy(): void {
        if (this.$scope.uploader) this.$scope.uploader.destroy();
        if (this.$scope.uploaderFollowUp) this.$scope.uploaderFollowUp.destroy();
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            PREOFFER_CODE: null,
            OFFER_TAB: null,
            ROUTE_TAB: null,
            CARGO_TAB: null,
            SPEC_TAB: null,
            OPTION_TAB: null,
            FOLLOW_UP_TAB: null,
            ATTACHMENT_TAB: null,
            RECIPIENT: null,
            ACTUAL_RESPONSIBLE: null,
            REQUESTER: null,
            SITUATION: null,
            REASON: null,
            COMPLETED: null,
            CREATED_BY: null,
            UPDATED_BY: null,
            CREATED_AT: null,
            UPDATED_AT: null
        };
        this.$scope.followUp = {
            FILES: null,
            FILES_NEW: null,
            ID_OFFER_OPPORTUNITY: null,
            ACTUAL_RESPONSIBLE: null,
            FROM: null,
            SITUATION: null,
            DATE: null,
            MESSAGE: null,
        };
    }

    initScopeFunctions(): void {

        this.$scope.hasInvalidRequiredElements = (elementId: string) => {
            return this.hasInvalidRequiredElements(elementId);
        }

        this.$scope.hasChanges = (newObj: Object, oldObj: Object, propertiesToIgnore?: string[]) => {
            if (propertiesToIgnore) {
                const newAux = newObj ? angular.copy(newObj) : null;
                const oldAux = oldObj ? angular.copy(oldObj) : null;
                for (const property of propertiesToIgnore) {
                    if (newAux && newAux[property]) {
                        delete newAux[property];
                    }
                    if (oldAux && oldAux[property]) {
                        delete oldAux[property];
                    }
                }
                return !angular.equals(JSON.stringify(newAux), JSON.stringify(oldAux));
            }
            return !angular.equals(newObj, oldObj);
        }

        this.$scope.modalSaveConfirmation = async (headerText: string, closeButtonText: string) => {
            return this.modalSaveConfirmation(headerText, closeButtonText);
        }

        this.$scope.collapseHeader = (elementId: string, parentElementId?: string) => {
            this.collapseHeader(elementId, parentElementId);
        }

        this.$scope.collapseOfferOptionDetails = (offerOption: IOfferOptionAux): void => {
            this.collapseOfferOptionDetails(offerOption);
        }

        this.$scope.isCollapseInFollowUp = () => {
            return angular.element("#collapseFollowUpHeading").attr("aria-expanded") == "true";
        }

        this.$scope.isCollapseInAttachment = () => {
            return angular.element("#collapseAttachmentHeading").attr("aria-expanded") == "true";
        }

        this.$scope.isCollapseInOffer = () => {
            return angular.element("#collapseOfferHeading").attr("aria-expanded") == "true";
        }

        this.$scope.isCollapseInRoute = () => {
            return angular.element("#collapseRouteHeading").attr("aria-expanded") == "true";
        }

        this.$scope.isCollapseInCargo = () => {
            return angular.element("#collapseCargoHeading").attr("aria-expanded") == "true";
        }

        this.$scope.isCollapseInSpec = () => {
            return angular.element("#collapseSpecHeading").attr("aria-expanded") == "true";
        }

        this.$scope.isCollapseInOption = () => {
            return angular.element("#collapseOptionHeading").attr("aria-expanded") == "true";
        }

        this.$scope.viewFollowUpModal = (followUp: IOfferOpportunityFollowUp): void => {
            this.openFollowUpModal(EOperation.VIEW, followUp);
        }

        this.$scope.replicateOfferFromModel = async (offerId: number): Promise<void> => {
            this.replicateOfferFromModel(offerId);
        }

        this.$scope.goToAgent = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.agent", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToAccountRequirement = (accountRequirementId: number) => {
            if (!accountRequirementId && accountRequirementId != 0) throw Error('accountRequirementId is null');
            this.$scope.sessionService.openTab("app.commercial.account", <ILinkParameter>{ ID: "" }, <IAccountExchangeData>{ OPERATION: "edit", ID: this.$scope.model.OFFER_TAB && this.$scope.model.OFFER_TAB.ACCOUNT ? parseInt(this.$scope.model.OFFER_TAB.ACCOUNT.ID) : null, ID_REQUIREMENT: accountRequirementId });
        }

        this.$scope.goToTariffFreight = (tariffId: number, tariffContractId: number) => {
            if (!tariffId && tariffId != 0) throw Error('tariffId is null');
            const endpoint = `${this.config.productUrl}/product/tariffFreight/getCacheById/${tariffContractId}`;
            this.$scope.sessionService.openTabByValidity(endpoint, "app.product.tariffFreight", <ILinkParameter>{ ID: tariffContractId ? tariffContractId.toString() : tariffContractId }, <ITariffFreightExchangeData>{ OPERATION: "edit", ID: tariffContractId ? tariffContractId.toString() : null, ID_TARIFF: tariffId });
        }

        this.$scope.getResponsibleListByName = async (search: string) => {
            let responsibleList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                responsibleList = await this.getRecipientListByName({ search: search, groups: null, roles: null });
            }
            this.$scope.offerOpportunityResponsibleList = responsibleList;
        }

        this.$scope.offerOptionChargeTypeFilter = (charge: IOfferOptionCharge) => {
            return (!charge.CHARGE_NAME) || (charge.CHARGE_NAME && !charge.CHARGE_NAME.TYPE) || (charge.CHARGE_NAME && charge.CHARGE_NAME.TYPE && charge.CHARGE_NAME.TYPE.ID != EChargeOriginId.COMISSION);
        }

        this.$scope.buildRoutesHtml = (offerOption: IOfferOption) => {
            return this.buildRoutesHtml(offerOption);
        }

        this.$scope.buildDetDemDetailDirection = (product: string, tariffDetDem: ITariffDetDemDetails): ITariffDetDemDetails => {
            return this.buildDetDemDetailDirection(product, tariffDetDem);
        }

        this.$scope.buildOfferOptionErrorTooltip = (errors: string[]) => {
            return this.buildOfferOptionErrorTooltip(errors);
        }

        this.$scope.buildOfferOptionInconsistencyHtml = (validationResult: IOfferOptionValidationResult) => {
            return this.buildOfferOptionInconsistencyHtml(validationResult);
        }

        this.$scope.removeUpload = (model: IUploadItem): Promise<boolean> => {
            return this.removeFollowUpUpload(model);
        }

        this.$scope.concatCommodities = (commodities: Array<ICommodity>): string => {
            return this.concatCommodities(commodities);
        }

        this.$scope.editOpportunity = async (opportunity: IOfferOpportunityModel): Promise<void> => {
            let blockedObject = {
                ID: opportunity.ID,
                NAME: opportunity.REASON.toString(),
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || opportunity.ID !== this.$scope.model.ID) this.$scope.fetchData(opportunity.ID, EOperation.VIEW);
                } else if (this.$scope.operation !== EOperation.EDIT || opportunity.ID !== this.$scope.model.ID) {
                    this.$scope.fetchData(opportunity.ID, EOperation.EDIT);
                }
            };
        }

        this.$scope.viewOpportunity = async (opportunity: IOfferOpportunityModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(opportunity.ID, EOperation.VIEW);
        }

        this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
            this.fetchData(id, action);
        }
    }

    async initDependencies(): Promise<any> {
        const self: CombinedPreOfferRegisterController = this;

        this.initCollapseEvents();

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericAlternativeAsCodeList("offer_option_inconsistency"),
                self.getGenericValue("offer_opportunity_situation")
            ]).then(async (result: any) => {
                self.$scope.offerOptionInconsistencyList = result[0];
                self.$scope.offerOpportunitySituationList = result[1];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    initGridColumns(columns: string[]): IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<a ng-click="grid.appScope.viewOpportunity(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="row.entity.SITUATION && row.entity.SITUATION.ID != '5' && grid.appScope.editOpportunity(row.entity)" ng-disabled="row.entity.SITUATION && row.entity.SITUATION.ID == '5'" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const replicateOfferOpportunityModel = `<a ng-show="grid.appScope.hasOfferCombinedWizardPermission" ng-click="grid.appScope.replicateOfferFromModel(row.entity.ID)" class="text-green" tooltip-placement="auto top" uib-tooltip="{{'PRODUCT.OPEN_WIZARD_BASED_PRE_OFFER' | translate }}" tooltip-append-to-body="true"><i class="fa fa-retweet icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: 80,
            cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${replicateOfferOpportunityModel}</div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };

        gridColumns.addColumn(colActions);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colCreated: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 130, cellFilter: "datetimeformated" };
            const colUpdated: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENEREL.UPDATED_AT", width: 130, cellFilter: "datetimeformated" };
            const colRepient: IMonacoColumnDef = { name: "RECIPIENT.NAME", displayName: "GENERAL.TO", width: 150 };
            const colRequester: IMonacoColumnDef = { name: "REQUESTER.NAME", displayName: "GENERAL.REQUESTOR", width: 120 };
            const colResponsible: IMonacoColumnDef = { name: "ACTUAL_RESPONSIBLE.NAME", displayName: "REGISTRATION.CURRENT_RESPONSIBLE", width: 150 };
            const colReason: IMonacoColumnDef = { name: "REASON", displayName: "OPERATIONAL.FAULT_REASON", width: 140, cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.REASON, null, "NAME")}}</div>' };
            const colSituation: IMonacoColumnDef = { name: "SITUATION.NAME", displayName: "PRODUCT.OFFER_STATUS", width: 160 };
            const colAccountReques: IMonacoColumnDef = { name: "OFFER_TAB.ACCOUNT_REQUEST", displayName: "PRODUCT.CLIENT_REQUEST_DATE", width: 180, cellFilter: "date:'dd/MM/yyyy'" };
            const colProduct: IMonacoColumnDef = { name: "OFFER_TAB.PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 100 };
            const colTypeCargo: IMonacoColumnDef = { name: "OFFER_TAB.TYPE_CARGO.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 130 };
            const colOfferType: IMonacoColumnDef = { name: "OFFER_TAB.OFFER_TYPE.NAME", displayName: "BASIC_DATA.OFFER_TYPE", width: 150 };
            const colQuantity: IMonacoColumnDef = { name: "QTD_UNITY_FORMATTED", displayName: "GENERAL.QUANTITY", width: 150 };
            const colGrossWeight: IMonacoColumnDef = { name: "CARGO_TAB.GROSS_WEIGHT", displayName: "GENERAL.GROSS_WEIGHT_KG", width: 150 };
            const colChargeableWeight: IMonacoColumnDef = { name: "CARGO_TAB.AIR.CHARGEABLE_WEIGHT", displayName: "BASIC_DATA.CHARGEABLE_WEIGHT", width: 150 };
            const colOvverChargeableWeight: IMonacoColumnDef = { name: "CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT", displayName: "BASIC_DATA.OVER_CHARGEABLE_WEIGHT", width: 150 };
            const colCubicWeight: IMonacoColumnDef = { name: "CARGO_TAB.CUBIC_WEIGHT", displayName: "GENERAL.CUBIC_METER", width: 150 ,cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.CARGO_TAB.CUBIC_WEIGHT | number: 3}}</div>' };
            const colProvider: IMonacoColumnDef = { name: "OFFER_TAB.PROVIDER.NAME", displayName: "BASIC_DATA.PROVIDER", width: 130 };
            const colAccountReq: IMonacoColumnDef = { name: "OFFER_TAB.ACCOUNT.NAME", displayName: "BASIC_DATA.CLIENT", width: 170 };
            const colIncoterm: IMonacoColumnDef = { name: "OFFER_TAB.INCOTERM.CODE", displayName: "BASIC_DATA.INCOTERM", width: 130 };
            const colProcess: IMonacoColumnDef = { name: "OFFER_TAB.PROCESS_TYPE.NAME", displayName: "REGISTRATION.EVENT_FILE", width: 130 };
            const colCommodity: IMonacoColumnDef = { name: "OFFER_TAB.COMMODITY.NAME", displayName: "GENERAL.COMMODITY", width: 150, cellTemplate: '<div class="grid-padding">{{grid.appScope.concatCommodities(row.entity.OFFER_TAB.COMMODITY)}}</div>' };
            const colMoveType: IMonacoColumnDef = { name: "ROUTE_TAB.ROUTES[0].MOVE_TYPE.CODE", displayName: "GENERAL.MENU.MOVE_TYPE", width: 160 };
            const colOrigin: IMonacoColumnDef = { name: "ROUTE_TAB.ROUTES[0].ORIGIN.DISPLAY_NAME", displayName: "BASIC_DATA.ORIGIN", width: 180 };
            const colDestination: IMonacoColumnDef = { name: "ROUTE_TAB.ROUTES[0].DESTINATION.DISPLAY_NAME", displayName: "BASIC_DATA.DESTINATION", width: 180 };
            const colOfferOpportunityCode: IMonacoColumnDef = { name: "PREOFFER_CODE", displayName: "BASIC_DATA.PRE_OFFER", width: 120 };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'CREATED_AT':
                        columnDefs.push(colCreated);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdated);
                        break;
                    case 'RECIPIENT':
                        columnDefs.push(colRepient);
                        break;
                    case 'REQUESTER':
                        columnDefs.push(colRequester);
                        break;
                    case 'ACTUAL_RESPONSIBLE':
                        columnDefs.push(colResponsible);
                        break;
                    case 'REASON':
                        columnDefs.push(colReason);
                        break;
                    case 'SITUATION':
                        columnDefs.push(colSituation);
                        break;
                    case 'OFFER_TAB.ACCOUNT_REQUEST':
                        columnDefs.push(colAccountReques);
                        break;
                    case 'OFFER_TAB.PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'OFFER_TAB.TYPE_CARGO':
                        columnDefs.push(colTypeCargo);
                        break;
                    case 'OFFER_TAB.OFFER_TYPE':
                        columnDefs.push(colOfferType);
                        break;
                    case 'OFFER_TAB.PROVIDER':
                        columnDefs.push(colProvider);
                        break;
                    case 'OFFER_TAB.ACCOUNT':
                        columnDefs.push(colAccountReq);
                        break;
                    case 'OFFER_TAB.INCOTERM':
                        columnDefs.push(colIncoterm);
                        break;
                    case 'OFFER_TAB.PROCESS_TYPE':
                        columnDefs.push(colProcess);
                        break;
                    case 'OFFER_TAB.COMMODITY':
                        columnDefs.push(colCommodity);
                        break;
                    case 'ROUTE_TAB.ROUTES[0].MOVE_TYPE':
                        columnDefs.push(colMoveType);
                        break;
                    case 'ROUTE_TAB.ROUTES[0].ORIGIN':
                        columnDefs.push(colOrigin);
                        break;
                    case 'ROUTE_TAB.ROUTES[0].DESTINATION':
                        columnDefs.push(colDestination);
                        break;
                    case 'PREOFFER_CODE':
                        columnDefs.push(colOfferOpportunityCode);
                        break;
                    case 'QTD_UNITY_FORMATTED':
                        columnDefs.push(colQuantity);
                        break;
                    case 'CARGO_TAB.GROSS_WEIGHT':
                        columnDefs.push(colGrossWeight);
                        break;
                    case 'CARGO_TAB.AIR.CHARGEABLE_WEIGHT':
                        columnDefs.push(colChargeableWeight);
                        break;
                    case 'CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT':
                        columnDefs.push(colOvverChargeableWeight);
                        break;
                    case 'CARGO_TAB.CUBIC_WEIGHT':
                        columnDefs.push(colCubicWeight);
                        break;    
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                };
            }

            return columnDefs;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$timeout(async () => {
                await this.initCollapseEvents();
                this.$scope.disableElements(true);
                this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
                await this.getOfferOpportunityData();
                this.$scope.menuFloating = this.getMenuFloatingDefault(this.$scope.model.PREOFFER_CODE);
                if (this.$scope.uploaderFollowUp) {
                    this.$scope.uploaderFollowUp.destroy();
                    this.$scope.uploaderFollowUp = null;
                }

                if (angular.element("#collapseFollowUpHeading").attr("aria-expanded") == "true") await this.initFollowUpPanel();
                else this.collapseHeader("collapseFollowUp");

                // Collapse elements to default
                this.collapse(this.$scope.collapseElements);

                // Make the row selected in grid.
                this.gridService.setFixedRow(this.$scope.model._id);
            });
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$timeout(async () => {
                this.$scope.disableElements(false);
                this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');
                await this.getOfferOpportunityData();
                this.$scope.menuFloating = this.getMenuFloatingDefault(this.$scope.model.PREOFFER_CODE);
                if (this.$scope.uploaderFollowUp) {
                    this.$scope.uploaderFollowUp.destroy();
                    this.$scope.uploaderFollowUp = null;
                }

                if (angular.element("#collapseFollowUpHeading").attr("aria-expanded") == "true") await this.initFollowUpPanel();
                else this.collapseHeader("collapseFollowUp");

                // Collapse elements to default
                this.collapse(this.$scope.collapseElements);

                this.loadEditForm();

                // Make the row selected in grid.
                if (this.gridService) this.gridService.setFixedRow(this.$scope.model._id);
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                const generated = await this.generateFollowUp(this.$scope.followUp);
                if (generated) this.formService.cancel();
                this.SSEService.closeEvents();
                return false;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    getFisFilesGenericRoute(): string {
        const baseRoute = '/fis/filesGeneric';
        const uploadRoute = this.config.fisUrl + baseRoute;
        return uploadRoute;
    }

    private concatCommodities(commodities: Array<ICommodity>): string {
        if (!commodities) return '';
        let concatCommodities: string = '';
        for (let commodity of commodities) concatCommodities += `${commodity.HS_CODE} - ${commodity.NAME}, `;
        return concatCommodities.slice(0, -2);
    }

    private collapse(elements: Array<string>) {
        for (let element of elements) {
            const collapseCargo = angular.element(`#${element}`);
            if (collapseCargo) {
                const isCollapsed = collapseCargo.attr("aria-expanded") == "true";
                if (isCollapsed) {
                    collapseCargo['collapse']('toggle');
                }
            }
        }
    }

    private async getGenericAlternativeAsCodeList(identifier: string): Promise<SelectorModel[]> {
        try {
            let resultList = new Array<SelectorModel>();

            const { data: generic } = await this.helperService.get(`/generic/getByIdentifier/${identifier}`, null, 15000);

            if ((generic.status !== 200) || (!generic.data)) return null;

            let selectorList = generic && generic.data ? generic.data : [];

            for (const item of selectorList) resultList.push({ ID: item.CODE, NAME: item.VALUE, CODE: item.ALTERNATIVE });

            if (resultList.length > 0) {
                resultList = resultList.sort((x, y) => x.ID < y.ID ? -1 : 1);
            }

            return resultList;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private initCollapseEvents() {

        const collapseFollowUp = angular.element('#collapseFollowUp');
        if (collapseFollowUp) {
            collapseFollowUp.on('shown.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    await this.initFollowUpPanel();
                }
            });
            collapseFollowUp.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    this.$scope.uploaderFollowUp.destroy();
                    this.$scope.uploaderFollowUp = null;
                }
            });
        }

        const collapsePreviousFollowUps = angular.element('#collapsePreviousFollowUps');
        if (collapsePreviousFollowUps) {
            collapsePreviousFollowUps.on('shown.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    this.$scope.previousFollowUp = await this.getOfferOpportunityPreviousFollowUp(this.$scope.model.ID);
                }
            });
            collapsePreviousFollowUps.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    this.$scope.previousFollowUp = [];
                }
            });
        }

        const collapseAttachment = angular.element('#collapseAttachment');
        if (collapseAttachment) {
            collapseAttachment.on('shown.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    this.$scope.uploader = this.getFileUploaderDefault();
                    await this.getOfferOpportunityAttachment();
                    this.navigateBetweenIds("collapseAttachment");
                }
            });
            collapseAttachment.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    this.$scope.uploader.destroy();
                    this.$scope.uploader = null;
                }
            });
        }

        const collapseOfferHistory = angular.element('#collapseOfferHistory');
        if (collapseOfferHistory) {
            collapseOfferHistory.on('shown.bs.collapse', (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    this.navigateBetweenIds("collapseOfferHistory");
                }
            });
        }

        const collapseOffer = angular.element('#collapseOffer');
        if (collapseOffer) {
            collapseOffer.on('shown.bs.collapse', (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    this.navigateBetweenIds("collapseOffer");
                }
            });
        }

        const collapseRoute = angular.element('#collapseRoute');
        if (collapseRoute) {
            collapseRoute.on('shown.bs.collapse', (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    this.navigateBetweenIds("collapseRoute");
                }
            });
        }

        const collapseCargo = angular.element('#collapseCargo');
        if (collapseCargo) {
            collapseCargo.on('shown.bs.collapse', (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    this.navigateBetweenIds("collapseCargo");
                }
            });
        }

        const collapseSpec = angular.element('#collapseSpec');
        if (collapseSpec) {
            collapseSpec.on('shown.bs.collapse', (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    this.navigateBetweenIds("collapseSpec");
                }
            });
        }

        const collapseOption = angular.element('#collapseOption');
        if (collapseOption) {
            collapseOption.on('shown.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    await this.getOfferOpportunityOptions();
                    this.navigateBetweenIds("collapseOption");
                }
            });
        }

    }

    private async getOfferOpportunityOptions(): Promise<void> {
        this.formService.block();
        try {
            const offerOpportunityOptionsData = await this.restService.getObject(`${this.quotationCombinedUrl}/preOffer/option/${this.$scope.model.ID}`, 30000, null);
            if (offerOpportunityOptionsData && offerOpportunityOptionsData.data && offerOpportunityOptionsData.data.data) {
                const offerOptions = this.buildOfferOptions(offerOpportunityOptionsData.data.data.length ? offerOpportunityOptionsData.data.data.filter(option => option.IS_VALID) : null);
                const offerInvalidOptions = this.buildInvalidOfferOptions(offerOpportunityOptionsData.data.data.length ? offerOpportunityOptionsData.data.data.filter(option => !option.IS_VALID) : null);
                this.$scope.model.OPTION_TAB = {
                    OFFER_OPTIONS: offerOptions,
                    INVALID_OFFER_OPTIONS: offerInvalidOptions,
                    HAS_ANY_OPTION: offerOptions && offerOptions.length > 0
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getOfferOpportunityFollowUp(): Promise<void> {
        this.formService.block();
        try {
            const offerOpportunityFollowUpData = await this.restService.getObjectAsPromise(`${this.quotationCombinedUrl}/preOffer/message/${this.$scope.model.ID}`, 30000, null, false);
            if (offerOpportunityFollowUpData && offerOpportunityFollowUpData.data) {
                this.$scope.model.FOLLOW_UP_TAB = {
                    FOLLOW_UP: offerOpportunityFollowUpData.data
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getOfferOpportunityAttachment(): Promise<void> {
        this.formService.block();
        try {
            const offerOpportunityAttachmentData = await this.restService.getObject(`${this.quotationCombinedUrl}/preOffer/files/${this.$scope.model.ID}`, 30000, false);
            if (offerOpportunityAttachmentData && offerOpportunityAttachmentData.data && offerOpportunityAttachmentData.data.data) {
                this.$scope.model.ATTACHMENT_TAB = {
                    FILES: offerOpportunityAttachmentData.data.data
                }
            } else {
                this.$scope.model.ATTACHMENT_TAB = {
                    FILES: null
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async collapseOfferOptionDetails(offerOption: IOfferOptionAux): Promise<void> {
        try {
            this.formService.block();
            offerOption.SHOW_DETAILS = !offerOption.SHOW_DETAILS;
            if (offerOption.SHOW_DETAILS) {
                if (offerOption.IS_VALID) {
                    const offerOptionValidChargeData = await this.restService.getObjectAsPromise(`${this.quotationCombinedUrl}/preOffer/charge/${offerOption._id}`, 30000, null, false);
                    if (offerOptionValidChargeData)
                        offerOption.CHARGES = offerOptionValidChargeData.data;
                } else {
                    const offerOptionInvalidChargeData = await this.restService.getObjectAsPromise(`${this.quotationCombinedUrl}/preOffer/charge/invalid/${offerOption._id}`, 30000, null, false);
                    if (offerOptionInvalidChargeData)
                        offerOption.CHARGES = offerOptionInvalidChargeData.data;
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private navigateBetweenIds(to: string): boolean {
        if (!to) return false;
        this.$timeout(function () {
            const element = $("#" + to);
            if (element.length == 0) return;
            const position = $("#" + to).offset().top + $('.app-content-body').scrollTop() - 215;
            $('.app-content-body').animate({
                scrollTop: position
            }, 500);
            return true;
        });
        return false;
    }

    private async getOfferOpportunityData(): Promise<void> {
        this.formService.block();
        try {
            const offerOpportunityData = await this.restService.getObjectAsPromise(`${this.quotationCombinedUrl}/preOffer/${this.$scope.model.ID}`, 30000, null, false);
            if (offerOpportunityData && offerOpportunityData.data) {
                this.$scope.model = offerOpportunityData.data;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async initFollowUpPanel() {
        const collapsePreviousFollowUps = angular.element('#collapsePreviousFollowUps');
        if (collapsePreviousFollowUps && collapsePreviousFollowUps.attr("aria-expanded") == "true") {
            collapsePreviousFollowUps["collapse"]('toggle');
            this.$scope.previousFollowUp = null;
        }
        this.$scope.uploaderFollowUp = this.getFileUploaderDefault();
        this.$scope.uploaderFollowUp.onSuccessItem = (item: IFileItem, response: ISuccessItemResponse): void => {
            this.onSuccessItemFollowUp(item, response);
        }
        this.$scope.lastFollowUp = await this.getLastOpportunifyFollowUp(this.$scope.model.ID);
        await this.getOfferOpportunityFollowUp();
        this.navigateBetweenIds("collapseFollowUp");
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    private getUrlQuotationCombined(): string {
        const baseRoute = '/quotationCombined';
        const urlQuotationCombined = this.config.quotationCombinedUrl + baseRoute;
        return urlQuotationCombined;
    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'combined_pre_offer_files', formName: "combinedPreOffer", folder: "combinedPreOffer" };
            return new this.fileUploader({
                url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData]
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getMenuFloatingDefault(preOfferCode?: string): IFloatingMenu {
        return {
            tooltipPlacement: "auto bottom",
            textTooltip: "GENERAL.PRE_OFFER_DATA",
            infos: [
                { text: "{{'BASIC_DATA.PRE_OFFER' | translate }}" + (preOfferCode ? ` - ${preOfferCode}` : ''), class: "text-rouge font-bold" }
            ],
            options: [
                { click: "collapseHeader", args: ['collapseFollowUp'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.MENU.FOLLOW_UP", iconClass: "fa fa-envelope-o", iconBodyClass: "text-yellow" },
                { click: "collapseHeader", args: ['collapseAttachment'], tooltipPlacement: "auto bottom", textTooltip: "REGISTRATION.EMAIL_ATTACHMENT", iconClass: "fa fa-files-o", iconBodyClass: "text-gray" },
                { click: "collapseHeader", args: ['collapseOffer', 'collapseOfferHistory'], tooltipPlacement: "auto bottom", textTooltip: "BASIC_DATA.OFFER", iconClass: "fa fa-handshake-o", iconBodyClass: "text-green" },
                { click: "collapseHeader", args: ['collapseRoute', 'collapseOfferHistory'], tooltipPlacement: "auto bottom", textTooltip: "ROUTE.ROUTES", iconClass: "fa fa-map-o", iconBodyClass: "text-brown" },
                { click: "collapseHeader", args: ['collapseCargo', 'collapseOfferHistory'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.CARGO", iconClass: "fa fa-truck", iconBodyClass: "text-cyano" },
                { click: "collapseHeader", args: ['collapseSpec', 'collapseOfferHistory'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.SPECIFICITIES", iconClass: "fa fa-file-o", iconBodyClass: "text-orange" },
                { click: "collapseHeader", args: ['collapseOption', 'collapseOfferHistory'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.OPTIONS", iconClass: "fa fa-list-alt", iconBodyClass: "text-danger" }
            ]
        };
    }

    private collapseHeader(elementId: string, parentElementId?: string): void {
        if (parentElementId) {
            if (angular.element("#collapseFollowUpHeading").attr("aria-expanded") == "true") $("#collapseFollowUp")["collapse"]('toggle');
            if (angular.element("#collapseAttachmentHeading").attr("aria-expanded") == "true") $("#collapseAttachment")["collapse"]('toggle');
            if (angular.element("#collapseOfferHistoryHeading").attr("aria-expanded") == "false") $("#" + parentElementId)["collapse"]('toggle');
            this.$timeout(() => {
                $("#" + elementId)["collapse"]('toggle');
            }, 100);
        } else $("#" + elementId)["collapse"]('toggle');
    }

    private hasInvalidRequiredElements(elementId: string): boolean {
        if (!elementId) return false;
        const isInvalid = FormService2.hasRequiredElements('#' + elementId);
        if (isInvalid) this.formService.notifyError(this.formService.getTranslate("GENERAL.ALL_FIELDS_MANDATORY"));
        return isInvalid;
    }

    private async modalSaveConfirmation(headerText: string, closeButtonText: string): Promise<boolean> {
        return await this.modalService.showModalConfirmation({}, {
            headerText: headerText,
            bodyText: this.formService.getTranslate('REGISTRATION.MESSAGES.ERROR.UPDATE_NOT_SAVED'),
            actionButtonText: 'REGISTRATION.SAVE_CONTINUE',
            closeButtonText: 'GENERAL.CLOSE'
        });
    }

    async cancel(): Promise<void> {
        try {
            BrowserTitle.$id = null;
            this.$scope.formOperation = "";
            this.$scope.showOfferOpportunityForm = false;
            if (this.gridService && this.gridService.$gridApi && this.gridService.$gridApi.selection) this.gridService.$gridApi.selection.clearSelectedRows();
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openFollowUpModal(operation: string, followUp?: IOfferOpportunityFollowUp, offerOpportunity?: IOfferOpportunityGridModel) {
        this.modalFollowUpId = this.modalService.newModal();
        const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
            {
                modalID: this.modalFollowUpId,
                scope: this.$scope,
                formService: operation,
                template: require('../view/modals/offerOpportunityFollowUpModal.html'),
                size: 'vlg modal-overflow'
            },
            {
                closeButtonText: 'GENERAL.CLOSE',
                headerText: "OPERATIONAL.VIEW_FOLLOWUP"
            }
        );
        modalInstance.opened.then(() => {
            this.buildGenerateOpportunityModalScope(operation, followUp, offerOpportunity);
        });
    }

    private async buildGenerateOpportunityModalScope(operation: string, followUp?: IOfferOpportunityFollowUp, offerOpportunity?: IOfferOpportunityGridModel): Promise<void> {
        try {
            if (!offerOpportunity) offerOpportunity = this.$scope.model;
            const modalScope: IOpportunityFollowUpModalScope = await this.modalService.getModalScope(this.modalFollowUpId);
            modalScope.operation = operation;
            modalScope.headerInfo = {
                REQUESTER: offerOpportunity.REQUESTER,
                CREATED_AT: offerOpportunity.CREATED_AT,
                ACTUAL_RESPONSIBLE: offerOpportunity.ACTUAL_RESPONSIBLE,
                ACCOUNT: offerOpportunity.OFFER_TAB.ACCOUNT,
                PRODUCT: offerOpportunity.OFFER_TAB.PRODUCT,
                SITUATION: offerOpportunity.SITUATION,
                REASON: offerOpportunity.REASON
            }
            modalScope.followUp = followUp ? followUp : {
                ID_OFFER_OPPORTUNITY: null,
                ACTUAL_RESPONSIBLE: null,
                FROM: null,
                SITUATION: null,
                DATE: null,
                MESSAGE: null,
                FILES: null,
                FILES_NEW: null
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
        return;
    }

    private async getOfferOpportunityPreviousFollowUp(offerOpportunityId: number): Promise<IOfferOpportunityFollowUp[]> {
        let previousFollowUp: IOfferOpportunityFollowUp[] = [];
        this.formService.block();
        try {
            const offerOpportunityFollowUpData = await this.restService.getObjectAsPromise(`${this.quotationCombinedUrl}/preOffer/message/${offerOpportunityId}`, 30000, null, false);
            if (offerOpportunityFollowUpData && offerOpportunityFollowUpData.data && offerOpportunityFollowUpData.data.length > 1) {
                previousFollowUp = offerOpportunityFollowUpData.data.slice(1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return previousFollowUp;
        }
    }

    private async getLastOpportunifyFollowUp(offerOpportunityId: number): Promise<IOfferOpportunityFollowUp> {
        let lastFollowUp: IOfferOpportunityFollowUp = null;
        try {
            this.formService.block();
            const opportunityLastFollowUpData = await this.restService.getObjectAsPromise(`${this.quotationCombinedUrl}/preOffer/message/last/${offerOpportunityId}`, 30000, null, false);
            if (opportunityLastFollowUpData) lastFollowUp = opportunityLastFollowUpData.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return lastFollowUp;
        }
    }

    private async getRecipientListByName(filter: IRecipientListCustomFilter): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.block();
            const recipients = await this.restService.newObjectPromise(`${this.getUrlProduct()}/user/list/custom`, filter, 30000, false);
            if (recipients && recipients.length > 0) result = recipients.map(recipient => { return { ID: recipient.ID, NAME: recipient.displayName, CODE: recipient.email } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async generateFollowUp(followUp: IOfferOpportunityFollowUp): Promise<boolean> {
        let success = true;
        try {
            const requestData = { _id: null, ID_OFFER_OPPORTUNITY: this.$scope.model.ID, PREOFFER_CODE: this.$scope.model.PREOFFER_CODE, SITUATION: followUp.SITUATION, ACTUAL_RESPONSIBLE: followUp.ACTUAL_RESPONSIBLE, MESSAGE: followUp.MESSAGE };
            const generateOpportunity = await this.restService.newObjectPromise(`${this.quotationCombinedUrl}/preOffer/message`, { data: requestData, files: followUp.FILES_NEW && followUp.FILES_NEW.length ? { FILES_NEW: followUp.FILES_NEW } : null }, 30000, false);
            const msg = this.formService.getTranslate('ENTITY.FOLLOWUP_GENERATE_SUCESSFULLY');
            if (generateOpportunity) this.formService.notifySuccess(msg);
            else {
                success = false;
                const msgError = this.formService.getTranslate('ENTITY.ERROR_GENERATING_FOLLOWUP');
                this.formService.notifyError(msgError);
            }
        } catch (ex) {
            success = false;
            this.handleError(ex);
        } finally {
            return success;
        }
    }

    private async replicateOfferFromModel(idCombinedPreOffer: number): Promise<void> {
        try {
            if (!idCombinedPreOffer) throw new Error("idCombinedPreOffer is null.");

            const hasOfferCombinedPermission = await this.permissionService.isRoleAllowed("OFFERCOMBINEDGENERATEWIZARD")
            if (!hasOfferCombinedPermission) throw new Error(this.formService.getTranslate("PRODUCT.ERROR_ON_GENERATE_OFFER"));

            this.$scope.sessionService.openTab("app.commercial.offer", <IOfferParameter>{ CONCATENATED: "" }, <IOfferExchangeData>{ OPERATION: "new", ID_NEW_FREIGHT_CONTRACT: null, ID_TARIFF_FREIGHT_CONTRACT: null, ID_TARIFF: null, ID_PAYMENT_NATURE: null, ID_OFFER: null, ID_OFFER_OPPORTUNITY: null, ID_COMBINED_PRE_OFFER: idCombinedPreOffer });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private buildOfferOptions(offerOptionList): IOfferOptionAux[] {
        const offerOptions: IOfferOptionAux[] = [];
        try {
            if (offerOptionList && offerOptionList.length > 0) {
                for (let offerOptionIndex = 0; offerOptionIndex < offerOptionList.length; offerOptionIndex++) {
                    const offerOption = offerOptionList[offerOptionIndex];
                    const newOfferOption: IOfferOptionAux = angular.copy(offerOption);
                    const routes: IOfferOptionRoutingPoint[] = this.getOfferOptionRoutes(offerOption);
                    newOfferOption.SELECTED = true;
                    newOfferOption.SHOW_DETAILS = false;
                    newOfferOption.TOOLTIP = this.buildTooltipRoutes(routes);
                    newOfferOption.MOVE_TYPE = offerOption.MOVE_TYPE;
                    newOfferOption.ORIGIN = offerOption.ROUTING_POINT_ORIGIN;
                    newOfferOption.DESTINATION = offerOption.ROUTING_POINT_DESTINATION;
                    newOfferOption.PROCESS_STATUS = offerOption.PROCESS_STATUS;
                    offerOptions.push(newOfferOption);
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            return offerOptions;
        }
    }

    private buildInvalidOfferOptions(offerOptionList): IOfferOptionInvalid[] {
        const offerOptions: IOfferOptionInvalid[] = [];
        try {
            if (offerOptionList && offerOptionList.length) {
                for (let offerOptionIndex = 0; offerOptionIndex < offerOptionList.length; offerOptionIndex++) {
                    const offerOption = offerOptionList[offerOptionIndex];
                    offerOptions.push({
                        _id: offerOption._id,
                        SHOW: false,
                        MOVE_TYPE: offerOption.MOVE_TYPE,
                        PRODUCT: offerOption.PRODUCT,
                        PROVIDER: offerOption.PROVIDER,
                        ORIGIN: offerOption.ROUTING_POINT_ORIGIN,
                        DESTINATION: offerOption.ROUTING_POINT_DESTINATION,
                        ID_FREIGHT_ROUTES: offerOption.ID_FREIGHT_ROUTES,
                        ID_NEW_FREIGHT_CONTRACT: offerOption.ID_NEW_FREIGHT_CONTRACT,
                        TARIFF_FREIGHT_CONCATENATED: offerOption.TARIFF_FREIGHT_CONCATENATED,
                        VALIDATION_RESULT: offerOption.VALIDATION_RESULT,
                        CHARGES: null
                    });
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            return offerOptions;
        }
    }

    private buildTooltipRoutes(routes: IOfferOptionRoutingPoint[]): string {
        try {
            let tooltip = "";
            if (routes && routes.length > 0) {
                for (let routeIndex = 0; routeIndex < routes.length; routeIndex++) {
                    const route = routes[routeIndex];
                    if (routeIndex == 0) {
                        tooltip += route.NAME;
                    }
                    else if (((routeIndex + 1) == routes.length)) {
                        tooltip += " - " + route.NAME;
                    } else tooltip += " - " + route.NAME;
                }
            }
            return tooltip;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private buildRoutesHtml(offerOption: IOfferOption): string {
        try {
            const routes: IOfferOptionRoutingPoint[] = this.getOfferOptionRoutes(offerOption);
            let routesHtml = "";
            const routesSeparator = "<label class='text-strong text-dark padding-xs-horizontal v-middle'>-</label>";
            for (let index = 0; index < routes.length; index++) {
                const route = routes[index];
                if (index == 0) {
                    routesHtml += `<label class="text-strong text-dark" style="vertical-align: sub;">${route.CODE}</label>`
                    if (route.COUNTRY && route.COUNTRY.COUNTRY_IMG) routesHtml += `<span style="margin-left: 2px;"><i class="flag-icon monaco-flag ${route.COUNTRY.COUNTRY_IMG.CODE}"></i></span>`;
                }
                else if (((index + 1) == routes.length)) {
                    routesHtml += `${routesSeparator} <label class="text-strong text-dark" style="vertical-align: sub;">${route.CODE}</label>`;
                    if (route.COUNTRY && route.COUNTRY.COUNTRY_IMG) routesHtml += `<span style="margin-left: 2px;"><i class="flag-icon monaco-flag ${route.COUNTRY.COUNTRY_IMG.CODE}"></i></span>`;
                }
            }
            return this.$sce.trustAsHtml(routesHtml);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private buildDetDemDetailDirection(product: string, tariffDetDem: ITariffDetDemDetails): ITariffDetDemDetails {
        let result = null;
        if (tariffDetDem) {
            if (product == 'IM') {
                result = tariffDetDem.DET_DEM_DESTINATION.SUMMARY;
            } else if (product == 'EM') {
                result = tariffDetDem.DET_DEM_ORIGIN.SUMMARY;
            }
        }
        return result;
    }

    private buildOfferOptionErrorTooltip(errors: string[]): string {
        let tooltip = "";
        if (errors && errors.length) {
            for (const reason of errors) {
                if (tooltip) tooltip += "<br>";
                tooltip += reason;
            }
        }
        return tooltip;
    }

    private buildOfferOptionInconsistencyHtml(validationResult: IOfferOptionValidationResult): string {
        try {
            const validationMessages: string[] = [];
            if (!this.$scope.offerOptionInconsistencyList || this.$scope.offerOptionInconsistencyList.length == 0) validationMessages.push("Offer option inconsistency not found.");
            else {
                if (!validationResult.FILTERS_BASE) {
                    const filtersBaseGeneric = this.$scope.offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.FILTERS_BASE);
                    if (filtersBaseGeneric) validationMessages.push(`${filtersBaseGeneric.CODE} - ${filtersBaseGeneric.NAME}`);
                }
                if (!validationResult.TYPE_CARGO) {
                    const typeCargoGeneric = this.$scope.offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.TYPE_CARGO);
                    if (typeCargoGeneric) validationMessages.push(`${typeCargoGeneric.CODE} - ${typeCargoGeneric.NAME}`);
                }
                if (!validationResult.TARIFF_FREIGHT_RECEIVING) {
                    const typeCargoGeneric = this.$scope.offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.TARIFF_FREIGHT_RECEIVING);
                    if (typeCargoGeneric) validationMessages.push(`${typeCargoGeneric.CODE} - ${typeCargoGeneric.NAME}`);
                }
                if (!validationResult.AGENTS) {
                    const agentsGeneric = this.$scope.offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.AGENTS);
                    if (agentsGeneric) validationMessages.push(`${agentsGeneric.CODE} - ${agentsGeneric.NAME}`);
                }
                if (!validationResult.ACCOUNT_REQUIREMENT) {
                    const accountRequirementGeneric = this.$scope.offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.ACCOUNT_REQUIREMENT);
                    if (accountRequirementGeneric) validationMessages.push(`${accountRequirementGeneric.CODE} - ${accountRequirementGeneric.NAME}`);
                }
                if (!validationResult.TARIFF_DET_DEM_PAYMENT) {
                    const tariffDetDemPaymentGeneric = this.$scope.offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.TARIFF_DET_DEM_PAYMENT);
                    if (tariffDetDemPaymentGeneric) validationMessages.push(`${tariffDetDemPaymentGeneric.CODE} - ${tariffDetDemPaymentGeneric.NAME}`);
                }
                if (!validationResult.TARIFF_DET_DEM_RECEIVING) {
                    const tariffDetDemReceivingGeneric = this.$scope.offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.TARIFF_DET_DEM_RECEIVING);
                    if (tariffDetDemReceivingGeneric) validationMessages.push(`${tariffDetDemReceivingGeneric.CODE} - ${tariffDetDemReceivingGeneric.NAME}`);
                }
                if (!validationResult.TARIFF_LOCAL) {
                    const tariffLocalGeneric = this.$scope.offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.TARIFF_LOCAL);
                    if (tariffLocalGeneric) validationMessages.push(`${tariffLocalGeneric.CODE} - ${tariffLocalGeneric.NAME}`);
                }
                if (!validationResult.COMMODITY) {
                    const commodityGeneric = this.$scope.offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.COMMODITY);
                    if (commodityGeneric) validationMessages.push(`${commodityGeneric.CODE} - ${commodityGeneric.NAME}`);
                }
                if (!validationResult.TARIFF_DOMESTIC_ORIGIN) {
                    const tariffDomesticOriginGeneric = this.$scope.offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.TARIFF_DOMESTIC_ORIGIN);
                    if (tariffDomesticOriginGeneric) validationMessages.push(`${tariffDomesticOriginGeneric.CODE} - ${tariffDomesticOriginGeneric.NAME}`);
                }
                if (!validationResult.TARIFF_DOMESTIC_DESTINATION) {
                    const tariffDomesticDestinationGeneric = this.$scope.offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.TARIFF_DOMESTIC_DESTINATION);
                    if (tariffDomesticDestinationGeneric) validationMessages.push(`${tariffDomesticDestinationGeneric.CODE} - ${tariffDomesticDestinationGeneric.NAME}`);
                }
            }
            return this.getCONCAT(validationMessages);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async removeFollowUpUpload(model: IUploadItem): Promise<boolean> {
        let result = false;
        try {
            if (!model) throw Error('item parameter is null');

            const thisTranslated = this.formService.getTranslate("GENERAL.GENDER.THIS", null, true);
            const emailAttachmentTranslated = this.formService.getTranslate("REGISTRATION.EMAIL_ATTACHMENT", null, true);
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thisTranslated, prop: emailAttachmentTranslated })
            });
            if (!modal) return;


            if (this.$scope.followUp.FILES_NEW && this.$scope.followUp.FILES_NEW.length > 0) {
                const index = this.$scope.followUp.FILES_NEW.findIndex(file => { return file.FILE_HASH == model.FILE_HASH });
                if (index > -1) {
                    this.$scope.followUp.FILES_NEW.splice(index, 1);
                    result = true;
                }
            }
        } catch (ex) {
            result = false;
            this.handleError(ex);
        } finally {
            return result;
        }
    }

    private async onSuccessItemFollowUp(item: IFileItem, response: ISuccessItemResponse): Promise<void> {
        try {
            if (!item) throw Error('item parameter is null');
            if (!response) throw Error('response parameter is null');

            if (response.data && response.data.length > 0) {
                if (!this.$scope.followUp.FILES_NEW) this.$scope.followUp.FILES_NEW = [];

                for (let item of response.data) {
                    const file: IUploadItem = {
                        FILE_DISPLAY_NAME: item.displayFileName,
                        FILE_NAME: item.fileName,
                        FILE_TYPE: item.fileType,
                        FILE_PATH: item.filePath,
                        FILE_URL: item.fileUrl,
                        FILE_HASH: item.fileHash,
                        FILE_SIZE: item.fileSize,
                        FORM_NAME: item.formName
                    }
                    this.$scope.followUp.FILES_NEW.push(file)
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getOfferOptionRoutes(offerOption: IOfferOption): IOfferOptionRoutingPoint[] {
        try {
            const routes: IOfferOptionRoutingPoint[] = [];

            if (offerOption.PUP) {
                let pupRoute = null;
                if (offerOption.PUP.FREIGHT && offerOption.PUP.FREIGHT.ROUTING_POINT) {
                    pupRoute = offerOption.PUP.FREIGHT.ROUTING_POINT;
                    pupRoute.TYPE = ERouteEventType.TARIFF_FREIGHT;
                }
                else if (offerOption.PUP.INLAND && offerOption.PUP.INLAND.ORIGIN && offerOption.PUP.INLAND.ORIGIN.ROUTING_POINT) {
                    pupRoute = offerOption.PUP.INLAND.ORIGIN.ROUTING_POINT;
                    pupRoute.TYPE = ERouteEventType.TARIFF_DOMESTIC_ORIGIN;
                }
                if (pupRoute) {
                    pupRoute.EVENT = ERouteEventType.PICK_UP;
                    routes.push(pupRoute);
                }
            }
            if (offerOption.OTFS) {
                let otfsRoute = null;
                if (offerOption.OTFS.FREIGHT && offerOption.OTFS.FREIGHT.ROUTING_POINT) {
                    otfsRoute = offerOption.OTFS.FREIGHT.ROUTING_POINT;
                    otfsRoute.TYPE = ERouteEventType.TARIFF_FREIGHT;
                }
                else if (offerOption.OTFS.INLAND && offerOption.OTFS.INLAND.ORIGIN && offerOption.OTFS.INLAND.ORIGIN.ROUTING_POINT) {
                    otfsRoute = offerOption.OTFS.INLAND.ORIGIN.ROUTING_POINT;
                    otfsRoute.TYPE = ERouteEventType.TARIFF_DOMESTIC_ORIGIN;
                }
                if (otfsRoute) {
                    otfsRoute.EVENT = ERouteEventType.ORIGIN_TERMINAL_FREIGHT_STATION;
                    routes.push(otfsRoute);
                }
            }
            if (offerOption.POLAOL) {
                let polaolRoute = null;
                if (offerOption.POLAOL.FREIGHT && offerOption.POLAOL.FREIGHT.ROUTING_POINT) {
                    polaolRoute = offerOption.POLAOL.FREIGHT.ROUTING_POINT;
                    polaolRoute.TYPE = ERouteEventType.TARIFF_FREIGHT;
                }
                else if (offerOption.POLAOL.INLAND && offerOption.POLAOL.INLAND.ORIGIN && offerOption.POLAOL.INLAND.ORIGIN.ROUTING_POINT) {
                    polaolRoute = offerOption.POLAOL.INLAND.ORIGIN.ROUTING_POINT;
                    polaolRoute.TYPE = ERouteEventType.TARIFF_DOMESTIC_ORIGIN;
                }
                if (polaolRoute) {
                    polaolRoute.EVENT = `${ERouteEventType.PORT_OF_LOAD}/${ERouteEventType.AIRPORT_OF_ORIGIN}`;
                    routes.push(polaolRoute);
                }

                let polaolLocal = null;
                if (offerOption.POLAOL.TARIFF_LOCAL && offerOption.POLAOL.ID_TARIFF_LOCAL_ORIGIN_PAYMENT) {
                    polaolLocal = offerOption.POLAOL.FREIGHT.ROUTING_POINT;
                    polaolLocal.TYPE = ERouteEventType.TARIFF_LOCAL_ORIGIN;
                    polaolLocal.TARIFF_LOCAL_ORIGIN = {
                        TARIFF_LOCAL_ORIGIN_RECEIVING: null,
                        TARIFF_LOCAL_ORIGIN_PAYMENT: {
                            ID: offerOption.POLAOL.ID_TARIFF_LOCAL_ORIGIN_PAYMENT,
                            NAME: offerOption.POLAOL.TARIFF_LOCAL_ORIGIN_PAYMENT_CONCATENATED,
                            CODE: ERouteEventType.TARIFF_LOCAL_ORIGIN
                        }
                    }
                }
            }
            if (offerOption.PODAOD) {
                let podaodRoute = null;
                if (offerOption.PODAOD.FREIGHT && offerOption.PODAOD.FREIGHT.ROUTING_POINT) {
                    podaodRoute = offerOption.PODAOD.FREIGHT.ROUTING_POINT;
                    podaodRoute.TYPE = ERouteEventType.TARIFF_FREIGHT;
                }
                else if (offerOption.PODAOD.INLAND && offerOption.PODAOD.INLAND.DESTINATION && offerOption.PODAOD.INLAND.DESTINATION.ROUTING_POINT) {
                    podaodRoute = offerOption.PODAOD.INLAND.DESTINATION.ROUTING_POINT;
                    podaodRoute.TYPE = ERouteEventType.TARIFF_DOMESTIC_DESTINATION;
                }
                if (podaodRoute) {
                    podaodRoute.EVENT = `${ERouteEventType.PORT_OF_DESTINATION}/${ERouteEventType.AIRPORT_OF_DESTINATION}`;
                    routes.push(podaodRoute);
                }
            }
            if (offerOption.DTFS) {
                let dtfsRoute = null;
                if (offerOption.DTFS.FREIGHT && offerOption.DTFS.FREIGHT.ROUTING_POINT) {
                    dtfsRoute = offerOption.DTFS.FREIGHT.ROUTING_POINT;
                    dtfsRoute.TYPE = ERouteEventType.TARIFF_FREIGHT;
                }
                else if (offerOption.DTFS.INLAND && offerOption.DTFS.INLAND.DESTINATION && offerOption.DTFS.INLAND.DESTINATION.ROUTING_POINT) {
                    dtfsRoute = offerOption.DTFS.INLAND.DESTINATION.ROUTING_POINT;
                    dtfsRoute.TYPE = ERouteEventType.TARIFF_DOMESTIC_DESTINATION;
                }
                if (dtfsRoute) {
                    dtfsRoute.EVENT = ERouteEventType.DESTINATION_TERMINAL_FREIGHT_STATION;
                    routes.push(dtfsRoute);
                }
            }
            if (offerOption.PLD) {
                let pldRoute = null;
                if (offerOption.PLD.FREIGHT && offerOption.PLD.FREIGHT.ROUTING_POINT) {
                    pldRoute = offerOption.PLD.FREIGHT.ROUTING_POINT;
                    pldRoute.TYPE = ERouteEventType.TARIFF_FREIGHT;
                }
                else if (offerOption.PLD.INLAND && offerOption.PLD.INLAND.DESTINATION && offerOption.PLD.INLAND.DESTINATION.ROUTING_POINT) {
                    pldRoute = offerOption.PLD.INLAND.DESTINATION.ROUTING_POINT;
                    pldRoute.TYPE = ERouteEventType.TARIFF_DOMESTIC_DESTINATION;
                }
                if (pldRoute) {
                    pldRoute.EVENT = ERouteEventType.PLACE_OF_DELIVERY;
                    routes.push(pldRoute);
                }
            }
            return routes;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getGenericValue(type: string, alternative?: boolean): Promise<any> {
        if (alternative) {
            const { data: generic } = await this.helperService.get(`/generic/value/${type}/${alternative}`, null, 10000);
            return generic && generic.data ? generic.data : [];
        } else {
            const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
            return generic && generic.data ? generic.data : [];
        }
    }

    private async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.restService.getObjectAsPromise(`${this.getUrlQuotationCombined()}/preOffer/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

}

import * as angular from "angular";
import { IColumnDef } from "ui-grid";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog, } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { ISessionService } from "@services/SessionService";
import { IModalService } from '@services/ModalService';
import { IScoreService } from '@services/ScoreService';
import { ICustomLogProperties, IViewLog } from "@models/interface/common/IViewLog";
import { ScoreModel, RULES, FILTER, SCORE, IColumsFields } from "../model/ScoreModel";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { SelectorModel } from "../../common/model/SelectorModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { IDataFilterRequest } from "../../common/interface/IDataFilterRequest";
import { IScoreConfigParameter } from "../../common/model/ModelParameter";
import * as ScoreConfigModel from "../model/ScoreConfigModel";
import { HelperService } from "@services/HelperService";

interface IScoreScope extends IGridFormServiceScope {
    model: ScoreModel;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: ScoreModel;
    scoreTargetList: SelectorModel[];

    scoreConfig: ScoreConfigModel.ScoreConfigModel;
    columnsFields: IColumsFields;
    columnsWidthFilter: number;
    columnsWidthScore: number;
    columnFieldData: (field: SelectorModel) => SelectorModel[];
    columnRequired: (field: SelectorModel, type: string) => boolean;
    changeScoreTarget: (selected: SelectorModel) => Promise<void>;
    addRule: () => void;
    removeRule: (index: number) => Promise<void>;
    goToScoreConfig: (scoreTarget: string) => void;
}

export class ScoreRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IScoreScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private $timeout: ng.ITimeoutService
    private sessionService: ISessionService;
    private ModalService: IModalService;
    private scoreService: IScoreService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IScoreScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$scope.additionalIndexSelectorValidity = ['$parentIndex'];
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');
        this.sessionService = $injector.get('SessionService');
        this.scoreService = $injector.get('ScoreService');
        this.helperService = $injector.get('HelperService');
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();

            this.initForm(this, 'form', 'score', 'GENERAL.MENU.SCORE', true);
            await this.initGrid('gridScore', '/score/list', true, true, null, true, true);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initDependencies(): Promise<any> {
        const self: ScoreRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getScoreTargetlist(),
            ]).then(async (result: any) => {
                self.$scope.scoreTargetList = result[0];
                if (self.$scope.model.SCORE_TARGET) {
                    await self.changeScoreTarget(self.$scope.model.SCORE_TARGET);
                }
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.changeScoreTarget = async (selected: SelectorModel): Promise<void> => {
            return await this.changeScoreTarget(selected);
        }
        this.$scope.columnFieldData = (field: SelectorModel): SelectorModel[] => {
            return this.columnFieldData(field);
        }
        this.$scope.columnRequired = (field: SelectorModel, type: string): boolean => {
            return this.columnRequired(field, type);
        }
        this.$scope.addRule = (): void => {
            this.addRule();
        }
        this.$scope.removeRule = (index: number): Promise<void> => {
            return this.removeRule(index);
        }
        this.$scope.goToScoreConfig = (scoreTarget: string): void => {
            this.sessionService.openTab("app.admin.scoreConfig", <IScoreConfigParameter>{ "SCORE_TARGET.NAME": scoreTarget });
        }
    }

    initGridColumns(columns: string[]): IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        gridColumns.includeActionBar([
            GridColumnBuilderConstants.BTN_VIEW,
            GridColumnBuilderConstants.BTN_EDIT,
            GridColumnBuilderConstants.BTN_COPY,
            GridColumnBuilderConstants.BTN_VIEWLOG], 25);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colEntity: IMonacoColumnDef = { name: "SCORE_TARGET.NAME", displayName: "GENERAL.LOCAL", width: 350 };
            const colValid: IMonacoColumnDef = { name: "VALID", displayName: "GENERAL.VALID", width: 150, cellFilter: "YesOrNo" };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 150, cellFilter: "YesOrNo" };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'SCORE_TARGET':
                        columnDefs.push(colEntity);
                        break;
                    case 'VALID':
                        columnDefs.push(colValid);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            SCORE_TARGET: null,
            RULES: null,
            OBSERVATION: null,
            VALID: true,
            ACTIVE: true
        };
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} (${this.$scope.model.SCORE_TARGET.NAME})`;
            BrowserTitle.$id = this.$scope.model.SCORE_TARGET.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} (${this.$scope.model.SCORE_TARGET.NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.SCORE_TARGET.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        return {
            route: `/score/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    private async getScoreTargetlist(): Promise<SelectorModel[]> {
        const { data: scoreTarget } = await this.helperService.get(`/generic/value/score_target`, null);
        return scoreTarget && scoreTarget.data ? scoreTarget.data : [];
    }

    private async changeScoreTarget(selected: SelectorModel): Promise<void> {
        try {
            if (selected) {
                this.formService.block();

                const filter: IDataFilterRequest = {
                    datafilter: {
                        limits: null,
                        filter: { "SCORE_TARGET.ID": selected.ID },
                    }
                }
                const data = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/scoreConfig/list/custom`, filter, 3000, false);
                if (data && data.length > 0) {
                    this.$scope.scoreConfig = data[0];

                    await this.manipulateFields();
                } else {
                    this.$scope.model.SCORE_TARGET = null;
                    throw Error('No Score Config found, please create a config first');
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async manipulateFields() {
        try {
            if (this.$scope.scoreConfig) {
                const scoreConfig = this.$scope.scoreConfig;

                this.$scope.columnsFields = { FILTER: [], SCORE: [] };
                this.$scope.columnsFields.FILTER = [];

                if (scoreConfig.FILTER) {
                    for (const filter of scoreConfig.FILTER) {
                        this.$scope.columnsFields.FILTER.push({
                            FIELD: filter.FIELD,
                            REQUIRED: filter.REQUIRED,
                            DATA: await this.getFieldData(filter)
                        });
                    }
                }

                if (scoreConfig.SCORE) {
                    for (const score of scoreConfig.SCORE) {
                        this.$scope.columnsFields.SCORE.push({
                            FIELD: score,
                            REQUIRED: true,
                            DATA: null
                        });
                    }
                }
            }

            if (!this.$scope.model.RULES || this.$scope.model.RULES.length === 0) {
                this.addRule();
            } else {
                this.equalizeModelWithConfig();
            }
            this.calculateColumnsSize();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private calculateColumnsSize(): void {
        try {
            if (this.$scope.model.RULES && this.$scope.model.RULES.length > 0) {
                const rule = this.$scope.model.RULES[0];

                this.$scope.columnsWidthFilter = 30 / (rule.FILTER.length);
                this.$scope.columnsWidthScore = (70 - 15) / (rule.SCORE.length);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private validValuesOfFilterFields(filter: FILTER): FILTER {
        try {
            if (filter) {
                const filterCopy = angular.copy(filter);
                const validDatas = this.$scope.columnsFields.FILTER.find(item => item.FIELD.ID === filter.FIELD.ID);
                if (validDatas && filter.VALUE && filter.VALUE.length > 0) {
                    filter.VALUE.map(value => {
                        if (value) {
                            const has = validDatas.DATA.filter(item => item.ID == value.ID && item.NAME == value.NAME)
                            if (has.length === 0) {
                                const index = filterCopy.VALUE.findIndex(item => item.ID == value.ID && item.NAME == value.NAME);
                                filterCopy.VALUE.splice(index, 1);
                            }
                        }
                    });
                }
                filter = filterCopy;
            }
            return filter;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private equalizeModelWithConfig(): void {
        try {
            if (this.$scope.model.RULES && this.$scope.model.RULES.length > 0) {
                let index = 0;
                for (let rule of this.$scope.model.RULES) {
                    const ruleCopy = angular.copy(rule);

                    if (rule.FILTER && rule.FILTER.length) {
                        for (const filter of rule.FILTER) {
                            const index = ruleCopy.FILTER.findIndex(item => item.FIELD.ID === filter.FIELD.ID);
                            if (!filter.FIELD || !filter.FIELD.ID || !this.checkFieldFilterInConfig(filter.FIELD.ID)) {
                                ruleCopy.FILTER.splice(index, 1);
                            }
                            ruleCopy.FILTER[index] = this.validValuesOfFilterFields(filter);
                        }
                    }

                    // Check Score Fields to Remove
                    if (rule.SCORE && rule.SCORE.length) {
                        rule.SCORE.map(score => {
                            if (!score.FIELD || !score.FIELD.ID || !this.checkFieldScoreInConfig(score.FIELD.ID)) {
                                const index = ruleCopy.SCORE.findIndex(item => item.FIELD.ID === score.FIELD.ID);
                                ruleCopy.SCORE.splice(index, 1);
                            }
                        });
                    }
                    this.$scope.model.RULES[index] = ruleCopy;

                    index++;
                }
            }

            if (this.$scope.columnsFields && this.$scope.columnsFields.FILTER && this.$scope.columnsFields.FILTER.length > 0) {
                for (const filter of this.$scope.columnsFields.FILTER) {
                    if (filter.FIELD && filter.FIELD.ID) {
                        this.checkFieldFilterInModel(filter.FIELD);
                    }
                }
            }

            if (this.$scope.columnsFields && this.$scope.columnsFields.SCORE && this.$scope.columnsFields.SCORE.length > 0) {
                for (const filter of this.$scope.columnsFields.SCORE) {
                    if (filter.FIELD && filter.FIELD.ID) {
                        this.checkFieldScoreInModel(filter.FIELD);
                    }
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private checkFieldFilterInConfig(id: string): boolean {
        try {
            if (!id) throw Error('id is null');

            if (this.$scope.columnsFields && this.$scope.columnsFields.FILTER && this.$scope.columnsFields.FILTER.length > 0) {
                const has = this.$scope.columnsFields.FILTER.find(item => item.FIELD.ID == id);
                return has ? true : false;
            }
            return false;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private checkFieldScoreInConfig(id: string): boolean {
        try {
            let result = false;
            if (!id) throw Error('id is null');

            if (this.$scope.columnsFields && this.$scope.columnsFields.SCORE && this.$scope.columnsFields.SCORE.length > 0) {
                const has = this.$scope.columnsFields.SCORE.find(item => item.FIELD.ID == id);
                result = has ? true : false;
            }
            return result;

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private checkFieldFilterInModel(field: ScoreConfigModel.FIELD): void {
        try {
            if (!field) throw Error('field is null');

            if (this.$scope.model && this.$scope.model.RULES && this.$scope.model.RULES.length > 0) {

                this.$scope.model.RULES.map(rule => {
                    const has = rule.FILTER.find(item => item.FIELD.ID == field.ID);
                    if (!has) {
                        rule.FILTER.push({
                            FIELD: field,
                            VALUE: null
                        })
                    }
                });
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private checkFieldScoreInModel(field: ScoreConfigModel.FIELD): void {
        try {
            if (!field) throw Error('field is null');

            if (this.$scope.model && this.$scope.model.RULES && this.$scope.model.RULES.length > 0) {

                this.$scope.model.RULES.map(rule => {
                    const has = rule.SCORE.find(item => item.FIELD.ID == field.ID);
                    if (!has) {
                        rule.SCORE.push({
                            FIELD: field,
                            VALUE: null
                        })
                    }
                });
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private columnFieldData(field: SelectorModel): SelectorModel[] {
        let data: SelectorModel[] = [];
        try {
            if (!field) throw Error('field is null');

            if (this.$scope.columnsFields) {
                const filterField = this.$scope.columnsFields.FILTER.find(fieldItem => fieldItem.FIELD.CODE == field.CODE);
                if (filterField) data = filterField.DATA;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            return data;
        }
    }

    private columnRequired(field: SelectorModel, type: string): boolean {
        let data: boolean = true;
        try {
            if (!field) throw Error('field is null');
            if (!type) throw Error('type is null');

            let filterField = null;
            if (this.$scope.columnsFields) {
                if (type === 'FILTER') {
                    filterField = this.$scope.columnsFields.FILTER.find(fieldItem => fieldItem.FIELD.CODE == field.CODE);
                } else if (type === 'SCORE') {
                    return true;
                }
                if (filterField) {
                    data = filterField.REQUIRED;
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            return data;
        }
    }

    private async getFieldData(filter: ScoreConfigModel.FILTER): Promise<SelectorModel[]> {
        let data: SelectorModel[] = [];
        try {
            if (!filter) throw Error('filter is null');

            data = await this.scoreService.getFieldData(filter);
        } catch (ex) {
            this.handleError(ex);
        } finally {
            return data;
        }
    }

    private filterFields(): FILTER[] {
        let filterFields: FILTER[] = []
        try {
            if (!this.$scope.columnsFields || !this.$scope.columnsFields.FILTER || this.$scope.columnsFields.FILTER.length === 0) throw Error('FILTER of columnsFields is null');

            for (const filterField of this.$scope.columnsFields.FILTER) {
                filterFields.push({
                    FIELD: filterField.FIELD,
                    VALUE: null
                })
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            return filterFields
        }
    }

    private scoreFields(): SCORE[] {
        let scoreFields: SCORE[] = []
        try {
            if (!this.$scope.columnsFields || !this.$scope.columnsFields.SCORE || this.$scope.columnsFields.SCORE.length === 0) throw Error('SCORE of columnsFields is null');

            for (const scoreField of this.$scope.columnsFields.SCORE) {
                scoreFields.push({
                    FIELD: scoreField.FIELD,
                    VALUE: null
                })
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            return scoreFields
        }
    }

    private addRule(): void {
        try {
            if (!this.$scope.columnsFields || !this.$scope.columnsFields.FILTER || this.$scope.columnsFields.FILTER.length === 0 ||
                !this.$scope.columnsFields || !this.$scope.columnsFields.SCORE || this.$scope.columnsFields.SCORE.length === 0) {
                throw Error('Select a valid score target');
            }

            if (!this.$scope.model.RULES) this.$scope.model.RULES = [];

            const rule: RULES = {
                FILTER: this.filterFields(),
                SCORE: this.scoreFields(),
                ACTIVE: true
            }
            this.$scope.model.RULES.push(angular.copy(rule));

            this.$timeout(() => {
                const currentIndex = this.$scope.model.RULES.length - 1;
                let index = 0;
                for (const columnFields of this.$scope.columnsFields.FILTER) {
                    this.$scope.selectorValidity(columnFields.FIELD.NAME + currentIndex + '' + index);
                    index++;
                }
                index = 0;
                for (const columnFields of this.$scope.columnsFields.SCORE) {
                    this.$scope.selectorValidity(columnFields.FIELD.NAME + currentIndex + '' + index);
                    index++;
                }
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async removeRule(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: this.formService.getTranslate('GENERAL.CONFIRM'),
                headerText: this.formService.getTranslate('GENERAL.CONFIRM_ACTION'),
                closeButtonText: this.formService.getTranslate('GENERAL.CLOSE'),
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.$scope.model.RULES && this.$scope.model.RULES.length > 0) {
                this.formService.block();
                this.$scope.model.RULES.splice(index, 1);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: "SCORE_TARGET",
                LABEL: "GENERAL.LOCAL"
            },
            {
                PROPERTY: "RULES",
                LABEL: "PRODUCT.RULES"
            },
            {
                PROPERTY: "OBSERVATION",
                LABEL: "GENERAL,REMARKS"
            },
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE"
            },
            {
                PROPERTY: "FILTER",
                LABEL: "GENERAL.FILTER_FIELDS"
            },
            {
                PROPERTY: "SCORE",
                LABEL: "GENERAL.SCORE_FIELDS"
            },
            {
                PROPERTY: "FIELD",
                LABEL: "GENERAL.FIELD"
            },
            {
                PROPERTY: "VALUE",
                LABEL: "FINANCIAL.VALUE"
            }
        ];
        return props;
    }
}
import * as angular from "angular";
import * as moment from 'moment';
import { IGridRow } from "ui-grid";
import { IColumnDef } from "ui-grid";
import { IModalInstanceService } from "angular-ui-bootstrap";
import { NotificationMessageService } from '@appServices/NotificationMessageService';
import { GridFormService, IGridFormController, IGridFormServiceScope, IMonacoRequest } from "@services/GridFormService";
import { IMonacoColumnDef } from "@services/GridService2";
import { TaskService } from "@services/TaskService";
import { IRestService } from "@services/RestService";
import { IModalService } from "@services/ModalService";
import { ISessionService } from '@services/SessionService';
import { ExternalService } from "@services/ExternalService";
import { FollowUpParams } from "@models/interface/common/FollowUpParams"
import { INotificationMessageServiceResult } from "@models/interface/operational/INotificationMessageServiceResult";
import { NewTaskView } from "@models/interface/task//NewTaskView";
import { ICustomLogProperties, IViewLog } from "@models/interface/common/IViewLog";
import { INotificationMessageServiceRequest } from '@models/interface/operational/INotificationMessageServiceRequest';
import { CellModel } from "@models/interface/common/CellModel";
import { FollowupReferenceType, FollowupReferenceDate } from '@enums/GenericConstants';
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { SelectorModel } from "../../common/model/SelectorModel";
import { IProcessParameter, IInvoiceParameter, ITaskParamParameter, IProcessDocumentParameter, INotificationMonitorParameter } from '../../common/model/ModelParameter';
import { NotificationBox } from '../../common/NotificationBox';
import { OperationalService } from "@services/OperationalService";
import { HelperService } from "@services/HelperService";

interface INewTaskScope extends IGridFormServiceScope {
    model: NewTaskView;
    customLogProperties: ICustomLogProperties[];
    cellList: SelectorModel[];
    roleList: SelectorModel[];
    userList: SelectorModel[];
    situationList: SelectorModel[];
    oldSituation: SelectorModel;
    selectedRows: NewTaskView[];
    clearSelections: () => Promise<void>;
    showActionsSelected: (row: NewTaskView, action: string) => void;
    disableSelected: (row: NewTaskView) => boolean;
    start: (model: NewTaskView) => void;
    pause: (model: NewTaskView) => void;
    finish: (model: NewTaskView) => void;
    cancelTask: (model: NewTaskView) => void;
    restore: (model: NewTaskView) => void;
    htmlChangeResponsible: (model: NewTaskView) => void;
    htmlDetour: (model: NewTaskView, finishTask: boolean) => void;
    openModalComment: ($event: Event, model: NewTaskView) => void;
    refreshCellDependencyList: (selectedCell: CellModel) => Promise<void>;
    getCellList: () => Promise<void>;
    addManualTask: () => void;
    removeTaskItem: (index: number) => void;
    addTaskCopy: (index: number) => void;
    getProcessSelector: (processNumber: string) => Promise<SelectorModel[]>;
    getUserSelector: (userName: string) => Promise<SelectorModel[]>;
    getResponsibleCellSelector: (responsibleCellName: string) => Promise<SelectorModel[]>;
    goToProcess: (process: string) => void;
    goToTaskParam: (taskParam: string) => void;
    goToInvoice: (invoice: string) => void;
    goToDocument: (document: string, type: string) => void;
    openFollowUpModal: (processNumber: string[], followUpParams: FollowUpParams) => void;
    updateTaskResposibleUser: () => void;
    modelList: INewTasks[];
    form: ng.IFormController;
}

interface INewTasks {
    newTask: NewTaskView;
    PROCESS: SelectorModel;
    RESPONSIBLE_USER: SelectorModel;
    RESPONSIBLE_CELL: any;
    CUSTOM_DESCRIPTION: string;
}

interface IResponsibleModal extends ng.IScope {
    selectedTask: NewTaskView;
    cellList: SelectorModel[];
    roleList: SelectorModel[];
    userList: SelectorModel[];
    changeResponsibleTask: () => void;
    refreshCellDependencyList: (selectedCell: CellModel) => Promise<void>;
}

interface IDetourModal extends ng.IScope {
    selectedTask: NewTaskView;
    detourList: SelectorModel[];
    changeDetourTask: () => void;
}

interface ICommentModal extends ng.IScope {
    selectedTask: NewTaskView;
    changeCommentTask: () => void;
}

export class NewTaskRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ["$injector", "$scope", "$element"];
    private $scope: INewTaskScope;
    private RestService: IRestService;
    private TaskService: TaskService;
    private ExternalService: ExternalService;
    private $q: ng.IQService;
    private selectedRows: NewTaskView[];
    private ModalService: IModalService;
    private $timeout: angular.ITimeoutService;
    private SessionService: ISessionService;
    private OperationalService: OperationalService;
    private helperService: HelperService;
    modalID: any;

    constructor($injector: ng.Injectable<any>, $scope: INewTaskScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.RestService = $injector.get('RestService');
        this.TaskService = $injector.get('TaskService');
        this.ExternalService = $injector.get('ExternalService');
        this.ModalService = $injector.get('ModalService');
        this.SessionService = $injector.get('SessionService');
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.OperationalService = $injector.get('OperationalService');
        this.helperService = $injector.get('HelperService');
    }

    initScopeFunctions(): void {
        this.$scope.showActionsSelected = (row: NewTaskView, action: string) => {
            try {
                let show: boolean = true;
                let hide: boolean = false;
                let tasks: NewTaskView[] = [row];

                if ((this.$scope.selectedRows && this.$scope.selectedRows.length > 0)) tasks = this.$scope.selectedRows;

                if (action == 'start') {
                    for (let task of tasks) {
                        if (row.TASK.ID == task.TASK.ID) {
                            if (task.SITUATION.ID == '2' || task.SITUATION.ID == '5' || task.SITUATION.ID == '6') {
                                show = true;
                            } else hide = true;
                        } else hide = true;
                    }
                } else if (action == 'pause') {
                    for (let task of tasks) {
                        if (row.TASK.ID == task.TASK.ID) {
                            if (task.SITUATION.ID == '1') {
                                show = true;
                            } else hide = true;
                        } else hide = true;
                    }
                } else if (action == 'cancel') {
                    for (let task of tasks) {
                        if (row.TASK.ID == task.TASK.ID) {
                            if (task.SITUATION.ID == '1' || task.SITUATION.ID == '2' || task.SITUATION.ID == '5' || task.SITUATION.ID == '6') {
                                show = true;
                            } else hide = true;
                        } else hide = true;
                    }
                } else if (action == 'finish') {
                    for (let task of tasks) {
                        if (row.TASK.ID == task.TASK.ID) {
                            if (task.SITUATION.ID == '1' || task.SITUATION.ID == '2' || task.SITUATION.ID == '5' || task.SITUATION.ID == '6') {
                                show = true;
                            } else hide = true;
                        } else hide = true;
                    }
                } else if (action == 'restore') {
                    for (let task of tasks) {
                        if (row.TASK.ID == task.TASK.ID) {
                            if (task.SITUATION.ID == '1' || task.SITUATION.ID == '2' || task.SITUATION.ID == '3' || task.SITUATION.ID == '4') {
                                show = true;
                            } else hide = true;
                        } else hide = true;
                    }
                } else if (action == 'responsible') {
                    for (let task of tasks) {
                        if (task.SITUATION.ID == '3' || task.SITUATION.ID == '4') {
                            show = false;
                        } else hide = false;
                    }
                } else if (action == 'detour') {
                    for (let task of tasks) {
                        if (row.TASK.ID == task.TASK.ID && row.TASK.ID !== "0") {
                            show = true;
                        } else hide = true;
                    }
                } else if (action == 'edit') {
                    for (let task of tasks) {
                        if (row.TASK.ID == task.TASK.ID && row.TASK.ID == '0' && tasks.length == 1) {
                            show = true;
                        } else hide = true;
                    }
                } else return false;

                if (hide) show = false;

                return show;
            } catch (ex) {
                this.handleError(ex);
            }
        }

        this.$scope.disableSelected = (row: NewTaskView) => {
            try {
                let disable = false;

                if (row.TASK.ID == '0' && (row.SITUATION.ID == '3' || row.SITUATION.ID == '4')) disable = true;

                return disable;
            } catch (ex) {
                this.handleError(ex);
            }
        }

        this.$scope.clearSelections = async () => {
            await this.clearSelections();
        }

        this.$scope.start = (model: NewTaskView) => {
            this.start(model);
        }

        this.$scope.pause = (model: NewTaskView) => {
            this.pause(model);
        }

        this.$scope.cancelTask = (model: NewTaskView) => {
            this.cancelTask(model);
        }

        this.$scope.finish = (model: NewTaskView) => {
            this.finish(model);
        }

        this.$scope.restore = (model: NewTaskView) => {
            this.restore(model);
        }

        this.$scope.htmlChangeResponsible = (model: NewTaskView) => {
            this.htmlChangeResponsible(model);
        }

        this.$scope.htmlDetour = async (model: NewTaskView, finishTask: boolean) => {
            this.htmlDetour(model, finishTask);
        }

        this.$scope.refreshCellDependencyList = (selectedCell: CellModel): Promise<void> => {
            return this.refreshCellDependencyList(selectedCell);
        }

        this.$scope.getCellList = async (): Promise<void> => {
            this.$scope.cellList = await this.getCellList();
        }
        this.$scope.addManualTask = () => {
            try {
                if (this.$scope.operation == "edit") throw ("Não é permitido adicionar na edição. Use a inclusão de atividades.")
                this.block();

                if (!this.$scope.modelList) this.$scope.modelList = [];

                this.$scope.modelList.push(<INewTasks>{ newTask: this.$scope.model, PROCESS: null, CUSTOM_DESCRIPTION: null });

                this.$timeout(() => {
                    for (let i = 0; i < this.$scope.modelList.length; i++) {
                        this.$scope.form[`taskDescription${i}`].$setDirty();
                        this.$scope.form[`taskResp${i}`].$setDirty();
                        this.$scope.form[`startDate${i}`].$setDirty();
                        this.$scope.form[`endDate${i}`].$setDirty();
                    }
                });

                this.unblock();
            } catch (ex) {
                this.handleError(ex);
            }
        }

        this.$scope.removeTaskItem = async (index: number) => {
            try {
                if (index >= 0 && this.$scope.modelList.length > 1) {
                    const thatTranslated = this.formService.getTranslate("GENERAL.GENDER.THAT")
                    const taskTranslated = this.formService.getTranslate("GENERAL.TASK")
                    const modal = await this.ModalService.showModalConfirmation({}, {
                        actionButtonText: "GENERAL.CONFIRM",
                        closeButtonText: "GENERAL.CLOSE",
                        headerText: "GENERAL.CONFIRM_ACTION",
                        bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thatTranslated, prop: taskTranslated })
                    });

                    if (!modal) return;

                    this.block();

                    this.$scope.modelList.splice(index, 1);

                    this.unblock();
                }
            } catch (ex) {
                this.handleError(ex);
            }
        }

        this.$scope.addTaskCopy = (index: number) => {
            try {
                if (index >= 0) {
                    this.block();

                    const taskCopy = this.$scope.modelList[index];
                    this.$scope.modelList.push(angular.copy(taskCopy));

                    this.$timeout(() => {
                        for (let i = 0; i < this.$scope.modelList.length; i++) {
                            this.$scope.form[`taskDescription${i}`].$setDirty();
                        }
                    });

                    this.$scope.$applyAsync();

                    this.unblock();
                }
            } catch (ex) {
                this.handleError(ex);
            }
        }

        this.$scope.getProcessSelector = async (processNumber: string) => {
            let result = [];
            try {
                this.block();
                const timeout = 120000;

                if (processNumber && processNumber.length >= 3) {
                    const request = {
                        data: {
                            forwardingSituationToExclude: ["5"], // finalizado
                            term: processNumber,
                            timeout,
                        },
                        route: `/process/selector`,
                        timeout,
                    }
                    const processes = await this.OperationalService.post(request.route, request.data, request.timeout);
                    result = processes && processes.data && processes.data.data ? processes.data.data : [];
                }
            } catch (ex) {
                this.handleError(ex);
            } finally {
                this.unblock();
                return result;
            }
        }

        this.$scope.getUserSelector = async (userName: string) => {
            try {
                let resultContent = [];

                if (userName && userName.length >= 3) {
                    const responsibleCell = this.$scope.modelList.find(item => item && item.RESPONSIBLE_CELL) || null;

                    if (responsibleCell) {
                        const responsibleGroup = responsibleCell.RESPONSIBLE_CELL.GROUP || null;

                        if (responsibleGroup) {
                            const allUsers = responsibleGroup
                                .map(item => item && item.USER)
                                .filter(users => users)
                                .flat();

                            if (allUsers.length > 0) {
                                const userList = allUsers.map(item => ({
                                    ID: item.USER_REFERENCE['email'],
                                    NAME: item.USER_REFERENCE['displayName'],
                                }));

                                resultContent = userList;
                            }
                        }
                    }
                }
                return resultContent;
            } catch (error) {
                console.error("An error occurred:", error);
                return [];
            }
        };


        this.$scope.getResponsibleCellSelector = async (responsibleCellName: string) => {
            let result = [];
            try {
                this.block();
                const timeout = 120000;

                if (responsibleCellName && responsibleCellName.length >= 2) {
                    const request = {
                        route: '/cell/list/',
                        timeout,
                    }
                    const responsibleCells = await this.OperationalService.get(request.route, request.timeout);
                    result = responsibleCells && responsibleCells.data && responsibleCells.data.data ? responsibleCells.data.data.data : [];
                }
            } catch (ex) {
                this.handleError(ex);
            } finally {
                this.unblock();
                return result;
            }
        }

        this.$scope.updateTaskResposibleUser = () => {
            const responsibleUser = this.$scope.modelList.find(item => item && item.RESPONSIBLE_USER) || null;
            if (responsibleUser) responsibleUser.RESPONSIBLE_USER = null;
        }

        this.$scope.goToProcess = (process: string) => {
            this.SessionService.openTab('app.operational.newProcess.list', <IProcessParameter>{ PROCESS_NUMBER: process });
        }

        this.$scope.goToTaskParam = (param: string) => {
            this.SessionService.openTab('app.registration.param', <ITaskParamParameter>{ TASK_NUMBER: param });
        }

        this.$scope.goToInvoice = (invoice: string) => {
            this.SessionService.openTab('app.finop.invoice.register', <IInvoiceParameter>{ INVOICE_NUMBER: invoice });
        }

        this.$scope.goToDocument = (document: string, type: string) => {
            this.SessionService.openTab('app.operational.process.processDocument', <IProcessDocumentParameter>{ DOCUMENT: document, TYPE: type });
        };

        this.$scope.openFollowUpModal = async (processNumber: string[], followUpParams: FollowUpParams) => {
            this.modalID = this.ModalService.newModal();
            const params: FollowUpParams = followUpParams;

            const modalInstance: IModalInstanceService = await this.ModalService.showModalFollowUpProcess({
                modalID: this.modalID,
                template: require("../../operational/view/followUpProcess.html"),
                scope: this.$scope,
                animation: true,
                keyboard: true,
                modalFade: true,
                size: 'full',
                params: params,
                processes: processNumber
            },
                {
                    actionButtonText: "GENERAL.CANCEL",
                }
            );
        }

        this.$scope.openModalComment = ($event: Event, model: NewTaskView): void => {
            $event.stopPropagation();
            this.openModalComment(model);
        }
    }

    async $onInit(): Promise<void> {
        try {
            this.selectedRows = [];
            this.$scope.selectedRows = [];
            this.$baseUrl = this.TaskService.$route;
            this.initForm(this, "form", "newTask", "GENERAL.TASKS", true);
            this.block();

            //enable multi row selection
            this.$gridService.setSelectable(true);

            // Limit only to 50 selectable rows
            this.$gridService.setSelectableRowsLimit(50, true);

            //init grid
            await this.initGrid('gridNewTaskView', '/newTaskView/list', true, true, 120000, true, true);
            // this.$gridService.setBackgroundUpdate(120000, [this.selectionReapply, this]);

            this.monitoringFilterChanges();

            //register grid multiple rows selection callback
            this.$gridService.$gridApi.selection.on.rowSelectionChanged(this.$scope, this.selectedRowCallback.bind(this));
            this.$gridService.$gridApi.selection.on.rowSelectionChangedBatch(this.$scope, this.selectedRowBatchCallback.bind(this));

            this.$scope.customLogProperties = this.getCustomLogProperties();

            this.unblock();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const columnDefs: Array<IColumnDef> = gridColumns.$columnDefs;

        //situations
        const start = `<span ng-show="grid.appScope.showActionsSelected(row.entity, \'start\');"><a ng-click="grid.appScope.start(row.entity);"   class="text-blue"  tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.START' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-play text-default"></i></a>&nbsp;&nbsp;</span>`;
        const pause = `<span ng-show="grid.appScope.showActionsSelected(row.entity, \'pause\');"><a ng-click="grid.appScope.pause(row.entity);"   class="text-black" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.PAUSE' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pause text-default"></i></a>&nbsp;&nbsp;</span>`;
        const finish = `<span ng-show="grid.appScope.showActionsSelected(row.entity, \'finish\');"><a ng-click="grid.appScope.finish(row.entity);"  class="text-green" tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.CONCLUDE' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-check text-default"></i></a>&nbsp;&nbsp;</span>`;
        const cancel = `<span ng-show="grid.appScope.showActionsSelected(row.entity, \'cancel\');"><a ng-click="grid.appScope.cancelTask(row.entity);"  class="text-red"   tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.CANCEL' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-ban text-default"></i></a>&nbsp;&nbsp;</span>`;
        const restore = `<span ng-show="grid.appScope.showActionsSelected(row.entity, \'restore\');"><a ng-click="grid.appScope.restore(row.entity);" class="text-orange"  tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.RESTORE' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-cog text-default"></i></a>&nbsp;&nbsp;</span>`;

        //others     
        const responsible = `<span ng-show="grid.appScope.showActionsSelected(row.entity, \'responsible\');"><a ng-click="grid.appScope.htmlChangeResponsible(row.entity);" class="text-blue" tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.UPDATE_RESPONSIBLE_USER' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-address-card-o"></i></a>&nbsp;&nbsp;</span>`;
        const detour = `<span ng-show="grid.appScope.showActionsSelected(row.entity, \'detour\');"><a ng-click="grid.appScope.htmlDetour(row.entity, false);" class="text-red" tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.FAULT_REASON' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-random"></i></a>&nbsp;&nbsp;</span>`;
        const edit = `<span ng-show="grid.appScope.showActionsSelected(row.entity, \'edit\');"><a ng-disabled = "grid.appScope.disableSelected(row.entity);" ng-click="grid.appScope.edit(row.entity);" class="text-yellow" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-pencil"></i></a>&nbsp;&nbsp;</span>`;
        const history = `<span ng-show="true"><a ng-click="grid.appScope.viewLog(row.entity)"   class="text-green" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;</span>`;
        const comment = `<span ng-show="true"><a ng-click="grid.appScope.openModalComment($event, row.entity);" class="text-black" tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.COMMENT' | translate }}" tooltip-append-to-body="true"><i ng-if="row.entity.COMMENT !== null" class="text-black fa fa-commenting"></i><i ng-if="row.entity.COMMENT === null" class="text-black fa fa-commenting-o"></i></a>&nbsp;&nbsp;</span>`;
        const actions = `<div class="text-center pull-left" style="padding-left: 10px;">${start}${finish}${pause}${cancel}${restore}${comment}${responsible}${detour}${edit}${history}</div>`;
        columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: '10%',
            cellTemplate: (actions),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            //visible
            const colSituation: IMonacoColumnDef = { name: "SITUATION.NAME", displayName: "GENERAL.SITUATION", width: 150 };
            const colTaskParam: IMonacoColumnDef = { name: "PARAMS.TASK_NUMBER", displayName: "OPERATIONAL.TASK_NUMBER", width: 120, cellTemplate: `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{'GENERAL.CLICK_HERE_VIEW_PARAMETRIZATION' | translate: {prop: row.entity.PARAMS.TASK_NUMBER} }}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToTaskParam(row.entity.PARAMS.TASK_NUMBER)">{{row.entity.PARAMS.TASK_NUMBER}}</a></div>` };
            const colTask: IMonacoColumnDef = { name: "TASK.NAME", displayName: "OPERATIONAL.TASK", width: 250 };
            const colProcessNumber: IMonacoColumnDef = { name: "PROCESS_NUMBER", displayName: "OPERATIONAL.FILE_NUMBER", width: 150, cellTemplate: `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{'OPERATIONAL.PROCESS_VIEW' | translate: {prop: row.entity.PROCESS_NUMBER} }}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToProcess(row.entity.PROCESS_NUMBER)">{{row.entity.PROCESS_NUMBER}}</a></div>` };
            const coliInvoiceNumber: IMonacoColumnDef = { name: "INVOICE_NUMBER", displayName: "GENERAL.INVOICES", width: 150, cellTemplate: `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{'OPERATIONAL.INVOICE_VIEW' | translate: {prop: row.entity.INVOICE_NUMBER} }}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToInvoice(row.entity.INVOICE_NUMBER)">{{row.entity.INVOICE_NUMBER}}</a></div>` };
            const colEstimatedStartDate: IMonacoColumnDef = { name: "ESTIMATED_START_DATE", displayName: "OPERATIONAL.ESTIMATED_START", width: 130, cellFilter: "datetime" };
            const colStartDate: IMonacoColumnDef = { name: "START_DATE", displayName: "OPERATIONAL.DEADLINE_START", width: 120, cellFilter: "datetime" };
            const colEstimatedEndDate: IMonacoColumnDef = { name: "ESTIMATED_END_DATE", displayName: "OPERATIONAL.ESTIMATED_CONCLUDE", width: 160, cellFilter: "datetime" };
            const colEndDate: IMonacoColumnDef = { name: "END_DATE", displayName: "REGISTRATION.DEADLINE_END", width: 120, cellFilter: "datetime" };
            const colExecutor: IMonacoColumnDef = { name: "EXECUTOR.NAME", displayName: "OPERATIONAL.EXECUTOR", width: 150 };
            const colResponsibleUser: IMonacoColumnDef = { name: "RESPONSIBLE_USER_NAME", displayName: "OPERATIONAL.INTERNAL_RESPONSIBLE", width: 150 };
            const colDetour: IMonacoColumnDef = { name: "DETOUR.NAME", displayName: "OPERATIONAL.FAULT_REASON", width: 150 };
            const colCustomer: IMonacoColumnDef = { name: "PROCESS.CUSTOMER.NAME", displayName: "BASIC_DATA.CLIENT", width: 200 };
            const colTotalTeus: IMonacoColumnDef = { name: "TEUS", displayName: "BASIC_DATA.TOTAL_TEUS", width: 150 };
            const colEquipment: IMonacoColumnDef = { name: "PROCESS.CARGO_DETAIL.EQUIPMENT_CONCATENATED", displayName: "BASIC_DATA.QTY_EQP", width: 150 };
            const colDangerousCargo: IMonacoColumnDef = { name: "PROCESS.CARGO_DETAIL.IS_DANGER_CARGO", displayName: "BASIC_DATA.DANGEROUS_CARGO", width: 100, cellFilter: "YesOrNo" }
            const colComment: IMonacoColumnDef = { name: "COMMENT", displayName: "OPERATIONAL.COMMENT", width: 150 };

            //not visible
            const colResponsibleCell: IMonacoColumnDef = { name: "RESPONSIBLE_CELL.NAME", displayName: "REGISTRATION.RESPONSIBLE_CELL", width: 150, visible: false };
            const colResponsibleRole: IMonacoColumnDef = { name: "RESPONSIBLE_ROLE.NAME", displayName: "REGISTRATION.RESPONSIBLE_ROLE", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.RESPONSIBLE_ROLE)}}</div>', visible: false };
            const colTaskProduct: IMonacoColumnDef = { name: "PARAMS.PRODUCT.NAME", displayName: "BASIC_DATA.PRODUCT", width: 150, visible: false };
            const colTaskModule: IMonacoColumnDef = { name: "PARAMS.MODULE.NAME", displayName: "REGISTRATION.MODULE", width: 120, visible: false };
            const colTaskTaskStep: IMonacoColumnDef = { name: "PARAMS.TASK_STEP.NAME", displayName: "REGISTRATION.STEP", width: 120, visible: false };
            const colMandatoryDetour: IMonacoColumnDef = { name: "PARAMS.MANDATORY_DETOUR.NAME", displayName: "REGISTRATION.REQUIRED_FAULT_REASON", width: 120, visible: false };
            const colScore: IMonacoColumnDef = { name: "SCORE", displayName: "BASIC_DATA.SCORE", width: 120, visible: false };
            const colCustQual: IMonacoColumnDef = { name: "PROCESS.CUSTOMER_QUALIFICATION.NAME", displayName: "GENERAL.CLIENT_QUALIFICATION", width: 150, visible: false };
            const colKAM: IMonacoColumnDef = { name: "PROCESS.KAM", displayName: "BASIC_DATA.KAM", width: 100, cellFilter: 'YesOrNo', visible: false };
            const colPicking: IMonacoColumnDef = { name: "PROCESS.PICKING.NAME", displayName: "BASIC_DATA.PICK_UP", width: 150, visible: false, cellTemplate: '<div ng-if=row.entity.PROCESS.PICKING class="ui-grid-cell-contents ng-binding ng-scope">{{row.entity.PROCESS.PICKING.NAME}}, {{ row.entity.PROCESS.PICKING.PROVINCE.CODE }} ({{row.entity.PROCESS.PICKING.CODE}})</a></div>' };
            const colReceipt: IMonacoColumnDef = { name: "PROCESS.RECEIPT.NAME", displayName: "BASIC_DATA.PLACE_OF_RECEIPT", width: 150, visible: false, cellTemplate: '<div ng-if=row.entity.PROCESS.RECEIPT class="ui-grid-cell-contents ng-binding ng-scope">{{row.entity.PROCESS.RECEIPT.NAME}}, {{ row.entity.PROCESS.RECEIPT.PROVINCE.CODE }} ({{row.entity.PROCESS.RECEIPT.CODE}})</a></div>' };
            const colOrigin: IMonacoColumnDef = { name: "PROCESS.ORIGIN.NAME", displayName: "BASIC_DATA.ORIGIN", width: 150, visible: false, cellTemplate: '<div ng-if=row.entity.PROCESS.ORIGIN class="ui-grid-cell-contents ng-binding ng-scope">{{row.entity.PROCESS.ORIGIN.NAME}}, {{ row.entity.PROCESS.ORIGIN.PROVINCE.CODE }} ({{row.entity.PROCESS.ORIGIN.CODE}})</a></div>' };
            const colDestin: IMonacoColumnDef = { name: "PROCESS.DESTINATION.NAME", displayName: "BASIC_DATA.DESTINATION", width: 150, visible: false, cellTemplate: '<div ng-if=row.entity.PROCESS.DESTINATION class="ui-grid-cell-contents ng-binding ng-scope">{{row.entity.PROCESS.DESTINATION.NAME}}, {{ row.entity.PROCESS.DESTINATION.PROVINCE.CODE }} ({{row.entity.PROCESS.DESTINATION.CODE}})</a></div>' };
            const colFinalDestination: IMonacoColumnDef = { name: "PROCESS.FINAL_DESTINATION.NAME", displayName: "BASIC_DATA.FINAL_DESTINATION", width: 150, visible: false, cellTemplate: '<div ng-if=row.entity.PROCESS.FINAL_DESTINATION class="ui-grid-cell-contents ng-binding ng-scope">{{row.entity.PROCESS.FINAL_DESTINATION.NAME}}, {{ row.entity.PROCESS.FINAL_DESTINATION.PROVINCE.CODE }} ({{row.entity.PROCESS.FINAL_DESTINATION.CODE}})</a></div>' };
            const colDelivery: IMonacoColumnDef = { name: "PROCESS.DELIVERY.NAME", displayName: "ROUTE.DELIVERY", width: 150, visible: false, cellTemplate: '<div ng-if=row.entity.PROCESS.DELIVERY class="ui-grid-cell-contents ng-binding ng-scope">{{row.entity.PROCESS.DELIVERY.NAME}}, {{ row.entity.PROCESS.DELIVERY.PROVINCE.CODE }} ({{row.entity.PROCESS.DELIVERY.CODE}})</a></div>' };
            const colImporter: IMonacoColumnDef = { name: "PROCESS.IMPORTER.NAME", displayName: "BASIC_DATA.CONSIGNEE", width: 220, visible: false };
            const colExporter: IMonacoColumnDef = { name: "PROCESS.EXPORTER.NAME", displayName: "BASIC_DATA.SHIPPER", width: 220, visible: false };
            const colBooking: IMonacoColumnDef = { name: "PROCESS.BOOKING", displayName: "OPERATIONAL.BOOKING", width: 120, visible: false, cellTemplate: `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{'OPERATIONAL.BOOKING_VIEW' | translate: {prop: row.entity.PROCESS.BOOKING} }}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToProcess(row.entity.PROCESS_NUMBER)">{{row.entity.PROCESS.BOOKING}}</a></div>` };
            const colTransport: IMonacoColumnDef = { name: "PROCESS.TRANSPORT_MODE_LOAD.NAME", displayName: "OPERATIONAL.TRANSPORT_MODE", width: 120, visible: false };
            const colEstLoad: IMonacoColumnDef = { name: "PROCESS.ESTIMATED_LOAD", displayName: "OPERATIONAL.ESTIMATED_LOADING_DATE", width: 120, cellFilter: 'simpleDate', visible: false };
            const colEffeLoad: IMonacoColumnDef = { name: "PROCESS.EFFECTIVE_LOAD", displayName: "OPERATIONAL.ACTUAL_LOADING_DATE", width: 120, cellFilter: 'simpleDate', visible: false };
            const colEstDisc: IMonacoColumnDef = { name: "PROCESS.ESTIMATED_DISCHARGE", displayName: "OPERATIONAL.ESTIMATED_DISCHARGE_DATE", width: 120, cellFilter: 'simpleDate', visible: false };
            const colEffeDisc: IMonacoColumnDef = { name: "PROCESS.EFFECTIVE_DISCHARGE", displayName: "OPERATIONAL.ACTUAL_DISCHARGE_DATE", width: 120, cellFilter: 'simpleDate', visible: false };
            const colMasterDirect: IMonacoColumnDef = { name: "PROCESS.MASTER_DIRECT", displayName: "BASIC_DATA.STRAIGHT_BL", width: 120, cellFilter: 'YesOrNo', visible: false };
            const colServProvider: IMonacoColumnDef = { name: "PROCESS.SERVICE_PROVIDER.NAME", displayName: "BASIC_DATA.PROVIDER", width: 120, visible: false };
            const colForwaBy: IMonacoColumnDef = { name: "PROCESS.FORWARDED_BY.NAME", displayName: "GENERAL.FORWARDED_BY", width: 120, visible: false };
            const colBrokerName: IMonacoColumnDef = { name: "PROCESS.BROKER.NAME", displayName: "BASIC_DATA.CUSTOMS_BROKER", width: 200, visible: false };
            const colCargoType: IMonacoColumnDef = { name: "PROCESS.CARGO_TYPE.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 120, visible: false };
            const colIncoterm: IMonacoColumnDef = { name: "PROCESS.INCOTERM.NAME", displayName: "BASIC_DATA.INCOTERM", width: 120, visible: false };
            const colProcessType: IMonacoColumnDef = { name: "PROCESS.PROCESS_TYPE.NAME", displayName: "BASIC_DATA.FILE_TYPE", width: 120, visible: false };
            const colDocHBL: IMonacoColumnDef = { name: "DOCUMENT_HBL", displayName: "OPERATIONAL.HBL", width: 120, visible: false, cellTemplate: `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{'OPERATIONAL.HOUSE_VIEW' |  translate: {prop: row.entity.DOCUMENT_HBL} }}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToDocument(row.entity.DOCUMENT_HBL, \'HOUSE\')">{{row.entity.DOCUMENT_HBL}}</a></div>` };
            const colDocMBL: IMonacoColumnDef = { name: "DOCUMENT_MBL", displayName: "OPERATIONAL.MBL", width: 120, visible: false, cellTemplate: `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{'OPERATIONAL.MASTER_VIEW' | translate: {prop: row.entity.DOCUMENT_MBL} }}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToDocument(row.entity.DOCUMENT_MBL, \'MASTER\')">{{row.entity.DOCUMENT_MBL}}</a></div>` };
            const colConclusionSource: IMonacoColumnDef = { name: "CONCLUSION_SOURCE.NAME", displayName: "OPERATIONAL.ORIGIN_END", width: 150, visible: false };
            const colServiceLevel: IMonacoColumnDef = { name: "SERVICE_LEVEL", displayName: "BASIC_DATA.SERVICE_LEVEL", width: 150, visible: true };
            const colServiceType: IMonacoColumnDef = { name: "SERVICE_TYPE.NAME", displayName: "BASIC_DATA.SERVICE_TYPE", width: 150, visible: true };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'PROCESS_NUMBER':
                        columnDefs.push(colProcessNumber);
                        break;
                    case 'INVOICE_NUMBER':
                        columnDefs.push(coliInvoiceNumber);
                        break;
                    case 'TASK':
                        columnDefs.push(colTask);
                        break;
                    case 'SITUATION':
                        columnDefs.push(colSituation);
                        break;
                    case 'RESPONSIBLE_USER_NAME':
                        columnDefs.push(colResponsibleUser);
                        break;
                    case 'EXECUTOR':
                        columnDefs.push(colExecutor);
                        break;
                    case 'RESPONSIBLE_CELL':
                        columnDefs.push(colResponsibleCell);
                        break;
                    case 'RESPONSIBLE_ROLE':
                        columnDefs.push(colResponsibleRole);
                        break;
                    case 'DETOUR':
                        columnDefs.push(colDetour);
                        break;
                    case 'PARAMS':
                        columnDefs.push(colTaskParam);
                        columnDefs.push(colTaskProduct);
                        columnDefs.push(colTaskModule);
                        columnDefs.push(colTaskTaskStep);
                        columnDefs.push(colMandatoryDetour);
                        break;
                    case 'ESTIMATED_START_DATE':
                        columnDefs.push(colEstimatedStartDate);
                        break;
                    case 'START_DATE':
                        columnDefs.push(colStartDate);
                        break;
                    case 'ESTIMATED_END_DATE':
                        columnDefs.push(colEstimatedEndDate);
                        break;
                    case 'END_DATE':
                        columnDefs.push(colEndDate);
                        break;
                    case 'SCORE':
                        columnDefs.push(colScore);
                        break;
                    case 'PROCESS':
                        columnDefs.push(colCustomer);
                        columnDefs.push(colCustQual);
                        columnDefs.push(colKAM);
                        columnDefs.push(colBooking);
                        columnDefs.push(colDangerousCargo);
                        columnDefs.push(colTransport);
                        columnDefs.push(colPicking);
                        columnDefs.push(colReceipt);
                        columnDefs.push(colOrigin);
                        columnDefs.push(colDestin);
                        columnDefs.push(colFinalDestination);
                        columnDefs.push(colDelivery);
                        columnDefs.push(colImporter);
                        columnDefs.push(colExporter);
                        columnDefs.push(colEstLoad);
                        columnDefs.push(colEffeLoad);
                        columnDefs.push(colEstDisc);
                        columnDefs.push(colEffeDisc);
                        columnDefs.push(colMasterDirect);
                        columnDefs.push(colServProvider);
                        columnDefs.push(colForwaBy);
                        columnDefs.push(colBrokerName);
                        columnDefs.push(colCargoType);
                        columnDefs.push(colIncoterm);
                        columnDefs.push(colProcessType);
                        columnDefs.push(colEquipment);
                        columnDefs.push(colServiceLevel);
                        columnDefs.push(colServiceType);
                        break;
                    case 'DOCUMENT_HBL':
                        columnDefs.push(colDocHBL);
                        break;
                    case 'DOCUMENT_MBL':
                        columnDefs.push(colDocMBL);
                        break;
                    case 'CONCLUSION_SOURCE':
                        columnDefs.push(colConclusionSource);
                        break;
                    case 'TEUS':
                        columnDefs.push(colTotalTeus);
                        break;
                    case 'COMMENT':
                        columnDefs.push(colComment);
                        break;
                };
            }

            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    public async selectionReapply(): Promise<void> {
        const rows = this.$gridService.$gridApi.core.getVisibleRows(this.$gridService.$gridApi.grid);
        const selectedRows = this.$gridService.$gridSelectedRows;

        if (selectedRows && selectedRows.length > 0) {
            const updatedRows: IGridRow[] = [];
            for (const row of selectedRows) {
                const foundRow = rows.find(x => x.entity._id == row._id);
                if (foundRow) {
                    foundRow.setSelected(true);
                    updatedRows.push(foundRow);
                }
            }
            this.selectedRowBatchCallback(updatedRows);
        }
    }

    private async start(model: NewTaskView): Promise<void> {
        try {
            let tasksToUpdate: NewTaskView[] = [model];
            if (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) tasksToUpdate = this.$scope.selectedRows;
            const msg = this.formService.getTranslate('OPERATIONAL.SELECT_TASKS_APPLY_STATUS');
            if (!tasksToUpdate || tasksToUpdate.length == 0 || !tasksToUpdate[0]) return this.handleError(msg);

            const confirmed = await this.ModalService.showModalConfirmation({}, {
                bodyText: (tasksToUpdate.length > 1) ? `${this.formService.getTranslate('OPERATIONAL.WANT_START_SELECTED_TASKS')}` : `${this.formService.getTranslate('OPERATIONAL.INTEGRATION_START')} ${tasksToUpdate[0].TASK.NAME}?`,
                actionButtonText: "OPERATIONAL.START",
                closeButtonText: "GENERAL.CLOSE",
                headerText: (tasksToUpdate.length > 1) ? `${this.formService.getTranslate('GENERAL.MULTIPLE')} - ${this.formService.getTranslate("OPERATIONAL.START")}` : `${this.formService.getTranslate("GENERAL.TASK")} ${tasksToUpdate[0].TASK.NAME} - ${this.formService.getTranslate("OPERATIONAL.START")}`,
            });

            if (!confirmed) return;

            this.block();

            const timeout = 120000;

            for (let task of tasksToUpdate) {
                const taskReq: IMonacoRequest = { route: `/newTask/start/${task._id}/${timeout}` }
                const result = await this.TaskService.get<boolean>(taskReq);
                if (!result || !result.data) return this.handleError(result);
            }

            await this.clearSelections();
            await this.updateGrid();

            const msgSuccess = this.formService.getTranslate('OPERATIONAL.TASKS_SUCCESSFULLY_UPDATED');
            this.$formService.notifySuccess(msgSuccess);

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async pause(model: NewTaskView): Promise<void> {
        try {
            let tasksToUpdate: NewTaskView[] = [model];
            if (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) tasksToUpdate = this.$scope.selectedRows;
            const msg = this.formService.getTranslate('OPERATIONAL.SELECT_TASKS_APPLY_STATUS');
            if (!tasksToUpdate || tasksToUpdate.length == 0 || !tasksToUpdate[0]) return this.handleError(msg);

            const confirmed = await this.ModalService.showModalConfirmation({}, {
                bodyText: (tasksToUpdate.length > 1) ? `${this.formService.getTranslate('OPERATIONAL.WANT_PAUSE_SELECTED_TASKS')}` : `${this.formService.getTranslate('OPERATIONAL.INTEGRATION_PAUSE')} ${tasksToUpdate[0].TASK.NAME}?`,
                actionButtonText: 'GENERAL.PAUSE',
                closeButtonText: 'GENERAL.CLOSE',
                headerText: (tasksToUpdate.length > 1) ? `Múltiplas - Pausar` : `${this.formService.getTranslate("GENERAL.TASK")} ${tasksToUpdate[0].TASK.NAME} - ${this.formService.getTranslate('GENERAL.PAUSE')}`,
            });

            if (!confirmed) return;

            this.block();

            const timeout = 120000;

            for (let task of tasksToUpdate) {
                const taskReq: IMonacoRequest = { route: `/newTask/pause/${task._id}/${timeout}` }
                const result = await this.TaskService.get<boolean>(taskReq);
                if (!result || !result.data) return this.handleError(result);
            }

            await this.clearSelections();
            await this.updateGrid();
            const msgSuccess = this.formService.getTranslate('OPERATIONAL.TASKS_SUCCESSFULLY_UPDATED');
            this.$formService.notifySuccess(msgSuccess);

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async cancelTask(model: NewTaskView): Promise<void> {
        try {
            let tasksToUpdate: NewTaskView[] = [model];
            if (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) tasksToUpdate = this.$scope.selectedRows;
            const msg = this.formService.getTranslate('OPERATIONAL.SELECT_TASKS_APPLY_STATUS');
            if (!tasksToUpdate || tasksToUpdate.length == 0 || !tasksToUpdate[0]) return this.handleError(msg);

            const confirmed = await this.ModalService.showModalConfirmation({}, {
                bodyText: (tasksToUpdate.length > 1) ? `${this.formService.getTranslate('OPERATIONAL.WANT_CANCEL_SELECTED_TASKS')}` : `${this.formService.getTranslate('OPERATIONAL.INTEGRATION_CANCEL')} ${tasksToUpdate[0].TASK.NAME}?`,
                actionButtonText: "OPERATIONAL.CANCEL",
                closeButtonText: "GENERAL.CLOSE",
                headerText: (tasksToUpdate.length > 1) ? `${this.formService.getTranslate('GENERAL.MULTIPLE')} - ${this.formService.getTranslate("OPERATIONAL.CANCEL")}` : `${this.formService.getTranslate("GENERAL.TASK")} ${tasksToUpdate[0].TASK.NAME} - ${this.formService.getTranslate("OPERATIONAL.CANCEL")}`,
            });

            if (!confirmed) return;

            this.block();

            const timeout = 120000;

            for (let task of tasksToUpdate) {
                const taskReq: IMonacoRequest = { route: `/newTask/cancel/${task._id}/${timeout}` }
                const result = await this.TaskService.get<boolean>(taskReq);
                if (!result || !result.data) return this.handleError(result);
            }

            await this.clearSelections();
            await this.updateGrid();
            const msgSuccess = this.formService.getTranslate('OPERATIONAL.TASKS_SUCCESSFULLY_UPDATED');
            this.$formService.notifySuccess(msgSuccess);

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async finish(model: NewTaskView): Promise<void> {
        try {
            let tasksToUpdate: NewTaskView[] = [model];
            if (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) tasksToUpdate = this.$scope.selectedRows;
            const msg = this.formService.getTranslate('OPERATIONAL.SELECT_TASKS_APPLY_STATUS');
            if (!tasksToUpdate || tasksToUpdate.length == 0 || !tasksToUpdate[0]) return this.handleError(msg);

            const confirmed = await this.ModalService.showModalConfirmation({}, {
                bodyText: (tasksToUpdate.length > 1) ? `${this.formService.getTranslate('OPERATIONAL.WANT_COMPLETE_SELECTED_TASKS')}` : `${this.formService.getTranslate('OPERATIONAL.INTEGRATION_COMPLETE')} ${tasksToUpdate[0].TASK.NAME}?`,
                actionButtonText: "OPERATIONAL.CONCLUDE",
                closeButtonText: "GENERAL.CLOSE",
                headerText: (tasksToUpdate.length > 1) ? `${this.formService.getTranslate('GENERAL.MULTIPLE')} - ${this.formService.getTranslate("OPERATIONAL.CONCLUDE")}` : `${this.formService.getTranslate("GENERAL.TASK")} ${tasksToUpdate[0].TASK.NAME} - ${this.formService.getTranslate("OPERATIONAL.CONCLUDE")}`,
            });

            if (!confirmed) return;

            let finishAfter = false;

            this.block();

            const endDate = moment();
            const estimatedEndDate = moment(model.ESTIMATED_END_DATE);

            if (model.PARAMS.MANDATORY_DETOUR.ID === '1' || (model.PARAMS.MANDATORY_DETOUR.ID === '2' && endDate.isAfter(estimatedEndDate))) {

                await this.htmlDetour(model, true);
                finishAfter = true;

            } else {
                const timeout = 120000;

                for (let task of tasksToUpdate) {
                    const taskReq: IMonacoRequest = { route: `/newTask/finish/${task._id}/${timeout}` }
                    const result = await this.TaskService.get<NewTaskView>(taskReq);
                    if (!result || !result.data) return this.handleError(result);
                }
            }

            if (model.PARAMS.INTEGRATION_PARAM) {
                await this.generateNotificationMailBox(model);
            }

            if (!finishAfter) {
                await this.followUpModal(tasksToUpdate, model.INVOICE_NUMBER);
                await this.clearSelections();
                await this.updateGrid();
                const msgSuccess = this.formService.getTranslate('OPERATIONAL.TASKS_SUCCESSFULLY_UPDATED');
                this.$formService.notifySuccess(msgSuccess);
            }

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async followUpModal(tasksToUpdate: NewTaskView[], invoiceNumber: string) {

        let processes = [];
        let taskNumber = '';

        //ordena por TASK_NUMBER 
        const tasks = tasksToUpdate.sort(function (a, b) {
            if (a.PARAMS.TASK_NUMBER > b.PARAMS.TASK_NUMBER) return 1;
            if (a.PARAMS.TASK_NUMBER < b.PARAMS.TASK_NUMBER) return -1;
        });

        for (let task of tasks) {
            if (task.PARAMS.TASK_NUMBER !== taskNumber && taskNumber !== '') {
                //modal - a cada troca de TASK_NUMBER
                await this.prepareModal(processes, taskNumber, invoiceNumber);
                processes = [task.PROCESS_NUMBER];
            } else {
                processes.push(task.PROCESS_NUMBER)
            }
            taskNumber = task.PARAMS.TASK_NUMBER;
        }
        //modal - última atividade
        await this.prepareModal(processes, taskNumber, invoiceNumber);
    }

    private async prepareModal(processes: string[], taskNumber: string, invoiceNumber: string) {
        try {
            const request: INotificationMessageServiceRequest = {
                processNumberList: processes,
                referenceType: FollowupReferenceType.NEW_TASK,
                referenceID: taskNumber,
                referenceDate: FollowupReferenceDate.EFFECTIVE
            };

            const notificationMessageService = new NotificationMessageService(request, this.$injector, this.$scope);
            const notificationMessageServiceResult: INotificationMessageServiceResult[] = await notificationMessageService.generateOnQueue(invoiceNumber);
            const automaticNotificationList: INotificationMessageServiceResult[] = (notificationMessageServiceResult && notificationMessageServiceResult.length > 0) ? notificationMessageServiceResult : [];

            for (const auto of automaticNotificationList) {
                await NotificationBox.Instance(this.$injector).create(`
                    ${auto.processNumber} - ${taskNumber} - ${this.formService.getTranslate('GENERAL.TASK')}`,
                    this.formService.getTranslate('GENERAL.FOLLOW_SAVED'),
                    "fa fa-list-alt",
                    "text-blue",
                    "app.management.notificationMonitor",
                    <INotificationMonitorParameter>{ "MESSAGE_METADATA.VOUCHER": auto.voucher },

                );
            }
        } catch (ex) {
            this.formService.notifyWarning(ex);
        }
    }

    private async restore(model: NewTaskView): Promise<void> {
        try {

            let tasksToUpdate: NewTaskView[] = [model];
            if (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) tasksToUpdate = this.$scope.selectedRows;
            const msg = this.formService.getTranslate('OPERATIONAL.SELECT_TASKS_APPLY_STATUS');
            if (!tasksToUpdate || tasksToUpdate.length == 0 || !tasksToUpdate[0]) return this.handleError(msg);

            const confirmed = await this.ModalService.showModalConfirmation({}, {
                bodyText: (tasksToUpdate.length > 1) ? `${this.formService.getTranslate('OPERATIONAL.WANT_RESTORE_SELECTED_TASKS')}` : `${this.formService.getTranslate('OPERATIONAL.INTEGRATION_RESTORE')} ${tasksToUpdate[0].TASK.NAME}?`,
                actionButtonText: "OPERATIONAL.RESTORE",
                closeButtonText: "GENERAL.CLOSE",
                headerText: (tasksToUpdate.length > 1) ? `${this.formService.getTranslate('GENERAL.MULTIPLE')} - ${this.formService.getTranslate("OPERATIONAL.RESTORE")}` : `${this.formService.getTranslate("GENERAL.TASK")} ${tasksToUpdate[0].TASK.NAME} - ${this.formService.getTranslate("OPERATIONAL.RESTORE")}`,
            });

            if (!confirmed) return;

            this.block();

            const timeout = 120000;

            for (let task of tasksToUpdate) {
                const taskReq: IMonacoRequest = { route: `/newTask/restore/${task._id}/${timeout}` }
                const result = await this.TaskService.get<boolean>(taskReq);
                if (!result || !result.data) return this.handleError(result);
            }

            await this.clearSelections();
            await this.updateGrid();
            const msgSuccess = this.formService.getTranslate('OPERATIONAL.TASKS_SUCCESSFULLY_UPDATED');
            this.$formService.notifySuccess(msgSuccess);

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async htmlChangeResponsible(model: NewTaskView): Promise<void> {
        try {
            let tasksToUpdate: NewTaskView[] = [model];
            const msgError = this.formService.getTranslate('OPERATIONAL.SELECT_THE_TASKS_TO_UPDATE_THE_RESPONSIBLE_USER');
            if (!tasksToUpdate || tasksToUpdate.length == 0 || !tasksToUpdate[0]) return this.handleError(msgError);

            const firstSelected = tasksToUpdate[0];

            this.modalID = this.ModalService.newModal();

            this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    formService: 'edit',
                    template: require("../view/newTaskResponsible.html"),
                    size: 'lg'
                },
                {
                    actionButtonText: "GENERAL.CLOSE",
                    headerText: (tasksToUpdate.length > 1) ? `${this.formService.getTranslate('OPERATIONAL.CHANGE_RESPONSIBLE_SELECTED_TASKS')}` : `${this.formService.getTranslate("GENERAL.TASK")}  ${firstSelected.TASK.NAME} - ${this.formService.getTranslate("OPERATIONAL.UPDATE_RESPONSIBLE_USER")}`
                });

            const modalScope: IResponsibleModal = await this.ModalService.getModalScope(this.modalID);

            modalScope.selectedTask = angular.copy(firstSelected);
            const cells = await this.getCellList();
            modalScope.cellList = angular.copy(cells.data);

            //first time, load the role and user of row.
            await this.refreshCellDependencyList(firstSelected.RESPONSIBLE_CELL);
            modalScope.roleList = angular.copy(this.$scope.roleList);
            modalScope.userList = angular.copy(this.$scope.userList);

            //when change the responsible cell, load the lists.
            modalScope.refreshCellDependencyList = async () => {

                await this.refreshCellDependencyList(modalScope.selectedTask.RESPONSIBLE_CELL);
                modalScope.roleList = angular.copy(this.$scope.roleList);
                modalScope.userList = angular.copy(this.$scope.userList);
                modalScope.selectedTask.RESPONSIBLE_ROLE = null;
                modalScope.selectedTask.RESPONSIBLE_USER = null;

                await modalScope.$applyAsync();
            };

            modalScope.changeResponsibleTask = async () => {
                await this.changeResponsibleTask(modalScope.selectedTask);
                await modalScope.$applyAsync();
            };

            await modalScope.$applyAsync();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async htmlDetour(model: NewTaskView, finishTask: boolean): Promise<void> {

        let tasksToUpdate: NewTaskView[] = [model];
        if (!tasksToUpdate || tasksToUpdate.length == 0 || !tasksToUpdate[0]) return this.handleError('Selecione as atividades para alterar Desvio');

        const firstSelected = tasksToUpdate[0];
        this.modalID = this.ModalService.newModal();

        this.ModalService.showModalInfo(
            {
                modalID: this.modalID,
                formService: 'edit',
                template: require("../view/newTaskDetour.html"),
                size: 'lg'
            },
            {
                actionButtonText: "GENERAL.CLOSE",
                headerText: (tasksToUpdate.length > 1) ? `${this.formService.getTranslate('OPERATIONAL.CHANGE_DEVIATION_SELECTED_TASKS')}` : `${this.formService.getTranslate("GENERAL.TASK")}  ${firstSelected.TASK.NAME} - ${this.formService.getTranslate('OPERATIONAL.CHANGE_DEVIATION')}`
            });

        const modalScope: IDetourModal = await this.ModalService.getModalScope(this.modalID);
        if (finishTask) firstSelected.DETOUR = null;
        modalScope.selectedTask = angular.copy(firstSelected);
        modalScope.detourList = model.PARAMS.DETOUR_LIST.map(x => { return { ID: x.DETOUR.ID, NAME: x.DETOUR.NAME } });

        modalScope.changeDetourTask = async () => {
            await this.changeDetourTask(modalScope.selectedTask, finishTask);
            await modalScope.$applyAsync();
        };

        await modalScope.$applyAsync();
    }

    private async changeResponsibleTask(selectedTask: NewTaskView): Promise<void> {
        try {

            let tasksToUpdate: NewTaskView[] = [selectedTask];
            if (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) tasksToUpdate = this.$scope.selectedRows;

            const confirmed = await this.ModalService.showModalConfirmation({}, {
                bodyText: (tasksToUpdate.length > 1) ? `${this.formService.getTranslate('OPERATIONAL.WANT_CHANGE_RESPONSIBLE_SELECTED_TASKS')}` : `${this.formService.getTranslate('OPERATIONAL.WANT_CHANGE_RESPONSIBLE')} ${tasksToUpdate[0].TASK.NAME}?`,
                actionButtonText: "OPERATIONAL.UPDATE_RESPONSIBLE_USER",
                closeButtonText: "GENERAL.CLOSE",
                headerText: (tasksToUpdate.length > 1) ? `${this.formService.getTranslate('GENERAL.MULTIPLE')} - ${this.formService.getTranslate("OPERATIONAL.UPDATE_RESPONSIBLE_USER")}` : `${this.formService.getTranslate("GENERAL.TASK")} ${tasksToUpdate[0].TASK.NAME} - ${this.formService.getTranslate("OPERATIONAL.UPDATE_RESPONSIBLE_USER")}`,
            });

            if (!confirmed) return;

            this.block();

            for (let task of tasksToUpdate) {

                const responsible = {
                    RESPONSIBLE_CELL: selectedTask.RESPONSIBLE_CELL,
                    RESPONSIBLE_ROLE: selectedTask.RESPONSIBLE_ROLE,
                    RESPONSIBLE_USER: selectedTask.RESPONSIBLE_USER,
                }

                const taskReq: IMonacoRequest = {
                    data: {
                        id: task._id,
                        responsible,
                        timeout: 120000
                    },
                    route: '/newTask/responsible',
                    timeout: 120000
                }
                const result = await this.TaskService.post<boolean>(taskReq);
                if (!result || !result.data) return this.handleError(result);
            }

            this.ModalService.closeModal(this.modalID);
            await this.clearSelections();
            await this.updateGrid();
            const msgSuccess = this.formService.getTranslate('OPERATIONAL.TASKS_SUCCESSFULLY_UPDATED');
            this.$formService.notifySuccess(msgSuccess);

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async changeDetourTask(selectedTask: NewTaskView, finiskTask: boolean): Promise<void> {
        try {
            let tasksToUpdate: NewTaskView[] = [selectedTask];
            if (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) tasksToUpdate = this.$scope.selectedRows;

            if (!finiskTask) {

                const confirmed = await this.ModalService.showModalConfirmation({}, {
                    bodyText: (tasksToUpdate.length > 1) ? `${this.formService.getTranslate('OPERATIONAL.WANT_CHANGE_DEVIATION_SELECTED_TASKS')}` : `${this.formService.getTranslate('OPERATIONAL.WANT_CHANGE_DEVIATION')} ${tasksToUpdate[0].TASK.NAME}?`,
                    actionButtonText: "OPERATIONAL.CONCLUDE",
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: (tasksToUpdate.length > 1) ? `${this.formService.getTranslate('GENERAL.MULTIPLES')} - ${this.formService.getTranslate('OPERATIONAL.CHANGE_THE_FAULT_REASON')}` : `${this.formService.getTranslate("GENERAL.TASK")} ${tasksToUpdate[0].TASK.NAME} - ${this.formService.getTranslate('OPERATIONAL.CHANGE_THE_FAULT_REASON')}`,
                });

                if (!confirmed) return;
            }

            this.block();

            this.ModalService.closeModal(this.modalID);

            const timeout = 120000;

            for (let task of tasksToUpdate) {

                const taskReq: IMonacoRequest = {
                    data: {
                        id: task._id,
                        detour: selectedTask.DETOUR,
                        timeout: timeout
                    },
                    route: '/newTask/detour',
                    timeout: timeout
                }
                const result = await this.TaskService.post<boolean>(taskReq);
                if (!result || !result.data) return this.handleError(result);

                if (finiskTask) {
                    const taskReq: IMonacoRequest = { route: `/newTask/finish/${task._id}/${timeout}` }
                    const result = await this.TaskService.get<boolean>(taskReq);
                    if (!result || !result.data) return this.handleError(result);

                    if (task.PARAMS.INTEGRATION_PARAM) {
                        await this.generateNotificationMailBox(task);
                    }
                }
            }

            await this.followUpModal(tasksToUpdate, selectedTask.INVOICE_NUMBER);
            await this.clearSelections();
            await this.updateGrid();
            const msgSuccess = this.formService.getTranslate('OPERATIONAL.TASKS_SUCCESSFULLY_UPDATED');
            this.$formService.notifySuccess(msgSuccess);

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async generateNotificationMailBox(task: NewTaskView) {
        try {
            const user_cell = <SelectorModel>{};

            if (task.RESPONSIBLE_CELL) {
                user_cell.ID = task.RESPONSIBLE_CELL.ID.toString();
                user_cell.NAME = task.RESPONSIBLE_CELL.NAME;
            }

            for (let param = 0; param < task.PARAMS.INTEGRATION_PARAM.length; param++) {
                await NotificationBox.Instance(this.$injector).create(
                    task.PROCESS_NUMBER + " - " + task.PARAMS.TASK_NUMBER + " - " + "{{ 'REGISTRATION.INTEGRATION' | translate }}" + " - " +
                    task.PARAMS.INTEGRATION_PARAM[param].INTEGRATION.NAME,
                    "{{'OPERATIONAL.FOLLOW_UP_NOTIF_SENT_AUTOMATICALLY' | translate }}",
                    `fa fa-${task.PARAMS.INTEGRATION_PARAM[param].NOTIFICATION_TYPE.ICON}`,
                    `text-${task.PARAMS.INTEGRATION_PARAM[param].NOTIFICATION_TYPE.COLOR}`,
                    "app.management.integration",
                    { "REFERENCE": task.PROCESS_NUMBER, "TASK.PARAMS.TASK_NUMBER": task.PARAMS.TASK_NUMBER },
                    task.PARAMS.INTEGRATION_PARAM[param].NOTIFICATION_TYPE,
                    null,
                    user_cell,
                );
            }

        } catch (ex) {
            this.formService.notifyWarning(ex);
        }
    }

    private async clearSelections(): Promise<void> {
        this.$scope.selectedRows = [];
        this.$gridService.$gridApi.selection.clearSelectedRows();

        if (this.$scope.log) this.$scope.log.show = false;
    }

    private async getCellList(): Promise<any> {
        const result = await this.OperationalService.get('/cell/list/', null, 10000);
        return result.data.data;
    }

    private async refreshCellDependencyList(selectedCell: CellModel) {
        try {
            if (!selectedCell || !selectedCell.GROUP) return;

            this.$scope.userList = [];
            this.$scope.roleList = selectedCell.GROUP.filter(x => x.ROLE_REFERENCE).map(x => { return { ID: x.ROLE_REFERENCE.ID.toString(), NAME: x.ROLE_REFERENCE.NAME } });

            for (const cellGroup of selectedCell.GROUP) {
                if (!cellGroup.USER) continue;
                this.$scope.userList = this.$scope.userList.concat(cellGroup.USER.map(x => { return { ID: x.USER_REFERENCE.email, NAME: x.USER_REFERENCE.displayName } }));
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private monitoringFilterChanges() {
        this.$gridService.$gridApi.core.on.filterChanged(this.$scope, this.clearSelections.bind(this));
    }

    private selectedRowCallback(row: IGridRow): void {
        try {
            this.block();

            const task: NewTaskView = row.entity;

            const i = this.selectedRows.findIndex(x => x._id === task._id);

            if (row.isSelected && i === -1)
                this.selectedRows.push(task);
            else
                this.selectedRows.splice(i, 1);

            //update scope
            this.$scope.selectedRows = this.selectedRows.slice();

            if (this.selectedRows.length > 1 && this.$scope.log) this.$scope.log.show = false;

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private selectedRowBatchCallback(rows: IGridRow[]): void {
        try {
            rows = this.$gridService.$gridApi.selection.getSelectedGridRows();
            this.block();

            this.selectedRows = rows.filter(x => x.isSelected).map(x => x.entity);
            if (this.selectedRows.length > 1 && this.$scope.log) this.$scope.log.show = false;

            this.$scope.selectedRows = this.selectedRows.slice();

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            TASK: null,
            SITUATION: null,
            RESPONSIBLE_CELL: null,
            RESPONSIBLE_ROLE: null,
            RESPONSIBLE_USER: null,
            EXECUTOR: null,
            DETOUR: null,
            PROCESS_NUMBER: null,
            PARAMS: null,
            INVOICE_NUMBER: null,
            ACTIVE: true,
            ESTIMATED_START_DATE: null,
            START_DATE: null,
            ESTIMATED_END_DATE: null,
            END_DATE: null,
            SCORE: null,
            PROCESS: null,
            CONCLUSION_SOURCE: null,
            RESPONSIBLE_USER_NAME: null,
            COMMENT: null,
        }
    }

    async request(): Promise<IMonacoRequest> {
        try {

            const route = this.$scope.operation === 'register' ? 'insert' : 'update';

            if (this.$scope.modelList) {
                const allNewTask = []
                for (let task of this.$scope.modelList) {
                    task.newTask.PROCESS_NUMBER = task.PROCESS.NAME;
                    task.newTask.TASK = { "ID": "0", "NAME": task.CUSTOM_DESCRIPTION };
                    task.newTask.RESPONSIBLE_USER = [task.RESPONSIBLE_USER];
                    task.newTask.RESPONSIBLE_CELL = task.RESPONSIBLE_CELL;
                    allNewTask.push(task.newTask);
                }
                return {
                    data: angular.copy(allNewTask),
                    route: (route == 'insert') ? `/newTask/insert/manual/list` : `/newTask/update/manual/list`,
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async requestHistory(id: string): Promise<any> {
        const timeout = 120000;
        const result = await this.TaskService.get({ route: `/newTask/viewLog/${id}/${timeout}`, timeout: timeout });
        if (!result || !result.data) return this.handleError(result);
        return result.data;
    }

    async register(): Promise<void> {
        this.$scope.operation = "insert"
        this.$scope.modelList = [];
        this.$scope.addManualTask();
    }

    async edit(): Promise<void> {

        if (this.$scope.disableSelected(this.$scope.model)) {

            throw (this.formService.getTranslate('OPERATIONAL.EDITING_COMPLETED_CANCELED_TASKS_NOT_ALLOWED'))
        }

        this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")} (${this.$scope.model.TASK.NAME})`;

        this.$scope.modelList = [];

        this.$scope.modelList.push(<INewTasks>{
            newTask: this.$scope.model,
            PROCESS: { ID: null, NAME: this.$scope.model.PROCESS_NUMBER },
            CUSTOM_DESCRIPTION: this.$scope.model.TASK.NAME,
            RESPONSIBLE_USER: this.$scope.model.RESPONSIBLE_USER[0],
            RESPONSIBLE_CELL: this.$scope.model.RESPONSIBLE_CELL,
        });


        this.$scope.showForm = true;
        this.$scope.operation = 'edit';

        this.$timeout(async () => {
            for (let i = 0; i < this.$scope.modelList.length; i++) {
                this.$scope.form[`taskDescription${i}`].$setDirty();
                this.$scope.form[`taskResp${i}`].$setDirty();
                this.$scope.form[`startDate${i}`].$setDirty();
                this.$scope.form[`endDate${i}`].$setDirty();
            }
        }, 500);

        this.$scope.$applyAsync();
    }

    private async viewLog(task) {
        try {
            if (this.$scope.log) this.$scope.log.show = false;
            this.block();
            let log: IViewLog = {
                operation: 'history',
                number: `${task._id}`,
                list: [],
                show: true,
                searchQuery: '',
                originalList: [],
            }
            const result = await this.requestHistory(log.number)
            log.list = result.data;
            log.originalList = angular.copy(log.list);
            this.$scope.log = log;
            angular.element('#log-viewer').removeClass('ng-hide');
            const position = angular.element('#log-viewer').offset().top + $('.app-content-body').scrollTop() - 105;

            $('.app-content-body').animate({
                scrollTop: position
            }, 500);

            this.unblock();
        } catch (err) {
            if (err['status'] && err['status'] === 404) return this.handleWarning(this.formService.getTranslate("GENERAL.NO_DATA_FOUND"));
            return this.handleError(err);
        }
    };

    private async openModalComment(model: NewTaskView): Promise<void> {
        this.modalID = this.ModalService.newModal();
        this.ModalService.showModalInfo(
            {
                modalID: this.modalID,
                scope: this.$scope,
                formService: 'edit',
                template: require("../view/modals/newTaskComment.html"),
                size: 'lg'
            },
            {
                actionButtonText: "GENERAL.CLOSE",
                headerText: "OPERATIONAL.TASK_COMMENT"
            }
        );

        const modalScope: ICommentModal = await this.ModalService.getModalScope(this.modalID);

        modalScope.selectedTask = angular.copy(model);

        modalScope.changeCommentTask = async () => {
            await this.updateComment(modalScope.selectedTask);
            await modalScope.$applyAsync();
            this.ModalService.closeModal(this.modalID);
        };
    }

    private async updateComment(model: NewTaskView): Promise<void> {
        try {
            let tasksToUpdate: NewTaskView[] = [model];
            if (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) tasksToUpdate = this.$scope.selectedRows;
            const msg = this.formService.getTranslate('OPERATIONAL.SELECT_TASKS_APPLY_STATUS');
            if (!tasksToUpdate || tasksToUpdate.length == 0 || !tasksToUpdate[0]) return this.handleError(msg);


            if (tasksToUpdate.length > 1) {
                const confirmed = await this.ModalService.showModalConfirmation({}, {
                    bodyText: this.formService.getTranslate('OPERATIONAL.WANT_COMMENT_SELECTED_TASKS'),
                    actionButtonText: "GENERAL.SAVE",
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: this.formService.getTranslate('GENERAL.SAVE'),
                });

                if (!confirmed) return;
            }

            this.block();

            const timeout = 120000;

            for (let task of tasksToUpdate) {
                const taskReq: IMonacoRequest = {
                    data: {
                        id: task._id,
                        comment: model.COMMENT,
                    },
                    route: '/newTask/update/comment',
                    timeout
                }

                const result = await this.TaskService.post<NewTaskView>(taskReq);
                if (result.status != 200) return this.handleError(result);
            }

            await this.clearSelections();
            await this.updateGrid();

            const msgSuccess = this.formService.getTranslate('OPERATIONAL.TASKS_SUCCESSFULLY_UPDATED');
            this.$formService.notifySuccess(msgSuccess);

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: 'ACTIVE', LABEL: 'GENERAL.ACTIVE' },
            { PROPERTY: 'TASK_NUMBER', LABEL: 'OPERATIONAL.TASK_NUMBER' },
            { PROPERTY: 'TASK', LABEL: 'GENERAL.TASK' },
            { PROPERTY: 'TASK_STEP', LABEL: 'REGISTRATION.STEP' },
            { PROPERTY: 'MODULE', LABEL: 'REGISTRATION.MODULE' },
            { PROPERTY: 'MANDATORY_DETOUR', LABEL: 'REGISTRATION.REQUIRED_FAULT_REASON' },
            { PROPERTY: 'DETOUR_LIST', LABEL: 'REGISTRATION.FAULT_LIST' },
            { PROPERTY: 'RESPONSIBLE_CELL', LABEL: 'REGISTRATION.RESPONSIBLE_CELL' },
            { PROPERTY: 'RESPONSIBLE_ROLE', LABEL: 'REGISTRATION.RESPONSIBLE_ROLE' },
            { PROPERTY: 'RESPONSIBLE_USER', LABEL: 'REGISTRATION.RESPONSIBLE_USER' },
            { PROPERTY: 'PRODUCT', LABEL: 'BASIC_DATA.PRODUCT' },
            { PROPERTY: 'FORWARDED_BY', LABEL: 'GENERAL.FORWARDED_BY' },
            { PROPERTY: 'PROCESS_TYPE', LABEL: 'BASIC_DATA.FILE_TYPE' },
            { PROPERTY: 'COMPANY', LABEL: 'GENERAL.COMPANY' },
            { PROPERTY: 'PRIORITY', LABEL: 'REGISTRATION.PRIORITY' },
            { PROPERTY: 'OPERATIONAL_SITUATION', LABEL: 'OPERATIONAL.FILE_OPERATIONAL_STATUS' },
            { PROPERTY: 'DIRECT_BL', LABEL: 'BASIC_DATA.STRAIGHT_BL' },
            { PROPERTY: 'CARGO_TYPE', LABEL: 'BASICC_DATA.CARGO_TYPE' },
            { PROPERTY: 'DANGER_CARGO', LABEL: 'BASIC_DATA.DANGEROUS_CARGO' },
            { PROPERTY: 'INSURANCE', LABEL: 'FINANCIAL.INSURANCE' },
            { PROPERTY: 'DTA', LABEL: 'REGISTRATION.BONDED_TRUCK' },
            { PROPERTY: 'STATUS_BOOKING', LABEL: 'OPERATIONAL.BOOKING_STATUS' },
            { PROPERTY: 'INCOTERM', LABEL: 'BASIC_DATA.INCOTERM' },
            { PROPERTY: 'MASTER_PAYMENT', LABEL: 'BASIC_DATA.MASTER_PAYMENT_MODE' },
            { PROPERTY: 'HOUSE_PAYMENT', LABEL: 'BASIC_DATA.HOUSE_PAYMENT_MODE' },
            { PROPERTY: 'SERVICE_MOVEMENT', LABEL: 'BASIC_DATA.MOVE_TYPE' },
            { PROPERTY: 'EQUIPMENT', LABEL: 'BASIC_DATA.EQUIPMENT' },
            { PROPERTY: 'SERVICE_TYPE', LABEL: 'BASIC_DATA.SERVICE_TYPE' },
            { PROPERTY: 'BOOKING_TYPE', LABEL: 'BASIC_DATA.BOOKING_TYPE' },
            { PROPERTY: 'CLIENT', LABEL: 'BASIC_DATA.CLIENT' },
            { PROPERTY: 'QUALIFICATION', LABEL: 'GENERAL.CLIENT_QUALIFICATION' },
            { PROPERTY: 'KAM', LABEL: 'BASIC_DATA.KAM' },
            { PROPERTY: 'ORIGIN_AGENT', LABEL: 'BASIC_DATA.LOCAL_AGENT' },
            { PROPERTY: 'DESTINATION_AGENT', LABEL: 'BASIC_DATA.OVERSEAS_AGENT' },
            { PROPERTY: 'SERVICE_PROVIDER', LABEL: 'BASIC_DATA.PROVIDER' },
            { PROPERTY: 'TRANSPORTER_PICKUP', LABEL: 'BASIC_DATA.TRANSPORTER_PICKUP' },
            { PROPERTY: 'TRANSPORTER_PLACE_RECEPT', LABEL: 'BASIC_DATA.TRANSPORTER_PLACE_RECEPT' },
            { PROPERTY: 'TRANSPORTER_FINAL_DESTINATION', LABEL: 'BASIC_DATA.TRANSPORTER_FINAL_DESTINATION' },
            { PROPERTY: 'TRANSPORTER_DELIVERY', LABEL: 'BASIC_DATA.TRANSPORTER_DELIVERY' },
            { PROPERTY: 'TRANSPORTER_GATE_OUT_EMPTY', LABEL: 'BASIC_DATA.TRANSPORTER_GATE_OUT_EMPTY' },
            { PROPERTY: 'TRANSPORTER_CONTAINER_STUFFING', LABEL: 'BASIC_DATA.TRANSPORTER_CONTAINER_STUFFING' },
            { PROPERTY: 'FLEXI_FITTING', LABEL: 'BASIC_DATA.FLEXI_FITTING' },
            { PROPERTY: 'ISOTANK_PROVIDER', LABEL: 'BASIC_DATA.ISOTANK_PROVIDER' },
            { PROPERTY: 'EXTERNAL_BROKER', LABEL: 'BASIC_DATA.EXTERNAL_BROKER' },
            { PROPERTY: 'ORIGIN_GROUP', LABEL: 'BASIC_DATA.ORIGIN_TRADE_GROUP' },
            { PROPERTY: 'ORIGIN_TRADE', LABEL: 'BASIC_DATA.ORIGIN_TRADE_LANE' },
            { PROPERTY: 'ORIGIN_COUNTRY', LABEL: 'BASIC_DATA.ORIGIN_COUNTRY' },
            { PROPERTY: 'ORIGIN_LOCAL', LABEL: 'BASIC_DATA.ORIGIN_LOCATION' },
            { PROPERTY: 'DESTINATION_GROUP', LABEL: 'BASIC_DATA.DESTINATION_TRADE_GROUP' },
            { PROPERTY: 'DESTINATION_TRADE', LABEL: 'TBASIC_DATA.DESTINATION_TRADE_LANE' },
            { PROPERTY: 'DESTINATION_COUNTRY', LABEL: 'BASIC_DATA.DESTINATION_COUNTRY' },
            { PROPERTY: 'DESTINATION_LOCAL', LABEL: 'BASIC_DATA.DESTINATION_LOCATION' },
            { PROPERTY: 'TRANSACTION', LABEL: 'BASIC_DATA.TRANSACTION' },
            { PROPERTY: 'PAYMENT_METHOD', LABEL: 'FINANCIAL.PAYMENT_MEANS' },
            { PROPERTY: 'PAYMENT_CONDITION', LABEL: 'GENERAL.INVOICE_PAYMENT_TERM' },
            { PROPERTY: 'CURRENCY_CONVERSION', LABEL: '~GENERAL.EXCHANGE_RATE_INDEX' },
            { PROPERTY: 'PERIOD_VARIATION', LABEL: 'REGISTRATION.PERIOD_VARIATION' },
            { PROPERTY: 'CONVERSION_REFERENCE', LABEL: 'REGISTRATION.EXC_RATE_REFERENCE_DATE' },
            { PROPERTY: 'NATURE', LABEL: 'BASIC_DATA.PAYMENT_NATURE' },
            { PROPERTY: 'ANTICIPATED_INVOICE', LABEL: 'REGISTRATION.EARLY_RECEIPT' },
            { PROPERTY: 'INVOICE_STATUS', LABEL: 'FINANCIAL.BILLING_STATUS' },
            { PROPERTY: 'INVOICE_FINANCIAL_STATUS', LABEL: 'REGISTRATION.FINANCIAL_STATUS' },
            { PROPERTY: 'PEOPLE', LABEL: 'GENERAL.HOLDER' },
            { PROPERTY: 'START', LABEL: 'OPERATIONAL.DEADLINE_START' },
            { PROPERTY: 'END', LABEL: 'OPERATIONAL.END' },
            { PROPERTY: 'RECALCULATE_TASK', LABEL: 'REGISTRATION.RECALCULATE*' },
            { PROPERTY: 'RECALCULATE_TASK_SITUATION', LABEL: 'REGISTRATION.TASK_STATUS' },
            { PROPERTY: 'RESTORE', LABEL: 'REGISTRATION.RESTORE' },
            { PROPERTY: 'PROCESS_SITUATION', LABEL: 'OPERATIONAL.LOGISTIC_STATUS' },
            { PROPERTY: 'TRIGGER', LABEL: 'REGISTRATION.TRIGGERS' },
            { PROPERTY: 'TARGET_GROUP', LABEL: 'REGISTRATION.MONITORING' },
            { PROPERTY: 'CURRENT_SITUATION', LABEL: 'OPERATIONAL.CURRENT_FILE_STATUS' },
            { PROPERTY: 'TASK_TO_UPDATE', LABEL: 'OPERATIONAL.UPDATE' },
            { PROPERTY: 'SITUATION_TO_UPDATE_TO', LABEL: 'OPERATIONAL.UPDATE_FILE_STATUS' },
            { PROPERTY: 'GENERATE_TASK', LABEL: 'REGISTRATION.GENERATE_TASK' },
            { PROPERTY: 'INSERT_STATUS', LABEL: 'REGISTRATION.INSERT_AND' },
            { PROPERTY: 'UPDATE_STATUS', LABEL: 'OPERATIONAL.UPDATE' },
            { PROPERTY: 'EXCLUDE_STATUS', LABEL: 'GENERAL.DELETE' },
            { PROPERTY: 'TARGETS', LABEL: 'REGISTRATION.TARGET' },
            { PROPERTY: 'CALC_TYPE', LABEL: 'REGISTRATION.CALCULATION_TYPE' },
            { PROPERTY: 'DEADLINE_HOURS', LABEL: 'OPERATIONAL.DEADLINE_HH' },
            { PROPERTY: 'DEADLINE_MINUTES', LABEL: 'BASCI_DATA.STRAIGHT_HOURS' },
            { PROPERTY: 'HOUR_CONCLUSION', LABEL: 'REGISTRATION.CONCLUSION_HOUR' },
            { PROPERTY: 'FIXED_HOURS', LABEL: 'OPERATIONAL.FIXED_HOUR_HH' },
            { PROPERTY: 'FIXED_MINUTES', LABEL: 'OPERATIONAL.FIXED_HOUR_MM' },
            { PROPERTY: 'INTEGRATION', LABEL: 'REGISTRATION.INTEGRATION' },
            { PROPERTY: 'NOTIFICATION_TYPE', LABEL: 'REGISTRATION.NOTIFICATION_TYPE' },
            { PROPERTY: 'HOTSPOT_NUMBER', LABEL: 'OPERATIONAL.HOTSPOT' },
            { PROPERTY: 'TARGET_TYPE', LABEL: 'REGISTRATION.TARGET_TYPE' },
            { PROPERTY: 'TARGET_CLASS', LABEL: 'REGISTRATION.TARGET_CLASS' },
            { PROPERTY: 'TARGET', LABEL: 'REGISTRATION.TARGET' },
            { PROPERTY: 'TARGET_PARAM', LABEL: 'REGISTRATION.PARAMETERS' },
            { PROPERTY: 'TARGET_PARAM_TYPE', LABEL: 'REGISTRATION.PARAMETER_TYPE' },
            { PROPERTY: 'TARGET_GENERIC_IDENTIFIER', LABEL: 'REGISTRATION.GENERIC_IDENTIFIER' },
            { PROPERTY: 'TARGET_TRACKER', LABEL: 'OPERATIONAL.TARGET_TRACKER' },
            { PROPERTY: 'STATUS', LABEL: 'GENERAL.ACTIVE' },
            { PROPERTY: 'EXISTS', LABEL: 'REGISTRATION.CONTAINS' },
            { PROPERTY: 'CRITERIA', LABEL: 'REGISTRATION.CRITERIA' },
            { PROPERTY: 'COMMENT', LABEL: 'OPERATIONAL.COMMENT' },
        ];
        return <ICustomLogProperties[]>props;
    }

}

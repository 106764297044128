import * as angular from 'angular';

const adminState = {
    name: 'app.admin',
    url: '/admin',
    abstract: true,
    data: {
        /* Solução de carregar os termos por módulo
        i18n: ['general', 'basic_data', 'product', 'registration', 'entity', 'operational', 'financial', 'route'],
        */
        requiresAuth: true,
        permissions: {
            except: 'EXTERNAL',
            redirectTo: 'app.denied'
        }
    }
}

const adminMenu = {
    name: 'app.admin.menu',
    url: '/menu',
    views: {
        'content@app': {
            template: require("./view/adminMenu.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.ADMIN',
        parent: 'app.admin'
    }
}

const scoreConfigState = {
    name: 'app.admin.scoreConfig',
    url: '/scoreConfig',
    views: {
        'content@app': {
            template: require("./view/scoreConfigRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.SCORE_CONFIGURATION',
        parent: 'app.admin.menu'
    }
}

const scoreState = {
    name: 'app.admin.score',
    url: '/score',
    views: {
        'content@app': {
            template: require("./view/scoreRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.SCORE',
        parent: 'app.admin.menu'
    }
}

const productConfigurationState = {
    name: 'app.admin.productConfiguration',
    url: '/productConfiguration',
    views: {
        'content@app': {
            template: require("./view/productConfigurationRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.GENERAL_CONFIGURATION',
        parent: 'app.admin.menu'
    }
}

const genericState = {
    name: 'app.admin.generic',
    url: '/generic',
    views: {
        'content@app': {
            template: require("./view/genericRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.GENERIC',
        parent: 'app.admin.menu'
    }
}

const serviceStatusState = {
    name: 'app.admin.status',
    url: '/serviceStatus',
    views: {
        'content@app': {
            template: require("./view/serviceStatus.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.SERVICE_STATUS',
        parent: 'app.admin.menu'
    }
}

const exchangeRateLogState = {
    name: 'app.admin.exchangeRateLog',
    url: '/exchangeRateLog',
    views: {
        'content@app': {
            template: require("./view/exchangeRateLog.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.LOG_UPDATE_CHARGES',
        parent: 'app.admin.menu'
    }
}

const corporateUnit = {
    name: 'app.admin.corporateUnit',
    url: '/corporateUnit',
    views: {
        'content@app': {
            template: require("./view/corporateUnit.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.UNIT_CORPORATE',
        parent: 'app.admin.menu'
    }
}

const analyzeCollectionDataState = {
    name: 'app.admin.analyzeCollectionData',
    url: '/analyzeCollectionData',
    views: {
        'content@app': {
            template: require("./view/analyzeCollectionDataRegister.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.COLLECTION_DOCUMENT_STATISTIC',
        parent: 'app.admin.menu'
    }
}

const analyzeCollectionState = {
    name: 'app.admin.analyzeCollection',
    url: '/analyzeCollection',
    views: {
        'content@app': {
            template: require("./view/analyzeCollectionRegister.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.COLLECTION_STATISTIC',
        parent: 'app.admin.menu'
    }
}

const connectedUsers = {
    name: 'app.admin.connectedUsers',
    url: '/connectedUsers',
    views: {
        'content@app': {
            template: require("./view/connectedUsers.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.CONNECTED_USER',
        parent: 'app.admin.menu'
    }
}

const erpNatureSetupState = {
    name: 'app.admin.erpNatureSetup',
    url: '/erpNatureSetup',
    views: {
        'content@app': {
            template: require("./view/erpNatureSetupRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.ERP_NATURE',
        parent: 'app.admin.menu'
    }
}

const erpTopSetupState = {
    name: 'app.admin.erpTopSetup',
    url: '/erpTopSetup',
    views: {
        'content@app': {
            template: require("./view/erpTopSetupRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.ERP_TOP',
        parent: 'app.admin.menu'
    }
}

const erpProjectSetupState = {
    name: 'app.admin.erpProjectSetup',
    url: '/erpProjectSetup',
    views: {
        'content@app': {
            template: require("./view/erpProjectSetupRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.ERP_PROJECT',
        parent: 'app.admin.menu'
    }
}

const erpNegotiationTypeSetupState = {
    name: 'app.admin.erpNegotiationTypeSetup',
    url: '/erpNegotiationTypeSetup',
    views: {
        'content@app': {
            template: require("./view/erpNegotiationTypeSetupRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.ERP_NEGOTIATE_TYPE',
        parent: 'app.admin.menu'
    }
}

const erpPaymentMethodSetupState = {
    name: 'app.admin.erpPaymentMethodSetup',
    url: '/erpPaymentMethodSetup',
    views: {
        'content@app': {
            template: require("./view/erpPaymentMethodSetupRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.ERP_TITLE_TYPE',
        parent: 'app.admin.menu'
    }
}

const gridSpreadsheet = {
    name: 'app.admin.gridSpreadsheet',
    url: '/gridSpreadsheet',
    views: {
        'content@app': {
            template: require("./view/gridSpreadsheetRegister.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.GRID_SPREADSHEET',
        parent: 'app.admin.menu'
    }
}

routeConfig.$inject = ['$uiRouterProvider'];
function routeConfig($uiRouterProvider) {
    const $stateRegistry = $uiRouterProvider.stateRegistry;
    $stateRegistry.register(adminState);
    $stateRegistry.register(adminMenu);

    $stateRegistry.register(scoreConfigState);
    $stateRegistry.register(productConfigurationState);
    $stateRegistry.register(genericState);
    $stateRegistry.register(scoreState);
    $stateRegistry.register(gridSpreadsheet);

    $stateRegistry.register(serviceStatusState);
    $stateRegistry.register(exchangeRateLogState);

    $stateRegistry.register(corporateUnit);
    $stateRegistry.register(analyzeCollectionDataState);
    $stateRegistry.register(analyzeCollectionState);
    $stateRegistry.register(connectedUsers);

    $stateRegistry.register(erpNatureSetupState);
    $stateRegistry.register(erpTopSetupState);
    $stateRegistry.register(erpProjectSetupState);
    $stateRegistry.register(erpNegotiationTypeSetupState);
    $stateRegistry.register(erpPaymentMethodSetupState);
}

export default function initRoute(moduleName: string): void {
    angular.module(moduleName).config(routeConfig);
}
export function btnYesNoDirective() {
  let ddo: ng.IDirective = {
    restrict: "E",
    transclude: true,
    replace: true,
    template: require("../view/template/btn-yes-no.html"),
    scope: {
      value: "=ngModel",
      isDisabled: "=ngDisabled",
      fnYes: "&",
      fnNo: "&",
      textYes: "@",
      textNo: "@"
    },
    link: ($scope) => { }
  };
  return ddo;
}

import * as angular from "angular";
import * as moment from 'moment';
import { SSEService } from "@appServices/SSEService";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { FormService2, IFormServiceScope } from '@services/FormService2';
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IModalService } from "@services/ModalService";
import { ISessionService } from "@services/SessionService";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IDomesticServiceModel, IDomesticServiceDetails } from "@models/interface/product/DomesticServiceModel";
import { IRoutingPointListCustomFilter } from "@models/interface/product/RoutingPointModel";
import { SelectorModel } from "../../common/model/SelectorModel";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { IFloatingMenu } from "../../common/interface/IFloatingMenu";
import { ILinkParameter } from "../../common/model/ModelParameter";
import { BrowserTitle } from "../../common/BrowserTitle";
import { EOperation } from "@enums/GenericData";
import { IDocumentError } from "WBA-Model/dist/interface/common/IDocumentError";
import { HelperService } from "@services/HelperService";

interface IDomesticServiceDetailModalScope extends IFormServiceScope {
    detail: IDomesticServiceDetails;
    operation: string;
    typeList: SelectorModel[];
    typeLocalOriginList: SelectorModel[];
    typeLocalDestinationList: SelectorModel[];
    frequencyList: SelectorModel[];
    weekdaysList: SelectorModel[];
    routingPointList: SelectorModel[];
    applyDetail: () => void;
    typeLocalFilter: () => Function;
    typeOriginChange: () => void;
    typeDestinationChange: () => void;
    getRoutingPointListByName: (search: string, type: SelectorModel) => Promise<void>;
    goToRoutingPoint: (routingPoint: SelectorModel) => void;
}

interface ILocals {
    ORIGIN: SelectorModel[];
    DESTINATION: SelectorModel[];
}

interface IDomesticServiceScope extends IGridFormServiceScope {
    gridOptions: uiGrid.IGridOptions;
    model: IDomesticServiceModel;
    log: IViewLog;
    productList: SelectorModel[];
    directionList: SelectorModel[];
    operatorTypeList: SelectorModel[];
    agentList: SelectorModel[];
    providerList: SelectorModel[];
    menuFloating: IFloatingMenu;
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IDomesticServiceModel;
    user: any;
    sessionService: ISessionService;
    editDomesticService: (domesticService: IDomesticServiceModel) => Promise<void>;
    viewDomesticService: (domesticService: IDomesticServiceModel) => Promise<void>;
    viewLogDomesticService: (domesticService: IDomesticServiceModel) => Promise<void>;
    copyDomesticService: (domesticService: IDomesticServiceModel) => Promise<void>;
    totalItems: number;
    currentPage: number;
    numPerPage: string;
    detailsSearchInput: string;
    detailsSearchText: string;
    filteredDetails: IDomesticServiceDetails[];
    productChange: (productBefore: SelectorModel) => void;
    directionChange: (directionBefore: SelectorModel) => void;
    filterDetails: () => void;
    paginateDetails: (detail: IDomesticServiceDetails) => void;
    addDetail: () => void;
    copyDetail: ($$hashKey: string) => void;
    editDetail: ($$hashKey: string) => void;
    removeDetail: ($$hashKey: string) => void;
    getAgentListByName: (search: string, operatorType: SelectorModel) => Promise<void>;
    getProviderListByName: (search: string, operatorType: SelectorModel) => Promise<void>;
    collapseHeader: (elementId: string, state?: string) => void;
    goToProvider: (id: number) => void;
    goToAgent: (id: number) => void;
    updateOperatorType: () => void;
    typeOriginChange: (index: number) => void;
    typeDestinationChange: (index: number) => void;
    fetchData: (id: number, action: string) => Promise<void>;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
}

export class DomesticServiceRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IDomesticServiceScope;
    private $q: ng.IQService;
    private $filter: ng.FilterFactory;
    private $timeout: ng.ITimeoutService;
    private RestService: IRestService;
    private ModalService: IModalService;
    private collapseState: string;
    private SSEService: SSEService;
    private gridName: string;
    private sessionService: ISessionService;
    private modalDetailId: number;
    private modalID: number;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IDomesticServiceScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$scope.additionalIndexSelectorValidity = ['$parentIndex'];
        this.$q = $injector.get('$q');
        this.$filter = $injector.get('$filter');
        this.$timeout = $injector.get('$timeout');
        this.RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');
        this.collapseState = "hide";
        this.$scope.sessionService = $injector.get('SessionService');
        this.gridName = 'GRID_DOMESTIC_SERVICE';
        this.SSEService = new SSEService($injector, $scope);
        this.sessionService = $injector.get('SessionService');
        this.helperService = $injector.get('HelperService');
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    private getMenuFloatingDefault(): IFloatingMenu {
        return { tooltipPlacement: "auto bottom", textTooltip: "ROUTE.DOMESTIC_SERVICE_DATA", infos: [{ text: "PRODUCT.DOMESTIC_SERVICE", class: "text-rouge font-bold" }], options: [{ click: "collapseHeader", args: ['collapseBasicData'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.BASIC_DATA", iconClass: "fa fa-plane", iconBodyClass: "text-brown" }, { click: "collapseHeader", args: ['collapseDetails'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.SOLUTION_DETAIL", iconClass: "fa fa-location-arrow", iconBodyClass: "text-dark" }, { click: "collapseHeader", args: ['collapseAll'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.COLLAPSE_EXPAND_ALL", iconClass: "fa fa-expand", iconBodyClass: "text-danger" }], btnActiveDisabled: false };
    }

    private initPanels = (panelsToShow?: string[], panelToNavigate?: string) => {
        try {
            const panels = document.getElementsByClassName("toggle-me");
            if (panels) {
                for (let i = 0; i < panels.length; i++) {
                    const panel = panels[i];
                    if (panelsToShow && panelsToShow.findIndex(obj => obj === panel.id) >= 0) {
                        if (!panel.classList.contains('in')) {
                            $("#" + panel.id)["collapse"]("show");
                        }
                    } else if (panel.classList.contains('in')) {
                        $("#" + panel.id)["collapse"]("hide");
                    }
                }
            }
            if (panelToNavigate) this.$scope.navigateBetweenIds(panelToNavigate);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private collapseHeader(elementId: string, state?: string): void {
        try {
            if (elementId === "collapseAll" || elementId[0] === "collapseAll") {
                this.collapseState = this.collapseState == "hide" ? "show" : "hide";
                $('.toggle-me')["collapse"](state ? state : this.collapseState);
            } else if (elementId != "registerBody") {
                $("#" + elementId)["collapse"](state ? state : 'toggle');
            }
            this.$scope.navigateBetweenIds(elementId);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'domesticService', 'GENERAL.MENU.DOMESTIC_SERVICE', true);
            await this.initGrid(this.gridName, '/domesticService/list', true, true, null, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editDomesticService = async (domesticService: IDomesticServiceModel): Promise<void> => {
            let blockedObject = {
                ID: domesticService.ID,
                NAME: domesticService.LOGISTIC_SOLUTION,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || domesticService.ID !== this.$scope.model.ID) this.$scope.fetchData(domesticService.ID, EOperation.VIEW);
                } else if (this.$scope.operation !== EOperation.EDIT || domesticService.ID !== this.$scope.model.ID) {
                    this.$scope.fetchData(domesticService.ID, EOperation.EDIT);
                }
            };
        }

        this.$scope.viewDomesticService = async (domesticService: IDomesticServiceModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(domesticService.ID, EOperation.VIEW);
        }

        this.$scope.viewLogDomesticService = async (domesticService: IDomesticServiceModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(domesticService);
        }

        this.$scope.copyDomesticService = async (domesticService: IDomesticServiceModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(domesticService.ID, EOperation.COPY);
        }

        try {
            this.$scope.addDetail = (): void => {
                this.openDetailModal();
            }

            this.$scope.copyDetail = ($$hashKey: string) => {
                this.openDetailModal($$hashKey, true);
            }

            this.$scope.editDetail = ($$hashKey: string) => {
                this.openDetailModal($$hashKey);
            }

            this.$scope.removeDetail = ($$hashKey: string): void => {
                this.removeDetail($$hashKey);
            }

            this.$scope.collapseHeader = (elementId: string, state?: string): void => {
                this.collapseHeader(elementId, state);
            }

            this.$scope.goToAgent = (id: number): void => {
                this.$scope.sessionService.openTab("app.registration.agent", <ILinkParameter>{ ID: id ? id.toString() : id });
            }

            this.$scope.getAgentListByName = async (search: string, operatorType: SelectorModel): Promise<void> => {
                return await this.getAgentListByName(search, operatorType);
            }

            this.$scope.getProviderListByName = async (search: string, operatorType: SelectorModel): Promise<void> => {
                return await this.getProviderListByName(search, operatorType);
            }

            this.$scope.collapseHeader = (elementId: string, state?: string): void => {
                this.collapseHeader(elementId, state);
            }

            this.$scope.goToProvider = (id: number): void => {
                this.goToProvider(id);
            }

            this.$scope.updateOperatorType = (): void => {
                this.updateOperatorType();
            }

            this.$scope.productChange = async (productBefore: SelectorModel) => {
                if (this.$scope.model.DETAILS && this.$scope.model.DETAILS.length) {
                    const confirm = await this.ModalService.showModalConfirmation({}, {
                        actionButtonText: 'GENERAL.CONFIRM',
                        closeButtonText: 'GENERAL.NO',
                        headerText: 'GENERAL.CONFIRM_ACTION',
                        bodyText: this.formService.getTranslate('GENERAL.LOST_DETAIL')
                    });
                    if (confirm) this.$scope.model.DETAILS = [];
                    else this.$scope.model.PRODUCT = productBefore;
                }
            }

            this.$scope.directionChange = async (directionBefore: SelectorModel) => {
                if (this.$scope.model.DETAILS && this.$scope.model.DETAILS.length) {
                    const confirm = await this.ModalService.showModalConfirmation({}, {
                        actionButtonText: 'GENERAL.CONFIRM',
                        closeButtonText: 'GENERAL.NO',
                        headerText: 'GENERAL.CONFIRM_ACTION',
                        bodyText: this.formService.getTranslate('GENERAL.LOST_DETAIL')
                    });
                    if (confirm) this.$scope.model.DETAILS = [];
                    else this.$scope.model.DIRECTION = directionBefore;
                }
            }

            this.$scope.filterDetails = () => {
                this.$scope.detailsSearchText = angular.copy(this.$scope.detailsSearchInput);
            }

            this.$scope.paginateDetails = (detail: IDomesticServiceDetails) => {
                return this.paginateDetails(detail);
            }

        } catch (ex) {
            this.handleLoadError(ex);
        }

        this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
            this.fetchData(id, action);
        }

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewDomesticService(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editDomesticService(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogDomesticService(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyDomesticService(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy + modalIntegration),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colLogisticSolution: IMonacoColumnDef = { name: "LOGISTIC_SOLUTION", displayName: "BASIC_DATA.SERVICE", width: 150 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.NAME", displayName: "BASIC_DATA.PRODUCT", width: 150 };
            const colDirection: IMonacoColumnDef = { name: "DIRECTION.NAME", displayName: "PRODUCT.WAY", width: 150 };
            const colOperatorType: IMonacoColumnDef = { name: "OPERATOR_TYPE.NAME", displayName: "ENTITY.COMPANY_TYPE", width: 150 };
            const colProvider: IMonacoColumnDef = { name: "OPERATOR.NAME", displayName: "BASIC_DATA.SERVICE_PROVIDER", width: 150 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 70, cellFilter: "YesOrNo" };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'LOGISTIC_SOLUTION':
                        columnDefs.push(colLogisticSolution);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'DIRECTION':
                        columnDefs.push(colDirection);
                        break;
                    case 'OPERATOR_TYPE':
                        columnDefs.push(colOperatorType);
                        break;
                    case 'OPERATOR':
                        columnDefs.push(colProvider);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        try {
            this.$scope.model = {
                _id: null,
                ID: null,
                LOGISTIC_SOLUTION: null,
                PRODUCT: null,
                DIRECTION: null,
                OPERATOR_TYPE: null,
                PROVIDER: null,
                ID_PROVIDER: null,
                LEGAL_PERSON_AGENT: null,
                ID_LEGAL_PERSON_AGENT: null,
                DETAILS: null,
                ACTIVE: true,
                CREATED_AT: null,
                CREATED_BY: null,
                UPDATED_AT: null,
                UPDATED_BY: null,
                SEARCH_FIELDS: null,
                DOCUMENT_ERROR: null
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initDependencies(): Promise<any> {
        try {
            const self: DomesticServiceRegisterController = this;

            return new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getProductList(),
                    self.getDirectionList(),
                    self.getOperatorTypeList()
                ]).then((result: any) => {
                    self.$scope.productList = self.filterProductList(result[0]);
                    self.$scope.directionList = result[1];
                    self.$scope.operatorTypeList = self.filterOperatorTypeList(result[2]);
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "Integration Product/Operation" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number): Promise<boolean> => {
        let sendSync = false;
        let success = false;
        try {
            if (id) {
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/domesticService/sendSync`, { "idDomesticService": id }, 120000, false);
                if (syncRequest) sendSync = true;

                success = sendSync;
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_SENDING_REQUEST');
        } finally {
            return success;
        }
    }

    private async getDomesticService(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/domesticService/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const domesticData = await this.getDomesticService(id);
                    if (row && domesticData && domesticData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = domesticData.DOCUMENT_ERROR;
                        documentError = domesticData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private initPagination() {
        this.$scope.detailsSearchInput = null;
        this.$scope.detailsSearchText = null;
        this.$scope.totalItems = this.$scope.model.DETAILS ? this.$scope.model.DETAILS.length : 0;
        this.$scope.currentPage = 1;
        this.$scope.numPerPage = "100";
    }

    private paginateDetails(detail: IDomesticServiceDetails) {
        try {
            let begin, end, index;
            begin = (this.$scope.currentPage - 1) * parseInt(this.$scope.numPerPage);
            end = begin + parseInt(this.$scope.numPerPage);
            index = this.$filter('filter')(this.$scope.model.DETAILS, this.$scope.detailsSearchText).indexOf(detail);
            return (begin <= index && index < end);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getDirectionList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/direction`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getOperatorTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/domestic_provider_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/process_event_transport_mode`, null);
        return generic && generic.data ? generic.data : [];
    }

    private filterProductList(productList: SelectorModel[]) {
        return productList.filter(product => product.ID != 'ER' && product.ID != 'IR');
    }

    private filterOperatorTypeList(operatorTypeList: SelectorModel[]) {
        //1 - Armador , 4 Cia Aérea
        return operatorTypeList.filter(operatorType => operatorType.ID != '1' && operatorType.ID != '4');
    }

    private getLocals(): ILocals {

        //DIRECTION (1 - ORIGEM, 2 - DESTINO)
        const locals: ILocals = { ORIGIN: [], DESTINATION: [] };
        try {
            if (this.$scope.model.DIRECTION && this.$scope.model.PRODUCT) {

                if (this.$scope.model.DIRECTION.ID == '1' && (this.$scope.model.PRODUCT.ID == "IA" || this.$scope.model.PRODUCT.ID == "EA")) {
                    locals.ORIGIN = [{ ID: '3', NAME: "Door" }, { ID: '5', NAME: "Terminal" }]
                    locals.DESTINATION = [{ ID: '4', NAME: "Airport" }, { ID: '5', NAME: "Terminal" }]
                }

                if (this.$scope.model.DIRECTION.ID == '2' && (this.$scope.model.PRODUCT.ID == "IA" || this.$scope.model.PRODUCT.ID == "EA")) {
                    locals.ORIGIN = [{ ID: '4', NAME: "Airport" }, { ID: '5', NAME: "Terminal" }]
                    locals.DESTINATION = [{ ID: '3', NAME: "Door" }, { ID: '5', NAME: "Terminal" }]
                }

                if (this.$scope.model.DIRECTION.ID == '1' && (this.$scope.model.PRODUCT.ID == "IM" || this.$scope.model.PRODUCT.ID == "EM")) {
                    locals.ORIGIN = [{ ID: '3', NAME: "Door" }, { ID: '5', NAME: "Terminal" }]
                    locals.DESTINATION = [{ ID: '5', NAME: "Terminal" }, { ID: '2', NAME: "Port" }]
                }

                if (this.$scope.model.DIRECTION.ID == '2' && (this.$scope.model.PRODUCT.ID == "IM" || this.$scope.model.PRODUCT.ID == "EM")) {
                    locals.ORIGIN = [{ ID: '5', NAME: "Terminal" }, { ID: '2', NAME: "Port" }]
                    locals.DESTINATION = [{ ID: '3', NAME: "Door" }, { ID: '5', NAME: "Terminal" }]
                }

            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return locals;
        }
    }

    private async getRoutingPointListByName(filter: IRoutingPointListCustomFilter): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            if (!filter) throw Error('filter is null.');
            if (filter && filter.name && filter.name.length >= 2) {
                if (!filter.types) throw Error('Select type first.');
                else {
                    this.formService.block();
                    const routingPoints = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/routingPoint/list/custom/`, filter, 30000, false);
                    result = routingPoints ? routingPoints.map((x) => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE } }) : []
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getAgentListByName(search: string, operatorType: SelectorModel): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                if (!operatorType || !operatorType.ID) throw Error(this.formService.getTranslate("PRODUCT.SELECT_SPECIALIZATION_FIRST"));

                this.formService.block();

                const products = [this.$scope.model.PRODUCT.ID];
                const agents = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/agent/list/custom`, { search, products }, 30000, false);
                result = agents ? agents.map(x => { return { ID: x.ID, NAME: x.NAME } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.agentList = result;
        }
    }

    private async getProviderListByName(search: string, operatorType: SelectorModel): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 3) {
                if (!this.$scope.model.PRODUCT) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                if (!operatorType || !operatorType.ID) throw Error(this.formService.getTranslate("PRODUCT.SELECT_SPECIALIZATION_FIRST"));

                this.formService.block();

                const types = this.$scope.model.OPERATOR_TYPE.ID == '1' ? ['2'] : ['3'];

                const providers = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/provider/list/custom/`, { types: types, search: search }, 30000, false);
                result = providers ? providers.map(provider => { return { ID: provider.ID.toString(), NAME: provider.NAME, CODE: provider.SCAC_IATA } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.providerList = result;
            this.formService.unblock();
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.operation = "register";
            this.$scope.disableElements(false);
            this.initPanels(["collapseBasicData", "collapseDetails"], "collapseBasicData");
            this.initPagination();
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.SSEService.closeEvents();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.menuFloating.formOperation = this.$scope.formOperation;
            this.$scope.menuFloating.infos = [{ text: "PRODUCT.DOMESTIC_SERVICE", class: "text-rouge font-bold" }];
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.operation = "view";
            this.$scope.disableElements(true);
            this.initPanels(["collapseBasicData", "collapseDetails"], "collapseBasicData");
            this.initPagination();
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.SSEService.closeEvents();
            BrowserTitle.$id = this.$scope.model.LOGISTIC_SOLUTION;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.operation = "edit";
            this.$scope.disableElements(false);
            this.initPanels(["collapseDetails"], "collapseBasicData");
            this.initPagination();
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            BrowserTitle.$id = this.$scope.model.LOGISTIC_SOLUTION;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        try {
            const route = this.$scope.operation == 'register' ? 'insert' : 'update';
            return {
                route: `/domesticService/${route}`,
                data: angular.copy(this.$scope.model),
                oldData: angular.copy(this.$scope.scopeBeforeSave),
                timeout: 15000
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async copy(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.initPanels(["collapseBasicData", "collapseDetails"], "collapseBasicData");
            this.initPagination();
            await this.clearFields(this.$scope.model);
            if (this.$scope.model.DETAILS && this.$scope.model.DETAILS.length > 0) {
                for (const detail of this.$scope.model.DETAILS) {
                    detail.ID = null;
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openDetailModal($$hashKey?: string, isCopy?: boolean): Promise<void> {
        try {
            const msgProduct = this.formService.getTranslate('ROUTE.MESSAGES.PRODUCT_FIRST')
            const msgDirection = this.formService.getTranslate('GENERAL.DIRECTION_DETAIL')
            if (!this.$scope.model.PRODUCT) return this.formService.notifyError(msgProduct);
            if (!this.$scope.model.DIRECTION) return this.formService.notifyError(msgDirection);
            const isRegister = $$hashKey == null || isCopy;
            const index = $$hashKey && this.$scope.model.DETAILS && this.$scope.model.DETAILS.length ? this.$scope.model.DETAILS.findIndex(detail => detail["$$hashKey"] == $$hashKey) : -1;
            const detail = index >= 0 ? this.$scope.model.DETAILS[index] : {
                _id: null,
                ID: null,
                TYPE: null,
                TYPE_LOCAL_ORIGIN: null,
                ROUTING_POINT_ORIGIN: null,
                TYPE_LOCAL_DESTINATION: null,
                ROUTING_POINT_DESTINATION: null,
                FREQUENCY: null,
                TRANSIT_TIME_MIN: null,
                TRANSIT_TIME_MAX: null,
                ID_DOMESTIC_SERVICE: null,
                ID_ROUTING_POINT_ORIGIN: null,
                ID_ROUTING_POINT_DESTINATION: null
            };

            if (isCopy) {
                detail._id = null;
                detail.ID = null;
            }

            this.modalDetailId = this.ModalService.newModal();
            this.ModalService.showModalInfo(
                {
                    modalID: this.modalDetailId,
                    scope: this.$scope,
                    formService: isRegister ? 'register' : 'edit',
                    template: require("../view/modals/domesticServiceDetailModal.html"),
                    size: 'lg'
                },
                {
                    actionButtonText: 'Fechar',
                    headerText: isRegister ? 'REGISTRATION.NEW_DETAIL ' : 'REGISTRATION.EDIT_DETAIL'
                });
            await this.buildDetailModalScope(detail, index, isRegister ? 'register' : 'edit');
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeDetail($$hashKey: string): Promise<void> {
        try {
            const index = this.$scope.model.DETAILS && this.$scope.model.DETAILS.length ? this.$scope.model.DETAILS.findIndex(detail => detail["$$hashKey"] == $$hashKey) : -1;
            if (index >= 0) {
                const modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: "GENERAL.CONFIRM",
                    closeButtonText: "GENERAL.NO",
                    headerText: "GENERAL.CONFIRM_ACTION",
                    bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
                });
                if (!modal) return;
                this.formService.block();
                this.$scope.model.DETAILS.splice(index, 1);
                this.formService.unblock();
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async buildDetailModalScope(detail: IDomesticServiceDetails, index: number, operation: string): Promise<IDomesticServiceDetailModalScope> {
        const modalScope: IDomesticServiceDetailModalScope = await this.ModalService.getModalScope(this.modalDetailId);
        try {
            const locals: ILocals = this.getLocals();
            modalScope.operation = operation;
            modalScope.detail = angular.copy(detail);
            modalScope.weekdaysList = this.weekDaysList();

            // get generics
            const result: Array<any> = await this.$q.all([
                this.getTypeList()
            ]);
            modalScope.typeList = result[0];
            modalScope.typeLocalOriginList = locals.ORIGIN;
            modalScope.typeLocalDestinationList = locals.DESTINATION;

            modalScope.applyDetail = async () => {
                if (!await this.hasInvalidRequiredElements("modalDetailForm")) this.applyDetail(modalScope.detail, modalScope.operation, index);
            };

            modalScope.typeLocalFilter = (): Function => {
                const terminalId = "5";
                return function (type: SelectorModel) {
                    return type.ID != terminalId || !(type.ID == terminalId && modalScope.detail && ((modalScope.detail.TYPE_LOCAL_ORIGIN && modalScope.detail.TYPE_LOCAL_ORIGIN.ID == terminalId) || (modalScope.detail.TYPE_LOCAL_DESTINATION && modalScope.detail.TYPE_LOCAL_DESTINATION.ID == terminalId)));
                }
            }

            modalScope.typeOriginChange = (): void => {
                modalScope.detail.ROUTING_POINT_ORIGIN = null;
                this.$timeout(() => { modalScope.selectorValidity("detailOrigin") });
            }

            modalScope.typeDestinationChange = (): void => {
                modalScope.detail.ROUTING_POINT_DESTINATION = null;
                this.$timeout(() => { modalScope.selectorValidity("detailDestination") });
            }

            modalScope.getRoutingPointListByName = async (search: string, type: SelectorModel): Promise<void> => {
                let routingPointList: SelectorModel[] = [];
                if (search && search.length >= 3) routingPointList = await this.getRoutingPointListByName({ name: search, types: type ? [type.ID] : null });
                modalScope.routingPointList = routingPointList;
            }

            modalScope.goToRoutingPoint = (routingPoint: SelectorModel): void => {
                this.sessionService.openTab("app.registration.routingPoint", <ILinkParameter>{ ID: routingPoint ? routingPoint.ID.toString() : routingPoint });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return modalScope;
        }
    }

    private async applyDetail(detail: IDomesticServiceDetails, operation: string, index: number): Promise<void> {
        try {
            if (operation === "register") {
                if (!this.$scope.model.DETAILS) this.$scope.model.DETAILS = [];
                const msgAddSuccess = this.formService.getTranslate('GENERAL.ADD_DETAIL');
                this.$scope.model.DETAILS.push(detail);
                this.formService.notifySuccess(msgAddSuccess);
            } else {
                const msgEditSuccess = this.formService.getTranslate('GENERAL.EDIT_DETAIL');
                this.formService.notifySuccess(msgEditSuccess);
                this.$scope.model.DETAILS.splice(index, 1, detail);
            }
            this.ModalService.closeModal(this.modalDetailId);
            this.modalDetailId = 0;
        }
        catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private goToProvider(id: number): void {
        try {
            this.$scope.sessionService.openTab("app.registration.provider", <ILinkParameter>{ ID: id ? id.toString() : id });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private weekDaysList(): SelectorModel[] {
        const days: SelectorModel[] = [];
        try {
            for (let day = 1; day <= 7; day++) { // weekdays
                days.push({ ID: day.toString(), NAME: moment.weekdaysShort(day) });
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            return days;
        }
    }

    private updateOperatorType(): void {
        try {
            this.$scope.model.PROVIDER = null;
            this.$scope.model.ID_PROVIDER = null;
            this.$scope.model.LEGAL_PERSON_AGENT = null;
            this.$scope.model.ID_LEGAL_PERSON_AGENT = null;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async hasInvalidRequiredElements(elementId: string): Promise<boolean> {
        if (!elementId) return false;
        const isInvalid = FormService2.hasRequiredElements('#' + elementId);
        const msgError = this.formService.getTranslate('GENERAL.ALL_FIELDS_MANDATORY');
        if (isInvalid) this.formService.notifyError(msgError);
        return isInvalid;
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        try {
            const props: Array<ICustomLogProperties> = [
                {
                    PROPERTY: 'LOGISTIC_SOLUTION',
                    LABEL: 'BASIC_DATA.SERVICE'
                },
                {
                    PROPERTY: 'PRODUCT',
                    LABEL: 'BASIC_DATA.PRODUCT'
                },
                {
                    PROPERTY: 'DIRECTION',
                    LABEL: 'PRODUCT.WAY'
                },
                {
                    PROPERTY: 'OPERATOR_TYPE',
                    LABEL: 'ENTITY.COMPANY_TYPE'
                },
                {
                    PROPERTY: 'PROVIDER',
                    LABEL: 'BASIC_DATA.PROVIDER'
                },
                {
                    PROPERTY: 'LEGAL_PERSON_AGENT',
                    LABEL: 'BASIC_DATA.AGENT'
                },
                {
                    PROPERTY: 'DETAILS',
                    LABEL: 'GENERAL.SOLUTION_DETAIL'
                },
                {
                    PROPERTY: 'FREQUENCY',
                    LABEL: 'ROUTE.SERVICE_FREQUENCY'
                },
                {
                    PROPERTY: 'TRANSIT_TIME_MIN',
                    LABEL: 'GENERAL.TRANSIT_TIME_MIN'
                },
                {
                    PROPERTY: 'TRANSIT_TIME_MAX',
                    LABEL: 'GENERAL.TRANSIT_TIME_MAX'
                },
                {
                    PROPERTY: 'ACTIVE',
                    LABEL: 'GENERAL.ACTIVE'
                },
                {
                    PROPERTY: 'TYPE',
                    LABEL: 'GENERAL.TYPE'
                },
                {
                    PROPERTY: 'TYPE_LOCAL_ORIGIN',
                    LABEL: 'ROUTE.ORIGIN_TYPE'
                },
                {
                    PROPERTY: 'ROUTING_POINT_ORIGIN',
                    LABEL: 'BASIC_DATA.ORIGIN'
                },
                {
                    PROPERTY: 'TYPE_LOCAL_DESTINATION',
                    LABEL: 'ROUTE.DESTINATION_TYPE'
                },
                {
                    PROPERTY: 'ROUTING_POINT_DESTINATION',
                    LABEL: 'BASIC_DATA.DESTINATION'
                },
                {
                    PROPERTY: 'NAME',
                    LABEL: 'GENERAL.NAME'
                },
                {
                    PROPERTY: 'CODE',
                    LABEL: 'GENERAL.CODE'
                },
                {
                    PROPERTY: 'ID',
                    LABEL: 'REGISTRATION.IDENTIFICATION'
                }
            ];
            return props;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/domesticService/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }
}

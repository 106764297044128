import angular = require('angular');
import * as uuid from 'uuid';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { IRestService } from "@services/RestService";
import { FormService2 } from '@services/FormService2';
import { ISessionService } from "@services/SessionService";
import { ProductService } from '@services/ProductService';
import { DataProcessService } from "@services/DataProcessService";
import { IModalService } from '@services/ModalService';
import { OperationalService } from '@services/OperationalService';
import { IMonacoRequest } from '@services/GridFormService';
import { IViewLog } from "@models/interface/common/IViewLog";
import { CARGO_LIST, DANGER_CARGO, SECURE, ENTITY } from '@models/interface/operational/NewProcess';
import { ISelectorModel, SelectorModel } from '@models/mongo/SelectorModel';
import { IChargeNameListCustomFilter } from '@models/interface/product/ChargeNameModel';
import { INewProcessCargoModel } from '@models/interface/operational/NewProcessTabsModel';
import { IProcessCargo, IInsurance, IVehicleTypeList } from '@models/interface/dataProcess/ProcessCargo';
import { IApplicationList } from '@models/interface/product/ApplicationModel';
import { IProductConfigurationSecure, IProductConfigurationModel } from '@models/interface/product/ProductConfigurationModel';
import { IWeightRangeSelector } from '@models/interface/product/WeightRangeModel';
import { IEquipmentSelector } from '@models/interface/product/EquipmentModel';
import { ECargoTypeId, EChargeOriginId, EOperation, EProductId, EProcessSituation, EEventType, EProcessTypeId } from '@enums/GenericData';
import { ELegalPersonSpecializationId } from '@enums/LegalPerson';
import { INewProcessScope, ECollapseState } from './NewProcessController';
import { IOfferRequestOperationWM, IOfferRequestOperationChargeable, IOfferRequestCargoListConversion, ICargo, ICargoSummary } from '../../product/model/OfferModel';
import { ILegalPersonListCustomFilter } from '../../registration/model/LegalPersonModel';
import { ILinkParameter } from '../../common/model/ModelParameter';
import { IParamsDetDemListRequest, IParamsDetDem } from 'WBA-Model/dist/interface/product/ParamsDetDem';
import { IMonacoConfig } from '../../common/MonacoInterface';
import { HelperService } from "@services/HelperService";

interface IModifiedReasonModal {
    cargoModifiedType: ISelectorModel,
    modifiedResponsibleType: ISelectorModel
}

interface INewProcessCargoScope extends ng.IScope {
    form: ng.IFormController;
    log: IViewLog;
    model: INewProcessCargoModel;
    oldModel: INewProcessCargoModel;
    vehicleListModel: IVehicleTypeList[];
    cargoModel: IProcessCargo;
    oldCargoModel: IProcessCargo;
    oldVehicleListModel: IVehicleTypeList[];
    oldSecure: SECURE;
    gateInFullEffective: Date;
    gateInFullForecast: Date;
    loadForecast: Date;
    bookingStatus: string;
    qtdContainers: number;
    qtdTeu: number;
    serviceProvider: string;
    vessel: string;
    processSituation: string;
    cargoChannelList: ISelectorModel[];
    packageTypeList: ISelectorModel[];
    cargoListPolLb: CARGO_LIST[];
    isCargoCalculatedFieldsDisabled: boolean;
    currencyList: ISelectorModel[];
    chargeNameList: ISelectorModel[];
    applicationList: IApplicationList[];
    insuranceBrokerList: ENTITY[];
    unClassList: ISelectorModel[];
    packingGroupList: ISelectorModel[];
    modifiedTypeList: ISelectorModel[];
    fileSpecsResponsibleList: ISelectorModel[];
    modifiedReasonModal: IModifiedReasonModal;
    productConfigurationList: IProductConfigurationModel[];
    weightRangeList: IWeightRangeSelector[];
    processNumber: string;
    airCargoTreatment: ISelectorModel[];
    paramsDDDetList: IParamsDetDem[];
    paramsDDDemList: IParamsDetDem[];
    paramsDDCombinedList: IParamsDetDem[];
    isCargoListGrossWeightFieldsDisabled: boolean;
    equipmentList: IEquipmentSelector[];
    inttraDangerousPackageTypeList: ISelectorModel[];
    collapseCargo: () => void;
    isCollapseIn(): boolean;
    isCargoAir(): boolean;
    isCargoFcl(): boolean;
    isCargoLcl(): boolean;
    isCargoBreakBulkOrRoro(): boolean;
    isCargoRoad(): boolean;
    isAirChargeableWeightDisabled: () => boolean;
    isQuantityDisabled: () => boolean;
    isCargoTotalGrossWeightDisabled: () => boolean;
    isLiquidProcessType: () => boolean;
    cargoListGrossWeightFieldsControl: (grossWeightTotal: number) => void;
    enableTotalGrossWeight: () => void;
    buildDetDemValuesView: (values: ICargoSummary) => string;
    openDetDemDetails: () => void;
    hasChanges: () => boolean;
    airChargeableWeightChange: (amount: number) => void;
    airOverChargeableWeightChange: () => void;
    weightRangeChange: () => void;
    grossWeightChange: () => void;
    cubicWeightChange: () => void;
    calculateCargoPolLbCBW: (index: number) => void;
    calculateCargoPolLbGrossWeightTotal: (index: number) => void;
    calculateCargoCBW: (index: number) => void;
    calculateCargoGrossWeightTotal: (index: number) => void;
    calculateGrossWeightTotal: () => void;
    calculateVolumeTotal: () => void;
    addCargo: () => void;
    removeCargo: (index: number) => void;
    addVehicle: () => void;
    removeVehicle: (index: number, vehicleId: string) => void;
    addCargoPolLb: () => void;
    removeCargoPolLb: (index: number) => void;
    launchCargoPolLb: () => void;
    applyCargoListPolLb: (apply: boolean) => void;
    addDangerousCargo: () => void;
    removeDangerousCargo: (index: number) => void;
    getWeightRangeMinValue: (weightRangeList: IWeightRangeSelector[], overTaxed: number) => number;
    getWeightRangeMaxValue: (weightRangeList: IWeightRangeSelector[], overTaxed: number) => number;
    getCurrencyListByName: (search: string) => Promise<void>;
    getChargeNameListByName: (search: string) => Promise<void>;
    getApplicationListByName: (search: string) => Promise<void>;
    getInsuranceBrokerListByName: (search: string) => Promise<void>;
    chargeNameChange: (optionChargeName: ISelectorModel) => void;
    specSecureChange: () => void;
    updateDtaModel: () => void;
    isSankhyaLiquid: () => boolean;
    thereIsCargoReadinessHistory: () => boolean;
    temperatureValidation: (temperature: number) => void;
    specRegrigeratedChange: () => void;
    specCargoTreatmentChange: () => void;
    isAirProduct: () => boolean;
    isMaritimeProduct: () => boolean;
    getParamsDDDemList: (direction: string, nature: string) => Promise<void>;
    getParamsDDDetList: (direction: string, nature: string) => Promise<void>;
    getParamsDDCombinedList: (direction: string, nature: string) => Promise<void>;
    isParamDetDemValid: (isValid: boolean, type: number) => boolean;
    applyDetDemDetails: () => Promise<void>;
    disablePanelDueToPreProcessStatus: () => boolean;
    getEquipmentListByName: (search: string) => Promise<void>;
    openCargoReadinessHistoryModal: (model: INewProcessCargoModel) => void;
}

export class NewProcessCargoController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: INewProcessCargoScope;
    private $newProcessScope: INewProcessScope;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private $sce: angular.ISCEService;
    private $compile: angular.ICompileService;
    private productService: ProductService;
    private dataProcessService: DataProcessService;
    private restService: IRestService;
    private formService: FormService2;
    private sessionService: ISessionService;
    private detDemModalId: number;
    private modalService: IModalService;
    private operationalService: OperationalService;
    private modalCargoPolLbId: number;
    private modalCargoModifiedTypeId: number;
    private config: IMonacoConfig
    private helperService: HelperService;
    private ModalService: IModalService;
    private modalID: number;

    constructor($injector: ng.Injectable<any>, $scope: INewProcessCargoScope) {
        this.$scope = $scope;
        this.$newProcessScope = <INewProcessScope>$scope.$parent.$parent;
        this.$q = this.$newProcessScope.$q;
        this.$sce = this.$newProcessScope.$sce;
        this.$compile = this.$newProcessScope.$compile;
        this.$timeout = this.$newProcessScope.$timeout;
        this.productService = this.$newProcessScope.productService;
        this.dataProcessService = this.$newProcessScope.dataProcessService;
        this.restService = this.$newProcessScope.restService;
        this.formService = this.$newProcessScope.formService;
        this.sessionService = this.$newProcessScope.sessionService;
        this.detDemModalId = 0;
        this.modalService = this.$newProcessScope.modalService;
        this.operationalService = this.$newProcessScope.operationalService;
        this.initScopeFunctions();
        this.config = $injector.get('config');
        this.helperService = $injector.get('HelperService');
        this.ModalService = $injector.get('ModalService');
    }

    async $onInit(): Promise<void> {
        try {
            this.initModel();
            await this.initDependencies();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initModel(): void {
        if (this.$newProcessScope.model) {
            this.$scope.model = {
                CARGO_TYPE: this.$newProcessScope.model.CARGO_TYPE,
                CARGO_DETAIL: this.$newProcessScope.model.CARGO_DETAIL,
                CARGO_LIST: this.$newProcessScope.model.CARGO_LIST,
                DTA: this.$newProcessScope.model.DTA,
                LI: this.$newProcessScope.model.LI,
                LI_DATE: this.$newProcessScope.model.LI_DATE,
                CREDIT_LETTER: this.$newProcessScope.model.CREDIT_LETTER,
                CREDIT_LETTER_DATE: this.$newProcessScope.model.CREDIT_LETTER_DATE,
                DTA_INFORMATION: this.$newProcessScope.model.DTA_INFORMATION,
                CARGO_READINESS_HISTORY: this.$newProcessScope.model.CARGO_READINESS_HISTORY
            };
        } else {
            this.$scope.model = {
                CARGO_TYPE: null,
                CARGO_DETAIL: null,
                CARGO_LIST: null,
                DTA: null,
                LI: null,
                LI_DATE: null,
                CREDIT_LETTER: null,
                CREDIT_LETTER_DATE: null,
                DTA_INFORMATION: null,
                CARGO_READINESS_HISTORY: null
            };
        }
        this.$scope.oldModel = angular.copy(this.$scope.model);
    }

    private initScopeFunctions(): void {

        this.$scope.collapseCargo = (): void => {
            this.collapseCargo();
        }

        this.$scope.isCollapseIn = (): boolean => {
            return this.$newProcessScope.collapseState.panel == ECollapseState.CARGO && !this.$newProcessScope.collapseState.released;
        }

        this.$scope.isCargoAir = (): boolean => {
            return this.$scope.model && this.$scope.model.CARGO_TYPE && this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.AIR;
        }

        this.$scope.isCargoFcl = (): boolean => {
            return this.$scope.model && this.$scope.model.CARGO_TYPE && this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.FCL;
        }

        this.$scope.isCargoLcl = (): boolean => {
            return this.$scope.model && this.$scope.model.CARGO_TYPE && this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.LCL;
        }

        this.$scope.isCargoBreakBulkOrRoro = (): boolean => {
            return this.$scope.model && this.$scope.model.CARGO_TYPE && (this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.BREAK_BULK || this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.RO_RO);
        }

        this.$scope.isCargoRoad = (): boolean => {
            return this.$scope.model && this.$scope.model.CARGO_TYPE && this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.ROAD;
        }

        this.$scope.isAirChargeableWeightDisabled = (): boolean => {
            return ((this.$scope.model.CARGO_LIST && this.$scope.model.CARGO_LIST.length > 0) || this.$scope.model.CARGO_DETAIL.VOLUME || this.$scope.model.CARGO_DETAIL.GROSS_WEIGHT || this.$scope.model.CARGO_DETAIL.CBM) ? true : false;
        }

        this.$scope.isQuantityDisabled = (): boolean => {
            return ((this.$scope.model.CARGO_LIST && this.$scope.model.CARGO_LIST.length > 0) || (this.$scope.model.CARGO_DETAIL && (this.$scope.model.CARGO_DETAIL.VOLUME || this.$scope.model.CARGO_DETAIL.GROSS_WEIGHT || this.$scope.model.CARGO_DETAIL.CBM))) ? true : false;
        }

        this.$scope.isCargoTotalGrossWeightDisabled = (): boolean => {
            const cargoList = this.$scope.model ? this.$scope.model.CARGO_LIST : null;
            return cargoList && cargoList.findIndex(cargo => (cargo.WEIGHT || cargo.WEIGHT == 0) || (cargo.TOTAL_WEIGHT || cargo.TOTAL_WEIGHT == 0)) >= 0;
        }

        this.$scope.isLiquidProcessType = (): boolean => {
            return this.$newProcessScope.model.PROCESS_TYPE && this.$newProcessScope.model.PROCESS_TYPE.ID == EProcessTypeId.LIQUID;
        }

        this.$scope.cargoListGrossWeightFieldsControl = (grossWeightTotal: number) => {
            this.$scope.isCargoListGrossWeightFieldsDisabled = this.$scope.isCargoTotalGrossWeightDisabled() ? false : grossWeightTotal !== null && angular.isDefined(grossWeightTotal);
        }

        this.$scope.enableTotalGrossWeight = () => {
            this.enableTotalGrossWeight();
        }

        this.$scope.isSankhyaLiquid = (): boolean => {
            return (this.$scope.model && this.$newProcessScope.model.PROCESS_TYPE.ID == '4' && this.$newProcessScope.model.INTEGRATION_DIRECTION.ID == '1');
        }

        this.$scope.thereIsCargoReadinessHistory = (): boolean => {
            return (this.$scope.model && this.$scope.model.CARGO_READINESS_HISTORY !== null);
        }

        this.$scope.buildDetDemValuesView = (values: ICargoSummary): string => {
            return this.buildDetDemValuesView(values);
        }

        this.$scope.openDetDemDetails = () => {
            this.openDetDemDetails();
        }

        this.$scope.hasChanges = (): boolean => {
            return this.$newProcessScope.hasChanges(this.$scope.model, this.$scope.oldModel);
        }

        this.$scope.airChargeableWeightChange = (amount: number) => {
            this.updateWeightRangeBase();
            this.updateCargoCalculateFieldsDisabledControl(amount);
        }

        this.$scope.airOverChargeableWeightChange = () => {
            this.updateWeightRangeBase();
        }

        this.$scope.weightRangeChange = () => {
            this.updateWeightRangeBase();
        }

        this.$scope.grossWeightChange = () => {
            this.grossWeightChange();
        }

        this.$scope.cubicWeightChange = () => {
            this.cubicWeightChange();
        }

        this.$scope.calculateCargoPolLbCBW = (index: number): void => {
            this.calculateCargoPolLbCBW(index);
        }

        this.$scope.calculateCargoPolLbGrossWeightTotal = (index: number): void => {
            this.calculateCargoPolLbGrossWeightTotal(index);
        }

        this.$scope.calculateCargoGrossWeightTotal = (index: number): void => {

            if (!index && index !== 0) throw this.formService.notifyError('index is null');
            if (this.$scope.model.CARGO_LIST && this.$scope.model.CARGO_LIST.length > 0 && this.$scope.model.CARGO_LIST[index] && this.$scope.model.CARGO_LIST[index].WEIGHT > 0) {
                const grossWeightTotal = this.calculateCargoGrossWeightTotal(this.$scope.model.CARGO_LIST[index]);
                this.$scope.model.CARGO_LIST[index].TOTAL_WEIGHT = grossWeightTotal ? parseFloat(grossWeightTotal.toFixed(4)) : grossWeightTotal;
            }
            this.calculateGrossWeightTotal();
        }

        this.$scope.calculateGrossWeightTotal = (): void => {
            this.calculateGrossWeightTotal();
        }

        this.$scope.calculateVolumeTotal = (): void => {
            this.calculateVolumeTotal();
        }

        this.$scope.calculateCargoCBW = (index: number): void => {
            if (!index && index !== 0) throw this.formService.notifyError('index is null');
            if (this.$scope.model.CARGO_LIST && this.$scope.model.CARGO_LIST.length > 0 && this.$scope.model.CARGO_LIST[index]) {
                const cbw = this.calculateCargoCBW(this.$scope.model.CARGO_LIST[index]);
                this.$scope.model.CARGO_LIST[index].CBM = cbw ? parseFloat(cbw.toFixed(3)) : cbw;
                this.calculateCBWTotal();
            }
        }

        this.$scope.addCargo = (): void => {
            this.addCargo();
        }

        this.$scope.removeCargo = (index: number): void => {
            this.removeCargo(index);
        }

        this.$scope.addVehicle = (): void => {
            this.addVehicle();
        }

        this.$scope.removeVehicle = (index: number, vehicleId: string): void => {
            this.removeVehicle(index, vehicleId);
        }

        this.$scope.addCargoPolLb = (): void => {
            this.addCargoPolLb();
        }

        this.$scope.removeCargoPolLb = (index: number): void => {
            this.removeCargoPolLb(index);
        }

        this.$scope.launchCargoPolLb = async (): Promise<void> => {
            this.launchCargoPolLb();
        }

        this.$scope.applyCargoListPolLb = (apply: boolean) => {
            this.applyCargoListPolLb(apply);
        }

        this.$scope.addDangerousCargo = (): void => {
            this.addDangerousCargo();
        }

        this.$scope.removeDangerousCargo = (index: number): void => {
            this.removeDangerousCargo(index);
        }

        this.$scope.getWeightRangeMinValue = (weightRangeList: IWeightRangeSelector[], overTaxed: number): number => {
            return this.getWeightRangeMinValue(weightRangeList, overTaxed);
        }

        this.$scope.getWeightRangeMaxValue = (weightRangeList: IWeightRangeSelector[], overTaxed: number): number => {
            return this.getWeightRangeMaxValue(weightRangeList, overTaxed);
        }

        this.$scope.getCurrencyListByName = async (search: string): Promise<void> => {
            let currencyList: ISelectorModel[] = [];
            if (search && search.length >= 2) currencyList = await this.getCurrencyListByName(search);
            this.$scope.currencyList = currencyList;
        }

        this.$scope.getChargeNameListByName = async (search: string) => {
            let chargeNameList: ISelectorModel[] = [];
            const products = [this.$newProcessScope.model.PRODUCT];
            const paramTypeCargo = [this.$scope.model.CARGO_TYPE];

            if (search && search.length >= 2) chargeNameList = await this.getChargeNameListByName({ search, types: [EChargeOriginId.INSURANCE], products: products && products.length > 0 ? products.map(product => product.ID) : null, paramTypeCargo: paramTypeCargo && paramTypeCargo.length > 0 ? paramTypeCargo.map(typeCargo => typeCargo.ID) : null });
            this.$scope.chargeNameList = chargeNameList;
        }

        this.$scope.getApplicationListByName = async (search: string): Promise<void> => {
            this.$scope.applicationList = await this.getApplicationListByName([this.$newProcessScope.model.PRODUCT], [this.$scope.model.CARGO_TYPE], search);
        }

        this.$scope.getInsuranceBrokerListByName = async (search: string) => {
            let insuranceBrokerList: ENTITY[] = [];
            if (search && search.length >= 3) insuranceBrokerList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.INSURANCE_BROKER], search: search });
            this.$scope.insuranceBrokerList = insuranceBrokerList;
        }

        this.$scope.getEquipmentListByName = async (search: string) => {
            this.getEquipmentListByName(search);
        }

        this.$scope.chargeNameChange = (optionChargeName: ISelectorModel): void => {
            this.chargeNameChange(optionChargeName);
        }

        this.$scope.specSecureChange = () => {
            this.specSecureChange();
        }

        this.$scope.updateDtaModel = () => {
            this.updateDtaModel();
        }

        this.$scope.temperatureValidation = (temperature: number) => {
            this.temperatureValidation(temperature);
        }

        this.$scope.specRegrigeratedChange = () => {
            this.specRegrigeratedChange();
        }

        this.$scope.specCargoTreatmentChange = () => {
            this.specCargoTreatmentChange();
        }

        this.$scope.isAirProduct = (): boolean => {
            return this.$newProcessScope.model.PRODUCT && (this.$newProcessScope.model.PRODUCT.ID == EProductId.AIR_IMPORT || this.$newProcessScope.model.PRODUCT.ID == EProductId.AIR_EXPORT);
        }

        this.$scope.isMaritimeProduct = (): boolean => {
            return this.$newProcessScope.model.PRODUCT && (this.$newProcessScope.model.PRODUCT.ID == EProductId.MARITIME_EXPORT);
        }

        this.$scope.getParamsDDDetList = async (direction: string, nature: string): Promise<void> => {
            await this.getParamsDDDetList(direction, nature);
        }

        this.$scope.getParamsDDDemList = async (direction: string, nature: string): Promise<void> => {
            await this.getParamsDDDemList(direction, nature);
        }

        this.$scope.getParamsDDCombinedList = async (direction: string, nature: string): Promise<void> => {
            await this.getParamsDDCombinedList(direction, nature);
        }

        this.$scope.isParamDetDemValid = (isValid: boolean, type: number): boolean => {
            if (isValid && isValid != undefined) return true;
            if (isValid == undefined && type == undefined) return true;
            if (type && type == undefined) return true;
            return false;
        }

        this.$scope.applyDetDemDetails = async (): Promise<void> => {
            await this.applyDetDemDetails('edit', true);
        }

        this.$scope.disablePanelDueToPreProcessStatus = (): boolean => {
            const situation = this.$newProcessScope && this.$newProcessScope.model && this.$newProcessScope.model.SITUATION && this.$newProcessScope.model.SITUATION.ID;
            let result = false;

            switch (situation) {
                case EProcessSituation.PRE_PROCESS:
                    break;
                default:
                    result = true;
            }
            return result;
        }

        this.$scope.openCargoReadinessHistoryModal = (model: INewProcessCargoModel) => {
            this.openCargoReadinessHistoryModal(model);
        }
    }

    async initDependencies(): Promise<any> {
        const self: NewProcessCargoController = this;

        this.initCollapseEvents();

        this.$scope.$on('processCargoSave', () => {
            this.saveProcessTabCargo();
        });

        this.$scope.$watch(() => this.$newProcessScope.model.PROCESS_NUMBER, () => {
            this.$scope.processNumber = this.$newProcessScope.model.PROCESS_NUMBER;
        });

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericList('cargo_channel'),
                self.getGenericList("package_type"),
                self.getGenericList("un_class"),
                self.getGenericList("packing_group"),
                self.getGenericList("modified_type"),
                self.getWeightRangeList(),
                self.getGenericList("air_cargo_treatment"),
                self.getGenericList("file_specs"),
                self.getGenericList("inttra_dangerous_package_type"),
            ]).then(async (result: any) => {
                self.$scope.cargoChannelList = result[0];
                self.$scope.packageTypeList = result[1];
                self.$scope.unClassList = result[2];
                self.$scope.packingGroupList = result[3];
                self.$scope.modifiedTypeList = result[4];
                self.$scope.weightRangeList = result[5];
                self.$scope.airCargoTreatment = result[6];
                self.$scope.fileSpecsResponsibleList = result[7];
                self.$scope.inttraDangerousPackageTypeList = result[8];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getWeightRangeList(): Promise<IWeightRangeSelector[]> {
        let result: IWeightRangeSelector[] = [];
        try {
            const weightRangeList = await this.productService.post({ route: `/weightRange/list/custom` });
            if (weightRangeList && weightRangeList.data && weightRangeList.data.data) result = weightRangeList.data.data.data.map(x => { return { ID: x.ID, NAME: x.NAME, MIN_WEIGHT: x.MIN_WEIGHT, MAX_WEIGHT: x.MAX_WEIGHT } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return result;
        }
    }

    private async getEquipmentListByName(search: string): Promise<void> {
        try {
            if (search && search.length >= 3) {
                this.formService.block();

                const equipmentSelector: IEquipmentSelector[] = [];
                const request: IMonacoRequest = {
                    data: {
                        search, products: [this.$newProcessScope.model.PRODUCT.ID]
                    },
                    route: `/equipment/list/custom`,
                    timeout: 30000,
                };

                const rc = await this.productService.post(request, false);
                const equipmentList = (rc && rc.data && rc.data.data) ? rc.data.data : null;

                for (const item of equipmentList) {
                    const processEquipmentSelector: IEquipmentSelector = {
                        ID: item.ID,
                        NAME: item.NAME,
                        CODE: item.CODE,
                        TEU: item.TEU,
                        FEET: item.FEET
                    }

                    equipmentSelector.push(processEquipmentSelector);
                }

                this.$scope.equipmentList = equipmentSelector;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getGenericList(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private initCollapseEvents() {
        this.$scope.$on('processCargoCollapse', () => {
            this.collapseCargo();
        });

        const collapseCargo = angular.element('#collapseCargo');
        if (collapseCargo) {
            collapseCargo.on('shown.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseCargoHeading").attr('aria-expanded', 'true');
                    // update collapse state
                    this.$newProcessScope.collapseState = { panel: ECollapseState.CARGO, released: false, nextState: null };
                    this.$newProcessScope.repositionPanels(ECollapseState.CARGO, true);
                    this.$newProcessScope.disableElements(this.$newProcessScope.operation == EOperation.VIEW);

                    this.$timeout(async () => {
                        await this.getProcessTabsCargo();
                        angular.element("#cargoListCards").hide();
                        const collapseCargoList = angular.element("#collapseCargoList");
                        collapseCargoList.on('shown.bs.collapse', (event: JQuery.Event) => {
                            if (event.target == event.currentTarget) {
                                angular.element("#cargoListCards").hide();
                            }
                        });
                        collapseCargoList.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                            if (event.target == event.currentTarget) {
                                angular.element("#cargoListCards").show();
                            }
                        });

                        const collapseSpec = angular.element("#collapseSpec");
                        collapseSpec.on('shown.bs.collapse', (event: JQuery.Event) => {
                            if (event.target == event.currentTarget) {
                                angular.element("#specCards").hide();
                            }
                        });
                        collapseSpec.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                            if (event.target == event.currentTarget) {
                                angular.element("#specCards").show();
                            }
                        });
                    });
                }
            });
            collapseCargo.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseCargoHeading").attr('aria-expanded', 'false');
                    this.$scope.model = null;
                    this.$scope.oldModel = null;
                }
            });
        }
    }

    private buildDetDemValuesView(values: ICargoSummary) {
        let detDem = "";
        if (values) {
            if (values.DET || values.DEM) {
                if (values.DET) detDem += values.DET.toString();
                if (values.DEM) detDem += " + " + values.DEM.toString();
            } else if (values.COMBINED) {
                detDem += values.COMBINED.toString();
            }
        }
        return detDem;
    }

    private async openDetDemDetails(): Promise<void> {
        try {

            const response = await this.dataProcessService.get(`/processCargo/cargo/${this.$newProcessScope.model.ID}`, null, 30000);
            const processCargo = response.data && response.data.data ? response.data.data : null;
            if (processCargo && !processCargo.CARGO_DETAIL.DET_DEM_DETAILS) return this.formService.notifyError("detDemDetails is null.");

            this.$scope.cargoModel = processCargo;
            this.$scope.oldCargoModel = angular.copy(this.$scope.cargoModel);

            this.detDemModalId = this.modalService.newModal();
            const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                {
                    modalID: this.detDemModalId,
                    scope: this.$scope,
                    formService: this.$newProcessScope.operation,
                    size: 'lg modal-overflow',
                    template: require("../view/modal/processDetDemModal.html"),
                    keyboard: true,
                },
                null
            );

            const endpoint = `${this.config.productUrl}/product/tariffDetDem/getCacheById`;
            await modalInstance.rendered.then(() => {
                angular.element(".openTariffDetDemOriginPayment").bind('click', () => {
                    const tariffId = this.$scope.cargoModel.CARGO_DETAIL.DET_DEM_DETAILS.DET_DEM_ORIGIN.ID_PAYMENT ? this.$scope.cargoModel.CARGO_DETAIL.DET_DEM_DETAILS.DET_DEM_ORIGIN.ID_PAYMENT.toString() : null;
                    this.sessionService.openTabByValidity(`${endpoint}/${tariffId}`, "app.product.tariffDetDem", <ILinkParameter>{ ID: tariffId });
                });

                angular.element(".openTariffDetDemOriginReceiving").bind('click', () => {
                    const tariffId = this.$scope.cargoModel.CARGO_DETAIL.DET_DEM_DETAILS.DET_DEM_ORIGIN.ID_RECEIVING ? this.$scope.cargoModel.CARGO_DETAIL.DET_DEM_DETAILS.DET_DEM_ORIGIN.ID_RECEIVING.toString() : null;
                    this.sessionService.openTabByValidity(`${endpoint}/${tariffId}`, "app.product.tariffDetDem", <ILinkParameter>{ ID: tariffId });
                });

                angular.element(".openTariffDetDemDestinationPayment").bind('click', () => {
                    const tariffId = this.$scope.cargoModel.CARGO_DETAIL.DET_DEM_DETAILS.DET_DEM_DESTINATION ? this.$scope.cargoModel.CARGO_DETAIL.DET_DEM_DETAILS.DET_DEM_DESTINATION.ID_PAYMENT.toString() : null;
                    this.sessionService.openTabByValidity(`${endpoint}/${tariffId}`, "app.product.tariffDetDem", <ILinkParameter>{ ID: tariffId });
                });

                angular.element(".openTariffDetDemDestinationReceiving").bind('click', () => {
                    const tariffId = this.$scope.cargoModel.CARGO_DETAIL.DET_DEM_DETAILS.DET_DEM_DESTINATION ? this.$scope.cargoModel.CARGO_DETAIL.DET_DEM_DETAILS.DET_DEM_DESTINATION.ID_RECEIVING.toString() : null;
                    this.sessionService.openTabByValidity(`${endpoint}/${tariffId}`, "app.product.tariffDetDem", <ILinkParameter>{ ID: tariffId });
                });
            });

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private updateCargoCalculateFieldsDisabledControl(amount: number) {
        this.$scope.isCargoCalculatedFieldsDisabled = amount && amount > 0;
        this.updateWeightRangeBase();
    }

    private updateWeightRangeBase(): void {
        const self: NewProcessCargoController = this;
        if (this.$scope.model.CARGO_DETAIL && this.$scope.model.CARGO_DETAIL.WEIGHT_RANGE_LIST) {
            const weightRangeSelected: IWeightRangeSelector[] = this.$scope.weightRangeList && this.$scope.weightRangeList.length ? this.$scope.weightRangeList.filter(weightRange => this.$scope.model.CARGO_DETAIL.WEIGHT_RANGE_LIST.some(weightRangeToVerify => weightRangeToVerify.ID == weightRange.ID)) : null;

            const quantity = self.$scope.model.CARGO_DETAIL.QUANTITY ? self.$scope.model.CARGO_DETAIL.QUANTITY : 0;
            const overTaxed = self.$scope.model.CARGO_DETAIL.OVERTAXED ? self.$scope.model.CARGO_DETAIL.OVERTAXED : 0;
            const weightMin = quantity >= overTaxed ? quantity : overTaxed;

            let lowestRange = weightRangeSelected && weightRangeSelected.length ? weightRangeSelected.reduce(function (prev, current) {
                const isBetweenChargeableWeight = self.betweenNumber(weightMin, prev.MIN_WEIGHT, prev.MAX_WEIGHT);
                return (prev.MIN_WEIGHT < current.MIN_WEIGHT && isBetweenChargeableWeight) ? prev : current;
            }) : null;

            this.$scope.model.CARGO_DETAIL.WEIGHT_RANGE_BASE = lowestRange;
            this.$timeout(() => this.$newProcessScope.selectorValidity("cargoAirWeightRangeBase"));
        }
    }

    private grossWeightChange() {
        if (this.$scope.model.CARGO_DETAIL.GROSS_WEIGHT == 0) this.$scope.model.CARGO_DETAIL.GROSS_WEIGHT = null;
        if (this.$scope.model.CARGO_TYPE) {
            if (this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.AIR) this.calculateOperationChargeable();
            else if (this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.BREAK_BULK || this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.LCL || this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.RO_RO) this.calculateOperationWM();
        }
    }

    private cubicWeightChange() {
        if (this.$scope.model.CARGO_DETAIL.CBM == 0) this.$scope.model.CARGO_DETAIL.CBM = null;
        if (this.$scope.model.CARGO_TYPE) {
            if (this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.AIR) this.calculateOperationChargeable();
            else if (this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.BREAK_BULK || this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.LCL || this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.RO_RO) this.calculateOperationWM();
        }
    }

    private calculateCargoPolLbCBW(index: number) {
        if (!index && index !== 0) throw this.formService.notifyError('index is null');
        const cargoListModel = this.$scope.cargoListPolLb ? this.$scope.cargoListPolLb : null;
        if (cargoListModel && cargoListModel.length > 0 && cargoListModel[index]) {
            const cbw = this.calculateCargoCBW(cargoListModel[index]);
            this.$scope.cargoListPolLb[index].CBM = cbw ? parseFloat(cbw.toFixed(3)) : cbw;
        }
    }

    private calculateCargoCBW(cargoModel: CARGO_LIST) {
        if (!cargoModel) this.formService.notifyError('cargoModel is null');
        let cbm = null;
        try {
            const vol = cargoModel.VOLUME != null ? cargoModel.VOLUME : 0;
            const length = cargoModel.CCM != null ? cargoModel.CCM : 0;
            const width = cargoModel.LCM != null ? cargoModel.LCM : 0;
            const height = cargoModel.ACM != null ? cargoModel.ACM : 0;
            cbm = (vol * length * width * height) / 1000000;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return cbm;
        }
    }

    private calculateCargoPolLbGrossWeightTotal(index: number) {
        if (!index && index !== 0) throw this.formService.notifyError('index is null');
        const cargoListModel = this.$scope.cargoListPolLb ? this.$scope.cargoListPolLb : null;
        if (cargoListModel && cargoListModel.length > 0 && cargoListModel[index] && cargoListModel[index].WEIGHT > 0) {
            const grossWeightTotal = this.calculateCargoGrossWeightTotal(cargoListModel[index]);
            this.$scope.cargoListPolLb[index].TOTAL_WEIGHT = grossWeightTotal ? parseFloat(grossWeightTotal.toFixed(4)) : grossWeightTotal;
        }
    }

    private calculateCargoGrossWeightTotal(cargoModel: CARGO_LIST): number {
        if (!cargoModel) this.formService.notifyError('cargoModel is null');
        let pbTotal = null;
        try {
            const vol = cargoModel.VOLUME != null ? cargoModel.VOLUME : 0;
            const pbUn = cargoModel.WEIGHT != null ? cargoModel.WEIGHT : 0;
            pbTotal = vol * pbUn;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return pbTotal;
        }
    }

    private betweenNumber = function (numberToVerify: number, initial: number, final: number): boolean {
        try {
            if (numberToVerify == null) throw Error("numberToVerify is null.");
            if (initial == null) throw Error("initial is null.");
            if (final == null) throw Error("final is null.");
            const min = Math.min.apply(Math, [initial, final]);
            const max = Math.max.apply(Math, [initial, final]);
            return numberToVerify >= min && numberToVerify <= max;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private addCargo(): void {
        try {
            if (!this.$scope.model.CARGO_LIST) this.$scope.model.CARGO_LIST = [];
            const cargo: CARGO_LIST = {
                PACKAGE: null,
                VOLUME: null,
                CCM: null,
                LCM: null,
                ACM: null,
                CBM: null,
                WEIGHT: null,
                TOTAL_WEIGHT: null
            }
            this.$scope.model.CARGO_LIST.push(cargo);
            this.updateCargoCalculatedFields();
            const lastIndex = this.$scope.model.CARGO_LIST.length - 1;
            this.$timeout(() => {
                this.$newProcessScope.selectorValidity("cargoPackage" + lastIndex);
                this.$newProcessScope.setDirtyFields(['cargoVolume' + lastIndex, 'cargoLength' + lastIndex, 'cargoWidth' + lastIndex, 'cargoHeight' + lastIndex, 'cargoCBW' + lastIndex, 'cargoGrossWeight' + lastIndex]);
            }, 100);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeCargo(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.$scope.model.CARGO_LIST && this.$scope.model.CARGO_LIST.length > 0) {
                this.formService.block();
                if (this.$scope.model.CARGO_LIST.length == 1) this.$scope.model.CARGO_LIST = null;
                else this.$scope.model.CARGO_LIST.splice(index, 1);
                this.calculateVolumeTotal();
                // this.calculateGrossWeightTotal();
                this.calculateCBWTotal();
                this.updateCargoCalculatedFields();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private addVehicle(): void {
        try {
            if (!this.$scope.vehicleListModel) this.$scope.vehicleListModel = [];
            const vehicle: IVehicleTypeList = {
                ID: uuid.v4(),
                ID_PROCESS: this.$newProcessScope.model.ID,
                TRUCK_LICENSE_PLATE: null,
                TRAILER_LICENSE_PLATE: null,
                DRIVER_NAME: null,
                DRIVER_ID: null,
                NCM: null,
                NALADI: null,
                UNITY_QUANTITY: null,
                PACKAGE_QUANTITY: null,
                GROUP_QUANTITY: null,
                PACKAGE_GROUP: null,
                GROSS_WEIGHT: null,
                NET_WEIGHT: null,
                CUBIC_METER: null,
                REFERENCE: null,
            };

            this.$scope.vehicleListModel.push(vehicle);

            const lastIndex = this.$scope.vehicleListModel.length - 1;
            this.$timeout(() => {
                this.$newProcessScope.setDirtyFields(['truckLicensePlate' + lastIndex, 'tractorUnitPlate' + lastIndex, 'driverName' + lastIndex]);
            }, 100);

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeVehicle(index: number, vehicleId: string): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');

            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            const eventModel = this.$newProcessScope && this.$newProcessScope.model && this.$newProcessScope.model.EVENT ? this.$newProcessScope.model.EVENT : null;
            const vehicleToRemove = vehicleId ? vehicleId : null;

            if (eventModel && vehicleToRemove) {
                for (const event of eventModel) {
                    const vehicleInEvent = event.VEHICLE_TYPE_DATE ? event.VEHICLE_TYPE_DATE.find(v => v.PROCESS_VEHICLE_TYPE_ID === vehicleToRemove) : null;
                    if (vehicleInEvent && vehicleInEvent.EFFECTIVE_DATE != null) {
                        this.formService.block();
                        const msg = this.formService.getTranslate('BASIC_DATA.VEHICLE_HAS_EFFECTIVE_DATE');
                        return this.formService.notifyError(msg);
                    }
                }
            }

            if (this.$scope.vehicleListModel && this.$scope.vehicleListModel.length > 0) {
                this.formService.block();
                if (this.$scope.vehicleListModel.length == 1) {
                    this.$scope.vehicleListModel = null;
                }
                else {
                    this.$scope.vehicleListModel.splice(index, 1);
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private calculateVolumeTotal(): void {
        let volTotal = 0;
        try {
            if (this.$scope.model.CARGO_LIST && this.$scope.model.CARGO_LIST.length > 0) for (const cargo of this.$scope.model.CARGO_LIST) { volTotal += cargo.VOLUME != null ? cargo.VOLUME : 0; }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.model.CARGO_DETAIL.VOLUME = volTotal;
        }
    }

    private async calculateGrossWeightTotal(): Promise<void> {
        if (!this.$scope.isCargoTotalGrossWeightDisabled()) return;
        let grossWeightTotal = 0;
        try {
            if (this.$scope.model.CARGO_LIST && this.$scope.model.CARGO_LIST.length > 0) for (const cargo of this.$scope.model.CARGO_LIST) { grossWeightTotal += cargo.TOTAL_WEIGHT != null ? cargo.TOTAL_WEIGHT : 0; }
        } catch (ex) {
            grossWeightTotal = null;
            this.formService.handleError(ex);
        } finally {
            if (this.$scope.model.CARGO_DETAIL) {
                this.$scope.model.CARGO_DETAIL.GROSS_WEIGHT = grossWeightTotal ? parseFloat(grossWeightTotal.toFixed(3)) : grossWeightTotal;
                this.$scope.model.CARGO_DETAIL.QUANTITY = this.$scope.model.CARGO_DETAIL.GROSS_WEIGHT && this.$scope.model.CARGO_DETAIL.GROSS_WEIGHT > grossWeightTotal ? Math.ceil((this.$scope.model.CARGO_DETAIL.GROSS_WEIGHT / 0.5)) * 0.5 : grossWeightTotal;
                if (this.$scope.model.CARGO_TYPE && (this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.BREAK_BULK || this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.LCL || this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.RO_RO)) await this.calculateOperationWM();
                this.updateWeightRangeBase();
            }
        }
    }

    private async calculateCBWTotal(): Promise<void> {
        let cbwTotal = 0;
        try {
            if (this.$scope.model.CARGO_LIST && this.$scope.model.CARGO_LIST.length > 0) for (const cargo of this.$scope.model.CARGO_LIST) { cbwTotal += cargo.CBM != null ? cargo.CBM : 0; }
        } catch (ex) {
            cbwTotal = null;
            this.formService.handleError(ex);
        } finally {
            this.$scope.model.CARGO_DETAIL.CBM = cbwTotal;
            if (this.$scope.model.CARGO_TYPE && this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.AIR) await this.calculateOperationChargeable();
            else if (this.$scope.model.CARGO_TYPE && (this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.BREAK_BULK || this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.LCL || this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.RO_RO)) await this.calculateOperationWM();
        }
    }

    private addCargoPolLb(): void {
        try {
            if (!this.$scope.cargoListPolLb) this.$scope.cargoListPolLb = [];
            const cargo: CARGO_LIST = {
                PACKAGE: null,
                VOLUME: null,
                CCM: null,
                LCM: null,
                ACM: null,
                CBM: null,
                WEIGHT: null,
                TOTAL_WEIGHT: null
            }
            this.$scope.cargoListPolLb.push(cargo);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeCargoPolLb(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CLOSE',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.$scope.cargoListPolLb && this.$scope.cargoListPolLb.length > 0) {
                this.formService.block();
                if (this.$scope.cargoListPolLb.length == 1) this.$scope.cargoListPolLb = null;
                else this.$scope.cargoListPolLb.splice(index, 1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async launchCargoPolLb(): Promise<void> {
        try {

            if (!this.modalCargoPolLbId) this.modalCargoPolLbId = this.modalService.newModal();

            this.$scope.cargoListPolLb = [{ PACKAGE: null, VOLUME: null, CCM: null, LCM: null, ACM: null, CBM: null, WEIGHT: null, TOTAL_WEIGHT: null }];
            await this.modalService.showModalInfo(
                {
                    modalID: this.modalCargoPolLbId,
                    scope: this.$scope,
                    formService: 'register',
                    template: require("../view/newProcessCargoPollbModal.html"),
                    size: 'full modal-overflow'
                },
                {
                    closeButtonText: 'GENERAL.CANCEL',
                    actionButtonText: 'REGISTRATION.APPLY',
                    headerText: this.formService.getTranslate('GENERAL.CARGO_DETAILS')
                }
            );
            this.$scope.cargoListPolLb = null;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async applyCargoListPolLb(apply: boolean): Promise<void> {
        try {
            if (apply) {
                const isInvalid = this.$newProcessScope.hasInvalidRequiredElements('cargoPolLbModalForm');
                if (isInvalid) return;
                if (this.$scope.cargoListPolLb && this.$scope.cargoListPolLb.length > 0) {
                    const filter: IOfferRequestCargoListConversion = { VOLUMES_LIST: this.convertToVolumeList(this.$scope.cargoListPolLb) };
                    const cargoListConverted = await this.productService.post({ route: "/offerWizard/addCargoListPolLib", data: filter });
                    if (!cargoListConverted || cargoListConverted && cargoListConverted.data && cargoListConverted.data.data && cargoListConverted.data.data.length == 0) {
                        const msg = this.formService.getTranslate('BASIC_DATA.THE_VALUES_COULD_NOT_BE_CONVERT');
                        return this.formService.notifyError(msg);
                    } else {
                        this.$scope.model.CARGO_LIST = this.$scope.model.CARGO_LIST ? this.$scope.model.CARGO_LIST.concat(this.convertToCargoList(cargoListConverted.data.data)) : this.convertToCargoList(cargoListConverted.data.data);
                        if (this.$scope.model.CARGO_LIST && this.$scope.model.CARGO_LIST.length > 0) {
                            angular.element('#collapseCargoList')["collapse"]("show");
                            this.$timeout(() => {
                                this.formService.loadRegisterForm(false);
                                this.calculateVolumeTotal();
                                this.calculateGrossWeightTotal();
                                this.calculateCBWTotal();
                            });
                        }
                    }
                    this.updateCargoCalculatedFields();
                    this.$scope.cargoListGrossWeightFieldsControl(this.$scope.model.CARGO_DETAIL.GROSS_WEIGHT);
                }
            }
            this.modalService.closeModal(this.modalCargoPolLbId, apply);
            this.modalCargoPolLbId = 0;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private addDangerousCargo(): void {
        try {
            if (!this.$scope.model.CARGO_DETAIL.DANGER_CARGO) this.$scope.model.CARGO_DETAIL.DANGER_CARGO = [];
            const dangerousCargo: DANGER_CARGO = {
                UNITY: null,
                CLASS: null,
                PACKING_GROUP: null,
                OUTER_PACKAGE_COUNT: null,
                OUTER_PACKAGE_TYPE: null,
                PROPER_SHIPPING: null
            }
            this.$scope.model.CARGO_DETAIL.DANGER_CARGO.push(dangerousCargo);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeDangerousCargo(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CLOSE',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.$scope.model.CARGO_DETAIL.DANGER_CARGO && this.$scope.model.CARGO_DETAIL.DANGER_CARGO.length > 0) {
                this.formService.block();
                if (this.$scope.model.CARGO_DETAIL.DANGER_CARGO.length == 1) this.$scope.model.CARGO_DETAIL.DANGER_CARGO = null;
                else this.$scope.model.CARGO_DETAIL.DANGER_CARGO.splice(index, 1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private getWeightRangeMinValue(weightRangeList: IWeightRangeSelector[], overTaxed: number): number {
        let minValue = null;

        switch (this.$newProcessScope.model.SITUATION.ID) {
            case EProcessSituation.PRE_PROCESS:
                break;
            default:
                if (!overTaxed && weightRangeList && weightRangeList.length > 0) {
                    for (const weightRange of weightRangeList) {
                        const fullWeightRange = this.$scope.weightRangeList.find(obj => obj.ID == weightRange.ID);
                        if (fullWeightRange && (minValue == null || (minValue != null && minValue > fullWeightRange.MIN_WEIGHT))) minValue = fullWeightRange.MIN_WEIGHT;
                    }
                }
        }

        if (minValue == null) minValue = 0.0001;
        return minValue;
    }

    private getWeightRangeMaxValue(weightRangeList: IWeightRangeSelector[], overTaxed: number): number {
        let maxValue = null;
        switch (this.$newProcessScope.model.SITUATION.ID) {
            case EProcessSituation.PRE_PROCESS:
                break;
            default:
                if (overTaxed) {
                    maxValue = overTaxed;
                } else if (!overTaxed && this.$newProcessScope.disablePanelDueToPreProcessStatus() && weightRangeList && weightRangeList.length > 0) {
                    for (const weightRange of weightRangeList) {
                        const fullWeightRange = this.$scope.weightRangeList.find(obj => obj.ID == weightRange.ID);
                        if (fullWeightRange && (!maxValue || (maxValue && maxValue < fullWeightRange.MAX_WEIGHT))) maxValue = fullWeightRange.MAX_WEIGHT;
                    }
                }
        }
        return maxValue;
    }

    private async getCurrencyListByName(search?: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            const currencyOperation = await this.productService.post({ route: "/currency/list/custom", data: { name: search, sysconvertIdToString: true } });
            if (currencyOperation && currencyOperation.data && currencyOperation.data.data) result = currencyOperation.data.data.map(currency => { return { ID: currency.ID, NAME: currency.NAME, CODE: currency.INITIALS } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getChargeNameListByName(filter: IChargeNameListCustomFilter): Promise<ISelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const chargesOperation = await this.productService.post({ route: "/chargeName/list/custom/exhibition", data: filter });
            if (chargesOperation && chargesOperation.data && chargesOperation.data.data && chargesOperation.data.data.data) result = chargesOperation.data.data.data.map(charge => { return { ID: charge.ID, NAME: charge.NAME, CODE: charge.CODE } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async chargeNameChange(optionChargeName: ISelectorModel): Promise<void> {
        if (optionChargeName) await this.getApplicationListByChargeNameId(optionChargeName);
    }

    private async getApplicationListByChargeNameId(optionChargeName: ISelectorModel): Promise<void> {
        try {
            this.formService.block();
            if (!optionChargeName) return this.formService.notifyError("optionChargeName is null");
            if (optionChargeName) {
                const applicationOperation = await this.productService.get<any>({ route: `/application/list/custom/charge_name/${optionChargeName.ID}`, data: { sysconvertIdToString: true } });
                const application = applicationOperation && applicationOperation.data && applicationOperation.data.data ? applicationOperation.data.data : null;
                if (application && application.CHARGE_NAME.PARAMS) {
                    const paymentApplicationList = application.CHARGE_NAME.PARAMS.map(x => {
                        return {
                            ID: x.PAYMENT.ID,
                            NAME: x.PAYMENT.NAME,
                            CODE: x.PAYMENT.CODE,
                            INTERNAL_SEQUENCE: x.PAYMENT.INTERNAL_SEQUENCE
                        }
                    });
                    const receivingApplicationList = application.CHARGE_NAME.PARAMS.map(x => {
                        return {
                            ID: x.RECEIVING.ID,
                            NAME: x.RECEIVING.NAME,
                            CODE: x.RECEIVING.CODE,
                            INTERNAL_SEQUENCE: x.RECEIVING.INTERNAL_SEQUENCE
                        }
                    });
                    if (paymentApplicationList && paymentApplicationList.length > 0) this.$scope.model.CARGO_DETAIL.INSURANCE.APPLICATION_PAYMENT = paymentApplicationList[0];
                    if (receivingApplicationList && receivingApplicationList.length > 0) this.$scope.model.CARGO_DETAIL.INSURANCE.APPLICATION_RECEIVING = receivingApplicationList[0];
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getApplicationListByName(product: ISelectorModel[], typeCargo: ISelectorModel[], search: string): Promise<IApplicationList[]> {
        let result: IApplicationList[] = [];
        let products = [];
        let typeCargos = [];
        try {
            if (search && search.length >= 2) {
                if (!product) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                if (!typeCargo) throw Error('Select type cargo first!');
                this.formService.block();
                products = product.map(item => item.ID);
                typeCargos = typeCargo.map(item => item.ID);
                const application = await this.productService.post({ route: "/application/list/custom", data: { search, products, typeCargos, sysconvertIdToString: true } });
                if (application && application.data && application.data.data) {
                    result = application.data.data.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PERCENT: x.PERCENT, APPLICATION_COMPLEMENT: x.APPLICATION_COMPLEMENT, INTERNAL_SEQUENCE: x.INTERNAL_SEQUENCE, FREE_TYPING_AMOUNT_CHARGE: x.FREE_TYPING_AMOUNT_CHARGE, CT_WITHOUT_DOC: x.CT_WITHOUT_DOC } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getLegalPersonListByName(filter: ILegalPersonListCustomFilter): Promise<ENTITY[]> {
        let result: ENTITY[] = [];
        try {
            this.formService.block();
            const response = await this.productService.post({ route: "/legalPerson/list/custom/operational", data: filter });
            if (response && response.data && response.data.data) result = response.data.data.map(legalPerson => {
                return {
                    ID: legalPerson.ID, NAME: legalPerson.NAME, CODE: legalPerson.CODE, ID_LEGAL_PERSON: legalPerson.ID,
                    TRADING_NAME: legalPerson.TRADING_NAME, TAXPAYER_NUMBER: legalPerson.TAXPAYER_NUMBER, SCAC: legalPerson.SCAC, NUMBER_IATA: legalPerson.NUMBER_IATA, ADDRESS: legalPerson.ADDRESS
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private convertToVolumeList(cargoList: CARGO_LIST[]): ICargo[] {
        const convertedList: ICargo[] = [];
        for (const cargo of cargoList) {
            convertedList.push({ UUID: null, PACKAGE: cargo.PACKAGE, VOLUME: cargo.VOLUME, LENGTH: cargo.CCM, WIDTH: cargo.LCM, HEIGHT: cargo.ACM, CUBIC_WEIGHT: cargo.CBM, GROSS_WEIGHT_UNITARY: cargo.WEIGHT, GROSS_WEIGHT: cargo.TOTAL_WEIGHT });
        }
        return convertedList;
    }

    private convertToCargoList(cargoList: ICargo[]): CARGO_LIST[] {
        const convertedList: CARGO_LIST[] = [];
        for (const cargo of cargoList) {
            convertedList.push({ PACKAGE: cargo.PACKAGE, VOLUME: cargo.VOLUME, CCM: cargo.LENGTH, LCM: cargo.LENGTH, ACM: cargo.HEIGHT, CBM: cargo.CUBIC_WEIGHT, WEIGHT: cargo.GROSS_WEIGHT_UNITARY, TOTAL_WEIGHT: cargo.GROSS_WEIGHT });
        }
        return convertedList;
    }

    private async updateCargoCalculatedFields() {
        if (this.$scope.model.CARGO_LIST && this.$scope.model.CARGO_LIST.length > 0) {
            this.$scope.isCargoCalculatedFieldsDisabled = true;
            if (this.$scope.model.CARGO_DETAIL) {
                this.$scope.model.CARGO_DETAIL.QUANTITY = null;
                this.$scope.model.CARGO_DETAIL.VOLUME = null;
                this.$scope.model.CARGO_DETAIL.CBM = null;
                this.calculateVolumeTotal();
                await this.calculateGrossWeightTotal();
                await this.calculateCBWTotal();
            }
        } else this.$scope.isCargoCalculatedFieldsDisabled = false;
    }

    private async calculateOperationChargeable(): Promise<void> {
        let result: number = 1;
        try {
            this.formService.block();
            if (this.$scope.model.CARGO_DETAIL.CBM) {
                const filter: IOfferRequestOperationChargeable = { CUBIC_WEIGHT: this.$scope.model.CARGO_DETAIL.CBM };
                const resultOperation = await this.productService.post({ route: "/calculationOperations/chargeable", data: filter });
                if (resultOperation && resultOperation.data && resultOperation.data.data && typeof resultOperation.data.data == 'number') result = resultOperation.data.data;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.model.CARGO_DETAIL.QUANTITY = this.$scope.model.CARGO_DETAIL.GROSS_WEIGHT && this.$scope.model.CARGO_DETAIL.GROSS_WEIGHT > result ? Math.ceil((this.$scope.model.CARGO_DETAIL.GROSS_WEIGHT / 0.5)) * 0.5 : result;
            this.updateWeightRangeBase();
            this.formService.unblock();
        }
    }

    private async calculateOperationWM(): Promise<void> {
        let result: number = 0;
        try {
            this.formService.block();
            if (this.$scope.model.CARGO_DETAIL.GROSS_WEIGHT && this.$scope.model.CARGO_DETAIL.CBM) {
                const filter: IOfferRequestOperationWM = { GROSS_WEIGHT: this.$scope.model.CARGO_DETAIL.GROSS_WEIGHT, CUBIC_WEIGHT: this.$scope.model.CARGO_DETAIL.CBM };
                const resultOperation = await this.productService.post({ route: "/calculationOperations/wm", data: filter });
                if (resultOperation && resultOperation.data && resultOperation.data.data && typeof resultOperation.data.data == 'number') result = resultOperation.data.data;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.model.CARGO_DETAIL.QUANTITY = result && result > 1 ? result : 1;
            this.formService.unblock();
        }
    }

    private async collapseCargo() {
        try {
            if (this.$newProcessScope.collapseState.released || this.$newProcessScope.collapseState.panel == ECollapseState.CARGO) {
                const collapseCargo = angular.element("#collapseCargo");
                if (collapseCargo) {
                    const isCollapsed = angular.element("#collapseCargoHeading").attr("aria-expanded") == "true";
                    if (isCollapsed) {
                        if (this.$newProcessScope.hasChanges(this.$scope.model, this.$scope.oldModel)) {
                            const close = this.formService.getTranslate('GENERAL.CLOSE');
                            const cargo = this.formService.getTranslate('GENERAL.CARGO');
                            const confirm = await this.$newProcessScope.modalSaveConfirmation(cargo, close);
                            if (confirm && !await this.saveProcessTabCargo()) return;
                        }
                        this.$newProcessScope.collapseState.released = true;
                    }
                    collapseCargo['collapse']('toggle');
                    if (isCollapsed) this.$newProcessScope.repositionPanels(ECollapseState.CARGO);
                }
            } else {
                this.$newProcessScope.collapseState.nextState = ECollapseState.CARGO;
                this.$newProcessScope.releaseCollapse();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async specSecureChange() {
        if (this.$scope.model.CARGO_DETAIL && this.$scope.model.CARGO_DETAIL.IS_INSURANCE) {
            if (!this.$scope.oldSecure) {
                const offerProduct = this.$newProcessScope.model.PRODUCT ? this.$newProcessScope.model.PRODUCT.ID : null;
                // Note: the product configuration registration will always have at most one registration.
                try {
                    this.formService.block();
                    const productConfigurationSecureArray: IProductConfigurationSecure[] = await this.getProductConfigurationList([offerProduct]);
                    const productConfigurationSecure: IProductConfigurationSecure = productConfigurationSecureArray[0];
                    const secureObject: IInsurance = {
                        CURRENCY: null,
                        INSURED_AMOUNT: null,
                        INSURANCE_PREMIUM: null,
                        INSURANCE_RECEIVING_MIN: null,
                        COMISSION_WITHOUT_PRIZE: null,
                        INSURANCE_PAYMENT_MIN: null,
                        INSURANCE_BROKER: null,
                        CHARGE_NAME_EXHIBITION: null,
                        APPLICATION_PAYMENT: null,
                        APPLICATION_RECEIVING: null
                    };
                    if (productConfigurationSecure) {
                        secureObject.INSURANCE_PREMIUM = productConfigurationSecure.INSURANCE_PREMIUM;
                        secureObject.INSURANCE_RECEIVING_MIN = productConfigurationSecure.INSURANCE_RECEIVING_MIN;
                        secureObject.COMISSION_WITHOUT_PRIZE = productConfigurationSecure.STANDARD_COMISSION_WITHOUT_PRIZE;
                        secureObject.INSURANCE_PAYMENT_MIN = productConfigurationSecure.INSURANCE_PAYMENT_MIN;
                        secureObject.INSURANCE_BROKER = productConfigurationSecure.INSURANCE_BROKER;
                        secureObject.CHARGE_NAME_EXHIBITION = productConfigurationSecure.CHARGE_NAME_EXHIBITION;
                        secureObject.APPLICATION_PAYMENT = productConfigurationSecure.APPLICATION_PAYMENT;
                        secureObject.APPLICATION_RECEIVING = productConfigurationSecure.APPLICATION_RECEIVING;
                        if (secureObject.INSURANCE_BROKER && secureObject.INSURANCE_BROKER.ID_LEGAL_PERSON) secureObject.INSURANCE_BROKER.ID_LEGAL_PERSON = secureObject.INSURANCE_BROKER.ID_LEGAL_PERSON.toString();
                    }

                    this.$scope.model.CARGO_DETAIL.INSURANCE = secureObject;
                } catch (ex) {
                    this.formService.handleError(ex);
                } finally {
                    this.formService.unblock();
                }

            } else this.$scope.model.CARGO_DETAIL.INSURANCE = angular.copy(this.$scope.oldSecure);
        } else this.$scope.model.CARGO_DETAIL.INSURANCE = null;
        this.$timeout(() => {
            this.$newProcessScope.selectorValidity("specSecureCurrency");
            this.$newProcessScope.selectorValidity("specSecureChargeName");
            this.$newProcessScope.selectorValidity("specSecureApplicationPayment");
            this.$newProcessScope.selectorValidity("secureApplicationReceiving");
            this.$newProcessScope.selectorValidity("specSecureBroker");
        });
    }

    private updateDtaModel() {
        if (this.$scope.model.DTA) {
            this.$scope.model.DTA_INFORMATION = {
                REGISTER_DATE: (this.$scope.model.DTA_INFORMATION && this.$scope.model.DTA_INFORMATION.REGISTER_DATE ? this.$scope.model.DTA_INFORMATION.REGISTER_DATE : null),
                LIBERATION_DATE: (this.$scope.model.DTA_INFORMATION && this.$scope.model.DTA_INFORMATION.LIBERATION_DATE ? this.$scope.model.DTA_INFORMATION.LIBERATION_DATE : null),
                DESCRIPTION: (this.$scope.model.DTA_INFORMATION && this.$scope.model.DTA_INFORMATION.DESCRIPTION ? this.$scope.model.DTA_INFORMATION.DESCRIPTION : null),
                DTA_CHANNEL: (this.$scope.model.DTA_INFORMATION && this.$scope.model.DTA_INFORMATION.DTA_CHANNEL ? this.$scope.model.DTA_INFORMATION.DTA_CHANNEL : null)
            }
        } else {
            this.$scope.model.DTA_INFORMATION = null;
        }
    }

    private async getProductConfigurationList(id: string[]): Promise<IProductConfigurationSecure[]> {
        let productConfiguration: IProductConfigurationSecure[] = [];
        const productConfigurationResponse = await this.productService.post({ route: `/productConfiguration/secure/list`, data: { id: id }, timeout: 30000 });
        if (productConfigurationResponse && productConfigurationResponse.data && productConfigurationResponse.data.data) productConfiguration = productConfigurationResponse.data.data.data;
        return productConfiguration;
    }

    private async getProcessTabsCargo(): Promise<void> {
        this.formService.block();
        try {
            this.$scope.vehicleListModel = [];
            const cargoTab = await this.operationalService.get(`/process/panel/cargo/${this.$newProcessScope.model.PROCESS_NUMBER}`, 30000);
            if (cargoTab && cargoTab.data && cargoTab.data.data) this.$scope.model = cargoTab.data.data;

            this.$scope.vehicleListModel = this.$scope.model.CARGO_DETAIL.VEHICLE_TYPE_LIST;

            let gateInFullEvent = null;
            let loadEvent = null;
            if (this.$newProcessScope.model.EVENT) {
                gateInFullEvent = this.$newProcessScope.model.EVENT.find(x => x.EVENT_TYPE.ID == EEventType.GATE_IN_FULL);
                loadEvent = this.$newProcessScope.model.EVENT.find(x => x.EVENT_TYPE.ID == EEventType.LOAD)
            }

            this.$scope.gateInFullEffective = gateInFullEvent ? gateInFullEvent.EFFECTIVE_DATE : null;
            this.$scope.gateInFullForecast = gateInFullEvent ? gateInFullEvent.FORECAST_DATE : null;
            this.$scope.loadForecast = loadEvent ? loadEvent.FORECAST_DATE : null;
            this.$scope.bookingStatus = this.$newProcessScope.model.BOOKING_STATUS ? this.$newProcessScope.model.BOOKING_STATUS.NAME : null;
            this.$scope.serviceProvider = this.$newProcessScope.model.SERVICE_PROVIDER ? this.$newProcessScope.model.SERVICE_PROVIDER.NAME : null;
            this.$scope.vessel = (this.$newProcessScope.model.EVENT_LOAD && this.$newProcessScope.model.EVENT_LOAD.TRANSPORT_MODE) ? this.$newProcessScope.model.EVENT_LOAD.TRANSPORT_MODE.NAME : null;
            this.$scope.qtdContainers = this.$scope.model.CARGO_DETAIL && this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST ? this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST.reduce((sum, item) => sum + item.QUANTITY, 0) : null;
            this.$scope.qtdTeu = this.$scope.model.CARGO_DETAIL && this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST ? this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST.reduce((sum, item) => sum + (item.EQUIPMENT.TEU * item.QUANTITY), 0) : null;
            this.$scope.processSituation = this.$newProcessScope.model.SITUATION.NAME;

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            if (this.$newProcessScope.operation == 'edit') {
                await this.$timeout(async () => {
                    this.formService.loadEditForm();
                    await this.updateCargoCalculatedFields();
                }, 100);
            }
            this.$scope.oldModel = angular.copy(this.$scope.model);
            this.$scope.cargoListGrossWeightFieldsControl(this.$scope.model.CARGO_DETAIL.GROSS_WEIGHT);
            this.formService.unblock();
        }
    }

    private async saveProcessTabCargo(): Promise<boolean> {
        let success = true;
        let isModifiedTypeOk = true;
        try {
            const isInvalid = this.$newProcessScope.hasInvalidRequiredElements('collapseCargo') || this.$newProcessScope.hasInvalidElements('collapseCargo');
            if (isInvalid) success = false;
            else {
                if (this.$newProcessScope.hasChanges(this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST, this.$scope.oldModel.CARGO_DETAIL.EQUIPMENT_LIST)) {
                    if (!this.modalCargoModifiedTypeId) this.modalCargoModifiedTypeId = this.modalService.newModal();

                    this.$scope.modifiedReasonModal = { cargoModifiedType: null, modifiedResponsibleType: null };

                    const body = `
                    <div class="row">
                        <div class="col-lg-6">
                            <label>` + this.formService.getTranslate('OPERATIONAL.UPDATE_REASON') + `</label>
                            <ui-select name="cargoModifiedType" id="cargoModifiedType"
                                ng-model="modifiedReasonModal.cargoModifiedType"
                                theme="bootstrap"
                                ng-change="selectorValidity(this.$select.ngModel.$name);"
                                ng-disabled="selectorDisabled(this.$select.ngModel.$name) || operation == 'view'"
                                ng-click="selectorFocus(this.$select.searchInput[0]);"
                                skip-focusser="true" required>
                                <ui-select-match placeholder="{{'GENERAL.UI_SELECT.SELECT' | translate }}">
                                    {{$select.selected.NAME}}
                                </ui-select-match>
                                <ui-select-choices
                                    repeat="item in modifiedTypeList | filter: $select.search track by $index">
                                    <div
                                        ng-bind-html="item.NAME | highlight: $select.search">
                                    </div>
                                </ui-select-choices>
                                <ui-select-no-choice>
                                    {{'GENERAL.UI_SELECT.EMPTY_SELECTOR_MESSAGE' | translate }}
                                </ui-select-no-choice>
                            </ui-select>
                        </div>
                        <div class="col-lg-6">
                                <label>` + this.formService.getTranslate('GENERAL.RESPONSIBLE') + ` <i
                                class="fa fa-asterisk text-danger small-fontawesome" aria-hidden="true"
                                tooltip-placement="auto top"
                                uib-tooltip="{{'GENERAL.MANDATORY_FIELD' | translate }}"
                                tooltip-append-to-body="true"></i></label>
                                <ui-select name="responsibleModifiedSelector" id="responsibleModifiedSelector" ng-model="modifiedReasonModal.modifiedResponsibleType"
                                    theme="bootstrap" ng-change="selectorValidity(this.$select.ngModel.$name);"
                                    ng-click="selectorFocus(this.$select.searchInput[0])"
                                    ng-disabled="selectorDisabled(this.$select.ngModel.$name)" skip-focusser="true"
                                    required>
                                    <ui-select-match allow-clear="true">{{$select.selected.ID}} -
                                        {{$select.selected.NAME}}
                                    </ui-select-match>
                                    <ui-select-choices
                                        repeat="item in fileSpecsResponsibleList | filter: $select.search track by $index">
                                        <div ng-bind-html="item.ID + ' - ' + item.NAME | highlight: $select.search">
                                        </div>
                                    </ui-select-choices>
                                </ui-select>
                            </div>
                    </div>
                    `;

                    const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                        {
                            modalID: this.modalCargoModifiedTypeId,
                            scope: this.$scope,
                            formService: 'register',
                            size: 'md',
                            events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                                if (event.name == "modal.closing" && closed) {
                                    if (!this.$scope.modifiedReasonModal || (this.$scope.modifiedReasonModal && !this.$scope.modifiedReasonModal.cargoModifiedType)) {
                                        event.preventDefault();
                                        const msgError = this.formService.getTranslate('BASIC_DATA.IT_IS_NECESSARY_TO_INFORM_THE_REASON_TO_CONTINUE');
                                        this.formService.notifyError(msgError);
                                    }
                                    if (!this.$scope.modifiedReasonModal || (this.$scope.modifiedReasonModal && !this.$scope.modifiedReasonModal.modifiedResponsibleType)) {
                                        event.preventDefault();
                                        const msgError = this.formService.getTranslate('GENERAL.ALL_FIELDS_MANDATORY');
                                        this.formService.notifyError(msgError);
                                    }
                                }
                            }
                        },
                        {
                            closeButtonText: 'GENERAL.CANCEL',
                            actionButtonText: 'REGISTRATION.APPLY',
                            headerText: 'BASIC_DATA.INFORM_THE_REASON_FOR_THE_QUANTITY_CHANGE',
                            bodyText: this.$sce.trustAsHtml(body),
                        }
                    );

                    modalInstance.rendered.then(() => {
                        const modifiedTypeSelect = angular.element("#cargoModifiedType");
                        if (modifiedTypeSelect) this.$compile(modifiedTypeSelect)(this.$scope);
                        const modifiedResponsibleSelect = angular.element("#responsibleModifiedSelector");
                        if (modifiedResponsibleSelect) this.$compile(modifiedResponsibleSelect)(this.$scope);
                        this.$timeout(() => this.$newProcessScope.selectorValidity("responsibleModifiedSelector"), 1000);
                    });

                    isModifiedTypeOk = await modalInstance.result.then(function (result) {
                        return result.$value;
                    }, function (result) {
                        return result.$value;
                    });

                    if (isModifiedTypeOk && this.$scope.model.CARGO_DETAIL && this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST) {
                        if (this.$scope.modifiedReasonModal && this.$scope.modifiedReasonModal.cargoModifiedType) {
                            for (const equipment of this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST) {
                                equipment.MODIFIED_TYPE = this.$scope.modifiedReasonModal.cargoModifiedType;
                            }
                        }
                    }
                }
                if (!isModifiedTypeOk) success = false;
                else {
                    this.formService.block();
                    this.updateDtaModel();

                    if (this.$scope.model.CARGO_DETAIL && this.$scope.model.CARGO_DETAIL.CBM)
                        this.$scope.model.CARGO_DETAIL.CBM = parseFloat(this.$scope.model.CARGO_DETAIL.CBM.toFixed(3));
                    this.$scope.model.CARGO_DETAIL.VEHICLE_TYPE_LIST = this.$scope.vehicleListModel;

                    const cargoUpdateResponse = await this.operationalService.post(`/cargo/update`, { processNumber: this.$newProcessScope.model.PROCESS_NUMBER, data: this.$scope.model, productId: this.$newProcessScope.model.PRODUCT.ID, processId: this.$newProcessScope.model.ID, timeout: 120000, modifiedResponsible: this.$scope.modifiedReasonModal && this.$scope.modifiedReasonModal.modifiedResponsibleType ? this.$scope.modifiedReasonModal.modifiedResponsibleType : null });
                    if (cargoUpdateResponse && cargoUpdateResponse.status == 200) {
                        success = true;
                        const msg = this.formService.getTranslate('BASIC_DATA.CARGO_TAB_DATA_SUCESSFULLY_SAVED');
                        this.formService.notifySuccess(msg);

                        const cargoUpdateData = (cargoUpdateResponse.data && cargoUpdateResponse.data.data) ? cargoUpdateResponse.data.data : null;
                        if (cargoUpdateData && cargoUpdateData.CARGO_DETAIL && cargoUpdateData.CARGO_DETAIL.EQUIPMENT_CONCATENATED) this.$newProcessScope.equipmentConcatenated = cargoUpdateData.CARGO_DETAIL.EQUIPMENT_CONCATENATED;
                    }
                    else success = false;
                }
            }
        } catch (ex) {
            success = false;
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            if (success) this.$scope.oldModel = angular.copy(this.$scope.model);
            else this.$newProcessScope.collapseState.nextState = null;
            return success;
        }
    }

    private enableTotalGrossWeight() {
        const cargoList = this.$scope.model.CARGO_LIST;
        if (cargoList) {
            this.$scope.model.CARGO_DETAIL.GROSS_WEIGHT = null;
            for (const cargo of cargoList) {
                cargo.WEIGHT = null;
                cargo.TOTAL_WEIGHT = null;
            }
            angular.element("#cargoGrossWeight").focus();
        }
    }

    private temperatureValidation = (temperature: number) => {
        this.$timeout(() => {
            if (!temperature) return;
            if (this.$newProcessScope.model.PRODUCT.ID !== 'EM') {
                if (this.$scope.model.CARGO_DETAIL.REFRIGERATED_CARGO.MINIMUM_TEMPERATURE > this.$scope.model.CARGO_DETAIL.REFRIGERATED_CARGO.MAXIMUM_TEMPERATURE) {
                    this.$scope.form['specRefrigeratedCargoMaximumTemperature'].$setValidity('$valid', false);
                } else {
                    this.$scope.form['specRefrigeratedCargoMaximumTemperature'].$setValidity('$valid', true);
                }
            } else {
                this.$scope.model.CARGO_DETAIL.REFRIGERATED_CARGO.MAXIMUM_TEMPERATURE = null;
            }
        }, 100);
    }

    private specRegrigeratedChange = () => {
        if (!this.$scope.model.CARGO_DETAIL.IS_REFRIGERATED_CARGO) this.$scope.model.CARGO_DETAIL.REFRIGERATED_CARGO = null;
    }

    private specCargoTreatmentChange = () => {
        if (!this.$scope.model.CARGO_DETAIL.IS_CARGO_TREATMENT) this.$scope.model.CARGO_DETAIL.CARGO_TREATMENT = null;
    }

    private async getParamsDDDetList(direction: string, nature: string): Promise<void> {
        let result: IParamsDetDem[] = [];
        try {
            this.formService.block();

            let countries = null;
            if (direction == "1" && this.$newProcessScope.model.ORIGIN) {
                countries = [parseInt(this.$newProcessScope.model.ORIGIN.COUNTRY.ID)];
            } else if (direction == "2" && this.$newProcessScope.model.DESTINATION) {
                countries = [parseInt(this.$newProcessScope.model.DESTINATION.COUNTRY.ID)];
            }

            const equipments = [];
            if (this.$scope.model.CARGO_DETAIL && this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST) {
                for (const equip of this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST) {
                    equipments.push(equip.EQUIPMENT.ID);
                }
            }

            const filter: IParamsDetDemListRequest = {
                product: this.$newProcessScope.model.PRODUCT.ID,
                type: "2",
                nature,
                direction,
                country_data: {
                    country: countries,
                    trade_lane: null,
                    trade_group: null,
                    routing_point: null

                },
                provider: this.$newProcessScope.model.SERVICE_PROVIDER ? parseInt(this.$newProcessScope.model.SERVICE_PROVIDER.ID) : null,
                accounts: this.$newProcessScope.model.CUSTOMER ? [parseInt(this.$newProcessScope.model.CUSTOMER.ID)] : null,
                equipment: equipments.length > 0 ? equipments : null
            };

            const paramsDetDems = await this.productService.post({ route: `/paramsDetDem/list/filter`, data: { data: filter } });
            if (paramsDetDems && paramsDetDems.data && paramsDetDems.data.data) result = paramsDetDems.data.data;

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.paramsDDDetList = result;
            this.formService.unblock();
        }
    }

    private async getParamsDDDemList(direction: string, nature: string): Promise<void> {
        let result = [];
        try {
            this.formService.block();

            let countries = null;
            if (direction == "1" && this.$newProcessScope.model.ORIGIN) {
                countries = [parseInt(this.$newProcessScope.model.ORIGIN.COUNTRY.ID)];
            } else if (direction == "2" && this.$newProcessScope.model.DESTINATION) {
                countries = [parseInt(this.$newProcessScope.model.DESTINATION.COUNTRY.ID)];
            }

            const equipments = [];
            if (this.$scope.model.CARGO_DETAIL && this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST) {
                for (const equip of this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST) {
                    equipments.push(equip.EQUIPMENT.ID);
                }
            }

            const filter: IParamsDetDemListRequest = {
                product: this.$newProcessScope.model.PRODUCT.ID,
                type: "3",
                nature,
                direction,
                country_data: {
                    country: countries,
                    trade_lane: null,
                    trade_group: null,
                    routing_point: null
                },
                provider: this.$newProcessScope.model.SERVICE_PROVIDER ? parseInt(this.$newProcessScope.model.SERVICE_PROVIDER.ID) : null,
                accounts: this.$newProcessScope.model.CUSTOMER ? [parseInt(this.$newProcessScope.model.CUSTOMER.ID)] : null,
                equipment: equipments.length > 0 ? equipments : null
            };

            const paramsDetDems = await this.productService.post({ route: `/paramsDetDem/list/filter`, data: { data: filter } });
            if (paramsDetDems && paramsDetDems.data && paramsDetDems.data.data) result = paramsDetDems.data.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.paramsDDDemList = result;
            this.formService.unblock();
        }
    }

    private async getParamsDDCombinedList(direction: string, nature: string): Promise<void> {
        let result = [];
        try {
            this.formService.block();

            let countries = null;
            if (direction == "1" && this.$newProcessScope.model.ORIGIN) {
                countries = [parseInt(this.$newProcessScope.model.ORIGIN.COUNTRY.ID)];
            } else if (direction == "2" && this.$newProcessScope.model.DESTINATION) {
                countries = [parseInt(this.$newProcessScope.model.DESTINATION.COUNTRY.ID)];
            }

            const equipments = [];
            if (this.$scope.model.CARGO_DETAIL && this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST) {
                for (const equip of this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST) {
                    equipments.push(equip.EQUIPMENT.ID);
                }
            }

            const filter: IParamsDetDemListRequest = {
                product: this.$newProcessScope.model.PRODUCT.ID,
                type: "1",
                nature,
                direction,
                country_data: {
                    country: countries,
                    trade_lane: null,
                    trade_group: null,
                    routing_point: null

                },
                provider: this.$newProcessScope.model.SERVICE_PROVIDER ? parseInt(this.$newProcessScope.model.SERVICE_PROVIDER.ID) : null,
                accounts: this.$newProcessScope.model.CUSTOMER ? [parseInt(this.$newProcessScope.model.CUSTOMER.ID)] : null,
                equipment: equipments.length > 0 ? equipments : null
            };

            const paramsDetDems = await this.productService.post({ route: `/paramsDetDem/list/filter`, data: { data: filter } });
            if (paramsDetDems && paramsDetDems.data && paramsDetDems.data.data) result = paramsDetDems.data.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.paramsDDCombinedList = result;
            this.formService.unblock();
        }
    }

    private async saveDetDemDetails(operation: string, closeModal?: boolean): Promise<boolean> {
        let success = false;
        try {
            if (!this.$scope.cargoModel.CARGO_DETAIL.DET_DEM_DETAILS) throw Error('detDemDetails is null');
            this.formService.block();
            await this.beforeSaveDetDemDetails();
            if (operation === "edit") {
                const paramsDetDems = await this.dataProcessService.post(`/processCargo/detDemDetail/update`, { data: this.$scope.cargoModel, oldData: this.$scope.oldCargoModel }, 30000);

                if (paramsDetDems && paramsDetDems.status == 200) {
                    success = true;
                    this.$scope.model.CARGO_DETAIL.DET_DEM_DETAILS = this.$scope.cargoModel.CARGO_DETAIL.DET_DEM_DETAILS;
                    const msg = this.formService.getTranslate('BASIC_DATA.CARGO_TAB_DATA_SUCESSFULLY_SAVED');
                    this.formService.notifySuccess(msg);
                }
                else success = false;
            }
            if (closeModal) {
                this.modalService.closeModal(this.detDemModalId);
                this.detDemModalId = 0;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return success;
        }
    }

    private async beforeSaveDetDemDetails() {
        try {
            const modalScope = await this.modalService.getModalScope(this.detDemModalId);
            modalScope.$applyAsync();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async applyDetDemDetails(operation: string, closeModal?: boolean): Promise<void> {
        if (this.$newProcessScope.hasChanges(JSON.stringify(this.$scope.cargoModel.CARGO_DETAIL), JSON.stringify(this.$scope.oldCargoModel.CARGO_DETAIL))) {
            const confirm = await this.$newProcessScope.modalSaveConfirmation("REGISTRATION.BACK", "GENERAL.CANCEL");
            if (!confirm || confirm && !await this.saveDetDemDetails(operation, closeModal)) return;
        }
    }

    private async openCargoReadinessHistoryModal(model: INewProcessCargoModel): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            await this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    template: require("../view/modal/cargoReadinessHistoryModal.html"),
                    formService: 'register',
                    size: 'full modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CANCEL',
                    headerText: `${this.formService.getTranslate('GENERAL.GRID.LOG')}`
                },
                {
                    idProcess: this.$newProcessScope.model.ID
                }
            );

            const modalScope = await this.ModalService.getModalScope(this.modalID);
            await modalScope.$applyAsync();
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }
}
import * as angular from 'angular';
import { IRestService } from "@services/RestService";
import { INotificationOptions } from "@services/NotificationService";
import { IMonacoColumnDef } from '@services/GridService2';
import { OperationalService } from '@services/OperationalService';
import { GridFormService, IGridFormServiceScope, IGridFormController } from "@services/GridFormService";
import { IViewLog, ILogResponse } from "@models/interface/common/IViewLog";
import { PROCESS } from '@models/interface/operational/FollowUpProcessModel';
import { DeadlineProcessAggregation } from "@models/interface/operational/DeadlineModel";
import { DEADLINES } from '@models/interface/operational/NewProcess';
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { RouteConstants } from "../../common/util/RouteConstants";
import { IGenericResponse } from "../../common/interface/IGenericResponse";
import ProcessDeadlinesModal, { IProcessDeadlinesModalParameter } from '../../operational/common/ProcessDeadlinesModal';

interface IDeadlineMasterHouseScope extends IGridFormServiceScope {
    model: any;

    openTab: (entity: DeadlineProcessAggregation) => Promise<void>;

    selectProcess: (PROCESS_NUMBER: string) => any;
    log: IViewLog;
    gridOptionsProcess: object;

    goToProcess: (processID: string) => void;
}

export class DeadlineProcessRegisterController extends GridFormService implements IGridFormController {
    static $inject: Array<string> = ['$injector', '$scope'];
    private actionBarBtns = [GridColumnBuilderConstants.BTN_VIEW,
    GridColumnBuilderConstants.BTN_EDIT,
    GridColumnBuilderConstants.BTN_VIEWLOG]
    private $scope: IDeadlineMasterHouseScope;
    private RestService: IRestService;
    private OperationalService: OperationalService;

    constructor($injector: ng.Injectable<any>, $scope: IDeadlineMasterHouseScope) {
        super($injector, $scope);
        //this.$injector = $injector;
        this.$scope = $scope;
        this.RestService = $injector.get('RestService');
        this.OperationalService = $injector.get('OperationalService');
    }

    async $onInit(): Promise<void> {
        try {

            this.initForm(this, 'form', 'deadlineProcess', 'GENERAL.MENU.DEADLINE_PROCESS', false);
            this.block();
            await this.initGrid('deadlineMasterHouseProcess', RouteConstants.DEADLINE_PROCESS_LIST, true, true, 120000, true);
            this.$formService.hideAddButton();
            this.unblock();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    //DEFINE MODEL
    initModel(): void { }

    //DEFINE FUNCTIONS
    initScopeFunctions(): void {
        this.$scope.edit = (entity: DeadlineProcessAggregation) => this.edit(entity);
        this.$scope.view = (entity: DeadlineProcessAggregation) => this.view(entity);
        this.$scope.viewLog = (entity: DeadlineProcessAggregation) => this.viewLog(entity);
        this.$scope.openTab = (entity: DeadlineProcessAggregation) => this.viewProcessNewTab(entity);
        this.$scope.goToProcess = (processID: string) => this.goToProcess(processID);
    }

    //COLUMNS
    initGridColumns(columns) {
        let builder = new GridColumnBuilder(columns);

        builder.includeActionBar(this.actionBarBtns, 70);

        //COLUMNS DEFS
        let processNumber: IMonacoColumnDef = {};
        processNumber.name = 'PROCESS.PROCESS_NUMBER';
        processNumber.displayName = 'OPERATIONAL.FILE_NUMBER';
        processNumber.width = 150;
        processNumber.cellTemplate = `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.PROCESS_VIEW' | translate }}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToProcess(row.entity.PROCESS.PROCESS_NUMBER)">{{row.entity.PROCESS.PROCESS_NUMBER}}</a></div>`;
        processNumber.filter = { condition: this.$gridService.filterSelectObject };

        let customer: IMonacoColumnDef = {};
        customer.name = 'CUSTOMER';
        customer.searchProps = ['CUSTOMER.NAME'];
        customer.cellFilter = 'selectormodel';
        customer.displayName = 'BASIC_DATA.CLIENT';
        customer.width = 200;
        customer.filter = { condition: this.$gridService.filterSelectObject };

        let exporter: IMonacoColumnDef = {};
        exporter.name = 'EXPORTER';
        exporter.searchProps = ['EXPORTER.NAME'];
        exporter.displayName = 'BASIC_DATA.SHIPPER';
        exporter.cellFilter = 'selectormodel';
        exporter.width = 200;
        exporter.filter = { condition: this.$gridService.filterSelectObject };

        let booking: IMonacoColumnDef = {};
        booking.name = 'BOOKING';
        booking.displayName = 'OPERATIONAL.BOOKING';
        booking.cellTemplate = `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.PROCESS_VIEW' | translate }}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToProcess(row.entity.PROCESS.PROCESS_NUMBER)">{{row.entity.BOOKING}}</a></div>`;
        booking.width = 100;
        booking.filter = { condition: this.$gridService.filterSelectObject };

        let type: IMonacoColumnDef = {};
        type.name = 'TYPE';
        type.displayName = 'GENERAL.TYPE';
        type.width = 75;
        type.filter = { condition: this.$gridService.filterSelectObject };

        let ship: IMonacoColumnDef = {};
        ship.name = 'STOPOVER.SHIP_REFERENCE.SHIP';
        ship.searchProps = ['STOPOVER.SHIP_REFERENCE.SHIP.NAME'];
        ship.displayName = 'BASIC_DATA.VESSEL';
        ship.cellFilter = 'selectormodel';
        ship.width = 240;
        ship.filter = { condition: this.$gridService.filterSelectObject };

        let shipOwner: IMonacoColumnDef = {};
        shipOwner.name = 'SHIPOWNER.OWNER';
        shipOwner.searchProps = ['SHIPOWNER.OWNER.NAME'];
        shipOwner.displayName = 'BASIC_DATA.SEA_CARRIER';
        shipOwner.cellFilter = 'selectormodel';
        shipOwner.width = 350;
        shipOwner.filter = { condition: this.$gridService.filterSelectObject };

        let travel: IMonacoColumnDef = {};
        travel.name = 'SHIPOWNER.TRAVEL';
        travel.displayName = 'OPERATIONAL.VOYAGE';
        travel.width = 100;
        travel.filter = { condition: this.$gridService.filterSelectObject };

        let codeStopOver: IMonacoColumnDef = {};
        codeStopOver.name = 'STOPOVER.ID';
        codeStopOver.displayName = 'REGISTRATION.STOPOVER_ID';
        codeStopOver.width = 200;
        codeStopOver.filter = { condition: this.$gridService.filterSelectObject };

        let port: IMonacoColumnDef = {};
        port.name = 'STOPOVER.PORT';
        port.searchProps = ['STOPOVER.PORT.NAME'];
        port.displayName = 'ROUTE.PORT';
        port.cellFilter = 'selectormodel';
        port.width = 100;
        port.filter = { condition: this.$gridService.filterSelectObject };

        let estimatedBerthing: IMonacoColumnDef = {};
        estimatedBerthing.name = 'STOPOVER.ESTIMATED_BERTHING';
        estimatedBerthing.displayName = 'OPERATIONAL.BERTH_ESTIMATED_DATE';
        estimatedBerthing.width = 150;
        estimatedBerthing.cellFilter = 'datetime';
        estimatedBerthing.filter = { condition: this.$gridService.filterSelectObject };

        let berthing: IMonacoColumnDef = {};
        berthing.name = 'STOPOVER.BERTHING';
        berthing.displayName = 'OPERATIONAL.BERTH';
        berthing.width = 120;
        berthing.cellFilter = 'datetime';
        berthing.filter = { condition: this.$gridService.filterSelectObject };

        let vgm: IMonacoColumnDef = {};
        vgm.name = 'PROCESS.DEADLINE_DATE.VGM';
        vgm.displayName = 'OPERATIONAL.VGM';
        vgm.width = 150;
        vgm.cellFilter = 'datetime';
        vgm.type = 'datetimepicker';
        vgm.filter = { condition: this.$gridService.filterSelectObject };

        let draftRegular: IMonacoColumnDef = {};
        draftRegular.name = 'PROCESS.DEADLINE_DATE.DRAFT';
        draftRegular.displayName = 'OPERATIONAL.DRAFT';
        draftRegular.width = 200;

        draftRegular.cellFilter = 'datetime';
        draftRegular.type = 'datetimepicker';
        draftRegular.filter = { condition: this.$gridService.filterSelectObject };

        let draftImo: IMonacoColumnDef = {};
        draftImo.name = 'PROCESS.DEADLINE_DATE.DRAFT_IMO';
        draftImo.displayName = 'OPERATIONAL.DRAFT_IMO';
        draftImo.width = 200;

        draftImo.cellFilter = 'datetime';
        draftImo.type = 'datetimepicker';
        draftImo.filter = { condition: this.$gridService.filterSelectObject };

        let release: IMonacoColumnDef = {};
        release.name = 'PROCESS.DEADLINE_DATE.RELEASE';
        release.displayName = 'OPERATIONAL.DEADLINE_RELEASE';
        release.width = 200;

        release.cellFilter = 'datetime';
        release.type = 'datetimepicker';
        release.filter = { condition: this.$gridService.filterSelectObject };

        let defaultInfo: IMonacoColumnDef = {};
        defaultInfo.name = 'PROCESS.SPECIFIC_SLA';
        defaultInfo.displayName = 'OPERATIONAL.SLA';
        defaultInfo.cellFilter = 'YesOrNo';
        defaultInfo.width = 100;
        defaultInfo.filter = { condition: this.$gridService.filterSelectObject };

        let reviewed: IMonacoColumnDef = {};
        reviewed.name = 'PROCESS.REVIEWED';
        reviewed.displayName = 'GENERAL.REVIEWED';
        reviewed.cellFilter = 'deadlineReviewed';
        reviewed.width = 100;
        reviewed.filter = { condition: this.$gridService.filterSelectObject };

        let vmgDefault: IMonacoColumnDef = {};
        vmgDefault.name = 'DEADLINE_DATE.VGM';
        vmgDefault.displayName = 'OPERATIONAL.STANDARD_VGM';
        vmgDefault.cellFilter = 'datetime';
        vmgDefault.width = 200;
        vmgDefault.filter = { condition: this.$gridService.filterSelectObject };

        let draftImoDefault: IMonacoColumnDef = {};
        draftImoDefault.name = 'DEADLINE_DATE.DRAFT_IMO';
        draftImoDefault.displayName = 'OPERATIONAL.STANDARD_DRAFT_IMO';
        draftImoDefault.cellFilter = 'datetime';
        draftImoDefault.width = 200;
        draftImoDefault.filter = { condition: this.$gridService.filterSelectObject };

        let draftRegularDefault: IMonacoColumnDef = {};
        draftRegularDefault.name = 'DEADLINE_DATE.DRAFT';
        draftRegularDefault.displayName = 'OPERATIONAL.STANDARD_DRAFT';
        draftRegularDefault.cellFilter = 'datetime';
        draftRegularDefault.width = 200;
        draftRegularDefault.filter = { condition: this.$gridService.filterSelectObject };

        let releaseDefault: IMonacoColumnDef = {};
        releaseDefault.name = 'DEADLINE_DATE.RELEASE';
        releaseDefault.displayName = 'OPERATIONAL.STANDARD_DEADLINE_RELEASE';
        releaseDefault.cellFilter = 'datetime';
        releaseDefault.width = 200;
        releaseDefault.filter = { condition: this.$gridService.filterSelectObject };

        builder.removeColumn('STOPOVER');
        builder.removeColumn('PROCESS');

        builder.addColumn(processNumber)
            .addColumn(customer)
            .addColumn(exporter)
            .addColumn(booking)
            .addColumn(type)
            .addColumn(vgm)
            .addColumn(draftRegular)
            .addColumn(draftImo)
            .addColumn(release)
            .addColumn(defaultInfo)
            .addColumn(reviewed)
            .addColumn(ship)
            .addColumn(shipOwner)
            .addColumn(travel)
            .addColumn(port)
            .addColumn(estimatedBerthing)
            .addColumn(berthing)
            .addColumn(vmgDefault)
            .addColumn(draftImoDefault)
            .addColumn(draftRegularDefault)
            .addColumn(releaseDefault);

        return builder.$columnDefs;
    }

    //REQUESTS
    private getProcessByNumber(number) {
        const timeout = 120000;
        return this.OperationalService.get(`${RouteConstants.PROCESS_NUMBER}/${number}/${timeout}`, null, timeout);
    }


    private async getDeadlineLog(number): Promise<any> {
        const response = await this.RestService.getObject(RouteConstants.DEADLINE_PROCESS_LOG + '/' + number);
        return response.data;
    }

    private async updateDeadlineGrid(limit: number = null): Promise<any> {
        await this.updateGrid();
        return await this.$gridService.getGridData();
    }

    private updateDeadline(cells: Array<DeadlineProcessAggregation>, field: string): IGenericResponse<any> {

        //TODO CREATE A NEW DIRECTIVE FOR DATETIMEPICKER        

        let request = {
            data: cells,
            field: field,
            timeout: 15000
        }

        return this.RestService.updateObject(RouteConstants.DEADLINE_PROCESS_UPDATE, request, 15000);
    }

    private async saveExcel(cells: Array<DeadlineProcessAggregation>, field: string): Promise<any> {

        try {

            this.block();

            if (field) field = field.replace('PROCESS.DEADLINE_DATE.', '');

            await this.updateDeadline(cells, field);
            const list = await this.updateDeadlineGrid();

            this.unblock();

            return list;

        } catch (ex) {

            const list = await this.updateDeadlineGrid();

            const options: INotificationOptions = { autoHideDelay: 5000 };
            this.handleError(ex, null, options);
            this.unblock();

            return list;

        }
    }

    public async edit(deadlineView: DeadlineProcessAggregation): Promise<void> {
        const modalParameters: IProcessDeadlinesModalParameter = {
            readonly: false,
            processNumber: null, // TODO Hammes get this info from selected row entity
            title: `${this.formService.getTranslate("GENERAL.EDITING")} deadlines`,
            house: deadlineView.TYPE === "HOUSE" ? this.convertToDeadlines(deadlineView) : null,
            master: deadlineView.TYPE === "MASTER" ? this.convertToDeadlines(deadlineView) : null,
            load: null
        }

        const modalInstance = new ProcessDeadlinesModal(this.$injector, this.$scope)
        modalInstance.show(modalParameters);
    }

    public async view(deadlineView: DeadlineProcessAggregation): Promise<void> {
        const modalParameters: IProcessDeadlinesModalParameter = {
            readonly: false,
            processNumber: null, // TODO Hammes get this info from selected row entity
            title: `${this.formService.getTranslate("GENERAL.VIEWING")} deadlines`,
            house: deadlineView.TYPE === "HOUSE" ? this.convertToDeadlines(deadlineView) : null,
            master: deadlineView.TYPE === "MASTER" ? this.convertToDeadlines(deadlineView) : null,
            load: null
        }

        const modalInstance = new ProcessDeadlinesModal(this.$injector, this.$scope)
        modalInstance.show(modalParameters);
    }

    private convertToDeadlines(deadlineView: DeadlineProcessAggregation): DEADLINES {
        if (!deadlineView) return null;
        const deadline: DEADLINES = {
            TYPE: deadlineView.TYPE,
            DEADLINE_DATES: deadlineView.DEADLINE_DATE,
            DEADLINE_REVIEWS: deadlineView.PROCESS.REVIEWED,
            SPECIFIC_SLA: deadlineView.PROCESS.SPECIFIC_SLA,
            STOPOVER_REFERENCE: deadlineView.STOPOVER_IDENTIFIER,
            DEADLINE_CARGO_DOCS: null,
        }

        return deadline;
    }

    private async viewLog(deadlineView: DeadlineProcessAggregation): Promise<void> {

        try {

            this.block();

            let log: IViewLog = {
                operation: 'history',
                number: deadlineView.DEADLINE_NUMBER,
                list: [],
                show: true,
                searchQuery: '',
                originalList: [],
            }

            const response = await this.getDeadlineLog(log.number);

            log.list = response.data;
            log.originalList = angular.copy(log.list);

            this.$scope.log = log;

            angular.element('#log-viewer').removeClass('ng-hide');
            const position = angular.element('#log-viewer').offset().top + $('.app-content-body').scrollTop() - 105;
            $('.app-content-body').animate({ scrollTop: position }, 500);

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }

    }

    private async goToProcess(processNumber: string) {
        this.block()
        const timeout = 120000;
        const processPromise = await this.OperationalService.get(`/process/byNumber/${processNumber}/${timeout}`, null, timeout);
        if (processPromise && processPromise.data && processPromise.data.data && processPromise.data.data.length) {
            const url = `#allog/app/operational/process/view/${processPromise.data[0]._id}`;
            window.open(url, '_blank');

            this.unblock();
        }
    }

    private async viewProcessNewTab(deadlineView: DeadlineProcessAggregation): Promise<void> {

        try {

            this.block();

            const response = await this.getProcessByNumber(deadlineView.PROCESS.PROCESS_NUMBER);
            const hasData: boolean = response && response.data && response.data.data && response.data.data.length > 0;

            let id: string = null;
            if (hasData) {
                const process: PROCESS = response.data.data[0] as any;
                id = process._id;
            }

            if (!id) throw new Error("Can't find _id of process " + deadlineView.PROCESS.PROCESS_NUMBER);

            const url = `#allog/app/operational/process/wizard/view/${id}`;
            window.open(url, '_blank');

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    };

}
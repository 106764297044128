import * as angular from "angular";
import { ICustomLogProperties } from "WBA-Model/dist/interface/common/IViewLog";
import { IConsolidatedModel } from "WBA-Model/dist/interface/operational/consolidated/Consolidated";
import { IGridFormController, GridFormService, IGridFormServiceScope } from "@services/GridFormService";
import { IMonacoColumnDef } from "@services/GridService2";
import { DataProcessService } from "@services/DataProcessService";
import { IModalService } from "@services/ModalService";
import { EOperation } from "@enums/GenericData";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { EGridConsolidated } from "../model/ConsolidatedModel";
import { IModalInstanceService } from "angular-ui-bootstrap";

export interface IConsolidatedScope extends IGridFormServiceScope {
    // Model
    model: IConsolidatedModel;
    consolidatedModalId: number

    // Log
    customLogProperties: ICustomLogProperties[];

    // Operations Grid
    editConsolidated: (consolidated: IConsolidatedModel) => Promise<void>;
    viewConsolidated: (consolidated: IConsolidatedModel) => Promise<void>;
    copyConsolidated: (consolidated: IConsolidatedModel) => Promise<void>;
    viewLogConsolidated: (consolidated: IConsolidatedModel) => Promise<void>;
}

export class ConsolidatedRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private scope: IConsolidatedScope;
    private DataProcessService: DataProcessService;
    private ModalService: IModalService;
    private gridName: string;

    constructor($injector: ng.Injectable<any>, $scope: IConsolidatedScope) {
        super($injector, $scope);

        this.scope = $scope;
        this.DataProcessService = $injector.get('DataProcessService');
        this.ModalService = $injector.get('ModalService');
        this.$baseUrl = this.DataProcessService.$route;
        this.scope.customLogProperties = this.getCustomLogProperties();
        this.gridName = EGridConsolidated.GRID_CONSOLIDATED;
    }

    public $onDestroy(): void {
        super.$onDestroy();
    }

    public async $onInit(): Promise<void> {
        try {
            this.block();
            this.initialize();
            this.unblock();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private async initialize(): Promise<void> {
        this.initFormGrid();
    }

    private async initFormGrid(): Promise<void> {
        try {
            this.$rootScope.setBreadCrumb("OPERATIONAL.CONSOL", () => this.openConsolidatedRegisterModal());
            this.initForm(this, "form", "consolidated", "OPERATIONAL.CONSOL", false);
            await this.initGrid(this.gridName, '/consolidated/list', true, true, 120000, true, true);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    public initScopeFunctions(): void {
        // Operations Grid
        this.scope.editConsolidated = (consolidated: IConsolidatedModel) => this.editConsolidated(consolidated);
        this.scope.viewConsolidated = (consolidated: IConsolidatedModel) => this.viewConsolidated(consolidated);
        this.scope.copyConsolidated = (consolidated: IConsolidatedModel) => this.copyConsolidated(consolidated);
        this.scope.viewLogConsolidated = (consolidated: IConsolidatedModel) => this.viewLogConsolidated(consolidated);
    }

    public initModel(): void {
        this.scope.model = {
            _id: null,
            ID: null,
            ID_DISPLAY: null,
            TARIFF_FREIGHT: null,
            TARIFF_LOCAL_DESTINATION: null,
            TARIFF_LOCAL_ORIGIN: null,
            ACTIVE: true,
            AWB_NUMBER: null,
            FLIGHT: null,
            DISPLAY_NAME: null,
            SITUATION: null,
            PRODUCT: null,
            PROVIDER: null,
            ORIGIN: null,
            DESTINATION: null,
            EXTERNAL_AGENT: null,
            ETD_DATE: null,
            ETA_DATE: null,
            DEADLINE: null,
            AUTOMATIC_RATE_ACHIEVED: null,
            CONSIDERED_RATE_ACHIEVED: null,
            CARGO: null,
            CONSOLIDATED_CHARGE: null,
            CONSOLIDATED_PROCESS: null,
            CONSOLIDATED_EVENT: null,
            CONSOLIDATED_INSTRUCTION: null,
            CONSOLIDATED_REFERENCE: null,
            PUBLISHED_FARE: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null
        }
    }

    private async getConsolidatedById(consolidatedId: number): Promise<IConsolidatedModel> {
        try {
            if (!consolidatedId) this.formService.notifyError('Missing consolidatedId in getConsolidatedById');
            this.block();

            const timeout: number = 120000;

            const rc = await this.DataProcessService.get(`/consolidated/getById/${consolidatedId}`, timeout);
            const result: IConsolidatedModel = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    public initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        try {
            const gridColumns = new GridColumnBuilder([]);

            const view = `<a ng-click="grid.appScope.viewConsolidated(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
            const edit = `<a ng-click="grid.appScope.editConsolidated(row.entity)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
            const copy = `<a ng-click="grid.appScope.copyConsolidated(row.entity)" class="text-orange copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
            const viewLog = `<a ng-click="grid.appScope.viewLogConsolidated(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;

            const colActions: IMonacoColumnDef = {
                name: "acoes",
                displayName: "GENERAL.ACTIONS",
                width: 120,
                cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${copy} ${viewLog}</div>`,
                enableFiltering: false,
                enableSorting: false,
                enableHiding: false,
                enableColumnMoving: false,
                enableColumnResizing: false,
                pinnedLeft: true,
                enablePinning: false
            };

            gridColumns.addColumn(colActions);
            const newColumnDefs: IMonacoColumnDef[] = this.buildColumns(columns);

            for (const column of newColumnDefs) {
                column.filter = (column.filter) ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column);
            };

            return <uiGrid.IColumnDef[]>gridColumns.$columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const gridColumns: IMonacoColumnDef[] = [];

            const colAwbNumber: IMonacoColumnDef = { name: "AWB_NUMBER", displayName: "OPERATIONAL.AWB_NUMBER", width: 150 }
            const colFlight: IMonacoColumnDef = { name: "FLIGHT.CODE", displayName: "OPERATIONAL.FLIGHT_CODE", width: 150 };
            const colDisplayName: IMonacoColumnDef = { name: "DISPLAY_NAME", displayName: "GENERAL.DISPLAY_NAME", width: 150 };
            const colSituation: IMonacoColumnDef = { name: "SITUATION.NAME", displayName: "GENERAL.SITUATION", width: 150 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.NAME", displayName: "BASIC_DATA.PRODUCT", width: 150 };
            const colProvider: IMonacoColumnDef = { name: "PROVIDER.NAME", displayName: "BASIC_DATA.PROVIDER", width: 150 };
            const colOrigin: IMonacoColumnDef = { name: "ORIGIN.NAME", displayName: "BASIC_DATA.ORIGIN", width: 150 };
            const colDestination: IMonacoColumnDef = { name: "DESTINATION.NAME", displayName: "BASIC_DATA.DESTINATION", width: 150 };
            const colExtAgent: IMonacoColumnDef = { name: "EXTERNAL_AGENT.NAME", displayName: "BASIC_DATA.OVERSEAS_AGENT", width: 150 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 70, cellFilter: "YesOrNo" };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "GENERAL.ID", width: 80 };

            columns.forEach(column => {
                switch (column) {
                    case "ACTIVE":
                        gridColumns.push(colActive);
                        break;
                    case "AWB_NUMBER":
                        gridColumns.push(colAwbNumber);
                        break;
                    case "FLIGHT":
                        gridColumns.push(colFlight);
                        break;
                    case "DISPLAY_NAME":
                        gridColumns.push(colDisplayName);
                        break;
                    case "SITUATION":
                        gridColumns.push(colSituation);
                        break;
                    case "PRODUCT":
                        gridColumns.push(colProduct);
                        break;
                    case "PROVIDER":
                        gridColumns.push(colProvider);
                        break;
                    case "ORIGIN":
                        gridColumns.push(colOrigin);
                        break;
                    case "DESTINATION":
                        gridColumns.push(colDestination);
                        break;
                    case 'EXTERNAL_AGENT':
                        gridColumns.push(colExtAgent);
                        break;
                    case 'CREATED_AT':
                        gridColumns.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        gridColumns.push(colUpdatedAt);
                        break;
                    case 'ID':
                        gridColumns.push(colId);
                        break;
                }
            });
            return gridColumns;
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private async editConsolidated(consolidated: IConsolidatedModel): Promise<void> {
        try {
            await this.fetchData(consolidated.ID, EOperation.EDIT);
            this.openConsolidatedWizardModal(this.scope.model, EOperation.EDIT);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async viewConsolidated(consolidated: IConsolidatedModel): Promise<void> {
        try {
            await this.fetchData(consolidated.ID, EOperation.VIEW);
            this.openConsolidatedWizardModal(this.scope.model, EOperation.VIEW);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async copyConsolidated(consolidated: IConsolidatedModel): Promise<void> {
        try {
            await this.fetchData(consolidated.ID, EOperation.COPY);
            await this.clearFields(this.scope.model);
            this.openConsolidatedRegisterModal(this.scope.model);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async viewLogConsolidated(consolidated: IConsolidatedModel): Promise<void> {
        try {
            this.scope.viewLog(consolidated, consolidated._id.toString());
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openConsolidatedRegisterModal(consolidated?: IConsolidatedModel): Promise<void> {
        try {
            const modalId: number = this.ModalService.newModal();
            const modalInstance = await this.ModalService.showModalInfo(
                {
                    modalID: modalId,
                    template: require("../view/modal/consolidatedRegisterModal.html"),
                    formService: 'register',
                    size: 'lg',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: 'OPERATIONAL.CONSOL'
                },
                {
                    model: (consolidated) ? consolidated : null
                }
            );

            const modalScope = await this.ModalService.getModalScope(modalId);
            await modalScope.$applyAsync();

            const apply = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (apply) {
                await this.updateGrid();
                this.formService.notifySuccess(this.formService.getTranslate('OPERATIONAL.CONSOL_REGISTERED_SUCCESS'));
                this.scope.$applyAsync();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action) throw Error('Missing action parameter in fetchData');

            const consolidated: IConsolidatedModel = await this.getConsolidatedById(id);
            if (consolidated) {
                const model = angular.copy(consolidated);
                this.scope.model = model;

                switch (action) {
                    case GridColumnBuilderConstants.BTN_EDIT:
                        this.scope.edit(model);
                        break;
                    case GridColumnBuilderConstants.BTN_COPY:
                        this.scope.copy(model);
                        break;
                    case GridColumnBuilderConstants.BTN_VIEW:
                        this.scope.view(model);
                        break;
                    default:
                        this.scope.view(model);
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openConsolidatedWizardModal(consolidatedModel: IConsolidatedModel, operation: string): Promise<void> {
        try {
            this.scope.consolidatedModalId = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    modalID: this.scope.consolidatedModalId,
                    template: require("../view/wizard/consolidated/consolidatedWizardModal.html"),
                    formService: operation,
                    size: 'full modal-overflow',
                    scope: this.scope

                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: 'REGISTRATION.FILES_WIZARD'
                },
                {
                    model: consolidatedModel,
                    oldModel: angular.copy(consolidatedModel)
                }
            );

            const modalScope = await this.ModalService.getModalScope(this.scope.consolidatedModalId);
            await modalScope.$applyAsync();

            let mustUpdateGrid = true;
            const apply = await modalInstance.result.then(function (result) {
                if (angular.isDefined(result.$value.mustUpdateGrid)) {
                    mustUpdateGrid = result.$value.mustUpdateGrid;
                    return result.$value.result;
                }
                return result.$value;
            });

            if (apply) {
                this.formService.notifySuccess(this.formService.getTranslate('OPERATIONAL.UPDATE_CONSOLIDATED_SUCCESS'));
                await this.updateGrid();
                this.scope.$applyAsync();
            } else if (mustUpdateGrid) {
                await this.updateGrid();
                this.scope.$applyAsync();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
            {
                PROPERTY: 'FLIGHT',
                LABEL: 'OPERATIONAL.FLIGHT'
            },
            {
                PROPERTY: 'PRODUCT',
                LABEL: 'BASIC_DATA.PRODUCT'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'TYPE',
                LABEL: 'GENERAL.TYPE'
            },
            {
                PROPERTY: 'ORIGIN',
                LABEL: 'BASIC_DATA.ORIGIN'
            },
            {
                PROPERTY: 'DESTINATION',
                LABEL: 'BASIC_DATA.DESTINATION'
            },
            {
                PROPERTY: 'EXTERNAL_AGENT',
                LABEL: 'BASIC_DATA.EXTERNAL_AGENT_NETWORK'
            },
            {
                PROPERTY: 'ETD_DATE',
                LABEL: 'OPERATIONAL.ETD'
            },
            {
                PROPERTY: 'ETA_DATE',
                LABEL: 'OPERATIONAL.ETA'
            },
            {
                PROPERTY: 'AUTOMATIC_RATE_ACHIEVED',
                LABEL: 'OPERATIONAL.AUTOMATIC_ACHIEVED'
            },
            {
                PROPERTY: 'CONSIDERED_RATE_ACHIEVED',
                LABEL: 'OPERATIONAL.CONSIDERED_ACHIEVED'
            },
            {
                PROPERTY: 'CARGO',
                LABEL: 'GENERAL.CARGO'
            },
            {
                PROPERTY: 'AWB_NUMBER',
                LABEL: 'OPERATIONAL.AWB_NUMBER'
            },
            {
                PROPERTY: 'SITUATION',
                LABEL: 'GENERAL.SITUATION'
            },
            {
                PROPERTY: 'PROVIDER',
                LABEL: 'BASIC_DATA.PROVIDER'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'BASIC_DATA.BRANCH'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            },
            {
                PROPERTY: 'DISPLAY_NAME',
                LABEL: 'BASIC_DATA.DISPLAY'
            },
            {
                PROPERTY: 'ID_DISPLAY',
                LABEL: 'BASIC_DATA.DISPLAY'
            },
            {
                PROPERTY: "ADDRESS",
                LABEL: "ENTITY.ADDRESS"
            },
            {
                PROPERTY: "ZIP_CODE",
                LABEL: "ENTITY.ZIP_CODE"
            },
            {
                PROPERTY: "COMPLEMENT",
                LABEL: "ENTITY.ADDRESS_COMPLEMENT"
            },
            {
                PROPERTY: "COUNTRY",
                LABEL: "ROUTE.COUNTRY"
            },
            {
                PROPERTY: "CITY",
                LABEL: "ROUTE.CITY"
            },
            {
                PROPERTY: "DISTRICT",
                LABEL: "ENTITY.DISTRICT"
            },
            {
                PROPERTY: "NUMBER",
                LABEL: "GENERAL.NUMBER"
            },
            {
                PROPERTY: "PUBLIC_PLACE",
                LABEL: "ENTITY.ADDRESS"
            },
            {
                PROPERTY: "PROVINCE",
                LABEL: "ROUTE.STATE"
            },
            {
                PROPERTY: "CORRESPONDENCE",
                LABEL: "ENTITY.POSTAL_ADDRESS"
            },
            {
                PROPERTY: 'COUNTRY_IMG',
                LABEL: 'REGISTRATION.FLAG'
            },
            {
                PROPERTY: 'CODE',
                LABEL: 'BASIC_DATA.INITIALS'
            },
            {
                PROPERTY: "NETWORK",
                LABEL: "BASIC_DATA.NETWORK"
            },
            {
                PROPERTY: "CORPORATE_NAME",
                LABEL: "ENTITY.CORPORATE_NAME"
            },
            {
                PROPERTY: 'NUMBER_IATA',
                LABEL: 'Número IATA'
            },
            {
                PROPERTY: 'SCAC',
                LABEL: 'GENERAL.SCAC_CODE'
            },
            {
                PROPERTY: "TRADE_GROUP",
                LABEL: "ROUTE.TRADE_GROUP"
            },
            {
                PROPERTY: "TRADE_LANE",
                LABEL: "ROUTE.TRADE_LANE"
            },
            {
                PROPERTY: "INITIALS",
                LABEL: "GENERAL.CODE"
            },
            {
                PROPERTY: 'LEGAL_PERSON_NAME',
                LABEL: 'ENTITY.LEGAL_PERSON'
            },
            {
                PROPERTY: 'DEADLINE',
                LABEL: 'OPERATIONAL.DEADLINE'
            },
            {
                PROPERTY: 'TARIFF_LOCAL_ORIGIN',
                LABEL: 'PRODUCT.TARIFF_LOCAL_ORIGIN'
            },
            {
                PROPERTY: 'TARIFF_FREIGHT',
                LABEL: 'GENERAL.MENU.TARIFF_FREIGHT'
            },
            {
                PROPERTY: 'TARIFF_LOCAL_DESTINATION',
                LABEL: 'PRODUCT.TARIFF_LOCAL_DESTINATION'
            },
            {
                PROPERTY: 'TAXPAYER_NUMBER',
                LABEL: 'GENERAL.TAX_ID'
            },
            {
                PROPERTY: 'VOLUME',
                LABEL: 'GENERAL.UNIT_VOLUME'
            },
            {
                PROPERTY: 'TRADING_NAME',
                LABEL: 'ENTITY.TRADE_NAME'
            },
            {
                PROPERTY: 'GW',
                LABEL: 'GENERAL.UNIT_GROSS_WEIGHT_LB'
            },
            {
                PROPERTY: 'CBM',
                LABEL: 'GENERAL.CUBIC_METER'
            },
            {
                PROPERTY: 'CW',
                LABEL: 'GENERAL.CW_KG'
            }
        ];
        return props;
    }
}
import * as angular from 'angular';
import initRoute from './RouteConfig';
import { GRID_FORM_MODULE } from '../gridForm/GridFormModule';
import { GRID_EXCEL_MODULE } from '../gridExcel/GridExcelModule';

// https://github.com/summernote/summernote/
import 'summernote';
import 'summernote/dist/summernote.js';
import 'summernote/dist/summernote.css';
// https://github.com/summernote/angular-summernote
import 'angular-summernote/dist/angular-summernote.js';

import { TaskService } from '@services/TaskService';
import { OperationalService } from '@services/OperationalService';
import { ProductService } from '@services/ProductService';
import { ManagementMenuController } from './controller/ManagementMenuController';
import { NewTaskRegisterController } from './controller/NewTaskRegisterController';
import { NewTaskHistoryController } from './controller/NewTaskHistoryController';
import { NotificationMonitorRegisterController } from './controller/NotificationMonitorRegisterController';
import { IntegrationTaskRegisterController } from './controller/IntegrationTaskRegisterController';
import { NotificationMailBoxRegisterController } from './controller/NotificationMailBoxRegisterController';
import { ExternalDataIntegrationRegisterController } from './controller/ExternalDataIntegrationRegisterController';
import { DataOperationalService } from '@services/DataOperationalService';

const MANAGEMENT_MODULE = angular
    .module('management', [GRID_FORM_MODULE.name, GRID_EXCEL_MODULE.name, 'summernote'])

    .service('TaskService', TaskService)
    .service('OperationalService', OperationalService)
    .service('ProductService', ProductService)
    .service('DataOperationalService',DataOperationalService)
    .controller('ManagementMenuController', ManagementMenuController)
    .controller('NewTaskRegisterController', NewTaskRegisterController)
    .controller('NewTaskHistoryController', NewTaskHistoryController)
    .controller('NotificationMonitorRegisterController', NotificationMonitorRegisterController)
    .controller('IntegrationTaskRegisterController', IntegrationTaskRegisterController)
    .controller('NotificationMailBoxRegisterController', NotificationMailBoxRegisterController)
    .controller('ExternalDataIntegrationRegisterController', ExternalDataIntegrationRegisterController)

initRoute(MANAGEMENT_MODULE.name);

export { MANAGEMENT_MODULE };
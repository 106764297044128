import * as config from '../../bootstrap/Config';
import * as Address from '../../communication/Address';
import { IModalService } from '@services/ModalService';
import { FormService2 } from '@services/FormService2';
import { IRestService } from "@services/RestService";
import { IModalInstanceService } from 'angular-ui-bootstrap';

export interface IBlockedNotificationScopeModal {
    status: boolean,
    blockedNotification: boolean;
    blockedNotificationMessage: string;
    showNotificationCheck: boolean;
    showActionButton: boolean;
    changeNotification: () => void;
    queueRemove: (status: boolean, blockedNotification: boolean) => void;
}

export interface IBlockedNotificationModal {
    status: boolean,
    blockedNotification: boolean;
}

export interface IBlockedObject {
    ID: number;
    NAME: string;
    EMAIL: string;
    FORM_NAME: string;
}

export class SSEService {
    private modalService: IModalService;
    private $scope: any;
    private status: boolean;
    private formService: FormService2;
    private route: string;
    public events: EventSource;
    private allEvents: EventSource[];
    private blockedObject: IBlockedObject;
    private restService: IRestService;

    constructor($injector: ng.Injectable<any>, $scope: any, formService: FormService2 = null) {
        try {
            this.modalService = $injector.get('ModalService');
            this.restService = $injector.get('RestService');
            this.$scope = $scope;
            this.$scope.blockedNotification = true;
            this.$scope.showActionButton = true;
            this.allEvents = [];
            this.formService = formService;

            if (config.default.environment === 'prod') {
                this.route = `${Address.monacoAddressProd.SSE}/api/v1/sse`;
            } else if (config.default.environment === 'qa') {
                this.route = `${Address.monacoAddressQa.SSE}/api/v1/sse`;
            } else { //dev
                this.route = `${Address.monacoAddressLocal.SSE}/api/v1/sse`;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    public initEvents() {
        if (!this.events) {
            const token = this.$scope.sessionService.getToken();
            const actualEvent: EventSource = new EventSource(`${this.route}?id=${this.blockedObject.ID}&name=${this.blockedObject.NAME}&code=&formName=${this.blockedObject.FORM_NAME}&token=${token}`);

            actualEvent.onerror = (ev: Event) => {
                if (config.default.environment != 'prod') console.error(ev);
            };

            this.allEvents.push(actualEvent);
            this.events = actualEvent;
        }
    }

    public removeFromQueue() {
        this.restService.newObjectPromise(`${this.route}/queue/remove`, { ID: this.blockedObject.ID, EMAIL: this.blockedObject.EMAIL, FORM_NAME: this.blockedObject.FORM_NAME }, 30000, false);
    }

    public async generate(parsedData: any, showActionButton: boolean = true, closeButtonText: string = 'Cancelar'): Promise<any> {
        try {
            this.$scope.blockedNotificationMessage = parsedData.message;
            this.$scope.showNotificationCheck = parsedData.user.CODE != this.$scope.user['email'];
            this.$scope.showActionButton = showActionButton;

            const modalId = this.modalService.newModal();
            const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                {
                    modalID: modalId,
                    scope: this.$scope,
                    formService: "view",
                    size: 'vmd modal-overflow',
                    template: require('../view/modals/blockedNotification.html'),
                    keyboard: false
                },
                {
                    actionButtonText: 'Sim, quero visualizar o registro',
                    headerText: 'Deseja visualizar?',
                    closeButtonText: closeButtonText
                }
            );
            await this.buildBlockedNotificationModalScope(modalId);
            const result: IBlockedNotificationModal = await modalInstance.closed.then(() => {
                return { status: this.status, blockedNotification: this.$scope.blockedNotification }
            });
            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async buildBlockedNotificationModalScope(modalId: number): Promise<void> {
        try {
            if (!modalId) throw Error('Missing modalId parameter in buildBlockedNotificationModalScope');
            const modalScope: IBlockedNotificationScopeModal = await this.modalService.getModalScope(modalId);

            modalScope.queueRemove = (status: boolean): void => {
                this.status = status;
                // Send message to server to define if this user wants to be notified when this offer is released
                if (!this.$scope.blockedNotification) this.removeFromQueue();
                this.modalService.closeModal(modalId);
            }

            modalScope.changeNotification = (): void => {
                this.$scope.blockedNotification = !this.$scope.blockedNotification;
            }
        } catch (ex) {
            this.modalService.closeModal(modalId);
            this.formService.handleError(ex);
        }
    }

    public getRoute() {
        return this.route
    }

    public closeEvents() {
        if (this.allEvents && this.allEvents.length) {
            for (const event of this.allEvents) {
                if (event.readyState != EventSource.CLOSED) event.close();
            }
        }

        if (this.events && this.events.readyState == EventSource.CLOSED) this.events = null;
    }

    public setBlockedObject(blockedObject: IBlockedObject) {
        this.blockedObject = blockedObject;
    }

    public getBlockedObject(): IBlockedObject {
        return this.blockedObject;
    }
}

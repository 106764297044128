import * as angular from 'angular';

const template = `<div class="breadcrumb" id="breadcrumb">
                    <div class="breadcrumb-content col-sm-7">
                        <i class="fa fa-home icon"></i>
                        <span ng-repeat="step in steps" ng-class="{active: $last}" ng-switch="$last || !!step.abstract">
                            <i class="fa fa-angle-right" aria-hidden="true"></i>
                            <a ng-switch-when="false" href="{{step.ncyBreadcrumbLink}}" ng-bind-html="step.ncyBreadcrumbLabel | translate"></a>
                            <span ng-switch-when="true">{{step.ncyBreadcrumbLabel | translate}}</span>
                        </span>
                    </div>
                    <div class="col-sm-5 pull-right padding-xs-horizontal">                        
                        <div class="pull-right full-width" ng-show="$root.gridOptions.enableProfile || $root.gridOptions.enableBackgroundUpdate || $root.breadCrumb.show">
                            <div class="input-group">
                                <div ng-class="(showProfile) ? 'fade in' : 'fade'">
                                    <ui-select name="gridProfileSelector" ng-model="$root.gridOptions.profile.gridSelectedProfile"
                                        theme="bootstrap" ng-click="selectorFocus(this.$select.searchInput[0]);"
                                        on-select="$root.gridOptions.gridLoadProfile($root.gridOptions.profile.gridSelectedProfile)"
                                        skip-focusser="true" style="padding-left: 0 !important">
                                        <ui-select-match allow-clear="false"
                                            placeholder="{{'GENERAL.PROFILE_SELECT' | translate}}">
                                            {{$select.selected.NAME}}</ui-select-match>
                                        <ui-select-choices
                                            repeat="item in $root.gridOptions.gridProfileList | filter: $select.search track by $index">
                                            <div ng-bind-html="item.NAME | highlight: $select.search">;
                                            </div>
                                        </ui-select-choices>
                                        <ui-select-no-choice> {{'BASIC_DATA.NO_REGISTERED_PROFILE' | translate}}
                                        </ui-select-no-choice>
                                    </ui-select>
                                </div>
                                <span class="input-group-btn">
                                    <span class="profile-toolbar">
                                        <span ng-class="(showProfile) ? 'fade in' : 'fade'">
                                            <a class="btn btn-default" ng-click="$root.gridOptions.gridAddProfileModal()"
                                                tooltip-placement="auto top" uib-tooltip="{{'GENERAL.PROFILE_ADD' | translate}}"
                                                tooltip-append-to-body="true">
                                                <i class="fa fa-plus text-success"></i>
                                            </a>
                                            <a class="btn btn-default"
                                                ng-click="$root.gridOptions.gridRemoveProfile($root.gridOptions.profile.gridSelectedProfile.NAME)"
                                                tooltip-placement="auto top" uib-tooltip="{{'GENERAL.PROFILE_REMOVE' | translate}}"
                                                tooltip-append-to-body="true">
                                                <i class="fa fa-minus text-danger"></i>
                                            </a>
                                            <a class="btn btn-default" ng-click="$root.gridOptions.gridAddProfileModal(true, false)"
                                                tooltip-placement="auto top" uib-tooltip="{{'GENERAL.PROFILE_EDIT' | translate}}"
                                                tooltip-append-to-body="true">
                                                <i class="fa fa-pencil text-especial"></i>
                                            </a>
                                            <a class="btn btn-default" ng-click="$root.gridOptions.gridAddProfileModal(false, true)"
                                                tooltip-placement="auto top" uib-tooltip="{{'GENERAL.PROFILE_COPY_ADD' | translate}}"
                                                tooltip-append-to-body="true">
                                                <i class="fa fa-copy text-orange"></i>
                                            </a>
                                            <a class="btn btn-default" ng-disabled="$root.gridOptions.profile.gridSelectedProfile.IS_DEFAULT"
                                                ng-click="!$root.gridOptions.profile.gridSelectedProfile.IS_DEFAULT && $root.gridOptions.gridSetProfileDefault($root.gridOptions.profile.gridSelectedProfile.NAME)"
                                                tooltip-placement="auto top" uib-tooltip="{{($root.gridOptions.profile.gridSelectedProfile.IS_DEFAULT ? 'GENERAL.PROFILE_IS_ALREADY_DEFAULT' : 'GENERAL.PROFILE_SET_DEFAULT') | translate }}"
                                                tooltip-append-to-body="true">
                                                <i class="fa text-yellow" ng-class="($root.gridOptions.profile.gridSelectedProfile.IS_DEFAULT) ? 'fa-star' : 'fa-star-o'"></i>
                                            </a>
                                            <a class="btn btn-default"
                                                ng-click="$root.gridOptions.gridSaveProfile($root.gridOptions.profile.gridSelectedProfile.NAME)"
                                                tooltip-placement="auto top" uib-tooltip="{{'GENERAL.PROFILE_SAVE' | translate}}"
                                                tooltip-append-to-body="true">
                                                <i class="fa fa-save text-info"></i>
                                            </a>
                                        </span>
                                        <a ng-show="!showProfile" class="btn btn-default" tooltip-placement="auto top"
                                            ng-click="showProfile = !showProfile;" uib-tooltip="{{'GENERAL.PROFILE_SHOW' | translate}}"
                                            tooltip-append-to-body="true">
                                            <i class="fa fa-user-o text-cyano"></i>
                                        </a>
                                        <a ng-show="showProfile" class="btn btn-default" tooltip-placement="auto top"
                                            ng-click="showProfile = !showProfile;" uib-tooltip="{{'GENERAL.PROFILE_HIDE' | translate}}"
                                            tooltip-append-to-body="true">
                                            <i class="fa fa-user-o text-cyano"></i>
                                        </a>
                                    </span>
                                    <span>
                                        <a class="btn btn-default"
                                        uib-tooltip="{{(!$root.gridOptions.gridHasSelectedRows() ? 'GENERAL.SELECT_ROWS_SPREADSHEET' : 'GENERAL.CREATE_SPREADSHEET') | translate}}"
                                        tooltip-placement="auto top"
                                        tooltip-append-to-body="true"
                                        ng-disabled="!$root.gridOptions.gridHasSelectedRows()"
                                        ng-click="$root.gridOptions.gridHasSelectedRows() && $root.gridOptions.gridModalTemplateExcel()"
                                        ng-show="$root.gridOptions.gridGetExcelRoute()">
                                            <i class="fa fa-file-excel-o text-success"></i>
                                        </a>
                                    </span>
                                    <span>
                                        <a class="btn btn-default"
                                            uib-tooltip="{{$root.gridOptions.getCustomButton().tooltipLabel | translate}}"
                                            tooltip-placement="auto top"
                                            tooltip-append-to-body="true"
                                            ng-click="$root.gridOptions.getCustomButton().fn()"
                                            ng-show="$root.gridOptions.gridHasSelectedRows() && $root.gridOptions.getCustomButton() && !$root.gridOptions.getCustomButton().disabled()"
                                            ng-disabled="$root.gridOptions.getCustomButton().disabled()"
                                        >
                                            <i class="{{$root.gridOptions.getCustomButton().icon}}"></i>
                                        </a>
                                    </span>
                                    <span>
                                        <a class="btn btn-default" ng-click="$root.gridOptions.gridViewFilter();"
                                            tooltip-placement="auto top" uib-tooltip="{{'GENERAL.ACTIVE_FILTERS' | translate}}" tooltip-append-to-body="true">
                                            <i class="fa fa-filter text-grey"></i>
                                        </a>
                                        <a class="btn btn-default text-ellipsize" style="max-width:8vw" ng-show="$root.breadCrumb.show && $root.breadCrumb.name"
                                            ng-click="$root.breadCrumb.action()" tooltip-append-to-body="true"
                                            tooltip-placement="auto top" uib-tooltip="{{$root.breadCrumb.name | translate}}" aria-expanded="false">
                                            <i class="text-success {{$root.breadCrumb.icon}}"></i>
                                            <strong class="text-success" translate="{{$root.breadCrumb.name}}"></strong>
                                        </a>
                                    </span>
                                </span>
                            </div>
                        </div>
                    </div>
                    <fieldset style="display:none">
                        <p>{{$root.title = ((steps && steps.length > 0) ? steps[steps.length - 1].ncyBreadcrumbLabel : 'MONACO')}}</p>
                    </fieldset>
                    </div>`;

setOptions.$inject = ['$breadcrumbProvider'];
function setOptions($breadcrumbProvider) {
    $breadcrumbProvider.setOptions({
        template: template
    })
}

export default function initBreadCrumb(moduleName: string): void {
    angular.module(moduleName).config(setOptions);
}

import * as angular from 'angular';
import * as moment from 'moment';
import { CellGroupUserModel } from '@models/interface/common/CellModel';
import { SHIPOWNER } from "@models/interface/operational/MaritimeTravel";
import { EXTERNAL } from '@models/interface/operational/FollowUpProcessModel';
import { PROCESS, REVIEWED } from "@models/interface/operational/DeadlineModel";
import { ObjectUtil } from '../../common/util/ObjectUtil';
import { ICustomLogProperties, ILogData } from "@models/interface/common/IViewLog";
import { ArrayUtil } from '../../common/util/ArrayUtil';
import { isObject } from 'angular';

export function getIDFromList() {
    return function (list: Array<any>) {
        let names: Array<string> = [];

        list.forEach(item => {
            names.push(item.ID);
        });

        return names.join(', ');
    };
}

export function propsFilter() {
    return function (items, props) {
        let out = [];

        if (angular.isArray(items)) {
            items.forEach(function (item) {
                let itemMatches = false;

                let keys = Object.keys(props);
                for (let i = 0; i < keys.length; i++) {
                    let prop = keys[i];
                    let text = props[prop].toLowerCase();
                    if (item[prop].toString().toLowerCase().indexOf(text) !== -1) {
                        itemMatches = true;
                        break;
                    }
                }

                if (itemMatches) {
                    out.push(item);
                }
            });
        } else {
            out = items;
        }
        return out;
    };
}

export function removeCellUsers() {
    return function (users: Array<CellGroupUserModel>, removeUsers: Array<CellGroupUserModel>) {
        if (angular.isArray(users) && angular.isArray(removeUsers)) {
            let usersToDelete: Array<CellGroupUserModel> = [];

            removeUsers.forEach((removeUser) => {
                users.forEach((user, index) => {
                    if (user.USER_REFERENCE._id == removeUser.USER_REFERENCE._id) usersToDelete.push(user);
                });
            });

            usersToDelete.forEach((user) => {
                users.splice(users.indexOf(user), 1);
            });
        }
        return users;
    };
}

export function cnpj() {
    return function (value) {
        if (!value) return '';
        return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "\$1.\$2.\$3\/\$4\-\$5");
    }
}

export function datePTBR() {
    return function (value) {
        if (!value) return '';
        return (moment(value).format("LLLL"));
    };
}

export function YesOrNo() {
    return function (value) {
        return (value) ? 'Sim' : 'Não';
    };
}

export function BytesToMegabytes() {
    return function (value) {
        return (value != null) ? value / 1048576 : 0;
    };
}

export function isDealdineReviewed() {
    return function (REVIEWED: REVIEWED) {
        let fields = [];
        if (REVIEWED.VGM) fields.push('VGM');
        if (REVIEWED.DRAFT) fields.push('DRAFT');
        if (REVIEWED.DRAFT_IMO) fields.push('DRAFT_IMO');
        if (REVIEWED.RELEASE) fields.push('RELEASE');

        return fields.join(', ');
    };
}

export function PrimarySecundary() {
    return function (value) {
        return (value) ? 'Primário' : 'Secundário';
    };
}

export function binaryText() {
    return function (value, yes, no) {
        return (value) ? yes : no;
    };
}

export function fromNow() {
    return function (date) {
        if (typeof date == 'string')
            return moment(date).fromNow();

        if (date instanceof Date)
            return moment(date).fromNow();

        if (moment.isMoment(date))
            return date.fromNow();
    }
}

export function markText() {
    return function (value) {
        moment.locale(['pt', 'br']);
        return (moment(value).format("LLLL"));
    };
}

export function datetimePicker() {
    return function (value) {
        if (value) {
            if (value.hasOwnProperty('date')) {
                moment.locale(['pt', 'br']);

                if (value.date)
                    return moment(value.date).format('DD[/]MM[/]YYYY HH:mm');
            }
        }
        return null;
    };
}

export function dateTime() {
    return function (date: Date) {
        moment.locale(['pt', 'br']);

        return moment(date).isValid() ? moment(date).format('DD[/]MM[/]YYYY HH:mm') : null;
    }
}

export function dateTimeFormated() {
    return function (date: Date) {
        if (!date) return null;

        moment.locale(['pt', 'br']);

        return moment(date).isValid() ? moment(date).format('DD[/]MM[/]YYYY HH:mm') : null;
    }
}
export function hourMinute() {
    return function (value: Date) {
        if (!value) return null;

        moment.locale(['pt', 'br']);

        return moment(value).isValid() ? moment(value).format('HH:mm') : null;
    }
}

export function simpleDate() {
    return function (value) {

        if (value) {
            moment.locale(['pt', 'br']);
            if (value) return moment(value).format('L');
        }

        return null;
    };
}

export function detalhedDate() {
    return function (date: Date) {
        if (!date) return null;

        moment.locale(['pt', 'br']);

        return moment(date).isValid() ? moment(date).format('DD[/]MM[/]YYYY HH:mm:ss:SSS') : null;
    }
}

export function plural() {
    return function (total, zeroText, singularText, pluralText) {

        let text = '';

        if (total == 0) {
            text = zeroText;
        } else if (total == 1) {
            text = singularText;
        } else {
            text = pluralText;
        }

        return text;

    };
}

export function byteToView() {
    const units: Array<string> = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    return function (bytes: string) {
        let l = 0, n = parseInt(bytes, 10) || 0;
        while (n >= 1024 && ++l) n = n / 1024;

        return (n.toFixed(n >= 10 || l < 1 ? 0 : 1) + ' ' + units[l]);
    }
}

export function fileName() {
    return function (name: string) {

        let type: string = 'default';

        if (!name) return type;


        let value: string = '';

        const result: Array<string> = name.split('.');
        if (angular.isArray(result) && result.length > 1) type = result[result.length - 1];
        else return type;

        switch (type) {
            case 'png':
            case 'jpeg':
            case 'jpg':
            case '3ds':
            case 'bmp':
            case 'doc':
            case 'gif':
            case 'html':
            case 'xls':
            case 'xlsx':
            case 'xml':
            case 'txt':
            case 'pdf':
                value = type;
                break;
            default:
                value = 'default';
        }
        const path = './img/icons/';

        return path + value;
    }
}

export function typeName() {
    return function (type: string, isFileName: boolean = false) {

        let value = '';
        if (!type) return value;

        if (isFileName) {
            const result: Array<string> = type.split('.');
            if (result.length) {
                type = result[result.length - 1];
            }
        }

        switch (type) {
            case 'image/png':
            case 'png':
                value = 'png';
                break
            case 'image/jpeg':
            case 'jpeg':
            case 'jpg':
                value = 'jpg';
                break;
            case 'application/x-3ds':
            case 'image/x-3ds':
            case '3ds':
                value = '3ds';
                break;
            case 'image/bmp':
            case 'image/x-windows-bmp':
            case 'bmp':
                value = 'bmp';
                break;
            case 'application/msword':
                value = 'doc';
                break;
            case 'image/gif':
            case 'gif':
                value = 'gif';
                break;
            case 'text/html':
            case 'html':
                value = 'html';
                break;
            case 'application/excel':
            case 'application/vnd.ms-excel':
            case 'application/application/x-excel':
            case 'application/x-msexcel':
            case 'xls':
            case 'xlsx':
                value = 'xls';
                break;
            case 'application/xml':
            case 'text/xml':
            case 'xml':
                value = 'xml';
                break;
            case 'text/plain':
            case 'txt':
                value = 'txt';
                break;
            case 'application/pdf':
            case 'pdf':
                value = 'pdf';
                break;
            default:
                value = 'default';
        }

        const path = './img/icons/';

        return path + value;
    }
}

formatLog.$inject = ['$filter', '$sce'];
export function formatLog($filter: angular.IFilterService, $sce: angular.ISCEService) {
    return function (originalValue: any, property: string, customLogProperties?: ICustomLogProperties[]) {

        let allData = originalValue;
        let concatData = "";

        if (allData === false) return $sce.trustAsHtml(`<label>${$filter("translate")("GENERAL.NO")}</label>`);
        if (allData === true) return $sce.trustAsHtml(`<label>${$filter("translate")("GENERAL.YES")}</label>`);

        if (!allData && allData != 0) return $sce.trustAsHtml(`<label>${$filter("translate")("GENERAL.EMPTY")}</label>`);

        if (typeof (allData) === 'string') {
            if (Object.prototype.toString.call(new Date(allData)) === "[object Date]") {
                try {
                    const dateTry = moment(allData);
                    if (dateTry.isValid() && isNaN(Number(allData)))
                        return dateTry.format('DD/MM/YYYY HH:mm');
                } catch (ex) {
                    return $sce.trustAsHtml(`<label>${$filter("translate")("GENERAL.UNRECOGNIZED_FORMAT")}</label>`);
                }
            }
            const dataCustomProp = customLogProperties ? customLogProperties.find(obj => obj.PROPERTY == allData) : null;
            return dataCustomProp && dataCustomProp.LABEL ? dataCustomProp.LABEL : allData;
        }

        if (typeof allData == 'object') {
            let result: string = "";
            if (angular.isArray(allData)) {
                if (allData.every(prop => (!Array.isArray(prop)))) {
                    const validData = getFormattedData(allData, customLogProperties, property);
                    if (validData.every(prop => typeof prop === 'object' && !Array.isArray(prop))) {
                        result += buildStringToConcat(validData, $filter, true);
                    } else {
                        for (const data of validData) {
                            result += buildStringToConcat(data, $filter);
                        }
                    }
                    return $sce.trustAsHtml(result);
                }
            } else {
                let extraFields = false
                for (const key of Object.keys(allData)) {
                    if (key !== 'ID' && key !== 'NAME' && key !== 'CODE') {
                        extraFields = true
                    }
                }
                if (!extraFields) {
                    const dataCustomProp = customLogProperties ? customLogProperties.find(obj => obj.PROPERTY == property) : null;
                    return dataCustomProp && dataCustomProp.READ ? allData[dataCustomProp.READ] : allData["NAME"];
                } else {
                    const validData = getFormattedData(allData, customLogProperties, property);
                    for (const data of validData) {
                        result += buildStringToConcat(data, $filter);
                    }
                    return $sce.trustAsHtml(result);
                }
            }

            if (allData.hasOwnProperty('date')) {
                if (Object.prototype.toString.call(new Date(allData["date"])) === "[object Date]") {
                    if (moment(allData["date"], 'DD/MM/YYYY', true).isValid() || moment(allData["date"], 'DD/MM/YYYY HH:mm', true).isValid()) {
                        return moment(allData["date"]).format('DD/MM/YYYY HH:mm:ss');
                    }
                }
            }

            angular.forEach(allData, (data, key) => {
                if (!data) data = 'Vazio'
                if (moment(data, 'DD/MM/YYYY', true).isValid() || moment(data, 'DD/MM/YYYY HH:mm', true).isValid()) data = moment(data).format('DD/MM/YYYY HH:mm');
                result += `${key} : ${data}</br>`;
            });
            if (result) return result;

        }

        if (typeof (allData) === "number") {
            if (property)
                if (['TOTAL', 'TOTAL_BRL', 'MINIMUM', 'CONVERSION_SPREAD', 'ORIGINAL_TOTAL'].indexOf(property) > -1) {
                    return $filter('number')(allData, 2);
                }
            if (['CONVERSION_SPREAD'].indexOf(property) > -1) {
                return $filter('number')(allData, 6);
            }
            return allData;
        }
        return concatData.substring(0, (concatData.trim().length - 1));
    }
}

function buildStringToConcat(data: ILogData[], $filter: angular.IFilterService, isSingleLine?: boolean): string {
    let strToConcat: string = "";

    if (!isSingleLine && Array.isArray(data)) {
        strToConcat = buildStringTable(data, true, $filter, false);
    } else {
        strToConcat = buildStringTable(data, true, $filter, isSingleLine);
    }
    return strToConcat != 'undefined' && strToConcat != undefined ? strToConcat : "";
}

function buildStringTable(data: ILogData | ILogData[], isParent: boolean, $filter: angular.IFilterService, isSingleLine?: boolean, caption?: string): string {
    let stringTable = isParent ? "<div class='table-responsive form-group'><table class='table log-table table-bordered " : "<table class='table log-table table-bordered ";
    stringTable += isParent ? "table-hover parent'>" : "'>";

    if (caption) {
        stringTable += `<caption><strong>${$filter("translate")(caption)}</strong></caption>`;
    }
    if (isSingleLine && Array.isArray(data)) {
        for (const td of data) {
            const tdValue = getFormattedValue(td.VALUE, td.NAME, $filter);
            stringTable += `<tr><td>${tdValue}</td></tr>`;
        }
    } else {
        if (Array.isArray(data)) {
            for (const tr of data) {
                stringTable += "<tr>";
                if (!tr.IS_SUB_TABLE) {
                    if (Array.isArray(tr)) {
                        const subTable = buildStringTable(tr, false, $filter, isSingleLine);
                        stringTable += `<td colspan="2">${subTable}</td>`;
                    } else {
                        const value = getFormattedValue(tr.VALUE, tr.NAME, $filter);
                        stringTable += tr.NAME ? `<td><strong>${$filter("translate")(tr.NAME)}</strong>: ${value}</td>` : `<td>${value}</td>`;
                    }
                } else {
                    const subTable = buildStringTable(tr.VALUE, false, $filter, isSingleLine, tr.NAME);
                    stringTable += `<td colspan="2">${subTable}</td>`;
                }
                stringTable += "</tr>";
            }
        } else {
            stringTable += "<tr>";
            if (!data.IS_SUB_TABLE) {
                stringTable += `<td><strong>${$filter("translate")(data.NAME)}</strong></td>`;
                const value = getFormattedValue(data.VALUE, data.NAME, $filter);
                stringTable += `<td>${value}</td>`;
            } else {
                const subTable = buildStringTable(data.VALUE, false, $filter, isSingleLine, data.NAME);
                stringTable += `<td colspan="2">${subTable}</td>`;
            }
            stringTable += "</tr>";
        }
    }
    stringTable += isParent ? "</table></div>" : "</table>";
    return stringTable;
}

function getFormattedValue(value: any | any[], prop: string, $filter: angular.IFilterService): string {
    let formattedValue: string = "Vazio";
    if (typeof value === "string") {
        try {
            const dateTry = moment(value);
            if (dateTry.isValid() && isNaN(Number(value)))
                formattedValue = dateTry.format('DD/MM/YYYY HH:MM');
            else
                formattedValue = value ? value.toString() : `<label>${$filter("translate")("GENERAL.EMPTY")}</label>`;
        } catch (ex) {
            formattedValue = `<label>${$filter("translate")("GENERAL.UNRECOGNIZED_FORMAT")}</label>`;
        }
    }
    else if (Array.isArray(value) && value.every(x => typeof x === "object"))
        formattedValue = getconcat(value, null, "VALUE");
    else if (typeof value === "number") {
        if (prop) {
            if ([
                'TOTAL',
                'ORIGINAL_TOTAL',
                'TOTAL ORIGINAL',
                'TOTAL_BRL',
                'TOTAL (BRL)',
                'UNITARY',
                'UNITÁRIO',
                'MINIMUM',
                'MÍNIMO',
                'PROFIT',
            ].indexOf(prop.toLocaleUpperCase()) > -1) {
                return $filter('number')(value, 2);
            } else
                if ([
                    'SPREAD',
                    'CONVERSION_SPREAD',
                    'CONVERSÃO+SPREAD',
                    'CONVERSION_FACTOR',
                    'FATOR',
                    'CONVERSION_FACTOR_SPREAD',
                    'FATOR+SPREAD',
                ].indexOf(prop.toLocaleUpperCase()) > -1) {
                    return $filter('number')(value, 6);
                } else
                    formattedValue = value.toString();
        } else
            formattedValue = value.toString();
    }
    else {
        if (value == true) {
            formattedValue = "Sim";
        } else if (value == false) {
            formattedValue = "Não";
        }
    }
    return formattedValue;
}

function getFormattedData(data: any, customLogProperties: ICustomLogProperties[], property: string): any[] {
    let validData: ILogData[] = [];

    if (Array.isArray(data)) {
        if (data.every(x => x && x.hasOwnProperty('NAME'))) {
            for (const dataChild in data) {
                const dataToInsert: any = getFormattedData(data[dataChild], customLogProperties, property);
                validData.push(dataToInsert);
            }
        } else {
            for (const dataChild of data) {
                const dataToInsert: any = getFormattedData(dataChild, customLogProperties, property);
                validData.push(dataToInsert);
            }
        }
    } else {
        if (isObject(data)) {
            for (const dataChild in data) {
                let dataToInsert: ILogData = { NAME: "", VALUE: "", IS_SUB_TABLE: false };
                const dataCustomProp = customLogProperties ? customLogProperties.find(obj => obj.PROPERTY == dataChild) : null;
                dataToInsert.IS_SUB_TABLE = data[dataChild] != null && data[dataChild]["NAME"] === undefined && (Array.isArray(data[dataChild]) || isObject(data[dataChild])) ? true : false;
                if (data && data[dataChild]) {
                    let extraFields = false
                    for (const key of Object.keys(data[dataChild])) {
                        if ((key !== 'ID' && key !== 'NAME' && key !== 'CODE') && (Array.isArray(data[dataChild]) || isObject(data[dataChild]))) {
                            extraFields = true
                        }
                    }
                    dataToInsert.IS_SUB_TABLE = extraFields === true ? extraFields : dataToInsert.IS_SUB_TABLE;
                }
                dataToInsert.NAME = dataCustomProp && dataCustomProp.LABEL ? dataCustomProp.LABEL : dataChild;
                if (dataToInsert.IS_SUB_TABLE) {
                    const subTableData: any = getFormattedData(data[dataChild], customLogProperties, property);
                    dataToInsert.VALUE = subTableData;
                } else {
                    dataToInsert.VALUE = dataCustomProp && dataCustomProp.READ && data[dataChild][dataCustomProp.READ] ? data[dataChild][dataCustomProp.READ] : data[dataChild] != null && data[dataChild]["NAME"] ? data[dataChild]["NAME"] : data[dataChild];
                }
                validData.push(dataToInsert);
            }
        } else {
            let dataToInsert: ILogData = { NAME: "", VALUE: data, IS_SUB_TABLE: false };
            validData.push(dataToInsert);
        }
    }
    return validData;
}

function getconcat(originalValue: any[], customProp?: string, objToReturn?: string, returnUnique: boolean = false, considerMultiline: boolean = false): string {
    try {
        if (!originalValue || originalValue.length === 0) return;
        const separator = ';';

        let values = originalValue;

        // check for multi-line
        if (considerMultiline) {
            const dataSample = (customProp) ? originalValue[0][customProp] : originalValue[0];
            // if that's the case, we mush all values together as we can't represent multiple lines in a one-line grid (duh).
            if (ArrayUtil.isArray(dataSample)) { // is multi-line
                // this way we still enable filtering data for the user.
                values = originalValue.reduce((acc, val) => {
                    if (customProp) return acc.concat(val[customProp]);
                    return acc.concat(val);
                }, []);
            }
        }

        const allData = values
            .filter(data => data != null)
            .map(val => {
                let accessedData: string = val;
                if (typeof val === 'object') {
                    if (customProp && val.hasOwnProperty(customProp) && val[customProp] != null) //added a customProp when needed to dive little deeper
                        accessedData = val[customProp][objToReturn ? objToReturn : "NAME"];
                    else
                        accessedData = val[objToReturn ? objToReturn : "NAME"];
                }
                return accessedData;
            });
        let treatedData = allData.filter(data => data != null);
        if (returnUnique) treatedData = treatedData.filter((v, i, a) => a.indexOf(v) === i);
        const concatData: string = treatedData.join(`${separator} `)
        return concatData;
    } catch (ex) {
        throw ex;
    }
}

export function getConcat() {
    return getconcat;
}

export function substring() {
    return function (text: string, limit: number = 25): string {
        if (!text) return '';
        if (limit > text.length) return text;
        return text.substring(0, (limit / 2) - 2) + '...' + text.slice(-((limit / 2) - 2));
    };
}

export function shipOwners() {
    return function (shipOwners: Array<SHIPOWNER>): string {

        let names: Array<string> = [];

        if (angular.isArray(shipOwners)) {
            for (let i = 0; i < shipOwners.length; i++) {

                let name = '';

                if (shipOwners[i].MASTER) name = '(*) ';
                name = `${name} [${shipOwners[i].TRAVEL}] - ${shipOwners[i].OWNER.CODE}`;


                names.push(name);
            }
        }
        return names.join(' / ');
    };
}

export function processNumber() {
    return function (PROCESS: Array<PROCESS>): string {

        let names: Array<string> = [];
        if (angular.isArray(PROCESS))
            for (let i = 0; i < PROCESS.length; i++)
                names.push(PROCESS[i].PROCESS_NUMBER);

        return names.join(' / ');

    };
}

export function mailIDToGrid() {
    return function (mails: Array<EXTERNAL> | EXTERNAL): string {
        if (angular.isArray(mails)) {
            let names: Array<string> = [];

            for (let i = 0; i < mails.length; i++) {
                const name = `${mails[i].NAME} <${mails[i].ID}>`;
                names.push(name);
            }

            return names.join('; ');
        } else if (angular.isObject(mails)) {
            return `${mails.NAME} <${mails.ID}>`;
        }

        return '';
    };
}

export function mailToGrid() {
    return function (mails: Array<EXTERNAL>): string {

        let names: Array<string> = [];

        if (angular.isArray(mails)) {
            for (let i = 0; i < mails.length; i++) {
                const name = `${mails[i].NAME} <${mails[i].MAIL}>`;
                names.push(name);
            }
        }
        return names.join('; ');
    };
}

export function selectorModelArray() {
    return function (mails: Array<EXTERNAL>): string {

        let names: Array<string> = [];

        if (angular.isArray(mails)) {
            for (let i = 0; i < mails.length; i++) {
                const name = `${mails[i].NAME}`;
                names.push(name);
            }
        }
        return names.join(', ');
    };
}

export function shipReference() {
    return function (ship): string {

        if (!ship) return '';

        return ship.SHIP.NAME;
    };
}

export function selectorModel() {
    return function (selectorModel): string {
        if (!selectorModel) return '';
        return selectorModel.NAME;
    };
}

formatInformationPairs.$inject = ['$filter'];
export function formatInformationPairs($filter: angular.IFilterService) {
    return function (values: object[], pathToDescription: string, pathToValue: string, formatToCurrency: boolean = false): string {
        if (!values || values.length == 0 || !pathToDescription || !pathToValue) return '';
        return values.filter(x => x != null).map(x => {
            let key = ObjectUtil.navObjectByString(x, pathToDescription);
            let val = ObjectUtil.navObjectByString(x, pathToValue);

            if (formatToCurrency) {
                const isConversion = ['CONVERSION_SPREAD', 'CONVERSION_FACTOR', 'CONVERSION_FACTOR_SPREAD'].some(term => pathToValue.includes(term));
                val = (isConversion) ? $filter('number')(val, 6) : $filter('number')(val, 2);
            }

            if (!val) return null;
            return `${key} ${val}`;
        }).filter(x => x != null).join('; ');
    };
}

export function purgeFollowUpHTML() {
    return function (rawHTML: string): string {
        if (!rawHTML) return '';
        let out = rawHTML;
        out = out.replace(/<br\s*[\/]?>/gi, "\n"); // set <br> and </br> to newlines
        out = out.replace(/<p>/gi, "\n"); // set <p> to newlines
        out = out.replace(/&nbsp;/gi, ""); // kill &nbsp;
        out = out.replace(/<\/div>/gi, "\n"); // set </div> to newlines
        out = out.replace(/(<([^>]+)>)/ig, ""); // kill other HTML tags (<a>, <b>, <strong>, etc)
        out = out.replace(/\n\s*\n\s*\n/g, '\n\n'); // fix too many newlines
        return out;
    };
}

export function filterWithWildCard() {
    return function (items: any[], text: string) {
        var filtered = [];

        angular.forEach(items, function (item) {
            if (item.NAME.toLocaleLowerCase().includes(text.toLocaleLowerCase()) || text.includes('%')) {
                filtered.push(item);
            }
        });

        return filtered;
    };
}

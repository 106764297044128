
AccessDeniedController.$inject = ['$scope', '$state'];
export function AccessDeniedController($scope, $state: ng.ui.IStateService) {

  this.$onInit = function () {
    $scope.reason = 'Você não tem permissão para executar esta ação.';

    if ($state.params.reason) {
      const reason = $state.params.reason;

      if (reason) {
        switch (reason) {
          case 'Account is blocked':
            $scope.reason = 'Sua conta foi bloqueada.';
            break;
          case 'Empty Roles':
            $scope.reason = 'Sua conta não possui permissões cadastradas';
            break;
          case 'Invalid Roles':
            $scope.reason = 'Sua conta não possui permissões válidas';
            break;
          case 'Empty People':
            $scope.reason = 'Sua conta não possui permissões cadastradas';
            break;
        }
      }
    }
  }

}  
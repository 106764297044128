import '../../libs/notify.js';

export interface INotificationOptions {
    clickToHide?: boolean;
    autoHide?: boolean;
    autoHideDelay?: number;
    arrowShow?: boolean;
    arrowSize?: number;
    position?: string;
    elementPosition?: string;
    globalPosition?: string;
    style?: string,
    className?: string;
    showAnimation?: string;
    showDuration?: number;
    hideAnimation?: string;
    hideDuration?: number;
    gap?: number;
    closeButtons?:
    {
        error?: { show: boolean, autoHide: boolean, autoHideDelay: number },
        info?: { show: boolean, autoHide: boolean, autoHideDelay: number },
        warning?: { show: boolean, autoHide: boolean, autoHideDelay: number },
        success?: { show: boolean, autoHide: boolean, autoHideDelay: number },
    }
}

NotificationService.$inject = ['$injector'];
export interface INotificationService {
    error: (text: string, clickEvent?: Function, options?: INotificationOptions) => any;
    info: (text: string, clickEvent?: Function, options?: INotificationOptions) => any;
    success: (text: string, clickEvent?: Function, options?: INotificationOptions) => any;
    warning: (text: string, clickEvent?: Function, options?: INotificationOptions) => any;
    element: (selector: string) => any;
}

export function NotificationService() {
    //TODO: DOUBLE CHECK THIS VARIABLE
    let eventCallback = null;

    //regiter the click event just once
    $(document).ready(function () {
        $(document).on('click', '.notifyjs-corner', function (e) {
            e.stopPropagation();
            e.preventDefault();
            if (eventCallback) {
                //call event callback
                eventCallback();
                eventCallback = null;
            };
        });
    });

    const _notify = {
        element: null,
        options: {
            globalPosition: 'bottom center',
            elementPosition: 'bottom left',
            autoHide: true,
            autoHideDelay: 120000,
            closeButtons:
            {
                error: { show: true, autoHide: true, autoHideDelay: 15000 },
                info: { show: false, autoHide: true, autoHideDelay: 5000 },
                warning: { show: false, autoHide: true, autoHideDelay: 5000 },
                success: { show: false, autoHide: true, autoHideDelay: 5000 },
            }
        },
        init: function (text, type, clickEvent, options) {
            //click event callback
            if (clickEvent && typeof clickEvent === "function") eventCallback = clickEvent;
            else eventCallback = null;

            //configura se previamente nao definido
            if (options != undefined) {
                this.setOptions(options);
            }

            //seta configuracoes
            $['notify'].defaults(this.getOptions());

            //inicia plugin notify
            if ((typeof this.getElement() == 'string')) {
                $['notify']($(this.getElement()), text, type);
                this.setElement(null);
            } else if (this.getElement() instanceof jQuery) {
                $['notify'](this.getElement(), text, type);
            } else {
                $['notify'](text, type);
            }
            this.isCenterPosition();
        },
        /**
         * Hack para ajustar caso nas configurações Globais de posicionamento do Notify marque centralizado,
         * pois o plugin Notify não centraliza na página corretamente.
         * 
         * @returns boolean
         */
        isCenterPosition: function () {

            let options = this.getOptions();

            //protecao para manter as opcoes default do notify valendo 
            if (options.globalPosition) {
                if (options.globalPosition.search('center') > 0) {

                    //centraliza
                    $(document).ready(function () {
                        let notifyDom = $('.notifyjs-corner');
                        let recuo = -(notifyDom.width() / 2);
                        let avanco = '50%';

                        notifyDom.css('left', avanco);
                        notifyDom.css('margin-left', recuo);
                    });

                    return true;
                }
            }

            return false;
        },

        //mutantes e acessores
        getElement: function () {
            return this.element;
        },
        setElement: function (element) {
            this.element = element;
        },
        getOptions: function () {
            return this.options;
        },
        setOptions: function (options) {
            Object.assign(this.options, options);
        },
    };


    /**
        * Chamada do Notify pelo Implementador conforme os tipos de notificações
        * 
        * @link https://notifyjs.com
        * @param {string} text
        * @param {callback} clickEvent
        * @param {object} options
        * 
        * @returns this
        */

    const sdo = {
        error: function (text: string, clickEvent?: Function, options?: object) {
            _notify.init(text, 'error', clickEvent, options);
            return this;
        },
        info: function (text: string, clickEvent?: Function, options?: object) {
            _notify.init(text, 'info', clickEvent, options);
            return this;
        },
        success: function (text: string, clickEvent?: Function, options?: object) {
            _notify.init(text, 'success', clickEvent, options);
            return this;

        },
        warning: function (text: string, clickEvent?: Function, options?: object) {
            _notify.init(text, 'warn', clickEvent, options);
            return this;
        },
        element: function (selector: string | JQLite) {
            _notify.setElement(selector);
            return this;
        }
    };

    return sdo;
}
import angular = require('angular');
import { FormService2 } from '@services/FormService2';
import { ISessionService } from '@services/SessionService';
import { IMonacoRequest } from '@services/GridFormService';
import { TaskService } from "@services/TaskService";
import { IViewLog } from "@models/interface/common/IViewLog";
import { NewTask } from "@models/interface/task/NewTask";
import { INewTaskParameter } from "../../common/model/ModelParameter";
import { INewProcessScope, ECollapseState } from './NewProcessController';
import { EOperation } from '@enums/GenericData';
import { OperationalService } from '@services/OperationalService';

export interface ITasks extends NewTask {
    ORDER_DATE_START: Date;
    ORDER_DATE_END: Date;
}

interface INewProcessTaskScope extends ng.IScope {
    log: IViewLog;
    taskList: ITasks[];
    orderByField: string;
    reverseSort: boolean;
    collapseTask: () => void;
    goToTasks: (process: string, taskNumber: string) => void;
    generateTasks: () => void;
}

export class NewProcessTaskController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: INewProcessTaskScope;
    private $newProcessScope: INewProcessScope;
    private formService: FormService2;
    private sessionService: ISessionService;
    private taskService: TaskService;
    private operationalService: OperationalService;

    constructor($injector: ng.Injectable<any>, $scope: INewProcessTaskScope) {
        this.$scope = $scope;
        this.$newProcessScope = <INewProcessScope>$scope.$parent.$parent;
        this.taskService = $injector.get('TaskService');
        this.operationalService = $injector.get('OperationalService');
        this.formService = this.$newProcessScope.formService;
        this.sessionService = this.$newProcessScope.sessionService;
        this.initScopeFunctions();
    }

    async $onInit(): Promise<void> {
        try {
            this.initModel();
            await this.initDependencies();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initModel(): void {

    }

    private initScopeFunctions(): void {

        this.$scope.collapseTask = (): void => {
            this.collapseTask();
        }

        this.$scope.goToTasks = async (process: string, taskNumber: string) => {
            this.sessionService.openTab("app.management.activities", <INewTaskParameter>{ "PROCESS_NUMBER": process, "PARAMS.TASK_NUMBER": taskNumber });
        }

        this.$scope.generateTasks = (): void => {
            this.generateTasks();
        }
    }

    async initDependencies(): Promise<any> {        
        this.initCollapseEvents();
    }

    private initCollapseEvents() {
        this.$scope.$on('processTaskCollapse', () => {
            this.collapseTask();
        });

        const collapseTask = angular.element('#collapseTask');
        if (collapseTask) {
            collapseTask.on('shown.bs.collapse', (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    this.getTaskByProcess();
                    angular.element("#collapseTaskHeading").attr('aria-expanded', 'true');
                    // update collapse state
                    this.$newProcessScope.collapseState = { panel: ECollapseState.TASKS, released: false, nextState: null };
                    this.$newProcessScope.repositionPanels(ECollapseState.TASKS, true);
                    this.$newProcessScope.disableElements(this.$newProcessScope.operation == EOperation.VIEW);
                }
            });
            collapseTask.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseTaskHeading").attr('aria-expanded', 'false');
                }
            });
        }
    }

    private async collapseTask() {
        try {
            if (this.$newProcessScope.collapseState.released || this.$newProcessScope.collapseState.panel == ECollapseState.TASKS) {
                const collapseTask = angular.element("#collapseTask");
                if (collapseTask) {
                    const isCollapsed = angular.element("#collapseTaskHeading").attr("aria-expanded") == "true";
                    if (isCollapsed) {
                        this.$newProcessScope.collapseState.released = true;
                    }
                    collapseTask['collapse']('toggle');
                    if (isCollapsed) this.$newProcessScope.repositionPanels(ECollapseState.TASKS);
                }
            } else {
                this.$newProcessScope.collapseState.nextState = ECollapseState.TASKS;
                this.$newProcessScope.releaseCollapse();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getTaskByProcess() {
        try {
            if (!this.$newProcessScope.model.PROCESS_NUMBER) throw new Error("Número do Processo está nulo.");

            this.formService.block();

            const taskReq: IMonacoRequest = {
                data: {
                    processNumber: this.$newProcessScope.model.PROCESS_NUMBER,
                    timeout: 120000,
                },
                route: '/newTask/list/process',
                timeout: 120000,
            }

            const result = await this.taskService.post(taskReq);

            if (result && result.data && result.data.data) this.$scope.taskList = result.data.data;

            //order by (END or ESTIMATED_DATE)
            const tasks: ITasks[] = this.$scope.taskList;
            for (let task of tasks) {
                task.ORDER_DATE_START = (task.START_DATE) ? task.START_DATE : task.ESTIMATED_START_DATE;
                task.ORDER_DATE_END = (task.END_DATE) ? task.END_DATE : task.ESTIMATED_END_DATE;
            }
            this.$scope.orderByField = "ORDER_DATE_END";
            this.$scope.reverseSort = false;
            this.$scope.taskList = tasks;

            this.formService.unblock();
        } catch (ex) {
            this.$scope.taskList = [];
            this.formService.unblock();
        }
    }

    private async generateTasks() {
        try {
            this.formService.block();

            const processSituationId = this.$newProcessScope.model.SITUATION.ID;
            const processNumber = this.$newProcessScope.model.PROCESS_NUMBER;

            const result = await this.operationalService.post("/processTask/generateTask", {processNumber, processSituationId }, 120000);
            const msgSuccess = this.formService.getTranslate('OPERATIONAL.TASKS_GENERATED');
            if (result && result.data && result.data.data) this.formService.notifySuccess(msgSuccess);
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }

    }
}
setNgAnimate.$inject = ['$animate'];
export function setNgAnimate($animate) {
    return {
        link: function ($scope, $element, $attrs) {
            $scope.$watch(function () {
                return $scope.$eval($attrs.setNgAnimate, $scope);
            }, function (valnew, valold) {
                 $animate.enabled(valnew, $element);
            });
        }
    };
}
import * as angular from "angular";
import { SSEService } from "@appServices/SSEService";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog, } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { ISessionService } from "@services/SessionService";
import { ProductService } from "@services/ProductService";
import { ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IRepresentativeParamsModel } from "@models/interface/product/RepresentativeParamsModel";
import { IRoutingPointSelector } from "@models/interface/operational/IProductIntegrationSelector";
import { ISelectorModel } from "@models/mongo/SelectorModel";
import { ETypeLocalId, EProductId, EOperation } from "@enums/GenericData";
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { SelectorModel } from "../../common/model/SelectorModel";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { ILinkParameter } from "../../common/model/ModelParameter";
import { ILegalPersonListCustomFilter } from "../model/LegalPersonModel";
import { IPhysicalPersonListCustomFilter } from "../model/PhysicalPersonModel";
import { EPhysicalPersonSpecializationId } from "@enums/PhysicalPerson";
import { HelperService } from "@services/HelperService";

export interface IMonacoRequest<T = any> {
    route?: string;
    operation?: string;
    data?: T;
    timeout?: number;
    user?: any;
}
interface IRepresentativeList extends SelectorModel {
    ID_LEGAL_PERSON: number,
    ID_PHYSICAL_PERSON: number,
}
interface IRepresentativeParamsScope extends IGridFormServiceScope {
    peopleList: any[];
    model: IRepresentativeParamsModel;
    representativeList: IRepresentativeList[];
    typeList: SelectorModel[];
    productList: SelectorModel[];
    agentList: SelectorModel[];
    typeCargoList: SelectorModel[];
    typeRoutingPointList: SelectorModel[];
    routingPointList: IRoutingPointSelector[];
    processTypeList: SelectorModel[];
    accountList: SelectorModel[];
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IRepresentativeParamsModel;
    representativeSelected: IRepresentativeSelctor;
    user: any;
    sessionService: ISessionService;

    editRepresentativeParams: (representativeParams: IRepresentativeParamsModel) => Promise<void>;
    viewRepresentativeParams: (representativeParams: IRepresentativeParamsModel) => Promise<void>;
    viewLogRepresentativeParams: (representativeParams: IRepresentativeParamsModel) => Promise<void>;
    copyRepresentativeParams: (representativeParams: IRepresentativeParamsModel) => Promise<void>;
    getRoutingPointListByName: (name: string) => Promise<void>;
    getAccountListByName: (name: string) => Promise<void>;
    goToRoutingPoint: () => void;
    goToAccount: () => void;
    getRepresentativeList: (search: string) => Promise<void>;
    masterDirectChange: () => void;
    changeTypeRoutingPoint: () => void;
    filterTypeRoutingPointList: () => SelectorModel[];
    buildRoutingPointViewGrid: (routingPoint: IRoutingPointSelector[]) => string;
}

interface IRepresentativeSelctor extends SelectorModel {
    ID_REPRESENTATIVE: number;
}

export class RepresentativeParamsRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IRepresentativeParamsScope;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private RestService: IRestService;
    private SSEService: SSEService;
    private gridName: string;
    private ProductService: ProductService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IRepresentativeParamsScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.RestService = $injector.get('RestService');
        this.ProductService = $injector.get("ProductService");
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.gridName = 'GRID_REPRESENTATIVE_PARAMS';
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'representativeParams', 'Parametrização de Representantes', true);
            await this.initGrid(this.gridName, '/representativeParams/list', true, true, null, true, true);

        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editRepresentativeParams = async (representativeParams: IRepresentativeParamsModel): Promise<void> => {
            let blockedObject = {
                ID: representativeParams.ID,
                NAME: representativeParams.ID.toString(),
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || representativeParams.ID !== this.$scope.model.ID) this.$scope.view(representativeParams);
                } else if (this.$scope.operation !== EOperation.EDIT || representativeParams.ID !== this.$scope.model.ID) {
                    this.$scope.edit(representativeParams);
                }
            };
        }

        this.$scope.getRepresentativeList = async (search: string) => {
            let peopleList = [];
            if (search && search.length >= 3) {
                const legalPersonList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.REPRESENTATIVE], search: search })
                if (legalPersonList && legalPersonList.length) peopleList = peopleList.concat(legalPersonList.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.NAME, ID_LEGAL_PERSON: legalPerson.ID, ID_PHYSICAL_PERSON: null } }));
                const physicalPersonList = await this.getPhysicalPersonListByName({ specializations: [EPhysicalPersonSpecializationId.REPRESENTATIVE], roles: [], search: search });
                if (physicalPersonList && physicalPersonList.length) peopleList = peopleList.concat(physicalPersonList.map(physicalPerson => { return { ID: physicalPerson.ID, NAME: physicalPerson.NAME, ID_LEGAL_PERSON: null, ID_PHYSICAL_PERSON: physicalPerson.ID } }));
            }
            this.$scope.representativeList = peopleList;
        }


        this.$scope.viewRepresentativeParams = async (representativeParams: IRepresentativeParamsModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(representativeParams);
        }

        this.$scope.viewLogRepresentativeParams = async (representativeParams: IRepresentativeParamsModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(representativeParams);
        }

        this.$scope.copyRepresentativeParams = async (representativeParams: IRepresentativeParamsModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(representativeParams);
        }

        //routing point
        this.$scope.goToRoutingPoint = () => {
            this.$scope.sessionService.openTab("app.registration.routingPoint", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.ROUTING_POINTS, null, 'ID') });
        }

        //account
        this.$scope.goToAccount = (): void => {
            this.$scope.sessionService.openTab("app.commercial.account", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.ACCOUNTS, null, "ID") });
        }

        this.$scope.getRoutingPointListByName = async (name: string) => {
            let routingPointList = [];
            if (name && name.length >= 3) {
                routingPointList = await this.getRoutingPointListByName(name);
            }
            this.$scope.routingPointList = routingPointList;
        }

        this.$scope.getAccountListByName = async (name: string) => {
            let accountList = [];
            if (name && name.length >= 3) {
                accountList = await this.getAccountListByName(name);
            }
            this.$scope.accountList = accountList;
        }

        this.$scope.masterDirectChange = () => {
            this.$timeout(() => {
                this.$scope.selectorValidity('representativeParamsLocalAgentID');
                this.$scope.selectorValidity('representativeParamsExtAgent');
            });
        }

        this.$scope.filterTypeRoutingPointList = (): SelectorModel[] => {
            return this.filterTypeRoutingPointList();
        }

        this.$scope.changeTypeRoutingPoint = (): void => {
            this.changeTypeRoutingPoint();
        }

        this.$scope.buildRoutingPointViewGrid = (routingPoint: IRoutingPointSelector[]) => {
            if (!routingPoint) return "";
            const routingPointToConcat = routingPoint.map(routingPoint => `${routingPoint.CODE} (${routingPoint.TYPE.NAME})`);
            return this.$scope.getCONCAT(routingPointToConcat);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewRepresentativeParams(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editRepresentativeParams(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.setCopy(true);grid.appScope.copyRepresentativeParams(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogRepresentativeParams(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy),
            enableCellEdit: true,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) {
            column.filter = (column.filter) ? column.filter : { condition: this.$gridService.filterSelectObject };
            gridColumns.addColumn(column);
        }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colRepresentative: IMonacoColumnDef = { name: "REPRESENTATIVE.NAME", displayName: "GENERAL.REPRESENTATIVE", width: 100 }
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.NAME", displayName: "BASIC_DATA.PRODUCT", width: 100, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PRODUCT)}}</div>' };
            const colTypeRoutingPoint: IMonacoColumnDef = { name: "TYPE_ROUTING_POINTS.NAME", displayName: "ROUTE.LOCAL_TYPE", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TYPE_ROUTING_POINTS, null, "NAME")}}</div>' };
            const colRoutingPoint: IMonacoColumnDef = { name: "ROUTING_POINTS.CODE", displayName: "ROUTE.ROUTING_POINT", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.buildRoutingPointViewGrid(row.entity.ROUTING_POINTS)}}</div>' };
            const colTypeCargo: IMonacoColumnDef = { name: "TYPE_CARGO.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TYPE_CARGO, null, "NAME")}}</div>' };
            const colAccount: IMonacoColumnDef = { name: "ACCOUNTS.NAME", displayName: "BASIC_DATA.CLIENT", width: 300, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNTS, null, "NAME")}}</div>' };
            const colProcessType: IMonacoColumnDef = { name: "PROCESS_TYPE.NAME", displayName: "BASIC_DATA.FILE_TYPE", width: 100, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PROCESS_TYPE, null, "NAME")}}</div>' };
            const colMasterDirect: IMonacoColumnDef = { name: "MASTER_DIRECT.NAME", displayName: "BASIC_DATA.STRAIGHT_BL", width: 80 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 100, cellFilter: "YesOrNo" };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "GENERAL.ID", width: 80 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'ID_REPRESENTATIVE':
                        columnDefs.push(colRepresentative)
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'TYPE_ROUTING_POINTS':
                        columnDefs.push(colTypeRoutingPoint);
                        break;
                    case 'ROUTING_POINTS':
                        columnDefs.push(colRoutingPoint);
                        break;
                    case 'TYPE_CARGO':
                        columnDefs.push(colTypeCargo);
                        break;
                    case 'PROCESS_TYPE':
                        columnDefs.push(colProcessType);
                        break;
                    case 'ACCOUNTS':
                        columnDefs.push(colAccount);
                        break;
                    case 'MASTER_DIRECT':
                        columnDefs.push(colMasterDirect);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                }
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            ID_REPRESENTATIVE: null,
            REPRESENTATIVE: null,
            PRODUCT: null,
            TYPE_ROUTING_POINTS: null,
            ROUTING_POINTS: null,
            TYPE_CARGO: null,
            ACCOUNTS: null,
            PROCESS_TYPE: null,
            MASTER_DIRECT: null,
            ID_LEGAL_PERSON: null,
            ID_PHYSICAL_PERSON: null,
            ACTIVE: true,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null
        };

    }

    initDependencies(): Promise<any> {
        const self: RepresentativeParamsRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getTypeList(),
                self.getProductList(),
                self.getTypeCargoList(),
                self.getProcessTypeList(),
                self.getTypeLocalEventList(),
            ]).then((result: any) => {
                self.$scope.typeList = result[0];
                self.$scope.productList = result[1];
                self.$scope.typeCargoList = result[2];
                self.$scope.processTypeList = result[3];
                self.$scope.typeRoutingPointList = result[4];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private changeTypeRoutingPoint(): void {
        setTimeout(() => {
            this.$scope.selectorValidity('representativeParamsCountryID');
        }, 100);
    }

    private async getTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/representativeParams_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTypeCargoList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_cargo`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getProcessTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/process_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTypeLocalEventList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_local_event`, null);
        return generic && generic.data ? generic.data : [];
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlDataManager = this.config.productUrl + baseRoute;
        return urlDataManager;
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: 'REPRESENTATIVE',
                LABEL: 'GENERAL.REPRESENTATIVE'
            },
            {
                PROPERTY: 'PRODUCT',
                LABEL: 'BASIC_DATA.PRODUCT'
            },
            {
                PROPERTY: 'TYPE_ROUTING_POINT',
                LABEL: 'ROUTE.LOCAL_TYPE'
            },
            {
                PROPERTY: 'ROUTING_POINTS',
                LABEL: 'ROUTE.ROUTING_POINT'
            },
            {
                PROPERTY: 'TYPE_CARGO',
                LABEL: 'BASIC_DATA.CARGO_TYPE'
            },
            {
                PROPERTY: 'ACCOUNTS',
                LABEL: 'BASIC_DATA.CLIENT'
            },
            {
                PROPERTY: 'PROCESS_TYPE',
                LABEL: 'BASIC_DATA.FILE_TYPE'
            },
            {
                PROPERTY: 'MASTER_DIRECT',
                LABEL: 'BASIC_DATA.STRAIGHT_BL'
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'ID',
                LABEL: 'GENERAL.ID'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            },
            {
                PROPERTY: 'CODE',
                LABEL: 'GENERAL.CODE'
            },
            {
                PROPERTY: 'TYPE',
                LABEL: 'GENERAL.TYPE'
            },
            {
                PROPERTY: 'TYPE_ROUTING_POINTS',
                LABEL: 'ROUTE.LOCAL_TYPE'
            }
        ]
        return props;
    }

    private filterTypeRoutingPointList(): SelectorModel[] {
        const result: SelectorModel[] = [];
        try {
            if (this.$scope.typeRoutingPointList && this.$scope.typeRoutingPointList.length > 0 && this.$scope.model && this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.length) {
                const terminalType = this.$scope.typeRoutingPointList.find(item => item.ID == ETypeLocalId.TERMINAL);
                const doorType = this.$scope.typeRoutingPointList.find(item => item.ID == ETypeLocalId.DOOR);
                if (terminalType) result.push(terminalType);
                if (doorType) result.push(doorType);
                if (this.$scope.model.PRODUCT.some(product => product.ID == EProductId.AIR_EXPORT || product.ID == EProductId.AIR_IMPORT)) {
                    result.push(this.$scope.typeRoutingPointList.find(item => item.ID == ETypeLocalId.AIRPORT));
                } else if (this.$scope.model.PRODUCT.some(product => product.ID == EProductId.MARITIME_EXPORT || product.ID == EProductId.MARITIME_IMPORT)) {
                    result.push(this.$scope.typeRoutingPointList.find(item => item.ID == ETypeLocalId.PORT));
                }
            }

        } catch (ex) {
            this.handleError(ex);
        } finally {
            return result;
        }
    }

    private async getLegalPerson(filter: ILegalPersonListCustomFilter): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            if (filter && filter.search && filter.search.length >= 2) {
                this.formService.block();
                const request: IMonacoRequest = {
                    data: {
                        ...filter
                    },
                    route: `/legalPerson/list/custom/operational`,
                    timeout: 120000,
                }
                const rc = await this.ProductService.post(request);
                if (rc && rc.data && rc.data.data && rc.status == 200) {
                    result = rc.data.data.map(x => { return { ID: x.ID, NAME: x.NAME } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getRoutingPointListByName(name?: string): Promise<IRoutingPointSelector[]> {
        let result = [];
        if (!this.$scope.model.TYPE_ROUTING_POINTS || this.$scope.model.TYPE_ROUTING_POINTS.length === 0) throw Error(`Select a type first`);
        this.formService.block();
        try {
            const types = this.$scope.model.TYPE_ROUTING_POINTS.map(item => item.ID);
            const routingPoints = await this.RestService.newObjectPromise(`${this.$baseUrl}/routingPoint/list/custom`, { name: name, types }, 30000, false);
            if (routingPoints) result = routingPoints.map(routingPoint => { return { ID: routingPoint.ID, NAME: routingPoint.NAME, CODE: routingPoint.CODE, TYPE: routingPoint.TYPE } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getAccountListByName(name?: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const accounts = await this.RestService.newObjectPromise(`${this.$baseUrl}/account/list/custom`, { search: name }, 30000, false);
            if (accounts) result = accounts.map(account => { return { ID: account.ID, NAME: account.NAME, CODE: account.CODE } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }
    private async getLegalPersonListByName(legalPersonFilter: ILegalPersonListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        try {
            this.formService.block();
            const legalPersons = await this.ProductService.post({ route: "/legalPerson/list/custom", data: legalPersonFilter });
            if (legalPersons && legalPersons.data && legalPersons.data.data) result = legalPersons.data.data.map(legalPerson => { return { ID: parseInt(legalPerson.ID), NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getPhysicalPersonListByName(physicalPersonFilter?: IPhysicalPersonListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const physicalPersons = await this.ProductService.post({ route: "/physicalPerson/list/custom", data: physicalPersonFilter });
            if (physicalPersons && physicalPersons.data && physicalPersons.data.data) result = physicalPersons.data.data.map(physicalPerson => { return { ID: parseInt(physicalPerson.ID), NAME: physicalPerson.NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW");
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT");
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            await this.clearFields(this.$scope.model, ['CONCATENATED']);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === "edit" ? "update" : "insert";
        return {
            route: `/representativeParams/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: this.$scope.scopeBeforeSave
        };
    }
}
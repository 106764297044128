import angular = require('angular');
import { IModalInstanceService } from "angular-ui-bootstrap";
import { IRestService } from "@services/RestService";
import { FormService2, IFormServiceScope } from '@services/FormService2';
import { IMonacoRequest } from "@services/GridFormService";
import { IModalService } from '@services/ModalService';
import { ISessionService } from '@services/SessionService';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IApplicationList } from '@models/interface/product/ApplicationModel';
import { IPercentualComposition, IWeightRangeCharge } from '@models/interface/product/OfferCharge';
import { IChargeNameList, IParams } from "@models/interface/product/ChargeNameModel";
import { ITariffFreightExchangeData } from '@models/interface/product/TariffFreightModel';
import { IWeightRangeListCustomFilter, IWeightRangeSelector } from '@models/interface/product/WeightRangeModel';
import { IWeightRangeResponse } from '@models/interface/product/OfferChargeWeightRange';
import { IEquipmentSelector } from '@models/interface/product/EquipmentModel';
import { EPaymentNatureId, EApplicationComplementId, EProductId, ETransactionId, EChargeOriginId, EApplicationInternalSequence, EOperation, EOfferChangeConfirmationReason, EDataOriginTypeId, EEventType, EApplicationId } from '@enums/GenericData';
import { IOfferChargeModel, IOfferCharge, IHolderSelector, IOfferPaymentReceiving, IOfferPaymentReceivingCharge, IOfferTabsChargeCalculateTotalResult, IOfferPercentualComposition, IOfferContract } from '../../commercial/model/OfferModel';
import { IOfferScope, ECollapseState } from '../../commercial/controller/OfferRegisterController';
import { ITableOptions } from "../../app/directives/monaco-data-table";
import { SelectorModel } from "../../common/model/SelectorModel";
import { ILinkParameter } from '../../common/model/ModelParameter';
import { ITariffLocalExchangeData } from '../../product/model/TariffLocalModel';
import { ITariffDomesticExchangeData } from '../../product/model/TariffDomesticModel';
import { ILegalPersonListCustomFilter } from '../../registration/model/LegalPersonModel';
import { IPhysicalPersonListCustomFilter } from '../../registration/model/PhysicalPersonModel';
import { IOfferChargeName } from '../model/OfferModel';
import { IOfferChangeConfirmation } from '@models/interface/product/OfferChangeConfirmation';
import { ISelectorModel } from '@models/mongo/SelectorModel';
import { ProductService } from '@services/ProductService';
import { HelperService } from "@services/HelperService";
import { IOfferValidity } from '@models/interface/product/Offer';
import { ITariffTransactionManagementErrorLog } from '@models/interface/product/TariffTransactionManagementError';
import { OfferChargeHelperController } from '../../common/OfferChargeHelperController';

interface IOfferChargeCollapseParams {
    _id?: string;
}

interface IOfferChargeScope extends angular.IScope {
    log: IViewLog;
    errorsOnFilter: ITariffTransactionManagementErrorLog[];
    model: IOfferChargeModel;
    oldModel: IOfferChargeModel;
    chargesTableOptions: ITableOptions;
    chargesTableList: IOfferCharge[];
    chargeOriginList: SelectorModel[];
    customLogProperties: ICustomLogProperties[];
    reasonModal: IReasonUpdateModal;
    offerChangeConfirmation: IOfferChangeConfirmation[];
    reasonChangeList: SelectorModel[];
    eventList: SelectorModel[];
    collapseCharges: () => void;
    viewLog: (allLogs: boolean, uuid?: string) => void;
    viewErrorLog: () => void;
    isCollapseIn(): boolean;
    openChargesPerWeightRangeModal: () => void;
    openPreviewInvoices: () => void;
    showInvoiceOriginalValues(invoice: IOfferInvoice, type: string): string
    showInvoiceFactors(invoice: IOfferInvoice, types: string[]): string
    viewLogConfirmation: () => void;
}

interface IChargeModalScope extends IFormServiceScope {
    currentIndex: number;
    currentDisplayIndex: number;
    charge: IOfferCharge;
    oldCharge: IOfferCharge;
    operation: string;
    chargeNameList: SelectorModel[];
    chargeNameExhibitionList: SelectorModel[];
    applicationList: IApplicationList[];
    paymentEquipmentList: IEquipmentSelector[];
    receivingEquipmentList: IEquipmentSelector[];
    vehicleTypeList: SelectorModel[];
    holderList: IHolderSelector[];
    holderPaymentList: IHolderSelector[];
    holderReceivingList: IHolderSelector[];
    weightRangeList: IWeightRangeSelector[];
    paymentCompositionList: IOfferPercentualComposition[];
    receivingCompositionList: IOfferPercentualComposition[];
    chargeShownForList: SelectorModel[];
    weightRangeActiveId: string;
    isPaymentWeightRangeApplication: boolean;
    isReceivingWeightRangeApplication: boolean;
    hasOutDateCharge: boolean;
    chargesOutDate: IOfferCharge[];
    isOutDateCharge: boolean;
    isManualCharge: boolean;
    isReceivingChargeNegotiated: boolean;
    applyCharge: () => Promise<void>;
    getApplicationListByName: (search: string) => Promise<void>;
    getChargeNameListByName: (search: string) => Promise<void>;
    getHolderListByName: (search: string) => Promise<void>;
    getCurrencyListByName: (search: string) => Promise<void>;
    goToTariffLocal: (id: number) => void;
    goToTariffDomestic: (id: number) => void;
    goToTariffFreight: (id: number) => void;
    goToInlandRoutes: (id: number) => void;
    goToFreightRoutes: (id: number) => void;
    goToTariffComplementary: (id: number) => void;
    isNotApplicableComplement: (applicationComplement: SelectorModel) => boolean;
    isWeightRangeComplement: (applicationComplement: SelectorModel) => boolean;
    isEquipmentComplement: (applicationComplement: SelectorModel) => boolean;
    isVehicleComplement: (applicationComplement: SelectorModel) => boolean;
    isAirProduct: () => boolean;
    isTransactionOther: (transaction: SelectorModel) => boolean;
    chargeChange: (selected: IOfferChargeName) => void;
    weightRangePaymentValueUnitaryChange: (weightRangeId: string) => void;
    weightRangeReceivingValueUnitaryChange: (weightRangeId: string) => void;
    hasPreviousCharge: (currentIndex: number) => boolean;
    hasNextCharge: (currentIndex: number) => boolean;
    applicationChange: (type: string, applicationIdBefore: string) => void;
    transactionChange: (type: string) => void;
    calculateTotal: (paymentReceivingCharge: IOfferPaymentReceiving, oldPaymentReceivingCharge: IOfferPaymentReceiving) => void;
    updatePaymentMin: () => void;
    updateReceivingMin: () => void;
    setReceivingNegotiated: (receivingCharge: IOfferPaymentReceiving) => void;
    previousCharge: (currentIndex: number) => void;
    nextCharge: (currentIndex: number) => void;
    closeChargeModal: () => void;
    prepareTextComposition: (item: IPercentualComposition, complete: boolean) => string;
    getModalityTransactionDefault: (type?: string, currencyId?: string) => void;
    changeHolder: (selected: IHolderSelector, paymentReceivingCharge: IOfferPaymentReceiving) => void;
    isApplicationScob: (internalSequence: string) => boolean;
    saveCharge: (currentDisplayIndex: number) => void;
    isNegotiated: (receivingCharge: IOfferPaymentReceiving) => boolean;
}

interface IOfferInvoice {
    _id: string;
    ID_OFFER: number;
    TYPE: number;
    CONTRACT: IOfferContract;
    HOLDER: SelectorModel;
    INVOICE: string;
    MODALITY: SelectorModel;
    TOTAL_CURRENT_CURRENCY: number;
    TRANSACTION: SelectorModel[];
    OFFER_CHARGE_PAYMENT: IOfferCharge[];
    OFFER_CHARGE_RECEIVING: IOfferCharge[];
}

interface IPreviewInvoiceModalScope extends angular.IScope {
    invoices: IOfferInvoice[];
    editViewCharge: (_id: string) => void;
    goToContract: (id: number) => void;
    showInvoiceFactors: (invoice: IOfferInvoice, types: string[]) => string;
}

interface IReasonUpdateModal {
    DESCRIPTION: string[];
    REASON_OBSERVATION: string;
    CHANGE_REASON: ISelectorModel;
    USER: string;
}

export class OfferChargeRegisterController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IOfferChargeScope;
    private $offerScope: IOfferScope;
    private $q: ng.IQService;
    private $filter: ng.FilterFactory;
    private $compile: angular.ICompileService;
    private $timeout: ng.ITimeoutService;
    private restService: IRestService;
    private $sce: angular.ISCEService;
    private formService: FormService2;
    private productService: ProductService;
    private modalService: IModalService;
    private sessionService: ISessionService;
    private modalChargeId: number;
    private propertiesToIgnore: string[];
    private modalPreviewInvoiceId: number;
    private modalReasonId: number;
    private helperService: HelperService;
    private offerChargeHelper: OfferChargeHelperController = null;
    private $injector: ng.Injectable<any>;

    constructor($injector: ng.Injectable<any>, $scope: IOfferChargeScope) {
        this.$scope = $scope;
        this.$injector = $injector;
        this.$offerScope = <IOfferScope>$scope.$parent.$parent;
        this.$q = $injector.get('$q');
        this.$sce = $injector.get('$sce');
        this.$filter = $injector.get('$filter');
        this.$compile = $injector.get('$compile');
        this.$timeout = $injector.get('$timeout');
        this.restService = $injector.get('RestService');
        this.productService = $injector.get('ProductService');
        this.helperService = $injector.get('HelperService');
        this.formService = this.$offerScope.formService;
        this.modalService = this.$offerScope.modalService;
        this.sessionService = this.$offerScope.sessionService;
        this.modalChargeId = 0;
        this.modalPreviewInvoiceId = 0;
        this.propertiesToIgnore = ["DISPLAY_INDEX", "selected", "uniqueIdControl"];
        this.initScopeFunctions();
    }

    async $onInit(): Promise<void> {
        try {
            this.formService.block();
            this.offerChargeHelper = new OfferChargeHelperController(this.$injector, this.$offerScope);

            this.$scope.chargesTableOptions = this.getChargesTableOptions();
            this.initModel();
            await this.initDependencies();
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private initModel(): void {
        this.$scope.model = {
            CHARGES: null
        };
        this.$scope.oldModel = angular.copy(this.$scope.model);
    }

    private initScopeFunctions(): void {
        this.$scope.collapseCharges = () => {
            this.collapseCharges();
        }

        this.$scope.openChargesPerWeightRangeModal = () => {
            this.openChargesPerWeightRangeModal();
        }

        this.$scope.openPreviewInvoices = () => {
            this.openPreviewInvoices();
        }

        this.$scope.viewLog = (allLogs: boolean, uuid?: string) => {
            this.viewLog(allLogs, uuid);
        }

        this.$scope.viewErrorLog = () => {
            this.offerChargeHelper.viewErrorLog();
        }

        this.$scope.showInvoiceOriginalValues = (invoice: IOfferInvoice, type: string): string => {
            return this.showInvoiceOriginalValues(invoice, type);
        }

        this.$scope.viewLogConfirmation = () => {
            this.viewLogConfirmation();
        }
    }

    async initDependencies(): Promise<any> {
        const self: OfferChargeRegisterController = this;

        this.initCollapseEvents();

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericValue("charge_origin"),
                self.getGenericValue("offer_change_confirmation_reason"),
                self.getGenericValue("event"),
            ]).then(async (result: any) => {
                self.$scope.chargeOriginList = result[0];
                self.$scope.reasonChangeList = result[1];
                self.$scope.eventList = result[2];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private initCollapseEvents() {
        this.$scope.$on('offerChargeCollapse', (params, customParams: IOfferChargeCollapseParams) => {
            this.collapseCharges(customParams);
        });

        const collapseCharge = angular.element("#collapseCharges");
        if (collapseCharge) {
            collapseCharge.on('shown.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#previewInvoices").show();
                    angular.element("#collapseChargeHeading").attr('aria-expanded', 'true');
                    // update collapse state
                    this.$offerScope.collapseState = { panel: ECollapseState.CHARGES, released: false, nextState: null };
                    this.$offerScope.repositionPanels('chargesRow', true);
                    await this.getOfferTabsCharge();
                    this.$timeout(() => {
                        const $table = angular.element("#chargesTable");
                        $table.bootstrapTable('multiSort', [{ sortName: "CHARGE_NAME.TYPE.ORDER", sortOrder: "asc" }, { sortName: "PAYMENT_CHARGE.TOTAL", sortOrder: "desc" }]);
                    }, 500);
                }
            });
            collapseCharge.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#previewInvoices").hide();
                    angular.element("#collapseChargeHeading").attr('aria-expanded', 'false');
                }
            });
        }
    }

    private async getGenericValue(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async collapseCharges(customParams?: IOfferChargeCollapseParams) {
        try {
            if (this.$offerScope.collapseState.released || this.$offerScope.collapseState.panel == ECollapseState.CHARGES) {
                const collapseCharge = angular.element("#collapseCharges");
                if (collapseCharge) {
                    const isCollapsed = angular.element("#collapseChargeHeading").attr("aria-expanded") == "true";
                    const product = this.$offerScope.model.PRODUCT;
                    if (isCollapsed) {
                        if (product && (product.ID == EProductId.AIR_EXPORT || product.ID == EProductId.AIR_IMPORT)) angular.element("#chargesWeightRangeCharge").hide();
                        angular.element("#filesTariff").hide();
                        angular.element("#detailTariff").hide();
                        angular.element("#previewInvoices").hide();
                        this.$offerScope.collapseState.released = true;
                    } else {
                        if (product && (product.ID == EProductId.AIR_EXPORT || product.ID == EProductId.AIR_IMPORT)) angular.element("#chargesWeightRangeCharge").show();
                        angular.element("#filesTariff").show();
                        angular.element("#detailTariff").show();
                        angular.element("#previewInvoices").show();
                        if (customParams && customParams._id) {
                            await this.getOfferTabsCharge();
                            const chargeIndex = this.$scope.model.CHARGES && this.$scope.model.CHARGES.length ? this.$scope.model.CHARGES.findIndex(charge => charge._id == customParams._id) : -1;
                            if (chargeIndex >= 0) this.$timeout(() => { this.editCharge(chargeIndex, chargeIndex); }, 1000);
                        }
                    }
                    if (this.$scope.chargesTableOptions.crudButtons && this.$scope.chargesTableOptions.crudButtons.add) this.$scope.chargesTableOptions.crudButtons.add.disabled = this.$offerScope.operation == EOperation.VIEW;
                    if (this.$scope.chargesTableOptions.crudButtons && this.$scope.chargesTableOptions.crudButtons.edit) {
                        this.$scope.chargesTableOptions.crudButtons.edit.label = this.formService.getTranslate(this.$offerScope.operation == EOperation.VIEW ? "GENERAL.GRID.VIEW" : "GENERAL.GRID.EDIT");
                        this.$scope.chargesTableOptions.crudButtons.edit.icon = this.$offerScope.operation == EOperation.VIEW ? "fa fa-search" : null;
                    }
                    if (this.$scope.chargesTableOptions.crudButtons && this.$scope.chargesTableOptions.crudButtons.remove) this.$scope.chargesTableOptions.crudButtons.remove.disabled = this.$offerScope.operation == EOperation.VIEW;
                    collapseCharge['collapse']('toggle');
                    if (isCollapsed) this.$offerScope.repositionPanels('chargesRow');
                }
            } else {
                this.$offerScope.collapseState.nextState = ECollapseState.CHARGES;
                this.$offerScope.releaseCollapse();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async openChargesPerWeightRangeModal(): Promise<void> {
        try {
            const modalID = this.modalService.newModal();
            const modalInstance: IModalInstanceService = await this.modalService.showChargesPerWeightRangeModal({ modalID: modalID, offerId: this.$offerScope.model.ID });
            const apply: boolean = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (apply) await this.getOfferTabsCharge();
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async openPreviewInvoices(): Promise<void> {
        try {
            this.modalPreviewInvoiceId = this.modalService.newModal();

            const modalInstance = await this.modalService.showModalInfo(
                {
                    modalID: this.modalPreviewInvoiceId,
                    scope: this.$scope,
                    formService: EOperation.VIEW,
                    template: require("../view/modals/offerPreviewInvoicesModal.html"),
                    size: 'vlg modal-overflow'
                },
                null
            );
            await this.buildPreviewInvoiceModalScope();

            modalInstance.rendered.then(async () => {
                const modalScope: IPreviewInvoiceModalScope = await this.modalService.getModalScope(this.modalPreviewInvoiceId);
                const tooltipModal = angular.element(".tooltipModalTop");
                if (tooltipModal) this.$compile(tooltipModal)(modalScope);
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async buildPreviewInvoiceModalScope(): Promise<IPreviewInvoiceModalScope> {
        const modalScope: IPreviewInvoiceModalScope = await this.modalService.getModalScope(this.modalPreviewInvoiceId);
        modalScope.invoices = await this.getOfferTabsPreviewInvoice();

        modalScope.editViewCharge = (_id: string) => {
            // 1 - close preview invoice modal
            this.modalService.closeModal(this.modalPreviewInvoiceId);
            this.modalPreviewInvoiceId = 0;
            // 2 - release charges panel
            this.collapseCharges();
            // 3 - open charge panel
            this.$offerScope.$broadcast("offerChargeCollapse", { _id });
        }

        modalScope.goToContract = (id: number) => {
            this.sessionService.openTab("app.finop.contract.financialContract", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        modalScope.showInvoiceFactors = (invoice: IOfferInvoice, types: string[]): string => {
            return this.showInvoiceFactors(invoice, types);
        }

        return modalScope;
    }

    private async getOfferTabsPreviewInvoice(): Promise<IOfferInvoice[]> {
        let invoices: IOfferInvoice[] = [];
        this.formService.block();
        try {
            const invoiceTab = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/management/invoice/${this.$offerScope.operation}/${this.$offerScope.model.ID}`, 30000, null, false);
            if (invoiceTab && invoiceTab.data) invoices = invoiceTab.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return invoices;
        }
    }

    private showInvoiceOriginalValues(invoice: IOfferInvoice, type: string): string {
        try {
            if (!invoice) throw Error('invoice is null');
            if (!type) throw Error('type is null');
            if (type != 'PAYMENT' && type != "RECEIVING") throw Error('type is invalid');
            let lista = [];
            let result = '';
            if (invoice[`OFFER_CHARGE_${type}`]) {
                for (const charge of invoice[`OFFER_CHARGE_${type}`]) {
                    if (charge[`${type}_CHARGE`] && charge[`${type}_CHARGE`].CURRENCY) {
                        const has = lista.find(item => item.CURRENCY == charge[`${type}_CHARGE`].CURRENCY.CODE);
                        if (has) {
                            has.VALUE += charge[`${type}_CHARGE`].TOTAL;
                        } else {
                            lista.push({ CURRENCY: charge[`${type}_CHARGE`].CURRENCY.CODE, VALUE: charge[`${type}_CHARGE`].TOTAL });
                        }
                    }
                }
            }

            for (const item of lista) {
                result += `${item.CURRENCY} ${this.$filter("number")(item.VALUE, 2)};`
            }
            result = result.slice(0, -1);
            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private showInvoiceFactors(invoice: IOfferInvoice, types: string[]): string {
        try {
            if (!invoice) throw Error('invoice is null');
            if (!types) throw Error('type is null');

            let lista = [];
            let result = '';
            for (const type of types) {
                if (invoice[`OFFER_CHARGE_${type}`]) {
                    for (const charge of invoice[`OFFER_CHARGE_${type}`]) {
                        if (charge[`${type}_CHARGE`] && charge[`${type}_CHARGE`].CURRENCY) {
                            if (charge[`${type}_CHARGE`].CURRENCY.CODE != 'BRL') {
                                const has = lista.find(item => charge[`${type}_CHARGE`].CURRENCY && item.CURRENCY == charge[`${type}_CHARGE`].CURRENCY.CODE);
                                if (!has) {
                                    lista.push({ CURRENCY: charge[`${type}_CHARGE`].CURRENCY.CODE, VALUE: (charge[`${type}_CHARGE`].TOTAL_FACTOR ? charge[`${type}_CHARGE`].TOTAL_FACTOR : 1), TABLE: (charge[`${type}_CHARGE`].TABLE ? charge[`${type}_CHARGE`].TABLE.NAME : ''), FACTOR: charge[`${type}_CHARGE`].FACTOR, SPREAD: charge[`${type}_CHARGE`].SPREAD });
                                }
                            }

                        }
                    }
                }
            }
            for (const item of lista) {
                result += `${item.CURRENCY} ${this.$filter("number")(item.VALUE, 4)} <i class="fa fa-info-circle text-cyano tooltipModalTop" tooltip-append-to-body="true" aria-hidden="true" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.TABLE_WITH_FACTOR_SPREAD' | translate: {table: '${item.TABLE}', factor: '${this.$filter("number")(item.FACTOR, 4)}', spread: '${this.$filter("number")(item.SPREAD, 2)}'} }}"></i>;`
            }
            result = result.slice(0, -1);
            return this.$sce.trustAsHtml(result);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async viewLog(allLogs: boolean, uuid?: string) {
        try {
            if (!allLogs && !uuid) {
                const msgError = this.formService.getTranslate('GENERAL.NO_CHARGE_SELECTED_CHECK_LOGS');
                this.formService.notifyError(msgError);
                return;
            }
            this.formService.block();
            let log: IViewLog = {
                operation: 'history',
                number: this.$offerScope.model.ID.toString(),
                list: [],
                show: true,
                showCloseButton: false,
                searchQuery: '',
                originalList: [],
            }

            this.requestHistory(log.number, uuid).then(result => {
                log.list = result.data;
                log.originalList = angular.copy(log.list);
                this.$scope.log = log;
                this.$scope.customLogProperties = this.getCustomLogProperties();
                angular.element('#log-viewer').removeClass('ng-hide');
                this.formService.unblock();
            }).catch(ex => {
                this.formService.handleError(ex);
            });

            const modalId = this.modalService.newModal();
            await this.modalService.showModalConfirmation(
                {
                    modalID: modalId,
                    scope: this.$scope,
                    template: require('../view/modals/offerLog.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG"
                }
            );
            this.modalService.closeModal(modalId);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private requestHistory(id: string, uuid?: string): Promise<any> {
        return this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/charge/viewLog/${id}${uuid ? '/' + uuid : ''}`, 10000, null, false);
    }

    private async getOfferTabsCharge(): Promise<void> {
        this.formService.block();
        try {
            const chargeTab = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/charge/${this.$offerScope.operation}/${this.$offerScope.model.ID}`, 30000, null, false);
            if (chargeTab && chargeTab.data) {
                for (const charge of chargeTab.data) {
                    if (charge.CHARGE_NAME && charge.CHARGE_NAME.TYPE) {
                        const chargeOrigin = this.$scope.chargeOriginList && this.$scope.chargeOriginList.length ? this.$scope.chargeOriginList.find(chargeOrigin => chargeOrigin.ID == charge.CHARGE_NAME.TYPE.ID) : null;
                        if (chargeOrigin) charge.CHARGE_NAME.TYPE.ORDER = chargeOrigin.ORDER;
                    }
                }
                this.$scope.model.CHARGES = chargeTab.data;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.oldModel = angular.copy(this.$scope.model);
            this.$scope.chargesTableOptions.load(null, true);
            this.formService.unblock();
        }
    }

    private getChargesTableOptions(): ITableOptions {
        return {
            persistName: "offerCharges",
            pagination: false,
            search: true,
            advancedSearch: false,
            showSearchClearButton: true,
            clickToSelect: true,
            singleSelect: true,
            showLoading: true,
            showColumns: true,
            showColumnsSearch: true,
            showColumnsToggleAll: true,
            showFullscreen: true,
            showToggle: false,
            showMultiSort: true,
            showMultiSortButton: true,
            height: '100%',
            filterControl: false,
            fixedColumns: true,
            fixedNumber: 3,
            sortable: true,
            serverSort: false,
            crudButtons: {
                add: { fn: async () => { this.addCharge(); }, name: "addCharge" },
                edit: { fn: async (index: number, displayIndex: number) => { this.editCharge(index, displayIndex); }, name: "editCharge" },
                scob: { fn: async (index: number) => { this.applyScob(index); }, name: "applyScob" },
            },
            customToolbarButtons: [
                { fn: async () => { this.viewLog(true) }, label: "", name: "log", class: "btn btn-xs btn-success", icon: "fa fa-history", title: "", disabled: this.$offerScope.operation == EOperation.VIEW },
            ],
            formatAddLevel: () => this.formService.getTranslate("GENERAL.ADD_LEVEL"),
            formatCancel: () => this.formService.getTranslate("GENERAL.CLOSE"),
            formatColumn: () => this.formService.getTranslate("GENERAL.COLUMNS"),
            formatDeleteLevel: () => this.formService.getTranslate("GENERAL.REMOVE_LEVEL"),
            formatMultipleSort: () => this.formService.getTranslate("GENERAL.MULTIPLE_SORT"),
            formatOrder: () => this.formService.getTranslate("GENERAL.ORDER"),
            formatSort: () => this.formService.getTranslate("GENERAL.SORT"),
            formatSortBy: () => this.formService.getTranslate("GENERAL.SORT_BY"),
            formatSortOrders: () => { return { asc: this.formService.getTranslate("GENERAL.ASCENDING_SORT"), desc: this.formService.getTranslate("GENERAL.DESCENDING_SORT") } },
            formatThenBy: () => this.formService.getTranslate("GENERAL.AND_BY"),
            headerStyle: (column: BootstrapTableColumn) => {
                let headerStyleObj = {};
                if (column.field.search("PAYMENT_CHARGE") >= 0 || column.field.search("CHARGE_NAME.DISPLAY_PAYMENT") >= 0) headerStyleObj = { css: { 'background': "#f2dede" } };
                else if (column.field.search("RECEIVING_CHARGE") >= 0 || column.field.search("CHARGE_NAME.DISPLAY_RECEIVING") >= 0) headerStyleObj = { css: { 'background': "#dff0d8" } };
                return headerStyleObj;
            },
            rowStyle(row: IOfferCharge, index: number): any {
                let style = {};
                if (row.PAYMENT_CHARGE && row.PAYMENT_CHARGE.CHARGE && row.PAYMENT_CHARGE.CHARGE.APPLICATION && row.PAYMENT_CHARGE.CHARGE.APPLICATION.ID == EApplicationId.SCOB &&
                    row.RECEIVING_CHARGE && row.RECEIVING_CHARGE.CHARGE && row.RECEIVING_CHARGE.CHARGE.APPLICATION && row.RECEIVING_CHARGE.CHARGE.APPLICATION.ID == EApplicationId.SCOB) {
                    style = { css: { 'color': "#D3D3D3" } };
                }
                return style;
            },
            onCheck: (row, element): boolean => {
                this.$scope.chargesTableOptions.crudButtons.scob.disabled = false;
                if (row.PAYMENT_CHARGE && row.PAYMENT_CHARGE.CHARGE && row.PAYMENT_CHARGE.CHARGE.APPLICATION && row.PAYMENT_CHARGE.CHARGE.APPLICATION.ID == EApplicationId.SCOB &&
                    row.RECEIVING_CHARGE && row.RECEIVING_CHARGE.CHARGE && row.RECEIVING_CHARGE.CHARGE.APPLICATION && row.RECEIVING_CHARGE.CHARGE.APPLICATION.ID == EApplicationId.SCOB) {
                    this.$scope.chargesTableOptions.crudButtons.scob.disabled = true;
                }
                angular.element(".logindividual").removeClass("disabled");
                angular.element(".logindividual").removeClass("btn-not-clickable");
                return true;
            },
            onUncheck: (row): boolean => {
                angular.element(".logindividual").addClass("disabled");
                angular.element(".logindividual").addClass("btn-not-clickable");
                return true;
            },
            showColumnsRename: [
                { dataField: "DATA_ORIGIN_TYPE", title: this.formService.getTranslate("GENERAL.TYPE") },
                { dataField: "CHARGE_NAME.TYPE.ORDER", title: this.formService.getTranslate("BASIC_DATA.CHARGE") },
                { dataField: "PAYMENT_CHARGE.CHARGE.DATA_ORIGIN_TYPE", title: this.formService.getTranslate("GENERAL.TYPE_C") },
                { dataField: "PAYMENT_CHARGE.CHARGE.CHARGE_NAME_EXHIBITION", title: this.formService.getTranslate("BASIC_DATA.DISPLAY_CHARGE") },
                { dataField: "PAYMENT_CHARGE.CHARGE.CHARGE_NAME_EXHIBITION.NAME", title: this.formService.getTranslate("FINANCIAL.CHARGE_DISPLAY_NAME_C") },
                { dataField: "PAYMENT_CHARGE.CHARGE", title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS_C") },
                { dataField: "PAYMENT_CHARGE.CURRENCY", title: this.formService.getTranslate("GENERAL.CURRENCY_C") },
                { dataField: "PAYMENT_CHARGE.AMOUNT", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.QUANTITY_C") },
                { dataField: "PAYMENT_CHARGE.VALUE_UNITARY", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY_C") },
                { dataField: "PAYMENT_CHARGE.VALUE_MIN", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM_C") },
                { dataField: "PAYMENT_CHARGE.TOTAL", title: this.formService.getTranslate("GENERAL.TOTAL_C") },
                { dataField: "PAYMENT_CHARGE.MODALITY", title: this.formService.getTranslate("FINANCIAL.CHARGE_PAYMENT_METHOD_C") },
                { dataField: "PAYMENT_CHARGE.TRANSACTION", title: this.formService.getTranslate("GENERAL.HOLDER_TYPE_C") },
                { dataField: "PAYMENT_CHARGE.HOLDER", title: this.formService.getTranslate("GENERAL.HOLDER_C") },
                { dataField: "PAYMENT_CHARGE.TABLE", title: this.formService.getTranslate("FINANCIAL.EXCHANGE_RATE_INDEX_B") },
                { dataField: "PAYMENT_CHARGE.SPREAD", title: this.formService.getTranslate("FINANCIAL.SPREAD_B") },
                { dataField: "PAYMENT_CHARGE.FACTOR", title: this.formService.getTranslate("BASIC_DATA.EXCHANGE_RATE_C") },
                { dataField: "PAYMENT_CHARGE.TOTAL_CURRENT_CURRENCY", title: this.formService.getTranslate("FINANCIAL.TOTAL_BRL_C") },
                { dataField: "PAYMENT_CHARGE.INVOICE", title: this.formService.getTranslate("FINANCIAL.GENERATE_INVOICE_B") },
                { dataField: "PAYMENT_CHARGE.INVOICE_VALIDITY_INITIAL", title: this.formService.getTranslate("PRODUCT.VALIDITY_START_C") },
                { dataField: "PAYMENT_CHARGE.INVOICE_VALIDITY_FINAL", title: this.formService.getTranslate("PRODUCT.VALIDITY_END_C") },
                { dataField: "PAYMENT_CHARGE.SPECIFICITY", title: this.formService.getTranslate("GENERAL.SPECIFICITIES_B") },
                { dataField: "CHARGE_NAME.DISPLAY_PAYMENT", title: this.formService.getTranslate("FINANCIAL.SHOWN_FOR_C") },
                { dataField: "PAYMENT_CHARGE.CHARGE_COMPOSITION", title: this.formService.getTranslate("GENERAL.COMPOSITION_C") },
                { dataField: "PAYMENT_CHARGE.TARIFF", title: this.formService.getTranslate("GENERAL.TARIFF_B") },
                { dataField: "PAYMENT_CHARGE.CONTRACT", title: this.formService.getTranslate("GENERAL.CONTRACT_B") },
                { dataField: "PAYMENT_CHARGE.OBSERVATION", title: this.formService.getTranslate("GENERAL.REMARKS_C") },
                { dataField: "RECEIVING_CHARGE.CHARGE.DATA_ORIGIN_TYPE", title: this.formService.getTranslate("GENERAL.TYPE_V") },
                { dataField: "RECEIVING_CHARGE.CHARGE.CHARGE_NAME_EXHIBITION", title: this.formService.getTranslate("BASIC_DATA.DISPLAY_CHARGE") },
                { dataField: "RECEIVING_CHARGE.CHARGE.CHARGE_NAME_EXHIBITION.NAME", title: this.formService.getTranslate("FINANCIAL.CHARGE_DISPLAY_NAME_V") },
                { dataField: "RECEIVING_CHARGE.CHARGE", title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS_V") },
                { dataField: "RECEIVING_CHARGE.CURRENCY", title: this.formService.getTranslate("GENERAL.CURRENCY_V") },
                { dataField: "RECEIVING_CHARGE.AMOUNT", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.QUANTITY_V") },
                { dataField: "RECEIVING_CHARGE.VALUE_ABBREVIATIONS.UNITARY", title: this.formService.getTranslate("GENERAL.UNITARY_V") },
                { dataField: "RECEIVING_CHARGE.VALUE_UNITARY", title: this.formService.getTranslate("BASIC_DATA.UNIT") },
                { dataField: "RECEIVING_CHARGE.VALUE_MIN", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM_V") },
                { dataField: "RECEIVING_CHARGE.TOTAL", title: this.formService.getTranslate("GENERAL.TOTAL_V") },
                { dataField: "RECEIVING_CHARGE.MODALITY", title: this.formService.getTranslate("FINANCIAL.CHARGE_PAYMENT_METHOD_V") },
                { dataField: "RECEIVING_CHARGE.TRANSACTION", title: this.formService.getTranslate("GENERAL.HOLDER_TYPE_V") },
                { dataField: "RECEIVING_CHARGE.HOLDER", title: this.formService.getTranslate("GENERAL.HOLDER_V") },
                { dataField: "RECEIVING_CHARGE.TABLE", title: this.formService.getTranslate("FINANCIAL.EXCHANGE_RATE_INDEX_S") },
                { dataField: "RECEIVING_CHARGE.SPREAD", title: this.formService.getTranslate("FINANCIAL.SPREAD_S") },
                { dataField: "RECEIVING_CHARGE.FACTOR", title: this.formService.getTranslate("BASIC_DATA.EXCHANGE_RATE_V") },
                { dataField: "RECEIVING_CHARGE.TOTAL_CURRENT_CURRENCY", title: this.formService.getTranslate("FINANCIAL.TOTAL_BRL_C") },
                { dataField: "RECEIVING_CHARGE.INVOICE", title: this.formService.getTranslate("FINANCIAL.GENERATE_INVOICE_S") },
                { dataField: "RECEIVING_CHARGE.INVOICE_VALIDITY_INITIAL", title: this.formService.getTranslate("PRODUCT.VALIDITY_START_V") },
                { dataField: "RECEIVING_CHARGE.INVOICE_VALIDITY_FINAL", title: this.formService.getTranslate("PRODUCT.VALIDITY_END_V") },
                { dataField: "RECEIVING_CHARGE.SPECIFICITY", title: this.formService.getTranslate("GENERAL.SPECIFICITIES_S") },
                { dataField: "CHARGE_NAME.DISPLAY_RECEIVING", title: this.formService.getTranslate("FINANCIAL.SHOWN_FOR_V") },
                { dataField: "RECEIVING_CHARGE.CHARGE_COMPOSITION", title: this.formService.getTranslate("GENERAL.COMPOSITION_V") },
                { dataField: "RECEIVING_CHARGE.TARIFF", title: this.formService.getTranslate("GENERAL.TARIFF_S") },
                { dataField: "RECEIVING_CHARGE.CONTRACT", title: this.formService.getTranslate("GENERAL.CONTRACT_S") },
                { dataField: "RECEIVING_CHARGE.OBSERVATION", title: this.formService.getTranslate("GENERAL.REMARKS_V") },
                { dataField: "CHARGE_NAME.TYPE.NAME", title: this.formService.getTranslate("BASIC_DATA.ORIGIN") },
                { dataField: "CHARGE_VALUE", title: this.formService.getTranslate("FINANCIAL.CHARGE_DIFFERENCE") },
                { dataField: "CHARGE_VALUE_CURRENT_CURRENCY", title: this.formService.getTranslate("PRODUCT.WIZARD_DATA_PREOFFER_OPTIONS") },
            ],
            onPostBody: (chargeList: IOfferCharge[]) => {
                for (const index in chargeList) { chargeList[index].DISPLAY_INDEX = parseInt(index); };
                this.$scope.chargesTableList = chargeList;
                angular.element(".logindividual").css('background-color', 'orange');
                angular.element(".logindividual").css('border-color', 'orange');
                angular.element(".loggeral").css('background-color', 'orange');
                angular.element(".loggeral").css('border-color', 'orange');
                return true;
            },
            columns: [
                { field: 'selected', title: this.formService.getTranslate("GENERAL.UI_SELECT.SELECT"), checkbox: true, showSelectTitle: true, cellStyle: () => { return { css: { width: '80px' } } } },
                { field: 'DATA_ORIGIN_TYPE', title: this.formService.getTranslate("GENERAL.TYPE"), formatter: (data) => data ? data.ID : data, sortable: false, searchable: true, visible: false },
                { field: 'CHARGE_NAME.TYPE.ORDER', title: this.formService.getTranslate("BASIC_DATA.CHARGE"), formatter: (value, row) => this.buildEllipsizeEl(row.CHARGE_NAME ? row.CHARGE_NAME.NAME : null), sortable: true, searchable: true, class: 'charge-name-col' },
                { field: 'PAYMENT_CHARGE.DATA_ORIGIN_TYPE', title: this.formService.getTranslate(" GENERAL.TYPE_C"), formatter: (data) => data ? data.ID : data, sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.CHARGE.CHARGE_NAME_EXHIBITION', title: this.formService.getTranslate("BASIC_DATA.DISPLAY_CHARGE"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'PAYMENT_CHARGE.CHARGE.CHARGE_NAME_EXHIBITION.NAME', title: this.formService.getTranslate("FINANCIAL.CHARGE_DISPLAY_NAME"), sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.CHARGE', title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS"), formatter: (data: IOfferPaymentReceivingCharge) => this.chargeTableApplicationFormatter(data), sortable: true, searchable: true },
                { field: 'PAYMENT_CHARGE.CURRENCY', title: this.formService.getTranslate("GENERAL.CURRENCY"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'PAYMENT_CHARGE.AMOUNT', title: this.formService.getTranslate("GENERAL.QUANTITY"), sortable: true, searchable: true },
                { field: 'PAYMENT_CHARGE.VALUE_UNITARY', title: this.formService.getTranslate("BASIC_DATA.UNIT"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'PAYMENT_CHARGE.VALUE_MIN', title: this.formService.getTranslate("BASIC_DATA.MINIMUM"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'PAYMENT_CHARGE.TOTAL', title: this.formService.getTranslate("GENERAL.TOTAL"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'CHARGE_NAME.DISPLAY_PAYMENT', title: this.formService.getTranslate("FINANCIAL.SHOWN_FOR"), formatter: (data) => data ? this.$offerScope.getCONCAT(data, null, "CODE") : data, sortable: true, searchable: true },
                { field: 'PAYMENT_CHARGE.MODALITY', title: this.formService.getTranslate("FINANCIAL.CHARGE_PAYMENT_METHOD"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.TRANSACTION', title: this.formService.getTranslate("GENERAL.HOLDER_TYPE"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.HOLDER', title: this.formService.getTranslate("GENERAL.HOLDER"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.TABLE', title: this.formService.getTranslate("GENERAL.EXCHANGE_RATE_INDEX"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.SPREAD', title: this.formService.getTranslate("FINANCIAL.SPREAD"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.FACTOR', title: this.formService.getTranslate("BASIC_DATA.EXCHANGE_RATE"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.TOTAL_CURRENT_CURRENCY', title: this.formService.getTranslate("FINANCIAL.TOTAL_BRL"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.INVOICE', title: this.formService.getTranslate("FINANCIAL.GENERATE_INVOICE"), formatter: (data) => this.$filter("YesOrNo")(data), sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.VALIDITY_START', title: this.formService.getTranslate("PRODUCT.VALIDITY_START"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true, visible: true },
                { field: 'PAYMENT_CHARGE.VALIDITY_END', title: this.formService.getTranslate("PRODUCT.VALIDITY_END"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true, visible: true },
                { field: 'PAYMENT_CHARGE.SPECIFICITY', title: this.formService.getTranslate("GENERAL.SPECIFICITIES"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.CHARGE_COMPOSITION', title: this.formService.getTranslate("GENERAL.COMPOSITION"), formatter: (data) => data ? this.$offerScope.getCONCAT(data) : data, sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.TARIFF', title: this.formService.getTranslate("PRODUCT.TARIFF"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.CONTRACT', title: this.formService.getTranslate("BASIC_DATA.FREIGHT_CONTRACT"), formatter: (data) => data ? data.CONTRACT_NUMBER : data, sortable: true, searchable: true, visible: false },
                { field: 'PAYMENT_CHARGE.OBSERVATION', title: this.formService.getTranslate("GENERAL.REMARKS"), sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.DATA_ORIGIN_TYPE', title: this.formService.getTranslate(" GENERAL.TYPE_V"), formatter: (data) => data ? data.ID : data, sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.CHARGE.CHARGE_NAME_EXHIBITION', title: this.formService.getTranslate("BASIC_DATA.DISPLAY_CHARGE"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'RECEIVING_CHARGE.CHARGE.CHARGE_NAME_EXHIBITION.NAME', title: this.formService.getTranslate("FINANCIAL.CHARGE_DISPLAY_NAME"), sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.CHARGE', title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS"), formatter: (data: IOfferPaymentReceivingCharge) => this.chargeTableApplicationFormatter(data), sortable: true, searchable: true },
                { field: 'RECEIVING_CHARGE.CURRENCY', title: this.formService.getTranslate("GENERAL.CURRENCY"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'RECEIVING_CHARGE.AMOUNT', title: this.formService.getTranslate("GENERAL.QUANTITY"), sortable: true, searchable: true },
                { field: 'RECEIVING_CHARGE.VALUE_UNITARY', title: this.formService.getTranslate("BASIC_DATA.UNIT"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'RECEIVING_CHARGE.VALUE_MIN', title: this.formService.getTranslate("BASIC_DATA.MINIMUM"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'RECEIVING_CHARGE.TOTAL', title: this.formService.getTranslate("GENERAL.TOTAL"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'CHARGE_NAME.DISPLAY_RECEIVING', title: this.formService.getTranslate("FINANCIAL.SHOWN_FOR"), formatter: (data) => data ? this.$offerScope.getCONCAT(data, null, "CODE") : data, sortable: true, searchable: true, },
                { field: 'RECEIVING_CHARGE.MODALITY', title: this.formService.getTranslate("FINANCIAL.CHARGE_PAYMENT_METHOD"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.TRANSACTION', title: this.formService.getTranslate("GENERAL.HOLDER_TYPE"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.HOLDER', title: this.formService.getTranslate("GENERAL.HOLDER"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.TABLE', title: this.formService.getTranslate("GENERAL.EXCHANGE_RATE_INDEX"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.SPREAD', title: this.formService.getTranslate("FINANCIAL.SPREAD"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.FACTOR', title: this.formService.getTranslate("BASIC_DATA.EXCHANGE_RATE"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.TOTAL_CURRENT_CURRENCY', title: this.formService.getTranslate("FINANCIAL.TOTAL_BRL"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.INVOICE', title: this.formService.getTranslate("FINANCIAL.GENERATE_INVOICE"), formatter: (data) => this.$filter("YesOrNo")(data), sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.VALIDITY_START', title: this.formService.getTranslate("PRODUCT.VALIDITY_START"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true, visible: true },
                { field: 'RECEIVING_CHARGE.VALIDITY_END', title: this.formService.getTranslate("PRODUCT.VALIDITY_END"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true, visible: true },
                { field: 'RECEIVING_CHARGE.SPECIFICITY', title: this.formService.getTranslate("GENERAL.SPECIFICITIES"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.CHARGE_COMPOSITION', title: this.formService.getTranslate("GENERAL.COMPOSITION"), formatter: (data) => data ? this.$offerScope.getCONCAT(data) : data, sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.TARIFF', title: this.formService.getTranslate("PRODUCT.TARIFF"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.CONTRACT', title: this.formService.getTranslate("BASIC_DATA.FREIGHT_CONTRACT"), formatter: (data) => data ? data.CONTRACT_NUMBER : data, sortable: true, searchable: true, visible: false },
                { field: 'RECEIVING_CHARGE.OBSERVATION', title: this.formService.getTranslate("GENERAL.REMARKS"), sortable: true, searchable: true, visible: false },
                { field: 'CHARGE_NAME.TYPE.NAME', title: this.formService.getTranslate("BASIC_DATA.ORIGIN"), sortable: true, searchable: true, visible: false },
                { field: 'CHARGE_VALUE', title: this.formService.getTranslate("FINANCIAL.CHARGE_DIFFERENCE"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: true },
                { field: 'CHARGE_VALUE_CURRENT_CURRENCY', title: this.formService.getTranslate("PRODUCT.WIZARD_DATA_PREOFFER_OPTIONS"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: true }
            ],
        };
    }

    private buildEllipsizeEl(value: string) {
        return `
            <span
                style="max-width: 100%;"
                class="ellipsize td-tooltip"
                ellipsis-tooltip
                tooltip-placement="auto top"
                uib-tooltip="${value}"
                tooltip-enable="true"
                tooltip-append-to-body="true">
                ${value}
            </span>
        `;
    }

    private applyScob(index: number): void {
        try {
            this.removeCharge(index);
            this.$scope.chargesTableOptions.load(null, true);

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private chargeTableApplicationFormatter(charge: IOfferPaymentReceivingCharge): string {
        let display = "";
        if (charge && charge.APPLICATION) {
            display += charge.APPLICATION.CODE;
            if (charge.APPLICATION.APPLICATION_COMPLEMENT) {
                if (charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE && charge.WEIGHT_RANGE) display += ` (${charge.WEIGHT_RANGE.NAME})`;
                if (charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.EQUIPMENT && charge.EQUIPMENT) display += ` (${charge.EQUIPMENT.CODE})`;
                if (charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.VEHICLE && charge.VEHICLE_TYPE) display += ` (${charge.VEHICLE_TYPE.NAME})`;
            }
        }
        return display;
    }

    private hasInvalidRequiredElements(elementId: string): boolean {
        if (!elementId) return false;
        const isInvalid = FormService2.hasRequiredElements('#' + elementId);
        if (isInvalid) this.formService.notifyError(this.formService.getTranslate("GENERAL.ALL_FIELDS_MANDATORY"));
        return isInvalid;
    }

    private async addCharge(): Promise<void> {
        try {
            this.modalChargeId = this.modalService.newModal();

            const charge: IOfferCharge = {
                DISPLAY_INDEX: null,
                ID_OFFER: null,
                CHARGE_NAME: null,
                DATA_ORIGIN_TYPE: null,
                PAYMENT_CHARGE: {
                    ID_OFFER_OPTION_INVOICE: null,
                    AMOUNT: null,
                    VALUE_UNITARY: null,
                    VALUE_MIN: null,
                    TOTAL: null,
                    CURRENCY: null,
                    CHARGE: null,
                    CICLIC_REFERENCE: null,
                    CALCULATE_ORDER: null,
                    ERROR: null,
                    PERCENTUAL_COMPOSITION: null,
                    PERCENTUAL_COMPOSITION_CHARGE_NAME_EXHIBITION: null,
                    MODALITY: null,
                    TRANSACTION: null,
                    TRANSACTION_CONTRACT: null,
                    HOLDER: null,
                    HOLDER_REASON: null,
                    HOLDER_CONTRACT: null,
                    INVOICE: true,
                    TEMP_TRANSACTION: null,
                    TEMP_HOLDER: null,
                    TABLE: null,
                    SPREAD: null,
                    FACTOR: null,
                    TOTAL_FACTOR: null,
                    TOTAL_CURRENT_CURRENCY: null,
                    CURRENT_CURRENCY: null,
                    CONTRACT: null,
                    CHARGE_COMPOSITION: null,
                    WEIGHT_RANGE_CHARGE: null,
                    VALIDITY_END: null,
                    VALIDITY_EVENT: null,
                    VALIDITY_START: null,
                    VALIDITY_START_ORIGINAL: null,
                    VALIDITY_END_ORIGINAL: null,
                    VALIDITY_START_DISPLAY: null,
                    VALIDITY_END_DISPLAY: null,
                    NEGOTIATED: true
                },
                RECEIVING_CHARGE: {
                    ID_OFFER_OPTION_INVOICE: null,
                    AMOUNT: null,
                    VALUE_UNITARY: null,
                    VALUE_MIN: null,
                    TOTAL: null,
                    CURRENCY: null,
                    CHARGE: null,
                    CICLIC_REFERENCE: null,
                    CALCULATE_ORDER: null,
                    ERROR: null,
                    PERCENTUAL_COMPOSITION: null,
                    PERCENTUAL_COMPOSITION_CHARGE_NAME_EXHIBITION: null,
                    MODALITY: null,
                    TRANSACTION: null,
                    TRANSACTION_CONTRACT: null,
                    HOLDER: null,
                    HOLDER_REASON: null,
                    HOLDER_CONTRACT: null,
                    INVOICE: true,
                    TEMP_TRANSACTION: null,
                    TEMP_HOLDER: null,
                    TABLE: null,
                    SPREAD: null,
                    FACTOR: null,
                    TOTAL_FACTOR: null,
                    TOTAL_CURRENT_CURRENCY: null,
                    CURRENT_CURRENCY: null,
                    CONTRACT: null,
                    CHARGE_COMPOSITION: null,
                    WEIGHT_RANGE_CHARGE: null,
                    VALIDITY_END: null,
                    VALIDITY_EVENT: null,
                    VALIDITY_START: null,
                    VALIDITY_START_ORIGINAL: null,
                    VALIDITY_END_ORIGINAL: null,
                    VALIDITY_START_DISPLAY: null,
                    VALIDITY_END_DISPLAY: null,
                    NEGOTIATED: true
                },
                CHARGE_VALUE: null,
                CHARGE_VALUE_CURRENT_CURRENCY: null,
                EXCHANGE_RATE_INDEX: null,
                EXCHANGE_BALANCE: null,
                BALANCE_AMOUNT: null,
                PROFIT_SHARE: false,
                EDITED_AT: null,
                EDITED_BY: null,
                WEIGHT_RANGE: null
            }

            const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                {
                    modalID: this.modalChargeId,
                    scope: this.$scope,
                    formService: 'register',
                    template: require("../view/modals/offerChargeModal.html"),
                    size: 'full'
                },
                null
            );
            modalInstance.rendered.then(async () => {
                const modalScope = await this.buildChargeModalScope(null, null, charge);
                modalScope.$applyAsync();
            });

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async editCharge(index: number, displayIndex: number): Promise<void> {
        try {
            this.$offerScope.lastScroll = angular.element('.app-content-body').scrollTop();
            if (!index && index != 0) throw Error('index is null');
            const charge: IOfferCharge = this.$scope.model.CHARGES && this.$scope.model.CHARGES.length ? this.$scope.model.CHARGES[index] : null;
            if (!charge) throw Error('charge is null');

            this.modalChargeId = this.modalService.newModal();
            const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                {
                    modalID: this.modalChargeId,
                    scope: this.$scope,
                    formService: this.$offerScope.operation,
                    template: require("../view/modals/offerChargeModal.html"),
                    size: 'full',
                    keyboard: true,
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing") {
                            if (reason.toString() == "escape key press") event.preventDefault();
                            if (!closed) {
                                const modalScope: IChargeModalScope = await this.modalService.getModalScope(this.modalChargeId);
                                await modalScope.closeChargeModal();
                            }
                        }
                    }
                },
                null
            );
            modalInstance.rendered.then(async () => {
                const chargeUpdated = await this.getUpdatedCharge(charge._id);
                if (chargeUpdated) {
                    const modalScope = await this.buildChargeModalScope(index, displayIndex, chargeUpdated);
                    modalScope.$applyAsync();

                    angular.element('.collapseDetails').on('shown.bs.collapse', (event: JQuery.Event) => {
                        if (event.target == event.currentTarget) {
                            angular.element("#collapsePaymentPanelHeading").attr('aria-expanded', 'true');
                            angular.element("#collapseReceivingPanelHeading").attr('aria-expanded', 'true');
                        }
                    });

                    angular.element('.collapseDetails').on('hidden.bs.collapse', (event: JQuery.Event) => {
                        if (event.target == event.currentTarget) {
                            angular.element("#collapsePaymentPanelHeading").attr('aria-expanded', 'false');
                            angular.element("#collapseReceivingPanelHeading").attr('aria-expanded', 'false');
                        }
                    });
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeCharge(index: number, confirmationToRemove?: IOfferChangeConfirmation): Promise<void | boolean> {
        let success = false;
        try {
            if (!index && index != 0) throw Error('index is null');
            if (this.$scope.model.CHARGES && this.$scope.model.CHARGES.length == 1) return this.formService.notifyError(this.formService.getTranslate("PRODUCT.MESSAGE.OFFER_CHARGE_UNABLE_REMOVE"));
            const charge = this.$scope.model.CHARGES && this.$scope.model.CHARGES.length && this.$scope.model.CHARGES[index] ? this.$scope.model.CHARGES[index] : null;
            if (!charge) throw Error("charge is null");

            this.formService.block();

            const request = await this.productService.post({ route: '/offer/tabs/charge/remove', data: { OFFER_ID: charge.ID_OFFER, CHARGE_ID: charge._id, confirmation: confirmationToRemove } });
            if (request && request.data && request.data.data) {
                const confirmation: boolean = request.data.data.confirmation;
                if (!confirmation) {
                    success = false;
                    this.formService.unblock();
                    const confirmationData: IOfferChangeConfirmation = request.data.data.data;
                    await this.openReasonModal(charge, null, confirmationData, EOperation.REMOVE);
                }
                else if (confirmation) {
                    success = true;
                    this.modalService.closeModal(this.modalChargeId);
                    this.modalChargeId = 0;
                }
            }

            this.formService.unblock();
            if (success) {
                this.modalService.closeModal(this.modalChargeId);
                this.modalChargeId = 0;
            }

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            const msgSuccess = this.formService.getTranslate('FINANCIAL.CHARGE_DATA_SAVED_SUCCESSFULLY');
            if (success) {
                this.formService.notifySuccess(msgSuccess);
                await this.getOfferTabsCharge();
            }
        }
    }

    private async getUpdatedCharge(chargeId: string): Promise<IOfferCharge> {
        if (!chargeId) throw Error("chargeId is null.");
        const modalScope: IChargeModalScope = await this.modalService.getModalScope(this.modalChargeId);
        this.formService.block();
        let chargeUpdated = null;
        try {
            chargeUpdated = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/charge/get/${chargeId}`, 30000, null, false);
            if (!chargeUpdated || (chargeUpdated && !chargeUpdated.data)) {
                const msgError = this.formService.getTranslate('FINANCIAL.CHARGE_NO_LONGER_EXISTS');
                this.formService.notifyError(msgError);
                this.modalService.closeModal(this.modalChargeId);
                this.modalChargeId = 0;
                angular.element('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
                this.getOfferTabsCharge();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            const charge = chargeUpdated ? chargeUpdated.data : chargeUpdated;
            charge.WEIGHT_RANGE = await this.getOfferWeightRange();
            if (charge.DATA_ORIGIN_TYPE && charge.DATA_ORIGIN_TYPE.ID === EDataOriginTypeId.MANUAL) {
                if (charge.WEIGHT_RANGE && charge.PAYMENT_CHARGE.WEIGHT_RANGE_CHARGE && charge.WEIGHT_RANGE.length > charge.PAYMENT_CHARGE.WEIGHT_RANGE_CHARGE.length) {
                    for (let i = 0; i < charge.WEIGHT_RANGE.length; i++) {
                        const hasPaymentWeightRangeAlready = charge.PAYMENT_CHARGE.WEIGHT_RANGE_CHARGE.some(paymentWeightRange => paymentWeightRange.WEIGHT_RANGE && paymentWeightRange.WEIGHT_RANGE.ID == charge.WEIGHT_RANGE[i].ID);
                        if (!hasPaymentWeightRangeAlready) {
                            charge.PAYMENT_CHARGE.WEIGHT_RANGE_CHARGE.push({ WEIGHT_RANGE: { ID: charge.WEIGHT_RANGE[i].ID, NAME: charge.WEIGHT_RANGE[i].NAME, CODE: charge.WEIGHT_RANGE[i].CODE }, CURRENCY: undefined, VALUE_UNITARY: null, VALUE_MIN: null })
                            this.formService.notifyError(`BUY weight range ${charge.WEIGHT_RANGE[i].NAME} cannot be without value`);
                            modalScope.charge = angular.copy(charge);
                        }
                    }
                }
                if (charge.WEIGHT_RANGE && charge.RECEIVING_CHARGE.WEIGHT_RANGE_CHARGE && charge.WEIGHT_RANGE.length > charge.RECEIVING_CHARGE.WEIGHT_RANGE_CHARGE.length) {
                    for (let i = 0; i < charge.WEIGHT_RANGE.length; i++) {
                        const hasReceivingWeightRangeAlready = charge.RECEIVING_CHARGE.WEIGHT_RANGE_CHARGE.some(receivingWeightRange => receivingWeightRange.WEIGHT_RANGE && receivingWeightRange.WEIGHT_RANGE.ID == charge.WEIGHT_RANGE[i].ID);
                        if (!hasReceivingWeightRangeAlready) {
                            charge.RECEIVING_CHARGE.WEIGHT_RANGE_CHARGE.push({ WEIGHT_RANGE: { ID: charge.WEIGHT_RANGE[i].ID, NAME: charge.WEIGHT_RANGE[i].NAME, CODE: charge.WEIGHT_RANGE[i].CODE }, CURRENCY: undefined, VALUE_UNITARY: null, VALUE_MIN: null })
                            this.formService.notifyError(`${charge.WEIGHT_RANGE[i].NAME} SALE weight range cannot be without value`);
                            modalScope.charge = angular.copy(charge);
                        }
                    }
                }

            }
            charge.RECEIVING_CHARGE.VALIDITY_START_DISPLAY = charge.RECEIVING_CHARGE.VALIDITY_START ? charge.RECEIVING_CHARGE.VALIDITY_START : charge.RECEIVING_CHARGE.VALIDITY_START_ORIGINAL;
            charge.RECEIVING_CHARGE.VALIDITY_END_DISPLAY = charge.RECEIVING_CHARGE.VALIDITY_END ? charge.RECEIVING_CHARGE.VALIDITY_END : charge.RECEIVING_CHARGE.VALIDITY_END_OVALIDITY_START_ORIGINAL;
            charge.PAYMENT_CHARGE.VALIDITY_START_DISPLAY = charge.PAYMENT_CHARGE.VALIDITY_START ? charge.PAYMENT_CHARGE.VALIDITY_START : charge.PAYMENT_CHARGE.VALIDITY_START_ORIGINAL;
            charge.PAYMENT_CHARGE.VALIDITY_END_DISPLAY = charge.PAYMENT_CHARGE.VALIDITY_END ? charge.PAYMENT_CHARGE.VALIDITY_END : charge.PAYMENT_CHARGE.VALIDITY_END_OVALIDITY_START_ORIGINAL;

            return chargeUpdated ? chargeUpdated.data : chargeUpdated;
        }
    }

    private async buildChargeModalScope(index: number, displayIndex: number, charge: IOfferCharge): Promise<IChargeModalScope> {
        try {
            const modalScope: IChargeModalScope = await this.modalService.getModalScope(this.modalChargeId);
            const operationBefore = angular.copy(modalScope.operation);
            const vehicleTypeGeneric = await this.getGenericValue("vehicle_type");
            modalScope.isManualCharge = charge.DATA_ORIGIN_TYPE ? charge.DATA_ORIGIN_TYPE.ID == EDataOriginTypeId.MANUAL ? true : false : true;
            if (modalScope.isManualCharge || modalScope.charge && modalScope.charge.RECEIVING_CHARGE && modalScope.charge.RECEIVING_CHARGE.NEGOTIATED) modalScope.isReceivingChargeNegotiated = true;

            const loadEvent = this.$scope.eventList.find(event => event.ID == EEventType.LOAD);
            if (modalScope.operation == EOperation.NEW) {
                const offerValidity = await this.getOfferValidity();
                this.updateChargeValidity(charge, offerValidity, loadEvent);
            }
            this.updateChargeValidityDisplay(charge);

            if (modalScope.operation != EOperation.NEW) {
                modalScope.chargesOutDate = await this.getOutDateChargesByCharge(charge._id);
                modalScope.hasOutDateCharge = (modalScope.chargesOutDate.length && modalScope.chargesOutDate.length > 0);
            }

            let chargePaymentWeightRange = [];
            let chargeReceivingWeightRange = [];

            if (charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.WEIGHT_RANGE_CHARGE) {
                charge.PAYMENT_CHARGE.WEIGHT_RANGE_CHARGE.map((chargePaymentWeight) => {
                    if (chargePaymentWeight.WEIGHT_RANGE && chargePaymentWeight.WEIGHT_RANGE) {
                        chargePaymentWeightRange.push(chargePaymentWeight);
                        charge.PAYMENT_CHARGE.WEIGHT_RANGE_CHARGE = chargePaymentWeightRange.sort((x, y) => x.WEIGHT_RANGE.ID < y.WEIGHT_RANGE.ID ? -1 : 1);
                    }
                });
            }

            if (charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.WEIGHT_RANGE_CHARGE) {
                charge.RECEIVING_CHARGE.WEIGHT_RANGE_CHARGE.map((chargeReceivingWeight) => {
                    if (chargeReceivingWeight.WEIGHT_RANGE && chargeReceivingWeight.WEIGHT_RANGE) {
                        chargeReceivingWeightRange.push(chargeReceivingWeight);
                        charge.RECEIVING_CHARGE.WEIGHT_RANGE_CHARGE = charge.RECEIVING_CHARGE.WEIGHT_RANGE_CHARGE.sort((x, y) => x.WEIGHT_RANGE.ID < y.WEIGHT_RANGE.ID ? -1 : 1);
                    }
                });
            }

            modalScope.currentIndex = index;
            modalScope.currentDisplayIndex = displayIndex;
            modalScope.charge = angular.copy(charge);
            modalScope.oldCharge = angular.copy(charge);
            modalScope.vehicleTypeList = vehicleTypeGeneric ? vehicleTypeGeneric : [];
            modalScope.paymentEquipmentList = await this.getEquipmentList(charge ? charge._id : null, EPaymentNatureId.PAYMENT);
            modalScope.receivingEquipmentList = await this.getEquipmentList(charge ? charge._id : null, EPaymentNatureId.RECEIVING);
            modalScope.weightRangeList = await this.getWeightRangeList({ search: null, products: [this.$offerScope.model.PRODUCT ? this.$offerScope.model.PRODUCT.ID : null] });
            modalScope.isPaymentWeightRangeApplication = charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.CHARGE && charge.PAYMENT_CHARGE.CHARGE.APPLICATION && charge.PAYMENT_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT && charge.PAYMENT_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
            modalScope.isReceivingWeightRangeApplication = charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.CHARGE && charge.RECEIVING_CHARGE.CHARGE.APPLICATION && charge.RECEIVING_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT && charge.RECEIVING_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
            if (modalScope.charge && modalScope.charge.CHARGE_NAME && modalScope.charge.CHARGE_NAME.TYPE && (modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.COMISSION)) modalScope.operation = EOperation.VIEW;
            if (modalScope.operation == EOperation.EDIT) {
                modalScope.paymentCompositionList = await this.getCompositionList({ OFFER_ID: this.$offerScope.model.ID, PAYMENT_NATURE: EPaymentNatureId.PAYMENT, CHARGE_NAME: modalScope.charge.CHARGE_NAME ? modalScope.charge.CHARGE_NAME.ID : null });
                modalScope.receivingCompositionList = await this.getCompositionList({ OFFER_ID: this.$offerScope.model.ID, PAYMENT_NATURE: EPaymentNatureId.RECEIVING, CHARGE_NAME: modalScope.charge.CHARGE_NAME ? modalScope.charge.CHARGE_NAME.ID : null });
                modalScope.chargeNameExhibitionList = await this.getChargeNameExhibitionListByName(null, modalScope.charge && modalScope.charge.CHARGE_NAME ? parseInt(modalScope.charge.CHARGE_NAME.ID) : null);
                if (modalScope.charge.PAYMENT_CHARGE.TRANSACTION && modalScope.charge.PAYMENT_CHARGE.TRANSACTION.ID != ETransactionId.OTHER) modalScope.holderPaymentList = await this.getOfferChargeHolder(modalScope.charge.PAYMENT_CHARGE.TRANSACTION.ID);
                if (modalScope.charge.RECEIVING_CHARGE.TRANSACTION && modalScope.charge.RECEIVING_CHARGE.TRANSACTION.ID != ETransactionId.OTHER) modalScope.holderReceivingList = await this.getOfferChargeHolder(modalScope.charge.RECEIVING_CHARGE.TRANSACTION.ID);
                modalScope.disableElements(charge.PAYMENT_CHARGE.CHARGE.APPLICATION.INTERNAL_SEQUENCE == "001" || modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.INSURANCE, null, ["chargePaymentApplicationWeightRange", "chargePaymentApplicationEquipment", "chargePaymentVehicleType", "chargePaymentAmount", "chargePaymentCurrency", "chargePaymentValueUnitary", "chargePaymentValueMin", "chargePaymentModality", "chargePaymentTransaction", "chargePaymentHolder", "chargePaymentInvoice", "chargeNameExhibitionPayment", "chargePaymentExhibition", "chargePaymentComposition", "chargePaymentSpecificity", "chargePaymentContract", "chargePaymentTable", "chargePaymentObservation"]);
                modalScope.disableElements(charge.RECEIVING_CHARGE.CHARGE.APPLICATION.INTERNAL_SEQUENCE == "001" || modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.INSURANCE, null, ["chargeReceivingApplicationWeightRange", "chargeReceivingApplicationEquipment", "chargeReceivingVehicleType", "chargeReceivingAmount", "chargeReceivingCurrency", "chargeReceivingValueUnitary", "chargeReceivingValueMin", "chargeReceivingModality", "chargeReceivingTransaction", "chargeReceivingHolder", "chargeReceivingInvoice", "chargeNameExhibitionReceiving", "chargeReceivingExhibition", "chargeReceivingComposition", "chargeReceivingSpecificity", "chargeReceivingContract", "chargeReceivingTable", "chargeReceivingObservation"]);
            }

            modalScope.chargeShownForList = await this.getChargeShownForList();

            modalScope.applyCharge = async () => {
                if (await this.applyCharge(modalScope.charge, modalScope.oldCharge, modalScope.operation, null, true)) this.getOfferTabsCharge();
            };

            modalScope.getChargeNameListByName = async (search: string) => {
                let chargeNameList: SelectorModel[] = [];
                if (search && search.length >= 3) chargeNameList = await this.getChargeNameListByName(search);
                modalScope.chargeNameList = chargeNameList;
            }

            modalScope.getApplicationListByName = async (search: string) => {
                let applicationList: IApplicationList[] = [];
                if (search && search.length >= 3) applicationList = await this.getApplicationListByName(search);
                modalScope.applicationList = applicationList;
            }

            modalScope.getHolderListByName = async (search: string) => {
                let holderList: IHolderSelector[] = [];
                if (search && search.length >= 3) {
                    const legalPersonList = await this.getLegalPersonListByName({ specializations: [], search: search })
                    if (legalPersonList && legalPersonList.length) holderList = holderList.concat(legalPersonList.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.NAME, ID_LEGAL_PERSON: parseInt(legalPerson.ID), ID_PHYSICAL_PERSON: null } }));
                    const physicalPersonList = await this.getPhysicalPersonListByName({ specializations: [], roles: [], search: search });
                    if (physicalPersonList && physicalPersonList.length) holderList = holderList.concat(physicalPersonList.map(physicalPerson => { return { ID: physicalPerson.ID, NAME: physicalPerson.NAME, ID_LEGAL_PERSON: null, ID_PHYSICAL_PERSON: parseInt(physicalPerson.ID) } }));
                }
                modalScope.holderList = holderList;
            }

            modalScope.goToTariffLocal = (id: number) => {
                const endpoint = `${this.$offerScope.productUrl}/tariffLocal/getCacheById/${id}`;
                this.sessionService.openTabByValidity(endpoint, "app.product.tariffLocal", <ILinkParameter>{ ID: id ? id.toString() : id }, <ITariffLocalExchangeData>{ OPERATION: EOperation.VIEW, ID: id ? id.toString() : id });
            }

            modalScope.goToTariffDomestic = (id: number) => {
                const endpoint = `${this.$offerScope.productUrl}/tariffDomestic/getCacheById/${id}`;
                this.sessionService.openTabByValidity(endpoint, "app.product.tariffDomestic", <ILinkParameter>{ ID: id ? id.toString() : id }, <ITariffDomesticExchangeData>{ OPERATION: EOperation.VIEW, ID: id ? id.toString() : id });
            }

            modalScope.goToTariffFreight = (id: number) => {
                const endpoint = `${this.$offerScope.productUrl}/tariffFreight/getCacheById/${id}`;
                this.sessionService.openTabByValidity(endpoint, "app.product.tariffFreight", <ILinkParameter>{ ID: id ? id.toString() : id }, <ITariffFreightExchangeData>{ OPERATION: EOperation.VIEW, ID: id ? id.toString() : id });
            }

            modalScope.goToInlandRoutes = (id: number) => {
                this.sessionService.openTab('app.product.inlandRoutes', <ILinkParameter>{ ID: id ? id.toString() : id }, <ITariffLocalExchangeData>{ OPERATION: EOperation.VIEW, ID: id ? id.toString() : id });
            }

            modalScope.goToFreightRoutes = (id: number) => {
                this.sessionService.openTab('app.product.freightRoutes', <ILinkParameter>{ ID: id ? id.toString() : id }, <ITariffFreightExchangeData>{ OPERATION: EOperation.VIEW, ID: id ? id.toString() : id });
            }

            modalScope.goToTariffComplementary = (id: number) => {
                this.sessionService.openTab('app.product.tariffComplementary', <ILinkParameter>{ ID: id ? id.toString() : id }, <ITariffFreightExchangeData>{ OPERATION: EOperation.VIEW, ID: id ? id.toString() : id });
            }

            modalScope.isNotApplicableComplement = (applicationComplement: SelectorModel): boolean => {
                return !applicationComplement || (applicationComplement && applicationComplement.ID == EApplicationComplementId.NOT_APPLICABLE);
            }

            modalScope.isWeightRangeComplement = (applicationComplement: SelectorModel): boolean => {
                return applicationComplement && applicationComplement.ID == EApplicationComplementId.WEIGHT_RANGE;
            }

            modalScope.isEquipmentComplement = (applicationComplement: SelectorModel): boolean => {
                return applicationComplement && applicationComplement.ID == EApplicationComplementId.EQUIPMENT;
            }

            modalScope.isVehicleComplement = (applicationComplement: SelectorModel): boolean => {
                return applicationComplement && applicationComplement.ID == EApplicationComplementId.VEHICLE;
            }

            modalScope.isAirProduct = (): boolean => {
                return this.$offerScope.model.PRODUCT && (this.$offerScope.model.PRODUCT.ID == EProductId.AIR_EXPORT || this.$offerScope.model.PRODUCT.ID == EProductId.AIR_IMPORT);
            }

            modalScope.isTransactionOther = (transaction: SelectorModel): boolean => {
                return transaction && transaction.ID == ETransactionId.OTHER;
            }

            modalScope.isApplicationScob = (internalSequence: string): boolean => {
                return internalSequence && internalSequence == '001';
            }

            modalScope.chargeChange = (selected: IOfferChargeName) => {
                this.chargeChange(selected);
            }

            modalScope.weightRangePaymentValueUnitaryChange = (weightRangeId: string) => {
                this.weightRangePaymentValueUnitaryChange(weightRangeId);
            }

            modalScope.weightRangeReceivingValueUnitaryChange = (weightRangeId: string) => {
                this.weightRangeReceivingValueUnitaryChange(weightRangeId);
            }

            modalScope.hasPreviousCharge = (currentDisplayIndex: number) => {
                return currentDisplayIndex > 0;
            }

            modalScope.applicationChange = (type: string, applicationIdBefore: string) => {
                this.applicationChange(type, applicationIdBefore);
            }

            modalScope.transactionChange = (type: string) => {
                this.transactionChange(type);
            }

            modalScope.hasNextCharge = (currentDisplayIndex: number) => {
                return (currentDisplayIndex || currentDisplayIndex == 0) && this.$scope.chargesTableList && (this.$scope.chargesTableList.length - 1 > currentDisplayIndex);
            }

            modalScope.calculateTotal = async (paymentReceivingCharge: IOfferPaymentReceiving, oldPaymentReceivingCharge: IOfferPaymentReceiving) => {
                this.calculateTotal(paymentReceivingCharge, oldPaymentReceivingCharge);
            }

            modalScope.updatePaymentMin = () => {
                this.updatePaymentMin();
            }

            modalScope.updateReceivingMin = () => {
                this.updateReceivingMin();
            }

            modalScope.previousCharge = (currentDisplayIndex: number) => {
                this.previousCharge(currentDisplayIndex, operationBefore);
            }

            modalScope.nextCharge = (currentDisplayIndex: number) => {
                this.nextCharge(currentDisplayIndex, operationBefore);
            }

            modalScope.closeChargeModal = () => {
                this.closeChargeModal();
            }

            modalScope.prepareTextComposition = (item: IPercentualComposition, complete: boolean): string => {
                return this.prepareTextComposition(item, complete);
            }

            modalScope.getModalityTransactionDefault = (type?: string, currencyId?: string) => {
                this.getModalityTransactionDefault(type, currencyId);
            }

            modalScope.changeHolder = (selected: IHolderSelector, paymentReceivingCharge: IOfferPaymentReceiving) => {
                if (paymentReceivingCharge.TRANSACTION && paymentReceivingCharge.TRANSACTION.ID == ETransactionId.OTHER) {
                    paymentReceivingCharge.TEMP_HOLDER = selected ? selected : null;
                }
            }

            modalScope.setReceivingNegotiated = (receivingCharge: IOfferPaymentReceiving) => {
                if (!receivingCharge.NEGOTIATED) {
                    receivingCharge.NEGOTIATED = true;
                }
                modalScope.isReceivingChargeNegotiated = true;
            }

            modalScope.saveCharge = (currentDisplayIndex: number) => {
                this.saveCharge(currentDisplayIndex);
            }

            modalScope.isNegotiated = (receivingCharge: IOfferPaymentReceiving): boolean => {
                return this.isNegotiated(receivingCharge);
            }

            return modalScope;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private isNegotiated(receivingCharge: IOfferPaymentReceiving): boolean {
        return (receivingCharge && receivingCharge.NEGOTIATED)
    }

    private updateChargeValidity(charge: IOfferCharge, offerValidity: IOfferValidity, loadEvent: SelectorModel) {
        if (charge.PAYMENT_CHARGE) {
            this.updateChargeValidityFields(charge.PAYMENT_CHARGE, offerValidity, loadEvent);
        }
        if (charge.RECEIVING_CHARGE) {
            this.updateChargeValidityFields(charge.RECEIVING_CHARGE, offerValidity, loadEvent);
        }
    }

    private updateChargeValidityFields(paymentReceivingCharge: IOfferPaymentReceiving, offerValidity: IOfferValidity, loadEvent: SelectorModel) {
        if (offerValidity) {
            paymentReceivingCharge.VALIDITY_END = offerValidity.VALIDITY_END;
            paymentReceivingCharge.VALIDITY_END_ORIGINAL = offerValidity.VALIDITY_END;
            paymentReceivingCharge.VALIDITY_START = offerValidity.VALIDITY_START;
            paymentReceivingCharge.VALIDITY_START_ORIGINAL = offerValidity.VALIDITY_START;
        }
        paymentReceivingCharge.VALIDITY_EVENT = loadEvent;
    }

    private updateChargeValidityDisplay(charge: IOfferCharge) {
        charge.RECEIVING_CHARGE.VALIDITY_START_DISPLAY =
            charge.RECEIVING_CHARGE.VALIDITY_START || charge.RECEIVING_CHARGE.VALIDITY_START_ORIGINAL;
        charge.RECEIVING_CHARGE.VALIDITY_END_DISPLAY =
            charge.RECEIVING_CHARGE.VALIDITY_END || charge.RECEIVING_CHARGE.VALIDITY_END_ORIGINAL;
        charge.PAYMENT_CHARGE.VALIDITY_START_DISPLAY =
            charge.PAYMENT_CHARGE.VALIDITY_START || charge.PAYMENT_CHARGE.VALIDITY_START_ORIGINAL;
        charge.PAYMENT_CHARGE.VALIDITY_END_DISPLAY =
            charge.PAYMENT_CHARGE.VALIDITY_END || charge.PAYMENT_CHARGE.VALIDITY_END_ORIGINAL;
    }

    private async getOfferValidity(): Promise<IOfferValidity> {
        try {
            const result = await this.productService.get({ route: `/offer/validity/${this.$offerScope.model.ID}` });
            if (result && result.data && result.data.data) {
                const validity: IOfferValidity = result.data.data
                return validity
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    /* Offer Charge Modal - Functions - Início */
    private async chargeChange(selected: IOfferChargeName) {
        const modalScope: IChargeModalScope = await this.modalService.getModalScope(this.modalChargeId);
        if (selected && selected.DISPLAY_PAYMENT && selected.DISPLAY_PAYMENT.length > 0) modalScope.charge.CHARGE_NAME.DISPLAY_PAYMENT = selected.DISPLAY_PAYMENT;
        if (selected && selected.DISPLAY_RECEIVING && selected.DISPLAY_RECEIVING.length > 0) modalScope.charge.CHARGE_NAME.DISPLAY_RECEIVING = selected.DISPLAY_RECEIVING;
        modalScope.paymentCompositionList = await this.getCompositionList({ OFFER_ID: this.$offerScope.model.ID, PAYMENT_NATURE: EPaymentNatureId.PAYMENT, CHARGE_NAME: modalScope.charge.CHARGE_NAME ? modalScope.charge.CHARGE_NAME.ID : null });
        modalScope.receivingCompositionList = await this.getCompositionList({ OFFER_ID: this.$offerScope.model.ID, PAYMENT_NATURE: EPaymentNatureId.RECEIVING, CHARGE_NAME: modalScope.charge.CHARGE_NAME ? modalScope.charge.CHARGE_NAME.ID : null });
        modalScope.chargeNameExhibitionList = await this.getChargeNameExhibitionListByName(null, parseInt(modalScope.charge.CHARGE_NAME.ID));
        await this.populateDefaultChargeNameExhibition(selected);
        await this.getDefaultApplication(selected);
        if (modalScope.isPaymentWeightRangeApplication || modalScope.isReceivingWeightRangeApplication) {
            const offerWeightRangeList: IWeightRangeResponse[] = await this.getOfferWeightRange();
            if (offerWeightRangeList && offerWeightRangeList.length) {
                const weightRangeActive = offerWeightRangeList.find(weightRange => weightRange.ACTIVE);
                const weightRangeCharge: IWeightRangeCharge[] = offerWeightRangeList.map(offerWeightRange => { return { ACTIVE: offerWeightRange.ACTIVE, CURRENCY: null, VALUE_MIN: null, VALUE_UNITARY: null, WEIGHT_RANGE: { ID: offerWeightRange.ID, NAME: offerWeightRange.NAME } } });
                modalScope.weightRangeActiveId = weightRangeActive.ID;
                modalScope.charge.PAYMENT_CHARGE.CHARGE.WEIGHT_RANGE = { ID: weightRangeActive.ID, NAME: weightRangeActive.NAME };
                modalScope.charge.RECEIVING_CHARGE.CHARGE.WEIGHT_RANGE = { ID: weightRangeActive.ID, NAME: weightRangeActive.NAME };
                modalScope.charge.PAYMENT_CHARGE.WEIGHT_RANGE_CHARGE = angular.copy(weightRangeCharge);
                modalScope.charge.RECEIVING_CHARGE.WEIGHT_RANGE_CHARGE = angular.copy(weightRangeCharge);
            }
        }
    }

    private async beforeSaveCharge() {
        try {
            const modalScope: IChargeModalScope = await this.modalService.getModalScope(this.modalChargeId);
            if (modalScope.charge && modalScope.charge.PAYMENT_CHARGE && modalScope.charge.PAYMENT_CHARGE.WEIGHT_RANGE_CHARGE) {
                if (modalScope.isPaymentWeightRangeApplication) {
                    for (const weightRangeCharge of modalScope.charge.PAYMENT_CHARGE.WEIGHT_RANGE_CHARGE) {
                        weightRangeCharge.CURRENCY = modalScope.charge.PAYMENT_CHARGE.CURRENCY;
                        weightRangeCharge.VALUE_MIN = modalScope.charge.PAYMENT_CHARGE.VALUE_MIN;
                    }
                } else {
                    modalScope.charge.PAYMENT_CHARGE.WEIGHT_RANGE_CHARGE = null;
                }
                modalScope.$applyAsync();
            }

            if (modalScope.charge && modalScope.charge.RECEIVING_CHARGE && modalScope.charge.RECEIVING_CHARGE.WEIGHT_RANGE_CHARGE) {
                if (modalScope.isReceivingWeightRangeApplication) {
                    for (const weightRangeCharge of modalScope.charge.RECEIVING_CHARGE.WEIGHT_RANGE_CHARGE) {
                        weightRangeCharge.CURRENCY = modalScope.charge.RECEIVING_CHARGE.CURRENCY;
                        weightRangeCharge.VALUE_MIN = modalScope.charge.RECEIVING_CHARGE.VALUE_MIN;
                    }
                } else {
                    modalScope.charge.RECEIVING_CHARGE.WEIGHT_RANGE_CHARGE = null;
                }
                modalScope.$applyAsync();
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async weightRangePaymentValueUnitaryChange(weightRangeId: string) {
        try {
            const modalScope: IChargeModalScope = await this.modalService.getModalScope(this.modalChargeId);
            const weightRangeActive = await this.getWeightRangePaymentActive(weightRangeId);
            if (weightRangeActive) {
                modalScope.charge.PAYMENT_CHARGE.VALUE_UNITARY = weightRangeActive.VALUE_UNITARY;
                this.calculateTotal(modalScope.charge.PAYMENT_CHARGE, modalScope.oldCharge ? modalScope.oldCharge.PAYMENT_CHARGE : null);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async weightRangeReceivingValueUnitaryChange(weightRangeId: string) {
        try {
            const modalScope: IChargeModalScope = await this.modalService.getModalScope(this.modalChargeId);
            const weightRangeActive = await this.getWeightRangeReceivingActive(weightRangeId);
            if (weightRangeActive) {
                modalScope.charge.RECEIVING_CHARGE.VALUE_UNITARY = weightRangeActive.VALUE_UNITARY;
                this.calculateTotal(modalScope.charge.RECEIVING_CHARGE, modalScope.oldCharge ? modalScope.oldCharge.RECEIVING_CHARGE : null);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getWeightRangePaymentActive(weightRangeId: string): Promise<IWeightRangeCharge> {
        try {
            const modalScope: IChargeModalScope = await this.modalService.getModalScope(this.modalChargeId);
            let weightRangeIdToSearch = null;
            if (modalScope.weightRangeActiveId && modalScope.weightRangeActiveId == weightRangeId) weightRangeIdToSearch = weightRangeId;
            else if (modalScope.charge.PAYMENT_CHARGE.CHARGE.WEIGHT_RANGE) weightRangeIdToSearch = modalScope.charge.PAYMENT_CHARGE.CHARGE.WEIGHT_RANGE.ID;
            const weightRangeActive = modalScope.charge.PAYMENT_CHARGE.WEIGHT_RANGE_CHARGE.find(weightRangeCharge => weightRangeCharge.WEIGHT_RANGE && weightRangeCharge.WEIGHT_RANGE.ID == weightRangeIdToSearch);
            return weightRangeActive && weightRangeActive.WEIGHT_RANGE && weightRangeActive.WEIGHT_RANGE.ID == weightRangeId ? weightRangeActive : null;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getWeightRangeReceivingActive(weightRangeId: string) {
        try {
            const modalScope: IChargeModalScope = await this.modalService.getModalScope(this.modalChargeId);
            let weightRangeIdToSearch = null;
            if (modalScope.weightRangeActiveId && modalScope.weightRangeActiveId == weightRangeId) weightRangeIdToSearch = weightRangeId;
            else if (modalScope.charge.RECEIVING_CHARGE.CHARGE.WEIGHT_RANGE) weightRangeIdToSearch = modalScope.charge.RECEIVING_CHARGE.CHARGE.WEIGHT_RANGE.ID;
            const weightRangeActive = modalScope.charge.RECEIVING_CHARGE.WEIGHT_RANGE_CHARGE.find(weightRangeCharge => weightRangeCharge.WEIGHT_RANGE && weightRangeCharge.WEIGHT_RANGE.ID == weightRangeIdToSearch);
            return weightRangeActive;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getOfferWeightRange(): Promise<IWeightRangeResponse[]> {
        let offerWeightRangeList: IWeightRangeResponse[] = null;
        try {
            this.formService.block();
            const request = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/chargeWeightRange/weightRange/${this.$offerScope.model.ID}`, 30000, null, false);
            if (request && request.data) offerWeightRangeList = request.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return offerWeightRangeList;
        }
    }

    private async applicationChange(type: string, applicationIdBefore: string) {
        if (!type) return this.formService.notifyError("type is null");
        let charge = null;
        let aux = "";

        const modalScope: IChargeModalScope = await this.modalService.getModalScope(this.modalChargeId);
        modalScope.chargeNameExhibitionList = await this.getChargeNameExhibitionListByName(null, parseInt(modalScope.charge.CHARGE_NAME.ID));

        if (type == EPaymentNatureId.PAYMENT) {
            aux = "Payment";
            const isScobApplication = modalScope.charge && modalScope.charge.PAYMENT_CHARGE && modalScope.charge.PAYMENT_CHARGE.CHARGE && modalScope.charge.PAYMENT_CHARGE.CHARGE.APPLICATION && modalScope.charge.PAYMENT_CHARGE.CHARGE.APPLICATION.INTERNAL_SEQUENCE == "001";
            if (modalScope.charge && modalScope.charge.PAYMENT_CHARGE) {
                if (isScobApplication) modalScope.charge.PAYMENT_CHARGE.INVOICE = false;
                else if (applicationIdBefore == EApplicationComplementId.NOT_APPLICABLE) modalScope.charge.PAYMENT_CHARGE.INVOICE = true;
            }
            charge = modalScope.charge && modalScope.charge.PAYMENT_CHARGE && modalScope.charge.PAYMENT_CHARGE.CHARGE ? modalScope.charge.PAYMENT_CHARGE.CHARGE : null;
            modalScope.paymentCompositionList = await this.getCompositionList({ OFFER_ID: this.$offerScope.model.ID, PAYMENT_NATURE: EPaymentNatureId.PAYMENT, CHARGE_NAME: modalScope.charge.CHARGE_NAME ? modalScope.charge.CHARGE_NAME.ID : null });
            modalScope.isPaymentWeightRangeApplication = charge.APPLICATION && charge.APPLICATION.APPLICATION_COMPLEMENT && charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
            modalScope.selectorValidity("chargePaymentCurrency");
        }
        else if (type == EPaymentNatureId.RECEIVING) {
            aux = "Receiving";
            const isScobApplication = modalScope.charge && modalScope.charge.RECEIVING_CHARGE && modalScope.charge.RECEIVING_CHARGE.CHARGE && modalScope.charge.RECEIVING_CHARGE.CHARGE.APPLICATION && modalScope.charge.RECEIVING_CHARGE.CHARGE.APPLICATION.INTERNAL_SEQUENCE == "001";
            if (modalScope.charge && modalScope.charge.RECEIVING_CHARGE) {
                if (isScobApplication) modalScope.charge.RECEIVING_CHARGE.INVOICE = false;
                else if (applicationIdBefore == EApplicationComplementId.NOT_APPLICABLE) modalScope.charge.RECEIVING_CHARGE.INVOICE = true;
            }
            charge = modalScope.charge && modalScope.charge.RECEIVING_CHARGE && modalScope.charge.RECEIVING_CHARGE.CHARGE ? modalScope.charge.RECEIVING_CHARGE.CHARGE : null;
            modalScope.receivingCompositionList = await this.getCompositionList({ OFFER_ID: this.$offerScope.model.ID, PAYMENT_NATURE: EPaymentNatureId.RECEIVING, CHARGE_NAME: modalScope.charge.CHARGE_NAME ? modalScope.charge.CHARGE_NAME.ID : null });
            modalScope.isReceivingWeightRangeApplication = charge.APPLICATION && charge.APPLICATION.APPLICATION_COMPLEMENT && charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
            modalScope.selectorValidity("chargeReceivingCurrency");
        }

        if (charge && charge.APPLICATION) {
            if (type == EPaymentNatureId.PAYMENT) modalScope.disableElements(charge.APPLICATION.INTERNAL_SEQUENCE == "001", null, ["chargePaymentApplicationWeightRange", "chargePaymentApplicationEquipment", "chargePaymentVehicleType", "chargePaymentAmount", "chargePaymentCurrency", "chargePaymentValueUnitary", "chargePaymentValueMin", "chargePaymentModality", "chargePaymentTransaction", "chargePaymentHolder", "chargePaymentInvoice", "chargeNameExhibitionPayment", "chargePaymentExhibition", "chargePaymentComposition", "chargePaymentSpecificity", "chargePaymentContract", "chargePaymentTable", "chargePaymentObservation"]);
            else if (type == EPaymentNatureId.RECEIVING) modalScope.disableElements(charge.APPLICATION.INTERNAL_SEQUENCE == "001", null, ["chargeReceivingApplicationWeightRange", "chargeReceivingApplicationEquipment", "chargeReceivingVehicleType", "chargeReceivingAmount", "chargeReceivingCurrency", "chargeReceivingValueUnitary", "chargeReceivingValueMin", "chargeReceivingModality", "chargeReceivingTransaction", "chargeReceivingHolder", "chargeReceivingInvoice", "chargeNameExhibitionReceiving", "chargeReceivingExhibition", "chargeReceivingComposition", "chargeReceivingSpecificity", "chargeReceivingContract", "chargeReceivingTable", "chargeReceivingObservation"]);

            // SCOB or PSNF
            if (charge.APPLICATION.INTERNAL_SEQUENCE == "001" || charge.APPLICATION.ID == "29") {
                if (type == EPaymentNatureId.PAYMENT) {
                    modalScope.charge.PAYMENT_CHARGE.VALUE_UNITARY = null;
                    modalScope.charge.PAYMENT_CHARGE.VALUE_MIN = null;
                    modalScope.charge.PAYMENT_CHARGE.AMOUNT = charge.APPLICATION.ID == "29" && this.$offerScope.model.COMMODITY_INVOICE_VALUE ? this.$offerScope.model.COMMODITY_INVOICE_VALUE : null;
                    modalScope.charge.PAYMENT_CHARGE.TOTAL = null;

                    modalScope.calculateTotal(modalScope.charge.PAYMENT_CHARGE, modalScope.oldCharge.PAYMENT_CHARGE);
                } else if (type == EPaymentNatureId.RECEIVING) {
                    modalScope.charge.RECEIVING_CHARGE.VALUE_UNITARY = null;
                    modalScope.charge.RECEIVING_CHARGE.VALUE_MIN = null;
                    modalScope.charge.RECEIVING_CHARGE.AMOUNT = charge.APPLICATION.ID == "29" && this.$offerScope.model.COMMODITY_INVOICE_VALUE ? this.$offerScope.model.COMMODITY_INVOICE_VALUE : null;
                    modalScope.charge.RECEIVING_CHARGE.TOTAL = null;

                    modalScope.calculateTotal(modalScope.charge.RECEIVING_CHARGE, modalScope.oldCharge.RECEIVING_CHARGE);
                }
            }

            if (charge.APPLICATION.APPLICATION_COMPLEMENT) {
                if (charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.NOT_APPLICABLE) {
                    charge.WEIGHT_RANGE = null;
                    charge.EQUIPMENT = null;
                    charge.VEHICLE_TYPE = null;
                }
                else if (charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE) {
                    charge.WEIGHT_RANGE = angular.copy(charge.WEIGHT_RANGE_ORIGINAL);
                    charge.EQUIPMENT = null;
                    charge.VEHICLE_TYPE = null;
                    this.$timeout(() => {
                        modalScope.selectorValidity(`charge${aux}ApplicationWeightRange`);
                    });
                }
                else if (charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.EQUIPMENT) {
                    charge.EQUIPMENT = angular.copy(charge.EQUIPMENT_ORIGINAL);
                    charge.WEIGHT_RANGE = null;
                    charge.VEHICLE_TYPE = null;
                    this.$timeout(() => {
                        modalScope.selectorValidity(`charge${aux}ApplicationEquipment`);
                    });
                }
                else if (charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.VEHICLE) {
                    charge.VEHICLE_TYPE = angular.copy(charge.VEHICLE_TYPE_ORIGINAL);
                    charge.WEIGHT_RANGE = null;
                    charge.EQUIPMENT = null;
                    this.$timeout(() => {
                        modalScope.selectorValidity(`charge${aux}VehicleType`);
                    });
                }
            }

            modalScope.getModalityTransactionDefault(type);
        } else {
            charge.WEIGHT_RANGE = null;
            charge.EQUIPMENT = null;
            charge.VEHICLE_TYPE = null;
        }

        if (modalScope.isPaymentWeightRangeApplication || modalScope.isReceivingWeightRangeApplication) {
            const offerWeightRangeList: IWeightRangeResponse[] = await this.getOfferWeightRange();
            if (offerWeightRangeList && offerWeightRangeList.length) {
                const weightRangeActive = offerWeightRangeList.find(weightRange => weightRange.ACTIVE);

                if (modalScope.isPaymentWeightRangeApplication) {
                    const weightRangeCharge: IWeightRangeCharge[] = offerWeightRangeList.map(offerWeightRange => { return { ACTIVE: offerWeightRange.ACTIVE, CURRENCY: modalScope.charge.PAYMENT_CHARGE.CURRENCY, VALUE_MIN: modalScope.charge.PAYMENT_CHARGE.VALUE_MIN, VALUE_UNITARY: modalScope.charge.PAYMENT_CHARGE.VALUE_UNITARY, WEIGHT_RANGE: { ID: offerWeightRange.ID, NAME: offerWeightRange.NAME } } });
                    modalScope.weightRangeActiveId = weightRangeActive.ID;
                    modalScope.charge.PAYMENT_CHARGE.CHARGE.WEIGHT_RANGE = { ID: weightRangeActive.ID, NAME: weightRangeActive.NAME };
                    if (!modalScope.charge.PAYMENT_CHARGE.WEIGHT_RANGE_CHARGE) modalScope.charge.PAYMENT_CHARGE.WEIGHT_RANGE_CHARGE = angular.copy(weightRangeCharge);
                }

                if (modalScope.isReceivingWeightRangeApplication) {
                    const weightRangeCharge: IWeightRangeCharge[] = offerWeightRangeList.map(offerWeightRange => { return { ACTIVE: offerWeightRange.ACTIVE, CURRENCY: modalScope.charge.RECEIVING_CHARGE.CURRENCY, VALUE_MIN: modalScope.charge.RECEIVING_CHARGE.VALUE_MIN, VALUE_UNITARY: modalScope.charge.RECEIVING_CHARGE.VALUE_UNITARY, WEIGHT_RANGE: { ID: offerWeightRange.ID, NAME: offerWeightRange.NAME } } });
                    modalScope.weightRangeActiveId = weightRangeActive.ID;
                    modalScope.charge.RECEIVING_CHARGE.CHARGE.WEIGHT_RANGE = { ID: weightRangeActive.ID, NAME: weightRangeActive.NAME };
                    if (!modalScope.charge.RECEIVING_CHARGE.WEIGHT_RANGE_CHARGE) modalScope.charge.RECEIVING_CHARGE.WEIGHT_RANGE_CHARGE = angular.copy(weightRangeCharge);
                }
            }
        }
    }

    private async transactionChange(type: string) {
        if (!type) return;

        const modalScope: IChargeModalScope = await this.modalService.getModalScope(this.modalChargeId);
        let holderToReplace = null;
        if (type == EPaymentNatureId.PAYMENT) {
            if (!(modalScope.charge.PAYMENT_CHARGE.TRANSACTION && modalScope.charge.PAYMENT_CHARGE.TRANSACTION.ID == ETransactionId.OTHER)) {
                modalScope.holderPaymentList = await this.getOfferChargeHolder(modalScope.charge.PAYMENT_CHARGE.TRANSACTION.ID);
                if (modalScope.holderPaymentList.length == 1) holderToReplace = modalScope.holderPaymentList[0];
            }
            modalScope.charge.PAYMENT_CHARGE.HOLDER = holderToReplace;
            this.$timeout(() => {
                modalScope.selectorValidity('chargePaymentHolder');
            }, 100);
        }
        else if (type == EPaymentNatureId.RECEIVING) {
            if (!(modalScope.charge.RECEIVING_CHARGE.TRANSACTION && modalScope.charge.RECEIVING_CHARGE.TRANSACTION.ID == ETransactionId.OTHER)) {
                modalScope.holderReceivingList = await this.getOfferChargeHolder(modalScope.charge.RECEIVING_CHARGE.TRANSACTION.ID);
                if (modalScope.holderReceivingList.length == 1) holderToReplace = modalScope.holderReceivingList[0];
            }
            modalScope.charge.RECEIVING_CHARGE.HOLDER = holderToReplace;
            this.$timeout(() => {
                modalScope.selectorValidity('chargeReceivingHolder');
            }, 100);
        }
    }

    private async calculateTotal(paymentReceivingCharge: IOfferPaymentReceiving, oldPaymentReceivingCharge: IOfferPaymentReceiving) {
        const modalScope: IChargeModalScope = await this.modalService.getModalScope(this.modalChargeId);
        if (paymentReceivingCharge) {
            const application = paymentReceivingCharge.CHARGE ? paymentReceivingCharge.CHARGE.APPLICATION : null;
            if (!application) {
                this.formService.notifyError("Select an application first.");
                return;
            }
            if (modalScope.charge.PAYMENT_CHARGE && modalScope.charge.PAYMENT_CHARGE.CHARGE && modalScope.charge.PAYMENT_CHARGE.CHARGE.UUID_REFERENCE) {
                for (const chargeOutDate of modalScope.chargesOutDate) {
                    if (chargeOutDate.PAYMENT_CHARGE && chargeOutDate.PAYMENT_CHARGE.CHARGE && chargeOutDate.PAYMENT_CHARGE.CHARGE.UUID_REFERENCE == modalScope.charge.PAYMENT_CHARGE.CHARGE.UUID_REFERENCE) {
                        chargeOutDate.PAYMENT_CHARGE.VALUE_MIN = modalScope.charge.PAYMENT_CHARGE.VALUE_MIN;
                        chargeOutDate.PAYMENT_CHARGE.VALUE_UNITARY = modalScope.charge.PAYMENT_CHARGE.VALUE_UNITARY;
                        chargeOutDate.PAYMENT_CHARGE.CURRENCY = modalScope.charge.PAYMENT_CHARGE.CURRENCY;
                    }
                }
            }
            if (modalScope.charge.RECEIVING_CHARGE && modalScope.charge.RECEIVING_CHARGE.CHARGE && modalScope.charge.RECEIVING_CHARGE.CHARGE.UUID_REFERENCE) {
                for (const chargeOutDate of modalScope.chargesOutDate) {
                    if (chargeOutDate.RECEIVING_CHARGE && chargeOutDate.RECEIVING_CHARGE.CHARGE && chargeOutDate.RECEIVING_CHARGE.CHARGE.UUID_REFERENCE == modalScope.charge.RECEIVING_CHARGE.CHARGE.UUID_REFERENCE) {
                        chargeOutDate.RECEIVING_CHARGE.VALUE_MIN = modalScope.charge.RECEIVING_CHARGE.VALUE_MIN;
                        chargeOutDate.RECEIVING_CHARGE.VALUE_UNITARY = modalScope.charge.RECEIVING_CHARGE.VALUE_UNITARY;
                        chargeOutDate.RECEIVING_CHARGE.CURRENCY = modalScope.charge.RECEIVING_CHARGE.CURRENCY;
                    }
                }
            }

            const complementId = this.getComplementId(paymentReceivingCharge.CHARGE);
            const data = {
                OFFER_ID: this.$offerScope.model.ID,
                CHARGE_ID: modalScope.charge._id,
                COMPLEMENT_ID: complementId,
                AMOUNT: paymentReceivingCharge.AMOUNT,
                VALUE_UNITARY: paymentReceivingCharge.VALUE_UNITARY,
                VALUE_MIN: paymentReceivingCharge.VALUE_MIN,
                APPLICATION: application,
                CURRENCY: paymentReceivingCharge.CURRENCY,
                OLD_TOTAL: oldPaymentReceivingCharge.TOTAL,
                OLD_VALUE_UNITARY: oldPaymentReceivingCharge.VALUE_UNITARY
            };
            const result: IOfferTabsChargeCalculateTotalResult = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/charge/calculate/total`, data, 30000, false);
            if (result) {
                paymentReceivingCharge.TOTAL = result.TOTAL;
                paymentReceivingCharge.AMOUNT = result.AMOUNT;
            }
            modalScope.$applyAsync();
        }
    }

    private async updatePaymentMin() {
        try {
            const modalScope: IChargeModalScope = await this.modalService.getModalScope(this.modalChargeId);
            if (!modalScope.charge) throw Error('Charge is null.');

            if (modalScope.charge.PAYMENT_CHARGE && modalScope.charge.PAYMENT_CHARGE.CHARGE && modalScope.charge.PAYMENT_CHARGE.CHARGE.APPLICATION) {
                if (!modalScope.charge.PAYMENT_CHARGE.CHARGE.APPLICATION.PERCENT) {
                    modalScope.charge.PAYMENT_CHARGE.VALUE_MIN = modalScope.charge.PAYMENT_CHARGE.VALUE_UNITARY;
                } else if (modalScope.charge.PAYMENT_CHARGE.CHARGE.APPLICATION.PERCENT) {
                    modalScope.charge.PAYMENT_CHARGE.VALUE_MIN = modalScope.charge.PAYMENT_CHARGE.VALUE_UNITARY;
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async updateReceivingMin() {
        try {
            const modalScope: IChargeModalScope = await this.modalService.getModalScope(this.modalChargeId);
            if (!modalScope.charge) throw Error('Charge is null.');

            if (modalScope.charge.RECEIVING_CHARGE && modalScope.charge.RECEIVING_CHARGE.CHARGE && modalScope.charge.RECEIVING_CHARGE.CHARGE.APPLICATION) {
                if (!modalScope.charge.RECEIVING_CHARGE.CHARGE.APPLICATION.PERCENT) {
                    modalScope.charge.RECEIVING_CHARGE.VALUE_MIN = modalScope.charge.RECEIVING_CHARGE.VALUE_UNITARY;
                } else if (modalScope.charge.RECEIVING_CHARGE.CHARGE.APPLICATION.PERCENT) {
                    modalScope.charge.RECEIVING_CHARGE.VALUE_MIN = modalScope.charge.RECEIVING_CHARGE.VALUE_UNITARY;
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async previousCharge(currentDisplayIndex: number, operationBefore: string) {
        const modalScope: IChargeModalScope = await this.modalService.getModalScope(this.modalChargeId);
        const previousChargeIndex = this.$scope.model.CHARGES && this.$scope.model.CHARGES.length ? this.$scope.model.CHARGES.findIndex(charge => charge.DISPLAY_INDEX == (currentDisplayIndex - 1)) : -1;
        if (previousChargeIndex >= 0) {
            if (this.$offerScope.hasChanges(JSON.stringify(modalScope.charge), JSON.stringify(modalScope.oldCharge)) && !(modalScope.charge && modalScope.charge.CHARGE_NAME && modalScope.charge.CHARGE_NAME.TYPE && (modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.COMISSION || modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.INSURANCE))) {
                const confirm = await this.$offerScope.modalSaveConfirmation("REGISTRATION.BACK", "GENERAL.CANCEL");
                if (!confirm || confirm && !await this.applyCharge(modalScope.charge, modalScope.oldCharge, modalScope.operation, null, false, currentDisplayIndex, previousChargeIndex, 'prev')) return;
                else this.getOfferTabsCharge();
            }
            modalScope.currentIndex = previousChargeIndex;
            modalScope.currentDisplayIndex--;

            const chargeUpdated = await this.getUpdatedCharge(this.$scope.model.CHARGES[previousChargeIndex]._id);
            if (chargeUpdated) {
                modalScope.charge = angular.copy(chargeUpdated);
                modalScope.oldCharge = angular.copy(chargeUpdated);
                if (modalScope.charge && modalScope.charge.CHARGE_NAME && modalScope.charge.CHARGE_NAME.TYPE && (modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.COMISSION || modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.INSURANCE)) modalScope.operation = EOperation.VIEW;
                else modalScope.operation = operationBefore;
                modalScope.disableElements(chargeUpdated.PAYMENT_CHARGE.CHARGE.APPLICATION.INTERNAL_SEQUENCE == "001" || modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.INSURANCE, null, ["chargePaymentApplicationWeightRange", "chargePaymentApplicationEquipment", "chargePaymentVehicleType", "chargePaymentAmount", "chargePaymentCurrency", "chargePaymentValueUnitary", "chargePaymentValueMin", "chargePaymentModality", "chargePaymentTransaction", "chargePaymentHolder", "chargePaymentInvoice", "chargeNameExhibitionPayment", "chargePaymentExhibition", "chargePaymentComposition", "chargePaymentSpecificity", "chargePaymentContract", "chargePaymentTable", "chargePaymentObservation"]);
                modalScope.disableElements(chargeUpdated.RECEIVING_CHARGE.CHARGE.APPLICATION.INTERNAL_SEQUENCE == "001" || modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.INSURANCE, null, ["chargeReceivingApplicationWeightRange", "chargeReceivingApplicationEquipment", "chargeReceivingVehicleType", "chargeReceivingAmount", "chargeReceivingCurrency", "chargeReceivingValueUnitary", "chargeReceivingValueMin", "chargeReceivingModality", "chargeReceivingTransaction", "chargeReceivingHolder", "chargeReceivingInvoice", "chargeNameExhibitionReceiving", "chargeReceivingExhibition", "chargeReceivingComposition", "chargeReceivingSpecificity", "chargeReceivingContract", "chargeReceivingTable", "chargeReceivingObservation"]);
                modalScope.isPaymentWeightRangeApplication = chargeUpdated.PAYMENT_CHARGE && chargeUpdated.PAYMENT_CHARGE.CHARGE && chargeUpdated.PAYMENT_CHARGE.CHARGE.APPLICATION && chargeUpdated.PAYMENT_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT && chargeUpdated.PAYMENT_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
                modalScope.isReceivingWeightRangeApplication = chargeUpdated.RECEIVING_CHARGE && chargeUpdated.RECEIVING_CHARGE.CHARGE && chargeUpdated.RECEIVING_CHARGE.CHARGE.APPLICATION && chargeUpdated.RECEIVING_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT && chargeUpdated.RECEIVING_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
            }
        }
    }

    private async nextCharge(currentDisplayIndex: number, operationBefore: string) {
        const modalScope: IChargeModalScope = await this.modalService.getModalScope(this.modalChargeId);
        const nextChargeIndex = this.$scope.model.CHARGES && this.$scope.model.CHARGES.length ? this.$scope.model.CHARGES.findIndex(charge => charge.DISPLAY_INDEX == (currentDisplayIndex + 1)) : -1;
        if (nextChargeIndex >= 0) {
            const propertiesToIgnore = this.propertiesToIgnore;
            const chargeToCompare = angular.copy(modalScope.charge);
            for (const property of propertiesToIgnore) {
                delete chargeToCompare[property];
            }
            const oldChargeToCompare = angular.copy(modalScope.oldCharge);
            for (const property of propertiesToIgnore) {
                delete oldChargeToCompare[property];
            }
            if (this.$offerScope.hasChanges(JSON.stringify(modalScope.charge), JSON.stringify(modalScope.oldCharge)) && !(modalScope.charge && modalScope.charge.CHARGE_NAME && modalScope.charge.CHARGE_NAME.TYPE && (modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.COMISSION || modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.INSURANCE))) {
                const confirm = await this.$offerScope.modalSaveConfirmation("GENERAL.NEXT", "GENERAL.CANCEL");
                if (!confirm || confirm && !await this.applyCharge(modalScope.charge, modalScope.oldCharge, modalScope.operation, null, false, currentDisplayIndex, nextChargeIndex, 'next')) return;
                else this.getOfferTabsCharge();
            }
            modalScope.currentIndex = nextChargeIndex;
            modalScope.currentDisplayIndex++;

            const chargeUpdated = await this.getUpdatedCharge(this.$scope.model.CHARGES[nextChargeIndex]._id);
            if (chargeUpdated) {
                modalScope.charge = angular.copy(chargeUpdated);
                modalScope.oldCharge = angular.copy(chargeUpdated);
                if (modalScope.charge && modalScope.charge.CHARGE_NAME && modalScope.charge.CHARGE_NAME.TYPE && (modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.COMISSION || modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.INSURANCE)) modalScope.operation = EOperation.VIEW;
                else modalScope.operation = operationBefore;
                modalScope.disableElements(chargeUpdated.PAYMENT_CHARGE.CHARGE.APPLICATION.INTERNAL_SEQUENCE == "001" || modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.INSURANCE, null, ["chargePaymentApplicationWeightRange", "chargePaymentApplicationEquipment", "chargePaymentVehicleType", "chargePaymentAmount", "chargePaymentCurrency", "chargePaymentValueUnitary", "chargePaymentValueMin", "chargePaymentModality", "chargePaymentTransaction", "chargePaymentHolder", "chargePaymentInvoice", "chargeNameExhibitionPayment", "chargePaymentExhibition", "chargePaymentComposition", "chargePaymentSpecificity", "chargePaymentContract", "chargePaymentTable", "chargePaymentObservation"]);
                modalScope.disableElements(chargeUpdated.RECEIVING_CHARGE.CHARGE.APPLICATION.INTERNAL_SEQUENCE == "001" || modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.INSURANCE, null, ["chargeReceivingApplicationWeightRange", "chargeReceivingApplicationEquipment", "chargeReceivingVehicleType", "chargeReceivingAmount", "chargeReceivingCurrency", "chargeReceivingValueUnitary", "chargeReceivingValueMin", "chargeReceivingModality", "chargeReceivingTransaction", "chargeReceivingHolder", "chargeReceivingInvoice", "chargeNameExhibitionReceiving", "chargeReceivingExhibition", "chargeReceivingComposition", "chargeReceivingSpecificity", "chargeReceivingContract", "chargeReceivingTable", "chargeReceivingObservation"]);
                modalScope.isPaymentWeightRangeApplication = chargeUpdated.PAYMENT_CHARGE && chargeUpdated.PAYMENT_CHARGE.CHARGE && chargeUpdated.PAYMENT_CHARGE.CHARGE.APPLICATION && chargeUpdated.PAYMENT_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT && chargeUpdated.PAYMENT_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
                modalScope.isReceivingWeightRangeApplication = chargeUpdated.RECEIVING_CHARGE && chargeUpdated.RECEIVING_CHARGE.CHARGE && chargeUpdated.RECEIVING_CHARGE.CHARGE.APPLICATION && chargeUpdated.RECEIVING_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT && chargeUpdated.RECEIVING_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
            }
        }
    }

    private async closeChargeModal() {
        const modalScope: IChargeModalScope = await this.modalService.getModalScope(this.modalChargeId);
        if (this.$offerScope.hasChanges(JSON.stringify(modalScope.charge), JSON.stringify(modalScope.oldCharge)) && !(modalScope.charge.CHARGE_NAME && modalScope.charge.CHARGE_NAME.TYPE && (modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.COMISSION || modalScope.charge.CHARGE_NAME.TYPE.ID == EChargeOriginId.INSURANCE))) {
            const confirm = await this.$offerScope.modalSaveConfirmation("GENERAL.CLOSE", "GENERAL.CLOSE");
            if (confirm && !await this.applyCharge(modalScope.charge, modalScope.oldCharge, modalScope.operation, null, true, null, null, 'close')) return;
            else this.getOfferTabsCharge();
        }
        this.modalService.closeModal(this.modalChargeId);
        this.modalChargeId = 0;
        angular.element('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
    }

    private prepareTextComposition(item: IPercentualComposition, complete: boolean): string {
        try {
            let result = "";
            if (item) {
                result = item.CHARGE_NAME_EXHIBITION ? item.CHARGE_NAME_EXHIBITION.CODE : '';

                if (result !== "") result += item.APPLICATION ? " - " + item.APPLICATION.CODE : '';
                else result += item.APPLICATION ? item.APPLICATION.CODE : '';

                if (item.EQUIPMENT || item.WEIGHT_RANGE || item.VEHICLE_TYPE) {
                    result += '(' + (item.EQUIPMENT ? item.EQUIPMENT.CODE : (item.WEIGHT_RANGE ? item.WEIGHT_RANGE.NAME : item.VEHICLE_TYPE.NAME)) + ')';
                }

                if (complete) result += ' - ' + this.$filter('number')(item.TOTAL, 2) + ' (' + item.CURRENCY.CODE + ')';

                return result;
            }
            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getDefaultApplication(selected: IChargeNameList): Promise<void> {
        let param: IParams = null;
        try {
            this.formService.block();
            const modalScope: IChargeModalScope = await this.modalService.getModalScope(this.modalChargeId);
            if (selected.PARAMS && selected.PARAMS.length > 0) param = selected.PARAMS.find(param => param.TYPE_CARGO.find(typeCargo => typeCargo.ID === this.$offerScope.model.TYPE_CARGO.ID));
            if (param) {
                const paymentApplicationIdBefore = modalScope.charge.PAYMENT_CHARGE.CHARGE.APPLICATION ? modalScope.charge.PAYMENT_CHARGE.CHARGE.APPLICATION.ID : null;
                const receivingApplicationIdBefore = modalScope.charge.RECEIVING_CHARGE.CHARGE.APPLICATION ? modalScope.charge.RECEIVING_CHARGE.CHARGE.APPLICATION.ID : null;
                modalScope.charge.PAYMENT_CHARGE.CHARGE.APPLICATION = param.PAYMENT;
                modalScope.charge.RECEIVING_CHARGE.CHARGE.APPLICATION = param.RECEIVING;
                modalScope.isPaymentWeightRangeApplication = modalScope.charge.PAYMENT_CHARGE && modalScope.charge.PAYMENT_CHARGE.CHARGE && modalScope.charge.PAYMENT_CHARGE.CHARGE.APPLICATION && modalScope.charge.PAYMENT_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT && modalScope.charge.PAYMENT_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
                modalScope.isReceivingWeightRangeApplication = modalScope.charge.RECEIVING_CHARGE && modalScope.charge.RECEIVING_CHARGE.CHARGE && modalScope.charge.RECEIVING_CHARGE.CHARGE.APPLICATION && modalScope.charge.RECEIVING_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT && modalScope.charge.RECEIVING_CHARGE.CHARGE.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
                this.$timeout(() => { modalScope.selectorValidity(`chargePaymentApplication`); });
                this.$timeout(() => { modalScope.selectorValidity(`chargeReceivingApplication`); });
                this.applicationChange(EPaymentNatureId.PAYMENT, paymentApplicationIdBefore);
                this.applicationChange(EPaymentNatureId.RECEIVING, receivingApplicationIdBefore);
            }
            modalScope.getModalityTransactionDefault();
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getModalityTransactionDefault(type?: string, currencyId?: string): Promise<void> {
        try {
            const modalScope: IChargeModalScope = await this.modalService.getModalScope(this.modalChargeId);
            if (!type || type === EPaymentNatureId.PAYMENT) {
                if (modalScope.charge && modalScope.charge.PAYMENT_CHARGE && modalScope.charge.PAYMENT_CHARGE.CHARGE && modalScope.charge.PAYMENT_CHARGE.CHARGE.APPLICATION) {
                    const modalities = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/charge/modalityTransaction`, {
                        id: this.$offerScope.model.ID,
                        application: modalScope.charge.PAYMENT_CHARGE.CHARGE.APPLICATION.ID,
                        chargeNameId: modalScope.charge && modalScope.charge.CHARGE_NAME ? parseInt(modalScope.charge.CHARGE_NAME.ID) : null,
                        paymentNature: EPaymentNatureId.PAYMENT,
                        currencyId: null
                    }, 30000, false);

                    if (modalities) {
                        modalScope.charge.PAYMENT_CHARGE.TRANSACTION = modalities.TRANSACTION ? modalities.TRANSACTION : null;
                        modalScope.charge.PAYMENT_CHARGE.MODALITY = modalities.MODALITY ? modalities.MODALITY : null;
                    }
                }
            }

            if (!type || type === EPaymentNatureId.RECEIVING) {
                if (modalScope.charge && modalScope.charge.RECEIVING_CHARGE && modalScope.charge.RECEIVING_CHARGE.CHARGE && modalScope.charge.RECEIVING_CHARGE.CHARGE.APPLICATION) {
                    const modalities = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/charge/modalityTransaction`, {
                        id: this.$offerScope.model.ID,
                        application: modalScope.charge.RECEIVING_CHARGE.CHARGE.APPLICATION.ID,
                        chargeNameId: modalScope.charge && modalScope.charge.CHARGE_NAME ? parseInt(modalScope.charge.CHARGE_NAME.ID) : null,
                        paymentNature: EPaymentNatureId.RECEIVING,
                        currencyId
                    }, 30000, false);

                    if (modalities) {
                        modalScope.charge.RECEIVING_CHARGE.TRANSACTION = modalities.TRANSACTION ? modalities.TRANSACTION : null;
                        modalScope.charge.RECEIVING_CHARGE.MODALITY = modalities.MODALITY ? modalities.MODALITY : null;
                    }
                }
            }

            this.$timeout(() => {
                modalScope.selectorValidity(`chargePaymentModality`);
                modalScope.selectorValidity(`chargePaymentTransaction`);
                modalScope.selectorValidity(`chargeReceivingModality`);
                modalScope.selectorValidity(`chargeReceivingTransaction`);
            }, 500);
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {

        }
    }

    private async populateDefaultChargeNameExhibition(data) {
        const modalScope: IChargeModalScope = await this.modalService.getModalScope(this.modalChargeId);
        if (!modalScope.charge.PAYMENT_CHARGE.CHARGE) modalScope.charge.PAYMENT_CHARGE.CHARGE = <IOfferPaymentReceivingCharge>{};
        if (!modalScope.charge.RECEIVING_CHARGE.CHARGE) modalScope.charge.RECEIVING_CHARGE.CHARGE = <IOfferPaymentReceivingCharge>{};
        modalScope.chargeNameExhibitionList = await this.getChargeNameExhibitionListByName(null, modalScope.charge && modalScope.charge.CHARGE_NAME ? parseInt(modalScope.charge.CHARGE_NAME.ID) : null, false);
        if (!modalScope.charge.PAYMENT_CHARGE || !modalScope.charge.PAYMENT_CHARGE.CHARGE.APPLICATION || modalScope.charge.PAYMENT_CHARGE.CHARGE.APPLICATION.INTERNAL_SEQUENCE != EApplicationInternalSequence.SCOB) {
            modalScope.charge.PAYMENT_CHARGE.CHARGE.CHARGE_NAME_EXHIBITION = data && data.CHARGE_NAME_EXHIBITION_FOUND ? {
                ID: data.CHARGE_NAME_EXHIBITION_FOUND.ID,
                NAME: data.CHARGE_NAME_EXHIBITION_FOUND.NAME,
                CODE: data.CHARGE_NAME_EXHIBITION_FOUND.CODE,
                NAME_INTL: data.CHARGE_NAME_EXHIBITION_FOUND.NAME_INTL
            } : {
                ID: modalScope.chargeNameExhibitionList[0].ID,
                NAME: modalScope.chargeNameExhibitionList[0].NAME,
                CODE: modalScope.chargeNameExhibitionList[0].CODE,
                NAME_INTL: null
            };
        }
        if (!modalScope.charge.RECEIVING_CHARGE || !modalScope.charge.RECEIVING_CHARGE.CHARGE.APPLICATION || modalScope.charge.RECEIVING_CHARGE.CHARGE.APPLICATION.INTERNAL_SEQUENCE != EApplicationInternalSequence.SCOB) {
            modalScope.charge.RECEIVING_CHARGE.CHARGE.CHARGE_NAME_EXHIBITION = data && data.CHARGE_NAME_EXHIBITION_FOUND ? {
                ID: data.CHARGE_NAME_EXHIBITION_FOUND.ID,
                NAME: data.CHARGE_NAME_EXHIBITION_FOUND.NAME,
                CODE: data.CHARGE_NAME_EXHIBITION_FOUND.CODE,
                NAME_INTL: data.CHARGE_NAME_EXHIBITION_FOUND.NAME_INTL
            } : {
                ID: modalScope.chargeNameExhibitionList[0].ID,
                NAME: modalScope.chargeNameExhibitionList[0].NAME,
                CODE: modalScope.chargeNameExhibitionList[0].CODE,
                NAME_INTL: null
            };
        }
        this.$timeout(() => {
            modalScope.selectorValidity("chargeNameExhibitionPayment");
            modalScope.selectorValidity("chargeNameExhibitionReceiving");
        });
    }
    /* Offer Charge Modal - Functions - Fim */

    private async getOfferChargeHolder(transactionId: string): Promise<IHolderSelector[]> {
        let result: IHolderSelector[] = [];
        try {
            this.formService.block();
            const holderResult = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/charge/holder`, { id: this.$offerScope.model.ID, transaction: transactionId }, 30000, false);
            if (holderResult && holderResult.length) result = holderResult;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getEquipmentList(chargeId: string, paymentNatureId: string) {
        let equipmentList: IEquipmentSelector[] = [];
        try {
            this.formService.block();
            const equipmentResult = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/charge/equipment`, { OFFER_ID: this.$offerScope.model.ID, CHARGE_ID: chargeId, PAYMENT_NATURE: paymentNatureId }, 30000, false);
            equipmentList = equipmentResult && equipmentResult.length ? equipmentResult.map(x => { return { ID: x.ID, NAME: x.NAME, TEU: x.TEU, FEET: x.FEET, CODE: x.CODE, optional: ["CODE"] } }) : [];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return equipmentList;
        }
    }

    private getComplementId(charge: IOfferPaymentReceivingCharge) {
        let complementId = null;
        try {
            const applicationComplementId = charge.APPLICATION && charge.APPLICATION.APPLICATION_COMPLEMENT ? charge.APPLICATION.APPLICATION_COMPLEMENT.ID : null;
            if (applicationComplementId == EApplicationComplementId.EQUIPMENT) complementId = charge.EQUIPMENT ? charge.EQUIPMENT.ID : null;
            if (applicationComplementId == EApplicationComplementId.WEIGHT_RANGE) complementId = charge.WEIGHT_RANGE ? charge.WEIGHT_RANGE.ID : null;
            if (applicationComplementId == EApplicationComplementId.VEHICLE) complementId = charge.VEHICLE_TYPE ? charge.VEHICLE_TYPE.ID : null;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return complementId;
        }
    }

    private async applyCharge(charge: IOfferCharge, oldCharge: IOfferCharge, operation: string, confirmationToUpdate?: IOfferChangeConfirmation, closeModal?: boolean, currentDisplayIndex?: number, chargeIndexTo?: number, action?: string): Promise<boolean> {
        const modalScope: IChargeModalScope = await this.modalService.getModalScope(this.modalChargeId);
        let success = false;
        try {
            if (!charge) throw Error('charge is null');
            const hasInvalid = !charge.CHARGE_NAME || this.hasInvalidRequiredElements('offerChargeModalPayment') || this.hasInvalidRequiredElements('offerChargeModalReceiving');
            if (hasInvalid) return false;
            this.formService.block();
            await this.beforeSaveCharge();
            let request = null;
            if (operation === EOperation.NEW) {
                request = await this.productService.post({ route: '/offer/tabs/charge/insert', data: { id: this.$offerScope.model.ID, data: charge, oldData: oldCharge, confirmation: confirmationToUpdate } });
            } else {
                request = await this.productService.post({ route: '/offer/tabs/charge/update', data: { id: this.$offerScope.model.ID, data: charge, oldData: oldCharge, confirmation: confirmationToUpdate } });
            }

            if (request && request.data && request.data.data) {
                const confirmation: boolean = request.data.data.confirmation;
                if (!confirmation) {
                    success = false;
                    this.formService.unblock();
                    const confirmationData: IOfferChangeConfirmation = request.data.data.data;
                    this.openReasonModal(charge, oldCharge, confirmationData, operation, currentDisplayIndex, chargeIndexTo, action);
                } else if (confirmation || closeModal) {
                    if (action === 'next' || action === 'prev') {
                        modalScope.currentIndex = chargeIndexTo;
                        action === 'next' ? modalScope.currentDisplayIndex++ : modalScope.currentDisplayIndex--;
                        const chargeUpdated = await this.getUpdatedCharge(this.$scope.model.CHARGES[chargeIndexTo]._id);
                        if (chargeUpdated) {
                            modalScope.charge = angular.copy(chargeUpdated);
                            modalScope.oldCharge = angular.copy(chargeUpdated);
                        }
                    } else if (action === 'close' || operation === EOperation.NEW) {
                        this.modalService.closeModal(this.modalChargeId);
                        this.modalChargeId = 0;
                        angular.element('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
                    } else if (operation === EOperation.EDIT) {
                        modalScope.currentIndex = chargeIndexTo;
                        modalScope.currentDisplayIndex;
                        const chargeUpdated = await this.getUpdatedCharge(this.$scope.model.CHARGES[chargeIndexTo]._id);
                        if (chargeUpdated) {
                            modalScope.charge = angular.copy(chargeUpdated);
                            modalScope.oldCharge = angular.copy(chargeUpdated);
                        }
                    }
                    success = true;
                }
            }

            if (success && closeModal) {
                this.modalService.closeModal(this.modalChargeId);
                this.modalChargeId = 0;
                angular.element('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
            }

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            const msgSuccess = this.formService.getTranslate('FINANCIAL.CHARGE_DATA_SAVED_SUCCESSFULLY');
            if (success) {
                this.formService.notifySuccess(msgSuccess);
                await this.getOfferTabsCharge();
                return success;
            }
        }
    }

    private async saveCharge(currentDisplayIndex: number) {
        const modalScope: IChargeModalScope = await this.modalService.getModalScope(this.modalChargeId);
        const chargeIndex = this.$scope.model.CHARGES && this.$scope.model.CHARGES.length ? this.$scope.model.CHARGES.findIndex(charge => charge.DISPLAY_INDEX == currentDisplayIndex) : currentDisplayIndex;

        await this.applyCharge(modalScope.charge, modalScope.oldCharge, modalScope.operation, null, false, currentDisplayIndex, chargeIndex)
    }

    private async openReasonModal(charge: IOfferCharge, oldCharge: IOfferCharge, confirmationData: IOfferChangeConfirmation, action: string, currentDisplayIndex?: number, chargeIndexTo?: number, operation?: string) {
        try {
            this.modalReasonId = this.modalService.newModal();
            this.$scope.reasonModal = { DESCRIPTION: confirmationData.DESCRIPTION, REASON_OBSERVATION: null, CHANGE_REASON: null, USER: confirmationData.CREATED_BY.NAME };

            const body = `
                        <div id="reasonModal">
                <div class="row form-group">
                    <div class="col-lg-6">
                        <label>{{'OPERATIONAL.UPDATE_REASON' | translate}} <i class="fa fa-asterisk text-danger small-fontawesome"
                                aria-hidden="true" tooltip-placement="auto top"
                                uib-tooltip="{{ 'GENERAL.MANDATORY_FIELD' | translate }}" tooltip-append-to-body="true"></i></label>
                        <ui-select name="reasonChange" id="reasonChange" ng-model="reasonModal.CHANGE_REASON"
                            theme="bootstrap" ng-change="selectorValidity(this.$select.ngModel.$name);"
                            ng-disabled="selectorDisabled(this.$select.ngModel.$name) || operation == 'view'"
                            ng-click="selectorFocus(this.$select.searchInput[0]);" skip-focusser="true" required>
                            <ui-select-match placeholder="{{'GENERAL.UI_SELECT.SELECT' | translate }}">
                                {{$select.selected.NAME}}
                            </ui-select-match>
                            <ui-select-choices repeat="item in reasonChangeList | filter: $select.search track by $index">
                                <div ng-bind-html="item.NAME | highlight: $select.search">
                                </div>
                            </ui-select-choices>
                            <ui-select-no-choice>
                                {{'GENERAL.UI_SELECT.EMPTY_SELECTOR_MESSAGE' | translate }}
                            </ui-select-no-choice>
                        </ui-select>
                    </div>
                    <div class="col-lg-6">
                    <label>{{'GENERAL.USER' | translate}}</label>
                    <input type="text" name="user" class="form-control"
                    ng-model="reasonModal.USER" readonly>
                    </div>
                </div>
                <div class="row form-group" ng-if="reasonModal.CHANGE_REASON.ID == '3' || reasonModal.CHANGE_REASON.ID == '4' || reasonModal.CHANGE_REASON.ID == '5' || reasonModal.CHANGE_REASON.ID == '6'">
                    <div class="col-lg-12">
                        <label>{{'GENERAL.REMARKS' | translate}} <i class="fa fa-asterisk text-danger small-fontawesome"
                                aria-hidden="true" tooltip-placement="auto top"
                                uib-tooltip="{{ 'GENERAL.MANDATORY_FIELD' | translate }}" tooltip-append-to-body="true"></i></label>
                        <textarea class="form-control" name="reasonObservation"
                            ng-model="reasonModal.REASON_OBSERVATION"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 space-list">
                        <ul class="list-group m-b-none">
                            <li class="list-group-item list-group-item-warning ellipsize full-width"
                                ng-repeat="description in reasonModal.DESCRIPTION track by $index" style="max-width: 100%;"
                                ellipsis-tooltip tooltip-placement="auto top" uib-tooltip-html="description" tooltip-enable="true"
                                tooltip-append-to-body="true">
                                <span ng-bind-html="description"></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            `;

            const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                {
                    modalID: this.modalReasonId,
                    scope: this.$scope,
                    formService: 'register',
                    size: 'lg modal-overflow',
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing" && closed) {
                            const modalData: IReasonUpdateModal = this.$scope.reasonModal;
                            const reasonsWithObservation = [EOfferChangeConfirmationReason.DOMESTIC_TARIFF_DOES_NOT_MEET, EOfferChangeConfirmationReason.LOCAL_TARIFF_DOES_NOT_MEET, EOfferChangeConfirmationReason.SHIPPING_TARIFF_DOES_NOT_MEET, EOfferChangeConfirmationReason.OTHERS];
                            const hasObservation = reasonsWithObservation.some(reasonId => modalData.CHANGE_REASON && modalData.CHANGE_REASON.ID == reasonId);
                            if (!modalData || (modalData && !modalData.CHANGE_REASON) || (hasObservation && !modalData.REASON_OBSERVATION)) {
                                event.preventDefault();
                                const msgError = this.formService.getTranslate('GENERAL.ALL_FIELDS_MANDATORY');
                                this.formService.notifyError(msgError);
                            }
                            else if (action == EOperation.EDIT && !await this.applyCharge(charge, oldCharge, EOperation.EDIT, { _id: null, ID: null, ID_OFFER: confirmationData.ID_OFFER, ID_OFFER_CHARGE: confirmationData.ID_OFFER_CHARGE, UPDATED_BY: confirmationData.UPDATED_BY, UPDATED_AT: null, CREATED_BY: confirmationData.CREATED_BY, CREATED_AT: null, OBSERVATION: modalData.REASON_OBSERVATION, DESCRIPTION: modalData.DESCRIPTION, CHANGE_REASON: modalData.CHANGE_REASON, ACTION: action }, false, currentDisplayIndex, chargeIndexTo, operation)) event.preventDefault();
                            else if (action == EOperation.REMOVE && !await this.removeCharge(this.$scope.model.CHARGES.indexOf(charge), { _id: null, ID: null, ID_OFFER: confirmationData.ID_OFFER, ID_OFFER_CHARGE: confirmationData.ID_OFFER_CHARGE, UPDATED_BY: confirmationData.UPDATED_BY, UPDATED_AT: null, CREATED_BY: confirmationData.CREATED_BY, CREATED_AT: null, OBSERVATION: modalData.REASON_OBSERVATION, DESCRIPTION: modalData.DESCRIPTION, CHANGE_REASON: modalData.CHANGE_REASON, ACTION: action })) event.preventDefault();
                            else if (action == EOperation.NEW && !await this.applyCharge(charge, oldCharge, EOperation.NEW, { _id: null, ID: null, ID_OFFER: confirmationData.ID_OFFER, ID_OFFER_CHARGE: confirmationData.ID_OFFER_CHARGE, UPDATED_BY: confirmationData.UPDATED_BY, UPDATED_AT: null, CREATED_BY: confirmationData.CREATED_BY, CREATED_AT: null, OBSERVATION: modalData.REASON_OBSERVATION, DESCRIPTION: modalData.DESCRIPTION, CHANGE_REASON: modalData.CHANGE_REASON, ACTION: action })) event.preventDefault();
                        }
                    }
                },
                {
                    closeButtonText: 'GENERAL.CANCEL',
                    actionButtonText: 'REGISTRATION.APPLY',
                    headerText: 'PRODUCT.OFFER_CHANGE_CONFIRMATION',
                    bodyText: this.$sce.trustAsHtml(body),

                }
            );

            modalInstance.rendered.then(() => {
                const reasonModal = angular.element("#reasonModal");
                if (reasonModal) this.$compile(reasonModal)(this.$scope);
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async viewLogConfirmation(): Promise<void> {
        try {
            this.formService.block();
            const retrieveLog = await this.productService.get({ route: `/offerChangeConfirmation/tabs/charge/getOfferChangeConfirmation/offer/${this.$offerScope.model.ID}` });
            this.formService.unblock();
            if (retrieveLog && retrieveLog.data && retrieveLog.data.data && retrieveLog.data.data.length) {
                this.$scope.offerChangeConfirmation = retrieveLog.data.data;
                const modalId = this.modalService.newModal();
                await this.modalService.showModalConfirmation(
                    {
                        modalID: modalId,
                        scope: this.$scope,
                        template: require("../view/modals/offerChangeConfirmationLogModal.html"),
                        size: 'vlg modal-overflow'
                    },
                    {
                        closeButtonText: 'GENERAL.CLOSE'
                    }
                );
            } else this.formService.notifyInfo(this.formService.getTranslate("REGISTRATION.NO_RECORD_FOUND"));
        } catch (ex) {
            this.formService.handleError(ex);
            this.formService.unblock();
        }
    }

    private async getChargeNameListByName(search?: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const products = this.$offerScope.model.PRODUCT ? [this.$offerScope.model.PRODUCT.ID] : null;
            const paramTypeCargo = this.$offerScope.model.TYPE_CARGO ? [this.$offerScope.model.TYPE_CARGO.ID] : null;
            const types = [EChargeOriginId.FREIGHT, EChargeOriginId.ORIGIN, EChargeOriginId.DESTINATION, EChargeOriginId.DET_DEM, EChargeOriginId.INSURANCE, EChargeOriginId.TAX, EChargeOriginId.OTHERS]

            const request: IMonacoRequest = {
                data: { search, paramTypeCargo, products, types },
                route: `/chargeName/list/custom/withexhibition`,
                timeout: 30000,
            };

            const { data: charges } = await this.productService.post(request, false);

            if (charges && charges.data) {
                result = charges.data.map(charge => {
                    const found = charge.CHARGE_NAME_EXHIBITION.find((ex => ex.NAME.toUpperCase().indexOf(search.toUpperCase()) > -1 || ex.NAME_INTL.toUpperCase().indexOf(search.toUpperCase()) > -1));
                    return {
                        ID: charge.ID,
                        NAME: charge.NAME,
                        CODE: charge.CODE,
                        CHARGE_NAME_EXHIBITION: charge.CHARGE_NAME_EXHIBITION,
                        CHARGE_NAME_EXHIBITION_DEFAULT: charge.CHARGE_NAME_EXHIBITION_DEFAULT,
                        PARAMS: charge.PARAMS,
                        CHARGE_NAME_EXHIBITION_FOUND: found ? found : charge.CHARGE_NAME_EXHIBITION_DEFAULT,
                        DISPLAY_PAYMENT: charge.DISPLAY_PAYMENT ? charge.DISPLAY_PAYMENT : null,
                        DISPLAY_RECEIVING: charge.DISPLAY_RECEIVING ? charge.DISPLAY_RECEIVING : null
                    }
                });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getChargeNameExhibitionListByName(search?: string, chargeNameId?: number, getDefault?: boolean): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const product = this.$offerScope.model.PRODUCT ? [this.$offerScope.model.PRODUCT.ID] : null;
            const typeCargo = this.$offerScope.model.TYPE_CARGO ? [this.$offerScope.model.TYPE_CARGO.ID] : null;
            const charges = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/chargeName/list/custom/exhibition`, { search: search, products: product, paramTypeCargo: typeCargo, chargeNameId }, 30000, false);
            if (charges) result = charges.map(charge => { return { ID: charge.ID, NAME: charge.NAME, CODE: charge.CODE, NAME_INTL: charge.NAME_INTL, DEFAULT: charge.DEFAULT } });
            if (getDefault) {
                result = result.filter(x => x.DEFAULT == true);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getOutDateChargesByCharge(chargeId: number): Promise<IOfferCharge[]> {
        let result = [];
        this.formService.block();
        try {
            const request = await this.productService.get({ route: `/offer/tabs/chargeOutDate/getByReference/${chargeId}` });
            if (request && request.data && request.data.data) result = request.data.data.sort((a, b) => {
                const dateA = new Date(a.PAYMENT_CHARGE.VALIDITY_START).getTime();
                const dateB = new Date(b.PAYMENT_CHARGE.VALIDITY_START).getTime();
                return dateA - dateB;
            });

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getLegalPersonListByName(legalPersonFilter: ILegalPersonListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        try {
            this.formService.block();
            const legalPersons = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/legalPerson/list/custom`, legalPersonFilter, 30000, false);
            if (legalPersons) result = legalPersons.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getPhysicalPersonListByName(physicalPersonFilter?: IPhysicalPersonListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const physicalPersons = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/physicalPerson/list/custom`, physicalPersonFilter, 30000, false);
            if (physicalPersons) result = physicalPersons.map(physicalPerson => { return { ID: physicalPerson.ID, NAME: physicalPerson.NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getWeightRangeList(filter: IWeightRangeListCustomFilter): Promise<IWeightRangeSelector[]> {
        let result: IWeightRangeSelector[] = [];
        try {
            const weightRangeList = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/weightRange/list/custom`, filter, 30000, false);
            if (weightRangeList) result = weightRangeList.map(x => { return { ID: x.ID, NAME: x.NAME, MIN_WEIGHT: x.MIN_WEIGHT, MAX_WEIGHT: x.MAX_WEIGHT } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return result;
        }
    }

    private async getCompositionList(filter: any): Promise<IOfferPercentualComposition[]> {
        let result: IOfferPercentualComposition[] = [];
        try {
            const compositionList = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/charge/composition`, filter, 30000, false);
            if (compositionList) result = compositionList;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return result;
        }
    }

    private async getChargeShownForList(): Promise<Array<SelectorModel>> {
        try {
            let resultList: SelectorModel[] = [];

            const { data: generic } = await this.helperService.get(`/generic/getByIdentifier/charge_shown_for`, null, 15000);

            if ((generic.status !== 200) || (!generic.data)) return null;

            let selectorList = generic && generic.data ? generic.data : [];

            for (const item of selectorList) {
                const selectorModel = <SelectorModel>{};
                selectorModel.ID = item.CODE;
                selectorModel.NAME = item.VALUE;
                selectorModel.CODE = item.ALTERNATIVE;
                resultList.push(selectorModel);
            }

            if (resultList.length > 0) resultList = resultList.sort((x, y) => x.ID < y.ID ? -1 : 1);

            return resultList;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getApplicationListByName(search: string): Promise<IApplicationList[]> {
        let result: IApplicationList[] = [];
        try {
            this.formService.block();
            const product = this.$offerScope.model.PRODUCT ? [this.$offerScope.model.PRODUCT.ID] : null;
            const types = this.$offerScope.model.TYPE_CARGO ? [this.$offerScope.model.TYPE_CARGO.ID] : null;
            const application = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/application/list/custom`, { search, products: product, typeCargos: types }, 10000, false);
            if (application) {
                result = application.map(x => { return { ID: x.ID, NAME: x.NAME, PERCENT: x.PERCENT, APPLICATION_COMPLEMENT: x.APPLICATION_COMPLEMENT, INTERNAL_SEQUENCE: x.INTERNAL_SEQUENCE, FREE_TYPING_AMOUNT_CHARGE: x.FREE_TYPING_AMOUNT_CHARGE, CT_WITHOUT_DOC: x.CT_WITHOUT_DOC, NAME_INTL: x.NAME_INTL, CODE: x.CODE, optional: ["CODE"] } });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: "PAYMENT", LABEL: "FINANCIAL.DEBIT" },
            { PROPERTY: "RECEIVING", LABEL: "FINANCAL.CREDIT" },
            { PROPERTY: "NAME", LABEL: "GENERAL.NAME" },
            { PROPERTY: "CODE", LABEL: "GENERAL.CODE" },
            { PROPERTY: "CHARGE_VALUE", LABEL: "FINANCIAL.CHARGE_DIFFERENCE" },
            { PROPERTY: "CHARGE_NAME", LABEL: "BASIC_DATA.CHARGE" },
            { PROPERTY: "PAYMENT_CHARGE", LABEL: "FINANCIAL.PAYMENT_CHARGE" },
            { PROPERTY: "RECEIVING_CHARGE", LABEL: "FINANCIAL.RECEIPT_CHARGE" },
            { PROPERTY: "OFFER_INVOICE", LABEL: "FINANCIAL.INVOICE_NUMBER" },
            { PROPERTY: "AMOUNT", LABEL: "GENERAL.QUANTITY" },
            { PROPERTY: "VALUE_UNITARY", LABEL: "FINANCIAL.UNITARY_VALUE" },
            { PROPERTY: "VALUE_MIN", LABEL: "GENERAL.MINIMUM_VALUE" },
            { PROPERTY: "TOTAL", LABEL: "GENERAL.TOTAL" },
            { PROPERTY: "CURRENCY", LABEL: "GENERAL.CURRENCY" },
            { PROPERTY: "CHARGE", LABEL: " " },
            { PROPERTY: "CONTRACT_NUMBER", LABEL: "GENERAL.CONTRACT_NUMBER" },
            { PROPERTY: "CONCATENATED", LABEL: "GENERAL.CONCATENATED" },
            { PROPERTY: "TARIFF_FREIGHT_CONCATENATED", LABEL: "PRODUCT.FREIGHT_TARIFF_CONCATENATED" },
            { PROPERTY: "TARIFF_LOCAL_CONCATENATED", LABEL: "PRODUCT.LOCAL_TARIFF_CONCATENATED" },
            { PROPERTY: "TARIFF_DOMESTIC_CONCATENATED", LABEL: "PRODUCT.DOMESTIC_TARIFF_CONCATENATED" },
            { PROPERTY: "APPLICATION", LABEL: "FINANCIAL.CHARGE_BASIS" },
            { PROPERTY: "WEIGHT_RANGE", LABEL: "GENERAL.WEIGHT_RANGE" },
            { PROPERTY: "EQUIPMENT", LABEL: "BASIC_DATA.EQUIPMENT" },
            { PROPERTY: "VEHICLE_TYPE", LABEL: "GENERAL.VEHICLE_TYPE" },
            { PROPERTY: "PAYMENT_MIN", LABEL: "FINANCIAL.MINIMUM_PAYMENT " },
            { PROPERTY: "PAYMENT_UNITARY", LABEL: "FINANCIAL.UNITARY_PAYMENT" },
            { PROPERTY: "RECEIVING_MIN", LABEL: "FINANCIAL.MINIMUM_RECEIPT " },
            { PROPERTY: "RECEIVING_UNITARY", LABEL: "FINANCIAL.UNITARY_RECEIPT" },
            { PROPERTY: "GROUP", LABEL: "GENERAL.GROUP" },
            { PROPERTY: "SPECIFICITY", LABEL: "GENERAL.SPECIFICITIES" },
            { PROPERTY: "RATE_TYPE", LABEL: "GENERAL.ERP_NEGOTIATE_TYPE" },
            { PROPERTY: "HOLDER_TYPE", LABEL: "GENERAL.HOLDER_TYPE" },
            { PROPERTY: "LEGAL_PERSON_HOLDER", LABEL: "ENTITY.LEGAL_PERSON" },
            { PROPERTY: "PHYSICAL_PERSON_HOLDER", LABEL: "GENERAL.PHYSICAL_PERSON" },
            { PROPERTY: "SHORT_NAME", LABEL: "GENERAL.HOLDER" },
            { PROPERTY: "COMPOSITION", LABEL: "GENERAL.COMPOSITION" },
            { PROPERTY: "CHARGE_NAME_EXHIBITION", LABEL: "BASIC_DATA.CHARGE_DISPLAY" },
            { PROPERTY: "ERROR", LABEL: "OPERATIONAL.HAS_ERROR" },
            { PROPERTY: "REASON", LABEL: "OPERATIONAL.FAULT_REASON" },
            { PROPERTY: "PERCENTUAL_COMPOSITION", LABEL: "FINANCIAL.PERCENTAGE_COMPOSITION" },
            { PROPERTY: "PERCENTUAL_COMPOSITION_CHARGE_NAME_EXHIBITION", LABEL: "GENERAL.COMPOSITION" },
            { PROPERTY: "MODALITY", LABEL: "FINANCIAL.CHARGE_PAYMENT_METHOD" },
            { PROPERTY: "TRANSACTION", LABEL: "BASIC_DATA.TRANSACTION" },
            { PROPERTY: "TRANSACTION_CONTRACT", LABEL: "FINANCIAL.TRANSACTION_CONTRACT" },
            { PROPERTY: "HOLDER", LABEL: "GENERAL.HOLDER" },
            { PROPERTY: "ORIGIN", LABEL: "BASIC_DATA.ORIGIN" },
            { PROPERTY: "HOLDER_CONTRACT", LABEL: "FINANCIAL.HOLDER_CONTRACT" },
            { PROPERTY: "TABLE", LABEL: "Tabela" },
            { PROPERTY: "SPREAD", LABEL: "FINANCIAL.SPREAD" },
            { PROPERTY: "FACTOR", LABEL: "BASIC_DATA.EXCHANGE_RATE" },
            { PROPERTY: "TOTAL_FACTOR", LABEL: "FINANCIAL.TOTAL_FACTOR" },
            { PROPERTY: "TOTAL_CURRENT_CURRENCY", LABEL: "FINANCIAL.TOTAL_LOCAL_CURRENCY" },
            { PROPERTY: "CURRENT_CURRENCY", LABEL: "FINANCIAL.LOCAL_CURRENCY" },
            { PROPERTY: "CONTRACT", LABEL: "BASIC_DATA.FREIGHT_CONTRACT" },
            { PROPERTY: "CHARGE_COMPOSITION", LABEL: "GENERAL.COMPOSITION_CHARGE" },
            { PROPERTY: "INVOICE", LABEL: "FINANCIAL.GENERATE_INVOICE" },
            { PROPERTY: "OBSERVATION", LABEL: "GENERAL.REMARKS" },
            { PROPERTY: "VALIDITY_START", LABEL: "PRODUCT.VALIDITY_START" },
            { PROPERTY: "VALIDITY_END", LABEL: "PRODUCT.VALIDITY_END" },
            { PROPERTY: "CHARGE_VALUE_CURRENT_CURRENCY", LABEL: "FINANCIAL.DIFFERENCE_CHARGE_LOCAL_CURRENCY" },
            { PROPERTY: "EXCHANGE_RATE_INDEX", LABEL: "FINANCIAL.IDC" },
            { PROPERTY: "EXCHANGE_BALANCE", LABEL: "FINANCIAL.DIFFERENCE_EXCHANGE_RATE" },
            { PROPERTY: "BALANCE_AMOUNT", LABEL: "FINANCIAL.BALANCE" },
            { PROPERTY: "PROFIT_SHARE", LABEL: "FINANCIAL.PROFIT_SHARE" },
            { PROPERTY: "DATA_ORIGIN_TYPE", LABEL: "PRODUCT.ORIGIN_DATA" },
        ];
        return props;

    }

    private async setAutoRatingErrorButtonLabel() {
        if (this.$offerScope.model && this.$offerScope.model.ID) {
            this.$offerScope.numberOfAutoRatingErrors = await this.offerChargeHelper.countAutoRatingErrors(this.$offerScope.model.ID.toString());
        }
        const autoRatingErrorButon = this.$scope.chargesTableOptions.customToolbarButtons.find(button => button.name == "autoRatingErrorLog");
        if (autoRatingErrorButon) autoRatingErrorButon.label = `${this.$offerScope.numberOfAutoRatingErrors}`;

    }
}

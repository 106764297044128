import angular = require("angular");
import { IGridOptions } from "ui-grid";
import { IMonacoColumnDef } from "@services/GridService2";
import { OperationalService } from "@services/OperationalService";
import { ProductService } from '@services/ProductService';
import { IRestService } from "@services/RestService";
import { GridFormService, IGridFormController, IGridFormServiceScope, IMonacoRequest, IMonacoRequestLog } from "@services/GridFormService";
import { IBookingRemarksConfigModel } from '@models/interface/operational/BookingRemarksConfig';
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { SelectorModel } from '../../common/model/SelectorModel';
import { ISelectorModel } from "WBA-Model/dist/mongo/SelectorModel";
import { IServiceProviderSelector } from 'WBA-Model/dist/interface/operational/IProductIntegrationSelector';
import { EDataOriginTypeId, EOperation, EOriginDestinationTypeId, ETypeLocalId } from "@enums/GenericData";
import { HelperService } from "@services/HelperService";
import { ISessionService } from "@services/SessionService";
import { ILinkParameter } from "src/ts/common/model/ModelParameter";
import { ICustomLogProperties } from "WBA-Model/dist/interface/common/IViewLog";
import { SSEService } from '@appServices/SSEService';

interface IBookingRemarksConfigScope extends IGridFormServiceScope {
    model: IBookingRemarksConfigModel;
    scopeBeforeSave: IBookingRemarksConfigModel;
    gridOptions: IGridOptions;
    form: ng.IFormController;
    user: any;
    typePaymentList: ISelectorModel;
    contractTypeList: ISelectorModel[];
    serviceProviderList: SelectorModel[];
    originList: SelectorModel[];
    destinationList: SelectorModel[];
    paymentLocalList: SelectorModel[];
    countryList: SelectorModel[];
    accountList: ISelectorModel[];
    sessionService: ISessionService;
    customLogProperties: ICustomLogProperties[];
    getServiceProviderListByName: (term: string) => Promise<void>;
    getOriginListByName: (term: string) => Promise<void>;
    getDestinationListByName: (term: string) => Promise<void>;
    getPaymentLocalListByName: (term: string) => Promise<void>;
    refreshCountryListByName: (search: string) => Promise<void>;
    refreshAccounts: (search: string) => Promise<void>;
    goToCountry: (id: number) => void;
    goToAccount: (id: number) => void;
    editBookingRemarksConfig: (bookingRemarksConfig: IBookingRemarksConfigModel) => Promise<void>;
    checkCountryField: (selectedValue, originDestination: number) => void;
    checkPortField: (originDestination: number) => void;
}

export class BookingRemarksConfigController extends GridFormService implements IGridFormController {

    static $inject: string[] = ["$injector", "$scope"];
    private $scope: IBookingRemarksConfigScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private productService: ProductService;
    private OperationalService: OperationalService;
    private gridName: string;
    private helperService: HelperService;
    private SSEService: SSEService;

    constructor($injector: ng.Injectable<any>, $scope: IBookingRemarksConfigScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.OperationalService = $injector.get('OperationalService');
        this.productService = $injector.get('ProductService');
        this.gridName = "GRID_BOOKING_REMARKS_CONFIG";
        this.helperService = $injector.get('HelperService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlDataManager = this.config.productUrl + baseRoute;
        return urlDataManager;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.OperationalService.$route;
            this.$scope.customLogProperties = this.getCustomLogProperties();
            await this.initDependencies();
            this.initModel();
            this.initForm(this, "form", "bookingRemarksConfig", "GENERAL.MENU.BOOKING_REMARKS_CONFIG", true);
            await this.initGrid(this.gridName, '/bookingRemarksConfig/list', true, true, 120000, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.$rootScope.clearBreadCrumb();
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    async register(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.scopeBeforeSave = null;
            this.$scope.model.ACTIVE = true;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.SSEService.closeEvents();
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")}`;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")}`;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.SSEService.closeEvents();
            this.$scope.disableElements(false);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        let proceed: boolean = true;
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                if (!this.$scope.model.OBSERVATION) {
                    proceed = false;
                    this.handleError('Please fill observation field.');
                }
                if ((this.$scope.model.OBSERVATION && this.$scope.model.SERVICE_PROVIDER) && (!this.$scope.model.FREIGHT_CONTRACT_TYPE &&
                    !this.$scope.model.COUNTRY_ORIGIN && !this.$scope.model.COUNTRY_DESTINATION &&
                    !this.$scope.model.ACCOUNT && !this.$scope.model.PLD && !this.$scope.model.POD)) {
                    proceed = false;
                    this.handleError('At least one another field must be filled.');
                }
            } catch (ex) {
                this.handleError(ex);
                return proceed;
            }
        }
        return proceed;
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        return {
            route: `/bookingRemarksConfig/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    private async getGenericList(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    async initDependencies(): Promise<any> {
        try {
            const self: BookingRemarksConfigController = this;
            return new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getGenericList("type_payment"),
                    self.getGenericList("contract_type")
                ]).then((result: any) => {
                    self.$scope.typePaymentList = result[0] && result[0].length ? result[0].map(obj => { return { ID: obj.ID, NAME: obj.NAME } }) : [];
                    self.$scope.contractTypeList = result[1] && result[1].length ? result[1].map(obj => { return { ID: obj.ID, NAME: obj.NAME } }) : [];
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            SERVICE_PROVIDER: null,
            ACCOUNT: null,
            COUNTRY_DESTINATION: null,
            COUNTRY_ORIGIN: null,
            FREIGHT_CONTRACT_TYPE: null,
            OBSERVATION: null,
            POD: null,
            PLD: null,
            ACTIVE: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null
        }
    }

    initScopeFunctions(): void {

        this.$scope.getServiceProviderListByName = async (term: string): Promise<void> => {
            await this.getServiceProviderListByName(term);
        }

        this.$scope.getOriginListByName = async (term: string): Promise<void> => {
            await this.getOriginListByName(term);
        }

        this.$scope.getDestinationListByName = async (term: string): Promise<void> => {
            await this.getDestinationListByName(term);
        }

        this.$scope.getPaymentLocalListByName = async (term: string): Promise<void> => {
            await this.getPaymentLocalListByName(term);
        }

        this.$scope.refreshCountryListByName = async (search: string): Promise<void> => {
            await this.refreshCountryListByName(search);
        }

        this.$scope.refreshAccounts = async (search: string): Promise<void> => {
            const accounts = await this.getAccountListByName(search);
            if (accounts) this.$scope.accountList = accounts;
        }

        this.$scope.goToCountry = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.country", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToAccount = (id: number): void => {
            this.$scope.sessionService.openTab("app.commercial.account", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.editBookingRemarksConfig = async (bookingRemarksConfig: IBookingRemarksConfigModel): Promise<void> => {

            let blockedObject = {
                ID: bookingRemarksConfig.ID,
                NAME: null,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || bookingRemarksConfig.ID !== this.$scope.model.ID) this.$scope.view(bookingRemarksConfig);
                } else if (this.$scope.operation !== EOperation.EDIT || bookingRemarksConfig.ID !== this.$scope.model.ID) {
                    this.$scope.edit(bookingRemarksConfig);
                }
            };
        }

        this.$scope.checkCountryField = (selectedValue, originDestination: number): void => {
            this.checkCountryField(selectedValue, originDestination);
        }

        this.$scope.checkPortField = (originDestination: number): void => {
            this.checkPortField(originDestination);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.view(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editBookingRemarksConfig(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copy(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLog(row.entity, row.entity._id)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;

        gridColumns.$columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: '7%',
            cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${copy} ${viewLog}</div>`,
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colServiceProvider: IMonacoColumnDef = { name: "SERVICE_PROVIDER.NAME", displayName: "BASIC_DATA.SEA_CARRIER", width: 150 };
            const colAccount: IMonacoColumnDef = { name: "ACCOUNT.NAME", displayName: "BASIC_DATA.CLIENT", width: 150 };
            const colContractType: IMonacoColumnDef = { name: "FREIGHT_CONTRACT_TYPE.NAME", displayName: "PRODUCT.FREIGHT_CONTRACT_TYPE", width: 150 };
            const colCountryOrigin: IMonacoColumnDef = { name: "COUNTRY_ORIGIN.NAME", displayName: "BASIC_DATA.ORIGIN_COUNTRY", width: 150 };
            const colCountryDestination: IMonacoColumnDef = { name: "COUNTRY_DESTINATION.NAME", displayName: "BASIC_DATA.DESTINATION_COUNTRY", width: 150 };
            const colOrigin: IMonacoColumnDef = { name: "POD.CODE", displayName: "BASIC_DATA.ORIGIN", width: 150 };
            const colDestination: IMonacoColumnDef = { name: "PLD.CODE", displayName: "BASIC_DATA.DESTINATION", width: 150 };
            const colPaymentLocal: IMonacoColumnDef = { name: "PAYMENT_LOCAL.CODE", displayName: "GENERAL.LOCAL", width: 150 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 150, cellFilter: "YesOrNo" };
            const colCreatedDate: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 140, cellFilter: "datetime" };
            const colCreatedBy: IMonacoColumnDef = { name: "CREATED_BY.NAME", displayName: "GENERAL.CREATED_BY", width: 180 };
            const colUpdatedDate: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 140, cellFilter: "datetime" };
            const colUpdatedBy: IMonacoColumnDef = { name: "UPDATED_BY.NAME", displayName: "GENERAL.UPDATED_BY", width: 180 };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 150 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'SERVICE_PROVIDER':
                        columnDefs.push(colServiceProvider);
                        break;
                    case 'ACCOUNT':
                        columnDefs.push(colAccount);
                        break;
                    case 'FREIGHT_CONTRACT_TYPE':
                        columnDefs.push(colContractType);
                        break;
                    case 'COUNTRY_ORIGIN':
                        columnDefs.push(colCountryOrigin);
                        break;
                    case 'COUNTRY_DESTINATION':
                        columnDefs.push(colCountryDestination);
                        break;
                    case 'ORIGIN':
                        columnDefs.push(colOrigin);
                        break;
                    case 'DESTINATION':
                        columnDefs.push(colDestination);
                        break;
                    case 'PAYMENT_LOCAL':
                        columnDefs.push(colPaymentLocal);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedDate);
                        break;
                    case 'CREATED_BY':
                        columnDefs.push(colCreatedBy);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedDate);
                        break;
                    case 'UPDATED_BY':
                        columnDefs.push(colUpdatedBy);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getServiceProviderListByName(search: string): Promise<void> {
        try {
            this.$scope.serviceProviderList = [];
            if (search && search.length >= 3) {
                this.block();

                const request: IMonacoRequest = {
                    data: {
                        search
                    },
                    route: `/provider/list/custom`,
                    timeout: 30000,
                }
                const result = await this.productService.post(request)
                if (result && result.data && result.status == 200) {
                    const providerList: IServiceProviderSelector[] = result.data.data;
                    for (const resultItem of providerList) {
                        const serviceProvider: ISelectorModel = { ID: resultItem.ID, CODE: resultItem.SCAC_IATA, NAME: resultItem.NAME }
                        this.$scope.serviceProviderList.push(serviceProvider);
                    }
                }
                this.unblock();
            }
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private async getRoutingPointListByName(search?: string, countries?: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            this.block();
            const routingPoints = await this.productService.post({ route: '/routingPoint/list/custom', data: { name: search, types: [ETypeLocalId.PORT], countries: countries ? [countries] : null}, timeout: 30000 });
            if (routingPoints && routingPoints.data && routingPoints.data.data && routingPoints.data.data.data) result = routingPoints.data.data.data.map(routingPoint => { return { ID: routingPoint.ID, NAME: routingPoint.NAME, CODE: routingPoint.CODE, TYPE: routingPoint.TYPE.NAME, COUNTRY_ID: routingPoint.COUNTRY.ID, COUNTRY_NAME: routingPoint.COUNTRY.NAME, COUNTRY_CODE: routingPoint.COUNTRY.CODE } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getOriginListByName(term: string) {
        let routingPointList: ISelectorModel[] = [];
        if (term && term.length >= 3) {
            const country = this.$scope.model.COUNTRY_ORIGIN && this.$scope.model.COUNTRY_ORIGIN.CODE ? this.$scope.model.COUNTRY_ORIGIN.CODE : null;
            routingPointList = await this.getRoutingPointListByName(term, country);
        }
        this.$scope.originList = routingPointList;
    }

    private async getDestinationListByName(term: string) {
        let routingPointList: ISelectorModel[] = [];
        if (term && term.length >= 3) {
            const country = this.$scope.model.COUNTRY_DESTINATION && this.$scope.model.COUNTRY_DESTINATION.CODE ? this.$scope.model.COUNTRY_DESTINATION.CODE : null;
            routingPointList = await this.getRoutingPointListByName(term, country);
        }
        this.$scope.destinationList = routingPointList;
    }

    private async getPaymentLocalListByName(term: string) {
        let routingPointList: ISelectorModel[] = [];
        if (term && term.length >= 3) {
            routingPointList = await this.getRoutingPointListByName(term);
        }
        this.$scope.paymentLocalList = routingPointList;
    }

    private async refreshCountryListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const countryList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/country/list/custom`, { search }, 30000, false);
                result = (countryList && countryList) ? countryList.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.countryList = result;
            this.formService.unblock();
        }
    }

    private async getAccountListByName(name: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            if (name && name.length >= 3) {
                this.block();
                const accounts = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/account/list/custom`, { search: name }, 30000, false);
                result = accounts ? accounts.map(x => { return { ID: x.ID, NAME: x.NAME } }) : [];
            }
            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.accountList = result;
            this.formService.unblock();
        }
    }

    private checkCountryField(selectedValue, originDestination: number) {
        if (selectedValue) {
            if (originDestination == 1) {
                this.$scope.model.COUNTRY_ORIGIN = {
                    ID: selectedValue.COUNTRY_ID,
                    NAME: selectedValue.COUNTRY_NAME,
                    CODE: selectedValue.COUNTRY_CODE
                };
            }
            if (originDestination == 2) {
                this.$scope.model.COUNTRY_DESTINATION = {
                    ID: selectedValue.COUNTRY_ID,
                    NAME: selectedValue.COUNTRY_NAME,
                    CODE: selectedValue.COUNTRY_CODE
                };
            }
        } else {
            if (originDestination == 1) this.$scope.model.PLD = null;
            if (originDestination == 2) this.$scope.model.POD = null;
        }
    }

    private checkPortField(originDestination: number) {
        if (originDestination == 1) this.$scope.model.POD = null;
        if (originDestination == 2) this.$scope.model.PLD = null;
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: 'ID',
                LABEL: 'REGISTRATION.IDENTIFICATION'
            },
            {
                PROPERTY: 'SERVICE_PROVIDER',
                LABEL: 'BASIC_DATA.SERVICE_PROVIDER'
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
            {
                PROPERTY: 'LEGAL_PERSON',
                LABEL: 'ENTITY.CORPORATE_NAME'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'PRODUCT',
                LABEL: 'BASIC_DATA.PRODUCT'
            },
            {
                PROPERTY: 'CORPORATE_NAME',
                LABEL: 'ENTITY.CORPORATE_NAME'
            },
            {
                PROPERTY: 'LEGAL_PERSON_NAME',
                LABEL: 'ENTITY.LEGAL_PERSON'
            },
            {
                PROPERTY: 'SCAC_IATA',
                LABEL: 'SCAC/IATA'
            },
            {
                PROPERTY: 'CODE',
                LABEL: 'GENERAL.CODE'
            },
            {
                PROPERTY: 'COUNTRY_ORIGIN',
                LABEL: 'BASIC_DATA.ORIGIN'
            },
            {
                PROPERTY: 'COUNTRY_DESTINATION',
                LABEL: 'BASIC_DATA.DESTINATION'
            },
            {
                PROPERTY: 'OBSERVATION',
                LABEL: 'GENERAL.OBSERVATION'
            },
            {
                PROPERTY: 'ACCOUNT',
                LABEL: 'GENERAL.MENU.ACCOUNT'
            },
            {
                PROPERTY: 'FREIGHT_CONTRACT_TYPE',
                LABEL: 'PRODUCT.FREIGHT_CONTRACT_TYPE'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            }
        ];
        return props;
    }

}

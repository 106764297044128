import angular = require('angular');
import moment = require('moment');
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { IMonacoRequest } from "@services/GridFormService";
import { IModalOptions, IModalService } from '@services/ModalService';
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { IRestService } from '@services/RestService';
import { ISessionService } from "@services/SessionService";
import { ProductService } from "@services/ProductService";
import { DataProcessService } from '@services/DataProcessService';
import { ISelectorModel, SelectorModel } from "@models/mongo/SelectorModel"
import { UserModel } from "@models/interface/common/UserModel";
import { Process, INSTRUCTION } from "@models/interface/operational/NewProcess";
import { FileReferenceModel } from "@models/interface/operational/FileReferenceModel";
import { IProcessWizardFilter, IOuterCells, ITerminalRedirect, IMaritimeStopover } from '@models/interface/dataProcess/ProcessWizardFilter';
import { IResponseProcessWizardFilterSave, IResponseProcessWizardPreviewGet, IProcessWizardOptionPreview, IError, IResponseProcessWizardPreProcessingStagesGet, IResponseProcessWizardConfirmationGet, IProcessWizardOptionConfirmation } from '@models/interface/dataProcess/ProcessWizard';
import { IProcessWizardError } from '@models/interface/dataProcess/ProcessWizardError';
import { IProcessWizardOption } from '@models/interface/dataProcess/ProcessWizardOption';
import { DEADLINES } from '@models/interface/operational/maritime/MaritimeStopover';
import { IExternalContact } from '@models/interface/dataProcess/ProcessWizardPreProcessingExternalContact';
import { IEntity } from '@models/interface/common/Entity';
import { ECargoTypeId, EProductId, EFileGroupId, EDataOriginTypeId, EProviderTypeId, EEventType, EProcessDocumentTypeId, ECustomerSituationId } from '@enums/GenericData';
import { IProcessParameter, ILinkParameter, IVoyageAndStopoverParameter, IVoyageParameter } from "../../common/model/ModelParameter";
import { EProcessWizardTab, EOperation } from '@enums/GenericData';
import { IAccountExchangeData } from 'src/ts/commercial/model/AccountModel';
import { OperationalService } from '@services/OperationalService';
import { HelperService } from "@services/HelperService";
import { ICommodity } from '@models/interface/product/CommodityModel';
import { EProcessDocumentType, } from '@models/interface/operational/GenericData';
import { IOfferCompiled, IOption } from "WBA-Model/dist/interface/dataProcess/ProcessWizardFilter";
import { ValidateUtil } from "../../common/util/ValidateUtil";
import { IProductConfiguration } from "@models/interface/product/ProductConfiguration";
import { EVENT } from "@models/interface/product/OfferCompiled";
import { EInttraSearchDateType, EEdiExternalCodeSetupInttra } from '@enums/InttraEnum';
import { IInttraOceanSchedulesRequest, IInttraOceanSchedulesResult } from 'WBA-Model/dist/interface/external/IInttra';
import { ExternalService } from '@services/ExternalService';

const enum ETabStep {
    OFFER_DATA = 0,
    DETAILS = 1,
    CARGO = 2,
    PREVIEW = 3,
    CONFIRMATION = 4
}

interface IUiTabSteps {
    current: number;
    percent: number;
    lastStep: number
}

export interface IOfferCommodity {
    COMMODITY: ICommodity;
    COMMODITY_SECTION: ISelectorModel;
    DATA_ORIGIN_TYPE: ISelectorModel;
}

interface IProcessWizardModel extends IProcessWizardFilter { }

interface IExternalContactCompany extends IEntity {
    CONTACTS: IExternalContact[];
}

interface ITransportModeInttra extends ISelectorModel {
    LOCAL: ISelectorModel;
    SERVICE_PROVIDER: string;
    ETB: Date;
}

interface ISearchBehaviorControl {
    isSearchTabDataListCanceled: boolean;
    searchTabDataError: string[];
    isSearchingTabDataList: boolean;
    searchTabDataListCount: number;
    searchTabDataCurrentAttempt: number;
    searchTabDataMaxAttempt: number;
}

export interface IDeadlinesModal extends ng.IScope {
    deadlines: IDeadlinesItem;
    refreshModal: (id: number) => void;
}

export interface IDeadlinesDetailsModalParameter {
    title: string;
    house: DEADLINES;
    master: DEADLINES;
}

enum IProcessInstructionType {
    PRODUCT = "product",
    COMMERCIAL = "commercial",
    OPERATIONAL = 'operational',
    FINANCIAL = 'financial',
    CUSTOMER = 'customer',
    AGENT = 'agent',
    HOUSE = 'house',
    MASTER = 'master'
}

interface ICommodityModalScope extends IFormServiceScope {
    operation: string;
    sectionList: SelectorModel[];
    commodityList: SelectorModel[];
    commodities: IOfferCommodity[];
    oldCommodities: IOfferCommodity[];
    exceptions: ICommodity[];
    addCommodity: () => void;
    removeCommodity: (index: number) => void;
    applyCommodity: (close?: boolean) => void;
    closeCommodityModal: () => Promise<void>;
    getCommodityListByName: (search: string) => Promise<void>;
    commodityChange: (index: number) => void;
    isCommodityDisabled: (commodity: IOfferCommodity, fieldName: string) => boolean;
}


interface IOfferCommodityRequestViewResult {
    COMMODITY_EXCEPTIONS: ICommodity[];
    COMMODITY_ITEM: IOfferCommodity[];
    COMMODITY_ITEM_WIZARD: IOfferCommodity[];
}

interface IDeadlinesItem {
    ID: string
    DEADLINES: DEADLINES[]
    NAME: string
}

interface IProcessWizardModalScope extends IFormServiceScope {
    modalOptions: IModalOptions;
    eventDetailModalID: number;
    steps: IUiTabSteps;
    errorsOnFilter: IProcessWizardError[];
    model: IProcessWizardModel;
    oldModel: IProcessWizardModel;
    addressOrigin: string;
    addressDestin: string;
    user: UserModel;
    processes: Process[];
    externalContactCompanies: IExternalContactCompany[];
    hasAnyPreviewOption: boolean;
    previewOptions: IProcessWizardOptionPreview[];
    previewTabSearchControl: ISearchBehaviorControl;
    hasAnyConfirmationOption: boolean;
    confirmationOptions: IProcessWizardOptionConfirmation[];
    confirmationTabSearchControl: ISearchBehaviorControl;
    instructions: INSTRUCTION;
    lastScroll: number;
    updatedInstructions: boolean;
    processRouteRedirect: boolean;

    // lists
    exporterList: IEntity[];
    importerList: IEntity[];
    notifyList: IEntity[];
    brokerList: IEntity[];
    localAgentList: IEntity[];
    destinAgentList: IEntity[];
    documentList: ISelectorModel[];
    terminalList: ITerminalRedirect[];
    outerCellList: IOuterCells[];
    maritimeStopoverList: IMaritimeStopover[];
    fileGroupList: ISelectorModel[];
    groupList: ISelectorModel[];
    commodities: IOfferCommodity[];
    exceptions: ICommodity[];
    deadlinesList: IDeadlinesItem[];
    deadlines: IDeadlinesItem;
    getVoyageByIntegration: boolean;
    providerListForInttraVoyage: ISelectorModel[];
    oceanScheduleOptions: IInttraOceanSchedulesResult[];
    oceanScheduleConcatenated: ITransportModeInttra[];
    transportMode: ISelectorModel;
    externalShipowners: ISelectorModel[];

    //gets
    getExporterList: (exporter_data: ISelectorModel[], search: string) => Promise<void>;
    getImporterList: (importer_data: ISelectorModel[], search: string) => Promise<void>;
    getNotifyList: (notify_data: ISelectorModel[], search: string) => Promise<void>;
    getBrokerList: (search: string) => Promise<void>;
    getTerminalList: (search: string) => Promise<void>;
    getOuterCellList: (name: string) => Promise<void>;
    getExternalContacts: () => void;
    getAndUpdatePreviewList: () => void;
    getAndUpdateConfirmationList: () => void;

    //conditions
    isCargoAir: () => boolean;
    isCargoFcl: () => boolean;
    isCargoFtl: () => boolean;
    isCargoLclBbRoro: () => boolean;
    isCargoBbRoro: () => boolean;
    isMaritimeStopover: () => boolean;
    isProductMaritime: () => boolean;
    isProductRoad: () => boolean;
    isProductMaritimeExport: () => boolean;
    hasAnyConfirmationOptionWithError: () => boolean;
    hasAnySearchControlInProgress: () => boolean;

    //navigation
    back: () => void;
    next: () => void;
    tabClick: (tabIndex: number) => void;
    isTabEnabled: (tabIndex: number) => boolean;
    goToAccountRequirement: (accountId: number, requirementId: number) => void;
    goToProcesses: (processNumbers: string) => void;
    goToVoyageAndStopover: (maritimeStopover: IMaritimeStopover) => void;
    goToVoyage: (maritimeStopover: IMaritimeStopover) => void;

    //functions
    addCargo: () => void;
    removeCargo: (index: number) => void;
    addReferenceList: (documentType: ISelectorModel, documentValue: string, optionIndex: number, index: number) => void;
    goToProcess: (process: string) => void;
    collapseExternalContacts: () => void;
    cancelPreviewSearch: () => void;
    cancelConfirmationSearch: () => void;
    hasAnyPreviewOptionWithError: () => boolean;
    viewOptionErrors: (errors: IError[]) => void;
    retryPreviewErrorOption: (option: IProcessWizardOption) => void;
    retryAllPreviewErrorOptions: (previewOptions: IProcessWizardOptionPreview[]) => void;
    retryGenerateProcess: (option: IProcessWizardOption) => void;
    retryGenerateAllProcess: (options: IProcessWizardOptionConfirmation[]) => void;
    addStopover: (stopover: IMaritimeStopover, index: number) => void;
    addInternalTag: (data: string, type: IProcessInstructionType) => void;
    deleteInternalTag: (data: string, type: IProcessInstructionType) => void;
    cleanInstructionAll: (type: IProcessInstructionType) => void;
    hasInvalidRequiredElements: (elementId: string) => boolean;
    openCommoditiesModal: () => void;
    hasChanges: (newObj: Object, oldObj: Object, propertiesToIgnore?: string[]) => boolean;
    modalSaveConfirmation: (headerText: string, closeButtonText: string) => Promise<boolean>;
    buildCommodityConcatenated: (commoditySummary: IOfferCommodity[]) => string;
    buildExceptionsView: (commoditySummary: ICommodity[]) => string;
    openDeadlines: () => Promise<void>;
    openProcessDeadlineModal: (currentIndex: number) => void;
    checkDateValidity: (initialDate: Date, finalDate: Date, index: number) => void;
    getTransportFromOceanSchedule: (search: string) => Promise<void>;
    refreshWithDelay: (search: string, ms: number) => void;
    applyTransportModeToEventFromInttra: (maritimeStopoverIndex: number, stopover: IMaritimeStopover) => Promise<void>;

}

export class ProcessWizardModalController extends FormService2 {
    static $inject: string[] = ['$injector', '$scope', 'RestService', 'ModalService', 'config', 'FormService'];
    private scope: IProcessWizardModalScope;
    private timeout: ng.ITimeoutService;
    private $q: ng.IQService;
    private $sce: angular.ISCEService;
    private $RestService: IRestService;
    private $SessionService: ISessionService;
    private ModalService: IModalService;
    private ProductService: ProductService;
    private DataProcessService: DataProcessService;
    private operationalService: OperationalService;
    private $filterService: ng.FilterFactory;
    private helperService: HelperService;
    private commoditiesModalId: number;
    private deadlinesModalId: number;
    private restService: IRestService;
    private $injector: ng.Injectable<any>;
    private externalService: ExternalService;

    constructor($injector: ng.Injectable<any>, $scope: IProcessWizardModalScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.timeout = $injector.get("$timeout");
        this.$q = $injector.get('$q');
        this.$sce = $injector.get('$sce');
        this.$RestService = $injector.get('RestService');
        this.$SessionService = $injector.get('SessionService');
        this.ModalService = $injector.get('ModalService');
        this.ProductService = $injector.get('ProductService');
        this.DataProcessService = $injector.get('DataProcessService');
        this.operationalService = $injector.get('OperationalService');
        this.$filterService = $injector.get('$filter');
        this.helperService = $injector.get('HelperService');
        this.restService = $injector.get('RestService');
        this.externalService = $injector.get('ExternalService');
    }

    async $onInit(): Promise<void> {
        try {
            this.block();
            this.init("processWizardModalForm", null, null);
            this.initScopeFunctions();
            this.initDependencies();
            this.initSearchControl();
            this.loadRegisterForm(false);
            this.loadDatas();
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    private loadDatas() {

        this.scope.steps = { current: 0, percent: 0, lastStep: 4 };

        if (!this.scope.model.DETAILS_TAB || this.scope.model.DETAILS_TAB && !this.scope.model.DETAILS_TAB.INSTRUCTIONS) {
            this.scope.model.DETAILS_TAB = {
                INSTRUCTIONS: {
                    PRODUCT: null,
                    COMMERCIAL: null,
                    OPERATIONAL: null,
                    FINANCIAL: null,
                    CUSTOMER: null,
                    AGENT: null,
                    PROCESS_HOUSE: null,
                    PROCESS_MASTER: null
                },
                EXTERNAL_CONTACT: null
            }
        }

    }

    private initScopeFunctions(): void {

        this.scope.back = (): void => {
            this.back();
        }

        this.scope.next = (): void => {
            this.next();
        }

        this.scope.tabClick = (tabIndex: number): void => {
            if (!this.scope.steps || tabIndex == null) return;
            const previousStep = this.scope.steps.current - 1;
            const nextStep = this.scope.steps.current + 1;
            if (previousStep == tabIndex) this.scope.back();
            else if (nextStep == tabIndex) this.scope.next();
        }

        this.scope.isTabEnabled = (tabIndex: number): boolean => {
            if (!this.scope.steps || tabIndex == null) return false;
            const previousStep = this.scope.steps.current - 1;
            const nextStep = this.scope.steps.current + 1;
            return (previousStep == tabIndex || nextStep == tabIndex);
        }

        this.scope.goToAccountRequirement = (accountId: number, requirementId: number) => {
            if (!accountId && accountId != 0) throw Error('accountId is null');
            if (!requirementId && requirementId != 0) throw Error('requirementId is null');
            this.$SessionService.openTab("app.commercial.account", <ILinkParameter>{ ID: accountId ? accountId.toString() : "" }, <IAccountExchangeData>{ OPERATION: "edit", ID: accountId, ID_REQUIREMENT: requirementId });
        }

        this.scope.goToProcess = async (process: string) => {
            this.$SessionService.openTab("app.operational.newProcess.list", <IProcessParameter>{ "PROCESS_NUMBER": process });
        }

        this.scope.goToVoyageAndStopover = (maritimeStopover: IMaritimeStopover) => {
            this.$SessionService.openTab('app.operational.voyagesAndDeadlines', <IVoyageAndStopoverParameter>{ IDENTIFIER: (maritimeStopover && maritimeStopover.STOPOVER && maritimeStopover.STOPOVER.ID) ? maritimeStopover.STOPOVER.ID : null });
        }

        this.scope.goToVoyage = (maritimeStopover: IMaritimeStopover) => {
            const selectedOption = this.scope.oceanScheduleOptions && maritimeStopover && maritimeStopover.VESSEL && this.scope.oceanScheduleOptions.length > 0 ? this.scope.oceanScheduleOptions.find(x => x['imoNumber'] == maritimeStopover.VESSEL.CODE) : null;
            this.$SessionService.openTab('app.operational.voyages', <IVoyageParameter>{ "VOYAGE_PROVIDER.CONCATENATED": maritimeStopover && maritimeStopover.VESSEL ? `${selectedOption.carrierName}${selectedOption.voyageNumber}` : '' });
        }

        this.scope.collapseExternalContacts = () => {
            this.collapseExternalContacts();
        }

        this.scope.cancelPreviewSearch = () => {
            this.cancelPreviewSearch();
        }

        this.scope.cancelConfirmationSearch = () => {
            this.cancelConfirmationSearch();
        }

        this.scope.hasAnyPreviewOptionWithError = () => {
            return this.scope.previewOptions && this.scope.previewOptions.some(option => option.ERRORS && option.ERRORS.length);
        }

        this.scope.viewOptionErrors = (errors: IError[]) => {
            this.viewOptionErrors(errors);
        }

        this.scope.retryPreviewErrorOption = (option: IProcessWizardOption) => {
            this.retryPreviewErrorOption([option]);
        }

        this.scope.retryAllPreviewErrorOptions = (previewOptions: IProcessWizardOptionPreview[]) => {
            if (previewOptions && previewOptions.length) {
                const previewOptionsWithError = previewOptions.filter(previewOption => previewOption.ERRORS && previewOption.ERRORS.length);
                if (previewOptionsWithError && previewOptionsWithError.length) this.retryPreviewErrorOption(previewOptionsWithError.map(previewOption => previewOption.OPTION));
            }
        }

        this.scope.retryGenerateProcess = (option: IProcessWizardOption) => {
            this.retryGenerateProcess([option]);
        }

        this.scope.retryGenerateAllProcess = (options: IProcessWizardOptionConfirmation[]) => {
            if (options && options.length) {
                const optionsWithError = options.filter(option => option.ERRORS && option.ERRORS.length);
                if (optionsWithError && optionsWithError.length) this.retryGenerateProcess(optionsWithError.map(option => option.OPTION));
            }
        }

        this.scope.getExporterList = async (exporter_data: IEntity[], search: string): Promise<void> => {
            let exporterList: IEntity[] = [];
            if (exporter_data || (search && search.length >= 3)) exporterList = await this.getlegalPersonList(exporter_data, search, ['4']);
            this.scope.exporterList = exporterList;
        }

        this.scope.getImporterList = async (importer_data: IEntity[], search: string): Promise<void> => {
            let importerList: IEntity[] = [];
            if (importer_data || (search && search.length >= 3)) importerList = await this.getlegalPersonList(importer_data, search, ['5']);
            this.scope.importerList = importerList;
        }

        this.scope.getNotifyList = async (notify_data: IEntity[], search: string): Promise<void> => {
            let notifyList: IEntity[] = [];
            if (notify_data || (search && search.length >= 3)) notifyList = await this.getlegalPersonList(notify_data, search, ['6']);
            this.scope.notifyList = notifyList;
        }

        this.scope.getBrokerList = async (search: string): Promise<void> => {
            let brokerList: IEntity[] = [];
            if (search && search.length >= 3) brokerList = await this.getlegalPersonList([], search, ['1']);
            this.scope.brokerList = brokerList;
        }

        this.scope.getTerminalList = async (search: string): Promise<void> => {
            let terminalList: ITerminalRedirect[] = [];
            if (search && search.length >= 3) terminalList = await this.getTerminalList(search);
            this.scope.terminalList = terminalList;
        }

        this.scope.getOuterCellList = async (search: string) => {
            let outerCellList: IOuterCells[] = [];
            if (search && search.length >= 3) outerCellList = await this.getOuterCellList(search);
            this.scope.outerCellList = outerCellList;
        }

        this.scope.getExternalContacts = () => {
            this.getExternalContacts();
        }

        this.scope.getAndUpdatePreviewList = () => {
            this.getAndUpdatePreviewList();
        }

        this.scope.getAndUpdateConfirmationList = () => {
            this.getAndUpdateConfirmationList();
        }

        this.scope.isCargoAir = () => {
            return this.scope.model.OFFER_COMPILED.CARGO_TYPE && this.scope.model.OFFER_COMPILED.CARGO_TYPE.ID == ECargoTypeId.AIR;
        }

        this.scope.isCargoFcl = () => {
            return this.scope.model.OFFER_COMPILED.CARGO_TYPE && this.scope.model.OFFER_COMPILED.CARGO_TYPE.ID == ECargoTypeId.FCL;
        }

        this.scope.isCargoFtl = () => {
            return this.scope.model.OFFER_COMPILED.CARGO_TYPE && this.scope.model.OFFER_COMPILED.CARGO_TYPE.ID == ECargoTypeId.ROAD;
        }

        this.scope.isCargoLclBbRoro = () => {
            return this.scope.model.OFFER_COMPILED.CARGO_TYPE && (this.scope.model.OFFER_COMPILED.CARGO_TYPE.ID == ECargoTypeId.LCL || this.scope.model.OFFER_COMPILED.CARGO_TYPE.ID == ECargoTypeId.BREAK_BULK || this.scope.model.OFFER_COMPILED.CARGO_TYPE.ID == ECargoTypeId.RO_RO)
        }

        this.scope.isCargoBbRoro = () => {
            return this.scope.model.OFFER_COMPILED.CARGO_TYPE && (this.scope.model.OFFER_COMPILED.CARGO_TYPE.ID == ECargoTypeId.BREAK_BULK || this.scope.model.OFFER_COMPILED.CARGO_TYPE.ID == ECargoTypeId.RO_RO)
        }

        this.scope.isProductMaritime = () => {
            return this.scope.model.OFFER_COMPILED.PRODUCT && (this.scope.model.OFFER_COMPILED.PRODUCT.ID == EProductId.MARITIME_EXPORT || this.scope.model.OFFER_COMPILED.PRODUCT.ID == EProductId.MARITIME_IMPORT);
        }

        this.scope.isProductRoad = () => {
            return this.scope.model.OFFER_COMPILED.PRODUCT && (this.scope.model.OFFER_COMPILED.PRODUCT.ID == EProductId.ROAD_EXPORT || this.scope.model.OFFER_COMPILED.PRODUCT.ID == EProductId.ROAD_IMPORT || this.scope.model.OFFER_COMPILED.PRODUCT.ID == EProductId.ROAD_NATIONAL);
        }

        this.scope.isProductMaritimeExport = () => {
            return this.scope.model.OFFER_COMPILED.PRODUCT && (this.scope.model.OFFER_COMPILED.PRODUCT.ID == EProductId.MARITIME_EXPORT);
        }

        this.scope.isMaritimeStopover = () => {
            return this.scope.confirmationOptions.some(option => option.CONFIRMATION && option.CONFIRMATION.MARITIME_STOPOVER && option.CONFIRMATION.MARITIME_STOPOVER.ID);
        }

        this.scope.hasAnyConfirmationOptionWithError = () => {
            return this.hasAnyOptionWithError();
        }

        this.scope.hasAnySearchControlInProgress = () => {
            return this.hasAnySearchControlInProgress();
        }

        this.scope.addCargo = (): void => {
            if (this.scope.model.CARGO_TAB.OPTION && this.scope.model.CARGO_TAB.OPTION.length) {
                const newCargo: IOption = {
                    CARGO: angular.copy(this.scope.model.CARGO_TAB.OPTION[0].CARGO),
                    CARGO_READINESS: {
                        ACTUAL_DATE: null,
                        ESTIMATED_DATE: null
                    },
                    REFERENCE: angular.copy(this.scope.model.CARGO_TAB.OPTION[0].REFERENCE),
                    SHIPMENT_REQUIRED: {
                        DATE_OF: this.calculateLoad(this.scope.model.OFFER_COMPILED),
                        DATE_UP: null
                    },
                    MARITIME_STOPOVER: null,
                    TERMINAL: null,
                    LOAD_REF: null
                }

                newCargo.LOAD_REF = newCargo.SHIPMENT_REQUIRED.DATE_OF;
                this.scope.model.CARGO_TAB.OPTION.push(newCargo);
            }
        }

        this.scope.removeCargo = async (index: number): Promise<void> => {
            const msg = this.getTranslate('REGISTRATION.DELETE_FILE_CONFIRMATION', { param: `(#${(index + 1)})` })
            const confirmed = await this.ModalService.showModalConfirmation({}, {
                bodyText: msg
            });

            if (!confirmed) return;

            this.scope.model.CARGO_TAB.OPTION.splice(index, 1);
        }

        this.scope.addReferenceList = (documentType: ISelectorModel, documentValue: string, optionIndex: number, index: number): void => {
            this.addReferenceList(documentType, documentValue, optionIndex, index);
        }

        this.scope.addStopover = (stopover: IMaritimeStopover, index: number) => {
            this.addStopover(stopover, index);
        }

        this.scope.addInternalTag = async (data: string, type: IProcessInstructionType) => {
            await this.addInternalTag(this.scope.instructions, data, type);
        };

        this.scope.deleteInternalTag = async (data: string, type: IProcessInstructionType) => {
            await this.deleteInternalTag(this.scope.instructions, data, type);
        };

        this.scope.cleanInstructionAll = async (type: IProcessInstructionType) => {
            await this.cleanInstructionAll(this.scope.instructions, type);
        }
        this.scope.hasInvalidRequiredElements = (elementId: string) => {
            return this.hasInvalidRequiredElements(elementId);
        }
        this.scope.openCommoditiesModal = (): void => {
            this.openCommoditiesModal();
        }

        this.scope.hasChanges = (newObj: Object, oldObj: Object, propertiesToIgnore?: string[]) => {
            if (propertiesToIgnore) {
                const newAux = newObj ? angular.copy(newObj) : null;
                const oldAux = oldObj ? angular.copy(oldObj) : null;
                for (const property of propertiesToIgnore) {
                    if (newAux && newAux[property]) {
                        delete newAux[property];
                    }
                    if (oldAux && oldAux[property]) {
                        delete oldAux[property];
                    }
                }
                return !angular.equals(JSON.stringify(newAux), JSON.stringify(oldAux));
            }
            return !angular.equals(newObj, oldObj);
        }
        this.scope.modalSaveConfirmation = async (headerText: string, closeButtonText: string) => {
            return this.modalSaveConfirmation(headerText, closeButtonText);
        }
        this.scope.buildCommodityConcatenated = (commoditySummary: IOfferCommodity[]): string => {
            return this.buildCommodityConcatenated(commoditySummary);
        }
        this.scope.buildExceptionsView = (commoditySummary: ICommodity[]): string => {
            return this.buildExceptionsView(commoditySummary);
        }
        this.scope.openDeadlines = async (): Promise<void> => {
            this.openDeadlines();
        }
        this.scope.openProcessDeadlineModal = (currentIndex: number) => {
            this.openProcessDeadlineModal(currentIndex);
        }
        this.scope.checkDateValidity = (initialDate: Date, finalDate: Date, index: number): void => {
            this.checkDateValidity(initialDate, finalDate, index);
        }
        this.scope.getTransportFromOceanSchedule = (search: string): Promise<void> => {
            return this.getTransportFromOceanSchedule(search);
        }

        this.scope.refreshWithDelay = (search: string, ms: number): void => {
            if (search.length >= 3) {
                this.timeout(() => { this.getTransportFromOceanSchedule(search) }, ms);
            }
        }

        this.scope.applyTransportModeToEventFromInttra = async (maritimeStopoverIndex: number, stopover: IMaritimeStopover): Promise<void> => {
            try {
                await this.applyTransportModeToEventFromInttra(maritimeStopoverIndex, stopover, this.scope.oceanScheduleOptions);
            } catch (ex) {
                this.handleError(ex);
            }
        }
    }

    async initDependencies(): Promise<any> {
        try {
            const self: ProcessWizardModalController = this;
            return new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getGenericList('document_type'),
                    self.getGenericList('file_group'),
                    self.getEventTransportMode(),
                    self.getGenericList('message_group'),
                    self.setScopeCommodities(),
                    self.getProductConfiguration(),
                ]).then((result: any) => {
                    self.scope.documentList = result[0];
                    self.scope.fileGroupList = result[1];
                    self.scope.maritimeStopoverList = self.montageMaritimeStopover(result[2].data.data);
                    self.scope.groupList = result[3];
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private initSearchControl() {
        this.scope.previewTabSearchControl = { isSearchTabDataListCanceled: false, isSearchingTabDataList: false, searchTabDataError: null, searchTabDataListCount: null, searchTabDataCurrentAttempt: null, searchTabDataMaxAttempt: null };
        this.scope.confirmationTabSearchControl = { isSearchTabDataListCanceled: false, isSearchingTabDataList: false, searchTabDataError: null, searchTabDataListCount: null, searchTabDataCurrentAttempt: null, searchTabDataMaxAttempt: null };
    }

    private initDetailsCollapseEvents() {
        this.scope.instructions = this.scope.model.DETAILS_TAB.INSTRUCTIONS;

        const collapseExternalContacts = angular.element('#collapseExternalContacts');
        if (collapseExternalContacts) {
            collapseExternalContacts.on('shown.bs.collapse', (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    this.getExternalContacts();
                    angular.element("#collapseExternalContactsHeading").attr('aria-expanded', 'true');
                }
            });
            collapseExternalContacts.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseExternalContactsHeading").attr('aria-expanded', 'false');
                }
            });
        }
    }

    private back(): void {
        try {
            const lastStep = this.scope.steps.lastStep;
            if (this.scope.steps.current == lastStep) return;
            if (this.scope && this.scope.steps && this.scope.steps.current > ETabStep.OFFER_DATA) {
                this.scope.steps.current--;
                this.loadRegisterForm(false);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async next(): Promise<void> {
        try {
            const lastStep = this.scope.steps.lastStep;
            // Validate Fields
            let isValid = this.checkPendingFields("processWizardModalForm");
            if (!isValid) return;

            if (this.scope && this.scope.steps && angular.isDefined(this.scope.steps.current)) {
                // Validate Tabs (before next tab)
                if (this.scope.steps.current == ETabStep.OFFER_DATA && !(await this.validateOfferDataTab())) return;
                if (this.scope.steps.current == ETabStep.DETAILS && !(await this.validateDetailsTab())) return;
                if (this.scope.steps.current == ETabStep.CARGO && !(await this.validateCargoTab())) return;
                if (this.scope.steps.current == ETabStep.PREVIEW && !(await this.generateProcessOptions())) return;
                // Advance Tab / finish
                if (lastStep == this.scope.steps.current) return this.finish();
                else if (this.scope.steps.current < ETabStep.CONFIRMATION) {
                    this.scope.steps.current++;
                }
                // Init Tabs (after next tab)
                if (this.scope.steps.current == ETabStep.DETAILS) await this.initDetailsTab();
                if (this.scope.steps.current == ETabStep.PREVIEW) await this.initPreviewTab();
                if (this.scope.steps.current == ETabStep.CONFIRMATION) await this.initConfirmationTab();
                this.loadRegisterForm(false);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async validateOfferDataTab(): Promise<boolean> {
        let valid = false;
        valid = await this.createProcessWizardFilter(EProcessWizardTab.OFFER_TAB);
        return valid;
    }

    private async validateDetailsTab(): Promise<boolean> {
        let valid = false;
        valid = await this.createProcessWizardFilter(EProcessWizardTab.DETAILS_TAB);
        return valid;
    }

    private async validateCargoTab(): Promise<boolean> {
        let valid = false;
        this.formatCargoTabData();
        valid = await this.createProcessWizardFilter(EProcessWizardTab.CARGO_TAB);
        return valid;
    }

    private async getProcessCustomerSituation(customerId: string): Promise<ISelectorModel> {
        try {
            const request: IMonacoRequest = {
                route: `/account/situation/${customerId}`,
                timeout: 30000
            };

            const rc = await this.ProductService.get(request);
            const result: ISelectorModel = (rc && rc.data && rc.data.data && rc.data.data.SITUATION) ? rc.data.data.SITUATION : null;

            return result;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async createProcessWizardFilter(tab: string, retry?: boolean): Promise<boolean> {
        let success = true;
        try {
            this.block();
            retry = retry ? retry : this.scope.errorsOnFilter && this.scope.errorsOnFilter.length > 0;
            const resultOperation = await this.DataProcessService.post('/processWizardFilter/save', { tab: tab, data: this.scope.model, oldData: this.scope.oldModel ? this.scope.oldModel : null, retry: retry }, 30000);
            if (resultOperation && resultOperation.data && resultOperation.data.data) {
                const result: IResponseProcessWizardFilterSave = resultOperation.data.data;
                this.scope.errorsOnFilter = result.PROCESS_WIZARD_ERROR_LIST;
                this.scope.model = result.PROCESS_WIZARD_FILTER;
                this.scope.oldModel = angular.copy(this.scope.model);

                const customerSituation = await this.getProcessCustomerSituation(this.scope.model.OFFER_COMPILED.CUSTOMER.ID);
                if (customerSituation && (customerSituation.ID == ECustomerSituationId.PROSPECT || customerSituation.ID == ECustomerSituationId.DECLINED)) {
                    this.notifyError(this.getTranslate("GENERAL.ERROR_CUSTOMER_SITUATION"));
                    success = false;
                }

                if (this.scope.errorsOnFilter && this.scope.errorsOnFilter.length) {
                    this.notifyError(this.getTranslate("GENERAL.ERROR_SENDING_REQUEST"));
                    success = false;
                }
            }
        } catch (ex) {
            success = false;
            this.handleError(ex);
        } finally {
            this.unblock();
            return success;
        }
    }

    private async initDetailsTab() {
        try {
            this.timeout(() => {
                this.initDetailsCollapseEvents();
            }, 100);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async initPreviewTab() {
        try {
            await this.getAndUpdatePreviewList();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async initConfirmationTab() {
        try {
            await this.getAndUpdateConfirmationList();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getAndUpdatePreviewList(): Promise<void> {
        try {
            const previewTabSearchControl = this.scope.previewTabSearchControl;
            previewTabSearchControl.isSearchTabDataListCanceled = false;
            this.scope.previewOptions = null;
            if (!previewTabSearchControl.isSearchingTabDataList) {
                previewTabSearchControl.searchTabDataListCount = 0;
                previewTabSearchControl.searchTabDataCurrentAttempt = 0;
                previewTabSearchControl.isSearchingTabDataList = true;
            }
            this.scope.previewOptions = [];
            const resultOperation = await this.DataProcessService.get('/processWizardOption/preview/' + this.scope.model._id, null, 30000);
            if (resultOperation && resultOperation.data && resultOperation.data.data) {
                const result: IResponseProcessWizardPreviewGet = resultOperation.data.data;
                if (result.SHOW) {
                    this.scope.errorsOnFilter = result.PROCESS_WIZARD_ERROR_LIST;
                    if (this.scope.errorsOnFilter && this.scope.errorsOnFilter.length) {
                        this.notifyError(this.getTranslate("GENERAL.ERROR_SENDING_REQUEST"));
                    }
                    this.scope.previewOptions = result.OPTIONS_PREVIEW;
                    this.scope.hasAnyPreviewOption = this.scope.previewOptions && this.scope.previewOptions.length > 0;
                    previewTabSearchControl.isSearchingTabDataList = false;
                    previewTabSearchControl.searchTabDataCurrentAttempt = 0;
                    this.scope.$applyAsync();
                } else if (previewTabSearchControl.searchTabDataCurrentAttempt == previewTabSearchControl.searchTabDataMaxAttempt) {
                    previewTabSearchControl.isSearchingTabDataList = false;
                    this.scope.$applyAsync();
                    return this.notifyError(this.getTranslate("GENERAL.LIMIT_ATTEMPTS"))
                } else {
                    previewTabSearchControl.isSearchingTabDataList = true;
                    previewTabSearchControl.searchTabDataCurrentAttempt++;
                    previewTabSearchControl.searchTabDataListCount = 0;
                    this.scope.$applyAsync();
                    this.timeout(() => { this.initCountGetPreviewTabDataList() }, 1000);
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async retryPreviewErrorOption(options: IProcessWizardOption[]) {
        if (!options) return;
        try {
            this.block();
            const resultOperation = await this.DataProcessService.post('/processWizardOption/retry', { data: options }, 30000);
            if (resultOperation && resultOperation.data && resultOperation.data.data) {
                this.unblock();
                await this.getAndUpdatePreviewList();
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async retryGenerateProcess(options: IProcessWizardOption[]) {
        if (!options) return;
        try {
            this.block();
            const resultOperation = await this.DataProcessService.post('/processWizardOption/retryGenerate', { data: options }, 30000);
            if (resultOperation && resultOperation.data && resultOperation.data.data) {
                this.unblock();
                await this.getAndUpdateConfirmationList();
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private initCountGetPreviewTabDataList() {
        if (!this.scope.previewTabSearchControl.isSearchTabDataListCanceled) {
            this.timeout(() => {
                this.scope.previewTabSearchControl.searchTabDataListCount += 5;
                if (this.scope.previewTabSearchControl.searchTabDataListCount < 100) this.initCountGetPreviewTabDataList();
                else if (!this.scope.previewTabSearchControl.isSearchTabDataListCanceled) this.timeout(() => { this.getAndUpdatePreviewList() }, 1000);
            }, 100);
        }
    }

    private async generateProcessOptions(): Promise<boolean> {
        let success = false;
        try {
            const successOptions: IProcessWizardOption[] = this.scope.previewOptions && this.scope.previewOptions.length ? this.scope.previewOptions.filter(previewOption => !previewOption.ERRORS || previewOption.ERRORS && previewOption.ERRORS.length == 0).map(previewOption => previewOption.OPTION) : [];
            const resultOperation = await this.DataProcessService.post('/processWizardOption/generate', { data: successOptions }, 30000);
            if (resultOperation && resultOperation.data && resultOperation.data.data) success = resultOperation.data.data;
            else this.notifyError(this.getTranslate("OPERATIONAL.ERROR_ON_GENERATE_PROCESS"));
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return success;
        }
    }

    private async getAndUpdateConfirmationList(): Promise<void> {
        try {
            const confirmationTabSearchControl = this.scope.confirmationTabSearchControl;
            confirmationTabSearchControl.isSearchTabDataListCanceled = false;
            this.scope.confirmationOptions = null;
            if (!confirmationTabSearchControl.isSearchingTabDataList) {
                confirmationTabSearchControl.searchTabDataListCount = 0;
                confirmationTabSearchControl.searchTabDataCurrentAttempt = 0;
                confirmationTabSearchControl.isSearchingTabDataList = true;
            }
            this.scope.confirmationOptions = [];

            const resultOperation = await this.DataProcessService.get('/processWizardOption/confirmation/' + this.scope.model._id, null, 30000);
            if (resultOperation && resultOperation.data && resultOperation.data.data) {
                const result: IResponseProcessWizardConfirmationGet = resultOperation.data.data;
                if (result.SHOW) {
                    this.scope.errorsOnFilter = result.PROCESS_WIZARD_ERROR_LIST;
                    if (this.scope.errorsOnFilter && this.scope.errorsOnFilter.length) {
                        this.notifyError(this.getTranslate("GENERAL.ERROR_SENDING_REQUEST"));
                    }
                    this.scope.confirmationOptions = result.OPTIONS_CONFIRMATION;
                    this.scope.hasAnyConfirmationOption = this.scope.confirmationOptions && this.scope.confirmationOptions.length > 0;
                    confirmationTabSearchControl.isSearchingTabDataList = false;
                    confirmationTabSearchControl.searchTabDataCurrentAttempt = 0;
                    this.scope.$applyAsync();
                } else if (confirmationTabSearchControl.searchTabDataCurrentAttempt == confirmationTabSearchControl.searchTabDataMaxAttempt) {
                    confirmationTabSearchControl.isSearchingTabDataList = false;
                    this.scope.$applyAsync();
                    return this.notifyError(this.getTranslate("GENERAL.LIMIT_ATTEMPTS"))
                } else {
                    confirmationTabSearchControl.isSearchingTabDataList = true;
                    confirmationTabSearchControl.searchTabDataCurrentAttempt++;
                    confirmationTabSearchControl.searchTabDataListCount = 0;
                    this.scope.$applyAsync();
                    this.timeout(() => { this.initCountGetConfirmationTabDataList() }, 1000);
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private initCountGetConfirmationTabDataList() {
        if (!this.scope.confirmationTabSearchControl.isSearchTabDataListCanceled) {
            this.timeout(() => {
                this.scope.confirmationTabSearchControl.searchTabDataListCount += 5;
                if (this.scope.confirmationTabSearchControl.searchTabDataListCount < 100) this.initCountGetConfirmationTabDataList();
                else if (!this.scope.confirmationTabSearchControl.isSearchTabDataListCanceled) this.timeout(() => { this.getAndUpdateConfirmationList() }, 1000);
            }, 100);
        }
    }

    private async collapseExternalContacts() {
        try {
            const collapseExternalContacts = angular.element("#collapseExternalContacts");
            if (collapseExternalContacts) {
                collapseExternalContacts['collapse']('toggle');
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getExternalContacts() {
        try {
            this.block();
            const resultOperation = await this.DataProcessService.get('/processWizardPreProcessingStages/' + this.scope.model._id + '/external_contacts', null, 30000);
            if (resultOperation && resultOperation.data && resultOperation.data.data) {
                const result: IResponseProcessWizardPreProcessingStagesGet = resultOperation.data.data;
                let externalContactCompanies: IExternalContactCompany[] = [];
                this.scope.model.DETAILS_TAB.EXTERNAL_CONTACT = []
                if (result) {
                    if (!result.SHOW) this.notifyInfo(this.getTranslate("GENERAL.PROCESSING_CONTACTS"));
                    else externalContactCompanies = this.buildCompanies(result.PROCESS_WIZARD_PRE_PROCESSING_EXTERNAL_CONTACT && result.PROCESS_WIZARD_PRE_PROCESSING_EXTERNAL_CONTACT.length ? result.PROCESS_WIZARD_PRE_PROCESSING_EXTERNAL_CONTACT.map(preProcessingExternalContact => preProcessingExternalContact.EXTERNAL_CONTACT) : null);
                }
                this.scope.externalContactCompanies = externalContactCompanies;
                for (const externalContacts of externalContactCompanies) {
                    for (const externalContact of externalContacts.CONTACTS) {
                        this.scope.model.DETAILS_TAB.EXTERNAL_CONTACT.push(externalContact)
                    }
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private formatCargoTabData() {
        try {
            if (this.scope.model.CARGO_TAB && this.scope.model.CARGO_TAB.OPTION) {
                this.scope.model.CARGO_TAB.OPTION = this.scope.model.CARGO_TAB.OPTION.map(option => {
                    if (option.REFERENCE && !option.REFERENCE.DOCUMENT_TYPE && !option.REFERENCE.DOCUMENT_VALUE) option.REFERENCE = null;
                    if (option.CARGO_READINESS) {
                        option.CARGO_READINESS = {
                            ESTIMATED_DATE: option.CARGO_READINESS.ESTIMATED_DATE ? option.CARGO_READINESS.ESTIMATED_DATE : null,
                            ACTUAL_DATE: option.CARGO_READINESS.ACTUAL_DATE ? option.CARGO_READINESS.ACTUAL_DATE : null
                        };
                    };
                    if (option.SHIPMENT_REQUIRED) {
                        option.SHIPMENT_REQUIRED = {
                            DATE_OF: option.SHIPMENT_REQUIRED.DATE_OF ? option.SHIPMENT_REQUIRED.DATE_OF : null,
                            DATE_UP: option.SHIPMENT_REQUIRED.DATE_UP ? option.SHIPMENT_REQUIRED.DATE_UP : null
                        };
                    };
                    return option
                });
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private buildCompanies(externalContacts: IExternalContact[]): IExternalContactCompany[] {
        const externalContactCompanies: IExternalContactCompany[] = [];
        try {
            if (externalContacts && externalContacts.length) {
                const companies: IEntity[] = [];
                for (const externalContact of externalContacts) {
                    if (externalContact.COMPANY && companies.findIndex(company => company.ID == externalContact.COMPANY.ID) < 0) companies.push(externalContact.COMPANY);
                }
                for (const company of companies) {
                    const contacts = externalContacts && externalContacts.length ? externalContacts.filter(externalContact => externalContact.COMPANY && externalContact.COMPANY.ID == company.ID_LEGAL_PERSON) : [];
                    const externalContactCompany: IExternalContactCompany = {
                        ID: company.ID,
                        NAME: company.NAME,
                        CONTACTS: contacts,
                        ADDRESS: company.ADDRESS,
                        CODE: company.CODE,
                        SCAC: company.SCAC,
                        NUMBER_IATA: company.NUMBER_IATA,
                        TAXPAYER_NUMBER: company.TAXPAYER_NUMBER,
                        TRADING_NAME: company.TRADING_NAME,
                        CORPORATE_NAME: company.CORPORATE_NAME,
                        ID_LEGAL_PERSON: company.ID_LEGAL_PERSON,
                        ID_PHYSICAL_PERSON: company.ID_PHYSICAL_PERSON,
                        NETWORK: company.NETWORK,
                    };
                    externalContactCompanies.push(externalContactCompany);
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
        return externalContactCompanies;
    }

    private cancelPreviewSearch() {
        this.scope.previewTabSearchControl.isSearchTabDataListCanceled = true;
        this.scope.previewTabSearchControl.isSearchingTabDataList = false;
        this.scope.previewTabSearchControl.searchTabDataCurrentAttempt = 0;
    }

    private cancelConfirmationSearch() {
        this.scope.confirmationTabSearchControl.isSearchTabDataListCanceled = true;
        this.scope.confirmationTabSearchControl.isSearchingTabDataList = false;
        this.scope.confirmationTabSearchControl.searchTabDataCurrentAttempt = 0;
    }

    private viewOptionErrors(errors: IError[]) {
        if (errors && errors.length) {
            let errorTrs = "";
            for (const error of errors) {
                errorTrs += `
                    <tr>
                        <td>${error.SUBJECT}</td>
                        <td>${error.REASON}</td>
                    </tr>
                `;
            }
            const errorsTable = `
                <table class="table gray-border table-bordered m-b-none table-responsive">
                    <thead>
                        <th width="30%">${this.getTranslate("REGISTRATION.SUBJECT")}</th>
                        <th width="70%">${this.getTranslate("GENERAL.REASON")}</th>
                    </thead>
                    <tbody>
                        ${errorTrs}
                    </tbody>
                </table>
            `;
            const html = `
                <div class="row">
                    <div class="col-lg-12">
                        ${errorsTable}
                    </div>
                </div>
            `;
            this.ModalService.showModalInfo({ size: 'vlg' }, {
                actionButtonText: 'GENERAL.CLOSE',
                actionButtonClass: 'btn-danger',
                headerText: 'GENERAL.ERRORS',
                bodyText: this.$sce.trustAsHtml(html)
            });
        }
    }

    private addReferenceList(documentType: ISelectorModel, documentValue: string, optionIndex: number, index: number) {
        try {
            const operationalGroup = this.scope.fileGroupList && this.scope.fileGroupList.length ? this.scope.fileGroupList.find(fileGroup => fileGroup.ID == EFileGroupId.OPERACIONAL) : null;
            let reference = <FileReferenceModel>{};
            reference.REFERENCE_ID = null;
            reference.ID_CONSOLIDATED = null;
            reference.SOURCE = "MONACO";
            reference.TYPE = "REFERENCE";
            reference.NAME = null;
            reference.LINK = null;
            reference.TEMPLATE_TYPE = null;
            reference.DOCUMENT_TYPE = (documentType) ? documentType : null;
            reference.DOCUMENT_VALUE = (documentValue) ? documentValue : null;
            reference.FILE_GROUP = operationalGroup;
            reference.FILE_SPECS = [{ ID: '2', NAME: "Cliente" }];
            reference.MAIN_FILE = true;
            reference.INSERT_DATE = new Date();
            reference.USER_REFERENCE = this.scope.user._id;
            this.scope.model.CARGO_TAB.OPTION[optionIndex].REFERENCE = reference;
            this.timeout(() => { this.scope.selectorValidity(`document_type${optionIndex}_${index}`); }, 100);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getGenericList(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async getlegalPersonList(model_data: IEntity[], search: string, specializations?: string[]): Promise<IEntity[]> {
        let result: IEntity[] = [];

        try {
            this.block();
            if (model_data && model_data.length > 0) result = model_data;
            else {
                const productReq: IMonacoRequest = {
                    route: `/legalPerson/list/custom/operational`,
                    data: { specializations, search }
                }
                const legalPersons = await this.ProductService.post<any>(productReq);
                if (legalPersons && legalPersons.data) {
                    result = legalPersons.data.data ? legalPersons.data.data.map(x => {
                        return {
                            ID: x.ID, NAME: x.NAME, CODE: x.CODE,
                            TRADING_NAME: x.TRADING_NAME, TAXPAYER_NUMBER: x.TAXPAYER_NUMBER, SCAC: x.SCAC, NUMBER_IATA: x.NUMBER_IATA, ADDRESS: x.ADDRESS,
                            ID_LEGAL_PERSON: x.ID, NETWORK: x.NETWORK,
                            CORPORATE_NAME: x.CORPORATE_NAME, ID_PHYSICAL_PERSON: null
                        }
                    }) : [];
                }
            }
            return result
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getTerminalList(search: string): Promise<ITerminalRedirect[]> {
        let result: ITerminalRedirect[] = [];

        try {
            this.block();

            const productReq = {
                route: `/legalPerson/list/custom/operational`,
                data: { specializations: ['16'], search: search }
            }
            const legalPersons = await this.ProductService.post<any>(productReq);
            result = legalPersons.data.data ? legalPersons.data.data.map(x => {
                return {
                    ID: x.ID, NAME: x.NAME, CODE: x.CODE,
                    TRADING_NAME: x.TRADING_NAME, CORPORATE_NAME: x.CORPORATE_NAME, ID_LEGAL_PERSON: x.ID_LEGAL_PERSON, ID_PHYSICAL_PERSON: null, TAXPAYER_NUMBER: x.TAXPAYER_NUMBER, SCAC: x.SCAC, NUMBER_IATA: x.NUMBER_IATA,
                    ADDRESS: x.ADDRESS && x.ADDRESS.CORRESPONDENCE ? x.ADDRESS.CORRESPONDENCE : ''
                }
            }) : [];

            return result
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getOuterCellList(search: string): Promise<IOuterCells[]> {
        let result: IOuterCells[] = [];

        try {
            this.block();

            const productReq = {
                route: `/outerCell/list/custom`,
                data: { name: search, entityIds: [] }
            }
            const outerCells = await this.ProductService.post<any>(productReq);
            result = outerCells.data.data ? outerCells.data.data.map(outerCell => { return { ID: outerCell.ID, NAME: outerCell.NAME, CODE: outerCell.CODE ? outerCell.CODE : null, ENTITY: outerCell.ENTITY ? outerCell.ENTITY : null } }) : [];

            return result
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async finish(): Promise<void> {
        try {
            const hasAnyConfirmationOptionWithError = this.hasAnyOptionWithError();
            const confirmed = hasAnyConfirmationOptionWithError ? await this.ModalService.showModalConfirmation({}, {
                bodyText: this.getTranslate("OPERATIONAL.THERE_IS_PROCESSES_WITH_ERROR_CONFIRMATION")
            }) : true;
            if (confirmed) {
                this.scope.modalOptions.ok(true);
                const processNumbers = this.scope.confirmationOptions.map(confirmationOption => confirmationOption.CONFIRMATION ? confirmationOption.CONFIRMATION.PROCESS_NUMBER : null);
                this.refreshProcessRouteParam(processNumbers);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private hasAnyOptionWithError(): boolean {
        return this.scope.confirmationOptions && this.scope.confirmationOptions.length ? this.scope.confirmationOptions.some(option => option.ERRORS && option.ERRORS.length) : false;
    }

    private hasAnySearchControlInProgress(): boolean {
        return (this.scope.previewTabSearchControl && this.scope.previewTabSearchControl.isSearchingTabDataList) || (this.scope.confirmationTabSearchControl && this.scope.confirmationTabSearchControl.isSearchingTabDataList);
    }

    private goToProcesses(processNumbers: string) {
        this.$SessionService.openTab("app.operational.newProcess.list", <IProcessParameter>{ "PROCESS_NUMBER": processNumbers });
    }

    private async getEventTransportMode() {
        const timeout = 15000;
        const request = {
            offerCompiled: this.scope.model.OFFER_COMPILED,
            timeout: timeout
        };

        return this.operationalService.post('/offercompiled/transportmode', request, timeout);
    }

    private addStopover(maritimeStopover: IMaritimeStopover, index: number): void {
        if (maritimeStopover) {
            if (!this.scope.model.CARGO_TAB.OPTION[index].MARITIME_STOPOVER && !this.scope.getVoyageByIntegration) return;

            this.scope.model.CARGO_TAB.OPTION[index].MARITIME_STOPOVER.STOPOVER = maritimeStopover.STOPOVER;
            this.scope.model.CARGO_TAB.OPTION[index].MARITIME_STOPOVER.VESSEL = maritimeStopover.VESSEL;
            this.scope.model.CARGO_TAB.OPTION[index].MARITIME_STOPOVER.FORECAST_DATE = maritimeStopover.FORECAST_DATE;
            this.buildDeadlines(maritimeStopover.VESSEL.ID, maritimeStopover.VESSEL.NAME)
        }
    }

    public montageMaritimeStopover(maritimeStopover): IMaritimeStopover[] {
        this.scope.deadlinesList = []

        if (maritimeStopover) {
            return maritimeStopover.map(x => {
                const stopoverSituation = (x.stopoverSituation.NAME === 'Sugerida') ? x.stopoverSituation.NAME : `${x.stopoverSituation.NAME}`;
                const eta = `ETA: ${(x.estimatedDate) ? this.$filterService('date')(x.estimatedDate, "dd/MM/yyyy") : 'Sugerida'}`;
                const shipowners = x.carriers.reduce((val, acc) => { return `${val} | ${acc.description}` }, '');

                const vessel = { ID: x.vessel.ID, NAME: `${x.vessel.NAME} ${this.findVesselCompatible(x.carriers)}` };
                const stopover = { ID: x.stopover.ID, NAME: x.stopover.NAME, CODE: `${x.vessel.NAME} | ${stopoverSituation} | ${eta} ${shipowners}` };
                const forecastDate = x.estimatedDate;
                this.scope.deadlinesList.push({ DEADLINES: x.deadlines, ID: vessel.ID, NAME: `${x.vessel.NAME} ${this.findVesselCompatible(x.carriers)}` })

                return {
                    VESSEL: vessel,
                    STOPOVER: stopover,
                    FORECAST_DATE: forecastDate
                }
            })
        }
    }

    private async getTransportFromOceanSchedule(search: string) {
        let event: EVENT = null;
        this.scope.model.OFFER_COMPILED.EVENT.forEach(OfferCompiledEvent => {
            if (OfferCompiledEvent.EVENT_TYPE.ID === EEventType.LOAD) {
                event = OfferCompiledEvent;
            }
        });

        const local = event.LOCAL && event.LOCAL.CODE ? event.LOCAL.CODE : null;
        let originPort: string = null;
        let destinationPort: string = null;
        let searchDateType: string = null;
        let searchDate: Date = moment(new Date()).toDate();
        let isLoad: boolean = [EEventType.LOAD, EEventType.LOAD_TRANSHIPMENT].includes(event.EVENT_TYPE.ID as EEventType);

        // Check if the event is load or discharge.
        if (isLoad) {
            const discharge = this.scope.model.OFFER_COMPILED.EVENT ? this.scope.model.OFFER_COMPILED.EVENT.find(x => x.EVENT_TYPE.ID == EEventType.DISCHARGE) : null;
            originPort = local;
            destinationPort = discharge && discharge.LOCAL ? discharge.LOCAL.CODE : null;
            searchDateType = EInttraSearchDateType.DEPARTURE;
        }

        let scacCodes: string = "";
        if (this.scope.providerListForInttraVoyage && this.scope.providerListForInttraVoyage.length > 0) {
            if (this.scope.model.OFFER_COMPILED.SERVICE_PROVIDER) this.scope.providerListForInttraVoyage = this.scope.providerListForInttraVoyage.filter(x => x.CODE == this.scope.model.OFFER_COMPILED.SERVICE_PROVIDER.SCAC);
            for (const item of this.scope.providerListForInttraVoyage) {
                scacCodes += item.CODE;
                scacCodes += ',';
            }
            scacCodes = scacCodes.slice(0, -1);
        }

        const requestData: IInttraOceanSchedulesRequest = {
            scacs: scacCodes,
            originPort,
            destinationPort,
            searchDate,
            weeksOut: "6",
            searchDateType
        };

        this.block();
        const oceanSchedulesRequest = await this.externalService.post({
            route: '/inttra/oceanSchedule', data: { data: requestData }
        });
        this.unblock();

        let i: number = 0;
        this.scope.oceanScheduleConcatenated = [];
        if (oceanSchedulesRequest && oceanSchedulesRequest.data && oceanSchedulesRequest.data.data) {
            this.scope.oceanScheduleOptions = oceanSchedulesRequest.data.data;
            let transshipmentConcatenated: string[] = [];

            if (this.scope.oceanScheduleOptions.length > 0) {

                this.scope.oceanScheduleConcatenated = this.scope.oceanScheduleOptions.map(result => {
                    const estimatedDate = isLoad ? moment(result.originDepartureDate).format('DD/MM/YYYY') : null;
                    const direct = result.scheduleType == 'transshipment' ? 'TS: ' : 'DIRECT';
                    const scac = this.scope.externalShipowners.find(x => x.CODE == result.scac);
                    ++i;
                    result['ID'] = i;
                    transshipmentConcatenated = [];

                    const arrayDeadline: DEADLINES[] = [];
                    arrayDeadline.push({
                        DRAFT_IMO: result.hazBkCutoff,
                        DRAFT: result.siCutoff,
                        VGM: result.vgmCutoff,
                        RELEASE: result.terminalCutoff,
                        BASE_DATE: new Date(),
                        CARRIER: null,
                        TYPE: "MASTER"
                    });

                    this.scope.deadlinesList.push({
                        DEADLINES: arrayDeadline,
                        ID: i.toString(),
                        NAME: result.vesselName + ' | ' + estimatedDate + ' (' + moment(result.originDepartureDate).format('dddd') + ') | ' + result.carrierName + ' | ' + result.voyageNumber + ' | ' + direct + transshipmentConcatenated.join(' '),
                    })

                    if (direct == 'TS: ' && result.legs && result.legs.length > 0) {
                        result.legs.forEach(leg => {
                            if (leg.sequence > 1) {
                                transshipmentConcatenated.push(leg.transportName + ' | ' + leg.departureCityName + ' (' + leg.departureUnloc + ') | ' + moment(leg.departureDate).format('DD/MM/YYYY') + ' (' + moment(leg.departureDate).format('dddd') + ')');
                            }
                        });
                    }

                    return {
                        ID: result['ID'].toString(),
                        NAME: result.vesselName + ' | ' + estimatedDate + ' (' + moment(result.originDepartureDate).format('dddd') + ') | ' + result.carrierName + ' | ' + result.voyageNumber + ' | ' + direct + transshipmentConcatenated.join(' '),
                        CODE: result.imoNumber,
                        LOCAL: {
                            ID: null,
                            NAME: isLoad ? result.originCityName : result.destinationCityName,
                            CODE: isLoad ? result.originUnloc : result.destinationUnloc
                        },
                        SERVICE_PROVIDER: scac.NAME,
                        ETB: result.originDepartureDate
                    }
                });

                if (isLoad) {
                    this.scope.oceanScheduleConcatenated = this.scope.oceanScheduleConcatenated.filter(x => x.LOCAL.CODE == local);
                }

                if (this.scope.model.OFFER_COMPILED.SERVICE_PROVIDER) {
                    this.scope.oceanScheduleConcatenated = this.scope.oceanScheduleConcatenated.filter(x => x.SERVICE_PROVIDER == this.scope.model.OFFER_COMPILED.SERVICE_PROVIDER.SCAC)
                }
            }
        }

        // Get the already registered voyages
        const actualVoyageRequest = {
            port: originPort,
            providerCode: this.scope.model.OFFER_COMPILED.SERVICE_PROVIDER.SCAC
        };

        const actualVoyages = await this.operationalService.post('/voyage/getVoyageToEvent', actualVoyageRequest);
        if (actualVoyages && actualVoyages.data && actualVoyages.data.data && actualVoyages.data.data.data) {
            for (const itemVoyage of actualVoyages.data.data.data) {
                const transportNameConcatenated = itemVoyage.VOYAGE.SHIP.NAME + ' | ' + moment(itemVoyage.ETB).format('DD/MM/YYYY') + ' (' + moment(itemVoyage.ETB).format('dddd') + ') | ' + itemVoyage.VOYAGE_PROVIDER_FULL.PROVIDER.NAME + ' | ' + itemVoyage.VOYAGE_PROVIDER_FULL.VOYAGE_NUMBER;
                ++i;
                const transportItemInttra: ITransportModeInttra = {
                    ID: itemVoyage.ID,
                    NAME: transportNameConcatenated,
                    CODE: itemVoyage.VOYAGE.SHIP.CODE,
                    LOCAL: {
                        ID: itemVoyage.PORT_ORIGIN.ID,
                        NAME: itemVoyage.PORT_ORIGIN.NAME,
                        CODE: itemVoyage.PORT_ORIGIN.CODE
                    },
                    SERVICE_PROVIDER: itemVoyage.VOYAGE_PROVIDER_FULL.PROVIDER.CODE,
                    ETB: itemVoyage.ETB
                };

                const inttraFakeOceanScheduleItem: IInttraOceanSchedulesResult = {
                    vesselName: itemVoyage.VOYAGE.SHIP.NAME,
                    voyageNumber: itemVoyage.VOYAGE_PROVIDER_FULL.VOYAGE_NUMBER,
                    serviceName: null,
                    terminalCutoff: null,
                    originUnloc: itemVoyage.PORT_ORIGIN.CODE,
                    originDepartureDate: itemVoyage.ETB,
                    destinationUnloc: null,
                    destinationArrivalDate: null,
                    totalDuration: null,
                    imoNumber: itemVoyage.VOYAGE.SHIP.CODE,
                    extraLoader: false,
                    scac: itemVoyage.VOYAGE_PROVIDER_FULL.PROVIDER.CODE,
                    carrierName: itemVoyage.VOYAGE_PROVIDER_FULL.PROVIDER.NAME
                };
                inttraFakeOceanScheduleItem["ID"] = itemVoyage.ID;

                const index = this.scope.oceanScheduleConcatenated.findIndex(x => x.NAME.split("|")[3].trim() == itemVoyage.VOYAGE_PROVIDER_FULL.VOYAGE_NUMBER);
                if (index > -1) this.scope.oceanScheduleConcatenated.splice(index, 1);

                if (!this.scope.oceanScheduleConcatenated.find(x => x.ID == itemVoyage.ID)) {
                    this.scope.oceanScheduleOptions.push(inttraFakeOceanScheduleItem);
                    this.scope.oceanScheduleConcatenated.push(transportItemInttra);

                    const arrayDeadline: DEADLINES[] = [];
                    if (itemVoyage.VOYAGE_DEADLINE.length) {
                        for (const deadline of itemVoyage.VOYAGE_DEADLINE) {
                            if (deadline.PROVIDER_DEADLINE.length) {
                                for (const providerDeadline of deadline.PROVIDER_DEADLINE) {
                                    arrayDeadline.push({
                                        "VGM": providerDeadline.VGM,
                                        "DRAFT_IMO": providerDeadline.DRAFT_IMO,
                                        "DRAFT": providerDeadline.DRAFT,
                                        "RELEASE": providerDeadline.RELEASE,
                                        "BASE_DATE": providerDeadline.BASE_DATE,
                                        "TYPE": providerDeadline.TYPE.ID == EProcessDocumentTypeId.MASTER ? EProcessDocumentType.MASTER : EProcessDocumentType.HOUSE,
                                        "CARRIER": null
                                    })
                                }
                            }

                        }

                        if (arrayDeadline.length) {
                            this.scope.deadlinesList.push({
                                DEADLINES: arrayDeadline,
                                ID: itemVoyage.ID,
                                NAME: transportNameConcatenated
                            })
                        }
                    }
                }
            }
        }

        // Sort the array based on date
        this.scope.oceanScheduleConcatenated.sort(function (a, b) {
            var dateA = new Date(a.ETB).getTime();
            var dateB = new Date(b.ETB).getTime();
            return dateA > dateB ? 1 : -1;
        });

    }

    private findVesselCompatible(carriers) {
        const compatibleCarrier = carriers && carriers.find(carrier => carrier && carrier.compatible);

        const validatedCompatibleCarrier =
            compatibleCarrier &&
            compatibleCarrier.carrierInformation &&
            compatibleCarrier.carrierInformation.VOYAGE;

        return validatedCompatibleCarrier ? `| ${validatedCompatibleCarrier}` : '';
    }

    private async addInternalTag(instructions: INSTRUCTION, data: string, type: IProcessInstructionType) {
        try {
            if (!instructions) throw new Error("Módulo de instruções está nulo.");
            if (!type) throw new Error("Tipo de instrução está nulo.");

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.getTranslate('OPERATIONAL.DO_YOU_WANT_TO_ADD_AN_INTERNAL_TAG_TO_THIS_INSTRUCTION')
            });

            if (!modal) return;

            const tagStart = "[internal";
            const tagEnd = "[/internal]";

            data = (data) ? data : "";
            const msgError = this.getTranslate('OPERATIONAL.TAG_ALREADY_EXISTS');
            if (data.indexOf(tagStart) >= 0) throw new Error(msgError);

            let tag: string = "";

            tag += tagStart + " " + moment(new Date()).format('DD[/]MM[/]YYYY HH:mm') + " - " + this.scope.user['email'] + "]\n\n";
            tag += ` ***** ${this.getTranslate('OPERATIONAL.USE_THIS_SPACE_TO_PUT_IMPORTANT_INFORMATION')} *****\n\n`;
            tag += tagEnd + "\n\n";

            this.fillInstructionInformation(instructions, type, tag + data);

            this.scope.updatedInstructions = true;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async deleteInternalTag(instructions: INSTRUCTION, data: string, type: IProcessInstructionType) {
        try {
            if (!instructions) throw new Error("Módulo de instruções está nulo.");
            if (!type) throw new Error("Tipo de instrução está nulo.");

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.getTranslate('GENERAL.DO_YOU_WANT_TO_REMOVE')
            });

            if (!modal) return;

            const tagStart = "[internal";
            const tagEnd = "[/internal]";
            const msgError = this.getTranslate('OPERATIONAL.NO_INTERNAL_TAG');
            if (!data || data.indexOf(tagStart) < 0 || data.indexOf(tagEnd) < 0) throw new Error(msgError);

            const posFirstTag = data.indexOf(tagStart);
            const posSecondTag = data.indexOf(tagEnd);

            const firstSection = data.substring(0, posFirstTag);
            const secondSection = data.substring(posSecondTag + tagEnd.length, data.length);
            const editedInstruction = firstSection.trim() + "\n" + secondSection.trim();

            this.fillInstructionInformation(instructions, type, editedInstruction.trim());
            const msg = this.getTranslate('OPERATIONAL.THE_INTERNAL_TAG_WAS_SUCESSFULLY_REMOVED');
            this.notifySuccess(msg);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async cleanInstructionAll(instructions: INSTRUCTION, type: IProcessInstructionType) {
        try {
            if (!instructions) throw new Error("Módulo de instruções está nulo.");
            if (!type) throw new Error("Tipo de instrução está nulo.");

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.getTranslate('OPERATIONAL.DO_YOU_WANT_TO_REMOVE_ALL_THE_INFORMATION_OF_THIS_INSTRUCTION')
            });

            if (!modal) return;

            this.fillInstructionInformation(instructions, type, "");

            this.scope.updatedInstructions = true;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private fillInstructionInformation(instructions: INSTRUCTION, type: IProcessInstructionType, data: string) {
        switch (type) {
            case IProcessInstructionType.PRODUCT: instructions.PRODUCT = data;
                break;
            case IProcessInstructionType.COMMERCIAL: instructions.COMMERCIAL = data;
                break;
            case IProcessInstructionType.OPERATIONAL: instructions.OPERATIONAL = data;
                break;
            case IProcessInstructionType.FINANCIAL: instructions.FINANCIAL = data;
                break;
            case IProcessInstructionType.CUSTOMER: instructions.CUSTOMER = data;
                break;
            case IProcessInstructionType.AGENT: instructions.AGENT = data;
                break;
            case IProcessInstructionType.HOUSE: instructions.PROCESS_HOUSE = data;
                break;
            case IProcessInstructionType.MASTER: instructions.PROCESS_MASTER = data;
                break;
        }


    }

    private async setScopeCommodities() {
        const commodityRequestResult: IOfferCommodityRequestViewResult = await this.getCommodityList();
        this.scope.commodities = commodityRequestResult && commodityRequestResult.COMMODITY_ITEM ? commodityRequestResult.COMMODITY_ITEM : null;
        this.scope.exceptions = await this.getCommodityException();
    }

    private async openCommoditiesModal(): Promise<void> {
        this.scope.lastScroll = $('.app-content-body').scrollTop();

        const commodities = angular.copy(this.scope.commodities)
        const exceptions = angular.copy(this.scope.exceptions)
        this.commoditiesModalId = this.ModalService.newModal();
        this.ModalService.showModalInfo(
            {
                modalID: this.commoditiesModalId,
                scope: this.scope,
                formService: 'register',
                size: 'lg modal-overflow',
                template: require("../view/modal/processWizardCommodityModal.html"),
                keyboard: true,
                events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                    if (event.name == "modal.closing") {
                        if (reason.toString() == "escape key press") event.preventDefault();
                        if (!closed) {
                            const modalScope: ICommodityModalScope = await this.ModalService.getModalScope(this.commoditiesModalId);
                            await modalScope.closeCommodityModal();
                        }
                    }
                }
            },
            null
        );
        await this.buildCommodityModalScope(commodities, exceptions);
    }

    private async getCommodityList(): Promise<IOfferCommodityRequestViewResult> {
        let commodityResult: IOfferCommodityRequestViewResult = null;
        this.block();
        try {

            const resultOperation = await this.ProductService.get({ route: `/offer/tabs/commodity/view/${this.scope.model.OFFER_COMPILED.ID}` });
            if (resultOperation && resultOperation.data && resultOperation.data.data) commodityResult = resultOperation.data.data;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return commodityResult;
        }
    }

    private async getCommodityException(): Promise<ICommodity[]> {
        let exceptions: ICommodity[] = null;
        this.block();
        try {
            const resultOperation = await this.ProductService.post({
                route: "/offer/list/commodity/exceptions",
                data: { offerId: this.scope.model.OFFER_COMPILED.ID }
            });
            if (resultOperation && resultOperation.data && resultOperation.data.data && resultOperation.data.data.length) exceptions = resultOperation.data.data;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return exceptions;
        }
    }

    private async buildCommodityModalScope(commodities: IOfferCommodity[], exceptions: ICommodity[]): Promise<ICommodityModalScope> {
        const modalScope: ICommodityModalScope = await this.ModalService.getModalScope(this.commoditiesModalId);

        try {
            modalScope.operation = EOperation.NEW;
            modalScope.commodities = commodities;
            modalScope.oldCommodities = angular.copy(commodities);
            modalScope.exceptions = exceptions;

            // get generics
            const result: Array<any> = await this.$q.all([
                this.getCommoditySectionList()
            ]);
            modalScope.sectionList = result[0];

            modalScope.addCommodity = (): void => {
                try {
                    if (!modalScope.commodities) modalScope.commodities = [];
                    const commodity: IOfferCommodity = {
                        COMMODITY: null,
                        COMMODITY_SECTION: null,
                        DATA_ORIGIN_TYPE: { ID: EDataOriginTypeId.MANUAL, NAME: "Manual" }
                    }
                    modalScope.commodities.push(commodity);
                    const lastIndex = modalScope.commodities.length - 1;
                    this.timeout(() => {
                        modalScope.selectorValidity("commoditySection" + lastIndex);
                        modalScope.selectorValidity("commodityCommodity" + lastIndex);
                    }, 100);
                } catch (ex) {
                    this.handleError(ex);
                }
            }

            modalScope.removeCommodity = async (index: number): Promise<void> => {
                try {
                    if (!index && index != 0) throw Error('index is null');
                    const modal = await this.ModalService.showModalConfirmation({}, {
                        actionButtonText: 'GENERAL.CONFIRM',
                        headerText: 'GENERAL.CONFIRM_ACTION',
                        bodyText: this.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
                    });
                    if (!modal) return;

                    if (modalScope.commodities && modalScope.commodities.length > 0) {
                        this.block();
                        modalScope.commodities.splice(index, 1);
                        this.timeout(() => {
                            modalScope.selectorValidity("commoditySection" + index);
                            modalScope.selectorValidity("commodityCommodity" + index);
                        }, 100);
                    }
                } catch (ex) {
                    this.handleError(ex);
                } finally {
                    this.unblock();
                }
            }

            modalScope.applyCommodity = (close?: boolean) => {
                this.applyCommodity(modalScope.commodities, modalScope.exceptions, close);
            };

            modalScope.closeCommodityModal = async (): Promise<void> => {
                if (this.scope.hasChanges(modalScope.commodities, modalScope.oldCommodities)) {
                    const confirm = await this.scope.modalSaveConfirmation("GENERAL.CLOSE", "GENERAL.CLOSE");
                    if (confirm && !this.applyCommodity(modalScope.commodities, modalScope.exceptions)) return;
                }
                this.ModalService.closeModal(this.commoditiesModalId);
                this.commoditiesModalId = 0;
                $('.app-content-body').animate({ scrollTop: this.scope.lastScroll }, 0);
            }

            modalScope.getCommodityListByName = async (name: string): Promise<void> => {
                let commodityList = [];
                if (name && name.length >= 2) {
                    commodityList = await this.getCommodityListByName(name);
                }
                modalScope.commodityList = commodityList;
            };

            modalScope.commodityChange = (index: number) => {
                if (!index && index != 0) return this.notifyError("index is null.");
                const commodity = modalScope.commodities && modalScope.commodities.length ? modalScope.commodities[index] : null;
                if (commodity) {
                    if (commodity.COMMODITY_SECTION) {
                        commodity.COMMODITY = null;
                    } else if (commodity.COMMODITY) {
                        commodity.COMMODITY_SECTION = null;
                    }
                    this.timeout(() => {
                        modalScope.selectorValidity("commoditySection" + index);
                        modalScope.selectorValidity("commodityCommodity" + index);
                    }, 100);
                }
            }

            modalScope.isCommodityDisabled = (commodity: IOfferCommodity, fieldName: string): boolean => {
                let disabled = false;
                try {
                    if (!commodity) throw Error('commodity is null');
                    if (!fieldName) throw Error('fieldName is null');
                    if (
                        (fieldName == 'section' && commodity.COMMODITY) ||
                        (fieldName == 'commodity' && commodity.COMMODITY_SECTION))
                        disabled = true;
                } catch (ex) {
                    this.handleError(ex);
                } finally {
                    return disabled;
                }
            }

        } catch (ex) {
            this.handleError(ex);
        }

        return modalScope;
    }

    private async getCommoditySectionList(): Promise<SelectorModel[]> {
        let result = [];
        this.block();
        try {
            const sections = await this.getGenericList('hscode_section');

            result = (sections && sections.length) ? sections : [];
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getCommodityListByName(name?: string): Promise<SelectorModel[]> {
        let result = [];
        this.block();
        try {
            const commodities = await this.ProductService.post({
                route: "/commodity/list/custom",
                data: { name }
            });
            if (commodities && commodities.data && commodities.data.data && commodities.data.data.length > 0) result = commodities.data.data.map(commodity => { return { ID: commodity.ID, NAME: commodity.NAME, HS_CODE: commodity.HS_CODE ? commodity.HS_CODE : null } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async applyCommodity(commodities: IOfferCommodity[], exceptions: ICommodity[], close?: boolean): Promise<boolean> {
        let success = false;
        try {
            const hasInvalid = this.scope.hasInvalidRequiredElements('modalForm');
            if (!hasInvalid) {
                this.block();
                this.scope.model.OFFER_TAB.COMMODITY_SUMMARY.COMMODITY_ITEM = commodities;
                this.scope.model.OFFER_TAB.COMMODITY_SUMMARY.COMMODITY_EXCEPTIONS = exceptions
                this.scope.model.OFFER_TAB.COMMODITY_SUMMARY.COMMODITY_TEXT = this.buildCommodityConcatenated(commodities);
                angular.element('#commoditySummary').html(this.scope.model.OFFER_TAB.COMMODITY_SUMMARY.COMMODITY_TEXT);
                angular.element('#commodityExceptionSummary').html(this.buildExceptionsView(exceptions));
                this.scope.commodities = commodities
                if (close) {
                    this.ModalService.closeModal(this.commoditiesModalId);
                    this.commoditiesModalId = 0;
                    $('.app-content-body').animate({ scrollTop: this.scope.lastScroll }, 0);
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            if (success) {
                const msg = this.getTranslate('BASIC_DATA.COMMODITY_SUCESSFULLY_UPDATED');
                this.notifySuccess(msg);
            }
            return success;
        }
    }

    private hasInvalidRequiredElements(elementId: string): boolean {
        if (!elementId) return false;
        const isInvalid = FormService2.hasRequiredElements('#' + elementId);
        if (isInvalid) this.notifyError(this.getTranslate("GENERAL.ALL_FIELDS_MANDATORY"));
        return isInvalid;
    }

    private async modalSaveConfirmation(headerText: string, closeButtonText: string): Promise<boolean> {
        return await this.ModalService.showModalConfirmation({}, {
            headerText: headerText,
            bodyText: this.getTranslate('REGISTRATION.MESSAGES.ERROR.UPDATE_NOT_SAVED'),
            actionButtonText: "GENERAL.SAVE",
            closeButtonText: closeButtonText
        });
    }

    private buildCommodityConcatenated(commoditySummary: IOfferCommodity[]): string {
        let concatenated = "";
        try {
            if (commoditySummary) {

                const commodityList: ICommodity[] = []
                const commoditySectionList: ISelectorModel[] = []
                let commoditiesList = []
                for (const commodity of commoditySummary) {
                    if (commodity.COMMODITY_SECTION) {
                        commoditySectionList.push(commodity.COMMODITY_SECTION)
                    }
                    if (commodity.COMMODITY) {
                        commodityList.push(commodity.COMMODITY)
                    }
                }
                commoditiesList = commodityList && commodityList.length ? commodityList.map(commodity => {
                    return { NAME: `${commodity.HS_CODE} - ${commodity.NAME}` };
                }) : null;
                const sectionList = commoditySectionList && commoditySectionList.length ? commoditySectionList.map(section => {
                    return { NAME: `${section.ID} - ${section.NAME}` };
                }) : null;
                const sections = sectionList && sectionList.length ? this.getCONCAT(sectionList, null, "NAME", null, null, ',') : "";

                const commodities = commoditiesList && commoditiesList.length ? this.getCONCAT(commoditiesList, null, "NAME", null, null, ",") : "";
                concatenated = concatenated.length > 0 ? concatenated.concat("; ", sections) : concatenated.concat(sections);
                concatenated = concatenated.length > 0 ? concatenated.concat("; ", commodities) : concatenated.concat(commodities);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            return concatenated;
        }
    }

    private buildExceptionsView(commodityExceptions: ICommodity[]): string {
        let exceptionList = "";
        try {
            if (commodityExceptions) {
                const exceptionsList = commodityExceptions && commodityExceptions.length ? commodityExceptions.map(exception => {
                    return { NAME: `${exception.HS_CODE} - ${exception.NAME}` };
                }) : null;
                const exceptions = exceptionsList && exceptionsList.length ? this.getCONCAT(exceptionsList, null, "NAME", null, null, ",") : "";
                exceptionList = exceptionList.concat(exceptions);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            return exceptionList;
        }
    }

    private buildDeadlines(stopoverId: string, stopoverName: string): void {
        try {
            if (!stopoverId) {
                this.scope.deadlines = null;
                return this.notifyError('Plese select stopover first');
            }
            this.scope.deadlines = this.scope.deadlinesList.find(deadlines => deadlines.ID == stopoverId && deadlines.NAME == stopoverName);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openDeadlines(): Promise<void> {
        if (!this.scope.deadlines) return this.notifyError('Plese select stopover first');

        this.deadlinesModalId = this.ModalService.newModal();
        this.ModalService.showModalInfo(
            {
                modalID: this.deadlinesModalId,
                scope: this.scope,
                formService: this.scope.operation,
                size: 'lg modal-overflow',
                template: require("../view/wizard/process/deadlinesModal.html"),
                keyboard: false
            },
            null
        );

        this.buildOfferProcessModalScope();
    }

    private async buildOfferProcessModalScope(): Promise<IDeadlinesModal> {
        try {

            this.block();
            const modalScope: IDeadlinesModal = await this.ModalService.getModalScope(this.deadlinesModalId);

            const filterDealines = this.scope.deadlines && this.scope.deadlines.DEADLINES && this.scope.deadlines.DEADLINES.length > 0 ? !this.scope.getVoyageByIntegration ? this.scope.deadlines.DEADLINES.filter(dealine => dealine.CARRIER && dealine.CARRIER.ID === this.scope.model.OFFER_COMPILED.SERVICE_PROVIDER.ID) : this.scope.deadlines.DEADLINES : null;
            modalScope.deadlines.DEADLINES = angular.copy(filterDealines);

            return modalScope;
        } catch (ex) {
            this.ModalService.closeModal(this.deadlinesModalId);
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async openProcessDeadlineModal(currentIndex: number): Promise<void> {
        const currentDeadline = this.scope.deadlines.DEADLINES.find((deadline, index) => index == currentIndex);
        let master: DEADLINES
        let house: DEADLINES
        if (currentDeadline.TYPE == EProcessDocumentType.MASTER) {
            master = currentDeadline
            house = this.scope.deadlines.DEADLINES.find((deadline, index) => index == (currentIndex + 1))
        }

        if (currentDeadline.TYPE == EProcessDocumentType.HOUSE) {
            house = currentDeadline
            master = this.scope.deadlines.DEADLINES.find((deadline, index) => index == (currentIndex - 1))
        }

        const modalParameters: IDeadlinesDetailsModalParameter = {
            title: `${this.getTranslate('OPERATIONAL.VIEWING_DEADLINES')}`,
            master,
            house,
        }

        await this.show(modalParameters);
    }

    public calculateLoad(offerCompiled: IOfferCompiled): Date {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (offerCompiled && (new Date(offerCompiled.VALIDITY_OF) > today)) {
            return new Date(offerCompiled.VALIDITY_OF)
        } else {
            return today
        }
    }

    public async show(parameters: IDeadlinesDetailsModalParameter): Promise<boolean> {
        let { house, master } = parameters;
        const { title } = parameters;
        const modalID: number = this.ModalService.newModal();

        const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo({
            modalID,
            scope: this.scope,
            formService: EOperation.VIEW,
            template: require("../view/wizard/process/deadlinesDetailsModal.html"),
            size: 'lg'
        }, {
            actionButtonText: 'Fechar',
            headerText: title,
        }, {
            master: master,
            house: house,
        });

        const apply = await modalInstance.result.then(function (result) {
            return result.$value;
        }, function (result) {
            return result.$value;
        });

        return apply;
    }

    private refreshProcessRouteParam(processNumbers: string[]): void {
        const concatProcessNumbers = this.getCONCAT(processNumbers);

        if (this.scope.processRouteRedirect) {
            this.goToProcesses(concatProcessNumbers);
        } else {
            const sessionParameter = <IProcessParameter>{ param: { PROCESS_NUMBER: concatProcessNumbers } };
            sessionStorage.setItem("app.operational.newProcess.list", JSON.stringify(sessionParameter));
            window.location.reload();
        }
    }

    private checkDateValidity(initialDate: Date, finalDate: Date, index: number): void {
        try {
            let isValid = false;
            if (!initialDate || typeof initialDate == "string") return;
            if (!finalDate || typeof finalDate == "string") return;
            isValid = ValidateUtil.isValidDateRange(initialDate, finalDate);
            if (!isValid) {
                this.scope.model.CARGO_TAB.OPTION[index].SHIPMENT_REQUIRED.DATE_UP = null;
            }
            this.updateLoadRef(this.scope.model.CARGO_TAB.OPTION[index].SHIPMENT_REQUIRED.DATE_OF, index);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private updateLoadRef(shipmentDateOf: Date, index: number): void {
        try {
            this.scope.model.CARGO_TAB.OPTION[index].LOAD_REF = shipmentDateOf;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getProductConfiguration(): Promise<void> {
        try {

            const resultOperation = await this.ProductService.post({ route: `/productConfiguration/` });
            if (resultOperation && resultOperation.data && resultOperation.data.data) {
                const productConfiguration: IProductConfiguration = resultOperation.data.data;
                const voyageByIntegration: boolean = productConfiguration.GET_VOYAGE_BY_INTEGRATION;
                this.scope.getVoyageByIntegration = voyageByIntegration;
                if (voyageByIntegration) {
                    this.scope.providerListForInttraVoyage = await this.getProviderScacForVoyage();
                    this.scope.externalShipowners = await this.getCodeIntegrationForShipowners();
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getProviderScacForVoyage(): Promise<ISelectorModel[]> {
        try {
            let result: ISelectorModel[] = [];
            const productReq = {
                route: `/provider/list/custom/operational`,
                data: { products: [this.scope.model.OFFER_COMPILED.PRODUCT.ID], sysConvertIdToString: true, types: [EProviderTypeId.SHIPOWNER] }
            }

            const providers = await this.ProductService.post<any>(productReq);
            if (providers.data.data) {
                providers.data.data.forEach(x => {
                    if (x.USED_OCEAN_SCHEDULE) {
                        const item: ISelectorModel = { ID: x.ID, NAME: x.TRADING_NAME, CODE: x.SCAC };
                        result.push(item);
                    }
                });
            }
            return result;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    public async applyTransportModeToEventFromInttra(maritimeStopoverIndex: number, stopover: IMaritimeStopover, oceanScheduleOptions: IInttraOceanSchedulesResult[]): Promise<void> {
        try {
            if (stopover && stopover.VESSEL) {
                const selectedOption = oceanScheduleOptions && oceanScheduleOptions.length > 0 ? oceanScheduleOptions.find(x => x['imoNumber'] == stopover.VESSEL.CODE) : null;

                stopover.FORECAST_DATE = new Date(selectedOption.originDepartureDate),
                    stopover.STOPOVER = {
                        ID: null,
                        NAME: null,
                        CODE: selectedOption.originUnloc
                    },

                    this.addStopover(stopover, maritimeStopoverIndex);

                const providerFromExternal = await this.externalService.post({ route: "/ediExternalCodeSetup/externalToShipowner", data: { code: selectedOption.scac } });
                const shipOwner = providerFromExternal && providerFromExternal.data && providerFromExternal.data.data ? providerFromExternal.data.data : null;

                if (shipOwner) {
                    const eventIndex = this.scope.model.OFFER_COMPILED.EVENT.findIndex(event => event.EVENT_TYPE.ID === EEventType.LOAD);
                    this.scope.model.OFFER_COMPILED.EVENT[eventIndex].EVENT_SERVICE_PROVIDER = {
                        ID: shipOwner.CODE_INTEGRATION.CODE_INTERNAL.ID,
                        NAME: this.scope.model.OFFER_COMPILED.EVENT[eventIndex].EVENT_SERVICE_PROVIDER.NAME,
                        ID_LEGAL_PERSON: null,
                        TYPE: this.scope.model.OFFER_COMPILED.EVENT[eventIndex].EVENT_SERVICE_PROVIDER.TYPE,
                        ADDRESS: null,
                        CORPORATE_NAME: null,
                        ID_PHYSICAL_PERSON: null,
                        NETWORK: null,
                        NUMBER_IATA: null,
                        SCAC: shipOwner.CODE_INTERNAL.NAME,
                        TAXPAYER_NUMBER: null,
                        TRADING_NAME: null
                    };
                }
            }

        } catch (ex) {
            throw ex;
        }
    }

    private async getCodeIntegrationForShipowners(): Promise<ISelectorModel[]> {
        try {
            let result: ISelectorModel[] = [];

            const providerFromExternal = await this.externalService.post({ route: "/ediExternalCodeSetup/list/byProviderType", data: { integrationId: EEdiExternalCodeSetupInttra.INTTRA_INTEGRATION_ID, typeId: EEdiExternalCodeSetupInttra.SHIPOWNER_TYPE_ID } });
            if (providerFromExternal && providerFromExternal.data && providerFromExternal.data.data) {
                for (const item of providerFromExternal.data.data.CODE_INTEGRATION) {
                    const itemOfResult: ISelectorModel = {
                        ID: item.CODE_INTERNAL[0].ID,
                        NAME: item.CODE_INTERNAL[0].NAME,
                        CODE: item.CODE_EXTERNAL
                    };

                    result.push(itemOfResult);
                }
            }

            return result;
        } catch (ex) {
            this.handleError(ex);
        }
    }
}

import * as moment from 'moment';
import { SelectorModel } from '../model/SelectorModel';

export enum EPeriodTime {
    TODAY = "1",
    YESTERDAY = "2",
    CURRENT_WEEK = "3",
    LAST_WEEK = "4",
    LAST_TWO_WEEKS = "5",
    CURRENT_MONTH = "6",
    LAST_MONTH = "7",
    CURRENT_YEAR = "8",
    LAST_YEAR = "9"
}

export abstract class DateUtil {

    public static getDateValuePeriod = (): SelectorModel[] => {
        return [
            {
                ID: EPeriodTime.TODAY,
                NAME: 'Hoje'
            },
            {
                ID: EPeriodTime.YESTERDAY,
                NAME: 'Ontem'
            },
            {
                ID: EPeriodTime.CURRENT_WEEK,
                NAME: 'Esta semana'
            },
            {
                ID: EPeriodTime.LAST_WEEK,
                NAME: 'Semana anterior'
            },
            {
                ID: EPeriodTime.LAST_TWO_WEEKS,
                NAME: 'Últimas duas semana'
            },
            {
                ID: EPeriodTime.CURRENT_MONTH,
                NAME: 'Este mês'
            },
            {
                ID: EPeriodTime.LAST_MONTH,
                NAME: 'Mês passado'
            },
            {
                ID: EPeriodTime.CURRENT_YEAR,
                NAME: 'Este ano'
            },
            {
                ID: EPeriodTime.LAST_YEAR,
                NAME: 'Ano passado'
            },
        ]
    }

    public static getDateFunctionPeriod = (date: Date, selectedPeriod: SelectorModel) => {
        if (!date || !selectedPeriod) return { startDate: null, endDate: null };

        switch (selectedPeriod.ID) {
            case EPeriodTime.TODAY:
                return {
                    startDate: moment(date).startOf('day').toDate(),
                    endDate: moment(date).endOf('day').toDate()
                }

            case EPeriodTime.YESTERDAY:
                return {
                    startDate: moment(date).subtract(1, 'day').startOf('day').toDate(),
                    endDate: moment(date).endOf('date').toDate()
                }

            case EPeriodTime.CURRENT_WEEK:
                return {
                    startDate: moment(date).startOf('week').toDate(),
                    endDate: moment(date).endOf('week').toDate()
                }

            case EPeriodTime.LAST_WEEK:
                return {
                    startDate: moment(date).subtract(1, 'weeks').startOf('week').toDate(),
                    endDate: moment(date).subtract(1, 'weeks').endOf('week').toDate(),
                }

            case EPeriodTime.LAST_TWO_WEEKS:
                return {
                    startDate: moment(date).subtract(2, 'weeks').startOf('week').toDate(),
                    endDate: moment(date).subtract(2, 'weeks').endOf('week').toDate(),
                }

            case EPeriodTime.CURRENT_MONTH:
                return {
                    startDate: moment(date).startOf('month').toDate(),
                    endDate: moment(date).endOf('month').toDate(),
                }

            case EPeriodTime.LAST_MONTH:
                return {
                    startDate: moment(date).subtract(1, 'months').startOf('month').toDate(),
                    endDate: moment(date).subtract(1, 'months').endOf('month').toDate(),
                }

            case EPeriodTime.CURRENT_YEAR:
                return {
                    startDate: moment(date).startOf('year').toDate(),
                    endDate: moment(date).endOf('year').toDate(),
                }

            case EPeriodTime.LAST_YEAR:
                return {
                    startDate: moment(date).subtract(1, 'years').startOf('year').toDate(),
                    endDate: moment(date).subtract(1, 'years').endOf('year').toDate(),
                }
        }
    };

    public static dateIsBetween(date: Date, startDate: Date, endDate: Date): boolean {
        try {
            if (!date) return false;
            if (!startDate) return false;
            if (!endDate) return false;

            const dateToCompare = moment(date).set({
                hour: 12,
                minute: 0,
                second: 0,
                millisecond: 0,
            });
            const start = moment(startDate).startOf('date');
            const end = moment(endDate).endOf('date');

            return dateToCompare.isBetween(start, end);
        } catch (ex) {
            throw ex;
        }
    }

    public static dateIsAfter(date: Date, compareDate: Date): boolean {
        try {
            if (!date) return false;
            if (!compareDate) return false;

            return moment(date).isAfter(compareDate);
        } catch (ex) {
            throw ex;
        }
    }

    public static dateIsBefore(date: Date, compareDate: Date): boolean {
        try {
            if (!date) return false;
            if (!compareDate) return false;

            return moment(date).isBefore(compareDate);
        } catch (ex) {
            throw ex;
        }
    }

    public static dateIsSame(date: Date, compareDate: Date): boolean {
        try {
            if (!date) return false;
            if (!compareDate) return false;

            return moment(date).isSame(compareDate);
        } catch (ex) {
            throw ex;
        }
    }

    public static formatDate(date: Date = new Date(), format: string = "lll"): string {
        try {
            return moment(date).format(format);
        } catch (ex) {
            throw ex;
        }
    }

    public static addDays(date: Date, quantity: number): Date {
        try {
            return moment(date).add(quantity, 'days').toDate();
        } catch (ex) {
            throw ex;
        }
    }

    public static substractDays(date: Date, quantity: number): Date {
        try {
            return moment(date).subtract(quantity, 'days').toDate();
        } catch (ex) {
            throw ex;
        }
    }

    public static isValidDate(date: Date): boolean {
        try {
            if (!date) return false;

            return moment(date).isValid();
        } catch (ex) {
            throw ex;
        }
    }
}

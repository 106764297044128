import { FormService2, IFormServiceScope } from "@services/FormService2";
import { IMonacoRequest } from '@services/GridFormService';
import { IModalOptions } from '@services/ModalService';
import { IRestService } from '@services/RestService';
import { ProductService } from "@services/ProductService";
import { OperationalService } from "@services/OperationalService";
import { EProductId, EProviderTypeId, ETypeLocalId, EOperation } from '@enums/GenericData';
import { IAirFlight, IAirEstimated } from 'WBA-Model/dist/interface/operational/AirFlight';
import { ISelectorModel, SelectorModel } from '@models/mongo/SelectorModel';
import { SSEService } from "@appServices/SSEService";
import { ISessionService } from "@services/SessionService";
import moment = require('moment');
import * as angular from 'angular';
import { HelperService } from "@services/HelperService";


interface IBaseAirFlightEditScope extends IFormServiceScope {
    model: IAirFlight;
    oldModel: IAirFlight;
    flightCode?: string;
    flightId?: string;
    modalOptions: IModalOptions;
    productList: ISelectorModel[];
    scheduleFrequency: ISelectorModel[];
    daysList: ISelectorModel[];
    airLineList: ISelectorModel[];
    routingPointList: ISelectorModel[];
    flightDuration: string;
    estimatedTimes: IAirEstimated;
    estimatedEtdString: string;
    estimatedEtaString: string;
    sessionService: ISessionService;
    user: string;
    getAirLineListByName(airLine: string): Promise<void>;
    getRoutingPointListByName(search: string): Promise<void>;
    updateSchedulingType(): void;
    checkFlightDuration(): void;
    save(): void;
    closeBaseModal(): void;
}

export class BaseAirFlightEditController extends FormService2 {

    static $inject: string[] = ['$injector', '$scope'];
    private scope: IBaseAirFlightEditScope;
    private $RestService: IRestService;
    private $q: ng.IQService;
    private ProductService: ProductService;
    private OperationalService: OperationalService;
    private SSEService: SSEService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IBaseAirFlightEditScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.$RestService = $injector.get('RestService');
        this.$q = $injector.get('$q');
        this.ProductService = $injector.get('ProductService');
        this.OperationalService = $injector.get('OperationalService');
        this.scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope, this);
        this.helperService = $injector.get('HelperService');
    }


    async $onInit(): Promise<void> {
        try {
            this.block();
            this.init("baseAirFlightWizardModalForm", null, null);
            this.initScopeFunctions();
            await this.initDependencies();
            await this.initModel();
            if (this.scope.flightCode || this.scope.flightId) await this.getBaseFlightInformation();
            if (this.scope.operation == EOperation.EDIT) this.checkSSE();
            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private checkSSE(): void {

        if (!this.scope.user) throw new Error('Can not open specificFlightEdit modal without user.');

        let blockedObject = {
            ID: this.scope.model.ID,
            NAME: this.scope.model.FLIGHT_CODE,
            EMAIL: this.scope.user,
            FORM_NAME: "MODAL_BASE_AIR_FLIGHT"
        };
        this.SSEService.closeEvents();
        this.SSEService.setBlockedObject(blockedObject);
        this.SSEService.initEvents();
        this.SSEService.events.onmessage = async (event) => {
            const parsedData = JSON.parse(event.data);
            if (!parsedData.status && this.scope.operation == EOperation.EDIT) {
                const result = await this.SSEService.generate(parsedData);
                if (result && !result.status) {
                    this.scope.modalOptions.ok(false);
                }
                this.scope.operation = EOperation.VIEW;
                this.scope.$applyAsync();
            }
        };
    }

    private initScopeFunctions(): void {
        this.scope.getAirLineListByName = async (search: string) => {
            let airLineList: ISelectorModel[] = [];
            if (search && search.length >= 3) airLineList = await this.getAirLineListByName(search);
            this.scope.airLineList = airLineList;
        }

        this.scope.getRoutingPointListByName = async (search: string) => {
            this.scope.routingPointList = await this.getRoutingPointListByName(search);
        }

        this.scope.updateSchedulingType = () => {
            if (this.scope.model.FREQUENCY) {
                this.scope.model.DAY = null;
                this.setSchedulingDays(this.scope.model.FREQUENCY.ID);
            }
        };

        this.scope.checkFlightDuration = () => {
            this.checkFlightDuration();
        }

        this.scope.save = () => {
            this.save();
        }

        this.scope.closeBaseModal = () => {
            this.SSEService.closeEvents();
            this.scope.modalOptions.ok(false);
        }
    }

    async initModel(): Promise<void> {
        this.scope.model = {
            _id: null,
            ID: null,
            FLIGHT_CODE: null,
            PRODUCT: null,
            FLIGHT_CONCATENATED: null,
            PROVIDER: null,
            PREFIX: null,
            FLIGHT: null,
            ORIGIN: null,
            DESTINATION: null,
            FREQUENCY: null,
            DAY: null,
            ETD_HOUR: null,
            ETA_HOUR: null,
            FLIGHT_STATUS: null,
            IS_SPECIFIC: null,
            SPECIFIC: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null
        }

        this.scope.estimatedTimes = {
            ETD_HOUR: null,
            ETD_MINUTE: null,
            ETA_HOUR: null,
            ETA_MINUTE: null
        }

        this.scope.daysList = [];
    }

    private async save(): Promise<void> {
        try {
            this.block();

            // Set hour and minute for ETD.
            const dateEtd = moment(this.scope.model.ETD_HOUR);
            dateEtd.set({ hour: this.scope.estimatedTimes.ETD_HOUR, minute: this.scope.estimatedTimes.ETD_MINUTE });
            this.scope.model.ETD_HOUR = dateEtd.toDate();

            // Set hour and minute for ETA.
            const dateEta = moment(this.scope.model.ETD_HOUR);
            dateEta.set({ hour: this.scope.estimatedTimes.ETA_HOUR, minute: this.scope.estimatedTimes.ETA_MINUTE });
            this.scope.model.ETA_HOUR = dateEta.toDate();

            const etdHour: string = this.scope.estimatedTimes.ETD_HOUR < 10 ? '0' + this.scope.estimatedTimes.ETD_HOUR : this.scope.estimatedTimes.ETD_HOUR.toString();
            const etdMinute: string = this.scope.estimatedTimes.ETD_MINUTE < 10 ? '0' + this.scope.estimatedTimes.ETD_MINUTE : this.scope.estimatedTimes.ETD_MINUTE.toString();
            this.scope.estimatedEtdString = etdHour + ':' + etdMinute;

            const etaHour: string = this.scope.estimatedTimes.ETA_HOUR < 10 ? '0' + this.scope.estimatedTimes.ETA_HOUR : this.scope.estimatedTimes.ETA_HOUR.toString();
            const etaMinute: string = this.scope.estimatedTimes.ETA_MINUTE < 10 ? '0' + this.scope.estimatedTimes.ETA_MINUTE : this.scope.estimatedTimes.ETA_MINUTE.toString();
            this.scope.estimatedEtaString = etaHour + ':' + etaMinute;

            this.scope.model.FLIGHT_CONCATENATED = this.generateFlightConcatenated();

            const registerBaseAirFlight = await this.OperationalService.post('/airFlight/update', { data: this.scope.model, oldData: this.scope.oldModel });
            if (registerBaseAirFlight && registerBaseAirFlight.status == 200 && registerBaseAirFlight.data && registerBaseAirFlight.data.data) {
                this.SSEService.closeEvents();
                this.scope.modalOptions.ok(this.scope.model);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return;
        }
    }

    private async getGenericList(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    async initDependencies(): Promise<any> {
        try {
            const self: BaseAirFlightEditController = this;
            return new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getGenericList("product"),
                    self.getGenericList('data_scheduling_types'),
                ]).then((result: any) => {
                    self.scope.productList = result[0] && result[0].length ? result[0].filter(data => data.ID == EProductId.AIR_EXPORT || data.ID == EProductId.AIR_IMPORT) : [];
                    self.scope.scheduleFrequency = result[1] && result[1].length ? result[1].map(obj => { return { ID: obj.ID, NAME: obj.NAME } }) : [];
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getBaseFlightInformation(): Promise<IAirFlight> {
        let result: IAirFlight = null;
        try {
            this.block();
            const baseAirFlight = await this.OperationalService.post('/airFlight/byFlightIdOrCode', { flightCode: this.scope.flightCode, id: this.scope.flightId });
            if (baseAirFlight && baseAirFlight.data && baseAirFlight.data.data && baseAirFlight.data.data[0]) {
                const foundBaseAirFlight = baseAirFlight.data.data[0];
                this.scope.model = {
                    _id: foundBaseAirFlight._id,
                    ID: foundBaseAirFlight.ID,
                    FLIGHT_CODE: foundBaseAirFlight.FLIGHT_CODE,
                    PRODUCT: foundBaseAirFlight.PRODUCT,
                    FLIGHT_CONCATENATED: foundBaseAirFlight.FLIGHT_CONCATENATED,
                    PROVIDER: foundBaseAirFlight.PROVIDER,
                    PREFIX: foundBaseAirFlight.PREFIX,
                    FLIGHT: foundBaseAirFlight.FLIGHT,
                    ORIGIN: foundBaseAirFlight.ORIGIN,
                    DESTINATION: foundBaseAirFlight.DESTINATION,
                    FREQUENCY: foundBaseAirFlight.FREQUENCY,
                    DAY: foundBaseAirFlight.DAY,
                    ETD_HOUR: foundBaseAirFlight.ETD_HOUR,
                    ETA_HOUR: foundBaseAirFlight.ETA_HOUR,
                    FLIGHT_STATUS: foundBaseAirFlight.FLIGHT_STATUS,
                    IS_SPECIFIC: foundBaseAirFlight.IS_SPECIFIC,
                    SPECIFIC: foundBaseAirFlight.SPECIFIC,
                    CREATED_AT: foundBaseAirFlight.CREATED_AT,
                    CREATED_BY: foundBaseAirFlight.CREATED_BY,
                    UPDATED_AT: foundBaseAirFlight.UPDATED_AT,
                    UPDATED_BY: foundBaseAirFlight.UPDATED_BY
                }

                this.scope.oldModel = angular.copy(this.scope.model);

                var etdDate = moment(foundBaseAirFlight.ETD_HOUR);
                var etaDate = moment(foundBaseAirFlight.ETA_HOUR);
                this.scope.estimatedTimes = {
                    ETD_HOUR: etdDate.hour(),
                    ETD_MINUTE: etdDate.minutes(),
                    ETA_HOUR: etaDate.hour(),
                    ETA_MINUTE: etaDate.minutes()
                }
                this.setSchedulingDays(this.scope.model.FREQUENCY.ID);
                this.checkFlightDuration();
            }

        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getAirLineListByName(airLine: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            if (airLine && airLine.length >= 2) {
                this.block();
                const request: IMonacoRequest = {
                    data: {
                        search: airLine,
                        types: [EProviderTypeId.AIRLINE]
                    },
                    route: `/provider/list/custom/operational`,
                    timeout: 120000,
                }
                const rc = await this.ProductService.post(request);

                if (rc && rc.data && rc.data.data && rc.status == 200) {
                    result = rc.data.data.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.SCAC } });
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getRoutingPointListByName(search?: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            this.block();
            const routingPoints = await this.ProductService.post({ route: '/routingPoint/list/custom', data: { name: search, types: [ETypeLocalId.AIRPORT] }, timeout: 30000 });
            if (routingPoints && routingPoints.data && routingPoints.data.data && routingPoints.data.data.data) result = routingPoints.data.data.data.map(routingPoint => { return { ID: routingPoint.ID, NAME: routingPoint.NAME, CODE: routingPoint.CODE, TYPE: routingPoint.TYPE.NAME } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private setSchedulingDays(type: string): void {
        if (!type || type === '1') return;

        if (this.scope.daysList) this.scope.daysList = [];
        if (type === '2') {
            for (let i = 1; i <= 7; i++) { // weekdays
                this.scope.daysList.push({ ID: i.toString(), NAME: moment.weekdays(i), CODE: i.toString() });
            }
        } else if (type === '3') {
            for (let i = 1; i <= 31; i++) { // month Days
                this.scope.daysList.push({ ID: i.toString(), NAME: i.toString(), CODE: i.toString() });
            }
        }
    }

    private checkFlightDuration(): void {
        if (!this.scope.estimatedTimes) return;
        if (!this.scope.estimatedTimes.ETA_HOUR || !this.scope.estimatedTimes.ETD_HOUR || this.scope.estimatedTimes.ETA_MINUTE == null || this.scope.estimatedTimes.ETD_MINUTE == null) return;

        let hourDuration = this.scope.estimatedTimes.ETA_HOUR - this.scope.estimatedTimes.ETD_HOUR;
        if (hourDuration < 0) {
            hourDuration += 24;
        }

        let minuteDuration = this.scope.estimatedTimes.ETA_MINUTE - this.scope.estimatedTimes.ETD_MINUTE;
        if (minuteDuration < 0) {
            minuteDuration += 60;
            hourDuration--;
        }

        let minuteDurationString = minuteDuration.toString();
        if (minuteDuration < 10) {
            minuteDurationString = minuteDurationString.padStart(2, '0');
        }
        this.scope.flightDuration = hourDuration + ":" + minuteDurationString;
    }

    private generateFlightConcatenated(): string {
        let concatenated = '';

        if (this.scope.model.FLIGHT_CODE) concatenated += this.scope.model.FLIGHT_CODE + ' | ';
        if (this.scope.model.PRODUCT) concatenated += this.scope.model.PRODUCT.ID + ' | ';
        if (this.scope.model.PROVIDER) concatenated += this.scope.model.PROVIDER.CODE + ' | ';
        if (this.scope.model.ORIGIN) concatenated += this.scope.model.ORIGIN.CODE + ' | ';
        if (this.scope.model.DESTINATION) concatenated += this.scope.model.DESTINATION.CODE + ' | ';
        if (this.scope.model.DAY) concatenated += this.scope.model.DAY.map(days => { return days.NAME }) + ' | ';
        if (this.scope.estimatedEtdString) concatenated += this.scope.estimatedEtdString + ' | ';
        if (this.scope.estimatedEtaString) concatenated += this.scope.estimatedEtaString + ' | ';
        concatenated = concatenated.slice(0, -3);
        return concatenated;
    }
}
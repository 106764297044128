selectNgFiles.$inject = ['$compile', '$injector', 'SessionService', '$rootScope']
export function selectNgFiles($compile, $injector, SessionService, $rootScope) {
    return {
        require: "ngModel",
        link: function postLink(scope, elem, attrs, ngModel) {
            elem.on("change", function (e) {
                var files = elem[0]['files'];
                ngModel.$setViewValue(files);
            })
        }
    }
}

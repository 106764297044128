import '../../../libs/pdf.min.js';
import { IGridFormServiceScope } from "@services/GridFormService";
import { IMonacoScope } from '../../common/MonacoInterface.js';
import { IStateParamsService } from 'angular-ui-router';
import { HandleError } from '../../common/util/HandleError';
import { IDownloadFilesGenericService, IFileDownload } from '@services/DownloadFilesGenericService';

export interface IErrorStorageDownloadFilesGeneric {
    status: string;
    message: string;
    reason: string;
}

interface IStorageDownloadFilesGenericScope extends IGridFormServiceScope {
    file: IFileDownload;
    type: string;
    pdfName: string;
    pdfUrl: string;
    onError(error: IErrorStorageDownloadFilesGeneric): void;
    onLoad(): void;
    getNavStyle(scroll: number): string;
    download(): void;
    print(): void;
}

export class StorageDownloadFilesGenericController implements ng.IController {
    static $inject: string[] = ['$injector', '$rootScope', '$scope', '$stateParams', 'ErrorService', 'blockUI', '$window', '$templateCache', 'DownloadFilesGenericService'];
    private injector: ng.Injectable<any>;
    private $rootScope: IMonacoScope;
    private $scope: IStorageDownloadFilesGenericScope;
    private $stateParams: IStateParamsService;
    private $window: ng.IWindowService;
    private blockUI: ng.blockUI.BlockUIService
    private $templateCache: ng.ITemplateCacheService;
    private downloadService: IDownloadFilesGenericService;

    constructor($injector: ng.Injectable<any>, $scope: IStorageDownloadFilesGenericScope) {
        this.injector = $injector;
        this.$rootScope = this.injector.get('$rootScope');
        this.$scope = $scope;
        this.$stateParams = this.injector.get('$stateParams');
        this.$window = this.injector.get('$window');
        this.blockUI = this.injector.get('blockUI');
        this.downloadService = this.injector.get('DownloadFilesGenericService');
        this.$templateCache = this.injector.get('$templateCache');

        this.$templateCache.put('pdfViewerMenuTemplate', require('../view/storagePDFViewerMenu.html'));
    }

    async $onInit(): Promise<void> {
        try {
            this.initScopeFunctions();
            this.sendToDownload();
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    $onDestroy(): void { }

    initScopeFunctions(): void {
        this.$scope.onLoad = (): void => {
            this.blockUI.stop();
        }
        this.$scope.getNavStyle = (scroll: number): string => {
            if (scroll > 100) return 'pdf-controls fixed';
            return 'pdf-controls';
        }

        this.$scope.onError = (error: IErrorStorageDownloadFilesGeneric): void => {
            HandleError.exception(error);
        }
        this.$scope.download = (): void => {
            this.downloadFile();
        }

        this.$scope.print = (): void => {
            const w = this.$window.open(this.$scope.file.url, '_blank');
            w.focus();
            w.print();
        }
    }

    private async sendToDownload(): Promise<void> {
        try {
            if (!this.$stateParams.hash) {
                HandleError.exception('The request contains Null Parameters');
            }

            this.blockUI.start({ message: 'Fazendo download do arquivo' });

            const hash = this.$stateParams.hash;
            this.$scope.file = null;
            this.$scope.type = null;

            this.$scope.file = <IFileDownload>await this.downloadService.previewFileFromGoogleStorage(hash);

            if (this.$scope.file) {
                if (this.$scope.file.type.indexOf('image') === 0) {
                    this.$scope.type = 'image';
                    this.blockUI.stop();
                    this.previewImage();
                } else if (this.$scope.file.type === 'application/pdf') {
                    this.$scope.type = 'pdf';
                    this.previewPdf();
                } else {
                    this.$scope.type = 'other';
                    this.blockUI.stop();
                    this.downloadFile();
                }
            }
        } catch (ex) {
            HandleError.exception(ex);
        } finally {
            this.blockUI.stop();
        }
    }

    private previewImage(): void {
        this.$scope.$apply();
    }

    private previewPdf(): void {
        this.$scope.pdfName = this.$scope.file.name;
        this.$scope.pdfUrl = this.$scope.file.url;

        this.$scope.$apply();
    }

    private downloadFile(): void {
        if (this.$rootScope.isIE) {
            this.$window.navigator.msSaveOrOpenBlob(this.$scope.file.blob, this.$scope.file.name);
        } else {
            let link = document.createElement('a');
            link.href = this.$scope.file.url;
            link.download = this.$scope.file.name;
            link.click();
        }
    }
}

import * as angular from 'angular';

import "../../css/fab.css";
import 'ng-img-crop/compile/minified/ng-img-crop.css';
import 'ng-img-crop/compile/minified/ng-img-crop.js';
import 'ng-csv';

import initRoute from './RouteConfig';
import { GRID_FORM_MODULE } from '../gridForm/GridFormModule';
import { GRID_EXCEL_MODULE } from '../gridExcel/GridExcelModule';

//import { GridFactory } from './factory/GridFactory';
import { ReportDataRegisterController } from './controller/ReportDataRegisterController'
import { ReportMenuController } from './controller/ReportMenuController';

const REPORT_MODULE = angular
    .module('report', ['ngImgCrop', GRID_FORM_MODULE.name, GRID_EXCEL_MODULE.name, 'ngCsv'])

    //.factory('GridFactory', GridFactory)
    .controller('ReportMenuController', ReportMenuController)
    .controller('ReportDataRegisterController', ReportDataRegisterController)

initRoute(REPORT_MODULE.name);

export { REPORT_MODULE };

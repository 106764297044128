import angular = require('angular');
import moment = require('moment');
import { FormService2 } from '@services/FormService2';
import { IModalService } from '@services/ModalService';
import { ISessionService } from '@services/SessionService';
import { ExternalService } from '@services/ExternalService';
import { IMonacoRequest } from '@services/GridFormService';
import { OperationalService } from '@services/OperationalService';
import { IViewLog } from "@models/interface/common/IViewLog";
import { INewProcessCommunicationModel } from "@models/interface/operational/NewProcessTabsModel";
import { FollowUpProcessModel } from "@models/interface/operational/FollowUpProcessModel";
import { INSTRUCTION } from '@models/interface/operational/NewProcess';
import { INewProcessScope, ECollapseState } from './NewProcessController';
import { INotificationMonitorParameterProcess } from '../../common/model/ModelParameter';
import { EOperation } from '@enums/GenericData';

enum IProcessInstructionType {
    PRODUCT = "product",
    COMMERCIAL = "commercial",
    OPERATIONAL = 'operational',
    FINANCIAL = 'financial',
    CUSTOMER = 'customer',
    AGENT = 'agent',
    HOUSE = 'house',
    MASTER = 'master'
}

interface ICommunicationInstructionModalScope extends ng.IScope {
    instructions: INSTRUCTION;
    operation: string;
    oldInstructions: INSTRUCTION;
    applyInstructionsModal: (close?: boolean) => void;
    closeInstructionsModal: () => Promise<void>;
    addInternalTag: (data: string, type: IProcessInstructionType) => void;
    deleteInternalTag: (data: string, type: IProcessInstructionType) => void;
    cleanInstructionAll: (type: IProcessInstructionType) => void;
}

interface INewProcessCommunicationScope extends ng.IScope {
    log: IViewLog;
    model: INewProcessCommunicationModel;
    notificationMonitorList: FollowUpProcessModel[];
    processNumber: string;
    collapseCommunication: () => void;
    hasChanges: () => boolean;
    goToNotification: (followUp: FollowUpProcessModel, operation: string) => void;
    
    // instructions
    updatedInstructions: boolean;
    showInstructions: () => void;
}

export class NewProcessCommunicationController {
    static $inject: string[] = ['$scope'];
    private $scope: INewProcessCommunicationScope;
    private $newProcessScope: INewProcessScope;
    private formService: FormService2;
    private modalService: IModalService;
    private sessionService: ISessionService;
    private externalService: ExternalService;
    private operationalService: OperationalService;
    private instructionsModalID: number;

    constructor($scope: INewProcessCommunicationScope) {
        this.$scope = $scope;
        this.$newProcessScope = <INewProcessScope>$scope.$parent.$parent;
        this.formService = this.$newProcessScope.formService;
        this.modalService = this.$newProcessScope.modalService;
        this.sessionService = this.$newProcessScope.sessionService;
        this.externalService = this.$newProcessScope.externalService;
        this.operationalService = this.$newProcessScope.operationalService;
        this.initScopeFunctions();
    }

    async $onInit(): Promise<void> {
        try {
            this.initModel();
            await this.initDependencies();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initModel(): void {
        if (this.$newProcessScope.model) {
            this.$scope.model = {
                
            };
        } else {
            this.$scope.model = {
                
            };
        }
    }

    private initScopeFunctions(): void {

        this.$scope.collapseCommunication = (): void => {
            this.collapseCommunication();
        }

        this.$scope.goToNotification = async (followUp: FollowUpProcessModel, operation: string) => {
            const processNumber = (followUp) ? followUp.PROCESS.PROCESS_NUMBER : this.$scope.processNumber;
            const customer = (followUp) ? '' : { ID: this.$newProcessScope.model.CUSTOMER.ID, NAME: this.$newProcessScope.model.CUSTOMER.NAME, CODE: this.$newProcessScope.model.CUSTOMER.CODE }

            this.sessionService.openTab("app.management.notificationMonitor", <INotificationMonitorParameterProcess>{
                "FOLLOWUPPROCESS_NUMBER": followUp.FOLLOWUPPROCESS_NUMBER, "PROCESS.PROCESS_NUMBER": processNumber,
            }, { operation: operation, process: processNumber, customer: customer });
        }

        //instructions
        this.$scope.showInstructions = () => {
            this.showInstructions();
        }

    }

    async initDependencies(): Promise<any> {
        this.initCollapseEvents();

        this.$scope.$watch(() => this.$newProcessScope.model.PROCESS_NUMBER, () => {
            this.$scope.processNumber = this.$newProcessScope.model.PROCESS_NUMBER;
        });
    }

    private initCollapseEvents() {
        this.$scope.$on('processCommunicationCollapse', () => {
            this.collapseCommunication();
        });

        const collapseCommunication = angular.element('#collapseCommunication');
        if (collapseCommunication) {
            collapseCommunication.on('shown.bs.collapse', (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    this.initModel();
                    this.getFollowUpByProcess();
                    angular.element("#collapseCommunicationHeading").attr('aria-expanded', 'true');
                    // update collapse state
                    this.$newProcessScope.collapseState = { panel: ECollapseState.COMMUNICATION, released: false, nextState: null };
                    this.$newProcessScope.repositionPanels(ECollapseState.COMMUNICATION, true);
                    this.$newProcessScope.disableElements(this.$newProcessScope.operation == EOperation.VIEW);
                }
            });
            collapseCommunication.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseCommunicationHeading").attr('aria-expanded', 'false');
                }
            });
        }
    }

    private async collapseCommunication() {
        try {
            if (this.$newProcessScope.collapseState.released || this.$newProcessScope.collapseState.panel == ECollapseState.COMMUNICATION) {
                const collapseCommunication = angular.element("#collapseCommunication");
                if (collapseCommunication) {
                    const isCollapsed = angular.element("#collapseCommunicationHeading").attr("aria-expanded") == "true";
                    if (isCollapsed) {
                        this.$newProcessScope.collapseState.released = true;
                    }
                    collapseCommunication['collapse']('toggle');
                    if (isCollapsed) this.$newProcessScope.repositionPanels(ECollapseState.COMMUNICATION);
                }
            } else {
                this.$newProcessScope.collapseState.nextState = ECollapseState.COMMUNICATION;
                this.$newProcessScope.releaseCollapse();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getFollowUpByProcess(): Promise<void> {
        let result = [];
        try {
            this.formService.block();

            const externalReq: IMonacoRequest = {
                route: `/notificationMonitor/process/${this.$scope.processNumber}/list`,
                data: {},
                timeout: 120000
            }
            const notificationMonitorList = await this.externalService.get<any>(externalReq);
            if (notificationMonitorList && notificationMonitorList.data && notificationMonitorList.data.data) {
                const _result: FollowUpProcessModel[] = notificationMonitorList.data.data.data;
                result = _result.sort(function (a, b) {
                    if (a.RECIPIENT_TYPE.NAME > b.RECIPIENT_TYPE.NAME) return 1;
                    if (a.RECIPIENT_TYPE.NAME < b.RECIPIENT_TYPE.NAME) return -1;
                    if (a.INSERT_DATE < b.INSERT_DATE) return 1;
                    if (a.INSERT_DATE > b.INSERT_DATE) return -1;
                })
            }
            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.notificationMonitorList = result;
        }
    }

    private async showInstructions() {
        try {
            const instructions = await this.getProcessTabsInstruction();
            this.instructionsModalID = this.modalService.newModal();
            this.modalService.showModalInfo(
                {
                    modalID: this.instructionsModalID,
                    scope: this.$scope,
                    formService: this.$newProcessScope.operation,
                    template: require('../view/NewProcessInstructionsModal.html'),
                    size: 'vlg modal-overflow',
                    keyboard: true,
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing") {
                            if (reason.toString() == "escape key press") event.preventDefault();
                            if (!closed) {
                                const modalScope: ICommunicationInstructionModalScope = await this.modalService.getModalScope(this.instructionsModalID);
                                await modalScope.closeInstructionsModal();
                            }
                        }
                    }
                },
                {
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: 'GENERAL.INSTRUCTIONS'
                }
            );
            await this.buildCommunicationInstructionModalScope(instructions);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getProcessTabsInstruction(): Promise<INSTRUCTION> {
        let instruction: INSTRUCTION = null;
        this.formService.block();
        try {
            const mainInstruction = await this.operationalService.get(`/process/panel/instruction/${this.$scope.processNumber}`, 30000);
            if (mainInstruction && mainInstruction.data && mainInstruction.data.data) instruction = mainInstruction.data.data.INSTRUCTION;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return instruction;
        }
    }

    private async buildCommunicationInstructionModalScope(instructions: INSTRUCTION): Promise<ICommunicationInstructionModalScope> {
        const modalScope: ICommunicationInstructionModalScope = await this.modalService.getModalScope(this.instructionsModalID);

        modalScope.operation = this.$newProcessScope.operation;
        modalScope.instructions = angular.copy(instructions);
        modalScope.oldInstructions = angular.copy(instructions);

        modalScope.applyInstructionsModal = (close?: boolean) => {
            this.applyInstructionsModal(modalScope.instructions, close);
        }

        modalScope.closeInstructionsModal = async () =>  {
            if (this.$newProcessScope.hasChanges(modalScope.instructions, modalScope.oldInstructions)) {
                const close = this.formService.getTranslate('GENERAL.CLOSE');
                const confirm = await this.$newProcessScope.modalSaveConfirmation(close, close);
                if (confirm && !this.applyInstructionsModal(null)) return;
            }
            this.modalService.closeModal(this.instructionsModalID);
            this.instructionsModalID = 0;
            $('.app-content-body').animate({ scrollTop: this.$newProcessScope.lastScroll }, 0);
        }

        modalScope.addInternalTag = async (data: string, type: IProcessInstructionType) => {
            await this.addInternalTag(modalScope.instructions, data, type);
        };

        modalScope.deleteInternalTag = async (data: string, type: IProcessInstructionType) => {
            await this.deleteInternalTag(modalScope.instructions, data, type);
        };

        modalScope.cleanInstructionAll = async (type: IProcessInstructionType) => {
            await this.cleanInstructionAll(modalScope.instructions, type);
        }

        return modalScope;
    }

    private async applyInstructionsModal(instructions: INSTRUCTION, close?: boolean): Promise<boolean> {
        let success = false;
        try {
            const hasInvalid = this.$newProcessScope.hasInvalidRequiredElements('modalForm');
            if (hasInvalid) return;
            if (!instructions) throw Error('instructions is null');
            if (!hasInvalid) {
                this.formService.block();
                const updateResponse = await this.operationalService.post(`/process/updateInstruction`, { processNumber: this.$scope.processNumber, data: { INSTRUCTION: instructions } }, 30000);
                if (updateResponse && updateResponse.status == 200) {
                    success = true;
                    const msg = this.formService.getTranslate('BASIC_DATA.INSTRUCTIONS_SUCESSFULLY_UPDATED');
                    this.formService.notifySuccess(msg);
                }
                if (close) {
                    this.modalService.closeModal(this.instructionsModalID);
                    this.instructionsModalID = 0;
                    $('.app-content-body').animate({ scrollTop: this.$newProcessScope.lastScroll }, 0);
                }
            }
            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return success;
        }
    }


    private async addInternalTag(instructions: INSTRUCTION, data: string, type: IProcessInstructionType) {
        try {
            if (!instructions) throw new Error("Módulo de instruções está nulo.");
            if (!type) throw new Error("Tipo de instrução está nulo.");

            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate('OPERATIONAL.DO_YOU_WANT_TO_ADD_AN_INTERNAL_TAG_TO_THIS_INSTRUCTION')
            });

            if (!modal) return;

            const tagStart = "[internal";
            const tagEnd = "[/internal]";

            data = (data) ? data : "";
            const msgError = this.formService.getTranslate('OPERATIONAL.TAG_ALREADY_EXISTS');
            if (data.indexOf(tagStart) >= 0) throw new Error(msgError);

            let tag: string = "";

            tag += tagStart + " " + moment(new Date()).format('DD[/]MM[/]YYYY HH:mm') + " - " + this.$newProcessScope.user['email'] + "]\n\n";
            tag += ` ***** ${this.formService.getTranslate('OPERATIONAL.USE_THIS_SPACE_TO_PUT_IMPORTANT_INFORMATION')} *****\n\n`;
            tag += tagEnd + "\n\n";

            this.fillInstructionInformation(instructions, type, tag + data);

            this.$scope.updatedInstructions = true;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async deleteInternalTag(instructions: INSTRUCTION, data: string, type: IProcessInstructionType) {
        try {
            if (!instructions) throw new Error("Módulo de instruções está nulo.");
            if (!type) throw new Error("Tipo de instrução está nulo.");

            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate('GENERAL.DO_YOU_WANT_TO_REMOVE')
            });

            if (!modal) return;

            const tagStart = "[internal";
            const tagEnd = "[/internal]";
            const msgError = this.formService.getTranslate('OPERATIONAL.NO_INTERNAL_TAG');
            if (!data || data.indexOf(tagStart) < 0 || data.indexOf(tagEnd) < 0) throw new Error(msgError);

            const posFirstTag = data.indexOf(tagStart);
            const posSecondTag = data.indexOf(tagEnd);

            const firstSection = data.substring(0, posFirstTag);
            const secondSection = data.substring(posSecondTag + tagEnd.length, data.length);
            const editedInstruction = firstSection.trim() + "\n" + secondSection.trim();

            this.fillInstructionInformation(instructions, type, editedInstruction.trim());
            const msg = this.formService.getTranslate('OPERATIONAL.THE_INTERNAL_TAG_WAS_SUCESSFULLY_REMOVED');
            this.formService.notifySuccess(msg);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async cleanInstructionAll(instructions: INSTRUCTION, type: IProcessInstructionType) {
        try {
            if (!instructions) throw new Error("Módulo de instruções está nulo.");
            if (!type) throw new Error("Tipo de instrução está nulo.");

            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate('OPERATIONAL.DO_YOU_WANT_TO_REMOVE_ALL_THE_INFORMATION_OF_THIS_INSTRUCTION')
            });

            if (!modal) return;

            this.fillInstructionInformation(instructions, type, "");

            this.$scope.updatedInstructions = true;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private fillInstructionInformation(instructions: INSTRUCTION, type: IProcessInstructionType, data: string) {
        switch (type) {
            case IProcessInstructionType.PRODUCT: instructions.PRODUCT = data;
                break;
            case IProcessInstructionType.COMMERCIAL: instructions.COMMERCIAL = data;
                break;
            case IProcessInstructionType.OPERATIONAL: instructions.OPERATIONAL = data;
                break;
            case IProcessInstructionType.FINANCIAL: instructions.FINANCIAL = data;
                break;
            case IProcessInstructionType.CUSTOMER: instructions.CUSTOMER = data;
                break;
            case IProcessInstructionType.AGENT: instructions.AGENT = data;
                break;
            case IProcessInstructionType.HOUSE: instructions.PROCESS_HOUSE = data;
                break;
            case IProcessInstructionType.MASTER: instructions.PROCESS_MASTER = data;
                break;
        }
    }

}
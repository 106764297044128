import * as angular from 'angular';
import * as Address from '../communication/Address';
import * as config from '../bootstrap/Config';
import { HandleError } from './util/HandleError';
import { REFERENCE } from '@models/interface/operational/FollowUpProcessModel';
import { ISuccessItemResponse } from '@models/interface/common/IMonacoUpload';

export interface UploadSettings {
    scope: ng.IScope;
    requests: UploadRequest[];
    scopeMap?: IScopeMap;
}

interface IScopeMap extends UploadRequest {
    modelName: string;
}

export interface UploadRequest {
    processNumber: string;
    customerId: string;
    customerName: string;
}

export interface IFile {
    abort?: angular.IDeferred<unknown>;
    active?: boolean;
    url: { processNumber: string, url: string }[]; // a file can be put in multiple directories (for multiple process)
    transferred: boolean;
    uploaded: boolean;
    error: object | string;
    classType: string;
    message: string;
    class: string;
    name: string;
    percent: number;
    size: number;
    type: string
}


export class UploadController {
    private static _instance: UploadController;
    private $injector: ng.Injectable<any>;
    private $timeout: ng.ITimeoutService;
    private $q: ng.IQService;
    private uploadRoute: string;
    private uploadSettings: UploadSettings;
    private uploadResponse: Array<any>;
    private pendingResponse: number;
    private timeout: number;

    private files: IFile[];

    private activeUploads: number;
    private uploadedFiles: number;


    private constructor() {
        this.timeout = 180000; //3minutes
        let uploadRoute = undefined;
        if (config.default.environment === 'prod') {
            uploadRoute = `${Address.monacoAddressProd.FIS}/api/v1/fis/upload`;
        } else if (config.default.environment === 'qa') {
            uploadRoute = `${Address.monacoAddressQa.FIS}/api/v1/fis/upload`;
        } else { //dev
            uploadRoute = `{${Address.monacoAddressLocal.FIS}}/api/v1/fis/upload`;
        }
        this.uploadRoute = uploadRoute;
        this.files = [];
        this.activeUploads = 0;
        this.uploadedFiles = 0;
    }

    //singleton get instance
    public static get Instance(): UploadController {
        return this._instance || (this._instance = new this());
    }

    private initFile(file: any): any {
        file['class'] = "progress-striped";
        file['classType'] = "";
        file['percent'] = 0;
        file['message'] = 'Aguardando...'
        file['url'] = null;

        return file;
    }


    private onUploadError(eventError: ProgressEvent | ErrorEvent, type: string, file: any): void {
        this.activeUploads -= 1;

        this.handleError(type, eventError, file);
    }

    private onUploadStart(event: ProgressEvent, file: any): void {
        file = this.initFile(file);
    }

    private onUploadTransferProgress(event: ProgressEvent, file: any): void {
        if (!event.lengthComputable) return;

        file.loaded = event.loaded;
        file.humanSize = this.getHumanSize(event.loaded);

        if (file['percent'] < 90) {

            file['percent'] = file.loaded * 100 / file.size;

            file['message'] = file.percent.toFixed(0) + '%';

            //to fast upload loaded and size conflict
            if (file['percent'] > 100) file['message'] = '100%';

        }
        else {
            file['message'] = '90%';
        }
    }

    private onUploadTransferSuccess(event: ProgressEvent, file: any): void {
        file['transferred'] = true;
    }

    private onUploadCompleted(file: any, responseText: string, status: number): void {
        //this.handleUploadResponse(file, responseText, status);
    }

    private onCompletedAll(files: Array<any>): void {
    }


    private getScopeObjectProperty(property: string): string {
        try {
            if (!property || !this.uploadSettings.scopeMap) return null;

            const modelName = this.uploadSettings.scopeMap.modelName;
            const properties = property.split('.');
            //TODO: IMPLEMENT DEEP > 2, this just works for one object property
            if (properties.length > 1) return this.uploadSettings.scope[modelName][properties[0]][properties[1]];
            else return this.uploadSettings.scope[modelName][property];

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    private getHumanSize(bytes: number): string {
        try {
            const sizes = ['n/a', 'bytes', 'KiB', 'MiB', 'GiB', 'TB', 'PB', 'EiB', 'ZiB', 'YiB'];
            const i = (bytes === 0) ? 0 : +Math.floor(Math.log(bytes) / Math.log(1024));
            return (bytes / Math.pow(1024, i)).toFixed(i ? 1 : 0) + ' ' + sizes[isNaN(bytes) ? 0 : i + 1];

        } catch (ex) {
            HandleError.exception(ex);
            return 'n/a';
        }
    }

    private handleError(type: string, error: any, file: IFile): void {
        try {
            //controll variables, do not touch transferred state
            if (file['abort']) file['abort'].resolve();
            delete file['abort'];
            file['active'] = false;
            file['uploaded'] = false;
            file['error'] = error;

            file['classType'] = "danger";
            file['class'] = "progress";
            file['percent'] = 100;
            file['url'] = null;

            switch (type) {
                case 'timeout':
                    file['message'] = 'Tempo Limite Esgotado!'
                    break;
                case 'abort':
                    file['message'] = 'Upload Cancelado!';
                    break;
                case 'invalid':
                    file['error'] = 'invalid';
                    file['message'] = 'Arquivo Inválido!';
                    break;
                case 'error':
                    file['message'] = 'Upload Falhou!';
                    break;
                default:
                    file['message'] = 'Erro Desconhecido!'
                    HandleError.exception(error);
                    break;
            }

        } catch (ex) {
            HandleError.exception(ex);
        }
    }


    private async uploadRequest(file: IFile): Promise<void> {
        try {
            if (this.uploadSettings.scopeMap) {
                const scopeMap = this.uploadSettings.scopeMap;
                const uploadRequest: UploadRequest = {
                    processNumber: this.getScopeObjectProperty(scopeMap.processNumber),
                    customerId: this.getScopeObjectProperty(scopeMap.customerId),
                    customerName: this.getScopeObjectProperty(scopeMap.customerName)
                };
                if (uploadRequest.processNumber) this.uploadSettings.requests.push(uploadRequest);
            }

            const $http: ng.IHttpService = this.$injector.get('$http');
            let uploadError = null;
            this.activeUploads += 1;

            //file controll variables
            file['transferred'] = false;
            file['uploaded'] = false;
            file['active'] = true;
            file['abort'] = this.$q.defer();

            //init timeout timer
            const timeoutPromise = this.$timeout((self) => {
                self.$timeout.cancel(timeoutPromise);
                file['error'] = 'timeout';
                file['abort'].resolve(); //aborts the request when timed out
            }, this.timeout, true, this);


            const isSingleProcessDirectory = (this.uploadSettings.requests.length === 1);
            for (const request of this.uploadSettings.requests) {

                // Append additional data if provided:
                const formData: FormData = new FormData();
                for (let prop in request) {
                    if (request.hasOwnProperty(prop)) {
                        formData.append(prop, request[prop]);
                    }
                }
                // Append file data to formData
                formData.append('files', <any>file, file.name);

                try {
                    const uploadOperation = $http<ISuccessItemResponse>(
                        {
                            method: 'POST',
                            url: this.uploadRoute,
                            data: formData,
                            transformRequest: angular.identity,
                            headers: { 'Content-Type': undefined },
                            cache: true,
                            timeout: file['abort'].promise,
                            withCredentials: false,
                            uploadEventHandlers: {
                                timeout: (event: ProgressEvent | ErrorEvent) => {
                                    uploadError = 'timeout';
                                    this.onUploadError(event, uploadError, file);
                                    this.uploadSettings.scope.$applyAsync();
                                },
                                abort: (event: ProgressEvent | ErrorEvent) => {
                                    uploadError = (file['error'] && file['error'] === 'timeout') ? 'timeout' : 'abort';
                                    this.onUploadError(event, uploadError, file);
                                    this.uploadSettings.scope.$applyAsync();
                                },
                                error: (event: ProgressEvent | ErrorEvent) => {
                                    uploadError = 'error';
                                    this.onUploadError(event, uploadError, file);
                                    this.uploadSettings.scope.$applyAsync();
                                },
                                loadstart: (event: ProgressEvent) => {
                                    const currentFile = angular.copy(file);
                                    this.onUploadStart(event, file);
                                    if (!isSingleProcessDirectory) file.url = currentFile.url;
                                    this.uploadSettings.scope.$applyAsync();
                                },
                                progress: (event: ProgressEvent) => {
                                    this.onUploadTransferProgress(event, file);
                                    //this.uploadSettings.scope.$applyAsync();
                                },
                                onload: (event: ProgressEvent) => {
                                    this.onUploadTransferSuccess(event, file);
                                    this.uploadSettings.scope.$applyAsync();
                                }
                            }
                        });

                    //exec operation
                    const uploadResponse = await uploadOperation;

                    //handle response
                    if (uploadResponse.data['data'] && uploadResponse.data['data'].length > 0) {
                        const fileInfo = uploadResponse.data['data'][0];
                        if (file['url'])
                            file['url'].push({ processNumber: request.processNumber, url: fileInfo.fileUrl });
                        else
                            file['url'] = [{ processNumber: request.processNumber, url: fileInfo.fileUrl }];

                    } else this.handleError('unkown', uploadResponse.data, file);

                    this.uploadSettings.scope.$applyAsync();

                } catch (responseError) {
                    this.$timeout.cancel(timeoutPromise);
                    if (file['abort']) file['abort'].resolve();
                    delete file['abort'];
                    //if (file['abort'] && file['abort'].promise.$$state.pending) file['abort'].resolve();

                    //just handle server response if the error is not a upload error and was not previously handled
                    if (!uploadError) {
                        let data = responseError.data;
                        if (data && typeof (data) === 'string' && data.includes('<pre>')) {
                            data = data.split('<pre>')[1].split('</pre>')[0];
                            if (data && data.startsWith('Invalid')) this.handleError('invalid', data, file);
                            else this.handleError('unkown', data, file);
                        } else this.handleError('error', responseError, file);

                        this.uploadSettings.scope.$applyAsync();
                    }
                }

            }

            //cancel timeout timer
            this.$timeout.cancel(timeoutPromise);
            file['active'] = false;
            if (file['abort']) file['abort'].resolve();
            delete file['abort'];

            file['uploaded'] = true;
            file['classType'] = "success";
            file['message'] = "Upload com Sucesso!";
            file['class'] = "progress";
            file['percent'] = 100;

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    public init($injector: ng.Injectable<any>, uploadSettings: UploadSettings): void {
        this.$injector = $injector;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.uploadSettings = uploadSettings;
    }

    public addFiles(files: any): void {
        for (let i = 0; i < files.length; i++) {
            this.files.push(this.initFile(files[i]));
        }
    }

    public loadReferencesFiles(files: Array<REFERENCE>, processNumber: string): Array<IFile> {
        if (angular.isArray(files)) {
            for (let i = 0; i < files.length; i++) {

                const file: IFile = {
                    class: '',
                    error: null,
                    message: 'Upload com Sucesso!',
                    name: files[i].NAME,
                    size: files[i].SIZE,
                    url: [{ processNumber, url: files[i].LINK }],
                    classType: 'success',
                    percent: 100,
                    type: '',
                    transferred: true,
                    uploaded: true,
                };
                this.files.push(file);
            }
        }
        return this.files;
    }

    public getFiles(): IFile[] {
        return this.files;
    }

    public getUploadedFiles(): Array<IFile> {
        const files: Array<IFile> = UploadController.Instance.getFiles();
        let results: Array<IFile> = null;
        if (angular.isArray(files) && files.length > 0) {
            for (let i: number = 0; i < files.length; i++) {
                if (files[i].uploaded) {

                    if (!angular.isArray(results)) results = [];

                    const file: IFile = angular.extend({}, files[i]);

                    ///breaking blob reference
                    file.name = angular.copy(files[i].name);
                    file.size = angular.copy(files[i].size);
                    file.type = angular.copy(files[i].type);
                    results.push(file);
                }
            }
        }
        return results;
    }

    public removeFile(file: any): void {
        try {

            if (file['abort']) file['abort'].resolve();
            this.files.splice(this.files.indexOf(file), 1);

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    public removeAll(): void {
        try {

            for (let i = 0; i < this.files.length; i++) if (this.files[i]['abort']) this.files['abort'].resolve();
            this.files.splice(0, this.files.length);

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    public convertFilesToReferencesModel(files: Array<IFile>, processNumber: string): Array<REFERENCE> {
        const references: Array<REFERENCE> = [];
        if (angular.isArray(files)) {
            for (let i: number = 0; i < files.length; i++) {
                const processDirectory = (files[i].url) ? files[i].url.find(x => x.processNumber === processNumber) : null;
                const reference: REFERENCE = {
                    ID_CONSOLIDATED: null,
                    REFERENCE_ID: null,
                    SOURCE: null,
                    TYPE: 'UPLOAD',
                    MAIN_FILE: null,
                    DOCUMENT_TYPE: null,
                    DOCUMENT_VALUE: null,
                    TEMPLATE_TYPE: null,
                    FILE_GROUP: null,
                    FILE_SPECS: null,
                    INSERT_DATE: null,
                    USER_REFERENCE: null,
                    NAME: files[i].name,
                    LINK: (processDirectory) ? processDirectory.url : null,
                    SIZE: files[i].size
                }

                references.push(reference);
            }
        }

        return references;
    }

    public convertFileToReferenceModel(file: IFile, processNumber: string): REFERENCE {
        const processDirectory = (file.url) ? file.url.find(x => x.processNumber === processNumber) : null;
        const reference: REFERENCE = {
            ID_CONSOLIDATED: null,
            REFERENCE_ID: null,
            SOURCE: null,
            TYPE: 'UPLOAD',
            MAIN_FILE: null,
            DOCUMENT_TYPE: null,
            DOCUMENT_VALUE: null,
            TEMPLATE_TYPE: null,
            FILE_GROUP: null,
            FILE_SPECS: null,
            INSERT_DATE: null,
            USER_REFERENCE: null,
            NAME: file.name,
            LINK: (processDirectory) ? processDirectory.url : null,
            SIZE: file.size
        }
        return reference;
    }

    public async startUpload(): Promise<void> {
        //headers are not shared by requests
        for (let i = 0; i < this.files.length; i++) {
            //ignore files that are being uploaded or are already uploaded
            if (this.files[i].active || this.files[i].uploaded || (this.files[i].error && this.files[i].error === 'invalid')) continue;
            await this.uploadRequest(this.files[i]);
        }
    }


    /*     private uploadRequest(file: any): XMLHttpRequest {
            try {
                const url = this.options.url;
                const header = this.options.headers;
                const data = this.options.data;
                const key = this.options.paramName;
    
                this.activeUploads += 1;
                file.active = true;
    
                //set xhr options
                const xhr: XMLHttpRequest = new XMLHttpRequest();
                xhr.timeout = this.options.timeout;
                // To account for sites that may require CORS
                if (this.options.withCredentials) xhr.withCredentials = true;
    
                //open request
                xhr.open('POST', url);
    
                //set request headers
                //const headers = this.options.headers || {};
                if (header) {
                    for (const headerKey in header) {
                        if (header.hasOwnProperty(headerKey)) {
                            xhr.setRequestHeader(headerKey, header[headerKey]);
                        }
                    }
                }
    
                // Triggered when upload starts:
                xhr.upload.onloadstart = () => {
                    this.onUploadStart(file);
                };
    
                //HOOKS
                //ontimeout: ((this: XMLHttpRequest, ev: ProgressEvent) => any) | null;
                //onabort: ((this: XMLHttpRequest, ev: ProgressEvent) => any) | null;
                //onerror: ((this: XMLHttpRequest, ev: ProgressEvent) => any) | null;
                //onload: ((this: XMLHttpRequest, ev: ProgressEvent) => any) | null;
                //onloadend: ((this: XMLHttpRequest, ev: ProgressEvent) => any) | null;
                //onloadstart: ((this: XMLHttpRequest, ev: ProgressEvent) => any) | null;
                //onprogress: ((this: XMLHttpRequest, ev: ProgressEvent) => any) | null;
    
    
                // Triggered many times during upload:
                xhr.upload.onprogress = (event: ProgressEvent) => {
                    if (!event.lengthComputable) return;
    
                    // Update file size because it might be bigger than reported by the fileSize:
                    //$log.info("progres..");
                    //console.info(event.loaded);
                    file.loaded = event.loaded;
                    file.humanSize = this.getHumanSize(event.loaded);
    
                    //call progress callback
                    //this.onUploadTransferProgress(xhr, file);
                };
    
                // Triggered when the upload is successful (the server may not have responded yet).
                xhr.upload.onload = (ev: ProgressEvent) => {
                    this.onUploadTransferSuccess(xhr, file);
                };
    
                xhr.onload = (ev: ProgressEvent) => {
                    this.onUploadTransferSuccess(xhr, file);
                };
    
                xhr.onprogress = (event: ProgressEvent) => {
                    this.onUploadTransferProgress2(xhr, file);
                };
    
                xhr.onerror = (ev: ProgressEvent | ErrorEvent) => {
                    this.activeUploads -= 1;
    
                    this.onUploadError(xhr, ev);
                };
    
                // Triggered when upload fails:
                xhr.upload.onerror = (ev: ProgressEvent | ErrorEvent) => {
                    this.activeUploads -= 1;
    
                    this.onUploadError(xhr, ev);
                };
    
                // Triggered when upload request timeout
                xhr.upload.ontimeout = (ev: ProgressEvent) => {
                    this.activeUploads -= 1;
    
                    this.onUploadError(xhr, ev);
                };
    
                // Triggered when upload request aborted
                xhr.upload.onabort = (ev: ProgressEvent) => {
                    this.activeUploads -= 1;
    
                    this.onUploadError(xhr, ev);
                };
    
    
                // Triggered when the upload has completed AND the server has responded. Equivalent to
                // listening for the readystatechange event when xhr.readyState === XMLHttpRequest.DONE.
                xhr.onload = () => {
                    this.activeUploads -= 1;
                    this.uploadedFiles += 1;
    
                    //call file completed callback
                    this.onUploadCompleted(file, xhr.responseText, xhr.status);
    
                    //check if all files completed
                    if (this.activeUploads === 0) {
                        this.uploadedFiles = 0;
                        //call files completed callback
                        this.onCompletedAll(this.files);
                    }
                };
    
                // Append additional data if provided:
                const formData: FormData = new FormData();
                if (data) {
                    for (let prop in data) {
                        if (data.hasOwnProperty(prop)) {
                            formData.append(prop, data[prop]);
                        }
                    }
                }
                // Append file data to formData
                formData.append(key, file, file.name);
    
                // Initiate upload:
                xhr.send(formData);
    
                return xhr;
    
            } catch (ex) {
                HandleError.exception(ex);
            }
        } */

}
import * as angular from 'angular';

const externalState = {
    name: 'app.external',
    url: '/external',
    abstract: true,
    data: {
        requiresAuth: true,
        permissions: {
            only: 'EXTERNAL',
            redirectTo: 'app.denied'
        }
    }
}

const oldDraftState = {
    name: 'app.external.draft',
    url: '/draft',
    views: {
        'content@app': {
            template: require("./view/oldExternalDraftForm.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    ncyBreadcrumb: {
        label: '{{breadCrumb.label}}',
    }
}

routeConfig.$inject = ['$uiRouterProvider'];
function routeConfig($uiRouterProvider) {
    const $stateRegistry = $uiRouterProvider.stateRegistry;

    $stateRegistry.register(externalState);
    $stateRegistry.register(oldDraftState);
}

export default function initRoute(moduleName: string): void {
    angular.module(moduleName).config(routeConfig);
}
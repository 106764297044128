import * as angular from 'angular';
import initRoute from './RouteConfig';
import { GRID_FORM_MODULE } from '../gridForm/GridFormModule';
import { GRID_EXCEL_MODULE } from '../gridExcel/GridExcelModule';
import { CommercialMenuController } from './controller/CommercialMenuController';

import { OfferRegisterController } from './controller/OfferRegisterController';
import { OfferCommunicationRegisterController } from './controller/OfferCommunicationRegisterController';
import { OfferMainRegisterController } from './controller/OfferMainRegisterController';
import { OfferCargoRegisterController } from './controller/OfferCargoRegisterController';
import { OfferEventRegisterController } from './controller/OfferEventRegisterController';
import { OfferChargeRegisterController } from './controller/OfferChargeRegisterController';
import { OfferChargeOutDateRegisterController } from './controller/OfferChargeOutDateRegisterController';
import { OfferManagementRegisterController } from './controller/OfferManagementRegisterController';
import { OfferReferenceRegisterController } from './controller/OfferReferenceRegisterController';
import { OfferWizardModalRegisterController } from './controller/OfferWizardModalRegisterController';
import { OfferOldWizardModalRegisterController } from './controller/OfferOldWizardModalRegisterController';
import { OfferOpportunityRegisterController } from './controller/OfferOpportunityRegisterController';
import { CombinedPreOfferRegisterController } from './controller/CombinedPreOfferRegisterController';
import { OfferNewAlternativeWizardModalController } from './controller/OfferNewAlternativeWizardModalController';
import { OfferNewAlternativeOldWizardModalController } from './controller/OfferNewAlternativeOldWizardModalController';
import { OfferCargoPolLbModalController } from './controller/OfferCargoPolLbModalController';
import { ChargesPerWeightRangeComparisonModalController } from './controller/ChargesPerWeightRangeComparisonModalController';

import { AccountBulkChangeSalesPersonWizardController } from './controller/AccountBulkChangeSalesPersonWizardController';
import { AccountRegisterController } from './controller/AccountRegisterController';

import { DataProductService } from '@services/DataProductService';
import { DataOperationalService } from '@services/DataOperationalService';
import { ProductService } from '@services/ProductService';
import { OperationalService } from '@services/OperationalService';

const COMMERCIAL_MODULE = angular
    .module('commercial', [GRID_FORM_MODULE.name, GRID_EXCEL_MODULE.name])

    .service("DataProductService", DataProductService)
    .service('DataOperationalService', DataOperationalService)
    .service('ProductService', ProductService)
    .service('OperationalService', OperationalService)

    .controller('CommercialMenuController', CommercialMenuController)

    .controller('OfferRegisterController', OfferRegisterController)
    .controller('OfferCommunicationRegisterController', OfferCommunicationRegisterController)
    .controller('OfferRegisterController', OfferRegisterController)
    .controller('OfferMainRegisterController', OfferMainRegisterController)
    .controller('OfferCargoRegisterController', OfferCargoRegisterController)
    .controller('OfferEventRegisterController', OfferEventRegisterController)
    .controller('OfferChargeRegisterController', OfferChargeRegisterController)
    .controller('OfferChargeOutDateRegisterController', OfferChargeOutDateRegisterController)
    .controller('OfferManagementRegisterController', OfferManagementRegisterController)
    .controller('OfferReferenceRegisterController', OfferReferenceRegisterController)
    .controller('OfferWizardModalRegisterController', OfferWizardModalRegisterController)
    .controller('OfferOldWizardModalRegisterController', OfferOldWizardModalRegisterController)
    .controller('OfferOpportunityRegisterController', OfferOpportunityRegisterController)
    .controller('CombinedPreOfferRegisterController', CombinedPreOfferRegisterController)
    .controller('OfferNewAlternativeWizardModalController', OfferNewAlternativeWizardModalController)
    .controller('OfferNewAlternativeOldWizardModalController', OfferNewAlternativeOldWizardModalController)
    .controller('OfferCargoPolLbModalController', OfferCargoPolLbModalController)
    .controller('ChargesPerWeightRangeComparisonModalController', ChargesPerWeightRangeComparisonModalController)

    .controller('AccountRegisterController', AccountRegisterController)
    .controller('AccountBulkChangeSalesPersonWizardController', AccountBulkChangeSalesPersonWizardController)

initRoute(COMMERCIAL_MODULE.name);

export { COMMERCIAL_MODULE };
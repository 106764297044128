import * as angular from "angular";
import { isArray } from "jquery";
import { ISessionService } from "@services/SessionService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IRestService } from "@services/RestService";
import { GridFormService, IGridFormController, IGridFormServiceScope, IMonacoRequest } from "@services/GridFormService";
import { TaskService } from "@services/TaskService";
import { IModalService } from "@services/ModalService";
import { ExternalService } from "@services/ExternalService";
import { ProductService } from "@services/ProductService";
import { ICustomLogProperties, IViewLog } from "@models/interface/common/IViewLog";
import { TARGETS, TARGET_GROUP, TaskParam, TRIGGER, DETOUR_LIST, INTEGRATION_PARAM } from "@models/interface/task/TaskParam";
import { CellModel } from "@models/interface/common/CellModel";
import { TaskHotspot } from "@models/interface/task/TaskHotspot";
import { IAccountSelector, ILegalPersonSelector, IAgentSelector, IServiceProviderSelector, IIncotermSelector, ITradeLaneSelector, IRoutingPointSelector } from "@models/interface/operational/IProductIntegrationSelector";
import { ISelectorModel } from "@models/mongo/SelectorModel";
import { IMoveTypeModel } from "@models/interface/product/MoveTypeModel";
import { TARGET_PARAM_TYPE } from "@enums/TaskHotspot";
import { BrowserTitle } from "../../common/BrowserTitle";
import { IFloatingMenu } from "../../common/interface/IFloatingMenu";
import { SelectorModel } from "../../common/model/SelectorModel";
import { ILegalPersonListCustomFilter } from "../../registration/model/LegalPersonModel";
import { IAgentListCustomFilter } from "../../registration/model/AgentModel";
import { IBrokerListCustomFilter } from "../model/BrokerModel";
import { OperationalService } from "@services/OperationalService";
import { HelperService } from "@services/HelperService";

const enum OperationModal {
    MARITIME = '2',
    ROAD = '3',
    AIR = '4'
}

interface ITaskParamScope extends IGridFormServiceScope {
    model: TaskParam;
    scopeBeforeSave: TaskParam;
    log: IViewLog;
    menuFloating: IFloatingMenu;
    customLogProperties: ICustomLogProperties[];
    form: ng.IFormController;

    basicDataTemplate: string;
    detourListTemplate: string;
    generalConditionsTemplate: string;
    involvedTemplate: string;
    routeTemplate: string;
    invoiceTemplate: string;
    calcTemplate: string;
    triggerTemplate: string;
    hotspotTemplate: string;
    integrationTemplate: string;

    taskOptionsList: SelectorModel[];
    taskStepList: SelectorModel[];
    moduleList: SelectorModel[];
    cellList: SelectorModel[];
    roleList: SelectorModel[];
    userList: SelectorModel[];
    //
    productList: SelectorModel[];
    forwardedByList: SelectorModel[];
    processTypeList: SelectorModel[];
    priorityList: SelectorModel[];
    cargoTypeList: SelectorModel[];
    bookingStatusList: SelectorModel[];
    incotermList: SelectorModel[];
    paymentTypeList: SelectorModel[];
    serviceType: SelectorModel[];
    equipmentList: SelectorModel[];
    contractBookingType: SelectorModel[];
    branchesList: SelectorModel[];
    transporterList: SelectorModel[];

    //
    accountList: SelectorModel[];
    qualificationList: SelectorModel[];
    serviceProviderList: SelectorModel[];
    agentList: SelectorModel[];
    brokerList: ISelectorModel[];
    externalBrokerList: ISelectorModel[];
    //
    groupList: SelectorModel[];
    originCountryList: SelectorModel[];
    originLocalList: SelectorModel[];
    destinationCountryList: SelectorModel[];
    destinationLocalList: SelectorModel[];
    tradeLineOriginList: SelectorModel[];
    tradeLineDestList: SelectorModel[];
    //
    transactionList: SelectorModel[];
    paymentMethodList: SelectorModel[];
    paymentConditionList: SelectorModel[];
    currencyExchangeList: SelectorModel[];
    conversionReferenceList: SelectorModel[];
    natureList: SelectorModel[];
    invoiceStatusList: SelectorModel[];
    invoiceFinancialStatusList: SelectorModel[];
    peopleList: SelectorModel[];
    //
    dependencyTypeList: SelectorModel[];
    startDependencyList: SelectorModel[];
    endDependencyList: SelectorModel[];

    startDependencyClassList: SelectorModel[];
    endDependencyClassList: SelectorModel[];

    startDependencyDateTypeList: SelectorModel[];
    endDependencyDateTypeList: SelectorModel[];

    cargoTreatmentList: SelectorModel[];
    serviceLevelList: SelectorModel[];
    serviceTypeList: SelectorModel[];

    calcTypeList: SelectorModel[];
    hourTypeList: SelectorModel[];
    consecutiveHourList: SelectorModel[];
    taskRecalcList: SelectorModel[]; // not used
    taskStatusList: SelectorModel[];
    taskRestoreList: SelectorModel[];
    processStatusList: SelectorModel[];
    //
    targetTypeList: SelectorModel[];
    targetClassList: { [parentIndex: number]: { [index: number]: SelectorModel[] } };
    targetList: { [parentIndex: number]: { [index: number]: SelectorModel[] } };
    targetParamList: { [parentIndex: number]: { [index: number]: SelectorModel[] } };
    processDocumentTypeList: SelectorModel[];
    eventList: SelectorModel[];
    detourMandatoryList: SelectorModel[];
    detourList: SelectorModel[];
    integrationList: SelectorModel[];
    notificationTypeList: object[];

    //
    htmlRestorePopover: string;
    //

    //data / refreshes
    refreshCellDependencyList: (selectedCell: CellModel) => Promise<void>;
    refreshLocals: (query: string, scopeProp: string) => Promise<void>;
    refreshCountry: (search: string, scopeProp: string) => Promise<void>;
    refreshTradeLine: (query: string, scopeProp: string) => Promise<void>;

    changeProduct: () => void;
    changeOriginDestinationEnable: (changedProp: string) => void;
    changeHourConsecutive: (type: string) => void;

    // fields
    addTaskTrigger: () => void;
    removeTaskTrigger: (index: number) => void;
    addTargetGroup: () => void;
    removeTargetGroup: (index: number) => void;
    addTarget: (targetGroupIndex: number, index: number) => void;
    removeTarget: (parentIndex: number, index: number) => void;
    validateDuplicateTrigger: (index: number) => void;
    validateDuplicateHotspotTarget: (parentIndex: number, index: number) => void;
    validateHotspotTargetEvent: (parentIndex: number) => void;
    changeHotspotTargetType: (parentIndex: number, index: number) => void;
    changeHotspotTarget: (hotspotID: string, parentIndex: number, index: number) => void;
    changeDependencyType: (dependency: string) => void;
    changeDependency: (dependency: string) => void;
    addIntegrationParam: () => void;
    removeIntegrationParam: (index: number) => void;

    //gets
    getPeopleList(query: string): Promise<void>;
    getNotificationTypeData(): Promise<void>;
    getBranchListByName: (term: string) => void;
    getAccountListByName: (term: string) => void;
    getAgentListByName: (term: string) => void;
    getServiceProviderListByName: (term: string) => void;
    getTransporterListByName: (term: string) => void;
    getBrokerOperationalListByName: (search: string) => void;

    // gotos
    goToProvider: (name?: string) => void;

    //list
    addDetour: () => void;
    removeDetour: (index: number) => void;
    formatConcatenatedChars: (value: string) => string;
    collapseHeader: (elementId: string, state?: string) => void;
    initPanels: () => void;
}

export class TaskParamRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: ITaskParamScope;
    private $timeout: ng.ITimeoutService
    private $q: ng.IQService;
    private RestService: IRestService;
    private ProductService: ProductService;
    private sessionService: ISessionService;
    private TaskService: TaskService;
    private ExternalService: ExternalService;
    private collapseState: string;
    private hotspotList: TaskHotspot[];
    private SCEService: ng.ISCEService;
    private ModalService: IModalService;
    private OperationalService: OperationalService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: ITaskParamScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$timeout = $injector.get('$timeout');
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.ProductService = $injector.get("ProductService");
        this.sessionService = $injector.get('SessionService');
        this.TaskService = $injector.get('TaskService');
        this.ExternalService = $injector.get('ExternalService');
        this.collapseState = "hide";
        this.SCEService = $injector.get('$sce');
        this.ModalService = $injector.get('ModalService');
        this.OperationalService = $injector.get('OperationalService');
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.TaskService.$route;
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.initForm(this, 'form', 'taskParam', 'GENERAL.MENU.ACTIVITY_PARAMETERIZATION', true);
            await this.initGrid('gridTaskParam', '/param/list', true, true, 120000);

            this.$scope.basicDataTemplate = `taskParamBasicDataTemplate`;
            this.$scope.detourListTemplate = `taskParamDetourListTemplate`;
            this.$scope.generalConditionsTemplate = `taskParamGeneralConditionsTemplate`;
            this.$scope.involvedTemplate = `taskParamInvolvedTemplate`;
            this.$scope.routeTemplate = `taskParamRouteTemplate`;
            this.$scope.invoiceTemplate = `taskParamInvoiceTemplate`;
            this.$scope.calcTemplate = `taskParamCalcTemplate`;
            this.$scope.triggerTemplate = `taskParamTriggerTemplate`;
            this.$scope.hotspotTemplate = `taskParamHotspotTemplate`;
            this.$scope.integrationTemplate = `taskParamIntegrationTemplate`;
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.$scope.htmlRestorePopover = this.SCEService.trustAsHtml(`<div class="label label-default">${this.formService.getTranslate('REGISTRATION.CONDITION_YES')}</div> ${this.formService.getTranslate('REGISTRATION.CONDITION_YES')} <b>${this.formService.getTranslate('REGISTRATION.SITUATION_TASK')}</b>. <p> <div class="label label-default">${this.formService.getTranslate('REGISTRATION.CONDITION_NO')}</div> ${this.formService.getTranslate('REGISTRATION.KEEP_TASK')} <b>${this.formService.getTranslate('REGISTRATION.TASK_CONCLUDED')}</b> ou <b>${this.formService.getTranslate('REGISTRATION.TASK_CANCELLED')}</b>. <p>  <div class="label label-default">${this.formService.getTranslate('REGISTRATION.CONDITION_NEVER')}</div> ${this.formService.getTranslate('REGISTRATION.KEEP_TASK_OTHER_THAN')} <b>${this.formService.getTranslate('REGISTRATION.TASK_SCHEDULED')}</b>.`);
            this.$gridService.$customExportData = this.customData.bind(this);

        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initGridColumns(columns) {
        const columnDefs: IMonacoColumnDef[] = [];

        const view = `<a ng-click="grid.appScope.view(row.entity)" class="text-info"tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.edit(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copy(row.entity)" class="text-orange"   tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`
        const history = `<a ng-click="grid.appScope.viewLog(row.entity)" class="text-green" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>`;

        const actions =
            `<div class="text-center">
            ${view}
            ${edit}
            ${copy}
            ${history}
        </div>`;

        const actionsColDef: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (actions),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            allowCellFocus: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true,
            visible: true,
        }
        columnDefs.push(actionsColDef);

        const NewColumnDefs = this.buildColunms(columns);
        for (let column of NewColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; columnDefs.push(column) }
        return columnDefs;
    }

    buildColunms(column, prop?): any {

        try {
            const columnDefs: IMonacoColumnDef[] = [];
            for (var property in column) {
                if (typeof (column[property]) === 'string') { //finally reached the bottom
                    let currentProperty = (prop) ? `${prop}.${column[property]}` : column[property];
                    let name = null;
                    let displayName = null;
                    let width = 0;
                    let cellTemplate = undefined;
                    let cellFilter = null;
                    let visible = true;
                    let field = null;
                    let filter: uiGrid.IFilterOptions = null;
                    let searchProps = null;

                    switch (currentProperty.toUpperCase()) {
                        case 'TASK_NUMBER':
                            name = currentProperty;
                            displayName = 'GENERAL.NUMBER';
                            width = currentProperty.length;
                            break;
                        case 'TASK':
                            name = currentProperty + '.NAME';
                            displayName = 'GENERAL.TASK';
                            width = currentProperty.length + 5;
                            break;
                        case 'ACTIVE':
                            name = currentProperty;
                            displayName = 'GENERAL.ACTIVE';
                            width = currentProperty.length + 3;
                            cellFilter = "YesOrNo";
                            break;
                        case 'PRODUCT':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.PRODUCT';
                            width = currentProperty.length + 4;
                            break;
                        case 'TASK_STEP':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.STEP';
                            width = currentProperty.length + 3;
                            break;
                        case 'MODULE':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.MODULE';
                            width = currentProperty.length + 3;
                            break;
                        case 'MANDATORY_DETOUR':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.REQUIRED_FAULT_REASON';
                            width = currentProperty.length + 3;
                            break;
                        case 'RESPONSIBLE_CELL':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.RESPONSIBLE_CELL';
                            width = currentProperty.length + 3;
                            break;
                        case 'RESPONSIBLE_ROLE':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.RESPONSIBLE_ROLE';
                            width = currentProperty.length + 3;
                            break;
                        case 'RESPONSIBLE_USER':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.RESPONSIBLE_USER';
                            width = currentProperty.length + 3;
                            break;
                        case 'START.DEPENDENCY.TARGET_TYPE':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.DEADLINE_DEPENDENCE_TYPE_START';
                            width = currentProperty.length + 3;
                            break;
                        case 'START.DEPENDENCY.TARGET':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.DEADLINE_DEPENDENCE_START';
                            width = currentProperty.length + 3;
                            break;
                        case 'START.CALC_TYPE':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.DEADLINE_DEPENDENCE_DATE_START';
                            width = currentProperty.length + 3;
                            break;
                        case 'END.DEPENDENCY.TARGET_TYPE':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.DEADLINE_DEPENDENCE_TYPE_END';
                            width = currentProperty.length + 3;
                            break;
                        case 'END.DEPENDENCY.TARGET':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.DEADLINE_DEPENDENCE_END';
                            width = currentProperty.length + 3;
                            break;
                        case 'END.CALC_TYPE':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.DEADLINE_DEPENDENCE_DATE_END';
                            width = currentProperty.length + 3;
                            break;
                        case 'CLIENT.CRITERIA':
                            name = currentProperty;
                            field = `${currentProperty}.NAME`;
                            displayName = 'BASIC_DATA.CLIENT';
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.CLIENT.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'QUALIFICATION.CRITERIA':
                            name = currentProperty;
                            displayName = 'GENERAL.QUALIFICATION';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.QUALIFICATION.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'KAM.CRITERIA':
                            name = currentProperty;
                            field = `${currentProperty}.NAME`;
                            displayName = 'BASIC_DATA.KAM';
                            width = currentProperty.length + 4;
                            break;
                        case 'ORIGIN_AGENT.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.LOCAL_AGENT';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.ORIGIN_AGENT.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'DESTINATION_AGENT.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.OVERSEAS_AGENT';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.DESTINATION_AGENT.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'SERVICE_PROVIDER.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.PROVIDER';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.SERVICE_PROVIDER.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'TRANSPORTER_PICKUP.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.TRANSPORTER_PICKUP';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.TRANSPORTER_PICKUP.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'TRANSPORTER_PLACE_RECEPT.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.TRANSPORTER_PLACE_RECEPT';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.TRANSPORTER_PLACE_RECEPT.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'TRANSPORTER_FINAL_DESTINATION.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.TRANSPORTER_FINAL_DESTINATION';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.TRANSPORTER_FINAL_DESTINATION.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'TRANSPORTER_DELIVERY.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.TRANSPORTER_DELIVERY';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.TRANSPORTER_DELIVERY.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'TRANSPORTER_GATE_OUT_EMPTY.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.TRANSPORTER_GATE_OUT_EMPTY';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.TRANSPORTER_GATE_OUT_EMPTY.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'TRANSPORTER_CONTAINER_STUFFING.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.TRANSPORTER_CONTAINER_STUFFING';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.TRANSPORTER_CONTAINER_STUFFING.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'FLEXI_FITTING.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.FLEXI_FITTING';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.FLEXI_FITTING.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'ISOTANK_PROVIDER.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.ISOTANK_PROVIDER';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.ISOTANK_PROVIDER.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'EXTERNAL_BROKER.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.EXTERNAL_BROKER';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.EXTERNAL_BROKER.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'ORIGIN_GROUP.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.ORIGIN_TRADE_GROUP';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.ORIGIN_GROUP.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'ORIGIN_TRADE.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.ORIGIN_TRADE_LANE';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.ORIGIN_TRADE.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'ORIGIN_COUNTRY.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.ORIGIN_COUNTRY';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.ORIGIN_COUNTRY.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'ORIGIN_LOCAL.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.ORIGIN_LOCATION';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.ORIGIN_LOCAL.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'DESTINATION_GROUP.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.DESTINATION_TRADE_GROUP';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.DESTINATION_GROUP.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'DESTINATION_TRADE.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.DESTINATION_TRADE_LANE';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.DESTINATION_TRADE.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'DESTINATION_COUNTRY.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.DESTINATION_COUNTRY';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.DESTINATION_COUNTRY.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'DESTINATION_LOCAL.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.DESTINATION_LOCATION';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.DESTINATION_LOCAL.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'TRANSACTION.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.TRANSACTION';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.TRANSACTION.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'PAYMENT_METHOD.CRITERIA':
                            name = currentProperty;
                            displayName = 'FINANCIAL.PAYMENT_MEANS';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.PAYMENT_METHOD.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'PAYMENT_CONDITION.CRITERIA':
                            name = currentProperty;
                            displayName = 'GENERAL.INVOICE_PAYMENT_TERM';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.PAYMENT_CONDITION.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'CURRENCY_CONVERSION.CRITERIA':
                            name = currentProperty;
                            displayName = 'GENERAL.EXCHANGE_RATE_INDEX';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.CURRENCY_CONVERSION.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'PERIOD_VARIATION.CRITERIA':
                            name = currentProperty;
                            displayName = 'REGISTRATION.PERIOD_VARIATION';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.PERIOD_VARIATION.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'CONVERSION_REFERENCE.CRITERIA':
                            name = currentProperty;
                            displayName = 'REGISTRATION.EXC_RATE_REFERENCE_DATE';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.CONVERSION_REFERENCE.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'NATURE.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.PAYMENT_NATURE';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.NATURE.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'ANTICIPATED_INVOICE.CRITERIA':
                            name = currentProperty;
                            displayName = 'REGISTRATION.EARLY_RECEIPT';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.ANTICIPATED_INVOICE.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'INVOICE_STATUS.CRITERIA':
                            name = currentProperty;
                            displayName = 'FINANCIAL.BILLING_STATUS';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.INVOICE_STATUS.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'INVOICE_FINANCIAL_STATUS.CRITERIA':
                            name = currentProperty;
                            displayName = 'FINANCIAL.FINANCIAL_STATUS';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.INVOICE_FINANCIAL_STATUS.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'FORWARDED_BY.CRITERIA':
                            name = currentProperty;
                            displayName = 'GENERAL.FORWARDED_BY';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.FORWARDED_BY.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'PROCESS_TYPE.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.FILE_TYPE';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.PROCESS_TYPE.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'COMPANY.CRITERIA':
                            name = currentProperty;
                            displayName = 'GENERAL.COMPANY';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.COMPANY.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'PRIORITY.CRITERIA':
                            name = currentProperty;
                            displayName = 'REGISTRATION.PRIORITY';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.PRIORITY.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'OPERATIONAL_SITUATION.CRITERIA':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.FILE_OPERATIONAL_STATUS';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.OPERATIONAL_SITUATION.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'DIRECT_BL.CRITERIA':
                            name = currentProperty;
                            field = `${currentProperty}.NAME`;
                            displayName = 'BASIC_DATA.STRAIGHT_BL';
                            width = currentProperty.length + 4;
                            break;
                        case 'CARGO_TYPE.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.CARGO_TYPE';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.CARGO_TYPE.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'DANGER_CARGO.CRITERIA':
                            name = currentProperty;
                            field = `${currentProperty}.NAME`;
                            displayName = 'BASIC_DATA.DANGEROUS_CARGO';
                            width = currentProperty.length + 4;
                            break;
                        case 'INSURANCE.CRITERIA':
                            name = currentProperty;
                            field = `${currentProperty}.NAME`;
                            displayName = 'FINANCIAL.INSURANCE';
                            width = currentProperty.length + 4;
                            break;
                        case 'DTA.CRITERIA':
                            name = currentProperty;
                            field = `${currentProperty}.NAME`;
                            displayName = 'REGISTRATION.BONDED_TRUCK';
                            width = currentProperty.length + 4;
                            break;
                        case 'STATUS_BOOKING.CRITERIA':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.BOOKING_STATUS';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.STATUS_BOOKING.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'INCOTERM.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.INCOTERM';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.INCOTERM.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'MASTER_PAYMENT.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.MASTER_PAYMENT_MODE';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.MASTER_PAYMENT.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'HOUSE_PAYMENT.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.HOUSE_PAYMENT_MODE';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.HOUSE_PAYMENT.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'SERVICE_MOVEMENT.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.MOVE_TYPE';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.SERVICE_MOVEMENT.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'EQUIPMENT.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.EQUIPMENT';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.EQUIPMENT.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'SERVICE_TYPE.CRITERIA':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.SERVICE_TYPE';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.SERVICE_TYPE.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'BOOKING_TYPE.CRITERIA':
                            name = currentProperty;
                            displayName = 'GENERAL.BOOKING_TYPE';
                            field = `${currentProperty}.NAME`;
                            cellTemplate = '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.BOOKING_TYPE.CRITERIA) }}</div>';
                            width = currentProperty.length + 4;
                            break;
                    };

                    const newColumn: IMonacoColumnDef = {
                        name: name,
                        displayName: displayName,
                        headerCellClass: this.$gridService.highlightFilteredHeader.bind(this.$gridService),
                        width: width + '%',
                        cellTemplate: cellTemplate,
                        cellFilter: cellFilter,
                        visible: visible,
                        filter: filter,
                        searchProps: searchProps,
                    };
                    if (field) newColumn.field = field;
                    if (name) columnDefs.push(newColumn);
                } else {
                    const composedPropPath = (prop) ? (isNaN(parseInt(property))) ? `${prop}.${property}` : prop : (isNaN(parseInt(property))) ? property : null;
                    const newCol = this.buildColunms(column[property], composedPropPath);
                    if (newCol)
                        for (let dados of newCol) {
                            columnDefs.push(dados);
                        }
                }
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }


    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            ACTIVE: true,
            TASK_NUMBER: null,
            TASK: null,
            TASK_STEP: null,
            MODULE: null,
            MANDATORY_DETOUR: null,
            DETOUR_LIST: null,
            RESPONSIBLE_CELL: null,
            RESPONSIBLE_ROLE: null,
            RESPONSIBLE_USER: null,
            PRODUCT: null,
            FORWARDED_BY: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            PROCESS_TYPE: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            COMPANY: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            PRIORITY: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            OPERATIONAL_SITUATION: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            DIRECT_BL: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            CARGO_TYPE: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            DANGER_CARGO: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            INSURANCE: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            DTA: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            STATUS_BOOKING: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            INCOTERM: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            MASTER_PAYMENT: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            HOUSE_PAYMENT: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            SERVICE_MOVEMENT: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            EQUIPMENT: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            SERVICE_TYPE: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            BOOKING_TYPE: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            CLIENT: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            QUALIFICATION: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            KAM: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            ORIGIN_AGENT: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            DESTINATION_AGENT: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            SERVICE_PROVIDER: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            TRANSPORTER_PICKUP: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            TRANSPORTER_PLACE_RECEPT: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            TRANSPORTER_FINAL_DESTINATION: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            TRANSPORTER_DELIVERY: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            TRANSPORTER_GATE_OUT_EMPTY: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            TRANSPORTER_CONTAINER_STUFFING: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            FLEXI_FITTING: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            ISOTANK_PROVIDER: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            EXTERNAL_BROKER: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            ORIGIN_GROUP: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            ORIGIN_TRADE: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            ORIGIN_COUNTRY: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            ORIGIN_LOCAL: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            DESTINATION_GROUP: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            DESTINATION_TRADE: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            DESTINATION_COUNTRY: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            DESTINATION_LOCAL: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            TRANSACTION: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            PAYMENT_METHOD: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            PAYMENT_CONDITION: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            CURRENCY_CONVERSION: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            PERIOD_VARIATION: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            CONVERSION_REFERENCE: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            NATURE: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            ANTICIPATED_INVOICE: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            INVOICE_STATUS: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            INVOICE_FINANCIAL_STATUS: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            PEOPLE: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            REFRIGERATED_CARGO: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            CARGO_TREATMENT: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            FREIGHT_CONTRACT_SERVICE_LEVEL: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            FREIGHT_CONTRACT_SERVICE_TYPE: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            THIRD_AGENT: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            BROKER: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            START: {
                DEPENDENCY: {
                    TARGET_TYPE: null,
                    TARGET_CLASS: null,
                    TARGET: null,
                    TARGET_PARAM: null,
                    TARGET_PARAM_TYPE: null,
                    TARGET_GENERIC_IDENTIFIER: null,
                    TARGET_TRACKER: null,
                },
                CALC_TYPE: null,
                DEADLINE_HOURS: null,
                DEADLINE_MINUTES: null,
                HOUR_TYPE: null,
                HOUR_CONCLUSION: null,
                FIXED_HOURS: null,
                FIXED_MINUTES: null,
            },
            END: {
                DEPENDENCY: {
                    TARGET_TYPE: null,
                    TARGET_CLASS: null,
                    TARGET: null,
                    TARGET_PARAM: null,
                    TARGET_PARAM_TYPE: null,
                    TARGET_GENERIC_IDENTIFIER: null,
                    TARGET_TRACKER: null,
                },
                CALC_TYPE: null,
                DEADLINE_HOURS: null,
                DEADLINE_MINUTES: null,
                HOUR_TYPE: null,
                HOUR_CONCLUSION: null,
                FIXED_HOURS: null,
                FIXED_MINUTES: null,
            },
            RECALC: {
                RECALCULATE_TASK: null,
                RECALCULATE_TASK_SITUATION: null,
                RESTORE: null,
                PROCESS_SITUATION: null,
            },
            TRIGGER: null,
            TARGET_GROUP: null,
            INTEGRATION_PARAM: [],
        };
    }

    async initDependencies(): Promise<boolean> {
        try {
            const self: TaskParamRegisterController = this;
            self.$scope.serviceType = await self.getMoveTypeList();
            self.$scope.equipmentList = await self.getEquipmentList();
            self.$scope.incotermList = await self.getIncotermList();
            self.$scope.serviceTypeList = await self.getServiceTypeList();
            self.$scope.serviceLevelList = await self.getServiceLevelList();

            return new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getGenericList('task_options'),
                    self.getGenericList('hour_type'),
                    self.getGenericList('consecutive_hour_options'),
                    self.getGenericList('task_recalc_when_options'),
                    self.getGenericList('task_status'),
                    self.getGenericList('task_restore_options'),
                    self.getGenericList('status_process'),
                    self.getGenericList('product'),
                    self.getGenericList('forwarded_by'),
                    self.getGenericList('process_type'),
                    self.getGenericList('priority'),
                    self.getGenericList('type_cargo'),
                    self.getGenericList('booking_status'),
                    self.getGenericList('type_payment'),
                    self.getCellList(),
                    self.getGenericList('transaction'),
                    self.getGenericList('exchange_currency_conversion'),
                    self.getGenericList('payment_condition'),
                    self.getGenericList('payment_method'),
                    self.getGenericList('conversion_reference'),
                    self.getGenericList('payment_nature'),
                    self.getGenericList('task_process_steps'),
                    self.getGenericList('task_module'),
                    self.getGenericList('contract_type'),
                    self.getGenericList('account_qualification'),
                    self.getGenericList('locals_group'),
                    self.getGenericList('invoice_status'),
                    self.getGenericList('invoice_financial_situation'),
                    self.getGenericList('begin_end_type_options'),
                    self.getHotspotList(),
                    self.getGenericList('task_hotspot_target'),
                    self.getGenericList('process_document_type'),
                    self.getGenericList('event'),
                    self.getGenericList('detour_options'),
                    self.getGenericList('detours'),
                    self.getGenericList('task_integration_param'),
                    self.getGenericList('air_cargo_treatment'),
                ]).then((result: any) => {
                    self.$scope.taskOptionsList = result[0];
                    self.$scope.hourTypeList = result[1];
                    self.$scope.consecutiveHourList = result[2];
                    self.$scope.taskRecalcList = result[3];
                    self.$scope.taskStatusList = result[4];
                    self.$scope.taskRestoreList = result[5];
                    self.$scope.processStatusList = result[6];
                    self.$scope.productList = result[7];
                    self.$scope.forwardedByList = result[8];
                    self.$scope.processTypeList = result[9];
                    self.$scope.priorityList = result[10];
                    self.$scope.cargoTypeList = result[11];
                    self.$scope.bookingStatusList = result[12];
                    self.$scope.paymentTypeList = result[13];
                    self.$scope.cellList = result[14].data;
                    self.$scope.transactionList = result[15];
                    self.$scope.currencyExchangeList = result[16];
                    self.$scope.paymentConditionList = result[17];
                    self.$scope.paymentMethodList = result[18];
                    self.$scope.conversionReferenceList = result[19];
                    self.$scope.natureList = result[20];
                    self.$scope.taskStepList = result[21];
                    self.$scope.moduleList = result[22];
                    self.$scope.contractBookingType = result[23];
                    self.$scope.qualificationList = result[24];
                    self.$scope.groupList = result[25];
                    self.$scope.invoiceStatusList = result[26];
                    self.$scope.invoiceFinancialStatusList = result[27];
                    self.$scope.calcTypeList = result[28];
                    self.hotspotList = result[29].data;
                    self.$scope.targetTypeList = result[30];
                    self.$scope.dependencyTypeList = result[30];
                    self.$scope.processDocumentTypeList = result[31];
                    self.$scope.eventList = result[32];
                    self.$scope.detourMandatoryList = result[33];
                    self.$scope.detourList = result[34];
                    self.$scope.integrationList = result[35];
                    self.$scope.cargoTreatmentList = result[36];
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            throw ex;
        }
    }

    initScopeFunctions(): void {
        this.$scope.initPanels = (): void => {
            this.initPanels();
        }
        this.$scope.collapseHeader = (elementId: string, state?: string): void => {
            this.collapseHeader(elementId, state);
        }
        this.$scope.refreshCellDependencyList = (selectedCell: CellModel): Promise<void> => {
            return this.refreshCellDependencyList(selectedCell);
        }
        this.$scope.refreshLocals = async (query: string, scopeProp: string): Promise<void> => {
            return this.refreshLocals(query, scopeProp);
        }
        this.$scope.refreshCountry = async (search: string, scopeProp: string): Promise<void> => {
            return this.refreshCountry(search, scopeProp);
        }
        this.$scope.refreshTradeLine = async (query: string, scopeProp: string): Promise<void> => {
            return this.refreshTradeLine(query, scopeProp);
        }

        this.$scope.changeProduct = (): void => {
            this.$scope.serviceProviderList = [];
            this.$scope.model.SERVICE_PROVIDER = {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            };
        }
        this.$scope.changeOriginDestinationEnable = (changedProp: string): void => {
            const model = this.$scope.model;
            const props =
                (changedProp.includes('ORIGIN')) ?
                    ['ORIGIN_GROUP', 'ORIGIN_TRADE', 'ORIGIN_COUNTRY', 'ORIGIN_LOCAL']
                    :
                    ['DESTINATION_GROUP', 'DESTINATION_TRADE', 'DESTINATION_COUNTRY', 'DESTINATION_LOCAL'];

            if (model[changedProp]['STATUS']) {
                model[changedProp]['STATUS'] = false;
            } else {
                for (const prop of props) {
                    model[prop]['STATUS'] = false;
                    model[prop]['CRITERIA'] = null;
                }
                model[changedProp]['STATUS'] = !model[changedProp]['STATUS'];
            }
        }
        this.$scope.changeHourConsecutive = (type: string): void => {
            const model = this.$scope.model;
            if (type === 'start' && model.START) {
                model.START.FIXED_HOURS = null;
                model.START.FIXED_MINUTES = null;
            } else if (type === 'end' && model.END) {
                model.END.FIXED_HOURS = null;
                model.END.FIXED_MINUTES = null;
            } else return;
        }
        this.$scope.addIntegrationParam = (): void => {
            this.addIntegrationParam();
        }
        this.$scope.removeIntegrationParam = (index: number): void => {
            this.removeIntegrationParam(index);
        }
        this.$scope.addTaskTrigger = (): void => {
            this.addTaskTrigger();
        }
        this.$scope.removeTaskTrigger = (index: number): void => {
            this.removeTaskTrigger(index);
        }
        this.$scope.addTargetGroup = (): void => {
            this.addTargetGroup();
        }
        this.$scope.removeTargetGroup = (index: number): void => {
            this.removeTargetGroup(index);
        }
        this.$scope.addTarget = (targetGroupIndex): void => {
            this.addTarget(targetGroupIndex);
        }
        this.$scope.removeTarget = (parentIndex: number, index: number): void => {
            this.removeTarget(parentIndex, index);
        }
        this.$scope.validateDuplicateTrigger = (index: number): void => {
            this.validateDuplicateTrigger(index);
        }
        this.$scope.validateDuplicateHotspotTarget = (parentIndex: number, index: number): void => {
            this.validateDuplicateHotspotTarget(parentIndex, index);
        }
        this.$scope.validateHotspotTargetEvent = (parentIndex: number): void => {
            this.validateHotspotTargetEvent(parentIndex);
        }
        this.$scope.changeHotspotTargetType = (parentIndex: number, index: number): Promise<void> => {
            const currentChanged = this.$scope.model.TARGET_GROUP[parentIndex].TARGETS[index];
            currentChanged.TARGET = null;
            currentChanged.TARGET_CLASS = null;
            currentChanged.TARGET_PARAM = null;
            return this.changeHotspotTargetType(parentIndex, index);
        }
        this.$scope.changeHotspotTarget = (hotspotID: string, parentIndex: number, index: number): Promise<void> => {
            const currentChanged = this.$scope.model.TARGET_GROUP[parentIndex].TARGETS[index];
            currentChanged.TARGET_PARAM = null;
            currentChanged.TARGET_CLASS = null;
            return this.changeHotspotTarget(hotspotID, parentIndex, index);
        }
        this.$scope.changeDependencyType = (dependency: string): Promise<void> => {
            if (dependency === 'start') {
                this.$scope.model.START.DEPENDENCY.TARGET = null;
                this.$scope.model.START.DEPENDENCY.TARGET_PARAM = null;
                this.$scope.model.START.DEPENDENCY.TARGET_CLASS = null;
            }
            else if (dependency === 'end') {
                this.$scope.model.END.DEPENDENCY.TARGET = null;
                this.$scope.model.END.DEPENDENCY.TARGET_PARAM = null;
                this.$scope.model.END.DEPENDENCY.TARGET_CLASS = null;
            }
            return this.changeDependencyType(dependency);
        }
        this.$scope.changeDependency = (dependency: string): Promise<void> => {
            if (dependency === 'start') {
                this.$scope.model.START.DEPENDENCY.TARGET_PARAM = null;
                this.$scope.model.START.DEPENDENCY.TARGET_CLASS = null;
            }
            else if (dependency === 'end') {
                this.$scope.model.END.DEPENDENCY.TARGET_PARAM = null;
                this.$scope.model.END.DEPENDENCY.TARGET_CLASS = null;
            }
            return this.changeDependency(dependency);
        }

        this.$scope.addDetour = (): void => {
            this.addDetour();
        }

        this.$scope.removeDetour = (index: number): void => {
            this.removeDetour(index);
        }

        this.$scope.getPeopleList = async (query) => {
            let peopleList = [];
            if (query && query.length >= 2) {
                peopleList = await this.getPeopleList(query);
            }
            this.$scope.peopleList = peopleList;
        }

        this.$scope.getNotificationTypeData = async () => {
            let notificationTypeList = [];
            notificationTypeList = await this.getNotificationTypeData();
            this.$scope.notificationTypeList = notificationTypeList;
        }

        this.$scope.getBranchListByName = async (term: string): Promise<void> => {
            this.$scope.branchesList = [];
            if (term && term.length >= 3) this.$scope.branchesList = await this.getCorporateBranchListByName(term, this.$scope.model.PRODUCT);
        }

        this.$scope.getAccountListByName = async (term: string): Promise<void> => {
            return await this.getAccountListByName(term);
        }

        this.$scope.getAgentListByName = async (term: string): Promise<void> => {
            return await this.getAgentListByName(term);
        }

        this.$scope.getServiceProviderListByName = async (term: string): Promise<void> => {
            return await this.getServiceProviderListByName(term);
        }

        this.$scope.getTransporterListByName = async (term: string): Promise<void> => {
            this.$scope.transporterList = [];
            if (term && term.length >= 3) this.$scope.transporterList = await this.getLegalPersonListByName({ specializations: null, search: term });
        }

        this.$scope.getBrokerOperationalListByName = async (search: string): Promise<void> => {
            return await this.getExternalBrokerListByName(search);
        }
    }

    private async getGenericList(type: string, alternative: boolean = false): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}/${alternative}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async getCellList() {
        const result = await this.OperationalService.get(`/cell/list/`, null, 12000);
        return result.data.data;
    }

    private async getTradeLaneList(search: string): Promise<SelectorModel[]> {
        try {
            let tradeList = [];
            const request: IMonacoRequest = {
                data: {
                    search
                },
                route: `/tradelane/list/custom`,
                timeout: 30000,
            }
            const result = await this.ProductService.post(request)
            if (result && result.data && result.status == 200) {
                const tradeResult: ITradeLaneSelector[] = result.data.data.data;
                for (const resultItem of tradeResult) {
                    const tradeLane: SelectorModel = { ID: resultItem.ID, CODE: resultItem.CODE, NAME: resultItem.NAME }
                    tradeList.push(tradeLane);
                }
            }
            return tradeList;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getCountries(search: string): Promise<SelectorModel[]> {
        try {
            let countryList = []
            const request: IMonacoRequest = {
                data: {
                    search
                },
                route: `/country/list/custom`,
                timeout: 30000,
            }
            const result = await this.ProductService.post(request)
            if (result && result.data && result.status == 200) {
                const countryResult = result.data.data;
                for (const resultItem of countryResult) {
                    const country: ISelectorModel = { ID: resultItem.ID, CODE: resultItem.CODE, NAME: resultItem.NAME }
                    countryList.push(country);
                }
            }
            return countryList
        } catch (ex) {
            this.handleError(ex);
        }
    }



    private async getHotspotList(): Promise<any> {
        const result = await this.TaskService.post<TaskHotspot>({ route: `/hotspot/list`, timeout: 10000 });
        if (!result || !result.data) return this.handleError(result);
        return result.data.data;
    }

    private async getIncotermList(): Promise<SelectorModel[]> {
        try {
            let incotermList: SelectorModel[] = [];
            const request: IMonacoRequest = {
                data: null,
                route: `/incoterm/list`,
                timeout: 30000,
            }
            const result = await this.ProductService.post(request)
            if (result && result.data && result.status == 200) {
                const providerList: IIncotermSelector[] = result.data.data.data;
                for (const resultItem of providerList) {
                    const incoterm: ISelectorModel = { ID: resultItem.ID, CODE: resultItem.INITIALS, NAME: resultItem.NAME }
                    incotermList.push(incoterm);
                }
            }
            return incotermList;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getCorporateBranchListByName(search?: string, product?: SelectorModel): Promise<SelectorModel[]> {
        try {
            this.block();
            const request: IMonacoRequest = {
                data: {
                    search,
                    products: product ? [product.ID] : null
                },
                route: `/corporateBranch/list/custom`,
                timeout: 30000,
            };

            const result = await this.ProductService.post(request);
            const corporateBranchResult: ISelectorModel[] = [];
            if (result && result.data && result.status == 200) {
                const corporateBranchList: SelectorModel[] = result.data.data;
                for (const resultItem of corporateBranchList) {
                    const corporateBranch: ISelectorModel = { ID: resultItem.ID, CODE: resultItem.CODE, NAME: resultItem.NAME }
                    corporateBranchResult.push(corporateBranch);
                }
            }
            this.unblock();

            return corporateBranchResult;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getServiceTypeList(): Promise<SelectorModel[]> {
        try {
            let serviceTypeList: SelectorModel[] = [];
            const request: IMonacoRequest = {
                data: null,
                route: `/servicetype/list`,
                timeout: 30000,
            }
            const result = await this.ProductService.post(request)
            if (result && result.data && result.status == 200) {
                const serviceTypeListResult = result.data.data.data;
                for (const resultItem of serviceTypeListResult) {
                    const serviceTypeSelectModel: ISelectorModel = { ID: resultItem.ID, CODE: resultItem.NAME, NAME: resultItem.DISPLAY_NAME }
                    serviceTypeList.push(serviceTypeSelectModel);
                }
            }
            return serviceTypeList;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getServiceLevelList(): Promise<SelectorModel[]> {
        try {
            let serviceLevelList: SelectorModel[] = [];
            const request: IMonacoRequest = {
                data: null,
                route: `/servicelevel/list`,
                timeout: 30000,
            }
            const result = await this.ProductService.post(request)
            if (result && result.data && result.status == 200) {
                const serviceLevelListResult = result.data.data.data;
                for (const resultItem of serviceLevelListResult) {
                    const serviceLevelSelectModel: ISelectorModel = { ID: resultItem.ID, CODE: resultItem.NAME, NAME: resultItem.DISPLAY_NAME }
                    serviceLevelList.push(serviceLevelSelectModel);
                }
            }
            return serviceLevelList;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getLegalPersonListByName(filter: ILegalPersonListCustomFilter): Promise<SelectorModel[]> {
        try {
            this.block();
            const request: IMonacoRequest = {
                data: {
                    ...filter
                },
                route: `/legalPerson/list/custom`,
                timeout: 30000,
            }
            const result = await this.ProductService.post(request)
            const legalPersonResult: ISelectorModel[] = [];
            if (result && result.data && result.status == 200) {
                const legalPersonList: ILegalPersonSelector[] = result.data.data;
                for (const resultItem of legalPersonList) {
                    const legalPerson: ISelectorModel = { ID: resultItem.ID, CODE: resultItem.CORPORATE_NAME, NAME: resultItem.SHORT_NAME }
                    legalPersonResult.push(legalPerson);
                }
            }
            this.unblock();
            return legalPersonResult
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getMoveTypeList(): Promise<SelectorModel[]> {
        try {
            let moveTypeList: SelectorModel[] = [];

            const request: IMonacoRequest = {
                data: null,
                route: `/moveType/list/full`,
                timeout: 30000,
            }

            const result = await this.ProductService.get(request);
            if (result && result.data && result.status == 200) {
                const moveTypeResult: IMoveTypeModel[] = result.data.data;
                for (const resultItem of moveTypeResult) {
                    const moveType: SelectorModel = { ID: (resultItem.ID ? resultItem.ID.toString() : null), CODE: resultItem.CODE, NAME: resultItem.NAME }
                    moveTypeList.push(moveType);
                }
            }
            return moveTypeList;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getEquipmentList(): Promise<SelectorModel[]> {
        try {
            let equipmentList: SelectorModel[] = [];

            const request: IMonacoRequest = {
                data: null,
                route: `/equipment/list`,
                timeout: 30000,
            }

            const result = await this.ProductService.post(request);
            if (result && result.data && result.status == 200) {
                const equipmentResult = result.data.data.data;
                for (const resultItem of equipmentResult) {
                    const equipment: SelectorModel = { ID: resultItem.ID, CODE: resultItem.CODE, NAME: resultItem.NAME }
                    equipmentList.push(equipment);
                }
            }

            return equipmentList
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getAccountListByName(search: string): Promise<void> {
        try {
            this.$scope.accountList = []

            if (search && search.length >= 3) {
                this.block();
                const request: IMonacoRequest = {
                    data: {
                        search
                    },
                    route: `/account/list/custom`,
                    timeout: 30000,
                }
                const result = await this.ProductService.post(request)
                if (result && result.data && result.status == 200) {
                    const accountList: IAccountSelector[] = result.data.data.data;
                    for (const resultItem of accountList) {
                        const account: ISelectorModel = { ID: resultItem.ID, CODE: (resultItem.LEGAL_PERSON && resultItem.LEGAL_PERSON.ID_FISCAL) ? resultItem.LEGAL_PERSON.ID_FISCAL : null, NAME: resultItem.NAME }
                        this.$scope.accountList.push(account);
                    }
                }
                this.unblock();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getAgentListByName(search: string): Promise<void> {
        try {
            this.$scope.agentList = []
            if (search && search.length >= 3) {
                this.block();

                const productList = (this.$scope.model.PRODUCT) ? [this.$scope.model.PRODUCT.ID] : [];
                const filter: IAgentListCustomFilter = { search: search, networks: null, products: productList }
                const request: IMonacoRequest = {
                    data: {
                        ...filter
                    },
                    route: `/agent/list/custom`,
                    timeout: 30000,
                }
                const result = await this.ProductService.post(request)
                if (result && result.data && result.status == 200) {
                    const agentList: IAgentSelector[] = result.data.data.data;
                    for (const resultItem of agentList) {
                        const legalPerson: ISelectorModel = { ID: resultItem.ID, NAME: resultItem.NAME, CODE: resultItem.NETWORK_ID }
                        this.$scope.agentList.push(legalPerson);
                    }
                }
                this.unblock();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getServiceProviderListByName(search: string): Promise<void> {
        try {
            this.$scope.serviceProviderList = [];
            if (search && search.length >= 3) {
                this.block();

                const request: IMonacoRequest = {
                    data: {
                        search
                    },
                    route: `/provider/list/custom`,
                    timeout: 30000,
                }
                const result = await this.ProductService.post(request)
                if (result && result.data && result.status == 200) {
                    const providerList: IServiceProviderSelector[] = result.data.data;
                    for (const resultItem of providerList) {
                        const serviceProvider: ISelectorModel = { ID: resultItem.ID, CODE: resultItem.SCAC_IATA, NAME: resultItem.NAME }
                        this.$scope.serviceProviderList.push(serviceProvider);
                    }
                }
                this.unblock();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getExternalBrokerListByName(search: string): Promise<void> {
        try {
            this.$scope.externalBrokerList = []
            if (search && search.length >= 3) {
                this.block();

                const productList = (this.$scope.model.PRODUCT) ? [this.$scope.model.PRODUCT.ID] : [];
                const filter: IBrokerListCustomFilter = { search: search, products: productList }
                const request: IMonacoRequest = {
                    data: {
                        ...filter
                    },
                    route: `/broker/list/custom/operational`,
                    timeout: 30000,
                }
                const result = await this.ProductService.post(request)
                if (result && result.data && result.status == 200) {
                    const externalBrokerList: ISelectorModel[] = result.data.data.data;
                    for (const resultItem of externalBrokerList) {
                        const broker: ISelectorModel = { ID: resultItem.ID, NAME: resultItem.NAME, CODE: resultItem.CODE }
                        this.$scope.externalBrokerList.push(broker);
                    }
                }
                this.unblock();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getRoutingPointListByName(name?: string, _types?: string[]): Promise<SelectorModel[]> {
        try {
            let routingList: SelectorModel[] = [];
            if (name && name.length >= 3) {
                this.block();

                const types = (_types) ? _types : this.getRoutingPointTypeByModal();

                const filter = { name, types }
                const request: IMonacoRequest = {
                    data: {
                        ...filter
                    },
                    route: `/routingPoint/list/custom`,
                    timeout: 30000,
                }
                const result = await this.ProductService.post(request)
                if (result && result.data && result.data.data && result.status == 200) {
                    const localResult: IRoutingPointSelector[] = result.data.data.data;
                    for (const resultItem of localResult) {
                        const serviceProvider: SelectorModel = { ID: resultItem.ID.toString(), CODE: resultItem.CODE, NAME: resultItem.NAME }
                        routingList.push(serviceProvider);
                    }
                }
                this.unblock();
            }

            return routingList;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getRoutingPointTypeByModal() {
        try {
            let routing: string[] = [];
            if (this.$scope.model.PRODUCT) {
                switch (this.$scope.model.PRODUCT.ID) {
                    case 'EM':
                    case 'IM':
                        routing.push(OperationModal.MARITIME);
                        break;

                    case 'IR':
                    case 'ER':
                        routing.push(OperationModal.ROAD);
                        break;

                    case 'IA':
                    case 'EA':
                        routing.push(OperationModal.AIR);
                        break;

                    default:
                        break;
                }
            }

            return routing;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getPeopleList(search: string): Promise<SelectorModel[]> {
        try {
            const result = await this.getLegalPersonListByName({ specializations: null, search: search });
            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getNotificationTypeData() {
        let result: SelectorModel[] = [];
        try {
            const externalReq: IMonacoRequest = {
                route: `/notificationType/list`,
                data: {},
                timeout: 120000
            }
            const notificationTypeList = await this.ExternalService.post<any>(externalReq);
            if (notificationTypeList && notificationTypeList.data && notificationTypeList.data.data) {
                const _result = notificationTypeList.data.data.data;
                result = _result
            }

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async refreshCellDependencyList(selectedCell: CellModel) {
        try {
            if (!selectedCell || !selectedCell.GROUP) return;

            this.$scope.userList = [];
            this.$scope.roleList = selectedCell.GROUP.filter(x => x.ROLE_REFERENCE).map(x => { return { ID: x.ROLE_REFERENCE.ID.toString(), NAME: x.ROLE_REFERENCE.NAME } });

            for (const cellGroup of selectedCell.GROUP) {
                if (!cellGroup.USER) continue;
                this.$scope.userList = this.$scope.userList.concat(cellGroup.USER.map(x => { return { ID: x.USER_REFERENCE.email, NAME: x.USER_REFERENCE.displayName } }));
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async refreshLocals(query: string, scopeProp: string): Promise<void> {
        try {
            this.$scope[scopeProp] = [];
            if (query.length >= 3) {
                const result = await this.getRoutingPointListByName(query);
                if (result && result.length > 0) {
                    this.$scope[scopeProp] = result;
                    await this.$scope.$applyAsync();
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async refreshCountry(search: string, scopeProp: string): Promise<void> {
        try {
            this.$scope[scopeProp] = [];
            if (search && search.length >= 3) {
                const result = await this.getCountries(search);
                if (result) {
                    this.$scope[scopeProp] = result;
                    await this.$scope.$applyAsync();
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async refreshTradeLine(query: string, scopeProp: string): Promise<void> {
        try {
            this.$scope[scopeProp] = [];
            if (query.length >= 3) {
                const result = await this.getTradeLaneList(query);
                if (result) {
                    this.$scope[scopeProp] = result;
                    await this.$scope.$applyAsync();
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private addTaskTrigger(): void {
        try {
            if (!this.$scope.model.TRIGGER) this.$scope.model.TRIGGER = [];
            const newTrigger: TRIGGER = {
                CURRENT_SITUATION: null,
                GENERATE_TASK: null,
                SITUATION_TO_UPDATE_TO: null,
                TASK_TO_UPDATE: null,
            }
            this.$scope.model.TRIGGER.push(newTrigger);
            const self: TaskParamRegisterController = this;
            this.$timeout(() => {
                for (let i = 0; i < this.$scope.model.TRIGGER.length; i++) {
                    self.$scope.selectorValidity(`currentSituation${i}`);
                    self.$scope.selectorValidity(`taskToUpdate${i}`);
                    self.$scope.selectorValidity(`situationToUpdateTo${i}`);
                    self.$scope.selectorValidity(`generateTask${i}`);
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeTaskTrigger(index: number): Promise<void> {
        try {
            this.$scope.model.TRIGGER.splice(index, 1);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private addTargetGroup(): void {
        try {
            if (!this.$scope.model.TARGET_GROUP) this.$scope.model.TARGET_GROUP = [];
            const newTargetGroup: TARGET_GROUP = {
                ID: null,
                TARGET_COMBO_SITUATION_HIT: null,
                TARGET_COMBO_SITUATION_MISS: null,
                TARGETS: null,
            }
            this.$scope.model.TARGET_GROUP.push(newTargetGroup);
            const self: TaskParamRegisterController = this;
            this.$timeout(() => {
                for (let i = 0; i < this.$scope.model.TARGET_GROUP.length; i++) {
                    self.$scope.selectorValidity(`taskHitStatus${i}`);
                    self.$scope.selectorValidity(`taskMissStatus${i}`);
                    self.$scope.selectorValidity(`taskTargetGenerateTask${i}`);
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeTargetGroup(index: number): Promise<void> {
        try {
            this.$scope.model.TARGET_GROUP.splice(index, 1);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private addTarget(targetGroupIndex): void {
        try {
            if (!this.$scope.model.TARGET_GROUP[targetGroupIndex].TARGETS) this.$scope.model.TARGET_GROUP[targetGroupIndex].TARGETS = [];
            const newTarget: TARGETS = {
                ID: null,
                TARGET_TYPE: null,
                TARGET_CLASS: null,
                TARGET: null,
                TARGET_PARAM: null,
                TARGET_PARAM_TYPE: null,
                TARGET_GENERIC_IDENTIFIER: null,
                TARGET_TRACKER: null,
            }
            this.$scope.model.TARGET_GROUP[targetGroupIndex].TARGETS.push(newTarget);
            const self: TaskParamRegisterController = this;
            this.$timeout(() => {
                for (let i = 0; i < this.$scope.model.TARGET_GROUP[targetGroupIndex].TARGETS.length; i++) {
                    self.$scope.selectorValidity(`targetType${targetGroupIndex}${i}`);
                    self.$scope.selectorValidity(`target${targetGroupIndex}${i}`);
                    self.$scope.selectorValidity(`targetParam${targetGroupIndex}${i}`);
                    self.$scope.selectorValidity(`targetClass${targetGroupIndex}${i}`);
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeTarget(parentIndex: number, index: number): Promise<void> {
        try {
            if (this.$scope.model.TARGET_GROUP[parentIndex])
                this.$scope.model.TARGET_GROUP[parentIndex].TARGETS.splice(index, 1);

            await this.prepareHotspotType();

        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async validateDuplicateTrigger(index: number): Promise<void> {
        try {
            const currentChanged = this.$scope.model.TRIGGER[index];
            if (!currentChanged.CURRENT_SITUATION || !currentChanged.TASK_TO_UPDATE) return;

            const triggerWithSameConfig = this.$scope.model.TRIGGER.filter(x =>
                x.CURRENT_SITUATION.ID === currentChanged.CURRENT_SITUATION.ID &&
                x.TASK_TO_UPDATE.ID === currentChanged.TASK_TO_UPDATE.ID
            );

            if (triggerWithSameConfig.length > 1) {
                currentChanged.CURRENT_SITUATION = null;
                currentChanged.TASK_TO_UPDATE = null;

                const self: TaskParamRegisterController = this;
                this.$timeout(() => {
                    self.$scope.selectorValidity(`currentSituation${index}`);
                    self.$scope.selectorValidity(`taskToUpdate${index}`);
                });
                const msg = this.formService.getTranslate('REGISTRATION.DOUBLE_TRIGGER');
                this.handleWarning(msg);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async validateDuplicateHotspotTarget(parentIndex: number, index: number): Promise<void> {
        try {
            const currentChanged = this.$scope.model.TARGET_GROUP[parentIndex].TARGETS[index];
            if (!currentChanged.TARGET_TYPE || !currentChanged.TARGET || !currentChanged.TARGET_PARAM) return;

            const allTargetLines = this.$scope.model.TARGET_GROUP.reduce((acc, val) => acc.concat(val.TARGETS), <TARGETS[]>[]);

            const targetWithSameConfig = allTargetLines.filter(x =>
                x.TARGET_TYPE.ID === currentChanged.TARGET_TYPE.ID &&
                (!currentChanged.TARGET_CLASS || x.TARGET_CLASS.ID === currentChanged.TARGET_CLASS.ID) &&
                x.TARGET.ID === currentChanged.TARGET.ID &&
                x.TARGET_PARAM.ID === currentChanged.TARGET_PARAM.ID
            );

            if (targetWithSameConfig.length > 1) {
                currentChanged.TARGET_TYPE = null;
                currentChanged.TARGET_CLASS = null;
                currentChanged.TARGET = null;
                currentChanged.TARGET_PARAM = null;

                const self: TaskParamRegisterController = this;
                this.$timeout(() => {
                    self.$scope.selectorValidity(`targetType${parentIndex}${index}`);
                    self.$scope.selectorValidity(`target${parentIndex}${index}`);
                    self.$scope.selectorValidity(`targetParam${parentIndex}${index}`);
                    self.$scope.selectorValidity(`targetClass${parentIndex}${index}`);
                });
                const msg = this.formService.getTranslate('REGISTRATION.DOUBLE_MONITORING');
                this.handleWarning(msg);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async validateHotspotTargetEvent(parentIndex: number): Promise<void> {
        try {
            const self: TaskParamRegisterController = this;
            this.$timeout(() => {
                self.$scope.selectorValidity(`taskHitStatus${parentIndex}`);
                self.$scope.selectorValidity(`taskMissStatus${parentIndex}`);
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async prepareHotspotType(): Promise<void> {
        try {
            if (this.$scope.model.TARGET_GROUP) {
                for (let i = 0; i < this.$scope.model.TARGET_GROUP.length; i++) {
                    for (let x = 0; x < this.$scope.model.TARGET_GROUP[i].TARGETS.length; x++) {
                        await this.changeHotspotTargetType(i, x);
                        await this.changeHotspotTarget(this.$scope.model.TARGET_GROUP[i].TARGETS[x].TARGET.ID, i, x)
                    }
                }
            }
            await this.changeDependencyType('start');
            await this.changeDependencyType('end');
            await this.changeDependency('start');
            await this.changeDependency('end');
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async changeHotspotTargetType(parentIndex: number, index: number): Promise<void> {
        try {
            if (!this.$scope.targetList) this.$scope.targetList = {};
            if (!this.$scope.targetList[parentIndex]) this.$scope.targetList[parentIndex] = {};

            const currentChanged = this.$scope.model.TARGET_GROUP[parentIndex].TARGETS[index];
            if (currentChanged && currentChanged.TARGET_TYPE) {
                const activeHotspots = this.hotspotList.filter(x => x.ACTIVE);
                this.$scope.targetList[parentIndex][index] = activeHotspots.filter(x => x.TARGET_TYPE.ID === currentChanged.TARGET_TYPE.ID).map(x => ({ ID: x.ID.toString(), NAME: x.TARGET.NAME }));
            }

            const self: TaskParamRegisterController = this;
            this.$timeout(() => {
                self.$scope.selectorValidity(`targetType${parentIndex}${index}`);
                self.$scope.selectorValidity(`target${parentIndex}${index}`);
                self.$scope.selectorValidity(`targetParam${parentIndex}${index}`);
                self.$scope.selectorValidity(`targetClass${parentIndex}${index}`);
            });
            if (this.$scope.targetParamList && this.$scope.targetParamList[parentIndex] && this.$scope.targetParamList[parentIndex][index]) this.$scope.targetParamList[parentIndex][index] = null;
            if (this.$scope.targetClassList && this.$scope.targetClassList[parentIndex] && this.$scope.targetClassList[parentIndex][index]) this.$scope.targetClassList[parentIndex][index] = null;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async changeHotspotTarget(hotspotID: string, parentIndex: number, index: number): Promise<void> {
        try {
            const currentChanged = this.$scope.model.TARGET_GROUP[parentIndex].TARGETS[index];
            const selectedHotspot = this.hotspotList.find(x => x.ID.toString() === hotspotID);
            if (selectedHotspot) {

                currentChanged.TARGET_TRACKER = selectedHotspot.TARGET_TRACKER;
                currentChanged.TARGET_PARAM_TYPE = selectedHotspot.TARGET_PARAM_TYPE;
                currentChanged.TARGET_GENERIC_IDENTIFIER = selectedHotspot.TARGET_GENERIC_IDENTIFIER;

                if (!this.$scope.targetParamList) this.$scope.targetParamList = {};
                if (!this.$scope.targetParamList[parentIndex]) this.$scope.targetParamList[parentIndex] = {};
                this.$scope.targetParamList[parentIndex][index] = selectedHotspot.TARGET_PARAM;

                if (selectedHotspot.TARGET_CLASS && selectedHotspot.TARGET_CLASS.length > 0) {
                    if (!this.$scope.targetClassList) this.$scope.targetClassList = {};
                    if (!this.$scope.targetClassList[parentIndex]) this.$scope.targetClassList[parentIndex] = {};
                    this.$scope.targetClassList[parentIndex][index] = selectedHotspot.TARGET_CLASS;
                }
            }
            const self: TaskParamRegisterController = this;
            this.$timeout(() => {
                self.$scope.selectorValidity(`targetType${parentIndex}${index}`);
                self.$scope.selectorValidity(`target${parentIndex}${index}`);
                self.$scope.selectorValidity(`targetParam${parentIndex}${index}`);
                self.$scope.selectorValidity(`targetClass${parentIndex}${index}`);
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async changeDependencyType(dependency: string): Promise<void> {
        try {
            if (dependency === 'start') {
                const type = this.$scope.model.START.DEPENDENCY.TARGET_TYPE;
                if (!type) return;
                const activeHotspots = this.hotspotList.filter(x => x.ACTIVE && x.TARGET_PARAM_TYPE === TARGET_PARAM_TYPE.DATE);
                this.$scope.startDependencyList = activeHotspots.filter(x => x.TARGET_TYPE.ID === type.ID).map(x => ({ ID: x.ID.toString(), NAME: x.TARGET.NAME }));
            }
            else if (dependency === 'end') {
                const type = this.$scope.model.END.DEPENDENCY.TARGET_TYPE;
                if (!type) return;
                const activeHotspots = this.hotspotList.filter(x => x.ACTIVE && x.TARGET_PARAM_TYPE === TARGET_PARAM_TYPE.DATE);
                this.$scope.endDependencyList = activeHotspots.filter(x => x.TARGET_TYPE.ID === type.ID).map(x => ({ ID: x.ID.toString(), NAME: x.TARGET.NAME }));
            }
            const self: TaskParamRegisterController = this;
            this.$timeout(() => {
                self.$scope.selectorValidity(`taskStartDependencyType`);
                self.$scope.selectorValidity(`taskStartDependency`);
                self.$scope.selectorValidity(`taskStartDependencyClass`);
                self.$scope.selectorValidity(`taskStartDependencyDate`);

                self.$scope.selectorValidity(`taskEndDependencyType`);
                self.$scope.selectorValidity(`taskEndDependency`);
                self.$scope.selectorValidity(`taskEndDependencyClass`);
                self.$scope.selectorValidity(`taskEndDependencyDate`);
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async changeDependency(dependency: string): Promise<void> {
        try {
            if (dependency === 'start') {
                const hotspot = this.$scope.model.START.DEPENDENCY.TARGET;
                if (!hotspot) return;

                const selectedHotspot = this.hotspotList.find(x => x.ID.toString() === hotspot.ID);
                if (selectedHotspot) {
                    this.$scope.model.START.DEPENDENCY.TARGET_TRACKER = selectedHotspot.TARGET_TRACKER;
                    this.$scope.model.START.DEPENDENCY.TARGET_PARAM_TYPE = selectedHotspot.TARGET_PARAM_TYPE;
                    this.$scope.model.START.DEPENDENCY.TARGET_GENERIC_IDENTIFIER = selectedHotspot.TARGET_GENERIC_IDENTIFIER;

                    this.$scope.startDependencyDateTypeList = selectedHotspot.TARGET_PARAM;

                    if (selectedHotspot.TARGET_CLASS && selectedHotspot.TARGET_CLASS.length > 0)
                        this.$scope.startDependencyClassList = selectedHotspot.TARGET_CLASS;
                }
            }
            else if (dependency === 'end') {
                const hotspot = this.$scope.model.END.DEPENDENCY.TARGET;
                if (!hotspot) return;

                const selectedHotspot = this.hotspotList.find(x => x.ID.toString() === hotspot.ID);
                if (selectedHotspot) {
                    this.$scope.model.END.DEPENDENCY.TARGET_TRACKER = selectedHotspot.TARGET_TRACKER;
                    this.$scope.model.END.DEPENDENCY.TARGET_PARAM_TYPE = selectedHotspot.TARGET_PARAM_TYPE;
                    this.$scope.model.END.DEPENDENCY.TARGET_GENERIC_IDENTIFIER = selectedHotspot.TARGET_GENERIC_IDENTIFIER;

                    this.$scope.endDependencyDateTypeList = selectedHotspot.TARGET_PARAM;

                    if (selectedHotspot.TARGET_CLASS && selectedHotspot.TARGET_CLASS.length > 0)
                        this.$scope.endDependencyClassList = selectedHotspot.TARGET_CLASS;
                }
            }
            const self: TaskParamRegisterController = this;
            this.$timeout(() => {
                self.$scope.selectorValidity(`taskStartDependencyType`);
                self.$scope.selectorValidity(`taskStartDependency`);
                self.$scope.selectorValidity(`taskStartDependencyClass`);
                self.$scope.selectorValidity(`taskStartDependencyDate`);

                self.$scope.selectorValidity(`taskEndDependencyType`);
                self.$scope.selectorValidity(`taskEndDependency`);
                self.$scope.selectorValidity(`taskEndDependencyClass`);
                self.$scope.selectorValidity(`taskEndDependencyDate`);
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async addDetour(): Promise<void> {
        try {

            const detourList: DETOUR_LIST = { DETOUR: null }

            if (!this.$scope.model.DETOUR_LIST) this.$scope.model.DETOUR_LIST = [];
            this.$scope.model.DETOUR_LIST.push(detourList);
            const currentIndex = this.$scope.model.DETOUR_LIST.length - 1;

            this.$timeout(() => { this.$scope.selectorValidity('detour' + currentIndex) }, 100);


        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeDetour(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.$scope.model.DETOUR_LIST && this.$scope.model.DETOUR_LIST.length > 0) {
                this.formService.block();
                this.$scope.model.DETOUR_LIST.splice(index, 1);
                this.formService.unblock();
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    async register(): Promise<void> {
        try {
            this.$scope.initPanels();
            BrowserTitle.$id = null;
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.formOperation = this.$scope.formOperation;
                this.$scope.menuFloating.infos = [{ text: "GENERAL.MENU.ACTIVITY_PARAMETERIZATION", class: "text-rouge font-bold" }];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.initPanels();
            BrowserTitle.$id = `${this.$scope.model.TASK_NUMBER}`;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [{ text: `${this.$scope.model.TASK_NUMBER}`, class: "text-rouge font-bold" }];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async edit(model: TaskParam): Promise<void> {
        try {
            this.$scope.model = model
            this.$scope.initPanels();
            BrowserTitle.$id = `${this.$scope.model.TASK_NUMBER}`;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');

            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [{ text: `${this.$scope.model.TASK_NUMBER}`, class: "text-rouge font-bold" }];
            }
            await this.prepareHotspotType();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.initPanels();
            BrowserTitle.$id = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATIO.COPYING');
            // this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [{ text: "GENERAL.MENU.ACTIVITY_PARAMETERIZATION", class: "text-rouge font-bold" }];
            }
            await this.prepareHotspotType();
            await this.clearFields(this.$scope.model);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async save(): Promise<boolean> {

        try {
            const hasTargetGroupMissingTarget = this.$scope.model.TARGET_GROUP && this.$scope.model.TARGET_GROUP.some(x => !x.TARGETS || x.TARGETS.length === 0);
            if (hasTargetGroupMissingTarget) {
                const msg = this.formService.getTranslate('REGISTRATION.ADD_TARGET');
                this.handleWarning(msg);
                return false;
            }
            return true;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequest> {
        try {
            //se desvio for diferente de "nunca", necessário existir ao menos um motivo na lista
            if (this.$scope.model.MANDATORY_DETOUR.ID !== '3' &&
                (!this.$scope.model.DETOUR_LIST || this.$scope.model.DETOUR_LIST.length == 0)) {
                const msgError = this.formService.getTranslate('REGISTRATION.ADD_FAULT_REASON');
                throw Error(msgError);
            }

            const route = this.$scope.operation === 'register' ? 'insert' : 'update';
            return {
                data: angular.copy(this.$scope.model),
                route: `/param/${route}`,
                timeout: 15000,
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getMenuFloatingDefault(): IFloatingMenu {
        return {
            "tooltipPlacement": "auto bottom",
            "textTooltip": "GENERAL.MENU.ACTIVITY_PARAMETERIZATION",
            "infos": [
                {
                    "text": "GENERAL.MENU.ACTIVITY_PARAMETERIZATION",
                    "class": "text-rouge font-bold",
                }
            ],
            "options": [
                {
                    "click": "collapseHeader",
                    "args": [
                        "collapseBasicData"
                    ],
                    "tooltipPlacement": "auto bottom",
                    "textTooltip": "GENERAL.BASIC_DATA",
                    "iconClass": "fa fa-address-card",
                    "iconBodyClass": "text-brown"
                },
                {
                    "click": "collapseHeader",
                    "args": [
                        "collapseGeneralConditions"
                    ],
                    "tooltipPlacement": "auto bottom",
                    "textTooltip": "REGISTRATION.GENERAL_CONDITIONS",
                    "iconClass": "fa fa-cogs",
                    "iconBodyClass": "text-rouge"
                },
                {
                    "click": "collapseHeader",
                    "args": [
                        "collapseInvolved"
                    ],
                    "tooltipPlacement": "auto bottom",
                    "textTooltip": "GENERAL.STAKEHOLDERS",
                    "iconClass": "fa fa-users",
                    "iconBodyClass": "text-allog"
                },
                {
                    "click": "collapseHeader",
                    "args": [
                        "collapseRoutes"
                    ],
                    "tooltipPlacement": "auto bottom",
                    "textTooltip": "ROUTE.ROUTES",
                    "iconClass": "fa fa-map-signs",
                    "iconBodyClass": "text-bronze"
                },
                {
                    "click": "collapseHeader",
                    "args": [
                        "collapseInvoices"
                    ],
                    "tooltipPlacement": "auto bottom",
                    "textTooltip": "GENERAL.INVOICES",
                    "iconClass": "fa fa-usd",
                    "iconBodyClass": "text-green"
                },
                {
                    "click": "collapseHeader",
                    "args": [
                        "collapseCalc"
                    ],
                    "tooltipPlacement": "auto bottom",
                    "textTooltip": "REGISTRATION.DEADLINE_CALCULATION",
                    "iconClass": "fa fa-clock-o",
                    "iconBodyClass": "text-dark"
                },
                {
                    "click": "collapseHeader",
                    "args": [
                        "collapseTriggers"
                    ],
                    "tooltipPlacement": "auto bottom",
                    "textTooltip": "REGISTRATION.TRIGGERS",
                    "iconClass": "fa fa-bolt",
                    "iconBodyClass": "text-especial"
                },
                {
                    "click": "collapseHeader",
                    "args": [
                        "collapseHotspot"
                    ],
                    "tooltipPlacement": "auto bottom",
                    "textTooltip": "REGISTRATION.MONITORING",
                    "iconClass": "fa fa-eye",
                    "iconBodyClass": "text-dark-dk"
                },
                {
                    "click": "collapseHeader",
                    "args": [
                        "collapseDetourList"
                    ],
                    "tooltipPlacement": "auto bottom",
                    "textTooltip": "REGISTRATION.FAULT_LIST",
                    "iconClass": "fa fa-bars",
                    "iconBodyClass": "text-blue"
                },
                {
                    "click": "collapseHeader",
                    "args": [
                        "collapseIntegration"
                    ],
                    "tooltipPlacement": "auto bottom",
                    "textTooltip": "REGISTRATION.INTEGRATION",
                    "iconClass": "fa fa-retweet",
                    "iconBodyClass": "text-red"
                },
                {
                    "click": "collapseHeader",
                    "args": [
                        "collapseAll"
                    ],
                    "tooltipPlacement": "auto bottom",
                    "textTooltip": "GENERAL.COLLAPSE_EXPAND_ALL",
                    "iconClass": "fa fa-expand",
                    "iconBodyClass": "text-danger"
                },
            ],
            "btnActiveDisabled": false
        }
    }

    private collapseHeader(elementId: string, state?: string): void {
        if (elementId === "collapseAll" || elementId[0] === "collapseAll") {
            this.collapseState = this.collapseState == "hide" ? "show" : "hide";
            $('.toggle-me')["collapse"](state ? state : this.collapseState);
        } else if (elementId != "registerBody") {
            $("#" + elementId)["collapse"](state ? state : 'toggle');
        }
        this.$scope.navigateBetweenIds(elementId);
    }

    private initPanels(): void {
        try {
            const panels = document.getElementsByClassName("toggle-me");
            if (panels && panels.length > 0) {
                for (let i = 0; i < panels.length; i++) {
                    const panel = panels[i];
                    if (panel.id === "collapseBasicData" || panel.id === "collapseDirect") {
                        if (!panel.classList.contains('in')) $("#" + panel.id)["collapse"]("show");
                    } else if (panel.classList.contains('in')) {
                        $("#" + panel.id)["collapse"]("hide");
                    }
                }
            }
            this.$scope.navigateBetweenIds('collapseBasicData');
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private async requestHistory(id: string): Promise<any> {
        const timeout: number = 15000;

        const result = await this.TaskService.get({ route: `/param/viewLog/${id}/${timeout}`, timeout });

        if (!result || !result.data) return this.handleError(result);

        return result.data;
    }

    private async viewLog(chargeGroupingParams) {
        try {
            if (this.$scope.log) this.$scope.log.show = false;
            this.block();
            let log: IViewLog = {
                operation: 'history',
                number: chargeGroupingParams.ID,
                list: [],
                show: true,
                searchQuery: '',
                originalList: [],
            }
            const result = await this.requestHistory(log.number)
            log.list = result.data;
            log.originalList = angular.copy(log.list);
            this.$scope.log = log;
            angular.element('#log-viewer').removeClass('ng-hide');
            const position = angular.element('#log-viewer').offset().top + $('.app-content-body').scrollTop() - 105;

            $('.app-content-body').animate({
                scrollTop: position
            }, 500);

            this.unblock();
        } catch (err) {
            const msgError = await this.$formService.getTranslate('REGISTRATION.NO_RECORD_FOUND')
            if (err['status'] && err['status'] === 404) return this.handleWarning(msgError);
            return this.handleError(err);
        }
    };

    public customData() {
        const { data } = this.$gridService.$gridOptions;

        for (let i = 0; i < data.length; i++) {
            for (let item of Object.keys(data[i])) {
                if ((data[i][item] != null)) {
                    if (isArray(data[i][item]['CRITERIA'])) {
                        const aux = data[i][item]['CRITERIA']
                            .map(x => x.NAME).join(' , ')
                        data[i][item]['CRITERIA'] = aux ? aux : ""
                    }
                }
            }
        }
    }

    private addIntegrationParam(): void {
        try {
            const integrateList: INTEGRATION_PARAM = { INTEGRATION: null, NOTIFICATION_TYPE: null }

            if (!this.$scope.model.INTEGRATION_PARAM) this.$scope.model.INTEGRATION_PARAM = [];
            this.$scope.model.INTEGRATION_PARAM.push(integrateList);

            const currentIndex = this.$scope.model.INTEGRATION_PARAM.length - 1;

            this.$timeout(() => { this.$scope.selectorValidity('INTEGRATION_PARAM' + currentIndex) }, 100);

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeIntegrationParam(index: number): Promise<any> {
        try {

            if (!index && index != 0) throw Error('index is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.$scope.model.INTEGRATION_PARAM && this.$scope.model.INTEGRATION_PARAM.length > 0) {
                this.formService.block();
                this.$scope.model.INTEGRATION_PARAM.splice(index, 1);
                this.formService.unblock();
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: 'ACTIVE', LABEL: 'GENERAL.ACTIVE' },
            { PROPERTY: 'TASK_NUMBER', LABEL: 'OPERATIONAL.TASK_NUMBER' },
            { PROPERTY: 'TASK', LABEL: 'GENERAL.TASK' },
            { PROPERTY: 'TASK_STEP', LABEL: 'REGISTRATION.STEP' },
            { PROPERTY: 'MODULE', LABEL: 'REGISTRATION.MODULE' },
            { PROPERTY: 'MANDATORY_DETOUR', LABEL: 'REGISTRATION.REQUIRED_FAULT_REASON' },
            { PROPERTY: 'DETOUR_LIST', LABEL: 'REGISTRATION.FAULT_LIST' },
            { PROPERTY: 'RESPONSIBLE_CELL', LABEL: 'REGISTRATION.RESPONSIBLE_CELL' },
            { PROPERTY: 'RESPONSIBLE_ROLE', LABEL: 'Papel Resposável' },
            { PROPERTY: 'RESPONSIBLE_USER', LABEL: 'REGISTRATION.RESPONSIBLE_ROLE' },
            { PROPERTY: 'PRODUCT', LABEL: 'BASIC_DATA.PRODUCT' },
            { PROPERTY: 'FORWARDED_BY', LABEL: 'GENERAL.FORWARDED_BY' },
            { PROPERTY: 'PROCESS_TYPE', LABEL: 'BASIC_DATA.FILE_TYPE' },
            { PROPERTY: 'COMPANY', LABEL: 'GENERAL.COMPANY' },
            { PROPERTY: 'PRIORITY', LABEL: 'REGISTRATION.PRIORITY' },
            { PROPERTY: 'OPERATIONAL_SITUATION', LABEL: 'OPERATIONAL.FILE_OPERATIONAL_STATUS' },
            { PROPERTY: 'DIRECT_BL', LABEL: 'GENERAL.STRAIGHT_BL' },
            { PROPERTY: 'CARGO_TYPE', LABEL: 'BASIC_DATA.CARGO_TYPE' },
            { PROPERTY: 'DANGER_CARGO', LABEL: 'BASIC_DATA.DANGEROUS_CARGO' },
            { PROPERTY: 'INSURANCE', LABEL: 'FINANCIAL.INSURANCE' },
            { PROPERTY: 'DTA', LABEL: 'REGISTRATION.BONDED_TRUCK' },
            { PROPERTY: 'STATUS_BOOKING', LABEL: 'OPERATIONAL.BOOKING_STATUS' },
            { PROPERTY: 'INCOTERM', LABEL: 'BASIC_DATA.INCOTERM' },
            { PROPERTY: 'MASTER_PAYMENT', LABEL: 'BASIC_DATA.MASTER_PAYMENT_MODE' },
            { PROPERTY: 'HOUSE_PAYMENT', LABEL: 'BASIC_DATA.HOUSE_PAYMENT_MODE' },
            { PROPERTY: 'SERVICE_MOVEMENT', LABEL: 'GENERAL.MOVE_TYPE' },
            { PROPERTY: 'EQUIPMENT', LABEL: 'BASIC_DATA.EQUIPMENT' },
            { PROPERTY: 'SERVICE_TYPE', LABEL: 'BASIC_DATA.SERVICE_TYPE' },
            { PROPERTY: 'BOOKING_TYPE', LABEL: 'GENERAL.BOOKING_TYPE' },
            { PROPERTY: 'CLIENT', LABEL: 'BASIC_DATA.CLIENT' },
            { PROPERTY: 'QUALIFICATION', LABEL: 'GENERAL.CLIENT_QUALIFICATION' },
            { PROPERTY: 'KAM', LABEL: 'BASIC.DATA.KAM' },
            { PROPERTY: 'ORIGIN_AGENT', LABEL: 'BASIC_DATA.LOCAL_AGENT' },
            { PROPERTY: 'DESTINATION_AGENT', LABEL: 'BASIC_DATA.OVERSEAS_AGENT' },
            { PROPERTY: 'SERVICE_PROVIDER', LABEL: 'BASIC_DATA.PROVIDER' },
            { PROPERTY: 'TRANSPORTER_PICKUP', LABEL: 'BASIC_DATA.TRANSPORTER_PICKUP' },
            { PROPERTY: 'TRANSPORTER_PLACE_RECEPT', LABEL: 'BASIC_DATA.TRANSPORTER_PLACE_RECEPT' },
            { PROPERTY: 'TRANSPORTER_FINAL_DESTINATION', LABEL: 'BASIC_DATA.TRANSPORTER_FINAL_DESTINATION' },
            { PROPERTY: 'TRANSPORTER_DELIVERY', LABEL: 'BASIC_DATA.TRANSPORTER_DELIVERY' },
            { PROPERTY: 'TRANSPORTER_GATE_OUT_EMPTY', LABEL: 'BASIC_DATA.TRANSPORTER_GATE_OUT_EMPTY' },
            { PROPERTY: 'TRANSPORTER_CONTAINER_STUFFING', LABEL: 'BASIC_DATA.TRANSPORTER_CONTAINER_STUFFING' },
            { PROPERTY: 'FLEXI_FITTING', LABEL: 'BASIC_DATA.FLEXI_FITTING' },
            { PROPERTY: 'ISOTANK_PROVIDER', LABEL: 'BASIC_DATA.ISOTANK_PROVIDER' },
            { PROPERTY: 'EXTERNAL_BROKER', LABEL: 'BASIC_DATA.EXTERNAL_BROKER' },
            { PROPERTY: 'ORIGIN_GROUP', LABEL: 'BASIC_DATA.ORIGIN_TRADE_GROUP' },
            { PROPERTY: 'ORIGIN_TRADE', LABEL: 'BASIC_DATA.ORIGIN_TRADE_LANE' },
            { PROPERTY: 'ORIGIN_COUNTRY', LABEL: 'BASIC_DATA.ORIGIN_COUNTRY' },
            { PROPERTY: 'ORIGIN_LOCAL', LABEL: 'BASIC_DATA.ORIGIN_LOCATION' },
            { PROPERTY: 'DESTINATION_GROUP', LABEL: 'BASIC_DATA.DESTINATION_TRADE_GROUP' },
            { PROPERTY: 'DESTINATION_TRADE', LABEL: 'BASIC_DATA.DESTINATION_TRADE_LANE' },
            { PROPERTY: 'DESTINATION_COUNTRY', LABEL: 'BASIC_DATA.DESTINATION_COUNTRY' },
            { PROPERTY: 'DESTINATION_LOCAL', LABEL: 'BASIC_DATA.DESTINATION_LOCATION' },
            { PROPERTY: 'TRANSACTION', LABEL: 'BASIC_DATA.TRANSACTION' },
            { PROPERTY: 'PAYMENT_METHOD', LABEL: 'FINANCIAL.PAYMENT_MEANS' },
            { PROPERTY: 'PAYMENT_CONDITION', LABEL: 'GENERAL.INVOICE_PAYMENT_TERM' },
            { PROPERTY: 'CURRENCY_CONVERSION', LABEL: 'GENERAL.EXCHANGE_RATE_INDEX' },
            { PROPERTY: 'PERIOD_VARIATION', LABEL: 'REGISTRATION.PERIOD_VARIATION' },
            { PROPERTY: 'CONVERSION_REFERENCE', LABEL: 'REGISTRATION.EXC_RATE_REFERENCE_DATE' },
            { PROPERTY: 'NATURE', LABEL: 'BASIC_DATA.PAYMENT_NATURE' },
            { PROPERTY: 'ANTICIPATED_INVOICE', LABEL: 'REGISTRATION.EARLY_RECEIPT' },
            { PROPERTY: 'INVOICE_STATUS', LABEL: 'FINANCIAL.BILLING_STATUS' },
            { PROPERTY: 'INVOICE_FINANCIAL_STATUS', LABEL: 'FINANCIAL.FINANCIAL_STATUS' },
            { PROPERTY: 'PEOPLE', LABEL: 'GENERAL.HOLDER' },
            { PROPERTY: 'START', LABEL: 'OPERATIONAL.DEADLINE_START' },
            { PROPERTY: 'END', LABEL: 'OPERATIONAL.END' },
            { PROPERTY: 'RECALCULATE_TASK', LABEL: 'REGISTRATION.RECALCULATE' },
            { PROPERTY: 'RECALCULATE_TASK_SITUATION', LABEL: 'REGISTRATION.TASK_STATUS' },
            { PROPERTY: 'RESTORE', LABEL: 'OPERATIONAL.RESTORE' },
            { PROPERTY: 'PROCESS_SITUATION', LABEL: 'OPERATIONAL.LOGISTIC_STATUS' },
            { PROPERTY: 'TRIGGER', LABEL: 'REGISTRATION.TRIGGERS' },
            { PROPERTY: 'TARGET_GROUP', LABEL: 'REGISTRATION.MONITORING' },
            { PROPERTY: 'CURRENT_SITUATION', LABEL: 'OPERATIONAL.CURRENT_FILE_STATUS' },
            { PROPERTY: 'TASK_TO_UPDATE', LABEL: 'OPERATIONAL.UPDATE' },
            { PROPERTY: 'SITUATION_TO_UPDATE_TO', LABEL: 'OPERATIONAL.UPDATE_FILE_STATUS' },
            { PROPERTY: 'GENERATE_TASK', LABEL: 'REGISTRATION.GENERATE_TASK' },
            { PROPERTY: 'INSERT_STATUS', LABEL: 'REGISTRATION.INSERT_AND' },
            { PROPERTY: 'UPDATE_STATUS', LABEL: 'OPERATIONAL.UPDATE' },
            { PROPERTY: 'EXCLUDE_STATUS', LABEL: 'REGISTRATION.DELETE_OR' },
            { PROPERTY: 'TARGETS', LABEL: 'REGISTRATION.TARGET' },
            { PROPERTY: 'CALC_TYPE', LABEL: 'REGISTRATION.CALCULATION_TYPE' },
            { PROPERTY: 'DEADLINE_HOURS', LABEL: 'OPERATIONAL.DEADLINE_HH' },
            { PROPERTY: 'DEADLINE_MINUTES', LABEL: 'OPERATIONAL.DEADLINE_MM' },
            { PROPERTY: 'HOUR_TYPE', LABEL: 'BASIC_DATA.SCORE' },
            { PROPERTY: 'HOUR_CONCLUSION', LABEL: 'REGISTRATION.CONCLUSION_HOUR' },
            { PROPERTY: 'FIXED_HOURS', LABEL: 'OPERATIONAL.FIXED_HOUR_HH' },
            { PROPERTY: 'FIXED_MINUTES', LABEL: 'OPERATIONAL.FIXED_HOUR_MM' },
            { PROPERTY: 'INTEGRATION', LABEL: 'REGISTRATION.INTEGRATION' },
            { PROPERTY: 'NOTIFICATION_TYPE', LABEL: 'REGISTRATION.NOTIFICATION_TYPE' },
            { PROPERTY: 'HOTSPOT_NUMBER', LABEL: 'OPERATIONAL.HOTSPOT' },
            { PROPERTY: 'TARGET_TYPE', LABEL: 'REGISTRATION.TARGET_TYPE' },
            { PROPERTY: 'TARGET_CLASS', LABEL: 'REGISTRATIONTARGET_CLASS' },
            { PROPERTY: 'TARGET', LABEL: 'REGISTRATION.TARGET' },
            { PROPERTY: 'TARGET_PARAM', LABEL: 'REGISTRATION.PARAMETERS' },
            { PROPERTY: 'TARGET_PARAM_TYPE', LABEL: 'REGISTRATION.PARAMETER_TYPE' },
            { PROPERTY: 'TARGET_GENERIC_IDENTIFIER', LABEL: 'REGISTRATION.GENERIC_IDENTIFIER' },
            { PROPERTY: 'TARGET_TRACKER', LABEL: 'OPERATIONAL.TARGET_TRACKER' },
            { PROPERTY: 'STATUS', LABEL: 'GENERAL.ACTIVE' },
            { PROPERTY: 'EXISTS', LABEL: 'REGISTRATION.CONTAINS' },
            { PROPERTY: 'CRITERIA', LABEL: 'REGISTRATION.CRITERIA' },
        ];
        return <ICustomLogProperties[]>props;
    }
}

import { IComponentOptions, Injectable, IControllerConstructor, INgModelController, IScope } from "angular";
import { IMonacoController } from "../../../common/MonacoInterface";
import * as uuid from 'uuid';
import "jquery-mask-plugin/dist/jquery.mask.min.js";
import { EOperation } from "@enums/GenericData";

interface CloseParams {
    closePressed: boolean;
    openDate: Date;
    closeDate: Date;
}

export class MonacoDateTimePickerComponent implements IComponentOptions {
    private config: object;
    public template: string;
    public controller: Injectable<IControllerConstructor>;
    public bindings: { [boundProperty: string]: string } = {
        ngModel: '<',
        ngDisabled: '='
    };
    public require: { [controller: string]: string } = {
        ngModelCtrl: 'ngModel'
    };

    constructor() {
        this.controller = MonacoDateTimePickerController;
        this.template = require("./monaco-datetime-picker.html");
        this.config = {
            dateFormat: 'dd/MM/yyyy HH:mm',
            defaultTime: '00:00:00',
            html5Types: {
                date: 'dd/MM/yyyy',
                'datetime-local': 'yyyy-MM-ddTHH:mm:ss.sss',
                'month': 'MM/yyyy'
            },
            initialPicker: 'date',
            reOpenDefault: false,
            enableDate: true,
            enableTime: true,
            buttonBar: {
                show: true,
                now: {
                    show: true,
                    text: 'Now',
                    cls: 'btn-sm btn-default'
                },
                today: {
                    show: true,
                    text: 'Today',
                    cls: 'btn-sm btn-info'
                },
                clear: {
                    show: true,
                    text: 'Clear',
                    cls: 'btn-sm btn-default'
                },
                date: {
                    show: true,
                    text: 'Date',
                    cls: 'btn-sm btn-default'
                },
                time: {
                    show: true,
                    text: 'Time',
                    cls: 'btn-sm btn-default'
                },
                close: {
                    show: true,
                    text: 'Ok',
                    cls: 'btn-sm btn-success'
                },
                cancel: {
                    show: false,
                    text: 'Cancel',
                    cls: 'btn-sm btn-default'
                }
            },
            closeOnDateSelection: true,
            closeOnTimeNow: true,
            appendToBody: false,
            altInputFormats: [],
            ngModelOptions: {},
            saveAs: false,
            readAs: false,
            datepickerOptions: {
                formatYear: 'yy',
                maxDate: new Date(2030, 1, 1),
                minDate: new Date(1970, 1, 1),
                startingDay: 1,
            }
        };
    }

    public get $config(): object {
        return this.config;
    }

}

class MonacoDateTimePickerController implements IMonacoController {
    static $inject: string[] = ['$scope', '$element'];
    private $scope: IScope;
    private $element: JQLite;
    private id: string;
    private timePickerOptions: object;
    private modelName: string;
    private ngModelCtrl: INgModelController;
    private ngModel: any;
    private isOpen: boolean;
    private operation: string;
    private parentScopeListener: any;
    private format: string;
    public isDateFormat: boolean;

    constructor($scope: IScope, $element: JQLite) {
        this.$scope = $scope;
        this.$element = $element;
    }

    $onInit() {

        this.id = uuid.v4();
        this.isOpen = false;
        this.timePickerOptions = {
            showMeridian: false
        };

        this.isDateFormat = this.$element.attr('date-format') === 'true';
        this.format = (this.isDateFormat) ? 'dd/MM/yyyy' : 'dd/MM/yyyy HH:mm';

        this.setMask();

        //validate model based on operation
        this.operation = (this.$scope.$parent && this.$scope.$parent['operation']) ? this.$scope.$parent['operation'] : 'register';
        if (this.operation === EOperation.VIEW) this.ngModelCtrl.$setPristine();
        else this.ngModelCtrl.$setDirty();

        //init model value, here and on the parent scope
        this.modelName = this.$element.attr('ng-model');
        if (this.ngModel) this.ngModel = new Date(this.ngModel);
        else this.ngModel = null; //set as null by default, instead of undefined
        this.parentScopeListener = this.$scope.$parent.$watch(this.modelName, this.initParentScopeModel.bind(this));
    }

    $onDestroy() {
        if (this.parentScopeListener) this.parentScopeListener();
    }

    ngModelChange(): void {
        //just use ng-change for direct input
        if (this.isOpen) return;
        if (this.ngModel !== undefined) this.ngModelCtrl.$setViewValue(this.ngModel);
    }

    open(): void {
        this.isOpen = true;
    }

    close(): void {
        this.isOpen = false;
    }

    whenClosed(args: CloseParams): void {
        if (!args.closePressed) {
            //reset the value
            this.ngModel = args.openDate;
        } else this.ngModelCtrl.$setViewValue(this.ngModel);
    }

    onEnter(): void {
        //this.isOpen = false;
        this.$scope['close']();
    }

    private initParentScopeModel(value: any): void {
        if (value) this.ngModel = new Date(value);
    }

    private setMask(): void {
        const input = this.$element.find('input');
        if (input) {
            (this.isDateFormat) ? input['mask']('00/00/0000') : input['mask']('00/00/0000 00:00');
        }
    }

}
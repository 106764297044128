import { IColumnDef } from "ui-grid";
import { GridFormService, IGridFormController, IGridFormServiceScope, IMonacoRequest } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IModalService } from "@services/ModalService";
import { ProductService } from "@services/ProductService";
import { DataManagerService } from "@services/DataManagerService";
import { ReportAutomaticRulesModel } from "@models/interface/operational/ReportAutomaticRulesModel";
import { ICustomLogProperties } from "@models/interface/common/IViewLog";
import { ISelectorModel } from "@models/mongo/SelectorModel";
import { IAccountSelector, ILegalPersonSelector, IAgentSelector, IServiceProviderSelector, IIncotermSelector } from "@models/interface/operational/IProductIntegrationSelector";
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { SelectorModel } from "../../common/model/SelectorModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { StringUtil } from "../../common/util/StringUtil";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { ILegalPersonListCustomFilter } from "../model/LegalPersonModel";
import { IAgentListCustomFilter } from "../model/AgentModel";
import { HelperService } from "@services/HelperService";
import { ETypeRuleId, IParam } from "WBA-Model/dist/interface/product/MoveTypeModel";
import { EDirectionId } from "@enums/GenericData";

const InvoiceGroup: SelectorModel = { ID: "1", NAME: "Não" };
const ReportPermissionAutomaticManual: SelectorModel = { ID: "1", NAME: "Automático/Manual" };
const ProcessDocumentType: SelectorModel = { ID: "2", NAME: "House", CODE: "HOUSE" };

interface IReportAutomaticRulesScope extends IGridFormServiceScope {
    model: ReportAutomaticRulesModel;
    reportList: SelectorModel[];
    categoryList: SelectorModel[];
    documentTypeList: SelectorModel[];
    languageList: SelectorModel[];
    productList: SelectorModel[];
    incotermList: SelectorModel[];
    corporateBranchList: SelectorModel[];
    tagList: SelectorModel[];
    transactionList: SelectorModel[];
    specList: SelectorModel[];
    groupList: SelectorModel[];
    financialSituationList: SelectorModel[];
    invoiceGroupList: SelectorModel[];
    permissionList: SelectorModel[];
    paymentList: SelectorModel[];
    cargoTypeList: SelectorModel[];
    paymentNatureList: SelectorModel[];
    accountList: SelectorModel[];
    exporterList: SelectorModel[];
    importerList: SelectorModel[];
    agentList: SelectorModel[];
    serviceProviderList: SelectorModel[];
    paymentModalityList: SelectorModel[];
    processDocumentTypeList: SelectorModel[];
    routingPointList: SelectorModel[];
    paymentConditionList: SelectorModel[];
    conversionReferenceList: SelectorModel[];

    //
    customLogProperties: ICustomLogProperties[];
    //
    getTagsByCategory: (category: SelectorModel) => void;
    changeCategory: (oldValue: SelectorModel) => void;
    isInvoiceGroupReadyOnly: () => boolean;
    isTransactionReadyOnly: () => boolean;
    isTransactionRequired: () => boolean;
    isPaymentNatureReadyOnly: () => boolean;
    isFinancialSituationReadyOnly: () => boolean;
    getAccountListByName: (term: string) => void;
    getExporterListByName: (term: string) => void;
    getImporterListByName: (term: string) => void;
    getAgentListByName: (term: string) => void;
    getServiceProviderListByName: (term: string) => void;
    getIncotermListByName: (term: string) => void;
    getCorporateBranchListByName: (term: string) => void;
    getRoutingPointListByName: (name: string) => Promise<void>;
}

export class ReportAutomaticRulesRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ["$injector", "$scope", "$element"];
    private $scope: IReportAutomaticRulesScope;
    private RestService: IRestService;
    private ProductService: ProductService;
    private ModalService: IModalService;
    private DataManagerService: DataManagerService;
    private $q: ng.IQService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IReportAutomaticRulesScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.RestService = $injector.get("RestService");
        this.DataManagerService = $injector.get("DataManagerService");
        this.ProductService = $injector.get("ProductService");
        this.ModalService = $injector.get("ModalService");
        this.$q = $injector.get("$q");
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlDataManager();
            this.initForm(this, "form", "reportAutomaticRules", "GENERAL.MENU.AUTOMATION_RULES", true);
            await this.initGrid("reportAutomaticRules", "/reportAutomaticRules/list", true, true, 120000, true, true);
            this.$scope.customLogProperties = this.getCustomLogProperties();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initGridColumns(columns) {

        const gridColumns = new GridColumnBuilder([]);

        gridColumns.includeActionBar([GridColumnBuilderConstants.BTN_VIEW, GridColumnBuilderConstants.BTN_EDIT, GridColumnBuilderConstants.BTN_COPY, GridColumnBuilderConstants.BTN_VIEWLOG], 25);

        const columnDefs: Array<IColumnDef> = gridColumns.$columnDefs;

        for (let i = 0; i < columns.length; i++) {
            let name = null;
            let displayName = null;
            let width = 0;
            let cellTemplate = undefined;
            let cellFilter = null;
            let visible = true;
            let filter: uiGrid.IFilterOptions = { condition: this.gridService.filterSelectObject };
            let searchProps = null;
            let filterCellFiltered = null;

            switch (columns[i].toUpperCase()) {
                case "ID":
                    name = columns[i];
                    displayName = "GENERAL.CODE";
                    width = 5;
                    break;
                case "NAME":
                    name = columns[i];
                    displayName = "GENERAL.NAME";
                    width = 20;
                    break;
                case "TEMPLATE":
                    name = columns[i] + ".NAME";
                    displayName = "REGISTRATION.TEMPLATE";
                    width = 15;
                    break;
                case "CATEGORY":
                    name = columns[i] + ".NAME";
                    displayName = "REGISTRATION.CATEGORY";
                    width = 10;
                    break;
                case "DOCUMENT_TYPE":
                    name = columns[i] + ".NAME";
                    displayName = "REGISTRATION.DOCUMENT_TYPE";
                    width = 15;
                    break;
                case "PAYMENT_MODALITY":
                    name = columns[i];
                    displayName = "FINANCIAL.PAYMENT_METHOD";
                    width = 15;
                    cellTemplate = '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.PAYMENT_MODALITY)}}</div>'
                    visible = false;
                    break;
                case "LANGUAGE":
                    name = columns[i] + ".NAME";
                    displayName = "GENERAL.LANGUAGE";
                    width = 7;
                    break;
                case "CREATED_BY":
                    name = columns[i];
                    displayName = "GENERAL.CREATED_BY";
                    width = 15;
                    visible = false;
                    break;
                case "CREATED_DATE":
                    name = columns[i];
                    displayName = "GENERAL.CREATED_AT";
                    width = 9;
                    visible = false;
                    cellFilter = "date:'dd/MM/yyyy hh:mm:ss'";
                    break;
                case "MODIFIED_BY":
                    name = columns[i];
                    displayName = "GENERAL.MODIFIED_BY";
                    width = 15;
                    visible = false;
                    break;
                case "MODIFIED_DATE":
                    name = columns[i];
                    displayName = "GENERAL.MODIFIED_DATE";
                    width = 9;
                    visible = false;
                    cellFilter = "date:'dd/MM/yyyy hh:mm:ss'";
                    break;
                case "SCORE":
                    name = columns[i];
                    displayName = "BASIC_DATA.SCORE";
                    width = 7;
                    break;
                case "PRODUCT":
                    name = columns[i] + ".CRITERIA.NAME";
                    displayName = "BASIC_DATA.PRODUCT";
                    width = 10;
                    cellTemplate = '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.PRODUCT.CRITERIA)}}</div>'
                    visible = false
                    break;
                case "CUSTOMER":
                    name = columns[i] + ".CRITERIA.NAME";
                    displayName = "BASIC_DATA.CLIENT";
                    width = 10;
                    cellTemplate = '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.CUSTOMER.CRITERIA)}}</div>'
                    visible = false
                    break;
                case "EXPORTER":
                    name = columns[i] + ".CRITERIA.NAME";
                    displayName = "BASIC_DATA.SHIPPER";
                    width = 10;
                    cellTemplate = '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.EXPORTER.CRITERIA)}}</div>'
                    visible = false
                    break;
                case "IMPORTER":
                    name = columns[i] + ".CRITERIA.NAME";
                    displayName = "BASIC_DATA.CONSIGNEE";
                    width = 10;
                    cellTemplate = '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.IMPORTER.CRITERIA)}}</div>'
                    visible = false
                    break;
                case "LOCAL_AGENT":
                    name = columns[i] + ".CRITERIA.NAME";
                    displayName = "BASIC_DATA.LOCAL_AGENT";
                    width = 10;
                    cellTemplate = '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.LOCAL_AGENT.CRITERIA)}}</div>'
                    visible = false
                    break;
                case "EXTERNAL_AGENT":
                    name = columns[i] + ".CRITERIA.NAME";
                    displayName = "BASIC_DATA.OVERSEAS_AGENT";
                    width = 10;
                    cellTemplate = '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.EXTERNAL_AGENT.CRITERIA)}}</div>'
                    visible = false
                    break;
                case "SERVICE_PROVIDER":
                    name = columns[i] + ".CRITERIA.NAME";
                    displayName = "BASIC_DATA.PROVIDER";
                    width = 10;
                    cellTemplate = '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.SERVICE_PROVIDER.CRITERIA)}}</div>'
                    visible = false
                    break;
                case "INCOTERM":
                    name = columns[i] + ".CRITERIA.NAME";
                    displayName = "BASIC_DATA.INCOTERM";
                    width = 10;
                    cellTemplate = '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.INCOTERM.CRITERIA)}}</div>'
                    visible = false
                    break;
                case "BRANCH":
                    name = columns[i] + ".CRITERIA.NAME";
                    displayName = "BASIC_DATA.BRANCH";
                    width = 10;
                    cellTemplate = '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.BRANCH.CRITERIA)}}</div>'
                    visible = false
                    break;
                case "HOUSE_PAYMENT":
                    name = columns[i] + ".CRITERIA.NAME";
                    displayName = "BASIC_DATA.HOUSE_PAYMENT_MODE";
                    width = 10;
                    cellTemplate = '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.HOUSE_PAYMENT.CRITERIA)}}</div>'
                    visible = false
                    break;
                case "MASTER_PAYMENT":
                    name = columns[i] + ".CRITERIA.NAME";
                    displayName = "BASIC_DATA.MASTER_PAYMENT_MODE";
                    width = 10;
                    cellTemplate = '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.MASTER_PAYMENT.CRITERIA)}}</div>'
                    visible = false
                    break;
                case "ACTIVE":
                    name = columns[i];
                    displayName = "GENERAL.ACTIVE";
                    width = 5;
                    cellFilter = "YesOrNo";
                    break;
                default:
                    continue;
            }

            const newColumn = {
                name: name,
                displayName: displayName,
                headerCellClass: this.$gridService.highlightFilteredHeader.bind(this.$gridService),
                width: width + "%",
                cellTemplate: cellTemplate,
                cellFilter: cellFilter,
                visible: visible,
                filter: filter,
                searchProps: searchProps,
                filterCellFiltered: filterCellFiltered,
            };
            columnDefs.push(newColumn);
        }
        return columnDefs;
    }

    initScopeFunctions(): void {
        this.$scope.getTagsByCategory = async (category: SelectorModel) => {
            return await this.getTagsByCategory(category);
        }

        this.$scope.changeCategory = async (oldValue: SelectorModel) => {
            await this.changeCategory(oldValue);
        }

        this.$scope.isInvoiceGroupReadyOnly = () => {
            return this.isInvoiceGroupReadyOnly();
        }

        this.$scope.isTransactionReadyOnly = () => {
            return this.isTransactionReadyOnly();
        }

        this.$scope.isTransactionRequired = () => {
            return this.isTransactionRequired();
        }

        this.$scope.isPaymentNatureReadyOnly = () => {
            return this.isPaymentNatureReadyOnly();
        }

        this.$scope.isFinancialSituationReadyOnly = () => {
            return this.isFinancialSituationReadyOnly();
        }

        this.$scope.getAccountListByName = async (term: string): Promise<void> => {
            return await this.getAccountListByName(term);
        }

        this.$scope.getExporterListByName = async (term: string): Promise<void> => {
            return await this.getExporterListByName(term);
        }

        this.$scope.getImporterListByName = async (term: string): Promise<void> => {
            return await this.getImporterListByName(term);
        }

        this.$scope.getAgentListByName = async (term: string): Promise<void> => {
            return await this.getAgentListByName(term);
        }

        this.$scope.getServiceProviderListByName = async (term: string): Promise<void> => {
            return await this.getServiceProviderListByName(term);
        }

        this.$scope.getIncotermListByName = async (term: string): Promise<void> => {
            return await this.getIncotermListByName(term);
        }

        this.$scope.getCorporateBranchListByName = async (term: string): Promise<void> => {
            this.$scope.corporateBranchList = [];
            if (term && term.length >= 3) this.$scope.corporateBranchList = await this.getCorporateBranchListByName(term);
        }

        this.$scope.getRoutingPointListByName = async (search: string): Promise<void> => {
            return await this.getRoutingPointListByName(search);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            NAME: null,
            TEMPLATE: null,
            CATEGORY: null,
            DOCUMENT_TYPE: null,
            LANGUAGE: null,
            PRODUCT: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            CUSTOMER: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            EXPORTER: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            IMPORTER: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            LOCAL_AGENT: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            EXTERNAL_AGENT: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            SERVICE_PROVIDER: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            INCOTERM: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            BRANCH: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            HOUSE_PAYMENT: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            MASTER_PAYMENT: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            CARGO_TYPE: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null,
            },
            TAG: null,
            TRANSACTION: null,
            PAYMENT_NATURE: null,
            SPEC: null,
            GROUP: null,
            INVOICE_GROUP: InvoiceGroup,
            SCORE: 0,
            PERMISSION: ReportPermissionAutomaticManual,
            FINANCIAL_SITUATION: null,
            CREATED_BY: null,
            CREATED_DATE: null,
            MODIFIED_BY: null,
            MODIFIED_DATE: null,
            ACTIVE: true,
            PAYMENT_MODALITY: null,
            TYPE: ProcessDocumentType,
            DESTINATION_AIRPORT: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null
            },
            ORIGIN_AIRPORT: {
                STATUS: false,
                EXISTS: true,
                CRITERIA: null
            },
            PAYMENT_CONDITION: null,
            CONVERSION_REFERENCE: null,
        }
    }

    getUrlDataManager() {
        const baseRoute = '/data-manager';
        const urlDataManager = this.config.reportDataManagerUrl + baseRoute;
        return urlDataManager;
    }

    async initDependencies(): Promise<boolean> {
        try {
            const result: Array<any> = await this.$q.all([
                this.getReportList(),
                this.getGenericType("language"),
                this.getGenericType("document_type"),
                this.getGenericType("product"),
                this.getGenericType("report_category"),
                this.getGenericType("transaction"),
                this.getGenericType("file_specs"),
                this.getGenericType("file_group"),
                this.getGenericType("report_invoice_group"),
                this.getGenericType("report_permission"),
                this.getGenericType("type_payment"),
                this.getGenericType("type_cargo"),
                this.getGenericType("payment_nature"),
                this.getGenericType("invoice_financial_situation"),
                this.getGenericAlternativeAsCodeList("process_document_type"),
                this.getGenericType("payment_condition"),
                this.getGenericType("conversion_reference"),
            ]);

            if (!result || result.length === 0) return false;

            this.$scope.reportList = result[0];
            this.$scope.languageList = result[1];
            this.$scope.documentTypeList = result[2];
            this.$scope.productList = result[3];
            this.$scope.categoryList = result[4];
            this.$scope.transactionList = result[5];
            this.$scope.specList = result[6];
            this.$scope.groupList = result[7];
            this.$scope.invoiceGroupList = result[8];
            this.$scope.permissionList = result[9];
            this.$scope.paymentList = result[10];
            this.$scope.paymentModalityList = result[10];
            this.$scope.cargoTypeList = result[11];
            this.$scope.paymentNatureList = result[12];
            this.$scope.financialSituationList = result[13];
            this.$scope.processDocumentTypeList = result[14];
            this.$scope.paymentConditionList = result[15];
            this.$scope.conversionReferenceList = result[16];

            this.$scope.tagList = [];
            this.$scope.accountList = [];

            if (typeof this.$scope.reportList != "object") this.handleLoadError("Object type not defined");

            return true;
        } catch (ex) {
            const msgError = this.formService.getTranslate("REGISTRATION.MESSAGES.ERROR.DEPENDENCY_LIST_FAILED");
            this.handleLoadError(`${msgError} ${JSON.stringify(ex)}`);
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate('REGISTRATION.NEW_AUTOMATION_RULE');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            await this.initDependencies();
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} - ${this.$scope.model.NAME}`;
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} - ${this.$scope.model.NAME}`;
            BrowserTitle.$id = this.$scope.model.NAME;

            await this.getTagsByCategory(this.$scope.model.CATEGORY);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.COPYING')} - ${this.$scope.model.NAME}`;
            BrowserTitle.$id = this.$scope.model.NAME;

            await this.getTagsByCategory(this.$scope.model.CATEGORY);
            await this.clearFields(this.$scope.model);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request() {
        const request = {
            operation: this.$scope.operation,
            data: this.$scope.model,
            timeout: 120000
        };

        return request;
    }

    async save(): Promise<boolean> {
        try {
            if (this.$scope.model.TEMPLATE && this.$scope.model.CATEGORY) {
                this.$scope.model.TEMPLATE.CODE = this.$scope.model.CATEGORY.ID;
            }
            return true;
        } catch (ex) {
            this.handleLoadError(ex);
        }
        return false;
    }

    private async getReportList(): Promise<SelectorModel> {
        const request = await this.DataManagerService.get("/reportTemplate/report/list", null, 120000);
        const reportList = request.data.data;
        return reportList;
    }

    private async getGenericType(identifier: string): Promise<SelectorModel> {
        const { data: generic } = await this.helperService.get(`/generic/value/${identifier}`, null, 120000);
        return generic && generic.data ? generic.data : [];
    }

    private async getGenericAlternativeAsCodeList(identifier: string): Promise<ISelectorModel[]> {
        try {
            const { data: generic } = await this.helperService.get(`/generic/getByIdentifier/${identifier}`, null, 15000);
            if ((generic.status !== 200) || (!generic.data)) return null;
            const selectModelList: ISelectorModel[] = generic.data.map(item => {
                return {
                    ID: item.CODE,
                    NAME: item.VALUE,
                    CODE: item.ALTERNATIVE,
                }
            })

            return selectModelList;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getTagsByCategory(category: SelectorModel): Promise<void> {
        try {
            if (!category || !category.NAME) {
                const msgError = this.formService.getTranslate("REGISTRATION.MESSAGES.ERROR.ERROR_CATEGORY");
                this.handleWarning(msgError);
                return null;
            }

            this.$scope.tagList = [];

            this.$scope.$applyAsync();

            const categoryName = StringUtil.formatString(category.NAME);

            const { data: generic } = await this.helperService.get(`/generic/value/${categoryName}`, null, 10000);

            this.$scope.tagList = generic && generic.data ? generic.data : [];
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getAccountListByName(search: string): Promise<void> {
        try {
            this.$scope.accountList = []

            if (search && search.length >= 3) {
                this.block();
                const request: IMonacoRequest = {
                    data: {
                        search
                    },
                    route: `/account/list/custom`,
                    timeout: 30000,
                }
                const result = await this.ProductService.post(request)
                if (result && result.data && result.status == 200) {
                    const accountList: IAccountSelector[] = result.data.data.data;
                    for (const resultItem of accountList) {
                        const account: ISelectorModel = { ID: resultItem.ID, CODE: (resultItem.LEGAL_PERSON && resultItem.LEGAL_PERSON.ID_FISCAL) ? resultItem.LEGAL_PERSON.ID_FISCAL : null, NAME: resultItem.NAME }
                        this.$scope.accountList.push(account);
                    }
                }
                this.unblock();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getExporterListByName(search: string): Promise<void> {
        try {
            this.$scope.exporterList = [];
            if (search && search.length >= 3) this.$scope.exporterList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.EXPORTER], search: search });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getImporterListByName(search: string): Promise<void> {
        try {
            this.$scope.importerList = [];
            if (search && search.length >= 3) this.$scope.importerList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.IMPORTER], search: search });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getLegalPersonListByName(filter: ILegalPersonListCustomFilter): Promise<SelectorModel[]> {
        try {
            this.block();
            const request: IMonacoRequest = {
                data: {
                    ...filter
                },
                route: `/legalPerson/list/custom`,
                timeout: 30000,
            }
            const result = await this.ProductService.post(request)
            const legalPersonResult: ISelectorModel[] = [];
            if (result && result.data && result.status == 200) {
                const legalPersonList: ILegalPersonSelector[] = result.data.data;
                for (const resultItem of legalPersonList) {
                    const legalPerson: ISelectorModel = { ID: resultItem.ID, CODE: resultItem.CORPORATE_NAME, NAME: resultItem.SHORT_NAME }
                    legalPersonResult.push(legalPerson);
                }
            }
            this.unblock();
            return legalPersonResult
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getCorporateBranchListByName(search?: string, product?: ISelectorModel): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const corporateBranchs = await this.ProductService.post({ route: `/corporateBranch/list/custom`, data: { search: search, products: product ? [product.ID] : null } });
            if (corporateBranchs && corporateBranchs.data && corporateBranchs.data.data) result = corporateBranchs.data.data.map(corporateBranch => { return { ID: corporateBranch.ID, NAME: corporateBranch.NAME, CODE: corporateBranch.CODE } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getRoutingPointListByName(name: string): Promise<void> {
        try {
            this.$scope.routingPointList = [];

            if (name && name.length >= 3) {
                this.block();

                const request: IMonacoRequest = {
                    data: { name, types: [] },
                    route: `/routingPoint/list/custom`,
                    timeout: 30000,
                };
    
                const rc = await this.ProductService.post(request, false);
                let routingPointList = (rc && rc.data && rc.data.data && rc.data.data.data) ? rc.data.data.data : null;
    
                for (const item of routingPointList) {
                    const selectorModel: ISelectorModel = {
                        ID: item.ID,
                        NAME: item.NAME,
                        CODE: item.CODE
                    }

                    this.$scope.routingPointList.push(selectorModel);
                }

                this.unblock();
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getAgentListByName(search: string): Promise<void> {
        try {
            this.$scope.agentList = []
            if (search && search.length >= 3) {
                this.block();

                const productList = (this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.CRITERIA) ? this.$scope.model.PRODUCT.CRITERIA.map(item => item.ID) : [];
                const filter: IAgentListCustomFilter = { search: search, networks: null, products: productList }
                const request: IMonacoRequest = {
                    data: {
                        ...filter
                    },
                    route: `/agent/list/custom`,
                    timeout: 30000,
                }
                const result = await this.ProductService.post(request)
                if (result && result.data && result.status == 200) {
                    const agentList: IAgentSelector[] = result.data.data.data;
                    for (const resultItem of agentList) {
                        const legalPerson: ISelectorModel = { ID: resultItem.ID, NAME: resultItem.NAME, CODE: resultItem.NETWORK_ID }
                        this.$scope.agentList.push(legalPerson);
                    }
                }
                this.unblock();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getServiceProviderListByName(search: string): Promise<void> {
        try {
            this.$scope.serviceProviderList = [];
            if (search && search.length >= 3) {
                this.block();

                const request: IMonacoRequest = {
                    data: {
                        search
                    },
                    route: `/provider/list/custom`,
                    timeout: 30000,
                }
                const result = await this.ProductService.post(request)
                if (result && result.data && result.status == 200) {
                    const providerList: IServiceProviderSelector[] = result.data.data;
                    for (const resultItem of providerList) {
                        const serviceProvider: ISelectorModel = { ID: resultItem.ID, CODE: resultItem.SCAC_IATA, NAME: resultItem.NAME }
                        this.$scope.serviceProviderList.push(serviceProvider);
                    }
                }
                this.unblock();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getIncotermListByName(search: string): Promise<void> {
        try {
            this.$scope.incotermList = [];
            if (search && search.length >= 3) {
                this.block();
                const productList = (this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.CRITERIA) ? this.$scope.model.PRODUCT.CRITERIA.map(item => item.ID) : [];
                const filter = { search: search, products: productList }
                const request: IMonacoRequest = {
                    data: {
                        ...filter
                    },
                    route: `/incoterm/list/custom`,
                    timeout: 30000,
                }
                const result = await this.ProductService.post(request)
                if (result && result.data && result.status == 200) {
                    const providerList: IIncotermSelector[] = result.data.data;
                    for (const resultItem of providerList) {
                        const incoterm: ISelectorModel = { ID: resultItem.ID, CODE: resultItem.INITIALS, NAME: resultItem.NAME }
                        this.$scope.incotermList.push(incoterm);
                    }
                }
                this.unblock();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async changeCategory(oldValue: SelectorModel) {
        try {
            if (this.$scope.model.TAG && this.$scope.model.TAG.length > 0 && this.$scope.model.CATEGORY && this.$scope.model.CATEGORY.ID !== oldValue.ID) {
                const yes = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: 'GENERAL.YES',
                    headerText: 'REGISTRATION.CATEGORY',
                    bodyText: this.formService.getTranslate('REGISTRATION.MESSAGES.ERROR.CATEGORY_CHANCE')
                });
                if (!yes) {
                    this.$scope.model.CATEGORY = oldValue;
                    return;
                }

                this.$scope.model.TAG = null;
            }

            this.$scope.model.INVOICE_GROUP = InvoiceGroup;
            this.$scope.model.TRANSACTION = null;
            this.$scope.model.PAYMENT_NATURE = null;
            this.$scope.model.PAYMENT_MODALITY = null;

            await this.getTagsByCategory(this.$scope.model.CATEGORY);

            this.$scope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private isTransactionReadyOnly(): boolean {
        return (!this.$scope.model.CATEGORY ||
            this.$scope.model.CATEGORY.ID !== "2" &&
            this.$scope.model.CATEGORY.ID !== "12" &&
            this.$scope.model.CATEGORY.ID !== "13" &&
            this.$scope.model.CATEGORY.ID !== "15"
        );
    }

    private isTransactionRequired(): boolean {
        return false;
        /*
        return (this.$scope.model.CATEGORY &&
            this.$scope.model.CATEGORY.ID === "2" ||
            this.$scope.model.CATEGORY.ID === "12" ||
            this.$scope.model.CATEGORY.ID === "13" ||
            this.$scope.model.CATEGORY.ID === "15"
        );
        */
    }

    private isPaymentNatureReadyOnly(): boolean {
        return (!this.$scope.model.CATEGORY ||
            this.$scope.model.CATEGORY.ID !== "2" &&
            this.$scope.model.CATEGORY.ID !== "12" &&
            this.$scope.model.CATEGORY.ID !== "13" &&
            this.$scope.model.CATEGORY.ID !== "15"
        );
    }

    private isFinancialSituationReadyOnly(): boolean {
        return (!this.$scope.model.CATEGORY ||
            (this.$scope.model.CATEGORY.ID !== "2" &&
            this.$scope.model.CATEGORY.ID !== "12" &&
            this.$scope.model.CATEGORY.ID !== "13" &&
            this.$scope.model.CATEGORY.ID !== "15")
        );
    }

    private isInvoiceGroupReadyOnly(): boolean {
        return (!this.$scope.model.CATEGORY || this.$scope.model.CATEGORY.ID !== "12");
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: "NAME",
                LABEL: "GENERAL.NAME"
            },
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE"
            },
            {
                PROPERTY: "TEMPLATE",
                LABEL: "REGISTRATION.TEMPLATE"
            },
            {
                PROPERTY: "CATEGORY",
                LABEL: "REGISTRATION.CATEGORY"
            },
            {
                PROPERTY: "DOCUMENT_TYPE",
                LABEL: "REGISTRATION.DOCUMENT_TYPE"
            },
            {
                PROPERTY: "LANGUAGE",
                LABEL: "GENERAL.LANGUAGE"
            },
            {
                PROPERTY: "CREATED_BY",
                LABEL: "GENERAL.CREATED_BY"
            },
            {
                PROPERTY: "CREATED_DATE",
                LABEL: "GENERAL.CREATED_AT"
            },
            {
                PROPERTY: "MODIFIED_BY",
                LABEL: "GENERAL.MODIFIED_BY"
            },
            {
                PROPERTY: "MODIFIED_DATE",
                LABEL: "GENERAL.MODIFIED_DATE"
            },
            {
                PROPERTY: "STATUS",
                LABEL: "GENERAL.ACTIVE"
            },
            {
                PROPERTY: "EXISTS",
                LABEL: "REGISTRATION.CONTAINS"
            },
            {
                PROPERTY: "CRITERIA",
                LABEL: "REGISTRATION.CRITERIA"
            },
            {
                PROPERTY: "PRODUCT",
                LABEL: "BASIC_DATA.PRODUCT"
            },
            {
                PROPERTY: "CUSTOMER",
                LABEL: "BASIC_DATA.CLIENT"
            },
            {
                PROPERTY: "EXPORTER",
                LABEL: "BASIC_DATA.SHIPPER"
            },
            {
                PROPERTY: "IMPORTER",
                LABEL: "BASIC_DATA.CONSIGNEE"
            },
            {
                PROPERTY: "ORIGIN_AGENT",
                LABEL: "BASIC_DATA.LOCAL_AGENT"
            },
            {
                PROPERTY: "DESTINATION_AGENT",
                LABEL: "BASIC_DATA.OVERSEAS_AGENT"
            },
            {
                PROPERTY: "SERVICE_PROVIDER",
                LABEL: "BASIC_DATA.PROVIDER"
            },
            {
                PROPERTY: "INCOTERM",
                LABEL: "BASIC_DATA.INCOTERM"
            },
            {
                PROPERTY: "BRANCH",
                LABEL: "BASIC_DATA.BRANCH"
            },
            {
                PROPERTY: "HOUSE_PAYMENT",
                LABEL: "BASIC_DATA.HOUSE_PAYMENT_MODE"
            },
            {
                PROPERTY: "MASTER_PAYMENT",
                LABEL: "BASIC_DATA.MASTER_PAYMENT_MODE"
            },
            {
                PROPERTY: "CARGO_TYPE",
                LABEL: "BASIC_DATA.CARGO_TYPE"
            },
            {
                PROPERTY: "TAG",
                LABEL: "REGISTRATION.TAGS"
            },
            {
                PROPERTY: "TRANSACTION",
                LABEL: "BASIC_DATA.TRANSACTION"
            },
            {
                PROPERTY: "PAYMENT_NATURE",
                LABEL: "REGISTRATION.TRANSACTION"
            },
            {
                PROPERTY: "SPEC",
                LABEL: "REGISTRATION.CHARACTERISTICS"
            },
            {
                PROPERTY: "GROUP",
                LABEL: "GENERAL.GROUP"
            },
            {
                PROPERTY: "INVOICE_GROUP",
                LABEL: "REGISTRATION.INVOICE_GROUPING"
            },
            {
                PROPERTY: "FINANCIAL_SITUATION",
                LABEL: "REGISTRATION.FINANCIAL_STATUS"
            },
            {
                PROPERTY: "PERMISSION",
                LABEL: "REGISTRATION.PERMISSION"
            },
            {
                PROPERTY: "SCORE",
                LABEL: "BASIC_DATA.SCORE"
            },
            {
                PROPERTY: "PAYMENT_MODALITY",
                LABEL: "FINANCIAL.PAYMENT_METHOD"
            },
            {
                PROPERTY: "PAYMENT_CONDITION",
                LABEL: "GENERAL.INVOICE_PAYMENT_TERM"
            },
            {
                PROPERTY: "CONVERSION_REFERENCE",
                LABEL: "REGISTRATION.EXC_RATE_REFERENCE_DATE"
            },
        ];
        return props;
    }
}

import { IColumnDef } from "ui-grid";
import { SSEService } from "@appServices/SSEService";
import { IGridFormServiceScope, GridFormService } from "@services/GridFormService";
import { IMonacoColumnDef } from "@services/GridService2";
import { ICustomLogProperties, IViewLog } from "@models/interface/common/IViewLog";
import { ConnectedUsersModel } from "../model/ConnectedUsersModel";
import { GridColumnBuilder} from "../../common/GridColumnBuilder";
import { SelectorModel } from "../../common/model/SelectorModel";

interface IConnectedUsersScope extends IGridFormServiceScope {
    model: ConnectedUsersModel;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[]
    scopeBeforeSave: ConnectedUsersModel;
    userTargetList: SelectorModel[]
    userTargetFilterTypeList: SelectorModel[];
    userTargetFilterFieldList: SelectorModel[];
    userTargetUserFieldList: SelectorModel[];
    httpMethodList: SelectorModel[];
    microserviceList: SelectorModel[];
}

export class ConnectedUsersController extends GridFormService  {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IConnectedUsersScope;
    private $q: ng.IQService;
    private SSEService: SSEService;

    constructor($injector: ng.Injectable<any>, $scope: IConnectedUsersScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.SSEService = new SSEService($injector, $scope, this.formService);
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.SSEService.getRoute();
            this.initForm(this, 'form', '', 'GENERAL.MENU.CONNECTED_USER', false);
            await this.initGrid('gridConectedUser', '/blocked/list', true, true, null, true, true);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initDependencies(): Promise<any> {
        const self: ConnectedUsersController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([]).then(async (result: any) => {
                self.$scope.userTargetFilterFieldList = result[0].data;
                self.$scope.userTargetFilterTypeList = result[1].data;
                self.$scope.userTargetFilterFieldList = result[2].data;
                self.$scope.userTargetUserFieldList = result[3].data;
                self.$scope.httpMethodList = result[4].data;
                self.$scope.microserviceList = result[5].data;
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {};
    
    initGridColumns(columns: string[]): IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        gridColumns.$columnDefs.push({
            name: "acoes",
            displayName: "",
            width: '0%',
            cellTemplate: (''),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colUser: IMonacoColumnDef = { name: "USER.CODE", displayName: "GENERAL.USER", width: 350 };
            const colObject: IMonacoColumnDef = { name: "BLOCKED_OBJECT.ID", displayName: "ID", width: 150};
            const colFormName: IMonacoColumnDef = { name: "FORM_NAME", displayName: "GENERAL.FORM_NAME", width: 150};
            const colDate: IMonacoColumnDef = { name: "DATE", displayName: "GENERAL.DATE", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'USER':
                        columnDefs.push(colUser);
                        break;
                    case 'BLOCKED_OBJECT':
                        columnDefs.push(colObject);
                        break;
                    case 'FORM_NAME':
                        columnDefs.push(colFormName);
                        break;
                    case 'DATE':
                        columnDefs.push(colDate);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }
    initModel(): void {}
}
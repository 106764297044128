import { IModalOptions, IModalService } from '@services/ModalService';
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { OperationalService } from "@services/OperationalService";
import { IMonacoScope } from '../../common/MonacoInterface';
import { IProcessCargoReadinessHistoryStructure } from 'WBA-Model/dist/interface/dataProcess/ProcessCargoReadinessHistory';
import { IEquipment } from 'WBA-Model/dist/interface/dataProcess/ProcessCargoReadinessHistory';
import angular = require('angular');
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { WizardStopoverController } from './WizardStopoverController';


interface CargoReadinessHistoryModalScope extends IFormServiceScope {
    modalService: IModalService;
    modalOptions: IModalOptions;
    idProcess: string;
    cargoReadinessHistoryResult: IProcessCargoReadinessHistoryStructure;
    compile: angular.ICompileService;
    currentDeadline: any;

    calcularTotalContainers(equipments: IEquipment[]): number;
    calculateTotalTeu(equipments: IEquipment[]): number;
    openProcessDeadlineModal: (readonly: any) => void;
}

export class CargoReadinessHistoryModalController extends FormService2 {
    static $inject: string[] = ['$injector', '$scope'];
    private scope: CargoReadinessHistoryModalScope;
    private rootScope: IMonacoScope;
    private $q: ng.IQService;
    private OperationalService: OperationalService;
    private modalApproveNotificationReasonId: number;
    private $sce: angular.ISCEService;
    private parent: WizardStopoverController;

    constructor($injector: ng.Injectable<any>, $scope: CargoReadinessHistoryModalScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.scope.modalService = $injector.get('ModalService');
        this.scope.compile = $injector.get('$compile');
        this.rootScope = $injector.get('$rootScope');
        this.$q = $injector.get('$q');
        this.OperationalService = $injector.get('OperationalService');
        this.$sce = $injector.get('$sce');
    }

    async $onInit(): Promise<void> {
        try {
            this.block();
            this.init("cargoReadinessHistoryModalForm", null, null);
            this.initScopeFunctions();
            await this.initDependencies();
            await this.initModel();
            await this.getCargoReadinessHistoryList();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    private initScopeFunctions(): void {
        this.scope.calcularTotalContainers = (equipments: IEquipment[]) => { return equipments ? equipments.reduce((sum, item) => sum + item.QTD_CONTAINER, 0) : 0; }
        this.scope.calculateTotalTeu = (equipments: IEquipment[]) => { return equipments ? equipments.reduce((sum, item) => sum + (item.QTD_TEU), 0) : 0; }
        this.scope.openProcessDeadlineModal = (readonly: boolean) => {
            this.openProcessDeadlineModal(readonly);
        }
    }

    async initDependencies(): Promise<any> {
        try {
            const self: CargoReadinessHistoryModalController = this;
            return new Promise(function (resolve, reject) {
                self.$q.all([

                ]).then((result: any) => {

                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async initModel(): Promise<void> {
        this.scope.cargoReadinessHistoryResult = null;
    }

    private async getCargoReadinessHistoryList() {
        const historyRequest = await this.OperationalService.post("/cargo/readinessHistory", { idProcess: this.scope.idProcess });
        if (historyRequest && historyRequest.data && historyRequest.data.data) {
            const historyResult: IProcessCargoReadinessHistoryStructure = historyRequest.data.data;
            this.scope.cargoReadinessHistoryResult = historyResult;
            this.scope.$applyAsync();
        }
    }

    private async openProcessDeadlineModal(readonly: any): Promise<void> {
        this.scope.currentDeadline = readonly;
        this.modalApproveNotificationReasonId = this.scope.modalService.newModal();
        const discardChanges = () => this.scope.modalService.closeModal(this.modalApproveNotificationReasonId);

        const modalInstance: IModalInstanceService = await this.scope.modalService.showModalInfo(
            {
                modalID: this.modalApproveNotificationReasonId,
                scope: this.scope,
                template: require("../view/modal/readniessHistoryDeadlineHouseModal.html"),
                formService: 'view',
                size: 'md',
            },
            {
                actionButtonClass: 'btn-default',
                actionButtonText: 'GENERAL.CLOSE',
                headerText: 'OPERATIONAL.DEADLINES_HISTORY',
            },
            {
                discardChanges
            }
        );

        modalInstance.rendered.then(() => {
            const modifiedTypeSelect = angular.element("#resonModal");
            if (modifiedTypeSelect) this.scope.compile(modifiedTypeSelect)(this.scope);
        });

    }
}

import * as angular from 'angular';

/**
* Diretiva para exibição do checkbox button
*/
export function labelCheckboxDirective() {
    let ddo: any = {};

    ddo.restrict = "A";
    ddo.transclude = true;
    ddo.require = 'ngModel';
    ddo.replace = true;
    ddo.$scope = true;
    ddo.template = require("../view/template/label-checkbox.html");

    ddo.scope = {
        id: "@",

        textTrue: "@",
        iconTrue: "@",
        textFalse: "@",
        iconFalse: "@",
    };

    ddo.link = function ($scope, $element, $attr, ngModel) {
        $scope.value = () => { return ngModel.$viewValue; }

        if (angular.isUndefined($scope.iconTrue))
            $scope.iconTrue = 'check';

        if (angular.isUndefined($scope.iconFalse))
            $scope.iconFalse = 'ban';

        if (angular.isUndefined($scope.textTrue))
            $scope.textTrue = 'use text-true="texto desejado"';

        if (angular.isUndefined($scope.textFalse))
            $scope.textFalse = 'use text-false="texto desejado"';

    }

    return ddo;
}
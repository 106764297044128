import * as angular from "angular";
import * as moment from 'moment';
import * as intlTelInput from 'intl-tel-input';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoConfig } from '../../common/MonacoInterface';
import { IMonacoColumnDef } from "@services/GridService2";
import { IModalService } from "@services/ModalService";
import { ISessionService } from "@services/SessionService";
import { ExternalService } from "@services/ExternalService";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { ICity } from "@models/interface/product/CityModel";
import { ILegalPersonModel, IPartner, ISpec, ISpecsWithRegistration, ILegalPersonExchangeData, IPersonContacts } from "../model/LegalPersonModel";
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { ICurrentAccount, ICurrentAccountModal } from '../interface/ICurrentAccount';
import { SelectorModel } from "../../common/model/SelectorModel";
import { IZipCodeResult } from "../../external/interface/ZipCode";
import { ICnpjResult, ISocio } from "../../external/interface/Cnpj";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { IFloatingMenu } from "../../common/interface/IFloatingMenu";
import { ISpecParameter, ILinkParameter } from "../../common/model/ModelParameter";
import { INetworkModel } from "../model/NetworkModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { StringUtil } from "../../common/util/StringUtil";
import { ValidateUtil } from "../../common/util/ValidateUtil";
import { DataProductService } from "@services/DataProductService";
import { IModalInstanceService } from "angular-ui-bootstrap";
import { IDocumentError } from '@models/interface/common/IDocumentError';
import { SSEService } from "@appServices/SSEService";
import { EOperation } from "@enums/GenericData";
import { HelperService } from "@services/HelperService";

interface ISpecRoute {
    URL: string;
    MODEL: ISpecsWithRegistration;
}

interface ISpecialization extends SelectorModel {
    ROUTE_URL: string;
}

interface ILegalPersonScope extends IGridFormServiceScope {
    model: ILegalPersonModel;
    gridOptions: uiGrid.IGridOptions;
    log: IViewLog;
    taxIdTypeList: SelectorModel[];
    branchTypeList: SelectorModel[];
    specTypeList: ISpecialization[];
    pixTypeList: SelectorModel[];
    cityList: SelectorModel[];
    empERPList: SelectorModel[];
    topBillingERPList: SelectorModel[];
    specSituationList: SelectorModel[];
    networkList: SelectorModel[];
    customLogProperties: ICustomLogProperties[];
    menuFloating: IFloatingMenu;
    scopeBeforeSave: ILegalPersonModel;
    user: any;
    sessionService: ISessionService;
    editLegalPerson: (legalPerson: ILegalPersonModel) => Promise<void>;
    viewLegalPerson: (legalPerson: ILegalPersonModel) => Promise<void>;
    viewLogLegalPerson: (legalPerson: ILegalPersonModel) => Promise<void>;
    copyLegalPerson: (legalPerson: ILegalPersonModel) => Promise<void>;
    getCityListByNameOrInitials: (search: string) => Promise<void>;
    getNetworkListByName: (name: string) => Promise<void>;
    addCurrentAccount: () => void;
    editCurrentAccount: (index: number, currentAccount: ICurrentAccount) => void;
    setIntegrationsFieldsNullable: () => void
    removeCurrentAccount: (index: number) => void;
    addSpec: () => void;
    removeSpec: (index: number) => void;
    addPartner: () => void;
    removePartner: (index: number) => void;
    getCnpj: (cnpj: string) => void;
    getZipCode: (zipCode: string) => void;
    collapseHeader: (elementId: string, state?: string) => void;
    goToNetwork: (id: number) => void;
    goToSpec: (specialty: ISpec) => void;
    goToCity: (id: number) => void;
    goToProvince: (id: number) => void;
    goToCountry: (id: number) => void;
    goToContact: (id: number, isAdd?: boolean) => void;
    correspondenceGenerate: () => void;
    hasSpecRegistration: (type: SelectorModel) => boolean;
    updateSpecType: (index: number) => void;
    limitLines: (value: string, limit: number) => string;
    initPanels: () => void;
    insertNetwork: (name: string) => Promise<void>;
    validityPhone: (inputName: string) => void;
    getPhoneValidityMessage: (inputName: string) => string;
    preventSpecialChars: (event, element) => void;
    findIntegrationId: () => Promise<void>;
    findCurrentAccountIntegrationId: (currentAccount: ICurrentAccount) => Promise<void>;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
    integrateSankhya: (id: number) => void;
    integrateSankhyaStatus: (id: number) => void;
    getCodEmpERP: (search: string) => Promise<void>;
    getTopBillingERP: (search: string) => Promise<void>;
    isCompany: () => boolean;
    buildErrorSankhyaTooltip: (errors: IDocumentError[]) => string;
    fetchData: (id: number, action: string) => Promise<void>;
    updateContactList: (idLegalPerson: number) => Promise<void>;
}

export class LegalPersonRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: ILegalPersonScope;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private RestService: IRestService;
    private ExternalService: ExternalService;
    private ModalService: IModalService;
    private modalID: number;
    private collapseState: string;
    private specRegistrationIdList: string[];
    private intlMainPhone: intlTelInput.Plugin;
    private countryChangeListener: EventListenerOrEventListenerObject;
    private dataProductService: DataProductService;
    private monacoConfig: IMonacoConfig;
    private gridName: string;
    private SSEService: SSEService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: ILegalPersonScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.RestService = $injector.get('RestService');
        this.ExternalService = $injector.get('ExternalService');
        this.ModalService = $injector.get('ModalService');
        this.modalID = null;
        this.collapseState = "hide";
        this.$scope.sessionService = $injector.get('SessionService');
        this.dataProductService = $injector.get('DataProductService');
        this.monacoConfig = $injector.get('config');
        this.gridName = 'GRID_LEGAL_PERSON';
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.customLogProperties = this.getCustomLogProperties();

            this.initForm(this, 'form', 'legalPerson', 'GENERAL.MENU.LEGAL_PERSON', true);
            await this.initGrid(this.gridName, '/legalPerson/list', true, true, null, true, true);
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
        this.destroyPhoneComponent();
    }

    initScopeFunctions(): void {
        this.$scope.editLegalPerson = async (legalPerson: ILegalPersonModel): Promise<void> => {
            let blockedObject = {
                ID: legalPerson.ID,
                NAME: legalPerson.SHORT_NAME,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || legalPerson.ID !== this.$scope.model.ID) this.$scope.fetchData(legalPerson.ID, EOperation.VIEW);
                } else if (this.$scope.operation !== EOperation.EDIT || legalPerson.ID !== this.$scope.model.ID) {
                    this.$scope.fetchData(legalPerson.ID, EOperation.EDIT);
                }
            };
        }

        this.$scope.updateContactList = async (idLegalPerson: number) => {
            this.updateContactList(idLegalPerson);
        }

        this.$scope.viewLegalPerson = async (legalPerson: ILegalPersonModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(legalPerson.ID, EOperation.VIEW);
        }

        this.$scope.viewLogLegalPerson = async (legalPerson: ILegalPersonModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(legalPerson);
        }

        this.$scope.copyLegalPerson = async (legalPerson: ILegalPersonModel): Promise<void> => {
            this.SSEService.closeEvents();
            legalPerson.ERP_INTEGRATION_PARAM = null;
            legalPerson.DOCUMENT_ERROR = null;
            this.$scope.fetchData(legalPerson.ID, EOperation.COPY);
        }

        this.$scope.getNetworkListByName = async (name: string) => {
            let networkList = [];
            if (name && name.length >= 3) {
                networkList = await this.getNetworkListByName(name);
            }
            this.$scope.networkList = networkList;
        }

        this.$scope.getCityListByNameOrInitials = async (search: string) => {
            let cityList = [];
            if (search && search.length >= 3) {
                cityList = await this.getCityListByNameOrInitials(search);
            }
            this.$scope.cityList = cityList;
        }

        this.$scope.getCnpj = async (cnpj: string) => {
            await this.getCnpj(cnpj);
        }

        this.$scope.getZipCode = async (zipCode: string) => {
            await this.getZipCode(zipCode);
        }

        this.$scope.addCurrentAccount = () => {
            this.addCurrentAccountModal();
        }

        this.$scope.editCurrentAccount = (index: number, currentAccount: ICurrentAccount) => {
            this.editCurrentAccount(index, currentAccount);
        }
        this.$scope.setIntegrationsFieldsNullable = () => {
            this.setIntegrationsFieldsNullable()
        }

        this.$scope.removeCurrentAccount = (index: number) => {
            this.removeCurrentAccount(index);
        }

        this.$scope.addSpec = () => {
            this.addSpec();
        }

        this.$scope.removeSpec = (index: number) => {
            this.removeSpec(index);
        }

        this.$scope.addPartner = () => {
            this.addPartner();
        }

        this.$scope.removePartner = (index: number) => {
            this.removePartner(index);
        }

        this.$scope.limitLines = (value: string, limit: number) => {
            return StringUtil.limitLines(value, limit);
        }

        this.$scope.initPanels = () => {
            const panels = document.getElementsByClassName("toggle-me");
            for (let i = 0; i < panels.length; i++) {
                const panel = panels[i];
                if (panel.id === "collapseBasicData") {
                    if (!panel.classList.contains('in')) $("#" + panel.id)["collapse"]("show");
                } else if (panel.classList.contains('in')) {
                    $("#" + panel.id)["collapse"]("hide");
                }
            }
            this.$scope.navigateBetweenIds('collapseBasicData');
        }

        this.$scope.collapseHeader = (elementId: string, state?: string) => {
            this.collapseHeader(elementId, state);
        }

        this.$scope.goToNetwork = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.network", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.hasSpecRegistration = (type: SelectorModel) => {
            return type && this.specRegistrationIdList ? this.specRegistrationIdList.some(obj => obj === type.ID) : false;
        }

        this.$scope.updateSpecType = (index: number) => {
            if ((!index && index != 0) || (!this.$scope.specSituationList) || (!this.$scope.model.SPEC) || (this.$scope.model.SPEC.length == 0)) return;

            const selected = this.$scope.model.SPEC[index].TYPE;
            const pending = this.$scope.specSituationList.find(obj => obj.ID == "0");
            const notApplicable = this.$scope.specSituationList.find(obj => obj.ID == "2");

            if (this.specRegistrationIdList.some(specId => specId === selected.ID)) {
                this.$scope.model.SPEC[index].SITUATION = pending;
            } else {
                this.$scope.model.SPEC[index].SITUATION = notApplicable;
            }
        }

        this.$scope.goToSpec = (specialty: ISpec) => {
            if (!specialty) return;

            const specRoute = this.getSpecRouteById(specialty.TYPE.ID);
            const inputParams: ILegalPersonExchangeData = { OPERATION: specialty.SITUATION.ID == "0" ? "register" : null, MODEL: specRoute.MODEL };

            if (specRoute.URL) this.$scope.sessionService.openTab(specRoute.URL, <ISpecParameter>{ "LEGAL_PERSON.CORPORATE_NAME": this.$scope.model.CORPORATE_NAME, "LEGAL_PERSON.CODE": this.$scope.model.CORPORATE_NAME }, inputParams);
        }

        this.$scope.goToCity = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.city", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToProvince = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.province", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToCountry = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.country", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToContact = (id: number, isAdd?: boolean) => {
            const inputParams: ILegalPersonExchangeData = isAdd ? { OPERATION: "register", MODEL: { LEGAL_PERSON: { CORPORATE_NAME: this.$scope.model.CORPORATE_NAME } } } : null;
            this.$scope.sessionService.openTab("app.registration.contact", <ILinkParameter>{ ID: id ? id.toString() : id }, inputParams);
        }

        this.$scope.correspondenceGenerate = () => {
            try {
                const model = this.$scope.model
                const idFiscal = model.ID_FISCAL;
                const fantasyName = model.FANTASY_NAME;
                const city = model.ADDRESS.CITY;

                if (!idFiscal) throw Error('Insert document number first!');
                if (!fantasyName) throw Error('Insert fantasy name first!');
                if (!city) throw Error('Select city first!');

                const publicPlace = model.ADDRESS.PUBLIC_PLACE;
                const number = model.ADDRESS.NUMBER;
                const zipCode = model.ADDRESS.ZIP_CODE;
                const complement = model.ADDRESS.COMPLEMENT;
                const district = model.ADDRESS.DISTRICT;

                let string = '';
                if (publicPlace && publicPlace != '') string += `${publicPlace}`;
                if (number && number !== '') string += `${string !== '' ? ' ' : ''}${number}`;
                if (complement && complement !== '') string += `${string !== '' ? ' ' : ''}${complement}`;
                if (district && district !== '') string += `${string !== '' ? ', ' : ''}${district}`;

                let address = `${fantasyName}` + "\n"
                if (string !== '') address += string + "\n";
                address += `${city.NAME}, ${city.PROVINCE.CODE} - ${city.PROVINCE.COUNTRY.NAME}` + (zipCode && zipCode !== '' ? ` CEP: ${zipCode}` : '');
                address += "\n" + `${model.TYPE.NAME}: ${idFiscal}`

                if (!publicPlace || !district) {
                    this.formService.notifyWarning("REGISTRATION.CORRESPONDENCE_ADDRESS_MISSING ");
                }

                model.ADDRESS.CORRESPONDENCE = address;
            } catch (ex) {
                this.formService.handleError(ex);
            }
        }

        this.$scope.insertNetwork = async (name: string) => {
            try {
                const newNetworkData: INetworkModel = {
                    _id: null,
                    ID: null,
                    ACTIVE: true,
                    NAME: name,
                    DISPLAY_NAME: name,
                    CREATED_AT: null,
                    CREATED_BY: null,
                    UPDATED_AT: null,
                    UPDATED_BY: null,
                    SEARCH_FIELDS: null,
                    DOCUMENT_ERROR: null
                };
                const result = await this.RestService.newObjectPromise(`${this.$baseUrl}/network/insert`, { data: newNetworkData, oldData: null }, 30000, false);
                if (result) this.formService.notifySuccess("REGISTRATION.NETWORK_ADDED");
                this.$scope.getNetworkListByName(name);
            } catch (ex) {
                this.formService.handleError(ex);
            }
        }

        this.$scope.validityPhone = (inputName: string) => {
            if (!inputName) return;
            ValidateUtil.validityPhone(this.$scope['form'][inputName], inputName == "mainPhone" ? this.intlMainPhone : null);
        };
        this.$scope.getPhoneValidityMessage = (inputName: string) => {
            return ValidateUtil.getPhoneValidityMessage(inputName && inputName == "mainPhone" ? this.intlMainPhone : null);
        }

        this.$scope.preventSpecialChars = (event, element) => {
            this.preventSpecialChars(event, element);
        }

        this.$scope.findIntegrationId = async (): Promise<void> => {
            await this.findIntegrationId();
        }
        this.$scope.findCurrentAccountIntegrationId = async (currentAccount: ICurrentAccount): Promise<void> => {
            await this.findCurrentAccountIntegrationId(currentAccount);
        }

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }

        this.$scope.integrateSankhya = (id: number) => {
            this.integrateSankhya(id);
        }

        this.$scope.integrateSankhyaStatus = (id: number) => {
            this.integrateSankhyaStatus(id);
        }

        this.$scope.getCodEmpERP = async (search: string) => {
            let empERPList = [];
            if (search && search.length >= 3) {
                empERPList = await this.getCodEmpERP(search);
            }
            this.$scope.empERPList = empERPList;
        }

        this.$scope.getTopBillingERP = async (search: string) => {
            let topBillingERPList = [];
            if (search && search.length >= 3) {
                topBillingERPList = await this.getTopBillingERP(search);
            }
            this.$scope.topBillingERPList = topBillingERPList;
        }

        this.$scope.isCompany = () => {

            if (this.$scope.model && this.$scope.model.SPEC) {
                const found = this.$scope.model.SPEC.find(spec => spec.TYPE && (spec.TYPE.ID == ELegalPersonSpecializationId.CORPORATE_BRANCH || spec.TYPE.ID == ELegalPersonSpecializationId.COMMERCIAL_UNITY));
                if (found) return true;
            }
            return false;
        }

        this.$scope.buildErrorSankhyaTooltip = (errors: IDocumentError[]) => {
            return this.buildErrorSankhyaTooltip(errors);
        }

        this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
            this.fetchData(id, action);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewLegalPerson(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editLegalPerson(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyLegalPerson(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogLegalPerson(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 125,
            maxWidth: 125,
            cellTemplate: (view + edit + copy + viewLog + modalIntegration),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 100, cellFilter: "YesOrNo" };
            const colTypeId: IMonacoColumnDef = { name: "TYPE.NAME", displayName: "ENTITY.TAX_TYPE", width: 100 };
            const colIdFiscal: IMonacoColumnDef = { name: "ID_FISCAL", displayName: "ENTITY.TAX_NUMBER", width: 180, cellTemplate: "<div class='grid-padding' >{{row.entity.ID_FISCAL | cnpj}}</div>" };
            const colEstablishment: IMonacoColumnDef = { name: "ESTABLISHMENT.NAME", displayName: "ENTITY.ESTABLISHMENT", width: 150 };
            const colShortName: IMonacoColumnDef = { name: "SHORT_NAME", displayName: "ENTITY.SHORT_NAME", width: 200 };
            const colCorporateName: IMonacoColumnDef = { name: "CORPORATE_NAME", displayName: "ENTITY.CORPORATE_NAME", width: 300 };
            const colFantasyName: IMonacoColumnDef = { name: "FANTASY_NAME", displayName: "ENTITY.TRADE_NAME", width: 300 };
            const colNetwork: IMonacoColumnDef = { name: "NETWORK.NAME", displayName: "BASIC_DATA.NETWORK", width: 200 };
            const colSpec: IMonacoColumnDef = { name: "SPEC.TYPE.NAME", displayName: "ENTITY.COMPANY_TYPE", width: 600, cellTemplate: "<div class='grid-padding' >{{grid.appScope.getCONCAT(row.entity.SPEC, 'TYPE')}}</div>" };
            const colProvinceRegistration: IMonacoColumnDef = { name: "PROVINCE_REGISTRATION", displayName: "ENTITY.STATE_REGISTRATION", width: 150 };
            const colMunicipalRegistration: IMonacoColumnDef = { name: "MUNICIPAL_REGISTRATION", displayName: "ENTITY.MUNICIPAL_INSCRIPTION", width: 180 };
            const colAddressZipCode: IMonacoColumnDef = { name: "ADDRESS.ZIP_CODE", displayName: "ENTITY.ZIP_CODE", width: 150 };
            const colAddressCity: IMonacoColumnDef = { name: "ADDRESS.CITY.NAME", displayName: "ROUTE.CITY", width: 200 };
            const colAddressProvince: IMonacoColumnDef = { name: "ADDRESS.CITY.PROVINCE.NAME", displayName: "ROUTE.STATE", width: 200 };
            const colAddressCountry: IMonacoColumnDef = { name: "ADDRESS.CITY.PROVINCE.COUNTRY.NAME", displayName: "ROUTE.COUNTRY", width: 200 };
            const colIdIntegration: IMonacoColumnDef = { name: "INTEGRATION_ID", displayName: "GENERAL.INTEGRATION_ID", width: 150 };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "GENERAL.ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 120, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 120, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colDocumentError: IMonacoColumnDef = { name: "DOCUMENT_ERROR", displayName: "REGISTRATION.INTEGRATION_ERROR", width: 200, cellTemplate: "<div class='grid-padding' >{{ grid.appScope.getCONCAT(row.entity.DOCUMENT_ERROR, null, 'REASON', null, true) }}</div>" };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'TYPE':
                        columnDefs.push(colTypeId);
                        break;
                    case 'ID_FISCAL':
                        columnDefs.push(colIdFiscal);
                        break;
                    case 'ESTABLISHMENT':
                        columnDefs.push(colEstablishment);
                        break;
                    case 'SHORT_NAME':
                        columnDefs.push(colShortName);
                        break;
                    case 'CORPORATE_NAME':
                        columnDefs.push(colCorporateName);
                        break;
                    case 'FANTASY_NAME':
                        columnDefs.push(colFantasyName);
                        break;
                    case 'SPEC':
                        columnDefs.push(colSpec);
                        break;
                    case 'PROVINCE_REGISTRATION':
                        columnDefs.push(colProvinceRegistration);
                        break;
                    case 'MUNICIPAL_REGISTRATION':
                        columnDefs.push(colMunicipalRegistration);
                        break;
                    case 'ADDRESS':
                        columnDefs.push(colAddressZipCode);
                        columnDefs.push(colAddressCity);
                        columnDefs.push(colAddressProvince);
                        columnDefs.push(colAddressCountry);
                        break;
                    case 'ID_NETWORK':
                        columnDefs.push(colNetwork);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'INTEGRATION_ID':
                        columnDefs.push(colIdIntegration);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                    case 'DOCUMENT_ERROR':
                        columnDefs.push(colDocumentError);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            ACTIVE: true,
            TYPE: null,
            ID_FISCAL: null,
            ESTABLISHMENT: null,
            SHORT_NAME: null,
            CORPORATE_NAME: null,
            FANTASY_NAME: null,
            NETWORK: null,
            ID_NETWORK: null,
            SPEC: [],
            PROVINCE_REGISTRATION: null,
            MUNICIPAL_REGISTRATION: null,
            MAIN_PHONE: null,
            MAIN_PHONE_COUNTRY_CODE: null,
            WEB_SITE: null,
            ADDRESS: { ZIP_CODE: null, DISTRICT: null, ID_CITY: null, CITY: null, COMPLEMENT: null, NUMBER: null, PUBLIC_PLACE: null, CORRESPONDENCE: null },
            CURRENT_ACCOUNT: [],
            CONTACTS: null,
            OBSERVATION: null,
            INTEGRATION_ID: null,
            OPENING_DATE: null,
            LEGAL_NATURE: null,
            SHARE_CAPITAL: null,
            PARTNER: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            DOCUMENT_ERROR: null,
            SEARCH_FIELDS: null,
            ERP_INTEGRATION_PARAM: null
        };
    }

    initDependencies(): Promise<any> {
        const self: LegalPersonRegisterController = this;

        this.$scope.model.OPENING_DATE = (this.$scope.model.OPENING_DATE != null) ? new Date(this.$scope.model.OPENING_DATE) : null;
        $('#idFiscalMask')['mask']('00.000.000/0000-00');

        this.initPhoneComponent();

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getTaxIdTypeList(),
                self.getBranchTypeList(),
                self.getSpecSituationList(),
                self.getSpecTypeList(),
                self.getGenericList('pix_type'),
            ]).then((result: any) => {
                self.$scope.taxIdTypeList = result[0];
                self.$scope.branchTypeList = result[1];
                self.$scope.specSituationList = result[2];
                self.$scope.specTypeList = result[3].data;
                self.$scope.pixTypeList = result[4];
                self.specRegistrationIdList = self.$scope.specTypeList ? self.$scope.specTypeList.filter(spec => spec.ROUTE_URL != null).map(spec => spec.ID) : [];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getGenericList(type: string, alternative: boolean = false): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}/${alternative}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private initPhoneComponent(): void {
        const self: LegalPersonRegisterController = this;

        this.destroyPhoneComponent();

        const inputMainPhone = document.querySelector("#mainPhone");
        self.intlMainPhone = intlTelInput(inputMainPhone, {
            utilsScript: "../../../../node_modules/intl-tel-input/build/js/utils.js",
            autoPlaceholder: "aggressive",
            formatOnDisplay: true,
            separateDialCode: true,
            initialCountry: "br"
        });

        if (!self.$scope.model.MAIN_PHONE_COUNTRY_CODE) self.$scope.model.MAIN_PHONE_COUNTRY_CODE = "br";
        self.intlMainPhone.setCountry(self.$scope.model.MAIN_PHONE_COUNTRY_CODE);

        this.countryChangeListener = () => {
            self.$scope.model.MAIN_PHONE = null;
            self.$scope.model.MAIN_PHONE_COUNTRY_CODE = self.intlMainPhone.getSelectedCountryData().iso2;
            self.intlMainPhone.setNumber("");
            self.$scope.validityPhone("phone");
            self.$scope.$apply();
        };
        inputMainPhone.addEventListener("countrychange", this.countryChangeListener, false);
    }

    private destroyPhoneComponent(): void {
        const inputMainPhone = document.querySelector("#mainPhone");
        if (inputMainPhone) inputMainPhone.removeEventListener("countrychange", this.countryChangeListener, false);
        if (this.intlMainPhone) this.intlMainPhone.destroy();
    }

    private buildErrorSankhyaTooltip(errors: IDocumentError[]): string {
        let tooltip = "<div align=\"left\">";
        if (errors && errors.length) {
            const errorsSankhya = errors.filter(e => e.SUBJECT.ID == "1" || e.SUBJECT.ID == "2" || e.SUBJECT.ID == "3");
            for (const reason of errorsSankhya) {
                tooltip += `DATA: ${reason.DATE} <br>`;
                tooltip += `ASSUNTO: ${reason.SUBJECT.NAME} <br>`;
                tooltip += `RAZÃO: ${reason.REASON} <br><br>`;
            }
        }
        tooltip += "</div>";
        return tooltip;
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            { PROPERTY: "ACTIVE", LABEL: "GENERAL.ACTIVE" },
            { PROPERTY: "SPEC", LABEL: "ENTITY.COMPANY_TYPE" },
            { PROPERTY: "SITUATION", LABEL: "ENTITY.REGISTRATION_STATUS" },
            { PROPERTY: "TYPE", LABEL: "ENTITY.TAX_TYPE" },
            { PROPERTY: "ID_FISCAL", LABEL: "ENTITY.TAX_NUMBER" },
            { PROPERTY: "ESTABLISHMENT", LABEL: "ENTITY.ESTABLISHMENT" },
            { PROPERTY: "SHORT_NAME", LABEL: "ENTITY.SHORT_NAME" },
            { PROPERTY: "CORPORATE_NAME", LABEL: "ENTITY.CORPORATE_NAME" },
            { PROPERTY: "FANTASY_NAME", LABEL: "ENTITY.TRADE_NAME" },
            { PROPERTY: "NETWORK", LABEL: "BASIC_DATA.NETWORK" },
            { PROPERTY: "SPEC_TYPE", LABEL: "GENERAL.TYPE" },
            { PROPERTY: "PROVINCE_REGISTRATION", LABEL: "ENTITY.STATE_REGISTRATION" },
            { PROPERTY: "MUNICIPAL_REGISTRATION", LABEL: "ENTITY.MUNICIPAL_INSCRIPTION" },
            { PROPERTY: "MAIN_PHONE", LABEL: "ENTITY.PHONE" },
            { PROPERTY: "MAIN_PHONE_COUNTRY_CODE", LABEL: "REGISTRATION.COUNTRY_CODE_MAIN_PHONE" },
            { PROPERTY: "WEB_SITE", LABEL: "ENTITY.WEBSITE" },
            { PROPERTY: "ADDRESS", LABEL: "ENTITY.ADDRESS" },
            { PROPERTY: "ZIP_CODE", LABEL: "ENTITY.ZIP_CODE" },
            { PROPERTY: "COMPLEMENT", LABEL: "ENTITY.ADDRESS_COMPLEMENT" },
            { PROPERTY: "COUNTRY", LABEL: "ROUTE.COUNTRY" },
            { PROPERTY: "CITY", LABEL: "ROUTE.CITY" },
            { PROPERTY: "DISTRICT", LABEL: "ENTITY.DISTRICT" },
            { PROPERTY: "NUMBER", LABEL: "GENERAL.NUMBER" },
            { PROPERTY: "PUBLIC_PLACE", LABEL: "ENTITY.ADDRESS" },
            { PROPERTY: "PROVINCE", LABEL: "ROUTE.STATE" },
            { PROPERTY: "CORRESPONDENCE", LABEL: "ENTITY.POSTAL_ADDRESS" },
            { PROPERTY: "MAIN", LABEL: "GENERAL.MAIN" },
            { PROPERTY: "CURRENCY", LABEL: "GENERAL.CURRENCY" },
            { PROPERTY: "HOLDER_NAME", LABEL: "GENERAL.HOLDER" },
            { PROPERTY: "BANK", LABEL: "ENTITY.BANK" },
            { PROPERTY: "AGENCY", LABEL: "ENTITY.BANK_AGENCY" },
            { PROPERTY: "ACCOUNT_CODE", LABEL: "GENERAL.CODE" },
            { PROPERTY: "ACCOUNT_DV", LABEL: "ENTITY.ACCOUNT_NUMBER" },
            { PROPERTY: "CONTACTS", LABEL: "GENERAL.CONTACT" },
            { PROPERTY: "OBSERVATION", LABEL: "GENERAL.REMARKS" },
            { PROPERTY: "INTEGRATION_ID", LABEL: "GENERAL.INTEGRATION_ID" },
            { PROPERTY: "OPENING_DATE", LABEL: "GENERAL.OPENING_DATE" },
            { PROPERTY: "LEGAL_NATURE", LABEL: "ENTITY.LEGAL_NATURE" },
            { PROPERTY: "SHARE_CAPITAL", LABEL: "ENTITY.SHARE_CAPITAL" },
            { PROPERTY: "NAME", LABEL: "GENERAL.NAME" },
            { PROPERTY: "QUALIFICATION", LABEL: "GENERAL.QUALIFICATION" },
            { PROPERTY: "LEGAL_REPRESENTATIVE_QUALIFICATION", LABEL: "ENTITY.QUALIFICATION_REP" },
            { PROPERTY: "PARTNER", LABEL: "GENERAL.CODE" },
            { PROPERTY: "CURRENT_ACCOUNT", LABEL: "ENTITY.CHECKING_ACCOUNT" },
            { PROPERTY: "ID", LABEL: "GENERAL.ID" },
            { PROPERTY: 'CODE', LABEL: 'GENERAL.CODE' },
            { PROPERTY: 'CREATED_AT', LABEL: 'GENERAL.CREATED_AT' },
            { PROPERTY: 'CREATED_BY', LABEL: 'GENERAL.CREATED_BY' },
            { PROPERTY: 'UPDATED_AT', LABEL: 'GENERAL.UPDATED_AT' },
            { PROPERTY: 'UPDATED_BY', LABEL: 'GENERAL.UPDATED_BY' },
            { PROPERTY: 'ROUTE_URL', LABEL: 'ROUTE.ROUTES' }
        ]
        return props;
    }

    private getSpecRouteById(id: string): ISpecRoute {
        let specRoute: ISpecRoute = { URL: null, MODEL: { LEGAL_PERSON: { CORPORATE_NAME: this.$scope.model.CORPORATE_NAME } } };

        const specType = this.$scope.specTypeList.find(spec => spec.ID == id);
        if (specType) specRoute.URL = specType.ROUTE_URL;

        return specRoute;
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlDataManager = this.config.productUrl + baseRoute;
        return urlDataManager;
    }

    private getMenuFloatingDefault(): IFloatingMenu {
        return { tooltipPlacement: "auto bottom", textTooltip: "REGISTRATION.LEGAL_PERSON_STATUS", infos: [{ text: "ENTITY.LEGAL_PERSON", class: "text-rouge font-bold" }], options: [{ click: "collapseHeader", args: ['collapseBasicData'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.BASIC_DATA", iconClass: "fa fa-address-card", iconBodyClass: "text-brown" }, { click: "collapseHeader", args: ['collapseSpec'], tooltipPlacement: "auto bottom", textTooltip: "ENTITY.COMPANY_TYPE", iconClass: "fa fa-drivers-license-o", iconBodyClass: "text-orange" }, { click: "collapseHeader", args: ['collapseAddress'], tooltipPlacement: "auto bottom", textTooltip: " ENTITY.ADDRESS", iconClass: "fa fa-address-book-o", iconBodyClass: "text-rouge" }, { click: "collapseHeader", args: ['collapseContacts'], tooltipPlacement: "auto bottom", textTooltip: " GENERAL.CONTACT", iconClass: "fa fa-address-book", iconBodyClass: "text-brown" }, { click: "collapseHeader", args: ['collapseCurrentAccount'], tooltipPlacement: "auto bottom", textTooltip: " ENTITY.CHECKING_ACCOUNT", iconClass: "fa fa-credit-card", iconBodyClass: "text-red" }, { click: "collapseHeader", args: ['collapsePartners'], tooltipPlacement: "auto bottom", textTooltip: " ENTITY.PARTNERS", iconClass: "fa fa-users", iconBodyClass: "text-dark" }, { click: "collapseHeader", args: ['collapseAll'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.COLLAPSE_EXPAND_ALL", iconClass: "fa fa-expand", iconBodyClass: "text-danger" }], btnActiveDisabled: false };
    }

    private collapseHeader(elementId: string, state?: string): void {
        if (elementId === "collapseAll" || elementId[0] === "collapseAll") {
            this.collapseState = this.collapseState == "hide" ? "show" : "hide";
            $('.toggle-me')["collapse"](state ? state : this.collapseState);
        } else if (elementId != "registerBody") {
            $("#" + elementId)["collapse"](state ? state : 'toggle');
        }
        this.$scope.navigateBetweenIds(elementId);
    }

    private async findIntegrationId(): Promise<void> {
        try {
            const self = this;
            this.$timeout(async function () {
                if (self.$scope.model && self.$scope.model.ID_FISCAL) {
                    self.formService.block();
                    const integrationId = await self.RestService.newObjectPromise(`${self.$baseUrl}/legalPerson/headCargo/legalPerson/integrationId`, { cnpj: self.$scope.model.ID_FISCAL }, 30000, false);
                    if (integrationId && integrationId.length === 1) {
                        self.$scope.model.INTEGRATION_ID = integrationId[0].INTEGRATION_ID.toString();
                    }
                    self.formService.unblock();
                }
            }, 500);
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async findCurrentAccountIntegrationId(currentAccount: ICurrentAccount): Promise<void> {
        try {
            if (currentAccount && this.$scope.model && this.$scope.model.INTEGRATION_ID && currentAccount.BANK && currentAccount.AGENCY && currentAccount.ACCOUNT_CODE && currentAccount.ACCOUNT_DV) {
                this.formService.block();
                const integrationId = await this.RestService.newObjectPromise(`${this.$baseUrl}/legalPerson/headCargo/legalPerson/currentAccount/integrationId`, { personId: this.$scope.model.INTEGRATION_ID, bank: currentAccount.BANK.CODE, agency: currentAccount.AGENCY.CODE, agencyDv: currentAccount.AGENCY.DV, account: currentAccount.ACCOUNT_CODE, accountDv: currentAccount.ACCOUNT_DV }, 30000, false);
                if (integrationId && integrationId.length === 1) {
                    currentAccount.INTEGRATION_ID = integrationId[0].INTEGRATION_ID.toString();
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getNetworkListByName(name: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const networks = await this.RestService.newObjectPromise(`${this.$baseUrl}/network/listByFullName`, { name }, 30000, false);
            if (networks) result = networks.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: null } });
        } catch (ex) {
            this.formService.handleError(ex);
        }
        this.formService.unblock();
        return result;
    }

    private async getCityListByNameOrInitials(search?: string, fullName: boolean = false): Promise<ICity[]> {
        let result = [];
        this.formService.block();
        try {
            const cities = await this.RestService.newObjectPromise(`${this.$baseUrl}/city/list/custom`, { name: search, fullName }, 30000, false);
            if (cities) result = cities.map(city => { return { ID: city.ID, NAME: city.NAME, CODE: city.INITIALS, PROVINCE: { ID: city.PROVINCE.ID, NAME: city.PROVINCE.NAME, CODE: city.PROVINCE.CODE, COUNTRY: { ID: city.PROVINCE.COUNTRY.ID, NAME: city.PROVINCE.COUNTRY.NAME, CODE: city.PROVINCE.COUNTRY.CODE } } } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCountryListByNameOrInitials(search: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        if (search.length >= 2) {
            try {
                const countries = await this.RestService.newObjectPromise(`${this.$baseUrl}/country/list/custom`, { search }, 30000, false);
                if (countries) result = countries.map(country => { return { ID: country.ID, NAME: country.NAME, CODE: country.INITIALS } });
            } catch (ex) {
                this.formService.handleError(ex);
            }
        }
        this.formService.unblock();
        return result;
    }

    private async getBankListByName(name: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        if (name.length >= 3) {
            try {
                const banks = await this.RestService.newObjectPromise(`${this.$baseUrl}/bank/list/custom`, { name }, 30000, false);
                if (banks) result = banks.map(bank => { return { ID: bank.ID, NAME: bank.NAME, CODE: bank.CODE } });
            } catch (ex) {
                this.formService.handleError(ex);
            }
        }
        this.formService.unblock();
        return result;
    }

    private async getTaxIdTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/tax_id_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getBranchTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/branch_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getSpecSituationList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/specialization_situation`, null);
        return generic && generic.data ? generic.data : [];
    }

    private getSpecTypeList(): Promise<ISpecialization[]> {
        return this.RestService.getObjectAsPromise(`${this.$baseUrl}/specialization/list`, 30000, null, false);
    }

    private addSpec(): void {
        try {
            const notAppicable = this.$scope.specSituationList.find(obj => obj.ID == "2");
            const spec = { TYPE: null, SITUATION: notAppicable };
            if (!this.$scope.model.SPEC) this.$scope.model.SPEC = [];
            this.$scope.model.SPEC.push(angular.copy(spec));
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeSpec(index: number): Promise<void> {
        try {
            if (index >= 0) {
                const modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: "GENERAL.CONFIRM",
                    headerText: "GENERAL.CONFIRM_ACTION",
                    bodyText: `${this.formService.getTranslate("REGISTRATION.REMOVE_SPECIALITY_QUESTION")}`
                });

                if (!modal) return;

                this.formService.block();

                this.$scope.model.SPEC.splice(index, 1);

                this.formService.unblock();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private addPartner(): void {
        try {
            const partner = {
                NAME: null,
                QUALIFICATION: null,
                LEGAL_REPRESENTATIVE_QUALIFICATION: null
            }

            if (!this.$scope.model.PARTNER) this.$scope.model.PARTNER = [];
            this.$scope.model.PARTNER.push(angular.copy(partner));
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removePartner(index: number): Promise<void> {
        try {
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: `${this.formService.getTranslate("REGISTRATION.REMOVE_PARTNER_ADM_QUESTION")}`
            });

            if (!modal) return;

            this.formService.block();

            this.$scope.model.PARTNER.splice(index, 1);

            this.formService.unblock();

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getZipCodeApi(zipCode: string): Promise<any> {
        if (!zipCode) return this.formService.notifyError('ZipCode is null.');
        try {
            const zipCodeFormatted = zipCode.replace(/\./g, '').replace(/-/g, '');
            return this.ExternalService.get({ route: "/zipCode/" + zipCodeFormatted });
        } catch (ex) {
            this.formService.notifyError('There was a problem with the request, check the ZIP code informed or contact the responsible sector!');
        }
    }

    private async getCnpjApi(cnpj: string): Promise<any> {
        try {
            const cnpjFormatted = cnpj.replace(/\./g, '').replace(/-/g, '').replace('/', '');
            return this.ExternalService.get({ route: "/cnpj/" + cnpjFormatted });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getCnpj(cnpj: string): Promise<void> {
        if (cnpj) {
            const hasInformationToBeReplaced = [this.$scope.model.OPENING_DATE, this.$scope.model.CORPORATE_NAME, this.$scope.model.MAIN_PHONE, this.$scope.model.SHARE_CAPITAL, this.$scope.model.LEGAL_NATURE, this.$scope.model.PARTNER, this.$scope.model.ESTABLISHMENT, this.$scope.model.ADDRESS.ZIP_CODE, this.$scope.model.ADDRESS.PUBLIC_PLACE, this.$scope.model.ADDRESS.NUMBER, this.$scope.model.ADDRESS.COMPLEMENT, this.$scope.model.ADDRESS.DISTRICT, this.$scope.model.ADDRESS.CITY].some(field => field !== null && field !== "");
            const approved = hasInformationToBeReplaced ? await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.YES",
                headerText: "REGISTRATION.ACTUAL_DATA_REPLACEMENT",
                bodyText: `${this.formService.getTranslate("GENERAL.CONTINUE_QUESTION")}`
            }) : true;
            if (approved) {
                this.$formService.block();
                try {
                    const rc = await this.getCnpjApi(cnpj);
                    if (rc && rc.data) {
                        const cnpjResult: ICnpjResult = rc.data.data;
                        this.$formService.notifySuccess('Cnpj found successfully!');
                        const cnpjOpeningDate = moment(cnpjResult.abertura, 'DD/MM/YYYY');
                        this.$scope.model.OPENING_DATE = cnpjOpeningDate.isValid() ? cnpjOpeningDate.toDate() : null;
                        this.$scope.model.CORPORATE_NAME = this.formatWords(cnpjResult.nome);
                        this.$scope.model.FANTASY_NAME = this.formatWords(cnpjResult.fantasia);
                        this.$scope.model.MAIN_PHONE = cnpjResult.telefone;
                        this.$scope.model.SHARE_CAPITAL = cnpjResult.capital_social;
                        this.$scope.model.LEGAL_NATURE = cnpjResult.natureza_juridica;
                        this.$scope.model.PARTNER = this.buildPartners(cnpjResult.qsa);
                        this.$scope.model.ESTABLISHMENT = this.$scope.branchTypeList.find(obj => obj.NAME.toLocaleLowerCase() === cnpjResult.tipo.toLocaleLowerCase());
                        this.$scope.model.ADDRESS.ZIP_CODE = cnpjResult.cep;
                        this.$scope.model.ADDRESS.PUBLIC_PLACE = this.formatWords(cnpjResult.logradouro);
                        this.$scope.model.ADDRESS.COMPLEMENT = this.formatWords(cnpjResult.complemento);
                        this.$scope.model.ADDRESS.DISTRICT = this.formatWords(cnpjResult.bairro);
                        this.$scope.model.ADDRESS.NUMBER = cnpjResult.numero;
                        if (cnpjResult.cep !== "") {
                            const rc = await this.getZipCodeApi(cnpjResult.cep);
                            this.$scope.model.ADDRESS.CITY = null;
                            if (rc && rc.data) {
                                const zipCodeResult: IZipCodeResult = rc.data.data;
                                const city: ICity[] = await this.getCityListByNameOrInitials(zipCodeResult.localidade, true);
                                if (city && city.length === 1) {
                                    this.$scope.model.ADDRESS.CITY = city[0];
                                } else this.formService.notifyWarning("REGISTRATION.CITY_NOT_FOUND");
                            }
                        }
                        this.$timeout(() => {
                            this.$scope.selectorValidity('establishment');
                            this.$scope.selectorValidity('addressCity');
                        });
                    }
                } catch (ex) {
                    this.$formService.unblock();
                    if (ex.data.data === "Too Many Requests") {
                        this.$formService.notifyError('Limit requests per minute reached, wait a few moments and try again!');
                    } else {
                        this.$formService.notifyError(ex.data.data);
                    }
                }
                this.$scope.$applyAsync();
                this.$formService.unblock();
            }
        }
    }

    private async getZipCode(zipCode: string): Promise<void> {
        if (zipCode) {
            const hasInformationToBeReplaced = [this.$scope.model.ADDRESS.PUBLIC_PLACE, this.$scope.model.ADDRESS.DISTRICT].some(field => field !== null && field !== "");
            const approved = hasInformationToBeReplaced ? await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.YES",
                headerText: "REGISTRATION.ACTUAL_DATA_REPLACEMENT",
                bodyText: `${this.formService.getTranslate("GENERAL.CONTINUE_QUESTION")}`
            }) : true;

            if (approved) {
                this.$formService.block();
                try {
                    const rc = await this.getZipCodeApi(zipCode);
                    if (rc && rc.data) {
                        this.$formService.notifySuccess('Zip code found successfully!');
                        const zipCodeResult: IZipCodeResult = rc.data.data;
                        this.$scope.model.ADDRESS.PUBLIC_PLACE = this.formatWords(zipCodeResult.logradouro);
                        this.$scope.model.ADDRESS.DISTRICT = this.formatWords(zipCodeResult.bairro);
                        const city: ICity[] = await this.getCityListByNameOrInitials(zipCodeResult.localidade, true);
                        if (city && city.length === 1) {
                            this.$scope.model.ADDRESS.CITY = city[0];
                        }
                    }
                    this.$timeout(() => {
                        this.$scope.selectorValidity('addressCity');
                    });
                } catch (ex) {
                    this.$formService.unblock();
                    if (ex.data.message === "Not Found") {
                        this.$formService.notifyError('Zip code not found!');
                    } else {
                        this.$formService.notifyError('There was a problem with the request, check the ZIP code informed or contact the responsible sector!');
                    }
                }
                this.$scope.$applyAsync();
                this.$formService.unblock();
            }
        }
    }

    private formatWords(word: string): string {
        if (!word) return "";
        const words = word.toLowerCase().split(" ");
        for (let a = 0; a < words.length; a++) {
            const w = words[a];
            words[a] = w[0].toUpperCase() + w.slice(1);
        }
        return words.join(" ");
    }

    private buildPartners(qsaList: ISocio[]): IPartner[] {
        const partners: IPartner[] = [];

        for (const qsa of qsaList) {
            const partner: IPartner = { NAME: this.formatWords(qsa.nome), QUALIFICATION: qsa.qual, LEGAL_REPRESENTATIVE_QUALIFICATION: qsa.qual_rep_legal ? qsa.qual_rep_legal : null };
            partners.push(partner);
        }
        return partners;
    }

    private async addCurrentAccountModal(): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();

            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    scope: this.$scope,
                    formService: 'register',
                    template: require("../view/modals/currentAccountModal.html"),
                    size: 'lg modal-overflow'
                },
                {
                    actionButtonText: "GENERAL.CLOSE",
                    headerText: "ENTITY.CHECKING_ACCOUNT"
                }
            );

            const currency = await this.getCurrencyListByNameOrInitials("BRL");
            const country = await this.getCountryListByNameOrInitials("Brasil");

            const currentAccount: ICurrentAccount = {
                COUNTRY: country && country[0] ? angular.copy(country[0]) : null,
                MAIN: (!this.$scope.model.CURRENT_ACCOUNT || (this.$scope.model.CURRENT_ACCOUNT && this.$scope.model.CURRENT_ACCOUNT.length === 0)),
                CURRENCY: currency && currency[0] ? angular.copy(currency[0]) : null,
                HOLDER_NAME: angular.copy(this.$scope.model.CORPORATE_NAME),
                BANK: null,
                AGENCY: null,
                ACCOUNT_CODE: null,
                ACCOUNT_DV: null,
                CORRESPONDENCE: null,
                INTEGRATION_ID: null,
                PIX: null,
                PIX_KEY: null,
                SWIFT: null,
                IBAN: null,
                OBSERVATION: null,
                ACTIVE: true,
                ID: null,
            }

            const modal: ICurrentAccountModal = await this.buildCurrentAccountModalScope(0, currentAccount);

            await modalInstance.rendered.then(() => {
                this.$timeout(() => {
                    modal.selectorValidity('country');
                    modal.selectorValidity('currency');
                });
            });

            $('#agencyCode')['mask']('0000');
            $('#agencyDV')['mask']('0');
            $('#accountCode')['mask']('00000000000000000');
            $('#accountDV')['mask']('0');

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async setIntegrationsFieldsNullable(): Promise<void> {
        if ((!this.$scope.model.ERP_INTEGRATION_PARAM.ERP_CODPARC)) {
            this.$scope.model.ERP_INTEGRATION_PARAM.ERP_CODPARC = null
            this.$scope.model.ERP_INTEGRATION_PARAM.ERP_STATUS = null
        }

    }

    private async editCurrentAccount(index: number, currentAccount: ICurrentAccount): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();

            this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    scope: this.$scope,
                    formService: 'edit',
                    template: require("../view/modals/currentAccountModal.html"),
                    size: 'lg modal-overflow'
                },
                {
                    actionButtonText: "GENERAL.CLOSE",
                    headerText: "ENTITY.CHECKING_ACCOUNT"
                }
            );
            const modalScope = await this.buildCurrentAccountModalScope(index, currentAccount);
            await modalScope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async buildCurrentAccountModalScope(index: number, currentAccount: ICurrentAccount): Promise<ICurrentAccountModal> {
        const modalScope: ICurrentAccountModal = await this.ModalService.getModalScope(this.modalID);

        modalScope.currentAccount = angular.copy(currentAccount);

        modalScope.applyCurrentAccount = () => {
            this.applyCurrentAccount(modalScope.currentAccount, modalScope.operation, index);
        };

        modalScope.getCountryListByNameOrInitials = async (search: string) => {
            modalScope.countryList = await this.getCountryListByNameOrInitials(search);
        };

        modalScope.getBankListByName = async (search: string) => {
            modalScope.bankList = await this.getBankListByName(search);
        };

        modalScope.getCurrencyListByNameOrInitials = async (search: string) => {
            modalScope.currencyList = await this.getCurrencyListByNameOrInitials(search);
        };

        modalScope.goToCountry = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.country", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        modalScope.goToBank = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.bank", <ILinkParameter>{ ID: id ? id.toString() : id });
        };

        modalScope.goToCurrency = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.currency", <ILinkParameter>{ ID: id ? id.toString() : id });
        };

        modalScope.limitLines = (value: string, limit: number) => {
            return StringUtil.limitLines(value, limit);
        };

        modalScope.verifySwiftField = (country: SelectorModel) => {
            if (country.ID == '1') {
                modalScope.currentAccount.SWIFT = null;
            }
        };

        modalScope.changePIX = () => {
            this.$timeout(() => {
                modalScope.selectorValidity('bank');
            })
        };

        return modalScope;
    }

    private applyCurrentAccount(currentAccount: ICurrentAccount, operation: string, index: number): void {
        try {
            if (operation === "register") {
                if (!this.$scope.model.CURRENT_ACCOUNT) this.$scope.model.CURRENT_ACCOUNT = [];
                this.$scope.model.CURRENT_ACCOUNT.push(currentAccount);
            } else {
                this.$scope.model.CURRENT_ACCOUNT.splice(index, 1, currentAccount);
            }
            this.ModalService.closeModal(this.modalID);
            this.modalID = 0;

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getCurrencyListByNameOrInitials(search: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        if (search.length >= 3) {
            try {
                const currencies = await this.RestService.newObjectPromise(`${this.$baseUrl}/currency/list/custom`, { name: search }, 30000, false);
                if (currencies) result = currencies.map(currency => { return { ID: currency.ID, NAME: currency.NAME, CODE: currency.INITIALS } });
            } catch (ex) {
                this.formService.handleError(ex);
            }
        }
        this.formService.unblock();
        return result;
    }

    private validateSpecs() {
        let proceed = true;

        const specs = this.$scope.model.SPEC;

        if (specs && specs.length > 0) {
            for (const spec of specs) {
                if (specs.some(obj => obj.TYPE.ID === spec.TYPE.ID && obj['$$hashKey'] !== spec['$$hashKey'])) {
                    proceed = false;
                    this.$formService.notifyError("It is not allowed to inform repeated specialties!");
                    break;
                }
            }
        }
        return proceed;
    }

    private validateCurrentAccount() {
        let proceed = true;

        const currentAccount = this.$scope.model.CURRENT_ACCOUNT;

        if (currentAccount && currentAccount.length > 0) {
            const hasAnyBr = currentAccount.some(obj => obj.COUNTRY.ID === "BR");
            const hasAnyOutside = currentAccount.some(obj => obj.COUNTRY.ID !== "BR");
            const mainAccountsBr = currentAccount.filter(obj => obj.COUNTRY.ID === "BR" && obj.MAIN).length;
            const mainAccountsOutside = currentAccount.filter(obj => obj.COUNTRY.ID !== "BR" && obj.MAIN).length;

            if (hasAnyBr) {
                if (mainAccountsBr === 0) {
                    proceed = false;
                    this.$formService.notifyError("A primary account in Brazil is required!");
                } else if (mainAccountsBr > 1) {
                    proceed = false;
                    this.$formService.notifyError("Only one main account in Brazil is required!");
                }
            }

            if (hasAnyOutside) {
                if (mainAccountsOutside === 0) {
                    proceed = false;
                    this.$formService.notifyError("Need to report a master account abroad!");
                } else if (mainAccountsOutside > 1) {
                    proceed = false;
                    this.$formService.notifyError("Only one main account needed to be reported abroad!");
                }
            }
        }

        return proceed;
    }

    private async removeCurrentAccount(index: number): Promise<void> {
        try {
            const thatTranslated = this.formService.getTranslate("GENERAL.GENDER.THAT", null, true);
            const originTranslated = this.formService.getTranslate("ENTITY.CHECKING_ACCOUNT", null, true);
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: `${this.formService.getTranslate("GENERAL.CONFIRM")}`,
                headerText: `${this.formService.getTranslate("GENERAL.CONFORM_ACTION")}`,
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thatTranslated, prop: originTranslated })
            });

            if (!modal) return;

            this.formService.block();

            this.$scope.model.CURRENT_ACCOUNT.splice(index, 1);
            if (this.$scope.model.CURRENT_ACCOUNT && this.$scope.model.CURRENT_ACCOUNT.length === 0) this.$scope.model.CURRENT_ACCOUNT = null;

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private preventSpecialChars(event, element) {
        this.$scope.model[element] = StringUtil.removeSpecialChars(this.$scope.model[element]);
    }

    private async getLegalPersonById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/legalPerson/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "Integration Product/Operation" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async integrateSankhya(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            if (!this.$scope.model.ERP_INTEGRATION_PARAM || this.$scope.model.DOCUMENT_ERROR) {
                const result = await this.dataProductService.post(`/sync/sankhya/legalPerson`, { "id": [id] }, 120000)
                if (result.data) this.formService.notifySuccess("REGISTRATION.SANKHYA_RECORD_INTEGRATION_WARNING");
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async integrateSankhyaStatus(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            if (this.$scope.model.ERP_INTEGRATION_PARAM && (!this.$scope.model.ERP_INTEGRATION_PARAM.ERP_STATUS || (this.$scope.model.ERP_INTEGRATION_PARAM.ERP_STATUS && this.$scope.model.ERP_INTEGRATION_PARAM.ERP_STATUS.ID == "N"))) {
                const result = await this.dataProductService.post(`/sync/sankhya/legalPerson/status`, { "id": [id] }, 120000)
                if (result.data) this.formService.notifySuccess("REGISTRATION.SANKHYA_RECORD_INTEGRATION_WARNING");
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getCodEmpERP(search?: string): Promise<ICity[]> {
        let result = [];
        this.formService.block();
        try {
            const results = await this.RestService.newObjectPromise(`${this.monacoConfig.externalUrl}/external/sankhya/company/list`, { search: search }, 30000, false);
            if (results) result = results
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getTopBillingERP(search?: string): Promise<ICity[]> {
        let result = [];
        this.formService.block();
        try {
            const results = await this.RestService.newObjectPromise(`${this.monacoConfig.externalUrl}/external/sankhya/top/salesInvoice`, { search: search }, 30000, false);
            if (results) result = results
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private sendSync = async (id: number, idSubject: string): Promise<boolean> => {
        let sendSync = false;
        let syncAccountCompiled = false;
        let success = false;
        try {
            if (id) {
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/legalPerson/sendSync`, { "idLegalPerson": id, "idSubject": idSubject }, 120000, false);
                if (syncRequest) sendSync = true;

                const rc = await this.dataProductService.post(`/sync/currentAccountCompiled`, { "idLegalPerson": [id] }, 120000);
                if (rc && rc.data && rc.data.data && rc.status == 200) syncAccountCompiled = true;

                success = (sendSync && syncAccountCompiled);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_SENDING_REQUEST');
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const legalPersonData = await this.getLegalPersonById(id);
                    if (row && legalPersonData && legalPersonData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = legalPersonData.DOCUMENT_ERROR;
                        documentError = legalPersonData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.initPanels();
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
            this.SSEService.closeEvents();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.formOperation = this.$scope.formOperation;
                this.$scope.menuFloating.infos = [{ text: "ENTITY.LEGAL_PERSON", class: "text-rouge font-bold" }];
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            BrowserTitle.$id = this.$scope.model.SHORT_NAME;
            this.$scope.scopeBeforeSave = null;
            this.$scope.initPanels();
            this.$scope.model.OPENING_DATE = (this.$scope.model.OPENING_DATE != null) ? new Date(this.$scope.model.OPENING_DATE) : null;
            this.$scope.model.INTEGRATION_ID = null;
            this.$scope.model.ERP_INTEGRATION_PARAM = null;
            this.$scope.model.SPEC = null;
            this.SSEService.closeEvents();
            await this.clearFields(this.$scope.model);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.disableElements(true);
            BrowserTitle.$id = this.$scope.model.SHORT_NAME;
            this.$scope.initPanels();
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")}`;
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.SSEService.closeEvents();
            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [{ text: this.$scope.model.SHORT_NAME + " - " + this.$scope.model.TYPE.NAME + ": " + this.$scope.model.ID_FISCAL, class: "text-rouge font-bold" }];
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            BrowserTitle.$id = this.$scope.model.SHORT_NAME;
            this.$scope.initPanels();
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")}`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [{ text: this.$scope.model.SHORT_NAME + " - " + this.$scope.model.TYPE.NAME + ": " + this.$scope.model.ID_FISCAL, class: "text-rouge font-bold" }];
            }
            this.collapseHeader("collapseSpec", "show");
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        let isValidSpec: boolean;
        let isValidCurrAcc: boolean;

        if (!this.$scope.model.SPEC || this.$scope.model.SPEC.length === 0){ 
            this.$formService.notifyError(this.$formService.getTranslate('REGISTRATION.MESSAGES.ERROR.SPECIALTY_IS_REQUIRED'));
            return;
        }

        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                isValidSpec = await this.validateSpecs();
                isValidCurrAcc = await this.validateCurrentAccount();
                this.SSEService.closeEvents();
            } catch (ex) {
                this.handleError(ex);
            }
        }

        return isValidCurrAcc && isValidSpec;
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
        this.$scope.scopeBeforeSave = angular.copy(this.$scope.model)
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === "edit" ? "update" : "insert";

        if (this.$scope.model.hasOwnProperty('CONTACTS')) delete this.$scope.model.CONTACTS;

        return {
            route: `/legalPerson/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: this.$scope.scopeBeforeSave
        };
    }

    private async updateContactList(idLegalPerson: number): Promise<void> {
        try {
            const contacts = await this.getContactListCustom(idLegalPerson);
            if (contacts && contacts.length) this.$scope.model.CONTACTS = contacts;
            else this.$scope.model.CONTACTS = [];
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async getContactListCustom(idLegalPerson: number): Promise<IPersonContacts[]> {
        let result = [];
        this.formService.block();
        try {
            const contacts = await this.RestService.newObjectPromise(`${this.$baseUrl}/contact/list/legalPerson/custom`, { idLegalPerson }, 30000, false);
            if (contacts) result = contacts.map(contact => { return { ID: contact.ID, NAME: contact.NAME, EMAIL: contact.EMAIL, PERSON_ROLE: contact.PERSON_ROLE, PHONE: contact.PHONE, SECTOR: contact.SECTOR } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/legalPerson/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }
}
import * as angular from 'angular';

const isEllipsized = element => element.offsetWidth < element.scrollWidth;
const isUiSelectTooltipEllipsized = (element, parent) => element.offsetWidth > parent.offsetWidth;

ellipsisTooltip.$inject = []
export function ellipsisTooltip() {
    let ddo: any = {};

    ddo.restrict = "A";

    ddo.link = (scope, element, attrs) => {
        let el = element[0];
        const ttDisableAttr = 'tooltipEnable';
        const ttEventTrigger = 'mouseenter';
        const isUiSelect = el && el.classList && el.classList.contains("ui-select-match");

        const ttShowEventBind = (event, isTriggeredByItself) => {
            let isUiSelectTooltip = false;
            if (isUiSelect) {
                const uiSelectMatch = element[0].getElementsByClassName("ui-select-match-text")[0];
                const placeholder = element[0].getElementsByClassName("ui-select-placeholder")[0];
                // check if the tooltip is displayed
                isUiSelectTooltip = placeholder && placeholder.classList && !placeholder.classList.contains("ng-hide");
                // if tooltip is displayed take his element, otherwise take the match 
                el = isUiSelectTooltip ? placeholder : uiSelectMatch;
            }

            if (isUiSelectTooltip ? isUiSelectTooltipEllipsized(el, element[0]) : isEllipsized(el)) {
                attrs.$set(ttDisableAttr, 'true');

                if (!isTriggeredByItself) {
                    element.triggerHandler(ttEventTrigger, [true]);
                }

                scope.$applyAsync(() => {
                    attrs.$set(ttDisableAttr, 'false');
                });
            }
        };

        if (angular.isObject(el)) {
            attrs.$set(ttDisableAttr, 'false');

            element.on(ttEventTrigger, ttShowEventBind);

            element.on('$destroy', () => {
                element.off(ttEventTrigger, ttShowEventBind);
            });
        }
    }
    return ddo;
}

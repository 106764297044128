import angular = require('angular');
import { IRestService } from "@services/RestService";
import { FormService2, IFormServiceScope } from '@services/FormService2';
import { ProductService } from '@services/ProductService';
import { IModalService } from '@services/ModalService';
import { OperationalService } from '@services/OperationalService';
import { ExternalService } from '@services/ExternalService';
import { ICustomLogProperties, IViewLog } from "@models/interface/common/IViewLog";
import { IProvider, IMaterials, ISerialNumbers, IProcessVoucher, IMaterial, IProcessFlexitanq, EInformations } from '@models/interface/operational/ProcessFlexitanq';
import { ISelectorModel, SelectorModel } from '@models/mongo/SelectorModel';
import { INewProcessScope } from './NewProcessController';
import { IERPSerieFlextanqRequestData, IERPVoucherFlextanqRequestData, ISankhyaMaterialFlexitanq } from '@models/interface/external/ISankhya';
import { HelperService } from "@services/HelperService";

interface INewProcessFlexitanqScope extends ng.IScope {
    log: IViewLog;
    model: IProcessFlexitanq;
    oldModel: IProcessFlexitanq;
    packageTypeList: ISelectorModel[];
    materialList: IMaterial[];
    providerList: IProvider[];
    storageList: ISelectorModel[];
    applicationList: ISelectorModel[];
    containerList: ISelectorModel[]; // every container that can be used on the modal (can be availiable or selected)
    selectedContainers: string[]; // containers that have been selected on some serial numbers
    availiableContainers: ISelectorModel[]; // containers to be listed on container selectors
    modalMaterial: IMaterials;
    processNumber: string;
    customLogProperties: ICustomLogProperties[];
    collapseFlexitanqIntegration: () => void;
    isCollapseIn(): boolean;
    hasChanges: () => boolean;
    addFlexitanq: () => void;
    removeFlexitanq: (index: number) => Promise<void>;
    createOrderFlexitanq: () => Promise<void>;
    confirmOrderFlexitanq: () => Promise<void>;
    confirmBillingFlexitanq: () => Promise<void>;
    confirmReverseFlexitanq: () => Promise<void>;
    manageBillingFlexitanq: (action: number) => Promise<void>;
    getProviderListByName: (search: string) => Promise<void>;
    getMaterialListByName: (search: string) => Promise<void>;
    getStorageListByName: (search: string) => Promise<void>;
    openFlexitanqDetailModal: (index: number) => void;
    saveProcessTabFlexitanq: () => Promise<void>;
    buildSerialNumberText: (index: number) => string;
    enableToSave: () => boolean;
    enableToCreateOrder: () => boolean;
    enableToEdit: () => boolean;
    enableToEditDetailModal: () => boolean;
    enableToIntegrate: () => boolean;
    viewLogFlexitanq: () => Promise<void>;
    buildInformationTooltip: () => string;
    updateAvailiableContainers: () => Promise<void>;
    getAvailiableContainers: (container: any) => ISelectorModel[];
}

interface IFlexitanqDetailModalScope extends IFormServiceScope {
    operation: string;
    processNumber: string;
    applicationList: ISelectorModel[];
    serialNumberList: string[];
    voucherList: ISelectorModel[];
    materialSituationList: ISelectorModel[];
    serialNumbers: ISerialNumbers[];
    oldSerialNumbers: ISerialNumbers[];
    materialOpener: IMaterials;
    enableToSave: boolean;
    enableToEdit: boolean;
    addSerialNumbers: () => void;
    removeSerialNumbers: (index: number) => void;
    applySerialNumbers: (close?: boolean) => void;
    closeSerialNumbersModal: () => Promise<void>;
    getSerialNumberList: () => Promise<void>;
    getVoucherList: (serial: ISelectorModel) => Promise<void>;
    serialNumberVoucherChange: (index: number, selected: ISelectorModel) => Promise<void>;
    serialNumberChange: (index: number) => void;
    isSerieApplication: (index: number) => boolean;
    isQuantityApplication: (index: number) => boolean;
    calculateTotal: (index: number) => number;
    changeVoucher: (index: number) => void;
}

export class NewProcessFlexitanqController {
    static $inject: string[] = ['$scope', '$injector'];
    private $scope: INewProcessFlexitanqScope;
    private $newProcessScope: INewProcessScope;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private $sce: angular.ISCEService;
    private $compile: angular.ICompileService;
    private productService: ProductService;
    private externalService: ExternalService;
    private restService: IRestService;
    private formService: FormService2;
    private modalService: IModalService;
    private operationalService: OperationalService;
    private flexitanqDetailModalId: number;
    private helperService: HelperService;

    constructor($scope: INewProcessFlexitanqScope, $injector: ng.Injectable<any>) {
        this.$scope = $scope;
        this.$newProcessScope = <INewProcessScope>$scope.$parent.$parent.$parent.$parent.$parent;
        this.$q = this.$newProcessScope.$q;
        this.$sce = this.$newProcessScope.$sce;
        this.$compile = this.$newProcessScope.$compile;
        this.$timeout = this.$newProcessScope.$timeout;
        this.productService = this.$newProcessScope.productService;
        this.externalService = this.$newProcessScope.externalService;
        this.restService = this.$newProcessScope.restService;
        this.formService = this.$newProcessScope.formService;
        this.modalService = this.$newProcessScope.modalService;
        this.operationalService = this.$newProcessScope.operationalService;
        this.initScopeFunctions();
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initModel();
            await this.initDependencies();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initModel(): void {

        this.$scope.model = {
            MATERIALS: null,
            ERP_BILLING: null,
            ERP_ORDER: null,
            ERP_REVERSE: null,
            ERP_STATUS: null,
            PROCESS_NUMBER: this.$newProcessScope.model.PROCESS_NUMBER,
            INFORMATIONS: null,
            _id: null
        };
        this.$scope.oldModel = angular.copy(this.$scope.model);

    }

    private initScopeFunctions(): void {

        this.$scope.collapseFlexitanqIntegration = (): void => {
            this.collapseFlexitanqIntegration();
        }

        this.$scope.hasChanges = (): boolean => {
            return this.$newProcessScope.hasChanges(this.$scope.model, this.$scope.oldModel);
        }

        this.$scope.addFlexitanq = (): void => {
            this.addFlexitanq();
        }

        this.$scope.saveProcessTabFlexitanq = async (): Promise<void> => {
            this.saveProcessTabFlexitanq();
        }

        this.$scope.removeFlexitanq = async (index: number): Promise<void> => {
            this.removeFlexitanq(index);
        }

        this.$scope.getAvailiableContainers = (container: any): ISelectorModel[] => {
            return this.getAvailiableContainers(container);
        }

        this.$scope.updateAvailiableContainers = async (): Promise<void> => {
            this.updateAvailiableContainers();
        }

        this.$scope.createOrderFlexitanq = async (): Promise<void> => {
            this.createOrderFlexitanq();
        }

        this.$scope.confirmOrderFlexitanq = async (): Promise<void> => {
            this.confirmOrderFlexitanq();
        }

        this.$scope.confirmBillingFlexitanq = async (): Promise<void> => {
            this.confirmBillingFlexitanq();
        }

        this.$scope.confirmReverseFlexitanq = async (): Promise<void> => {
            this.confirmReverseFlexitanq();
        }

        this.$scope.manageBillingFlexitanq = async (action: number): Promise<void> => {
            this.manageBillingFlexitanq(action);
        }

        this.$scope.getMaterialListByName = async (search: string): Promise<void> => {
            let materialList: IMaterial[] = [];
            if (search && search.length >= 3) materialList = await this.getMaterialListByName(search);
            this.$scope.materialList = materialList;
        }

        this.$scope.getProviderListByName = async (search: string) => {
            let providerList: IProvider[] = [];
            if (search && search.length >= 3) providerList = await this.getLegalPersonListByName(search);
            this.$scope.providerList = providerList;
        }

        this.$scope.getStorageListByName = async (search: string) => {
            let storageList: ISelectorModel[] = [];
            if (search && search.length >= 3) storageList = await this.getStorageListByName(search);
            this.$scope.storageList = storageList;
        }

        this.$scope.openFlexitanqDetailModal = (index: number): void => {
            this.openFlexitanqDetailModal(index);
        }

        this.$scope.buildSerialNumberText = (index: number): string => {
            return this.buildSerialNumberText(index);
        }

        this.$scope.enableToSave = (): boolean => {
            return this.enableToSave();
        }

        this.$scope.enableToCreateOrder = (): boolean => {
            return this.enableToCreateOrder();
        }

        this.$scope.enableToEdit = (): boolean => {
            return this.enableToEdit();
        }

        this.$scope.enableToEditDetailModal = (): boolean => {
            return this.enableToEditDetailModal();
        }

        this.$scope.enableToIntegrate = (): boolean => {
            return this.enableToIntegrate();
        }

        this.$scope.viewLogFlexitanq = (): Promise<void> => {
            return this.viewLogFlexitanq();
        }

        this.$scope.buildInformationTooltip = () => {
            let result = '<div class="text-left">';
            if (this.$scope.model.INFORMATIONS) {
                for (const info of this.$scope.model.INFORMATIONS) {
                    result += `<b>${info.ID == EInformations.CHARGE ? this.formService.getTranslate('OPERATIONAL.FLEXITANQ_CHARGE_INFO') : ""}</b>: `;
                    for (const data of info.DATA) {
                        result += `<br><b>${this.formService.getTranslate('OPERATIONAL.VOUCHER')}</b>: ${data.ID ? data.ID : ""}`;
                        result += `<br><b>${this.formService.getTranslate('OPERATIONAL.FILE_NUMBER')}</b>: ${data.NAME ? data.NAME : ""}`;
                        result += `<br>`;
                    }
                }
            }
            result += "</div>";
            return result;
        }
    }

    async initDependencies(): Promise<any> {
        const self: NewProcessFlexitanqController = this;

        this.initCollapseEvents();

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericList('flexitanq_application'),
            ]).then(async (result: any) => {
                self.$scope.applicationList = result[0];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });

    }

    private async getGenericList(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private initCollapseEvents() {
        const collapseFlexitanqIntegration = angular.element('#collapseFlexitanqIntegration');
        this.getProcessTabsFlexitanq();
        if (collapseFlexitanqIntegration) {
            collapseFlexitanqIntegration.on('shown.bs.collapse', (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseFlexitanqIntegrationHeading").attr('aria-expanded', 'true');
                }
            });
            collapseFlexitanqIntegration.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseFlexitanqIntegrationHeading").attr('aria-expanded', 'false');
                }
            });
        }
    }

    private addFlexitanq(): void {
        try {
            if (!this.$scope.model.MATERIALS) this.$scope.model.MATERIALS = [];
            const material: IMaterials = {
                UUID: null,
                EFFECTIVE_AMOUNT: 0,
                ESTIMATED_AMOUNT: this.$newProcessScope.model.CARGO_DETAIL && this.$newProcessScope.model.CARGO_DETAIL.EQUIPMENT_LIST ? this.$newProcessScope.model.CARGO_DETAIL.EQUIPMENT_LIST.reduce((acum, z) => acum + z.QUANTITY, 0) : 0,
                MATERIAL: null,
                PROVIDER: null,
                SERIAL_NUMBERS: null,
                STORAGE: null,
                ERP_SEQUENCE: null
            }
            if (!this.$scope.model.MATERIALS) this.$scope.model.MATERIALS = [];
            this.$scope.model.MATERIALS.push(material);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeFlexitanq(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            if (!this.$scope.enableToEdit()) return;
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: await this.formService.getTranslate('GENERAL.CONFIRM'),
                headerText: await this.formService.getTranslate('GENERAL.CONFIRM_ACTION'),
                closeButtonText: await this.formService.getTranslate('GENERAL.CLOSE'),
                bodyText: await this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.$scope.model.MATERIALS && this.$scope.model.MATERIALS.length > 0) {
                if (this.$scope.model.MATERIALS[index].UUID && this.$scope.model.ERP_ORDER) {
                    this.formService.block();
                    const params = { flexitanqData: this.$scope.model, sequence: this.$scope.model.MATERIALS[index].ERP_SEQUENCE }
                    const flexitanqUpdateResponse = await this.operationalService.post(`/flexitanq/remove`, params);
                    if (flexitanqUpdateResponse && flexitanqUpdateResponse.status == 200) {
                        const msg = await this.formService.getTranslate('BASIC_DATA.FLEXITANQ_TAB_DATA_SUCESSFULLY_SAVED');
                        this.formService.notifySuccess(msg);
                        if (this.$scope.model.MATERIALS.length == 1) this.$scope.model.MATERIALS = null;
                        else this.$scope.model.MATERIALS.splice(index, 1);
                        this.getProcessTabsFlexitanq();
                    }
                } else {
                    this.$scope.model.MATERIALS.splice(index, 1);
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async createOrderFlexitanq(): Promise<void> {
        try {
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: await this.formService.getTranslate('GENERAL.CONFIRM'),
                headerText: await this.formService.getTranslate('GENERAL.CONFIRM_ACTION'),
                closeButtonText: await this.formService.getTranslate('GENERAL.CLOSE'),
                bodyText: await this.formService.getTranslate("OPERATIONAL.MESSAGES.CONFIRMATION.FLEXITANQ_ORDER_CREATE")
            });
            if (!modal) return;

            if (!this.$scope.model.ERP_STATUS) {
                this.formService.block();
                const params = { data: this.$scope.model }
                const flexitanqUpdateResponse = await this.operationalService.post(`/flexitanq/order/create`, params);
                if (flexitanqUpdateResponse && flexitanqUpdateResponse.status == 200) {
                    const msg = await this.formService.getTranslate('BASIC_DATA.FLEXITANQ_TAB_DATA_SUCESSFULLY_SAVED');
                    this.formService.notifySuccess(msg);
                    this.getProcessTabsFlexitanq();
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async confirmOrderFlexitanq(): Promise<void> {
        try {
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: await this.formService.getTranslate('GENERAL.CONFIRM'),
                headerText: await this.formService.getTranslate('GENERAL.CONFIRM_ACTION'),
                closeButtonText: await this.formService.getTranslate('GENERAL.CLOSE'),
                bodyText: await this.formService.getTranslate("OPERATIONAL.MESSAGES.CONFIRMATION.FLEXITANQ_ORDER_CONFIRM")
            });
            if (!modal) return;

            if (this.$scope.model.ERP_STATUS && this.$scope.model.ERP_STATUS.ID == "1") {
                this.formService.block();
                const params = { data: this.$scope.model }
                const flexitanqUpdateResponse = await this.operationalService.post(`/flexitanq/order/confirm`, params);
                if (flexitanqUpdateResponse && flexitanqUpdateResponse.status == 200) {
                    const msg = await this.formService.getTranslate('BASIC_DATA.FLEXITANQ_TAB_DATA_SUCESSFULLY_SAVED');
                    this.formService.notifySuccess(msg);
                    this.getProcessTabsFlexitanq();
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async confirmBillingFlexitanq(): Promise<void> {
        try {
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: await this.formService.getTranslate('GENERAL.CONFIRM'),
                headerText: await this.formService.getTranslate('GENERAL.CONFIRM_ACTION'),
                closeButtonText: await this.formService.getTranslate('GENERAL.CLOSE'),
                bodyText: await this.formService.getTranslate("OPERATIONAL.MESSAGES.CONFIRMATION.FLEXITANQ_BILLING_CONFIRM")
            });
            if (!modal) return;

            if (this.$scope.model.ERP_STATUS && this.$scope.model.ERP_STATUS.ID == "3") {
                this.formService.block();
                const params = { data: this.$scope.model, timeout: 120000 }
                const flexitanqUpdateResponse = await this.operationalService.post(`/flexitanq/billing/confirm`, params, 120000);
                if (flexitanqUpdateResponse && flexitanqUpdateResponse.status == 200) {
                    const msg = await this.formService.getTranslate('BASIC_DATA.FLEXITANQ_TAB_DATA_SUCESSFULLY_SAVED');
                    this.formService.notifySuccess(msg);
                    this.getProcessTabsFlexitanq();
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async confirmReverseFlexitanq(): Promise<void> {
        try {
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: await this.formService.getTranslate('GENERAL.CONFIRM'),
                headerText: await this.formService.getTranslate('GENERAL.CONFIRM_ACTION'),
                closeButtonText: await this.formService.getTranslate('GENERAL.CLOSE'),
                bodyText: await this.formService.getTranslate("OPERATIONAL.MESSAGES.CONFIRMATION.FLEXITANQ_REVERSE_CONFIRM")
            });
            if (!modal) return;

            if (this.$scope.model.ERP_STATUS && this.$scope.model.ERP_STATUS.ID == "5") {
                this.formService.block();
                const params = { data: this.$scope.model, timeout: 600000 }
                const flexitanqUpdateResponse = await this.operationalService.post(`/flexitanq/reverse/confirm`, params, 600000);
                if (flexitanqUpdateResponse && flexitanqUpdateResponse.status == 200) {
                    const msg = await this.formService.getTranslate('BASIC_DATA.FLEXITANQ_TAB_DATA_SUCESSFULLY_SAVED');
                    this.formService.notifySuccess(msg);
                    this.getProcessTabsFlexitanq();
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async manageBillingFlexitanq(action: number): Promise<void> {
        try {
            let bodyText = "OPERATIONAL.MESSAGES.CONFIRMATION.FLEXITANQ_BILLING_REVERSE";
            if (action == 1) bodyText = "OPERATIONAL.MESSAGES.CONFIRMATION.FLEXITANQ_BILLING_CREATE";

            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: await this.formService.getTranslate('GENERAL.CONFIRM'),
                headerText: await this.formService.getTranslate('GENERAL.CONFIRM_ACTION'),
                closeButtonText: await this.formService.getTranslate('GENERAL.CLOSE'),
                bodyText: await this.formService.getTranslate(bodyText)
            });
            if (!modal) return;

            if (this.$scope.model.ERP_STATUS && (this.$scope.model.ERP_STATUS.ID == "2" || this.$scope.model.ERP_STATUS.ID == "3" || this.$scope.model.ERP_STATUS.ID == "4") && this.$scope.model.MATERIALS.every(x => x.SERIAL_NUMBERS)) {
                this.formService.block();
                const params = { data: this.$scope.model, action: action }
                const flexitanqUpdateResponse = await this.operationalService.post(`/flexitanq/billing/management`, params);
                if (flexitanqUpdateResponse && flexitanqUpdateResponse.status == 200) {
                    const msg = await this.formService.getTranslate('BASIC_DATA.FLEXITANQ_TAB_DATA_SUCESSFULLY_SAVED');
                    this.formService.notifySuccess(msg);
                    this.getProcessTabsFlexitanq();
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getLegalPersonListByName(search?: string): Promise<IProvider[]> {
        let result: IProvider[] = [];
        try {
            this.formService.block();
            const response = await this.productService.post({ route: "/legalPerson/list/custom/operational/flexitanq", data: { search: search } });
            if (response && response.data && response.data.data) result = response.data.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getMaterialListByName(search?: string): Promise<IMaterial[]> {
        let result: ISankhyaMaterialFlexitanq[] = [];
        let materialsList: IMaterial[] = [];
        try {
            this.formService.block();
            const response = await this.externalService.post({ route: "/sankhya/flexitanq/material/list", data: { search: search } });
            if (response && response.data && response.data.data) result = response.data.data;
            for (const res of result) {
                const material: IMaterial = {
                    ID: res.ID,
                    NAME: res.NAME,
                    CODE: res.CODE,
                    APPLICATION: null,
                    CHARGE: null,
                    GROUP: res.GROUP
                };

                if (res.TYPE == "E") { //Serie
                    material.APPLICATION = this.$scope.applicationList && this.$scope.applicationList.find(x => x.ID == "1");
                } else {
                    material.APPLICATION = this.$scope.applicationList && this.$scope.applicationList.find(x => x.ID == "2");
                }
                let charge: ISelectorModel = null;
                if (res.ID_CHARGE) {
                    const response = await this.productService.get({ route: `/chargeName/getById/${res.ID_CHARGE}` });
                    if (response && response.data && response.data.data) charge = response.data.data;
                    if (charge) {
                        const chr: ISelectorModel = {
                            ID: charge.ID.toString(),
                            NAME: charge.NAME,
                            CODE: charge.CODE
                        }
                        material.CHARGE = chr
                    }
                }

                materialsList.push(material);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return materialsList;
        }
    }

    private async getStorageListByName(search?: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            this.formService.block();
            const response = await this.externalService.post({ route: "/sankhya/flexitanq/storage/list", data: { search: search } });
            if (response && response.data && response.data.data) result = response.data.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async collapseFlexitanqIntegration(ignoreChange: boolean = false) {
        try {
            const collapseFlexitanqIntegration = angular.element("#collapseFlexitanqIntegration");
            if (collapseFlexitanqIntegration) {
                const isCollapsed = angular.element("#collapseFlexitanqIntegrationHeading").attr("aria-expanded") == "true";
                if (isCollapsed) {
                    const changes = this.$newProcessScope.hasChanges(this.$scope.model, this.$scope.oldModel)
                    if (changes) {
                        if (!ignoreChange) {
                            const close = await this.formService.getTranslate('GENERAL.CLOSE');
                            const material = await this.formService.getTranslate('GENERAL.MATERIAL');
                            const confirm = await this.$newProcessScope.modalSaveConfirmation(material, close);
                            if (confirm && !await this.saveProcessTabFlexitanq()) return;
                        }
                    }
                }
                collapseFlexitanqIntegration['collapse']('toggle');
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getProcessTabsFlexitanq(): Promise<void> {
        this.formService.block();
        try {
            const flexitanqTab = await this.operationalService.get(`/process/panel/flexitanqIntegration/${this.$newProcessScope.model.PROCESS_NUMBER}`, 30000);
            if (flexitanqTab && flexitanqTab.data && flexitanqTab.data.data) this.$scope.model = flexitanqTab.data.data;
            else this.initModel();
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.oldModel = angular.copy(this.$scope.model);
            if (this.$newProcessScope.operation == 'edit') {
                this.$timeout(() => {
                    this.formService.loadEditForm();
                }, 100);
            }
            this.formService.unblock();
        }
    }

    private async openFlexitanqDetailModal(index): Promise<void> {
        if (!this.$scope.model.MATERIALS[index].MATERIAL || !this.$scope.model.MATERIALS[index].STORAGE) return
        this.$newProcessScope.lastScroll = $('.app-content-body').scrollTop();
        this.flexitanqDetailModalId = this.modalService.newModal();
        const serialNumbers = this.$scope.model.MATERIALS[index].SERIAL_NUMBERS && this.$scope.model.MATERIALS[index].SERIAL_NUMBERS ? this.$scope.model.MATERIALS[index].SERIAL_NUMBERS : null;
        await this.getSelectedContainers(index);
        this.getContainerList();
        this.modalService.showModalInfo(
            {
                modalID: this.flexitanqDetailModalId,
                scope: this.$scope,
                formService: 'register',
                size: 'full modal-overflow',
                template: require("../view/NewProcessFlexitanqDetailModal.html"),
                keyboard: true,
                events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                    if (event.name == "modal.closing") {
                        if (reason.toString() == "escape key press") event.preventDefault();
                        if (!closed) {
                            const modalScope: IFlexitanqDetailModalScope = await this.modalService.getModalScope(this.flexitanqDetailModalId);
                            await modalScope.closeSerialNumbersModal();
                        }
                    }
                }
            },
            null
        );
        await this.buildFlexitanqSerialNumbersModalScope(serialNumbers, index);
    }

    private async buildFlexitanqSerialNumbersModalScope(serialNumbers: ISerialNumbers[], indexMaterial: number): Promise<IFlexitanqDetailModalScope> {
        const modalScope: IFlexitanqDetailModalScope = await this.modalService.getModalScope(this.flexitanqDetailModalId);

        try {
            modalScope.processNumber = this.$scope.processNumber;
            modalScope.operation = this.$newProcessScope.operation;
            modalScope.serialNumbers = angular.copy(serialNumbers);
            modalScope.oldSerialNumbers = angular.copy(serialNumbers);
            modalScope.materialOpener = this.$scope.model.MATERIALS[indexMaterial];
            modalScope.enableToSave = this.$scope.enableToSave();
            modalScope.enableToEdit = this.$scope.enableToEditDetailModal();

            // get generics
            const result: Array<any> = await this.$q.all([
                this.getGenericList('flexitanq_situation'),
            ]);
            modalScope.applicationList = this.$scope.applicationList;
            modalScope.materialSituationList = result[0];

            modalScope.addSerialNumbers = (): void => {
                try {
                    if (!modalScope.enableToEdit) return;
                    if (!modalScope.serialNumbers) modalScope.serialNumbers = [];

                    const serialNumber: ISerialNumbers = {
                        AMOUNT: 1,
                        APPLICATION: modalScope.materialOpener && modalScope.materialOpener.MATERIAL && modalScope.materialOpener.MATERIAL.APPLICATION ? modalScope.materialOpener.MATERIAL.APPLICATION : null,
                        CONTAINER: null,
                        PROCESS_VOUCHER: null,
                        SENT: null,
                        SENT_REVERSE: null,
                        SERIAL_NUMBER: null,
                        STATUS: null,
                        VALUE: null,
                        CHARGE_PAIR_NUMBER_PAYMENT: null,
                        CHARGE_PAIR_NUMBER_RECEIVING: null
                    }
                    modalScope.serialNumbers.push(serialNumber);

                    const lastIndex = modalScope.serialNumbers.length - 1;
                    this.$timeout(() => {
                        modalScope.selectorValidity("serialNumberApplication" + lastIndex);
                        modalScope.selectorValidity("serialNumberSerialNumber" + lastIndex);
                        modalScope.selectorValidity("serialNumberVoucher" + lastIndex);
                        modalScope.selectorValidity("serialNumberValue" + lastIndex);
                        modalScope.selectorValidity("serialNumberStatus" + lastIndex);
                        modalScope.selectorValidity("serialNumberAmount" + lastIndex);
                    }, 100);
                } catch (ex) {
                    this.formService.handleError(ex);
                }
            }

            modalScope.removeSerialNumbers = async (index: number): Promise<void> => {
                try {
                    if (!index && index != 0) throw Error('index is null');
                    if (!modalScope.enableToEdit) return;
                    const modal = await this.modalService.showModalConfirmation({}, {
                        actionButtonText: 'GENERAL.CONFIRM',
                        headerText: 'GENERAL.CONFIRM_ACTION',
                        bodyText: "GENERAL.MESSAGES.CONFIRMATION.REMOVAL"
                    });
                    if (!modal) return;

                    if (modalScope.serialNumbers && modalScope.serialNumbers.length > 0) {
                        this.formService.block();
                        this.updateAvailiableContainers();
                        modalScope.serialNumbers.splice(index, 1);
                    }
                } catch (ex) {
                    this.formService.handleError(ex);
                } finally {
                    this.formService.unblock();
                }
            }

            modalScope.serialNumberVoucherChange = async (index: number, selected: IProcessVoucher): Promise<void> => {
                modalScope.serialNumbers[index].VALUE = parseInt(selected.CODE);
            }

            modalScope.applySerialNumbers = (close?: boolean) => {
                var reduced = [];
                modalScope.serialNumbers.forEach((item) => {
                    var duplicated = reduced.findIndex(redItem => {
                        return item.SERIAL_NUMBER.ID == redItem.SERIAL_NUMBER.ID;
                    }) > -1;

                    if (!duplicated) {
                        reduced.push(item);
                    }
                })

                if (reduced.length !== modalScope.serialNumbers.length) {
                    this.formService.notifyError("Duplicate serial numbers were found");
                } else {
                    this.applySerialNumbers(modalScope.serialNumbers, indexMaterial, close);
                }
            };

            modalScope.closeSerialNumbersModal = async (): Promise<void> => {
                if (this.$newProcessScope.hasChanges(modalScope.serialNumbers, modalScope.oldSerialNumbers) && modalScope.enableToEdit) {
                    const close = await this.formService.getTranslate('GENERAL.CLOSE');
                    const confirm = await this.$newProcessScope.modalSaveConfirmation(close, close);
                    if (confirm && !this.applySerialNumbers(modalScope.serialNumbers, indexMaterial, true)) return;
                    else {
                        this.modalService.closeModal(this.flexitanqDetailModalId);
                        this.flexitanqDetailModalId = 0;
                        $('.app-content-body').animate({ scrollTop: this.$newProcessScope.lastScroll }, 0);
                    }
                } else {
                    this.modalService.closeModal(this.flexitanqDetailModalId);
                    this.flexitanqDetailModalId = 0;
                    $('.app-content-body').animate({ scrollTop: this.$newProcessScope.lastScroll }, 0);
                }
            }

            modalScope.getSerialNumberList = async (): Promise<void> => {
                modalScope.serialNumberList = await this.getSerialNumberList(modalScope.materialOpener);
            };

            modalScope.getVoucherList = async (serial: ISelectorModel): Promise<void> => {
                modalScope.voucherList = await this.getVoucherList(serial, modalScope.materialOpener);
            };

            modalScope.serialNumberChange = async (index: number): Promise<void> => {
                if (!index && index != 0) return this.formService.notifyError("index is null.");
                const serial = modalScope.serialNumbers && modalScope.serialNumbers.length ? modalScope.serialNumbers[index].SERIAL_NUMBER : null;
                if (serial) {
                    await modalScope.getVoucherList(serial);
                }
            }

            modalScope.isSerieApplication = (index: number): boolean => {
                let isSerieApplication = false;
                try {
                    if (!index && index != 0) throw Error('index is null');
                    if (modalScope.serialNumbers[index].APPLICATION && modalScope.serialNumbers[index].APPLICATION.ID == "1") {
                        isSerieApplication = true;
                        modalScope.serialNumbers[index].AMOUNT = 1;
                    }
                } catch (ex) {
                    this.formService.handleError(ex);
                } finally {
                    return isSerieApplication;
                }
            }

            modalScope.isQuantityApplication = (index: number): boolean => {
                let isQuantityApplication = false;
                try {
                    if (!index && index != 0) throw Error('index is null');
                    if (modalScope.serialNumbers[index].APPLICATION && modalScope.serialNumbers[index].APPLICATION.ID == "2") {
                        isQuantityApplication = true;
                    }
                } catch (ex) {
                    this.formService.handleError(ex);
                } finally {
                    return isQuantityApplication;
                }
            }

            modalScope.calculateTotal = (index: number): number => {
                try {
                    if (!index && index != 0) throw Error('index is null');
                    return modalScope.serialNumbers[index].AMOUNT * modalScope.serialNumbers[index].VALUE;
                } catch (ex) {
                    this.formService.handleError(ex);
                }
            }

            modalScope.changeVoucher = (index: number): void => {
                try {
                    if (!index && index != 0) throw Error('index is null');
                    modalScope.serialNumbers[index].VALUE = modalScope.serialNumbers[index].PROCESS_VOUCHER && modalScope.serialNumbers[index].PROCESS_VOUCHER.CODE ? parseFloat(modalScope.serialNumbers[index].PROCESS_VOUCHER.CODE) : 0;
                } catch (ex) {
                    this.formService.handleError(ex);
                }
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }

        return modalScope;
    }

    private getContainerList(): void {
        this.$scope.containerList = [];
        try {
            if (this.$newProcessScope.model.EVENT) {
                const eventLoad = this.$newProcessScope.model.EVENT.find(x => x.EVENT_TYPE.ID == "4");
                if (eventLoad && eventLoad.CONTAINER_GROUP) {
                    this.$scope.containerList = eventLoad.CONTAINER_GROUP.map(x => { return { ID: x.CONTAINER_ID, NAME: x.CONTAINER_NUMBER, CODE: x.CONTAINER_TYPE } });
                    this.initializeAvailiableContainers();
                }
                else
                    throw Error('Container not found! Check LOAD event, container group.');
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initializeAvailiableContainers(): void {
        this.$scope.availiableContainers = this.$scope.containerList.filter(c => this.$scope.selectedContainers.indexOf(c.NAME) == -1);
        this.$scope.containerList = this.$scope.availiableContainers;
        if (this.$scope.modalMaterial.SERIAL_NUMBERS) this.$scope.containerList = this.$scope.containerList.concat(this.$scope.modalMaterial.SERIAL_NUMBERS.map(s => s.CONTAINER))
    }

    private async getSelectedContainers(selectedMaterialIndex: number): Promise<void> {
        const selectedMaterial = this.$scope.model.MATERIALS[selectedMaterialIndex];
        this.$scope.modalMaterial = selectedMaterial;
        const materials = this.$scope.model.MATERIALS.filter(m => m.MATERIAL.GROUP == selectedMaterial.MATERIAL.GROUP && m.MATERIAL.APPLICATION.ID == selectedMaterial.MATERIAL.APPLICATION.ID);
        const selectedContainers: ISelectorModel[] = materials.reduce((arr, m) => m.SERIAL_NUMBERS ? arr.concat(m.SERIAL_NUMBERS) : arr, []).map(s => s.CONTAINER);
        this.$scope.selectedContainers = selectedContainers.map(c => c.NAME);
    }

    private getAvailiableContainers(container: any): ISelectorModel[] {
        if (!container)
            return this.$scope.availiableContainers;

        if (!this.$scope.availiableContainers.find(a => a.ID == container.ID))
            return [container].concat(this.$scope.availiableContainers);

        return this.$scope.availiableContainers;
    }

    private async updateAvailiableContainers(): Promise<void> {
        const modalScope: IFlexitanqDetailModalScope = await this.modalService.getModalScope(this.flexitanqDetailModalId);

        const selected = modalScope.serialNumbers.filter(s => s.CONTAINER).map(s => s.CONTAINER.NAME);
        this.$scope.selectedContainers = selected;
        this.$scope.availiableContainers = this.$scope.containerList.filter(c => selected.indexOf(c.NAME) == -1);
    }

    private async getSerialNumberList(material: IMaterials): Promise<string[]> {
        let result: string[] = [];
        this.formService.block();
        try {
            const filter: IERPSerieFlextanqRequestData = {
                CODLOCAL: material.STORAGE.ID,
                CODPROD: material.MATERIAL.ID
            }

            const series = await this.externalService.post({ route: '/sankhya/flexitanq/serie/list', data: { data: filter } });
            if (series && series.data && series.data.data) result = series.data.data.map(x => { return { ID: x, NAME: x } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getVoucherList(serial: ISelectorModel, material: IMaterials): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        this.formService.block();
        try {
            if (material) {
                const filter: IERPVoucherFlextanqRequestData = {
                    SERIE: serial && serial.ID ? serial.ID : "",
                    CODPROD: material.MATERIAL.ID
                }
                const series = await this.externalService.post({ route: '/sankhya/flexitanq/voucher/list', data: { data: filter } });
                if (series && series.data && series.data.data) result = series.data.data;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async applySerialNumbers(serialNumbers: ISerialNumbers[], index: number, close?: boolean): Promise<boolean> {
        let success = false;
        try {
            const hasInvalid = this.$newProcessScope.hasInvalidRequiredElements('modalForm');
            if (!hasInvalid) {
                this.formService.block();
                this.$scope.model.MATERIALS[index].SERIAL_NUMBERS = serialNumbers;
                this.$scope.model.MATERIALS[index].EFFECTIVE_AMOUNT = serialNumbers.reduce((accum, x) => accum + x.AMOUNT, 0);
                success = true;
                if (close) {
                    this.modalService.closeModal(this.flexitanqDetailModalId);
                    this.flexitanqDetailModalId = 0;
                    $('.app-content-body').animate({ scrollTop: this.$newProcessScope.lastScroll }, 0);
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            if (success) {
                const msg = await this.formService.getTranslate('BASIC_DATA.FLEXITANQ_SUCESSFULLY_UPDATED');
                this.formService.notifySuccess(msg);

            }
            return success;
        }
    }

    private async saveProcessTabFlexitanq(): Promise<boolean> {
        let success = true;
        try {
            const isInvalid = this.$newProcessScope.hasInvalidElements('collapseFlexitanqIntegration');
            if (isInvalid || !this.$scope.model.MATERIALS || (this.$scope.model.MATERIALS && this.$scope.model.MATERIALS.length == 0)) success = false;
            else {
                this.formService.block();
                let route = "/flexitanq/insert";
                const params = { processNumber: this.$newProcessScope.model.PROCESS_NUMBER, data: this.$scope.model }
                if (this.$scope.model._id) route = `/flexitanq/update`;
                const flexitanqUpdateResponse = await this.operationalService.post(route, params);
                if (flexitanqUpdateResponse && flexitanqUpdateResponse.status == 200) {
                    success = true;
                    const msg = await this.formService.getTranslate('BASIC_DATA.FLEXITANQ_TAB_DATA_SUCESSFULLY_SAVED');
                    this.formService.notifySuccess(msg);
                    this.getProcessTabsFlexitanq();

                }
                else success = false;
            }
        } catch (ex) {
            success = false;
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            if (success) this.$scope.oldModel = angular.copy(this.$scope.model);
            else this.$newProcessScope.collapseState.nextState = null;
            return success;
        }
    }

    private enableToSave(): boolean {
        try {
            let enabled: boolean = true;
            if (!this.$scope.model || (this.$scope.model && (!this.$scope.model.MATERIALS) || this.$scope.model.MATERIALS && this.$scope.model.MATERIALS.length == 0) || (this.$scope.model.ERP_STATUS && this.$scope.model.ERP_STATUS.ID && (this.$scope.model.ERP_STATUS.ID == "3" || this.$scope.model.ERP_STATUS.ID == "4" || this.$scope.model.ERP_STATUS.ID == "5")) || (this.$newProcessScope.model.FORWARDING_SITUATION && this.$newProcessScope.model.FORWARDING_SITUATION.SITUATION && (this.$newProcessScope.model.FORWARDING_SITUATION.SITUATION.ID == "5" || this.$newProcessScope.model.FORWARDING_SITUATION.SITUATION.ID == "7"))) {
                enabled = false;
            }
            else {
                if (!this.$scope.model.MATERIALS.every(x => x.SERIAL_NUMBERS)) enabled = false
            }

            return enabled;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private enableToCreateOrder(): boolean {
        try {
            let enabled: boolean = true;
            if ((!this.$scope.model) || (this.$scope.model && (!this.$scope.model._id || !this.$scope.model.MATERIALS || (this.$scope.model.MATERIALS.length == 0))) || (this.$scope.model.ERP_STATUS && this.$scope.model.ERP_STATUS.ID && (this.$scope.model.ERP_STATUS.ID == "3" || this.$scope.model.ERP_STATUS.ID == "4" || this.$scope.model.ERP_STATUS.ID == "5")) || (this.$newProcessScope.model.FORWARDING_SITUATION && this.$newProcessScope.model.FORWARDING_SITUATION.SITUATION && (this.$newProcessScope.model.FORWARDING_SITUATION.SITUATION.ID == "5" || this.$newProcessScope.model.FORWARDING_SITUATION.SITUATION.ID == "7"))) {
                enabled = false;
            }

            return enabled;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private enableToEdit(): boolean {
        try {
            let enabled: boolean = true;
            if (!this.$scope.model || (this.$scope.model && this.$scope.model.ERP_STATUS && this.$scope.model.ERP_STATUS.ID && (this.$scope.model.ERP_STATUS.ID == "2" || this.$scope.model.ERP_STATUS.ID == "3" || this.$scope.model.ERP_STATUS.ID == "4" || this.$scope.model.ERP_STATUS.ID == "5")) || (this.$newProcessScope.model.FORWARDING_SITUATION && this.$newProcessScope.model.FORWARDING_SITUATION.SITUATION && (this.$newProcessScope.model.FORWARDING_SITUATION.SITUATION.ID == "5" || this.$newProcessScope.model.FORWARDING_SITUATION.SITUATION.ID == "7"))) {
                enabled = false;
            }
            return enabled;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private enableToEditDetailModal(): boolean {
        try {
            let enabled: boolean = true;
            if (!this.$scope.model || (this.$scope.model && this.$scope.model.ERP_STATUS && this.$scope.model.ERP_STATUS.ID && (this.$scope.model.ERP_STATUS.ID == "3" || this.$scope.model.ERP_STATUS.ID == "4" || this.$scope.model.ERP_STATUS.ID == "5")) || (this.$newProcessScope.model.FORWARDING_SITUATION && this.$newProcessScope.model.FORWARDING_SITUATION.SITUATION && (this.$newProcessScope.model.FORWARDING_SITUATION.SITUATION.ID == "5" || this.$newProcessScope.model.FORWARDING_SITUATION.SITUATION.ID == "7"))) {
                enabled = false;
            }
            return enabled;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }


    private enableToIntegrate(): boolean {
        try {
            let enabled: boolean = true;
            if (!this.$newProcessScope || (this.$newProcessScope.model.FORWARDING_SITUATION && this.$newProcessScope.model.FORWARDING_SITUATION.SITUATION && (this.$newProcessScope.model.FORWARDING_SITUATION.SITUATION.ID == "5" || this.$newProcessScope.model.FORWARDING_SITUATION.SITUATION.ID == "7"))) {
                enabled = false;
            }
            return enabled;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private buildSerialNumberText(index: number): string {
        try {
            let text = "";
            if (this.$scope.model.MATERIALS) {
                if (this.$scope.model.MATERIALS[index].SERIAL_NUMBERS) {
                    for (const serial of this.$scope.model.MATERIALS[index].SERIAL_NUMBERS) {
                        if (serial.SERIAL_NUMBER && serial.SERIAL_NUMBER.ID) text += `${serial.SERIAL_NUMBER.ID.toString() + ";"}`;
                    }
                }
            }
            return text;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async viewLogFlexitanq(): Promise<void> {
        try {
            this.formService.block();
            let route = `/flexitanq/log/${this.$scope.model._id.toString()}`;
            const request = await this.operationalService.get(route, null);

            const log: IViewLog = {
                operation: 'history',
                number: this.$scope.model._id.toString(),
                list: [],
                show: true,
                showCloseButton: false,
                searchQuery: '',
                originalList: [],
            }

            log.list = request.data.data;
            log.originalList = angular.copy(log.list);
            this.$scope.log = log;
            this.$scope.customLogProperties = this.getCustomLogProperties();

            const modalId = this.modalService.newModal();
            this.modalService.showModalConfirmation(
                {
                    modalID: modalId,
                    scope: this.$scope,
                    template: require('../../common/view/modals/viewLog.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG"
                }
            );
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'ERP_ORDER',
                LABEL: 'Pedido'
            },
            {
                PROPERTY: 'ERP_BILLING',
                LABEL: 'Baixa'
            },
            {
                PROPERTY: 'ERP_REVERSE',
                LABEL: 'Estorno'
            },
            {
                PROPERTY: 'ERP_STATUS',
                LABEL: 'Status integração'
            },
            {
                PROPERTY: 'MATERIALS',
                LABEL: 'OPERATIONAL.MATERIAL'
            }
        ];
        return props;
    }

}
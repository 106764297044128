export const enum GenericConstants {
    // STOPOVER
    STOPOVER_PLANNED = 'PROGRAMADA',
    STOPOVER_OPERATING = 'OPERANDO',
    STOPOVER_FINISHED = 'FINALIZADO',
    STOPOVER_SUGGESTED = 'SUGERIDA',

    // DEADLINE
    DEADLINE_MASTER = 'MASTER',
    DEADLINE_HOUSE = 'HOUSE',

    // YES/NO
    YES_ID = '1',
    NO_ID = '2',
}

export const enum FollowupRecipient {
    CUSTOMER = '1',
    ORIGIN_AGENT = '2',
    DESTINATION_AGENT = '3',
    INTERNAL = '4',
    IMPORTER = '5',
    EXPORTER = '6',
    NOTIFY = '7',
    BROKER = '8',
    INDICATION = '9',
    SERVICE_PROVIDER = '10',
    AGENCY = '11',
    REPRESENTATIVE = '12',
    DESTINATION_REPRESENTATIVE = '13',
    INSURER = '14'
}
export const enum FollowupReferenceType {
    TASK = '1',
    EVENT = '2',
    STOPOVER = '3',
    DEADLINE = '4',
    NEW_TASK = '5'
}
export const enum FollowupReferenceDate {
    ESTIMATED = '1',
    EFFECTIVE = '2'
}
export const enum FollowupReferenceStopover {
    ARRIVAL = '1',
    BERTHING = '2',
    UNBERTHING = '3',
    DEPARTURE = '4',
    OPERATION = '5'
}

export const enum FollowupReferenceDeadline {
    MASTER_DRAFT = '1',
    MASTER_DRAFT_IMO = '2',
    MASTER_VGM = '3',
    MASTER_RELEASE = '4',
    HOUSE_DRAFT = '5',
    HOUSE_DRAFT_IMO = '6',
    HOUSE_VGM = '7',
    HOUSE_RELEASE = '8',
}

export const enum TaskStatus {
    IN_PROGRESS = '1',
    PAUSED = '2',
    DONE = '3',
    CANCELED = '4',
}
import { HandleError } from "../../common/util/HandleError";
import * as uiGrid from "ui-grid";
import * as angular from "angular";
import { ProcessDocument } from "../../common/model/ProcessDocument";
import { SelectorModel } from "../../common/model/SelectorModel";
import { GenericConstants } from "@models/interface/common/GenericConstants";


uiSelectMasterDocuments.$inject = ['$document', 'uiGridEditConstants', 'uiGridConstants', 'RestService', 'blockUI'];
export function uiSelectMasterDocuments($document: JQuery, uiGridEditConstants: uiGrid.edit.IUiGridEditConstants, uiGridConstants: uiGrid.IUiGridConstants, RestService, blockUI) {
    return {
        require: '^uiGrid',
        link: ($scope, $elm, $attr, uiGridCtrl) => {

            $scope.search = search;

            $document.on('click', endCellEdit);
            $document.on('keyup', keyUp);

            const grid: uiGrid.IGridApi = uiGridCtrl.grid.api;
            grid.edit.on.beginCellEdit($scope, beginCellEdit);

            //AUXS
            function beginCellEdit(rowEntity: any, colDef: uiGrid.IColumnDefOf<any>, triggerEvent: JQueryEventObject): void {
                const totalOpened = $('ui-select-external-contacts').length;

                if (totalOpened > 1) cancelCellEdit(triggerEvent);
            }

            function cancelCellEdit(evt: JQueryEventObject) {
                $scope.$emit(uiGridEditConstants.events.CANCEL_CELL_EDIT);
            }

            function endCellEdit(evt: JQueryEventObject) {
                const hasChanged = $(evt.target).closest('.ui-select-container').length === 0;
                if (hasChanged) $scope.$emit(uiGridEditConstants.events.END_CELL_EDIT);
            }

            function keyUp(evt: JQueryEventObject): void {
                if (evt.keyCode == uiGridConstants.keymap.ESC) cancelCellEdit(evt);
            }

            async function search(query: string, processDocument: ProcessDocument): Promise<void> {
                try {
                    
                    const options: uiGrid.IGridOptions = uiGridCtrl.grid.options;
                    let result: Array<SelectorModel> = [];

                    const hasData: boolean = options.data && angular.isArray(options.data);
                    if (hasData) {
                        const data: Array<ProcessDocument> = options.data as any;
                        const filtered: Array<ProcessDocument> = data.filter(prd => prd.PROCESS_NUMBER == processDocument.PROCESS_NUMBER && prd.TYPE == GenericConstants.DEADLINE_MASTER);

                        if (filtered == null || filtered.length == 0) {
                            $scope.$emit(uiGridEditConstants.events.END_CELL_EDIT);
                            return;
                        }

                        result = filtered.map(item => {
                            const content: SelectorModel = {
                                ID: item.ID,
                                NAME: item.DOCUMENT,
                            }
                            return content
                        }).filter(resultItem => resultItem.NAME);
                    }

                    $scope.list = result;
                    $scope.$applyAsync(() => { blockUI.stop() });

                } catch (ex) {
                    HandleError.exception(ex);
                }

            };

        }
    };
}
'use strict';

import config from '../bootstrap/Config';
import * as angular from 'angular';
import { HandleError } from '../common/util/HandleError';
import { NotificationConstants } from '../common/util/NotificationConstants';
import { ISessionService } from '@services/SessionService';
import { IModalService } from './ModalService';
import { IRestService } from './RestService';
import { INotificationService } from './NotificationService';
import { BrowserTitle } from '../common/BrowserTitle';
import { EOperation } from '@enums/GenericData';
import * as moment from 'moment';
import { SelectorModel } from "../common/model/SelectorModel";

FormService.$inject = [
    '$rootScope',
    '$timeout',
    '$state',
    'blockUI',
    'SessionService',
    'ModalService',
    'RestService',
    '$anchorScroll',
    'NotificationService',
    '$q',
    'HelperService'
];

export function FormService($rootScope, $timeout, $state, blockUI: ng.blockUI.BlockUIService, SessionService: ISessionService, ModalService: IModalService, RestService: IRestService, $anchorScroll, NotificationService: INotificationService, $q: ng.IQService, HelperService) {
    let $scope = undefined;
    let formName = undefined;
    let modelName = undefined;
    let displayName = undefined;
    let cancelCallback = undefined;
    let id = undefined;

    const elementType = [
        'SELECTOR',
        'MULTIPLE_SELECTOR',
        'DATEPICKER',
        'INPUT_NUMBER'
    ]
    const emptySelectorMsg = NotificationConstants.EMPTY_SELECTOR_MSG;


    this.YES_SELECTOR = {
        ID: '1',
        NAME: 'Sim',
        VALUE: true,
    };

    this.NO_SELECTOR = {
        ID: '2',
        NAME: 'Não',
        VALUE: false,
    };

    const yesNoSelector = [this.YES_SELECTOR, this.NO_SELECTOR];

    const ActiveBlockedSelector = [{
        ID: '1',
        NAME: 'ATIVO',
        VALUE: true,
    },
    {
        ID: '2',
        NAME: 'BLOQUEADO',
        VALUE: false,
    }
    ];
    const products = [{
        ID: '1',
        NAME: 'Exportação Aérea',
        CODE: 'EA',
    },
    {
        ID: '2',
        NAME: 'Exportação Marítima',
        CODE: 'EM',
    },
    {
        ID: '3',
        NAME: 'Exportação Rodoviária',
        CODE: 'ER',
    },
    {
        ID: '4',
        NAME: 'Importação Aérea',
        CODE: 'IA',
    },
    {
        ID: '5',
        NAME: 'Importação Marítima',
        CODE: 'IM',
    },
    {
        ID: '6',
        NAME: 'Importação Rodoviária',
        CODE: 'IR',
    }
    ];
    let operations = [];
    let modals = [];
    let forwardedByOptions = [];
    let serviceTypeOptions = [];
    let cargoTypeOptions = [];

    async function getRegisteredList(type): Promise<SelectorModel[]> {
        const { data: generic } = await HelperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    async function initFixedData(): Promise<void> {
        try {
            const result: Array<any> = await $q.all([getRegisteredList('oper'),
            getRegisteredList('modal'),
            getRegisteredList('forwarded_by'),
            getRegisteredList('type_service'),
            getRegisteredList('type_cargo')
            ]);
            operations = result[0];
            modals = result[1];
            forwardedByOptions = result[2];
            serviceTypeOptions = result[3];
            cargoTypeOptions = result[4];

        } catch (ex) {
            throw ex;
        }
    }


    this.init = function (_$scope, _formName, _modelName, _displayName, _cancelCallback?) {
        $scope = _$scope;
        formName = _formName;
        modelName = _modelName;
        displayName = _displayName;
        cancelCallback = _cancelCallback;
        // GAMBI - DAVID - 2802
        if (modelName == 'requirements')
            id = 'REQUIREMENT_NUMBER';
        else
            id = modelName.toUpperCase() + '_NUMBER';

        //init uib-tab
        $scope.steps = {
            percent: 0,
            current: 0
        };

        //set date format options
        $scope.dateFormat = 'dd/MM/yyyy';
        $scope.dateTimeFormat = 'dd/MM/yyyy HH:mm:ss';
        $scope.dateOptions = {
            formatYear: 'yy',
            maxDate: new Date(2030, 1, 1),
            minDate: new Date(1970, 1, 1),
            startingDay: 1
        };

        //register form service functions on $scope
        const self = this;
        Object.keys(this).forEach(function (key) {
            if (key === 'init' || key[0] === '$') return;
            $scope[key] = self[key];
        });

        // all initializations in a one place
        //this.initFormUtil();
        this.initFixedData();
    };

    this.initStandAlone = function (_$scope, _formName) {
        $scope = _$scope;
        formName = _formName;

        //init uib-tab
        $scope.steps = {
            percent: 0,
            current: 0
        };

        //set date format options
        $scope.dateFormat = 'dd/MM/yyyy';
        $scope.dateTimeFormat = 'dd/MM/yyyy HH:mm:ss';
        $scope.dateOptions = {
            formatYear: 'yy',
            maxDate: new Date(2030, 1, 1),
            minDate: new Date(1970, 1, 1),
            startingDay: 1
        };

        //register form service functions on $scope
        const self = this;
        Object.keys(this).forEach(function (key) {
            if (key === 'init' || key[0] === '$') return;
            $scope[key] = self[key];
        });
    };

    /* DEPRACATED - Removed by David 06/09 */
    this.initFormUtil = function () {
        const self = this;
        $timeout(function () {
            // gotop page
            $anchorScroll();
            angular.element(document).ready(function () {
                $(window).scroll(function () {
                    if ($(this).scrollTop() > 50) {
                        $('#back-to-top').fadeIn();
                    } else {
                        $('#back-to-top').fadeOut();
                    }
                });
            });
            //unblock the init block
            self.unblock();
        });
    }


    this.initMichaelMenu = function () {
        /*         // register window resize event
                const self = this;
                $(window).resize(function () {
                    self.resizeMichaelMenu();
                });
                //init the first time
                this.resizeMichaelMenu(); */
    };

    this.resizeMichaelMenu = function () {
        $timeout(function () {
            let differenceLength = 0;
            // check actual size
            if (!$rootScope.app.settings.asideFolded) {
                // div align
                differenceLength = 100;
            }
            $('#nav').animate({
                'left': ((($(document).width() - $('#nav').width()) / 2) + differenceLength) + "px"
            }, {
                duration: 500,
                queue: false
            });
        }, 500);
    };

    this.notifyError = function (msg: string, callback?: Function, options?: any): void {
        NotificationService.error(msg, callback, options);
    }

    this.notifySuccess = function (msg: string, callback?: Function, options?: any): void {
        NotificationService.success(msg, callback, options);
    }

    this.notifyInfo = function (msg: string, callback?: Function, options?: any): void {
        NotificationService.info(msg, callback, options);
    }

    this.notifyWarning = function (msg: string, callback?: Function, options?: any): void {
        NotificationService.warning(msg, callback, options);
    }

    this.notifyElementError = function (element: string, msg: string, callback?: Function, options?: any): void {
        NotificationService.element(element).error(msg, callback, options);
    }

    this.notifyElementSuccess = function (element: string, msg: string, callback?: Function, options?: any): void {
        NotificationService.element(element).success(msg, callback, options);
    }

    this.notifyElementInfo = function (element: string, msg: string, callback?: Function, options?: any): void {
        NotificationService.element(element).info(msg, callback, options);
    }

    this.notifyElementWarning = function (element: string, msg: string, callback?: Function, options?: any): void {
        NotificationService.element(element).warning(msg, callback, options);
    }


    this.apply = function () {
        $timeout(function () {
            $scope.$apply();
        });
    }

    this.modals = function () {
        return modals;
    };

    this.operations = function () {
        return operations;
    };

    this.products = function () {
        return products;
    };

    this.forwardedByOptions = function () {
        return forwardedByOptions;
    };

    this.serviceTypeOptions = function () {
        return serviceTypeOptions;
    };

    this.cargoTypeOptions = function () {
        return cargoTypeOptions;
    };

    this.initFixedData = function () {
        return initFixedData();
    };

    this.getProductByCode = function (code) {
        if (!code) return

        for (let i = 0; i < products.length; i++) {
            if (products[i].CODE == code) return products[i];
        }
        return null;
    };

    this.getCargoTypeByName = function (name) {
        if (!name) return

        let result = name.toUpperCase();
        switch (result) {
            case 'BULK':
                result = 'BREAKBULK';
                break;
            case 'RO_RO':
                result = result.replace('_', ' ');
                break;
            case 'BACK_TO_BACK':
                result = result.replace('_', ' ').replace('_', ' ');
                break;
            case 'PART_CHARTER':
                result = result.replace('_', ' ');
                break;
            case 'CONSOLIDADA':
                result = 'CONSOLIDADO';
                break;
            default:
                result = result.replace('_', '/');
                break;
        }

        //console.log('Cargo Count: ', cargoTypeOptions);

        for (let i = 0; i < cargoTypeOptions.length; i++) {
            //console.log('Found Cargo Type: ', cargoTypeOptions[i]);
            if (cargoTypeOptions[i].NAME.toUpperCase() === result) {
                return cargoTypeOptions[i];
            }
        }

        return null;
    };

    this.formatCargoTypeToHC2 = function (cargoType) {
        // value with validation
        let result = cargoType.toUpperCase();

        switch (result) {
            case 'BREAKBULK':
                result = 'BULK';
                break;
            case 'Ro-ro':
                result = result.replace('-', '_');
                break;
            case 'BACK TO BACK':
                result = result.replace(' ', '_').replace(' ', '_');
                break;
            case 'PART CHARTER':
                result = result.replace(' ', '_');
                break;
            case 'CONSOLIDADO':
                result = 'CONSOLIDADA';
                break;
            default:
                result = result.replace('/', '_');
                break;
        }
        return result;
    };

    this.goTo = function (route, reload) {
        if (!route) return;
        $state.go(route, {}, {
            reload: reload
        });
    };

    this.goToquotations = function () {
        this.goTo('app.crm.quotation.list', false);
    };

    this.getEmptySelectorMsg = function () {
        return emptySelectorMsg;
    };

    this.getYesNoSelector = function () {
        return yesNoSelector;
    };

    this.getActiveBlockedSelector = function () {
        return ActiveBlockedSelector;
    };

    this.next = function () {
        $scope.steps.current = $scope.steps.current + 1;
    }

    this.back = function () {
        $scope.steps.current = $scope.steps.current - 1;
    }

    this.setComboBoxValidity = function (element, index) {
        if (!element) return;
        let elementName = element;
        if (index !== undefined && index !== null && index >= 0) {
            elementName = (element + index);
        }
        $timeout(function () {
            if ($scope[formName][elementName].$valid) $scope[formName][elementName].$setValidity('$valid', true);
            else $scope[formName][elementName].$setValidity('$valid', false);
        });
    };

    this.selectorFocus = function (elementName) {
        // input?
        if (!(elementName instanceof HTMLInputElement) && !(elementName.type == 'text')) return;

        const self = this;
        $timeout(function () {
            // set focus on specified element
            if (elementName) {
                elementName.focus()

            };
        });
    };

    this.selectorValidity = function (elementName) {
        if (!elementName || !$scope[formName] || !$scope[formName][elementName]) return;

        const self = this;
        $timeout(function () {
            //double check after digest, scope may be refresh/destroyed
            if (!$scope[formName] || !$scope[formName][elementName]) return;

            //check if element is a single selector type
            const isSelector = (angular.isDefined($scope[formName][elementName].$$attr.class) && $scope[formName][elementName].$$attr.class.startsWith('ui-select-container'));
            const isSelectorMultiple = angular.isDefined($scope[formName][elementName].$$attr.multiple);
            if (!isSelector) return;
            let model = undefined;
            let index = -1;
            const numberMask = elementName.match(/\d/g);
            //index = parseInt(elementName.charAt(elementName.length - 1));
            index = (numberMask) ? parseInt(numberMask.join("")) : parseInt(elementName);
            if (isNaN(index)) model = $scope[formName][elementName].$$attr.ngModel;
            else model = $scope[formName][elementName].$$attr.ngModel.replace('[$index]', '');
            const modelValue = $scope[formName][elementName].$modelValue;

            /* console.log('name: ' + elementName + ' index: ' + index + ' model: ' + model);
             console.log('model: ', model);
             console.log('element: ', $scope[formName][elementName]);
             console.log('modelValue: ', modelValue);
             console.log('typeof modelValue: ', typeof (modelValue));*/

            //TODO: IMPROVE FORMAT FOR MORE DATA THAN JUST ID, NAME

            if (modelValue && typeof (modelValue) === 'object') {
                //return if is selector multiple and it is empty
                if (modelValue instanceof Array && modelValue.length === 0) {
                    //empty multiple selector, set model null
                    return self.formatSelector(model, null, true, index);
                }

                //format selector data if different of {ID, NAME}
                if (isSelectorMultiple && (!modelValue[modelValue.length - 1].ID || !modelValue[modelValue.length - 1].NAME)) {
                    let keys = [];
                    //get the properties of the the last added item
                    Object.keys(modelValue[modelValue.length - 1]).forEach(function (key) {
                        //if (key === 'ID' || key === 'NAME') return;
                        keys.push(key);
                    });
                    //make sure we have at least 2 attributes to format and set ID, NAME
                    if (keys.length >= 2) self.formatSelector(model, keys, true, index);

                } else if (!isSelectorMultiple && (!modelValue.ID || !modelValue.NAME)) {
                    let keys = [];
                    Object.keys(modelValue).forEach(function (key) {
                        //if (key === 'ID' || key === 'NAME') return;
                        keys.push(key);
                    });
                    //make sure we have at least 2 attributes to format and set ID, NAME
                    if (keys.length >= 2) self.formatSelector(model, keys, false, index);
                }
            }
            //set css validity just for single selector
            if (!isSelectorMultiple) {
                const elementMatch = $("[name='" + elementName + "']").find('div.ui-select-match');
                if ($scope[formName][elementName].$valid) {
                    // span tag to set class validity
                    elementMatch.children('span')
                        .removeClass("btn")
                        .removeClass("btn-default")
                        .removeClass("ng-invalid")
                        .addClass('ng-valid ng-dirty');

                } else {
                    elementMatch.children('span')
                        .removeClass("btn")
                        .removeClass("btn-default").removeClass("ng-valid")
                        .addClass('ng-invalid ng-dirty');
                }
            }
        });
    };

    this.numberValidity = function (elementName, _index, _index2) {
        if (!elementName) return;

        const self = this;
        $timeout(function () {
            let model = undefined;
            let index = parseInt(_index);
            let index2 = parseInt(_index2);
            if (isNaN(index) && isNaN(index2)) model = $scope[formName][elementName].$$attr.ngModel;
            else {
                model = $scope[formName][elementName].$$attr.ngModel.replace('[$parent.$index]', '').replace('[$parent.$index]', '').replace('[$index]', '').replace('[$index]', '');
                //check for extra indexes
                let extraIndex = model.split('[')[1];
                if (extraIndex && extraIndex.length > 0) {
                    extraIndex = extraIndex.split(']')[0];
                    if (self.validatePositiveNumber(extraIndex)) {
                        model = model.replace('[' + extraIndex + ']', '');
                        if (!self.validatePositiveNumber(index)) index = extraIndex;
                        if (!self.validatePositiveNumber(index2)) index2 = extraIndex;
                    }
                }
            }
            const modelValue = $scope[formName][elementName].$modelValue;
            const min = parseInt($scope[formName][elementName].$$attr.min);
            const max = parseInt($scope[formName][elementName].$$attr.max);
            self.validateNumberModel(model, modelValue, min, max, index, index2);
        });
    };

    this.selectorDisabled = function (name) {
        if (!$scope[name]) $scope[name] = false;
        return $scope[name];
    };

    this.disableElements = function (_disable?: boolean, _exceptNames?: string[], _regExp?: RegExp) {
        $timeout(function () {
            const disable = (typeof _disable !== 'undefined') ? _disable : true;
            const exceptNames = (_exceptNames && _exceptNames.length > 0) ? _exceptNames : [];
            const regex = (_regExp) ? _regExp : new RegExp('.*');

            angular.forEach($scope[formName], function (element, name: string) {
                if ((name[0] !== '$') &&
                    (exceptNames.indexOf(name) == -1) &&
                    (regex.test(name)) &&
                    (angular.isDefined(element.$$attr.$attr.multiple) ||
                        element.$$attr.class.startsWith('ui-select-container') ||
                        element.$$attr.type === "text" ||
                        element.$$attr.$$element[0].type === "textarea")
                ) {
                    $scope[name] = disable;

                    if (element.$$attr.type === "text")
                        angular.element('input[name=' + name + ']').prop("disabled", true);

                    if (element.$$attr.$$element[0].type === "textarea")
                        angular.element('textarea[name=' + name + ']').prop("disabled", true);
                }
            });
        });
    };


    this.setSelectorsPlaceholder = function (msg) {
        $('.ui-select-search').prop('placeholder', msg);
    };

    this.validatePositiveNumber = function (number) {
        if (number != undefined && number != null && !isNaN(number) && number >= 0) return true;
        else return false;
    };


    this.validateNumberModel = function (model, modelValue, min, max, index, index2) {
        if (!model || !this.validatePositiveNumber(min) || !this.validatePositiveNumber(max)) return;

        if (!modelValue || modelValue < min || modelValue > max) {
            let newValue = undefined;
            if (modelValue > max) newValue = max;
            else if (modelValue < min || !modelValue) newValue = min;

            let modelStructure = model.split('.');
            switch (modelStructure.length) {
                case 0:
                    if (this.validatePositiveNumber(index)) $scope[model][index] = newValue;
                    else $scope[model] = newValue;
                    break;
                case 1:
                    if (this.validatePositiveNumber(index)) $scope[modelStructure[0]][index] = newValue;
                    else $scope[modelStructure[0]] = newValue;
                    break;
                case 2:
                    if (this.validatePositiveNumber(index) && this.validatePositiveNumber(index2))
                        $scope[modelStructure[0]][index][modelStructure[1]][index2] = newValue;
                    else if (this.validatePositiveNumber(index) && !this.validatePositiveNumber(index2))
                        $scope[modelStructure[0]][index][modelStructure[1]] = newValue;
                    else if (!this.validatePositiveNumber(index) && this.validatePositiveNumber(index2))
                        $scope[modelStructure[0]][modelStructure[1]][index2] = newValue;
                    else
                        $scope[modelStructure[0]][modelStructure[1]] = newValue;
                    break;
                case 3:
                    if (this.validatePositiveNumber(index) && this.validatePositiveNumber(index2))
                        $scope[modelStructure[0]][index][modelStructure[1]][index2][modelStructure[2]] = newValue;
                    else if (this.validatePositiveNumber(index) && !this.validatePositiveNumber(index2))
                        $scope[modelStructure[0]][index][modelStructure[1]][modelStructure[2]] = newValue;
                    else if (!this.validatePositiveNumber(index) && this.validatePositiveNumber(index2))
                        $scope[modelStructure[0]][modelStructure[1]][index2][modelStructure[2]] = newValue;
                    else
                        $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]] = newValue;
                    break;
                case 4:
                    if (this.validatePositiveNumber(index) && this.validatePositiveNumber(index2))
                        $scope[modelStructure[0]][index][modelStructure[1]][index2][modelStructure[2]][modelStructure[3]] = newValue;
                    else if (this.validatePositiveNumber(index) && !this.validatePositiveNumber(index2))
                        $scope[modelStructure[0]][index][modelStructure[1]][modelStructure[2]][modelStructure[3]] = newValue;
                    else if (!this.validatePositiveNumber(index) && this.validatePositiveNumber(index2))
                        $scope[modelStructure[0]][modelStructure[1]][index2][modelStructure[2]][modelStructure[3]] = newValue;
                    else
                        $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][modelStructure[3]] = newValue;
                    break;
                default:
                    console.log('Profundidade máxima de formatação do model é de 4 níveis');
                    break;
            }
        }
    };


    this.formatSelector = function (model, keys, isMultiple, index) {
        if (!model) return;

        let modelStructure = model.split('.');
        //console.log('modelStructure: ', modelStructure);
        switch (modelStructure.length) {
            case 0:
                if (this.validatePositiveNumber(index)) {
                    if ($scope[model][index]) {
                        if (isMultiple) {
                            const lastIndex = $scope[model][index].length - 1;
                            if (lastIndex === -1) {
                                //multiple selector is empty, set model null
                                $scope[model][index] = null;
                                return;
                            }
                            const id = $scope[model][index][lastIndex][keys[0]];
                            $scope[model][index][lastIndex]['ID'] = id.toString();
                            $scope[model][index][lastIndex]['NAME'] = $scope[model][index][lastIndex][keys[1]].trim();
                        } else {
                            const id = $scope[model][index][keys[0]];
                            $scope[model][index]['ID'] = id.toString();
                            $scope[model][index]['NAME'] = $scope[model][index][keys[1]].trim();
                        }
                    }
                } else { //no index (not a table or array of array)
                    if ($scope[model]) {
                        if (isMultiple) {
                            const lastIndex = $scope[model].length - 1;
                            if (lastIndex === -1) {
                                //multiple selector is empty, set model null
                                $scope[model] = null;
                                return;
                            }
                            const id = $scope[model][lastIndex][keys[0]];
                            $scope[model][lastIndex]['ID'] = id.toString();
                            $scope[model][lastIndex]['NAME'] = $scope[model][lastIndex][keys[1]].trim();
                        } else {
                            const id = $scope[model][keys[0]];
                            $scope[model]['ID'] = id.toString();
                            $scope[model]['NAME'] = $scope[model][keys[1]].trim();
                        }
                    }
                }
                break;
            case 1:
                if (this.validatePositiveNumber(index)) {
                    if ($scope[modelStructure[0]][index]) {
                        if (isMultiple) {
                            const lastIndex = $scope[modelStructure[0]][index].length - 1;
                            if (lastIndex === -1) {
                                //multiple selector is empty, set model null
                                $scope[modelStructure[0]][index] = null;
                                return;
                            }
                            const id = $scope[modelStructure[0]][index][lastIndex][keys[0]];
                            $scope[modelStructure[0]][index][lastIndex]['ID'] = id.toString();
                            $scope[modelStructure[0]][index][lastIndex]['NAME'] = $scope[modelStructure[0]][index][lastIndex][keys[1]].trim();
                        } else {
                            const id = $scope[modelStructure[0]][index][keys[0]];
                            $scope[modelStructure[0]][index]['ID'] = id.toString();
                            $scope[modelStructure[0]][index]['NAME'] = $scope[modelStructure[0]][index][keys[1]].trim();
                        }
                    }
                } else { //no index (not a table or array of array)
                    if ($scope[modelStructure[0]]) {
                        if (isMultiple) {
                            const lastIndex = $scope[modelStructure[0]].length - 1;
                            if (lastIndex === -1) {
                                //multiple selector is empty, set model null
                                $scope[modelStructure[0]] = null;
                                return;
                            }
                            const id = $scope[modelStructure[0]][lastIndex][keys[0]];
                            $scope[modelStructure[0]][lastIndex]['ID'] = id.toString();
                            $scope[modelStructure[0]][lastIndex]['NAME'] = $scope[modelStructure[0]][lastIndex][keys[1]].trim();
                        } else {
                            const id = $scope[modelStructure[0]][keys[0]];
                            $scope[modelStructure[0]]['ID'] = id.toString();
                            $scope[modelStructure[0]]['NAME'] = $scope[modelStructure[0]][keys[1]].trim();
                        }
                    }
                }
                break;
            case 2:
                if (this.validatePositiveNumber(index)) {
                    //console.log("CHECKING: ", $scope[modelStructure[0]]);
                    if ($scope[modelStructure[0]][index][modelStructure[1]]) {
                        if (isMultiple) {
                            const lastIndex = $scope[modelStructure[0]][index][modelStructure[1]].length - 1;
                            if (lastIndex === -1) {
                                //multiple selector is empty, set model null
                                $scope[modelStructure[0]][index][modelStructure[1]] = null;
                                return;
                            }
                            const id = $scope[modelStructure[0]][index][modelStructure[1]][lastIndex][keys[0]];
                            $scope[modelStructure[0]][index][modelStructure[1]][lastIndex]['ID'] = id.toString();
                            $scope[modelStructure[0]][index][modelStructure[1]][lastIndex]['NAME'] = $scope[modelStructure[0]][index][modelStructure[1]][lastIndex][keys[1]].trim();
                        } else {
                            const id = $scope[modelStructure[0]][index][modelStructure[1]][keys[0]];
                            $scope[modelStructure[0]][index][modelStructure[1]]['ID'] = id.toString();
                            $scope[modelStructure[0]][index][modelStructure[1]]['NAME'] = $scope[modelStructure[0]][index][modelStructure[1]][keys[1]].trim();
                        }
                    }
                } else { //no index (not a table or array of array)
                    if ($scope[modelStructure[0]][modelStructure[1]]) {
                        if (isMultiple) {
                            const lastIndex = $scope[modelStructure[0]][modelStructure[1]].length - 1;
                            if (lastIndex === -1) {
                                //multiple selector is empty, set model null
                                $scope[modelStructure[0]][modelStructure[1]] = null;
                                return;
                            }
                            const id = $scope[modelStructure[0]][modelStructure[1]][lastIndex][keys[0]];
                            $scope[modelStructure[0]][modelStructure[1]][lastIndex]['ID'] = id.toString();
                            $scope[modelStructure[0]][modelStructure[1]][lastIndex]['NAME'] = $scope[modelStructure[0]][modelStructure[1]][lastIndex][keys[1]].trim();
                        } else {
                            const id = $scope[modelStructure[0]][modelStructure[1]][keys[0]];
                            $scope[modelStructure[0]][modelStructure[1]]['ID'] = id.toString();
                            $scope[modelStructure[0]][modelStructure[1]]['NAME'] = $scope[modelStructure[0]][modelStructure[1]][keys[1]].trim();
                        }
                    }
                }
                break;
            case 3:
                if (this.validatePositiveNumber(index)) {
                    if ($scope[modelStructure[0]][modelStructure[1]][index][modelStructure[2]]) {
                        if (isMultiple) {
                            const lastIndex = $scope[modelStructure[0]][modelStructure[1]][index][modelStructure[2]].length - 1;
                            if (lastIndex === -1) {
                                //multiple selector is empty, set model null
                                $scope[modelStructure[0]][modelStructure[1]][index][modelStructure[2]] = null;
                                return;
                            }
                            const id = $scope[modelStructure[0]][modelStructure[1]][index][modelStructure[2]][lastIndex][keys[0]];
                            $scope[modelStructure[0]][modelStructure[1]][index][modelStructure[2]][lastIndex]['ID'] = id.toString();
                            $scope[modelStructure[0]][modelStructure[1]][index][modelStructure[2]][lastIndex]['NAME'] = $scope[modelStructure[0]][modelStructure[1]][index][modelStructure[2]][lastIndex][keys[1]].trim();
                        } else {
                            const id = $scope[modelStructure[0]][modelStructure[1]][index][modelStructure[2]][keys[0]];
                            $scope[modelStructure[0]][modelStructure[1]][index][modelStructure[2]]['ID'] = id.toString();
                            $scope[modelStructure[0]][modelStructure[1]][index][modelStructure[2]]['NAME'] = $scope[modelStructure[0]][modelStructure[1]][index][modelStructure[2]][keys[1]].trim();
                        }
                    }
                } else { //no index (not a table or array of array)
                    if ($scope[modelStructure[0]][modelStructure[1]][modelStructure[2]]) {
                        if (isMultiple) {
                            const lastIndex = $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]].length - 1;
                            if (lastIndex === -1) {
                                //multiple selector is empty, set model null
                                $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]] = null;
                                return;
                            }
                            const id = $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][lastIndex][keys[0]];
                            $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][lastIndex]['ID'] = id.toString();
                            $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][lastIndex]['NAME'] = $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][lastIndex][keys[1]].trim();
                        } else {
                            const id = $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][keys[0]];
                            $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]]['ID'] = id.toString();
                            $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]]['NAME'] = $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][keys[1]].trim();
                        }
                    }
                }
                break;
            case 4:
                if (this.validatePositiveNumber(index)) {
                    if ($scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][index][modelStructure[3]]) {
                        if (isMultiple) {
                            const lastIndex = $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][index][modelStructure[3]].length - 1;
                            if (lastIndex === -1) {
                                //multiple selector is empty, set model null
                                $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][index][modelStructure[3]] = null;
                                return;
                            }
                            const id = $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][index][modelStructure[3]][lastIndex][keys[0]];
                            $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][index][modelStructure[3]][lastIndex]['ID'] = id.toString();
                            $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][index][modelStructure[3]][lastIndex]['NAME'] = $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][index][modelStructure[3]][lastIndex][keys[1]].trim();
                        } else {
                            const id = $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][index][modelStructure[3]][keys[0]];
                            $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][index][modelStructure[3]]['ID'] = id.toString();
                            $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][index][modelStructure[3]]['NAME'] = $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][index][modelStructure[3]][keys[1]].trim();
                        }
                    }
                } else { //no index (not a table or array of array)
                    if ($scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][modelStructure[3]]) {
                        if (isMultiple) {
                            const lastIndex = $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][modelStructure[3]].length - 1;
                            if (lastIndex === -1) {
                                //multiple selector is empty, set model null
                                $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][modelStructure[3]] = null;
                                return;
                            }
                            const id = $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][lastIndex][keys[0]];
                            $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][modelStructure[3]][lastIndex]['ID'] = id.toString();
                            $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][modelStructure[3]][lastIndex]['NAME'] = $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][lastIndex][keys[1]].trim();
                        } else {
                            const id = $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][modelStructure[3]][keys[0]];
                            $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][modelStructure[3]]['ID'] = id.toString();
                            $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][modelStructure[3]]['NAME'] = $scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][modelStructure[3]][keys[1]].trim();
                        }
                    }
                }
                break;
            default:
                console.log('Profundidade máxima de formatação do objeto do selector é de 4 níveis');
                break;
        }
    };


    this.validateForm = function () {
        const self = this;
        $timeout(function () {
            angular.forEach($scope[formName], function (element, name) {
                //if (name[0] !== '$' && (element.$$success.required || element.$error.required) && element.$invalid) {
                if (name[0] !== '$' && (element.$$success.required || element.$error.required)) {
                    const isSelector = (angular.isDefined(element.$$attr.class) && element.$$attr.class.startsWith('ui-select-container'));
                    //const isSelectorMultiple = angular.isDefined(element.$$attr.multiple);
                    //validate selectors
                    if (isSelector) self.selectorValidity(name);
                    //validate other elements
                    element.$setDirty();
                }
            });
        });
    };


    this.resetElement = function (elementName) {
        if (!elementName) return;

        $timeout(function () {
            $scope[formName][elementName].$setPristine();
        });
    };


    this.resetForm = function () {
        $timeout(function () {
            angular.forEach($scope[formName], function (element, name) {
                if (name[0] !== '$') {
                    if (element.$$attr.class.startsWith('ui-select-container')) {
                        let choicesName = null;
                        //reset single selectors classes
                        if (!angular.isDefined(element.$$attr.multiple)) {
                            $("[name='" + name + "']").find('div.ui-select-match').children('span').removeClass("ng-valid").removeClass("ng-invalid");
                        } else {
                            choicesName = $("[name='" + name + "'] ul.ui-select-choices")[0].attributes['repeat'].nodeValue.split("in ")[1].split(" |")[0];
                            if (choicesName != 'results') $scope[choicesName] = [];
                        }
                        //re-enable selectorsremo
                        if (choicesName != 'results') $scope[name] = false;
                    }
                    element.$setPristine();
                }
            });
        });
    };

    this.getPendingFields = function (_formName?: string) {

        const form = (_formName) ? _formName : formName;

        let pendingList = [];

        angular.forEach($scope[form], function (element: any, name: string) {
            if (name == 'action.ROLE') {
                console.log('Action: ', element);
            }
            if (name[0] !== '$' && (element.$$success.required || element.$error.required || element.$error.uiSelectRequired) && element.$invalid) {
                pendingList.push(name);

                if (config.environment === 'dev') console.log('CAMPO PENDENTE: ', name);
            }

        });

        return pendingList;
    };

    /*     this.setFormOperation = function () {
            //console.log("Full Scope: ", $scope);
            switch ($scope.operation) {
                case 'register':
                    $scope.formOperation = 'Cadastrar ' + displayName;
                    break;
                case 'edit':
                    $scope.formOperation = 'Editar ' + displayName + ' (' + $scope[modelName][id] + ')';
    
                    if (modelName == "user") $scope.formOperation = 'Editar ' + displayName + ' (' + $scope[modelName].email + ')';
    
                    break;
                case 'view':
                    $scope.formOperation = 'Visualizar ' + displayName + ' (' + $scope[modelName][id] + ')';
    
                    if (modelName == "user") $scope.formOperation = 'Visualizar ' + displayName + ' (' + $scope[modelName].email + ')';
    
                    break;
            }
        }; */

    this.loadViewForm = function () {
        try {
            this.block();

            $scope.operation = EOperation.VIEW;
            $scope.showForm = false;
            $scope.loadError = null;
            $scope.error = null;

            const self = this;
            $timeout(function () {
                angular.forEach($scope[formName], function (element, name) {
                    if (name[0] !== '$') {
                        const isSelector = (angular.isDefined(element.$$attr.class) && element.$$attr.class.startsWith('ui-select-container'));
                        const isSelectorMultiple = angular.isDefined(element.$$attr.multiple);
                        const isRequired = (element.$$success.required || element.$error.required);
                        if (isSelector) {
                            //reset single selectors classes
                            if (!isSelectorMultiple) {
                                $("[name='" + name + "']").find('div.ui-select-match').children('span').removeClass("ng-valid").removeClass("ng-invalid");
                            }


                            //initialize selector choices if necessary
                            const choicesName = $("[name='" + name + "'] ul.ui-select-choices")[0].attributes['repeat'].nodeValue.split("in ")[1].split(" |")[0];

                            if (choicesName != 'results') {
                                if (!$scope[choicesName]) $scope[choicesName] = [];
                                //disable all selectors
                                $scope[name] = true;
                            }
                        }
                        //reset element
                        element.$setPristine();
                    }
                });
                //self.setFormOperation();
                self.navigateBetweenIds('registerBody');
                $scope.showForm = true;
                self.unblock();
            });
        } catch (ex) {
            console.log(ex);
            this.handleError(ex);
        }
    };

    this.loadRegisterForm = function (validateAll) {
        try {
            this.block();

            $scope.operation = 'register';
            $scope.showForm = false;
            $scope.loadError = null;
            $scope.error = null;

            const self = this;
            $timeout(function () {
                angular.forEach($scope[formName], function (element, name) {
                    if (name[0] !== '$') {
                        const isSelector = (angular.isDefined(element.$$attr.class) && element.$$attr.class.startsWith('ui-select-container'));
                        const isSelectorMultiple = angular.isDefined(element.$$attr.multiple);
                        const isRequired = (element.$$success.required || element.$error.required);

                        if (isSelector) {
                            //remove classes of single selector
                            if (!isSelectorMultiple) {
                                $("[name='" + name + "']").find('div.ui-select-match').children('span').removeClass("ng-valid").removeClass("ng-invalid");
                            }
                            //initialize selector choices if necessary
                            const choicesName = $("[name='" + name + "'] ul.ui-select-choices")[0].attributes['repeat'].nodeValue.split("in ")[1].split(" |")[0];

                            if (choicesName != 'results') {
                                if (!$scope[choicesName]) $scope[choicesName] = [];
                                //enable all selectors
                                $scope[name] = false;
                            }

                        }
                        //validity required elements
                        if (isRequired || validateAll) {
                            //validate required selectors
                            if (isSelector) {
                                self.selectorValidity(name);
                            }
                            //validate other elements
                            element.$setDirty();
                        }
                    }
                });
                //self.setFormOperation();
                self.navigateBetweenIds('registerBody');
                $scope.showForm = true;
                self.unblock();
            });
        } catch (ex) {
            console.log(ex);
            this.handleLoadError(ex);
        }
    };

    this.loadEditForm = function () {
        try {
            this.block();

            $scope.operation = 'edit';
            $scope.showForm = false;
            $scope.loadError = null;
            $scope.error = null;

            const self = this;
            $timeout(function () {
                angular.forEach($scope[formName], function (element, name) {
                    if (name[0] !== '$') {
                        const isSelector = (angular.isDefined(element.$$attr.class) && element.$$attr.class.startsWith('ui-select-container'));
                        const isSelectorMultiple = angular.isDefined(element.$$attr.multiple);
                        const isRequired = (element.$$success.required || element.$error.required);

                        if (isSelector) {
                            //remove classes of single selector
                            if (!isSelectorMultiple) {
                                $("[name='" + name + "']").find('div.ui-select-match').children('span').removeClass("ng-valid").removeClass("ng-invalid");
                            }
                            //initialize selector choices if necessary
                            const choicesName = $("[name='" + name + "'] ul.ui-select-choices")[0].attributes['repeat'].nodeValue.split("in ")[1].split(" |")[0];

                            if (choicesName != 'results') {
                                if (!$scope[choicesName]) $scope[choicesName] = [];
                                //validate and format all selectors
                                self.selectorValidity(name);
                                //enable all selectors
                                $scope[name] = false;
                            }
                        }
                        //validate all elements
                        element.$setDirty();
                    }
                });
                //self.setFormOperation();
                self.navigateBetweenIds('registerBody');
                $scope.showForm = true;
                self.unblock();
            });
        } catch (ex) {
            console.log(ex);
            this.handleLoadError(ex);
        }
    };

    this.cancel = function () {
        //default operation
        $scope.operation = 'register';
        if ($scope[modelName]) $scope[modelName] = null;
        $scope.loadError = null;
        $scope.error = null;
        $scope.showForm = false;
        this.resetForm();
        this.navigateBetweenIds('gridBody');

        BrowserTitle.$id = null;

        if (cancelCallback) cancelCallback();
    };

    this.checkPendingFields = function (_formName?: string) {
        this.block();

        let result = undefined;
        const pendingFields = this.getPendingFields();
        if (this.getPendingFields(_formName).length > 0) {
            $scope.error = 'Todos os campos com (*) são obrigatórios e devem ser preenchidos';
            NotificationService.error($scope.error);
            result = false;
        } else result = true;
        this.unblock();

        return result;
    };

    this.block = function () {
        blockUI.start();
    };

    this.resetBlock = function () {
        blockUI.reset();
    };

    this.unblock = function () {
        $timeout(function () {
            blockUI.stop();
            //$scope.$apply();
        });
    };

    this.handleError = function (error, errorCallback?: Function) {
        this.unblock();
        HandleError.exception(error, errorCallback);
    };

    this.handleLoadError = async function (error: any, message?: string) {
        $scope.loadError = true;
        //HandleError.exception(error, function () { $state.reload(); }, { autoHide: false, globalPosition: 'top center' });
        this.resetBlock();
        await SessionService.redirectToError(error, message);
    };

    this.getRequiredFields = function () {
        const self = this;
        let elementList = [];
        let result = new Promise(function (resolve, reject) {
            $timeout(function () {
                angular.forEach($scope[formName], function (element, name) {
                    if (name[0] !== '$' && (element.$$success.required || element.$error.required)) {
                        elementList.push(name);
                    }
                });
            });
        });
        return result;
    };

    this.getOptinalFields = function () {
        const self = this;
        let elementList = [];
        let result = new Promise(function (resolve, reject) {
            $timeout(function () {
                angular.forEach($scope[formName], function (element, name) {
                    if (name[0] !== '$' && (!element.$$success.required || !element.$error.required)) {
                        elementList.push(name);
                    }
                });
            });
        });
        return result;
    };

    this.navigateBetweenIds = function (to) {
        if (!to) return;
        $timeout(function () {
            const element = $("#" + to);
            if (element.length == 0) return;
            const position = $("#" + to).offset().top + $('.app-content-body').scrollTop() - 105;
            $('.app-content-body').animate({
                scrollTop: position
            }, 500);
        });
        return false;
    };

    this.disableDivElementsById = function (id) {
        if (!id) return;
        $("#" + id + " *").attr("disabled", "disabled").off('click');
    };

    this.isDate = function (value) {
        if (!value) return false;
        return (value && typeof value === 'string' && moment(value, "YYYY-MM-DDTHH:mm:ss.SSSZ", true).isValid());
    }

    this.decodeBoolean = function (value) {
        if (typeof (value) !== 'boolean') return;

        if (value) {
            return 'Sim';
        } else {
            return 'Não';
        }
    };

    this.numberFormatter = function (number, digits) {

        if (!digits) {
            digits = 0
        };

        return Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: digits
        }).format(number);
    }

    this.getScopeVariableByName = function (name) {
        if (!$scope[name]) $scope[name] = undefined;
        return $scope[name];
    };

    this.getScopeVariableByNameAsBool = function (name) {
        if (!$scope[name]) $scope[name] = false;
        return $scope[name];
    };

    this.getScopeVariableByIndex = function (name, index) {
        if (!$scope[name + index]) $scope[name + index] = undefined;
        return $scope[name + index];
    };

    this.getScopeVariableByIndexAsBool = function (name, index) {
        if (!$scope[name + index]) $scope[name + index] = false;
        return $scope[name + index];
    };

    this.toggleScopeVariableByIndex = function (name, index) {
        if (!$scope[name + index]) $scope[name + index] = false;
        $scope[name + index] = !$scope[name + index];
        return $scope[name + index];
    };

    this.searchCitiesByName = function (name) {
        return RestService.getObjectAsPromise("/headcargo/cities/" + name, 10000);
    }

    this.searchPeopleBySpecs = function (spec, query?) {
        const route = (query) ? `/operation/peopleBySpec/${spec}/${query}` : `/operation/peopleBySpec/${spec}`;
        return RestService.getObjectAsPromise(route, 10000);
    }

    this.searchPeopleDetails = function (spec, query?) {
        const route = (query) ? `/operation/peopledetail/${spec}/${query}` : `/operation/peopledetail/${spec}`;
        return RestService.getObjectAsPromise(route, 10000);
    }

    this.searchLocalsByName = function (name, type) {
        if (!type)
            type = 'Z';
        else {
            // defined by PRODUCT MODAL
            if (type.charAt(1) == 'M') {
                type = 'P';
            } else if (type.charAt(1) == "A") {
                type = 'A';
            } else {
                type = 'O';
            }
        }
        console.log("MODAL FORM SERVICE: ", type);
        return RestService.getObjectAsPromise(`/headcargo/locals/${type}/${name}`, 10000);
    }

    this.refreshCities = function (query, type, modal) {
        if (query.length == 3) {
            this.block();

            const self = this;
            if (type === 'cities') {
                this.searchCitiesByName(query).then(result => {
                    $scope.cities = result.data;
                    return self.unblock();
                }).catch(ex => {
                    console.log(ex);
                    self.handleError(ex);
                });
            } else if (type === 'locals') { //locals
                this.searchLocalsByName(query, modal).then(result => {
                    $scope.locals = result.data;
                    return self.unblock();
                }).catch(ex => {
                    console.log(ex);
                    self.handleError(ex);
                });
            } else {
                console.log('refreshCities: Invalid type ', type);
            }
        }
    };

    this.selectorSearchBy = function (query, type, variable) {
        if (query.length >= 3) {
            this.block();
            const self = this;
            this.searchPeopleBySpecs(type, query).then(result => {
                $scope[variable] = result.data;
                return self.unblock();
            }).catch(ex => {
                console.log(ex);
                self.handleError(ex);
            });
        };
    };

    this.selectorProcessPeople = function (query, type, variable) {
        if (query.length >= 3) {
            this.block();
            const self = this;
            this.searchPeopleDetails(type, query).then(result => {
                $scope[variable] = result.data;
                return self.unblock();
            }).catch(ex => {
                console.log(ex);
                self.handleError(ex);
            });
        };
    };

    this.handleSequence = function (msg) {
        this.block();

        const self = this;
        this.getSequenceList().then(function (result) {
            if (!result || !result.data) return;

            const sequenceList = result.data;
            let lastRegisteredSequence = 0;
            if (result.data.length > 0) lastRegisteredSequence = sequenceList[sequenceList.length - 1].SEQUENCE;

            console.log('SEQUENCE LIST: ', sequenceList);
            console.log('CURRENT SEQUENCE: ', $scope.sequence);
            console.log('LAST SEQUENCE: ', lastRegisteredSequence);

            if (($scope.sequence - lastRegisteredSequence) > 1) {
                $scope.sequence = lastRegisteredSequence + 1;
                self.unblock();
                return;
            }
            let exists = -1
            for (let i = 0; i < sequenceList.length; i++) {
                if ($scope.sequence === sequenceList[i].SEQUENCE) {
                    exists = i;
                    break;
                }
            }
            self.unblock();

            if (exists !== -1) {
                ModalService.showModalConfirmation({}, {
                    bodyText: msg
                }).then(function () {
                    self.unblock();
                    return;
                }).catch(function () {
                    $scope.sequence = lastRegisteredSequence + 1;
                });
            }
        }).catch(function (ex) {
            console.log(ex);
            self.handleError(ex);
        });
    };


    this.swapArrayElements = function (array, indexA, indexB) {
        let temp = array[indexA];
        array[indexA] = array[indexB];
        array[indexB] = temp;
    };

    this.dynamicSort = function (property) {
        let sortOrder = 1;
        if (property[0] === "-") {
            sortOrder = -1;
            property = property.substr(1);
        }
        return function (a, b) {
            let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
            return result * sortOrder;
        }
    };

    this.getProductInfo = function (product) {
        if (!product) return;

        let returnArray = [];
        const operationExport = 'fa-share';
        const operationImport = 'fa-reply';
        const modalShip = 'fa-ship';
        const modalAir = 'fa-plane';
        const modalRodo = 'fa-truck';

        //set icon
        switch (product.CODE) {
            case 'IM':
                returnArray[0] = modalShip;
                returnArray[1] = operationImport;
                break;
            case 'EM':
                returnArray[0] = modalShip;
                returnArray[1] = operationExport;
                break;
            case 'IA':
                returnArray[0] = modalAir;
                returnArray[1] = operationImport;
                break;
            case 'EA':
                returnArray[0] = modalAir;
                returnArray[1] = operationExport;
                break;
            case 'IR':
                returnArray[0] = modalRodo;
                returnArray[1] = operationImport;
                break;
            case 'ER':
                returnArray[0] = modalRodo;
                returnArray[1] = operationExport;
                break;
            default:
                break;
        }
        returnArray[2] = product.NAME
        return returnArray;
    };

    this.formatGridTimeInput = function (data) {
        if (!data) return;
        const type = data.TYPE.NAME;
        const hours = data.HOURS.toString();
        const minutes = (data.MINUTES.toString().length === 1) ? '0' + data.MINUTES.toString() : data.MINUTES.toString();
        return type + '(' + hours + ':' + minutes + ')';
    };

    // ALL INFORMATION THAT NEED TO BE CONCATENATED
    this.getCONCATInformation = function (originalValue, field?) {
        return this.getCONCAT(originalValue, ';', field);
    }

    this.getCONCAT = function (originalValue, separator, field?) {
        const allData = originalValue;
        let concatData = "";
        if (!allData) return;

        // sometimes will be more than one VALUE
        for (let i = 0; i < allData.length; i++) {
            if (typeof allData[i] === 'object') {
                if (field) {
                    switch (field) {
                        case 'ID': concatData += allData[i].ID + separator + " ";
                            break;
                        case 'CODE': concatData += allData[i].CODE + separator + " ";
                            break;
                        default: concatData += allData[i].NAME + separator + " ";
                            break;
                    }
                }
                else {
                    concatData += allData[i].NAME + separator + " ";
                }
            } else {
                concatData += allData[i] + separator + " ";
            }
        }
        // return concatenated data and remove the last semicolon
        return concatData.substring(0, (concatData.trim().length - 1));
    };

    /*     this.getGridHeight = function () {
            const w = angular.element($window)
            return {
                height: (w.height() - 180) + "px"
            };
        }; */


    this.formateStringToDate = function (date) {
        if (!date) return;
        //format: MM/DD/YYYY
        const dataArray = date.split('/');
        const day = dataArray[0];
        const month = dataArray[1];
        const year = dataArray[2];
        return new Date(month + '/' + day + '/' + year);
    }

    this.dateToString = function (date) {
        if (!date) return;

        const dateArray = date.dateToString().split('T')[0].split('-');
        return dateArray[2] + '/' + dateArray[1] + '/' + dateArray[2];
    }

    this.sleep = function (time) {
        return new Promise((resolve, reject) => {
            try {
                setTimeout(() => resolve(true), time);
            } catch (ex) {
                reject(ex);
            }
        });
    };

    this.booleanDecode = function (boolValue) {
        if (boolValue) {
            return 'Sim';
        } else {
            return 'Não';
        }
    };

    this.dynamicSortMultiple = function () {
        /*
         * save the arguments object as it will be overwritten
         * note that arguments object is an array-like object
         * consisting of the names of the properties to sort by
         */
        var props = arguments;
        return function (obj1, obj2) {
            var i = 0,
                result = 0,
                numberOfProperties = props.length;
            /* try getting a different result from 0 (equal)
             * as long as we have extra properties to compare
             */
            while (result === 0 && i < numberOfProperties) {
                result = this.dynamicSort(props[i])(obj1, obj2);
                i++;
            }
            return result;
        }
    }



}
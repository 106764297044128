import * as moment from 'moment';
import { DEADLINES, Process } from '@models/interface/operational/NewProcess';
import { IVehicleTypeDateFrontEnd, NewProcessEvent } from "@models/interface/operational/NewProcessEvent";
import { CONTAINER_GROUP, ITransportMode } from "@models/interface/operational/NewProcessEvent";
import { ISelectorModel, SelectorModel } from "@models/mongo/SelectorModel";
import { IModalService } from "@services/ModalService";
import { HelperService } from "@services/HelperService";
import { IInttraDeadline, IInttraOceanSchedulesResult } from "@models/interface/external/IInttra";
import { EEventType, EProcessDocumentType } from "@enums/GenericData";
import { ExternalService } from '@services/ExternalService';
import { EProcessEventSituationId } from "@enums/GenericData";

export class ProcessEventHelperController {
    static $inject: string[] = ['$injector', '$scope'];
    private $q: ng.IQService;
    private $filter: ng.FilterFactory;
    private ModalService: IModalService;
    private externalService: ExternalService;
    private eventSituationList: ISelectorModel[] = [];
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>) {
        try {
            this.$q = $injector.get('$q');
            this.$filter = $injector.get('$filter');
            this.ModalService = $injector.get('ModalService');
            this.externalService = $injector.get('ExternalService');
            this.helperService = $injector.get('HelperService');

            const asyncInit =
                setInterval(async (self: ProcessEventHelperController) => {
                    await self.initDependencies();
                    clearInterval(asyncInit);
                }, 100, this);
        } catch (ex) {
            throw ex;
        }
    }

    private async initDependencies(): Promise<void> {
        try {
            const result: Array<any> = await this.$q.all([
                this.getGenericList('process_event_situation'),
            ]);
            if (!result || !result.length) throw new Error(`${this.constructor.name}: Failed to load controller dependencies`);
            this.eventSituationList = result[0];
        } catch (ex) {
            throw ex;
        }
    }

    private async getGenericList(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    public async applyTransportModeToEvent(eventToSet: NewProcessEvent, transportMode: ITransportMode, serviceProviderId: string): Promise<NewProcessEvent> {
        try {
            const compatibleServiceProvider = (transportMode.carriers.find(x => x.carrierInformation.CARRIER.ID == serviceProviderId));
            if (!compatibleServiceProvider) throw 'Nenhum prestador de serviço compatível com processo neste meio de transporte/escala!';

            const transportModeSelector = { ID: transportMode.vessel.ID, NAME: `${transportMode.vessel.NAME}|${compatibleServiceProvider.carrierInformation.VOYAGE}` };
            const serviceProvider = compatibleServiceProvider.carrierInformation.CARRIER;

            eventToSet.TRANSPORT_MODE = transportModeSelector;
            eventToSet.STOPOVER = transportMode.stopover;
            eventToSet.SERVICE_PROVIDER = {
                ID: serviceProvider.ID,
                NAME: serviceProvider.NAME,
                CODE: serviceProvider.CODE,
                ID_LEGAL_PERSON: null,
                TYPE: null
            };
            eventToSet.VIA = transportMode.via.NAME;
            eventToSet.FORECAST_DATE = transportMode.estimatedDate
            eventToSet.EFFECTIVE_DATE = transportMode.effectiveDate;
            //
            const updatedSituation = this.getEventSituation(eventToSet);
            if (updatedSituation) eventToSet.SITUATION = updatedSituation;
            //
            const updatedEvent = await this.updateEventAndContainerDates(eventToSet);
            if (updatedEvent) eventToSet = updatedEvent;

            return eventToSet;

        } catch (ex) {
            throw ex;
        }
    }

    public async applyDefaultTransportModeToEvent(eventToSet: NewProcessEvent, defaultTransportMode: ISelectorModel): Promise<NewProcessEvent> {
        try {
            eventToSet.TRANSPORT_MODE = defaultTransportMode;
            eventToSet.STOPOVER = null;
            //
            const updatedSituation = this.getEventSituation(eventToSet);
            if (updatedSituation) eventToSet.SITUATION = updatedSituation;
            //
            const updatedEvent = await this.updateEventAndContainerDates(eventToSet);
            if (updatedEvent) eventToSet = updatedEvent;

            return eventToSet;
        } catch (ex) {
            throw ex;
        }
    }

    public async updateEventAndContainerDates(eventToSet: NewProcessEvent): Promise<NewProcessEvent> {
        try {
            //update event containers, since we changed the event date
            if (eventToSet.EFFECTIVE_DATE)
                eventToSet = await this.handleEventContainerDateChange(eventToSet, 'EFFECTIVE');
            else
                eventToSet = await this.handleEventContainerDateChange(eventToSet, 'FORECAST');

            if (eventToSet.EFFECTIVE_DATE)
                eventToSet = await this.setEventDateBasedOnContainers(eventToSet, 'EFFECTIVE');
            else
                eventToSet = await this.setEventDateBasedOnContainers(eventToSet, 'FORECAST');

            return eventToSet;

        } catch (ex) {
            throw ex;
        }
    }

    public getEventSituation(eventToSet: NewProcessEvent): ISelectorModel {
        try {
            let situation: ISelectorModel = null;
            if (eventToSet.FORECAST_DATE && eventToSet.EFFECTIVE_DATE) {
                situation = this.eventSituationList.find(x => x.ID === EProcessEventSituationId.EFFECTIVE);
            } else if (eventToSet.FORECAST_DATE && !eventToSet.EFFECTIVE_DATE) {
                situation = this.eventSituationList.find(x => x.ID === EProcessEventSituationId.PROGRAMMED);
            } else if (!eventToSet.FORECAST_DATE && !eventToSet.EFFECTIVE_DATE) {
                situation = this.eventSituationList.find(x => x.ID === EProcessEventSituationId.NOT_PROGRAMMED);
            } else { //it should never happen
                situation = this.eventSituationList.find(x => x.ID === EProcessEventSituationId.NOT_PROGRAMMED);
            }
            return situation;
        } catch (ex) {
            throw ex;
        }
    }

    // a.k.a. updateEventDate
    public async handleEventContainerDateChange(eventToSet: NewProcessEvent, type: string, forcedEventDate?: Date): Promise<NewProcessEvent> {
        try {
            // only if there are containers
            if (!eventToSet.CONTAINER_GROUP || eventToSet.CONTAINER_GROUP.length === 0) return eventToSet;

            let dataModel = 'FORECAST_DATE';
            let dataType = 'PREVISTA';

            switch (type) {
                case 'FORECAST':
                    dataModel = 'FORECAST_DATE';
                    dataType = 'PREVISTA';
                    break;
                case 'EFFECTIVE':
                    dataModel = 'EFFECTIVE_DATE';
                    dataType = 'EFETIVA';
                    break;
            }

            const eventNewDate: Date = (typeof forcedEventDate !== 'undefined') ? forcedEventDate : eventToSet[dataModel];

            const datesList = new Array<Date>();
            let allContainersHasDate = true;

            for (let i = 0; i < eventToSet.CONTAINER_GROUP.length; i++) {
                if (eventToSet.CONTAINER_GROUP[i][dataModel]) datesList.push(new Date(eventToSet.CONTAINER_GROUP[i][dataModel]));
                else allContainersHasDate = false;
            }
            const maxDate = (datesList.length > 0) ? new Date(Math.max.apply(null, datesList)) : null;

            const headerText = `Atualizar Containers do Evento ${eventToSet.EVENT_NUMBER}`;

            //new valid date
            if (eventNewDate) {
                //check if any container has date, if yes, lets ask user what to do
                let updateAll = false;

                if (datesList.length > 0) {
                    updateAll = await this.ModalService.showModalConfirmation({}, {
                        headerText: headerText,
                        bodyText: `Deseja sobrescrever a data ${dataType} de todos os containers ou somente dos containers que não tem uma data ${dataType}?<br><br> 
                    <span>Data ${dataType}: <b>${(eventNewDate) ? this.$filter('date')(eventNewDate, "dd/MM/yyyy HH:mm") : ''}</b></span><br>`,
                        actionButtonText: 'Sobrescrever Todos',
                        closeButtonText: 'Manter'

                    });
                }

                if (updateAll) {
                    //update all containers
                    for (let i = 0; i < eventToSet.CONTAINER_GROUP.length; i++) {
                        //set the new value
                        eventToSet.CONTAINER_GROUP[i][dataModel] = eventNewDate;
                    }
                } else {
                    //update just the containers that does not have a date
                    for (let i = 0; i < eventToSet.CONTAINER_GROUP.length; i++) {
                        //set the new value just if current value is undefined/null
                        if (!eventToSet.CONTAINER_GROUP[i][dataModel]) eventToSet.CONTAINER_GROUP[i][dataModel] = eventNewDate;
                    }
                }

                //when settings EFFECTIVE date and FORECAST date is null, replicate EFFECTIVE date to FORECAST date
                if (type === 'EFFECTIVE' && !eventToSet['FORECAST_DATE']) {
                    eventToSet['FORECAST_DATE'] = eventNewDate;
                    eventToSet = await this.handleEventContainerDateChange(eventToSet, 'FORECAST', eventNewDate);
                }

            } else {
                //cleaned the event date
                const deleteAll = await this.ModalService.showModalConfirmation({}, {
                    headerText: headerText,
                    bodyText: `Deseja apagar a data ${dataType} de todos os containers?`,
                    actionButtonText: 'Apagar',
                    closeButtonText: 'Manter'

                });

                if (deleteAll) {
                    for (let i = 0; i < eventToSet.CONTAINER_GROUP.length; i++) {
                        //set the new value
                        eventToSet.CONTAINER_GROUP[i][dataModel] = null;
                    }
                } else {
                    //lets check if all containers have dates and if we have a max date
                    if (allContainersHasDate && maxDate) eventToSet[dataModel] = maxDate;
                }
            }

            return eventToSet;

        } catch (ex) {
            throw ex;
        }
    }

    // aka updateEventContainer
    public async setEventDateBasedOnContainers(eventToSet: NewProcessEvent, type: string, container?: CONTAINER_GROUP): Promise<NewProcessEvent> {
        try {
            if (!eventToSet.CONTAINER_GROUP || eventToSet.CONTAINER_GROUP.length === 0) return eventToSet;

            let dataModel = 'FORECAST_DATE';

            switch (type) {
                case 'FORECAST':
                    dataModel = 'FORECAST_DATE';
                    break;
                case 'EFFECTIVE':
                    dataModel = 'EFFECTIVE_DATE';
                    break;
            }

            const datesList = new Array<Date>();
            let allContainersHasDate = true;

            for (let i = 0; i < eventToSet.CONTAINER_GROUP.length; i++) {
                if (eventToSet.CONTAINER_GROUP[i][dataModel]) datesList.push(new Date(eventToSet.CONTAINER_GROUP[i][dataModel]));
                else allContainersHasDate = false;
            }
            //get the higher date from all containers when all containers has a date of the current type
            const maxDate = (datesList.length > 0) ? new Date(Math.max.apply(null, datesList)) : null;
            if (allContainersHasDate && maxDate) eventToSet[dataModel] = maxDate;
            //clear the event effective date if user removes any container effective date
            else if (type === 'EFFECTIVE' && container && !container[dataModel]) eventToSet[dataModel] = null;

            //replicate effective_date to forecast_date if forecast_date is null
            if (type === 'EFFECTIVE') {
                if (container) {
                    if (container[dataModel] && !container.FORECAST_DATE)
                        container.FORECAST_DATE = container[dataModel];
                } else { // no specific container, user changed transport mode
                    for (let i = 0; i < eventToSet.CONTAINER_GROUP.length; i++) {
                        // preserve estimated date. If null, use effective date
                        eventToSet.CONTAINER_GROUP[i].FORECAST_DATE = (eventToSet.FORECAST_DATE) ? eventToSet.FORECAST_DATE : eventToSet[dataModel];
                    }
                }
            }

            return eventToSet;

        } catch (ex) {
            throw ex;
        }
    }

    public async applyTransportModeToEventFromInttra(eventToSet: NewProcessEvent, selectedTransportModeStopover: ISelectorModel, oceanScheduleOptions: IInttraOceanSchedulesResult[], process: Process, oceanScheduleDeadline: IInttraDeadline[]): Promise<NewProcessEvent> {
        try {

            const selectedOption = oceanScheduleOptions && oceanScheduleOptions.length > 0 && selectedTransportModeStopover ? oceanScheduleOptions.find(x => x['ID'] == selectedTransportModeStopover.ID) : null;
            const selectedDeadline = oceanScheduleDeadline && oceanScheduleDeadline.length > 0 && selectedTransportModeStopover ? oceanScheduleDeadline.find(x => x.ID == selectedTransportModeStopover.ID) : null;

            let isLoad: boolean = [EEventType.LOAD, EEventType.LOAD_TRANSHIPMENT].includes(eventToSet.EVENT_TYPE.ID as EEventType);
            let isDischarge: boolean = [EEventType.DISCHARGE, EEventType.DISCHARGE_TRANSHIPMENT].includes(eventToSet.EVENT_TYPE.ID as EEventType);
            let estimatedDate: Date;
            if (selectedOption) {
                estimatedDate = isLoad ? moment(selectedOption.originDepartureDate).toDate() : isDischarge ? moment(selectedOption.destinationArrivalDate).toDate() : null;
                const transportModeSelector = { ID: null, NAME: `${selectedOption.vesselName}|${selectedOption.voyageNumber}`, CODE: selectedOption.imoNumber };
                eventToSet.TRANSPORT_MODE = transportModeSelector;

                // In this block, the stopover is setted, but it'll be replaced in the backend. A search on Voyage collection will find the correct ID for Stopover.
                eventToSet.STOPOVER = {
                    ID: null,
                    NAME: isLoad ? selectedOption.originUnloc : isDischarge ? selectedOption.destinationUnloc : null,
                    CODE: null
                };

                const providerFromExternal = await this.externalService.post({ route: "/ediExternalCodeSetup/externalToShipowner", data: { code: selectedOption.scac } });
                const shipOwner = providerFromExternal && providerFromExternal.data && providerFromExternal.data.data ? providerFromExternal.data.data : null;
                if (shipOwner) {
                    eventToSet.SERVICE_PROVIDER = {
                        ID: shipOwner.CODE_INTERNAL.ID,
                        NAME: eventToSet.SERVICE_PROVIDER.NAME,
                        CODE: shipOwner.CODE_INTERNAL.NAME,
                        ID_LEGAL_PERSON: null,
                        TYPE: null
                    };
                } else {
                    throw new Error(`Missing setup for ${selectedOption.scac} - ${selectedOption.carrierName} at Integration Settings for Shipowner.`);
                }

                eventToSet.VIA = selectedOption.scheduleType == 'transshipment' ? 'TRANSBORDO' : 'DIRETO';
                eventToSet.FORECAST_DATE = estimatedDate;
                eventToSet.EFFECTIVE_DATE = null;

                // Update the situation based on the information setted on the event.
                const updatedSituation = this.getEventSituation(eventToSet);
                if (updatedSituation) eventToSet.SITUATION = updatedSituation;

                // Update the containers date based on the event.
                const updatedEvent = await this.updateEventAndContainerDates(eventToSet);
                if (updatedEvent) eventToSet = updatedEvent;

                if (process && selectedDeadline) {

                    process.DEADLINES = [];

                    const voyageDeadlines: DEADLINES = {
                        TYPE: EProcessDocumentType.MASTER,
                        STOPOVER_REFERENCE: isLoad ? selectedOption.originUnloc : isDischarge ? selectedOption.destinationUnloc : null,
                        DEADLINE_DATES: {
                            VGM: new Date(selectedDeadline.VGM),
                            DRAFT_IMO: new Date(selectedDeadline.DRAFT_IMO),
                            DRAFT: new Date(selectedDeadline.DRAFT),
                            RELEASE: new Date(selectedDeadline.RELEASE),
                            BASE_DATE: new Date(selectedDeadline.BASE_DATE)
                        },
                        DEADLINE_REVIEWS: null,
                        DEADLINE_CARGO_DOCS: null,
                        SPECIFIC_SLA: false
                    };

                    process.DEADLINES.push(voyageDeadlines);

                    const voyageDeadlineHouse: DEADLINES = {
                        TYPE: EProcessDocumentType.HOUSE,
                        STOPOVER_REFERENCE: isLoad ? selectedOption.originUnloc : isDischarge ? selectedOption.destinationUnloc : null,
                        DEADLINE_DATES: {
                            VGM: null,
                            DRAFT_IMO: null,
                            DRAFT: null,
                            RELEASE: null,
                            BASE_DATE: null
                        },
                        DEADLINE_REVIEWS: null,
                        DEADLINE_CARGO_DOCS: null,
                        SPECIFIC_SLA: false
                    };

                    process.DEADLINES.push(voyageDeadlineHouse);
                }
            }

            return eventToSet;

        } catch (ex) {
            throw ex;
        }
    }

    public async setEventDateBasedOnTruck(eventToSet: NewProcessEvent, type: string, vehicle: IVehicleTypeDateFrontEnd, processVehicleTypeEventList: IVehicleTypeDateFrontEnd[]): Promise<NewProcessEvent> {
        try {

            let dataModel = 'FORECAST_DATE';

            switch (type) {
                case 'FORECAST':
                    dataModel = 'FORECAST_DATE';
                    break;
                case 'EFFECTIVE':
                    dataModel = 'EFFECTIVE_DATE';
                    break;
            }

            const datesList = new Array<Date>();
            let allTrucksHasDate = true;

            for (let i = 0; i < processVehicleTypeEventList.length; i++) {
                if (processVehicleTypeEventList[i][dataModel]) datesList.push(new Date(processVehicleTypeEventList[i][dataModel]));
                else allTrucksHasDate = false;
            }

            //get the higher date from all containers when all containers has a date of the current type
            const maxDate = (datesList.length > 0) ? new Date(Math.max.apply(null, datesList)) : null;

            if (allTrucksHasDate && maxDate) eventToSet[dataModel] = maxDate;
            //clear the event effective date if user removes any container effective date

            else if (type === 'EFFECTIVE' && vehicle && !vehicle[dataModel]) eventToSet[dataModel] = null;

            //replicate effective_date to forecast_date if forecast_date is null
            if (type === 'EFFECTIVE') {
                if (vehicle) {
                    if (vehicle[dataModel] && !vehicle.FORECAST_DATE) vehicle.FORECAST_DATE = vehicle[dataModel];
                } else {
                    for (let i = 0; i < processVehicleTypeEventList.length; i++) {
                        // preserve estimated date. If null, use effective date
                        processVehicleTypeEventList[i].FORECAST_DATE = processVehicleTypeEventList[i].FORECAST_DATE ? processVehicleTypeEventList[i].FORECAST_DATE : eventToSet[dataModel];
                    }
                }
            } else if (type === 'FORECAST') {
                for (let i = 0; i < processVehicleTypeEventList.length; i++) {
                    processVehicleTypeEventList[i].FORECAST_DATE = processVehicleTypeEventList[i].FORECAST_DATE ? processVehicleTypeEventList[i].FORECAST_DATE : eventToSet[dataModel];
                }
            }

            return eventToSet;

        } catch (ex) {
            throw ex;
        }
    }
}

import { IGridRow } from "ui-grid";
import { IGridFormController, IGridFormServiceScope, GridFormService } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { ISessionService } from "@services/SessionService";
import { Invoice } from "@models/interface/finop/Invoice";
import { SelectorModel } from "../../common/model/SelectorModel";
import { IProcessParameter, IContractParameter, IInvoiceParameter } from "../../common/model/ModelParameter";
import config from "../../bootstrap/Config";
import * as moment from 'moment';
import { BrowserTitle } from "../../common/BrowserTitle";
import { HelperService } from "@services/HelperService";

interface IBillingContactRegister {
    _id: object,
    ID: number,
    BILLING_NUMBER: string,
    INVOICE_NUMBER: Array<string>,
    HISTORY: {
        BILLING_DATE: Date,
        CONTACT_TYPE: SelectorModel,
        CONTACT_CUSTOMER: string,
        NEW_CONTACT_DATE: Date,
        FORECAST_PAYMENT: Date,
        CONTACT_RESULT: string
        OBSERVATION: string,
        BILLING_STATUS: SelectorModel
    }
}

interface IBillingContactScope extends IGridFormServiceScope {
    customTemplate: string;
    model: Invoice;
    selectedRows: Invoice[];
    selectedInvoices: Invoice[];
    totalInvoices: number;
    modalError: string;
    modalSuccess: string;
    billingContactRegister: IBillingContactRegister;
    billingTypeList: SelectorModel[];
    billingResult: SelectorModel[];
    billingContactStatus: SelectorModel[];
    isOpenRegister: boolean;
    invoiceNumberListView: string;
    newRegisterData: () => void;
    newRegisterEvent: (row: IGridRow, isOpenRegister: boolean) => void;
    showActionsSelected: (row: IGridRow, action: string) => void;
    addFileReference: (model: Invoice) => void;
    goToProcess: (process: string) => void;
    goToContract: (contract: string) => void;
    goToInvoice: (invoiceNumber: string) => void;
    getInvoiceHistory: (row: IGridRow) => void;
    setHistoryDateFormat: (date: Date, format: string) => void;
    calculusTotal: (model: Invoice[]) => void;
}

export class BillingContactRegisterController extends GridFormService<Invoice> implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IBillingContactScope;
    private RestService: IRestService;
    private selectedRows: Invoice[];
    private sessionService: ISessionService;
    modalID: any;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IBillingContactScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.RestService = $injector.get('RestService');
        this.sessionService = $injector.get('SessionService');
        this.modalID = null;
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = config.fmsUrl;
            this.$scope.customTemplate = 'gridTemplate';
            this.$scope.formOperation = 'Detalhes';
            this.selectedRows = new Array<any>();
            this.$scope.selectedRows = [];
            this.$scope.selectedInvoices = [];
            this.$scope.billingContactRegister = null;
            this.$scope.totalInvoices = null;
            this.$scope.isOpenRegister = false;
            this.$scope.invoiceNumberListView = null;

            this.initForm(this, 'form', 'billing', 'Cobranca', false);
            this.block();

            this.$gridService.setSelectable(true);

            await this.initGrid('gridBilling', '/fms/billingcontact/list', true, true, 30000, false);
            this.$gridService.setBackgroundUpdate(15000, [this.selectionReapply, this]);

            const billingType = await this.getSelectorModelFromGeneric('billing_contact_type');
            const billingResult = await this.getSelectorModelFromGeneric('billing_contact_result');
            const billingStatus = await this.getSelectorModelFromGeneric('billing_contact_status');

            this.$scope.billingTypeList = (billingType) ? billingType : [];
            this.$scope.billingResult = (billingResult) ? billingResult : [];
            this.$scope.billingContactStatus = (billingStatus) ? billingStatus : [];

            this.monitoringFilterChanges();

            this.$gridService.$gridApi.selection.on.rowSelectionChanged(this.$scope, this.selectedRowCallback.bind(this));
            this.$gridService.$gridApi.selection.on.rowSelectionChangedBatch(this.$scope, this.selectedRowBatchCallback.bind(this));

            this.unblock();
        } catch (ex) {
            this.unblock();
            this.handleWarning('Hmmm... Desculpa o serviço WBA-FMS está indisponível no momento');
            this.handleWarning('Já estamos cuidando disso, aguarde alguns instantes e recarregue a página novamente.');
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initGridColumns(columns) {
        const columnDefs: IMonacoColumnDef[] = new Array();

        const history = '<div class="text-center"><span ng-show="grid.appScope.showActionsSelected(row, \'history\');"><a ng-click="grid.appScope.getInvoiceHistory(row)" class="text-allog" tooltip-placement="auto top" uib-tooltip="Obter histórico da fatura" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;</span>';
        const newRegister = '<a ng-click="grid.appScope.newRegisterEvent(row, true)" class="text-brown" tooltip-placement="auto top" uib-tooltip="Adicionar nova cobrança" tooltip-append-to-body="true" ><i class="fa fa-plus-circle icon"></i></a>&nbsp;&nbsp;&nbsp;'
        columnDefs.push({
            name: "acoes",
            displayName: "Ações",
            minWidth: 70,
            maxWidth: 180,
            cellTemplate: (history + newRegister),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            allowCellFocus: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        const NewColumnDefs = this.buildColunms(columns);
        for (let column of NewColumnDefs) { columnDefs.push(column) }
        return columnDefs;
    }

    buildColunms(column, prop?): any {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            for (var property in column) {
                if (typeof (column[property]) === 'string') {
                    let currentProperty = (prop) ? `${prop}.${column[property]}` : column[property];
                    let name = null;
                    let displayName = null;
                    let width = 0;
                    let cellTemplate = undefined;
                    let cellFilter = null;
                    let visible = true;
                    let field = null;
                    let filter: uiGrid.IFilterOptions = { condition: this.$gridService.filterSelectObject };
                    let searchProps = null;

                    switch (currentProperty.toUpperCase()) {
                        case 'INVOICE_NUMBER':
                            name = currentProperty;
                            displayName = '#Fatura';
                            width = currentProperty.length / 1.5;
                            cellTemplate = '<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="Clique para visualizar a fatura {{row.entity.INVOICE_NUMBER}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToInvoice(row.entity.INVOICE_NUMBER)">{{row.entity.INVOICE_NUMBER}}</a></div>';
                            break;
                        case 'PROCESS_NUMBER':
                            name = currentProperty;
                            displayName = 'Processo';
                            width = currentProperty.length;
                            cellTemplate = '<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="Clique para visualizar o processo {{row.entity.PROCESS_NUMBER}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToProcess(row.entity.PROCESS_NUMBER)">{{row.entity.PROCESS_NUMBER}}</a><span class="label label-danger" ng-if="row.entity.PROCESS.PRIORITY.ID == \'2\'"><i class="text-warning fa fa-bolt"></i></span></div>';
                            break;
                        case 'PEOPLE':
                            name = currentProperty + '.NAME';
                            displayName = 'Pessoa';
                            width = currentProperty.length + 11;
                            break;
                        case 'CONTRACT_NUMBER':
                            name = currentProperty;
                            field = `${currentProperty}`;
                            displayName = 'Contrato';
                            width = currentProperty.length / 1.5;
                            filter = { condition: this.$gridService.filterSelectObject };
                            cellTemplate = '<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="Clique para visualizar o contrato {{row.entity.CONTRACT_NUMBER}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToContract(row.entity.CONTRACT_NUMBER)">{{row.entity.CONTRACT_NUMBER}}</a></div>';
                            break;
                        case 'TOTAL':
                            name = currentProperty;
                            displayName = 'Total';
                            cellTemplate = '<div class="ui-grid-cell-contents">{{row.entity.TOTAL | number: 2}}<div>';
                            width = currentProperty.length + 2;
                            break;
                        case 'ORIGINAL_TOTAL':
                            name = currentProperty;
                            displayName = 'Total Original';
                            cellTemplate = '<div class="ui-grid-cell-contents">{{row.entity.ORIGINAL_TOTAL | number: 2}}<div>';
                            width = currentProperty.length;
                            break;
                        case 'PARTIAL_PAYMENT':
                            name = currentProperty;
                            displayName = 'Pagamento Parcial';
                            cellTemplate = '<div class="ui-grid-cell-contents">{{row.entity.PARTIAL_PAYMENT | number: 2}}<div>';
                            width = currentProperty.length;
                            break;
                        case 'DUE_DATE':
                            name = currentProperty;
                            displayName = 'Data de Vencimento';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length + 2;
                            break;
                        case 'PROCESS.CUSTOMER':
                            name = currentProperty + '.NAME';
                            displayName = 'Cliente';
                            width = currentProperty.length + 6;
                            break;
                        case 'PROCESS.EXPECTED_LOAD_DATE':
                            name = currentProperty;
                            displayName = 'Prev. Embarque';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length;
                            break;
                        case 'PROCESS.EXPECTED_DISCHARGE_DATE':
                            name = currentProperty;
                            displayName = 'Prev. Desembarque';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length;
                            break;
                        case 'PROCESS.CLIENT_REFERENCE':
                            name = currentProperty;
                            displayName = 'Ref. do Cliente';
                            width = currentProperty.length;
                            break;
                        case 'PROCESS.DOCUMENT_MBL':
                            name = currentProperty;
                            displayName = 'Conhecimento Master';
                            width = currentProperty.length + 2;
                            break;
                        case 'PROCESS.DOCUMENT_HBL':
                            name = currentProperty;
                            displayName = 'Conhecimento House';
                            width = currentProperty.length + 2;
                            break;
                        case 'STATUS':
                            name = currentProperty + '.NAME';
                            displayName = 'Situação Fatura';
                            width = currentProperty.length + 4;
                            break;
                        case 'BILLING_CONTACT_HISTORY.BILLING_DATE':
                            name = 'BILLING_CONTACT_HISTORY[0].HISTORY.BILLING_DATE';
                            searchProps = ['BILLING_CONTACT_HISTORY.HISTORY.BILLING_DATE'];
                            displayName = 'Data da Cobrança';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = displayName.length;
                            break;
                        case 'BILLING_CONTACT_HISTORY.CONTACT_TYPE':
                            name = 'BILLING_CONTACT_HISTORY[0].HISTORY.CONTACT_TYPE.NAME';
                            searchProps = ['BILLING_CONTACT_HISTORY.HISTORY.CONTACT_TYPE.NAME'];
                            displayName = 'Tipo de Contato';
                            width = displayName.length;
                            break;
                        case 'BILLING_CONTACT_HISTORY.CONTACT_CUSTOMER':
                            name = 'BILLING_CONTACT_HISTORY[0].HISTORY.CONTACT_CUSTOMER';
                            searchProps = ['BILLING_CONTACT_HISTORY.HISTORY.CONTACT_CUSTOMER'];
                            displayName = 'Contato no Cliente';
                            width = displayName.length;
                            break;
                        case 'BILLING_CONTACT_HISTORY.NEW_CONTACT_DATE':
                            name = 'BILLING_CONTACT_HISTORY[0].HISTORY.NEW_CONTACT_DATE';
                            searchProps = ['BILLING_CONTACT_HISTORY.HISTORY.NEW_CONTACT_DATE'];
                            displayName = 'Nova ação Prevista'
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = displayName.length;
                            break;
                        case 'BILLING_CONTACT_HISTORY.FORECAST_PAYMENT':
                            name = 'BILLING_CONTACT_HISTORY[0].HISTORY.FORECAST_PAYMENT';
                            searchProps = ['BILLING_CONTACT_HISTORY.HISTORY.FORECAST_PAYMENT'];
                            displayName = 'Prev. Pagamento';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = displayName.length;
                            break;
                        case 'BILLING_CONTACT_HISTORY.CONTACT_RESULT':
                            name = 'BILLING_CONTACT_HISTORY[0].HISTORY.CONTACT_RESULT.NAME';
                            searchProps = ['BILLING_CONTACT_HISTORY.HISTORY.CONTACT_RESULT.NAME'];
                            displayName = 'Última Cobrança';
                            width = displayName.length;
                            break;
                        case 'BILLING_CONTACT_HISTORY.BILLING_STATUS':
                            name = 'BILLING_CONTACT_HISTORY[0].HISTORY.BILLING_STATUS.NAME';
                            searchProps = ['BILLING_CONTACT_HISTORY.HISTORY.BILLING_STATUS.NAME'];
                            displayName = 'Situação Cobrança';
                            width = displayName.length;
                            break;
                        case 'BILLING_CONTACT_HISTORY.OBSERVATION':
                            name = 'BILLING_CONTACT_HISTORY[0].HISTORY.OBSERVATION';
                            searchProps = ['BILLING_CONTACT_HISTORY.HISTORY.OBSERVATION'];
                            displayName = 'Observação Cobrança';
                            width = displayName.length;
                            break;
                        default:
                            name = currentProperty;
                            width = currentProperty.length;
                            visible = false;
                            break;
                    };

                    const newColumn: IMonacoColumnDef = {
                        name: name,
                        displayName: displayName,
                        headerCellClass: this.$gridService.highlightFilteredHeader.bind(this.$gridService),
                        width: width + '%',
                        cellTemplate: cellTemplate,
                        cellFilter: cellFilter,
                        visible: visible,
                        filter: filter,
                        searchProps: searchProps,
                    };
                    if (field) newColumn.field = field;
                    columnDefs.push(newColumn);
                } else {
                    let newCol = this.buildColunms(column[property], property);
                    if (newCol) {
                        for (let dados of newCol) {
                            columnDefs.push(dados);
                        }
                    }
                }
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getSelectorModelFromGeneric(identifier: string): Promise<Array<SelectorModel>> {
        let resultList = new Array<SelectorModel>();

        const { data: generic } = await this.helperService.get(`/generic/getByIdentifier/${identifier}`, null, 15000);

        if ((generic.status !== 200) || (!generic.data)) return null;

        let selectorList = generic && generic.data ? generic.data : [];

        for (const item of selectorList) {
            const selectorModel = <SelectorModel>{};
            selectorModel.ID = item.CODE;
            selectorModel.NAME = item.VALUE;
            selectorModel.CODE = item.ALTERNATIVE;

            resultList.push(selectorModel);
        }

        if (resultList.length > 0) {
            resultList = resultList.sort((x, y) => x.ID < y.ID ? -1 : 1);
        }

        return resultList;
    }

    initScopeFunctions(): void {
        this.$scope.showActionsSelected = (row: IGridRow, action: string) => {
            try {
                const show: boolean = true;

                return show;
            } catch (ex) {
                this.handleError(ex);
            }
        }

        this.$scope.addFileReference = (model: Invoice) => {
            this.addFileReference(model);
        }
        this.$scope.getInvoiceHistory = (row: IGridRow) => {
            this.getInvoiceHistory(row);
        }

        this.$scope.goToProcess = (process: string) => {
            this.goToProcess(process);
        }
        this.$scope.goToContract = (contract: string) => {
            this.goToContract(contract);
        }
        this.$scope.goToInvoice = (invoiceNumber: string) => {
            this.goToInvoice(invoiceNumber);
        }
        this.$scope.setHistoryDateFormat = function (date, format) {
            if (date && format) return (moment(date).format(format));
        }
        this.$scope.calculusTotal = (model: Invoice[]) => {
            this.calculusTotal(model);
        }
        this.$scope.newRegisterData = () => {
            this.newRegisterData()
        }
        this.$scope.newRegisterEvent = (row: IGridRow, isOpenRegister: boolean) => {
            this.newRegisterEvent(row, isOpenRegister)
        }
    }

    initModel(): void { }

    private async clearSelections(): Promise<void> {
        this.$scope.selectedRows = [];
        this.$scope.selectedInvoices = [];

        this.$gridService.$gridApi.selection.clearSelectedRows();
        await this.$scope.$applyAsync();
    }

    private goToProcess(process: string) {
        try {
            this.sessionService.openTab("app.operational.newProcess.list", <IProcessParameter>{ PROCESS_NUMBER: process });
        } catch (ex) {
            this.handleWarning(ex);
        }
    }

    private goToContract(contract: string) {
        try {
            this.sessionService.openTab("app.registration.contract", <IContractParameter>{ CONTRACT_NUMBER: contract });
        } catch (ex) {
            this.handleWarning(ex);
        }
    }

    private goToInvoice(invoiceNumber: string) {
        try {
            this.sessionService.openTab("app.finop.invoice.register", <IInvoiceParameter>{ INVOICE_NUMBER: invoiceNumber });
        } catch (ex) {
            this.handleWarning(ex);
        }
    }

    initDependencies(): Promise<any> {
        const self: BillingContactRegisterController = this;

        return new Promise(function (resolve, reject) {
            resolve(true);
        });
    }

    private selectedRowCallback(row: IGridRow): void {
        try {
            this.block();
            let updateScope = true;

            if (row.isSelected && this.selectedRows.indexOf(row.entity) === -1) {
                if (!this.selectedRows.find(item => item.INVOICE_NUMBER === row.entity.INVOICE_NUMBER)) {
                    this.selectedRows.push(row.entity)
                } else {
                    row.isSelected = false;
                    updateScope = false;
                    this.handleWarning(`Fatura já selecionada`);
                }
            }
            else {
                this.selectedRows.splice(this.selectedRows.indexOf(row.entity), 1);
            }

            if (updateScope) this.$scope.selectedRows = this.selectedRows.slice();
            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private selectedRowBatchCallback(rows: IGridRow[]): void {
        try {
            rows = this.$gridService.$gridApi.selection.getSelectedGridRows();
            this.block();

            for (let i = 0; i < rows.length; i++) {
                if (rows[i].isSelected && this.selectedRows.indexOf(rows[i].entity) === -1) this.selectedRows.push(rows[i].entity);
                else this.selectedRows.splice(this.selectedRows.indexOf(rows[i].entity), 1);
            }

            this.$scope.selectedRows = this.selectedRows.slice();

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    public async selectionReapply(): Promise<void> {
        const rows = this.$gridService.$gridApi.core.getVisibleRows(this.$gridService.$gridApi.grid);
        const selectedRows = this.$gridService.$gridSelectedRows;
        if (selectedRows && selectedRows.length > 0) {
            for (const row of selectedRows) {
                const foundRow = rows.find(x => x.entity._id == row._id);
                if (foundRow) foundRow.setSelected(true);
            }
        }
    }

    private async addFileReference(rowInfo: Invoice): Promise<void> {
        try {
            return this.handleError(`Sorry, method not implemented`);
            // const result = await this.RestService.getObjectAsPromise(`router`, 15000);
            // if (!result || !result.data) return this.handleError(`return message`);
            // const process = result.data[0];

            // const invoiceNumberLsit = (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) ?  this.$scope.selectedRows.map(item => item.INVOICE_NUMBER).join(';') : rowInfo.INVOICE_NUMBER;

            // const fileReferenceModalID = this.ModalService.newModal();
            // const modalResponse = await this.ModalService.showModalFileReference(
            //     {
            //         modalID: fileReferenceModalID,
            //         categoryName: ['List of report template here'],
            //         process: process,
            //         invoiceID: rowInfo.ID.toString(),
            //         reference: invoiceNumberLsit
            //     });

            // if (modalResponse) {
            //     this.$formService.notifySuccess(`Referência ${modalResponse[0].DOCUMENT_VALUE} adicionada ao processo ${rowInfo.PROCESS_NUMBER}`);
            // }

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getInvoiceHistory(gridRow: IGridRow): Promise<any> {
        try {
            if (this.$scope.selectedRows.length === 0 && !gridRow.entity) return this.handleError('Falha ao obter dados da coluna do grid.');

            BrowserTitle.$id = gridRow.entity.INVOICE_NUMBER;
            this.$gridService.$gridApi.selection.clearSelectedRows();
            this.gridService.setFixedRow(gridRow.entity._id);

            let invoiceList = []

            if (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) {
                invoiceList = this.$scope.selectedRows;
            } else if (gridRow.entity) {
                invoiceList.push(gridRow.entity);
            }

            if (!invoiceList || invoiceList.length === 0) return this.handleError('Erro ao selecionar fatura(s)');

            this.$scope.selectedInvoices = invoiceList
            this.$scope.totalInvoices = this.calculusTotal(invoiceList)
            await this.$scope.$applyAsync();
            this.loadViewForm();

        } catch (ex) {
            this.$scope.selectedInvoices = [];
            await this.$scope.$applyAsync();
            this.handleError(ex);
        }
    }

    private calculusTotal(invoiceList: Invoice[]) {
        try {
            if (!invoiceList) throw this.handleError('Falha ao totalizar');

            if (invoiceList && invoiceList.length > 0) {
                return invoiceList.reduce((some, item) => some + item.TOTAL, 0);
            }

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async newRegisterEvent(gridRow: IGridRow, isOpenRegister: boolean) {
        try {
            if (gridRow && gridRow.entity) {
                BrowserTitle.$id = gridRow.entity.INVOICE_NUMBER;
                this.$gridService.$gridApi.selection.clearSelectedRows();
                this.$gridService.setFixedRow(gridRow.entity._id);
            }

            if (isOpenRegister) {
                if (this.$scope.selectedRows.length === 0 && !gridRow.entity) return this.handleError('Falha ao incluir novo registro');
                let invoiceNumberList = [];

                if (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) {
                    invoiceNumberList = this.$scope.selectedRows;
                } else if (gridRow.entity) {
                    invoiceNumberList.push(gridRow.entity);
                }

                this.$scope.invoiceNumberListView = invoiceNumberList.map(item => item.INVOICE_NUMBER).join(' ');
            }

            this.$scope.isOpenRegister = isOpenRegister;

            this.loadViewForm();

            await this.$scope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async newRegisterData(): Promise<any> {
        try {
            if (this.$scope.selectedRows.length === 0 && !this.$scope.invoiceNumberListView) return this.handleError('Falha ao incluir novo registro');
            if (!this.$scope.billingContactRegister) return this.handleError('Nenhuma informação de registro para ser salva');
            this.block();
            let invoiceNumberList = [];

            if (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) {
                invoiceNumberList = this.$scope.selectedRows.map(item => item.INVOICE_NUMBER);
            } else if (this.$scope.invoiceNumberListView) {
                const invoiceList = this.$scope.invoiceNumberListView;
                invoiceNumberList = invoiceList.split(' ');
            }

            if (!invoiceNumberList || invoiceNumberList.length === 0) return this.handleError('Nenhuma fatura localizada');

            const data: IBillingContactRegister = this.$scope.billingContactRegister;
            const historyInfo = this.$scope.billingContactRegister.HISTORY;
            data._id = null;
            data.ID = null;
            data.BILLING_NUMBER = null;
            data.INVOICE_NUMBER = invoiceNumberList;
            data.HISTORY.BILLING_DATE = (historyInfo.BILLING_DATE) ? historyInfo.BILLING_DATE : null;
            data.HISTORY.BILLING_STATUS = (historyInfo.BILLING_STATUS) ? historyInfo.BILLING_STATUS : null;
            data.HISTORY.CONTACT_CUSTOMER = (historyInfo.CONTACT_CUSTOMER) ? historyInfo.CONTACT_CUSTOMER : null;
            data.HISTORY.CONTACT_RESULT = (historyInfo.CONTACT_RESULT) ? historyInfo.CONTACT_RESULT : null;
            data.HISTORY.CONTACT_TYPE = (historyInfo.CONTACT_TYPE) ? historyInfo.CONTACT_TYPE : null;
            data.HISTORY.FORECAST_PAYMENT = (historyInfo.FORECAST_PAYMENT) ? historyInfo.FORECAST_PAYMENT : null;
            data.HISTORY.NEW_CONTACT_DATE = (historyInfo.NEW_CONTACT_DATE) ? historyInfo.NEW_CONTACT_DATE : null;
            data.HISTORY.OBSERVATION = (historyInfo.OBSERVATION) ? historyInfo.OBSERVATION : null;

            const dataInfo = {
                data
            }

            const result = await this.RestService.newObject(`${this.config.fmsUrl}/fms/billingcontact/insert`, dataInfo, 15000, false);

            this.unblock();
            if (result.status !== 200) return this.handleError('Falha ao registrar a cobrança');
            if (!result.data.data) return this.handleError('Resultado indefinido');

            if (result.data.data) {
                if (result.status === 200) {
                    this.$scope.billingContactRegister = null;
                    this.$scope.isOpenRegister = false;
                    await this.$scope.$applyAsync();
                    await this.updateGrid();
                    await this.$scope.$applyAsync();
                    return this.$formService.notifySuccess(`Cobrança salva com sucesso`);
                }
            }

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private monitoringFilterChanges() {
        this.$gridService.$gridApi.core.on.filterChanged(this.$scope, this.clearSelections.bind(this));
    }
}

import * as angular from "angular";
import { SSEService } from "@appServices/SSEService";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IModalService } from "@services/ModalService";
import { ISessionService } from "@services/SessionService";
import { DataOperationalService } from "@services/DataOperationalService";
import { DataProductService } from "@services/DataProductService";
import { FinopService } from '@services/FinopService';
import { IChargeNameListCustomFilter } from "@models/interface/product/ChargeNameModel";
import { IApplicationList } from "@models/interface/product/ApplicationModel";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { ITablePerDiemQueryFields } from "@models/interface/product/TablePerDiem";
import { IProductConfigurationModel, ITransshipment, IProfitShareApplication, ICorporativeClientSankhya, ICurrency } from "@models/interface/product/ProductConfigurationModel";
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { SelectorModel } from "../../common/model/SelectorModel";
import { ILegalPersonListCustomFilter, ILegalPersonSelector } from "../../registration/model/LegalPersonModel";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { BrowserTitle } from "../../common/BrowserTitle";
import { ILinkParameter } from "../../common/model/ModelParameter";
import { EOperation } from "@enums/GenericData";
import { ProductService } from "@services/ProductService";
import { DataProcessService } from "@services/DataProcessService";
import { HelperService } from "@services/HelperService";
import { MyAllogOperationalService } from "@services/MyAllogOperationalService";
import { MyAllogProductService } from "@services/MyAllogProductService";
import { IMonacoRequest } from '@services/GridFormService';
import { IHttpResponse } from "angular";
import { ISelectorModel } from "WBA-Model/dist/mongo/SelectorModel";
import { OperationalService } from "@services/OperationalService";

interface IOperationalProductIntegrationParams {
    offerSyncStartOpen: boolean;
    offerSyncStart: Date;
    offerSyncEnd: Date;
    tariffFreightSyncStartOpen: boolean;
    tariffFreightSyncStart: Date;
    tariffFreightSyncEnd: Date;
    daysAgoProcess: number;
}

interface IProductConfigurationIntegrationParamsModal {
    currentEntity: string;
    operationalProduct: IOperationalProductIntegrationParams;
}

interface IProductConfigurationScope extends IGridFormServiceScope {
    model: IProductConfigurationModel;
    hasModelChanges: boolean;
    log: IViewLog;
    productList: SelectorModel[];
    eventList: SelectorModel[];
    chargeNameList: SelectorModel[];
    insuranceBrokerList: SelectorModel[];
    typeCargoList: SelectorModel[];
    applicationList: IApplicationList[];
    applicationFlexitanqList: IApplicationList[];
    currencyFlexitanqList: ICurrency[];
    currencyFactorList: number[];
    integrationDirectionList: SelectorModel[];
    tablePerDiemList: SelectorModel[];
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IProductConfigurationModel;
    defaultApplicationPayment: IApplicationList;
    defaultApplicationReceiving: IApplicationList;
    corporateBranchList: SelectorModel[];
    accountList: SelectorModel[];
    modalityList: SelectorModel[];
    user: any;
    sessionService: ISessionService;
    productConfigurationIntegrationParams: IProductConfigurationIntegrationParamsModal;
    dataProcessService: DataProcessService;
    processList: ISelectorModel[];
    processToSync: string;
    editProductConfiguration: (productConfiguration: IProductConfigurationModel) => Promise<void>;
    viewProductConfiguration: (productConfiguration: IProductConfigurationModel) => Promise<void>;
    viewLogProductConfiguration: (productConfiguration: IProductConfigurationModel) => Promise<void>;
    copyProductConfiguration: (productConfiguration: IProductConfigurationModel) => Promise<void>;
    addTransshipment: () => void;
    removeTransshipment: (index: number) => void;
    addSecure: () => void;
    removeSecure: (index: number) => void;
    addApplication: () => void;
    removeApplication: (index: number) => void;
    addCoporationClient: () => void;
    removeCoporationClient: (index: number) => void;
    getApplicationListByName: (product: SelectorModel[], typeCargo: SelectorModel[], search: string) => Promise<void>;
    getApplicationFlexitanqListByName: (search: string) => Promise<void>;
    getCurrencyFlexitanqListByName: (search: string) => Promise<void>;
    getInsuranceBrokerListByName: (search: string) => Promise<void>;
    getCorporateBranchListByName: (search: string) => Promise<void>;
    getAccountListByName: (search: string) => Promise<void>;
    getChargeNameListByName: (search: string, products?: SelectorModel[]) => Promise<void>;
    getTablePerDiemListOrigin: (search: string) => Promise<void>;
    getTablePerDiemListDestination: (search: string) => Promise<void>;
    goToInsuranceBroker: (id?: number) => void;
    goToApplication: (id: number) => void;
    goToTablePerDiem: (tablePerDiemId: number) => void;
    transshipmentProductFilter: () => Function;
    secureProductFilter: () => Function;
    clearApplication: ($index: number) => void;
    clearCache: (clearCache: string[]) => void;
    clearData: (identifier: string) => void;
    clearAllCache: () => void;
    sendSyncFull: (entityToSync: string) => void;
    hasChanges: (newObj: Object, oldObj: Object, propertiesToIgnore?: string[]) => boolean;
    getProcessToSync: (search: string) => void;
    updateProcessToSync: (processToSync: string) => void;
}

export class ProductConfigurationRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IProductConfigurationScope;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private RestService: IRestService;
    private ProductService: ProductService;
    private ModalService: IModalService;
    private dataProductService: DataProductService;
    private myAllogOperationalService: MyAllogOperationalService;
    private myAllogProductService: MyAllogProductService;
    private dataOperationalService: DataOperationalService;
    private finopService: FinopService;
    private quotationUrl: String;
    private quotationCombinedUrl: String;
    private SSEService: SSEService;
    private gridName: string;
    private helperService: HelperService;
    private OperationalService: OperationalService;

    constructor($injector: ng.Injectable<any>, $scope: IProductConfigurationScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.RestService = $injector.get('RestService');
        this.ProductService = $injector.get('ProductService');
        this.ModalService = $injector.get('ModalService');
        this.dataProductService = $injector.get('DataProductService');
        this.myAllogOperationalService = $injector.get('MyAllogOperationalService');
        this.myAllogProductService = $injector.get('MyAllogProductService');
        this.dataOperationalService = $injector.get('DataOperationalService');
        this.finopService = $injector.get('FinopService');
        this.$scope.dataProcessService = $injector.get('DataProcessService');
        this.$scope.productConfigurationIntegrationParams = { currentEntity: "", operationalProduct: { offerSyncStart: null, offerSyncEnd: null, offerSyncStartOpen: false, tariffFreightSyncStart: null, tariffFreightSyncEnd: null, tariffFreightSyncStartOpen: false, daysAgoProcess: 3 } };
        this.$scope.productConfigurationIntegrationParams.operationalProduct.offerSyncStart = new Date();
        this.$scope.productConfigurationIntegrationParams.operationalProduct.offerSyncEnd = new Date();
        this.$scope.productConfigurationIntegrationParams.operationalProduct.offerSyncStart.setDate(this.$scope.productConfigurationIntegrationParams.operationalProduct.offerSyncStart.getDate() - 30);
        this.$scope.productConfigurationIntegrationParams.operationalProduct.tariffFreightSyncStart = new Date();
        this.$scope.productConfigurationIntegrationParams.operationalProduct.tariffFreightSyncEnd = new Date();
        this.$scope.productConfigurationIntegrationParams.operationalProduct.tariffFreightSyncStart.setDate(this.$scope.productConfigurationIntegrationParams.operationalProduct.tariffFreightSyncStart.getDate() - 30);
        this.quotationUrl = this.getUrlQuotation();
        this.quotationCombinedUrl = this.getUrlQuotationCombined();
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope);
        this.gridName = 'GRID_PRODUCT_CONFIGURATION';
        this.helperService = $injector.get('HelperService');
        this.OperationalService = $injector.get('OperationalService');
        this.$scope.processToSync = null;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'productConfiguration', 'GENERAL.MENU.GENERAL_CONFIGURATION', true);
            await this.initGrid(this.gridName, '/productConfiguration/list', true, true, null, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.addTransshipment = (): void => {
            this.addTransshipment();
        }

        this.$scope.removeTransshipment = (index: number): void => {
            this.removeTransshipment(index);
        }

        this.$scope.addSecure = (): void => {
            this.addSecure();
        }

        this.$scope.removeSecure = (index: number): void => {
            this.removeSecure(index);
        }

        this.$scope.addApplication = (): void => {
            this.addApplication();
        }

        this.$scope.removeApplication = (index: number): void => {
            this.removeApplication(index);
        }

        this.$scope.addCoporationClient = (): void => {
            this.addCoporationClient();
        }

        this.$scope.removeCoporationClient = (index: number): void => {
            this.removeCoporationClient(index);
        }
        this.$scope.getAccountListByName = async (search: string): Promise<void> => {
            await this.getAccountListByName(search);
        }
        this.$scope.getApplicationListByName = async (product: SelectorModel[], typeCargo: SelectorModel[], search: string): Promise<void> => {
            await this.getApplicationListByName(product, typeCargo, search);
        }

        this.$scope.getInsuranceBrokerListByName = async (search: string) => {
            let insuranceBrokerList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                const legalPersonList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.INSURANCE_BROKER], search: search });
                if (legalPersonList) insuranceBrokerList = legalPersonList.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME } });
            }
            this.$scope.insuranceBrokerList = insuranceBrokerList;
        }

        this.$scope.getCorporateBranchListByName = async (search: string) => {
            let corporateBranchList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                corporateBranchList = await this.getCorporateBranchListByName(search);
            }
            this.$scope.corporateBranchList = corporateBranchList;
        }

        this.$scope.getChargeNameListByName = async (search: string, products?: SelectorModel[]) => {
            let chargeNameList: SelectorModel[] = [];
            if (search && search.length >= 2) chargeNameList = await this.getChargeNameListByName({ search, types: ['6'], products: products && products.length > 0 ? products.map(product => product.ID) : null });
            this.$scope.chargeNameList = chargeNameList;
        }

        this.$scope.getApplicationFlexitanqListByName = async (search: string): Promise<void> => {
            this.$scope.applicationFlexitanqList = await this.getApplicationFlexitanqListByName(search);
        }

        this.$scope.getCurrencyFlexitanqListByName = async (search: string): Promise<void> => {
            this.$scope.currencyFlexitanqList = await this.getCurrencyFlexitanqListByName(search);
        }

        this.$scope.getTablePerDiemListOrigin = async (search: string): Promise<void> => {
            this.$scope.tablePerDiemList = await this.getTablePerDiemListOrigin(search);
        }

        this.$scope.getTablePerDiemListDestination = async (search: string): Promise<void> => {
            this.$scope.tablePerDiemList = await this.getTablePerDiemListDestination(search);
        }

        this.$scope.goToInsuranceBroker = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.legalPerson", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToApplication = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.application", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToTablePerDiem = (tablePerDiemId: number) => {
            this.$scope.sessionService.openTab("app.product.tablePerDiem", <ILinkParameter>{ ID: tablePerDiemId ? tablePerDiemId.toString() : tablePerDiemId });
        }

        this.$scope.transshipmentProductFilter = (): Function => {
            return this.transshipmentProductFilter();
        }

        this.$scope.secureProductFilter = (): Function => {
            return this.secureProductFilter();
        }

        this.$scope.clearApplication = (index: number): void => {
            if (this.$scope.model.PROFIT_SHARE_APPLICATION && this.$scope.model.PROFIT_SHARE_APPLICATION.length && this.$scope.model.PROFIT_SHARE_APPLICATION[index]) {
                this.$scope.model.PROFIT_SHARE_APPLICATION[index].APPLICATION = null;
                this.$timeout(() => { this.$scope.selectorValidity("applicationApplication" + index) });
            }
        }

        this.$scope.clearCache = (clearCache: string[]): void => {
            this.clearCache(clearCache);
        }

        this.$scope.clearData = (identifier: string): void => {
            this.clearData(identifier);
        }

        this.$scope.clearAllCache = (): void => {
            this.clearAllCache();
        }

        this.$scope.sendSyncFull = (entityToSync: string): void => {
            this.sendSyncFull(entityToSync);
        }

        this.$scope.editProductConfiguration = async (productConfiguration: IProductConfigurationModel): Promise<void> => {
            let blockedObject = {
                ID: productConfiguration.ID,
                NAME: productConfiguration.CREATED_AT.toString(),
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || productConfiguration.ID !== this.$scope.model.ID) this.$scope.view(productConfiguration);
                } else if (this.$scope.operation !== EOperation.EDIT || productConfiguration.ID !== this.$scope.model.ID) {
                    this.$scope.edit(productConfiguration);
                }
            };
        }

        this.$scope.viewProductConfiguration = async (productConfiguration: IProductConfigurationModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(productConfiguration);
        }

        this.$scope.viewLogProductConfiguration = async (productConfiguration: IProductConfigurationModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(productConfiguration);
        }

        this.$scope.hasChanges = (newObj: Object, oldObj: Object, propertiesToIgnore?: string[]) => {
            if (propertiesToIgnore) {
                const newAux = newObj ? angular.copy(newObj) : null;
                const oldAux = oldObj ? angular.copy(oldObj) : null;
                for (const property of propertiesToIgnore) {
                    if (newAux && newAux[property]) {
                        delete newAux[property];
                    }
                    if (oldAux && oldAux[property]) {
                        delete oldAux[property];
                    }
                }
                return !angular.equals(JSON.stringify(newAux), JSON.stringify(oldAux));
            }
            this.$scope.hasModelChanges = !angular.equals(newObj, oldObj);
            return this.$scope.hasModelChanges;
        }

        this.$scope.getProcessToSync = async (search: string) => {
            this.getProcessToSync(search);
        }

        this.$scope.updateProcessToSync = (processToSync: string) => {
            this.updateProcessToSync(processToSync);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewProductConfiguration(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editProductConfiguration(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogProductConfiguration(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 70,
            maxWidth: 70,
            cellTemplate: (view + edit + viewLog),
            enableCellEdit: true,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };

        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 50 };
            const colName: IMonacoColumnDef = { name: "NAME", displayName: "GENERAL.NAME", width: 600 };
            const colIdLegalPerson: IMonacoColumnDef = { name: "LEGAL_PERSON.CODE", displayName: "BASIC_DATA.BRANCH", width: 350 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'NAME':
                        columnDefs.push(colName);
                        break;
                    case 'LEGAL_PERSON':
                        columnDefs.push(colIdLegalPerson);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            NAME: null,
            PROFIT_SHARE_APPLICATION: null,
            TRANSSHIPMENT: null,
            SECURE: null,
            SEVERAL_QTD_ATTEMPT_TTIME: 5,
            TIMER_ACTUATION_TIME: 2,
            QTD_DAYS_CLEANING_LOG: 7,
            CURRENCY_FACTOR_TIME: 1,
            CURRENCY_FACTOR_HISTORY_DAYS: 30,
            QTD_DAYS_TARIFF_HISTORY: 60,
            LOCAL_TARIFF_LIMIT_VALUE: 50,
            INLAND_ROUTES_CHARGE_LIMIT_VALUE: 50,
            COMPLEMENTARY_TARIFF_LIMIT_VALUE: 50,
            FREIGHT_ROUTES_CHARGE_LIMIT_VALUE: 50,
            QTD_DAYS_OFFER_VALIDITY_EXTENDS: 15,
            CACHE_TIMER: {
                COLLECTION_NAME_TIME: 1440,
                ORIGIN_DESTINATION_TIME: 60,
                TYPE_FIELDS_TIME: 1440,
                CONTACTS_TIME: 1440,
                GENERIC_TIME: 1440,
                CONTRACT_TIME: 1,
                PRODUCT_CONFIGURATION_TIME: 1440,
                CURRENCY_TIME: 60,
                CNPJ_TIME: 15,
                CEP_TIME: 30,
                FREIGHT_RATES_MANAGEMENT_EQUIPMENT_TIME: 1440,
                FREIGHT_RATES_MANAGEMENT_WEIGHT_RANGE_TIME: 1440,
                ERP_CHARGE_NAME_PARAM_TIME: 1440,
                ERP_NATURE_SETUP_PARAM_TIME: 1440,
                ERP_NEGOTIATION_TYPE_SETUP_PARAM_TIME: 1440,
                ERP_PARTNER_PARAM_TIME: 1440,
                ERP_PAYMENT_METHOD_SETUP_PARAM_TIME: 1440,
                ERP_PROJECT_SETUP_PARAM_TIME: 1440,
                ERP_TOP_SETUP_PARAM_TIME: 1440,
                BUSINESS_DAY: 120,
                EXCHANGE_RATE: 120,
                EXCHANGE_RATE_HIGHEST: 120,
                BOOKING_REMARKS_TIME: 1440,
                AUTO_RATING_CONTROL_TIME: 1440,
                HOLIDAY_DATES: 8600,
                TRACKING_LINK_SETTINGS: 120,
                CITY_TIME: 1440
            },
            SANKHYA_INTEGRATION_DIRECTION: {
                BRANCH: null,
                INTEGRATION_DIRECTION: null,
                TOP_DESP: null,
                TOP_REC: null,
                BANK: null,
                ACCOUNT: null,
                FLEXITANQ_TOP_ORDER_CREATE: null,
                FLEXITANQ_TOP_BILLING_CREATE: null,
                FLEXITANQ_TOP_BILLING_REVERT: null
            },
            MYALLOG_INTEGRATION: {
                DAYS_PROCESS_SYNC: null,
            },
            STATISTICS: null,
            FLEXITANQ_PARAMS: {
                APPLICATION_PAYMENT: null,
                APPLICATION_RECEIVING: null,
                CURRENCY_PAYMENT: null,
                CURRENCY_RECEIVING: null,
                MODALITY_PAYMENT: null,
                MODALITY_RECEIVING: null
            },
            TABLE_PER_DIEM_DESTINATION: null,
            TABLE_PER_DIEM_ORIGIN: null,
            VOYAGE_SHIP_ETD_MINUS: null,
            VOYAGE_SHIP_ETD_PLUS: null,
            VOYAGE_SHIP_PORT_ETD_MINUS: null,
            VOYAGE_SHIP_PORT_ETD_PLUS: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
        };
        this.$scope.processList = [];
    }

    initDependencies(): Promise<any> {
        const self: ProductConfigurationRegisterController = this;

        this.$scope.currencyFactorList = this.getCurrencyFactorList();

        self.$scope.$watch("hasModelChanges", function (modelNewValues, modelOldValues) {
            const buttons = Array.from(document.getElementsByClassName("btn btn-default")) as HTMLButtonElement[];
            for (const button of buttons) button.disabled = !!modelNewValues;
        });

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getProductList(),
                self.getEventList(),
                self.getTypeCargoList(),
                self.getDefaultApplicationPayment(),
                self.getDefaultApplicationReceiving(),
                self.getIntegrationDirectionList(),
                self.getModalityList(),
            ]).then((result: any) => {
                self.$scope.productList = result[0];
                self.$scope.eventList = result[1];
                self.$scope.typeCargoList = result[2];
                self.$scope.defaultApplicationPayment = result[3];
                self.$scope.defaultApplicationReceiving = result[4];
                self.$scope.integrationDirectionList = result[5];
                self.$scope.modalityList = result[6];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private getCurrencyFactorList(): number[] {
        const list: number[] = [];
        for (let i: number = 1; i <= 23; i++) {
            list.push(i);
        }
        return list;
    }

    private transshipmentProductFilter = (): Function => {
        let productsInUse: string[] = [];
        if (this.$scope.model.TRANSSHIPMENT && this.$scope.model.TRANSSHIPMENT.length > 0) {
            for (const transshipment of this.$scope.model.TRANSSHIPMENT) {
                if (transshipment.PRODUCT && transshipment.PRODUCT.length > 0)
                    for (const product of transshipment.PRODUCT) { productsInUse.push(product.ID) }
            }
        }
        return function (product: SelectorModel) {
            return !productsInUse || productsInUse && productsInUse.findIndex(productInUse => productInUse == product.ID) < 0;
        }
    }

    private secureProductFilter = (): Function => {
        let productsInUse: string[] = [];
        if (this.$scope.model.SECURE && this.$scope.model.SECURE.length > 0) {
            for (const secure of this.$scope.model.SECURE) {
                if (secure.PRODUCT && secure.PRODUCT.length > 0)
                    for (const product of secure.PRODUCT) { productsInUse.push(product.ID) }
            }
        }
        return function (product: SelectorModel) {
            return !productsInUse || productsInUse && productsInUse.findIndex(productInUse => productInUse == product.ID) < 0;
        }
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            { PROPERTY: "NAME", LABEL: "GENERAL.NAME" },
            { PROPERTY: "TRANSSHIPMENT", LABEL: "OPERATIONAL.TRANSSHIPMENT" },
            { PROPERTY: "PRODUCT", LABEL: "BASIC_DATA.PRODUCT" },
            { PROPERTY: "INITIALS", LABEL: "GENERAL.CODE" },
            { PROPERTY: "NAME_INTL", LABEL: "GENERAL.NAME_INTL" },
            { PROPERTY: "EVENT_ARRIVAL", LABEL: "GENERAL.ENTRANCE_EVENT" },
            { PROPERTY: "EVENT_DEPARTURE", LABEL: "GENERAL.EXIT_EVENT" },
            { PROPERTY: "SECURE", LABEL: "FINANCIAL.INSURANCE" },
            { PROPERTY: "STANDARD_AWARD", LABEL: "FINANCIAL.STANDARD_INSURANCE_PREMIUM" },
            { PROPERTY: "STANDARD_COMISSION_WITHOUT_PRIZE", LABEL: "FINANCIAL.STANDARD_COMISSION_OVER_PREMIUM" },
            { PROPERTY: "INSURANCE_BROKER", LABEL: "ENTITY.INSURANCE_BROKER" },
            { PROPERTY: "SEVERAL_QTD_ATTEMPT_TTIME", LABEL: "GENERAL.QTY_ATTEMPTS_TTIME" },
            { PROPERTY: "CHARGE_NAME_EXHIBITION", LABEL: "BASIC_DATA.CHARGE" },
            { PROPERTY: "INSURANCE_PREMIUM", LABEL: "FINANCIAL.INSURANCE_PREMIUM" },
            { PROPERTY: "INSURANCE_RECEIVING_MIN", LABEL: "FINANCIAL.MINIMUM_FOR_SELLING" },
            { PROPERTY: "INSURANCE_PAYMENT_MIN", LABEL: "FINANCIAL.MINIMUM_FOR_BUYING" },
            { PROPERTY: "TIMER_ACTUATION_TIME", LABEL: "GENERAL.TIMER_OPERATION_TIME" },
            { PROPERTY: "CURRENCY_FACTOR_TIME", LABEL: "GENERAL.CURRENCY_SYNC_TIME_BETWEEN_HC_AND_MONACO" },
            { PROPERTY: "CURRENCY_FACTOR_HISTORY_DAYS", LABEL: "GENERAL.NUMBER_OF_DAYS_TO_SEARCH_FOR_EXCHANGE_RATE" },
            { PROPERTY: "APPLICATION_PAYMENT", LABEL: "FINANCIAL.BASIS_FOR_BUYING_PERC" },
            { PROPERTY: "APPLICATION_RECEIVING", LABEL: "FINANCIAL.BASIS_FOR_SELLING_PERC" },
            { PROPERTY: "CACHE_TIMER", LABEL: "GENERAL.CACHE_TIME_RULES_FOR_NAMESPACES" },
            { PROPERTY: "COLLECTION_NAME_TIME", LABEL: "GENERAL.NAMESPACE_OF_COLLECTIONS_ACCORDING_TO_MOVEMENT_TYPE" },
            { PROPERTY: "ORIGIN_DESTINATION_TIME", LABEL: "GENERAL.NAMESPACE_FOR_RESEARCHES_BY_MOVEMENT_TYPES" },
            { PROPERTY: "TYPE_FIELDS_TIME", LABEL: "GENERAL.NAMESPACE_FOR_RESEARCHES_OF_FIELD_NAMES_ACCORDING_TO_MOVEMENT_TYPES" },
            { PROPERTY: "GENERIC_TIME", LABEL: "GENERAL.NAMESPACE_FOR_RESEARCHES_OF_GENERIC_RECORDS" },
            { PROPERTY: "CONTRACT_TIME", LABEL: "GENERAL.NAMESPACE_FOR_RESEARCHES_OF_CONTRACTS" },
            { PROPERTY: "CNPJ_TIME", LABEL: "GENERAL.NAMESPACE_FOR_RESEARCHES_OF_TAX_ID" },
            { PROPERTY: "CEP_TIME", LABEL: "GENERAL.NAMESPACE_FOR_RESEARCHES_OF_ZIP_CODE" },
            { PROPERTY: "PRODUCT_CONFIGURATION_TIME", LABEL: "GENERAL.NAMESPACE_FOR_RESEARCHES_OF_PRODUCT_SETTINGS" },
            { PROPERTY: "CURRENCY_TIME", LABEL: "GENERAL.NAMESPACE_FOR_RESEARCHES_OF_CURRENCY_EXCHANGE_RATES" },
            { PROPERTY: "PROFIT_SHARE_APPLICATION", LABEL: "GENERAL.STANDARD_APPLICATION_FOR_COMMISSIONS" },
            { PROPERTY: "APPLICATION", LABEL: "FINANCIAL.CHARGE_BASIS" },
            { PROPERTY: "STATISTICS", LABEL: "GENERAL.STATISTICS" },
            { PROPERTY: "INTEGRATION_DIRECTION", LABEL: "GENERAL.FINOP_INTEGRATION" },
            { PROPERTY: "OFFER_GRID", LABEL: "GENERAL.ENABLE_OFFER_GRID_STATISTICS" },
            { PROPERTY: "OFFER_TAB", LABEL: "GENERAL.ENABLE_OFFER_TABS_STATISTICS" },
            { PROPERTY: "CONTACTS_TIME", LABEL: "GENERAL.CACHE_TIME_FOR_CONTACT_SEARCH" },
            { PROPERTY: "CREATED_AT", LABEL: "GENERAL.CREATED_AT" },
            { PROPERTY: "CREATED_BY", LABEL: "GENERAL.CREATED_BY" },
            { PROPERTY: "UPDATED_AT", LABEL: "GENERAL.UPDATED_AT" },
            { PROPERTY: "UPDATED_BY", LABEL: "GENERAL.UPDATED_BY" },
            { PROPERTY: "CORPORATE_BRANCH", LABEL: "BASIC_DATA.BRANCH" },
            { PROPERTY: "CACHE_TIME", LABEL: "GENERAL.CACHE_TIME" },
            { PROPERTY: "INTERNAL_SEQUENCE", LABEL: "REGISTRATION.INTERNAL_SEQUENCE" },
            { PROPERTY: "CODE", LABEL: "GENERAL.CODE" },
            { PROPERTY: "TYPE_CARGO", LABEL: "BASIC_DATA.CARGO_TYPE" },
            { PROPERTY: "CT_WITHOUT_DOC", LABEL: "REGISTRATION.CONTRIBUTION_WITHOUT_DOCS" },
            {
                PROPERTY: "FREE_TYPING_AMOUNT_CHARGE",
                //TODO: traduzir
            },
            {
                PROPERTY: "SANKHYA_INTEGRATION_DIRECTION",
                //TODO: traduzir
            },
            {
                PROPERTY: "APPLICATION_COMPLEMENT",
                //TODO: traduzir
            },
            {
                PROPERTY: "FREIGHT_RATES_MANAGEMENT_WEIGHT_RANGE_TIME",
                //TODO: traduzir
            },
            {
                PROPERTY: "BEST_AGGREGATION_PRE_FILTER_SCENARIO_TIME",
                //TODO: traduzir
            },
            {
                PROPERTY: "FREIGHT_RATES_MANAGEMENT_EQUIPMENT_TIME",
                //TODO: traduzir
            },
            {
                PROPERTY: "NEW_ALTERNATIVE_TIME",
                //TODO: traduzir
            },
            {
                PROPERTY: "ERP_TOP_SETUP_PARAM_TIME",
                //TODO: traduzir
            },
            {
                PROPERTY: "ERP_PROJECT_SETUP_PARAM_TIME",
                //TODO: traduzir
            },
            {
                PROPERTY: "ERP_PAYMENT_METHOD_SETUP_PARAM_TIME",
                //TODO: traduzir
            },
            {
                PROPERTY: "ERP_PARTNER_PARAM_TIME",
                //TODO: traduzir
            },
            {
                PROPERTY: "ERP_NEGOTIATION_TYPE_SETUP_PARAM_TIME",
                //TODO: traduzir
            },
            {
                PROPERTY: "ERP_NATURE_SETUP_PARAM_TIME",
                //TODO: traduzir
            },
            {
                PROPERTY: "ERP_CHARGE_NAME_PARAM_TIME",
                //TODO: traduzir
            },
            {
                PROPERTY: "BEST_AGGREGATION_SCENERY_TIME",
                //TODO: traduzir
            },
            { PROPERTY: "PERCENT", LABEL: "REGISTRATION.PERCENTAGE" },
            { PROPERTY: "ACCOUNT", LABEL: "FINANCIAL.ACCOUNT" },
            { PROPERTY: "BANK", LABEL: "ENTITY.BANK" },
            { PROPERTY: "TOP_DESP", LABEL: "GENERAL.TOP_OUTCOME" },
            { PROPERTY: "TOP_REC", LABEL: "GENERAL.TOP_INCOME" },
            { PROPERTY: "BRANCH", LABEL: "BASIC_DATA.BRANCH" },
            { PROPERTY: "COMPLEMENTARY_TARIFF_LIMIT_VALUE", LABEL: "PRODUCT.VARIATION_LIMIT_TARIFF_COMPLEMENTARY" }
        ]
        return props;
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlDataManager = this.config.productUrl + baseRoute;
        return urlDataManager;
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: product } = await this.helperService.get(`/generic/value/product`, null);
        return product && product.data ? product.data : [];
    }

    private async getEventList(): Promise<SelectorModel[]> {
        const { data: event } = await this.helperService.get(`/generic/value/event`, null);
        return event && event.data ? event.data : [];
    }

    private async getTypeCargoList(): Promise<SelectorModel[]> {
        const { data: typeCargo } = await this.helperService.get(`/generic/value/type_cargo`, null);
        return typeCargo && typeCargo.data ? typeCargo.data : [];
    }

    private async getModalityList(): Promise<SelectorModel[]> {
        const { data: modalLity } = await this.helperService.get(`/generic/value/type_payment`, null);
        return modalLity && modalLity.data ? modalLity.data : [];
    }

    private async getApplicationFlexitanqListByName(search: string): Promise<IApplicationList[]> {
        let result: IApplicationList[] = [];
        let products = ["EM", "IM"];
        let typeCargos = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();
                result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/application/list/custom`, { search, products, typeCargos }, 10000, false);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCurrencyFlexitanqListByName(search?: string): Promise<ICurrency[]> {
        let result: ICurrency[] = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();
                result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/currency/list/custom/operational`, { search }, 10000, false);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getDefaultApplicationPayment(): Promise<SelectorModel> {
        const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/application/list/custom`, { search: '', products: [], typeCargos: [], internalSequence: "020" }, 10000, false);
        if (result && result.length > 0) return result[0]
        return null
    }

    private async getDefaultApplicationReceiving(): Promise<SelectorModel> {
        const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/application/list/custom`, { search: '', products: [], typeCargos: [], internalSequence: "025" }, 10000, false);
        if (result && result.length > 0) return result[0]
        return null
    }

    private async getIntegrationDirectionList(): Promise<SelectorModel[]> {
        const { data: integrationDirection } = await this.helperService.get(`/generic/value/integration_direction`, null);
        return integrationDirection && integrationDirection.data ? integrationDirection.data : [];
    }

    private async getTablePerDiemListOrigin(search: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        this.formService.block();
        try {

            const data: ITablePerDiemQueryFields = {
                DIRECTION: "1",
                TRANSACTION: "2",
                COUNTRY_CODE: "BR",
                ID_TABLE_TYPE: null,
                DEFAULT: true,
                ACTIVE: true
            };

            const tablesPerDiem = await this.RestService.newObjectPromise(`${this.$baseUrl}/tablePerDiem/listByFullName`, { data }, 30000, false);
            if (tablesPerDiem) result = tablesPerDiem.map(tablePerDiem => { return { ID: tablePerDiem.ID, NAME: tablePerDiem.CONCATENATED, CODE: null } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
        return result;
    }

    private async getTablePerDiemListDestination(search: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        this.formService.block();
        try {
            const data: ITablePerDiemQueryFields = {
                DIRECTION: "2",
                TRANSACTION: "2",
                COUNTRY_CODE: "BR",
                ID_TABLE_TYPE: null,
                DEFAULT: true,
                ACTIVE: true
            };
            const tablesPerDiem = await this.RestService.newObjectPromise(`${this.$baseUrl}/tablePerDiem/listByFullName`, { data }, 30000, false);
            if (tablesPerDiem) result = tablesPerDiem.map(tablePerDiem => { return { ID: tablePerDiem.ID, NAME: tablePerDiem.CONCATENATED, CODE: null } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
        return result;
    }

    private addTransshipment(): void {
        try {
            if (!this.$scope.model.TRANSSHIPMENT) this.$scope.model.TRANSSHIPMENT = [];
            const transshipment: ITransshipment = {
                PRODUCT: null,
                INITIALS: null,
                NAME: null,
                NAME_INTL: null,
                EVENT_ARRIVAL: null,
                EVENT_DEPARTURE: null
            }
            this.$scope.model.TRANSSHIPMENT.push(transshipment);

            this.$timeout(() => {
                const currentIndex = this.$scope.model.TRANSSHIPMENT.length - 1;
                this.$scope.selectorValidity('transshipmentProduct' + currentIndex);
                this.$scope.selectorValidity('transshipmentArrivalEvent' + currentIndex);
                this.$scope.selectorValidity('transshipmentExitEvent' + currentIndex);
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeTransshipment(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.CLOSE',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate('GENERAL.MESSAGES.CONFIRMATION.REMOVAL')
            });
            if (!modal) return;

            if (this.$scope.model.TRANSSHIPMENT && this.$scope.model.TRANSSHIPMENT.length > 0) {
                this.formService.block();
                this.$scope.model.TRANSSHIPMENT.splice(index, 1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private addSecure(): void {
        try {
            if (!this.$scope.model.SECURE) this.$scope.model.SECURE = [];
            const secure: any = {
                _id: null,
                ID: null,
                ID_PRODUCT_CONFIGURATION: null,
                PRODUCT: null,
                ID_CHARGE_NAME_EXHIBITION: null,
                CHARGE_NAME_EXHIBITION: null,
                INSURANCE_PREMIUM: 0.50,
                INSURANCE_RECEIVING_MIN: null,
                STANDARD_COMISSION_WITHOUT_PRIZE: 40,
                INSURANCE_PAYMENT_MIN: null,
                ID_INSURANCE_BROKER: null,
                INSURANCE_BROKER: null,
                APPLICATION_PAYMENT: this.$scope.defaultApplicationPayment,
                APPLICATION_RECEIVING: this.$scope.defaultApplicationReceiving

            }

            this.$scope.model.SECURE.push(secure);

            this.$timeout(() => {
                const currentIndex = this.$scope.model.SECURE.length - 1;
                this.$scope.selectorValidity('secureChargeName' + currentIndex);
                this.$scope.selectorValidity('secureInsuranceBroker' + currentIndex);
                this.$scope.selectorValidity('secureApplicationPayment' + currentIndex);
                this.$scope.selectorValidity('secureApplicationReceiving' + currentIndex);
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeSecure(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.CLOSE',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate('GENERAL.MESSAGES.CONFIRMATION.REMOVAL')
            });
            if (!modal) return;

            if (this.$scope.model.SECURE && this.$scope.model.SECURE.length > 0) {
                this.formService.block();
                this.$scope.model.SECURE.splice(index, 1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private addApplication(): void {
        try {
            if (!this.$scope.model.PROFIT_SHARE_APPLICATION) this.$scope.model.PROFIT_SHARE_APPLICATION = [];
            const application: IProfitShareApplication = {
                PRODUCT: null,
                TYPE_CARGO: null,
                APPLICATION: null
            }

            this.$scope.model.PROFIT_SHARE_APPLICATION.push(application);

            this.$timeout(() => {
                const currentIndex = this.$scope.model.PROFIT_SHARE_APPLICATION.length - 1;
                this.$scope.selectorValidity('applicationProduct' + currentIndex);
                this.$scope.selectorValidity('applicationTypeCargo' + currentIndex);
                this.$scope.selectorValidity('applicationApplication' + currentIndex);
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeApplication(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.CLOSE',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate('GENERAL.MESSAGES.CONFIRMATION.REMOVAL')
            });
            if (!modal) return;

            if (this.$scope.model.PROFIT_SHARE_APPLICATION && this.$scope.model.PROFIT_SHARE_APPLICATION.length > 0) {
                this.formService.block();
                this.$scope.model.PROFIT_SHARE_APPLICATION.splice(index, 1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private addCoporationClient(): void {
        try {
            if (!this.$scope.model.SANKHYA_INTEGRATION_DIRECTION.BRANCH) this.$scope.model.SANKHYA_INTEGRATION_DIRECTION.BRANCH = [];
            const corporativeClientSankhya: ICorporativeClientSankhya = {
                ACCOUNT: null,
                CORPORATE_BRANCH: null
            }
            this.$scope.model.SANKHYA_INTEGRATION_DIRECTION.BRANCH.push(corporativeClientSankhya);
            this.$timeout(() => {
                const currentIndex = this.$scope.model.SANKHYA_INTEGRATION_DIRECTION.BRANCH.length - 1;
                this.$scope.selectorValidity('corporateBranch' + currentIndex);
                this.$scope.selectorValidity('client' + currentIndex);
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeCoporationClient(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.CLOSE',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate('GENERAL.MESSAGES.CONFIRMATION.REMOVAL')
            });
            if (!modal) return;

            if (this.$scope.model.SANKHYA_INTEGRATION_DIRECTION.BRANCH && this.$scope.model.SANKHYA_INTEGRATION_DIRECTION.BRANCH.length > 0) {
                this.formService.block();
                this.$scope.model.SANKHYA_INTEGRATION_DIRECTION.BRANCH.splice(index, 1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getLegalPersonListByName(legalPersonFilter?: ILegalPersonListCustomFilter): Promise<ILegalPersonSelector[]> {
        let result = [];
        this.formService.block();
        try {
            const legalPersons = await this.RestService.newObjectPromise(`${this.$baseUrl}/legalPerson/list/custom`, legalPersonFilter, 30000, false);
            if (legalPersons) result = legalPersons.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.NAME, CODE: legalPerson.CODE, SHORT_NAME: legalPerson.SHORT_NAME, CORPORATE_NAME: legalPerson.CORPORATE_NAME, ID_FISCAL: legalPerson.ID_FISCAL } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCorporateBranchListByName(search?: string): Promise<ILegalPersonSelector[]> {
        let result = [];
        this.formService.block();
        try {
            const corporateBranchs = await this.ProductService.post({ route: `/corporateBranch/list/custom`, data: { search: search } });
            if (corporateBranchs && corporateBranchs.data && corporateBranchs.data.data) result = corporateBranchs.data.data.map(corporateBranch => { return { ID: corporateBranch.ID, NAME: corporateBranch.NAME, CODE: corporateBranch.CODE } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getChargeNameListByName(filter: IChargeNameListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const charges = await this.RestService.newObjectPromise(`${this.$baseUrl}/chargeName/list/custom/exhibition`, filter, 30000, false);
            if (charges) result = charges.map(charge => { return { ID: charge.ID, NAME: charge.NAME, CODE: charge.CODE } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getAccountListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 3) {
                this.formService.block();
                const accounts = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/account/list/custom/`, { search: search }, 30000, false);
                if (accounts && accounts.length > 0) {
                    result = accounts.map(account => { return { ID: account.ID.toString(), NAME: account.NAME, CODE: account.LEGAL_PERSON.SHORT_NAME } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.accountList = result
            this.formService.unblock();
        }
    }

    private async getApplicationListByName(product: SelectorModel[], typeCargo: SelectorModel[], search: string): Promise<void> {
        let result = [];
        let products = [];
        let typeCargos = [];
        try {
            if (search && search.length >= 2) {
                if (!product) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                if (!typeCargo) throw Error(this.formService.getTranslate('PRODUCT.SELECT_CARGO_TYPE_FIRST'));
                this.block();
                products = product.map(item => item.ID);
                typeCargos = typeCargo.map(item => item.ID);
                const application = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/application/list/custom`, { search, products, typeCargos }, 10000, false);
                if (application) {
                    result = application.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PERCENT: x.PERCENT, APPLICATION_COMPLEMENT: x.APPLICATION_COMPLEMENT, INTERNAL_SEQUENCE: x.INTERNAL_SEQUENCE, FREE_TYPING_AMOUNT_CHARGE: x.FREE_TYPING_AMOUNT_CHARGE, CT_WITHOUT_DOC: x.CT_WITHOUT_DOC, NAME_INTL: x.NAME_INTL } });
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            this.$scope.applicationList = result;
        }
    }

    private getUrlQuotation(): string {
        const baseRoute = '/quotation';
        return this.config.quotationUrl + baseRoute;
    }

    private getUrlQuotationCombined(): string {
        const baseRoute = '/quotationCombined';
        return this.config.quotationCombinedUrl + baseRoute;
    }

    private async getProcessToSync(search: string): Promise<void> {
        try {
            if (search && search.length >= 5) {
                this.block();

                const timeout = 120000;

                const request: IMonacoRequest = {
                    data: {
                        term: search,
                        timeout
                    },
                    route: "/process/selector",
                    timeout,
                };

                const rc: IHttpResponse<IHttpResponse<ISelectorModel[]>> = await this.OperationalService.post(request.route, request.data, request.timeout);
                this.$scope.processList = (rc && rc.data && rc.data.data) ? rc.data.data : [];
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async updateProcessToSync(processToSync: string): Promise<void> {
        this.$scope.processToSync = processToSync;
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.SSEService.closeEvents();
            this.addSecure();
            this.addApplication();
            this.addTransshipment();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            BrowserTitle.$id = this.$scope.model.NAME;
            this.$scope.scopeBeforeSave = null;
            this.SSEService.closeEvents();
            await this.clearFields(this.$scope.model);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            BrowserTitle.$id = this.$scope.model.NAME;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            BrowserTitle.$id = this.$scope.model.NAME;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === "edit" ? "update" : "insert";

        return {
            route: `/productConfiguration/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: this.$scope.scopeBeforeSave
        };
    }

    private async clearCache(searchString: string[]) {
        const modal = await this.ModalService.showModalConfirmation({}, {
            actionButtonText: 'GENERAL.CONFIRM',
            closeButtonText: 'GENERAL.CLOSE',
            headerText: 'GENERAL.CONFIRM_ACTION',
            bodyText: this.formService.getTranslate('GENERAL.DO_YOU_WANT_TO_CLEAR_THE_NAMESPACE_CACHE?', { prop: searchString })
        });
        if (!modal) return;

        const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/productConfiguration/clearCache`, { clearCache: searchString }, 10000, false);
        if (!isNaN(parseInt(result))) {
            const msgSuccess = this.formService.getTranslate('GENERAL.NAMESPACE_SUCCESSFULLY_DELETED', { prop: searchString });
            return this.$formService.notifySuccess(msgSuccess);
        } else {
            const msgInfo = this.formService.getTranslate('GENERAL.NO_DATA_TO_BE_DELETED');
            return this.$formService.notifyInfo(msgInfo);
        }
    }

    private async clearData(identifier: string) {
        const days = this.$scope.model.QTD_DAYS_CLEANING_LOG;

        const modal = await this.ModalService.showModalConfirmation({}, {
            actionButtonText: 'GENERAL.CONFIRM',
            closeButtonText: 'GENERAL.CLOSE',
            headerText: 'GENERAL.CONFIRM_ACTION',
            bodyText: this.formService.getTranslate('GENERAL.ARE_YOU_SURE_YOU_WANT_TO_CLEAR_OLD_DATA?')
        });
        if (!modal) return;

        await this.dataProductService.post('/productConfiguration/clearData', { identifier, days }, 10000);
        const msgSuccess = this.formService.getTranslate('GENERAL.OLD_DATA_SUCCESSFULLY_DELETED_SENT');
        return this.$formService.notifySuccess(msgSuccess);
    }

    private async clearAllCache() {
        const modal = await this.ModalService.showModalConfirmation({}, {
            actionButtonText: 'GENERAL.CONFIRM',
            closeButtonText: 'GENERAL.CLOSE',
            headerText: 'GENERAL.CONFIRM_ACTION',
            bodyText: this.formService.getTranslate('GENERAL.ARE_YOU_SURE_YOU_WANT_TO_CLEAR_THE_ENTIRE_CACHE?')
        });
        if (!modal) return;

        const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/productConfiguration/clearAllCache`, {}, 10000, false);
        if (!isNaN(parseInt(result))) {
            const msgSuccess = this.formService.getTranslate('GENERAL.SYSTEM_CACHE_SUCCESSFULLY_DELETED');
            return this.$formService.notifySuccess(msgSuccess);
        } else {
            const msgInfo = this.formService.getTranslate('GENERAL.NO_DATA_TO_BE_DELETED');
            return this.$formService.notifyInfo(msgInfo);
        }
    }

    private async sendSyncFull(entityToSync: string) {
        try {
            const entitiesWithParams = ["offerCompiled", "offerUsedProcess", "tariffFreight", "tariffFreightRates"];
            let body = {};
            let entity = {
                contract: 'GENERAL.OF_CONTRACTS',
                offerCompiled: 'GENERAL.OF_OFFERS',
                currentAccountCompiled: 'GENERAL.OF_CHECKING_ACCOUNTS',
                uniqueTermCompiled: 'GENERAL.OF_UNIQUE_TERMS',
                user: 'GENERAL.OF_USERS',
                generic: 'GENERAL.OF_GENERIC_RECORDS',
                offerUsedProcess: 'GENERAL.OF_FILES',
                syncCurrencies: 'GENERAL.OF_CURRENCIES',
                processResultCenter: 'GENERAL.OF_FILE_RESULTS_CENTERS',
                invoiceStatus: 'GENERAL.OF_INVOICES',
                legalPerson: 'GENERAL.OF_LEGAL_PERSON',
                legalPersonStatus: 'GENERAL.OF_THE_STATUS_OF_LEGAL_PERSON_WITH_ERP',
                physicalPerson: 'GENERAL.OF_INDIVIDUALS_WITH_ERP',
                physicalPersonStatus: 'GENERAL.OF_THE_STATUS_OF_INDIVIDUALS_WITH_ERP',
                chargeName: 'GENERAL.OF_LOGISTICS_CHARGES_WITH_ERP',
                chargeNameStatus: 'GENERAL.OF_THE_STATUS_OF_LOGISTICS_CHARGES_WITH_ERP',
                tariffFreight: 'GENERAL.OF_FREIGHT_TARIFFS',
                tariffFreightRates: 'GENERAL.OF_FREIGHT_TARIFFS',
                integrationAgentProcess: "GENERAL.OF_AGENTS",
                processReplication: "da Replicação dos Processos",
                processDetDemManagement: 'GENERAL.PROCESS_DET_DEM_MANAGEMENT_GENERATE',
                holiday: 'GENERAL.MENU.HOLIDAY',
                currency: 'GENERAL.MENU.CURRENCY',
                country: 'GENERAL.MENU.COUNTRY',
                tradeLane: 'GENERAL.MENU.TRADE_LANE',
                city: 'GENERAL.MENU.CITY',
                province: 'GENERAL.MENU.PROVINCE',
                application: 'GENERAL.MENU.APPLICATION',
                logisticCharge: 'GENERAL.MENU.LOGISTIC_CHARGES',
                physicalPersonFatturo: 'GENERAL.MENU.PHYSICAL_PERSON',
                incoterm: 'GENERAL.MENU.INCOTERM',
                networkFatturo: 'GENERAL.MENU.NETWORK',
                legalPersonFatturo: 'GENERAL.MENU.LEGAL_PERSON',
                routingPoint: 'GENERAL.MENU.ROUTING_POINT',
                bank: 'GENERAL.MENU.BANK',
                masterBillingSettings: 'GENERAL.BILLING_SETTINGS',
                legalPersonAccoRequ: 'GENERAL.CLIENT_REQUIREMENT',
                equipment: 'GENERAL.EQUIPMENT',
                weightRange: 'GENERAL.MENU.WEIGHT_RANGE',
                processDetDemFatturo: 'GENERAL.MENU.DETENTION_DEMURRAGE_PROCESS',
                event: "GENERAL.MENU.EVENT",
                leadLegalPersonSync: "GENERAL.LEGAL_PERSON_SYNC_LEAD_MODAL",
                leadPhysicalPersonSync: "GENERAL.PHYSICAL_PERSON_SYNC_LEAD_MODAL",
                leadGenericSync: "GENERAL.GENERIC_SYNC_LEAD_MODAL",
                leadBrokerSync: "GENERAL.BROKER_SYNC_LEAD_MODAL",
                leadIncotermSync: "GENERAL.INCOTERM_SYNC_LEAD_MODAL",
                leadAccountSync: "GENERAL.ACCOUNT_SYNC_LEAD_MODAL",
                myallogProcessSync: "GENERAL.PROCESS_SYNC_MYALLOG_MODAL",
                myallogProcessSyncSingle: "GENERAL.SINGLE_PROCESS_SYNC_MYALLOG_MODAL",
                myallogLegalPersonsSync: "GENERAL.LEGAL_PERSON_SYNC_MYALLOG_MODAL",
                newFreightContractCommodity: "PRODUCT.FREIGHT_CONTRACT_COMMODITY_MODAL"
            }

            const integrationParams = this.$scope.productConfigurationIntegrationParams;

            if (entitiesWithParams.indexOf(entityToSync) >= 0) {
                const modalParamsId = this.ModalService.newModal();
                integrationParams.currentEntity = entityToSync;
                const modal = await this.ModalService.showModalConfirmation(
                    {
                        modalID: modalParamsId,
                        formService: 'register',
                        size: 'lg',
                        scope: this.$scope,
                        template: require('../view/modals/productConfigurationParamsModal.html')
                    }, {
                    actionButtonText: 'GENERAL.CONFIRM',
                    closeButtonText: 'GENERAL.CLOSE',
                    // headerText: `Informe os parâmetros para a integração ${entity[entityToSync]}`
                    headerText: this.formService.getTranslate('GENERAL.ENTER_THE_PARAMETERS_FOR_THE_INTEGRATION', { prop: this.formService.getTranslate(entity[entityToSync]) })
                });
                if (!modal) return;
            } else {
                const modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: 'GENERAL.CONFIRM',
                    closeButtonText: 'GENERAL.CLOSE',
                    headerText: 'GENERAL.CONFIRM_ACTION',
                    bodyText: this.formService.getTranslate('GENERAL.ARE_YOU_SURE_YOU_WANT_TO_RUN_THE_FULL_INTEGRATION?', { prop: this.formService.getTranslate(entity[entityToSync]) })
                });
                if (!modal) return;
            }

            if (entityToSync == 'offerUsedProcess' && integrationParams.operationalProduct.daysAgoProcess) {
                body = { "daysAgo": integrationParams.operationalProduct.daysAgoProcess };
            } else if (entityToSync == 'offerCompiled') {
                body = {
                    start: integrationParams.operationalProduct.offerSyncStart ? integrationParams.operationalProduct.offerSyncStart : null,
                    end: integrationParams.operationalProduct.offerSyncEnd ? integrationParams.operationalProduct.offerSyncEnd : null
                };
            } else if (entityToSync == 'tariffFreight') {
                body = {
                    start: integrationParams.operationalProduct.tariffFreightSyncStart ? integrationParams.operationalProduct.tariffFreightSyncStart : null,
                    end: integrationParams.operationalProduct.tariffFreightSyncEnd ? integrationParams.operationalProduct.tariffFreightSyncEnd : null
                };
            } else if (entityToSync == 'tariffFreightRates') {
                body = {
                    start: integrationParams.operationalProduct.tariffFreightSyncStart ? integrationParams.operationalProduct.tariffFreightSyncStart : null,
                    end: integrationParams.operationalProduct.tariffFreightSyncEnd ? integrationParams.operationalProduct.tariffFreightSyncEnd : null
                };
            }

            this.$formService.notifyInfo(this.formService.getTranslate('GENERAL.CALLING_ROUTE_FOR_INTEGRATION', { prop: this.formService.getTranslate(entity[entityToSync]) }));
            // Product -> Operational
            const arrayToSyncProduct: string[] = ["contract", "offerCompiled", "currentAccountCompiled", "uniqueTermCompiled", "country", "tradeLane", "currency", "city", "province", "application", "incoterm", "logisticCharge", "routingPoint", "bank", "masterBillingSettings", "legalPersonAccoRequ", "equipment", "weightRange", "newFreightContractCommodity"];
            if (arrayToSyncProduct.indexOf(entityToSync) != -1) {
                await this.dataProductService.post(`/sync/${entityToSync}`, body, 120000);
            }

            // Product -> Fatturo
            const arrayToSyncFatturo: string[] = ["physicalPersonFatturo", "networkFatturo", "legalPersonFatturo"];
            if (arrayToSyncFatturo.indexOf(entityToSync) != -1) {
                await this.dataProductService.post(`/sync/${entityToSync}`, body, 120000);
            }

            //Data-Product
            const arrayToSyncDataProduct: string[] = ["legalPerson", "physicalPerson", "chargeName"];
            if (arrayToSyncDataProduct.indexOf(entityToSync) != -1) {
                await this.dataProductService.post(`/sync/sankhya/${entityToSync}`, body, 120000);
            }

            //Data-Product Status
            const arrayToSyncDataProductStatus: string[] = ["legalPersonStatus", "physicalPersonStatus", "chargeNameStatus"];
            if (arrayToSyncDataProductStatus.indexOf(entityToSync) != -1) {
                const entityWithoutStatusToSync = entityToSync.replace('Status', '')
                await this.dataProductService.post(`/sync/sankhya/${entityWithoutStatusToSync}/status`, body, 120000);
            }

            const arrayToSyncAgent: string[] = ["integrationAgentProcess"];
            if (arrayToSyncAgent.indexOf(entityToSync) != -1) {
                // await this.dataProductService.post({ data: [], route: `/sync/${entityToSync}`, timeout: 120000});
                await this.RestService.newObjectPromise(`${this.$baseUrl}/agent/${entityToSync}`, {}, 120000, false);
            }

            // Operational -> Product
            const arrayToSyncOperational: string[] = ["user", "generic", "offerUsedProcess", "userGroup", "userRoles", "holiday", "event"];
            if (arrayToSyncOperational.indexOf(entityToSync) != -1) {
                await this.dataOperationalService.post(`/sync/${entityToSync}`, body, 120000);
            }

            //Data Operational => Sankhya
            const arrayToSyncDataOperational: string[] = ["processResultCenter"];
            if (arrayToSyncDataOperational.indexOf(entityToSync) != -1) {
                await this.dataOperationalService.post(`/sync/sankhya/${entityToSync}`, body, 120000);
            }

            // Quotation
            const arrayToSyncQuotationFreightRatesManagement: string[] = ["tariffFreight"];
            if (arrayToSyncQuotationFreightRatesManagement.indexOf(entityToSync) != -1) {
                await this.RestService.newObjectPromise(`${this.quotationUrl}/freightRatesManagement/sync/${entityToSync}`, body, 120000, false);
            }

            // Quotation Combined
            const arrayToSyncQuotationCombinedFreightRatesManagement: string[] = ["tariffFreightRates"];
            if (arrayToSyncQuotationCombinedFreightRatesManagement.indexOf(entityToSync) != -1) {
                await this.RestService.newObjectPromise(`${this.quotationCombinedUrl}/tariffFreightRatesManagement/sync/${entityToSync}`, body, 120000, false);
            }

            const arrayToSyncQuotationCurrency: string[] = ["syncCurrencies"];
            if (arrayToSyncQuotationCurrency.indexOf(entityToSync) != -1) {
                await this.RestService.newObjectPromise(`${this.quotationUrl}/currency/${entityToSync}`, {}, 120000, false);
            }

            //FINOP
            const arrayToSyncFinop: string[] = ["invoiceStatus"];
            if (arrayToSyncFinop.indexOf(entityToSync) != -1) {
                await this.finopService.post({ data: [], route: `/sync/${entityToSync}`, timeout: 120000 });
            }

            //Data Process
            const arrayToSyncDataProcess: string[] = ["processReplication"];
            if (arrayToSyncDataProcess.indexOf(entityToSync) != -1) {
                await this.$scope.dataProcessService.post<any>(`/processReplication/allProcess/`, null, 120000);
            }

            const arrayToProcessDataProcess: string[] = ["processDetDemManagement"];
            if (arrayToProcessDataProcess.indexOf(entityToSync) != -1) {
                await this.$scope.dataProcessService.post<any>(`/processDetDemManagement/generate/`, null, 120000);
            }

            const arrayToSyncProcessDetDem: string[] = ["processDetDemFatturo"];
            if (arrayToSyncProcessDetDem.indexOf(entityToSync) != -1) {
                await this.$scope.dataProcessService.post<any>(`/processDetDem/sendSync/`, null, 120000);
            }

            // Lead
            const arrayToSyncLeadLegalPerson: string[] = ["leadLegalPersonSync"];
            if (arrayToSyncLeadLegalPerson.indexOf(entityToSync) != -1) {
                await this.dataProductService.post(`/sync/lead/legalPerson`, null, 120000);
            }

            // Lead
            const arrayToSyncLeadPhysicalPerson: string[] = ["leadPhysicalPersonSync"];
            if (arrayToSyncLeadPhysicalPerson.indexOf(entityToSync) != -1) {
                await this.dataProductService.post(`/sync/lead/physicalPerson`, null, 120000);
            }

            // Lead
            const arrayToSyncLeadCommodity: string[] = ["leadGenericSync"];
            if (arrayToSyncLeadCommodity.indexOf(entityToSync) != -1) {
                await this.dataProductService.post(`/sync/lead/generic`, null, 120000);
            }

            // Lead
            const arrayToSyncLeadBroker: string[] = ["leadBrokerSync"];
            if (arrayToSyncLeadBroker.indexOf(entityToSync) != -1) {
                await this.dataProductService.post(`/sync/lead/broker`, null, 120000);
            }

            // Lead
            const arrayToSyncLeadIncoterm: string[] = ["leadIncotermSync"];
            if (arrayToSyncLeadIncoterm.indexOf(entityToSync) != -1) {
                await this.dataProductService.post(`/sync/lead/incoterm`, null, 120000);
            }

            // Lead
            const arrayToSyncLeadAccount: string[] = ["leadAccountSync"];
            if (arrayToSyncLeadAccount.indexOf(entityToSync) != -1) {
                await this.dataProductService.post(`/sync/lead/account`, null, 120000);
            }

            // MyAllog
            const arrayToSyncMyAllogProduct: string[] = ["myallogLegalPersonsSync"];
            if (arrayToSyncMyAllogProduct.indexOf(entityToSync) != -1) {
                await this.dataProductService.post<any>(`/sync/myAllog/legalPerson`, null, 120000);
            }

            // MyAllog
            const arrayToSyncMyAllogOperational: string[] = ["myallogProcessSync"];
            if (arrayToSyncMyAllogOperational.indexOf(entityToSync) != -1) {
                await this.dataOperationalService.post<any>(`/sync/myAllog/process`, null, 120000);
            }

            // MyAllog
            const arrayToSyncOneProcessMyAllogOperational: string[] = ["myallogProcessSyncSingle"];
            if (arrayToSyncOneProcessMyAllogOperational.indexOf(entityToSync) != -1) {
                await this.dataOperationalService.post<any>(`/sync/myAllog/process`, { processNumber: this.$scope.processToSync }, 120000);
            }


            const msgInfo = this.formService.getTranslate('GENERAL.INTEGRATION_COMPLETED');
            this.$formService.notifySuccess(msgInfo);
        } catch (ex) {
            const stringifyMsg = JSON.stringify(ex);
            const msgError = this.formService.getTranslate('GENERAL.ERROR_SENDING_INTEGRATION');
            const msg = `${msgError}: ${stringifyMsg == "{}" ? ex : stringifyMsg}`;
            this.$formService.notifyError(msg);
        }
    }
}
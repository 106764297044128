import * as angular from "angular";
import { IColumnDef } from "ui-grid";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IMonacoColumnDef } from '@services/GridService2';
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { IFloatingMenu } from "../../common/interface/IFloatingMenu";
import { IRestService } from '@services/RestService';
import { BrowserTitle } from "../../common/BrowserTitle";
import { ICustomLogProperties } from "WBA-Model/dist/interface/common/IViewLog";
import { IGridSpreadsheet } from "WBA-Model/dist/interface/product/GridSpreadsheet";
import { SelectorModel } from "../../common/model/SelectorModel";
import { SSEService } from "../../app/services/SSEService";
import { ISessionService } from "@services/SessionService";
import { EOperation } from "@enums/GenericData";

interface IGridSpreadsheetScope extends IGridFormServiceScope {
    model: IGridSpreadsheet;
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IGridSpreadsheet;
    menuFloating: IFloatingMenu;
    reportList: SelectorModel[];
    user: any;
    sessionService: ISessionService;
    editGridSpreadsheet: (gridSpreadsheet: IGridSpreadsheet) => Promise<void>;
    viewGridSpreadsheet: (gridSpreadsheet: IGridSpreadsheet) => Promise<void>;
    viewLogGridSpreadsheet: (gridSpreadsheet: IGridSpreadsheet) => Promise<void>;
    copyGridSpreadsheet: (gridSpreadsheet: IGridSpreadsheet) => Promise<void>;
}

export class GridSpreadsheetController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IGridSpreadsheetScope;
    private $q: ng.IQService;
    private restService: IRestService;
    private SSEService: SSEService;
    private gridName: string;

    constructor($injector: ng.Injectable<any>, $scope: IGridSpreadsheetScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.restService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope);
        this.gridName = 'GRID_SPREADSHEET';
    }

    getUrlProduct() {
        try {
            const baseRoute = '/product';
            const urlProduct = this.config.productUrl + baseRoute;
            return urlProduct;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'gridSpreadsheet', 'GENERAL.MENU.TEMPLATES', true);
            await this.initGrid(this.gridName, "/gridSpreadsheet/list", true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            throw ex;
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initModel(): void {
        try {
            this.$scope.model = {
                _id: null,
                ID: null,
                GRID_NAME: null,
                DISPLAY_NAME: null,
                TEMPLATE: null,
                DEFAULT: null,
                ACTIVE: null,
                CREATED_BY: null,
                CREATED_AT: null,
                UPDATED_BY: null,
                UPDATED_AT: null
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async initDependencies(): Promise<boolean> {
        try {
            const result: Array<any> = await this.$q.all([
                this.getReportList(),
            ]);

            if (!result || result.length === 0) return false;
            this.$scope.reportList = result[0].data;
            const msgError = this.formService.getTranslate('GENERAL.OBJECT_NOT_DEFINED');
            if (typeof this.$scope.reportList != "object") this.handleLoadError(msgError);
            return true;
        } catch (ex) {
            const msgError = this.formService.getTranslate('GENERAL.DEPENDENCY_LIST_FAILED');
            this.handleLoadError(`${msgError} ${JSON.stringify(ex)}`);
        }
    }

    initScopeFunctions() {

        this.$scope.editGridSpreadsheet = async (gridSpreadsheet: IGridSpreadsheet): Promise<void> => {
            let blockedObject = {
                ID: gridSpreadsheet.ID,
                NAME: gridSpreadsheet.TEMPLATE.NAME,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || gridSpreadsheet.ID !== this.$scope.model.ID) this.$scope.view(gridSpreadsheet);
                } else if (this.$scope.operation !== EOperation.EDIT || gridSpreadsheet.ID !== this.$scope.model.ID) this.$scope.edit(gridSpreadsheet);
            };
        }

        this.$scope.viewGridSpreadsheet = async (gridSpreadsheet: IGridSpreadsheet): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(gridSpreadsheet);
        }

        this.$scope.viewLogGridSpreadsheet = async (gridSpreadsheet: IGridSpreadsheet): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(gridSpreadsheet);
        }

        this.$scope.copyGridSpreadsheet = async (gridSpreadsheet: IGridSpreadsheet): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(gridSpreadsheet);
        }

    }

    initGridColumns(columns: string[]): IColumnDef[] {
        try {
            const gridColumns = new GridColumnBuilder([]);

            const view = `<a ng-click="grid.appScope.viewGridSpreadsheet(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
            const edit = `<a ng-click="grid.appScope.editGridSpreadsheet(row.entity)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
            const copy = `<a ng-click="grid.appScope.copyGridSpreadsheet(row.entity)" class="text-orange copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
            const viewLog = `<a ng-click="grid.appScope.viewLogGridSpreadsheet(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;

            const colActions: IMonacoColumnDef = {
                name: "acoes",
                displayName: "GENERAL.ACTIONS",
                width: 120,
                cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${copy} ${viewLog}</div>`,
                enableFiltering: false,
                enableSorting: false,
                enableHiding: false,
                enableColumnMoving: false,
                enableColumnResizing: false,
                pinnedLeft: true,
                enablePinning: false
            };

            gridColumns.addColumn(colActions);
            const newColumnDefs: IMonacoColumnDef[] = this.buildColumns(columns);
            for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) };

            return <IColumnDef[]>gridColumns.$columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colGridName: IMonacoColumnDef = { name: "GRID_NAME", displayName: "GENERAL.GRID_NAME", width: 150 };
            const colDisplayName: IMonacoColumnDef = { name: "DISPLAY_NAME", displayName: "BASIC_DATA.DISPLAY", width: 140 };
            const colTemplate: IMonacoColumnDef = { name: "TEMPLATE.NAME", displayName: "GENERAL.GRID_TEMPLATE", width: 130 };
            const colDefault: IMonacoColumnDef = { name: "DEFAULT", displayName: "GENERAL.DEFAULT", width: 80, cellFilter: "YesOrNo" };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 80, cellFilter: "YesOrNo" };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 70 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'GRID_NAME':
                        columnDefs.push(colGridName);
                        break;
                    case 'DISPLAY_NAME':
                        columnDefs.push(colDisplayName);
                        break;
                    case 'TEMPLATE':
                        columnDefs.push(colTemplate);
                        break;
                    case 'DEFAULT':
                        columnDefs.push(colDefault);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")} - ${this.$scope.model.GRID_NAME}`;
            BrowserTitle.$id = this.$scope.model.GRID_NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")} (${this.$scope.model.GRID_NAME})`;
            BrowserTitle.$id = this.$scope.model.GRID_NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === 'register' ? 'insert' : 'update';
        return {
            route: `/gridSpreadsheet/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }


    private async getReportList(): Promise<SelectorModel> {
        return this.restService.getObjectAsPromise("/reportTemplate/report/list", 120000);
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'BASIC_DATA.BRANCH'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            },
            {
                PROPERTY: 'DEFAULT',
                LABEL: 'REGISTRATION.STANDARD'
            },
            {
                PROPERTY: 'DISPLAY_NAME',
                LABEL: 'BASIC_DATA.DISPLAY'
            },
            {
                PROPERTY: 'GRID_NAME',
                LABEL: 'GENERAL.GRID_TEMPLATE'
            },
            {
                PROPERTY: 'TEMPLATE',
                LABEL: 'REGISTRATION.TEMPLATE'
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
        ];
        return props;
    }
}
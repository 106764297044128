import * as angular from 'angular';
import { IColumnDef, IGridOptions } from "ui-grid";
import { GridFormService, IGridFormController, IGridFormServiceScope } from "@services/GridFormService";
import { GridService2 } from "@services/GridService2";
import { IModalService } from "@services/ModalService";
import { IRestService } from "@services/RestService";
import { DataOperationalService } from "@services/DataOperationalService";
import { IDocumentError } from '@models/interface/common/IDocumentError';
import { UserRolesModel } from "@models/interface/product/UserRolesModel";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { HelperService } from '@services/HelperService';

interface IUserRolesScope extends IGridFormServiceScope {
    model: UserRolesModel;
    gridOptions: IGridOptions;
    openModalIntegration: (id: string, documentError: IDocumentError[]) => void;
}

export class UserRolesRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ["$injector", "$scope", "$element"];
    private $scope: IUserRolesScope;
    private $timeout: ng.ITimeoutService;
    private ModalService: IModalService;
    private RestService: IRestService;
    private helpersService: HelperService;
    private modalID: number;
    private dataOperationalService: DataOperationalService;

    constructor($injector: ng.Injectable<any>, $scope: IUserRolesScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$scope.template = 'gridTemplate';
        this.gridService = new GridService2($injector, $scope);
        this.$timeout = $injector.get("$timeout");
        this.RestService = $injector.get("RestService");
        this.ModalService = $injector.get("ModalService");
        this.helpersService = $injector.get('HelperService');
        this.dataOperationalService = $injector.get('DataOperationalService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlHelper();

            this.initForm(this, "form", "userRoles", 'GENERAL.USER_ACESS_ROLES', true);
            await this.initGrid("userRoles", "/userRoles/list", true, true, 120000, true, true);
        } catch (ex) {
            throw ex;
        }
    }

    $onDestroy(): void {
    }

    initGridColumns(columns) {
        const gridColumns = new GridColumnBuilder([]);

        const columnDefs: Array<IColumnDef> = gridColumns.$columnDefs;
        const shield = `<div class="text-center"><span><a class="text-green" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.MENU.ACCESS_RULES' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-shield" aria-hidden="true"></i></span></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity._id, row.entity.DOCUMENT_ERROR)" ng-class="(row.entity.DOCUMENT_ERROR && row.entity.DOCUMENT_ERROR.length) ? 'text-danger' : 'text-green'" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: '5%',
            cellTemplate: (shield + modalIntegration),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        for (let i = 0; i < columns.length; i++) {
            let name = null;
            let displayName = null;
            let width = 0;
            let cellTemplate = undefined;
            let cellFilter = null;
            let visible = true;
            let filter: uiGrid.IFilterOptions = { condition: this.gridService.filterSelectObject };
            let searchProps = null;
            let filterCellFiltered = null;

            switch (columns[i].toUpperCase()) {
                case "ROLE":
                    name = columns[i];
                    displayName = "GENERAL.MENU.ACCESS_RULES";
                    width = 10;
                    break;
                case "MODULE":
                    name = columns[i] + ".NAME";
                    displayName = "REGISTRATION.MODULE";
                    width = 10;
                    break;
                case "ACTION":
                    name = columns[i] + ".NAME";
                    displayName = "GENERAL.ACTIONS";
                    width = 10;
                    break;
                case "DESCRIPTION":
                    name = columns[i];
                    displayName = "GENERAL.DESCRIPTION";
                    width = 30;
                    break;
                default:
                    continue;
            }

            const newColumn = {
                name: name,
                displayName: displayName,
                headerCellClass: this.gridService.highlightFilteredHeader.bind(this.gridService),
                width: width + "%",
                cellTemplate: cellTemplate,
                cellFilter: cellFilter,
                visible: visible,
                filter: filter,
                searchProps: searchProps,
                filterCellFiltered: filterCellFiltered,
            };
            columnDefs.push(newColumn);
        }
        return columnDefs;
    }

    initScopeFunctions(): void {
        this.$scope.openModalIntegration = (id: string, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ROLE: null,
            DESCRIPTION: null,
            MODULE: null,
            ACTION: null,
            ROUTE: null,
            CRITERIA: null,
            DOCUMENT_ERROR: null
        }
    }

    private getUrlHelper(): string {
        const baseRoute = '/helper';
        const urlHelper = this.config.helperUrl + baseRoute;

        return urlHelper;
    }

    private async openModalIntegration(id: string, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance(
                {
                    modalID: this.modalID, 
                    integrationId: id, 
                    documentErrorList: 
                    documentErrorList, 
                    fnSync: this.sendSync, 
                    fnUpdateIntegrationGrid: this.updateIntegrationGrid, 
                    headerText: "Integration Operation/Product" 
                }
            );
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                const rc = await this.dataOperationalService.post(`/sync/userRoles`, { "id": [id] }, 120000);
                if (rc && rc.data && rc.data.data && rc.status == 200) success = true;
            }
        } catch (ex) {
            this.formService.handleError(this.formService.getTranslate('GENERAL.ERROR_SENDING_REQUEST'));
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x._id == id);
                await this.$timeout(async () => {
                    const userRolesData = await this.getUserRolesById(id);
                    if (row && userRolesData && userRolesData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = userRolesData.DOCUMENT_ERROR;
                        documentError = userRolesData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError(this.formService.getTranslate('GENERAL.ERROR_SENDING_REQUEST'));
        } finally {
            return documentError;
        }
    }

    private async getUserRolesById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const rc = await this.helpersService.get(`/userRoles/id/${id}`, null, 120000);
            return rc.data.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }
}

import * as uuid from 'uuid';
import angular = require("angular");
import { ISelectorModel } from "@models/mongo/SelectorModel";
import { SITUATION } from '@models/interface/operational/maritime/MaritimeStopover';
import { MaritimeStopover } from "@models/interface/operational/maritime/MaritimeStopover";
import { EMaritimeStopOverLog } from "@enums/MaritimeStopover";
import { IExceptionStopoverModel, IStopoverModalScope, WizardStopoverController } from "./WizardStopoverController";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";

export interface IStopoverListOptions {
    vessel: ISelectorModel[];
    situation: ISelectorModel[];
    port: ISelectorModel[];
}

interface IStopoverEditModalScope extends angular.IScope {
    stopover: MaritimeStopover;
    oldStopover: MaritimeStopover;
    stopoverViaList: ISelectorModel[];
    stopoverShipList: ISelectorModel[];
    stopoverModalViewData: {
        stopoverShipowners: string,
    }
    currentNavigationLength: number;
    currentNavigationIndex: number;
    hasPreviousStopover: () => boolean;
    hasNextStopover: () => boolean;
    previousStopover: () => Promise<void>;
    nextStopover: (skipConfirm?: boolean) => Promise<void>;
    handleStopoverNavigation: () => Promise<void>;
    handleStopoverViaChange: () => Promise<void>;
    handleStopoverViaShipChange: () => Promise<void>;
    handleStopoverDateChange: (propName: string) => Promise<void>;
    handleEnterApplyStopover: () => Promise<void>;
    confirmStopoverChanges: (closeModal?: boolean, moveToNext?: boolean) => Promise<boolean>;
    confirmStopoverDiscard: () => Promise<boolean>;
    closeStopoverModal: (skipConfirm: boolean) => Promise<void>;
    discardStopoverChanges: () => Promise<void>;
    applyStopover: (closeModal: boolean, moveToNext: boolean) => Promise<void>;
    handleStopoverShipChange: () => Promise<void>;
    viewLog: (logBlock: string) => Promise<void>;
}

export interface IStopoverAddExceptionModalScope extends angular.IScope {
    applyExceptionStopover: () => Promise<void>;
    closeStopoverModal: () => Promise<void>;
}

export class WizardStopoverPanelController {
    private modalStopoverId: number;
    private scope: IStopoverModalScope;
    private confirmationModalId: number;
    private parent: WizardStopoverController;

    constructor(scope: IStopoverModalScope, parent: WizardStopoverController) {
        this.scope = scope;
        this.parent = parent;
        this.modalStopoverId = 0;
        this.parent.navigationChangeListeners[`${this.parent.TABS.STOPOVER}`] = {
            NEW_RECORD: async (lastSelectedTab) => this.handleStopoversRegisterInit(lastSelectedTab),
            EDIT: async (lastSelectedTab) => this.handleStopoversEditInit(lastSelectedTab),
            VIEW: async (lastSelectedTab) => this.handleStopoversEditInit(lastSelectedTab)
        };

        this.scope.showAllStopovers = () => this.showAllStopovers();

        this.scope.editStopover = (stopoverId: string): void => {
            this.editStopover(stopoverId);
        }

        this.scope.filterStopovers = (): void => {
            this.filterStopovers();
        }

        this.scope.viewStopoverLog = (stopoverId: string): void => {
            this.viewLog(stopoverId);
        }

        this.scope.addStopover = (): void => {
            this.addStopover();
        }
    }

    private showAllStopovers(): void {
        try {
            this.scope.viewData.showAllStopovers = !this.scope.viewData.showAllStopovers;
            
            this.scope.viewData.stopoverTableData = this.refreshVisibleStopovers();

            // // Fetch Deadline List
            this.scope.viewData.stopoverList = angular.copy(this.scope.viewData.stopoverTableData);
            this.scope.viewData.filteredStopoverList = angular.copy(this.scope.viewData.stopoverTableData);

            // Fetch Deadline Filter Options
            this.scope.viewData.stopoverListOptions = { port: [], vessel: [], situation: [] }
            for (const stopover of this.scope.voyageBaseData.stopovers) {
                if ((stopover.PORT) && !this.scope.viewData.stopoverListOptions.port.some(port => port.ID == stopover.PORT.ID)) this.scope.viewData.stopoverListOptions.port.push({ ID: stopover.PORT.ID, NAME: stopover.PORT.NAME, CODE: stopover.PORT.CODE })
                if ((stopover.VESSEL_INFORMATION && stopover.VESSEL_INFORMATION.VESSEL) && !this.scope.viewData.stopoverListOptions.vessel.some(vessel => vessel.ID == stopover.VESSEL_INFORMATION.VESSEL.ID)) this.scope.viewData.stopoverListOptions.vessel.push({ ID: stopover.VESSEL_INFORMATION.VESSEL.ID, NAME: stopover.VESSEL_INFORMATION.VESSEL.NAME, CODE: stopover.VESSEL_INFORMATION.VESSEL.CODE });
                if ((stopover.SITUATION) && !this.scope.viewData.stopoverListOptions.situation.some(situation => situation.ID == stopover.SITUATION.ID)) this.scope.viewData.stopoverListOptions.situation.push({ ID: stopover.SITUATION.ID, NAME: stopover.SITUATION.NAME, CODE: stopover.SITUATION.CODE })
            }
        } catch (ex) {
            this.parent.handleError(ex);
        }
    }

    private filterStopovers(): void {
        if (this.scope.viewData && this.scope.viewData.filteredStopoverList) {
            this.scope.viewData.filteredStopoverList = angular.copy(this.scope.voyageBaseData.stopovers);

            if (this.scope.viewData.stopoverFilterPort) {
                this.scope.viewData.filteredStopoverList = this.scope.viewData.filteredStopoverList.filter(stopover => stopover.PORT && stopover.PORT.ID == this.scope.viewData.stopoverFilterPort.ID);
            }
            if (this.scope.viewData.stopoverFilterVessel) {
                this.scope.viewData.filteredStopoverList = this.scope.viewData.filteredStopoverList.filter(stopover => stopover.VESSEL_INFORMATION && stopover.VESSEL_INFORMATION.VESSEL && stopover.VESSEL_INFORMATION.VESSEL.ID == this.scope.viewData.stopoverFilterVessel.ID);
            }
            if (this.scope.viewData.stopoverFilterSituation) {
                this.scope.viewData.filteredStopoverList = this.scope.viewData.filteredStopoverList.filter(stopover => stopover.SITUATION && stopover.SITUATION.ID == this.scope.viewData.stopoverFilterSituation.ID);
            }
        }
    };

    private refreshVisibleStopovers(_stopoverList?: MaritimeStopover[]): MaritimeStopover[] {
        try {
            const stopoverList = (_stopoverList) ? _stopoverList : this.scope.voyageBaseData.stopovers;

            if (!this.scope.showAllStopoversButton) return stopoverList;

            if (!this.scope.viewData.showAllStopovers) {
                const eventLocalIds = this.scope.processEvents.filter(x => x.LOCAL_TYPE && x.LOCAL && x.LOCAL_TYPE.ID === '2').map(x => x.LOCAL.ID); // Porto/Aeroporto
                return stopoverList.filter(x => x.PORT && eventLocalIds.includes(x.PORT.ID));
            }

            return stopoverList;
        } catch (ex) {
            this.parent.handleError(ex);
        }
    }

    private async handleStopoversRegisterInit(lastSelectedTab): Promise<void> {
        try {
            this.parent.block();

            const mainShip = this.scope.voyageBaseData.voyage.VESSEL_INFORMATION.find(x => x.MAIN === true);
            this.scope.viewData.showAllStopovers = true;
            this.scope.viewData.travelMainShip = (mainShip) ? mainShip.VESSEL.NAME : null;
            this.scope.viewData.travelShipownersTravel = (mainShip) ? mainShip.CARRIER_INFORMATION.map(x => `${x.CARRIER.CODE}(${x.VOYAGE})`).join(', ') : null;

            const voyageHasChanges = (lastSelectedTab && lastSelectedTab.label === this.parent.TABS.VOYAGE) &&
                (this.parent.hasChanges(this.parent.voyageTabOldValue, this.scope.voyageBaseData.voyage));

            const shouldGenerateStopovers = voyageHasChanges || this.parent.voyageTabOldValue == null;

            if (shouldGenerateStopovers) {
                await this.generateStopoversBasedOnVoyage();
                this.parent.voyageTabOldValue = angular.copy(this.scope.voyageBaseData.voyage);
            }

            this.showAllStopovers();
            this.scope.viewData.stopoverTableData = this.refreshVisibleStopovers();

            // // Fetch Deadline List
            this.scope.viewData.stopoverList = angular.copy(this.scope.viewData.stopoverTableData);
            this.scope.viewData.filteredStopoverList = angular.copy(this.scope.viewData.stopoverTableData);

            this.parent.timeout(async () => {
                await this.scope.$applyAsync();
                this.parent.unblock();
            }, 100, true);

        } catch (ex) {
            this.parent.handleError(ex);
        }
    }

    private async handleStopoversEditInit(lastSelectedTab): Promise<void> {
        try {
            this.parent.block();

            this.refreshShipowner();

            const voyageHasChanges = (lastSelectedTab && lastSelectedTab.label === this.parent.TABS.VOYAGE) &&
                (this.parent.hasChanges(this.parent.voyageTabOldValue, this.scope.voyageBaseData.voyage));

            if (voyageHasChanges) {
                await this.updateStopoversBasedOnVoyage();
                this.parent.voyageTabOldValue = angular.copy(this.scope.voyageBaseData.voyage);
            }

            this.showAllStopovers();
            this.scope.viewData.stopoverTableData = this.refreshVisibleStopovers();

            // // Fetch Deadline List
            this.scope.viewData.stopoverList = angular.copy(this.scope.viewData.stopoverTableData);
            this.scope.viewData.filteredStopoverList = angular.copy(this.scope.viewData.stopoverTableData);

            this.parent.timeout(async () => {
                await this.scope.$applyAsync();
                this.parent.unblock();
            }, 100, true);

        } catch (ex) {
            this.parent.handleError(ex);
        }
    }

    private async updateStopoversBasedOnVoyage() {
        const result = await this.parent.operationalService.post('/stopover/maritime/vessel/refresh/reference', {
            "timeout": 10000,
            ...this.scope.voyageBaseData
        });

        if (result.data && result.data.data) {
            this.scope.voyageBaseData.stopovers = result.data.data;
        }

        await this.scope.$applyAsync();
    }

    private async generateStopoversBasedOnVoyage() {
        const result = await this.parent.operationalService.post('/stopover/maritime/generate', {
            "voyage": this.scope.voyageBaseData.voyage
        });

        if (result.data && result.data.data) {
            this.scope.voyageBaseData.stopovers = result.data.data;
        }

        await this.scope.$applyAsync();
    }

    private async refreshShipowner(): Promise<void> {
        try {
            if (this.scope.voyageBaseData.voyage.VESSEL_INFORMATION && this.scope.voyageBaseData.stopovers && this.scope.voyageBaseData.stopovers.length > 0 && this.scope.voyageBaseData.voyage.VESSEL_INFORMATION.length > 0) {
                for (const stopoverItem of this.scope.voyageBaseData.stopovers) {
                    if (stopoverItem.VESSEL_INFORMATION && stopoverItem.VESSEL_INFORMATION.VESSEL) {
                        const ship = this.scope.voyageBaseData.voyage.VESSEL_INFORMATION.find(item => item.VESSEL.ID == stopoverItem.VESSEL_INFORMATION.VESSEL.ID)
                        if (ship) {
                            stopoverItem.VESSEL_INFORMATION = ship;
                        }
                    }
                }
            }
            const mainShip = this.scope.voyageBaseData.voyage.VESSEL_INFORMATION.find(x => x.MAIN === true);
            this.scope.viewData = {
                showAllStopovers: false,
                travelMainShip: (mainShip) ? mainShip.VESSEL.NAME : null,
                travelShipownersTravel: (mainShip) ? mainShip.CARRIER_INFORMATION.map(x => `${x.CARRIER.CODE}(${x.VOYAGE})`).join(', ') : null,
                stopoverTableData: angular.copy(this.scope.voyageBaseData.stopovers),
            }

        } catch (ex) {
            this.parent.handleError(ex);
        }
    }

    private async editStopover(stopoverId: string): Promise<void> {
        try {
            const stopover = this.scope.viewData.stopoverTableData.find(stopover => stopover.IDENTIFIER == stopoverId);
            const stopoverIndex = this.scope.viewData.stopoverTableData.indexOf(stopover);

            this.modalStopoverId = this.parent.ModalService.newModal();
            this.parent.ModalService.showModalInfo(
                {
                    modalID: this.modalStopoverId,
                    scope: this.scope,
                    formService: 'edit',
                    template: require("../view/wizardTravelStopoverModal.html"),
                    size: 'lg'
                },
                {
                    actionButtonText: `${await this.parent.getTranslate("GENERAL.CLOSE")}`,
                    headerText: `${(this.scope.voyageBaseData.voyage.VOYAGE_NUMBER) ? this.scope.voyageBaseData.voyage.VOYAGE_NUMBER : await this.parent.getTranslate("OPERATIONAL.NEW_VOYAGE")} - ${await this.parent.getTranslate("OPERATIONAL.EDIT_STOPOVER")}`
                });

            let tableStopovers = this.scope.viewData.stopoverTableData;
            let updatedTravelStopovers = angular.copy(this.scope.voyageBaseData.stopovers);
            const previousTravelStopovers = angular.copy(this.scope.voyageBaseData.stopovers);

            const modalScope: IStopoverEditModalScope = await this.parent.ModalService.getModalScope(this.modalStopoverId);

            modalScope.stopover = angular.copy(tableStopovers[stopoverIndex]);
            modalScope.oldStopover = angular.copy(tableStopovers[stopoverIndex]);
            modalScope.stopoverViaList = this.scope.stopoverViaList;
            modalScope.stopoverShipList = this.scope.voyageBaseData.voyage.VESSEL_INFORMATION.filter(v => !v.MAIN).map(x => x.VESSEL);
            modalScope.stopoverModalViewData = {
                stopoverShipowners: (modalScope.stopover && modalScope.stopover.VESSEL_INFORMATION && modalScope.stopover.VESSEL_INFORMATION.MAIN) ? modalScope.stopover.VESSEL_INFORMATION.CARRIER_INFORMATION.map(x => `${x.CARRIER.CODE}(${x.VOYAGE})`).join(', ') : null,
            }
            modalScope.currentNavigationLength = tableStopovers.length;
            modalScope.currentNavigationIndex = stopoverIndex;

            if (modalScope.stopover && modalScope.stopover.PROCESS_REFERENCE) {
                for (const processItem of modalScope.stopover.PROCESS_REFERENCE) {
                    if (!this.scope.affectedProcesses.includes(processItem)) {
                        this.scope.affectedProcesses.push(processItem);
                    }
                }
            }

            modalScope.hasPreviousStopover = (): boolean => {
                return tableStopovers.length > 0 && modalScope.currentNavigationIndex > 0;
            }
            modalScope.hasNextStopover = (): boolean => {
                return tableStopovers.length > 0 && (tableStopovers.length - 1 > modalScope.currentNavigationIndex);
            }
            modalScope.previousStopover = async (): Promise<void> => {
                if (!modalScope.hasPreviousStopover()) return;
                const confirm = await modalScope.confirmStopoverChanges();
                if (confirm) {
                    modalScope.currentNavigationIndex--;
                    await modalScope.handleStopoverNavigation();
                }
            }
            modalScope.nextStopover = async (skipConfirm: boolean = false): Promise<void> => {
                if (!modalScope.hasNextStopover()) return;
                const confirm = (skipConfirm) ? true : await modalScope.confirmStopoverChanges();
                if (confirm) {
                    modalScope.currentNavigationIndex++;
                    await modalScope.handleStopoverNavigation();
                }
            }
            modalScope.handleStopoverNavigation = async (): Promise<void> => {
                try {
                    const nextItem = tableStopovers[modalScope.currentNavigationIndex];
                    if (nextItem) {
                        modalScope.stopover = angular.copy(nextItem);
                        modalScope.oldStopover = angular.copy(nextItem);
                    }
                    await modalScope.$applyAsync();
                } catch (ex) {
                    this.parent.handleError(ex);
                }
            }
            modalScope.handleStopoverViaChange = async (): Promise<void> => {
                try {
                    if (modalScope.stopover.VIA && modalScope.stopover.VIA.ID === '1')
                        modalScope.stopover.VESSEL_INFORMATION = this.scope.voyageBaseData.voyage.VESSEL_INFORMATION.find(x => x.MAIN);
                } catch (ex) {
                    this.parent.handleError(ex);
                }
            }
            modalScope.handleStopoverViaShipChange = async (): Promise<void> => {
                try {
                } catch (ex) {
                    this.parent.handleError(ex);
                }
            }
            modalScope.handleStopoverShipChange = async (): Promise<void> => {
                try {
                    if (modalScope.stopover && modalScope.stopover.VESSEL_INFORMATION && modalScope.stopover.VESSEL_INFORMATION.VESSEL) {
                        modalScope.stopover.VESSEL_INFORMATION = this.scope.voyageBaseData.voyage.VESSEL_INFORMATION.find(item => item.VESSEL && item.VESSEL.ID == modalScope.stopover.VESSEL_INFORMATION.VESSEL.ID);

                        modalScope.stopoverModalViewData = {
                            stopoverShipowners: (modalScope.stopover.VESSEL_INFORMATION && modalScope.stopover.VESSEL_INFORMATION.CARRIER_INFORMATION) ? modalScope.stopover.VESSEL_INFORMATION.CARRIER_INFORMATION.map(x => `${x.CARRIER.CODE}(${x.VOYAGE})`).join(', ') : null,
                        }
                    }
                } catch (ex) {
                    this.parent.handleError(ex);
                }
            }
            modalScope.handleStopoverDateChange = async (propName: string): Promise<void> => {
                try {
                    const hasChanges = this.parent.hasChanges(modalScope.stopover, modalScope.oldStopover);
                    if (!hasChanges) return;
                    if (!modalScope.stopover[propName]) return;
                    try {
                        await this.validateStopoverDates(modalScope.stopover);
                    } catch (ex) {
                        if (ex.status !== 200) {

                            modalScope.stopover[propName] = null;

                            this.parent.timeout(async () => {
                                await modalScope.$applyAsync();
                            });
                        }
                        this.parent.handleError(ex);
                    }

                    const result: SITUATION = await this.getStopoverSituation(modalScope.stopover);
                    if (result) modalScope.stopover.SITUATION = result;

                } catch (ex) {
                    this.parent.handleError(ex);
                }
            }
            modalScope.handleEnterApplyStopover = async (): Promise<void> => {
                try {
                    const hasChanges = this.parent.hasChanges(modalScope.stopover, modalScope.oldStopover);
                    if (hasChanges) {
                        const actionText = await this.parent.getTranslate("REGISTRATION.APPLY_AND_NEXT");
                        const confirmationText = await this.parent.getTranslate("GENERAL.CONTINUE_QUESTION");
                        const confirm = await this.stopoverSaveConfirmation(confirmationText, actionText, "Fechar");
                        if (confirm) await modalScope.applyStopover(false, true);
                    }
                } catch (ex) {
                    this.parent.handleError(ex);
                }
            }
            modalScope.confirmStopoverChanges = async (closeModal: boolean = false, moveToNext: boolean = false): Promise<boolean> => {
                const hasChanges = this.parent.hasChanges(modalScope.stopover, modalScope.oldStopover);
                if (hasChanges) {
                    const actionText = (closeModal) ? await this.parent.getTranslate("REGISTRATION.APPLY_AND_CLOSE") : await this.parent.getTranslate("REGISTRATION.APPLY_AND_NEXT");
                    const confirmationText = await this.parent.getTranslate("REGISTRATION.MESSAGES.ERROR.UPDATE_NOT_SAVED");
                    const confirm = await this.stopoverSaveConfirmation(confirmationText, actionText, await this.parent.getTranslate("GENERAL.CLOSE"));
                    if (confirm) await modalScope.applyStopover(closeModal, moveToNext);
                    return confirm;
                }
                return true;
            }
            modalScope.confirmStopoverDiscard = async (): Promise<boolean> => {
                const actionText = await this.parent.getTranslate("GENERAL.DISCARD_ALL");
                const confirmationText = await this.parent.getTranslate("REGISTRATION.DISCARD_CHANGES");
                const confirm = await this.stopoverSaveConfirmation(confirmationText, actionText, await this.parent.getTranslate("GENERAL.CLOSE"));
                return confirm;
            }
            modalScope.closeStopoverModal = async (skipConfirm: boolean = false): Promise<void> => {
                if (!skipConfirm) await modalScope.confirmStopoverChanges(true, true);

                this.scope.voyageBaseData.stopovers = angular.copy(updatedTravelStopovers);
                // It loads the light data table rows

                this.scope.viewData.stopoverTableData = this.refreshVisibleStopovers();

                // // Fetch Deadline List
                this.scope.viewData.stopoverList = angular.copy(this.scope.viewData.stopoverTableData);
                this.scope.viewData.filteredStopoverList = angular.copy(this.scope.viewData.stopoverTableData);

                await this.scope.$applyAsync();

                this.parent.ModalService.closeModal(this.modalStopoverId);
                this.modalStopoverId = 0;
            }
            modalScope.discardStopoverChanges = async (): Promise<void> => {
                try {
                    const confirm = await modalScope.confirmStopoverDiscard();
                    if (!confirm) return;

                    updatedTravelStopovers = angular.copy(previousTravelStopovers);
                    tableStopovers = this.refreshVisibleStopovers(updatedTravelStopovers);

                    modalScope.stopover = angular.copy(tableStopovers[modalScope.currentNavigationIndex]);
                    modalScope.oldStopover = angular.copy(modalScope.stopover);
                    await this.scope.$applyAsync();

                } catch (ex) {
                    this.parent.handleError(ex);
                }
            }
            modalScope.applyStopover = async (closeModal: boolean = false, moveToNext: boolean = false): Promise<void> => {
                try {
                    const hasChanges = this.parent.hasChanges(modalScope.stopover, modalScope.oldStopover);
                    if (hasChanges) {
                        const isVesselChanged = this.parent.hasChanges(modalScope.stopover.VESSEL_INFORMATION, modalScope.oldStopover.VESSEL_INFORMATION);
                        if (isVesselChanged) {
                            const result = await this.parent.operationalService.post('/stopover/maritime/vessel/change', {
                                stopover: modalScope.stopover,
                                timeout: 10000
                            });

                            if (result.data) {
                                modalScope.stopover = result.data.data;
                                await modalScope.$applyAsync();
                            }
                        }

                        const updatedStopoverInd = updatedTravelStopovers.findIndex(x => x.IDENTIFIER === modalScope.stopover.IDENTIFIER);
                        if (updatedStopoverInd === -1) return;

                        updatedTravelStopovers[updatedStopoverInd] = angular.copy(modalScope.stopover);
                        modalScope.oldStopover = angular.copy(modalScope.stopover);

                        const ttimeUpdatedStopovers = await this.calculateStopoverDateByTTime(modalScope.stopover, updatedTravelStopovers);
                        if (ttimeUpdatedStopovers && ttimeUpdatedStopovers.length > 0) {

                            updatedTravelStopovers = angular.copy(ttimeUpdatedStopovers);
                            tableStopovers = this.refreshVisibleStopovers(updatedTravelStopovers);

                            await modalScope.$applyAsync();
                        }
                    }
                    if (moveToNext) {
                        if (modalScope.hasNextStopover()) await modalScope.nextStopover();
                        else closeModal = true;
                    }
                    if (closeModal)
                        await modalScope.closeStopoverModal(true);

                } catch (ex) {
                    this.parent.handleError(ex);
                }
            }

            await modalScope.$applyAsync();

            // bind key events when modal is rendered
            const checkModalAddEvent =
                setInterval(function (scope) {
                    if ($('#stopoverEditModal').length) {
                        $("#stopoverEditModal").on("keydown", async (event) => {
                            if ($("#stopoverEditModal :input").is(":focus")) {
                                if (event.key === 'Esc' || event.key === 'Escape') {
                                    $('input:focus').blur();
                                    $('button:focus').blur();
                                    $('#stopoverEditModal').focus();
                                }
                                else if (event.key === 'Enter') {
                                    $('#stopoverEditModal').focus();
                                }
                            } else {
                                if (event.key === 'Left' || event.key === 'ArrowLeft') {
                                    event.preventDefault();
                                    await scope.previousStopover();
                                }
                                else if (event.key === 'Right' || event.key === 'ArrowRight') {
                                    event.preventDefault();
                                    await scope.nextStopover();
                                }
                                else if (event.key === 'Esc' || event.key === 'Escape') {
                                }
                                else if (event.key === 'Enter') {
                                    await scope.handleEnterApplyStopover();
                                }
                            }
                        });
                        $('#stopoverEditModal').focus();
                        clearInterval(checkModalAddEvent);
                    }
                }, 100, modalScope);

        } catch (ex) {
            this.parent.handleError(ex);
        }
    }

    private async addStopover(): Promise<void> {
        try {
            this.modalStopoverId = this.parent.ModalService.newModal();
            this.buildShipExceptionStopoverData();
            this.parent.ModalService.showModalInfo(
                {
                    modalID: this.modalStopoverId,
                    scope: this.scope,
                    formService: 'register',
                    template: require("../view/exceptionStopoverModal.html"),
                    size: 'lg'
                },
                {
                    actionButtonText: await this.parent.getTranslate("GENERAL.CLOSE"),
                    headerText: `${this.scope.voyageBaseData.voyage.VOYAGE_NUMBER} - ${await this.parent.getTranslate("OPERATIONAL.ADDITIONAL_STOPOVER")}`
                });

            const modalScope: IStopoverAddExceptionModalScope = await this.parent.ModalService.getModalScope(this.modalStopoverId);
            modalScope.applyExceptionStopover = async (): Promise<void> => {
                try {
                    const exceptionStopoverList = this.scope.exceptionStopoverModel.filter(item => item.PORT);
                    if (!exceptionStopoverList || exceptionStopoverList.length == 0) return this.parent.handleError("REGISTRATION.INFORM_PLACE");

                    await this.buildExceptionStopover(exceptionStopoverList);
                    await modalScope.$applyAsync();

                    this.parent.ModalService.closeModal(this.modalStopoverId);
                    this.modalStopoverId = 0;
                    // It loads the light data table rows

                    this.scope.viewData.stopoverTableData = this.refreshVisibleStopovers();

                    // // Fetch Deadline List
                    this.scope.viewData.stopoverList = angular.copy(this.scope.viewData.stopoverTableData);
                    this.scope.viewData.filteredStopoverList = angular.copy(this.scope.viewData.stopoverTableData);
                    await this.scope.$applyAsync();

                } catch (ex) {
                    this.parent.handleError(ex);
                }
            }
            modalScope.closeStopoverModal = async (): Promise<void> => {
                this.parent.ModalService.closeModal(this.modalStopoverId);
                this.modalStopoverId = 0;
            }
        } catch (ex) {
            this.parent.handleError(ex);
        }
    }

    private async buildExceptionStopover(exceptionStopoverList: IExceptionStopoverModel[]): Promise<void> {
        if (!exceptionStopoverList || exceptionStopoverList.length == 0) throw 'exceptionStopoverList is null';

        for (const stopoverItem of exceptionStopoverList) {
            for (const portItem of stopoverItem.PORT) {

                if (this.scope.voyageBaseData.stopovers) {
                    const invalidStopover = this.scope.voyageBaseData.stopovers.some(item => item.VESSEL_INFORMATION && item.VESSEL_INFORMATION.VESSEL && item.VESSEL_INFORMATION.VESSEL.ID == stopoverItem.SHIP.ID && item.PORT.ID == portItem.ID)
                    if (invalidStopover) throw this.parent.getTranslate("OPERATIONAL.ALREADY_EXIST_STOPOVER_SHIP", { portName: portItem.NAME, shipName: stopoverItem.SHIP.NAME });
                } else {
                    this.scope.voyageBaseData.stopovers = [];
                }

                const exception = (this.scope.voyageBaseData.voyage.FEEDER) ? false : true;

                const VIA: ISelectorModel = (stopoverItem.MASTER) ? this.scope.stopoverViaList.find(item => item.ID == '1') : this.scope.stopoverViaList.find(item => item.ID == '2');
                const exceptionStopover: MaritimeStopover = {
                    _id: null,
                    ID: null,
                    PRODUCT: this.scope.voyageBaseData.voyage.SERVICE.PRODUCT,
                    VOYAGE_NUMBER: null,
                    VOYAGE_REFERENCE: null,
                    DEADLINES: null,
                    VESSEL_INFORMATION: {
                        MAIN: stopoverItem.MASTER,
                        VESSEL: stopoverItem.SHIP,
                        CARRIER_INFORMATION: stopoverItem.SHIPOWNER.map((x) => ({
                            CARRIER: x.OWNER,
                            VOYAGE: x.TRAVEL,
                        })),
                    },
                    SITUATION: { "ID": "1", "NAME": "Sugerida" }, // #generic stopover_situation
                    CONCAT_REFERENCE: null,
                    CREATED_DATE: null,
                    IDENTIFIER: uuid.v4(),
                    COUNTRY: portItem.COUNTRY,
                    EXCEPTION: exception,
                    TRANSIT_TIME: 0,
                    VIA: (!VIA ? null : VIA),
                    PROCESS_REFERENCE: [],
                    MODIFIED_DATE: null,
                    MODIFIED_BY: null,
                    ESTIMATED_DEPARTURE: null,
                    DEPARTURE: null,
                    ESTIMATED_ARRIVAL: null,
                    ARRIVAL: null,
                    ESTIMATED_BERTHING: null,
                    BERTHING: null,
                    ESTIMATED_UNBERTHING: null,
                    UNBERTHING: null,
                    TRANSSHIPMENT_DIRECTION: null,
                    TRANSSHIPMENT_ROUTING_POINT: null,
                    PORT: { ID: portItem.ID, NAME: portItem.NAME, CODE: portItem.CODE },
                }

                this.scope.voyageBaseData.stopovers.push(exceptionStopover);

                this.scope.hasExceptionStopover = true;
            }
        }
    }

    private buildShipExceptionStopoverData() {
        try {
            if (this.scope.voyageBaseData && this.scope.voyageBaseData.voyage.VESSEL_INFORMATION) {
                if (!this.scope.exceptionStopoverModel || this.scope.exceptionStopoverModel.length > 0) this.scope.exceptionStopoverModel = [];
                for (const shipItem of this.scope.voyageBaseData.voyage.VESSEL_INFORMATION) {
                    this.scope.exceptionStopoverModel.push({
                        MASTER: shipItem.MAIN,
                        SHIP: shipItem.VESSEL,
                        SHIPOWNER: shipItem.CARRIER_INFORMATION.map(info => ({
                            MASTER: null,
                            OWNER: info.CARRIER,
                            TRAVEL: info.VOYAGE
                        })),
                        PORT: null,
                    })
                }
            }
        } catch (ex) {
            this.parent.handleError(ex);
        }
    }

    private async stopoverSaveConfirmation(confirmationText: string, actionText: string, closeButtonText: string): Promise<boolean> {
        this.confirmationModalId = this.parent.ModalService.newModal();
        return await this.parent.ModalService.showModalConfirmation({
            modalID: this.confirmationModalId,
            keyboard: true,
            events: async (event: angular.IAngularEvent, _reason: Object, _closed: boolean) => {
                if (event.name == "modal.closing") {
                    this.confirmationModalId = 0;
                }
            }
        }, {
            headerText: 'REGISTRATION.REGISTRATION',
            bodyText: confirmationText,
            actionButtonText: actionText,
            closeButtonText: closeButtonText,
        });
    }

    private async getStopoverSituation(stopover: MaritimeStopover): Promise<SITUATION> {
        const params = { stopover }
        const result = await this.parent.operationalService.post('/stopover/maritime/situation', params, 15000);
        if (result && result.data && result.data.data) {
            return result.data.data;
        }
        return null;
    }

    private validateStopoverDates(stopover: MaritimeStopover): Promise<any> {
        const params = { stopover }
        return this.parent.operationalService.post('/stopover/maritime/date/validate', params, 15000);
    }

    private async calculateStopoverDateByTTime(stopover: MaritimeStopover, stopoverList: MaritimeStopover[]): Promise<MaritimeStopover[]> {
        try {
            this.parent.block();

            const updatedStopovers = await this.parent.operationalService.post(`/stopover/maritime/date/ttime`, {
                pivotStopover: stopover,
                stopovers: stopoverList,
            }, 30000);

            this.parent.unblock();

            return updatedStopovers.data ? updatedStopovers.data.data : [];
        } catch (ex) {
            this.parent.handleError(ex);
        }
    }

    private async viewLog(stopoverId: string): Promise<void> {
        try {
            const stopover = this.scope.viewData.stopoverTableData.find(stopover => stopover._id == stopoverId);
            const identifier: string = this.scope.voyageBaseData.voyage.VOYAGE_NUMBER + '-' + stopover.PORT.NAME;

            const retrieveLog = await this.parent.$RestService.getObjectAsPromise(`${this.parent.operationalService.$route}/stopover/wizard/viewLog/${EMaritimeStopOverLog.STOPOVER}/${identifier}`, 30000, null, false);

            if (retrieveLog && retrieveLog.data) {
                let log: IViewLog = {
                    operation: 'history',
                    number: this.scope.voyageBaseData.voyage.VOYAGE_NUMBER,
                    list: retrieveLog.data,
                    show: true,
                    showCloseButton: false,
                    searchQuery: '',
                    originalList: angular.copy(retrieveLog.data)
                }
                this.scope.log = log;
                this.scope.customLogProperties = this.getCustomLogProperties();
                this.scope.modalID = this.parent.ModalService.newModal();
                this.parent.ModalService.showModalInfo(
                    {
                        modalID: this.scope.modalID,
                        template: require("../view/modal/maritimeStopOverModalLog.html"),
                        formService: 'register',
                        size: 'full modal-overflow',
                        scope: this.scope
                    },
                    {
                        actionButtonClass: 'btn-default',
                        closeButtonText: await this.parent.getTranslate("GENERAL.CLOSE"),
                        headerText: `${await this.parent.getTranslate("OPERATIONAL.STOPOVER_LOG")} - ${this.scope.voyageBaseData.voyage.VOYAGE_NUMBER} - ${stopover.PORT.NAME}`
                    },
                );

                this.parent.ModalService.closeModal(this.scope.modalID);
            }
        } catch (ex) {
            this.parent.handleError(ex);
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: "TRANSIT_TIME", LABEL: "REGISTRATION.TIME" },
            { PROPERTY: "SITUATION", LABEL: "GENERAL.SITUATION" },
            { PROPERTY: "ESTIMATED_ARRIVAL", LABEL: "OPERATIONAL.ESTIMATED_ARRIVAL" },
            { PROPERTY: "ARRIVAL", LABEL: "OPERATIONAL.ARRIVAL" },
            { PROPERTY: "ESTIMATED_BERTHING", LABEL: "OPERATIONAL.BERTH_ESTIMATED_DATE" },
            { PROPERTY: "BERTHING", LABEL: "OPERATIONAL.BERTH" },
            { PROPERTY: "ESTIMATED_UNBERTHING", LABEL: "OPERATIONAL.ESTIMATED_UNBERTH" },
            { PROPERTY: "UNBERTHING", LABEL: "OPERATIONAL.UNBERTH" },
            { PROPERTY: "ESTIMATED_DEPARTURE", LABEL: "OPERATIONAL.ESTIMATED_DEPARTURE" },
            { PROPERTY: "DEPARTURE", LABEL: "OPERATIONAL.DEPARTURE" },
            { PROPERTY: "VOYAGE_NUMBER", LABEL: "GENERAL.NUMBER" },
            { PROPERTY: "PRODUCT", LABEL: "GENERAL.PRICING_DEPARTMENT" },
            { PROPERTY: "PORT", LABEL: "REGISTRATION.PORT" },
            { PROPERTY: "COUNTRY", LABEL: "ROUTE.COUNTRY" },
            { PROPERTY: "VIA", LABEL: "ROUTE.HUB" },
            { PROPERTY: "CREATED_DATE", LABEL: "GENERAL.CREATED_AT" },
        ];
        return props;
    }
}


import * as angular from 'angular';
import { NotificationConstants } from '../../common/util/NotificationConstants';
import { EOperation } from '@enums/GenericData';

fabDirective.$inject = ['$rootScope', '$timeout'];

/**
 * @description Diretiva para usar o FAB
 * 
 * 
 * Design Ex.: <fab hide='new, view' show='save, edit' />
 * 
 * @returns {object} Objeto de Diretiva
 */
export function fabDirective($rootScope, $timeout) {
    let ddo: any = {};

    ddo.restrict = "E";
    ddo.transclude = true;
    ddo.replace = true;
    ddo.$scope = true;
    ddo.template = require("../view/template/fab.html");

    ddo.scope = {
        disable: "@",
        enable: "@",
        model: "=",
    };

    ddo.link = function ($scope: IFabScope, $element, $attr, ngModel) {

        function confirmDialog() {

            if ($rootScope.change) {

                let result = confirm(NotificationConstants.CHANGE_ROUTE)
                if (result) $rootScope.change = false;

                return result;
            }

            return true;
        }

        $scope.openMenu = () => {

            let container = angular.element('.fab-container');

            if (container.hasClass('open')) container.removeClass('open');
            else container.addClass('open');

        }

        //reatualiza a cada mudança de tela
        $scope.$parent.$watch('operation', (operation: string) => {

            //base
            $scope.action = {
                view: true,
                new: true,
                save: true,
                edit: true,
                copy: true,
                cancel: true,
                delete: true,
            }

            $scope.closeDeletePopover = ($event: Event) => {

                let popover = angular.element($event.target).parents('.popover');
                let trash = popover.prev('.fab.delete')
                trash.trigger('click');

                popover.remove();

            }

            $scope.cancel = () => {
                $scope.openMenu();
                let result = confirmDialog();
                if (result) $scope.$parent.cancel();
            }

            $scope.save = () => {
                $scope.openMenu();
                $scope.$parent.save();
            }

            $scope.edit = () => {
                $scope.openMenu();
                if ($scope.$parent.customEdit) $scope.$parent.customEdit(($scope.model) ? $scope.model : $scope.$parent.model);
                else $scope.$parent.edit(($scope.model) ? $scope.model : $scope.$parent.model);
            }

            $scope.view = () => {
                $scope.openMenu();

                let result = confirmDialog();
                if (result) $scope.$parent.view(($scope.model) ? $scope.model : $scope.$parent.model);
            }

            $scope.copy = () => {
                $scope.openMenu();

                let result = confirmDialog();
                if (result) $scope.$parent.copy(($scope.model) ? $scope.model : $scope.$parent.model);
            }

            $scope.new = () => {
                $scope.openMenu();

                let result = confirmDialog();
                // some of our codes have REGISTER as add function. So I changed the logic to consider it
                if (result) if (typeof $scope.$parent.new !== "undefined") $scope.$parent.new(); else $scope.$parent.register();
            }

            $scope.delete = (event) => {
                $scope.openMenu();

                $scope.$parent.delete(($scope.model) ? $scope.model : $scope.$parent.model, event);
            }

            switch (operation) {
                case EOperation.VIEW:
                    $scope.action.view = false;
                    $scope.action.save = false;
                    $scope.action.delete = false;
                    break;
                case EOperation.NEW:
                    $scope.action.view = false;
                    $scope.action.new = false;
                    $scope.action.edit = false;
                    $scope.action.copy = false;
                    $scope.action.delete = false;
                    break;
                case EOperation.EDIT:
                    $scope.action.edit = false;
                    break;
                case EOperation.LIST:
                    $scope.action.view = false;
                    $scope.action.save = false;
                    $scope.action.edit = false;
                    $scope.action.copy = false;
                    $scope.action.cancel = false;
                    $scope.action.delete = false;
                    $scope.action.new = true;
                    break;
            }

            if ($scope.disable) {

                let actions: Array<string> = $scope.disable.split(',');

                //esconde o que o usuário solicitou no atributo "hide"
                if (actions.length) {
                    actions.forEach(action => {

                        action = action.replace(' ', '');

                        if ($scope.action[action] != undefined) $scope.action[action] = false;
                    });
                }
            }


            if ($scope.enable) {
                let actions: Array<string> = $scope.enable.split(',');

                //esconde o que o usuário solicitou no atributo "show"
                if (actions.length) {
                    actions.forEach(action => {
                        action = action.replace(' ', '');

                        if ($scope.action[action] != undefined) $scope.action[action] = true;
                    });
                }
            }


        })



    }

    return ddo;

}

interface IFabScope extends angular.IScope, IFabAction {
    action: IAction;
    enable: string;
    model: any;
    disable: string;
    openMenu: () => void;
    $parent: any;
    closeDeletePopover: ($event: Event) => void;
    confirmDialog: () => void;
}

export interface IFabAction {
    cancel: () => void;
    view: (model) => void;
    edit: (model) => void;
    save: () => void;
    new: () => void;
    copy: () => void;
    delete: (model, event: Event) => void;
}

interface IAction {
    view: boolean,
    new: boolean,
    save: boolean,
    edit: boolean,
    copy: boolean,
    cancel: boolean,
    delete: boolean
}
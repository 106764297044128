import * as angular from "angular";
import { IColumnDef } from "ui-grid";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequest } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IModalService } from "@services/ModalService";
import { TaskService } from "@services/TaskService";
import { ProductService } from "@services/ProductService";
import { ExternalService } from "@services/ExternalService";
import { ISelectorModel } from "@models/mongo/SelectorModel";
import { IAccountSelector, IAgentSelector } from "@models/interface/operational/IProductIntegrationSelector";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { FollowUpParams, EXCEPTION_LIST } from "@models/interface/common/FollowUpParams";
import { SelectorModel } from "../../common/model/SelectorModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { IAgentListCustomFilter } from "../model/AgentModel";
import { HelperService } from "@services/HelperService";

interface IFollowUpParamsScope extends IGridFormServiceScope {
    model: FollowUpParams;
    log: IViewLog;
    scopeBeforeSave: FollowUpParams;
    followup_models: any[];
    referenceTypes: any[];
    references: any[];
    referenceDateTypes: any[];
    events: any[];
    operations: any[];
    modals: any[];
    messageGroups: any[];
    responsibleRoles: any[];
    paramTypes: any[];
    recipients: any[];
    documentTypes: any[];
    languages: SelectorModel[];
    stopOvers: any[];
    deadlines: any[];
    //
    qualificationList: SelectorModel[];
    accountList: SelectorModel[];
    agentList: SelectorModel[];
    bookingStatusList: SelectorModel[];
    //
    copyGroupHasInternal: boolean;
    copyHiddenGroupHasInternal: boolean;
    htmlAllDocumentsPopover: string;
    htmlSurveyPopover: string;
    refreshReferenceType: (clear: boolean) => void;
    setStatus: () => void;
    updateCopyGroup: (model: SelectorModel[]) => void;
    updateCopyHiddenGroup: (model: SelectorModel[]) => void;
    setDefatultLanguage: () => void;
    addException: (type: string) => void;
    removeException: (index: number) => void;
    getTaskParams: () => any;
    getExceptionListAccountByName: (name: string) => Promise<void>;
    getExceptionListAgentByName: (name: string) => Promise<void>;
    customLogProperties: ICustomLogProperties[];
}

export class FollowUpParamsRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IFollowUpParamsScope;
    private ModalService: IModalService;
    private $timeout: ng.ITimeoutService
    private $q: ng.IQService;
    private RestService: IRestService;
    private ProductService: ProductService;
    private tasks: any[];
    private newTasks: any[];
    private events: any[];
    private SCEService: ng.ISCEService;
    private selectorsInfo: {};
    private ExternalService: ExternalService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IFollowUpParamsScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.ModalService = $injector.get('ModalService');
        this.$timeout = $injector.get('$timeout');
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.ProductService = $injector.get("ProductService");
        this.SCEService = $injector.get('$sce');
        this.ExternalService = $injector.get('ExternalService');
        this.$baseUrl = this.config.externalUrl + '/external';
        this.helperService = $injector.get('HelperService');
    }

    //HOOKS
    async $onInit(): Promise<void> {
        try {
            this.initForm(this, 'form', 'FollowUpParams', 'GENERAL.MENU.FOLLOW_UP_PARAMETERIZATION', true);

            this.block();

            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.MENU.FOLLOW_UP_PARAMETERIZATION');
            this.setCopyable(true);
            this.setDeletable(false);

            await this.initGrid('gridFollowUpParams', '/followUpParams/list/', true);

            this.$scope.htmlAllDocumentsPopover = this.SCEService.trustAsHtml(this.formService.getTranslate('GENERAL.ATTACHMENT_RULE_EXPLANATION'));
            this.$scope.htmlSurveyPopover = this.SCEService.trustAsHtml(this.formService.getTranslate('GENERAL.SATISFACTION_SURVEY_RULE_EXPLANATION'));

            this.unblock();

        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    async initScopeFunctions(): Promise<void> {
        this.$scope.refreshReferenceType = async (clear: boolean) => {
            await this.refreshReferenceType(clear);
        }
        this.$scope.setStatus = async () => {
            await this.setStatus();
        }
        this.$scope.viewLog = (entity) => {
            this.viewLog(entity);
        }
        this.$scope.updateCopyGroup = (model) => {
            this.updateCopyGroup(model);
        }
        this.$scope.updateCopyHiddenGroup = (model) => {
            this.updateCopyHiddenGroup(model);
        }
        this.$scope.setDefatultLanguage = async () => {
            await this.setDefatultLanguage();
        }
        this.$scope.addException = (type: string): void => {
            this.addException(type);
        }
        this.$scope.removeException = (index: number): void => {
            this.removeException(index);
        }
        this.$scope.getExceptionListAccountByName = async (search: string) => {
            this.$scope.accountList = [];
            if (search && search.length >= 3) {
                this.block();
                this.$scope.accountList = await this.getAccountListByName(search);
                this.unblock();
            }
        }
        this.$scope.getExceptionListAgentByName = async (search: string) => {
            this.$scope.agentList = [];
            if (search && search.length >= 3) {
                this.block();
                this.$scope.agentList = await this.getAgentListByName(search);
                this.unblock();
            }
        }
    }

    initGridColumns(columns) {
        let columnDefs: Array<IColumnDef> = new Array();

        //action column, contain just buttons and icons
        const view = `<div class="text-center"><a ng-click="grid.appScope.view(row.entity)" class="text-info"tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`
        const edit = `<a ng-click="grid.appScope.edit(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`
        const copy = `<a ng-click="grid.appScope.copy(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a> &nbsp;&nbsp;`
        const history = `<a ng-click="grid.appScope.viewLog(row.entity)" class="text-green" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a></div>`

        columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + copy + history),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        let NewColumnDefs = this.buildColunms(columns);
        for (let column of NewColumnDefs) { columnDefs.push(column) }
        return columnDefs;
    }

    buildColunms(column, prop?): any {
        try {
            let columnDefs = new Array();
            for (var property in column) {
                if (typeof (column[property]) === 'string') { //finally reached the bottom
                    let currentProperty = column[property];
                    let name = null;
                    let displayName = null;
                    let width = 0;
                    let cellTemplate = undefined;
                    let cellFilter = null;
                    let grouping = null;
                    let sort = null;
                    let pinned = null;
                    let visible = true;
                    let filter = { condition: this.gridService.filterSelectObject };
                    switch (currentProperty.toUpperCase()) {
                        case 'STATUS':
                            name = currentProperty + '.NAME';
                            displayName = 'GENERAL.ACTIVE';
                            width = currentProperty.length;
                            break;
                        case 'FOLLOWUP_MODEL':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.FOLLOW_UP';
                            width = currentProperty.length + 12;
                            break;
                        case 'REFERENCE_TYPE':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.REFERENCE_TYPE';
                            width = currentProperty.length;
                            break;
                        case 'REFERENCE':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.REFERENCE';
                            width = currentProperty.length + 6;
                            break;
                        case 'REFERENCE_DATE':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.REFERENCE_DATE';
                            width = currentProperty.length + 1;
                            break;
                        case 'EVENT':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.EVENT';
                            width = currentProperty.length + 6;
                            break;
                        case 'OPERATION':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.OPERATION';
                            width = currentProperty.length + 4;
                            break;
                        case 'MODAL':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.MODE_OF_TRANSPORT';
                            width = currentProperty.length + 4;
                            break;
                        case 'MESSAGE_GROUP':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.GROUP_SEND_MESSAGE';
                            cellTemplate = '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.MESSAGE_GROUP)}}</div>';
                            width = currentProperty.length + 6;
                            break;
                        case 'ROLE':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.RESPONSIBLE_ROLE';
                            width = currentProperty.length + 8;
                            break;
                        case 'TYPE':
                            name = currentProperty + '.NAME';
                            displayName = 'GENERAL.TYPE';
                            width = currentProperty.length + 4;
                            break;
                        case 'RECIPIENT':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.RECIPIENT';
                            width = currentProperty.length + 4;
                            break;
                        case 'DEFAULT':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.STANDARD';
                            width = currentProperty.length + 2;
                            break;
                        case 'INTERACTION':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.INTERACTION';
                            width = currentProperty.length;
                            break;
                        case 'NOTIFY':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.NOTIFY';
                            width = currentProperty.length + 2;
                            break;
                        case 'ATTACHMENT':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.EMAIL_ATTACHMENT';
                            width = currentProperty.length + 2;
                            break;
                        case 'DOCUMENT_TYPE':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.DOCUMENT_TYPE';
                            cellTemplate = '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.DOCUMENT_TYPE)}}</div>';
                            width = currentProperty.length + 4;
                            break;
                        case 'ALL_DOCUMENT':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.ALL_DOCUMENTS_VERSIONS';
                            width = currentProperty.length + 2;
                            break;
                        case 'EXCEPTION':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.EXCEPTION';
                            width = currentProperty.length + 2;
                            break;
                        case 'LANGUAGE':
                            name = currentProperty + '.NAME';
                            displayName = 'GENERAL.LANGUAGE';
                            width = currentProperty.length + 1;
                            break;
                        case 'SURVEY':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.SATISFACTION_SURVEY';
                            width = currentProperty.length + 2;
                            break;
                        case 'FOLLOWUP_AUTOM':
                            name = currentProperty;
                            displayName = 'REGISTRATION.AUTOMATIC_FOLLOW_UP';
                            width = currentProperty.length + 2;
                            cellFilter = 'YesOrNo';
                            break;
                        default:
                            name = currentProperty;
                            width = currentProperty.length;
                            visible = false;
                            break;
                    };
                    const newColumn = {
                        name: (prop) ? prop + '.' + name : name,
                        displayName: displayName,
                        headerCellClass: 'header-filtered',
                        width: width + '%',
                        cellTemplate: cellTemplate,
                        cellFilter: cellFilter,
                        visible: visible,
                        filter: filter
                    };
                    columnDefs.push(newColumn);
                } else {
                    let newCol = this.buildColunms(column[property], property);
                    if (newCol)
                        for (let dados of newCol) {
                            columnDefs.push(dados);
                        }
                }
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    //TODO: YES/NO SELECTOR MODEL SHOULD ALWAYS BE BOOLEAN TYPE
    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            STATUS: { ID: '1', NAME: 'Sim' },
            FOLLOWUP_MODEL: null,
            REFERENCE_TYPE: null,
            REFERENCE: null,
            REFERENCE_DATE: null,
            EVENT: null,
            OPERATION: null,
            MODAL: null,
            MESSAGE_GROUP: null,
            ROLE: null,
            TYPE: null,
            RECIPIENT: null,
            RECIPIENT_INTERNAL_ROLES: null,
            COPY_GROUP: null,
            COPY_GROUP_INTERNAL_ROLES: null,
            COPY_HIDDEN_GROUP: null,
            COPY_HIDDEN_GROUP_INTERNAL_ROLES: null,
            DEFAULT: null,
            INTERACTION: { ID: '2', NAME: 'Não' },
            NOTIFY: { ID: '1', NAME: 'Sim' },
            ATTACHMENT: { ID: '2', NAME: 'Não' },
            DOCUMENT_TYPE: null,
            ALL_DOCUMENT: { ID: '2', NAME: 'Não' },
            EXCEPTION: { ID: '2', NAME: 'Não' },
            LANGUAGE: { ID: '1', NAME: 'pt-BR' },
            SURVEY: { ID: '2', NAME: 'Não' },
            FOLLOWUP_AUTOM: false,
            EXCEPTION_LIST: null
        }
    }

    initDependencies(): Promise<any> {
        const self: FollowUpParamsRegisterController = this;
        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getSelectorInfo(),
                self.getGenericList('oper'),
                self.getGenericList('modal'),
                self.getGenericList('message_group'),
                self.getGenericList('followup_type'),
                self.getGenericList('followup_reference_type'),
                self.getGenericList('followup_reference_date'),
                self.getGenericList('followup_reference_stopover'),
                self.getGenericList('followup_reference_deadline'),
                self.getGenericList('followup_recipient'),
                self.getGenericList('document_type'),
                self.getGenericList('language'),
                self.getGenericList('account_qualification'),
                self.getGenericList('booking_status'),
            ]).then((result: Array<any>) => {
                self.selectorsInfo = result[0].data.data;
                self.$scope.operations = result[1];
                self.$scope.modals = result[2];
                self.$scope.messageGroups = result[3];
                self.$scope.paramTypes = result[4];
                self.$scope.referenceTypes = result[5];
                self.$scope.referenceDateTypes = result[6];
                self.$scope.stopOvers = result[7];
                self.$scope.deadlines = result[8];
                self.$scope.recipients = result[9];
                self.$scope.documentTypes = result[10];
                self.$scope.languages = result[11];
                self.$scope.qualificationList = result[12];
                self.$scope.bookingStatusList = result[13];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getGenericList(type): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private getSelectorInfo(): Promise<any> {
        return this.ExternalService.get({ route: `/followUpParams/selector` });
    }

    private requestHistory(id: string): Promise<any> {
        return this.ExternalService.get({ route: `/followUpParams/viewLog/${id}` });
    }

    private async setStatus(): Promise<void> {
        const status = this.$scope.model.STATUS;
        if (status)
            if (status.ID == '2') {
                await this.$scope.disableElements(true, ['activeSelector']);
            } else {
                await this.$scope.disableElements(false)
            }
    }
    private async setDefatultLanguage(): Promise<void> {
        const recipient = this.$scope.model.RECIPIENT;
        if (recipient && recipient.ID != '1') {
            this.$scope.model.LANGUAGE = this.$scope.languages.find(x => x.ID == '1'); //pt-br
        }
    }

    private async refreshReferenceType(clear: boolean): Promise<void> {
        try {
            let referenceType = this.$scope.model.REFERENCE_TYPE;
            if (!referenceType) return;

            if (clear) {
                this.$scope.model.REFERENCE = null;
                this.$scope.model.REFERENCE_DATE = null;
                this.$scope.model.EVENT = null;
            }

            if (referenceType.ID == '1') { //Atividade
                this.$scope.references = (this.tasks && this.tasks.length > 0) ? this.tasks : [];
                this.$scope.model.REFERENCE_DATE = { ID: '2', NAME: 'Data efetiva' };
                this.$scope.selectorValidity('referenceDateSelector');
            }

            if (referenceType.ID == '2')
                this.$scope.references = (this.events && this.events.length > 0) ? this.events : [];

            if (referenceType.ID == '3') {
                this.$scope.references = (this.$scope.stopOvers && this.$scope.stopOvers.length > 0) ? this.$scope.stopOvers : [];

                if (this.$scope.references.length > 0)
                    if (this.$scope.model.MODAL.ID == '1') { //Aéreo
                        this.$scope.references = this.$scope.references.filter(ref => ref['ID'] === '5');
                        if (this.$scope.references.length > 0)
                            this.$scope.model.REFERENCE = this.$scope.references[0];

                    } else
                        this.$scope.references = this.$scope.references.filter(ref => ref['ID'] !== '5');
            }

            if (referenceType.ID == '4') { //Deadline
                this.$scope.references = (this.$scope.deadlines && this.$scope.deadlines.length > 0) ? this.$scope.deadlines : [];
                this.$scope.model.REFERENCE_DATE = { ID: '2', NAME: 'Data efetiva' };
                this.$scope.selectorValidity('referenceDateSelector');

            }

            if (referenceType.ID == '5') { //Novas Atividade
                this.newTasks = await this.getTaskParams();
                this.$scope.references = (this.newTasks && this.newTasks.length > 0) ? this.newTasks : [];
                this.$scope.model.REFERENCE_DATE = { ID: '2', NAME: 'Data efetiva' };
                this.$scope.selectorValidity('referenceDateSelector');
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getTaskParams() {
        try {
            const taskService = new TaskService(this.$injector);
            const taskReq: IMonacoRequest = { data: null, route: `/param/list`, timeout: 10000 }
            const taskParams = await taskService.post(taskReq);
            return taskParams.data.data.data ? taskParams.data.data.data.map(x => {
                return {
                    ID: x.ID,
                    NAME: `${x.TASK_NUMBER} - ${x.TASK.NAME}`,
                    CODE: x.TASK_NUMBER
                }
            }) : [];
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async setSelectorsData(): Promise<void> {

        if (!this.selectorsInfo) {
            const errorMsg = this.formService.getTranslate("REGISTRATION.DATA_ERROR");
            this.handleError(errorMsg);
            return;
        }

        if (this.selectorsInfo['FOLLOWUP_MODELS'])
            this.$scope.followup_models = this.selectorsInfo['FOLLOWUP_MODELS'];

        if (this.selectorsInfo['CELLROLES'])
            this.$scope.responsibleRoles = this.selectorsInfo['CELLROLES'];

        //References
        if (this.selectorsInfo['TASKS'])
            this.tasks = this.selectorsInfo['TASKS'];

        if (this.selectorsInfo['EVENTS']) {
            this.events = this.selectorsInfo['EVENTS'];
            this.$scope.events = this.events;
        }

        if (this.selectorsInfo['NEW_TASK']) {
            this.newTasks = this.selectorsInfo['NEW_TASK'];
        }
    }

    private async addException(type: string): Promise<void> {
        try {

            const exception: EXCEPTION_LIST = {
                CUSTOMER: null,
                CUSTOMER_QUALIFICATION: null,
                AGENT: null,
                BOOKING_STATUS: null
            }

            switch (type.toUpperCase()) {
                case 'CUSTOMER':
                    if (this.$scope.model.EXCEPTION_LIST && this.$scope.model.EXCEPTION_LIST.length > 0 &&
                        (this.$scope.model.EXCEPTION_LIST[0].CUSTOMER_QUALIFICATION !== null ||
                            this.$scope.model.EXCEPTION_LIST[0].AGENT !== null ||
                            this.$scope.model.EXCEPTION_LIST[0].BOOKING_STATUS !== null)) {
                        const modal = await this.ModalService.showModalConfirmation({}, {
                            actionButtonText: "GENERAL.CONFIRM",
                            closeButtonText: "GENERAL.NO",
                            headerText: "GENERAL.CONFIRM_ACTION",
                            bodyText: this.formService.getTranslate("REGISTRATION.ADD_CUSTOMER")
                        });
                        if (!modal) return
                        this.$scope.model.EXCEPTION_LIST = [];
                    }
                    exception.CUSTOMER = { ID: null, NAME: null };
                    break;
                case 'CUSTOMER_QUALIFICATION':
                    if (this.$scope.model.EXCEPTION_LIST && this.$scope.model.EXCEPTION_LIST.length > 0 &&
                        (this.$scope.model.EXCEPTION_LIST[0].CUSTOMER !== null ||
                            this.$scope.model.EXCEPTION_LIST[0].AGENT !== null ||
                            this.$scope.model.EXCEPTION_LIST[0].BOOKING_STATUS !== null)) {
                        const modal = await this.ModalService.showModalConfirmation({}, {
                            actionButtonText: "GENERAL.CONFIRM",
                            closeButtonText: "GENERAL.NO",
                            headerText: "GENERAL.CONFIRM_ACTION",
                            bodyText: this.formService.getTranslate("REGISTRATION.ADD_QUALIFICATION")
                        });
                        if (!modal) return;
                        this.$scope.model.EXCEPTION_LIST = [];
                    }
                    exception.CUSTOMER_QUALIFICATION = { ID: null, NAME: null };
                    break;
                case 'AGENT':
                    if (this.$scope.model.EXCEPTION_LIST && this.$scope.model.EXCEPTION_LIST.length > 0 &&
                        (this.$scope.model.EXCEPTION_LIST[0].CUSTOMER !== null ||
                            this.$scope.model.EXCEPTION_LIST[0].CUSTOMER_QUALIFICATION !== null ||
                            this.$scope.model.EXCEPTION_LIST[0].BOOKING_STATUS !== null)) {
                        const modal = await this.ModalService.showModalConfirmation({}, {
                            actionButtonText: "GENERAL.CONFIRM",
                            closeButtonText: "GENERAL.NO",
                            headerText: "GENERAL.CONFIRM_ACTION",
                            bodyText: this.formService.getTranslate("REGISTRATION.ADD_AGENT")
                        });
                        if (!modal) return;
                        this.$scope.model.EXCEPTION_LIST = [];
                    }
                    exception.AGENT = { ID: null, NAME: null };
                    break;
                case 'BOOKING_STATUS':
                    if (this.$scope.model.EXCEPTION_LIST && this.$scope.model.EXCEPTION_LIST.length > 0 &&
                        (this.$scope.model.EXCEPTION_LIST[0].CUSTOMER !== null ||
                            this.$scope.model.EXCEPTION_LIST[0].CUSTOMER_QUALIFICATION !== null ||
                            this.$scope.model.EXCEPTION_LIST[0].AGENT !== null)) {
                        const modal = await this.ModalService.showModalConfirmation({}, {
                            actionButtonText: "GENERAL.CONFIRM",
                            closeButtonText: "GENERAL.NO",
                            headerText: "GENERAL.CONFIRM_ACTION",
                            bodyText: this.formService.getTranslate("REGISTRATION.ADD_BOOKING_STATUS")
                        });
                        if (!modal) return;
                        this.$scope.model.EXCEPTION_LIST = [];
                    }
                    exception.BOOKING_STATUS = { ID: null, NAME: null };
                    break;
            }

            if (!this.$scope.model.EXCEPTION_LIST) this.$scope.model.EXCEPTION_LIST = [];
            this.$scope.model.EXCEPTION_LIST.push(exception);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeException(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.$scope.model.EXCEPTION_LIST && this.$scope.model.EXCEPTION_LIST.length > 0) {
                this.formService.block();
                this.$scope.model.EXCEPTION_LIST.splice(index, 1);
                this.formService.unblock();
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async getAccountListByName(search: string): Promise<SelectorModel[]> {
        try {
            const accountList: ISelectorModel[] = []
            const request: IMonacoRequest = {
                data: {
                    search
                },
                route: `/account/list/custom`,
                timeout: 30000,
            }
            const result = await this.ProductService.post(request)
            if (result && result.data && result.status == 200) {
                const accountResult: IAccountSelector[] = result.data.data.data;
                for (const resultItem of accountResult) {
                    const account: ISelectorModel = { ID: resultItem.ID, CODE: (resultItem.LEGAL_PERSON && resultItem.LEGAL_PERSON.ID_FISCAL) ? resultItem.LEGAL_PERSON.ID_FISCAL : null, NAME: resultItem.NAME }
                    accountList.push(account);
                }
            }
            return accountList;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getAgentListByName(search: string): Promise<SelectorModel[]> {
        try {
            const agentList: ISelectorModel[] = []
            const filter: IAgentListCustomFilter = { search: search, networks: null, products: [] }
            const request: IMonacoRequest = {
                data: {
                    ...filter
                },
                route: `/agent/list/custom`,
                timeout: 30000,
            }
            const result = await this.ProductService.post(request)
            if (result && result.data && result.status == 200) {
                const agentResult: IAgentSelector[] = result.data.data.data;
                for (const resultItem of agentResult) {
                    const legalPerson: ISelectorModel = { ID: resultItem.ID, NAME: resultItem.NAME, CODE: resultItem.NETWORK_ID }
                    agentList.push(legalPerson);
                }
            }

            return agentList;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        BrowserTitle.$id = this.$scope.model.FOLLOWUP_MODEL.NAME;

        if (this.$scope.model.COPY_GROUP_INTERNAL_ROLES && this.$scope.model.COPY_GROUP_INTERNAL_ROLES.length > 0) {
            if (this.$scope.model.COPY_GROUP_INTERNAL_ROLES.findIndex((x => x.ID === '4')) !== -1) this.$scope.copyGroupHasInternal = true;
        }

        if (this.$scope.model.COPY_HIDDEN_GROUP_INTERNAL_ROLES && this.$scope.model.COPY_HIDDEN_GROUP_INTERNAL_ROLES.length > 0) {
            if (this.$scope.model.COPY_HIDDEN_GROUP_INTERNAL_ROLES.findIndex((x => x.ID === '4')) !== -1) this.$scope.copyHiddenGroupHasInternal = true;
        }
    }

    async register(): Promise<void> {
        await this.setSelectorsData();
    }

    async edit(): Promise<void> {
        BrowserTitle.$id = this.$scope.model.FOLLOWUP_MODEL.NAME;

        await this.setSelectorsData();
        await this.refreshReferenceType(false);
        await this.setStatus();

        if (this.$scope.model.COPY_GROUP_INTERNAL_ROLES && this.$scope.model.COPY_GROUP_INTERNAL_ROLES.length > 0) {
            if (this.$scope.model.COPY_GROUP_INTERNAL_ROLES.findIndex((x => x.ID === '4')) !== -1) this.$scope.copyGroupHasInternal = true;
        }

        if (this.$scope.model.COPY_HIDDEN_GROUP_INTERNAL_ROLES && this.$scope.model.COPY_HIDDEN_GROUP_INTERNAL_ROLES.length > 0) {
            if (this.$scope.model.COPY_HIDDEN_GROUP_INTERNAL_ROLES.findIndex((x => x.ID === '4')) !== -1) this.$scope.copyHiddenGroupHasInternal = true;
        }

        this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
    }

    async postEdit(): Promise<void> {
        await this.setStatus();
    }

    async request(): Promise<IMonacoRequest> {
        let request;

        if (this.$scope.model.EXCEPTION_LIST && this.$scope.model.EXCEPTION_LIST.length > 0) {
            const errorMsgClient = this.formService.getTranslate("REGISTRATION.SET_CUSTOMER");
            const errorMsgQualification = this.formService.getTranslate("REGISTRATION.SET_QUALIFICATION");
            const errorMsgAgent = this.formService.getTranslate("REGISTRATION.SET_AGENT");
            const errorMsgStatusBooking = this.formService.getTranslate("REGISTRATION.SET_BOOKING_STATUS");
            for (let exceptionList of this.$scope.model.EXCEPTION_LIST) {
                if (exceptionList.CUSTOMER && exceptionList.CUSTOMER.ID == null) {
                    throw Error(errorMsgClient);
                }
                if (exceptionList.CUSTOMER_QUALIFICATION && exceptionList.CUSTOMER_QUALIFICATION.ID == null) {
                    throw Error(errorMsgQualification);
                }
                if (exceptionList.AGENT && exceptionList.AGENT.ID == null) {
                    throw Error(errorMsgAgent);
                }
                if (exceptionList.BOOKING_STATUS && exceptionList.BOOKING_STATUS.ID == null) {
                    throw Error(errorMsgStatusBooking);
                }
            }
        }
        else {
            this.$scope.model.EXCEPTION_LIST = null;
        }

        if (this.$scope.operation === 'delete') {
            request = {
                route: `/followUpParams/delete/${this.$scope.model._id}`,
                timeout: 15000
            };
        } else {
            request = {
                route: `/followUpParams/insert`,
                data: {
                    model: angular.copy(this.$scope.model),
                    beforeScope: angular.copy(this.$scope.scopeBeforeSave),
                },
                operation: this.$scope.operation,
                timeout: 15000
            };
        }
        return request;
    }

    private viewLog(followUpModel) {
        this.block();

        let log: IViewLog = {
            operation: 'history',
            number: followUpModel.ID,
            list: [],
            show: true,
            searchQuery: '',
            originalList: [],
        }

        this.requestHistory(log.number).then(result => {
            log.list = result && result.data && result.data.data;
            log.originalList = angular.copy(log.list);
            this.$scope.log = log;
            angular.element('#log-viewer').removeClass('ng-hide');
            const position = angular.element('#log-viewer').offset().top + $('.app-content-body').scrollTop() - 105;
            $('.app-content-body').animate({
                scrollTop: position
            }, 500);

            this.unblock();
        }).catch(ex => {
            this.handleError(ex);
        });
    };

    private updateCopyGroup(model: SelectorModel[]) {
        try {
            this.$scope.model.COPY_GROUP_INTERNAL_ROLES = null;
            this.$scope.copyGroupHasInternal = false;

            if (model && model.length > 0) {
                const internalIndex = model.findIndex((x => x.ID === '4'));
                if (internalIndex !== -1) this.$scope.copyGroupHasInternal = true;
            }

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private updateCopyHiddenGroup(model: SelectorModel[]) {
        try {
            this.$scope.model.COPY_HIDDEN_GROUP_INTERNAL_ROLES = null;
            this.$scope.copyHiddenGroupHasInternal = false;

            if (model && model.length > 0) {
                const internalIndex = model.findIndex((x => x.ID === '4'));
                if (internalIndex !== -1) this.$scope.copyHiddenGroupHasInternal = true;
            }

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'LANGUAGE',
                LABEL: 'GENERAL.LANGUAGE'
            },
            {
                PROPERTY: 'STATUS',
                LABEL: 'OPERATIONAL.STATUS'
            },
            {
                PROPERTY: 'CARGO_TYPE',
                LABEL: 'BASIC_DATA.CARGO_TYPE'
            },
            {
                PROPERTY: 'FOLLOWUP_MODEL',
                LABEL: 'REGISTRATION.TEMPLATE_MODEL'
            },
            {
                PROPERTY: 'REFERENCE_TYPE',
                LABEL: 'REGISTRATION.REFERENCE_TYPE'
            },
            {
                PROPERTY: 'REFERENCE',
                LABEL: 'REGISTRATION.REFERENCE'
            },
            {
                PROPERTY: 'REFERENCE_DATE',
                LABEL: 'REGISTRATION.REFERENCE_DATE'
            },
            {
                PROPERTY: 'EVENT',
                LABEL: 'OPERATIONAL.EVENT'
            },
            {
                PROPERTY: 'OPERATION',
                LABEL: 'REGISTRATION.OPERATION'
            },
            {
                PROPERTY: 'MODAL',
                LABEL: 'REGISTRATION.MODE_OF_TRANSPORT'
            },
            {
                PROPERTY: 'MESSAGE_GROUP',
                LABEL: 'REGISTRATION.GROUP_SEND_MESSAGE'
            },
            {
                PROPERTY: 'ROLE',
                LABEL: 'REGISTRATION.RESPONSIBLE_ROLE'
            },
            {
                PROPERTY: 'RECIPIENT',
                LABEL: 'REGISTRATION.RECIPIENT'
            },
            {
                PROPERTY: 'RECIPIENT_INTERNAL_ROLES',
                LABEL: 'REGISTRATION.RECIPIENT_INTERNAL_ROLE'
            },
            {
                PROPERTY: 'COPY_GROUP',
                LABEL: 'REGISTRATION.CARBON_COPY_EMAIL'
            },
            {
                PROPERTY: 'COPY_GROUP_INTERNAL_ROLES',
                LABEL: 'REGISTRATION.INTERNAL_RESPONSIBLE_COPY'
            },
            {
                PROPERTY: 'COPY_HIDDEN_GROUP',
                LABEL: 'REGISTRATION.BLIND_CARBON_COPY_EMAIL'
            },
            {
                PROPERTY: 'COPY_HIDDEN_GROUP_INTERNAL_ROLES',
                LABEL: 'REGISTRATION.INTERNAL_RESPONSIBLE_CARBON_COPY'
            },
            {
                PROPERTY: 'DEFAULT',
                LABEL: 'REGISTRATION.STANDARD'
            },
            {
                PROPERTY: 'INTERACTION',
                LABEL: 'REGISTRATION.INTERACTION'
            },
            {
                PROPERTY: 'NOTIFY',
                LABEL: 'REGISTRATION.NOTIFY'
            },
            {
                PROPERTY: 'ATTACHMENT',
                LABEL: 'REGISTRATION.EMAIL_ATTACHMENT'
            },
            {
                PROPERTY: 'DOCUMENT_TYPE',
                LABEL: 'REGISTRATION.DOCUMENT_TYPE'
            },
            {
                PROPERTY: 'ALL_DOCUMENT',
                LABEL: 'REGISTRATION.ALL_DOCUMENTS_VERSIONS'
            },
            {
                PROPERTY: 'EXCEPTION',
                LABEL: 'REGISTRATION.EXCEPTION'
            },
            {
                PROPERTY: 'SURVEY',
                LABEL: 'REGISTRATION.SATISFACTION_SURVEY'
            },
            {
                PROPERTY: 'FOLLOWUP_AUTOM',
                LABEL: 'REGISTRATION.AUTOMATIC_FOLLOW_UP'
            },
            {
                PROPERTY: 'EXCEPTION_LIST',
                LABEL: 'REGISTRATION.EXCEPTION_LIST'
            },
            {
                PROPERTY: 'TYPE',
                LABEL: 'REGISTRATION.CONSIDER_RECORD'
            }
        ];
        return props;
    }
}

ErrorService.$inject = ['$state'];
export function ErrorService($state) {
    this.error = new Object();
    this.error.situation = undefined;
    this.error.code = undefined;
    this.error.message = undefined;
    this.error.reason = undefined;

    this.factory = function (status, reason) {
        let error = {
            status: status,
            data: {
                situation: undefined,
                code: undefined,
                message: undefined,
                reason: reason
            }
        }
        this.handleError(error);
    }

    this.setError = function (error) {
        this.error.situation = "An error has occured";
        this.error.code = '#' + error.status;
        this.error.message = error.message;
        if (error.reason) {
            this.error.reason = error.reason;
        }
        //populate the sugestion based on the error code
        this.setSugestion(error.status);
        return $state.go('error');
    };

    this.getError = function () {
        return this.error;
    };

    this.badRequest = function (reason) {
        this.error.situation = "An error has occured";
        this.error.code = '#400';
        this.error.message = "Bad Request";
        this.setSugestion(400);
        if (reason) {
            this.error.reason = reason;
        }
        //$state.go('error', {}, { reload: true });
        return $state.go('error');
    }

    this.notFound = function (reason) {
        this.error.situation = "An error has occured";
        this.error.code = '#404';
        this.error.message = "Not Found";
        this.setSugestion(404);
        if (reason) {
            this.error.reason = reason;
        }
        //$state.go('error', {}, { reload: true });
        return $state.go('error');
    }


    this.unavailable = function (reason) {
        this.error.situation = "An error has occured";
        this.error.code = '#503';
        this.error.message = "Service Temporarily Unavailable";
        this.setSugestion(503);
        if (reason) {
            this.error.reason = reason;
        }
        //$state.go('error', {}, { reload: true });
        return $state.go('error');
    }

    this.internal = function (reason) {
        this.error.situation = "An error has occured";
        this.error.code = '#500';
        this.error.message = "Internal Error";
        this.setSugestion(500);
        if (reason) {
            this.error.reason = reason;
        }
        //$state.go('error', {}, { reload: true });
        return $state.go('error');
    }

    this.setSugestion = function (status) {
        //populate the sugestion based on the error code
        switch (status) {
            case 400:
                this.error.sugestion = "Make sure the request parameters are correct.";
                //bad request
                break;
            case 401:
                this.error.sugestion = "Contact the IT support.";
                break;
            case 404:
                this.error.sugestion = "Resource not found.";
                //not found
                break;
            case 500:
                //internal server error
                this.error.sugestion = "Contact the IT support.";
                break;
            default:
                this.error.sugestion = "Contact the IT support.";
                break;
        }
    }

    this.handleError = function (error) {
        switch (error.status) {
            case -1:
                this.unavailable('Request timeout reached');
                break;
            case 400:
                this.badRequest(error.data.reason);
                break;
            case 404:
                this.notFound(error.data.reason);
                break;
            case 500:
                this.internal(error.data.reason);
                break;
            case 503:
                this.unavailable(error.data.reason);
                break;
            default:
                this.setError(error.data);
                break;
        }
    }

}
import { IRestService } from "@services/RestService";
import { IMonacoConfig } from "../../common/MonacoInterface";
import { INotificationMessageServiceRequest } from "@models/interface/operational/INotificationMessageServiceRequest";
import { FollowUpParams } from "@models/interface/common/FollowUpParams"
import { INotificationMessageServiceResult } from "@models/interface/operational/INotificationMessageServiceResult";
import { RabbitMQService, IRabbitMQServiceResult } from "@services/RabbitMQService";
import angular = require("angular");
import { CryptoService } from "./CryptoService";

interface IFollowUpParamsProcess {
    followUpParams: FollowUpParams;
    process: string;
}

interface IFollowUpParamsProcesses {
    followUpParams: FollowUpParams;
    processes: string[];
}

export class NotificationMessageService {
    private request: INotificationMessageServiceRequest;
    private restService: IRestService;
    private config: IMonacoConfig;
    private timeout: number;
    private rabbitMQService: RabbitMQService;
    private queueName: string;
    private cryptoService: CryptoService;
    private $scope: ng.IScope;

    constructor(request: INotificationMessageServiceRequest, $injector: ng.Injectable<any>, $scope: ng.IScope) {
        this.$scope = $scope;
        this.timeout = 1800000; // 30 Min
        this.queueName = "EXTERNAL-NotificationMessage-draft";
        this.request = request;
        this.restService = $injector.get('RestService');
        this.config = $injector.get('config');
        this.rabbitMQService = new RabbitMQService();
        this.cryptoService = new CryptoService();
    }

    public async generate(): Promise<INotificationMessageServiceResult[]> {
        try {
            if (!this.request) return [];

            const message = {
                request: this.request,
                timeout: this.timeout
            };

            const rc = await this.restService.newObject(this.config.externalUrl + "/external/notificationMessage/generate", message, this.timeout, false);
            if (rc.status !== 200) throw rc;

            return (rc && rc.data) ? rc.data.data : [];
        } catch (ex) {
            throw ex;
        }
    }

    public async generateOnQueue(invoiceNumber: string): Promise<INotificationMessageServiceResult[]> {
        try {
            const resultList: INotificationMessageServiceResult[] = [];

            if (!this.request) return resultList;

            for (const process of this.request.processNumberList) {
                const voucher: string = await this.cryptoService.generateUniqueRandomId();

                const processRequest: INotificationMessageServiceRequest = angular.copy(this.request);

                processRequest.processNumberList = [process];
                processRequest.voucher = voucher;
                processRequest.user = this.$scope["user"];
                processRequest.invoiceNumber = invoiceNumber;

                const rc: IRabbitMQServiceResult = await this.rabbitMQService.send(this.queueName, processRequest, this.timeout);

                if (!rc || !rc.routed) throw new Error(`Generate on queue error ${JSON.stringify(this.request)}`);

                resultList.push(<INotificationMessageServiceResult>{
                    processNumber: process,
                    generated: true,
                    voucher,
                });
            }
            return resultList;
        } catch (ex) {
            throw ex;
        }
    }

    public async getFollowUpParams(): Promise<IFollowUpParamsProcesses[]> {
        try {
            const followUpParamsResult: IFollowUpParamsProcesses[] = [];

            if (!this.request) return null;;

            const message = {
                request: this.request,
                timeout: this.timeout
            };

            const rc = await this.restService.newObject(this.config.externalUrl + "/external/notificationMessage/getFollowUpParams", message, this.timeout, false);
            if (rc.data.data.hasError) throw rc;

            const result: IFollowUpParamsProcess[] = (rc && rc.data) ? rc.data.data.data : [];

            if (result) {
                const arrayFollowUpParams = result.sort(function (a, b) {
                    if (a.followUpParams.ID > b.followUpParams.ID) return 1;
                    if (a.followUpParams.ID < b.followUpParams.ID) return -1;
                });

                let followUpParamsID = null;
                let processes = [];
                let followUpParams: FollowUpParams = null;

                for (const result of arrayFollowUpParams) {
                    if (followUpParamsID !== result.followUpParams.ID && (followUpParamsID)) {
                        followUpParamsResult.push({ processes: processes, followUpParams: followUpParams });

                        followUpParamsID = result.followUpParams.ID;
                        followUpParams = result.followUpParams;
                        processes = [result.process];

                    } else if (result.followUpParams && followUpParamsID === result.followUpParams.ID) { //se é igual e nao é null
                        processes.push(result.process);
                    } else {
                        followUpParamsID = result.followUpParams.ID;
                        followUpParams = result.followUpParams;
                        processes = [result.process];
                    }
                }
                followUpParamsResult.push({ processes: processes, followUpParams: followUpParams });
            }
            return (followUpParamsResult);
        } catch (ex) {
            throw ex;
        }
    }
}

import * as angular from "angular";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog, } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IAgencyModel } from "../model/AgencyModel";
import { SelectorModel } from "../../common/model/SelectorModel";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { ISessionService } from "@services/SessionService";
import { ILinkParameter } from "../../common/model/ModelParameter";
import { ILegalPersonExchangeData, ILegalPersonSelector, ILegalPersonAddress } from "../model/LegalPersonModel";
import { StringUtil } from "../../common/util/StringUtil";
import { IProviderModel } from "@models/interface/product/ProviderModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { SSEService } from "@appServices/SSEService"
import { EOperation } from "@enums/GenericData";
import { IDocumentError } from "@models/interface/common/IDocumentError";
import { IModalService } from "@services/ModalService";
import { HelperService } from "@services/HelperService";

interface IProviderSelector extends SelectorModel {
    ID_LEGAL_PERSON: number;
}

interface IAgencyScope extends IGridFormServiceScope {
    model: IAgencyModel;
    log: IViewLog;
    legalPersonList: SelectorModel[];
    routingPointList: SelectorModel[];
    productList: SelectorModel[];
    providerList: SelectorModel[];
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IAgencyModel;
    scacLataLengthValidation: number;
    user: any;
    sessionService: ISessionService;
    editAgency: (agency: IAgencyModel) => Promise<void>;
    viewAgency: (agency: IAgencyModel) => Promise<void>;
    viewLogAgency: (agency: IAgencyModel) => Promise<void>;
    copyAgency: (agency: IAgencyModel) => Promise<void>;
    getLegalPersonListByName: (search: string) => Promise<void>;
    getProviderListByName: (search: string) => Promise<void>;
    getRoutingPointListByName: (search: string) => Promise<void>;
    goToLegalPerson: (id: number) => void;
    goToProvider: (providers: IProviderModel[]) => void;
    goToRoutingPoint: (routingPoints: SelectorModel[]) => void;
    limitLines: (value: string, limit: number) => string;
    selectLegalPerson: (legalPerson: SelectorModel) => void;
    productChange: () => void;
    productListFilter: () => Function;
    correspondenceGenerate: () => void;
    openModalIntegration: (id: number, documentErrorList: IDocumentError[], name: string) => void;
    fetchData: (id: number, action: string) => Promise<void>;
}

export class AgencyRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IAgencyScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private $timeout: ng.ITimeoutService;
    private gridName: string;
    private SSEService: SSEService;
    private ModalService: IModalService;
    private modalID: number;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IAgencyScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.$timeout = $injector.get('$timeout');
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.gridName = "gridAgency"
        this.modalID = 0;
        this.ModalService = $injector.get('ModalService');
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'agency', 'GENERAL.MENU.AGENCY', true);
            await this.initGrid('gridAgency', '/agency/list', true, true, null, true, true);

            const sessionParameter = this.$gridService.$sessionParameter;
            if (sessionParameter && sessionParameter.data) this.callSessionFunctions(sessionParameter.data);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editAgency = async (agency: IAgencyModel): Promise<void> => {
            let blockedObject = {
                ID: agency.ID,
                NAME: agency.NAME,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || agency.ID !== this.$scope.model.ID) this.$scope.fetchData(agency.ID, EOperation.VIEW);
                } else if (this.$scope.operation !== EOperation.EDIT || agency.ID !== this.$scope.model.ID) {
                    this.$scope.fetchData(agency.ID, EOperation.EDIT);
                }
            };
        }

        this.$scope.viewAgency = async (agency: IAgencyModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(agency.ID, EOperation.VIEW);
        }

        this.$scope.viewLogAgency = async (agency: IAgencyModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(agency);
        }

        this.$scope.copyAgency = async (agency: IAgencyModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(agency.ID, EOperation.COPY);
        }

        this.$scope.getLegalPersonListByName = async (search: string) => {
            let legalPersonSelectorList: ILegalPersonSelector[] = [];
            if (search && search.length >= 3) {
                legalPersonSelectorList = await this.getLegalPersonListByName(search);
            }
            this.$scope.legalPersonList = legalPersonSelectorList;
        }

        this.$scope.viewLogAgency = async (agency: IAgencyModel): Promise<void> => {
            this.$scope.viewLog(agency);
        }

        this.$scope.copyAgency = async (agency: IAgencyModel): Promise<void> => {
            this.$scope.fetchData(agency.ID, 'copy');
        }

        this.$scope.getProviderListByName = async (search: string) => {
            let providerList = [];
            if (search && search.length >= 3) {
                if (!this.$scope.model.LEGAL_PERSON) {
                    this.$formService.notifyError(this.formService.getTranslate("PRODUCT.SELECT_LEGAL_PERSON_TO_SELECT_PROVIDER_FIRST"));
                } else {
                    providerList = await this.getProviderListByName(search);
                    providerList = this.$scope.model.LEGAL_PERSON ? providerList.filter(provider => provider.ID_LEGAL_PERSON !== this.$scope.model.LEGAL_PERSON.ID) : providerList;
                }
            }
            this.$scope.providerList = providerList;
        }
        this.$scope.goToLegalPerson = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.legalPerson", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.$scope.goToProvider = (providers: IProviderModel[]): void => {
            this.$scope.sessionService.openTab("app.registration.provider", <ILinkParameter>{ ID: this.$scope.getCONCAT(providers, null, "ID") });
        }
        this.$scope.goToRoutingPoint = (routingPoints: SelectorModel[]): void => {
            this.$scope.sessionService.openTab("app.registration.routingPoint", <ILinkParameter>{ ID: this.$scope.getCONCAT(routingPoints, null, "ID") });
        }
        this.$scope.getRoutingPointListByName = async (search: string) => {
            let routingPointList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                routingPointList = await this.getRoutingPointListByName(search);
            }
            this.$scope.routingPointList = routingPointList;
        }
        this.$scope.limitLines = (value: string, limit: number) => {
            return StringUtil.limitLines(value, limit);
        }
        this.$scope.selectLegalPerson = (legalPerson: SelectorModel) => {
            if (this.$scope.model.NAME == null || this.$scope.model.NAME == "") this.$scope.model.NAME = legalPerson.NAME;
            if (this.$scope.model.PROVIDERS) this.$scope.model.PROVIDERS = this.$scope.model.PROVIDERS.filter(provider => provider.CODE !== legalPerson.ID);
        }
        this.$scope.productChange = (): void => {
            this.$scope.model.ROUTING_POINTS = null;
            this.$timeout(() => { this.$scope.selectorValidity("place") });
        }
        this.$scope.productListFilter = (): Function => {
            return this.productListFilter();
        }

        this.$scope.correspondenceGenerate = async () => {
            try {
                const legalPerson = this.$scope.model.LEGAL_PERSON
                if (!legalPerson) throw Error(this.formService.getTranslate("PRODUCT.SELECT_LEGAL_PERSON_FIRST"));

                const legalPersonAddress: ILegalPersonAddress = await this.getLegalPersonAddressById(legalPerson.ID)

                if (legalPersonAddress) {
                    const idFiscal = legalPersonAddress.ID_FISCAL;
                    const fantasyName = legalPersonAddress.FANTASY_NAME;
                    const city = legalPersonAddress.ADDRESS.CITY;

                    const publicPlace = legalPersonAddress.ADDRESS.PUBLIC_PLACE;
                    const number = legalPersonAddress.ADDRESS.NUMBER;
                    const zipCode = legalPersonAddress.ADDRESS.ZIP_CODE;
                    const complement = legalPersonAddress.ADDRESS.COMPLEMENT;
                    const district = legalPersonAddress.ADDRESS.DISTRICT;

                    let string = '';
                    if (publicPlace && publicPlace != '') string += `${publicPlace}`;
                    if (number && number !== '') string += `${string !== '' ? ' ' : ''}${number}`;
                    if (complement && complement !== '') string += `${string !== '' ? ' ' : ''}${complement}`;
                    if (district && district !== '') string += `${string !== '' ? ', ' : ''}${district}`;

                    let address = `${fantasyName}` + "\n"
                    if (string !== '') address += string + "\n";
                    address += `${city.NAME}, ${city.PROVINCE.CODE} - ${city.PROVINCE.COUNTRY.NAME}` + (zipCode && zipCode !== '' ? ` CEP: ${zipCode}` : '');
                    address += "\n" + `${legalPersonAddress.TYPE.NAME}: ${idFiscal}`

                    if (!publicPlace || !district) this.formService.notifyWarning(this.formService.getTranslate("REGISTRATION.MESSAGES.ERROR.MISSING_ADDRESS_IN_LEGAL_PERSON_TO_GENERATE_CORRESPONDENCE"));
                    this.$scope.model.CORRESPONDENCE = address;
                } else throw Error(this.formService.getTranslate("REGISTRATION.MESSAGES.ERROR.LEGAL_PERSON_NOT_FOUND"));
            } catch (ex) {
                this.formService.handleError(ex);
            }
        }

        this.$scope.openModalIntegration = (id: number, documentErrorList: IDocumentError[]) => {
            this.openModalIntegration(id, documentErrorList);
        }

        this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
            this.fetchData(id, action);
        }
    };

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            ID_LEGAL_PERSON: null,
            LEGAL_PERSON: null,
            NAME: null,
            PRODUCT: null,
            PROVIDERS: null,
            ACTIVE: true,
            ROUTING_POINTS: [],
            CORRESPONDENCE: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            SEARCH_FIELDS: null,
            DOCUMENT_ERROR: null
        };
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        try {
            const gridColumns = new GridColumnBuilder([]);

            const view = `<a ng-click="grid.appScope.viewAgency(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true"><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
            const edit = `<a ng-click="grid.appScope.editAgency(row.entity)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true"><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
            const viewLog = `<a ng-click="grid.appScope.viewLogAgency(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true"><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
            const copy = `<a ng-click="grid.appScope.setCopy(true);grid.appScope.copyAgency(row.entity)" class="text-orange copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true"><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
            const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;`;

            const colActions: IMonacoColumnDef = {
                name: "acoes", displayName: "GENERAL.ACTIONS",
                width: 125,
                cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${viewLog} ${copy} ${modalIntegration}</div>`,
                enableFiltering: false,
                enableSorting: false,
                enableHiding: false,
                enableColumnMoving: false,
                enableColumnResizing: false,
                pinnedLeft: true,
                enablePinning: false
            };

            gridColumns.addColumn(colActions);
            const newColumnDefs: IMonacoColumnDef[] = this.buildColumns(columns);
            for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) };

            return <uiGrid.IColumnDef[]>gridColumns.$columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colCorporateName: IMonacoColumnDef = { name: "LEGAL_PERSON.CORPORATE_NAME", displayName: "ENTITY.CORPORATE_NAME", width: 250 };
            const colName: IMonacoColumnDef = { name: "NAME", displayName: "GENERAL.NAME", width: 250 };
            const colProvider: IMonacoColumnDef = { name: "PROVIDERS.NAME", displayName: "BASIC_DATA.PROVIDER", width: 300, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PROVIDERS, null, "CODE")}}</div>' };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 300, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PRODUCT, null, "ID")}}</div>' };
            const colRoutingPoint: IMonacoColumnDef = { name: "ROUTING_POINTS.NAME", displayName: "GENERAL.LOCAL", width: 300, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ROUTING_POINTS, null, "NAME")}}</div>' };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 100, cellFilter: "YesOrNo" };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'ID_LEGAL_PERSON':
                        columnDefs.push(colCorporateName);
                        break;
                    case 'NAME':
                        columnDefs.push(colName);
                        break;
                    case 'ID_LEGAL_PERSON_PROVIDER':
                        columnDefs.push(colProvider);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'ROUTING_POINTS':
                        columnDefs.push(colRoutingPoint);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'PROVIDERS':
                        columnDefs.push(colProvider);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initDependencies(): Promise<any> {
        const self: AgencyRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getProductList(),
            ]).then((result: any) => {
                self.$scope.productList = self.resultProducts(result[0]);
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private resultProducts = (productList: SelectorModel[]): SelectorModel[] => {
        if (!productList) throw Error('productList is null!');
        const result: SelectorModel[] = [];
        for (const product of productList) {
            if (product.ID != 'IR' && product.ID != 'ER') {
                result.push(product);
            }
        }
        return result;
    }

    private callSessionFunctions(data: object): void {
        const legalPersonExchangeData = <ILegalPersonExchangeData>data;
        switch (legalPersonExchangeData.OPERATION) {
            case "register": this.$scope.register(legalPersonExchangeData.MODEL);
                break;
        }
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlDataManager = this.config.productUrl + baseRoute;
        return urlDataManager;
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: 'ID',
                LABEL: 'REGISTRATION.IDENTIFICATION'
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
            {
                PROPERTY: 'LEGAL_PERSON',
                LABEL: 'ENTITY.CORPORATE_NAME'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'PROVIDERS',
                LABEL: 'BASIC_DATA.PROVIDER'
            },
            {
                PROPERTY: 'PRODUCT',
                LABEL: 'BASIC_DATA.PRODUCT'
            },
            {
                PROPERTY: 'CORRESPONDENCE',
                LABEL: 'ENTITY.POSTAL_ADDRESS'
            },
            {
                PROPERTY: 'SHORT_NAME',
                LABEL: 'ENTITY.SHORT_NAME'
            },
            {
                PROPERTY: 'CORPORATE_NAME',
                LABEL: 'ENTITY.CORPORATE_NAME'
            },
            {
                PROPERTY: 'FANTASY_NAME',
                LABEL: 'ENTITY.TRADE_NAME'
            },
            {
                PROPERTY: 'LEGAL_PERSON_NAME',
                LABEL: 'ENTITY.LEGAL_PERSON'
            },
            {
                PROPERTY: 'SCAC_IATA',
                LABEL: 'SCAC/IATA'
            },
            {
                PROPERTY: 'CODE',
                LABEL: 'GENERAL.CODE'
            },
            {
                PROPERTY: 'ROUTING_POINT',
                LABEL: 'GENERAL.LOCAL'
            },
            {
                PROPERTY: 'TYPE',
                LABEL: 'GENERAL.TYPE'
            },
            {
                PROPERTY: 'NAME_INTL',
                LABEL: 'GENERAL.NAME_INTL'
            },
            {
                PROPERTY: 'INTEGRATION_ID',
                LABEL: 'GENERAL.INTEGRATION_ID'
            },
            {
                PROPERTY: 'TRADE_GROUP',
                LABEL: 'ROUTE.TRADE_GROUP'
            },
            {
                PROPERTY: 'ROUTING_POINTS',
                LABEL: 'GENERAL.LOCAL'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            }
        ];
        return props;
    }

    private async getLegalPersonListByName(name: string): Promise<ILegalPersonSelector[]> {
        let result = [];
        this.formService.block();
        try {
            if (name && name.length >= 3) {
                const legalPersons = await this.RestService.newObjectPromise(`${this.$baseUrl}/legalPerson/list/custom`, { specializations: [], search: name }, 30000, false);
                if (legalPersons) result = legalPersons.map(legalPerson => { return { ID: legalPerson.ID, CORPORATE_NAME: legalPerson.CORPORATE_NAME, SHORT_NAME: legalPerson.SHORT_NAME } });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getLegalPersonAddressById(id: string): Promise<ILegalPersonAddress> {
        this.formService.block();
        try {
            if (!id) throw Error('Missing id in getLegalPersonAddressById!');

            const request = await this.RestService.getObjectAsPromise(`${this.$baseUrl}/legalPerson/getAddress/${id}`, 30000, null, false);
            const result = request && request.data && request.data[0] ? request.data[0] : null;

            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getRoutingPointListByName(name: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            if (!this.$scope.model.PRODUCT) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
            else {
                const types = [];
                // port
                if (this.$scope.model.PRODUCT.some(product => product.ID == "IM" || product.ID == "EM")) types.push("2");
                // door
                if (this.$scope.model.PRODUCT.some(product => product.ID == "IR" || product.ID == "ER")) types.push("3");
                // airport
                if (this.$scope.model.PRODUCT.some(product => product.ID == "IA" || product.ID == "EA")) types.push("4");
                const routingPoints = await this.RestService.newObjectPromise(`${this.$baseUrl}/routingpoint/list/custom`, { name: name, types: types, countries: [] }, 30000, false);
                if (routingPoints) result = routingPoints.map(routingPoint => { return { ID: routingPoint.ID, NAME: routingPoint.NAME, CODE: routingPoint.CODE } });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getProviderListByName(search: string): Promise<IProviderSelector[]> {
        let result = [];
        this.formService.block();
        try {
            const providers = await this.RestService.newObjectPromise(`${this.$baseUrl}/provider/list/custom`, { search: search, legalPersonIdNotIn: [this.$scope.model.LEGAL_PERSON ? this.$scope.model.LEGAL_PERSON.ID : null] }, 30000, false);
            if (providers) result = providers.map(provider => { return { ID: provider.ID, NAME: provider.NAME, CODE: provider.SCAC_IATA, ID_LEGAL_PERSON: provider.ID_LEGAL_PERSON } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async openModalIntegration(id: number, documentErrorList: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            this.ModalService.showModalIntegrationRedundance({ integrationId: id, documentErrorList: documentErrorList, messageDefault: { awaitingRequest: 'Aguarde, enviando requisição...', successRequest: 'Registros enviados para fila de processamento.', requestError: 'Ocorreu um erro ao enviar a requisição.', awaitingGridSync: 'Aguarde, processando sincronização...', successGridSync: 'Sincronização realizada com sucesso.', gridSyncError: 'Ocorreu um erro durante a sincronização.', logCopyMessage: 'Mensagem de log copiada para o clipboard.' }, fnSync: this.sendSync, headerText: 'Erros de Integração - Agências', actionButtonText: 'Sincronizar agora', closeButtonText: 'Fechar' });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number, idSubject: string): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                this.formService.block();
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/agency/sendSync`, { "idAgency": id, "idSubject": idSubject }, 120000, false);
                if (syncRequest) success = true;
            }
        } catch (ex) {
            this.formService.handleError(this.formService.getTranslate("GENERAL.ERROR_SENDING_REQUEST"));
        } finally {
            this.formService.unblock();
            return success;
        }
    }

    private productListFilter = (): Function => {
        const selectedProduct = this.$scope.model ? this.$scope.model.PRODUCT : null;
        const isAir = selectedProduct && selectedProduct.length && (selectedProduct[0].ID == "EA" || selectedProduct[0].ID == "IA");
        const isMaritime = selectedProduct && selectedProduct.length && (selectedProduct[0].ID == "EM" || selectedProduct[0].ID == "IM");
        return function (product: SelectorModel) {
            if (!selectedProduct || selectedProduct && selectedProduct.length == 0) return true;
            const exists = selectedProduct.find(item => item.ID === product.ID);
            if (isAir && (product.ID == "EA" || product.ID == "IA") && !exists) return true;
            if (isMaritime && (product.ID == "EM" || product.ID == "IM") && !exists) return true;
            return false;
        }
    }

    async register(params: IAgencyModel): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            if (params && params.LEGAL_PERSON && params.LEGAL_PERSON.CORPORATE_NAME) {
                const legalPerson = await this.getLegalPersonListByName(params.LEGAL_PERSON.CORPORATE_NAME);
                if (legalPerson && legalPerson.length > 0) {
                    this.$scope.model.NAME = legalPerson[0].SHORT_NAME;
                    this.$scope.model.LEGAL_PERSON = { ID: legalPerson[0].ID, NAME: legalPerson[0].SHORT_NAME, CODE: legalPerson[0].CORPORATE_NAME, SHORT_NAME: legalPerson[0].SHORT_NAME, CORPORATE_NAME: legalPerson[0].CORPORATE_NAME };
                }
            }
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            BrowserTitle.$id = this.$scope.model.NAME;
            this.SSEService.closeEvents();
            await this.clearFields(this.$scope.model);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} (${this.$scope.model.NAME})`;
            BrowserTitle.$id = this.$scope.model.NAME;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} (${this.$scope.model.NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === "edit" ? "update" : "insert";

        return {
            route: `/agency/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: this.$scope.scopeBeforeSave
        };
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/agency/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }
}
import * as moment from 'moment';
import { IMonacoController } from "../../common/MonacoInterface";
import { FormService2 } from "@services/FormService2";
import { OperationalService } from "@services/OperationalService";
import { Process } from "@models/interface/operational/NewProcess";
import { OfferCompiled } from "@models/interface/product/OfferCompiled";
import { EProcessSituation, EEventType } from '@enums/GenericData';
import { ProductService } from "@services/ProductService";

interface ICompareData {
    quotationList: Pick<OfferCompiled, "ID" | "CONCATENATED" | "VALIDITY_UP" | "VALIDITY_OF">[];
    newQuotationList: Pick<OfferCompiled, "ID" | "CONCATENATED" | "VALIDITY_UP" | "VALIDITY_OF">[];
    selectedQuotation: Pick<OfferCompiled, "ID" | "CONCATENATED" | "VALIDITY_UP" | "VALIDITY_OF">;
    selectedNewQuotation: Pick<OfferCompiled, "ID" | "CONCATENATED" | "VALIDITY_UP" | "VALIDITY_OF">;
    loadRef: Date;
}

interface ICompareProcessScope extends ng.IScope {
    compare: ICompareData
    currentQuotation: string;
    currentProcess: Process;
    origin?: string;
    modalID: number;
    modalOptions: any;
    disabledLoadRef: boolean;
    offerCompiledSelected: Pick<OfferCompiled, "ID" | "CONCATENATED" | "VALIDITY_UP" | "VALIDITY_OF">;
    compareProcess: () => any;
    isPreProcess: () => boolean;
    getLoadRef: () => void;
    validateLoadRef: (date: Date) => void;
    getProcessNewOffers: (search: string) => Promise<void>;
}

export class CompareProcessFromProcessModalController implements IMonacoController {
    public static $inject: string[] = ['$scope', '$injector'];
    private $scope: ICompareProcessScope;
    private $state: any;
    private formService: FormService2;
    private operationalService: OperationalService;
    private productService: ProductService;
    private currentProcess: Process;
    private origin: string;

    constructor($scope, $injector: ng.Injectable<any>) {
        this.$scope = $scope;
        this.$state = $injector.get('$state');
        this.operationalService = $injector.get('OperationalService');
        this.productService = $injector.get('ProductService');
        this.formService = new FormService2($injector, $scope);
        this.currentProcess = this.$scope.currentProcess;
        this.origin = this.$scope.origin;
    }

    async $onInit() {
        this.registerScopeFunctions();
        this.initModel();
        this.formService.block();

        if (!this.isPreProcess()) {
            let operationSuccess = await this.validateLockedInvoices();

            if (!operationSuccess) {
                this.$scope.modalOptions.ok(false);
                this.formService.unblock();
                return false;
            }

            await this.getProcessCurrentOffers();
        }

        this.formService.unblock();
    }

    public async getProcessNewOffers(search: string): Promise<void> {
        try {
            if (search && search.length >= 3) {
                this.formService.block();

                const rc = await this.operationalService.post(`/offerCompiled/processCompareCustom`, {
                    customerId: this.currentProcess.CUSTOMER.ID,
                    productId: this.currentProcess.PRODUCT.ID,
                    search: search ? search : "",
                    isValidOffer: true
                });
                const offerCompiledList: Pick<OfferCompiled, "ID" | "CONCATENATED" | "VALIDITY_UP" | "VALIDITY_OF">[] = (rc && rc.data && rc.data.data) ? rc.data.data : null;

                this.$scope.compare.newQuotationList = offerCompiledList;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private initModel(): void {
        this.$scope.compare = {
            newQuotationList: null,
            quotationList: null,
            selectedNewQuotation: null,
            selectedQuotation: null,
            loadRef: null,
        };
        this.$scope.currentQuotation = null;
        this.$scope.currentProcess = this.currentProcess;
        this.$scope.modalID = null;
    }

    private registerScopeFunctions(): void {
        this.$scope.compareProcess = () => this.compareProcess();
        this.$scope.getLoadRef = () => this.getLoadRef();
        this.$scope.validateLoadRef = (date) => this.validateLoadRef(date);
        this.$scope.getProcessNewOffers = (search) => this.getProcessNewOffers(search);

        this.$scope.isPreProcess = () => this.isPreProcess();
    }

    private async getOfferCodeLink(offerId: number): Promise<string> {
        this.formService.block();
        try {
            const offer = await this.productService.get({ route: `/offer/tabs/main/view/${offerId}` });
            if (offer && offer.data && offer.data.data && offer.data.data.OFFER_CODE_LINK) return offer.data.data.OFFER_CODE_LINK;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getProcessCurrentOffers(): Promise<void> {
        try {
            const offer = this.currentProcess.OFFER.find(item => item.ACTIVE);
            const offerCode = await this.getOfferCodeLink(offer.OFFER_ID);

            const rc = await this.operationalService.post(`/offerCompiled/processCompare`, {
                customerId: this.currentProcess.CUSTOMER.ID,
                productId: this.currentProcess.PRODUCT.ID,
                offerCode: (offer && offer.OFFER_CODE) ? offer.OFFER_CODE : null,
                offerCodeLink: offerCode ? offerCode : null
            });

            const result: Pick<OfferCompiled, "ID" | "CONCATENATED" | "VALIDITY_UP" | "VALIDITY_OF">[] = (rc && rc.data && rc.data.data) ? rc.data.data : [];
            this.$scope.compare.quotationList = result;

            const current = (this.$scope.compare.quotationList) ? this.$scope.compare.quotationList.find(x => x.ID.toString() == offer.OFFER_CODE) : null;
            this.$scope.compare.selectedQuotation = current;
            this.$scope.currentQuotation = offer.CONCATENATED;
        } catch (ex) {
            this.formService.unblock();
            return ex;
        }
    }

    private async getOfferCompiledById(offerCompiledId: number): Promise<OfferCompiled> {
        try {
            if (!offerCompiledId) this.formService.notifyError('Missing offerCompiledId in getOfferCompiledById');
            this.formService.block();

            const timeout: number = 120000;

            const rc = await this.operationalService.get(`/offerCompiled/getById/${offerCompiledId}/${timeout}`, timeout);
            const result: OfferCompiled = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            return result;
        } catch (ex) {
            this.formService.unblock();
            this.formService.handleError(ex);
        }
    }

    private async getProcessInvoices() {
        const result = await this.operationalService.get(`/process/getInvoices/process/${this.currentProcess.PROCESS_NUMBER}`, null, 30000);

        return result && result.data;
    }

    private async getProcessData() {
        const timeout = 120000;
        const result = await this.operationalService.get(`/process/byNumber/${this.currentProcess.PROCESS_NUMBER}/${timeout}`, null, timeout);
        return result && result.data && result.data.data && result.data.data[0];
    }

    private async validateLockedInvoices(): Promise<boolean> {
        try {
            const processInvoices = await this.getProcessInvoices();
            if (processInvoices == null || processInvoices.data == null) {
                const msgError = this.formService.getTranslate('GENERAL.UNABLE_TO_LOAD_THE_INVOICES', { prop: this.currentProcess['PROCESS_NUMBER'] });
                this.formService.handleError(msgError);
                return false;
            }
            if (processInvoices.data.length() > 0) {
                let invoiceLocked = processInvoices.data.filter(x => x.LOCKED);
                if (invoiceLocked.length() > 0) {
                    const msgWarning = this.formService.getTranslate('GENERAL.THERE_ARE_LOCKED_INVOICES_UNABLE_TO_COMPARE', { prop: this.currentProcess['PROCESS_NUMBER'] })
                    this.formService.notifyWarning(msgWarning);
                    return false;
                }
            }

            return true;
        } catch (ex) {
            this.formService.unblock();
            return ex;
        }
    }

    private async compareProcess() {
        try {
            this.$scope.modalOptions.ok();
            /*
            this.formService.block();

            const compare = (this.$scope.compare.selectedQuotation) ? this.$scope.compare.selectedQuotation : this.$scope.compare.selectedNewQuotation;

            
            const quotation: QuotationModel = compare;

            quotation.QUOTATION_OFFER_ID = compare.ID;
            quotation.QUOTATION_OFFER = compare.NAME;

            const quotationController = new QuotationController(this.$injector, <any>this.$scope);
            await quotationController.onInit(quotation, false, null);

            const processList = await quotationController.buildProcessList();

            if (!processList) return this.formService.handleError("Erro ao comparar processo.");

            if (processList.length == 0) return this.formService.handleError("Lista de processos inválida.");
            
            this.formService.unblock();
            */
            const offerCompiled = this.$scope.compare.selectedQuotation ? this.$scope.compare.selectedQuotation : this.$scope.compare.selectedNewQuotation;
            const quotationToCompare = await this.getOfferCompiledById(offerCompiled.ID);
            return await this.$state.go('app.operational.process.compare', { loadRef: this.$scope.compare.loadRef.toString(), quotation: quotationToCompare, process: this.currentProcess, origin: this.origin, offerCode: quotationToCompare.OFFER_CODE }, { reload: false });
        }
        catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private isPreProcess(): boolean {
        return this.$scope.currentProcess.SITUATION.ID === EProcessSituation.PRE_PROCESS;
    }

    private async getLoadRef() {
        await this.calculateLoad();
    }

    private async validateLoadRef(loadRef: Date) {
        const momentLoadRef = moment(loadRef).startOf('day');
        const momentOfferValidityUp = moment(this.$scope.offerCompiledSelected.VALIDITY_UP).startOf('day');
        const momentOfferValidityOf = moment(this.$scope.offerCompiledSelected.VALIDITY_OF).startOf('day');

        if (momentLoadRef.isAfter(momentOfferValidityUp) || momentLoadRef.isBefore(momentOfferValidityOf)) {
            this.formService.notifyError('A data de Referência de Embarque deve estar dentro da vigência da oferta.');
            this.$scope.compare.loadRef = null;
        }
    }

    private async calculateLoad(): Promise<void> {
        try {
            this.formService.block();
            const today = moment().startOf('day').toDate();
            this.$scope.disabledLoadRef = false;

            this.$scope.offerCompiledSelected = this.$scope.compare.selectedQuotation ? this.$scope.compare.selectedQuotation : this.$scope.compare.selectedNewQuotation;

            let loadRef: Date;
            const processData: Process = await this.getProcessData();
            if (processData.EVENT && processData.EVENT.length > 0) {
                const loadEvent = processData.EVENT.find(event => event.EVENT_TYPE.ID == EEventType.LOAD);
                if (loadEvent) {
                    if (loadEvent.EFFECTIVE_DATE) loadRef = loadEvent.EFFECTIVE_DATE;
                    else if (loadEvent.FORECAST_DATE) loadRef = loadEvent.FORECAST_DATE;
                    if (loadRef) this.$scope.disabledLoadRef = true;
                }
            }
            if (!loadRef) loadRef = today;
            this.$scope.compare.loadRef = loadRef;

            await this.validateLoadRef(this.$scope.compare.loadRef)

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }

    }
}
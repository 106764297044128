import { IGridRow, IColumnDef } from "ui-grid";
import { GridFormService, IGridFormController, IGridFormServiceScope } from "@services/GridFormService";
import { IMonacoColumnDef } from "@services/GridService2";
import { ISessionService } from '@services/SessionService';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { ExternalDataIntegration } from "@models/interface/integration/ExternalDataIntegration";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { DataOperationalService } from "@services/DataOperationalService";
import { IExternalDataIntegration } from 'WBA-Model/dist/interface/external/IFtpServiceConfig';

export interface IMonacoRequest<T = any> {
    route?: string;
    operation?: string;
    data?: T;
    timeout?: number;
    user?: any;
}

interface IExternalDataIntegrationScope extends IGridFormServiceScope {
    model: ExternalDataIntegration;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    selectedRows: ExternalDataIntegration[];
    clearSelections: () => Promise<void>;
    readCheck: (model: ExternalDataIntegration) => void;
    goToLink: (link: string, filter: string) => void;
    goToExternalDataIntegration: (goToExternalDataIntegration: string) => void;
    form: ng.IFormController;
}

export class ExternalDataIntegrationRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ["$injector", "$scope", "$element"];
    private $element: JQLite;
    private $scope: IExternalDataIntegrationScope;
    private selectedRows: ExternalDataIntegration[];
    private SessionService: ISessionService;
    private dataOperationalService: DataOperationalService;
    modalID: any;

    constructor($injector: ng.Injectable<any>, $scope: IExternalDataIntegrationScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.dataOperationalService = $injector.get('DataOperationalService');

    }

    initScopeFunctions(): void {
        this.$scope.clearSelections = async () => {
            await this.clearSelections();
        }

        this.$scope.readCheck = (model: ExternalDataIntegration) => {
            this.readCheck(model);
        }

        this.$scope.goToLink = (link: string, filter: string) => {
            this.SessionService.openTab(link, JSON.parse(filter));
        }

        this.$scope.goToExternalDataIntegration = (externalDataIntegration: string) => {
            if (externalDataIntegration) {
                window.open(externalDataIntegration);
            }
        }
    }

    async $onInit(): Promise<void> {
        try {
            this.selectedRows = [];
            this.$scope.selectedRows = [];
            this.$baseUrl = this.config.externalUrl + '/external';
            this.initForm(this, "form", "externalDataIntegration", "Dados de Arquivos Externos", false);
            this.block();

            // enable multi row selection
            this.$gridService.setSelectable(true);

            // Limit to 50 selectable rows. After this limit callback will be executed
            this.$gridService.setSelectableRowsLimitCallback(50, (limitReached, selectedRowsLength) => {
                const customBreadcrumbArea = this.$element.find('.breadcrum-custom-area');
                customBreadcrumbArea.toggleClass('ng-hide', !limitReached);
                customBreadcrumbArea.html(limitReached ? `<h4 class='selection-limit-reached'>Selection has exceeded the limit of 50 lines. You have ${selectedRowsLength} rows selected.</h4>` : '');
            });

            // init grid
            await this.initGrid('externalDataIntegrationGrid', '/externalDataIntegration/list', true, true, 120000, true, true);
            this.$gridService.setBackgroundUpdate(120000, [this.selectionReapply, this]);

            this.monitoringFilterChanges();

            // register grid multiple rows selection callback
            this.$gridService.$gridApi.selection.on.rowSelectionChanged(this.$scope, this.selectedRowCallback.bind(this));
            this.$gridService.$gridApi.selection.on.rowSelectionChangedBatch(this.$scope, this.selectedRowBatchCallback.bind(this));

            this.unblock();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);
        const columnDefs: Array<IColumnDef> = gridColumns.$columnDefs;

        //situations
        const readCheck = `<span ng-show="true"><a ng-click="grid.appScope.readCheck(row.entity)" class="text-black" tooltip-placement="auto top" uib-tooltip=" Reprocessar " tooltip-append-to-body="true" ><i class="fa fa-recycle icon"></i></a>&nbsp;&nbsp;</span>`;
        //actions       
        const actions = `<div class="text-center pull-left" style="padding-left: 10px;">${readCheck}</div>`;

        columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: '5%',
            cellTemplate: (actions),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });
        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            // visible
            const colFile: IMonacoColumnDef = {
                name: "FILES.FILE_DISPLAY_NAME",
                displayName: "REGISTRATION.FILES",
                width: 230, cellTemplate: `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" 
            uib-tooltip="REGISTRATION.FILES" tooltip-append-to-body="true" href="javascript:void(0);" 
            style="text-decoration: underline;" 
            ng-click="grid.appScope.goToExternalDataIntegration(row.entity.FILES[0].FILE_URL)">{{row.entity.FILES[0].FILE_DISPLAY_NAME}}</a>
            </div>`};
            const colIdentifier: IMonacoColumnDef = { name: "IDENTIFIER", displayName: 'REGISTRATION.GENERIC_IDENTIFIER', width: 150 };
            const colStatus: IMonacoColumnDef = { name: "STATUS.NAME", displayName: 'OPERATIONAL.STATUS', width: 150 };
            const colProvider: IMonacoColumnDef = { name: "PROVIDER", displayName: 'BASIC_DATA.PROVIDER', width: 140 };
            const colUserCreated: IMonacoColumnDef = { name: "CREATED_BY.NAME", displayName: 'GENERAL.CREATED_BY', width: 150 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: 'GENERAL.CREATED_AT', width: 140, cellFilter: "date" };
            const colError: IMonacoColumnDef = { name: "DOCUMENT_ERROR[0].REASON", displayName: 'GENERAL.REASON' }
            const colCDBL: IMonacoColumnDef = {
                name: "CDBL",
                displayName: "GENERAL.EXTERNAL_FILES_REFERENCE",
                width: 230, cellTemplate: `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" 
            href="javascript:void(0);">{{grid.appScope.getCONCAT(row.entity.CDBL, null)}}</a>
            </div>`};

            for (const column of columns) {
                switch (column.toUpperCase()) {

                    case 'FILES':
                        columnDefs.push(colFile);
                        break
                    case 'IDENTIFIER':
                        columnDefs.push(colIdentifier);
                        break;
                    case 'STATUS':
                        columnDefs.push(colStatus);
                        break;
                    case 'PROVIDER':
                        columnDefs.push(colProvider);
                        break;
                    case 'DOCUMENT_ERROR':
                        columnDefs.push(colError);
                        break;
                    case 'CREATED_BY':
                        columnDefs.push(colUserCreated);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'CDBL':
                        columnDefs.push(colCDBL);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    public async selectionReapply(): Promise<void> {
        const rows = this.$gridService.$gridApi.core.getVisibleRows(this.$gridService.$gridApi.grid);
        const selectedRows = this.$gridService.$gridSelectedRows;

        if (selectedRows && selectedRows.length > 0) {
            const updatedRows: IGridRow[] = [];
            for (const row of selectedRows) {
                const foundRow = rows.find(x => x.entity._id == row._id);
                if (foundRow) {
                    foundRow.setSelected(true);
                    updatedRows.push(foundRow);
                }
            }
            this.selectedRowBatchCallback(updatedRows);
        }
    }

    private async readCheck(model: ExternalDataIntegration): Promise<void> {
        try {
            if (!model) return this.handleError('Error Model is missing');

            this.block();
            let externalsDataIntegration: ExternalDataIntegration[] = [];
            if (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) {
                externalsDataIntegration = this.$scope.selectedRows;
            } else {
                externalsDataIntegration.push(model);
            }
            
            let externalsDataIntegrationList: IExternalDataIntegration[] = [];
            externalsDataIntegration.forEach(async element => {
                const formatedObject: IExternalDataIntegration = {
                    DATA_FILE: element.DATA_FILE,
                    IDENTIFIER: element.IDENTIFIER,
                    PROVIDER: element.PROVIDER,
                    FILES: element.FILES,
                    CREATED_AT: element.CREATED_AT,
                    CREATED_BY: element.CREATED_BY,
                    DOCUMENT_ERROR: element.DOCUMENT_ERROR,
                    STATUS: element.STATUS,
                    FILES_MESSAGE: null,
                    CDBL: element.CDBL
                }
                externalsDataIntegrationList.push(formatedObject);
            });
            const result = await this.dataOperationalService.post(`/sync/externalDataIntegration/reprocess`, { data: externalsDataIntegrationList }, 120000);
            if (!result || !result.data) return this.handleError(result);
            

            await this.clearSelections();
            await this.updateGrid();

            this.$formService.notifySuccess(await this.formService.getTranslate("Arquivo Reprocessado com Sucesso"));

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async clearSelections(): Promise<void> {
        this.$scope.selectedRows = [];
        this.$gridService.$gridApi.selection.clearSelectedRows();

        if (this.$scope.log) this.$scope.log.show = false;
    }

    private monitoringFilterChanges() {
        this.$gridService.$gridApi.core.on.filterChanged(this.$scope, this.clearSelections.bind(this));
    }

    private selectedRowCallback(row: IGridRow): void {
        try {
            this.block();

            const externalDataIntegration: ExternalDataIntegration = row.entity;

            const i = this.selectedRows.findIndex(x => x._id === externalDataIntegration._id);

            if (row.isSelected && i === -1)
                this.selectedRows.push(externalDataIntegration);
            else
                this.selectedRows.splice(i, 1);

            // update scope
            this.$scope.selectedRows = this.selectedRows.slice();

            if (this.selectedRows.length > 1 && this.$scope.log) this.$scope.log.show = false;

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private selectedRowBatchCallback(rows: IGridRow[]): void {
        try {
            rows = this.$gridService.$gridApi.selection.getSelectedGridRows();
            this.block();

            this.selectedRows = rows.filter(x => x.isSelected).map(x => x.entity);
            if (this.selectedRows.length > 1 && this.$scope.log) this.$scope.log.show = false;

            this.$scope.selectedRows = this.selectedRows.slice();

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            CREATED_AT: null,
            CREATED_BY: null,
            DATA_FILE: null,
            DOCUMENT_ERROR: null,
            FILES: null,
            IDENTIFIER: null,
            PROVIDER: null,
            STATUS: null,
            CDBL: null,
        }
    }

}

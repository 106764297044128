import * as angular from "angular";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { SelectorModel } from "../../common/model/SelectorModel";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { ITradeLaneModel } from "@models/interface/product/TradeLaneModel";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { BrowserTitle } from "../../common/BrowserTitle";
import { ISessionService } from "@services/SessionService";
import { SSEService } from "@appServices/SSEService";
import { EOperation } from "@enums/GenericData";
import { IDocumentError } from "WBA-Model/dist/interface/common/IDocumentError";
import { IModalService } from '@services/ModalService';
import { IGridOptions } from "ui-grid";
import { HelperService } from "@services/HelperService";

interface ITradeLaneScope extends IGridFormServiceScope {
    model: ITradeLaneModel;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    tradeGroupList: SelectorModel[];
    scopeBeforeSave: ITradeLaneModel;
    user: any;
    sessionService: ISessionService;
    gridOptions: IGridOptions;
    editTradeLane: (tradeLane: ITradeLaneModel) => Promise<void>;
    viewTradeLane: (tradeLane: ITradeLaneModel) => Promise<void>;
    viewLogTradeLane: (tradeLane: ITradeLaneModel) => Promise<void>;
    copyTradeLane: (tradeLane: ITradeLaneModel) => Promise<void>;
    openModalIntegration: (id: number, documentErrorList: IDocumentError[], name: string) => void;
}

export class TradeLaneRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: ITradeLaneScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private SSEService: SSEService;
    private gridName: string;
    private $timeout: ng.ITimeoutService
    private modalID: number;
    private ModalService: IModalService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: ITradeLaneScope) {
        super($injector, $scope);

        this.$timeout = $injector.get('$timeout');     
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope);
        this.gridName = 'GRID_TRADE_LANE';
        this.ModalService = $injector.get('ModalService');
        this.modalID = null;
        this.helperService = $injector.get('HelperService');
    }

    getUrlProduct() {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.config.productUrl + '/product';
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'tradelane', 'GENERAL.MENU.TRADE_LANE', true);
            await this.initGrid(this.gridName, '/tradelane/list', true, true, null, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editTradeLane = async (tradeLane: ITradeLaneModel): Promise<void> => {
            let blockedObject = {
                ID: tradeLane.ID,
                NAME: tradeLane.NAME,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || tradeLane.ID !== this.$scope.model.ID) this.$scope.view(tradeLane);
                } else if (this.$scope.operation !== EOperation.EDIT || tradeLane.ID !== this.$scope.model.ID) {
                    this.$scope.edit(tradeLane);
                }
            };
        }

        this.$scope.viewTradeLane = async (tradeLane: ITradeLaneModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(tradeLane);
        }

        this.$scope.viewLogTradeLane = async (tradeLane: ITradeLaneModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(tradeLane);
        }

        this.$scope.copyTradeLane = async (tradeLane: ITradeLaneModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(tradeLane);
        }

        this.$scope.openModalIntegration = (id: number, documentErrorList: IDocumentError[]) => {
            this.openModalIntegration(id, documentErrorList);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewTradeLane(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editTradeLane(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogTradeLane(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyTradeLane(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy + modalIntegration),
            enableCellEdit: true,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };

        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colActive: IMonacoColumnDef = { name: 'ACTIVE', displayName: 'GENERAL.ACTIVE', width: 100, cellFilter: 'YesOrNo' };
            const colInitials: IMonacoColumnDef = { name: 'INITIALS', displayName: 'GENERAL.CODE', width: 300 };
            const colName: IMonacoColumnDef = { name: 'NAME', displayName: 'GENERAL.NAME', width: 200 };
            const colInternationalName: IMonacoColumnDef = { name: 'NAME_INTL', displayName: 'GENERAL.NAME_INTL', width: 200 };
            const colTradeGroup: IMonacoColumnDef = { name: 'TRADE_GROUP.NAME', displayName: 'ROUTE.TRADE_GROUP', width: 250 };
            const colIntegrationId: IMonacoColumnDef = { name: 'INTEGRATION_ID', displayName: 'GENERAL.INTEGRATION_ID', width: 100 };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'INITIALS':
                        columnDefs.push(colInitials);
                        break;
                    case 'TRADE_GROUP':
                        columnDefs.push(colTradeGroup)
                        break;
                    case 'NAME':
                        columnDefs.push(colName);
                        break;
                    case 'NAME_INTL':
                        columnDefs.push(colInternationalName);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'INTEGRATION_ID':
                        columnDefs.push(colIntegrationId);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                    default:
                        continue;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            ACTIVE: true,
            INITIALS: null,
            NAME: null,
            NAME_INTL: null,
            TRADE_GROUP: null,
            INTEGRATION_ID: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            SEARCH_FIELDS: null,
            DOCUMENT_ERROR: null
        };
    }

    initDependencies(): Promise<any> {
        const self: TradeLaneRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getTradeGroupList()
            ]).then((result: any) => {
                self.$scope.tradeGroupList = result[0];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getTradeGroupList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/locals_group`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} (${this.$scope.model.NAME})`;
            BrowserTitle.$id = this.$scope.model.NAME;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} (${this.$scope.model.NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === 'register' ? 'insert' : 'update';
        return {
            route: `/tradelane/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "Integration Product/Operation" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                this.formService.block();
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/tradelane/sendSync`, { "idTradeLane": id }, 120000, false);
                if (syncRequest) success = true;
            }
        } catch (ex) {
            this.formService.handleError(`${this.formService.getTranslate("REGISTRATION.MESSAGES.ERROR.ERROR_SENDING_REQUEST")}`);
        } finally {
            this.formService.unblock();
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const tradelaneData = await this.getTradeLaneById(id);
                    if (row && tradelaneData && tradelaneData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = tradelaneData.DOCUMENT_ERROR;
                        documentError = tradelaneData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async getTradeLaneById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/tradelane/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private getCustomLogProperties() {
        this.$scope.customLogProperties = [];
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE"
            },
            {
                PROPERTY: "NAME",
                LABEL: "GENERAL.NAME"
            },
            {
                PROPERTY: "INITIALS",
                LABEL: "GENERAL.CODE"
            },
            {
                PROPERTY: "NAME_INTL",
                LABEL: "GENERAL.NAME_INTL"
            },
            {
                PROPERTY: "TRADE_GROUP",
                LABEL: "ROUTE.TRADE_GROUP"
            },
            {
                PROPERTY: "INTEGRATION_ID",
                LABEL: "GENERAL.INTEGRATION_ID"
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            }
        ];
        return props;
    }
}

import * as angular from "angular";
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { IModalService } from "@services/ModalService";
import { OperationalService } from "@services/OperationalService";
import { ICustomLogProperties, IViewLog } from "@models/interface/common/IViewLog";
import { processLogsPropertyNameMap } from "@models/interface/operational/NewProcess";

interface IProcessLogViewerModalScope extends IFormServiceScope {
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    //
    uuid: string;
    process: string;
    identifier: string;
    customClass: string;
    //
    openModal: ($event: JQuery.Event<HTMLElement>) => void;
}

export function processLogViewer(): ng.IDirective {

    const directive: ng.IDirective = {
        transclude: false,
        replace: false,
        restrict: 'E',
        scope: {
            identifier: '@',
            process: '=',
            uuid: '=',
            customClass: '@'
        },
        template: require("../view/processLogViewer.html"),
        controller: ['$injector', '$scope', ProcessLogViewerController],
    }

    return directive;
}

async function ProcessLogViewerController ($injector: ng.Injectable<any>, $scope: IProcessLogViewerModalScope) {
    const formService = new FormService2($injector, $scope);
    const modalService: IModalService = $injector.get('ModalService');
    const operationalService: OperationalService = $injector.get('OperationalService');

    const requestHistory = async (id: string, identifier: string, uuid?: string): Promise<Array<any>> => {
        try {
            const timeout: number = 120000;
            formService.block();
            const result = await operationalService.get(`/process/viewLog/${timeout}/${identifier}/${id}${uuid ? '/' + uuid : ''}`, null, timeout);        
            return result && result.data ? result.data.data : null;
        } catch (ex) {
            formService.handleError(ex);
        } finally {
            formService.unblock();
        }
    }

    $scope.openModal = async ($event: JQuery.Event<HTMLElement>) => {
        $event.stopPropagation();
        try {

            let log: IViewLog = {
                operation: 'history',
                number: $scope.process,
                list: [],
                show: true,
                showCloseButton: false,
                searchQuery: '',
                originalList: [],
            }

            const logList = await requestHistory(log.number, $scope.identifier, $scope.uuid);
            
            log.originalList = angular.copy(logList);
            log.list = logList;
            $scope.log = log;
            $scope.customLogProperties = processLogsPropertyNameMap;
            angular.element('#log-viewer').removeClass('ng-hide');
            $scope.$applyAsync();

            const modalId = modalService.newModal();
            await modalService.showModalConfirmation(
                {
                    modalID: modalId,
                    scope: $scope,
                    template: require('../view/processLogModal.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG"
                }
            );

            modalService.closeModal(modalId);
        } catch (ex) {
            formService.handleError(ex);
        }
    }
};
import { ArrayUtil } from "./ArrayUtil";

export abstract class StringUtil {

    public static replaceAll(target: string, search: string, replacement: string): string {
        return target.replace(new RegExp(StringUtil.escape(search), 'g'), replacement);
    }

    public static escape(string) {
        return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
    }

    public static rpad(value: string, complement: string, sizeLimit: number) {
        if (!value || !complement || value.length >= sizeLimit) return value;

        const max = (sizeLimit - value.length) / complement.length;
        for (let i = 0; i < max; i++) value += complement;

        return value;
    }

    public static limitLines(value: string, limit: number): string {
        let result = value ? value : "";
        try {
            if (value && limit) {
                const values = value.replace(/\r\n/g, "\n").split("\n")
                if (values.length > limit) {
                    result = values.slice(0, limit).join("\n");
                }
            }
        } catch (ex) {
            throw ex;
        } finally {
            return result;
        }
    }

    public static formatString(itemToFormat: string) {
        let itemResult = '';
        try {
            if (itemToFormat) {
                itemResult = itemToFormat.replace(/[_ ]/g, "").toLowerCase();
            }
        } catch (ex) {
            throw ex;
        } finally {
            return itemResult;
        }
    }

    public static removeDiacriticAndUpper(data: string): string {
        if (!data) return "";

        try {
            data = this.removeDuplicateSpaces(data);
            data = data.normalize("NFD").replace(/[\u0300-\u036f]/g, "").trim().toUpperCase();
            data = this.removeSpecialChars(data);
            return data;
        } catch (ex) {
            throw ex;
        }
    }

    public static removeDuplicateSpaces(data: string): string {
        if (!data) return "";

        try {
            data = data.replace(/\s+/g, ' ');
            return data;
        } catch (ex) {
            throw ex;
        }
    }

    public static removeSpecialChars(data: string): string {
        if (!data) return "";

        try {
            data = data.replace(/[^a-zA-Z0-9 ]/gi, '');
            return data;
        } catch (ex) {
            throw ex;
        }
    }

    public static formatConcatenatedChars(data: string): string {
        if (!data) return "";

        try {
            data = data.normalize('NFD').replace(/[\u0300-\u036f]/g, "");
            data = data.replace(/[^A-Za-z0-9|\-,./ ]+/g, "");
            return data;
        } catch (ex) {
            throw ex;
        }
    }

    public static getCONCAT(originalValue: any[], customProp?: string, objToReturn?: string, returnUnique: boolean = false, considerMultiline: boolean = false, customSeparator?: string): string {
        try {
            if (!originalValue || originalValue.length === 0) return;
            const separator = (customSeparator) ? customSeparator : ';';

            let values = originalValue;

            // check for multi-line
            if (considerMultiline) {
                const dataSample = (customProp) ? originalValue[0][customProp] : originalValue[0];
                // if that's the case, we mush all values together as we can't represent multiple lines in a one-line grid (duh).
                if (ArrayUtil.isArray(dataSample)) { // is multi-line
                    // this way we still the user stil enable to filter data
                    values = originalValue.reduce((acc, val) => {
                        if (customProp) return acc.concat(val[customProp]);
                        return acc.concat(val);
                    }, []);
                }
            }

            const allData = (ArrayUtil.isArray(values) ? values : values = [values])
                .filter(data => data != null)
                .map(val => {
                    let accessedData: string = val;
                    if (typeof val === 'object') {
                        if (customProp && val.hasOwnProperty(customProp) && val[customProp] != null) //added a customProp when needed to dive little deeper
                            accessedData = val[customProp][objToReturn ? objToReturn : "NAME"];
                        else
                            accessedData = val[objToReturn ? objToReturn : "NAME"];
                    }
                    return accessedData;
                });

            let treatedData = allData.filter(data => data != null);
            if (returnUnique) treatedData = treatedData.filter((v, i, a) => a.indexOf(v) === i);
            const concatData: string = treatedData.join(`${separator} `)
            return concatData;
        } catch (ex) {
            throw ex;
        }
    }
}

import * as angular from "angular";
import uiGrid = require("ui-grid");
import { IStateParamsService } from "angular-ui-router";
import { IHttpResponse } from "angular";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequest } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IModalService } from "@services/ModalService";
import { IMonacoColumnDef } from "@services/GridService2";
import { ISessionService } from "@services/SessionService";
import { OperationalService } from "@services/OperationalService";
import { ProductService } from '@services/ProductService';
import { IFinopService } from "@services/FinopService";
import { CARGO_DETAIL, CARGO_TYPE, FORWARDING_SITUATION, AUDIT, EQUIPMENT, ENTITY } from "@models/interface/operational/NewProcess";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { ISelectorModel } from "@models/mongo/SelectorModel";
import { Charge, APPLICATION, PEOPLE, COMPOSITION_GROUP, CHARGE_EXHIBITION, WEIGHT_RANGE_CHARGE } from "@models/interface/finop/NewCharge";
import { IWeightRangeResponse } from "@models/interface/operational/ProcessChargeWeightRange";
import { ChargePair } from "@models/interface/finop/NewChargePair";
import { SelectorModel } from "../../common/model/SelectorModel";
import { noBilling, newInvoice, IInvoiceNumber } from "@models/interface/finop/Invoice";
import { IProcessParameter, IInvoiceParameter } from "../../common/model/ModelParameter";
import { BrowserTitle } from "../../common/BrowserTitle";
import { EOperation, EApplicationComplementId, EChargeOriginId, EDataOriginTypeId, EEventType } from "@enums/GenericData";
import { IParams } from "WBA-Model/dist/interface/product/ChargeNameModel";
import { HelperService } from "@services/HelperService";
import { PermissionService } from "@appServices/PermissionService";
export interface INVOICE_INFO {
    LOCKED: boolean;
    STATUS: ISelectorModel;
}

interface IProcessInfo {
    CARGO_DETAIL: CARGO_DETAIL,
    CARGO_TYPE: CARGO_TYPE,
    FORWARDING_SITUATION: FORWARDING_SITUATION,
    AUDIT: AUDIT,
    VALIDITY_OF: Date,
    VALIDITY_UP: Date,
}

interface IDefaultComposition {
    CHARGECODE: string;
    NATUREID: string;
}

interface navItem { $$hashKey?: string, CHARGE: string, CHARGE_NUMBER: string, PROCESS: SelectorModel }
interface IProcess {
    ID: string,
    NAME: string,
    CODE: string,
    CARGO_TYPE?: SelectorModel
}
interface IChargeScope extends IGridFormServiceScope {
    model: ChargePair;
    oldModel: ChargePair;
    user: any;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: ChargePair;
    weightRangeActiveId: string;
    isOutcomeWeightRangeApplication: boolean;
    isIncomeWeightRangeApplication: boolean;
    invoiceStatus: {
        INCOME: INVOICE_INFO,
        OUTCOME: INVOICE_INFO,
    }

    charge: { process: IProcess };

    //lists
    processList: ISelectorModel[];
    chargeList: ISelectorModel[];
    applications: SelectorModel[];
    currencies: SelectorModel[];
    eventList: SelectorModel[];
    transactions: SelectorModel[];
    shownFor: SelectorModel[];
    modalities: SelectorModel[];
    invoices: {
        INCOME: SelectorModel[],
        OUTCOME: SelectorModel[]
    }
    people: {
        INCOME: SelectorModel[],
        OUTCOME: SelectorModel[]
    }
    compositionList: {
        INCOME: COMPOSITION_GROUP[],
        OUTCOME: COMPOSITION_GROUP[]
    }
    chargeExhibitionList: {
        INCOME: CHARGE_EXHIBITION[],
        OUTCOME: CHARGE_EXHIBITION[]
    }
    processes: SelectorModel[];
    processEquipment: EQUIPMENT[];

    blocked: {
        CHECK_VALUE: boolean,
        INCOME: {
            wizard: boolean;
            profitShare: boolean;
            pre_invoice: boolean;
            locked: boolean;
            pending_integration: boolean;
        },
        OUTCOME: {
            wizard: boolean;
            profitShare: boolean;
            pre_invoice: boolean;
            locked: boolean;
            pending_integration: boolean;
        }
    }

    hasOutDateCharge: boolean;
    chargesOutDate: ChargePair[];

    //returns if the invoice is disabled according to specific rules
    invoiceDisabled: (nature: string) => boolean;

    //searches for process number
    getProcessSelector: (processNumber: string) => Promise<void>;

    //re-init the Model, as the chosen Process changed
    refreshProcess: (clear: boolean) => void;

    //changed Charge, so clear everything and the user must pick it all again
    setSelectedCharge: () => void;

    setChargePairExhibition: () => void;

    //searches existent Charges, but also considers limitations of automatic TYPE ('A')
    refreshCharges: (name: string, chargeType: string) => void;
    getDefaultApplication: (params: IParams[]) => void

    refreshInvoices: (nature: string) => void;

    //changed TRANSACTION, so feeds people
    refreshProcessPeople: (nature: string) => void;

    //feeds ALL people when TRANSACTION.ID == 8
    refreshPeople: (nature: string, name: string) => void;

    delayApplicationUpdateRequest: (natureID: string, triggeredBy: string) => void;
    refreshApplication: (natureID: string, triggeredBy: string) => void;
    setEquipmentAmount: (nature: string, equipmentAmount: number) => void;

    //recalculate Total value of charge
    refreshTotal: (nature: string) => void;

    weightRangeOutcomeValueUnitaryChange: (weightRangeId: string) => void;
    weightRangeIncomeValueUnitaryChange: (weightRangeId: string) => void;

    truncate: (word, n) => string;

    changeGridHeight: (addPixels) => void;

    viewNextCharge: () => void;

    goToProcess: (process: string) => void;
    goToInvoice: (invoice: string) => void;

    // navigation

    currentNavigationIndex: number;
    navigationMap: navItem[];
    navigationSave: boolean;
    hasPreviousCharge: () => boolean;
    hasNextCharge: () => boolean;
    previousCharge: () => void;
    nextCharge: (skipConfirm?: boolean) => void;
    handleNavigationSave: (goToNextCharge: boolean) => void;

    getValidInvoiceSelectableList: (invoiceList: IInvoiceNumber[]) => IInvoiceNumber[];
    hasChargeChange: () => void;

    disabledOutcomeNegotiated: boolean;
    disabledIncomeNegotiated: boolean;
}

export class ChargeRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope', 'SessionService'];
    private $scope: IChargeScope;
    private $timeout: ng.ITimeoutService
    private $q: ng.IQService;
    private RestService: IRestService;
    private ModalService: IModalService;
    private defaultCompositions: IDefaultComposition[];
    private processCharges: Charge[];
    private previousCharge: {
        INCOME: Charge,
        OUTCOME: Charge
    };
    private allInvoices: SelectorModel[];
    private newInvoice: SelectorModel;
    private noBilling: SelectorModel;
    private allApplications: APPLICATION[];

    //grid control
    private gridAdjusted: boolean;
    private actualHeight: any;
    private $stateParams: IStateParamsService;
    private sessionService: ISessionService;

    private confirmationModalId: number;
    private requestPromiseTimeout;

    public operationalService: OperationalService;
    public productService: ProductService;
    public finopService: IFinopService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IChargeScope, sessionService: ISessionService) {
        super($injector, $scope);
        //set $scope
        this.$scope = $scope;
        //get injections
        this.$timeout = $injector.get('$timeout');
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');
        this.sessionService = sessionService;

        this.operationalService = $injector.get('OperationalService');
        this.productService = $injector.get('ProductService');
        this.finopService = $injector.get('FinopService');

        //
        this.$scope.charge = { process: null };
        this.$scope.navigationMap = [];
        this.$scope.currentNavigationIndex = 0;
        this.helperService = $injector.get('HelperService');
        this.permissionService = new PermissionService(this.$scope, $injector);
    }

    async $onInit(): Promise<void> {
        try {

            this.$baseUrl = this.operationalService.$route;

            this.initForm(this, 'form', 'charge', 'BASIC_DATA.CHARGE', true);

            this.block();

            this.setCopyable(false);
            this.setDeletable(false);
            this.previousCharge = {
                INCOME: null,
                OUTCOME: null
            };

            this.$scope.charge = { process: null };
            this.$scope.customLogProperties = this.getCustomLogProperties();

            this.newInvoice = newInvoice;
            this.noBilling = noBilling;

            await this.initGrid('gridCharge', '/charge/list', true, true, 60000, true, true);

            // get grid height to properly collapse grid when user needs it
            this.actualHeight = this.$gridService.getGridHeight().value;

            // navigation
            await this.enableChargeNavigation();

            this.$scope.disabledOutcomeNegotiated = true;
            this.$scope.disabledIncomeNegotiated = true;

            this.unblock();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        try {
            super.$onDestroy();
            if (this.requestPromiseTimeout) {
                this.$timeout.cancel(this.requestPromiseTimeout);
                this.requestPromiseTimeout = null;
            }
        } catch (ex) {
            throw ex;
        }
    }

    initScopeFunctions(): void {

        this.$scope.invoiceDisabled = (nature: string) => {
            return this.invoiceDisabled(nature);
        }
        this.$scope.refreshProcess = async (clear: boolean) => {
            await this.$scope.$applyAsync();
            await this.refreshProcess(clear);
        }
        this.$scope.setSelectedCharge = async () => {
            await this.setSelectedCharge();
        }
        this.$scope.setChargePairExhibition = async () => {
            const validChargeExhibition = (this.$scope.model.OUTCOME.CHARGE_EXHIBITION) ? this.$scope.model.OUTCOME.CHARGE_EXHIBITION : (this.$scope.model.INCOME.CHARGE_EXHIBITION) ? this.$scope.model.INCOME.CHARGE_EXHIBITION : null;
            if (validChargeExhibition) this.$scope.model.CHARGE_EXHIBITION = validChargeExhibition;
        }
        this.$scope.refreshCharges = async (name, chargeType) => {
            await this.refreshCharges(name, chargeType);
        }
        this.$scope.refreshInvoices = async (nature: string) => {
            await this.refreshInvoices(nature);
        }
        this.$scope.refreshProcessPeople = async (nature) => {
            await this.refreshProcessPeople(nature);
        }
        this.$scope.refreshPeople = async (nature, name) => {
            await this.refreshPeople(nature, name);
        }
        this.$scope.refreshApplication = async (natureID, triggeredBy) => {
            await this.refreshApplication(natureID, triggeredBy);
        }
        this.$scope.viewLog = (entity) => {
            this.viewLog(entity);
        }
        this.$scope.truncate = (word, n): string => {
            return this.truncate(word, n);
        }
        this.$scope.changeGridHeight = () => {
            return this.changeGridHeight();
        }
        this.$scope.goToProcess = (process: string) => {
            this.sessionService.openTab('app.operational.newProcess.list', <IProcessParameter>{ PROCESS_NUMBER: process });
        }
        this.$scope.goToInvoice = (invoice: string) => {
            this.sessionService.openTab('app.finop.invoice.register', <IInvoiceParameter>{ INVOICE_NUMBER: invoice });
        }
        this.$scope.weightRangeOutcomeValueUnitaryChange = (weightRangeId: string) => {
            this.weightRangeOutcomeValueUnitaryChange(weightRangeId);
        }

        this.$scope.weightRangeIncomeValueUnitaryChange = (weightRangeId: string) => {
            this.weightRangeIncomeValueUnitaryChange(weightRangeId);
        }
        //this.$scope.delayRequest = (callback: (...args: any[]) => any, ms: number) => {
        this.$scope.delayApplicationUpdateRequest = async (natureID, triggeredBy) => {
            try {
                if (this.requestPromiseTimeout) {
                    this.$timeout.cancel(this.requestPromiseTimeout);
                    this.requestPromiseTimeout = null;
                }
                this.requestPromiseTimeout = this.$timeout(this.refreshApplication.bind(this, natureID, triggeredBy), 1000);
            } catch (ex) {
                this.handleError(ex);
            }
        };
        this.$scope.getProcessSelector = async (query) => {
            let processList = [];
            if (query && query.length >= 2) {
                processList = await this.getProcessSelector(query);
            }
            this.$scope.processList = processList;
        }
        // navigation
        this.$scope.hasPreviousCharge = () => {
            return this.$scope.navigationMap.length > 0 && this.$scope.currentNavigationIndex > 0;
        }
        this.$scope.hasNextCharge = () => {
            try {
                return this.$scope.navigationMap.length > 0 && (this.$scope.navigationMap.length - 1 > this.$scope.currentNavigationIndex);
            } catch (ex) {
                this.handleError(ex);
            }
        }
        this.$scope.previousCharge = async () => {
            if (!this.$scope.hasPreviousCharge()) return;
            const confirm = await this.confirmChargeChanges();
            if (confirm) {
                this.$scope.currentNavigationIndex--;
                await this.handleChargeNavigation();
            }
        }
        this.$scope.nextCharge = async (skipConfirm: boolean = false) => {
            if (!this.$scope.hasNextCharge()) return;
            const confirm = (skipConfirm) ? true : await this.confirmChargeChanges();
            if (confirm) {
                this.$scope.currentNavigationIndex++;
                await this.handleChargeNavigation();
            }
        }
        this.$scope.handleNavigationSave = async (goToNextCharge: boolean = false) => {
            await this.confirmChargeChanges(true, false);
            if (goToNextCharge) await this.$scope.nextCharge(true);
        }

        this.$scope.getValidInvoiceSelectableList = (invoiceList: IInvoiceNumber[]) => {
            return this.getValidInvoiceSelectableList(invoiceList);
        }

        this.$scope.hasChargeChange = () => {
            this.hasChargeChange();
        }
    }

    private changeGridHeight = () => {
        if (this.gridAdjusted) {
            this.gridAdjusted = false;
            this.$gridService.$gridHeight = 0;
        } else {
            this.gridAdjusted = true;
            this.$gridService.$gridHeight = -(this.actualHeight / 2);
        }
    }

    initGridColumns(columns) {
        const columnDefs: IMonacoColumnDef[] = new Array();

        //action column, contain just buttons and icons
        const view = `<div class="text-center"><a ng-click="grid.appScope.view(row.entity)" class="text-info"tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`
        const edit = `<a ng-click="grid.appScope.edit(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`
        const copy = `<a ng-click="grid.appScope.copy(row.entity)" class="text-brown" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.ADD_CHARGE' | translate }} {{row.entity.PROCESS.NAME}}" tooltip-append-to-body="true" ><i class="fa fa-plus-circle icon"></i></a>&nbsp;&nbsp;&nbsp;`
        const history = `<a ng-click="grid.appScope.viewLog(row.entity)" class="text-green" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a></div>`

        columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + copy + history),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        for (let i = 0; i < columns.length; i++) {
            let name = null;
            let displayName = null;
            let width = 0;
            let cellTemplate = undefined;
            let cellFilter = null;
            let visible = true;
            let field = null;
            let filter: uiGrid.IFilterOptions = { condition: this.gridService.filterSelectObject };
            let searchProps = null;
            let partialCriteria = false;

            switch (columns[i].toUpperCase()) {
                case 'PROCESS':
                    name = 'PROCESS_NUMBER';
                    field = columns[i] + '.NAME';
                    displayName = 'OPERATIONAL.FILE_NUMBER';
                    width = columns[i].length + 3;
                    cellTemplate = `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.PROCESS_VIEW' | translate }} {{row.entity.PROCESS.NAME}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToProcess(row.entity.PROCESS.NAME)">{{row.entity.PROCESS.NAME}}</a><span class="label label-danger" ng-if="row.entity.PROCESS.PRIORITY.ID == \'2\'"><i class="text-warning fa fa-bolt"></i></span></div>`;
                    break;
                case 'CHARGE_NUMBER':
                    name = columns[i];
                    displayName = 'FINANCIAL.CHARGE_IDENTIFICATION';
                    width = columns[i].length;
                    visible = false;
                    break;
                case 'CHARGE_TYPE':
                    name = columns[i] + '.ID';
                    displayName = 'GENERAL.TYPE';
                    width = columns[i].length - 7;
                    break;
                case 'CHARGE':
                    name = columns[i] + '.NAME';
                    displayName = 'BASIC_DATA.CHARGE';
                    width = columns[i].length + 4;
                    break;
                case 'CHARGE_EXHIBITION':
                    name = columns[i] + '.NAME';
                    displayName = 'BASIC_DATA.CHARGE_DISPLAY';
                    width = columns[i].length + 4;
                    break;
                case 'PROCESS.PRODUCT':
                    name = columns[i] + '.NAME';
                    displayName = 'BASIC_DATA.PRODUCT';
                    width = columns[i].length - 4;
                    break;
                case 'PROCESS.COMMERCIAL_UNITY':
                    name = columns[i] + '.NAME';
                    displayName = 'BASIC_DATA.COMMERCIAL_BRANCH';
                    width = columns[i].length - 14;
                    break;
                case 'PROCESS.BRANCH':
                    name = columns[i] + '.NAME';
                    displayName = 'BASIC_DATA.BRANCH';
                    width = columns[i].length - 4;
                    break;
                case 'INVOICE_NUMBER':
                    name = columns[i] + '.NAME';
                    displayName = 'GENERAL.INVOICES';
                    width = columns[i].length - 3;
                    cellTemplate = `<div ng-if="row.entity.INVOICE_NUMBER.ID === \'-1\'" class="ui-grid-cell-contents ng-binding ng-scope">{{row.entity.INVOICE_NUMBER.NAME}}</div><div ng-if="row.entity.INVOICE_NUMBER.ID !== \'-1\'" class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.OPEN_INVOICES' | translate }} {{row.entity.INVOICE_NUMBER.NAME}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToInvoice(row.entity.INVOICE_NUMBER.NAME)">{{row.entity.INVOICE_NUMBER.NAME}}</a></div>`;
                    break;
                case 'NATURE':
                    name = columns[i] + '.NAME';
                    displayName = 'BASIC_DATA.PAYMENT_NATURE';
                    width = columns[i].length + 3;
                    break;
                case 'AMOUNT':
                    name = columns[i];
                    displayName = 'GENERAL.QUANTITY';
                    cellTemplate = '<div class="ui-grid-cell-contents">{{row.entity.AMOUNT | number }}<div>';
                    width = columns[i].length - 2;
                    break;
                case 'APPLICATION':
                    name = columns[i] + '.NAME';
                    displayName = 'FINANCIAL.CHARGE_BASIS';
                    width = columns[i].length + 4;
                    break;
                case 'EQUIPMENT':
                    name = columns[i] + '.NAME';
                    displayName = 'GENERAL.EQUIPMENT';
                    width = columns[i].length + 4;
                    break;
                case 'CURRENCY':
                    name = columns[i] + '.CODE';
                    displayName = 'GENERAL.CURRENCY';
                    width = columns[i].length;
                    break;
                case 'MINIMUM':
                    name = columns[i];
                    displayName = 'BASIC_DATA.MINIMUM';
                    cellTemplate = '<div class="ui-grid-cell-contents">{{row.entity.MINIMUM | number: 2}}<div>';
                    width = columns[i].length - 2;
                    break;
                case 'UNITARY':
                    name = columns[i];
                    displayName = 'BASIC_DATA.UNIT';
                    cellTemplate = '<div class="ui-grid-cell-contents">{{row.entity.UNITARY | number: 2}}<div>';
                    width = columns[i].length - 1;
                    break;
                case 'TOTAL':
                    name = columns[i];
                    displayName = 'FINANCIAL.ACTUAL_TOTAL_AMOUNT';
                    cellTemplate = '<div class="ui-grid-cell-contents">{{row.entity.TOTAL | number: 2}}<div>';
                    width = columns[i].length;
                    break;
                case 'TRANSACTION':
                    name = columns[i] + '.NAME';
                    displayName = 'GENERAL.HOLDER_TYPE';
                    width = columns[i].length + 3;
                    break;
                case 'PEOPLE':
                    name = columns[i] + '.NAME';
                    displayName = 'GENERAL.HOLDER';
                    width = columns[i].length + 10;
                    break;
                case 'VALIDITY_EVENT':
                    name = columns[i] + '.NAME';
                    displayName = 'GENERAL.VALIDITY_BASIS';
                    width = columns[i].length;
                    break;
                case 'VALIDITY_OF':
                    name = columns[i];
                    displayName = 'PRODUCT.VALIDITY_START';
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length;
                    break;
                case 'VALIDITY_UP':
                    name = columns[i];
                    displayName = 'PRODUCT.VALIDITY_END';
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length;
                    break;
                case 'SHOW_FOR':
                    name = columns[i];
                    displayName = 'FINANCIAL.SHOWN_FOR';
                    width = columns[i].length + 4;
                    break;
                case 'CHAINED':
                    name = columns[i];
                    displayName = 'FINANCIAL.LOCKED';
                    width = columns[i].length;
                    break;
                case 'COMPOSITION_GROUP':
                    name = columns[i] + '.NAME';
                    displayName = 'GENERAL.COMPOSITION';
                    cellTemplate = '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.COMPOSITION_GROUP, \'CHARGE\')}}</div>';
                    width = columns[i].length + 4;
                    break;
                case 'SHOW_FOR_GROUP':
                    name = columns[i] + '.NAME';
                    displayName = 'FINANCIAL.SHOWN_FOR';
                    cellTemplate = '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.SHOW_FOR_GROUP)}}</div>';
                    width = columns[i].length + 2;
                    break;
                case 'GENERATE_INVOICE':
                    name = columns[i] + '.NAME';
                    displayName = 'REGISTRATION.GENERATE_INVOICE';
                    width = columns[i].length;
                    break;
                case 'CHARGE_ORIGIN':
                    name = columns[i] + '.NAME';
                    displayName = 'BASIC_DATA.ORIGIN';
                    width = columns[i].length;
                    break;
                case 'MODALITY':
                    name = columns[i] + '.NAME';
                    displayName = 'FINANCIAL.CHARGE_PAYMENT_METHOD';
                    width = columns[i].length;
                    break;
                case 'OBSERVATION':
                    name = columns[i];
                    displayName = 'GENERAL.REMARKS';
                    width = columns[i].length + 5;
                    break;
                case 'PROCESS.DOCUMENT_HBL':
                    name = columns[i];
                    displayName = 'OPERATIONAL.HOUSE_BL';
                    width = columns[i].length - 9;
                    break;
                case 'PROCESS.DOCUMENT_MBL':
                    name = columns[i];
                    displayName = 'OPERATIONAL.MASTER_BL';
                    width = columns[i].length - 9;
                    partialCriteria = true;
                    break;
                default:
                    continue;
            };
            const newColumn: IMonacoColumnDef = {
                name: name,
                displayName: displayName,
                headerCellClass: this.$gridService.highlightFilteredHeader.bind(this.$gridService),
                width: width + '%',
                cellTemplate: cellTemplate,
                cellFilter: cellFilter,
                visible: visible,
                filter: filter,
                searchProps: searchProps,
                partialCriteria: partialCriteria,
            };
            if (field) newColumn.field = field;
            columnDefs.push(newColumn);
        }
        return columnDefs;
    }

    initModel(keepProcess?: boolean, keepCharge?: boolean): void {
        let process;
        let validityUp;
        let validityOf;
        let charge;

        this.$scope.blocked = {
            CHECK_VALUE: false,
            OUTCOME: { wizard: false, profitShare: false, locked: false, pre_invoice: false, pending_integration: false },
            INCOME: { wizard: false, profitShare: false, locked: false, pre_invoice: false, pending_integration: false },
        };

        this.$scope.invoiceStatus = {
            OUTCOME: null,
            INCOME: null,
        }

        this.$scope.compositionList = {
            INCOME: [],
            OUTCOME: []
        }
        this.$scope.people = {
            INCOME: [],
            OUTCOME: []
        }

        this.$scope.chargeExhibitionList = {
            INCOME: [],
            OUTCOME: []
        }

        if (keepProcess) {

            validityUp = (this.$scope.model.INCOME && this.$scope.model.INCOME.VALIDITY_UP) ? this.$scope.model.INCOME.VALIDITY_UP :
                (this.$scope.model.OUTCOME && this.$scope.model.OUTCOME.VALIDITY_UP) ? this.$scope.model.OUTCOME.VALIDITY_UP : null;

            validityOf = (this.$scope.model.INCOME && this.$scope.model.INCOME.VALIDITY_OF) ? this.$scope.model.INCOME.VALIDITY_OF :
                (this.$scope.model.OUTCOME && this.$scope.model.OUTCOME.VALIDITY_OF) ? this.$scope.model.OUTCOME.VALIDITY_OF : null;
        } else {
            this.$scope.charge.process = null;
        }

        if (keepCharge)
            charge = this.$scope.model.CHARGE;

        this.$scope.disabledOutcomeNegotiated = true;
        this.$scope.disabledIncomeNegotiated = true;

        this.$scope.model = {
            CHARGE_PAIR_NUMBER: null,
            CHARGE: (keepCharge) ? charge : null,
            CHARGE_EXHIBITION: null,
            OUTCOME: {
                CHARGE_NUMBER: null,
                CHARGE_PAIR_REFERENCE: null,
                CHARGE_TYPE: { "ID": "M", "NAME": "Manual" },
                CHARGE: null,
                CHARGE_EXHIBITION: null,
                NATURE: { ID: "1", NAME: "Pagamento" },
                AMOUNT: 0,
                APPLICATION: null,
                CURRENCY: null,
                MINIMUM: 0,
                UNITARY: 0,
                TOTAL: 0,
                TOTAL_BRL: 0,
                CONVERSION_SPREAD: 0,
                TRANSACTION: { ID: "0", NAME: "(Sem pagamento)" },
                PEOPLE: null,
                VALIDITY_OF: (keepCharge) ? validityOf : null,
                VALIDITY_UP: (keepCharge) ? validityUp : null,
                SHOW_FOR_GROUP: null,
                EQUIPMENT: null,
                VEHICLE_TYPE: null,
                WEIGHT_RANGE: null,
                INVOICE_NUMBER: this.$scope.operation == "register" ? this.newInvoice : this.noBilling,
                COMPOSITION_GROUP: null,
                OBSERVATION: null,
                CHARGE_ORIGIN: null,
                MODALITY: null,
                ERP_INTEGRATION_STATUS: null,
                ERP_ORDER: null,
                ERP_SEQUENCE: null,
                ERP_TOP: null,
                ERP_BILLING: null,
                WEIGHT_RANGE_CHARGE: null,
                PARAMS: null,
                VALIDITY_EVENT: null,
                NEGOTIATED: true,
                ID_TARIFF_DOMESTIC: null,
                ID_TARIFF_FREIGHT: null,
                ID_TARIFF_LOCAL: null,
                ID_CONSOLIDATED: null,
                ID_FREIGHT_ROUTES: null,
                ID_INLAND_ROUTES: null,
                ID_TARIFF_COMPLEMENTARY: null
            },
            INCOME: {
                CHARGE_NUMBER: null,
                CHARGE_PAIR_REFERENCE: null,
                CHARGE_TYPE: { "ID": "M", "NAME": "Manual" },
                CHARGE: null,
                CHARGE_EXHIBITION: null,
                NATURE: { ID: "2", NAME: "Recebimento" },
                AMOUNT: 0,
                APPLICATION: null,
                CURRENCY: null,
                MINIMUM: 0,
                UNITARY: 0,
                TOTAL: 0,
                TOTAL_BRL: 0,
                CONVERSION_SPREAD: 0,
                TRANSACTION: { ID: "0", NAME: "(Sem pagamento)" },
                PEOPLE: null,
                VALIDITY_OF: (keepCharge) ? validityOf : null,
                VALIDITY_UP: (keepCharge) ? validityUp : null,
                SHOW_FOR_GROUP: null,
                EQUIPMENT: null,
                VEHICLE_TYPE: null,
                WEIGHT_RANGE: null,
                INVOICE_NUMBER: this.$scope.operation == "register" ? this.newInvoice : this.noBilling,
                COMPOSITION_GROUP: null,
                OBSERVATION: null,
                CHARGE_ORIGIN: null,
                MODALITY: null,
                ERP_INTEGRATION_STATUS: null,
                ERP_ORDER: null,
                ERP_SEQUENCE: null,
                ERP_TOP: null,
                ERP_BILLING: null,
                WEIGHT_RANGE_CHARGE: null,
                PARAMS: null,
                VALIDITY_EVENT: null,
                NEGOTIATED: true,
                ID_TARIFF_DOMESTIC: null,
                ID_TARIFF_FREIGHT: null,
                ID_TARIFF_LOCAL: null,
                ID_CONSOLIDATED: null,
                ID_FREIGHT_ROUTES: null,
                ID_INLAND_ROUTES: null,
                ID_TARIFF_COMPLEMENTARY: null
            }
        };
    }

    initDependencies(): Promise<any> {
        const self: ChargeRegisterController = this;
        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getApplicationList(),
                self.getGenericList('transaction'),
                self.getGenericList('charge_shown_for'),
                self.getGenericList('type_payment'),
                self.getCurrenciesList(),
                self.getGenericList("event"),
            ]).then((result: any[]) => {
                self.allApplications = result[0];
                self.$scope.transactions = (result[1]) ? result[1].filter(x => x && x.NAME).sort((x, y) => x.NAME > y.NAME ? 1 : -1) : [];
                self.$scope.shownFor = result[2];
                self.$scope.modalities = result[3];
                self.$scope.currencies = result[4];
                self.$scope.eventList = result[5];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private truncate(word: string, n): string {
        return (word.length > n) ? word.substr(0, n - 1) + '&hellip;' : word;
    }
    private getChargePair(processNumber: string, chargePairNumber?: string): Promise<any> {
        if (chargePairNumber) return this.operationalService.get(`/charge/pair/${processNumber}/${chargePairNumber}`, 10000);
        else this.operationalService.get(`/charge/pair/${processNumber}`, 10000);
    }
    private getProcessByNumber(number: string): Promise<any> {
        const timeout = 120000;
        return this.operationalService.get(`/process/byNumber/${number}/${timeout}`, null, timeout);
    }
    private requestProcessInfo(number: string): Promise<any> {
        const timeout = 120000;
        return this.operationalService.get(`/charge/process/processinfo/${number}/${timeout}`, timeout);
    }

    private async getProcessInvoicesSelector(processNumber: string, transactionID: string, peopleType: string, peopleID?: string, paymentModalityID?: string): Promise<any> {
        try {
            if (!processNumber || !transactionID) return;

            this.block();
            const finopReq: IMonacoRequest = {
                route: `/invoice/list/selector/${processNumber}/${transactionID}/${paymentModalityID}/${peopleType}/${peopleID != null ? peopleID : ''}`,
                timeout: 15000,
            }
            const result = await this.finopService.get(finopReq);
            if (!result || !result.data) return this.handleError(result);

            this.unblock();

            return result.data;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getDefaultApplication(params: IParams[]): Promise<void> {
        try {
            let param = null;
            this.formService.block();

            const result = await this.requestProcessInfo(this.$scope.charge.process.NAME);

            const processInfo: IProcessInfo = result.data.data;

            if (params && params.length > 0) param = params.find(param => param.TYPE_CARGO.find(typeCargo => typeCargo.ID === processInfo.CARGO_TYPE.ID));

            if (param) {
                const foundOutcome = this.allApplications.find(item => item.CODE == param.PAYMENT.CODE)
                this.$scope.model.OUTCOME.APPLICATION = foundOutcome

                const foundIcome = this.allApplications.find(item => item.CODE == param.PAYMENT.CODE)
                this.$scope.model.OUTCOME.APPLICATION = foundIcome

                this.refreshApplication('1', 'composition')
                this.refreshApplication('2', 'composition')
            }

            this.$timeout(() => {
                this.$scope.selectorValidity('OUTCOME_application');
                this.$scope.selectorValidity('INCOME_application');
            }, 100);
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getRegisteredCharges(search?: string): Promise<ISelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const products = (this.$scope.charge && this.$scope.charge.process && this.$scope.charge.process.CODE) ? [this.$scope.charge.process.CODE] : undefined;
            const types = [EChargeOriginId.FREIGHT, EChargeOriginId.ORIGIN, EChargeOriginId.DESTINATION, EChargeOriginId.DET_DEM, EChargeOriginId.INSURANCE, EChargeOriginId.TAX, EChargeOriginId.OTHERS]

            const request: IMonacoRequest = {
                data: { search, products, types },
                route: `/chargeName/list/custom/withexhibition`,
                timeout: 10000,
            };

            const { data: charges } = await this.productService.post(request);

            if (charges && charges.data) {
                result = charges.data.map(charge => {
                    const found = charge.CHARGE_NAME_EXHIBITION.find((ex => ex.NAME.toUpperCase().indexOf(search.toUpperCase()) > -1 || ex.NAME_INTL.toUpperCase().indexOf(search.toUpperCase()) > -1));
                    return {
                        ID: charge.ID,
                        NAME: charge.NAME,
                        NAME_INTL: charge.NAME,
                        CODE: charge.CODE,
                        CHARGE_NAME_EXHIBITION: charge.CHARGE_NAME_EXHIBITION,
                        CHARGE_NAME_EXHIBITION_DEFAULT: charge.CHARGE_NAME_EXHIBITION_DEFAULT,
                        PARAMS: charge.PARAMS,
                        CHARGE_NAME_EXHIBITION_FOUND: found ? found : charge.CHARGE_NAME_EXHIBITION_DEFAULT,
                        DISPLAY_PAYMENT: charge.DISPLAY_PAYMENT ? charge.DISPLAY_PAYMENT : null,
                        DISPLAY_RECEIVING: charge.DISPLAY_RECEIVING ? charge.DISPLAY_RECEIVING : null,
                        CHARGE_ORIGIN: charge['TYPE']
                    }
                });
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getChargeExhibitionList(chargeId: string): Promise<CHARGE_EXHIBITION[]> {
        try {
            const transform = (returnedData) => returnedData.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, NAME_INTL: x.NAME_INTL } });

            const request: IMonacoRequest = {
                data: {
                    chargeNameId: chargeId,
                    products: (this.$scope.charge && this.$scope.charge.process && this.$scope.charge.process.CODE) ? [this.$scope.charge.process.CODE] : undefined,
                },
                route: `/chargeName/list/custom/exhibition`,
                timeout: 30000,
            }
            const response = await this.productService.post<IHttpResponse<object[]>>(request);

            this.unblock();
            const result: CHARGE_EXHIBITION[] = (response && response.data && response.data.data) ? (response.data.data.data) ? transform(response.data.data.data) : transform(response.data.data) : [];

            return result;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getCurrenciesList(term?: string): Promise<ISelectorModel[]> {
        try {
            const transform = (returnedData) => returnedData.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } });

            const request: IMonacoRequest = {
                data: {},
                route: `/currency/list/custom/`,
                timeout: 10000,
            };
            const response = await this.productService.post(request);

            this.unblock();
            const result: ISelectorModel[] = (response && response.data && response.data.data) ? transform(response.data.data) : [];

            return result;
        } catch (ex) {
            this.handleError(ex);
        }
    };

    private async getApplicationList(term?: string): Promise<ISelectorModel[]> {
        try {

            const transform = (returnedData: SelectorModel[]): SelectorModel[] => {
                const noChargeApplication = returnedData.find(x => x.ID == '1');
                const result = returnedData.filter(x => x && x.NAME && x.ID != "1").sort((x, y) => x.NAME > y.NAME ? 1 : -1);

                if (noChargeApplication) result.unshift(noChargeApplication);

                return result;
            };

            const request: IMonacoRequest = {
                data: {},
                route: `/application/list/custom/`,
                timeout: 10000,
            };
            const response = await this.productService.post(request);

            this.unblock();
            const result: ISelectorModel[] = (response && response.data && response.data.data) ? transform(response.data.data) : [];

            return result;
        } catch (ex) {
            this.handleError(ex);
        }
    };

    private async getPeople(term: string): Promise<PEOPLE[]> {
        try {
            let result: PEOPLE[] = [];
            const transform = (returnedData: ENTITY[]): PEOPLE[] => returnedData.map(x => ({ ID: x.ID, NAME: x.NAME, CODE: x.CODE, ID_LEGAL_PERSON: x.ID_LEGAL_PERSON ? x.ID_LEGAL_PERSON.toString() : null, ID_PHYSICAL_PERSON: null, NETWORK: x.NETWORK, CORPORATE_NAME: x.TRADING_NAME, TAXPAYER_NUMBER: x.TAXPAYER_NUMBER, SCAC: x.SCAC, NUMBER_IATA: x.NUMBER_IATA, ADDRESS: x.ADDRESS, TRADING_NAME: x.TRADING_NAME }));
            const transformPhysicalPerson = (returnedData: ENTITY[]): PEOPLE[] => returnedData.map(x => ({ ID: x.ID, NAME: x.NAME, CODE: x.CODE, ID_LEGAL_PERSON: null, ID_PHYSICAL_PERSON: x.ID, NETWORK: x.NETWORK, CORPORATE_NAME: x.CORPORATE_NAME, TAXPAYER_NUMBER: null, TRADING_NAME: x.TRADING_NAME, SCAC: x.SCAC, NUMBER_IATA: x.NUMBER_IATA, ADDRESS: x.ADDRESS }));

            if (term.length > 2) {
                this.block();
                const request: IMonacoRequest = {
                    data: { search: term },
                    route: `/legalPerson/list/custom/operational`,
                    timeout: 10000,
                };
                const response = await this.productService.post(request);


                const requestPhysicalPerson: IMonacoRequest = {
                    data: { search: term },
                    route: `/physicalPerson/list/custom/operational`,
                    timeout: 10000,
                };
                const responsePhysicalPerson = await this.productService.post(requestPhysicalPerson);

                this.unblock();
                result = (response && response.data && response.data.data) ? transform(response.data.data) : [];
                if (responsePhysicalPerson && responsePhysicalPerson.data && responsePhysicalPerson.data.data) {
                    result = result.concat(transformPhysicalPerson(responsePhysicalPerson.data.data))
                }
            }
            return result;
        } catch (ex) {
            this.handleError(ex);
        }
    };

    private async getProcessSelector(processNumber: string): Promise<ISelectorModel[]> {
        try {
            try {
                let resultContent: ISelectorModel[] = [];
                if (processNumber && processNumber.length >= 3) {
                    const rc = await this.getProcessByNumber(processNumber)
                    if (rc && rc.data && rc.data.data) {
                        const process = rc.data.data[0];
                        if (process.SITUATION && process.SITUATION.ID && process.SITUATION.ID == "1" && !await this.permissionService.isRoleAllowed('PREPROCESSCHARGEEDIT')) {
                            this.permissionService.showBlockMessage();
                            resultContent = null;
                        } else {
                            this.block();
                            const timeout = 120000;
                            const data = {
                                forwardingSituationToExclude: ["5", "7"], // finalizado e cancelado
                                term: processNumber,
                                timeout,
                            }
                            const response = await this.operationalService.post(`/process/selector`, data, timeout);
                            resultContent = (response && response.data && response.data.data) ? response.data.data : [];
                        }
                    }
                }
                return resultContent;
            } catch (ex) {
                this.handleError(ex);
            }
        } finally {
            this.unblock();
        }
    }

    private refreshChargeApplication(processNumber: string, charge: Charge): Promise<any> {
        if (!charge.APPLICATION) return;
        const params = { processNumber: processNumber, chargeToUpdate: charge }
        return this.operationalService.post('/charge/application/refresh', params, 15000);
    }

    private async getChargeCompositionList(processNumber: string, charge: Charge, isDefaultComposition: boolean = false): Promise<COMPOSITION_GROUP[]> {
        try {
            try {
                this.block();
                const response = await this.operationalService.post(`/charge/application/composition`, { processNumber, percentualCharge: charge, defaultComposition: isDefaultComposition, }, 10000);
                const resultContent: COMPOSITION_GROUP[] = (response && response.data && response.data.data) ? response.data.data : [];
                return resultContent;
            } catch (ex) {
                this.handleError(ex);
            }
        } finally {
            this.unblock();
        }
    }

    private requestHistory(chargeid: string, processId?: number): Promise<any> {
        return this.operationalService.get(`/charge/viewLog/${chargeid}/${processId}`, 20000);
    }

    private getPeopleByTransaction(processNumber: string, transactionid: string): Promise<any> {
        return this.operationalService.get(`/charge/transaction/${processNumber}/${transactionid}`, 10000);
    }

    private async getGenericList(type): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private invoiceDisabled(nature: string): boolean {
        return ((this.$scope.model[nature].APPLICATION && this.$scope.model[nature].APPLICATION.INTEGRATION_ID == '1') || (this.$scope.model[nature].TRANSACTION && this.$scope.model[nature].TRANSACTION.ID == '8' && this.$scope.model[nature].PEOPLE === null));
    }

    private async refreshProcess(clear) {
        try {
            this.block();

            this.$scope.processes = null;
            this.$scope.processEquipment = [];
            this.$scope.invoices = { INCOME: null, OUTCOME: null };

            if (clear || !this.$scope.charge.process) await this.initModel(true);

            if (!this.$scope.charge.process) { this.unblock(); return; }

            const process_number = this.$scope.charge.process.NAME;
            if (process_number) {
                const result = await this.requestProcessInfo(process_number);
                if (result && result.data) {
                    const processInfo: IProcessInfo = result.data.data;

                    this.$scope.processEquipment = (processInfo.CARGO_DETAIL && processInfo.CARGO_DETAIL.EQUIPMENT_LIST) ? processInfo.CARGO_DETAIL.EQUIPMENT_LIST.map(x => x.EQUIPMENT) : [];

                    if (processInfo.VALIDITY_OF) {
                        if (!this.$scope.model.INCOME.VALIDITY_OF) this.$scope.model.INCOME.VALIDITY_OF = processInfo.VALIDITY_OF;
                        if (!this.$scope.model.OUTCOME.VALIDITY_OF) this.$scope.model.OUTCOME.VALIDITY_OF = processInfo.VALIDITY_OF;
                    }
                    if (processInfo.VALIDITY_UP) {
                        if (!this.$scope.model.INCOME.VALIDITY_UP) this.$scope.model.INCOME.VALIDITY_UP = processInfo.VALIDITY_UP;
                        if (!this.$scope.model.OUTCOME.VALIDITY_UP) this.$scope.model.OUTCOME.VALIDITY_UP = processInfo.VALIDITY_UP;
                    }

                    this.$scope.blocked.CHECK_VALUE = (processInfo.AUDIT && processInfo.AUDIT.CHECK_VALUE && processInfo.AUDIT.CHECK_VALUE.STATUS === true);
                }
            }

            await this.refreshInvoices("OUTCOME");
            await this.refreshInvoices("INCOME");

            this.$scope.selectorValidity('INCOME_process');
            this.$scope.selectorValidity('OUTCOME_process');

            await this.$scope.$applyAsync();

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async setSelectedCharge() {
        try {
            await this.initModel(true, true);

            this.defaultCompositions = [];
            this.processCharges = [];
            this.$scope.compositionList = {
                INCOME: [],
                OUTCOME: []
            };
            this.previousCharge = {
                INCOME: null,
                OUTCOME: null
            };
            this.$scope.chargeExhibitionList = {
                INCOME: [],
                OUTCOME: []
            };

            const charge = this.$scope.model.CHARGE;

            await this.setShownForCharge();

            let chargeOrigin: SelectorModel = null;
            if (charge) {

                if (charge.hasOwnProperty('CHARGE_ORIGIN')) {
                    chargeOrigin = angular.copy(charge['CHARGE_ORIGIN']);
                    delete charge['CHARGE_ORIGIN'];
                }

                if (charge.hasOwnProperty('SHOW_CUSTOMER')) delete charge['SHOW_CUSTOMER'];
                if (charge.hasOwnProperty('SHOW_AGENT')) delete charge['SHOW_AGENT'];
                if (charge.hasOwnProperty('SHOW_TRANSPORT_DOC')) delete charge['SHOW_TRANSPORT_DOC'];
                if (charge.hasOwnProperty('NAME_TAXA_LOGISTICA')) delete charge['NAME_TAXA_LOGISTICA'];
            }

            this.$scope.model.CHARGE = angular.copy(charge);
            this.$scope.model.INCOME.CHARGE = angular.copy(charge);
            this.$scope.model.OUTCOME.CHARGE = angular.copy(charge);

            if (chargeOrigin) {
                this.$scope.model.INCOME.CHARGE_ORIGIN = chargeOrigin;
                this.$scope.model.OUTCOME.CHARGE_ORIGIN = chargeOrigin;
            }

            this.$scope.selectorValidity('INCOME_charge');
            this.$scope.selectorValidity('OUTCOME_charge');

            if ((!this.$scope.model) || (!this.$scope.charge) || (!this.$scope.charge.process)) return;

            await this.configureModality(this.$scope.model, this.$scope.charge.process.NAME);

            if (this.$scope.model.CHARGE) {
                const chargeExhibitionList = await this.getChargeExhibitionList(this.$scope.model.CHARGE.ID);

                this.$scope.chargeExhibitionList.OUTCOME = angular.copy(chargeExhibitionList);
                this.$scope.chargeExhibitionList.INCOME = angular.copy(chargeExhibitionList);

                if (chargeExhibitionList && chargeExhibitionList.length > 0) {
                    let findedChargeExhibition = null;

                    const selectedCharge = this.$scope.chargeList.find(charge => charge.ID === this.$scope.model.CHARGE.ID);

                    if (selectedCharge && selectedCharge['CHARGE_NAME_EXHIBITION_FOUND']) findedChargeExhibition = chargeExhibitionList.find(chargeExhibition => chargeExhibition.ID === selectedCharge['CHARGE_NAME_EXHIBITION_FOUND']['ID']);

                    const chargeExhibition = findedChargeExhibition ? findedChargeExhibition : chargeExhibitionList[0];

                    this.$scope.model.OUTCOME.CHARGE_EXHIBITION = chargeExhibition;
                    this.$scope.model.INCOME.CHARGE_EXHIBITION = chargeExhibition;

                    this.$timeout(() => {
                        this.$scope.selectorValidity('OUTCOME_exhibition');
                        this.$scope.selectorValidity('INCOME_exhibition');
                    }, 100);
                }
            }

            await this.getDefaultApplication(this.$scope.model.CHARGE.PARAMS);
            this.getDefaultValidityBasis();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private beforeSaveCharge() {
        try {
            if (this.$scope.isOutcomeWeightRangeApplication && this.$scope.model.OUTCOME && this.$scope.model.OUTCOME.WEIGHT_RANGE_CHARGE) {
                for (const weightRangeCharge of this.$scope.model.OUTCOME.WEIGHT_RANGE_CHARGE) {
                    weightRangeCharge.CURRENCY = this.$scope.model.OUTCOME.CURRENCY;
                    weightRangeCharge.MINIMUM = this.$scope.model.OUTCOME.MINIMUM;
                }
            } else if (!this.$scope.isOutcomeWeightRangeApplication && this.$scope.model.OUTCOME && this.$scope.model.OUTCOME.WEIGHT_RANGE_CHARGE) {
                this.$scope.model.OUTCOME.WEIGHT_RANGE_CHARGE = null;
                this.$scope.model.INCOME.WEIGHT_RANGE = null;
            }
            if (this.$scope.isIncomeWeightRangeApplication && this.$scope.model.INCOME && this.$scope.model.INCOME.WEIGHT_RANGE_CHARGE) {
                for (const weightRangeCharge of this.$scope.model.INCOME.WEIGHT_RANGE_CHARGE) {
                    weightRangeCharge.CURRENCY = this.$scope.model.INCOME.CURRENCY;
                    weightRangeCharge.MINIMUM = this.$scope.model.INCOME.MINIMUM;
                }
            } else if (this.$scope.isIncomeWeightRangeApplication && this.$scope.model.INCOME && this.$scope.model.INCOME.WEIGHT_RANGE_CHARGE) {
                this.$scope.model.INCOME.WEIGHT_RANGE_CHARGE = null;
                this.$scope.model.INCOME.WEIGHT_RANGE = null;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async configureModality(charge: ChargePair, processNumber: string) {
        try {
            if ((!charge) || (!processNumber)) return;

            const rc = await this.getProcessByNumber(processNumber);

            if (rc && rc.data && rc.data.data) {
                const process = rc.data.data[0];

                const chargeList = [charge.OUTCOME, charge.INCOME];

                for (const item of chargeList) {
                    if (!item.CHARGE_ORIGIN) continue;

                    switch (item.CHARGE_ORIGIN.ID) {
                        case '1':
                        case '4': item.MODALITY = item.NATURE.ID === "1" ? process.MASTER_PAYMENT : process.HOUSE_PAYMENT;
                            break;
                        case '2': item.MODALITY = this.$scope.modalities.find(x => x.ID === "3");
                            break;
                        case '3': item.MODALITY = this.$scope.modalities.find(x => x.ID === "2");
                            break;
                    }
                }

                this.$scope.$applyAsync();
            }
            else return;

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async setShownForCharge() {
        if (!this.$scope.model.CHARGE || !this.$scope.shownFor || !(this.$scope.shownFor.length > 0)) return true;
        try {
            const charge = this.$scope.model.CHARGE;
            const showForList = this.$scope.shownFor;
            const selectedshowFor = [];

            if (charge.hasOwnProperty('SHOW_CUSTOMER') && charge['SHOW_CUSTOMER']) {
                let genericInfo = showForList.find(chr => (chr.NAME == "Cliente"))
                if (genericInfo) selectedshowFor.push(genericInfo);
            }
            if (charge.hasOwnProperty('SHOW_AGENT') && charge['SHOW_AGENT']) {
                let genericInfo = showForList.find(chr => (chr.NAME == "Agente"))
                if (genericInfo) selectedshowFor.push(genericInfo);
            }
            if (charge.hasOwnProperty('SHOW_TRANSPORT_DOC') && charge['SHOW_TRANSPORT_DOC']) {
                let genericInfo = showForList.find(chr => (chr.NAME == "Conhecimento Master"))
                if (genericInfo) selectedshowFor.push(genericInfo);

                genericInfo = null;

                genericInfo = showForList.find(chr => (chr.NAME == "Conhecimento House"))
                if (genericInfo) selectedshowFor.push(genericInfo);
            }
            this.$scope.model.OUTCOME.SHOW_FOR_GROUP = selectedshowFor;
            this.$scope.model.INCOME.SHOW_FOR_GROUP = selectedshowFor;
        } catch (ex) {
            this.handleError(ex);
        }
    }


    // ========================= APPLICATION & COMPOSITION & TOTAL =================================== //

    private async refreshApplication(natureID: string, triggeredBy: string): Promise<void> {
        try {
            this.block();

            let chargeToEval = (natureID == '1') ? this.$scope.model.OUTCOME : this.$scope.model.INCOME;

            if (!chargeToEval.APPLICATION) { this.unblock(); return; }

            if (chargeToEval.APPLICATION.INTEGRATION_ID === '1') {
                await this.setNoPayment(chargeToEval);
            } else {
                // selectors restoration logic
                if (natureID == '1') {
                    if (this.previousCharge.OUTCOME) {
                        const application = angular.copy(chargeToEval.APPLICATION);
                        chargeToEval = angular.copy(this.previousCharge.OUTCOME);
                        chargeToEval.APPLICATION = application;
                        this.previousCharge.OUTCOME = null;
                    }
                } else {
                    if (this.previousCharge.INCOME) {
                        const application = angular.copy(chargeToEval.APPLICATION);
                        chargeToEval = angular.copy(this.previousCharge.INCOME);
                        chargeToEval.APPLICATION = application;
                        this.previousCharge.INCOME = null;
                    }
                }

                await this.$scope.$applyAsync();

                // properly resets dependent fields
                if (!chargeToEval.AMOUNT) chargeToEval.AMOUNT = 0;
                if (!chargeToEval.UNITARY) chargeToEval.UNITARY = 0;
                if (!chargeToEval.PEOPLE) chargeToEval.PEOPLE = null;
                if (!chargeToEval.CURRENCY) chargeToEval.CURRENCY = null;
                if (!chargeToEval.EQUIPMENT) chargeToEval.EQUIPMENT = null;
                if (!chargeToEval.INVOICE_NUMBER) chargeToEval.INVOICE_NUMBER = null;

                const currencyId = (chargeToEval.CURRENCY && !chargeToEval.CURRENCY.hasOwnProperty('INTEGRATION_ID')) ? chargeToEval.CURRENCY.ID : null;
                if (currencyId) {
                    const currencyIntegrationIdResult = await this.productService.get<{ INTEGRATION_ID: string }>({ route: `/currency/integrationId/${currencyId}` });
                    const currencyIntegrationId = (currencyIntegrationIdResult.data && currencyIntegrationIdResult.data.data) ? currencyIntegrationIdResult.data.data.INTEGRATION_ID : null;
                    if (currencyIntegrationId) chargeToEval.CURRENCY['INTEGRATION_ID'] = currencyIntegrationId.toString();
                }

                if (chargeToEval.APPLICATION.INTEGRATION_ID == '13') {

                    //get composition list
                    const compositionList = await this.getChargeCompositionList(this.$scope.charge.process.NAME, chargeToEval);
                    if (natureID == '1') this.$scope.compositionList.OUTCOME = compositionList;
                    else this.$scope.compositionList.INCOME = compositionList;

                    if (this.$scope.model.INCOME && this.$scope.model.INCOME.APPLICATION && this.$scope.model.INCOME.APPLICATION.ID == '6' && this.$scope.model.INCOME.COMPOSITION_GROUP && this.$scope.model.INCOME.COMPOSITION_GROUP.length) {
                        this.$scope.model.INCOME.COMPOSITION_GROUP.forEach(x => {
                            this.$scope.compositionList.INCOME.forEach(c => {
                                if (x.REFERENCE == c.REFERENCE) {
                                    x.INCIDENCE = c.INCIDENCE
                                }
                            });
                        });
                    }

                    if (this.$scope.model.OUTCOME && this.$scope.model.OUTCOME.APPLICATION && this.$scope.model.OUTCOME.APPLICATION.ID == '6' && this.$scope.model.INCOME.COMPOSITION_GROUP && this.$scope.model.OUTCOME.COMPOSITION_GROUP.length) {
                        this.$scope.model.OUTCOME.COMPOSITION_GROUP.forEach(x => {
                            this.$scope.compositionList.OUTCOME.forEach(c => {
                                if (x.REFERENCE == c.REFERENCE) {
                                    x.INCIDENCE = c.INCIDENCE
                                }
                            });
                        });
                    }

                } else chargeToEval.COMPOSITION_GROUP = null;

                const result = await this.refreshChargeApplication(this.$scope.charge.process.NAME, chargeToEval);
                const applicationRefreshedCharge: Charge = (result.data) ? result.data.data : null;
                if (!applicationRefreshedCharge || isNaN(applicationRefreshedCharge.AMOUNT)) {
                    if (natureID == '1') this.$scope.setSelectorStatus('OUTCOME_application', false);
                    else this.$scope.setSelectorStatus('INCOME_application', false);
                    return this.handleError(this.formService.getTranslate("FINANCIAL.ERROR_BASIS"));
                }

                if (applicationRefreshedCharge.WEIGHT_RANGE_CHARGE) applicationRefreshedCharge.WEIGHT_RANGE_CHARGE = applicationRefreshedCharge.WEIGHT_RANGE_CHARGE.sort((x, y) => x.WEIGHT_RANGE.ID < y.WEIGHT_RANGE.ID ? -1 : 1);

                chargeToEval = applicationRefreshedCharge;
            }

            if (natureID == '1') {
                this.$scope.model.OUTCOME = angular.copy(chargeToEval);
                this.$scope.isOutcomeWeightRangeApplication = chargeToEval.APPLICATION && chargeToEval.APPLICATION.APPLICATION_COMPLEMENT && chargeToEval.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
            }
            else {
                this.$scope.model.INCOME = angular.copy(chargeToEval);
                this.$scope.isIncomeWeightRangeApplication = chargeToEval.APPLICATION && chargeToEval.APPLICATION.APPLICATION_COMPLEMENT && chargeToEval.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
            }

            if (triggeredBy == 'application') {
                this.validateChargeTransaction();
                if (this.$scope.isOutcomeWeightRangeApplication || this.$scope.isIncomeWeightRangeApplication) {
                    const processWeightRangeList: IWeightRangeResponse[] = await this.getProcessWeightRange();
                    if (processWeightRangeList && processWeightRangeList.length) {
                        const weightRangeActive = processWeightRangeList.find(weightRange => weightRange.ACTIVE);
                        const weightRangeCharge: WEIGHT_RANGE_CHARGE[] = processWeightRangeList.map(offerWeightRange => { return { ACTIVE: offerWeightRange.ACTIVE, CURRENCY: null, MINIMUM: null, UNITARY: null, WEIGHT_RANGE: { ID: offerWeightRange.ID, NAME: offerWeightRange.NAME } } });
                        this.$scope.weightRangeActiveId = weightRangeActive.ID;
                        if (natureID == '1') {
                            const isIncomeCollapsedIn = angular.element("#collapseIncomeWeightRange").attr("aria-expanded") == "true";
                            angular.element("#collapseOutcomeWeightRange")["collapse"](isIncomeCollapsedIn ? "show" : "hide");
                            this.$scope.model.OUTCOME.WEIGHT_RANGE = { ID: weightRangeActive.ID, NAME: weightRangeActive.NAME };
                            this.$scope.model.OUTCOME.WEIGHT_RANGE_CHARGE = angular.copy(weightRangeCharge);
                        } else {
                            const isOutcomeCollapsedIn = angular.element("#collapseOutcomeWeightRange").attr("aria-expanded") == "true";
                            angular.element("#collapseIncomeWeightRange")["collapse"](isOutcomeCollapsedIn ? "show" : "hide");
                            this.$scope.model.INCOME.WEIGHT_RANGE = { ID: weightRangeActive.ID, NAME: weightRangeActive.NAME };
                            this.$scope.model.INCOME.WEIGHT_RANGE_CHARGE = angular.copy(weightRangeCharge);
                        }
                    }
                }
            }
            if (triggeredBy == 'unitary') {
                if (natureID == '1') this.updateOutcomeMin();
                else this.updateIncomeMin();
            }

            this.unblock();
            await this.$scope.$applyAsync();
        } catch (ex) {
            if (natureID == '1') this.$scope.setSelectorStatus('OUTCOME_application', false);
            if (natureID == '2') this.$scope.setSelectorStatus('INCOME_application', false);
            this.handleError(ex);
        }
    }

    private async updateOutcomeMin() {
        try {
            if (this.$scope.model.OUTCOME && this.$scope.model.OUTCOME.APPLICATION && this.$scope.model.OUTCOME.APPLICATION.PERCENT && !this.$scope.model.OUTCOME.MINIMUM) {
                this.$scope.model.OUTCOME.MINIMUM = 0;
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async updateIncomeMin() {
        try {
            if (this.$scope.model.INCOME && this.$scope.model.INCOME.APPLICATION && this.$scope.model.INCOME.APPLICATION.PERCENT && !this.$scope.model.INCOME.MINIMUM) {
                this.$scope.model.INCOME.MINIMUM = 0;
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async weightRangeOutcomeValueUnitaryChange(weightRangeId: string) {
        try {
            const weightRangeActive = await this.getWeightRangeOutcomeActive(weightRangeId);
            if (weightRangeActive) {
                this.$scope.model.OUTCOME.UNITARY = weightRangeActive.UNITARY;
                await this.refreshApplication('1', 'editStart');
                this.hasChargeChange();

            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async weightRangeIncomeValueUnitaryChange(weightRangeId: string) {
        try {
            const weightRangeActive = await this.getWeightRangeIncomeActive(weightRangeId);
            if (weightRangeActive) {
                this.$scope.model.INCOME.UNITARY = weightRangeActive.UNITARY;
                await this.refreshApplication('2', 'editStart');
                this.hasChargeChange();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getWeightRangeOutcomeActive(weightRangeId: string): Promise<WEIGHT_RANGE_CHARGE> {
        try {
            let weightRangeIdToSearch = null;
            if (this.$scope.weightRangeActiveId && this.$scope.weightRangeActiveId == weightRangeId) weightRangeIdToSearch = weightRangeId;
            else if (this.$scope.model.OUTCOME.WEIGHT_RANGE) weightRangeIdToSearch = this.$scope.model.OUTCOME.WEIGHT_RANGE.ID;
            const weightRangeActive = this.$scope.model.OUTCOME.WEIGHT_RANGE_CHARGE.find(weightRangeCharge => weightRangeCharge.WEIGHT_RANGE && weightRangeCharge.WEIGHT_RANGE.ID == weightRangeIdToSearch);
            return weightRangeActive && weightRangeActive.WEIGHT_RANGE && weightRangeActive.WEIGHT_RANGE.ID == weightRangeId ? weightRangeActive : null;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getWeightRangeIncomeActive(weightRangeId: string) {
        try {
            let weightRangeIdToSearch = null;
            if (this.$scope.weightRangeActiveId && this.$scope.weightRangeActiveId == weightRangeId) weightRangeIdToSearch = weightRangeId;
            else if (this.$scope.model.INCOME.WEIGHT_RANGE) weightRangeIdToSearch = this.$scope.model.INCOME.WEIGHT_RANGE.ID;
            const weightRangeActive = this.$scope.model.INCOME.WEIGHT_RANGE_CHARGE.find(weightRangeCharge => weightRangeCharge.WEIGHT_RANGE && weightRangeCharge.WEIGHT_RANGE.ID == weightRangeIdToSearch);
            return weightRangeActive;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getProcessWeightRange(): Promise<IWeightRangeResponse[]> {
        let processWeightRangeList: IWeightRangeResponse[] = null;
        try {
            this.formService.block();
            const request = await this.operationalService.get(`/processChargeWeightRange/weightRange/${this.$scope.charge.process.ID}`, null);
            if (request && request.data && request.data.data) processWeightRangeList = request.data.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return processWeightRangeList;
        }
    }

    private async setNoPayment(charge: Charge) {

        if (charge.NATURE.ID == '1')
            this.previousCharge.OUTCOME = angular.copy(this.$scope.model.OUTCOME);

        if (charge.NATURE.ID == '2')
            this.previousCharge.INCOME = angular.copy(this.$scope.model.INCOME);

        charge.TRANSACTION = { ID: '0', NAME: '(Sem pagamento)' };
        charge.AMOUNT = 0;
        charge.COMPOSITION_GROUP = null;
        charge.CURRENCY = null;
        charge.EQUIPMENT = null;
        charge.MINIMUM = 0;
        charge.PEOPLE = null;
        //charge.SHOW_FOR_GROUP = []
        charge.TOTAL = 0;
        charge.UNITARY = 0;

        charge.INVOICE_NUMBER = this.noBilling;

        /* if (charge.NATURE.ID == '1')
            await this.refreshInvoices('OUTCOME');
    
        if (charge.NATURE.ID == '2')
            await this.refreshInvoices('INCOME'); */
    }

    // ============================ REFRESHES ====================================//

    private async refreshCharges(name: string, chargeType: string): Promise<void> {
        this.$scope.chargeList = [];
        if (!this.$scope.charge.process) return;
        try {
            if (name.length >= 3) {
                const result = await this.getRegisteredCharges(name);
                const returnedCharges = result;
                if (returnedCharges && returnedCharges.length > 0) {
                    // if (chargeType === 'A') returnedCharges = returnedCharges.filter(chrg => chrg.CODE == this.$scope.model.CHARGE.CODE);
                    this.$scope.chargeList = returnedCharges;
                    await this.$scope.$applyAsync();
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async refreshPeople(nature: string, name: string): Promise<void> {
        try {
            if (name.length >= 3) {
                this.$scope.model[nature].INVOICE_NUMBER = this.$scope.operation == "register" ? this.newInvoice : null;
                const result = await this.getPeople(name);
                if (result) {
                    this.$scope.people[nature] = result;
                    await this.$scope.$applyAsync();
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async refreshProcessPeople(nature: string) {

        this.$scope.model[nature].PEOPLE = null;
        const transactionID = this.$scope.model[nature].TRANSACTION.ID;

        this.validateChargeTransaction();

        const processNumber = this.$scope.charge.process.NAME;
        const result = await this.getPeopleByTransaction(processNumber, transactionID);
        const processPeople = (result && result.data) ? result.data.data : null;

        this.$scope.people[nature] = [processPeople];
        this.$scope.model[nature]['PEOPLE'] = this.$scope.people[nature][0];
        this.$scope.model[nature]['INVOICE_NUMBER'] = this.$scope.operation == "register" ? this.newInvoice : null;
        await this.refreshInvoices(nature);
        await this.$scope.$applyAsync();
    }

    private async refreshInvoices(nature: string): Promise<void> {
        try {
            if (!nature) return;

            this.$scope.invoices[nature] = [];

            if (this.$scope.charge.process && this.$scope.model[nature].TRANSACTION) {
                const transactionID = (this.$scope.model[nature].TRANSACTION) ? this.$scope.model[nature].TRANSACTION.ID : null;
                const peopleID = this.$scope.model[nature].PEOPLE ? (this.$scope.model[nature].PEOPLE.ID_PHYSICAL_PERSON ? this.$scope.model[nature].PEOPLE.ID_PHYSICAL_PERSON : this.$scope.model[nature].PEOPLE.ID_LEGAL_PERSON) : null;
                const peopleType = this.$scope.model[nature].PEOPLE && this.$scope.model[nature].PEOPLE.ID_PHYSICAL_PERSON ? 'F' : 'J';
                const paymentModalityID = this.$scope.model[nature].MODALITY ? this.$scope.model[nature].MODALITY.ID : null;
                const processNumber = this.$scope.charge.process.NAME;

                const result = await this.getProcessInvoicesSelector(processNumber, transactionID, peopleType, peopleID, paymentModalityID);
                const invoices: IInvoiceNumber[] = (result && result.data) ? result.data : [];
                for (let invoice of invoices) {
                    //se for invoce "sem fatura" ou 'nova fatura', ou status diferente de pré faturada, adiciona
                    if ((invoice.ID == '0' || invoice.ID == '-1') || invoice.STATUS.ID != '2') {
                        this.$scope.invoices[nature].push(invoice);
                    }
                }
            }
            this.$scope.invoices[nature].unshift(this.newInvoice);
            this.$scope.invoices[nature].unshift(this.noBilling);

            if (this.$scope.model[nature].INVOICE_NUMBER && !this.$scope.invoices[nature].some(x => x.ID === this.$scope.model[nature].INVOICE_NUMBER.ID) && !this.$scope.blocked[nature].locked && !this.$scope.blocked[nature].pre_invoice) this.$scope.model[nature].INVOICE_NUMBER = null;

            await this.$scope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    // ============================ FORM CONTROL ====================================//

    private async validateChargeTransaction(): Promise<boolean> {
        try {
            let isValid = true;
            if (this.$scope.model.OUTCOME.APPLICATION &&
                this.$scope.model.OUTCOME.APPLICATION.INTEGRATION_ID != '1' &&
                this.$scope.model.OUTCOME.TRANSACTION &&
                this.$scope.model.OUTCOME.TRANSACTION.ID == '0') {
                this.$scope.setSelectorStatus(`OUTCOME_transaction`, false);
                isValid = false;
            } else
                this.$scope.setSelectorStatus(`OUTCOME_transaction`, true);

            if (this.$scope.model.INCOME.APPLICATION &&
                this.$scope.model.INCOME.APPLICATION.INTEGRATION_ID != '1' &&
                this.$scope.model.INCOME.TRANSACTION &&
                this.$scope.model.INCOME.TRANSACTION.ID == '0') {
                this.$scope.setSelectorStatus(`INCOME_transaction`, false);
                isValid = false;
            }
            else
                this.$scope.setSelectorStatus(`INCOME_transaction`, true);

            await this.$scope.$applyAsync();

            if (!isValid) this.handleWarning(this.formService.getTranslate("FINANCIAL.SELECT_VALID_HOLDER_TYPE"));

            return isValid;
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private async setLocked(): Promise<void> {
        try {
            if (this.$scope.blocked.CHECK_VALUE)
                await this.$scope.disableElements(true, ['OUTCOME_invoice', 'INCOME_invoice'], new RegExp('^OUTCOME|^INCOME', 'i'));

            if (this.$scope.blocked.OUTCOME.locked || this.$scope.blocked.OUTCOME.pre_invoice || this.$scope.blocked.OUTCOME.pending_integration)
                await this.$scope.disableElements(true, [], new RegExp('^OUTCOME', 'i'));

            if (this.$scope.blocked.INCOME.locked || this.$scope.blocked.INCOME.pre_invoice || this.$scope.blocked.INCOME.pending_integration)
                await this.$scope.disableElements(true, [], new RegExp('^INCOME', 'i'));

            await this.$scope.$applyAsync();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private async loadViewChargeGroup(_model: ChargePair | Charge | navItem): Promise<void> {
        try {
            const model = (_model) ? _model : this.$scope.model;
            if (!model['PROCESS'] || !model['CHARGE_NUMBER']) return;

            this.previousCharge = {
                INCOME: null,
                OUTCOME: null
            };

            this.$scope.charge.process = model['PROCESS'];

            const result = await this.getChargePair(this.$scope.charge.process.NAME, model['CHARGE_PAIR_REFERENCE']);
            if (result && result.data) {
                this.$scope.model = result.data.data[0];

                if (!this.$scope.model.OUTCOME)
                    this.handleLoadError(this.formService.getTranslate("FINANCIAL.ERROR_PAYMENT_CHARGE"));
                if (!this.$scope.model.INCOME)
                    this.handleLoadError(this.formService.getTranslate("FINANCIAL.ERROR_RECEIPT_CHARGE"));

            } else this.handleError(result);

            this.$scope.invoiceStatus = {
                OUTCOME: null,
                INCOME: null,
            }
            if (this.$scope.model.OUTCOME.INVOICE_NUMBER) {
                const statusResult = await this.finopService.post<INVOICE_INFO>({ data: { invoiceNumber: this.$scope.model.OUTCOME.INVOICE_NUMBER.NAME }, route: `/invoice/status`, timeout: 15000 })
                this.$scope.invoiceStatus.OUTCOME = (statusResult.data && statusResult.data.data) ? statusResult.data.data : null;
            }
            if (this.$scope.model.INCOME.INVOICE_NUMBER) {
                const statusResult = await this.finopService.post<INVOICE_INFO>({ data: { invoiceNumber: this.$scope.model.INCOME.INVOICE_NUMBER.NAME }, route: `/invoice/status`, timeout: 15000 })
                this.$scope.invoiceStatus.INCOME = (statusResult.data && statusResult.data.data) ? statusResult.data.data : null;
            }
            if (this.$scope.model.INCOME.COMPOSITION_GROUP && this.$scope.model.INCOME.COMPOSITION_GROUP.length) {
                let incomeTotal = 0;
                //get composition list
                const compositionList = await this.getChargeCompositionList(this.$scope.charge.process.NAME, this.$scope.model.INCOME);
                for (const composition of compositionList) {
                    if (!composition.INCIDENCE) composition.INCIDENCE = 0;

                    this.$scope.model.INCOME.COMPOSITION_GROUP.forEach(x => {
                        if (x.REFERENCE == composition.REFERENCE) {
                            x.CHARGE = composition.CHARGE
                            x.INCIDENCE = composition.INCIDENCE
                            x.REFERENCE = composition.REFERENCE
                        }
                    });
                }

                for (const compositionTotal of this.$scope.model.INCOME.COMPOSITION_GROUP) {
                    incomeTotal += compositionTotal.INCIDENCE;
                }
                this.$scope.model.INCOME.TOTAL = incomeTotal;
            }

            this.$scope.blocked = {
                CHECK_VALUE: false,
                OUTCOME: {
                    wizard: false,
                    profitShare: (this.$scope.model.OUTCOME.CHARGE_ORIGIN && this.$scope.model.OUTCOME.CHARGE_ORIGIN.ID == '4'),
                    locked: (this.$scope.invoiceStatus.OUTCOME && this.$scope.invoiceStatus.OUTCOME.hasOwnProperty('LOCKED')) ? this.$scope.invoiceStatus.OUTCOME.LOCKED : false,
                    pre_invoice: (this.$scope.invoiceStatus.OUTCOME && this.$scope.invoiceStatus.OUTCOME.hasOwnProperty('STATUS')) ? (this.$scope.invoiceStatus.OUTCOME.STATUS.ID === '2') : false,
                    pending_integration: (this.$scope.invoiceStatus.OUTCOME && this.$scope.invoiceStatus.OUTCOME.hasOwnProperty('STATUS')) ? (this.$scope.invoiceStatus.OUTCOME.STATUS.ID === '4' || this.$scope.invoiceStatus.OUTCOME.STATUS.ID === '5') : false,
                },
                INCOME: {
                    wizard: false,
                    profitShare: (this.$scope.model.INCOME.CHARGE_ORIGIN && this.$scope.model.INCOME.CHARGE_ORIGIN.ID == '4'),
                    locked: (this.$scope.invoiceStatus.INCOME && this.$scope.invoiceStatus.INCOME.hasOwnProperty('LOCKED')) ? this.$scope.invoiceStatus.INCOME.LOCKED : false,
                    pre_invoice: (this.$scope.invoiceStatus.INCOME && this.$scope.invoiceStatus.INCOME.hasOwnProperty('STATUS')) ? (this.$scope.invoiceStatus.INCOME.STATUS.ID === '2') : false,
                    pending_integration: (this.$scope.invoiceStatus.INCOME && this.$scope.invoiceStatus.INCOME.hasOwnProperty('STATUS')) ? (this.$scope.invoiceStatus.INCOME.STATUS.ID === '4' || this.$scope.invoiceStatus.INCOME.STATUS.ID === '5') : false,
                }
            };

            if (this.$scope.model.CHARGE) {
                const chargeExhibitionList = await this.getChargeExhibitionList(this.$scope.model.CHARGE.ID);
                this.$scope.chargeExhibitionList.OUTCOME = angular.copy(chargeExhibitionList);
                this.$scope.chargeExhibitionList.INCOME = angular.copy(chargeExhibitionList);
            }

            // navigation
            this.$scope.currentNavigationIndex = this.$scope.navigationMap.findIndex(x => (this.$scope.model.INCOME.CHARGE_NUMBER === x.CHARGE_NUMBER || this.$scope.model.OUTCOME.CHARGE_NUMBER === x.CHARGE_NUMBER));

            if (this.$scope.model.CHARGE_PAIR_NUMBER) {
                this.$scope.chargesOutDate = await this.getOutDateChargesByCharge(this.$scope.model.CHARGE_PAIR_NUMBER);
                this.$scope.hasOutDateCharge = (this.$scope.chargesOutDate.length && this.$scope.chargesOutDate.length > 0);
            }

            await this.$scope.$applyAsync();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    async save(): Promise<boolean> {
        try {
            const isValid = await this.validateChargeTransaction();
            if (!isValid) return false;
            else this.beforeSaveCharge();

            return true;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(model: Charge | navItem): Promise<void> {
        try {
            await this.loadViewChargeGroup(model);

            BrowserTitle.$id = this.$scope.model.CHARGE.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(_model: Charge | navItem): Promise<void> {
        try {
            const model = (_model) ? _model : this.$scope.model;

            if (!model['PROCESS']) return this.handleError(`${this.formService.getTranslate("FINANCIAL.FAIL_TO_EDIT_CHARGE")}, Process number is NULL`);

            const processInfo = await this.getProcessInfo(model['PROCESS']['NAME']);

            const rc = await this.getProcessByNumber(model['PROCESS']['NAME']);

            if (rc && rc.data && rc.data.data) {
                const process = rc.data.data[0];
                if (process.SITUATION && process.SITUATION.ID && process.SITUATION.ID == "1" && !await this.permissionService.isRoleAllowed('PREPROCESSCHARGEEDIT')) return this.permissionService.showBlockMessage();
            }

            if (!processInfo) return this.handleWarning(`${model['PROCESS']['NAME']} ${this.formService.getTranslate("FINANCIAL.ERROR_FILE_DETAILS")}`);
            if (processInfo && processInfo.FORWARDING_SITUATION && processInfo.FORWARDING_SITUATION.SITUATION && processInfo.FORWARDING_SITUATION.SITUATION.ID == '5') return this.handleWarning(this.formService.getTranslate("FINANCIAL.CLOSED_FILE"));

            await this.loadViewChargeGroup(model);
            await this.refreshProcess(false);

            await this.refreshApplication('1', 'editStart');
            await this.refreshApplication('2', 'editStart');

            if (this.$scope.model.INCOME.CHARGE_TYPE.ID == 'A' && this.$scope.model.OUTCOME.CHARGE_TYPE.ID == 'A') {
                this.$scope.applications = this.allApplications.filter(apl => (apl.ID == '1' || apl.ID == '22' ||
                    apl.ID == this.$scope.model.INCOME.APPLICATION.ID || apl.ID == this.$scope.model.OUTCOME.APPLICATION.ID));
            } else {
                this.$scope.applications = this.allApplications;
            }

            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);

            BrowserTitle.$id = this.$scope.model.CHARGE.NAME;

        } catch (ex) {
            this.handleError(ex);
        }
    }

    async getProcessInfo(processNumber: string): Promise<IProcessInfo> {
        try {
            if (!processNumber) throw 'Fail to getProcessForwardingSituation ProcessNumber is NULL';
            const result = await this.requestProcessInfo(processNumber);
            const processInfo: IProcessInfo = (result && result.data) ? result.data.data : null;
            return processInfo;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async postEdit(): Promise<void> {
        try {
            await this.setLocked();

            //block editing for profit share
            if (this.$scope.blocked.OUTCOME.profitShare)
                await this.$scope.disableElements(true, ['OUTCOME_invoice', 'OUTCOME_shownFor', 'OUTCOME_observation'], new RegExp('^OUTCOME', 'i'));

            if (this.$scope.blocked.INCOME.profitShare)
                await this.$scope.disableElements(true, ['INCOME_invoice', 'INCOME_shownFor', 'INCOME_observation'], new RegExp('^INCOME', 'i'));

        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            if (!this.$scope.model['PROCESS']) return;
            const processInfo = await this.getProcessInfo(this.$scope.model['PROCESS']['NAME']);
            if (!processInfo) return this.handleWarning(`${this.$scope.model['PROCESS']['NAME']} ${this.formService.getTranslate("FINANCIAL.ERROR_FILE_DETAILS")}`);

            const checkValue = (processInfo.AUDIT && processInfo.AUDIT.CHECK_VALUE && processInfo.AUDIT.CHECK_VALUE.STATUS === true);

            const forwardingSituation = (processInfo.FORWARDING_SITUATION && processInfo.FORWARDING_SITUATION.SITUATION);
            if (!forwardingSituation) return this.handleWarning(`${this.formService.getTranslate("FINANCIAL.ERROR_FILE_STATUS")} ${this.$scope.model['PROCESS']['NAME']}!`);

            if (forwardingSituation.ID == '5') return this.handleWarning(this.formService.getTranslate("FINANCIAL.CLOSED_FILE"));

            if (forwardingSituation.ID == '7') return this.handleWarning(this.formService.getTranslate("FINANCIAL.CANCELED_FILE"));

            if (forwardingSituation.ID == '8' && checkValue) return this.handleWarning(this.formService.getTranslate("FINANCIAL.VALUES_CHECK"));

            this.$scope.charge.process = this.$scope.model['PROCESS'];

            await this.initModel(true);
            await this.refreshProcess(false);

            // manually re-enable editable fields that edit might have locked (locked invoice, pre-invoice, etc)
            await this.$scope.disableElements(false, [
                'INCOME_people',
                'INCOME_amount',
                'INCOME_unitary',
                'INCOME_minimum',
                'INCOME_total',
                'INCOME_validityOf',
                'INCOME_validityUp',
            ], new RegExp('^INCOME', 'i'));

            await this.$scope.disableElements(false, [
                'OUTCOME_people',
                'OUTCOME_amount',
                'OUTCOME_unitary',
                'OUTCOME_minimum',
                'OUTCOME_total',
                'OUTCOME_validityOf',
                'OUTCOME_validityUp',
            ], new RegExp('^OUTCOME', 'i'));

            await this.$scope.$applyAsync();

            await this.clearFields(this.$scope.model);

        } catch (ex) {
            this.handleError(ex);
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.applications = this.allApplications;
            this.$scope.scopeBeforeSave = null;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequest> {

        if (this.requestPromiseTimeout) {
            this.$timeout.cancel(this.requestPromiseTimeout);
            this.requestPromiseTimeout = null;
        }
        if (!this.$scope.model.INCOME.CURRENCY) {
            this.$scope.model.INCOME.TOTAL_BRL = 0;
            this.$scope.model.INCOME.CONVERSION_SPREAD = 0;

        }
        if (!this.$scope.model.OUTCOME.CURRENCY) {
            this.$scope.model.OUTCOME.TOTAL_BRL = 0;
            this.$scope.model.OUTCOME.CONVERSION_SPREAD = 0;
        }
        const request = {
            route: '/charge/pair/register',
            data: {
                processNumber: this.$scope.charge.process.NAME,
                chargepair: this.$scope.model,
                chargePairStatusOnOpening: this.$scope.blocked,
            },
            operation: this.$scope.operation,
            timeout: 120000
        };
        return request;
    }

    /* 
    DISABLED DELETE
    async delete(): Promise<boolean> {
        try {
            this.confirmDelete();
        } catch (ex) {
            this.handleError(ex);
            return false;
        }
        return false;
    }

    async confirmDelete() {
        const request = {
            data: {
                PROCESS_NUMBER: this.$scope.charge.process.NAME,
                CHARGE_PAIR_NUMBER: this.$scope.model.CHARGE_PAIR_NUMBER
            },
            timeout: 15000
        };
        try {
            const confirmed = await this.ModalService.showModalConfirmation({}, { bodyText: 'Tem certeza que deseja excluir?' });
            if (confirmed) {
                this.block();
                let result = await this.RestService.newObject('/financial/charge/delete', request, request.timeout);
                if (!result || result.status != 200) return this.handleError('Failed to delete');
                this.updateGrid();
                this.$scope.showForm = false;
                this.initModel()
                this.$scope.error = null;
                this.$formService.notifySuccess('Taxa deletada com sucesso!');
                this.unblock();

                return true;
            }
            return false;

        } catch (ex) {
            this.handleError(ex);
        }
    } */

    private viewLog(charge) {
        this.block();

        const log: IViewLog = {
            operation: 'history',
            number: charge.CHARGE_NUMBER,
            list: [],
            show: true,
            searchQuery: '',
            originalList: [],
        }
        const processId = charge.PROCESS && charge.PROCESS.ID ? charge.PROCESS.ID : charge.PROCESS;
        this.requestHistory(log.number, processId).then(result => {

            log.list = result.data.data;
            log.originalList = angular.copy(log.list);

            this.$scope.log = log;
            //hack para renderizar e pegar o offset
            angular.element('#log-viewer').removeClass('ng-hide');

            //forcar a busca na view
            const position = angular.element('#log-viewer').offset().top + $('.app-content-body').scrollTop() - 105;

            //anima
            $('.app-content-body').animate({
                scrollTop: position
            }, 500);

            this.unblock();

        }).catch(ex => {
            this.handleError(ex);
        });
    };

    async saveSuccess(): Promise<void> {
        // navigation save
        if (this.$scope.operation === 'edit' && this.$scope.hasNextCharge() && this.$scope.navigationSave) {
            delete this.$scope['newRegister'];
            await this.updateGrid();
            this.$formService.notifySuccess(this.formService.getTranslate("REGISTRATION.SUCCESSFUL_REGISTER"));
        }
    }

    async preGridFilter(): Promise<void> {
        this.$scope.showForm = false;
    }

    async postGridFilter(): Promise<void> {
        await this.enableChargeNavigation();
    }

    // ========================= NAVIGATION =================================== //

    async enableChargeNavigation(): Promise<void> {
        await this.feedNavigationMap();

        // set navigation keymap // 

        const self: ChargeRegisterController = this;

        // intercept fab save to handle nav or regular save 
        $('#fabSave').on('click', () => { self.$scope.navigationSave = false });

        window.addEventListener("keydown", async function (event) {
            if (event.defaultPrevented) return;

            const isConfirmationModalOpen = (self.confirmationModalId) // alternative: $('.modal-dialog').length;

            switch (event.key) {
                case "Left": // IE/Edge specific value
                case "ArrowLeft":
                    if (self.$scope.showForm && !$(":input").is(":focus") && !isConfirmationModalOpen) {
                        event.preventDefault();
                        self.$scope.previousCharge();
                    }
                    break;
                case "Right": // IE/Edge specific value
                case "ArrowRight":
                    if (self.$scope.showForm && !$(":input").is(":focus") && !isConfirmationModalOpen) {
                        event.preventDefault();
                        self.$scope.nextCharge();
                    }
                    break;
                case "Enter":
                    if (!$(":input").is(":focus")) {
                        if (!isConfirmationModalOpen) {
                            if (self.$scope.showForm && !self.$scope['form'].$invalid) {
                                event.preventDefault();
                                await self.confirmChargeChanges(false, true);
                            }
                        } else {
                            self.ModalService.closeModal(self.confirmationModalId, true);
                            // alternative: modalScope.$emit(`modal.closing`, 'enter key press');
                        }
                    }
                    break;
                case "Esc": // IE/Edge specific value
                case "Escape":
                    if (!$("#gridBody :input").is(":focus")) { // no focus on grid
                        if (!isConfirmationModalOpen) {
                            $('input:focus').blur();
                            $('button:focus').blur();
                        }
                    }
                    break;
                default:
                    return;
            }
        }, true);
    }

    async feedNavigationMap(): Promise<void> {
        const gridData = await this.gridService.getGridData<Charge>();
        const preMappedData = gridData.map(x => { return { CHARGE_NUMBER: x.CHARGE_NUMBER, CHARGE_PAIR_REFERENCE: x.CHARGE_PAIR_REFERENCE, CHARGE: x.CHARGE.ID, PROCESS: x['PROCESS'] } });
        const uniqueMappedData = preMappedData.filter((x, index, self) => x && index === self.findIndex(t => t.CHARGE_PAIR_REFERENCE === x.CHARGE_PAIR_REFERENCE));
        this.$scope.navigationMap = uniqueMappedData;
        this.$scope.currentNavigationIndex = 0;
    }

    private handleChargeNavigation = async () => {
        try {
            const nextItem = this.$scope.navigationMap[this.$scope.currentNavigationIndex];
            if (this.$scope.operation === EOperation.VIEW) await this.view(nextItem);
            else
                // flags to prevent reloading dependencies every time + prevent grid $scope update to avoid "up/down" re-centering effect
                await this.$scope.edit(nextItem, false, false);

            const gridRows: uiGrid.IGridRowOf<Charge>[] = this.gridService.$gridApi.grid['rows']
            const rowIndex = gridRows.findIndex(x => x.entity.CHARGE_NUMBER === nextItem.CHARGE_NUMBER);
            this.$gridService.$gridApi.selection.clearSelectedRows();
            gridRows[rowIndex].setSelected(true);

        } catch (ex) {
            this.handleError(ex);
        }
    }

    async confirmChargeChanges(skipConfirm: boolean = false, goToNextCharge: boolean = false): Promise<boolean> {
        let confirm = true;
        const hasChanges = this.hasChanges(this.$scope.model, this.$scope.scopeBeforeSave, ['_id']);
        if (hasChanges) {
            const actionText = this.formService.getTranslate("REGISTRATION.APPLY_AND_CLOSE");
            const confirmationText = this.formService.getTranslate("REGISTRATION.UPDATE_NOT_SAVED");
            confirm = (skipConfirm) ? skipConfirm : await this.chargeSaveConfirmation(confirmationText, actionText, this.formService.getTranslate("GENERAL.CLOSE"));
            if (confirm) {
                this.$scope.navigationSave = true;
                await this.$scope.save();
            }
            if (goToNextCharge) this.$scope.nextCharge(true);
        }
        return confirm;
    }

    private async chargeSaveConfirmation(confirmationText: string, actionText: string, closeButtonText: string): Promise<boolean> {
        this.confirmationModalId = this.ModalService.newModal();
        return await this.ModalService.showModalConfirmation({
            modalID: this.confirmationModalId,
            keyboard: true,
            events: async (event: angular.IAngularEvent, _reason: Object, _closed: boolean) => {
                if (event.name == "modal.closing") {
                    this.confirmationModalId = 0;
                }
            }
        }, {
            headerText: "REGISTRATION.WARNING",
            bodyText: confirmationText,
            actionButtonText: actionText,
            closeButtonText: closeButtonText,
        });
    }

    private hasChanges(_newObj: Object, _oldObj: Object, _ignore?: string[]): boolean {
        const newObj = angular.copy(_newObj);
        const oldObj = angular.copy(_oldObj);
        const ignore = (_ignore) ? _ignore : [];
        let hasChanges = false;
        if (oldObj) {
            for (let property in newObj) {
                if (ignore.includes(property)) continue;
                if (newObj.hasOwnProperty(property)) {
                    if (JSON.stringify(newObj[property]) != JSON.stringify(oldObj[property])) {
                        hasChanges = true;
                        break;
                    }
                }
            }
        }
        return hasChanges;
    }

    private getValidInvoiceSelectableList(invoiceList: IInvoiceNumber[]): IInvoiceNumber[] {
        return invoiceList && invoiceList.filter(x => x.STATUS.ID == '1');
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: 'CHARGE', LABEL: 'BASIC_DATA.CHARGE' },
            { PROPERTY: 'CHARGE_NUMBER', LABEL: 'FINANCIAL.CHARGE_IDENTIFICATION' },
            { PROPERTY: 'CHARGE_TYPE', LABEL: 'GENERAL.TYPE' },
            { PROPERTY: 'NATURE', LABEL: 'BASIC_DATA.PAYMENT_NATURE' },
            { PROPERTY: 'AMOUNT', LABEL: 'GENERAL.QUANTITY' },
            { PROPERTY: 'APPLICATION', LABEL: 'FINANCIAL.CHARGE_BASIS' },
            { PROPERTY: 'CURRENCY', LABEL: 'GENERAL.CURRENCY' },
            { PROPERTY: 'MINIMUM', LABEL: 'BASIC_DATA.MINIMUM' },
            { PROPERTY: 'UNITARY', LABEL: 'BASIC_DATA.UNIT' },
            { PROPERTY: 'TOTAL', LABEL: 'FINANCIAL.ACTUAL_TOTAL_AMOUNT' },
            { PROPERTY: 'TOTAL_BRL', LABEL: 'FINANCIAL.TOTAL_AMOUNT_BRL' },
            { PROPERTY: 'CONVERSION_SPREAD', LABEL: 'GENERAL.EXCHANGE_RATE_INDEX + FINANCIAL.SPREAD' },
            { PROPERTY: 'TRANSACTION', LABEL: 'GENERAL.HOLDER_TYPE' },
            { PROPERTY: 'PEOPLE', LABEL: 'GENERAL.HOLDER' },
            { PROPERTY: 'VALIDITY_OF', LABEL: 'PRODUCT.VALIDITY_START' },
            { PROPERTY: 'VALIDITY_UP', LABEL: 'PRODUCT.VALIDITY_END' },
            { PROPERTY: 'SHOW_FOR_GROUP', LABEL: 'FINANCIAL.SHOWN_FOR' },
            { PROPERTY: 'EQUIPMENT', LABEL: 'GENERAL.EQUIPMENT' },
            { PROPERTY: 'INVOICE_NUMBER', LABEL: 'GENERAL.INVOICES' },
            { PROPERTY: 'COMPOSITION_GROUP', LABEL: 'GENERAL.COMPOSITION' },
            { PROPERTY: 'OBSERVATION', LABEL: 'GENERAL.REMARKS' },
            { PROPERTY: 'HC2COD_CHARGE', LABEL: 'GENERAL.IDENTIFIER' },
            { PROPERTY: 'CHARGE_ORIGIN', LABEL: 'BASIC_DATA.ORIGIN' },
            { PROPERTY: 'MODALITY', LABEL: 'FINANCIAL.CHARGE_PAYMENT_METHOD' },
            { PROPERTY: 'PROCESS.DOCUMENT_HBL', LABEL: 'OPERATIONAL.HOUSE_BL' },
            { PROPERTY: 'PROCESS.DOCUMENT_MBL', LABEL: 'OPERATIONAL.MASTER_BL' }
        ];
        return props;
    }

    private getDefaultValidityBasis(): void {
        if (this.$scope.eventList && this.$scope.eventList.length) {
            const eventLoad = this.$scope.eventList.find(event => event.ID == EEventType.LOAD);
            this.$scope.model.INCOME.VALIDITY_EVENT = eventLoad;
            this.$scope.model.OUTCOME.VALIDITY_EVENT = eventLoad;
        }
    }

    private hasChargeChange(): void {
        if (this.$scope.operation == EOperation.EDIT) {
            if (this.$scope.model.OUTCOME.CHARGE_TYPE.ID != EDataOriginTypeId.MANUAL) {
                const hasChangesOutcome = this.hasChanges(this.$scope.model.OUTCOME, this.$scope.scopeBeforeSave.OUTCOME, ['OBSERVATION', 'SHOW_FOR_GROUP', 'INVOICE_NUMBER', 'NEGOTIATED']);
                this.$scope.disabledOutcomeNegotiated = !hasChangesOutcome;
                if (!hasChangesOutcome) {
                    this.$scope.model.OUTCOME.NEGOTIATED = this.$scope.scopeBeforeSave.OUTCOME.NEGOTIATED;
                } else {
                    this.$scope.model.OUTCOME.NEGOTIATED = true;
                }
            }

            if (this.$scope.model.INCOME.CHARGE_TYPE.ID != EDataOriginTypeId.MANUAL) {
                const hasChangesIncome = this.hasChanges(this.$scope.model.INCOME, this.$scope.scopeBeforeSave.INCOME, ['OBSERVATION', 'SHOW_FOR_GROUP', 'INVOICE_NUMBER', 'NEGOTIATED']);
                this.$scope.disabledIncomeNegotiated = !hasChangesIncome;
                if (!hasChangesIncome) {
                    this.$scope.model.INCOME.NEGOTIATED = this.$scope.scopeBeforeSave.INCOME.NEGOTIATED;
                } else {
                    this.$scope.model.INCOME.NEGOTIATED = true;
                }
            }
        }
    }

    private async getOutDateChargesByCharge(chargePairNumber: string): Promise<ChargePair[]> {
        let result = [];
        this.formService.block();
        try {
            const request = await this.operationalService.get(`/charge/chargeOutDate/getByReference/${chargePairNumber}`, 10000);
            if (request && request.data && request.data.data) result = request.data.data.sort((a, b) => {
                const dateA = new Date(a.OUTCOME.VALIDITY_OF).getTime();
                const dateB = new Date(b.OUTCOME.VALIDITY_OF).getTime();
                return dateA - dateB;
            });

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }
}

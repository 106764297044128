import * as angular from "angular";
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { OperationalService } from "@services/OperationalService";
import { IModalOptions } from '@services/ModalService';
import { IAirFlight, IAirFlightSpecification } from 'WBA-Model/dist/interface/operational/AirFlight';
import { SSEService } from "@appServices/SSEService";
import { ISessionService } from "@services/SessionService";
import moment = require('moment');
import { EOperation } from "@enums/GenericData";

interface ISpecificAirFlightEditScope extends IFormServiceScope {
    model: IAirFlightSpecification;
    oldData: IAirFlightSpecification;
    sessionService: ISessionService;
    user: string;
    modalOptions: IModalOptions;
    baseFlight: IAirFlight;
    dayOfWeek: string;
    totalWeight: string;
    totalProcess: string;
    specificFlightCode?: string;
    save(): void;
    closeSpecificModal(): void;
}

export class SpecificAirFlightEditController extends FormService2 {

    static $inject: string[] = ['$injector', '$scope'];
    private scope: ISpecificAirFlightEditScope;
    private OperationalService: OperationalService;
    private SSEService: SSEService;

    constructor($injector: ng.Injectable<any>, $scope: ISpecificAirFlightEditScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.OperationalService = $injector.get('OperationalService');
        this.scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope, this);
    }

    async $onInit(): Promise<void> {
        try {
            this.block();
            this.init("specificAirFlightEditModalForm", null, null);
            this.initScopeFunctions();
            this.initModel();
            if (this.scope.specificFlightCode) await this.getSpecificAirFlightInformation();
            if (this.scope.operation == EOperation.EDIT) this.checkSSE();
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private checkSSE(): void {

        if (!this.scope.user) throw new Error('Can not open specificFlightEdit modal without user.');

        let blockedObject = {
            ID: this.scope.baseFlight.ID,
            NAME: this.scope.model.SPECIFIC_FLIGHT_CODE,
            EMAIL: this.scope.user,
            FORM_NAME: "MODAL_SPECIFIC_AIR_FLIGHT"
        };
        this.SSEService.closeEvents();
        this.SSEService.setBlockedObject(blockedObject);
        this.SSEService.initEvents();
        this.SSEService.events.onmessage = async (event) => {
            const parsedData = JSON.parse(event.data);
            if (!parsedData.status && this.scope.operation == EOperation.EDIT) {
                const result = await this.SSEService.generate(parsedData);
                if (result && !result.status) {
                    this.scope.modalOptions.ok(false);
                }
                this.scope.operation = EOperation.VIEW;
                this.scope.$applyAsync();
            }
        };
    }

    private initScopeFunctions(): void {
        this.scope.save = () => {
            this.save();
        }

        this.scope.closeSpecificModal = () => {
            this.SSEService.closeEvents();
            this.scope.modalOptions.ok(false);
        }
    }

    private initModel(): void {
        this.scope.model = {
            ACTIVE: null,
            SPECIFIC_FLIGHT_CODE: null,
            ESTIMATED_TIME_DEPARTURE: null,
            ESTIMATED_TIME_ARRIVAL: null,
            ACTUAL_TIME_DEPARTURE: null,
            ACTUAL_TIME_ARRIVAL: null,
            DEADLINE_CARGO_DOCS_HOUSE: null,
            DEADLINE_CARGO_DOCS_MASTER: null,
            FINAL_TIME_ARRIVAL: {
                DATE: null,
                IS_ESTIMATED: null
            },
            FINAL_TIME_DEPARTURE: {
                DATE: null,
                IS_ESTIMATED: null
            }
        }

        this.scope.baseFlight = {
            _id: null,
            ID: null,
            FLIGHT_CODE: null,
            PRODUCT: null,
            FLIGHT_CONCATENATED: null,
            PROVIDER: null,
            PREFIX: null,
            FLIGHT: null,
            ORIGIN: null,
            DESTINATION: null,
            FREQUENCY: null,
            DAY: null,
            ETD_HOUR: null,
            ETA_HOUR: null,
            FLIGHT_STATUS: null,
            IS_SPECIFIC: null,
            SPECIFIC: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null
        }

        this.scope.totalProcess = '0';
        this.scope.totalWeight = '0';
    }

    private async getSpecificAirFlightInformation(): Promise<IAirFlight> {
        let result: IAirFlight = null;
        const timeout: number = 120000;
        try {
            this.block();
            const specificAirFlight = await this.OperationalService.post('/airFlight/getSpecific', { specificFlightCode: this.scope.specificFlightCode, timeout }, timeout);
            if (specificAirFlight && specificAirFlight.data && specificAirFlight.data.data && specificAirFlight.data.data) {
                const foundSpecificAirFlight = specificAirFlight.data.data;
                this.scope.model = {
                    ACTIVE: foundSpecificAirFlight.SPECIFIC.ACTIVE,
                    SPECIFIC_FLIGHT_CODE: foundSpecificAirFlight.SPECIFIC.SPECIFIC_FLIGHT_CODE,
                    ESTIMATED_TIME_DEPARTURE: foundSpecificAirFlight.SPECIFIC.ESTIMATED_TIME_DEPARTURE ? new Date(foundSpecificAirFlight.SPECIFIC.ESTIMATED_TIME_DEPARTURE) : null,
                    ESTIMATED_TIME_ARRIVAL: foundSpecificAirFlight.SPECIFIC.ESTIMATED_TIME_ARRIVAL ? new Date(foundSpecificAirFlight.SPECIFIC.ESTIMATED_TIME_ARRIVAL) : null,
                    ACTUAL_TIME_DEPARTURE: foundSpecificAirFlight.SPECIFIC.ACTUAL_TIME_DEPARTURE ? new Date(foundSpecificAirFlight.SPECIFIC.ACTUAL_TIME_DEPARTURE) : null,
                    ACTUAL_TIME_ARRIVAL: foundSpecificAirFlight.SPECIFIC.ACTUAL_TIME_ARRIVAL ? new Date(foundSpecificAirFlight.SPECIFIC.ACTUAL_TIME_ARRIVAL) : null,
                    DEADLINE_CARGO_DOCS_HOUSE: foundSpecificAirFlight.SPECIFIC.DEADLINE_CARGO_DOCS_HOUSE ? new Date(foundSpecificAirFlight.SPECIFIC.DEADLINE_CARGO_DOCS_HOUSE) : null,
                    DEADLINE_CARGO_DOCS_MASTER: foundSpecificAirFlight.SPECIFIC.DEADLINE_CARGO_DOCS_MASTER ? new Date(foundSpecificAirFlight.SPECIFIC.DEADLINE_CARGO_DOCS_MASTER) : null,
                    FINAL_TIME_ARRIVAL: {
                        DATE: foundSpecificAirFlight.ACTUAL_TIME_ARRIVAL ? new Date(foundSpecificAirFlight.ACTUAL_TIME_ARRIVAL) : new Date(foundSpecificAirFlight.ESTIMATED_TIME_ARRIVAL),
                        IS_ESTIMATED: foundSpecificAirFlight.ACTUAL_TIME_ARRIVAL == null ? true : null
                    },
                    FINAL_TIME_DEPARTURE: {
                        DATE: foundSpecificAirFlight.ACTUAL_TIME_DEPARTURE ? new Date(foundSpecificAirFlight.ACTUAL_TIME_DEPARTURE) : new Date(foundSpecificAirFlight.ESTIMATED_TIME_DEPARTURE),
                        IS_ESTIMATED: foundSpecificAirFlight.ACTUAL_TIME_DEPARTURE == null ? true : null
                    }
                }

                this.scope.baseFlight.ID = foundSpecificAirFlight.ID;
                this.scope.baseFlight.PRODUCT = foundSpecificAirFlight.PRODUCT;
                this.scope.baseFlight.FLIGHT_CODE = foundSpecificAirFlight.FLIGHT_CODE;
                this.scope.baseFlight.FLIGHT_STATUS = foundSpecificAirFlight.FLIGHT_STATUS;
                this.scope.baseFlight.ORIGIN = foundSpecificAirFlight.ORIGIN;
                this.scope.baseFlight.DESTINATION = foundSpecificAirFlight.DESTINATION;
                this.scope.baseFlight.FLIGHT_CONCATENATED = foundSpecificAirFlight.FLIGHT_CONCATENATED;
                this.scope.dayOfWeek = moment.weekdaysShort(moment(this.scope.model.ESTIMATED_TIME_DEPARTURE).isoWeekday());

                //this.scope.oldModel = angular.copy(this.scope.model);
            }

            const searchTotals = await this.OperationalService.post('/airFlight/getFlightWeight', { flightId: this.scope.baseFlight.ID, timeout }, timeout);
            if (searchTotals && searchTotals.data && searchTotals.data.data && searchTotals.data.data) {
                const foundTotals = searchTotals.data.data;
                this.scope.totalWeight = foundTotals && foundTotals.TOTAL_WEIGHT_FLIGHT ? foundTotals.TOTAL_WEIGHT_FLIGHT : '0';
                this.scope.totalProcess = foundTotals && foundTotals.TOTAL_PROCESSOS ? foundTotals.TOTAL_PROCESSOS : '0';
            }

            this.scope.oldData = angular.copy(this.scope.model);
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async save(): Promise<void> {
        try {
            this.block();
            const specificAirFlight = await this.OperationalService.post('/airFlight/updateSpecific', { data: this.scope.model, oldData: this.scope.oldData });
            if (specificAirFlight && specificAirFlight.data && specificAirFlight.data.data && specificAirFlight.data.data && specificAirFlight.status == 200) {
                this.SSEService.closeEvents();
                this.scope.modalOptions.ok(specificAirFlight.data.data);
            }

        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }
}
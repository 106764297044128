import * as angular from "angular";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { ISessionService } from "@services/SessionService";
import { EOperation, ETariffType } from "@enums/GenericData";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { SelectorModel } from "../../common/model/SelectorModel";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { HelperService } from "@services/HelperService";
import { IAutoRatingControlModel } from "@models/interface/product/AutoRatingControlModel";

interface IAutoRatingControlScope extends IGridFormServiceScope {
    model: IAutoRatingControlModel;
    log: IViewLog;
    productList: SelectorModel[];
    cargoTypeList: SelectorModel[];
    accountList: SelectorModel[];
    processTypeList: SelectorModel[];
    accountTypeList: SelectorModel[];
    contractModalityList: SelectorModel[];
    tariffList: SelectorModel[];
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IAutoRatingControlModel;
    sessionService: ISessionService;
    editAutoRatingControl: (autoRating: IAutoRatingControlModel) => Promise<void>;
    viewAutoRatingControl: (autoRating: IAutoRatingControlModel) => Promise<void>;
    viewLogAutoRatingControl: (autoRating: IAutoRatingControlModel) => Promise<void>;
    copyAutoRatingControl: (autoRating: IAutoRatingControlModel) => Promise<void>;
    getAccountListByName: (search: string) => Promise<void>;
    fetchData: (id: number, action: string) => Promise<void>;
}

export class AutoRatingControlRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ["$injector", "$scope"];
    private $scope: IAutoRatingControlScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private gridName: string;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IAutoRatingControlScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get("$q");
        this.RestService = $injector.get("RestService");
        this.$scope.sessionService = $injector.get("SessionService");
        this.gridName = "GRID_AUTO_RATING_CONTROL";
        this.helperService = $injector.get("HelperService");
    }

    getUrlProduct(): string {
        const baseRoute = "/product";
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, "form", "autoRatingControl", "GENERAL.MENU.AUTO_RATING_CONTROL", true);
            await this.initGrid(this.gridName, "/autoRatingControl/list", true, true, null, true, true);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        try {
            this.$scope.editAutoRatingControl = async (autoRatingControl: IAutoRatingControlModel): Promise<void> => {
                this.$scope.fetchData(autoRatingControl.ID, EOperation.EDIT);
            }

            this.$scope.viewAutoRatingControl = async (autoRatingControl: IAutoRatingControlModel): Promise<void> => {
                this.$scope.fetchData(autoRatingControl.ID, EOperation.VIEW);
            }

            this.$scope.viewLogAutoRatingControl = async (autoRatingControl: IAutoRatingControlModel): Promise<void> => {
                this.$scope.viewLog(autoRatingControl);
            }

            this.$scope.copyAutoRatingControl = async (autoRatingControl: IAutoRatingControlModel): Promise<void> => {
                this.$scope.fetchData(autoRatingControl.ID, EOperation.COPY);
            }

            this.$scope.getAccountListByName = async (search: string): Promise<void> => {
                await this.getAccountListByName(search);
            }

            this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
                this.fetchData(id, action);
            }
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewAutoRatingControl(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editAutoRatingControl(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogAutoRatingControl(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.setCopy(true);grid.appScope.copyAutoRatingControl(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };

        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) {
            column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) 
        }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PRODUCT, null, "ID")}}</div>' };
            const colTypeCargo: IMonacoColumnDef = { name: "TYPE_CARGO.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TYPE_CARGO, null, "NAME")}}</div>' };
            const colTariffNature: IMonacoColumnDef = { name: "TARIFF.NAME", displayName: "PRODUCT.TARIFF", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TARIFF, null, "NAME")}}</div>' };
            const colAccount: IMonacoColumnDef = { name: "ACCOUNTS.NAME", displayName: "BASIC_DATA.CLIENT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNTS, null, "NAME")}}</div>' };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 150, cellFilter: "YesOrNo" };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case "PRODUCT":
                        columnDefs.push(colProduct);
                        break;
                    case "TYPE_CARGO":
                        columnDefs.push(colTypeCargo);
                        break;
                    case "TARIFF":
                        columnDefs.push(colTariffNature);
                        break;
                    case "ACCOUNTS":
                        columnDefs.push(colAccount);
                        break;
                    case "ID":
                        columnDefs.push(colId);
                        break;
                    case "ACTIVE":
                        columnDefs.push(colActive);
                        break;
                    case "CREATED_AT":
                        columnDefs.push(colCreatedAt);
                        break;
                    case "UPDATED_AT":
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }

            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        try {
            this.$scope.model = {
                _id: null,
                ID: null,
                PRODUCT: null,
                TYPE_CARGO: null,
                ACCOUNTS: null,
                ACTIVE: true,
                CREATED_AT: null,
                CREATED_BY: null,
                UPDATED_AT: null,
                UPDATED_BY: null
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getCargoTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_cargo`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getProcessTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/process_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getAccountTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/account_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTariffList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/offer_formated_date_type`, null);
        const enalbedTariffs = [ETariffType.Local, ETariffType.Inland, ETariffType.Route];
        return generic && generic.data ? generic.data.filter(generic => enalbedTariffs.includes(generic.ID)) : [];
    }

    private async getAccountListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            const scopeAccounts = this.$scope.model.ACCOUNTS;
            if (search && search.length >= 3) {
                this.formService.block();
                const accounts = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/account/list/custom/`, { search: search }, 30000, false);
                if (accounts && accounts.length > 0) {
                    result = accounts.map(account => { return { ID: account.ID, NAME: account.NAME, CODE: account.LEGAL_PERSON.SHORT_NAME } });
                }
                if (scopeAccounts) {
                    result = result.filter(item => scopeAccounts.some(scopeAccount => scopeAccount.ID != item.ID))
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.accountList = result
            this.formService.unblock();
        }
    }

    initDependencies(): Promise<any> {
        try {
            const self: AutoRatingControlRegisterController = this;

            return new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getProductList(),
                    self.getCargoTypeList(),
                    self.getProcessTypeList(),
                    self.getAccountTypeList(),
                    self.getTariffList(),
                ]).then((result: any) => {
                    self.$scope.productList = result[0];
                    self.$scope.cargoTypeList = result[1];
                    self.$scope.processTypeList = result[2];
                    self.$scope.accountTypeList = result[3];
                    self.$scope.tariffList = result[4];
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")}`;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")}`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === "register" ? "insert" : "update";
        return {
            route: `/autoRatingControl/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: "PRODUCT",
                LABEL: "BASIC_DATA.PRODUCT"
            },
            {
                PROPERTY: "TYPE_CARGO",
                LABEL: "BASIC_DATA.CARGO_TYPE"
            },
            {
                PROPERTY: "ACCOUNTS",
                LABEL: "BASIC_DATA.CLIENT"
            },
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE"
            },
            {
                PROPERTY: "CREATED_AT",
                LABEL: "GENERAL.CREATED_AT"
            },
            {
                PROPERTY: "CREATED_BY",
                LABEL: "GENERAL.CREATED_BY"
            },
            {
                PROPERTY: "UPDATED_AT",
                LABEL: "GENERAL.UPDATED_AT"
            },
            {
                PROPERTY: "UPDATED_BY",
                LABEL: "GENERAL.UPDATED_BY"
            },
            {
                PROPERTY: "TARIFF",
                LABEL: "PRODUCT.TARIFF"
            }
        ];
        return props;
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error("Missing id parameter in fetchData");
            if (!action || action === "") throw Error("Missing action parameter in fetchData");

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/autoRatingControl/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error("No data found.");
        } catch (ex) {
            this.handleError(ex);
        }
    }
}
import * as angular from 'angular';

uiSelectRequired.$inject = [];
export function uiSelectRequired() {
    return {
        require: 'ngModel', link: function (scope, elm, attrs, ctrl) {
            ctrl.$validators.uiSelectRequired = function (modelValue, viewValue) {

                if (modelValue) {
                    const isArray = angular.isArray(modelValue) || angular.isArray(viewValue);
                    if (isArray) return Boolean(Array.from(modelValue).length) || Boolean(Array.from(viewValue).length);

                    const isObject = angular.isObject(modelValue) || angular.isObject(viewValue);
                    if (isObject) return true;

                    const isNumber = angular.isNumber(modelValue) || angular.isNumber(viewValue);
                    if (isNumber) return true;
                }

                return false;

            };
        }
    };
}
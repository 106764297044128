import { HandleError } from "../../common/util/HandleError";
import { IGenericData } from "../../common/interface/IGenericResponse";
import * as uiGrid from "ui-grid";
import { ProcessDocument } from "../../common/model/ProcessDocument";
import { OperationalService } from '@services/OperationalService';

uiSelectExternalContacts.$inject = ['$document', 'uiGridEditConstants', 'uiGridConstants', 'blockUI', 'OperationalService'];
export function uiSelectExternalContacts($document: JQuery, uiGridEditConstants: uiGrid.edit.IUiGridEditConstants, uiGridConstants: uiGrid.IUiGridConstants, blockUI, OperationalService: OperationalService) {
    return {
        require: '^uiGrid',
        link: ($scope, $elm, $attr, uiGridCtrl) => {

            $scope.search = search;

            $document.on('click', endCellEdit);
            $document.on('keyup', keyUp);

            const grid: uiGrid.IGridApi = uiGridCtrl.grid.api;
            grid.edit.on.beginCellEdit($scope, beginCellEdit);

            //AUXS
            function beginCellEdit(rowEntity: any, colDef: uiGrid.IColumnDefOf<any>, triggerEvent: JQueryEventObject): void {
                const totalOpened = $('ui-select-external-contacts').length;

                if (totalOpened > 1) cancelCellEdit(triggerEvent);
            }

            function cancelCellEdit(evt: JQueryEventObject) {
                $scope.$emit(uiGridEditConstants.events.CANCEL_CELL_EDIT);
            }

            function endCellEdit(evt: JQueryEventObject) {
                const hasChanged = $(evt.target).closest('.ui-select-container').length === 0;
                if (hasChanged) $scope.$emit(uiGridEditConstants.events.END_CELL_EDIT);
            }

            function keyUp(evt: JQueryEventObject): void {
                if (evt.keyCode == uiGridConstants.keymap.ESC) cancelCellEdit(evt);
            }


            async function search(query: string, processDocument: ProcessDocument): Promise<void> {

                try {

                    if (query.length > 2) {
                        blockUI.start();

                        let response: IGenericData<any> = await OperationalService.get(`/process/number/${processDocument.PROCESS_NUMBER}/externalContacts/${query}`, null, 10000);
                        $scope.list = response && response.data && response.data.data;

                        $scope.$applyAsync(() => { blockUI.stop(); });
                    }
                } catch (ex) {
                    $scope.$applyAsync(() => { blockUI.stop(); });
                    HandleError.exception(ex);
                }

            };

        }
    };
}
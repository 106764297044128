import * as angular from "angular";
import * as intlTelInput from 'intl-tel-input';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IContactModel } from "@models/interface/product/ContactModel";
import { SelectorModel } from "../../common/model/SelectorModel";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { ILegalPersonExchangeData, ISpecsWithRegistration, ILegalPersonListCustomFilter } from "../model/LegalPersonModel";
import { ILinkParameter } from "../../common/model/ModelParameter";
import { ISessionService } from "@services/SessionService";
import { BrowserTitle } from "../../common/BrowserTitle";
import { ValidateUtil } from "../../common/util/ValidateUtil";
import { SSEService } from "@appServices/SSEService";
import { EOperation } from "@enums/GenericData";
import { HelperService } from "@services/HelperService";

const enum ETypeEntityId {
    LEGAL_PERSON = "1",
    NETWORK = "2"
}

interface IOuterCellSelectorModel extends SelectorModel {
    ENTITY: SelectorModel;
}

interface IContactScope extends IGridFormServiceScope {
    model: IContactModel;
    log: IViewLog;
    typeList: SelectorModel[];
    legalPersonList: SelectorModel[];
    networkList: SelectorModel[];
    sectorList: SelectorModel[];
    personRoleList: SelectorModel[];
    productList: SelectorModel[];
    accountList: SelectorModel[];
    messageGroupList: SelectorModel[];
    notificationTypeList: SelectorModel[];
    countryList: SelectorModel[];
    exporterList: SelectorModel[];
    importerList: SelectorModel[];
    routingPointList: SelectorModel[];
    localGroupList: SelectorModel[];
    commodityList: SelectorModel[];
    outerCellList: IOuterCellSelectorModel[];
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IContactModel;
    tradeGroupList: SelectorModel[];
    tradeLaneList: SelectorModel[];
    user: any;
    sessionService: ISessionService;
    editContact: (contact: IContactModel) => Promise<void>;
    viewContact: (contact: IContactModel) => Promise<void>;
    viewLogContact: (contact: IContactModel) => Promise<void>;
    copyContact: (contact: IContactModel) => Promise<void>;
    getLegalPersonListByName: (search: string) => Promise<void>;
    getAccountListByName: (search: string) => Promise<void>;
    getNetworkListByName: (name: string) => Promise<void>;
    getSectorListByName: (name: string) => Promise<void>;
    getPersonRoleListByName: (name: string) => Promise<void>;
    getCountryListByNameOrInitials: (search: string) => Promise<void>;
    getRoutingPointListByName: (name: string, types?: string[]) => Promise<void>;
    getImporterListByName: (search: string) => Promise<void>;
    getExporterListByName: (search: string) => Promise<void>;
    getCommodityListByName: (name: string) => Promise<void>;
    getOuterCellListByName: (name: string) => Promise<void>;
    goToCountry: (ids: string) => void;
    goToSector: (id: number) => void;
    goToPersonRole: (id: number) => void;
    goToRoutingPoint: (ids: string) => void;
    goToCommodity: (commodities: SelectorModel[]) => void;
    goToEntity: (entity: SelectorModel) => void;
    goToExporter: (exporters: SelectorModel[]) => void;
    goToImporter: (importers: SelectorModel[]) => void;
    goToAccount: (accounts: SelectorModel[]) => void;
    goToOuterCell: () => void;
    goToTradeLane: (ids: string) => void;
    typeSelect: (type: SelectorModel) => void;
    formatEmail: (email: string) => string;
    isMaritimeOriginFieldDisabled: (field: SelectorModel[]) => boolean;
    isMaritimeDestinationFieldDisabled: (field: SelectorModel[]) => boolean;
    isAirOriginFieldDisabled: (field: SelectorModel[]) => boolean;
    isAirDestinationFieldDisabled: (field: SelectorModel[]) => boolean;
    isRoadOriginFieldDisabled: (field: SelectorModel[]) => boolean;
    isRoadDestinationFieldDisabled: (field: SelectorModel[]) => boolean;
    refreshTradeLaneListByName: (search: string) => Promise<void>;
    refreshCountryListByName: (search: string) => Promise<void>;
    checkProductMaritimeModel: () => boolean;
    checkProductAirModel: () => boolean;
    checkProductRoadModel: () => boolean;
    clearFieldsChangeProduct: () => void;
    validityPhone: (inputName: string) => void;
    getPhoneValidityMessage: (inputName: string) => string;
}

export class ContactRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IContactScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private intlPhone: intlTelInput.Plugin;
    private countryChangeListener: EventListenerOrEventListenerObject;
    private SSEService: SSEService;
    private gridName: string;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IContactScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.gridName = 'GRID_CONTACT';
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'contact', 'GENERAL.MENU.CONTACT', true);
            await this.initGrid(this.gridName, '/contact/list', true, true, null, true, true);

            const sessionParameter = this.$gridService.$sessionParameter;
            if (sessionParameter && sessionParameter.data) this.callSessionFunctions(sessionParameter.data);

        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
        this.destroyPhoneComponent();
    }

    initScopeFunctions(): void {
        this.$scope.editContact = async (contact: IContactModel): Promise<void> => {
            let blockedObject = {
                ID: contact.ID,
                NAME: contact.ID.toString(),
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || contact.ID !== this.$scope.model.ID) this.fetchData(contact.ID, EOperation.VIEW);
                } else if (this.$scope.operation !== EOperation.EDIT || contact.ID !== this.$scope.model.ID) {
                    this.fetchData(contact.ID, EOperation.EDIT);
                }
            };
        }

        this.$scope.viewContact = async (contact: IContactModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.fetchData(contact.ID, EOperation.VIEW);
        }

        this.$scope.viewLogContact = async (contact: IContactModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(contact);
        }

        this.$scope.copyContact = async (contact: IContactModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.fetchData(contact.ID, EOperation.COPY);
        }

        this.$scope.getNetworkListByName = async (name: string) => {
            let networkList = [];
            if (name && name.length >= 3) {
                networkList = await this.getNetworkListByName(name);
            }
            this.$scope.networkList = networkList;
        }

        this.$scope.getLegalPersonListByName = async (search: string) => {
            let legalPersonList = [];
            if (search && search.length >= 3) {
                legalPersonList = await this.getLegalPersonListByName({ specializations: [], search: search });
            }
            this.$scope.legalPersonList = legalPersonList;
        }

        this.$scope.getSectorListByName = async (name: string) => {
            let sectorList = [];
            if (name && name.length >= 2) {
                sectorList = await this.getSectorListByName(name);
            }
            this.$scope.sectorList = sectorList;
        }

        this.$scope.getPersonRoleListByName = async (name: string) => {
            let personRoleList = [];
            if (name && name.length >= 2) {
                personRoleList = await this.getPersonRoleListByName(name);
            }
            this.$scope.personRoleList = personRoleList;
        }

        this.$scope.getCountryListByNameOrInitials = async (search: string) => {
            let countryList = [];
            if (search && search.length >= 2) {
                countryList = await this.getCountryListByNameOrInitials(search);
            }
            this.$scope.countryList = countryList;
        }

        this.$scope.getRoutingPointListByName = async (name: string, types?: string[]) => {
            let routingPointList = [];
            if (name && name.length >= 3) {
                routingPointList = await this.getRoutingPointListByName(name, types);
            }
            this.$scope.routingPointList = routingPointList;
        }

        this.$scope.getImporterListByName = async (search: string) => {
            let importerList = [];
            if (search && search.length >= 3) {
                importerList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.IMPORTER], search: search });
            }
            this.$scope.importerList = importerList;
        }

        this.$scope.getExporterListByName = async (search: string) => {
            let exporterList = [];
            if (search && search.length >= 3) {
                exporterList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.EXPORTER], search: search });
            }
            this.$scope.exporterList = exporterList;
        }

        this.$scope.getCommodityListByName = async (name: string) => {
            let commodityList = [];
            if (name && name.length >= 2) {
                commodityList = await this.getCommodityListByName(name);
            }
            this.$scope.commodityList = commodityList;
        }

        this.$scope.getOuterCellListByName = async (name: string) => {
            let outerCellList: IOuterCellSelectorModel[] = [];
            if (name && name.length >= 3) {
                if (this.$scope.model.TYPE) outerCellList = await this.getOuterCellListByName(name, [this.$scope.model.TYPE.ID == ETypeEntityId.LEGAL_PERSON ? parseInt(this.$scope.model.LEGAL_PERSON.ID) : parseInt(this.$scope.model.NETWORK.ID)]);
                else this.$formService.notifyError("Select entity(legal person/network) first to select outer cells!");
            }
            this.$scope.outerCellList = this.$scope.model.OUTER_CELLS ? outerCellList.filter(outerCell => this.$scope.model.OUTER_CELLS.some(obj => obj.ID != outerCell.ID)) : outerCellList;
        }
        this.$scope.getAccountListByName = async (search: string): Promise<void> => {
            return await this.getAccountListByName(search);
        }
        this.$scope.goToCountry = (ids: string) => {
            this.$scope.sessionService.openTab("app.registration.country", <ILinkParameter>{ ID: ids });
        }

        this.$scope.goToSector = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.sector", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToPersonRole = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.personRole", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToRoutingPoint = (ids: string): void => {
            this.$scope.sessionService.openTab("app.registration.routingPoint", <ILinkParameter>{ ID: ids });
        }

        this.$scope.goToCommodity = (commodities: SelectorModel[]): void => {
            this.$scope.sessionService.openTab("app.registration.commodity", <ILinkParameter>{ ID: this.$scope.getCONCAT(commodities, null, "ID") });
        }

        this.$scope.goToExporter = (exporters: SelectorModel[]): void => {
            this.$scope.sessionService.openTab("app.registration.legalPerson", <ILinkParameter>{ ID: this.$scope.getCONCAT(exporters, null, "ID") });
        }

        this.$scope.goToImporter = (importers: SelectorModel[]): void => {
            this.$scope.sessionService.openTab("app.registration.legalPerson", <ILinkParameter>{ ID: this.$scope.getCONCAT(importers, null, "ID") });
        }

        this.$scope.goToAccount = (accounts: SelectorModel[]): void => {
            this.$scope.sessionService.openTab("app.commercial.account", <ILinkParameter>{ ID: this.$scope.getCONCAT(accounts, null, "ID") });
        }

        this.$scope.goToEntity = (entity: SelectorModel): void => {
            if (this.$scope.model.TYPE.ID == "1") this.$scope.sessionService.openTab("app.registration.legalPerson", <ILinkParameter>{ ID: entity ? entity.ID : entity });
            else this.$scope.sessionService.openTab("app.registration.network", <ILinkParameter>{ ID: entity ? entity.ID.toString() : entity });
        }

        this.$scope.goToOuterCell = (): void => {
            this.$scope.sessionService.openTab("app.registration.outerCell", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.OUTER_CELLS, null, "ID") });
        }

        this.$scope.goToTradeLane = (ids: string): void => {
            this.$scope.sessionService.openTab("app.registration.tradelane", <ILinkParameter>{ ID: ids });
        }

        this.$scope.typeSelect = (type: SelectorModel) => {
            if (type.ID == ETypeEntityId.LEGAL_PERSON) {
                this.$scope.model.ID_NETWORK = null;
                this.$scope.model.NETWORK = null;
            } else if (type.ID == ETypeEntityId.NETWORK) {
                this.$scope.model.ID_LEGAL_PERSON = null;
                this.$scope.model.LEGAL_PERSON = null;
            }
        }

        this.$scope.formatEmail = (email: string): string => {
            return email ? email.replace(/[^A-Za-z0-9-_@.]/g, '').toLowerCase() : "";
        }

        this.$scope.isMaritimeOriginFieldDisabled = (field: SelectorModel[]): boolean => {
            let disabled = true;
            if (this.checkProductMaritimeModel()) {
                if (this.$scope.model && (this.isEmptyArray(field) && this.isEmptyArray(this.$scope.model.TRADE_ORIGIN_MARITIME) && this.isEmptyArray(this.$scope.model.TRADE_LANE_ORIGIN_MARITIME) && this.isEmptyArray(this.$scope.model.COUNTRY_ORIGIN_MARITIME) && this.isEmptyArray(this.$scope.model.ROUTING_POINT_ORIGIN_MARITIME))
                    || (!this.isEmptyArray(field) && (angular.equals(field, this.$scope.model.TRADE_ORIGIN_MARITIME) || angular.equals(field, this.$scope.model.TRADE_LANE_ORIGIN_MARITIME) || angular.equals(field, this.$scope.model.COUNTRY_ORIGIN_MARITIME) || angular.equals(field, this.$scope.model.ROUTING_POINT_ORIGIN_MARITIME)))) {
                    disabled = false;
                }
            }
            return disabled;
        }

        this.$scope.isMaritimeDestinationFieldDisabled = (field: SelectorModel[]): boolean => {
            let disabled = true;
            if (this.checkProductMaritimeModel()) {
                if (this.$scope.model && (this.isEmptyArray(field) && this.isEmptyArray(this.$scope.model.TRADE_DESTINATION_MARITIME) && this.isEmptyArray(this.$scope.model.TRADE_LANE_DESTINATION_MARITIME) && this.isEmptyArray(this.$scope.model.COUNTRY_DESTINATION_MARITIME) && this.isEmptyArray(this.$scope.model.ROUTING_POINT_DESTINATION_MARITIME))
                    || (!this.isEmptyArray(field) && (angular.equals(field, this.$scope.model.TRADE_DESTINATION_MARITIME) || angular.equals(field, this.$scope.model.TRADE_LANE_DESTINATION_MARITIME) || angular.equals(field, this.$scope.model.COUNTRY_DESTINATION_MARITIME) || angular.equals(field, this.$scope.model.ROUTING_POINT_DESTINATION_MARITIME)))) {
                    disabled = false;
                }
            }
            return disabled;
        }

        this.$scope.isAirOriginFieldDisabled = (field: SelectorModel[]): boolean => {

            let disabled = true;
            if (this.checkProductAirModel()) {
                if (this.$scope.model && (this.isEmptyArray(field) && this.isEmptyArray(this.$scope.model.TRADE_ORIGIN_AIR) && this.isEmptyArray(this.$scope.model.TRADE_LANE_ORIGIN_AIR) && this.isEmptyArray(this.$scope.model.COUNTRY_ORIGIN_AIR) && this.isEmptyArray(this.$scope.model.ROUTING_POINT_ORIGIN_AIR))
                    || (!this.isEmptyArray(field) && (angular.equals(field, this.$scope.model.TRADE_ORIGIN_AIR) || angular.equals(field, this.$scope.model.TRADE_LANE_ORIGIN_AIR) || angular.equals(field, this.$scope.model.COUNTRY_ORIGIN_AIR) || angular.equals(field, this.$scope.model.ROUTING_POINT_ORIGIN_AIR)))) {
                    disabled = false;
                }
            }
            return disabled;
        }

        this.$scope.isAirDestinationFieldDisabled = (field: SelectorModel[]): boolean => {
            let disabled = true;
            if (this.checkProductAirModel()) {
                if (this.$scope.model && (this.isEmptyArray(field) && this.isEmptyArray(this.$scope.model.TRADE_DESTINATION_AIR) && this.isEmptyArray(this.$scope.model.TRADE_LANE_DESTINATION_AIR) && this.isEmptyArray(this.$scope.model.COUNTRY_DESTINATION_AIR) && this.isEmptyArray(this.$scope.model.ROUTING_POINT_DESTINATION_AIR))
                    || (!this.isEmptyArray(field) && (angular.equals(field, this.$scope.model.TRADE_DESTINATION_AIR) || angular.equals(field, this.$scope.model.TRADE_LANE_DESTINATION_AIR) || angular.equals(field, this.$scope.model.COUNTRY_DESTINATION_AIR) || angular.equals(field, this.$scope.model.ROUTING_POINT_DESTINATION_AIR)))) {
                    disabled = false;
                }
            }
            return disabled;
        }

        this.$scope.isRoadOriginFieldDisabled = (field: SelectorModel[]): boolean => {
            let disabled = true;
            if (this.checkProductRoadModel()) {
                if (this.$scope.model && (this.isEmptyArray(field) && this.isEmptyArray(this.$scope.model.TRADE_ORIGIN_ROAD) && this.isEmptyArray(this.$scope.model.TRADE_LANE_ORIGIN_ROAD) && this.isEmptyArray(this.$scope.model.COUNTRY_ORIGIN_ROAD) && this.isEmptyArray(this.$scope.model.ROUTING_POINT_ORIGIN_ROAD))
                    || (!this.isEmptyArray(field) && (angular.equals(field, this.$scope.model.TRADE_ORIGIN_ROAD) || angular.equals(field, this.$scope.model.TRADE_LANE_ORIGIN_ROAD) || angular.equals(field, this.$scope.model.COUNTRY_ORIGIN_ROAD) || angular.equals(field, this.$scope.model.ROUTING_POINT_ORIGIN_ROAD)))) {
                    disabled = false;
                }
            }
            return disabled;
        }

        this.$scope.isRoadDestinationFieldDisabled = (field: SelectorModel[]): boolean => {
            let disabled = true;
            if (this.checkProductRoadModel()) {
                if (this.$scope.model && (this.isEmptyArray(field) && this.isEmptyArray(this.$scope.model.TRADE_DESTINATION_ROAD) && this.isEmptyArray(this.$scope.model.TRADE_LANE_DESTINATION_ROAD) && this.isEmptyArray(this.$scope.model.COUNTRY_DESTINATION_ROAD) && this.isEmptyArray(this.$scope.model.ROUTING_POINT_DESTINATION_ROAD))
                    || (!this.isEmptyArray(field) && (angular.equals(field, this.$scope.model.TRADE_DESTINATION_ROAD) || angular.equals(field, this.$scope.model.TRADE_LANE_DESTINATION_ROAD) || angular.equals(field, this.$scope.model.COUNTRY_DESTINATION_ROAD) || angular.equals(field, this.$scope.model.ROUTING_POINT_DESTINATION_ROAD)))) {
                    disabled = false;
                }
            }
            return disabled;
        }

        this.$scope.refreshTradeLaneListByName = async (search: string): Promise<void> => {
            await this.refreshTradeLaneListByName(search);
        }

        this.$scope.refreshCountryListByName = async (search: string): Promise<void> => {
            await this.refreshCountryListByName(search);
        }

        this.$scope.checkProductMaritimeModel = (): boolean => {
            return this.checkProductMaritimeModel();
        }

        this.$scope.checkProductAirModel = (): boolean => {
            return this.checkProductAirModel();
        }

        this.$scope.checkProductRoadModel = (): boolean => {
            return this.checkProductRoadModel();
        }

        this.$scope.clearFieldsChangeProduct = (): void => {
            this.clearFieldsChangeProduct();
        }

        this.$scope.validityPhone = (inputName: string) => {
            if (!inputName) return;
            ValidateUtil.validityPhone(this.$scope['form'][inputName], inputName == "phone" ? this.intlPhone : null);
        };
        this.$scope.getPhoneValidityMessage = (inputName: string) => {
            return ValidateUtil.getPhoneValidityMessage(inputName && inputName == "phone" ? this.intlPhone : null);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewContact(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editContact(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogContact(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyContact(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;


        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy),
            enableCellEdit: true,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colLegalPerson: IMonacoColumnDef = { name: "LEGAL_PERSON.NAME", displayName: "ENTITY.LEGAL_PERSON", width: 400 };
            const colNetwork: IMonacoColumnDef = { name: "NETWORK.NAME", displayName: "BASIC_DATA.NETWORK", width: 400 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT", displayName: "BASIC_DATA.PRODUCT", width: 300, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PRODUCT, null, "ID")}}</div>' };
            const colName: IMonacoColumnDef = { name: "NAME", displayName: "GENERAL.NAME", width: 300 };
            const colEmail: IMonacoColumnDef = { name: "EMAIL", displayName: "ENTITY.EMAIL", width: 250 };
            const colSector: IMonacoColumnDef = { name: "SECTOR.NAME", displayName: "ENTITY.SECTOR", width: 250 };
            const colPersonRole: IMonacoColumnDef = { name: "PERSON_ROLE.NAME", displayName: "ENTITY.JOB_TITLE", width: 250 };
            const colMessageGroup: IMonacoColumnDef = { name: "MESSAGE_GROUP", displayName: "ENTITY.FOLLOWUP_GROUP", width: 300, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.MESSAGE_GROUP, null, "NAME")}}</div>' };
            const colNotification: IMonacoColumnDef = { name: "NOTIFICATION.NAME", displayName: "ENTITY.NOTIFICATION", width: 180 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 100, cellFilter: "YesOrNo" };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'LEGAL_PERSON':
                        columnDefs.push(colLegalPerson);
                        break;
                    case 'NETWORK':
                        columnDefs.push(colNetwork);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'NAME':
                        columnDefs.push(colName);
                        break;
                    case 'EMAIL':
                        columnDefs.push(colEmail);
                        break;
                    case 'SECTOR':
                        columnDefs.push(colSector);
                        break;
                    case 'PERSON_ROLE':
                        columnDefs.push(colPersonRole);
                        break;
                    case 'MESSAGE_GROUP':
                        columnDefs.push(colMessageGroup);
                        break;
                    case 'NOTIFICATION':
                        columnDefs.push(colNotification);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            ACTIVE: true,
            TYPE: null,
            LEGAL_PERSON: null,
            ID_LEGAL_PERSON: null,
            NETWORK: null,
            ID_NETWORK: null,
            PRODUCT: null,
            NAME: null,
            EMAIL: null,
            PHONE: null,
            PHONE_COUNTRY_CODE: null,
            SECTOR: null,
            PERSON_ROLE: null,
            MESSAGE_GROUP: null,
            NOTIFICATION: null,
            ACCOUNTS: null,
            EXPORTERS: null,
            IMPORTERS: null,
            COMMODITY: null,
            OUTER_CELLS: null,
            TRADE_ORIGIN_MARITIME: null,
            TRADE_LANE_ORIGIN_MARITIME: null,
            COUNTRY_ORIGIN_MARITIME: null,
            ROUTING_POINT_ORIGIN_MARITIME: null,
            TRADE_DESTINATION_MARITIME: null,
            TRADE_LANE_DESTINATION_MARITIME: null,
            COUNTRY_DESTINATION_MARITIME: null,
            ROUTING_POINT_DESTINATION_MARITIME: null,
            TRADE_ORIGIN_AIR: null,
            TRADE_LANE_ORIGIN_AIR: null,
            COUNTRY_ORIGIN_AIR: null,
            ROUTING_POINT_ORIGIN_AIR: null,
            TRADE_DESTINATION_AIR: null,
            TRADE_LANE_DESTINATION_AIR: null,
            COUNTRY_DESTINATION_AIR: null,
            ROUTING_POINT_DESTINATION_AIR: null,
            TRADE_ORIGIN_ROAD: null,
            TRADE_LANE_ORIGIN_ROAD: null,
            COUNTRY_ORIGIN_ROAD: null,
            ROUTING_POINT_ORIGIN_ROAD: null,
            TRADE_DESTINATION_ROAD: null,
            TRADE_LANE_DESTINATION_ROAD: null,
            COUNTRY_DESTINATION_ROAD: null,
            ROUTING_POINT_DESTINATION_ROAD: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null

        };
    }

    initDependencies(): Promise<any> {
        const self: ContactRegisterController = this;

        this.initPhoneComponent();

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getTypeList(),
                self.getProductList(),
                self.getMessageGroupList(),
                self.getNotificationTypeList(),
                self.getLocalGroupList(),
                self.getTradeGroupList(),
            ]).then((result: any) => {
                self.$scope.typeList = result[0];
                self.$scope.productList = result[1];
                self.$scope.messageGroupList = result[2];
                self.$scope.notificationTypeList = result[3];
                self.$scope.localGroupList = result[4];
                self.$scope.tradeGroupList = result[5];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private initPhoneComponent(): void {
        const self: ContactRegisterController = this;

        this.destroyPhoneComponent();

        const inputPhone = document.querySelector("#phone");
        self.intlPhone = intlTelInput(inputPhone, {
            utilsScript: "../../../../node_modules/intl-tel-input/build/js/utils.js",
            autoPlaceholder: "aggressive",
            formatOnDisplay: true,
            separateDialCode: true,
            initialCountry: "br"
        });

        if (!self.$scope.model.PHONE_COUNTRY_CODE) self.$scope.model.PHONE_COUNTRY_CODE = "br";
        self.intlPhone.setCountry(self.$scope.model.PHONE_COUNTRY_CODE);

        this.countryChangeListener = () => {
            self.$scope.model.PHONE = null;
            self.$scope.model.PHONE_COUNTRY_CODE = self.intlPhone.getSelectedCountryData().iso2;
            self.intlPhone.setNumber("");
            self.$scope.validityPhone("phone");
            self.$scope.$apply();
        };
        inputPhone.addEventListener("countrychange", this.countryChangeListener, false);
    }

    private destroyPhoneComponent(): void {
        const inputPhone = document.querySelector("#phone");
        if (inputPhone) inputPhone.removeEventListener("countrychange", this.countryChangeListener, false);
        if (this.intlPhone) this.intlPhone.destroy();
    }

    private callSessionFunctions(data: object): void {
        const legalPersonExchangeData = <ILegalPersonExchangeData>data;
        switch (legalPersonExchangeData.OPERATION) {
            case "register": this.$scope.register(legalPersonExchangeData.MODEL);
                break;
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: "TYPE", LABEL: "GENERAL.TYPE" },
            { PROPERTY: "ACTIVE", LABEL: "GENERAL.ACTIVE" },
            { PROPERTY: "PRODUCT", LABEL: "BASIC_DATA.PRODUCT" },
            { PROPERTY: "NAME", LABEL: "GENERAL.NAME" },
            { PROPERTY: "EMAIL", LABEL: "ENTITY.EMAIL" },
            { PROPERTY: "PHONE", LABEL: "ENTITY.PHONE" },
            { PROPERTY: "SECTOR", LABEL: "ENTITY.SECTOR" },
            { PROPERTY: "PERSON_ROLE", LABEL: "ENTITY.JOB_TITLE" },
            { PROPERTY: "MESSAGE_GROUP", LABEL: "REGISTRATION.GROUP_SEND_MESSAGE" },
            { PROPERTY: "NOTIFICATION", LABEL: "ENTITY.NOTIFICATION" },
            { PROPERTY: "COUNTRY", LABEL: "ROUTE.COUNTRY" },
            { PROPERTY: "ACCOUNTS", LABEL: "BASIC_DATA.CLIENT" },
            { PROPERTY: "EXPORTER", LABEL: "BASIC_DATA.SHIPPER" },
            { PROPERTY: "IMPORTER", LABEL: "BASIC_DATA.CONSIGNEE" },
            { PROPERTY: "COMMODITY", LABEL: "GENERAL.COMMODITY" },
            { PROPERTY: "OUTER_CELLS", LABEL: "ENTITY.OUTER_CELL" },
            { PROPERTY: "CODE", LABEL: "GENERAL.CODE" },
            { PROPERTY: "IMPORTERS", LABEL: "GENERAL.EXPORTERS" },
            { PROPERTY: "EXPORTERS", LABEL: "GENERAL.IMPORTERS" },
            { PROPERTY: "ID", LABEL: "REGISTRATION.IDENTIFICATION" },
            { PROPERTY: "TRADE_ORIGIN_MARITIME", LABEL: "GENERAL.SEA_ORIGIN_TRADE_GROUP" },
            { PROPERTY: "TRADE_LANE_ORIGIN_MARITIME", LABEL: "GENERAL.SEA_ORIGIN_TRADE_LANE" },
            { PROPERTY: "COUNTRY_ORIGIN_MARITIME", LABEL: "GENERAL.SEA_ORIGIN_COUNTRY" },
            { PROPERTY: "ROUTING_POINT_ORIGIN_MARITIME", LABEL: "BASIC_DATA.ORIGIN_PORT" },
            { PROPERTY: "TRADE_DESTINATION_MARITIME", LABEL: "GENERAL.SEA_DESTINATION_TRADE_GROUP" },
            { PROPERTY: "TRADE_LANE_DESTINATION_MARITIME", LABEL: "GENERAL.SEA_DESTINATION_TRADE_LANE" },
            { PROPERTY: "COUNTRY_DESTINATION_MARITIME", LABEL: "GENERAL.SEA_DESTINATION_COUNTRY" },
            { PROPERTY: "ROUTING_POINT_DESTINATION_MARITIME", LABEL: "BASIC_DATA.DESTINATION_PORT" },
            { PROPERTY: "TRADE_ORIGIN_AIR", LABEL: "GENERAL.AIR_ORIGIN_TRADE_GROUP" },
            { PROPERTY: "TRADE_LANE_ORIGIN_AIR", LABEL: "GENERAL.AIR_ORIGIN_TRADE_LANE" },
            { PROPERTY: "COUNTRY_ORIGIN_AIR", LABEL: "GENERAL.AIR_ORIGIN_COUNTRY" },
            { PROPERTY: "ROUTING_POINT_ORIGIN_AIR", LABEL: "BASIC_DATA.ORIGIN_AIRPORT" },
            { PROPERTY: "TRADE_DESTINATION_AIR", LABEL: "GENERAL.AIR_DESTINATION_TRADE_GROUP" },
            { PROPERTY: "TRADE_LANE_DESTINATION_AIR", LABEL: "GENERAL.AIR_DESTINATION_TRADE_LANE" },
            { PROPERTY: "COUNTRY_DESTINATION_AIR", LABEL: "GENERAL.AIR_DESTINATION_COUNTRY" },
            { PROPERTY: "ROUTING_POINT_DESTINATION_AIR", LABEL: "BASIC_DATA.DESTINATION_AIRPORT" },
            { PROPERTY: "TRADE_ORIGIN_ROAD", LABEL: "GENERAL.ROAD_TRADE_GROUP_ORIGIN" },
            { PROPERTY: "TRADE_LANE_ORIGIN_ROAD", LABEL: "GENERAL.ROAD_TRADE_LINE_ORIGIN" },
            { PROPERTY: "COUNTRY_ORIGIN_ROAD", LABEL: "GENERAL.ROAD_ORIGIN_COUNTRY" },
            { PROPERTY: "ROUTING_POINT_ORIGIN_ROAD", LABEL: "BASIC_DATA.ORIGIN_CITY" },
            { PROPERTY: "TRADE_DESTINATION_ROAD", LABEL: "GENERAL.ROAD_DESTINATION_TRADE_GROUP" },
            { PROPERTY: "TRADE_LANE_DESTINATION_ROAD", LABEL: "GENERAL.ROAD_DESTINATION_TRADE_LANE" },
            { PROPERTY: "COUNTRY_DESTINATION_ROAD", LABEL: "GENERAL.ROAD_DESTINATION_COUNTRY" },
            { PROPERTY: "ROUTING_POINT_DESTINATION_ROAD", LABEL: "BASIC_DATA.DESTINATION_CITY" },
            { PROPERTY: "QUALIFICATION", LABEL: "GENERAL.QUALIFICATION" },
            { PROPERTY: "CREDIT", LABEL: "FINANCIAL.CREDIT" },
            { PROPERTY: "FORWARDED_BY", LABEL: "GENERAL.FORWARDED_BY" },
            { PROPERTY: "LANGUAGE", LABEL: "GENERAL.LANGUAGE" },
            { PROPERTY: "PHONE_COUNTRY_CODE", LABEL: "GENERAL.PHONE_COUNTRY_CODE" },
            { PROPERTY: "NETWORK", LABEL: "BASIC_DATA.NETWORK" },
            { PROPERTY: "LEGAL_PERSON", LABEL: "ENTITY.LEGAL_PERSON" },
            { PROPERTY: "ID_NETWORK", LABEL: "BASIC_DATA.NETWORK_IDENTIFIER" },
            { PROPERTY: "CREATED_AT", LABEL: "GENERAL.CREATED_AT" },
            { PROPERTY: "CREATED_BY", LABEL: "GENERAL.CREATED_BY" },
            { PROPERTY: "UPDATED_AT", LABEL: "GENERAL.UPDATED_AT" },
            { PROPERTY: "UPDATED_BY", LABEL: "GENERAL.UPDATED_BY" }
        ]
        return props;
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlDataManager = this.config.productUrl + baseRoute;
        return urlDataManager;
    }

    private async getTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/contact_entity`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getMessageGroupList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/message_group`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getNotificationTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/notification_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getLocalGroupList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/locals_group`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTradeGroupList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/locals_group`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getNetworkListByName(name?: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const networks = await this.RestService.newObjectPromise(`${this.$baseUrl}/network/listByFullName`, { name }, 30000, false);
            if (networks) result = networks.map(network => { return { ID: network.ID, NAME: network.NAME, CODE: network.CODE ? network.CODE : null } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCountryListByNameOrInitials(search?: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const countries = await this.RestService.newObjectPromise(`${this.$baseUrl}/country/list`, { search }, 30000, false);
            if (countries && countries.data) result = countries.data.map(country => { return { ID: country.ID, NAME: country.NAME, CODE: country.INITIALS } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getSectorListByName(name?: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const sectors = await this.RestService.newObjectPromise(`${this.$baseUrl}/sector/list`, { name }, 30000, false);
            if (sectors && sectors.data) result = sectors.data.map(sector => { return { ID: sector.ID, NAME: sector.DISPLAY_NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getPersonRoleListByName(name?: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const roles = await this.RestService.newObjectPromise(`${this.$baseUrl}/personRole/list`, { search: name }, 30000, false);
            if (roles && roles.data) result = roles.data.map(role => { return { ID: role.ID, NAME: role.DISPLAY_NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getRoutingPointListByName(name?: string, types?: string[]): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const routingPoints = await this.RestService.newObjectPromise(`${this.$baseUrl}/routingPoint/list/custom`, { name, types }, 30000, false);
            if (routingPoints) result = routingPoints.map(routingPoint => { return { ID: routingPoint.ID, NAME: routingPoint.NAME, CODE: routingPoint.CODE } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getAccountListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const accounts = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/account/list/custom`, { search: search }, 30000, false);
                result = accounts ? accounts.map(x => { return { ID: x.ID, NAME: x.NAME } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.accountList = result;
            this.formService.unblock();
        }
    }

    private async getLegalPersonListByName(legalPersonFilter?: ILegalPersonListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const legalPersons = await this.RestService.newObjectPromise(`${this.$baseUrl}/legalPerson/list/custom`, legalPersonFilter, 30000, false);
            if (legalPersons) result = legalPersons.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCommodityListByName(name?: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const commodities = await this.RestService.newObjectPromise(`${this.$baseUrl}/commodity/list/custom`, { name }, 30000, false);
            if (commodities && commodities.length > 0) result = commodities.map(commodity => { return { ID: commodity.ID, NAME: commodity.NAME, HS_CODE: commodity.HS_CODE ? commodity.HS_CODE : null } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getOuterCellListByName(name?: string, entityIds?: number[]): Promise<IOuterCellSelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const outerCells = await this.RestService.newObjectPromise(`${this.$baseUrl}/outerCell/list/custom`, { name: name, entityIds: entityIds }, 30000, false);
            if (outerCells) result = outerCells.map(outerCell => { return { ID: outerCell.ID, NAME: outerCell.NAME, CODE: outerCell.CODE ? outerCell.CODE : null, ENTITY: outerCell.ENTITY ? outerCell.ENTITY : null } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async refreshTradeLaneListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const tradeLanesList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/tradelane/list/custom`, { name: search }, 30000, false);
                result = tradeLanesList ? tradeLanesList.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.tradeLaneList = result;
        }
    }

    private async refreshCountryListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const countryList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/country/list/custom`, { search }, 30000, false);
                result = (countryList && countryList) ? countryList.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.countryList = result;
            this.formService.unblock();
        }
    }

    async register(params: ISpecsWithRegistration): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} (${this.$scope.model.NAME})`;
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} (${this.$scope.model.NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        this.$scope.model.ID_LEGAL_PERSON = null;
        this.$scope.model.ID_NETWORK = null;
        try {
            this.$scope.disableElements(false);
            await this.clearFields(this.$scope.model, ['CONCATENATED']);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        const operation = this.$scope.operation === "edit" ? "update" : "insert";

        return {
            route: `/contact/${operation}`,
            data: angular.copy(this.$scope.model),
            oldData: this.$scope.scopeBeforeSave
        };
    }

    private isEmptyArray(arrayObj: any[]): boolean {
        return (arrayObj && arrayObj.length == 0 || !arrayObj);
    }

    private checkProductMaritimeModel(): boolean {

        let found = null;
        if (this.$scope.model && this.$scope.model.PRODUCT != null) {
            found = this.$scope.model.PRODUCT.find(item => item.ID == 'IM' || item.ID == 'EM');
        }

        if (found != null && found.ID) {
            return found.ID.length > 0 ? true : false;
        }
        return false;
    }

    private checkProductAirModel(): boolean {
        let found = null;
        if (this.$scope.model && this.$scope.model.PRODUCT != null) {
            found = this.$scope.model.PRODUCT.find(item => item.ID == 'IA' || item.ID == 'EA');
        }

        if (found != null && found.ID) {
            return found.ID.length > 0 ? true : false;
        }

        return false;
    }
    private checkProductRoadModel(): boolean {
        let found = null;
        if (this.$scope.model && this.$scope.model.PRODUCT != null) {
            found = this.$scope.model.PRODUCT.find(item => item.ID == 'IR' || item.ID == 'ER');
        }

        if (found != null && found.ID) {
            return found.ID.length > 0 ? true : false;
        }

        return false;
    }

    private clearFieldsChangeProduct(): void {
        if (!this.checkProductMaritimeModel()) {
            this.$scope.model.TRADE_ORIGIN_MARITIME = null;
            this.$scope.model.TRADE_DESTINATION_MARITIME = null;
            this.$scope.model.TRADE_LANE_ORIGIN_MARITIME = null;
            this.$scope.model.TRADE_LANE_DESTINATION_MARITIME = null;
            this.$scope.model.COUNTRY_ORIGIN_MARITIME = null;
            this.$scope.model.COUNTRY_DESTINATION_MARITIME = null;
            this.$scope.model.ROUTING_POINT_ORIGIN_MARITIME = null;
            this.$scope.model.ROUTING_POINT_DESTINATION_MARITIME = null;
        }

        if (!this.checkProductAirModel()) {
            this.$scope.model.TRADE_ORIGIN_AIR = null;
            this.$scope.model.TRADE_DESTINATION_AIR = null;
            this.$scope.model.TRADE_LANE_ORIGIN_AIR = null;
            this.$scope.model.TRADE_LANE_DESTINATION_AIR = null;
            this.$scope.model.COUNTRY_ORIGIN_AIR = null;
            this.$scope.model.COUNTRY_DESTINATION_AIR = null;
            this.$scope.model.ROUTING_POINT_ORIGIN_AIR = null;
            this.$scope.model.ROUTING_POINT_DESTINATION_AIR = null;
        }

        if (!this.checkProductRoadModel()) {
            this.$scope.model.TRADE_ORIGIN_ROAD = null;
            this.$scope.model.TRADE_DESTINATION_ROAD = null;
            this.$scope.model.TRADE_LANE_ORIGIN_ROAD = null;
            this.$scope.model.TRADE_LANE_DESTINATION_ROAD = null;
            this.$scope.model.COUNTRY_ORIGIN_ROAD = null;
            this.$scope.model.COUNTRY_DESTINATION_ROAD = null;
            this.$scope.model.ROUTING_POINT_ORIGIN_ROAD = null;
            this.$scope.model.ROUTING_POINT_DESTINATION_ROAD = null;
        }

    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/contact/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);

            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }
}

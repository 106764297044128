import * as angular from "angular";
import { SSEService } from "@appServices/SSEService"
import { ISessionService } from "@services/SessionService";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IFreightContractModel } from "@models/interface/product/FreightContractModel";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { BrowserTitle } from "../../common/BrowserTitle";
import { EOperation } from "@enums/GenericData";

interface IFreightContractScope extends IGridFormServiceScope {
    model: IFreightContractModel;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IFreightContractModel;
    user: any;
    sessionService: ISessionService;
    editFreightContract: (freightContract: IFreightContractModel) => Promise<void>;
    viewFreightContract: (freightContract: IFreightContractModel) => Promise<void>;
    viewLogFreightContract: (freightContract: IFreightContractModel) => Promise<void>;
    copyFreightContract: (freightContract: IFreightContractModel) => Promise<void>;
}

export class FreightContractRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IFreightContractScope;
    private $q: ng.IQService;
    private gridName: string;
    private SSEService: SSEService;

    constructor($injector: ng.Injectable<any>, $scope: IFreightContractScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.gridName = "gridFreightContract"
    }

    getUrlProduct() {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();

            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'freightContract', 'GENERAL.MENU.FREIGHT_CONTRACT', true);
            await this.initGrid('gridFreightContract', '/freightContract/list', true, true, null, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editFreightContract = async (freightContract: IFreightContractModel): Promise<void> => {
            let blockedObject = {
                ID: freightContract.ID,
                NAME: freightContract.DISPLAY_NAME,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || freightContract.ID !== this.$scope.model.ID) this.$scope.view(freightContract);
                } else if (this.$scope.operation !== EOperation.EDIT || freightContract.ID !== this.$scope.model.ID) {
                    this.$scope.edit(freightContract);
                }
            };
        }

        this.$scope.viewFreightContract = async (freightContract: IFreightContractModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(freightContract);
        }

        this.$scope.viewLogFreightContract = async (freightContract: IFreightContractModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(freightContract);
        }

        this.$scope.copyFreightContract = async (freightContract: IFreightContractModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(freightContract);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder(columns);

        const view = `<a ng-click="grid.appScope.viewFreightContract(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true"><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editFreightContract(row.entity)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true"><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogFreightContract(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true"><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.setCopy(true);grid.appScope.copyFreightContract(row.entity)" class="text-orange copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true"><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        
        const colActions: IMonacoColumnDef = {
            name: "acoes", displayName: "GENERAL.ACTIONS",
            width: 175,
            cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${viewLog} ${copy}</div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };

        gridColumns.addColumn(colActions);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colName: IMonacoColumnDef = { name: "DISPLAY_NAME", displayName: "OPERATIONAL.CONTRACT_REFERENCE", width: 400 };
            const colDescription: IMonacoColumnDef = { name: "DISPLAY_DESCRIPTION", displayName: "OPERATIONAL.CONTRACT_DESCRIPTION", width: 400 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 100, cellFilter: "YesOrNo" };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'DISPLAY_NAME':
                        columnDefs.push(colName);
                        break;
                    case 'DISPLAY_DESCRIPTION':
                        columnDefs.push(colDescription);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;

                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            DISPLAY_NAME: null,
            NAME: null,
            DESCRIPTION: null,
            DISPLAY_DESCRIPTION: null,
            CONCATENATED: null,
            ACTIVE: true,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            SEARCH_FIELDS: null,
            DOCUMENT_ERROR: null
        };
    }

    initDependencies(): Promise<any> {
        const self: FreightContractRegisterController = this;
        return new Promise(function (resolve, reject) {
            self.$q.all([

            ]).then((result: any) => {
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} (${this.$scope.model.DISPLAY_NAME})`;
            BrowserTitle.$id = this.$scope.model.DISPLAY_NAME;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} (${this.$scope.model.DISPLAY_NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.DISPLAY_NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        try {
            let proceed: boolean = true;
            return proceed;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === 'register' ? 'insert' : 'update';
        return {
            route: `/freightContract/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: "DISPLAY_NAME",
                LABEL: "OPERATIONAL.CONTRACT_REFERENCE"
            },
            {
                PROPERTY: "DISPLAY_DESCRIPTION",
                LABEL: "OPERATIONAL.CONTRACT_DESCRIPTION"
            },
            {
                PROPERTY: "DESCRIPTION",
                LABEL: "GENERAL.DESCRIPTION"
            },
            {
                PROPERTY: "CONCATENATED",
                LABEL: "GENERAL.CONCATENATED"
            },
            {
                PROPERTY: "NAME",
                LABEL: "GENERAL.NAME"
            },
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE"
            },
            {
                PROPERTY: "CREATED_AT",
                LABEL: "GENERAL.CREATED_AT"
            },
            {
                PROPERTY: "CREATED_BY",
                LABEL: "GENERAL.CREATED_BY"
            },
            {
                PROPERTY: "UPDATED_AT",
                LABEL: "GENERAL.UPDATED_AT"
            },
            {
                PROPERTY: "UPDATED_BY",
                LABEL: "GENERAL.UPDATED_BY"
            }
        ];
        return props;
    }
}

import { IMonacoConfig } from '../common/MonacoInterface';
import * as Address from '../communication/Address';

//TODO: CREATE AN INTERFACE FOR THIS CONFIG OBJECT
let config: IMonacoConfig = {
    name: 'MONACO',
    version: '4.3.7',
    environment: 'prod',
    hashPrefix: 'allog',
    loginState: 'app.signin',
    appState: 'app.home',
    externalState: 'app.external.draft',
    invalidState: 'app.invalid',
    errorState: 'app.error',
    restfulDns: null,
    restfulBaseUrl: '/api/v1',
    wsUrl: null,
    fmsUrl: null,
    reportDataManagerUrl: null,
    reportDataStartUrl: null,
    reportDataBuilderUrl: null,
    productUrl: null,
    quotationUrl: null,
    quotationCombinedUrl: null,
    operationalUrl: null,
    fisUrl: null,
    externalUrl: null,
    helperUrl: null,
    fatturoUrl: null,
    fatturoApi: null,
    debug: false,
    debugRoute: false,
    debugLazyLoad: false,
    storagePort: 3001,
}
function initConfig() {
    if (config.environment === 'dev') {

        config.restfulDns = null;
        config.restfulBaseUrl = '/api/v1';
        config.wsUrl = null;
        config.fmsUrl = `${Address.monacoAddressLocal.FMS}/api/v1`;
        config.reportDataManagerUrl = `${Address.monacoAddressLocal.DATAMANAGER}/api/v1`;
        config.reportDataStartUrl = `${Address.monacoAddressLocal.DATASTART}/api/v1`;
        config.reportDataBuilderUrl = `${Address.monacoAddressLocal.DATABUILDER}/api/v1`;
        config.productUrl = `${Address.monacoAddressLocal.PRODUCT}/api/v1`;
        config.quotationUrl = `${Address.monacoAddressLocal.QUOTATION}/api/v1`;
        config.quotationCombinedUrl = `${Address.monacoAddressLocal.QUOTATIONCOMBINED}/api/v1`;
        config.operationalUrl = `${Address.monacoAddressLocal.OPERATIONAL}/api/v1`;
        config.fisUrl = `${Address.monacoAddressLocal.FIS}/api/v1`;
        config.externalUrl = `${Address.monacoAddressLocal.EXTERNAL}/api/v1`;
        config.helperUrl = `${Address.monacoAddressLocal.HELPER}/api/v1`;
        config.fatturoUrl = null;
        config.fatturoApi = null;

        config.debug = true;

        console.log('Running Monaco on Development mode');

    } else if (config.environment === 'qa') {
        config.restfulDns = null;
        config.restfulBaseUrl = '/api/v1';
        config.wsUrl = null;
        config.fmsUrl = `${Address.monacoAddressQa.FMS}/api/v1`;
        config.reportDataManagerUrl = `${Address.monacoAddressQa.DATAMANAGER}/api/v1`;
        config.reportDataStartUrl = `${Address.monacoAddressQa.DATASTART}/api/v1`;
        config.reportDataBuilderUrl = `${Address.monacoAddressQa.DATABUILDER}/api/v1`;
        config.productUrl = `${Address.monacoAddressQa.PRODUCT}/api/v1`;
        config.quotationUrl = `${Address.monacoAddressQa.QUOTATION}/api/v1`;
        config.quotationCombinedUrl = `${Address.monacoAddressQa.QUOTATIONCOMBINED}/api/v1`;
        config.operationalUrl = `${Address.monacoAddressQa.OPERATIONAL}/api/v1`;
        config.fisUrl = `${Address.monacoAddressQa.FIS}/api/v1`;
        config.externalUrl = `${Address.monacoAddressQa.EXTERNAL}/api/v1`;
        config.helperUrl = `${Address.monacoAddressQa.HELPER}/api/v1`;
        config.fatturoUrl = null;
        config.fatturoApi = null;

        config.debug = false;

        console.log('Running Monaco on QA mode');

    } else if (config.environment === 'prod') {
        config.restfulDns = null;
        config.restfulBaseUrl = '/api/v1';
        config.wsUrl = null;
        config.fmsUrl = `${Address.monacoAddressProd.FMS}/api/v1`;
        config.reportDataManagerUrl = `${Address.monacoAddressProd.DATAMANAGER}/api/v1`;
        config.reportDataStartUrl = `${Address.monacoAddressProd.DATASTART}/api/v1`;
        config.reportDataBuilderUrl = `${Address.monacoAddressProd.DATABUILDER}/api/v1`;
        config.productUrl = `${Address.monacoAddressProd.PRODUCT}/api/v1`;
        config.quotationUrl = `${Address.monacoAddressProd.QUOTATION}/api/v1`;
        config.quotationCombinedUrl = `${Address.monacoAddressProd.QUOTATIONCOMBINED}/api/v1`;
        config.operationalUrl = `${Address.monacoAddressProd.OPERATIONAL}/api/v1`;
        config.fisUrl = `${Address.monacoAddressProd.FIS}/api/v1`;
        config.externalUrl = `${Address.monacoAddressProd.EXTERNAL}/api/v1`;
        config.helperUrl = `${Address.monacoAddressProd.HELPER}/api/v1`;
        config.fatturoUrl = null;
        config.fatturoApi = null;

        config.debug = false;

        console.log('Running Monaco on Production mode');

    } else console.log('INVALID CONFIG!');

    return config;
}

initConfig();
export default config;

import { randomBytes } from 'crypto';
import { v1, v4 } from 'uuid';

export class CryptoService {
    public async generateUniqueTimeId(): Promise<string> {
        try {
            return <Promise<string>>new Promise((resolve, reject) => {
                randomBytes(16, (err, random) => {
                    if (err) return reject(err);
                    else resolve(v1({ random }));
                });
            });
        } catch (ex) {
            throw ex;
        }
    }

    public async generateUniqueRandomId(): Promise<string> {
        try {
            return <Promise<string>>new Promise((resolve, reject) => {
                randomBytes(16, (err, random) => {
                    if (err) return reject(err);
                    else resolve(v4({ random }));
                });
            });
        } catch (ex) {
            throw ex;
        }
    }
}

import { ICargo } from "../../model/OfferModel";
import { CARGO_LIST } from "WBA-Model/dist/interface/product/OfferWizardFilter";

export abstract class OfferHelper {

    public static calculateCargoCBW(cargoModel: ICargo | CARGO_LIST): number {
        if (!cargoModel) throw Error('cargoModel is null');
        let cbm = null;
        try {
            const vol = cargoModel.VOLUME != null ? cargoModel.VOLUME : 0;
            const length = cargoModel.LENGTH != null ? cargoModel.LENGTH : 0;
            const width = cargoModel.WIDTH != null ? cargoModel.WIDTH : 0;
            const height = cargoModel.HEIGHT != null ? cargoModel.HEIGHT : 0;
            cbm = (vol * length * width * height) / 1000000;
        } catch (ex) {
            throw ex;
        } finally {
            return cbm;
        }
    }

    public static calculateCargoGrossWeightTotal(cargoModel: ICargo | CARGO_LIST): number {
        if (!cargoModel) throw Error('cargoModel is null');
        let pbTotal = null;
        try {
            const vol = cargoModel.VOLUME != null ? cargoModel.VOLUME : 0;
            const pbUn = cargoModel.GROSS_WEIGHT_UNITARY != null ? cargoModel.GROSS_WEIGHT_UNITARY : 0;
            pbTotal = vol * pbUn;
        } catch (ex) {
            throw ex;
        } finally {
            return pbTotal;
        }
    }

    public static calculateVolumeTotal(cargoList: ICargo[] | CARGO_LIST[]): number {
        let volTotal = 0;
        try {
            for (const cargo of cargoList) { volTotal += cargo.VOLUME != null ? cargo.VOLUME : 0; }
        } catch (ex) {
            throw ex;
        } finally {
            return volTotal;
        }
    }

    public static calculateGrossWeightTotal(cargoList: ICargo[] | CARGO_LIST[]): number {
        let grossWeightTotal = 0;
        try {
            if (cargoList && cargoList.length) for (const cargo of cargoList) { grossWeightTotal += cargo.GROSS_WEIGHT != null ? cargo.GROSS_WEIGHT : 0; }
        } catch (ex) {
            grossWeightTotal = null;
            throw ex;
        } finally {
            return grossWeightTotal ? parseFloat(grossWeightTotal.toFixed(3)) : grossWeightTotal;
        }
    }

    public static calculateAirChargeableWeight(grossWeight: number): number {
        return Math.ceil((grossWeight / 0.5)) * 0.5;
    }

    public static calculateCBWTotal(cargoList: ICargo[] | CARGO_LIST[]): number {
        let cbwTotal = 0;
        try {
            if (cargoList && cargoList.length) for (const cargo of cargoList) { cbwTotal += cargo.CUBIC_WEIGHT != null ? cargo.CUBIC_WEIGHT : 0; }
        } catch (ex) {
            cbwTotal = null;
            throw ex;
        } finally {
            return cbwTotal;
        }
    }
}
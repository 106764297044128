import { IApplicationList } from "@models/interface/product/ApplicationModel";
import { IChargeNameList } from "@models/interface/product/ChargeNameModel";
import { MongoModel } from "../../common/model/MongoModel";
import { SelectorModel } from "../../common/model/SelectorModel";
import { IUploadItem } from "@models/interface/common/IMonacoUpload";
import { IProviderSelector } from "@models/interface/product/ProviderModel";
import { IEquipmentSelector } from "@models/interface/product/EquipmentModel";
import { IRoutingPointSelector } from "@models/interface/product/RoutingPoint";
import { IDocumentError } from '@models/interface/common/IDocumentError';

export const enum ETariffLocalImporterExporterListRoutePath {
    IMPORTER = "listImporter",
    EXPORTER = "listExporter"
}

export interface ITariffLocalExchangeData {
    OPERATION: string;
    ID: string;
}

export interface ITariffLocalScoreFieldsFilter {
    PAYMENT_NATURE: string[];
    PRODUCT: string[];
}

export interface ITariffLocalImporterExporterListCustomFilter {
    search: string;
    id: number;
}

export interface ITariffLocalScoreFieldsScore {
    LOCAL: number;
    COUNTRY: number;
    PROVIDER: number;
    LEGAL_PERSON_AGENT: number;
    NETWORK: number;
    EXPORTER: number;
    IMPORTER: number;
    ACCOUNT: number;
}

export interface ITariffLocalScoreFields {
    FILTER: ITariffLocalScoreFieldsFilter;
    SCORE: ITariffLocalScoreFieldsScore;
}

export interface IChargeModalWeightRangeCharge {
    WEIGHT_RANGE: SelectorModel;
    UNITARY: number;
}

export interface IChargePaymentReceiving {
    ID_CURRENCY: number;
    CURRENCY: SelectorModel;
    UNITARY: number;
    MIN: number;
    FREE_OF_CHARGE: boolean;
    HOLDER_TYPE: SelectorModel;
    ID_LEGAL_PERSON_HOLDER: number;
    ID_PHYSICAL_PERSON_HOLDER: number;
    LEGAL_PERSON_HOLDER: SelectorModel;
    PHYSICAL_PERSON_HOLDER: SelectorModel;
    WEIGHT_RANGE_CHARGE: IChargeModalWeightRangeCharge[];
}

export interface ICharge extends MongoModel {
    DISPLAY_INDEX: number;
    ID_TARIFF_LOCAL: number;
    ID_CHARGE_NAME_EXHIBITION: number;
    CHARGE_NAME_EXHIBITION: IChargeNameList;
    APPLICATION: IApplicationList;
    EQUIPMENT: IEquipmentSelector[];
    VEHICLE_TYPE: SelectorModel[];
    WEIGHT_RANGE: SelectorModel[];
    FLAT: boolean;
    VALIDITY_START: Date;
    VALIDITY_END: Date;
    GROUP: boolean;
    EVENT: SelectorModel;
    FREIGHT_BASIS: boolean;
    RATE_TYPE: SelectorModel;
    TARIFF_LOCAL_PAYMENT: IChargePaymentReceiving;
    TARIFF_LOCAL_RECEIVING: IChargePaymentReceiving;
}

export interface ITariffLocalModel extends MongoModel {
    CONCATENATED: string;
    CONCATENATED_COMPLEMENT: string;
    VERSION: number;
    PAYMENT_NATURE: SelectorModel;
    PRODUCT: SelectorModel;
    DIRECTION: SelectorModel;
    TYPE_CARGO: SelectorModel;
    TYPE_LOCAL: SelectorModel[];
    LOCAL: IRoutingPointSelector[];
    PROVIDERS: IProviderSelector[];
    NETWORKS: SelectorModel[];
    LEGAL_PERSON_AGENTS: SelectorModel[];
    ACCOUNTS: SelectorModel[];
    EXPORTERS: SelectorModel[];
    IMPORTERS: SelectorModel[];
    SERVICE_TYPE: SelectorModel;
    CHARGE: ICharge[];
    OBSERVATION: string;
    SCORE: number;
    SCORE_RULE: ITariffLocalScoreFields;
    SCORE_DATE: Date;
    SCORE_ERROR: string;
    VALIDITY_START: Date;
    DISPLAY_VALIDITY_START: Date;
    VALIDITY_END: Date;
    DISPLAY_VALIDITY_END: Date;
    EVENT: SelectorModel;
    FREIGHT_BASIS: boolean;
    FILES: IUploadItem[];
    FILES_REMOVE: IUploadItem[];
    FILES_NEW: IUploadItem[];
    SITUATION: SelectorModel;
    DETAILS: SelectorModel[];
    ACTIVE: boolean;
    VALIDITY_EXPIRED: boolean;
    CREATED_AT: Date;
    CREATED_BY: SelectorModel;
    UPDATED_AT: Date;
    UPDATED_BY: SelectorModel;
    DOCUMENT_ERROR: IDocumentError[];
}

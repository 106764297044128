import { IWebSocket } from "angular-websocket";
import { initFireworks } from "@services/FireworkService";
import { IMonacoScope } from "../common/MonacoInterface";
import { IPromise } from "angular";

export interface IWebsocketService {
    init: (token: string) => boolean;
    close: (force?: boolean) => void;
    subscribe: (topic: string, callback: SubscribeHandler) => Promise<void>;
    unsubscribe: (topic: string) => Promise<void>;
}

export type SubscribeHandler = ((topic: string, data: any) => void);

interface WebsocketMessage {
    op: number
    topic: string
    data?: any
}

export class WebSocketService {
    public static $inject: string[] = ['$injector'];
    private injector: ng.Injectable<any>;
    private $rootScope: IMonacoScope;
    private $timeout: ng.ITimeoutService;
    private blockUI: ng.blockUI.BlockUIService;
    private websocket: IWebSocket;
    private url: string;
    private pingInterval: number;
    private pingHandler: IPromise<unknown>;
    private reconnectionInterval: number;
    private reconnectionHandler: IPromise<unknown>;
    private alive: boolean;
    private subscribeMap: Map<string, SubscribeHandler>;

    constructor($injector: ng.Injectable<any>) {
        this.injector = $injector;
        this.$rootScope = $injector.get('$rootScope');
        this.$timeout = $injector.get('$timeout');
        this.blockUI = $injector.get('blockUI');
        this.websocket = null;
        this.url = null;
        this.subscribeMap = new Map<string, SubscribeHandler>();
        this.pingInterval = 15000;
        this.reconnectionInterval = 15000;
    }

    public init(token: string): boolean {
        try {
            if (this.connect(token)) {
                //register websocket events
                this.websocket.onOpen(this.handleConnection.bind(this))
                this.websocket.onMessage(this.handleMessage.bind(this));
                this.websocket.onError(this.handleError.bind(this));
                return true;

            } else {
                console.log(`Failed to connect ws to ${this.url}`);

                //start reconnection process
                this.setReconnectionTrigger()
                return false;
            }

        } catch (ex) {
            //start reconnection process
            this.setReconnectionTrigger()
            throw ex;
        }
    }

    private setPingTrigger(): void {
        this.pingHandler = this.$timeout(this.handlePing.bind(this), this.pingInterval);
    }

    private setReconnectionTrigger(): void {
        this.reconnectionHandler = this.$timeout(this.handleReconnection.bind(this), this.reconnectionInterval);
    }

    public close(force?: boolean): void {
        try {
            this.alive = false;
            if (this.websocket) this.websocket.close(force);
            if (this.reconnectionHandler) this.$timeout.cancel(this.reconnectionHandler);
            if (this.pingHandler) this.$timeout.cancel(this.pingHandler);

            console.log('ws closed');

        } catch (ex) {
            throw ex;
        }
    }

    private async send(data: WebsocketMessage): Promise<void> {
        try {
            return this.websocket.send(data);

        } catch (ex) {
            throw ex;
        }
    }

    public async subscribe(topic: string, callback: SubscribeHandler): Promise<void> {
        try {
            if (!topic) throw new Error('topic is NULL');
            if (!callback) throw new Error('callback is NULL');

            if (this.subscribeMap.has(topic)) throw new Error(`topic ${topic} is already subscribed`);

            //register topic and callback on the map, even if not connected
            this.subscribeMap.set(topic, callback);

            await this.sendSubscribe(topic);

        } catch (ex) {
            throw ex;
        }
    }

    public async unsubscribe(topic: string): Promise<void> {
        try {
            if (!topic) throw new Error('topic is NULL');

            //send message to server
            await this.sendUnsubscribe(topic);

            this.subscribeMap.delete(topic);

        } catch (ex) {
            throw ex;
        }
    }

    private async sendPing(): Promise<void> {
        try {
            this.alive = false;

            //send ping to server
            const pingMessage: WebsocketMessage = { op: 4, topic: 'ping' };
            await this.send(pingMessage);

        } catch (ex) {
            throw ex;
        }
    }

    private async sendSubscribe(topic: string): Promise<void> {
        try {
            if (!topic) throw new Error('topic is NULL');

            //send message to server
            const subscribeMessage: WebsocketMessage = { op: 1, topic: topic };
            await this.send(subscribeMessage);

            //TODO: IMPLEMENT FAILED SUBSCRIBE WHILE ON THE RECONNECTION PROCESS

        } catch (ex) {
            throw ex;
        }
    }

    private async sendUnsubscribe(topic: string): Promise<void> {
        try {
            if (!topic) throw new Error('topic is NULL');

            //send message to server
            const unsubscribeMessage: WebsocketMessage = { op: 2, topic: topic };
            await this.send(unsubscribeMessage);

        } catch (ex) {
            throw ex;
        }
    }

    private connect(token: string): boolean {
        try {
            const wsUrl = this.injector.get('config').wsUrl;
            this.url = `${wsUrl}/auth?token=${token}`;
            this.websocket = this.injector.get('$websocket')(this.url);
            if (this.websocket) return true;
            return false;

        } catch (ex) {
            throw ex;
        }
    }


    private handleConnection(connection: any): void {
        try {
            console.log(`ws connected`);

            this.alive = true;

            //lets start the ping here
            this.setPingTrigger();

        } catch (ex) {
            throw ex;
        }
    }

    private handleMessage(message: MessageEvent): void {
        try {
            let receivedMessage: WebsocketMessage = null;
            try {
                receivedMessage = JSON.parse(message.data);
                if (!receivedMessage.hasOwnProperty('op') || !receivedMessage.hasOwnProperty('topic')) {
                    throw new Error('Received invalid websocket message from server');
                }

            } catch (parseEx) {
                throw new Error(`Failed to parse received message from server: ${parseEx.message}`);
            }

            if (receivedMessage.op === 3 && !receivedMessage.hasOwnProperty('data')) throw new Error('Received websocket message from server with NULL data');

            switch (receivedMessage.op) {
                case 3:
                    //find the callback handler based on the topic name
                    const callbackHandler = this.subscribeMap.get(receivedMessage.topic);
                    if (!callbackHandler) throw new Error(`Received message on topic ${receivedMessage.topic} but the topic is not registered, forgot to unregister the topic?`);

                    //exec the callback passing the data
                    callbackHandler(receivedMessage.topic, receivedMessage.data);
                    break;
                case 4:
                    if (receivedMessage.topic === 'ping' && receivedMessage.data === 'pong') this.alive = true;
                    break;
                case 5: //Especial case fireworks
                    if (receivedMessage.topic === 'fireworks') {
                        //do not apply fireworks for external users
                        if (this.$rootScope.user.isExternal) return;

                        this.blockUI.reset();
                        initFireworks();
                    }
                    break;
                default:
                    throw new Error(`Received invalid websocket message from server: ${JSON.stringify(receivedMessage)}`);
            }

        } catch (ex) {
            throw ex;
        }
    }

    private handleError(error: any): void {
        try {
            console.log(`Ws error: ${JSON.stringify(error)}`);

            this.close(true);

            //after error lets reconnect
            this.setReconnectionTrigger()

        } catch (ex) {
            throw ex;
        }
    }

    private handlePing(): void {
        try {
            if (this.alive === false) {
                console.log('ws connection lost, starting reconnection..');

                this.close(true);
                this.setReconnectionTrigger()

                return;
            }

            this.sendPing();
            this.setPingTrigger();

        } catch (ex) {
            console.log(ex);
        }
    }

    private handleReconnection(): void {
        try {
            console.log('Reconnecting to ws server..');

            const sessionService = this.injector.get('SessionService');
            if (!sessionService || !sessionService.isAuthenticated()) {
                console.log('Failed to reconnect ws, not authenticated..');
                this.setReconnectionTrigger()
                return;
            }

            const token = sessionService.getToken();
            const reconnected = this.init(token);
            if (!reconnected) {
                console.log('Failed to reconnect ws..');
                this.setReconnectionTrigger()
                return;
            }

            //re-register the previous registered subscribed topics
            const topicList = Array.from(this.subscribeMap.keys());
            for (let i = 0; i < topicList.length; i++) {
                this.sendSubscribe(topicList[i]);
            }

        } catch (ex) {
            console.log(ex);
            this.setReconnectionTrigger()
        }
    }


}
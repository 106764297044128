//import "jquery-mask-plugin/dist/jquery.mask.min.js";

import "angular-ui-grid/ui-grid.min.css";
import "ui-select/dist/select.min.css";
import '../../css/grid.css';
import './directive/ui-grid-excel.css';

import * as angular from 'angular';
import 'angular-ui-grid';
import 'ui-select';
//import 'angular-input-masks';

import { uiGridExcel, configExcel } from "./directive/UiGridExcel";

const GRID_EXCEL_MODULE = angular
    .module('gridExcel', [
        'ui.grid',
        'ui.grid.pagination',
        'ui.grid.selection',
        'ui.grid.cellNav',
        'ui.grid.expandable',
        'ui.grid.edit',
        'ui.grid.rowEdit',
        'ui.grid.saveState',
        'ui.grid.resizeColumns',
        'ui.grid.pinning',
        'ui.grid.moveColumns',
        'ui.grid.exporter',
        'ui.grid.infiniteScroll',
        'ui.grid.importer',
        'ui.grid.grouping',
        'ui.select'
    ])
    .directive('uiGridExcel', uiGridExcel)
    .config(configExcel);



export { GRID_EXCEL_MODULE };
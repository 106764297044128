import * as angular from "angular";
import * as moment from "moment";
import { isArray } from "angular";
import { IGridRow } from "ui-grid";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequest, } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IModalService } from "@services/ModalService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IFinopService } from "@services/FinopService";
import { ISessionService } from "@services/SessionService";
import { BatchError, BatchViewService, BatchItem } from "@services/BatchViewService";
import { ProductService } from "@services/ProductService";
import { Invoice, IFinancialMovements, PEOPLE, CLIENT_ACCOUNT, IInvoiceInstructions, EXCHANGE_CONTRACT, IInvoiceUniqueFilter, IProductCompatibilityIntegrationIds, IErpTopHistory } from '@models/interface/finop/NewInvoice';
import { ICustomActionsMap, ICustomLogProperties, IViewLog } from "@models/interface/common/IViewLog";
import { propertyNameMap } from "@models/interface/finop/ChargeGroupingParams";
import { Process } from "@models/interface/operational/NewProcess";
import { CURRENT_ACCOUNT } from "@models/product/LegalPerson";
import { ISelectorModel } from "@models/mongo/SelectorModel";
import { EErpIntegrationStatus, EIntegrateDirection, EInvoiceStatus, EPaymentMethod, EOperation } from "@enums/GenericData";
import { ESankhyaStatusMessage } from "@enums/Sankhya";
import { EAction } from "@enums/Invoice";
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { SelectorModel } from "../../common/model/SelectorModel";
import { IProcessParameter, IContractParameter, IChargeParameter } from "../../common/model/ModelParameter";
import { InvoiceNegotiationController } from "./InvoiceNegotiationController";
import { OperationalService } from "@services/OperationalService";
import { HelperService } from "@services/HelperService";

type InvoiceBatchItem = BatchItem<Invoice, IInvoiceUniqueFilter>;

interface ICurrencyTotals {
    ID: string;
    NAME: string;
    CODE: string;
    VALUE: number;
    TOTAL_BRL: number;
}

interface IExchangeModal {
    INVOICE: string;
    CONTRACT: string;
    COUNTRY: SelectorModel;
    CHARGE_CODE: SelectorModel;
    EXCHANGE: SelectorModel;
    BROKER: SelectorModel;
    DATE_SHIPMENT: Date;
    DATE_ORDER: Date;
    LOCK_CONTRACT_NUMBER: string;
    PAYMENT_REQUEST: Date;
    LOCK_DATE: Date;
    UNLOCK_DATE: Date;
    OBSERVATION: string;
}

interface IExchangeScope extends ng.IScope {
    exchangeModal: IExchangeModal;
    countriesList: Array<SelectorModel>;
    companiesList: Array<SelectorModel>;
    exchangeModList: Array<SelectorModel>;
    chargeList: Array<SelectorModel>;
    applyExchangeContract: () => void;
    searchBrokerBy: (query: string) => any;
    searchCountriesBy: (query: string) => any;
}

interface IContractModal extends ng.IScope {
    data: {
        selectedContract: SelectorModel;
        invoiceNumber: string;
        invoiceID: string[];
        hasCustomContract: boolean;
    };
    contractList: SelectorModel[];
    applyFinancialContract: (invoiceID: string[], contract: SelectorModel) => void;
}

interface IInstructionModal extends ng.IScope {
    data: {
        isMultipleAndOneHasApprovalDate: boolean;
        paymentPeriod: SelectorModel,
        isSankhya: boolean,
        invoiceStatus: SelectorModel;
    };
    approvalDate: Date;
    thirdPartyDate: Date;
    periodList: SelectorModel[];
    providerInvoice: string;
    typedNumber: string;
    paymentDate: Date;
    erpTopHistory: IErpTopHistory[];
    applyInstruction: (clearApprovalDate?: boolean) => Promise<void>;
    getEmptySelectorMsg: () => string;
    automaticNumber: () => boolean;
}

interface ICommentModal extends ng.IScope {
    data: {
        isMultipleAndOneHasApprovalDate: boolean;
        paymentPeriod: SelectorModel,
        isSankhya: boolean,
        invoiceStatus: SelectorModel;
    };
    approvalDate: Date;
    thirdPartyDate: Date;
    periodList: SelectorModel[];
    providerInvoice: string;
    comment: string;
    paymentDate: Date;
    erpTopHistory: IErpTopHistory[];
    applyComment: () => Promise<void>;
    getEmptySelectorMsg: () => string;
    automaticNumber: () => boolean;
}

interface IGroupingSpecModal extends ng.IScope {
    log: IViewLog,
    data: {
        invoice: Invoice,
        groupingSpecs: Object | Object[],
    };
}

interface IModelERP_BillingHistoryModal extends ng.IScope {
    invoice: Invoice,
    isOk: boolean;
    isCanceled: boolean;
    modalRow: IModelERPR_BillingHistoryModalRow[],
}

interface IModelERPR_BillingHistoryModalRow {
    ERP_BILLING: string,
    CANCELED: boolean,
    financial: IFinancialMovements
}

interface IAccountModal extends ng.IScope {
    selectedAccount: CLIENT_ACCOUNT;
    accounts: SelectorModel[];

    refreshAccount: (account: ISelectorModel) => void;
    changeInvoiceAccount: () => void;
}

export interface IInvoiceController {
    gridTemplate: string;
    model: Invoice;
    customLogProperties: ICustomLogProperties[];
    invoiceActionsMap: ICustomActionsMap[];
    selectedRows: Invoice[];
    selectedInvoices: Invoice[];
    selectedInvoicesCurrencies: any[];
    modalError: string;
    modalSuccess: string;
    totalBRL: number;
    actionsDisabled: Boolean;
    breadcumbCustomArea: any;
    executeCellAction: Function;
    groupingSpecModal: (selectedInvoiceIndex: number) => void;
    modalErpOrder: (selectedInvoiceIndex: number) => void;
    showActionsSelected: (row: IGridRow, action: string) => void;
    lockedSelected: () => void;
    applyFinancialContract: (invoiceID: string[], selectedContract: SelectorModel) => void;
    clearSelections: () => Promise<void>;
    updateGrid: () => Promise<void>;
    goToProcess: (process: string) => void;
    goToExternalDataIntegration: (goToExternalDataIntegration: string) => void;
    goToContract: (contract: string) => void;
    goToCharge: (invoice: Invoice, charge: string) => void;
    buildErpIntegrationStatusTooltip: (charge: object) => string;
    buildErpIntegrationErroTooltip: (invoice: object) => string;
    buildErpIntegrationTooltip: (charge: object, invoice: object) => string;
    concatConversionDate: (entity: Invoice) => string;
}

export interface IInvoiceScope extends IGridFormServiceScope, IInvoiceController {
    invoiceCancelReason: ISelectorModel;
    reasonList: ISelectorModel[];
    isSankhya: boolean;
    updateReasonValue: (reason: ISelectorModel) => void;
}

export function CustomizationAnnotation() {
    return function <C extends Invoice>(target: { new(...args: any[]): C; }): any {
        if (!isCustomization(target)) {
            throw new Error('Invoice can be only used for classes that implement Invoice interface!');
        }
        let newTarget = new target();
        return newTarget;
    };
}
function isCustomization(target: any | Invoice): target is { new(...args: any[]): Invoice; } {
    return (<Invoice>target.prototype) !== undefined;
}

export class InvoiceRegisterController extends GridFormService<Invoice> implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope', '$element'];
    private $element: JQLite;
    private $scope: IInvoiceScope;
    private OperationalService: OperationalService;
    private RestService: IRestService;
    private ModalService: IModalService;
    private selectedRows: Invoice[];
    private selectedContract: SelectorModel;
    private finopService: IFinopService;
    private sessionService: ISessionService;
    private negotiationController: InvoiceNegotiationController;
    public productService: ProductService;
    modalID: any;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IInvoiceScope, $element: JQLite) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$element = $element;
        this.OperationalService = $injector.get('OperationalService');
        this.RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');
        this.finopService = $injector.get('FinopService');
        this.sessionService = $injector.get('SessionService');
        this.productService = $injector.get('ProductService');
        this.modalID = null;
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            // grid
            this.$scope.gridTemplate = 'gridTemplate';
            //init form
            this.$scope.formOperation = 'Detalhes';
            this.selectedRows = [];
            this.selectedContract = null;
            this.$scope.selectedRows = [];
            this.$scope.selectedInvoices = [];

            this.$baseUrl = this.finopService.$route();

            this.initForm(this, 'form', 'invoice', 'Fatura', false);
            this.block();

            //enable multi row selection
            this.$gridService.setSelectable(true);

            //set max number of query terms
            this.$gridService.MaxQueryCriteria = 25;

            this.$scope.executeCellAction = this.executeCellAction.bind(this);

            // Limit to 50 selectable rows. After this limit callback will be executed
            this.$gridService.setSelectableRowsLimitCallback(25, (limitReached, selectedRowsLength) => {
                this.$scope.actionsDisabled = limitReached;
                const customBreadcrumbArea = this.$element.find('.breadcrum-custom-area');
                customBreadcrumbArea.toggleClass('ng-hide', !limitReached);
                customBreadcrumbArea.html(limitReached ? `<h4 class='selection-limit-reached'>Selection has exceeded the limit of 25 lines. You have ${selectedRowsLength} rows selected.</h4>` : '');
            });

            //init grid

            this.$gridService.$gridOptions.enableFullRowSelection = false;
            await this.initGrid('gridInvoice', '/invoice/list', true, true, 30000, true, true);
            this.$gridService.setBackgroundUpdate(1800000, [this.selectionReapply, this]);

            this.monitoringFilterChanges();
            //register grid multiple rows selection callback
            this.$gridService.$gridApi.selection.on.rowSelectionChanged(this.$scope, this.selectedRowCallback.bind(this));
            this.$gridService.$gridApi.selection.on.rowSelectionChangedBatch(this.$scope, this.selectedRowBatchCallback.bind(this));

            this.$scope.totalBRL = null;

            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.$scope.invoiceActionsMap = this.getInvoiceActionsMap();

            this.unblock();

        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();

        // terminate controller class and clear memory
        if (this.negotiationController) {
            this.negotiationController.onDestroy();
            this.negotiationController = null;
        }
        //$interval.cancel(updateDataTask);
    }

    executeCellAction(actionName: string, entity: any) {
        const actionToByPassDisableRule = ['groupSelectedInvoices', 'ungroupSelectedInvoices', 'lockGroupSelectedInvoices', 'unlockGroupSelectedInvoices']
        if (this.$scope.actionsDisabled && !actionToByPassDisableRule.includes(actionName)) {
            this.$formService.notifyInfo(`Selection has exceeded the limit of 50 lines`);
        } else if (this[actionName] != null && typeof this[actionName] === 'function') {
            this[actionName].call(this, entity);
        }
    }

    initGridColumns(columns) {
        const columnDefs: IMonacoColumnDef[] = new Array();

        // status
        const openinvoice = `<span ng-class="grid.appScope.actionsDisabled ? \'disabled\' : \'\'" ng-show="grid.appScope.showActionsSelected(row, \'openinvoice\');"><a ng-click="grid.appScope.executeCellAction(\'preInvoiceSelectedInvoices\', row.entity)" class="text-green" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.PRE_LOCK_INVOICE' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-circle-o"></i></a>&nbsp;&nbsp;</span>`;
        const preinvoice = `<span ng-class="grid.appScope.actionsDisabled ? \'disabled\' : \'\'" ng-show="grid.appScope.showActionsSelected(row, \'preinvoice\');"><a ng-click="grid.appScope.executeCellAction(\'openSelectedInvoices\', row.entity)" class="text-yellow" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.PRE_LOCKED_INVOICE' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-check-circle-o"></i></a>&nbsp;&nbsp;</span>`;
        const invoiced = `<span ng-class="grid.appScope.actionsDisabled ? \'disabled\' : \'\'" ng-show="grid.appScope.showActionsSelected(row, \'invoiced\');"><a style="cursor: default;" class="text-danger" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOCKED_INVOICE' | translate }}" tooltip-append-to-body="true"><i class="fa fa-circle"></i></a>&nbsp;&nbsp;</span>`;
        const lock = `<span ng-class="grid.appScope.actionsDisabled ? \'disabled\' : \'\'" ng-show="grid.appScope.showActionsSelected(row, \'lock\');"><a ng-click="grid.appScope.executeCellAction(\'lockInvoice\', row.entity)" class="text-rouge" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOCK' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-unlock icon"></i></a>&nbsp;&nbsp;</span>`;
        const unlock = `<span ng-class="grid.appScope.actionsDisabled ? \'disabled\' : \'\'" ng-show="grid.appScope.showActionsSelected(row, \'unlock\');" style="margin-right: 4px;"><a ng-click="grid.appScope.executeCellAction(\'unlockInvoice\', row.entity)" class="text-rouge" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.UNLOCK' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-lock icon"></i></a>&nbsp;&nbsp;</span>`;
        const lockGroup = `<span ng-class="grid.appScope.actionsDisabled ? \'disabled\' : \'\'" ng-show="grid.appScope.showActionsSelected(row, \'lockGroup\');"><a ng-click="grid.appScope.executeCellAction(\'lockGroupSelectedInvoices\')" class="text-green" tooltip-placement="auto top" uib-tooltip="{{ 'FINANCIAL.GROUP_LOCK' | translate }}" tooltip-append-to-body="true"><i class="fa fa-link icon"></i></a>&nbsp;&nbsp;</span>`;
        const lockUngroup = `<span ng-class="grid.appScope.actionsDisabled ? \'disabled\' : \'\'" ng-show="grid.appScope.showActionsSelected(row, \'lockUngroup\');"><a ng-click="grid.appScope.executeCellAction(\'unlockGroupSelectedInvoices\', row.entity)" class="text-ouro" tooltip-placement="auto top" uib-tooltip="{{ 'FINANCIAL.UNGROUP' | translate}} {{ row.entity.LOCKED_UUID }}" tooltip-append-to-body="true"><i class="fa fa-chain-broken"></i></a>&nbsp;&nbsp;</span>`;
        // actions
        const file = `<span ng-class="grid.appScope.actionsDisabled ? \'disabled\' : \'\'" ng-show="grid.appScope.showActionsSelected(row, \'file\');"><a ng-click="grid.appScope.executeCellAction(\'addFileReference\', row.entity)" class="text-brown" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.ADD_ATTACHMENT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-paperclip icon"></i></a>&nbsp;&nbsp;</span>`;
        const contract = `<span ng-class="grid.appScope.actionsDisabled ? \'disabled\' : \'\'" ng-show="grid.appScope.showActionsSelected(row, \'contract\');"><a ng-click="grid.appScope.executeCellAction(\'contractModal\', row.entity)" class="text-info"tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT_FINANCIAL_CONTRACT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-file-text-o icon"></i></a>&nbsp;&nbsp;</span>`;
        const account = `<span ng-class="grid.appScope.actionsDisabled ? \'disabled\' : \'\'" ng-show="grid.appScope.showActionsSelected(row, \'account\');"><a ng-click="grid.appScope.executeCellAction(\'accountModal\', row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT_CHECKING_ACCOUNT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-address-card-o"></i></a>&nbsp;&nbsp;</span>`;
        const update = `<span ng-class="grid.appScope.actionsDisabled ? \'disabled\' : \'\'" ng-show="grid.appScope.showActionsSelected(row, \'update\');"><a ng-click="grid.appScope.executeCellAction(\'updateSelectedInvoices\', row.entity)" class="text-gray" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.RECALCULATE' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-calculator icon" aria-hidden="true"></i></a>&nbsp;&nbsp;</span>`;
        const negotiate = `<span ng-class="grid.appScope.actionsDisabled ? \'disabled\' : \'\'" ng-show="grid.appScope.showActionsSelected(row, \'negotiate\');"><a ng-click="grid.appScope.executeCellAction(\'invokeNegotiation\', row.entity)" class="text-bronze" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.NEGOTIATE' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-handshake-o icon"></i></a>&nbsp;&nbsp;</span>`;
        const integration = `<span ng-class="grid.appScope.actionsDisabled ? \'disabled\' : \'\'" ng-show="grid.appScope.showActionsSelected(row, \'integration\');"><a ng-click="grid.appScope.executeCellAction(\'updateInvoiceIntegration\', row.entity)" class="text-cyano" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.UPDATE_INTEGRATION_HC2' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh"></i></a>&nbsp;&nbsp;</span>`;
        const group = `<span ng-show="grid.appScope.showActionsSelected(row, \'group\');"><a ng-click="grid.appScope.executeCellAction(\'groupSelectedInvoices\')" class="text-green" tooltip-placement="auto top" uib-tooltip="{{ 'REGISTRATION.AGROUP' | translate }}" tooltip-append-to-body="true"><i class="fa fa-link icon"></i></a>&nbsp;&nbsp;</span>`;
        const ungroup = `<span ng-show="grid.appScope.showActionsSelected(row, \'ungroup\');"><a ng-click="grid.appScope.executeCellAction(\'ungroupSelectedInvoices\', row.entity)" class="text-ouro" tooltip-placement="auto top" uib-tooltip="{{ 'FINANCIAL.UNGROUP' | translate }} {{ row.entity.UNIFICATION }}" tooltip-append-to-body="true"><i class="fa fa-chain-broken"></i></a>&nbsp;&nbsp;</span>`;
        const instructions = `<span ng-class="grid.appScope.actionsDisabled ? \'disabled\' : \'\'" ng-show="grid.appScope.showActionsSelected(row, \'instructions\');"><a ng-click="grid.appScope.executeCellAction(\'instructionModal\', row.entity)" class="text-dark" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.INSTRUCTIONS' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-sliders icon"></i></a>&nbsp;&nbsp;</span>`;
        const exchangeContract = `<span ng-class="grid.appScope.actionsDisabled ? \'disabled\' : \'\'" ng-show="grid.appScope.showActionsSelected(row, \'exchangeContract\');"><a ng-click="grid.appScope.executeCellAction(\'exchangeContractModal\', row.entity)" class="text-blue" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.REMITTANCE' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-line-chart"></i></a>&nbsp;&nbsp;</span>`;
        const history = `<span ng-class="grid.appScope.actionsDisabled ? \'disabled\' : \'\'" ng-show="true"><a ng-click="grid.appScope.executeCellAction(\'viewLog\', row.entity)" class="text-green" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;</span>`;
        //
        const denied = `<span ng-class="grid.appScope.actionsDisabled ? \'disabled\' : \'\'" ng-show="grid.appScope.showActionsSelected(row, \'denied\');"><a class="text-danger" tooltip-placement="auto top" uib-tooltip="{{ 'FINANCIAL.NO_GROUP_ACTIONS' | translate }}" tooltip-append-to-body="true"><i class="fa fa-ban icon"></i></a></span>`;
        const comment = `<span ng-class="grid.appScope.actionsDisabled ? \'disabled\' : \'\'" ng-show="true"><a ng-click="grid.appScope.executeCellAction(\'commentModal\', row.entity)" class="text-black" tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.COMMENT' | translate }}" tooltip-append-to-body="true"><i class="fa fa-commenting-o"></i></a></span>`;

        const status =
            `<div class="text-center">
            ${openinvoice}
            ${preinvoice}
            ${invoiced}
            ${lock}
            ${unlock}
            ${lockGroup}
            ${lockUngroup}
        </div>`;

        const actions =
            `<div class="text-center pull-left" style="padding-left: 10px;">
            ${file}
            ${update}
            ${contract}
            ${account}
            ${negotiate}
            ${instructions}
            ${exchangeContract}
            ${integration}
            ${group}
            ${ungroup}
            ${denied}
            ${history}
            ${comment}
        </div>`;

        const actionsColDef: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 210,
            maxWidth: 210,
            cellTemplate: (actions),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            allowCellFocus: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true,
            visible: true,
            partialCriteria: false,
        }
        columnDefs.push(actionsColDef);

        const statusColDef: IMonacoColumnDef = {
            name: "invoiceacoes",
            displayName: "",
            minWidth: 75,
            maxWidth: 75,
            cellTemplate: (status),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            allowCellFocus: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true,
            visible: true,
            partialCriteria: false,
        }
        columnDefs.push(statusColDef);
        this.$gridService.addActionColumn(statusColDef.name);

        const NewColumnDefs = this.buildColunms(columns);
        for (let column of NewColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; columnDefs.push(column) }
        return columnDefs;
    }

    buildColunms(column, prop?): any {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            for (var property in column) {
                if (typeof (column[property]) === 'string') { //finally reached the bottom
                    let currentProperty = (prop) ? `${prop}.${column[property]}` : column[property];
                    let name = null;
                    let displayName = null;
                    let width = 0;
                    let cellTemplate = undefined;
                    let cellFilter = null;
                    let grouping = null;
                    let sort = null;
                    let pinned = null;
                    let visible = true;
                    let field = null;
                    let filter: uiGrid.IFilterOptions = null;
                    let searchProps = null;
                    let partialCriteria = false;
                    let arrayProperty = null;

                    switch (currentProperty.toUpperCase()) {
                        case 'INVOICE_NUMBER':
                            name = currentProperty;
                            displayName = 'GENERAL.INVOICES';
                            width = currentProperty.length / 1.5;
                            break;
                        case 'PROCESS_NUMBER':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.FILE_NUMBER';
                            width = currentProperty.length - 4;
                            cellTemplate = `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.PROCESS_VIEW' | translate }} {{row.entity.PROCESS_NUMBER}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToProcess(row.entity.PROCESS_NUMBER)">{{row.entity.PROCESS_NUMBER}}</a><span class="label label-danger" ng-if="row.entity.PROCESS.PRIORITY.ID == \'2\'"><i class="text-warning fa fa-bolt"></i></span></div>`;
                            break;
                        case 'PROCESS.CARGO_DETAIL.QUANTITY':
                            name = currentProperty;
                            displayName = 'GENERAL.CHARGEABLE_WEIGHT';
                            width = currentProperty.length - 19;
                            break;
                        case 'PROCESS.CARGO_DETAIL.OVERTAXED':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.OVER_CHARGEABLE_WEIGHT';
                            width = currentProperty.length - 21;
                            break;
                        case 'PROCESS.CARGO_DETAIL.GROSS_WEIGHT':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.GROSS_WEIGHT';
                            width = currentProperty.length - 23;
                            break;
                        case 'PROCESS.CARGO_DETAIL.CBM':
                            name = currentProperty;
                            displayName = 'GENERAL.CUBIC_METER';
                            width = currentProperty.length - 16;
                            break;
                        case 'PROCESS.CARGO_DETAIL.VOLUME':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.TOTAL_PIECES';
                            width = currentProperty.length - 20;
                            break;
                        case 'PEOPLE':
                            name = currentProperty + '.NAME';
                            displayName = 'GENERAL.HOLDER';
                            width = currentProperty.length + 9;
                            break;
                        case 'NATURE':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.TRANSACTION';
                            width = currentProperty.length + 2;
                            break;
                        case 'STATUS':
                            name = currentProperty + '.NAME';
                            displayName = 'FINANCIAL.BILLING_STATUS';
                            width = currentProperty.length + 4;
                            break;
                        case 'PROCESS.PRODUCT':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.PRODUCT';
                            width = currentProperty.length - 4;
                            break;
                        case 'PROCESS.COMMERCIAL_UNITY':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.COMMERCIAL_BRANCH';
                            width = currentProperty.length - 13;
                            break;
                        case 'PROCESS.BRANCH':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.BRANCH';
                            width = currentProperty.length - 1;
                            break;
                        case 'FINANCIAL_SITUATION':
                            name = currentProperty + '.NAME';
                            displayName = 'FINANCIAL.FINANCIAL_STATUS';
                            width = currentProperty.length + 4;
                            break;
                        case 'CONTRACT_NUMBER':
                            name = currentProperty;
                            field = `${currentProperty}`;
                            displayName = 'OPERATIONAL.FINANCIAL_CONTRACT';
                            width = currentProperty.length / 1.5;
                            filter = { condition: this.$gridService.filterSelectObject };
                            cellTemplate = `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{ 'FINANCIAL.OPEN_FINANCIAL_CONTRACT' | translate }} {{row.entity.CONTRACT_NUMBER}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToContract(row.entity.CONTRACT_NUMBER)">{{row.entity.CONTRACT_NUMBER}}</a></div>`;
                            break;
                        case 'LOCKED':
                            name = currentProperty;
                            displayName = 'FINANCIAL.LOCKED';
                            cellTemplate = `<div class="ui-grid-cell-contents">{{ (row.entity.LOCKED) ? 'GENERAL.YES' : 'GENERAL.NO' | translate }}</div>`;
                            width = currentProperty.length;
                            filter = { condition: this.$gridService.filterSelectObject };
                            break;
                        case 'LOCKED_DATE':
                            name = currentProperty;
                            displayName = 'FINANCIAL.ISSUE_DATE';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length;
                            break;
                        case 'APPROVAL_DATE':
                            name = currentProperty;
                            displayName = 'FINANCIAL.PROVIDER_INVOICE_APPROVAL';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length;
                            break;
                        case 'THIRDPARTY_DATE':
                            name = currentProperty;
                            displayName = 'FINANCIAL.PROVIDER_INVOICE_ISSUE';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROVIDER_INVOICE':
                            name = currentProperty;
                            displayName = 'FINANCIAL.PROVIDER_INVOICE';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'TYPED_NUMBER':
                            name = currentProperty;
                            displayName = 'FINANCIAL.BAR_CODE';
                            width = currentProperty.length;
                            visible = true;
                            break;
                        case 'PAYMENT_DATE':
                            name = currentProperty;
                            displayName = 'FINANCIAL.PAYMENT_INSTRUCIONS';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length;
                            visible = true;
                            break;
                        case 'NEGOTIATED':
                            name = currentProperty;
                            displayName = 'FINANCIAL.NEGOTIATED';
                            cellTemplate = `<div class="ui-grid-cell-contents">{{ (row.entity.NEGOTIATION) ? 'GENERAL.YES' : 'GENERAL.NO' | translate }}</div>`;
                            width = currentProperty.length;
                            filter = { condition: this.$gridService.filterSelectObject };
                            break;
                        case 'NEGOTIATED':
                            name = currentProperty;
                            displayName = 'FINANCIAL.NEGOTIATED';
                            cellTemplate = `<div class="ui-grid-cell-contents">{{ (row.entity.NEGOTIATION) ? 'GENERAL.YES' : 'GENERAL.NO' | translate }}</div>`;
                            width = currentProperty.length;
                            filter = { condition: this.$gridService.filterSelectObject };
                            break;
                        case 'TOTAL':
                            name = currentProperty;
                            displayName = 'FINANCIAL.ACTUAL_TOTAL_AMOUNT';
                            cellTemplate = '<div class="ui-grid-cell-contents">{{row.entity.TOTAL | number: 2}}</div>';
                            width = currentProperty.length + 2;
                            break;
                        case 'RESIDUAL_VALUE':
                            name = currentProperty;
                            displayName = 'FINANCIAL.RESIDUAL';
                            cellTemplate = '<div class="ui-grid-cell-contents">{{row.entity.RESIDUAL_VALUE | number: 2}}</div>';
                            width = currentProperty.length + 2;
                            break;
                        case 'ADDITION_VALUE':
                            name = currentProperty;
                            displayName = 'FINANCIAL.INCREASE';
                            cellTemplate = '<div class="ui-grid-cell-contents">{{row.entity.ADDITION_VALUE | number: 2}}</div>';
                            width = currentProperty.length + 2;
                            break;
                        case 'DISCOUNT_VALUE':
                            name = currentProperty;
                            displayName = 'FINANCIAL.DISCOUNT';
                            cellTemplate = '<div class="ui-grid-cell-contents">{{row.entity.DISCOUNT_VALUE | number: 2}}</div>';
                            width = currentProperty.length + 2;
                            break;
                        case 'ORIGINAL_TOTAL':
                            name = currentProperty;
                            displayName = 'FINANCIAL.TOTAL_AMOUNT';
                            cellTemplate = '<div class="ui-grid-cell-contents">{{row.entity.ORIGINAL_TOTAL | number: 2}}</div>';
                            width = currentProperty.length;
                            break;
                        case 'PARTIAL_PAYMENT':
                            name = currentProperty;
                            displayName = 'FINANCIAL.PARTIAL_PAYMENT';
                            cellTemplate = '<div class="ui-grid-cell-contents">{{row.entity.PARTIAL_PAYMENT | number: 2}}</div>';
                            width = currentProperty.length;
                            break;
                        case 'DUE_DATE':
                            name = currentProperty;
                            displayName = 'FINANCIAL.DUE_DATE';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length + 2;
                            break;
                        case 'LIQUIDATION_DATE':
                            name = currentProperty;
                            displayName = 'FINANCIAL.RECEIPT_ACKNOWLEDGEMENT_DATE';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length + 2;
                            break;
                        case 'PAYMENT_PERIOD':
                            name = currentProperty + '.NAME';
                            displayName = 'FINANCIAL.PAYMENT_PERIOD';
                            width = currentProperty.length + 4;
                            break;
                        case 'PAYMENT_METHOD':
                            name = currentProperty + '.NAME';
                            displayName = 'FINANCIAL.PAYMENT_MEANS';
                            width = currentProperty.length + 4;
                            break;
                        case 'PAYMENT_MODALITY':
                            name = currentProperty + '.NAME';
                            displayName = 'FINANCIAL.PAYMENT_METHOD';
                            width = currentProperty.length + 1;
                            break;
                        case 'CLIENT_ACCOUNT':
                            name = currentProperty + '.ACCOUNT.NAME';
                            displayName = 'ENTITY.CHECKING_ACCOUNT';
                            width = currentProperty.length + 4;
                            break;
                        case 'TRANSACTION':
                            name = currentProperty + ".NAME";
                            searchProps = [`${currentProperty}.NAME`];
                            field = currentProperty;
                            arrayProperty = 'NAME';
                            cellTemplate = '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.TRANSACTION, null, "NAME", null, true)}}</div>';
                            displayName = 'GENERAL.HOLDER_TYPE';
                            width = currentProperty.length + 4;
                            break;
                        case 'INVOICE_CURRENCIES_FACTORS':
                            name = currentProperty;
                            displayName = 'FINANCIAL.EXCHANGE_RATE_PER_CURRENCY';
                            width = currentProperty.length;
                            break;
                        case 'INVOICE_CURRENCIES_VALUES':
                            name = currentProperty;
                            displayName = 'FINANCIAL.ORIGINAL_AMOUNT_PER_CURRENCY';
                            width = currentProperty.length;
                            break;
                        case 'OBSERVATION':
                            name = currentProperty;
                            displayName = 'GENERAL.REMARKS';
                            width = currentProperty.length + 11;
                            visible = false;
                            break;
                        case 'HC2_FINANCIAL_RECORD':
                            name = currentProperty;
                            displayName = 'REGISTRATION.HC2_INTEGRATION_REF';
                            width = currentProperty.length + 1;
                            visible = false;
                            break;
                        case 'UNIFICATION':
                            name = currentProperty;
                            displayName = 'REGISTRATION.HC2_LOT_RECORD';
                            width = currentProperty.length + 1;
                            break;
                        case 'LOCKED_UUID':
                            name = currentProperty;
                            displayName = 'REGISTRATION.UNIFIED_LOCKED';
                            width = currentProperty.length + 1;
                            break;
                        case 'LOG':
                            name = currentProperty;
                            displayName = 'Log';
                            width = currentProperty.length + 1;
                            visible = false;
                            break;
                        case 'HC2_INTEGRATION_ERRORS':
                            name = currentProperty + '.DESCRIPTION';
                            displayName = 'REGISTRATION.INTEGRATION_ERROR_HC2';
                            width = currentProperty.length + 20;
                            visible = true;
                            break;
                        case 'ERP_INTEGRATION_STATUS':
                            name = currentProperty + '.STATUS.NAME';
                            displayName = 'REGISTRATION.INTEGRATION_STATUS';
                            width = (currentProperty.length / 2) + 3;
                            visible = true;
                            break;
                        case 'CHARGE':
                            searchProps = [`${currentProperty}.ERP_INTEGRATION_STATUS.STATUS.NAME`, `${currentProperty}.ERP_INTEGRATION_STATUS.MESSAGE`];
                            cellTemplate = '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.CHARGE, "ERP_INTEGRATION_STATUS", "MESSAGE", null, true)}}</div>';
                            width = currentProperty.length + 20;
                            displayName = 'REGISTRATION.INTEGRATION_CHARGES_STATUS';
                            name = currentProperty + '.ERP_INTEGRATION_STATUS.MESSAGE';
                            field = currentProperty + '.ERP_INTEGRATION_STATUS.MESSAGE';
                            arrayProperty = "ERP_INTEGRATION_STATUS.MESSAGE";
                            visible = true;
                            break;
                        case 'INTEGRATION_DIRECTION':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.INTEGRATION_DIRECTION';
                            width = (currentProperty.length / 2) + 3;
                            visible = true;
                            break;
                        case 'DOCUMENT_ERROR':
                            searchProps = [`${currentProperty}.SUBJECT.NAME`, `${currentProperty}.REASON`];
                            cellTemplate = '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.DOCUMENT_ERROR, null, "REASON", null, true)}}</div>';
                            width = currentProperty.length + 20;
                            displayName = 'REGISTRATION.INTEGRATION_ERROR';
                            name = currentProperty + '.ERP_INTEGRATION_STATUS.MESSAGE';
                            field = currentProperty;
                            arrayProperty =  "REASON"
                            visible = true;
                            break;
                        case 'ERP_TOP_HISTORY':
                            searchProps = [`${currentProperty}.CODE`];
                            cellTemplate = '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.ERP_TOP_HISTORY, null, "CODE", null, true)}}</div>';
                            width = currentProperty.length + 20;
                            displayName = 'FINANCIAL.SANKHYA_TOP';
                            name = currentProperty + '.CODE';
                            field = currentProperty;
                            arrayProperty = "CODE";
                            visible = true;
                            break;
                        //INVOICE - COMPOSITION (CONTRACT)
                        case 'COMPOSITION.PAYMENT_CONDITION':
                            name = currentProperty + '.NAME';
                            displayName = 'GENERAL.INVOICE_PAYMENT_TERM';
                            width = currentProperty.length / 2;
                            visible = false;
                            break;
                        case 'COMPOSITION.DUE_DATE_REFERENCE':
                            name = currentProperty + '.NAME';
                            displayName = 'FINANCIAL.PAYMENT_TERMS_REFERENCE';
                            width = currentProperty.length / 2;
                            visible = false;
                            break;
                        case 'COMPOSITION.DUE_DATE_D0':
                            name = currentProperty;
                            displayName = 'FINANCIAL.PAYMENT_TERM_DAYS';
                            width = (currentProperty.length / 2) + 2;
                            visible = false;
                            break;
                        case 'COMPOSITION.PREFERENCE_TYPE':
                            name = currentProperty + '.NAME';
                            displayName = 'FINANCIAL.PREFERENCE_TYPE';
                            width = (currentProperty.length / 2) + 3;
                            visible = false;
                            break;
                        case 'COMPOSITION.PREFERENCE':
                            name = currentProperty;
                            displayName = 'FINANCIAL.PREFERENCE';
                            cellTemplate = '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.COMPOSITION.PREFERENCE)}}</div>';
                            width = currentProperty.length + 4;
                            visible = false;
                            field = currentProperty;
                            arrayProperty = "COMPOSITION.PREFERENCE";
                            break;
                        case 'COMPOSITION.PREFERENCE_PARAMETERIZATION':
                            name = currentProperty + '.NAME';
                            displayName = 'FINANCIAL.PREFERENCE_SETTING';
                            width = currentProperty.length / 2;
                            visible = false;
                            break;
                        case 'COMPOSITION.PERIOD_VARIATION':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.PERIOD_VARIATION';
                            width = currentProperty.length / 2;
                            visible = false;
                            break;
                        case 'COMPOSITION.CONVERSION_REFERENCE':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.EXC_RATE_REFERENCE_DATE';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'COMPOSITION.CURRENCY_CONVERSION':
                            name = currentProperty + '.NAME';
                            displayName = 'GENERAL.EXCHANGE_RATE_INDEX';
                            width = (currentProperty.length / 3) + 3;
                            visible = false;
                            break;
                        case 'COMPOSITION.BASE':
                            name = currentProperty;
                            displayName = 'FINANCIAL.BASE';
                            width = currentProperty.length + 2;
                            visible = false;
                            break;
                        case 'COMPOSITION.SPREAD':
                            name = currentProperty;
                            displayName = 'FINANCIAL.SPREAD';
                            width = currentProperty.length / 2;
                            visible = false;
                            break;
                        case 'COMPOSITION.PAYMENT_COMPROMISE':
                            name = currentProperty + '.NAME';
                            displayName = 'FINANCIAL.PAYMENT_AGREEMENT';
                            width = currentProperty.length - 4;
                            visible = false;
                            break;
                        case 'COMPOSITION.OBSERVATION':
                            name = currentProperty;
                            displayName = 'GENERAL.REMARKS';
                            width = currentProperty.length + 15;
                            visible = false;
                            break;
                        case 'CONTRACT_COMPOSITION.ANTICIPATED_INVOICE':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.EARLY_RECEIPT';
                            width = currentProperty.length / 2;
                            visible = false;
                            break;
                        // INVOICE - EXCHANGE_CONTRACT
                        case 'EXCHANGE_CONTRACT.INVOICE':
                            name = currentProperty;
                            displayName = 'FINANCIAL.INTERNATIONAL_REMITTANCE_INVOICE';
                            width = currentProperty.length + 2;
                            visible = false;
                            break;
                        case 'EXCHANGE_CONTRACT.CONTRACT':
                            name = currentProperty;
                            displayName = 'FINANCIAL.INTERNATIONAL_REMITTANCE_CONTRACT';
                            width = currentProperty.length + 2;
                            visible = false;
                            break;
                        case 'EXCHANGE_CONTRACT.COUNTRY':
                            name = currentProperty + '.NAME';
                            displayName = 'FINANCIAL.INTERNATIONAL_REMITTANCE_COUNTRY';
                            width = currentProperty.length + 2;
                            visible = false;
                            break;
                        case 'EXCHANGE_CONTRACT.CHARGE_CODE':
                            name = currentProperty + '.NAME';
                            displayName = 'FINANCIAL.INTERNATIONAL_REMITTANCE_TRANSACTION';
                            width = currentProperty.length + 2;
                            visible = false;
                            break;
                        case 'EXCHANGE_CONTRACT.EXCHANGE':
                            name = currentProperty + '.NAME';
                            displayName = 'FINANCIAL.INTERNATIONAL_REMITTANCE_METHOD';
                            width = currentProperty.length + 2;
                            visible = false;
                            break;
                        case 'EXCHANGE_CONTRACT.BROKER':
                            name = currentProperty + '.NAME';
                            displayName = 'FINANCIAL.INTERNATIONAL_REMITTANCE_BANK';
                            width = currentProperty.length + 2;
                            visible = false;
                            break;
                        case 'EXCHANGE_CONTRACT.DATE_SHIPMENT':
                            name = currentProperty;
                            displayName = 'FINANCIAL.INTERNATIONAL_REMITTANCE_DATE';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length + 2;
                            visible = false;
                            break;
                        case 'EXCHANGE_CONTRACT.DATE_ORDER':
                            name = currentProperty;
                            displayName = 'FINANCIAL.INTERNATIONAL_REMITTANCE_ORDER_DATE';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length + 2;
                            visible = false;
                            break;
                        case 'EXCHANGE_CONTRACT.LOCK_CONTRACT_NUMBER':
                            name = currentProperty;
                            displayName = 'FINANCIAL.EXCHANGE_LOCK_CONTRACT';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'EXCHANGE_CONTRACT.PAYMENT_REQUEST':
                            name = currentProperty;
                            displayName = 'FINANCIAL.PAYMENT_REQUEST';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'EXCHANGE_CONTRACT.LOCK_DATE':
                            name = currentProperty;
                            displayName = 'FINANCIAL.EXCHANGE_LOCK_DATE';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'EXCHANGE_CONTRACT.UNLOCK_DATE':
                            name = currentProperty;
                            displayName = 'FINANCIAL.EXCHANGE_UNLOCK_DATE';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'CURRENCIES.ORIGINAL.EXCHANGE_RATE.DATE':
                            name = currentProperty;
                            displayName = 'FINANCIAL.CONVERSTION_DATE';
                            cellTemplate = '<div class="grid-padding" >{{grid.appScope.concatConversionDate(row.entity)}}</div>';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        // PROCESS
                        case 'PROCESS.DELIVERY_DATE':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.DELIVERY_DATE';
                            width = currentProperty.length / 2;
                            cellFilter = "date:'dd/MM/yyyy'"
                            visible = false;
                            break;
                        case 'PROCESS.LOAD_TRANSPORT_MODE':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.VESSEL';
                            width = currentProperty.length / 3;
                            visible = false;
                            break;
                        case 'PROCESS.FORWARDING_SITUATION.SITUATION':
                            name = currentProperty + '.NAME';
                            displayName = 'OPERATIONAL.FILE_STATUS';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.FORWARDING_SITUATION.DATE':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.FILE_STATUS_DATE';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.PAYMENT_SITUATION.SITUATION':
                            name = currentProperty + '.NAME';
                            displayName = 'FINANCIAL.PAYMENT_STATUS';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.PAYMENT_SITUATION.DATE':
                            name = currentProperty;
                            displayName = 'FINANCIAL.PAYMENT_DATE';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.RECEIPT_SITUATION.SITUATION':
                            name = currentProperty + '.NAME';
                            displayName = 'FINANCIAL.RECEIPT_SITUATION';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.RECEIPT_SITUATION.DATE':
                            name = currentProperty;
                            displayName = 'FINANCIAL.RECEIPT_DATE';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.AGENT_SETTLEMENT_SITUATION.SITUATION':
                            name = currentProperty + '.NAME';
                            displayName = 'FINANCIAL.AGENT_SETTLEMENT_STATUS';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.AGENT_SETTLEMENT_SITUATION.DATE':
                            name = currentProperty;
                            displayName = 'FINANCIAL.AGENT_SETTLEMENT_DATE';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.PRIORITY':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.PRIORITY';
                            width = currentProperty.length + 6;
                            visible = false;
                            break;
                        case 'PROCESS.CUSTOMER':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.CLIENT';
                            width = currentProperty.length + 6;
                            visible = false;
                            break;
                        case 'ID_KESTRAA_INTEGRATION':
                            name = currentProperty;
                            displayName = 'FINANCIAL.KESTRAA_ID';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.CUSTOMER_QUALIFICATION':
                            name = currentProperty + '.NAME';
                            displayName = 'GENERAL.CLIENT_QUALIFICATION';
                            width = currentProperty.length + 6;
                            visible = false;
                            break;
                        case 'PROCESS.LOCAL_AGENT':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.LOCAL_AGENT';
                            width = currentProperty.length + 6;
                            visible = false;
                            break;
                        case 'PROCESS.EXTERNAL_AGENT':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.OVERSEAS_AGENT';
                            width = currentProperty.length + 6;
                            visible = false;
                            break;
                        case 'PROCESS.EXPORTER':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.SHIPPER';
                            width = currentProperty.length + 6;
                            visible = false;
                            break;
                        case 'PROCESS.IMPORTER':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.CONSIGNEE';
                            width = currentProperty.length + 6;
                            visible = false;
                            break;
                        case 'PROCESS.NOTIFY':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.NOTIFY';
                            width = currentProperty.length + 6;
                            visible = false;
                            break;
                        case 'PROCESS.HOUSE_PAYMENT':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.HOUSE_PAYMENT_MODE';
                            width = currentProperty.length + 1;
                            visible = false;
                            break;
                        case 'PROCESS.MASTER_PAYMENT':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.MASTER_PAYMENT_MODE';
                            width = currentProperty.length + 1;
                            visible = false;
                            break;
                        case 'PROCESS.SITUATION':
                            name = currentProperty + '.NAME';
                            displayName = 'OPERATIONAL.LOGISTIC_STATUS';
                            width = currentProperty.length + 4;
                            visible = false;
                            break;
                        case 'PROCESS.BOOKING':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.BOOKING';
                            width = currentProperty.length + 2;
                            cellTemplate = `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{ 'REGISTRATION.CLICK_TO_VIEW_BOOKING' | translate }} {{row.entity.PROCESS.BOOKING}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToProcess(row.entity.PROCESS_NUMBER)">{{row.entity.PROCESS.BOOKING}}</a></div>`;
                            visible = false;
                            break;
                        case 'PROCESS.LOAD_DATE':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.ACTUAL_LOADING_DATE';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length + 2;
                            visible = false;
                            break;
                        case 'PROCESS.DISCHARGE_DATE':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.ACTUAL_DISCHARGE_DATE';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length + 2;
                            visible = false;
                            break;
                        case 'PROCESS.CLIENT_REFERENCE':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.CLIENT_REFERENCE';
                            width = currentProperty.length + 2;
                            visible = false;
                            break;
                        case 'PROCESS.DOCUMENT_MBL':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.MASTER_BL';
                            width = currentProperty.length + 2;
                            partialCriteria = true;
                            break;
                        case 'PROCESS.DOCUMENT_HBL':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.HOUSE_BL';
                            width = currentProperty.length + 2;
                            visible = false;
                            break;
                        case 'PROCESS.DOCUMENT_MASTER_EMISSION_TYPE':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.MASTER_ISSUE_METHOD';
                            width = currentProperty.length + 2;
                            visible = false;
                            break;
                        case 'PROCESS.DOCUMENT_MASTER_LOCAL_ISSUEANCE':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.MASTER_ISSUE_PLACE';
                            width = currentProperty.length + 2;
                            visible = false;
                            break;
                        case 'PROCESS.DOCUMENT_MASTER_SENT_DATE':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.RELEASE_DATE';
                            width = currentProperty.length + 2;
                            visible = false;
                            break;
                        case 'PROCESS.DOCUMENT_MASTER_ISSUEANCE':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.ISSUANCE_DATE';
                            cellFilter = 'date:\'dd/MM/yyyy HH:mm:ss\'';
                            width = currentProperty.length / 3;
                            visible = true
                            break;
                        case 'PROCESS.MAIN_ORIGIN':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.ORIGIN';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.MAIN_DESTINATION':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.DESTINATION';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.SERVICE_PROVIDER':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.PROVIDER';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.INCOTERM':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.INCOTERM';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.AGENT_REFERENCE':
                            name = currentProperty;
                            displayName = 'REGISTRATION.AGENT_REFERENCE';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.MASTER_DIRECT':
                            name = currentProperty;
                            displayName = 'BASIC_DATA.STRAIGHT_BL';
                            cellTemplate = `<div class="ui-grid-cell-contents">{{ (row.entity.PROCESS.MASTER_DIRECT) ? 'GENERAL.YES' : 'GENERAL.NO' | translate }}</div>`;
                            width = currentProperty.length;
                            filter = { condition: this.$gridService.filterSelectObject };
                            visible = false;
                            break;
                        case 'PROCESS.FINAL_DESTINATION':
                            name = currentProperty + '.DISPLAY_NAME';
                            displayName = 'ROUTE.FINAL_DESTINATION';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.DESTINATION_COUNTRY':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.DESTINATION_COUNTRY';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.SALES_PERSON':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.SALES_EXECUTIVE';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.INSIDE_SALES_PERSON':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.INSIDE_SALES';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.ESTIMATED_PROFIT':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.ESTIMATED_RESULT';
                            cellTemplate = '<div class="ui-grid-cell-contents">{{row.entity.PROCESS.ESTIMATED_PROFIT | number: 2}}</div>';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.EFFECTIVE_PROFIT':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.ACTUAL_RESULT';
                            cellTemplate = '<div class="ui-grid-cell-contents">{{row.entity.PROCESS.EFFECTIVE_PROFIT | number: 2}}</div>';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.EXPECTED_LOAD_DATE':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.ESTIMATED_LOADING_DATE';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.EXPECTED_PICK_UP_DATE':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.ESTIMATED_PICK_UP';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.PICK_UP_DATE':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.PICK_UP_DATE';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.EXPECTED_DELIVERY_DATE':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.ESTIMATED_DELIVERY_DATE';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.EXPECTED_DISCHARGE_DATE':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.ESTIMATED_DISCHARGE_DATE';
                            cellFilter = 'date:\'dd/MM/yyyy\'';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.AGENT_PAYMENT_CONDITION':
                            name = currentProperty;
                            displayName = 'FINANCIAL.AGENT_CONDITIONS';
                            cellTemplate = '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PROCESS.AGENT_PAYMENT_CONDITION, null, null, true)}}</div>';
                            width = currentProperty.length;
                            field = currentProperty;
                            arrayProperty = "NAME";
                            visible = false;
                            break;
                        case 'PROCESS.OUTCOME_PAYMENT_CONDITION':
                            name = currentProperty;
                            displayName = 'FINANCIAL.PAYMENT_CONDITIONS';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.INCOME_PAYMENT_CONDITION':
                            name = currentProperty;
                            displayName = 'FINANCIAL.RECEIPT_CONDITIONS';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'PROCESS.PROCESS_TYPE':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.FILE_TYPE';
                            width = currentProperty.length;
                            visible = false;
                            break;
                        case 'INVOICE_VALIDATION_EDI':
                            searchProps = [`${currentProperty}.ID`, `${currentProperty}.NAME`];
                            name = currentProperty;
                            displayName = 'GENERAL.INVOICE_VALIDATION_EDI';
                            cellTemplate = '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.INVOICE_VALIDATION_EDI)}}</div>';
                            field = currentProperty;
                            arrayProperty = "INVOICE_VALIDATION_EDI";
                            width = currentProperty.length + 4;
                            break;
                        case 'INVOICE_VALIDATION_EDI_NAME':
                            name = currentProperty;
                            displayName = 'GENERAL.INVOICE_VALIDATION_EDI_NAME'
                            cellTemplate = '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.INVOICE_VALIDATION_EDI_NAME, null)}}</div>';
                            width = currentProperty.length + 4;
                            field = currentProperty;
                            arrayProperty = "INVOICE_VALIDATION_EDI_NAME";
                            break;
                        case 'LAST_INTEGRATION_STATUS_DATE':
                            name = currentProperty;
                            displayName = 'FINANCIAL.LAST_INTEGRATION_STATUS_DATE';
                            cellFilter = 'date:\'dd/MM/yyyy HH:mm:ss\'';
                            width = currentProperty.length + 2;
                            break;
                        case 'COMMENT':
                            name = currentProperty;
                            displayName = 'OPERATIONAL.COMMENT';
                            width = currentProperty.length + 10;
                            break
                        case 'PROCESS.CARGO_TYPE':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.CARGO_TYPE';
                            width = currentProperty.length + 10;
                            break;
                    };

                    const newColumn: IMonacoColumnDef = {
                        name: name,
                        displayName: displayName,
                        headerCellClass: this.$gridService.highlightFilteredHeader.bind(this.$gridService),
                        width: width + '%',
                        cellTemplate: cellTemplate,
                        cellFilter: cellFilter,
                        visible: visible,
                        filter: filter,
                        searchProps: searchProps,
                        partialCriteria: partialCriteria,
                        arrayProperty: arrayProperty
                    };
                    if (field) newColumn.field = field;
                    if (name) columnDefs.push(newColumn);

                    // if People, add Network
                    if (name == "PEOPLE.NAME") {
                        const newColumn: IMonacoColumnDef = {
                            name: "PEOPLE.NETWORK.NAME",
                            displayName: "BASIC_DATA.NETWORK",
                            headerCellClass: this.$gridService.highlightFilteredHeader.bind(this.$gridService),
                            width: name.length + '%',
                            cellTemplate: cellTemplate,
                            cellFilter: cellFilter,
                            visible: visible,
                            filter: filter,
                            searchProps: searchProps,
                        };
                        if (field) newColumn.field = field;
                        if (name) columnDefs.push(newColumn);
                    }

                    // if erp_integration_param add message
                    if (name == "ERP_INTEGRATION_STATUS.STATUS.NAME") {
                        const newColumn: IMonacoColumnDef = {
                            name: "ERP_INTEGRATION_STATUS.MESSAGE",
                            displayName: "REGISTRATION.INTEGRATION_MESSAGE",
                            headerCellClass: this.$gridService.highlightFilteredHeader.bind(this.$gridService),
                            width: name.length + '%',
                            cellTemplate: cellTemplate,
                            cellFilter: cellFilter,
                            visible: visible,
                            filter: filter,
                            searchProps: searchProps,
                        };
                        if (field) newColumn.field = field;
                        if (name) columnDefs.push(newColumn);
                    }

                } else {
                    const composedPropPath = (prop) ? (isNaN(parseInt(property))) ? `${prop}.${property}` : prop : (isNaN(parseInt(property))) ? property : null;
                    const newCol = this.buildColunms(column[property], composedPropPath);
                    if (newCol)
                        for (let dados of newCol) {
                            columnDefs.push(dados);
                        }
                }
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    lockedSelected(): boolean {
        return (this.$scope.selectedRows.length != this.$scope.selectedInvoices.length);
    }

    initScopeFunctions(): void {
        this.$scope.showActionsSelected = (row: IGridRow, action: string) => {
            try {
                let show: boolean = false;
                const invoice: Invoice = row.entity;
                const unlockedInvoice: number = this.$scope.selectedInvoices.length;
                const selectedRows: number = this.$scope.selectedRows.length;

                const unified = (invoice.FINANCIAL_SITUATION && invoice.FINANCIAL_SITUATION.ID == '4');
                const sameUnified = this.$scope.selectedRows.filter(x => x.FINANCIAL_SITUATION.ID == '4').every(x => x.UNIFICATION == invoice.UNIFICATION);

                const firstPersonID = (unlockedInvoice > 0) ? (this.$scope.selectedInvoices[0].PEOPLE) ? this.$scope.selectedInvoices[0].PEOPLE.ID : null : (invoice.PEOPLE) ? invoice.PEOPLE.ID : null;
                const samePeople = (firstPersonID) ? (unlockedInvoice > 0) ? this.$scope.selectedInvoices.every(x => x.PEOPLE && x.PEOPLE.ID === firstPersonID) : true : false;

                const isSankhya = (invoice.INTEGRATION_DIRECTION && invoice.INTEGRATION_DIRECTION.ID == EIntegrateDirection.SANKHYA);
                const isSankhyaIntegrationOrderConfirmed = (invoice.ERP_INTEGRATION_STATUS && invoice.ERP_INTEGRATION_STATUS.STATUS && invoice.ERP_INTEGRATION_STATUS.STATUS.ID == EErpIntegrationStatus.ORDER_CONFIRMED);
                const isSankhyaIntegrationOrderPendingConfirmation = (invoice.ERP_INTEGRATION_STATUS && invoice.ERP_INTEGRATION_STATUS.STATUS && invoice.ERP_INTEGRATION_STATUS.STATUS.ID == EErpIntegrationStatus.PENDING_CONFIRM_ORDER);
                const isSankhyaIntegrationBillingConfirmed = (invoice.ERP_INTEGRATION_STATUS && invoice.ERP_INTEGRATION_STATUS.STATUS && invoice.ERP_INTEGRATION_STATUS.STATUS.ID == EErpIntegrationStatus.ORDER_CONFIRMED);
                const isSankhyaIntegrationBillingPendingConfirmation = (invoice.ERP_INTEGRATION_STATUS && invoice.ERP_INTEGRATION_STATUS.STATUS && invoice.ERP_INTEGRATION_STATUS.STATUS.ID == EErpIntegrationStatus.PENDING_CONFIRM_ORDER);
                const isSankhyaIntegrationOrderPendingUnificationMulti = (invoice.ERP_INTEGRATION_STATUS && invoice.ERP_INTEGRATION_STATUS.STATUS && invoice.ERP_INTEGRATION_STATUS.STATUS.ID == EErpIntegrationStatus.PENDING_UNIFY_MULTIPLE_INVOICE);
                const isSankhyaIntegrationOrderPendingUnification = (invoice.ERP_INTEGRATION_STATUS && invoice.ERP_INTEGRATION_STATUS.STATUS && invoice.ERP_INTEGRATION_STATUS.STATUS.ID == EErpIntegrationStatus.PENDING_UNIFY_INVOICE);
                const hasErrorDocumentSankhya = (invoice.DOCUMENT_ERROR && invoice.DOCUMENT_ERROR.length > 0)

                const invoiceStatusPendingIntegration = (invoice.STATUS && (invoice.STATUS.ID == EInvoiceStatus.PENDING_INTEGRATION_ORDER || invoice.STATUS.ID == EInvoiceStatus.PENDING_INTEGRATION_INVOICE));
                const invoiceCancelPendingIntegration = (invoice.ERP_INTEGRATION_STATUS && invoice.ERP_INTEGRATION_STATUS.STATUS && (invoice.ERP_INTEGRATION_STATUS.STATUS.ID == EErpIntegrationStatus.PENDING_CANCEL_INVOICE || invoice.ERP_INTEGRATION_STATUS.STATUS.ID == EErpIntegrationStatus.PENDING_CANCEL_ORDER));
                const invoiceStatusPendingLockGroup = (invoice.STATUS && invoice.STATUS.ID == EInvoiceStatus.PENDING_LOCK_GROUP);

                //if (invoice.FINANCIAL_SITUATION.ID == '2' || invoice.FINANCIAL_SITUATION.ID == '10' ) return false; //PAID OR CANCELLED, no actions
                if (this.lockedSelected() && (action != 'denied' && action != 'unlock' && action != 'group' && action != 'ungroup' && action != 'lockGroup' && action != 'lockUngroup' && action != 'file' && action != 'invoiced' && action != 'exchangeContract')) return false;
                if (action == 'file') {
                    show = (!invoiceStatusPendingIntegration && !invoiceCancelPendingIntegration && !invoiceStatusPendingLockGroup);
                } else if (action == 'contract') {
                    show = (!invoiceStatusPendingIntegration && !invoiceCancelPendingIntegration && !invoiceStatusPendingLockGroup && !invoice.LOCKED && ((unlockedInvoice <= 1) || (unlockedInvoice > 1 && row.isSelected)));
                    if (!show && isSankhya && (invoiceStatusPendingIntegration && hasErrorDocumentSankhya))
                        show = true;
                } else if (action == 'account') {
                    show = (samePeople && !this.lockedSelected() && !invoice.LOCKED && invoice.PEOPLE && ((unlockedInvoice <= 1) || (unlockedInvoice > 1 && row.isSelected)) && !invoiceCancelPendingIntegration)
                } else if (action == 'update') {
                    show = (!invoice.LOCKED && ((unlockedInvoice <= 1) || (unlockedInvoice > 1 && row.isSelected)) && !invoiceStatusPendingIntegration && !invoiceCancelPendingIntegration && !invoiceStatusPendingLockGroup);
                } else if (action == 'lock') {
                    show = (!invoice.LOCKED && ((unlockedInvoice <= 1) || (unlockedInvoice > 1 && row.isSelected)) && !invoiceStatusPendingIntegration && !invoiceCancelPendingIntegration && !invoiceStatusPendingLockGroup && !invoice.LOCKED_UUID); //show = (!invoice.LOCKED && selectedInvoice <= 1);
                    if (show && isSankhya && (isSankhyaIntegrationOrderConfirmed || isSankhyaIntegrationOrderPendingConfirmation || isSankhyaIntegrationBillingConfirmed || isSankhyaIntegrationBillingPendingConfirmation) && !invoiceStatusPendingLockGroup && !invoice.LOCKED_UUID)
                        show = true;
                } else if (action == 'unlock' || action == 'invoiced') {
                    // show = (invoice.LOCKED && !unified && ((selectedRows > 0 && row.isSelected) || (selectedRows == 0) || (selectedRows == unlockedInvoice)));
                    show = (invoice.LOCKED && this.$scope.selectedRows.every(x => x.LOCKED) && !invoiceStatusPendingIntegration && !invoiceCancelPendingIntegration && !isSankhyaIntegrationOrderPendingUnificationMulti && !invoiceStatusPendingLockGroup && !invoice.LOCKED_UUID); //show = (!invoice.LOCKED && selectedInvoice <= 1);
                    if (show && isSankhya && (isSankhyaIntegrationOrderConfirmed || isSankhyaIntegrationOrderPendingConfirmation || isSankhyaIntegrationBillingConfirmed || isSankhyaIntegrationBillingPendingConfirmation))
                        show = true;
                } else if (action == 'negotiate') {
                    show = (!invoice.LOCKED && ((unlockedInvoice <= 1) || (unlockedInvoice > 1 && row.isSelected)) && !invoiceStatusPendingIntegration && !invoiceCancelPendingIntegration);
                    if (!show && isSankhya && (invoiceStatusPendingIntegration && hasErrorDocumentSankhya))
                        show = true;
                } else if (action == 'instructions') {
                    show = (!invoice.LOCKED && ((unlockedInvoice <= 1) || (unlockedInvoice > 1 && row.isSelected)) && !invoiceCancelPendingIntegration);
                } else if (action == 'integration') {
                    show = ((invoice.LOCKED && unlockedInvoice <= 1 && !invoiceStatusPendingIntegration && !invoiceCancelPendingIntegration) || (isSankhya && invoice.ERP_INTEGRATION_STATUS && invoice.ERP_INTEGRATION_STATUS.STATUS && invoice.STATUS && invoice.STATUS.ID != EInvoiceStatus.PRE_INVOICE));
                } else if (action == 'group') {
                    show = (invoice.LOCKED && (selectedRows > 1 && unlockedInvoice == 0 && this.$scope.selectedRows.every(x => x.FINANCIAL_SITUATION.ID == '1') && row.isSelected) && !invoiceStatusPendingIntegration && !invoiceCancelPendingIntegration && !isSankhyaIntegrationOrderPendingUnificationMulti && !invoiceStatusPendingLockGroup && !isSankhyaIntegrationOrderPendingUnification && !invoice.LOCKED_UUID);
                } else if (action == 'ungroup') {
                    show = (invoice.LOCKED && unified && (selectedRows == 0 || selectedRows > 0 && row.isSelected && sameUnified) && !invoiceStatusPendingIntegration && !invoiceCancelPendingIntegration);
                } else if (action == 'lockGroup') {
                    show = (!invoice.LOCKED && (selectedRows > 1 && this.$scope.selectedRows.every(x => x.FINANCIAL_SITUATION.ID == '1') && row.isSelected) && !invoiceStatusPendingIntegration && !invoiceCancelPendingIntegration && !isSankhyaIntegrationOrderPendingUnificationMulti && !invoiceStatusPendingLockGroup && isSankhya && !invoice.LOCKED_UUID);
                } else if (action == 'lockUngroup') {
                    show = (invoice.LOCKED && invoice.LOCKED_UUID && (selectedRows == 0 || selectedRows > 0 && row.isSelected) && !invoiceStatusPendingIntegration && !invoiceCancelPendingIntegration && !invoiceCancelPendingIntegration && !invoiceStatusPendingLockGroup);
                } else if (action == 'denied') {
                    show = (this.lockedSelected() && ((!invoice.LOCKED || (invoice.LOCKED && !row.isSelected) || (unified && !sameUnified))) && !invoiceStatusPendingIntegration && !invoiceCancelPendingIntegration && !invoiceStatusPendingLockGroup);
                } else if (action == 'exchangeContract') {
                    show = (invoice.PAYMENT_METHOD && invoice.PAYMENT_METHOD.ID === "4");
                } else if (action == 'preinvoice') { // is open
                    show = (!invoice.LOCKED && invoice.STATUS.ID == '2' && ((unlockedInvoice <= 1) || (unlockedInvoice > 1 && this.$scope.selectedRows.every(x => x.STATUS.ID == '2') && row.isSelected)) && !invoiceStatusPendingIntegration && !invoiceCancelPendingIntegration && !invoiceStatusPendingLockGroup && !invoice.LOCKED_UUID);
                    if (show && isSankhya && (isSankhyaIntegrationOrderConfirmed || isSankhyaIntegrationOrderPendingConfirmation || isSankhyaIntegrationBillingConfirmed || isSankhyaIntegrationBillingPendingConfirmation))
                        show = true;
                } else if (action == 'openinvoice') {
                    show = (!invoice.LOCKED && invoice.STATUS.ID == '1' && ((unlockedInvoice <= 1) || (unlockedInvoice > 1 && this.$scope.selectedRows.every(x => x.STATUS.ID == '1') && row.isSelected)) && !invoiceStatusPendingIntegration && !invoiceCancelPendingIntegration && !invoiceStatusPendingLockGroup && !invoice.LOCKED_UUID);
                    if (show && isSankhya && !invoice.ERP_INTEGRATION_STATUS && !invoiceStatusPendingLockGroup && !invoice.LOCKED_UUID)
                        show = true;
                } else return false;

                return show;
            } catch (ex) {
                this.handleError(ex);
            }
        }
        this.$scope.lockedSelected = () => {
            return this.lockedSelected();
        }
        this.$scope.groupingSpecModal = (selectedInvoiceIndex: number) => {
            this.groupingSpecModal(selectedInvoiceIndex);
        }
        this.$scope.modalErpOrder = (selectedInvoiceIndex: number) => {
            this.modalErpOrder(selectedInvoiceIndex);
        }

        this.$scope.applyFinancialContract = (invoiceID: string[], selectedContract: SelectorModel) => {
            this.applyFinancialContract(invoiceID, selectedContract);
        }
        this.$scope.clearSelections = async () => {
            await this.clearSelections();
        }
        this.$scope.updateGrid = async () => {
            await this.updateGrid();
        }
        this.$scope.goToProcess = (process: string) => {
            this.goToProcess(process);
        }
        this.$scope.goToExternalDataIntegration = (externalDataIntegration: string) => {
            this.goToExternalDataIntegration(externalDataIntegration);
        }

        this.$scope.goToContract = (contract: string) => {
            this.goToContract(contract);
        }
        this.$scope.goToCharge = (invoice: Invoice, charge: string) => {
            this.goToCharge(invoice, charge);
        }
        this.$scope.buildErpIntegrationStatusTooltip = (charge: any) => {
            let result = "";
            if (charge.ERP_INTEGRATION_STATUS) {
                result += `<b> ${this.formService.getTranslate('REGISTRATION.INTEGRATION_STATUS')}${charge.ERP_INTEGRATION_STATUS && charge.ERP_INTEGRATION_STATUS.STATUS ? ': ' + charge.ERP_INTEGRATION_STATUS.STATUS.NAME : ''}</b>`;
                result += charge.ERP_ORDER ? `<br><b>${this.formService.getTranslate('FINANCIAL.ORDER')}</b>: ${charge.ERP_ORDER}` : "";
                result += charge.SEQUENCE ? `<br><b>${this.formService.getTranslate('GENERAL.SEQUENCE')}</b>: ${charge.SEQUENCE}` : "";
                result += `<br><b>${this.formService.getTranslate('REGISTRATION.MESSAGE')}: </b>${charge.ERP_INTEGRATION_STATUS.MESSAGE}`;
            }
            return result;
        }

        this.$scope.buildErpIntegrationErroTooltip = (invoice: any) => {
            let result = "";
            if (invoice.ERP_INTEGRATION_STATUS) {
                if (invoice.ERP_INTEGRATION_STATUS.STATUS && invoice.ERP_INTEGRATION_STATUS.STATUS.NAME) {
                    result += `<b>${this.formService.getTranslate('REGISTRATION.INTEGRATION_STATUS')}: ${invoice.ERP_INTEGRATION_STATUS.STATUS.NAME}</b><br>`;
                }

                if (invoice.ERP_INTEGRATION_STATUS.MESSAGE) {
                    result += `<b>${this.formService.getTranslate('REGISTRATION.MESSAGE')}: </b>${invoice.ERP_INTEGRATION_STATUS.MESSAGE}`;
                }
            }

            if (invoice.DOCUMENT_ERROR && invoice.DOCUMENT_ERROR.length > 0) {
                const reasons = invoice.DOCUMENT_ERROR.map(x => x.REASON);
                result += `<br><b>${this.formService.getTranslate('REGISTRATION.MESSAGE')}:</b>`;
                for (const rea of reasons) {
                    let message = "";
                    if (rea.includes(ESankhyaStatusMessage.TOP_NO_AUTOMATIC)) {
                        message = this.formService.getTranslate('FINANCIAL.MISSING_TAX_INVOICE');
                    } else {
                        message = rea;
                    }
                    result += `<br>${message}<br>`;
                }
            }
            return result;
        }

        this.$scope.buildErpIntegrationTooltip = (charge: any, invoice: any) => {
            let result = '<div class="text-left">';
            if (charge.ERP_ORDER) {
                result += `<b>${this.formService.getTranslate('REGISTRATION.INTEGRATION_STATUS')}</b>: ${charge.ERP_INTEGRATION_STATUS.STATUS.NAME}`;
                result += `<br><b>${this.formService.getTranslate('FINANCIAL.ORDER')}</b>: ${charge.ERP_ORDER ? charge.ERP_ORDER : ""}`;
                result += `<br><b>${this.formService.getTranslate('GENERAL.SEQUENCE')}</b>: ${charge.ERP_SEQUENCE ? charge.ERP_SEQUENCE : ""}`;
                result += `<br><b>${this.formService.getTranslate('FINANCIAL.BILLING')}</b>: ${charge.ERP_BILLING ? charge.ERP_BILLING : ""}`;
                result += `<br><b>${this.formService.getTranslate('FINANCIAL.TOP_ORDER')}</b>: ${charge.ERP_TOP && charge.ERP_TOP.ERP_TOP_PURCHASE ? charge.ERP_TOP.ERP_TOP_PURCHASE.ID + " - " + charge.ERP_TOP.ERP_TOP_PURCHASE.NAME : ""}`;

                let erpTopBilling = charge.ERP_TOP && charge.ERP_TOP.ERP_TOP_BILLING ? charge.ERP_TOP.ERP_TOP_BILLING.ID + " - " + charge.ERP_TOP.ERP_TOP_BILLING.NAME + ( charge.ERP_TOP.ERP_TOP_BILLING.NUM_AUTOMATIC ? " (" + this.formService.getTranslate('FINANCIAL.AUTOMATIC_NUMBER') + ")" : "") : "";
                if (charge.ERP_TOP && charge.ERP_TOP.ERP_TOP_BILLING_RETENTION_ISS) {
                    erpTopBilling = charge.ERP_TOP && charge.ERP_TOP.ERP_TOP_BILLING_RETENTION_ISS ? charge.ERP_TOP.ERP_TOP_BILLING_RETENTION_ISS.ID + " - " + charge.ERP_TOP.ERP_TOP_BILLING_RETENTION_ISS.NAME + ( charge.ERP_TOP.ERP_TOP_BILLING_RETENTION_ISS.NUM_AUTOMATIC ? " (" + this.formService.getTranslate('FINANCIAL.AUTOMATIC_NUMBER') + ")" : "") : "";
                } else if (charge.ERP_TOP && charge.ERP_TOP.ERP_TOP_BILLING_RETENTION) {
                    erpTopBilling = charge.ERP_TOP && charge.ERP_TOP.ERP_TOP_BILLING_RETENTION ? charge.ERP_TOP.ERP_TOP_BILLING_RETENTION.ID + " - " + charge.ERP_TOP.ERP_TOP_BILLING_RETENTION.NAME + ( charge.ERP_TOP.ERP_TOP_BILLING_RETENTION.NUM_AUTOMATIC ? " (" + this.formService.getTranslate('FINANCIAL.AUTOMATIC_NUMBER') + ")" : "") : "";
                }
                
                result += `<br><b>${this.formService.getTranslate('FINANCIAL.TOP_BILLING')}</b>: ${erpTopBilling}`;
            }
            result += "</div>";
            return result;
        }

        this.$scope.updateReasonValue = (reason: ISelectorModel) => {
            this.$scope.invoiceCancelReason = reason;
        }

        this.$scope.concatConversionDate = (entity: Invoice): string => {
            return this.concatConversionDate(entity);
        }
    }

    initModel(): void { }

    private async clearSelections(): Promise<void> {
        this.$scope.selectedRows = [];
        this.$scope.selectedInvoices = [];
        this.$scope.selectedInvoicesCurrencies = [];
        this.$gridService.$gridApi.selection.clearSelectedRows();

        if (this.$scope.log) this.$scope.log.show = false;
    }

    private goToProcess(process: string) {
        this.sessionService.openTab("app.operational.newProcess.list", <IProcessParameter>{ PROCESS_NUMBER: process });
    }

    private goToExternalDataIntegration(externalDataIntegration: string) {
        this.sessionService.openTab("app.external.externalDataIntegration")
    }

    private goToContract = (contract: string) => {
        this.sessionService.openTab("app.finop.contract.financialContract", <IContractParameter>{ CONTRACT_NUMBER: contract });
    }


    private goToCharge(invoice: Invoice, charge: string) {
        try {
            const tabParameter: IChargeParameter = { PROCESS_NUMBER: invoice.PROCESS_NUMBER, 'CHARGE.NAME': charge, 'INVOICE_NUMBER.NAME': invoice.INVOICE_NUMBER }
            this.sessionService.openTab('app.finop.charge.register', tabParameter)
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private concatConversionDate(entity: Invoice): string {
        let returnedDates = '';
        try {
            if (entity.CURRENCIES && entity.CURRENCIES.ORIGINAL) {
                for (const currencyOriginal of entity.CURRENCIES.ORIGINAL) {
                    if (currencyOriginal.CURRENCY.CODE != "BRL" && currencyOriginal.EXCHANGE_RATE.DATE) {
                        returnedDates += moment(currencyOriginal.EXCHANGE_RATE.DATE).format('DD/MM/YYYY') + ', ';
                    }
                }
            }

            return returnedDates.slice(0, -2);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initDependencies(): Promise<any> {
        const self: InvoiceRegisterController = this;

        return new Promise(function (resolve, reject) {
            resolve(true);
        });
    }

    private async formatBatchReturnView(invoiceBatchReturn: InvoiceBatchItem[]): Promise<BatchError[]> {
        try {
            return invoiceBatchReturn.map(x => ({
                identifier: (x.identifier.INVOICE_NUMBER) ? x.identifier.INVOICE_NUMBER : (x.document && x.document.INVOICE_NUMBER) ? x.document.INVOICE_NUMBER : x.identifier._id.toString(),
                error: x.failed.error,
            }));
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private handleBatchError(ex) {
        try {
            const batchService = new BatchViewService(this.$injector, this.$scope);
            batchService.handleBatchResponse(ex, this.formatBatchReturnView, this);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    //******* VIEW BEHAVIOR ******//

    private selectedRowCallback(row: IGridRow): void {
        try {
            //if (invoice.LOCKED) { this.$gridService.$gridApi.selection.unSelectRow(row.entity); return; } 

            this.block();

            const invoice: Invoice = row.entity;
            const i = this.selectedRows.findIndex(x => x._id === invoice._id);
            if (row.isSelected && i === -1)
                this.selectedRows.push(invoice);
            else
                this.selectedRows.splice(i, 1);

            //update scope
            this.$scope.selectedRows = this.selectedRows.slice();
            this.$scope.selectedInvoices = this.selectedRows.filter(x => (!x.LOCKED));
            this.$scope.selectedInvoicesCurrencies = this.calcInvoiceCurrencies();

            if (this.selectedRows.length > 1 && this.$scope.log) this.$scope.log.show = false;

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private selectedRowBatchCallback(rows: IGridRow[]): void {
        try {
            rows = this.$gridService.$gridApi.selection.getSelectedGridRows();
            this.block();

            this.selectedRows = rows.filter(x => x.isSelected).map(x => x.entity);
            if (this.selectedRows.length > 1 && this.$scope.log) this.$scope.log.show = false;

            this.$scope.selectedRows = this.selectedRows.slice();
            this.$scope.selectedInvoices = this.selectedRows.filter(x => (!x.LOCKED));
            this.$scope.selectedInvoicesCurrencies = this.calcInvoiceCurrencies();

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private calcInvoiceCurrencies(): Array<any> {
        try {
            const currencyList = new Array<ICurrencyTotals>();

            this.$scope.totalBRL = 0;

            for (const row of this.selectedRows) {
                const chargeList = angular.copy(row.CHARGE);
                for (const charge of chargeList) {
                    if (charge && charge.CURRENCY) {
                        if (charge.NATURE.ID === "1") {
                            charge.TOTAL *= -1;
                            charge.TOTAL_BRL *= - 1;
                        }
                        if (!currencyList.some(x => x.ID === charge.CURRENCY.ID)) {
                            currencyList.push({ ID: charge.CURRENCY.ID, NAME: charge.CURRENCY.NAME, CODE: charge.CURRENCY.CODE, VALUE: charge.TOTAL, TOTAL_BRL: charge.TOTAL_BRL });
                        }
                        else {
                            const currencyIndex = currencyList.findIndex(x => x.ID === charge.CURRENCY.ID);
                            if (currencyIndex !== -1) currencyList[currencyIndex].VALUE += Number(charge.TOTAL.toFixed(2));
                            if (currencyIndex !== -1) currencyList[currencyIndex].TOTAL_BRL += Number(charge.TOTAL_BRL.toFixed(2));
                        }
                    }
                }
            }
            this.$scope.totalBRL = currencyList.reduce((total, currValues) => total + currValues.TOTAL_BRL, 0);

            return currencyList;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    public async selectionReapply(): Promise<void> {
        const rows = this.$gridService.$gridApi.core.getVisibleRows(this.$gridService.$gridApi.grid);
        const selectedRows = this.$gridService.$gridSelectedRows;
        if (selectedRows && selectedRows.length > 0) {
            const updatedRows: IGridRow[] = [];
            for (const row of selectedRows) {
                const foundRow = rows.find(x => x.entity._id == row._id);
                if (foundRow) {
                    foundRow.setSelected(true);
                    updatedRows.push(foundRow);
                }
            }
            this.selectedRowBatchCallback(updatedRows);
        }
    }

    // GET DATA

    private async getInvoiceFinancialContracts(invoiceId: string[]): Promise<any> {
        try {
            this.block();

            if (!invoiceId) return this.handleError(this.formService.getTranslate("FINANCIAL.NO_INVOICE_CONTRACT"));

            const finopReq: IMonacoRequest = {
                data: {
                    invoiceId,
                },
                route: `/invoice/contract/compatible`,
                timeout: 15000,
            }
            const result = await this.finopService.post<SelectorModel[]>(finopReq);
            if (!result || !result.data || !result.data.data) return this.handleWarning(this.formService.getTranslate("FINANCIAL.NO_CONTRACT"));
            const contractList = result.data.data;

            this.unblock();

            return contractList;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getFinancialAccount(people: PEOPLE): Promise<CLIENT_ACCOUNT[]> {
        try {
            this.block();

            let accountList: CLIENT_ACCOUNT[] = [];
            const route = people.ID_PHYSICAL_PERSON ? `/physicalPerson/list/currentAccount/${people.ID_PHYSICAL_PERSON}` : `/legalPerson/list/currentAccount/${people.ID_LEGAL_PERSON || people.ID}`;
            const result = await this.productService.get<CURRENT_ACCOUNT[]>({ route, timeout: 15000 });
            if (result && result.data && result.status == 200) {
                const productAccounts = result.data.data;

                accountList = productAccounts.filter(i => i['ACTIVE']).map((x) => {
                    let agencyFounded = '';
                    if (x['AGENCY']) {
                        agencyFounded = `${x['AGENCY']['CODE']}`;
                        if (x['AGENCY']['DV']) {
                            agencyFounded += `-${x['AGENCY']['DV']}`;
                        }
                    }
                    const account = `${x['ACCOUNT_CODE'] ? x['ACCOUNT_CODE'] : ""}${x['ACCOUNT_DV'] ? "-" + x['ACCOUNT_DV'] : ""}`;
                    const bank = `${x['BANK'] && x['BANK']['NAME'] ? x['BANK']['NAME'] : ""}${account ? "- CC: " + account : ""}`;
                    const pix = `${x['PIX'] && x['PIX']['NAME'] ? x['PIX']['NAME'] : ""}${x['PIX_KEY'] ? " - " + x['PIX_KEY'] : ""}`;
                    return {
                        ACCOUNT_NUMBER: account,
                        ACCOUNT: account ? {
                            ID: account,
                            NAME: bank,
                        } : pix ? {
                            ID: pix,
                            NAME: bank + " - PIX: " + pix,
                        } : null,
                        AGENCY: (x['AGENCY']) ? { ID: `${agencyFounded}`, NAME: null } : null,
                        BANK: x['BANK'],
                        OPERATION: null,
                        HOLDER_NAME: x['HOLDER_NAME'],
                        HOLDER_CNPJ: null,
                        DEFAULT: x['MAIN'],
                        OBSERVATION: x['OBSERVATION'],
                        PIX: x['PIX'],
                        PIX_KEY: x['PIX_KEY'],
                        SWIFT: x["SWIFT"],
                        IBAN: x['IBAN'],
                        ACTIVE: x['ACTIVE'],
                        ID: x['ID'],
                        CORRESPONDENCE: x['CORRESPONDENCE']
                    };
                });
            } else
                throw this.formService.getTranslate("FINANCIAL.NO_ACCOUNT_DETAILS");

            this.unblock();

            return accountList;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getRegisteredList(router, field) {
        const { data: generic } = await this.helperService.get(router + field, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async getGenericValue(identifier: string): Promise<any> {
        try {
            this.block();
            const router = "/generic/value/";
            const result = await this.getRegisteredList(router, identifier);
            if (!result) return [];
            const genericList: SelectorModel[] = result;

            this.unblock();

            return genericList;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getPaymentMethods(): Promise<SelectorModel[]> {
        try {
            this.block();
            const router = "/headcargo/alg0014/";
            const identifier = "payment_method";

            const result = await this.getRegisteredList(router, identifier);
            if (!result || !result.data) return [];
            const methodList: SelectorModel[] = result.data

            this.unblock();

            return methodList;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    // MODALS

    private async contractModal(model: Invoice): Promise<void> {
        try {
            let invoicesToChangeContract: Invoice[] = [];
            if (this.$scope.selectedInvoices && this.$scope.selectedInvoices.length > 0) {
                invoicesToChangeContract = this.$scope.selectedInvoices;
            } else if (model && model.INVOICE_NUMBER) {
                invoicesToChangeContract.push(model);
            }
            if (!invoicesToChangeContract || invoicesToChangeContract.length == 0) return this.handleError(this.formService.getTranslate("FINANCIAL.SELECT_INVOICES_RECALCULATE"));

            const invoiceID = invoicesToChangeContract.map(x => x._id);
            const contractList: SelectorModel[] = await this.getInvoiceFinancialContracts(invoiceID);

            if (!contractList || contractList.length == 0) return;

            this.selectedContract = contractList.find(x => x.NAME == model.CONTRACT_NUMBER);

            this.modalID = this.ModalService.newModal();

            this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    formService: 'edit',
                    template: require("../view/invoiceContract.html"),
                },
                {
                    actionButtonText: "GENERAL.CLOSE",
                    headerText: `${this.formService.getTranslate("GENERAL.INVOICES")} ${model.INVOICE_NUMBER} - ${this.formService.getTranslate("FINANCIAL.MANAGE_FINANCIAL_CONTRACT")}`,
                });

            const modalScope: IContractModal = await this.ModalService.getModalScope(this.modalID);
            modalScope.data = {
                selectedContract: this.selectedContract,
                invoiceNumber: invoicesToChangeContract.map(x => x.INVOICE_NUMBER).join(', '),
                invoiceID: invoiceID,
                hasCustomContract: (model.CONTRACT_COMPOSITION && model.CONTRACT_COMPOSITION.CUSTOM_CONTRACT),
            };
            modalScope.contractList = contractList;
            modalScope.applyFinancialContract = async (invoiceID, contract) => {
                await this.applyFinancialContract(invoiceID, contract);
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async accountModal(model: Invoice): Promise<void> {
        try {
            let invoicesSelected: Invoice[] = [];
            if (this.$scope.selectedInvoices && this.$scope.selectedInvoices.length > 0) {
                invoicesSelected = this.$scope.selectedInvoices;
            } else if (model && model.INVOICE_NUMBER) {
                invoicesSelected.push(model);
            }

            if (!invoicesSelected || invoicesSelected.length == 0) {
                const msgError = this.formService.getTranslate('FINANCIAL.CHANGE_ACCOUNT');
                return this.handleError(msgError);
            }
            if (invoicesSelected.some(x => !x.PEOPLE)) {
                const msgError = this.formService.getTranslate('FINANCIAL.PERSON_CHANGE_ACCOUNT');
                return this.handleError(msgError);
            }
            if (!invoicesSelected.every(x => x.PEOPLE.ID === invoicesSelected[0].PEOPLE.ID)) {
                const msgError = this.formService.getTranslate('FINANCIAL.PERSON_CHANGE_ACCOUNT');
                return this.handleError(msgError);
            }

            const firstSelected = invoicesSelected[0];
            const accountList = await this.getFinancialAccount(firstSelected.PEOPLE);
            if (!accountList || accountList.length == 0) {
                const msgError = this.formService.getTranslate('FINANCIAL.NO_CURRENT_ACCOUNT');
                return this.$formService.notifyWarning(msgError);
            }

            let selectedAccount: CLIENT_ACCOUNT = null;
            if (firstSelected.CLIENT_ACCOUNT && firstSelected.CLIENT_ACCOUNT.ACCOUNT) {
                selectedAccount = accountList.find(account => account.ACCOUNT && account.ACCOUNT.ID == firstSelected.CLIENT_ACCOUNT.ACCOUNT.ID);
                if (selectedAccount) {
                    selectedAccount.PIX_KEY = firstSelected.CLIENT_ACCOUNT.PIX_KEY;
                }
            }

            this.modalID = this.ModalService.newModal();

            this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    formService: 'edit',
                    template: require("../view/invoiceAccount.html"),
                    size: 'lg modal-overflow'
                },
                {
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: (invoicesSelected.length > 1) ? `FINANCIAL.INVOICE_CURRENT_ACCOUNT` : this.formService.getTranslate('FINANCIAL.INVOICE_CURRENT_ACCOUNT', { invoiceNumber: firstSelected.INVOICE_NUMBER })
                });

            const modalScope: IAccountModal = await this.ModalService.getModalScope(this.modalID);

            modalScope.selectedAccount = angular.copy(selectedAccount);
            modalScope.accounts = accountList.filter(acc => acc.ACCOUNT).map(acc => { return acc.ACCOUNT });

            modalScope.refreshAccount = async (account: ISelectorModel) => {
                const accountInfo = accountList.find(acc => acc.ACCOUNT.ID == account.ID);
                if (!accountInfo) return;

                modalScope.selectedAccount = angular.copy(accountInfo);

                await modalScope.$applyAsync();
            };

            modalScope.changeInvoiceAccount = async () => {
                await this.changeInvoiceAccount(invoicesSelected, modalScope.selectedAccount);
                await modalScope.$applyAsync();
            };

            await modalScope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async applyExchangeContract(invoiceIDs: number[], modal: Invoice, modalScope: IExchangeScope) {
        try {
            if (!invoiceIDs || invoiceIDs.length == 0) return this.handleError(this.formService.getTranslate("FINANCIAL.NO_INVOICE_EXCHANGE_RATE"));
            if (!modal || !modalScope) {
                this.ModalService.closeModal(this.modalID);
                this.modalID = 0;
                return;
            };

            if (!modalScope.exchangeModal) return this.handleError(this.formService.getTranslate("FINANCIAL.ERROR_EXCHANGE_RATE"));
            this.block();
            modal.EXCHANGE_CONTRACT = <EXCHANGE_CONTRACT>{};
            modal.EXCHANGE_CONTRACT.INVOICE = (modalScope.exchangeModal.INVOICE) ? modalScope.exchangeModal.INVOICE : null
            modal.EXCHANGE_CONTRACT.CONTRACT = (modalScope.exchangeModal.CONTRACT) ? modalScope.exchangeModal.CONTRACT : null
            modal.EXCHANGE_CONTRACT.COUNTRY = (modalScope.exchangeModal.COUNTRY) ? modalScope.exchangeModal.COUNTRY : null
            modal.EXCHANGE_CONTRACT.CHARGE_CODE = (modalScope.exchangeModal.CHARGE_CODE) ? modalScope.exchangeModal.CHARGE_CODE : null
            modal.EXCHANGE_CONTRACT.EXCHANGE = (modalScope.exchangeModal.EXCHANGE) ? modalScope.exchangeModal.EXCHANGE : null
            modal.EXCHANGE_CONTRACT.BROKER = (modalScope.exchangeModal.BROKER) ? modalScope.exchangeModal.BROKER : null
            modal.EXCHANGE_CONTRACT.DATE_SHIPMENT = (modalScope.exchangeModal.DATE_SHIPMENT) ? modalScope.exchangeModal.DATE_SHIPMENT : null
            modal.EXCHANGE_CONTRACT.DATE_ORDER = (modalScope.exchangeModal.DATE_ORDER) ? modalScope.exchangeModal.DATE_ORDER : null
            modal.EXCHANGE_CONTRACT.LOCK_CONTRACT_NUMBER = modalScope.exchangeModal.LOCK_CONTRACT_NUMBER ? modalScope.exchangeModal.LOCK_CONTRACT_NUMBER : null
            modal.EXCHANGE_CONTRACT.PAYMENT_REQUEST = modalScope.exchangeModal.PAYMENT_REQUEST ? modalScope.exchangeModal.PAYMENT_REQUEST : null
            modal.EXCHANGE_CONTRACT.LOCK_DATE = modalScope.exchangeModal.LOCK_DATE ? modalScope.exchangeModal.LOCK_DATE : null
            modal.EXCHANGE_CONTRACT.UNLOCK_DATE = modalScope.exchangeModal.UNLOCK_DATE ? modalScope.exchangeModal.UNLOCK_DATE : null
            modal.EXCHANGE_CONTRACT.OBSERVATION = (modalScope.exchangeModal.OBSERVATION) ? modalScope.exchangeModal.OBSERVATION : null


            const request = {
                data: {
                    invoiceIDs: invoiceIDs,
                    exchange: modal.EXCHANGE_CONTRACT,
                },
                route: `/invoice/exchange/apply`,
                timeout: 120000
            };

            const result = await this.finopService.post(request);



            if (!result || !result.data) return this.handleError(result);

            await this.clearSelections();
            await this.updateGrid();

            this.ModalService.closeModal(this.modalID);
            this.modalID = 0;

            this.$formService.notifySuccess(this.formService.getTranslate("FINANCIAL.EXCHANGE_RATE_APPLIED"));

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async invokeNegotiation(model: Invoice): Promise<void> {
        try {
            this.negotiationController = new InvoiceNegotiationController(this.$injector, this.$scope);
            this.negotiationController.init(model);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async instructionModal(model: Invoice): Promise<void> {
        try {
            let invoicesSelected: Invoice[] = [];
            if (this.$scope.selectedInvoices && this.$scope.selectedInvoices.length > 0) {
                invoicesSelected = this.$scope.selectedInvoices;
            } else if (model && model.INVOICE_NUMBER) {
                invoicesSelected.push(model);
            }

            if (!invoicesSelected || invoicesSelected.length == 0) return this.handleError(this.formService.getTranslate('FINANCIAL.INVOICE_TRADE'));

            const periodList: SelectorModel[] = await this.getGenericValue('day_period');

            this.modalID = this.ModalService.newModal();

            this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    formService: 'edit',
                    template: require("../view/invoiceInstruction.html"),
                    size: 'lg'
                },
                {
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: (invoicesSelected.length == 1) ? this.formService.getTranslate('FINANCIAL.INVOICE_INSTRUCTION', { invoiceNumber: model.INVOICE_NUMBER }) : `FINANCIAL.INVOICE_INSTRUCTION`
                });

            const modalScope: IInstructionModal = await this.ModalService.getModalScope(this.modalID);
            modalScope.data = {
                isMultipleAndOneHasApprovalDate: (invoicesSelected.length > 1 && invoicesSelected.some(x => x.APPROVAL_DATE)),
                paymentPeriod: (invoicesSelected.length == 1 && invoicesSelected[0].PAYMENT_PERIOD) ? invoicesSelected[0].PAYMENT_PERIOD : null,
                isSankhya: invoicesSelected.filter(item => item.INTEGRATION_DIRECTION.ID == EIntegrateDirection.SANKHYA).length > 0,
                invoiceStatus: (invoicesSelected.length > 0 && invoicesSelected[0].STATUS) ? invoicesSelected[0].STATUS : null,
            }
            modalScope.periodList = periodList;

            //Pre-Invoice
            modalScope.approvalDate = (invoicesSelected.length == 1 && invoicesSelected[0].APPROVAL_DATE) ? new Date(invoicesSelected[0].APPROVAL_DATE) : null;
            modalScope.thirdPartyDate = (invoicesSelected.length == 1 && invoicesSelected[0].THIRDPARTY_DATE) ? new Date(invoicesSelected[0].THIRDPARTY_DATE) : null;
            modalScope.providerInvoice = (invoicesSelected.length == 1 && invoicesSelected[0].PROVIDER_INVOICE) ? invoicesSelected[0].PROVIDER_INVOICE : null;
            modalScope.typedNumber = (invoicesSelected.length == 1 && invoicesSelected[0].TYPED_NUMBER) ? invoicesSelected[0].TYPED_NUMBER : null;
            modalScope.paymentDate = (invoicesSelected.length == 1 && invoicesSelected[0].PAYMENT_DATE) ? new Date(invoicesSelected[0].PAYMENT_DATE) : null;
            modalScope.erpTopHistory = (invoicesSelected.length && invoicesSelected[0].ERP_TOP_HISTORY) ? invoicesSelected[0].ERP_TOP_HISTORY : null;

            modalScope.applyInstruction = async (clearApprovalDate?: boolean) => {

                const invoiceIDs = invoicesSelected.map(x => x._id);

                const invoiceInstuctions = {} as IInvoiceInstructions
                if (modalScope.approvalDate) invoiceInstuctions.APPROVAL_DATE = modalScope.approvalDate;
                else if (clearApprovalDate) invoiceInstuctions.APPROVAL_DATE = null; // explicitly null, to clean it

                if (modalScope.data.paymentPeriod) invoiceInstuctions.PAYMENTPERIOD = modalScope.data.paymentPeriod;
                if (modalScope.thirdPartyDate) invoiceInstuctions.THIRDPARTY_DATE = modalScope.thirdPartyDate;
                if (modalScope.providerInvoice) invoiceInstuctions.PROVIDERINVOICE = modalScope.providerInvoice;
                if (modalScope.typedNumber) invoiceInstuctions.TYPED_NUMBER = modalScope.typedNumber;
                if (modalScope.paymentDate) invoiceInstuctions.PAYMENT_DATE = modalScope.paymentDate;
                if (modalScope.erpTopHistory) invoiceInstuctions.ERP_TOP_HISTORY = modalScope.erpTopHistory;

                await this.applyInstruction(invoiceIDs, invoiceInstuctions);
                await modalScope.$applyAsync();
            };
            modalScope.getEmptySelectorMsg = (): string => {
                return this.$formService.getEmptySelectorMsg();
            }
            modalScope.automaticNumber = (): boolean => {
                const count = modalScope.erpTopHistory.filter(item => !item.NUM_AUTOMATIC || !item.ERP_TOP).length;
                return count === 0;
            }
            await modalScope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async commentModal(model: Invoice): Promise<void> {
        try {
            let invoicesSelected: Invoice[] = [];

            if (this.selectedRows && this.selectedRows.length > 0) {
                invoicesSelected = this.selectedRows;
            } else if (model && model.INVOICE_NUMBER) {
                invoicesSelected.push(model);
            }
            if (!invoicesSelected || invoicesSelected.length == 0) return this.handleError(this.formService.getTranslate('FINANCIAL.INVOICE_TRADE'));

            this.modalID = this.ModalService.newModal();
            this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    formService: 'edit',
                    template: require("../view/invoiceComment.html"),
                    size: 'lg'
                },
                {
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: (invoicesSelected.length == 1) ? `${this.formService.getTranslate("GENERAL.INVOICES")} ${model.INVOICE_NUMBER}` : `OPERATIONAL.COMMENT`
                });

            const modalScope: ICommentModal = await this.ModalService.getModalScope(this.modalID);

            modalScope.comment = (invoicesSelected.length == 1 && invoicesSelected[0].COMMENT) ? invoicesSelected[0].COMMENT : null;

            modalScope.applyComment = async () => {
                const invoiceIDs = invoicesSelected.map(x => x._id);

                let comment = null;

                if (modalScope.comment) comment = modalScope.comment;

                await this.applyComment(invoiceIDs, comment);
                await modalScope.$applyAsync();
            };
            modalScope.getEmptySelectorMsg = (): string => {
                return this.$formService.getEmptySelectorMsg();
            }
            await modalScope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async modalErpOrder(selectedInvoiceIndex: number): Promise<void> {
        try {

            const invoice = this.$scope.selectedInvoices[selectedInvoiceIndex] || this.$scope.selectedRows[selectedInvoiceIndex];
            if (!invoice) return


            this.modalID = this.ModalService.newModal();
            this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    formService: EOperation.VIEW,
                    scope: this.$scope,
                    template: require("../view/invoiceRegisterModal.html"),
                    size: 'vlg'
                },
                {
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: `${this.formService.getTranslate("GENERAL.INVOICES")} ${invoice.INVOICE_NUMBER} - ${this.formService.getTranslate("FINANCIAL.GROUPING_SETTINGS")}`
                });

            const modalScope: IModelERP_BillingHistoryModal = await this.ModalService.getModalScope(this.modalID);
            let modalRow: IModelERPR_BillingHistoryModalRow[] = [];
            modalScope.isOk = false;
            modalScope.isCanceled = false;
            if (invoice.ERP_BILLING_HISTORY) {
                invoice.ERP_BILLING_HISTORY.map((item) => {
                    if (item.FINANCIAL_MOVEMENTS) {
                        item.FINANCIAL_MOVEMENTS.map(financial => {
                            let findFinancial = null
                            if (modalRow) {
                                findFinancial = modalRow.find(item => item.financial.NUFIN == financial.NUFIN)
                            }
                            if (findFinancial) {
                                findFinancial.ERP_BILLING = findFinancial.ERP_BILLING + "," + item.ERP_BILLING
                            } else {
                                modalRow.push({
                                    ERP_BILLING: item.ERP_BILLING,
                                    CANCELED: item.CANCELED,
                                    financial,
                                });
                            }
                            if (item.CANCELED) modalScope.isCanceled = true;
                            if (!item.CANCELED) modalScope.isOk = true;
                        });
                    }
                });
            }
            modalScope.invoice = invoice
            modalScope.modalRow = modalRow

            await modalScope.$applyAsync();

        } catch (ex) {
            this.handleError(ex);
        }

    }
    private async groupingSpecModal(selectedInvoiceIndex: number): Promise<void> {
        try {
            const invoice = this.$scope.selectedInvoices[selectedInvoiceIndex] || this.$scope.selectedRows[selectedInvoiceIndex];
            if (!invoice) return

            if (!invoice.GROUPING_SPECS || invoice.GROUPING_SPECS.length == 0) return this.handleWarning(this.formService.getTranslate("FINANCIAL.NO_GROUPING"));

            this.modalID = this.ModalService.newModal();
            this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    formService: EOperation.VIEW,
                    scope: this.$scope,
                    template: require("../view/invoiceGroupingSpec.html"),
                    size: 'vlg'
                },
                {
                    actionButtonText: "GENERAL.CLOSE",
                    headerText: `${this.formService.getTranslate("GENERAL.INVOICES")} ${invoice.INVOICE_NUMBER} - ${this.formService.getTranslate("FINANCIAL.GROUPING_SETTINGS")}`
                });

            const modalScope: IGroupingSpecModal = await this.ModalService.getModalScope(this.modalID);

            // format grouping specs to show
            const formattedSpecs = [];
            for (const groupingSpec of invoice.GROUPING_SPECS) {

                const specProps = ['CONDITION', 'RULE'];
                const multilineProps = ['INVOICE_HOLDER', 'PROCESS_SPEC'];

                const specPropItems = {};
                for (const specProperty of specProps) {

                    const tableItems: { name: string, content: string }[] = []

                    const specPropObj: Object = groupingSpec['param'][specProperty];
                    if (!specPropObj) continue;

                    for (const key of Object.keys(specPropObj)) {
                        const map = propertyNameMap.find(x => x.PROPERTY === key);
                        if (!map) continue;

                        const propContent: any = specPropObj[key];
                        let formattedContent = '';
                        if (propContent != null && typeof propContent === 'object') {
                            if (Array.isArray(propContent)) {
                                if (multilineProps.some(mult => key == mult)) {
                                    const lines = angular.copy(propContent);

                                    formattedContent += `
                                            <div class="table-responsive">
                                                <table class="table table-bordered">                                                    
                                                    <tbody>`

                                    let lineKeys = [];
                                    for (const line of lines) {
                                        lineKeys = Object.keys(line);
                                        let values = line[lineKeys[0]];
                                        if (values) {
                                            const simpleTextValues = this.formService.getCONCAT(values);
                                            values = simpleTextValues.split(';').map(x => `<span class="badge text-sm btn-allog">${x}</span>`).join(' ');
                                        }
                                        let role = line[lineKeys[lineKeys.length - 1]]; // last prop
                                        role = (role && role.hasOwnProperty('NAME')) ? role['NAME'] : '';

                                        formattedContent +=
                                            `   <tr>
                                                    <td>${values}</td>
                                                    <td class="col-md-2">${role}</td>
                                                </tr>
                                            `;
                                    }
                                    formattedContent +=
                                        `   </tbody>
                                            <thead>
                                        `
                                    for (const key of lineKeys) {
                                        const lineKeyMap = propertyNameMap.find(x => x.PROPERTY === key);
                                        if (lineKeyMap) formattedContent += `<th>${lineKeyMap.LABEL}</th>`;
                                    }

                                    formattedContent += `
                                                    </thead>
                                                </table>
                                            </div>`;

                                } else // not a multiline
                                    formattedContent = this.formService.getCONCAT(propContent);
                            } else // not an array
                                formattedContent = (propContent.hasOwnProperty('NAME')) ? propContent['NAME'] : '';
                        } else // not an object/array
                            formattedContent = propContent;

                        tableItems.push({
                            name: map.LABEL,
                            content: formattedContent,
                        })
                    }
                    specPropItems[specProperty] = tableItems;
                }

                formattedSpecs.push({
                    specs: groupingSpec,
                    propItems: specPropItems
                })
            }

            modalScope.data = {
                invoice: invoice,
                groupingSpecs: formattedSpecs
            }
            await modalScope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    //******* INVOICE OPERATIONS ******//
    private async updateInvoiceIntegration(model: Invoice): Promise<void> {
        try {
            if (!model) return this.handleError(this.formService.getTranslate('FINANCIAL.INVOICE_UPDATE_INTEGRATION'));

            this.block();

            // Sankhya integration
            if (model.INTEGRATION_DIRECTION && model.INTEGRATION_DIRECTION.ID == EIntegrateDirection.SANKHYA) {
                if (model.ERP_INTEGRATION_STATUS && model.ERP_INTEGRATION_STATUS.STATUS && model.ERP_INTEGRATION_STATUS.STATUS.ID == EErpIntegrationStatus.BILLING_CONFIRMED) {
                    const finopReq: IMonacoRequest = {
                        data: {
                            invoiceNumber: model.INVOICE_NUMBER,
                        },
                        route: `/sankhya/invoice/order/status/`,
                        timeout: 30000,
                    }
                    const result = await this.finopService.post(finopReq);
                    if (!result || !result.data) return this.handleError(result);
                } else if (model.ERP_INTEGRATION_STATUS && model.ERP_INTEGRATION_STATUS.STATUS && (model.ERP_INTEGRATION_STATUS.STATUS.ID == EErpIntegrationStatus.PENDING_CANCEL_INVOICE || model.ERP_INTEGRATION_STATUS.STATUS.ID == EErpIntegrationStatus.PENDING_CANCEL_ORDER)) {
                    const finopReq: IMonacoRequest = {
                        data: {
                            invoiceNumber: model.INVOICE_NUMBER
                        },
                        route: `/sankhya/invoice/cancel/history`,
                        timeout: 30000,
                    }
                    const result = await this.finopService.post(finopReq);
                    if (!result || !result.data) return this.handleError(result);
                } else {
                    const finopReq: IMonacoRequest = {
                        data: {
                            invoiceNumber: model.INVOICE_NUMBER,
                        },
                        route: `/sankhya/invoice/`,
                        timeout: 30000,
                    }
                    const result = await this.finopService.post(finopReq);
                    if (!result || !result.data) return this.handleError(result);
                }
            } else {
                const finopReq: IMonacoRequest = {
                    route: `/integra/invoice/${model._id}`,
                    timeout: 30000,
                }
                const result = await this.finopService.get(finopReq);
                if (!result || !result.data) return this.handleError(result);
            }

            this.$formService.notifySuccess(this.formService.getTranslate('FINANCIAL.INTEGRATION'));

            await this.clearSelections();
            await this.updateGrid();
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async applyFinancialContract(invoiceId: string[], selectedContract: SelectorModel): Promise<void> {
        try {
            this.block();

            if (!invoiceId || typeof selectedContract === 'undefined') return this.handleError(this.formService.getTranslate("FINANCIAL.MISSING_INFO_CONTRACT"));

            const finopReq: IMonacoRequest = {
                data: {
                    invoiceId,
                    contractNumber: (selectedContract) ? selectedContract.NAME : null,
                },
                route: '/invoice/contract/set',
                timeout: 15000,
            }
            const result = await this.finopService.post(finopReq);
            if (!result || !result.data) return this.handleError(result);

            this.selectedContract = selectedContract;

            await this.clearSelections();
            await this.updateGrid();

            this.ModalService.closeModal(this.modalID);
            this.modalID = 0;

            this.unblock();

            this.$formService.notifySuccess(this.formService.getTranslate("FINANCIAL.FINANCIAL_CONTRACT_SUCCESS"));

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async changeInvoiceAccount(selectedInvoices: Invoice[], account: CLIENT_ACCOUNT): Promise<void> {
        try {
            this.block();

            if (!selectedInvoices || selectedInvoices.length === 0) return this.handleError(this.formService.getTranslate('FINANCIAL.NO_CHANGE_CURRENT_ACCOUNT'));
            if (!account) return this.handleError(this.formService.getTranslate('FINANCIAL.NO_CHANGE_CURRENT_ACCOUNT'));

            const invoiceId = selectedInvoices.map(x => x._id.toString())
            const finopReq: IMonacoRequest = {
                data: {
                    invoiceId,
                    account,
                },
                route: '/invoice/account/set',
                timeout: 45000,
            }
            const result = await this.finopService.post(finopReq);
            if (!result || !result.data) return this.handleError(result);

            await this.clearSelections();
            await this.updateGrid();

            this.ModalService.closeModal(this.modalID);
            this.modalID = 0;

            this.$formService.notifySuccess(this.formService.getTranslate('GENERAL.UPDATE_SUCESSFULLY_PERFORMED'));

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async applyInstruction(invoiceIDs: string[], instruction: IInvoiceInstructions): Promise<void> {
        try {
            if (!invoiceIDs || invoiceIDs.length == 0) return this.handleError(this.formService.getTranslate('FINANCIAL.INVOICE_NOT_FOUNT'));
            if (invoiceIDs.length > 1 && Object.keys(instruction).length == 0) {
                this.ModalService.closeModal(this.modalID);
                this.modalID = 0;
                return;
            };

            this.block();

            instruction.INVOICEID = invoiceIDs;

            const finopReq: IMonacoRequest = {
                data: {
                    instruction
                },
                route: '/invoice/instructions/set',
                timeout: 90000,
            }
            const result = await this.finopService.post(finopReq);
            if (!result || !result.data) return this.handleError(result);

            await this.clearSelections();
            await this.updateGrid();

            this.ModalService.closeModal(this.modalID);
            this.modalID = 0;

            this.$formService.notifySuccess(this.formService.getTranslate('GENERAL.UPDATE_SUCESSFULLY_PERFORMED'));

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async applyComment(invoiceIDs: string[], comment: string): Promise<void> {
        try {
            if (!invoiceIDs || invoiceIDs.length == 0) return this.handleError(this.formService.getTranslate('FINANCIAL.INVOICE_NOT_FOUNT'));
            if (!comment) {
                this.ModalService.closeModal(this.modalID);
                this.modalID = 0;
                return;
            };
            this.block();


            const finopReq: IMonacoRequest = {
                data: {
                    invoiceIDs,
                    comment
                },
                route: '/invoice/comment/set',
                timeout: 90000,
            }
            const result = await this.finopService.post(finopReq);
            if (!result || !result.data) return this.handleError(result);

            await this.clearSelections();
            await this.updateGrid();

            this.ModalService.closeModal(this.modalID);
            this.modalID = 0;

            this.$formService.notifySuccess(this.formService.getTranslate('GENERAL.UPDATE_SUCESSFULLY_PERFORMED'));

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async lockInvoice(model: Invoice): Promise<void> {
        try {
            if (!model.DUE_DATE) {
                return this.handleError(this.formService.getTranslate('FINANCIAL.DUE_DATE_NULL'));
            }
            if (await this.permissionService.isRoleAllowed("INVOICELOCK") === false) {
                this.permissionService.showBlockMessage();
                return;
            }
            this.block();

            let invoicesToLock: Invoice[] = [];
            if (this.$scope.selectedInvoices && this.$scope.selectedInvoices.length > 0) {
                invoicesToLock = this.$scope.selectedInvoices;
            } else if (model && model.INVOICE_NUMBER && model._id) {
                invoicesToLock.push(model);
            }

            if (!invoicesToLock || invoicesToLock.length == 0) return this.handleError(this.formService.getTranslate('FINANCIAL.SELECT_INVOICE'));

            const integrationRequests: { invoiceId: string, productIntegrationIds: IProductCompatibilityIntegrationIds }[] = [];
            for (const invoice of invoicesToLock) {
                const productIntegrationIds = await this.prepareProductIntegrationIds(invoice);
                if (productIntegrationIds) integrationRequests.push({ invoiceId: invoice._id, productIntegrationIds });
            }
            if (!integrationRequests || integrationRequests.length == 0) return;

            const finopReq: IMonacoRequest = {
                data: {
                    integrationRequests
                },
                route: '/invoice/lock',
                timeout: 60000,
            }
            const result = await this.finopService.post(finopReq);
            if (!result || !result.data) return this.handleError(result);

            await this.clearSelections();
            await this.updateGrid();
            await this.delay(2000);

            this.unblock()
            this.$formService.notifySuccess(this.formService.getTranslate('GENERAL.UPDATE_SUCESSFULLY_PERFORMED'));

        } catch (ex) {
            this.handleError(ex);

            await this.clearSelections();
            await this.updateGrid();
        }
    }

    private async unlockInvoice(model: Invoice): Promise<void> {
        try {
            if (!model.DUE_DATE) {
                return this.handleError(this.formService.getTranslate('FINANCIAL.DUE_DATE_NULL'));
            }
            if (await this.permissionService.isRoleAllowed("INVOICEUNLOCK") === false) {
                this.permissionService.showBlockMessage();
                return;
            }
            let invoicesToUnlock: Invoice[] = [];
            if (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) {
                invoicesToUnlock = this.$scope.selectedRows.filter(x => x.LOCKED);
            } else if (model && model.INVOICE_NUMBER && model._id) {
                if (model.LOCKED) invoicesToUnlock.push(model);
            }

            if (!invoicesToUnlock || invoicesToUnlock.length === 0) return this.handleError(this.formService.getTranslate('FINANCIAL.SELECT_INVOICE'));

            this.$scope.reasonList = await this.getGenericValue('po_cancel_reason');
            this.$scope.invoiceCancelReason = this.$scope.reasonList[0];
            this.$scope.isSankhya = invoicesToUnlock.filter(item => item.INTEGRATION_DIRECTION && item.INTEGRATION_DIRECTION.ID == '1').length > 0;
            const modalParamsId = this.ModalService.newModal();
            const confirmed = await this.ModalService.showModalConfirmation(
                {
                    modalID: modalParamsId,
                    formService: 'register',
                    size: 'md',
                    scope: this.$scope,
                    template: require('../view/modals/reopenInvoice.html')
                }, {
                bodyText: (invoicesToUnlock.length > 1) ? `FINANCIAL.UPDATE_INVOICE` : this.formService.getTranslate('FINANCIAL.UPDATE_INVOICE', { invoiceNumber: model.INVOICE_NUMBER }),
                actionButtonText: 'GENERAL.GRID.UNLOCK',
                headerText: this.formService.getTranslate('GENERAL.GRID.UNLOCK', { invoiceNumber: model.INVOICE_NUMBER }),
            }); if (!confirmed) return;

            this.block();

            const integrationRequests: { invoiceId: string, productIntegrationIds: IProductCompatibilityIntegrationIds, reason: ISelectorModel }[] = [];
            for (const invoice of invoicesToUnlock) {
                const productIntegrationIds = await this.prepareProductIntegrationIds(invoice);
                if (productIntegrationIds) integrationRequests.push({ invoiceId: invoice._id, productIntegrationIds, reason: this.$scope.invoiceCancelReason });
            }
            if (!integrationRequests || integrationRequests.length == 0) return;

            const finopReq: IMonacoRequest = {
                data: {
                    integrationRequests
                },
                route: '/invoice/unlock',
                timeout: 60000,
            }
            const result = await this.finopService.post(finopReq);
            if (!result || !result.data) return this.handleError(result);

            await this.clearSelections();
            await this.updateGrid();
            await this.delay(2000);

            this.unblock()
            this.$formService.notifySuccess(this.formService.getTranslate('GENERAL.UPDATE_SUCESSFULLY_PERFORMED'));

        } catch (ex) {
            this.handleBatchError(ex);

            await this.clearSelections();
            await this.updateGrid();
        }
    }

    private async prepareProductIntegrationIds(invoice: Invoice): Promise<IProductCompatibilityIntegrationIds> {
        try {
            if (!invoice.PEOPLE) throw `${invoice.INVOICE_NUMBER} ${this.formService.getTranslate("FINANCIAL.NO_HOLDER")}`;

            const processInfo: Partial<Process> = invoice['PROCESS'];
            const result = await Promise.all([
                this.productService.get<{ INTEGRATION_ID: string }>({ route: `/legalPerson/integrationId/${processInfo.BRANCH.ID}` }),
                this.productService.get<{ INTEGRATION_ID: string }>({ route: `/legalPerson/integrationId/${processInfo.CUSTOMER.ID_LEGAL_PERSON || processInfo.CUSTOMER}` }),
                (invoice.PEOPLE.ID_PHYSICAL_PERSON ? this.productService.get<{ INTEGRATION_ID: string }>({ route: `/physicalPerson/integrationId/${invoice.PEOPLE.ID_PHYSICAL_PERSON || invoice.PEOPLE.ID}` }) : this.productService.get<{ INTEGRATION_ID: string }>({ route: `/legalPerson/integrationId/${invoice.PEOPLE.ID_LEGAL_PERSON || invoice.PEOPLE.ID}` })),
                this.productService.get<{ INTEGRATION_ID: string }>({ route: `/moveType/integrationId/${processInfo.SERVICE_TYPE.ID}` }),
                (invoice.PEOPLE.ID_PHYSICAL_PERSON ? this.productService.get<CURRENT_ACCOUNT[]>({ route: `/physicalPerson/list/currentAccount/${invoice.PEOPLE.ID_PHYSICAL_PERSON}` }) : this.productService.get<CURRENT_ACCOUNT[]>({ route: `/legalPerson/list/currentAccount/${invoice.PEOPLE.ID_LEGAL_PERSON || invoice.PEOPLE.ID}` })),
            ]);

            const personAccounts = result[4].data.data;
            const selectedAccount = (invoice.CLIENT_ACCOUNT) ? personAccounts.find(x => (invoice.CLIENT_ACCOUNT.ACCOUNT.ID == `${x['ACCOUNT_CODE']}-${x['ACCOUNT_DV']}`)) : null;

            const integrationInfo: IProductCompatibilityIntegrationIds = {
                branchId: result[0].data.data.INTEGRATION_ID,
                customerId: result[1].data.data.INTEGRATION_ID,
                invoicePeopleId: result[2].data.data.INTEGRATION_ID,
                serviceTypeId: result[3].data.data.INTEGRATION_ID,
                invoiceAccountId: (selectedAccount) ? selectedAccount['INTEGRATION_ID'] : null,
            };
            return integrationInfo;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async updateSelectedInvoices(model: Invoice): Promise<void> {
        try {
            this.block();

            let invoicesToUpdate: string[] = [];
            if (this.$scope.selectedInvoices && this.$scope.selectedInvoices.length > 0) {
                invoicesToUpdate = this.$scope.selectedInvoices.map(x => x._id);
            } else if (model && model.INVOICE_NUMBER) {
                invoicesToUpdate.push(model._id);
            }

            if (!invoicesToUpdate || invoicesToUpdate.length == 0) return this.handleError(this.formService.getTranslate('FINANCIAL.SELECT_INVOICE'));

            const finopReq: IMonacoRequest = {
                data: {
                    invoiceId: invoicesToUpdate
                },
                route: '/invoice/equalize',
                timeout: 30000,
            }
            const result = await this.finopService.post(finopReq);
            if (!result || !result.data) return this.handleError(result);

            await this.clearSelections();
            await this.updateGrid();

            this.$formService.notifySuccess(this.formService.getTranslate('GENERAL.UPDATE_SUCESSFULLY_PERFORMED'));

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openSelectedInvoices(model: Invoice, forceOne: boolean = false): Promise<void> {
        try {
            if (!model.DUE_DATE) {
                return this.handleError(this.formService.getTranslate('FINANCIAL.DUE_DATE_NULL'));
            }
            let invoicesToUpdate: Invoice[] = [model];
            if (!forceOne && (this.$scope.selectedInvoices && this.$scope.selectedInvoices.length > 0)) invoicesToUpdate = this.$scope.selectedInvoices;
            if (!invoicesToUpdate || invoicesToUpdate.length == 0 || !invoicesToUpdate[0]) return this.handleError(this.formService.getTranslate('FINANCIAL.SELECT_INVOICE'));

            this.$scope.reasonList = await this.getGenericValue('po_cancel_reason');
            this.$scope.invoiceCancelReason = this.$scope.reasonList[0];
            this.$scope.isSankhya = invoicesToUpdate.filter(item => item.INTEGRATION_DIRECTION && item.INTEGRATION_DIRECTION.ID == '1').length > 0;
            const modalParamsId = this.ModalService.newModal();
            const confirmed = await this.ModalService.showModalConfirmation(
                {
                    modalID: modalParamsId,
                    formService: 'register',
                    size: 'md',
                    scope: this.$scope,
                    template: require('../view/modals/reopenInvoice.html')
                }, {
                bodyText: (invoicesToUpdate.length > 1) ? `FINANCIAL.UPDATE_INVOICE` : this.formService.getTranslate('FINANCIAL.UPDATE_INVOICE', { invoiceNumber: invoicesToUpdate[0].INVOICE_NUMBER }),
                actionButtonText: 'GENERAL.GRID.UNLOCK',
                headerText: (invoicesToUpdate.length > 1) ? `GENERAL.GRID.UNLOCK` : this.formService.getTranslate('GENERAL.GRID.UNLOCK', { invoiceNumber: invoicesToUpdate[0].INVOICE_NUMBER }),
            });

            if (!confirmed) return;
            this.block();
            const finopReq: IMonacoRequest = {
                data: {
                    invoiceId: invoicesToUpdate.map(x => x._id),
                    reason: this.$scope.invoiceCancelReason
                },
                route: '/invoice/status/open',
                timeout: 30000,
            }
            const result = await this.finopService.post(finopReq);
            if (!result || !result.data) return this.handleError(result);

            await this.clearSelections();
            await this.updateGrid();
            await this.delay(2000);

            this.$formService.notifySuccess(this.formService.getTranslate('GENERAL.UPDATE_SUCESSFULLY_PERFORMED'));

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async preInvoiceSelectedInvoices(model: Invoice, forceOne: boolean = false): Promise<void> {
        try {
            if (!model.DUE_DATE) {
                return this.handleError(this.formService.getTranslate('FINANCIAL.DUE_DATE_NULL'));
            }
            if (await this.permissionService.isRoleAllowed("INVOICEPRE") === false) {
                this.permissionService.showBlockMessage();
                return;
            }
            let invoicesToUpdate: Invoice[] = [model];
            if (!forceOne && (this.$scope.selectedInvoices && this.$scope.selectedInvoices.length > 0)) invoicesToUpdate = this.$scope.selectedInvoices;
            if (!invoicesToUpdate || invoicesToUpdate.length == 0 || !invoicesToUpdate[0]) return this.handleError(this.formService.getTranslate('FINANCIAL.SELECT_INVOICE'));

            const confirmed = await this.ModalService.showModalConfirmation({}, {
                bodyText: (invoicesToUpdate.length > 1) ? `FINANCIAL.UPDATE_INVOICE` : this.formService.getTranslate('FINANCIAL.UPDATE_INVOICE', { invoiceNumber: invoicesToUpdate[0].INVOICE_NUMBER }),
                actionButtonText: 'FINANCIAL.PRE_LOCK',
                headerText: (invoicesToUpdate.length > 1) ? `FINANCIAL.PRE_LOCK` : this.formService.getTranslate('FINANCIAL.PRE_LOCK', { invoiceNumber: invoicesToUpdate[0].INVOICE_NUMBER }),
            });

            if (!confirmed) return;

            this.block();

            const finopReq: IMonacoRequest = {
                data: {
                    invoiceId: invoicesToUpdate.map(x => x._id),
                },
                route: '/invoice/status/preinvoice',
                timeout: 30000,
            }
            const result = await this.finopService.post(finopReq);
            if (!result || !result.data) return this.handleError(result);

            await this.clearSelections();
            await this.updateGrid();
            await this.delay(2000);

            this.$formService.notifySuccess(this.formService.getTranslate('GENERAL.UPDATE_SUCESSFULLY_PERFORMED'));

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async groupSelectedInvoices(): Promise<void> {
        try {
            if (!this.$scope.selectedRows && this.$scope.selectedRows.length == 0) return this.handleError(this.formService.getTranslate('FINANCIAL.SELECT_INVOICE'));

            this.block();

            const invoicesToGroup: string[] = this.$scope.selectedRows.map(x => x._id);
            const productIntegrationIds = await this.prepareProductIntegrationIds(this.$scope.selectedRows[0]);

            const finopReq: IMonacoRequest = {
                data: {
                    invoiceId: invoicesToGroup,
                    productIntegrationIds
                },
                route: '/invoice/group',
                timeout: 30000,
            }
            const result = await this.finopService.post(finopReq);
            if (!result || !result.data) return this.handleError(result);

            await this.clearSelections();
            await this.delay(5000);
            await this.updateGrid();

            this.$formService.notifySuccess(this.formService.getTranslate('GENERAL.UPDATE_SUCESSFULLY_PERFORMED'));

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async ungroupSelectedInvoices(model: Invoice): Promise<void> {
        try {
            this.block();

            let groupingRecord: string = model.UNIFICATION;

            if (!groupingRecord || groupingRecord.length == 0) return this.handleError(this.formService.getTranslate('FINANCIAL.NO_GROUPING_ACTIVE'));

            const productIntegrationIds = await this.prepareProductIntegrationIds(model);

            const finopReq: IMonacoRequest = {
                data: {
                    groupingrecord: groupingRecord,
                    productIntegrationIds
                },
                route: '/invoice/ungroup',
                timeout: 30000,
            }
            const result = await this.finopService.post(finopReq);
            if (!result || !result.data) return this.handleError(result);

            await this.clearSelections();
            await this.updateGrid();

            this.$formService.notifySuccess(this.formService.getTranslate('GENERAL.UPDATE_SUCESSFULLY_PERFORMED'));

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async lockGroupSelectedInvoices(): Promise<void> {
        try {
            if (!this.$scope.selectedRows && this.$scope.selectedRows.length == 0) return this.handleError(this.formService.getTranslate('FINANCIAL.SELECT_INVOICE'));

            if (!this.$scope.selectedRows.every(x => x.DUE_DATE)) {
                return this.handleError(this.formService.getTranslate('FINANCIAL.DUE_DATE_NULL'));
            }

            this.block();

            const invoicesToGroup: string[] = this.$scope.selectedRows.map(x => x.INVOICE_NUMBER);

            const finopReq: IMonacoRequest = {
                data: {
                    invoiceNumbers: invoicesToGroup
                },
                route: '/sankhya/invoice/lock/group',
                timeout: 30000,
            }
            const result = await this.finopService.post(finopReq);
            if (!result || !result.data) return this.handleError(result);

            await this.clearSelections();
            await this.updateGrid();

            this.$formService.notifySuccess(this.formService.getTranslate('FINANCIAL.GROUPING'));

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async unlockGroupSelectedInvoices(model: Invoice): Promise<void> {
        try {
            if (!model.DUE_DATE) {
                return this.handleError(this.formService.getTranslate('FINANCIAL.DUE_DATE_NULL'));
            }

            if (await this.permissionService.isRoleAllowed("INVOICEUNLOCK") === false) {
                this.permissionService.showBlockMessage();
                return;
            }
            let invoicesToUnlock: Invoice[] = [];
            if (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) {
                invoicesToUnlock = this.$scope.selectedRows.filter(x => x.LOCKED);
            } else if (model && model.INVOICE_NUMBER && model._id) {
                if (model.LOCKED) invoicesToUnlock.push(model);
            }

            if (!invoicesToUnlock || invoicesToUnlock.length === 0) return this.handleError(this.formService.getTranslate(' FINANCIAL.SELECT_INVOICE'));

            this.$scope.reasonList = await this.getGenericValue('po_cancel_reason');
            this.$scope.invoiceCancelReason = this.$scope.reasonList[0];
            this.$scope.isSankhya = invoicesToUnlock.filter(item => item.INTEGRATION_DIRECTION && item.INTEGRATION_DIRECTION.ID == '1').length > 0;
            const modalParamsId = this.ModalService.newModal();
            const confirmed = await this.ModalService.showModalConfirmation(
                {
                    modalID: modalParamsId,
                    formService: 'register',
                    size: 'md',
                    scope: this.$scope,
                    template: require('../view/modals/reopenInvoice.html')
                }, {
                bodyText: (invoicesToUnlock.length > 1) ? `FINANCIAL.UPDATE_INVOICE` : this.formService.getTranslate('FINANCIAL.UPDATE_INVOICE', { invoiceNumber: model.INVOICE_NUMBER }),
                actionButtonText: 'GENERAL.GRID.UNLOCK',
                headerText: this.formService.getTranslate('GENERAL.GRID.UNLOCK', { invoiceNumber: model.INVOICE_NUMBER }),
            }); if (!confirmed) return;

            this.block();

            let integrationRequests: { invoiceId: string, reason: ISelectorModel } = null;

            integrationRequests = {
                invoiceId: invoicesToUnlock[0]._id,
                reason: this.$scope.invoiceCancelReason
            }

            if (!integrationRequests) return;

            const finopReq: IMonacoRequest = {
                data: {
                    integrationRequests
                },
                route: '/sankhya/invoice/unlock/group',
                timeout: 60000,
            }
            const result = await this.finopService.post(finopReq);
            if (!result || !result.data) return this.handleError(result);

            await this.clearSelections();
            await this.updateGrid();

            this.unblock()
            this.$formService.notifySuccess(this.formService.getTranslate('FINANCIAL.HOLD_UNGROUPING'));

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async addFileReference(model: Invoice): Promise<void> {
        try {
            let invoices: Invoice[] = [];
            if (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) {
                invoices = this.$scope.selectedRows;
            } else if (model && model.INVOICE_NUMBER && model._id) {
                invoices.push(model);
            }
            if (!invoices || invoices.length === 0) return this.handleError(this.formService.getTranslate('FINANCIAL.SELECT_INVOICE'));

            const invoiceNumberList = (this.$scope.selectedRows && this.$scope.selectedRows.length > 0) ? this.$scope.selectedRows.map(item => item.INVOICE_NUMBER).join(';') : model.INVOICE_NUMBER;
            const invoiceProcessNumbers = invoices.map(x => x.PROCESS_NUMBER).filter((x, index, self) => x && index === self.findIndex(t => t === x));

            const processes: Process[] = [];
            for (const processNumber of invoiceProcessNumbers) {
                const timeout = 120000;
                const result = await this.OperationalService.get(`/process/byNumber/${processNumber}/${timeout}`, timeout);
                if (!result || !result.data || (result && result.data && !result.data.data)) return this.handleError(this.formService.getTranslate('FINANCIAL.FAIL_PROCESS_DATA'));
                const process: Process = result.data.data[0];
                if (process) processes.push(process);
            }

            const fileReferenceModalID = this.ModalService.newModal();
            const modalResponse = await this.ModalService.showModalFileReference(
                {
                    modalID: fileReferenceModalID,
                    categoryName: ['Fatura', 'Credit_Debit Note', 'Recibo', 'Pre Invoice'],
                    process: processes,
                    invoiceID: invoices.map(x => ({ ID: x._id, INVOICE_NUMBER: x.INVOICE_NUMBER, PROCESS_NUMBER: x.PROCESS_NUMBER })),
                    reference: invoiceNumberList,
                    transaction: model.TRANSACTION,
                    paymentCondition: model.COMPOSITION.PAYMENT_CONDITION,
                    conversionReference: model.COMPOSITION.CONVERSION_REFERENCE
                });

            if (modalResponse)
                if (isArray(modalResponse))
                    this.$formService.notifySuccess(this.formService.getTranslate('GENERAL.UPDATE_SUCESSFULLY_PERFORMED', { documentValue: modalResponse[0].DOCUMENT_VALUE, processNumber: model.PROCESS_NUMBER }));
                else
                    this.$formService.notifySuccess(this.formService.getTranslate('GENERAL.UPDATE_SUCESSFULLY_PERFORMED', { processNumbers: processes.map(x => x.PROCESS_NUMBER).join(', ') }));

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async exchangeContractModal(model: Invoice): Promise<void> {
        try {
            let invoicesSelected: Invoice[] = [];
            if (this.selectedRows && this.selectedRows.length > 0) {
                invoicesSelected = this.selectedRows;
            } else if (model && model.INVOICE_NUMBER) {
                invoicesSelected.push(model);
            }

            if (!invoicesSelected) return this.handleError(this.formService.getTranslate('FINANCIAL.SELECT_INVOICE'));

            invoicesSelected = invoicesSelected.filter(x => x.PAYMENT_METHOD.ID === "4");

            const invoiceIDs: number[] = [];
            for (const invoice of invoicesSelected) {
                invoiceIDs.push(invoice._id);
            }

            this.modalID = this.ModalService.newModal();

            this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    formService: 'edit',
                    template: require("../view/invoiceExchangeContract.html"),
                    size: 'lg',
                },
                {
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: (invoicesSelected.length == 1) ? this.formService.getTranslate('FINANCIAL.APP_INTL_REMITTANCE_CONTRACT', { invoiceNumber: model.INVOICE_NUMBER }) : `FINANCIAL.INTERNATIONAL_REMITTANCE_CONTRACT`
                });

            const modalScope: IExchangeScope = await this.ModalService.getModalScope(this.modalID);
            modalScope.exchangeModal = <IExchangeModal>{};
            if (invoicesSelected.length == 1) {
                modalScope.exchangeModal.INVOICE = model.EXCHANGE_CONTRACT ? model.EXCHANGE_CONTRACT.INVOICE : null;
                modalScope.exchangeModal.CONTRACT = model.EXCHANGE_CONTRACT ? model.EXCHANGE_CONTRACT.CONTRACT : null;
                modalScope.exchangeModal.COUNTRY = model.EXCHANGE_CONTRACT ? model.EXCHANGE_CONTRACT.COUNTRY : null;
                modalScope.exchangeModal.CHARGE_CODE = model.EXCHANGE_CONTRACT ? model.EXCHANGE_CONTRACT.CHARGE_CODE : null;
                modalScope.exchangeModal.EXCHANGE = model.EXCHANGE_CONTRACT ? model.EXCHANGE_CONTRACT.EXCHANGE : null;
                modalScope.exchangeModal.BROKER = model.EXCHANGE_CONTRACT ? model.EXCHANGE_CONTRACT.BROKER : null;
                modalScope.exchangeModal.DATE_SHIPMENT = (model.EXCHANGE_CONTRACT && model.EXCHANGE_CONTRACT.DATE_SHIPMENT) ? new Date(model.EXCHANGE_CONTRACT.DATE_SHIPMENT) : null;
                modalScope.exchangeModal.DATE_ORDER = (model.EXCHANGE_CONTRACT && model.EXCHANGE_CONTRACT.DATE_ORDER) ? new Date(model.EXCHANGE_CONTRACT.DATE_ORDER) : null;
                modalScope.exchangeModal.LOCK_CONTRACT_NUMBER = model.EXCHANGE_CONTRACT ? model.EXCHANGE_CONTRACT.LOCK_CONTRACT_NUMBER : null;
                modalScope.exchangeModal.PAYMENT_REQUEST = model.EXCHANGE_CONTRACT && model.EXCHANGE_CONTRACT.PAYMENT_REQUEST ? new Date(model.EXCHANGE_CONTRACT.PAYMENT_REQUEST) : null;
                modalScope.exchangeModal.LOCK_DATE = (model.EXCHANGE_CONTRACT && model.EXCHANGE_CONTRACT.LOCK_DATE) ? new Date(model.EXCHANGE_CONTRACT.LOCK_DATE) : null;
                modalScope.exchangeModal.UNLOCK_DATE = (model.EXCHANGE_CONTRACT && model.EXCHANGE_CONTRACT.UNLOCK_DATE) ? new Date(model.EXCHANGE_CONTRACT.UNLOCK_DATE) : null;

                if (model.EXCHANGE_CONTRACT) {
                    modalScope.exchangeModal.OBSERVATION = model.EXCHANGE_CONTRACT ? model.EXCHANGE_CONTRACT.OBSERVATION : null;
                }
                else {
                    if ((model.NATURE.ID == "1") && (model.CLIENT_ACCOUNT)) {
                        modalScope.exchangeModal.OBSERVATION = model.CLIENT_ACCOUNT.OBSERVATION;
                    }
                }
            }

            if (!modalScope.exchangeModal.OBSERVATION) modalScope.exchangeModal.OBSERVATION = null;
            modalScope.companiesList = [];
            modalScope.searchCountriesBy = async (query: string) => { if (query.length >= 3) modalScope.countriesList = await this.getCountryList(query); }
            modalScope.exchangeModList = await this.getSelectorModelFromGeneric('exchange_mode');
            modalScope.chargeList = await this.getSelectorModelFromGeneric('charge_code');
            modalScope.searchBrokerBy = async (query: string) => { if (query.length >= 3) modalScope.companiesList = await this.getLegalPersonsList(query) }

            modalScope.applyExchangeContract = async () => {
                await this.applyExchangeContract(invoiceIDs, model, modalScope);
                await modalScope.$applyAsync();
            };

            await modalScope.$applyAsync();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private monitoringFilterChanges() {
        this.$gridService.$gridApi.core.on.filterChanged(this.$scope, this.clearSelections.bind(this));
    }

    private async getLegalPersonsList(query: string) {
        try {
            this.block();
            const productReq: IMonacoRequest = {
                route: '/legalPerson/list/custom/operational',
                data: { specializations: [ELegalPersonSpecializationId.INSURANCE_BROKER], search: query },
                timeout: 10000,
            }
            const legalPersons = await this.productService.post<any>(productReq);

            const result = legalPersons.data.data ? legalPersons.data.data.map((x: { ID: SelectorModel, NAME: SelectorModel, CODE: SelectorModel }) => ({ ID: x.ID, NAME: x.NAME, CODE: x.CODE })) : [];

            return result;
        }
        catch (ex) {
            throw ex;
        }
        finally {
            this.unblock();
        }
    }

    private async getCountryList(query: string) {
        try {
            this.block();
            const countries = await this.productService.post<any>({ route: '/country/list/custom', data: { search: query } });

            if (!countries || !countries.data.data) return [];
            const result = countries.data.data.map((x: { ID: SelectorModel, NAME: SelectorModel, INITIALS: SelectorModel }) => ({ ID: x.ID, NAME: x.NAME, CODE: x.INITIALS }));

            return result;
        }
        catch (ex) {
            throw ex;
        }
        finally {
            this.unblock();
        }
    }

    private async getSelectorModelFromGeneric(identifier: string): Promise<Array<SelectorModel>> {
        let resultList = new Array<SelectorModel>();

        const { data: generic } = await this.helperService.get(`/generic/getByIdentifier/${identifier}`, null, 15000);

        if ((generic.status !== 200) || (!generic.data)) return null;

        let selectorList = generic && generic.data ? generic.data : [];

        for (const item of selectorList) {
            const selectorModel = <SelectorModel>{};
            selectorModel.ID = item.CODE;
            selectorModel.NAME = item.VALUE;
            selectorModel.CODE = item.ALTERNATIVE;

            resultList.push(selectorModel);
        }

        if (resultList.length > 0) {
            resultList = resultList.sort((x, y) => x.ID < y.ID ? -1 : 1);
        }

        return resultList;
    }

    // LOG
    private async requestHistory(id: string): Promise<any> {
        const timeout = 120000;
        const result = await this.finopService.get({ route: `/invoice/viewLog/${id}/${timeout}`, timeout: timeout });
        if (!result || !result.data) return this.handleError(result);
        return result.data;
    }

    private async viewLog(chargeGroupingParams) {

        try {
            if (this.$scope.log) this.$scope.log.show = false;
            this.block();
            let log: IViewLog = {
                operation: 'history',
                number: chargeGroupingParams._id,
                list: [],
                show: true,
                searchQuery: '',
                originalList: [],
            }
            const result = await this.requestHistory(log.number)
            log.list = result.data;
            log.originalList = angular.copy(log.list);
            this.$scope.log = log;
            angular.element('#log-viewer').removeClass('ng-hide');
            const position = angular.element('#log-viewer').offset().top + $('.app-content-body').scrollTop() - 105;

            $('.app-content-body').animate({
                scrollTop: position
            }, 500);

            this.unblock();
        } catch (err) {
            if (err['status'] && err['status'] === 404) return this.handleWarning('Nenhum registro encontrado');
            return this.handleError(err);
        }
    };

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: 'CHARGE', LABEL: 'BASIC_DATA.CHARGE' },
            { PROPERTY: 'CHARGE_NUMBER', LABEL: 'FINANCIAL.CHARGE_IDENTIFICATION' },
            { PROPERTY: 'CHARGE_TYPE', LABEL: 'FINANCIAL.CHARGE_TYPE' },
            { PROPERTY: 'NATURE', LABEL: 'REGISTRATION.TRANSACTION' },
            { PROPERTY: 'AMOUNT', LABEL: 'GENERAL.QUANTITY' },
            { PROPERTY: 'APPLICATION', LABEL: 'FINANCIAL.CHARGE_BASIS' },
            { PROPERTY: 'CURRENCY', LABEL: 'GENERAL.CURRENCY' },
            { PROPERTY: 'MINIMUM', LABEL: 'BASIC_DATA.MINIMUM' },
            { PROPERTY: 'UNITARY', LABEL: 'BASIC_DATA.UNIT' },
            { PROPERTY: 'TOTAL', LABEL: 'FINANCIAL.TOTAL_AMOUNT' },
            { PROPERTY: 'TOTAL_BRL', LABEL: 'FINANCIAL.TOTAL_AMOUNT_BRL' },
            { PROPERTY: 'CONVERSION_SPREAD', LABEL: 'GENERAL.EXCHANGE_RATE_INDEX + FINANCIAL.SPREAD' },
            { PROPERTY: 'TRANSACTION', LABEL: 'GENERAL.HOLDER_TYPE' },
            { PROPERTY: 'PEOPLE', LABEL: 'GENERAL.HOLDER' },
            { PROPERTY: 'VALIDITY_OF', LABEL: 'PRODUCT.VALIDITY_START' },
            { PROPERTY: 'VALIDITY_UP', LABEL: 'PRODUCT.VALIDITY_END' },
            { PROPERTY: 'SHOW_FOR_GROUP', LABEL: 'BASIC_DATA.DISPLAY' },
            { PROPERTY: 'EQUIPMENT', LABEL: 'BASIC_DATA.EQUIPMENT' },
            { PROPERTY: 'INVOICE_NUMBER', LABEL: 'GENERAL.INVOICES' },
            { PROPERTY: 'COMPOSITION_GROUP', LABEL: 'GENERAL.COMPOSITION' },
            { PROPERTY: 'OBSERVATION', LABEL: 'GENERAL.REMARKS' },
            { PROPERTY: 'HC2COD_CHARGE', LABEL: 'GENERAL.IDENTIFIER' },
            { PROPERTY: 'CHARGE_ORIGIN', LABEL: 'BASIC_DATA.ORIGIN' },
            { PROPERTY: 'MODALITY', LABEL: 'FINANCIAL.CHARGE_PAYMENT_METHOD' },
            { PROPERTY: 'PAYMENT_METHOD', LABEL: 'FINANCIAL.PAYMENT_MEANS' },
            { PROPERTY: 'PAYMENT_CONDITION', LABEL: 'GENERAL.INVOICE_PAYMENT_TERM' },
            { PROPERTY: 'DUE_DATE_REFERENCE', LABEL: 'FINANCIAL.PAYMENT_TERMS_REFERENCE' },
            { PROPERTY: 'DUE_DATE_D0', LABEL: 'FINANCIAL.PAYMENT_TERM_DAYS' },
            { PROPERTY: 'CONVERSION_REFERENCE', LABEL: 'FINANCIAL.CONVERSION_REFERENCE' },
            { PROPERTY: 'CURRENCY_CONVERSION', LABEL: 'GENERAL.EXCHANGE_RATE_INDEX' },
            { PROPERTY: 'SPREAD', LABEL: 'FINANCIAL.SPREAD' },
            { PROPERTY: 'PERIOD_VARIATION', LABEL: 'REGISTRATION.PERIOD_VARIATION' },
            { PROPERTY: 'BASE', LABEL: 'FINANCIAL.BASE' },
            { PROPERTY: 'PREFERENCE_TYPE', LABEL: 'FINANCIAL.PREFERENCE_TYPE' },
            { PROPERTY: 'PREFERENCE', LABEL: 'FINANCIAL.PREFERENCE' },
            { PROPERTY: 'PREFERENCE_PARAMETERIZATION', LABEL: 'FINANCIAL.PREFERENCE_SETTING' },
            { PROPERTY: 'ANTICIPATED_INVOICE', LABEL: 'REGISTRATION.EARLY_RECEIPT' },
            { PROPERTY: 'PAYMENT_COMPROMISE', LABEL: 'FINANCIAL.PAYMENT_AGREEMENT' },
            { PROPERTY: 'OBSERVATION', LABEL: 'GENERAL.REMARKS' },
            { PROPERTY: 'PROCESS_NUMBER', LABEL: 'OPERATIONAL.FILE_NUMBER' },
            { PROPERTY: 'INVOICE_NUMBER', LABEL: 'GENERAL.INVOICES' },
            { PROPERTY: 'NATURE', LABEL: 'REGISTRATION.TRANSACTION' },
            { PROPERTY: 'STATUS', LABEL: 'FINANCIAL' },
            { PROPERTY: 'FINANCIAL_SITUATION', LABEL: 'GENERAL.FINANCIAL_DEPARTMENT' },
            { PROPERTY: 'LOCKED', LABEL: 'FINANCIAL.LOCKED' },
            { PROPERTY: 'LOCKED_DATE', LABEL: 'FINANCIAL.LOCK_DATE' },
            { PROPERTY: 'GROUPING_SPECS', LABEL: 'FINANCIAL.CHARGES_SPLIT' },
            { PROPERTY: 'PEOPLE', LABEL: 'GENERAL.HOLDER' },
            { PROPERTY: 'CONTRACT_NUMBER', LABEL: 'OPERATIONAL.FINANCIAL_CONTRACT' },
            { PROPERTY: 'DUE_DATE', LABEL: 'FINANCIAL.DUE_DATE' },
            { PROPERTY: 'COMPOSITION', LABEL: 'GENERAL.COMPOSITION' },
            { PROPERTY: 'CONTRACT_COMPOSITION', LABEL: 'FINANCIAL.CONTRACT_INFORMATION' },
            { PROPERTY: 'CUSTOM_CONTRACT', LABEL: 'FINANCIAL.PERSONALIZED_CONTRACT' },
            { PROPERTY: 'INVOICE_CONFIGURATION', LABEL: 'FINANCIAL.INVOICE_SETTINGS' },
            { PROPERTY: 'CONVERSION_DATE', LABEL: 'FINANCIAL.CONVERSTION_DATE' },
            { PROPERTY: 'EXCHANGE_RATE', LABEL: 'FINANCIAL.INTL_REMITTANCE_DETAILS' },
            { PROPERTY: 'CURRENCY', LABEL: 'GENERAL.CURRENCY' },
            { PROPERTY: 'DATE', LABEL: 'GENERAL.DATE' },
            { PROPERTY: 'CONVERSION_FACTOR', LABEL: 'BASIC_DATA.EXCHANGE_RATE' },
            { PROPERTY: 'CONVERSION_FACTOR_SPREAD', LABEL: 'BASIC_DATA.EXCHANGE_RATE + FINANCIAL.SPREAD' },
            { PROPERTY: 'CLIENT_ACCOUNT', LABEL: 'ENTITY.CHECKING_ACCOUNT' },
            { PROPERTY: 'ACCOUNT_NUMBER', LABEL: 'ENTITY.ACCOUNT_NUMBER' },
            { PROPERTY: 'ACCOUNT', LABEL: 'FINANCIAL.ACCOUNT' },
            { PROPERTY: 'AGENCY', LABEL: 'ENTITY.BANK_AGENCY' },
            { PROPERTY: 'BANK', LABEL: 'ENTITY.BANK' },
            { PROPERTY: 'OPERATION', LABEL: 'REGISTRATION.OPERATION' },
            { PROPERTY: 'HOLDER_NAME', LABEL: 'GENERLA.HOLDER' },
            { PROPERTY: 'HOLDER_CNPJ', LABEL: 'REGISTRATION.DOCUMENT_ID_NUMBER' },
            { PROPERTY: 'DEFAULT', LABEL: 'REGISTRATION.STANDARD' },
            { PROPERTY: 'OBSERVATION', LABEL: 'GENERAL.REMARKS' },
            { PROPERTY: 'DESCRIPTION', LABEL: 'GENERAL.DESCRIPTION' },
            { PROPERTY: 'PAYMENT_PERIOD', LABEL: 'FINANCIAL.PAYMENT_PERIOD' },
            { PROPERTY: 'LIQUIDATION_DATE', LABEL: 'FINANCIAL.SETTLEMENT_DATE' },
            { PROPERTY: 'ORIGINAL_TOTAL', LABEL: 'FINANCIAL.TOTAL_AMOUNT' },
            { PROPERTY: 'PARTIAL_PAYMENT', LABEL: 'FINANCIAL.PARTIAL_PAYMENT' },
            { PROPERTY: 'CURRENCIES', LABEL: 'GENERAL.CURRENCY' },
            { PROPERTY: 'PROFIT', LABEL: 'Profit' },
            { PROPERTY: 'ORIGINAL', LABEL: 'FINANCIAL.ORIGINALS' },
            { PROPERTY: 'CURRENT', LABEL: 'GENERAL.CURRENT' },
            { PROPERTY: 'NEGOTIATED', LABEL: 'FINANCIAL.NEGOTIATED' },
            { PROPERTY: 'NEGOTIATION', LABEL: 'FINANCIAL.UPDATE_INTEGRATION_HC2' },
            { PROPERTY: 'NEGOTIATION_COMPOSITION', LABEL: 'FINANCIAL.NEGOTIATION_DETAILS' },
            { PROPERTY: 'ORIGINAL_INFORMATION', LABEL: 'FINANCIAL.ORIGINAL_DATA' },
            { PROPERTY: 'CREATED_DATE', LABEL: 'GENERAL.CREATED_AT' },
            { PROPERTY: 'CREATED_BY', LABEL: 'GENERAL.CREATED_BY' },
            { PROPERTY: 'PERSISTENT', LABEL: 'FINANCIAL.PERSISTENT' },
            { PROPERTY: 'EXCHANGE_CONTRACT', LABEL: 'FINANCIAL.INTERNATIONAL_REMITTANCE_CONTRACT' },
            { PROPERTY: 'APPROVAL_DATE', LABEL: 'FINANCIAL.APPROVAL_DATE' },
            { PROPERTY: 'THIRDPARTY_DATE', LABEL: 'FINANCIAL.THIRD_PARTY_INVOICE' },
            { PROPERTY: 'HC2_FINANCIAL_RECORD', LABEL: 'FINANCIAL.INTEGRATION_ID_HC2' },
            { PROPERTY: 'UNIFICATION', LABEL: 'REGISTRATION.HC2_LOT_RECORD' },
            { PROPERTY: 'LOCKED_UUID', LABEL: 'REGISTRATION.UNIFIED_LOCKED' },
            { PROPERTY: 'GENERATED_BY', LABEL: 'GENERAL.GENERATED_BY' },
            { PROPERTY: 'ORDER_DATE', LABEL: 'GENERAL.GENERATED_BY' },
            { PROPERTY: 'CHANGE_DATE', LABEL: 'GENERAL.UPDATED_AT' },
            { PROPERTY: 'INVOICE', LABEL: 'GENREAL.INVOICES' },
            { PROPERTY: 'CONTRACT', LABEL: 'Contrato' },
            { PROPERTY: 'COUNTRY', LABEL: 'Páis de Remessa' },
            { PROPERTY: 'CHARGE_CODE', LABEL: 'Código da Natureza' },
            { PROPERTY: 'EXCHANGE', LABEL: 'Modalidade de Câmbio' },
            { PROPERTY: 'BROKER', LABEL: 'Corretora' },
            { PROPERTY: 'DATE_SHIPMENT', LABEL: 'Data da Remessa' },
            { PROPERTY: 'DATE_ORDER', LABEL: 'Data da Ordem' },
            { PROPERTY: 'PAYMENT_REQUEST', LABEL: 'Solicitação de Pagamento' },
            { PROPERTY: 'LOCK_DATE', LABEL: 'Data de Trava' },
            { PROPERTY: 'UNLOCK_DATE', LABEL: 'Data de Destrava' },
            { PROPERTY: 'OBSERVATION', LABEL: 'Observação' },
            { PROPERTY: 'INVOICE_VALIDATION_EDI', LABEL: 'GENERAL.INVOICE_VALIDATION_EDI' },
            { PROPERTY: 'INVOICE_VALIDATION_EDI_NAME', LABEL: 'GENERAL.INVOICE_VALIDATION_EDI_NAME' },
            { PROPERTY: 'LAST_INTEGRATION_STATUS_DATE', LABEL: 'FINANCIAL.LAST_INTEGRATION_STATUS_DATE' },
            { PROPERTY: 'COMMENT', LABEL: 'OPERATIONAL.COMMENT' }
        ];
        return <ICustomLogProperties[]>props;
    }

    private getInvoiceActionsMap(): ICustomActionsMap[] {
        const customActionsMap = [
            { ACTION: EAction.NEW, LABEL: "GENERAL.CREATION", CLASS_COLOR: "text-yellow", ICON: "fa fa-certificate" },
            { ACTION: EAction.REMOVE, LABEL: "GENERAL.REMOVE", CLASS_COLOR: "text-danger", ICON: "fa fa-trash" },
            { ACTION: EAction.RECALCULATION, LABEL: 'FINANCIAL.INVOICE_UPDATE', CLASS_COLOR: 'text-gray', ICON: 'fa fa-calculator' },
            { ACTION: EAction.EQUALIZATION, LABEL: 'FINANCIAL.INVOICE_EQUALIZATION', CLASS_COLOR: 'text-primary', ICON: 'fa fa-moon-o' },
            { ACTION: EAction.CONTRACT_CHANGE, LABEL: "FINANCIAL.FINANCIAL_CONTRACT_SHIFT", CLASS_COLOR: "text-info", ICON: "fa fa-file-text-o icon" },
            { ACTION: EAction.ACCOUNT_CHANGE, LABEL: "FINANCIAL.ACCOUNT_EDIT", CLASS_COLOR: "text-especial", ICON: "fa fa-address-card-o" },
            { ACTION: EAction.NEGOTIATION, LABEL: "FINANCIAL.NEGOTIATION", CLASS_COLOR: "text-bronze", ICON: "fa fa-handshake-o icon" },
            { ACTION: EAction.INSTRUCTION, LABEL: "FINANCIAL.INSTRUCTION", CLASS_COLOR: "text-dark", ICON: "fa fa-sliders icon" },
            { ACTION: EAction.OPEN, LABEL: "FINANCIAL.UNLOCKED.INVOICE", CLASS_COLOR: "text-green", ICON: "fa fa-circle-o" },
            { ACTION: EAction.PRE_INVOICE, LABEL: "FINANCIAL.PRE_LOCKED", CLASS_COLOR: "text-yellow", ICON: "fa fa-check-circle-o" },
            { ACTION: EAction.LOCK, LABEL: "FINANCIAL.LOCKING", CLASS_COLOR: "text-rouge", ICON: "fa fa-lock icon" },
            { ACTION: EAction.UNLOCK, LABEL: "FINANCIAL.UNLOCKING", CLASS_COLOR: "text-rouge", ICON: "fa fa-unlock icon" },
            { ACTION: EAction.MANUAL_UNLOCK, LABEL: "FINANCIAL.MANUAL_UNLOCKING", CLASS_COLOR: "text-rouge", ICON: "fa fa-lock icon" },
            { ACTION: EAction.UNIFY, LABEL: "FINANCIAL.GROUP", CLASS_COLOR: "text-green", ICON: "fa fa-link icon" },
            { ACTION: EAction.SEPARATE, LABEL: "FINANCIAL.UNGROUP", CLASS_COLOR: "text-ouro", ICON: "fa fa-chain-broken" },
            { ACTION: EAction.HC2_INTEGRATION_UPDATE, LABEL: "FINANCIAL.HC2_UPDATE", CLASS_COLOR: "text-cyano", ICON: "fa fa-refresh" },
            { ACTION: EAction.EXCHANGE_CONTRACT, LABEL: "FINANCIAL.INTL_REMITTANCE_CONTRACT", CLASS_COLOR: "text-dark", ICON: "fa fa-line-chart" },
            { ACTION: EAction.EXTERNAL_FILE, LABEL: "GENERAL.EXTERNAL_FILES", CLASS_COLOR: "text-dark", ICON: "fa fa-file-code-o" },
            { ACTION: EAction.COMMENT, LABEL: "OPERATIONAL.COMMENT", CLASS_COLOR: "text-dark", ICON: "fa fa-commenting-o" },
            { ACTION: EAction.FINANCIAL_MOVEMENTS, LABEL: "FINANCIAL.FINANCIAL_TITLES", CLASS_COLOR: "text-dark", ICON: "fa fa-credit-card" },
            { ACTION: EAction.PARTIAL_PAID, LABEL: "FINANCIAL.PARTIAL_PAID", CLASS_COLOR: "text-green", ICON: "fa fa-usd" },
            { ACTION: EAction.PAID, LABEL: "FINANCIAL.PAID", CLASS_COLOR: "text-green", ICON: "fa fa-usd" },
        ]
        return <ICustomActionsMap[]>customActionsMap;
    }

    private delay(ms: number): Promise<boolean> {
        return new Promise(resolve => {
            setTimeout(() => {
                resolve(true);
            }, ms);
        });
    }
}

import * as angular from "angular";
import { IColumnDef } from "ui-grid";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { DataManagerSchedulingModel, SITUATION, SCHEDULING_TYPE, SEND_TYPE_FILE, SCHEDULING_DAY, REPORT } from "@models/interface/dataManager/DataManagerSchedulingModel";
import * as moment from 'moment';
import { SelectorModel } from "../../common/model/SelectorModel";
import { DataManagerReportModel } from "../model/DataManagerReportModel";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { BrowserTitle } from "../../common/BrowserTitle";
import { HelperService } from "@services/HelperService";

interface IDataManagerSchedulingScope extends IGridFormServiceScope {
    model: DataManagerSchedulingModel;
    data_scheduling_situations: SITUATION;
    data_scheduling_types: SCHEDULING_TYPE;
    data_scheduling_file_types: SEND_TYPE_FILE;
    data_scheduling_days: SCHEDULING_DAY[];
    data_scheduling_reports: Array<DataManagerReportModel>;
    scopeBeforeSave: DataManagerSchedulingModel;
    gridVisibleStatus: boolean;
    gridDataCount: number;
    // History properties
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    // End History properties
    updateSchedulingType(type: number);
    addEmailField(field: string);
    removeEmailField(field: string, index: string);
    getConcatInformation(value: Array<string>): string;
    getConcatReports(value: REPORT[]): string;
}

export class DataManagerSchedulingController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IDataManagerSchedulingScope;
    private $timeout: ng.ITimeoutService
    private $q: ng.IQService;
    private RestService: IRestService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IDataManagerSchedulingScope) {
        super($injector, $scope);
        this.$scope = $scope;
        //get injections
        this.$timeout = $injector.get('$timeout');
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.helperService = $injector.get('HelperService');
    }

    async getGenericValues(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    getAllReports() {
        return this.RestService.getObjectAsPromise(`${this.getUrlDataManager()}/report/list/`, null, 10000, false);
    }

    getUrlDataManager() {
        const baseRoute = '/data-manager';
        const urlDataManager = this.config.reportDataManagerUrl + baseRoute;
        return urlDataManager;
    }

    async $onInit(): Promise<void> {
        try {
            //set before gridInit
            this.$baseUrl = this.getUrlDataManager();
            this.$scope.gridVisibleStatus = false;
            this.$scope.gridDataCount = 0;
            this.setDeletable(true);

            this.$scope.customLogProperties = [
                { PROPERTY: 'SCHEDULING_NAME', LABEL: 'GENERAL.NAME' },
                { PROPERTY: 'SCHEDULING_TYPE', LABEL: 'GENERAL.REPORT_SCHEDULING_TYPE' },
                { PROPERTY: 'SCHEDULING_DAY', LABEL: 'GENERAL.REPORT_SCHEDULING_DAY' },
                { PROPERTY: 'SCHEDULING_HOUR', LABEL: 'GENERAL.REPORT_SCHEDULING_HOUR' },
                { PROPERTY: 'SCHEDULING_MINUTE', LABEL: 'GENERAL.REPORT_SCHEDULING_MINUTE' },
                { PROPERTY: 'SEND_TO', LABEL: 'REGISTRATION.RECIPIENT' },
                { PROPERTY: 'SEND_CC', LABEL: 'OPERATIONAL.CARBON_COPY' },
                { PROPERTY: 'SEND_BCC', LABEL: 'OPERATIONAL.BLIND_CARBON_COPY' },
                { PROPERTY: 'SEND_SUBJECT', LABEL: 'REGISTRATION.SUBJECT' },
                { PROPERTY: 'SEND_CONTENT', LABEL: 'OPERATIONAL.CONTENT' },
                { PROPERTY: 'SEND_TYPE_FILE', LABEL: 'GENERAL.FILE_TYPE' },
                { PROPERTY: 'ZIP_FILE', LABEL: 'GENERAL.COMPRESS' },
                { PROPERTY: 'REPORTS', LABEL: 'GENERAL.MENU.REPORT' },
                { PROPERTY: 'SITUATION', LABEL: 'GENERAL.SITUATION' },
                { PROPERTY: 'CREATED_BY', LABEL: 'GENERAL.CREATED_BY' },
                { PROPERTY: 'CREATED_DATE', LABEL: 'GENERAL.CREATED_AT' }
            ];

            //init form and init grid
            this.initForm(this, 'form', 'scheduling', 'Agendamento', true);
            await this.initGrid('gridDataManagerScheduling', '/scheduling/list', true, true);

        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initScopeFunctions(): void {

        this.$scope.getConcatInformation = (originalValue: Array<string>) => {
            return this.formService.getCONCAT(originalValue);
        }

        this.$scope.getConcatReports = (originalValue: REPORT[]) => {
            return this.formService.getCONCAT(originalValue);
        }

        this.$scope.updateSchedulingType = () => {
            this.updateSchedulingType();
        };

        this.$scope.addEmailField = (field) => {
            this.addEmailField(field);
        };

        this.$scope.removeEmailField = (field, index) => {
            this.$scope.model[field].splice(parseInt(index), 1);
        };
    }

    private updateSchedulingType() {
        this.$scope.data_scheduling_days = [];
        this.$scope.model.SCHEDULING_DAY = null;
        if (this.$scope.model.SCHEDULING_TYPE)
            this.setSchedulingDays(this.$scope.model.SCHEDULING_TYPE.ID);
    }

    private addEmailField = (field) => {
        if (!this.$scope.model[field]) this.$scope.model[field] = [];
        if ((this.$scope.model[field].length - 1) != null) {
            this.$scope.model[field].push(null);
            this.formService.validateForm();
        }
    };

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            SCHEDULING_NAME: null,
            SCHEDULING_TYPE: { ID: '1', NAME: 'Diariamente', CODE: null },
            SCHEDULING_DAY: null,
            SCHEDULING_HOUR: null,
            SCHEDULING_MINUTE: null,
            SEND_TO: null,
            SEND_CC: null,
            SEND_BCC: null,
            SEND_SUBJECT: null,
            SEND_CONTENT: null,
            SEND_TYPE_FILE: null,
            ZIP_FILE: true,
            REPORTS: null,
            SITUATION: { ID: '1', NAME: 'Ativo', CODE: null },
            CREATED_BY: { ID: this.$rootScope.user._id, NAME: this.$rootScope.user.email, CODE: null },
            CREATED_DATE: new Date(),
            MODIFIED_BY: null,
            MODIFIED_DATE: null,
        };

    }

    initGridColumns(columns) {

        let columnDefs: Array<IColumnDef> = new Array();

        //action column, contain just buttons and icons
        const view = `<div class="text-center"><a ng-click="grid.appScope.view(row.entity)" class="text-info"tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`
        const edit = `<a ng-click="grid.appScope.edit(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`
        const copy = `<a ng-click="grid.appScope.copy(row.entity)" class="text-orange"   tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`
        const history = `<a ng-click="grid.appScope.viewLog(row.entity)" class="text-green"   tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a></div>`

        columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 110,
            maxWidth: 110,
            cellTemplate: (view + edit + copy + history),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        for (let i = 0; i < columns.length; i++) {
            let name = null;
            let displayName = null;
            let width = 0;
            let cellTemplate = undefined;
            let cellFilter = null;
            let grouping = null;
            let sort = null;
            let pinned = null;
            let visible = true;
            let filter = { condition: this.gridService.filterSelectObject };

            switch (columns[i].toUpperCase()) {
                case 'ID':
                    name = columns[i];
                    displayName = 'GENERAL.ID';
                    width = columns[i].length + 2;
                    break;
                case 'SCHEDULING_NAME':
                    name = columns[i];
                    displayName = 'GENERAL.NAME';
                    width = columns[i].length + 15;
                    break;
                case 'SCHEDULING_TYPE':
                    name = columns[i] + '.NAME';
                    displayName = 'GENERAL.REPORT_SCHEDULING_TYPE';
                    width = columns[i].length + 1;
                    break;
                case 'SCHEDULING_DAY':
                    name = columns[i] + '.NAME';
                    displayName = 'GENERAL.REPORT_SCHEDULING_DAY';
                    width = columns[i].length + 1;
                    cellTemplate = '<div class="grid-padding">{{grid.appScope.getConcatReports(row.entity.SCHEDULING_DAY)}}</div>';
                    break;
                case 'SCHEDULING_HOUR':
                    name = columns[i];
                    displayName = 'GENERAL.REPORT_SCHEDULING_HOUR';
                    width = columns[i].length + 1;
                    break;
                case 'SCHEDULING_MINUTE':
                    name = columns[i];
                    displayName = 'GENERAL.REPORT_SCHEDULING_MINUTE';
                    width = columns[i].length + 1;
                    break;
                case 'SEND_TO':
                    name = columns[i];
                    displayName = 'REGISTRATION.RECIPIENT';
                    width = columns[i].length + 1;
                    cellTemplate = '<div class="grid-padding">{{grid.appScope.getConcatInformation(row.entity.SEND_TO)}}</div>';
                    break;
                case 'SEND_CC':
                    name = columns[i];
                    displayName = 'OPERATIONAL.CARBON_COPY';
                    width = columns[i].length + 1;
                    cellTemplate = '<div class="grid-padding">{{grid.appScope.getConcatInformation(row.entity.SEND_CC)}}</div>';
                    break;
                case 'SEND_BCC':
                    name = columns[i];
                    displayName = 'OPERATIONAL.BLIND_CARBON_COPY';
                    width = columns[i].length + 1;
                    cellTemplate = '<div class="grid-padding">{{grid.appScope.getConcatInformation(row.entity.SEND_BCC)}}</div>';
                    break;
                case 'SEND_SUBJECT':
                    name = columns[i];
                    displayName = 'REGISTRATION.SUBJECT';
                    width = columns[i].length + 1;
                    break;
                case 'SEND_TYPE_FILE':
                    name = columns[i] + '.NAME';
                    displayName = 'GENERAL.FILE_TYPE';
                    width = columns[i].length + 1;
                    break;
                case 'ZIP_FILE':
                    name = columns[i];
                    displayName = 'GENERAL.COMPRESS';
                    width = columns[i].length + 1;
                    cellFilter = 'YesOrNo'
                    break;
                case 'REPORTS':
                    name = columns[i] + '.NAME';
                    displayName = 'GENERAL.MENU.REPORT';
                    width = columns[i].length + 1;
                    cellTemplate = '<div class="grid-padding">{{grid.appScope.getConcatReports(row.entity.REPORTS)}}</div>';
                    break;
                case 'SITUATION':
                    name = columns[i] + '.NAME';
                    displayName = 'GENERAL.SITUATION';
                    width = columns[i].length + 1;
                    break;
                case 'CREATED_BY':
                    name = columns[i] + '.NAME';
                    displayName = 'GENERAL.CREATED_BY';
                    width = columns[i].length + 10;
                    break;
                case 'CREATED_DATE':
                    name = columns[i];
                    displayName = 'GENERAL.CREATED_AT';
                    cellFilter = 'date:\'dd/MM/yyyy HH:mm:ss\'';
                    width = columns[i].length;
                    break;
                case 'MODIFIED_BY':
                    name = columns[i] + '.NAME';
                    displayName = 'GENERAL.UPDATED_BY';
                    width = columns[i].length + 10;
                    break;
                case 'MODIFIED_DATE':
                    name = columns[i];
                    displayName = 'GENERAL.UPDATED_AT';
                    cellFilter = 'date:\'dd/MM/yyyy HH:mm:ss\'';
                    width = columns[i].length;
                    break;
                default:
                    name = columns[i];
                    width = columns[i].length;
                    visible = false;
                    break;
            }

            const newColumn = {
                name: name,
                displayName: displayName,
                headerCellClass: 'header-filtered',
                width: width + '%',
                cellTemplate: cellTemplate,
                cellFilter: cellFilter,
                visible: visible,
                grouping: grouping,
                sort: sort,
                pinned: pinned,
                filter: filter
            };
            columnDefs.push(newColumn);
        }
        return columnDefs;
    }

    async initDependencies(): Promise<any> {
        try {
            this.$scope.data_scheduling_days = [];

            const result: Array<any> = await this.$q.all([
                this.getGenericValues('data_scheduling_situations'),
                this.getGenericValues('data_scheduling_types'),
                this.getGenericValues('data_scheduling_file_types'),
                this.getAllReports(),
            ]);
            this.$scope.data_scheduling_situations = result[0].map(item => ({ ID: item.ID,NAME: item.NAME,CODE: null }) );
            this.$scope.data_scheduling_types = result[1].map(item => ({ ID: item.ID, NAME: item.NAME, CODE: null }) );
            this.$scope.data_scheduling_file_types = result[2].map(item => ({ ID: item.ID, NAME: item.NAME, CODE: null }) );
            this.$scope.data_scheduling_reports = result[3].data.map(item => {
                const result: SelectorModel = {
                    ID: item._id,
                    NAME: item.REPORT_NAME,
                    CODE: item.ID,
                }
                return result;
            });

            this.setSchedulingDays(this.$scope.model.SCHEDULING_TYPE.ID);

            return true;

        } catch (ex) {
            throw ex;
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
            this.addEmailField('SEND_TO');
            this.$scope.scopeBeforeSave = null;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")} (${this.$scope.model.ID})`;

            BrowserTitle.$id = this.$scope.model.SCHEDULING_NAME;

        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")} (${this.$scope.model.ID})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);

            BrowserTitle.$id = this.$scope.model.SCHEDULING_NAME;

        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.NEW_REPORT")} (${this.formService.getTranslate("GENERAL.COPIED_FROM")} ${this.$scope.model.SCHEDULING_NAME})`;
            this.$scope.scopeBeforeSave = null;
            this.$scope.model.SCHEDULING_NAME = null;
            await this.clearFields(this.$scope.model);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        let request;
        let customRoute: string = '/scheduling';

        switch (this.$scope.operation) {
            case 'delete':
                customRoute += `/remove/${this.$scope.model._id}`;
                break;
            case 'edit':
                customRoute += '/update';
                break;
            default:
                customRoute += '/insert';
                break;
        }

        request = {
            route: customRoute,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            operation: this.$scope.operation,
            timeout: 15000
        }
        return request;
    }

    setSchedulingDays(type: string): void {
        if (!type || type === '1') return;
        if (type === '2') {
            for (let i = 1; i <= 5; i++) { // weekdays
                this.$scope.data_scheduling_days.push({ ID: i.toString(), NAME: moment.weekdays(i), CODE: i.toString() });
            }
        } else if (type === '3') {
            for (let i = 1; i <= 31; i++) { // month Days
                this.$scope.data_scheduling_days.push({ ID: i.toString(), NAME: i.toString(), CODE: i.toString() });
            }
        }
    }
}

import * as angular from "angular";
import { IColumnDef } from "ui-grid";
import { IGridFormController, IGridFormServiceScope, GridFormService, } from "@services/GridFormService";
import { ReportDataModel, IReportDataColumnDef } from "../model/ReportDataModel";
import { GridService2, IMonacoColumnDef } from "@services/GridService2";
import { IDataFilterRequest } from "../../common/interface/IDataFilterRequest";
import { IGridDataReturn } from "../../common/interface/IGridDataReturn";
import { IRestService } from "@services/RestService";

interface IReportDataScope extends IGridFormServiceScope {
    model: ReportDataModel;
    modalError: string;
    modalSuccess: string;
    scopeBeforeSave: ReportDataModel;
    gridVisibleStatus: boolean;
    gridDataCount: number;
    showData: () => void;
    downloadData: () => void;
    getGridDataLength: () => number;
}

export class ReportDataRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope', 'uiGridExporterConstants', 'uiGridExporterService'];
    //private $injector: ng.Injectable<any>;
    private $scope: IReportDataScope;
    private $timeout: ng.ITimeoutService;
    private RestService: IRestService;
    private reportDataGrid: GridService2;
    private reportDataGridColDef: uiGrid.IColumnDef[];
    private $uiGridExporterConstants: uiGrid.exporter.IUiGridExporterConstants;
    private $exportService: any;

    constructor($injector: ng.Injectable<any>, $scope: IReportDataScope) {
        super($injector, $scope);
        //this.$injector = $injector;
        this.$scope = $scope;
        this.$timeout = $injector.get('$timeout');
        this.$uiGridExporterConstants = $injector.get('uiGridExporterConstants');
        this.$exportService = $injector.get('uiGridExporterService');
        this.RestService = $injector.get('RestService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$scope.gridVisibleStatus = false;
            this.$scope.gridDataCount = 0;
            this.initForm(this, 'form', 'reportData', 'Relatório', true);
            await this.initGrid('gridReport', '/reportData/list', true, true);

        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.showData = () => {
            return this.showData();
        }

        this.$scope.downloadData = () => {
            return this.downloadData();
        }

        this.$scope.getGridDataLength = () => {
            return this.getGridDataLength();
        }
    }

    initGridColumns(columns) {
        const columnDefs: Array<IColumnDef> = new Array();

        //const view = '<div class="text-center"><a ng-click="grid.appScope.view(row.entity)" class="text-info"tooltip-placement="auto top" uib-tooltip="Visualizar" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;'
        const edit = '<div class="text-center"><a ng-click="grid.appScope.edit(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="Editar" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;'
        const copy = '<a ng-click="grid.appScope.copy(row.entity)" class="text-orange"   tooltip-placement="auto top" uib-tooltip="Copiar" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a></div>'

        columnDefs.push({
            name: "acoes",
            displayName: "Ações",
            minWidth: 80,
            maxWidth: 80,
            cellTemplate: (edit + copy),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        for (let i = 0; i < columns.length; i++) {
            let name = null;
            let displayName = null;
            let width = 0;
            let cellTemplate = undefined;
            let cellFilter = null;
            let visible = true;
            let filter: uiGrid.IFilterOptions = { condition: this.gridService.filterSelectObject };
            let searchProps = null;

            switch (columns[i].toUpperCase()) {
                case 'REPORT_NAME':
                    name = columns[i];
                    displayName = 'Nome';
                    width = columns[i].length + 4;
                    break;
                case 'CREATED_BY':
                    name = columns[i];
                    displayName = 'Criado Por';
                    width = columns[i].length + 4;
                    break;
                case 'CREATED_DATE':
                    name = columns[i];
                    displayName = 'Criado Em';
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length + 4;
                    break;
                default:
                    continue;
            }

            const newColumn = {
                name: name,
                displayName: displayName,
                headerCellClass: this.$gridService.highlightFilteredHeader.bind(this.$gridService),
                width: width + '%',
                cellTemplate: cellTemplate,
                cellFilter: cellFilter,
                visible: visible,
                filter: filter,
                searchProps: searchProps,
            };
            columnDefs.push(newColumn);
        }
        return columnDefs;
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            REPORT_NAME: null,
            CREATED_BY: this.$rootScope.user.email,
            CREATED_DATE: new Date,
            COLUMN: null,
            PROCESS: null,
            INVOICE: null,
            CHARGE: null
        };
    }

    initGridColumnsData(columns) {
        const columnDefs: Array<IColumnDef> = new Array();

        for (let i = 0; i < columns.length; i++) {
            let name = null;
            let displayName = null;
            let width = 0;
            let cellTemplate = undefined;
            let cellFilter = null;
            let pinned = null;
            let visible = true;
            let filterCellFiltered = true;
            let searchProps = null;
            let filter: uiGrid.IFilterOptions = null;

            switch (columns[i].toUpperCase()) {
                case 'PROCESS_NUMBER':
                    name = columns[i];
                    displayName = 'Processo';
                    width = columns[i].length / 1.4;
                    break;
                case 'QUOTATION_OFFER':
                    name = columns[i];
                    displayName = 'Oferta';
                    width = columns[i].length;
                    break;
                case 'FORWARDED_BY':
                    name = columns[i] + '.NAME';
                    displayName = 'Encaminhado Por';
                    width = columns[i].length;
                    break;
                case 'CUSTOMER':
                    name = columns[i] + '.NAME';
                    displayName = 'Cliente';
                    width = columns[i].length + 10;
                    break;
                case 'BRANCH':
                    name = columns[i] + '.NAME';
                    displayName = 'Empresa/Filial';
                    width = columns[i].length + 8;
                    break;
                case 'INCOTERM':
                    name = columns[i] + '.CODE';
                    displayName = 'Incoterm';
                    width = columns[i].length;
                    break;
                case 'CARGO_TYPE':
                    name = columns[i] + '.NAME';
                    displayName = 'Tipo de Carga';
                    width = columns[i].length;
                    break;
                case 'PROCESS_TYPE':
                    name = columns[i] + '.NAME';
                    displayName = 'Tipo de Processo';
                    width = columns[i].length;
                    break;
                case 'SERVICE_PROVIDER':
                    name = columns[i] + '.NAME';
                    displayName = 'Prestador de Serviço';
                    width = columns[i].length;
                    break;
                case 'SERVICE':
                    name = columns[i];
                    displayName = 'Serviço';
                    width = columns[i].length;
                    break;
                case 'TTIME':
                    name = columns[i];
                    displayName = 'Transit Time';
                    width = columns[i].length + 6;
                    break;
                case 'SERVICE_TYPE':
                    name = columns[i] + '.NAME';
                    displayName = 'Tipo de Serviço';
                    width = columns[i].length;
                    break;
                case 'ORIGIN_COUNTRY':
                    name = columns[i] + '.NAME';
                    displayName = 'País Origem';
                    width = columns[i].length;
                    break;
                case 'DESTINATION_COUNTRY':
                    name = columns[i] + '.NAME';
                    displayName = 'País Destino';
                    width = columns[i].length;
                    break;
                case 'PICKING':
                    name = columns[i] + '.CODE';
                    displayName = 'Coleta';
                    width = columns[i].length;
                    break;
                case 'RECEIPT_LOCAL':
                    name = columns[i] + '.NAME';
                    displayName = 'Local Recebimento';
                    width = columns[i].length + 5;
                    break;
                case 'RECEIPT':
                    name = columns[i] + '.CODE';
                    displayName = 'Recebimento';
                    width = columns[i].length + 5;
                    break;
                case 'ORIGIN_LOCAL':
                    name = columns[i] + '.NAME';
                    displayName = 'Origem Nome';
                    width = columns[i].length;
                    break;
                case 'ORIGIN':
                    name = columns[i] + '.LOCAL.CODE';
                    displayName = 'Origem';
                    width = columns[i].length;
                    break;
                case 'DESTINATION_LOCAL':
                    name = columns[i] + '.NAME';
                    displayName = 'Destino Nome';
                    width = columns[i].length;
                    break;
                case 'DESTINATION':
                    name = columns[i] + '.LOCAL.CODE';
                    displayName = 'Destino';
                    width = columns[i].length;
                    break;
                case 'FINAL_DESTINATION_LOCAL':
                    name = columns[i] + '.NAME';
                    displayName = 'Destino Final Nome';
                    width = columns[i].length;
                    break;
                case 'FINAL_DESTINATION':
                    name = columns[i] + '.CODE';
                    displayName = 'Destino Final';
                    width = columns[i].length;
                    break;
                case 'DELIVERY':
                    name = columns[i] + '.CODE';
                    displayName = 'Entrega';
                    width = columns[i].length + 5;
                    break;
                case 'DELIVERY_LOCAL':
                    name = columns[i] + '.NAME';
                    displayName = 'Local de Entrega';
                    width = columns[i].length + 5;
                    break;
                case 'ORIGIN_AGENT':
                    name = columns[i] + '.NAME';
                    displayName = 'Agente de Origem';
                    width = columns[i].length + 5;
                    break;
                case 'ORIGIN_AGENT_NIF':
                    name = columns[i];
                    displayName = 'Agente de Origem NIF';
                    width = columns[i].length;
                    break;
                case 'DESTINATION_AGENT':
                    name = columns[i] + '.NAME';
                    displayName = 'Agente de Destino';
                    width = columns[i].length;
                    break;
                case 'DESTINATION_AGENT_NIF':
                    name = columns[i];
                    displayName = 'Agente de Destino NIF';
                    width = columns[i].length;
                    break;
                case 'EXPORTER':
                    name = columns[i] + '.NAME';
                    displayName = 'Exportador';
                    width = columns[i].length + 10;
                    break;
                case 'IMPORTER':
                    name = columns[i] + '.NAME';
                    displayName = 'Importador';
                    width = columns[i].length + 10;
                    break;
                case 'NOTIFY':
                    name = columns[i] + '.NAME';
                    displayName = 'Notify';
                    width = columns[i].length + 10;
                    break;
                case 'BROKER':
                    name = columns[i] + '.NAME';
                    displayName = 'Despachante';
                    width = columns[i].length + 12;
                    break;
                case 'AGENCY':
                    name = columns[i] + '.NAME';
                    displayName = 'Agência';
                    width = columns[i].length + 12;
                    break;
                case 'CARGO_DETAIL_INSURANCE_INSURANCE_BROKER':
                    name = columns[i];
                    displayName = 'Seguradora';
                    width = columns[i].length / 2.1;
                    break;
                case 'EXTERNAL_AGENT_NETWORK':
                    name = columns[i] + '.NAME';
                    displayName = 'Network Agente';
                    width = columns[i].length;
                    break;
                case 'INDICATION':
                    name = columns[i] + '.NAME';
                    displayName = 'Indicação';
                    width = columns[i].length;
                    break;
                case 'CUSTOMER_CELL':
                    name = columns[i] + '.NAME';
                    displayName = 'Célula do Cliente';
                    width = columns[i].length;
                    break;
                case 'SITUATION':
                    name = columns[i] + '.NAME';
                    displayName = 'Situação';
                    width = columns[i].length + 5;
                    break;
                case 'ENGLISH_SITUATION':
                    name = columns[i] + '.ALTERNATIVE';
                    displayName = 'Situation';
                    width = columns[i].length;
                    break;
                case 'PRIORITY':
                    name = columns[i] + '.NAME';
                    displayName = 'Prioridade';
                    width = columns[i].length;
                    break;
                case 'VALIDITY_OF':
                    name = columns[i];
                    displayName = 'Validade De';
                    width = columns[i].length;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'VALIDITY_UP':
                    name = columns[i];
                    displayName = 'Validade Até';
                    width = columns[i].length;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'QUOTATION_OFFER_ID':
                    name = columns[i];
                    displayName = 'ID Oferta';
                    width = columns[i].length / 2;
                    break;
                case 'HOUSE_PAYMENT':
                    name = columns[i] + '.NAME';
                    displayName = 'Pagamento House';
                    width = columns[i].length;
                    break;
                case 'MASTER_PAYMENT':
                    name = columns[i] + '.NAME';
                    displayName = 'Pagamento Master';
                    width = columns[i].length;
                    break;
                case 'PRODUCT':
                    name = columns[i] + '.NAME';
                    displayName = 'Produto';
                    width = columns[i].length + 5;
                    break;
                case 'CREATED_DATE':
                    name = columns[i];
                    displayName = 'Criado Em';
                    width = columns[i].length / 1.5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'CREATED_BY':
                    name = columns[i];
                    displayName = 'Criado Por';
                    width = columns[i].length + 10;
                    break;
                case 'MODIFIED_DATE':
                    name = columns[i];
                    displayName = 'Modificado Em';
                    width = columns[i].length / 1.5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'MODIFIED_BY':
                    name = columns[i];
                    displayName = 'Modificado Por';
                    width = columns[i].length;
                    break;
                case 'MODAL':
                    name = columns[i] + '.NAME';
                    displayName = 'Modal';
                    width = columns[i].length + 5;
                    break;
                case 'OPERATION':
                    name = columns[i] + '.NAME';
                    displayName = 'Operação';
                    width = columns[i].length;
                    break;
                case 'FREIGHT_CONTRACT':
                    name = columns[i] + '.NAME';
                    displayName = 'Contrato de Frete';
                    width = columns[i].length;
                    break;
                case 'SHIPMENT_REQUIRED_DATE_OF':
                    name = columns[i];
                    displayName = 'Embarque Solicitado De';
                    width = columns[i].length / 1.5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'SHIPMENT_REQUIRED_DATE_UP':
                    name = columns[i];
                    displayName = 'Embarque Solicitado Até';
                    width = columns[i].length / 1.5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'LI':
                    name = columns[i];
                    displayName = 'LI';
                    width = columns[i].length + 5;
                    break;
                case 'MERCHAN':
                    name = columns[i];
                    displayName = 'Mercadoria';
                    width = columns[i].length + 5;
                    break;
                case 'MERCHAN_GROUP':
                    name = columns[i];
                    displayName = 'Grupo de Mercadoria';
                    width = columns[i].length;
                    break;
                case 'COMPARED_BY':
                    name = columns[i];
                    displayName = 'Comparado Por';
                    width = columns[i].length + 3;
                    break;
                case 'COMPARED_DATE':
                    name = columns[i];
                    displayName = 'Comparado Em';
                    width = columns[i].length;
                    cellFilter = `datetimeformated`;
                    break;
                case 'LOAD_VESSEL_VOY':
                    name = columns[i];
                    displayName = 'Navio/Viagem de Emb.';
                    width = columns[i].length;
                    break;
                case 'LOAD_VESSEL':
                    name = columns[i];
                    displayName = 'Navio';
                    width = columns[i].length;
                    break;
                case 'LOAD_VOY':
                    name = columns[i];
                    displayName = 'Viagem';
                    width = columns[i].length;
                    break;
                case 'DISCH_TRSHP_VESSEL_VOY':
                    name = columns[i];
                    displayName = 'Navio/Viagem Desemb. de Transbordo';
                    width = columns[i].length;
                    break;
                case 'LOAD_TRSHP_VESSEL_VOY':
                    name = columns[i];
                    displayName = 'Navio/Viagem Emb. de Transbordo';
                    width = columns[i].length;
                    break;
                case 'DISCH_VESSEL_VOY':
                    name = columns[i];
                    displayName = 'Navio de Desembarque';
                    width = columns[i].length;
                    break;
                case 'LOAD_BOOKING':
                    name = columns[i];
                    displayName = 'Reserva';
                    width = columns[i].length / 1.5;
                    break;
                case 'LOAD_FORECAST_DATE':
                    name = columns[i];
                    displayName = 'Previsão de embarque';
                    width = columns[i].length;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'LOAD_EFFECTIVE_DATE':
                    name = columns[i];
                    displayName = 'Embarque efetivo';
                    width = columns[i].length / 1.5;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'DISCH_FORECAST_DATE':
                    name = columns[i];
                    displayName = 'Previsão de desembarque';
                    width = columns[i].length;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'DISCH_EFFECTIVE_DATE':
                    name = columns[i];
                    displayName = 'Desembarque efetivo';
                    width = columns[i].length;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'DISCH_TERMINAL_REDIRECT':
                    name = columns[i];
                    displayName = 'Terminal de redestinação';
                    width = columns[i].length;
                    break;
                case 'DELIV_FORECAST_DATE':
                    name = columns[i];
                    displayName = 'Previsão de entrega';
                    width = columns[i].length / 1.2;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'DELIV_EFFECTIVE_DATE':
                    name = columns[i];
                    displayName = 'Entrega efetiva';
                    width = columns[i].length / 2;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'TRSHP_DISCH_FORECAST_DATE':
                    name = columns[i];
                    displayName = 'Previsão de desembarque de transbordo';
                    width = columns[i].length;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'TRSHP_DISCH_EFFECTIVE_DATE':
                    name = columns[i];
                    displayName = 'Desembarque de transbordo';
                    width = columns[i].length / 1.2;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'TRSHP_LOAD_FORECAST_DATE':
                    name = columns[i];
                    displayName = 'Previsão de embarque de transbordo';
                    width = columns[i].length;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'TRSHP_LOAD_EFFECTIVE_DATE':
                    name = columns[i];
                    displayName = 'Embarque de transbordo';
                    width = columns[i].length / 1.2;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'PICK_FORECAST_DATE':
                    name = columns[i];
                    displayName = 'Previsão de coleta';
                    width = columns[i].length / 1.2;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'PICK_EFFECTIVE_DATE':
                    name = columns[i];
                    displayName = 'Coleta efetiva';
                    width = columns[i].length / 1.2;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'FORWARDING_SITUATION':
                    name = columns[i] + ".SITUATION.NAME";
                    displayName = 'Situação do agenciamento';
                    width = columns[i].length;
                    break;
                case 'DOCUMENT_HBL':
                    name = columns[i];
                    displayName = 'Número do HBL';
                    width = columns[i].length;
                    break;
                case 'DOCUMENT_MBL':
                    name = columns[i];
                    displayName = 'Número do MBL';
                    width = columns[i].length;
                    break;
                case 'QTYXEQP':
                    name = columns[i];
                    displayName = 'Qty x Eqp';
                    width = columns[i].length;
                    break;
                case 'BOOKING_STATUS':
                    name = columns[i] + ".NAME";
                    displayName = 'Status do Booking';
                    width = columns[i].length;
                    break;
                case 'EQUIPMENTS':
                    name = columns[i];
                    displayName = 'Conteiner(s)';
                    width = columns[i].length;
                    break;
                case 'CUSTOMER_REF':
                    name = columns[i];
                    displayName = 'Ref. Principal Cliente';
                    width = columns[i].length;
                    break;
                case 'CUSTOMER_OTHER_REF':
                    name = columns[i];
                    displayName = 'Demais Ref. Cliente';
                    width = columns[i].length;
                    break;
                case 'AGENT_REF':
                    name = columns[i];
                    displayName = 'Ref. Agente';
                    width = columns[i].length + 5;
                    break;
                case 'EQP_40':
                    name = columns[i] + ".TOTAL";
                    displayName = 'Total de 40\'';
                    width = columns[i].length + 6;
                    break;
                case 'EQP_20':
                    name = columns[i] + ".TOTAL";
                    displayName = 'Total de 20\'';
                    width = columns[i].length + 6;
                    break;
                case 'FREE_TIME_OFTH':
                    name = columns[i];
                    displayName = 'Free Time Origem House';
                    width = columns[i].length;
                    break;
                case 'FREE_TIME_OFTM':
                    name = columns[i];
                    displayName = 'Free Time Origem Master';
                    width = columns[i].length;
                    break;
                case 'FREE_TIME_DFTH':
                    name = columns[i];
                    displayName = 'Free Time Destino House';
                    width = columns[i].length;
                    break;
                case 'FREE_TIME_DFTM':
                    name = columns[i];
                    displayName = 'Free Time Destino Master';
                    width = columns[i].length;
                    break;
                case 'TEUS':
                    name = columns[i];
                    displayName = 'Total de TEUS';
                    width = columns[i].length + 6;
                    break;
                case 'CS_PROCESS_OWNER':
                    name = columns[i] + ".USER.NAME";
                    displayName = 'C/S Responsável';
                    width = columns[i].length;
                    break;
                case 'SALES_PERSON':
                    name = columns[i] + ".NAME";
                    displayName = 'Vendedor Externo';
                    width = columns[i].length;
                    break;
                case 'INSIDE_SALES_PERSON':
                    name = columns[i] + ".NAME";
                    displayName = 'Vendedor Interno';
                    width = columns[i].length;
                    break;
                case 'BILLING_RELEASE':
                    name = columns[i] + ".DATE";
                    displayName = 'Liberado Faturamento';
                    width = columns[i].length;
                    cellFilter = `datetimeformated`;
                    break;
                case 'CHECK_VALUE':
                    name = columns[i] + ".DATE";
                    displayName = 'Check de Valores';
                    width = columns[i].length;
                    cellFilter = `datetimeformated`;
                    break;
                case 'CANCELLATION':
                    name = columns[i] + ".DATE";
                    displayName = 'Cancelamento';
                    width = columns[i].length;
                    cellFilter = `datetimeformated`;
                    break;
                case 'PAYMENT_SITUATION':
                    name = columns[i] + ".SITUATION.NAME";
                    displayName = 'Situação de pagamento';
                    width = columns[i].length;
                    break;
                case 'RECEIPT_SITUATION':
                    name = columns[i] + ".SITUATION.NAME";
                    displayName = 'Situação de recebimento';
                    width = columns[i].length;
                    break;
                case 'AGENT_SETTLEMENT_SITUATION':
                    name = columns[i] + ".SITUATION.NAME";
                    displayName = 'Acerto agente';
                    width = columns[i].length;
                    break;
                case 'CE_HOUSE':
                    name = columns[i];
                    displayName = 'CE Mercante';
                    width = columns[i].length + 5;
                    break;
                case 'DEADLINE_REGULAR_HOUSE':
                    name = columns[i];
                    displayName = 'Deadline Regular House';
                    width = columns[i].length / 1.5;
                    cellFilter = `datetimeformated`;
                    break;
                case 'DEADLINE_RELEASE_HOUSE':
                    name = columns[i];
                    displayName = 'Deadline Release House';
                    width = columns[i].length / 1.5;
                    cellFilter = `datetimeformated`;
                    break;
                case 'DEADLINE_VGM_HOUSE':
                    name = columns[i];
                    displayName = 'Deadline VGM House';
                    width = columns[i].length / 1.5;
                    cellFilter = `datetimeformated`;
                    break;

                case 'DEADLINE_DAY_REGULAR_HOUSE':
                    name = columns[i];
                    displayName = 'Dia Deadline Regular House';
                    width = columns[i].length / 1.5;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'DEADLINE_DAY_RELEASE_HOUSE':
                    name = columns[i];
                    displayName = 'Dia Deadline Release House';
                    width = columns[i].length / 1.5;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'DEADLINE_DAY_VGM_HOUSE':
                    name = columns[i];
                    displayName = 'Dia Deadline VGM House';
                    width = columns[i].length / 1.5;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'DEADLINE_HOUR_REGULAR_HOUSE':
                    name = columns[i];
                    displayName = 'Hora Deadline Regular House';
                    width = columns[i].length / 1.5;
                    cellFilter = `hourminute`;
                    break;
                case 'DEADLINE_HOUR_RELEASE_HOUSE':
                    name = columns[i];
                    displayName = 'Hora Deadline Release House';
                    width = columns[i].length / 1.5;
                    cellFilter = `hourminute`;
                    break;
                case 'DEADLINE_HOUR_VGM_HOUSE':
                    name = columns[i];
                    displayName = 'Hora Deadline VGM House';
                    width = columns[i].length / 1.5;
                    cellFilter = `hourminute`;
                    break;
                case 'SCAC_MASTER':
                    name = columns[i];
                    displayName = 'SCAC Master';
                    width = columns[i].length;
                    break;
                case 'SCAC_HOUSE':
                    name = columns[i];
                    displayName = 'SCAC House';
                    width = columns[i].length;
                    break;
                case 'END_DATE':
                    name = columns[i];
                    displayName = 'Finalização do processo';
                    width = columns[i].length + 6;
                    cellFilter = `datetimeformated`;
                    break;
                case 'GP_ESTIMATED':
                    name = columns[i] + ".TOTAL";
                    cellFilter = `number: 2`;
                    displayName = 'GP Corrente Estimado';
                    width = columns[i].length + 3;
                    break;
                case 'GP_EFFECTIVE':
                    name = columns[i] + ".TOTAL";
                    cellFilter = `number: 2`;
                    displayName = 'GP Corrente Efetivo';
                    width = columns[i].length;
                    break;
                case 'FOLLOW_UP_CONTENT':
                    name = columns[i];
                    cellTemplate = '<div class="ui-grid-cell-contents" ng-bind-html="row.entity.FOLLOW_UP_CONTENT"><div>';
                    displayName = 'Follow Up';
                    cellFilter = 'purgeFollowUpHTML';
                    width = columns[i].length + 15;
                    break;
                case 'INVOICE_NUMBER':
                    name = columns[i];
                    displayName = 'Fatura';
                    width = columns[i].length / 1.5;
                    break;
                case 'CONTRACT_PAYMENT_CONDITION':
                    name = columns[i] + ".NAME";
                    displayName = 'Condição de Pagamento';
                    width = columns[i].length / 1.2;
                    break;
                case 'CONTRACT_D0':
                    name = columns[i];
                    displayName = 'Prazo';
                    width = columns[i].length / 1.2;
                    break;
                case 'CONTRACT_NUMBER':
                    name = columns[i];
                    displayName = 'Contrato';
                    width = columns[i].length / 1.2;
                    break;
                case 'PEOPLE':
                    name = columns[i] + '.NAME';
                    displayName = 'Pessoa';
                    width = columns[i].length + 15;
                    break;
                case 'TOTAL':
                    name = columns[i];
                    displayName = 'Total';
                    cellTemplate = '<div class="ui-grid-cell-contents">{{row.entity.TOTAL | number: 2}}<div>';
                    width = columns[i].length;
                    break;
                case 'PARTIAL_PAYMENT':
                    name = columns[i];
                    displayName = 'Pagamento Parcial';
                    cellTemplate = '<div class="ui-grid-cell-contents">{{row.entity.PARTIAL_PAYMENT | number: 2}}<div>';
                    width = columns[i].length;
                    break;
                case 'ORIGINAL_TOTAL':
                    name = columns[i];
                    displayName = 'Total Original';
                    cellTemplate = '<div class="ui-grid-cell-contents">{{row.entity.ORIGINAL_TOTAL | number: 2}}<div>';
                    width = columns[i].length / 1.5;
                    break;
                case 'DUE_DATE':
                    name = columns[i];
                    displayName = 'Data de Vencimento';
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length + 5;
                    break;
                case 'LOCKED':
                    name = columns[i];
                    displayName = 'Cadeada';
                    cellFilter = 'YesOrNo';
                    width = columns[i].length + 3;
                    break;
                case 'LOCKED_DATE':
                    name = columns[i];
                    displayName = 'Data da Geração';
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length;
                    break;
                case 'LIQUIDATION_DATE':
                    name = columns[i];
                    displayName = 'Data da Baixa Efetiva';
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length / 1.2;
                    break;
                case 'NATURE':
                    name = columns[i] + '.NAME';
                    displayName = 'Natureza';
                    width = columns[i].length + 2;
                    break;
                case 'TRANSACTION':
                    name = columns[i] + '.NAME';
                    displayName = 'Transação';
                    width = columns[i].length + 4;
                    break;
                case 'STATUS':
                    name = columns[i] + '.NAME';
                    displayName = 'Status';
                    width = columns[i].length;
                    break;
                case 'APPROVAL_DATE':
                    name = columns[i];
                    displayName = 'Data de Aprovação';
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length;
                    break;
                case 'THIRDPARTY_DATE':
                    name = columns[i];
                    displayName = 'Invoice Terceiro';
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length;
                    break;
                case 'NEGOTIATED':
                    name = columns[i];
                    displayName = 'Negociada';
                    cellFilter = 'YesOrNo';
                    width = columns[i].length;
                    break;
                case 'PAYMENT_PERIOD':
                    name = columns[i] + '.NAME';
                    displayName = 'Período do Pagamento';
                    width = columns[i].length + 4;
                    break;
                case 'PAYMENT_METHOD':
                    name = columns[i] + '.NAME';
                    displayName = 'Forma de Cobrança';
                    width = columns[i].length;
                    break;
                case 'CLIENT_ACCOUNT':
                    name = columns[i] + '.ACCOUNT.NAME';
                    displayName = 'Conta Corrente';
                    width = columns[i].length;
                    break;
                case 'PAYMENT_CONDITION':
                    name = columns[i] + '.NAME';
                    displayName = 'Condição Fatura';
                    width = columns[i].length;
                    break;
                case 'HC2_FINANCIAL_RECORD':
                    name = columns[i];
                    displayName = 'Ref. Integração HC2';
                    width = columns[i].length + 1;
                    visible = false;
                    break;
                case 'SUBMISSION_DATE':
                    name = columns[i];
                    displayName = 'Data Envio Draft';
                    width = columns[i].length + 1;
                    break;
                case 'EXCH_INVOICE':
                    name = columns[i];
                    displayName = 'Câmbio Invoice';
                    width = columns[i].length + 2;
                    break;
                case 'EXCH_CONTRACT':
                    name = columns[i];
                    displayName = 'Câmbio Contrato';
                    width = columns[i].length + 2;
                    break;
                case 'EXCH_COUNTRY':
                    name = columns[i];
                    displayName = 'Câmbio País';
                    width = columns[i].length + 2;
                    break;
                case 'EXCH_CHARGE':
                    name = columns[i];
                    displayName = 'Câmbio Taxa';
                    width = columns[i].length + 2;
                    break;
                case 'EXCH_EXCHANGE':
                    name = columns[i];
                    displayName = 'Câmbio Modalidade';
                    width = columns[i].length + 2;
                    break;
                case 'EXCH_BROKER':
                    name = columns[i];
                    displayName = 'Câmbio Corretora';
                    width = columns[i].length + 2;
                    break;
                case 'EXCH_DATESHP':
                    name = columns[i];
                    displayName = 'Câmbio Data Remessa';
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length + 2;
                    break;
                case 'EXCH_DATEORD':
                    name = columns[i];
                    displayName = 'Câmbio Data da Ordem';
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length + 2;
                    break;
                case 'CHR_NUMBER':
                    name = columns[i];
                    displayName = 'Número da taxa';
                    width = columns[i].length + 10;
                    break;
                case 'CHR_CURRENCY':
                    name = columns[i] + ".NAME";
                    displayName = 'Taxa Moeda';
                    width = columns[i].length + 10;
                    break;
                case 'CHR_TYPE':
                    name = columns[i];
                    displayName = 'Tipo de taxa';
                    width = columns[i].length + 1;
                    break;
                case 'CHR_NAME':
                    name = columns[i];
                    displayName = 'Taxa';
                    width = columns[i].length + 1;
                    break;
                case 'CHR_AMOUNT':
                    name = columns[i];
                    displayName = 'Taxa montante';
                    width = columns[i].length + 1;
                    break;
                case 'CHR_APPLICATION':
                    name = columns[i] + ".NAME";
                    displayName = 'Taxa aplicação';
                    width = columns[i].length / 1.5;
                    break;
                case 'CHR_MINIMUM':
                    name = columns[i];
                    displayName = 'Taxa mínima';
                    cellFilter = 'number:2';
                    width = columns[i].length / 1.1;
                    break;
                case 'CHR_UNITARY':
                    name = columns[i];
                    displayName = 'Taxa unitária';
                    cellFilter = 'number:2';
                    width = columns[i].length / 1.1;
                    break;
                case 'CHR_TOTAL':
                    name = columns[i];
                    displayName = 'Taxa total';
                    cellFilter = 'number:2';
                    width = columns[i].length / 1.1;
                    break;
                case 'CHR_CONVERSION_SPREAD':
                    name = columns[i];
                    displayName = 'Taxa de conversão';
                    cellFilter = 'number:6';
                    width = columns[i].length / 1.5;
                    break;
                case 'CHR_OBSERVATION':
                    name = columns[i];
                    displayName = 'Taxa Observações';
                    width = columns[i].length + 11;
                    visible = false;
                    break;
                case 'CHR_NATURE':
                    name = columns[i];
                    displayName = 'Natureza';
                    width = columns[i].length + 5;
                    break;
                case 'CHR_EXHIBITION':
                    name = columns[i];
                    displayName = 'Taxa Exibição';
                    width = columns[i].length + 5;
                    break;
                default:
                    continue;
            }
            const newColumn: IMonacoColumnDef = {
                searchProps: searchProps,
                name: name,
                field: name,
                displayName: displayName,
                headerCellClass: 'header-filtered',
                width: width + '%',
                cellTemplate: cellTemplate,
                cellFilter: cellFilter,
                filterCellFiltered: filterCellFiltered,
                pinnedLeft: pinned,
                visible: visible,
                headerCellTemplate: require("../../app/view/template/monaco-grid-cell-template.html"),
                filter: filter,
            };
            columnDefs.push(newColumn);
        }

        return columnDefs;
    }

    public getGridDataLength() {
        return (this.reportDataGrid && this.reportDataGrid.$gridOptions && this.reportDataGrid.$gridOptions.data) ? this.reportDataGrid.$gridOptions.data.length : 0;
    }

    private async showData(): Promise<void> {
        try {
            if (!this.$scope.model.PROCESS && !this.$scope.model.INVOICE && !this.$scope.model.CHARGE) {
                this.$scope.gridVisibleStatus = false;
                this.$scope.$applyAsync();
                this.$formService.notifyError("Selecione um Grupo de Dados para mostrar as colunas disponíveis");
                return;
            }

            this.block();

            //destroy previous grid report data
            this.$scope.gridVisibleStatus = false;

            //wait for a digest cycle
            this.$timeout(async () => {
                if (this.reportDataGrid) this.reportDataGrid.destruct();
                this.reportDataGrid = null;

                this.$scope.gridVisibleStatus = true;
                const showProcess = (this.$scope.model.PROCESS) ? 1 : 0;
                const showInvoice = (this.$scope.model.INVOICE) ? 1 : 0;
                const showCharge = (this.$scope.model.CHARGE) ? 1 : 0;

                //const grid = this.$scope.gridServicePool.getGrid(this.gridData);
                this.reportDataGrid = new GridService2<ReportDataModel>(this.$injector, this.$scope, this, 'gridReportData');

                // disable pagination
                this.reportDataGrid.$gridOptions.enablePagination = true;
                this.reportDataGrid.$gridOptions.enablePaginationControls = true;
                this.reportDataGrid.$gridOptions.paginationPageSizes = [100, 200, 500, 1000, 2000];
                this.reportDataGrid.$gridOptions.paginationPageSize = 100;
                this.reportDataGrid.$gridOptions.exporterCsvFilename = `${(this.$scope.model.REPORT_NAME) ? this.$scope.model.REPORT_NAME : 'export'}.csv`;
                this.reportDataGrid.$gridOptions.exporterMenuAllData = true;
                this.reportDataGrid.$gridOptions.exporterMenuExcel = false;
                this.reportDataGrid.$gridOptions.exporterMenuPdf = false;
                this.reportDataGrid.$gridOptions.exporterMenuVisibleData = false;
                this.reportDataGrid.$gridOptions.enableSorting = false;
                this.reportDataGrid.$gridOptions.exporterAllDataFn = this.requestReportExport.bind(this);

                //init grid - we're basically supressing intial data request
                const result: IGridDataReturn = await this.RestService.getObjectAsPromise(`/reportData/columns/${showProcess}/${showInvoice}/${showCharge}`, 10000);
                if (!result) return this.handleError('Nenhuma listagem de colunas encontrada');
                const columnsList = result.columns;
                const columnsDefList = (columnsList && columnsList.length > 0) ? await this.initGridColumnsData(columnsList) : [];
                if (!columnsDefList || columnsDefList.length == 0) return this.handleError('Nenhuma definição de colunas encontrada.');
                const reportDataRoute = `/reportData/execute2/${showProcess}/${showInvoice}/${showCharge}`;
                this.reportDataGrid.$requestGridDataTimeout = 300000;
                await this.reportDataGrid.init('gridReportData', reportDataRoute, columnsDefList, [], false, true, true);

                this.reportDataGrid.$gridOptions.gridMenuCustomItems[1].action = this.restoreReport.bind(this);

                const hasCompositionChanged = (
                    this.$scope.scopeBeforeSave.PROCESS != this.$scope.model.PROCESS ||
                    this.$scope.scopeBeforeSave.INVOICE != this.$scope.model.INVOICE ||
                    this.$scope.scopeBeforeSave.CHARGE != this.$scope.model.CHARGE
                )

                this.reportDataGridColDef = angular.copy(this.reportDataGrid.$gridOptions.columnDefs);
                const gridState = this.reportDataGrid.$gridApi.saveState.save();

                if (this.$scope.model.COLUMN) {
                    for (let index = this.$scope.model.COLUMN.length - 1; index >= 0; index--) {
                        const reportColumn = this.$scope.model.COLUMN[index];
                        const gridColumn = this.reportDataGrid.$gridOptions.columnDefs.find(storedCol => storedCol.name == reportColumn.name);

                        if (!gridColumn) {
                            if (!hasCompositionChanged) gridColumn.visible = false;
                        } else {
                            if (reportColumn.alias) {
                                gridColumn.displayName = reportColumn.alias;
                            }

                            if (reportColumn.visible) {
                                const positionColumn = this.reportDataGrid.$gridOptions.columnDefs.indexOf(gridColumn);
                                if (positionColumn >= 0) {
                                    this.reportDataGrid.$gridOptions.columnDefs.splice(positionColumn, 1);
                                    this.reportDataGrid.$gridOptions.columnDefs.unshift(gridColumn);
                                }
                            }
                        }
                    }
                }

                this.reportDataGrid.$gridApi.core.notifyDataChange(this.reportDataGrid.$gridConstants.dataChange.COLUMN);

                gridState.columns = (this.$scope.model.COLUMN) ? this.$scope.model.COLUMN : gridState.columns;

                this.reportDataGrid.$gridApi.saveState.restore(this.$scope, gridState);

                await this.reportDataGrid.filterChangedEventCallback();

                this.$scope.$apply();
                this.unblock();
            }).catch(x => {
                this.unblock();
                this.$formService.handleLoadError(x);
            })

        } catch (ex) {
            this.unblock();
            this.$formService.handleLoadError(ex);
        }
    }

    private async restoreReport() {
        try {
            this.block();
            this.reportDataGrid.$gridOptions.columnDefs = angular.copy(this.reportDataGridColDef);
            await this.reportDataGrid.setDefaultView();
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async requestReportExport() {
        try {
            this.block();

            //await this.reportDataGrid.applyGridFilterAndUpdate();
            //await this.reportDataGrid.filterChangedEventCallback();
            this.reportDataGrid.$gridApi.core.notifyDataChange(this.reportDataGrid.$gridConstants.dataChange.COLUMN);
            const requestParam: IDataFilterRequest = await this.reportDataGrid.getFilterReqParams();

            const showProcess = (this.$scope.model.PROCESS) ? 1 : 0;
            const showInvoice = (this.$scope.model.INVOICE) ? 1 : 0;
            const showCharge = (this.$scope.model.CHARGE) ? 1 : 0;
            const downloadRoute = `/reportData/download2/${showProcess}/${showInvoice}/${showCharge}`;

            console.log('exec gridRoute: ', this.reportDataGrid.$gridRoute);
            console.log('download gridRoute: ', downloadRoute);
            console.log('enablePagination: ', this.reportDataGrid.$gridOptions.enablePagination);
            console.log('requestParam: ', requestParam);

            if (requestParam.datafilter.limits) delete requestParam.datafilter.limits;
            requestParam['timeout'] = 600000;
            const $http: ng.IHttpService = this.$injector.get('$http');
            try {
                const downloadOperation = $http(
                    {
                        method: 'POST',
                        url: `${this.reportDataGrid.$baseUrl}${downloadRoute}`,
                        data: JSON.stringify(requestParam),
                        transformRequest: angular.identity,
                        headers: { 'Content-Type': 'application/json' },
                        cache: true,
                        timeout: requestParam['timeout'],
                        withCredentials: false,
                        eventHandlers: {
                            timeout: (event: ProgressEvent | ErrorEvent) => {
                                console.log(event);
                            },
                            abort: (event: ProgressEvent | ErrorEvent) => {
                                console.log(event);
                            },
                            error: (event: ProgressEvent | ErrorEvent) => {
                                console.log(event);
                            },
                            loadstart: (event: ProgressEvent) => {
                                console.log(event);
                            },
                            progress: (event: ProgressEvent) => {
                                console.log(event);
                            },
                            load: (event: ProgressEvent) => {
                                console.log(event);
                            },
                            loadend: (event: ProgressEvent) => {
                                console.log(event);
                            }
                        }
                    });

                //exec operation
                const downloadResponse = await downloadOperation;
                //cancel timeout timer
                //this.$timeout.cancel(timeoutPromise);

                const downloadData = <Array<any>>(downloadResponse.data['data']) ? downloadResponse.data['data'] : downloadResponse.data;
                console.log('download data length: ', downloadData.length);

                this.unblock();

                return downloadData;

            } catch (responseError) {
                //this.$timeout.cancel(timeoutPromise);
                this.handleError(responseError);
            }


            //const result = await $http.post(`${this.reportDataGrid.$baseUrl}${downloadRoute}`, requestParam, { timeout: requestParam['timeout'] });
            //$http.post(`${this.reportDataGrid.$baseUrl}${downloadRoute}`, requestParam, { timeout: requestParam['timeout'], responseType: 'json' }).then(result => {
            /*             $http.post(`${this.reportDataGrid.$baseUrl}${downloadRoute}`, requestParam, { timeout: requestParam['timeout'] }).then(result => {
                            const downloadData = <Array<any>>result.data;
                            console.log('download data length: ', downloadData.length);
            
                            this.unblock();
            
                            return result.data;
                        }).catch(ex => {
                            this.handleError(ex);
                        }); */


        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async downloadData(): Promise<void> {
        try {
            this.block();
            this.reportDataGrid.$gridApi.core.notifyDataChange(this.reportDataGrid.$gridConstants.dataChange.COLUMN);
            const _self: ReportDataRegisterController = this;
            this.$exportService.loadAllDataIfNeeded(this.reportDataGrid.$gridApi.grid, this.$uiGridExporterConstants.ALL, this.$uiGridExporterConstants.VISIBLE).then(function () {
                const grid = _self.reportDataGrid.$gridApi.grid;
                const options = _self.reportDataGrid.$gridOptions;
                const exportColumnHeaders = _self.$exportService.getColumnHeaders(grid, _self.$uiGridExporterConstants.VISIBLE);
                const exportData = _self.$exportService.getData(grid, _self.$uiGridExporterConstants.ALL, _self.$uiGridExporterConstants.VISIBLE);
                const csvContent = _self.$exportService.formatAsCsv(exportColumnHeaders, exportData, options.exporterCsvColumnSeparator);
                _self.$exportService.downloadFile(options.exporterCsvFilename, csvContent, options.exporterOlderExcelCompatibility);
            });
            this.$scope.$applyAsync();
            this.unblock();
        } catch (ex) {
            this.unblock();
            this.$formService.handleLoadError(ex);
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.formOperation = `Novo Relatório`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.gridVisibleStatus = false;
            // in case that has some data
            if (this.reportDataGrid && this.reportDataGrid.$gridOptions) this.reportDataGrid.$gridOptions.data = null;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.formOperation = `Novo Relatório (Cópia de ${this.$scope.model.REPORT_NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.model.REPORT_NAME = null;
            this.$scope.gridVisibleStatus = false;
            // in case that has some data
            if (this.reportDataGrid && this.reportDataGrid.$gridOptions) this.reportDataGrid.$gridOptions.data = null;
            await this.clearFields(this.$scope.model);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `Editando (${this.$scope.model.REPORT_NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.gridVisibleStatus = false;
            // in case that has some data
            if (this.reportDataGrid && this.reportDataGrid.$gridOptions) this.reportDataGrid.$gridOptions.data = null;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request() {
        const request = {
            operation: this.$scope.operation,
            data: this.$scope.model,
            timeout: 15000
        };
        return request;
    }

    async save(): Promise<boolean> {
        try {
            if (this.reportDataGrid) {
                const gridState = this.reportDataGrid.$gridApi.saveState.save();
                this.$scope.model.COLUMN = gridState.columns.map(x => {
                    const colDef = this.reportDataGrid.$gridOptions.columnDefs.find(col => col.name == x.name);
                    const teste: IReportDataColumnDef = {
                        alias: (colDef) ? colDef.displayName : null,
                        filters: x.filters,
                        name: x.name,
                        pinned: x.pinned,
                        sort: x.sort,
                        visible: x.visible,
                        width: x.width,
                    };
                    return teste;
                });
            }
            return true;
        } catch (ex) {
            this.$formService.handleLoadError(ex);
        }
        return false;
    }

    private arrayBufferToString(buffer) {
        const bufView = new Uint16Array(buffer);
        const length = bufView.length;
        let result = '';
        let addition = Math.pow(2, 16) - 1;

        for (var i = 0; i < length; i += addition) {

            if (i + addition > length) {
                addition = length - i;
            }
            result += String.fromCharCode.apply(null, bufView.subarray(i, i + addition));
        }
        return result;
    }

    private bin2String(array) {
        let result = "";
        for (var i = 0; i < array.length; i++) {
            result += String.fromCharCode(parseInt(array[i], 2));
        }
        return result;
    }
}

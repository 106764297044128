//https://github.com/mozilla/pdf.js/issues/8088

import '../../../libs/pdf.min.js';

StorageDownloadController.$inject = ['$rootScope', '$scope', '$stateParams', 'ErrorService', 'blockUI', '$window', '$templateCache', 'DownloadService'];
export function StorageDownloadController($rootScope, $scope, $stateParams, ErrorService, blockUI, $window, $templateCache, DownloadService) {

    this.$onInit = function () {
        if (!$stateParams.hash) {
            return ErrorService.badRequest('The request contains Null Parameters');
        }
        // Block the user interface
        blockUI.start({ message: 'Fazendo download do arquivo' });

        const hash = $stateParams.hash;
        //window.PDFJS.workerSrc = '../../../vendor1';

        $templateCache.put("pdfViewerMenuTemplate", require("../view/storagePDFViewerMenu.html"));
        $scope.file = undefined;
        $scope.type = undefined;

        DownloadService.previewFileFromGoogleStorage(hash).then(function (result) {
            $scope.file = result;

            if (result.type.indexOf('image') === 0) {
                $scope.type = 'image';
                previewImage();
            } else if (result.type === 'application/pdf') {
                $scope.type = 'pdf';
                previewPdf();
            } else {
                $scope.type = 'other';
                //update the view
                $scope.$apply(function () {
                    blockUI.stop();
                    downloadFile();
                });
            }
        }).catch(function (ex) {
            console.log(ex);
            handleError(ex);
        });
    }

    this.$onDestroy = function () {
    }

    function registerPdfCallbacks() {
        $scope.onError = function (error) {
            return ErrorService.setError(error);
        }
        $scope.onLoad = function () {
            blockUI.stop();
        }
    }

    function previewImage() {
        //update the view
        $scope.$apply(function () {
            blockUI.stop();
        });
    }

    function previewPdf() {
        registerPdfCallbacks();
        $scope.pdfName = $scope.file.name;
        $scope.pdfUrl = $scope.file.url;
        //update the view
        $scope.$apply();
    }

    function downloadFile() {
        if ($rootScope.isIE) {
            window.navigator.msSaveOrOpenBlob($scope.file.blob, $scope.file.name);
        } else {
            let link = document.createElement('a');
            link.href = $scope.file.url;
            link.download = $scope.file.name;
            link.click();
        }
    }

    function handleError(error) {
        // Unblock the user interface
        blockUI.stop();
        //redirect to error page
        ErrorService.handleError(error);
    };

    $scope.getNavStyle = function (scroll) {
        if (scroll > 100) return 'pdf-controls fixed';
        else return 'pdf-controls';
    }

    $scope.onError = function (error) {
        console.log(error);
    }

    $scope.download = function () {
        downloadFile();
    }

    $scope.print = function () {
        //$window.print($scope.file.url);
        const w = $window.open($scope.file.url, '_blank');
        w.focus();
        w.print();
        //w.close();
    }

}
import * as angular from 'angular';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from '@services/GridFormService';
import { IRestService } from '@services/RestService';
import { ExternalService } from "@services/ExternalService";
import { IMonacoColumnDef } from '@services/GridService2';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IErpNatureSetup } from '../model/ErpNatureSetupModel';
import { GridColumnBuilder, GridColumnBuilderConstants } from '../../common/GridColumnBuilder';
import { SelectorModel } from '../../common/model/SelectorModel';
import { BrowserTitle } from "../../common/BrowserTitle";
import { HelperService } from "@services/HelperService";

interface IErpNatureSetupScope extends IGridFormServiceScope {
    model: IErpNatureSetup;
    log: IViewLog;
    transactionList: SelectorModel[];
    paymentNatureList: SelectorModel[];
    erpNatureList: SelectorModel[];
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IErpNatureSetup;
    getErpNatureListByName: (search: string) => Promise<void>;
}

export class ErpNatureSetupRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IErpNatureSetupScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private ExternalService: ExternalService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IErpNatureSetupScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.ExternalService = $injector.get('ExternalService');
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.config.fmsUrl + '/fms';
            this.$scope.customLogProperties = this.getCustomLogProperties();

            this.initForm(this, 'form', 'erpNatureSetup', 'ERP Natureza', true);
            await this.initGrid('gridErpNatureSetup', '/erpNatureSetup/list', true, true, null, true, true);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.getErpNatureListByName = async (search: string) => {
            let erpNatureList: SelectorModel[] = [];
            if (search && search.length >= 2) erpNatureList = await this.getErpNatureListByName(search);
            this.$scope.erpNatureList = erpNatureList;
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        gridColumns.includeActionBar([
            GridColumnBuilderConstants.BTN_VIEW,
            GridColumnBuilderConstants.BTN_EDIT,
            GridColumnBuilderConstants.BTN_COPY,
            GridColumnBuilderConstants.BTN_VIEWLOG
        ], 25);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colErpNature: IMonacoColumnDef = { name: 'ERP_NATURE.NAME', displayName: 'GENERAL.MENU.ERP_NATURE', width: 300 };
            const colTransaction: IMonacoColumnDef = { name: 'TRANSACTION.NAME', displayName: 'BASIC_DATA.TRANSACTION', width: 300, cellTemplate: "<div class='grid-padding' >{{grid.appScope.getCONCAT(row.entity.TRANSACTION)}}</div>" };
            const colPaymentNature: IMonacoColumnDef = { name: 'PAYMENT_NATURE.NAME', displayName: 'REGISTRATION.TRANSACTION', width: 200, cellTemplate: "<div class='grid-padding' >{{grid.appScope.getCONCAT(row.entity.PAYMENT_NATURE)}}</div>" };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "GENERAL.ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 150, cellFilter: "YesOrNo" };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'ERP_NATURE':
                        columnDefs.push(colErpNature);
                        break;
                    case 'TRANSACTION':
                        columnDefs.push(colTransaction);
                        break;
                    case 'PAYMENT_NATURE':
                        columnDefs.push(colPaymentNature);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            ERP_NATURE: null,
            PAYMENT_NATURE: null,
            TRANSACTION: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            ACTIVE: true
        };
    }

    initDependencies(): Promise<any> {
        const self: ErpNatureSetupRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericList('transaction'),
                self.getGenericList('payment_nature'),
            ]).then((result: any) => {
                self.$scope.transactionList = result[0],
                    self.$scope.paymentNatureList = result[1],
                    resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getGenericList(type: string, alternative: boolean = false): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}/${alternative}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async getErpNatureListByName(search: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const charges = await this.ExternalService.post({ route: "/sankhya/nature/list", data: { search: search } });
            if (charges && charges.data) {
                result = charges.data.data
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")} (${this.$scope.model.ERP_NATURE.NAME})`;
            BrowserTitle.$id = this.$scope.model.ERP_NATURE.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")} (${this.$scope.model.ERP_NATURE.NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.ERP_NATURE.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === 'register' ? 'insert' : 'update';
        return {
            route: `/erpNatureSetup/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };

    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'ERP_NATURE',
                LABEL: 'GENERAL.MENU.ERP_NATURE'
            },
            {
                PROPERTY: 'TRANSACTION',
                LABEL: 'BASIC_DATA.TRANSACTION'
            },
            {
                PROPERTY: 'PAYMENT_NATURE',
                LABEL: 'REGISTRATION.TRANSACTION'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            },
        ];
        return props;
    }
}

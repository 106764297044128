import angular = require('angular');
import * as moment from 'moment';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { IModalService, IModalOptions } from '@services/ModalService';
import { IRestService } from '@services/RestService';
import { ProductService } from "@services/ProductService";
import { ISessionService, ISessionParameter } from '@services/SessionService';
import { ISelectorModel, SelectorModel } from '@models/mongo/SelectorModel';
import { INewAlternativeModel, IChargesTabData } from '@models/interface/quotationCombined/NewAlternativeModel';
import { ITariffFreightOfferListCustomFilter, ITariffFreightExchangeData } from '@models/interface/product/TariffFreightModel';
import { IOfferTabResponse, IRoutesTabRequest, IRoutesTabResponse, IInsertFilterRequest, IInsertFilterResponse, ICargoTabRequest, ICargoTabResponse, ICompareRatesTabResponse, ICompareChargesTabResponse, ICompareChargesTabRequest, IGeneralTabRequest, IGeneralTabResponse, IConfirmationTabRequest, IConfirmationTabResponse, ICargoTab, IGenerateOfferRequest, IOfferOptions, IGenerateOfferResult, IDetailsTabResponse, IDetailsTabRequest, ICompareWeightRangeChargesTabResponse, INewAlternativeCompareWeightRangeCharges } from '@models/interface/quotationCombined/NewAlternative';
import { IMoveTypeModel, IParam } from '@models/interface/product/MoveTypeModel';
import { ROUTES, IOfferWizardFilter, FCL, BB_RORO } from '@models/interface/quotationCombined/OfferWizardFilter';
import { IEquipmentSelector } from '@models/interface/product/EquipmentModel';
import { ITariffDetDemExchangeData } from '@models/interface/product/TariffDetDemModel';
import { IChargeNameListCustomFilter } from '@models/interface/product/ChargeNameModel';
import { IProductConfigurationSecure, IProductConfigurationModel } from '@models/interface/product/ProductConfigurationModel';
import { IWeightRangeListCustomFilter, IWeightRangeSelector } from '@models/interface/product/WeightRangeModel';
import { IApplicationList } from '@models/interface/product/ApplicationModel';
import { EPaymentNatureId, EDirectionId, ECargoTypeId, EChargeOriginId, EProductId, EDataOriginTypeId, EOperation, ETariffType, EProcessTypeId } from '@enums/GenericData';
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { ETypeRuleId, ERouteEventType } from "@enums/MoveTypeModel";
import { ILegalPersonListCustomFilter } from '../../registration/model/LegalPersonModel';
import { IMonacoConfig } from '../../common/MonacoInterface';
import { ISpecRoute, IOfferWizardAgentResult, IOfferWizardRequestRouteAccount, IOfferWizardAccountResult, IOfferWizardRequestRouteAgent, IRouteAgentOriginDestination, IOfferWizardRequestIncoterm, IOfferWizardIncotermResult, ITransitTime } from "../model/OfferWizardModalModel";
import { ILinkParameter } from '../../common/model/ModelParameter';
import { IAccountExchangeData } from '../model/AccountModel';
import { IAgentListCustomFilter } from '../../registration/model/AgentModel';
import { OfferNewAlternativeHelper } from './util/OfferNewAlternativeHelper';
import { IOfferOptionAux, IOfferOptionInvalid, IOfferOptionPaymentRoute, IOfferOptionPaymentListResult, IOfferOption, IOfferOptionConfirmation, IOfferOptionRoutingPoint, IOfferOptionValidationResult, IRouteDetails, ITariffDetDemDetails, IOfferOptionIndicators } from './OfferWizardModalRegisterController';
import { IOfferProfitShareDetail, IOfferOptionCharge, ICargo } from "../../commercial/model/OfferModel";
import { ITariffLocalExchangeData } from '../../product/model/TariffLocalModel';
import { ITariffDomesticExchangeData } from '../../product/model/TariffDomesticModel';
import { OfferHelper } from './util/OfferHelper';
import { IOfferRequestOperationChargeable, IOfferRequestOperationWM } from 'src/ts/product/model/OfferModel';
import { IOfferOptionChargeWeightRange } from 'WBA-Model/dist/interface/quotation/OfferOptionChargeWeightRange';
import { HelperService } from "@services/HelperService";
import { IRoutingPointSelector } from "@models/interface/product/RoutingPoint";
import { IListCustomFilters } from "WBA-Model/dist/interface/product/FreightRoutes";

const enum ETabStep {
    OFFER = 0,
    ROUTES = 1,
    CARGO = 2,
    DETAILS = 3,
    ALTERNATIVES = 4,
    RATES = 5,
    CHARGES = 6,
    GENERAL = 7,
    CONFIRMATION = 8
}

interface IUiTabSteps {
    current: number,
    percent: number,
    lastStep: number
}

interface ISearchBehaviorControl {
    isSearchTabDataListCanceled: boolean;
    searchTabDataError: string[];
    isSearchingTabDataList: boolean;
    searchTabDataListCount: number;
    searchTabDataCurrentAttempt: number;
    searchTabDataMaxAttempt: number;
}

interface IIndicatorsTableSettings {
    contributionLabel: string;
    contributionCode: string;
    wdLabel: string;
    wdCode: string;
}

interface ITariffComplementary {
    ID: number;
    CONCATENATED: string;
    TYPE: ISelectorModel;
    TARIFF_TYPE: ISelectorModel[];
    TRANSACTION: ISelectorModel[];
}

interface IOfferNewAlternativeModalScope extends IFormServiceScope {
    modalOptions: IModalOptions;
    steps: IUiTabSteps;
    model: INewAlternativeModel;
    scopeBeforeSave: INewAlternativeModel;
    idOffer: number;
    selectorModelList: ISelectorModel[];
    incotermList: ISelectorModel[];
    moveTypeList: ISelectorModel[];
    routeOptionsList: ROUTES[];
    equipmentList: IEquipmentSelector[];
    typePaymentList: ISelectorModel[];
    ordinationList: ISelectorModel[];
    weightRangeList: ISelectorModel[];
    weightRangeFullList: IWeightRangeSelector[];
    productConfigurationList: IProductConfigurationModel[];
    packageTypeList: ISelectorModel[];
    inlandRoutesList: ISelectorModel[];
    freightRoutesList: ISelectorModel[];
    newFreightContractList: ISelectorModel[];
    offerExtendedSearchList: ISelectorModel[];
    outerCellList: SelectorModel[];
    offerOptions: IOfferOptionAux[];
    invalidOfferOptions: IOfferOptionInvalid[];
    optionRoutesError: IOfferOptionPaymentRoute[];
    selectedOptions: IOfferOptionConfirmation[];
    hasAnyOption: boolean;
    offerOptionInconsistencyList: ISelectorModel[];
    routeDetails: IRouteDetails[];
    tariffComplementary: ITariffComplementary[];
    alternativesTabSearchControl: ISearchBehaviorControl;
    ratesTabSearchControl: ISearchBehaviorControl;
    chargesTabSearchControl: ISearchBehaviorControl;
    indicatorsSettings: IIndicatorsTableSettings;
    hasManualContacts: boolean;
    hasInstructions: boolean;
    defaultProductSecure: ISelectorModel[];
    defaultProfitShareTypeCargo: ISelectorModel[];
    isFclCargo: boolean;
    isRoadCargo: boolean;
    isBbLclRoroCargo: boolean;
    isBbRoroCargo: boolean;
    isAirCargo: boolean;
    isContractRoutesCollapsed: boolean;
    isCargoCalculatedFieldsDisabled: boolean;
    isCargoListGrossWeightFieldsDisabled: boolean;
    isCombined: boolean;

    //general methods
    cancelOfferOption: () => void;
    cancelRatesSearch: () => void;
    cancelChargesSearch: () => void;
    openRouteDetails: (offerOption: IOfferOption) => void;
    openTransitTimeDetails: (transitTime: ITransitTime) => void;
    openTariffDetDemDetails: (tariffDetDem: ITariffDetDemDetails) => void;
    openProfitShareDetails: (profitShareDetail: IOfferProfitShareDetail) => void;
    openPaymentReceivingChargesDetails: (currentCurrency: ISelectorModel, charges: IOfferOptionCharge[], indicators: IOfferOptionIndicators) => void;
    openChargesPerWeightRangeModal: (chargeWeightRangeList: IOfferOptionChargeWeightRange[]) => void;
    openComparisonChargesPerWeightRangeModal: () => void;
    reprocessOfferOption: () => void;
    enableTotalGrossWeight: () => void;
    clearContractRoutesFilter: () => void;

    // check methods
    isAirChargeableWeightDisabled: () => boolean;
    isTabEnabled: (tabIndex: number) => boolean;
    isCargoTotalGrossWeightDisabled: () => boolean;
    hasAnyCharges: (charges: IChargesTabData) => boolean;

    //build html methods
    buildRoutesHtml: (offerOption: IOfferOption) => string;
    buildOfferOptionInconsistencyHtml: (validationResult: IOfferOptionValidationResult) => string;
    buildDetDemDetailDirection: (product: string, tariffDetDem: ITariffDetDemDetails) => ITariffDetDemDetails;
    buildOfferOptionErrorTooltip: (error: string[]) => string;
    buildQtyUnityHtml: (typeCargo: ISelectorModel, cargo: ICargoTab) => string;
    buildTooltipHtmlTranslated: (term: string) => string;

    //navigation
    back: () => void;
    next: () => void;
    finish: () => void;
    tabClick: (tabIndex: number) => void;
    goToAccountRequirement: (accountRequirementId: number) => void;
    goToAgent: (id?: number) => void;
    goToTariff: (type: string, tariffId: number, tariffContractId: number) => void;
    goToTariffComplementary: (tariffId: number) => void;
    goToTariffFreight: (idTariffContract: number, idTariffFreight?: number) => void;
    goToTariffLocal: (id: number) => void;
    goToTariffDomestic: (id: number) => void;
    goToFreightRoutes: (id: number) => void;
    goToNewFreightContract: (id: number) => void;
    goToInlandRoutes: (id: number) => void;
    goToInlandContract: (id: number) => void;
    goToTariffDetDem: (id: number, detDemType: ISelectorModel) => void;
    collapseContractRoutes: () => void;

    //gets
    getOfferOptionInvalidCharge: (offerOption: IOfferOptionInvalid) => void;
    getCarrierListByName: (search: string) => Promise<void>;
    getBuyingRateListByName: (search: string) => Promise<void>;
    getSellingRateListByName: (search: string) => Promise<void>;
    getShipperListByName: (search: string) => Promise<void>;
    getConsigneeListByName: (search: string) => Promise<void>;
    getNotifyListByName: (search: string) => Promise<void>;
    getRoutingPointListByName: (name: string, params: IParam[], directionId: string) => Promise<void>;
    getEquipmentListByNameOrCode: (search: string) => Promise<void>;
    getAgentListByName: (search: string, networkId: number) => Promise<void>;
    getCurrencyListByName: (search: string) => Promise<void>;
    getChargeNameListByName: (search: string, products?: ISelectorModel[], paramTypeCargo?: ISelectorModel[]) => Promise<void>;
    getApplicationListByName: (product: ISelectorModel[], typeCargo: ISelectorModel[], search: string) => Promise<void>;
    getInsuranceBrokerListByName: (search: string) => Promise<void>;
    getAndUpdateOptionList: () => void;
    getAndUpdateRatesList: () => void;
    getAndUpdateRouteAccountValues: (routeIndex: number, route?: ISpecRoute, masterDirect?: boolean) => Promise<void>;
    refreshAccountRequirement: (route: ISpecRoute) => void;
    getNewFreightContract: (name: string) => Promise<void>;
    getFreightRoutes: (name: string, transactionId: string) => Promise<void>;
    getInlandRoutesListByName: (name: string, transactionId: string) => Promise<void>;
    getOuterCellListByName: (search: string) => Promise<void>;

    //filters
    offerOptionChargeTypeFilter: (charge: IOfferOptionCharge) => boolean;

    //field changes
    buyingRateContractChange: () => void;
    sellingRateContractChange: () => void;
    detailsRouteAgentChange: (index: number) => void;
    ordinationChange: (optionOrdination: ISelectorModel) => void;
    chargeNameChange: (optionChargeName: ISelectorModel) => void;
    chargeKeepSellingRatesChange: () => void;
    chargeManualChargesChange: () => void;
    specSecureChange: () => void;
    weightRangeChange: () => void;
    cargoAirChargeableWeightChange: (amount: number) => void;
    cargoAirOverChargeableWeightChange: (amount: number) => void;
    cargoListGrossWeightFieldsControl: (grossWeightTotal: number) => void;
    grossWeightChange: () => void;
    cubicWeightChange: () => void;

    //multilines control
    selectRouteOption: (routeOptionIndex: number) => void;
    selectOfferOption: (offerOptionIndex: number) => void;
    launchCargoPolLb: () => void;
    addCargo: () => void;
    removeCargo: (index: number) => void;
    addMaritimeCargoFCL: () => void;
    removeMaritimeCargoFCL: (index: number) => void;
    addMaritimeCargoBBRORO: () => void;
    removeMaritimeCargoBBRORO: (index: number) => void;
    calculateCargoCBW: (index: number) => Promise<void>;
    calculateCargoGrossWeightTotal: (index: number) => void;
    calculateGrossWeightTotal: () => void;
    calculateVolumeTotal: () => void;
    isLiquidProcessType: () => boolean;
}

export class OfferNewAlternativeWizardModalController extends FormService2 {
    static $inject: string[] = ['$injector', '$scope'];
    private scope: IOfferNewAlternativeModalScope;
    private $q: ng.IQService;
    private $filterService: ng.FilterFactory;
    private $compile: angular.ICompileService;
    private sce: angular.ISCEService;
    private timeout: ng.ITimeoutService
    private config: IMonacoConfig;
    private modalService: IModalService;
    private restService: IRestService;
    private productService: ProductService;
    private sessionService: ISessionService;
    private quotationCombinedUrl: string;
    private modalCargoPolLbId: number;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IOfferNewAlternativeModalScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.$q = $injector.get('$q');
        this.$filterService = $injector.get('$filter');
        this.$compile = $injector.get('$compile');
        this.sce = $injector.get('$sce');
        this.timeout = $injector.get('$timeout');
        this.config = $injector.get('config');
        this.modalService = $injector.get('ModalService');
        this.restService = $injector.get('RestService');
        this.productService = $injector.get('ProductService');
        this.sessionService = $injector.get('SessionService');
        this.modalCargoPolLbId = 0;
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.block();
            this.init("offerNewAlternativeWizardModalForm", null, null);
            this.initScopeFunctions();
            this.quotationCombinedUrl = this.getUrlQuotationCombined();
            await this.initDependencies();
            await this.initModel();
            await this.initControl();
            this.loadRegisterForm(false);
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    private initScopeFunctions(): void {
        this.scope.cancelOfferOption = () => {
            this.cancelOfferOption();
        }
        this.scope.cancelRatesSearch = () => {
            this.cancelRatesSearch();
        }
        this.scope.cancelChargesSearch = () => {
            this.cancelChargesSearch();
        }
        this.scope.openRouteDetails = (offerOption: IOfferOption) => {
            this.openRouteDetails(offerOption);
        }
        this.scope.openTransitTimeDetails = (transitTime: ITransitTime) => {
            this.openTransitTimeDetails(transitTime);
        }
        this.scope.openTariffDetDemDetails = (tariffDetDem: ITariffDetDemDetails) => {
            this.openTariffDetDemDetails(tariffDetDem);
        }
        this.scope.openProfitShareDetails = (profitShareDetail: IOfferProfitShareDetail) => {
            this.openProfitShareDetails(profitShareDetail);
        }
        this.scope.openPaymentReceivingChargesDetails = (currentCurrency: ISelectorModel, charges: IOfferOptionCharge[], indicators: IOfferOptionIndicators) => {
            this.openPaymentReceivingChargesDetails(currentCurrency, charges, indicators);
        }
        this.scope.openChargesPerWeightRangeModal = (chargeWeightRangeList: IOfferOptionChargeWeightRange[]) => {
            this.openChargesPerWeightRangeModal(chargeWeightRangeList);
        }
        this.scope.openComparisonChargesPerWeightRangeModal = () => {
            this.openComparisonChargesPerWeightRangeModal();
        }
        this.scope.reprocessOfferOption = () => {
            this.reprocessOfferOption();
        }
        this.scope.enableTotalGrossWeight = () => {
            this.enableTotalGrossWeight();
        }
        this.scope.clearContractRoutesFilter = async () => {
            const confirm = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.NO',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.getTranslate('GENERAL.LOST_DETAIL')
            });
            if (confirm) {
                this.scope.model.OFFER_TAB.CONTRACT_ROUTES = {
                    NEW_FREIGHT_CONTRACT_PAYMENT: null,
                    NEW_FREIGHT_CONTRACT_RECEIVING: null,
                    ID_NEW_FREIGHT_CONTRACT: null,
                    FREIGHT_ROUTES: null,
                    ID_FREIGHT_ROUTES: null,
                    ID_NEW_FREIGHT_CONTRACT_RECEIVING: null,
                    FREIGHT_ROUTES_RECEIVING: null,
                    ID_FREIGHT_ROUTES_RECEIVING: null,
                    INLAND_PUP_PAYMENT: null,
                    INLAND_OTFS_PAYMENT: null,
                    INLAND_DTFS_PAYMENT: null,
                    INLAND_PLD_PAYMENT: null,
                    INLAND_PUP_RECEIVING: null,
                    INLAND_OTFS_RECEIVING: null,
                    INLAND_DTFS_RECEIVING: null,
                    INLAND_PLD_RECEIVING: null
                }
            }
        }
        this.scope.isAirChargeableWeightDisabled = (): boolean => {
            return ((this.scope.model.CARGO_TAB.CARGO_LIST && this.scope.model.CARGO_TAB.CARGO_LIST.length > 0) || (this.scope.model.CARGO_TAB && this.scope.model.CARGO_TAB.AIR && this.scope.model.CARGO_TAB.AIR.WEIGHT_BREAKDOWN) || this.scope.model.CARGO_TAB.VOLUME || this.scope.model.CARGO_TAB.GROSS_WEIGHT || this.scope.model.CARGO_TAB.CUBIC_WEIGHT) ? true : false;
        }
        this.scope.isTabEnabled = (tabIndex: number): boolean => {
            if (!this.scope.steps || tabIndex == null) return false;
            const previousStep = this.scope.steps.current - 1;
            const nextStep = this.scope.steps.current + 1;
            return (previousStep == tabIndex || nextStep == tabIndex);
        }
        this.scope.isCargoTotalGrossWeightDisabled = (): boolean => {
            const cargoList = this.scope.model && this.scope.model.CARGO_TAB ? this.scope.model.CARGO_TAB.CARGO_LIST : null;
            return cargoList && cargoList.findIndex(cargo => (cargo.GROSS_WEIGHT_UNITARY || cargo.GROSS_WEIGHT_UNITARY == 0) || (cargo.GROSS_WEIGHT || cargo.GROSS_WEIGHT == 0)) >= 0;
        }
        this.scope.hasAnyCharges = (charges: IChargesTabData) => {
            return (charges.ADDED_CHARGES && charges.ADDED_CHARGES.length > 0) || (charges.DELETED_CHARGES && charges.DELETED_CHARGES.length > 0) || (charges.MAINTAINED_CHARGES && charges.MAINTAINED_CHARGES.length > 0);
        }
        this.scope.buildRoutesHtml = (offerOption: IOfferOption) => {
            return this.sce.trustAsHtml(OfferNewAlternativeHelper.buildRoutesHtml(offerOption));
        }
        this.scope.buildOfferOptionInconsistencyHtml = (validationResult: IOfferOptionValidationResult) => {
            return OfferNewAlternativeHelper.buildOfferOptionInconsistencyHtml(this.scope.offerOptionInconsistencyList, validationResult);
        }
        this.scope.buildDetDemDetailDirection = (product: string, tariffDetDem: ITariffDetDemDetails): ITariffDetDemDetails => {
            return OfferNewAlternativeHelper.buildDetDemDetailDirection(product, tariffDetDem);
        }
        this.scope.buildOfferOptionErrorTooltip = (errors: string[]) => {
            return OfferNewAlternativeHelper.buildOfferOptionErrorTooltip(errors);
        }
        this.scope.buildQtyUnityHtml = (typeCargo: ISelectorModel, cargo: ICargoTab) => {
            return this.buildQtyUnityHtml(typeCargo, cargo);
        }
        this.scope.buildTooltipHtmlTranslated = (term: string) => {
            return term ? this.getTranslate(term) : "";
        }
        this.scope.back = async (): Promise<void> => {
            if (this.scope && this.scope.steps && this.scope.steps.current > 0) {
                this.scope.steps.current--;
            }
        }
        this.scope.next = async (): Promise<void> => {
            if (this.scope && this.scope.steps && angular.isDefined(this.scope.steps.current)) {

                const validFields = this.checkPendingFields('offerNewAlternativeWizardModalForm');
                if (!validFields) return;

                const lastStep = ETabStep.CONFIRMATION;
                if (this.scope.steps.current < (lastStep + 1) && await this.handleNavigationChange(this.scope.steps.current)) {
                    this.scope.steps.current++;
                }
            }
        }
        this.scope.finish = async (): Promise<void> => {
            await this.finish();
        }
        this.scope.tabClick = (tabIndex: number): void => {
            if (!this.scope.steps || tabIndex == null) return;
            const previousStep = this.scope.steps.current - 1;
            const nextStep = this.scope.steps.current + 1;
            if (previousStep == tabIndex) this.scope.back();
            else if (nextStep == tabIndex) this.scope.next();
        }
        this.scope.goToAccountRequirement = (accountRequirementId: number) => {
            if (!accountRequirementId && accountRequirementId != 0) throw Error('accountRequirementId is null');
            this.sessionService.openTab("app.commercial.account", <ILinkParameter>{ ID: "" }, <IAccountExchangeData>{ OPERATION: "edit", ID: this.scope.model.OFFER_TAB && this.scope.model.OFFER_TAB.ACCOUNT ? parseInt(this.scope.model.OFFER_TAB.ACCOUNT.ID) : null, ID_REQUIREMENT: accountRequirementId });
        }
        this.scope.goToAgent = (id: number): void => {
            this.sessionService.openTab("app.registration.agent", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.scope.goToTariff = (type: string, tariffId: number, tariffContractId: number) => {
            if (!type) throw Error('type is null');
            if (!tariffId && tariffId != 0) throw Error('tariffId is null');

            const contractIdOrTariffId = typeof tariffContractId == 'number' ? tariffContractId : tariffId;
            if (type == ERouteEventType.TARIFF_FREIGHT) {
                this.sessionService.openTab('app.product.freightRoutes', <ILinkParameter>{ ID: contractIdOrTariffId ? contractIdOrTariffId.toString() : contractIdOrTariffId }, <ITariffFreightExchangeData>{ OPERATION: EOperation.VIEW, ID: contractIdOrTariffId ? contractIdOrTariffId.toString() : contractIdOrTariffId });
            }
            else if (type == ERouteEventType.TARIFF_DOMESTIC_ORIGIN || type == ERouteEventType.TARIFF_DOMESTIC_DESTINATION) {
                this.sessionService.openTab('app.product.inlandRoutes', <ILinkParameter>{ ID: contractIdOrTariffId ? contractIdOrTariffId.toString() : contractIdOrTariffId }, <ITariffLocalExchangeData>{ OPERATION: EOperation.VIEW, ID: contractIdOrTariffId ? contractIdOrTariffId.toString() : contractIdOrTariffId });
            }
            else if (type == ERouteEventType.TARIFF_LOCAL_DESTINATION || type == ERouteEventType.TARIFF_LOCAL_ORIGIN) {
                const endpoint = `${this.config.productUrl}/product/tariffLocal/getCacheById/${contractIdOrTariffId}`;
                this.sessionService.openTabByValidity(endpoint, "app.product.tariffLocal", <ILinkParameter>{ ID: tariffId ? tariffId.toString() : null }, <ITariffLocalExchangeData>{ OPERATION: "view", ID: tariffId ? tariffId.toString() : null });
            }
        }
        this.scope.goToTariffComplementary = (tariffId: number) => {
            this.sessionService.openTab('app.product.tariffComplementary', <ILinkParameter>{ ID: tariffId ? tariffId.toString() : tariffId }, <ITariffFreightExchangeData>{ OPERATION: EOperation.VIEW, ID: tariffId ? tariffId.toString() : tariffId });
        }
        this.scope.goToTariffFreight = (idTariffContract: number) => {
            this.sessionService.openTab('app.product.freightRoutes', <ILinkParameter>{ ID: idTariffContract ? idTariffContract.toString() : idTariffContract }, <ITariffFreightExchangeData>{ OPERATION: EOperation.VIEW, ID: idTariffContract ? idTariffContract.toString() : idTariffContract });
        }
        this.scope.goToTariffLocal = (id: number) => {
            const endpoint = `${this.config.productUrl}/product/tariffLocal/getCacheById/${id}`;
            this.sessionService.openTabByValidity(endpoint, "app.product.tariffLocal", <ILinkParameter>{ ID: id ? id.toString() : id }, <ITariffLocalExchangeData>{ OPERATION: EOperation.VIEW, ID: id ? id.toString() : id });
        }
        this.scope.goToTariffDomestic = (id: number) => {
            const endpoint = `${this.config.productUrl}/product/tariffDomestic/getCacheById/${id}`;
            this.sessionService.openTabByValidity(endpoint, "app.product.tariffDomestic", <ILinkParameter>{ ID: id ? id.toString() : id }, <ITariffDomesticExchangeData>{ OPERATION: EOperation.VIEW, ID: id ? id.toString() : id });
        }
        this.scope.goToFreightRoutes = (id: number) => {
            this.sessionService.openTab('app.product.freightRoutes', <ILinkParameter>{ ID: id ? id.toString() : id }, <ITariffFreightExchangeData>{ OPERATION: EOperation.VIEW, ID: id ? id.toString() : id });
        }
        this.scope.goToNewFreightContract = (id: number) => {
            this.sessionService.openTab('app.product.newFreightContract', <ILinkParameter>{ ID: id ? id.toString() : id }, <ITariffFreightExchangeData>{ OPERATION: EOperation.VIEW, ID: id ? id.toString() : id });
        }
        this.scope.goToInlandRoutes = (id: number) => {
            this.sessionService.openTab('app.product.inlandRoutes', <ILinkParameter>{ ID: id ? id.toString() : id }, <ITariffLocalExchangeData>{ OPERATION: EOperation.VIEW, ID: id ? id.toString() : id });
        }
        this.scope.goToInlandContract = (id: number) => {
            this.sessionService.openTab('app.product.inlandContract', <ILinkParameter>{ ID: id ? id.toString() : id }, <ITariffLocalExchangeData>{ OPERATION: EOperation.VIEW, ID: id ? id.toString() : id });
        }
        this.scope.goToTariffDetDem = (id: number, detDemType: ISelectorModel) => {
            if (!id) throw Error('id is null');

            const detDemTypeId = detDemType ? detDemType.ID : null;

            switch (detDemTypeId) {
                case ETariffType.Route:
                    this.sessionService.openTab('app.product.freightRoutes', <ILinkParameter>{ ID: id ? id.toString() : id }, <ITariffFreightExchangeData>{ OPERATION: EOperation.VIEW, ID: id ? id.toString() : id });
                    break;
                case ETariffType.DetDemContract:
                    this.sessionService.openTab('app.product.newFreightContract', <ILinkParameter>{ ID: id ? id.toString() : id }, <ITariffFreightExchangeData>{ OPERATION: EOperation.VIEW, ID: id ? id.toString() : id });
                    break;
                default:
                    this.sessionService.openTab("app.product.tariffDetDem", <ILinkParameter>{ ID: id ? id.toString() : id }, <ITariffDetDemExchangeData>{ OPERATION: EOperation.VIEW, ID: id ? id.toString() : id });
                    break;
            }
        }
        this.scope.collapseContractRoutes = (): void => {
            const collapseContractRoutes = angular.element("#collapseContractRoutes");
            if (collapseContractRoutes) {
                const isCollapsed = collapseContractRoutes.attr("aria-expanded") == "true";
                this.scope.isContractRoutesCollapsed = !isCollapsed;
            }
        }
        this.scope.getOfferOptionInvalidCharge = (offerOption: IOfferOptionInvalid) => {
            this.getOfferOptionInvalidCharge(offerOption);
        }
        this.scope.getCarrierListByName = async (search: string) => {
            let carrierList: ISelectorModel[] = [];
            if (search && search.length >= 3) carrierList = await this.getProviderListByName(search);
            this.scope.selectorModelList = carrierList;
        }
        this.scope.getBuyingRateListByName = async (search: string): Promise<void> => {
            let buyingRateList: ISelectorModel[] = [];
            const product = this.scope.model.OFFER_TAB.PRODUCT ? [this.scope.model.OFFER_TAB.PRODUCT.ID] : [];
            const typeCargo = this.scope.model.OFFER_TAB.TYPE_CARGO ? [this.scope.model.OFFER_TAB.TYPE_CARGO.ID] : [];
            const account = this.scope.model.OFFER_TAB.ACCOUNT ? [parseInt(this.scope.model.OFFER_TAB.ACCOUNT.ID)] : [];
            const provider = this.scope.model.OFFER_TAB.PROVIDER ? [parseInt(this.scope.model.OFFER_TAB.PROVIDER.ID)] : [];
            const refDate = this.scope.model.OFFER_TAB.LOAD_REF ? this.scope.model.OFFER_TAB.LOAD_REF : null;
            if (search && search.length >= 2) {
                buyingRateList = await this.getTariffFreightCustomOfferListByName({ search: search, paymentNature: [EPaymentNatureId.PAYMENT], products: product, typeCargo: typeCargo, accounts: account, providers: provider, refDate: refDate });
            }
            this.scope.selectorModelList = buyingRateList;
        }
        this.scope.getSellingRateListByName = async (search: string): Promise<void> => {
            let sellingRateList: ISelectorModel[] = [];
            const product = this.scope.model.OFFER_TAB.PRODUCT ? [this.scope.model.OFFER_TAB.PRODUCT.ID] : [];
            const typeCargo = this.scope.model.OFFER_TAB.TYPE_CARGO ? [this.scope.model.OFFER_TAB.TYPE_CARGO.ID] : [];
            const account = this.scope.model.OFFER_TAB.ACCOUNT ? [parseInt(this.scope.model.OFFER_TAB.ACCOUNT.ID)] : [];
            const provider = this.scope.model.OFFER_TAB.PROVIDER ? [parseInt(this.scope.model.OFFER_TAB.PROVIDER.ID)] : [];
            const refDate = this.scope.model.OFFER_TAB.LOAD_REF ? this.scope.model.OFFER_TAB.LOAD_REF : null;
            if (search && search.length >= 2) {
                sellingRateList = await this.getTariffFreightCustomOfferListByName({ search: search, paymentNature: [EPaymentNatureId.RECEIVING], products: product, typeCargo: typeCargo, accounts: account, providers: provider, refDate: refDate });
            }
            this.scope.selectorModelList = sellingRateList;
        }
        this.scope.getShipperListByName = async (search: string): Promise<void> => {
            let exporterList: ISelectorModel[] = [];
            if (search && search.length >= 3) exporterList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.EXPORTER], search: search });
            this.scope.selectorModelList = exporterList;
        }
        this.scope.getConsigneeListByName = async (search: string): Promise<void> => {
            let importerList: ISelectorModel[] = [];
            if (search && search.length >= 3) importerList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.IMPORTER], search: search });
            this.scope.selectorModelList = importerList;
        }
        this.scope.getNotifyListByName = async (search: string): Promise<void> => {
            let notifyList: ISelectorModel[] = [];
            if (search && search.length >= 3) notifyList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.NOTIFY], search: search });
            this.scope.selectorModelList = notifyList;
        }
        this.scope.getRoutingPointListByName = async (name: string, params: IParam[], directionId: string): Promise<void> => {
            let routingPointList: ISelectorModel[] = [];
            if (name && name.length >= 2) {
                if (!params) return this.notifyError("params is null");
                if (!directionId) return this.notifyError("directionId is null");
                const directionParams: IParam[] = params && params.length > 0 ? params.filter(param => param.DIRECTION && param.DIRECTION.ID == directionId) : null;
                let types: string[] = [];
                if (directionParams) {
                    if (directionId == EDirectionId.ORIGIN) {
                        for (let i = 0; i < directionParams.length; i++) {
                            if (directionParams[i] && directionParams[i].TYPE_RULE && directionParams[i].TYPE_RULE.ID == ETypeRuleId.MANDATORY) {
                                types = directionParams[i].TYPE_LOCAL_EVENT && directionParams[i].TYPE_LOCAL_EVENT.length > 0 ? directionParams[i].TYPE_LOCAL_EVENT.map(local => local.ID) : null;
                                break;
                            }
                        }
                    } else if (directionId == EDirectionId.DESTINATION) {
                        for (let i = directionParams.length; i >= 0; i--) {
                            if (directionParams[i] && directionParams[i].TYPE_RULE && directionParams[i].TYPE_RULE.ID == ETypeRuleId.MANDATORY) {
                                types = directionParams[i].TYPE_LOCAL_EVENT && directionParams[i].TYPE_LOCAL_EVENT.length > 0 ? directionParams[i].TYPE_LOCAL_EVENT.map(local => local.ID) : null;
                                break;
                            }
                        }
                    }
                }
                routingPointList = await this.getRoutingPointListByName(name, types);
            }
            this.scope.selectorModelList = routingPointList;
        }
        this.scope.getEquipmentListByNameOrCode = async (search: string) => {
            let equipmentList: IEquipmentSelector[] = [];
            if (search && search.length >= 2) equipmentList = await this.getEquipmentListByNameOrCode(search);
            this.scope.equipmentList = equipmentList;
        }
        this.scope.getAgentListByName = async (search: string, networkId: number): Promise<void> => {
            let agentList: ISelectorModel[] = [];
            if (search && search.length >= 3) agentList = await this.getAgentListByName({ search: search, products: this.scope.model.OFFER_TAB.PRODUCT ? [this.scope.model.OFFER_TAB.PRODUCT.ID] : [], networks: networkId ? [networkId] : [] });
            this.scope.selectorModelList = agentList;
        }
        this.scope.getCurrencyListByName = async (search: string): Promise<void> => {
            let currencyList: ISelectorModel[] = [];
            if (search && search.length >= 2) currencyList = await this.getCurrencyListByName(search);
            this.scope.selectorModelList = currencyList;
        }
        this.scope.getChargeNameListByName = async (search: string, products?: ISelectorModel[], paramTypeCargo?: ISelectorModel[]) => {
            let chargeNameList: ISelectorModel[] = [];
            if (!paramTypeCargo) return this.notifyError("paramTypeCargo is null");
            if (!products) return this.notifyError("products is null");
            products = this.scope.defaultProductSecure ? this.scope.defaultProductSecure : products;
            paramTypeCargo = this.scope.defaultProfitShareTypeCargo ? this.scope.defaultProfitShareTypeCargo : paramTypeCargo;

            if (search && search.length >= 2) chargeNameList = await this.getChargeNameListByName({ search, types: ['6'], products: products && products.length > 0 ? products.map(product => product.ID) : null, paramTypeCargo: paramTypeCargo && paramTypeCargo.length > 0 ? paramTypeCargo.map(typeCargo => typeCargo.ID) : null });
            this.scope.selectorModelList = chargeNameList;
        }
        this.scope.getApplicationListByName = async (product: ISelectorModel[], typeCargo: ISelectorModel[], search: string): Promise<void> => {
            this.scope.selectorModelList = await this.getApplicationListByName(product, typeCargo, search);
        }
        this.scope.getInsuranceBrokerListByName = async (search: string) => {
            let insuranceBrokerList: ISelectorModel[] = [];
            if (search && search.length >= 3) insuranceBrokerList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.INSURANCE_BROKER], search: search });
            this.scope.selectorModelList = insuranceBrokerList;
        }

        this.scope.getNewFreightContract = async (name: string): Promise<void> => {
            let newFreightContractList: ISelectorModel[] = [];
            if (name && name.length >= 2) {
                newFreightContractList = await this.getNewFreightContractListByName(name);
            }
            this.scope.newFreightContractList = newFreightContractList;
        }

        this.scope.getFreightRoutes = async (name: string, transactionId: string): Promise<void> => {
            let freightRoutesList: ISelectorModel[] = [];

            if (name && name.length >= 2) {
                freightRoutesList = await this.getFreightRoutesListByName(name, transactionId);
            }

            this.scope.freightRoutesList = freightRoutesList;
        }

        this.scope.getInlandRoutesListByName = async (name: string, transactionId: string): Promise<void> => {
            let inlandRoutesList: ISelectorModel[] = [];
            if (name.length >= 3) {
                inlandRoutesList = await this.getInlandRoutesListByName(name, transactionId);
            }
            this.scope.inlandRoutesList = inlandRoutesList;
        }

        this.scope.getOuterCellListByName = async (search: string): Promise<void> => {
            let outerCellList: SelectorModel[] = [];
            if (search && search.length >= 2) outerCellList = await this.getOuterCellListByName(search);
            this.scope.outerCellList = outerCellList;
        }

        this.scope.getAndUpdateOptionList = () => {
            this.getAndUpdateOptionList();
        }
        this.scope.getAndUpdateRatesList = () => {
            this.getAndUpdateRatesList();
        }
        this.scope.getAndUpdateRouteAccountValues = async (routeIndex: number, route?: ISpecRoute, masterDirect?: boolean): Promise<void> => {
            this.getAndUpdateRouteAccountValues(routeIndex, route, masterDirect);
        }
        this.scope.refreshAccountRequirement = (route: ISpecRoute) => {
            this.getAndUpdateRouteAccountValues(null, route);
        }
        this.scope.offerOptionChargeTypeFilter = (charge: IOfferOptionCharge) => {
            return (!charge.CHARGE_NAME) || (charge.CHARGE_NAME && !charge.CHARGE_NAME.TYPE) || (charge.CHARGE_NAME && charge.CHARGE_NAME.TYPE && charge.CHARGE_NAME.TYPE.ID != EChargeOriginId.COMISSION);
        }
        this.scope.buyingRateContractChange = () => {
            if (!this.scope.model.OFFER_TAB.CONTRACT_ROUTES.NEW_FREIGHT_CONTRACT_PAYMENT) this.scope.model.OFFER_TAB.CONTRACT_ROUTES.ID_FREIGHT_ROUTES = null;
        }
        this.scope.sellingRateContractChange = () => {
            if (!this.scope.model.OFFER_TAB.CONTRACT_ROUTES.NEW_FREIGHT_CONTRACT_RECEIVING) this.scope.model.OFFER_TAB.CONTRACT_ROUTES.ID_FREIGHT_ROUTES_RECEIVING = null;
        }
        this.scope.detailsRouteAgentChange = (index: number): void => {
            this.detailsRouteAgentChange(index);
        }
        this.scope.ordinationChange = (optionOrdination: ISelectorModel) => {
            if (optionOrdination) {
                this.scope.offerOptions = this.scope.offerOptions.sort((x, y) => {
                    return x.INDICATORS[optionOrdination.CODE] < y.INDICATORS[optionOrdination.CODE] ? 1 : -1;
                });
            }
        }
        this.scope.chargeNameChange = (optionChargeName: ISelectorModel): void => {
            this.chargeNameChange(optionChargeName);
        }
        this.scope.chargeKeepSellingRatesChange = () => {
            this.getAndUpdateChargesList();
        }
        this.scope.chargeManualChargesChange = () => {
            this.getAndUpdateChargesList();
            // workaround(firefox) - keep overflow in same position
            this.timeout(function () {
                const manualChargesPanel = document.getElementById('manualChargesPanel');
                const topPos = manualChargesPanel.offsetTop;
                document.getElementById('tab6').scrollTop = topPos;
            });
        }
        this.scope.specSecureChange = () => {
            this.specSecureChange();
        }
        this.scope.weightRangeChange = () => {
            this.updateWeightRangeBase();
        }
        this.scope.cargoAirChargeableWeightChange = (amount: number) => {
            this.updateCargoCalculateFieldsDisabledControl(amount);
            this.updateWeightBreakBasedOverChargeableWeightOrGrossWeight([amount > this.scope.model.CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT ? amount : this.scope.model.CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT]);
            this.updateAirApplication();
        }
        this.scope.cargoAirOverChargeableWeightChange = (amount: number) => {
            if (!this.scope.model.CARGO_TAB.AIR.WEIGHT_BREAKDOWN) this.updateWeightBreakBasedOverChargeableWeightOrGrossWeight([this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT > amount ? this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT : amount]);
            if (this.scope.model.CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT && this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.AIR) this.updateWeightBreakBasedOverChargeableWeightOrGrossWeight([this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT > amount ? this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT : amount]);

            this.updateAirApplication();
        }
        this.scope.cargoListGrossWeightFieldsControl = (grossWeightTotal: number) => {
            this.scope.isCargoListGrossWeightFieldsDisabled = this.scope.isCargoTotalGrossWeightDisabled() ? false : grossWeightTotal !== null && angular.isDefined(grossWeightTotal);
        }
        this.scope.grossWeightChange = () => {
            this.grossWeightChange();
        }
        this.scope.cubicWeightChange = () => {
            this.cubicWeightChange();
        }
        this.scope.selectRouteOption = (routeOptionIndex: number) => {
            this.selectRouteOption(routeOptionIndex);
        }
        this.scope.selectOfferOption = (offerOptionIndex: number) => {
            this.selectOfferOption(offerOptionIndex);
        }
        this.scope.launchCargoPolLb = async (): Promise<void> => {
            this.launchCargoPolLb();
        }
        this.scope.addCargo = (): void => {
            this.addCargo();
        }
        this.scope.removeCargo = (index: number): void => {
            this.removeCargo(index);
        }
        this.scope.addMaritimeCargoFCL = (): void => {
            this.addMaritimeCargoFCL();
        }
        this.scope.removeMaritimeCargoFCL = (index: number): void => {
            this.removeMaritimeCargoFCL(index);
        }
        this.scope.addMaritimeCargoBBRORO = (): void => {
            this.addMaritimeCargoBBRORO();
        }
        this.scope.removeMaritimeCargoBBRORO = (index: number): void => {
            this.removeMaritimeCargoBBRORO(index);
        }
        this.scope.calculateCargoCBW = async (index: number): Promise<void> => {
            if (!index && index !== 0) throw this.notifyError('index is null');
            if (this.scope.model.CARGO_TAB.CARGO_LIST && this.scope.model.CARGO_TAB.CARGO_LIST.length > 0 && this.scope.model.CARGO_TAB.CARGO_LIST[index]) {
                const cbw = OfferHelper.calculateCargoCBW(this.scope.model.CARGO_TAB.CARGO_LIST[index]);
                this.scope.model.CARGO_TAB.CARGO_LIST[index].CUBIC_WEIGHT = cbw ? parseFloat(cbw.toFixed(3)) : cbw;
                this.calculateCBWTotal();
                if (this.scope.isAirCargo) await this.calculateOperationChargeable();
                if (this.scope.isBbLclRoroCargo) this.calculateOperationWM();
            }
        }
        this.scope.calculateCargoGrossWeightTotal = (index: number): void => {
            if (!index && index !== 0) throw this.notifyError('index is null');
            if (this.scope.model.CARGO_TAB.CARGO_LIST && this.scope.model.CARGO_TAB.CARGO_LIST.length > 0 && this.scope.model.CARGO_TAB.CARGO_LIST[index] && this.scope.model.CARGO_TAB.CARGO_LIST[index].GROSS_WEIGHT_UNITARY > 0) {
                const grossWeightTotal = OfferHelper.calculateCargoGrossWeightTotal(this.scope.model.CARGO_TAB.CARGO_LIST[index]);
                this.scope.model.CARGO_TAB.CARGO_LIST[index].GROSS_WEIGHT = grossWeightTotal ? parseFloat(grossWeightTotal.toFixed(4)) : grossWeightTotal;
            }
            this.calculateGrossWeightTotal();
            this.calculateCBWTotal();
            if (this.scope.model.OFFER_TAB && this.scope.model.OFFER_TAB.TYPE_CARGO && (this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.BREAK_BULK || this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.LCL || this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.RO_RO)) this.calculateOperationWM();
            this.updateAirApplication();
        }
        this.scope.calculateGrossWeightTotal = (): void => {
            this.calculateGrossWeightTotal();
        }
        this.scope.calculateVolumeTotal = (): void => {
            this.calculateVolumeTotal();
        }
        this.scope.isLiquidProcessType = () => this.isLiquidProcessType();
    }

    async initDependencies(): Promise<any> {
        try {
            const self: OfferNewAlternativeWizardModalController = this;
            return new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getMoveTypeList(),
                    self.getGenericValue("type_payment"),
                    self.getGenericAlternativeAsCodeList("offer_option_inconsistency"),
                    self.getProductConfigurationList(),
                    self.getGenericValue("package_type"),
                    self.getGenericValue("offer_extended_search"),
                ]).then((result: any) => {
                    self.scope.moveTypeList = result[0] && result[0].length ? result[0].map(moveType => { return { ID: moveType.ID, NAME: moveType.NAME, CODE: moveType.CODE, PARAMS: moveType.PARAMS, APPLY_ROAD: moveType.APPLY_ROAD, ORIGIN_TYPE: moveType.ORIGIN_TYPE, DESTINATION_TYPE: moveType.DESTINATION_TYPE } }) : null;
                    self.scope.typePaymentList = result[1];
                    self.scope.offerOptionInconsistencyList = result[2];
                    self.scope.productConfigurationList = result[3].data;
                    self.scope.packageTypeList = result[4];
                    self.scope.offerExtendedSearchList = result[5]
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async initModel(): Promise<void> {
        this.scope.model = {
            _id: null,
            OFFER_TAB: {
                PRODUCT: null,
                TYPE_CARGO: null,
                LOAD_REF: null,
                ACCOUNT: null,
                EXPORTER: null,
                IMPORTER: null,
                NOTIFY: null,
                PROVIDER: null,
                INCOTERM: null,
                OFFER_TYPE: null,
                PROCESS_TYPE: null,
                CURRENCY: null,
                VALUE: null,
                ACCOUNT_REQUEST: null,
                COMMODITY: null,
                COMMODITY_SECTION: null,
                NEW_ALTERNATIVE: null,
                ID_OFFER: null,
                CONCATENATED: null,
                CONTRACT_ROUTES: null,
                EXTENDED_SEARCH: null,
                EXTENDED_SEARCH_DATE: null,
                OUTER_CELL: null
            },
            ROUTES_TAB: {
                ORIGINAL_ROUTE_FOUND: null,
                ROUTES: null
            },
            CARGO_TAB: {
                AIR: null,
                BB_RORO: null,
                CARGO_LIST: null,
                CUBIC_WEIGHT: null,
                FCL: null,
                FTL: null,
                GROSS_WEIGHT: null,
                LCL_AMOUNT: null,
                LCL_APPLICATION: null,
                STACKABLE: null,
                STANDARD_EQUIPMENT: null,
                VOLUME: null,
                CARGO_SOC: null,
                ID_EQUIPMENT_PROVIDER: null,
                EQUIPMENT_PROVIDER: null,
                FLEXITANK: this.isLiquidProcessType() ? true : false
            },
            DETAILS_TAB: {
                ROUTE: null,
                IS_DANGEROUS_CARGO: false,
                DANGEROUS_CARGO: null,
                IS_INSURANCE: null,
                SECURE: null,
                IS_REFRIGERATED_CARGO: false,
                REFRIGERATED_CARGO: null,
                IS_CARGO_TREATMENT: false,
                CARGO_TREATMENT: null
            },
            RATES_TAB: {
                FREIGHT_DOMESTIC: null,
                INLAND: null,
                LOCAL: null,
                DEMURRAGE_DETENTION: null
            },
            CHARGES_TAB: {
                ID_OFFER_OPTION_PAYMENT: null,
                INDICATORS: null,
                FREIGHT_DOMESTIC: null,
                LOCAL: null,
                INSURANCE: null,
                PROFIT_SHARE: null,
                MANUAL_CHARGES: null,
                CAN_KEEP_SELLING_RATES: false,
                CAN_KEEP_MANUAL_CHARGES: null,
                KEEP_SELLING_RATES: null,
                KEEP_MANUAL_CHARGES: null,
            },
            GENERAL_TAB: {
                KEEP_REFERENCES: true,
                REFERENCES: null,
                KEEP_MANUAL_CONTACTS: true,
                CONTACTS: null,
                KEEP_INSTRUCTIONS: true,
                INSTRUCTIONS: null
            },
            CONFIRMATION_TAB: {
                ID_OFFER_OPTION_PAYMENT: null,
                NEW_OFFER: null,
                CURRENT_OFFER: null
            }
        };
    }

    private isLiquidProcessType() {
        const processId = this.scope.model && this.scope.model.OFFER_TAB.PROCESS_TYPE && this.scope.model.OFFER_TAB.PROCESS_TYPE.ID;
        if (!processId) return false;

        return [EProcessTypeId.LIQUID].some(item => item === processId);
    }

    private async initControl(): Promise<void> {
        this.scope.steps = { current: 0, percent: 0, lastStep: 8 };
        await this.initOfferTab();
        this.scope.incotermList = await this.getIncotermListByName(null);
        this.scope.isContractRoutesCollapsed = false;
    }

    private async initOfferTab(): Promise<void> {
        try {
            this.block();
            const newAlternativeModelRequest = await this.productService.get({ route: `/newAlternative/offerToNewAlternative/${this.scope.idOffer}`, data: { sysConvertIdToString: false } });
            if (newAlternativeModelRequest && newAlternativeModelRequest.data && newAlternativeModelRequest.data.data) {
                const newAlternativeModel: IOfferTabResponse = newAlternativeModelRequest.data.data;
                this.scope.isFclCargo = newAlternativeModel.TYPE_CARGO && newAlternativeModel.TYPE_CARGO.ID == ECargoTypeId.FCL;
                this.scope.isRoadCargo = newAlternativeModel.TYPE_CARGO && newAlternativeModel.TYPE_CARGO.ID == ECargoTypeId.ROAD;
                this.scope.isBbLclRoroCargo = newAlternativeModel.TYPE_CARGO && (newAlternativeModel.TYPE_CARGO.ID == ECargoTypeId.BREAK_BULK || newAlternativeModel.TYPE_CARGO.ID == ECargoTypeId.LCL || newAlternativeModel.TYPE_CARGO.ID == ECargoTypeId.RO_RO);
                this.scope.isBbRoroCargo = newAlternativeModel.TYPE_CARGO && (newAlternativeModel.TYPE_CARGO.ID == ECargoTypeId.BREAK_BULK || newAlternativeModel.TYPE_CARGO.ID == ECargoTypeId.RO_RO);
                this.scope.isAirCargo = newAlternativeModel.TYPE_CARGO && newAlternativeModel.TYPE_CARGO.ID == ECargoTypeId.AIR;
                this.scope.isCombined = newAlternativeModel.IS_COMBINED;
                this.scope.model.OFFER_TAB = newAlternativeModel;
                this.timeout(() => {
                    this.scope.selectorValidity("carrier");
                    this.scope.selectorValidity("buyingRateContract");
                    this.scope.selectorValidity("sellingRateContract");
                    this.scope.selectorValidity("shipper");
                    this.scope.selectorValidity("consignee");
                    this.scope.selectorValidity("incoterm");
                }, 500);
            }
            if (this.scope.isAirCargo) this.updateWeightRangeList({ search: "", products: [this.scope.model.OFFER_TAB.PRODUCT.ID] });
            this.buildIndicatorTableSettings();
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getMoveTypeList(): Promise<IMoveTypeModel[]> {
        let moveTypeList: IMoveTypeModel[] = [];
        const result = await this.productService.get({ route: '/moveType/list/full', data: { sysConvertIdToString: false } });
        if (result && result.data && result.data.data) moveTypeList = result.data.data;
        return moveTypeList;
    }

    private async getGenericValue(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async getGenericAlternativeAsCodeList(identifier: string): Promise<ISelectorModel[]> {
        try {
            let resultList = new Array<ISelectorModel>();

            const { data: generic } = await this.helperService.get(`/generic/getByIdentifier/${identifier}`, null, 15000);

            if ((generic.status !== 200) || (!generic.data)) return null;

            let selectorList = generic && generic.data ? generic.data : [];

            for (const item of selectorList) resultList.push({ ID: item.CODE, NAME: item.VALUE, CODE: item.ALTERNATIVE });

            if (resultList.length > 0) {
                resultList = resultList.sort((x, y) => x.ID < y.ID ? -1 : 1);
            }

            return resultList;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getIncotermListByName(search: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            const products = this.scope.model.OFFER_TAB.PRODUCT ? [this.scope.model.OFFER_TAB.PRODUCT.ID] : [];
            const incoterms = await this.productService.post({ route: '/incoterm/list/custom', data: { search, products, sysConvertIdToString: false } });
            if (incoterms && incoterms.data && incoterms.data.data && incoterms.data.data.length > 0) result = incoterms.data.data.map(incoterm => { return { ID: incoterm.ID, NAME: incoterm.NAME, CODE: incoterm.INITIALS } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getProviderListByName(search?: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            this.block();
            const providers = await this.productService.post({ route: '/provider/list/custom', data: { search: search, sysConvertIdToString: false }, timeout: 30000 });
            if (providers && providers.data && providers.data.data) result = providers.data.data.map(carrier => { return { ID: carrier.ID, NAME: carrier.NAME, CODE: carrier.SCAC_IATA } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getTariffFreightCustomOfferListByName(filter: ITariffFreightOfferListCustomFilter): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            this.block();
            const tariffContracts = await this.productService.post({ route: '/tariffFreight/list/custom/offer', data: { ...filter, sysConvertIdToString: false } });
            if (tariffContracts && tariffContracts.data && tariffContracts.data.data && tariffContracts.data.data.data && tariffContracts.data.data.data.length > 0) result = tariffContracts.data.data.data.map(tariffContract => { return { ID: tariffContract.ID, NAME: tariffContract.CONCATENATED, CODE: tariffContract.CONTRACT } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getLegalPersonListByName(filter: ILegalPersonListCustomFilter): Promise<ISelectorModel[]> {
        let result = [];
        try {
            this.block();
            const legalPersons = await this.productService.post({ route: '/legalPerson/list/custom', data: { ...filter, sysConvertIdToString: false } });
            if (legalPersons && legalPersons.data && legalPersons.data.data && legalPersons.data.data.length) result = legalPersons.data.data.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getRoutingPointListByName(name: string, types: string[]): Promise<IRoutingPointSelector[]> {
        let result: IRoutingPointSelector[] = [];
        try {
            this.block();
            const routingPoints = await this.productService.post({ route: '/routingPoint/list/custom/', data: { name, types, sysConvertIdToString: false } });
            if (routingPoints && routingPoints.data && routingPoints.data.data && routingPoints.data.data.data && routingPoints.data.data.data.length > 0) {
                result = routingPoints.data.data.data.map(routingPoint => {
                    return {
                        ID: routingPoint.ID,
                        NAME: routingPoint.NAME,
                        DISPLAY_NAME: routingPoint.DISPLAY_NAME,
                        CODE: routingPoint.CODE,
                        COUNTRY: routingPoint.COUNTRY
                    }
                });
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getEquipmentListByNameOrCode(search?: string): Promise<IEquipmentSelector[]> {
        let result: IEquipmentSelector[] = [];
        this.block();
        try {
            const equipments = await this.productService.post({ route: '/equipment/list/custom', data: { search: search, products: [], sysConvertIdToString: false } }, false);
            if (equipments && equipments.data && equipments.data.data && equipments.data.data.length) result = equipments.data.data.map(equipment => { return { ID: equipment.ID, NAME: equipment.NAME, CODE: equipment.CODE, TEU: equipment.TEU, FEET: equipment.FEET } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getAgentListByName(filter: IAgentListCustomFilter): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            this.block();
            const agents = await this.productService.post({ route: '/agent/list/custom', data: { ...filter, sysConvertIdToString: false } });
            if (agents && agents.data && agents.data.data && agents.data.data.data && agents.data.data.data.length) result = agents.data.data.data.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.NETWORK_ID, LEGAL_PERSON_ID: x.LEGAL_PERSON_ID } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getCurrencyListByName(search?: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            const currencies = await this.productService.post({ route: "/currency/list/custom", data: { name: search } }, false);
            if (currencies && currencies.data && currencies.data.data) result = currencies.data.data.map(currency => { return { ID: currency.ID, NAME: currency.NAME, CODE: currency.INITIALS } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getChargeNameListByName(filter: IChargeNameListCustomFilter): Promise<ISelectorModel[]> {
        let result = [];
        this.block();
        try {
            const charges = await this.productService.post({ route: "/chargeName/list/custom/exhibition", data: { ...filter, sysConvertIdToString: false } });
            if (charges && charges.data && charges.data.data && charges.data.data.data) result = charges.data.data.data.map(charge => { return { ID: charge.ID, NAME: charge.NAME, CODE: charge.CODE } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getApplicationListByName(product: ISelectorModel[], typeCargo: ISelectorModel[], search: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        let products = [];
        let typeCargos = [];
        try {
            if (search && search.length >= 2) {
                if (!product) throw Error(this.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                if (!typeCargo) throw Error('Select type cargo first!');
                this.block();
                products = product.map(item => item.ID);
                typeCargos = typeCargo.map(item => item.ID);
                const application = await this.productService.post({ route: "/application/list/custom", data: { search, products, typeCargos } }, false);
                if (application && application.data && application.data.data) {
                    result = application.data.data.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PERCENT: x.PERCENT, APPLICATION_COMPLEMENT: x.APPLICATION_COMPLEMENT, INTERNAL_SEQUENCE: x.INTERNAL_SEQUENCE, FREE_TYPING_AMOUNT_CHARGE: x.FREE_TYPING_AMOUNT_CHARGE, CT_WITHOUT_DOC: x.CT_WITHOUT_DOC, NAME_INTL: x.NAME_INTL } });
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getApplicationListByChargeNameId(optionChargeName: ISelectorModel): Promise<void> {
        try {
            this.block();
            if (!optionChargeName) return this.notifyError("optionChargeName is null");
            if (optionChargeName) {
                const applicationOperation = await this.productService.get({ route: `/application/list/custom/charge_name/${optionChargeName.ID}` });
                const application = applicationOperation && applicationOperation.data && applicationOperation.data.data ? applicationOperation.data.data : null;
                if (application && application.CHARGE_NAME.PARAMS) {
                    const paymentApplicationList = application.CHARGE_NAME.PARAMS.map(x => {
                        return {
                            ID: x.PAYMENT.ID,
                            NAME: x.PAYMENT.NAME,
                            CODE: x.PAYMENT.CODE,
                            PERCENT: x.PAYMENT.PERCENT,
                            APPLICATION_COMPLEMENT: x.PAYMENT.APPLICATION_COMPLEMENT,
                            INTERNAL_SEQUENCE: x.PAYMENT.INTERNAL_SEQUENCE,
                            FREE_TYPING_AMOUNT_CHARGE: x.PAYMENT.FREE_TYPING_AMOUNT_CHARGE,
                            CT_WITHOUT_DOC: x.PAYMENT.CT_WITHOUT_DOC,
                            NAME_INTL: x.PAYMENT.NAME_INTL
                        }
                    });
                    const receivingApplicationList = application.CHARGE_NAME.PARAMS.map(x => {
                        return {
                            ID: x.RECEIVING.ID,
                            NAME: x.RECEIVING.NAME,
                            CODE: x.RECEIVING.CODE,
                            PERCENT: x.RECEIVING.PERCENT,
                            APPLICATION_COMPLEMENT: x.RECEIVING.APPLICATION_COMPLEMENT,
                            INTERNAL_SEQUENCE: x.RECEIVING.INTERNAL_SEQUENCE,
                            FREE_TYPING_AMOUNT_CHARGE: x.RECEIVING.FREE_TYPING_AMOUNT_CHARGE,
                            CT_WITHOUT_DOC: x.RECEIVING.CT_WITHOUT_DOC,
                            NAME_INTL: x.RECEIVING.NAME_INTL
                        }
                    });
                    if (paymentApplicationList && paymentApplicationList.length > 0) this.scope.model.DETAILS_TAB.SECURE.APPLICATION_PAYMENT = paymentApplicationList[0];
                    if (receivingApplicationList && receivingApplicationList.length > 0) this.scope.model.DETAILS_TAB.SECURE.APPLICATION_RECEIVING = receivingApplicationList[0];
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getNewFreightContractListByName(search: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            this.block();
            const freightContracts = await this.restService.newObjectPromise(`${this.config.productUrl}/product/newFreightContract/list/custom`, { search }, 30000, false);
            if (freightContracts && freightContracts.length > 0) result = freightContracts.map(freightContract => { return { ID: freightContract.ID, NAME: freightContract.CONCATENATED, CODE: null } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getFreightRoutesListByName(search: string, transactionId: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            this.block();

            const filters: IListCustomFilters = {
                SEARCH: search,
                PAYMENTS: [transactionId]
            }

            const freightRoutes = await this.restService.newObjectPromise(`${this.config.productUrl}/product/freightRoutes/list/custom`, { filters }, 30000, false);
            if (freightRoutes && freightRoutes.length) result = freightRoutes.map(freightRoute => { return { ID: freightRoute.ID, NAME: freightRoute.CONCATENATED, CODE: null } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getInlandRoutesListByName(search: string, transactionId: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            this.block();

            const inlandRoutes = await this.productService.post({ route: "/inlandRoutes/list/custom", data: { search, transactionId } });
            if (inlandRoutes && inlandRoutes.data && inlandRoutes.data.data && inlandRoutes.data.data.data) result = inlandRoutes.data.data.data.map(inlandRoute => { return { ID: inlandRoute.ID, NAME: inlandRoute.NAME, CODE: null } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getOuterCellListByName(search?: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        let outerCellList = null;
        try {
            if (search && search.length >= 2) {
                this.block();
                outerCellList = await this.restService.newObjectPromise(`${this.config.productUrl}/product/outerCell/list/custom/`, { name: search }, 30000, false);
                if (outerCellList && outerCellList.length > 0) result = outerCellList.map(outerCell => { return { ID: outerCell.ID, NAME: outerCell.NAME, CODE: outerCell.CODE ? outerCell.CODE : null, ENTITY: outerCell.ENTITY ? outerCell.ENTITY : null } });
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getProductConfigurationList(): Promise<IProductConfigurationModel[]> {
        let productConfiguration: IProductConfigurationModel[] = [];
        const result = await this.productService.post({ route: "/productConfiguration/list" }, false);
        if (result && result.data && result.data.data) productConfiguration = result.data.data;
        return productConfiguration;
    }

    private selectRouteOption(routeOptionIndex: number) {
        if (!routeOptionIndex && routeOptionIndex != 0) return this.notifyError("routeOptionIndex is null.");
        if (this.scope.routeOptionsList && this.scope.routeOptionsList.length && this.scope.routeOptionsList[routeOptionIndex]) {
            this.scope.routeOptionsList.forEach(async (routeOption, index) => {
                routeOption.SELECTED = (index == routeOptionIndex);
                if (routeOption.SELECTED) {
                    routeOption.MOVE_TYPE = routeOption.MOVE_TYPE ? routeOption.MOVE_TYPE : null;
                    routeOption.ORIGIN = routeOption.ORIGIN ? routeOption.ORIGIN : null;
                    routeOption.DESTINATION = routeOption.DESTINATION ? routeOption.DESTINATION : null;

                    this.scope.model.ROUTES_TAB.ROUTES = [];
                    this.scope.model.ROUTES_TAB.ROUTES.push(routeOption);

                    this.timeout(() => {
                        this.scope.selectorValidity("routeMoveType" + index);
                        this.scope.selectorValidity("routeOrigin" + index);
                        this.scope.selectorValidity("routeDestination" + index);
                    }, 100);
                }
            });
        }
    }

    private selectOfferOption(offerOptionIndex: number) {
        if (!offerOptionIndex && offerOptionIndex != 0) return this.notifyError("offerOptionIndex is null.");
        if (this.scope.offerOptions && this.scope.offerOptions.length && this.scope.offerOptions[offerOptionIndex]) {
            this.scope.offerOptions.forEach(async (offerOption, index) => {
                offerOption.SELECTED = (index == offerOptionIndex);
                offerOption.SHOW_DETAILS = offerOption.SELECTED;
            });
        }
    }

    private async specSecureChange() {
        if (this.scope.model.DETAILS_TAB) {
            if (this.scope.model.DETAILS_TAB.IS_INSURANCE) {
                const offerProduct = this.scope.model.OFFER_TAB && this.scope.model.OFFER_TAB.PRODUCT ? this.scope.model.OFFER_TAB.PRODUCT.ID : null;
                // Note: the product configuration registration will always have at most one registration.
                try {
                    this.block();
                    const productConfigurationList = await this.getProductConfigurationList();
                    if (productConfigurationList && productConfigurationList.length) this.scope.productConfigurationList = productConfigurationList;
                } catch (ex) {
                    this.handleError(ex);
                } finally {
                    this.unblock();
                }
                const productConfigurationSecure: IProductConfigurationSecure = this.scope.productConfigurationList && this.scope.productConfigurationList.length > 0 && this.scope.productConfigurationList[0].SECURE && this.scope.productConfigurationList[0].SECURE.length > 0 ? this.scope.productConfigurationList[0].SECURE.find(secure => secure.PRODUCT.some(product => product.ID == offerProduct)) : null;
                const productConfigurationTypeCargo: ISelectorModel[] = this.scope.productConfigurationList && this.scope.productConfigurationList.length > 0 && this.scope.productConfigurationList[0].PROFIT_SHARE_APPLICATION && this.scope.productConfigurationList[0].PROFIT_SHARE_APPLICATION.length > 0 ? this.scope.productConfigurationList[0].PROFIT_SHARE_APPLICATION.find(profitShare => profitShare.TYPE_CARGO).TYPE_CARGO : null;
                const secureObject = {
                    CURRENCY: null,
                    INSURED_AMOUNT: null,
                    INSURANCE_PREMIUM: null,
                    INSURANCE_RECEIVING_MIN: null,
                    COMISSION_WITHOUT_PRIZE: null,
                    INSURANCE_PAYMENT_MIN: null,
                    INSURANCE_BROKER: null,
                    ID_CHARGE_NAME_EXHIBITION: null,
                    CHARGE_NAME_EXHIBITION: null,
                    APPLICATION_PAYMENT: null,
                    APPLICATION_RECEIVING: null
                };
                if (productConfigurationSecure) {
                    secureObject.INSURANCE_PREMIUM = productConfigurationSecure.INSURANCE_PREMIUM;
                    secureObject.INSURANCE_RECEIVING_MIN = productConfigurationSecure.INSURANCE_RECEIVING_MIN;
                    secureObject.COMISSION_WITHOUT_PRIZE = productConfigurationSecure.STANDARD_COMISSION_WITHOUT_PRIZE;
                    secureObject.INSURANCE_PAYMENT_MIN = productConfigurationSecure.INSURANCE_PAYMENT_MIN;
                    secureObject.INSURANCE_BROKER = productConfigurationSecure.INSURANCE_BROKER;
                    secureObject.ID_CHARGE_NAME_EXHIBITION = productConfigurationSecure.CHARGE_NAME_EXHIBITION.ID;
                    secureObject.CHARGE_NAME_EXHIBITION = productConfigurationSecure.CHARGE_NAME_EXHIBITION;
                    secureObject.APPLICATION_PAYMENT = productConfigurationSecure.APPLICATION_PAYMENT;
                    secureObject.APPLICATION_RECEIVING = productConfigurationSecure.APPLICATION_RECEIVING;
                    this.scope.defaultProductSecure = productConfigurationSecure.PRODUCT;
                }

                if (productConfigurationTypeCargo) {
                    this.scope.defaultProfitShareTypeCargo = productConfigurationTypeCargo
                }

                this.scope.model.DETAILS_TAB.SECURE = secureObject;
            }
            else this.scope.model.DETAILS_TAB.SECURE = null;
        }
    }

    private updateWeightRangeBase(): void {
        if (this.scope.model.CARGO_TAB.AIR && this.scope.model.CARGO_TAB.AIR.WEIGHT_RANGE) {
            const weightRangeSelected: IWeightRangeSelector[] = this.scope.weightRangeFullList && this.scope.weightRangeFullList.length ? this.scope.weightRangeFullList.filter(weightRange => this.scope.model.CARGO_TAB.AIR.WEIGHT_RANGE.some(weightRangeToVerify => weightRangeToVerify.ID == weightRange.ID)) : null;
            // Pega o que for maior entre quantidade e sobretaxado para fazer a validação.
            const chargeableWeightToVerify = this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT && this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT > this.scope.model.CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT ? this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT : this.scope.model.CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT;
            const lowestRange = weightRangeSelected && weightRangeSelected.length ? weightRangeSelected.reduce(function (prev, current) {
                if (chargeableWeightToVerify && chargeableWeightToVerify > 1) {
                    const isBetweenChargeableWeight = OfferNewAlternativeHelper.betweenNumber(chargeableWeightToVerify, prev.MIN_WEIGHT, prev.MAX_WEIGHT);
                    return (prev.MIN_WEIGHT < current.MIN_WEIGHT && isBetweenChargeableWeight) ? prev : current;
                }
                return (prev.MIN_WEIGHT < current.MIN_WEIGHT) ? prev : current;
            }) : null;
            this.scope.model.CARGO_TAB.AIR.WEIGHT_RANGE_BASE = lowestRange;
            this.timeout(() => { this.scope.selectorValidity("cargoAirWeightRangeBase"); });
        }
    }

    private addMaritimeCargoFCL(): void {
        try {
            if (!this.scope.model.CARGO_TAB.FCL) this.scope.model.CARGO_TAB.FCL = [];
            const cargo: FCL = {
                ORIGINAL: null,
                EQUIPMENT: null,
                AMOUNT: 1
            }
            this.scope.model.CARGO_TAB.FCL.push(cargo);
            const lastIndex = this.scope.model.CARGO_TAB.FCL.length - 1;
            this.timeout(() => {
                this.scope.selectorValidity("cargoMaritimeFCLEquipment" + lastIndex);
            }, 100);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async removeMaritimeCargoFCL(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.scope.model.CARGO_TAB.FCL && this.scope.model.CARGO_TAB.FCL.length > 0) {
                this.scope.model.CARGO_TAB.FCL.splice(index, 1);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private addMaritimeCargoBBRORO(): void {
        try {
            if (!this.scope.model.CARGO_TAB.BB_RORO) this.scope.model.CARGO_TAB.BB_RORO = [];
            const cargo: BB_RORO = {
                ORIGINAL: null,
                EQUIPMENT: null,
                AMOUNT: 1
            }
            this.scope.model.CARGO_TAB.BB_RORO.push(cargo);
            const lastIndex = this.scope.model.CARGO_TAB.BB_RORO.length - 1;
            this.timeout(() => {
                this.scope.selectorValidity("cargoMaritimeFCLEquipment" + lastIndex);
            }, 100);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async removeMaritimeCargoBBRORO(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.scope.model.CARGO_TAB.BB_RORO && this.scope.model.CARGO_TAB.BB_RORO.length > 0) {
                this.scope.model.CARGO_TAB.BB_RORO.splice(index, 1);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async launchCargoPolLb(): Promise<void> {
        try {

            if (!this.modalCargoPolLbId) this.modalCargoPolLbId = this.modalService.newModal();

            const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                {
                    modalID: this.modalCargoPolLbId,
                    formService: 'register',
                    template: require("../view/modals/offerCargoPollbModal.html"),
                    size: 'full modal-overflow'
                },
                {
                    closeButtonText: 'GENERAL.CLOSE',
                    actionButtonText: 'REGISTRATION.APPLY',
                    headerText: `GENERAL.CARGO_DETAILS`
                }
            );

            const cargoListConverted: ICargo[] = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (cargoListConverted && this.scope.model.CARGO_TAB) {
                this.scope.model.CARGO_TAB.CARGO_LIST = this.scope.model.CARGO_TAB.CARGO_LIST ? this.scope.model.CARGO_TAB.CARGO_LIST.concat(cargoListConverted) : cargoListConverted;
                if (this.scope.model.CARGO_TAB.CARGO_LIST && this.scope.model.CARGO_TAB.CARGO_LIST.length > 0) {
                    angular.element('#collapseCargoList')["collapse"]("show");
                    this.timeout(() => {
                        this.loadRegisterForm(false);
                        this.calculateVolumeTotal();
                        this.calculateGrossWeightTotal();
                        this.calculateCBWTotal();
                        if (this.scope.model.CARGO_TAB.AIR) this.calculateOperationChargeable();
                        if (this.scope.isBbLclRoroCargo) this.calculateOperationWM();
                        this.updateAirApplication();
                        this.scope.cargoListGrossWeightFieldsControl(this.scope.model.CARGO_TAB.GROSS_WEIGHT);
                    });
                }
            }
            this.updateCargoCalculatedFields();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private calculateVolumeTotal(): void {
        this.scope.model.CARGO_TAB.VOLUME = OfferHelper.calculateVolumeTotal(this.scope.model.CARGO_TAB.CARGO_LIST);
    }

    private calculateGrossWeightTotal(): void {
        this.scope.model.CARGO_TAB.GROSS_WEIGHT = OfferHelper.calculateGrossWeightTotal(this.scope.model.CARGO_TAB.CARGO_LIST);
        if (this.scope.model.CARGO_TAB.AIR) this.calculateOperationChargeable();
    }

    private calculateCBWTotal(): void {
        this.scope.model.CARGO_TAB.CUBIC_WEIGHT = OfferHelper.calculateCBWTotal(this.scope.model.CARGO_TAB.CARGO_LIST);
    }

    private addCargo(): void {
        try {
            if (!this.scope.model.CARGO_TAB.CARGO_LIST) this.scope.model.CARGO_TAB.CARGO_LIST = [];
            const cargo: ICargo = {
                PACKAGE: null,
                VOLUME: null,
                LENGTH: null,
                WIDTH: null,
                HEIGHT: null,
                CUBIC_WEIGHT: null,
                GROSS_WEIGHT_UNITARY: null,
                GROSS_WEIGHT: null,
                UUID: null
            }
            this.scope.model.CARGO_TAB.CARGO_LIST.push(cargo);
            this.updateCargoCalculatedFields();
            const lastIndex = this.scope.model.CARGO_TAB.CARGO_LIST.length - 1;
            this.timeout(() => {
                this.scope.selectorValidity("cargoPackage" + lastIndex);
                this.scope.setDirtyFields(['cargoVolume' + lastIndex, 'cargoLength' + lastIndex, 'cargoWidth' + lastIndex, 'cargoHeight' + lastIndex, 'cargoCBW' + lastIndex, 'cargoGrossWeight' + lastIndex]);
            }, 100);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async removeCargo(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.getTranslate('GENERAL.MESSAGES.CONFIRMATION.REMOVAL')
            });
            if (!modal) return;

            if (this.scope.model.CARGO_TAB.CARGO_LIST && this.scope.model.CARGO_TAB.CARGO_LIST.length > 0) {
                this.block();
                if (this.scope.model.CARGO_TAB.CARGO_LIST.length == 1) this.scope.model.CARGO_TAB.CARGO_LIST = null;
                else this.scope.model.CARGO_TAB.CARGO_LIST.splice(index, 1);
                this.calculateVolumeTotal();
                this.calculateGrossWeightTotal();
                this.calculateCBWTotal();
                if (this.scope.isAirCargo) await this.calculateOperationChargeable();
                else if (this.scope.isBbLclRoroCargo) this.calculateOperationWM();
                this.updateCargoCalculatedFields();
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    // tab navigation
    private async handleNavigationChange(currentStep: number): Promise<boolean> {
        const nextStep = currentStep + 1;
        let success = true;
        try {
            if (nextStep == ETabStep.ROUTES) success = await this.initRoutesTab();
            else if (currentStep == ETabStep.ROUTES) success = await this.validateRoutesTab();

            if (success && nextStep == ETabStep.CARGO) success = await this.initCargoTab();
            else if (currentStep == ETabStep.CARGO) success = this.validateCargoTab();

            if (success && nextStep == ETabStep.DETAILS) success = await this.initDetailsTab();
            else if (currentStep == ETabStep.DETAILS) success = await this.validateDetailsTab();

            if (success && nextStep == ETabStep.ALTERNATIVES) success = await this.initAlternativesTab();
            else if (currentStep == ETabStep.ALTERNATIVES) success = await this.validateAlternativesTab();

            if (success && nextStep == ETabStep.RATES) success = await this.initRatesTab();
            else if (currentStep == ETabStep.RATES) success = await this.validateRatesTab();

            if (success && nextStep == ETabStep.CHARGES) success = await this.initChargesTab();
            else if (currentStep == ETabStep.CHARGES) success = await this.validateChargesTab();

            if (success && nextStep == ETabStep.GENERAL) success = await this.initGeneralTab();
            else if (currentStep == ETabStep.GENERAL) success = await this.validateGeneralTab();

            if (success && nextStep == ETabStep.CONFIRMATION) success = await this.initConfirmationTab();

            await this.scope.$applyAsync();
        } catch (ex) {
            success = false;
            this.handleError(ex);
        }
        return success;
    }

    private async initRoutesTab(): Promise<boolean> {
        let success = true;
        try {
            this.block();
            const requestData: IRoutesTabRequest = { OFFER_TAB: this.scope.model.OFFER_TAB };
            const resultOperation: IRoutesTabResponse = await this.restService.newObjectPromise(`${this.quotationCombinedUrl}/newAlternative/routes`, requestData, 30000, false);
            if (resultOperation) {
                this.scope.routeOptionsList = resultOperation.ROUTES && resultOperation.ROUTES.length ? resultOperation.ROUTES : [{ SELECTED: false, MOVE_TYPE: null, ORIGIN: null, DESTINATION: null }];
                const selectedRoute = resultOperation.ROUTES && resultOperation.ROUTES.length ? resultOperation.ROUTES.find(route => route.SELECTED) : null;
                this.scope.model.ROUTES_TAB.ROUTES = selectedRoute ? [selectedRoute] : null;
                this.scope.model.ROUTES_TAB.ORIGINAL_ROUTE_FOUND = resultOperation.ORIGINAL_ROUTE_FOUND;
            }
        } catch (ex) {
            success = false;
            this.handleError(ex);
        } finally {
            this.unblock();
            return success;
        }
    }

    private async validateRoutesTab(): Promise<boolean> {
        let valid = true;
        try {
            const hasOneSelectedOption = this.scope.model.ROUTES_TAB && this.scope.model.ROUTES_TAB.ROUTES && this.scope.model.ROUTES_TAB.ROUTES.length == 1;
            if (!hasOneSelectedOption) {
                valid = false;
                const msgError = this.getTranslate('GENERAL.MANDATORY_ROUTE');
                this.notifyError(msgError)
            } else {
                valid = await this.createOfferWizardFilter(false);
            }
        } catch (ex) {
            valid = false;
            this.handleError(ex);
        }
        return valid;
    }

    private async createOfferWizardFilter(reprocess?: boolean): Promise<boolean> {
        let valid = true;
        try {
            this.block();
            const formatedData: IOfferWizardFilter = {
                _id: this.scope.model._id,
                OFFER_TAB: this.scope.model.OFFER_TAB,
                ROUTE_TAB: this.scope.model.ROUTES_TAB,
                CARGO_TAB: this.scope.model.CARGO_TAB,
                SPEC_TAB: this.scope.model.DETAILS_TAB,
                ID_OPPORTUNITY: null,
                REPROCESS: reprocess,
                COMPLETED: null,
                CREATED_AT: null,
                CREATED_BY: null,
                MODIFIED_AT: null,
                MODIFIED_BY: null
            };
            const formatedOldData: IOfferWizardFilter = this.scope.scopeBeforeSave ? {
                _id: this.scope.scopeBeforeSave._id,
                OFFER_TAB: this.scope.scopeBeforeSave.OFFER_TAB,
                ROUTE_TAB: this.scope.scopeBeforeSave.ROUTES_TAB,
                CARGO_TAB: this.scope.scopeBeforeSave.CARGO_TAB,
                SPEC_TAB: this.scope.scopeBeforeSave.DETAILS_TAB,
                ID_OPPORTUNITY: null,
                REPROCESS: null,
                COMPLETED: null,
                CREATED_AT: null,
                CREATED_BY: null,
                MODIFIED_AT: null,
                MODIFIED_BY: null
            } : null;
            if (formatedData.CARGO_TAB && formatedData.CARGO_TAB.AIR && formatedData.CARGO_TAB.AIR.APPLICATION) {
                formatedData.CARGO_TAB.AIR.APPLICATION.ORDER = null;
                formatedData.CARGO_TAB.AIR.ORIGINAL_AIR_CARGO.APPLICATION.ORDER = null;
            }
            if (!formatedData.OFFER_TAB.OUTER_CELL) formatedData.OFFER_TAB.OUTER_CELL = null;
            if (formatedData.OFFER_TAB.EXTENDED_SEARCH) {
                formatedData.OFFER_TAB.EXTENDED_SEARCH_DATE = moment(formatedData.OFFER_TAB.LOAD_REF).add(formatedData.OFFER_TAB.EXTENDED_SEARCH.ID, 'days').toDate();
            } else {
                formatedData.OFFER_TAB.EXTENDED_SEARCH = null;
                formatedData.OFFER_TAB.EXTENDED_SEARCH_DATE = null;
            }
            const requestData: IInsertFilterRequest = { data: formatedData, oldData: formatedOldData };
            const resultOperation: IInsertFilterResponse = await this.restService.newObjectPromise(`${this.quotationCombinedUrl}/newAlternative/filter/insert`, requestData, 30000, false);
            if (resultOperation && resultOperation._id && resultOperation._id) {
                this.scope.model._id = resultOperation._id;
                this.scope.scopeBeforeSave = angular.copy(this.scope.model);
            }
        } catch (ex) {
            valid = false;
            this.handleError(ex);
        } finally {
            this.unblock();
            return valid;
        }
    }

    private async initCargoTab(): Promise<boolean> {
        let success = true;
        try {
            await this.getAndUpdateCargoTab();
            this.updateCargoCalculatedFields();
        } catch (ex) {
            success = false;
            this.handleError(ex);
        } finally {
            return success;
        }
    }

    private async getAndUpdateCargoTab(): Promise<void> {
        try {
            this.block();
            const formatedData: IOfferWizardFilter = {
                _id: this.scope.model._id,
                OFFER_TAB: this.scope.model.OFFER_TAB,
                ROUTE_TAB: this.scope.model.ROUTES_TAB,
                CARGO_TAB: this.scope.model.CARGO_TAB,
                SPEC_TAB: this.scope.model.DETAILS_TAB,
                ID_OPPORTUNITY: null,
                REPROCESS: null,
                COMPLETED: null,
                CREATED_AT: null,
                CREATED_BY: null,
                MODIFIED_AT: null,
                MODIFIED_BY: null
            };
            const requestData: ICargoTabRequest = { data: formatedData };
            const resultOperation: ICargoTabResponse = await this.restService.newObjectPromise(`${this.quotationCombinedUrl}/newAlternative/cargo`, requestData, 30000, false);
            if (resultOperation) this.scope.model.CARGO_TAB = resultOperation;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private validateCargoTab(): boolean {
        let valid = true;
        try {
            const typeCargo = this.scope.model.OFFER_TAB.TYPE_CARGO;
            const isFcl = typeCargo && typeCargo.ID == ECargoTypeId.FCL;
            // FCL - Validations
            if (isFcl) {
                const hasAnyCargo = this.scope.model.CARGO_TAB && this.scope.model.CARGO_TAB.FCL && this.scope.model.CARGO_TAB.FCL.length > 0;
                if (!hasAnyCargo) {
                    valid = false;
                    this.notifyError("It is mandatory to have at least one cargo.")
                }
            }
        } catch (ex) {
            valid = false;
            this.handleError(ex);
        }
        return valid;
    }

    private async initDetailsTab(): Promise<boolean> {
        let success = true;
        try {
            await this.getAndUpdateRouteAgentValues();
            await this.getAndUpdateDetailsTab();
        } catch (ex) {
            success = false;
            this.handleError(ex);
        } finally {
            return success;
        }
    }

    private async validateDetailsTab(): Promise<boolean> {
        let valid = true;
        try {
            if (this.scope.model.DETAILS_TAB.ROUTE && this.scope.model.DETAILS_TAB.ROUTE.length && this.scope.model.DETAILS_TAB.ROUTE.some(route => !route.ACCOUNT_REQUIREMENT_ID)) {
                valid = false;
                const msgError = this.getTranslate('GENERAL.MANDATORY_CUSTOMER_REQUIREMENT');
                this.notifyError(msgError);
            } else valid = await this.updateOfferWizardFilter();
        } catch (ex) {
            valid = false;
            this.handleError(ex);
        }
        return valid;
    }

    private async initAlternativesTab(): Promise<boolean> {
        let success = true;
        try {
            this.scope.alternativesTabSearchControl = { isSearchTabDataListCanceled: false, isSearchingTabDataList: false, searchTabDataError: null, searchTabDataListCount: null, searchTabDataCurrentAttempt: null, searchTabDataMaxAttempt: null };
            this.updateOrdinationList();
            this.getAndUpdateOptionList();
        } catch (ex) {
            success = false;
            this.handleError(ex);
        } finally {
            return success;
        }
    }

    private async validateAlternativesTab(): Promise<boolean> {
        let valid = true;
        try {
            const hasAnyAlternativeSelected = this.scope.offerOptions && this.scope.offerOptions.length && this.scope.offerOptions.some(option => option.SELECTED);
            if (!hasAnyAlternativeSelected) {
                valid = false;
                const msgError = this.getTranslate('GENERAL.AT_LEAST_OPTION');
                this.notifyError(msgError);
            }
        } catch (ex) {
            valid = false;
            this.handleError(ex);
        }
        return valid;
    }

    private async initRatesTab(): Promise<boolean> {
        let success = true;
        try {
            this.scope.ratesTabSearchControl = { isSearchTabDataListCanceled: false, isSearchingTabDataList: false, searchTabDataError: null, searchTabDataListCount: null, searchTabDataCurrentAttempt: null, searchTabDataMaxAttempt: null };
            this.getAndUpdateRatesList();
        } catch (ex) {
            success = false;
            this.handleError(ex);
        } finally {
            return success;
        }
    }

    private async validateRatesTab(): Promise<boolean> {
        let valid = true;
        try {

        } catch (ex) {
            valid = false;
            this.handleError(ex);
        }
        return valid;
    }

    private async initChargesTab(): Promise<boolean> {
        let success = true;
        try {
            this.scope.chargesTabSearchControl = { isSearchTabDataListCanceled: false, searchTabDataError: null, isSearchingTabDataList: false, searchTabDataListCount: null, searchTabDataCurrentAttempt: null, searchTabDataMaxAttempt: null };
            this.getAndUpdateChargesList();
        } catch (ex) {
            success = false;
            this.handleError(ex);
        } finally {
            return success;
        }
    }

    private async validateChargesTab(): Promise<boolean> {
        let valid = true;
        try {

        } catch (ex) {
            valid = false;
            this.handleError(ex);
        }
        return valid;
    }

    private async initGeneralTab(): Promise<boolean> {
        let success = true;
        try {
            await this.getAndUpdateGeneralTab();
        } catch (ex) {
            success = false;
            this.handleError(ex);
        } finally {
            return success;
        }
    }

    private async validateGeneralTab(): Promise<boolean> {
        let valid = true;
        try {

        } catch (ex) {
            valid = false;
            this.handleError(ex);
        }
        return valid;
    }

    private async initConfirmationTab(): Promise<boolean> {
        let success = true;
        try {
            await this.getAndUpdateConfirmationTab();
        } catch (ex) {
            success = false;
            this.handleError(ex);
        } finally {
            return success;
        }
    }

    private async finish(): Promise<void> {
        try {
            this.block();
            const timeout: number = 300000;
            const formatedData: IOfferOptions = { id: this.scope.model.CONFIRMATION_TAB.ID_OFFER_OPTION_PAYMENT, ...this.scope.model.CONFIRMATION_TAB.NEW_OFFER };
            const requestData: IGenerateOfferRequest = {
                OFFER_OPTIONS: [formatedData],
                NEW_ALTERNATIVE_OPTIONS: { KEEP_REFERENCES: this.scope.model.GENERAL_TAB.KEEP_REFERENCES, KEEP_MANUAL_CONTACTS: this.scope.model.GENERAL_TAB.KEEP_MANUAL_CONTACTS, KEEP_INSTRUCTIONS: this.scope.model.GENERAL_TAB.KEEP_INSTRUCTIONS }
            }
            const resultOperation: IGenerateOfferResult[] = await this.restService.newObjectPromise(`${this.quotationCombinedUrl}/newAlternative/generate-offer`, requestData, timeout, false);
            if (resultOperation && resultOperation.length) {
                const error = resultOperation.find(obj => obj.ERROR != null);
                if (error) this.notifyError(error.ERROR);
                else {
                    let params = resultOperation.map(x => x.OFFER_CODE_EXHIBITION.toString()).join(';');
                    const sessionParameter = <ISessionParameter>{
                        param: { OFFER_CODE_EXHIBITION: params },
                    };
                    sessionStorage.setItem("app.commercial.offer", JSON.stringify(sessionParameter));
                    this.scope.modalOptions.ok(false);
                    window.location.reload();
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getAndUpdateGeneralTab(): Promise<void> {
        try {
            this.block();
            const formatedData: IOfferWizardFilter = {
                _id: this.scope.model._id,
                OFFER_TAB: this.scope.model.OFFER_TAB,
                ROUTE_TAB: this.scope.model.ROUTES_TAB,
                CARGO_TAB: this.scope.model.CARGO_TAB,
                SPEC_TAB: this.scope.model.DETAILS_TAB,
                ID_OPPORTUNITY: null,
                REPROCESS: null,
                COMPLETED: null,
                CREATED_AT: null,
                CREATED_BY: null,
                MODIFIED_AT: null,
                MODIFIED_BY: null
            };
            const requestData: IGeneralTabRequest = { data: formatedData };
            const resultOperation: IGeneralTabResponse = await this.restService.newObjectPromise(`${this.quotationCombinedUrl}/newAlternative/general`, requestData, 30000, false);
            if (resultOperation) {
                this.scope.model.GENERAL_TAB.REFERENCES = resultOperation.REFERENCES;
                this.scope.model.GENERAL_TAB.CONTACTS = resultOperation.CONTACTS;
                this.scope.model.GENERAL_TAB.INSTRUCTIONS = resultOperation.INSTRUCTIONS;
                this.scope.hasManualContacts = resultOperation.CONTACTS && resultOperation.CONTACTS.length && resultOperation.CONTACTS.some(contact => contact.DATA_ORIGIN_TYPE && contact.DATA_ORIGIN_TYPE.ID == EDataOriginTypeId.MANUAL);
                this.scope.hasInstructions = resultOperation.INSTRUCTIONS && (this.hasValueInString(resultOperation.INSTRUCTIONS.AGENT) || this.hasValueInString(resultOperation.INSTRUCTIONS.COMMERCIAL) || this.hasValueInString(resultOperation.INSTRUCTIONS.CUSTOMER) || this.hasValueInString(resultOperation.INSTRUCTIONS.FINANCIAL) || this.hasValueInString(resultOperation.INSTRUCTIONS.OPERATIONAL) || this.hasValueInString(resultOperation.INSTRUCTIONS.PROCESS_HOUSE) || this.hasValueInString(resultOperation.INSTRUCTIONS.PROCESS_MASTER) || this.hasValueInString(resultOperation.INSTRUCTIONS.PRODUCT));
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private hasValueInString(str: string): boolean {
        let hasValue = false;
        try {
            hasValue = str && str.length > 0;
        } catch (ex) {
            this.handleError(ex);
        }
        return hasValue;
    }

    private async getAndUpdateConfirmationTab(): Promise<void> {
        try {
            this.block();
            const formatedData: IConfirmationTabRequest = {
                data: {
                    _id: this.scope.model._id,
                    OFFER_TAB: this.scope.model.OFFER_TAB,
                    ROUTE_TAB: this.scope.model.ROUTES_TAB,
                    CARGO_TAB: this.scope.model.CARGO_TAB,
                    SPEC_TAB: this.scope.model.DETAILS_TAB,
                    ID_OPPORTUNITY: null,
                    REPROCESS: null,
                    COMPLETED: null,
                    CREATED_AT: null,
                    CREATED_BY: null,
                    MODIFIED_AT: null,
                    MODIFIED_BY: null
                },
                ID_OFFER_OPTION_PAYMENT: this.scope.model.CHARGES_TAB.ID_OFFER_OPTION_PAYMENT,
                KEEP_MANUAL_CHARGES: this.scope.model.CHARGES_TAB.KEEP_MANUAL_CHARGES,
                KEEP_SELLING_RATES: this.scope.model.CHARGES_TAB.KEEP_SELLING_RATES,
            };
            const requestData: IConfirmationTabRequest = formatedData;
            const resultOperation: IConfirmationTabResponse = await this.restService.newObjectPromise(`${this.quotationCombinedUrl}/newAlternative/confirmation`, requestData, 30000, false);
            if (resultOperation) {
                this.scope.model.CONFIRMATION_TAB.ID_OFFER_OPTION_PAYMENT = resultOperation.ID_OFFER_OPTION_PAYMENT;
                this.scope.model.CONFIRMATION_TAB.NEW_OFFER = resultOperation.NEW_OFFER;
                this.scope.model.CONFIRMATION_TAB.CURRENT_OFFER = resultOperation.CURRENT_OFFER;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getAndUpdateRatesList(): Promise<void> {
        try {
            const ratesTabData = this.scope.model.RATES_TAB;
            const ratesTabSearchControl = this.scope.ratesTabSearchControl;
            ratesTabSearchControl.isSearchTabDataListCanceled = false;
            ratesTabSearchControl.searchTabDataError = null;
            if (!ratesTabSearchControl.isSearchTabDataListCanceled) ratesTabSearchControl.searchTabDataCurrentAttempt = 0;
            ratesTabData.FREIGHT_DOMESTIC = [];
            ratesTabData.INLAND = [];
            ratesTabData.LOCAL = [];
            ratesTabData.DEMURRAGE_DETENTION = [];
            const selectedOfferOption = this.scope.offerOptions.find(offerOption => offerOption.SELECTED);
            const resultOperation: ICompareRatesTabResponse = await this.restService.newObjectPromise(`${this.quotationCombinedUrl}/newAlternative/rates`, { ID_OFFER_WIZARD_FILTER: this.scope.model._id, ID_OFFER_OPTION_PAYMENT: selectedOfferOption._id }, 30000, false);
            if (resultOperation) {
                if (resultOperation.SHOW) {
                    if (resultOperation.RATES && resultOperation.RATES.length) {
                        for (const rate of resultOperation.RATES) {
                            if (rate.NEW_RATES && rate.CURRENT_RATES) {
                                if (rate.NEW_RATES.FREIGHT_RATES && rate.CURRENT_RATES.FREIGHT_RATES) {
                                    ratesTabData.FREIGHT_DOMESTIC.push({ TYPE: "Freight", IS_NEW: true, ...rate.NEW_RATES.FREIGHT_RATES });
                                    ratesTabData.FREIGHT_DOMESTIC.push({ TYPE: "Freight", IS_NEW: false, ...rate.CURRENT_RATES.FREIGHT_RATES });
                                }
                                if (rate.NEW_RATES.INLAND_PUP_RATES && rate.CURRENT_RATES.INLAND_PUP_RATES) {
                                    ratesTabData.INLAND.push({ TYPE: "Domestic - PUP", IS_NEW: true, ...rate.NEW_RATES.INLAND_PUP_RATES });
                                    ratesTabData.INLAND.push({ TYPE: "Domestic - PUP", IS_NEW: false, ...rate.CURRENT_RATES.INLAND_PUP_RATES });
                                }
                                if (rate.NEW_RATES.INLAND_OTFS_RATES && rate.CURRENT_RATES.INLAND_OTFS_RATES) {
                                    ratesTabData.INLAND.push({ TYPE: "Domestic - OTFS", IS_NEW: true, ...rate.NEW_RATES.INLAND_OTFS_RATES });
                                    ratesTabData.INLAND.push({ TYPE: "Domestic - OTFS", IS_NEW: false, ...rate.CURRENT_RATES.INLAND_OTFS_RATES });
                                }
                                if (rate.NEW_RATES.INLAND_DTFS_RATES && rate.CURRENT_RATES.INLAND_DTFS_RATES) {
                                    ratesTabData.INLAND.push({ TYPE: "Domestic - DTFS", IS_NEW: true, ...rate.NEW_RATES.INLAND_DTFS_RATES });
                                    ratesTabData.INLAND.push({ TYPE: "Domestic - DTFS", IS_NEW: false, ...rate.CURRENT_RATES.INLAND_DTFS_RATES });
                                }
                                if (rate.NEW_RATES.INLAND_PLD_RATES && rate.CURRENT_RATES.INLAND_PLD_RATES) {
                                    ratesTabData.INLAND.push({ TYPE: "Domestic - PLD", IS_NEW: true, ...rate.NEW_RATES.INLAND_PLD_RATES });
                                    ratesTabData.INLAND.push({ TYPE: "Domestic - PLD", IS_NEW: false, ...rate.CURRENT_RATES.INLAND_PLD_RATES });
                                }
                                if (rate.NEW_RATES.DOMESTIC_ORIGIN_RATES && rate.CURRENT_RATES.DOMESTIC_ORIGIN_RATES) {
                                    ratesTabData.FREIGHT_DOMESTIC.push({ TYPE: "DO", IS_NEW: true, ...rate.NEW_RATES.DOMESTIC_ORIGIN_RATES });
                                    ratesTabData.FREIGHT_DOMESTIC.push({ TYPE: "DO", IS_NEW: false, ...rate.CURRENT_RATES.DOMESTIC_ORIGIN_RATES });
                                }
                                if (rate.NEW_RATES.DOMESTIC_DESTINATION_RATES && rate.CURRENT_RATES.DOMESTIC_DESTINATION_RATES) {
                                    ratesTabData.FREIGHT_DOMESTIC.push({ TYPE: "DD", IS_NEW: true, ...rate.NEW_RATES.DOMESTIC_DESTINATION_RATES });
                                    ratesTabData.FREIGHT_DOMESTIC.push({ TYPE: "DD", IS_NEW: false, ...rate.CURRENT_RATES.DOMESTIC_DESTINATION_RATES });
                                }
                                if (rate.NEW_RATES.LOCAL_ORIGIN_RATES && rate.CURRENT_RATES.LOCAL_ORIGIN_RATES) {
                                    ratesTabData.LOCAL.push({ TYPE: "Origin", IS_NEW: true, ...rate.NEW_RATES.LOCAL_ORIGIN_RATES });
                                    ratesTabData.LOCAL.push({ TYPE: "Origin", IS_NEW: false, ...rate.CURRENT_RATES.LOCAL_ORIGIN_RATES });
                                }
                                if (rate.NEW_RATES.LOCAL_DESTINATION_RATES && rate.CURRENT_RATES.LOCAL_DESTINATION_RATES) {
                                    ratesTabData.LOCAL.push({ TYPE: "Destination", IS_NEW: true, ...rate.NEW_RATES.LOCAL_DESTINATION_RATES });
                                    ratesTabData.LOCAL.push({ TYPE: "Destination", IS_NEW: false, ...rate.CURRENT_RATES.LOCAL_DESTINATION_RATES });
                                }
                                if (rate.NEW_RATES.DET_DEM_ORIGIN_RATES && rate.CURRENT_RATES.DET_DEM_ORIGIN_RATES) {
                                    ratesTabData.DEMURRAGE_DETENTION.push({ TYPE: "D&D Origin", IS_NEW: true, ...rate.NEW_RATES.DET_DEM_ORIGIN_RATES });
                                    ratesTabData.DEMURRAGE_DETENTION.push({ TYPE: "D&D Origin", IS_NEW: false, ...rate.CURRENT_RATES.DET_DEM_ORIGIN_RATES });
                                }
                                if (rate.NEW_RATES.DET_DEM_DESTINATION_RATES && rate.CURRENT_RATES.DET_DEM_DESTINATION_RATES) {
                                    ratesTabData.DEMURRAGE_DETENTION.push({ TYPE: "D&D Destination", IS_NEW: true, ...rate.NEW_RATES.DET_DEM_DESTINATION_RATES });
                                    ratesTabData.DEMURRAGE_DETENTION.push({ TYPE: "D&D Destination", IS_NEW: false, ...rate.CURRENT_RATES.DET_DEM_DESTINATION_RATES });
                                }
                            }
                        }
                    }
                    ratesTabSearchControl.searchTabDataError = resultOperation.PROCESS && resultOperation.PROCESS.length ? resultOperation.PROCESS.filter(process => process.ERROR && process.ERROR.length).map(process => process.ERROR) : null;
                    ratesTabSearchControl.isSearchingTabDataList = false;
                    ratesTabSearchControl.searchTabDataCurrentAttempt = 0;
                    this.scope.$applyAsync();
                } else if (ratesTabSearchControl.searchTabDataCurrentAttempt == ratesTabSearchControl.searchTabDataMaxAttempt) {
                    ratesTabSearchControl.isSearchingTabDataList = false;
                    this.scope.$applyAsync();
                    const msgError = this.getTranslate('GENERAL.LIMIT_ATTEMPTS');
                    return this.notifyError(msgError)
                } else {
                    ratesTabSearchControl.isSearchingTabDataList = true;
                    ratesTabSearchControl.searchTabDataCurrentAttempt++;
                    ratesTabSearchControl.searchTabDataListCount = 0;
                    this.scope.$applyAsync();
                    this.timeout(() => { this.initCountGetRatesTabDataList() }, 1000);
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async chargeNameChange(optionChargeName: ISelectorModel): Promise<void> {
        if (optionChargeName) {
            await this.getApplicationListByChargeNameId(optionChargeName);
            if (this.scope.model.DETAILS_TAB && this.scope.model.DETAILS_TAB.IS_INSURANCE)
                this.scope.model.DETAILS_TAB.SECURE.ID_CHARGE_NAME_EXHIBITION = parseInt(optionChargeName.ID)
        }
    }

    private async getAndUpdateChargesList(): Promise<void> {
        try {
            const chargesTabData = this.scope.model.CHARGES_TAB;
            const chargesTabSearchControl = this.scope.chargesTabSearchControl;
            chargesTabSearchControl.isSearchTabDataListCanceled = false;
            chargesTabSearchControl.searchTabDataError = null;
            chargesTabData.INDICATORS = [];
            chargesTabData.FREIGHT_DOMESTIC = { ADDED_CHARGES: [], DELETED_CHARGES: [], MAINTAINED_CHARGES: [] };
            chargesTabData.LOCAL = { ADDED_CHARGES: [], DELETED_CHARGES: [], MAINTAINED_CHARGES: [] };
            chargesTabData.INSURANCE = { ADDED_CHARGES: [], DELETED_CHARGES: [], MAINTAINED_CHARGES: [] };
            chargesTabData.PROFIT_SHARE = { ADDED_CHARGES: [], DELETED_CHARGES: [], MAINTAINED_CHARGES: [] };
            chargesTabData.MANUAL_CHARGES = [];
            if (!chargesTabSearchControl.isSearchTabDataListCanceled) chargesTabSearchControl.searchTabDataCurrentAttempt = 0;
            const selectedOfferOption = this.scope.offerOptions.find(offerOption => offerOption.SELECTED);
            const requestFilters: ICompareChargesTabRequest = { ID_OFFER_WIZARD_FILTER: this.scope.model._id, ID_OFFER_OPTION_PAYMENT: selectedOfferOption._id, KEEP_MANUAL_CHARGES: chargesTabData.KEEP_MANUAL_CHARGES, KEEP_SELLING_RATES: chargesTabData.KEEP_SELLING_RATES };
            const resultOperation: ICompareChargesTabResponse = await this.restService.newObjectPromise(`${this.quotationCombinedUrl}/newAlternative/charges`, requestFilters, 30000, false);
            if (resultOperation) {
                if (resultOperation.SHOW) {
                    if (resultOperation.CHARGES && resultOperation.CHARGES.length) {
                        const isFirstEntrance = chargesTabData.KEEP_SELLING_RATES == null && chargesTabData.KEEP_MANUAL_CHARGES == null
                        if (isFirstEntrance) {
                            chargesTabData.KEEP_SELLING_RATES = false;
                        }
                        chargesTabData.CAN_KEEP_SELLING_RATES = resultOperation.CHARGES[0].CAN_KEEP_SELLING_RATES;
                        chargesTabData.CAN_KEEP_MANUAL_CHARGES = resultOperation.CHARGES[0].CAN_KEEP_MANUAL_CHARGES;
                        chargesTabData.ID_OFFER_OPTION_PAYMENT = resultOperation.CHARGES[0].ID_OFFER_OPTION_PAYMENT;
                        for (const charge of resultOperation.CHARGES) {
                            chargesTabData.CAN_KEEP_SELLING_RATES = charge.CAN_KEEP_SELLING_RATES;
                            if (charge.NEW_CHARGES && charge.CURRENT_CHARGES) {
                                // Indicators
                                if (charge.NEW_CHARGES.INDICATORS_CHARGES && charge.CURRENT_CHARGES.INDICATORS_CHARGES) {
                                    chargesTabData.INDICATORS.push({ IS_NEW: true, ...charge.NEW_CHARGES.INDICATORS_CHARGES });
                                    chargesTabData.INDICATORS.push({ IS_NEW: false, ...charge.CURRENT_CHARGES.INDICATORS_CHARGES });
                                }
                                // Freight Charges
                                if (charge.NEW_CHARGES.FREIGHT_CHARGES && charge.CURRENT_CHARGES.FREIGHT_CHARGES) {
                                    // Maintained
                                    if (charge.NEW_CHARGES.FREIGHT_CHARGES.MAINTAINED_CHARGES && charge.NEW_CHARGES.FREIGHT_CHARGES.MAINTAINED_CHARGES.length) {
                                        for (let index = 0; index < charge.NEW_CHARGES.FREIGHT_CHARGES.MAINTAINED_CHARGES.length; index++) {
                                            const newMaintainedCharge = charge.NEW_CHARGES.FREIGHT_CHARGES.MAINTAINED_CHARGES[index];
                                            const currentMaintainedCharge = charge.CURRENT_CHARGES.FREIGHT_CHARGES.MAINTAINED_CHARGES[index];
                                            chargesTabData.FREIGHT_DOMESTIC.MAINTAINED_CHARGES.push({ TYPE: "Freight", CHARGE_NAME: newMaintainedCharge.CHARGE_NAME, CHARGE: newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newMaintainedCharge.RECEIVING_CHARGES });
                                            chargesTabData.FREIGHT_DOMESTIC.MAINTAINED_CHARGES.push({ TYPE: "Freight", CHARGE_NAME: currentMaintainedCharge.CHARGE_NAME, CHARGE: currentMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentMaintainedCharge.RECEIVING_CHARGES });
                                        }
                                    }
                                    // Added
                                    if (charge.NEW_CHARGES.FREIGHT_CHARGES.ADDED_CHARGES && charge.NEW_CHARGES.FREIGHT_CHARGES.ADDED_CHARGES.length) {
                                        for (const newAddedCharge of charge.NEW_CHARGES.FREIGHT_CHARGES.ADDED_CHARGES) {
                                            chargesTabData.FREIGHT_DOMESTIC.ADDED_CHARGES.push({ TYPE: "Freight", CHARGE_NAME: newAddedCharge.CHARGE_NAME, CHARGE: newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newAddedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newAddedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newAddedCharge.RECEIVING_CHARGES });
                                        }
                                    }
                                    // Deleted
                                    if (charge.CURRENT_CHARGES.FREIGHT_CHARGES.DELETED_CHARGES && charge.CURRENT_CHARGES.FREIGHT_CHARGES.DELETED_CHARGES.length) {
                                        for (const currentDeletedCharge of charge.CURRENT_CHARGES.FREIGHT_CHARGES.DELETED_CHARGES) {
                                            chargesTabData.FREIGHT_DOMESTIC.DELETED_CHARGES.push({ TYPE: "Freight", CHARGE_NAME: currentDeletedCharge.CHARGE_NAME, CHARGE: currentDeletedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentDeletedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentDeletedCharge.RECEIVING_CHARGES });
                                        }
                                    }
                                }
                                // Domestic Origin Charges
                                if (charge.NEW_CHARGES.DOMESTIC_ORIGIN_CHARGES && charge.CURRENT_CHARGES.DOMESTIC_ORIGIN_CHARGES) {
                                    // Maintained
                                    if (charge.NEW_CHARGES.DOMESTIC_ORIGIN_CHARGES.MAINTAINED_CHARGES && charge.NEW_CHARGES.DOMESTIC_ORIGIN_CHARGES.MAINTAINED_CHARGES.length) {
                                        for (let index = 0; index < charge.NEW_CHARGES.DOMESTIC_ORIGIN_CHARGES.MAINTAINED_CHARGES.length; index++) {
                                            const newMaintainedCharge = charge.NEW_CHARGES.DOMESTIC_ORIGIN_CHARGES.MAINTAINED_CHARGES[index];
                                            const currentMaintainedCharge = charge.CURRENT_CHARGES.DOMESTIC_ORIGIN_CHARGES.MAINTAINED_CHARGES[index];
                                            chargesTabData.FREIGHT_DOMESTIC.MAINTAINED_CHARGES.push({ TYPE: "DO", CHARGE_NAME: newMaintainedCharge.CHARGE_NAME, CHARGE: newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newMaintainedCharge.RECEIVING_CHARGES });
                                            chargesTabData.FREIGHT_DOMESTIC.MAINTAINED_CHARGES.push({ TYPE: "DO", CHARGE_NAME: currentMaintainedCharge.CHARGE_NAME, CHARGE: currentMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentMaintainedCharge.RECEIVING_CHARGES });
                                        }
                                    }
                                    // Added
                                    if (charge.NEW_CHARGES.DOMESTIC_ORIGIN_CHARGES.ADDED_CHARGES && charge.NEW_CHARGES.DOMESTIC_ORIGIN_CHARGES.ADDED_CHARGES.length) {
                                        for (const newAddedCharge of charge.NEW_CHARGES.DOMESTIC_ORIGIN_CHARGES.ADDED_CHARGES) {
                                            chargesTabData.FREIGHT_DOMESTIC.ADDED_CHARGES.push({ TYPE: "DO", CHARGE_NAME: newAddedCharge.CHARGE_NAME, CHARGE: newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newAddedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newAddedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newAddedCharge.RECEIVING_CHARGES });
                                        }
                                    }
                                    // Deleted
                                    if (charge.CURRENT_CHARGES.DOMESTIC_ORIGIN_CHARGES.DELETED_CHARGES && charge.CURRENT_CHARGES.DOMESTIC_ORIGIN_CHARGES.DELETED_CHARGES.length) {
                                        for (const currentDeletedCharge of charge.CURRENT_CHARGES.DOMESTIC_ORIGIN_CHARGES.DELETED_CHARGES) {
                                            chargesTabData.FREIGHT_DOMESTIC.DELETED_CHARGES.push({ TYPE: "DO", CHARGE_NAME: currentDeletedCharge.CHARGE_NAME, CHARGE: currentDeletedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentDeletedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentDeletedCharge.RECEIVING_CHARGES });
                                        }
                                    }
                                }
                                // Domestic Destination Charges
                                if (charge.NEW_CHARGES.DOMESTIC_DESTINATION_CHARGES && charge.CURRENT_CHARGES.DOMESTIC_DESTINATION_CHARGES) {
                                    // Maintained
                                    if (charge.NEW_CHARGES.DOMESTIC_DESTINATION_CHARGES.MAINTAINED_CHARGES && charge.NEW_CHARGES.DOMESTIC_DESTINATION_CHARGES.MAINTAINED_CHARGES.length) {
                                        for (let index = 0; index < charge.NEW_CHARGES.DOMESTIC_DESTINATION_CHARGES.MAINTAINED_CHARGES.length; index++) {
                                            const newMaintainedCharge = charge.NEW_CHARGES.DOMESTIC_DESTINATION_CHARGES.MAINTAINED_CHARGES[index];
                                            const currentMaintainedCharge = charge.CURRENT_CHARGES.DOMESTIC_DESTINATION_CHARGES.MAINTAINED_CHARGES[index];
                                            chargesTabData.FREIGHT_DOMESTIC.MAINTAINED_CHARGES.push({ TYPE: "DD", CHARGE_NAME: newMaintainedCharge.CHARGE_NAME, CHARGE: newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newMaintainedCharge.RECEIVING_CHARGES });
                                            chargesTabData.FREIGHT_DOMESTIC.MAINTAINED_CHARGES.push({ TYPE: "DD", CHARGE_NAME: currentMaintainedCharge.CHARGE_NAME, CHARGE: currentMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentMaintainedCharge.RECEIVING_CHARGES });
                                        }
                                    }
                                    // Added
                                    if (charge.NEW_CHARGES.DOMESTIC_DESTINATION_CHARGES.ADDED_CHARGES && charge.NEW_CHARGES.DOMESTIC_DESTINATION_CHARGES.ADDED_CHARGES.length) {
                                        for (const newAddedCharge of charge.NEW_CHARGES.DOMESTIC_DESTINATION_CHARGES.ADDED_CHARGES) {
                                            chargesTabData.FREIGHT_DOMESTIC.ADDED_CHARGES.push({ TYPE: "DD", CHARGE_NAME: newAddedCharge.CHARGE_NAME, CHARGE: newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newAddedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newAddedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newAddedCharge.RECEIVING_CHARGES });
                                        }
                                    }
                                    // Deleted
                                    if (charge.CURRENT_CHARGES.DOMESTIC_DESTINATION_CHARGES.DELETED_CHARGES && charge.CURRENT_CHARGES.DOMESTIC_DESTINATION_CHARGES.DELETED_CHARGES.length) {
                                        for (const currentDeletedCharge of charge.CURRENT_CHARGES.DOMESTIC_DESTINATION_CHARGES.DELETED_CHARGES) {
                                            chargesTabData.FREIGHT_DOMESTIC.DELETED_CHARGES.push({ TYPE: "DD", CHARGE_NAME: currentDeletedCharge.CHARGE_NAME, CHARGE: currentDeletedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentDeletedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentDeletedCharge.RECEIVING_CHARGES });
                                        }
                                    }
                                }
                                // Local Origin Charges
                                if (charge.NEW_CHARGES.LOCAL_ORIGIN_CHARGES && charge.CURRENT_CHARGES.LOCAL_ORIGIN_CHARGES) {
                                    // Maintained
                                    if (charge.NEW_CHARGES.LOCAL_ORIGIN_CHARGES.MAINTAINED_CHARGES && charge.NEW_CHARGES.LOCAL_ORIGIN_CHARGES.MAINTAINED_CHARGES.length) {
                                        for (let index = 0; index < charge.NEW_CHARGES.LOCAL_ORIGIN_CHARGES.MAINTAINED_CHARGES.length; index++) {
                                            const newMaintainedCharge = charge.NEW_CHARGES.LOCAL_ORIGIN_CHARGES.MAINTAINED_CHARGES[index];
                                            const currentMaintainedCharge = charge.CURRENT_CHARGES.LOCAL_ORIGIN_CHARGES.MAINTAINED_CHARGES[index];
                                            chargesTabData.LOCAL.MAINTAINED_CHARGES.push({ TYPE: "Origin", CHARGE_NAME: newMaintainedCharge.CHARGE_NAME, CHARGE: newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newMaintainedCharge.RECEIVING_CHARGES });
                                            chargesTabData.LOCAL.MAINTAINED_CHARGES.push({ TYPE: "Origin", CHARGE_NAME: currentMaintainedCharge.CHARGE_NAME, CHARGE: currentMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentMaintainedCharge.RECEIVING_CHARGES });
                                        }
                                    }
                                    // Added
                                    if (charge.NEW_CHARGES.LOCAL_ORIGIN_CHARGES.ADDED_CHARGES && charge.NEW_CHARGES.LOCAL_ORIGIN_CHARGES.ADDED_CHARGES.length) {
                                        for (const newAddedCharge of charge.NEW_CHARGES.LOCAL_ORIGIN_CHARGES.ADDED_CHARGES) {
                                            chargesTabData.LOCAL.ADDED_CHARGES.push({ TYPE: "Origin", CHARGE_NAME: newAddedCharge.CHARGE_NAME, CHARGE: newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newAddedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newAddedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newAddedCharge.RECEIVING_CHARGES });
                                        }
                                    }
                                    // Deleted
                                    if (charge.CURRENT_CHARGES.LOCAL_ORIGIN_CHARGES.DELETED_CHARGES && charge.CURRENT_CHARGES.LOCAL_ORIGIN_CHARGES.DELETED_CHARGES.length) {
                                        for (const currentDeletedCharge of charge.CURRENT_CHARGES.LOCAL_ORIGIN_CHARGES.DELETED_CHARGES) {
                                            chargesTabData.LOCAL.DELETED_CHARGES.push({ TYPE: "Origin", CHARGE_NAME: currentDeletedCharge.CHARGE_NAME, CHARGE: currentDeletedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentDeletedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentDeletedCharge.RECEIVING_CHARGES });
                                        }
                                    }
                                }
                                // Local Destination Charges
                                if (charge.NEW_CHARGES.LOCAL_DESTINATION_CHARGES && charge.CURRENT_CHARGES.LOCAL_DESTINATION_CHARGES) {
                                    // Maintained
                                    if (charge.NEW_CHARGES.LOCAL_DESTINATION_CHARGES.MAINTAINED_CHARGES && charge.NEW_CHARGES.LOCAL_DESTINATION_CHARGES.MAINTAINED_CHARGES.length) {
                                        for (let index = 0; index < charge.NEW_CHARGES.LOCAL_DESTINATION_CHARGES.MAINTAINED_CHARGES.length; index++) {
                                            const newMaintainedCharge = charge.NEW_CHARGES.LOCAL_DESTINATION_CHARGES.MAINTAINED_CHARGES[index];
                                            const currentMaintainedCharge = charge.CURRENT_CHARGES.LOCAL_DESTINATION_CHARGES.MAINTAINED_CHARGES[index];
                                            chargesTabData.LOCAL.MAINTAINED_CHARGES.push({ TYPE: "Destination", CHARGE_NAME: newMaintainedCharge.CHARGE_NAME, CHARGE: newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newMaintainedCharge.RECEIVING_CHARGES });
                                            chargesTabData.LOCAL.MAINTAINED_CHARGES.push({ TYPE: "Destination", CHARGE_NAME: currentMaintainedCharge.CHARGE_NAME, CHARGE: currentMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentMaintainedCharge.RECEIVING_CHARGES });
                                        }
                                    }
                                    // Added
                                    if (charge.NEW_CHARGES.LOCAL_DESTINATION_CHARGES.ADDED_CHARGES && charge.NEW_CHARGES.LOCAL_DESTINATION_CHARGES.ADDED_CHARGES.length) {
                                        for (const newAddedCharge of charge.NEW_CHARGES.LOCAL_DESTINATION_CHARGES.ADDED_CHARGES) {
                                            chargesTabData.LOCAL.ADDED_CHARGES.push({ TYPE: "Destination", CHARGE_NAME: newAddedCharge.CHARGE_NAME, CHARGE: newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newAddedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newAddedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newAddedCharge.RECEIVING_CHARGES });
                                        }
                                    }
                                    // Deleted
                                    if (charge.CURRENT_CHARGES.LOCAL_DESTINATION_CHARGES.DELETED_CHARGES && charge.CURRENT_CHARGES.LOCAL_DESTINATION_CHARGES.DELETED_CHARGES.length) {
                                        for (const currentDeletedCharge of charge.CURRENT_CHARGES.LOCAL_DESTINATION_CHARGES.DELETED_CHARGES) {
                                            chargesTabData.LOCAL.DELETED_CHARGES.push({ TYPE: "Destination", CHARGE_NAME: currentDeletedCharge.CHARGE_NAME, CHARGE: currentDeletedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentDeletedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentDeletedCharge.RECEIVING_CHARGES });
                                        }
                                    }
                                }
                                // Insurance
                                if (charge.NEW_CHARGES.INSURANCE_CHARGES && charge.CURRENT_CHARGES.INSURANCE_CHARGES) {
                                    // Maintained
                                    if (charge.NEW_CHARGES.INSURANCE_CHARGES.MAINTAINED_CHARGES && charge.NEW_CHARGES.INSURANCE_CHARGES.MAINTAINED_CHARGES.length) {
                                        for (let index = 0; index < charge.NEW_CHARGES.INSURANCE_CHARGES.MAINTAINED_CHARGES.length; index++) {
                                            const newMaintainedCharge = charge.NEW_CHARGES.INSURANCE_CHARGES.MAINTAINED_CHARGES[index];
                                            const currentMaintainedCharge = charge.CURRENT_CHARGES.INSURANCE_CHARGES.MAINTAINED_CHARGES[index];
                                            chargesTabData.INSURANCE.MAINTAINED_CHARGES.push({ TYPE: "Insurance", CHARGE_NAME: newMaintainedCharge.CHARGE_NAME, CHARGE: newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newMaintainedCharge.RECEIVING_CHARGES });
                                            chargesTabData.INSURANCE.MAINTAINED_CHARGES.push({ TYPE: "Insurance", CHARGE_NAME: currentMaintainedCharge.CHARGE_NAME, CHARGE: currentMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentMaintainedCharge.RECEIVING_CHARGES });
                                        }
                                    }
                                    // Added
                                    if (charge.NEW_CHARGES.INSURANCE_CHARGES.ADDED_CHARGES && charge.NEW_CHARGES.INSURANCE_CHARGES.ADDED_CHARGES.length) {
                                        for (const newAddedCharge of charge.NEW_CHARGES.INSURANCE_CHARGES.ADDED_CHARGES) {
                                            chargesTabData.INSURANCE.ADDED_CHARGES.push({ TYPE: "Insurance", CHARGE_NAME: newAddedCharge.CHARGE_NAME, CHARGE: newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newAddedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newAddedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newAddedCharge.RECEIVING_CHARGES });
                                        }
                                    }
                                    // Deleted
                                    if (charge.CURRENT_CHARGES.INSURANCE_CHARGES.DELETED_CHARGES && charge.CURRENT_CHARGES.INSURANCE_CHARGES.DELETED_CHARGES.length) {
                                        for (const currentDeletedCharge of charge.CURRENT_CHARGES.INSURANCE_CHARGES.DELETED_CHARGES) {
                                            chargesTabData.INSURANCE.DELETED_CHARGES.push({ TYPE: "Insurance", CHARGE_NAME: currentDeletedCharge.CHARGE_NAME, CHARGE: currentDeletedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentDeletedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentDeletedCharge.RECEIVING_CHARGES });
                                        }
                                    }
                                }
                                // Profit Share
                                if (charge.NEW_CHARGES.PROFIT_SHARE_CHARGES && charge.CURRENT_CHARGES.PROFIT_SHARE_CHARGES) {
                                    // Maintained
                                    if (charge.NEW_CHARGES.PROFIT_SHARE_CHARGES.MAINTAINED_CHARGES && charge.NEW_CHARGES.PROFIT_SHARE_CHARGES.MAINTAINED_CHARGES.length) {
                                        for (let index = 0; index < charge.NEW_CHARGES.PROFIT_SHARE_CHARGES.MAINTAINED_CHARGES.length; index++) {
                                            const newMaintainedCharge = charge.NEW_CHARGES.PROFIT_SHARE_CHARGES.MAINTAINED_CHARGES[index];
                                            const currentMaintainedCharge = charge.CURRENT_CHARGES.PROFIT_SHARE_CHARGES.MAINTAINED_CHARGES[index];
                                            chargesTabData.PROFIT_SHARE.MAINTAINED_CHARGES.push({ TYPE: "Profit Share", CHARGE_NAME: newMaintainedCharge.CHARGE_NAME, CHARGE: newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newMaintainedCharge.RECEIVING_CHARGES });
                                            chargesTabData.PROFIT_SHARE.MAINTAINED_CHARGES.push({ TYPE: "Profit Share", CHARGE_NAME: currentMaintainedCharge.CHARGE_NAME, CHARGE: currentMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentMaintainedCharge.RECEIVING_CHARGES });
                                        }
                                    }
                                    // Added
                                    if (charge.NEW_CHARGES.PROFIT_SHARE_CHARGES.ADDED_CHARGES && charge.NEW_CHARGES.PROFIT_SHARE_CHARGES.ADDED_CHARGES.length) {
                                        for (const newAddedCharge of charge.NEW_CHARGES.PROFIT_SHARE_CHARGES.ADDED_CHARGES) {
                                            chargesTabData.PROFIT_SHARE.ADDED_CHARGES.push({ TYPE: "Profit Share", CHARGE_NAME: newAddedCharge.CHARGE_NAME, CHARGE: newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newAddedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newAddedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newAddedCharge.RECEIVING_CHARGES });
                                        }
                                    }
                                    // Deleted
                                    if (charge.CURRENT_CHARGES.PROFIT_SHARE_CHARGES.DELETED_CHARGES && charge.CURRENT_CHARGES.PROFIT_SHARE_CHARGES.DELETED_CHARGES.length) {
                                        for (const currentDeletedCharge of charge.CURRENT_CHARGES.PROFIT_SHARE_CHARGES.DELETED_CHARGES) {
                                            chargesTabData.PROFIT_SHARE.DELETED_CHARGES.push({ TYPE: "Profit Share", CHARGE_NAME: currentDeletedCharge.CHARGE_NAME, CHARGE: currentDeletedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentDeletedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentDeletedCharge.RECEIVING_CHARGES });
                                        }
                                    }
                                }
                                // Manual Charges
                                if (charge.CURRENT_CHARGES && charge.CURRENT_CHARGES.MANUAL_CHARGES && charge.CURRENT_CHARGES.MANUAL_CHARGES.length) {
                                    for (let index = 0; index < charge.CURRENT_CHARGES.MANUAL_CHARGES.length; index++) {
                                        const currentManualCharge = charge.CURRENT_CHARGES.MANUAL_CHARGES[index];
                                        chargesTabData.MANUAL_CHARGES.push({ IS_NEW: false, CHARGE_NAME: currentManualCharge.CHARGE_NAME, PAYMENT_CHARGES: currentManualCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentManualCharge.RECEIVING_CHARGES });
                                        const newManualCharge = charge.NEW_CHARGES && charge.NEW_CHARGES.MANUAL_CHARGES ? charge.NEW_CHARGES.MANUAL_CHARGES[index] : null;
                                        if (newManualCharge) chargesTabData.MANUAL_CHARGES.push({ IS_NEW: true, CHARGE_NAME: newManualCharge.CHARGE_NAME, PAYMENT_CHARGES: newManualCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newManualCharge.RECEIVING_CHARGES });
                                    }
                                }
                                if (isFirstEntrance) chargesTabData.KEEP_MANUAL_CHARGES = chargesTabData.MANUAL_CHARGES && chargesTabData.MANUAL_CHARGES.length > 0;
                            }
                        }
                    }
                    chargesTabSearchControl.searchTabDataError = resultOperation.PROCESS && resultOperation.PROCESS.length ? resultOperation.PROCESS.filter(process => process.ERROR && process.ERROR.length).map(process => process.ERROR) : null;
                    chargesTabSearchControl.isSearchingTabDataList = false;
                    chargesTabSearchControl.searchTabDataCurrentAttempt = 0;
                    this.scope.$applyAsync();
                } else if (chargesTabSearchControl.searchTabDataCurrentAttempt == chargesTabSearchControl.searchTabDataMaxAttempt) {
                    chargesTabSearchControl.isSearchingTabDataList = false;
                    this.scope.$applyAsync();
                    return this.notifyError("Limit number of attempts has been reached, contact an administrator or try again.")
                } else {
                    chargesTabSearchControl.isSearchingTabDataList = true;
                    chargesTabSearchControl.searchTabDataCurrentAttempt++;
                    chargesTabSearchControl.searchTabDataListCount = 0;
                    this.scope.$applyAsync();
                    this.timeout(() => { this.initCountGetChargesTabDataList() }, 1000);
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private updateOrdinationList(): void {
        const typeCargo = this.scope.model.OFFER_TAB ? this.scope.model.OFFER_TAB.TYPE_CARGO : null;
        if (!typeCargo) throw Error('type cargo is null');
        let contributionCode = "";
        if (typeCargo.ID == ECargoTypeId.FCL) contributionCode = "EGP_TOTAL_TEUS";
        else if (typeCargo.ID == ECargoTypeId.AIR) contributionCode = "EGP_TOTAL_WEIGHT";
        else if (typeCargo.ID == ECargoTypeId.ROAD) contributionCode = "EGP_TOTAL_VEHICLES";
        else if (typeCargo.ID == ECargoTypeId.BREAK_BULK || typeCargo.ID == ECargoTypeId.LCL || typeCargo.ID == ECargoTypeId.RO_RO) contributionCode = "EGP_TOTAL_FILE";
        this.scope.ordinationList = [{ ID: "1", NAME: "GP Estimated", CODE: "ESTIMATED_GROSS_PROFIT" }, { ID: "2", NAME: "Contribution", CODE: contributionCode }, { ID: "3", NAME: "Total Payment", CODE: "TOTAL_PAYMENT" }, { ID: "4", NAME: "Total Receiving", CODE: "TOTAL_RECEIVING" }];
    }

    private async getAndUpdateOptionList(): Promise<void> {
        try {
            const alternativesTabSearchControl = this.scope.alternativesTabSearchControl;
            alternativesTabSearchControl.isSearchTabDataListCanceled = false;
            this.scope.optionRoutesError = null;
            this.scope.offerOptions = null;
            if (!alternativesTabSearchControl.isSearchingTabDataList) alternativesTabSearchControl.searchTabDataCurrentAttempt = 0;
            this.scope.offerOptions = [];
            const resultOperation: IOfferOptionPaymentListResult = await this.restService.newObjectPromise(`${this.quotationCombinedUrl}/offerOptionPayment/list`, { id: this.scope.model._id }, 30000, false);
            if (resultOperation) {
                if (resultOperation.SHOW) {
                    const validRoutes: IOfferOptionPaymentRoute[] = [];
                    const invalidRoutes: IOfferOptionPaymentRoute[] = [];
                    if (resultOperation.ROUTES && resultOperation.ROUTES.length) {
                        for (const route of resultOperation.ROUTES) {
                            const validOfferOptions: IOfferOption[] = [];
                            const invalidOfferOptions: IOfferOption[] = [];
                            if (route.OFFER_OPTION && route.OFFER_OPTION.length) {
                                for (const offerOption of route.OFFER_OPTION) {
                                    if (offerOption.IS_VALID) validOfferOptions.push(offerOption);
                                    else invalidOfferOptions.push(offerOption);
                                }
                            }
                            validRoutes.push({ PROCESS_STATUS: route.PROCESS_STATUS, MOVE_TYPE: route.MOVE_TYPE, ORIGIN: route.ORIGIN, DESTINATION: route.DESTINATION, OFFER_OPTION: validOfferOptions });
                            invalidRoutes.push({ PROCESS_STATUS: route.PROCESS_STATUS, MOVE_TYPE: route.MOVE_TYPE, ORIGIN: route.ORIGIN, DESTINATION: route.DESTINATION, OFFER_OPTION: invalidOfferOptions });
                        }
                    }
                    this.scope.optionRoutesError = validRoutes && validRoutes.length > 0 ? validRoutes.filter(route => route.PROCESS_STATUS && route.PROCESS_STATUS.ERROR) : null;
                    this.scope.offerOptions = validRoutes && validRoutes.length ? this.buildOfferOptions(validRoutes.filter(route => !route.PROCESS_STATUS || (route.PROCESS_STATUS && !route.PROCESS_STATUS.ERROR))) : null;
                    this.scope.invalidOfferOptions = invalidRoutes && invalidRoutes.length ? this.buildInvalidOfferOptions(invalidRoutes.filter(route => !route.PROCESS_STATUS || (route.PROCESS_STATUS && !route.PROCESS_STATUS.ERROR))) : null;
                    this.scope.hasAnyOption = this.scope.offerOptions && this.scope.offerOptions.length > 0;
                    alternativesTabSearchControl.isSearchingTabDataList = false;
                    alternativesTabSearchControl.searchTabDataCurrentAttempt = 0;
                    this.scope.$applyAsync();
                } else if (alternativesTabSearchControl.searchTabDataCurrentAttempt == alternativesTabSearchControl.searchTabDataMaxAttempt) {
                    alternativesTabSearchControl.isSearchingTabDataList = false;
                    this.scope.$applyAsync();
                    return this.notifyError("Limit number of attempts has been reached, contact an administrator or try again.")
                } else {
                    alternativesTabSearchControl.isSearchingTabDataList = true;
                    alternativesTabSearchControl.searchTabDataCurrentAttempt++;
                    alternativesTabSearchControl.searchTabDataListCount = 0;
                    this.scope.$applyAsync();
                    this.timeout(() => { this.initCountGetAlternativesTabDataList() }, 1000);
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async updateOfferWizardFilter(): Promise<boolean> {
        let success = true;
        try {
            this.block();
            const formatedData: IOfferWizardFilter = {
                _id: this.scope.model._id,
                OFFER_TAB: this.scope.model.OFFER_TAB,
                ROUTE_TAB: this.scope.model.ROUTES_TAB,
                CARGO_TAB: this.scope.model.CARGO_TAB,
                SPEC_TAB: this.scope.model.DETAILS_TAB,
                ID_OPPORTUNITY: null,
                REPROCESS: null,
                COMPLETED: null,
                CREATED_AT: null,
                CREATED_BY: null,
                MODIFIED_AT: null,
                MODIFIED_BY: null
            };
            const formatedOldData: IOfferWizardFilter = this.scope.scopeBeforeSave ? {
                _id: this.scope.scopeBeforeSave._id,
                OFFER_TAB: this.scope.scopeBeforeSave.OFFER_TAB,
                ROUTE_TAB: this.scope.scopeBeforeSave.ROUTES_TAB,
                CARGO_TAB: this.scope.scopeBeforeSave.CARGO_TAB,
                SPEC_TAB: this.scope.scopeBeforeSave.DETAILS_TAB,
                ID_OPPORTUNITY: null,
                REPROCESS: null,
                COMPLETED: null,
                CREATED_AT: null,
                CREATED_BY: null,
                MODIFIED_AT: null,
                MODIFIED_BY: null
            } : null;
            if (formatedData.CARGO_TAB && formatedData.CARGO_TAB.AIR) {
                this.calculateAirHigherAmount();
                if (formatedData.CARGO_TAB.AIR.APPLICATION) {
                    formatedData.CARGO_TAB.AIR.APPLICATION.ORDER = null;
                    formatedData.CARGO_TAB.AIR.ORIGINAL_AIR_CARGO.APPLICATION.ORDER = null;
                }
            }
            if (!formatedData.OFFER_TAB.OUTER_CELL) formatedData.OFFER_TAB.OUTER_CELL = null;
            if (formatedData.SPEC_TAB.SECURE && !formatedData.SPEC_TAB.SECURE.ID_CHARGE_NAME_EXHIBITION)
                formatedData.SPEC_TAB.SECURE = null;
            if (formatedData.ROUTE_TAB && formatedData.ROUTE_TAB.ROUTES) formatedData.ROUTE_TAB.ROUTES = formatedData.ROUTE_TAB.ROUTES.filter(route => route.SELECTED);
            if (!formatedData.OFFER_TAB.COMMODITY_SECTION) formatedData.OFFER_TAB.COMMODITY_SECTION = null;
            const resultOperation = await this.restService.newObjectPromise(`${this.quotationCombinedUrl}/newAlternative/filter/update`, { data: formatedData, oldData: formatedOldData }, 30000, false);
            if (!resultOperation) {
                success = false;
                const msgError = this.getTranslate('GENERAL.ROUTINE_ERROR');
                throw this.notifyError(msgError);
            } else this.scope.scopeBeforeSave = angular.copy(this.scope.model);
        } catch (ex) {
            success = false;
            this.handleError(ex);
        } finally {
            this.unblock();
            return success;
        }
    }

    private calculateAirHigherAmount(): void {
        this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT_VALID = this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT > this.scope.model.CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT ? this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT : this.scope.model.CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT;
    }

    private buildOfferOptions(optionRoutes: IOfferOptionPaymentRoute[]): IOfferOptionAux[] {
        const offerOptions: IOfferOptionAux[] = [];
        try {
            if (optionRoutes && optionRoutes.length > 0) {
                for (let routeIndex = 0; routeIndex < optionRoutes.length; routeIndex++) {
                    const optionRoute = optionRoutes[routeIndex];
                    if (optionRoute && optionRoute.OFFER_OPTION && optionRoute.OFFER_OPTION.length > 0) {
                        for (let offerIndex = 0; offerIndex < optionRoute.OFFER_OPTION.length; offerIndex++) {
                            const offerOption = optionRoute.OFFER_OPTION[offerIndex];
                            const newOfferOption: IOfferOptionAux = angular.copy(offerOption);
                            const routes: IOfferOptionRoutingPoint[] = OfferNewAlternativeHelper.getOfferOptionRoutes(offerOption);
                            newOfferOption.SELECTED = optionRoute.OFFER_OPTION.length == 1;
                            newOfferOption.SHOW_DETAILS = newOfferOption.SELECTED;
                            newOfferOption.TOOLTIP = OfferNewAlternativeHelper.buildTooltipRoutes(routes);
                            newOfferOption.MOVE_TYPE = optionRoute.MOVE_TYPE;
                            newOfferOption.ORIGIN = optionRoute.ORIGIN;
                            newOfferOption.DESTINATION = optionRoute.DESTINATION;
                            newOfferOption.PROCESS_STATUS = optionRoute.PROCESS_STATUS;
                            newOfferOption.OFFER_OPTION_RECEIVING = optionRoute.OFFER_OPTION[routeIndex].OFFER_OPTION_RECEIVING;
                            if (this.scope.selectedOptions)
                                if (!this.scope.selectedOptions.find(selected => selected._id == newOfferOption._id))
                                    newOfferOption.SELECTED = false;
                            offerOptions.push(newOfferOption);
                        }
                    }
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            return offerOptions;
        }
    }

    private buildInvalidOfferOptions(optionRoutes: IOfferOptionPaymentRoute[]): IOfferOptionInvalid[] {
        const offerOptions: IOfferOptionInvalid[] = [];
        try {
            if (optionRoutes && optionRoutes.length > 0) {
                for (let routeIndex = 0; routeIndex < optionRoutes.length; routeIndex++) {
                    const optionRoute = optionRoutes[routeIndex];
                    if (optionRoute && optionRoute.OFFER_OPTION && optionRoute.OFFER_OPTION.length > 0) {
                        for (let offerIndex = 0; offerIndex < optionRoute.OFFER_OPTION.length; offerIndex++) {
                            const offerOption = optionRoute.OFFER_OPTION[offerIndex];
                            offerOptions.push({
                                SHOW: false,
                                MOVE_TYPE: optionRoute.MOVE_TYPE,
                                PRODUCT: offerOption.PRODUCT,
                                PROVIDER: offerOption.PROVIDER,
                                ORIGIN: optionRoute.ORIGIN,
                                DESTINATION: optionRoute.DESTINATION,
                                ID_FREIGHT_ROUTES: offerOption.ID_FREIGHT_ROUTES,
                                ID_NEW_FREIGHT_CONTRACT: offerOption.ID_NEW_FREIGHT_CONTRACT,
                                TARIFF_FREIGHT_CONCATENATED: offerOption.TARIFF_FREIGHT_CONCATENATED,
                                VALIDATION_RESULT: offerOption.VALIDATION_RESULT,
                                CHARGES: null
                            });
                        }
                    }
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            return offerOptions;
        }
    }

    private cancelOfferOption() {
        this.scope.alternativesTabSearchControl.isSearchTabDataListCanceled = true;
        this.scope.alternativesTabSearchControl.isSearchingTabDataList = false;
        this.scope.alternativesTabSearchControl.searchTabDataCurrentAttempt = 0;
    }

    private cancelRatesSearch() {
        this.scope.ratesTabSearchControl.isSearchTabDataListCanceled = true;
        this.scope.ratesTabSearchControl.isSearchingTabDataList = false;
        this.scope.ratesTabSearchControl.searchTabDataCurrentAttempt = 0;
    }

    private cancelChargesSearch() {
        this.scope.chargesTabSearchControl.isSearchTabDataListCanceled = true;
        this.scope.chargesTabSearchControl.isSearchingTabDataList = false;
        this.scope.chargesTabSearchControl.searchTabDataCurrentAttempt = 0;
    }

    private async openRouteDetails(offerOption: IOfferOption): Promise<void> {
        try {
            const routes: IOfferOptionRoutingPoint[] = OfferNewAlternativeHelper.getOfferOptionRoutes(offerOption);
            const routeDetails: IRouteDetails[] = [];
            let lastRoute = null;

            for (let index = 0; index < routes.length; index++) {
                const route = routes[index];
                const property = route.EVENT.replace('/', '');
                let offerOptionRoutingPoint = null;
                let offerOptionReceivingRoutingPoint = null;
                let isInland: boolean = false;
                let isInlandReceiving: boolean = false;
                if (offerOption[property]) {
                    offerOptionRoutingPoint = offerOption[property].FREIGHT ? offerOption[property].FREIGHT : offerOption[property].INLAND ? offerOption[property].INLAND : null;
                    isInland = offerOption[property].INLAND ? true : false;
                }

                if (offerOption.OFFER_OPTION_RECEIVING && offerOption.OFFER_OPTION_RECEIVING[property]) {
                    offerOptionReceivingRoutingPoint = offerOption.OFFER_OPTION_RECEIVING[property].FREIGHT ? offerOption.OFFER_OPTION_RECEIVING[property].FREIGHT : offerOption.OFFER_OPTION_RECEIVING[property].INLAND ? offerOption.OFFER_OPTION_RECEIVING[property].INLAND : null;
                    isInlandReceiving = offerOption.OFFER_OPTION_RECEIVING[property] && offerOption.OFFER_OPTION_RECEIVING[property].INLAND ? true : false;
                }

                switch (route.TYPE) {
                    case ERouteEventType.TARIFF_FREIGHT:
                        let freightRoutingPoint = null;
                        let freightDestinationRoutingPoint = null;
                        let receivingRoutingPoint = null;

                        if (offerOption.PRODUCT.ID == EProductId.ROAD_EXPORT || offerOption.PRODUCT.ID == EProductId.ROAD_IMPORT || offerOption.PRODUCT.ID == EProductId.ROAD_NATIONAL) {
                            freightRoutingPoint = offerOption.PUP;
                            freightDestinationRoutingPoint = offerOption.PLD;
                        } else {
                            freightRoutingPoint = offerOption.POLAOL;
                            freightDestinationRoutingPoint = offerOption.PODAOD;
                            receivingRoutingPoint = offerOption.OFFER_OPTION_RECEIVING && offerOption.OFFER_OPTION_RECEIVING.POLAOL && offerOption.OFFER_OPTION_RECEIVING.POLAOL.FREIGHT ? offerOption.OFFER_OPTION_RECEIVING.POLAOL.FREIGHT : null;
                        }

                        routeDetails.push({
                            type: ERouteEventType.TARIFF_FREIGHT,
                            typeToShow: 'Frete',
                            showType: !lastRoute || (lastRoute && lastRoute.TYPE != route.TYPE),
                            typeRowspan: 1,
                            event: route.EVENT,
                            name: freightRoutingPoint.FREIGHT.ROUTING_POINT.DISPLAY_NAME + ' - ' + freightDestinationRoutingPoint.FREIGHT.ROUTING_POINT.DISPLAY_NAME,
                            tariffPayment: freightRoutingPoint.FREIGHT.FREIGHT_ROUTES.CONCATENATED,
                            tariffPaymentId: freightRoutingPoint.FREIGHT.FREIGHT_ROUTES.ID,
                            tariffContractPaymentId: freightRoutingPoint.FREIGHT.FREIGHT_ROUTES.ROUTE_CONTRACT.ID,
                            tariffReceiving: receivingRoutingPoint && receivingRoutingPoint.FREIGHT_ROUTES ? receivingRoutingPoint.FREIGHT_ROUTES.CONCATENATED : null,
                            tariffReceivingId: receivingRoutingPoint && receivingRoutingPoint.FREIGHT_ROUTES ? receivingRoutingPoint.FREIGHT_ROUTES.ID : null,
                            tariffContractReceivingId: receivingRoutingPoint && receivingRoutingPoint.FREIGHT_ROUTES ? receivingRoutingPoint.FREIGHT_ROUTES.ID : null,
                        });

                        lastRoute = route;
                        break;

                    case ERouteEventType.TARIFF_DOMESTIC_ORIGIN:
                        routeDetails.push({
                            type: ERouteEventType.TARIFF_DOMESTIC_ORIGIN,
                            typeToShow: 'Doméstico',
                            showType: !lastRoute || (lastRoute && lastRoute.TYPE != route.TYPE),
                            typeRowspan: 1,
                            event: route.EVENT,
                            name: route.DISPLAY_NAME,
                            tariffPayment: isInland && offerOptionRoutingPoint.ORIGIN ? offerOptionRoutingPoint.ORIGIN.INLAND_ROUTES.CONCATENATED : offerOptionRoutingPoint.FREIGHT ? offerOptionRoutingPoint.FREIGHT.FREIGHT_ROUTES.CONCATENATED : null,
                            tariffPaymentId: isInland && offerOptionRoutingPoint.ORIGIN ? offerOptionRoutingPoint.ORIGIN.INLAND_ROUTES.ID : offerOptionRoutingPoint.FREIGHT ? offerOptionRoutingPoint.FREIGHT.FREIGHT_ROUTES.ID : null,
                            tariffContractPaymentId: isInland && offerOptionRoutingPoint.ORIGIN ? offerOptionRoutingPoint.ORIGIN.INLAND_ROUTES.ROUTE_CONTRACT.ID : offerOptionRoutingPoint.FREIGHT ? offerOptionRoutingPoint.FREIGHT.FREIGHT_ROUTES.ROUTE_CONTRACT.ID : null,
                            tariffReceiving: offerOptionReceivingRoutingPoint && offerOptionReceivingRoutingPoint.ORIGIN ? isInlandReceiving ? offerOptionReceivingRoutingPoint.ORIGIN.INLAND_ROUTES.CONCATENATED : offerOptionReceivingRoutingPoint.FREIGHT ? offerOptionReceivingRoutingPoint.FREIGHT.FREIGHT_ROUTES.CONCATENATED : null : null,
                            tariffReceivingId: offerOptionReceivingRoutingPoint && offerOptionReceivingRoutingPoint.ORIGIN ? isInlandReceiving ? offerOptionReceivingRoutingPoint.ORIGIN.INLAND_ROUTES.ID : offerOptionReceivingRoutingPoint.FREIGHT ? offerOptionReceivingRoutingPoint.FREIGHT.FREIGHT_ROUTES.ID : null : null,
                            tariffContractReceivingId: offerOptionReceivingRoutingPoint && offerOptionReceivingRoutingPoint.ORIGIN ? isInlandReceiving ? offerOptionReceivingRoutingPoint.ORIGIN.INLAND_ROUTES.ROUTE_CONTRACT.ID : offerOptionReceivingRoutingPoint.FREIGHT ? offerOptionReceivingRoutingPoint.FREIGHT.FREIGHT_ROUTES.ROUTE_CONTRACT.ID : null : null
                        });
                        break;

                    case ERouteEventType.TARIFF_DOMESTIC_DESTINATION:
                        routeDetails.push({
                            type: ERouteEventType.TARIFF_DOMESTIC_DESTINATION,
                            typeToShow: 'Doméstico',
                            showType: !lastRoute || (lastRoute && lastRoute.TYPE != route.TYPE),
                            typeRowspan: 1,
                            event: route.EVENT,
                            name: route.DISPLAY_NAME,
                            tariffPayment: isInland && offerOptionRoutingPoint.DESTINATION ? offerOptionRoutingPoint.DESTINATION.INLAND_ROUTES.CONCATENATED : offerOptionRoutingPoint.FREIGHT ? offerOptionRoutingPoint.FREIGHT.FREIGHT_ROUTES.CONCATENATED : null,
                            tariffPaymentId: isInland && offerOptionRoutingPoint.DESTINATION ? offerOptionRoutingPoint.DESTINATION.INLAND_ROUTES.ID : offerOptionRoutingPoint.FREIGHT ? offerOptionRoutingPoint.FREIGHT.FREIGHT_ROUTES.ID : null,
                            tariffContractPaymentId: isInland && offerOptionRoutingPoint.DESTINATION ? offerOptionRoutingPoint.DESTINATION.INLAND_ROUTES.ROUTE_CONTRACT.ID : offerOptionRoutingPoint.FREIGHT ? offerOptionRoutingPoint.FREIGHT.FREIGHT_ROUTES.ROUTE_CONTRACT.ID : null,
                            tariffReceiving: offerOptionReceivingRoutingPoint && offerOptionReceivingRoutingPoint.DESTINATION ? isInlandReceiving ? offerOptionReceivingRoutingPoint.DESTINATION.INLAND_ROUTES.CONCATENATED : offerOptionReceivingRoutingPoint.FREIGHT ? offerOptionReceivingRoutingPoint.FREIGHT.FREIGHT_ROUTES.CONCATENATED : null : null,
                            tariffReceivingId: offerOptionReceivingRoutingPoint && offerOptionReceivingRoutingPoint.DESTINATION ? isInlandReceiving ? offerOptionReceivingRoutingPoint.DESTINATION.INLAND_ROUTES.ID : offerOptionReceivingRoutingPoint.FREIGHT ? offerOptionReceivingRoutingPoint.FREIGHT.FREIGHT_ROUTES.ID : null : null,
                            tariffContractReceivingId: offerOptionReceivingRoutingPoint && offerOptionReceivingRoutingPoint.DESTINATION ? isInlandReceiving ? offerOptionReceivingRoutingPoint.DESTINATION.INLAND_ROUTES.ROUTE_CONTRACT.ID : offerOptionReceivingRoutingPoint.FREIGHT ? offerOptionReceivingRoutingPoint.FREIGHT.FREIGHT_ROUTES.ROUTE_CONTRACT.ID : null : null
                        });
                        break;

                    case ERouteEventType.TARIFF_LOCAL_ORIGIN:
                        routeDetails.push({
                            type: ERouteEventType.TARIFF_LOCAL_ORIGIN,
                            typeToShow: 'Local',
                            showType: !lastRoute || (lastRoute && lastRoute.TYPE != route.TYPE),
                            typeRowspan: 1,
                            event: route.EVENT,
                            name: route.DISPLAY_NAME,
                            tariffPayment: offerOption[property].TARIFF_LOCAL_ORIGIN_PAYMENT_CONCATENATED,
                            tariffPaymentId: offerOption[property].ID_TARIFF_LOCAL_ORIGIN_PAYMENT,
                            tariffContractPaymentId: offerOption[property].ID_TARIFF_LOCAL_ORIGIN_PAYMENT,
                            tariffReceiving: offerOption[property] ? offerOption[property].TARIFF_LOCAL_ORIGIN_RECEIVING_CONCATENATED : null,
                            tariffReceivingId: offerOption[property] ? offerOption[property].ID_TARIFF_LOCAL_ORIGIN_RECEIVING : null,
                            tariffContractReceivingId: offerOption[property] ? offerOption[property].ID_TARIFF_LOCAL_ORIGIN_RECEIVING : null
                        });
                        break;

                    case ERouteEventType.TARIFF_LOCAL_DESTINATION:
                        routeDetails.push({
                            type: ERouteEventType.TARIFF_LOCAL_DESTINATION,
                            typeToShow: 'Local',
                            showType: !lastRoute || (lastRoute && lastRoute.TYPE != route.TYPE),
                            typeRowspan: 1,
                            event: route.EVENT,
                            name: route.DISPLAY_NAME,
                            tariffPayment: offerOption[property].TARIFF_LOCAL_DESTINATION_PAYMENT_CONCATENATED,
                            tariffPaymentId: offerOption[property].ID_TARIFF_LOCAL_DESTINATION_PAYMENT,
                            tariffContractPaymentId: offerOption[property].ID_TARIFF_LOCAL_DESTINATION_PAYMENT,
                            tariffReceiving: offerOption[property] ? offerOption[property].TARIFF_LOCAL_DESTINATION_RECEIVING_CONCATENATED : null,
                            tariffReceivingId: offerOption[property] ? offerOption[property].ID_TARIFF_LOCAL_DESTINATION_RECEIVING : null,
                            tariffContractReceivingId: offerOption[property] ? offerOption[property].ID_TARIFF_LOCAL_DESTINATION_RECEIVING : null
                        });
                        break;
                }
            }

            this.scope.tariffComplementary = offerOption.TARIFF_COMPLEMENTARY;
            this.scope.routeDetails = routeDetails;
            this.modalService.showModalInfo(
                {
                    scope: this.scope,
                    template: require('../view/modals/offerWizardModalRouteDetails.html'),
                    size: 'vlg'
                },
                {
                    actionButtonText: "GENERAL.CLOSE",
                    headerText: "BASIC_DATA.ROUTING_DETAILS"
                }
            );
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openTransitTimeDetails(transitTime: ITransitTime): Promise<void> {
        if (!transitTime) return this.notifyError("transitTime is null");

        const html = OfferNewAlternativeHelper.buildTransitTimeDetails(transitTime, this.$filterService);
        this.modalService.showModalInfo(
            {
                scope: this.scope
            },
            {
                actionButtonClass: 'btn-default',
                actionButtonText: 'GENERAL.CLOSE',
                headerText: 'GENERAL.TRANSIT_TIME',
                bodyText: this.sce.trustAsHtml(html)
            });
    }

    private async reprocessOfferOption() {
        await this.createOfferWizardFilter(true);
        this.initAlternativesTab();
    }

    private enableTotalGrossWeight() {
        const cargoList = this.scope.model.CARGO_TAB.CARGO_LIST;
        if (cargoList) {
            this.scope.model.CARGO_TAB.GROSS_WEIGHT = null;
            for (const cargo of cargoList) {
                cargo.GROSS_WEIGHT_UNITARY = null;
                cargo.GROSS_WEIGHT = null;
            }
            angular.element("#cargoGrossWeight").focus();
        }
    }

    private buildQtyUnityHtml = (typeCargo: ISelectorModel, cargo: ICargoTab): string => {
        let qtyUnity = "";
        if (typeCargo && cargo) {
            if (typeCargo && typeCargo.ID == ECargoTypeId.FCL) {
                if (cargo.FCL && cargo.FCL.length) {
                    for (const fcl of cargo.FCL) {
                        if (qtyUnity && qtyUnity.length) qtyUnity += ", ";
                        qtyUnity += `${fcl.AMOUNT} x ${fcl.EQUIPMENT ? fcl.EQUIPMENT.CODE : ''}`;
                    }
                }
            } else if (typeCargo && typeCargo.ID == ECargoTypeId.BREAK_BULK) {
                if (cargo.BB_RORO && cargo.BB_RORO.length) {
                    for (const bbRoro of cargo.BB_RORO) {
                        if (qtyUnity && qtyUnity.length) qtyUnity += ", ";
                        qtyUnity += `${bbRoro.AMOUNT} x ${bbRoro.EQUIPMENT ? bbRoro.EQUIPMENT.CODE : ''}`;
                    }
                }
            } else if (typeCargo && typeCargo.ID == ECargoTypeId.LCL) {
                qtyUnity = cargo.LCL_AMOUNT + " x " + cargo.LCL_APPLICATION.CODE;
            } else if (typeCargo && typeCargo.ID == ECargoTypeId.ROAD) {
                if (cargo.FTL && cargo.FTL.length) {
                    for (const ftl of cargo.FTL) {
                        if (qtyUnity && qtyUnity.length) qtyUnity += ", ";
                        qtyUnity += `${ftl.AMOUNT} x ${ftl.VEHICLE_TYPE ? ftl.VEHICLE_TYPE.NAME : ''}`;
                    }
                }
            }
        }
        return qtyUnity;
    }

    private async buildIndicatorTableSettings() {
        const typeCargo = this.scope.model.OFFER_TAB ? this.scope.model.OFFER_TAB.TYPE_CARGO : null;
        if (!typeCargo) throw Error('type cargo is null');
        const productId = this.scope.model.OFFER_TAB.PRODUCT.ID ? this.scope.model.OFFER_TAB.PRODUCT.ID : null;
        let contributionLabel = "";
        let contributionCode = "";
        let wdLabel = "";
        let wdCode = "";
        if ((productId == EProductId.MARITIME_EXPORT || productId == EProductId.MARITIME_IMPORT) && typeCargo.ID == ECargoTypeId.FCL) {
            contributionLabel = "FINANCIAL.CT_TEU";
            contributionCode = "EGP_TOTAL_TEUS";
            wdLabel = "FINANCIAL.CT_FILE_WITHOUT_DOCS";
            wdCode = "CTWD";
        }
        else if ((productId == EProductId.MARITIME_EXPORT || productId == EProductId.MARITIME_IMPORT) && typeCargo.ID == ECargoTypeId.LCL) {
            contributionLabel = "FINANCIAL.CT_FILE";
            contributionCode = "EGP_TOTAL_FILE";
            wdLabel = "FINANCIAL.CT_FILE_WITHOUT_DOCS";
            wdCode = "CTWD_FILE";
        }
        else if ((productId == EProductId.AIR_EXPORT || productId == EProductId.AIR_IMPORT) && typeCargo.ID == ECargoTypeId.AIR) {
            contributionLabel = "FINANCIAL.CT_TON";
            contributionCode = "EGP_TOTAL_WEIGHT";
            wdLabel = "PRODUCT.CT_WD_TON";
            wdCode = "CTWD_WEIGHT";
        }
        else if ((productId == EProductId.ROAD_EXPORT || productId == EProductId.ROAD_IMPORT) && typeCargo.ID == ECargoTypeId.ROAD) {
            contributionLabel = "FINANCIAL.CT_VEHICLE";
            contributionCode = "EGP_TOTAL_VEHICLES";
            wdLabel = "PRODUCT.CT_WD_BY_VEHICLE";
            wdCode = "CTWD_VEHICLES";
        }
        else if (typeCargo.ID == ECargoTypeId.BREAK_BULK || typeCargo.ID == ECargoTypeId.RO_RO) {
            contributionLabel = "FINANCIAL.CT_FILE";
            contributionCode = "EGP_TOTAL_FILE";
            wdLabel = "FINANCIAL.CT_FILE_WITHOUT_DOCS";
            wdCode = "CTWD_FILE";
        }
        this.scope.indicatorsSettings = {
            contributionLabel: contributionLabel,
            contributionCode: contributionCode,
            wdLabel: wdLabel,
            wdCode: wdCode
        };
    }

    private async getOfferOptionInvalidCharge(offerOption: IOfferOptionInvalid) {
        try {
            if (offerOption.SHOW && (!offerOption.CHARGES || offerOption.CHARGES.length == 0)) {
                this.block();
                const result = await this.productService.get({ route: "/offerWizard/offerOptionInvalid/charge/" + offerOption.ID_FREIGHT_ROUTES, data: { sysConvertIdToString: false } });
                if (result && result.data && result.data.data && result.data.data.data) offerOption.CHARGES = result.data.data.data;
                else offerOption.CHARGES = [];
                this.scope.$applyAsync();
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async openTariffDetDemDetails(tariffDetDem: ITariffDetDemDetails) {
        if (!tariffDetDem) return this.notifyError("tariffDetDem is null");

        const html = OfferNewAlternativeHelper.buildTariffDetDemDetails(this.$filterService, tariffDetDem);
        const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
            {
                scope: this.scope,
                size: 'full monaco-wizard'
            },
            {
                actionButtonClass: 'btn-default',
                actionButtonText: 'GENERAL.CLOSE',
                headerText: 'GENERAL.DETDEM_TARIFF_COMPOSITION',
                bodyText: this.sce.trustAsHtml(html)
            });

        await modalInstance.rendered.then(() => {
            angular.element(".openTariffDetDemOriginPayment").bind('click', () => { this.sessionService.openTab("app.product.tariffDetDem", <ILinkParameter>{ ID: tariffDetDem.DET_DEM_ORIGIN ? tariffDetDem.DET_DEM_ORIGIN.ID_PAYMENT.toString() : null }); });
            angular.element(".openTariffDetDemOriginReceiving").bind('click', () => { this.sessionService.openTab("app.product.tariffDetDem", <ILinkParameter>{ ID: tariffDetDem.DET_DEM_ORIGIN ? tariffDetDem.DET_DEM_ORIGIN.ID_RECEIVING.toString() : null }); });
            angular.element(".openTariffDetDemDestinationPayment").bind('click', () => { this.sessionService.openTab("app.product.tariffDetDem", <ILinkParameter>{ ID: tariffDetDem.DET_DEM_DESTINATION ? tariffDetDem.DET_DEM_DESTINATION.ID_PAYMENT.toString() : null }); });
            angular.element(".openTariffDetDemDestinationReceiving").bind('click', () => { this.sessionService.openTab("app.product.tariffDetDem", <ILinkParameter>{ ID: tariffDetDem.DET_DEM_DESTINATION ? tariffDetDem.DET_DEM_DESTINATION.ID_RECEIVING.toString() : null }); });
        });
    }

    private async openProfitShareDetails(profitShareDetail: IOfferProfitShareDetail) {
        if (!profitShareDetail) return;
        if (profitShareDetail.COMPOSITION_ORIGINAL && profitShareDetail.COMPOSITION && profitShareDetail.COMPOSITION_ORIGINAL.length != profitShareDetail.COMPOSITION.length) return this.notifyError("Inconsistency was found in the compositions, please contact an administrator.");

        const html = OfferNewAlternativeHelper.buildProfitShareDetails(this.$filterService, profitShareDetail);
        const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
            {
                scope: this.scope,
                size: 'full monaco-wizard'
            },
            {
                actionButtonClass: 'btn-default',
                actionButtonText: 'GENERAL.CLOSE',
                headerText: 'OPERATIONAL.INTERCOMPANY',
                bodyText: this.sce.trustAsHtml(html)
            }
        );

        await modalInstance.rendered.then(() => {
            angular.element("#profitShareDetailAgreement").bind('click', () => { this.sessionService.openTab("app.product.paramsProfitShare", <ILinkParameter>{ ID: profitShareDetail && profitShareDetail.ID ? profitShareDetail.ID.toString() : profitShareDetail.ID }); });
        });
    }

    private async openPaymentReceivingChargesDetails(currentCurrency: ISelectorModel, charges: IOfferOptionCharge[], indicators: IOfferOptionIndicators) {
        if (!currentCurrency) return this.notifyError("currentCurrency is null.");
        if (!charges) return this.notifyError("charges is null.");
        if (!indicators) return this.notifyError("indicators is null.");
        if (!this.scope.model.OFFER_TAB.TYPE_CARGO) throw Error('type cargo is null');
        if (!this.scope.model.OFFER_TAB.PRODUCT) throw Error('product is null');

        const html = OfferNewAlternativeHelper.buildPaymentReceivingChargesDetails(this.$filterService, this.scope.model.OFFER_TAB.TYPE_CARGO, this.scope.model.OFFER_TAB.PRODUCT.ID, currentCurrency, charges, indicators);
        const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
            {
                scope: this.scope,
                size: 'full monaco-wizard'
            },
            {
                actionButtonClass: 'btn-default',
                actionButtonText: 'GENERAL.CLOSE',
                headerText: 'FINANCIAL.INDICATORS_DETAILS',
                bodyText: this.sce.trustAsHtml(html)
            }
        );

        // compile tooltips
        modalInstance.rendered.then(() => {
            const tooltipTotalPayment = angular.element("#tooltipTotalPayment");
            const tooltipTotalReceiving = angular.element("#tooltipTotalReceiving");
            const tooltipTotalProfitShare = angular.element("#tooltipTotalProfitShare");
            const tooltipContribution = angular.element("#tooltipContribution");
            const tooltipModal = angular.element(".tooltipModal");
            if (tooltipTotalPayment) this.$compile(tooltipTotalPayment)(this.scope);
            if (tooltipTotalReceiving) this.$compile(tooltipTotalReceiving)(this.scope);
            if (tooltipTotalProfitShare) this.$compile(tooltipTotalProfitShare)(this.scope);
            if (tooltipContribution) this.$compile(tooltipContribution)(this.scope);
            if (tooltipModal) this.$compile(tooltipModal)(this.scope);
        });

    }

    private async openChargesPerWeightRangeModal(chargeWeightRangeList: IOfferOptionChargeWeightRange[]): Promise<void> {
        try {
            const modalID = this.modalService.newModal();
            await this.modalService.showChargesPerWeightRangeModal({ modalID: modalID, actionButtonClass: 'btn-default', offerOptionChargeWeightRangeList: chargeWeightRangeList, isViewMode: true });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async openComparisonChargesPerWeightRangeModal(): Promise<void> {
        try {
            const weightRangeCharges = await this.getWeightRangeChargeComparisonList();
            if (weightRangeCharges && weightRangeCharges.length) {
                this.modalService.showModalInfo(
                    {
                        formService: 'register',
                        template: require("../view/modals/chargesPerWeightRangeComparisonModal.html"),
                        size: 'full'
                    },
                    {
                        headerText: "PRODUCT.CHARGES_PER_WEIGHT_RANGE"
                    },
                    {
                        weightRangeCharges
                    }
                );
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getWeightRangeChargeComparisonList(): Promise<INewAlternativeCompareWeightRangeCharges[]> {
        let weightRangeCharges: INewAlternativeCompareWeightRangeCharges[] = [];
        try {
            this.block();
            const selectedOfferOption = this.scope.offerOptions.find(offerOption => offerOption.SELECTED);
            const requestParams: ICompareChargesTabRequest = { ID_OFFER_WIZARD_FILTER: this.scope.model._id, ID_OFFER_OPTION_PAYMENT: selectedOfferOption._id, KEEP_MANUAL_CHARGES: this.scope.model.CHARGES_TAB.KEEP_MANUAL_CHARGES, KEEP_SELLING_RATES: this.scope.model.CHARGES_TAB.KEEP_SELLING_RATES };
            const request: ICompareWeightRangeChargesTabResponse = await this.restService.newObjectPromise(`${this.quotationCombinedUrl}/newAlternative/weightRangeCharges`, requestParams, 30000, false);
            if (!request.SHOW) this.notifyInfo(this.getTranslate("GENERAL.PROCESSING_DATA"));
            else {
                weightRangeCharges = request.WEIGHT_RANGE_CHARGES;
                if (!request.WEIGHT_RANGE_CHARGES || (request.WEIGHT_RANGE_CHARGES && request.WEIGHT_RANGE_CHARGES.length == 0)) this.notifyInfo(this.getTranslate("REGISTRATION.NO_RECORD_FOUND"));
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return weightRangeCharges;
        }
    }

    private getRouteDetailsRowSpan(rowSpan: number, routes: IOfferOptionRoutingPoint[], index: number): number {
        if (routes && routes.length > 0 && (index || index == 0) && routes[index]) {
            const type = routes[index].TYPE;
            if (routes[index + 1] && routes[index + 1].TYPE == type) {
                rowSpan++;
                rowSpan = this.getRouteDetailsRowSpan(rowSpan, routes, index + 1);
            }
        }
        return rowSpan;
    }

    private async getAndUpdateRouteAgentValues(): Promise<void> {
        try {
            this.scope.model.DETAILS_TAB.ROUTE = null;
            const result: ISpecRoute[] = [];
            const offerTabModel = this.scope.model.OFFER_TAB ? this.scope.model.OFFER_TAB : null;
            const selectedRoutes = this.scope.model.ROUTES_TAB && this.scope.model.ROUTES_TAB.ROUTES ? this.scope.model.ROUTES_TAB.ROUTES.filter(route => route.SELECTED) : null;
            if (offerTabModel && this.scope.model.DETAILS_TAB && selectedRoutes && selectedRoutes.length > 0) {
                const originDestination: IRouteAgentOriginDestination[] = [];
                selectedRoutes.forEach(element => {
                    originDestination.push({ DESTINATION: element.DESTINATION, ORIGIN: element.ORIGIN, MOVE_TYPE: element.MOVE_TYPE });
                });
                const filter: IOfferWizardRequestRouteAgent = {
                    ORIGIN_DESTINATION: originDestination,
                    PRODUCT: offerTabModel.PRODUCT ? offerTabModel.PRODUCT.ID : null,
                    TYPE_CARGO: offerTabModel.TYPE_CARGO ? offerTabModel.TYPE_CARGO.ID : null,
                    PROCESS_TYPE: offerTabModel.PROCESS_TYPE ? offerTabModel.PROCESS_TYPE.ID : null,
                    COMMODITY_SECTION: offerTabModel.COMMODITY ? offerTabModel.COMMODITY.map(commodity => commodity.ID) : [],
                    ACCOUNT: offerTabModel.ACCOUNT ? parseInt(offerTabModel.ACCOUNT.ID) : null
                };
                const resultOperation = await this.productService.post({ route: '/offerWizard/agent', data: { ...filter, sysConvertIdToString: false } });
                if (resultOperation && resultOperation.data && resultOperation.data.data && resultOperation.data.data.length > 0) {
                    const offerWizardAgents: IOfferWizardAgentResult[] = resultOperation.data.data;
                    for (const element of offerWizardAgents) {
                        let networkId = null;
                        if (element.LOCAL_AGENT && element.LOCAL_AGENT.ID_NETWORK) networkId = element.LOCAL_AGENT.ID_NETWORK;
                        else if (element.EXTERNAL_AGENT && element.EXTERNAL_AGENT.ID_NETWORK) networkId = element.EXTERNAL_AGENT.ID_NETWORK;
                        else if (element.OTHER_AGENT && element.OTHER_AGENT.ID_NETWORK) networkId = element.OTHER_AGENT.ID_NETWORK;
                        let name = element.ORIGIN_DESTINATION && element.ORIGIN_DESTINATION.ORIGIN ? element.ORIGIN_DESTINATION.ORIGIN.CODE : "";
                        if (element.ORIGIN_DESTINATION && element.ORIGIN_DESTINATION.DESTINATION) name += ` x ${element.ORIGIN_DESTINATION.DESTINATION.CODE}`;

                        const route: ISpecRoute = {
                            NAME: name,
                            ORIGIN: element.ORIGIN_DESTINATION ? element.ORIGIN_DESTINATION.ORIGIN : null,
                            DESTINATION: element.ORIGIN_DESTINATION ? element.ORIGIN_DESTINATION.DESTINATION : null,
                            MOVE_TYPE: element.ORIGIN_DESTINATION ? element.ORIGIN_DESTINATION.MOVE_TYPE : null,
                            MASTER_DIRECT: element.MASTER_DIRECT,
                            LOCAL_AGENT: element.LOCAL_AGENT ? { ID: element.LOCAL_AGENT.ID, NAME: element.LOCAL_AGENT.NAME, CODE: element.LOCAL_AGENT.ID_NETWORK ? element.LOCAL_AGENT.ID_NETWORK.toString() : null, LEGAL_PERSON_ID: element.LOCAL_AGENT.LEGAL_PERSON_ID } : null,
                            EXTERNAL_AGENT: element.EXTERNAL_AGENT ? { ID: element.EXTERNAL_AGENT.ID, NAME: element.EXTERNAL_AGENT.NAME, CODE: element.EXTERNAL_AGENT.ID_NETWORK ? element.EXTERNAL_AGENT.ID_NETWORK.toString() : null, LEGAL_PERSON_ID: element.EXTERNAL_AGENT.LEGAL_PERSON_ID } : null,
                            OTHER_AGENT: element.OTHER_AGENT ? { ID: element.OTHER_AGENT.ID, NAME: element.OTHER_AGENT.NAME, CODE: element.OTHER_AGENT.ID_NETWORK ? element.OTHER_AGENT.ID_NETWORK.toString() : null, LEGAL_PERSON_ID: element.OTHER_AGENT.LEGAL_PERSON_ID } : null,
                            AGENT_NETWORK_ID: networkId,
                            MASTER_MODALITY: null,
                            HOUSE_MODALITY: null,
                            ACCOUNT_REQUIREMENT_ID: null,
                            ACCOUNT_REQUIREMENT_CONCATENATED: null
                        };
                        await this.getAndUpdateRouteAccountValues(null, route);
                        result.push(route);
                    }
                }
                if (result && result.length > 0) {
                    this.scope.model.DETAILS_TAB.ROUTE = [];
                    for (let index in result) {
                        this.scope.model.DETAILS_TAB.ROUTE.push(result[index]);
                        this.timeout(() => {
                            this.scope.selectorValidity("detailsRouteLocalAgent" + index);
                            this.scope.selectorValidity("detailsRouteExternalAgent" + index);
                            this.scope.selectorValidity("detailsRouteMasterModality" + index);
                            this.scope.selectorValidity("detailsRouteHouseModality" + index);
                        }, 200);
                    }
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getAndUpdateDetailsTab(): Promise<void> {
        try {
            this.block();
            const formatedData: IOfferWizardFilter = {
                _id: this.scope.model._id,
                OFFER_TAB: this.scope.model.OFFER_TAB,
                ROUTE_TAB: this.scope.model.ROUTES_TAB,
                CARGO_TAB: this.scope.model.CARGO_TAB,
                SPEC_TAB: this.scope.model.DETAILS_TAB,
                ID_OPPORTUNITY: null,
                REPROCESS: null,
                COMPLETED: null,
                CREATED_AT: null,
                CREATED_BY: null,
                MODIFIED_AT: null,
                MODIFIED_BY: null
            };
            const requestData: IDetailsTabRequest = { data: formatedData };
            const resultOperation: IDetailsTabResponse = await this.restService.newObjectPromise(`${this.quotationCombinedUrl}/newAlternative/details`, requestData, 30000, false);
            if (resultOperation) {
                this.scope.model.DETAILS_TAB.IS_DANGEROUS_CARGO = resultOperation.IS_DANGEROUS_CARGO;
                this.scope.model.DETAILS_TAB.DANGEROUS_CARGO = resultOperation.DANGEROUS_CARGO;
                this.scope.model.DETAILS_TAB.IS_INSURANCE = resultOperation.IS_INSURANCE;
                this.scope.model.DETAILS_TAB.SECURE = resultOperation.SECURE;
                this.scope.model.DETAILS_TAB.IS_REFRIGERATED_CARGO = resultOperation.IS_REFRIGERATED_CARGO;
                this.scope.model.DETAILS_TAB.REFRIGERATED_CARGO = resultOperation.REFRIGERATED_CARGO;
                this.scope.model.DETAILS_TAB.IS_CARGO_TREATMENT = resultOperation.IS_CARGO_TREATMENT;
                this.scope.model.DETAILS_TAB.CARGO_TREATMENT = resultOperation.CARGO_TREATMENT;
                if (resultOperation.ROUTE) this.scope.model.DETAILS_TAB.ROUTE = resultOperation.ROUTE;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getIncotermValues(filter: IOfferWizardRequestIncoterm): Promise<IOfferWizardIncotermResult> {
        if (!filter) this.notifyError('filter is null');
        let result: IOfferWizardIncotermResult = null;
        try {
            const request = await this.productService.post({ route: '/offerWizard/incoterm', data: { ...filter, sysConvertIdToString: false } });
            if (request && request.data && request.data.data) result = request.data.data;
        } catch (ex) {
            this.handleError(ex);
        }
        return result;
    }

    private async getRouteAccountValues(filter: IOfferWizardRequestRouteAccount): Promise<IOfferWizardAccountResult> {
        if (!filter) this.notifyError('filter is null');
        let result: IOfferWizardAccountResult = null;
        try {
            const request = await this.productService.post({ route: '/offerWizard/account', data: { ...filter, sysConvertIdToString: false } });
            if (request && request.data && request.data.data) result = request.data.data;
        } catch (ex) {
            this.handleError(ex);
        }
        return result;
    }

    private async getAndUpdateRouteAccountValues(routeIndex: number, route?: ISpecRoute, masterDirect?: boolean) {
        if (!route && routeIndex == null) return this.notifyError("routeIndex is null.");
        try {
            this.block();
            await this.scope.$applyAsync();
            if (!route) route = this.scope.model.DETAILS_TAB && this.scope.model.DETAILS_TAB.ROUTE && this.scope.model.DETAILS_TAB.ROUTE.length && this.scope.model.DETAILS_TAB.ROUTE[routeIndex] ? this.scope.model.DETAILS_TAB.ROUTE[routeIndex] : null;
            const offerTabModel = this.scope.model.OFFER_TAB ? this.scope.model.OFFER_TAB : null;
            const filter: IOfferWizardRequestRouteAccount = {
                ID_ACCOUNT: offerTabModel.ACCOUNT ? parseInt(offerTabModel.ACCOUNT.ID) : null,
                PROCESS_TYPE: offerTabModel.PROCESS_TYPE ? offerTabModel.PROCESS_TYPE.ID : null,
                ID_ROUTING_POINT_ORIGIN: route.ORIGIN ? parseInt(route.ORIGIN.ID) : null,
                ID_ROUTING_POINT_DESTINATION: route.DESTINATION ? parseInt(route.DESTINATION.ID) : null,
                ID_PRODUCT: offerTabModel.PRODUCT ? offerTabModel.PRODUCT.ID : null,
                ID_INCOTERM: offerTabModel.INCOTERM ? parseInt(offerTabModel.INCOTERM.ID) : null,
                MASTER_DIRECT: masterDirect ? masterDirect : route.MASTER_DIRECT,
                ID_NETWORK: route.AGENT_NETWORK_ID,
                ID_EXPORTER: offerTabModel.EXPORTER ? parseInt(offerTabModel.EXPORTER.ID) : null,
                ID_IMPORTER: offerTabModel.IMPORTER ? parseInt(offerTabModel.IMPORTER.ID) : null,
                ID_EXTERNAL_AGENT: route.EXTERNAL_AGENT ? parseInt(route.EXTERNAL_AGENT.ID) : null,
                ID_PROVIDER: offerTabModel.PROVIDER ? parseInt(offerTabModel.PROVIDER.ID) : null,
                ID_TYPE_CARGO: offerTabModel.TYPE_CARGO ? offerTabModel.TYPE_CARGO.ID : null,
                ID_NEW_FREIGHT_CONTRACT: offerTabModel.CONTRACT_ROUTES && offerTabModel.CONTRACT_ROUTES.ID_NEW_FREIGHT_CONTRACT ? offerTabModel.CONTRACT_ROUTES.ID_NEW_FREIGHT_CONTRACT : null,
                ID_ORIGIN: route.ORIGIN && route.ORIGIN.COUNTRY && route.ORIGIN.COUNTRY.ID ? route.ORIGIN.COUNTRY.ID : null,
                ID_DESTINATION: route.DESTINATION && route.DESTINATION.COUNTRY && route.DESTINATION.COUNTRY.ID ? route.DESTINATION.COUNTRY.ID : null,
            };
            const accountResult: IOfferWizardAccountResult = await this.getRouteAccountValues(filter);
            let masterModality = null;
            let houseModality = null;
            let accountRequirementId = null;
            let accountRequirementConcatenated = null;
            if (accountResult) {
                accountRequirementId = accountResult.ID;
                accountRequirementConcatenated = accountResult.CONCATENATED;
                if (accountResult.MASTER_MODALITY) masterModality = accountResult.MASTER_MODALITY.MODALITY ? { ID: accountResult.MASTER_MODALITY.MODALITY.ID, NAME: accountResult.MASTER_MODALITY.MODALITY.NAME } : null;
                if (accountResult.HOUSE_MODALITY) houseModality = accountResult.HOUSE_MODALITY.MODALITY ? { ID: accountResult.HOUSE_MODALITY.MODALITY.ID, NAME: accountResult.HOUSE_MODALITY.MODALITY.NAME } : null;
            }
            if (!masterModality || !houseModality) {
                const incoterm = await this.getIncotermValues({ ID_INCOTERM: this.scope.model.OFFER_TAB && this.scope.model.OFFER_TAB.INCOTERM ? parseInt(this.scope.model.OFFER_TAB.INCOTERM.ID) : null });
                if (!masterModality) masterModality = incoterm.INCOME;
                if (!houseModality) houseModality = incoterm.OUTCOME;
            }
            route.MASTER_MODALITY = masterModality;
            route.HOUSE_MODALITY = houseModality;
            route.ACCOUNT_REQUIREMENT_ID = accountRequirementId;
            route.ACCOUNT_REQUIREMENT_CONCATENATED = accountRequirementConcatenated;
            await this.scope.$applyAsync();
            if (routeIndex != null) {
                this.timeout(() => {
                    this.scope.selectorValidity("detailsRouteLocalAgent" + routeIndex);
                    this.scope.selectorValidity("detailsRouteExternalAgent" + routeIndex);
                    this.scope.selectorValidity("detailsRouteMasterModality" + routeIndex);
                    this.scope.selectorValidity("detailsRouteHouseModality" + routeIndex);
                }, 200);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async updateWeightRangeList(filter: IWeightRangeListCustomFilter): Promise<void> {
        let result: IWeightRangeSelector[] = [];
        try {
            const weightRangeListRequest = await this.productService.post({ route: `/weightRange/list/custom`, data: filter }, false);
            if (weightRangeListRequest && weightRangeListRequest.data && weightRangeListRequest.data.data && weightRangeListRequest.data.data.data) result = weightRangeListRequest.data.data.data.map(x => { return { ID: x.ID, NAME: x.NAME, MIN_WEIGHT: x.MIN_WEIGHT, MAX_WEIGHT: x.MAX_WEIGHT } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.scope.weightRangeList = result ? result.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE } }) : [];
            this.scope.weightRangeFullList = result;
            this.scope.$applyAsync();
        }
    }

    private async updateAirApplication(): Promise<void> {
        if (this.scope.model.OFFER_TAB.TYPE_CARGO.ID == ECargoTypeId.AIR) {
            const chargeableWeight = this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT;
            const overChargeableWeight = this.scope.model.CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT;
            const taxedWeightInternalSequence = overChargeableWeight && overChargeableWeight > chargeableWeight ? "019" : "003";
            if (this.scope.model.CARGO_TAB.AIR.APPLICATION && this.scope.model.CARGO_TAB.AIR.APPLICATION.INTERNAL_SEQUENCE !== taxedWeightInternalSequence) {
                const applicationRequest = await this.productService.post({ route: '/application/list/custom', data: { internalSequence: taxedWeightInternalSequence } });
                let result: IApplicationList = null;
                if (applicationRequest && applicationRequest.data && applicationRequest.data.data && applicationRequest.data.data.length > 0) result = { ID: applicationRequest.data.data[0].ID, NAME: applicationRequest.data.data[0].NAME, CODE: applicationRequest.data.data[0].CODE, PERCENT: applicationRequest.data.data[0].PERCENT, APPLICATION_COMPLEMENT: applicationRequest.data.data[0].APPLICATION_COMPLEMENT, INTERNAL_SEQUENCE: applicationRequest.data.data[0].INTERNAL_SEQUENCE, FREE_TYPING_AMOUNT_CHARGE: applicationRequest.data.data[0].FREE_TYPING_AMOUNT_CHARGE, CT_WITHOUT_DOC: applicationRequest.data.data[0].CT_WITHOUT_DOC, NAME_INTL: applicationRequest.data.data[0].NAME_INTL };
                if (!result) this.notifyError(this.getTranslate('GENERAL.MESSAGES.NOT_POSSIBLE_TO_FIND_CHARGEABLE_WEIGHT_IN_APPLICATION'));
                this.scope.model.CARGO_TAB.AIR.APPLICATION = { ID: result.ID, NAME: result.NAME, PERCENT: result.PERCENT, APPLICATION_COMPLEMENT: result.APPLICATION_COMPLEMENT, INTERNAL_SEQUENCE: result.INTERNAL_SEQUENCE, FREE_TYPING_AMOUNT_CHARGE: result.FREE_TYPING_AMOUNT_CHARGE, CT_WITHOUT_DOC: result.CT_WITHOUT_DOC, NAME_INTL: result.NAME_INTL };
                this.scope.$applyAsync();
            }
        }
    }

    private async calculateOperationChargeable(keepWeightRange?: boolean): Promise<void> {
        let result: number = 1;
        try {
            this.block();
            if (this.scope.model.CARGO_TAB && this.scope.model.CARGO_TAB.CUBIC_WEIGHT) {
                const filter: IOfferRequestOperationChargeable = { CUBIC_WEIGHT: this.scope.model.CARGO_TAB.CUBIC_WEIGHT };
                const resultOperation = await this.productService.post({ route: '/calculationOperations/chargeable', data: filter });
                if (resultOperation && resultOperation.data && resultOperation.data.data && typeof resultOperation.data.data == 'number') result = resultOperation.data.data;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            if (this.scope.model.CARGO_TAB && this.scope.model.CARGO_TAB.AIR) {
                this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT = this.scope.model.CARGO_TAB.GROSS_WEIGHT && this.scope.model.CARGO_TAB.GROSS_WEIGHT > result ? OfferHelper.calculateAirChargeableWeight(this.scope.model.CARGO_TAB.GROSS_WEIGHT) : result;
                if (!keepWeightRange) this.updateWeightBreakBasedOverChargeableWeightOrGrossWeight([this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT > this.scope.model.CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT ? this.scope.model.CARGO_TAB.AIR.CHARGEABLE_WEIGHT : this.scope.model.CARGO_TAB.AIR.OVER_CHARGEABLE_WEIGHT]);
                this.updateAirApplication();
            }
            this.unblock();
        }
    }

    private async calculateOperationWM(): Promise<void> {
        let result: number = 0;
        try {
            this.block();
            if (this.scope.model.CARGO_TAB && this.scope.model.CARGO_TAB.GROSS_WEIGHT && this.scope.model.CARGO_TAB.CUBIC_WEIGHT) {
                const filter: IOfferRequestOperationWM = { GROSS_WEIGHT: this.scope.model.CARGO_TAB.GROSS_WEIGHT, CUBIC_WEIGHT: this.scope.model.CARGO_TAB.CUBIC_WEIGHT };
                const resultOperation = await this.productService.post({ route: '/calculationOperations/wm', data: filter });
                if (resultOperation && resultOperation.data && resultOperation.data.data && typeof resultOperation.data.data == 'number') result = resultOperation.data.data;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            if (this.scope.model.CARGO_TAB) this.scope.model.CARGO_TAB.LCL_AMOUNT = result && result > 1 ? result : 1;
            this.unblock();
        }
    }

    private detailsRouteAgentChange(index: number): void {
        if (index == null) return this.notifyError('index is null');
        if (this.scope.model.DETAILS_TAB && this.scope.model.DETAILS_TAB.ROUTE && this.scope.model.DETAILS_TAB.ROUTE.length && this.scope.model.DETAILS_TAB.ROUTE[index]) {
            const route = this.scope.model.DETAILS_TAB.ROUTE[index];
            route.MASTER_MODALITY = null;
            route.HOUSE_MODALITY = null;
            this.timeout(() => {
                this.scope.selectorValidity("detailsRouteMasterModality" + index);
                this.scope.selectorValidity("detailsRouteHouseModality" + index);
            }, 200);
            if (!route.LOCAL_AGENT && !route.EXTERNAL_AGENT && !route.OTHER_AGENT) route.AGENT_NETWORK_ID = null;
            else if (route.LOCAL_AGENT && !route.EXTERNAL_AGENT && !route.OTHER_AGENT) route.AGENT_NETWORK_ID = parseInt(route.LOCAL_AGENT.CODE);
            else if (route.EXTERNAL_AGENT && !route.LOCAL_AGENT && !route.OTHER_AGENT) route.AGENT_NETWORK_ID = parseInt(route.EXTERNAL_AGENT.CODE);
            else if (route.OTHER_AGENT && !route.LOCAL_AGENT && !route.EXTERNAL_AGENT) route.AGENT_NETWORK_ID = parseInt(route.OTHER_AGENT.CODE);
        }
    }

    private async grossWeightChange() {
        if (this.scope.model.CARGO_TAB.GROSS_WEIGHT == 0) this.scope.model.CARGO_TAB.GROSS_WEIGHT = null;
        if (this.scope.isAirCargo) await this.calculateOperationChargeable();
        else if (this.scope.isBbLclRoroCargo) this.calculateOperationWM();

    }

    private async cubicWeightChange() {
        if (this.scope.model.CARGO_TAB.CUBIC_WEIGHT == 0) this.scope.model.CARGO_TAB.CUBIC_WEIGHT = null;
        if (this.scope.isAirCargo) await this.calculateOperationChargeable();
        else if (this.scope.isBbLclRoroCargo) this.calculateOperationWM();
    }

    private initCountGetAlternativesTabDataList() {
        if (!this.scope.alternativesTabSearchControl.isSearchTabDataListCanceled) {
            this.timeout(() => {
                this.scope.alternativesTabSearchControl.searchTabDataListCount += 5;
                if (this.scope.alternativesTabSearchControl.searchTabDataListCount < 100) this.initCountGetAlternativesTabDataList();
                else if (!this.scope.alternativesTabSearchControl.isSearchTabDataListCanceled) this.timeout(() => { this.getAndUpdateOptionList() }, 1000);
            }, 100);
        }
    }

    private initCountGetRatesTabDataList() {
        if (!this.scope.ratesTabSearchControl.isSearchTabDataListCanceled) {
            this.timeout(() => {
                this.scope.ratesTabSearchControl.searchTabDataListCount += 5;
                if (this.scope.ratesTabSearchControl.searchTabDataListCount < 100) this.initCountGetRatesTabDataList();
                else if (!this.scope.ratesTabSearchControl.isSearchTabDataListCanceled) this.timeout(() => { this.getAndUpdateRatesList() }, 1000);
            }, 100);
        }
    }

    private initCountGetChargesTabDataList() {
        if (!this.scope.chargesTabSearchControl.isSearchTabDataListCanceled) {
            this.timeout(() => {
                this.scope.chargesTabSearchControl.searchTabDataListCount += 5;
                if (this.scope.chargesTabSearchControl.searchTabDataListCount < 100) this.initCountGetChargesTabDataList();
                else if (!this.scope.chargesTabSearchControl.isSearchTabDataListCanceled) this.timeout(() => { this.getAndUpdateChargesList() }, 1000);
            }, 100);
        }
    }

    private updateCargoCalculateFieldsDisabledControl(amount: number) {
        this.scope.isCargoCalculatedFieldsDisabled = amount && amount > 0 && amount != 1;
    }

    private updateCargoCalculatedFields() {
        if (this.scope.model.CARGO_TAB) {
            if (this.scope.model.CARGO_TAB.CARGO_LIST && this.scope.model.CARGO_TAB.CARGO_LIST.length > 0) {
                this.scope.isCargoCalculatedFieldsDisabled = true;
            } else {
                this.scope.isCargoCalculatedFieldsDisabled = false;
            }
        }
    }

    private updateWeightBreakBasedOverChargeableWeightOrGrossWeight(amounts: number[]): void {
        const weightRangeList = [];
        try {
            if (amounts && amounts.length > 0 && this.scope.weightRangeFullList && this.scope.weightRangeFullList.length > 0) {
                for (let i = 0; i < this.scope.weightRangeFullList.length; i++) {
                    const weightRange = this.scope.weightRangeFullList[i];
                    if (weightRange) {
                        for (const amount of amounts) {
                            if (amount != null && !weightRangeList.some(weightRangeObj => weightRangeObj.ID == weightRange.ID) && OfferNewAlternativeHelper.betweenNumber(amount, weightRange.MIN_WEIGHT, weightRange.MAX_WEIGHT)) {
                                weightRangeList.push({ ID: weightRange.ID, NAME: weightRange.NAME, CODE: weightRange.CODE });
                                break;
                            }
                        }
                    }
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.scope.model.CARGO_TAB.AIR.WEIGHT_RANGE = weightRangeList;
            this.updateWeightRangeBase();
        }
    }

    private getUrlQuotationCombined(): string {
        const baseRoute = '/quotationCombined';
        return this.config.quotationCombinedUrl + baseRoute;
    }
}

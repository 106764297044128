import angular = require("angular");
import { IColumnDef, IGridOptions } from "ui-grid";
import { GridFormService, IGridFormController, IGridFormServiceScope } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IModalService } from "@services/ModalService";
import { DataOperationalService } from "@services/DataOperationalService";
import { IDocumentError } from '@models/interface/common/IDocumentError';
import { UserGroupModel, PERMISSION, CRITERIA, CRITERIA_DESCRIPTION } from "@models/interface/product/UserGroupModel";
import { UserRolesModel } from "@models/interface/product/UserRolesModel";
import { ICustomLogProperties } from "@models/interface/common/IViewLog";
import { BrowserTitle } from "../../common/BrowserTitle";
import { SelectorModel } from '../../common/model/SelectorModel';
import { HelperService } from "@services/HelperService";

interface IUserRolePermission extends UserRolesModel {
    SELECTED_ALLOW: boolean;
    SELECTED_CRITERIA: CRITERIA_DESCRIPTION;
    SELECTED_CRITERIA_ID: CRITERIA;
}

interface IUserGroupScope extends IGridFormServiceScope {
    model: UserGroupModel;
    userRolesList: IUserRolePermission[];
    crudList: IUserRolePermission[];
    filterList: IUserRolePermission[];
    routineList: IUserRolePermission[];
    menuList: IUserRolePermission[];
    customLogProperties: ICustomLogProperties[];
    gridOptions: IGridOptions;
    getRouteData: (role: IUserRolePermission, search?: string) => void;
    allowPermission: (role: IUserRolePermission) => void;
    collapseHeader: (elementId: string, state?: string) => void;
    selectAll: (roles: IUserRolePermission[]) => void;
    clearAll: (roles: IUserRolePermission[]) => void;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
}

export class UserGroupRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ["$injector", "$scope", "$element"];
    private $scope: IUserGroupScope;
    private RestService: IRestService;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private collapseState: string;
    private ModalService: IModalService;
    private modalID: number;
    private dataOperationalService: DataOperationalService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IUserGroupScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get("$q");
        this.$timeout = $injector.get("$timeout");
        this.RestService = $injector.get("RestService");
        this.ModalService = $injector.get("ModalService");
        this.dataOperationalService = $injector.get('DataOperationalService');
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlHelper();
            this.$scope.customLogProperties = this.getCustomLogProperties();

            this.initForm(this, "form", "userGroup", "GENERAL.MENU.USER_GROUP", true);
            await this.initGrid("userGroup", "/userGroup/list", true, true, 120000, true, true);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initGridColumns(columns) {
        let columnDefs: Array<IColumnDef> = new Array();

        const view = `<div class="text-center"><a ng-click="grid.appScope.view(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.edit(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copy(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="(row.entity.DOCUMENT_ERROR && row.entity.DOCUMENT_ERROR.length) ? 'text-danger' : 'text-green'" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 130,
            maxWidth: 130,
            cellTemplate: (view + edit + copy + modalIntegration),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        for (let i = 0; i < columns.length; i++) {
            let name = null;
            let displayName = null;
            let width = 0;
            let cellTemplate = undefined;
            let cellFilter = null;
            let visible = true;
            let filter: uiGrid.IFilterOptions = { condition: this.gridService.filterSelectObject };
            let searchProps = null;
            let filterCellFiltered = null;

            switch (columns[i].toUpperCase()) {
                case "ID":
                    name = columns[i];
                    displayName = "GENERAL.CODE";
                    width = 5;
                    break;
                case "NAME":
                    name = columns[i];
                    displayName = "GENERAL.NAME";
                    width = 20;
                    break;
                case "CREATED_BY":
                    name = columns[i];
                    displayName = "GENERAL.CREATED_BY";
                    width = 15;
                    visible = true;
                    break;
                case "CREATED_DATE":
                    name = columns[i];
                    displayName = "GENERAL.CREATED_AT";
                    width = 9;
                    visible = true;
                    cellFilter = "date:'dd/MM/yyyy hh:mm:ss'";
                    break;
                case "MODIFIED_BY":
                    name = columns[i];
                    displayName = "GENERAL.UPDATED_BY";
                    width = 15;
                    visible = true;
                    break;
                case "MODIFIED_DATE":
                    name = columns[i];
                    displayName = "GENERAL.UPDATED_AT";
                    width = 9;
                    visible = true;
                    cellFilter = "date:'dd/MM/yyyy hh:mm:ss'";
                    break;
                case "ACTIVE":
                    name = columns[i];
                    displayName = "GENERAL.ACTIVE";
                    width = 9;
                    visible = true;
                    cellFilter = "YesOrNo";
                    break;
                default:
                    continue;
            }

            const newColumn = {
                name: name,
                displayName: displayName,
                headerCellClass: this.$gridService.highlightFilteredHeader.bind(this.$gridService),
                width: width + "%",
                cellTemplate: cellTemplate,
                cellFilter: cellFilter,
                visible: visible,
                filter: filter,
                searchProps: searchProps,
                filterCellFiltered: filterCellFiltered,
            };
            columnDefs.push(newColumn);
        }
        return columnDefs;
    }

    initScopeFunctions(): void {
        this.$scope.allowPermission = (role: IUserRolePermission) => {
            role.SELECTED_ALLOW = !role.SELECTED_ALLOW;

            if (role.ACTION.ID === "8") { // Menu
                const uniqueId = role.DESCRIPTION + " - ";
                const subMenuList = this.$scope.menuList.filter(x => x.DESCRIPTION.includes(uniqueId));

                for (const subMenu of subMenuList) {
                    subMenu.SELECTED_ALLOW = role.SELECTED_ALLOW;
                }
            }
        }

        this.$scope.getRouteData = async (role: IUserRolePermission, search?: string) => {
            try {
                if (role && role.CRITERIA && role.CRITERIA.TERM) {
                    role[role.ROLE] = role.CRITERIA.TERM;
                    return;
                }

                let rc = null;
                let routebase: boolean = true;

                const timeout: number = 120000;

                if (role && role.ROUTE) {
                    let from: string = "";

                    if (role.ROUTE.FROM) {
                        routebase = false;
                        from = this.config[role.ROUTE.FROM];
                    }

                    const url: string = from + role.ROUTE.URL;

                    switch (role.ROUTE.METHOD) {
                        case 'GET':
                            {
                                rc = await this.RestService.getObjectAsPromise(url, timeout, null, routebase);
                            }
                            break;
                        case 'POST':
                            {
                                let body: any = {};

                                if (role.ROUTE.BODY) body = JSON.parse(role.ROUTE.BODY);

                                if (search && search.length >= 3) {
                                    body.search = search;
                                    body.name = search;
                                }

                                rc = await this.RestService.newObjectPromise(url, body, timeout, routebase)
                            }
                            break;
                    }

                    let data: SelectorModel[] = (rc && rc.length > 0) ? rc : [];

                    if (data.length === 0) data = (rc && rc.data) ? rc.data : [];

                    if (data.length > 0) {
                        role[role.ROLE] = data.map(x => x);
                        return;
                    }
                }

                role[role.ROLE] = [];
            } catch (ex) {
                this.handleLoadError(`${this.formService.getTranslate("GENERAL.COMMUNICATE_ROUTE_FAIL")} ${JSON.stringify(ex)}`);
            }
        }

        this.$scope.selectAll = (roles: IUserRolePermission[]) => {

            if (roles && roles.length > 0) {
                const selectedOrNot = roles[0].SELECTED_ALLOW;
                for (let role of roles) {
                    role.SELECTED_ALLOW = !selectedOrNot;
                }
            }

        }

        this.$scope.clearAll = (roles: IUserRolePermission[]) => {
            for (let role of roles) {
                role.SELECTED_CRITERIA = null;
            }
        }

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            NAME: null,
            PERMISSION: [],
            CREATED_BY: null,
            CREATED_DATE: null,
            MODIFIED_BY: null,
            MODIFIED_DATE: null,
            ACTIVE: true,
            DOCUMENT_ERROR: null
        }

        this.collapseHeader("collapseAll", "hide");
    }

    private collapseHeader(elementId: string, state?: string): void {

        if (elementId === "collapseAll" || elementId[0] === "collapseAll") {
            this.collapseState = this.collapseState == "hide" ? "show" : "hide";
            $('.toggle-me')["collapse"](state ? state : this.collapseState);
        } else if (elementId != "registerBody") {
            $("#" + elementId)["collapse"](state ? state : 'toggle');
        }

        this.$scope.$applyAsync();
    }

    private getUrlHelper(): string {
        const baseRoute = '/helper';
        const urlHelper = this.config.helperUrl + baseRoute;

        return urlHelper;
    }

    async initDependencies(): Promise<boolean> {
        try {
            const result: Array<any> = await this.$q.all([
                this.getUserRolesList(),
            ]);

            if (!result || result.length === 0) return false;

            this.$scope.userRolesList = result[0].data.data.data;

            for (const role of this.$scope.userRolesList) {
                if (role.ROUTE) {
                    role.CRITERIA = <CRITERIA>{ TERM: null };
                }

                role.SELECTED_ALLOW = false;
                role.SELECTED_CRITERIA = angular.copy(role.CRITERIA);
                role.SELECTED_CRITERIA_ID = angular.copy(role.CRITERIA);
                if (role.SELECTED_CRITERIA) role.SELECTED_CRITERIA.TERM = null;
            }

            // Show only filter roles
            this.$scope.filterList = this.$scope.userRolesList.filter(x => x.ACTION.ID === "7"); // equal FILTER type

            // Show only crud roles
            this.$scope.crudList = this.$scope.userRolesList.filter(x => (x.ACTION.ID !== "8" && x.ACTION.ID !== "7" && x.ACTION.ID !== "0")); // not equal MENU, FILTER, CUSTOM type

            // Show only routine roles
            this.$scope.routineList = this.$scope.userRolesList.filter(x => x.ACTION.ID === "0"); // equal ROUTINE type

            // Show only manu roles
            this.$scope.menuList = this.$scope.userRolesList.filter(x => x.ACTION.ID === "8"); // equal MENU type

            return true;
        } catch (ex) {
            this.handleLoadError(ex);
            this.handleLoadError(`${this.formService.getTranslate("GENERAL.DEPENDENCY_LIST_FAILED")} ${JSON.stringify(ex)}`);
        }
    }

    // private async getUserRolesList(): Promise<IUserRolePermission[]> {
    //     return this.RestService.getObjectAsPromise("/userRoles/all", 120000);
    // }
    private async getUserRolesList(): Promise<any> { // IMPLEMENT AFTER CARD #1385
        return this.helperService.get("/userRoles/all", null, 120000);
    }

    async register(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.MENU.USER_GROUP")}`;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            await this.initDependencies();
            this.loadPermissionList();

            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")} (${this.$scope.model.NAME})`;
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            await this.initDependencies();
            this.loadPermissionList();

            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")} (${this.$scope.model.NAME})`;
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            await this.initDependencies();
            this.loadPermissionList();

            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW")} (${this.$scope.model.NAME})`;

            this.$scope.model.NAME = null;
            await this.clearFields(this.$scope.model);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request() {
        const request = {
            operation: this.$scope.operation,
            data: this.$scope.model,
            timeout: 120000
        };
        return request;
    }

    async save(): Promise<boolean> {
        try {
            this.savePermissionList();
            return true;
        } catch (ex) {
            this.handleLoadError(ex);
        }
        return false;
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance(
                {
                    modalID: this.modalID,
                    integrationId: id,
                    documentErrorList: documentErrorList,
                    fnSync: this.sendSync,
                    fnUpdateIntegrationGrid: this.updateIntegrationGrid,
                    headerText: "Integration Operation/Product"
                }
            );
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private savePermissionList() {
        this.$scope.model.PERMISSION = [];

        for (const role of this.$scope.userRolesList) {
            const permission = <PERMISSION>{};
            permission.ROLE = role.ROLE;
            permission.ALLOW = role.SELECTED_ALLOW;
            let criteriaNameSelectd = {
                TERM: []
            }
            let criteriaIdSelectd = {
                TERM: []
            }
            if (role.SELECTED_CRITERIA && role.SELECTED_CRITERIA.TERM) {
                role.SELECTED_CRITERIA.TERM.map(i => {
                    if (i['ID'] || i['NAME']) {
                        criteriaIdSelectd.TERM.push(i['ID'].toString());
                        criteriaIdSelectd.TERM.push(parseInt(i['ID']));
                        criteriaIdSelectd.TERM.push(i['NAME']);
                    } else criteriaNameSelectd.TERM.push(i);
                    if (i || i['NAME'] || i['ID']) criteriaNameSelectd.TERM.push({
                        NAME: i['NAME'],
                        ID: i['ID']
                    });
                    else criteriaNameSelectd.TERM.push(i);
                });
                permission.CRITERIA = criteriaIdSelectd;
                permission.CRITERIA_DESCRIPTION = criteriaNameSelectd;

            } else {
                permission.CRITERIA = null;
                permission.CRITERIA_DESCRIPTION = null;

            }

            this.$scope.model.PERMISSION.push(permission);
        }
    }

    private loadPermissionList() {
        if (this.$scope.model.PERMISSION && this.$scope.model.PERMISSION.length > 0) {
            for (const permission of this.$scope.model.PERMISSION) {
                const role = this.$scope.userRolesList.find(x => x.ROLE === permission.ROLE);
                if (role) {
                    role.SELECTED_ALLOW = permission.ALLOW;
                    role.SELECTED_CRITERIA = permission.CRITERIA_DESCRIPTION;
                    role.SELECTED_CRITERIA_ID = permission.CRITERIA;
                }
            }
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: "NAME",
                LABEL: "GENERAL.NAME"
            },
            {
                PROPERTY: "PERMISSION",
                LABEL: "REGISTRATION.ACCESS_GROUP"
            },
            {
                PROPERTY: "ROLE",
                LABEL: "REGISTRATION.RESPONSIBLE_PAPER"
            },
            {
                PROPERTY: "ALLOW",
                LABEL: "Concedido"
            },
            {
                PROPERTY: "CREATED_BY",
                LABEL: "GENERAL.CREATED_BY"
            },
            {
                PROPERTY: "CREATED_DATE",
                LABEL: "GENERAL.CREATED_AT"
            },
            {
                PROPERTY: "MODIFIED_BY",
                LABEL: "GENERAL.UPDATED_BY"
            },
            {
                PROPERTY: "MODIFIED_DATE",
                LABEL: "GENERAL.UPDATED_BY"
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENGERAL.ACTIVE'
            }
        ];
        return props;
    }

    private async getUserGroupById(id: number) {
        if (!id) throw new Error(`${this.formService.getTranslate("GENERAL.ID_IS_NULL")}`);
        this.formService.block();
        try {
            const rc = await this.helperService.get(`/userGroup/id/${id}`, null, 30000);
            return rc.data.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private sendSync = async (id: number): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                const rc = await this.dataOperationalService.post(`/sync/userGroup`, { "id": [id] }, 120000);
                if (rc && rc.data && rc.data.data && rc.status == 200) success = true;
            }
        } catch (ex) {
            this.formService.handleError(`${this.formService.getTranslate("GENERAL.ERROR_SENDING_REQUEST")}`);
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const legalPersonData = await this.getUserGroupById(id);
                    if (row && legalPersonData && legalPersonData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = legalPersonData.DOCUMENT_ERROR;
                        documentError = legalPersonData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError(`${this.formService.getTranslate("GENERAL.ERROR_DURING_REQUEST")}`);
        } finally {
            return documentError;
        }
    }
}

import { IScopeUser } from "../interface/ICellParameterization";
import { IRestService } from "@services/RestService";
import { CellGroupUserModel } from "@models/interface/common/CellModel";
import * as angular from 'angular';


UserSelectController.$inject = ['$scope', 'RestService', 'blockUI'];
export function UserSelectController($scope: IScopeUser, RestService: IRestService, blockUI: ng.blockUI.BlockUIService) {

    //HOOKS
    this.$onInit = function () {
        $scope.load();
    }

    this.$onDestroy = function () { }

    //CRUD
    $scope.remove = (user: CellGroupUserModel, users: Array<CellGroupUserModel>) => {
        const results = users.filter(item => item.USER_REFERENCE._id === user.USER_REFERENCE._id);
        if (angular.isArray(results)) {
            users.splice(users.indexOf(results[0]), 1);
            $scope.list.push(results[0]);
        }
    }

    $scope.add = (user: CellGroupUserModel, users: Array<CellGroupUserModel>): void => {

        if (user && angular.isArray(users)) {
            user.SINCE = new Date();
            users.push(user);
        }

    }

    //DOM
    $scope.load = (): void => {
        $scope.list = angular.copy($scope.$parent.loadUsers());
    }



}
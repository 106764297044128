import * as Address from '../../communication/Address';
import * as angular from 'angular';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { IColumnDef, IGridOptions } from "ui-grid";
import { IGridFormServiceScope, GridFormService, IGridFormController } from "@services/GridFormService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IModalService, IModalOptions, IOfferWizardModalParams } from '@services/ModalService';
import { IRestService } from "@services/RestService";
import { DataProductService } from '@services/DataProductService';
import { ProductService } from '@services/ProductService';
import { IDownloadParamsReturn } from '@services/DownloadFilesGenericService';
import { DataOperationalService } from "@services/DataOperationalService";
import { FormService2, IFormServiceScope } from '@services/FormService2';
import { ISessionService } from '@services/SessionService';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { ITariffFreightExchangeData } from '@models/interface/product/TariffFreightModel';
import { ITariffDetDemExchangeData } from '@models/interface/product/TariffDetDemModel';
import { IFormatedDataTariff, IOfferTariffFiles, IOfferVigenceAndTariff } from '@models/interface/product/Offer';
import { IUploadItem } from '@models/interface/common/IMonacoUpload';
import { IDocumentError } from '@models/interface/common/IDocumentError';
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { IOfferExchangeData, IOfferModel, IOfferGridModel, IQtdUnity, IFormatedData, IOfferSummaryValidationResult } from "../model/OfferModel";
import { EOfferFormatedDataTypeId, EDirectionId, EOperation, EProductId, ETariffTransactionNotificationTypeId, ETariffTransactionNotificationtSituationId, ETariffType } from "@enums/GenericData";
import { IFloatingMenu, IFloatingOption } from "../../common/interface/IFloatingMenu";
import { BrowserTitle } from '../../common/BrowserTitle';
import { SelectorModel } from '../../common/model/SelectorModel';
import { ILinkParameter, IProcessParameter } from '../../common/model/ModelParameter';
import { ITariffLocalExchangeData } from '../../product/model/TariffLocalModel';
import { ITariffDomesticExchangeData } from '../../product/model/TariffDomesticModel';
import { ValidateUtil } from '../../common/util/ValidateUtil';
import { SSEService } from '../../app/services/SSEService';
import { IProcessValidityDate } from '@models/interface/product/OfferCompiled';
import { IProductConfigurationModel } from '@models/interface/product/ProductConfigurationModel';
import { HelperService } from "@services/HelperService";
import { ITableOptions } from "../../app/directives/monaco-data-table";
import { ITariffTransactionNotification } from 'src/ts/product/model/TariffTransactionNotificationModel';
import { OfferChargeHelperController } from '../../common/OfferChargeHelperController';

export interface IDateOptionsAccountRequest {
    formatYear: string
    maxDate: Date
    minDate: Date
    startingDay: number
}
export interface IOfferProcessModal extends angular.IScope {
    offerProcess: IOfferGridModel;
    processList: IProcessValidityDate[];
    dateOptionsAccountRequest: IDateOptionsAccountRequest;
    productConfigurationList: IProductConfigurationModel;

    refreshModal: (id: number) => void;
    goToOfferProcess: (processNumber: string) => void;
}

export interface IOfferProcess {
    PROCESS_NUMBER: string;
    SITUATION: IProcessSituation;
    FORWARDING_SITUATION: IForwardingSituation;
}

export interface IForwardingSituation {
    SITUATION: IProcessSituation;
    DATE: Date;
}

export interface IProcessSituation {
    NAME: string;
}

interface IRepositionPanel {
    panelId: string;
    panelElement: JQLite;
    collapseId: string;
    collapseElement: JQLite;
}

interface IOfferUpdateConcatenatedResult {
    CONCATENATED: string;
    CONCATENATED_COMPLEMENT: string;
}

interface ICollapseState {
    panel: string;
    released: boolean;
    nextState: string;
}

interface IVigenceModalScope {
    formatedData: IFormatedData;
    oldFormatedData: IFormatedData;
    hasInlandRoute: boolean;
    hasFreightRoute: boolean;
    applyVigenceModal: () => Promise<void>;
    closeVigenceModal: () => Promise<void>;
    checkDateValidity: (initialDate: Date, finalDate: Date) => void;
    viewLogTariff: () => void;
}

interface ITariffFilesModalScope {
    tariffFiles: IOfferTariffFiles;
    closeTariffFilesModal: () => Promise<void>;
    downloadFile: (file: IUploadItem) => Promise<void>;
}
interface ITariffTransactionNotificationModalScope extends IFormServiceScope {
    tariffTransactionNotification: ITariffTransactionNotification[];
    closeTariffTransactionNotificationModal: () => Promise<void>;
    approveTariffTransactionNotification: (notification: ITariffTransactionNotification) => Promise<void>;
    viewLogTariffTransactionNotification: (id: string) => void;
    tariffTransactionTableOptions: ITableOptions;

}

interface IAllValidationResult {
    ID: number;
    OFFER: IValidationResult;
    EVENT: IValidationResult;
    CHARGES: IAllValidationResultCharge[];
}

interface IAllValidationResultCharge {
    PAYMENT_CHARGE: IValidationResult;
    RECEIVING_CHARGE: IValidationResult;
}

interface IValidationResult {
    HAS_ERROR: boolean;
    HAS_WARNING: boolean;
    ERROR_REASONS: IValidationResultErrorReasons[];
    WARNING_REASONS: IValidationResultErrorReasons[];
}

interface IValidationResultErrorReasons {
    VALIDATION_RESULT_ERRO: SelectorModel;
    VALIDATION_RESULT_REASON: SelectorModel;
}

export const enum ECollapseState {
    VIEW_OFFER = "viewOffer",
    EDIT_OFFER = "editOffer",
    CANCEL_OFFER = "cancelOffer",
    MAIN = "mainRow",
    CARGO = "cargoRow",
    EVENTS = "eventsRow",
    CHARGES = "chargesRow",
    CHARGES_OUT_DATE = "chargesOutDateRow",
    MANAGEMENT = "managementRow",
    REFERENCE = "referencesRow",
    COMMUNICATION = "communicationRow"
}

interface IReasonApproveModal {
    REASON_OBSERVATION: string;
    CHANGE_REASON: SelectorModel;
}

export interface IOfferScope extends IGridFormServiceScope {
    formName: string;
    showOfferForm: boolean;
    gridOptions: IGridOptions;
    modalOptions: IModalOptions;
    model: IOfferModel;
    currentUserHasNewAlternativePermission: boolean;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    sessionService: ISessionService;
    formService: FormService2;
    modalService: IModalService;
    dataProductService: DataProductService;
    productService: ProductService;
    dataOperationalService: DataOperationalService;
    menuFloating: IFloatingMenu;
    equipSpecList: SelectorModel[];
    typePaymentList: SelectorModel[];
    currencyList: SelectorModel[];
    transactionList: SelectorModel[];
    specificityList: SelectorModel[];
    messageGroupList: SelectorModel[];
    reasonsForChangeNotification: SelectorModel[];
    auditedReason: IReasonApproveModal;
    productUrl: string;
    tariffFreightId: string;
    tariffLocalIdPO: string;
    tariffLocalIdPD: string;
    tariffLocalIdRO: string;
    tariffLocalIdRD: string;
    lastScroll: number;
    collapseState: ICollapseState;
    allValidationResult: IAllValidationResult;
    lastOfferSelected: IOfferGridModel;
    user: any;
    isMaritimeService: boolean;
    isRoadOffer: boolean;
    transactionSelected: ITariffTransactionNotification;
    tariffTransactionNotification: ITariffTransactionNotification;
    numberOfAutoRatingErrors: number;

    editOffer: (offer: IOfferGridModel) => Promise<void>;
    viewOffer: (offer: IOfferGridModel) => Promise<void>;
    consistencyValidateOffer: (offerId: number, offer?: IOfferGridModel, loadDetails?: boolean) => Promise<void>;
    viewOfferConsistencyValidateDetails: (offer: IOfferGridModel) => Promise<void>;
    replicateOfferFromModel: (offerId: number) => Promise<void>;
    newAlternative: (offerId: number, isCombined: boolean) => Promise<void>;
    viewOfferStatusLog: () => Promise<void>;
    generateFile: () => void;
    releaseCollapse: (panel?: string) => void;
    hasInvalidRequiredElements: (elementId: string) => boolean;
    hasChanges: (newObj: Object, oldObj: Object, propertiesToIgnore?: string[]) => boolean;
    modalSaveConfirmation: (headerText: string, closeButtonText: string) => Promise<boolean>;
    collapseHeader: (elementId: string, state?: string) => void;
    viewLog: () => void;
    openFormatedData: () => void;
    openOfferProcess: (offer: IOfferGridModel) => Promise<void>;
    openTariffFiles: () => void;
    openTariffTransactionNotification: () => Promise<void>;
    viewLogTariffTransactionNotification: (id: string) => Promise<void>;
    openConcatenatedModal: () => void;
    repositionPanels: (currentPanelId: string, isOpenning?: boolean) => void;
    buildGridConsistencyTooltip: (summaryValidationResult: IOfferSummaryValidationResult) => string;
    getCurrencyListByName: (search: string) => Promise<void>;
    goToTariff: (type: SelectorModel, tariffId: number, tariffContractId: number, tariffFormatedData: IFormatedDataTariff[]) => void;
    goToTariffRoutes: (type: SelectorModel, tariffId: number) => void;
    goToOpportunity: (id: number) => void;
    goToOriginalOffer: (id: number) => void;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
    getCONCAT: (value: any[], customProp?: string, objToReturn?: string, returnUnique?: boolean, considerMultiline?: boolean, customSeparator?: string, limitCharacter?: boolean) => string;
}

export class OfferRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IOfferScope;
    private $q: ng.IQService;
    private $sce: angular.ISCEService;
    private $compile: angular.ICompileService;
    private $timeout: ng.ITimeoutService;
    private $filter: ng.FilterFactory;
    private restService: IRestService;
    private lastGridOffer: IOfferGridModel;
    private modalFormatedDataId: number;
    private modalIntegrationId: number;
    private modalTariffFilesId: number;
    private modalTariffTransactionId: number;
    private modalApproveNotificationReasonId: number;
    private downloadRoute: string;
    private SSEService: SSEService;
    private helperService: HelperService;
    private offerChargeHelper: OfferChargeHelperController = null;

    constructor($injector: ng.Injectable<any>, $scope: IOfferScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$sce = $injector.get('$sce');
        this.$compile = $injector.get('$compile');
        this.$timeout = $injector.get('$timeout');
        this.$filter = $injector.get('$filter');
        this.restService = $injector.get('RestService');
        this.modalFormatedDataId = 0;
        this.modalTariffFilesId = 0;
        this.$scope.formService = this.formService;
        this.$scope.modalService = $injector.get('ModalService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.$scope.dataProductService = $injector.get('DataProductService');
        this.$scope.productService = $injector.get('ProductService');
        this.$scope.template = 'gridTemplate';
        this.$scope.collapseState = { panel: null, released: true, nextState: null };
        this.$scope.dataOperationalService = $injector.get('DataOperationalService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.helperService = $injector.get('HelperService');
        this.$scope.numberOfAutoRatingErrors = 0;

        if (this.config.environment === 'prod') {
            this.downloadRoute = `${Address.monacoAddressProd.FIS}/api/v1/fis/filesGeneric/download`;
        } else if (this.config.environment === 'qa') {
            this.downloadRoute = `${Address.monacoAddressQa.FIS}/api/v1/fis/filesGeneric/download`;
        } else { //dev
            this.downloadRoute = `${Address.monacoAddressLocal.FIS}/api/v1/fis/filesGeneric/download`;
        }
    }

    async $onInit(): Promise<void> {
        try {
            this.$scope.formName = 'GRID_OFFER';
            this.$scope.productUrl = this.getUrlProduct();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.gridService.$baseUrl = this.$scope.productUrl;
            this.$rootScope.setBreadCrumb("BASIC_DATA.OFFER", () => { this.newOffer(); });
            this.initForm(this, 'form', 'offer', 'BASIC_DATA.OFFER', false);
            this.$scope.currentUserHasNewAlternativePermission = await this.permissionService.isRoleAllowed("OFFERNEWALTERNATIVEGENERATION");
            await this.initGrid(this.$scope.formName, '/offer/list', true, true, 120000, true, true);
            await this.initDependencies();
            const sessionParameter = this.gridService.$sessionParameter;
            if (sessionParameter && sessionParameter.data) await this.callSessionFunctions(sessionParameter.data);
            this.offerChargeHelper = new OfferChargeHelperController(this.$injector, this.$scope);

            this.SSEService.closeEvents();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        this.$rootScope.clearBreadCrumb();
        super.$onDestroy();
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            ID_ORIGINAL_OFFER: null,
            ACTIVE: true,
            PRODUCT: null,
            TYPE_CARGO: null,
            ACCOUNT: null,
            OFFER_SITUATION: null,
            CONCATENATED: null,
            CONCATENATED_COMPLEMENT: null,
            MOVE_TYPE: null,
            FORMATED_DATA: null,
            ID_PARAMS_PROFIT_SHARE: null,
            OFFER_TYPE: null,
            ID_NEW_FREIGHT_CONTRACT: null,
            ID_NEW_FREIGHT_CONTRACT_RECEIVING: null,
            ID_TARIFF_FREIGHT_CONTRACT: null,
            ID_TARIFF_FREIGHT_CONTRACT_RECEIVING: null,
            DOCUMENT_ERROR: null,
            SUMMARY_VALIDATION_RESULT: null,
            OPPORTUNITY: null,
            PROCESS_TYPE: null,
            COMMODITY_INVOICE_VALUE: null,
            IS_COMBINED: null,
            PROCESS_DEADLINE_FREIGHT_ROUTES: null,
            PROCESS_DEADLINE_INLAND_ROUTES: null,
            PROCESS_DEADLINE_TARIFF_LOCAL: null
        },
            this.$scope.numberOfAutoRatingErrors = 10;
    }

    initScopeFunctions(): void {
        this.$scope.releaseCollapse = (panel?: string) => {
            this.releaseCollapse(panel);
        }

        this.$scope.editOffer = async (offer: IOfferGridModel): Promise<void> => {
            this.$scope.isMaritimeService = offer && offer.PRODUCT && (offer.PRODUCT.ID == EProductId.MARITIME_EXPORT || offer.PRODUCT.ID == EProductId.MARITIME_IMPORT);
            this.$scope.isRoadOffer = offer && offer.PRODUCT && (offer.PRODUCT.ID == EProductId.ROAD_EXPORT || offer.PRODUCT.ID == EProductId.ROAD_IMPORT || offer.PRODUCT.ID == EProductId.ROAD_NATIONAL);
            this.$scope.operation = "";
            this.SSEService.closeEvents();
            this.setNumberOfAutoRatingErros(offer.ID);

            this.SSEService.setBlockedObject({
                ID: offer.ID,
                NAME: offer.OFFER_CODE_EXHIBITION,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.$scope.formName
            });

            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || offer.ID !== this.$scope.model.ID) this.$scope.viewOffer(offer);
                } else if (this.$scope.operation !== EOperation.EDIT || offer.ID !== this.$scope.model.ID) {
                    BrowserTitle.$id = this.$scope.model.CONCATENATED;
                    this.$scope.operation = EOperation.EDIT;
                    this.$scope.disableElements(false);
                    this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")}`;
                    this.viewOffer(offer);
                }
            };
        }

        this.$scope.viewOffer = async (offer: IOfferGridModel): Promise<void> => {
            this.$scope.isMaritimeService = offer && offer.PRODUCT && (offer.PRODUCT.ID == EProductId.MARITIME_EXPORT || offer.PRODUCT.ID == EProductId.MARITIME_IMPORT);
            BrowserTitle.$id = this.$scope.model.CONCATENATED;
            this.$scope.operation = EOperation.VIEW;
            this.$scope.disableElements(true);
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")}`;
            this.viewOffer(offer);
            this.SSEService.closeEvents();
        }

        this.$scope.consistencyValidateOffer = async (offerId: number, offer?: IOfferGridModel, loadDetails?: boolean): Promise<void> => {
            this.consistencyValidateOffer(offerId, offer, loadDetails);
        }

        this.$scope.viewOfferConsistencyValidateDetails = async (offer: IOfferGridModel): Promise<void> => {
            this.viewOfferConsistencyValidateDetails(offer);
        }

        this.$scope.viewOfferStatusLog = async (): Promise<void> => {
            this.viewOfferStatusLog();
        }

        this.$scope.generateFile = () => {
            this.generateFile();
        }

        this.$scope.viewLog = () => {
            this.viewLog("", "1");
            this.SSEService.closeEvents();
        }

        this.$scope.openFormatedData = () => {
            this.openFormatedData();
        }

        this.$scope.openOfferProcess = async (offer: IOfferGridModel): Promise<void> => {
            this.openOfferProcess(offer);
        }

        this.$scope.openTariffFiles = () => {
            this.openTariffFiles();
        }

        this.$scope.openTariffTransactionNotification = async () => {
            await this.openTariffTransactionNotification();
        }

        this.$scope.viewLogTariffTransactionNotification = async (id: string) => {
            await this.viewLogTariffTransactionNotification(id);
        }

        this.$scope.hasInvalidRequiredElements = (elementId: string) => {
            return this.hasInvalidRequiredElements(elementId);
        }

        this.$scope.hasChanges = (newObj: Object, oldObj: Object, propertiesToIgnore?: string[]) => {
            if (propertiesToIgnore) {
                const newAux = newObj ? angular.copy(newObj) : null;
                const oldAux = oldObj ? angular.copy(oldObj) : null;
                for (const property of propertiesToIgnore) {
                    if (newAux && newAux[property]) {
                        delete newAux[property];
                    }
                    if (oldAux && oldAux[property]) {
                        delete oldAux[property];
                    }
                }
                return !angular.equals(JSON.stringify(newAux), JSON.stringify(oldAux));
            }
            return !angular.equals(newObj, oldObj);
        }

        this.$scope.modalSaveConfirmation = async (headerText: string, closeButtonText: string) => {
            return this.modalSaveConfirmation(headerText, closeButtonText);
        }

        this.$scope.openConcatenatedModal = () => {
            this.openConcatenatedModal();
        }

        this.$scope.repositionPanels = (currentPanelId: string, isOpenning?: boolean) => {
            this.repositionPanels(currentPanelId, isOpenning);
        }

        this.$scope.buildGridConsistencyTooltip = (summaryValidationResult: IOfferSummaryValidationResult) => {
            return this.tooltip(summaryValidationResult);
        }

        this.$scope.getCurrencyListByName = async (search: string): Promise<void> => {
            let currencyList: SelectorModel[] = [];
            if (search && search.length >= 2) currencyList = await this.getCurrencyListByName(search);
            this.$scope.currencyList = currencyList;
        }

        this.$scope.goToTariff = async (type: SelectorModel, tariffId: number, tariffContractId: number, tariffFormatedData: IFormatedDataTariff[]) => {
            if (!type) return;

            if (type.ID == EOfferFormatedDataTypeId.TARIFF_FREIGHT) {
                if (tariffFormatedData && tariffFormatedData.length) {
                    const tariffFreight = tariffFormatedData.find(tariff => tariff.TYPE && tariff.TYPE.ID == EOfferFormatedDataTypeId.TARIFF_FREIGHT);
                    if (tariffFreight) tariffId = tariffFreight.ID;
                }
                const endpoint = `${this.config.productUrl}/product/tariffFreight/getCacheById/${tariffContractId}`;
                this.$scope.sessionService.openTabByValidity(endpoint, 'app.product.tariffFreight', <ILinkParameter>{ ID: tariffContractId ? tariffContractId.toString() : tariffContractId }, <ITariffFreightExchangeData>{ OPERATION: "edit", ID: tariffContractId ? tariffContractId.toString() : null, ID_TARIFF: tariffId });
            } else if (type.ID == EOfferFormatedDataTypeId.TARIFF_LOCAL) {
                const endpoint = `${this.config.productUrl}/product/tariffLocal/getCacheById/${tariffId}`;
                this.$scope.sessionService.openTabByValidity(endpoint, "app.product.tariffLocal", <ILinkParameter>{ ID: tariffId ? tariffId.toString() : tariffId }, <ITariffLocalExchangeData>{ OPERATION: "edit", ID: tariffId.toString() });
            } else if (type.ID == EOfferFormatedDataTypeId.TARIFF_DOMESTIC) {
                const endpoint = `${this.config.productUrl}/product/tariffDomestic/getCacheById/${tariffId}`;
                this.$scope.sessionService.openTabByValidity(endpoint, "app.product.tariffDomestic", <ILinkParameter>{ ID: tariffId ? tariffId.toString() : tariffId }, <ITariffDomesticExchangeData>{ OPERATION: "edit", ID: tariffId.toString() });
            } else if (type.ID == EOfferFormatedDataTypeId.TARIFF_DET_DEM) {
                const endpoint = `${this.config.productUrl}/product/tariffDetDem/getCacheById/${tariffId}`;
                this.$scope.sessionService.openTabByValidity(endpoint, "app.product.tariffDetDem", <ILinkParameter>{ ID: tariffId ? tariffId.toString() : tariffId }, <ITariffDetDemExchangeData>{ OPERATION: "edit", ID: tariffId.toString() });
            }
        }

        this.$scope.goToTariffRoutes = async (type: SelectorModel, tariffId: number, buying?: boolean, formatedData?: IFormatedData) => {
            if (!type) return;
            if (type.ID === ETariffType.Local) {
                const endpoint = `${this.$scope.productUrl}/tariffLocal/getCacheById/${tariffId}`;
                this.$scope.sessionService.openTabByValidity(endpoint, "app.product.tariffLocal", <ILinkParameter>{ ID: tariffId ? tariffId.toString() : tariffId }, <ITariffLocalExchangeData>{ OPERATION: EOperation.VIEW, ID: tariffId ? tariffId.toString() : tariffId });
            } else if (type.ID === ETariffType.Inland) {
                this.$scope.sessionService.openTab('app.product.inlandRoutes', <ILinkParameter>{ ID: tariffId ? tariffId.toString() : tariffId }, <ITariffLocalExchangeData>{ OPERATION: EOperation.VIEW, ID: tariffId ? tariffId.toString() : tariffId });
            } else if (type.ID === ETariffType.Route || type.ID == ETariffType.DetDemRoute) {
                if(buying == null ){
                    this.$scope.sessionService.openTab('app.product.freightRoutes', <ILinkParameter>{ ID: tariffId ? tariffId.toString() : tariffId }, <ITariffFreightExchangeData>{ OPERATION: EOperation.VIEW, ID: tariffId ? tariffId.toString() : tariffId });
                }else if (buying) {
                    const buyingTariff = formatedData.TARIFF_PAYMENT.find(x => x.TYPE.ID == type.ID)
                    this.$scope.sessionService.openTab('app.product.freightRoutes', <ILinkParameter>{ ID: buyingTariff.ID ? buyingTariff.ID.toString() : buyingTariff.ID }, <ITariffFreightExchangeData>{ OPERATION: EOperation.VIEW, ID: buyingTariff.ID ? buyingTariff.ID.toString() : buyingTariff.ID });
                } else if(!buying){
                    const receivingTariff = formatedData.TARIFF_RECEIVING.find(x => x.TYPE.ID == type.ID)
                    this.$scope.sessionService.openTab('app.product.freightRoutes', <ILinkParameter>{ ID: receivingTariff.ID ? receivingTariff.ID.toString() : receivingTariff.ID }, <ITariffFreightExchangeData>{ OPERATION: EOperation.VIEW, ID: receivingTariff.ID ? receivingTariff.ID.toString() : receivingTariff.ID });
                }
                
            } else if (type.ID === ETariffType.Complementary) {
                this.$scope.sessionService.openTab('app.product.tariffComplementary', <ILinkParameter>{ ID: tariffId ? tariffId.toString() : tariffId }, <ITariffFreightExchangeData>{ OPERATION: EOperation.VIEW, ID: tariffId ? tariffId.toString() : tariffId });
            } else if (type.ID == ETariffType.DetDem) {
                const endpoint = `${this.config.productUrl}/product/tariffDetDem/getCacheById/${tariffId}`;
                this.$scope.sessionService.openTabByValidity(endpoint, "app.product.tariffDetDem", <ILinkParameter>{ ID: tariffId ? tariffId.toString() : tariffId }, <ITariffDetDemExchangeData>{ OPERATION: "edit", ID: tariffId.toString() });
            } else if (type.ID == ETariffType.DetDemContract) {
                this.$scope.sessionService.openTab('app.product.newFreightContract', <ILinkParameter>{ ID: tariffId ? tariffId.toString() : tariffId }, <ITariffFreightExchangeData>{ OPERATION: EOperation.VIEW, ID: tariffId ? tariffId.toString() : tariffId });
            }
        }

        this.$scope.goToOpportunity = (id: number) => {
            try {
                if (!id) throw Error('Missing id in goToOpportunity');
                this.$scope.sessionService.openTab("app.commercial.preoffer", <ILinkParameter>{ ID: id.toString() });
            } catch (ex) {
                this.formService.handleError(ex);
            }
        }

        this.$scope.goToOriginalOffer = (id: number) => {
            try {
                if (!id) throw Error('Missing id in goToOriginalOffer');
                this.$scope.sessionService.openTab("app.commercial.offer", <ILinkParameter>{ ID: id.toString() });
            } catch (ex) {
                this.formService.handleError(ex);
            }
        }

        this.$scope.replicateOfferFromModel = async (offerId: number): Promise<void> => {
            this.replicateOfferFromModel(offerId);
        }

        this.$scope.newAlternative = async (offerId: number, isCombined: boolean): Promise<void> => {
            this.newAlternative(offerId, isCombined);
        }

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }
    }

    async initDependencies(): Promise<any> {
        const self: OfferRegisterController = this;
        // on change released            
        self.$scope.$watch('collapseState.released', function (released: boolean) {
            if (released && self.$scope.collapseState.nextState) self.releaseCollapse(self.$scope.collapseState.nextState);
        });

        // on change active
        self.$scope.$watch('model.ACTIVE', function (newValue: boolean, oldValue: boolean) {
            if (angular.isDefined(newValue) && newValue != oldValue) self.updateActiveInactive(newValue);
        });

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericValue("equip_spec"),
                self.getGenericValue("type_payment"),
                self.getGenericValue("transaction"),
                self.getGenericValue("specificity"),
                self.getGenericValue("message_group"),
            ]).then(async (result: any) => {
                self.$scope.equipSpecList = result[0];
                self.$scope.typePaymentList = result[1] ? result[1].map(obj => { return { ID: obj.ID, NAME: obj.NAME, CODE: null } }) : [];
                self.$scope.transactionList = result[2];
                self.$scope.specificityList = result[3];
                self.$scope.messageGroupList = result[4];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    initGridColumns(columns: string[]): IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<a ng-click="grid.appScope.viewOffer(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-disabled="!row.entity.IS_COMBINED" ng-click="row.entity.IS_COMBINED ? grid.appScope.editOffer(row.entity) : ''" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true"><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const consistency = `<a ng-click="grid.appScope.consistencyValidateOffer(row.entity.ID, row.entity)" ng-class="{'text-green': !row.entity.SUMMARY_VALIDATION_RESULT || (row.entity.SUMMARY_VALIDATION_RESULT && !row.entity.SUMMARY_VALIDATION_RESULT.HAS_ERROR && !row.entity.SUMMARY_VALIDATION_RESULT.HAS_WARNING), 'text-danger': row.entity.SUMMARY_VALIDATION_RESULT.HAS_ERROR, 'text-warning': row.entity.SUMMARY_VALIDATION_RESULT.HAS_WARNING}" tooltip-placement="auto top" uib-tooltip-html="grid.appScope.buildGridConsistencyTooltip(row.entity.SUMMARY_VALIDATION_RESULT)" tooltip-append-to-body="true"><i class="fa icon" ng-class="row.entity.SUMMARY_VALIDATION_RESULT && (row.entity.SUMMARY_VALIDATION_RESULT.HAS_ERROR || row.entity.SUMMARY_VALIDATION_RESULT.HAS_WARNING) ? 'fa-exclamation-triangle' : 'fa-check'"></i></a>&nbsp;&nbsp;`;
        const replicateOfferModel = `<a ng-click="row.entity.UNLOCK && grid.appScope.replicateOfferFromModel(row.entity.ID)" ng-disabled="!row.entity.UNLOCK" class="text-green" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.USE_AS_MODEL' | translate }}" tooltip-append-to-body="true"><i class="fa fa-retweet icon"></i></a>&nbsp;&nbsp;`;
        const newAlternative = `<a ng-click="row.entity.UNLOCK && grid.appScope.currentUserHasNewAlternativePermission && grid.appScope.newAlternative(row.entity.ID, row.entity.IS_COMBINED)" ng-disabled="!row.entity.UNLOCK || !grid.appScope.currentUserHasNewAlternativePermission" class="text-info" tooltip-placement="auto top" uib-tooltip="${this.$scope.currentUserHasNewAlternativePermission ? "{{'PRODUCT.GENERATE_NEW_ALTERNATIVE' | translate }}" : "{{'PRODUCT.USER_NO_PERMISSION_NEW_ALTERNATIVE' | translate }}"}" tooltip-append-to-body="true"><i class="fa fa-random icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="(row.entity.DOCUMENT_ERROR && row.entity.DOCUMENT_ERROR.length) ? 'text-danger' : 'text-green'" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;`;
        const process = `<a ng-disabled="!row.entity.OFFER_USED_PROCESS.length"><btn ng-click="row.entity.OFFER_USED_PROCESS.length ? grid.appScope.openOfferProcess(row.entity) : ''" class="text-info" ng-class="{'text-muted': !row.entity.OFFER_USED_PROCESS.length}" tooltip-placement="auto top" uib-tooltip="{{row.entity.OFFER_USED_PROCESS.length ? 'REGISTRATION.VIEW_FILES' : 'PRODUCT.NO_PROCESS_LIKED_TO_OFFER' | translate }}" tooltip-append-to-body="true"><i class="fa fa-list-alt icon"></i></btn></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: 150,
            cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${consistency} ${replicateOfferModel} ${newAlternative} ${modalIntegration} ${process}</div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };

        gridColumns.addColumn(colActions);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colOfferCode: IMonacoColumnDef = { name: "OFFER_CODE_EXHIBITION", displayName: "BASIC_DATA.OFFER", width: 150 };
            const colOfferLink: IMonacoColumnDef = { name: "OFFER_CODE_LINK", displayName: "BASIC_DATA.OFFER_LINK", width: 150 };
            const colCreated: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: "datetimeformated" };
            const colUpdated: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: "datetimeformated" };
            const colConcatenated: IMonacoColumnDef = { name: "CONCATENATED", displayName: "GENERAL.CONCATENATED", width: 250 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 100 };
            const colTypeCargo: IMonacoColumnDef = { name: "TYPE_CARGO.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 150 };
            const colOfferCargoCbm: IMonacoColumnDef = { name: "OFFER_CARGO.CUBIC_WEIGHT", displayName: "GENERAL.CUBIC_METER", width: 150, cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.OFFER_CARGO.CUBIC_WEIGHT_FORMATTED | number: 3}}</div>' };
            const colGrossWeightTotal: IMonacoColumnDef = { name: "OFFER_CARGO.GROSS_WEIGHT", displayName: "GENERAL.GROSS_WEIGHT_KG", width: 150, cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.OFFER_CARGO.GROSS_WEIGHT | number: 3}}</div>' };
            const colOverChargeableWeight: IMonacoColumnDef = { name: "OFFER_CARGO.OVER_CHARGEABLE_WEIGHT", displayName: "BASIC_DATA.OVER_CHARGEABLE_WEIGHT", width: 150, cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.OFFER_CARGO.OVER_CHARGEABLE_WEIGHT | number: 4}}</div>' };
            const colChargeableWeight: IMonacoColumnDef = { name: "OFFER_CARGO.CHARGEABLE_WEIGHT", displayName: "GENERAL.CHARGEABLE_WEIGHT", width: 150, cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.OFFER_CARGO.CHARGEABLE_WEIGHT | number: 4}}</div>' };
            const colOfferType: IMonacoColumnDef = { name: "OFFER_TYPE.NAME", displayName: "GENERAL.TYPE", width: 150 };
            const colIncoterm: IMonacoColumnDef = { name: "INCOTERM.CODE", displayName: "GENERAL.MENU.INCOTERM", width: 150 };
            const colProvider: IMonacoColumnDef = { name: "PROVIDER.NAME", displayName: "BASIC_DATA.PROVIDER", width: 150 };
            const colOfferOpportunityCode: IMonacoColumnDef = { name: "OPPORTUNITY.PREOFFER_CODE", displayName: "BASIC_DATA.PRE_OFFER", width: 150, cellTemplate: `<div ng-show="row.entity.OPPORTUNITY" class="ui-grid-cell-contents" tooltip-placement="auto top" uib-tooltip="Clique para acessar a rota" tooltip-append-to-body="true"><a href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToOpportunity(row.entity.OPPORTUNITY.ID)">{{row.entity.OPPORTUNITY.PREOFFER_CODE}}</a></div>` };
            const colServices: IMonacoColumnDef = { name: "SERVICES_FORMATTED", displayName: "BASIC_DATA.SERVICE", width: 200 };
            const colAccount: IMonacoColumnDef = { name: "ACCOUNT.NAME", displayName: "BASIC_DATA.CLIENT", width: 150 };
            const colAccountFantasyName: IMonacoColumnDef = { name: "ACCOUNT.LEGAL_PERSON.FANTASY_NAME", displayName: "GENERAL.CUSTOMER_COMMERCIAL_NAME", width: 200 };
            const colAccountReqType: IMonacoColumnDef = { name: "ACCOUNT.ACCOUNT_REQUIREMENT.ACCOUNT_TYPE.NAME", displayName: "REGISTRATION.ACCOUNT_TYPE", width: 150 };
            const colAccountReqCorporate: IMonacoColumnDef = { name: "ACCOUNT.ACCOUNT_REQUIREMENT.CORPORATE_BRANCH.NAME", displayName: "BASIC_DATA.BRANCH", width: 150 };
            const colAccountReqExtSale: IMonacoColumnDef = { name: "ACCOUNT.ACCOUNT_REQUIREMENT.SALES_PERSON.NAME", displayName: "BASIC_DATA.SALES_EXECUTIVE", width: 150 };
            const colCommercialUnity: IMonacoColumnDef = { name: "COMMERCIAL_UNITY.NAME", displayName: "BASIC_DATA.COMMERCIAL_BRANCH", width: 150 };
            const colAccountReqIntSale: IMonacoColumnDef = { name: "ACCOUNT.ACCOUNT_REQUIREMENT.INSIDE_SALES_PERSON.NAME", displayName: "BASIC_DATA.INSIDE_SALES", width: 150 };
            const colAccountReqRespon: IMonacoColumnDef = { name: "ACCOUNT.ACCOUNT_REQUIREMENT.RESPONSIBLE_PRODUCT.NAME", displayName: "BASIC_DATA.PRODUCT_RESPONSIBLE", width: 150 };
            const colAccountRequest: IMonacoColumnDef = { name: "ACCOUNT_REQUEST", displayName: "PRODUCT.CLIENT_REQUEST_DATE", width: 150, cellFilter: "simpleDate" };
            const colNetwork: IMonacoColumnDef = { name: "NETWORK.NAME", displayName: "BASIC_DATA.NETWORK", width: 150 };
            const colImporters: IMonacoColumnDef = { name: "IMPORTERS_NAME_FORMATTED", displayName: "BASIC_DATA.CONSIGNEE", width: 200 };
            const colImportersFantasyName: IMonacoColumnDef = { name: "IMPORTERS_FANTASY_NAME_FORMATTED", displayName: "GENERAL.CNEE_COMMERCIAL_NAME", width: 200 };
            const colExporters: IMonacoColumnDef = { name: "EXPORTERS_NAME_FORMATTED", displayName: "BASIC_DATA.SHIPPER", width: 200 };
            const colExportersFantasyName: IMonacoColumnDef = { name: "EXPORTERS_FANTASY_NAME_FORMATTED", displayName: "GENERAL.SHIPPER_COMMERCIAL_NAME", width: 200 };
            const colExtAgent: IMonacoColumnDef = { name: "EXTERNAL_AGENT.NAME", displayName: "BASIC_DATA.OVERSEAS_AGENT", width: 115 };
            const colTransPickup: IMonacoColumnDef = { name: "TRANSPORTER_PICKUP.NAME", displayName: "BASIC_DATA.TRANSPORTER_PICKUP", width: 150 };
            const colTransRecept: IMonacoColumnDef = { name: "TRANSPORTER_PLACE_RECEPT.NAME", displayName: "BASIC_DATA.TRANSPORTER_PLACE_RECEPT", width: 150 };
            const colTransDestination: IMonacoColumnDef = { name: "TRANSPORTER_FINAL_DESTINATION.NAME", displayName: "BASIC_DATA.TRANSPORTER_FINAL_DESTINATION", width: 150 };
            const colTransDelivery: IMonacoColumnDef = { name: "TRANSPORTER_DELIVERY.NAME", displayName: "BASIC_DATA.TRANSPORTER_DELIVERY", width: 150 };
            const colCommodity: IMonacoColumnDef = { name: "REPLICATION_DATA.COMMODITY_SUMMARY.COMMODITY_TEXT", displayName: "GENERAL.COMMODITY", width: 250 };
            const colOrigin: IMonacoColumnDef = { name: "ORIGIN.DISPLAY_NAME", displayName: "BASIC_DATA.ORIGIN", width: 180 };
            const colDestination: IMonacoColumnDef = { name: "DESTINATION.DISPLAY_NAME", displayName: "BASIC_DATA.DESTINATION", width: 180 };
            const colPUP: IMonacoColumnDef = { name: "PUP_REPL.DISPLAY_NAME", displayName: "GENERAL.PICK_UP_CODE", width: 180 };
            const colOTFS: IMonacoColumnDef = { name: "OTFS_REPL.DISPLAY_NAME", displayName: "GENERAL.ORIGIN_TERMINAL_CODE", width: 180 };
            const colPOLAOL: IMonacoColumnDef = { name: "POLAOL_REPL.CODE", displayName: "GENERAL.LOADING_PLACE_CODE", width: 150 };
            const colPODAOD: IMonacoColumnDef = { name: "PODAOD_REPL.CODE", displayName: "GENERAL.UNLOADING_PLACE_CODE", width: 150 };
            const colDTFS: IMonacoColumnDef = { name: "DTFS_REPL.DISPLAY_NAME", displayName: "GENERAL.DESTINATION_TERMINAL_CODE", width: 180 };
            const colPLD: IMonacoColumnDef = { name: "PLD_REPL.DISPLAY_NAME", displayName: "GENERAL.PLACE_OF_DELIVERY_CODE", width: 180 };
            const colMoveType: IMonacoColumnDef = { name: "MOVE_TYPE.CODE", displayName: "GENERAL.MENU.MOVE_TYPE", width: 150 };
            const colValidityEvent: IMonacoColumnDef = { name: "VALIDITY_EVENT.NAME", displayName: "GENERAL.VALIDITY_BASIS", width: 150 };
            const colValidityStart: IMonacoColumnDef = { name: "VALIDITY_START", displayName: "PRODUCT.VALIDITY_START", width: 150, cellFilter: "simpleDate" };
            const colValidityEnd: IMonacoColumnDef = { name: "VALIDITY_END", displayName: "PRODUCT.VALIDITY_END", width: 150, cellFilter: "simpleDate" };
            const colTotalFeet20: IMonacoColumnDef = { name: "REPLICATION_DATA.TOTAL_20_FEET", displayName: "20'", width: 80 };
            const colTotalFeet40: IMonacoColumnDef = { name: "REPLICATION_DATA.TOTAL_40_FEET", displayName: "40'", width: 80 };
            const colTotalTeus: IMonacoColumnDef = { name: "REPLICATION_DATA.TOTAL_TEUS", displayName: "GENERAL.TEUS", width: 80 };
            const colQuantityUnity: IMonacoColumnDef = { name: "QTD_UNITY_FORMATTED", displayName: "GENERAL.QUANTITY", width: 180 };
            const colTariffFreightConcatenated: IMonacoColumnDef = { name: "TARIFF_FREIGHT_CONCATENATED", displayName: "PRODUCT.BUYING_TARIFF", width: 250, cellTemplate: `<div class="ui-grid-cell-contents" tooltip-placement="auto top" uib-tooltip="Clique para acessar a rota" tooltip-append-to-body="true"><a href="javascript:void(0);" style="text-decoration: underline;" ng-click="row.entity.IS_COMBINED ? grid.appScope.goToTariffRoutes({ ID: '${ETariffType.Route}' }, null, true, row.entity.FORMATED_DATA): grid.appScope.goToTariff({ ID: '${EOfferFormatedDataTypeId.TARIFF_FREIGHT}' }, null, row.entity.ID_TARIFF_FREIGHT_CONTRACT, row.entity.FORMATED_DATA.TARIFF_PAYMENT)">{{row.entity.TARIFF_FREIGHT_CONCATENATED}}</a></div>` };
            const colTariffFreightReceivingConcatenated: IMonacoColumnDef = { name: "TARIFF_FREIGHT_RECEIVING_CONCATENATED", displayName: "PRODUCT.SELLING_TARIFF", width: 250, cellTemplate: `<div class="ui-grid-cell-contents" tooltip-placement="auto top" uib-tooltip="Clique para acessar a rota" tooltip-append-to-body="true"><a href="javascript:void(0);" style="text-decoration: underline;" ng-click="row.entity.IS_COMBINED ? grid.appScope.goToTariffRoutes({ ID: '${ETariffType.Route}' }, null, false, row.entity.FORMATED_DATA): grid.appScope.goToTariff({ ID: '${EOfferFormatedDataTypeId.TARIFF_FREIGHT}' }, null, row.entity.ID_TARIFF_FREIGHT_CONTRACT_RECEIVING, row.entity.FORMATED_DATA.TARIFF_RECEIVING)">{{row.entity.TARIFF_FREIGHT_RECEIVING_CONCATENATED}}</a></div>` };
            const colTariffFreightContract: IMonacoColumnDef = { name: "TARIFF_FREIGHT_CONTRACT.NAME", displayName: "PRODUCT.BUYING_CONTRACT", width: 150 };
            const colGpEstimated: IMonacoColumnDef = { name: "INDICATORS.ESTIMATED_GROSS_PROFIT", displayName: "FINANCIAL.ESTIMATED_GROSS_PROFIT", width: 150, cellTemplate: "<div class='grid-padding'>{{row.entity.INDICATORS.ESTIMATED_GROSS_PROFIT | number:2}}</div>" };
            const colEgpType: IMonacoColumnDef = { name: "EGP_TYPE", displayName: "FINANCIAL.CONTRIBUTION_TYPE", width: 150 };
            const colEgpTypeGeneral: IMonacoColumnDef = { name: "EGP_TYPE_GENERAL", displayName: "FINANCIAL.GENERAL_CONTRIBUTION", width: 150, cellTemplate: "<div class='grid-padding'>{{row.entity.EGP_TYPE_GENERAL | number:2}}</div>" };
            const colEGpCtwd: IMonacoColumnDef = { name: "INDICATORS.EGP_TOTAL_CTWD", displayName: "FINANCIAL.CONTRIBUTION_WITHOUT_DOCS", width: 150, cellTemplate: "<div class='grid-padding'>{{row.entity.INDICATORS.EGP_TOTAL_CTWD | number:2}}</div>" };
            const colOfferSituation: IMonacoColumnDef = { name: "OFFER_SITUATION.CURRENT_SITUATION.NAME", displayName: "PRODUCT.OFFER_STATUS", width: 150 };
            const colOfferWarning: IMonacoColumnDef = { name: "SUMMARY_VALIDATION_RESULT.HAS_WARNING", displayName: "REGISTRATION.ALERT", width: 90, cellFilter: "YesOrNo" };
            const colOfferError: IMonacoColumnDef = { name: "SUMMARY_VALIDATION_RESULT.HAS_ERROR", displayName: "REGISTRATION.ERROR", width: 90, cellFilter: "YesOrNo" };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 150, cellFilter: "YesOrNo" };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "GENERAL.ID", width: 80 };
            const colIdOriginalOffer: IMonacoColumnDef = { name: "ID_ORIGINAL_OFFER", displayName: "REGISTRATION.ORIGINAL_OFFER_ID", width: 150 };
            const colClientReference: IMonacoColumnDef = { name: "REPLICATION_DATA.ACCOUNT_REFERENCE.NAME", displayName: "OPERATIONAL.CLIENT_REFERENCE", width: 150 };
            const colUnityNumber: IMonacoColumnDef = { name: "OFFER_CARGO.UN_NUMBER", displayName: "GENERAL.UN_NUMBER", width: 150 };
            const colLoadRef: IMonacoColumnDef = { name: "LOAD_REF", displayName: "BASIC_DATA.LOAD_REF", width: 250, cellFilter: "simpleDate" };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'OFFER_CODE_EXHIBITION':
                        columnDefs.push(colOfferCode);
                        break;
                    case 'OFFER_CODE_LINK':
                        columnDefs.push(colOfferLink);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreated);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdated);
                        break;
                    case 'CONCATENATED':
                        columnDefs.push(colConcatenated);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'TYPE_CARGO':
                        columnDefs.push(colTypeCargo);
                        break;
                    case 'INCOTERM':
                        columnDefs.push(colIncoterm);
                        break;
                    case 'OFFER_CARGO.CUBIC_WEIGHT':
                        columnDefs.push(colOfferCargoCbm)
                        break;
                    case 'OFFER_CARGO.GROSS_WEIGHT':
                        columnDefs.push(colGrossWeightTotal)
                        break;
                    case 'OFFER_CARGO.AIR.OVER_CHARGEABLE_WEIGHT':
                        columnDefs.push(colOverChargeableWeight)
                        break;
                    case 'OFFER_CARGO.AIR.CHARGEABLE_WEIGHT':
                        columnDefs.push(colChargeableWeight)
                        break;
                    case 'OFFER_TYPE':
                        columnDefs.push(colOfferType);
                        break;
                    case 'PROVIDER':
                        columnDefs.push(colProvider);
                        break;
                    case 'SERVICES_FORMATTED':
                        columnDefs.push(colServices);
                        break;
                    case 'ACCOUNT':
                        columnDefs.push(colAccount);
                        break;
                    case 'ACCOUNT.LEGAL_PERSON.FANTASY_NAME':
                        columnDefs.push(colAccountFantasyName);
                        break;
                    case 'ACCOUNT.ACCOUNT_REQUIREMENT.ACCOUNT_TYPE':
                        columnDefs.push(colAccountReqType);
                        break;
                    case 'ACCOUNT.ACCOUNT_REQUIREMENT.CORPORATE_BRANCH':
                        columnDefs.push(colAccountReqCorporate);
                        break;
                    case 'ACCOUNT.ACCOUNT_REQUIREMENT.SALES_PERSON':
                        columnDefs.push(colAccountReqExtSale);
                        break;
                    case 'COMMERCIAL_UNITY':
                        columnDefs.push(colCommercialUnity);
                        break;
                    case 'ACCOUNT.ACCOUNT_REQUIREMENT.INSIDE_SALES_PERSON':
                        columnDefs.push(colAccountReqIntSale);
                        break;
                    case 'ACCOUNT.ACCOUNT_REQUIREMENT.RESPONSIBLE_PRODUCT':
                        columnDefs.push(colAccountReqRespon);
                        break;
                    case 'ACCOUNT_REQUEST':
                        columnDefs.push(colAccountRequest);
                        break;
                    case 'NETWORK':
                        columnDefs.push(colNetwork);
                        break;
                    case 'IMPORTERS_NAME_FORMATTED':
                        columnDefs.push(colImporters);
                        break;
                    case 'IMPORTERS_FANTASY_NAME_FORMATTED':
                        columnDefs.push(colImportersFantasyName);
                        break;
                    case 'EXPORTERS_NAME_FORMATTED':
                        columnDefs.push(colExporters);
                        break;
                    case 'EXPORTERS_FANTASY_NAME_FORMATTED':
                        columnDefs.push(colExportersFantasyName);
                        break;
                    case 'EXTERNAL_AGENT':
                        columnDefs.push(colExtAgent);
                        break;
                    case 'TRANSPORTER_PICKUP':
                        columnDefs.push(colTransPickup);
                        break;
                    case 'TRANSPORTER_PLACE_RECEPT':
                        columnDefs.push(colTransRecept);
                        break;
                    case 'TRANSPORTER_FINAL_DESTINATION':
                        columnDefs.push(colTransDestination);
                        break;
                    case 'TRANSPORTER_DELIVERY':
                        columnDefs.push(colTransDelivery);
                        break;
                    case 'REPLICATION_DATA.COMMODITY_SUMMARY.COMMODITY_TEXT':
                        columnDefs.push(colCommodity);
                        break;
                    case 'ORIGIN':
                        columnDefs.push(colOrigin);
                        break;
                    case 'DESTINATION':
                        columnDefs.push(colDestination);
                        break;
                    case 'PUP_REPL':
                        columnDefs.push(colPUP);
                        break;
                    case 'OTFS_REPL':
                        columnDefs.push(colOTFS);
                        break;
                    case 'POLAOL_REPL':
                        columnDefs.push(colPOLAOL);
                        break;
                    case 'PODAOD_REPL':
                        columnDefs.push(colPODAOD);
                        break;
                    case 'DTFS_REPL':
                        columnDefs.push(colDTFS);
                        break;
                    case 'PLD_REPL':
                        columnDefs.push(colPLD);
                        break;
                    case 'MOVE_TYPE':
                        columnDefs.push(colMoveType);
                        break;
                    case 'VALIDITY_EVENT':
                        columnDefs.push(colValidityEvent);
                        break;
                    case 'VALIDITY_START':
                        columnDefs.push(colValidityStart);
                        break;
                    case 'VALIDITY_END':
                        columnDefs.push(colValidityEnd);
                        break;
                    case 'REPLICATION_DATA.TOTAL_20_FEET':
                        columnDefs.push(colTotalFeet20);
                        break;
                    case 'REPLICATION_DATA.TOTAL_40_FEET':
                        columnDefs.push(colTotalFeet40);
                        break;
                    case 'REPLICATION_DATA.TOTAL_TEUS':
                        columnDefs.push(colTotalTeus);
                        break;
                    case 'QTD_UNITY_FORMATTED':
                        columnDefs.push(colQuantityUnity);
                        break;
                    case 'TARIFF_FREIGHT_CONCATENATED':
                        columnDefs.push(colTariffFreightConcatenated);
                        break;
                    case 'TARIFF_FREIGHT_RECEIVING_CONCATENATED':
                        columnDefs.push(colTariffFreightReceivingConcatenated);
                        break;
                    case 'TARIFF_FREIGHT_CONTRACT':
                        columnDefs.push(colTariffFreightContract);
                        break;
                    case 'INDICATORS.ESTIMATED_GROSS_PROFIT':
                        columnDefs.push(colGpEstimated);
                        break;
                    case 'EGP_TYPE':
                        columnDefs.push(colEgpType);
                        break;
                    case 'EGP_TYPE_GENERAL':
                        columnDefs.push(colEgpTypeGeneral);
                        break;
                    case 'INDICATORS.EGP_TOTAL_CTWD':
                        columnDefs.push(colEGpCtwd);
                        break;
                    case 'OFFER_SITUATION':
                        columnDefs.push(colOfferSituation);
                        break;
                    case 'SUMMARY_VALIDATION_RESULT.HAS_WARNING':
                        columnDefs.push(colOfferWarning);
                        break;
                    case 'SUMMARY_VALIDATION_RESULT.HAS_ERROR':
                        columnDefs.push(colOfferError);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'OPPORTUNITY':
                        columnDefs.push(colOfferOpportunityCode);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'ID_ORIGINAL_OFFER':
                        columnDefs.push(colIdOriginalOffer);
                        break;
                    case 'REPLICATION_DATA.ACCOUNT_REFERENCE.NAME':
                        columnDefs.push(colClientReference);
                        break;
                    case 'OFFER_CARGO.UN_NUMBER':
                        columnDefs.push(colUnityNumber);
                        break;
                    case 'LOAD_REF':
                        columnDefs.push(colLoadRef);
                        break;

                };
            }
            return columnDefs;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private repositionPanels(currentPanelId: string, isOpenning?: boolean) {
        if (currentPanelId) {
            const panels: IRepositionPanel[] = [];
            panels.push({ panelId: 'mainRow', panelElement: angular.element("#mainRow"), collapseId: "collapseMain", collapseElement: angular.element("#collapseMain") });
            panels.push({ panelId: 'cargoRow', panelElement: angular.element("#cargoRow"), collapseId: "collapseCargo", collapseElement: angular.element("#collapseCargo") });
            panels.push({ panelId: 'eventsRow', panelElement: angular.element("#eventsRow"), collapseId: "collapseEvents", collapseElement: angular.element("#collapseEvents") });
            panels.push({ panelId: 'chargesRow', panelElement: angular.element("#chargesRow"), collapseId: "collapseCharges", collapseElement: angular.element("#collapseCharges") });
            panels.push({ panelId: 'chargesOutDateRow', panelElement: angular.element("#chargesOutDateRow"), collapseId: "collapseChargesOutDate", collapseElement: angular.element("#collapseChargesOutDate") });
            panels.push({ panelId: 'managementRow', panelElement: angular.element("#managementRow"), collapseId: "collapseManagement", collapseElement: angular.element("#collapseManagement") });
            panels.push({ panelId: 'communicationRow', panelElement: angular.element("#communicationRow"), collapseId: "collapseCommunication", collapseElement: angular.element("#collapseCommunication") });
            panels.push({ panelId: 'referencesRow', panelElement: angular.element("#referencesRow"), collapseId: "collapseReferences", collapseElement: angular.element("#collapseReferences") });

            if (!this.$rootScope.app || !this.$rootScope.app.settings || this.$rootScope.app.settings.panelsReorder) {
                if (isOpenning) {
                    // set current panel to be the first
                    const currentPanelIndex = panels.findIndex(panel => panel.panelId == currentPanelId);
                    if (currentPanelIndex >= 0) {
                        panels[currentPanelIndex].panelElement.css('order', '1');
                        // reposition other panels
                        panels.splice(currentPanelIndex, 1);
                        let order = 2;
                        for (const panel of panels) {
                            $("#" + panel.collapseId)["collapse"]('hide');
                            panel.panelElement.css('order', order);
                            order++;
                        }
                        // navigate to first panel
                        this.navigateBetweenIds(currentPanelId);
                    }
                } else {
                    const panelsToReposition = panels.filter(panel => !panel.collapseElement.hasClass("in"));
                    let order = panels.length - panelsToReposition.length + 1;
                    for (const panel of panelsToReposition) {
                        panel.panelElement.css('order', order);
                        order++;
                    }
                    if (currentPanelId == "managementRow") this.getOfferHeader(this.$scope.model.ID);
                }
            } else this.navigateBetweenIds(currentPanelId);
        }
    }

    private navigateBetweenIds(to: string): boolean {
        if (!to) return false;
        this.$timeout(function () {
            const element = $("#" + to);
            if (element.length == 0) return;
            const position = $("#" + to).offset().top + $('.app-content-body').scrollTop() - 215;
            $('.app-content-body').animate({
                scrollTop: position
            }, 500);
            return true;
        });
        return false;
    };

    private async buildVigenceModalScope(): Promise<IVigenceModalScope> {
        const modalScope: IVigenceModalScope = await this.$scope.modalService.getModalScope(this.modalFormatedDataId);

        modalScope.formatedData = await this.getOfferTabsFormatedData();
        modalScope.oldFormatedData = angular.copy(modalScope.formatedData);
        modalScope.hasInlandRoute = modalScope.formatedData.TARIFF_PAYMENT.some(tariff => tariff.TYPE.ID == ETariffType.Inland);
        modalScope.hasFreightRoute = modalScope.formatedData.TARIFF_PAYMENT.some(tariff => tariff.TYPE.ID == ETariffType.Route);

        modalScope.applyVigenceModal = async () => {
            this.applyVigenceModal(modalScope.formatedData, modalScope.oldFormatedData, true);
        };

        modalScope.checkDateValidity = (initialDate: Date, finalDate: Date): void => {
            let isValid = false;
            if (!initialDate || typeof initialDate == "string") return;
            if (!finalDate || typeof finalDate == "string") return;
            isValid = ValidateUtil.isValidDateRange(initialDate, finalDate);
            if (!isValid) {
                modalScope.formatedData.OFFER_VIGENCE.VALIDITY_END = null;
            }
        }

        modalScope.closeVigenceModal = async (): Promise<void> => {
            if (this.$scope.hasChanges(JSON.stringify(modalScope.formatedData), JSON.stringify(modalScope.oldFormatedData))) {
                const close = this.formService.getTranslate('GENERAL.CLOSE');
                const confirm = await this.$scope.modalSaveConfirmation(close, close);
                if (confirm && !await this.applyVigenceModal(modalScope.formatedData, modalScope.oldFormatedData)) return;
            }
            this.$scope.modalService.closeModal(this.modalFormatedDataId);
            this.modalFormatedDataId = 0;
            angular.element('.app-content-body').animate({ scrollTop: this.$scope.lastScroll }, 0);
        }

        modalScope.viewLogTariff = async (): Promise<void> => {
            try {
                this.formService.block();
                let log: IViewLog = {
                    operation: 'history',
                    number: this.$scope.model.ID.toString(),
                    list: [],
                    show: true,
                    showCloseButton: false,
                    searchQuery: '',
                    originalList: [],
                }

                this.requestTariffHistory(log.number,).then(result => {
                    log.list = result.data;
                    log.originalList = angular.copy(log.list);
                    this.$scope.log = log;
                    angular.element('#log-viewer').removeClass('ng-hide');
                    this.formService.unblock();
                }).catch(ex => {
                    this.formService.handleError(ex);
                });

                const modalId = this.$scope.modalService.newModal();
                await this.$scope.modalService.showModalConfirmation(
                    {
                        modalID: modalId,
                        scope: this.$scope,
                        template: require('../view/modals/offerLog.html'),
                        size: 'full'
                    },
                    {
                        closeButtonText: "GENERAL.CLOSE",
                        headerText: "GENERAL.GRID.LOG"
                    }
                );
                this.$scope.modalService.closeModal(modalId);
            } catch (ex) {
                this.formService.handleError(ex);
            }
        }

        return modalScope;
    }

    private async buildOfferProcessModalScope(offer: IOfferGridModel): Promise<IOfferProcessModal> {
        try {
            this.formService.block();
            if (!offer) throw Error('Missing offer parameter in buildOfferProcessModalScope');

            const modalScope: IOfferProcessModal = await this.$scope.modalService.getModalScope(this.modalFormatedDataId);
            modalScope.offerProcess = angular.copy(offer);

            const productConfigurationList = await this.getProductConfigurationList();

            if (productConfigurationList) {
                modalScope.productConfigurationList = productConfigurationList;

                let maxValidityDate = new Date(modalScope.offerProcess.VALIDITY_END);
                maxValidityDate.setDate(maxValidityDate.getDate() + productConfigurationList.QTD_DAYS_OFFER_VALIDITY_EXTENDS);

                let minValidityDate = new Date(modalScope.offerProcess.VALIDITY_END);
                minValidityDate.setDate(minValidityDate.getDate() + 1);

                modalScope.dateOptionsAccountRequest = {
                    formatYear: 'yy',
                    maxDate: maxValidityDate,
                    minDate: minValidityDate,
                    startingDay: 1
                };

                modalScope.processList = [];
                const requestList = await this.getOfferProcessList(offer.ID);
                if (requestList) modalScope.processList = angular.copy(requestList);

                modalScope.goToOfferProcess = (processNumber: string): void => {
                    if (!processNumber || (processNumber && processNumber == '')) throw Error('processNumber is null');
                    this.$scope.sessionService.openTab("app.operational.newProcess.list", <IProcessParameter>{ PROCESS_NUMBER: processNumber });
                }

                modalScope.refreshModal = async (offerID: number): Promise<void> => {
                    try {
                        this.formService.block();
                        const modalScope: IOfferProcessModal = await this.$scope.modalService.getModalScope(this.modalFormatedDataId);

                        this.validateOfferValidity(modalScope.processList);
                        this.updateOfferValidity(offerID, modalScope.processList);

                        setTimeout(async () => {
                            const requestList = await this.getOfferProcessList(offerID);
                            if (requestList) modalScope.processList = angular.copy(requestList);
                            this.formService.unblock();
                        }, 1000);
                    } catch (ex) {
                        this.formService.unblock();
                        this.formService.handleError(ex);
                    }
                }
            }
            return modalScope;
        } catch (ex) {
            this.$scope.modalService.closeModal(this.modalFormatedDataId);
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async updateOfferValidity(offerID: number, processList: IProcessValidityDate[]): Promise<IProcessValidityDate[]> {
        try {
            const timeout: number = 120000;
            let offerUsedProcess: IProcessValidityDate[] = null

            const offerUsedProcessRequest = await this.restService.newObjectPromise(`${this.$scope.productUrl}/offer/update/validity`, { offerID, processList }, timeout, false);
            offerUsedProcess = offerUsedProcessRequest ? offerUsedProcess = offerUsedProcessRequest : [];

            return offerUsedProcess

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async validateOfferValidity(processList: IProcessValidityDate[]): Promise<void> {
        try {
            for (const process of processList) {
                const extendedValidityDate = new Date(process.EXTENDED_VALIDITY_DATE).toLocaleDateString()
                if (extendedValidityDate == "Invalid Date") {
                    throw Error(`Invalid date informed in process ${process.PROCESS_NUMBER}`);
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getOfferProcessList(offerID: number): Promise<IProcessValidityDate[]> {
        try {
            let result = [];

            const requestList = await this.restService.getObjectAsPromise(`${this.$scope.productUrl}/offer/process/${offerID}`, 30000, null, false);
            if (requestList && requestList.data) result = requestList.data;
            return result
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async applyVigenceModal(formatedData: IFormatedData, oldFormatedData: IFormatedData, closeModal?: boolean): Promise<boolean> {
        let success = false;
        this.formService.block();
        try {
            const data: IOfferVigenceAndTariff = {
                AUTO_RATING_TARIFF_LOCAL: formatedData.AUTO_RATING_TARIFF_LOCAL,
                AUTO_RATING_INLAND_ROUTES: formatedData.AUTO_RATING_INLAND_ROUTES,
                AUTO_RATING_FREIGHT_ROUTES: formatedData.AUTO_RATING_FREIGHT_ROUTES,
                ...formatedData.OFFER_VIGENCE
            }
            const oldData: IOfferVigenceAndTariff = {
                AUTO_RATING_TARIFF_LOCAL: oldFormatedData.AUTO_RATING_TARIFF_LOCAL,
                AUTO_RATING_INLAND_ROUTES: oldFormatedData.AUTO_RATING_INLAND_ROUTES,
                AUTO_RATING_FREIGHT_ROUTES: oldFormatedData.AUTO_RATING_FREIGHT_ROUTES,
                ...oldFormatedData.OFFER_VIGENCE
            }
            const offerVigenceUpdate = await this.restService.newObjectPromise(`${this.$scope.productUrl}/offer/tabs/vigence/update`, {
                id: this.$scope.model.ID,
                data,
                oldData
            }, 30000, false);
            if (offerVigenceUpdate) success = true;
            if (closeModal) {
                this.$scope.modalService.closeModal(this.modalFormatedDataId);
                this.modalFormatedDataId = 0;
                angular.element('.app-content-body').animate({ scrollTop: this.$scope.lastScroll }, 0);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            const msgSuccess = this.formService.getTranslate('PRODUCT.OFFER_VIGENCE_UPDATED_SUCCESSFULLY');
            if (success) this.formService.notifySuccess(msgSuccess);
            return success;
        }
    }

    private async buildTariffFilesModalScope(): Promise<ITariffFilesModalScope> {
        const modalScope: ITariffFilesModalScope = await this.$scope.modalService.getModalScope(this.modalTariffFilesId);

        modalScope.tariffFiles = await this.getOfferTabsTariffLinks();

        modalScope.closeTariffFilesModal = async (): Promise<void> => {
            this.$scope.modalService.closeModal(this.modalTariffFilesId);
            this.modalTariffFilesId = 0;
            angular.element('.app-content-body').animate({ scrollTop: this.$scope.lastScroll }, 0);
        }

        modalScope.downloadFile = async (file: IUploadItem): Promise<void> => {
            this.downloadFileFromGoogleStorage(file.FILE_HASH);
        }

        return modalScope;
    }

    private async buildTariffTransactionNotificationModalScope(data: ITariffTransactionNotification[]): Promise<ITariffTransactionNotificationModalScope> {
        try {
            const modalScope: ITariffTransactionNotificationModalScope = await this.$scope.modalService.getModalScope(this.modalTariffTransactionId);

            modalScope.tariffTransactionNotification = data;

            modalScope.closeTariffTransactionNotificationModal = async (): Promise<void> => {
                this.$scope.modalService.closeModal(this.modalTariffTransactionId);
                this.modalTariffTransactionId = 0;
                angular.element('.app-content-body').animate({ scrollTop: this.$scope.lastScroll }, 0);
            }

            modalScope.approveTariffTransactionNotification = async (notification: ITariffTransactionNotification): Promise<void> => {
                try {
                    if (notification.SITUATION && notification.SITUATION.ID != ETariffTransactionNotificationtSituationId.PENDING) {
                        this.formService.handleError(`Notification already ${notification.SITUATION.NAME.toLowerCase()}.`);
                    }
                    await this.approveTariffTransactionNotification(notification);
                    modalScope.tariffTransactionNotification = await this.getOfferTariffTransactionNotifications();
                    this.$timeout(() => {
                        modalScope.tariffTransactionTableOptions.load(modalScope.tariffTransactionNotification, true);
                    }, 200);
                } catch (ex) {
                    this.formService.handleError(ex);
                }
            }
            await modalScope.$applyAsync();
            return modalScope;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getOfferHeader(offerId: number): Promise<void> {
        this.formService.block();
        try {
            const offerHeader = await this.restService.getObjectAsPromise(`${this.$scope.productUrl}/offer/tabs/header/${offerId}`, 30000, null, false);
            if (offerHeader && offerHeader.data) {
                this.$scope.model = offerHeader.data;
                this.$scope.model.ID = offerId;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async viewLog(logName, id) {
        try {
            this.formService.block();
            let log: IViewLog = {
                operation: 'history',
                number: id,
                list: [],
                show: true,
                showCloseButton: false,
                searchQuery: '',
                originalList: [],
            }

            this.requestHistory(logName, log.number).then(result => {
                log.list = result.data;
                log.originalList = angular.copy(log.list);
                this.$scope.log = log;
                angular.element('#log-viewer').removeClass('ng-hide');
                this.formService.unblock();
            }).catch(ex => {
                this.formService.handleError(ex);
            });

            const modalId = this.$scope.modalService.newModal();
            await this.$scope.modalService.showModalConfirmation(
                {
                    modalID: modalId,
                    scope: this.$scope,
                    template: require('../view/modals/offerLog.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG"
                }
            );
            this.$scope.modalService.closeModal(modalId);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async openFormatedData(): Promise<void> {
        this.modalFormatedDataId = this.$scope.modalService.newModal();

        // Validate if offer is combined or old version
        this.$scope.modalService.showModalInfo(
            this.$scope.model.IS_COMBINED
                ? {
                    modalID: this.modalFormatedDataId,
                    scope: this.$scope,
                    formService: this.$scope.operation,
                    size: 'lg modal-overflow',
                    template: require("../view/modals/offerFormatedDataModal.html"),
                    keyboard: false
                }
                : {
                    modalID: this.modalFormatedDataId,
                    scope: this.$scope,
                    formService: this.$scope.operation,
                    size: 'lg modal-overflow',
                    template: require("../view/modals/offerOldFormatedDataModal.html"),
                    keyboard: false
                },
            null
        );

        this.buildVigenceModalScope();
    }

    private async openOfferProcess(offer: IOfferGridModel): Promise<void> {
        this.modalFormatedDataId = this.$scope.modalService.newModal();
        this.$scope.modalService.showModalInfo(
            {
                modalID: this.modalFormatedDataId,
                scope: this.$scope,
                formService: this.$scope.operation,
                size: 'lg modal-overflow',
                template: require("../view/modals/offerProcessModal.html"),
                keyboard: false
            },
            null
        );

        this.buildOfferProcessModalScope(offer);
    }

    private async openTariffFiles(): Promise<void> {
        this.modalTariffFilesId = this.$scope.modalService.newModal();
        this.$scope.modalService.showModalInfo(
            {
                modalID: this.modalTariffFilesId,
                scope: this.$scope,
                formService: this.$scope.operation,
                size: 'lg modal-overflow',
                template: require("../view/modals/offerTariffFilesModal.html"),
                keyboard: false
            },
            null
        );
        this.buildTariffFilesModalScope();
    }

    private async openTariffTransactionNotification(): Promise<void> {
        try {
            this.modalTariffTransactionId = this.$scope.modalService.newModal();
            const data = await this.getOfferTariffTransactionNotifications()
            const tariffTransactionTableOptions = await this.getTariffTransactionTableOptions();

            await this.$scope.modalService.showModalInfo(
                {
                    modalID: this.modalTariffTransactionId,
                    formService: this.$scope.operation,
                    size: 'full modal-overflow',
                    template: require("../view/modals/offerTariffTransactionNotificationModal.html"),
                    keyboard: false,
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: 'OPERATIONAL.CONSOL'
                },
                {
                    tariffTransactionTableOptions,
                }
            );

            const modalScope = await this.buildTariffTransactionNotificationModalScope(data);

            this.$timeout(() => {
                modalScope.tariffTransactionTableOptions.load(data, true);
            }, 400);

        } catch (ex) {
            this.formService.handleError(ex);
        }

    }

    private async getOfferTabsFormatedData(): Promise<IFormatedData> {
        let formatedData: IFormatedData = null;
        this.formService.block();
        try {
            const vigenceData = await this.restService.getObjectAsPromise(`${this.$scope.productUrl}/offer/tabs/vigence/${this.$scope.model.ID}`, 10000, null, false);
            if (vigenceData && vigenceData.data && vigenceData.data.FORMATED_DATA) {
                formatedData = vigenceData.data.FORMATED_DATA;
                if (formatedData.OFFER_VIGENCE) {
                    if (formatedData.OFFER_VIGENCE.VALIDITY_START) formatedData.OFFER_VIGENCE.VALIDITY_START = new Date(formatedData.OFFER_VIGENCE.VALIDITY_START);
                    if (formatedData.OFFER_VIGENCE.VALIDITY_END) formatedData.OFFER_VIGENCE.VALIDITY_END = new Date(formatedData.OFFER_VIGENCE.VALIDITY_END);
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return formatedData;
        }
    }

    private async getOfferTabsTariffLinks(): Promise<IOfferTariffFiles> {
        let tariffFiles: IOfferTariffFiles = null;
        this.formService.block();
        try {
            const results = await this.restService.getObjectAsPromise(`${this.$scope.productUrl}/offer/list/tariff/files/${this.$scope.model.ID}`, 10000, null, false);
            if (results && results.data)
                tariffFiles = results.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return tariffFiles;
        }
    }

    private async getOfferTariffTransactionNotifications(): Promise<ITariffTransactionNotification[]> {
        let tariffTransactionNotification: ITariffTransactionNotification[] = [];
        this.formService.block();
        try {
            const results = await this.restService.getObjectAsPromise(`${this.$scope.productUrl}/tariffTransactionNotification/getByOfferId/${this.$scope.model.ID}`, 10000, null, false);
            if (results && results.data)
                tariffTransactionNotification = results.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return tariffTransactionNotification;
        }
    }

    private async approveTariffTransactionNotification(notification: ITariffTransactionNotification): Promise<void> {
        try {
            if (!notification) return this.formService.handleError('Selecione uma transação para ser aprovada!');
            if (notification.TYPE && notification.TYPE.ID != ETariffTransactionNotificationTypeId.INFO) {
                if (await this.openReasonApproveModal(notification)) {
                    const results = await this.$scope.productService.post({ route: `/tariffTransactionNotification/approve`, data: { data: notification } });
                    if (!results || !results.data) return this.handleError(results);
                }
                const transactions: ITariffTransactionNotification[] = await this.getOfferTariffTransactionNotifications();
                const modalScope = await this.buildTariffTransactionNotificationModalScope(transactions);
                modalScope.tariffTransactionTableOptions.load(transactions, true);

            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private newOffer(): void {
        try {
            this.openOfferWizardModal();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async openReasonApproveModal(notification: ITariffTransactionNotification): Promise<boolean> {
        let success = true;
        let isModifiedTypeOk = true;
        try {
            this.modalApproveNotificationReasonId = this.$scope.modalService.newModal();

            let result = await this.getGenericValue("offer_change_confirmation_reason");
            if (result) this.$scope.reasonsForChangeNotification = result;

            this.$scope.auditedReason = { REASON_OBSERVATION: null, CHANGE_REASON: null };

            const body = `
                    <div class="row" id="resonModal">
                        <div class="col-lg-12">
                            <ui-select name="reasonChange" id="reasonChange" ng-model="auditedReason.CHANGE_REASON"
                                theme="bootstrap" ng-change="selectorValidity(this.$select.ngModel.$name);"
                                ng-disabled="selectorDisabled(this.$select.ngModel.$name) || operation == 'view'"
                                ng-click="selectorFocus(this.$select.searchInput[0]);" skip-focusser="true" required>
                                <ui-select-match placeholder="{{'GENERAL.UI_SELECT.SELECT' | translate }}">
                                    {{$select.selected.NAME}}
                                </ui-select-match>
                                <ui-select-choices repeat="item in reasonsForChangeNotification | filter: $select.search track by $index">
                                    <div ng-bind-html="item.NAME | highlight: $select.search">
                                    </div>
                                </ui-select-choices>
                                <ui-select-no-choice>
                                    {{'GENERAL.UI_SELECT.EMPTY_SELECTOR_MESSAGE' | translate }}
                                </ui-select-no-choice>
                            </ui-select>
                        </div>
                        <div class="col-lg-12" ng-if="auditedReason.CHANGE_REASON.ID == '3'">
                            <label>{{'GENERAL.REMARKS' | translate}} <i class="fa fa-asterisk text-danger small-fontawesome"
                                    aria-hidden="true" tooltip-placement="auto top"
                                    uib-tooltip="{{ 'GENERAL.MANDATORY_FIELD' | translate }}" tooltip-append-to-body="true"></i></label>
                            <textarea class="form-control" name="reasonObservation"
                                ng-model="auditedReason.REASON_OBSERVATION"></textarea>
                        </div>
                    </div>
                    `;

            const modalInstance: IModalInstanceService = await this.$scope.modalService.showModalInfo(
                {
                    modalID: this.modalApproveNotificationReasonId,
                    scope: this.$scope,
                    formService: 'register',
                    size: 'md',
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing" && closed) {
                            if (!this.$scope.auditedReason || (this.$scope.auditedReason && !this.$scope.auditedReason.CHANGE_REASON)) {
                                event.preventDefault();
                                const msgError = this.formService.getTranslate('BASIC_DATA.IT_IS_NECESSARY_TO_INFORM_THE_REASON_TO_CONTINUE');
                                this.formService.notifyError(msgError);
                            }
                        }
                    }
                },
                {
                    closeButtonText: 'GENERAL.CANCEL',
                    actionButtonText: 'REGISTRATION.APPLY',
                    headerText: 'BASIC_DATA.INFORM_THE_REASON_FOR_THE_QUANTITY_CHANGE',
                    bodyText: this.$sce.trustAsHtml(body),

                }
            );

            modalInstance.rendered.then(() => {
                const modifiedTypeSelect = angular.element("#resonModal");
                if (modifiedTypeSelect) this.$compile(modifiedTypeSelect)(this.$scope);
            });

            isModifiedTypeOk = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });
            if (isModifiedTypeOk && notification && this.$scope.auditedReason.CHANGE_REASON) {
                notification.AUDITED_REASON = this.$scope.auditedReason.CHANGE_REASON
                notification.AUDITED_OBSERVATION = this.$scope.auditedReason.REASON_OBSERVATION
            }
            if (!isModifiedTypeOk) success = false;
        }
        catch (ex) {
            success = false;
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return success;
        }
    }

    private async viewOffer(offer: IOfferGridModel): Promise<void> {
        if (!offer) throw new Error("Offer is null.");
        try {
            if (!this.$scope.collapseState || this.$scope.collapseState.released) {
                this.setNumberOfAutoRatingErros(offer.ID);

                BrowserTitle.$id = offer.OFFER_CODE_EXHIBITION;
                await this.getOfferHeader(offer.ID);
                this.getAndUpdateTariffsIds();
                this.$scope.model.ID = offer.ID;
                this.$scope.showForm = true;
                this.$scope.showOfferForm = true;
                this.$scope.menuFloating = this.getMenuFloatingDefault(offer);
                if (this.$scope.menuFloating) {
                    this.$scope.menuFloating.infos = [
                        { text: offer.OFFER_CODE_EXHIBITION, class: "text-rouge font-bold" }
                    ];
                }
                this.$timeout(() => {
                    angular.element("#collapseMain")["collapse"]('show');
                }, 500);

                // Make the row selected in grid.
                this.$gridService.setFixedRow(offer._id);

            } else {
                this.lastGridOffer = offer;
                this.$scope.collapseState.nextState = this.$scope.operation == EOperation.VIEW ? ECollapseState.VIEW_OFFER : ECollapseState.EDIT_OFFER;
                this.releaseCollapse();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private tooltip(summaryValidationResult: IOfferSummaryValidationResult): string {
        let result = this.formService.getTranslate('PRODUCT.CLICK_VALIDATE_OFFER');
        if (summaryValidationResult && summaryValidationResult.USER && summaryValidationResult.DATE) {
            result = this.formService.getTranslate('GENERAL.CLICK_VALIDATE_OFFER_MESSAGE', { user: summaryValidationResult.USER, date: this.$filter('date')(summaryValidationResult.DATE, 'dd/MM/yyyy HH:mm') })
        }
        return result;
    }

    private async consistencyValidateOffer(offerId: number, offer?: IOfferGridModel, loadDetails?: boolean): Promise<void> {
        if (!offerId) throw new Error("offerId is null.");
        this.formService.block();
        try {
            const validateResult = await this.restService.getObjectAsPromise(`${this.$scope.productUrl}/offer/validate/${offerId}/false`, 30000, null, false);
            if (validateResult && validateResult.data) {
                if (offer) offer.SUMMARY_VALIDATION_RESULT = validateResult.data;
                if (typeof this.$scope.gridOptions.data == 'object') {
                    const offerGrid: IOfferGridModel = this.$scope.gridOptions.data.find(obj => obj.ID == offerId);
                    if (offerGrid) offerGrid.SUMMARY_VALIDATION_RESULT = validateResult.data;
                }
                const msgSuccess = this.formService.getTranslate('PRODUCT.CONSISTENCY_VALIDATION_PERFORMED_SUCCESSFULLY');
                this.formService.notifySuccess(msgSuccess);
                if (loadDetails) this.viewOfferConsistencyValidateDetails(this.$scope.lastOfferSelected, true);
            }
            this.viewOfferConsistencyValidateDetails(offer);
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async updateActiveInactive(active: boolean): Promise<void> {
        if (!angular.isDefined(active)) throw new Error("active is null.");
        this.formService.block();
        try {
            const validateResult = await this.restService.newObjectPromise(`${this.$scope.productUrl}/offer/update/status`, { id: this.$scope.model.ID, active }, 30000, false);
            const msgSuccess = this.formService.getTranslate('PRODUCT.OFFER_STATUS_UPDATED_SUCCESSFULLY');
            if (validateResult) this.formService.notifySuccess(msgSuccess);
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getOfferById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const offerResult = await this.restService.newObjectPromise(`${this.$scope.productUrl}/offer/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return offerResult.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async viewOfferConsistencyValidateDetails(offer: IOfferGridModel, isReload?: boolean): Promise<void> {
        if (!offer) throw new Error("offer is null.");
        this.formService.block();
        try {
            const allValidationResultRequest = await this.restService.getObjectAsPromise(`${this.$scope.productUrl}/offer/allValidation/${offer.ID}`, 30000, null, false);
            if (allValidationResultRequest && allValidationResultRequest.data) {
                this.$scope.lastOfferSelected = offer;
                this.$scope.allValidationResult = allValidationResultRequest.data;
                if ((allValidationResultRequest.data.OFFER.VALIDATION_RESULT && (allValidationResultRequest.data.OFFER.VALIDATION_RESULT.HAS_ERROR || allValidationResultRequest.data.OFFER.VALIDATION_RESULT.HAS_WARNING)) || (allValidationResultRequest.data.EVENT.VALIDATION_RESULT && (allValidationResultRequest.data.EVENT.VALIDATION_RESULT.HAS_ERROR || allValidationResultRequest.data.EVENT.VALIDATION_RESULT.HAS_WARNING)) || (allValidationResultRequest.data.CHARGES && allValidationResultRequest.data.CHARGES.length != 0) || (allValidationResultRequest.data.OFFER_CARGO.VALIDATION_RESULT && (allValidationResultRequest.data.OFFER_CARGO.VALIDATION_RESULT.HAS_ERROR || allValidationResultRequest.data.OFFER_CARGO.VALIDATION_RESULT.HAS_WARNING))) {
                    if (!isReload) {
                        const modalId = this.$scope.modalService.newModal();
                        this.$scope.modalService.showModalInfo(
                            {
                                modalID: modalId,
                                scope: this.$scope,
                                formService: EOperation.VIEW,
                                size: 'vlg modal-overflow',
                                template: require("../view/modals/offerConsistencyValidateDetailsModal.html"),
                                keyboard: false
                            },
                            null
                        );
                    }
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async viewOfferStatusLog(): Promise<void> {
        try {
            const retrieveLog = await this.restService.getObjectAsPromise(`${this.$scope.productUrl}/offer/viewLog/OFFER-ACTIVE-/${this.$scope.model.ID}`, 30000, null, false);
            if (retrieveLog && retrieveLog.data) {
                this.formService.block();
                let log: IViewLog = {
                    operation: 'history',
                    number: this.$scope.model.ID.toString(),
                    list: [],
                    show: true,
                    showCloseButton: false,
                    searchQuery: '',
                    originalList: [],
                }

                log.list = retrieveLog.data;
                log.originalList = angular.copy(log.list);
                this.$scope.log = log;
                angular.element('#log-viewer').removeClass('ng-hide');
                this.formService.unblock();

                const modalId = this.$scope.modalService.newModal();
                await this.$scope.modalService.showModalConfirmation(
                    {
                        modalID: modalId,
                        scope: this.$scope,
                        template: require('../view/modals/offerLog.html'),
                        size: 'full'
                    },
                    {
                        closeButtonText: "GENERAL.CLOSE",
                        headerText: "GENERAL.GRID.LOG"
                    }
                );
                this.$scope.modalService.closeModal(modalId);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private releaseCollapse(panel?: string) {
        if (!this.$scope.collapseState) this.formService.notifyError("collapseState is null.");
        const panelToCollapse = panel ? panel : this.$scope.collapseState.panel;
        switch (panelToCollapse) {
            case ECollapseState.VIEW_OFFER:
                this.$scope.collapseState.nextState = null;
                this.$scope.viewOffer(this.lastGridOffer)
                break;
            case ECollapseState.EDIT_OFFER:
                this.$scope.collapseState.nextState = null;
                this.$scope.editOffer(this.lastGridOffer);
                break;
            case ECollapseState.CANCEL_OFFER:
                this.$scope.collapseState.nextState = null;
                this.cancel();
                break;
            case ECollapseState.MAIN:
                this.$scope.$broadcast("offerMainCollapse");
                break;
            case ECollapseState.CARGO:
                this.$scope.$broadcast("offerCargoCollapse");
                break;
            case ECollapseState.EVENTS:
                this.$scope.$broadcast("offerEventCollapse");
                break;
            case ECollapseState.CHARGES:
                this.$scope.$broadcast("offerChargeCollapse");
                break;
            case ECollapseState.CHARGES_OUT_DATE:
                this.$scope.$broadcast("offerChargeOutDateCollapse");
                break;
            case ECollapseState.MANAGEMENT:
                this.$scope.$broadcast("offerManagementCollapse");
                break;
            case ECollapseState.REFERENCE:
                this.$scope.$broadcast("offerReferenceCollapse");
                break;
            case ECollapseState.COMMUNICATION:
                this.$scope.$broadcast("offerCommunicationCollapse");
                break;
        }
    }

    private async getGenericValue(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private requestHistory(logName: string, id: string): Promise<any> {
        return this.restService.getObjectAsPromise(`${this.$scope.productUrl}/account/viewLog/${id}`, 10000, null, false);
        //return this.restService.getObjectAsPromise(`/offer/${logName}/viewLog/${id}`, 10000);
    }

    private requestTariffNotificationLog(id: string): Promise<any> {
        return this.restService.getObjectAsPromise(`${this.$scope.productUrl}/tariffTransactionNotification/viewLog/${id}`, 10000, null, false);
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlDataManager = this.config.productUrl + baseRoute;
        return urlDataManager;
    }

    private getMenuFloatingDefault(offer?: IOfferGridModel): IFloatingMenu {
        const goToOriginalOfferOption: IFloatingOption = offer && offer.ID_ORIGINAL_OFFER ? { click: "goToOriginalOffer", args: [offer.ID_ORIGINAL_OFFER], tooltipPlacement: "auto bottom", textTooltip: " Oferta original", iconClass: "fa fa-handshake-o", iconBodyClass: "text-green" } : null;
        const floatingMenu: IFloatingMenu = {
            tooltipPlacement: "auto bottom",
            textTooltip: "OPERATIONAL.OFFER_DATA",
            infos: [
                { text: "{{'BASIC_DATA.OFFER' | translate }}", class: "text-rouge font-bold" }
            ],
            options: [
                { click: "releaseCollapse", args: [ECollapseState.MAIN], tooltipPlacement: "auto bottom", textTooltip: "OPERATIONAL.MAIN_TAB", iconClass: "fa fa-home", iconBodyClass: "text-brown" },
                { click: "releaseCollapse", args: [ECollapseState.CARGO], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.CARGO", iconClass: "fa fa-truck", iconBodyClass: "text-cyano" },
                { click: "releaseCollapse", args: [ECollapseState.EVENTS], tooltipPlacement: "auto bottom", textTooltip: "OPERATIONAL.EVENTS", iconClass: "fa fa-location-arrow", iconBodyClass: "text-rouge" },
                { click: "releaseCollapse", args: [ECollapseState.CHARGES], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.CHARGES", iconClass: "fa fa-usd", iconBodyClass: "text-green" },
                { click: "releaseCollapse", args: [ECollapseState.CHARGES_OUT_DATE], tooltipPlacement: "auto bottom", textTooltip: "Charges Validity Off", iconClass: "fa fa-usd", iconBodyClass: "text-danger" },
                { click: "releaseCollapse", args: [ECollapseState.MANAGEMENT], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.MANAGEMENT", iconClass: "fa fa-gears", iconBodyClass: "text-green" },
                { click: "releaseCollapse", args: [ECollapseState.COMMUNICATION], tooltipPlacement: "auto bottom", textTooltip: "OPERATIONAL.COMMUNICATION", iconClass: "fa fa-paper-plane", iconBodyClass: "text-orange" },
                { click: "releaseCollapse", args: [ECollapseState.REFERENCE], tooltipPlacement: "auto bottom", textTooltip: "OPERATIONAL.REFERENCES_AND_ATTACHMENTS", iconClass: "fa fa-files-o", iconBodyClass: "text-gray" }
            ],
            optionsOnTheRight: [
                { click: "viewOfferStatusLog", args: null, tooltipPlacement: "auto bottom", textTooltip: "PRODUCT.OFFER_LOG_STATUS", iconClass: "fa fa-history icon", iconBodyClass: "text-green btn btn-default btn-xs" }
            ],
            btnActiveDisabled: false
        };
        if (goToOriginalOfferOption) floatingMenu.options.push(goToOriginalOfferOption);
        return floatingMenu;
    }

    private hasInvalidRequiredElements(elementId: string): boolean {
        if (!elementId) return false;
        const isInvalid = FormService2.hasRequiredElements('#' + elementId);
        if (isInvalid) this.formService.notifyError(this.formService.getTranslate("GENERAL.ALL_FIELDS_MANDATORY"));
        return isInvalid;
    }

    private async callSessionFunctions(data: object): Promise<void> {
        if (!data) return;
        const offerExchangeData = <IOfferExchangeData>data;

        let offerModel: IOfferModel = null;
        for (const gridData of this.$scope.gridOptions.data) {
            if (offerExchangeData.ID != null && gridData.ID == offerExchangeData.ID) {
                offerModel = gridData;
                break;
            }
        }
        switch (offerExchangeData.OPERATION) {
            case "new": {
                await this.openOfferWizardModal(offerExchangeData.ID_TARIFF_FREIGHT_CONTRACT, offerExchangeData.ID_TARIFF, offerExchangeData.ID_PAYMENT_NATURE, offerExchangeData.ID_OFFER, offerExchangeData.ID_OFFER_OPPORTUNITY, offerExchangeData.ID_COMBINED_PRE_OFFER, offerExchangeData.PRODUCT, offerExchangeData.CARGO_TYPE, offerExchangeData.ACCOUNTS, offerExchangeData.PROVIDER, offerExchangeData.NEW_FREIGHT_CONTRACT, offerExchangeData.FREIGHT_ROUTES);
                break;
            }
            case "view": {
                if (offerModel) this.$scope.view(offerModel);
                break;
            }
        }
    }

    private async openOfferWizardModal(idTariffFreightContract?: number, idTariff?: number, idPaymentNature?: number, idOffer?: number, idOfferOpportunity?: number, idCombinedPreOffer?: number, product?: SelectorModel, cargoType?: SelectorModel, accounts?: SelectorModel[], provider?: SelectorModel, newFreighContract?: SelectorModel, freightRoutes?: SelectorModel) {
        try {
            const modalOfferId = this.$scope.modalService.newModal();
            const modalParams: IOfferWizardModalParams = { modalID: modalOfferId, idTariffFreightContract: idTariffFreightContract, idTariff, idPaymentNature, idOffer, idOfferOpportunity, idCombinedPreOffer, product, cargoType, accounts, provider, newFreighContract, freightRoutes };

            await this.showOfferWizardModal(modalParams);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async openConcatenatedModal(): Promise<void> {
        const concatenatedComplementBefore = angular.copy(this.$scope.model.CONCATENATED_COMPLEMENT);
        const html = `
        <div class="row">
            <div class="col-lg-12">
                <input type="text" class="form-control" id="concatenatedComplement" name="concatenatedComplement" ng-model="model.CONCATENATED_COMPLEMENT" maxlength="15" ng-readonly="operation == 'view'">
            </div>
        </div>
        `;
        const modalInstance: IModalInstanceService = await this.$scope.modalService.showModalInfo(
            {
                scope: this.$scope,
                size: 'lg'
            },
            {
                closeButtonText: "GENERAL.CLOSE",
                actionButtonText: "GENERAL.SAVE",
                headerText: "GENERAL.UPDATE_CONCATENATED",
                bodyText: this.$sce.trustAsHtml(html),
            }
        );

        modalInstance.rendered.then(() => {
            const concatenatedComplement = angular.element("#concatenatedComplement");
            if (concatenatedComplement) this.$compile(concatenatedComplement)(this.$scope);
        });

        const apply = await modalInstance.result.then(function (result) {
            return result.$value;
        }, function (result) {
            return result.$value;
        });

        if (apply) {
            const resultOperation: IOfferUpdateConcatenatedResult = await this.restService.newObjectPromise(`${this.$scope.productUrl}/offer/update/concatenated`, { ID: this.$scope.model.ID, CONCATENATED_COMPLEMENT: this.$scope.model.CONCATENATED_COMPLEMENT }, 30000, false);
            if (resultOperation && resultOperation.CONCATENATED) {
                const msgSuccess = this.formService.getTranslate('GENERAL.SUCCESS_UPDATE_CONCATENATED');
                this.formService.notifySuccess(msgSuccess);
                this.$scope.model.CONCATENATED = resultOperation.CONCATENATED;
            }
            else {
                const msgError = this.formService.getTranslate('REGISTRATION.MESSAGES.ERROR.UPDATE_ERROR')
                this.formService.notifyError(msgError);
                this.$scope.model.CONCATENATED_COMPLEMENT = concatenatedComplementBefore;
            }
        } else this.$scope.model.CONCATENATED_COMPLEMENT = concatenatedComplementBefore;
    }

    private async modalSaveConfirmation(headerText: string, closeButtonText: string): Promise<boolean> {
        return await this.$scope.modalService.showModalConfirmation({}, {
            headerText: headerText,
            bodyText: this.formService.getTranslate('REGISTRATION.MESSAGES.ERROR.UPDATE_NOT_SAVED'),
            actionButtonText: "GENERAL.SAVE",
            closeButtonText: closeButtonText
        });
    }

    public async downloadFileFromGoogleStorage(hash: string): Promise<void> {
        try {
            this.formService.block();
            if (!hash) return null;

            const operation = await this.restService.getObjectAsPromise(`${this.downloadRoute}/${hash}`, 30000, null, false);
            const response: angular.IHttpResponse<any> = operation;
            if (response && response.data) {
                const resultFile = <IDownloadParamsReturn>response.data;

                const file = resultFile.file;
                if (!resultFile.buffer) {
                    throw Error('Failed to get file information');
                }
                const buffer = resultFile.buffer.data;
                if (!file || !buffer) {
                    throw Error('Failed to get file information');
                }
                const fileName = file.fileName;
                const fileBuffer = new Uint8Array(buffer);
                const fileType = this.getContentType(file.fileType);
                const fileBlob = new Blob([fileBuffer], { type: fileType });
                const fileURL = window.URL.createObjectURL(fileBlob);

                let link = document.createElement('a');
                link.href = fileURL;
                link.download = fileName;
                link.click();
            } else {
                throw Error('Error on download file')
            }

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private getContentType(fileType: string): string {
        switch (fileType.toLowerCase()) {
            case 'arc':
                return 'application/octet-stream';
            case 'bin':
                return 'application/octet-stream';
            case 'avi':
                return 'video/x-msvideo';
            case 'azw':
                return 'application/vnd.amazon.ebook';
            case 'gif':
                return 'image/gif';
            case 'htm':
                return 'text/html';
            case 'html':
                return 'text/html';
            case 'ico':
                return 'image/x-icon';
            case 'ics':
                return 'text/calendar';
            case 'js':
                return 'application/javascript';
            case 'json':
                return 'application/json';
            case 'mpeg':
                return 'video/mpeg';
            case 'jpeg':
                return 'image/jpeg';
            case 'jpg':
                return 'image/jpg';
            case 'png':
                return 'image/png';
            case 'pdf':
                return 'application/pdf';
            case 'rar':
                return 'application/x-rar-compressed';
            case 'zip':
                return 'application/zip';
            case '7z':
                return 'application/x-7z-compressed';
            case 'ppt':
                return 'application/vnd.ms-powerpoint';
            case 'csv':
                return 'text/csv';
            case 'doc':
                return 'application/msword';
            case 'xls':
                return 'application/vnd.ms-excel';
            case 'xlsx':
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            case 'vsd':
                return 'application/vnd.visio';
            case 'ts':
                return 'application/typescript';
            case 'rtf':
                return 'application/rtf';
            default:
                return 'text/plain';
        }
    }

    async cancel(): Promise<void> {
        try {
            this.SSEService.closeEvents();
            if (!this.$scope.collapseState) this.formService.notifyError("collapseState is null.");
            else if (this.$scope.collapseState.released) {
                BrowserTitle.$id = null;
                this.$scope.formOperation = "";
                this.$scope.showOfferForm = false;
                if (this.gridService && this.gridService.$gridApi && this.gridService.$gridApi.selection) this.gridService.$gridApi.selection.clearSelectedRows();
            } else {
                this.$scope.collapseState.nextState = ECollapseState.CANCEL_OFFER;
                this.releaseCollapse();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (!this.$scope.collapseState) this.formService.notifyError("collapseState is null.");
        else if (!this.$scope.collapseState.released) {
            switch (this.$scope.collapseState.panel) {
                case ECollapseState.MAIN:
                    this.$scope.$broadcast("offerMainSave");
                    break;
                case ECollapseState.CARGO:
                    this.$scope.$broadcast("offerCargoSave");
                    break;
                case ECollapseState.EVENTS:
                    this.consistencyValidateOffer(this.$scope.model.ID);
                    break;
                case ECollapseState.CHARGES:
                    this.consistencyValidateOffer(this.$scope.model.ID);
                    break;
                case ECollapseState.CHARGES_OUT_DATE:
                    this.consistencyValidateOffer(this.$scope.model.ID);
                    break;
                case ECollapseState.MANAGEMENT:
                    this.$scope.$broadcast("offerManagementSave");
                    break;
                case ECollapseState.COMMUNICATION:
                    this.consistencyValidateOffer(this.$scope.model.ID);
                    break;
                case ECollapseState.REFERENCE:
                    this.consistencyValidateOffer(this.$scope.model.ID);
                    break;
            }
        }
        return false;
    }

    private async getCurrencyListByName(search?: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            const currencies = await this.restService.newObjectPromise(`${this.getUrlProduct()}/currency/list/custom`, { name: search }, 30000, false);
            if (currencies) result = currencies.map(currency => { return { ID: currency.ID, NAME: currency.NAME, CODE: currency.INITIALS } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private getAndUpdateTariffsIds() {
        let tariffFreightId = "";
        let tariffLocalIdPO = "";
        let tariffLocalIdPD = "";
        let tariffLocalIdRO = "";
        let tariffLocalIdRD = "";
        const formatedData = this.$scope.model ? this.$scope.model.FORMATED_DATA : null;
        if (formatedData) {
            if (formatedData.TARIFF_PAYMENT) {
                const tariffFreight = formatedData.TARIFF_PAYMENT.find(tariff => tariff.TYPE && tariff.TYPE.ID == EOfferFormatedDataTypeId.TARIFF_FREIGHT);
                if (tariffFreight) tariffFreightId = tariffFreight.ID.toString();
                const tariffLocalOrigin = formatedData.TARIFF_PAYMENT.find(tariff => tariff.TYPE && tariff.TYPE.ID == EOfferFormatedDataTypeId.TARIFF_LOCAL && tariff.DIRECTION && tariff.DIRECTION.ID == EDirectionId.ORIGIN);
                if (tariffLocalOrigin) tariffLocalIdPO = tariffLocalOrigin.ID.toString();
                const tariffLocalDestination = formatedData.TARIFF_PAYMENT.find(tariff => tariff.TYPE && tariff.TYPE.ID == EOfferFormatedDataTypeId.TARIFF_LOCAL && tariff.DIRECTION && tariff.DIRECTION.ID == EDirectionId.DESTINATION);
                if (tariffLocalDestination) tariffLocalIdPD = tariffLocalDestination.ID.toString();
            }
            if (formatedData.TARIFF_RECEIVING) {
                const tariffLocalOrigin = formatedData.TARIFF_RECEIVING.find(tariff => tariff.TYPE && tariff.TYPE.ID == EOfferFormatedDataTypeId.TARIFF_LOCAL && tariff.DIRECTION && tariff.DIRECTION.ID == EDirectionId.ORIGIN);
                if (tariffLocalOrigin) tariffLocalIdRO = tariffLocalOrigin.ID.toString();
                const tariffLocalDestination = formatedData.TARIFF_RECEIVING.find(tariff => tariff.TYPE && tariff.TYPE.ID == EOfferFormatedDataTypeId.TARIFF_LOCAL && tariff.DIRECTION && tariff.DIRECTION.ID == EDirectionId.DESTINATION);
                if (tariffLocalDestination) tariffLocalIdRD = tariffLocalDestination.ID.toString();
            }
        }
        this.$scope.tariffFreightId = tariffFreightId;
        this.$scope.tariffLocalIdPO = tariffLocalIdPO;
        this.$scope.tariffLocalIdPD = tariffLocalIdPD;
        this.$scope.tariffLocalIdRO = tariffLocalIdRO;
        this.$scope.tariffLocalIdRD = tariffLocalIdRD;
    }

    private async generateFile(): Promise<void> {
        try {
            const isPreview = !await this.$scope.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.GENERATE',
                actionButtonClass: 'btn-danger',
                closeButtonText: "GENERAL.GRID.VIEW",
                headerText: "GENERAL.CONFIRM",
                bodyText: this.formService.getTranslate('REGISTRATION.WISH_GENERATE_VIEW_FILE')
            });
            this.formService.block();
            if (isPreview) {
                const result = await this.restService.newObjectPromise(`${this.$scope.productUrl}/offer/report/view`, { id: this.$scope.model.ID, product: this.$scope.model.PRODUCT.ID, typeCargo: this.$scope.model.TYPE_CARGO.ID }, 30000, false);
                const msgError = this.formService.getTranslate('PRODUCT.FAILED_RENDER_TEMPLATE_OFFER');
                if (!result) return this.formService.handleError(msgError);

                const file = new File([new Uint8Array(result)], `EM0521/0109A_V1_1622664315950.f1661694-1359-41f1-824f-963fe40fc5da.pdf`, { type: "application/pdf" });
                const objectUrl = window.URL.createObjectURL(file);

                const newTab = window.open(objectUrl, '_blank');
                const msgWarning = this.formService.getTranslate('GENERAL.PRINT_POPUP_BLOCKED');
                if (!newTab) return this.formService.handleWarning(msgWarning);
            } else {
                const result = await this.restService.newObjectPromise(`${this.$scope.productUrl}/offer/report/generate`, { id: this.$scope.model.ID, product: this.$scope.model.PRODUCT.ID }, 30000, false);
                const msgSuccess = this.formService.getTranslate('PRODUCT.SUCCESSFULLY_GENERATED_FILE');
                if (result) this.formService.notifySuccess(msgSuccess);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private requestTariffHistory(id: string, uuid?: string): Promise<any> {
        return this.restService.getObjectAsPromise(`${this.$scope.productUrl}/offer/tabs/vigence/viewLog/${id}`, 10000, null, false);
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: "VALIDITY_START", LABEL: "PRODUCT.VALIDITY_START" },
            { PROPERTY: "VALIDITY_END", LABEL: "PRODUCT.VALIDITY_END" },
            { PROPERTY: "ACTIVE", LABEL: "GENERAL.ACTIVE" },
            { PROPERTY: "ID_TARIFF_TRANSACTION_MANAGEMENT", LABEL: "PRODUCT.AUTO_RATING_TRANSACTION_ID" },
            { PROPERTY: "ID_OFFER", LABEL: "OPERATIONAL.OFFER_ID" },
            { PROPERTY: "ID_OFFER_CHARGE", LABEL: "FINANCIAL.CHARGE_IDENTIFICATION" },
            { PROPERTY: "TYPE", LABEL: "OPERATIONAL.STATUS" },
            { PROPERTY: "ORIGIN_TYPE", LABEL: "BASIC_DATA.PAYMENT_NATURE" },
            { PROPERTY: "SITUATION", LABEL: "GENERAL.SITUATION" },
            { PROPERTY: "TARIFF_NATURE", LABEL: "PRODUCT.TARIFF_TRANSACTION" },
            { PROPERTY: "CHARGE_NAME_EXHIBITION", LABEL: "BASIC_DATA.DISPLAY_CHARGE" },
            { PROPERTY: "NEW_CURRENCY", LABEL: "GENERAL.NEW_CURRENCY" },
            { PROPERTY: "NEW_MIN", LABEL: "GENERAL.NEW_MIN" },
            { PROPERTY: "NEW_UNITARY", LABEL: "GENERAL.NEW_UNITARY" },
            { PROPERTY: "NEW_VALIDITY_START", LABEL: "PRODUCT.NEW_VALIDITY_START" },
            { PROPERTY: "OLD_VALIDITY_START", LABEL: "PRODUCT.OLD_VALIDITY_START" },
            { PROPERTY: "NEW_VALIDITY_END", LABEL: "PRODUCT.NEW_VALIDITY_END" },
            { PROPERTY: "OLD_VALIDITY_END", LABEL: "PRODUCT.OLD_VALIDITY_END" },
            { PROPERTY: "OLD_CHARGE_VALUE_CURRENT_CURRENCY", LABEL: "GENERAL.OLD_CHARGE_VALUE_CURRENT_CURRENCY" },
            { PROPERTY: "NEW_CHARGE_VALUE_CURRENT_CURRENCY", LABEL: "GENERAL.NEW_CHARGE_VALUE_CURRENT_CURRENCY" },
            { PROPERTY: "CREATED_AT", LABEL: "GENERAL.CREATED_AT" },
            { PROPERTY: "CREATED_BY", LABEL: "GENERAL.CREATED_BY" },
            { PROPERTY: "UPDATED_AT", LABEL: "GENERAL.UPDATED_AT" },
            { PROPERTY: "UPDATED_BY", LABEL: "GENERAL.UPDATED_BY" },
            { PROPERTY: "AUDITED_BY", LABEL: "GENERAL.AUDITED_BY" },
            { PROPERTY: "AUDITED_AT", LABEL: "GENERAL.AUDITED_ON" },
            { PROPERTY: "AUDITED_REASON", LABEL: "OPERATIONAL.UPDATE_REASON" },
            { PROPERTY: "AUDITED_OBSERVATION", LABEL: "GENERAL.REMARKS" },
            { PROPERTY: "NAME", LABEL: "GENERAL.NAME" },
            { PROPERTY: "CODE", LABEL: "GENERAL.CODE" },
            { PROPERTY: "ORDER", LABEL: "REGISTRATION.FIELD_ORDER" },
        ];
        return props;
    }

    private async replicateOfferFromModel(offerId: number): Promise<void> {
        if (!offerId) throw new Error("offerId is null.");
        this.formService.block();
        try {
            const modalOfferId = this.$scope.modalService.newModal();
            const modalParams: IOfferWizardModalParams = { modalID: modalOfferId, idOffer: offerId };

            await this.showOfferWizardModal(modalParams);
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async showOfferWizardModal(modalParams: IOfferWizardModalParams): Promise<void> {
        if (!modalParams) throw new Error("modalParams is null.");
        this.formService.block();
        try {
            const hasOfferCombinedWizardPermission = await this.permissionService.isRoleAllowed("OFFERCOMBINEDGENERATEWIZARD");

            if (hasOfferCombinedWizardPermission) this.$scope.modalService.showModalOfferWizard(modalParams);
            else this.$scope.modalService.showModalOfferOldWizard(modalParams);
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async newAlternative(offerId: number, isCombined: boolean): Promise<void> {
        if (!offerId) throw new Error("offerId is null.");
        this.formService.block();
        try {
            const modalNewAlternativeId = this.$scope.modalService.newModal();
            const modalParams = { modalID: modalNewAlternativeId, idOffer: offerId };

            const hasOfferCombinedWizardPermission = await this.permissionService.isRoleAllowed("OFFERCOMBINEDGENERATEWIZARD");

            if (hasOfferCombinedWizardPermission && !isCombined) {
                this.formService.handleWarning(this.formService.getTranslate('GENERAL.CANNOT_NEW_ALTERNATIVE_COMBINED'));
                return;
            } else if (!hasOfferCombinedWizardPermission && isCombined) {
                this.formService.handleWarning(this.formService.getTranslate('GENERAL.CANNOT_OLD_NEW_ALTERNATIVE_WITH_COMBINED'));
                return;
            }

            let template = null;
            if (hasOfferCombinedWizardPermission) template = require("../view/modals/offerNewAlternativeWizardModal.html");
            else template = require("../view/modals/offerNewAlternativeOldWizardModal.html");

            const modalInstance: IModalInstanceService = await this.$scope.modalService.showModalInfo(
                {
                    modalID: modalNewAlternativeId,
                    formService: 'register',
                    template,
                    size: 'full',
                    keyboard: false
                },
                {
                    actionButtonText: "GENERAL.CLOSE"
                }, modalParams
            );
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalIntegrationId = this.$scope.modalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.$scope.modalService.showModalIntegrationRedundance({ modalID: this.modalIntegrationId, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "Integration Product/Operation" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                const rc = await this.$scope.dataProductService.post(`/sync/offerCompiled`, { "id": id }, 120000);
                if (rc && rc.data && rc.data.data && rc.status == 200) success = true;
            }
        } catch (ex) {
            this.formService.handleError(this.formService.getTranslate('GENERAL.ERROR_SENDING_REQUEST'));
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const offerData = await this.getOfferById(id);
                    if (row && offerData && offerData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = offerData.DOCUMENT_ERROR;
                        documentError = offerData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async getProductConfigurationList(): Promise<IProductConfigurationModel> {
        let productConfiguration: IProductConfigurationModel = null;
        const result = await this.restService.newObjectPromise(`${this.$scope.productUrl}/productConfiguration`, {}, 30000, false);
        if (result) productConfiguration = result
        return productConfiguration;
    }

    private async viewLogTariffTransactionNotification(id: string): Promise<void> {
        try {
            if (!id) return this.formService.handleError('Selecione uma transação!');

            this.formService.block();
            let log: IViewLog = {
                operation: 'history',
                number: id,
                list: [],
                show: true,
                showCloseButton: false,
                searchQuery: '',
                originalList: [],
            }

            this.requestTariffNotificationLog(log.number).then(result => {
                log.list = result.data;
                log.originalList = angular.copy(log.list);
                this.$scope.log = log;
                angular.element('#log-viewer').removeClass('ng-hide');
                this.formService.unblock();
            }).catch(ex => {
                this.formService.handleError(ex);
            });

            const modalId = this.$scope.modalService.newModal();
            await this.$scope.modalService.showModalConfirmation(
                {
                    modalID: modalId,
                    scope: this.$scope,
                    template: require('../view/modals/offerLog.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG"
                }
            );
            this.$scope.modalService.closeModal(modalId);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getTariffTransactionTableOptions(): Promise<ITableOptions> {
        return {
            persistName: "tariffTransactionNotification",
            pagination: true,
            search: true,
            advancedSearch: false,
            showSearchClearButton: true,
            clickToSelect: true,
            singleSelect: true,
            showLoading: true,
            showColumns: true,
            showColumnsSearch: true,
            showColumnsToggleAll: true,
            showFullscreen: true,
            showToggle: false,
            showMultiSort: true,
            showMultiSortButton: true,
            height: '100%',
            filterControl: false,
            fixedColumns: true,
            fixedNumber: 1,
            sortable: true,
            serverSort: false,
            crudButtons: {
            },
            customToolbarButtons: [
                {
                    fn: () => { this.approveTariffTransactionNotification(this.$scope.transactionSelected) },
                    name: "log",
                    class: "btn btn-xs btn-success pull-right-xs approveButton",
                    icon: "fa fa-check icon",
                    label: "Aprovar",
                    disabled: true
                },
                {
                    fn: async () => { this.viewLogTariffTransactionNotification(this.$scope.transactionSelected._id.toString()); },
                    name: "log",
                    class: "btn btn-xs btn-info customButton",
                    icon: "fa fa-history",
                    disabled: true
                }

            ],
            formatAddLevel: () => this.formService.getTranslate("GENERAL.ADD_LEVEL"),
            formatCancel: () => this.formService.getTranslate("GENERAL.CLOSE"),
            formatColumn: () => this.formService.getTranslate("GENERAL.COLUMNS"),
            formatDeleteLevel: () => this.formService.getTranslate("GENERAL.REMOVE_LEVEL"),
            formatMultipleSort: () => this.formService.getTranslate("GENERAL.MULTIPLE_SORT"),
            formatOrder: () => this.formService.getTranslate("GENERAL.ORDER"),
            formatSort: () => this.formService.getTranslate("GENERAL.SORT"),
            formatSortBy: () => this.formService.getTranslate("GENERAL.SORT_BY"),
            formatSortOrders: () => { return { asc: this.formService.getTranslate("GENERAL.ASCENDING_SORT"), desc: this.formService.getTranslate("GENERAL.DESCENDING_SORT") } },
            formatThenBy: () => this.formService.getTranslate("GENERAL.AND_BY"),
            headerStyle: (column: BootstrapTableColumn) => {
                return {
                    CREATED_AT: {
                        css: { 'min-width': '150px' }
                    },
                    selected: {
                        css: { 'min-width': '80px' }
                    },
                }[column.field]
            },
            onCheck: (row: ITariffTransactionNotification, element): boolean => {
                angular.element(".logindividual").removeClass("disabled");
                angular.element(".logindividual").removeClass("btn-not-clickable");
                angular.element(".customButton").removeClass("disabled");
                angular.element(".customButton").removeClass("btn-not-clickable");
                if (row.SITUATION && row.SITUATION.ID == ETariffTransactionNotificationtSituationId.PENDING) {
                    angular.element(".approveButton").removeClass("disabled");
                    angular.element(".approveButton").removeClass("btn-not-clickable");
                } else if (!angular.element(".approveButton").hasClass("disabled")) {
                    angular.element(".approveButton").addClass("disabled")
                    angular.element(".approveButton").addClass("btn-not-clickable")
                }
                this.$scope.transactionSelected = row;
                return true;
            },
            onUncheck: (row): boolean => {
                angular.element(".logindividual").addClass("disabled");
                angular.element(".logindividual").addClass("btn-not-clickable");
                angular.element(".customButton").addClass("disabled");
                angular.element(".customButton").addClass("btn-not-clickable");
                angular.element(".approveButton").addClass("disabled");
                angular.element(".approveButton").addClass("btn-not-clickable");
                this.$scope.transactionSelected = null;
                return true;
            },

            onPageChange: () => {
                this.$timeout(() => {
                }, 200);

                return true;
            },
            showColumnsRename: [
                { dataField: "ID_TARIFF_TRANSACTION", title: this.formService.getTranslate("PRODUCT.AUTO_RATING_TRANSACTION_ID") },
                { dataField: "SITUATION", title: this.formService.getTranslate("GENERAL.SITUATION") },
                { dataField: "CHARGE_NAME_EXHIBITION", title: this.formService.getTranslate("GENERAL.CHARGES") },
                { dataField: "TARIFF_NATURE", title: this.formService.getTranslate("PRODUCT.TARIFF_TRANSACTION") },
                { dataField: "TARIFF_TYPE", title: this.formService.getTranslate("PRODUCT.ORIGIN_TARIFF") },
                { dataField: "ORIGIN_TYPE", title: this.formService.getTranslate("BASIC_DATA.PAYMENT_NATURE") },
                { dataField: "NEW_CURRENCY", title: this.formService.getTranslate("GENERAL.NEW_CURRENCY") },
                { dataField: "NEW_MIN", title: this.formService.getTranslate("GENERAL.NEW_MIN") },
                { dataField: "NEW_UNITARY", title: this.formService.getTranslate("GENERAL.NEW_UNITARY") },
                { dataField: "OLD_CHARGE_VALUE_CURRENT_CURRENCY", title: this.formService.getTranslate("GENERAL.OLD_CHARGE_VALUE_CURRENT_CURRENCY") },
                { dataField: "NEW_CHARGE_VALUE_CURRENT_CURRENCY", title: this.formService.getTranslate("GENERAL.NEW_CHARGE_VALUE_CURRENT_CURRENCY") },
                { dataField: "OLD_VALIDITY_START", title: this.formService.getTranslate("GENERAL.NEW_CHARGE_VALIDITY_START") },
                { dataField: "NEW_VALIDITY_START", title: this.formService.getTranslate("GENERAL.OLD_CHARGE_VALIDITY_START") },
                { dataField: "OLD_VALIDITY_END", title: this.formService.getTranslate("GENERAL.NEW_CHARGE_VALIDITY_END") },
                { dataField: "NEW_VALIDITY_END", title: this.formService.getTranslate("GENERAL.OLD_CHARGE_VALIDITY_END") },
                { dataField: "CREATED_AT", title: this.formService.getTranslate("GENERAL.CREATED_AT") },
            ],
            columns: [
                {
                    field: 'selected', title: this.formService.getTranslate("GENERAL.UI_SELECT.SELECT"), sortable: false, searchable: false, checkbox: true, showSelectTitle: true,
                },
                {
                    field: 'status', title: this.formService.getTranslate("OPERATIONAL.STATUS"),
                    formatter: (cell, row) => {
                        return (
                            `<span>
                                <a class="text-success" ng-if="${row.SITUATION.ID != '3'}"
                                    style="margin-right: 2px;margin-left: 2px; cursor: default;"
                                    ng-disabled="${row.SITUATION.ID != '1'}">
                                    <i class="fa fa fa-check icon" aria-hidden="true"
                                    tooltip-placement="auto top" uib-tooltip="${row.SITUATION.NAME}"
                                    tooltip-append-to-body="true"></i>
                                </a>
                                <a class="text-red" ng-if="${row.SITUATION.ID == '3'}"
                                    style="margin-right: 2px;margin-left: 2px; cursor: default;">
                                    <i class="fa fa fa-ban icon" aria-hidden="true"
                                    tooltip-placement="auto top" uib-tooltip="${row.SITUATION.NAME}"
                                    tooltip-append-to-body="true"></i>
                                </a>
                                <a ng-if="${row.TYPE.ID == '3'} || ${row.TYPE.ID == '2'}" 
                                    style="margin-right: 2px; cursor: default;"
                                    ng-class="${row.TYPE.ID == '3'}? 'text-danger' : 'text-warning'">
                                    <i class="fa fa-exclamation-triangle icon"></i>
                                </a>
                            </span>`
                        );
                    }
                },
                { field: 'ID_TARIFF_TRANSACTION', title: this.formService.getTranslate("PRODUCT.AUTO_RATING_TRANSACTION_ID"), sortable: false, searchable: true, visible: true },
                { field: 'SITUATION', title: this.formService.getTranslate("GENERAL.SITUATION"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: true },
                { field: 'CHARGE_NAME_EXHIBITION', title: this.formService.getTranslate("GENERAL.CHARGES"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true, visible: true },
                { field: 'TARIFF_NATURE', title: this.formService.getTranslate("PRODUCT.TARIFF_TRANSACTION"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: true },
                { field: 'TARIFF_TYPE', title: this.formService.getTranslate("PRODUCT.ORIGIN_TARIFF"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: true },
                { field: 'ORIGIN_TYPE', title: this.formService.getTranslate("BASIC_DATA.PAYMENT_NATURE"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true, visible: true },
                { field: 'NEW_CURRENCY', title: this.formService.getTranslate("GENERAL.NEW_CURRENCY"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true, visible: true },
                { field: 'NEW_MIN', title: this.formService.getTranslate("GENERAL.NEW_MIN"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: true },
                { field: 'NEW_UNITARY', title: this.formService.getTranslate("GENERAL.NEW_UNITARY"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: true },
                { field: 'OLD_CHARGE_VALUE_CURRENT_CURRENCY', title: this.formService.getTranslate("GENERAL.OLD_CHARGE_VALUE_CURRENT_CURRENCY"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: true },
                { field: 'NEW_CHARGE_VALUE_CURRENT_CURRENCY', title: this.formService.getTranslate("GENERAL.NEW_CHARGE_VALUE_CURRENT_CURRENCY"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: true },
                { field: 'OLD_VALIDITY_START', title: this.formService.getTranslate("GENERAL.OLD_CHARGE_VALIDITY_START"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true, visible: true },
                { field: 'NEW_VALIDITY_START', title: this.formService.getTranslate("GENERAL.NEW_CHARGE_VALIDITY_START"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true, visible: true },
                { field: 'OLD_VALIDITY_END', title: this.formService.getTranslate("GENERAL.OLD_CHARGE_VALIDITY_END"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true, visible: true },
                { field: 'NEW_VALIDITY_END', title: this.formService.getTranslate("GENERAL.NEW_CHARGE_VALIDITY_END"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true, visible: true },
                { field: 'CREATED_AT', title: this.formService.getTranslate("GENERAL.CREATED_AT"), showSelectTitle: true, formatter: (data) => this.$filter("datetimeformated")(data) },
            ],
        };
    }

    private async setNumberOfAutoRatingErros(offerId: number) {
        this.$scope.numberOfAutoRatingErrors = await this.offerChargeHelper.countAutoRatingErrors(offerId.toString());

    }
}

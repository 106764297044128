import angular = require('angular');
import moment = require('moment');
import { FormService2 } from '@services/FormService2';
import { IModalService } from '@services/ModalService';
import { ISessionService } from "@services/SessionService";
import { DataProcessService } from '@services/DataProcessService';
import { IViewLog } from "@models/interface/common/IViewLog";
import { EVENT } from '@models/interface/operational/NewProcess';
import { NewProcessEvent } from "@models/interface/operational/NewProcessEvent";
import { ISelectorModel } from '@models/mongo/SelectorModel';
import { EDirectionId, EOperation, EPaymentNatureId } from '@enums/GenericData';
import { INewProcessDetDemScope, ECollapseState } from './NewProcessDetDemController';
import { IProcessDetDemManagement } from 'WBA-Model/dist/interface/dataProcess/ProcessDetDemManagement';
import { IPerDiemTable, IProcessDetDemManagementDetail } from 'WBA-Model/dist/interface/dataProcess/ProcessDetDemManagementDetail';

interface IProcessDetDemManagementDetailGrid {
    _id: string | object;
    ID_DET_DEM_MANAGEMENT: string | object;
    ORIGIN: ISelectorModel;
    PAYMENT_NATURE: ISelectorModel;
    TARIFF_TYPE: ISelectorModel;
    TYPE: string;
    CHARGE: ISelectorModel;
    FREE_TIME: number;
    FREE_TIME_ADITIONAL: number;
    REFERENCE_START: ISelectorModel;
    START_RULE: string;
    REFERENCE_END: ISelectorModel;
    END_RULE: string;
    ESTIMATED_END: Date;
    RULE: ISelectorModel;
    PROGRESSIVE: boolean;
    DAYS_USED: number;
    INCIDENCE: number;
    EQUIPMENT_SITUATION: ISelectorModel;
    ID: number;
    PER_DIEM_TABLE: IPerDiemTable[];
    TOTAL_INCIDENCE: number;
    ALL_TOTAL_VALUE: number;
    CREATED_AT: Date;
    CREATED_BY: ISelectorModel;
    UPDATED_AT: Date;
    UPDATED_BY: ISelectorModel;
}

interface INewProcessDetDemContainerScope extends ng.IScope {
    log: IViewLog;
    model: IProcessDetDemManagement[];
    eventMaritimeServices: ISelectorModel[];
    eventModal: NewProcessEvent;
    newEventModalID: number;
    newEventsData: EVENT[];
    newEventsOptions: ISelectorModel[];
    eventDetailModalID: number;
    processNumber: string;
    monacoEventList: any[];
    product: ISelectorModel;
    sessionService: ISessionService;
    detDemManagementOriginDetails: IProcessDetDemManagementDetail[];
    detDemManagementDestinationDetails: IProcessDetDemManagementDetail[];
    collapseContainer: () => void;
    isCollapseIn(): boolean;
    convertToUTC: (date: Date) => void;
    viewDetDemManagementDetails: (detDemManagement: IProcessDetDemManagement) => Promise<void>;
    filterPayment: (detail: IProcessDetDemManagementDetail) => boolean;
    filterReceiving: (detail: IProcessDetDemManagementDetail) => boolean;
    hasPayment: (detail: IProcessDetDemManagementDetail[]) => boolean;
    hasReceiving: (detail: IProcessDetDemManagementDetail[]) => boolean;
}

export class NewProcessDetDemContainerController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: INewProcessDetDemContainerScope;
    private $newProcessDetDemScope: INewProcessDetDemScope;
    private $q: ng.IQService;
    private dataProcessService: DataProcessService;
    private formService: FormService2;
    private modalService: IModalService;

    constructor($injector: ng.Injectable<any>, $scope: INewProcessDetDemContainerScope) {
        this.$scope = $scope;
        this.$newProcessDetDemScope = <INewProcessDetDemScope>$scope.$parent.$parent;
        this.$q = $injector.get('$q');
        this.dataProcessService = $injector.get('DataProcessService');
        this.formService = this.$newProcessDetDemScope.formService;
        this.modalService = this.$newProcessDetDemScope.modalService;
        this.$scope.sessionService = $injector.get('SessionService');
        this.initScopeFunctions();
    }

    async $onInit(): Promise<void> {
        try {
            this.initModel();
            await this.initDependencies();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initModel(): void {
        this.$scope.model = [{
            _id: null,
            CONTAINER_NUMBER: null,
            CONTAINER_TYPE: null,
            CREATED_AT: null,
            CREATED_BY: null,
            CUSTOMER: null,
            DOCUMENT_HBL: null,
            DOCUMENT_MBL: null,
            EVENT: null,
            EVENT_CONTAINER_STATUS: null,
            FILE_NUMBER_PAYMENT_STATUS: null,
            FILE_NUMBER_RECEIVING_STATUS: null,
            FTHD: null,
            FTHO: null,
            FTMD: null,
            FTMO: null,
            ID: null,
            ID_PROCESS: null,
            ID_PROCESS_DET_DEM: null,
            INCIDENCE_PAYMENT_DESTINATION: null,
            INCIDENCE_PAYMENT_ORIGIN: null,
            INCIDENCE_RECEIVING_DESTINATION: null,
            INCIDENCE_RECEIVING_ORIGIN: null,
            OBSERVATION_PAYMENT: null,
            OBSERVATION_RECEIVING: null,
            PROCESS_NUMBER: null,
            PRODUCT: null,
            SERVICE_PROVIDER: null,
            SITUATION_DESTINATION_PAYMENT: null,
            SITUATION_DESTINATION_RECEIVING: null,
            SITUATION_ORIGIN_PAYMENT: null,
            SITUATION_ORIGIN_RECEIVING: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            VALIDATION_RESULT: null,
            BOOKING: null,
            PROCESS_TYPE: null,
            INSTRUCTION: null
        }]
    }

    private initScopeFunctions(): void {

        this.$scope.collapseContainer = (): void => {
            this.collapseContainer();
        }

        this.$scope.isCollapseIn = (): boolean => {
            return this.$newProcessDetDemScope.collapseState.panel == ECollapseState.CONTAINERS && !this.$newProcessDetDemScope.collapseState.released;
        }

        this.$scope.convertToUTC = (date: Date) => {
            if (date) return moment.utc(date).format("DD/MM/YYYY HH:mm:ss");
            return null;
        };

        this.$scope.viewDetDemManagementDetails = async (detDemManagement: IProcessDetDemManagement): Promise<void> => {
            this.viewDetDemManagementDetails(detDemManagement);
        }

        this.$scope.filterPayment = (detail: IProcessDetDemManagementDetail): boolean => {
            return detail.PAYMENT_NATURE.ID == EPaymentNatureId.PAYMENT
        }
        this.$scope.filterReceiving = (detail: IProcessDetDemManagementDetail): boolean => {
            return detail.PAYMENT_NATURE.ID == EPaymentNatureId.RECEIVING
        }
        this.$scope.hasPayment = (details: IProcessDetDemManagementDetail[]): boolean => {
            return details && details.find(x => x.PAYMENT_NATURE.ID == EPaymentNatureId.PAYMENT) ? true : false;
        }
        this.$scope.hasReceiving = (details: IProcessDetDemManagementDetail[]): boolean => {
            return details && details.find(x => x.PAYMENT_NATURE.ID == EPaymentNatureId.RECEIVING) ? true : false;
        }

    }

    async initDependencies(): Promise<any> {
        const self: NewProcessDetDemContainerController = this;
        this.initCollapseContainer();

        this.$scope.$watch(() => this.$newProcessDetDemScope.model.PROCESS_NUMBER, () => {
            this.$scope.processNumber = this.$newProcessDetDemScope.model.PROCESS_NUMBER;
        });

        // get generics
        new Promise(function (resolve, reject) {
            self.$q.all([
            ]).then(async (result: any) => {
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private initCollapseContainer() {
        this.$scope.$on('processContainerCollapse', () => {
            this.collapseContainer();
        });

        const collapseContainer = angular.element('#collapseContainers');
        if (collapseContainer) {
            collapseContainer.on('shown.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    await this.getProcessTabsEvent();
                    angular.element("#collapseContainerHeading").attr('aria-expanded', 'true');
                    // update collapse state
                    this.$newProcessDetDemScope.collapseState = { panel: ECollapseState.CONTAINERS, released: false, nextState: null };
                    this.$newProcessDetDemScope.repositionPanels(ECollapseState.CONTAINERS, true);
                    this.$newProcessDetDemScope.disableElements(this.$newProcessDetDemScope.operation == EOperation.VIEW);
                }
            });
            collapseContainer.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseContainerHeading").attr('aria-expanded', 'false');
                    this.$scope.model = null;
                }
            });
        }
    }

    private async getProcessTabsEvent(): Promise<void> {
        this.formService.block();
        try {
            const timeout: number = 120000;
            const mainContainer = await this.dataProcessService.get(`/processDetDem/${this.$newProcessDetDemScope.model.ID}/management`, timeout);
            if (mainContainer && mainContainer.data && mainContainer.data.data) this.$scope.model = mainContainer.data.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async collapseContainer(): Promise<void> {
        try {
            if (this.$newProcessDetDemScope.collapseState.released || this.$newProcessDetDemScope.collapseState.panel == ECollapseState.CONTAINERS) {
                const collapseEvent = angular.element("#collapseContainers");
                if (collapseEvent) {
                    const isCollapsed = angular.element("#collapseContainerHeading").attr("aria-expanded") == "true";
                    if (isCollapsed) {
                        this.$newProcessDetDemScope.collapseState.released = true;
                    }
                    collapseEvent['collapse']('toggle');
                    if (isCollapsed) this.$newProcessDetDemScope.repositionPanels(ECollapseState.CONTAINERS);
                }
            } else {
                this.$newProcessDetDemScope.collapseState.nextState = ECollapseState.CONTAINERS;
                this.$newProcessDetDemScope.releaseCollapse();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async viewDetDemManagementDetails(detDemManagement: IProcessDetDemManagement): Promise<void> {
        try {
            this.formService.block();
            const request = await this.dataProcessService.get("/processDetDemManagement/details/" + detDemManagement._id, null);
            if (request && request.data && request.data.data) {
                const details: IProcessDetDemManagementDetailGrid[] = request.data.data; //: IProcessDetDemManagementDetail[] 
                if (details && details.length > 0) {
                    details.forEach(item => {
                        if (item.PER_DIEM_TABLE) {
                            item.PER_DIEM_TABLE.forEach(itemPer => {
                                item.TOTAL_INCIDENCE = item.TOTAL_INCIDENCE ? item.TOTAL_INCIDENCE + itemPer.INCIDENCE : itemPer.INCIDENCE;
                                item.ALL_TOTAL_VALUE = item.ALL_TOTAL_VALUE ? item.ALL_TOTAL_VALUE + itemPer.TOTAL_VALUE_INCIDENCE : itemPer.TOTAL_VALUE_INCIDENCE;
                            })
                        }
                        return item;
                    });
                }
                const origin = details.filter(detail => detail.ORIGIN && detail.ORIGIN.ID == EDirectionId.ORIGIN);
                const destination = details.filter(detail => detail.ORIGIN && detail.ORIGIN.ID == EDirectionId.DESTINATION);


                this.$scope.detDemManagementOriginDetails = origin;
                this.$scope.detDemManagementDestinationDetails = destination;
                const modalId = this.modalService.newModal();
                this.modalService.showModalInfo(
                    {
                        modalID: modalId,
                        scope: this.$scope,
                        formService: EOperation.VIEW,
                        size: 'full modal-overflow',
                        template: require("../view/modal/processDetDemManagementDetailsModal.html"),
                        keyboard: false
                    },
                    null
                );
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

}
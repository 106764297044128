import { IMonacoController } from "../../common/MonacoInterface";
import { ISessionService } from "@services/SessionService";
import { MonitorStatusService } from "../../app/services/MonitorStatusService";
import { BrowserTitle } from "../../common/BrowserTitle";

interface IServiceStatusScope extends ng.IScope {
    serviceStatusList: Array<any>;
}

export class ServiceStatusController implements IMonacoController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IServiceStatusScope;
    private $injector: ng.Injectable<any>;
    private blockUI: ng.blockUI.BlockUIService;
    private sessionService: ISessionService;

    constructor($injector: ng.Injectable<any>, $scope: IServiceStatusScope) {
        this.$scope = $scope;
        this.$injector = $injector;
        this.blockUI = $injector.get('blockUI');
        this.sessionService = $injector.get('SessionService');
    }

    async $onInit(): Promise<void> {
        try {
            BrowserTitle.$menu = "{{'GENERAL.MANAGEMENT' | translate }}";
            this.$scope.serviceStatusList = new Array<any>();
            await this.listServiceStatus();
            BrowserTitle.$id = "{{'GENERAL.MENU.SERVICE_STATUS' | translate }}";
        } catch (ex) {
            this.blockUI.stop();
            this.sessionService.redirectToError(ex);
        }
    }

    async $onDestroy(): Promise<void> {
        BrowserTitle.$id = null;
    }

    async listServiceStatus() {
        try {
            this.blockUI.start();

            if (!MonitorStatusService.Instance.$initialized) MonitorStatusService.Instance.init(this.$injector);
            this.$scope.serviceStatusList = await MonitorStatusService.Instance.updateServiceStatusList();
            this.$scope.$applyAsync();

            this.blockUI.stop();
        } catch (ex) {
            throw ex;
        }
    }

}

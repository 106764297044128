import * as angular from 'angular';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from '@services/GridFormService';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IApplicationModel } from '@models/interface/product/ApplicationModel';
import { GridColumnBuilder } from '../../common/GridColumnBuilder';
import { IMonacoColumnDef } from '@services/GridService2';
import { BrowserTitle } from "../../common/BrowserTitle";
import { SelectorModel } from '../../common/model/SelectorModel';
import { IRestService } from '@services/RestService';
import { ISessionService } from '@services/SessionService';
import { SSEService } from '@appServices/SSEService';
import { EOperation } from '@enums/GenericData';
import { HelperService } from "@services/HelperService";
import { IDocumentError } from '@models/interface/common/IDocumentError';
import { IModalService } from "@services/ModalService";

interface IApplicationScope extends IGridFormServiceScope {
    model: IApplicationModel;
    log: IViewLog;
    gridOptions: uiGrid.IGridOptions;
    scopeBeforeSave: IApplicationModel;
    customLogProperties: ICustomLogProperties[];
    productList: SelectorModel[];
    typeCargoList: SelectorModel[];
    applicationComplementList: SelectorModel[];
    apportionmentParamList: SelectorModel[];
    selectedApplicationComplement: SelectorModel;
    user: any;
    sessionService: ISessionService;
    editApplication: (application: IApplicationModel) => Promise<void>;
    viewApplication: (application: IApplicationModel) => Promise<void>;
    viewLogApplication: (application: IApplicationModel) => Promise<void>;
    copyApplication: (application: IApplicationModel) => Promise<void>;
    checkComplement: () => void;
    productListFilter: () => Function;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
}

export class ApplicationRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IApplicationScope;
    private RestService: IRestService;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService
    private SSEService: SSEService;
    private gridName: string;
    private helperService: HelperService;
    private ModalService: IModalService;
    private modalID: number;

    constructor($injector: ng.Injectable<any>, $scope: IApplicationScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.$timeout = $injector.get('$timeout');
        this.$scope.sessionService = $injector.get('SessionService');
        this.gridName = 'GRID_APPLICATION';
        this.SSEService = new SSEService($injector, $scope);
        this.helperService = $injector.get('HelperService');
        this.ModalService = $injector.get('ModalService');
        this.modalID = null;
    }

    getUrlProduct(): string {
        const baseRout = '/product';
        const urlProduct = this.config.productUrl + baseRout;
        return urlProduct;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();

            this.$scope.customLogProperties = this.getCustomLogProperties();

            this.initForm(this, 'form', 'application', 'GENERAL.MENU.APPLICATION', true);
            await this.initGrid(this.gridName, '/application/list', true, true, null, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editApplication = async (application: IApplicationModel): Promise<void> => {
            if (application && application.ID == 28) return; // disable o edit application for flexitanq integration
            let blockedObject = {
                ID: application.ID,
                NAME: application.NAME,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || application.ID !== this.$scope.model.ID) this.$scope.view(application);
                } else if (this.$scope.operation !== EOperation.EDIT || application.ID !== this.$scope.model.ID) {
                    this.$scope.edit(application);
                }
            };
        }

        this.$scope.viewApplication = async (application: IApplicationModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(application);
        }

        this.$scope.viewLogApplication = async (application: IApplicationModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(application);
        }

        this.$scope.copyApplication = async (application: IApplicationModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(application);
        }

        this.$scope.checkComplement = () => {
            return this.checkComplement();
        }

        this.$scope.productListFilter = (): Function => {
            return this.productListFilter();
        }

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }
    }

    private productListFilter = (): Function => {
        if (this.$scope.model.APPLICATION_COMPLEMENT && this.$scope.model.APPLICATION_COMPLEMENT.ID != "1") {
            const selectedProduct = this.$scope.model ? this.$scope.model.PRODUCT : null;
            const isAir = selectedProduct && selectedProduct.length && (selectedProduct[0].ID == "EA" || selectedProduct[0].ID == "IA");
            const isMaritime = selectedProduct && selectedProduct.length && (selectedProduct[0].ID == "EM" || selectedProduct[0].ID == "IM");
            const isRoad = selectedProduct && selectedProduct.length && (selectedProduct[0].ID == "ER" || selectedProduct[0].ID == "IR");
            return function (product: SelectorModel) {
                if (!selectedProduct || selectedProduct && selectedProduct.length == 0) return true;
                if (isAir && (product.ID == "EA" || product.ID == "IA")) return true;
                if (isMaritime && (product.ID == "EM" || product.ID == "IM")) return true;
                if (isRoad && (product.ID == "ER" || product.ID == "IR")) return true;
                return false;
            }
        }
    }

    private checkComplement() {
        if (this.$scope.selectedApplicationComplement && this.$scope.model.APPLICATION_COMPLEMENT && (this.$scope.selectedApplicationComplement.ID != this.$scope.model.APPLICATION_COMPLEMENT.ID)) {
            if (this.$scope.selectedApplicationComplement.ID == '1') {
                this.$scope.model.PRODUCT = null;
            }
        }
        this.$scope.selectedApplicationComplement = this.$scope.model.APPLICATION_COMPLEMENT;
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            INTERNAL_SEQUENCE: null,
            ACTIVE: true,
            CODE: null,
            NAME: null,
            NAME_INTL: null,
            PRODUCT: null,
            TYPE_CARGO: null,
            PERCENT: false,
            FREE_TYPING_AMOUNT_CHARGE: false,
            APPLICATION_COMPLEMENT: null,
            INTEGRATION_ID: null,
            CT_WITHOUT_DOC: false,
            ALLOWS_APPORTIONMENT: false,
            APPORTIONMENT_PARAM: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            SEARCH_FIELDS: null,
            DOCUMENT_ERROR: null
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewApplication(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-disabled="row.entity.ID == 28" ng-click="grid.appScope.editApplication(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogApplication(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyApplication(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy + modalIntegration),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };

        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    private buildColumns(columns: string[]): IMonacoColumnDef[] {
        let gridColumns: Array<IMonacoColumnDef> = [];

        const colCode: IMonacoColumnDef = { name: 'CODE', displayName: 'GENERAL.CODE', width: 150 };
        const colName: IMonacoColumnDef = { name: 'NAME', displayName: 'GENERAL.NAME', width: 350 };
        const colNameIntl: IMonacoColumnDef = { name: 'NAME_INTL', displayName: 'GENERAL.NAME_INTL', width: 350 };
        const colProduct: IMonacoColumnDef = { name: 'PRODUCT.ID', displayName: 'BASIC_DATA.PRODUCT', width: 350, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PRODUCT, null, "ID")}}</div>' };
        const colTypeCargo: IMonacoColumnDef = { name: 'TYPE_CARGO.NAME', displayName: 'BASIC_DATA.CARGO_TYPE', width: 350, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TYPE_CARGO, null, "NAME")}}</div>' };
        const colPercent: IMonacoColumnDef = { name: 'PERCENT', displayName: 'REGISTRATION.PERCENTAGE', width: 100, cellFilter: 'YesOrNo' };
        const colApplicationComplement: IMonacoColumnDef = { name: 'APPLICATION_COMPLEMENT.NAME', displayName: 'FINANCIAL.CHARGE_BASIS_COMPLEMENT', width: 140 };
        const colActive: IMonacoColumnDef = { name: 'ACTIVE', displayName: 'GENERAL.ACTIVE', width: 70, cellFilter: 'YesOrNo' };
        const colIntegrationID: IMonacoColumnDef = { name: 'INTEGRATION_ID', displayName: 'GENERAL.INTEGRATION_ID', width: 150 };
        const colContribuition: IMonacoColumnDef = { name: 'CT_WITHOUT_DOC', displayName: 'REGISTRATION.CONTRIBUTION_WITHOUT_DOCS', width: 100, cellFilter: 'YesOrNo' };
        const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
        const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
        const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

        columns.forEach(column => {
            switch (column) {
                case 'CODE':
                    gridColumns.push(colCode);
                    break;
                case 'NAME':
                    gridColumns.push(colName);
                    break;
                case 'NAME_INTL':
                    gridColumns.push(colNameIntl);
                    break;
                case 'PRODUCT':
                    gridColumns.push(colProduct);
                    break;
                case 'TYPE_CARGO':
                    gridColumns.push(colTypeCargo);
                    break;
                case 'PERCENTUAL':
                    gridColumns.push(colPercent);
                    break;
                case 'APPLICATION_COMPLEMENT':
                    gridColumns.push(colApplicationComplement);
                    break;
                case 'ACTIVE':
                    gridColumns.push(colActive);
                    break;
                case 'INTEGRATION_ID':
                    gridColumns.push(colIntegrationID);
                    break;
                case 'CT_WITHOUT_DOC':
                    gridColumns.push(colContribuition);
                    break;
                case 'ID':
                    gridColumns.push(colId);
                    break;
                case 'CREATED_AT':
                    gridColumns.push(colCreatedAt);
                    break;
                case 'UPDATED_AT':
                    gridColumns.push(colUpdatedAt);
                    break;
            }
        });

        return gridColumns;
    }

    initDependencies(): Promise<any> {
        const self: ApplicationRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getProductList(),
                self.getTypeCargoList(),
                self.getApplicationComplementList(),
                self.getApportionmentParamList()
            ]).then((result: any) => {
                self.$scope.productList = result[0];
                self.$scope.typeCargoList = result[1];
                self.$scope.applicationComplementList = result[2];
                self.$scope.apportionmentParamList = result[3];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTypeCargoList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_cargo`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getApplicationComplementList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/application_complement`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getApportionmentParamList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/apportionment_param`, null);
        return generic && generic.data ? generic.data : [];
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} (${this.$scope.model.NAME})`;
            BrowserTitle.$id = this.$scope.model.NAME;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} (${this.$scope.model.NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.NAME;
            this.$scope.selectedApplicationComplement = this.$scope.model.APPLICATION_COMPLEMENT;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "GENERAL.MENU.INTEGRATION" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number, idSubject: string): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/application/sendSync`, { "idApplication": id, "idSubject": idSubject }, 120000, false);
                if (syncRequest) success = true;
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_SENDING_REQUEST');
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const legalPersonData = await this.getApplicationById(id);
                    if (row && legalPersonData && legalPersonData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = legalPersonData.DOCUMENT_ERROR;
                        documentError = legalPersonData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async getApplicationById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/application/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        const operation = this.$scope.operation === 'register' ? 'insert' : 'update';
        return {
            route: `/application/${operation}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'ID',
                LABEL: 'GENERAL.CODE'
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
            {
                PROPERTY: 'CODE',
                LABEL: 'GENERAL.CODE'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'NAME_INTL',
                LABEL: 'GENERAL.NAME_INTL'
            },
            {
                PROPERTY: 'PRODUCT',
                LABEL: 'BASIC_DATA.PRODUCT'
            },
            {
                PROPERTY: 'TYPE_CARGO',
                LABEL: 'BASIC_DATA.CARGO_TYPE'
            },
            {
                PROPERTY: 'PERCENT',
                LABEL: 'REGISTRATION.PERCENTAGE'
            },
            {
                PROPERTY: 'APPLICATION_COMPLEMENT',
                LABEL: 'FINANCIAL.CHARGE_BASIS_COMPLEMENT'
            },
            {
                PROPERTY: 'INTEGRATION_ID',
                LABEL: 'GENERAL.INTEGRATION_ID'
            },
            {
                PROPERTY: 'CT_WITHOUT_DOC',
                LABEL: 'REGISTRATION.CONTRIBUTION_WITHOUT_DOCS'
            },
            {
                PROPERTY: 'INTERNAL_SEQUENCE',
                LABEL: 'REGISTRATION.INTERNAL_SEQUENCE'
            },
            {
                PROPERTY: 'FREE_TYPING_AMOUNT_CHARGE',
                LABEL: 'REGISTRATION.FREE_FIELD_CHANGE_QUANTITY'
            },
            {
                PROPERTY: 'ALLOWS_APPORTIONMENT',
                LABEL: 'OPERATIONAL.ALLOWS_APPORTIONMENT'
            },
            {
                PROPERTY: 'APPORTIONMENT_PARAM',
                LABEL: 'OPERATIONAL.APPORTIONMENT_PARAM'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            }
        ]
        return props;
    }
}

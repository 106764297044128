import * as angular from "angular";
import * as moment from "moment";
import { SSEService } from '@appServices/SSEService';
import { IModalInstanceService } from "angular-ui-bootstrap";
import { fileUploader as FileUploader } from 'angular-file-upload';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog, IMonacoRequest } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { ISessionService } from "@services/SessionService";
import { EOperation, ETariffFreightPerDiemType, EPaymentNatureId, ETariffTypeDetDem, ETariffChangeConfirmationReasonValue, ETariffChangeConfirmationReasonValidity, ERoutingPointType, EDirectionId, ECargoTypeId, EProcessEventTransportModeId } from "@enums/GenericData";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IFreightRoutes as IFreightRoutesModel, IEquipmentFreeTime, IFreightRoutesCharge, IDynamicField, IEquipmentWeightRangeVehicle } from "@models/interface/product/FreightRoutes";
import { IFreightRoutesDailyRate } from "@models/interface/product/FreightRoutesDailyRate";
import { SelectorModel } from "../../common/model/SelectorModel";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { IMoveTypeModel, IParam } from "@models/interface/product/MoveTypeModel";
import { IRoutingPointSelector } from "@models/interface/product/RoutingPoint";
import { HelperService } from "@services/HelperService";
import { IAirServiceListCustomFilter } from "@models/interface/product/AirServiceModel";
import { EProductId, EProviderTypeId, EApplicationComplementId, EMoveTypeId } from "@enums/GenericData";
import { ValidateUtil } from "../../common/util/ValidateUtil";
import { StringUtil } from "../../common/util/StringUtil";
import { IEquipmentSelector } from "@models/interface/product/EquipmentModel";
import { IIntegrationRedundanceModalParams, IModalService } from '@services/ModalService';
import { ILinkParameter, ILinkFreightRoutesParameter, IOfferParameter, ITariffFreightAirParameter } from "../../common/model/ModelParameter";
import { ITablePerDiemQueryFields } from "@models/interface/product/TablePerDiem";
import { INewFreightContract } from "@models/interface/product/NewFreightContract";
import { ProductService } from "@services/ProductService";
import { IFloatingMenu, IFloatingOption } from "src/ts/common/interface/IFloatingMenu";
import { IUploader, IUploadItem, IFileItem, IFormData, ISuccessItemResponse } from "@models/interface/common/IMonacoUpload";
import { ITableOptions } from "src/ts/app/directives/monaco-data-table";
import { IApplicationList } from "@models/interface/product/ApplicationModel";
import { ITariffChangeConfirmation, IReasonUpdateModal } from '@models/interface/product/TariffChangeConfirmation';
import { IGridRow } from "ui-grid";
import { IFreightWizard } from "@models/interface/product/FreightWizard";
import { ISelectorModel } from "@models/mongo/SelectorModel";
import { NotificationService } from "@services/NotificationService";
import { IOfferExchangeData } from "../model/OfferModel";
import { IDocumentError } from "WBA-Model/dist/interface/common/IDocumentError";

interface IChargeTableFilter {
    SHOW_ONLY_CURRENT_CHARGES: boolean;
    LOAD_REFERENCE: Date;
}

interface IFreightRoutesScope extends IGridFormServiceScope {
    model: IFreightRoutesModel;
    oldModel: IFreightRoutesModel;
    dailyRouteModel: IFreightRoutesDailyRate[];
    oldDailyRouteModel: IFreightRoutesDailyRate[];
    log: IViewLog;
    moveTypeList: SelectorModel[];
    moveTypeFullList: IMoveTypeModel[];
    airServiceList: SelectorModel[];
    maritimeServiceList: SelectorModel[];
    serviceLevelList: SelectorModel[];
    serviceTypeList: SelectorModel[];
    detailsList: SelectorModel[];
    eventList: SelectorModel[];
    processEventTransportModeList: SelectorModel[];
    routingPointList: IRoutingPointSelector[];
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IFreightRoutesModel;
    sessionService: ISessionService;
    isAirProduct: boolean;
    isLandProduct: boolean;
    isMaritimeProduct: boolean;
    perDiemTypeList: SelectorModel[];
    detDemTypeList: SelectorModel[];
    freeTimeTypeList: SelectorModel[];
    equipmentFreeTimeList: SelectorModel[];
    tablePerDiemDefaultContractOrigin: boolean;
    tablePerDiemDefaultContractDestination: boolean;
    tablePerDiemList: SelectorModel[];
    freightContractList: INewFreightContract[];
    allogFreightContractList: SelectorModel[];
    menuFloating: IFloatingMenu;
    uploader: IUploader;
    user: any;
    chargesTableOptions: ITableOptions;
    chargesListDisplay: IFreightRoutesCharge[];
    chargeTableControl: {
        data: IFreightRoutesCharge[];
        chargeFilter: IChargeTableFilter;
    };
    holderTypeList: SelectorModel[];
    reasonValueChangeList: SelectorModel[];
    reasonValidityChangeList: SelectorModel[];
    reasonUpdateModal: IReasonUpdateModal;
    tariffChangeConfirmation: ITariffChangeConfirmation[];
    routeEquipmentList: IEquipmentSelector[];
    vehicleTypeList: ISelectorModel[];
    vehicleTypeListWeightLimit: ISelectorModel[];
    selectedRows: IFreightRoutesModel[];
    editFreightRoutes: (freightRoute: IFreightRoutesModel) => Promise<void>;
    viewFreightRoutes: (freightRoute: IFreightRoutesModel) => Promise<void>;
    viewLogFreightRoutes: (freightRoute: IFreightRoutesModel) => Promise<void>;
    goToOfferWizard: (freightRoute: IFreightRoutesModel) => void;
    copyFreightRoutes: (freightRoute: IFreightRoutesModel) => Promise<void>;
    fetchData: (id: number, action: string) => Promise<void>;
    checkDateValidity: (initialDate: Date, finalDate: Date, scopeName: string) => void;
    checkRouteDateValidity: (type: string) => void;
    checkDateIsBetween: (type: string) => void; 
    getAirServiceListByName: (search: string) => Promise<void>;
    getMaritimeServiceListByName: (search: string) => Promise<void>;
    getRoutingPointListByName: (name: string, abbreviation: string) => Promise<void>;
    generateConcatenatedOriginDestination(): void;
    generateConcatenated(): void;
    addDetDemRoute: () => void;
    removeDetDemRoute: () => void;
    collapseHeader: (elementId: string, state?: string) => void;
    addRouteEquipmentFreeTime: (originDestination: number) => void;
    getEquipmentListForDetDem: (search: string) => Promise<void>;
    removeDetDemRouteEquipment: (index: number, property: string) => void;
    checkPerDiemTypeChangeRoute: (originDestination: number) => void;
    checkReadOnlyRoute: (originDestination: number, perDiemTypeId: string) => boolean;
    setTablePerDiemRoute: (originDestination: number) => void;
    goToTablePerDiem: (tablePerDiemId: number) => void;
    getTablePerDiemList: (direction: string, tariffTypeId: string) => Promise<void>;
    checkRouteFreeTimeSplittedDetention: (transactionId: string, originDestination: number, index: number) => void;
    checkRouteFreeTimeSplittedDemurrage: (transactionId: string, originDestination: number, index: number) => void;
    checkRouteFreeTimeCombined: (transactionId: string, originDestination: number, index: number) => void;
    checkProductId: () => boolean;
    initRouteFreeTimeTable: (originDestination: number) => void;
    limitLines: (value: string, limit: number) => string;
    getAllogFreightContractListByName: (search: string) => Promise<void>;
    getFreightContractById(): Promise<void>;
    goToAllogFreightContract: (idFreightContract: string) => void;
    removeUpload: (model: IUploadItem) => boolean;
    hasChanges: (newObj: Object[], oldObj: Object[], propertiesToIgnore?: string[]) => boolean;
    showOnlyCurrentChargesChange: () => void;
    chargeFilterLoadReferenceChange: () => void;
    viewLogConfirmation: () => void;
    updateMoveType: (selected: SelectorModel) => void;
    clearRoutingPoint: () => void;
    getMoveTypeByProduct: () => void;
    checkRulesForDetDem: () => void;
    addDailyRoute: () => void;
    removeDailyRoute: (index: number) => Promise<void>;
    adjustVehicleTypeList: () => void;
    viewLogDailyRate: () => void;
    updateMask: (field: string, index: number) => void
    goToAirService: () => void;
    goToMaritimeService: () => void;
    addEquipmentOrVehicle: (init: boolean) => void;
    removeEquipmentOrVehicle: (index: number) => void;
    openReplicationModalIntegration: (id: number, documentError: IDocumentError[]) => void;
    showModalIntegrationRedundance: (params: IIntegrationRedundanceModalParams) => Promise<IModalInstanceService>;
}

export class FreightRoutesRegisterController extends GridFormService implements IGridFormController {

    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IFreightRoutesScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private gridName: string;
    private helperService: HelperService;
    private collapseState: string;
    private $timeout: ng.ITimeoutService
    private ModalService: IModalService;
    private ProductService: ProductService;
    private isCopy: boolean;
    private fileUploader: FileUploader;
    private formName: string;
    private propertiesToIgnore: string[];
    private $filter: ng.FilterFactory;
    private modalChargeId: number;
    private modalReasonUpdateId: number;
    private $sce: angular.ISCEService;
    private $compile: angular.ICompileService;
    private SSEService: SSEService;
    private modalIntegrationId: number;

    constructor($injector: ng.Injectable<any>, $scope: IFreightRoutesScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.gridName = 'GRID_FREIGHT_ROUTES';
        this.helperService = $injector.get('HelperService');
        this.collapseState = "hide";
        this.$timeout = $injector.get('$timeout');
        this.ModalService = $injector.get('ModalService');
        this.ProductService = $injector.get('ProductService');
        this.fileUploader = $injector.get('FileUploader');
        this.formName = 'freightRoutes';
        this.propertiesToIgnore = ["selected", "uniqueIdControl"];
        this.$filter = $injector.get('$filter');
        this.modalChargeId = 0;
        this.$sce = $injector.get('$sce');
        this.$compile = $injector.get('$compile');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.$gridService.setSelectable(true);
        this.$scope.selectedRows = [];
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    getFisFilesGenericRoute(): string {
        const baseRoute = '/fis/filesGeneric';
        const uploadRoute = this.config.fisUrl + baseRoute;
        return uploadRoute;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.chargesTableOptions = this.getChargesTableOptions();
            this.$scope.uploader = this.getFileUploaderDefault();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.chargeTableControl = { data: null, chargeFilter: { SHOW_ONLY_CURRENT_CHARGES: false, LOAD_REFERENCE: null } };
            this.$scope.$watch('model.CHARGE', (newValue: IFreightRoutesCharge[], oldValue: IFreightRoutesCharge[]) => {
                if (newValue != oldValue) {
                    this.$scope.chargeTableControl.data = angular.copy(newValue);
                    this.applyChargeFilterTable();
                }
            });

            this.initForm(this, 'form', 'freightRoutes', 'GENERAL.FREIGHT_ROUTES', true);
            await this.initGrid(this.gridName, '/freightRoutes/list', true, true, 30000, true, true, null, { fn: () => this.initGridCustomButtonFn(), disabled: () => this.initGridCustomButtonDisabledFn(), icon: 'fa fa-bolt', tooltipLabel: 'PRODUCT.OPEN_MASS_TARIFF_UPDATE' });

            this.$gridService.$gridApi.selection.on.rowSelectionChanged(this.$scope, this.selectedRowCallback.bind(this));
            this.$gridService.$gridApi.selection.on.rowSelectionChangedBatch(this.$scope, this.selectedRowBatchCallback.bind(this));
            this.$gridService.$gridApi.core.on.filterChanged(this.$scope, this.clearSelections.bind(this));

            const sessionParameter = this.$gridService.$sessionParameter;
            if (sessionParameter && sessionParameter.data) this.callSessionFunctions(sessionParameter.data);

            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private async initGridCustomButtonFn(): Promise<void> {
        const freightContractId = (this.$scope.selectedRows && this.$scope.selectedRows.length) ? this.$scope.selectedRows[0].FREIGHT_CONTRACT.ID : null;
        await this.openFreightWizardModal(freightContractId, this.$scope.selectedRows);
    }

    private initGridCustomButtonDisabledFn() {
        const freightContract = (this.$scope.selectedRows && this.$scope.selectedRows.length) ? this.$scope.selectedRows[0].FREIGHT_CONTRACT.ALLOG_FREIGHT_CONTRACT : null;
        return !this.$scope.selectedRows.every(row => row.FREIGHT_CONTRACT.ALLOG_FREIGHT_CONTRACT === freightContract);
    }

    private async openFreightWizardModal(freightContractId: number, freightRoutes: IFreightRoutesModel[]): Promise<void> {
        try {
            if (!freightContractId) return;
            if (!freightRoutes) return;
            this.block();

            const timeout: number = 120000;
            const request: IMonacoRequest = {
                data: { idFreightContract: freightContractId, freightRoutes },
                route: `/freightWizard/freightContractToWizard`,
                timeout
            }
            const rc = await this.ProductService.post(request, false);
            const freightWizard: IFreightWizard = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            const modalId = this.ModalService.newModal();
            await this.ModalService.showModalInfo(
                {
                    modalID: modalId,
                    template: require("../view/wizard/freight/freightWizardModal.html"),
                    formService: 'register',
                    size: 'full modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                },
                {
                    model: freightWizard,
                    initSelectedRoutes: true
                }
            );
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async clearSelections(): Promise<void> {
        this.$scope.selectedRows = [];
        this.$gridService.$gridApi.selection.clearSelectedRows();
        if (this.$scope.log) this.$scope.log.show = false;
    }


    private selectedRowCallback(row: IGridRow): void {
        try {
            this.block();
            const freightRoutes = row.entity;
            const i = this.$scope.selectedRows.findIndex(x => x._id === freightRoutes._id);

            if (row.isSelected && i === -1)
                this.$scope.selectedRows.push(freightRoutes);
            else
                this.$scope.selectedRows.splice(i, 1);

            this.$scope.selectedRows = this.$scope.selectedRows.slice();
            if (this.$scope.selectedRows.length > 1 && this.$scope.log) this.$scope.log.show = false;

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private selectedRowBatchCallback(rows: IGridRow[]): void {
        try {
            this.block();
            rows = this.$gridService.$gridApi.selection.getSelectedGridRows();

            this.$scope.selectedRows = rows.filter(x => x.isSelected).map(x => x.entity);
            if (this.$scope.selectedRows.length > 1 && this.$scope.log) this.$scope.log.show = false;

            this.$scope.selectedRows = this.$scope.selectedRows.slice();

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        try {
            this.$scope.editFreightRoutes = async (freightRoute: IFreightRoutesModel): Promise<void> => {
                this.editFreightRoutes(freightRoute);
            }

            this.$scope.viewFreightRoutes = async (freightRoute: IFreightRoutesModel): Promise<void> => {
                this.SSEService.closeEvents();
                this.$scope.fetchData(freightRoute.ID, EOperation.VIEW);
            }

            this.$scope.viewLogFreightRoutes = async (freightRoute: IFreightRoutesModel): Promise<void> => {
                this.SSEService.closeEvents();
                this.$scope.viewLog(freightRoute);
            }

            this.$scope.goToOfferWizard = (freightRoute: IFreightRoutesModel) => {
                if (!freightRoute) return;

                this.$scope.sessionService.openTab("app.commercial.offer", <IOfferParameter>{ CONCATENATED: "" }, <IOfferExchangeData>{
                    OPERATION: "new",
                    ID_PAYMENT_NATURE: parseInt(freightRoute.FREIGHT_CONTRACT.TRANSACTION.ID),
                    PRODUCT: freightRoute.FREIGHT_CONTRACT.PRODUCT,
                    CARGO_TYPE: freightRoute.FREIGHT_CONTRACT.CARGO_TYPE,
                    ACCOUNTS: freightRoute.FREIGHT_CONTRACT.ACCOUNT,
                    PROVIDER: freightRoute.FREIGHT_CONTRACT && freightRoute.FREIGHT_CONTRACT.PROVIDER ? freightRoute.FREIGHT_CONTRACT.PROVIDER : null,
                    NEW_FREIGHT_CONTRACT: { ID: freightRoute.FREIGHT_CONTRACT.ID.toString(), NAME: freightRoute.FREIGHT_CONTRACT.CONCATENATED },
                    FREIGHT_ROUTES: { ID: freightRoute.ID.toString(), NAME: freightRoute.CONCATENATED }
                });
            }

            this.$scope.copyFreightRoutes = async (freightRoute: IFreightRoutesModel): Promise<void> => {
                this.SSEService.closeEvents();
                this.$scope.fetchData(freightRoute.ID, EOperation.COPY);
            }

            this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
                this.fetchData(id, action);
            }

            this.$scope.checkDateValidity = (initialDate: Date, finalDate: Date, scopeName: string, charge?: IFreightRoutesCharge): void => {
                this.checkDateValidity(initialDate, finalDate, scopeName, charge);
            }

            this.$scope.checkRouteDateValidity = (type: string): void => {
                this.checkRouteDateValidity(type);
            }

            this.$scope.checkDateIsBetween = (): void => {
                this.checkDateIsBetween();
            }

            this.$scope.getAirServiceListByName = async (search: string): Promise<void> => {
                await this.getAirServiceListByName(search);
            }

            this.$scope.getMaritimeServiceListByName = async (search: string): Promise<void> => {
                await this.getMaritimeServiceListByName(search);
            }

            this.$scope.getRoutingPointListByName = async (name: string, abbreviation: string): Promise<void> => {
                await this.getRoutingPointListByName(name, abbreviation);
            }

            this.$scope.generateConcatenatedOriginDestination = (): void => {
                this.generateConcatenatedOriginDestination();
            }

            this.$scope.generateConcatenated = (): void => {
                this.generateConcatenated();
            }

            this.$scope.addDetDemRoute = () => {
                this.addDetDemRoute();
            }

            this.$scope.removeDetDemRoute = () => {
                this.removeDetDemRoute();
            }

            this.$scope.collapseHeader = (elementId: string, state?: string) => {
                if (elementId === "collapseAll") {
                    this.collapseState = (this.collapseState === "hide") ? "show" : "hide";
                    $('.toggle-me')["collapse"](state || this.collapseState);
                } else if (elementId !== "registerBody") {
                    $("#" + elementId)["collapse"](state || 'toggle');
                }

                this.$scope.navigateBetweenIds(elementId);
            }

            this.$scope.addRouteEquipmentFreeTime = (originDestination: number) => {
                this.addRouteEquipmentFreeTime(originDestination);
            }

            this.$scope.getEquipmentListForDetDem = async (search: string): Promise<void> => {
                await this.getEquipmentListForDetDem(search);
            }

            this.$scope.removeDetDemRouteEquipment = (index: number, property: string) => {
                this.removeDetDemRouteEquipment(index, property);
            }

            this.$scope.checkPerDiemTypeChangeRoute = (originDestination: number) => {
                this.checkPerDiemTypeChangeRoute(originDestination);
            }

            this.$scope.checkReadOnlyRoute = (originDestination: number, perDiemTypeId: string) => {
                return this.checkReadOnlyRoute(originDestination, perDiemTypeId);
            }

            this.$scope.setTablePerDiemRoute = (originDestination: number) => {
                this.setTablePerDiemRoute(originDestination);
            }

            this.$scope.goToTablePerDiem = (tablePerDiemId: number) => {
                this.$scope.sessionService.openTab("app.product.tablePerDiem", <ILinkParameter>{ ID: tablePerDiemId.toString() });
            }

            this.$scope.goToAllogFreightContract = (idFreightContract: string) => {
                this.$scope.sessionService.openTab("app.product.newFreightContract", <ILinkParameter>{ ID: idFreightContract.toString() });
            }

            this.$scope.removeUpload = (model: IUploadItem): boolean => {
                return this.removeUpload(model);
            }

            this.$scope.hasChanges = (newObj: Object[], oldObj: Object[], propertiesToIgnore?: string[]) => {
                return this.hasChanges(newObj, oldObj, propertiesToIgnore);
            }

            this.$scope.showOnlyCurrentChargesChange = () => {
                if (this.$scope.chargeTableControl) {
                    this.$scope.chargeTableControl.chargeFilter.LOAD_REFERENCE = this.$scope.chargeTableControl.chargeFilter.SHOW_ONLY_CURRENT_CHARGES ? new Date() : null;
                    this.applyChargeFilterTable();
                }
            }

            this.$scope.chargeFilterLoadReferenceChange = () => {
                const loadReference = moment(this.$scope.chargeTableControl.chargeFilter.LOAD_REFERENCE, 'DD/MM/YYYY');
                if (!this.$scope.chargeTableControl.chargeFilter.LOAD_REFERENCE || loadReference.isValid()) this.applyChargeFilterTable();
            }

            this.$scope.uploader.onSuccessItem = (item: IFileItem, response: ISuccessItemResponse): void => {
                this.onSuccessItem(item, response);
            }

            this.$scope.getTablePerDiemList = async (direction: string, tariffTypeId: string): Promise<void> => {
                await this.getTablePerDiemList(direction, tariffTypeId);
            }

            this.$scope.checkRouteFreeTimeSplittedDemurrage = (transactionId: string, originDestination: number, index: number) => {
                this.checkRouteFreeTimeSplittedDemurrage(transactionId, originDestination, index);
            }

            this.$scope.checkRouteFreeTimeSplittedDetention = (transactionId: string, originDestination: number, index: number) => {
                this.checkRouteFreeTimeSplittedDetention(transactionId, originDestination, index);
            }

            this.$scope.checkRouteFreeTimeCombined = (transactionId: string, originDestination: number, index: number) => {
                this.checkRouteFreeTimeCombined(transactionId, originDestination, index);
            }

            this.$scope.checkProductId = (): boolean => {
                return this.checkProductId();
            }

            this.$scope.initRouteFreeTimeTable = (originDestination: number) => {
                this.initRouteFreeTimeTable(originDestination);
            }

            this.$scope.limitLines = (value: string, limit: number): string => {
                return StringUtil.limitLines(value, limit);
            }

            this.$scope.getAllogFreightContractListByName = (search: string): Promise<void> => {
                return this.getAllogFreightContractListByName(search);
            }

            this.$scope.getFreightContractById = (): Promise<void> => {
                return this.getFreightContractById();
            }

            this.$scope.viewLogConfirmation = () => {
                this.viewLogConfirmation();
            }

            this.$scope.updateMoveType = (selected: SelectorModel): void => {
                this.updateMoveType(selected);
            }

            this.$scope.clearRoutingPoint = (): void => {
                this.clearRoutingPoint();
            }

            this.$scope.getMoveTypeByProduct = (): void => {
                this.getMoveTypeByProduct();
            }

            this.$scope.checkRulesForDetDem = (): void => {
                this.checkRulesForDetDem();
            }

            this.$scope.addDailyRoute = (): void => {
                this.addDailyRoute();
            }

            this.$scope.removeDailyRoute = async (index: number): Promise<void> => {
                await this.removeDailyRoute(index);
            }

            this.$scope.adjustVehicleTypeList = (): void => {
                this.adjustVehicleTypeList();
            }

            this.$scope.viewLogDailyRate = async (): Promise<void> => {
                await this.viewLogDailyRate();
            }

            this.$scope.updateMask = (field: string, index: number): void => {
                this.updateMask(field, index);
            }

            this.$scope.goToAirService = () => {
                this.$scope.sessionService.openTab("app.product.airService", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.AIR_SERVICE, null, 'ID') });
            }

            this.$scope.goToMaritimeService = () => {
                this.$scope.sessionService.openTab("app.product.maritimeService", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.MARITIME_SERVICE, null, 'ID') });
            }

            this.$scope.addEquipmentOrVehicle = (init: boolean) => {
                if (init) {
                    if (!this.$scope.model.EQUIPMENT_WEIGHT_RANGE_VEHICLE) this.$scope.model.EQUIPMENT_WEIGHT_RANGE_VEHICLE = [];
                } else {
                    this.addEquipmentOrVehicle();
                }
            }

            this.$scope.removeEquipmentOrVehicle = (index: number): void => {
                this.removeEquipmentOrVehicle(index);
            }

            this.$scope.openReplicationModalIntegration = async (id: number, documentError: IDocumentError[]): Promise<void> => {
                try {
                    this.modalIntegrationId = this.ModalService.newModal();
                    const documentErrorList: IDocumentError[] = documentError;
                    this.ModalService.showModalIntegrationRedundance({ modalID: this.modalIntegrationId, integrationId: id, documentErrorList: documentErrorList, fnSync: null, fnUpdateIntegrationGrid: null, headerText: "Integration Product/Operation", notButtonSync: false })
                } catch (ex) {
                    this.handleError(ex);
                }
            }

        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewFreightRoutes(row.entity); $event.stopPropagation()" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editFreightRoutes(row.entity); $event.stopPropagation()" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogFreightRoutes(row.entity); $event.stopPropagation()" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const offer = `<a ng-click="$event.preventDefault(); $event.stopPropagation(); grid.appScope.goToOfferWizard(row.entity)" class="text-green edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'PRODUCT.NEW_OFFER' | translate }}" tooltip-append-to-body="true"><i class="fa fa-handshake-o icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyFreightRoutes(row.entity); $event.stopPropagation()" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const modalErrorIntegration = `<a ng-click="grid.appScope.openReplicationModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="(row.entity.DOCUMENT_ERROR && row.entity.DOCUMENT_ERROR.length) ? 'text-danger' : 'text-green'" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 140,
            maxWidth: 140,
            cellTemplate: (view + edit + copy + offer + viewLog + modalErrorIntegration),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colTransaction: IMonacoColumnDef = { name: "FREIGHT_CONTRACT.TRANSACTION.NAME", displayName: "BASIC_DATA.TRANSACTION", width: 150 };
            const colProduct: IMonacoColumnDef = { name: "FREIGHT_CONTRACT.PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 100 };
            const colCargoType: IMonacoColumnDef = { name: "FREIGHT_CONTRACT.CARGO_TYPE.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 150 };
            const colAllogContract: IMonacoColumnDef = { name: "FREIGHT_CONTRACT.ALLOG_FREIGHT_CONTRACT", displayName: "BASIC_DATA.ALLOG_FREIGHT_CONTRACT", width: 150, cellTemplate: '<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="Click view Freight Contract" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToAllogFreightContract(row.entity.FREIGHT_CONTRACT.ID)">{{row.entity.FREIGHT_CONTRACT.ALLOG_FREIGHT_CONTRACT}}</a></div>' };
            const colContract: IMonacoColumnDef = { name: "FREIGHT_CONTRACT.FREIGHT_CONTRACT", displayName: "BASIC_DATA.FREIGHT_CONTRACT", width: 150 };
            const colDescription: IMonacoColumnDef = { name: "FREIGHT_CONTRACT.CONTRACT_DESCRIPTION", displayName: "OPERATIONAL.CONTRACT_DESCRIPTION", width: 200 };
            const colContractType: IMonacoColumnDef = { name: "FREIGHT_CONTRACT.FREIGHT_CONTRACT_TYPE.NAME", displayName: "PRODUCT.FREIGHT_CONTRACT_TYPE", width: 150 };
            const colProvider: IMonacoColumnDef = { name: "FREIGHT_CONTRACT.PROVIDER.NAME", displayName: "BASIC_DATA.PROVIDER", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.FREIGHT_CONTRACT.PROVIDER, null, "NAME")}}</div>' };
            const colNetwork: IMonacoColumnDef = { name: "FREIGHT_CONTRACT.EXTERNAL_AGENT_NETWORK.NAME", displayName: "BASIC_DATA.EXTERNAL_AGENT_NETWORK", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.FREIGHT_CONTRACT.EXTERNAL_AGENT_NETWORK, null, "NAME")}}</div>' };
            const colAccount: IMonacoColumnDef = { name: "FREIGHT_CONTRACT.ACCOUNT.NAME", displayName: "BASIC_DATA.CLIENT", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.FREIGHT_CONTRACT.ACCOUNT, null, "NAME")}}</div>' };
            const colExporter: IMonacoColumnDef = { name: "FREIGHT_CONTRACT.SHIPPER.NAME", displayName: "BASIC_DATA.SHIPPER", width: 200, cellTemplate: '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.FREIGHT_CONTRACT.SHIPPER, null, "NAME")}}</div>' };
            const colImporter: IMonacoColumnDef = { name: "FREIGHT_CONTRACT.CONSIGNEE.NAME", displayName: "BASIC_DATA.CONSIGNEE", width: 200, cellTemplate: '<div class="grid-padding">{{ grid.appScope.getCONCAT(row.entity.FREIGHT_CONTRACT.CONSIGNEE, null, "NAME")}}</div>' };
            const colMoveType: IMonacoColumnDef = { name: "MOVE_TYPE.CODE", displayName: "GENERAL.MOVEMENT_TYPE", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.MOVE_TYPE, null, "CODE")}}</div>' };
            const colDetails: IMonacoColumnDef = { name: "DETAILS.NAME", displayName: "GENERAL.DETAILS", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.DETAILS, null, "NAME")}}</div>' };
            const colServiceLevel: IMonacoColumnDef = { name: "SERVICE_LEVEL.NAME", displayName: "BASIC_DATA.SERVICE_LEVEL", width: 200 };
            const colServiceType: IMonacoColumnDef = { name: "SERVICE_TYPE.NAME", displayName: "BASIC_DATA.SERVICE_TYPE", width: 200 };
            const colPup: IMonacoColumnDef = { name: "PUP.DISPLAY_NAME", displayName: "GENERAL.PICK_UP_CODE", width: 180, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PUP, null, "DISPLAY_NAME")}}</div>' };
            const colMotFromPup: IMonacoColumnDef = { name: "MOT_FROM_PUP.NAME", displayName: "GENERAL.MOT_FROM_PUP", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.MOT_FROM_PUP, null, "NAME")}}</div>' };
            const colOtfs: IMonacoColumnDef = { name: "OTFS.DISPLAY_NAME", displayName: "GENERAL.ORIGIN_TERMINAL_CODE", width: 180, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.OTFS, null, "DISPLAY_NAME")}}</div>' };
            const colMotFromOtfs: IMonacoColumnDef = { name: "MOT_FROM_OTFS.NAME", displayName: "GENERAL.MOT_FROM_OTFS", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.MOT_FROM_OTFS, null, "NAME")}}</div>' };
            const colPolAol: IMonacoColumnDef = { name: "POL_AOL.DISPLAY_NAME", displayName: "GENERAL.LOADING_PLACE_CODE", width: 180, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.POL_AOL, null, "DISPLAY_NAME")}}</div>' };
            const colMot: IMonacoColumnDef = { name: "MOT.NAME", displayName: "GENERAL.MAIN_MOT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.MOT, null, "NAME")}}</div>' };
            const colPodAod: IMonacoColumnDef = { name: "POD_AOD.DISPLAY_NAME", displayName: "GENERAL.UNLOADING_PLACE_CODE", width: 180, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.POD_AOD, null, "DISPLAY_NAME")}}</div>' };
            const colMotFromDtfs: IMonacoColumnDef = { name: "MOT_FROM_DTFS.NAME", displayName: "GENERAL.MOT_TO_DTFS", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.MOT_FROM_DTFS, null, "NAME")}}</div>' };
            const colDtfs: IMonacoColumnDef = { name: "DTFS.DISPLAY_NAME", displayName: "GENERAL.DESTINATION_TERMINAL_CODE", width: 180, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.DTFS, null, "DISPLAY_NAME")}}</div>' };
            const colMotFromPld: IMonacoColumnDef = { name: "MOT_FROM_PLD.NAME", displayName: "GENERAL.MOT_TO_PLD", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.MOT_FROM_PLD, null, "NAME")}}</div>' };
            const colPld: IMonacoColumnDef = { name: "PLD.DISPLAY_NAME", displayName: "GENERAL.PLACE_OF_DELIVERY_CODE", width: 180, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PLD, null, "DISPLAY_NAME")}}</div>' };
            const colEvent: IMonacoColumnDef = { name: "EVENT.NAME", displayName: "GENERAL.VALIDITY_BASIS", width: 120 };
            const colValidityStart: IMonacoColumnDef = { name: "DISPLAY_VALIDITY_START", displayName: "PRODUCT.VALIDITY_START", width: 150, cellFilter: "simpleDate" };
            const colValidityEnd: IMonacoColumnDef = { name: "DISPLAY_VALIDITY_END", displayName: "PRODUCT.VALIDITY_END", width: 150, cellFilter: "simpleDate" };
            const colConcatenated: IMonacoColumnDef = { name: "CONCATENATED", displayName: "GENERAL.CONCATENATED", width: 250, enableFiltering: false };
            const colConcatenatedComplement: IMonacoColumnDef = { name: "CONCATENATED_COMPLEMENT", displayName: "GENERAL.CONCATENATED_COMPLEMENT", width: 250 };
            const colCommoditySection: IMonacoColumnDef = { name: "FREIGHT_CONTRACT.COMMODITY.SECTION.NAME", displayName: "GENERAL.COMMODITY_GROUP_CONDITION", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.FREIGHT_CONTRACT.COMMODITY, "SECTION", null, false, true)}}</div>' };
            const colCommodityCondition: IMonacoColumnDef = { name: "FREIGHT_CONTRACT.COMMODITY.CONDITION.NAME", displayName: "GENERAL.COMMODITY_CONDITION", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.FREIGHT_CONTRACT.COMMODITY, "CONDITION", null, false, true)}}</div>' };
            const colCommodityException: IMonacoColumnDef = { name: "FREIGHT_CONTRACT.COMMODITY.EXCEPTION.NAME", displayName: "GENERAL.COMMODITY_EXCEPTION", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.FREIGHT_CONTRACT.COMMODITY, "EXCEPTION", null, false, true)}}</div>' };
            const colScore: IMonacoColumnDef = { name: "FREIGHT_CONTRACT.SCORE", displayName: "BASIC_DATA.SCORE", width: 100 };
            const colValidityExpired: IMonacoColumnDef = { name: "FREIGHT_CONTRACT.VALIDITY_EXPIRED", displayName: "GENERAL.EXPIRED", width: 100, cellFilter: "YesOrNo" };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colTradeGroupOriginRepl: IMonacoColumnDef = { name: "TRADE_GROUP_ORIGIN.NAME", displayName: "BASIC_DATA.ORIGIN_TRADE_LANE", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TRADE_GROUP_ORIGIN, null, "NAME")}}</div>' };
            const colTradeGroupDestinationRepl: IMonacoColumnDef = { name: "TRADE_GROUP_DESTINATION.NAME", displayName: "BASIC_DATA.DESTINATION_TRADE_LANE", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TRADE_GROUP_DESTINATION, null, "NAME")}}</div>' };
            const colEquipWeightRange: IMonacoColumnDef = { name: "EQUIPMENT_WEIGHT_RANGE_VEHICLE_REPL", displayName: "PRODUCT.BASIS_COMPLEMENT", width: 250};
            const colDTA: IMonacoColumnDef = { name: "DTA", displayName: "REGISTRATION.BONDED_TRUCK", width: 150, cellFilter: 'YesOrNo' };
            const colContractSituation: IMonacoColumnDef = { name: "FREIGHT_CONTRACT.SITUATION.NAME", displayName: "GENERAL.SITUATION", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.FREIGHT_CONTRACT.SITUATION, null, "NAME")}}</div>' };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 150, cellFilter: "YesOrNo" };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'TRANSACTION':
                        columnDefs.push(colTransaction);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'CARGO_TYPE':
                        columnDefs.push(colCargoType);
                        break;
                    case 'ALLOG_FREIGHT_CONTRACT':
                        columnDefs.push(colAllogContract);
                        break;
                    case 'FREIGHT_CONTRACT':
                        columnDefs.push(colContract);
                        break;
                    case 'CONTRACT_DESCRIPTION':
                        columnDefs.push(colDescription);
                        break;
                    case 'FREIGHT_CONTRACT_TYPE':
                        columnDefs.push(colContractType);
                        break;
                    case 'PROVIDER':
                        columnDefs.push(colProvider);
                        break;
                    case 'EXTERNAL_AGENT_NETWORK':
                        columnDefs.push(colNetwork);
                        break;
                    case 'ACCOUNT':
                        columnDefs.push(colAccount);
                        break;
                    case 'SHIPPER':
                        columnDefs.push(colExporter);
                        break;
                    case 'CONSIGNEE':
                        columnDefs.push(colImporter);
                        break;
                    case 'MOVE_TYPE':
                        columnDefs.push(colMoveType);
                        break;
                    case 'DETAILS':
                        columnDefs.push(colDetails);
                        break;
                    case 'SERVICE_LEVEL':
                        columnDefs.push(colServiceLevel);
                        break;
                    case 'SERVICE_TYPE':
                        columnDefs.push(colServiceType);
                        break;
                    case 'PUP':
                        columnDefs.push(colPup);
                        break;
                    case 'MOT_FROM_PUP':
                        columnDefs.push(colMotFromPup);
                        break;
                    case 'OTFS':
                        columnDefs.push(colOtfs);
                        break;
                    case 'MOT_FROM_OTFS':
                        columnDefs.push(colMotFromOtfs);
                        break;
                    case 'POL_AOL':
                        columnDefs.push(colPolAol);
                        break;
                    case 'MOT':
                        columnDefs.push(colMot);
                        break;
                    case 'POD_AOD':
                        columnDefs.push(colPodAod);
                        break;
                    case 'MOT_FROM_DTFS':
                        columnDefs.push(colMotFromDtfs);
                        break;
                    case 'DTFS':
                        columnDefs.push(colDtfs);
                        break;
                    case 'MOT_FROM_PLD':
                        columnDefs.push(colMotFromPld);
                        break;
                    case 'PLD':
                        columnDefs.push(colPld);
                        break;
                    case 'SECTION':
                        columnDefs.push(colCommoditySection);
                        break;
                    case 'CONDITION':
                        columnDefs.push(colCommodityCondition);
                        break;
                    case 'EXCEPTION':
                        columnDefs.push(colCommodityException);
                        break;
                    case 'SCORE':
                        columnDefs.push(colScore);
                        break;
                    case 'VALIDITY_EXPIRED':
                        columnDefs.push(colValidityExpired);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                    case 'EVENT':
                        columnDefs.push(colEvent);
                        break;
                    case 'DISPLAY_VALIDITY_START':
                        columnDefs.push(colValidityStart);
                        break;
                    case 'DISPLAY_VALIDITY_END':
                        columnDefs.push(colValidityEnd);
                        break;
                    case 'CONCATENATED':
                        columnDefs.push(colConcatenated);
                        break;
                    case 'CONCATENATED_COMPLEMENT':
                        columnDefs.push(colConcatenatedComplement);
                        break;
                    case 'TRADE_GROUP_ORIGIN':
                        columnDefs.push(colTradeGroupOriginRepl);
                        break;
                    case 'TRADE_GROUP_DESTINATION':
                        columnDefs.push(colTradeGroupDestinationRepl);
                        break;
                    case 'EQUIPMENT_WEIGHT_RANGE_VEHICLE_REPL':
                        columnDefs.push(colEquipWeightRange);
                        break;
                    case 'DTA':
                        columnDefs.push(colDTA);
                        break;
                    case 'SITUATION':
                        columnDefs.push(colContractSituation);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        try {
            this.$scope.model = {
                _id: null,
                ID: null,
                ALLOG_FREIGHT_CONTRACT: null,
                FREIGHT_CONTRACT: null,
                ID_FREIGHT_CONTRACT: null,
                ID_SERVICE_LEVEL: null,
                MOVE_TYPE: null,
                CHARGE: null,
                CONCATENATED_COMPLEMENT: null,
                CONCATENATED: null,
                CONCATENATED_ROUTES: null,
                DETAILS: null,
                DET_DEM_DESTINATION: null,
                DET_DEM_ORIGIN: null,
                EVENT: null,
                MOT_FROM_DTFS: null,
                MOT_FROM_OTFS: null,
                MOT_FROM_PLD: null,
                MOT_FROM_PUP: null,
                MOT: null,
                DTFS: null,
                OTFS: null,
                PLD: null,
                PUP: null,
                POD_AOD: null,
                POL_AOL: null,
                OBSERVATION: null,
                AIR_SERVICE: null,
                MARITIME_SERVICE: null,
                SERVICE_LEVEL: null,
                SERVICE_TYPE: null,
                DISPLAY_VALIDITY_END: null,
                DISPLAY_VALIDITY_START: null,
                FILES: null,
                FILES_NEW: null,
                FILES_REMOVE: null,
                EQUIPMENT_WEIGHT_RANGE_VEHICLE: null,
                DTA: false,
                EQUIPMENT_WEIGHT_RANGE_VEHICLE_REPL: null,
                DYNAMIC_FIELDS: this.buildDynamicFields(),
                ACTIVE: true,
                DOCUMENT_ERROR: null,
                CREATED_AT: null,
                CREATED_BY: null,
                UPDATED_AT: null,
                UPDATED_BY: null,
                TRADE_GROUP_DESTINATION: null,
                TRADE_GROUP_ORIGIN: null
            };
            this.$scope.oldModel = angular.copy(this.$scope.model);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getMoveTypeList(): Promise<IMoveTypeModel[]> {
        const result = await this.RestService.getObjectAsPromise(`${this.$baseUrl}/moveType/list/full`, 30000, null, false);
        return result ? result.data : [];
    }

    private async getAirServiceListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 2) {
                if (!this.$scope.model.FREIGHT_CONTRACT.PRODUCT) { throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST")) }
                if (!this.$scope.model.FREIGHT_CONTRACT.PROVIDER) { throw Error(this.formService.getTranslate('PRODUCT.SELECT_PROVIDER_FIRST')) }

                this.formService.block();
                const filter: IAirServiceListCustomFilter = { search: search, products: this.$scope.model.FREIGHT_CONTRACT.PRODUCT ? [this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID] : null, providers: this.$scope.model.FREIGHT_CONTRACT.PROVIDER ? [parseInt(this.$scope.model.FREIGHT_CONTRACT.PROVIDER.ID)] : null };
                const airServices = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/airService/list/custom/`, filter, 30000, false);
                if (airServices && airServices.length > 0) {
                    result = airServices.map(airService => { return { ID: airService.ID, NAME: airService.LOGISTIC_SOLUTION } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.airServiceList = result
            this.formService.unblock();
        }
    }

    private async getMaritimeServiceListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 2) {
                if (!this.$scope.model.FREIGHT_CONTRACT.PRODUCT) { throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST")) }
                if (!this.$scope.model.FREIGHT_CONTRACT.PROVIDER) { throw Error(this.formService.getTranslate('PRODUCT.SELECT_PROVIDER_FIRST')) }

                this.formService.block();

                const product = [this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID];
                const provider = [this.$scope.model.FREIGHT_CONTRACT.PROVIDER.ID];
                const maritimeServices = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/maritimeService/list/custom/`, { search, products: product, providers: provider }, 30000, false);
                if (maritimeServices && maritimeServices.length > 0) {
                    result = maritimeServices.map(maritimeService => { return { ID: maritimeService.ID, NAME: maritimeService.LOGISTIC_SOLUTION } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.maritimeServiceList = result
            this.formService.unblock();
        }
    }

    private async getRoutingPointListByName(name: string, abbreviation: string): Promise<void> {
        let result: IRoutingPointSelector[] = [];
        try {
            if (name && name.length >= 2) {
                this.formService.block();
                const product = this.$scope.model.FREIGHT_CONTRACT.PRODUCT && this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID;
                const maritime = product && (product == EProductId.MARITIME_IMPORT || product == EProductId.MARITIME_EXPORT);
                const air = product && (product == EProductId.AIR_IMPORT || product == EProductId.AIR_EXPORT);
                let types = [];

                switch (abbreviation) {
                    case 'PUP':
                        types.push(ERoutingPointType.DOOR)
                        break;
                    case 'PLD':
                        types.push(ERoutingPointType.DOOR)
                        break;
                    case 'OTFS':
                        types.push(ERoutingPointType.TERMINAL)
                        break;
                    case 'DTFS':
                        types.push(ERoutingPointType.TERMINAL)
                        break;
                    case 'POL_AOL':
                        if (maritime) {
                            types.push(ERoutingPointType.PORT);
                        } else if (air) {
                            types.push(ERoutingPointType.AIRPORT);
                        }
                        break;
                    case 'POD_AOD':
                        if (maritime) {
                            types.push(ERoutingPointType.PORT);
                        } else if (air) {
                            types.push(ERoutingPointType.AIRPORT);
                        }
                        break;
                    default:
                        break;
                }

                const routingPoints = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/routingPoint/list/custom/`, { name, types }, 30000, false);
                if (routingPoints && routingPoints.length > 0) {
                    result = routingPoints.map(routingPoint => { return { ID: routingPoint.ID, NAME: routingPoint.NAME, CODE: routingPoint.CODE, DISPLAY_NAME: routingPoint.DISPLAY_NAME, TRADE_GROUP: routingPoint.TRADE_GROUP, ID_CITY: routingPoint.ID_CITY } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.routingPointList = result
            this.formService.unblock();
        }
    }

    private async getServiceLevelListByName(search?: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.formService.block();
            const servicesLevel = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/serviceLevel/list/custom/`, { search }, 30000, false);
            if (servicesLevel && servicesLevel.length > 0) {
                result = servicesLevel.map(serviceLevel => { return { ID: serviceLevel.ID, NAME: serviceLevel.DISPLAY_NAME } });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getServiceTypeListByName(search?: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            if (!this.$scope.model.FREIGHT_CONTRACT.PRODUCT) return result;
            else {
                this.formService.block();
                const products = [this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID];
                const serviceTypeList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/serviceType/list/custom`, { search, products }, 30000, false);
                result = serviceTypeList ? serviceTypeList.map(x => { return { ID: x.ID, NAME: x.NAME } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getGenericList(type: string, alternative: boolean = false): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}/${alternative}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    initDependencies(): Promise<any> {
        try {
            const self: FreightRoutesRegisterController = this;
            self.initCollapseEvents();
            $('#durationForm')['mask']('00:00');

            return new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getMoveTypeList(), //0
                    self.getServiceLevelListByName(), //1
                    self.getGenericList('inland_details'), //2
                    self.getGenericList('process_event_transport_mode'), //3
                    self.getGenericList('event'), //4
                    self.getGenericList('tariff_freight_per_diem_type'), //5
                    self.getGenericList('dd_type_tariff'), //6
                    self.getGenericList('tariff_freight_free_time_type'), //7
                    self.getGenericList('holder_type'), //8
                    self.getGenericList("tariff_change_confirmation_reason_value"), //9
                    self.getGenericList("tariff_change_confirmation_reason_validity"), //10
                    self.getGenericList("vehicle_type") //11
                ]).then((result: any) => {
                    self.$scope.moveTypeFullList = result[0];
                    self.$scope.serviceLevelList = result[1];
                    self.$scope.detailsList = result[2];
                    self.$scope.processEventTransportModeList = result[3];
                    self.$scope.eventList = result[4];
                    self.$scope.perDiemTypeList = result[5];
                    self.$scope.detDemTypeList = result[6];
                    self.$scope.freeTimeTypeList = result[7];
                    self.$scope.holderTypeList = result[8];
                    self.$scope.reasonValueChangeList = result[9];
                    self.$scope.reasonValidityChangeList = result[10];
                    self.$scope.vehicleTypeList = result[11];

                    if (self.$scope.moveTypeFullList && self.$scope.moveTypeFullList.length > 0) {
                        self.$scope.moveTypeList = self.$scope.moveTypeFullList.map(moveType => { return { ID: moveType.ID.toString(), NAME: moveType.NAME, CODE: moveType.CODE } });
                    }
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }

    }

    private initCollapseEvents() {
        angular.element('#collapseCharges').on('shown.bs.collapse', (event: JQuery.Event) => {
            if (event.target == event.currentTarget) {
                angular.element("#chargeTableFilter").addClass("in");
            }
        });

        angular.element('#collapseCharges').on('hidden.bs.collapse', (event: JQuery.Event) => {
            if (event.target == event.currentTarget) {
                angular.element("#chargeTableFilter").removeClass("in");
            }
        });

    }

    private setCopy(value: boolean): void {
        this.isCopy = value;
    }

    async register(): Promise<void> {
        try {
            this.$scope.oldModel = null;
            this.$scope.scopeBeforeSave = null;
            this.$scope.chargeTableControl.data = [];
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.$scope.chargesTableOptions.load(null, true);
            this.$scope.chargesTableOptions.crudButtons.add.disabled = false;
            this.$scope.chargesTableOptions.crudButtons.remove.disabled = false;
            this.$scope.chargesTableOptions.viewTariffAirFreight.disabled = this.checkProductId();
            this.$scope.oldDailyRouteModel = null;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")}`;
            this.$scope.oldModel = angular.copy(this.$scope.model);
            await this.updateControl();
            this.$scope.chargesTableOptions.crudButtons.add.disabled = true;
            this.$scope.chargesTableOptions.crudButtons.remove.disabled = true;
            this.$scope.chargesTableOptions.viewTariffAirFreight.disabled = this.checkProductId();
            this.$scope.model.ALLOG_FREIGHT_CONTRACT.NAME = `${this.$scope.model.ALLOG_FREIGHT_CONTRACT.NAME} | ${this.$scope.model.FREIGHT_CONTRACT.FREIGHT_CONTRACT}`;
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.generateConcatenated();
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")}`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.oldModel = angular.copy(this.$scope.model);
            this.$scope.chargesTableOptions.crudButtons.add.disabled = false;
            this.$scope.chargesTableOptions.crudButtons.remove.disabled = false;
            this.$scope.chargesTableOptions.crudButtons.validityRoute.disabled = true;
            this.$scope.chargesTableOptions.viewTariffAirFreight.disabled = this.checkProductId();
            const moveType = this.$scope.model.MOVE_TYPE ? this.$scope.moveTypeFullList.find(moveType => moveType.ID == parseInt(this.$scope.model.MOVE_TYPE.ID)) : null;
            this.$scope.model.ALLOG_FREIGHT_CONTRACT.NAME = `${this.$scope.model.ALLOG_FREIGHT_CONTRACT.NAME} | ${this.$scope.model.FREIGHT_CONTRACT.FREIGHT_CONTRACT}`;
            if (moveType && moveType.PARAMS) this.$scope.model.DYNAMIC_FIELDS = this.buildDynamicFields(moveType.PARAMS);
            this.$scope.updateMoveType(this.$scope.model.MOVE_TYPE);
            await this.updateControl();
            this.applyChargeFilterTable();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.generateConcatenated();
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.model.DET_DEM_ORIGIN = null;
            this.$scope.model.DET_DEM_DESTINATION = null;
            this.$scope.model.FILES = null;
            this.$scope.model.FILES_NEW = null;
            this.$scope.model.FILES_REMOVE = null;
            await this.clearFields(this.$scope.model);
            if (this.$scope.model.CHARGE && this.$scope.model.CHARGE.length > 0) {
                for (const charge of this.$scope.model.CHARGE) {
                    charge.ID = null;
                }
            }
            this.$scope.oldModel = angular.copy(this.$scope.model);
            this.$scope.chargesTableOptions.crudButtons.add.disabled = false;
            this.$scope.chargesTableOptions.crudButtons.remove.disabled = false;
            const moveType = this.$scope.model.MOVE_TYPE ? this.$scope.moveTypeFullList.find(moveType => moveType.ID == parseInt(this.$scope.model.MOVE_TYPE.ID)) : null;
            if (moveType && moveType.PARAMS) this.$scope.model.DYNAMIC_FIELDS = this.buildDynamicFields(moveType.PARAMS);
            this.$scope.updateMoveType(this.$scope.model.MOVE_TYPE);
            await this.updateControl();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.generateConcatenated();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === 'register' ? 'insert' : 'update';
        return {
            route: `/freightRoutes/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.oldModel),
            timeout: 15000
        };

    }

    async save(): Promise<boolean> {
        let proceed: boolean = true;
        try {
            if (this.$scope.uploader.queue && this.$scope.uploader.queue.length > 0) {
                proceed = false;
                this.handleError('GENERAL.FILES_IN_THE_QUEUE');
            }

            if ((!this.$scope.dailyRouteModel || this.$scope.dailyRouteModel.length == 0) && (this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID == EProductId.ROAD_EXPORT || this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID == EProductId.ROAD_IMPORT
                || this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID == EProductId.ROAD_NATIONAL)) {
                proceed = false;
                this.handleError('No daily route was informed.');
            }

            this.SSEService.closeEvents();
            this.setCopy(false);
        } catch (ex) {
            this.handleError(ex);
            proceed = false;
        } finally {
            this.customSave();
            return false;
        }

    }

    private getDefaultProcessEventTransportMode(): void {
        if (this.$scope.processEventTransportModeList && this.$scope.processEventTransportModeList.length > 0 && !this.$scope.model.MOT) {
            const allAir = this.$scope.processEventTransportModeList.find(event => event.ID == EProcessEventTransportModeId.ALL_AIR);
            const allWater = this.$scope.processEventTransportModeList.find(event => event.ID == EProcessEventTransportModeId.ALL_WATER);
            const truck = this.$scope.processEventTransportModeList.find(event => event.ID == EProcessEventTransportModeId.TRUCK);
            if (!this.$scope.model.MOT) this.$scope.model.MOT = [];

            switch (this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID) {
                case EProductId.AIR_IMPORT:
                case EProductId.AIR_EXPORT:
                    this.$scope.model.MOT.push(allAir);
                    break;
                case EProductId.MARITIME_IMPORT:
                case EProductId.MARITIME_EXPORT:
                    this.$scope.model.MOT.push(allWater);
                    break;
                case EProductId.ROAD_IMPORT:
                case EProductId.ROAD_EXPORT:
                case EProductId.ROAD_NATIONAL:
                    this.$scope.model.MOT.push(truck);
                    break;
                default:
                    break;

            }
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: "CONCATENATED",
                LABEL: "GENERAL.CONCATENATED"
            },
            {
                PROPERTY: "NAME",
                LABEL: "GENERAL.NAME"
            },
            {
                PROPERTY: "CODE",
                LABEL: "ENTITY.SHORT_NAME"
            },
            {
                PROPERTY: "EVENT",
                LABEL: "GENERAL.VALIDITY_BASIS"
            },
            {
                PROPERTY: "DISPLAY_VALIDITY_START",
                LABEL: "PRODUCT.VALIDITY_START"
            },
            {
                PROPERTY: "DISPLAY_VALIDITY_END",
                LABEL: "PRODUCT.VALIDITY_END"
            },
            {
                PROPERTY: "SITUATION",
                LABEL: "GENERAL.SITUATION"
            },
            {
                PROPERTY: "OBSERVATION",
                LABEL: "GENERAL.REMARKS"
            },
            {
                PROPERTY: "FILES",
                LABEL: "REGISTRATION.FILES"
            },
            {
                PROPERTY: "FILE_DISPLAY_NAME",
                LABEL: "REGISTRATION.FILE_ATTACHMENT_NAME"
            },
            {
                PROPERTY: "FILE_TYPE",
                LABEL: "REGISTRATION.FILE_TYPE"
            },
            {
                PROPERTY: "FILE_URL",
                LABEL: "REGISTRATION.FILE_URL"
            },
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE"
            },
            {
                PROPERTY: "VALIDITY_EXPIRED",
                LABEL: "GENERAL.VALIDITY_EXPIRED"
            },
            {
                PROPERTY: "MOVE_TYPE",
                LABEL: "GENERAL.MOVEMENT_TYPE"
            },
            {
                PROPERTY: "AIR_SERVICE",
                LABEL: "BASIC_DATA.SERVICE"
            },
            {
                PROPERTY: "MARITIME_SERVICE",
                LABEL: "BASIC_DATA.SERVICE"
            },
            {
                PROPERTY: "SERVICE_LEVEL",
                LABEL: "BASIC_DATA.SERVICE_LEVEL"
            },
            { PROPERTY: "PUP.CODE", LABEL: "GENERAL.PICK_UP_CODE" },
            { PROPERTY: "MOT_FROM_PUP", LABEL: "GENERAL.MOT_FROM_PUP" },
            { PROPERTY: "OTFS", LABEL: "GENERAL.ORIGIN_TERMINAL_CODE" },
            { PROPERTY: "MOT_FROM_OTFS", LABEL: "GENERAL.MOT_FROM_OTFS" },
            { PROPERTY: "POL_AOL", LABEL: "GENERAL.LOADING_PLACE_CODE" },
            { PROPERTY: "MOT", LABEL: "GENERAL.MAIN_MOT" },
            { PROPERTY: "POD_AOD", LABEL: "GENERAL.UNLOADING_PLACE_CODE" },
            { PROPERTY: "MOT_FROM_DTFS", LABEL: "GENERAL.MOT_TO_DTFS" },
            { PROPERTY: "DTFS", LABEL: "GENERAL.DESTINATION_TERMINAL_CODE" },
            { PROPERTY: "MOT_FROM_PLD", LABEL: "GENERAL.MOT_TO_PLD" },
            { PROPERTY: "PLD", LABEL: "GENERAL.PLACE_OF_DELIVERY_CODE" },
            {
                PROPERTY: "SERVICE_TYPE",
                LABEL: "BASIC_DATA.SERVICE_TYPE"
            },
            {
                PROPERTY: "DET_DEM",
                LABEL: "PRODUCT.DD_TARIFF"
            },
            {
                PROPERTY: "CONCATENATED_COMPLEMENT",
                LABEL: "GENERAL.CONCATENATED_COMPLEMENT"
            },
            {
                PROPERTY: "CREATED_AT",
                LABEL: "GENERAL.CREATED_AT"
            },
            {
                PROPERTY: "CREATED_BY",
                LABEL: "GENERAL.CREATED_BY"
            },
            {
                PROPERTY: "UPDATED_AT",
                LABEL: "GENERAL.UPDATED_AT"
            },
            {
                PROPERTY: "UPDATED_BY",
                LABEL: "GENERAL.UPDATED_BY"
            },
            {
                PROPERTY: "CONCATENATED_ROUTES",
                LABEL: "BASIC_DATA.ROUTING_DETAILS"
            },
            {
                PROPERTY: "DISPLAY_NAME",
                LABEL: "OPERATIONAL.CONTRACT_REFERENCE"
            },
            {
                PROPERTY: "DET_DEM_DESTINATION",
                LABEL: "PRODUCT.DET_DEM_DESTINATION"
            },
            {
                PROPERTY: "DET_DEM_ORIGIN",
                LABEL: "PRODUCT.DET_DEM_ORIGIN"
            },
            {
                PROPERTY: "DET_DEM_TYPE",
                LABEL: "PRODUCT.TARIFF_TYPE"
            },
            {
                PROPERTY: "FIXED_ADDITIONAL",
                LABEL: "PRODUCT.PD_FIXED_ADDITIONAL"
            },
            {
                PROPERTY: "PER_DIEM_TYPE",
                LABEL: "PRODUCT.PER_DIEM_TYPE"
            },
            {
                PROPERTY: "FREE_TIME_TYPE",
                LABEL: "PRODUCT.FREE_TIME_TYPE"
            },
            {
                PROPERTY: "PERCENTAGE_ADDITIONAL",
                LABEL: "PRODUCT.PD_PERC_ADDITIONAL"
            },
            {
                PROPERTY: "TABLE_PER_DIEM",
                LABEL: "GENERAL.TABLE"
            },
            {
                PROPERTY: "EQUIPMENT_FREE_TIME",
                LABEL: "PRODUCT.ADD_EQUIPMENT_FREE_TIME"
            },
            {
                PROPERTY: "COMBINED_DET_DEM_HOUSE_FREE_TIME",
                LABEL: "FINANCIAL.HOUSE"
            },
            {
                PROPERTY: "COMBINED_DET_DEM_MASTER_FREE_TIME",
                LABEL: "FINANCIAL.MASTER"
            },
            {
                PROPERTY: "SPLITTED_DEMURRAGE_HOUSE_FREE_TIME",
                LABEL: "FINANCIAL.HOUSE"
            },
            {
                PROPERTY: "SPLITTED_DEMURRAGE_MASTER_FREE_TIME",
                LABEL: "FINANCIAL.MASTER"
            },
            {
                PROPERTY: "SPLITTED_DETENTION_HOUSE_FREE_TIME",
                LABEL: "FINANCIAL.HOUSE"
            },
            {
                PROPERTY: "SPLITTED_DETENTION_MASTER_FREE_TIME",
                LABEL: "FINANCIAL.MASTER"
            }, {
                PROPERTY: "ALLOG_FREIGHT_CONTRACT",
                LABEL: "BASIC_DATA.ALLOG_FREIGHT_CONTRACT"
            },
            {
                PROPERTY: "CHARGE",
                LABEL: "BASIC_DATA.CHARGE"
            },
            {
                PROPERTY: "DETAILS",
                LABEL: "GENERAL.DETAILS"
            },
            {
                PROPERTY: "FILES_NEW",
                LABEL: "GENERAL.ADDED_FILES"
            },
            {
                PROPERTY: "FILES_REMOVE",
                LABEL: "GENERAL.REMOVED_FILES"
            },
            {
                PROPERTY: "EQUIPMENT",
                LABEL: "BASIC_DATA.EQUIPMENT"
            },
            {
                PROPERTY: "WEIGHT_LIMIT",
                LABEL: "PRODUCT.WEIGHT_LIMITE"
            },
            {
                PROPERTY: "CHARGE_NAME_EXHIBITION",
                LABEL: "BASIC_DATA.CHARGE"
            },
            {
                PROPERTY: 'PARAMS',
                LABEL: 'GENERAL.PARAMS'
            },
            {
                PROPERTY: "TYPE_CARGO",
                LABEL: "BASIC_DATA.CARGO_TYPE"
            },
            {
                PROPERTY: "PERCENT",
                LABEL: "REGISTRATION.PERCENTAGE"
            },
            {
                PROPERTY: "APPLICATION_COMPLEMENT",
                LABEL: "PRODUCT.BASIS_COMPLEMENT"
            },
            {
                PROPERTY: "INTERNAL_SEQUENCE",
                LABEL: "REGISTRATION.INTERNAL_SEQUENCE"
            },
            {
                PROPERTY: "FREE_TYPING_AMOUNT_CHARGE",
                LABEL: "REGISTRATION.FREE_FIELD_CHANGE_QUANTITY"
            },
            {
                PROPERTY: "CT_WITHOUT_DOC",
                LABEL: "REGISTRATION.CONTRIBUTION_WITHOUT_DOCS"
            },
            {
                PROPERTY: "NAME_INTL",
                LABEL: "GENERAL.NAME_INTL"
            },
            {
                PROPERTY: "PAYMENT",
                LABEL: "PRODUCT.BUYING"
            },
            {
                PROPERTY: "RECEIVING",
                LABEL: "PRODUCT.SELLING"
            },
            {
                PROPERTY: "FLAT",
                LABEL: "REGISTRATION.FLAT"
            },
            {
                PROPERTY: "VEHICLE_TYPE",
                LABEL: "GENERAL.VEHICLE_TYPE"
            },
            {
                PROPERTY: "WEIGHT_RANGE",
                LABEL: "GENERAL.MENU.WEIGHT_RANGE"
            },
            {
                PROPERTY: "APPLICATION",
                LABEL: "FINANCIAL.CHARGE_BASIS"
            },
            {
                PROPERTY: "CURRENCY",
                LABEL: "GENERAL.CURRENCY"
            },
            {
                PROPERTY: "MIN",
                LABEL: "BASIC_DATA.MINIMUM"
            },
            {
                PROPERTY: "UNITARY",
                LABEL: "BASIC_DATA.UNIT"
            },
            {
                PROPERTY: "EVENT",
                LABEL: "GENERAL.VALIDITY_BASIS"
            },
            {
                PROPERTY: "VALIDITY_START",
                LABEL: "PRODUCT.VALIDITY_START"
            },
            {
                PROPERTY: "VALIDITY_END",
                LABEL: "PRODUCT.VALIDITY_END"
            },
            {
                PROPERTY: "FREIGHT_ROUTES_PAYMENT",
                LABEL: "PRODUCT.BUYING"
            },
            {
                PROPERTY: "FREIGHT_ROUTES_RECEIVING",
                LABEL: "PRODUCT.SELLING"
            },
            {
                PROPERTY: "FREE_OF_CHARGE",
                LABEL: "PRODUCT.FREE_OF_CHARGE"
            },
            {
                PROPERTY: "FREIGHT_BASIS",
                LABEL: "REGISTRATION.FREIGHT_BASIS"
            },
            {
                PROPERTY: "HOLDER_TYPE",
                LABEL: "GENERAL.PERSON_TYPE"
            },
            {
                PROPERTY: "LEGAL_PERSON_HOLDER",
                LABEL: "GENERAL.HOLDER"
            },
            {
                PROPERTY: "PHYSICAL_PERSON_HOLDER",
                LABEL: "GENERAL.HOLDER"
            },
            {
                PROPERTY: "WEIGHT_RANGE_CHARGE",
                LABEL: "GENERAL.MENU.WEIGHT_RANGE"
            },
            {
                PROPERTY: "TRADE_GROUP",
                LABEL: "ROUTE.TRADE_GROUP"
            },
            {
                PROPERTY: "FREE_TIME_PUP_MASTER",
                LABEL: "PRODUCT.PUP_FREE_TIME",
            },
            {
                PROPERTY: "FREE_TIME_PUP_HOUSE",
                LABEL: "PRODUCT.PUP_FREE_TIME",
            },
            {
                PROPERTY: "ORIGIN_FREE_TIME_CUSTOMS_CLEARANCE_MASTER",
                LABEL: "PRODUCT.ORIGIN_FREE_TIME_CUSTOMS_CLEARANCE",
            },
            {
                PROPERTY: "ORIGIN_FREE_TIME_CUSTOMS_CLEARANCE_HOUSE",
                LABEL: "PRODUCT.ORIGIN_FREE_TIME_CUSTOMS_CLEARANCE",
            },
            {
                PROPERTY: "ORIGIN_FREE_TIME_BORDER_CROSS_MASTER",
                LABEL: "PRODUCT.ORIGIN_FREE_TIME_BORDER_CROSS",
            },
            {
                PROPERTY: "ORIGIN_FREE_TIME_BORDER_CROSS_HOUSE",
                LABEL: "PRODUCT.ORIGIN_FREE_TIME_BORDER_CROSS",
            },
            {
                PROPERTY: "DESTINATION_FREE_TIME_CUSTOMS_CLEARANCE_MASTER",
                LABEL: "PRODUCT.DESTINATION_FREE_TIME_CUSTOMS_CLEARANCE",
            },
            {
                PROPERTY: "DESTINATION_FREE_TIME_CUSTOMS_CLEARANCE_HOUSE",
                LABEL: "PRODUCT.DESTINATION_FREE_TIME_CUSTOMS_CLEARANCE",
            },
            {
                PROPERTY: "DESTINATION_FREE_TIME_BORDER_CROSS_MASTER",
                LABEL: "PRODUCT.DESTINATION_FREE_TIME_BORDER_CROSS",
            },
            {
                PROPERTY: "DESTINATION_FREE_TIME_BORDER_CROSS_HOUSE",
                LABEL: "PRODUCT.DESTINATION_FREE_TIME_BORDER_CROSS",
            },
            {
                PROPERTY: "FREE_TIME_DELIVERY_MASTER",
                LABEL: "PRODUCT.DELIVERY_FREE_TIME",
            },
            {
                PROPERTY: "FREE_TIME_DELIVERY_HOUSE",
                LABEL: "PRODUCT.DELIVERY_FREE_TIME",
            }
        ];
        return props;
    }


    private async updateControl() {
        try {
            const product = this.$scope.model.FREIGHT_CONTRACT.PRODUCT;
            this.$scope.isAirProduct = product && (product.ID == EProductId.AIR_IMPORT || product.ID == EProductId.AIR_EXPORT);
            this.$scope.isMaritimeProduct = product && (product.ID == EProductId.MARITIME_IMPORT || product.ID == EProductId.MARITIME_EXPORT);
            this.$scope.isLandProduct = product && (product.ID == EProductId.ROAD_IMPORT || product.ID == EProductId.ROAD_EXPORT || product.ID == EProductId.ROAD_NATIONAL);
            this.$scope.serviceTypeList = await this.getServiceTypeListByName();
            this.getDefaultProcessEventTransportMode();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/freightRoutes/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) {
                    await this.$scope.edit(model);
                    await this.getFreightRouteDailyRate();
                } else if (action === GridColumnBuilderConstants.BTN_COPY) {
                    await this.$scope.copy(model);
                } else {
                    await this.$scope.view(model);
                    await this.getFreightRouteDailyRate();
                }
            } else throw Error('No data found.');

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private checkDateValidity(initialDate: Date, finalDate: Date, scopeName: string, charge: IFreightRoutesCharge = null): void {
        let isValid = false;
        if (!initialDate || typeof initialDate == "string") return;
        if (!finalDate || typeof finalDate == "string") return;
        isValid = ValidateUtil.isValidDateRange(initialDate, finalDate);
        if (!isValid) {
            switch (scopeName) {
                case 'displayValidityDate':
                    this.$scope.model.DISPLAY_VALIDITY_END = null;
                    break;

                case 'chargeValidityDate':
                    charge.VALIDITY_END = null;
                    break;
            }
        }
    }

    private checkRouteDateValidity(type: string): void {
        this.checkDateValidity(this.$scope.model.DISPLAY_VALIDITY_START, this.$scope.model.DISPLAY_VALIDITY_END, 'displayValidityDate');

        const format = 'DD/MM/YYYY';

        if (type == 'START') {
            const contractStart = this.$scope.model.FREIGHT_CONTRACT && this.$scope.model.FREIGHT_CONTRACT.VALIDITY_START ? moment(this.$scope.model.FREIGHT_CONTRACT.VALIDITY_START) : null;
            const routeStart = this.$scope.model.DISPLAY_VALIDITY_START ? moment(this.$scope.model.DISPLAY_VALIDITY_START) : null;

            if (contractStart && routeStart && routeStart.isBefore(contractStart)) {
                this.$scope.model.DISPLAY_VALIDITY_START = null;

                NotificationService().error(`The route's start date (${moment(routeStart).format(format)}) is less than the contract's start date (${moment(contractStart).format(format)})`);
            }
        }

        if (type == 'END') {
            const contractEnd = this.$scope.model.FREIGHT_CONTRACT && this.$scope.model.FREIGHT_CONTRACT.VALIDITY_END ? moment(this.$scope.model.FREIGHT_CONTRACT.VALIDITY_END) : null;
            const routeEnd = this.$scope.model.DISPLAY_VALIDITY_END ? moment(this.$scope.model.DISPLAY_VALIDITY_END) : null;

            if (contractEnd && routeEnd && routeEnd.isAfter(contractEnd)) {
                this.$scope.model.DISPLAY_VALIDITY_END = null;

                NotificationService().error(`The route end date (${moment(routeEnd).format(format)}) is greater than the contract end date (${moment(contractEnd).format(format)})`);
            }
        }
    }

    private checkDateIsBetween(): void{
        this.checkDateValidity(this.$scope.model.DISPLAY_VALIDITY_START, this.$scope.model.DISPLAY_VALIDITY_END, 'displayValidityDate');
        const contractStart = this.$scope.model.FREIGHT_CONTRACT && this.$scope.model.FREIGHT_CONTRACT.VALIDITY_START ? moment(this.$scope.model.FREIGHT_CONTRACT.VALIDITY_START) : null;
        const contractEnd = this.$scope.model.FREIGHT_CONTRACT && this.$scope.model.FREIGHT_CONTRACT.VALIDITY_END ? moment(this.$scope.model.FREIGHT_CONTRACT.VALIDITY_END) : null;
            
        const routeStart = this.$scope.model.DISPLAY_VALIDITY_START ? moment(this.$scope.model.DISPLAY_VALIDITY_START) : null;
        const routeEnd = this.$scope.model.DISPLAY_VALIDITY_END ? moment(this.$scope.model.DISPLAY_VALIDITY_END) : null;

        const format = 'DD/MM/YYYY';

            if (contractStart && routeStart && !routeStart.isBetween(contractStart,contractEnd) && (!routeStart.isSameOrAfter(contractStart) || !routeStart.isSameOrBefore(contractEnd))) {
                this.$scope.model.DISPLAY_VALIDITY_START = null;

                NotificationService().error(`The route's start date (${moment(routeStart).format(format)}) is not between the contract start date`);
            }

            if (contractEnd && routeEnd && !routeEnd.isBetween(contractStart,contractEnd) && (!routeEnd.isSameOrAfter(contractStart) || !routeEnd.isSameOrBefore(contractEnd))) {
                this.$scope.model.DISPLAY_VALIDITY_END = null;

                NotificationService().error(`The route end date (${moment(routeEnd).format(format)}) is not between the contract start date`);
            }

    }

    private initTablePerDiemPerRoute(): void {
        if (this.$scope.model.FREIGHT_CONTRACT.DET_DEM_ORIGIN && this.$scope.model.DET_DEM_ORIGIN) {
            this.$scope.tablePerDiemDefaultContractOrigin = true;
            this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM = this.$scope.model.FREIGHT_CONTRACT.DET_DEM_ORIGIN.TABLE_PER_DIEM;
        }
        if (this.$scope.model.FREIGHT_CONTRACT.DET_DEM_DESTINATION && this.$scope.model.DET_DEM_DESTINATION) {
            this.$scope.tablePerDiemDefaultContractDestination = true;
            this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM = this.$scope.model.FREIGHT_CONTRACT.DET_DEM_DESTINATION.TABLE_PER_DIEM;
        }
    }

    private generateConcatenatedOriginDestination(): void {
        try {
            const freightRoutes = this.$scope.model;
            const separator = " | ";
            let concatenated = "";

            if (freightRoutes.PUP && freightRoutes.PUP.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, freightRoutes.PUP.map(obj => { return obj.CODE }).join(',')) : concatenated.concat(freightRoutes.PUP.map(obj => { return obj.CODE }).join(','));
            }

            if (freightRoutes.OTFS && freightRoutes.OTFS.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, freightRoutes.OTFS.map(obj => { return obj.CODE }).join(',')) : concatenated.concat(freightRoutes.OTFS.map(obj => { return obj.CODE }).join(','));
            }

            if (freightRoutes.POL_AOL && freightRoutes.POL_AOL.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, freightRoutes.POL_AOL.map(obj => { return obj.CODE }).join(',')) : concatenated.concat(freightRoutes.POL_AOL.map(obj => { return obj.CODE }).join(','));
            }

            if (freightRoutes.POD_AOD && freightRoutes.POD_AOD.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, freightRoutes.POD_AOD.map(obj => { return obj.CODE }).join(',')) : concatenated.concat(freightRoutes.POD_AOD.map(obj => { return obj.CODE }).join(','));
            }

            if (freightRoutes.DTFS && freightRoutes.DTFS.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, freightRoutes.DTFS.map(obj => { return obj.CODE }).join(',')) : concatenated.concat(freightRoutes.DTFS.map(obj => { return obj.CODE }).join(','));
            }

            if (freightRoutes.PLD && freightRoutes.PLD.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, freightRoutes.PLD.map(obj => { return obj.CODE }).join(',')) : concatenated.concat(freightRoutes.PLD.map(obj => { return obj.CODE }).join(','));
            }

            freightRoutes.CONCATENATED_ROUTES = StringUtil.formatConcatenatedChars(concatenated);
            this.generateConcatenated()
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private generateConcatenated(): void {
        try {
            const separator = " | ";
            let concatenated = "";

            if (this.$scope.model.FREIGHT_CONTRACT.PROVIDER && this.$scope.model.FREIGHT_CONTRACT.PROVIDER.TYPE) {
                if ((this.$scope.model.FREIGHT_CONTRACT.PROVIDER.TYPE.ID == EProviderTypeId.SHIPOWNER || this.$scope.model.FREIGHT_CONTRACT.PROVIDER.TYPE.ID == EProviderTypeId.AIRLINE) && this.$scope.model.FREIGHT_CONTRACT.PROVIDER.CODE && this.$scope.model.FREIGHT_CONTRACT.PROVIDER.CODE.length) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.FREIGHT_CONTRACT.PROVIDER.CODE) : concatenated.concat(this.$scope.model.FREIGHT_CONTRACT.PROVIDER.CODE);
                } else if (this.$scope.model.FREIGHT_CONTRACT.PROVIDER.NAME && this.$scope.model.FREIGHT_CONTRACT.PROVIDER.NAME.length) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.FREIGHT_CONTRACT.PROVIDER.NAME) : concatenated.concat(this.$scope.model.FREIGHT_CONTRACT.PROVIDER.NAME);
                }
            }

            if (this.$scope.model.FREIGHT_CONTRACT.FREIGHT_CONTRACT && this.$scope.model.FREIGHT_CONTRACT.FREIGHT_CONTRACT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.FREIGHT_CONTRACT.FREIGHT_CONTRACT) : concatenated.concat(this.$scope.model.FREIGHT_CONTRACT.FREIGHT_CONTRACT);
            }

            if (this.$scope.model.FREIGHT_CONTRACT.ACCOUNT && this.$scope.model.FREIGHT_CONTRACT.ACCOUNT.length) {
                const account = this.$scope.getCONCAT(this.$scope.model.FREIGHT_CONTRACT.ACCOUNT, null, "CODE", null, null, ',')
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, account) : concatenated.concat(account);
            }

            if (this.$scope.model.FREIGHT_CONTRACT.CONTRACT_DESCRIPTION && this.$scope.model.FREIGHT_CONTRACT.CONTRACT_DESCRIPTION.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.FREIGHT_CONTRACT.CONTRACT_DESCRIPTION) : concatenated.concat(this.$scope.model.FREIGHT_CONTRACT.CONTRACT_DESCRIPTION);
            }

            if (this.$scope.model.DETAILS && this.$scope.model.DETAILS.length) {
                const detail = this.$scope.getCONCAT(this.$scope.model.DETAILS, null, "NAME", null, null, ',')
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, detail) : concatenated.concat(detail);
            }

            if (this.$scope.model.CONCATENATED_ROUTES) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CONCATENATED_ROUTES) : concatenated.concat(this.$scope.model.CONCATENATED_ROUTES);
            }

            if (this.$scope.model.DET_DEM_ORIGIN && this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE && this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE.NAME) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE.NAME) : concatenated.concat(this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE.NAME);
            }

            if (this.$scope.model.DET_DEM_DESTINATION && this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE && this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE.NAME) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE.NAME) : concatenated.concat(this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE.NAME);
            }

            if (this.$scope.model.CONCATENATED_COMPLEMENT) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CONCATENATED_COMPLEMENT) : concatenated.concat(this.$scope.model.CONCATENATED_COMPLEMENT);
            }

            if (this.$scope.model.DISPLAY_VALIDITY_START) {
                const validity = this.$scope.model.DISPLAY_VALIDITY_END ? moment(this.$scope.model.DISPLAY_VALIDITY_START).format('DD/MM/YY') + '-' + moment(this.$scope.model.DISPLAY_VALIDITY_END).format('DD/MM/YY') : moment(this.$scope.model.DISPLAY_VALIDITY_START).format('DD/MM/YY');
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, validity) : concatenated.concat(validity);
            }

            this.$scope.model.CONCATENATED = StringUtil.formatConcatenatedChars(concatenated);

            if (this.$scope.menuFloating) this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private addDetDemRoute(): void {
        const perDiemTypeTable = this.$scope.perDiemTypeList.find(x => x.ID == ETariffFreightPerDiemType.TABLE);

        if (!this.$scope.model.DET_DEM_ORIGIN) {
            this.$scope.model.DET_DEM_ORIGIN = {
                DET_DEM_TYPE: null,
                PER_DIEM_TYPE: null,
                FREE_TIME_TYPE: null,
                FIXED_ADDITIONAL: null,
                PERCENTAGE_ADDITIONAL: null,
                TABLE_PER_DIEM: null,
                EQUIPMENT_FREE_TIME: []
            }
            if (this.$scope.model.FREIGHT_CONTRACT.DET_DEM_ORIGIN) {
                this.$scope.tablePerDiemDefaultContractOrigin = true;
                this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM = this.$scope.model.FREIGHT_CONTRACT.DET_DEM_ORIGIN.TABLE_PER_DIEM;
            }
            if (this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID == EProductId.MARITIME_EXPORT && this.$scope.model.FREIGHT_CONTRACT.TRANSACTION.ID == EPaymentNatureId.PAYMENT) {
                this.$scope.model.DET_DEM_ORIGIN.PER_DIEM_TYPE = perDiemTypeTable;
            }
        }

        if (!this.$scope.model.DET_DEM_DESTINATION) {
            this.$scope.model.DET_DEM_DESTINATION = {
                DET_DEM_TYPE: null,
                PER_DIEM_TYPE: null,
                FREE_TIME_TYPE: null,
                FIXED_ADDITIONAL: null,
                PERCENTAGE_ADDITIONAL: null,
                TABLE_PER_DIEM: null,
                EQUIPMENT_FREE_TIME: []
            }

            if (this.$scope.model.FREIGHT_CONTRACT.DET_DEM_DESTINATION) {
                this.$scope.tablePerDiemDefaultContractDestination = true;
                this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM = this.$scope.model.FREIGHT_CONTRACT.DET_DEM_DESTINATION.TABLE_PER_DIEM;
            }
            if (this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID == EProductId.MARITIME_IMPORT && this.$scope.model.FREIGHT_CONTRACT.TRANSACTION.ID == EPaymentNatureId.PAYMENT) {
                this.$scope.model.DET_DEM_DESTINATION.PER_DIEM_TYPE = perDiemTypeTable;
            }
        }

        let arrayFields: string[] = [];
        arrayFields.push(`route_det_dem_fixed_additional_origin`);
        arrayFields.push(`route_det_dem_percentage_additional_origin`);
        arrayFields.push(`route_det_dem_fixed_additional_destination`);
        arrayFields.push(`route_det_dem_percentage_additional_destination`);
        this.$timeout(() => {
            this.$scope.collapseHeader("collapseDetDemRoute", "show");
            this.$scope.setDirtyFields(arrayFields);
        }, 1000);

        const product = this.$scope.model.FREIGHT_CONTRACT.PRODUCT && this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID;
        if (product && product == EProductId.MARITIME_EXPORT) {
            this.$timeout(() => {
                this.$scope.collapseHeader("routeCollapseDetDemOrigin", "show");
                this.$scope.selectorValidity(`route_det_dem_type_origin`);
                this.$scope.selectorValidity(`route_per_diem_type_origin`);
                this.$scope.selectorValidity(`route_free_time_type_origin`);
                this.$scope.selectorValidity(`routeTablePerDiemOrigin`);
            }, 1000);
        } else if (product && product == EProductId.MARITIME_IMPORT) {
            this.$timeout(() => {
                this.$scope.collapseHeader("routeCollapseDetDemDestination", "show");
                this.$scope.selectorValidity(`route_det_dem_type_destination`);
                this.$scope.selectorValidity(`route_per_diem_type_destination`);
                this.$scope.selectorValidity(`route_free_time_type_destination`);
                this.$scope.selectorValidity(`routeTablePerDiemDestination`);
            }, 1000);
        }
    }

    private async removeDetDemRoute(): Promise<void> {
        try {
            const msg = "Deseja remover os dados de Detention e Demurrage para essa rota?";
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: msg
            });

            if (!modal) return;

            this.formService.block();
            this.$scope.model.DET_DEM_DESTINATION = null;
            this.$scope.model.DET_DEM_ORIGIN = null;
            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private addRouteEquipmentFreeTime(originDestination: number): void {
        try {
            const msg = 'Select Tariff Type first.';
            if (originDestination == 1 && (!this.$scope.model.DET_DEM_ORIGIN || !this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE)) return this.formService.notifyError(msg);
            if (originDestination == 2 && (!this.$scope.model.DET_DEM_DESTINATION || !this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE)) return this.formService.notifyError(msg);

            const equipmentDetDemFreeTime: IEquipmentFreeTime = {
                EQUIPMENT: null,
                COMBINED_DET_DEM_HOUSE_FREE_TIME: null,
                COMBINED_DET_DEM_MASTER_FREE_TIME: null,
                SPLITTED_DEMURRAGE_HOUSE_FREE_TIME: null,
                SPLITTED_DEMURRAGE_MASTER_FREE_TIME: null,
                SPLITTED_DETENTION_HOUSE_FREE_TIME: null,
                SPLITTED_DETENTION_MASTER_FREE_TIME: null,
            };

            if (originDestination == 1) {
                if (!this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME) this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME = [];
                this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME.push(equipmentDetDemFreeTime);

                if (this.$scope.model.DET_DEM_ORIGIN && this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE && this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE.ID == ETariffTypeDetDem.COMBINED) {
                    this.$timeout(() => {
                        this.$scope.setDirtyFields([`combinedDetDemHouseFreeTimeOrigin`]);
                    }, 100);
                }

                if (this.$scope.model.DET_DEM_ORIGIN && this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE && this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE.ID == ETariffTypeDetDem.DET_DEM) {
                    this.$timeout(() => {
                        this.$scope.setDirtyFields([`splittedDetentionHouseFreeTimeOrigin`, `splittedDemurrageHouseFreeTimeOrigin`]);
                    }, 100);
                }
            }

            if (originDestination == 2) {
                if (!this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME) this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME = [];
                this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME.push(equipmentDetDemFreeTime);

                if (this.$scope.model.DET_DEM_DESTINATION && this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE && this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE.ID == ETariffTypeDetDem.COMBINED) {
                    this.$timeout(() => {
                        this.$scope.setDirtyFields([`combinedDetDemHouseFreeTimeDestination`]);
                    }, 100);
                }
                if (this.$scope.model.DET_DEM_DESTINATION && this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE && this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE.ID == ETariffTypeDetDem.DET_DEM) {
                    this.$timeout(() => {
                        this.$scope.setDirtyFields([`splittedDetentionHouseFreeTimeDestination`, `splittedDemurrageHouseFreeTimeDestination`]);
                    }, 100);
                }
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getEquipmentListForDetDem(search: string): Promise<void> {
        let result: IEquipmentSelector[] = [];
        let tempResult: IEquipmentSelector[] = [];
        try {
            if (search && search.length >= 2) {
                if (!this.$scope.model.FREIGHT_CONTRACT.PRODUCT) {
                    throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                }

                this.formService.block();

                const products = [this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID];
                const equipmentList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/equipment/list/custom`, { search, products }, 30000, false);
                tempResult = equipmentList ? equipmentList.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, TEU: x.TEU, FEET: x.FEET } }) : [];

                for (const equip of tempResult) {
                    if (this.$scope.routeEquipmentList.some(item => item.ID == equip.ID)) result.push(equip);
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.equipmentFreeTimeList = result;
        }
    }

    private async removeDetDemRouteEquipment(index: number, property: string) {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: `${this.formService.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")}`
            });
            if (!modal) return;

            if (this.$scope.model[property] && this.$scope.model[property].EQUIPMENT_FREE_TIME.length > 0) {
                this.formService.block();
                this.$scope.model[property].EQUIPMENT_FREE_TIME.splice(index, 1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private checkPerDiemTypeChangeRoute(originDestination: number): void {
        if (originDestination == 1 && this.$scope.model.DET_DEM_ORIGIN) {
            switch (this.$scope.model.DET_DEM_ORIGIN.PER_DIEM_TYPE.ID) {
                case ETariffFreightPerDiemType.DEFAULT_ALLOG:
                    this.$scope.model.DET_DEM_ORIGIN.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_ORIGIN.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM = null;
                    break;
                case ETariffFreightPerDiemType.TRANSFER_ALL:
                    this.$scope.model.DET_DEM_ORIGIN.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_ORIGIN.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM = null;
                    break;
                case ETariffFreightPerDiemType.TRANSFER_PLUS_FIXED:
                    this.$scope.model.DET_DEM_ORIGIN.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM = null;
                    break;
                case ETariffFreightPerDiemType.TRANSFER_PLUS_PERCENTAGE:
                    this.$scope.model.DET_DEM_ORIGIN.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM = null;
                    break;
                case ETariffFreightPerDiemType.TABLE:
                    this.$scope.model.DET_DEM_ORIGIN.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_ORIGIN.PERCENTAGE_ADDITIONAL = null;
                    break;
                default:
                    this.formService.notifyError('No Per Diem Type ID at Route Origin selected.');
                    break;
            }

            this.$timeout(() => {
                this.$scope.setDirtyFields([`route_det_dem_fixed_additional_origin`, `route_det_dem_percentage_additional_origin`]);
                this.$scope.selectorValidity(`route_det_dem_type_origin`);
                this.$scope.selectorValidity(`route_per_diem_type_origin`);
                this.$scope.selectorValidity(`route_free_time_type_origin`);
                this.$scope.selectorValidity(`routeTablePerDiemOrigin`);
            }, 100);
        }

        if (originDestination == 2 && this.$scope.model.DET_DEM_DESTINATION) {
            switch (this.$scope.model.DET_DEM_DESTINATION.PER_DIEM_TYPE.ID) {
                case ETariffFreightPerDiemType.DEFAULT_ALLOG:
                    this.$scope.model.DET_DEM_DESTINATION.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_DESTINATION.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM = null;
                    break;
                case ETariffFreightPerDiemType.TRANSFER_ALL:
                    this.$scope.model.DET_DEM_DESTINATION.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_DESTINATION.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM = null;
                    break;
                case ETariffFreightPerDiemType.TRANSFER_PLUS_FIXED:
                    this.$scope.model.DET_DEM_DESTINATION.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM = null;
                    break;
                case ETariffFreightPerDiemType.TRANSFER_PLUS_PERCENTAGE:
                    this.$scope.model.DET_DEM_DESTINATION.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM = null;
                    break;
                case ETariffFreightPerDiemType.TABLE:
                    this.$scope.model.DET_DEM_DESTINATION.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_DESTINATION.PERCENTAGE_ADDITIONAL = null;
                    break;
                default:
                    this.formService.notifyError('No Per Diem Type ID at Route Destination selected.');
                    break;
            }

            this.$timeout(() => {
                this.$scope.setDirtyFields([`route_det_dem_fixed_additional_destination`, `route_det_dem_percentage_additional_destination`]);
                this.$scope.selectorValidity(`route_det_dem_type_destination`);
                this.$scope.selectorValidity(`route_per_diem_type_destination`);
                this.$scope.selectorValidity(`route_free_time_type_destination`);
                this.$scope.selectorValidity(`routeTablePerDiemDestination`);
            }, 100);
        }
    }

    private checkReadOnlyRoute(originDestination: number, perDiemTypeId: string): boolean {
        let readOnly = false;
        try {
            if (originDestination == 1) {
                if (!this.$scope.model.FREIGHT_CONTRACT.TRANSACTION || !this.$scope.model.DET_DEM_ORIGIN || !this.$scope.model.DET_DEM_ORIGIN.PER_DIEM_TYPE) {
                    readOnly = true;
                }

                if (this.$scope.model.FREIGHT_CONTRACT.TRANSACTION && this.$scope.model.FREIGHT_CONTRACT.TRANSACTION.ID == EPaymentNatureId.RECEIVING && this.$scope.model.DET_DEM_ORIGIN
                    && this.$scope.model.DET_DEM_ORIGIN.PER_DIEM_TYPE && this.$scope.model.DET_DEM_ORIGIN.PER_DIEM_TYPE.ID
                    && this.$scope.model.DET_DEM_ORIGIN.PER_DIEM_TYPE.ID != perDiemTypeId) {
                    readOnly = true;
                }
            } else if (originDestination == 2) {
                if (!this.$scope.model.FREIGHT_CONTRACT.TRANSACTION || !this.$scope.model.DET_DEM_DESTINATION || !this.$scope.model.DET_DEM_DESTINATION.PER_DIEM_TYPE) {
                    readOnly = true;
                }

                if (this.$scope.model.FREIGHT_CONTRACT.TRANSACTION && this.$scope.model.FREIGHT_CONTRACT.TRANSACTION.ID == EPaymentNatureId.RECEIVING && this.$scope.model.DET_DEM_DESTINATION
                    && this.$scope.model.DET_DEM_DESTINATION.PER_DIEM_TYPE && this.$scope.model.DET_DEM_DESTINATION.PER_DIEM_TYPE.ID
                    && this.$scope.model.DET_DEM_DESTINATION.PER_DIEM_TYPE.ID != perDiemTypeId) {
                    readOnly = true;
                }
            }
            if (this.$scope.model.FREIGHT_CONTRACT.TRANSACTION && this.$scope.model.FREIGHT_CONTRACT.TRANSACTION.ID == EPaymentNatureId.PAYMENT) {
                readOnly = true;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
        return readOnly;
    }

    private async setTablePerDiemRoute(originDestination: number): Promise<void> {
        try {
            if (originDestination == 1) {
                if (this.$scope.tablePerDiemDefaultContractOrigin && this.$scope.tablePerDiemDefaultContractOrigin === true) {
                    const msg = "Deseja trocar para a tabela Per Diem do Tarifário de Frete da Origem?"
                    const modal = await this.ModalService.showModalConfirmation({}, {
                        actionButtonText: "GENERAL.CONFIRM",
                        closeButtonText: "GENERAL.NO",
                        headerText: "GENERAL.CONFIRM_ACTION",
                        bodyText: msg
                    });

                    if (!modal) {
                        this.$scope.tablePerDiemDefaultContractOrigin = !this.$scope.tablePerDiemDefaultContractOrigin;
                        return;
                    }
                    this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM = null;
                }
            } else if (originDestination == 2) {
                if (this.$scope.tablePerDiemDefaultContractDestination && this.$scope.tablePerDiemDefaultContractDestination === true) {
                    const msg = "Deseja trocar para a tabela Per Diem do Tarifário de Frete de Destino?"
                    const modal = await this.ModalService.showModalConfirmation({}, {
                        actionButtonText: "GENERAL.CONFIRM",
                        closeButtonText: "GENERAL.NO",
                        headerText: "GENERAL.CONFIRM_ACTION",
                        bodyText: msg
                    });

                    if (!modal) {
                        this.$scope.tablePerDiemDefaultContractDestination = !this.$scope.tablePerDiemDefaultContractDestination;
                        return;
                    }
                    this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM = null;
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getTablePerDiemList(direction: string, tariffTypeId: string): Promise<void> {
        let result: SelectorModel[] = [];
        this.formService.block();
        try {
            if (this.$scope.model.FREIGHT_CONTRACT.TRANSACTION) {
                const getDefault: boolean = this.$scope.model.FREIGHT_CONTRACT.TRANSACTION.ID == EPaymentNatureId.RECEIVING ? false : null;
                const filterEquipmentProvider: object = this.$scope.model.FREIGHT_CONTRACT.TRANSACTION.ID == EPaymentNatureId.PAYMENT ? { ID_EQUIPMENT_PROVIDER: { $eq: null } } : null;
                const data: ITablePerDiemQueryFields = {
                    TRANSACTION: this.$scope.model.FREIGHT_CONTRACT.TRANSACTION.ID,
                    DIRECTION: direction,
                    PROVIDER: this.$scope.model.FREIGHT_CONTRACT.PROVIDER ? [parseInt(this.$scope.model.FREIGHT_CONTRACT.PROVIDER.ID)] : null,
                    COUNTRY_CODE: 'BR',
                    ID_TABLE_TYPE: tariffTypeId,
                    ...filterEquipmentProvider,
                    ACTIVE: true,
                    DEFAULT: getDefault,
                };

                const tablesPerDiem = await this.RestService.newObjectPromise(`${this.$baseUrl}/tablePerDiem/listByFullName`, { data }, 30000, false);
                if (tablesPerDiem) result = tablesPerDiem.map(tablePerDiem => { return { ID: tablePerDiem.ID, NAME: tablePerDiem.CONCATENATED, CODE: null } });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.tablePerDiemList = result;
        }
    }

    private checkRouteFreeTimeSplittedDetention(transactionId: string, originDestination: number, index: number): void {
        let master: number;
        let house: number;
        if (transactionId == EPaymentNatureId.PAYMENT) {
            if (originDestination == 1) {
                master = this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_MASTER_FREE_TIME;
                house = this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_HOUSE_FREE_TIME;
            } else if (originDestination == 2) {
                master = this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_MASTER_FREE_TIME;
                house = this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_HOUSE_FREE_TIME;
            }

            if (house > master) {
                if (originDestination == 1) {
                    this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_HOUSE_FREE_TIME = null;
                } else if (originDestination == 2) {
                    this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_HOUSE_FREE_TIME = null;
                }
                const msg = "Não é possível informar um valor maior para o House no Free Time de Splitted Detention.";
                this.formService.notifyError(msg);
            }
        }
    }

    private checkRouteFreeTimeSplittedDemurrage(transactionId: string, originDestination: number, index: number): void {
        let master: number;
        let house: number;
        if (transactionId == EPaymentNatureId.PAYMENT) {
            if (originDestination == 1) {
                master = this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_MASTER_FREE_TIME;
                house = this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_HOUSE_FREE_TIME;
            } else if (originDestination == 2) {
                master = this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_MASTER_FREE_TIME;
                house = this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_HOUSE_FREE_TIME;
            }
            if (house > master) {
                if (originDestination == 1) {
                    this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_HOUSE_FREE_TIME = null;
                } else if (originDestination == 2) {
                    this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_HOUSE_FREE_TIME = null;
                }
                const msg = "Não é possível informar um valor maior para o House no Free Time de Splitted Demurrage.";
                this.formService.notifyError(msg);
            }
        }
    }

    private checkRouteFreeTimeCombined(transactionId: string, originDestination: number, index: number): void {
        let master: number;
        let house: number;
        if (transactionId == EPaymentNatureId.PAYMENT) {
            if (originDestination == 1) {
                master = this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_MASTER_FREE_TIME;
                house = this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_HOUSE_FREE_TIME;
            } else if (originDestination == 2) {
                master = this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_MASTER_FREE_TIME;
                house = this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_HOUSE_FREE_TIME;
            }

            if (house > master) {
                const msg = "Não é possível informar um valor maior para o House no Free Time Combined.";

                this.formService.notifyError(msg);
                if (originDestination == 1) {
                    this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_HOUSE_FREE_TIME = null;
                } else if (originDestination == 2) {
                    this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_HOUSE_FREE_TIME = null;
                }
            }
        }
    }

    private initRouteFreeTimeTable(originDestination: number): void {

        const freeTimeItem: IEquipmentFreeTime = {
            COMBINED_DET_DEM_HOUSE_FREE_TIME: null,
            COMBINED_DET_DEM_MASTER_FREE_TIME: null,
            EQUIPMENT: [],
            SPLITTED_DEMURRAGE_HOUSE_FREE_TIME: null,
            SPLITTED_DEMURRAGE_MASTER_FREE_TIME: null,
            SPLITTED_DETENTION_HOUSE_FREE_TIME: null,
            SPLITTED_DETENTION_MASTER_FREE_TIME: null
        };

        if (originDestination == 1) {
            if (this.$scope.model.DET_DEM_ORIGIN && this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME.length == 0) {
                this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME.push(freeTimeItem);
            }

            if (this.$scope.model.DET_DEM_ORIGIN && this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE.ID == ETariffTypeDetDem.COMBINED) {
                this.$timeout(() => {
                    this.$scope.setDirtyFields([`routeCombinedDetDemHouseFreeTimeOrigin`]);
                }, 100);
            }

            if (this.$scope.model.DET_DEM_ORIGIN && this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE.ID == ETariffTypeDetDem.DET_DEM) {
                this.$timeout(() => {
                    this.$scope.setDirtyFields([`splittedDetentionHouseFreeTimeOrigin`, `splittedDemurrageHouseFreeTimeOrigin`]);
                }, 100);
            }

        }

        if (originDestination == 2) {
            if (this.$scope.model.DET_DEM_DESTINATION && this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME.length == 0) {
                this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME.push(freeTimeItem);
            }

            if (this.$scope.model.DET_DEM_DESTINATION && this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE.ID == ETariffTypeDetDem.COMBINED) {
                this.$timeout(() => {
                    this.$scope.setDirtyFields([`routeCombinedDetDemHouseFreeTimeDestination`]);
                }, 100);
            }

            if (this.$scope.model.DET_DEM_DESTINATION && this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE.ID == ETariffTypeDetDem.DET_DEM) {
                this.$timeout(() => {
                    this.$scope.setDirtyFields([`splittedDetentionHouseFreeTimeDestination`, `splittedDemurrageHouseFreeTimeDestination`]);
                }, 100);
            }
        }
    }

    private async getAllogFreightContractListByName(search: string): Promise<void> {
        let allogFreightContracts = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const result = await this.ProductService.post({ route: `/newFreightContract/list/custom`, data: { search: search } });
                allogFreightContracts = result && result.data && result.data.data
                    ? result.data.data.map(freightContract => { return { ID: freightContract.ID, NAME: `${freightContract.ALLOG_FREIGHT_CONTRACT} | ${freightContract.FREIGHT_CONTRACT}`, CODE: null } })
                    : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.allogFreightContractList = allogFreightContracts;
            this.formService.unblock();
        }
    }

    private async getFreightContractById() {
        this.formService.block();
        const idFreightContract = this.$scope.model.ALLOG_FREIGHT_CONTRACT ? this.$scope.model.ALLOG_FREIGHT_CONTRACT.ID : null;
        try {
            if (idFreightContract) {
                const oldFreightContract = this.$scope.model.FREIGHT_CONTRACT;
                const result = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/newFreightContract/getById/${idFreightContract}`, 30000, null, false);
                this.$scope.model.FREIGHT_CONTRACT = result.data;
                this.$scope.model.EVENT = (this.$scope.model.FREIGHT_CONTRACT && this.$scope.model.FREIGHT_CONTRACT.VALIDITY_BASIS) ? this.$scope.model.FREIGHT_CONTRACT.VALIDITY_BASIS : null;

                // Reset EQUIPMENT_WEIGHT_RANGE_VEHICLE
                this.$scope.model.EQUIPMENT_WEIGHT_RANGE_VEHICLE = null;

                if (this.$scope.model.CHARGE && this.$scope.model.CHARGE.length && this.$scope.model.FREIGHT_CONTRACT && this.$scope.model.FREIGHT_CONTRACT.TRANSACTION && this.$scope.model.FREIGHT_CONTRACT.TRANSACTION.ID && this.$scope.model.FREIGHT_CONTRACT.TRANSACTION.ID == EPaymentNatureId.RECEIVING) {
                    for (const charge of this.$scope.model.CHARGE) {
                        charge.FREIGHT_ROUTES_PAYMENT.FREE_OF_CHARGE = true;
                        charge.FREIGHT_ROUTES_PAYMENT.ID_CURRENCY = null;
                        charge.FREIGHT_ROUTES_PAYMENT.CURRENCY = null;
                        charge.FREIGHT_ROUTES_PAYMENT.UNITARY = null;
                        charge.FREIGHT_ROUTES_PAYMENT.MIN = null;
                        charge.FREIGHT_ROUTES_PAYMENT.HOLDER_TYPE = null;
                        charge.FREIGHT_ROUTES_PAYMENT.ID_LEGAL_PERSON_HOLDER = null;
                        charge.FREIGHT_ROUTES_PAYMENT.LEGAL_PERSON_HOLDER = null;
                        charge.FREIGHT_ROUTES_PAYMENT.ID_PHYSICAL_PERSON_HOLDER = null;
                        charge.FREIGHT_ROUTES_PAYMENT.PHYSICAL_PERSON_HOLDER = null;
                        charge.FREIGHT_ROUTES_PAYMENT.WEIGHT_RANGE_CHARGE = null;
                    }

                    this.$scope.chargeTableControl.data = angular.copy(this.$scope.model.CHARGE);
                    this.applyChargeFilterTable();
                }

                this.$scope.chargesTableOptions.viewTariffAirFreight.disabled = this.checkProductId();
                this.initTablePerDiemPerRoute();
                await this.updateControl();

                if (oldFreightContract && oldFreightContract.PRODUCT.ID != this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID) this.$scope.model.MOVE_TYPE = null;

                this.checkDateIsBetween();

                this.changeMoveTypeToAirPort();
                this.clearRoutingPoint();

                this.formService.validateForm();
            }

            if (!this.$scope.isLandProduct) this.$scope.dailyRouteModel = null;
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.generateConcatenated();
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private changeMoveTypeToAirPort(): void {
        const isFreightContractAir = this.$scope.model.FREIGHT_CONTRACT && (this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID == EProductId.AIR_EXPORT || this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID == EProductId.AIR_IMPORT);

        if (isFreightContractAir) {
            const airMoveType = this.$scope.moveTypeFullList.find(moveType => moveType.ID.toString() === EMoveTypeId.AIRPORT_TO_AIRPORT);

            this.$scope.model.MOVE_TYPE = {
                ID: airMoveType.ID.toString(),
                NAME: airMoveType.NAME,
                CODE: airMoveType.CODE
            };

            this.$scope.updateMoveType(this.$scope.model.MOVE_TYPE);
        }
    }

    private getMenuFloatingDefault(): IFloatingMenu {
        const dailyRate: IFloatingOption[] = this.$scope.isLandProduct ? [{ click: "collapseHeader", args: ['collapseDailyRoute'], tooltipPlacement: "auto bottom", textTooltip: "REGISTRATION.DAILY_ROUTE", iconClass: `fa fa-truck`, iconBodyClass: "text-cyano" }] : [];
        const detDem: IFloatingOption[] = this.$scope.isMaritimeProduct ? [{ click: "collapseHeader", args: ['collapseDetDemRoute'], tooltipPlacement: "auto bottom", textTooltip: "PRODUCT.DET_DEM", iconClass: "fa fa-map-marker", iconBodyClass: "text-brown" }] : [];
        return {
            tooltipPlacement: "auto bottom",
            options: [
                { click: "collapseHeader", args: ['collapseBasicData'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.BASIC_DATA", iconClass: "fa fa-address-card", iconBodyClass: "text-brown" },
                { click: "collapseHeader", args: ['collapseRoutingDetails'], tooltipPlacement: "auto bottom", textTooltip: "BASIC_DATA.ROUTING_DETAILS", iconClass: "fa fa-address-book-o", iconBodyClass: "text-rouge" },
                { click: "collapseHeader", args: ['collapseCharges'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.CHARGES", iconClass: "fa fa-usd", iconBodyClass: "text-green" },
                ...dailyRate,
                ...detDem,
                { click: "collapseHeader", args: ['collapseUploads'], tooltipPlacement: "auto bottom", textTooltip: "REGISTRATION.EMAIL_ATTACHMENT", iconClass: "fa fa-files-o", iconBodyClass: "text-gray" },
                { click: "collapseHeader", args: ['collapseFreightRoutesObservation'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.REMARKS", iconClass: "fa fa-arrows-alt", iconBodyClass: "text-blue" },
                { click: "collapseHeader", args: ['collapseEquipment'], tooltipPlacement: "auto bottom", textTooltip: "PRODUCT.WEIGHT_LIMITE", iconClass: "fa fa-tachometer", iconBodyClass: "text-green" },
                { click: "collapseHeader", args: ['collapseAll'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.COLLAPSE_EXPAND_ALL", iconClass: "fa fa-expand", iconBodyClass: "text-danger" }
            ],
            btnActiveDisabled: false
        };
    }

    private removeUpload(model: IUploadItem): boolean {
        let result = false;
        try {
            if (!model) throw Error('item parameter is null');

            const isAdminOrCoordinator = (this.$scope.user.roles && (this.$scope.user.roles.hasOwnProperty('ADMIN') || this.$scope.user.roles.hasOwnProperty('COORDINATOR')));

            if (isAdminOrCoordinator) {
                result = true;
            } else {
                // check if in model.FILES_NEW;
                if (this.$scope.model.FILES_NEW && this.$scope.model.FILES_NEW.length > 0) {
                    const index = this.$scope.model.FILES_NEW.findIndex(file => { return file.FILE_HASH == model.FILE_HASH });
                    if (index > -1) {
                        // Remove from FILES_NEW
                        this.$scope.model.FILES_NEW.splice(index, 1);
                        result = true;
                    }
                }
            }

            if (result) {
                if (!this.$scope.model.FILES_REMOVE) this.$scope.model.FILES_REMOVE = [];
                this.$scope.model.FILES_REMOVE.push(model);
            } else {
                throw Error('You don\'t have permission to remove this file');
            }
        } catch (ex) {
            result = false;
            this.handleError(ex);
        } finally {
            return result;
        }
    }

    private onSuccessItem(item: IFileItem, response: ISuccessItemResponse): void {
        try {
            if (!item) throw Error('item parameter is null');
            if (!response) throw Error('response parameter is null');

            if (response.data && response.data.length > 0) {
                if (!this.$scope.model.FILES_NEW) this.$scope.model.FILES_NEW = [];

                for (let dataItem of response.data) {
                    const file: IUploadItem = {
                        FILE_DISPLAY_NAME: dataItem.displayFileName,
                        FILE_NAME: dataItem.fileName,
                        FILE_TYPE: dataItem.fileType,
                        FILE_PATH: dataItem.filePath,
                        FILE_URL: dataItem.fileUrl,
                        FILE_HASH: dataItem.fileHash,
                        FILE_SIZE: dataItem.fileSize,
                        FORM_NAME: dataItem.formName
                    }
                    this.$scope.model.FILES_NEW.push(file)
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'tariff_files', formName: this.formName, folder: this.formName };
            return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData] });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private hasChanges(newObj: Object[], oldObj: Object[], propertiesToIgnore?: string[]): boolean {
        if (!propertiesToIgnore) propertiesToIgnore = this.propertiesToIgnore;
        if (propertiesToIgnore) {
            const newAux = newObj ? angular.copy(newObj) : [];
            const oldAux = oldObj ? angular.copy(oldObj) : [];
            if (newAux && newAux.length) {
                for (const auxNew of newAux) {
                    for (const property of propertiesToIgnore) {
                        if (auxNew && angular.isDefined(auxNew[property])) delete auxNew[property];
                        if (oldAux[newAux.indexOf(auxNew)] && angular.isDefined(oldAux[newAux.indexOf(auxNew)][property])) delete oldAux[newAux.indexOf(auxNew)][property];
                    }
                }
            }

            return !angular.equals(JSON.stringify(newAux), JSON.stringify(oldAux));
        }
        return !angular.equals(JSON.stringify(newObj), JSON.stringify(oldObj));
    }

    private getChargesTableOptions(): ITableOptions {
        return {
            persistName: "freightRoutesCharges",
            pagination: false,
            search: true,
            advancedSearch: false,
            showSearchClearButton: true,
            clickToSelect: true,
            singleSelect: false,
            showLoading: true,
            showColumns: true,
            showColumnsSearch: true,
            showColumnsToggleAll: true,
            showFullscreen: true,
            showToggle: false,
            showMultiSort: true,
            showMultiSortButton: true,
            height: '100%',
            filterControl: false,
            fixedColumns: true,
            fixedNumber: 3,
            sortable: true,
            serverSort: false,
            crudButtons: {
                validityRoute: { fn: async () => { this.validityRouteCharge(); }, name: "validityRouteCharge" },
                add: { fn: async () => { this.addCharge(); }, name: "addCharge" },
                edit: { fn: async (index: number, displayIndex: number) => { this.editCharge(index, displayIndex); }, name: "editCharge" },
                copy: { fn: async (index: number, displayIndex: number) => { this.copyCharge(index); }, name: "copyCharge" },
                remove: { fn: async (index: number) => { this.removeCharge(index); }, name: "removeCharge" }
            },
            customToolbarButtons: [
                { fn: async () => { this.viewChargeLog() }, label: "", name: "log", class: "btn btn-xs btn-success", icon: "fa fa-history", title: "", disabled: this.$scope.operation == EOperation.VIEW }],
            viewTariffAirFreight: { fn: async () => { this.viewTariffFreightAir() }, label: "", name: "tariffFreightAir", class: "btn btn-xs", icon: "", title: "" },
            formatAddLevel: () => this.formService.getTranslate("GENERAL.ADD_LEVEL"),
            formatCancel: () => this.formService.getTranslate("GENERAL.CLOSE"),
            formatColumn: () => this.formService.getTranslate("GENERAL.COLUMNS"),
            formatDeleteLevel: () => this.formService.getTranslate("GENERAL.REMOVE_LEVEL"),
            formatMultipleSort: () => this.formService.getTranslate("GENERAL.MULTIPLE_SORT"),
            formatOrder: () => this.formService.getTranslate("GENERAL.ORDER"),
            formatSort: () => this.formService.getTranslate("GENERAL.SORT"),
            formatSortBy: () => this.formService.getTranslate("GENERAL.SORT_BY"),
            formatSortOrders: () => { return { asc: this.formService.getTranslate("GENERAL.ASCENDING_SORT"), desc: this.formService.getTranslate("GENERAL.DESCENDING_SORT") } },
            formatThenBy: () => this.formService.getTranslate("GENERAL.AND_BY"),
            headerStyle: (column: BootstrapTableColumn) => {
                let headerStyleObj = {};
                if (column.field.search("PAYMENT") >= 0) headerStyleObj = { css: { 'background': "#f2dede" } };
                else if (column.field.search("RECEIVING") >= 0) headerStyleObj = { css: { 'background': "#dff0d8" } };
                return headerStyleObj;
            },
            showColumnsRename: [
                { dataField: "CHARGE_NAME_EXHIBITION", title: this.formService.getTranslate("BASIC_DATA.CHARGE") },
                { dataField: "APPLICATION", title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS") },
                { dataField: "APPLICATION.APPLICATION_COMPLEMENT", title: this.formService.getTranslate("PRODUCT.BASIS_COMPLEMENT") },
                { dataField: "FREIGHT_ROUTES_PAYMENT.CURRENCY", title: this.formService.getTranslate("GENERAL.CURRENCY") },
                { dataField: "FREIGHT_ROUTES_PAYMENT.UNITARY", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY_C") },
                { dataField: "FREIGHT_ROUTES_PAYMENT.MIN", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM_C") },
                { dataField: "FREIGHT_ROUTES_RECEIVING.CURRENCY", title: this.formService.getTranslate("GENERAL.CURRENCY") },
                { dataField: "FREIGHT_ROUTES_RECEIVING.UNITARY", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY_V") },
                { dataField: "FREIGHT_ROUTES_RECEIVING.MIN", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM_V") },
                { dataField: "VALIDITY_START", title: this.formService.getTranslate("PRODUCT.VALIDITY_START") },
                { dataField: "VALIDITY_END", title: this.formService.getTranslate("PRODUCT.VALIDITY_END") },
            ],
            onAll: () => {
                if (!this.$scope.$$phase) {
                    const self: FreightRoutesRegisterController = this;
                    this.$scope.$apply(function() {
                        if (self.countCheckSelected() > 1) {
                            self.$scope.chargesTableOptions.crudButtons.edit.disabled = true;
                            self.$scope.chargesTableOptions.crudButtons.copy.disabled = true;
                            self.$scope.chargesTableOptions.crudButtons.remove.disabled = true;
                            self.$scope.chargesTableOptions.crudButtons.validityRoute.disabled = false;
                        } else if (self.countCheckSelected() == 1) {
                            self.$scope.chargesTableOptions.crudButtons.validityRoute.disabled = false;
                        } else {
                            self.$scope.chargesTableOptions.crudButtons.edit.disabled = false;
                            self.$scope.chargesTableOptions.crudButtons.copy.disabled = false;
                            self.$scope.chargesTableOptions.crudButtons.remove.disabled = false;
                            self.$scope.chargesTableOptions.crudButtons.validityRoute.disabled = true;
                        }
                    });
                }
                return true;
            },

            onPostBody: (chargeList: IFreightRoutesCharge[]) => {
                for (const index in chargeList) { chargeList[index].DISPLAY_INDEX = parseInt(index); };
                this.$scope.chargesListDisplay = chargeList;
                return true;
            },
            columns: [
                { field: 'selected', title: this.formService.getTranslate("GENERAL.UI_SELECT.SELECT"), checkbox: true, showSelectTitle: true, cellStyle: () => { return { css: { width: '80px' } } } },
                { field: 'CHARGE_NAME_EXHIBITION', title: this.formService.getTranslate("BASIC_DATA.CHARGE"), formatter: (data) => data ? `${data.CODE} - ${data.NAME}` : data, sortable: true, searchable: true },
                { field: 'APPLICATION', title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'APPLICATION.APPLICATION_COMPLEMENT', title: this.formService.getTranslate("PRODUCT.BASIS_COMPLEMENT"), formatter: (application: IApplicationList, charge: IFreightRoutesCharge) => this.getChargeApplicationComplementValue(application, charge), sortable: true, searchable: true },
                { field: 'FREIGHT_ROUTES_PAYMENT.CURRENCY', title: this.formService.getTranslate("GENERAL.CURRENCY"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'FREIGHT_ROUTES_PAYMENT.UNITARY', title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'FREIGHT_ROUTES_PAYMENT.MIN', title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'FREIGHT_ROUTES_RECEIVING.CURRENCY', title: this.formService.getTranslate("GENERAL.CURRENCY"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'FREIGHT_ROUTES_RECEIVING.UNITARY', title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'FREIGHT_ROUTES_RECEIVING.MIN', title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'VALIDITY_START', title: this.formService.getTranslate("PRODUCT.VALIDITY_START"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true },
                { field: 'VALIDITY_END', title: this.formService.getTranslate("PRODUCT.VALIDITY_END"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true },
            ],
        };
    }

    private applyChargeFilterTable() {
        if (this.$scope.model) {
            this.$scope.routeEquipmentList = [];
            let chargeList = this.$scope.model.CHARGE;
            this.updateRouteChargeList(chargeList);
            const loadReference = moment(moment(this.$scope.chargeTableControl.chargeFilter.LOAD_REFERENCE).format('YYYY-MM-DD'));
            if (loadReference.isValid()) {
                chargeList = chargeList.filter(charge => {
                    const start = moment(moment(charge.VALIDITY_START).format('YYYY-MM-DD'));
                    const end = moment(moment(charge.VALIDITY_END).format('YYYY-MM-DD'));
                    return (loadReference.isBetween(start, end) || loadReference.isSame(start) || loadReference.isSame(end));
                });
            }
            this.$scope.chargeTableControl.data = chargeList;
            this.$scope.chargesTableOptions.load(null, true);
        }
    }

    private checkDailyRouteVehicleType() {
        if (!this.$scope.dailyRouteModel || this.$scope.dailyRouteModel.length === 0) {
            return;
        }
        const originalVehicleTypeCount = this.getTotalVehicleTypeCount(this.$scope.dailyRouteModel);

        this.$scope.dailyRouteModel = this.$scope.dailyRouteModel
            .map(routeItem => this.filterVehicleTypes(routeItem))
            .filter(routeItem => routeItem !== null);

        const currentVehicleTypeCount = this.getTotalVehicleTypeCount(this.$scope.dailyRouteModel);
        if (currentVehicleTypeCount < originalVehicleTypeCount) {
            this.handleWarning(this.formService.getTranslate("PRODUCT.DAILY_ROUTE_VEHICLE_REMOVED"));
        }
    }

    private getTotalVehicleTypeCount(routeModel) {
        return routeModel.reduce((total, routeItem) => {
            return total + (routeItem.VEHICLE_TYPE?.length || 0);
        }, 0);
    }

    private filterVehicleTypes(routeItem) {
        if (routeItem.VEHICLE_TYPE && routeItem.VEHICLE_TYPE.length) {
            routeItem.VEHICLE_TYPE = routeItem.VEHICLE_TYPE.filter(equipment =>
                this.$scope.vehicleTypeList.some(routeEquipment => routeEquipment.ID === equipment.ID)
            );

            if (routeItem.VEHICLE_TYPE.length === 0) {
                return null;
            }
        }
        return routeItem;
    }

    private updateRouteChargeList(chargeList: IFreightRoutesCharge[]) {
        this.$scope.vehicleTypeList = [];
        this.$scope.vehicleTypeListWeightLimit = [];
        this.$scope.routeEquipmentList = [];
        if (chargeList && chargeList.length) {
            for (const charge of chargeList) {
                if (charge.EQUIPMENT && charge.EQUIPMENT.length > 0) {
                    for (const equip of charge.EQUIPMENT) {
                        if (!this.$scope.routeEquipmentList.some(item => item.ID == equip.ID)) this.$scope.routeEquipmentList.push(equip);
                    }
                }

                if (charge.VEHICLE_TYPE && charge.VEHICLE_TYPE.length > 0) {
                    for (const vehicle of charge.VEHICLE_TYPE) {
                        if (!this.$scope.vehicleTypeList.some(item => item.ID == vehicle.ID)) {
                            this.$scope.vehicleTypeList.push(vehicle);
                            this.$scope.vehicleTypeListWeightLimit.push(vehicle);
                        }
                    }
                }
            }
        }

        this.$scope.routeEquipmentList.sort((a, b) => parseInt(a.ID) - parseInt(b.ID));
        this.$scope.vehicleTypeList.sort((a, b) => parseInt(a.ID) - parseInt(b.ID));
        this.$scope.vehicleTypeListWeightLimit.sort((a, b) => parseInt(a.ID) - parseInt(b.ID));
    }

    private getChargeApplicationComplementValue(applicationComplement: IApplicationList, charge: IFreightRoutesCharge): string {
        let complementValue: string = "";
        if (applicationComplement.ID == EApplicationComplementId.EQUIPMENT) complementValue = this.$scope.getCONCAT(charge.EQUIPMENT, null, "CODE");
        else if (applicationComplement.ID == EApplicationComplementId.WEIGHT_RANGE) complementValue = this.$scope.getCONCAT(charge.WEIGHT_RANGE);
        else if (applicationComplement.ID == EApplicationComplementId.VEHICLE) complementValue = this.$scope.getCONCAT(charge.VEHICLE_TYPE);
        return complementValue;
    }

    private async addCharge(): Promise<void> {
        try {
            const charge: IFreightRoutesCharge = {
                _id: null,
                ID: null,
                ID_FREIGHT_ROUTES: null,
                DISPLAY_INDEX: null,
                EVENT: (this.$scope.model.EVENT) ? this.$scope.model.EVENT : null,
                ID_CHARGE_NAME_EXHIBITION: null,
                CHARGE_NAME_EXHIBITION: null,
                APPLICATION: null,
                EQUIPMENT: null,
                WEIGHT_RANGE: null,
                FLAT: null,
                VEHICLE_TYPE: null,
                FREIGHT_ROUTES_PAYMENT: {
                    ID_CURRENCY: null,
                    CURRENCY: null,
                    UNITARY: null,
                    MIN: null,
                    FREE_OF_CHARGE: this.$scope.model.FREIGHT_CONTRACT.TRANSACTION && this.$scope.model.FREIGHT_CONTRACT.TRANSACTION.ID == EPaymentNatureId.RECEIVING ? true : false,
                    HOLDER_TYPE: null,
                    ID_LEGAL_PERSON_HOLDER: null,
                    ID_PHYSICAL_PERSON_HOLDER: null,
                    LEGAL_PERSON_HOLDER: null,
                    PHYSICAL_PERSON_HOLDER: null,
                    WEIGHT_RANGE_CHARGE: null
                },
                FREIGHT_ROUTES_RECEIVING: {
                    ID_CURRENCY: null,
                    CURRENCY: null,
                    UNITARY: null,
                    MIN: null,
                    FREE_OF_CHARGE: false,
                    HOLDER_TYPE: null,
                    ID_LEGAL_PERSON_HOLDER: null,
                    ID_PHYSICAL_PERSON_HOLDER: null,
                    LEGAL_PERSON_HOLDER: null,
                    PHYSICAL_PERSON_HOLDER: null,
                    WEIGHT_RANGE_CHARGE: null
                },
                VALIDITY_START: this.$scope.model.DISPLAY_VALIDITY_START ? this.$scope.model.DISPLAY_VALIDITY_START : null,
                VALIDITY_END: this.$scope.model.DISPLAY_VALIDITY_END ? this.$scope.model.DISPLAY_VALIDITY_END : null,
                DOCUMENT_ERROR: null
            };

            if (!this.$scope.model.CHARGE) this.$scope.model.CHARGE = [];
            this.openChargeModal(charge);
            this.checkDateValidity(charge.VALIDITY_START, charge.VALIDITY_END, 'chargeValidityDate', charge);

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private editCharge(index: number, displayIndex: number): void {
        try {
            if (!index && index != 0) throw Error('index is null');

            let charge: IFreightRoutesCharge = null;
            charge = this.getRatesByTheCorrectIndex(index);
            if (!charge) throw Error('charge is null');

            this.openChargeModal(charge, this.$scope.model.CHARGE.indexOf(charge), charge.DISPLAY_INDEX);

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private copyCharge(index: number): void {
        try {
            if (!index && index != 0) throw Error('index is null');
            const charge: IFreightRoutesCharge = this.getRatesByTheCorrectIndex(index);
            if (!charge) throw Error('charge is null');
            const copyCharge = angular.copy(charge);
            copyCharge._id = null;
            copyCharge.ID = null;

            this.openChargeModal(copyCharge);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private validityRouteCharge(): void {
        try {

            const baseDataValidityEnd = this.$scope.model.DISPLAY_VALIDITY_END;
            for (const charge of this.$scope.model.CHARGE) {
                if (charge['selected']) {
                    const index = this.$scope.model.CHARGE.indexOf(charge)
                    this.$scope.model.CHARGE[index].VALIDITY_END = new Date(baseDataValidityEnd)
                }
            }

            this.$scope.chargesTableOptions.load(null, true);

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private countCheckSelected(): number {
        try {
            const charges = this.$scope.model.CHARGE;
            let counter = 0;

            if (charges && charges.length) {
                for (let i = 0; i < charges.length; i++) {
                    if (charges[i]['selected']) counter++;
                }
            }

            return counter;

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getRatesByTheCorrectIndex(index: number): IFreightRoutesCharge {

        let chargeResult: IFreightRoutesCharge = null;
        if (Object.is(this.$scope.model.CHARGE[index], this.$scope.chargeTableControl.data[index])) {
            chargeResult = this.$scope.model.CHARGE[index];
        } else {

            for (const fee of this.$scope.model.CHARGE) {
                if (Object.is(fee, this.$scope.chargeTableControl.data[index])) {
                    chargeResult = fee;
                }
            }
        }
        return chargeResult;
    }

    private async removeCharge(index: number): Promise<void> {
        try {
            if (!index && index !== 0) throw Error('index is null');
            const thatTranslated = this.formService.getTranslate("GENERAL.GENDER.THAT", null, true);
            const chargeTranslated = this.formService.getTranslate("BASIC_DATA.CHARGE", null, true);
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thatTranslated, prop: chargeTranslated })
            });

            if (!modal) return;

            this.formService.block();

            if (this.$scope.model.CHARGE) {
                const charge = this.getRatesByTheCorrectIndex(index);
                this.$scope.model.CHARGE.splice(this.$scope.model.CHARGE.indexOf(charge), 1);
                this.$scope.chargeTableControl.data = this.$scope.model.CHARGE;
                this.$scope.chargesTableOptions.load(null, true);
            }

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async openChargeModal(charge: IFreightRoutesCharge, index?: number, displayIndex?: number) {
        try {
            let errorMessage: string = '';
            if (!this.$scope.model.FREIGHT_CONTRACT.TRANSACTION) errorMessage = 'payment nature';
            if (!this.$scope.model.FREIGHT_CONTRACT.CARGO_TYPE) errorMessage += (errorMessage ? ', ' : '') + 'type cargo';
            if (!this.$scope.model.FREIGHT_CONTRACT.TRANSACTION) errorMessage += (errorMessage ? ', ' : '') + 'product';
            if (errorMessage) throw Error(`Select ${errorMessage} first`);

            this.modalChargeId = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    modalID: this.modalChargeId,
                    formService: this.$scope.operation,
                    template: require("../view/modals/freightRoutesChargeModal.html"),
                    size: 'vlg',
                    keyboard: true,
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing") {
                            if (reason.toString() == "escape key press") event.preventDefault();
                        }
                    }
                },
                null,
                {
                    freightContract: this.$scope.model.FREIGHT_CONTRACT,
                    freightRoute: this.$scope.model,
                    charges: this.$scope.model.CHARGE,
                    currentDisplayIndex: displayIndex,
                    currentIndex: index,
                    charge: charge,
                }
            );

            const resultModalInstance = await modalInstance.result.then((result) => result.$value, (result) => result.$value);

            this.$scope.chargeTableControl.data = this.$scope.model.CHARGE;
            this.$scope.chargesTableOptions.load(null, true);

            if (resultModalInstance) {
                const operation: EOperation = resultModalInstance.operation;

                switch (operation) {
                    case EOperation.NEW:
                    case EOperation.EDIT:
                        this.applyChargeFilterTable();
                        this.checkDailyRouteVehicleType();
                        break;
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async customInsert(): Promise<boolean> {
        let success = true;
        try {
            this.block();
            const request = await this.ProductService.post({ route: '/freightRoutes/insert', data: { data: this.$scope.model, oldData: this.$scope.oldModel, dailyRouteData: this.$scope.dailyRouteModel, timeout: 30000 } });
            if (request && request.data && request.data.data) {
                success = request.data.data;
                if (success) this.finishUpdateControl();
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return success;
        }
    }

    private async customSave(confirmationToUpdate?: ITariffChangeConfirmation): Promise<boolean> {
        let success = true;
        try {
            this.block();
            const operation = this.$scope.operation == EOperation.EDIT ? 'update' : 'insert';
            const request = await this.ProductService.post({ route: `/freightRoutes/${operation}`, data: { data: this.$scope.model, oldData: this.$scope.oldModel, dailyRouteData: this.$scope.dailyRouteModel, oldDailyRouteModel: this.$scope.oldDailyRouteModel, confirmation: confirmationToUpdate, timeout: 30000 } });
            if (request && request.data && request.data.data) {
                const confirmation: boolean = request.data.data.confirmation;
                if (!confirmation) {
                    success = false;
                    if (!confirmationToUpdate) {
                        this.unblock();
                        const confirmationData: ITariffChangeConfirmation = request.data.data.data;
                        this.openReasonUpdateModal(confirmationData);
                    }
                }
                if (success) this.finishUpdateControl();
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return success;
        }
    }

    private async finishUpdateControl() {
        delete this.$scope['newRegister'];
        await this.updateGrid();
        this.$scope.showForm = false;
        this.$scope.model = null;
        this.$scope.error = null;
        this.$scope.operation = null;
        this.$scope.$applyAsync();
        this.$formService.notifySuccess(this.formService.getTranslate("REGISTRATION.SUCCESSFUL_REGISTER"));
    }

    private async openReasonUpdateModal(confirmationData: ITariffChangeConfirmation) {
        try {
            if (!this.modalReasonUpdateId) this.modalReasonUpdateId = this.ModalService.newModal();
            this.$scope.reasonUpdateModal = { DESCRIPTION: confirmationData.DESCRIPTION, REASON_VALUE_CHANGE: null, REASON_VALIDITY_CHANGE: null, REASON_OBSERVATION: null };

            const body = `
            <div id="reasonModal">
                <div class="row form-group">
                    <div ng-if="${confirmationData.HAS_VALUE_CHANGE}" ng-class="${confirmationData.HAS_VALIDITY_CHANGE} ? 'col-lg-6' : 'col-lg-12'">
                        <label>{{'PRODUCT.REASON_VALUE_CHANGE' | translate}} <i class="fa fa-asterisk text-danger small-fontawesome" aria-hidden="true"
                        tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.MANDATORY_FIELD' | translate }}"
                        tooltip-append-to-body="true"></i></label>
                        <ui-select name="reasonValueChange" id="reasonValueChange"
                            ng-model="reasonUpdateModal.REASON_VALUE_CHANGE"
                            theme="bootstrap"
                            ng-change="selectorValidity(this.$select.ngModel.$name);"
                            ng-disabled="selectorDisabled(this.$select.ngModel.$name) || operation == 'view'"
                            ng-click="selectorFocus(this.$select.searchInput[0]);"
                            skip-focusser="true" required>
                            <ui-select-match placeholder="{{'GENERAL.UI_SELECT.SELECT' | translate }}">
                                {{$select.selected.NAME}}
                            </ui-select-match>
                            <ui-select-choices
                                repeat="item in reasonValueChangeList | filter: $select.search track by $index">
                                <div
                                    ng-bind-html="item.NAME | highlight: $select.search">
                                </div>
                            </ui-select-choices>
                            <ui-select-no-choice>
                                {{'GENERAL.UI_SELECT.EMPTY_SELECTOR_MESSAGE' | translate }}
                            </ui-select-no-choice>
                        </ui-select>
                    </div>
                    <div ng-if="${confirmationData.HAS_VALIDITY_CHANGE}" ng-class="${confirmationData.HAS_VALUE_CHANGE} ? 'col-lg-6' : 'col-lg-12'">
                        <label>{{'PRODUCT.REASON_VALIDITY_CHANGE' | translate}} <i class="fa fa-asterisk text-danger small-fontawesome" aria-hidden="true"
                        tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.MANDATORY_FIELD' | translate }}"
                        tooltip-append-to-body="true"></i></label>
                        <ui-select name="reasonValidityChange" id="reasonValidityChange"
                            ng-model="reasonUpdateModal.REASON_VALIDITY_CHANGE"
                            theme="bootstrap"
                            ng-change="selectorValidity(this.$select.ngModel.$name);"
                            ng-disabled="selectorDisabled(this.$select.ngModel.$name) || operation == 'view'"
                            ng-click="selectorFocus(this.$select.searchInput[0]);"
                            skip-focusser="true" required>
                            <ui-select-match placeholder="{{'GENERAL.UI_SELECT.SELECT' | translate }}">
                                {{$select.selected.NAME}}
                            </ui-select-match>
                            <ui-select-choices
                                repeat="item in reasonValidityChangeList | filter: $select.search track by $index">
                                <div
                                    ng-bind-html="item.NAME | highlight: $select.search">
                                </div>
                            </ui-select-choices>
                            <ui-select-no-choice>
                                {{'GENERAL.UI_SELECT.EMPTY_SELECTOR_MESSAGE' | translate }}
                            </ui-select-no-choice>
                        </ui-select>
                    </div>
                </div>
                <div class="row form-group" ng-if="reasonUpdateModal.REASON_VALUE_CHANGE.ID == '3' || reasonUpdateModal.REASON_VALIDITY_CHANGE.ID == '2'">
                    <div class="col-lg-12">
                        <label>{{'GENERAL.REMARKS' | translate}} <i class="fa fa-asterisk text-danger small-fontawesome" aria-hidden="true"
                        tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.MANDATORY_FIELD' | translate }}"
                        tooltip-append-to-body="true"></i></label>
                        <textarea class="form-control" name="reasonObservation" ng-model="reasonUpdateModal.REASON_OBSERVATION"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 space-list">
                        <ul class="list-group m-b-none">
                            <li class="list-group-item list-group-item-warning ellipsize full-width"
                                ng-repeat="description in reasonUpdateModal.DESCRIPTION track by $index"
                                style="max-width: 100%;" ellipsis-tooltip tooltip-placement="auto top"
                                uib-tooltip-html="description"
                                tooltip-enable="true" tooltip-append-to-body="true">
                                <span ng-bind-html="description"></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            `;

            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    modalID: this.modalReasonUpdateId,
                    scope: this.$scope,
                    formService: 'register',
                    size: 'lg modal-overflow',
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing" && closed) {
                            const modalData: IReasonUpdateModal = this.$scope.reasonUpdateModal;
                            const hasOthers = modalData && ((modalData.REASON_VALUE_CHANGE && modalData.REASON_VALUE_CHANGE.ID == ETariffChangeConfirmationReasonValue.OTHERS) || (modalData.REASON_VALIDITY_CHANGE && modalData.REASON_VALIDITY_CHANGE.ID == ETariffChangeConfirmationReasonValidity.OTHERS));
                            if (!modalData || (modalData && (confirmationData.HAS_VALUE_CHANGE && !modalData.REASON_VALUE_CHANGE)) || (confirmationData.HAS_VALIDITY_CHANGE && !modalData.REASON_VALIDITY_CHANGE) || (hasOthers && !modalData.REASON_OBSERVATION)) {
                                event.preventDefault();
                                const msgError = this.formService.getTranslate('GENERAL.ALL_FIELDS_MANDATORY');
                                this.formService.notifyError(msgError);
                            } else if (!await this.customSave({ _id: null, ID: null, ID_FREIGHT_ROUTES: this.$scope.model.ID, ID_TARIFF_LOCAL: null, UPDATED_BY: null, UPDATED_AT: null, CREATED_BY: null, CREATED_AT: null, REASON_VALUE_CHANGE: modalData.REASON_VALUE_CHANGE, REASON_VALIDITY_CHANGE: modalData.REASON_VALIDITY_CHANGE, OBSERVATION: modalData.REASON_OBSERVATION, DESCRIPTION: modalData.DESCRIPTION, HAS_VALUE_CHANGE: confirmationData.HAS_VALUE_CHANGE, HAS_VALIDITY_CHANGE: confirmationData.HAS_VALIDITY_CHANGE, ID_INLAND_ROUTE: null, ID_TARIFF_COMPLEMENTARY: null })) event.preventDefault();
                        }
                    }
                },
                {
                    closeButtonText: 'GENERAL.CANCEL',
                    actionButtonText: 'REGISTRATION.APPLY',
                    headerText: 'PRODUCT.TARIFF_CHANGE_CONFIRMATION',
                    bodyText: this.$sce.trustAsHtml(body),

                }
            );

            modalInstance.rendered.then(() => {
                const reasonModal = angular.element("#reasonModal");
                if (reasonModal) this.$compile(reasonModal)(this.$scope);
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async viewChargeLog(): Promise<void> {
        try {
            this.formService.block();

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/freightRoutes/tabs/charge/viewLog/${this.$scope.model.ID.toString()}/CHARGES`, 30000, null, false);
            const log: IViewLog = {
                operation: 'history',
                number: this.$scope.model.ID.toString(),
                list: [],
                show: true,
                showCloseButton: false,
                searchQuery: '',
                originalList: [],
            }
            log.list = request.data;
            log.originalList = angular.copy(log.list);

            const modalId = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    modalID: modalId,
                    template: require('../../common/view/modals/viewLog.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG"
                }
            );
            modalInstance.opened.then(async () => {
                const modalScope = await this.ModalService.getModalScope(modalId);
                modalScope.customLogProperties = this.$scope.customLogProperties;
                modalScope.log = log;
                modalScope.$applyAsync();
            });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async viewLogConfirmation(): Promise<void> {
        try {
            this.formService.block();
            const retrieveLog = await this.ProductService.get({ route: `/tariffChangeConfirmation/tabs/charge/getTariffChangeConfirmation/freightRoutes/${this.$scope.model.ID}` });
            this.formService.unblock();
            if (retrieveLog && retrieveLog.data && retrieveLog.data.data && retrieveLog.data.data.length) {
                this.$scope.tariffChangeConfirmation = retrieveLog.data.data;
                const modalId = this.ModalService.newModal();
                await this.ModalService.showModalConfirmation(
                    {
                        modalID: modalId,
                        scope: this.$scope,
                        template: require('../view/modals/freightRoutesChangeConfirmationLogModal.html'),
                        size: 'vlg modal-overflow'
                    },
                    {
                        closeButtonText: 'GENERAL.CLOSE'
                    }
                );
            } else this.formService.notifyInfo(this.formService.getTranslate("REGISTRATION.NO_RECORD_FOUND"));
        } catch (ex) {
            this.formService.handleError(ex);
            this.formService.unblock();
        }
    }

    private async viewTariffFreightAir(): Promise<void> {
        try {
            this.formService.block();

            const pup = this.$scope.model && this.$scope.model.PUP && this.$scope.model.PUP.length ? this.getCONCAT(this.$scope.model.PUP, null, 'DISPLAY_NAME').toString() : null;
            const otfs = this.$scope.model && this.$scope.model.OTFS && this.$scope.model.OTFS.length ? this.getCONCAT(this.$scope.model.OTFS, null, 'DISPLAY_NAME').toString() : null;
            const polaol = this.$scope.model && this.$scope.model.POL_AOL && this.$scope.model.POL_AOL.length ? this.getCONCAT(this.$scope.model.POL_AOL, null, 'CODE').toString() : null;
            const podaod = this.$scope.model && this.$scope.model.POD_AOD && this.$scope.model.POD_AOD.length ? this.getCONCAT(this.$scope.model.POD_AOD, null, 'CODE').toString() : null;
            const dtfs = this.$scope.model && this.$scope.model.DTFS && this.$scope.model.DTFS.length ? this.getCONCAT(this.$scope.model.DTFS, null, 'DISPLAY_NAME').toString() : null;
            const pld = this.$scope.model && this.$scope.model.PLD && this.$scope.model.PLD.length ? this.getCONCAT(this.$scope.model.PLD, null, 'DISPLAY_NAME').toString() : null;
            const productId = this.$scope.model && this.$scope.model.FREIGHT_CONTRACT && this.$scope.model.FREIGHT_CONTRACT.PRODUCT && this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID ? this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID : null;

            this.$scope.sessionService.openTab("app.product.newTariffFreightAir", <ITariffFreightAirParameter>{ "PUP.ROUTING_POINT.DISPLAY_NAME": pup, "OTFS.ROUTING_POINT.DISPLAY_NAME": otfs, "POLAOL.ROUTING_POINT.CODE": polaol, "PODAOD.ROUTING_POINT.CODE": podaod, "DTFS.ROUTING_POINT.DISPLAY_NAME": dtfs, "PLD.ROUTING_POINT.DISPLAY_NAME": pld, "PRODUCT.ID": productId, "ACTIVE": "Sim" });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private checkProductId(): boolean {
        if (this.$scope.model && this.$scope.model.FREIGHT_CONTRACT && this.$scope.model.FREIGHT_CONTRACT.PRODUCT && this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID && (this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID === EProductId.AIR_EXPORT || this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID === EProductId.AIR_IMPORT)) return true;
        else return false;
    }

    private updateMoveType(selected: SelectorModel): void {
        try {
            if (!selected) throw Error('selected is null');

            if (!this.$scope.model.FREIGHT_CONTRACT.PRODUCT) {
                this.$scope.model.MOVE_TYPE = null;
                throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
            }

            const moveType = this.$scope.moveTypeFullList.find(moveType => moveType.ID == parseInt(selected.ID));
            if (moveType && moveType.PARAMS) {
                this.$scope.model.DYNAMIC_FIELDS = this.buildDynamicFields(moveType.PARAMS);
            }

            if (!this.$scope.model.DYNAMIC_FIELDS[0].ENABLED) {
                this.$scope.model.PUP = null;
                this.$scope.model.MOT_FROM_PUP = null;
            }

            if (!this.$scope.model.DYNAMIC_FIELDS[1].ENABLED) {
                this.$scope.model.OTFS = null;
                this.$scope.model.MOT_FROM_OTFS = null;
            }

            if (!this.$scope.model.DYNAMIC_FIELDS[2].ENABLED) {
                this.$scope.model.POL_AOL = null;
                this.$scope.model.MOT = null;
            }

            if (!this.$scope.model.DYNAMIC_FIELDS[3].ENABLED) this.$scope.model.POD_AOD = null;

            if (!this.$scope.model.DYNAMIC_FIELDS[4].ENABLED) {
                this.$scope.model.DTFS = null;
                this.$scope.model.MOT_FROM_DTFS = null;
            }

            if (!this.$scope.model.DYNAMIC_FIELDS[5].ENABLED) {
                this.$scope.model.PLD = null;
                this.$scope.model.MOT_FROM_PLD = null;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private buildDynamicFields(params?: IParam[]): IDynamicField[] {
        let dynamicFields: IDynamicField[] = [];
        try {
            const originParam1 = params ? params.find(param => param.DIRECTION.ID == EDirectionId.ORIGIN && param.ORDER == 1 && (!this.$scope.model.FREIGHT_CONTRACT.PRODUCT || !param.PRODUCT || param.PRODUCT.some(product => product.ID == this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID))) : null;
            const originParam2 = params ? params.find(param => param.DIRECTION.ID == EDirectionId.ORIGIN && param.ORDER == 2 && (!this.$scope.model.FREIGHT_CONTRACT.PRODUCT || !param.PRODUCT || param.PRODUCT.some(product => product.ID == this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID))) : null;
            const originParam3 = params ? params.find(param => param.DIRECTION.ID == EDirectionId.ORIGIN && param.ORDER == 3 && (!this.$scope.model.FREIGHT_CONTRACT.PRODUCT || !param.PRODUCT || param.PRODUCT.some(product => product.ID == this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID))) : null;
            const destinationParam1 = params ? params.find(param => param.DIRECTION.ID == EDirectionId.DESTINATION && param.ORDER == 1 && (!this.$scope.model.FREIGHT_CONTRACT.PRODUCT || !param.PRODUCT || param.PRODUCT.some(product => product.ID == this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID))) : null;
            const destinationParam2 = params ? params.find(param => param.DIRECTION.ID == EDirectionId.DESTINATION && param.ORDER == 2 && (!this.$scope.model.FREIGHT_CONTRACT.PRODUCT || !param.PRODUCT || param.PRODUCT.some(product => product.ID == this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID))) : null;
            const destinationParam3 = params ? params.find(param => param.DIRECTION.ID == EDirectionId.DESTINATION && param.ORDER == 3 && (!this.$scope.model.FREIGHT_CONTRACT.PRODUCT || !param.PRODUCT || param.PRODUCT.some(product => product.ID == this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID))) : null;
            const originField1: IDynamicField = this.buildDynamicField(originParam1);
            const originField2: IDynamicField = this.buildDynamicField(originParam2);
            const originField3: IDynamicField = this.buildDynamicField(originParam3);
            const destinationField1: IDynamicField = this.buildDynamicField(destinationParam1);
            const destinationField2: IDynamicField = this.buildDynamicField(destinationParam2);
            const destinationField3: IDynamicField = this.buildDynamicField(destinationParam3);
            dynamicFields.push(originField1);
            dynamicFields.push(originField2);
            dynamicFields.push(originField3);
            dynamicFields.push(destinationField1);
            dynamicFields.push(destinationField2);
            dynamicFields.push(destinationField3);
        } catch (ex) {
            this.formService.handleError(ex);
        }
        return dynamicFields;
    }

    private buildDynamicField(param: IParam): IDynamicField {
        const dynamicField: IDynamicField = { ENABLED: false, PLACEHOLDER: "GENERAL.FIELD_WITHOUT_REQUIRED_VALUE", LABEL: "N/A", INITIALS: "", MANDATORY: false };
        if (param) {
            dynamicField.PLACEHOLDER = "GENERAL.UI_SELECT.TYPE_TEXT";
            dynamicField.ENABLED = !param.TYPE_RULE || (param.TYPE_RULE && param.TYPE_RULE.ID != "3");
            if (param.NAME && param.NAME.length > 0) dynamicField.LABEL = param.NAME;
            if (param.CODE) dynamicField.INITIALS = param.CODE;
            if (param.TYPE_RULE && param.TYPE_RULE.ID == "1") dynamicField.MANDATORY = true;
        }
        return dynamicField;
    }

    private async getMoveTypeByProduct(): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (!this.$scope.model.FREIGHT_CONTRACT.PRODUCT) { throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST")) }
            this.formService.block();

            const products = this.$scope.model.FREIGHT_CONTRACT.PRODUCT ? [this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID] : [];
            const moveTypeList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/moveType/list/custom`, { products }, 30000, false);
            result = moveTypeList ? moveTypeList.map(x => { return { ID: x.ID.toString(), NAME: x.NAME, CODE: x.CODE } }) : [];
            result.sort(function (a, b) { return (a.NAME > b.NAME) ? 1 : ((b.NAME > a.NAME) ? -1 : 0) });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.moveTypeList = result;
        }
    }

    private async editFreightRoutes(freightRoute: IFreightRoutesModel): Promise<void> {
        let blockedObject = {
            ID: freightRoute.ID,
            NAME: freightRoute.CONCATENATED,
            EMAIL: this.$scope.user['email'],
            FORM_NAME: this.gridName
        };
        this.SSEService.closeEvents();
        this.SSEService.setBlockedObject(blockedObject);
        this.SSEService.initEvents();
        this.SSEService.events.onmessage = async (event) => {
            const parsedData = JSON.parse(event.data);
            if (!parsedData.status) {
                const result = await this.SSEService.generate(parsedData);
                if (result && !result.status) {
                    this.$rootScope.refreshPage();
                    return;
                }
                if (this.$scope.operation !== EOperation.VIEW || freightRoute.ID !== this.$scope.model.ID) this.$scope.viewFreightRoutes(freightRoute);
            } else if (this.$scope.operation !== EOperation.EDIT || freightRoute.ID !== this.$scope.model.ID) {
                await this.$scope.fetchData(freightRoute.ID, EOperation.EDIT);
            }
        };
    }

    private async callSessionFunctions(data: object): Promise<void> {
        if (!data) return;

        const freightContractExchangeData = <ILinkFreightRoutesParameter>data;

        switch (freightContractExchangeData.OPERATION) {
            case EOperation.NEW: {
                if (!freightContractExchangeData.ID) this.$rootScope.breadCrumb.action();
                else await this.getFreightContract(freightContractExchangeData.ID, freightContractExchangeData.ALLOG_FREIGHT_CONTRACT);
                break;
            }
        }
    }

    private async getFreightContract(idFreightContract: string, allogFreightContractName: string) {
        try {
            if (!idFreightContract) throw new Error("idFreightContract is null");
            if (!allogFreightContractName) throw new Error("allogFreightContract is null");

            await this.$scope.register();
            this.$scope.model.ALLOG_FREIGHT_CONTRACT = { ID: idFreightContract, NAME: allogFreightContractName };
            await this.getFreightContractById();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private checkRulesForDetDem() {
        let equipOnDetDemOrigin: ISelectorModel[] = [];
        let equipOnDetDemDestination: ISelectorModel[] = [];

        const detDemOrigin = this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID == EProductId.MARITIME_EXPORT && this.$scope.model.FREIGHT_CONTRACT.CARGO_TYPE.ID == ECargoTypeId.FCL && this.$scope.model.FREIGHT_CONTRACT.TRANSACTION.ID != EPaymentNatureId.RECEIVING
        const detDemdDestination = this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID == EProductId.MARITIME_IMPORT && this.$scope.model.FREIGHT_CONTRACT.CARGO_TYPE.ID == ECargoTypeId.FCL && this.$scope.model.FREIGHT_CONTRACT.TRANSACTION.ID != EPaymentNatureId.RECEIVING

        if (this.$scope.model.FREIGHT_CONTRACT.DET_DEM_ORIGIN && this.$scope.model.FREIGHT_CONTRACT.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME && this.$scope.model.FREIGHT_CONTRACT.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME.length > 0) {
            this.$scope.model.FREIGHT_CONTRACT.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME.forEach(equipFreeTime => {
                if (equipFreeTime && equipFreeTime.EQUIPMENT.length > 0) {
                    equipFreeTime.EQUIPMENT.forEach(equip => {
                        const equipItem: ISelectorModel = {
                            ID: equip.ID,
                            NAME: equip.NAME,
                        };
                        equipOnDetDemOrigin.push(equipItem);
                    });
                }
            });
        }

        if (this.$scope.model.FREIGHT_CONTRACT.DET_DEM_DESTINATION && this.$scope.model.FREIGHT_CONTRACT.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME && this.$scope.model.FREIGHT_CONTRACT.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME.length > 0) {
            this.$scope.model.FREIGHT_CONTRACT.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME.forEach(equipFreeTime => {
                if (equipFreeTime && equipFreeTime.EQUIPMENT.length > 0) {
                    equipFreeTime.EQUIPMENT.forEach(equip => {
                        const equipItem: ISelectorModel = {
                            ID: equip.ID,
                            NAME: equip.NAME,
                        };
                        equipOnDetDemDestination.push(equipItem);
                    });
                }
            });
        }

        if (this.$scope.model && this.$scope.model.DET_DEM_ORIGIN && this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME && this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME.length > 0) {
            this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME.forEach(equipFreeTime => {
                if (equipFreeTime && equipFreeTime.EQUIPMENT.length > 0) {
                    equipFreeTime.EQUIPMENT.forEach(equip => {
                        const foundEquip = equipOnDetDemOrigin.some(x => x && x.ID == equip.ID);
                        if (!foundEquip && detDemOrigin) {
                            const msg = `Equipment ${equip.NAME} not found in D&D origin at freight contract. Missing freight contract: ${this.$scope.model.ALLOG_FREIGHT_CONTRACT.NAME}`;
                            this.formService.notifyError(msg);
                        }
                    });
                }
            });
        }

        if (this.$scope.model && this.$scope.model.DET_DEM_DESTINATION && this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME && this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME.length > 0) {
            this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME.forEach(equipFreeTime => {
                if (equipFreeTime && equipFreeTime.EQUIPMENT.length > 0) {
                    equipFreeTime.EQUIPMENT.forEach(equip => {
                        const foundEquip = equipOnDetDemDestination.some(x => x && x.ID == equip.ID);
                        if (!foundEquip && detDemdDestination) {
                            const msg = `Equipment ${equip.NAME} not found in D&D destination at freight contract. Missing freight contract: ${this.$scope.model.ALLOG_FREIGHT_CONTRACT.NAME}`;
                            this.formService.notifyError(msg);
                        }
                    });
                }
            });
        }
    }

    private addDailyRoute(): void {
        if (!this.$scope.dailyRouteModel) this.$scope.dailyRouteModel = [];

        const dailyRouteItem: any = {
            _id: null,
            ID: null,
            ID_FREIGHT_ROUTES: this.$scope.model.ID,
            VEHICLE_TYPE: [],
            FREE_TIME_PUP_MASTER: null,
            FREE_TIME_PUP_HOUSE: null,
            ORIGIN_FREE_TIME_CUSTOMS_CLEARANCE_MASTER: null,
            ORIGIN_FREE_TIME_CUSTOMS_CLEARANCE_HOUSE: null,
            ORIGIN_FREE_TIME_BORDER_CROSS_MASTER: null,
            ORIGIN_FREE_TIME_BORDER_CROSS_HOUSE: null,
            DESTINATION_FREE_TIME_CUSTOMS_CLEARANCE_MASTER: null,
            DESTINATION_FREE_TIME_CUSTOMS_CLEARANCE_HOUSE: null,
            DESTINATION_FREE_TIME_BORDER_CROSS_MASTER: null,
            DESTINATION_FREE_TIME_BORDER_CROSS_HOUSE: null,
            FREE_TIME_DELIVERY_MASTER: null,
            FREE_TIME_DELIVERY_HOUSE: null
        };

        this.$scope.dailyRouteModel.push(dailyRouteItem);

        this.applyChargeFilterTable();
        this.adjustVehicleTypeList();
    }

    private async removeDailyRoute(index: number): Promise<void> {

        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: `${this.formService.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")}`
            });
            if (!modal) return;

            if (this.$scope.dailyRouteModel && this.$scope.dailyRouteModel.length > 0) {
                this.formService.block();
                this.$scope.dailyRouteModel.splice(index, 1);
            }
            this.applyChargeFilterTable();
            this.adjustVehicleTypeList();
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private adjustVehicleTypeList() {
        this.applyChargeFilterTable();
        if (this.$scope.dailyRouteModel && this.$scope.dailyRouteModel.length > 0) {
            const filteredList: ISelectorModel[] = [];

            for (const vehicleType of this.$scope.vehicleTypeList) {
                let isTypeUsed = false;

                for (const dailyRouteModel of this.$scope.dailyRouteModel) {
                    if (dailyRouteModel && dailyRouteModel.VEHICLE_TYPE && dailyRouteModel.VEHICLE_TYPE.length > 0 && dailyRouteModel.VEHICLE_TYPE.find(x => x.ID === vehicleType.ID)) {
                        isTypeUsed = true;
                        break;
                    }
                }

                if (!isTypeUsed) {
                    filteredList.push(vehicleType);
                }
            }
            this.$scope.vehicleTypeList = filteredList;
        }
    }

    private requestHistory(id: string): Promise<any> {
        return this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/freightRoutes/viewDailyRateLog/${id}`, 10000, null, false);
    }

    private async viewLogDailyRate() {

        try {
            this.formService.block();
            let log: IViewLog = {
                operation: 'history',
                number: this.$scope.model.ID.toString(),
                list: [],
                show: true,
                showCloseButton: false,
                searchQuery: '',
                originalList: [],
            }

            this.requestHistory(log.number).then(result => {
                log.list = result.data;
                log.originalList = angular.copy(log.list);
                this.$scope.log = log;
                this.$scope.customLogProperties = this.getCustomLogProperties();
                angular.element('#log-viewer').removeClass('ng-hide');
                this.formService.unblock();
            }).catch(ex => {
                this.formService.handleError(ex);
            });

            const modalId = this.ModalService.newModal();
            await this.ModalService.showModalConfirmation(
                {
                    modalID: modalId,
                    scope: this.$scope,
                    template: require('../../common/view/modals/viewLog.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG"
                }
            );
            this.ModalService.closeModal(modalId);
        } catch (ex) {
            this.formService.handleError(ex);
        }

    }

    private async getFreightRouteDailyRate(): Promise<void> {
        this.formService.block();
        try {
            if (this.$scope.isLandProduct) {
                const timeout: number = 120000;
                const request: IMonacoRequest = {
                    data: null,
                    route: `/freightRoutes/getDailyRate/${this.$scope.model.ID}`,
                    timeout
                };
                const rc = await this.ProductService.get(request);
                const dailyRouteData: any[] = (rc && rc.data && rc.data.data) ? rc.data.data : null;
                if (dailyRouteData) {
                    this.$scope.dailyRouteModel = dailyRouteData;
                    this.$scope.oldDailyRouteModel = angular.copy(dailyRouteData);
                }
            }

            const collapseDailyRoute = angular.element('#collapseDailyRoute');
            if (collapseDailyRoute) {
                collapseDailyRoute["collapse"]('show');
            }

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

   

    private clearRoutingPoint() {
        this.$scope.model.MOT = null;
        this.$scope.model.MOT_FROM_PUP = null;
        this.$scope.model.MOT_FROM_DTFS = null;
        this.$scope.model.MOT_FROM_PLD = null;
        this.$scope.model.MOT_FROM_OTFS = null;
        this.$scope.model.PUP = null;
        this.$scope.model.OTFS = null;
        this.$scope.model.POL_AOL = null;
        this.$scope.model.DTFS = null;
        this.$scope.model.PLD = null;
        this.$scope.model.POD_AOD = null;

        if (this.$scope.model.FREIGHT_CONTRACT) {
            const isRoad = [
                EProductId.ROAD_EXPORT.toString(),
                EProductId.ROAD_IMPORT.toString(),
                EProductId.ROAD_NATIONAL.toString()
            ].includes(this.$scope.model.FREIGHT_CONTRACT.PRODUCT.ID);

            if (!isRoad) this.getDefaultProcessEventTransportMode();
        }
    }

    private updateMask(field: string, index) {
        $(`#${field}_${index}`)['mask']('99:99');
    }

    private addEquipmentOrVehicle(): void {
        const equipmentWeightRangeVehicle: IEquipmentWeightRangeVehicle = {
            EQUIPMENT: null,
            VEHICLE_TYPE: null,
            WEIGHT_LIMIT: null,
            CONCATENATED: null,
        }

        this.$scope.model.EQUIPMENT_WEIGHT_RANGE_VEHICLE.push(equipmentWeightRangeVehicle);
    }

    private async removeEquipmentOrVehicle(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: this.formService.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")
            });
            if (!modal) return;

            if (this.$scope.model.EQUIPMENT_WEIGHT_RANGE_VEHICLE && this.$scope.model.EQUIPMENT_WEIGHT_RANGE_VEHICLE.length) {
                this.block();
                this.$scope.model.EQUIPMENT_WEIGHT_RANGE_VEHICLE.splice(index, 1);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }
}
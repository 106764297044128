import { SLADeadlineRegisterController } from './controller/SLADeadlineRegisterController';
import * as angular from 'angular';

// https://github.com/summernote/summernote/
import 'summernote';
import 'summernote/dist/summernote.js';
import 'summernote/dist/summernote.css';
// https://github.com/summernote/angular-summernote
import 'angular-summernote/dist/angular-summernote.js';

import "../../css/fab.css";
import 'ng-img-crop/compile/minified/ng-img-crop.css';
import 'ng-img-crop/compile/minified/ng-img-crop.js';

import initRoute from './RouteConfig';
import { GRID_FORM_MODULE } from '../gridForm/GridFormModule';
import { GRID_EXCEL_MODULE } from '../gridExcel/GridExcelModule';

import { RegistrationMenuController } from './controller/RegistrationMenuController'
import { EquipmentRegisterController } from './controller/EquipmentRegisterController';
import { TradeLaneRegisterController } from './controller/TradeLaneRegisterController';
import { ReportTemplateRegisterController } from './controller/ReportTemplateRegisterController';
import { ReportAutomaticRulesRegisterController } from './controller/ReportAutomaticRulesRegisterController';
import { UserRegisterController } from './controller/UserRegisterController'
import { EventRegisterController } from './controller/EventRegisterController'
import { SpecRegisterController } from './controller/SpecRegisterController';
import { RoleRegisterController } from './controller/RoleRegisterController';
import { CellRegisterController } from './controller/CellRegisterController';
import { UserSelectController } from './controller/UserSelectController';
import { FollowUpModelRegisterController } from './controller/FollowUpModelRegisterController';
import { FollowUpNotificationRegisterController } from './controller/FollowUpNotificationRegisterController';
import { FollowUpParamsRegisterController } from './controller/FollowUpParamsRegisterController';
import { NotificationTypeRegisterController } from './controller/NotificationTypeRegisterController';
import { removeCellUsers } from '../app/filter/filters';
import { DeadlineProcessRegisterController } from './controller/DeadlineProcessRegisterController';
import { HolidayRegisterController } from './controller/HolidayRegisterController';
import { CountryRegisterController } from './controller/CountryRegisterController';
import { IncotermRegisterController } from './controller/IncotermRegisterController';
import { ShipRegisterController } from './controller/ShipRegisterController';
import { CurrencyRegisterController } from './controller/CurrencyRegisterController';
import { LegalPersonRegisterController } from './controller/LegalPersonRegisterController';
import { PhysicalPersonRegisterController } from './controller/PhysicalPersonRegisterController';
import { ApplicationRegisterController } from './controller/ApplicationRegisterController';
import { BankRegisterController } from './controller/BankRegisterController';
import { ProvinceRegisterController } from './controller/ProvinceRegisterController';
import { CityRegisterController } from './controller/CityRegisterController';
import { CommodityRegisterController } from './controller/CommodityRegisterController';
import { ServiceLevelRegisterController } from './controller/ServiceLevelRegisterController'
import { ServiceTypeRegisterController } from './controller/ServiceTypeRegisterController'
import { RoutingPointRegisterController } from './controller/RoutingPointRegisterController';
import { WeightRangeRegisterController } from './controller/WeightRangeRegisterController';
import { NetworkRegisterController } from './controller/NetworkRegisterController';
import { SectorRegisterController } from './controller/SectorRegisterController';
import { CorporateBranchRegisterController } from './controller/CorporateBranchRegisterController';
import { ProviderRegisterController } from './controller/ProviderRegisterController';
import { AgentRegisterController } from './controller/AgentRegisterController';
import { PersonRoleRegisterController } from './controller/PersonRoleRegisterController';
import { ContactRegisterController } from './controller/ContactRegisterController';
import { ChargeNameRegisterController } from './controller/ChargeNameRegisterController';
import { AgencyRegisterController } from './controller/AgencyRegisterController';
import { BrokerRegisterController } from './controller/BrokerRegisterController';
import { ChargeNameSubGroupRegisterController } from './controller/ChargeNameSubGroupController';
import { AgentsParamsRegisterController } from './controller/AgentsParamsRegisterController';
import { ParamsDetDemRegisterController } from './controller/ParamsDetDemRegisterController';
import { OuterCellRegisterController } from './controller/OuterCellRegisterController';
import { TariffFreightChargesRegisterController } from './controller/TariffFreightChargesRegisterController';
import { MoveTypeRegisterController } from './controller/MoveTypeRegisterController';
import { UserRolesRegisterController } from './controller/UserRolesRegisterController';
import { UserGroupRegisterController } from './controller/UserGroupRegisterController';
import { ProductService } from '@services/ProductService';
import { DataOperationalService } from '@services/DataOperationalService';
import { DataProductService } from '@services/DataProductService';
import { OperationalService } from '@services/OperationalService';
import { HelperService } from '@services/HelperService';
import { TaskService } from '@services/TaskService';
import { EdiExternalCodeSetupController } from './controller/EdiExternalCodeSetupController'
import { TaskParamRegisterController } from './controller/TaskParamRegisterController';
import { TaskHotspotRegisterController } from './controller/TaskHotspotRegisterController';
import { RepresentativeParamsRegisterController } from './controller/RepresentativeParamsRegisterController';
import { EDISessionRegisterController } from './controller/EDISessionRegisterController';
import { EDIPartnerRegisterController } from './controller/EDIPartnerRegisterController';
import { BookingPaymentConfigController } from './controller/BookingPaymentConfigController';
import { BookingRemarksConfigController } from './controller/BookingRemarksConfigController';
import { TrackingLinkSettingsController } from './controller/TrackingLinkSettingsController';
import { EDIService } from '@services/EDIService';
import { ResponsibleReasonSettingRegisterController } from '../registration/controller/ResponsibleReasonSettingRegisterController'
import { DataManagerService } from '@services/DataManagerService';

const REGISTRATION_MODULE = angular
    .module('registration', ['ngImgCrop', GRID_FORM_MODULE.name, GRID_EXCEL_MODULE.name, 'summernote'])

    .filter('removeCellUsers', removeCellUsers)

    .service("ProductService", ProductService)
    .service("DataOperationalService", DataOperationalService)
    .service("DataProductService", DataProductService)
    .service('TaskService', TaskService)
    .service('EDIService', EDIService)
    .service("OperationalService", OperationalService)
    .service('DataManagerService', DataManagerService)
    .service("HelperService", HelperService)

    .controller('RegistrationMenuController', RegistrationMenuController)
    .controller('EquipmentRegisterController', EquipmentRegisterController)
    .controller('TradeLaneRegisterController', TradeLaneRegisterController)
    .controller('ReportTemplateRegisterController', ReportTemplateRegisterController)
    .controller('ReportAutomaticRulesRegisterController', ReportAutomaticRulesRegisterController)
    .controller('UserGroupRegisterController', UserGroupRegisterController)
    .controller('UserRegisterController', UserRegisterController)
    .controller('UserRolesRegisterController', UserRolesRegisterController)
    .controller('EventRegisterController', EventRegisterController)
    .controller('CellRegisterController', CellRegisterController)
    .controller('SLADeadlineRegisterController', SLADeadlineRegisterController)
    .controller('UserSelectController', UserSelectController)
    .controller('SpecRegisterController', SpecRegisterController)
    .controller('RoleRegisterController', RoleRegisterController)
    .controller('DeadlineProcessRegisterController', DeadlineProcessRegisterController)
    .controller('FollowUpModelRegisterController', FollowUpModelRegisterController)
    .controller('FollowUpNotificationRegisterController', FollowUpNotificationRegisterController)
    .controller('FollowUpParamsRegisterController', FollowUpParamsRegisterController)
    .controller('NotificationTypeRegisterController', NotificationTypeRegisterController)
    .controller('HolidayRegisterController', HolidayRegisterController)
    .controller('IncotermRegisterController', IncotermRegisterController)
    .controller('ShipRegisterController', ShipRegisterController)
    .controller('CurrencyRegisterController', CurrencyRegisterController)
    .controller('LegalPersonRegisterController', LegalPersonRegisterController)
    .controller('PhysicalPersonRegisterController', PhysicalPersonRegisterController)
    .controller('ApplicationRegisterController', ApplicationRegisterController)
    .controller('BankRegisterController', BankRegisterController)
    .controller('CountryRegisterController', CountryRegisterController)
    .controller('IncotermRegisterController', IncotermRegisterController)
    .controller('ProvinceRegisterController', ProvinceRegisterController)
    .controller('CityRegisterController', CityRegisterController)
    .controller('CommodityRegisterController', CommodityRegisterController)
    .controller('ServiceLevelRegisterController', ServiceLevelRegisterController)
    .controller('ServiceTypeRegisterController', ServiceTypeRegisterController)
    .controller('RoutingPointRegisterController', RoutingPointRegisterController)
    .controller('WeightRangeRegisterController', WeightRangeRegisterController)
    .controller('NetworkRegisterController', NetworkRegisterController)
    .controller('CorporateBranchRegisterController', CorporateBranchRegisterController)
    .controller('ProviderRegisterController', ProviderRegisterController)
    .controller('AgentRegisterController', AgentRegisterController)
    .controller('PersonRoleRegisterController', PersonRoleRegisterController)
    .controller('ContactRegisterController', ContactRegisterController)
    .controller('SectorRegisterController', SectorRegisterController)
    .controller('ChargeNameRegisterController', ChargeNameRegisterController)
    .controller('AgencyRegisterController', AgencyRegisterController)
    .controller('BrokerRegisterController', BrokerRegisterController)
    .controller('ChargeNameSubGroupRegisterController', ChargeNameSubGroupRegisterController)
    .controller('AgentsParamsRegisterController', AgentsParamsRegisterController)
    .controller('ParamsDetDemRegisterController', ParamsDetDemRegisterController)
    .controller('OuterCellRegisterController', OuterCellRegisterController)
    .controller('TariffFreightChargesRegisterController', TariffFreightChargesRegisterController)
    .controller('MoveTypeRegisterController', MoveTypeRegisterController)
    .controller('EdiExternalCodeSetupController', EdiExternalCodeSetupController)
    .controller('TaskParamRegisterController', TaskParamRegisterController)
    .controller('TaskHotspotRegisterController', TaskHotspotRegisterController)
    .controller('RepresentativeParamsRegisterController', RepresentativeParamsRegisterController)
    .controller('EDISessionRegisterController', EDISessionRegisterController)
    .controller('EDIPartnerRegisterController', EDIPartnerRegisterController)
    .controller('ResponsibleReasonSettingRegisterController', ResponsibleReasonSettingRegisterController)
    .controller('BookingPaymentConfigController', BookingPaymentConfigController)
    .controller('BookingRemarksConfigController', BookingRemarksConfigController)
    .controller('TrackingLinkSettingsController', TrackingLinkSettingsController)

initRoute(REGISTRATION_MODULE.name);

export { REGISTRATION_MODULE }; 

import * as angular from 'angular';
import moment = require('moment');
import { IGridOptions } from 'ui-grid';
import { fileUploader as FileUploader } from 'angular-file-upload';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { GridFormService, IGridFormController, IGridFormServiceScope } from "@services/GridFormService";
import { IMonacoColumnDef } from "@services/GridService2";
import { FormService2 } from '@services/FormService2';
import { IModalService } from "@services/ModalService";
import { IRestService } from '@services/RestService';
import { ISessionService } from "@services/SessionService";
import { OperationalService } from "@services/OperationalService";
import { ExternalService } from "@services/ExternalService";
import { ProductService } from '@services/ProductService';
import { DataProcessService } from "@services/DataProcessService";
import { Process, TOTAL_CHARGES } from "@models/interface/operational/NewProcess";
import { UserModel } from "@models/interface/common/UserModel";
import { ISelectorModel, SelectorModel } from '@models/mongo/SelectorModel';
import { EOperation, EProcessSituation } from '@enums/GenericData'; 1
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { BrowserTitle } from '../../common/BrowserTitle';
import { IFloatingMenu } from '../../common/interface/IFloatingMenu';
import { IFinopService } from '@services/FinopService';
import { IProcessDetDemParameter } from '../../common/model/ModelParameter';
import { IDocumentError } from '@models/interface/common/IDocumentError';
import { HelperService } from "@services/HelperService";
import { IProcessDetDem } from 'WBA-Model/dist/interface/dataProcess/ProcessDetDem';
import { ICustomLogProperties, IViewLog } from 'WBA-Model/dist/interface/common/IViewLog';
import { IProcessDetDemEquipment } from 'WBA-Model/dist/interface/dataProcess/ProcessDetDemEquipment';

export interface IIntegrationRedundance {
    NAME: string
    REASON: string;
    DATE: Date;
    ID: string | number;
}

export interface IIntegrationRedundanceModal extends ng.IScope {
    integrationReduncance: IIntegrationRedundance;
    parsedErrorMessage: string;
    copyErrorTextToClipboard: (integrationReduncance: IIntegrationRedundance) => void;
    sendSync: (id: number) => void;
}

export const enum ECollapseState {
    VIEW_PROCESS = "viewProcess",
    EDIT_PROCESS = "editProcess",
    CANCEL_PROCESS = "cancelProcess",
    MAIN = "mainRow",
    CARGO = "cargoRow",
    EVENTS = "eventsRow",
    CONTAINERS = "containerRow",
}

export interface INewProcessExchangeData {
    OPERATION: string;
    PROCESS_NUMBER: string;
}

interface IRepositionPanel {
    panelId: string;
    panelElement: JQLite;
    collapseId: string;
    collapseElement: JQLite;
}

interface ICollapseState {
    panel: string;
    released: boolean;
    nextState: string;
}

interface IValidationResult {
    HAS_ERROR: boolean;
    HAS_WARNING: boolean;
    ERROR_REASONS: IValidationResultErrorReasons[];
    WARNING_REASONS: IValidationResultErrorReasons[];
}

interface IValidationResultErrorReasons {
    VALIDATION_RESULT_ERRO: ISelectorModel;
    VALIDATION_RESULT_REASON: ISelectorModel;
}

interface IAllValidationResult {
    PROCESS_NUMBER: string;
    PROCESS: IValidationResult;
}

export interface IProcessSummaryValidationResult {
    USER: string;
    DATE: Date;
    ERROR: number;
    HAS_ERROR: boolean;
    HAS_WARNING: boolean;
    WARNING: number;
}

export interface INewProcessDetDemScope extends IGridFormServiceScope {
    model: IProcessDetDem;
    equipmentModel: IProcessDetDemEquipment;
    form: ng.IFormController;
    gridOptions: IGridOptions;
    showNewProcessForm: boolean;
    menuFloating: IFloatingMenu;
    processConcatenated: string;
    processConcatenatedHtml: string;
    processIsBookmarked: boolean;
    user: UserModel;
    $q: ng.IQService;
    $filter: ng.FilterFactory;
    $sce: angular.ISCEService;
    $compile: angular.ICompileService;
    $timeout: ng.ITimeoutService;
    formService: FormService2;
    sessionService: ISessionService;
    restService: IRestService;
    operationalService: OperationalService;

    dataProcessService: DataProcessService;
    externalService: ExternalService;
    productService: ProductService;
    finopService: IFinopService;
    modalService: IModalService;
    fileUploader: FileUploader;
    lastScroll: number;
    collapseState: ICollapseState;
    downloadRoute: string;
    equipmentConcatenated: string;
    eventTransitTime: string;
    eventTransitTimeDifference: string;
    priorityList: ISelectorModel[];
    totalSumCharges: TOTAL_CHARGES;
    allValidationResult: IAllValidationResult;
    customLogProperties: ICustomLogProperties[];
    editProcess: (process: IProcessDetDem) => Promise<void>;
    viewProcess: (process: IProcessDetDem) => Promise<void>;
    verifyOfferError: (entity: IProcessDetDem) => boolean;
    goToProcessDetDemManagement: (entity: Process) => void;
    repositionPanels: (currentPanelId: string, isOpenning?: boolean) => void;
    hasChanges: (newObj: Object, oldObj: Object, propertiesToIgnore?: string[]) => boolean;
    hasInvalidRequiredElements: (elementId: string) => boolean;
    hasInvalidElements: (elementId: string) => boolean;
    modalSaveConfirmation: (headerText: string, closeButtonText: string) => Promise<boolean>;
    releaseCollapse: (panel?: string) => void;
    showSave: () => boolean;
    saveBookmarkProcess: () => Promise<void>;
    updateProcessPriority: () => void;
    openSummaryModal: () => void;
    buildIntegrationErrorModalScope: (integrationReduncance: IIntegrationRedundance, name: string) => Promise<any>;
    parseErrorMessage: (integrationReduncance: IIntegrationRedundance) => string;
    openConcatenatedModal: () => void;
    updateGridRow: (id: string) => void;
    viewProcessConsistencyValidateDetails: (process: IProcessDetDem) => Promise<void>;
    buildGridConsistencyTooltip: (summaryValidationResult: IProcessSummaryValidationResult) => string;
    disablePanelDueToPreProcessStatus: () => boolean;
    viewLogProcessDetDem: () => Promise<void>;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
}

export class NewProcessDetDemController extends GridFormService implements IGridFormController {
    static $inject: string[] = ["$injector", "$scope"];
    private $scope: INewProcessDetDemScope;
    private lastGridProcess: IProcessDetDem;
    private preProcessModalId: number;
    private helperService: HelperService;
    private ModalService: IModalService;
    private modalID: number;
    private $timeout: ng.ITimeoutService;

    constructor($injector: ng.Injectable<any>, $scope: INewProcessDetDemScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$scope.$q = $injector.get('$q');
        this.$scope.$filter = $injector.get('$filter');
        this.$scope.$sce = $injector.get('$sce');
        this.$scope.$compile = $injector.get('$compile');
        this.$scope.$timeout = $injector.get('$timeout');
        this.$scope.formService = this.formService;
        this.$scope.restService = $injector.get('RestService');
        this.$scope.externalService = $injector.get('ExternalService');
        this.$scope.productService = $injector.get('ProductService');
        this.$scope.operationalService = $injector.get('OperationalService');
        this.$scope.modalService = $injector.get('ModalService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.$scope.fileUploader = $injector.get('FileUploader');
        this.$scope.productService = $injector.get('ProductService');
        this.$scope.finopService = $injector.get('FinopService');
        this.$scope.collapseState = { panel: null, released: true, nextState: null };
        this.$scope.dataProcessService = $injector.get('DataProcessService');
        this.helperService = $injector.get('HelperService');
        this.$timeout = $injector.get('$timeout');
        this.modalID = null;
        this.ModalService = $injector.get('ModalService');
    }

    initScopeFunctions(): void {
        this.$scope.repositionPanels = (currentPanelId: string, isOpenning?: boolean) => {
            this.repositionPanels(currentPanelId, isOpenning);
        }

        this.$scope.hasChanges = (newObj: Object, oldObj: Object, propertiesToIgnore?: string[]) => {
            if (propertiesToIgnore) {
                const newAux = newObj ? angular.copy(newObj) : null;
                const oldAux = oldObj ? angular.copy(oldObj) : null;
                for (const property of propertiesToIgnore) {
                    if (newAux && newAux[property]) {
                        delete newAux[property];
                    }
                    if (oldAux && oldAux[property]) {
                        delete oldAux[property];
                    }
                }
                return !angular.equals(JSON.stringify(newAux), JSON.stringify(oldAux));
            }
            return !angular.equals(newObj, oldObj);
        }

        this.$scope.hasInvalidRequiredElements = (elementId: string) => {
            return this.hasInvalidRequiredElements(elementId);
        }

        this.$scope.hasInvalidElements = (elementId: string) => {
            return this.hasInvalidElements(elementId ? "#" + elementId : elementId);
        }

        this.$scope.modalSaveConfirmation = async (headerText: string, closeButtonText: string) => {
            return this.modalSaveConfirmation(headerText, closeButtonText);
        }

        this.$scope.releaseCollapse = (panel?: string) => {
            this.releaseCollapse(panel);
        }

        this.$scope.showSave = () => {
            return this.$scope.operation != EOperation.VIEW && (this.$scope.collapseState.panel == ECollapseState.MAIN || this.$scope.collapseState.panel == ECollapseState.CARGO);
        }

        this.$scope.saveBookmarkProcess = async () => {
            try {
                this.formService.block();

                if (this.$scope.processIsBookmarked) {
                    const removed = await this.$rootScope.removeSetting('PROCESS_BOOKMARKED', this.$scope.model.PROCESS_NUMBER, false);
                    if (removed) {
                        this.$scope.processIsBookmarked = false;
                        const msg = this.formService.getTranslate('OPERATIONAL.REMOVE_STAR');
                        this.formService.notifySuccess(msg);
                    } else {
                        const msg = this.formService.getTranslate('OPERATIONAL.REMOVE_STAR_ERROR');
                        this.formService.notifyError(msg);
                    }
                } else {
                    const updated = await this.$rootScope.updateSetting('PROCESS_BOOKMARKED', this.$scope.model._id, this.$scope.model.PROCESS_NUMBER, false);
                    if (updated) {
                        this.$scope.processIsBookmarked = true;
                        const msg = this.formService.getTranslate('OPERATIONAL.ADD_STAR');
                        this.formService.notifySuccess(msg)
                    } else {
                        const msg = this.formService.getTranslate('OPERATIONAL.ADD_STAR_ERROR');
                        this.formService.notifyError(msg);
                    }
                }

                this.formService.unblock();
            } catch (ex) {
                this.formService.handleError(ex);
            }
        }

        this.$scope.editProcess = async (process: IProcessDetDem): Promise<void> => {
            if (await this.permissionService.isRoleAllowed("PROCESSEDIT") === false) {
                this.permissionService.showBlockMessage();
                this.unblock();
                return;
            }
            this.$scope.operation = "edit";
            this.$scope.disableElements(false);
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');
            this.viewProcess(process);
        }

        this.$scope.viewProcess = async (process: IProcessDetDem): Promise<void> => {
            if (await this.permissionService.isRoleAllowed("PROCESSVIEW") === false) {
                this.permissionService.showBlockMessage();
                this.unblock();
                return;
            }
            this.$scope.operation = EOperation.VIEW;
            this.$scope.disableElements(true);
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
            this.viewProcess(process);
        }

        this.$scope.goToProcessDetDemManagement = (entity: Process) => {
            this.$scope.sessionService.openTab('app.operational.process.detDemManagement', <IProcessDetDemParameter>{ PROCESS_NUMBER: entity.PROCESS_NUMBER.substring(0, entity.PROCESS_NUMBER.length - 2) });
        }

        this.$scope.parseErrorMessage = (integrationReduncance: IIntegrationRedundance): string => {
            let returnString = ``;
            if (integrationReduncance && integrationReduncance.DATE && integrationReduncance.REASON) {
                returnString = (moment(integrationReduncance.DATE).format("(DD/MM) HH:mm:ss"));
                returnString += (' - ');
                returnString += integrationReduncance.REASON;
            }
            return returnString;
        }

        this.$scope.openConcatenatedModal = () => {
            this.openConcatenatedModal();
        }

        this.$scope.updateGridRow = (id: string) => {
            this.updateGridRow(id);
        }

        this.$scope.viewProcessConsistencyValidateDetails = async (process: IProcessDetDem): Promise<void> => {
            this.viewProcessConsistencyValidateDetails(process);
        }

        this.$scope.buildGridConsistencyTooltip = (summaryValidationResult: IProcessSummaryValidationResult) => {
            return this.tooltip(summaryValidationResult);
        }

        this.$scope.disablePanelDueToPreProcessStatus = (): boolean => {
            const situation = this.$scope && this.$scope.model && this.$scope.model.SITUATION && this.$scope.model.SITUATION.ID;
            let result = false;

            switch (situation) {
                case EProcessSituation.PRE_PROCESS:
                    break;
                default:
                    result = true;
            }
            return result;
        }

        this.$scope.viewLogProcessDetDem = (): Promise<void> => {
            return this.viewLogProcessDetDem();
        }

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }
    }

    private async openConcatenatedModal(): Promise<void> {
        const concatenatedComplementBefore = angular.copy(this.$scope.model.CONCATENATED_COMPLEMENT);
        const html = `
        <div class="row">
            <div class="col-lg-12">
                <input type="text" class="form-control" id="concatenatedComplement" name="concatenatedComplement" ng-model="model.CONCATENATED_COMPLEMENT" maxlength="15" ng-readonly="operation == 'view'">
            </div>
        </div>
        `;
        const modalInstance: IModalInstanceService = await this.$scope.modalService.showModalInfo(
            {
                scope: this.$scope,
                size: 'lg'
            },
            {
                closeButtonText: 'GENERAL.CLOSE',
                actionButtonText: 'GENERAL.SAVE',
                headerText: 'GENERAL.UPDATE_CONCATENATED',
                bodyText: this.$scope.$sce.trustAsHtml(html),
            }
        );

        modalInstance.rendered.then(() => {
            const concatenatedComplement = angular.element("#concatenatedComplement");
            if (concatenatedComplement) this.$scope.$compile(concatenatedComplement)(this.$scope);
        });

        const apply = await modalInstance.result.then(function (result) {
            return result.$value;
        }, function (result) {
            return result.$value;
        });

        if (apply) {
            const resultOperation = await this.$scope.dataProcessService.post('/processDetDem/update/concatenatedComplement', { ID: this.$scope.model.ID, CONCATENATED_COMPLEMENT: this.$scope.model.CONCATENATED_COMPLEMENT }, 30000);
            if (resultOperation) {
                this.$scope.processConcatenated = this.generateProcessConcatenated();
                this.$scope.processConcatenatedHtml = this.generateProcessConcatenated(true);
                this.$scope.$applyAsync();
                const msgSuccess = this.formService.getTranslate('GENERAL.SUCCESS_UPDATE_CONCATENATED');
                this.formService.notifySuccess(msgSuccess);
            }
            else {
                const msgError = this.formService.getTranslate('REGISTRATION.MESSAGES.ERROR.UPDATE_ERROR');
                this.formService.notifyError(msgError);
                this.$scope.model.CONCATENATED_COMPLEMENT = concatenatedComplementBefore;
            }
        } else this.$scope.model.CONCATENATED_COMPLEMENT = concatenatedComplementBefore;
    }

    async getProcessByProcessNumber(id: string) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const processData = await this.$scope.dataProcessService.post('/processDetDem/list', { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000);
            if (processData && processData.data && processData.data.data && (processData.data.data.data && processData.data.data.data.length > 0)) return processData.data.data.data[0];
            else return null;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    async getProcessDetDemEquipments(idProcessDetDem: number) {
        try {
            if (!idProcessDetDem) throw new Error("idProcessDetDem is null on getProcessDetDemEquipments.");
            const processDetDemEquipmentData = await this.$scope.dataProcessService.get(`/processDetDem/${idProcessDetDem}/equipment`, 3000);
            if (processDetDemEquipmentData && processDetDemEquipmentData.data && processDetDemEquipmentData.data.data) return processDetDemEquipmentData.data.data;
            else return null;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async initDependencies(): Promise<any> {
        try {
            const self: NewProcessDetDemController = this;

            // on change released            
            self.$scope.$watch('collapseState.released', function (released: boolean) {
                if (released && self.$scope.collapseState.nextState) self.releaseCollapse(self.$scope.collapseState.nextState);
            });

            return new Promise(function (resolve, reject) {
                self.$scope.$q.all([
                    self.getGenericList('priority')
                ]).then((result: any) => {
                    self.$scope.priorityList = result[0];
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getGenericList(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    async viewProcess(processDetDem: IProcessDetDem) {
        if (!this.$scope.collapseState || this.$scope.collapseState.released) {

            this.$scope.model = processDetDem;
            this.$scope.equipmentModel = await this.getProcessDetDemEquipments(this.$scope.model.ID);
            const isBookmarked = await this.getIsBookmarked("BM");
            this.$scope.processIsBookmarked = isBookmarked && isBookmarked.data;
            this.$scope.processConcatenated = this.generateProcessConcatenated();
            this.$scope.processConcatenatedHtml = this.generateProcessConcatenated(true);
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.showForm = true;
            this.$scope.showNewProcessForm = true;
            // this.$scope.equipmentConcatenated = this.$scope.model.CARGO_DETAIL ? this.$scope.model.CARGO_DETAIL.EQUIPMENT_CONCATENATED : "";
            // this.$scope.eventTransitTime = this.$scope.model.TTIME ? this.$scope.model.TTIME.toString() : "0";
            // this.$scope.eventTransitTimeDifference = this.$scope.model.TTIME_DIFFERENCE ? this.$scope.model.TTIME_DIFFERENCE.toString() : "0";
            // this.$scope.totalSumCharges = this.$scope.model.TOTAL_CHARGES;

            this.$scope.$timeout(() => {
                angular.element("#collapseMain")["collapse"]('show');
            }, 500);

            // Make the row selected in grid.
            this.$gridService.setFixedRow(this.$scope.model._id.toString());

        } else {
            this.lastGridProcess = processDetDem;
            this.$scope.collapseState.nextState = this.$scope.operation == EOperation.VIEW ? ECollapseState.VIEW_PROCESS : ECollapseState.EDIT_PROCESS;
            this.releaseCollapse();
        }

        BrowserTitle.$id = this.$scope.model.PROCESS_NUMBER;
    }

    async save(): Promise<boolean> {
        if (!this.$scope.collapseState) this.formService.notifyError("collapseState is null.");
        else if (!this.$scope.collapseState.released) {
            switch (this.$scope.collapseState.panel) {
                case ECollapseState.MAIN:
                    this.$scope.$broadcast("processMainSave");
                    break;
                case ECollapseState.CARGO:
                    this.$scope.$broadcast("processCargoSave");
                    break;
            }
        }
        return false;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.$scope.dataProcessService.$route;
            this.initForm(this, "form", "newProcess", "OPERATIONAL.PROCESS_LIST", false);
            this.block();
            // init grid

            await this.initGrid('gridNewProcess', '/processDetDem/list', true, true, 120000, true, true);
            await this.initDependencies();
            this.$scope.showNewProcessForm = false;
            const sessionParameter = this.$gridService.$sessionParameter;
            if (sessionParameter && sessionParameter.data) await this.callSessionFunctions(sessionParameter.data);
            this.unblock();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.$rootScope.clearBreadCrumb();
        super.$onDestroy();
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<a ng-click="grid.appScope.viewProcess(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editProcess(row.entity)" ng-class="{'disabled': row.entity.FORWARDING_SITUATION && row.entity.FORWARDING_SITUATION.SITUATION && row.entity.FORWARDING_SITUATION.SITUATION.ID == '7'}" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{row.entity.FORWARDING_SITUATION && row.entity.FORWARDING_SITUATION.SITUATION && row.entity.FORWARDING_SITUATION.SITUATION.ID == '7' ? 'OPERATIONAL.EDIT_CANCEL' : 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true"><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const processDetDemManagement = `<a ng-click="grid.appScope.goToProcessDetDemManagement(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{ 'Ir para Gerenciamento D&D' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-file icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: 95,
            cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${processDetDemManagement} ${modalIntegration} </div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };

        gridColumns.addColumn(colActions);
        columns.push("CONCATENADO");
        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            // process data
            const colConcatenado: IMonacoColumnDef = { name: "CONCATENATED", displayName: "GENERAL.CONCATENATED", width: 150 };
            const colProcessNumber: IMonacoColumnDef = { name: "PROCESS_NUMBER", displayName: "OPERATIONAL.FILE_NUMBER", width: 150 };
            const colProcessType: IMonacoColumnDef = { name: "PROCESS_TYPE.NAME", displayName: "OPERATIONAL.FILE_TYPE", width: 150 };
            const colExporter: IMonacoColumnDef = { name: "EXPORTER.NAME", displayName: "BASIC_DATA.SHIPPER", width: 150 };
            const colImporter: IMonacoColumnDef = { name: "IMPORTER.NAME", displayName: "BASIC_DATA.CONSIGNEE", width: 150 };
            const colCreatedDate: IMonacoColumnDef = { name: "CREATED_DATE", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colBookingIntegration: IMonacoColumnDef = { name: "BOOKING_INTEGRATION.NAME", displayName: "OPERATIONAL.BOOKING", width: 150 };
            const colSituation: IMonacoColumnDef = { name: "SITUATION.NAME", displayName: "OPERATIONAL.FILE_OPERATIONAL_STATUS", width: 150 };
            const colReference: IMonacoColumnDef = { name: "REFERENCE", displayName: "OPERATIONAL.CLIENT_REFERENCE", width: 150 };
            const colServiceProvider: IMonacoColumnDef = { name: "SERVICE_PROVIDER.NAME", displayName: "BASIC_DATA.PROVIDER", width: 150 };
            const colMasterDocument: IMonacoColumnDef = { name: "MASTER_DOCUMENT", displayName: "OPERATIONAL.MASTER_BL", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.MASTER_DOCUMENT, null, "")}}</div>' };
            const colHouseDocument: IMonacoColumnDef = { name: "HOUSE_DOCUMENT", displayName: "OPERATIONAL.HOUSE_BL", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.HOUSE_DOCUMENT, null, "")}}</div>' };
            const colCommercialUnity: IMonacoColumnDef = { name: "COMMERCIAL_UNITY.NAME", displayName: "BASIC_DATA.COMMERCIAL_BRANCH", width: 150 };
            const colBranch: IMonacoColumnDef = { name: "BRANCH.NAME", displayName: "BASIC_DATA.BRANCH", width: 150 };
            const colSalesPerson: IMonacoColumnDef = { name: "SALES_PERSON.NAME", displayName: "BASIC_DATA.SALES_EXECUTIVE", width: 150 };
            const colCustomer: IMonacoColumnDef = { name: "CUSTOMER.NAME", displayName: "BASIC_DATA.CLIENT", width: 150 };
            const colContainerNumbers: IMonacoColumnDef = { name: "PROCESS_DET_DEM_MANAGEMENT.CONTAINER_NUMBER", displayName: "OPERATIONAL.CONTAINER", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PROCESS_DET_DEM_MANAGEMENT, null, "CONTAINER_NUMBER")}}</div>' };
            const colHousePayment: IMonacoColumnDef = { name: "HOUSE_PAYMENT.NAME", displayName: "BASIC_DATA.HOUSE_PAYMENT_MODE", width: 150 };
            const colMasterPayment: IMonacoColumnDef = { name: "MASTER_PAYMENT.NAME", displayName: "BASIC_DATA.MASTER_PAYMENT_MODE", width: 150 };
            const colFatturo: IMonacoColumnDef = { name: "FATTURO_FINANCIAL_PROCESS", displayName: "FINANCIAL.FATTURO", width: 150 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'CONCATENADO':
                        columnDefs.push(colConcatenado);
                        break;
                    case 'PROCESS_NUMBER':
                        columnDefs.push(colProcessNumber);
                        break;
                    case 'PROCESS_TYPE':
                        columnDefs.push(colProcessType);
                        break;
                    case 'EXPORTER':
                        columnDefs.push(colExporter);
                        break;
                    case 'IMPORTER':
                        columnDefs.push(colImporter);
                        break;
                    case 'CREATED_DATE':
                        columnDefs.push(colCreatedDate);
                        break;
                    case 'BOOKING_INTEGRATION':
                        columnDefs.push(colBookingIntegration);
                        break;
                    case 'SITUATION':
                        columnDefs.push(colSituation);
                        break;
                    case 'REFERENCE':
                        columnDefs.push(colReference);
                        break;
                    case 'SERVICE_PROVIDER':
                        columnDefs.push(colServiceProvider);
                        break;
                    case 'MASTER_DOCUMENT':
                        columnDefs.push(colMasterDocument);
                        break;
                    case 'HOUSE_DOCUMENT':
                        columnDefs.push(colHouseDocument);
                        break;
                    case 'COMMERCIAL_UNITY':
                        columnDefs.push(colCommercialUnity);
                        break;
                    case 'BRANCH':
                        columnDefs.push(colBranch);
                        break;
                    case 'SALES_PERSON':
                        columnDefs.push(colSalesPerson);
                        break;
                    case 'CUSTOMER':
                        columnDefs.push(colCustomer);
                        break;
                    case "PROCESS_DET_DEM_MANAGEMENT":
                        // columnDefs.push(colMasterDocument);
                        // columnDefs.push(colHouseDocument);
                        columnDefs.push(colContainerNumbers);
                        break;
                    case "MASTER_PAYMENT":
                        columnDefs.push(colMasterPayment);
                        break;
                    case "HOUSE_PAYMENT":
                        columnDefs.push(colHousePayment);
                        break;
                    case "FATTURO_FINANCIAL_PROCESS":
                        columnDefs.push(colFatturo);
                        break;
                };
            }

            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            AGENCY: null,
            BOOKING_INTEGRATION: null,
            BRANCH: null,
            BROKER: null,
            COMMERCIAL_UNITY: null,
            CONCATENATED_COMPLEMENT: null,
            CREATED_DATE: null,
            EXPORTER: null,
            EXTERNAL_AGENT: null,
            FLEXI_FITTING: null,
            HOUSE_DOCUMENT: null,
            ID: null,
            ID_PROCESS: null,
            IMPORTER: null,
            ISOTANK_PROVIDER: null,
            LOCAL_AGENT: null,
            MASTER_DOCUMENT: null,
            NOTIFY: null,
            PROCESS_NUMBER: null,
            PROCESS_TYPE: null,
            REFERENCE: null,
            REPRESENTATIVES: null,
            SALES_PERSON: null,
            SERVICE_PROVIDER: null,
            SITUATION: null,
            THIRD_AGENT: null,
            TRANSPORTER_CONTAINER_STUFFING: null,
            TRANSPORTER_DELIVERY: null,
            TRANSPORTER_FINAL_DESTINATION: null,
            TRANSPORTER_GATE_OUT_EMPTY: null,
            TRANSPORTER_PICKUP: null,
            TRANSPORTER_PLACE_RECEPT: null,
            EXTERNAL_BROKER: null,
            CUSTOMER: null,
            HOUSE_PAYMENT: null,
            MASTER_PAYMENT: null,
            DESTINATION: null,
            ORIGIN: null,
            CONCATENATED: null,
            DOCUMENT_ERROR: null,
            CARGO_TYPE: null,
            CONTAINER_GROUP_LOAD: null,
            EFFECTIVE_DATE_DELIVERY: null,
            EFFECTIVE_DATE_DISCHARGE: null,
            EFFECTIVE_DATE_LOAD: null,
            EMISSION_TYPE: null,
            FORECAST_DATE_DISCHARGE: null,
            FORECAST_DATE_LOAD: null,
            FORECAST_DATE_DELIVERY: null,
            INCOTERM: null,
            ISSUEANCE: null,
            LOCAL_ISSUEANCE: null,
            MASTER_DIRECT: null,
            PRODUCT: null,
            RELEASE: null,
            SERVICE_CATEGORY: null,
            TRANSPORT_MODE_LOAD: null,
            FORWARDING_SITUATION: null,
            FATTURO_FINANCIAL_PROCESS: null,
            PROCESS_BASE_SITUATION: null,
            PROCESS_BASE_PROCESS_NUMBER: null,
            CORPORATE_ACCOUNT: null
        }
    }

    private repositionPanels(currentPanelId: string, isOpenning?: boolean) {
        if (currentPanelId) {
            const panels: IRepositionPanel[] = [];
            panels.push({ panelId: ECollapseState.MAIN, panelElement: angular.element(`#${ECollapseState.MAIN}`), collapseId: "collapseMain", collapseElement: angular.element("#collapseMain") });
            panels.push({ panelId: ECollapseState.CARGO, panelElement: angular.element(`#${ECollapseState.CARGO}`), collapseId: "collapseCargo", collapseElement: angular.element("#collapseCargo") });
            panels.push({ panelId: ECollapseState.CONTAINERS, panelElement: angular.element(`#${ECollapseState.CONTAINERS}`), collapseId: "collapseContainers", collapseElement: angular.element("#collapseContainers") });
            panels.push({ panelId: ECollapseState.EVENTS, panelElement: angular.element(`#${ECollapseState.EVENTS}`), collapseId: "collapseEvents", collapseElement: angular.element("#collapseEvents") });

            if (!this.$rootScope.app || !this.$rootScope.app.settings || this.$rootScope.app.settings.panelsReorder) {
                if (isOpenning) {
                    // set current panel to be the first
                    const currentPanelIndex = panels.findIndex(panel => panel.panelId == currentPanelId);
                    if (currentPanelIndex >= 0) {
                        panels[currentPanelIndex].panelElement.css('order', '1');
                        // reposition other panels
                        panels.splice(currentPanelIndex, 1);
                        let order = 2;
                        for (const panel of panels) {
                            $("#" + panel.collapseId)["collapse"]('hide');
                            panel.panelElement.css('order', order);
                            order++;
                        }
                        // navigate to first panel
                        this.navigateBetweenIds(currentPanelId);
                    }
                } else {
                    const panelsToReposition = panels.filter(panel => !panel.collapseElement.hasClass("in"));
                    let order = panels.length - panelsToReposition.length + 1;
                    for (const panel of panelsToReposition) {
                        panel.panelElement.css('order', order);
                        order++;
                    }
                }
            }
        }
    }

    private hasInvalidRequiredElements(elementId: string): boolean {
        if (!elementId) return false;
        const isInvalid = FormService2.hasRequiredElements('#' + elementId);
        if (isInvalid) this.formService.notifyError(this.formService.getTranslate("GENERAL.ALL_FIELDS_MANDATORY"));
        return isInvalid;
    }

    private hasInvalidElements(elementId: string): boolean {
        const selector: string = (elementId == '') ? 'input, textarea, select, .ui-select-container' : `${elementId} input, ${elementId} textarea, ${elementId} select, ${elementId} .ui-select-container`;
        const elements = angular.element(selector);

        let isInvalid = false;

        if (elements && elements.length) {
            for (let i = 0; i < elements.length; i++) {
                const element = angular.element(elements[i]);
                isInvalid = element.hasClass('ng-invalid') || element.hasClass('ng-invalid-datetime');
                if (isInvalid) break;
            }
        }

        if (isInvalid) this.formService.notifyError('Verifique os campos inválidos e tente novamente.');
        return isInvalid;
    }

    private async getIsBookmarked(type) {
        const result = await this.$rootScope.getSetting('PROCESS_BOOKMARKED', this.$scope.model.PROCESS_NUMBER);
        return { id: type, data: result };
    }

    private async modalSaveConfirmation(headerText: string, closeButtonText: string): Promise<boolean> {
        return await this.$scope.modalService.showModalConfirmation({}, {
            headerText: headerText,
            bodyText: this.formService.getTranslate('REGISTRATION.MESSAGES.ERROR.UPDATE_NOT_SAVED'),
            actionButtonText: 'REGISTRATION.SAVE_CONTINUE',
            closeButtonText: 'GENERAL.CLOSE',
        });
    }

    private releaseCollapse(panel?: string) {
        if (!this.$scope.collapseState) this.formService.notifyError("collapseState is null.");
        const panelToCollapse = panel ? panel : this.$scope.collapseState.panel;
        switch (panelToCollapse) {
            case ECollapseState.VIEW_PROCESS:
                this.$scope.collapseState.nextState = null;
                this.$scope.viewProcess(this.lastGridProcess)
                break;
            case ECollapseState.EDIT_PROCESS:
                this.$scope.collapseState.nextState = null;
                this.$scope.editProcess(this.lastGridProcess);
                break;
            case ECollapseState.CANCEL_PROCESS:
                this.$scope.collapseState.nextState = null;
                this.cancel();
                break;
            case ECollapseState.MAIN:
                this.$scope.$broadcast("processMainCollapse");
                break;
            case ECollapseState.CARGO:
                this.$scope.$broadcast("processCargoCollapse");
                break;
            case ECollapseState.CONTAINERS:
                this.$scope.$broadcast("processContainerCollapse");
                break;
            case ECollapseState.EVENTS:
                this.$scope.$broadcast("processEventCollapse");
                break;
        }
    }

    async cancel(): Promise<void> {
        try {
            if (!this.$scope.collapseState) this.formService.notifyError("collapseState is null.");
            else if (this.$scope.collapseState.released) {
                BrowserTitle.$id = null;
                this.$scope.formOperation = "";
                this.$scope.showNewProcessForm = false;
                if (this.gridService && this.gridService.$gridApi && this.gridService.$gridApi.selection) this.gridService.$gridApi.selection.clearSelectedRows();
            } else {
                this.$scope.collapseState.nextState = ECollapseState.CANCEL_PROCESS;
                this.releaseCollapse();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private generateProcessConcatenated(isHtml?: boolean): string {
        let concatenated = "";
        const separator = " | ";

        try {

            if (this.$scope.model.PROCESS_NUMBER && this.$scope.model.PROCESS_NUMBER.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PROCESS_NUMBER) : concatenated.concat(this.$scope.model.PROCESS_NUMBER);
            }

            if (this.$scope.model.CUSTOMER && this.$scope.model.CUSTOMER.NAME && this.$scope.model.CUSTOMER.NAME.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CUSTOMER.NAME) : concatenated.concat(this.$scope.model.CUSTOMER.NAME);
            }

            if (this.$scope.model.ORIGIN && this.$scope.model.ORIGIN.CODE && this.$scope.model.ORIGIN.CODE.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.ORIGIN.CODE) : concatenated.concat(this.$scope.model.ORIGIN.CODE);
            }

            if (this.$scope.model.DESTINATION && this.$scope.model.DESTINATION.CODE && this.$scope.model.DESTINATION.CODE.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.DESTINATION.CODE) : concatenated.concat(this.$scope.model.DESTINATION.CODE);
            }

            if (this.$scope.model.SERVICE_PROVIDER && this.$scope.model.SERVICE_PROVIDER.SCAC && this.$scope.model.SERVICE_PROVIDER.SCAC.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.SERVICE_PROVIDER.SCAC) : concatenated.concat(this.$scope.model.SERVICE_PROVIDER.SCAC);
            }

            if (this.$scope.equipmentModel && this.$scope.equipmentModel.EQUIPMENT_LIST && this.$scope.equipmentModel.EQUIPMENT_LIST.length) {
                const equipments = this.$scope.getCONCAT(this.$scope.equipmentModel.EQUIPMENT_LIST, "EQUIPMENT", null, null, null, ';');
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, equipments) : concatenated.concat(equipments);
            }

            if (this.$scope.model.CONCATENATED_COMPLEMENT && this.$scope.model.CONCATENATED_COMPLEMENT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CONCATENATED_COMPLEMENT) : concatenated.concat(this.$scope.model.CONCATENATED_COMPLEMENT);
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
        return concatenated;
    }

    private getMenuFloatingDefault(): IFloatingMenu {
        const menuOptions = {
            tooltipPlacement: "auto bottom",
            textTooltip: "OPERATIONAL.FILE_DATA",
            infos: [
                { text: this.$scope.model.PROCESS_NUMBER, class: "text-cyano font-bold", click: "openSummaryModal", args: [] }
            ],
            options: [
                { click: "releaseCollapse", args: [ECollapseState.MAIN], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.MAIN", iconClass: "fa fa-home", iconBodyClass: "text-brown" },
                { click: "releaseCollapse", args: [ECollapseState.CARGO], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.EQUIPMENT", iconClass: "fa fa-truck", iconBodyClass: "text-cyano" },
                { click: "releaseCollapse", args: [ECollapseState.CONTAINERS], tooltipPlacement: "auto bottom", textTooltip: "OPERATIONAL.CONTAINER", iconClass: "fa fa-cubes", iconBodyClass: "text-cyano" },
                { click: "releaseCollapse", args: [ECollapseState.EVENTS], tooltipPlacement: "auto bottom", textTooltip: "OPERATIONAL.EVENT", iconClass: "fa fa-location-arrow", iconBodyClass: "text-rouge" },
            ],
        };

        return menuOptions;
    }

    private async callSessionFunctions(data: object): Promise<void> {
        try {
            if (!data) return;
            const processExchangeData = <INewProcessExchangeData>data;
            let processModel: IProcessDetDem = null;
            for (const gridData of this.$scope.gridOptions.data) {
                if (processExchangeData.PROCESS_NUMBER != null && gridData.PROCESS_NUMBER == processExchangeData.PROCESS_NUMBER) {
                    processModel = gridData;
                    break;
                }
            }
            switch (processExchangeData.OPERATION) {
                case EOperation.VIEW: if (processModel) this.$scope.viewProcess(processModel);
                    break;
                case EOperation.EDIT: {
                    if (processModel) await this.$scope.editProcess(processModel);
                    break;
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private navigateBetweenIds(to: string): boolean {
        if (!to) return false;
        this.$scope.$timeout(function () {
            const element = $("#" + to);
            if (element.length == 0) return;
            const position = $("#" + to).offset().top + $('.app-content-body').scrollTop() - 215;
            $('.app-content-body').animate({
                scrollTop: position
            }, 500);
            return true;
        });
        return false;
    };

    private async updateGridRow(id: string) {
        try {
            if (id && angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$scope.$timeout(async () => {
                    const processData = await this.getProcessByProcessNumber(id);
                    if (row && processData) {
                        row.BOOKING = processData.BOOKING;
                    }
                }, 1000);
            }
        } catch (ex) {
            this.handleError(ex);
        }

    }

    private tooltip(summaryValidationResult: IProcessSummaryValidationResult): string {
        let result = this.formService.getTranslate('PROCESS.CLICK_VALIDATE_PROCESS');
        if (summaryValidationResult && summaryValidationResult.USER && summaryValidationResult.DATE) {
            result = this.formService.getTranslate('GENERAL.CLICK_VALIDATE_OFFER_MESSAGE', { user: summaryValidationResult.USER, date: summaryValidationResult.DATE })
        }
        return result;
    }

    private async viewProcessConsistencyValidateDetails(process: IProcessDetDem, isReload?: boolean): Promise<void> {
        if (!process) throw new Error("process is null.");
        this.formService.block();
        try {
            let allValidationResultRequest = null;

            if (allValidationResultRequest && allValidationResultRequest.data.data) {
                this.lastGridProcess = process;
                this.$scope.allValidationResult = allValidationResultRequest.data.data;

                if ((allValidationResultRequest.data.data.PROCESS.VALIDATION_RESULT && (allValidationResultRequest.data.data.PROCESS.VALIDATION_RESULT.HAS_ERROR || allValidationResultRequest.data.data.PROCESS.VALIDATION_RESULT.HAS_WARNING)) || (allValidationResultRequest.data.data.PROCESS_CARGO && allValidationResultRequest.data.data.PROCESS_CARGO.VALIDATION_RESULT && (allValidationResultRequest.data.data.PROCESS_CARGO.VALIDATION_RESULT.HAS_ERROR || allValidationResultRequest.data.data.PROCESS_CARGO.VALIDATION_RESULT.HAS_WARNING))) {
                    if (!isReload) {
                        const modalId = this.$scope.modalService.newModal();
                        this.$scope.modalService.showModalInfo(
                            {
                                modalID: modalId,
                                scope: this.$scope,
                                formService: EOperation.VIEW,
                                size: 'vlg modal-overflow',
                                template: require("../view/modal/processConsistencyValidateDetailsModal.html"),
                                keyboard: false
                            },
                            null
                        );
                    }
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private sendSync = async (id: number): Promise<boolean> => {
        let sendSync = false;
        let success = false;
        try {
            if (id) {
                const syncRequest = await this.$scope.dataProcessService.post(`/processDetDem/sendSync`, { ID: id }, 30000);
                if (syncRequest) sendSync = true;

                success = sendSync;
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_SENDING_REQUEST');
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$scope.$timeout(async () => {
                    const processData = await this.getProcessByProcessNumber(id.toString());
                    if (processData) {
                        if (row && processData && processData.DOCUMENT_ERROR !== undefined) {
                            row.DOCUMENT_ERROR = processData.DOCUMENT_ERROR;
                            documentError = processData.DOCUMENT_ERROR;
                        }
                    }

                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async openPreProcessRegisterModal(): Promise<void> {
        try {
            if (this.preProcessModalId) this.$scope.modalService.closeModal(this.preProcessModalId);
            if (!this.preProcessModalId) this.preProcessModalId = this.$scope.modalService.newModal();

            await this.$scope.modalService.showModalRegisterPreProcess(this.preProcessModalId);

            const modalScope = await this.$scope.modalService.getModalScope(this.preProcessModalId);
            await modalScope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'ERP_ORDER',
                LABEL: 'Pedido'
            },
            {
                PROPERTY: 'ERP_BILLING',
                LABEL: 'Baixa'
            },
            {
                PROPERTY: 'ERP_REVERSE',
                LABEL: 'Estorno'
            },
            {
                PROPERTY: 'ERP_STATUS',
                LABEL: 'Status integração'
            },
            {
                PROPERTY: 'MATERIALS',
                LABEL: 'OPERATIONAL.MATERIAL'
            }
        ];
        return props;
    }

    private async viewLogProcessDetDem(): Promise<void> {
        try {
            this.formService.block();
            let route = `/processDetDem/${this.$scope.model._id.toString()}/30000/viewLog`;
            const request = await this.$scope.dataProcessService.get(route, null);

            const log: IViewLog = {
                operation: 'history',
                number: this.$scope.model._id.toString(),
                list: [],
                show: true,
                showCloseButton: false,
                searchQuery: '',
                originalList: [],
            }

            log.list = request.data.data;
            log.originalList = angular.copy(log.list);
            this.$scope.log = log;
            this.$scope.customLogProperties = this.getCustomLogProperties();

            const modalId = this.$scope.modalService.newModal();
            this.$scope.modalService.showModalConfirmation(
                {
                    modalID: modalId,
                    scope: this.$scope,
                    template: require('../../common/view/modals/viewLog.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG"
                }
            );
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "Integration Product/Operation" });
        } catch (ex) {
            this.handleError(ex);
        }
    }
}

import * as angular from "angular";
import * as moment from 'moment';
import { fileUploader as FileUploader } from 'angular-file-upload';
import { IModalInstanceService } from "angular-ui-bootstrap";
import { IColumnDef, IGridOptions } from "ui-grid";
import { SSEService } from '@appServices/SSEService';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { ISessionService } from "@services/SessionService";
import { IUploader, IUploadItem, IFileItem, IFormData, ISuccessItemResponse } from "@models/interface/common/IMonacoUpload";
import { IEquipmentSelector } from "@models/interface/product/EquipmentModel";
import { EProductId, EOperation, EDirectionId, EPaymentNatureId, EApplicationComplementId, EChargeOriginId, ETariffChangeConfirmationReasonValue, ETariffChangeConfirmationReasonValidity } from "@enums/GenericData";
import { IInlandRoutes, IEquipmentWeightRangeVehicle } from "@models/interface/product/InlandRoutes";
import { IInlandCharge } from "@models/interface/product/InlandCharge";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { SelectorModel } from "../../common/model/SelectorModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { IInlandContractParameter, ILinkOperationParameter } from "../../common/model/ModelParameter";
import { StringUtil } from "../../common/util/StringUtil";
import { IFloatingMenu } from "../../common/interface/IFloatingMenu";
import { ValidateUtil } from "../../common/util/ValidateUtil";
import { ProductService } from "@services/ProductService";
import { IInlandContract } from "WBA-Model/dist/interface/product/InlandContract";
import { IFormServiceScope, FormService2 } from "@services/FormService2";
import { IParams, IChargeNameList } from "@models/interface/product/ChargeNameModel";
import { ITableOptions } from "src/ts/app/directives/monaco-data-table";
import { IModalService } from '@services/ModalService';
import { IApplicationList } from "@models/interface/product/ApplicationModel";
import { ILegalPersonListCustomFilter } from "../model/LegalPersonModel";
import { IPhysicalPersonListCustomFilter } from "../model/PhysicalPersonModel";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { ITariffChangeConfirmation } from '@models/interface/product/TariffChangeConfirmation';
import { ISelectorModel } from "WBA-Model/dist/mongo/SelectorModel";
import { HelperService } from "@services/HelperService";
import { NotificationService } from "@services/NotificationService";

interface IChargeTableFilter {
    SHOW_ONLY_CURRENT_CHARGES: boolean;
    LOAD_REFERENCE: Date;
}

interface IReasonUpdateModal {
    DESCRIPTION: string[];
    REASON_VALUE_CHANGE: ISelectorModel;
    REASON_VALIDITY_CHANGE: ISelectorModel;
    REASON_OBSERVATION: string;
}

interface IChargeModalScope extends IFormServiceScope {
    currentIndex: number;
    currentDisplayIndex: number;
    charge: IInlandCharge;
    oldCharge: IInlandCharge;
    operation: string;
    chargeApplicationList: SelectorModel[];
    chargeNameList: IChargeNameList[];
    currencyList: SelectorModel[];
    equipmentList: IEquipmentSelector[];
    weightRangeList: SelectorModel[];
    holderList: SelectorModel[];
    isPaymentNature: boolean;
    isReceivingNature: boolean;

    // modal functions
    applyCharge: () => Promise<void>;
    closeChargeModal: () => void;
    hasPreviousCharge: (currentIndex: number) => boolean;
    hasNextCharge: (currentIndex: number) => boolean;
    previousCharge: (currentIndex: number) => void;
    nextCharge: (currentIndex: number) => void;

    // check functions
    isNotApplicableComplement: (applicationComplement: SelectorModel) => boolean;
    isWeightRangeComplement: (applicationComplement: SelectorModel) => boolean;
    isEquipmentComplement: (applicationComplement: SelectorModel) => boolean;
    isVehicleComplement: (applicationComplement: SelectorModel) => boolean;

    // change functions
    updateParam: (selected: IChargeNameList) => void;
    setChargeNameId: () => void;
    flatChange: (chargeIndex: number) => void;
    weightRangeChange: () => void;
    freeOfChargePaymentChange: (charge: IInlandCharge) => void;
    freeOfChargeReceivingChange: (charge: IInlandCharge) => void;

    // get functions
    getChargeNameListByName: (search: string) => Promise<void>;
    getCurrencyListByName: (name: string) => Promise<void>;
    getWeightRangeListByName: (search: string) => Promise<void>;
    getHolderListByName: (search: string, holderId: string) => Promise<void>;

    // general functions
    updatePaymentUnit: () => void;
    updateReceivingUnit: () => void;
    updateHolderTypePayment: () => void;
    updateHolderTypeReceiving: () => void;
    checkChargeDateValidity: (initialDate: Date, finalDate: Date, charge: IInlandCharge, type: string) => void;
}

interface IInlandRoutesScope extends IGridFormServiceScope {
    form: ng.IFormController;
    gridOptions: IGridOptions;
    model: IInlandRoutes;
    preOfferList: SelectorModel[];
    contractModel: IInlandContract;
    scopeBeforeSave: IInlandRoutes;
    customLogProperties: ICustomLogProperties[];
    allogInlandContractList: IInlandContract[];
    cargoTypeList: SelectorModel[];
    routingPointList: SelectorModel[];
    chargeApplicationList: SelectorModel[];
    currencyList: SelectorModel[];
    equipmentList: IEquipmentSelector[];
    routeEquipmentList: IEquipmentSelector[];
    weightRangeList: SelectorModel[];
    modalList: SelectorModel[];
    weekdaysList: SelectorModel[];
    originDestinationTypeList: SelectorModel[];
    holderTypeList: SelectorModel[];
    reasonValueChangeList: SelectorModel[];
    reasonValidityChangeList: SelectorModel[];
    detailsList: SelectorModel[];
    uploader: IUploader;
    user: any;
    menuFloating: IFloatingMenu;
    sessionService: ISessionService;
    isAirProduct: boolean;
    isMaritimeProduct: boolean;
    chargesTableOptions: ITableOptions;
    chargeTableControl: {
        data: IInlandCharge[];
        chargeFilter: IChargeTableFilter;
    };
    chargesListDisplay: IInlandCharge[];
    reasonUpdateModal: IReasonUpdateModal;
    tariffChangeConfirmation: ITariffChangeConfirmation[];
    contractId: string;
    wayDisplayValue: string;
    serviceProviderDisplayValue: string;
    eventList: SelectorModel[];

    editInlandRoutes: (inlandRoutes: IInlandRoutes) => Promise<void>;
    viewInlandRoutes: (inlandRoutes: IInlandRoutes) => Promise<void>;
    viewLogInlandRoutes: (inlandRoutes: IInlandRoutes) => Promise<void>;
    copyInlandRoutes: (inlandRoutes: IInlandRoutes) => Promise<void>;
    removeUpload: (model: IUploadItem) => boolean;
    getAllogInlandContractListByName: (search: string) => Promise<void>;
    updateAllogInlandContract: () => void;
    changeInlandContract: (id: string) => void;
    filterOriginType: () => SelectorModel[];
    filterDestinationType: () => SelectorModel[];
    getRoutingPointListByName: (search: string, type: string) => Promise<void>;
    getEquipmentListByNameOrCode: (search: string) => Promise<void>;
    goToInlandContract: (inlandContract?: string) => void;
    limitLines: (value: string, limit: number) => string;
    formatConcatenatedChars: (value: string) => string;
    collapseHeader: (elementId: string, state?: string, navigate?: boolean) => void;
    generateConcatenated: () => void;
    originDestinationTypeValidate: (fieldName: string) => void;
    showOnlyCurrentChargesChange: () => void;
    chargeFilterLoadReferenceChange: () => void;
    getPreOfferListByName: (search: string) => Promise<void>;

    checkDateValidity: (initialDate: Date, finalDate: Date) => void;
    checkRouteDateValidity: (type: string) => void;
    checkDateIsBetween: (type: string) => void;
    viewLogConfirmation: () => void;
    hasChanges: (newObj: Object, oldObj: Object, propertiesToIgnore?: string[]) => boolean;

    setCopy: (value: boolean) => void;
    fetchData: (id: number, action: string) => Promise<void>;

    addEquipmentOrVehicle: (init: boolean) => void;
    removeEquipmentOrVehicle: (index: number) => void;
}

export class InlandRoutesRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IInlandRoutesScope;
    private $q: ng.IQService;
    private $filter: ng.FilterFactory;
    private $sce: angular.ISCEService;
    private $compile: angular.ICompileService;
    private RestService: IRestService;
    private $timeout: ng.ITimeoutService
    private fileUploader: FileUploader;
    private formName: string;
    private gridName: string;
    private collapseState: string;
    private SSEService: SSEService;
    private isCopy: boolean;
    private ProductService: ProductService;
    private modalChargeId: number;
    private ModalService: IModalService;
    private propertiesToIgnore: string[];
    private modalReasonUpdateId: number;
    private HelperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IInlandRoutesScope) {
        super($injector, $scope);
        this.formName = 'inlandRoutes';
        this.gridName = 'inlandRoutes';
        this.$scope = $scope;
        this.$scope.additionalIndexSelectorValidity = ['$childIndex', '$parentIndex'];
        this.$q = $injector.get('$q');
        this.$filter = $injector.get('$filter');
        this.$sce = $injector.get('$sce');
        this.$compile = $injector.get('$compile');
        this.RestService = $injector.get('RestService');
        this.$timeout = $injector.get('$timeout');
        this.$scope.sessionService = $injector.get('SessionService');
        this.fileUploader = $injector.get('FileUploader');
        this.collapseState = "hide";
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.ProductService = $injector.get('ProductService');
        this.$scope.weekdaysList = [];
        this.modalChargeId = 0;
        this.ModalService = $injector.get('ModalService');
        this.propertiesToIgnore = ["DISPLAY_INDEX", "selected", "uniqueIdControl"];
        this.HelperService = $injector.get('HelperService');

    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    getFisFilesGenericRoute(): string {
        const baseRoute = '/fis/filesGeneric';
        const uploadRoute = this.config.fisUrl + baseRoute;
        return uploadRoute;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.uploader = this.getFileUploaderDefault();
            this.$scope.chargesTableOptions = this.getChargesTableOptions();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.chargeTableControl = { data: null, chargeFilter: { SHOW_ONLY_CURRENT_CHARGES: false, LOAD_REFERENCE: null } };
            this.$scope.$watch('model.CHARGE', (newValue: IInlandCharge[], oldValue: IInlandCharge[]) => {
                if (newValue != oldValue) {
                    this.$scope.chargeTableControl.data = angular.copy(newValue);
                    this.applyChargeFilterTable();
                }
            });

            this.initForm(this, 'form', this.formName, 'ROUTE.ADD_ROUTE', true);
            await this.initGrid(this.gridName, '/inlandRoutes/list', true, true, null, true, true);
            const sessionParameter = this.$gridService.$sessionParameter;
            if (sessionParameter && sessionParameter.data) this.callSessionFunctions(sessionParameter.data);
            this.SSEService.closeEvents();
            this.setCopy(false);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initDependencies(): Promise<any> {
        this.$scope.weekdaysList = this.weekDaysList();

        const self: InlandRoutesRegisterController = this;
        return new Promise(function (resolve, reject) {
            self.initDateFields();
            self.initCollapseEvents();

            self.$q.all([
                self.getGenericList('process_event_transport_mode'), //0
                self.getGenericList('type_local_event'), //1
                self.getGenericList('holder_type'), //2
                self.getGenericList('tariff_change_confirmation_reason_value'), //3
                self.getGenericList('tariff_change_confirmation_reason_validity'), //4
                self.getGenericList('inland_details'), //5
                self.getGenericList('event'), //6
            ]).then((result: any) => {
                self.$scope.modalList = result[0];
                self.$scope.originDestinationTypeList = result[1];
                self.$scope.holderTypeList = result[2];
                self.$scope.reasonValueChangeList = result[3];
                self.$scope.reasonValidityChangeList = result[4];
                self.$scope.detailsList = result[5];
                self.$scope.eventList = result[6];

                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getGenericList(type: string, alternative: boolean = false): Promise<SelectorModel[]> {
        const { data: generic } = await this.HelperService.get(`/generic/value/${type}/${alternative}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editInlandRoutes = async (inlandRoutes: IInlandRoutes): Promise<void> => {
            let blockedObject = {
                ID: inlandRoutes.ID,
                NAME: inlandRoutes.CONCATENATED && inlandRoutes.CONCATENATED !== '' ? inlandRoutes.CONCATENATED : this.gridName + " - " + inlandRoutes.ID,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || inlandRoutes.ID !== this.$scope.model.ID) this.$scope.fetchData(inlandRoutes.ID, EOperation.VIEW);
                } else if (this.$scope.operation !== EOperation.EDIT || inlandRoutes.ID !== this.$scope.model.ID) {
                    this.$scope.fetchData(inlandRoutes.ID, EOperation.EDIT);
                }
            };
        }

        this.$scope.viewInlandRoutes = async (inlandRoutes: IInlandRoutes): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(inlandRoutes.ID, EOperation.VIEW);
        }

        this.$scope.viewLogInlandRoutes = async (inlandRoutes: IInlandRoutes): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(inlandRoutes);
        }

        this.$scope.copyInlandRoutes = async (inlandRoutes: IInlandRoutes): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(inlandRoutes.ID, EOperation.COPY);
        }

        this.$scope.limitLines = (value: string, limit: number) => {
            return StringUtil.limitLines(value, limit);
        }
        this.$scope.formatConcatenatedChars = (value: string) => {
            return StringUtil.formatConcatenatedChars(value);
        }
        this.$scope.generateConcatenated = (): void => {
            this.generateConcatenated();
        }
        this.$scope.getAllogInlandContractListByName = (search: string): Promise<void> => {
            return this.getAllogInlandContractListByName(search);
        }
        this.$scope.updateAllogInlandContract = (): void => {
            this.updateAllogInlandContract();
        }
        this.$scope.changeInlandContract = (id: string): void => {
            this.changeInlandContract(id);
        }
        this.$scope.filterOriginType = (): SelectorModel[] => {
            return this.filterOriginType();
        }
        this.$scope.filterDestinationType = (): SelectorModel[] => {
            return this.filterDestinationType();
        }
        this.$scope.getRoutingPointListByName = async (search: string, type: string): Promise<void> => {
            return await this.getRoutingPointListByName(search, type);
        }
        this.$scope.getEquipmentListByNameOrCode = async (search: string): Promise<void> => {
            return await this.getEquipmentListByNameOrCode(search);
        }
        this.$scope.goToInlandContract = (inlandContract?: string) => {
            inlandContract ? this.$scope.sessionService.openTab("app.product.inlandContract", <IInlandContractParameter>{ ALLOG_INLAND_CONTRACT: inlandContract }) :
                this.$scope.sessionService.openTab("app.product.inlandContract", <IInlandContractParameter>{});
        }
        this.$scope.removeUpload = (model: IUploadItem): boolean => {
            return this.removeUpload(model);
        }
        this.$scope.uploader.onSuccessItem = (item: IFileItem, response: ISuccessItemResponse): void => {
            this.onSuccessItem(item, response);
        }
        this.$scope.collapseHeader = (elementId: string, state?: string, navigate?: boolean): void => {
            this.collapseHeader(elementId, state, navigate);
        }
        this.$scope.originDestinationTypeValidate = (fieldName: string): void => {
            this.originDestinationTypeValidate(fieldName);
        }
        this.$scope.showOnlyCurrentChargesChange = () => {
            if (this.$scope.chargeTableControl) {
                this.$scope.chargeTableControl.chargeFilter.LOAD_REFERENCE = this.$scope.chargeTableControl.chargeFilter.SHOW_ONLY_CURRENT_CHARGES ? new Date() : null;
                this.applyChargeFilterTable();
            }
        }
        this.$scope.chargeFilterLoadReferenceChange = () => {
            const loadReference = moment(this.$scope.chargeTableControl.chargeFilter.LOAD_REFERENCE, 'DD/MM/YYYY');
            if (!this.$scope.chargeTableControl.chargeFilter.LOAD_REFERENCE || loadReference.isValid()) this.applyChargeFilterTable();
        }
        this.$scope.getPreOfferListByName = async (search: string): Promise<void> => {
            return await this.getPreOfferListByName(search);
        }
        this.$scope.viewLogConfirmation = () => {
            this.viewLogConfirmation();
        }
        this.$scope.hasChanges = (newObj: Object[], oldObj: Object[], propertiesToIgnore?: string[]) => {
            return this.hasChanges(newObj, oldObj, propertiesToIgnore);
        }
        this.$scope.checkDateValidity = (initialDate: Date, finalDate: Date): void => {
            this.checkDateValidity(initialDate, finalDate);
        }
        this.$scope.checkRouteDateValidity = (type: string): void => {
            this.checkRouteDateValidity(type);
        }
        this.$scope.checkDateIsBetween = (): void => {
            this.checkDateIsBetween();
        }
        this.$scope.setCopy = (value: boolean): void => {
            this.setCopy(value);
        }
        this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
            this.fetchData(id, action);
        }

        this.$scope.addEquipmentOrVehicle = (init: boolean) => {
            if (init) {
                if (!this.$scope.model.EQUIPMENT_WEIGHT_RANGE_VEHICLE) this.$scope.model.EQUIPMENT_WEIGHT_RANGE_VEHICLE = [];
            } else {
                this.addEquipmentOrVehicle();
            }
        }

        this.$scope.removeEquipmentOrVehicle = (index: number): void => {
            this.removeEquipmentOrVehicle(index);
        }
    }

    initGridColumns(columns: string[]): IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<a ng-click="grid.appScope.viewInlandRoutes(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true"><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editInlandRoutes(row.entity)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true"><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogInlandRoutes(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true"><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.setCopy(true);grid.appScope.copyInlandRoutes(row.entity)" class="text-orange copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true"><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const contract = `<a ng-click="grid.appScope.goToInlandContract(row.entity.INLAND_CONTRACT.NAME)" class="text-yellow edit-btn-action-bar" tooltip-placement="auto top"  uib-tooltip="Click view Inland Contract"  tooltip-append-to-body="true"><i class="fa fa-handshake-o icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes", displayName: "GENERAL.ACTIONS",
            width: 125,
            cellTemplate: `<div class="text-center view-btn-action-bar"> ${view} ${edit} ${copy} ${contract} ${viewLog}</div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };

        gridColumns.addColumn(colActions);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colConcatenated: IMonacoColumnDef = { name: "CONCATENATED", displayName: "GENERAL.CONCATENATED", width: 300 };
            const colTransaction: IMonacoColumnDef = { name: "TRANSACTION.NAME", displayName: "BASIC_DATA.TRANSACTION", width: 100 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 100 };
            const colWay: IMonacoColumnDef = { name: "WAY.NAME", displayName: "PRODUCT.WAY", width: 150 };
            const colCargoType: IMonacoColumnDef = { name: "CARGO_TYPE.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 120 };
            const colCompanyType: IMonacoColumnDef = { name: "COMPANY_TYPE.NAME", displayName: "ENTITY.COMPANY_TYPE", width: 120 };
            const colServiceProvider: IMonacoColumnDef = { name: "SERVICE_PROVIDER.NAME", displayName: "BASIC_DATA.SERVICE_PROVIDER", width: 150 };
            const colAccount: IMonacoColumnDef = { name: "ACCOUNT.NAME", displayName: "GENERAL.ACCOUNT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNT, null, "NAME")}}</div>' };
            const colSpot: IMonacoColumnDef = { name: "SPOT", displayName: "PRODUCT.SPOT", width: 80, cellFilter: "YesOrNo" };
            const colPreOffer: IMonacoColumnDef = { name: "PRE_OFFER.NAME", displayName: "BASIC_DATA.PRE_OFFER", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PRE_OFFER, null, "NAME")}}</div>' };
            const colAllogContract: IMonacoColumnDef = { name: "INLAND_CONTRACT.NAME", displayName: "PRODUCT.ALLOG_INLAND_CONTRACT", width: 200, cellTemplate: '<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="Click view Inland Contract" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToInlandContract(row.entity.INLAND_CONTRACT.NAME)">{{row.entity.INLAND_CONTRACT.NAME}}</a></div>' };
            const colProviderContract: IMonacoColumnDef = { name: "PROVIDER_INLAND_CONTRACT", displayName: "PRODUCT.PROVIDER_INLAND_CONTRACT", width: 200 };
            const colSituation: IMonacoColumnDef = { name: "SITUATION.NAME", displayName: "GENERAL.SITUATION", width: 120 };

            const colOriginType: IMonacoColumnDef = { name: "ORIGIN_TYPE.NAME", displayName: "ROUTE.ORIGIN_TYPE", width: 120 };
            const colOrigin: IMonacoColumnDef = { name: "ORIGIN.DISPLAY_NAME", displayName: "BASIC_DATA.ORIGIN", width: 300, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ORIGIN, null, "DISPLAY_NAME")}}</div>' };
            const colDestinationType: IMonacoColumnDef = { name: "DESTINATION_TYPE.NAME", displayName: "ROUTE.DESTINATION_TYPE", width: 120 };
            const colDestination: IMonacoColumnDef = { name: "DESTINATION.DISPLAY_NAME", displayName: "BASIC_DATA.DESTINATION", width: 300, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.DESTINATION, null, "DISPLAY_NAME")}}</div>' };
            const colModal: IMonacoColumnDef = { name: "MODAL.NAME", displayName: "GENERAL.MODAL", width: 120 };
            const colTTMin: IMonacoColumnDef = { name: "TRANSIT_TIME_MIN", displayName: "GENERAL.TRANSIT_TIME_MIN", width: 120 };
            const colTTMax: IMonacoColumnDef = { name: "TRANSIT_TIME_MAX", displayName: "GENERAL.TRANSIT_TIME_MAX", width: 120 };
            const colFrequency: IMonacoColumnDef = { name: "SERVICE_FREQUENCY.NAME", displayName: "ROUTE.SERVICE_FREQUENCY", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.SERVICE_FREQUENCY, null, "NAME")}}</div>' };
            const colDTA: IMonacoColumnDef = { name: "DTA", displayName: "REGISTRATION.BONDED_TRUCK", width: 150, cellFilter: 'YesOrNo' };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 120, cellFilter: "YesOrNo", filterCellFiltered: true };
            const colValidityStart: IMonacoColumnDef = { name: "VALIDITY_START", displayName: "PRODUCT.VALIDITY_START", width: 150, cellFilter: "simpleDate" };
            const colValidityEnd: IMonacoColumnDef = { name: "VALIDITY_END", displayName: "PRODUCT.VALIDITY_END", width: 150, cellFilter: "simpleDate" };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'ORIGIN_TYPE':
                        columnDefs.push(colOriginType);
                        break;
                    case 'ORIGIN':
                        columnDefs.push(colOrigin);
                        break;
                    case 'DESTINATION_TYPE':
                        columnDefs.push(colDestinationType);
                        break;
                    case 'DESTINATION':
                        columnDefs.push(colDestination);
                        break;
                    case 'MODAL':
                        columnDefs.push(colModal);
                        break;
                    case 'TRANSIT_TIME_MIN':
                        columnDefs.push(colTTMin);
                        break;
                    case 'TRANSIT_TIME_MAX':
                        columnDefs.push(colTTMax);
                        break;
                    case 'SERVICE_FREQUENCY':
                        columnDefs.push(colFrequency);
                        break;
                    case 'TRANSACTION':
                        columnDefs.push(colTransaction);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'WAY':
                        columnDefs.push(colWay);
                        break;
                    case 'CARGO_TYPE':
                        columnDefs.push(colCargoType);
                        break;
                    case 'COMPANY_TYPE':
                        columnDefs.push(colCompanyType);
                        break;
                    case 'SERVICE_PROVIDER':
                        columnDefs.push(colServiceProvider);
                        break;
                    case 'ACCOUNT':
                        columnDefs.push(colAccount);
                        break;
                    case 'SPOT':
                        columnDefs.push(colSpot);
                        break;
                    case 'PRE_OFFER':
                        columnDefs.push(colPreOffer);
                        break;
                    case 'INLAND_CONTRACT':
                        columnDefs.push(colAllogContract);
                        break;
                    case 'PROVIDER_INLAND_CONTRACT':
                        columnDefs.push(colProviderContract);
                        break;
                    case 'SITUATION':
                        columnDefs.push(colSituation);
                        break;
                    case 'CONCATENATED':
                        columnDefs.push(colConcatenated);
                        break;
                    case 'VALIDITY_START':
                        columnDefs.push(colValidityStart);
                        break;
                    case 'VALIDITY_END':
                        columnDefs.push(colValidityEnd);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'DTA':
                        columnDefs.push(colDTA);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                };
            }

            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            ID_INLAND_CONTRACT: null,
            INLAND_CONTRACT: null,
            ORIGIN_TYPE: null,
            ORIGIN: null,
            DESTINATION_TYPE: null,
            DESTINATION: null,
            MODAL: null,
            TRANSIT_TIME_MIN: null,
            TRANSIT_TIME_MAX: null,
            SERVICE_FREQUENCY: null,
            DETAILS: null,
            VALIDITY_START: null,
            VALIDITY_END: null,
            OBSERVATION: null,
            FILES: null,
            FILES_REMOVE: null,
            FILES_NEW: null,
            EQUIPMENT_WEIGHT_RANGE_VEHICLE: [],
            CHARGE: null,
            CONCATENATED: null,
            DTA: false,
            ACTIVE: true,
            VALIDITY_BASIS: null,
            PRE_OFFER: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
        };
    }

    async save(): Promise<boolean> {
        let proceed: boolean = true;
        try {
            if (this.$scope.uploader.queue && this.$scope.uploader.queue.length > 0) {
                const msgError = this.formService.getTranslate('GENERAL.MESSAGES.ERROR.FILES_IN_THE_QUEUE')
                proceed = false;
                this.handleError(msgError);
            }
            this.SSEService.closeEvents();
            this.setCopy(false);
        } catch (ex) {
            this.handleError(ex);
            proceed = false;

        } finally {
            this.customSave();
            return false;
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.operation = EOperation.NEW;
            this.$scope.contractModel = null;
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.scopeBeforeSave = null;
            if (!this.isCopy) this.$scope.contractModel = null

            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.$scope.chargeTableControl.data = [];
            this.initPanels(["collapseBasicData"], "collapseBasicData");
            this.$scope.chargesTableOptions.load(null, true);
            this.$scope.chargesTableOptions.crudButtons.add.disabled = false;
            this.$scope.chargesTableOptions.crudButtons.remove.disabled = false;
            this.$timeout(() => { this.$scope.selectorValidity("origin"); })
            this.changeInlandContract(this.$scope.contractId);
            this.$scope.contractId = null;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
            this.initPanels(["collapseBasicData"], "collapseBasicData");
            BrowserTitle.$id = this.$scope.model.CONCATENATED;
            this.initDateFields();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
            }
            this.$scope.chargesTableOptions.crudButtons.add.disabled = true;
            this.$scope.chargesTableOptions.crudButtons.remove.disabled = true;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        } finally {
            await this.updateAllogInlandContract();
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.CONCATENATED;
            this.initPanels(["collapseBasicData"], "collapseBasicData");
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
            }
            this.$scope.chargesTableOptions.crudButtons.add.disabled = false;
            this.$scope.chargesTableOptions.crudButtons.remove.disabled = false;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            await this.updateAllogInlandContract();
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.COPYING');
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.initPanels(["collapseBasicData"], "collapseBasicData");
            this.$scope.model.ID = null;
            this.$scope.model.CONCATENATED = null;
            await this.clearFields(this.$scope.model, ['CONCATENATED']);
            if (this.$scope.model.CHARGE && this.$scope.model.CHARGE.length > 0) {
                for (const charge of this.$scope.model.CHARGE) {
                    charge.ID = null;
                }
            }
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.chargesTableOptions.crudButtons.add.disabled = false;
            this.$scope.chargesTableOptions.crudButtons.remove.disabled = false;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        } finally {
            await this.updateAllogInlandContract(this.$scope.contractId);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        this.setCopy(false);
        return {
            route: `/inlandRoutes/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    private async finishUpdateControl() {
        delete this.$scope['newRegister'];
        await this.updateGrid();
        this.$scope.showForm = false;
        this.$scope.model = null;
        this.$scope.error = null;
        this.$scope.operation = null;
        this.$scope.$applyAsync();
        this.$formService.notifySuccess(this.formService.getTranslate("REGISTRATION.SUCCESSFUL_REGISTER"));
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
        this.setCopy(false);
    }

    private async callSessionFunctions(data: object): Promise<void> {
        if (!data) return;

        const inlandContractExchangeData = <ILinkOperationParameter>data;

        switch (inlandContractExchangeData.OPERATION) {
            case EOperation.NEW: {
                if (!inlandContractExchangeData.ID) this.$rootScope.breadCrumb.action();
                else await this.newInlandRouteByContract(inlandContractExchangeData.ID.toString());
                break;
            }
        }
    }

    async newInlandRouteByContract(id: string) {
        try {
            this.$scope.contractId = id;
            await this.$scope.register();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async getInlandContractById(id: string): Promise<IInlandContract> {
        this.formService.block();
        if (!id) throw Error('Id is required in getInlandContractById.');
        try {
            const contractID = id;
            const result = await this.ProductService.get({ route: `/inlandContract/getById/${contractID}`, data: { id: contractID } });
            if (result && result.data && result.data.data) {
                const inlandContract: IInlandContract = result.data.data;
                this.setInlandContractScopeDependencies(inlandContract);
                this.checkDateIsBetween();
                return inlandContract;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private setCopy(value: boolean): void {
        this.isCopy = value;
    }

    private initDateFields(): void {
        try {
            this.$scope.model.VALIDITY_START = (this.$scope.model.VALIDITY_START != null) ? new Date(this.$scope.model.VALIDITY_START) : null;
            this.$scope.model.VALIDITY_END = (this.$scope.model.VALIDITY_END != null) ? new Date(this.$scope.model.VALIDITY_END) : null;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'tariff_files', formName: this.formName, folder: this.formName };
            return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData] });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getUrlQuotationCombined(): string {
        const baseRoute = '/quotationCombined';
        const urlQuotationCombined = this.config.quotationCombinedUrl + baseRoute;
        return urlQuotationCombined;
    }

    private async getPreOfferListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 3) {
                this.formService.block();
                const preOffers = await this.RestService.newObjectPromise(`${this.getUrlQuotationCombined()}/preOffer/list/custom`, { search }, 30000, false);
                result = preOffers ? preOffers.map(x => { return { ID: x.ID, NAME: x.PREOFFER_CODE, CODE: x.PREOFFER_CODE ? x.PREOFFER_CODE : null } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.preOfferList = result;
            this.formService.unblock();
        }
    }

    private generateConcatenated(): void {
        try {
            const separator = " | ";
            let concatenated = "";

            if (this.$scope.model.PRE_OFFER && this.$scope.model.PRE_OFFER.length > 0) {
                concatenated = concatenated.length > 0
                    ? concatenated.concat(separator, this.$scope.model.PRE_OFFER.map(obj => { return obj.NAME }).join(','))
                    : concatenated.concat(this.$scope.model.PRE_OFFER.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.contractModel && this.$scope.contractModel.ALLOG_INLAND_CONTRACT && this.$scope.contractModel.ALLOG_INLAND_CONTRACT.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.contractModel.ALLOG_INLAND_CONTRACT) : concatenated.concat(this.$scope.contractModel.ALLOG_INLAND_CONTRACT);
            }

            if (this.$scope.contractModel && this.$scope.contractModel.PROVIDER_INLAND_CONTRACT && this.$scope.contractModel.PROVIDER_INLAND_CONTRACT.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.contractModel.PROVIDER_INLAND_CONTRACT) : concatenated.concat(this.$scope.contractModel.PROVIDER_INLAND_CONTRACT);
            }

            if (this.$scope.contractModel && this.$scope.contractModel.SERVICE_PROVIDER && this.$scope.contractModel.SERVICE_PROVIDER.NAME && this.$scope.contractModel.SERVICE_PROVIDER.NAME.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.contractModel.SERVICE_PROVIDER.NAME) : concatenated.concat(this.$scope.contractModel.SERVICE_PROVIDER.NAME);
            }

            if (this.$scope.contractModel && this.$scope.contractModel.ACCOUNT && this.$scope.contractModel.ACCOUNT.length > 0) {
                concatenated = concatenated.length > 0
                    ? concatenated.concat(separator, this.$scope.contractModel.ACCOUNT.map(obj => { return obj.NAME }).join(','))
                    : concatenated.concat(this.$scope.contractModel.ACCOUNT.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.contractModel && this.$scope.contractModel.PRODUCT && this.$scope.contractModel.PRODUCT.ID && this.$scope.contractModel.PRODUCT.ID.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.contractModel.PRODUCT.ID) : concatenated.concat(this.$scope.contractModel.PRODUCT.ID);
            }

            if (this.$scope.contractModel && this.$scope.contractModel.CARGO_TYPE && this.$scope.contractModel.CARGO_TYPE.NAME) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.contractModel.CARGO_TYPE.NAME) : concatenated.concat(this.$scope.contractModel.CARGO_TYPE.NAME);
            }

            if (this.$scope.contractModel && this.$scope.contractModel.WAY && this.$scope.contractModel.WAY.NAME) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.contractModel.WAY.NAME) : concatenated.concat(this.$scope.contractModel.WAY.NAME);
            }

            if (this.$scope.model.ORIGIN && this.$scope.model.ORIGIN.length > 0) {
                concatenated = concatenated.length > 0
                    ? concatenated.concat(separator, this.$scope.model.ORIGIN.map(obj => { return obj.DISPLAY_NAME }).join(','))
                    : concatenated.concat(this.$scope.model.ORIGIN.map(obj => { return obj.DISPLAY_NAME }).join(','));
            }

            if (this.$scope.model.DESTINATION && this.$scope.model.DESTINATION.length > 0) {
                concatenated = concatenated.length > 0
                    ? concatenated.concat(separator, this.$scope.model.DESTINATION.map(obj => { return obj.DISPLAY_NAME }).join(','))
                    : concatenated.concat(this.$scope.model.DESTINATION.map(obj => { return obj.DISPLAY_NAME }).join(','));
            }

            if (this.$scope.model.DETAILS && this.$scope.model.DETAILS.length) {
                const detail = this.$scope.getCONCAT(this.$scope.model.DETAILS, null, "NAME", null, null, ',')
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, detail) : concatenated.concat(detail);
            }

            if (this.$scope.model.VALIDITY_START) {
                const validity = this.$scope.model.VALIDITY_END ? moment(this.$scope.model.VALIDITY_START).format('DD/MM/YY') + '-' + moment(this.$scope.model.VALIDITY_END).format('DD/MM/YY') : moment(this.$scope.model.VALIDITY_START).format('DD/MM/YY');
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, validity) : concatenated.concat(validity);
            }

            if (this.$scope.contractModel && this.$scope.contractModel.CONCATENATED_COMPLEMENT && this.$scope.contractModel.CONCATENATED_COMPLEMENT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.contractModel.CONCATENATED_COMPLEMENT) : concatenated.concat(this.$scope.contractModel.CONCATENATED_COMPLEMENT);
            }

            this.$scope.model.CONCATENATED = StringUtil.formatConcatenatedChars(concatenated);

            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getAllogInlandContractListByName(search: string): Promise<void> {
        let inlandAllogContracts = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const result = await this.ProductService.post({ route: `/inlandContract/list/custom`, data: { search: search } });
                inlandAllogContracts = result && result.data && result.data.data ? result.data.data.map(x => { return { ID: parseInt(x.ID), NAME: x.ALLOG_INLAND_CONTRACT, CODE: x.ALLOG_INLAND_CONTRACT ? x.ALLOG_INLAND_CONTRACT : null } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.allogInlandContractList = inlandAllogContracts;
            this.formService.unblock();
        }
    }

    private async updateAllogInlandContract(id?: string): Promise<void> {
        try {
            if (this.$scope.model) {
                const contractID = this.$scope.model.INLAND_CONTRACT ? this.$scope.model.INLAND_CONTRACT.ID : id;
                const inlandContract = await this.getInlandContractById(contractID);
                if (inlandContract) {
                    this.$scope.contractId = null;

                    if (this.$scope.model.CHARGE && this.$scope.model.CHARGE.length && this.$scope.contractModel && this.$scope.contractModel.TRANSACTION && this.$scope.contractModel.TRANSACTION.ID && this.$scope.contractModel.TRANSACTION.ID == EPaymentNatureId.RECEIVING) {
                        for (const charge of this.$scope.model.CHARGE) {
                            charge.INLAND_PAYMENT.FREE_OF_CHARGE = true;
                            charge.INLAND_PAYMENT.ID_CURRENCY = null;
                            charge.INLAND_PAYMENT.CURRENCY = null;
                            charge.INLAND_PAYMENT.UNITARY = null;
                            charge.INLAND_PAYMENT.MIN = null;
                            charge.INLAND_PAYMENT.HOLDER_TYPE = null;
                            charge.INLAND_PAYMENT.ID_LEGAL_PERSON_HOLDER = null;
                            charge.INLAND_PAYMENT.LEGAL_PERSON_HOLDER = null;
                            charge.INLAND_PAYMENT.ID_PHYSICAL_PERSON_HOLDER = null;
                            charge.INLAND_PAYMENT.PHYSICAL_PERSON_HOLDER = null;
                            charge.INLAND_PAYMENT.WEIGHT_RANGE_CHARGE = null;
                        }

                        this.$scope.chargeTableControl.data = angular.copy(this.$scope.model.CHARGE);
                        this.applyChargeFilterTable();
                    }
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async changeInlandContract(id: string): Promise<void> {
        if (!id) return
        await this.getInlandContractById(id);
        this.$timeout(() => { this.$scope.selectorValidity("origin"); })
        this.$timeout(() => { this.$scope.selectorValidity("destination"); })
        this.$timeout(() => { this.$scope.selectorValidity("originType"); })
        this.$timeout(() => { this.$scope.selectorValidity("destinationType"); })
        this.$timeout(() => { this.$scope.selectorValidity("allogInlandContract"); })
        this.$timeout(() => { this.$scope.selectorValidity("validityBasis"); })
        this.$timeout(() => { this.$scope.selectorValidity("modalTransport"); })

        this.checkDateIsBetween();
    }


    private async clearAllogInlandContractRelations(): Promise<void> {
        if (this.$scope.model) {
            this.$scope.model.DESTINATION = null;
            this.$scope.model.DESTINATION_TYPE = null;
            this.$scope.model.ORIGIN = null;
            this.$scope.model.ORIGIN_TYPE = null;
            this.$scope.model.MODAL = null;
        }
    }

    private filterOriginType(): SelectorModel[] {
        let result: SelectorModel[] = [];
        try {
            if (this.$scope.originDestinationTypeList && this.$scope.originDestinationTypeList.length > 0 && this.$scope.contractModel && this.$scope.contractModel.WAY && this.$scope.contractModel.PRODUCT) {
                if (this.$scope.contractModel.WAY.ID == EDirectionId.ORIGIN && (this.$scope.isMaritimeProduct || this.$scope.isAirProduct)) {
                    result = this.$scope.originDestinationTypeList.filter(el => el.ID == '3' || el.ID == '5');

                } else if (this.$scope.contractModel.WAY.ID == EDirectionId.DESTINATION && this.$scope.isMaritimeProduct) {
                    result = this.$scope.originDestinationTypeList.filter(el => el.ID == '2' || el.ID == '5');

                } else if (this.$scope.contractModel.WAY.ID == EDirectionId.DESTINATION && this.$scope.isAirProduct) {
                    result = this.$scope.originDestinationTypeList.filter(el => el.ID == '4' || el.ID == '5');
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return result;
        }
    }

    private filterDestinationType(): SelectorModel[] {
        let result: SelectorModel[] = [];
        try {
            if (this.$scope.originDestinationTypeList && this.$scope.originDestinationTypeList.length > 0 && this.$scope.contractModel && this.$scope.contractModel.WAY && this.$scope.contractModel.PRODUCT) {
                if (this.$scope.model && this.$scope.contractModel.WAY.ID == EDirectionId.ORIGIN && this.$scope.isMaritimeProduct) {
                    if (this.$scope.model.ORIGIN_TYPE && this.$scope.model.ORIGIN_TYPE.ID == '3') {
                        result = this.$scope.originDestinationTypeList.filter(el => el.ID == '2' || el.ID == '5');
                    } else {
                        result = this.$scope.originDestinationTypeList.filter(el => el.ID == '2');
                    }
                } else if (this.$scope.model && this.$scope.contractModel.WAY.ID == EDirectionId.ORIGIN && this.$scope.isAirProduct) {
                    if (this.$scope.model.ORIGIN_TYPE && this.$scope.model.ORIGIN_TYPE.ID == '3') {
                        result = this.$scope.originDestinationTypeList.filter(el => el.ID == '4' || el.ID == '5');
                    } else {
                        result = this.$scope.originDestinationTypeList.filter(el => el.ID == '4');
                    }

                } else if (this.$scope.model && this.$scope.contractModel.WAY.ID == EDirectionId.DESTINATION && this.$scope.isMaritimeProduct) {
                    if (this.$scope.model.ORIGIN_TYPE && this.$scope.model.ORIGIN_TYPE.ID == '2') {
                        result = this.$scope.originDestinationTypeList.filter(el => el.ID == '3' || el.ID == '5');
                    } else {
                        result = this.$scope.originDestinationTypeList.filter(el => el.ID == '3');
                    }

                } else if (this.$scope.model && this.$scope.contractModel.WAY.ID == EDirectionId.DESTINATION && this.$scope.isAirProduct) {
                    if (this.$scope.model.ORIGIN_TYPE && this.$scope.model.ORIGIN_TYPE.ID == '4') {
                        result = this.$scope.originDestinationTypeList.filter(el => el.ID == '3' || el.ID == '5');
                    } else {
                        result = this.$scope.originDestinationTypeList.filter(el => el.ID == '3');
                    }
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return result;
        }
    }

    private async getRoutingPointListByName(search: string, type: string): Promise<void> {
        let result = [];
        try {
            if (!type) throw Error('type is null');

            if (search && search.length >= 2) {
                this.formService.block();

                let types = [];
                if (type == 'origin') {
                    if (!this.$scope.model || !this.$scope.model.ORIGIN_TYPE || !this.$scope.model.ORIGIN_TYPE.ID) {
                        throw Error('Select origin type first');
                    }
                    types = [this.$scope.model.ORIGIN_TYPE.ID];
                } else if (type == 'destination') {
                    if (!this.$scope.model || !this.$scope.model.DESTINATION_TYPE || !this.$scope.model.DESTINATION_TYPE.ID) {
                        throw Error('Select destination type first');
                    }
                    types = [this.$scope.model.DESTINATION_TYPE.ID];
                }
                const routingPoints = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/routingPoint/list/custom/`, { name: search, types }, 30000, false);
                result = routingPoints ? routingPoints.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, DISPLAY_NAME: x.DISPLAY_NAME } }) : [];

            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.routingPointList = result;
            this.formService.unblock();
        }
    }

    private async getEquipmentListByNameOrCode(search?: string): Promise<void> {
        let equipmentList: IEquipmentSelector[] = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();
                const equipments = await this.ProductService.post({ route: '/equipment/list/custom', data: { search: search, products: [], sysConvertIdToString: false } }, false);

                if (equipments && equipments.data && equipments.data.data && equipments.data.data.length) {
                    const result: IEquipmentSelector[] = equipments.data.data
                    equipmentList = result.map(equipment => { return { ID: equipment.ID, NAME: equipment.NAME, CODE: equipment.CODE, TEU: equipment.TEU, FEET: equipment.FEET } });
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.$scope.equipmentList = equipmentList;
            this.formService.unblock();
        }
    }

    private removeUpload(model: IUploadItem): boolean {
        let result = false;
        try {
            if (!model) throw Error('item parameter is null');

            const isAdminOrCoordinator = (this.$scope.user.roles && (this.$scope.user.roles.hasOwnProperty('ADMIN') || this.$scope.user.roles.hasOwnProperty('COORDINATOR')));

            if (isAdminOrCoordinator) {
                result = true;
            } else {
                // check if in model.FILES_NEW;
                if (this.$scope.model.FILES_NEW && this.$scope.model.FILES_NEW.length > 0) {
                    const index = this.$scope.model.FILES_NEW.findIndex(file => { return file.FILE_HASH == model.FILE_HASH });
                    if (index > -1) {
                        // Remove from FILES_NEW
                        this.$scope.model.FILES_NEW.splice(index, 1);
                        result = true;
                    }
                }
            }

            if (result) {
                if (!this.$scope.model.FILES_REMOVE) this.$scope.model.FILES_REMOVE = [];
                this.$scope.model.FILES_REMOVE.push(model);
            } else {
                throw Error('You don\'t have permission to remove this file');
            }
        } catch (ex) {
            result = false;
            this.handleError(ex);
        } finally {
            return result;
        }
    }

    private onSuccessItem(item: IFileItem, response: ISuccessItemResponse): void {
        try {
            if (!item) throw Error('item parameter is null');
            if (!response) throw Error('response parameter is null');

            if (response.data && response.data.length > 0) {
                if (!this.$scope.model.FILES_NEW) this.$scope.model.FILES_NEW = [];

                for (let dataItem of response.data) {
                    const file: IUploadItem = {
                        FILE_DISPLAY_NAME: dataItem.displayFileName,
                        FILE_NAME: dataItem.fileName,
                        FILE_TYPE: dataItem.fileType,
                        FILE_PATH: dataItem.filePath,
                        FILE_URL: dataItem.fileUrl,
                        FILE_HASH: dataItem.fileHash,
                        FILE_SIZE: dataItem.fileSize,
                        FORM_NAME: dataItem.formName
                    }
                    this.$scope.model.FILES_NEW.push(file)
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private checkDateValidity(initialDate: Date, finalDate: Date): void {
        let isValid = false;
        if (!initialDate || typeof initialDate == "string") return;
        if (!finalDate || typeof finalDate == "string") return;
        isValid = ValidateUtil.isValidDateRange(initialDate, finalDate);
        if (!isValid) {
            this.$scope.model.VALIDITY_END = null;
        }
    }

    private checkDateIsBetween(): void {
        this.checkDateValidity(this.$scope.model.VALIDITY_START, this.$scope.model.VALIDITY_END);
        const contractStart = this.$scope.contractModel && this.$scope.contractModel.VALIDITY_START ? moment(this.$scope.contractModel.VALIDITY_START) : null;
        const contractEnd = this.$scope.contractModel && this.$scope.contractModel.VALIDITY_END ? moment(this.$scope.contractModel.VALIDITY_END) : null;

        const routeStart = this.$scope.model.VALIDITY_START ? moment(this.$scope.model.VALIDITY_START) : null;
        const routeEnd = this.$scope.model.VALIDITY_END ? moment(this.$scope.model.VALIDITY_END) : null;

        const format = 'DD/MM/YYYY';

        if (contractStart && routeStart && !routeStart.isBetween(contractStart, contractEnd) && (!routeStart.isSameOrAfter(contractStart) || !routeStart.isSameOrBefore(contractEnd))) {
            this.$scope.model.VALIDITY_START = null;

            NotificationService().error(`The route's start date (${moment(routeStart).format(format)}) is not between the contract start date`);
        }

        if (contractEnd && routeEnd && !routeEnd.isBetween(contractStart, contractEnd) && (!routeEnd.isSameOrAfter(contractStart) || !routeEnd.isSameOrBefore(contractEnd))) {
            this.$scope.model.VALIDITY_END = null;

            NotificationService().error(`The route end date (${moment(routeEnd).format(format)}) is not between the contract start date`);
        }

    }

    private checkRouteDateValidity(type: string): void {
        this.checkDateValidity(this.$scope.model.VALIDITY_START, this.$scope.model.VALIDITY_END);

        const format = 'DD/MM/YYYY';

        if (type == 'START') {
            const contractStart = this.$scope.contractModel && this.$scope.contractModel.VALIDITY_START ? moment(this.$scope.contractModel.VALIDITY_START) : null;
            const routeStart = this.$scope.model.VALIDITY_START ? moment(this.$scope.model.VALIDITY_START) : null;

            if (contractStart && routeStart && routeStart.isBefore(contractStart)) {
                this.$scope.model.VALIDITY_START = null;

                NotificationService().error(`The route's start date (${moment(routeStart).format(format)}) is less than the contract's start date (${moment(contractStart).format(format)})`);
            }
        }

        if (type == 'END') {
            const contractEnd = this.$scope.contractModel && this.$scope.contractModel.VALIDITY_END ? moment(this.$scope.contractModel.VALIDITY_END) : null;
            const routeEnd = this.$scope.model.VALIDITY_END ? moment(this.$scope.model.VALIDITY_END) : null;

            if (contractEnd && routeEnd && routeEnd.isAfter(contractEnd)) {
                this.$scope.model.VALIDITY_END = null;

                NotificationService().error(`The route end date (${moment(routeEnd).format(format)}) is greater than the contract end date (${moment(contractEnd).format(format)})`);
            }
        }
    }

    private checkChargeDateValidity(initialDate: Date, finalDate: Date, charge: IInlandCharge, type: string): void {
        this.checkDateValidity(initialDate, finalDate);

        const format = 'DD/MM/YYYY';

        if (type == 'START') {
            const routeStart = this.$scope.model && this.$scope.model.VALIDITY_START ? moment(this.$scope.model.VALIDITY_START) : null;
            const chargeStart = charge.VALIDITY_START ? moment(charge.VALIDITY_START) : null;

            if (routeStart && chargeStart && chargeStart.isBefore(routeStart)) {
                charge.VALIDITY_START = null;

                NotificationService().error(`The charge start date (${moment(chargeStart).format(format)}) is less than the route start date (${moment(routeStart).format(format)})`);
            }
        }

        if (type == 'END') {
            const routeEnd = this.$scope.model && this.$scope.model.VALIDITY_END ? moment(this.$scope.model.VALIDITY_END) : null;
            const chargeEnd = charge.VALIDITY_END ? moment(charge.VALIDITY_END) : null;

            if (routeEnd && chargeEnd && chargeEnd.isAfter(routeEnd)) {
                charge.VALIDITY_END = null;

                NotificationService().error(`The charge end date (${moment(chargeEnd).format(format)}) is greater than the route end date (${moment(routeEnd).format(format)})`);
            }
        }
    }

    private originDestinationTypeValidate(fieldName: string): void {
        try {
            if (!this.$scope.model.INLAND_CONTRACT) {
                this.$scope.model.ORIGIN_TYPE = null;
                this.$scope.model.DESTINATION_TYPE = null;
                throw Error('Select Inland Contract first');
            }
            if (fieldName == "originType") {
                this.$scope.model.ORIGIN = null;
            } else if (fieldName == "destinationType") {
                this.$scope.model.DESTINATION = null;
            }
            this.$timeout(() => { this.$scope.selectorValidity("origin"); })
            this.$timeout(() => { this.$scope.selectorValidity("destination"); })
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private setProductScope(product: SelectorModel): void {
        this.$scope.isAirProduct = product && (product.ID == EProductId.AIR_IMPORT || product.ID == EProductId.AIR_EXPORT);
        this.$scope.isMaritimeProduct = product && (product.ID == EProductId.MARITIME_IMPORT || product.ID == EProductId.MARITIME_EXPORT);
    }

    private setInlandContractScopeDependencies(inlandContract: IInlandContract): void {
        const oldInlandContract = this.$scope.contractModel;
        this.$scope.contractModel = inlandContract;
        const product = inlandContract.PRODUCT;

        this.generateConcatenated();
        this.setProductScope(product);

        this.$scope.model.INLAND_CONTRACT = { ID: inlandContract.ID.toString(), NAME: inlandContract.ALLOG_INLAND_CONTRACT, CODE: inlandContract.ALLOG_INLAND_CONTRACT };

        if (inlandContract && oldInlandContract && (inlandContract.PRODUCT.ID != oldInlandContract.PRODUCT.ID || inlandContract.WAY.ID != oldInlandContract.WAY.ID)) this.clearAllogInlandContractRelations();

        if (inlandContract.WAY) {
            this.$scope.wayDisplayValue = inlandContract.WAY.ID + ' - ' + inlandContract.WAY.NAME;
        } else {
            this.$scope.wayDisplayValue = '';
        }
        if (inlandContract.SERVICE_PROVIDER) {
            this.$scope.serviceProviderDisplayValue = inlandContract.SERVICE_PROVIDER.CODE + ' - ' + inlandContract.SERVICE_PROVIDER.NAME;
        } else {
            this.$scope.serviceProviderDisplayValue = '';
        }

        this.$scope.model.VALIDITY_BASIS = inlandContract.VALIDITY_BASIS;
    }

    private getChargesTableOptions(): ITableOptions {
        return {
            persistName: "tariffLocalCharges",
            pagination: false,
            search: true,
            advancedSearch: false,
            showSearchClearButton: true,
            clickToSelect: true,
            singleSelect: false,
            showLoading: true,
            showColumns: true,
            showColumnsSearch: true,
            showColumnsToggleAll: true,
            showFullscreen: true,
            showToggle: false,
            showMultiSort: true,
            showMultiSortButton: true,
            height: '100%',
            filterControl: false,
            fixedColumns: true,
            fixedNumber: 3,
            sortable: true,
            serverSort: false,
            crudButtons: {
                validityRoute: { fn: async () => { this.validityRouteCharge(); }, name: "validityRouteCharge" },
                add: { fn: async () => { this.addCharge(); }, name: "addCharge" },
                edit: { fn: async (index: number, displayIndex: number) => { this.editCharge(index, displayIndex); }, name: "editCharge" },
                copy: { fn: async (index: number, displayIndex: number) => { this.copyCharge(index); }, name: "copyCharge" },
                remove: { fn: async (index: number) => { this.removeCharge(index); }, name: "removeCharge" }
            },
            customToolbarButtons: [
                { fn: async () => { this.viewChargeLog() }, label: "", name: "log", class: "btn btn-xs btn-success", icon: "fa fa-history", title: "", disabled: this.$scope.operation == EOperation.VIEW }
            ],
            formatAddLevel: () => this.formService.getTranslate("GENERAL.ADD_LEVEL"),
            formatCancel: () => this.formService.getTranslate("GENERAL.CLOSE"),
            formatColumn: () => this.formService.getTranslate("GENERAL.COLUMNS"),
            formatDeleteLevel: () => this.formService.getTranslate("GENERAL.REMOVE_LEVEL"),
            formatMultipleSort: () => this.formService.getTranslate("GENERAL.MULTIPLE_SORT"),
            formatOrder: () => this.formService.getTranslate("GENERAL.ORDER"),
            formatSort: () => this.formService.getTranslate("GENERAL.SORT"),
            formatSortBy: () => this.formService.getTranslate("GENERAL.SORT_BY"),
            formatSortOrders: () => { return { asc: this.formService.getTranslate("GENERAL.ASCENDING_SORT"), desc: this.formService.getTranslate("GENERAL.DESCENDING_SORT") } },
            formatThenBy: () => this.formService.getTranslate("GENERAL.AND_BY"),
            headerStyle: (column: BootstrapTableColumn) => {
                let headerStyleObj = {};
                if (column.field.search("PAYMENT") >= 0) headerStyleObj = { css: { 'background': "#f2dede" } };
                else if (column.field.search("RECEIVING") >= 0) headerStyleObj = { css: { 'background': "#dff0d8" } };
                return headerStyleObj;
            },
            showColumnsRename: [
                { dataField: "CHARGE_NAME_EXHIBITION", title: this.formService.getTranslate("BASIC_DATA.CHARGE") },
                { dataField: "APPLICATION", title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS") },
                { dataField: "APPLICATION.APPLICATION_COMPLEMENT", title: this.formService.getTranslate("PRODUCT.BASIS_COMPLEMENT") },
                { dataField: "INLAND_PAYMENT.CURRENCY", title: this.formService.getTranslate("GENERAL.CURRENCY") },
                { dataField: "INLAND_PAYMENT.UNITARY", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY_C") },
                { dataField: "INLAND_PAYMENT.MIN", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM_C") },
                { dataField: "INLAND_RECEIVING.CURRENCY", title: this.formService.getTranslate("GENERAL.CURRENCY") },
                { dataField: "INLAND_RECEIVING.UNITARY", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY_V") },
                { dataField: "INLAND_RECEIVING.MIN", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM_V") },
                { dataField: "VALIDITY_START", title: this.formService.getTranslate("PRODUCT.VALIDITY_START") },
                { dataField: "VALIDITY_END", title: this.formService.getTranslate("PRODUCT.VALIDITY_END") },
            ],
            onAll: () => {
                if (!this.$scope.$$phase) {
                    const self: InlandRoutesRegisterController = this;
                    this.$scope.$apply(function () {
                        if (self.countCheckSelected() > 1) {
                            self.$scope.chargesTableOptions.crudButtons.edit.disabled = true;
                            self.$scope.chargesTableOptions.crudButtons.copy.disabled = true;
                            self.$scope.chargesTableOptions.crudButtons.remove.disabled = true;
                            self.$scope.chargesTableOptions.crudButtons.validityRoute.disabled = false;
                        } else if (self.countCheckSelected() == 1) {
                            self.$scope.chargesTableOptions.crudButtons.validityRoute.disabled = false;
                        } else {
                            self.$scope.chargesTableOptions.crudButtons.edit.disabled = false;
                            self.$scope.chargesTableOptions.crudButtons.copy.disabled = false;
                            self.$scope.chargesTableOptions.crudButtons.remove.disabled = false;
                            self.$scope.chargesTableOptions.crudButtons.validityRoute.disabled = true;
                        }
                    });
                }

                return true;
            },
            onPostBody: (chargeList: IInlandCharge[]) => {
                for (const index in chargeList) { chargeList[index].DISPLAY_INDEX = parseInt(index); };
                this.$scope.chargesListDisplay = chargeList;
                return true;
            },
            columns: [
                { field: 'selected', title: this.formService.getTranslate("GENERAL.UI_SELECT.SELECT"), checkbox: true, showSelectTitle: true, cellStyle: () => { return { css: { width: '80px' } } } },
                { field: 'CHARGE_NAME_EXHIBITION', title: this.formService.getTranslate("BASIC_DATA.CHARGE"), formatter: (data) => data ? `${data.CODE} - ${data.NAME}` : data, sortable: true, searchable: true },
                { field: 'APPLICATION', title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'APPLICATION.APPLICATION_COMPLEMENT', title: this.formService.getTranslate("PRODUCT.BASIS_COMPLEMENT"), formatter: (application: IApplicationList, charge: IInlandCharge) => this.getChargeApplicationComplementValue(application, charge), sortable: true, searchable: true },
                { field: 'INLAND_PAYMENT.CURRENCY', title: this.formService.getTranslate("GENERAL.CURRENCY"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'INLAND_PAYMENT.UNITARY', title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'INLAND_PAYMENT.MIN', title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'INLAND_RECEIVING.CURRENCY', title: this.formService.getTranslate("GENERAL.CURRENCY"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'INLAND_RECEIVING.UNITARY', title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'INLAND_RECEIVING.MIN', title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'VALIDITY_START', title: this.formService.getTranslate("PRODUCT.VALIDITY_START"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true },
                { field: 'VALIDITY_END', title: this.formService.getTranslate("PRODUCT.VALIDITY_END"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true },
            ],
        };
    }

    private countCheckSelected(): number {
        try {
            const charges = this.$scope.model.CHARGE;
            let counter = 0;

            if (charges && charges.length) {
                for (let i = 0; i < charges.length; i++) {
                    if (charges[i]['selected']) counter++;
                }
            }

            return counter;

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: "CONCATENATED",
                LABEL: "GENERAL.CONCATENATED"
            },
            {
                PROPERTY: "ORIGIN_TYPE",
                LABEL: "ROUTE.ORIGIN_TYPE"
            },
            {
                PROPERTY: "ORIGIN",
                LABEL: "BASIC_DATA.ORIGIN"
            },
            {
                PROPERTY: "DESTINATION_TYPE",
                LABEL: "ROUTE.DESTINATION_TYPE"
            },
            {
                PROPERTY: "DESTINATION",
                LABEL: "BASIC_DATA.DESTINATION"
            },
            {
                PROPERTY: "MODAL",
                LABEL: "GENERAL.MODAL"
            },
            {
                PROPERTY: "TRANSIT_TIME_MIN",
                LABEL: "GENERAL.TRANSIT_TIME_MIN"
            },
            {
                PROPERTY: "TRANSIT_TIME_MAX",
                LABEL: "GENERAL.TRANSIT_TIME_MAX"
            },
            {
                PROPERTY: "SERVICE_FREQUENCY",
                LABEL: "ROUTE.SERVICE_FREQUENCY"
            },
            {
                PROPERTY: "DETAILS",
                LABEL: "GENERAL.DETAILS"
            },
            {
                PROPERTY: "VALIDITY_START",
                LABEL: "PRODUCT.VALIDITY_START"
            },
            {
                PROPERTY: "VALIDITY_END",
                LABEL: "PRODUCT.VALIDITY_END"
            },
            {
                PROPERTY: "FILES",
                LABEL: "REGISTRATION.EMAIL_ATTACHMENT"
            },
            {
                PROPERTY: "FILE_DISPLAY_NAME",
                LABEL: "REGISTRATION.FILE_ATTACHMENT_NAME"
            },
            {
                PROPERTY: "FILE_URL",
                LABEL: "REGISTRATION.FILE_URL"
            },
            {
                PROPERTY: "FILE_TYPE",
                LABEL: "REGISTRATION.FILE_TYPE"
            },
            {
                PROPERTY: "CHARGE",
                LABEL: "GENERAL.CHARGES"
            },
            {
                PROPERTY: "CHARGE_NAME",
                LABEL: "BASIC_DATA.CHARGE"
            },
            {
                PROPERTY: "APPLICATION",
                LABEL: "FINANCIAL.CHARGE_BASIS"
            },

            {
                PROPERTY: "NAME",
                LABEL: "GENERAL.NAME"
            },
            {
                PROPERTY: "CODE",
                LABEL: "GENERAL.CODE"
            },
            {
                PROPERTY: "PERCENT",
                LABEL: "REGISTRATION.PERCENTAGE"
            },
            {
                PROPERTY: "APPLICATION_COMPLEMENT",
                LABEL: "PRODUCT.BASIS_COMPLEMENT"
            },
            {
                PROPERTY: "INTERNAL_SEQUENCE",
                LABEL: "REGISTRATION.INTERNAL_SEQUENCE"
            },
            {
                PROPERTY: "FREE_TYPING_AMOUNT_CHARGE",
                LABEL: "REGISTRATION.FREE_FIELD_CHANGE_QUANTITY"
            },
            {
                PROPERTY: "CT_WITHOUT_DOC",
                LABEL: "REGISTRATION.CONTRIBUTION_WITHOUT_DOCS"
            },
            {
                PROPERTY: "NAME_INTL",
                LABEL: "GENERAL.NAME_INTL"
            },
            {
                PROPERTY: "FLAT",
                LABEL: "REGISTRATION.FLAT"
            },
            {
                PROPERTY: "WEIGHT_RANGE",
                LABEL: "REGISTRATION.WEIGHT_BREAK"
            },
            {
                PROPERTY: "EQUIPMENT",
                LABEL: "BASIC_DATA.EQUIPMENT"
            },
            {
                PROPERTY: "VEHICLE_TYPE",
                LABEL: "GENERAL.VEHICLE_TYPE"
            },
            {
                PROPERTY: "WEIGHT_RANGE_CHARGE",
                LABEL: "GENERAL.MENU.WEIGHT_RANGE"
            },
            {
                PROPERTY: "CURRENCY",
                LABEL: "GENERAL.CURRENCY"
            },
            {
                PROPERTY: "MIN",
                LABEL: "PRODUCT.MIN_PAYMENT"
            },
            {
                PROPERTY: "PAYMENT_UNITARY",
                LABEL: "PRODUCT.UNIT_PAYMENT."
            },
            {
                PROPERTY: "MIN",
                LABEL: "PRODUCT.MIN_RECEIPT."
            },
            {
                PROPERTY: "RECEIVING_UNITARY",
                LABEL: "PRODUCT.UNIT_RECEIPT"
            },
            {
                PROPERTY: "UNITARY",
                LABEL: "BASIC_DATA.UNIT"
            },
            {
                PROPERTY: "FREE_OF_CHARGE",
                LABEL: "PRODUCT.FREE_OF_CHARGE"
            },
            {
                PROPERTY: "HOLDER_TYPE",
                LABEL: "GENERAL.PERSON_TYPE"
            },
            {
                PROPERTY: "LEGAL_PERSON_HOLDER",
                LABEL: "GENERAL.HOLDER"
            },
            {
                PROPERTY: "PHYSICAL_PERSON_HOLDER",
                LABEL: "GENERAL.HOLDER"
            },
            {
                PROPERTY: "INLAND_PAYMENT",
                LABEL: "PRODUCT.BUYING"
            },
            {
                PROPERTY: "INLAND_RECEIVING",
                LABEL: "PRODUCT.SELLING"
            },
            {
                PROPERTY: "CHARGE_NAME_EXHIBITION",
                LABEL: "BASIC_DATA.CHARGE"
            },
            {
                PROPERTY: "TYPE_CARGO",
                LABEL: "BASIC_DATA.CARGO_TYPE"
            },
        ];
        return props;
    }

    private initCollapseEvents() {
        angular.element('#collapseCharges').on('shown.bs.collapse', (event: JQuery.Event) => {
            if (event.target == event.currentTarget) {
                angular.element("#chargeTableFilter").addClass("in");
            }
        });

        angular.element('#collapseCharges').on('hidden.bs.collapse', (event: JQuery.Event) => {
            if (event.target == event.currentTarget) {
                angular.element("#chargeTableFilter").removeClass("in");
            }
        });
    }

    private async viewLogConfirmation(): Promise<void> {
        try {
            this.formService.block();
            const retrieveLog = await this.ProductService.get({ route: `/tariffChangeConfirmation/tabs/charge/getTariffChangeConfirmation/inlandRoutes/${this.$scope.model.ID}` });
            this.formService.unblock();
            if (retrieveLog && retrieveLog.data && retrieveLog.data.data && retrieveLog.data.data.length) {
                this.$scope.tariffChangeConfirmation = retrieveLog.data.data;
                const modalId = this.ModalService.newModal();
                await this.ModalService.showModalConfirmation(
                    {
                        modalID: modalId,
                        scope: this.$scope,
                        template: require('../view/modals/tariffLocalChangeConfirmationLogModal.html'),
                        size: 'vlg modal-overflow'
                    },
                    {
                        closeButtonText: 'GENERAL.CLOSE'
                    }
                );
            } else this.formService.notifyInfo(this.formService.getTranslate("REGISTRATION.NO_RECORD_FOUND"));
        } catch (ex) {
            this.formService.handleError(ex);
            this.formService.unblock();
        }
    }

    private applyChargeFilterTable() {
        if (this.$scope.model) {
            this.$scope.routeEquipmentList = [];
            let chargeList = this.$scope.model.CHARGE
            this.updateRouteChargeList(chargeList);
            const loadReference = moment(moment(this.$scope.chargeTableControl.chargeFilter.LOAD_REFERENCE).format('YYYY-MM-DD'));
            if (loadReference.isValid()) {
                chargeList = chargeList.filter(charge => {
                    const start = moment(moment(charge.VALIDITY_START).format('YYYY-MM-DD'));
                    const end = moment(moment(charge.VALIDITY_END).format('YYYY-MM-DD'));
                    return (loadReference.isBetween(start, end) || loadReference.isSame(start) || loadReference.isSame(end));
                });
            }
            this.$scope.chargeTableControl.data = chargeList;
            this.$scope.chargesTableOptions.load(null, true);
        }
    }

    private validityRouteCharge(): void {
        try {
            const baseDataValidityEnd = this.$scope.model.VALIDITY_END;
            for (const charge of this.$scope.model.CHARGE) {
                if (charge['selected']) {
                    const index = this.$scope.model.CHARGE.indexOf(charge)
                    this.$scope.model.CHARGE[index].VALIDITY_END = new Date(baseDataValidityEnd)
                }
            }
            this.$scope.chargesTableOptions.load(null, true);

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async addCharge(): Promise<void> {
        try {
            if(!this.$scope.contractModel) throw Error('Select Inland Contract first');
            const charge: IInlandCharge = {
                _id: null,
                ID: null,
                ID_INLAND_ROUTE: null,
                DISPLAY_INDEX: null,
                PHYSICAL_PERSON_HOLDER: null,
                ID_CHARGE_NAME_EXHIBITION: null,
                CHARGE_NAME_EXHIBITION: null,
                APPLICATION: null,
                EQUIPMENT: null,
                WEIGHT_RANGE: null,
                FLAT: null,
                VEHICLE_TYPE: null,
                INLAND_PAYMENT: {
                    ID_CURRENCY: null,
                    CURRENCY: null,
                    UNITARY: null,
                    MIN: null,
                    FREE_OF_CHARGE: this.$scope.contractModel.TRANSACTION && this.$scope.contractModel.TRANSACTION.ID == EPaymentNatureId.RECEIVING ? true : false,
                    HOLDER_TYPE: null,
                    ID_LEGAL_PERSON_HOLDER: null,
                    ID_PHYSICAL_PERSON_HOLDER: null,
                    LEGAL_PERSON_HOLDER: null,
                    PHYSICAL_PERSON_HOLDER: null,
                    WEIGHT_RANGE_CHARGE: null
                },
                INLAND_RECEIVING: {
                    ID_CURRENCY: null,
                    CURRENCY: null,
                    UNITARY: null,
                    MIN: null,
                    FREE_OF_CHARGE: false,
                    HOLDER_TYPE: null,
                    ID_LEGAL_PERSON_HOLDER: null,
                    ID_PHYSICAL_PERSON_HOLDER: null,
                    LEGAL_PERSON_HOLDER: null,
                    PHYSICAL_PERSON_HOLDER: null,
                    WEIGHT_RANGE_CHARGE: null
                },
                VALIDITY_START: this.$scope.model.VALIDITY_START ? this.$scope.model.VALIDITY_START : null,
                VALIDITY_END: this.$scope.model.VALIDITY_END ? this.$scope.model.VALIDITY_END : null,
                VALIDITY_BASIS: this.$scope.contractModel.VALIDITY_BASIS ? this.$scope.contractModel.VALIDITY_BASIS : null,
            };

            if (!this.$scope.model.CHARGE) this.$scope.model.CHARGE = [];
            this.openChargeModal(charge);

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private editCharge(index: number, displayIndex: number): void {
        try {
            if (!index && index != 0) throw Error('index is null');

            let charge: IInlandCharge = null;
            charge = this.getRatesByTheCorrectIndex(index);
            if (!charge) throw Error('charge is null');

            this.openChargeModal(charge, this.$scope.model.CHARGE.indexOf(charge), charge.DISPLAY_INDEX);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private copyCharge(index: number): void {
        try {
            if (!index && index != 0) throw Error('index is null');
            const charge: IInlandCharge = this.getRatesByTheCorrectIndex(index);
            if (!charge) throw Error('charge is null');
            const copyCharge = angular.copy(charge);
            copyCharge._id = null;
            copyCharge.ID = null;

            this.openChargeModal(copyCharge);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async openChargeModal(charge: IInlandCharge, index?: number, displayIndex?: number) {
        try {
            let errorMessage: string = '';
            if (!this.$scope.contractModel) throw Error(`Select Inland Contract first`);
            if (!this.$scope.contractModel.TRANSACTION) errorMessage = 'payment nature';
            if (!this.$scope.contractModel.WAY) errorMessage += (errorMessage ? ', ' : '') + 'direction';
            if (!this.$scope.contractModel.CARGO_TYPE) errorMessage += (errorMessage ? ', ' : '') + 'type cargo';
            if (!this.$scope.contractModel.TRANSACTION) errorMessage += (errorMessage ? ', ' : '') + 'product';
            if (errorMessage) throw Error(`Select ${errorMessage} first`);

            this.modalChargeId = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    modalID: this.modalChargeId,
                    scope: this.$scope,
                    formService: 'register',
                    template: require("../view/modals/inlandChargeModal.html"),
                    size: 'vlg',
                    keyboard: true,
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing") {
                            if (reason.toString() == "escape key press") event.preventDefault();
                            if (!closed) {
                                const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
                                modalScope.closeChargeModal();
                            }
                        }
                    }
                },
                null
            );
            modalInstance.rendered.then(async () => {
                const modalScope = await this.buildChargeModalScope(index, displayIndex, charge);
                this.$timeout(() => {
                    modalScope.oldCharge = angular.copy(modalScope.charge);
                });
                modalScope.$applyAsync();

                angular.element('.collapseWeightRanges').on('shown.bs.collapse', (event: JQuery.Event) => {
                    if (event.target == event.currentTarget) {
                        angular.element("#collapsePaymentPanelHeading").attr('aria-expanded', 'true');
                        angular.element("#collapseReceivingPanelHeading").attr('aria-expanded', 'true');
                    }
                });

                angular.element('.collapseWeightRanges').on('hidden.bs.collapse', (event: JQuery.Event) => {
                    if (event.target == event.currentTarget) {
                        angular.element("#collapsePaymentPanelHeading").attr('aria-expanded', 'false');
                        angular.element("#collapseReceivingPanelHeading").attr('aria-expanded', 'false');
                    }
                });

                modalScope.selectorValidity("chargeNameExhibition");
                modalScope.selectorValidity("param");
                modalScope.selectorValidity("weightRange");
                modalScope.selectorValidity("equipment");
                modalScope.selectorValidity("vehicleType");
                modalScope.selectorValidity("validityBasis");
                modalScope.selectorValidity("paymentCurrency");
                modalScope.selectorValidity("receivingCurrency");

                modalScope.checkChargeDateValidity(modalScope.charge.VALIDITY_START, modalScope.charge.VALIDITY_END, modalScope.charge, 'START');
                modalScope.checkChargeDateValidity(modalScope.charge.VALIDITY_START, modalScope.charge.VALIDITY_END, modalScope.charge, 'END');
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async buildChargeModalScope(index: number, displayIndex: number, charge: IInlandCharge): Promise<IChargeModalScope> {
        try {
            const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
            modalScope.operation = this.$scope.operation == EOperation.VIEW ? EOperation.VIEW : (!index && index != 0) ? EOperation.NEW : EOperation.EDIT;
            modalScope.currentIndex = index;
            modalScope.currentDisplayIndex = displayIndex;
            modalScope.charge = angular.copy(charge);
            modalScope.chargeApplicationList = await this.getChargeNameParamsList();
            modalScope.equipmentList = await this.getEquipmentListByName(null);
            modalScope.isPaymentNature = this.$scope.contractModel.TRANSACTION && this.$scope.contractModel.TRANSACTION.ID == EPaymentNatureId.PAYMENT;
            modalScope.isReceivingNature = this.$scope.contractModel.TRANSACTION && this.$scope.contractModel.TRANSACTION.ID == EPaymentNatureId.RECEIVING;

            modalScope.applyCharge = async () => {
                if (await this.applyCharge(modalScope.currentIndex, modalScope.charge, modalScope.operation, true)) {
                    this.$scope.chargeTableControl.data = this.$scope.model.CHARGE;
                    this.applyChargeFilterTable();
                }
            }
            modalScope.closeChargeModal = () => {
                this.closeChargeModal();
            }
            modalScope.hasPreviousCharge = (currentDisplayIndex: number) => {
                return currentDisplayIndex > 0;
            }
            modalScope.hasNextCharge = (currentDisplayIndex: number) => {
                return (currentDisplayIndex || currentDisplayIndex == 0) && this.$scope.chargesListDisplay && (this.$scope.chargesListDisplay.length - 1 > currentDisplayIndex);
            }
            modalScope.previousCharge = (currentDisplayIndex: number) => {
                this.previousCharge(currentDisplayIndex);
            }
            modalScope.nextCharge = (currentDisplayIndex: number) => {
                this.nextCharge(currentDisplayIndex);
            }
            modalScope.isNotApplicableComplement = (applicationComplement: SelectorModel): boolean => {
                return !applicationComplement || (applicationComplement && applicationComplement.ID == EApplicationComplementId.NOT_APPLICABLE);
            }
            modalScope.isWeightRangeComplement = (applicationComplement: SelectorModel): boolean => {
                return applicationComplement && applicationComplement.ID == EApplicationComplementId.WEIGHT_RANGE;
            }
            modalScope.isEquipmentComplement = (applicationComplement: SelectorModel): boolean => {
                return applicationComplement && applicationComplement.ID == EApplicationComplementId.EQUIPMENT;
            }
            modalScope.isVehicleComplement = (applicationComplement: SelectorModel): boolean => {
                return applicationComplement && applicationComplement.ID == EApplicationComplementId.VEHICLE;
            }
            modalScope.updateParam = (selected: IChargeNameList): void => {
                this.updateParam(selected);
            }
            modalScope.setChargeNameId = (): void => {
                this.setChargeNameId();
            }
            modalScope.flatChange = () => {
                this.flatChange();
            }
            modalScope.weightRangeChange = () => {
                this.weightRangeChange();
            }
            modalScope.freeOfChargePaymentChange = (charge: IInlandCharge) => {
                this.freeOfChargePaymentChange(charge);
                this.$timeout(() => { modalScope.selectorValidity("paymentCurrency"); });
            }
            modalScope.freeOfChargeReceivingChange = (charge: IInlandCharge) => {
                this.freeOfChargeReceivingChange(charge);
                this.$timeout(() => { modalScope.selectorValidity("receivingCurrency"); });
            }
            modalScope.getChargeNameListByName = async (search: string): Promise<void> => {
                modalScope.chargeNameList = await this.getChargeNameListByName(search);
            }
            modalScope.getCurrencyListByName = async (name: string): Promise<void> => {
                modalScope.currencyList = await this.getCurrencyListByName(name);
            }
            modalScope.getWeightRangeListByName = async (search: string): Promise<void> => {
                modalScope.weightRangeList = await this.getWeightRangeListByName(search);
            }
            modalScope.getHolderListByName = async (search: string, holderId: string) => {
                let holderList: SelectorModel[] = [];
                if (search && search.length >= 3 && holderId) {
                    holderList = holderId == "1" ? await this.getLegalPersonListByName({ specializations: [], search: search }) : await this.getPhysicalPersonListByName({ specializations: [], roles: [], search: search });
                }
                modalScope.holderList = holderList;
            }
            modalScope.updatePaymentUnit = (): void => {
                this.updatePaymentUnit();
            }
            modalScope.updateReceivingUnit = (): void => {
                this.updateReceivingUnit();
            }
            modalScope.updateHolderTypePayment = (): void => {
                this.updateHolderTypePayment();
            }
            modalScope.updateHolderTypeReceiving = (): void => {
                this.updateHolderTypeReceiving();
            }
            modalScope.checkChargeDateValidity = (initialDate: Date, finalDate: Date, charge: IInlandCharge, type: string): void => {
                this.checkChargeDateValidity(initialDate, finalDate, charge, type);
            }

            return modalScope;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async previousCharge(currentDisplayIndex: number) {
        const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
        const previousDisplayCharge = this.$scope.chargesListDisplay[currentDisplayIndex - 1];
        const previousChargeIndex = this.$scope.model.CHARGE && this.$scope.model.CHARGE.length ? this.$scope.model.CHARGE.findIndex((charge, index) => previousDisplayCharge.ID ? charge.ID == previousDisplayCharge.ID : index == (currentDisplayIndex - 1)) : -1;
        if (previousChargeIndex >= 0) {
            if (this.hasChanges([JSON.stringify(modalScope.charge)], [JSON.stringify(modalScope.oldCharge)])) {
                const confirm = await this.modalSaveConfirmation("REGISTRATION.BACK", "GENERAL.CANCEL");
                if (!confirm || confirm && !await this.applyCharge(modalScope.currentIndex, modalScope.charge, modalScope.operation, false)) return;
            }
            modalScope.currentIndex = previousChargeIndex;
            modalScope.currentDisplayIndex--;

            const chargeUpdated = this.$scope.model.CHARGE[previousChargeIndex];
            if (chargeUpdated) {
                modalScope.charge = angular.copy(chargeUpdated);
                modalScope.oldCharge = angular.copy(chargeUpdated);
            }
            modalScope.$applyAsync();
        }
    }

    private async nextCharge(currentDisplayIndex: number) {
        const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
        const nextDisplayCharge = this.$scope.chargesListDisplay[currentDisplayIndex + 1];
        const nextChargeIndex = nextDisplayCharge && this.$scope.model.CHARGE && this.$scope.model.CHARGE.length ? this.$scope.model.CHARGE.findIndex((charge, index) => nextDisplayCharge.ID ? charge.ID == nextDisplayCharge.ID : index == (currentDisplayIndex + 1)) : -1;
        if (nextChargeIndex >= 0) {
            if (this.$scope.hasChanges([JSON.stringify(modalScope.charge)], [JSON.stringify(modalScope.oldCharge)])) {
                const confirm = await this.modalSaveConfirmation("GENERAL.NEXT", "GENERAL.CANCEL");
                if (!confirm || (confirm && !await this.applyCharge(modalScope.currentIndex, modalScope.charge, modalScope.operation, false))) return;
            }
            modalScope.currentIndex = nextChargeIndex;
            modalScope.currentDisplayIndex++;

            const charge = this.$scope.model.CHARGE[nextChargeIndex];
            if (charge) {
                modalScope.charge = angular.copy(charge);
                modalScope.oldCharge = angular.copy(charge);
            }
            modalScope.$applyAsync();
        }
    }

    private async applyCharge(index: number, charge: IInlandCharge, operation: string, closeModal?: boolean): Promise<boolean> {
        let success = true;
        try {
            if (!charge) throw Error('charge is null');
            const hasInvalid = this.hasInvalidRequiredElements('inlandChargeModal') || !ValidateUtil.isValidDateRange(charge.VALIDITY_START, charge.VALIDITY_END);
            if (hasInvalid) success = false;
            else {
                if (operation == EOperation.NEW) {
                    if (!this.$scope.model.CHARGE) this.$scope.model.CHARGE = [];
                    this.$scope.model.CHARGE.push(charge);
                }
                else if (operation == EOperation.EDIT) {
                    charge.ID_CHARGE_NAME_EXHIBITION = parseInt(charge.CHARGE_NAME_EXHIBITION.ID);
                    this.$scope.model.CHARGE.splice(index, 1, charge);
                }
                if (closeModal) {
                    this.ModalService.closeModal(this.modalChargeId);
                    this.modalChargeId = 0;
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            const msgSuccess = this.formService.getTranslate('FINANCIAL.CHARGE_DATA_SAVED_SUCCESSFULLY');
            if (success) this.formService.notifySuccess(msgSuccess);
            return success;
        }
    }

    private async closeChargeModal() {
        const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
        if (this.$scope.hasChanges(JSON.stringify(modalScope.charge), JSON.stringify(modalScope.oldCharge))) {
            const confirm = await this.modalSaveConfirmation("GENERAL.CLOSE", "GENERAL.CLOSE");
            if (confirm && !await this.applyCharge(modalScope.currentIndex, modalScope.charge, modalScope.operation)) return;
            else {
                if (!modalScope.charge.VALIDITY_START) modalScope.charge.VALIDITY_START = modalScope.oldCharge.VALIDITY_START;
                if (!modalScope.charge.VALIDITY_END) modalScope.charge.VALIDITY_END = modalScope.oldCharge.VALIDITY_END;
                this.$scope.chargesTableOptions.load(null, true);
            }
        }
        this.ModalService.closeModal(this.modalChargeId);
        this.modalChargeId = 0;
    }

    private async removeCharge(index: number): Promise<void> {
        try {
            if (!index && index !== 0) throw Error('index is null');
            const thatTranslated = this.formService.getTranslate("GENERAL.GENDER.THAT", null, true);
            const chargeTranslated = this.formService.getTranslate("BASIC_DATA.CHARGE", null, true);
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thatTranslated, prop: chargeTranslated })
            });

            if (!modal) return;

            this.formService.block();

            if (this.$scope.model.CHARGE) {
                const charge = this.getRatesByTheCorrectIndex(index);
                this.$scope.model.CHARGE.splice(this.$scope.model.CHARGE.indexOf(charge), 1);
                this.$scope.chargeTableControl.data = this.$scope.model.CHARGE;
                this.$scope.chargesTableOptions.load(null, true);
            }

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async viewChargeLog(): Promise<void> {
        try {
            this.formService.block();
            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/inlandRoutes/tabs/charge/viewLog/${this.$scope.model.ID.toString()}/CHARGES`, 30000, null, false);
            const log: IViewLog = {
                operation: 'history',
                number: this.$scope.model.ID.toString(),
                list: [],
                show: true,
                showCloseButton: false,
                searchQuery: '',
                originalList: [],
            }
            log.list = request.data;
            log.originalList = angular.copy(log.list);

            const modalId = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    modalID: modalId,
                    template: require('../../common/view/modals/viewLog.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG"
                }
            );
            modalInstance.opened.then(async () => {
                const modalScope = await this.ModalService.getModalScope(modalId);
                modalScope.customLogProperties = this.$scope.customLogProperties;
                modalScope.log = log;
                modalScope.$applyAsync();
            });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private getChargeApplicationComplementValue(applicationComplement: IApplicationList, charge: IInlandCharge): string {
        let complementValue: string = "";
        if (applicationComplement.ID == EApplicationComplementId.EQUIPMENT) complementValue = this.$scope.getCONCAT(charge.EQUIPMENT, null, "CODE");
        else if (applicationComplement.ID == EApplicationComplementId.WEIGHT_RANGE) complementValue = this.$scope.getCONCAT(charge.WEIGHT_RANGE);
        else if (applicationComplement.ID == EApplicationComplementId.VEHICLE) complementValue = this.$scope.getCONCAT(charge.VEHICLE_TYPE);
        return complementValue;
    }

    private async getChargeNameParamsList(): Promise<SelectorModel[]> {
        let result: IApplicationList[] = [];
        try {
            this.formService.block();
            const products = this.$scope.contractModel.PRODUCT ? [this.$scope.contractModel.PRODUCT.ID] : null;
            const paramsTypeCargo = this.$scope.contractModel.CARGO_TYPE ? [this.$scope.contractModel.CARGO_TYPE.ID] : null;
            const request = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/application/list/custom`, { products: products, typeCargos: paramsTypeCargo }, 30000, false);
            result = request ? request.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PERCENT: x.PERCENT, APPLICATION_COMPLEMENT: x.APPLICATION_COMPLEMENT, INTERNAL_SEQUENCE: x.INTERNAL_SEQUENCE, FREE_TYPING_AMOUNT_CHARGE: x.FREE_TYPING_AMOUNT_CHARGE, CT_WITHOUT_DOC: x.CT_WITHOUT_DOC, NAME_INTL: x.NAME_INTL } }) : [];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async setChargeNameId(): Promise<void> {
        const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
        if (!modalScope.charge) throw Error('charge is null');
        if (modalScope.charge.CHARGE_NAME_EXHIBITION) {
            modalScope.charge.ID_CHARGE_NAME_EXHIBITION = parseInt(modalScope.charge.CHARGE_NAME_EXHIBITION.ID)
        }
    }

    private async updateParam(selected: IChargeNameList): Promise<void> {
        let param: IParams = null;
        try {
            if (!selected) throw Error('selected is null');

            const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
            if (!modalScope.charge) throw Error('charge is null');

            const isPaymentNature = this.$scope.contractModel.TRANSACTION && this.$scope.contractModel.TRANSACTION.ID == EPaymentNatureId.PAYMENT;
            if (selected.PARAMS && selected.PARAMS.length > 0) param = selected.PARAMS.find(param => param.TYPE_CARGO.find(typeCargo => typeCargo.ID === this.$scope.contractModel.CARGO_TYPE.ID));
            if (param && this.$scope.contractModel.TRANSACTION) {
                modalScope.charge.APPLICATION = isPaymentNature ? param.PAYMENT : param.RECEIVING;
                this.$timeout(() => { modalScope.selectorValidity('param'); }, 100);
            }

            // SCOB Application behavior
            const isScobApplication = modalScope.charge.APPLICATION && modalScope.charge.APPLICATION.INTERNAL_SEQUENCE == "001";
            if (isScobApplication) {
                modalScope.charge.INLAND_PAYMENT.FREE_OF_CHARGE = true;
                modalScope.charge.INLAND_RECEIVING.FREE_OF_CHARGE = true;
                this.freeOfChargePaymentChange(modalScope.charge);
                this.freeOfChargeReceivingChange(modalScope.charge);
            } else if (isPaymentNature) modalScope.charge.INLAND_PAYMENT.FREE_OF_CHARGE = false;
            else modalScope.charge.INLAND_RECEIVING.FREE_OF_CHARGE = false;

            const applicationComplement = modalScope.charge.APPLICATION && modalScope.charge.APPLICATION.APPLICATION_COMPLEMENT ? modalScope.charge.APPLICATION.APPLICATION_COMPLEMENT.ID : null;
            if (!applicationComplement || applicationComplement == EApplicationComplementId.NOT_APPLICABLE || applicationComplement == EApplicationComplementId.WEIGHT_RANGE) {
                if (applicationComplement == EApplicationComplementId.WEIGHT_RANGE) {
                    if (!modalScope.charge.FLAT) {
                        modalScope.charge.FLAT = true;
                        this.flatChange();
                    }
                } else {
                    modalScope.charge.FLAT = false;
                    modalScope.charge.WEIGHT_RANGE = null;
                    modalScope.charge.INLAND_PAYMENT.WEIGHT_RANGE_CHARGE = null;
                    modalScope.charge.INLAND_RECEIVING.WEIGHT_RANGE_CHARGE = null;
                }
                modalScope.charge.EQUIPMENT = null;
                modalScope.charge.VEHICLE_TYPE = null;
                this.$timeout(() => { modalScope.selectorValidity('weightRange'); });
            }
            else if (applicationComplement == EApplicationComplementId.EQUIPMENT) {
                modalScope.charge.FLAT = false;
                modalScope.charge.WEIGHT_RANGE = null;
                modalScope.charge.INLAND_PAYMENT.WEIGHT_RANGE_CHARGE = null;
                modalScope.charge.INLAND_RECEIVING.WEIGHT_RANGE_CHARGE = null;
                modalScope.charge.VEHICLE_TYPE = null;
                this.$timeout(() => { modalScope.selectorValidity('equipment'); });
            }
            else if (applicationComplement == EApplicationComplementId.VEHICLE) {
                modalScope.charge.FLAT = false;
                modalScope.charge.WEIGHT_RANGE = null;
                modalScope.charge.INLAND_PAYMENT.WEIGHT_RANGE_CHARGE = null;
                modalScope.charge.INLAND_RECEIVING.WEIGHT_RANGE_CHARGE = null;
                modalScope.charge.EQUIPMENT = null;
                this.$timeout(() => { modalScope.selectorValidity('vehicleType'); });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async flatChange() {
        try {
            const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
            if (modalScope.charge) {
                if (modalScope.charge.FLAT) {
                    const availableWeightRangeList = await this.getWeightRangeListByName(null);
                    const availableWeightRangeToConcat = availableWeightRangeList.filter(weightRange => (!modalScope.charge.WEIGHT_RANGE || !(modalScope.charge.WEIGHT_RANGE && modalScope.charge.WEIGHT_RANGE.some((chargeWeightRange) => chargeWeightRange.ID == weightRange.ID))));
                    if (modalScope.charge.WEIGHT_RANGE) modalScope.charge.WEIGHT_RANGE = modalScope.charge.WEIGHT_RANGE.concat(availableWeightRangeToConcat);
                    else modalScope.charge.WEIGHT_RANGE = availableWeightRangeToConcat;
                    this.weightRangeChange();
                } else {
                    modalScope.charge.INLAND_PAYMENT.UNITARY = null;
                    modalScope.charge.INLAND_RECEIVING.UNITARY = null;
                }
                modalScope.$applyAsync();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async weightRangeChange(onlyPayment?: boolean, onlyReceiving?: boolean) {
        try {
            const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
            const chargeWeightRange = modalScope.charge.WEIGHT_RANGE;
            if (this.isEmptyArray(chargeWeightRange)) {
                if (!onlyReceiving) modalScope.charge.INLAND_PAYMENT.WEIGHT_RANGE_CHARGE = null;
                if (!onlyPayment) modalScope.charge.INLAND_RECEIVING.WEIGHT_RANGE_CHARGE = null;
            } else {
                if (!onlyReceiving && !modalScope.charge.INLAND_PAYMENT.WEIGHT_RANGE_CHARGE) modalScope.charge.INLAND_PAYMENT.WEIGHT_RANGE_CHARGE = [];
                if (!onlyPayment && !modalScope.charge.INLAND_RECEIVING.WEIGHT_RANGE_CHARGE) modalScope.charge.INLAND_RECEIVING.WEIGHT_RANGE_CHARGE = [];
                for (const weightRange of chargeWeightRange) {
                    // Payment
                    if (!onlyReceiving && !modalScope.charge.INLAND_PAYMENT.FREE_OF_CHARGE) {
                        const hasPaymentWeightRangeAlready = modalScope.charge.INLAND_PAYMENT.WEIGHT_RANGE_CHARGE.some(paymentWeightRange => paymentWeightRange.WEIGHT_RANGE && paymentWeightRange.WEIGHT_RANGE.ID == weightRange.ID);
                        // Create the lines with the weight ranges if they don't exist
                        if (this.isEmptyArray(modalScope.charge.INLAND_PAYMENT.WEIGHT_RANGE_CHARGE) || !hasPaymentWeightRangeAlready) {
                            modalScope.charge.INLAND_PAYMENT.WEIGHT_RANGE_CHARGE.push({ WEIGHT_RANGE: weightRange, UNITARY: modalScope.charge.FLAT ? modalScope.charge.INLAND_PAYMENT.UNITARY : null });
                        } else if (modalScope.charge.FLAT) {
                            // Fill in unit values case is FLAT
                            for (const weightRangeCharge of modalScope.charge.INLAND_PAYMENT.WEIGHT_RANGE_CHARGE) { weightRangeCharge.UNITARY = modalScope.charge.INLAND_PAYMENT.UNITARY }
                        }
                    }

                    // Receiving
                    if (!onlyPayment && !modalScope.charge.INLAND_RECEIVING.FREE_OF_CHARGE) {
                        const hasReceivingWeightRangeAlready = modalScope.charge.INLAND_RECEIVING.WEIGHT_RANGE_CHARGE.some(receivingWeightRange => receivingWeightRange.WEIGHT_RANGE && receivingWeightRange.WEIGHT_RANGE.ID == weightRange.ID);
                        // Create the lines with the weight ranges if they don't exist
                        if (this.isEmptyArray(modalScope.charge.INLAND_RECEIVING.WEIGHT_RANGE_CHARGE) || !hasReceivingWeightRangeAlready) {
                            modalScope.charge.INLAND_RECEIVING.WEIGHT_RANGE_CHARGE.push({ WEIGHT_RANGE: weightRange, UNITARY: modalScope.charge.FLAT ? modalScope.charge.INLAND_RECEIVING.UNITARY : null });
                        } else if (modalScope.charge.FLAT) {
                            // Fill in unit values case is FLAT
                            for (const weightRangeCharge of modalScope.charge.INLAND_RECEIVING.WEIGHT_RANGE_CHARGE) { weightRangeCharge.UNITARY = modalScope.charge.INLAND_RECEIVING.UNITARY }
                        }
                    }
                }
                // Keep the correct list in case of removal
                modalScope.charge.INLAND_PAYMENT.WEIGHT_RANGE_CHARGE = modalScope.charge.INLAND_PAYMENT.WEIGHT_RANGE_CHARGE.filter(weightRangeCharge => chargeWeightRange && chargeWeightRange.some(weightRange => weightRange.ID == weightRangeCharge.WEIGHT_RANGE.ID));
                if (!modalScope.charge.INLAND_PAYMENT.WEIGHT_RANGE_CHARGE.length) modalScope.charge.INLAND_PAYMENT.WEIGHT_RANGE_CHARGE = null;
                modalScope.charge.INLAND_RECEIVING.WEIGHT_RANGE_CHARGE = modalScope.charge.INLAND_RECEIVING.WEIGHT_RANGE_CHARGE.filter(weightRangeCharge => chargeWeightRange && chargeWeightRange.some(weightRange => weightRange.ID == weightRangeCharge.WEIGHT_RANGE.ID));
                if (!modalScope.charge.INLAND_RECEIVING.WEIGHT_RANGE_CHARGE.length) modalScope.charge.INLAND_RECEIVING.WEIGHT_RANGE_CHARGE = null;
                modalScope.$applyAsync();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }


    private async freeOfChargePaymentChange(charge: IInlandCharge): Promise<IInlandCharge> {
        const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
        if (charge && charge.INLAND_PAYMENT && charge.INLAND_PAYMENT.FREE_OF_CHARGE) {
            charge.INLAND_PAYMENT.ID_CURRENCY = null;
            charge.INLAND_PAYMENT.CURRENCY = null;
            charge.INLAND_PAYMENT.UNITARY = null;
            charge.INLAND_PAYMENT.MIN = null;
            charge.INLAND_PAYMENT.HOLDER_TYPE = null;
            charge.INLAND_PAYMENT.ID_LEGAL_PERSON_HOLDER = null;
            charge.INLAND_PAYMENT.LEGAL_PERSON_HOLDER = null;
            charge.INLAND_PAYMENT.ID_PHYSICAL_PERSON_HOLDER = null;
            charge.INLAND_PAYMENT.PHYSICAL_PERSON_HOLDER = null;
            charge.INLAND_PAYMENT.WEIGHT_RANGE_CHARGE = null;
            this.$timeout(() => {
                modalScope.selectorValidity("paymentCurrency");
            });
        }
        this.weightRangeChange(true);
        return charge;
    }

    private async freeOfChargeReceivingChange(charge: IInlandCharge): Promise<IInlandCharge> {
        const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
        if (charge && charge.INLAND_RECEIVING && charge.INLAND_RECEIVING.FREE_OF_CHARGE) {
            charge.INLAND_RECEIVING.ID_CURRENCY = null;
            charge.INLAND_RECEIVING.CURRENCY = null;
            charge.INLAND_RECEIVING.UNITARY = null;
            charge.INLAND_RECEIVING.MIN = null;
            charge.INLAND_RECEIVING.HOLDER_TYPE = null;
            charge.INLAND_RECEIVING.ID_LEGAL_PERSON_HOLDER = null;
            charge.INLAND_RECEIVING.LEGAL_PERSON_HOLDER = null;
            charge.INLAND_RECEIVING.ID_PHYSICAL_PERSON_HOLDER = null;
            charge.INLAND_RECEIVING.PHYSICAL_PERSON_HOLDER = null;
            charge.INLAND_RECEIVING.WEIGHT_RANGE_CHARGE = null;
            this.$timeout(() => {
                modalScope.selectorValidity("receivingCurrency");
            });
        }
        this.weightRangeChange(false, true);
        return charge;
    }

    private async getCurrencyListByName(name: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            if (name && name.length >= 2) {
                this.formService.block();

                const currencies = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/currency/list/custom`, { name: name }, 10000, false);
                result = (currencies) ? currencies.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getChargeNameListByName(search: string): Promise<IChargeNameList[]> {
        let result: IChargeNameList[] = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();
                let errorMessage: string = '';
                if (!this.$scope.contractModel.WAY.ID) {
                    errorMessage += (errorMessage ? ', ' : '') + 'direction';
                }
                if (!this.$scope.contractModel.PRODUCT.ID) {
                    errorMessage += (errorMessage ? ', ' : '') + 'product';
                }
                if (!this.$scope.contractModel.CARGO_TYPE.ID) {
                    errorMessage += (errorMessage ? ', ' : '') + 'type cargo';
                }
                if (errorMessage) {
                    throw Error(`Select ${errorMessage} first`);
                }

                let types = [EChargeOriginId.FREIGHT, EChargeOriginId.TAX];
                if (this.$scope.contractModel.WAY.ID === EDirectionId.ORIGIN) {
                    types.push(EChargeOriginId.ORIGIN);
                } else if (this.$scope.contractModel.WAY.ID === EDirectionId.DESTINATION) {
                    types.push(EChargeOriginId.DESTINATION);
                } else {
                    throw Error('Invalid direction');
                }
                const products = [this.$scope.contractModel.PRODUCT.ID];
                const paramTypeCargo = [this.$scope.contractModel.CARGO_TYPE.ID];
                const chargeNames = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/chargeName/list/custom/exhibition`, { search, types, products, paramTypeCargo }, 30000, false);
                result = chargeNames ? chargeNames.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PARAMS: x.PARAMS } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getEquipmentListByName(search: string): Promise<IEquipmentSelector[]> {
        let result: IEquipmentSelector[] = [];
        try {
            if (!this.$scope.contractModel.PRODUCT) {
                throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
            }

            this.formService.block();

            const products = [this.$scope.contractModel.PRODUCT.ID];
            const equipmentList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/equipment/list/custom`, { search, products }, 30000, false);
            result = equipmentList ? equipmentList.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, TEU: x.TEU, FEET: x.FEET } }) : [];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getWeightRangeListByName(search: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.formService.block();
            const products = this.$scope.contractModel.PRODUCT ? [this.$scope.contractModel.PRODUCT.ID] : [];
            const weightRangeList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/weightRange/list/custom`, { search, products }, 30000, false);
            result = weightRangeList ? weightRangeList.map(x => { return { ID: x.ID, NAME: x.NAME } }) : [];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getLegalPersonListByName(legalPersonFilter: ILegalPersonListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        try {
            this.formService.block();
            if (legalPersonFilter.search && legalPersonFilter.search.length >= 3) {
                const legalPersons = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/legalPerson/list/custom`, legalPersonFilter, 30000, false);
                if (legalPersons) result = legalPersons.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME } });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getPhysicalPersonListByName(physicalPersonFilter?: IPhysicalPersonListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const physicalPersons = await this.RestService.newObjectPromise(`${this.$baseUrl}/physicalPerson/list/custom`, physicalPersonFilter, 30000, false);
            if (physicalPersons) result = physicalPersons.map(physicalPerson => { return { ID: physicalPerson.ID, NAME: physicalPerson.NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async updatePaymentUnit(): Promise<void> {
        try {
            const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
            if (modalScope.charge.FLAT) {
                const unit = modalScope.charge.INLAND_PAYMENT.UNITARY;
                if (modalScope.charge.INLAND_PAYMENT.WEIGHT_RANGE_CHARGE) {
                    for (const weightRangeCharge of modalScope.charge.INLAND_PAYMENT.WEIGHT_RANGE_CHARGE) {
                        weightRangeCharge.UNITARY = unit;
                    }
                }
                modalScope.$applyAsync();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async updateReceivingUnit(): Promise<void> {
        try {
            const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
            if (modalScope.charge.FLAT) {
                const unit = modalScope.charge.INLAND_RECEIVING.UNITARY;
                if (modalScope.charge.INLAND_RECEIVING.WEIGHT_RANGE_CHARGE) {
                    for (const weightRangeCharge of modalScope.charge.INLAND_RECEIVING.WEIGHT_RANGE_CHARGE) {
                        weightRangeCharge.UNITARY = unit;
                    }
                }
                modalScope.$applyAsync();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async updateHolderTypePayment() {
        try {
            const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
            modalScope.charge.INLAND_PAYMENT.ID_LEGAL_PERSON_HOLDER = null;
            modalScope.charge.INLAND_PAYMENT.LEGAL_PERSON_HOLDER = null;
            modalScope.charge.INLAND_PAYMENT.ID_PHYSICAL_PERSON_HOLDER = null;
            modalScope.charge.INLAND_PAYMENT.PHYSICAL_PERSON_HOLDER = null;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private updateRouteChargeList(chargeList: IInlandCharge[]) {
        if (chargeList && chargeList.length) {
            for (const charge of chargeList) {
                if (charge.EQUIPMENT) this.$scope.routeEquipmentList = charge.EQUIPMENT.map(equipment => equipment)
                charge.VEHICLE_TYPE = null;
            }
        }
    }

    private async updateHolderTypeReceiving() {
        try {
            const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
            modalScope.charge.INLAND_RECEIVING.ID_LEGAL_PERSON_HOLDER = null;
            modalScope.charge.INLAND_RECEIVING.LEGAL_PERSON_HOLDER = null;
            modalScope.charge.INLAND_RECEIVING.ID_PHYSICAL_PERSON_HOLDER = null;
            modalScope.charge.INLAND_RECEIVING.PHYSICAL_PERSON_HOLDER = null;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openReasonUpdateModal(confirmationData: ITariffChangeConfirmation) {
        try {
            if (!this.modalReasonUpdateId) this.modalReasonUpdateId = this.ModalService.newModal();
            this.$scope.reasonUpdateModal = { DESCRIPTION: confirmationData.DESCRIPTION, REASON_VALUE_CHANGE: null, REASON_VALIDITY_CHANGE: null, REASON_OBSERVATION: null };

            const body = `
            <div id="reasonModal">
                <div class="row form-group">
                    <div ng-if="${confirmationData.HAS_VALUE_CHANGE}" ng-class="${confirmationData.HAS_VALIDITY_CHANGE} ? 'col-lg-6' : 'col-lg-12'">
                        <label>{{'PRODUCT.REASON_VALUE_CHANGE' | translate}} <i class="fa fa-asterisk text-danger small-fontawesome" aria-hidden="true"
                        tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.MANDATORY_FIELD' | translate }}"
                        tooltip-append-to-body="true"></i></label>
                        <ui-select name="reasonValueChange" id="reasonValueChange"
                            ng-model="reasonUpdateModal.REASON_VALUE_CHANGE"
                            theme="bootstrap"
                            ng-change="selectorValidity(this.$select.ngModel.$name);"
                            ng-disabled="selectorDisabled(this.$select.ngModel.$name) || operation == 'view'"
                            ng-click="selectorFocus(this.$select.searchInput[0]);"
                            skip-focusser="true" required>
                            <ui-select-match placeholder="{{'GENERAL.UI_SELECT.SELECT' | translate }}">
                                {{$select.selected.NAME}}
                            </ui-select-match>
                            <ui-select-choices
                                repeat="item in reasonValueChangeList | filter: $select.search track by $index">
                                <div
                                    ng-bind-html="item.NAME | highlight: $select.search">
                                </div>
                            </ui-select-choices>
                            <ui-select-no-choice>
                                {{'GENERAL.UI_SELECT.EMPTY_SELECTOR_MESSAGE' | translate }}
                            </ui-select-no-choice>
                        </ui-select>
                    </div>
                    <div ng-if="${confirmationData.HAS_VALIDITY_CHANGE}" ng-class="${confirmationData.HAS_VALUE_CHANGE} ? 'col-lg-6' : 'col-lg-12'">
                        <label>{{'PRODUCT.REASON_VALIDITY_CHANGE' | translate}} <i class="fa fa-asterisk text-danger small-fontawesome" aria-hidden="true"
                        tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.MANDATORY_FIELD' | translate }}"
                        tooltip-append-to-body="true"></i></label>
                        <ui-select name="reasonValidityChange" id="reasonValidityChange"
                            ng-model="reasonUpdateModal.REASON_VALIDITY_CHANGE"
                            theme="bootstrap"
                            ng-change="selectorValidity(this.$select.ngModel.$name);"
                            ng-disabled="selectorDisabled(this.$select.ngModel.$name) || operation == 'view'"
                            ng-click="selectorFocus(this.$select.searchInput[0]);"
                            skip-focusser="true" required>
                            <ui-select-match placeholder="{{'GENERAL.UI_SELECT.SELECT' | translate }}">
                                {{$select.selected.NAME}}
                            </ui-select-match>
                            <ui-select-choices
                                repeat="item in reasonValidityChangeList | filter: $select.search track by $index">
                                <div
                                    ng-bind-html="item.NAME | highlight: $select.search">
                                </div>
                            </ui-select-choices>
                            <ui-select-no-choice>
                                {{'GENERAL.UI_SELECT.EMPTY_SELECTOR_MESSAGE' | translate }}
                            </ui-select-no-choice>
                        </ui-select>
                    </div>
                </div>
                <div class="row form-group" ng-if="reasonUpdateModal.REASON_VALUE_CHANGE.ID == '3' || reasonUpdateModal.REASON_VALIDITY_CHANGE.ID == '2'">
                    <div class="col-lg-12">
                        <label>{{'GENERAL.REMARKS' | translate}} <i class="fa fa-asterisk text-danger small-fontawesome" aria-hidden="true"
                        tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.MANDATORY_FIELD' | translate }}"
                        tooltip-append-to-body="true"></i></label>
                        <textarea class="form-control" name="reasonObservation" ng-model="reasonUpdateModal.REASON_OBSERVATION"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 space-list">
                        <ul class="list-group m-b-none">
                            <li class="list-group-item list-group-item-warning ellipsize full-width"
                                ng-repeat="description in reasonUpdateModal.DESCRIPTION track by $index"
                                style="max-width: 100%;" ellipsis-tooltip tooltip-placement="auto top"
                                uib-tooltip-html="description"
                                tooltip-enable="true" tooltip-append-to-body="true">
                                <span ng-bind-html="description"></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            `;

            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    modalID: this.modalReasonUpdateId,
                    scope: this.$scope,
                    formService: 'register',
                    size: 'lg modal-overflow',
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing" && closed) {
                            const modalData: IReasonUpdateModal = this.$scope.reasonUpdateModal;
                            const hasOthers = modalData && ((modalData.REASON_VALUE_CHANGE && modalData.REASON_VALUE_CHANGE.ID == ETariffChangeConfirmationReasonValue.OTHERS) || (modalData.REASON_VALIDITY_CHANGE && modalData.REASON_VALIDITY_CHANGE.ID == ETariffChangeConfirmationReasonValidity.OTHERS));
                            if (!modalData || (modalData && (confirmationData.HAS_VALUE_CHANGE && !modalData.REASON_VALUE_CHANGE)) || (confirmationData.HAS_VALIDITY_CHANGE && !modalData.REASON_VALIDITY_CHANGE) || (hasOthers && !modalData.REASON_OBSERVATION)) {
                                event.preventDefault();
                                const msgError = this.formService.getTranslate('GENERAL.ALL_FIELDS_MANDATORY');
                                this.formService.notifyError(msgError);
                            } else if (!await this.customSave({ _id: null, ID: null, ID_FREIGHT_ROUTES: null, ID_INLAND_ROUTE: this.$scope.model.ID, UPDATED_BY: null, UPDATED_AT: null, CREATED_BY: null, CREATED_AT: null, REASON_VALUE_CHANGE: modalData.REASON_VALUE_CHANGE, REASON_VALIDITY_CHANGE: modalData.REASON_VALIDITY_CHANGE, OBSERVATION: modalData.REASON_OBSERVATION, DESCRIPTION: modalData.DESCRIPTION, HAS_VALUE_CHANGE: confirmationData.HAS_VALUE_CHANGE, HAS_VALIDITY_CHANGE: confirmationData.HAS_VALIDITY_CHANGE, ID_TARIFF_LOCAL: null, ID_TARIFF_COMPLEMENTARY: null })) event.preventDefault();
                        }
                    }
                },
                {
                    closeButtonText: 'GENERAL.CANCEL',
                    actionButtonText: 'REGISTRATION.APPLY',
                    headerText: 'PRODUCT.TARIFF_CHANGE_CONFIRMATION',
                    bodyText: this.$sce.trustAsHtml(body),

                }
            );

            modalInstance.rendered.then(() => {
                const reasonModal = angular.element("#reasonModal");
                if (reasonModal) this.$compile(reasonModal)(this.$scope);
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async customSave(confirmationToUpdate?: ITariffChangeConfirmation): Promise<boolean> {
        let success = true;
        try {

            this.block();
            const operation = this.$scope.operation == EOperation.EDIT ? 'update' : 'insert';
            const request = await this.ProductService.post({ route: `/inlandRoutes/${operation}`, data: { data: this.$scope.model, oldData: this.$scope.scopeBeforeSave, confirmation: confirmationToUpdate, timeout: 30000 } });
            if (request && request.data && request.data.data) {
                const confirmation: boolean = request.data.data.confirmation;
                if (!confirmation) {
                    success = false;
                    if (!confirmationToUpdate) {
                        this.unblock();
                        const confirmationData: ITariffChangeConfirmation = request.data.data.data;
                        this.openReasonUpdateModal(confirmationData);
                    }
                }
                if (success) this.finishUpdateControl();
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return success;
        }
    }

    private getRatesByTheCorrectIndex(index: number): IInlandCharge {

        let chargeResult: IInlandCharge = null;
        if (Object.is(this.$scope.model.CHARGE[index], this.$scope.chargeTableControl.data[index])) {
            chargeResult = this.$scope.model.CHARGE[index];
        } else {

            for (const fee of this.$scope.model.CHARGE) {
                if (Object.is(fee, this.$scope.chargeTableControl.data[index])) {
                    chargeResult = fee;
                }
            }
        }
        return chargeResult;
    }

    private hasChanges(newObj: Object[], oldObj: Object[], propertiesToIgnore?: string[]): boolean {
        if (!propertiesToIgnore) propertiesToIgnore = this.propertiesToIgnore;
        if (propertiesToIgnore) {
            const newAux = newObj ? angular.copy(newObj) : [];
            const oldAux = oldObj ? angular.copy(oldObj) : [];
            if (newAux && newAux.length) {
                for (const auxNew of newAux) {
                    for (const property of propertiesToIgnore) {
                        if (auxNew && angular.isDefined(auxNew[property])) delete auxNew[property];
                        if (oldAux[newAux.indexOf(auxNew)] && angular.isDefined(oldAux[newAux.indexOf(auxNew)][property])) delete oldAux[newAux.indexOf(auxNew)][property];
                    }
                }
            }

            return !angular.equals(JSON.stringify(newAux), JSON.stringify(oldAux));
        }
        return !angular.equals(JSON.stringify(newObj), JSON.stringify(oldObj));
    }

    private getMenuFloatingDefault(): IFloatingMenu {
        return {
            btnActiveDisabled: false,
            options: [
                {
                    click: "collapseHeader",
                    args: ['collapseBasicData'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.BASIC_DATA",
                    iconClass: "fa fa-address-card",
                    iconBodyClass: "text-brown"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseUploads'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "REGISTRATION.EMAIL_ATTACHMENT",
                    iconClass: "fa fa-files-o",
                    iconBodyClass: "text-gray"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseObservation'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.REMARKS",
                    iconClass: "fa fa-arrows-alt",
                    iconBodyClass: "text-blue"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseEquipment'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "PRODUCT.WEIGHT_LIMITE",
                    iconClass: "fa fa-tachometer",
                    iconBodyClass: "text-green"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseAll'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.COLLAPSE_EXPAND_ALL",
                    iconClass: "fa fa-expand",
                    iconBodyClass: "text-danger"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseContractData'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "PRODUCT.CONTRACT_DATA",
                    iconClass: "fa fa-expand",
                    iconBodyClass: "text-warning"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseCharges'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.CHARGES",
                    iconClass: "fa fa-usd",
                    iconBodyClass: "text-green"
                }
            ]
        };
    }

    private async modalSaveConfirmation(headerText: string, closeButtonText: string, bodyText?: string): Promise<boolean> {
        return await this.ModalService.showModalConfirmation({}, {
            headerText: headerText,
            bodyText: this.formService.getTranslate(bodyText ? bodyText : 'REGISTRATION.MESSAGES.ERROR.UPDATE_NOT_SAVED'),
            actionButtonText: "GENERAL.SAVE",
            closeButtonText: closeButtonText
        });
    }

    private hasInvalidRequiredElements(elementId: string): boolean {
        if (!elementId) return false;
        const isInvalid = FormService2.hasRequiredElements('#' + elementId);
        if (isInvalid) this.formService.notifyError(this.formService.getTranslate("GENERAL.ALL_FIELDS_MANDATORY"));
        return isInvalid;
    }

    private isEmptyArray(arrayObj: any[]): boolean {
        return (arrayObj && arrayObj.length == 0 || !arrayObj);
    }

    private weekDaysList(): SelectorModel[] {
        const days: SelectorModel[] = [];
        try {
            for (let day = 1; day <= 7; day++) { // weekdays
                days.push({ ID: day.toString(), NAME: moment.weekdaysShort(day), CODE: moment.weekdaysShort(day) });
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            return days;
        }
    }

    private collapseHeader(elementId: string, state?: string, navigate?: boolean): void {
        if (elementId === "collapseAll") {
            this.collapseState = this.collapseState == "hide" ? "show" : "hide";
            $('.toggle-me')["collapse"](state ? state : this.collapseState);
        } else if (elementId != "registerBody") {
            $("#" + elementId)["collapse"](state ? state : 'toggle');
        }
        if (navigate && state != "hide") this.$scope.navigateBetweenIds(elementId);
    }

    private initPanels = (panelsToShow?: string[], panelToNavigate?: string) => {
        try {
            const panels = document.getElementsByClassName("toggle-me");
            if (panels) {
                for (let i = 0; i < panels.length; i++) {
                    const panel = panels[i];
                    if (panelsToShow && panelsToShow.findIndex(obj => obj === panel.id) >= 0) {
                        if (!panel.classList.contains('in')) {
                            $("#" + panel.id)["collapse"]("show");
                        }
                    } else if (panel.classList.contains('in')) {
                        $("#" + panel.id)["collapse"]("hide");
                    }
                }
            }
            if (panelToNavigate) this.$scope.navigateBetweenIds(panelToNavigate);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/inlandRoutes/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private addEquipmentOrVehicle(): void {
        const equipmentWeightRangeVehicle: IEquipmentWeightRangeVehicle = {
            EQUIPMENT: null,
            VEHICLE_TYPE: null,
            WEIGHT_LIMIT: null,
            CONCATENATED: null
        }

        this.$scope.model.EQUIPMENT_WEIGHT_RANGE_VEHICLE.push(equipmentWeightRangeVehicle);
    }

    private async removeEquipmentOrVehicle(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: this.formService.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")
            });
            if (!modal) return;

            if (this.$scope.model.EQUIPMENT_WEIGHT_RANGE_VEHICLE && this.$scope.model.EQUIPMENT_WEIGHT_RANGE_VEHICLE.length) {
                this.block();
                this.$scope.model.EQUIPMENT_WEIGHT_RANGE_VEHICLE.splice(index, 1);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }
}
import angular = require('angular');
import moment = require('moment');
import { IRestService } from "@services/RestService";
import { FormService2 } from '@services/FormService2';
import { IModalService } from '@services/ModalService';
import { ISessionService } from "@services/SessionService";
import { DataProcessService } from '@services/DataProcessService';
import { HelperService } from "@services/HelperService";
import { IViewLog } from "@models/interface/common/IViewLog";
import { EVENT } from '@models/interface/operational/NewProcess';
import { NewProcessEvent } from "@models/interface/operational/NewProcessEvent";
import { ISelectorModel } from '@models/mongo/SelectorModel';
import { IProcessEvent } from '@models/interface/trackingAWB/StatusEventCargoAITracking';
import { EOperation } from '@enums/GenericData';
import { IProcessParameter } from "../../common/model/ModelParameter";
import { INewProcessDetDemScope, ECollapseState } from './NewProcessDetDemController';
import { INewProcessExchangeData } from './NewProcessController';

interface ISituationEvent {
    COLOR: string;
    TEXT: string;
}

interface INewProcessDetDemEventScope extends ng.IScope {
    log: IViewLog;
    model: EVENT[];
    eventMaritimeServices: ISelectorModel[];
    eventModal: NewProcessEvent;
    newEventModalID: number;
    newEventsData: EVENT[];
    newEventsOptions: ISelectorModel[];
    eventDetailModalID: number;
    processNumber: string;
    monacoEventList: any[];
    product: ISelectorModel;
    sessionService: ISessionService;
    collapseEvent: () => void;
    isCollapseIn(): boolean;
    getDetDemEventStatus: (event: EVENT) => ISituationEvent;
    convertToUTC: (date: Date) => void;
    goToEvent: (event: NewProcessEvent) => void;
    getConcatenatedProcessEventName: (processEvent: IProcessEvent[]) => string;
}

export class NewProcessDetDemEventController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: INewProcessDetDemEventScope;
    private $newProcessDetDemScope: INewProcessDetDemScope;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private dataProcessService: DataProcessService;
    private restService: IRestService;
    private formService: FormService2;
    private modalService: IModalService;
    private $injector: ng.Injectable<any>;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: INewProcessDetDemEventScope) {
        this.$scope = $scope;
        this.$newProcessDetDemScope = <INewProcessDetDemScope>$scope.$parent.$parent;
        this.$injector = $injector;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.dataProcessService = $injector.get('DataProcessService');
        this.helperService = $injector.get('HelperService');
        this.restService = this.$newProcessDetDemScope.restService;
        this.formService = this.$newProcessDetDemScope.formService;
        this.modalService = this.$newProcessDetDemScope.modalService;
        this.$scope.sessionService = $injector.get('SessionService');
        this.initScopeFunctions();
    }

    async $onInit(): Promise<void> {
        try {
            this.initModel();
            await this.initDependencies();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initModel(): void {
        this.$scope.model = [{
            EVENT_ID: null,
            EVENT_NUMBER: null,
            EVENT_TYPE: null,
            SERVICE_TYPE_GROUP: null,
            SEQUENCE: null,
            EVENT_EDI_SETUP: null,
            EVENT_GROUP: null,
            LOCAL_TYPE: null,
            LOCAL: null,
            ZIP_CODE: null,
            NAME_ADDRESS: null,
            TRANSPORT_MODE: null,
            STOPOVER: null,
            FORECAST_DATE: null,
            FORECAST_DATE_ORIGIN_TYPE: null,
            FORECAST_DATE_EDITION_REASON: null,
            FORECAST_DATE_BEFORE: null,
            EFFECTIVE_DATE: null,
            EFFECTIVE_DATE_ORIGIN_TYPE: null,
            EFFECTIVE_DATE_EDITION_REASON: null,
            EFFECTIVE_DATE_BEFORE: null,
            SITUATION: null,
            REQUIRED: null,
            FROM: null,
            SERVICE_PROVIDER: null,
            TERMINAL: null,
            TERMINAL_REDIRECT: null,
            CONTAINER_GROUP: null,
            MODIFIED_TYPE: null,
            MODIFIED_RESPONSIBLE: null,
            OBSERVATION: null,
            PROCESS_EVENT_TRANSPORT_MODE: null,
            CREATED_BY: null,
            CREATED_DATE: null,
            MODIFIED_BY: null,
            MODIFIED_DATE: null,
            VEHICLE_TYPE_DATE: null
        }]
    }

    private initScopeFunctions(): void {

        this.$scope.collapseEvent = (): void => {
            this.collapseEvent();
        }

        this.$scope.isCollapseIn = (): boolean => {
            return this.$newProcessDetDemScope.collapseState.panel == ECollapseState.EVENTS && !this.$newProcessDetDemScope.collapseState.released;
        }

        this.$scope.getDetDemEventStatus = (event: EVENT) : ISituationEvent => {
            return this.getDetDemEventStatus(event);
        }

        this.$scope.convertToUTC = (date: Date) => {
            if (date) return moment.utc(date).format("DD/MM/YYYY HH:mm:ss");
            return null;
        };

        this.$scope.goToEvent = (event: EVENT): void => {
            this.$scope.sessionService.openTab("app.operational.newProcess.list", <IProcessParameter>{ PROCESS_NUMBER: this.$newProcessDetDemScope.model.PROCESS_NUMBER.replace('DD', '') }, <INewProcessExchangeData>{ OPERATION: "edit", PROCESS_NUMBER: this.$newProcessDetDemScope.model.PROCESS_NUMBER.replace('DD', ''), ID_EVENT: event.EVENT_ID });
        }

    }

    async initDependencies(): Promise<any> {
        const self: NewProcessDetDemEventController = this;
        this.initCollapseEvents();

        this.$scope.$watch(() => this.$newProcessDetDemScope.model.PROCESS_NUMBER, () => {
            this.$scope.processNumber = this.$newProcessDetDemScope.model.PROCESS_NUMBER;
        });

        // get generics
        new Promise(function (resolve, reject) {
            self.$q.all([
            ]).then(async (result: any) => {
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private initCollapseEvents() {
        this.$scope.$on('processEventCollapse', () => {
            this.collapseEvent();
        });

        const collapseEvent = angular.element('#collapseEvents');
        if (collapseEvent) {
            collapseEvent.on('shown.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    await this.getProcessTabsEvent();
                    angular.element("#collapseEventHeading").attr('aria-expanded', 'true');
                    // update collapse state
                    this.$newProcessDetDemScope.collapseState = { panel: ECollapseState.EVENTS, released: false, nextState: null };
                    this.$newProcessDetDemScope.repositionPanels(ECollapseState.EVENTS, true);
                    this.$newProcessDetDemScope.disableElements(this.$newProcessDetDemScope.operation == EOperation.VIEW);
                }
            });
            collapseEvent.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseEventHeading").attr('aria-expanded', 'false');
                    this.$scope.model = null;
                }
            });
        }
    }

    private async getProcessTabsEvent(): Promise<void> {
        this.formService.block();
        try {
            const timeout: number = 120000;
            const mainEvent = await this.dataProcessService.get(`/processDetDem/${this.$newProcessDetDemScope.model.ID_PROCESS}/event`, timeout);
            if (mainEvent && mainEvent.data && mainEvent.data.data) this.$scope.model = mainEvent.data.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async collapseEvent(): Promise<void> {
        try {
            if (this.$newProcessDetDemScope.collapseState.released || this.$newProcessDetDemScope.collapseState.panel == ECollapseState.EVENTS) {
                const collapseEvent = angular.element("#collapseEvents");
                if (collapseEvent) {
                    const isCollapsed = angular.element("#collapseEventHeading").attr("aria-expanded") == "true";
                    if (isCollapsed) {
                        this.$newProcessDetDemScope.collapseState.released = true;
                    }
                    collapseEvent['collapse']('toggle');
                    if (isCollapsed) this.$newProcessDetDemScope.repositionPanels(ECollapseState.EVENTS);
                }
            } else {
                this.$newProcessDetDemScope.collapseState.nextState = ECollapseState.EVENTS;
                this.$newProcessDetDemScope.releaseCollapse();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }
    

    private getDetDemEventStatus(event: EVENT): ISituationEvent {
        try {
            /*  Previsão Vencida & Efetivo Nulo = Vermelho
                Efetivo Maior que Previsão = Amarelo
                Efetivo e Previsão mesmo dia = Verde
                Efetivo menor que Previsão = Azul 
            */
            let status: ISituationEvent = { COLOR: 'text-default', TEXT: 'OPERATIONAL.WAITING_FORECAST_DATE' };

            if (event && event.EVENT_NUMBER) {

                const todayDate = new Date();
                const estimatedDate = (event.FORECAST_DATE) ? new Date(event.FORECAST_DATE) : null;
                const effectiveDate = (event.EFFECTIVE_DATE) ? new Date(event.EFFECTIVE_DATE) : null;

                if (effectiveDate && estimatedDate) {
                    if (effectiveDate.getTime() > estimatedDate.getTime()) {
                        //YELLOW - WARNING - fa-circle
                        status.COLOR = 'text-warning';
                        status.TEXT = 'OPERATIONAL.ACTUAL_GREATER_THAN_FORECAST';
                        //$scope.eventList.STATUS = 'YELLOW';
                    } else if (effectiveDate.getTime() === estimatedDate.getTime()) {
                        //GREEN - SUCCESS - fa-circle
                        status.COLOR = 'text-success';
                        status.TEXT = 'OPERATIONAL.ACTUAL_FORECAST_SAME_DAY';
                        //$scope.eventList.STATUS = 'GREEN';
                    } else if (effectiveDate.getTime() < estimatedDate.getTime()) {
                        //BLUE - PRIMARY - fa-circle
                        status.COLOR = 'text-primary';
                        status.TEXT = 'OPERATIONAL.ACTUAL_LOWER_THAN_FORECAST';
                        //$scope.eventList.STATUS = 'BLUE';
                    }
                } else if (estimatedDate && !effectiveDate && todayDate.getTime() > estimatedDate.getTime()) {
                    //RED - DANGER - fa-circle
                    status.COLOR = 'text-danger';
                    status.TEXT = 'OPERATIONAL.ESTIMATED_DATE_EXPIRED';
                    //$scope.eventList.STATUS = 'BLUE';
                } else if (estimatedDate && !effectiveDate && todayDate.getTime() <= estimatedDate.getTime()) {
                    //ORANGE - ORANGE - fa-circle
                    status.COLOR = 'text-orange';
                    status.TEXT = 'OPERATIONAL.WAITING_ACTUAL_DATE';
                }
            }
            console.log(event.EVENT_TYPE.NAME, status);
            return status;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

}
import * as angular from "angular";
import * as moment from 'moment';
import { IColumnDef, IGridOptions } from "ui-grid";
import { fileUploader as FileUploader } from 'angular-file-upload';
import { SSEService } from '@appServices/SSEService';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IModalService } from '@services/ModalService';
import { ISessionService } from "@services/SessionService";
import { ICustomLogProperties, IViewLog } from "@models/interface/common/IViewLog";
import { IParams, IChargeNameList } from "@models/interface/product/ChargeNameModel";
import { IApplicationList } from "@models/interface/product/ApplicationModel";
import { IProviderModel, IProviderSelector } from "@models/interface/product/ProviderModel";
import { IUploader, IUploadItem, IFileItem, IFormData, ISuccessItemResponse } from "@models/interface/common/IMonacoUpload";
import { IEquipmentSelector } from "@models/interface/product/EquipmentModel";
import { IRoutingPointSelector } from "@models/interface/product/RoutingPoint";
import { ITariffChangeConfirmation } from '@models/interface/product/TariffChangeConfirmation';
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { EProductId, EPaymentNatureId, ERateTypeId, EProviderTypeId, EChargeOriginId, EApplicationComplementId, EOperation, ETypeLocalId, EEventType, EDirectionId, ETariffChangeConfirmationReasonValue, ETariffChangeConfirmationReasonValidity } from "@enums/GenericData";
import { ITariffLocalModel, ICharge, ITariffLocalExchangeData } from "../model/TariffLocalModel";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { SelectorModel } from "../../common/model/SelectorModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { ILegalPersonListCustomFilter } from "../model/LegalPersonModel";
import { ILinkParameter, IOfferParameter } from "../../common/model/ModelParameter";
import { StringUtil } from "../../common/util/StringUtil";
import { IFloatingMenu } from "../../common/interface/IFloatingMenu";
import { IPhysicalPersonListCustomFilter } from "../model/PhysicalPersonModel";
import { ValidateUtil } from "../../common/util/ValidateUtil";
import { ITableOptions } from "src/ts/app/directives/monaco-data-table";
import { IModalInstanceService } from "angular-ui-bootstrap";
import { IFormServiceScope, FormService2 } from "@services/FormService2";
import { ProductService } from "@services/ProductService";
import { ISelectorModel } from "WBA-Model/dist/mongo/SelectorModel";
import { IDocumentError } from '@models/interface/common/IDocumentError';
import { HelperService } from "@services/HelperService";

export interface ITariffLocalCode {
    ID: string;
    VALUE: string;
}

export interface IUsedOfferModal extends ng.IScope {
    tariffLocal: ITariffLocalModel;
    usedOfferList: IUsedOffer[];
    goToOffer: (offerNumber: string) => void;
}

export interface IUsedOffer {
    CODE: string;
    VALIDITY_START: any;
    VALIDITY_END: any;
    SITUATION: string;
}

interface IChargeTableFilter {
    SHOW_ONLY_CURRENT_CHARGES: boolean;
    LOAD_REFERENCE: Date;
}

interface IChargeModalScope extends IFormServiceScope {
    currentIndex: number;
    currentDisplayIndex: number;
    charge: ICharge;
    oldCharge: ICharge;
    operation: string;
    chargeApplicationList: SelectorModel[];
    chargeNameList: IChargeNameList[];
    currencyList: SelectorModel[];
    equipmentList: IEquipmentSelector[];
    weightRangeList: SelectorModel[];
    holderList: SelectorModel[];
    isPaymentNature: boolean;
    isReceivingNature: boolean;

    // modal functions
    applyCharge: () => Promise<void>;
    closeChargeModal: () => void;
    hasPreviousCharge: (currentIndex: number) => boolean;
    hasNextCharge: (currentIndex: number) => boolean;
    previousCharge: (currentIndex: number) => void;
    nextCharge: (currentIndex: number) => void;
    setChargeNameId: () => void;

    // check functions
    isNotApplicableComplement: (applicationComplement: SelectorModel) => boolean;
    isWeightRangeComplement: (applicationComplement: SelectorModel) => boolean;
    isEquipmentComplement: (applicationComplement: SelectorModel) => boolean;
    isVehicleComplement: (applicationComplement: SelectorModel) => boolean;

    // change functions
    updateParam: (selected: IChargeNameList) => void;
    flatChange: (chargeIndex: number) => void;
    weightRangeChange: () => void;
    freightBasisChange: () => void;
    freeOfChargePaymentChange: (charge: ICharge) => void;
    freeOfChargeReceivingChange: (charge: ICharge) => void;

    // get functions
    getChargeNameListByName: (search: string) => Promise<void>;
    getCurrencyListByName: (name: string) => Promise<void>;
    getWeightRangeListByName: (search: string) => Promise<void>;
    getHolderListByName: (search: string, holderId: string) => Promise<void>;

    // general functions
    updatePaymentUnit: () => void;
    updateReceivingUnit: () => void;
    updateHolderTypePayment: () => void;
    updateHolderTypeReceiving: () => void;

    validityStartEndKick: (chargeNameId: string) => void;
}

interface IReasonUpdateModal {
    DESCRIPTION: string[];
    REASON_VALUE_CHANGE: ISelectorModel;
    REASON_VALIDITY_CHANGE: ISelectorModel;
    REASON_OBSERVATION: string;
}

interface ITariffLocalScope extends IGridFormServiceScope {
    form: ng.IFormController;
    gridOptions: IGridOptions;
    model: ITariffLocalModel;
    oldModel: ITariffLocalModel;
    customLogProperties: ICustomLogProperties[];
    productList: SelectorModel[];
    paymentNatureList: SelectorModel[];
    directionList: SelectorModel[];
    typeCargoList: SelectorModel[];
    countryList: SelectorModel[];
    routingPointList: IRoutingPointSelector[];
    providerList: IProviderSelector[];
    networkList: SelectorModel[];
    agentList: SelectorModel[];
    accountList: SelectorModel[];
    exporterList: SelectorModel[];
    importerList: SelectorModel[];
    eventList: SelectorModel[];
    rateTypeList: SelectorModel[];
    serviceTypeList: SelectorModel[];
    situationList: SelectorModel[];
    holderTypeList: SelectorModel[];
    vehicleTypeList: SelectorModel[];
    typeLocalEventList: SelectorModel[];
    reasonValueChangeList: ISelectorModel[];
    reasonValidityChangeList: ISelectorModel[];
    detailsList: SelectorModel[];
    uploader: IUploader;
    user: any;
    menuFloating: IFloatingMenu;
    sessionService: ISessionService;
    isAirProduct: boolean;
    isMaritimeProduct: boolean;
    chargesTableOptions: ITableOptions;
    chargesListDisplay: ICharge[];
    chargeTableControl: {
        data: ICharge[];
        chargeFilter: IChargeTableFilter;
    };
    reasonUpdateModal: IReasonUpdateModal;
    tariffChangeConfirmation: ITariffChangeConfirmation[];

    // crud functions
    copyTariffLocal: (tariffLocal: ITariffLocalModel) => void;
    viewTariffLocal: (tariffLocal: ITariffLocalModel) => void;
    customEdit: (tariffLocal: ITariffLocalModel) => void;
    viewLogTariffLocal: (tariffLocal: ITariffLocalModel) => void;

    // get functions
    getGridViewProvider: (providers: IProviderSelector[]) => string;
    getCountryListByName: (search: string) => Promise<void>;
    getRoutingPointListByName: (search: string) => Promise<void>;
    getProviderListByName: (search: string) => Promise<void>;
    getNetworkListByName: (name: string) => Promise<void>;
    getAgentListByName: (search: string) => Promise<void>;
    getAccountListByName: (search: string) => Promise<void>;
    getExporterListByName: (search: string) => Promise<void>;
    getImporterListByName: (search: string) => Promise<void>;

    // change functions
    productChange: (selected: SelectorModel) => void;
    paymentNatureChange: (selected: SelectorModel) => void;
    freightBasisChange: () => void;
    showOnlyCurrentChargesChange: () => void;
    chargeFilterLoadReferenceChange: () => void;

    // go to functions
    goToProvider: () => void;
    goToNetwork: () => void;
    goToAgent: () => void;
    goToAccount: () => void;
    goToLegalPerson: (ids: string) => void;
    goToRoutingPoint: (ids: string) => void;
    goToServiceType: (id: number) => void;

    // general functions
    removeUpload: (model: IUploadItem) => boolean;
    limitLines: (value: string, limit: number) => string;
    formatConcatenatedChars: (value: string) => string;
    fetchData: (id: number, action: string) => Promise<void>;
    setCopy: (value: boolean) => void;
    formatedScoreDate: () => string;
    collapseHeader: (elementId: string, state?: string, navigate?: boolean) => void;
    generateConcatenated: () => void;
    openTariffUsedOffer: (tariffLocal: ITariffLocalModel) => Promise<void>;
    viewLogConfirmation: () => void;

    // check functions
    hasChanges: (newObj: Object, oldObj: Object, propertiesToIgnore?: string[]) => boolean;
    checkDateValidity: (initialDate: Date, finalDate: Date) => void;
    validateOverseasAgent: (model : ITariffLocalModel) => boolean;
    validateExternalAgentNetwork: (model : ITariffLocalModel) => boolean;
    validateProvider: (model : ITariffLocalModel) => boolean;

    // filter functions
    filterTypeLocalEventList: () => SelectorModel[];
    filterRateType: (data: SelectorModel[]) => SelectorModel[];

    // integration
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;

}

export class TariffLocalRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: ITariffLocalScope;
    private $q: ng.IQService;
    private $filter: ng.FilterFactory;
    private $sce: angular.ISCEService;
    private $compile: angular.ICompileService;
    private RestService: IRestService;
    private ProductService: ProductService;
    private $timeout: ng.ITimeoutService
    private ModalService: IModalService;
    private fileUploader: FileUploader;
    private formName: string;
    private gridName: string;
    private collapseState: string;
    private modalChargeId: number;
    private modalFormatedDataId: number;
    private modalReasonUpdateId: number;
    private SSEService: SSEService;
    private propertiesToIgnore: string[];
    private isCopy: boolean;
    private modalID: number;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: ITariffLocalScope) {
        super($injector, $scope);
        this.formName = 'tariffLocal';
        this.gridName = 'GRID_TARIFF_LOCAL';
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$filter = $injector.get('$filter');
        this.$sce = $injector.get('$sce');
        this.$compile = $injector.get('$compile');
        this.RestService = $injector.get('RestService');
        this.ProductService = $injector.get('ProductService');
        this.$timeout = $injector.get('$timeout');
        this.ModalService = $injector.get('ModalService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.fileUploader = $injector.get('FileUploader');
        this.collapseState = "hide";
        this.modalChargeId = 0;
        this.propertiesToIgnore = ["DISPLAY_INDEX", "selected", "uniqueIdControl"];
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.modalID = null;
        this.helperService = $injector.get('HelperService');
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    getFisFilesGenericRoute(): string {
        const baseRoute = '/fis/filesGeneric';
        const uploadRoute = this.config.fisUrl + baseRoute;
        return uploadRoute;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.uploader = this.getFileUploaderDefault();
            this.$scope.chargesTableOptions = this.getChargesTableOptions();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.chargeTableControl = { data: null, chargeFilter: { SHOW_ONLY_CURRENT_CHARGES: false, LOAD_REFERENCE: null } };
            this.$scope.$watch('model.CHARGE', (newValue: ICharge[], oldValue: ICharge[]) => {
                if (newValue != oldValue) {
                    this.$scope.chargeTableControl.data = angular.copy(newValue);
                    this.applyChargeFilterTable();
                }
            });

            this.initForm(this, 'form', this.formName, 'GENERAL.MENU.TARIFF_LOCAL', true);
            await this.initGrid(this.gridName, '/tariffLocal/list', true, true, null, true, true);
            const sessionParameter = this.$gridService.$sessionParameter;
            if (sessionParameter && sessionParameter.data) this.callSessionFunctions(sessionParameter.data);
            this.initCollapseEvents();
            this.SSEService.closeEvents();
            this.setCopy(false);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initDependencies(): Promise<any> {
        const self: TariffLocalRegisterController = this;
        return new Promise(function (resolve, reject) {
            self.initDateFields();

            self.$q.all([
                self.getGenericList("product"),
                self.getGenericList("payment_nature"),
                self.getGenericList("direction"),
                self.getGenericList("type_cargo"),
                self.getGenericList("event"),
                self.getGenericList("rate_type"),
                self.getGenericList("registration_situation"),
                self.getGenericList("holder_type"),
                self.getGenericList("vehicle_type"),
                self.getGenericList("type_local_event"),
                self.getGenericList("tariff_change_confirmation_reason_value"),
                self.getGenericList("tariff_change_confirmation_reason_validity"),
                self.getGenericList("inland_details")
            ]).then((result: any) => {
                self.$scope.productList = self.filterProducts(result[0]);
                self.$scope.paymentNatureList = result[1];
                self.$scope.directionList = result[2];
                self.$scope.typeCargoList = result[3];
                self.$scope.eventList = result[4];
                self.$scope.rateTypeList = result[5];
                self.$scope.situationList = result[6];
                self.$scope.holderTypeList = result[7];
                self.$scope.vehicleTypeList = result[8];
                self.$scope.typeLocalEventList = result[9];
                self.$scope.reasonValueChangeList = result[10];
                self.$scope.reasonValidityChangeList = result[11];
                self.$scope.detailsList = result[12];
                if (self.$scope.situationList && self.$scope.situationList.length > 0 && !self.$scope.model.SITUATION) {
                    self.$scope.model.SITUATION = self.$scope.situationList.find(item => item.ID == '1');
                }
                if (self.$scope.eventList && self.$scope.eventList.length > 0 && !self.$scope.model.EVENT) {
                    self.$scope.model.EVENT = self.$scope.eventList.find(event => event.ID == '4');
                }
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.copyTariffLocal = (tariffLocal: ITariffLocalModel): void => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(tariffLocal.ID, EOperation.COPY);
        }
        this.$scope.viewTariffLocal = (tariffLocal: ITariffLocalModel): void => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(tariffLocal.ID, EOperation.VIEW);
        }
        this.$scope.customEdit = (tariffLocal: ITariffLocalModel): void => {
            this.editTariffLocal(tariffLocal);
        }
        this.$scope.viewLogTariffLocal = (tariffLocal: ITariffLocalModel): void => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(tariffLocal);
        }
        this.$scope.getGridViewProvider = (providers: IProviderSelector[]): string => {
            const providerList: SelectorModel[] = [];
            if (providers) {
                for (const provider of providers) {
                    if (provider.TYPE) providerList.push({ ID: provider.ID, NAME: provider.TYPE.ID == EProviderTypeId.SHIPOWNER || provider.TYPE.ID == EProviderTypeId.AIRLINE ? provider.CODE : provider.NAME });
                }
            }
            return this.$scope.getCONCAT(providerList);
        }
        this.$scope.getCountryListByName = async (search: string): Promise<void> => {
            return await this.getCountryListByName(search);
        }
        this.$scope.getRoutingPointListByName = async (search: string): Promise<void> => {
            return await this.getRoutingPointListByName(search);
        }
        this.$scope.getProviderListByName = async (search: string): Promise<void> => {
            return await this.getProviderListByName(search);
        }
        this.$scope.getNetworkListByName = async (name: string): Promise<void> => {
            return await this.getNetworkListByName(name);
        }
        this.$scope.getAgentListByName = async (search: string): Promise<void> => {
            return await this.getAgentListByName(search);
        }
        this.$scope.getAccountListByName = async (search: string): Promise<void> => {
            return await this.getAccountListByName(search);
        }
        this.$scope.getExporterListByName = async (search: string): Promise<void> => {
            return await this.getExporterListByName(search);
        }
        this.$scope.getImporterListByName = async (search: string): Promise<void> => {
            return await this.getImporterListByName(search);
        }
        this.$scope.productChange = (selected: SelectorModel): void => {
            this.productChange(selected);
        }
        this.$scope.paymentNatureChange = (selected: SelectorModel): void => {
            this.paymentNatureChange(selected);
        }
        this.$scope.freightBasisChange = () => {
            this.$scope.model.EVENT = null;
            this.$timeout(() => {
                this.$scope.selectorValidity("event");
            });
        }
        this.$scope.showOnlyCurrentChargesChange = () => {
            if (this.$scope.chargeTableControl && this.$scope.chargeTableControl.chargeFilter) {
                if (this.$scope.chargeTableControl.chargeFilter.SHOW_ONLY_CURRENT_CHARGES) {
                    this.$scope.chargeTableControl.chargeFilter.LOAD_REFERENCE = new Date();
                } else {
                    this.$scope.chargeTableControl.chargeFilter.LOAD_REFERENCE = null;
                }
                this.applyChargeFilterTable();
            }
        }
        this.$scope.chargeFilterLoadReferenceChange = () => {
            const loadReference = moment(this.$scope.chargeTableControl.chargeFilter.LOAD_REFERENCE, 'DD/MM/YYYY');
            if (!this.$scope.chargeTableControl.chargeFilter.LOAD_REFERENCE || loadReference.isValid()) this.applyChargeFilterTable();
        }
        this.$scope.goToProvider = (): void => {
            this.$scope.sessionService.openTab("app.registration.provider", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.PROVIDERS, null, 'ID') });
        }
        this.$scope.goToNetwork = (): void => {
            this.$scope.sessionService.openTab("app.registration.network", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.NETWORKS, null, 'ID') });
        }
        this.$scope.goToAgent = (): void => {
            this.$scope.sessionService.openTab("app.registration.agent", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.LEGAL_PERSON_AGENTS, null, 'ID') });
        }
        this.$scope.goToAccount = (): void => {
            this.$scope.sessionService.openTab("app.commercial.account", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.ACCOUNTS, null, 'ID') });
        }
        this.$scope.goToLegalPerson = (ids: string): void => {
            this.$scope.sessionService.openTab("app.registration.legalPerson", <ILinkParameter>{ ID: ids });
        }
        this.$scope.goToRoutingPoint = (ids: string): void => {
            this.$scope.sessionService.openTab("app.registration.routingPoint", <ILinkParameter>{ ID: ids });
        }
        this.$scope.goToServiceType = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.servicetype", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.$scope.removeUpload = (model: IUploadItem): boolean => {
            return this.removeUpload(model);
        }
        this.$scope.limitLines = (value: string, limit: number) => {
            return StringUtil.limitLines(value, limit);
        }
        this.$scope.formatConcatenatedChars = (value: string) => {
            return StringUtil.formatConcatenatedChars(value);
        }
        this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
            this.fetchData(id, action);
        }
        this.$scope.setCopy = (value: boolean): void => {
            this.setCopy(value);
        }
        this.$scope.formatedScoreDate = (): string => {
            return this.formatedScoreDate();
        }
        this.$scope.collapseHeader = (elementId: string, state?: string, navigate?: boolean): void => {
            this.collapseHeader(elementId, state, navigate);
        }
        this.$scope.generateConcatenated = (): void => {
            this.generateConcatenated();
        }
        this.$scope.openTariffUsedOffer = async (tariffLocal: ITariffLocalModel): Promise<void> => {
            this.openTariffUsedOffer(tariffLocal);
        }
        this.$scope.viewLogConfirmation = () => {
            this.viewLogConfirmation();
        }
        this.$scope.hasChanges = (newObj: Object, oldObj: Object, propertiesToIgnore?: string[]) => {
            return this.hasChanges(newObj, oldObj, propertiesToIgnore);
        }
        this.$scope.checkDateValidity = (initialDate: Date, finalDate: Date): void => {
            this.checkDateValidity(initialDate, finalDate);
        }
        this.$scope.validateOverseasAgent = (model : ITariffLocalModel): boolean => {
            return this.validateOverseasAgent(model);
        }
        this.$scope.validateExternalAgentNetwork = (model : ITariffLocalModel): boolean => {
            return this.validateExternalAgentNetwork(model);
        }
        this.$scope.validateProvider = (model : ITariffLocalModel): boolean => {
            return this.validateProvider(model);
        }
        this.$scope.filterTypeLocalEventList = (): SelectorModel[] => {
            return this.filterTypeLocalEventList();
        }
        this.$scope.filterRateType = (data: SelectorModel[]): SelectorModel[] => {
            return this.filterRateType(data);
        }
        this.$scope.uploader.onSuccessItem = (item: IFileItem, response: ISuccessItemResponse): void => {
            this.onSuccessItem(item, response);
        }
        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }
    }

    initGridColumns(columns: string[]): IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<a ng-click="grid.appScope.viewTariffLocal(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.customEdit(row.entity)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyTariffLocal(row.entity)" class="text-orange copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogTariffLocal(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const offerGenerated = `<a ng-disabled="!row.entity.TARIFF_LOCAL_USED_OFFER.length"><btn ng-click="row.entity.TARIFF_LOCAL_USED_OFFER.length ? grid.appScope.openTariffUsedOffer(row.entity) : ''" class="text-info" ng-class="{'text-muted': !row.entity.TARIFF_LOCAL_USED_OFFER.length}" tooltip-placement="auto top" uib-tooltip="{{row.entity.TARIFF_LOCAL_USED_OFFER.length ? 'GENERAL.OFFER_VIEW' : 'GENERAL.NO_OFFERS_GENERATED_WITH_THIS_TARIFF' | translate }}" tooltip-append-to-body="true"><i class="fa fa-list-alt icon"></i></btn></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: 150,
            cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${copy} ${viewLog} ${offerGenerated} ${modalIntegration}</div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };

        gridColumns.addColumn(colActions);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colSituation: IMonacoColumnDef = { name: "SITUATION.NAME", displayName: "GENERAL.SITUATION", width: 100 };
            const colConcatenated: IMonacoColumnDef = { name: "CONCATENATED", displayName: "GENERAL.CONCATENATED", width: 250 };
            const colVersion: IMonacoColumnDef = { name: "VERSION", displayName: "GENERAL.VERSION", width: 100 };
            const colPaymentNature: IMonacoColumnDef = { name: "PAYMENT_NATURE.NAME", displayName: "BASIC_DATA.TRANSACTION", width: 120 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 90 };
            const colDirection: IMonacoColumnDef = { name: "DIRECTION.NAME", displayName: "PRODUCT.WAY", width: 100 };
            const colCargoType: IMonacoColumnDef = { name: "TYPE_CARGO.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 120 };
            const colServiceType: IMonacoColumnDef = { name: "SERVICE_TYPE.NAME", displayName: "BASIC_DATA.SERVICE_TYPE", width: 120 };
            const colLocal: IMonacoColumnDef = { name: "LOCAL.DISPLAY_NAME", displayName: "GENERAL.LOCAL", width: 180, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.LOCAL, null, "DISPLAY_NAME")}}</div>' };
            const colProvider: IMonacoColumnDef = { name: "PROVIDERS.NAME", displayName: "BASIC_DATA.PROVIDER", width: 120, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getGridViewProvider(row.entity.PROVIDERS)}}</div>' };
            const colNetwork: IMonacoColumnDef = { name: "NETWORKS.NAME", displayName: "BASIC_DATA.EXTERNAL_AGENT_NETWORK", width: 160, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.NETWORKS, null, "NAME")}}</div>' };
            const colAgent: IMonacoColumnDef = { name: "LEGAL_PERSON_AGENTS.NAME", displayName: "BASIC_DATA.AGENT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.LEGAL_PERSON_AGENTS, null, "NAME")}}</div>' };
            const colAccount: IMonacoColumnDef = { name: "ACCOUNTS.NAME", displayName: "BASIC_DATA.CLIENT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNTS, null, "NAME")}}</div>' };
            const colAccountFantasyName: IMonacoColumnDef = { name: "ACCOUNTS.FANTASY_NAME", displayName: "GENERAL.CUSTOMER_COMMERCIAL_NAME", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNTS, null, "FANTASY_NAME")}}</div>' };
            const colUsedOffer: IMonacoColumnDef = { name: "TARIFF_LOCAL_USED_OFFER.OFFER_CODE_EXHIBITION", displayName: "BASIC_DATA.OFFER", width: 160, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TARIFF_LOCAL_USED_OFFER, null, "OFFER_CODE_EXHIBITION")}}</div>' };
            const colExporter: IMonacoColumnDef = { name: "EXPORTERS.NAME", displayName: "BASIC_DATA.SHIPPER", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.EXPORTERS, null, "NAME")}}</div>' };
            const colExporterFantasyName: IMonacoColumnDef = { name: "EXPORTERS.FANTASY_NAME", displayName: "GENERAL.SHIPPER_COMMERCIAL_NAME", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.EXPORTERS, null, "FANTASY_NAME")}}</div>' };
            const colImporter: IMonacoColumnDef = { name: "IMPORTERS.NAME", displayName: "BASIC_DATA.CONSIGNEE", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.IMPORTERS, null, "NAME")}}</div>' };
            const colImporterFantasyName: IMonacoColumnDef = { name: "IMPORTERS.FANTASY_NAME", displayName: "GENERAL.CNEE_COMMERCIAL_NAME", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.IMPORTERS, null, "FANTASY_NAME")}}</div>' };
            const colScore: IMonacoColumnDef = { name: "SCORE", displayName: "BASIC_DATA.SCORE", width: 100 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 80, cellFilter: "YesOrNo" };
            const colValidityExpired: IMonacoColumnDef = { name: "VALIDITY_EXPIRED", displayName: "GENERAL.EXPIRED", width: 150, cellFilter: "YesOrNo" };
            const colValidityStartContract: IMonacoColumnDef = { name: "DISPLAY_VALIDITY_START", displayName: "PRODUCT.VALIDITY_START", width: 150, cellFilter: "simpleDate" };
            const colValidityEndContract: IMonacoColumnDef = { name: "DISPLAY_VALIDITY_END", displayName: "PRODUCT.VALIDITY_END", width: 150, cellFilter: "simpleDate" };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'SITUATION':
                        columnDefs.push(colSituation);
                        break;
                    case 'CONCATENATED':
                        columnDefs.push(colConcatenated);
                        break;
                    case 'VERSION':
                        columnDefs.push(colVersion);
                        break;
                    case 'PAYMENT_NATURE':
                        columnDefs.push(colPaymentNature);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'DIRECTION':
                        columnDefs.push(colDirection);
                        break;
                    case 'TYPE_CARGO':
                        columnDefs.push(colCargoType);
                        break;
                    case 'SERVICE_TYPE':
                        columnDefs.push(colServiceType);
                        break;
                    case 'LOCAL':
                        columnDefs.push(colLocal);
                        break;
                    case 'PROVIDERS':
                        columnDefs.push(colProvider);
                        break;
                    case 'NETWORKS':
                        columnDefs.push(colNetwork);
                        break;
                    case 'LEGAL_PERSON_AGENTS':
                        columnDefs.push(colAgent);
                        break;
                    case 'ACCOUNTS':
                        columnDefs.push(colAccount, colAccountFantasyName);
                        break;
                    case 'TARIFF_LOCAL_USED_OFFER':
                        columnDefs.push(colUsedOffer);
                        break;
                    case 'EXPORTERS':
                        columnDefs.push(colExporter, colExporterFantasyName);
                        break;
                    case 'IMPORTERS':
                        columnDefs.push(colImporter, colImporterFantasyName);
                        break;
                    case 'DISPLAY_VALIDITY_START':
                        columnDefs.push(colValidityStartContract);
                        break;
                    case 'DISPLAY_VALIDITY_END':
                        columnDefs.push(colValidityEndContract);
                        break;
                    case 'SCORE':
                        columnDefs.push(colScore);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'VALIDITY_EXPIRED':
                        columnDefs.push(colValidityExpired);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            CONCATENATED: null,
            CONCATENATED_COMPLEMENT: null,
            VERSION: null,
            PAYMENT_NATURE: null,
            PRODUCT: null,
            DIRECTION: null,
            TYPE_CARGO: null,
            TYPE_LOCAL: null,
            LOCAL: null,
            PROVIDERS: null,
            NETWORKS: null,
            LEGAL_PERSON_AGENTS: null,
            ACCOUNTS: null,
            EXPORTERS: null,
            IMPORTERS: null,
            SERVICE_TYPE: null,
            CHARGE: null,
            OBSERVATION: null,
            SCORE: null,
            SCORE_RULE: null,
            SCORE_DATE: null,
            SCORE_ERROR: null,
            VALIDITY_START: null,
            DISPLAY_VALIDITY_START: null,
            VALIDITY_END: null,
            DISPLAY_VALIDITY_END: null,
            EVENT: null,
            FREIGHT_BASIS: true,
            FILES: null,
            FILES_REMOVE: null,
            FILES_NEW: null,
            SITUATION: null,
            DETAILS: null,
            ACTIVE: true,
            VALIDITY_EXPIRED: true,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            DOCUMENT_ERROR: null
        };
        this.$scope.oldModel = angular.copy(this.$scope.model);
    }

    async register(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.updateControl();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.oldModel = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.$scope.chargeTableControl.data = [];
            this.initPanels(["collapseBasicData", "collapseCharges"], "collapseBasicData");
            this.$scope.chargesTableOptions.load(null, true);
            this.$scope.chargesTableOptions.crudButtons.add.disabled = false;
            this.$scope.chargesTableOptions.crudButtons.remove.disabled = false;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(model: ITariffLocalModel): Promise<void> {
        try {
            this.$scope.disableElements(true, ['filterChargeLoadReference']);
            this.initDateFields();
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
            this.$scope.oldModel = angular.copy(model);
            BrowserTitle.$id = this.$scope.model.CONCATENATED;
            this.updateControl();
            this.initPanels(["collapseCharges"], "collapseCharges");
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
            }
            this.$scope.chargesTableOptions.crudButtons.add.disabled = true;
            this.$scope.chargesTableOptions.crudButtons.remove.disabled = true;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(model: ITariffLocalModel): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');
            this.$scope.oldModel = angular.copy(model);
            BrowserTitle.$id = this.$scope.model.CONCATENATED;
            this.updateControl();
            this.initPanels(["collapseCharges"], "collapseCharges");
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
            }
            this.$scope.chargesTableOptions.crudButtons.add.disabled = false;
            this.$scope.chargesTableOptions.crudButtons.remove.disabled = false;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.updateControl();
            this.$scope.disableElements(false);
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            await this.clearFields(this.$scope.model, ['CONCATENATED', 'SCORE', 'SCORE_DATE', 'SCORE_RULE', 'SCORE_ERROR']);
            if (this.$scope.model.CHARGE && this.$scope.model.CHARGE.length > 0) {
                for (const charge of this.$scope.model.CHARGE) {
                    charge.ID = null;
                }
            }
            this.$scope.oldModel = angular.copy(this.$scope.model);
            this.$scope.chargesTableOptions.crudButtons.add.disabled = false;
            this.$scope.chargesTableOptions.crudButtons.remove.disabled = false;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        let proceed: boolean = true;
        try {
            if (!this.validateChargesMandatoryFields()) proceed = false;
            if (proceed && this.$scope.uploader.queue && this.$scope.uploader.queue.length > 0) {
                proceed = false;
                this.handleError(this.formService.getTranslate('GENERAL.MESSAGES.ERROR.FILES_IN_THE_QUEUE'));
            }

            if (proceed) {
                this.$scope.model.SCORE = null;
                this.$scope.model.SCORE_DATE = null;
                this.$scope.model.SCORE_RULE = null;
                this.$scope.model.SCORE_ERROR = null;
                if (this.$scope.oldModel) {
                    this.$scope.oldModel.SCORE = null;
                    this.$scope.oldModel.SCORE_DATE = null;
                    this.$scope.oldModel.SCORE_RULE = null;
                    this.$scope.oldModel.SCORE_ERROR = null;
                }
                this.SSEService.closeEvents();
                this.setCopy(false);
            }
        } catch (ex) {
            this.handleError(ex);
            proceed = false;
        } finally {
            this.customSave();
            return false;
        }
    }

    private async openReasonUpdateModal(confirmationData: ITariffChangeConfirmation) {
        try {
            if (!this.modalReasonUpdateId) this.modalReasonUpdateId = this.ModalService.newModal();
            this.$scope.reasonUpdateModal = { DESCRIPTION: confirmationData.DESCRIPTION, REASON_VALUE_CHANGE: null, REASON_VALIDITY_CHANGE: null, REASON_OBSERVATION: null };

            const body = `
            <div id="reasonModal">
                <div class="row form-group">
                    <div ng-if="${confirmationData.HAS_VALUE_CHANGE}" ng-class="${confirmationData.HAS_VALIDITY_CHANGE} ? 'col-lg-6' : 'col-lg-12'">
                        <label>{{'PRODUCT.REASON_VALUE_CHANGE' | translate}} <i class="fa fa-asterisk text-danger small-fontawesome" aria-hidden="true"
                        tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.MANDATORY_FIELD' | translate }}"
                        tooltip-append-to-body="true"></i></label>
                        <ui-select name="reasonValueChange" id="reasonValueChange"
                            ng-model="reasonUpdateModal.REASON_VALUE_CHANGE"
                            theme="bootstrap"
                            ng-change="selectorValidity(this.$select.ngModel.$name);"
                            ng-disabled="selectorDisabled(this.$select.ngModel.$name) || operation == 'view'"
                            ng-click="selectorFocus(this.$select.searchInput[0]);"
                            skip-focusser="true" required>
                            <ui-select-match placeholder="{{'GENERAL.UI_SELECT.SELECT' | translate }}">
                                {{$select.selected.NAME}}
                            </ui-select-match>
                            <ui-select-choices
                                repeat="item in reasonValueChangeList | filter: $select.search track by $index">
                                <div
                                    ng-bind-html="item.NAME | highlight: $select.search">
                                </div>
                            </ui-select-choices>
                            <ui-select-no-choice>
                                {{'GENERAL.UI_SELECT.EMPTY_SELECTOR_MESSAGE' | translate }}
                            </ui-select-no-choice>
                        </ui-select>
                    </div>
                    <div ng-if="${confirmationData.HAS_VALIDITY_CHANGE}" ng-class="${confirmationData.HAS_VALUE_CHANGE} ? 'col-lg-6' : 'col-lg-12'">
                        <label>{{'PRODUCT.REASON_VALIDITY_CHANGE' | translate}} <i class="fa fa-asterisk text-danger small-fontawesome" aria-hidden="true"
                        tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.MANDATORY_FIELD' | translate }}"
                        tooltip-append-to-body="true"></i></label>
                        <ui-select name="reasonValidityChange" id="reasonValidityChange"
                            ng-model="reasonUpdateModal.REASON_VALIDITY_CHANGE"
                            theme="bootstrap"
                            ng-change="selectorValidity(this.$select.ngModel.$name);"
                            ng-disabled="selectorDisabled(this.$select.ngModel.$name) || operation == 'view'"
                            ng-click="selectorFocus(this.$select.searchInput[0]);"
                            skip-focusser="true" required>
                            <ui-select-match placeholder="{{'GENERAL.UI_SELECT.SELECT' | translate }}">
                                {{$select.selected.NAME}}
                            </ui-select-match>
                            <ui-select-choices
                                repeat="item in reasonValidityChangeList | filter: $select.search track by $index">
                                <div
                                    ng-bind-html="item.NAME | highlight: $select.search">
                                </div>
                            </ui-select-choices>
                            <ui-select-no-choice>
                                {{'GENERAL.UI_SELECT.EMPTY_SELECTOR_MESSAGE' | translate }}
                            </ui-select-no-choice>
                        </ui-select>
                    </div>
                </div>
                <div class="row form-group" ng-if="reasonUpdateModal.REASON_VALUE_CHANGE.ID == '3' || reasonUpdateModal.REASON_VALIDITY_CHANGE.ID == '2'">
                    <div class="col-lg-12">
                        <label>{{'GENERAL.REMARKS' | translate}} <i class="fa fa-asterisk text-danger small-fontawesome" aria-hidden="true"
                        tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.MANDATORY_FIELD' | translate }}"
                        tooltip-append-to-body="true"></i></label>
                        <textarea class="form-control" name="reasonObservation" ng-model="reasonUpdateModal.REASON_OBSERVATION"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 space-list">
                        <ul class="list-group m-b-none">
                            <li class="list-group-item list-group-item-warning ellipsize full-width"
                                ng-repeat="description in reasonUpdateModal.DESCRIPTION track by $index"
                                style="max-width: 100%;" ellipsis-tooltip tooltip-placement="auto top"
                                uib-tooltip-html="description"
                                tooltip-enable="true" tooltip-append-to-body="true">
                                <span ng-bind-html="description"></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            `;

            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    modalID: this.modalReasonUpdateId,
                    scope: this.$scope,
                    formService: 'register',
                    size: 'lg modal-overflow',
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing" && closed) {
                            const modalData: IReasonUpdateModal = this.$scope.reasonUpdateModal;
                            const hasOthers = modalData && ((modalData.REASON_VALUE_CHANGE && modalData.REASON_VALUE_CHANGE.ID == ETariffChangeConfirmationReasonValue.OTHERS) || (modalData.REASON_VALIDITY_CHANGE && modalData.REASON_VALIDITY_CHANGE.ID == ETariffChangeConfirmationReasonValidity.OTHERS));
                            if (!modalData || (modalData && (confirmationData.HAS_VALUE_CHANGE && !modalData.REASON_VALUE_CHANGE)) || (confirmationData.HAS_VALIDITY_CHANGE && !modalData.REASON_VALIDITY_CHANGE) || (hasOthers && !modalData.REASON_OBSERVATION)) {
                                event.preventDefault();
                                const msgError = this.formService.getTranslate('GENERAL.ALL_FIELDS_MANDATORY');
                                this.formService.notifyError(msgError);
                            } else if (!await this.customSave({ _id: null, ID: null, ID_FREIGHT_ROUTES: null, ID_TARIFF_LOCAL: this.$scope.model.ID, UPDATED_BY: null, UPDATED_AT: null, CREATED_BY: null, CREATED_AT: null, REASON_VALUE_CHANGE: modalData.REASON_VALUE_CHANGE, REASON_VALIDITY_CHANGE: modalData.REASON_VALIDITY_CHANGE, OBSERVATION: modalData.REASON_OBSERVATION, DESCRIPTION: modalData.DESCRIPTION, HAS_VALUE_CHANGE: confirmationData.HAS_VALUE_CHANGE, HAS_VALIDITY_CHANGE: confirmationData.HAS_VALIDITY_CHANGE, ID_INLAND_ROUTE: null, ID_TARIFF_COMPLEMENTARY: null })) event.preventDefault();
                        }
                    }
                },
                {
                    closeButtonText: 'GENERAL.CANCEL',
                    actionButtonText: 'REGISTRATION.APPLY',
                    headerText: 'PRODUCT.TARIFF_CHANGE_CONFIRMATION',
                    bodyText: this.$sce.trustAsHtml(body),

                }
            );

            modalInstance.rendered.then(() => {
                const reasonModal = angular.element("#reasonModal");
                if (reasonModal) this.$compile(reasonModal)(this.$scope);
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async customSave(confirmationToUpdate?: ITariffChangeConfirmation): Promise<boolean> {
        let success = true;
        try {
            this.$scope.model.CHARGE = (this.$scope.model.CHARGE || []).map(charge => {
                charge.VALIDITY_START = new Date(charge.VALIDITY_START);
                charge.VALIDITY_START.setHours(0, 0, 0, 0);
                return charge;
            });

            this.block();
            const operation = this.$scope.operation == EOperation.EDIT ? 'update' : 'insert';
            const request = await this.ProductService.post({ route: `/tariffLocal/${operation}`, data: { data: this.$scope.model, oldData: this.$scope.oldModel, confirmation: confirmationToUpdate, timeout: 30000 } });
            if (request && request.data && request.data.data) {
                const confirmation: boolean = request.data.data.confirmation;
                if (!confirmation) {
                    success = false;
                    if (!confirmationToUpdate) {
                        this.unblock();
                        const confirmationData: ITariffChangeConfirmation = request.data.data.data;
                        this.openReasonUpdateModal(confirmationData);
                    }
                }
                if (success) this.finishUpdateControl();
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return success;
        }
    }

    private async finishUpdateControl() {
        delete this.$scope['newRegister'];
        await this.updateGrid();
        this.$scope.showForm = false;
        this.$scope.model = null;
        this.$scope.error = null;
        this.$scope.operation = null;
        this.$scope.$applyAsync();
        this.$formService.notifySuccess(this.formService.getTranslate("REGISTRATION.SUCCESSFUL_REGISTER"));
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == EOperation.NEW ? 'insert' : 'update';
        this.setCopy(false);
        return {
            route: `/tariffLocal/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.oldModel),
            timeout: 15000
        };
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "GENERAL.MENU.INTEGRATION" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number, idSubject: string): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/tariffLocal/sendSync`, { "idLocal": id, "idSubject": idSubject }, 120000, false);
                if (syncRequest) success = true;
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_SENDING_REQUEST');
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const tariffLocal = await this.getTariffLocalById(id);
                    if (row && tariffLocal && tariffLocal.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = tariffLocal.DOCUMENT_ERROR;
                        documentError = tariffLocal.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async getTariffLocalById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/tariffLocal/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
        this.setCopy(false);
    }

    private async callSessionFunctions(data: object): Promise<void> {
        if (!data) return;
        const tariffLocalExchangeData = <ITariffLocalExchangeData>data;
        let tariffLocalModel: ITariffLocalModel = null;
        for (const gridData of this.$scope.gridOptions.data) {
            if (gridData.ID == tariffLocalExchangeData.ID) {
                tariffLocalModel = gridData;
                break;
            }
        }
        switch (tariffLocalExchangeData.OPERATION) {
            case EOperation.VIEW: if (tariffLocalModel) this.$scope.fetchData(tariffLocalModel.ID, EOperation.VIEW);
                break;
            case EOperation.EDIT: if (tariffLocalModel) {
                setTimeout(() => {
                    this.editTariffLocal(tariffLocalModel);
                }, 300);
                break;
            }
            case EOperation.NEW: {
                if (!tariffLocalExchangeData.ID) this.$rootScope.breadCrumb.action();
                else await this.copyTariffFromHistory(parseInt(tariffLocalExchangeData.ID));
                break;
            }
        }
    }

    async copyTariffFromHistory(id: number) {
        try {
            const tariffLocal = await this.getTariffLocalHistoryById(id);
            if (tariffLocal) {
                const model: ITariffLocalModel = angular.copy(tariffLocal);
                model.ID = null;
                model._id = null;
                this.setCopy(true);
                await this.$scope.copy(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async getTariffLocalHistoryById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const tariffLocal = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/tariffLocalHistory/getById/${id}`, 30000, null, false);
            return tariffLocal ? tariffLocal.data : null;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private initCollapseEvents() {
        angular.element('#collapseCharges').on('shown.bs.collapse', (event: JQuery.Event) => {
            if (event.target == event.currentTarget) {
                angular.element("#chargeTableFilter").addClass("in");
            }
        });

        angular.element('#collapseCharges').on('hidden.bs.collapse', (event: JQuery.Event) => {
            if (event.target == event.currentTarget) {
                angular.element("#chargeTableFilter").removeClass("in");
            }
        });
    }

    /* 
        If Receiving buying is not mandatory.
        If Receiving selling is mandatory.
        If Payment buying/selling is mandatory, except if free of charge = true.
    */
    private validateChargesMandatoryFields(): boolean {
        let valid = true;
        try {
            if (this.$scope.model.CHARGE && this.$scope.model.CHARGE.length) {
                const isPaymentNature = this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.PAYMENT;
                const isReceivingNature = this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.RECEIVING;
                for (let index = 0; index < this.$scope.model.CHARGE.length; index++) {
                    const charge: ICharge = this.$scope.model.CHARGE[index];
                    const payment = charge.TARIFF_LOCAL_PAYMENT;
                    const receiving = charge.TARIFF_LOCAL_RECEIVING;
                    const isWeightRangeApplication = charge.APPLICATION && charge.APPLICATION.APPLICATION_COMPLEMENT && charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
                    if (isPaymentNature) {
                        if ((!payment.FREE_OF_CHARGE && (!payment.CURRENCY || payment.MIN == null || (!isWeightRangeApplication && payment.UNITARY == null) || (isWeightRangeApplication && charge.FLAT && payment.UNITARY == null))) ||
                            (!receiving.FREE_OF_CHARGE && (!receiving.CURRENCY || receiving.MIN == null || (!isWeightRangeApplication && receiving.UNITARY == null) || (isWeightRangeApplication && charge.FLAT && receiving.UNITARY == null)))) {
                            valid = false;
                            this.formService.notifyError(this.formService.getTranslate("REGISTRATION.MESSAGES.ERROR.CHARGE_BASIS_HAS_MANDATORY_FIELDS_PENDING", { charge: `${charge.CHARGE_NAME_EXHIBITION.CODE} - ${charge.CHARGE_NAME_EXHIBITION.NAME}`, basis: charge.APPLICATION.CODE }));
                            break;
                        }
                    } else if (isReceivingNature) {
                        if (!receiving.FREE_OF_CHARGE && (!receiving.CURRENCY || receiving.MIN == null || (!isWeightRangeApplication && receiving.UNITARY == null) || (isWeightRangeApplication && charge.FLAT && receiving.UNITARY == null))) {
                            valid = false;
                            this.formService.notifyError(this.formService.getTranslate("REGISTRATION.MESSAGES.ERROR.CHARGE_BASIS_HAS_MANDATORY_FIELDS_PENDING", { charge: `${charge.CHARGE_NAME_EXHIBITION.CODE} - ${charge.CHARGE_NAME_EXHIBITION.NAME}`, basis: charge.APPLICATION.CODE }));
                            break;
                        }
                    }
                }
            }
        } catch (ex) {
            this.handleError(ex);
            valid = false;
        }
        return valid;
    }

    private async editTariffLocal(tariffLocal: ITariffLocalModel): Promise<void> {
        let blockedObject = {
            ID: tariffLocal.ID,
            NAME: tariffLocal.CONCATENATED,
            EMAIL: this.$scope.user['email'],
            FORM_NAME: this.gridName
        };
        this.SSEService.closeEvents();
        this.SSEService.setBlockedObject(blockedObject);
        this.SSEService.initEvents();
        this.SSEService.events.onmessage = async (event) => {
            const parsedData = JSON.parse(event.data);
            if (!parsedData.status) {
                const result = await this.SSEService.generate(parsedData);
                if (result && !result.status) {
                    this.$rootScope.refreshPage();
                    return;
                }
                if (this.$scope.operation !== EOperation.VIEW || tariffLocal.ID !== this.$scope.model.ID) this.$scope.viewTariffLocal(tariffLocal);
            } else if (this.$scope.operation !== EOperation.EDIT || tariffLocal.ID !== this.$scope.model.ID) {
                this.$scope.fetchData(tariffLocal.ID, EOperation.EDIT);
            }
        };
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/tariffLocal/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private validateOverseasAgent(model : ITariffLocalModel): boolean{
        if(this.validateEA(model)) return false;
        if(model.PAYMENT_NATURE && model.PAYMENT_NATURE.ID === '1' && ( !model.NETWORKS || model.NETWORKS.length === 0 ) && ( !model.PROVIDERS || model.PROVIDERS.length === 0)) return true;
        return false;
    }

    private validateExternalAgentNetwork(model : ITariffLocalModel): boolean{
        if(this.validateEA(model)) return false;
        if(model.PAYMENT_NATURE && model.PAYMENT_NATURE.ID === '1' && ( !model.PROVIDERS || model.PROVIDERS.length === 0 ) && ( !model.LEGAL_PERSON_AGENTS || model.LEGAL_PERSON_AGENTS.length === 0 )) return true;
        return false;
    }

    private validateProvider(model : ITariffLocalModel): boolean{
        if(this.validateEA(model)) return false;
        if(model.PAYMENT_NATURE && model.PAYMENT_NATURE.ID === '1' && ( !model.NETWORKS || model.NETWORKS.length === 0 ) && ( !model.LEGAL_PERSON_AGENTS || model.LEGAL_PERSON_AGENTS.length === 0 )) return true;
        return false;
    }

    private validateEA(model : ITariffLocalModel): boolean{
        if(model.PRODUCT && model.PRODUCT.ID == 'EA' && model.PAYMENT_NATURE && model.PAYMENT_NATURE.ID == '1' && model.DIRECTION.ID == '1') return true;
        return false;
    }

    private async updateControl() {
        const product = this.$scope.model.PRODUCT;
        this.$scope.isAirProduct = product && (product.ID == EProductId.AIR_IMPORT || product.ID == EProductId.AIR_EXPORT);
        this.$scope.isMaritimeProduct = product && (product.ID == EProductId.MARITIME_IMPORT || product.ID == EProductId.MARITIME_EXPORT);
        this.$scope.serviceTypeList = await this.getServiceTypeListByName();
    }

    private async getGenericList(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 30000);
        return generic && generic.data ? generic.data : [];
    }

    private async addCharge(): Promise<void> {
        try {
            const rateType = this.getChargeRateTypeBaseOnPaymentNature();
            const charge: ICharge = {
                _id: null,
                ID: null,
                DISPLAY_INDEX: null,
                ID_TARIFF_LOCAL: null,
                ID_CHARGE_NAME_EXHIBITION: null,
                CHARGE_NAME_EXHIBITION: null,
                APPLICATION: null,
                EQUIPMENT: null,
                WEIGHT_RANGE: null,
                VEHICLE_TYPE: null,
                FLAT: false,
                VALIDITY_START: null,
                VALIDITY_END: null,
                GROUP: false,
                RATE_TYPE: rateType,
                EVENT: angular.copy(this.$scope.model.EVENT),
                FREIGHT_BASIS: angular.copy(this.$scope.model.FREIGHT_BASIS),
                TARIFF_LOCAL_PAYMENT: {
                    ID_CURRENCY: null,
                    CURRENCY: null,
                    UNITARY: null,
                    MIN: null,
                    FREE_OF_CHARGE: this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.RECEIVING ? true : false,
                    HOLDER_TYPE: null,
                    ID_LEGAL_PERSON_HOLDER: null,
                    ID_PHYSICAL_PERSON_HOLDER: null,
                    LEGAL_PERSON_HOLDER: null,
                    PHYSICAL_PERSON_HOLDER: null,
                    WEIGHT_RANGE_CHARGE: null
                },
                TARIFF_LOCAL_RECEIVING: {
                    ID_CURRENCY: null,
                    CURRENCY: null,
                    UNITARY: null,
                    MIN: null,
                    FREE_OF_CHARGE: false,
                    HOLDER_TYPE: null,
                    ID_LEGAL_PERSON_HOLDER: null,
                    ID_PHYSICAL_PERSON_HOLDER: null,
                    LEGAL_PERSON_HOLDER: null,
                    PHYSICAL_PERSON_HOLDER: null,
                    WEIGHT_RANGE_CHARGE: null
                }
            };

            if (!this.$scope.model.CHARGE) this.$scope.model.CHARGE = [];
            this.openChargeModal(charge);

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private editCharge(index: number, displayIndex: number): void {
        try {
            if (!index && index != 0) throw Error('index is null');

            let charge: ICharge = null;
            charge = this.getRatesByTheCorrectIndex(index);

            if (!charge) throw Error('charge is null');
            this.openChargeModal(charge, this.$scope.model.CHARGE.indexOf(charge), charge.DISPLAY_INDEX);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private copyCharge(index: number): void {
        try {
            if (!index && index != 0) throw Error('index is null');
            const charge: ICharge = this.getRatesByTheCorrectIndex(index);
            if (!charge) throw Error('charge is null');
            const copyCharge = angular.copy(charge);
            copyCharge._id = null;
            copyCharge.ID = null;

            this.openChargeModal(copyCharge);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async openChargeModal(charge: ICharge, index?: number, displayIndex?: number) {
        try {
            let errorMessage: string = '';
            if (!this.$scope.model.PAYMENT_NATURE) errorMessage = 'payment nature';
            if (!this.$scope.model.DIRECTION) errorMessage += (errorMessage ? ', ' : '') + 'direction';
            if (!this.$scope.model.TYPE_CARGO) errorMessage += (errorMessage ? ', ' : '') + 'type cargo';
            if (!this.$scope.model.PRODUCT) errorMessage += (errorMessage ? ', ' : '') + 'product';
            if (errorMessage) throw Error(`Select ${errorMessage} first`);

            this.modalChargeId = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    modalID: this.modalChargeId,
                    scope: this.$scope,
                    formService: 'register',
                    template: require("../view/modals/tariffLocalChargeModal.html"),
                    size: 'vlg',
                    keyboard: true,
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing") {
                            if (reason.toString() == "escape key press") event.preventDefault();
                            if (!closed) {
                                const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
                                modalScope.closeChargeModal();
                            }
                        }
                    }
                },
                null
            );
            modalInstance.rendered.then(async () => {
                const modalScope = await this.buildChargeModalScope(index, displayIndex, charge);
                this.$timeout(() => {
                    modalScope.oldCharge = angular.copy(modalScope.charge);
                });
                modalScope.$applyAsync();

                angular.element('.collapseWeightRanges').on('shown.bs.collapse', (event: JQuery.Event) => {
                    if (event.target == event.currentTarget) {
                        angular.element("#collapsePaymentPanelHeading").attr('aria-expanded', 'true');
                        angular.element("#collapseReceivingPanelHeading").attr('aria-expanded', 'true');
                    }
                });

                angular.element('.collapseWeightRanges').on('hidden.bs.collapse', (event: JQuery.Event) => {
                    if (event.target == event.currentTarget) {
                        angular.element("#collapsePaymentPanelHeading").attr('aria-expanded', 'false');
                        angular.element("#collapseReceivingPanelHeading").attr('aria-expanded', 'false');
                    }
                });

                modalScope.selectorValidity("chargeNameExhibition");
                modalScope.selectorValidity("param");
                modalScope.selectorValidity("weightRange");
                modalScope.selectorValidity("equipment");
                modalScope.selectorValidity("vehicleType");
                modalScope.selectorValidity("validityBasis");
                modalScope.selectorValidity("paymentCurrency");
                modalScope.selectorValidity("receivingCurrency");
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async buildChargeModalScope(index: number, displayIndex: number, charge: ICharge): Promise<IChargeModalScope> {
        try {
            const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
            modalScope.operation = this.$scope.operation == EOperation.VIEW ? EOperation.VIEW : (!index && index != 0) ? EOperation.NEW : EOperation.EDIT;
            modalScope.currentIndex = index;
            modalScope.currentDisplayIndex = displayIndex;
            modalScope.charge = angular.copy(charge);
            modalScope.chargeApplicationList = await this.getChargeNameParamsList();
            modalScope.equipmentList = await this.getEquipmentListByName(null);
            modalScope.isPaymentNature = this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.PAYMENT;
            modalScope.isReceivingNature = this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.RECEIVING;
            this.validityStartEndKick();

            modalScope.applyCharge = async () => {
                if (await this.applyCharge(modalScope.currentIndex, modalScope.charge, modalScope.operation, true)) {
                    this.$scope.chargeTableControl.data = this.$scope.model.CHARGE;
                    this.applyChargeFilterTable();
                }
            }
            modalScope.closeChargeModal = () => {
                this.closeChargeModal();
            }
            modalScope.hasPreviousCharge = (currentDisplayIndex: number) => {
                return currentDisplayIndex > 0;
            }
            modalScope.hasNextCharge = (currentDisplayIndex: number) => {
                return (currentDisplayIndex || currentDisplayIndex == 0) && this.$scope.chargesListDisplay && (this.$scope.chargesListDisplay.length - 1 > currentDisplayIndex);
            }
            modalScope.previousCharge = (currentDisplayIndex: number) => {
                this.previousCharge(currentDisplayIndex);
            }
            modalScope.nextCharge = (currentDisplayIndex: number) => {
                this.nextCharge(currentDisplayIndex);
            }
            modalScope.setChargeNameId = (): void => {
                this.setChargeNameId();
            }
            modalScope.isNotApplicableComplement = (applicationComplement: SelectorModel): boolean => {
                return !applicationComplement || (applicationComplement && applicationComplement.ID == EApplicationComplementId.NOT_APPLICABLE);
            }
            modalScope.isWeightRangeComplement = (applicationComplement: SelectorModel): boolean => {
                return applicationComplement && applicationComplement.ID == EApplicationComplementId.WEIGHT_RANGE;
            }
            modalScope.isEquipmentComplement = (applicationComplement: SelectorModel): boolean => {
                return applicationComplement && applicationComplement.ID == EApplicationComplementId.EQUIPMENT;
            }
            modalScope.isVehicleComplement = (applicationComplement: SelectorModel): boolean => {
                return applicationComplement && applicationComplement.ID == EApplicationComplementId.VEHICLE;
            }
            modalScope.updateParam = (selected: IChargeNameList): void => {
                this.updateParam(selected);
            }
            modalScope.flatChange = () => {
                this.flatChange();
            }
            modalScope.weightRangeChange = () => {
                this.weightRangeChange();
            }
            modalScope.freightBasisChange = () => {
                modalScope.charge.EVENT = null;
                this.$timeout(() => {
                    modalScope.selectorValidity("validityBasis");
                });
            }
            modalScope.freeOfChargePaymentChange = (charge: ICharge) => {
                this.freeOfChargePaymentChange(charge);
                this.$timeout(() => { modalScope.selectorValidity("paymentCurrency"); });
            }
            modalScope.freeOfChargeReceivingChange = (charge: ICharge) => {
                this.freeOfChargeReceivingChange(charge);
                this.$timeout(() => { modalScope.selectorValidity("receivingCurrency"); });
            }
            modalScope.getChargeNameListByName = async (search: string): Promise<void> => {
                modalScope.chargeNameList = await this.getChargeNameListByName(search);
            }
            modalScope.getCurrencyListByName = async (name: string): Promise<void> => {
                modalScope.currencyList = await this.getCurrencyListByName(name);
            }
            modalScope.getWeightRangeListByName = async (search: string): Promise<void> => {
                modalScope.weightRangeList = await this.getWeightRangeListByName(search);
            }
            modalScope.getHolderListByName = async (search: string, holderId: string) => {
                let holderList: SelectorModel[] = [];
                if (search && search.length >= 3 && holderId) {
                    holderList = holderId == "1" ? await this.getLegalPersonListByName({ specializations: [], search: search }) : await this.getPhysicalPersonListByName({ specializations: [], roles: [], search: search });
                }
                modalScope.holderList = holderList;
            }
            modalScope.updatePaymentUnit = (): void => {
                this.updatePaymentUnit();
            }
            modalScope.updateReceivingUnit = (): void => {
                this.updateReceivingUnit();
            }
            modalScope.updateHolderTypePayment = (): void => {
                this.updateHolderTypePayment();
            }
            modalScope.updateHolderTypeReceiving = (): void => {
                this.updateHolderTypeReceiving();
            }

            modalScope.validityStartEndKick = (): void => {
                this.validityStartEndKick();
            }

            return modalScope;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async previousCharge(currentDisplayIndex: number) {
        const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
        const previousDisplayCharge = this.$scope.chargesListDisplay[currentDisplayIndex - 1];
        const previousChargeIndex = this.$scope.model.CHARGE && this.$scope.model.CHARGE.length ? this.$scope.model.CHARGE.findIndex((charge, index) => previousDisplayCharge.ID ? charge.ID == previousDisplayCharge.ID : index == (currentDisplayIndex - 1)) : -1;
        if (previousChargeIndex >= 0) {
            if (this.hasChanges(modalScope.charge, modalScope.oldCharge)) {
                const confirm = await this.modalSaveConfirmation("REGISTRATION.BACK", "GENERAL.CANCEL");
                if (!confirm || confirm && !await this.applyCharge(modalScope.currentIndex, modalScope.charge, modalScope.operation, false)) return;
            }
            modalScope.currentIndex = previousChargeIndex;
            modalScope.currentDisplayIndex--;

            const chargeUpdated = this.$scope.model.CHARGE[previousChargeIndex];
            if (chargeUpdated) {
                modalScope.charge = angular.copy(chargeUpdated);
                modalScope.oldCharge = angular.copy(chargeUpdated);
            }
            modalScope.$applyAsync();
        }
    }

    private async nextCharge(currentDisplayIndex: number) {
        const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
        const nextDisplayCharge = this.$scope.chargesListDisplay[currentDisplayIndex + 1];
        const nextChargeIndex = nextDisplayCharge && this.$scope.model.CHARGE && this.$scope.model.CHARGE.length ? this.$scope.model.CHARGE.findIndex((charge, index) => nextDisplayCharge.ID ? charge.ID == nextDisplayCharge.ID : index == (currentDisplayIndex + 1)) : -1;
        if (nextChargeIndex >= 0) {
            if (this.hasChanges(modalScope.charge, modalScope.oldCharge)) {
                const confirm = await this.modalSaveConfirmation("GENERAL.NEXT", "GENERAL.CANCEL");
                if (!confirm || (confirm && !await this.applyCharge(modalScope.currentIndex, modalScope.charge, modalScope.operation, false))) return;
            }
            modalScope.currentIndex = nextChargeIndex;
            modalScope.currentDisplayIndex++;

            const charge = this.$scope.model.CHARGE[nextChargeIndex];
            if (charge) {
                modalScope.charge = angular.copy(charge);
                modalScope.oldCharge = angular.copy(charge);
            }
            modalScope.$applyAsync();
        }
    }

    private async applyCharge(index: number, charge: any, operation: string, closeModal?: boolean): Promise<boolean> {
        let success = true;
        try {
            if (!charge) throw Error('charge is null');
            const hasInvalid = this.hasInvalidRequiredElements('tariffLocalChargeModal') || !ValidateUtil.isValidDateRange(charge.VALIDITY_START, charge.VALIDITY_END);
            if (hasInvalid) success = false;
            else {
                if (operation == EOperation.NEW) {
                    if (!this.$scope.model.CHARGE) this.$scope.model.CHARGE = [];
                    this.$scope.model.CHARGE.push(charge);
                }
                else if (operation == EOperation.EDIT) {
                    this.$scope.model.CHARGE.splice(index, 1, charge);
                }
                if (closeModal) {
                    this.ModalService.closeModal(this.modalChargeId);
                    this.modalChargeId = 0;
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            const msgSuccess = this.formService.getTranslate('FINANCIAL.CHARGE_DATA_SAVED_SUCCESSFULLY');
            if (success) this.formService.notifySuccess(msgSuccess);
            return success;
        }
    }

    private async closeChargeModal() {
        const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
        if (this.$scope.hasChanges(JSON.stringify(modalScope.charge), JSON.stringify(modalScope.oldCharge))) {
            const confirm = await this.modalSaveConfirmation("GENERAL.CLOSE", "GENERAL.CLOSE");
            if (confirm && !await this.applyCharge(modalScope.currentIndex, modalScope.charge, modalScope.operation)) return;
            else this.$scope.chargesTableOptions.load(null, true);
        }
        this.ModalService.closeModal(this.modalChargeId);
        this.modalChargeId = 0;
    }

    private async removeCharge(index: number): Promise<void> {
        try {
            if (!index && index !== 0) throw Error('index is null');
            const thatTranslated = this.formService.getTranslate("GENERAL.GENDER.THAT", null, true);
            const chargeTranslated = this.formService.getTranslate("BASIC_DATA.CHARGE", null, true);
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thatTranslated, prop: chargeTranslated })
            });

            if (!modal) return;

            this.formService.block();

            if (this.$scope.model.CHARGE) {
                const charge = this.getRatesByTheCorrectIndex(index);
                this.$scope.model.CHARGE.splice(this.$scope.model.CHARGE.indexOf(charge), 1);
                this.$scope.chargeTableControl.data = this.$scope.model.CHARGE;
                this.$scope.chargesTableOptions.load(null, true);
            }

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async flatChange() {
        try {
            const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
            if (modalScope.charge) {
                if (modalScope.charge.FLAT) {
                    const availableWeightRangeList = await this.getWeightRangeListByName(null);
                    const availableWeightRangeToConcat = availableWeightRangeList.filter(weightRange => (!modalScope.charge.WEIGHT_RANGE || !(modalScope.charge.WEIGHT_RANGE && modalScope.charge.WEIGHT_RANGE.some((chargeWeightRange) => chargeWeightRange.ID == weightRange.ID))));
                    if (modalScope.charge.WEIGHT_RANGE) modalScope.charge.WEIGHT_RANGE = modalScope.charge.WEIGHT_RANGE.concat(availableWeightRangeToConcat);
                    else modalScope.charge.WEIGHT_RANGE = availableWeightRangeToConcat;
                    this.weightRangeChange();
                } else {
                    modalScope.charge.TARIFF_LOCAL_PAYMENT.UNITARY = null;
                    modalScope.charge.TARIFF_LOCAL_RECEIVING.UNITARY = null;
                }
                modalScope.$applyAsync();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async weightRangeChange(onlyPayment?: boolean, onlyReceiving?: boolean) {
        try {
            const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
            const chargeWeightRange = modalScope.charge.WEIGHT_RANGE;
            if (this.isEmptyArray(chargeWeightRange)) {
                if (!onlyReceiving) modalScope.charge.TARIFF_LOCAL_PAYMENT.WEIGHT_RANGE_CHARGE = null;
                if (!onlyPayment) modalScope.charge.TARIFF_LOCAL_RECEIVING.WEIGHT_RANGE_CHARGE = null;
            } else {
                if (!onlyReceiving && !modalScope.charge.TARIFF_LOCAL_PAYMENT.WEIGHT_RANGE_CHARGE) modalScope.charge.TARIFF_LOCAL_PAYMENT.WEIGHT_RANGE_CHARGE = [];
                if (!onlyPayment && !modalScope.charge.TARIFF_LOCAL_RECEIVING.WEIGHT_RANGE_CHARGE) modalScope.charge.TARIFF_LOCAL_RECEIVING.WEIGHT_RANGE_CHARGE = [];
                for (const weightRange of chargeWeightRange) {
                    // Payment
                    if (!onlyReceiving && !modalScope.charge.TARIFF_LOCAL_PAYMENT.FREE_OF_CHARGE) {
                        const hasPaymentWeightRangeAlready = modalScope.charge.TARIFF_LOCAL_PAYMENT.WEIGHT_RANGE_CHARGE.some(paymentWeightRange => paymentWeightRange.WEIGHT_RANGE && paymentWeightRange.WEIGHT_RANGE.ID == weightRange.ID);
                        // Create the lines with the weight ranges if they don't exist
                        if (this.isEmptyArray(modalScope.charge.TARIFF_LOCAL_PAYMENT.WEIGHT_RANGE_CHARGE) || !hasPaymentWeightRangeAlready) {
                            modalScope.charge.TARIFF_LOCAL_PAYMENT.WEIGHT_RANGE_CHARGE.push({ WEIGHT_RANGE: weightRange, UNITARY: modalScope.charge.FLAT ? modalScope.charge.TARIFF_LOCAL_PAYMENT.UNITARY : null });
                        } else if (modalScope.charge.FLAT) {
                            // Fill in unit values case is FLAT
                            for (const weightRangeCharge of modalScope.charge.TARIFF_LOCAL_PAYMENT.WEIGHT_RANGE_CHARGE) { weightRangeCharge.UNITARY = modalScope.charge.TARIFF_LOCAL_PAYMENT.UNITARY }
                        }
                    }

                    // Receiving
                    if (!onlyPayment && !modalScope.charge.TARIFF_LOCAL_RECEIVING.FREE_OF_CHARGE) {
                        const hasReceivingWeightRangeAlready = modalScope.charge.TARIFF_LOCAL_RECEIVING.WEIGHT_RANGE_CHARGE.some(receivingWeightRange => receivingWeightRange.WEIGHT_RANGE && receivingWeightRange.WEIGHT_RANGE.ID == weightRange.ID);
                        // Create the lines with the weight ranges if they don't exist
                        if (this.isEmptyArray(modalScope.charge.TARIFF_LOCAL_RECEIVING.WEIGHT_RANGE_CHARGE) || !hasReceivingWeightRangeAlready) {
                            modalScope.charge.TARIFF_LOCAL_RECEIVING.WEIGHT_RANGE_CHARGE.push({ WEIGHT_RANGE: weightRange, UNITARY: modalScope.charge.FLAT ? modalScope.charge.TARIFF_LOCAL_RECEIVING.UNITARY : null });
                        } else if (modalScope.charge.FLAT) {
                            // Fill in unit values case is FLAT
                            for (const weightRangeCharge of modalScope.charge.TARIFF_LOCAL_RECEIVING.WEIGHT_RANGE_CHARGE) { weightRangeCharge.UNITARY = modalScope.charge.TARIFF_LOCAL_RECEIVING.UNITARY }
                        }
                    }
                }
                // Keep the correct list in case of removal
                modalScope.charge.TARIFF_LOCAL_PAYMENT.WEIGHT_RANGE_CHARGE = modalScope.charge.TARIFF_LOCAL_PAYMENT.WEIGHT_RANGE_CHARGE.filter(weightRangeCharge => chargeWeightRange && chargeWeightRange.some(weightRange => weightRange.ID == weightRangeCharge.WEIGHT_RANGE.ID));
                if (!modalScope.charge.TARIFF_LOCAL_PAYMENT.WEIGHT_RANGE_CHARGE.length) modalScope.charge.TARIFF_LOCAL_PAYMENT.WEIGHT_RANGE_CHARGE = null;
                modalScope.charge.TARIFF_LOCAL_RECEIVING.WEIGHT_RANGE_CHARGE = modalScope.charge.TARIFF_LOCAL_RECEIVING.WEIGHT_RANGE_CHARGE.filter(weightRangeCharge => chargeWeightRange && chargeWeightRange.some(weightRange => weightRange.ID == weightRangeCharge.WEIGHT_RANGE.ID));
                if (!modalScope.charge.TARIFF_LOCAL_RECEIVING.WEIGHT_RANGE_CHARGE.length) modalScope.charge.TARIFF_LOCAL_RECEIVING.WEIGHT_RANGE_CHARGE = null;
                modalScope.$applyAsync();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async freeOfChargePaymentChange(charge: ICharge): Promise<ICharge> {
        const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
        if (charge && charge.TARIFF_LOCAL_PAYMENT && charge.TARIFF_LOCAL_PAYMENT.FREE_OF_CHARGE) {
            charge.TARIFF_LOCAL_PAYMENT.ID_CURRENCY = null;
            charge.TARIFF_LOCAL_PAYMENT.CURRENCY = null;
            charge.TARIFF_LOCAL_PAYMENT.UNITARY = null;
            charge.TARIFF_LOCAL_PAYMENT.MIN = null;
            charge.TARIFF_LOCAL_PAYMENT.HOLDER_TYPE = null;
            charge.TARIFF_LOCAL_PAYMENT.ID_LEGAL_PERSON_HOLDER = null;
            charge.TARIFF_LOCAL_PAYMENT.LEGAL_PERSON_HOLDER = null;
            charge.TARIFF_LOCAL_PAYMENT.ID_PHYSICAL_PERSON_HOLDER = null;
            charge.TARIFF_LOCAL_PAYMENT.PHYSICAL_PERSON_HOLDER = null;
            charge.TARIFF_LOCAL_PAYMENT.WEIGHT_RANGE_CHARGE = null;
            this.$timeout(() => {
                modalScope.selectorValidity("paymentCurrency");
            });
        }
        this.weightRangeChange(true);
        return charge;
    }

    private async freeOfChargeReceivingChange(charge: ICharge): Promise<ICharge> {
        const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
        if (charge && charge.TARIFF_LOCAL_RECEIVING && charge.TARIFF_LOCAL_RECEIVING.FREE_OF_CHARGE) {
            charge.TARIFF_LOCAL_RECEIVING.ID_CURRENCY = null;
            charge.TARIFF_LOCAL_RECEIVING.CURRENCY = null;
            charge.TARIFF_LOCAL_RECEIVING.UNITARY = null;
            charge.TARIFF_LOCAL_RECEIVING.MIN = null;
            charge.TARIFF_LOCAL_RECEIVING.HOLDER_TYPE = null;
            charge.TARIFF_LOCAL_RECEIVING.ID_LEGAL_PERSON_HOLDER = null;
            charge.TARIFF_LOCAL_RECEIVING.LEGAL_PERSON_HOLDER = null;
            charge.TARIFF_LOCAL_RECEIVING.ID_PHYSICAL_PERSON_HOLDER = null;
            charge.TARIFF_LOCAL_RECEIVING.PHYSICAL_PERSON_HOLDER = null;
            charge.TARIFF_LOCAL_RECEIVING.WEIGHT_RANGE_CHARGE = null;
            this.$timeout(() => {
                modalScope.selectorValidity("receivingCurrency");
            });
        }
        this.weightRangeChange(false, true);
        return charge;
    }

    private applyChargeFilterTable() {
        if (this.$scope.model) {
            let chargeList = this.$scope.model.CHARGE;
            const loadReference = moment(moment(this.$scope.chargeTableControl.chargeFilter.LOAD_REFERENCE).format('YYYY-MM-DD'));
            if (loadReference.isValid()) {
                chargeList = chargeList.filter(charge => {
                    const start = moment(moment(charge.VALIDITY_START).format('YYYY-MM-DD'));
                    const end = moment(moment(charge.VALIDITY_END).format('YYYY-MM-DD'));
                    return (loadReference.isBetween(start, end) || loadReference.isSame(start) || loadReference.isSame(end));
                });
            }
            this.$scope.chargeTableControl.data = chargeList;
            this.$scope.chargesTableOptions.load(null, true);
        }
    }

    private async getCountryListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const countryList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/country/list/custom`, { search }, 30000, false);
                result = (countryList) ? countryList.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.countryList = result;
            this.formService.unblock();
        }
    }

    private async getRoutingPointListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                if (!this.$scope.model.TYPE_LOCAL || this.$scope.model.TYPE_LOCAL.length === 0) throw Error(`Select a type first`);
                this.formService.block();
                const types = this.$scope.model.TYPE_LOCAL.map(item => item.ID);
                const routingPoints = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/routingPoint/list/custom/`, { name: search, types }, 30000, false);
                result = routingPoints ? routingPoints.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, DISPLAY_NAME: x.DISPLAY_NAME } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.routingPointList = result;
            this.formService.unblock();
        }
    }

    private async getProviderListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                if (!this.$scope.model.PRODUCT || !this.$scope.model.PRODUCT.ID) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));

                let types = [];
                if (this.$scope.model.PRODUCT.ID == EProductId.AIR_IMPORT || this.$scope.model.PRODUCT.ID == EProductId.AIR_EXPORT) {
                    types = [EProviderTypeId.AIRLINE];
                } else if (this.$scope.model.PRODUCT.ID == EProductId.MARITIME_IMPORT || this.$scope.model.PRODUCT.ID == EProductId.MARITIME_EXPORT) {
                    types = [EProviderTypeId.SHIPOWNER];
                } else if (this.$scope.model.PRODUCT.ID == EProductId.ROAD_IMPORT || this.$scope.model.PRODUCT.ID == EProductId.ROAD_EXPORT) {
                    types = [EProviderTypeId.SHIPPING_COMPANY];
                } else {
                    throw Error(`Select a valid product`);
                }
                this.formService.block();

                const providers: IProviderModel[] = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/provider/list/custom`, { search, types }, 30000, false);
                result = providers ? providers.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.SCAC_IATA, TYPE: x.TYPE } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.providerList = result;
        }
    }

    private async getNetworkListByName(name: string): Promise<void> {
        let result = [];
        try {
            if (name && name.length >= 2) {
                this.formService.block();

                const networks = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/network/listByLegalPersonSpecType`, { name: name, types: ['12'] }, 30000, false);
                result = networks ? networks.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.networkList = result;
        }
    }

    private async getAgentListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                if (!this.$scope.model.PRODUCT) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                this.formService.block();

                const products = [this.$scope.model.PRODUCT.ID];
                const agents = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/agent/list/custom`, { search, products }, 30000, false);
                result = agents ? agents.map(x => { return { ID: x.ID, NAME: x.NAME } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.agentList = result;
        }
    }

    private async getAccountListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const accounts = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/account/list/custom`, { search: search }, 30000, false);
                result = accounts ? accounts.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.LEGAL_PERSON ? x.LEGAL_PERSON.SHORT_NAME : null } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.accountList = result;
            this.formService.unblock();
        }
    }

    private async getCurrencyListByName(name: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            if (name && name.length >= 2) {
                this.formService.block();

                const currencies = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/currency/list/custom`, { name: name }, 10000, false);
                result = (currencies) ? currencies.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getChargeNameListByName(search: string): Promise<IChargeNameList[]> {
        let result: IChargeNameList[] = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                let types = null;
                if (this.$scope.model.DIRECTION.ID === EDirectionId.ORIGIN) {
                    types = [EChargeOriginId.ORIGIN, EChargeOriginId.TAX];
                } else if (this.$scope.model.DIRECTION.ID === EDirectionId.DESTINATION) {
                    types = [EChargeOriginId.DESTINATION, EChargeOriginId.TAX];
                } else {
                    throw Error('Invalid direction');
                }

                const products = [this.$scope.model.PRODUCT.ID];
                const paramTypeCargo = [this.$scope.model.TYPE_CARGO.ID];
                const chargeNames = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/chargeName/list/custom/exhibition`, { search, types, products, paramTypeCargo }, 30000, false);
                result = chargeNames ? chargeNames.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PARAMS: x.PARAMS } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getEquipmentListByName(search: string): Promise<IEquipmentSelector[]> {
        let result: IEquipmentSelector[] = [];
        try {
            if (!this.$scope.model.PRODUCT) {
                throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
            }

            this.formService.block();

            const products = [this.$scope.model.PRODUCT.ID];
            const equipmentList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/equipment/list/custom`, { search, products }, 30000, false);
            result = equipmentList ? equipmentList.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, TEU: x.TEU, FEET: x.FEET } }) : [];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getServiceTypeListByName(search?: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            if (!this.$scope.model.PRODUCT) return result;
            else {
                this.formService.block();
                const products = [this.$scope.model.PRODUCT.ID];
                const serviceTypeList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/serviceType/list/custom`, { search, products }, 30000, false);
                result = serviceTypeList ? serviceTypeList.map(x => { return { ID: x.ID, NAME: x.NAME } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getWeightRangeListByName(search: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.formService.block();
            const products = this.$scope.model.PRODUCT ? [this.$scope.model.PRODUCT.ID] : [];
            const weightRangeList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/weightRange/list/custom`, { search, products }, 30000, false);
            result = weightRangeList ? weightRangeList.map(x => { return { ID: x.ID, NAME: x.NAME } }) : [];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getExporterListByName(search: string): Promise<void> {
        let result = [];
        try {
            result = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.EXPORTER], search: search });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.exporterList = result;
        }
    }

    private async getImporterListByName(search: string): Promise<void> {
        let result = [];
        try {
            result = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.IMPORTER], search: search });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.importerList = result;
        }
    }

    private async getLegalPersonListByName(legalPersonFilter: ILegalPersonListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        try {
            this.formService.block();
            if (legalPersonFilter.search && legalPersonFilter.search.length >= 3) {
                const legalPersons = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/legalPerson/list/custom`, legalPersonFilter, 30000, false);
                if (legalPersons) result = legalPersons.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME } });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getPhysicalPersonListByName(physicalPersonFilter?: IPhysicalPersonListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const physicalPersons = await this.RestService.newObjectPromise(`${this.$baseUrl}/physicalPerson/list/custom`, physicalPersonFilter, 30000, false);
            if (physicalPersons) result = physicalPersons.map(physicalPerson => { return { ID: physicalPerson.ID, NAME: physicalPerson.NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async updateParam(selected: IChargeNameList): Promise<void> {
        let param: IParams = null;
        try {
            if (!selected) throw Error('selected is null');

            const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
            if (!modalScope.charge) throw Error('charge is null');

            const isPaymentNature = this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.PAYMENT;
            if (selected.PARAMS && selected.PARAMS.length > 0) param = selected.PARAMS.find(param => param.TYPE_CARGO.find(typeCargo => typeCargo.ID === this.$scope.model.TYPE_CARGO.ID));
            if (param && this.$scope.model.PAYMENT_NATURE) {
                modalScope.charge.APPLICATION = isPaymentNature ? param.PAYMENT : param.RECEIVING;
                this.$timeout(() => { modalScope.selectorValidity('param'); }, 100);
            }

            // SCOB Application behavior
            const isScobApplication = modalScope.charge.APPLICATION && modalScope.charge.APPLICATION.INTERNAL_SEQUENCE == "001";
            if (isScobApplication) {
                modalScope.charge.TARIFF_LOCAL_PAYMENT.FREE_OF_CHARGE = true;
                modalScope.charge.TARIFF_LOCAL_RECEIVING.FREE_OF_CHARGE = true;
                this.freeOfChargePaymentChange(modalScope.charge);
                this.freeOfChargeReceivingChange(modalScope.charge);
            } else if (isPaymentNature) modalScope.charge.TARIFF_LOCAL_PAYMENT.FREE_OF_CHARGE = false;
            else modalScope.charge.TARIFF_LOCAL_RECEIVING.FREE_OF_CHARGE = false;

            const applicationComplement = modalScope.charge.APPLICATION && modalScope.charge.APPLICATION.APPLICATION_COMPLEMENT ? modalScope.charge.APPLICATION.APPLICATION_COMPLEMENT.ID : null;
            if (!applicationComplement || applicationComplement == EApplicationComplementId.NOT_APPLICABLE || applicationComplement == EApplicationComplementId.WEIGHT_RANGE) {
                if (applicationComplement == EApplicationComplementId.WEIGHT_RANGE) {
                    if (!modalScope.charge.FLAT) {
                        modalScope.charge.FLAT = true;
                        this.flatChange();
                    }
                } else {
                    modalScope.charge.FLAT = false;
                    modalScope.charge.WEIGHT_RANGE = null;
                    modalScope.charge.TARIFF_LOCAL_PAYMENT.WEIGHT_RANGE_CHARGE = null;
                    modalScope.charge.TARIFF_LOCAL_RECEIVING.WEIGHT_RANGE_CHARGE = null;
                }
                modalScope.charge.EQUIPMENT = null;
                modalScope.charge.VEHICLE_TYPE = null;
                this.$timeout(() => { modalScope.selectorValidity('weightRange'); });
            }
            else if (applicationComplement == EApplicationComplementId.EQUIPMENT) {
                modalScope.charge.FLAT = false;
                modalScope.charge.WEIGHT_RANGE = null;
                modalScope.charge.TARIFF_LOCAL_PAYMENT.WEIGHT_RANGE_CHARGE = null;
                modalScope.charge.TARIFF_LOCAL_RECEIVING.WEIGHT_RANGE_CHARGE = null;
                modalScope.charge.VEHICLE_TYPE = null;
                this.$timeout(() => { modalScope.selectorValidity('equipment'); });
            }
            else if (applicationComplement == EApplicationComplementId.VEHICLE) {
                modalScope.charge.FLAT = false;
                modalScope.charge.WEIGHT_RANGE = null;
                modalScope.charge.TARIFF_LOCAL_PAYMENT.WEIGHT_RANGE_CHARGE = null;
                modalScope.charge.TARIFF_LOCAL_RECEIVING.WEIGHT_RANGE_CHARGE = null;
                modalScope.charge.EQUIPMENT = null;
                this.$timeout(() => { modalScope.selectorValidity('vehicleType'); });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private productChange(selected: SelectorModel): void {
        try {
            if (!selected) throw Error('selected is null');

            this.updateControl();

            if (!this.$scope.model.EVENT) {
                this.$scope.model.EVENT = this.getChargeEventBasedOnProduct();
            }

            if (this.$scope.model.PRODUCT && this.$scope.model.CHARGE && this.$scope.model.CHARGE.length > 0) {
                for (const charge of this.$scope.model.CHARGE) {
                    if (this.$scope.model.PRODUCT.ID == EProductId.AIR_IMPORT || this.$scope.model.PRODUCT.ID == EProductId.AIR_EXPORT) {
                        charge.EQUIPMENT = null;
                    } else {
                        charge.WEIGHT_RANGE = null;
                    }
                }
            }

            if (this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.ID != EProductId.AIR_IMPORT && this.$scope.model.PRODUCT.ID != EProductId.AIR_EXPORT) {
                this.$scope.model.SERVICE_TYPE = null;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async paymentNatureChange(selected: SelectorModel): Promise<void> {
        try {
            if (!selected) throw Error('selected is null');

            if (this.$scope.model.CHARGE && this.$scope.model.CHARGE.length > 0) {
                for (const charge of this.$scope.model.CHARGE) {
                    charge.RATE_TYPE = this.getChargeRateTypeBaseOnPaymentNature();
                }
            }

            if (this.$scope.model.PAYMENT_NATURE) {
                const isPayment = this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.PAYMENT;
                if (this.$scope.model.CHARGE && this.$scope.model.CHARGE.length > 0) {
                    for (let charge of this.$scope.model.CHARGE) {
                        if (isPayment) {
                            // If payment Free of Charge set false default, but the user can change.
                            charge.TARIFF_LOCAL_PAYMENT.FREE_OF_CHARGE = false;
                            charge.TARIFF_LOCAL_RECEIVING.MIN = null;
                            charge.TARIFF_LOCAL_RECEIVING.UNITARY = null;
                        } else {
                            // If receiving Free of Charge must be true.
                            charge.TARIFF_LOCAL_PAYMENT.FREE_OF_CHARGE = true;
                            charge.TARIFF_LOCAL_PAYMENT.MIN = null;
                            charge.TARIFF_LOCAL_PAYMENT.UNITARY = null;
                            const chargeAfterFreeOfChargeChange = await this.freeOfChargePaymentChange(charge);
                            charge.TARIFF_LOCAL_PAYMENT = chargeAfterFreeOfChargeChange.TARIFF_LOCAL_PAYMENT;
                        }
                    }
                    this.$scope.chargeTableControl.data = angular.copy(this.$scope.model.CHARGE);
                    this.$scope.chargesTableOptions.load(null, true);
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getChargeRateTypeBaseOnPaymentNature(): SelectorModel {
        let rateType: SelectorModel = null;
        try {
            if (this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.PAYMENT) {
                rateType = this.$scope.rateTypeList.find(e => { return e.ID == ERateTypeId.TABLE });
            } else if (this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.RECEIVING) {
                rateType = this.$scope.rateTypeList.find(e => { return e.ID == ERateTypeId.TRANSFER });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return rateType;
        }
    }

    private getChargeEventBasedOnProduct(): SelectorModel {
        let event: SelectorModel = null;
        try {
            const isExport = this.$scope.model.PRODUCT && (this.$scope.model.PRODUCT.ID == EProductId.MARITIME_EXPORT || this.$scope.model.PRODUCT.ID == EProductId.AIR_EXPORT || this.$scope.model.PRODUCT.ID == EProductId.ROAD_EXPORT);
            const isImport = this.$scope.model.PRODUCT && (this.$scope.model.PRODUCT.ID == EProductId.MARITIME_IMPORT || this.$scope.model.PRODUCT.ID == EProductId.AIR_IMPORT || this.$scope.model.PRODUCT.ID == EProductId.ROAD_IMPORT);

            if (isExport) {
                const filterEvent = this.$scope.eventList.find(e => { return e.ID == EEventType.LOAD });
                event = filterEvent;
            }
            if (isImport) {
                const filterEvent = this.$scope.eventList.find(e => { return e.ID == EEventType.DISCHARGE });
                event = filterEvent;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return event;
        }
    }

    private initDateFields(): void {
        try {
            this.$scope.model.DISPLAY_VALIDITY_START = (this.$scope.model.DISPLAY_VALIDITY_START != null) ? new Date(this.$scope.model.DISPLAY_VALIDITY_START) : null;
            this.$scope.model.VALIDITY_START = (this.$scope.model.VALIDITY_START != null) ? new Date(this.$scope.model.VALIDITY_START) : null;
            this.$scope.model.DISPLAY_VALIDITY_END = (this.$scope.model.DISPLAY_VALIDITY_END != null) ? new Date(this.$scope.model.DISPLAY_VALIDITY_END) : null;
            this.$scope.model.VALIDITY_END = (this.$scope.model.VALIDITY_END != null) ? new Date(this.$scope.model.VALIDITY_END) : null;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private filterRateType(data: SelectorModel[]): SelectorModel[] {
        let filterList = [];
        try {
            if (data) {
                if (this.$scope.model.PAYMENT_NATURE) {
                    if (this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.PAYMENT) {
                        filterList = data.filter(el => el.ID == ERateTypeId.TABLE || el.ID == ERateTypeId.NEGOTIATED);
                    } else if (this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.RECEIVING) {
                        filterList = data.filter(el => el.ID == ERateTypeId.FIXED || el.ID == ERateTypeId.TRANSFER);
                    }
                } else {
                    filterList = data;
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return filterList;
        }
    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'tariff_files', formName: this.formName, folder: this.formName };
            return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData] });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getChargesTableOptions(): ITableOptions {
        return {
            persistName: "tariffLocalCharges",
            pagination: false,
            search: true,
            advancedSearch: false,
            showSearchClearButton: true,
            clickToSelect: true,
            singleSelect: false,
            showLoading: true,
            showColumns: true,
            showColumnsSearch: true,
            showColumnsToggleAll: true,
            showFullscreen: true,
            showToggle: false,
            showMultiSort: true,
            showMultiSortButton: true,
            height: '100%',
            filterControl: false,
            fixedColumns: true,
            fixedNumber: 3,
            sortable: true,
            serverSort: false,
            crudButtons: {
                validityRoute: { fn: async () => { this.validityRouteCharge(); }, name: "validityRouteCharge", label: "GENERAL.APPLY_TARIFF_VALIDITY" },
                add: { fn: async () => { this.addCharge(); }, name: "addCharge" },
                edit: { fn: async (index: number, displayIndex: number) => { this.editCharge(index, displayIndex); }, name: "editCharge" },
                copy: { fn: async (index: number, displayIndex: number) => { this.copyCharge(index); }, name: "copyCharge" },
                remove: { fn: async (index: number) => { this.removeCharge(index); }, name: "removeCharge" }
            },
            customToolbarButtons: [
                { fn: async () => { this.viewChargeLog() }, label: "", name: "log", class: "btn btn-xs btn-success", icon: "fa fa-history", title: "", disabled: this.$scope.operation == EOperation.VIEW }
            ],
            formatAddLevel: () => this.formService.getTranslate("GENERAL.ADD_LEVEL"),
            formatCancel: () => this.formService.getTranslate("GENERAL.CLOSE"),
            formatColumn: () => this.formService.getTranslate("GENERAL.COLUMNS"),
            formatDeleteLevel: () => this.formService.getTranslate("GENERAL.REMOVE_LEVEL"),
            formatMultipleSort: () => this.formService.getTranslate("GENERAL.MULTIPLE_SORT"),
            formatOrder: () => this.formService.getTranslate("GENERAL.ORDER"),
            formatSort: () => this.formService.getTranslate("GENERAL.SORT"),
            formatSortBy: () => this.formService.getTranslate("GENERAL.SORT_BY"),
            formatSortOrders: () => { return { asc: this.formService.getTranslate("GENERAL.ASCENDING_SORT"), desc: this.formService.getTranslate("GENERAL.DESCENDING_SORT") } },
            formatThenBy: () => this.formService.getTranslate("GENERAL.AND_BY"),
            headerStyle: (column: BootstrapTableColumn) => {
                let headerStyleObj = {};
                if (column.field.search("PAYMENT") >= 0) headerStyleObj = { css: { 'background': "#f2dede" } };
                else if (column.field.search("RECEIVING") >= 0) headerStyleObj = { css: { 'background': "#dff0d8" } };
                return headerStyleObj;
            },
            showColumnsRename: [
                { dataField: "CHARGE_NAME_EXHIBITION", title: this.formService.getTranslate("BASIC_DATA.CHARGE") },
                { dataField: "APPLICATION", title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS") },
                { dataField: "APPLICATION.APPLICATION_COMPLEMENT", title: this.formService.getTranslate("PRODUCT.BASIS_COMPLEMENT") },
                { dataField: "TARIFF_LOCAL_PAYMENT.CURRENCY", title: this.formService.getTranslate("GENERAL.CURRENCY") },
                { dataField: "TARIFF_LOCAL_PAYMENT.UNITARY", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY_C") },
                { dataField: "TARIFF_LOCAL_PAYMENT.MIN", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM_C") },
                { dataField: "TARIFF_LOCAL_RECEIVING.CURRENCY", title: this.formService.getTranslate("GENERAL.CURRENCY") },
                { dataField: "TARIFF_LOCAL_RECEIVING.UNITARY", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY_V") },
                { dataField: "TARIFF_LOCAL_RECEIVING.MIN", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM_V") },
                { dataField: "VALIDITY_START", title: this.formService.getTranslate("PRODUCT.VALIDITY_START") },
                { dataField: "VALIDITY_END", title: this.formService.getTranslate("PRODUCT.VALIDITY_END") },
                { dataField: "EVENT", title: this.formService.getTranslate("GENERAL.VALIDITY_BASIS") },
                { dataField: "RATE_TYPE", title: this.formService.getTranslate("GENERAL.TYPE") }
            ],
            onAll: () => {
                if (!this.$scope.$$phase) {
                    const self: TariffLocalRegisterController = this;
                    this.$scope.$apply(function() {
                        if (self.countCheckSelected() > 1) {
                            self.$scope.chargesTableOptions.crudButtons.edit.disabled = true;
                            self.$scope.chargesTableOptions.crudButtons.copy.disabled = true;
                            self.$scope.chargesTableOptions.crudButtons.remove.disabled = true;
                            self.$scope.chargesTableOptions.crudButtons.validityRoute.disabled = false;
                        } else if (self.countCheckSelected() == 1) {
                            self.$scope.chargesTableOptions.crudButtons.validityRoute.disabled = false;
                        } else {
                            self.$scope.chargesTableOptions.crudButtons.edit.disabled = false;
                            self.$scope.chargesTableOptions.crudButtons.copy.disabled = false;
                            self.$scope.chargesTableOptions.crudButtons.remove.disabled = false;
                            self.$scope.chargesTableOptions.crudButtons.validityRoute.disabled = true;
                        }
                    });
                }

                return true;
            },
            onPostBody: (chargeList: ICharge[]) => {
                for (const index in chargeList) { chargeList[index].DISPLAY_INDEX = parseInt(index); };
                this.$scope.chargesListDisplay = chargeList;
                return true;
            },
            columns: [
                { field: 'selected', title: this.formService.getTranslate("GENERAL.UI_SELECT.SELECT"), checkbox: true, showSelectTitle: true, cellStyle: () => { return { css: { width: '80px' } } } },
                { field: 'CHARGE_NAME_EXHIBITION', title: this.formService.getTranslate("BASIC_DATA.CHARGE"), formatter: (data) => data ? `${data.CODE} - ${data.NAME}` : data, sortable: true, searchable: true },
                { field: 'APPLICATION', title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'APPLICATION.APPLICATION_COMPLEMENT', title: this.formService.getTranslate("PRODUCT.BASIS_COMPLEMENT"), formatter: (application: IApplicationList, charge: ICharge) => this.getChargeApplicationComplementValue(application, charge), sortable: true, searchable: true },
                { field: 'TARIFF_LOCAL_PAYMENT.CURRENCY', title: this.formService.getTranslate("GENERAL.CURRENCY"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'TARIFF_LOCAL_PAYMENT.UNITARY', title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'TARIFF_LOCAL_PAYMENT.MIN', title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'TARIFF_LOCAL_RECEIVING.CURRENCY', title: this.formService.getTranslate("GENERAL.CURRENCY"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'TARIFF_LOCAL_RECEIVING.UNITARY', title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'TARIFF_LOCAL_RECEIVING.MIN', title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'VALIDITY_START', title: this.formService.getTranslate("PRODUCT.VALIDITY_START"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true },
                { field: 'VALIDITY_END', title: this.formService.getTranslate("PRODUCT.VALIDITY_END"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true },
                { field: 'EVENT', title: this.formService.getTranslate("GENERAL.VALIDITY_BASIS"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true },
                { field: 'RATE_TYPE', title: this.formService.getTranslate("GENERAL.TYPE"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true }
            ],
        };
    }

    private validityRouteCharge(): void {
        try {
            const baseDataValidityEnd = this.$scope.model.DISPLAY_VALIDITY_END;
            for (const charge of this.$scope.model.CHARGE) {
                if (charge['selected']) {
                    const index = this.$scope.model.CHARGE.indexOf(charge)
                    this.$scope.model.CHARGE[index].VALIDITY_END = new Date(baseDataValidityEnd)
                }
            }
            this.$scope.chargesTableOptions.load(null, true);

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private countCheckSelected(): number {
        try {
            const charges = this.$scope.model.CHARGE;
            let counter = 0;

            if (charges && charges.length) {
                for (let i = 0; i < charges.length; i++) {
                    if (charges[i]['selected']) counter++;
                }
            }

            return counter;

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async viewChargeLog(): Promise<void> {
        try {
            this.formService.block();

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/tariffLocal/tabs/charge/viewLog/${this.$scope.model.ID.toString()}/CHARGES`, 30000, null, false);
            const log: IViewLog = {
                operation: 'history',
                number: this.$scope.model.ID.toString(),
                list: [],
                show: true,
                showCloseButton: false,
                searchQuery: '',
                originalList: [],
            }
            log.list = request.data;
            log.originalList = angular.copy(log.list);

            const modalId = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    modalID: modalId,
                    template: require('../../common/view/modals/viewLog.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG"
                }
            );
            modalInstance.opened.then(async () => {
                const modalScope = await this.ModalService.getModalScope(modalId);
                modalScope.customLogProperties = this.$scope.customLogProperties;
                modalScope.log = log;
                modalScope.$applyAsync();
            });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private getChargeApplicationComplementValue(applicationComplement: IApplicationList, charge: ICharge): string {
        let complementValue: string = "";
        if (applicationComplement.ID == EApplicationComplementId.EQUIPMENT) complementValue = this.$scope.getCONCAT(charge.EQUIPMENT, null, "CODE");
        else if (applicationComplement.ID == EApplicationComplementId.WEIGHT_RANGE) complementValue = this.$scope.getCONCAT(charge.WEIGHT_RANGE);
        else if (applicationComplement.ID == EApplicationComplementId.VEHICLE) complementValue = this.$scope.getCONCAT(charge.VEHICLE_TYPE);
        return complementValue;
    }

    private removeUpload(model: IUploadItem): boolean {
        let result = false;
        try {
            if (!model) throw Error('item parameter is null');

            const isAdminOrCoordinator = (this.$scope.user.roles && (this.$scope.user.roles.hasOwnProperty('ADMIN') || this.$scope.user.roles.hasOwnProperty('COORDINATOR')));

            if (isAdminOrCoordinator) {
                result = true;
            } else {
                // check if in model.FILES_NEW;
                if (this.$scope.model.FILES_NEW && this.$scope.model.FILES_NEW.length > 0) {
                    const index = this.$scope.model.FILES_NEW.findIndex(file => { return file.FILE_HASH == model.FILE_HASH });
                    if (index > -1) {
                        // Remove from FILES_NEW
                        this.$scope.model.FILES_NEW.splice(index, 1);
                        result = true;
                    }
                }
            }

            if (result) {
                if (!this.$scope.model.FILES_REMOVE) this.$scope.model.FILES_REMOVE = [];
                this.$scope.model.FILES_REMOVE.push(model);
            } else {
                throw Error('You don\'t have permission to remove this file');
            }
        } catch (ex) {
            result = false;
            this.handleError(ex);
        } finally {
            return result;
        }
    }

    private onSuccessItem(item: IFileItem, response: ISuccessItemResponse): void {
        try {
            if (!item) throw Error('item parameter is null');
            if (!response) throw Error('response parameter is null');

            if (response.data && response.data.length > 0) {
                if (!this.$scope.model.FILES_NEW) this.$scope.model.FILES_NEW = [];

                for (let dataItem of response.data) {
                    const file: IUploadItem = {
                        FILE_DISPLAY_NAME: dataItem.displayFileName,
                        FILE_NAME: dataItem.fileName,
                        FILE_TYPE: dataItem.fileType,
                        FILE_PATH: dataItem.filePath,
                        FILE_URL: dataItem.fileUrl,
                        FILE_HASH: dataItem.fileHash,
                        FILE_SIZE: dataItem.fileSize,
                        FORM_NAME: dataItem.formName
                    }
                    this.$scope.model.FILES_NEW.push(file)
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private formatedScoreDate(): string {
        try {
            if (this.$scope.model.SCORE_DATE) {
                return moment(this.$scope.model.SCORE_DATE).format('DD/MM/YYYY HH:mm:ss');
            }
            return null;
        } catch (ex) {
            this.handleError(ex);
            return null;
        }
    }

    private async getChargeNameParamsList(): Promise<SelectorModel[]> {
        let result: IApplicationList[] = [];
        try {
            this.formService.block();
            const products = this.$scope.model.PRODUCT ? [this.$scope.model.PRODUCT.ID] : null;
            const paramsTypeCargo = this.$scope.model.TYPE_CARGO ? [this.$scope.model.TYPE_CARGO.ID] : null;
            const request = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/application/list/custom`, { products: products, typeCargos: paramsTypeCargo }, 30000, false);
            result = request ? request.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PERCENT: x.PERCENT, APPLICATION_COMPLEMENT: x.APPLICATION_COMPLEMENT, INTERNAL_SEQUENCE: x.INTERNAL_SEQUENCE, FREE_TYPING_AMOUNT_CHARGE: x.FREE_TYPING_AMOUNT_CHARGE, CT_WITHOUT_DOC: x.CT_WITHOUT_DOC, NAME_INTL: x.NAME_INTL } }) : [];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private setCopy(value: boolean): void {
        this.isCopy = value;
    }

    private hasChanges(newObj: Object | Object[], oldObj: Object | Object[], propertiesToIgnore?: string[]): boolean {
        if (!propertiesToIgnore) propertiesToIgnore = this.propertiesToIgnore;

        const newAux: Object[] = newObj ? Array.isArray(newObj) ? angular.copy(newObj) : [newObj] : [];
        const oldAux: Object[] = oldObj ? Array.isArray(oldObj) ? angular.copy(oldObj) : [oldObj] : [];

        if (propertiesToIgnore) {
            for (let index = 0; index < newAux.length; index++) {
                for (const property of propertiesToIgnore) {
                    if (newAux && angular.isDefined(newAux[index][property])) delete newAux[index][property];
                }
            }
            for (let index = 0; index < oldAux.length; index++) {
                for (const property of propertiesToIgnore) {
                    if (oldAux && angular.isDefined(oldAux[index][property])) delete oldAux[index][property];
                }
            }

            return !angular.equals(JSON.stringify(newAux), JSON.stringify(oldAux));
        }

        return !angular.equals(JSON.stringify(newObj), JSON.stringify(oldObj));
    }

    private async updatePaymentUnit(): Promise<void> {
        try {
            const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
            if (modalScope.charge.FLAT) {
                const unit = modalScope.charge.TARIFF_LOCAL_PAYMENT.UNITARY;
                if (modalScope.charge.TARIFF_LOCAL_PAYMENT.WEIGHT_RANGE_CHARGE) {
                    for (const weightRangeCharge of modalScope.charge.TARIFF_LOCAL_PAYMENT.WEIGHT_RANGE_CHARGE) {
                        weightRangeCharge.UNITARY = unit;
                    }
                }
                modalScope.$applyAsync();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async updateReceivingUnit(): Promise<void> {
        try {
            const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
            if (modalScope.charge.FLAT) {
                const unit = modalScope.charge.TARIFF_LOCAL_RECEIVING.UNITARY;
                if (modalScope.charge.TARIFF_LOCAL_RECEIVING.WEIGHT_RANGE_CHARGE) {
                    for (const weightRangeCharge of modalScope.charge.TARIFF_LOCAL_RECEIVING.WEIGHT_RANGE_CHARGE) {
                        weightRangeCharge.UNITARY = unit;
                    }
                }
                modalScope.$applyAsync();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async updateHolderTypePayment() {
        try {
            const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
            modalScope.charge.TARIFF_LOCAL_PAYMENT.ID_LEGAL_PERSON_HOLDER = null;
            modalScope.charge.TARIFF_LOCAL_PAYMENT.LEGAL_PERSON_HOLDER = null;
            modalScope.charge.TARIFF_LOCAL_PAYMENT.ID_PHYSICAL_PERSON_HOLDER = null;
            modalScope.charge.TARIFF_LOCAL_PAYMENT.PHYSICAL_PERSON_HOLDER = null;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async updateHolderTypeReceiving() {
        try {
            const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
            modalScope.charge.TARIFF_LOCAL_RECEIVING.ID_LEGAL_PERSON_HOLDER = null;
            modalScope.charge.TARIFF_LOCAL_RECEIVING.LEGAL_PERSON_HOLDER = null;
            modalScope.charge.TARIFF_LOCAL_RECEIVING.ID_PHYSICAL_PERSON_HOLDER = null;
            modalScope.charge.TARIFF_LOCAL_RECEIVING.PHYSICAL_PERSON_HOLDER = null;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async validityStartEndKick() {
        try {
            const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
            if (!modalScope.charge) throw Error('charge is null');

            if (modalScope.charge.CHARGE_NAME_EXHIBITION && modalScope.operation == EOperation.NEW) {

                let charges = this.$scope.model.CHARGE.filter(modelCharge => modelCharge.CHARGE_NAME_EXHIBITION.ID == modalScope.charge.CHARGE_NAME_EXHIBITION.ID && JSON.stringify(modalScope.charge.APPLICATION) === JSON.stringify(modelCharge.APPLICATION));
                const { WEIGHT_RANGE, EQUIPMENT, VEHICLE_TYPE } = modalScope.charge;

                if (WEIGHT_RANGE) {
                    charges = charges.filter(modelCharge => JSON.stringify(modelCharge.WEIGHT_RANGE) === JSON.stringify(WEIGHT_RANGE));
                } else if (EQUIPMENT) {
                    charges = charges.filter(modelCharge => JSON.stringify(modelCharge.EQUIPMENT) === JSON.stringify(EQUIPMENT));
                } else if (VEHICLE_TYPE) {
                    charges = charges.filter(modelCharge => JSON.stringify(modelCharge.VEHICLE_TYPE) === JSON.stringify(VEHICLE_TYPE));
                }

                if (charges) {
                    const baseDataValidatyEnd = new Date(this.$scope.model.DISPLAY_VALIDITY_END)
                    if (charges.length === 1) {
                        for (const charge of charges) {

                            const validityEnd = new Date(charge.VALIDITY_END);
                            modalScope.charge.VALIDITY_START = new Date(validityEnd.setDate(validityEnd.getDate() + 1));
                            if (validityEnd.getTime() < baseDataValidatyEnd.getTime()) {
                                modalScope.charge.VALIDITY_END = baseDataValidatyEnd;
                            } else {
                                modalScope.charge.VALIDITY_END = null;
                            }
                        }
                    } else if (charges.length > 1) {
                        charges = charges.sort((a, b) => {
                            return new Date(a.VALIDITY_START).getTime() - new Date(b.VALIDITY_START).getTime();
                        });

                        const lastCharge = charges[charges.length - 1];
                        const lastChargeValidityEnd = new Date(lastCharge.VALIDITY_END);
                        modalScope.charge.VALIDITY_START = new Date(lastChargeValidityEnd.setDate(lastChargeValidityEnd.getDate() + 1));
                        if (lastChargeValidityEnd.getTime() < baseDataValidatyEnd.getTime()) {
                            modalScope.charge.VALIDITY_END = baseDataValidatyEnd;
                        } else {
                            modalScope.charge.VALIDITY_END = null;
                        }
                    } else {
                        modalScope.charge.VALIDITY_START = new Date();
                        modalScope.charge.VALIDITY_END = baseDataValidatyEnd;
                    }
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }



    private filterProducts(data: SelectorModel[]): SelectorModel[] {
        try {
            if (!data) throw Error('data is null');
            return data.filter(item => item.ID != EProductId.ROAD_EXPORT && item.ID != EProductId.ROAD_IMPORT);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private filterTypeLocalEventList(): SelectorModel[] {
        const result: SelectorModel[] = [];
        try {
            if (this.$scope.typeLocalEventList && this.$scope.typeLocalEventList.length > 0 && this.$scope.model && this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.ID) {
                result.push(this.$scope.typeLocalEventList.find(item => item.ID == ETypeLocalId.TERMINAL))
                if (this.$scope.model.PRODUCT.ID == EProductId.AIR_IMPORT || this.$scope.model.PRODUCT.ID == EProductId.AIR_EXPORT) {
                    result.push(this.$scope.typeLocalEventList.find(item => item.ID == ETypeLocalId.AIRPORT))
                } else if (this.$scope.model.PRODUCT.ID == EProductId.MARITIME_EXPORT || this.$scope.model.PRODUCT.ID == EProductId.MARITIME_IMPORT) {
                    result.push(this.$scope.typeLocalEventList.find(item => item.ID == ETypeLocalId.PORT))
                }
            }

        } catch (ex) {
            this.handleError(ex);
        } finally {
            return result;
        }
    }

    private checkDateValidity(initialDate: Date, finalDate: Date): void {
        let isValid = false;
        if (!initialDate || typeof initialDate == "string") return;
        if (!finalDate || typeof finalDate == "string") return;
        isValid = ValidateUtil.isValidDateRange(initialDate, finalDate);
        if (!isValid) {
            this.$scope.model.DISPLAY_VALIDITY_END = null;
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: "CONCATENATED",
                LABEL: "GENERAL.CONCATENATED"
            },
            {
                PROPERTY: "PAYMENT_NATURE",
                LABEL: "BASIC_DATA.TRANSACTION"
            },
            {
                PROPERTY: "PRODUCT",
                LABEL: "BASIC_DATA.PRODUCT"
            },
            {
                PROPERTY: "DIRECTION",
                LABEL: "GENERAL.TYPE"
            },
            {
                PROPERTY: "TYPE_CARGO",
                LABEL: "BASIC_DATA.CARGO_TYPE"
            },
            {
                PROPERTY: "LOCAL",
                LABEL: "GENERAL.LOCAL"
            },
            {
                PROPERTY: "PROVIDERS",
                LABEL: "BASIC_DATA.PROVIDER"
            },
            {
                PROPERTY: "NETWORKS",
                LABEL: "BASIC_DATA.NETWORK"
            },
            {
                PROPERTY: "LEGAL_PERSON_AGENTS",
                LABEL: "BASIC_DATA.AGENT"
            },
            {
                PROPERTY: "ACCOUNTS",
                LABEL: "BASIC_DATA.CLIENT"
            },
            {
                PROPERTY: "EXPORTERS",
                LABEL: "BASIC_DATA.SHIPPER"
            },
            {
                PROPERTY: "IMPORTERS",
                LABEL: "BASIC_DATA.CONSIGNEE"
            },
            {
                PROPERTY: "CHARGE",
                LABEL: "GENERAL.CHARGES"
            },
            {
                PROPERTY: "OBSERVATION",
                LABEL: "GENERAL.REMARKS"
            },
            {
                PROPERTY: "CHARGE_NAME",
                LABEL: "BASIC_DATA.CHARGE"
            },
            {
                PROPERTY: "APPLICATION",
                LABEL: "FINANCIAL.CHARGE_BASIS"
            },
            {
                PROPERTY: "EQUIPMENT",
                LABEL: "BASIC_DATA.EQUIPMENT"
            },
            {
                PROPERTY: "WEIGHT_RANGE",
                LABEL: "REGISTRATION.WEIGHT_BREAK"
            },
            {
                PROPERTY: "CURRENCY",
                LABEL: "GENERAL.CURRENCY"
            },
            {
                PROPERTY: "MIN",
                LABEL: "BASIC_DATA.MINIMUM"
            },
            {
                PROPERTY: "UNITARY",
                LABEL: "BASIC_DATA.UNIT"
            },
            {
                PROPERTY: "EVENT",
                LABEL: "GENERAL.VALIDITY_BASIS"
            },
            {
                PROPERTY: "VALIDITY_START",
                LABEL: "PRODUCT.VALIDITY_START"
            },
            {
                PROPERTY: "DISPLAY_VALIDITY_START",
                LABEL: "PRODUCT.VALIDITY_START"
            },
            {
                PROPERTY: "VALIDITY_END",
                LABEL: "PRODUCT.VALIDITY_END"
            },
            {
                PROPERTY: "DISPLAY_VALIDITY_END",
                LABEL: "PRODUCT.VALIDITY_END"
            },
            {
                PROPERTY: "GROUP",
                LABEL: "REGISTRATION.AGROUP"
            },
            {
                PROPERTY: "RATE_TYPE",
                LABEL: "GENERAL.TYPE"
            },
            {
                PROPERTY: "HOLDER_TYPE",
                LABEL: "GENERAL.PERSON_TYPE"
            },
            {
                PROPERTY: "LEGAL_PERSON_HOLDER",
                LABEL: "GENERAL.HOLDER"
            },
            {
                PROPERTY: "PHYSICAL_PERSON_HOLDER",
                LABEL: "GENERAL.HOLDER"
            },
            {
                PROPERTY: "SITUATION",
                LABEL: "GENERAL.SITUATION"
            },
            {
                PROPERTY: "NAME",
                LABEL: "GENERAL.NAME"
            },
            {
                PROPERTY: "CODE",
                LABEL: "GENERAL.CODE"
            },
            {
                PROPERTY: "CODE",
                LABEL: "GENERAL.CODE"
            },
            {
                PROPERTY: "FILES",
                LABEL: "REGISTRATION.EMAIL_ATTACHMENT"
            },
            {
                PROPERTY: "FILE_DISPLAY_NAME",
                LABEL: "REGISTRATION.FILE_ATTACHMENT_NAME"
            },
            {
                PROPERTY: "FILE_URL",
                LABEL: "REGISTRATION.FILE_URL"
            },
            {
                PROPERTY: "FILE_TYPE",
                LABEL: "REGISTRATION.FILE_TYPE"
            },
            {
                PROPERTY: "SCORE",
                LABEL: "BASIC_DATA.SCORE"
            },
            {
                PROPERTY: "SCORE_DATE",
                LABEL: "GENERAL.SCORE_CALCULATION_DATA"
            },
            {
                PROPERTY: "SCORE_ERROR",
                LABEL: "GENERAL.SCORE_CALCULATION_ERROR"
            },
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE"
            },
            {
                PROPERTY: "VALIDITY_EXPIRED",
                LABEL: "GENERAL.VALIDITY_EXPIRED"
            },
            {
                PROPERTY: "CHARGE_NAME_EXHIBITION",
                LABEL: "BASIC_DATA.CHARGE"
            },
            {
                PROPERTY: "VEHICLE_TYPE",
                LABEL: "GENERAL.VEHICLE_TYPE"
            },
            {
                PROPERTY: "VERSION",
                LABEL: "GENERAL.VERSION"
            },
            {
                PROPERTY: "TYPE_LOCAL",
                LABEL: "ROUTE.LOCAL_TYPE"
            },
            {
                PROPERTY: "SERVICE_TYPE",
                LABEL: "BASIC_DATA.SERVICE_TYPE"
            },
            {
                PROPERTY: "CONCATENATED_COMPLEMENT",
                LABEL: "GENERAL.CONCATENATED_COMPLEMENT"
            },
            {
                PROPERTY: "PAYMENT_MIN",
                LABEL: "PRODUCT.MIN_PAYMENT"
            },
            {
                PROPERTY: "PAYMENT_UNITARY",
                LABEL: "PRODUCT.UNIT_PAYMENT."
            },
            {
                PROPERTY: "RECEIVING_MIN",
                LABEL: "PRODUCT.MIN_RECEIPT."
            },
            {
                PROPERTY: "RECEIVING_UNITARY",
                LABEL: "PRODUCT.UNIT_RECEIPT"
            },
            {
                PROPERTY: "CREATED_AT",
                LABEL: "GENERAL.CREATED_AT"
            },
            {
                PROPERTY: "CREATED_BY",
                LABEL: "GENERAL.CREATED_BY"
            },
            {
                PROPERTY: "UPDATED_AT",
                LABEL: "GENERAL.UPDATED_AT"
            },
            {
                PROPERTY: "UPDATED_BY",
                LABEL: "GENERAL.UPDATED_BY"
            },
            {
                PROPERTY: "DISPLAY_NAME",
                LABEL: "GENERAL.DISPLAY_NAME"
            },
            {
                PROPERTY: "APPLICATION",
                LABEL: "FINANCIAL.CHARGE_BASIS"
            },
            {
                PROPERTY: "FLAT",
                LABEL: "REGISTRATION.FLAT"
            },
            {
                PROPERTY: "EQUIPMENT",
                LABEL: "BASIC_DATA.EQUIPMENT"
            },
            {
                PROPERTY: "VEHICLE_TYPE",
                LABEL: "GENERAL.VEHICLE_TYPE"
            },
            {
                PROPERTY: "WEIGHT_RANGE",
                LABEL: "GENERAL.WEIGHT_RANGE"
            },
            {
                PROPERTY: "GROUP",
                LABEL: "FINANCIAL.GROUP"
            },
            {
                PROPERTY: "RATE_TYPE",
                LABEL: "GENERAL.TYPE"
            },
            {
                PROPERTY: "EVENT",
                LABEL: "GENERAL.VALIDITY_BASIS"
            },
            {
                PROPERTY: "VALIDITY_START",
                LABEL: "PRODUCT.VALIDITY_START"
            },
            {
                PROPERTY: "VALIDITY_END",
                LABEL: "PRODUCT.VALIDITY_END"
            },
            {
                PROPERTY: "TARIFF_LOCAL_PAYMENT",
                LABEL: "PRODUCT.BUYING"
            },
            {
                PROPERTY: "TARIFF_LOCAL_RECEIVING",
                LABEL: "PRODUCT.SELLING"
            },
            {
                PROPERTY: "WEIGHT_RANGE_CHARGE",
                LABEL: "GENERAL.MENU.WEIGHT_RANGE"
            },
            {
                PROPERTY: "PERCENT",
                LABEL: "REGISTRATION.PERCENTAGE"
            },
            {
                PROPERTY: "APPLICATION_COMPLEMENT",
                LABEL: "PRODUCT.BASIS_COMPLEMENT"
            },
            {
                PROPERTY: "INTERNAL_SEQUENCE",
                LABEL: "REGISTRATION.INTERNAL_SEQUENCE"
            },
            {
                PROPERTY: "FREE_TYPING_AMOUNT_CHARGE",
                LABEL: "REGISTRATION.FREE_FIELD_CHANGE_QUANTITY"
            },
            {
                PROPERTY: "CT_WITHOUT_DOC",
                LABEL: "REGISTRATION.CONTRIBUTION_WITHOUT_DOCS"
            },
            {
                PROPERTY: "NAME_INTL",
                LABEL: "GENERAL.NAME_INTL"
            },
            {
                PROPERTY: "ORDER",
                LABEL: "FINANCIAL.ORDER"
            },
            {
                PROPERTY: "FREE_OF_CHARGE",
                LABEL: "PRODUCT.FREE_OF_CHARGE"
            },
            {
                PROPERTY: "FREIGHT_BASIS",
                LABEL: "REGISTRATION.FREIGHT_BASIS"
            }
        ];
        return props;
    }

    private getMenuFloatingDefault(): IFloatingMenu {
        return {
            btnActiveDisabled: false,
            options: [
                {
                    click: "collapseHeader",
                    args: ['collapseBasicData'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.BASIC_DATA",
                    iconClass: "fa fa-address-card",
                    iconBodyClass: "text-brown"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseCharges'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.CHARGES",
                    iconClass: "fa fa-usd",
                    iconBodyClass: "text-green"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseUploads'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "REGISTRATION.EMAIL_ATTACHMENT",
                    iconClass: "fa fa-files-o",
                    iconBodyClass: "text-gray"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseObservation'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.REMARKS",
                    iconClass: "fa fa-arrows-alt",
                    iconBodyClass: "text-blue"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseAll'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.COLLAPSE_EXPAND_ALL",
                    iconClass: "fa fa-expand",
                    iconBodyClass: "text-danger"
                }
            ]
        };
    }

    private collapseHeader(elementId: string, state?: string, navigate?: boolean): void {
        if (elementId === "collapseAll") {
            this.collapseState = this.collapseState == "hide" ? "show" : "hide";
            $('.toggle-me')["collapse"](state ? state : this.collapseState);
        } else if (elementId != "registerBody") {
            $("#" + elementId)["collapse"](state ? state : 'toggle');
        }
        if (navigate && state != "hide") this.$scope.navigateBetweenIds(elementId);
    }

    private initPanels = (panelsToShow?: string[], panelToNavigate?: string) => {
        try {
            const panels = document.getElementsByClassName("toggle-me");
            if (panels) {
                for (let i = 0; i < panels.length; i++) {
                    const panel = panels[i];
                    if (panelsToShow && panelsToShow.findIndex(obj => obj === panel.id) >= 0) {
                        if (!panel.classList.contains('in')) {
                            $("#" + panel.id)["collapse"]("show");
                        }
                    } else if (panel.classList.contains('in')) {
                        $("#" + panel.id)["collapse"]("hide");
                    }
                }
            }
            if (panelToNavigate) this.$scope.navigateBetweenIds(panelToNavigate);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private generateConcatenated(): void {
        try {
            const separator = " | ";
            let concatenated = "";

            if (this.$scope.model.ACCOUNTS && this.$scope.model.ACCOUNTS.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.ACCOUNTS.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(this.$scope.model.ACCOUNTS.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.PROVIDERS && this.$scope.model.PROVIDERS.length) {
                concatenated = concatenated.length > 0 ?
                    concatenated.concat(separator, this.$scope.model.PROVIDERS.map(obj => { if (obj.TYPE && (obj.TYPE.ID == EProviderTypeId.SHIPOWNER || obj.TYPE.ID == EProviderTypeId.AIRLINE)) return obj.CODE; else return obj.NAME; }).join(',')) :
                    concatenated.concat(this.$scope.model.PROVIDERS.map(obj => { if (obj.TYPE && (obj.TYPE.ID == EProviderTypeId.SHIPOWNER || obj.TYPE.ID == EProviderTypeId.AIRLINE)) return obj.CODE; else return obj.NAME; }).join(','));
            }

            if (this.$scope.model.NETWORKS && this.$scope.model.NETWORKS.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.NETWORKS.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(this.$scope.model.NETWORKS.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.LEGAL_PERSON_AGENTS && this.$scope.model.LEGAL_PERSON_AGENTS.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.LEGAL_PERSON_AGENTS.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(this.$scope.model.LEGAL_PERSON_AGENTS.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.IMPORTERS && this.$scope.model.IMPORTERS.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.IMPORTERS.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(this.$scope.model.IMPORTERS.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.EXPORTERS && this.$scope.model.EXPORTERS.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.EXPORTERS.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(this.$scope.model.EXPORTERS.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID && this.$scope.model.PAYMENT_NATURE.ID.length > 0) {
                let paymentNature = null;
                if (this.$scope.model.PAYMENT_NATURE.ID === EPaymentNatureId.PAYMENT) {
                    paymentNature = 'PGTO';
                } else if (this.$scope.model.PAYMENT_NATURE.ID === EPaymentNatureId.RECEIVING) {
                    paymentNature = 'RCTO';
                }
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, paymentNature) : concatenated.concat(paymentNature);
            }

            if (this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.ID && this.$scope.model.PRODUCT.ID.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PRODUCT.ID) : concatenated.concat(this.$scope.model.PRODUCT.ID);
            }

            if (this.$scope.model.DIRECTION && this.$scope.model.DIRECTION.NAME && this.$scope.model.DIRECTION.NAME.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.DIRECTION.NAME) : concatenated.concat(this.$scope.model.DIRECTION.NAME);
            }

            if (this.$scope.model.TYPE_CARGO && this.$scope.model.TYPE_CARGO.NAME && this.$scope.model.TYPE_CARGO.NAME.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.TYPE_CARGO.NAME) : concatenated.concat(this.$scope.model.TYPE_CARGO.NAME);
            }

            if (this.$scope.model.LOCAL && this.$scope.model.LOCAL.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.LOCAL.map(obj => { return obj.CODE }).join(',')) : concatenated.concat(this.$scope.model.LOCAL.map(obj => { return obj.CODE }).join(','));
            }

            if (this.$scope.model.CONCATENATED_COMPLEMENT && this.$scope.model.CONCATENATED_COMPLEMENT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CONCATENATED_COMPLEMENT) : concatenated.concat(this.$scope.model.CONCATENATED_COMPLEMENT);
            }

            if (this.$scope.model.DETAILS && this.$scope.model.DETAILS.length) {
                const detail = this.$scope.getCONCAT(this.$scope.model.DETAILS, null, "NAME", null, null, ',')
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, detail) : concatenated.concat(detail);
            }

            if (this.$scope.model.VERSION) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, 'V' + this.$scope.model.VERSION) : concatenated.concat('V' + this.$scope.model.VERSION);
            }

            if (this.$scope.model.DISPLAY_VALIDITY_START) {
                const validity = this.$scope.model.DISPLAY_VALIDITY_END ? moment(this.$scope.model.DISPLAY_VALIDITY_START).format('DD/MM/YY') + '-' + moment(this.$scope.model.DISPLAY_VALIDITY_END).format('DD/MM/YY') : moment(this.$scope.model.DISPLAY_VALIDITY_START).format('DD/MM/YY');
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, validity) : concatenated.concat(validity);
            }

            this.$scope.model.CONCATENATED = StringUtil.formatConcatenatedChars(concatenated);

            if (this.$scope.menuFloating) this.$scope.menuFloating.infos = [{ text: `${this.$scope.model.CONCATENATED}`, class: "text-rouge font-bold" }];
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async openTariffUsedOffer(tariffLocal: ITariffLocalModel): Promise<void> {
        this.modalFormatedDataId = this.ModalService.newModal();
        this.ModalService.showModalInfo(
            {
                modalID: this.modalFormatedDataId,
                scope: this.$scope,
                formService: this.$scope.operation,
                size: 'lg modal-overflow',
                template: require("../view/modals/tariffLocalUsedOfferModal.html"),
                keyboard: false
            },
            null
        );

        this.buildOfferProcessModalScope(tariffLocal);
    }

    private async viewLogConfirmation(): Promise<void> {
        try {
            this.formService.block();
            const retrieveLog = await this.ProductService.get({ route: `/tariffChangeConfirmation/tabs/charge/getTariffChangeConfirmation/tariffLocal/${this.$scope.model.ID}` });
            this.formService.unblock();
            if (retrieveLog && retrieveLog.data && retrieveLog.data.data && retrieveLog.data.data.length) {
                this.$scope.tariffChangeConfirmation = retrieveLog.data.data;
                const modalId = this.ModalService.newModal();
                await this.ModalService.showModalConfirmation(
                    {
                        modalID: modalId,
                        scope: this.$scope,
                        template: require('../view/modals/tariffLocalChangeConfirmationLogModal.html'),
                        size: 'vlg modal-overflow'
                    },
                    {
                        closeButtonText: 'GENERAL.CLOSE'
                    }
                );
            } else this.formService.notifyInfo(this.formService.getTranslate("REGISTRATION.NO_RECORD_FOUND"));
        } catch (ex) {
            this.formService.handleError(ex);
            this.formService.unblock();
        }
    }

    private async buildOfferProcessModalScope(tariffLocal: ITariffLocalModel): Promise<IUsedOfferModal> {
        try {
            this.formService.block();
            if (!tariffLocal) throw Error('Missing tariffLocal parameter in buildOfferProcessModalScope');

            const modalScope: IUsedOfferModal = await this.ModalService.getModalScope(this.modalFormatedDataId);
            modalScope.tariffLocal = angular.copy(tariffLocal);

            modalScope.usedOfferList = [];
            const requestList = await this.getUsedOfferList(tariffLocal.ID);
            if (requestList) modalScope.usedOfferList = angular.copy(requestList);
            modalScope.goToOffer = (offerNumber: string): void => {
                if (!offerNumber || (offerNumber && offerNumber == '')) throw Error('offerNumber is null');
                this.$scope.sessionService.openTab("app.commercial.offer", <IOfferParameter>{ OFFER_CODE_EXHIBITION: offerNumber });
            }

            return modalScope;
        } catch (ex) {
            this.ModalService.closeModal(this.modalFormatedDataId);
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getUsedOfferList(tariffID: number): Promise<IUsedOffer[]> {
        try {
            let result = [];

            const requestList = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/tariffLocal/usedOffer/${tariffID}`, 30000, null, false)
            if (requestList && requestList.data) result = requestList.data;

            return result
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async modalSaveConfirmation(headerText: string, closeButtonText: string, bodyText?: string): Promise<boolean> {
        return await this.ModalService.showModalConfirmation({}, {
            headerText: headerText,
            bodyText: this.formService.getTranslate(bodyText ? bodyText : 'REGISTRATION.MESSAGES.ERROR.UPDATE_NOT_SAVED'),
            actionButtonText: "GENERAL.SAVE",
            closeButtonText: closeButtonText
        });
    }

    private isEmptyArray(arrayObj: any[]): boolean {
        return (arrayObj && arrayObj.length == 0 || !arrayObj);
    }

    private hasInvalidRequiredElements(elementId: string): boolean {
        if (!elementId) return false;
        const isInvalid = FormService2.hasRequiredElements('#' + elementId);
        if (isInvalid) this.formService.notifyError(this.formService.getTranslate("GENERAL.ALL_FIELDS_MANDATORY"));
        return isInvalid;
    }

    private getRatesByTheCorrectIndex(index: number): ICharge {

        let chargeResult: ICharge = null;
        if (Object.is(this.$scope.model.CHARGE[index], this.$scope.chargeTableControl.data[index])) {
            chargeResult = this.$scope.model.CHARGE[index];
        } else {

            for (const fee of this.$scope.model.CHARGE) {
                if (Object.is(fee, this.$scope.chargeTableControl.data[index])) {
                    chargeResult = fee;
                }
            }
        }
        return chargeResult;
    }

    private async setChargeNameId(): Promise<void> {
        const modalScope: IChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
        if (!modalScope.charge) throw Error('charge is null');
        if (modalScope.charge.CHARGE_NAME_EXHIBITION) {
            modalScope.charge.ID_CHARGE_NAME_EXHIBITION = parseInt(modalScope.charge.CHARGE_NAME_EXHIBITION.ID)
        }
    }
}

import { IConsolidatedInstruction } from "WBA-Model/dist/interface/operational/consolidated/ConsolidatedInstruction";
import { IGridFormServiceScope, GridFormService, IMonacoRequest } from '@services/GridFormService';
import { DataProcessService } from "@services/DataProcessService";
import { IConsolidatedWizardModalScope } from "./ConsolidatedWizardModalController";
import { IConsolidatedModel } from "WBA-Model/dist/interface/operational/consolidated/Consolidated";
import { IUser } from "WBA-Model/dist/interface/common/UserModel";

interface IConsolidatedInstructionWizardPanelScope extends IGridFormServiceScope {
    model: IConsolidatedInstruction;
    consolidatedScopeModel: IConsolidatedModel;
    currentUser: IUser;

    saveInstruction: () => Promise<void>;
}

export class ConsolidatedInstructionWizardPanelController extends GridFormService {
    static $inject: string[] = ['$injector', '$scope'];
    private scope: IConsolidatedInstructionWizardPanelScope;
    private $consolidatedScope: IConsolidatedWizardModalScope;

    private $timeout: ng.ITimeoutService;
    private DataProcessService: DataProcessService;

    constructor($injector: ng.Injectable<any>, $scope: IConsolidatedInstructionWizardPanelScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.$consolidatedScope = <IConsolidatedWizardModalScope>$scope.$parent.$parent;
        this.$timeout = $injector.get('$timeout');
        this.DataProcessService = $injector.get('DataProcessService');
        this.$baseUrl = this.DataProcessService.$route;
    }

    public async $onInit(): Promise<void> {
        try {
            this.block();
            this.initialize();
            this.unblock();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private initialize(): void {
        try {
            this.initScopeFunctions();
            this.initModel();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private initModel(): void {
        try {
            this.scope.model = {
                _id: null,
                ID_CONSOLIDATED: this.$consolidatedScope.model.ID,
                MESSAGE: null,
                CREATED_AT: null,
                CREATED_BY: null
            };

            this.scope.consolidatedScopeModel = this.$consolidatedScope.model;
            this.scope.currentUser = this.$rootScope.user;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    public $onDestroy(): void {
        super.$onDestroy();
    }

    public initScopeFunctions(): void {
        this.scope.saveInstruction = () => this.saveInstruction();
    }

    private async saveInstruction(): Promise<void> {
        try {
            await this.insertInstruction();
            await this.getConsolidatedInstructionList();
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async insertInstruction(): Promise<void> {
        try {
            this.block();

            const request: IMonacoRequest = {
                data: {
                    consolidatedId: this.$consolidatedScope.model.ID,
                    data: this.scope.model,
                },
                route: `/consolidatedInstruction/insert`,
                timeout: 12000
            }
            const rc = await this.DataProcessService.post(request.route, request.data, request.timeout);
            const resultOperation = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            if (resultOperation) this.scope.model.MESSAGE = null;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getConsolidatedInstructionList(): Promise<void> {
        try {
            this.block();

            const request: IMonacoRequest = {
                route: `/consolidatedInstruction/list/${this.$consolidatedScope.model.ID}`,
                timeout: 12000
            }
            const rc = await this.DataProcessService.get(request.route, request.data, request.timeout);
            const resultOperation = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            if (resultOperation) {
                this.$consolidatedScope.model.CONSOLIDATED_INSTRUCTION = resultOperation;
                this.scrollToLastMessage();
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private scrollToLastMessage(): void {
        try {
            this.$timeout(() => {
                const chatContainer = document.querySelector('.chat');
                chatContainer.lastElementChild.scrollIntoView({ behavior: 'smooth' });
            }, 600);
        } catch (ex) {
            this.handleError(ex);
        }
    }
} 

export const enum EOfferLogsName {
    // Main
    MAIN_BASIC = 'MAIN-BASIC-',
    MAIN_INVOLVED = 'MAIN-INVOLVED-',
    MAIN_ACCOUNT = 'MAIN-ACCOUNT-',

    // Active
    ACTIVE = 'OFFER-ACTIVE-',

    // Cargo
    CARGO = 'CARGO-',
    CARGO_SPECIFICATIONS = 'CARGO-SPECS-',
    CARGO_EQUIPMENT = 'CARGO-EQUIPMENT-',
    CARGO_CARGO_LIST = 'CARGO-LIST-',
    DET_DEM_FREE_TIME = "DET-DEM-FREE-TIME-",

    // Events
    OFFER_VIGENCE = 'OFFER-VIGENCE-',
    EVENTS = 'EVENTS-',
    EVENTS_SERVICE = 'EVENTS-SERVICE-',
    EVENTS_SERVICE_TT = 'EVENTS-SERVICE-TT-',

    // Charges
    CHARGES = 'CHARGES-',
    CHARGES_WEIGHT_RANGE = 'CHARGES-WEIGHT-RANGE-',
    CHARGES_OUT_DATE = 'CHARGES-OUT-DATE-',

    // Management
    MANAGEMENT_BASIC = "MANAGEMENT-BASIC-",
    MANAGEMENT_INDICATORS = "MANAGEMENT-INDICATORS-",

    // Communication
    COMMUNICATION = 'COMMUNICATION-',
    COMMUNICATION_INSTRUCTIONS = 'COMMUNICATION-INSTRUCTIONS-',

    // ProfitShare
    PROFIT_SHARE = 'PROFIT_SHARE-'
}

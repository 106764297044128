import { MonitorStatusService } from "../../app/services/MonitorStatusService";
import config from "../../bootstrap/Config";
import { HandleError } from "../../common/util/HandleError";

interface IFinopMenuScope extends ng.IScope {
    serviceMaintenance: boolean;
    goTo: (route: string) => void;
}

export class FinopMenuController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IFinopMenuScope;
    private $state: ng.ui.IStateService;

    constructor($injector: ng.Injectable<any>, $scope: IFinopMenuScope) {
        this.$scope = $scope;
        this.$state = $injector.get('$state');
        this.$scope.serviceMaintenance = false;

        this.initScopeFunctions();
    }

    async $onInit(): Promise<void> {
        try {

            if (config.environment === 'dev') return;

            const service = await MonitorStatusService.Instance.getServiceInfo('WBA-FINOP');
            if (!service && config.environment != 'dev') {
                // not ready yet this.$scope.serviceMaintenance = true;
                this.$scope.$applyAsync();
            }
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    $onDestroy(): void {
    }

    initScopeFunctions(): void {
        this.$scope.goTo = (route: string) => {
            this.goTo(route);
        }
    }

    private goTo(route: string): void {
        this.$state.go(route, { reload: true });
    }
}

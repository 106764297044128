import * as angular from 'angular';
import * as moment from 'moment';
import { IStateService } from 'angular-ui-router';
import { PermissionService } from '@appServices/PermissionService';
import { MonitorStatusService } from '@appServices/MonitorStatusService';
import { IWebsocketService } from '@services/WebSocketService';
import { IRestService } from '@services/RestService';
import { NotificationService } from '@services/NotificationService';
import { ISessionService } from '@services/SessionService';
import { IMonacoSetting, IMonacoScope, IMonacoConfig, IMonacoSettings, IMonacoMenu } from '../../common/MonacoInterface';
import { HandleError } from '../../common/util/HandleError';
import { NotificationConstants } from '../../common/util/NotificationConstants';
import { HelperService } from '@services/HelperService';

export const ELanguage = {
    enUS: { ID: "1", NAME: "English US", CODE: "en-US", MOMENT_CODE: "en-ie", IMG: "us" },
    ptBR: { ID: "2", NAME: "Português Brasileiro", CODE: "pt-BR", MOMENT_CODE: "pt-br", IMG: "br" },
}

export class AppController implements ng.IController {
    public static $inject: string[] = ['$injector'];
    private injector: ng.Injectable<any>;
    private $window: ng.IWindowService;
    private $rootScope: IMonacoScope;
    private $templateCache: ng.ITemplateCacheService;
    private $timeout: ng.ITimeoutService;
    private tmhDynamicLocale;
    private $translate;
    private $filter: ng.IFilterService;
    private $state: IStateService;
    private $location: ng.ILocationService;
    private restService: IRestService;
    private sessionService: ISessionService;
    private blockUI: ng.blockUI.BlockUIService;
    private config: IMonacoConfig;
    private defaultSettings: IMonacoSetting;
    private permissionService: PermissionService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>) {
        this.injector = $injector;
        this.$window = $injector.get('$window');
        this.$rootScope = $injector.get('$rootScope');
        this.$templateCache = $injector.get('$templateCache');
        this.$timeout = $injector.get('$timeout');
        this.tmhDynamicLocale = $injector.get('tmhDynamicLocale');
        this.$translate = $injector.get('$translate');
        this.$filter = $injector.get('$filter');
        this.$state = $injector.get('$state');
        this.$location = $injector.get('$location');
        this.restService = $injector.get('RestService');
        this.sessionService = $injector.get('SessionService');
        this.blockUI = $injector.get('blockUI');
        this.config = $injector.get('config');
        this.helperService = $injector.get('HelperService');
        this.defaultSettings = {
            color: {
                primary: '#7266ba',
                info: '#23b7e5',
                success: '#27c24c',
                warning: '#fad733',
                danger: '#f05050',
                light: '#e8eff0',
                dark: '#3a3f51',
                black: '#1c2b36',
                theme: '#00ADBE'
            },
            settings: {
                themeID: 15,
                language: ELanguage.enUS,
                navbarCollapseColor: 'material bg-blue',
                navbarHeaderColor: 'material bg-blue dark box-shadow',
                asideColor: 'material bg-white-only aside b-r box-shadow',
                headerFixed: true,
                asideFixed: true,
                asideFolded: false,
                asideDock: false,
                container: false,
                panelsReorder: true
            }
        };
        this.permissionService = new PermissionService(this.$rootScope, $injector);
    }

    $onInit() {
        this.init();
        this.registerApptemplates();
        this.loadAppSettings();
        // if (this.config.environment !== 'dev') this.checkCurrentVersion();
    }

    $onDestroy() {
        if (MonitorStatusService.Instance.$initialized) MonitorStatusService.Instance.destruct();
    }

    private init(): void {
        try {
            this.registerRootScopeFunctions();

            //register window refresh/exit events
            this.$window.onbeforeunload = this.handleRefreshEvent.bind(this);

            MonitorStatusService.Instance.init(this.injector);

        } catch (ex) {
            HandleError.exception(ex);
        }
    }


    private setDefaultThemeSetting(): void {
        try {
            this.$rootScope.app.color = angular.copy(this.defaultSettings.color);
            this.$rootScope.app.settings = angular.copy(this.defaultSettings.settings);

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    private async getSetting(settingName: string, profileName?: string): Promise<any> {
        try {
            const timeout = 15000;
            const userReference = this.$rootScope.user._id;

            //if has profileName, get just the profile name, otherwise get the entire profile list
            const route = (profileName) ? `/userSettings/${userReference}/${settingName}/${profileName}` : `/userSettings/${userReference}/${settingName}`;
            const result = await this.helperService.get(route, null, timeout);
            if (result && result.status === 200) {
                return (result.data && result.data.data && result.data.data[settingName]) ? result.data.data[settingName] : null;
            }
            return null;

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    private async updateSetting(settingName: string, data: IMonacoSettings, profileName?: string, showMessage: boolean = true, updateOnlySetting?: boolean): Promise<boolean> {
        if (!settingName || !data) return NotificationService().error(this.$filter("translate")(NotificationConstants.SETTING_UPDATE_ERROR));

        try {
            const timeout = 15000;
            let request = {
                userReference: this.$rootScope.user._id,
                settingName: settingName,
                data: data,
                profileName: 'DEFAULT',
                updateOnlySetting: updateOnlySetting,
                timeout: timeout
            };
            if (profileName) request.profileName = profileName;

            if (data && data.columns) {
                if (data.columns.some(column => column.filters && column.filters.some(filter => filter.term && filter.term.match("%")))) {
                    NotificationService().error(this.$filter("translate")(NotificationConstants.SETTING_UPDATE_INVALID_SEARCH));
                    return true;
                }

                for (const column of data.columns) {
                    for (const filter of column.filters) {
                        if (!filter.term || filter.term.trim() == "") {
                            delete filter.term;
                        }
                    }
                }
            }

            // Remove pagination property - performance issues.
            if (request && request.data && request.data["pagination"]) {
                delete request.data["pagination"];
            }

            // Remove pagination property - performance issues.
            if (request && request.data && request.data["pagination"]) {
                delete request.data["pagination"];
            }

            const result = await this.helperService.put(`/userSettings/update`, request, timeout);
            if (!result || result.status !== 200) return false;

            if (result && result.status === 200) {
                const currentLanguage = this.$translate.use();
                if (data && data.language && currentLanguage.toString() != data.language.CODE) {
                    this.$translate.use(data.language.CODE);
                    this.$rootScope.refreshPage();
                }
            }
            if (showMessage) {
                if (result && result.status === 200) NotificationService().success(this.$filter("translate")(NotificationConstants.SETTING_UPDATE_SUCCESS));
                else NotificationService().error(this.$filter("translate")(NotificationConstants.SETTING_UPDATE_ERROR));
            }

            return true;

        } catch (ex) {
            HandleError.exception(ex);
            return false;
        }
    }

    private async removeSetting(settingName: string, profileName?: string, showMessage: boolean = true): Promise<boolean> {
        try {
            const timeout = 15000;
            const userReference = this.$rootScope.user._id;

            //if has profileName, get just the profile name, otherwise get the entire profile list
            const route = (profileName) ? `/userSettings/remove/${userReference}/${settingName}/${profileName}` : `/userSettings/remove/${userReference}/${settingName}`;
            const result = await this.helperService.delete(route, null, timeout);
            if (!result || result.status !== 200) return false;

            if (showMessage) {
                if (result && result.status === 200) NotificationService().success(this.$filter("translate")(NotificationConstants.SETTING_DELETE_SUCCESS));
                else NotificationService().error(this.$filter("translate")(NotificationConstants.SETTING_DELETE_ERROR));
            }

            return true;

        } catch (ex) {
            HandleError.exception(ex);
            return false;
        }
    }

    private async getVersion(): Promise<any> {
        try {
            const timeout = 10000;
            const result = await this.helperService.get('/version/current', null, timeout);
            if (result && result.status === 200) {
                return (result.data && result.data.data) ? result.data.data : null;
            }
            return null;

        } catch (ex) {
            throw ex;
        }
    }

    private registerApptemplates(): void {
        try {
            //app
            this.$templateCache.put('headerTemplate', require("../view/header.html"));
            this.$templateCache.put('asideTemplate', require("../view/aside.html"));
            this.$templateCache.put('settingsTemplate', require("../view/settings.html"));
            this.$templateCache.put('navTemplate', require("../view/nav.html"));
            //monaco-grid
            this.$templateCache.put('gridTemplate', require("../view/template/monaco-grid.html"));
            this.$templateCache.put('gridExcelTemplate', require("../../gridExcel/view/monaco-grid-excel.html"));
            //this.$templateCache.put('monaco-grid-cell-template.html', require("../view/template/monaco-grid-cell-template.html"));
            //monaco-ui-select
            this.$templateCache.put("uiSelectTemplate", require("../view/template/monaco-ui-select.html"));
            this.$templateCache.put("uiSelectRequiredTemplate", require("../view/template/monaco-ui-select-required.html"));
            this.$templateCache.put("uiSelectMultipleTemplate", require("../view/template/monaco-ui-select-multiple.html"));
            this.$templateCache.put("uiSelectMultipleRequiredTemplate", require("../view/template/monaco-ui-select-multiple-required.html"));
            //monaco-ui-upload
            this.$templateCache.put("uiUploadSingleTemplate", require("../view/template/monaco-ui-upload-single.html"));
            this.$templateCache.put("uiUploadMultipleTemplate", require("../view/template/monaco-ui-upload-multiple.html"));
            //monaco gridForm
            this.$templateCache.put("gridFormTemplate", require("../../gridForm/view/gridForm.html"));
            this.$templateCache.put("buttonsTemplate", require("../../gridForm/view/buttons.html"));
            //monaco fab
            this.$templateCache.put("fabRemoveTemplate", require("../view/template/fab-remove.html"));
            //process
            this.$templateCache.put("processProfitShareTemplate", require("../../operational/view/processProfitShare.html"));
            this.$templateCache.put("processChargesTemplate", require("../../operational/view/processCharges.html"));
            this.$templateCache.put("processInvoiceClosureTemplate", require("../../operational/view/processInvoiceClosure.html"));
            this.$templateCache.put("processTasksTemplate", require("../../operational/view/processTasks.html"));
            this.$templateCache.put("processInttraTrackingEventsTemplate", require("../../operational/view/processInttraTrackingEvents.html"));
            this.$templateCache.put("processTrackingEventsTemplate", require("../../operational/view/processTrackingEvents.html"));
            //new processs
            this.$templateCache.put("NewProcessMainTemplate", require("../../operational/view/NewProcessMain.html"));
            this.$templateCache.put("NewProcessCargoTemplate", require("../../operational/view/NewProcessCargo.html"));
            this.$templateCache.put("NewProcessEventTemplate", require("../../operational/view/NewProcessEvent.html"));
            this.$templateCache.put("NewProcessChargeTemplate", require("../../operational/view/NewProcessCharge.html"));
            this.$templateCache.put("NewProcessChargeOutDateTemplate", require("../../operational/view/NewProcessChargeOutDate.html"));
            this.$templateCache.put("NewProcessCommunicationTemplate", require("../../operational/view/NewProcessCommunication.html"));
            this.$templateCache.put("NewProcessManagementTemplate", require("../../operational/view/NewProcessManagement.html"));
            this.$templateCache.put("NewProcessTaskTemplate", require("../../operational/view/NewProcessTask.html"));
            this.$templateCache.put("NewProcessDocumentTemplate", require("../../operational/view/NewProcessDocument.html"));
            this.$templateCache.put("NewProcessFlexitanqTemplate", require("../../operational/view/NewProcessFlexitanq.html"));

            //DetDem
            this.$templateCache.put("NewProcessDetDemMainTemplate", require("../../operational/view/NewProcessDetDemMain.html"));
            this.$templateCache.put("NewProcessDetDemEquipmentTemplate", require("../../operational/view/NewProcessDetDemEquipment.html"));
            this.$templateCache.put("NewProcessDetDemEventTemplate", require("../../operational/view/NewProcessDetDemEvent.html"));
            this.$templateCache.put("NewProcessDetDemContainerTemplate", require("../../operational/view/NewProcessDetDemContainer.html"));

            //custom logs
            this.$templateCache.put("logLineTemplate", require("../view/template/log-line.html"));
            this.$templateCache.put("followupProcessLogTemplate", require("../view/template/log-followup-process.html"));
            //modal
            this.$templateCache.put("modalCloseTemplate", require("../view/template/modal-close.html"));
            //cell
            this.$templateCache.put("cellRemoveRoleTemplate", require("../view/template/cell-remove_role.html"));
            this.$templateCache.put("cellRemoveSpecTemplate", require("../view/template/cell-remove-spec.html"));
            this.$templateCache.put("cellAddUserTemplate", require("../view/template/cell-add-user.html"));
            this.$templateCache.put("cellGroupingRoleTemplate", require("../view/template/cell-grouping-role.html"));
            //invoice
            this.$templateCache.put("invoiceLogAditionsTemplate", require("../../finop/view/invoiceLogAditions.html"));
            // tasks
            this.$templateCache.put("taskParamBasicDataTemplate", require("../../task/view/taskParamBasicDataPanel.html"));
            this.$templateCache.put("taskParamDetourListTemplate", require("../../task/view/taskParamDetourListPanel.html"));
            this.$templateCache.put("taskParamGeneralConditionsTemplate", require("../../task/view/taskParamGeneralConditionsPanel.html"));
            this.$templateCache.put("taskParamInvolvedTemplate", require("../../task/view/taskParamInvolvedPanel.html"));
            this.$templateCache.put("taskParamRouteTemplate", require("../../task/view/taskParamRoutePanel.html"));
            this.$templateCache.put("taskParamInvoiceTemplate", require("../../task/view/taskParamInvoicePanel.html"));
            this.$templateCache.put("taskParamCalcTemplate", require("../../task/view/taskParamCalcPanel.html"));
            this.$templateCache.put("taskParamTriggerTemplate", require("../../task/view/taskParamTriggerPanel.html"));
            this.$templateCache.put("taskParamHotspotTemplate", require("../../task/view/taskParamHotspotPanel.html"));
            this.$templateCache.put("taskParamIntegrationTemplate", require("../../task/view/taskParamIntegrationPanel.html"));

            //Wizard stopover
            this.$templateCache.put("wizardTravelTemplate", require("../../operational/view/wizardTravelPanel.html"));
            this.$templateCache.put("wizardDeadlinesTemplate", require("../../operational/view/wizardDeadlinesPanel.html"));
            this.$templateCache.put("wizardTraverStopoverTemplate", require("../../operational/view/wizardTravelStopoverPanel.html"));
            this.$templateCache.put("wizardMaritimeServiceTemplate", require("../../operational/view/wizardMaritimeServicePanel.html"));
            this.$templateCache.put("wizardTravelStopoverConfirmationTemplate", require("../../operational/view/wizardTravelConfirmationPanel.html"));

            //Offer templates
            this.$templateCache.put("offerMainTemplate", require("../../commercial/view/offerMainRegister.html"));
            this.$templateCache.put("offerCargoTemplate", require("../../commercial/view/offerCargoRegister.html"));
            this.$templateCache.put("offerEventTemplate", require("../../commercial/view/offerEventRegister.html"));
            this.$templateCache.put("offerChargeTemplate", require("../../commercial/view/offerChargeRegister.html"));
            this.$templateCache.put("offerChargeOutDateTemplate", require("../../commercial/view/offerChargeOutDateRegister.html"));
            this.$templateCache.put("offerManagementTemplate", require("../../commercial/view/offerManagementRegister.html"));
            this.$templateCache.put("offerCommunicationTemplate", require("../../commercial/view/offerCommunicationRegister.html"));
            this.$templateCache.put("offerReferenceTemplate", require("../../commercial/view/offerReferenceRegister.html"));

            //Offer New Alternative templates
            this.$templateCache.put("OfferNewAlternativeWizardOfferTemplate", require("../../commercial/view/modals/offerNewAlternativeWizardOffer.html"));
            this.$templateCache.put("OfferNewAlternativeWizardRoutesTemplate", require("../../commercial/view/modals/offerNewAlternativeWizardRoutes.html"));
            this.$templateCache.put("OfferNewAlternativeWizardCargoTemplate", require("../../commercial/view/modals/offerNewAlternativeWizardCargo.html"));
            this.$templateCache.put("OfferNewAlternativeWizardDetailsTemplate", require("../../commercial/view/modals/offerNewAlternativeWizardDetails.html"));
            this.$templateCache.put("OfferNewAlternativeWizardAlternativesTemplate", require("../../commercial/view/modals/offerNewAlternativeWizardAlternatives.html"));
            this.$templateCache.put("OfferNewAlternativeWizardRatesTemplate", require("../../commercial/view/modals/offerNewAlternativeWizardRates.html"));
            this.$templateCache.put("OfferNewAlternativeWizardChargesTemplate", require("../../commercial/view/modals/offerNewAlternativeWizardCharges.html"));
            this.$templateCache.put("OfferNewAlternativeWizardGeneralTemplate", require("../../commercial/view/modals/offerNewAlternativeWizardGeneral.html"));
            this.$templateCache.put("OfferNewAlternativeWizardConfirmationTemplate", require("../../commercial/view/modals/offerNewAlternativeWizardConfirmation.html"));

            // Combined Pre Offer Templates
            this.$templateCache.put("combinedPreOfferOfferTemplate", require("../../commercial/view/combinedPreOfferOfferRegister.html"));
            this.$templateCache.put("combinedPreOfferRouteTemplate", require("../../commercial/view/combinedPreOfferRouteRegister.html"));
            this.$templateCache.put("combinedPreOfferCargoTemplate", require("../../commercial/view/combinedPreOfferCargoRegister.html"));
            this.$templateCache.put("combinedPreOfferSpecTemplate", require("../../commercial/view/combinedPreOfferSpecRegister.html"));
            this.$templateCache.put("combinedPreOfferOptionTemplate", require("../../commercial/view/combinedPreOfferOptionRegister.html"));
            this.$templateCache.put("combinedPreOfferFollowUpTemplate", require("../../commercial/view/combinedPreOfferFollowUpRegister.html"));
            this.$templateCache.put("combinedPreOfferAttachmentTemplate", require("../../commercial/view/combinedPreOfferAttachmentRegister.html"));

            // Offer Opportunity Templates
            this.$templateCache.put("offerOpportunityOfferTemplate", require("../../commercial/view/offerOpportunityOfferRegister.html"));
            this.$templateCache.put("offerOpportunityRouteTemplate", require("../../commercial/view/offerOpportunityRouteRegister.html"));
            this.$templateCache.put("offerOpportunityCargoTemplate", require("../../commercial/view/offerOpportunityCargoRegister.html"));
            this.$templateCache.put("offerOpportunitySpecTemplate", require("../../commercial/view/offerOpportunitySpecRegister.html"));
            this.$templateCache.put("offerOpportunityOptionTemplate", require("../../commercial/view/offerOpportunityOptionRegister.html"));
            this.$templateCache.put("offerOpportunityFollowUpTemplate", require("../../commercial/view/offerOpportunityFollowUpRegister.html"));
            this.$templateCache.put("offerOpportunityAttachmentTemplate", require("../../commercial/view/offerOpportunityAttachmentRegister.html"));

            //Process Wizard
            this.$templateCache.put("processWizardFilterErrorsTemplate", require("../../operational/view/wizard/process/filterErrors.html"));
            this.$templateCache.put("processWizardOfferDataTemplate", require("../../operational/view/wizard/process/offerData.html"));
            this.$templateCache.put("processWizardDetailsTemplate", require("../../operational/view/wizard/process/details.html"));
            this.$templateCache.put("processWizardCargoTemplate", require("../../operational/view/wizard/process/cargo.html"));
            this.$templateCache.put("processWizardPreviewTemplate", require("../../operational/view/wizard/process/preview.html"));
            this.$templateCache.put("processWizardConfirmationTemplate", require("../../operational/view/wizard/process/confirmation.html"));

            //Wizard offer - CRM
            this.$templateCache.put("wizardOfferDataTemplate", require("../../crm/view/wizardOfferData.html"));
            this.$templateCache.put("wizardOfferCargoTemplate", require("../../crm/view/wizardOfferCargo.html"));
            this.$templateCache.put("wizardOfferInstructionsTemplate", require("../../crm/view/wizardOfferInstructions.html"));
            this.$templateCache.put("wizardOfferConfirmationTemplate", require("../../crm/view/wizardOfferConfirmation.html"));

            //Wizard Booking templates
            this.$templateCache.put("BookingWizardGeneralDetailsTemplate", require("../../operational/view/bookingWizardGeneralDetails.html"));
            this.$templateCache.put("BookingWizardTransportTemplate", require("../../operational/view/bookingWizardTransport.html"));
            this.$templateCache.put("BookingWizardCargoTemplate", require("../../operational/view/bookingWizardCargo.html"));
            this.$templateCache.put("BookingWizardConfirmationTemplate", require("../../operational/view/bookingWizardConfirmation.html"));

            //Commercial templates
            this.$templateCache.put("AccountRequirementTemplate", require("../../commercial/view/accountRequirement.html"));
            this.$templateCache.put("AccountPotentialTemplate", require("../../commercial/view/accountPotential.html"));

            //Charges per weight range modal template
            this.$templateCache.put("ChargesPerWeightRangeListBodyTemplate", require("../view/modals/chargesPerWeightRangeListBody.html"));

            //Wizard Base Flight templates
            this.$templateCache.put("AirFlightWizardGeneralDetailsTemplate", require("../../operational/view/baseAirFlightWizardGeneralDetails.html"));
            this.$templateCache.put("AirFlightWizardConfirmationTemplate", require("../../operational/view/baseAirFlightWizardConfirmation.html"));

            // Process Event Modal Templates
            this.$templateCache.put("ProcessEventDetailModalAirFlight", require("../../operational/view/processEventDetailModalAirFlight.html"));
            this.$templateCache.put("ProcessEventDetailModalMaritime", require("../../operational/view/processEventDetailModalMaritime.html"));
            this.$templateCache.put("ProcessEventDetailModalRoad", require("../../operational/view/processEventDetailModalRoad.html"));

            // Process Deadline Modal Templates
            this.$templateCache.put("ProcessDeadlineAirFlight", require("../../operational/view/processDeadlineAirFlight.html"));
            this.$templateCache.put("ProcessDeadlineMaritime", require("../../operational/view/processDeadlineMaritime.html"));

            // Process Tracking Templates
            this.$templateCache.put("ProcessTrackingAirFlight", require("../../operational/view/processTrackingAirFlight.html"));
            this.$templateCache.put("ProcessTrackingMaritime", require("../../operational/view/processTrackingMaritime.html"));

            // PreProcess Wizard Templates
            this.$templateCache.put("preProcessWizardPreTabTemplate", require("../../operational/view/wizard/preProcess/preProcessWizardPreTab.html"));
            this.$templateCache.put("preProcessWizardRoutesTabTemplate", require("../../operational/view/wizard/preProcess/preProcessWizardRoutesTab.html"));
            this.$templateCache.put("preProcessWizardCargoTabTemplate", require("../../operational/view/wizard/preProcess/preProcessWizardCargoTab.html"));
            this.$templateCache.put("preProcessWizardSpecTabTemplate", require("../../operational/view/wizard/preProcess/preProcessWizardSpecTab.html"));
            this.$templateCache.put("preProcessWizardPreviewTabTemplate", require('../../operational/view/wizard/preProcess/preProcessWizardPreviewTab.html'));
            this.$templateCache.put("preProcessWizardFilterErrorsTemplate", require('../../operational/view/wizard/preProcess/preProcessWizardFilterErrors.html'));
            this.$templateCache.put("preProcessWizardConfirmationTabTemplate", require('../../operational/view/wizard/preProcess/preProcessWizardConfirmationTab.html'));

            // Freight Wizard Templates
            this.$templateCache.put("freightWizardRoutesTabTemplate", require("../../product/view/wizard/freight/freightWizardRoutesTab.html"));
            this.$templateCache.put("freightWizardVigencyTabTemplate", require("../../product/view/wizard/freight/freightWizardVigencyTab.html"));
            this.$templateCache.put("freightWizardPreviewTabTemplate", require("../../product/view/wizard/freight/freightWizardPreviewTab.html"));
            this.$templateCache.put("freightWizardConfirmationTabTemplate", require("../../product/view/wizard/freight/freightWizardConfirmationTab.html"));

            // Consolidated Wizard Templates
            this.$templateCache.put("consolidatedWizardChargeTabTemplate", require("../../operational/view/wizard/consolidated/consolidatedChargeWizardPanel.html"));
            this.$templateCache.put("consolidatedWizardDocumentTabTemplate", require("../../operational/view/wizard/consolidated/consolidatedDocumentWizardPanel.html"));
            this.$templateCache.put("consolidatedWizardProcessTabTemplate", require("../../operational/view/wizard/consolidated/consolidatedProcessWizardPanel.html"));
            this.$templateCache.put("consolidatedWizardInstructionTabTemplate", require("../../operational/view/wizard/consolidated/consolidatedInstructionWizardPanel.html"));
            this.$templateCache.put("consolidatedWizardEventTabTemplate", require("../../operational/view/wizard/consolidated/consolidatedEventWizardPanel.html"));

            // PreProcess OfferCompiled Wizard Templates
            this.$templateCache.put("preProcessOfferCompiledWizardPreTabTemplate", require("../../operational/view/wizard/preProcessOfferCompiled/preProcessOfferCompiledWizardPreTab.html"));
            this.$templateCache.put("preProcessOfferCompiledWizardRoutesTabTemplate", require("../../operational/view/wizard/preProcessOfferCompiled/preProcessOfferCompiledWizardPreRoutesTab.html"));
            this.$templateCache.put("preProcessOfferCompiledWizardPreCargoTabTemplate", require("../../operational/view/wizard/preProcessOfferCompiled/preProcessOfferCompiledWizardPreCargo.html"));
            this.$templateCache.put("preProcessOfferCompiledWizardPreSpecTabTemplate", require("../../operational/view/wizard/preProcessOfferCompiled/preProcessOfferCompiledWizardSpecTab.html"));
            this.$templateCache.put("preProcessOfferCompiledWizardPreviewTabTemplate", require("../../operational/view/wizard/preProcessOfferCompiled/preProcessOfferCompiledWizardPreviewTab.html"));
            this.$templateCache.put("preProcessOfferCompiledFiltersErrorsTemplate", require("../../operational/view/wizard/preProcessOfferCompiled/preProcessOfferCompiledFiltersErrors.html"));
            this.$templateCache.put("preProcessOfferCompiledConfirmationTabTemplate", require("../../operational/view/wizard/preProcessOfferCompiled/preProcessOfferCompiledConfirmationTab.html"));
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    private registerAppSettings(): void {
        try {
            this.$rootScope.$watch('app.settings', (newValue: any, oldValue: any, scope: IMonacoScope) => {
                // check if menu needs to be centralized
                if ($('#nav').length > 0) {
                    if (scope.app.settings.asideFolded) {
                        // nav is FOLDED, so CSS do the magic
                        $('#nav').animate({ 'left': ((($(document).width() - $('#nav').width()) / 2)) + "px" }, { duration: 500, queue: false });
                    } else {

                        // when nav is not folded, centered in div
                        $('#nav').animate({ 'left': ((($(document).width() - $('#nav').width()) / 2) + 100) + "px" }, { duration: 500, queue: false });
                    }
                }
                //header fixed
                if (scope.app.settings.asideDock && scope.app.settings.asideFixed) {
                    // aside dock and fixed must set the header fixed.
                    scope.app.settings.headerFixed = true;
                }
                // for box layout, add background image
                scope.app.settings.container ? angular.element('html').addClass('bg') : angular.element('html').removeClass('bg');
            }, true);

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    private registerRootScopeFunctions(): void {
        try {

            this.$rootScope.goToSelectedMenu = (menu: IMonacoMenu) => {
                if (menu && menu.ROUTE) this.sessionService.openRoute(menu.ROUTE);
            }

            this.$rootScope.buildMenuSearchList = async (search: string) => {
                if (this.$rootScope.app) {
                    this.$rootScope.app.menuSearchList = await this.buildMonacoMenu(search);
                    this.$rootScope.$applyAsync();
                }
            }

            this.$rootScope.onUnauthorized = (element: any) => {
                if (element) angular.element(element).remove();
            }

            //register scope functions
            this.$rootScope.refreshPage = () => {
                this.$state.reload();
            }

            this.$rootScope.logout = async () => {
                await this.sessionService.logout();
                //await this.sessionService.redirectToLogin();
                //const loginState = this.config.loginState;
                //this.$state.go(loginState);
            }

            this.$rootScope.defaultThemeSetting = () => {
                this.setDefaultThemeSetting();
            }

            this.$rootScope.getSetting = async (name: string, profileName: string) => {
                const setting = await this.getSetting(name, profileName);
                return setting;
            }

            this.$rootScope.updateSetting = async (name: string, data: any, profileName: string, showMessage?: boolean, updateOnlySetting?: boolean) => {
                return await this.updateSetting(name, data, profileName, showMessage, updateOnlySetting);
            }

            this.$rootScope.removeSetting = async (name: string, profileName: string, showMessage?: boolean) => {
                return await this.removeSetting(name, profileName, showMessage);
            }

            this.$rootScope.setBreadCrumb = (name: string, action: Function, label?: string, icon?: string) => {
                this.$rootScope.breadCrumb.name = name;
                this.$rootScope.breadCrumb.action = action
                if (label) this.$rootScope.breadCrumb.label = label;
                this.$rootScope.breadCrumb.icon = (icon) ? icon : 'fa fa-plus';
                this.$rootScope.breadCrumb.show = true;
            }

            this.$rootScope.clearBreadCrumb = () => {
                this.$rootScope.breadCrumb.name = null;
                this.$rootScope.breadCrumb.action = null;
                this.$rootScope.breadCrumb.label = null;
                this.$rootScope.breadCrumb.icon = null;
                this.$rootScope.breadCrumb.show = false;
            }

            this.$rootScope.showBreadCrumb = () => {
                if (this.$rootScope.breadCrumb) this.$rootScope.breadCrumb.show = true;
            }

            this.$rootScope.hideBreadCrumb = () => {
                if (this.$rootScope.breadCrumb) this.$rootScope.breadCrumb.show = false;
            }

            this.$rootScope.getCurrentUrl = () => {
                return this.getCurrentUrl();
            }

            this.$rootScope.openNewTab = () => {
                this.sessionService.openTab(this.getCurrentUrl());
            }

            this.$rootScope.goToFatturo = () => {
                this.sessionService.redirectToFatturo();
            }

            //register session_event callback
            //this.$rootScope.$on('SESSION_EVENT', this.handleSessionEvent.bind(this));

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    private initBreadCrumb(): void {
        this.$rootScope.breadCrumb = {
            label: null,
            name: null,
            action: null,
            icon: 'fa fa-plus',
            show: false
        };
    }

    private getCurrentUrl() {
        console.log("URL:" + this.$location.url());
        return this.$location.url();
    }

    private async buildMonacoMenu(search: string): Promise<IMonacoMenu[]> {
        try {
            let monacoMenu: IMonacoMenu[] = [
                // PRODUCT
                { NAME: this.$filter("translate")("GENERAL.MENU.TARIFF_FREIGHT"), ROUTE: "app.product.tariffFreight", ROLE: "PRODUCTMENUTARIFFFREIGHT", PARENT: { NAME: "GENERAL.MENU.PRODUCT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.TARIFF_FREIGHT_FCL"), ROUTE: "app.product.tariffFreightFcl", ROLE: "PRODUCTMENUTARIFFFREIGHTFCL", PARENT: { NAME: "GENERAL.MENU.PRODUCT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.TARIFF_FREIGHT_FCL"), ROUTE: "app.product.newTariffFreightFcl", ROLE: "PRODUCTMENUFREIGHTFCL", PARENT: { NAME: "GENERAL.MENU.PRODUCT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.TARIFF_FREIGHT_AIR"), ROUTE: "app.product.tariffFreightAir", ROLE: "PRODUCTMENUTARIFFFREIGHTAIR", PARENT: { NAME: "GENERAL.MENU.PRODUCT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.TARIFF_FREIGHT_AIR"), ROUTE: "app.product.newTariffFreightAir", ROLE: "PRODUCTMENUFREIGHTAIR", PARENT: { NAME: "GENERAL.MENU.PRODUCT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.TARIFF_FREIGHT_CHARGES"), ROUTE: "app.product.tariffFreightCharges", ROLE: "PRODUCTMENUTARIFFFREIGHTCHARGES", PARENT: { NAME: "GENERAL.MENU.PRODUCT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.TARIFF_LOCAL"), ROUTE: "app.product.tariffLocal", ROLE: "PRODUCTMENUTARIFFFREIGHTLOCAL", PARENT: { NAME: "GENERAL.MENU.PRODUCT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.TARIFF_DET_DEM"), ROUTE: "app.product.tariffDetDem", ROLE: "PRODUCTMENUTARIFFFREIGHTDETDEM", PARENT: { NAME: "GENERAL.MENU.PRODUCT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.TARIFF_DOMESTIC"), ROUTE: "app.product.tariffDomestic", ROLE: "PRODUCTMENUTARIFFFREIGHTDOMESTIC", PARENT: { NAME: "GENERAL.MENU.PRODUCT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.FREIGHT_CONTRACT"), ROUTE: "app.product.freightContract", PARENT: { NAME: "GENERAL.MENU.PRODUCT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.PROFIT_SHARE"), ROUTE: "app.product.paramsProfitShare", PARENT: { NAME: "GENERAL.MENU.PRODUCT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.MARITIME_SERVICE"), ROUTE: "app.product.maritimeService", PARENT: { NAME: "GENERAL.MENU.PRODUCT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.AIR_SERVICE"), ROUTE: "app.product.airService", PARENT: { NAME: "GENERAL.MENU.PRODUCT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.DOMESTIC_SERVICE"), ROUTE: "app.product.domesticService", PARENT: { NAME: "GENERAL.MENU.PRODUCT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.AUTO_RATING_EXCEPTION"), ROUTE: "app.product.autoRatingParam", PARENT: { NAME: "GENERAL.MENU.PRODUCT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.AUTO_RATING_CONTROL"), ROUTE: "app.product.autoRatingControl", PARENT: { NAME: "GENERAL.MENU.PRODUCT", ROUTE: null } },
                { NAME: this.$filter("translate")("PRODUCT.INLAND_CONTRACT"), ROUTE: "app.product.inlandContract", PARENT: { NAME: "GENERAL.MENU.PRODUCT", ROUTE: null } },
                { NAME: this.$filter("translate")("PRODUCT.INLAND_ROUTES"), ROUTE: "app.product.inlandRoutes", PARENT: { NAME: "GENERAL.MENU.PRODUCT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.MASTER_BILLING_SETTINGS"), ROUTE: "app.product.masterBillingParam", PARENT: { NAME: "GENERAL.MENU.PRODUCT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.FREIGHT_ROUTES"), ROUTE: "app.product.freightRoutes", PARENT: { NAME: "GENERAL.MENU.PRODUCT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.TARIFF_COMPLEMENTARY"), ROUTE: "app.product.tariffComplementary", PARENT: { NAME: "GENERAL.MENU.PRODUCT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.FREIGHT_CONTRACT"), ROUTE: "app.product.newFreightContract", ROLE: "PRODUCTMENUTARIFFFREIGHT", PARENT: { NAME: "GENERAL.MENU.PRODUCT", ROUTE: null } },
                // COMMERCIAL
                { NAME: this.$filter("translate")("GENERAL.MENU.OFFER"), ROUTE: "app.commercial.offer", PARENT: { NAME: "GENERAL.MENU.COMMERCIAL", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.PRE_OFFER"), ROUTE: "app.commercial.preoffer", PARENT: { NAME: "GENERAL.MENU.COMMERCIAL", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.PRE_OFFER"), ROUTE: "app.commercial.combinedPreOffer", PARENT: { NAME: "GENERAL.MENU.COMMERCIAL", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.ACCOUNT"), ROUTE: "app.commercial.account", PARENT: { NAME: "GENERAL.MENU.COMMERCIAL", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.ACCOUNT_BULK_CHANGE_SALES_PERSON"), ROUTE: "app.commercial.account.bulkChangeSalesPerson", PARENT: { NAME: "GENERAL.MENU.COMMERCIAL", ROUTE: null } },
                // OPERATIONAL
                { NAME: this.$filter("translate")("GENERAL.MENU.PROCESS"), ROUTE: "app.operational.newProcess.list", PARENT: { NAME: "GENERAL.MENU.OPERATIONAL", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.APPROVED_OFFER"), ROUTE: "app.operational.approvedOffer", PARENT: { NAME: "GENERAL.MENU.OPERATIONAL", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.BOOKING"), ROUTE: "app.operational.bookingList", PARENT: { NAME: "GENERAL.MENU.OPERATIONAL", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.BOOKING_PAYMENT_CONFIG"), ROUTE: "app.operational.bookingPaymentConfig", PARENT: { NAME: "GENERAL.MENU.OPERATIONAL", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.DRAFT_RECEPTION"), ROUTE: "app.operational.draftRegister", PARENT: { NAME: "GENERAL.MENU.OPERATIONAL", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.PROCESS_KNOWLEDGE"), ROUTE: "app.operational.process.document", PARENT: { NAME: "GENERAL.MENU.OPERATIONAL", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.PROCESS_KNOWLEDGE"), ROUTE: "app.operational.process.processDocument", PARENT: { NAME: "GENERAL.MENU.OPERATIONAL", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.DETENTION_DEMURRAGE"), ROUTE: "app.operational.process.detDemManagement", PARENT: { NAME: "GENERAL.MENU.OPERATIONAL", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.DETENTION_DEMURRAGE"), ROUTE: "app.operational.process.detDemProcess", PARENT: { NAME: "GENERAL.MENU.OPERATIONAL", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.VOYAGE_STOPOVER"), ROUTE: "app.operational.voyagesAndDeadlines", PARENT: { NAME: "GENERAL.MENU.OPERATIONAL", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.DEADLINE_PROCESS"), ROUTE: "app.registration.deadlineProcess", PARENT: { NAME: "GENERAL.MENU.OPERATIONAL", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.SLA_DEADLINE"), ROUTE: "app.registration.slaDeadline", PARENT: { NAME: "GENERAL.MENU.OPERATIONAL", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.AWB_STOCK"), ROUTE: "app.operational.awbstock", PARENT: { NAME: "GENERAL.MENU.OPERATIONAL", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.VOYAGES"), ROUTE: "app.operational.voyages", PARENT: { NAME: "GENERAL.MENU.VOYAGES", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.CONSOLIDATED"), ROUTE: "app.operational.consolidated", PARENT: { NAME: "GENERAL.MENU.OPERATIONAL", ROUTE: null } },
                // FINOP            
                { NAME: this.$filter("translate")("GENERAL.MENU.CHARGES"), ROUTE: "app.finop.charge.register", PARENT: { NAME: "GENERAL.MENU.FINOP", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.INVOICES"), ROUTE: "app.finop.invoice.register", PARENT: { NAME: "GENERAL.MENU.FINOP", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.CHARGE_GROUPING"), ROUTE: "app.finop.charge.grouping.register", PARENT: { NAME: "GENERAL.MENU.FINOP", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.FINANCIAL_CONTRACT"), ROUTE: "app.finop.contract.financialContract", PARENT: { NAME: "GENERAL.MENU.FINOP", ROUTE: null } },
                // GERENCIAMENTO
                { NAME: this.$filter("translate")("GENERAL.MENU.ACTIVITY_SCHEDULE"), ROUTE: "app.management.activities", PARENT: { NAME: "GENERAL.MENU.MANAGEMENT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.ACTIVITY_SCHEDULE_HISTORY"), ROUTE: "app.management.newTaskHistory", PARENT: { NAME: "GENERAL.MENU.MANAGEMENT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.FOLLOW_UP"), ROUTE: "app.management.notificationMonitor", PARENT: { NAME: "GENERAL.MENU.MANAGEMENT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.INTEGRATION"), ROUTE: "app.management.integration", PARENT: { NAME: "GENERAL.MENU.MANAGEMENT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.NOTIFICATION"), ROUTE: "app.management.notificationMailBox", PARENT: { NAME: "GENERAL.MENU.MANAGEMENT", ROUTE: null } },
                // RELATORIOS
                { NAME: this.$filter("translate")("GENERAL.MENU.REGISTER"), ROUTE: "app.datamanager.report", PARENT: { NAME: "GENERAL.MENU.REPORT", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.SCHEDULES"), ROUTE: "app.datamanager.scheduling", PARENT: { NAME: "GENERAL.MENU.REPORT", ROUTE: null } },
                // CADASTROS
                { NAME: this.$filter("translate")("GENERAL.MENU.TRADE_LANE"), ROUTE: "app.registration.tradelane", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.COUNTRY"), ROUTE: "app.registration.country", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.PROVINCE"), ROUTE: "app.registration.province", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.CITY"), ROUTE: "app.registration.city", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.ROUTING_POINT"), ROUTE: "app.registration.routingPoint", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.LEGAL_PERSON"), ROUTE: "app.registration.legalPerson", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.PHYSICAL_PERSON"), ROUTE: "app.registration.physicalPerson", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.NETWORK"), ROUTE: "app.registration.network", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.AGENCY"), ROUTE: "app.registration.agency", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.AGENT"), ROUTE: "app.registration.agent", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.BANK"), ROUTE: "app.registration.bank", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.BROKER"), ROUTE: "app.registration.broker", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.PROVIDER"), ROUTE: "app.registration.provider", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.PERSON_ROLE"), ROUTE: "app.registration.personRole", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.SECTOR"), ROUTE: "app.registration.sector", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.OUTER_CELL"), ROUTE: "app.registration.outerCell", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.CONTACT"), ROUTE: "app.registration.contact", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.LOGISTIC_CHARGES"), ROUTE: "app.registration.chargeName", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.SUBGROUP_LOGISTIC_CHARGES"), ROUTE: "app.registration.chargeNameSubGroup", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.APPLICATION"), ROUTE: "app.registration.application", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.EQUIPMENT"), ROUTE: "app.registration.equipment", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.EVENT"), ROUTE: "app.registration.event", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.WEIGHT_RANGE"), ROUTE: "app.registration.weightRange", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.HOLIDAY"), ROUTE: "app.registration.holiday", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.INCOTERM"), ROUTE: "app.registration.incoterm", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.SERVICE_LEVEL"), ROUTE: "app.registration.servicelevel", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.CELL_PARAMETERIZATION"), ROUTE: "app.registration.cellParameter", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.MOVE_TYPE"), ROUTE: "app.registration.moveType", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.SERVICE_TYPE"), ROUTE: "app.registration.servicetype", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.COMMODITY"), ROUTE: "app.registration.commodity", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.CURRENCY"), ROUTE: "app.registration.currency", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.SHIP"), ROUTE: "app.registration.ship", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.USER"), ROUTE: "app.registration.user", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.USER_GROUP"), ROUTE: "app.registration.userGroup", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.ACCESS_RULES"), ROUTE: "app.registration.userRoles", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.FOLLOW_UP_MODEL"), ROUTE: "app.registration.followUpModel", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.FOLLOW_UP_NOTIFICATION"), ROUTE: "app.registration.followUpNotification", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.FOLLOW_UP_PARAMETERIZATION"), ROUTE: "app.registration.followUpParams", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.NOTIFICATION_TYPE"), ROUTE: "app.registration.notificationType", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.FROM_TO"), ROUTE: "app.registration.ediExternalCodeSetup", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.HOT_SPOT_REGISTRATION"), ROUTE: "app.registration.hotspot", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.ACTIVITY_PARAMETERIZATION"), ROUTE: "app.registration.param", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.AUTOMATION_RULES"), ROUTE: "app.registration.reportAutomaticRules", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.AGENT_PARAMETERIZATION"), ROUTE: "app.registration.agentsParams", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.DETENTION_DEMURRAGE_PARAMETERIZATION"), ROUTE: "app.registration.paramsDetDem", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("BASIC_DATA.BRANCH"), ROUTE: "app.registration.corporateBranch", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.RESPONSIBLE_REASON_SETTINGS"), ROUTE: "app.registration.responsiblereasonsetting", PARENT: { NAME: "GENERAL.MENU.REGISTER", ROUTE: null } },
                // ADMIN
                { NAME: this.$filter("translate")("GENERAL.MENU.UNIT_CORPORATE"), ROUTE: "app.admin.corporateUnit", PARENT: { NAME: "GENERAL.MENU.ADMIN", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.SCORE_CONFIGURATION"), ROUTE: "app.admin.scoreConfig", PARENT: { NAME: "GENERAL.MENU.ADMIN", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.GENERAL_CONFIGURATION"), ROUTE: "app.admin.productConfiguration", PARENT: { NAME: "GENERAL.MENU.ADMIN", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.COLLECTION_STATISTIC"), ROUTE: "app.admin.analyzeCollection", PARENT: { NAME: "GENERAL.MENU.ADMIN", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.COLLECTION_DOCUMENT_STATISTIC"), ROUTE: "app.admin.analyzeCollectionData", PARENT: { NAME: "GENERAL.MENU.ADMIN", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.GENERIC"), ROUTE: "app.admin.generic", PARENT: { NAME: "GENERAL.MENU.ADMIN", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.SCORE"), ROUTE: "app.admin.score", PARENT: { NAME: "GENERAL.MENU.ADMIN", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.SERVICE_STATUS"), ROUTE: "app.admin.serviceStatus", PARENT: { NAME: "GENERAL.MENU.ADMIN", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.ERROR_MONITORING"), ROUTE: "app.admin.errorLog", PARENT: { NAME: "GENERAL.MENU.ADMIN", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.LOG_UPDATE_CHARGES"), ROUTE: "app.admin.exchangeRateLog", PARENT: { NAME: "GENERAL.MENU.ADMIN", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.CONNECTED_USER"), ROUTE: "app.admin.connectedUsers", PARENT: { NAME: "GENERAL.MENU.ADMIN", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.ERP_NATURE"), ROUTE: "app.admin.erpNatureSetup", PARENT: { NAME: "GENERAL.MENU.ADMIN", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.ERP_TOP"), ROUTE: "app.admin.erpTopSetup", PARENT: { NAME: "GENERAL.MENU.ADMIN", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.ERP_PROJECT"), ROUTE: "app.admin.erpProjectSetup", PARENT: { NAME: "GENERAL.MENU.ADMIN", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.ERP_NEGOTIATE_TYPE"), ROUTE: "app.admin.erpNegotiationTypeSetup", PARENT: { NAME: "GENERAL.MENU.ADMIN", ROUTE: null } },
                { NAME: this.$filter("translate")("GENERAL.MENU.ERP_TITLE_TYPE"), ROUTE: "app.admin.erpPaymentMethodSetup", PARENT: { NAME: "GENERAL.MENU.ADMIN", ROUTE: null } }
            ];
            // filter by search text
            monacoMenu = monacoMenu.filter(menu => menu.NAME.toLocaleLowerCase().includes(search ? search.toLocaleLowerCase() : ""));

            // filter permission
            const allowedMenu: IMonacoMenu[] = [];
            for (const menu of monacoMenu) {
                if (!menu.ROLE || (menu.ROLE && await this.permissionService.isMenuRoleAllowed(menu.ROLE))) allowedMenu.push(menu);
            }
            return allowedMenu;
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    private async loadAppSettings(): Promise<void> {
        try {
            this.blockUI.start();

            //init app
            this.$rootScope.app = {
                team: 'Allog Dev Team',
                name: this.config.name,
                version: this.config.version,
                environment: this.config.environment,
                year: new Date().getFullYear(),
                currentUrl: this.$location.url(),
                color: angular.copy(this.defaultSettings.color),
                settings: angular.copy(this.defaultSettings.settings),
                languageList: [ELanguage.enUS, ELanguage.ptBR],
                menuSearchList: []
            };
            this.registerAppSettings();
            this.initBreadCrumb();

            //get user theme setting from the database
            const settings = await this.getSetting('THEME');
            if (settings) {
                this.$rootScope.app.settings = settings;
                if (settings.language && settings.language.ID != this.defaultSettings.settings.language.ID) this.$translate.use(settings.language.CODE);
                const currentLanguage = settings.language ? this.$rootScope.app.languageList.find(language => language.ID == settings.language.ID) : null;
                moment.locale(currentLanguage ? currentLanguage.MOMENT_CODE : 'en-ie');
                this.tmhDynamicLocale.set(currentLanguage ? currentLanguage.CODE : 'en-US');
            }

            await this.$rootScope.$applyAsync();
            this.blockUI.stop();

        } catch (ex) {
            this.$timeout(() => {
                this.blockUI.stop();
            });
            HandleError.exception(ex);
        }
    };

    private async checkCurrentVersion(): Promise<void> {
        try {
            let latestVersion: string | string[] = await this.getVersion();
            if (!latestVersion) {
                this.$timeout(this.checkCurrentVersion.bind(this), 180000);
                return;
            }

            const currentVersion = this.config.version.split('.');
            const currentMajor = parseInt(currentVersion[0]);
            const currentMinor = parseInt(currentVersion[1]);
            const currentBuild = parseInt(currentVersion[2]);

            console.log(`current version: ${currentMajor} ${currentMinor} ${currentBuild}`);

            const latestVersionString = latestVersion;
            latestVersion = (latestVersion as string).split('.');
            const latestMajor = parseInt(latestVersion[0]);
            const latestMinor = parseInt(latestVersion[1]);
            const latestBuild = parseInt(latestVersion[2]);

            console.log(`latest version: ${latestMajor} ${latestMinor} ${latestBuild}`);

            if (latestMajor > currentMajor || latestMinor > currentMinor || latestBuild > currentBuild) {
                //outdated version, force user to refresh the page
                console.log(`outdated version ${this.config.version}, new version ${latestVersionString}, refreshing page..`);

                const ModalService = this.injector.get('ModalService');
                const modalID = ModalService.newModal();
                await ModalService.showModalInfo(
                    {
                        modalID: modalID
                    },
                    {
                        headerText: `Versão Desatualizada`,
                        bodyText: `Versão desatualizada, click em recarregar para atualizar a página para a nova versão ${latestVersionString}`,
                        actionButtonText: 'Recarregar'
                    });
                this.$window.location.reload();

            } else {
                //3 min interval
                this.$timeout(this.checkCurrentVersion.bind(this), 180000);
            }

        } catch (ex) {
            this.$timeout(this.checkCurrentVersion.bind(this), 180000);
            HandleError.exception(ex);
        }
    }

    private handleRefreshEvent(): void {
        try {
            console.log('REFRESH EVENT');

            //close websocket connection anyway
            const webSocketService: IWebsocketService = this.injector.get('WebSocketService');
            if (webSocketService) webSocketService.close(true);

            if (this.sessionService.isAuthenticated()) this.sessionService.saveSessionOnStorage();
            else this.sessionService.redirectToLogin().catch((ex) => {
                HandleError.exception(ex);
            });
            //else await this.sessionService.redirectToLogin();

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    /*   private handleSessionEvent(event: IAngularEvent, data: string): void {
        try {
          event.defaultPrevented = false;
          this.webSocketService.init(data);
        } catch (ex) {
          console.log(ex);
        }
      } */


}
import "angular-ui-grid/ui-grid.min.css";
import "ui-select/dist/select.min.css";
import '../../css/grid.css';

import * as angular from 'angular';
import 'angular-ui-grid';
import 'ui-select';
import 'angular-input-masks';

import { FormService } from '@services/FormService';
import { GridService } from '@services/GridService';
import { ModalService } from '@services/ModalService';


const GRID_FORM_MODULE = angular
    .module('gridForm', [
        'ui.grid',
        'ui.grid.saveState',
        'ui.grid.pinning',
        'ui.grid.resizeColumns',
        'ui.grid.moveColumns',
        'ui.grid.selection',
        'ui.grid.pagination',
        'ui.grid.autoResize',
        'ui.grid.exporter',
        'ui.select',
        'ui.utils.masks'
    ])
    .service('FormService', FormService)
    .service('GridService', GridService)
    .service('ModalService', ModalService)

export { GRID_FORM_MODULE };
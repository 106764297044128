import { FormService2 } from "@services/FormService2";
import { IRestService } from "@services/RestService";
import { IMonacoRequest } from '@services/GridFormService';
import { IMonacoController, IMonacoConfig } from "../../common/MonacoInterface";
import { SelectorModel } from "../../common/model/SelectorModel";
import { IPhysicalPersonListCustomFilter, IPhysicalPersonSelectorModel } from "../../registration/model/PhysicalPersonModel";
import { IRequirementListBySalesPersonFilter } from "../model/AccountModel";
import { HelperService } from "@services/HelperService";

interface IRequirementList extends SelectorModel {
    ACTIVE: boolean;
    SELECTED: boolean;
    ACCOUNT_NAME: string;
}

interface IAccountBulkChangesSalesPerson {
    TYPE_SALES_PERSON: SelectorModel;
    SALES_PERSON: SelectorModel;
    REQUIREMENT_LIST: IRequirementList[];
    NEW_SALES_PERSON: SelectorModel;
    SELECT_ALL: boolean;
}

interface IUiTabSteps {
    percent: number
    current: number
}

interface IAccountRequirementUpdateSalesPersonRequest {
    requirementIds: number[];
    idTypeSalesPerson: string;
    salesPerson: SelectorModel;
    newSalesPerson: SelectorModel;
}

interface IAccountBulkChangeSalesPersonWizardScope extends ng.IScope {
    model: IAccountBulkChangesSalesPerson;
    salesPersonList: SelectorModel[];
    salesPersonTypeList: SelectorModel[];
    steps: IUiTabSteps;
    replacerSeller: string;
    getEmptySelectorMsg: () => string;
    getSalesPersonListByName: (search: string) => Promise<void>;
    getSubstituteSalesPersonListByName: (search: string) => Promise<void>;
    salesPersonChange: () => void;
    reset: () => void;
    next: () => Promise<void>;
    back: () => void;
    save: () => Promise<void>;
    toggleCheckAll: () => void;
    verifyChecked: ()=> void;
}

export class AccountBulkChangeSalesPersonWizardController implements IMonacoController {
    public static $inject: string[] = ['$scope', '$injector'];
    private $scope: IAccountBulkChangeSalesPersonWizardScope;
    private $q: ng.IQService;
    private formService: FormService2;
    private restService: IRestService;
    private config: IMonacoConfig;
    private baseUrl: string;
    private helperService: HelperService;

    constructor($scope, $injector: ng.Injectable<any>) {
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.restService = $injector.get('RestService');
        this.formService = new FormService2($injector, $scope);
        this.config = $injector.get('config');
        this.helperService = $injector.get('HelperService');
    }

    async $onInit() {
        this.baseUrl = this.getUrlProduct();
        this.formService.block();
        this.initModel();
        this.registerScopeFunctions();
        await this.initDependencies();
        this.formService.unblock();

        
    }


    private initDependencies(): Promise<any> {
        const self: AccountBulkChangeSalesPersonWizardController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getSalesPersonTypeList()
            ]).then(async (result: any) => {
                self.$scope.salesPersonTypeList = result[0];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private initModel(): void {
        this.$scope.model = {
            TYPE_SALES_PERSON: null,
            SALES_PERSON: null,
            REQUIREMENT_LIST: null,
            NEW_SALES_PERSON: null,
            SELECT_ALL: null
        };
        this.$scope.steps = {
            current: 0,
            percent: 0
        };
    }

    private registerScopeFunctions(): void {
        this.$scope.getEmptySelectorMsg = () => {
            return this.formService.getEmptySelectorMsg();
        }

        this.$scope.getSalesPersonListByName = async (search: string) => {
            let salesPersonList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                salesPersonList = await this.getPhysicalPersonListByName({ specializations: [], roles: [], search: search });
            }
            this.$scope.salesPersonList = salesPersonList;
        }

        this.$scope.getSubstituteSalesPersonListByName = async (search: string) => {
            await this.$scope.getSalesPersonListByName(search);
            if (this.$scope.model.SALES_PERSON) this.$scope.salesPersonList = this.$scope.salesPersonList.filter(salesPerson => salesPerson.ID != this.$scope.model.SALES_PERSON.ID);
        }

        this.$scope.salesPersonChange = () => {
            this.$scope.model.NEW_SALES_PERSON = null;
            this.$scope.model.REQUIREMENT_LIST = null;
        }

        this.$scope.reset = (): void => {
            this.initModel();
        }

        this.$scope.next = async (): Promise<void> => {
            this.setTxtReplacedSeller();
            let goToNext = true;
            if (this.$scope.steps.current == 0) goToNext = await this.substituteStep();
            if (goToNext && this.$scope.steps.current < 3) {
                this.$scope.steps.current++;
            }
        }

        this.$scope.back = (): void => {
            if (this.$scope.steps.current > 0) {
                this.$scope.steps.current--;
            }
        }

        this.$scope.save = async (): Promise<void> => {
            this.save();
        }

        this.$scope.toggleCheckAll = async (): Promise<void> => {
            this.toggleCheckAll();
        }

        this.$scope.verifyChecked = (): void => {
            this.verifyChecked();
        }
    }

    private async substituteStep(): Promise<boolean> {
        let success = true;
        let requirementList: IRequirementList[] = [];
        if (this.$scope.model.TYPE_SALES_PERSON && this.$scope.model.SALES_PERSON) requirementList = await this.getRequirementListBySalesPerson({ idTypeSalesPerson: this.$scope.model.TYPE_SALES_PERSON.ID, idSalesPerson: parseInt(this.$scope.model.SALES_PERSON.ID) });
        this.$scope.model.REQUIREMENT_LIST = requirementList;
        if (!this.$scope.model.REQUIREMENT_LIST || this.$scope.model.REQUIREMENT_LIST && this.$scope.model.REQUIREMENT_LIST.length == 0) {
            this.formService.notifyError('No requirements were found for the selected seller.');
            success = false;
        }
        this.$scope.model.SELECT_ALL = !this.$scope.model.REQUIREMENT_LIST.some(item => item.SELECTED === false)
        return success;
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlDataManager = this.config.productUrl + baseRoute;
        return urlDataManager;
    }

    private async getPhysicalPersonListByName(physicalPersonFilter?: IPhysicalPersonListCustomFilter): Promise<IPhysicalPersonSelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const physicalPersons = await this.restService.newObjectPromise(`${this.baseUrl}/physicalPerson/list/custom`, physicalPersonFilter, 30000, false);
            if (physicalPersons) result = physicalPersons.map(physicalPerson => { return { ID: physicalPerson.ID, NAME: physicalPerson.NAME, CODE: physicalPerson.CODE, SECTOR: physicalPerson.SECTOR ? { ID: physicalPerson.SECTOR.ID, NAME: physicalPerson.SECTOR.NAME, CODE: physicalPerson.SECTOR.CODE } : null } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getRequirementListBySalesPerson(requirementListBySalesPersonFilter?: IRequirementListBySalesPersonFilter): Promise<IRequirementList[]> {
        let result = [];
        this.formService.block();
        try {
            const requirements = await this.restService.newObjectPromise(`${this.baseUrl}/account/list/requirement/salesPerson`, requirementListBySalesPersonFilter, 30000, false);
            if (requirements) result = requirements.map(requirement => { return { ID: requirement.ID, NAME: requirement.CONCATENATED, ACTIVE: requirement.ACTIVE, SELECTED: requirement.ACTIVE, ACCOUNT_NAME: requirement.ACCOUNT_NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }
 
    private toggleCheckAll(){
        this.$scope.model.REQUIREMENT_LIST.forEach(x => x.SELECTED = !this.$scope.model.SELECT_ALL);
    }

    private verifyChecked(){  
        this.$scope.model.SELECT_ALL =  !this.$scope.model.REQUIREMENT_LIST.some(item => item.SELECTED === false)
    }

    private async getSalesPersonTypeList(): Promise<SelectorModel[]> {
        const { data: salesPersonType } = await this.helperService.get(`/generic/value/bulk_change_sales_person_field_type`, null);
        return salesPersonType && salesPersonType.data ? salesPersonType.data : [];
    }

    private setTxtReplacedSeller(){
        if(this.$scope.model.TYPE_SALES_PERSON.ID == "1"){
            this.$scope.replacerSeller = "REGISTRATION.CONFIRM_REPLACED_SELLER_EXTERNAL";
        }else if(this.$scope.model.TYPE_SALES_PERSON.ID == "2"){
            this.$scope.replacerSeller = "REGISTRATION.CONFIRM_REPLACED_SELLER_INTERNAL";
        }

    }

    private async save(): Promise<void> {
        try {
            const requirementListToApply = this.$scope.model.REQUIREMENT_LIST ? this.$scope.model.REQUIREMENT_LIST.filter(requirement => requirement.SELECTED) : null;
            if (requirementListToApply && requirementListToApply.length > 0) {
                const updateData: IAccountRequirementUpdateSalesPersonRequest = { requirementIds: requirementListToApply.map(requirement => parseInt(requirement.ID)), idTypeSalesPerson: this.$scope.model.TYPE_SALES_PERSON ? this.$scope.model.TYPE_SALES_PERSON.ID : null, newSalesPerson: this.$scope.model.NEW_SALES_PERSON, salesPerson: this.$scope.model.SALES_PERSON };
                const request: IMonacoRequest = {
                    route: `${this.baseUrl}/account/requirement/salesPerson/update`,
                    timeout: 30000,
                    data: updateData
                };

                const rc = await this.restService.newObject(request.route, request.data, request.timeout, false);
                if (rc.status === 200) {
                    this.formService.notifySuccess('Requirements updated successfully.');
                    this.$scope.$applyAsync(() => this.initModel());
                }
            } else this.formService.notifyInfo('No requirements were selected.');
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }
}

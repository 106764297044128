export abstract class RouteConstants {

    //PROCESS ROUTER
    public static readonly PROCESS_TRANSLATE_TAGS = "/process/translateTags";
    public static readonly PROCESS_FOLLOWUP = "/process/followup";
    public static readonly PROCESS_NUMBER = "/process/byNumber";
    public static readonly PROCESS_MESSAGE_GROUP = "/process/messageGroups";
    public static readonly PROCESS_EXTERNAL_CONTACTS = "";

    public static readonly PROCESS_DOCUMENT_LIST = "/document/list";
    public static readonly PROCESS_DOCUMENT_UPDATE = "/document/update";
    public static readonly PROCESS_RECIPIENTS = "/process/recipients";

    //CELL
    public static readonly CELL_REMOVE = "/cell/remove";
    public static readonly CELL_LIST = "/cell/list";
    public static readonly CELL_ROLE_LIST = "/cell/role/list";
    public static readonly CELL_INSERT = "/cell/insert";


    //USER
    public static readonly USER_LIST = "/user/list";

    //FOLLOWUP
    public static readonly FOLLOWUP_PROCESS = "followup/process";
    public static readonly FOLLOWUP_MODEL_SEARCH = "/followup/model/search";
    public static readonly FOLLOWUP_MODEL_ID = "/followup/model/id";

    //CLIENT
    public static readonly CLIENT_LIST = "/client/list";

    //SLA DEADLINE
    public static readonly SLA_LIST = "/slaDeadline/list";
    public static readonly SLA_DEFAULT = "/slaDeadLine/default";
    public static readonly SLA_TOGGLE_STATUS = "/slaDeadline/toggleStatus";
    public static readonly SLA_VIEW_LOG = "/slaDeadline/viewLog";

    //STOPOVER
    public static readonly STOPOVER_LIST = "/stopOver/list";

    //DEADLINE
    public static readonly DEADLINE_LIST = "/deadline/list";
    public static readonly DEADLINE_UPDATE = "/deadline/update";
    public static readonly DEADLINE_LOG = "/deadline/log";

    public static readonly DEADLINE_PROCESS_LIST = "/deadline/process/list";
    public static readonly DEADLINE_PROCESS_UPDATE = "/deadline/process/update";
    public static readonly DEADLINE_PROCESS_LOG = "/deadline/process/log";
    public static readonly DEADLINE_PROCESS_REVIEWED = "/deadline/process/deadlineReviewed";
    public static readonly DEADLINE_PROCESS_RECALC = "/deadlineMasterHouse/process/recalc";
}

import * as angular from "angular";
import * as moment from 'moment';
import { IColumnDef, IGridOptions } from "ui-grid";
import { SSEService } from "@appServices/SSEService"
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { DataProductService } from "@services/DataProductService";
import { IModalService } from "@services/ModalService";
import { ISessionService } from "@services/SessionService";
import { IProviderSelector } from "@models/interface/product/ProviderModel";
import { IDocumentError } from "@models/interface/common/IDocumentError";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { EProviderTypeId, EDeadlinePreferenceType, EDeadlinePreferenceParameterization, EOperation } from "@enums/GenericData";
import { IFloatingMenu } from "../../common/interface/IFloatingMenu";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { SelectorModel } from "../../common/model/SelectorModel";
import { ILinkParameter } from "../../common/model/ModelParameter";
import { IContract, IContractExchangeData } from "../model/ContractModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { StringUtil } from "../../common/util/StringUtil";
import { ValidateUtil } from "../../common/util/ValidateUtil";
import { HelperService } from "@services/HelperService";

interface IResponseData {
    result: boolean;
    data: string;
}

interface IResponse {
    data: IResponseData;
    message: string;
    reason: string;
    status: number;
}

interface IContractScope extends IGridFormServiceScope {
    form: ng.IFormController;
    gridOptions: IGridOptions;
    model: IContract;
    scopeBeforeSave: IContract;
    log: IViewLog;
    menuFloating: IFloatingMenu;
    customLogProperties: ICustomLogProperties[];
    situationList: SelectorModel[];
    holderList: SelectorModel[];
    networkList: SelectorModel[];
    accountList: SelectorModel[];
    providerList: IProviderSelector[];
    agentList: SelectorModel[];
    processTypeList: SelectorModel[];
    productList: SelectorModel[];
    transactionList: SelectorModel[];
    typeCargoList: SelectorModel[];
    natureList: SelectorModel[];
    paymentTypeList: SelectorModel[];
    validityReferenceList: SelectorModel[];
    paymentMethodList: SelectorModel[];
    paymentConditionList: SelectorModel[];
    deadlineReferenceList: SelectorModel[];
    preferenceTypeList: SelectorModel[];
    preferenceList: SelectorModel[];
    preferenceParameterizationList: SelectorModel[];
    conversionReferenceList: SelectorModel[];
    conversionList: SelectorModel[];
    tradeLaneList: SelectorModel[];
    tradeGroupList: SelectorModel[];
    countryList: SelectorModel[];
    routingPointList: SelectorModel[];
    user: any;
    sessionService: ISessionService;
    editFinancialContract: (financialContract: IContract) => Promise<void>;
    viewFinancialContract: (financialContract: IContract) => Promise<void>;
    viewLogFinancialContract: (financialContract: IContract) => Promise<void>;
    copyFinancialContract: (financialContract: IContract) => Promise<void>;

    //data getters
    refreshHolders: (search: string) => Promise<void>;
    refreshNetworkListByName: (search: string) => Promise<void>;
    refreshTradeLaneListByName: (search: string) => Promise<void>;
    refreshCountryListByName: (search: string) => Promise<void>;
    refreshRoutingPointListByName: (search: string) => Promise<void>;
    refreshAccounts: (search: string) => Promise<void>;
    refreshServiceProviders: (search: string) => Promise<void>;
    refreshAgents: (search: string) => Promise<void>;

    // fields
    handleProductFields: (onChange?: boolean) => void;
    updateDeadlinePreference: () => void;
    updatePeriodVariation: () => void;
    refreshPaymentType: () => Boolean;
    refreshPaymentMethod: () => void;
    generateConcatenated: () => void;
    isOriginFieldDisabled: (field: SelectorModel[]) => boolean;
    isDestinationFieldDisabled: (field: SelectorModel[]) => boolean;
    validTransaction: () => void;

    // gotos
    goToLegalPerson: () => void;
    goToNetwork: () => void;
    goToProvider: (id?: number) => void;
    goToAgent: (id?: number) => void;
    goToAccount: (id?: number) => void;
    goToTradeLane: (ids: string) => void;
    goToRoutingPoint: (ids: string) => void;
    goToCountry: (ids: string) => void;

    getGridViewProvider: (provider: IProviderSelector) => string;
    getGridViewOriginDestination: (trade: SelectorModel[], tradeLane: SelectorModel[], country: SelectorModel[], routingPoint: SelectorModel[]) => string;
    formatConcatenatedChars: (value: string) => string;
    collapseHeader: (elementId: string, state?: string) => void;
    multipleProductSelection: () => void;
    initPanels: () => void;
    checkDateValidity: (initialDate: Date, finalDate: Date) => void;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
    getPreferenceParameterizationList: () => SelectorModel[];
    getNextMonthPreference: () => boolean;
}

export class ContractRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IContractScope;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private RestService: IRestService;
    private ModalService: IModalService;
    private collapseState: string;
    private selectedProducts: string[];
    private modalID: number;
    private dataProductService: DataProductService;
    private sessionService: ISessionService;
    private SSEService: SSEService;
    private gridName: string;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IContractScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.RestService = $injector.get('RestService');
        this.dataProductService = $injector.get('DataProductService');
        this.ModalService = $injector.get('ModalService');
        this.collapseState = "hide";
        this.selectedProducts = [];
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.gridName = "contract"
        this.helperService = $injector.get('HelperService'); 
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'contract', 'GENERAL.MENU.FINANCIAL_CONTRACT', true);
            await this.initGrid('contract', '/contract/list', true, true, null, true, true);
            this.$gridService.$customExportData = this.customExportData.bind(this);
            const sessionParameter = this.$gridService.$sessionParameter;
            if (sessionParameter && sessionParameter.data) this.callSessionFunctions(sessionParameter.data);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initGridColumns(columns: string[]): IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewFinancialContract(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editFinancialContract(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyFinancialContract(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogFinancialContract(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 130,
            maxWidth: 130,
            cellTemplate: (view + edit + copy + viewLog + modalIntegration),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colSituation: IMonacoColumnDef = {
                name: 'SITUATION.NAME',
                displayName: 'GENERAL.SITUATION',
                width: 200,
            };
            const colContractNumber: IMonacoColumnDef = {
                name: 'CONTRACT_NUMBER',
                displayName: 'OPERATIONAL.FINANCIAL_CONTRACT',
                width: 200,
            };
            const colConcatenated: IMonacoColumnDef = {
                name: 'CONCATENATED',
                displayName: 'GENERAL.CONCATENATED',
                width: 225,
            };
            const colActive: IMonacoColumnDef = {
                name: 'ACTIVE',
                displayName: 'GENERAL.ACTIVE',
                width: 80,
                cellFilter: "YesOrNo",
            };
            const colCargoType: IMonacoColumnDef = {
                name: 'TYPE_CARGO.NAME',
                displayName: 'Tipo de Carga',
                width: 80,
                cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.TYPE_CARGO, null, "NAME")}}</div>',
                filter: { condition: this.$gridService.filterSelectObject },

            };
            const colHolder: IMonacoColumnDef = {
                name: 'HOLDER.NAME',
                displayName: 'GENERAL.HOLDER',
                width: 220,
                cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.HOLDER, null, "NAME")}}</div>',
                filter: { condition: this.$gridService.filterSelectObject },
            };
            const colNetwork: IMonacoColumnDef = {
                name: 'NETWORK.NAME',
                displayName: 'BASIC_DATA.NETWORK',
                width: 220,
                cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.NETWORK, null, "NAME")}}</div>',
                filter: { condition: this.$gridService.filterSelectObject },
            };
            const colProduct: IMonacoColumnDef = {
                name: 'PRODUCT.NAME',
                displayName: 'BASIC_DATA.PRODUCT',
                cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.PRODUCT, null, "NAME")}}</div>',
                width: 210,
                filter: { condition: this.$gridService.filterSelectObject },
            };
            const colPaymentType: IMonacoColumnDef = {
                name: 'PAYMENT_TYPE.NAME',
                displayName: 'FINANCIAL.CHARGE_PAYMENT_METHOD',
                width: 200,
                cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.PAYMENT_TYPE, null, "NAME")}}</div>',
                filter: { condition: this.$gridService.filterSelectObject },
            };
            const colNature: IMonacoColumnDef = {
                name: 'PAYMENT_NATURE.NAME',
                displayName: 'BASIC_DATA.PAYMENT_NATURE',
                width: 200,
            };
            const colTransaction: IMonacoColumnDef = {
                name: 'TRANSACTION.NAME',
                displayName: 'GENERAL.HOLDER_TYPE',
                width: 200,
                cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.TRANSACTION)}}</div>',
                filter: { condition: this.$gridService.filterSelectObject },
            };
            const colProvider: IMonacoColumnDef = {
                name: 'PROVIDER.NAME',
                displayName: 'BASIC_DATA.PROVIDER',
                width: 220,
                cellTemplate: '<div class="grid-padding" >{{grid.appScope.getGridViewProvider(row.entity.PROVIDER)}}</div>'
            };
            const colAccount: IMonacoColumnDef = {
                name: 'ACCOUNT.NAME',
                displayName: 'BASIC_DATA.CLIENT',
                width: 220,
            };
            const colAgent: IMonacoColumnDef = {
                name: 'LEGAL_PERSON_AGENT.NAME',
                displayName: 'BASIC_DATA.AGENT',
                width: 220,
            };
            const colProcessType: IMonacoColumnDef = {
                name: 'PROCESS_TYPE.NAME',
                displayName: 'FINANCIAL.PRODUCT_SERVICE_TYPE',
                cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PROCESS_TYPE)}}</div>',
                width: 200,
                filter: { condition: this.$gridService.filterSelectObject },
            };
            const colOrigin: IMonacoColumnDef = {
                name: 'ORIGIN',
                displayName: 'BASIC_DATA.ORIGIN',
                cellTemplate: '<div class="grid-padding" >{{grid.appScope.getGridViewOriginDestination(row.entity.TRADE_ORIGIN, row.entity.TRADE_LANE_ORIGIN, row.entity.COUNTRY_ORIGIN, row.entity.ROUTING_POINT_ORIGIN)}}</div>',
                filter: { condition: this.$gridService.filterSelectObject },
                width: 220,
            };
            const colDestination: IMonacoColumnDef = {
                name: 'DESTINATION',
                displayName: 'BASIC_DATA.DESTINATION',
                cellTemplate: '<div class="grid-padding" >{{grid.appScope.getGridViewOriginDestination(row.entity.TRADE_DESTINATION, row.entity.TRADE_LANE_DESTINATION, row.entity.COUNTRY_DESTINATION, row.entity.ROUTING_POINT_DESTINATION)}}</div>',
                filter: { condition: this.$gridService.filterSelectObject },
                width: 220,
            };
            const colValidityReference: IMonacoColumnDef = {
                name: 'VALIDITY_REFERENCE.NAME',
                displayName: 'GENERAL.VALIDITY_BASIS',
                width: 200,
            };
            const colValidityStart: IMonacoColumnDef = {
                name: 'VALIDITY_START',
                displayName: 'PRODUCT.VALIDITY_START',
                cellFilter: 'date:\'dd/MM/yyyy\': \'UTC\'',
                width: 200,
            };
            const colValidityEnd: IMonacoColumnDef = {
                name: 'VALIDITY_END',
                displayName: 'PRODUCT.VALIDITY_END',
                cellFilter: 'date:\'dd/MM/yyyy\': \'UTC\'',
                width: 200,
            };
            const colPaymentMethod: IMonacoColumnDef = {
                name: 'PAYMENT_METHOD.NAME',
                displayName: 'FINANCIAL.PAYMENT_MEANS',
                width: 200,
            };
            const colPaymentCondition: IMonacoColumnDef = {
                name: 'PAYMENT_CONDITION.NAME',
                displayName: 'GENERAL.INVOICE_PAYMENT_TERM',
                width: 200,
            };
            const colDeadlineReference: IMonacoColumnDef = {
                name: 'DEADLINE_REFERENCE.NAME',
                displayName: 'FINANCIAL.PAYMENT_TERMS_REFERENCE',
                width: 200,
            };
            const colDeadline: IMonacoColumnDef = {
                name: 'DEADLINE_D0',
                displayName: 'FINANCIAL.PAYMENT_TERM_DAYS',
                width: 200,
            };
            const colPreferenceType: IMonacoColumnDef = {
                name: 'PREFERENCE_TYPE.NAME',
                displayName: 'FINANCIAL.PREFERENCE_TYPE',
                width: 200,
            };
            const colPreference: IMonacoColumnDef = {
                name: 'PREFERENCE.NAME',
                displayName: 'FINANCIAL.PREFERENCE',
                cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PREFERENCE)}}</div>',
                filter: { condition: this.$gridService.filterSelectObject },
                width: 200,
            };
            const colPreferenceParametrization: IMonacoColumnDef = {
                name: 'PREFERENCE_PARAMETERIZATION.NAME',
                displayName: 'FINANCIAL.PREFERENCE_PARAMETERS',
                width: 200,
            };
            const colConversionReference: IMonacoColumnDef = {
                name: 'CONVERSION_REFERENCE.NAME',
                displayName: 'REGISTRATION.EXC_RATE_REFERENCE_DATE',
                width: 200,
            };
            const colCurrencyConversion: IMonacoColumnDef = {
                name: 'CURRENCY_CONVERSION.NAME',
                displayName: 'GENERAL.EXCHANGE_RATE_INDEX',
                width: 200,
            };
            const colBase: IMonacoColumnDef = {
                name: 'BASE',
                displayName: 'FINANCIAL.BASE',
                width: 200,
            };
            const colSpread: IMonacoColumnDef = {
                name: 'SPREAD',
                displayName: 'FINANCIAL.SPREAD',
                cellTemplate: '<div class="grid-padding" >{{row.entity.SPREAD}}%</div>',
                width: 200,
            };
            const colPeriosVariation: IMonacoColumnDef = {
                name: 'PERIOD_VARIATION.NAME',
                displayName: 'REGISTRATION.PERIOD_VARIATION',
                width: 200,
            };
            const colAnticipatedInvoice: IMonacoColumnDef = {
                name: 'ANTICIPATED_INVOICE.NAME',
                displayName: 'REGISTRATION.EARLY_RECEIPT',
                width: 200,
            };
            const colPaymentCompromise: IMonacoColumnDef = {
                name: 'PAYMENT_COMPROMISE.NAME',
                displayName: 'FINANCIAL.PAYMENT_AGREEMENT',
                width: 200,
            };
            const colObservation: IMonacoColumnDef = {
                name: 'OBSERVATION',
                displayName: 'GENERAL.REMARKS',
                width: 200,
            };
            const colId: IMonacoColumnDef = {
                name: "ID",
                displayName: "ID",
                width: 80
            };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 120, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 120, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'SITUATION':
                        columnDefs.push(colSituation);
                        break;
                    case 'CONTRACT_NUMBER':
                        columnDefs.push(colContractNumber);
                        break;
                    case 'CONCATENATED':
                        columnDefs.push(colConcatenated);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'TYPE_CARGO':
                        columnDefs.push(colCargoType);
                        break;
                    case 'HOLDER':
                        columnDefs.push(colHolder);
                        break;
                    case 'NETWORK':
                        columnDefs.push(colNetwork);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'PAYMENT_TYPE':
                        columnDefs.push(colPaymentType);
                        break;
                    case 'PAYMENT_NATURE':
                        columnDefs.push(colNature);
                        break;
                    case 'TRANSACTION':
                        columnDefs.push(colTransaction);
                        break;
                    case 'PROVIDER':
                        columnDefs.push(colProvider);
                        break;
                    case 'ACCOUNT':
                        columnDefs.push(colAccount);
                        break;
                    case 'LEGAL_PERSON_AGENT':
                        columnDefs.push(colAgent);
                        break;
                    case 'PROCESS_TYPE':
                        columnDefs.push(colProcessType);
                        break;
                    case 'ORIGIN':
                        columnDefs.push(colOrigin);
                        break;
                    case 'DESTINATION':
                        columnDefs.push(colDestination);
                        break;
                    case 'VALIDITY_REFERENCE':
                        columnDefs.push(colValidityReference);
                        break;
                    case 'VALIDITY_START':
                        columnDefs.push(colValidityStart);
                        break;
                    case 'VALIDITY_END':
                        columnDefs.push(colValidityEnd);
                        break;
                    case 'PAYMENT_METHOD':
                        columnDefs.push(colPaymentMethod);
                        break;
                    case 'PAYMENT_CONDITION':
                        columnDefs.push(colPaymentCondition);
                        break;
                    case 'DEADLINE_REFERENCE':
                        columnDefs.push(colDeadlineReference);
                        break;
                    case 'DEADLINE_D0':
                        columnDefs.push(colDeadline);
                        break;
                    case 'PREFERENCE_TYPE':
                        columnDefs.push(colPreferenceType);
                        break;
                    case 'PREFERENCE':
                        columnDefs.push(colPreference);
                        break;
                    case 'PREFERENCE_PARAMETERIZATION':
                        columnDefs.push(colPreferenceParametrization);
                        break;
                    case 'CONVERSION_REFERENCE':
                        columnDefs.push(colConversionReference);
                        break;
                    case 'CURRENCY_CONVERSION':
                        columnDefs.push(colCurrencyConversion);
                        break;
                    case 'BASE':
                        columnDefs.push(colBase);
                        break;
                    case 'SPREAD':
                        columnDefs.push(colSpread);
                        break;
                    case 'PERIOD_VARIATION':
                        columnDefs.push(colPeriosVariation);
                        break;
                    case 'ANTICIPATED_INVOICE':
                        columnDefs.push(colAnticipatedInvoice);
                        break;
                    case 'PAYMENT_COMPROMISE':
                        columnDefs.push(colPaymentCompromise);
                        break;
                    case 'OBSERVATION':
                        columnDefs.push(colObservation);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                }
            }
            return columnDefs;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ACTIVE: true,
            ID: null,
            CONTRACT_NUMBER: null,
            CONCATENATED: null,
            CONCATENATED_COMPLEMENT: null,
            HOLDER: null,
            TRANSACTION: null,
            PAYMENT_NATURE: null,
            PRODUCT: null,
            PAYMENT_TYPE: null,
            NETWORK: null,
            PROVIDER: null,
            ID_PROVIDER: null,
            ACCOUNT: null,
            ID_ACCOUNT: null,
            TRADE_LANE_ORIGIN: null,
            TRADE_ORIGIN: null,
            COUNTRY_ORIGIN: null,
            COUNTRY_DESTINATION: null,
            ROUTING_POINT_ORIGIN: null,
            ROUTING_POINT_DESTINATION: null,
            TRADE_LANE_DESTINATION: null,
            TRADE_DESTINATION: null,
            LEGAL_PERSON_AGENT: null,
            ID_LEGAL_PERSON_AGENT: null,
            PROCESS_TYPE: null,
            VALIDITY_REFERENCE: null,
            VALIDITY_START: null,
            DISPLAY_VALIDITY_START: null,
            VALIDITY_END: null,
            DISPLAY_VALIDITY_END: null,
            EXPIRED: null,
            SITUATION: null,
            PAYMENT_CONDITION: null,
            DEADLINE_REFERENCE: null,
            DEADLINE_D0: null,
            PREFERENCE_TYPE: null,
            PREFERENCE: null,
            PREFERENCE_PARAMETERIZATION: null,
            PAYMENT_METHOD: null,
            CONVERSION_REFERENCE: null,
            CURRENCY_CONVERSION: null,
            BASE: null,
            SPREAD: null,
            PERIOD_VARIATION: null,
            ANTICIPATED_INVOICE: null,
            PAYMENT_COMPROMISE: null,
            OBSERVATION: null,
            SCORE: null,
            SCORE_DATE: null,
            SCORE_ERROR: null,
            SCORE_RULE: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            DOCUMENT_ERROR: null,
            COMBINED_FIELDS_SEARCH: null,
            TYPE_CARGO: null,
            TRADE_GROUP_ORIGIN_REPL: null,
            TRADE_GROUP_DESTINATION_REPL: null,
        };
    }

    async saveSuccess(returnedData: IResponse): Promise<void> {
        if (returnedData && returnedData.data && returnedData.data.result === false && returnedData.data.data) {
            this.formService.notifyInfo(returnedData.data.data);
        }
    }
    getPreferenceParameterizationList(): SelectorModel[] {
        if (this.$scope.preferenceParameterizationList && this.$scope.preferenceParameterizationList.length > 0) {
            const list = this.$scope.preferenceParameterizationList

            if (this.$scope.model.PREFERENCE_TYPE.ID != EDeadlinePreferenceType.MONTHDAY) return list.filter(item => item.ID != EDeadlinePreferenceParameterization.NEXT_MONTH)
            return list
        }
    }

    initDependencies(): Promise<any> {
        const self: ContractRegisterController = this;

        this.initDateFields();

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericList('registration_situation'),
                self.getGenericList('financial_contract_type'),
                self.getGenericList('product'),
                self.getGenericList('transaction'),
                self.getGenericList('payment_nature'),
                self.getGenericList('type_payment'),
                self.getGenericList('deadline_reference'),
                self.getGenericList('payment_method'),
                self.getGenericList('payment_condition'),
                self.getGenericList('deadline_preference_type'),
                self.getGenericList('deadline_preference_parameterization'),
                self.getGenericList('conversion_reference'),
                self.getGenericList('exchange_currency_conversion'),
                self.getGenericList('locals_group'),
                self.getGenericList('type_cargo')
            ]).then((result: any) => {
                self.$scope.situationList = result[0];
                self.$scope.processTypeList = result[1];
                self.$scope.productList = result[2];
                self.$scope.transactionList = result[3];
                self.$scope.natureList = result[4];
                self.$scope.paymentTypeList = result[5];
                self.$scope.validityReferenceList = result[6];
                self.$scope.deadlineReferenceList = result[6];
                self.$scope.paymentMethodList = result[7];
                self.$scope.paymentConditionList = result[8];
                self.$scope.preferenceTypeList = result[9];
                self.$scope.preferenceParameterizationList = result[10];
                self.$scope.conversionReferenceList = result[11];
                self.$scope.conversionList = result[12];
                self.$scope.tradeGroupList = result[13];
                self.$scope.typeCargoList = result[14];

                if (self.$scope.situationList && self.$scope.situationList.length > 0 && !self.$scope.model.SITUATION) {
                    self.$scope.model.SITUATION = self.$scope.situationList.find(item => item.ID = '1');
                }

                if (self.$scope.transactionList && self.$scope.transactionList.length > 0) {
                    // Sort array by name;
                    self.$scope.transactionList.sort((a, b) => a.NAME !== b.NAME ? a.NAME < b.NAME ? -1 : 1 : 0);
                }

                self.updateDeadlinePreference(true);
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    initScopeFunctions(): void {
        this.$scope.editFinancialContract = async (financialContract: IContract): Promise<void> => {
            let blockedObject = {
                ID: financialContract.ID,
                NAME: financialContract.CONCATENATED,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || financialContract.ID !== this.$scope.model.ID) this.$scope.view(financialContract);
                } else if (this.$scope.operation !== EOperation.EDIT || financialContract.ID !== this.$scope.model.ID) {
                    this.$scope.edit(financialContract);
                }
            };
        }

        this.$scope.viewFinancialContract = async (financialContract: IContract): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(financialContract);
        }

        this.$scope.viewLogFinancialContract = async (financialContract: IContract): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(financialContract);
        }

        this.$scope.copyFinancialContract = async (financialContract: IContract): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(financialContract);
        }

        this.$scope.initPanels = (): void => {
            this.initPanels();
        }

        this.$scope.collapseHeader = (elementId: string, state?: string): void => {
            this.collapseHeader(elementId, state);
        }

        this.$scope.multipleProductSelection = (): void => {
            this.multipleProductSelection();
        }

        this.$scope.getGridViewProvider = (provider: IProviderSelector): string => {
            let gridValue = "";
            if (provider && provider.TYPE) {
                if (provider.TYPE.ID == EProviderTypeId.SHIPOWNER || provider.TYPE.ID == EProviderTypeId.AIRLINE) gridValue = provider.CODE;
                else if (provider.TYPE.ID == EProviderTypeId.SHIPPING_COMPANY) gridValue = provider.NAME;
            }
            return gridValue;
        }

        this.$scope.getGridViewOriginDestination = (trade: SelectorModel[], tradeLane: SelectorModel[], country: SelectorModel[], routingPoint: SelectorModel[]) => {
            let gridValue = "";

            if (trade && trade.length) gridValue = gridValue.concat(this.$scope.getCONCAT(trade, null, "NAME"));
            if (tradeLane && tradeLane.length) gridValue = gridValue.concat(this.$scope.getCONCAT(tradeLane, null, "CODE"));
            if (country && country.length) gridValue = gridValue.concat(this.$scope.getCONCAT(country, null, "CODE"));
            if (routingPoint && routingPoint.length) gridValue = gridValue.concat(this.$scope.getCONCAT(routingPoint, null, "CODE"));

            return gridValue;
        }

        this.$scope.formatConcatenatedChars = (value: string) => {
            return StringUtil.formatConcatenatedChars(value);
        }

        // selectors
        this.$scope.refreshHolders = async (search: string): Promise<void> => {
            let holders: SelectorModel[] = [];
            if (search && search.length >= 3) {
                holders = await this.getLegalPersonListByName(search);
                this.$scope.holderList = holders;
            }
        }

        this.$scope.refreshAccounts = async (search: string): Promise<void> => {
            const accounts = await this.getAccountListByName(search);
            if (accounts) this.$scope.accountList = accounts;
        }

        this.$scope.refreshServiceProviders = async (search: string): Promise<void> => {
            await this.getProviderListByName(search);
        }

        this.$scope.refreshAgents = async (search: string): Promise<void> => {
            await this.getAgentByName(search);
        }

        this.$scope.refreshNetworkListByName = async (search: string): Promise<void> => {
            await this.refreshNetworkListByName(search);
        }

        this.$scope.refreshTradeLaneListByName = async (search: string): Promise<void> => {
            await this.refreshTradeLaneListByName(search);
        }

        this.$scope.refreshCountryListByName = async (search: string): Promise<void> => {
            await this.refreshCountryListByName(search);
        }

        this.$scope.refreshRoutingPointListByName = async (search: string): Promise<void> => {
            await this.refreshRoutingPointListByName(search);
        }

        this.$scope.handleProductFields = (onChange?: boolean): void => {
            this.handleProductFields(onChange);
        }

        this.$scope.updateDeadlinePreference = (): void => {
            this.updateDeadlinePreference();
        }

        this.$scope.updatePeriodVariation = (): void => {
            this.updatePeriodVariation();
        }

        this.$scope.refreshPaymentType = (): Boolean => {
            return this.refreshPaymentType();
        }

        this.$scope.refreshPaymentMethod = (): void => {
            this.refreshPaymentMethod();
        }

        this.$scope.generateConcatenated = (): void => {
            this.generateConcatenated();
        }

        this.$scope.validTransaction = () => {
            const model = this.$scope.model;
            if (model && (!model.HOLDER && !model.NETWORK)) model.TRANSACTION = null;
        }

        this.$scope.isOriginFieldDisabled = (field: SelectorModel[]): boolean => {
            let disabled = true;
            if (this.$scope.model && (this.isEmptyArray(field) && this.isEmptyArray(this.$scope.model.TRADE_ORIGIN) && this.isEmptyArray(this.$scope.model.TRADE_LANE_ORIGIN) && this.isEmptyArray(this.$scope.model.COUNTRY_ORIGIN) && this.isEmptyArray(this.$scope.model.ROUTING_POINT_ORIGIN))
                || (!this.isEmptyArray(field) && (angular.equals(field, this.$scope.model.TRADE_ORIGIN) || angular.equals(field, this.$scope.model.TRADE_LANE_ORIGIN) || angular.equals(field, this.$scope.model.COUNTRY_ORIGIN) || angular.equals(field, this.$scope.model.ROUTING_POINT_ORIGIN)))) disabled = false;
            return disabled;
        }

        this.$scope.isDestinationFieldDisabled = (field: SelectorModel[]): boolean => {
            let disabled = true;
            if (this.$scope.model && (this.isEmptyArray(field) && this.isEmptyArray(this.$scope.model.TRADE_DESTINATION) && this.isEmptyArray(this.$scope.model.TRADE_LANE_DESTINATION) && this.isEmptyArray(this.$scope.model.COUNTRY_DESTINATION) && this.isEmptyArray(this.$scope.model.ROUTING_POINT_DESTINATION))
                || (!this.isEmptyArray(field) && (angular.equals(field, this.$scope.model.TRADE_DESTINATION) || angular.equals(field, this.$scope.model.TRADE_LANE_DESTINATION) || angular.equals(field, this.$scope.model.COUNTRY_DESTINATION) || angular.equals(field, this.$scope.model.ROUTING_POINT_DESTINATION)))) disabled = false;
            return disabled;
        }

        // gotos
        this.$scope.goToLegalPerson = (): void => {
            this.sessionService.openTab("app.registration.legalPerson", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.HOLDER, null, 'ID') });
        }

        this.$scope.goToNetwork = (): void => {
            this.sessionService.openTab("app.registration.network", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.NETWORK, null, 'ID') });
        }

        this.$scope.goToProvider = (id?: number): void => {
            this.sessionService.openTab("app.registration.provider", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToAgent = (id: number): void => {
            this.sessionService.openTab("app.registration.agent", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToAccount = (id?: number): void => {
            this.sessionService.openTab("app.commercial.account", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToTradeLane = (ids: string): void => {
            this.sessionService.openTab("app.registration.tradelane", <ILinkParameter>{ ID: ids });
        }

        this.$scope.goToRoutingPoint = (ids: string): void => {
            this.sessionService.openTab("app.registration.routingPoint", <ILinkParameter>{ ID: ids });
        }

        this.$scope.goToCountry = (ids: string): void => {
            this.sessionService.openTab("app.registration.country", <ILinkParameter>{ ID: ids });
        }

        this.$scope.checkDateValidity = (initialDate: Date, finalDate: Date): void => {
            this.checkDateValidity(initialDate, finalDate);
        }

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }

        this.$scope.getPreferenceParameterizationList = (): SelectorModel[] => {
            return this.getPreferenceParameterizationList()
        }

        this.$scope.getNextMonthPreference = (): boolean => {
            return this.getNextMonthPreference();
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.initPanels();
            BrowserTitle.$id = null;
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.formOperation = this.$scope.formOperation;
                this.$scope.menuFloating.infos = [{ text: "OPERATIONAL.FINANCIAL_CONTRACT", class: "text-rouge font-bold" }];
            }
            await this.$scope.$applyAsync(() => {
                this.$scope.handleProductFields();
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.disableElements(true);
            this.$scope.initPanels();
            BrowserTitle.$id = `${this.$scope.model.CONTRACT_NUMBER} - ${this.$scope.model.CONCATENATED}`;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [{ text: `${this.$scope.model.CONTRACT_NUMBER} - ${this.$scope.model.CONCATENATED}`, class: "text-rouge font-bold" }];
            }
            await this.$scope.$applyAsync(() => {
                this.formatDateStrings();
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.initPanels();
            BrowserTitle.$id = `${this.$scope.model.CONTRACT_NUMBER} - ${this.$scope.model.CONCATENATED}`;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [{ text: `${this.$scope.model.CONTRACT_NUMBER} - ${this.$scope.model.CONCATENATED}`, class: "text-rouge font-bold" }];
            }
            await this.$scope.$applyAsync(() => {
                this.formatDateStrings();
                this.$scope.handleProductFields();
            });
            this.checkPaymentMethod();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            await this.clearFields(this.$scope.model, ['CONCATENATED']);
            this.$scope.initPanels();
            BrowserTitle.$id = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.$scope.scopeBeforeSave = null;
            await this.$scope.$applyAsync(() => {
                this.formatDateStrings();
                this.$scope.handleProductFields();
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        try {
            const route = this.$scope.operation == 'register' ? 'insert' : 'update';
            return {
                route: `/contract/${route}`,
                data: angular.copy(this.$scope.model),
                oldData: angular.copy(this.$scope.scopeBeforeSave),
                timeout: 15000
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private callSessionFunctions(data: object): void {
        if (!data) return;
        const contractExchangeData = <IContractExchangeData>data;
        let contractModel: IContract = null;
        for (const gridData of this.$scope.gridOptions.data) {
            if (gridData.ID == contractExchangeData.ID) {
                contractModel = gridData;
                break;
            }
        }
        switch (contractExchangeData.OPERATION) {
            case "view": this.$scope.view(contractModel);
                break;
        }
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlDataManager = this.config.productUrl + baseRoute;
        return urlDataManager;
    }

    // abstractable html stuff
    private getMenuFloatingDefault(): IFloatingMenu {
        return {
            infos: [
                {
                    text: "OPERATIONAL.FINANCIAL_CONTRACT",
                    class: "text-rouge font-bold"
                }
            ],
            options: [
                {
                    click: "collapseHeader",
                    args: [
                        "collapseBasicData"
                    ],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.BASIC_DATA",
                    iconClass: "fa fa-address-card",
                    iconBodyClass: "text-brown"
                },
                {
                    click: "collapseHeader",
                    args: [
                        "collapseDeadline"
                    ],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "FINANCIAL.CREDIT_TERMS",
                    iconClass: "fa fa-clock-o",
                    iconBodyClass: "text-allog"
                },
                {
                    click: "collapseHeader",
                    args: [
                        "collapseExchange"
                    ],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "BASIC_DATA.EXCHANGE",
                    iconClass: "fa fa-usd",
                    iconBodyClass: "text-green"
                },
                {
                    click: "collapseHeader",
                    args: [
                        "collapseOthers"
                    ],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "FINANCIAL.OTHERS",
                    iconClass: "fa fa-ellipsis-h",
                    iconBodyClass: "text-bronze"
                },
                {
                    click: "collapseHeader",
                    args: [
                        "collapseObs"
                    ],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.REMARKS",
                    iconClass: "fa fa-bullhorn",
                    iconBodyClass: "text-yellow"
                },
                {
                    click: "collapseHeader",
                    args: [
                        "collapseAll"
                    ],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.COLLAPSE_EXPAND_ALL",
                    iconClass: "fa fa-expand",
                    iconBodyClass: "text-danger"
                }
            ],
            btnActiveDisabled: false
        }
    }

    private collapseHeader(elementId: string, state?: string): void {
        if (elementId === "collapseAll" || elementId[0] === "collapseAll") {
            this.collapseState = this.collapseState == "hide" ? "show" : "hide";
            $('.toggle-me')["collapse"](state ? state : this.collapseState);
        } else if (elementId != "registerBody") {
            $("#" + elementId)["collapse"](state ? state : 'toggle');
        }
        this.$scope.navigateBetweenIds(elementId);
    }

    private multipleProductSelection = (): void => {
        const selectedProduct = this.$scope.model ? this.$scope.model.PRODUCT : null;

        if (selectedProduct) {
            const productfilter = {
                isAir: false,
                isMaritime: false,
                isRoad: false,
            }

            selectedProduct.map((item) => {
                if (item.ID == "EA" || item.ID == "IA") return productfilter.isAir = true;
                if (item.ID == "EM" || item.ID == "IM") return productfilter.isMaritime = true;
                if (item.ID == "ER" || item.ID == "IR") return productfilter.isRoad = true;
            })

            let count = 0;
            if (productfilter.isAir) count++;
            if (productfilter.isMaritime) count++;
            if (productfilter.isRoad) count++;

            const isActive = count > 1 ? true : false;

            this.isOriginDestinationDisabled(isActive);
        }
    }

    // date to string formatting case
    private formatDateStrings(): void {
        try {
            const validityUp = this.$scope.model.DISPLAY_VALIDITY_END;
            const validityOf = this.$scope.model.DISPLAY_VALIDITY_START;
            if (!validityOf || !validityUp) return;
            this.$scope.model.DISPLAY_VALIDITY_END = moment(validityUp).toDate();
            this.$scope.model.DISPLAY_VALIDITY_START = moment(validityOf).toDate();
        } catch (ex) {
            throw ex;
        }
    }

    private getNextMonthPreference(): boolean {
        if (this.$scope.model && this.$scope.model.PREFERENCE && this.$scope.model.PREFERENCE.length > 0) {

            for (let item = 0; item < this.$scope.model.PREFERENCE.length; item++) {
                if ((this.$scope.model.PREFERENCE[item].ID == "31" || this.$scope.model.PREFERENCE[item].ID == "30" || this.$scope.model.PREFERENCE[item].ID == "29")
                    && this.$scope.model.PREFERENCE_PARAMETERIZATION.ID == EDeadlinePreferenceParameterization.NEXT_MONTH) {
                    return true
                }
            }
        }
        return false;
    }

    private initPanels(): void {
        try {
            const panels = document.getElementsByClassName("toggle-me");
            if (panels && panels.length > 0) {
                for (let i = 0; i < panels.length; i++) {
                    const panel = panels[i];
                    if (panel.id === "collapseBasicData" || panel.id === "collapseDirect") {
                        if (!panel.classList.contains('in')) $("#" + panel.id)["collapse"]("show");
                    } else if (panel.classList.contains('in')) {
                        $("#" + panel.id)["collapse"]("hide");
                    }
                }
            }
            this.$scope.navigateBetweenIds('collapseBasicData');
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private handleProductFields(onChange?: boolean): void {
        try {
            this.$scope.providerList = [];

            const isEmptyBefore = !this.selectedProducts || this.selectedProducts.length == 0;
            const isAirBefore = this.selectedProducts && this.selectedProducts.length && this.selectedProducts.some(product => product == "EA" || product == "IA");
            const isMaritimeBefore = this.selectedProducts && this.selectedProducts.length && this.selectedProducts.some(product => product == "EM" || product == "IM");
            const isRoadBefore = this.selectedProducts && this.selectedProducts.length && this.selectedProducts.some(product => product == "ER" || product == "IR");

            let disableMutipleModalRelatedFields = false;
            let disableMutipleOperationRelatedFields = false;

            const modelProducts = this.$scope.model.PRODUCT;
            if (!modelProducts || modelProducts.length === 0) {
                disableMutipleModalRelatedFields = true;
                disableMutipleOperationRelatedFields = true;
            }
            if (!disableMutipleModalRelatedFields || !disableMutipleOperationRelatedFields) {
                const allProducts = modelProducts.map(x => `${x.NAME.toUpperCase().split(' ')[0].substr(0, 1)}${x.NAME.toUpperCase().split(' ')[1].substr(0, 1)}`);
                const allOperations = modelProducts.map(x => x.NAME.toUpperCase().split(' ')[0].substr(0, 1));
                const allModals = modelProducts.map(x => x.NAME.toUpperCase().split(' ')[1].substr(0, 1));
                const operations = allOperations.filter((x, index, self) => x && index === self.findIndex(t => t === x));
                const modals = allModals.filter((x, index, self) => x && index === self.findIndex(t => t === x));
                this.selectedProducts = allProducts;

                if (onChange) {
                    const isAir = this.selectedProducts && this.selectedProducts.length && this.selectedProducts.some(product => product == "EA" || product == "IA");
                    const isMaritime = this.selectedProducts && this.selectedProducts.length && this.selectedProducts.some(product => product == "EM" || product == "IM");
                    const isRoad = this.selectedProducts && this.selectedProducts.length && this.selectedProducts.some(product => product == "ER" || product == "IR");

                    if (!isEmptyBefore &&
                        (isAir && !isAirBefore) ||
                        (isMaritime && !isMaritimeBefore) ||
                        (isRoad && !isRoadBefore)) {
                        this.$scope.model.ROUTING_POINT_ORIGIN = null;
                        this.$scope.model.ROUTING_POINT_DESTINATION = null;
                    }
                }
                if (modals.length > 1) disableMutipleModalRelatedFields = true;
                if (operations.length > 1) disableMutipleOperationRelatedFields = true;
            }
            this.$scope.disableElements(disableMutipleOperationRelatedFields, null, ['freightcontract']);
            this.$scope.disableElements(disableMutipleModalRelatedFields, null, new RegExp('^(origin|destination).*$', 'i'));
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private updateDeadlinePreference(startValue: boolean = false): void {
        try {
            if (!startValue) {
                this.$scope.model.PREFERENCE = null;
                this.$scope.model.PREFERENCE_PARAMETERIZATION = null;
            }
            this.$scope.preferenceList = [];

            const preferenceType = this.$scope.model.PREFERENCE_TYPE;
            if (!preferenceType) return;

            if (preferenceType.ID === '2') {
                for (let i = 1; i <= 5; i++) { // weekdays
                    this.$scope.preferenceList.push({ ID: i.toString(), NAME: moment.weekdays(i) });
                }
            } else if (preferenceType.ID === '3') {
                for (let i = 1; i <= 31; i++) { // month Days
                    this.$scope.preferenceList.push({ ID: i.toString(), NAME: i.toString() });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private updatePeriodVariation(): void {
        try {
            if (this.$scope.model.PERIOD_VARIATION.ID == '1') {
                const conversionReference = this.$scope.conversionReferenceList.find(x => x.ID == '4');
                if (!conversionReference) return;
                this.$scope.model.CONVERSION_REFERENCE = conversionReference;
            }
            else
                this.$scope.model.CONVERSION_REFERENCE = null;

            this.$timeout(() => {
                this.$scope.selectorValidity('conversionreference');
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private refreshPaymentType(): Boolean {
        try {
            const paymentType = this.$scope.model.PAYMENT_TYPE;
            if (!paymentType){
                this.$timeout(() => { this.$scope.selectorValidity("paymentMethod"); });
                this.refreshPaymentMethod()
                return;
            }
            for (const paymentItem of paymentType) {
                if (paymentItem.ID == '4' || paymentItem.ID == '5') {
                    const exchangePaymentMethod = this.$scope.paymentMethodList.find(x => x.ID == '4'); // câmbio
                    const conversionReference = this.$scope.conversionReferenceList.find(x => x.ID == '1'); // dia pagamento
                    const conversion = this.$scope.conversionList.find(x => x.ID == '1'); // PTAX
                    if (!exchangePaymentMethod || !conversionReference || !conversion) return;
                    this.$scope.model.PAYMENT_METHOD = exchangePaymentMethod;
                }
            }
            this.$timeout(() => { this.$scope.selectorValidity("paymentMethod"); });
            this.refreshPaymentMethod()
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private refreshPaymentMethod(): void {
        try {
            if (this.$scope.paymentMethodList) {
                const exchangeFieldNames = ['periodvariation', 'conversionreference', 'conversion', 'base', 'spread'];
                const exchangePaymentMethod = this.$scope.paymentMethodList.find(x => x.ID == '4'); // câmbio
                if (this.$scope.model.PAYMENT_METHOD && this.$scope.model.PAYMENT_METHOD == exchangePaymentMethod) {
                    const no = this.$scope.getYesNoSelector.find(x => x.ID == '2');
                    const conversionReference = this.$scope.conversionReferenceList.find(x => x.ID == '1'); // dia pagamento
                    const conversion = this.$scope.conversionList.find(x => x.ID == '1'); // PTAX

                    this.$scope.model.PERIOD_VARIATION = no;
                    this.$scope.model.CONVERSION_REFERENCE = conversionReference;
                    this.$scope.model.CURRENCY_CONVERSION = conversion;
                    this.$scope.model.BASE = 0;
                    this.$scope.model.SPREAD = 0;

                    this.$scope.disableElements(true, null, exchangeFieldNames);
                    this.$timeout(() => { this.$scope.selectorValidity(exchangeFieldNames.concat(['paymentMethod'])); });
                } else if (!this.$scope.model.PAYMENT_TYPE || this.$scope.model.PAYMENT_TYPE.length == 0) {
                    this.$scope.model.PERIOD_VARIATION = null;
                    this.$scope.model.CONVERSION_REFERENCE = null;
                    this.$scope.model.CURRENCY_CONVERSION = null;
                    this.$scope.model.BASE = null;
                    this.$scope.model.SPREAD = null;

                    this.$scope.disableElements(false, null, exchangeFieldNames);
                    this.$timeout(() => { this.$scope.selectorValidity(exchangeFieldNames.concat(['paymentMethod'])); });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initDateFields(): void {
        try {
            if (this.$scope.model) {
                this.$scope.model.DISPLAY_VALIDITY_START = (this.$scope.model.DISPLAY_VALIDITY_START != null) ? new Date(this.$scope.model.DISPLAY_VALIDITY_START) : null;
                this.$scope.model.VALIDITY_START = (this.$scope.model.VALIDITY_START != null) ? new Date(this.$scope.model.VALIDITY_START) : null;
                this.$scope.model.DISPLAY_VALIDITY_END = (this.$scope.model.DISPLAY_VALIDITY_END != null) ? new Date(this.$scope.model.DISPLAY_VALIDITY_END) : null;
                this.$scope.model.VALIDITY_END = (this.$scope.model.VALIDITY_END != null) ? new Date(this.$scope.model.VALIDITY_END) : null;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private generateConcatenated(): void {
        try {
            const separator = " | ";
            let concatenated = "";

            if (this.$scope.model.HOLDER && this.$scope.model.HOLDER.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.HOLDER.map(obj => { return obj.CODE }).join(',')) : concatenated.concat(this.$scope.model.HOLDER.map(obj => { return obj.CODE }).join(','));
            }

            if (this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID) {
                let paymentNature = null;
                if (this.$scope.model.PAYMENT_NATURE.ID === '1') {
                    paymentNature = 'PGTO';
                } else if (this.$scope.model.PAYMENT_NATURE.ID === '2') {
                    paymentNature = 'RCTO';
                }
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, paymentNature) : concatenated.concat(paymentNature);
            }

            if (this.$scope.model.ACCOUNT && this.$scope.model.ACCOUNT.NAME) {
                concatenated = concatenated.length > 0
                    ? concatenated.concat(separator, this.$scope.model.ACCOUNT.NAME)
                    : concatenated.concat(this.$scope.model.ACCOUNT.NAME);
            }

            if (this.$scope.model.NETWORK && this.$scope.model.NETWORK.length) {
                concatenated = concatenated.length > 0
                    ? concatenated.concat(separator, this.$scope.model.NETWORK.map(network => { return network.NAME }).join(','))
                    : concatenated.concat(this.$scope.model.NETWORK.map(network => { return network.NAME }).join(','));
            }

            if (this.$scope.model.LEGAL_PERSON_AGENT && this.$scope.model.LEGAL_PERSON_AGENT.NAME) {
                concatenated = concatenated.length > 0
                    ? concatenated.concat(separator, this.$scope.model.LEGAL_PERSON_AGENT.NAME)
                    : concatenated.concat(this.$scope.model.LEGAL_PERSON_AGENT.NAME);
            }

            if (this.$scope.model.PROVIDER && this.$scope.model.PROVIDER.NAME) {
                concatenated = concatenated.length > 0
                    ? concatenated.concat(separator, this.$scope.model.PROVIDER.TYPE && (this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.SHIPOWNER || this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.AIRLINE) ? this.$scope.model.PROVIDER.CODE : this.$scope.model.PROVIDER.NAME)
                    : concatenated.concat(this.$scope.model.PROVIDER.TYPE && (this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.SHIPOWNER || this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.AIRLINE) ? this.$scope.model.PROVIDER.CODE : this.$scope.model.PROVIDER.NAME);
            }

            if (this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PRODUCT.map(obj => { return obj.ID }).join(',')) : concatenated.concat(this.$scope.model.PRODUCT.map(obj => { return obj.ID }).join(','));
            }

            if (this.$scope.model.PAYMENT_TYPE && this.$scope.model.PAYMENT_TYPE.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PAYMENT_TYPE.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(this.$scope.model.PAYMENT_TYPE.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.CONCATENATED_COMPLEMENT && this.$scope.model.CONCATENATED_COMPLEMENT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CONCATENATED_COMPLEMENT) : concatenated.concat(this.$scope.model.CONCATENATED_COMPLEMENT);
            }

            if (this.$scope.model.DISPLAY_VALIDITY_START && this.$scope.model.DISPLAY_VALIDITY_END) {
                const validity = moment(this.$scope.model.DISPLAY_VALIDITY_START).format('DD/MM/YY') + '-' + moment(this.$scope.model.DISPLAY_VALIDITY_END).format('DD/MM/YY');
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, validity) : concatenated.concat(validity);
            }

            this.$scope.model.CONCATENATED = StringUtil.formatConcatenatedChars(concatenated);

            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [{ text: `${this.$scope.model.CONTRACT_NUMBER} - ${this.$scope.model.CONCATENATED}`, class: "text-rouge font-bold" }];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private isOriginDestinationDisabled(isActive: boolean): void {
        const exchangeFieldNames = ['tradeOrigin', 'tradeLaneOrigin', 'countryOrigin', 'routingPointOrigin', 'tradeDestination', 'tradeLaneDestination', 'countryDestination', 'routingPointDestination'];

        if (isActive) {
            this.$scope.disableElements(true, null, exchangeFieldNames);
            this.$scope.model.TRADE_ORIGIN = null;
            this.$scope.model.TRADE_LANE_ORIGIN = null;
            this.$scope.model.COUNTRY_ORIGIN = null;
            this.$scope.model.ROUTING_POINT_ORIGIN = null;
            this.$scope.model.TRADE_DESTINATION = null;
            this.$scope.model.TRADE_LANE_DESTINATION = null;
            this.$scope.model.COUNTRY_DESTINATION = null;
            this.$scope.model.ROUTING_POINT_DESTINATION = null;
        } else {
            this.$scope.disableElements(false, null, exchangeFieldNames);
        }
    }

    private async getGenericList(type: string, alternative: boolean = false): Promise<any> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}/${alternative}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async refreshNetworkListByName(name: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            if (name && name.length >= 3) {
                this.block();
                const networks = await this.RestService.getObjectAsPromise(`${this.$baseUrl}/network/list/${name}`, 30000, null, false);
                if (networks && networks.data) result = networks.data;
            }
            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.networkList = result;
            this.formService.unblock();
        }
    }

    private async refreshTradeLaneListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const tradeLanesList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/tradelane/list/custom`, { name: search }, 30000, false);
                result = tradeLanesList ? tradeLanesList.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.tradeLaneList = result;
        }
    }

    private async refreshCountryListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const countryList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/country/list/custom`, { search }, 30000, false);
                result = (countryList && countryList) ? countryList.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.countryList = result;
            this.formService.unblock();
        }
    }

    private async refreshRoutingPointListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();
                if (!this.$scope.model.PRODUCT) return this.formService.handleError(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                let types = ['2', '4'];
                if (this.$scope.model.PRODUCT.some(product => product.ID == "EM" || product.ID == "IM")) types = ['2'];
                if (this.$scope.model.PRODUCT.some(product => product.ID == "EA" || product.ID == "IA")) types = ['4'];
                const routingPoints = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/routingPoint/list/custom/`, { name: search, types: types }, 30000, false);
                result = routingPoints ? routingPoints.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.routingPointList = result;
            this.formService.unblock();
        }
    }

    private async getLegalPersonListByName(name: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.block();
            const legalPeople = await this.RestService.newObjectPromise(`${this.$baseUrl}/legalPerson/list/custom`, { search: name }, 30000, false);
            if (legalPeople) result = legalPeople.map(x => { return { ID: x.ID, NAME: x.CORPORATE_NAME, CODE: x.SHORT_NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getProviderListByName(name: string): Promise<IProviderSelector[]> {
        let result: IProviderSelector[] = [];
        try {
            if (name && name.length >= 3) {
                this.block();
                const providers = await this.RestService.newObjectPromise(`${this.$baseUrl}/provider/list/custom`, { search: name, products: this.selectedProducts }, 30000, false);
                if (providers) result = providers.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.SCAC_IATA, TYPE: x.TYPE } });
            }
            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.providerList = result;
            this.formService.unblock();
        }
    }

    private async getAgentByName(name: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            if (name && name.length >= 3) {
                if (!this.$scope.model.PRODUCT) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                this.block();
                const products = this.$scope.model.PRODUCT.map(products => { return products.ID });
                const agents = await this.RestService.newObjectPromise(`${this.$baseUrl}/agent/list/custom`, { search: name, products }, 30000, false);
                if (agents) result = agents.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.LEGAL_PERSON_ID.toString() } });
            }
            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.agentList = result;
            this.formService.unblock();
        }
    }

    private async getAccountListByName(name: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            if (name && name.length >= 3) {
                this.block();
                const accounts = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/account/list/custom`, { search: name }, 30000, false);
                result = accounts ? accounts.map(x => { return { ID: x.ID, NAME: x.NAME } }) : [];
            }
            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.agentList = result;
            this.formService.unblock();
        }
    }

    private async getContractById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const genericResult = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/contract/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return genericResult.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private isEmptyArray(arrayObj: any[]): boolean {
        return (arrayObj && arrayObj.length == 0 || !arrayObj);
    }

    private checkDateValidity(initialDate: Date, finalDate: Date): void {
        let isValid = false;
        if (!initialDate || typeof initialDate == "string") return;
        if (!finalDate || typeof finalDate == "string") return;
        isValid = ValidateUtil.isValidDateRange(initialDate, finalDate);
        if (!isValid) {
            this.$scope.model.DISPLAY_VALIDITY_END = null;
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE"
            },
            {
                PROPERTY: "NAME",
                LABEL: "GENERAL.NAME"
            },
            {
                PROPERTY: "CONTRACT_NUMBER",
                LABEL: "OPERATIONAL.FINANCIAL_CONTRACT"
            },
            {
                PROPERTY: "CONCATENATED",
                LABEL: "GENERAL.CONCATENATED"
            },
            {
                PROPERTY: "PRODUCT",
                LABEL: "BASIC_DATA.PRODUCT"
            },
            {
                PROPERTY: "HOLDER",
                LABEL: "GENERAL.HOLDER"
            },
            {
                PROPERTY: "TRANSACTION",
                LABEL: "GENERAL.HOLDER_TYPE"
            },
            {
                PROPERTY: "PAYMENT_NATURE",
                LABEL: "BASIC_DATA.PAYMENT_NATURE"
            },
            {
                PROPERTY: "PAYMENT_TYPE",
                LABEL: "FINANCIAL.CHARGE_PAYMENT_METHOD"
            },
            {
                PROPERTY: "NETWORK",
                LABEL: "BASIC_DATA.NETWORK"
            },
            {
                PROPERTY: "PROVIDER",
                LABEL: "BASIC_DATA.PROVIDER"
            },
            {
                PROPERTY: "ACCOUNT",
                LABEL: "BASIC_DATA.CLIENT"
            },
            {
                PROPERTY: "LEGAL_PERSON_AGENT",
                LABEL: "BASIC_DATA.AGENT"
            },
            {
                PROPERTY: "PROCESS_TYPE",
                LABEL: "FINANCIAL.PRODUCT_SERVICE_TYPE"
            },
            {
                PROPERTY: "ORIGIN_TYPE",
                LABEL: "ROUTE.ORIGIN_TYPE"
            },
            {
                PROPERTY: "ORIGIN",
                LABEL: "BASIC_DATA.ORIGIN"
            },
            {
                PROPERTY: "DESTINATION_TYPE",
                LABEL: "ROUTE.DESTINATION_TYPE"
            },
            {
                PROPERTY: "DESTINATION",
                LABEL: "BASIC_DATA.DESTINATION"
            },
            {
                PROPERTY: "VALIDITY_REFERENCE",
                LABEL: "GENERAL.VALIDITY_BASIS"
            },
            {
                PROPERTY: "TRADE_LANE_ORIGIN",
                LABEL: "BASIC_DATA.ORIGIN_TRADE_LANE"
            },
            {
                PROPERTY: "TRADE_LANE_DESTINATION",
                LABEL: "BASIC_DATA.DESTINATION_TRADE_LANE"
            },
            {
                PROPERTY: "TRADE_ORIGIN",
                LABEL: "BASIC_DATA.ORIGIN_TRADE_GROUP"
            },
            {
                PROPERTY: "TRADE_DESTINATION",
                LABEL: "BASIC_DATA.DESTINATION_TRADE_GROUP"
            },
            {
                PROPERTY: "COUNTRY_ORIGIN",
                LABEL: "BASIC_DATA.ORIGIN_COUNTRY"
            },
            {
                PROPERTY: "COUNTRY_DESTINATION",
                LABEL: "BASIC_DATA.DESTINATION_COUNTRY"
            },
            {
                PROPERTY: "ROUTING_POINT_ORIGIN",
                LABEL: "BASIC_DATA.ORIGIN_PORT"
            },
            {
                PROPERTY: "ROUTING_POINT_DESTINATION",
                LABEL: "BASIC_DATA.DESTINATION_PORT"
            },
            {
                PROPERTY: "DISPLAY_VALIDITY_START",
                LABEL: "PRODUCT.VALIDITY_START"
            },
            {
                PROPERTY: "DISPLAY_VALIDITY_END",
                LABEL: "PRODUCT.VALIDITY_END"
            },
            {
                PROPERTY: "SITUATION",
                LABEL: "GENERAL.SITUATION"
            },
            {
                PROPERTY: "PAYMENT_CONDITION",
                LABEL: "GENERAL.INVOICE_PAYMENT_TERM"
            },
            {
                PROPERTY: "DEADLINE_REFERENCE",
                LABEL: "FINANCIAL.PAYMENT_TERMS_REFERENCE"
            },
            {
                PROPERTY: "DEADLINE_D0",
                LABEL: "FINANCIAL.PAYMENT_TERM_DAYS"
            },
            {
                PROPERTY: "PREFERENCE_TYPE",
                LABEL: "FINANCIAL.PREFERENCE_TYPE"
            },
            {
                PROPERTY: "PREFERENCE",
                LABEL: "FINANCIAL.PREFERENCE"
            },
            {
                PROPERTY: "PREFERENCE_PARAMETERIZATION",
                LABEL: "FINANCIAL.PREFERENCE_SETTING"
            },
            {
                PROPERTY: "PAYMENT_METHOD",
                LABEL: "FINANCIAL.PAYMENT_MEANS"
            },
            {
                PROPERTY: "CONVERSION_REFERENCE",
                LABEL: "REGISTRATION.EXC_RATE_REFERENCE_DATE"
            },
            {
                PROPERTY: "CURRENCY_CONVERSION",
                LABEL: "GENERAL.EXCHANGE_RATE_INDEX"
            },
            {
                PROPERTY: "BASE",
                LABEL: "FINANCIAL.BASE"
            },
            {
                PROPERTY: "SPREAD",
                LABEL: "FINANCIAL.SPREAD"
            },
            {
                PROPERTY: "PERIOD_VARIATION",
                LABEL: "REGISTRATION.PERIOD_VARIATION"
            },
            {
                PROPERTY: "CODE",
                LABEL: "GENERAL.CODE"
            },
            {
                PROPERTY: "ANTICIPATED_INVOICE",
                LABEL: "REGISTRATION.EARLY_RECEIPT"
            },
            {
                PROPERTY: "PAYMENT_COMPROMISE",
                LABEL: "FINANCIAL.PAYMENT_AGREEMENT"
            },
            {
                PROPERTY: "OBSERVATION",
                LABEL: "GENERAL.REMARKS"
            },
            {
                PROPERTY: "SCORE",
                LABEL: "BASIC_DATA.SCORE"
            },
            {
                PROPERTY: "SCORE_DATE",
                LABEL: "GENERAL.SCORE_CALCULATION_DATA"
            },
            {
                PROPERTY: "SCORE_ERROR",
                LABEL: "GENERAL.SCORE_CALCULATION_ERROR"
            },
            {
                PROPERTY: "CONCATENATED_COMPLEMENT",
                LABEL: "GENERAL.CONCATENATED_COMPLEMENT"
            },
            {
                PROPERTY: "EXPIRED",
                LABEL: "GENERAL.EXPIRED"
            },
            {
                PROPERTY: "CREATED_AT",
                LABEL: "GENERAL.CREATED_AT"
            },
            {
                PROPERTY: "CREATED_BY",
                LABEL: "GENERAL.CREATED_BY"
            },
            {
                PROPERTY: "UPDATED_AT",
                LABEL: "GENERAL.UPDATED_AT"
            },
            {
                PROPERTY: "UPDATED_BY",
                LABEL: "GENERAL.UPDATED_BY"
            }
        ];
        return props;
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "Integration Product/Operation" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                const rc = await this.dataProductService.post(`/sync/contract`, { "id": [id] }, 120000);
                if (rc && rc.data && rc.data.data && rc.status == 200) success = true;
            }
        } catch (ex) {
            const msgError = this.formService.getTranslate('GENERAL.ERROR_SENDING_REQUEST');
            this.formService.handleError(msgError);
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const contractData = await this.getContractById(id);
                    if (row && contractData && contractData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = contractData.DOCUMENT_ERROR;
                        documentError = contractData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            const msgError = this.formService.getTranslate('GENERAL.ERROR_DURING_REQUEST');
            this.formService.handleError(msgError);
        } finally {
            return documentError;
        }
    }

    private checkPaymentMethod() {
        if (this.$scope.model.PAYMENT_METHOD && this.$scope.model.PAYMENT_METHOD.ID == '4') {
            this.$scope.disableElements(true, null, ['periodvariation', 'conversionreference', 'conversion', 'base', 'spread']);
        }
    }

    private customExportData() {
        try {
            const { data } = this.$gridService.$gridOptions;
            for (let i = 0; i < data.length; i++) {
                for (let item of Object.keys(data[i])) {
                    if ((data[i][item] != null)) {
                        if (angular.isArray(data[i][item])) {
                            const aux = data[i][item].map(x => x.NAME).join(' , ')
                            data[i][item].NAME = aux ? aux : ""
                        }
                    }
                }
            }
        } catch (ex) {
            throw ex;
        }
    }
}

import * as angular from 'angular';

import initRoute from './RouteConfig';
import { GRID_FORM_MODULE } from '../gridForm/GridFormModule';
import { ChargeRegisterController } from './controller/ChargeRegisterController';
import { ChargeGroupingParamsRegisterController } from './controller/ChargeGroupingParamsRegisterController';
import { FinopMenuController } from './controller/FinopMenuController';
import { InvoiceRegisterController } from './controller/InvoiceRegisterController';
import { BillingContactRegisterController } from './controller/BillingContactRegisterController';
import { ContractRegisterController } from './controller/ContractRegisterController';
import { FinopService } from '@services/FinopService';
import { ProductService } from '@services/ProductService';
import { OperationalService } from '@services/OperationalService';
import { DataProductService } from '@services/DataProductService';
import { DataManagerService } from '@services/DataManagerService';

const FINOP_MODULE = angular
    .module('finop', [GRID_FORM_MODULE.name])
    .controller('ChargeRegisterController', ChargeRegisterController)
    .controller('ChargeGroupingParamsRegisterController', ChargeGroupingParamsRegisterController)
    .controller('FinopMenuController', FinopMenuController)
    .controller('InvoiceRegisterController', InvoiceRegisterController)
    .controller('BillingContactRegisterController', BillingContactRegisterController)
    .controller('ContractRegisterController', ContractRegisterController)
    .service('FinopService', FinopService)
    .service('ProductService', ProductService)
    .service('OperationalService', OperationalService)
    .service('DataProductService', DataProductService)
    .service('DataManagerService', DataManagerService)

initRoute(FINOP_MODULE.name);

export { FINOP_MODULE };
import angular = require('angular');
import moment = require('moment');
import { IModalOptions, IModalService } from '@services/ModalService';
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { IRestService } from '@services/RestService';
import { ProductService } from "@services/ProductService";
import { OperationalService } from "@services/OperationalService";
import { ExternalService } from '@services/ExternalService';
import { IBookingWizardModel } from "@models/interface/operational/BookingModel";
import { IInttraEntity, ICargoDetail, IEquipmentList, IDangerCargo, IPayerInformation, IEquipmentInterface, IInttraEntityProvider, IBooking, IMainCarriage } from '@models/interface/operational/Booking';
import { ISelectorModel, SelectorModel } from '@models/mongo/SelectorModel';
import { Process } from '@models/interface/operational/NewProcess';
import { IInttraOceanSchedulesResult, IInttraOceanSchedulesRequest, IInttraValidationResult } from '@models/interface/external/IInttra';
import { IParam, IMoveTypeModel } from '@models/interface/product/MoveTypeModel';
import { EUnitOfMeasurementId, EEventType, EEquipmentGroupId, EDirectionId, EProductId, EMoveTypeId, EProcessSituation, EProcessTypeId } from '@enums/GenericData';
import { EInttraSearchDateType, EPayerChargeType, EPartyRole, EInttraMoveType, EBookingContactCell } from '@enums/InttraEnum';
import { ETypeRuleId } from '@enums/MoveTypeModel';
import { ILegalPersonListCustomFilter } from '../../product/model/LegalPersonModel';
import { HelperService } from "@services/HelperService";
import { ENTITY, EVENT, ENTITY_AGENT } from '@models/interface/operational/NewProcess';

const enum ETabStep {
    GENERAL_DETAILS = 0,
    TRANSPORT = 1,
    CARGO = 2,
    CONFIRMATION = 3
}

interface IUiTabSteps {
    current: number;
    percent: number;
}

interface IOceanSchedule extends IInttraOceanSchedulesResult {
    selected: boolean;
    extraLoader: boolean;
}

interface IBookingIntegration {
    bookingIntegration: boolean;
    bookingNumber: string;
    bookingStatus: string;
}

interface IBookingModalScope extends IFormServiceScope {
    modalOptions: IModalOptions;
    isViewMode?: boolean;
    bookingToView?: IBooking;
    isAmendMode?: boolean;
    steps: IUiTabSteps;
    model: IBookingWizardModel;
    processInfo: Process;
    isProcessOrigin: boolean;
    bookingAttached: IBookingIntegration;
    processList: ISelectorModel[];
    productList: ISelectorModel[];
    carrierList: IInttraEntity[];
    contractNumberList: ISelectorModel[];
    routingPointList: ISelectorModel[];
    paymentPointList: ISelectorModel[];
    commodityList: ISelectorModel[];
    moveTypeList: IMoveTypeModel[];
    equipmentList: IInttraEntity[];
    unClassList: ISelectorModel[];
    inttraDangerousPackageTypeList: ISelectorModel[];
    packingGroupList: ISelectorModel[];
    unitOfMeasurementList: ISelectorModel[];
    processEventTransportModeList: IInttraEntity[];
    oceanScheduleOptions: IOceanSchedule[];
    integrationBookingResult: IInttraValidationResult;
    shipNotFound: string;
    inttraChargePayerList: ISelectorModel[];
    inttraChargeTypeList: ISelectorModel[];
    typePaymentList: ISelectorModel[];
    isPlaceCarrierReceiptMandatory: boolean;
    isPlaceCarrierDeliveryMandatory: boolean;
    isPreCarriageModeMandatory: boolean;
    isOnCarriageModeMandatory: boolean;
    isCarrierBookingProcessDifferent: boolean;
    loggedInttraCorporateBranch: string;

    //general methods
    retryBooking: () => Promise<void>;

    //navigation
    back: () => void;
    next: () => void;
    finish: () => void;
    tabClick: (tabIndex: number) => void;
    isTabEnabled: (tabIndex: number) => boolean;

    //gets
    getProcessListByName: (processNumber: string) => Promise<void>;
    getCarrierListByName: (search: string) => Promise<void>;
    getContractNumberListByName: (search: string) => Promise<void>;
    getRoutingPointListByName: (name: string, params: IParam[], directionId: string, types?: string[]) => Promise<void>;
    getPaymentPointListByName: (name: string, params: IParam[]) => Promise<void>;
    getCommodityListByName: (search: string) => Promise<void>;
    getEquipmentListByNameOrCode: (search: string) => Promise<void>;

    //field changes
    fileChange: () => void;
    moveTypeChange: (moveType: IInttraEntity) => void;
    dangerousCargoChange: () => void;
    formatEquipmentTemperature: (equipment: IEquipmentList) => void;
    onPortChange: (moveType: IInttraEntity) => void;

    //multilines control
    addCargoDetail: () => void;
    removeCargoDetail: (index: number) => void;
    addDangerousCargo: () => void;
    removeDangerousCargo: (index: number) => void;
    addContainer: () => void;
    removeContainer: (index: number) => void;
    addPayer: () => void;
    removePayer: (index: number) => void;
    selectOceanSchedule: (oceanScheduleIndex: number) => void;
    isEquipGroupSpecial: (equipmentGroup: ISelectorModel) => boolean;
    isEquipGroupReefer: (equipmentGroup: ISelectorModel) => boolean;
    checkPaymentLocation: (payment: ISelectorModel, index: number) => Promise<ISelectorModel>;
    checkRemarks: () => Promise<void>;
    openPeopleDetails: (entity: ENTITY, agentEntity?: ENTITY_AGENT) => void;
}

export class BookingWizardModalController extends FormService2 {
    static $inject: string[] = ['$injector', '$scope'];
    private scope: IBookingModalScope;
    private $RestService: IRestService;
    private $q: ng.IQService;
    private timeout: ng.ITimeoutService
    private ModalService: IModalService;
    private ProductService: ProductService;
    private OperationalService: OperationalService;
    private ExternalService: ExternalService;
    private helperService: HelperService;
    private $sce: angular.ISCEService;
    private formService: FormService2;

    constructor($injector: ng.Injectable<any>, $scope: IBookingModalScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.$RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');
        this.$q = $injector.get('$q');
        this.timeout = $injector.get('$timeout');
        this.ProductService = $injector.get('ProductService');
        this.OperationalService = $injector.get('OperationalService');
        this.ExternalService = $injector.get('ExternalService');
        this.scope.isProcessOrigin = false;
        this.helperService = $injector.get('HelperService');
        this.formService = this.scope.formService;
        this.$sce = $injector.get('$sce');
    }

    async $onInit(): Promise<void> {
        try {
            this.block();

            this.init("bookingWizardModalForm", null, null);
            this.initScopeFunctions();
            await this.initModel(this.scope.bookingToView);
            if (!this.scope.isViewMode || this.scope.isAmendMode) {
                await this.initDependencies();
                await this.getAndUpdateForwarder();
            }
            this.loadRegisterForm(false);
            if (this.scope.isAmendMode) {
                await this.fileChange();
                this.checkBookingAndFileCarrier();
            }

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    private initScopeFunctions(): void {

        this.scope.retryBooking = async (): Promise<void> => {
            if (this.scope.integrationBookingResult.REGISTERED_BOOKING && this.scope.integrationBookingResult.REGISTERED_BOOKING.ID) {
                if (!this.scope.isAmendMode) {
                    this.scope.integrationBookingResult = await this.reprocessBooking();
                } else {
                    this.scope.integrationBookingResult = await this.amendBooking();
                }
            } else {
                this.scope.integrationBookingResult = await this.registerBooking();
            }
        }

        this.scope.back = async (): Promise<void> => {
            if (this.scope && this.scope.steps && this.scope.steps.current > 0) {
                this.scope.steps.current--;
            }
            if (!this.scope.isViewMode && this.scope.steps.current == 2) {
                this.resetErrorsAndWarnings();
            }
        }

        this.scope.next = async (): Promise<void> => {
            if (this.scope && this.scope.steps && angular.isDefined(this.scope.steps.current)) {
                if (this.scope.isViewMode) {
                    this.scope.steps.current++;
                } else {
                    const validFields = this.checkPendingFields('bookingWizardModalForm');
                    if (!validFields) return;

                    if (this.scope.steps.current < 3 && await this.handleNavigationChange(this.scope.steps.current)) {
                        this.scope.steps.current++;
                    }
                }
                this.scope.$applyAsync();
            }
        }

        this.scope.tabClick = (tabIndex: number): void => {
            if (!this.scope.steps || tabIndex == null) return;
            const previousStep = this.scope.steps.current - 1;
            const nextStep = this.scope.steps.current + 1;
            if (previousStep == tabIndex) this.scope.back();
            else if (nextStep == tabIndex) this.scope.next();
        }

        this.scope.isTabEnabled = (tabIndex: number): boolean => {
            if (!this.scope.steps || tabIndex == null) return false;
            const previousStep = this.scope.steps.current - 1;
            const nextStep = this.scope.steps.current + 1;
            return (previousStep == tabIndex || nextStep == tabIndex);
        }

        this.scope.finish = async (): Promise<void> => {
            await this.finish();
        }

        this.scope.getProcessListByName = async (processNumber: string) => {
            let processList: ISelectorModel[] = [];
            if (processNumber && processNumber.length >= 3) processList = await this.getProcessListByName(processNumber);
            this.scope.processList = processList;
        }

        this.scope.getCarrierListByName = async (search: string) => {
            let carrierList: IInttraEntity[] = [];
            if (search && search.length >= 3) carrierList = await this.getProviderListByName(search);
            this.scope.carrierList = carrierList;
        }

        this.scope.getContractNumberListByName = async (search: string) => {
            let carrierList: ISelectorModel[] = [];
            if (search && search.length >= 3) carrierList = await this.getFreightContractListByName(search);
            this.scope.contractNumberList = carrierList;
        }

        this.scope.getRoutingPointListByName = async (name: string, params: IParam[], directionId: string, types?: string[]) => {
            let routingPointList: ISelectorModel[] = [];
            if (name && name.length >= 2) {
                if (!types) {
                    if (!params) return this.notifyError("params is null");
                    if (!directionId) return this.notifyError("directionId is null");
                    types = [];
                    const directionParams: IParam[] = params && params.length > 0 ? params.filter(param => param.DIRECTION && param.DIRECTION.ID == directionId) : null;
                    if (directionParams) {
                        if (directionId == EDirectionId.ORIGIN) {
                            for (let i = 0; i < directionParams.length; i++) {
                                if (directionParams[i] && directionParams[i].TYPE_RULE && directionParams[i].TYPE_RULE.ID == ETypeRuleId.MANDATORY) {
                                    types = directionParams[i].TYPE_LOCAL_EVENT && directionParams[i].TYPE_LOCAL_EVENT.length > 0 ? directionParams[i].TYPE_LOCAL_EVENT.map(local => local.ID) : null;
                                    break;
                                }
                            }
                        } else if (directionId == EDirectionId.DESTINATION) {
                            for (let i = directionParams.length; i >= 0; i--) {
                                if (directionParams[i] && directionParams[i].TYPE_RULE && directionParams[i].TYPE_RULE.ID == ETypeRuleId.MANDATORY) {
                                    types = directionParams[i].TYPE_LOCAL_EVENT && directionParams[i].TYPE_LOCAL_EVENT.length > 0 ? directionParams[i].TYPE_LOCAL_EVENT.map(local => local.ID) : null;
                                    break;
                                }
                            }
                        }
                    }
                }
                routingPointList = await this.getRoutingPointListByName(name, types);
            }
            this.scope.routingPointList = routingPointList;
        }

        this.scope.getPaymentPointListByName = async (name: string, params: IParam[]) => {
            let routingPointList: ISelectorModel[] = [];
            if (name && name.length >= 2) {
                if (!this.scope.model.GENERAL_DETAILS_TAB.PRODUCT.ID) return this.handleError(this.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                let type: string[] = [];
                // Port
                if (this.scope.model.GENERAL_DETAILS_TAB.PRODUCT.ID == EProductId.MARITIME_IMPORT || this.scope.model.GENERAL_DETAILS_TAB.PRODUCT.ID == EProductId.MARITIME_EXPORT) {
                    type.push("2");
                }
                // Door
                if (this.scope.model.GENERAL_DETAILS_TAB.PRODUCT.ID == EProductId.ROAD_IMPORT || this.scope.model.GENERAL_DETAILS_TAB.PRODUCT.ID == EProductId.ROAD_EXPORT) {
                    type.push("3");
                }
                // Airport
                if (this.scope.model.GENERAL_DETAILS_TAB.PRODUCT.ID == EProductId.AIR_IMPORT || this.scope.model.GENERAL_DETAILS_TAB.PRODUCT.ID == EProductId.AIR_EXPORT) {
                    type.push("4");
                }
                routingPointList = await this.getRoutingPointListByName(name, type);
            }
            this.scope.paymentPointList = routingPointList;
        }

        this.scope.getCommodityListByName = async (search: string) => {
            let commodityList: ISelectorModel[] = [];
            if (search && search.length >= 2) commodityList = await this.getCommodityListByName(search);
            this.scope.commodityList = commodityList;
        }

        this.scope.getEquipmentListByNameOrCode = async (search: string) => {
            let equipmentList: IInttraEntity[] = [];
            if (search && search.length >= 2) equipmentList = await this.getEquipmentListByNameOrCode(search);
            this.scope.equipmentList = equipmentList;
        }

        this.scope.addCargoDetail = (): void => {
            this.addCargoDetail();
        }

        this.scope.removeCargoDetail = (index: number): void => {
            this.removeCargoDetail(index);
        }

        this.scope.addDangerousCargo = (): void => {
            this.addDangerousCargo();
        }

        this.scope.openPeopleDetails = (entity: ENTITY, agentEntity?: ENTITY_AGENT) => {
            this.openPeopleDetails(entity, agentEntity);
        }

        this.scope.removeDangerousCargo = (index: number): void => {
            this.removeDangerousCargo(index);
        }

        this.scope.addContainer = (): void => {
            this.addContainer();
        }

        this.scope.removeContainer = (index: number): void => {
            this.removeContainer(index);
        }

        this.scope.addPayer = (): void => {
            this.addPayer();
        }

        this.scope.removePayer = (index: number): void => {
            this.removePayer(index);
        }

        this.scope.selectOceanSchedule = (oceanScheduleIndex: number) => {
            this.selectOceanSchedule(oceanScheduleIndex);
        }

        this.scope.isEquipGroupSpecial = (equipmentGroup: ISelectorModel) => {
            return equipmentGroup && equipmentGroup.ID == EEquipmentGroupId.SPECIAL;
        }

        this.scope.isEquipGroupReefer = (equipmentGroup: ISelectorModel) => {
            if (this.scope.processInfo && this.scope.processInfo.CARGO_DETAIL && this.scope.processInfo.CARGO_DETAIL.REFRIGERATED_CARGO) {
                this.scope.model.CARGO_TAB.EQUIPMENT_LIST.map(i => {
                    i.TEMPERATURE = this.scope.processInfo.CARGO_DETAIL.REFRIGERATED_CARGO.MINIMUM_TEMPERATURE.toString();
                });
            }
            return equipmentGroup && equipmentGroup.ID == EEquipmentGroupId.REEFER;
        }

        this.scope.fileChange = () => {
            this.fileChange();
        }

        this.scope.moveTypeChange = (moveType: IInttraEntity) => {
            this.moveTypeChange(moveType);
        }

        this.scope.dangerousCargoChange = () => {
            this.dangerousCargoChange();
        }

        this.scope.formatEquipmentTemperature = (equipment: IEquipmentList) => {
            if (equipment.TEMPERATURE != null) {
                const isNegative = equipment.TEMPERATURE.toString().startsWith("-");
                const temperature = isNegative ? parseInt(equipment.TEMPERATURE.toString().substring(1)) : parseInt(equipment.TEMPERATURE);
                let formatedTemperature = equipment.TEMPERATURE;
                if (temperature != 0 && temperature < 100) {
                    formatedTemperature = `${isNegative ? '-0' : '0'}${temperature.toString()}`;
                } else if (formatedTemperature.substring(0, 1) == '0') formatedTemperature = formatedTemperature.substring(1);
                equipment.TEMPERATURE = formatedTemperature;
            }
        }

        this.scope.onPortChange = (moveType: IInttraEntity) => {
            if (moveType && moveType.ID) {
                switch (moveType.ID.toString()) {
                    case EMoveTypeId.DOOR_TO_PORT:
                    case EMoveTypeId.DOOR_TO_TERMINAL:
                    case EMoveTypeId.DOOR_TO_DOOR:
                    case EMoveTypeId.PORT_TO_PORT:
                    case EMoveTypeId.PORT_TO_TERMINAL:
                    case EMoveTypeId.PORT_TO_DOOR:
                    case EMoveTypeId.PORT_TO_DOOR:
                    case EMoveTypeId.TERMINAL_TO_DOOR:
                    case EMoveTypeId.DOOR_TO_DOOR:
                    case EMoveTypeId.PORT_TO_PORT:
                    case EMoveTypeId.TERMINAL_TO_PORT:
                    case EMoveTypeId.DOOR_TO_PORT:
                        this.scope.model.GENERAL_DETAILS_TAB.PLACE_CARRIER_RECEIPT = this.scope.model.GENERAL_DETAILS_TAB.PORT_OF_LOAD ? this.scope.model.GENERAL_DETAILS_TAB.PORT_OF_LOAD : null;
                        this.scope.model.GENERAL_DETAILS_TAB.PLACE_CARRIER_DELIVERY = this.scope.model.GENERAL_DETAILS_TAB.PORT_OF_DISCHARGE ? this.scope.model.GENERAL_DETAILS_TAB.PORT_OF_DISCHARGE : null;
                        break;
                    default:
                        break;
                }
            }
        }

        this.scope.checkPaymentLocation = (paymentType: ISelectorModel, index: number) => {
            return this.checkPaymentLocation(paymentType, index);
        }

        this.scope.checkRemarks = (): Promise<void> => {
            return this.checkRemarks();
        }

    }

    private async getAndUpdateForwarder(): Promise<void> {
        const legalPersons = await this.getLegalPersonListByName({ search: "UN Allog ITJ", specializations: null });
        const unAllogItj = legalPersons && legalPersons.length ? legalPersons.find(legalPerson => legalPerson.ID == "181") : null;
        if (!unAllogItj) this.notifyError(this.getTranslate('OPERATIONAL.FORWARDER_NOT_FOUND'));
        this.scope.model.GENERAL_DETAILS_TAB.FORWARDER = { ID: unAllogItj.ID, NAME: unAllogItj.NAME, CODE: unAllogItj.CODE, INTTRA_CODE: null };
    }

    private checkBookingAndFileCarrier(): void {
        if (this.scope.model && this.scope.model.GENERAL_DETAILS_TAB && this.scope.model.GENERAL_DETAILS_TAB.FILE && this.scope.model.GENERAL_DETAILS_TAB.FILE.NAME &&
            this.scope.bookingToView && this.scope.bookingToView.FILE && this.scope.bookingToView.FILE.NAME &&
            this.scope.model.GENERAL_DETAILS_TAB.FILE.NAME != this.scope.bookingToView.FILE.NAME) {
            this.scope.isCarrierBookingProcessDifferent = true;
            this.notifyError(this.getTranslate('OPERATIONAL.CARRIER_BOOKING_IS_NOT_SAME_FROM_FILE'));
        }
    }

    private async bookingAuth() {

        try {
            const corporateBranchList = await this.ProductService.post({ route: '/corporateBranch/list/custom' });
            let corporateBranchId = null;
            let corporateBranchSecret = null;


            if (corporateBranchList && corporateBranchList.data.data) {
                const filteredCorporateBranch = corporateBranchList.data.data.find(x => x.ID == this.scope.model.GENERAL_DETAILS_TAB.FORWARDER.ID);

                if (filteredCorporateBranch) {
                    corporateBranchId = filteredCorporateBranch.CLIENT_ID;
                    corporateBranchSecret = filteredCorporateBranch.CLIENT_SECRET;

                    if (corporateBranchId && corporateBranchSecret) {
                        const authInttra = await this.ExternalService.post({
                            route: '/inttra/auth', data: { clientId: corporateBranchId, clientSecret: corporateBranchSecret }
                        });

                        if (authInttra && authInttra.status == 200) {
                            this.scope.loggedInttraCorporateBranch = this.scope.model.GENERAL_DETAILS_TAB.FORWARDER.NAME;
                            this.scope.model.CLIENT_ID = corporateBranchId;
                            this.scope.model.CLIENT_SECRET = corporateBranchSecret;
                            this.scope.$applyAsync();
                        }
                    }
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async initDependencies(): Promise<any> {
        try {
            const self: BookingWizardModalController = this;
            return new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getGenericList("un_class"),
                    self.getGenericList("inttra_dangerous_package_type"),
                    self.getGenericList("packing_group"),
                    self.getGenericList("unit_of_measurement"),
                    self.getGenericList("process_event_transport_mode"),
                    self.getGenericList("product"),
                    self.getMoveTypeList(),
                    self.getGenericList("inttra_charge_payer"),
                    self.getGenericList("inttra_charge_type"),
                    self.getGenericList("type_payment")
                ]).then((result: any) => {
                    self.scope.unClassList = result[0];
                    self.scope.inttraDangerousPackageTypeList = result[1];
                    self.scope.packingGroupList = result[2];
                    self.scope.unitOfMeasurementList = result[3];
                    self.scope.processEventTransportModeList = result[4] && result[4].length ? result[4].map(obj => { return { ID: obj.ID, NAME: obj.NAME, INTTRA_CODE: null } }) : [];
                    self.scope.productList = result[5] && result[5].length ? result[5].filter(data => data.ID == EProductId.MARITIME_EXPORT || data.ID == EProductId.MARITIME_IMPORT) : [];
                    self.scope.moveTypeList = result[6] ? result[6].map(moveType => { return { ID: moveType.ID, NAME: moveType.NAME, CODE: moveType.CODE, PARAMS: moveType.PARAMS, APPLY_ROAD: moveType.APPLY_ROAD, ORIGIN_TYPE: moveType.ORIGIN_TYPE, DESTINATION_TYPE: moveType.DESTINATION_TYPE } }) : [];
                    self.scope.inttraChargePayerList = result[7];
                    self.scope.inttraChargeTypeList = result[8];
                    self.scope.typePaymentList = result[9];
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async initModel(bookingToView?: IBooking): Promise<void> {
        this.scope.model = {
            ID: null,
            CLIENT_ID: null,
            CLIENT_SECRET: null,
            CUSTOMER: null,
            CARGO_TYPE: null,
            SERVICE: null,
            INTTRA_REFERENCE_NUMBER: null,
            GENERAL_DETAILS_TAB: {
                FILE: null,
                PRODUCT: null,
                CARRIER: null,
                CONTRACT_NUMBER: null,
                CARRIER_BOOKING_NUMBER: null,
                FORWARDER: null,
                FORWARDER_REFERENCE_NUMBER: null,
                MOVE_TYPE: null,
                PORT_OF_LOAD: null,
                PORT_OF_DISCHARGE: null,
                PLACE_CARRIER_RECEIPT: null,
                EARLIEST_DEPARTURE_DATE: null,
                PLACE_CARRIER_DELIVERY: null,
                LATEST_DELIVERY_DATE: null
            },
            PAYER_INFORMATION: null,
            TRANSPORT_TAB: {
                PRE_CARRIAGE: {
                    MODE: null,
                    START: null,
                    ETD: null,
                    END: null,
                    ETA: null
                },
                MAIN_CARRIAGE: {
                    VESSEL: null,
                    VOYAGE: null,
                    PORT_OF_LOAD: null,
                    LOADING_DATE: null,
                    PORT_OF_DISCHARGE: null,
                    DISCHARGE_DATE: null,
                    SERVICE: null
                },
                TRANSSHIPMENT: null,
                ON_CARRIAGE: {
                    MODE: null,
                    START: null,
                    ETD: null,
                    END: null,
                    ETA: null
                }
            },
            CARGO_TAB: {
                CARGO_DETAIL: null,
                IS_DANGEROUS_CARGO: false,
                DANGEROUS_CARGO: null,
                EQUIPMENT_LIST: null
            },
            OOG: null,
            BOOKING_SITUATION: 'Allocation',
            INTTRA_SITUATION: null,
            LOADING_SITUATION: null,
            COMMENTS: null,
            PARTNER_EMAIL_NOTIFICATION: null,
            NOTIFY_ME: null,
            VALIDATION_RESULT: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            IS_ELIGIBLE: true,
            ALLOCATED: null,
            SHIP_TO: null,
            SHIP_FROM: null,
            UPDATE_PROCESS: null,
            CONTACT_CELL: null,
            OVERSEAS_AGENT: null,
            NOTIFY: null,
            CONSIGNEE: null
        };

        this.scope.bookingAttached = null;
        this.scope.isCarrierBookingProcessDifferent = false;

        if (bookingToView) {
            // Booking Basic Data
            this.scope.model.ID = bookingToView.ID;
            this.scope.model.INTTRA_REFERENCE_NUMBER = bookingToView.INTTRA_REFERENCE_NUMBER;

            // General Tab
            this.scope.model.GENERAL_DETAILS_TAB.FILE = bookingToView.FILE;
            this.scope.model.GENERAL_DETAILS_TAB.PRODUCT = bookingToView.PRODUCT;
            this.scope.model.GENERAL_DETAILS_TAB.CARRIER = bookingToView.CARRIER;
            this.scope.model.GENERAL_DETAILS_TAB.FORWARDER = bookingToView.FORWARDER;
            this.scope.model.GENERAL_DETAILS_TAB.FORWARDER_REFERENCE_NUMBER = bookingToView.FORWARDER_REFERENCE_NUMBER;
            this.scope.model.GENERAL_DETAILS_TAB.CARRIER_BOOKING_NUMBER = bookingToView.CARRIER_BOOKING_NUMBER;

            // Transport Tab
            this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE = bookingToView.PRE_CARRIAGE;
            this.scope.model.TRANSPORT_TAB.MAIN_CARRIAGE = bookingToView.MAIN_CARRIAGE;
            this.scope.model.TRANSPORT_TAB.TRANSSHIPMENT = bookingToView.TRANSSHIPMENT;

            if (this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE && (!this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE.START || !this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE.START.ID)) this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE.START = null;
            if (this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE && (!this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE.END || !this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE.END.ID)) this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE.END = null;
            if (this.scope.model.TRANSPORT_TAB.ON_CARRIAGE && (!this.scope.model.TRANSPORT_TAB.ON_CARRIAGE.START || !this.scope.model.TRANSPORT_TAB.ON_CARRIAGE.START.ID)) this.scope.model.TRANSPORT_TAB.ON_CARRIAGE.START = null;
            if (this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE && (!this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE.MODE || !this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE.MODE.ID)) this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE.MODE = null;
            if (this.scope.model.TRANSPORT_TAB.ON_CARRIAGE && (!this.scope.model.TRANSPORT_TAB.ON_CARRIAGE.MODE || !this.scope.model.TRANSPORT_TAB.ON_CARRIAGE.MODE.ID)) this.scope.model.TRANSPORT_TAB.ON_CARRIAGE.MODE = null;

            // Cargo Tab
            this.scope.model.COMMENTS = bookingToView.COMMENTS;
            this.scope.model.PARTNER_EMAIL_NOTIFICATION = bookingToView.PARTNER_EMAIL_NOTIFICATION;
            this.scope.model.NOTIFY_ME = bookingToView.NOTIFY_ME;
            this.scope.model.CARGO_TAB.IS_DANGEROUS_CARGO = bookingToView.IS_DANGER_CARGO;
            this.scope.model.CARGO_TAB.DANGEROUS_CARGO = bookingToView.DANGER_CARGO;
            this.scope.model.CARGO_TAB.CARGO_DETAIL = bookingToView.CARGO_DETAIL;
        }
    }

    private async fileChange() {
        try {
            this.scope.isProcessOrigin = this.scope.model.GENERAL_DETAILS_TAB && this.scope.model.GENERAL_DETAILS_TAB.FILE != null;

            if (this.scope.isProcessOrigin) {
                this.scope.processInfo = await this.getProcessByNumber(this.scope.model.GENERAL_DETAILS_TAB.FILE.NAME);

                if (this.scope.processInfo && this.scope.processInfo.SITUATION && this.scope.processInfo.SITUATION.ID == EProcessSituation.PRE_PROCESS) {
                    return this.notifyError(this.getTranslate('OPERATIONAL.UNABLE_CREATE_BOOKING_PRE_PROCESS'));
                }

                if (this.scope.processInfo && !this.scope.isAmendMode) {
                    // Set the variable to confirm that the process already has a booking attached to it.
                    this.scope.bookingAttached = {
                        bookingIntegration: this.scope.processInfo.IS_BOOKING_INTEGRATION ? true : false,
                        bookingNumber: this.scope.processInfo.BOOKING_INTEGRATION ? this.scope.processInfo.BOOKING_INTEGRATION.INTTRA_REFERENCE : null,
                        bookingStatus: this.scope.processInfo.BOOKING_INTEGRATION ? this.scope.processInfo.BOOKING_INTEGRATION.INTTRA_STATUS : null
                    };

                    if (this.scope.bookingAttached && this.scope.bookingAttached.bookingIntegration) {
                        const modal = await this.ModalService.showModalConfirmation({}, {
                            actionButtonText: 'GENERAL.CONFIRM',
                            headerText: 'GENERAL.CONFIRM_ACTION',
                            bodyText: this.getTranslate('OPERATIONAL.SAME_FILE_BOOKING')
                        });
                        if (modal) {
                            this.scope.model.UPDATE_PROCESS = true;
                        } else {
                            this.notifyError(this.getTranslate('OPERATIONAL.UNABLE_CREATE_BOOKING_SAME_PROCESS'));
                            this.scope.modalOptions.ok(false);
                        }
                    }
                }

                const { IMPORTER, MASTER_DIRECT, NOTIFY, EXTERNAL_AGENT } = this.scope.processInfo;

                const placeReceiptSameServiceProviderEvent = this.scope.processInfo.EVENT ? this.scope.processInfo.EVENT.find(event => (event.SERVICE_PROVIDER && this.scope.processInfo.SERVICE_PROVIDER && event.SERVICE_PROVIDER.ID == this.scope.processInfo.SERVICE_PROVIDER.ID) && (event.EVENT_TYPE && event.EVENT_TYPE.ID == EEventType.PLACE_OF_RECEIPT)) : null;
                const finalDestinationSameServiceProviderEvent = this.scope.processInfo.EVENT ? this.scope.processInfo.EVENT.find(event => (event.SERVICE_PROVIDER && this.scope.processInfo.SERVICE_PROVIDER && event.SERVICE_PROVIDER.ID == this.scope.processInfo.SERVICE_PROVIDER.ID) && (event.EVENT_TYPE && event.EVENT_TYPE.ID == EEventType.FINAL_DESTINATION)) : null;
                const receiptOrLoadSameServiceProviderEvent = this.scope.processInfo.EVENT ? this.scope.processInfo.EVENT.find(event => (event.SERVICE_PROVIDER && this.scope.processInfo.SERVICE_PROVIDER && event.SERVICE_PROVIDER.ID == this.scope.processInfo.SERVICE_PROVIDER.ID) && (event.EVENT_TYPE && (event.EVENT_TYPE.ID == EEventType.PLACE_OF_RECEIPT || event.EVENT_TYPE.ID == EEventType.LOAD))) : null;
                const dischargeSameServiceProviderEvent = this.scope.processInfo.EVENT ? this.scope.processInfo.EVENT.find(event => (event.SERVICE_PROVIDER && this.scope.processInfo.SERVICE_PROVIDER && event.SERVICE_PROVIDER.ID == this.scope.processInfo.SERVICE_PROVIDER.ID) && (event.EVENT_TYPE && event.EVENT_TYPE.ID == EEventType.DISCHARGE)) : null;
                const pickupSameServiceProviderEvent = this.scope.processInfo.EVENT ? this.scope.processInfo.EVENT.find(event => (event.SERVICE_PROVIDER && this.scope.processInfo.SERVICE_PROVIDER && event.SERVICE_PROVIDER.ID == this.scope.processInfo.SERVICE_PROVIDER.ID) && (event.EVENT_TYPE && event.EVENT_TYPE.ID == EEventType.PICK_UP)) : null;
                const deliverySameServiceProviderEvent = this.scope.processInfo.EVENT ? this.scope.processInfo.EVENT.find(event => (event.SERVICE_PROVIDER && this.scope.processInfo.SERVICE_PROVIDER && event.SERVICE_PROVIDER.ID == this.scope.processInfo.SERVICE_PROVIDER.ID) && (event.EVENT_TYPE && event.EVENT_TYPE.ID == EEventType.DELIVERY)) : null;

                // Booking Information
                this.scope.model.CUSTOMER = this.scope.processInfo.CUSTOMER ? this.scope.processInfo.CUSTOMER : null;
                this.scope.model.CARGO_TYPE = this.scope.processInfo.CARGO_TYPE ? this.scope.processInfo.CARGO_TYPE : null;
                this.scope.model.OOG = this.scope.processInfo.CARGO_DETAIL && this.scope.processInfo.CARGO_DETAIL.EQUIPMENT_LIST && this.scope.processInfo.CARGO_DETAIL.EQUIPMENT_LIST.find(x => x.EQUIPMENT.GROUP.ID === "3") ? true : false;
                this.scope.model.BOOKING_SITUATION = this.scope.processInfo.BOOKING_STATUS ? this.scope.processInfo.BOOKING_STATUS.NAME : 'Allocation';
                this.scope.model.LOADING_SITUATION = this.scope.processInfo.SITUATION ? this.scope.processInfo.SITUATION.NAME : null;

                // General Details Tab
                this.scope.model.GENERAL_DETAILS_TAB.PRODUCT = this.scope.processInfo.PRODUCT;
                this.scope.model.GENERAL_DETAILS_TAB.CARRIER = { ID: this.scope.processInfo.SERVICE_PROVIDER.ID_LEGAL_PERSON, NAME: this.scope.processInfo.SERVICE_PROVIDER.NAME, CODE: this.scope.processInfo.SERVICE_PROVIDER.SCAC, INTTRA_CODE: null, ID_PROVIDER: this.scope.processInfo.SERVICE_PROVIDER.ID };
                this.scope.model.GENERAL_DETAILS_TAB.CONTRACT_NUMBER = this.scope.processInfo.FREIGHT_CONTRACT;
                this.scope.model.GENERAL_DETAILS_TAB.FORWARDER = { ID: this.scope.processInfo.BRANCH.ID, NAME: this.scope.processInfo.BRANCH.NAME, CODE: this.scope.processInfo.BRANCH.CODE, INTTRA_CODE: null };
                this.scope.model.GENERAL_DETAILS_TAB.FORWARDER_REFERENCE_NUMBER = this.scope.processInfo.PROCESS_NUMBER;
                this.scope.model.GENERAL_DETAILS_TAB.MOVE_TYPE = { ID: this.scope.processInfo.SERVICE_TYPE.ID, NAME: this.scope.processInfo.SERVICE_TYPE.NAME, CODE: this.scope.processInfo.SERVICE_TYPE.CODE, INTTRA_CODE: null };
                this.scope.model.GENERAL_DETAILS_TAB.PORT_OF_LOAD = receiptOrLoadSameServiceProviderEvent ? receiptOrLoadSameServiceProviderEvent.LOCAL : null;
                this.scope.model.GENERAL_DETAILS_TAB.EARLIEST_DEPARTURE_DATE = receiptOrLoadSameServiceProviderEvent ? receiptOrLoadSameServiceProviderEvent.FORECAST_DATE : null;

                this.scope.model.GENERAL_DETAILS_TAB.PLACE_CARRIER_RECEIPT = placeReceiptSameServiceProviderEvent ? placeReceiptSameServiceProviderEvent.LOCAL : pickupSameServiceProviderEvent ? pickupSameServiceProviderEvent.LOCAL : receiptOrLoadSameServiceProviderEvent ? receiptOrLoadSameServiceProviderEvent.LOCAL : null;
                this.scope.model.GENERAL_DETAILS_TAB.PLACE_CARRIER_DELIVERY = deliverySameServiceProviderEvent ? deliverySameServiceProviderEvent.LOCAL : finalDestinationSameServiceProviderEvent ? finalDestinationSameServiceProviderEvent.LOCAL : dischargeSameServiceProviderEvent ? dischargeSameServiceProviderEvent.LOCAL : null;

                // Involveds
                this.scope.model.CONSIGNEE = MASTER_DIRECT ? IMPORTER : null;
                this.scope.model.NOTIFY = MASTER_DIRECT ? NOTIFY : null;
                this.scope.model.OVERSEAS_AGENT = !MASTER_DIRECT ? EXTERNAL_AGENT : null;

                this.setMoveTypeToInttra(this.scope.processInfo.EVENT, this.scope.model.GENERAL_DETAILS_TAB.CARRIER);
                this.moveTypeChange(this.scope.model.GENERAL_DETAILS_TAB.MOVE_TYPE);

                if (this.scope.model.GENERAL_DETAILS_TAB.MOVE_TYPE.CODE == 'P/D') {
                    this.scope.model.GENERAL_DETAILS_TAB.PORT_OF_DISCHARGE = finalDestinationSameServiceProviderEvent ? finalDestinationSameServiceProviderEvent.LOCAL : dischargeSameServiceProviderEvent ? dischargeSameServiceProviderEvent.LOCAL : null;
                } else {
                    this.scope.model.GENERAL_DETAILS_TAB.PORT_OF_DISCHARGE = dischargeSameServiceProviderEvent ? dischargeSameServiceProviderEvent.LOCAL : finalDestinationSameServiceProviderEvent ? finalDestinationSameServiceProviderEvent.LOCAL : null;
                }

                // Transport Tab
                if (pickupSameServiceProviderEvent && pickupSameServiceProviderEvent.TRANSPORT_MODE && !this.scope.isAmendMode) {
                    const preCarriageMode: IInttraEntity = {
                        ID: pickupSameServiceProviderEvent.TRANSPORT_MODE.ID,
                        NAME: pickupSameServiceProviderEvent.TRANSPORT_MODE.NAME,
                        CODE: pickupSameServiceProviderEvent.TRANSPORT_MODE.CODE,
                        INTTRA_CODE: null
                    };
                    this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE.MODE = preCarriageMode;
                }

                // Só preenche caso exista início e fim, nunca deverá preencher somente uma das informações.
                if (pickupSameServiceProviderEvent && pickupSameServiceProviderEvent.LOCAL && receiptOrLoadSameServiceProviderEvent && receiptOrLoadSameServiceProviderEvent.LOCAL) {
                    this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE.START = pickupSameServiceProviderEvent ? pickupSameServiceProviderEvent.LOCAL : null;
                    this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE.ETD = pickupSameServiceProviderEvent ? pickupSameServiceProviderEvent.FORECAST_DATE : null;
                    this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE.END = receiptOrLoadSameServiceProviderEvent ? receiptOrLoadSameServiceProviderEvent.LOCAL : null;
                    this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE.ETA = receiptOrLoadSameServiceProviderEvent ? receiptOrLoadSameServiceProviderEvent.FORECAST_DATE : null;
                }

                const onCarriageEnd = finalDestinationSameServiceProviderEvent ? finalDestinationSameServiceProviderEvent : deliverySameServiceProviderEvent;
                if (onCarriageEnd && onCarriageEnd.PROCESS_EVENT_TRANSPORT_MODE && !this.scope.isAmendMode) {
                    this.scope.model.TRANSPORT_TAB.ON_CARRIAGE.MODE = {
                        ID: onCarriageEnd.PROCESS_EVENT_TRANSPORT_MODE.ID,
                        NAME: onCarriageEnd.PROCESS_EVENT_TRANSPORT_MODE.NAME,
                        CODE: onCarriageEnd.PROCESS_EVENT_TRANSPORT_MODE.CODE,
                        INTTRA_CODE: null
                    };
                }

                if (dischargeSameServiceProviderEvent && dischargeSameServiceProviderEvent.LOCAL && onCarriageEnd && onCarriageEnd.LOCAL) {
                    this.scope.model.TRANSPORT_TAB.ON_CARRIAGE.START = dischargeSameServiceProviderEvent ? dischargeSameServiceProviderEvent.LOCAL : null;
                    this.scope.model.TRANSPORT_TAB.ON_CARRIAGE.ETD = dischargeSameServiceProviderEvent ? dischargeSameServiceProviderEvent.FORECAST_DATE : null;
                    this.scope.model.TRANSPORT_TAB.ON_CARRIAGE.END = onCarriageEnd ? onCarriageEnd.LOCAL : null;
                    this.scope.model.TRANSPORT_TAB.ON_CARRIAGE.ETA = onCarriageEnd ? onCarriageEnd.FORECAST_DATE : null;
                }

                // Transshipments
                if (this.scope.processInfo && this.scope.processInfo.EVENT) {
                    const transshipments: IMainCarriage[] = [];

                    const eventsInOrder: EVENT[] = [];
                    const dischargeTransshipmentEvent = this.scope.processInfo.EVENT.filter(x => x.EVENT_TYPE.ID == EEventType.DISCHARGE_TRANSHIPMENT);
                    const loadTransshipmentEvent = this.scope.processInfo.EVENT.filter(x => x.EVENT_TYPE.ID == EEventType.LOAD_TRANSHIPMENT);
                    const dischargeItemEvent = this.scope.processInfo.EVENT.find(x => x.EVENT_TYPE.ID == EEventType.DISCHARGE);

                    if (dischargeTransshipmentEvent.length > 0 && loadTransshipmentEvent.length > 0) {

                        for (const itemOfLoadTS of loadTransshipmentEvent) {
                            const localOfDischarge = itemOfLoadTS.LOCAL ? itemOfLoadTS.LOCAL : null;
                            const loadFound = dischargeTransshipmentEvent.find(x => x.LOCAL.ID == localOfDischarge.ID);

                            if (loadFound) {
                                eventsInOrder.push(loadFound);
                            }
                        }

                        if (eventsInOrder) {
                            for (var i = 0; i < eventsInOrder.length - 1; i++) {
                                const splitTransport = (eventsInOrder[0].TRANSPORT_MODE) ? eventsInOrder[i].TRANSPORT_MODE.NAME.split("|", 2) : null;
                                const vesselName = (splitTransport) ? ((splitTransport[i]) ? splitTransport[i].toUpperCase() : null) : null;
                                const voyage = (splitTransport) ? splitTransport[1] : null;

                                const itemForTransshipment: IMainCarriage = {
                                    VESSEL: {
                                        ID: eventsInOrder[i].TRANSPORT_MODE ? eventsInOrder[i].TRANSPORT_MODE.ID : null,
                                        NAME: vesselName,
                                    },
                                    SERVICE: this.scope.processInfo.SERVICES[i].MARITIME_SERVICE.NAME,
                                    VOYAGE: voyage,
                                    PORT_OF_LOAD: {
                                        ID: eventsInOrder[i].LOCAL ? eventsInOrder[i].LOCAL.ID : null,
                                        NAME: eventsInOrder[i].LOCAL ? eventsInOrder[i].LOCAL.NAME : null,
                                        CODE: eventsInOrder[i].LOCAL ? eventsInOrder[i].LOCAL.CODE : null,
                                    },
                                    PORT_OF_DISCHARGE: {
                                        ID: eventsInOrder[i + 1].LOCAL ? eventsInOrder[i + 1].LOCAL.ID : null,
                                        NAME: eventsInOrder[i + 1].LOCAL ? eventsInOrder[i + 1].LOCAL.NAME : null,
                                        CODE: eventsInOrder[i + 1].LOCAL ? eventsInOrder[i + 1].LOCAL.CODE : null,
                                    },
                                    LOADING_DATE: eventsInOrder[i].FORECAST_DATE ? eventsInOrder[i].FORECAST_DATE : null,
                                    DISCHARGE_DATE: eventsInOrder[i + 1].FORECAST_DATE ? eventsInOrder[i + 1].FORECAST_DATE : null
                                };
                                transshipments.push(itemForTransshipment);
                            }

                            const index = eventsInOrder.length - 1;
                            const splitTransport = (eventsInOrder[index].TRANSPORT_MODE) ? eventsInOrder[index].TRANSPORT_MODE.NAME.split("|", 2) : null;
                            const vesselName = (splitTransport) ? ((splitTransport[i]) ? splitTransport[i].toUpperCase() : null) : null;
                            const voyage = (splitTransport) ? splitTransport[1] : null;

                            const itemForTransshipment: IMainCarriage = {
                                VESSEL: {
                                    ID: eventsInOrder[index].TRANSPORT_MODE ? eventsInOrder[index].TRANSPORT_MODE.ID : null,
                                    NAME: vesselName,
                                },
                                SERVICE: this.scope.processInfo.SERVICES[0].MARITIME_SERVICE.NAME,
                                VOYAGE: voyage,
                                PORT_OF_LOAD: {
                                    ID: eventsInOrder[index].LOCAL ? eventsInOrder[index].LOCAL.ID : null,
                                    NAME: eventsInOrder[index].LOCAL ? eventsInOrder[index].LOCAL.NAME : null,
                                    CODE: eventsInOrder[index].LOCAL ? eventsInOrder[index].LOCAL.CODE : null,
                                },
                                PORT_OF_DISCHARGE: {
                                    ID: dischargeItemEvent.LOCAL ? dischargeItemEvent.LOCAL.ID : null,
                                    NAME: dischargeItemEvent.LOCAL ? dischargeItemEvent.LOCAL.NAME : null,
                                    CODE: dischargeItemEvent.LOCAL ? dischargeItemEvent.LOCAL.CODE : null,
                                },
                                LOADING_DATE: eventsInOrder[i].FORECAST_DATE ? eventsInOrder[i].FORECAST_DATE : null,
                                DISCHARGE_DATE: dischargeItemEvent.FORECAST_DATE ? dischargeItemEvent.FORECAST_DATE : null
                            };
                            transshipments.push(itemForTransshipment);
                        }
                    }

                    this.scope.model.TRANSPORT_TAB.TRANSSHIPMENT = transshipments;
                }

                // Cargo Tab
                const sumEquip = this.scope.processInfo.CARGO_DETAIL.EQUIPMENT_LIST.reduce((total, equip) => total + equip.QUANTITY, 0);

                if (this.scope.model.CARGO_TAB.CARGO_DETAIL && this.scope.processInfo.CARGO_DETAIL && this.scope.processInfo.CARGO_DETAIL.GROSS_WEIGHT) {
                    this.scope.model.CARGO_TAB.CARGO_DETAIL = this.scope.model.CARGO_TAB.CARGO_DETAIL.map(cargoDetail => {
                        return { ...cargoDetail, GROSS_WEIGHT: this.scope.processInfo.CARGO_DETAIL.GROSS_WEIGHT * sumEquip };
                    });
                }

                this.scope.model.CARGO_TAB.CARGO_DETAIL = [{ CBM: this.scope.processInfo.CARGO_DETAIL ? this.scope.processInfo.CARGO_DETAIL.CBM : null, GROSS_WEIGHT: this.scope.processInfo.CARGO_DETAIL ? this.scope.processInfo.CARGO_DETAIL.GROSS_WEIGHT * sumEquip : null, COMMODITY: this.scope.processInfo.COMMODITY_SUMMARY && this.scope.processInfo.COMMODITY_SUMMARY.COMMODITY_ITEM ? this.scope.processInfo.COMMODITY_SUMMARY.COMMODITY_ITEM.filter(commodityItem => commodityItem.COMMODITY != null).map(commodityItem => { return { ID: commodityItem.COMMODITY.ID, NAME: commodityItem.COMMODITY.NAME, CODE: commodityItem.COMMODITY.HS_CODE } }) : null }];
                if (!this.scope.model.CARGO_TAB.IS_DANGEROUS_CARGO) this.scope.model.CARGO_TAB.IS_DANGEROUS_CARGO = this.scope.processInfo.CARGO_DETAIL.IS_DANGER_CARGO;
                if (!this.scope.model.CARGO_TAB.DANGEROUS_CARGO) this.scope.model.CARGO_TAB.DANGEROUS_CARGO = this.scope.processInfo.CARGO_DETAIL && this.scope.processInfo.CARGO_DETAIL.DANGER_CARGO ? this.scope.processInfo.CARGO_DETAIL.DANGER_CARGO.map(dangerCargo => { return { UNITY: dangerCargo.UNITY, CLASS: dangerCargo.CLASS, PACKING_GROUP: dangerCargo.PACKING_GROUP, OUTER_PACKAGE_TYPE: dangerCargo.OUTER_PACKAGE_TYPE ? dangerCargo.OUTER_PACKAGE_TYPE : null, OUTER_PACKAGE_COUNT: dangerCargo.OUTER_PACKAGE_COUNT ? dangerCargo.OUTER_PACKAGE_COUNT : null, PROPER_SHIPPING: dangerCargo.PROPER_SHIPPING ? dangerCargo.PROPER_SHIPPING : null } }) : [];
                if (!this.scope.model.CARGO_TAB.EQUIPMENT_LIST) this.scope.model.CARGO_TAB.EQUIPMENT_LIST = await this.fillEquipmentList();

                // Contact
                const contactCell = this.scope.processInfo && this.scope.processInfo.CONTACT && this.scope.processInfo.CONTACT.INTERNAL && this.scope.processInfo.CONTACT.INTERNAL.find(internal => internal.CELL && internal.CELL.ID == EBookingContactCell.UNIT_CORPORATIVE);
                this.scope.model.CONTACT_CELL = (contactCell && contactCell.USER) ? contactCell.USER : null;
            } else {
                await this.initModel();
                await this.getAndUpdateForwarder();
            }

            await this.bookingAuth();

            this.timeout(() => {
                this.scope.selectorValidity("carrier");
                this.scope.selectorValidity("product");
                this.scope.selectorValidity("contractNumber");
                this.scope.selectorValidity("moveType");
                this.scope.selectorValidity("portOfLoad");
                this.scope.selectorValidity("portOfDischarge");
                this.scope.selectorValidity("placeCarrierReceipt");
                this.scope.selectorValidity("placeCarrierDelivery");
            }, 100);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private moveTypeChange(moveType: IInttraEntity) {
        let isPlaceCarrierReceiptMandatory = false;
        let isPlaceCarrierDeliveryMandatory = false;
        if (this.scope.isProcessOrigin && moveType && (moveType.ID == EMoveTypeId.PORT_TO_TERMINAL || moveType.ID == EMoveTypeId.TERMINAL_TO_PORT || moveType.ID == EMoveTypeId.TERMINAL_TO_TERMINAL)) {
            isPlaceCarrierReceiptMandatory = this.scope.processInfo && this.scope.processInfo.EVENT && this.scope.processInfo.EVENT.length ? this.scope.processInfo.EVENT.some(event => (event.SERVICE_PROVIDER && this.scope.processInfo.SERVICE_PROVIDER && event.SERVICE_PROVIDER.ID == this.scope.processInfo.SERVICE_PROVIDER.ID) && event.EVENT_TYPE && event.EVENT_TYPE.ID == EEventType.PICK_UP) : false;
            isPlaceCarrierDeliveryMandatory = this.scope.processInfo && this.scope.processInfo.EVENT && this.scope.processInfo.EVENT.length ? this.scope.processInfo.EVENT.some(event => (event.SERVICE_PROVIDER && this.scope.processInfo.SERVICE_PROVIDER && event.SERVICE_PROVIDER.ID == this.scope.processInfo.SERVICE_PROVIDER.ID) && event.EVENT_TYPE && event.EVENT_TYPE.ID == EEventType.DELIVERY) : false;
        }
        this.scope.isPlaceCarrierReceiptMandatory = isPlaceCarrierReceiptMandatory;
        this.scope.isPlaceCarrierDeliveryMandatory = isPlaceCarrierDeliveryMandatory;
    }

    private setMoveTypeToInttra(event: EVENT[], carrier: IInttraEntityProvider) {
        let checkMoveType: string;
        const sameServiceProvider = event.filter(x => x.SERVICE_PROVIDER && x.SERVICE_PROVIDER.ID == carrier.ID_PROVIDER);
        const pickup = sameServiceProvider.find(x => x.EVENT_TYPE.ID == EEventType.PICK_UP);
        const placeOfReceipt = sameServiceProvider.find(x => x.EVENT_TYPE.ID == EEventType.PLACE_OF_RECEIPT);
        const load = sameServiceProvider.find(x => x.EVENT_TYPE.ID == EEventType.LOAD);
        const discharge = sameServiceProvider.find(x => x.EVENT_TYPE.ID == EEventType.DISCHARGE);
        const finalDestination = sameServiceProvider.find(x => x.EVENT_TYPE.ID == EEventType.FINAL_DESTINATION);
        const delivery = sameServiceProvider.find(x => x.EVENT_TYPE.ID == EEventType.DELIVERY);

        if (pickup && load && discharge) {
            if (!delivery && !finalDestination) {
                checkMoveType = 'D/P';
            }
            if (delivery) {
                checkMoveType = 'D/D';
            }
            if (finalDestination) {
                checkMoveType = 'D/T';
            }
        }

        if (!pickup && placeOfReceipt && load && discharge) {
            if (delivery) {
                checkMoveType = 'T/D';
            } else if (finalDestination) {
                checkMoveType = 'T/T';
            } else {
                checkMoveType = 'T/P';
            }
        }


        if (!pickup && !placeOfReceipt && load && discharge) {
            if (delivery) {
                checkMoveType = 'P/D';
            } else if (finalDestination) {
                checkMoveType = 'P/T';
            } else {
                checkMoveType = 'P/P';
            }
        }

        let newMoveType = this.scope.moveTypeList.find(x => x.CODE == checkMoveType);
        let newInttraCode = null;
        if (checkMoveType == 'D/D') {
            newInttraCode = EInttraMoveType.DOOR_TO_DOOR;
        }

        if (checkMoveType == 'D/P' || checkMoveType == 'D/T') {
            newInttraCode = EInttraMoveType.DOOR_TO_PORT;
        }

        if (checkMoveType == 'T/T' || checkMoveType == 'T/P' || checkMoveType == 'P/T' || checkMoveType == 'P/P') {
            newInttraCode = EInttraMoveType.PORT_TO_PORT;
        }

        if (checkMoveType == 'P/D' || checkMoveType == 'T/D') {
            newInttraCode = EInttraMoveType.PORT_TO_DOOR;
        }

        if (newMoveType && newInttraCode) {
            this.scope.model.GENERAL_DETAILS_TAB.MOVE_TYPE = {
                ID: newMoveType.ID.toString(),
                NAME: newMoveType.NAME,
                CODE: newMoveType.CODE,
                INTTRA_CODE: newInttraCode
            };
        }
    }

    private async openPeopleDetails(entity: ENTITY, agentEntity?: ENTITY_AGENT) {
        try {
            if (!entity && !agentEntity) return;
            const agentFields = agentEntity ? `
            <div class="row form-group">
                <div class="col-lg-6">
                    <label><b>${this.formService.getTranslate('ENTITY.SHIPPER_MASTER')}</b></label>
                    <textarea class="form-control" rows="6" style="resize:none" readonly>${agentEntity.SHIPPER_MASTER ? agentEntity.SHIPPER_MASTER : ''}</textarea>
                </div>
                <div class="col-lg-6">
                    <label><b>${this.formService.getTranslate('ENTITY.CONSIGNEE_MASTER')}</b></label>
                    <textarea class="form-control" rows="6" style="resize:none" readonly>${agentEntity.CNEE_MASTER ? agentEntity.CNEE_MASTER : ''}</textarea>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-lg-6">
                    <label><b>${this.formService.getTranslate('ENTITY.NOTIFY_MASTER')}</b></label>
                    <textarea class="form-control" rows="6" style="resize:none" readonly>${agentEntity.NOTIFY_MASTER ? agentEntity.NOTIFY_MASTER : ''}</textarea>
                </div>
                <div class="col-lg-6">
                    <label><b>${this.formService.getTranslate('ENTITY.REMARKS_MASTER')}</b></label>
                    <textarea class="form-control" rows="6" style="resize:none" readonly>${agentEntity.REMARKS_MASTER ? agentEntity.REMARKS_MASTER : ''}</textarea>
                </div>
            </div>
            ` : '';
            const body = `
            <div class="row form-group">
                <div class="col-lg-12">
                    <label><b>${this.formService.getTranslate('GENERAL.TAX_ID')}</b></label>
                    <span class="form-control" readonly>${agentEntity ? agentEntity.TAXPAYER_NUMBER : entity.TAXPAYER_NUMBER}</span>
                </div>
            </div>
            ${agentFields}
            <div class="row">
                <div class="col-lg-12">
                    <label><b>${this.formService.getTranslate('ENTITY.POSTAL_ADDRESS')}</b></label>
                    <textarea class="form-control" rows="6" style="resize:none" readonly>${agentEntity && agentEntity.AGENT_CORRESPONDENCE ? agentEntity.AGENT_CORRESPONDENCE : entity && entity.ADDRESS && entity.ADDRESS.CORRESPONDENCE ? entity.ADDRESS.CORRESPONDENCE : ''}</textarea>
                </div>
            </div>`;

            this.ModalService.showModalInfo({}, {
                actionButtonText: 'GENERAL.CLOSE',
                headerText: agentEntity ? agentEntity.NAME : entity.NAME,
                bodyText: this.$sce.trustAsHtml(body)
            });

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private dangerousCargoChange() {
        if (!this.scope.model.CARGO_TAB.IS_DANGEROUS_CARGO) {
            this.scope.model.CARGO_TAB.DANGEROUS_CARGO = [{
                UNITY: null,
                CLASS: null,
                OUTER_PACKAGE_COUNT: null,
                OUTER_PACKAGE_TYPE: null,
                PACKING_GROUP: null,
                PROPER_SHIPPING: null
            }];
        }
        this.timeout(() => {
            this.scope.selectorValidity("class");
            this.scope.selectorValidity("outerPackageType");
            this.scope.selectorValidity("packingGroup");
        }, 100);
    }

    private addCargoDetail(): void {
        try {
            if (!this.scope.model.CARGO_TAB.CARGO_DETAIL) this.scope.model.CARGO_TAB.CARGO_DETAIL = [];
            const cargoDetail: ICargoDetail = {
                COMMODITY: null,
                GROSS_WEIGHT: null,
                CBM: null
            }
            this.scope.model.CARGO_TAB.CARGO_DETAIL.push(cargoDetail);
            const lastIndex = this.scope.model.CARGO_TAB.CARGO_DETAIL.length - 1;
            this.timeout(() => {
                this.scope.selectorValidity("commodity_" + lastIndex);
            }, 100);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async removeCargoDetail(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.scope.model.CARGO_TAB.CARGO_DETAIL && this.scope.model.CARGO_TAB.CARGO_DETAIL.length > 0) {
                this.block();
                if (this.scope.model.CARGO_TAB.CARGO_DETAIL.length == 1) this.scope.model.CARGO_TAB.CARGO_DETAIL = null;
                else this.scope.model.CARGO_TAB.CARGO_DETAIL.splice(index, 1);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private addDangerousCargo(): void {
        try {
            if (!this.scope.model.CARGO_TAB.DANGEROUS_CARGO) this.scope.model.CARGO_TAB.DANGEROUS_CARGO = [];
            const dangerousCargo: IDangerCargo = {
                UNITY: null,
                CLASS: null,
                OUTER_PACKAGE_COUNT: null,
                OUTER_PACKAGE_TYPE: null,
                PACKING_GROUP: null,
                PROPER_SHIPPING: null
            }
            this.scope.model.CARGO_TAB.DANGEROUS_CARGO.push(dangerousCargo);
            const lastIndex = this.scope.model.CARGO_TAB.DANGEROUS_CARGO.length - 1;
            this.timeout(() => {
                this.scope.selectorValidity("dangerousCargoClass_" + lastIndex);
                this.scope.selectorValidity("dangerousCargoOuterPackageType_" + lastIndex);
                this.scope.selectorValidity("dangerousCargoPackingGroup_" + lastIndex);
            }, 100);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async removeDangerousCargo(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.scope.model.CARGO_TAB.DANGEROUS_CARGO && this.scope.model.CARGO_TAB.DANGEROUS_CARGO.length > 0) {
                this.block();
                if (this.scope.model.CARGO_TAB.DANGEROUS_CARGO.length == 1) this.scope.model.CARGO_TAB.DANGEROUS_CARGO = null;
                else this.scope.model.CARGO_TAB.DANGEROUS_CARGO.splice(index, 1);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private addContainer(): void {
        try {
            if (!this.scope.model.CARGO_TAB.EQUIPMENT_LIST) this.scope.model.CARGO_TAB.EQUIPMENT_LIST = [];
            const meter = this.scope.unitOfMeasurementList && this.scope.unitOfMeasurementList.length ? this.scope.unitOfMeasurementList.find(obj => obj.ID == EUnitOfMeasurementId.METER) : null;
            const container: IEquipmentList = {
                QUANTITY: null,
                EQUIPMENT: null,
                COMMENTS: null,
                SHIPPER_OWNED: null,
                OUT_OF_GAUGE: { LENGTH: null, WIDTH: null, HEIGTH: null, UOM: meter },
                TEMPERATURE: null
            }
            this.scope.model.CARGO_TAB.EQUIPMENT_LIST.push(container);
            const lastIndex = this.scope.model.CARGO_TAB.EQUIPMENT_LIST.length - 1;
            this.timeout(() => {
                this.scope.selectorValidity("containerType_" + lastIndex);
                this.scope.selectorValidity("UOM_" + lastIndex);
            }, 100);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async removeContainer(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.scope.model.CARGO_TAB.EQUIPMENT_LIST && this.scope.model.CARGO_TAB.EQUIPMENT_LIST.length > 0) {
                this.block();
                if (this.scope.model.CARGO_TAB.EQUIPMENT_LIST.length == 1) this.scope.model.CARGO_TAB.EQUIPMENT_LIST = null;
                else this.scope.model.CARGO_TAB.EQUIPMENT_LIST.splice(index, 1);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private addPayer(): void {
        try {
            if (!this.scope.model.PAYER_INFORMATION) this.scope.model.PAYER_INFORMATION = [];
            const payer: IPayerInformation = {
                CHARGE_TYPE: {
                    ID: "2",
                    NAME: "Basic Freight",
                    INTTRA_CODE: EPayerChargeType.OCEAN_FREIGHT
                },
                PAYER: {
                    ID: "1",
                    NAME: EPartyRole.BOOKER,
                    INTTRA_CODE: EPartyRole.BOOKER
                },
                PAYMENT_LOCATION: null,
                PAYMENT_TERM: this.scope.processInfo && this.scope.processInfo.MASTER_PAYMENT ? {
                    ID: this.scope.processInfo.MASTER_PAYMENT.ID,
                    NAME: this.scope.processInfo.MASTER_PAYMENT.NAME,
                    INTTRA_CODE: null
                } : null
            }
            this.scope.model.PAYER_INFORMATION.push(payer);
            const lastIndex = this.scope.model.PAYER_INFORMATION.length - 1;

            if (this.scope.model.PAYER_INFORMATION[lastIndex].PAYMENT_TERM) {
                this.checkPaymentLocation(this.scope.model.PAYER_INFORMATION[lastIndex].PAYMENT_TERM, lastIndex);
            }

            this.timeout(() => {
                this.scope.selectorValidity("chargeType" + lastIndex);
                this.scope.selectorValidity("paymentTerm" + lastIndex);
                this.scope.selectorValidity("payer" + lastIndex);
                this.scope.selectorValidity("paymentLocation" + lastIndex);
            }, 100);

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async checkRemarks(): Promise<void> {
        try {

            this.block();

            // Comments
            this.scope.model.COMMENTS = '';

            if (this.scope.processInfo.CARGO_DETAIL && this.scope.processInfo.CARGO_DETAIL.FOODGRADE) this.scope.model.COMMENTS += this.scope.model.COMMENTS && this.scope.model.COMMENTS.length > 0 ? ', FOODGRADE, //' : 'FOODGRADE // ';
            if (this.scope.processInfo.CARGO_DETAIL && this.scope.processInfo.CARGO_DETAIL.SUPERTESTED) this.scope.model.COMMENTS += this.scope.model.COMMENTS && this.scope.model.COMMENTS.length > 0 ? ', HIGHTESTED // ' : 'HIGHTESTED // ';

            if (this.scope.processInfo.PROCESS_TYPE && this.scope.processInfo.PROCESS_TYPE.ID == EProcessTypeId.LIQUID) {
                if (this.scope.processInfo.CARGO_DETAIL && this.scope.processInfo.CARGO_DETAIL.FLEXITANK) {
                    this.scope.model.COMMENTS += this.scope.model.COMMENTS && this.scope.model.COMMENTS.length > 0 ? 'FLEXITANK // ' : 'FLEXITANK // ';
                }
                if (this.scope.processInfo.CARGO_DETAIL && !this.scope.processInfo.CARGO_DETAIL.FLEXITANK) {
                    this.scope.model.COMMENTS += this.scope.model.COMMENTS && this.scope.model.COMMENTS.length > 0 ? 'NON FLEXITANK // ' : 'NON FLEXITANK // ';
                }
            }

            if (this.scope.model.GENERAL_DETAILS_TAB.FORWARDER && this.scope.model.GENERAL_DETAILS_TAB.FORWARDER.ID) {
                switch (this.scope.model.GENERAL_DETAILS_TAB.FORWARDER.ID) {
                    case "181": // Itajaí
                        this.scope.model.COMMENTS += 'CNPJ: 43.603.041/0001-85 // ';
                        break;
                    case "5361": // Navegantes
                        this.scope.model.COMMENTS += 'CNPJ: 48.151.581/0001-99 // ';
                        break;
                    case "184": // Curitiba
                        this.scope.model.COMMENTS += 'CNPJ: 43.662.598/0001-97 // ';
                        break;
                    default:
                        this.scope.model.COMMENTS += 'CNPJ: 43.603.041/0001-85 // ';
                        break;
                }
            }

            if (this.scope.processInfo.CARGO_DETAIL.IS_DANGER_CARGO) {
                this.scope.model.COMMENTS += this.scope.model.COMMENTS && this.scope.model.COMMENTS.length > 0 ? 'IMO Cargo // ' : 'IMO Cargo // ';
            } else {
                this.scope.model.COMMENTS += this.scope.model.COMMENTS && this.scope.model.COMMENTS.length > 0 ? 'NON-IMO Cargo // ' : 'NON-IMO Cargo // ';
            }
            let objectSearchConfig = {
                serviceProvider: this.scope.processInfo.SERVICE_PROVIDER,
                origin: this.scope.model.GENERAL_DETAILS_TAB.PORT_OF_LOAD,
                destination: this.scope.model.GENERAL_DETAILS_TAB.PORT_OF_DISCHARGE,
                contractType: this.scope.processInfo.CONTRACT_TYPE,
                account: this.scope.processInfo.CUSTOMER,
                countryOrigin: this.scope.processInfo.ORIGIN.COUNTRY,
                countryDestination: this.scope.processInfo.DESTINATION.COUNTRY
            };

            const remarksConfig = await this.OperationalService.post('/bookingRemarksConfig/getRemarksConfig', objectSearchConfig, 30000);
            if (remarksConfig && remarksConfig.data && remarksConfig.data.data) {
                const remarks = remarksConfig.data.data;

                if (remarks) {
                    this.scope.model.COMMENTS += remarks;
                }
            }

        } catch (ex) {
            this.notifyError(ex);
        } finally {
            this.unblock();
        }
    }

    private async removePayer(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.scope.model.PAYER_INFORMATION && this.scope.model.PAYER_INFORMATION.length > 0) {
                this.block();
                if (this.scope.model.PAYER_INFORMATION.length == 1) this.scope.model.PAYER_INFORMATION = null;
                else this.scope.model.PAYER_INFORMATION.splice(index, 1);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getGenericList(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async getProcessByNumber(processNumber: string): Promise<Process> {
        let process: Process = null;
        try {
            if (!processNumber) this.notifyError("processNumber is null.");
            this.block();
            const timeout = 120000;
            const processRequest = await this.OperationalService.get(`/process/byNumber/${processNumber}/${timeout}`, timeout);
            if (processRequest && processRequest.data && processRequest.data.data && processRequest.data.data.length) process = processRequest.data.data[0];
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return process;
        }
    }

    private async getProcessListByName(processNumber: string): Promise<IInttraEntity[]> {
        let result: IInttraEntity[] = [];
        try {
            this.block();
            const timeout = 120000;
            const process = await this.OperationalService.post('/process/selector', { forwardingSituationToExclude: ["5", "7"], term: processNumber, timeout }, timeout);
            if (process && process.data && process.data.data) result = process.data.data;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getProviderListByName(search?: string): Promise<IInttraEntity[]> {
        let result: IInttraEntity[] = [];
        try {
            this.block();
            const carriers = await this.ProductService.post({ route: '/provider/list/custom', data: { search: search }, timeout: 30000 });
            if (carriers && carriers.data && carriers.data.data) result = carriers.data.data.map(carrier => { return { ID: carrier.ID_LEGAL_PERSON, NAME: carrier.NAME, CODE: carrier.SCAC_IATA, INTTRA_CODE: null, ID_PROVIDER: carrier.ID } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getFreightContractListByName(search?: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            this.block();
            const freightContracts = await this.ProductService.post({ route: '/freightContract/listByFullName', data: { name: search }, timeout: 30000 });
            if (freightContracts && freightContracts.data && freightContracts.data.data && freightContracts.data.data.data) result = freightContracts.data.data.data.map(freightContract => { return { ID: freightContract.ID, NAME: freightContract.NAME } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getRoutingPointListByName(search?: string, types?: string[]): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            this.block();
            const routingPoints = await this.ProductService.post({ route: '/routingPoint/list/custom', data: { name: search, types: types }, timeout: 30000 });
            if (routingPoints && routingPoints.data && routingPoints.data.data && routingPoints.data.data.data) result = routingPoints.data.data.data.map(routingPoint => { return { ID: routingPoint.ID, NAME: routingPoint.NAME, CODE: routingPoint.CODE, TYPE: routingPoint.TYPE.NAME } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getCommodityListByName(search?: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            this.block();
            const commodities = await this.ProductService.post({ route: '/commodity/list/custom', data: { name: search }, timeout: 30000 });
            if (commodities && commodities.data && commodities.data.data) result = commodities.data.data.map(commodity => { return { ID: commodity.ID, NAME: commodity.NAME, CODE: commodity.HS_CODE } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getMoveTypeList(): Promise<IMoveTypeModel[]> {
        let moveTypeList: IMoveTypeModel[] = [];
        const result = await this.ProductService.get({ route: '/moveType/list/full', timeout: 30000 });
        if (result && result.data && result.data.data) moveTypeList = result.data.data;
        return moveTypeList;
    }

    private async getEquipmentListByNameOrCode(search?: string): Promise<IEquipmentInterface[]> {
        let result: IEquipmentInterface[] = [];
        try {
            this.block();
            const equipments = await this.ProductService.post({ route: '/equipment/list', data: { search: search }, timeout: 30000 });
            if (equipments && equipments.data && equipments.data.data && equipments.data.data.data) result = equipments.data.data.data.map(equipment => { return { ID: equipment.ID, NAME: equipment.NAME, CODE: equipment.CODE, INTTRA_CODE: null, TEU: equipment.TEU, GROUP: equipment.GROUP, ISO: equipment.ISO } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async fillEquipmentList(): Promise<IEquipmentList[]> {
        const equipmentList: IEquipmentList[] = [];
        if (this.scope.processInfo.CARGO_DETAIL && this.scope.processInfo.CARGO_DETAIL.EQUIPMENT_LIST) {
            for (const equips of this.scope.processInfo.CARGO_DETAIL.EQUIPMENT_LIST) {
                const iso = await this.getEquipmentISO(equips.EQUIPMENT.NAME);
                const equipmentItem: IEquipmentList = {
                    QUANTITY: equips.QUANTITY,
                    EQUIPMENT: { ID: equips.EQUIPMENT.ID, NAME: equips.EQUIPMENT.NAME, CODE: equips.EQUIPMENT.CODE, INTTRA_CODE: null, TEU: equips.EQUIPMENT.TEU, GROUP: equips.EQUIPMENT.GROUP, ISO: iso },
                    COMMENTS: null,
                    SHIPPER_OWNED: this.scope.processInfo.CARGO_DETAIL.CARGO_SOC,
                    OUT_OF_GAUGE: {
                        LENGTH: null,
                        WIDTH: null,
                        HEIGTH: null,
                        UOM: null,
                    },
                    TEMPERATURE: null,
                }
                equipmentList.push(equipmentItem);
            }
        }
        return equipmentList;
    }

    private async getEquipmentISO(search: string): Promise<string> {
        let isoCode: string;
        try {
            const equipments = await this.getEquipmentListByNameOrCode(search);
            isoCode = equipments.find(x => x.NAME == search).ISO;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            return isoCode;
        }
    }

    // tab navigation
    private async handleNavigationChange(currentStep: number): Promise<boolean> {
        const nextStep = currentStep + 1;
        let success = true;
        try {
            this.resetErrorsAndWarnings();
            if (currentStep == ETabStep.TRANSPORT) {
                success = this.validateTransportTab();
            }

            if (currentStep == ETabStep.CARGO) {
                success = this.validateCargoTab();
            }

            if (success && nextStep == ETabStep.CARGO) {
                success = await this.initCargoTab();
            }
            if (success && nextStep === ETabStep.TRANSPORT) {
                if (this.scope.isCarrierBookingProcessDifferent) {
                    this.notifyError(this.getTranslate('OPERATIONAL.DIFFERENT_CARRIER_BOOKING'));
                    success = false;
                }

                if (!this.scope.loggedInttraCorporateBranch) {
                    this.notifyError('No Inttra Corporate Branch authentication informed.');
                    success = false;
                }

                if (success) {
                    success = await this.initTransportTab();
                }
            }
            if (success && nextStep === ETabStep.CONFIRMATION) {
                success = await this.initConfirmationTab();
            }
            await this.scope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
        return success;
    }

    private validateTransportTab(): boolean {
        const hasOptionSelected = this.scope.oceanScheduleOptions && this.scope.oceanScheduleOptions.length && this.scope.oceanScheduleOptions.some(option => option.selected);

        if (!hasOptionSelected) this.notifyError(this.getTranslate('OPERATIONAL.OPTION_MUST_BE_SELECTED'));

        return hasOptionSelected;
    }

    private validateCargoTab(): boolean {

        const hasCargoList = this.scope.model.CARGO_TAB.CARGO_DETAIL && this.scope.model.CARGO_TAB.CARGO_DETAIL.length > 0;
        if (!hasCargoList) this.notifyError(this.getTranslate('OPERATIONAL.CARGO_MUST_BE_INFORMED'));

        const hasEquipmentList = this.scope.model.CARGO_TAB.EQUIPMENT_LIST && this.scope.model.CARGO_TAB.EQUIPMENT_LIST.length > 0;
        if (!hasEquipmentList) this.notifyError(this.getTranslate('OPERATIONAL.EQUIPMENT_MUST_BE_INFORMED'));

        return hasCargoList && hasEquipmentList;
    }

    private async initTransportTab(): Promise<boolean> {
        let success = true;
        this.scope.oceanScheduleOptions = [];
        this.scope.isPreCarriageModeMandatory = false;
        this.scope.isOnCarriageModeMandatory = false;

        try {
            this.block();
            const generalDetailsTab = this.scope.model.GENERAL_DETAILS_TAB;
            const requestData: IInttraOceanSchedulesRequest = {
                scacs: generalDetailsTab.CARRIER ? generalDetailsTab.CARRIER.CODE : null,
                originPort: generalDetailsTab.PORT_OF_LOAD ? generalDetailsTab.PORT_OF_LOAD.CODE : null,
                destinationPort: generalDetailsTab.PORT_OF_DISCHARGE ? generalDetailsTab.PORT_OF_DISCHARGE.CODE : null,
                searchDate: generalDetailsTab.EARLIEST_DEPARTURE_DATE,
                weeksOut: "4",
                searchDateType: EInttraSearchDateType.DEPARTURE
            };
            const oceanSchedulesRequest = await this.ExternalService.post({
                route: '/inttra/oceanSchedule', data: { data: requestData }
            });

            if (oceanSchedulesRequest && oceanSchedulesRequest.data && oceanSchedulesRequest.data.data) {
                const oceanSchedulesResult: IInttraOceanSchedulesResult[] = oceanSchedulesRequest.data.data;

                const loadEvent = this.scope.processInfo && this.scope.processInfo.EVENT ? this.scope.processInfo.EVENT.find(event => event.EVENT_TYPE && event.EVENT_TYPE.ID == EEventType.LOAD) : null;
                if (oceanSchedulesResult.length > 0) {
                    let shipLoad: ISelectorModel = null;
                    if (loadEvent && loadEvent.TRANSPORT_MODE) shipLoad = await this.getShipByIdOrImoNumber(loadEvent.TRANSPORT_MODE.ID);
                    this.scope.oceanScheduleOptions = oceanSchedulesResult.map(result => {
                        return {
                            vesselName: result.vesselName,
                            voyageNumber: result.voyageNumber,
                            imoNumber: result.imoNumber,
                            serviceName: result.serviceName,
                            terminalCutoff: result.terminalCutoff ? new Date(result.terminalCutoff) : null,
                            originUnloc: result.originUnloc,
                            originDepartureDate: result.originDepartureDate ? new Date(result.originDepartureDate) : null,
                            destinationUnloc: result.destinationUnloc,
                            destinationArrivalDate: result.destinationArrivalDate ? new Date(result.destinationArrivalDate) : null,
                            totalDuration: result.totalDuration,
                            selected: false,
                            extraLoader: false
                        }
                    }).sort((a, b) => new Date(a.originDepartureDate).getTime() - new Date(b.originDepartureDate).getTime());
                    // Trás selecionado por padrão a opção encontrada para o navio de evento de load caso encontre.
                    if (shipLoad) {
                        const index = this.scope.oceanScheduleOptions.findIndex(option => option.imoNumber == shipLoad.CODE);
                        if (index >= 0) this.selectOceanSchedule(index);
                        else {
                            this.addExtraLoader();
                        }
                    }
                } else if (loadEvent && loadEvent.TRANSPORT_MODE && loadEvent.TRANSPORT_MODE.ID) {
                    success = true;
                    this.addExtraLoader();
                } else {
                    success = false;
                    const msg = this.getTranslate('OPERATIONAL.NO_VESSEL_FOUND');
                    this.notifyError(msg);
                }
            }

            this.scope.isPreCarriageModeMandatory = this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE && this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE.START && this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE.END && this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE.END.ID && !this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE.MODE ? true : false;
            this.scope.isOnCarriageModeMandatory = this.scope.model.TRANSPORT_TAB.ON_CARRIAGE && this.scope.model.TRANSPORT_TAB.ON_CARRIAGE.START && this.scope.model.TRANSPORT_TAB.ON_CARRIAGE.START.ID && this.scope.model.TRANSPORT_TAB.ON_CARRIAGE.END && !this.scope.model.TRANSPORT_TAB.ON_CARRIAGE.MODE ? true : false;
            this.timeout(() => {
                this.scope.selectorValidity("preCarriageMode");
                this.scope.selectorValidity("onCarriageMode");
            }, 100);
        } catch (ex) {
            success = false;
            this.handleError(ex);
        } finally {
            this.unblock();
        }
        return success;
    }

    private async initCargoTab(): Promise<boolean> {
        const sucess = true;
        if (!this.scope.model.PAYER_INFORMATION || this.scope.model.PAYER_INFORMATION && this.scope.model.PAYER_INFORMATION.length == 0) this.addPayer();

        if (this.scope.isAmendMode) {
            const totalEquip = this.scope.processInfo.CARGO_DETAIL.EQUIPMENT_LIST.reduce((total, equip) => total + equip.QUANTITY, 0);
            this.scope.model.CARGO_TAB.EQUIPMENT_LIST[0].QUANTITY = totalEquip;
        }

        const lastIndex = this.scope.model.CARGO_TAB.DANGEROUS_CARGO.length - 1;
        this.timeout(() => {
            this.scope.selectorValidity("dangerousCargoOuterPackageType_" + lastIndex);
        }, 100);

        await this.checkRemarks();

        return sucess;
    }

    private resetErrorsAndWarnings(): void {
        if (this.scope.integrationBookingResult && this.scope.integrationBookingResult.HAS_ERRORS) {
            this.scope.integrationBookingResult.HAS_ERRORS = false;
            this.scope.integrationBookingResult.ERRORS = [];
        }
        if (this.scope.integrationBookingResult && this.scope.integrationBookingResult.HAS_WARNINGS) {
            this.scope.integrationBookingResult.HAS_WARNINGS = false;
            this.scope.integrationBookingResult.WARNINGS = [];
        }
    }

    private async initConfirmationTab(): Promise<boolean> {
        let success = true;
        try {

            if (!this.scope.model.GENERAL_DETAILS_TAB.EARLIEST_DEPARTURE_DATE) {
                this.scope.model.GENERAL_DETAILS_TAB.EARLIEST_DEPARTURE_DATE = this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE.ETA ? this.scope.model.TRANSPORT_TAB.PRE_CARRIAGE.ETA : this.scope.model.TRANSPORT_TAB.MAIN_CARRIAGE.LOADING_DATE;
            }

            if (!this.scope.model.GENERAL_DETAILS_TAB.LATEST_DELIVERY_DATE) {
                this.scope.model.GENERAL_DETAILS_TAB.LATEST_DELIVERY_DATE = this.scope.model.TRANSPORT_TAB.ON_CARRIAGE.ETD ? this.scope.model.TRANSPORT_TAB.ON_CARRIAGE.ETD : this.scope.model.TRANSPORT_TAB.MAIN_CARRIAGE.DISCHARGE_DATE;
            }

            if (this.scope.isAmendMode) {
                this.scope.integrationBookingResult = await this.amendBooking();
                if (this.scope.integrationBookingResult == null) success = false;
            } else {
                this.scope.integrationBookingResult = await this.registerBooking();
                if (this.scope.integrationBookingResult == null) success = false;
            }

        } catch (ex) {
            success = false;
            this.handleError(ex);
        } finally {
            this.unblock();
        }
        return success;
    }

    private async registerBooking(): Promise<IInttraValidationResult> {
        let inttraResult: IInttraValidationResult = null;
        try {
            this.block();
            const registerBookingRequest = await this.ExternalService.post({
                route: '/inttra/registerBooking', data: { bookingData: this.scope.model }
            });
            if (registerBookingRequest && registerBookingRequest.status == 200 && registerBookingRequest.data && registerBookingRequest.data.data) {
                inttraResult = registerBookingRequest.data.data;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return inttraResult;
        }
    }

    private async amendBooking(): Promise<IInttraValidationResult> {
        let inttraResult: IInttraValidationResult = null;
        try {
            this.block();
            const amendBookingRequest = await this.ExternalService.post({
                route: '/inttra/amendBooking', data: { bookingData: this.scope.model }
            });
            if (amendBookingRequest && amendBookingRequest.status == 200 && amendBookingRequest.data && amendBookingRequest.data.data) {
                inttraResult = amendBookingRequest.data.data;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return inttraResult;
        }
    }

    private async reprocessBooking(): Promise<IInttraValidationResult> {
        let inttraResult: IInttraValidationResult = this.scope.integrationBookingResult;
        try {
            this.block();
            const reprocessBooking = await this.ExternalService.post({
                route: '/inttra/reprocessBooking', data: { idBooking: this.scope.integrationBookingResult.REGISTERED_BOOKING.ID }
            });
            if (reprocessBooking && reprocessBooking.status == 200 && reprocessBooking.data && reprocessBooking.data.data) {
                inttraResult = reprocessBooking.data.data;
            }
        } catch (ex) {
        } finally {
            this.unblock();
            return inttraResult;
        }
    }

    private selectOceanSchedule(oceanScheduleIndex: number) {
        if (!oceanScheduleIndex && oceanScheduleIndex != 0) return this.notifyError("oceanScheduleIndex is null.");
        if (this.scope.oceanScheduleOptions && this.scope.oceanScheduleOptions.length && this.scope.oceanScheduleOptions[oceanScheduleIndex]) {
            this.scope.oceanScheduleOptions.forEach(async (oceanSchedule, index) => {
                oceanSchedule.selected = (index == oceanScheduleIndex);
                if (oceanSchedule.selected) {
                    const ship = await this.getShipByIdOrImoNumber(null, oceanSchedule.imoNumber);
                    const generalDetailsTab = this.scope.model.GENERAL_DETAILS_TAB;
                    this.scope.model.TRANSPORT_TAB.MAIN_CARRIAGE = {
                        VESSEL: ship ? ship : { ID: "0", NAME: oceanSchedule.vesselName, CODE: oceanSchedule.imoNumber },
                        VOYAGE: oceanSchedule.voyageNumber,
                        PORT_OF_LOAD: generalDetailsTab.PORT_OF_LOAD,
                        LOADING_DATE: oceanSchedule.originDepartureDate,
                        PORT_OF_DISCHARGE: generalDetailsTab.PORT_OF_DISCHARGE,
                        DISCHARGE_DATE: oceanSchedule.destinationArrivalDate,
                        SERVICE: oceanSchedule.serviceName
                    };
                    this.notifyInfo(this.getTranslate('OPERATIONAL.SCHEDULE_SELECTED_SUCCESSFULLY'));
                }
            });
        }
    }

    private async getShipByIdOrImoNumber(id?: string, imoNumber?: string): Promise<ISelectorModel> {
        let ship: ISelectorModel = null;
        try {
            this.block();
            const requestShip = await this.ProductService.post({ route: '/ship/byIdOrImoNumber', data: { id, imoNumber } });
            if (requestShip && requestShip.data && requestShip.data.data) {
                ship = { ID: requestShip.data.data.ID, NAME: requestShip.data.data.NAME, CODE: requestShip.data.data.IMO };
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return ship;
        }
    }

    private async getLegalPersonListByName(legalPersonFilter?: ILegalPersonListCustomFilter): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        this.block();
        try {
            const legalPersons = await this.ProductService.post({ route: '/legalPerson/list/custom', data: legalPersonFilter });
            if (legalPersons && legalPersons.data && legalPersons.data.data) result = legalPersons.data.data.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.NAME, CODE: legalPerson.ID_FISCAL } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async addExtraLoader() {
        const loadEvent = this.scope.processInfo && this.scope.processInfo.EVENT ? this.scope.processInfo.EVENT.find(event => event.EVENT_TYPE && event.EVENT_TYPE.ID == EEventType.LOAD) : null;
        const dischargeEvent = this.scope.processInfo && this.scope.processInfo.EVENT ? this.scope.processInfo.EVENT.find(event => event.EVENT_TYPE && event.EVENT_TYPE.ID == EEventType.DISCHARGE) : null;
        const splitTransport = (loadEvent.TRANSPORT_MODE) ? loadEvent.TRANSPORT_MODE.NAME.split("|", 2) : null;
        const vesselName = (splitTransport) ? ((splitTransport[0]) ? splitTransport[0].toUpperCase() : null) : null;
        const voyage = (splitTransport) ? splitTransport[1] : null;
        const dta = dischargeEvent.EFFECTIVE_DATE ? dischargeEvent.EFFECTIVE_DATE : dischargeEvent.FORECAST_DATE;
        const odd = loadEvent.EFFECTIVE_DATE ? loadEvent.EFFECTIVE_DATE : loadEvent.FORECAST_DATE;
        this.scope.shipNotFound = `${vesselName} / ${voyage} ${this.getTranslate('OPERATIONAL.VESSEL_NOT_FOUND')}`;

        //Difference in number of days
        const totalDuration = (moment.duration(moment(dta).diff(moment(odd))).asDays()) + 1;
        const ship = await this.getShipByIdOrImoNumber(loadEvent.TRANSPORT_MODE.ID);
        const scheduleExtraLoader: IOceanSchedule = {
            selected: false,
            extraLoader: true,
            destinationArrivalDate: dta,
            destinationUnloc: dischargeEvent.LOCAL && dischargeEvent.LOCAL.CODE ? dischargeEvent.LOCAL.CODE : null,
            imoNumber: ship.CODE,
            originDepartureDate: odd,
            originUnloc: loadEvent.LOCAL && loadEvent.LOCAL.CODE ? loadEvent.LOCAL.CODE : null,
            serviceName: 'EXTRA LOADER',
            terminalCutoff: null,
            totalDuration,
            vesselName: vesselName,
            voyageNumber: voyage,
        };

        this.scope.oceanScheduleOptions.unshift(scheduleExtraLoader);

        // Select the extra loader if it's the only one on the list.
        if (this.scope.oceanScheduleOptions.length == 1) {
            this.selectOceanSchedule(0);
        }

        return;
    }

    private async checkPaymentLocation(paymentType: ISelectorModel, index: number): Promise<ISelectorModel> {
        let paymentLocationSelector: ISelectorModel = null;

        try {
            if (!paymentType) this.notifyError("paymentType is null.");
            this.block();
            let objectSearchConfig = {
                serviceProvider: this.scope.model.GENERAL_DETAILS_TAB.CARRIER,
                origin: this.scope.model.GENERAL_DETAILS_TAB.PORT_OF_LOAD,
                destination: this.scope.model.GENERAL_DETAILS_TAB.PORT_OF_DISCHARGE,
                paymentType: paymentType
            };

            const paymentLocation = await this.OperationalService.post('/bookingPaymentConfig/getPaymentConfig', objectSearchConfig, 30000);
            if (paymentLocation && paymentLocation.data && paymentLocation.data.data) {
                const newPaymentLocation: ISelectorModel = paymentLocation.data.data;

                if (Array.isArray(newPaymentLocation)) {
                    const msg = "No config was found for: Service Provider: " + this.scope.model.GENERAL_DETAILS_TAB.CARRIER.NAME + ", Origin: " + this.scope.model.GENERAL_DETAILS_TAB.PORT_OF_LOAD.NAME + ", Destination: " + this.scope.model.GENERAL_DETAILS_TAB.PORT_OF_DISCHARGE.NAME + " and Payment Modality: " + paymentType.NAME;
                    this.scope.model.PAYER_INFORMATION[index].PAYMENT_LOCATION = null;
                    this.timeout(() => {
                        this.scope.selectorValidity("paymentLocation" + index);
                    }, 100);
                    this.notifyError(msg);
                    return;
                }

                this.scope.model.PAYER_INFORMATION[index].PAYMENT_LOCATION = {
                    ID: newPaymentLocation.ID,
                    NAME: newPaymentLocation.NAME,
                    CODE: newPaymentLocation.CODE,
                    INTTRA_CODE: null
                }

                this.timeout(() => {
                    this.scope.selectorValidity("paymentLocation" + index);
                }, 100);
            }

        } catch (ex) {
            this.notifyError(ex);
        } finally {
            this.unblock();
        }
        return paymentLocationSelector;
    }

    private async finish(): Promise<void> {
        try {

        } catch (ex) {
            this.handleError(ex);
        }
    }
}

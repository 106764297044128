import * as angular from "angular";
import { IColumnDef } from "ui-grid";
import { GridFormService, IGridFormController, IGridFormServiceScope } from "@services/GridFormService";
import { IMonacoColumnDef } from "@services/GridService2";
import { TaskService } from "@services/TaskService";
import { ISessionService } from '@services/SessionService';
import { NewTaskView } from "@models/interface/task//NewTaskView";
import { ICustomLogProperties, IViewLog } from "@models/interface/common/IViewLog";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { IProcessParameter, IInvoiceParameter, ITaskParamParameter, IProcessDocumentParameter } from '../../common/model/ModelParameter';

interface INewTaskScope extends IGridFormServiceScope {
    model: NewTaskView;
    customLogProperties: ICustomLogProperties[];
    goToProcess: (process: string) => void;
    goToTaskParam: (taskParam: string) => void;
    goToInvoice: (invoice: string) => void;
    goToDocument: (document: string, type: string) => void;
}

export class NewTaskHistoryController extends GridFormService implements IGridFormController {
    static $inject: string[] = ["$injector", "$scope", "$element"];
    private $scope: INewTaskScope;
    private TaskService: TaskService;
    private SessionService: ISessionService;

    constructor($injector: ng.Injectable<any>, $scope: INewTaskScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.TaskService = $injector.get('TaskService');
        this.SessionService = $injector.get('SessionService');
    }

    initScopeFunctions(): void {
        this.$scope.goToProcess = (process: string) => {
            this.SessionService.openTab('app.operational.newProcess.list', <IProcessParameter>{ PROCESS_NUMBER: process });
        }
        this.$scope.goToTaskParam = (param: string) => {
            this.SessionService.openTab('app.task.param', <ITaskParamParameter>{ TASK_NUMBER: param });
        }
        this.$scope.goToInvoice = (invoice: string) => {
            this.SessionService.openTab('app.finop.invoice.register', <IInvoiceParameter>{ INVOICE_NUMBER: invoice });
        }
        this.$scope.goToDocument = (document: string, type: string) => {
            this.SessionService.openTab('app.operational.process.processDocument', <IProcessDocumentParameter>{ DOCUMENT: document, TYPE: type });
        };
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.TaskService.$route;
            this.initForm(this, "form", "newTaskHistory", "Atividades Histórico", false);
            this.block();

            //init grid
            await this.initGrid('gridNewTaskViewHistory', '/newTaskView/history/list', true, true, 30000, true, true);
            this.$gridService.setBackgroundUpdate(60000);

            this.$scope.customLogProperties = this.getCustomLogProperties();

            this.unblock();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const columnDefs: Array<IColumnDef> = gridColumns.$columnDefs;
        const history = `<span ng-show="true"><a ng-click="grid.appScope.viewLog(row.entity)" class="text-green" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;</span>`;
        const actions = `<div class="text-center pull-left" style="padding-left: 10px;">${history}</div>`;

        columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: '10%',
            cellTemplate: (actions),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            //visible
            const colSituation: IMonacoColumnDef = { name: "SITUATION.NAME", displayName: "GENERAL.SITUATION", width: 150 };
            const colTaskParam: IMonacoColumnDef = { name: "PARAMS.TASK_NUMBER", displayName: "OPERATIONAL.TASK_NUMBER", width: 120, cellTemplate: `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.SETTINGS_VIEW' | translate }} {{row.entity.PARAMS.TASK_NUMBER}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToTaskParam(row.entity.PARAMS.TASK_NUMBER)">{{row.entity.PARAMS.TASK_NUMBER}}</a></div>` };
            const colTask: IMonacoColumnDef = { name: "TASK.NAME", displayName: "OPERATIONAL.TASK", width: 250 };
            const colProcessNumber: IMonacoColumnDef = { name: "PROCESS_NUMBER", displayName: "OPERATIONAL.FILE_NUMBER", width: 150, cellTemplate: `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.PROCESS_VIEW}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToProcess(row.entity.PROCESS_NUMBER)">{{row.entity.PROCESS_NUMBER}}</a></div>` };
            const coliInvoiceNumber: IMonacoColumnDef = { name: "INVOICE_NUMBER", displayName: "GENERAL.INVOICES", width: 150, cellTemplate: `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.INVOICE_VIEW' | translate }} {{row.entity.INVOICE_NUMBER}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToInvoice(row.entity.INVOICE_NUMBER)">{{row.entity.INVOICE_NUMBER}}</a></div>` };
            const colEstimatedStartDate: IMonacoColumnDef = { name: "ESTIMATED_START_DATE", displayName: "OPERATIONAL.ESTIMATED_START", width: 130, cellFilter: "datetime" };
            const colStartDate: IMonacoColumnDef = { name: "START_DATE", displayName: "OPERATIONAL.DEADLINE_START", width: 120, cellFilter: "datetime" };
            const colEstimatedEndDate: IMonacoColumnDef = { name: "ESTIMATED_END_DATE", displayName: "OPERATIONAL.ESTIMATED_CONCLUDE", width: 160, cellFilter: "datetime" };
            const colEndDate: IMonacoColumnDef = { name: "END_DATE", displayName: "REGISTRATION.DEADLINE_END", width: 120, cellFilter: "datetime" };
            const colExecutor: IMonacoColumnDef = { name: "EXECUTOR.NAME", displayName: "OPERATIONAL.EXECUTOR", width: 150 };
            const colResponsibleUser: IMonacoColumnDef = { name: "RESPONSIBLE_USER.NAME", displayName: "REGISTRATION.RESPONSIBLE_USER", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.RESPONSIBLE_USER)}}</div>' };
            const colDetour: IMonacoColumnDef = { name: "DETOUR.NAME", displayName: "OPERATIONAL.FAULT_REASON", width: 150 };
            const colCustomer: IMonacoColumnDef = { name: "PROCESS.CUSTOMER.NAME", displayName: "BASIC_DATA.CLIENT", width: 200 };

            //not visible
            const colResponsibleCell: IMonacoColumnDef = { name: "RESPONSIBLE_CELL.NAME", displayName: "REGISTRATION.RESPONSIBLE_CELL", width: 150, visible: false };
            const colResponsibleRole: IMonacoColumnDef = { name: "RESPONSIBLE_ROLE.NAME", displayName: "REGISTRATION.RESPONSIBLE_ROLE", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.RESPONSIBLE_ROLE)}}</div>', visible: false };
            const colTaskProduct: IMonacoColumnDef = { name: "PARAMS.PRODUCT.NAME", displayName: "BASIC_DATA.PRODUCT", width: 150, visible: false };
            const colTaskModule: IMonacoColumnDef = { name: "PARAMS.MODULE.NAME", displayName: "REGISTRATION.MODULE", width: 120, visible: false };
            const colTaskTaskStep: IMonacoColumnDef = { name: "PARAMS.TASK_STEP.NAME", displayName: "REGISTRATION.STEP", width: 120, visible: false };
            const colMandatoryDetour: IMonacoColumnDef = { name: "PARAMS.MANDATORY_DETOUR.NAME", displayName: "REGISTRATION.REQUIRED_FAULT_REASON", width: 120, visible: false };
            const colScore: IMonacoColumnDef = { name: "SCORE", displayName: "BASIC_DATA.SCORE", width: 120, visible: false };
            const colCustQual: IMonacoColumnDef = { name: "PROCESS.CUSTOMER_QUALIFICATION.NAME", displayName: "GENERAL.CLIENT_QUALIFICATION", width: 150, visible: false };
            const colKAM: IMonacoColumnDef = { name: "PROCESS.KAM", displayName: "BASIC_DATA.KAM", width: 100, cellFilter: 'YesOrNo', visible: false };
            const colOrigin: IMonacoColumnDef = { name: "PROCESS.ORIGIN.LOCAL.NAME", displayName: "BASIC_DATA.ORIGIN", width: 120, visible: false };
            const colDestin: IMonacoColumnDef = { name: "PROCESS.DESTINATION.LOCAL.NAME", displayName: "BASIC_DATA.DESTINATION", width: 140, visible: false };
            const colImporter: IMonacoColumnDef = { name: "PROCESS.IMPORTER.NAME", displayName: "BASIC_DATA.CONSIGNEE", width: 220, visible: false };
            const colExporter: IMonacoColumnDef = { name: "PROCESS.EXPORTER.NAME", displayName: "BASIC_DATA.SHIPPER", width: 220, visible: false };
            const colBookLoad: IMonacoColumnDef = { name: "PROCESS.BOOKING_LOAD", displayName: "OPERATIONAL.BOOKING", width: 120, visible: false, cellTemplate: `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.BOOKING_VIEW' | translate }} {{row.entity.PROCESS_BOOKING_LOAD}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToProcess(row.entity.PROCESS_NUMBER)">{{row.entity.PROCESS_BOOKING_LOAD}}</a></div>` };
            const colTransport: IMonacoColumnDef = { name: "PROCESS.TRANSPORT_MODE_LOAD.NAME", displayName: "OPERATIONAL.TRANSPORT_MODE", width: 120, visible: false };
            const colEstLoad: IMonacoColumnDef = { name: "PROCESS.ESTIMATED_LOAD", displayName: "OPERATIONAL.ESTIMATED_LOADING_DATE", width: 120, cellFilter: 'simpleDate', visible: false };
            const colEffeLoad: IMonacoColumnDef = { name: "PROCESS.EFFECTIVE_LOAD", displayName: "OPERATIONAL.ACTUAL_LOADING_DATE", width: 120, cellFilter: 'simpleDate', visible: false };
            const colEstDisc: IMonacoColumnDef = { name: "PROCESS.ESTIMATED_DISCHARGE", displayName: "OPERATIONAL.ESTIMATED_DISCHARGE_DATE", width: 120, cellFilter: 'simpleDate', visible: false };
            const colEffeDisc: IMonacoColumnDef = { name: "PROCESS.EFFECTIVE_DISCHARGE", displayName: "OPERATIONAL.ACTUAL_DISCHARGE_DATE", width: 120, cellFilter: 'simpleDate', visible: false };
            const colMasterDirect: IMonacoColumnDef = { name: "PROCESS.MASTER_DIRECT", displayName: "BASIC_DATA.STRAIGHT_BL", width: 120, cellFilter: 'YesOrNo', visible: false };
            const colServProvider: IMonacoColumnDef = { name: "PROCESS.SERVICE_PROVIDER.NAME", displayName: "BASIC_DATA.PROVIDER", width: 120, visible: false };
            const colForwaBy: IMonacoColumnDef = { name: "PROCESS.FORWARDED_BY.NAME", displayName: "GENERAL.FORWARDED_BY", width: 120, visible: false };
            const colBrokerName: IMonacoColumnDef = { name: "PROCESS.BROKER.NAME", displayName: "BASIC_DATA.CUSTOMS_BROKER", width: 200, visible: false };
            const colCargoType: IMonacoColumnDef = { name: "PROCESS.CARGO_TYPE.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 120, visible: false };
            const colIncoterm: IMonacoColumnDef = { name: "PROCESS.INCOTERM.NAME", displayName: "BASIC_DATA.INCOTERM", width: 120, visible: false };
            const colProcessType: IMonacoColumnDef = { name: "PROCESS.PROCESS_TYPE.NAME", displayName: "BASIC_DATA.FILE_TYPE", width: 120, visible: false };
            const colDocHBL: IMonacoColumnDef = { name: "DOCUMENT_HBL", displayName: "OPERATIONAL.HBL", width: 120, visible: false, cellTemplate: `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.HOUSE_VIEW' | translate }} {{row.entity.DOCUMENT_HBL}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToDocument(row.entity.DOCUMENT_HBL, \'HOUSE\')">{{row.entity.DOCUMENT_HBL}}</a></div>` };
            const colDocMBL: IMonacoColumnDef = { name: "DOCUMENT_MBL", displayName: "OPERATIONAL.MBL", width: 120, visible: false, cellTemplate: `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.MASTER_VIEW' | translate }} {{row.entity.DOCUMENT_MBL}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToDocument(row.entity.DOCUMENT_MBL, \'MASTER\')">{{row.entity.DOCUMENT_MBL}}</a></div>` };
            const colConclusionSource: IMonacoColumnDef = { name: "CONCLUSION_SOURCE.NAME", displayName: "OPERATIONAL.ORIGIN_END", width: 150, visible: false };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'PROCESS_NUMBER':
                        columnDefs.push(colProcessNumber);
                        break;
                    case 'INVOICE_NUMBER':
                        columnDefs.push(coliInvoiceNumber);
                        break;
                    case 'TASK':
                        columnDefs.push(colTask);
                        break;
                    case 'SITUATION':
                        columnDefs.push(colSituation);
                        break;
                    case 'RESPONSIBLE_USER':
                        columnDefs.push(colResponsibleUser);
                        break;
                    case 'EXECUTOR':
                        columnDefs.push(colExecutor);
                        break;
                    case 'RESPONSIBLE_CELL':
                        columnDefs.push(colResponsibleCell);
                        break;
                    case 'RESPONSIBLE_ROLE':
                        columnDefs.push(colResponsibleRole);
                        break;
                    case 'DETOUR':
                        columnDefs.push(colDetour);
                        break;
                    case 'PARAMS':
                        columnDefs.push(colTaskParam);
                        columnDefs.push(colTaskProduct);
                        columnDefs.push(colTaskModule);
                        columnDefs.push(colTaskTaskStep);
                        columnDefs.push(colMandatoryDetour);
                        break;
                    case 'ESTIMATED_START_DATE':
                        columnDefs.push(colEstimatedStartDate);
                        break;
                    case 'START_DATE':
                        columnDefs.push(colStartDate);
                        break;
                    case 'ESTIMATED_END_DATE':
                        columnDefs.push(colEstimatedEndDate);
                        break;
                    case 'END_DATE':
                        columnDefs.push(colEndDate);
                        break;
                    case 'SCORE':
                        columnDefs.push(colScore);
                        break;
                    case 'PROCESS':
                        columnDefs.push(colCustomer);
                        columnDefs.push(colCustQual);
                        columnDefs.push(colKAM);
                        columnDefs.push(colBookLoad);
                        columnDefs.push(colTransport);
                        columnDefs.push(colOrigin);
                        columnDefs.push(colDestin);
                        columnDefs.push(colImporter);
                        columnDefs.push(colExporter);
                        columnDefs.push(colEstLoad);
                        columnDefs.push(colEffeLoad);
                        columnDefs.push(colEstDisc);
                        columnDefs.push(colEffeDisc);
                        columnDefs.push(colMasterDirect);
                        columnDefs.push(colServProvider);
                        columnDefs.push(colForwaBy);
                        columnDefs.push(colBrokerName);
                        columnDefs.push(colCargoType);
                        columnDefs.push(colIncoterm);
                        columnDefs.push(colProcessType);
                        break;
                    case 'DOCUMENT_HBL':
                        columnDefs.push(colDocHBL);
                        break;
                    case 'DOCUMENT_MBL':
                        columnDefs.push(colDocMBL);
                        break;
                    case 'CONCLUSION_SOURCE':
                        columnDefs.push(colConclusionSource);
                        break;
                };
            }

            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
    }

    private async requestHistory(id: string): Promise<any> {
        const result = await this.TaskService.get({ route: `/newTask/viewLog/${id}`, timeout: 10000 });
        if (!result || !result.data) return this.handleError(result);
        return result.data;
    }

    private async viewLog(task) {
        try {
            if (this.$scope.log) this.$scope.log.show = false;
            this.block();
            let log: IViewLog = {
                operation: 'history',
                number: `${task.PROCESS_NUMBER}-${task.PARAMS.TASK_NUMBER}`,
                list: [],
                show: true,
                searchQuery: '',
                originalList: [],
            }
            const result = await this.requestHistory(log.number)
            log.list = result.data;
            log.originalList = angular.copy(log.list);
            this.$scope.log = log;
            angular.element('#log-viewer').removeClass('ng-hide');
            const position = angular.element('#log-viewer').offset().top + $('.app-content-body').scrollTop() - 105;

            $('.app-content-body').animate({
                scrollTop: position
            }, 500);

            this.unblock();
        } catch (err) {
            if (err['status'] && err['status'] === 404) return this.handleWarning(this.formService.getTranslate("GENERAL.NO_DATA_FOUND"));
            return this.handleError(err);
        }
    };

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: 'ACTIVE', LABEL: 'GENERAL.ACTIVE' },
            { PROPERTY: 'TASK_NUMBER', LABEL: 'OPERATIONAL.TASK_NUMBER' },
            { PROPERTY: 'TASK', LABEL: 'OPERATIONAL.TASK' },
            { PROPERTY: 'TASK_STEP', LABEL: 'REGISTRATION.STEP' },
            { PROPERTY: 'MODULE', LABEL: 'REGISTRATION.MODULE*' },
            { PROPERTY: 'MANDATORY_DETOUR', LABEL: 'REGISTRATION.REQUIRED_FAULT_REASON' },
            { PROPERTY: 'DETOUR_LIST', LABEL: 'REGISTRATION.FAULT_LIST' },
            { PROPERTY: 'RESPONSIBLE_CELL', LABEL: 'REGISTRATION.RESPONSIBLE_CELL' },
            { PROPERTY: 'RESPONSIBLE_ROLE', LABEL: 'REGISTRATION.RESPONSIBLE_ROLE' },
            { PROPERTY: 'RESPONSIBLE_USER', LABEL: 'REGISTRATION.RESPONSIBLE_USER' },
            { PROPERTY: 'PRODUCT', LABEL: 'BASIC_DATA.PRODUCT*' },
            { PROPERTY: 'FORWARDED_BY', LABEL: 'GENERAL.FORWARDED_BY' },
            { PROPERTY: 'PROCESS_TYPE', LABEL: 'BASIC_DATA.FILE_TYPE' },
            { PROPERTY: 'COMPANY', LABEL: 'GENERAL.COMPANY' },
            { PROPERTY: 'PRIORITY', LABEL: 'REGISTRATION.PRIORITY' },
            { PROPERTY: 'OPERATIONAL_SITUATION', LABEL: 'OPERATIONAL.FILE_OPERATIONAL_STATUS' },
            { PROPERTY: 'DIRECT_BL', LABEL: 'OPERATIONAL.TRANSPORT_BILL' },
            { PROPERTY: 'CARGO_TYPE', LABEL: 'BASIC_DATA.CARGO_TYPE' },
            { PROPERTY: 'DANGER_CARGO', LABEL: 'BASIC_DATA.DANGEROUS_CARGO' },
            { PROPERTY: 'INSURANCE', LABEL: 'FINANCIAL.INSURANCE' },
            { PROPERTY: 'DTA', LABEL: 'REGISTRATION.BONDED_TRUCK' },
            { PROPERTY: 'STATUS_BOOKING', LABEL: 'OPERATIONAL.BOOKING_STATUS' },
            { PROPERTY: 'INCOTERM', LABEL: 'BASIC_DATA.INCOTERM' },
            { PROPERTY: 'MASTER_PAYMENT', LABEL: 'BASIC_DATA.MASTER_PAYMENT_MODE' },
            { PROPERTY: 'HOUSE_PAYMENT', LABEL: 'BASIC_DATA.HOUSE_PAYMENT_MODE' },
            { PROPERTY: 'SERVICE_MOVEMENT', LABEL: 'GENERAL.MOVEMENT_TYPE' },
            { PROPERTY: 'EQUIPMENT', LABEL: 'BASIC_DATA.EQUIPMENT' },
            { PROPERTY: 'SERVICE_TYPE', LABEL: 'BASIC_DATA.SERVICE_TYPE' },
            { PROPERTY: 'BOOKING_TYPE', LABEL: 'GENERAL.BOOKING_TYPE' },
            { PROPERTY: 'CLIENT', LABEL: 'BASIC_DATA.CLIENT' },
            { PROPERTY: 'QUALIFICATION', LABEL: 'GENERAL.CLIENT_QUALIFICATION' },
            { PROPERTY: 'KAM', LABEL: 'BASIC_DATA.KAM' },
            { PROPERTY: 'ORIGIN_AGENT', LABEL: 'BASIC_DATA.LOCAL_AGENT' },
            { PROPERTY: 'DESTINATION_AGENT', LABEL: 'BASIC_DATA.OVERSEAS_AGENT' },
            { PROPERTY: 'SERVICE_PROVIDER', LABEL: 'BASIC_DATA.PROVIDER' },
            { PROPERTY: 'TRANSPORTER_PICKUP', LABEL: 'BASIC_DATA.TRANSPORTER_PICKUP' },
            { PROPERTY: 'TRANSPORTER_PLACE_RECEPT', LABEL: 'BASIC_DATA.TRANSPORTER_PLACE_RECEPT' },
            { PROPERTY: 'TRANSPORTER_FINAL_DESTINATION', LABEL: 'BASIC_DATA.TRANSPORTER_FINAL_DESTINATION' },
            { PROPERTY: 'TRANSPORTER_DELIVERY', LABEL: 'BASIC_DATA.TRANSPORTER_DELIVERY' },
            { PROPERTY: 'TRANSPORTER_GATE_OUT_EMPTY', LABEL: 'BASIC_DATA.TRANSPORTER_GATE_OUT_EMPTY' },
            { PROPERTY: 'TRANSPORTER_CONTAINER_STUFFING', LABEL: 'BASIC_DATA.TRANSPORTER_CONTAINER_STUFFING' },
            { PROPERTY: 'FLEXI_FITTING', LABEL: 'BASIC_DATA.FLEXI_FITTING' },
            { PROPERTY: 'ISOTANK_PROVIDER', LABEL: 'BASIC_DATA.ISOTANK_PROVIDER' },
            { PROPERTY: 'EXTERNAL_BROKER', LABEL: 'BASIC_DATA.EXTERNAL_BROKER' },
            { PROPERTY: 'ORIGIN_GROUP', LABEL: 'BASIC_DATA.ORIGIN_TRADE_GROUP' },
            { PROPERTY: 'ORIGIN_TRADE', LABEL: 'BASIC_DATA.ORIGIN_TRADE_LANE' },
            { PROPERTY: 'ORIGIN_COUNTRY', LABEL: 'BASIC_DATA.ORIGIN_COUNTRY' },
            { PROPERTY: 'ORIGIN_LOCAL', LABEL: 'BASIC_DATA.ORIGIN_LOCATION' },
            { PROPERTY: 'DESTINATION_GROUP', LABEL: 'BASIC_DATA.DESTINATION_TRADE_GROUP' },
            { PROPERTY: 'DESTINATION_TRADE', LABEL: 'BASIC_DATA.DESTINATION_TRADE_LANE' },
            { PROPERTY: 'DESTINATION_COUNTRY', LABEL: 'BASIC_DATA.DESTINATION_COUNTRY' },
            { PROPERTY: 'DESTINATION_LOCAL', LABEL: 'BASIC_DATA.DESTINATION_LOCATION' },
            { PROPERTY: 'TRANSACTION', LABEL: 'GENERAL.HOLDER_TYPE' },
            { PROPERTY: 'PAYMENT_METHOD', LABEL: 'FINANCIAL.PAYMENT_MEANS' },
            { PROPERTY: 'PAYMENT_CONDITION', LABEL: 'GENERAL.INVOICE_PAYMENT_TERM' },
            { PROPERTY: 'CURRENCY_CONVERSION', LABEL: 'GENERAL.EXCHANGE_RATE_INDEX' },
            { PROPERTY: 'PERIOD_VARIATION', LABEL: 'REGISTRATION.PERIOD_VARIATION' },
            { PROPERTY: 'CONVERSION_REFERENCE', LABEL: 'REGISTRATION.EXC_RATE_REFERENCE_DATE' },
            { PROPERTY: 'NATURE', LABEL: 'BASIC_DATA.PAYMENT_NATURE' },
            { PROPERTY: 'ANTICIPATED_INVOICE', LABEL: 'REGISTRATION.EARLY_RECEIPT' },
            { PROPERTY: 'INVOICE_STATUS', LABEL: 'FINANCIAL.BILLING_STATUS' },
            { PROPERTY: 'INVOICE_FINANCIAL_STATUS', LABEL: 'REGISTRATION.FINANCIAL_STATUS' },
            { PROPERTY: 'PEOPLE', LABEL: 'GENERAL.HOLDER' },
            { PROPERTY: 'START', LABEL: 'OPERATIONAL.START' },
            { PROPERTY: 'END', LABEL: 'OPERATIONAL.END' },
            { PROPERTY: 'RECALCULATE_TASK', LABEL: 'REGISTRATION.RECALCULATE*' },
            { PROPERTY: 'RECALCULATE_TASK_SITUATION', LABEL: 'REGISTRATION.TASK_STATUS' },
            { PROPERTY: 'RESTORE', LABEL: 'OPERATIONAL.RESTORE' },
            { PROPERTY: 'PROCESS_SITUATION', LABEL: 'OPERATIONAL.LOGISTIC_STATUS' },
            { PROPERTY: 'TRIGGER', LABEL: 'REGISTRATION.TRIGGERS' },
            { PROPERTY: 'TARGET_GROUP', LABEL: 'REGISTRATION.MONITORING' },
            { PROPERTY: 'CURRENT_SITUATION', LABEL: 'OPERATIONAL.CURRENT_FILE_STATUS' },
            { PROPERTY: 'TASK_TO_UPDATE', LABEL: 'OPERATIONAL.UPDATE' },
            { PROPERTY: 'SITUATION_TO_UPDATE_TO', LABEL: 'OPERATIONAL.UPDATE_FILE_STATUS' },
            { PROPERTY: 'GENERATE_TASK', LABEL: 'REGISTRATION.GENERATE_TASK' },
            { PROPERTY: 'INSERT_STATUS', LABEL: 'REGISTRATION.INSERT_AND' },
            { PROPERTY: 'UPDATE_STATUS', LABEL: 'OPERATIONAL.UPDATE' },
            { PROPERTY: 'EXCLUDE_STATUS', LABEL: 'GENERAL.DELETE' },
            { PROPERTY: 'TARGETS', LABEL: 'REGISTRATION.TARGET' },
            { PROPERTY: 'CALC_TYPE', LABEL: 'REGISTRATION.CALCULATION_TYPE' },
            { PROPERTY: 'DEADLINE_HOURS', LABEL: 'REGISTRATION.DEADLINE_HOURS_HH_MM' },
            { PROPERTY: 'DEADLINE_MINUTES', LABEL: 'REGISTRATION.DEADLINE_HOURS_HH_MM' },
            { PROPERTY: 'HOUR_TYPE', LABEL: 'REGISTRATION.HOURS_TYPE' },
            { PROPERTY: 'HOUR_CONCLUSION', LABEL: 'REGISTRATION.CONCLUSION_HOUR' },
            { PROPERTY: 'FIXED_HOURS', LABEL: 'REGISTRATION.DEADLINE_FIXED_HOURS_HH_MM' + '(hh)' },
            { PROPERTY: 'FIXED_MINUTES', LABEL: 'REGISTRATION.DEADLINE_FIXED_HOURS_HH_MM' + '(mm)' },
            { PROPERTY: 'INTEGRATION', LABEL: 'REGISTRATION.INTEGRATION' },
            { PROPERTY: 'NOTIFICATION_TYPE', LABEL: 'REGISTRATION.NOTIFICATION_TYPE' },
            { PROPERTY: 'HOTSPOT_NUMBER', LABEL: 'OPERATIONAL.HOTSPOT' },
            { PROPERTY: 'TARGET_TYPE', LABEL: 'REGISTRATION.TARGET_TYPE' },
            { PROPERTY: 'TARGET_CLASS', LABEL: 'REGISTRATION.TARGET_CLASS' },
            { PROPERTY: 'TARGET', LABEL: 'REGISTRATION.TARGET' },
            { PROPERTY: 'TARGET_PARAM', LABEL: 'REGISTRATION.PARAMETERS' },
            { PROPERTY: 'TARGET_PARAM_TYPE', LABEL: 'REGISTRATION.PARAMETER_TYPE' },
            { PROPERTY: 'TARGET_GENERIC_IDENTIFIER', LABEL: 'REGISTRATION.GENERIC_IDENTIFIER' },
            { PROPERTY: 'TARGET_TRACKER', LABEL: 'OPERATIONAL.TARGET_TRACKER' },
            { PROPERTY: 'STATUS', LABEL: 'OPERATIONAL.STATUS' },
            { PROPERTY: 'EXISTS', LABEL: 'REGISTRATION.CONTAINS' },
            { PROPERTY: 'CRITERIA', LABEL: 'REGTISTRATION.CRITERIA' },
        ];
        return <ICustomLogProperties[]>props;
    }
}

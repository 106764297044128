import { IMonacoScope } from "./MonacoInterface";

export class BrowserTitle {
    private static rootScope: IMonacoScope;

    public static set $rootScope(value: IMonacoScope) {
        this.rootScope = value;
    }

    public static set $menu(value: string) {
        if (!BrowserTitle.rootScope) return;

        this.rootScope.title = value;
        this.rootScope.titleId = "";
    }

    public static set $id(value: string) {
        if (!BrowserTitle.rootScope) return;

        this.rootScope.titleId = value;
    }
}
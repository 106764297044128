import * as angular from 'angular';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog, IMonacoRequest } from '@services/GridFormService';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { EVENT_EDI_SETUP, IEvent, EVENT_EDI_SETUP_PARAMS } from '@models/interface/operational/Event';
import { GridColumnBuilder } from '../../common/GridColumnBuilder';
import { IMonacoColumnDef } from '@services/GridService2';
import { BrowserTitle } from "../../common/BrowserTitle";
import { SelectorModel } from '../../common/model/SelectorModel';
import { IRestService } from '@services/RestService';
import { ISessionService } from '@services/SessionService';
import { SSEService } from '@appServices/SSEService';
import { EOperation } from '@enums/GenericData';
import { OperationalService } from '@services/OperationalService';
import { ProductService } from '@services/ProductService';
import { IMoveTypeModel } from "@models/interface/product/MoveTypeModel";
import { IAccountSelector, IIncotermSelector, IServiceProviderSelector } from 'WBA-Model/dist/interface/operational/IProductIntegrationSelector';
import { ISelectorModel } from 'WBA-Model/dist/mongo/SelectorModel';
import { IModalService } from '@services/ModalService';
import { HelperService } from "@services/HelperService";
import { IDocumentError } from 'WBA-Model/dist/interface/common/IDocumentError';

interface IEventSequence {
    SEQUENCE: number;
    EVENT_NUMBER: string;
}

interface IEventScope extends IGridFormServiceScope {
    model: IEvent;
    log: IViewLog;
    scopeBeforeSave: IEvent;
    customLogProperties: ICustomLogProperties[];
    productList: SelectorModel[];
    eventList: SelectorModel[];
    eventGroupList: SelectorModel[];
    localTypeList: SelectorModel[];
    processTypeList: SelectorModel[];
    typeList: SelectorModel[];
    validationFieldsList: SelectorModel[];
    qualificationList: SelectorModel[];
    targetTypeList: SelectorModel[];
    forwardedByList: SelectorModel[];
    typeCargoList: SelectorModel[];
    eventContainerStatusList: SelectorModel[];
    serviceTypeList: SelectorModel[];
    incotermList: SelectorModel[];
    serviceProviderList: SelectorModel[];
    accountList: SelectorModel[];
    originList: SelectorModel[];
    sequenceList: IEventSequence[];
    user: any;
    sessionService: ISessionService;
    gridOptions: uiGrid.IGridOptions;
    editEvent: (event: IEvent) => Promise<void>;
    viewEvent: (event: IEvent) => Promise<void>;
    viewLogEvent: (event: IEvent) => Promise<void>;
    copyEvent: (event: IEvent) => Promise<void>;
    checkComplement: () => void;
    getServiceProviderListByName: (term: string) => Promise<void>;
    addEventEDISetup: () => void;
    addEventEDISetupParams: (index: number) => void;
    getAccountListByName: (term: string) => Promise<void>;
    validDataEDISetup: (parentIndex: number, field: string) => void;
    validDataEDISetupParams: (parentIndex: number, index: number, field: string) => void;
    validTargets: (parentIndex: number) => void;
    removeEventEDISetupParams: (parentIndex: number, index: number) => Promise<void>;
    removeEventEDISetup: (index: number) => Promise<void>;
    copyEDISetup: (index: number) => Promise<void>;
    yesChoice: () => void;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
}

export class EventRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IEventScope;
    private RestService: IRestService;
    private $q: ng.IQService;
    private SSEService: SSEService;
    private gridName: string;
    private operationalService: OperationalService;
    private productService: ProductService;
    private timeout = null;
    private modalService: IModalService;
    private helperService: HelperService;
    private modalID: number;

    constructor($injector: ng.Injectable<any>, $scope: IEventScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.operationalService = $injector.get('OperationalService');
        this.productService = $injector.get('ProductService');
        this.modalService = $injector.get('ModalService');;
        this.timeout = $injector.get('$timeout');
        this.gridName = 'GRID_EVENT';
        this.SSEService = new SSEService($injector, $scope);
        this.helperService = $injector.get('HelperService');
        this.modalID = null;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.operationalService.$route;

            this.$scope.customLogProperties = this.getCustomLogProperties();

            this.initForm(this, 'form', 'event', 'GENERAL.MENU.EVENT', true);
            await this.initGrid(this.gridName, '/events/list', true, true, null, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editEvent = async (iEvent: IEvent): Promise<void> => {
            let blockedObject = {
                ID: iEvent.ID,
                NAME: iEvent.EVENT_NUMBER,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || iEvent.ID !== this.$scope.model.ID) this.$scope.view(iEvent);
                } else if (this.$scope.operation !== EOperation.EDIT || iEvent.ID !== this.$scope.model.ID) {
                    this.$scope.edit(iEvent);
                }
            };
        }

        this.$scope.viewEvent = async (event: IEvent): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(event);
        }

        this.$scope.viewLogEvent = async (event: IEvent): Promise<void> => {
            this.SSEService.closeEvents();
            this.viewLogEvent(event);
        }

        this.$scope.copyEvent = async (event: IEvent): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(event);
        }

        this.$scope.addEventEDISetup = (): void => {
            this.addEventEDISetup();
        }

        this.$scope.addEventEDISetupParams = (index: number): void => {
            this.addEventEDISetupParams(index);
        }

        this.$scope.validDataEDISetup = (parentIndex: number, field: string): void => {
            this.validDataEDISetup(parentIndex, field);
        }

        this.$scope.validDataEDISetupParams = (parentIndex: number, index: number, field: string): void => {
            this.validDataEDISetupParams(parentIndex, index, field);
        }

        this.$scope.getServiceProviderListByName = async (term: string): Promise<void> => {
            await this.getServiceProviderListByName(term);
        }

        this.$scope.getAccountListByName = async (term: string): Promise<void> => {
            await this.getAccountListByName(term);
        }

        this.$scope.validTargets = (parentIndex: number): void => {
            this.validTargets(parentIndex);
        }

        this.$scope.removeEventEDISetupParams = async (parentIndex: number, index: number): Promise<void> => {
            await this.removeEventEDISetupParams(parentIndex, index);
        }

        this.$scope.removeEventEDISetup = async (index: number): Promise<void> => {
            await this.removeEventEDISetup(index);
        }

        this.$scope.copyEDISetup = async (index: number): Promise<void> => {
            await this.copyEDISetup(index);
        }

        this.$scope.yesChoice = (): void => {
            this.yesChoice();
        }

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }

    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            EVENT_NUMBER: null,
            EVENT: null,
            LOCAL_TYPE: null,
            EVENT_GROUP: null,
            INTTRA_CODE: null,
            PRODUCT: null,
            OPERATION_GROUP: null,
            MODAL_GROUP: null,
            FORWARDED_BY_GROUP: null,
            PROCESS_TYPE_GROUP: null,
            SERVICE_TYPE_GROUP: null,
            CARGO_TYPE_GROUP: null,
            INCOTERM_GROUP: null,
            DTA: null,
            REQUIRED: false,
            EVENT_EDI_SETUP: null,
            INTERNAL_SEQUENCE: null,
            DET_DEM_CONTROL: false,
            EVENT_CONTAINER_STATUS: null,
            CODE: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewEvent(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editEvent(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogEvent(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyEvent(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy + modalIntegration),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };

        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    private buildColumns(columns: string[]): IMonacoColumnDef[] {
        let gridColumns: Array<IMonacoColumnDef> = [];

        const colEventNumber: IMonacoColumnDef = { name: 'EVENT_NUMBER', displayName: 'GENERAL.NUMBER', width: 150 };
        const colInternalSequence: IMonacoColumnDef = { name: 'INTERNAL_SEQUENCE', displayName: 'REGISTRATION.INTERNAL_SEQUENCE', width: 150 };
        const colCode: IMonacoColumnDef = { name: 'CODE', displayName: 'BASIC_DATA.INITIALS', width: 150 };
        const colEvent: IMonacoColumnDef = { name: 'EVENT.NAME', displayName: 'GENERAL.NAME', width: 350 };
        const collocalType: IMonacoColumnDef = { name: 'LOCAL_TYPE.NAME', displayName: 'ROUTE.LOCAL_TYPE', width: 350 };
        const colEventGroup: IMonacoColumnDef = { name: 'EVENT_GROUP.NAME', displayName: 'GENERAL.EVENT_GROUP', width: 350 };
        const colProduct: IMonacoColumnDef = { name: 'PRODUCT', displayName: 'BASIC_DATA.PRODUCT', width: 350, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PRODUCT, null, "ID")}}</div>' };
        const colOperationGroup: IMonacoColumnDef = { name: 'OPERATION_GROUP', displayName: 'REGISTRATION.OPERATION', width: 100, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.OPERATION_GROUP)}}</div>' };
        const colModalGroup: IMonacoColumnDef = { name: 'MODAL_GROUP', displayName: 'GENERAL.MODAL', width: 140, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.MODAL_GROUP)}}</div>' };
        const colForwardByGroup: IMonacoColumnDef = { name: 'FORWARDED_BY_GROUP', displayName: 'GENERAL.FORWARDED_BY', width: 140, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.FORWARDED_BY_GROUP)}}</div>' };
        const colProcessTypeGroup: IMonacoColumnDef = { name: 'PROCESS_TYPE_GROUP', displayName: 'BASIC_DATA.FILE_TYPE', width: 140, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PROCESS_TYPE_GROUP)}}</div>' };
        const colServiceTypeGroup: IMonacoColumnDef = { name: 'SERVICE_TYPE_GROUP', displayName: 'BASIC_DATA.SERVICE_TYPE', width: 140, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.SERVICE_TYPE_GROUP)}}</div>' };
        const colCargoTypeGroup: IMonacoColumnDef = { name: 'CARGO_TYPE_GROUP', displayName: 'BASIC_DATA.CARGO_TYPE', width: 140, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.CARGO_TYPE_GROUP)}}</div>' };
        const colIncotermGroup: IMonacoColumnDef = { name: 'INCOTERM_GROUP', displayName: 'BASIC_DATA.INCOTERM', width: 140, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.INCOTERM_GROUP)}}</div>' };
        const colDta: IMonacoColumnDef = { name: 'DTA', displayName: 'REGISTRATION.BONDED_TRUCK', width: 70, cellFilter: 'YesOrNo' };
        const colRequired: IMonacoColumnDef = { name: 'REQUIRED', displayName: 'REGISTRATION.REQUIRED', width: 100, cellFilter: 'YesOrNo' };
        const colDetDemControl: IMonacoColumnDef = { name: 'DET_DEM_CONTROL', displayName: 'OPERATIONAL.DET_DEM_CONTROL', width: 100, cellFilter: 'YesOrNo' };
        const colEventContainerStatus: IMonacoColumnDef = { name: 'EVENT_CONTAINER_STATUS.NAME', displayName: 'OPERATIONAL.EVENT_CONTAINER_STATUS', width: 100 };
        const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
        const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
        const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

        columns.forEach(column => {
            switch (column) {
                case 'INTERNAL_SEQUENCE':
                    gridColumns.push(colInternalSequence);
                    break;
                case 'EVENT_NUMBER':
                    gridColumns.push(colEventNumber);
                    break;
                case 'EVENT':
                    gridColumns.push(colEvent);
                    break;
                case 'CODE':
                    gridColumns.push(colCode);
                    break;
                case 'LOCAL_TYPE':
                    gridColumns.push(collocalType);
                    break;
                case 'EVENT_GROUP':
                    gridColumns.push(colEventGroup);
                    break;
                case 'PRODUCT':
                    gridColumns.push(colProduct);
                    break;
                case 'OPERATION_GROUP':
                    gridColumns.push(colOperationGroup);
                    break;
                case 'MODAL_GROUP':
                    gridColumns.push(colModalGroup);
                    break;
                case 'FORWARDED_BY_GROUP':
                    gridColumns.push(colForwardByGroup);
                    break;
                case 'PROCESS_TYPE_GROUP':
                    gridColumns.push(colProcessTypeGroup);
                    break;
                case 'SERVICE_TYPE_GROUP':
                    gridColumns.push(colServiceTypeGroup);
                    break;
                case 'CARGO_TYPE_GROUP':
                    gridColumns.push(colCargoTypeGroup);
                    break;
                case 'INCOTERM_GROUP':
                    gridColumns.push(colIncotermGroup);
                    break;
                case 'DTA':
                    gridColumns.push(colDta);
                    break;
                case 'REQUIRED':
                    gridColumns.push(colRequired);
                    break;
                case 'DET_DEM_CONTROL':
                    gridColumns.push(colDetDemControl);
                    break;
                case 'EVENT_CONTAINER_STATUS':
                    gridColumns.push(colEventContainerStatus);
                    break;
                case 'ID':
                    gridColumns.push(colId);
                    break;
                case 'CREATED_AT':
                    gridColumns.push(colCreatedAt);
                    break;
                case 'UPDATED_AT':
                    gridColumns.push(colUpdatedAt);
                    break;
            }
        });

        return gridColumns;
    }

    initDependencies(): Promise<any> {
        const self: EventRegisterController = this;
        self.getServiceTypeList();
        self.getIncotermList();

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getSequenceList(),
                self.getGenericList('event'),
                self.getGenericList('group_event'),
                self.getGenericList('type_local_event'),
                self.getGenericList('process_type'),
                self.getGenericList('integration_origin'),
                self.getGenericList('integration_date_type'),
                self.getGenericList('product'),
                self.getGenericList('integration_validation_fields'),
                self.getGenericList('account_qualification'),
                self.getGenericList('integration_target_type'),
                self.getGenericList('forwarded_by'),
                self.getGenericList('type_cargo'),
                self.getGenericList('event_container_status'),

            ]).then((result: any) => {
                self.$scope.sequenceList = result[0].data;
                self.$scope.eventList = result[1];
                self.$scope.eventGroupList = result[2];
                self.$scope.localTypeList = result[3];
                self.$scope.processTypeList = result[4];
                self.$scope.originList = result[5];
                self.$scope.typeList = result[6];
                self.$scope.productList = result[7];
                self.$scope.validationFieldsList = result[8];
                self.$scope.qualificationList = result[9];
                self.$scope.targetTypeList = result[10];
                self.$scope.forwardedByList = result[11];
                self.$scope.typeCargoList = result[12];
                self.$scope.eventContainerStatusList = result[13];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getServiceTypeList(): Promise<void> {
        try {
            this.$scope.serviceTypeList = [];
            const request: IMonacoRequest = {
                data: null,
                route: `/moveType/list/full`,
                timeout: 30000,
            }
            const result = await this.productService.get(request)

            if (result && result.data && result.status == 200) {
                const serviceTypeList: IMoveTypeModel[] = result.data.data;
                for (const serviceItem of serviceTypeList) {
                    const service: SelectorModel = { ID: (serviceItem.ID ? serviceItem.ID.toString() : null), CODE: serviceItem.CODE, NAME: serviceItem.NAME }
                    this.$scope.serviceTypeList.push(service);
                }
            }

        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private async getIncotermList(): Promise<void> {
        try {
            this.$scope.incotermList = [];
            const request: IMonacoRequest = {
                data: null,
                route: `/incoterm/list`,
                timeout: 30000,
            }
            const result = await this.productService.post(request)
            if (result && result.data && result.status == 200) {
                const resultList: IIncotermSelector[] = result.data.data.data;
                for (const resultItem of resultList) {
                    const incoterm: ISelectorModel = { ID: resultItem.ID, CODE: resultItem.INITIALS, NAME: resultItem.NAME }
                    this.$scope.incotermList.push(incoterm);
                }
            }

        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private async getServiceProviderListByName(search: string): Promise<void> {
        try {
            this.$scope.serviceProviderList = [];
            if (search && search.length >= 3) {
                this.block();

                const request: IMonacoRequest = {
                    data: {
                        search
                    },
                    route: `/provider/list/custom`,
                    timeout: 30000,
                }
                const result = await this.productService.post(request)
                if (result && result.data && result.status == 200) {
                    const providerList: IServiceProviderSelector[] = result.data.data;
                    for (const resultItem of providerList) {
                        const serviceProvider: ISelectorModel = { ID: resultItem.ID, CODE: resultItem.SCAC_IATA, NAME: resultItem.NAME }
                        this.$scope.serviceProviderList.push(serviceProvider);
                    }
                }
                this.unblock();
            }
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private async getAccountListByName(search: string): Promise<void> {
        try {
            this.$scope.accountList = []

            if (search && search.length >= 3) {
                this.block();
                const request: IMonacoRequest = {
                    data: {
                        search
                    },
                    route: `/account/list/custom`,
                    timeout: 30000,
                }
                const result = await this.productService.post(request)
                if (result && result.data && result.status == 200) {
                    const accountList: IAccountSelector[] = result.data.data.data;
                    for (const resultItem of accountList) {
                        const account: ISelectorModel = { ID: resultItem.ID, CODE: (resultItem.LEGAL_PERSON && resultItem.LEGAL_PERSON.ID_FISCAL) ? resultItem.LEGAL_PERSON.ID_FISCAL : null, NAME: resultItem.NAME }
                        this.$scope.accountList.push(account);
                    }
                }
                this.unblock();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getGenericList(type): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async getSequenceList(): Promise<any> {
        const result = await this.operationalService.get(`/events/sequence/list`, 10000);
        if (result && result.data) return result.data;
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.$scope.model.INTERNAL_SEQUENCE = (this.$scope.sequenceList && this.$scope.sequenceList.length > 0) ? ((this.$scope.sequenceList[0].SEQUENCE + 1) * 10) : 10;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} (${this.$scope.model.EVENT_NUMBER})`;
            BrowserTitle.$id = this.$scope.model.EVENT_NUMBER;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} (${this.$scope.model.EVENT_NUMBER})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.EVENT_NUMBER;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        const operation = this.$scope.operation === 'register' ? 'insert' : 'update';
        return {
            route: `/events/${operation}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    private addEventEDISetup(): void {
        if (!this.$scope.model.EVENT_EDI_SETUP) this.$scope.model.EVENT_EDI_SETUP = [];

        const newEventEDISetup: EVENT_EDI_SETUP = {
            ORIGIN: null,
            PROVIDER: null,
            EXTERNAL_CODE: null,
            TYPE: null,
            PRODUCT: null,
            VALIDATION_FIELDS: null,
            PARAMS: null
        };

        this.$scope.model.EVENT_EDI_SETUP.push(newEventEDISetup);

        this.timeout(() => {
            for (let i = 0; i < this.$scope.model.EVENT_EDI_SETUP.length; i++) {
                this.$scope.selectorValidity(`Origin${i}`);
                this.$scope.selectorValidity(`Provider${i}`);
                // this.$scope['form'][`ExternalCode${i}`].$setDirty();
                this.$scope.selectorValidity(`ExternalCode${i}`);
                this.$scope.selectorValidity(`Type${i}`);
                this.$scope.selectorValidity(`Product${i}`);
                this.$scope.selectorValidity(`ValidationFields${i}`);
            }
        }, 1000);
    }

    private addEventEDISetupParams(eventEDISetupIndex: number): void {

        if (!this.$scope.model.EVENT_EDI_SETUP[eventEDISetupIndex].PARAMS) this.$scope.model.EVENT_EDI_SETUP[eventEDISetupIndex].PARAMS = [];
        const newEventEDISetupParams: EVENT_EDI_SETUP_PARAMS = {
            PRODUCT: null,
            QUALIFICATION: null,
            CUSTOMER: null,
            ESTIMATED_TARGET: null,
            ESTIMATED_TARGET_TYPE: null,
            ACTUAL_TARGET: null,
            ACTUAL_TARGET_TYPE: null
        };

        this.$scope.model.EVENT_EDI_SETUP[eventEDISetupIndex].PARAMS.push(newEventEDISetupParams);

        this.timeout(() => {
            for (let i = 0; i < this.$scope.model.EVENT_EDI_SETUP[eventEDISetupIndex].PARAMS.length; i++) {
                this.$scope.selectorValidity(`paramsProduct${eventEDISetupIndex}${i}`);
                this.$scope.selectorValidity(`paramsQualification${eventEDISetupIndex}${i}`);
                this.$scope.selectorValidity(`paramsCustomer${eventEDISetupIndex}${i}`);
                // this.$scope['form'][`paramsEstTarget${eventEDISetupIndex}${i}`].$setDirty();
                this.$scope.selectorValidity(`paramsEstTargetType${eventEDISetupIndex}${i}`);
                // this.$scope['form'][`paramsActTarget${eventEDISetupIndex}${i}`].$setDirty();
                this.$scope.selectorValidity(`paramsActTargetType${eventEDISetupIndex}${i}`);
            }
        }, 1000);
    }

    private validDataEDISetupParams(parentIndex: number, index: number, field: string): boolean {
        try {
            let i = 0;
            let valid = true;

            const allConditionsOther = [];
            const allConditionsEdit = [];
            for (const eventEDISetupParams of this.$scope.model.EVENT_EDI_SETUP[parentIndex].PARAMS) {

                let productsParams = eventEDISetupParams.PRODUCT;
                let qualificationsParams = eventEDISetupParams.QUALIFICATION;
                let customersParams = eventEDISetupParams.CUSTOMER;

                const selectorNull: SelectorModel = { "ID": null, "NAME": null };

                if (!productsParams) productsParams = [selectorNull];
                if (!qualificationsParams) qualificationsParams = [selectorNull];
                if (!customersParams) customersParams = [selectorNull];

                for (let product of productsParams) {
                    for (let qualification of qualificationsParams) {
                        for (let customer of customersParams) {
                            if (i !== index) allConditionsOther.push({ PRODUCT: product.ID, QUALIFICATION: qualification.ID, CUSTOMER: customer.ID });
                            else allConditionsEdit.push({ PRODUCT: product.ID, QUALIFICATION: qualification.ID, CUSTOMER: customer.ID });
                        }
                    }
                }
                i = i + 1;
            }

            for (let conditionsEdit of allConditionsEdit) {
                if (allConditionsOther.some(y => y.PRODUCT == conditionsEdit.PRODUCT &&
                    y.QUALIFICATION == conditionsEdit.QUALIFICATION &&
                    y.CUSTOMER == conditionsEdit.CUSTOMER)) {
                    valid = false;
                }
            }

            if (!valid) {
                const errorMsg = this.formService.getTranslate("GENERAL.MESSAGES.SAME_ORIGIN_PROVIDER_EXTERNAL_CODE");
                this.unblock();
                this.timeout(() => {
                    switch (field) {
                        case 'PRODUCT':
                            if (this.$scope.model.EVENT_EDI_SETUP[parentIndex].PARAMS[index].PRODUCT.length > 0) this.$scope.model.EVENT_EDI_SETUP[parentIndex].PARAMS[index].PRODUCT.pop();
                            break;
                        case 'QUALIFICATION':
                            if (this.$scope.model.EVENT_EDI_SETUP[parentIndex].PARAMS[index].QUALIFICATION.length > 0) this.$scope.model.EVENT_EDI_SETUP[parentIndex].PARAMS[index].QUALIFICATION.pop();
                            break;
                        case 'CUSTOMER':
                            if (this.$scope.model.EVENT_EDI_SETUP[parentIndex].PARAMS[index].CUSTOMER.length > 0) this.$scope.model.EVENT_EDI_SETUP[parentIndex].PARAMS[index].CUSTOMER.pop();
                            break;
                    }
                }, 500)
                throw Error(errorMsg)
            }
        } catch (ex) {
            this.handleError(ex);
        }

        return true;
    }

    private validDataEDISetup(parentIndex: number, field: string): boolean {
        try {
            let valid = true;

            const origin = (this.$scope.model.EVENT_EDI_SETUP[parentIndex].ORIGIN) ? this.$scope.model.EVENT_EDI_SETUP[parentIndex].ORIGIN.ID : null;
            const provider = (this.$scope.model.EVENT_EDI_SETUP[parentIndex].PROVIDER) ? this.$scope.model.EVENT_EDI_SETUP[parentIndex].PROVIDER.ID : null;
            const externalCode = this.$scope.model.EVENT_EDI_SETUP[parentIndex].EXTERNAL_CODE;

            let index = 0;
            for (const eventEDISetup of this.$scope.model.EVENT_EDI_SETUP) {
                if (index !== parentIndex) {

                    const originEDISetup = (eventEDISetup.ORIGIN) ? eventEDISetup.ORIGIN.ID : null;
                    const providerEDISetup = (eventEDISetup.PROVIDER) ? eventEDISetup.PROVIDER.ID : null;

                    if (originEDISetup == origin && eventEDISetup.EXTERNAL_CODE == externalCode && providerEDISetup == provider) {
                        valid = false;
                        const errorMsg = this.formService.getTranslate("GENERAL.MESSAGES.SAME_CUSTOMER_PRODUCT_QUALIFICATION");
                        this.unblock();
                        throw Error(errorMsg)
                    }
                }
                index = index + 1;
            }

            if (!valid) {
                switch (field) {
                    case 'ORIGIN':
                        this.$scope.model.EVENT_EDI_SETUP[parentIndex].ORIGIN = null;
                        break;
                    case 'PROVIDER':
                        this.$scope.model.EVENT_EDI_SETUP[parentIndex].PROVIDER = null;
                        break;
                    case 'EXTERNAL_CODE':
                        this.$scope.model.EVENT_EDI_SETUP[parentIndex].EXTERNAL_CODE = null;
                        break;
                }
            }
            return valid;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private validTargets(parentIndex: number): boolean {
        let i = 0;
        if (this.$scope.model.EVENT_EDI_SETUP[parentIndex].PARAMS) {
            for (const eventEDISetupParams of this.$scope.model.EVENT_EDI_SETUP[parentIndex].PARAMS) {
                // this.$scope['form'][`paramsEstTarget${parentIndex}${i}`].$setDirty();
                this.$scope.selectorValidity(`paramsEstTargetType${parentIndex}${i}`);
                // this.$scope['form'][`paramsActTarget${parentIndex}${i}`].$setDirty();
                this.$scope.selectorValidity(`paramsActTargetType${parentIndex}${i}`);
                i = i + 1;
            }
        }
        return true;
    }

    private async removeEventEDISetupParams(parentIndex: number, index: number): Promise<void> {
        const msg = await this.formService.getTranslate("GENERAL.MESSAGES.DELETE_PERMANENTLY_PARAMETER");
        const confirmed = await this.modalService.showModalConfirmation({}, {
            bodyText: msg
        });

        if (!confirmed) return;

        if (this.$scope.model.EVENT_EDI_SETUP[parentIndex]) {
            this.$scope.model.EVENT_EDI_SETUP[parentIndex].PARAMS.splice(index, 1);
        }
    };

    private async removeEventEDISetup(index: number): Promise<void> {
        const msg = await this.formService.getTranslate("GENERAL.MESSAGES.DELETE_PERMANENTLY_PARAMETRIZATION");
        const confirmed = await this.modalService.showModalConfirmation({}, {
            bodyText: msg + (index + 1) + '?'
        });

        if (!confirmed) return;
        this.$scope.model.EVENT_EDI_SETUP.splice(index, 1);
    };

    private async copyEDISetup(index: number): Promise<void> {
        if (!index && index !== 0) throw Error('index is null');
        if (index >= 0) {

            const copyEDISetup = angular.copy(this.$scope.model.EVENT_EDI_SETUP[index]);
            copyEDISetup.EXTERNAL_CODE = null;

            this.$scope.model.EVENT_EDI_SETUP.push(copyEDISetup);

            this.timeout(() => {
                for (let i = 0; i < this.$scope.model.EVENT_EDI_SETUP.length; i++) {
                    this.$scope.selectorValidity(`Origin${i}`);
                    this.$scope.selectorValidity(`Provider${i}`);
                    // this.$scope['form'][`ExternalCode${i}`].$setDirty();
                    this.$scope.selectorValidity(`ExternalCode${i}`);
                    this.$scope.selectorValidity(`Type${i}`);
                    this.$scope.selectorValidity(`Product${i}`);
                    this.$scope.selectorValidity(`ValidationFields${i}`);
                }
            }, 1000);
        }

    };

    private getSequence(): number {
        for (let i = 0; i < this.$scope.sequenceList.length; i++) {
            if (this.$scope.model.EVENT_NUMBER === this.$scope.sequenceList[i].EVENT_NUMBER) {
                return this.$scope.sequenceList[i].SEQUENCE;
            }
        }
    }

    private yesChoice(): void {
        this.$scope.model.REQUIRED = true;
    }

    private async viewLogEvent(event: IEvent): Promise<void> {
        try {
            this.formService.block();
            let route = `/events/log/${event._id.toString()}`;
            const request = await this.operationalService.get(route, null);

            const log: IViewLog = {
                operation: 'history',
                number: event._id.toString(),
                list: [],
                show: true,
                showCloseButton: false,
                searchQuery: '',
                originalList: [],
            }

            log.list = request.data.data;
            log.originalList = angular.copy(log.list);
            this.$scope.log = log;
            this.$scope.customLogProperties = this.getCustomLogProperties();

            const modalId = this.modalService.newModal();
            this.modalService.showModalConfirmation(
                {
                    modalID: modalId,
                    scope: this.$scope,
                    template: require('../../commercial/view/modals/offerLog.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG"
                }
            );
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private getUrlOperational(): string {
        const baseRoute = '/operational';
        const urlDataManager = this.config.operationalUrl + baseRoute;
        return urlDataManager;
    }

    private async getEventById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlOperational()}/events/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.timeout(async () => {
                    const eventData = await this.getEventById(id);
                    if (row && eventData && eventData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = eventData.DOCUMENT_ERROR;
                        documentError = eventData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.modalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.modalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "Integration Product/Operation" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number): Promise<boolean> => {
        let sendSync = false;
        let success = false;
        try {
            if (id) {
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlOperational()}/events/sendSync`, { "idEvent": id }, 120000, false);
                if (syncRequest) sendSync = true;

                success = sendSync;
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_SENDING_REQUEST');
        } finally {
            return success;
        }
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'EVENT_NUMBER',
                LABEL: 'GENERAL.NUMBER'
            },
            {
                PROPERTY: 'CODE',
                LABEL: 'BASIC_DATA.INITIALS'
            },
            {
                PROPERTY: 'EVENT',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'LOCAL_TYPE',
                LABEL: 'ROUTE.LOCAL_TYPE'
            },
            {
                PROPERTY: 'EVENT_GROUP',
                LABEL: 'GENERAL.EVENT_GROUP'
            },
            {
                PROPERTY: 'PRODUCT',
                LABEL: 'BASIC_DATA.PRODUCT'
            },
            {
                PROPERTY: 'OPERATION_GROUP',
                LABEL: 'REGISTRATION.OPERATION'
            },
            {
                PROPERTY: 'MODAL_GROUP',
                LABEL: 'GENERAL.MODAL'
            },
            {
                PROPERTY: 'FORWARDED_BY_GROUP',
                LABEL: 'GENERAL.FORWARDED_BY'
            },
            {
                PROPERTY: 'PROCESS_TYPE_GROUP',
                LABEL: 'BASIC_DATA.FILE_TYPE'
            },
            {
                PROPERTY: 'SERVICE_TYPE_GROUP',
                LABEL: 'BASIC_DATA.SERVICE_TYPE'
            },
            {
                PROPERTY: 'CARGO_TYPE_GROUP',
                LABEL: 'BASIC_DATA.CARGO_TYPE'
            },
            {
                PROPERTY: 'INCOTERM_GROUP',
                LABEL: 'BASIC_DATA.INCOTERM'
            },
            {
                PROPERTY: 'DTA',
                LABEL: 'REGISTRATION.BONDED_TRUCK'
            },
            {
                PROPERTY: 'REQUIRED',
                LABEL: 'REGISTRATION.REQUIRED'
            },
            {
                PROPERTY: 'DET_DEM_CONTROL',
                LABEL: 'OPERATIONAL.DET_DEM_CONTROL'
            },
            {
                PROPERTY: 'EVENT_CONTAINER_STATUS',
                LABEL: 'OPERATIONAL.EVENT_CONTAINER_STATUS'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            }
        ]
        return props;
    }
}

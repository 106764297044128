'use strict';

export default {
    dateFormat: 'dd/MM/yyyy HH:mm',
    defaultTime: '00:00:00',
    html5Types: {
        date: 'dd/MM/yyyy',
        'datetime-local': 'yyyy-MM-ddTHH:mm:ss.sss',
        'month': 'MM/yyyy'
    },
    initialPicker: 'date',
    reOpenDefault: false,
    enableDate: true,
    enableTime: true,
    buttonBar: {
        show: true,
        now: {
            show: true,
            text: 'REGISTRATION.NOW',
            cls: 'btn-sm btn-default'
        },
        today: {
            show: true,
            text: 'REGISTRATION.TODAY',
            cls: 'btn-sm btn-info'
        },
        clear: {
            show: true,
            text: 'REGISTRATION.CLEAR',
            cls: 'btn-sm btn-default'
        },
        date: {
            show: true,
            text: 'GENERAL.DATE',
            cls: 'btn-sm btn-default'
        },
        time: {
            show: true,
            text: 'REGISTRATION.TIME',
            cls: 'btn-sm btn-default'
        },
        close: {
            show: true,
            text: 'GENERAL.SAVE',
            cls: 'btn-sm btn-success'
        },
        cancel: {
            show: false,
            text: 'GENERAL.CANCEL',
            cls: 'btn-sm btn-default'
        }
    },
    closeOnDateSelection: true,
    closeOnTimeNow: true,
    appendToBody: false,
    altInputFormats: [],
    ngModelOptions: {},
    saveAs: false,
    readAs: false,
    datepickerOptions: {
        formatYear: 'yy',
        maxDate: new Date(2030, 1, 1),
        minDate: new Date(1970, 1, 1),
        startingDay: 1,
    },
    timepickerOptions: {
        showMeridian: false
    }
};
import * as angular from 'angular';

const storagePreviewState = {
    name: 'storage',
    url: '/storage/:hash',
    template: require("./view/storagePreview.html"),
    params: { hash: undefined }
}

const storageFilesGenericPreviewState = {
    name: 'storageFilesGeneric',
    url: '/storageFilesGeneric/:hash',
    template: require("./view/storageFilesGenericPreview.html"),
    params: { hash: undefined }
}

const errorState = {
    name: 'error',
    url: '/error',
    template: require("./view/error.html")
}

routeConfig.$inject = ['$uiRouterProvider'];
function routeConfig($uiRouterProvider) {
    const $urlService = $uiRouterProvider.urlService;
    $urlService.rules.otherwise(function ($injector) {
        const $state = $injector.get("$state");
        $state.go('error');
    });

    const $stateRegistry = $uiRouterProvider.stateRegistry;
    $stateRegistry.register(storagePreviewState);
    $stateRegistry.register(storageFilesGenericPreviewState);
    $stateRegistry.register(errorState);
}

export default function initRoute(moduleName: string): void {
    angular.module(moduleName).config(routeConfig);
}
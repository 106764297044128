import angular = require("angular");
import moment = require("moment");
import { fileUploader as FileUploader } from 'angular-file-upload';
import { GridFormService, IGridFormController, IGridFormServiceScope, IMonacoRequest } from "@services/GridFormService";
import { OperationalService } from "@services/OperationalService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IRestService } from "@services/RestService";
import { ProductService } from "@services/ProductService";
import { IModalService } from "@services/ModalService";
import { ISessionService } from "@services/SessionService";
import { ICustomLogProperties } from "@models/interface/common/IViewLog";
import { CHARGE } from "@models/interface/operational/DraftViewModel";
import { DraftViewModel } from "@models/interface/operational/DraftViewModel";
import { DRAFT, REFERENCE } from "@models/interface/operational/Process";
import { IRoutingPointListCustomFilter } from "@models/interface/product/RoutingPointModel";
import { DraftDocument, AirDraftDocument, OTHER_CHARGES_LIST } from "@models/interface/operational/DraftModel";
import { IViewLog } from "@models/interface/common/IViewLog";
import { Process, ENTITY } from "@models/interface/operational/NewProcess";
import { IUploader, IFileItem, ISuccessItemResponse, IUploadItem } from "@models/interface/common/IMonacoUpload";
import { IFormData } from "@models/interface/product/Upload";
import { ECargoTypeId, EProductId, EDocumentTypeId, EFileGroupId, EFileSpecsId, EOperation, EChargeOriginId, ETypePaymentId } from "@enums/GenericData";
import { EFileReferenceSource } from "@enums/FisUploadRequest";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { BrowserTitle } from "../../common/BrowserTitle";
import { SelectorModel } from "../../common/model/SelectorModel";
import { IProcessParameter, IProcessDocumentParameter } from "../../common/model/ModelParameter";
import { IFiles } from "../../app/controllers/FileReferenceModalController";
import { HelperService } from "@services/HelperService";
import { dateTime } from "src/ts/app/filter/filters";

enum DraftSource {
    Internal = "Internal",
    External = "External",
    HouseAWB = "HouseAWB",
    MasterAWB = "MasterAWB"
}

interface IDraftStatus {
    icon: string;
    title: string;
}

interface IDraftValidation {
    shipper: string;
    consignee: string;
    cnpj: string;
    notify: string;
    marks: string;
    cargo: string;
    cargoInstructions: string;
    documentType: string;
    originPort: string;
    destinationPort: string;
    finalDestination: string;
    placeReceipt: string;
    seller: string;
    buyer: string;
    shipTo: string;
    supplier: string;
    containerStuffing: string;
    consolidator: string;
    corporateBranch: string;
}

interface IBranch {
    selectedBranch: SelectorModel;
}

interface IDraftRegisterScope extends IGridFormServiceScope {
    form: ng.IFormController;
    model: DraftViewModel;
    draft: DRAFT;
    document: DraftDocument;
    airDocument: AirDraftDocument;
    uploader: IUploader;
    fileModel: IFiles;
    validation: IDraftValidation;
    releaseTypeOptions: SelectorModel[];
    woodenPackageType: SelectorModel[];
    routingPointList: SelectorModel[];
    equipmentTypeList: SelectorModel[];
    packageList: SelectorModel[];
    documentTypeList: SelectorModel[];
    fileGroupList: SelectorModel[];
    fileSpecsList: SelectorModel[];
    rateClass: SelectorModel[];
    corporateBranchList: SelectorModel[];
    branch: IBranch;
    customLogProperties: ICustomLogProperties[];
    draftDocumentTypes: string[];
    KL: string[];
    user: any;
    aux: any;
    getStatusIcon: (status: string) => IDraftStatus;
    formatTextArea: (property: string, maxRows: number, maxCols: number, htmlComponent: string, label: string) => void;
    preventSpecialChars: (event, element) => void;
    isProcessSituationValidity: (model: DraftViewModel) => boolean;
    validateDeadline: (model: DraftViewModel) => boolean;
    validateTextAllowOnlyLetterAndNumber: (event, element) => void;

    editDraftValidity: (model: DraftViewModel) => boolean;
    receiveDraftValidity: (model: DraftViewModel) => boolean;
    newDraftValidity: (model: DraftViewModel) => boolean;
    inactivateDraftValidity: (model: DraftViewModel) => boolean;
    sendDraftValidity: (model: DraftViewModel) => boolean;
    printDraftValidity: (model: DraftViewModel) => boolean;

    receiveUpdate: (model: DraftViewModel) => Promise<void>;
    sendUpdate: (model: DraftViewModel) => Promise<void>;
    inactivateUpdate: (model: DraftViewModel) => Promise<void>;
    print: (model: DraftViewModel) => Promise<void>;

    viewLog: (model: DraftViewModel) => void;

    isCnpjValidity: () => void;
    isCorporateBranchValidity: (selectedBranch, htmlComponent: string) => void;
    getRoutingPointListByName: (search: string) => Promise<void>;
    addCargoList: () => void;
    addAirCargoList: () => void;
    addAirOtherChargeList: () => void;
    removeCargoList: (index: number) => void;
    removeAirCargoList: (index: number) => void;
    removeOtherChargeList: (index: number) => void;
    removeUpload: (model: IUploadItem) => Promise<boolean>;
    sumCargoList: () => void;
    sumAirCargoList: () => void;
    sumAirOtherChargeList: () => void;
    containerValidation: (containerNumber: string, elementName: string, index: number) => void;
    cargoContainerizedChange: (index: number) => void;
    isContainerizedCargoAllowedToChange(containerizedCurrentState: boolean)
    sealValidation: (sealNumber: string, elementName: string, index: number) => void;
    netWeightValidation: (netWeight: string, elementName: string, index: number) => void;
    updateCacheDraft: (processNumber: string, typeDocument: string) => void;

    loadCopiedData: () => void;

    goToProcess: (process: string) => void;
    goToDocument: (processNumber: string, draftNumber: string) => void;

    decodeBoolean: (value: boolean) => void;
    getDraftDocumentTypes: () => void;
    getKL: () => void;
    getDraftType: (type: string) => void;
    formatInput: (property: string, htmlComponent: string, label: string) => void;
    getCorporateBranch: (search: string) => Promise<void>;

    source: string;
}

export class DraftRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ["$injector", "$scope", "$element"];
    private $scope: IDraftRegisterScope;
    private OperationalService: OperationalService;
    private ProductService: ProductService;
    private RestService: IRestService;
    private fileUploader: FileUploader;
    private $q: ng.IQService;
    private regex: RegExp;
    private $timeout: ng.ITimeoutService;
    private ModalService: IModalService;
    private sessionService: ISessionService;
    private regexOnlyNumberAndLetter: RegExp;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IDraftRegisterScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.OperationalService = $injector.get("OperationalService");
        this.RestService = $injector.get("RestService");
        this.fileUploader = $injector.get("FileUploader");
        this.ProductService = $injector.get("ProductService");
        this.sessionService = $injector.get('SessionService');
        this.ModalService = $injector.get('ModalService');
        this.$q = $injector.get("$q");
        this.$timeout = $injector.get('$timeout');
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.OperationalService.$route;
            const isInternal: boolean = await this.permissionService.isRoleAllowed("DRAFTINTERNAL");
            const isExternal: boolean = await this.permissionService.isRoleAllowed("DRAFTEXTERNAL");

            if (isInternal && isExternal) {
                this.$scope.source = DraftSource.Internal;
            } else {
                this.$scope.source = (isInternal) ? DraftSource.Internal : DraftSource.External;
            }

            this.initForm(this, "form", "draftRegister", "GENERAL.MENU.DRAFT_RECEPTION", false);
            await this.initGrid("draftRegister", "/draft/list", true, true, 120000, true, true);
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.regex = new RegExp(`^[- a-zA-Z0-9:='"!?@#%&*()+$/{};.,]+$`);
            this.regexOnlyNumberAndLetter = new RegExp(`[^ A-Za-z0-9]`);
            this.$scope.branch = {
                selectedBranch: null
            };

            this.$scope.validation = {
                shipper: "",
                consignee: "",
                cnpj: "",
                notify: "",
                marks: "",
                cargo: "",
                cargoInstructions: "",
                documentType: "",
                originPort: "",
                destinationPort: "",
                finalDestination: "",
                placeReceipt: "",
                seller: "",
                buyer: "",
                shipTo: "",
                supplier: "",
                containerStuffing: "",
                consolidator: "",
                corporateBranch: "",
            };

            // Remove draft auto update
            // this.$gridService.setBackgroundUpdate(10000);

            this.$scope.aux.copyBookingNumber = null;
            this.$scope.routingPointList = [];
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
        if (this.$scope.uploader) this.$scope.uploader.destroy();
    }

    getFisFilesGenericRoute(): string {
        const baseRoute = '/fis/filesGeneric';
        const uploadRoute = this.config.fisUrl + baseRoute;
        return uploadRoute;
    }

    initGridColumns(): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<span ng-if="row.entity.DRAFT.DRAFT_NUMBER">&nbsp;<a ng-click="grid.appScope.view(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search" aria-hidden="true"></i></a></span>`;

        let add = "";
        let copy = "";
        let edit = "";
        let received = "";
        let release = "";
        let send = "";
        let print = "";
        let inactivate = "";
        let history = "";

        switch (this.$scope.source) {
            case DraftSource.Internal:
            case DraftSource.HouseAWB:
            case DraftSource.MasterAWB: {
                add = `<span ng-if="grid.appScope.isProcessSituationValidity(row.entity)">&nbsp;<a ng-click="grid.appScope.register(row.entity)" class="text-success" tooltip-placement="auto top" uib-tooltip="{{ 'REGISTRATION.ADD' | translate }}" tooltip-append-to-body="true"><i class="fa fa fa-plus icon"></i></a></span>`;
                edit = `<span ng-if="grid.appScope.editDraftValidity(row.entity) && grid.appScope.isProcessSituationValidity(row.entity)">&nbsp;<a ng-click="grid.appScope.edit(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a></span>`;
                received = `<span ng-if="grid.appScope.receiveDraftValidity(row.entity) && grid.appScope.isProcessSituationValidity(row.entity)">&nbsp;<a ng-click="grid.appScope.receiveUpdate(row.entity)" class="text-success" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.RECEIVED' | translate }}" tooltip-append-to-body="true"><i class="fa fa-check icon"></i></a></span>`;
                inactivate = `<span ng-if="grid.appScope.inactivateDraftValidity(row.entity) && grid.appScope.isProcessSituationValidity(row.entity)">&nbsp;<a ng-click="grid.appScope.inactivateUpdate(row.entity)" class="text-rouge" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INACTIVATE' | translate }}" tooltip-append-to-body="true"><i class="fa fa-minus-circle"></i></a></span>`;
                release = `<span ng-if="grid.appScope.sendDraftValidity(row.entity) && grid.appScope.isProcessSituationValidity(row.entity)">&nbsp;<a ng-click="grid.appScope.sendUpdate(row.entity)" class="text-blue" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.RELEASE_UPDATE' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-unlock-alt"></i></a></span>`;
                history = `<span>&nbsp;<a ng-click="grid.appScope.viewLog(row.entity)" class="text-green" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a></span>`;
                print = `<span ng-if="grid.appScope.printDraftValidity(row.entity) && grid.appScope.isProcessSituationValidity(row.entity)">&nbsp;<a ng-click="grid.appScope.print(row.entity)" class="text-gray" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.PRINT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-print icon"></i></a></span>`;
            }
                break;

            case DraftSource.External: {
                add = `<span ng-if="grid.appScope.newDraftValidity(row.entity) && grid.appScope.isProcessSituationValidity(row.entity)">&nbsp;<a ng-click="grid.appScope.register(row.entity)" class="text-success" tooltip-placement="auto top" uib-tooltip="{{ 'REGISTRATION.ADD' | translate }}" tooltip-append-to-body="true"><i class="fa fa fa-plus icon"></i></a></span>`;
                send = `<span ng-if="grid.appScope.sendDraftValidity(row.entity) && grid.appScope.isProcessSituationValidity(row.entity) && grid.appScope.validateDeadline(row.entity)">&nbsp;<a ng-click="grid.appScope.sendUpdate(row.entity)" class="text-primary-lter" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.SEND' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-paper-plane"></i></a></span>`;
                edit = `<span ng-if="grid.appScope.editDraftValidity(row.entity) && grid.appScope.isProcessSituationValidity(row.entity)">&nbsp;<a ng-click="grid.appScope.edit(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a></span>`;
                copy = `<span ng-if="grid.appScope.isProcessSituationValidity(row.entity) && row.entity.DRAFT.DRAFT_NUMBER">&nbsp;<a ng-click="grid.appScope.copy(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a></span>`
                print = `<span ng-if="grid.appScope.printDraftValidity(row.entity)">&nbsp;<a ng-click="grid.appScope.print(row.entity)" class="text-gray" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.PRINT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-print icon"></i></a></span>`;
            }
                break;
        }

        const actionColDef: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 120,
            maxWidth: 120,
            cellTemplate: `<span>&nbsp;&nbsp;${add + view + copy + edit + inactivate + received + send + release + print + history}</span>`,
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.$columnDefs.push(actionColDef);

        const statusColDef: IMonacoColumnDef = {
            name: "status",
            displayName: "OPERATIONAL.STATUS",
            minWidth: 75,
            maxWidth: 75,
            cellTemplate: (`<div class="text-center"><i tooltip-placement="auto toolttop" uib-tooltip="{{grid.appScope.getStatusIcon(row.entity.DRAFT.STATUS.NAME).title}}" tooltip-append-to-body="true" class={{grid.appScope.getStatusIcon(row.entity.DRAFT.STATUS.NAME).icon}}></i></div>`),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            allowCellFocus: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true,
            visible: true,
        }
        gridColumns.$columnDefs.push(statusColDef);

        const columnDefs = this.buildColumns();
        for (const column of columnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            let colProcessNumber: IMonacoColumnDef = { name: "PROCESS_NUMBER", displayName: "OPERATIONAL.FILE_NUMBER", width: 120, cellTemplate: '<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="Click view process {{row.entity.PROCESS_NUMBER}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToProcess(row.entity.PROCESS_NUMBER)">{{row.entity.PROCESS_NUMBER}}</a><span class="label label-danger" ng-if="row.entity.PROCESS.PRIORITY.ID == \'2\'"><i class="text-warning fa fa-bolt"></i></span></div>' };
            let colProcessType: IMonacoColumnDef = { name: "PROCESS_TYPE.NAME", displayName: "BASIC_DATA.FILE_TYPE", width: 170 };
            let colCommercialunity: IMonacoColumnDef = { name: "COMMERCIAL_UNITY.NAME", displayName: "BASIC_DATA.COMMERCIAL_BRANCH", width: 170 };
            let colDraftNumber: IMonacoColumnDef = { name: "DRAFT.DRAFT_NUMBER", displayName: "OPERATIONAL.PROTOCOL", width: 170, cellTemplate: '<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="Click view protocol {{row.entity.DRAFT.DRAFT_NUMBER}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToDocument(row.entity.PROCESS_NUMBER, row.entity.DRAFT.DRAFT_NUMBER)">{{row.entity.DRAFT.DRAFT_NUMBER}}</a></div>' };

            if (this.$scope.source === DraftSource.External) {
                colProcessNumber = { name: "PROCESS_NUMBER", displayName: "OPERATIONAL.FILE_NUMBER", width: 120 };
                colDraftNumber = { name: "DRAFT.DRAFT_NUMBER", displayName: "OPERATIONAL.PROTOCOL", width: 170 };
            }

            const colProduct: IMonacoColumnDef = { name: "PRODUCT.NAME", displayName: "BASIC_DATA.PRODUCT", width: 150 };
            const colDraftSubmissionDate: IMonacoColumnDef = { name: "DRAFT.SUBMISSION_DATE", displayName: "GENERAL.SEND_DATE", width: 160, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colDraftReceiptDate: IMonacoColumnDef = { name: "DRAFT.RECEIPT_DATE", displayName: "GENERAL.RECEIPT_DATE", width: 160, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colBooking: IMonacoColumnDef = { name: "BOOKING", displayName: "OPERATIONAL.BOOKING", width: 120 };
            const colCustomer: IMonacoColumnDef = { name: "CUSTOMER.NAME", displayName: "BASIC_DATA.CLIENT", width: 350 };
            const colBroker: IMonacoColumnDef = { name: "BROKER.NAME", displayName: "BASIC_DATA.CUSTOMS_BROKER", width: 350 };
            const colVesselVoy: IMonacoColumnDef = { name: "EVENT_LOAD.TRANSPORT_MODE.NAME", displayName: "OPERATIONAL.VESSEL_VOYAGE", width: 170 };
            const colForecastDate: IMonacoColumnDef = { name: "EVENT_LOAD.FORECAST_DATE", displayName: "OPERATIONAL.ETD_ORIGIN", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colPol: IMonacoColumnDef = { name: "EVENT_LOAD.LOCAL.NAME", displayName: "BASIC_DATA.ORIGIN_LOCATION", width: 170 };
            const colPod: IMonacoColumnDef = { name: "EVENT_DISCH.LOCAL.NAME", displayName: "BASIC_DATA.DESTINATION_LOCATION", width: 170 };
            const colCargoType: IMonacoColumnDef = { name: "CARGO_TYPE.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 125 };
            const colServiceType: IMonacoColumnDef = { name: "SERVICE_TYPE.NAME", displayName: "BASIC_DATA.SERVICE_TYPE", width: 150 };
            const colLocalAgentType: IMonacoColumnDef = { name: "LOCAL_AGENT.NAME", displayName: "BASIC_DATA.LOCAL_AGENT", width: 350 };
            const colExternalAgentType: IMonacoColumnDef = { name: "EXTERNAL_AGENT.NAME", displayName: "BASIC_DATA.OVERSEAS_AGENT", width: 350 };
            const colMasterDirect: IMonacoColumnDef = { name: "MASTER_DIRECT", displayName: "BASIC_DATA.STRAIGHT_BL", width: 120, cellTemplate: '<div class="grid-padding" >{{grid.appScope.decodeBoolean(row.entity.MASTER_DIRECT)}}</div>' };
            const colInstruction: IMonacoColumnDef = { name: "INSTRUCTION.OPERATIONAL", displayName: "OPERATIONAL.OPERATIONAL_INSTRUCTION", width: 200 };
            const colSource: IMonacoColumnDef = { name: "DRAFT.SOURCE", displayName: "GENERAL.SOURCE", width: 150 };
            const colDeadlineHouseVgm: IMonacoColumnDef = { name: "DEADLINE_DATES.VGM_HOUSE", displayName: "OPERATIONAL.VGM", width: 180, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colDeadlineHouseDraft: IMonacoColumnDef = { name: "DEADLINE_DATES.DRAFT_HOUSE", displayName: "OPERATIONAL.DRAFT", width: 180, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colDeadlineHouseDraftImo: IMonacoColumnDef = { name: "DEADLINE_DATES.DRAFT_IMO_HOUSE", displayName: "OPERATIONAL.DRAFT_IMO", width: 180, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colDeadlineHouseRelease: IMonacoColumnDef = { name: "DEADLINE_DATES.RELEASE_HOUSE", displayName: "OPERATIONAL.DEADLINE_RELEASE", width: 180, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colDeadlineMasterDraft: IMonacoColumnDef = { name: "DEADLINE_DATES.DRAFT_MASTER", displayName: "OPERATIONAL.DEADLINE_DRAFT_MASTER", width: 180, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colCustomerReference: IMonacoColumnDef = { name: "CUSTOMER_REF", displayName: "OPERATIONAL.CLIENT_REFERENCE", width: 180 };

            columnDefs.push(colProcessNumber, colProcessType, colCommercialunity);
            columnDefs.push(colCustomerReference);
            columnDefs.push(colProduct);
            columnDefs.push(colDraftNumber);
            columnDefs.push(colBooking);
            columnDefs.push(colDraftSubmissionDate);

            if (this.$scope.source === DraftSource.Internal) {
                columnDefs.push(colDraftReceiptDate);
                columnDefs.push(colCustomer);
                columnDefs.push(colBroker);
                columnDefs.push(colPol);
                columnDefs.push(colPod);
                columnDefs.push(colCargoType);
                columnDefs.push(colServiceType);
            }

            columnDefs.push(colVesselVoy);
            columnDefs.push(colForecastDate);
            columnDefs.push(colDeadlineHouseVgm);
            columnDefs.push(colDeadlineHouseDraft);
            columnDefs.push(colDeadlineHouseDraftImo);

            if (this.$scope.source === DraftSource.Internal) {
                columnDefs.push(colDeadlineHouseRelease);
                columnDefs.push(colDeadlineMasterDraft);
                columnDefs.push(colLocalAgentType);
                columnDefs.push(colExternalAgentType);
                columnDefs.push(colMasterDirect);
                columnDefs.push(colInstruction);
                columnDefs.push(colSource);
            }

            if (this.$scope.source === DraftSource.External) {
                columnDefs.push(colDeadlineHouseRelease);
            }

            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initScopeFunctions(): void {
        this.$scope.getStatusIcon = (status: string) => {
            return this.getStatusIcon(status);
        }

        this.$scope.formatTextArea = (property: string, maxRows: number, maxCols: number, htmlComponent: string, label: string) => {
            this.formatTextArea(property, maxRows, maxCols, htmlComponent, label);
        }

        this.$scope.preventSpecialChars = (event, element) => {
            this.preventSpecialChars(event, element);
        }

        this.$scope.validateTextAllowOnlyLetterAndNumber = (element) => {
            this.validateTextAllowOnlyLetterAndNumber(element);
        }

        this.$scope.validateDeadline = (model: DraftViewModel) => {
            return this.newDraftValidity(model);
        }

        this.$scope.isProcessSituationValidity = (model: DraftViewModel) => {
            return this.isProcessSituationValidity(model);
        }

        this.$scope.editDraftValidity = (model: DraftViewModel) => {
            return this.editDraftValidity(model);
        }

        this.$scope.receiveDraftValidity = (model: DraftViewModel) => {
            return this.receiveDraftValidity(model);
        }

        this.$scope.newDraftValidity = (model: DraftViewModel) => {
            return this.newDraftValidity(model);
        }

        this.$scope.inactivateDraftValidity = (model: DraftViewModel) => {
            return this.inactivateDraftValidity(model);
        }

        this.$scope.sendDraftValidity = (model: DraftViewModel) => {
            return this.sendDraftValidity(model);
        }

        this.$scope.printDraftValidity = (model: DraftViewModel) => {
            return this.printDraftValidity(model);
        }

        this.$scope.receiveUpdate = (model: DraftViewModel) => {
            return this.receiveUpdate(model);
        }

        this.$scope.sendUpdate = (model: DraftViewModel) => {
            return this.sendUpdate(model);
        }

        this.$scope.inactivateUpdate = (model: DraftViewModel) => {
            return this.inactivateUpdate(model);
        }

        this.$scope.print = (model: DraftViewModel) => {
            return this.print(model);
        }

        this.$scope.isCnpjValidity = () => {
            this.isCnpjValidity();
        }

        this.$scope.isCorporateBranchValidity = (selectedBranch, htmlComponent) => {
            this.isCorporateBranchValidity(selectedBranch, htmlComponent);
        }

        this.$scope.getRoutingPointListByName = async (search: string): Promise<void> => {
            this.$scope.routingPointList = await this.getRoutingPointListByName({ name: search, types: ['2'] });
        }

        this.$scope.addCargoList = () => {
            this.addCargoList();
        }

        this.$scope.addAirCargoList = () => {
            this.addAirCargoList();
        }

        this.$scope.addAirOtherChargeList = () => {
            this.addAirOtherChargeList();
        }

        this.$scope.removeCargoList = (index: number) => {
            this.removeCargoList(index);
        }

        this.$scope.removeAirCargoList = (index: number) => {
            this.removeAirCargoList(index);
        }

        this.$scope.removeOtherChargeList = (index: number) => {
            this.removeOtherChargeList(index);
        }

        this.$scope.removeUpload = (model: IUploadItem): Promise<boolean> => {
            return this.removeUpload(model);
        }

        this.$scope.sumCargoList = () => {
            this.sumCargoList();
        }

        this.$scope.sumAirCargoList = () => {
            this.sumAirCargoList();
        }

        this.$scope.sumAirOtherChargeList = () => {
            this.sumAirOtherChargeList();
        }

        this.$scope.containerValidation = (containerNumber: string, elementName: string, index: number) => {
            this.containerValidation(containerNumber, elementName, index);
        }

        this.$scope.cargoContainerizedChange = (index: number) => {
            this.cargoContainerizedChange(index);
        }

        this.$scope.isContainerizedCargoAllowedToChange = (containerizedCurrentState: boolean) => {
            return this.isContainerizedCargoAllowedToChange(containerizedCurrentState);
        }

        this.$scope.viewLog = (model: DraftViewModel) => {
            this.viewLog(model);
        }

        this.$scope.loadCopiedData = async () => {
            await this.loadCopiedData();
        }

        this.$scope.goToProcess = (process: string) => {
            this.goToProcess(process);
        }

        this.$scope.goToDocument = (processNumber: string, draftNumber: string) => {
            this.goToDocument(processNumber, draftNumber);
        };

        this.$scope.decodeBoolean = (value: boolean): string => {
            return this.decodeBoolean(value);
        }

        this.$scope.getDraftDocumentTypes = () => {
            this.$scope.draftDocumentTypes = this.getDraftDocumentTypes();
        }

        this.$scope.getKL = () => {
            this.$scope.KL = this.getKL();
        }

        this.$scope.getDraftType = (draftType: string) => {
            this.getDraftType(draftType);
        }

        this.$scope.formatInput = (property: string, htmlComponent: string, label: string) => {
            this.formatInput(property, htmlComponent, label);
        }

        this.$scope.sealValidation = (containerNumber: string, elementName: string, index: number) => {
            this.sealValidation(containerNumber, elementName, index);
        }

        this.$scope.netWeightValidation = (netWeight: string, elementName: string, index: number) => {
            this.netWeightValidation(netWeight, elementName, index);
        }

        this.$scope.getCorporateBranch = async (search: string): Promise<void> =>
            this.getCorporateBranch(search);

        this.$scope.updateCacheDraft = async (processNumber: string, typeDocument: string): Promise<void> => {
            this.updateCacheDraft(processNumber, typeDocument);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            PROCESS_NUMBER: null,
            PRODUCT: null,
            SITUATION: null,
            CUSTOMER: null,
            CUSTOMER_REF: null,
            CARGO_TYPE: null,
            SERVICE_TYPE: null,
            MASTER_DIRECT: null,
            LOCAL_AGENT: null,
            EXTERNAL_AGENT: null,
            BROKER: null,
            IMPORTER: null,
            EXPORTER: null,
            NOTIFY: null,
            INSTRUCTION: null,
            DRAFT: null,
            EVENT_DISCH: null,
            EVENT_LOAD: null,
            EVENT_FINAL_DESTINATION: null,
            EVENT_PLACE_OF_RECEIPT: null,
            BOOKING: null,
            HOUSE_PAYMENT: null,
            MASTER_PAYMENT: null,
            CARGO_DETAIL: null,
            CARGO_LIST: null,
            DEADLINE_DATES: {
                DRAFT_HOUSE: null,
                DRAFT_IMO_HOUSE: null,
                DRAFT_MASTER: null,
                RELEASE_HOUSE: null,
                VGM_HOUSE: null
            },
            BRANCH: null,
            CHARGE: null,
            SERVICE_PROVIDER: null,
            EVENT_CONN_ARRIVAL: null,
            FREIGHT_CONTRACT: null,
            CONTRACT_TYPE: null,
            COMMODITY_SUMMARY: null,
            COMMERCIAL_INVOICE: null,
            HOUSE_DOCUMENT: null,
            MASTER_DOCUMENT: null,
            SPOT_RATE: null,
        };

        this.$scope.document = {
            SHIPPER: null,
            CONSIGNEE: null,
            NOTIFY: null,
            RUC: null,
            DUE: null,
            PLACE_OF_RECEIPT_BL: null,
            PORT_OF_LOADING_BL: null,
            PORT_OF_DISCHARGE_BL: null,
            FINAL_DESTINATION_BL: null,
            MARKS_NUMBERS: null,
            CARGO_DESCRIPTION: null,
            INSTRUCTION: null,
            RELEASE_TYPE: null,
            WOODEN_PACKAGE: null,
            CARGO_LIST: [],
            TOTAL: {
                CBM: 0,
                CNTR: 0,
                GROSS_WEIGHT: 0,
                NET_WEIGHT: 0,
                QTY: 0
            },
            RECIPIENTS: null,
            CNPJ: null,
            PORT_OF_LOADING: null,
            DATE_OF_LOADING: null,
            LAST_FOREIGN_PORT: null,
            DATE_ON_LAST_FOREIGN_PORT: null,
            FIRST_AMERICAN_PORT: null,
            DATE_ON_FIRST_AMERICAN_PORT: null,
            PORT_OF_ARRIVAL: null,
            DATE_OF_ARRIVAL: null,
            SELLER: null,
            BUYER: null,
            SHIP_TO: null,
            MANUFACTURER_SUPLIER: null,
            CONTAINER_STUFFING_LOCATION: null,
            CONSOLIDATOR: null
        };

        this.$scope.airDocument = {
            SHIPPER: null,
            TAX_ID_EXPORTER: null,
            TAX_ID_IMPORTER: null,
            CONSIGNEE: null,
            DUE: null,
            RUC: null,
            ISSUER: null,
            ISSUING_CARRIER_AGENT: null,
            IATA_NUMBER: null,
            ACCOUNTING_INFORMATION: null,
            DEPARTURE: null,
            DEPARTURE_TO_PRINT_ON_AWB: null,
            FLIGHT: null,
            DESTINATION: null,
            DESTINATION_TO_PRINT_ON_AWB: null,
            FIRST_TO: null,
            FIRST_BY: null,
            SECOND_TO: null,
            SECOND_BY: null,
            THIRD_TO: null,
            THIRD_BY: null,
            HANDLING_INFORMATION: null,
            SCI: null,
            CURRENCY: null,
            VALUE_FOR_CARRIAGE: null,
            VALUE_FOR_CUSTOMS: null,
            AMOUNT_OF_INSURANCE: null,
            CHARGE_CODE: null,
            WT_VAL: null,
            OTHER_CHARGES: null,
            AIR_CARGO_LIST: [{
                PIECES: null,
                GROSS_WEIGHT: null,
                K_L: null,
                RATE_CLASS: null,
                ITEM_NO: null,
                CHARGEABLE_WEIGHT: null,
                RATE_CHARGE: null,
                TOTAL: null,
                NATURE_AND_QUANTITY: null,
            }],
            TOTAL_LIST: {
                TOTAL_PIECES: null,
                TOTAL_GROSS_WEIGHT: null,
                TOTAL_CHARGEABLE_WEIGHT: null,
                TOTAL_VALUE: null,
                K_L: null,
            },
            WEIGHT_CHARGE: null,
            VALUATION_CHARGE: null,
            TAX: null,
            OTHER_CHARGES_DUE_AGENT: null,
            OTHER_CHARGES_DUE_CARRIER: null,
            TOTAL: null,
            OTHER_CHARGES_LIST: [{
                DESCRIPTION: null,
                AMOUNT: null,
                ENTITLEMENT: null
            }],
            CURRENCY_CONVERTION_RATES: null,
            DESTINATION_CC_CHARGES: null,
            CHARGES_AT_DESTINATION: null,
            TOTAL_COLLECT_CHARGES: null,
            SHIPPER_CERTIFICATION: null,
            CARRIER_EXECUTION: null
        }

        this.$scope.draft = {
            DRAFT_NUMBER: null,
            DOCUMENT: {
                EXTERNAL: this.$scope.document,
                INTERNAL: this.$scope.document,
                MASTERAWB: this.$scope.airDocument,
                HOUSEAWB: this.$scope.airDocument,
            },
            STATUS: null,
            SUBMISSION_DATE: null,
            RECEIPT_DATE: null,
            SOURCE: this.$scope.source,
            CREATED_DATE: null,
            CREATED_BY: null,
            REFERENCE: []
        }

        this.$scope.fileModel = { FILES: [] };
        this.$scope.aux = {
            copyBookingNumber: null
        }
        this.$scope.branch = {
            selectedBranch: null
        };
        this.$scope.corporateBranchList = [];
    }

    initDependencies(): Promise<any> {
        const self: DraftRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericType("draft_release_type"),
                self.getGenericType("draft_wooden_package_type"),
                self.getGenericType("package_type"),
                self.getGenericType("document_type"),
                self.getGenericType("file_group"),
                self.getGenericType("file_specs"),
                self.getGenericType("draft_rate_class"),
            ]).then(async (result: any) => {
                self.$scope.releaseTypeOptions = result[0];
                self.$scope.woodenPackageType = result[1];
                self.$scope.packageList = result[2];
                self.$scope.documentTypeList = result[3];
                self.$scope.fileGroupList = result[4];
                self.$scope.fileSpecsList = result[5];
                self.$scope.rateClass = result[6];
                self.$scope.equipmentTypeList = [];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    async register(model: DraftViewModel): Promise<void> {
        try {
            this.$scope.model = model;
            this.$scope.operation = "register";
            this.initControl();
            this.buildReferences();

            this.$scope.formOperation = `${this.formService.getTranslate("OPERATIONAL.NEW_DRAFT")} (${this.$scope.model.PROCESS_NUMBER})`;

            BrowserTitle.$id = this.$scope.model.PROCESS_NUMBER;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.operation = EOperation.VIEW;
            this.initControl();
            this.buildReferences();

            this.$scope.formOperation = `${this.formService.getTranslate("OPERATIONAL.VIEW_DRAFT")} (${this.$scope.model.PROCESS_NUMBER})`;

            BrowserTitle.$id = this.$scope.model.PROCESS_NUMBER;
            this.$scope.branch.selectedBranch = this.$scope.model.BRANCH;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.operation = "edit";
            this.initControl();
            this.buildReferences();

            this.$scope.formOperation = `${this.formService.getTranslate("OPERATIONAL.EDIT_DRAFT")} (${this.$scope.model.PROCESS_NUMBER})`;

            BrowserTitle.$id = this.$scope.model.PROCESS_NUMBER;
            this.$scope.branch.selectedBranch = this.$scope.model.BRANCH;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.aux.copyBookingNumber = null;
            this.$scope.operation = "copy";

            await this.clearFields(this.$scope.model);

            this.initControl();
            this.buildReferences();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request() {

        if (this.$scope.source == DraftSource.Internal) this.applyInternalRulesBeforeSave();


        if (this.$scope.draft.SOURCE == DraftSource.HouseAWB || this.$scope.draft.SOURCE == DraftSource.MasterAWB) {
            if (this.$scope.draft.SOURCE == DraftSource.HouseAWB) {
                this.$scope.source = DraftSource.HouseAWB;
                this.$scope.draft.DOCUMENT.HOUSEAWB = this.$scope.airDocument;
                this.$scope.draft.DOCUMENT.MASTERAWB = null;
            }

            if (this.$scope.draft.SOURCE == DraftSource.MasterAWB) {
                this.$scope.source = DraftSource.MasterAWB;
                this.$scope.draft.DOCUMENT.MASTERAWB = this.$scope.airDocument;
                this.$scope.draft.DOCUMENT.HOUSEAWB = null;
            }
            this.$scope.draft.DOCUMENT.EXTERNAL = null;
            this.$scope.draft.DOCUMENT.INTERNAL = null;
            if (this.$scope.airDocument.AIR_CARGO_LIST && this.$scope.airDocument.AIR_CARGO_LIST.length === 0) {
                throw new Error(this.formService.getTranslate("GENERAL.CARGO_LIST_IS_EMPTY"));
            }
        } else {
            if (this.$scope.document.CARGO_LIST && this.$scope.document.CARGO_LIST.length === 0) {
                throw new Error(this.formService.getTranslate("GENERAL.CARGO_LIST_IS_EMPTY"));
            }
        }

        const request = {
            route: "/draft/insert",
            operation: this.$scope.operation,
            processNumber: this.$scope.model.PROCESS_NUMBER,
            data: this.$scope.draft,
            source: this.$scope.source,
            timeout: 280000
        };

        return request;
    }

    async save(): Promise<boolean> {
        try {

            return true;
        } catch (ex) {
            this.handleLoadError(ex);
        }
        return false;
    }

    private async initControl() {
        if (this.$scope.uploader) {
            this.$scope.uploader = null;
        }
        this.$scope.uploader = this.getFileUploaderDefault();
        this.$scope.validation = {
            shipper: "",
            consignee: "",
            cnpj: "",
            notify: "",
            marks: "",
            cargo: "",
            cargoInstructions: "",
            documentType: "",
            originPort: "",
            destinationPort: "",
            finalDestination: "",
            placeReceipt: "",
            seller: "",
            buyer: "",
            shipTo: "",
            supplier: "",
            containerStuffing: "",
            consolidator: "",
            corporateBranch: ""
        };

        if (this.$scope.model.DRAFT) {
            if (this.$scope.operation === "register") {
                this.$scope.draft.REFERENCE = [];

                if (this.$scope.model && this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.ID === "IA" || this.$scope.model.PRODUCT.ID === "EA") {
                    this.$scope.draft.SOURCE = null;
                }
            } else {
                this.$scope.draft = this.$scope.model.DRAFT;
                if (this.$scope.draft.SOURCE === DraftSource.Internal || this.$scope.draft.SOURCE === DraftSource.External) {
                    this.$scope.draft.DOCUMENT.EXTERNAL = this.$scope.draft.DOCUMENT.INTERNAL;
                    this.$scope.document = this.$scope.draft.DOCUMENT.INTERNAL;

                    this.$scope.document.DATE_OF_LOADING = (this.$scope.document.DATE_OF_LOADING) ? new Date(this.$scope.document.DATE_OF_LOADING) : null;
                    this.$scope.document.DATE_OF_ARRIVAL = (this.$scope.document.DATE_OF_ARRIVAL) ? new Date(this.$scope.document.DATE_OF_ARRIVAL) : null;
                    this.$scope.document.DATE_ON_FIRST_AMERICAN_PORT = (this.$scope.document.DATE_ON_FIRST_AMERICAN_PORT) ? new Date(this.$scope.document.DATE_ON_FIRST_AMERICAN_PORT) : null;
                    this.$scope.document.DATE_ON_LAST_FOREIGN_PORT = (this.$scope.document.DATE_ON_LAST_FOREIGN_PORT) ? new Date(this.$scope.document.DATE_ON_LAST_FOREIGN_PORT) : null;
                }
            }
        }
        if (this.$scope.model && this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.ID === "IA" || this.$scope.model.PRODUCT.ID === "EA") {
            this.$scope.source = this.$scope.draft.SOURCE;
            this.setAirDocument(this.$scope.draft.SOURCE)
        }

        if (this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.LCL) {
            this.$scope.equipmentTypeList = await this.getEquipmentList();
        } else if (this.$scope.model && this.$scope.model.CARGO_DETAIL && this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST) {
            this.$scope.equipmentTypeList = (this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST) ? this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST.map(x => (<SelectorModel>{ ID: x.EQUIPMENT.ID, NAME: x.EQUIPMENT.NAME, CODE: x.EQUIPMENT.CODE })) : null;
        }

        if (this.$scope.model && this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.ID === "IM") {
            this.$scope.document.SHIPPER = this.formatAddress(this.$scope.model.EXPORTER);
            this.$scope.document.CONSIGNEE = this.formatAddress(this.$scope.model.IMPORTER);
            this.$scope.document.NOTIFY = this.formatAddress(this.$scope.model.NOTIFY);
            this.$scope.document.CNPJ = (this.$scope.model && this.$scope.model.IMPORTER && this.$scope.model && this.$scope.model.IMPORTER.CODE) ? this.$scope.model.IMPORTER.CODE : null;
            this.$scope.document.INSTRUCTION = (this.$scope.model.INSTRUCTION) ? this.$scope.model.INSTRUCTION.OPERATIONAL : null;
        }

        this.sumCargoList();
        this.isCnpjValidity();

        this.$formService.validateForm();
    }

    private async getGenericType(identifier: string): Promise<SelectorModel> {
        const { data: generic } = await this.helperService.get(`/generic/value/${identifier}`, null, 120000);
        return generic && generic.data ? generic.data : [];
    }

    private async getRoutingPointListByName(filter: IRoutingPointListCustomFilter): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            if (filter && filter.name && filter.name.length >= 3) {
                this.formService.block();

                const request: IMonacoRequest = {
                    data: filter,
                    route: `/routingPoint/list/custom/`,
                    timeout: 120000,
                }
                const rc = await this.ProductService.post(request);

                if (rc && rc.data && rc.data.data && rc.data.data.data && rc.status == 200) {
                    result = rc.data.data.data.map(x => <SelectorModel>{ ID: x.ID, NAME: x.NAME, CODE: x.CODE });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
        finally {
            this.formService.unblock();
            return result;
        }
    }

    private getStatusIcon(status: string) {
        const draftStatus: IDraftStatus = <IDraftStatus>{ title: (status) ? status : "Awaiting Draft", icon: "fa fa-hourglass-start" };

        switch (status) {
            case "Awaiting Draft":
                draftStatus.icon = "fa fa-hourglass-start";
                break;
            case "Saved":
                draftStatus.icon = "text-warning fa fa-floppy-o";
                break;
            case "Issued":
                draftStatus.icon = "text-success fa fa-check";
                break;
            case "Sent":
                draftStatus.icon = "text-orange fa fa-paper-plane";
                break;
            case "Received":
                draftStatus.icon = "text-allog fa fa-inbox";
                break;
            case "Inactive":
                draftStatus.icon = "text-rouge fa fa-minus-circle";
                break;
        }

        return draftStatus;
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: "PROCESS_NUMBER",
                LABEL: "OPERATIONAL.FILE_NUMBER"
            },
        ];
        return props;
    }

    private formatText(text: string, maxCols: number): string {
        if (!text || text.length === 0) return;

        let line: string = text;
        for (let j = 0; j < text.length; j++) {
            if (!this.regex.test(text[j])) {
                line = line.replace(text[j], '');
            }
        }

        let finalText: string[] = [];

        const list = text.split('\n');

        for (const item of list) {
            if (this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.ID != EProductId.MARITIME_IMPORT) finalText = finalText.concat(item.match(new RegExp('.{1,' + maxCols + '}', 'g')));
            else finalText = finalText.concat(item);
        }

        return finalText.join('\n');
    }

    private formatTextArea(property: string, maxRows: number, maxCols: number, htmlComponent: string, label: string) {
        try {
            if (!property || !maxRows || !maxCols) return;
            const formProperty = this.$scope.document[property] ? this.$scope.document[property] : this.$scope.airDocument[property] ? this.$scope.airDocument[property] : null;

            if (formProperty && formProperty.length > 0) {
                this.$scope.validation[label] = "";
                this.$scope.form[htmlComponent].$setValidity('$valid', true);

                let text: string = formProperty;
                if (!text || !text.length) return;

                let list = text.split('\n');

                const regex = /[\u00C0-\u00FF]/;
                let hasAccentuation = list.some(item => regex.test(item));

                let finalText: string[] = [];

                for (const item of list) {
                    if (this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.ID != EProductId.MARITIME_IMPORT) finalText = finalText.concat(item.match(new RegExp('.{1,' + maxCols + '}', 'g')));
                    else finalText = finalText.concat(item);
                }

                this.$scope.document[property] ? this.$scope.document[property] = finalText.join('\n') : this.$scope.airDocument[property] ? this.$scope.airDocument[property] = finalText.join('\n') : null;
                this.$scope.form[htmlComponent].$setValidity('$valid', true);
                this.$scope[label] = "";

                if (finalText.length > maxRows) {
                    this.$scope.form[htmlComponent].$setValidity('$valid', false);
                    this.$scope.validation[label] = "This field exceeds the maximum number of rows allowed: " + finalText.length + ' to ' + maxRows;
                }

                if (hasAccentuation) {
                    this.$scope.form[htmlComponent].$setValidity('$valid', false);
                    this.$scope.validation[label] = "This field contains forbidden characters";
                }

                if (hasAccentuation && finalText.length > maxRows) {
                    this.$scope.form[htmlComponent].$setValidity('$valid', false);
                    this.$scope.validation[label] = "This field exceeds the maximum number of rows allowed: " + finalText.length + ' to ' + maxRows;
                    this.$scope.validation[label] += "  - This field contains forbidden characters";
                }
            } else {
                this.$scope.validation[label] = "";
                this.$scope.form[htmlComponent].$setValidity('$valid', true);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private preventSpecialChars(event, element) {
        let keycode = event.keyCode;
        let str = String.fromCharCode(keycode);

        if (!this.regex.test(str) && keycode != 13) {
            event.preventDefault();
            this.$scope.document[element] = this.$scope.document[element].replace(str, '');
        }
    }

    private validateTextAllowOnlyLetterAndNumber(element) {
        const validateString = Array.from(this.$scope.document[element])
            .map((char: string) => char.replace(this.regexOnlyNumberAndLetter, '')).join('')

        this.$scope.document[element] = validateString;
    }

    private isProcessSituationValidity(model: DraftViewModel): boolean {
        try {
            return (model && (!model.SITUATION || model.SITUATION.ID !== "5"));
        } catch (ex) {
            this.handleError(ex);
            return false;
        }
    }

    private async receiveUpdate(model: DraftViewModel): Promise<void> {
        try {
            if (!model || !model.DRAFT || !model.DRAFT.DRAFT_NUMBER) return this.handleError(this.formService.getTranslate("GENERAL.FAILED_TO_GET_DRAFT_NUMBER"));

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.YES",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.ATTENTION_REQUIRED",
                bodyText: this.formService.getTranslate("GENERAL.DO_YOU_WANT_TO_RECEIVE_THIS_DRAFT")
            });

            if (!modal) return;

            this.block();

            const timeout = 120000;
            const request = {
                processNumber: model.PROCESS_NUMBER,
                draftNumber: model.DRAFT.DRAFT_NUMBER,
                source: this.$scope.source,
                timeout: timeout,
            };

            const rc = await this.OperationalService.post("/draft/receive", request, timeout);

            if (rc.status === 200) {
                await this.updateGrid();
                this.$formService.notifySuccess(this.formService.getTranslate("GENERAL.DRAFT_STATUS_UPDATED"))
            } else {
                this.$formService.notifyError(this.formService.getTranslate("GENERAL.DRAFT_UPDATE_ERROR"))
            }

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async sendUpdate(model: DraftViewModel): Promise<void> {
        try {
            if (!model || !model.DRAFT || !model.DRAFT.DRAFT_NUMBER) return this.handleError(this.formService.getTranslate("GENERAL.FAILED_TO_GET_DRAFT_NUMBER"));

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.YES",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.ATTENTION_REQUIRED",
                bodyText: "Do you want to send this draft?"
            });

            if (!modal) return;

            this.block();

            const timeout = 120000;
            const request = {
                processNumber: model.PROCESS_NUMBER,
                draftNumber: model.DRAFT.DRAFT_NUMBER,
                source: this.$scope.source,
                timeout: timeout,
            };

            const rc = await this.OperationalService.post("/draft/send", request, timeout);

            if (rc.status === 200) {
                await this.updateGrid();
                this.$formService.notifySuccess(this.formService.getTranslate("GENERAL.DRAFT_STATUS_UPDATED"))
            } else {
                this.$formService.notifyError(this.formService.getTranslate("GENERAL.DRAFT_UPDATE_ERROR"))
            }

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async inactivateUpdate(model: DraftViewModel): Promise<void> {
        try {
            if (!model || !model.DRAFT || !model.DRAFT.DRAFT_NUMBER) return this.handleError(this.formService.getTranslate("GENERAL.FAILED_TO_GET_DRAFT_NUMBER"));

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.YES",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.ATTENTION_REQUIRED",
                bodyText: "Do you want to inactivate this draft?"
            });

            if (!modal) return;

            this.block();

            const timeout = 120000;
            const request = {
                processNumber: model.PROCESS_NUMBER,
                draftNumber: model.DRAFT.DRAFT_NUMBER,
                source: this.$scope.source,
                timeout: timeout,
            };

            const rc = await this.OperationalService.post("/draft/inactivate", request, timeout);

            if (rc.status === 200) {
                await this.updateGrid();
                this.$formService.notifySuccess(this.formService.getTranslate("GENERAL.DRAFT_STATUS_UPDATED"))
            } else {
                this.$formService.notifyError(this.formService.getTranslate("GENERAL.DRAFT_UPDATE_ERROR"))
            }

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async print(model: DraftViewModel): Promise<void> {
        try {
            if (!model || !model.DRAFT || !model.DRAFT.DRAFT_NUMBER) return this.handleError('Fail to get draft number.');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: `GENERAL.YES`,
                closeButtonText: `GENERAL.NO`,
                headerText: "GENERAL.ATTENTION_REQUIRED",
                bodyText: 'Do you want to print this draft?'
            });

            if (!modal) return;

            this.block();

            const timeout = 120000;
            const request = {
                processNumber: model.PROCESS_NUMBER,
                draftNumber: model.DRAFT.DRAFT_NUMBER,
                source: this.$scope.source,
                timeout: timeout,
            };

            const rc = await this.OperationalService.post("/draft/print", request, timeout);

            if (rc.status === 200 && rc.data && rc.data.data) {
                const fileInfo = rc.data.data;
                const file = new File([new Uint8Array(fileInfo.buffer)], fileInfo.fileName, { type: "application/pdf" });
                const objectUrl = URL.createObjectURL(file);
                window.open(objectUrl, '_blank');
            } else {
                this.$formService.notifyError(this.formService.getTranslate("GENERAL.DRAFT_PRINT_ERROR"));
            }

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private editDraftValidity(model: DraftViewModel): boolean {
        try {
            if (!model.PRODUCT) return false;

            switch (this.$scope.source) {
                case DraftSource.Internal:
                    return (
                        model.DRAFT.STATUS.NAME == "Received" ||
                        (model.DRAFT.STATUS.NAME == "Saved" && model.CARGO_TYPE.NAME == "FCL" && model.PRODUCT.ID == "IM") ||
                        (model.DRAFT.STATUS.NAME == "Saved" && (model.PRODUCT.ID == "IA" || model.PRODUCT.ID == "EA"))
                    );
                case DraftSource.External:
                    return (model.DRAFT.STATUS.NAME == "Saved" || model.DRAFT.STATUS.NAME === "Sent");
            }

            return false;
        } catch (ex) {
            this.handleError(ex);
            return false;
        }
    }


    private async getEquipmentList(): Promise<SelectorModel[]> {
        try {
            let equipmentList: SelectorModel[] = [];

            const request: IMonacoRequest = {
                data: null,
                route: `/equipment/list`,
                timeout: 30000,
            }

            const result = await this.ProductService.post(request);
            if (result && result.data && result.status == 200) {
                const equipmentResult = result.data && result.data.data && result.data.data.data ? result.data.data.data : [];
                for (const resultItem of equipmentResult) {
                    const equipment: SelectorModel = { ID: resultItem.ID, CODE: resultItem.CODE, NAME: resultItem.NAME }
                    equipmentList.push(equipment);
                }
            }

            return equipmentList
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private buildReferences() {
        this.$scope.fileModel = <IFiles>{ FILES: [] };
        if (this.$scope.draft.REFERENCE) {
            const references = this.$scope.draft.REFERENCE;
            for (const reference of references) {

                const extensionRemoveList: string[] = (reference.NAME) ? reference.NAME.split('.') : null;
                const extension: string = (extensionRemoveList && extensionRemoveList.length > 0) ? extensionRemoveList[extensionRemoveList.length - 1] : null;

                this.$scope.fileModel.FILES.push({
                    FILE_DISPLAY_NAME: reference.NAME,
                    FILE_NAME: reference.NAME,
                    FILE_TYPE: extension,
                    FILE_PATH: null,
                    FILE_URL: reference.LINK,
                    FILE_HASH: null,
                    FORM_NAME: "draftRegister"
                });
            }
        }
    }

    private receiveDraftValidity(model: DraftViewModel): boolean {
        try {
            const status: string = (model && model.DRAFT && model.DRAFT.STATUS) ? model.DRAFT.STATUS.NAME : null;
            const source: string = (model && model.DRAFT && model.DRAFT.SOURCE) ? model.DRAFT.SOURCE : null;

            if (!status || !source) return false;

            const isSent = (status === "Sent");
            const isSaved = (status === "Saved");
            const isSourceInternal = (source === DraftSource.Internal || source === DraftSource.HouseAWB || source === DraftSource.MasterAWB);

            return isSent || (isSaved && isSourceInternal);
        } catch (ex) {
            this.handleError(ex);
            return false;
        }
    }

    private newDraftValidity(model: DraftViewModel): boolean {
        try {
            if (!model || !model.DRAFT || !model.DEADLINE_DATES || !model.DEADLINE_DATES.DRAFT_HOUSE) return true;

            return moment(new Date).isSameOrBefore(moment(model.DEADLINE_DATES.DRAFT_HOUSE));
        } catch (ex) {
            this.handleError(ex);
            return false;
        }
    }

    private inactivateDraftValidity(model: DraftViewModel): boolean {
        try {
            return (model && model.DRAFT && model.DRAFT.DRAFT_NUMBER && model.DRAFT.STATUS.NAME == "Received");
        } catch (ex) {
            this.handleError(ex);
            return false;
        }
    }

    private sendDraftValidity(model: DraftViewModel): boolean {
        try {
            switch (this.$scope.source) {
                case DraftSource.Internal: return (model.DRAFT.STATUS.NAME === "Received");
                case DraftSource.External: return (model.DRAFT.STATUS.NAME === "Saved");
            }
            return false;
        } catch (ex) {
            this.handleError(ex);
            return false;
        }
    }

    private printDraftValidity(model: DraftViewModel): boolean {
        try {
            return (model.DRAFT.DRAFT_NUMBER !== "" && model.DRAFT.STATUS.NAME !== "Inactive");
        } catch (ex) {
            this.handleError(ex);
            return false;
        }
    }

    private isCnpjValidity() {
        if (this.$scope.source === DraftSource.Internal) {
            this.$scope.validation.cnpj = "";

            let message: string = null;

            if (this.$scope.document && this.$scope.document.CNPJ) {
                const cnpj: string = this.$scope.document.CNPJ.replace('.', '').replace('/', '').replace('-', '');

                switch (this.$scope.model.PRODUCT.ID) {
                    case "EM": {
                        message = "This field needs to be equal process exporter!";
                        if (this.$scope.model.EXPORTER && this.$scope.model.EXPORTER.CODE) {
                            const exporterCNPJ: string = this.$scope.model.EXPORTER.CODE.replace('.', '').replace('.', '').replace('/', '').replace('-', '');
                            if (cnpj === exporterCNPJ) message = null;
                        }
                    }
                        break;

                    case "IM": {
                        message = "This field needs to be equal process importer!";
                        if (this.$scope.model.IMPORTER && this.$scope.model.IMPORTER.CODE) {
                            const importerCNPJ: string = this.$scope.model.IMPORTER.CODE.replace('.', '').replace('.', '').replace('/', '').replace('-', '');
                            if (cnpj === importerCNPJ) message = null;
                        }
                    }
                        break;
                }

                this.$scope.validation.cnpj = message;
            }
        }
    }

    private addCargoList() {
        try {
            if (!this.$scope.document.CARGO_LIST) return;

            this.$scope.document.CARGO_LIST.push({
                CONTAINERIZED: this.isNonContainerizedCargoAllowed() ? false : true,
                CONTAINER_ID: null,
                CONTAINER_NUMBER: this.isNonContainerizedCargoAllowed() ? "Non containerized" : null,
                CBM: null,
                GROSS_WEIGHT: null,
                NCM: null,
                NET_WEIGHT: null,
                PACKAGE: null,
                QTY: null,
                SHIPPER_SEALING: null,
                TARA: null,
                TYPE: null,
            });

            this.$formService.validateForm();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private addAirCargoList() {
        try {
            this.$scope.airDocument.AIR_CARGO_LIST.push({
                PIECES: null,
                GROSS_WEIGHT: null,
                K_L: "K",
                RATE_CLASS: this.$scope.rateClass.find(rate => rate.NAME === "K").NAME,
                ITEM_NO: null,
                CHARGEABLE_WEIGHT: null,
                RATE_CHARGE: null,
                TOTAL: null,
                NATURE_AND_QUANTITY: null,
            });

            this.sumAirCargoList();

            this.$formService.validateForm();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private addAirOtherChargeList() {
        try {
            this.$scope.airDocument.OTHER_CHARGES_LIST.push({
                DESCRIPTION: null,
                AMOUNT: null,
                ENTITLEMENT: null
            });

            this.$formService.validateForm();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private removeOtherChargeList(index: number) {
        try {
            if (index < 0) return;

            this.$scope.airDocument.OTHER_CHARGES_LIST.splice(index, 1);

            this.sumAirOtherChargeList();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private removeAirCargoList(index: number) {
        try {
            if (index < 0) return;

            this.$scope.airDocument.AIR_CARGO_LIST.splice(index, 1);

            this.sumAirCargoList();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private removeCargoList(index: number) {
        try {
            if (index < 0) return;

            this.$scope.document.CARGO_LIST.splice(index, 1);

            this.sumCargoList();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sumCargoList() {
        try {
            if (this.$scope.document && this.$scope.document.TOTAL) {
                this.$scope.document.TOTAL.CNTR = 0;
                this.$scope.document.TOTAL.QTY = 0;
                this.$scope.document.TOTAL.GROSS_WEIGHT = 0;
                this.$scope.document.TOTAL.NET_WEIGHT = 0;
                this.$scope.document.TOTAL.CBM = 0;

                for (const cargo of this.$scope.document.CARGO_LIST) {
                    if (cargo.CONTAINERIZED) this.$scope.document.TOTAL.CNTR += 1;
                    this.$scope.document.TOTAL.QTY += (cargo.QTY) ? cargo.QTY : 0;
                    this.$scope.document.TOTAL.GROSS_WEIGHT += (cargo.GROSS_WEIGHT) ? cargo.GROSS_WEIGHT : 0;
                    this.$scope.document.TOTAL.NET_WEIGHT += (cargo.NET_WEIGHT) ? cargo.NET_WEIGHT : 0;
                    this.$scope.document.TOTAL.CBM += (cargo.CBM) ? cargo.CBM : 0;
                }

                this.$scope.$applyAsync();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sumAirCargoList() {
        try {
            if (this.$scope.airDocument && this.$scope.airDocument.TOTAL_LIST && this.$scope.airDocument.AIR_CARGO_LIST) {
                const airCargoList = this.$scope.airDocument.AIR_CARGO_LIST;
                let totalList = this.$scope.airDocument.TOTAL_LIST;
                let totalPieces: number = 0
                let totalGrossWeight: number = 0
                let totalChargeableWeight: number = 0
                let totalValue: number = 0;

                totalList.K_L = "";
                if (airCargoList) {
                    console.log("sum air cargo")
                    for (let cargo = 0; cargo < airCargoList.length; cargo++) {
                        totalPieces += airCargoList[cargo].PIECES ? +airCargoList[cargo].PIECES : 0;
                        totalGrossWeight += airCargoList[cargo].GROSS_WEIGHT ? +airCargoList[cargo].GROSS_WEIGHT : 0;
                        totalChargeableWeight += airCargoList[cargo].CHARGEABLE_WEIGHT ? +airCargoList[cargo].CHARGEABLE_WEIGHT : 0;
                        totalValue += airCargoList[cargo].TOTAL ? +airCargoList[cargo].TOTAL : 0;
                        totalList.K_L = airCargoList[0].K_L ? airCargoList[0].K_L : "";
                    }
                    totalList.TOTAL_PIECES = totalPieces ? totalPieces.toFixed(0) : "";
                    totalList.TOTAL_GROSS_WEIGHT = totalGrossWeight ? totalGrossWeight.toFixed(3) : "";
                    totalList.TOTAL_CHARGEABLE_WEIGHT = totalChargeableWeight ? totalChargeableWeight.toFixed(3) : "";
                    totalList.TOTAL_VALUE = totalValue ? totalValue.toFixed(2) : "";
                }
                this.$scope.airDocument.WEIGHT_CHARGE = totalList.TOTAL_VALUE;

                this.sumAirOtherChargeList()
                this.$scope.$applyAsync();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sumAirOtherChargeList() {
        try {
            console.log("sum")
            if (this.$scope.airDocument && this.$scope.airDocument.OTHER_CHARGES_LIST) {
                let chargeDueAgent: number = 0;
                let chargeDueCarrier: number = 0;
                let chargeTotal: number = 0;

                for (const charge of this.$scope.airDocument.OTHER_CHARGES_LIST) {
                    const amount = charge.AMOUNT;

                    chargeDueAgent += (charge.ENTITLEMENT == "A" && amount) ? +amount : 0;

                    chargeDueCarrier += (charge.ENTITLEMENT == "C" && amount) ? +amount : 0;

                    chargeTotal += (amount) ? +amount : 0;
                }
                this.$scope.airDocument.OTHER_CHARGES_DUE_AGENT = chargeDueAgent == 0 ? "" : chargeDueAgent.toFixed(2);
                this.$scope.airDocument.OTHER_CHARGES_DUE_CARRIER = chargeDueCarrier == 0 ? "" : chargeDueCarrier.toFixed(2);
                chargeTotal = chargeTotal + +this.$scope.airDocument.TOTAL_LIST.TOTAL_VALUE + +this.$scope.airDocument.TAX + +this.$scope.airDocument.VALUATION_CHARGE;
                this.$scope.airDocument.TOTAL = chargeTotal == 0 ? "" : chargeTotal.toFixed(2);
                this.formService.validateForm();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private containerValidation(containerNumber: string, elementName: string, index: number) {
        this.$timeout(async () => {
            try {
                const timeout: number = 120000;

                this.$scope.form[elementName].$setValidity('$valid', false);

                if (!containerNumber || containerNumber == "Non containerized") return;

                const dupList = this.$scope.document.CARGO_LIST.filter(x => x.CONTAINER_NUMBER === containerNumber);

                if (dupList && dupList.length > 1) {
                    this.$formService.notifyError(`Container number: <b>${containerNumber}</b> is already in use`);
                    this.$scope.document.CARGO_LIST[index].CONTAINER_NUMBER = null;
                    return;
                }

                const result = await this.OperationalService.get(`/draft/validation/container/${containerNumber}`, null, timeout);

                if (result.status === 200 && result.data) {
                    const valid = result.data['data'];
                    this.$scope['form'][elementName].$setValidity('$valid', valid);

                    if (!valid && containerNumber.length === 11)
                        this.$formService.notifyError(`Invalid container number: <b>${containerNumber}</b>`);

                } else {
                    const msg = `Falha ao validar container! ${JSON.stringify(result)}`;
                    this.$formService.notifyError(msg)
                }

            } catch (ex) {
                const msg = `Exception: ${JSON.stringify(ex)}`;
                this.$formService.notifyError(msg)
            }
            this.$scope.$apply();
        });
    }

    private sealValidation(seal: string, elementName: string, index: number) {
        this.$timeout(() => {

            this.$scope.form[elementName].$setValidity('$valid', false);

            if (!seal) return;

            const dupList = this.$scope.document.CARGO_LIST.filter(x => x.SHIPPER_SEALING === seal);

            if (dupList && dupList.length > 1) {
                this.$formService.notifyError(`Seal number: <b>${seal}</b> is already in use`);
                this.$scope.document.CARGO_LIST[index].SHIPPER_SEALING = null;
            } else {
                this.$scope.form[elementName].$setValidity('$valid', true);
            }

        }, 1000);
    }

    private netWeightValidation(netWeight: string, elementName: string, index: number) {
        this.$timeout(() => {

            if (!netWeight) return;

            const { GROSS_WEIGHT, NET_WEIGHT } = this.$scope.document.CARGO_LIST[index];

            if (GROSS_WEIGHT && (NET_WEIGHT > GROSS_WEIGHT)) {
                this.$formService.notifyError(`Net Weight cannot be heavier then Gross Weight`);
                this.$scope.document.CARGO_LIST[index].NET_WEIGHT = null;
            } else {
                this.sumCargoList();
            }

        }, 1000);
    }

    private cargoContainerizedChange(index: number) {
        if (index == null) return;
        try {
            const cargo = this.$scope.document.CARGO_LIST[index];
            if (!cargo.CONTAINERIZED) {
                cargo.CONTAINER_ID = null;
                cargo.CONTAINER_NUMBER = "Non containerized";
                cargo.TYPE = null;
                cargo.TARA = null;
                cargo.SHIPPER_SEALING = null;
                this.$timeout(() => {
                    this.$scope.form["containerNumber" + index].$setValidity('$valid', true, null);
                    this.$scope.selectorValidity("typeContainerNumber" + index);
                });
            }
            this.sumCargoList();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private isContainerizedCargoAllowed(): boolean {
        const hasEquipment = this.$scope.model.CARGO_DETAIL && this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST && this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST.length > 0;
        const isExternal = this.$scope.source == DraftSource.External;
        const isCargoFcl = this.$scope.model.CARGO_TYPE && this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.FCL;
        const isCargoLcl = this.$scope.model.CARGO_TYPE && this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.LCL;
        const isCargoBbOrRoro = this.$scope.model.CARGO_TYPE && (this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.BREAK_BULK || this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.RO_RO);
        if (isExternal) {
            if (isCargoLcl) return false;
            if (isCargoFcl) return true;
            if (isCargoBbOrRoro) return hasEquipment;
        }
        return isCargoFcl || isCargoLcl || (isCargoBbOrRoro && hasEquipment);
    }

    private isNonContainerizedCargoAllowed(): boolean {
        const hasEquipment = this.$scope.model.CARGO_DETAIL && this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST && this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST.length > 0 ? true : false;
        const isExternal = this.$scope.source == DraftSource.External;
        const isCargoFcl = this.$scope.model.CARGO_TYPE && this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.FCL;
        const isCargoLcl = this.$scope.model.CARGO_TYPE && this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.LCL;
        const isCargoBbOrRoro = this.$scope.model.CARGO_TYPE && (this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.BREAK_BULK || this.$scope.model.CARGO_TYPE.ID == ECargoTypeId.RO_RO);
        if (isExternal) {
            if (isCargoLcl) return true;
            if (isCargoFcl) return false;
            if (isCargoBbOrRoro) return !hasEquipment;
        }
        return (isCargoLcl || isCargoBbOrRoro);
    }

    private isContainerizedCargoAllowedToChange(containerizedCurrentState: boolean): boolean {
        return (containerizedCurrentState && this.isNonContainerizedCargoAllowed()) || (!containerizedCurrentState && this.isContainerizedCargoAllowed())
    }

    private requestHistory(identifier: string): Promise<any> {
        let timeout = 15000;
        return this.OperationalService.get(`/process/viewLog/${timeout}/${identifier}`, null, timeout);
    }

    private viewLog(model: DraftViewModel) {
        this.block();
        let log: IViewLog = {
            operation: 'history',
            number: model.PROCESS_NUMBER + '-' + model.DRAFT.DRAFT_NUMBER,
            list: [],
            show: true,
            searchQuery: '',
            originalList: [],
        }

        this.requestHistory(log.number).then(result => {
            log.list = result && result.data && result.data.data;
            log.originalList = angular.copy(log.list);
            this.$scope.log = log;
            angular.element('#log-viewer').removeClass('ng-hide');
            const position = angular.element('#log-viewer').offset().top + $('.app-content-body').scrollTop() - 105;
            $('.app-content-body').animate({
                scrollTop: position
            }, 500);

            this.unblock();
        }).catch(ex => {
            this.handleError(ex);
        });
    };

    private async loadCopiedData() {
        try {
            if (!this.$scope.aux.copyBookingNumber) throw new Error(this.formService.getTranslate("GENERAL.BOOKING_NUMBER_IS_EMPTY"));
            const copyBookingNumber = this.$scope.aux.copyBookingNumber;
            this.block();

            const timeout: number = 120000;

            let result = null
            try {
                result = await this.OperationalService.get(`/process/byBookingNumber/${copyBookingNumber}`, null, timeout);
            } catch {
                throw new Error(this.formService.getTranslate("GENERAL.BOOKING_NOT_FOUND"));
            }

            if (result && result.data && result.data.data && result.data.data.length > 0) {
                const process: Process = result.data.data[0];
                if (process.SITUATION.ID == '5') throw new Error(this.formService.getTranslate("GENERAL.INVALID_FILE"));

                if (process.DEADLINES) {
                    const draftHouse = process.DEADLINES.find(item => item.TYPE === "HOUSE")
                    const draftDate = draftHouse && draftHouse.DEADLINE_DATES && draftHouse.DEADLINE_DATES.DRAFT ? moment(draftHouse.DEADLINE_DATES.DRAFT) : null;
                    if (draftDate) {
                        if (!moment(new Date).isSameOrBefore(moment(draftDate))) {
                            throw new Error(this.formService.getTranslate("GENERAL.DRAFT_DEADLINE_ERROR"))
                        }
                    }
                }

                this.$scope.model.BOOKING = copyBookingNumber;
                this.$scope.model.PROCESS_NUMBER = process.PROCESS_NUMBER;
                this.$scope.model.DRAFT.DRAFT_NUMBER = null;
                this.$scope.model.DRAFT.SOURCE = this.$scope.source;

                // clear fields                
                this.$scope.draft.DRAFT_NUMBER = null; //Protocol
                if (this.$scope.draft.DOCUMENT.EXTERNAL) this.$scope.draft.DOCUMENT.EXTERNAL.DUE = null; //DU-E
                if (this.$scope.draft.DOCUMENT.INTERNAL) this.$scope.draft.DOCUMENT.INTERNAL.DUE = null; //DU-E
                if (this.$scope.draft.DOCUMENT.EXTERNAL) this.$scope.draft.DOCUMENT.EXTERNAL.RUC = null; //RUC
                if (this.$scope.draft.DOCUMENT.INTERNAL) this.$scope.draft.DOCUMENT.INTERNAL.RUC = null; //RUC
                if (this.$scope.document.PLACE_OF_RECEIPT_BL) this.$scope.document.PLACE_OF_RECEIPT_BL = null;
                if (this.$scope.document.PORT_OF_LOADING_BL) this.$scope.document.PORT_OF_LOADING_BL = null;
                if (this.$scope.document.PORT_OF_DISCHARGE_BL) this.$scope.document.PORT_OF_DISCHARGE_BL = null;
                if (this.$scope.document.FINAL_DESTINATION_BL) this.$scope.document.FINAL_DESTINATION_BL = null;

                // clear fields external
                if (this.$scope.draft.DOCUMENT.EXTERNAL.PORT_OF_LOADING) this.$scope.draft.DOCUMENT.EXTERNAL.PORT_OF_LOADING = null;
                if (this.$scope.draft.DOCUMENT.EXTERNAL.DATE_OF_LOADING) this.$scope.draft.DOCUMENT.EXTERNAL.DATE_OF_LOADING = null;
                if (this.$scope.draft.DOCUMENT.EXTERNAL.LAST_FOREIGN_PORT) this.$scope.draft.DOCUMENT.EXTERNAL.LAST_FOREIGN_PORT = null;
                if (this.$scope.draft.DOCUMENT.EXTERNAL.DATE_ON_LAST_FOREIGN_PORT) this.$scope.draft.DOCUMENT.EXTERNAL.DATE_ON_LAST_FOREIGN_PORT = null;
                if (this.$scope.draft.DOCUMENT.EXTERNAL.FIRST_AMERICAN_PORT) this.$scope.draft.DOCUMENT.EXTERNAL.FIRST_AMERICAN_PORT = null;
                if (this.$scope.draft.DOCUMENT.EXTERNAL.DATE_ON_FIRST_AMERICAN_PORT) this.$scope.draft.DOCUMENT.EXTERNAL.DATE_ON_FIRST_AMERICAN_PORT = null;
                if (this.$scope.draft.DOCUMENT.EXTERNAL.PORT_OF_ARRIVAL) this.$scope.draft.DOCUMENT.EXTERNAL.PORT_OF_ARRIVAL = null;
                if (this.$scope.draft.DOCUMENT.EXTERNAL.DATE_OF_ARRIVAL) this.$scope.draft.DOCUMENT.EXTERNAL.DATE_OF_ARRIVAL = null;
                if (this.$scope.draft.DOCUMENT.EXTERNAL.SELLER) this.$scope.draft.DOCUMENT.EXTERNAL.SELLER = null;
                if (this.$scope.draft.DOCUMENT.EXTERNAL.BUYER) this.$scope.draft.DOCUMENT.EXTERNAL.BUYER = null;
                if (this.$scope.draft.DOCUMENT.EXTERNAL.SHIP_TO) this.$scope.draft.DOCUMENT.EXTERNAL.SHIP_TO = null;
                if (this.$scope.draft.DOCUMENT.EXTERNAL.MANUFACTURER_SUPLIER) this.$scope.draft.DOCUMENT.EXTERNAL.MANUFACTURER_SUPLIER = null;
                if (this.$scope.draft.DOCUMENT.EXTERNAL.CONTAINER_STUFFING_LOCATION) this.$scope.draft.DOCUMENT.EXTERNAL.CONTAINER_STUFFING_LOCATION = null;
                if (this.$scope.draft.DOCUMENT.EXTERNAL.CONSOLIDATOR) this.$scope.draft.DOCUMENT.EXTERNAL.CONSOLIDATOR = null;

                // clear fields internal
                if (this.$scope.draft.DOCUMENT.INTERNAL.PORT_OF_LOADING) this.$scope.draft.DOCUMENT.INTERNAL.PORT_OF_LOADING = null;
                if (this.$scope.draft.DOCUMENT.INTERNAL.DATE_OF_LOADING) this.$scope.draft.DOCUMENT.INTERNAL.DATE_OF_LOADING = null;
                if (this.$scope.draft.DOCUMENT.INTERNAL.LAST_FOREIGN_PORT) this.$scope.draft.DOCUMENT.INTERNAL.LAST_FOREIGN_PORT = null;
                if (this.$scope.draft.DOCUMENT.INTERNAL.DATE_ON_LAST_FOREIGN_PORT) this.$scope.draft.DOCUMENT.INTERNAL.DATE_ON_LAST_FOREIGN_PORT = null;
                if (this.$scope.draft.DOCUMENT.INTERNAL.FIRST_AMERICAN_PORT) this.$scope.draft.DOCUMENT.INTERNAL.FIRST_AMERICAN_PORT = null;
                if (this.$scope.draft.DOCUMENT.INTERNAL.DATE_ON_FIRST_AMERICAN_PORT) this.$scope.draft.DOCUMENT.INTERNAL.DATE_ON_FIRST_AMERICAN_PORT = null;
                if (this.$scope.draft.DOCUMENT.INTERNAL.PORT_OF_ARRIVAL) this.$scope.draft.DOCUMENT.INTERNAL.PORT_OF_ARRIVAL = null;
                if (this.$scope.draft.DOCUMENT.INTERNAL.DATE_OF_ARRIVAL) this.$scope.draft.DOCUMENT.INTERNAL.DATE_OF_ARRIVAL = null;
                if (this.$scope.draft.DOCUMENT.INTERNAL.SELLER) this.$scope.draft.DOCUMENT.INTERNAL.SELLER = null;
                if (this.$scope.draft.DOCUMENT.INTERNAL.BUYER) this.$scope.draft.DOCUMENT.INTERNAL.BUYER = null;
                if (this.$scope.draft.DOCUMENT.INTERNAL.SHIP_TO) this.$scope.draft.DOCUMENT.INTERNAL.SHIP_TO = null;
                if (this.$scope.draft.DOCUMENT.INTERNAL.MANUFACTURER_SUPLIER) this.$scope.draft.DOCUMENT.INTERNAL.MANUFACTURER_SUPLIER = null;
                if (this.$scope.draft.DOCUMENT.INTERNAL.CONTAINER_STUFFING_LOCATION) this.$scope.draft.DOCUMENT.INTERNAL.CONTAINER_STUFFING_LOCATION = null;
                if (this.$scope.draft.DOCUMENT.INTERNAL.CONSOLIDATOR) this.$scope.draft.DOCUMENT.INTERNAL.CONSOLIDATOR = null;

                // update datas from process   
                const customerRef = (process.REFERENCE) ? process.REFERENCE.find(x => x.MAIN_FILE) : null;
                this.$scope.model.CUSTOMER_REF = (customerRef) ? customerRef.DOCUMENT_VALUE : null; //Reference            
                this.$scope.model.CARGO_TYPE = process.CARGO_TYPE; //Cargo Type
                this.$scope.model.SERVICE_TYPE = process.SERVICE_TYPE; //Movement Type
                this.$scope.model.HOUSE_PAYMENT = process.HOUSE_PAYMENT; //Payment Mode
                // locals
                this.$scope.model.EVENT_LOAD = (process.EVENT) ? process.EVENT.find(x => x.EVENT_TYPE.ID === '4') : null;
                this.$scope.model.EVENT_PLACE_OF_RECEIPT = (process.EVENT) ? process.EVENT.find(x => x.EVENT_TYPE.ID === '12') : null;
                this.$scope.model.EVENT_DISCH = (process.EVENT) ? process.EVENT.find(x => x.EVENT_TYPE.ID === '7') : null;
                this.$scope.model.EVENT_FINAL_DESTINATION = (process.EVENT) ? process.EVENT.find(x => x.EVENT_TYPE.ID === '8') : null;
                if (this.$scope.model.PRODUCT.ID === 'EM') {
                    this.$scope.model.EVENT_FINAL_DESTINATION = (process.EVENT) ? process.EVENT.find(x => x.EVENT_TYPE.ID === '10') || process.EVENT.find(x => x.EVENT_TYPE.ID === '8') : null;
                }

                this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST = process.CARGO_DETAIL.EQUIPMENT_LIST;
                this.$scope.equipmentTypeList = (process.CARGO_DETAIL.EQUIPMENT_LIST) ? process.CARGO_DETAIL.EQUIPMENT_LIST.map(x => (<SelectorModel>{ ID: x.EQUIPMENT.ID, NAME: x.EQUIPMENT.NAME, CODE: x.EQUIPMENT.CODE })) : null;

                if (this.$scope.draft.DOCUMENT.EXTERNAL) {
                    for (const cargo of this.$scope.draft.DOCUMENT.EXTERNAL.CARGO_LIST) {
                        cargo.TYPE = (this.$scope.equipmentTypeList) ? this.$scope.equipmentTypeList[0] : null
                    }
                }

                if (this.$scope.draft.DOCUMENT.INTERNAL) {
                    for (const cargo of this.$scope.draft.DOCUMENT.EXTERNAL.CARGO_LIST) {
                        cargo.TYPE = (this.$scope.equipmentTypeList) ? this.$scope.equipmentTypeList[0] : null
                    }
                }

                this.$scope.operation = "register";
                this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.GRID.COPY")} (${this.$scope.model.PROCESS_NUMBER})`;

                this.$scope.$applyAsync();
                this.formService.validateForm();
            }

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private applyInternalRulesBeforeSave() {
        if (this.$scope.document && this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.ID == EProductId.MARITIME_EXPORT) {
            if (!this.$scope.document.SELLER || this.$scope.document.SELLER.length == 0) this.$scope.document.SELLER = angular.copy(this.$scope.document.SHIPPER);
            if (!this.$scope.document.BUYER || this.$scope.document.BUYER.length == 0) this.$scope.document.BUYER = angular.copy(this.$scope.document.CONSIGNEE);
            if (!this.$scope.document.SHIP_TO || this.$scope.document.SHIP_TO.length == 0) this.$scope.document.SHIP_TO = angular.copy(this.$scope.document.CONSIGNEE);
            if (!this.$scope.document.MANUFACTURER_SUPLIER || this.$scope.document.MANUFACTURER_SUPLIER.length == 0) this.$scope.document.MANUFACTURER_SUPLIER = angular.copy(this.$scope.document.SHIPPER);
            if (!this.$scope.document.CONTAINER_STUFFING_LOCATION || this.$scope.document.CONTAINER_STUFFING_LOCATION.length == 0) this.$scope.document.CONTAINER_STUFFING_LOCATION = angular.copy(this.$scope.document.SHIPPER);
            if (!this.$scope.document.CONSOLIDATOR || this.$scope.document.CONSOLIDATOR.length == 0) this.$scope.document.CONSOLIDATOR = angular.copy(this.$scope.document.SHIPPER);
        }
    }

    private goToProcess(process: string) {
        this.sessionService.openTab("app.operational.newProcess.list", <IProcessParameter>{ PROCESS_NUMBER: process });
    }

    private goToDocument(processNumber: string, draftNumber: string) {
        this.sessionService.openTab('app.operational.process.processDocument', <IProcessDocumentParameter>{ PROCESS_NUMBER: processNumber, DRAFT_NUMBER: draftNumber });
    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'allog_file_integration_service', formName: "draftRegister", folder: "draftRegister" };
            return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData], removeAfterUpload: true, onSuccessItem: (item: IFileItem, response: ISuccessItemResponse) => this.onSuccessItem(item, response) });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private onSuccessItem(item: IFileItem, response: ISuccessItemResponse): void {
        try {
            if (!item) throw Error('item parameter is null');
            if (!response) throw Error('response parameter is null');

            const documentTypeLCL = this.$scope.documentTypeList && this.$scope.documentTypeList.length ? this.$scope.documentTypeList.find(documentType => documentType.ID == EDocumentTypeId.DOCUMENTOS_LCL) : null;
            const fileGroupOperational = this.$scope.fileGroupList && this.$scope.fileGroupList.length ? this.$scope.fileGroupList.find(fileGroup => fileGroup.ID == EFileGroupId.OPERACIONAL) : null;
            const fileSpecAccount = this.$scope.fileSpecsList && this.$scope.fileSpecsList.length ? this.$scope.fileSpecsList.find(fileSpec => fileSpec.ID == EFileSpecsId.CLIENTE) : null;
            if (!this.$scope.draft.REFERENCE) this.$scope.draft.REFERENCE = [];
            if (response.data && response.data.length > 0) {
                for (let dataItem of response.data) {
                    const reference = <REFERENCE>{};
                    reference.REFERENCE_ID = this.$scope.model.PROCESS_NUMBER + "_" + (this.$scope.draft.REFERENCE.length + 1).toString();
                    reference.ID_CONSOLIDATED = null;
                    reference.SOURCE = EFileReferenceSource.DRAFT;
                    reference.TYPE = "TEMPLATE";
                    reference.MAIN_FILE = false;
                    reference.DOCUMENT_TYPE = documentTypeLCL;
                    reference.DOCUMENT_VALUE = null;
                    reference.TEMPLATE_TYPE = null;
                    reference.FILE_GROUP = fileGroupOperational;
                    reference.FILE_SPECS = [fileSpecAccount];
                    reference.INSERT_DATE = new Date();
                    reference.USER_REFERENCE = null;
                    reference.NAME = dataItem.fileName;
                    reference.LINK = dataItem.fileUrl;

                    this.$scope.draft.REFERENCE.push(reference);
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeUpload(model: IUploadItem): Promise<boolean> {
        let result = false;
        try {
            if (!model) throw Error((this.formService.getTranslate("GENERAL.ITEM_PARAMETER_IS_NULL")));

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: this.formService.getTranslate("GENERAL.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_ATTACHMENT")
            });
            if (!modal) return;

            if (this.$scope.draft.REFERENCE && this.$scope.draft.REFERENCE.length > 0) {
                const index = this.$scope.draft.REFERENCE.findIndex(file => { return file.NAME == model.FILE_NAME });
                if (index > -1) {
                    this.$scope.draft.REFERENCE.splice(index, 1);
                    result = true;
                }
            }
        } catch (ex) {
            result = false;
            this.handleError(ex);
        } finally {
            return result;
        }
    }

    private setAirDocument(draftType: string) {
        try {
            if (draftType === DraftSource.HouseAWB) this.$scope.airDocument = this.$scope.draft.DOCUMENT.HOUSEAWB;
            if (draftType === DraftSource.MasterAWB) this.$scope.airDocument = this.$scope.draft.DOCUMENT.MASTERAWB;

            const thisModel = this.$scope.model;
            if (!thisModel) return;

            thisModel.DRAFT.SOURCE = draftType;
            this.$scope.draft.SOURCE = (thisModel && thisModel.DRAFT && thisModel.DRAFT.SOURCE) ? thisModel.DRAFT.SOURCE : null;

            if (this.$scope.operation !== 'register') return

            this.$scope.airDocument.ISSUING_CARRIER_AGENT = (thisModel && thisModel.BRANCH && thisModel.BRANCH.ADDRESS.CORRESPONDENCE) ? thisModel.BRANCH.ADDRESS.CORRESPONDENCE : null;
            this.$scope.airDocument.DEPARTURE = (thisModel && thisModel.EVENT_LOAD && thisModel.EVENT_LOAD.LOCAL && thisModel.EVENT_LOAD.LOCAL.CODE) ? thisModel.EVENT_LOAD.LOCAL.CODE : null;
            this.$scope.airDocument.DEPARTURE_TO_PRINT_ON_AWB = (thisModel && thisModel && thisModel.EVENT_LOAD && thisModel.EVENT_LOAD.LOCAL && thisModel.EVENT_LOAD.LOCAL.CODE && thisModel.EVENT_LOAD.LOCAL.NAME) ? thisModel.EVENT_LOAD.LOCAL.CODE + ' / ' + thisModel.EVENT_LOAD.LOCAL.NAME : null;
            this.$scope.airDocument.DESTINATION = (thisModel && thisModel.EVENT_DISCH && thisModel.EVENT_DISCH.LOCAL && thisModel.EVENT_DISCH.LOCAL.CODE) ? thisModel.EVENT_DISCH.LOCAL.CODE : null;
            this.$scope.airDocument.DESTINATION_TO_PRINT_ON_AWB = (thisModel && thisModel.EVENT_DISCH && thisModel.EVENT_DISCH.LOCAL && thisModel.EVENT_DISCH.LOCAL.CODE && thisModel.EVENT_DISCH.LOCAL.NAME) ? thisModel.EVENT_DISCH.LOCAL.CODE + ' / ' + thisModel.EVENT_DISCH.LOCAL.NAME : null;
            this.formatConnections();
            this.$scope.airDocument.CURRENCY = this.formatCurrency();
            this.$scope.airDocument.VALUE_FOR_CARRIAGE = "NVD";
            this.$scope.airDocument.VALUE_FOR_CUSTOMS = "NCV";
            this.$scope.airDocument.AMOUNT_OF_INSURANCE = "NIL";
            this.$scope.airDocument.CHARGE_CODE = this.formatChargeCode();
            this.$scope.airDocument.WT_VAL = (this.$scope.airDocument.CHARGE_CODE && this.$scope.airDocument.CHARGE_CODE === "PP") ? "PPD" : this.$scope.airDocument.CHARGE_CODE === "CC" ? "COLL" : null;
            this.$scope.airDocument.OTHER_CHARGES = (this.$scope.airDocument.CHARGE_CODE && this.$scope.airDocument.CHARGE_CODE === "PP") ? "PPD" : this.$scope.airDocument.CHARGE_CODE === "CC" ? "COLL" : null;
            this.$scope.airDocument.AIR_CARGO_LIST = this.formatAirCargoList();
            this.$scope.airDocument.OTHER_CHARGES_LIST = this.formatOtherChargeList();
            this.$scope.airDocument.SHIPPER_CERTIFICATION = (this.$scope.user && this.$scope.user['name'] && this.$scope.user['lastname']) ? this.$scope.user['name'] + ' ' + this.$scope.user['lastname'] : null;
            this.$scope.airDocument.CARRIER_EXECUTION = (this.$scope.user && this.$scope.user['name'] && this.$scope.user['lastname']) ? this.$scope.user['name'] + ' ' + this.$scope.user['lastname'] : null;

            if (draftType === DraftSource.HouseAWB) {
                this.$scope.airDocument.SHIPPER = (thisModel && thisModel.EXPORTER && thisModel.EXPORTER.ADDRESS && thisModel.EXPORTER.ADDRESS.CORRESPONDENCE) ? thisModel.EXPORTER.ADDRESS.CORRESPONDENCE : null;
                this.$scope.airDocument.TAX_ID_EXPORTER = (thisModel && thisModel.EXPORTER && thisModel.EXPORTER.TAXPAYER_NUMBER) ? thisModel.EXPORTER.TAXPAYER_NUMBER : null;
                this.$scope.airDocument.CONSIGNEE = (thisModel && thisModel.IMPORTER && thisModel.IMPORTER.ADDRESS && thisModel.IMPORTER.ADDRESS.CORRESPONDENCE) ? thisModel.IMPORTER.ADDRESS.CORRESPONDENCE : null;
                this.$scope.airDocument.TAX_ID_IMPORTER = (thisModel && thisModel.IMPORTER && thisModel.IMPORTER.TAXPAYER_NUMBER) ? thisModel.IMPORTER.TAXPAYER_NUMBER : null;
                this.$scope.airDocument.ISSUER = (thisModel && thisModel.BRANCH && thisModel.BRANCH.ADDRESS.CORRESPONDENCE) ? thisModel.BRANCH.ADDRESS.CORRESPONDENCE : null;

                if (thisModel && thisModel.HOUSE_PAYMENT && (thisModel.HOUSE_PAYMENT.ID === ETypePaymentId.COLLECT || thisModel.HOUSE_PAYMENT.ID === ETypePaymentId.COLLECT_ELSEWHERE)) {
                    this.$scope.airDocument.ACCOUNTING_INFORMATION = `AIR FREIGHT COLLECT\nNOTIFY: ${thisModel.NOTIFY ? thisModel.NOTIFY.ADDRESS ? thisModel.NOTIFY.ADDRESS.CORRESPONDENCE : '' : ''}`;
                } else if (thisModel && thisModel.HOUSE_PAYMENT && (thisModel.HOUSE_PAYMENT.ID === ETypePaymentId.PREPAID || thisModel.HOUSE_PAYMENT.ID === ETypePaymentId.PREPAID_ABROAD)) {
                    this.$scope.airDocument.ACCOUNTING_INFORMATION = `AIR FREIGHT PREPAID\nNOTIFY: ${thisModel.NOTIFY ? thisModel.NOTIFY.ADDRESS ? thisModel.NOTIFY.ADDRESS.CORRESPONDENCE : '' : ''}`;
                }
            }

            if (draftType === DraftSource.MasterAWB) {
                if (thisModel.MASTER_DIRECT) {
                    this.$scope.airDocument.SHIPPER = (thisModel.EXPORTER && thisModel.EXPORTER.ADDRESS && thisModel.EXPORTER.ADDRESS.CORRESPONDENCE) ? thisModel.EXPORTER.ADDRESS.CORRESPONDENCE : null;
                    this.$scope.airDocument.TAX_ID_EXPORTER = (thisModel.EXPORTER && thisModel.EXPORTER.TAXPAYER_NUMBER) ? thisModel.EXPORTER.TAXPAYER_NUMBER : null;
                    this.$scope.airDocument.CONSIGNEE = (thisModel.IMPORTER && thisModel.IMPORTER.ADDRESS && thisModel.IMPORTER.ADDRESS.CORRESPONDENCE) ? thisModel.IMPORTER.ADDRESS.CORRESPONDENCE : null;
                    this.$scope.airDocument.TAX_ID_IMPORTER = (thisModel.IMPORTER && thisModel.IMPORTER.TAXPAYER_NUMBER) ? thisModel.IMPORTER.TAXPAYER_NUMBER : null;
                } else {
                    this.$scope.airDocument.SHIPPER = (thisModel.BRANCH && thisModel.BRANCH.ADDRESS && thisModel.BRANCH.ADDRESS.CORRESPONDENCE) ? thisModel.BRANCH.ADDRESS.CORRESPONDENCE : null;
                    this.$scope.airDocument.TAX_ID_EXPORTER = (thisModel.BRANCH && thisModel.BRANCH.TAXPAYER_NUMBER) ? thisModel.BRANCH.TAXPAYER_NUMBER : null;
                    this.$scope.airDocument.CONSIGNEE = (thisModel.EXTERNAL_AGENT && thisModel.EXTERNAL_AGENT.ADDRESS && thisModel.EXTERNAL_AGENT.ADDRESS.CORRESPONDENCE) ? thisModel.EXTERNAL_AGENT.ADDRESS.CORRESPONDENCE : null;
                    this.$scope.airDocument.TAX_ID_IMPORTER = (thisModel.EXTERNAL_AGENT && thisModel.EXTERNAL_AGENT.TAXPAYER_NUMBER) ? thisModel.EXTERNAL_AGENT.TAXPAYER_NUMBER : null;
                }
                this.$scope.airDocument.ISSUER = (thisModel && thisModel.SERVICE_PROVIDER && thisModel.SERVICE_PROVIDER.NAME) ? thisModel.SERVICE_PROVIDER.NAME : null;

                if (thisModel.MASTER_PAYMENT && thisModel.CONTRACT_TYPE && thisModel.CONTRACT_TYPE.ID !== '2') {
                    if (thisModel.MASTER_PAYMENT.ID === ETypePaymentId.PREPAID || thisModel.MASTER_PAYMENT.ID === ETypePaymentId.PREPAID_ABROAD) {
                        this.$scope.airDocument.ACCOUNTING_INFORMATION = "AIR FREIGHT PREPAID";
                    }
                    if (thisModel.MASTER_PAYMENT.ID === ETypePaymentId.COLLECT || thisModel.MASTER_PAYMENT.ID === ETypePaymentId.COLLECT_ELSEWHERE) {
                        this.$scope.airDocument.ACCOUNTING_INFORMATION = "AIR FREIGHT COLLECT";
                    }
                } else if (thisModel.MASTER_PAYMENT && thisModel.CONTRACT_TYPE && thisModel.CONTRACT_TYPE.ID === '2') {
                    if (thisModel.MASTER_PAYMENT.ID === ETypePaymentId.PREPAID || thisModel.MASTER_PAYMENT.ID === ETypePaymentId.PREPAID_ABROAD) {
                        this.$scope.airDocument.ACCOUNTING_INFORMATION = `AIR FREIGHT PREPAID\nSPOT Rate: ${thisModel.SPOT_RATE ? thisModel.SPOT_RATE : ""}`;
                    }
                    if (thisModel.MASTER_PAYMENT.ID === ETypePaymentId.COLLECT || thisModel.MASTER_PAYMENT.ID === ETypePaymentId.COLLECT_ELSEWHERE) {
                        this.$scope.airDocument.ACCOUNTING_INFORMATION = `AIR FREIGHT COLLECT\nSPOT Rate: ${thisModel.SPOT_RATE ? thisModel.SPOT_RATE : ""}`;
                    }
                }
            }

            const minimumTemperature = this.$scope.model.CARGO_DETAIL && this.$scope.model.CARGO_DETAIL.REFRIGERATED_CARGO && this.$scope.model.CARGO_DETAIL.REFRIGERATED_CARGO.MINIMUM_TEMPERATURE ? this.$scope.model.CARGO_DETAIL.REFRIGERATED_CARGO.MINIMUM_TEMPERATURE : null;
            const maximumTemperature = this.$scope.model.CARGO_DETAIL && this.$scope.model.CARGO_DETAIL.REFRIGERATED_CARGO && this.$scope.model.CARGO_DETAIL.REFRIGERATED_CARGO.MAXIMUM_TEMPERATURE ? this.$scope.model.CARGO_DETAIL.REFRIGERATED_CARGO.MAXIMUM_TEMPERATURE : null;
            this.$scope.airDocument.HANDLING_INFORMATION = this.$scope.model.CARGO_DETAIL.IS_REFRIGERATED_CARGO ? "Refrigerated cargo (" + minimumTemperature + "ºC to " + maximumTemperature + "ºC)" : null;
            this.$scope.airDocument.IATA_NUMBER = this.$scope.model.BRANCH && this.$scope.model.BRANCH.NUMBER_IATA ? this.$scope.model.BRANCH.NUMBER_IATA : null;

            this.sumAirCargoList();
            this.sumAirOtherChargeList();
            this.$formService.validateForm();

        }
        catch (ex) {
            this.handleError(ex);
        }
    }

    private formatAddress(entity: ENTITY): string {
        try {
            let description: string = "";
            if (entity) {
                const name: string = (entity.TRADING_NAME) ? entity.TRADING_NAME : "";
                const address: string = (entity.ADDRESS && entity.ADDRESS.PUBLIC_PLACE) ? entity.ADDRESS.PUBLIC_PLACE : "";
                const number: string = (entity.ADDRESS && entity.ADDRESS.NUMBER) ? entity.ADDRESS.NUMBER.toString() : "";
                const complement: string = (entity.ADDRESS && entity.ADDRESS.COMPLEMENT) ? entity.ADDRESS.COMPLEMENT : "";
                const zip: string = (entity.ADDRESS && entity.ADDRESS.ZIP_CODE) ? entity.ADDRESS.ZIP_CODE : "";
                const city: string = (entity.ADDRESS && entity.ADDRESS.CITY && entity.ADDRESS.CITY.NAME) ? entity.ADDRESS.CITY.NAME : "";
                const province: string = (entity.ADDRESS && entity.ADDRESS.PROVINCE && entity.ADDRESS.PROVINCE.NAME) ? entity.ADDRESS.PROVINCE.NAME : "";
                const country: string = (entity.ADDRESS && entity.ADDRESS.COUNTRY && entity.ADDRESS.COUNTRY.NAME) ? entity.ADDRESS.COUNTRY.NAME : "";
                const cnpj: string = "CNPJ: " + ((entity && entity.TAXPAYER_NUMBER) ? entity.TAXPAYER_NUMBER : "");

                description += `${this.formatWords(name)}\n${address} ${number} ${complement} ${zip}\n${city} ${province} ${country}\n${cnpj}`;
            }
            return this.formatText(description, 35);
        }
        catch (ex) {
            this.handleError(ex);
        }
    }

    private formatChargeCode(): string {
        if (!this.$scope.model.HOUSE_PAYMENT.NAME) return "";
        let housePaymentName = "";

        if (this.$scope.source === DraftSource.HouseAWB) {
            housePaymentName = this.$scope.model.HOUSE_PAYMENT.NAME;
        }
        if (this.$scope.source === DraftSource.MasterAWB) {
            housePaymentName = this.$scope.model.MASTER_PAYMENT.NAME;
        }
        const nameComparable = housePaymentName.toLowerCase();
        if (nameComparable === "prepaid" || nameComparable === "prepaid abroad") return "PP";
        if (nameComparable === "collect" || nameComparable === "collect elsewhere") return "CC";
        return "";
    }

    private formatConnections(): void {
        const thisModel = this.$scope.model;
        try {
            this.$scope.airDocument.FIRST_TO = (thisModel && thisModel.EVENT_CONN_ARRIVAL && thisModel.EVENT_CONN_ARRIVAL[0] && thisModel.EVENT_CONN_ARRIVAL[0].LOCAL && thisModel.EVENT_CONN_ARRIVAL[0].LOCAL.CODE) ?
                thisModel.EVENT_CONN_ARRIVAL[0].LOCAL.CODE :
                (thisModel.EVENT_DISCH && thisModel.EVENT_DISCH.LOCAL && thisModel.EVENT_DISCH.LOCAL.CODE) ? thisModel.EVENT_DISCH.LOCAL.CODE : null;
            this.$scope.airDocument.FIRST_BY = (thisModel && thisModel.EVENT_CONN_ARRIVAL && thisModel.EVENT_CONN_ARRIVAL[0] && thisModel.EVENT_CONN_ARRIVAL[0].SERVICE_PROVIDER && thisModel.EVENT_CONN_ARRIVAL[0].SERVICE_PROVIDER.NAME) ?
                thisModel.EVENT_CONN_ARRIVAL[0].SERVICE_PROVIDER.NAME :
                (thisModel.EVENT_DISCH && thisModel.EVENT_DISCH.SERVICE_PROVIDER && thisModel.EVENT_DISCH.SERVICE_PROVIDER.NAME) ? thisModel.EVENT_DISCH.SERVICE_PROVIDER.NAME : null;
            this.$scope.airDocument.SECOND_TO = (thisModel && thisModel.EVENT_CONN_ARRIVAL && thisModel.EVENT_CONN_ARRIVAL[1] && thisModel.EVENT_CONN_ARRIVAL[1].LOCAL && thisModel.EVENT_CONN_ARRIVAL[1].LOCAL.CODE) ?
                thisModel.EVENT_CONN_ARRIVAL[1].LOCAL.CODE :
                (thisModel.EVENT_CONN_ARRIVAL && thisModel.EVENT_CONN_ARRIVAL[0] && thisModel.EVENT_CONN_ARRIVAL[0].LOCAL && thisModel.EVENT_CONN_ARRIVAL[0].LOCAL.CODE && thisModel.EVENT_DISCH && thisModel.EVENT_DISCH.LOCAL && thisModel.EVENT_DISCH.LOCAL.CODE) ?
                    thisModel.EVENT_DISCH.LOCAL.CODE : null;
            this.$scope.airDocument.SECOND_BY = (thisModel && thisModel.EVENT_CONN_ARRIVAL && thisModel.EVENT_CONN_ARRIVAL[1] && thisModel.EVENT_CONN_ARRIVAL[1].SERVICE_PROVIDER && thisModel.EVENT_CONN_ARRIVAL[1].SERVICE_PROVIDER.NAME) ?
                thisModel.EVENT_CONN_ARRIVAL[1].SERVICE_PROVIDER.NAME :
                (thisModel.EVENT_CONN_ARRIVAL && thisModel.EVENT_CONN_ARRIVAL[0] && thisModel.EVENT_CONN_ARRIVAL[0].SERVICE_PROVIDER && thisModel.EVENT_CONN_ARRIVAL[0].SERVICE_PROVIDER.CODE && thisModel.EVENT_DISCH && thisModel.EVENT_DISCH.SERVICE_PROVIDER && thisModel.EVENT_DISCH.SERVICE_PROVIDER.CODE) ?
                    thisModel.EVENT_DISCH.SERVICE_PROVIDER.CODE : null;
            this.$scope.airDocument.THIRD_TO = (thisModel && thisModel.EVENT_CONN_ARRIVAL && thisModel.EVENT_CONN_ARRIVAL[2] && thisModel.EVENT_CONN_ARRIVAL[2].LOCAL && thisModel.EVENT_CONN_ARRIVAL[2].LOCAL.CODE) ?
                thisModel.EVENT_CONN_ARRIVAL[2].LOCAL.CODE :
                (thisModel.EVENT_CONN_ARRIVAL && thisModel.EVENT_CONN_ARRIVAL[1] && thisModel.EVENT_CONN_ARRIVAL[1].LOCAL && thisModel.EVENT_CONN_ARRIVAL[1].LOCAL.CODE && thisModel.EVENT_DISCH && thisModel.EVENT_DISCH.LOCAL && thisModel.EVENT_DISCH.LOCAL.CODE) ?
                    thisModel.EVENT_DISCH.LOCAL.CODE : null;
            this.$scope.airDocument.SECOND_BY = (thisModel && thisModel.EVENT_CONN_ARRIVAL && thisModel.EVENT_CONN_ARRIVAL[2] && thisModel.EVENT_CONN_ARRIVAL[2].SERVICE_PROVIDER && thisModel.EVENT_CONN_ARRIVAL[2].SERVICE_PROVIDER.NAME) ?
                thisModel.EVENT_CONN_ARRIVAL[2].SERVICE_PROVIDER.NAME :
                (thisModel.EVENT_CONN_ARRIVAL && thisModel.EVENT_CONN_ARRIVAL[1] && thisModel.EVENT_CONN_ARRIVAL[1].SERVICE_PROVIDER && thisModel.EVENT_CONN_ARRIVAL[1].SERVICE_PROVIDER.CODE && thisModel.EVENT_DISCH && thisModel.EVENT_DISCH.SERVICE_PROVIDER && thisModel.EVENT_DISCH.SERVICE_PROVIDER.CODE) ?
                    thisModel.EVENT_DISCH.SERVICE_PROVIDER.CODE : null;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private formatCurrency(): string {
        let currency: string = "";
        if (this.$scope.model.CHARGE && this.$scope.model.CHARGE.length > 0) {
            if (this.$scope.source === DraftSource.HouseAWB) {
                const freightCharges: CHARGE[] = this.$scope.model.CHARGE.filter(charge => charge.INCOME.CHARGE_ORIGIN.ID == EChargeOriginId.FREIGHT);
                const highestFreightCharge = freightCharges ? freightCharges.reduce(function (prev, current) {
                    return (prev.INCOME.TOTAL > current.INCOME.TOTAL) ? prev : current
                }) : null;
                currency = highestFreightCharge ? highestFreightCharge.INCOME.CURRENCY.CODE : null;
            }
            if (this.$scope.source === DraftSource.MasterAWB) {
                const freightCharges: CHARGE[] = this.$scope.model.CHARGE.filter(charge => charge.OUTCOME.CHARGE_ORIGIN.ID == EChargeOriginId.FREIGHT);
                const highestFreightCharge = freightCharges ? freightCharges.reduce(function (prev, current) {
                    return (prev.OUTCOME.TOTAL > current.OUTCOME.TOTAL) ? prev : current
                }) : null;
                currency = highestFreightCharge ? highestFreightCharge.OUTCOME.CURRENCY.CODE : null;
            }
        }
        if (currency) return currency;
        return;
    }

    private formatRateCharge(charges: CHARGE[]): string {
        let rateCharge: string = null;
        if (charges.length > 0) {
            if (this.$scope.source === DraftSource.HouseAWB) {
                const freightCharges: CHARGE[] = this.$scope.model.CHARGE.filter(charge => charge.INCOME.CHARGE_ORIGIN.ID == EChargeOriginId.FREIGHT);
                const highestFreightCharge = freightCharges ? freightCharges.reduce(function (prev, current) {
                    return (prev.INCOME.TOTAL > current.INCOME.TOTAL) ? prev : current
                }) : null;
                rateCharge = highestFreightCharge ? highestFreightCharge.INCOME.UNITARY.toFixed(2) : null;
            }
            if (this.$scope.source === DraftSource.MasterAWB) {
                if (this.$scope.model.MASTER_DIRECT) {
                    const freightCharges: CHARGE[] = this.$scope.model.CHARGE.filter(charge => charge.INCOME.CHARGE_ORIGIN.ID == EChargeOriginId.FREIGHT);
                    const highestFreightCharge = freightCharges ? freightCharges.reduce(function (prev, current) {
                        return (prev.INCOME.TOTAL > current.INCOME.TOTAL) ? prev : current
                    }) : null;
                    rateCharge = highestFreightCharge ? highestFreightCharge.INCOME.UNITARY.toFixed(2) : null;
                } else {
                    const freightCharges: CHARGE[] = this.$scope.model.CHARGE.filter(charge => charge.OUTCOME.CHARGE_ORIGIN.ID == EChargeOriginId.FREIGHT);
                    const highestFreightCharge = freightCharges ? freightCharges.reduce(function (prev, current) {
                        return (prev.OUTCOME.TOTAL > current.OUTCOME.TOTAL) ? prev : current
                    }) : null;
                    rateCharge = highestFreightCharge ? highestFreightCharge.OUTCOME.UNITARY.toFixed(2) : null;
                }

            }
        }
        if (rateCharge) return rateCharge;
        return;
    }

    private formatTotal(charges: CHARGE[]): string {
        let airCharge: string = "";
        if (charges.length > 0) {
            if (this.$scope.source === DraftSource.HouseAWB) {
                const freightCharges: CHARGE[] = this.$scope.model.CHARGE.filter(charge => charge.INCOME.CHARGE_ORIGIN.ID == EChargeOriginId.FREIGHT);
                const highestFreightCharge = freightCharges ? freightCharges.reduce(function (prev, current) {
                    return (prev.INCOME.TOTAL > current.INCOME.TOTAL) ? prev : current
                }) : null;
                airCharge = highestFreightCharge ? highestFreightCharge.INCOME.TOTAL.toFixed(2) : null;
            }
            if (this.$scope.source === DraftSource.MasterAWB) {
                if (this.$scope.model.MASTER_DIRECT) {
                    const freightCharges: CHARGE[] = this.$scope.model.CHARGE.filter(charge => charge.INCOME.CHARGE_ORIGIN.ID == EChargeOriginId.FREIGHT);
                    const highestFreightCharge = freightCharges ? freightCharges.reduce(function (prev, current) {
                        return (prev.INCOME.TOTAL > current.INCOME.TOTAL) ? prev : current
                    }) : null;
                    airCharge = highestFreightCharge ? highestFreightCharge.INCOME.TOTAL.toFixed(2) : null;
                } else {
                    const freightCharges: CHARGE[] = this.$scope.model.CHARGE.filter(charge => charge.OUTCOME.CHARGE_ORIGIN.ID == EChargeOriginId.FREIGHT);
                    const highestFreightCharge = freightCharges ? freightCharges.reduce(function (prev, current) {
                        return (prev.OUTCOME.TOTAL > current.OUTCOME.TOTAL) ? prev : current
                    }) : null;
                    airCharge = highestFreightCharge ? highestFreightCharge.OUTCOME.TOTAL.toFixed(2) : null;
                }
            }
        }
        if (airCharge) return airCharge;
        return;
    }

    private formatChargeableWeightCharge(charges: CHARGE[]): string {
        let airCharge: string = "";
        if (charges.length > 0) {
            if (this.$scope.source === DraftSource.HouseAWB) {
                const freightCharges: CHARGE[] = this.$scope.model.CHARGE.filter(charge => charge.INCOME.CHARGE_ORIGIN.ID == EChargeOriginId.FREIGHT);
                const highestFreightCharge = freightCharges ? freightCharges.reduce(function (prev, current) {
                    return (prev.INCOME.AMOUNT > current.INCOME.AMOUNT) ? prev : current
                }) : null;
                airCharge = highestFreightCharge ? highestFreightCharge.INCOME.AMOUNT.toFixed(2) : null;
            }
            if (this.$scope.source === DraftSource.MasterAWB) {
                if (this.$scope.model.MASTER_DIRECT) {
                    const freightCharges: CHARGE[] = this.$scope.model.CHARGE.filter(charge => charge.INCOME.CHARGE_ORIGIN.ID == EChargeOriginId.FREIGHT);
                    const highestFreightCharge = freightCharges ? freightCharges.reduce(function (prev, current) {
                        return (prev.INCOME.AMOUNT > current.INCOME.AMOUNT) ? prev : current
                    }) : null;
                    airCharge = highestFreightCharge ? highestFreightCharge.INCOME.AMOUNT.toFixed(2) : null;
                } else {
                    const freightCharges: CHARGE[] = this.$scope.model.CHARGE.filter(charge => charge.OUTCOME.CHARGE_ORIGIN.ID == EChargeOriginId.FREIGHT);
                    const highestFreightCharge = freightCharges ? freightCharges.reduce(function (prev, current) {
                        return (prev.OUTCOME.AMOUNT > current.OUTCOME.AMOUNT) ? prev : current
                    }) : null;
                    airCharge = highestFreightCharge ? highestFreightCharge.OUTCOME.AMOUNT.toFixed(2) : null;
                }
            }
        }
        if (airCharge) return airCharge;
        return;
    }

    private formatAirCargoList() {
        const process = this.$scope.model;
        let natureAndQuantity: string = "";
        let dimensions: string = "";

        if (process.CARGO_LIST) {
            process.CARGO_LIST.forEach(cargo => {
                dimensions += `${cargo.CCM ? cargo.CCM : ""} x ${cargo.LCM ? cargo.LCM : ""} x ${cargo.ACM ? cargo.ACM : ""} cm x ${cargo.VOLUME ? cargo.VOLUME : ""} vol x ${cargo.CBM} m³\n`
            });

            let rateCharge: string = this.formatRateCharge(process.CHARGE);
            let totalCharge: string = this.formatTotal(process.CHARGE);
            let chargeableWeight: string = this.formatChargeableWeightCharge(process.CHARGE);

            if (this.$scope.source === DraftSource.HouseAWB) {
                natureAndQuantity = `${process.COMMODITY_SUMMARY ? process.COMMODITY_SUMMARY : ""}\n Invoice: ${process.COMMERCIAL_INVOICE ? process.COMMERCIAL_INVOICE : ""}\n Freight ${process.HOUSE_PAYMENT.NAME ? process.HOUSE_PAYMENT.NAME : ""}\n DU-E: \n Dimensions: ${dimensions}`
            }

            if (this.$scope.source === DraftSource.MasterAWB) {
                if (this.$scope.model.MASTER_DIRECT) {
                    natureAndQuantity = `${process.COMMODITY_SUMMARY ? process.COMMODITY_SUMMARY : ""}\n Invoice: ${process.COMMERCIAL_INVOICE ? process.COMMERCIAL_INVOICE : ""}\n Freight ${process.HOUSE_PAYMENT.NAME ? process.HOUSE_PAYMENT.NAME : ""}\n DU-E: \n Dimensions: ${dimensions}`
                } else {
                    natureAndQuantity = `CONSOLIDATED CARGO AS PER ATTACHED MANIFEST  \n House AWB: \n ${process.HOUSE_DOCUMENT ? process.HOUSE_DOCUMENT : ""}`
                }
            }

            return [{
                PIECES: process.CARGO_DETAIL.VOLUME.toFixed(),
                GROSS_WEIGHT: process.CARGO_DETAIL.GROSS_WEIGHT.toFixed(3),
                K_L: "K",
                RATE_CLASS: "K",
                ITEM_NO: null,
                CHARGEABLE_WEIGHT: chargeableWeight,
                RATE_CHARGE: rateCharge,
                TOTAL: totalCharge,
                NATURE_AND_QUANTITY: natureAndQuantity,
            }];
        }

        return [];
    }

    private formatOtherChargeList() {
        const agentOrCarrierCharges: OTHER_CHARGES_LIST[] = [];
        if (this.$scope.model.CHARGE && this.$scope.model.CHARGE.length > 0) {
            const charges = this.$scope.model.CHARGE;
            let agentCharge = null;
            let carrierCharge = null;
            for (const charge of charges) {
                if (this.$scope.source === DraftSource.HouseAWB && charge.INCOME.SHOW_FOR_GROUP) {
                    agentCharge = charge.INCOME.SHOW_FOR_GROUP.find(group => group.NAME === "AWB - Agent");
                    carrierCharge = charge.INCOME.SHOW_FOR_GROUP.find(group => group.NAME === "AWB - Carrier");
                    if (agentCharge || carrierCharge) {
                        agentOrCarrierCharges.push({
                            DESCRIPTION: charge.CHARGE_EXHIBITION.NAME_INTL,
                            AMOUNT: charge.INCOME.TOTAL,
                            ENTITLEMENT: agentCharge ? "A" : carrierCharge ? "C" : ""
                        });
                    }
                }

                if (this.$scope.source === DraftSource.MasterAWB) {
                    if (this.$scope.model.MASTER_DIRECT) {
                        agentCharge = charge.INCOME && charge.INCOME.SHOW_FOR_GROUP && charge.INCOME.SHOW_FOR_GROUP.find(group => group.NAME === "AWB - Agent");
                        carrierCharge = charge.OUTCOME && charge.OUTCOME.SHOW_FOR_GROUP && charge.OUTCOME.SHOW_FOR_GROUP.find(group => group.NAME === "AWB - Carrier");
                    } else {
                        carrierCharge = charge.OUTCOME && charge.OUTCOME.SHOW_FOR_GROUP && charge.OUTCOME.SHOW_FOR_GROUP.find(group => group.NAME === "AWB - Carrier");
                    }

                    if (agentCharge) {
                        agentOrCarrierCharges.push({
                            DESCRIPTION: charge.CHARGE_EXHIBITION.NAME_INTL,
                            AMOUNT: charge.INCOME.TOTAL,
                            ENTITLEMENT: "A"
                        });
                    }

                    if (carrierCharge) {
                        agentOrCarrierCharges.push({
                            DESCRIPTION: charge.CHARGE_EXHIBITION.NAME_INTL,
                            AMOUNT: charge.OUTCOME.TOTAL,
                            ENTITLEMENT: "C"
                        });
                    }

                }
            }

            return agentOrCarrierCharges;
        }
    }

    private formatWords(word: string): string {
        if (!word) return "";
        const words = word.toLowerCase().split(" ");
        for (let a = 0; a < words.length; a++) {
            const w = words[a];
            words[a] = w[0].toUpperCase() + w.slice(1);
        }
        return words.join(" ");
    }

    protected decodeBoolean(value: boolean): string {
        if (value) {
            return 'Yes';
        } else {
            return 'No';
        }
    }

    protected getDraftDocumentTypes() {
        return [DraftSource.HouseAWB, DraftSource.MasterAWB]
    }

    protected getKL() {
        return ["K", "L"]
    }

    protected getDraftType(draftType: string) {
        this.$scope.draft.SOURCE = draftType;
        this.$scope.source = draftType;
        if (draftType === DraftSource.HouseAWB) return this.setAirDocument(DraftSource.HouseAWB);
        if (draftType === DraftSource.MasterAWB) return this.setAirDocument(DraftSource.MasterAWB);
    }

    protected formatInput(property: string, htmlComponent: string, label: string) {
        try {
            if (!property) return;
            const formProperty = this.$scope.document[property] ? this.$scope.document[property] : this.$scope.airDocument[property] ? this.$scope.airDocument[property] : null;
            if (formProperty && formProperty.length > 0) {
                this.$scope.validation[label] = "";
                this.$scope.form[htmlComponent].$setValidity('$valid', true);

                let text: string = formProperty;
                if (!text || !text.length) return;

                let list = text.split('\n');
                let regex;

                // If the product is 'EM' and the field is 'DUE', then the hyphen is not an allowed special character.
                if (this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.ID === EProductId.MARITIME_EXPORT && property === 'DUE') {
                    regex = /[\u00C0-\u00FF-]/;
                } else {
                    regex = /[\u00C0-\u00FF]/;
                }

                let hasAccentuation = list.some(item => regex.test(item));
                if (hasAccentuation) {
                    this.$scope.form[htmlComponent].$setValidity('$valid', false);
                    this.$scope.validation[label] = "This field contains forbidden characters";
                }

            } else {
                this.$scope.validation[label] = "";
                this.$scope.form[htmlComponent].$setValidity('$valid', true);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getCorporateBranch(search: string): Promise<void> {
        try {
            if (search && search.length >= 3) {
                this.block();

                const route = `/corporateBranch/list/custom/`;
                const rc = await this.ProductService.post({ route });

                if (rc && rc.status === 200) this.$scope.corporateBranchList = (rc && rc.data && rc.data.data) ? rc.data.data : [];
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async updateCacheDraft(processNumber: string, typeDocument: string): Promise<void> {
        try {
            if (processNumber) {
                this.block();
                const timeout = 120000;
                const request = { processNumber: processNumber, typeDocument: typeDocument };
                const rc = await this.OperationalService.post("/draft/updateDraftProcess", request, timeout);

                if (rc && rc.status == 200) {
                    await this.updateGrid();
                    this.$formService.notifySuccess(this.formService.getTranslate("GENERAL.DRAFT_STATUS_UPDATED"));
                    const resultDraft = await this.OperationalService.get(`/draft/getDraftView/${request.processNumber}/${request.typeDocument}`, null, timeout);
                    if (resultDraft && resultDraft.data) {
                        const draft = resultDraft.data.data.data.DRAFT;
                        switch (typeDocument) {
                            case DraftSource.MasterAWB:
                                for (let i = 0; i < this.$scope.model.DRAFT.DOCUMENT.MASTERAWB.AIR_CARGO_LIST.length; i++) {
                                    for (let j = 0; j < draft.DOCUMENT.MASTERAWB.AIR_CARGO_LIST.length; j++) {
                                        if (this.$scope.model.DRAFT.DOCUMENT.MASTERAWB.AIR_CARGO_LIST[i].PIECES && draft.DOCUMENT.MASTERAWB.AIR_CARGO_LIST[j].PIECES) this.$scope.model.DRAFT.DOCUMENT.MASTERAWB.AIR_CARGO_LIST[i].PIECES = draft.DOCUMENT.MASTERAWB.AIR_CARGO_LIST[j].PIECES
                                        if (this.$scope.model.DRAFT.DOCUMENT.MASTERAWB.AIR_CARGO_LIST[i].TOTAL && draft.DOCUMENT.MASTERAWB.AIR_CARGO_LIST[j].TOTAL) this.$scope.model.DRAFT.DOCUMENT.MASTERAWB.AIR_CARGO_LIST[i].TOTAL = draft.DOCUMENT.MASTERAWB.AIR_CARGO_LIST[j].TOTAL
                                        if (this.$scope.model.DRAFT.DOCUMENT.MASTERAWB.AIR_CARGO_LIST[i].GROSS_WEIGHT && draft.DOCUMENT.MASTERAWB.AIR_CARGO_LIST[j].GROSS_WEIGHT) this.$scope.model.DRAFT.DOCUMENT.MASTERAWB.AIR_CARGO_LIST[i].GROSS_WEIGHT = draft.DOCUMENT.MASTERAWB.AIR_CARGO_LIST[j].GROSS_WEIGHT
                                    }
                                }
                                this.$scope.model.DRAFT.DOCUMENT.MASTERAWB.TOTAL_LIST = draft.DOCUMENT.MASTERAWB.TOTAL_LIST;
                                this.$scope.model.DRAFT.DOCUMENT.MASTERAWB.OTHER_CHARGES_LIST = draft.DOCUMENT.MASTERAWB.OTHER_CHARGES_LIST;
                                break;
                            case DraftSource.HouseAWB:
                                for (let i = 0; i < this.$scope.model.DRAFT.DOCUMENT.HOUSEAWB.AIR_CARGO_LIST.length; i++) {
                                    for (let j = 0; j < draft.DOCUMENT.HOUSEAWB.AIR_CARGO_LIST.length; j++) {
                                        if (this.$scope.model.DRAFT.DOCUMENT.HOUSEAWB.AIR_CARGO_LIST[i].PIECES && draft.DOCUMENT.HOUSEAWB.AIR_CARGO_LIST[j].PIECES) this.$scope.model.DRAFT.DOCUMENT.HOUSEAWB.AIR_CARGO_LIST[i].PIECES = draft.DOCUMENT.HOUSEAWB.AIR_CARGO_LIST[j].PIECES
                                        if (this.$scope.model.DRAFT.DOCUMENT.HOUSEAWB.AIR_CARGO_LIST[i].TOTAL && draft.DOCUMENT.HOUSEAWB.AIR_CARGO_LIST[j].TOTAL) this.$scope.model.DRAFT.DOCUMENT.HOUSEAWB.AIR_CARGO_LIST[i].TOTAL = draft.DOCUMENT.HOUSEAWB.AIR_CARGO_LIST[j].TOTAL
                                        if (this.$scope.model.DRAFT.DOCUMENT.HOUSEAWB.AIR_CARGO_LIST[i].GROSS_WEIGHT && draft.DOCUMENT.HOUSEAWB.AIR_CARGO_LIST[j].GROSS_WEIGHT) this.$scope.model.DRAFT.DOCUMENT.HOUSEAWB.AIR_CARGO_LIST[i].GROSS_WEIGHT = draft.DOCUMENT.HOUSEAWB.AIR_CARGO_LIST[j].GROSS_WEIGHT
                                    }
                                } this.$scope.model.DRAFT.DOCUMENT.HOUSEAWB.TOTAL_LIST = draft.DOCUMENT.HOUSEAWB.TOTAL_LIST;
                                this.$scope.model.DRAFT.DOCUMENT.HOUSEAWB.OTHER_CHARGES_LIST = draft.DOCUMENT.HOUSEAWB.OTHER_CHARGES_LIST;
                                break;
                        }
                    }
                }
                else this.$formService.notifyError(this.formService.getTranslate("GENERAL.DRAFT_UPDATE_ERROR"));
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private isCorporateBranchValidity(selectedBranch: string, htmlComponent: string): void {
        let message: string = null;
        this.$scope.validation.corporateBranch = "";

        if (this.$scope.model && this.$scope.model.BRANCH) {
            message = this.formService.getTranslate("OPERATIONAL.BRANCH_MISMATCH");
            const processBranchName: string = this.$scope.model.BRANCH.NAME;

            if (processBranchName) {
                if (selectedBranch === processBranchName) {
                    message = null;
                    this.$timeout(() => {
                        this.$scope.setSelectorStatus(htmlComponent, true);
                    });

                } else {
                    this.$scope.validation.corporateBranch = message;
                    this.$timeout(() => {
                        this.$scope.setSelectorStatus(htmlComponent, false);
                    });
                }
            }
        }
    }

}

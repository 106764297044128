export const enum ETypeRuleId {
    MANDATORY = "1",
    OPTIONAL = "2",
    DISABLED = "3"
}

export const enum ERouteEventType {
    TARIFF_FREIGHT = "TF",
    TARIFF_DOMESTIC_ORIGIN = "DO",
    TARIFF_DOMESTIC_DESTINATION = "DD",
    PICK_UP = "PUP",
    ORIGIN_TERMINAL_FREIGHT_STATION = "OTFS",
    AIRPORT_OF_ORIGIN = "AOL",
    PORT_OF_LOAD = "POL",
    AIRPORT_OF_DESTINATION = "AOD",
    PORT_OF_DESTINATION = "POD",
    DESTINATION_TERMINAL_FREIGHT_STATION = "DTFS",
    PLACE_OF_DELIVERY = "PLD",
    TRANSSHIPMENT = "XXX",
    TARIFF_LOCAL_DESTINATION = "LD",
    TARIFF_LOCAL_ORIGIN = "LO"
}

import * as angular from "angular";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { ISessionService } from "@services/SessionService";
import { EOperation } from "@enums/GenericData";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IAutoRatingParamModel } from "@models/interface/product/AutoRatingParamModel";
import { SelectorModel } from "../../common/model/SelectorModel";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { ILegalPersonListCustomFilter } from '../../registration/model/LegalPersonModel';
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { HelperService } from "@services/HelperService";

interface IAutoRatingParamScope extends IGridFormServiceScope {
    model: IAutoRatingParamModel;
    log: IViewLog;
    productList: SelectorModel[];
    cargoTypeList: SelectorModel[];
    directionList: SelectorModel[];
    transactionNatureList: SelectorModel[];
    accountList: SelectorModel[];
    processTypeList: SelectorModel[];
    qualificationList: SelectorModel[];
    accountTypeList: SelectorModel[];
    commercialUnityList: SelectorModel[];
    physicalPersonList: SelectorModel[];
    contractModalityList: SelectorModel[];
    tariffOriginList: SelectorModel[];
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IAutoRatingParamModel;
    sessionService: ISessionService;
    editAutoRatingParam: (airService: IAutoRatingParamModel) => Promise<void>;
    viewAutoRatingParam: (airService: IAutoRatingParamModel) => Promise<void>;
    viewLogAutoRatingParam: (airService: IAutoRatingParamModel) => Promise<void>;
    copyAutoRatingParam: (airService: IAutoRatingParamModel) => Promise<void>;
    getAccountListByName: (search: string) => Promise<void>;
    getCommercialUnityListByName: (search: string) => Promise<void>;
    getPhysicalPersonListByName: (search: string) => Promise<void>;
    fetchData: (id: number, action: string) => Promise<void>;
}

export class AutoRatingParamRegisterController extends GridFormService implements IGridFormController {

    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IAutoRatingParamScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private gridName: string;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IAutoRatingParamScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.gridName = 'GRID_AUTO_RATING_PARAM';
        this.helperService = $injector.get('HelperService');
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'autoRatingParam', 'Auto Rating Param', true);
            await this.initGrid(this.gridName, '/autoRatingParam/list', true, true, null, true, true);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        try {
            this.$scope.editAutoRatingParam = async (autoRatingParam: IAutoRatingParamModel): Promise<void> => {
                this.$scope.fetchData(autoRatingParam.ID, EOperation.EDIT);
            }

            this.$scope.viewAutoRatingParam = async (autoRatingParam: IAutoRatingParamModel): Promise<void> => {
                this.$scope.fetchData(autoRatingParam.ID, EOperation.VIEW);
            }

            this.$scope.viewLogAutoRatingParam = async (autoRatingParam: IAutoRatingParamModel): Promise<void> => {
                this.$scope.viewLog(autoRatingParam);
            }

            this.$scope.copyAutoRatingParam = async (autoRatingParam: IAutoRatingParamModel): Promise<void> => {
                this.$scope.fetchData(autoRatingParam.ID, EOperation.COPY);
            }

            this.$scope.getAccountListByName = async (search: string): Promise<void> => {
                await this.getAccountListByName(search);
            }

            this.$scope.getCommercialUnityListByName = async (search: string): Promise<void> => {
                await this.getCommercialUnityListByName(search);
            }

            this.$scope.getPhysicalPersonListByName = async (search: string): Promise<void> => {
                await this.getPhysicalPersonListByName(search);
            }

            this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
                this.fetchData(id, action);
            }

        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewAutoRatingParam(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editAutoRatingParam(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogAutoRatingParam(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.setCopy(true);grid.appScope.copyAutoRatingParam(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PRODUCT, null, "ID")}}</div>' };
            const colTypeCargo: IMonacoColumnDef = { name: "TYPE_CARGO.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TYPE_CARGO, null, "NAME")}}</div>' };
            const colOriginTransaction: IMonacoColumnDef = { name: "ORIGIN_TRANSACTION.NAME", displayName: "PRODUCT.TARIFF_TYPE", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ORIGIN_TRANSACTION, null, "NAME")}}</div>' };
            const colTariffNature: IMonacoColumnDef = { name: "TARIFF_NATURE.NAME", displayName: "PRODUCT.TARIFF_TRANSACTION", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TARIFF_NATURE, null, "NAME")}}</div>' };
            const colTransactionNature: IMonacoColumnDef = { name: "TRANSACTION_NATURE.NAME", displayName: "PRODUCT.TRANSACTION_TYPE", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TRANSACTION_NATURE, null, "NAME")}}</div>' };
            const colDirect: IMonacoColumnDef = { name: "DIRECT.NAME", displayName: "PRODUCT.WAY", width: 70, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.DIRECT, null, "NAME")}}</div>' };
            const colCommercialUnity: IMonacoColumnDef = { name: "COMMERCIAL_UNITY.NAME", displayName: "BASIC_DATA.COMMERCIAL_BRANCH", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.COMMERCIAL_UNITY, null, "NAME")}}</div>' };
            const colAccount: IMonacoColumnDef = { name: "ACCOUNTS", displayName: "BASIC_DATA.CLIENT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNTS, null, "NAME")}}</div>' };
            const colTypeProcess: IMonacoColumnDef = { name: "TYPE_PROCESS.NAME", displayName: "BASIC_DATA.FILE_TYPE", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TYPE_PROCESS, null, "NAME")}}</div>' };
            const colQualification: IMonacoColumnDef = { name: "QUALIFICATION.NAME", displayName: "GENERAL.QUALIFICATION", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.QUALIFICATION, null, "NAME")}}</div>' };
            const colTypeAccount: IMonacoColumnDef = { name: "TYPE_ACCOUNT.NAME", displayName: "REGISTRATION.ACCOUNT_TYPE", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TYPE_ACCOUNT, null, "NAME")}}</div>' };
            const colSeller: IMonacoColumnDef = { name: "SELLER.NAME", displayName: "OPERATIONAL.SELLER", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.SELLER, null, "NAME")}}</div>' };
            const colInitialRelativeVariation: IMonacoColumnDef = { name: "INITIAL_RELATIVE_VARIATION", displayName: "PRODUCT.VARIATION_PERCENT_START", width: 200 };
            const colFinalRelativeVariation: IMonacoColumnDef = { name: "FINAL_RELATIVE_VARIATION", displayName: "PRODUCT.VARIATION_PERCENT_END", width: 200 };
            const colInitialAbsoluteVariation: IMonacoColumnDef = { name: "INITIAL_ABSOLUTE_VARIATION", displayName: "PRODUCT.VARIATION_ABSOLUTE_START", width: 200 };
            const colFinalAbsoluteVariation: IMonacoColumnDef = { name: "FINAL_ABSOLUTE_VARIATION", displayName: "PRODUCT.VARIATION_ABSOLUTE_END", width: 200 };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'TYPE_CARGO':
                        columnDefs.push(colTypeCargo);
                        break;
                    case 'ORIGIN_TRANSACTION':
                        columnDefs.push(colOriginTransaction);
                        break;
                    case 'TARIFF_NATURE':
                        columnDefs.push(colTariffNature);
                        break;
                    case 'TRANSACTION_NATURE':
                        columnDefs.push(colTransactionNature);
                        break;
                    case 'DIRECT':
                        columnDefs.push(colDirect);
                        break;
                    case 'TYPE_PROCESS':
                        columnDefs.push(colTypeProcess);
                        break;
                    case 'COMMERCIAL_UNITY':
                        columnDefs.push(colCommercialUnity);
                        break;
                    case 'ACCOUNTS':
                        columnDefs.push(colAccount);
                        break;
                    case 'QUALIFICATION':
                        columnDefs.push(colQualification);
                        break;
                    case 'TYPE_ACCOUNT':
                        columnDefs.push(colTypeAccount);
                        break;
                    case 'SELLER':
                        columnDefs.push(colSeller);
                        break;
                    case 'INITIAL_RELATIVE_VARIATION':
                        columnDefs.push(colInitialRelativeVariation);
                        break;
                    case 'FINAL_RELATIVE_VARIATION':
                        columnDefs.push(colFinalRelativeVariation);
                        break;
                    case 'INITIAL_ABSOLUTE_VARIATION':
                        columnDefs.push(colInitialAbsoluteVariation);
                        break;
                    case 'FINAL_ABSOLUTE_VARIATION':
                        columnDefs.push(colFinalAbsoluteVariation);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        try {
            this.$scope.model = {
                _id: null,
                ID: null,
                PRODUCT: null,
                TYPE_CARGO: null,
                ORIGIN_TRANSACTION: null,
                TARIFF_NATURE: null,
                TRANSACTION_NATURE: null,
                DIRECT: null,
                TYPE_PROCESS: null,
                QUALIFICATION: null,
                TYPE_ACCOUNT: null,
                ACCOUNTS: null,
                COMMERCIAL_UNITY: null,
                SELLER: null,
                INITIAL_RELATIVE_VARIATION: null,
                FINAL_RELATIVE_VARIATION: null,
                INITIAL_ABSOLUTE_VARIATION: null,
                FINAL_ABSOLUTE_VARIATION: null,
                ACTIVE: true,
                CREATED_AT: null,
                CREATED_BY: null,
                UPDATED_AT: null,
                UPDATED_BY: null,
                SEARCH_FIELDS: null
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getCargoTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_cargo`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getDirectionList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/direction`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getPaymentNatureList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/payment_nature`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getProcessTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/process_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getQualificationList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/account_qualification`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getAccountTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/account_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getContractModalityList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/contract_modality`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTariffOriginList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/tariff_origin`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getAccountListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            const scopeAccounts = this.$scope.model.ACCOUNTS;
            if (search && search.length >= 3) {
                this.formService.block();
                const accounts = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/account/list/custom/`, { search: search }, 30000, false);
                if (accounts && accounts.length > 0) {
                    result = accounts.map(account => { return { ID: account.ID, NAME: account.NAME, CODE: account.LEGAL_PERSON.SHORT_NAME } });
                }
                if (scopeAccounts) {
                    result = result.filter(item => scopeAccounts.some(scopeAccount => scopeAccount.ID != item.ID))
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.accountList = result
            this.formService.unblock();
        }
    }


    private async getCommercialUnityListByName(search: string): Promise<void> {
        let commercialUnityList: SelectorModel[] = [];
        if (search && search.length >= 3) {
            const legalPersonList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.COMMERCIAL_UNITY], search: search });
            if (legalPersonList) commercialUnityList = legalPersonList.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.NAME, CODE: legalPerson.CODE } });
        }
        this.$scope.commercialUnityList = commercialUnityList;
    }

    private async getLegalPersonListByName(filter: ILegalPersonListCustomFilter): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.formService.block();
            const legalPersons = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/legalPerson/list/custom/offer`, filter, 30000, false);
            if (legalPersons) result = legalPersons.map(legalPerson => {
                return {
                    ID: legalPerson.ID,
                    NAME: legalPerson.SHORT_NAME,
                    CODE: legalPerson.CORPORATE_NAME,
                    ID_LEGAL_PERSON: legalPerson.ID_LEGAL_PERSON,
                    LEGAL_PERSON: legalPerson.LEGAL_PERSON
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getPhysicalPersonListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 3) {
                this.formService.block();
                const physicalPersons = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/physicalPerson/list/custom`, { search: search }, 30000, false);
                if (physicalPersons && physicalPersons.length > 0) result = physicalPersons.map(physicalPerson => { return { ID: physicalPerson.ID, NAME: physicalPerson.NAME } });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.physicalPersonList = result
            this.formService.unblock();
        }
    }



    initDependencies(): Promise<any> {
        try {
            const self: AutoRatingParamRegisterController = this;

            return new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getProductList(),
                    self.getCargoTypeList(),
                    self.getDirectionList(),
                    self.getPaymentNatureList(),
                    self.getProcessTypeList(),
                    self.getQualificationList(),
                    self.getAccountTypeList(),
                    self.getContractModalityList(),
                    self.getTariffOriginList(),
                ]).then((result: any) => {
                    self.$scope.productList = result[0];
                    self.$scope.cargoTypeList = result[1];
                    self.$scope.directionList = result[2];
                    self.$scope.transactionNatureList = result[3];
                    self.$scope.processTypeList = result[4];
                    self.$scope.qualificationList = result[5];
                    self.$scope.accountTypeList = result[6];
                    self.$scope.contractModalityList = result[7];
                    self.$scope.tariffOriginList = result[8];
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }

    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")}`;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")}`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === 'register' ? 'insert' : 'update';
        return {
            route: `/autoRatingParam/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };

    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: "PRODUCT",
                LABEL: "BASIC_DATA.PRODUCT"
            },
            {
                PROPERTY: "TYPE_CARGO",
                LABEL: "BASIC_DATA.CARGO_TYPE"
            },
            {
                PROPERTY: "ORIGIN_TRANSACTION",
                LABEL: "PRODUCT.TARIFF_TYPE"
            },
            {
                PROPERTY: "TARIFF_NATURE",
                LABEL: "PRODUCT.TARIFF_TRANSACTION"
            },
            {
                PROPERTY: "TRANSACTION_NATURE",
                LABEL: "PRODUCT.TRANSACTION_TYPE"
            },
            {
                PROPERTY: "DIRECT",
                LABEL: "ROUTE.DIRECT_SERVICE"
            },
            {
                PROPERTY: "TYPE_PROCESS",
                LABEL: "BASIC_DATA.FILE_TYPE"
            },
            {
                PROPERTY: "QUALIFICATION",
                LABEL: "GENERAL.QUALIFICATION"
            },
            {
                PROPERTY: "TYPE_ACCOUNT",
                LABEL: "REGISTRATION.ACCOUNT_TYPE"
            },
            {
                PROPERTY: "ACCOUNTS",
                LABEL: "BASIC_DATA.CLIENT"
            },
            {
                PROPERTY: "COMMERCIAL_UNITY",
                LABEL: "BASIC_DATA.COMMERCIAL_BRANCH"
            },
            {
                PROPERTY: "SELLER",
                LABEL: "OPERATIONAL.SELLER"
            },
            {
                PROPERTY: "INITIAL_RELATIVE_VARIATION",
                LABEL: "PRODUCT.VARIATION_PERCENT_START"
            },
            {
                PROPERTY: "FINAL_RELATIVE_VARIATION",
                LABEL: "PRODUCT.VARIATION_PERCENT_END"
            },
            {
                PROPERTY: "INITIAL_ABSOLUTE_VARIATION",
                LABEL: "PRODUCT.VARIATION_ABSOLUTE_START"
            },
            {
                PROPERTY: 'FINAL_ABSOLUTE_VARIATION',
                LABEL: "PRODUCT.VARIATION_ABSOLUTE_END"
            },
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE"
            },
            {
                PROPERTY: "CREATED_AT",
                LABEL: "GENERAL.CREATED_AT"
            },
            {
                PROPERTY: "CREATED_BY",
                LABEL: "GENERAL.CREATED_BY"
            },
            {
                PROPERTY: "UPDATED_AT",
                LABEL: "GENERAL.UPDATED_AT"
            },
            {
                PROPERTY: "UPDATED_BY",
                LABEL: "GENERAL.UPDATED_BY"
            }
        ];
        return props;
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/autoRatingParam/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }
}
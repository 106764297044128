import angular = require('angular');
import * as moment from 'moment';
import { IRestService } from "@services/RestService";
import { IModalService } from "@services/ModalService";
import { FormService2, IFormServiceScope } from '@services/FormService2';
import { OperationalService } from "@services/OperationalService";
import { ISessionService } from '@services/SessionService';
import { IMonacoRequest } from "@services/GridFormService";
import { ProductService } from "@services/ProductService";
import { Process, EQUIPMENT_LIST, CHARGE, OFFER, IMPORTER, EXPORTER, SALES_PERSON } from "@models/interface/operational/NewProcess";
import { ENTITY as ENTITY_PROCESS } from '@models/interface/operational/NewProcess';
import { OfferCompiled, ChargePair, SALES_HOLDER, ENTITY } from '@models/interface/product/OfferCompiled';
import { ECargoTypeId, EAccountTypeId, EProductId } from '@enums/GenericData';
import { IMonacoScope } from '../../common/MonacoInterface';
import { BrowserTitle } from '../../common/BrowserTitle';
import { SelectorModel } from '../../common/model/SelectorModel';
import { IProcessParameter } from '../../common/model/ModelParameter';
import { INewProcessExchangeData } from './NewProcessController';
import { HelperService } from "@services/HelperService";
import { ISelectorModel } from 'WBA-Model/dist/mongo/SelectorModel';

interface IEquipmentList {
    EQUIPMENT_LIST: EQUIPMENT_LIST[],
    QUANTITY: number,
    MODIFIED_RESPONSIBLE: ISelectorModel;
}

interface IProcessComparisonScope extends IFormServiceScope {
    customTemplate: string;
    model: any;
    quotation: OfferCompiled;
    quotationExternalSalesman: SALES_HOLDER | SALES_PERSON;
    process: Process;
    processOffer: OFFER;
    newChargesList: ChargePair[];
    currentChargesList: CHARGE[];
    chargesConcat: object;
    productInstructions: any;
    commercialInstructions: any;
    operationalInstructions: any;
    financialInstructions: any;
    customerInstructions: any;
    agentInstructions: any;
    houseInstructions: any;
    masterInstructions: any;
    measurementLabel: any;
    measurementNewValue: any;
    measurementCurrentValue: any;
    processCompared: any;
    done: boolean;
    invoices: any;
    equipmentList: IEquipmentList;
    sortingOrder: any;
    reverse: any;
    modifiedTypeList: SelectorModel[];
    editEquipment: Boolean;
    fileSpecsResponsible: SelectorModel;
    fileSpecsResponsibleList: SelectorModel[];
    compareDate: (from: Date, to: Date) => boolean;
    formatDate: (date: Date) => Date;
    decodeBoolean: (value: boolean) => string;
    save: () => any;
    cancel: () => any;
    goTo: (route, reload) => any;
    viewProcessNewTab: (processNumber) => any;
    getLabelAndValueToCargoList: (cargo) => any;
    adjustCargoItem: (index) => any;
    validateAmountItem: (index) => any;
    sortBy: (newSortingOrder, ident) => any;
    isCargoLclBreakBulkOrRoro: () => boolean;
    isImporterDifferent: (offerImporter: IMPORTER[], processImporter: IMPORTER) => boolean;
    isExporterDifferent: (offerExporter: EXPORTER[], processExporter: EXPORTER) => boolean;
}

export class ProcessComparisonController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IProcessComparisonScope;
    private $timeout: ng.ITimeoutService
    private $q: ng.IQService;
    private $state: ng.ui.IStateService;
    private formService: FormService2;
    private RestService: IRestService;
    private ModalService: IModalService;
    private operationalService: OperationalService;
    private ProductService: ProductService;
    private sessionService: ISessionService;
    private selectedRows: Array<any>;
    private cargoTypeOptions: any;
    private $rootScope: IMonacoScope;
    private $window: ng.IWindowService;
    private $filter: ng.FilterFactory;
    private operationTimeout: number;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IProcessComparisonScope) {

        //set $scope
        this.$scope = $scope;
        this.formService = new FormService2($injector, $scope);

        //get injections
        this.$rootScope = $injector.get('$rootScope');
        this.$window = $injector.get('$window');
        this.$timeout = $injector.get('$timeout');
        this.$q = $injector.get('$q');
        this.$state = $injector.get('$state');
        this.RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');
        this.operationalService = $injector.get('OperationalService');
        this.ProductService = $injector.get('ProductService');
        this.sessionService = $injector.get('SessionService');
        this.$filter = $injector.get('$filter');
        this.helperService = $injector.get('HelperService');
        this.selectedRows = new Array<any>();
        this.operationTimeout = 300000;
    }

    async $onInit() {
        try {
            this.formService.init('form', 'GENERAL.MENU.COMPARE', false);

            if (!this.$state.params.quotation || !this.$state.params.process) {
                return this.$state.go('app.operational.newProcess.list', {}, {
                    reload: false
                });
            }

            let breadCrumbLabel = this.formService.getTranslate('OPERATIONAL.COMPARE');
            this.$rootScope.setBreadCrumb(`${this.$state.params.process.PROCESS_NUMBER}`, () => {
                this.sessionService.openRoute("app.operational.newProcess.list", <IProcessParameter>{ PROCESS_NUMBER: this.$state.params.process.PROCESS_NUMBER }, <INewProcessExchangeData>{ OPERATION: "edit", PROCESS_NUMBER: this.$state.params.process.PROCESS_NUMBER });
            }, breadCrumbLabel, 'fa fa-search');

            this.$rootScope.hideBreadCrumb();

            BrowserTitle.$id = this.$state.params.process.PROCESS_NUMBER;
            this.$scope.quotation = this.$state.params.quotation;
            this.$scope.quotationExternalSalesman = this.$scope.quotation.ACCOUNT_TYPE && this.$scope.quotation.ACCOUNT_TYPE.ID == EAccountTypeId.CORPORATIVE_ACCOUNT ? this.$scope.quotation.SALES_HOLDER : this.$scope.quotation.SALES_PERSON;

            let rc = await this.getProcessUpdated(this.$state.params.process.PROCESS_NUMBER);
            const msgError = this.formService.getTranslate('OPERATIONAL.IT_IS_NOT_POSSIBLE_TO_LOAD_THE_FILE_DATA', { prop: this.$state.params.process.PROCESS_NUMBER });
            if (!rc || !rc.data || (rc.data && !rc.data.data)) return this.formService.handleLoadError(msgError);
            this.$scope.process = rc.data.data[0];
            this.$scope.processOffer = this.$scope.process && this.$scope.process.OFFER ? this.$scope.process.OFFER.find(offer => offer.ACTIVE) : null;

            // charges
            this.$scope.newChargesList = this.$scope.quotation.CHARGE;
            this.$scope.currentChargesList = this.$scope.process.CHARGE.filter(x => (x.INCOME.CHARGE_TYPE && x.INCOME.CHARGE_TYPE.ID == 'A' || x.OUTCOME.CHARGE_TYPE && x.OUTCOME.CHARGE_TYPE.ID == 'A'));

            // AIR
            if (this.$scope.process.CARGO_TYPE.ID == '1' && this.$scope.quotation.CARGO_TYPE.ID == '1' && this.$scope.process.CARGO_DETAIL.WEIGHT_RANGE_BASE.ID != this.$scope.quotation.CARGO.AIR.WEIGHT_RANGE_BASE.ID) {
                // Remove charges with other weight ranges
                for (let index = 0; index < this.$scope.newChargesList.length; index++) {
                    const charge = this.$scope.newChargesList[index];
                    if ((charge.OUTCOME && charge.OUTCOME.WEIGHT_RANGE && charge.OUTCOME.WEIGHT_RANGE.ID != this.$scope.process.CARGO_DETAIL.WEIGHT_RANGE_BASE.ID) || (charge.INCOME && charge.INCOME.WEIGHT_RANGE && charge.INCOME.WEIGHT_RANGE.ID != this.$scope.process.CARGO_DETAIL.WEIGHT_RANGE_BASE.ID)) {
                        this.$scope.newChargesList.splice(index, 1);
                        index--;
                    }
                }

                if (this.$scope.quotation.WEIGHT_RANGE_CHARGE && this.$scope.quotation.WEIGHT_RANGE_CHARGE.length) {
                    const weightRangeCharge = this.$scope.quotation.WEIGHT_RANGE_CHARGE.find(item => item.ID == this.$scope.process.CARGO_DETAIL.WEIGHT_RANGE_BASE.ID);

                    if (weightRangeCharge && weightRangeCharge.CHARGE && weightRangeCharge.CHARGE.length) this.$scope.newChargesList = this.$scope.newChargesList.concat(weightRangeCharge.CHARGE);
                }
            }

            // lets do some reorder
            this.$scope.newChargesList = this.$scope.newChargesList.sort((a, b) => (a.CHARGE.NAME > b.CHARGE.NAME) ? 1 : ((b.CHARGE.NAME > a.CHARGE.NAME) ? -1 : 0));
            this.$scope.currentChargesList = this.$scope.currentChargesList.sort((a, b) => (a.CHARGE.NAME > b.CHARGE.NAME) ? 1 : ((b.CHARGE.NAME > a.CHARGE.NAME) ? -1 : 0));

            const allChargesIncome = [];
            const allChargesOutcome = [];

            if (this.$scope.currentChargesList) {
                for (let currentCharge of this.$scope.currentChargesList) {
                    allChargesIncome.push({ typeCharge: "CURRENT", charge: currentCharge.CHARGE, data: currentCharge.INCOME, chargeOK: true });
                    allChargesOutcome.push({ typeCharge: "CURRENT", charge: currentCharge.CHARGE, data: currentCharge.OUTCOME, chargeOK: true });
                }
            }

            if (this.$scope.newChargesList) {
                for (let newCharge of this.$scope.newChargesList) {

                    const resultsIncome = this.$scope.currentChargesList.filter(charge => charge.INCOME.CHARGE && charge.INCOME.CHARGE.ID == newCharge.INCOME.CHARGE.ID);

                    let validIncomeUnitary = true;
                    let validIncomePeople = true;
                    let validIncomeValidityOf = true;
                    let validIncomeValidityUp = true;
                    let chargeok = true;

                    if (resultsIncome) {
                        for (let result of resultsIncome) {
                            if ((result.INCOME.EQUIPMENT && newCharge.INCOME.EQUIPMENT && result.INCOME.EQUIPMENT.ID == newCharge.INCOME.EQUIPMENT.ID) || !result.INCOME.EQUIPMENT && !newCharge.INCOME.EQUIPMENT) {
                                if (result.INCOME.UNITARY != newCharge.INCOME.UNITARY) validIncomeUnitary = false;
                                if (result.INCOME.PEOPLE && newCharge.INCOME.PEOPLE && result.INCOME.PEOPLE.ID != newCharge.INCOME.PEOPLE.ID) validIncomePeople = false;
                                if (result.INCOME.VALIDITY_OF && !moment(result.INCOME.VALIDITY_OF).isSame(moment(newCharge.INCOME.VALIDITY_OF))) validIncomeValidityOf = false;
                                if (result.INCOME.VALIDITY_UP && !moment(result.INCOME.VALIDITY_UP).isSame(moment(newCharge.INCOME.VALIDITY_UP))) validIncomeValidityUp = false;
                            }

                            chargeok = true;
                            if (!validIncomeUnitary || !validIncomePeople || !validIncomeValidityOf || !validIncomeValidityUp) {
                                chargeok = false;
                                if (newCharge.INCOME.CHARGE) {
                                    const findCharge = allChargesIncome.find(y => y.charge.ID == newCharge.INCOME.CHARGE.ID &&
                                        ((y.data.EQUIPMENT == null && newCharge.INCOME.EQUIPMENT == null)
                                            || (y.data.EQUIPMENT && newCharge.INCOME.EQUIPMENT && y.data.EQUIPMENT.ID == newCharge.INCOME.EQUIPMENT.ID)
                                        )
                                    )
                                    if (findCharge) findCharge.chargeOK = false;
                                }
                            }
                        }
                    }

                    allChargesIncome.push({
                        typeCharge: "NEW", charge: newCharge.CHARGE,
                        data: newCharge.INCOME,
                        valid: {
                            validIncomeUnitary: validIncomeUnitary,
                            validIncomePeople: validIncomePeople,
                            validIncomeValidityOf: validIncomeValidityOf,
                            validIncomeValidityUp: validIncomeValidityUp,
                        },
                        chargeOK: chargeok
                    });

                    const resultsOutcome = this.$scope.currentChargesList.filter(charge => charge.OUTCOME.CHARGE && charge.OUTCOME.CHARGE.ID == newCharge.OUTCOME.CHARGE.ID);
                    let validOutcomeUnitary = true;
                    let validOutcomePeople = true;
                    let validOutcomeValidityOf = true;
                    let validOutcomeValidityUp = true;
                    chargeok = true;

                    if (resultsOutcome) {
                        for (let result of resultsOutcome) {
                            if ((result.OUTCOME.EQUIPMENT && newCharge.OUTCOME.EQUIPMENT && result.OUTCOME.EQUIPMENT.ID == newCharge.OUTCOME.EQUIPMENT.ID) || !result.OUTCOME.EQUIPMENT && !newCharge.OUTCOME.EQUIPMENT) {
                                if (result.OUTCOME.UNITARY != newCharge.OUTCOME.UNITARY) validOutcomeUnitary = false;
                                if (result.OUTCOME.PEOPLE && newCharge.OUTCOME.PEOPLE && result.OUTCOME.PEOPLE.ID != newCharge.OUTCOME.PEOPLE.ID) validOutcomePeople = false;
                                if (result.OUTCOME.VALIDITY_OF && !moment(result.OUTCOME.VALIDITY_OF).isSame(moment(newCharge.OUTCOME.VALIDITY_OF))) validOutcomeValidityOf = false;
                                if (result.OUTCOME.VALIDITY_UP && !moment(result.OUTCOME.VALIDITY_UP).isSame(moment(newCharge.OUTCOME.VALIDITY_UP))) validOutcomeValidityUp = false;
                            }
                            chargeok = true;
                            if (!validOutcomeUnitary || !validOutcomePeople || !validOutcomeValidityOf || !validOutcomeValidityUp) {

                                chargeok = false;
                                if (newCharge.OUTCOME.CHARGE) {
                                    const findCharge = allChargesOutcome.find(y => y.charge.ID == newCharge.OUTCOME.CHARGE.ID &&
                                        ((y.data.EQUIPMENT == null && newCharge.OUTCOME.EQUIPMENT == null)
                                            || (y.data.EQUIPMENT && newCharge.OUTCOME.EQUIPMENT && y.data.EQUIPMENT.ID == newCharge.OUTCOME.EQUIPMENT.ID)
                                        )
                                    )
                                    if (findCharge) findCharge.chargeOK = false;
                                }
                            }
                        }
                    }

                    allChargesOutcome.push({
                        typeCharge: "NEW", charge: newCharge.CHARGE,
                        data: newCharge.OUTCOME,
                        valid: {
                            validOutcomeUnitary: validOutcomeUnitary,
                            validOutcomePeople: validOutcomePeople,
                            validOutcomeValidityOf: validOutcomeValidityOf,
                            validOutcomeValidityUp: validOutcomeValidityUp
                        },
                        chargeOK: chargeok
                    });
                }
            }

            const allChargesOutcomeOrder = allChargesOutcome.sort(function (a, b) {
                if (a.charge.NAME > b.charge.NAME) return 1;
                if (a.charge.NAME < b.charge.NAME) return -1;

                if (a.data && b.data && a.data.EQUIPMENT && b.data.EQUIPMENT) {
                    if (a.data.EQUIPMENT.NAME > b.data.EQUIPMENT.NAME) return 1;
                    if (a.data.EQUIPMENT.NAME < b.data.EQUIPMENT.NAME) return -1;
                }
            });

            const allChargesIncomeOrder = allChargesIncome.sort(function (a, b) {
                if (a.charge.NAME > b.charge.NAME) return 1;
                if (a.charge.NAME < b.charge.NAME) return -1;

                if (a.data && b.data && a.data.EQUIPMENT && b.data.EQUIPMENT) {
                    if (a.data.EQUIPMENT.NAME > b.data.EQUIPMENT.NAME) return 1;
                    if (a.data.EQUIPMENT.NAME < b.data.EQUIPMENT.NAME) return -1;
                }
            });

            this.$scope.chargesConcat = {
                'QUOTATION': this.$scope.newChargesList, 'PROCESS': this.$scope.currentChargesList,
                'OUTCOMECHARGES': allChargesOutcomeOrder, 'INCOMECHARGES': allChargesIncomeOrder
            };

            //this.$scope.quotation.CHARGE = this.$scope.newChargesList;
            this.$scope.decodeBoolean = (value) => {
                return this.decodeBoolean(value);
            }

            const isFcl = this.$scope.quotation.CARGO_TYPE && this.$scope.quotation.CARGO_TYPE.ID == ECargoTypeId.FCL;
            const isBbRoro = this.$scope.quotation.CARGO_TYPE && (this.$scope.quotation.CARGO_TYPE.ID == ECargoTypeId.BREAK_BULK || this.$scope.quotation.CARGO_TYPE.ID == ECargoTypeId.RO_RO);
            this.$scope.equipmentList = { EQUIPMENT_LIST: null, QUANTITY: null, MODIFIED_RESPONSIBLE: null };
            // FCL only
            if (isFcl || isBbRoro) {
                // current process cargo list must be checked
                if (this.$scope.process.CARGO_DETAIL && this.$scope.process.CARGO_DETAIL.EQUIPMENT_LIST) {
                    this.$scope.process.CARGO_DETAIL.EQUIPMENT_LIST.map(x => {
                        x['CHECKED'] = true;
                    });
                }
                this.$scope.equipmentList.EQUIPMENT_LIST = (this.$scope.process.CARGO_DETAIL && this.$scope.process.CARGO_DETAIL.EQUIPMENT_LIST) ? this.$scope.process.CARGO_DETAIL.EQUIPMENT_LIST : [];

                // map the current array to bring a new structure of cargo fcl list
                if (isFcl && this.$scope.quotation.CARGO && this.$scope.quotation.CARGO.FCL) {
                    for (let pro = 0; pro < this.$scope.quotation.CARGO.FCL.length; pro++) {
                        const found = this.$scope.equipmentList.EQUIPMENT_LIST.findIndex(x => {
                            return x.EQUIPMENT && x.EQUIPMENT.ID == this.$scope.quotation.CARGO.FCL[pro].EQUIPMENT.ID;
                        });

                        if (found > -1) {
                            this.$scope.equipmentList.EQUIPMENT_LIST[found].DFTM = this.$scope.quotation.CARGO.FCL[pro].DFTM;
                            this.$scope.equipmentList.EQUIPMENT_LIST[found].OFTM = this.$scope.quotation.CARGO.FCL[pro].OFTM;
                            this.$scope.equipmentList.EQUIPMENT_LIST[found].DFTH = this.$scope.quotation.CARGO.FCL[pro].DFTH;
                            this.$scope.equipmentList.EQUIPMENT_LIST[found].OFTH = this.$scope.quotation.CARGO.FCL[pro].OFTH;
                            this.$scope.equipmentList.EQUIPMENT_LIST[found]['CHECKED'] = true;
                        }
                        else {
                            this.$scope.equipmentList.EQUIPMENT_LIST.push({
                                DFTM: this.$scope.quotation.CARGO.FCL[pro].DFTM,
                                OFTM: this.$scope.quotation.CARGO.FCL[pro].OFTM,
                                DFTH: this.$scope.quotation.CARGO.FCL[pro].DFTH,
                                OFTH: this.$scope.quotation.CARGO.FCL[pro].OFTH,
                                EQUIPMENT: this.$scope.quotation.CARGO.FCL[pro].EQUIPMENT,
                                QUANTITY: this.$scope.quotation.CARGO.FCL[pro].AMOUNT,
                                MODIFIED_TYPE: null
                            });
                        }
                    }
                } else if (isBbRoro && this.$scope.quotation.CARGO && this.$scope.quotation.CARGO.BB_RORO) {
                    for (let pro = 0; pro < this.$scope.quotation.CARGO.BB_RORO.length; pro++) {
                        const found = this.$scope.equipmentList.EQUIPMENT_LIST.findIndex(x => {
                            return x.EQUIPMENT && x.EQUIPMENT.ID == this.$scope.quotation.CARGO.BB_RORO[pro].EQUIPMENT.ID;
                        });

                        if (found > -1) {
                            this.$scope.equipmentList.EQUIPMENT_LIST[found].DFTM = this.$scope.quotation.CARGO.BB_RORO[pro].DFTM;
                            this.$scope.equipmentList.EQUIPMENT_LIST[found].OFTM = this.$scope.quotation.CARGO.BB_RORO[pro].OFTM;
                            this.$scope.equipmentList.EQUIPMENT_LIST[found].DFTH = this.$scope.quotation.CARGO.BB_RORO[pro].DFTH;
                            this.$scope.equipmentList.EQUIPMENT_LIST[found].OFTH = this.$scope.quotation.CARGO.BB_RORO[pro].OFTH;
                            this.$scope.equipmentList.EQUIPMENT_LIST[found]['CHECKED'] = true;
                        }
                        else {
                            this.$scope.equipmentList.EQUIPMENT_LIST.push({
                                DFTM: this.$scope.quotation.CARGO.BB_RORO[pro].DFTM,
                                OFTM: this.$scope.quotation.CARGO.BB_RORO[pro].OFTM,
                                DFTH: this.$scope.quotation.CARGO.BB_RORO[pro].DFTH,
                                OFTH: this.$scope.quotation.CARGO.BB_RORO[pro].OFTH,
                                EQUIPMENT: this.$scope.quotation.CARGO.BB_RORO[pro].EQUIPMENT,
                                QUANTITY: this.$scope.quotation.CARGO.BB_RORO[pro].AMOUNT,
                                MODIFIED_TYPE: null
                            });
                        }
                    }
                }
            }

            this.$scope.modifiedTypeList = await this.getGenericByType('modified_type');
            this.$scope.fileSpecsResponsibleList = await this.getGenericByType('file_specs');
            this.$scope.editEquipment = false;

            const externalContactsOffer = [];
            //change EMAIL -> MAIL
            if (this.$scope.quotation.CONTACT && this.$scope.quotation.CONTACT.EXTERNAL) {
                for (let contact of this.$scope.quotation.CONTACT.EXTERNAL) {
                    const newContact = {
                        ID: contact.ID,
                        NAME: contact.NAME,
                        MAIL: contact.EMAIL,
                        GROUP: contact.GROUP,
                        TYPE: contact.TYPE,
                        COMPANY: contact.COMPANY,
                        DATA_ORIGIN_TYPE: { ID: "A", NAME: "Automático" }
                    }
                    externalContactsOffer.push(newContact);
                }

                this.$scope.quotation.CONTACT.EXTERNAL = externalContactsOffer;
            }

            const importer = (this.isEntityDifferent(this.$scope.quotation.IMPORTER, this.$scope.process.IMPORTER)) ? null : this.$scope.process.IMPORTER;
            const exporter = (this.isEntityDifferent(this.$scope.quotation.EXPORTER, this.$scope.process.EXPORTER)) ? null : this.$scope.process.EXPORTER;
            const notify = (this.isEntityDifferent(this.$scope.quotation.NOTIFY, this.$scope.process.NOTIFY)) ? null : this.$scope.process.NOTIFY;

            // search contacts - from offer
            if (this.$scope.quotation.CUSTOMER) await this.getExternalContact(this.$scope.quotation.CUSTOMER);
            if (this.$scope.quotation.IMPORTER) await this.getExternalContact(importer);
            if (this.$scope.quotation.EXPORTER) await this.getExternalContact(exporter);
            if (this.$scope.quotation.NOTIFY) await this.getExternalContact(notify);
            if (this.$scope.quotation.SERVICE_PROVIDER) await this.getExternalContact(this.$scope.quotation.SERVICE_PROVIDER);
            if (this.$scope.quotation.LOCAL_AGENT) await this.getExternalContact(this.$scope.quotation.LOCAL_AGENT);
            if (this.$scope.quotation.EXTERNAL_AGENT) await this.getExternalContact(this.$scope.quotation.EXTERNAL_AGENT);
            if (this.$scope.quotation.THIRD_AGENT) await this.getExternalContact(this.$scope.quotation.THIRD_AGENT);
            if (this.$scope.quotation.TRANSPORTER_PICKUP) await this.getExternalContact(this.$scope.quotation.TRANSPORTER_PICKUP);
            if (this.$scope.quotation.TRANSPORTER_PLACE_RECEPT) await this.getExternalContact(this.$scope.quotation.TRANSPORTER_PLACE_RECEPT);
            if (this.$scope.quotation.TRANSPORTER_FINAL_DESTINATION) await this.getExternalContact(this.$scope.quotation.TRANSPORTER_FINAL_DESTINATION);
            if (this.$scope.quotation.TRANSPORTER_DELIVERY) await this.getExternalContact(this.$scope.quotation.TRANSPORTER_DELIVERY);
            if (this.$scope.quotation.TRANSPORTER_GATE_OUT_EMPTY) await this.getExternalContact(this.$scope.quotation.TRANSPORTER_GATE_OUT_EMPTY);
            if (this.$scope.quotation.REPRESENTATIVES && this.$scope.process.REPRESENTATIVES) {
                if (this.$scope.quotation.REPRESENTATIVES.REPRESENTATIVE_DELIVERY && this.$scope.quotation.REPRESENTATIVES.REPRESENTATIVE_DELIVERY.REPRESENTATIVE) {
                    await this.getExternalContact(this.$scope.quotation.REPRESENTATIVES.REPRESENTATIVE_DELIVERY.REPRESENTATIVE);
                } else if (this.$scope.process.REPRESENTATIVES.REPRESENTATIVE_DELIVERY && this.$scope.process.REPRESENTATIVES.REPRESENTATIVE_DELIVERY.REPRESENTATIVE) {
                    await this.getExternalContact(this.$scope.process.REPRESENTATIVES.REPRESENTATIVE_DELIVERY.REPRESENTATIVE);
                }
                if (this.$scope.quotation.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE && this.$scope.quotation.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE.REPRESENTATIVE) {
                    await this.getExternalContact(this.$scope.quotation.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE.REPRESENTATIVE);
                } else if (this.$scope.process.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE && this.$scope.process.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE.REPRESENTATIVE) {
                    await this.getExternalContact(this.$scope.process.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE.REPRESENTATIVE);
                }
                if (this.$scope.quotation.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION && this.$scope.quotation.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION.REPRESENTATIVE) {
                    await this.getExternalContact(this.$scope.quotation.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION.REPRESENTATIVE);
                } else if (this.$scope.process.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION && this.$scope.process.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION.REPRESENTATIVE) {

                    await this.getExternalContact(this.$scope.process.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION.REPRESENTATIVE);
                }

                if (this.$scope.quotation.REPRESENTATIVES.REPRESENTATIVE_LOAD && this.$scope.quotation.REPRESENTATIVES.REPRESENTATIVE_LOAD.REPRESENTATIVE) {
                    await this.getExternalContact(this.$scope.quotation.REPRESENTATIVES.REPRESENTATIVE_LOAD.REPRESENTATIVE);
                } else if (this.$scope.process.REPRESENTATIVES.REPRESENTATIVE_LOAD && this.$scope.process.REPRESENTATIVES.REPRESENTATIVE_LOAD.REPRESENTATIVE) {

                    await this.getExternalContact(this.$scope.process.REPRESENTATIVES.REPRESENTATIVE_LOAD.REPRESENTATIVE);
                }

                if (this.$scope.quotation.REPRESENTATIVES.REPRESENTATIVE_PICK_UP && this.$scope.quotation.REPRESENTATIVES.REPRESENTATIVE_PICK_UP.REPRESENTATIVE) {
                    await this.getExternalContact(this.$scope.quotation.REPRESENTATIVES.REPRESENTATIVE_PICK_UP.REPRESENTATIVE);
                } else if (this.$scope.process.REPRESENTATIVES.REPRESENTATIVE_PICK_UP && this.$scope.process.REPRESENTATIVES.REPRESENTATIVE_PICK_UP.REPRESENTATIVE) {

                    await this.getExternalContact(this.$scope.process.REPRESENTATIVES.REPRESENTATIVE_PICK_UP.REPRESENTATIVE);
                }

                if (this.$scope.quotation.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT && this.$scope.quotation.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT.REPRESENTATIVE) {
                    await this.getExternalContact(this.$scope.quotation.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT.REPRESENTATIVE);
                } else if (this.$scope.process.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT && this.$scope.process.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT.REPRESENTATIVE) {

                    await this.getExternalContact(this.$scope.process.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT.REPRESENTATIVE);
                }
            }
            if (this.$scope.quotation.CARGO.IS_INSURANCE) await this.getExternalContact(this.$scope.quotation.CARGO.SECURE.INSURANCE_BROKER);
            if (this.$scope.quotation.FLEXI_FITTING) await this.getExternalContact(this.$scope.quotation.FLEXI_FITTING);
            if (this.$scope.quotation.ISOTANK_PROVIDER) await this.getExternalContact(this.$scope.quotation.ISOTANK_PROVIDER);
            // search contacts - broker
            const broker = (this.$scope.process.BROKER) ? this.$scope.process.BROKER : this.$scope.quotation.BROKER;
            if (broker) await this.getExternalContact(broker);

            this.initScopeFunctions();
            this.formService.unblock();

        } catch (ex) {
            const msgError = this.formService.getTranslate('OPERATIONAL.ERROR_WHILE_LOADING_THE_COMPARISON_WIZARD');
            this.formService.handleLoadError(ex, msgError);
        }
    }

    initScopeFunctions(): void {
        this.$scope.save = () => {
            this.save();
        }

        // override cancel function
        this.$scope.cancel = () => {
            this.cancel();
        }

        this.$scope.goTo = (route, reload) => {
            this.goTo(route, reload);
        }

        this.$scope.viewProcessNewTab = (processNumber) => {
            return this.viewProcessNewTab(processNumber);
        }

        //TODO: please, do not remove
        this.$scope.getLabelAndValueToCargoList = (cargo) => {
            return this.getLabelAndValueToCargoList(cargo);
        }

        this.$scope.adjustCargoItem = (index) => {
            return this.adjustCargoItem(index);
        }

        this.$scope.validateAmountItem = (index) => {
            return this.validateAmountItem(index);
        }

        this.$scope.sortBy = (newSortingOrder, ident) => {
            return this.sortBy(newSortingOrder, ident);
        }

        this.$scope.isCargoLclBreakBulkOrRoro = () => {
            return this.$scope.process.CARGO_TYPE && (this.$scope.process.CARGO_TYPE.ID == ECargoTypeId.LCL || this.$scope.process.CARGO_TYPE.ID == ECargoTypeId.BREAK_BULK || this.$scope.process.CARGO_TYPE.ID == ECargoTypeId.RO_RO);
        }

        this.$scope.isImporterDifferent = (offerImporter: IMPORTER[], processImporter: IMPORTER) => {
            return (offerImporter && processImporter && offerImporter.some(importer => importer.ID == processImporter.ID)) || (!offerImporter && !processImporter) ? false : true;
        }

        this.$scope.isExporterDifferent = (offerExporter: EXPORTER[], processExporter: EXPORTER) => {
            return (offerExporter && processExporter && offerExporter.some(exporter => exporter.ID == processExporter.ID)) || (!offerExporter && !processExporter) ? false : true;
        }

        this.$scope.compareDate = (from: Date, to: Date) => {
            const compareDateFrom = moment(from, 'YYYY-MM-DD').utc();
            const compareDateTo = moment(to, 'YYYY-MM-DD').utc();
            return !compareDateFrom.isSame(compareDateTo);
        }

        this.$scope.formatDate = (date: Date) => {
            return moment(date, 'YYYY-MM-DD').utc().toDate();
        }
    }

    $onDestroy(): void {
        BrowserTitle.$id = null;
        this.$rootScope.clearBreadCrumb();
    }

    private adjustCargoItem(index) {
        let checkBox = $(`#checkEqp${index}`);
        this.$scope.editEquipment = true;

        if (checkBox) {
            if (!checkBox[0]['checked']) {
                this.$scope.equipmentList.EQUIPMENT_LIST[index].QUANTITY = 0;
                this.$scope.equipmentList.EQUIPMENT_LIST[index]['CHECKED'] = false;
                $("[name='amount" + index + "']").attr("disabled", "disabled");
            } else {
                this.$scope.equipmentList.EQUIPMENT_LIST[index].QUANTITY = 1;
                this.$scope.equipmentList.EQUIPMENT_LIST[index]['CHECKED'] = true;
                $("[name='amount" + index + "']").removeAttr("disabled");
            }
        }
        this.$scope.$applyAsync();
    }

    private validateAmountItem(index) {
        let checkBox = $(`#checkEqp${index}`);
        this.$scope.editEquipment = true;

        if (this.$scope.equipmentList.EQUIPMENT_LIST[index].QUANTITY == 0) {
            this.$scope.equipmentList.EQUIPMENT_LIST[index]['CHECKED'] = false;
            checkBox[0]['checked'] = false;
            $("[name='amount" + index + "']").attr("disabled", "disabled");
        }

        this.$scope.$applyAsync();
    }

    private async getGenericByType(identifier: string) {
        let content = null
        const result = await this.getGenericById(identifier)
        if (result) content = result;

        return content;
    }

    private async getGenericById(identifier: string) {
        const { data: generic } = await this.helperService.get(`/generic/value/${identifier}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private getProcessUpdated(processNumber) {
        const timeout = 120000;
        return this.operationalService.get(`/process/byNumber/${processNumber}/${timeout}`, null, timeout);
    }

    private decodeBoolean(value: boolean) {
        if (value) {
            return 'Sim';
        } else {
            return 'Não';
        }
    };

    //TODO: please, do not remove
    private getLabelAndValueToCargoList(cargo) {
        let result = {};
        if (!cargo) return this.formService.handleLoadError("Ops, lista de cargas não informada!");

        if (cargo.AMOUNT || cargo.AMOUNT == 0) {
            result = {
                'LABEL': 'GENERAL.QUANTITY',
                'VALUE': cargo.AMOUNT + ((cargo.EQUIPMENT != null) ? ' x ' + cargo.EQUIPMENT.NAME : null)
            };
        } else if (cargo.CHARGEABLE_WEIGHT) {
            result = {
                'LABEL': 'GENERAL.CHARGEABLE_WEIGHT',
                'VALUE': cargo.CHARGEABLE_WEIGHT
            };
        } else if (cargo.CUBIC_WEIGHT) {
            result = {
                'LABEL': 'GENERAL.CUBIC_WEIGHT',
                'VALUE': cargo.CUBIC_WEIGHT
            };
        } else if (cargo.GROSS_WEIGHT) {
            result = {
                'LABEL': 'GENERAL.TOTAL_GROSS_WEIGHT_KG',
                'VALUE': cargo.GROSS_WEIGHT
            };
        } else if (cargo.MEASUREMENT) {
            result = {
                'LABEL': 'GENERAL.MEASURE',
                'VALUE': cargo.MEASUREMENT
            };
        } else if (cargo.NET_WEIGHT) {
            result = {
                'LABEL': 'GENERAL.NET_WEIGHT_KG',
                'VALUE': cargo.NET_WEIGHT
            };
        } else if (cargo.VOLUMES) {
            result = {
                'LABEL': 'GENERAL.UNIT_VOLUME',
                'VALUE': cargo.VOLUMES
            };
        } else if (cargo.VOLUMETRIC_WEIGHT) {
            result = {
                'LABEL': 'GENERAL.VOLUMETRIC_WEIGHT',
                'VALUE': cargo.VOLUMETRIC_WEIGHT
            };
        } else if (cargo.WM) {
            result = {
                'LABEL': 'GENERAL.WM_WEIGHT',
                'VALUE': cargo.WM
            };
        } else {
            result = {
                'LABEL': 'GENERAL.UNINFORMED',
                'VALUE': 0
            };
        }

        return result;
    }

    private viewProcessNewTab(processNumber) {
        this.sessionService.openTab("app.operational.newProcess.list", <IProcessParameter>{ PROCESS_NUMBER: processNumber }, <INewProcessExchangeData>{ OPERATION: "edit", PROCESS_NUMBER: processNumber });
    };

    private goTo(route, reload) {
        this.$state.go(route, { reload: reload });
    };

    private save = async () => {
        try {
            let isValid = this.formService.checkPendingFields("processComparisonForm");
            if (!isValid) return;
            const confirmed = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate('OPERATIONAL.CONFIRM_THE_FILE_UPDATE', { prop: this.$scope.process.PROCESS_NUMBER })
            });

            if (!confirmed) return;

            this.formService.block();

            // update cargo list
            if (this.$scope.equipmentList.EQUIPMENT_LIST) {
                const tempequipmentList = angular.copy(this.$scope.equipmentList.EQUIPMENT_LIST);
                const selectedequipmentList = tempequipmentList.filter(x => x['CHECKED'] && x.QUANTITY > 0);
                selectedequipmentList.forEach(x => { delete x['CHECKED'] });

                /*
                if (selectedequipmentList.EQUIPMENT_LIST.length > 0) {
                    const totalObject = [];
                    for (let i = 0; i < selectedequipmentList.EQUIPMENT_LIST.length; i++) {
                        let currentEquipment = selectedequipmentList.EQUIPMENT_LIST[i];
                        totalObject.push({
                            "ID": (currentEquipment.EQUIPMENT) ? currentEquipment.EQUIPMENT.ID : null,
                            "NAME": (currentEquipment.EQUIPMENT) ? currentEquipment.EQUIPMENT.NAME : null,
                            "AMOUNT": currentEquipment.QUANTITY,
                            //"TYPE": currentEquipment.TYPE
                        })
                    }
                    this.$scope.process.CARGO.TOTAL.EQUIPMENT_TOTAL = totalObject;
                    this.$scope.process.CARGO_DETAIL.EQUIPMENT_LIST = selectedequipmentList.EQUIPMENT_LIST;
                }
                */
            }
            this.$scope.equipmentList.QUANTITY = this.$scope.process.CARGO_DETAIL.QUANTITY;

            let newOfferTariffFiles = null;
            let tariffFilesResult = null;

            if (this.$scope.processOffer && this.$scope.processOffer.OFFER_ID) {
                const offerTariffFiles = {
                    route: `/offer/list/tariff/files/${this.$scope.processOffer.OFFER_ID}`,
                    timeout: 10000
                };

                tariffFilesResult = await this.ProductService.get<any>(offerTariffFiles);
            }

            if (tariffFilesResult && tariffFilesResult.data && tariffFilesResult.data.data) newOfferTariffFiles = tariffFilesResult.data.data;
            const request = {
                data: {
                    offerCode: this.$state.params.offerCode,
                    processNumber: this.$scope.process.PROCESS_NUMBER,
                    cargoInfo: this.$scope.equipmentList,
                    externalContacts: (this.$scope.quotation.CONTACT && this.$scope.quotation.CONTACT.EXTERNAL) ? this.$scope.quotation.CONTACT.EXTERNAL : null,
                    loadRef: this.$state.params.loadRef,
                    newOfferTariffFiles
                },
                timeout: this.operationTimeout
            };

            const result = await this.operationalService.post('/process/compare', request, this.operationTimeout);
            if (result.status === 200 && result.data && result.data.data) {
                this.$scope.processCompared = result.data.data.current;
                this.$scope.done = true;
            } else {
                const msg = this.formService.getTranslate('OPERATIONAL.FAILED_TO_UPDATE_THE_FILE');
                this.formService.notifyError(msg);
            }

            this.formService.unblock();

            if (this.$scope.processCompared) {
                if (this.$scope.processCompared.TTIME_DIFFERENCE != 0) {
                    const msgWarning = this.formService.getTranslate('OPERATIONAL.TRANSIT_TIME_DIFFERENCE_BETWEEN_FILE_AND_OFFER', { prop: this.$scope.processCompared.TTIME_DIFFERENCE });
                    this.formService.notifyWarning(msgWarning);
                }
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private cancel = () => {
        if (this.$state.params.origin && this.$state.params.origin == 'PROCESSVIEW') {
            this.sessionService.openRoute("app.operational.newProcess.list", <IProcessParameter>{ PROCESS_NUMBER: this.$state.params.process.PROCESS_NUMBER }, <INewProcessExchangeData>{ OPERATION: "edit", PROCESS_NUMBER: this.$state.params.process.PROCESS_NUMBER });
        } else {
            this.sessionService.openRoute("app.operational.newProcess.list");
        }
    };

    private sortBy = function (newSortingOrder, ident) {
        if (this.$scope.sortingOrder[ident] == newSortingOrder)
            this.$scope.reverse[ident] = !this.$scope.reverse[ident];
        this.$scope.sortingOrder[ident] = newSortingOrder;
    }

    private isEntityDifferent(offerEntity: ENTITY[], processEntity: ENTITY_PROCESS) {
        return (offerEntity && processEntity && offerEntity.some(exporter => exporter.ID == processEntity.ID)) || (!offerEntity && !processEntity) ? false : true;
    }

    private async getExternalContact(involved: ENTITY | ENTITY_PROCESS) {
        try {
            if (!involved) return;
            if (!involved.ID_LEGAL_PERSON) return;

            this.formService.block();
            let commodities = [];
            if (this.$scope.quotation.COMMODITY_SUMMARY) {
                for (const commodityItem of this.$scope.quotation.COMMODITY_SUMMARY.COMMODITY_ITEM) {
                    if (commodityItem.COMMODITY && commodityItem.COMMODITY.ID) commodities.push(commodityItem.COMMODITY.ID);
                };
            }

            const importer = (this.isEntityDifferent(this.$scope.quotation.IMPORTER, this.$scope.process.IMPORTER)) ? null : this.$scope.process.IMPORTER;
            const exporter = (this.isEntityDifferent(this.$scope.quotation.EXPORTER, this.$scope.process.EXPORTER)) ? null : this.$scope.process.EXPORTER;

            let result = [];
            const productReq: IMonacoRequest = {
                route: `/contact/list/operational`,
                data: {
                    filters: {
                        EXTERNAL_BROKER: this.$scope.process.EXTERNAL_BROKER && this.$scope.process.EXTERNAL_BROKER ? [this.$scope.process.EXTERNAL_BROKER.ID] : null,
                        LEGAL_PERSON: parseInt(involved.ID_LEGAL_PERSON),
                        PRODUCTS: [this.$scope.quotation.PRODUCT.ID],
                        COMMODITIES: commodities,
                        ACCOUNTS: this.$scope.quotation.CUSTOMER ? [this.$scope.quotation.CUSTOMER.ID] : null,
                        EXPORTERS: exporter ? [exporter.ID] : null,
                        IMPORTERS: importer ? [importer.ID] : null,
                        OUTER_CELLS: null,
                        ORIGIN_MARITIME: (this.$scope.quotation.POLAOL && this.isMaritimeService()) ? [this.$scope.quotation.POLAOL.ID] : null,
                        DESTINATION_MARITIME: (this.$scope.quotation.PODAOD && this.isMaritimeService()) ? [this.$scope.quotation.PODAOD.ID] : null,
                        ORIGIN_AIR: (this.$scope.quotation.POLAOL && this.isAirService()) ? [this.$scope.quotation.POLAOL.ID] : null,
                        DESTINATION_AIR: (this.$scope.quotation.PODAOD && this.isAirService()) ? [this.$scope.quotation.PODAOD.ID] : null,
                        ORIGIN_ROAD: null,
                        DESTINATION_ROAD: null,
                        TRADE_ORIGIN_AIR: (this.$scope.quotation.POLAOL && this.$scope.quotation.POLAOL.TRADE_GROUP && this.isAirService()) ? [this.$scope.quotation.POLAOL.TRADE_GROUP.ID] : null,
                        TRADE_DESTINATION_AIR: (this.$scope.quotation.PODAOD && this.$scope.quotation.PODAOD.TRADE_GROUP && this.isAirService()) ? [this.$scope.quotation.PODAOD.TRADE_GROUP.ID] : null,
                        TRADE_ORIGIN_MARITIME: (this.$scope.quotation.POLAOL && this.$scope.quotation.POLAOL.TRADE_GROUP && this.isMaritimeService()) ? [this.$scope.quotation.POLAOL.TRADE_GROUP.ID] : null,
                        TRADE_DESTINATION_MARITIME: (this.$scope.quotation.PODAOD && this.$scope.quotation.PODAOD.TRADE_GROUP && this.isMaritimeService()) ? [this.$scope.quotation.PODAOD.TRADE_GROUP.ID] : null,
                        TRADE_ORIGIN_ROAD: null,
                        TRADE_DESTINATION_ROAD: null,
                        TRADE_LANE_ORIGIN_AIR: (this.$scope.quotation.POLAOL && this.$scope.quotation.POLAOL.TRADE_LANE && this.isAirService()) ? [this.$scope.quotation.POLAOL.TRADE_LANE.ID] : null,
                        TRADE_LANE_DESTINATION_AIR: (this.$scope.quotation.PODAOD && this.$scope.quotation.PODAOD.TRADE_LANE && this.isAirService()) ? [this.$scope.quotation.PODAOD.TRADE_LANE.ID] : null,
                        TRADE_LANE_ORIGIN_MARITIME: (this.$scope.quotation.POLAOL && this.$scope.quotation.POLAOL.TRADE_LANE && this.isMaritimeService()) ? [this.$scope.quotation.POLAOL.TRADE_LANE.ID] : null,
                        TRADE_LANE_DESTINATION_MARITIME: (this.$scope.quotation.PODAOD && this.$scope.quotation.PODAOD.TRADE_LANE && this.isMaritimeService()) ? [this.$scope.quotation.PODAOD.TRADE_LANE.ID] : null,
                        TRADE_LANE_ORIGIN_ROAD: null,
                        TRADE_LANE_DESTINATION_ROAD: null,
                        COUNTRY_ORIGIN_AIR: (this.$scope.quotation.POLAOL && this.$scope.quotation.POLAOL.COUNTRY && this.isAirService()) ? [this.$scope.quotation.POLAOL.COUNTRY.ID] : null,
                        COUNTRY_DESTINATION_AIR: (this.$scope.quotation.PODAOD && this.$scope.quotation.PODAOD.COUNTRY && this.isAirService()) ? [this.$scope.quotation.PODAOD.COUNTRY.ID] : null,
                        COUNTRY_ORIGIN_MARITIME: (this.$scope.quotation.POLAOL && this.$scope.quotation.POLAOL.COUNTRY && this.isMaritimeService()) ? [this.$scope.quotation.POLAOL.COUNTRY.ID] : null,
                        COUNTRY_DESTINATION_MARITIME: (this.$scope.quotation.PODAOD && this.$scope.quotation.PODAOD.COUNTRY && this.isMaritimeService()) ? [this.$scope.quotation.PODAOD.COUNTRY.ID] : null,
                        COUNTRY_ORIGIN_ROAD: null,
                        COUNTRY_DESTINATION_ROAD: null
                    },
                }
            }
            const contacts = await this.ProductService.post<any>(productReq);
            if (contacts && contacts.data) {
                for (const contact of contacts.data.data) {
                    if (this.$scope.process.CONTACT.EXTERNAL.some(item => item.ID == contact.ID)) {
                        const externalContact = {
                            ID: contact.ID ? contact.ID : null,
                            NAME: contact.NAME ? contact.NAME : null,
                            MAIL: contact.EMAIL ? contact.EMAIL : null,
                            GROUP: contact.MESSAGE_GROUP ? contact.MESSAGE_GROUP : null,
                            TYPE: contact.TYPE ? contact.TYPE : null,
                            COMPANY: contact.COMPANY ? contact.COMPANY : null,
                            DATA_ORIGIN_TYPE: { ID: "A", NAME: "Automático" }
                        };

                        result.push(externalContact);
                    }
                }
            }
            for (const contact of result) {
                if (!this.$scope.quotation.CONTACT) this.$scope.quotation.CONTACT = { INTERNAL: [], EXTERNAL: [] }
                let indexId = -1;
                if (this.$scope.quotation.CONTACT.EXTERNAL && this.$scope.quotation.CONTACT.EXTERNAL.length > 0) indexId = this.$scope.quotation.CONTACT.EXTERNAL.findIndex(item => item.ID == contact.ID);
                if (!(indexId >= 0)) this.$scope.quotation.CONTACT.EXTERNAL.push(contact);
            }

            this.formService.unblock();

            return;

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private isAirService() {
        return this.$scope.quotation.PRODUCT.ID == EProductId.AIR_IMPORT || this.$scope.quotation.PRODUCT.ID == EProductId.AIR_EXPORT
    }

    private isMaritimeService() {
        return this.$scope.quotation.PRODUCT.ID == EProductId.MARITIME_IMPORT || this.$scope.quotation.PRODUCT.ID == EProductId.MARITIME_EXPORT
    }
}

import angular = require('angular');
import { IModalInstanceService } from 'angular-ui-bootstrap';
import * as intlTelInput from 'intl-tel-input';
import { IRestService } from "@services/RestService";
import { FormService2 } from '@services/FormService2';
import { IModalService } from '@services/ModalService';
import { ISessionService } from '@services/SessionService';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { EOfferLogsName } from '@enums/Offer';
import { IOfferCommunicationModel } from '../model/OfferModel';
import { IOfferScope, ECollapseState } from './OfferRegisterController';
import { SelectorModel } from "../../common/model/SelectorModel";
import { ValidateUtil } from "../../common/util/ValidateUtil";
import { ILinkParameter } from "../../common/model/ModelParameter";
import { ILegalPerson } from "../../registration/model/LegalPersonModel";
import { INetworkModel } from '../../registration/model/NetworkModel';

interface IContact {
    ID: number,
    ID_OFFER: number,
    LEGAL_PERSON: ILegalPerson,
    NETWORK: INetworkModel,
    NAME: string,
    PERSON_ROLE: SelectorModel,
    SECTOR: SelectorModel,
    EMAIL: string,
    PHONE_COUNTRY_CODE: string,
    PHONE: string,
    MESSAGE_GROUP: SelectorModel[],
    DATA_ORIGIN_TYPE: SelectorModel,
    UUID: string
}

interface IContactModal extends ng.IScope {
    contact: IContact;
    oldContact: IContact;
    operation: string;
    legalPersonList: ILegalPerson[];
    personRoleList: SelectorModel[];
    sectorList: SelectorModel[];
    messageGroupList: SelectorModel[];
    email: string;
    isLegalPerson: boolean;
    applyContact: () => void;
    closeContactModal: () => Promise<void>;
    getLegalPersonByNameOrInitials: (search: string) => Promise<void>;
    getPersonRoleByName: (search: string) => Promise<void>;
    getSectorByName: (search: string) => Promise<void>;
    getMessageGroup: () => Promise<void>;
    initPhoneComponent: () => void;
    destroyPhoneComponent: () => void;
    goToSector: (id: number) => void;
    goToPersonRole: (id: number) => void;
    goToLegalPerson: (id: number) => void;
    goToNetwork: (id: number) => void;
}

interface ICommunicationInstruction {
    PRODUCT: string;
    COMMERCIAL: string;
    OPERATIONAL: string;
    FINANCIAL: string;
    CUSTOMER: string;
    AGENT: string;
    PROCESS_HOUSE: string;
    PROCESS_MASTER: string;
}

interface ICommunicationInstructionModal extends ng.IScope {
    instructions: ICommunicationInstruction;
    operation: string;
    oldInstructions: ICommunicationInstruction;
    applyCommunicationInstructions: () => void;
    closeCommunicationInstructions: () => Promise<void>;
}

interface IOfferWizardCommunicationScope extends ng.IScope {
    log: IViewLog;
    model: IOfferCommunicationModel;
    oldModel: IOfferCommunicationModel;
    customLogProperties: ICustomLogProperties[];
    viewLog: (uuid?: string) => void;
    viewInstructionsLog: () => void;
    addContact: () => void;
    removeContact: (index: number) => void;
    editContact: (index: number, contact: IContact) => void;
    validityPhone: (inputName: string) => void;
    addInstructions: () => void;
    collapseCommunication: () => void;
    isCollapseIn(): boolean;
}

export class OfferCommunicationRegisterController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IOfferWizardCommunicationScope;
    private $offerScope: IOfferScope;
    private $q: ng.IQService;
    private restService: IRestService;
    private formService: FormService2;
    private modalService: IModalService;
    private sessionService: ISessionService;
    private contactsModalID: number;
    private instructionsModalID: number;
    private intlPhone: intlTelInput.Plugin;
    private countryChangeListener: EventListenerOrEventListenerObject;

    constructor($injector: ng.Injectable<any>, $scope: IOfferWizardCommunicationScope) {
        this.$scope = $scope;
        this.$offerScope = <IOfferScope>$scope.$parent.$parent;
        this.$q = $injector.get('$q');
        this.restService = $injector.get('RestService');
        this.formService = this.$offerScope.formService;
        this.modalService = this.$offerScope.modalService;
        this.sessionService = this.$offerScope.sessionService;
        this.contactsModalID = 0;
        this.instructionsModalID = 0;
        this.initScopeFunctions();
    }

    async $onInit(): Promise<void> {
        try {
            this.initModel();
            await this.initDependencies();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initModel(): void {
        this.$scope.model = {
            CONTACTS: null,
            INSTRUCTIONS: null
        };
    }

    private initScopeFunctions(): void {

        this.$scope.viewLog = (uuid?: string) => {
            this.viewLog(uuid);
        }

        this.$scope.addContact = (): void => {
            this.addContact();
        }

        this.$scope.editContact = (index: number, currentAccount: IContact) => {
            this.editContact(index, currentAccount);
        }

        this.$scope.removeContact = (index: number) => {
            this.removeContact(index);
        }

        this.$scope.validityPhone = (inputName: string) => {
            if (!inputName) return;
            ValidateUtil.validityPhone(this.$scope['form'][inputName], inputName == "phone" ? this.intlPhone : null);
        };

        this.$scope.addInstructions = () => {
            this.addInstructions();
        }

        this.$scope.collapseCommunication = (): void => {
            this.collapseCommunication();
        }

        this.$scope.isCollapseIn = (): boolean => {
            return this.$offerScope.collapseState.panel == ECollapseState.COMMUNICATION && !this.$offerScope.collapseState.released;
        }

        this.$scope.viewInstructionsLog = () => {
            this.viewInstructionsLog();
        }
    }

    async initDependencies(): Promise<any> {
        const self: OfferCommunicationRegisterController = this;

        this.initCollapseEvents();

        return new Promise(function (resolve, reject) {
            self.$q.all([
            ]).then(async (result: any) => {
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private initCollapseEvents() {

        this.$scope.$on('offerCommunicationCollapse', () => {
            this.collapseCommunication();
        });

        $('#collapseCommunication').on('shown.bs.collapse', (event: JQuery.Event) => {
            angular.element("#instructions").show();
            angular.element("#instructionsLog").show();
            if (event.target == event.currentTarget) {
                angular.element("#collapseCommunicationHeading").attr('aria-expanded', 'true');
                // update collapse state
                this.$offerScope.collapseState = { panel: ECollapseState.COMMUNICATION, released: false, nextState: null };
                this.$offerScope.repositionPanels('communicationRow', true);
                this.getOfferTabsCommunication();
            }
        });

        $('#collapseCommunication').on('hidden.bs.collapse', async (event: JQuery.Event) => {
            angular.element("#instructions").hide();
            angular.element("#instructionsLog").hide();
            if (event.target == event.currentTarget) {
                angular.element("#collapseCommunicationHeading").attr('aria-expanded', 'false');
                this.$scope.model = null;
                this.$scope.oldModel = null;
            }
        });
    }

    private async viewLog(uuid?: string) {
        try {
            this.formService.block();
            let log: IViewLog = {
                operation: 'history',
                number: this.$offerScope.model.ID.toString(),
                list: [],
                show: true,
                showCloseButton: false,
                searchQuery: '',
                originalList: [],
            }

            this.requestHistory(log.number, uuid).then(result => {
                log.list = result.data;
                log.originalList = angular.copy(log.list);
                this.$scope.log = log;
                this.$scope.customLogProperties = this.getCustomLogProperties();
                angular.element('#log-viewer').removeClass('ng-hide');
                this.formService.unblock();
            }).catch(ex => {
                this.formService.handleError(ex);
            });

            const modalId = this.modalService.newModal();
            await this.modalService.showModalConfirmation(
                {
                    modalID: modalId,
                    scope: this.$scope,
                    template: require('../view/modals/offerLog.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG"
                }
            );
            this.modalService.closeModal(modalId);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private requestHistory(id: string, uuid?: string): Promise<any> {
        return this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/communication/viewLog/${EOfferLogsName.COMMUNICATION}/${id}${uuid ? '/' + uuid : ''}`, 10000, null, false);
    }

    private async viewInstructionsLog() {
        try {
            this.formService.block();
            let log: IViewLog = {
                operation: 'history',
                number: this.$offerScope.model.ID.toString(),
                list: [],
                show: true,
                showCloseButton: false,
                searchQuery: '',
                originalList: [],
            }

            this.requestHistoryInstructions(log.number).then(result => {
                log.list = result.data;
                log.originalList = angular.copy(log.list);
                this.$scope.log = log;
                this.$scope.customLogProperties = this.getCustomLogProperties();
                angular.element('#log-viewer').removeClass('ng-hide');
                this.formService.unblock();
            }).catch(ex => {
                this.formService.handleError(ex);
            });

            const modalId = this.modalService.newModal();
            await this.modalService.showModalConfirmation(
                {
                    modalID: modalId,
                    scope: this.$scope,
                    template: require('../view/modals/offerLog.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG"
                }
            );
            this.modalService.closeModal(modalId);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private requestHistoryInstructions(id: string): Promise<any> {
        return this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/communication/viewLog/${EOfferLogsName.COMMUNICATION_INSTRUCTIONS}/${id}`, 10000, null, false);
    }

    private async addContact(): Promise<void> {
        try {
            this.contactsModalID = this.modalService.newModal();
            const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                {
                    modalID: this.contactsModalID,
                    scope: this.$scope,
                    formService: 'register',
                    template: require("../view/modals/offerCommunicationContactModal.html"),
                    size: 'vlg'
                },
                {
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: `GENERAL.CONTACT`
                });

            const contact: IContact = {
                ID: null,
                ID_OFFER: null,
                LEGAL_PERSON: null,
                NETWORK: null,
                NAME: null,
                PERSON_ROLE: null,
                SECTOR: null,
                EMAIL: null,
                PHONE_COUNTRY_CODE: null,
                PHONE: null,
                MESSAGE_GROUP: null,
                DATA_ORIGIN_TYPE: { ID: 'M', NAME: 'Manual' },
                UUID: null
            }
            const modalScope = await this.buildContactModalScope(0, contact);
            await modalInstance.rendered.then(() => {
                modalScope.initPhoneComponent();
            });

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async buildContactModalScope(index: number, contact: IContact): Promise<IContactModal> {
        const modalScope: IContactModal = await this.modalService.getModalScope(this.contactsModalID);

        modalScope.contact = angular.copy(contact);
        modalScope.oldContact = angular.copy(contact);
        modalScope.isLegalPerson = index == 0 || modalScope.contact.LEGAL_PERSON != null;

        modalScope.applyContact = async () => {
            if (await this.applyContact(modalScope.contact, modalScope.oldContact, modalScope.operation, index)) this.$scope.oldModel = angular.copy(this.$scope.model);
        };

        modalScope.closeContactModal = async (): Promise<void> => {
            let close = true;
            if (this.$offerScope.hasChanges(modalScope.contact, modalScope.oldContact)) {
                const confirm = await this.$offerScope.modalSaveConfirmation("GENERAL.CLOSE", "GENERAL.CLOSE");
                if (confirm) {
                    if (await this.applyContact(modalScope.contact, modalScope.oldContact, modalScope.operation, index)) this.$scope.oldModel = angular.copy(this.$scope.model);
                    else close = false;
                }
            }
            if (close) {
                this.modalService.closeModal(this.contactsModalID);
                this.contactsModalID = 0;
                $('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
            }
        }

        modalScope.getLegalPersonByNameOrInitials = async (search: string) => {
            let legalPersonList: ILegalPerson[] = [];
            if (search && search.length >= 2) legalPersonList = await this.getLegalPersonByNameOrInitials(search);
            modalScope.legalPersonList = legalPersonList;
        };

        modalScope.getPersonRoleByName = async (search: string) => {
            let personRoleList: SelectorModel[] = [];
            if (search && search.length >= 2) personRoleList = await this.getPersonRoleByName(search);
            modalScope.personRoleList = personRoleList;
        };

        modalScope.getSectorByName = async (search: string) => {
            let sectorList: SelectorModel[] = [];
            if (search && search.length >= 2) sectorList = await this.getSectorByName(search);
            modalScope.sectorList = sectorList;
        };

        modalScope.getMessageGroup = async () => {
            modalScope.messageGroupList = this.$offerScope.messageGroupList;
        };

        modalScope.initPhoneComponent = () => {
            const self: OfferCommunicationRegisterController = this;

            modalScope.destroyPhoneComponent();

            const inputPhone = document.querySelector("#phone_contact");

            self.intlPhone = intlTelInput(inputPhone, {
                utilsScript: "../../../../node_modules/intl-tel-input/build/js/utils.js",
                autoPlaceholder: "aggressive",
                formatOnDisplay: true,
                separateDialCode: true,
                initialCountry: "br"
            });

            self.intlPhone.setCountry('br');

            this.countryChangeListener = () => {
                modalScope.contact.PHONE_COUNTRY_CODE = self.intlPhone.getSelectedCountryData().iso2;
                self.intlPhone.setNumber("");
                self.$scope.validityPhone("phone_contact");
                self.$scope.$apply();
            };
            inputPhone.addEventListener("countrychange", this.countryChangeListener, false);
        }

        modalScope.destroyPhoneComponent = () => {
            const inputPhone = document.querySelector("#phone_contact");
            if (inputPhone) inputPhone.removeEventListener("countrychange", this.countryChangeListener, false);
            if (this.intlPhone) this.intlPhone.destroy();
        }

        modalScope.goToSector = (id: number) => {
            this.sessionService.openTab("app.registration.sector", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        modalScope.goToPersonRole = (id: number) => {
            this.sessionService.openTab("app.registration.personRole", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        modalScope.goToLegalPerson = (id: number) => {
            this.sessionService.openTab("app.registration.legalPerson", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        modalScope.goToNetwork = (id: number) => {
            this.sessionService.openTab("app.registration.network", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        return modalScope;
    }

    private async applyContact(contact: IContact, oldContact: IContact, operation: string, index: number): Promise<boolean> {
        let success: boolean = false;
        try {
            if (!contact) throw Error('contact is null');
            if (!index && index != 0) throw Error('index is null');
            if (!this.$offerScope.hasInvalidRequiredElements("offerCommunicationContactModal")) {
                this.formService.block();
                if (!contact.DATA_ORIGIN_TYPE) contact.DATA_ORIGIN_TYPE = { ID: 'M', NAME: 'Manual' };
                if (!contact.ID_OFFER) contact.ID_OFFER = this.$offerScope.model.ID;
                let contactResult: IContact = null;
                if (operation === 'register') {
                    contactResult = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/communication/insertContact`, { id: this.$offerScope.model.ID, data: contact, oldData: oldContact }, 30000, false);
                } else {
                    contactResult = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/communication/updateContact`, { id: this.$offerScope.model.ID, data: contact, oldData: oldContact }, 30000, false);
                }
                if (contactResult) {
                    success = true;
                    contact.UUID = contactResult.UUID;
                    if (operation === "register") {
                        if (!this.$scope.model.CONTACTS) {
                            this.$scope.model.CONTACTS = [];
                        }
                        this.$scope.model.CONTACTS.push(contact);
                    }
                    else this.$scope.model.CONTACTS.splice(index, 1, contact);
                    this.modalService.closeModal(this.contactsModalID);
                    this.contactsModalID = 0;
                }
            }
        } catch (ex) {
            success = false;
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            if (success) this.formService.notifySuccess(`Contact successfully ${operation == 'register' ? 'inserted' : 'updated'}`);
            return success;
        }
    }

    private async getSectorByName(name?: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const sectors = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/sector/list/custom`, { name }, 30000, false);
            if (sectors) result = sectors.map(sector => { return { ID: sector.ID, NAME: sector.DISPLAY_NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getPersonRoleByName(name?: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const roles = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/personRole/list/custom`, { search: name }, 30000, false);
            if (roles) result = roles.map(role => { return { ID: role.ID, NAME: role.DISPLAY_NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getLegalPersonByNameOrInitials(legalPersonFilter?: string): Promise<ILegalPerson[]> {
        let result = [];
        this.formService.block();
        try {
            const legalPersons = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/legalPerson/list/custom`, { search: legalPersonFilter }, 30000, false);
            if (legalPersons) result = legalPersons.map(legalPerson => { return { ID: legalPerson.ID, SHORT_NAME: legalPerson.SHORT_NAME, CORPORATE_NAME: legalPerson.CORPORATE_NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async editContact(index: number, currentAccount: IContact): Promise<void> {
        try {
            this.contactsModalID = this.modalService.newModal();

            const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                {
                    modalID: this.contactsModalID,
                    scope: this.$scope,
                    formService: 'edit',
                    template: require("../view/modals/offerCommunicationContactModal.html"),
                    size: 'vlg',
                    keyboard: true,
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing") {
                            if (reason.toString() == "escape key press") event.preventDefault();
                            if (!closed) {
                                const modalScope: IContactModal = await this.modalService.getModalScope(this.contactsModalID);
                                await modalScope.closeContactModal();
                            }
                        }
                    }
                },
                {
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: `GENERAL.CONTACT`
                }
            );
            const modalScope = await this.buildContactModalScope(index, currentAccount);
            await modalInstance.rendered.then(() => {
                modalScope.initPhoneComponent();
            });
            await modalScope.$applyAsync();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeContact(index: number): Promise<void> {
        try {
            const thisTranslated = this.formService.getTranslate("GENERAL.GENDER.THIS", null, true);
            const contactTranslated = this.formService.getTranslate("GENERAL.CONTACT", null, true);
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thisTranslated, prop: contactTranslated })
            });

            if (!modal) return;

            this.formService.block();
            let contactResult: IContact = null;
            contactResult = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/communication/deleteContact`, { id: this.$offerScope.model.ID, data: this.$scope.model.CONTACTS[index] }, 30000, false);
            if (contactResult) {
                this.formService.notifySuccess("Contact successfully removed.");
                this.$scope.model.CONTACTS.splice(index, 1);
                this.$scope.oldModel = angular.copy(this.$scope.model);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getOfferTabsCommunication(): Promise<void> {
        try {
            const communicationTab = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/contacts/${this.$offerScope.operation}/${this.$offerScope.model.ID}`, 30000, null, false);
            if (communicationTab && communicationTab.data) this.$scope.model = communicationTab.data;

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.oldModel = angular.copy(this.$scope.model);
            this.formService.unblock();
        }
    }

    private async addInstructions() {
        try {
            const instructions: ICommunicationInstruction = await this.getInstructionsData();
            this.instructionsModalID = this.modalService.newModal();
            this.modalService.showModalInfo(
                {
                    modalID: this.instructionsModalID,
                    scope: this.$scope,
                    formService: this.$offerScope.operation,
                    template: require('../view/modals/offerCommunicationInstructionsModal.html'),
                    size: 'vlg',
                    keyboard: true,
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing") {
                            if (reason.toString() == "escape key press") event.preventDefault();
                            if (!closed) {
                                const modalScope: ICommunicationInstructionModal = await this.modalService.getModalScope(this.instructionsModalID);
                                await modalScope.closeCommunicationInstructions();
                            }
                        }
                    }
                },
                {
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: `GENERAL.INSTRUCTIONS`
                });

            await this.buildCommunicationInstructionModalScope(instructions);

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }
    private async buildCommunicationInstructionModalScope(instructions: ICommunicationInstruction): Promise<ICommunicationInstructionModal> {
        const modalScope: ICommunicationInstructionModal = await this.modalService.getModalScope(this.instructionsModalID);
        modalScope.instructions = instructions;
        modalScope.oldInstructions = angular.copy(instructions);
        modalScope.applyCommunicationInstructions = async () => {
            if (await this.applyCommunicationInstructions(modalScope.instructions, modalScope.oldInstructions)) this.$scope.oldModel = angular.copy(this.$scope.model);
        };

        modalScope.closeCommunicationInstructions = async (): Promise<void> => {
            if (this.$offerScope.hasChanges(modalScope.instructions, modalScope.oldInstructions)) {
                const confirm = await this.$offerScope.modalSaveConfirmation("GENERAL.CLOSE", "GENERAL.CLOSE");
                if (confirm && await this.applyCommunicationInstructions(modalScope.instructions, modalScope.oldInstructions)) this.$scope.oldModel = angular.copy(this.$scope.model);
            }
            this.modalService.closeModal(this.instructionsModalID);
            this.instructionsModalID = 0;
            $('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
        };

        return modalScope;
    }

    private async getInstructionsData(): Promise<ICommunicationInstruction> {
        let instructions = null;
        try {
            this.formService.block();
            const instructionsData = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/communication/instructions/${this.$offerScope.model.ID}`, 30000, null, false);
            if (instructionsData && instructionsData.data) {
                instructions = instructionsData.data.INSTRUCTIONS;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return instructions;
        }
    }

    private async applyCommunicationInstructions(instructions: ICommunicationInstruction, oldInstructions: ICommunicationInstruction): Promise<boolean> {
        let success: boolean = false;
        try {
            this.formService.block();
            const result = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/communication/updateInstruction`, { id: this.$offerScope.model.ID, data: instructions, oldData: oldInstructions }, 30000, false);
            if (result) {
                success = true;
                const msgSuccess = this.formService.getTranslate('PRODUCT.INSTRUCTION_DATA_SAVED_SUCCESSFULLY');
                this.formService.notifySuccess(msgSuccess);
                this.$scope.model.INSTRUCTIONS = instructions;
                this.modalService.closeModal(this.instructionsModalID);
                this.instructionsModalID = 0;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return success;
        }
    }

    private async collapseCommunication() {
        try {
            if (this.$offerScope.collapseState.released || this.$offerScope.collapseState.panel == ECollapseState.COMMUNICATION) {
                const collapseMain = angular.element("#collapseCommunication");
                if (collapseMain) {
                    const isCollapsed = angular.element("#collapseCommunicationHeading").attr("aria-expanded") == "true";
                    collapseMain['collapse']('toggle');
                    if (isCollapsed) {
                        this.$offerScope.collapseState.released = true;
                        this.$offerScope.repositionPanels('communicationRow');
                    }
                }
            } else {
                this.$offerScope.collapseState.nextState = ECollapseState.COMMUNICATION;
                this.$offerScope.releaseCollapse();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: "ACTIVE", LABEL: "GENERAL.ACTIVE" },
            { PROPERTY: "CORPORATE_NAME", LABEL: "ENTITY.CORPORATE_NAME" },
            { PROPERTY: "FANTASY_NAME", LABEL: "ENTITY.TRADE_NAME" },
            { PROPERTY: "SHORT_NAME", LABEL: "ENTITY.SHORT_NAME" },
            { PROPERTY: "LEGAL_PERSON", LABEL: "ENTITY.LEGAL_PERSON" },
            { PROPERTY: "NETWORK", LABEL: "BASIC_DATA.NETWORK" },
            { PROPERTY: "ID_NETWORK", LABEL: "GENERAL.NETWORK_IDENTIFER" },
            { PROPERTY: "NAME", LABEL: "GENERAL.NAME" },
            { PROPERTY: "EMAIL", LABEL: "ENTITY.EMAIL" },
            { PROPERTY: "PERSON_ROLE", LABEL: "ENTITY.JOB_TITLE" },
            { PROPERTY: "SECTOR", LABEL: "ENTITY.SECTOR" },
            { PROPERTY: "PHONE_COUNTRY_CODE", LABEL: "GENERAL.TELEPHONE_COUNTRY_CODE" },
            { PROPERTY: "PHONE", LABEL: "ENTITY.PHONE" },
            { PROPERTY: "MESSAGE_GROUP", LABEL: "REGISTRATION.GROUP_SEND_MESSAGE" },
            { PROPERTY: "DATA_ORIGIN_TYPE", LABEL: "GENERAL.DATA_ORIGIN" },
            // Instructions
            { PROPERTY: "PRODUCT", LABEL: "BASIC_DATA.PRODUCT" },
            { PROPERTY: "COMMERCIAL", LABEL: "GENERAL.COMMERCIAL_DEPARTMENT" },
            { PROPERTY: "OPERATIONAL", LABEL: "GENERAL.OPERATIONAL_DEPARTMENT" },
            { PROPERTY: "FINANCIAL", LABEL: "GENERAL.FINANCIAL_DEPARTMENT" },
            { PROPERTY: "CUSTOMER", LABEL: "BASIC_DATA.CLIENT" },
            { PROPERTY: "AGENT", LABEL: "BASIC_DATA.AGENT" },
            { PROPERTY: "PROCESS_HOUSE", LABEL: "FINANCIAL.HOUSE" },
            { PROPERTY: "PROCESS_MASTER", LABEL: "FINANCIAL.MASTER" },
            { PROPERTY: "INSTRUCTIONS", LABEL: "GENERAL.INSTRUCTIONS" },
        ];
        return props;
    }
}
import * as angular from 'angular';

const productState = {
    name: 'app.product',
    url: '/product',
    abstract: true,
    data: {
        /* Solução de carregar os termos por módulo
        i18n: ['general', 'registration', 'route', 'product', 'financial', 'basic_data', 'entity', 'operational'],
        */
        requiresAuth: true,
        permissions: {
            except: 'EXTERNAL',
            redirectTo: 'app.denied'
        }
    }
}

const productMenu = {
    name: 'app.product.menu',
    url: '/menu',
    views: {
        'content@app': {
            template: require("./view/productMenu.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.PRODUCT',
        parent: 'app.product'
    }
}

const newTariffFreightFclState = {
    name: 'app.product.newTariffFreightFcl',
    url: '/newTariffFreightFcl',
    views: {
        'content@app': {
            template: require("./view/newTariffFreightFcl.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.TARIFF_FREIGHT_FCL',
        parent: 'app.product.menu'
    }
}

const newTariffFreightAirState = {
    name: 'app.product.newTariffFreightAir',
    url: '/newTariffFreightAir',
    views: {
        'content@app': {
            template: require("./view/newTariffFreightAir.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.TARIFF_FREIGHT_AIR',
        parent: 'app.product.menu'
    }
}

const tariffLocalState = {
    name: 'app.product.tariffLocal',
    url: '/tariffLocal',
    views: {
        'content@app': {
            template: require("./view/tariffLocalRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.TARIFF_LOCAL',
        parent: 'app.product.menu'
    }
}

const tariffDetDemState = {
    name: 'app.product.tariffDetDem',
    url: '/tariffDetDem',
    views: {
        'content@app': {
            template: require("./view/tariffDetDemRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.TARIFF_DET_DEM',
        parent: 'app.product.menu'
    }
}

const newFreightContractState = {
    name: 'app.product.newFreightContract',
    url: '/newFreightContract',
    views: {
        'content@app': {
            template: require("./view/newFreightContractRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.FREIGHT_CONTRACT',
        parent: 'app.product.menu'
    }
}

const paramsProfitShareState = {
    name: 'app.product.paramsProfitShare',
    url: '/paramsProfitShare',
    views: {
        'content@app': {
            template: require("./view/paramsProfitShareRegister.html")
        }
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.PROFIT_SHARE',
        parent: 'app.product.menu'
    }
}

const inlandContractState = {
    name: 'app.product.inlandContract',
    url: '/inlandContract',
    views: {
        'content@app': {
            template: require("./view/inlandContractRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'PRODUCT.INLAND_CONTRACT',
        parent: 'app.product.menu'
    }
}

const inlandRoutesState = {
    name: 'app.product.inlandRoutes',
    url: '/inlandRoutes',
    views: {
        'content@app': {
            template: require("./view/inlandRoutesRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'PRODUCT.INLAND_ROUTES',
        parent: 'app.product.menu'
    }
}

//Services
const maritimeServiceState = {
    name: 'app.product.maritimeService',
    url: '/maritimeService',
    views: {
        'content@app': {
            template: require("./view/maritimeServiceRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.MARITIME_SERVICE',
        parent: 'app.product.menu'
    }
}

const airServiceState = {
    name: 'app.product.airService',
    url: '/airService',
    views: {
        'content@app': {
            template: require("./view/airServiceRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.AIR_SERVICE',
        parent: 'app.product.menu'
    }
}

const autoRatingParamState = {
    name: 'app.product.autoRatingParam',
    url: '/autoRatingParam',
    views: {
        'content@app': {
            template: require("./view/autoRatingParamRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.AUTO_RATING_EXCEPTION',
        parent: 'app.product.menu'
    }
}

const tariffTransactionRegisterManagementState = {
    name: 'app.product.tariffTransaction',
    url: '/tariffTransaction',
    views: {
        'content@app': {
            template: require("./view/tariffTransactionManagement.html")
        },
    },
    ncyBreadcrumb: {
        label: 'PRODUCT.AUTO_RATING_MANAGEMENT',
        parent: 'app.product.menu'
    }
}

const autoRatingControlState = {
    name: 'app.product.autoRatingControl',
    url: '/autoRatingControl',
    views: {
        'content@app': {
            template: require("./view/autoRatingControlRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.AUTO_RATING_CONTROL',
        parent: 'app.product.menu'
    }
}

//#region  Tariff history
const tariffFreightHistory = {
    name: 'app.product.tariffFreightHistory',
    url: '/tariffFreightHistory',
    views: {
        'content@app': {
            template: require("./view/tariffFreightHistoryRegister.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.TARIFF_FREIGHT_HISTORY',
        parent: 'app.product.menu'
    }
}

const tariffDomesticHistory = {
    name: 'app.product.tariffDomesticHistory',
    url: '/tariffDomesticHistory',
    views: {
        'content@app': {
            template: require("./view/tariffDomesticHistoryRegister.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.TARIFF_DOMESTIC_HISTORY',
        parent: 'app.product.menu'
    }
}

const tariffLocalHistory = {
    name: 'app.product.tariffLocalHistory',
    url: '/tariffLocalHistory',
    views: {
        'content@app': {
            template: require("./view/tariffLocalHistoryRegister.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.TARIFF_LOCAL_HISTORY',
        parent: 'app.product.menu'
    }
}

const tariffDetDemHistory = {
    name: 'app.product.tariffDetDemHistory',
    url: '/tariffDetDemHistory',
    views: {
        'content@app': {
            template: require("./view/tariffDetDemHistoryRegister.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.TARIFF_DET_DEM_HISTORY',
        parent: 'app.product.menu'
    }
}

const tablePerDiem = {
    name: 'app.product.tablePerDiem',
    url: '/tablePerDiem',
    views: {
        'content@app': {
            template: require("./view/tablePerDiemRegister.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.TABLE_PER_DIEM',
        parent: 'app.product.menu'
    }
}

const masterBillingSettingsState = {
    name: 'app.product.masterBillingSettings',
    url: '/masterBillingSettings',
    views: {
        'content@app': {
            template: require("./view/masterBillingSettingsRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.MASTER_BILLING_SETTINGS',
        parent: 'app.product.menu'
    }
}

const tariffComplementaryState = {
    name: 'app.product.tariffComplementary',
    url: '/tariffComplementary',
    views: {
        'content@app': {
            template: require("./view/tariffComplementaryRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.TARIFF_COMPLEMENTARY',
        parent: 'app.product.menu'
    }
}

const freightRoutesState = {
    name: 'app.product.freightRoutes',
    url: '/freightRoutes',
    views: {
        'content@app': {
            template: require("./view/freightRoutesRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.FREIGHT_ROUTES',
        parent: 'app.product.menu'
    }
}

//#endregion

routeConfig.$inject = ['$uiRouterProvider'];
function routeConfig($uiRouterProvider) {
    const $stateRegistry = $uiRouterProvider.stateRegistry;
    $stateRegistry.register(productState);
    $stateRegistry.register(productMenu);

    // Tariff Freight
    $stateRegistry.register(newTariffFreightFclState);
    $stateRegistry.register(newTariffFreightAirState);
    $stateRegistry.register(tariffLocalState);
    $stateRegistry.register(tariffDetDemState);
    $stateRegistry.register(tablePerDiem);
    $stateRegistry.register(freightRoutesState);

    // Contracts
    $stateRegistry.register(paramsProfitShareState);
    $stateRegistry.register(inlandContractState);
    $stateRegistry.register(inlandRoutesState);
    $stateRegistry.register(newFreightContractState);

    //Services
    $stateRegistry.register(maritimeServiceState);
    $stateRegistry.register(airServiceState);

    //Auto Rating
    $stateRegistry.register(autoRatingParamState);
    $stateRegistry.register(tariffTransactionRegisterManagementState);
    $stateRegistry.register(autoRatingControlState);

    // Tariff History
    $stateRegistry.register(tariffFreightHistory);
    $stateRegistry.register(tariffDomesticHistory);
    $stateRegistry.register(tariffLocalHistory);
    $stateRegistry.register(tariffDetDemHistory);

    $stateRegistry.register(masterBillingSettingsState);

    $stateRegistry.register(tariffComplementaryState);
}

export default function initRoute(moduleName: string): void {
    angular.module(moduleName).config(routeConfig);
}
import * as angular from "angular";
import { SSEService } from "@appServices/SSEService";
import { IModalService } from '@services/ModalService';
import { IScoreService } from '@services/ScoreService';
import { ISessionService } from "@services/SessionService";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog, } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { ICustomLogProperties, IViewLog } from "@models/interface/common/IViewLog";
import { ScoreConfigModel, FILTER } from "../model/ScoreConfigModel";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { SelectorModel } from "../../common/model/SelectorModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { EOperation } from "@enums/GenericData";
import { HelperService } from "@services/HelperService";

interface IScoreConfigScope extends IGridFormServiceScope {
    model: ScoreConfigModel;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: ScoreConfigModel;
    scoreTargetList: SelectorModel[]
    scoreTargetFilterTypeList: SelectorModel[];
    scoreTargetFilterFieldList: SelectorModel[];
    scoreTargetScoreFieldList: SelectorModel[];
    httpMethodList: SelectorModel[];
    microserviceList: SelectorModel[];
    user: any;
    sessionService: ISessionService;
    editScoreConfig: (scoreConfig: ScoreConfigModel) => Promise<void>;
    viewScoreConfig: (scoreConfig: ScoreConfigModel) => Promise<void>;
    viewLogScoreConfig: (scoreConfig: ScoreConfigModel) => Promise<void>;
    copyScoreConfig: (scoreConfig: ScoreConfigModel) => Promise<void>;
    addFilter: () => void;
    removeFilter: (index: number) => void;
    changeType: (selected: SelectorModel, index: number) => void;
    changeChecked: (index: number) => void;
    checkFieldData: (filter: FILTER) => Promise<void>;
}

export class ScoreConfigRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IScoreConfigScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private $timeout: ng.ITimeoutService
    private ModalService: IModalService;
    private scoreService: IScoreService;
    private SSEService: SSEService;
    private gridName: string;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IScoreConfigScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');
        this.scoreService = $injector.get('ScoreService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope);
        this.gridName = 'GRID_SCORE_CONFIG';
        this.helperService = $injector.get('HelperService');
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();

            this.initForm(this, 'form', 'scoreConfig', 'GENERAL.MENU.SCORE_CONFIGURATION', true);
            await this.initGrid(this.gridName, '/scoreConfig/list', true, true, null, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initDependencies(): Promise<any> {
        const self: ScoreConfigRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getScoreTargetList(),
                self.getScoreTargetFilterTypeList(),
                self.getScoreTargetFilterFieldList(),
                self.getScoreTargetScoreFieldList(),
                self.getHttpMethodsList(),
                self.getMicroserviceList(),
            ]).then(async (result: any) => {
                self.$scope.scoreTargetList = result[0];
                self.$scope.scoreTargetFilterTypeList = result[1];
                self.$scope.scoreTargetFilterFieldList = result[2].data;
                self.$scope.scoreTargetScoreFieldList = result[3].data;
                self.$scope.httpMethodList = result[4];
                self.$scope.microserviceList = result[5];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.addFilter = (): void => {
            this.addFilter();
        }

        this.$scope.removeFilter = (index: number): void => {
            this.removeFilter(index);
        }

        this.$scope.changeType = (selected: SelectorModel, index: number): void => {
            this.changeType(selected, index);
        }

        this.$scope.changeChecked = (index: number): void => {
            this.changeChecked(index);
        }

        this.$scope.checkFieldData = (filter: FILTER): Promise<void> => {
            return this.checkFieldData(filter);
        }

        this.$scope.editScoreConfig = async (scoreConfig: ScoreConfigModel): Promise<void> => {
            let blockedObject = {
                ID: scoreConfig.ID,
                NAME: scoreConfig.CREATED_AT.toString(),
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || scoreConfig.ID !== this.$scope.model.ID) this.$scope.view(scoreConfig);
                } else if (this.$scope.operation !== EOperation.EDIT || scoreConfig.ID !== this.$scope.model.ID) {
                    this.$scope.edit(scoreConfig);
                }
            };
        }

        this.$scope.viewScoreConfig = async (scoreConfig: ScoreConfigModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(scoreConfig);
        }

        this.$scope.viewLogScoreConfig = async (scoreConfig: ScoreConfigModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(scoreConfig);
        }

        this.$scope.copyScoreConfig = async (scoreConfig: ScoreConfigModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(scoreConfig);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewScoreConfig(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editScoreConfig(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogScoreConfig(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyScoreConfig(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy),
            enableCellEdit: true,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };

        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colEntity: IMonacoColumnDef = { name: "SCORE_TARGET.NAME", displayName: "GENERAL.LOCAL", width: 350 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 150, cellFilter: "YesOrNo" };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'SCORE_TARGET':
                        columnDefs.push(colEntity);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            SCORE_TARGET: null,
            FILTER: null,
            SCORE: null,
            ACTIVE: true,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null
        };
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.SSEService.closeEvents();
            this.addFilter();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} (${this.$scope.model.SCORE_TARGET.NAME})`;
            BrowserTitle.$id = this.$scope.model.SCORE_TARGET.NAME;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} (${this.$scope.model.SCORE_TARGET.NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.SCORE_TARGET.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        return {
            route: `/scoreConfig/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    private async getScoreTargetList(): Promise<SelectorModel[]> {
        const { data: scoreTarget } = await this.helperService.get(`/generic/value/score_target`, null);
        return scoreTarget && scoreTarget.data ? scoreTarget.data : [];
    }

    private async getScoreTargetFilterTypeList(): Promise<SelectorModel[]> {
        const { data: scoreTargetFilterType } = await this.helperService.get(`/generic/value/score_target_filter_type`, null);
        return scoreTargetFilterType && scoreTargetFilterType.data ? scoreTargetFilterType.data : [];
    }

    private getScoreTargetFilterFieldList(): Promise<SelectorModel[]> {
        return this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/scoreConfig/genericFields/score_target_filter_field`, 30000, null, false);
    }

    private getScoreTargetScoreFieldList(): Promise<SelectorModel[]> {
        return this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/scoreConfig/genericFields/score_target_score_field`, 30000, null, false);
    }

    private async getHttpMethodsList(): Promise<SelectorModel[]> {
        const { data: httpMethods } = await this.helperService.get(`/generic/value/http_method`, null);
        return httpMethods && httpMethods.data ? httpMethods.data : [];
    }

    private async getMicroserviceList(): Promise<SelectorModel[]> {
        const { data: microService } = await this.helperService.get(`/generic/value/microservice`, null);
        return microService && microService.data ? microService.data : [];
    }

    private addFilter(): void {
        try {
            if (!this.$scope.model.FILTER) this.$scope.model.FILTER = [];
            const filter: FILTER = {
                FIELD: null,
                REQUIRED: true,
                TYPE: null,
                MICROSERVICE: null,
                ENDPOINT: null,
                HTTP_METHOD: null,
                FILTER: null,
                CHECKED: false
            }
            this.$scope.model.FILTER.push(angular.copy(filter));

            this.$timeout(() => {
                const currentIndex = this.$scope.model.FILTER.length - 1;
                this.$scope.selectorValidity('field' + currentIndex);
                this.$scope.selectorValidity('type' + currentIndex);
                this.$scope.selectorValidity('httpMethod' + currentIndex);
                this.$scope.selectorValidity('microservice' + currentIndex);
                this.$scope.selectorValidity('endpoint' + currentIndex);
                this.$scope.selectorValidity('filter' + currentIndex);
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeFilter(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: this.formService.getTranslate('GENERAL.CONFIRM'),
                headerText: this.formService.getTranslate('GENERAL.CONFIRM_ACTION'),
                closeButtonText: this.formService.getTranslate('GENERAL.CLOSE'),
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });

            if (!modal) return;

            if (this.$scope.model.FILTER && this.$scope.model.FILTER.length > 0) {
                this.formService.block();
                this.$scope.model.FILTER.splice(index, 1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private changeType(selected: SelectorModel, index: number): void {
        try {
            if (!index && index != 0) throw Error('index is null');
            if (!selected) throw Error('selected is null');

            if (selected.ID == '1' && this.$scope.model.FILTER && this.$scope.model.FILTER[index] && this.$scope.model.FILTER[index].FILTER) {
                this.$scope.model.FILTER[index].FILTER = null;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private changeChecked(index: number): void {
        try {
            if (!index && index != 0) throw Error('index is null');

            if (this.$scope.model.FILTER && this.$scope.model.FILTER[index]) {
                this.$scope.model.FILTER[index].CHECKED = false;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async checkFieldData(filter: FILTER): Promise<void> {
        let status = false;
        try {
            if (!filter) throw Error('filter is null');
            let validateMessage = '';
            if (!filter.HTTP_METHOD) validateMessage += 'http method';
            if (!filter.MICROSERVICE) validateMessage += (validateMessage != '' ? ',' : '') + ' microservice';
            if (!filter.ENDPOINT) validateMessage += (validateMessage != '' ? ',' : '') + ' endpoint';
            if (validateMessage != '') throw Error(`please, select ${validateMessage} first`);

            this.formService.block();
            const data: SelectorModel[] = await this.scoreService.getFieldData(filter);
            this.formService.unblock();

            let modal: boolean = false;
            if (data && data.length > 0) {
                modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: this.formService.getTranslate('GENERAL.CONFIRM'),
                    closeButtonText: this.formService.getTranslate('GENERAL.CLOSE'),
                    headerText: this.formService.getTranslate('GENERAL.DATA_RETURNED'),
                    bodyText: `<code>${JSON.stringify(data).substr(0, 300)}</code>`
                });

            } else {
                modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: this.formService.getTranslate('GENERAL.CONFIRM_ANYWAY'),
                    closeButtonText: this.formService.getTranslate('GENERAL.CLOSE'),
                    headerText: this.formService.getTranslate('GENERAL.NO_DATA'),
                    bodyText: this.formService.getTranslate('GENERAL.NO_DATA_RETURNED_GIVEN_SETTINGS')
                });
            }
            if (!modal) {
                status = false;
            } else {
                status = true;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            filter.CHECKED = status;
            this.formService.unblock();
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: "SCORE_TARGET",
                LABEL: "GENERAL.LOCAL"
            },
            {
                PROPERTY: "FIELD",
                LABEL: "GENERAL.FIELD"
            },
            {
                PROPERTY: "REQUIRED",
                LABEL: "REGISTRATION.REQUIRED"
            },
            {
                PROPERTY: "FILTER",
                LABEL: "GENERAL.FILTER_FIELDS"
            },
            {
                PROPERTY: "SCORE",
                LABEL: "GENERAL.SCORE_FIELDS",
                READ: "NAME"
            },
            {
                PROPERTY: "NAME",
                LABEL: "GENERAL.DESCRIPTION"
            },
            {
                PROPERTY: "ID",
                LABEL: "GENERAL.FIELD"
            },
            {
                PROPERTY: "HTTP_METHOD",
                LABEL: "GENERAL.HTTP_METHOD"
            },
            {
                PROPERTY: "TYPE",
                LABEL: "GENERAL.TYPE"
            },
            {
                PROPERTY: "MICROSERVICE",
                LABEL: "GENERAL.MICROSERVICE"
            },
            {
                PROPERTY: "ENDPOINT",
                LABEL: "GENERAL.SYSTEM_ROUTING"
            },
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE"
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            },
            {
                PROPERTY: 'CHECKED',
                LABEL: 'GENERAL.SYSTEM_TEST'
            },
            {
                PROPERTY: 'CODE',
                LABEL: "GENERAL.FIELD"
            }
        ];
        return props;
    }
}
import { HandleError } from "../../common/util/HandleError";
import { IGenericData } from "../../common/interface/IGenericResponse";
import * as uiGrid from "ui-grid";

uiSelectPorts.$inject = ['$document', 'uiGridEditConstants', 'uiGridConstants', 'RestService', 'blockUI'];
export function uiSelectPorts($document: JQuery, uiGridEditConstants: uiGrid.edit.IUiGridEditConstants, uiGridConstants: uiGrid.IUiGridConstants, RestService, blockUI) {
  return {
    require: '^uiGrid',
    link: ($scope, $elm, $attr, uiGridCtrl) => {

      $scope.searchPorts = searchPorts;
      
      $document.on('click', endCellEdit);
      $document.on('keyup', keyUp);

      const grid: uiGrid.IGridApi = uiGridCtrl.grid.api;
      grid.edit.on.beginCellEdit($scope, beginCellEdit);

      //AUXS
      function beginCellEdit(rowEntity: any, colDef: uiGrid.IColumnDefOf<any>, triggerEvent: JQueryEventObject): void {
        const totalPortsOpened = $('ui-select-ports').length;

        if (totalPortsOpened > 1) cancelCellEdit(triggerEvent);
      }

      function cancelCellEdit(evt: JQueryEventObject) {
        $scope.$emit(uiGridEditConstants.events.CANCEL_CELL_EDIT);
      }

      function endCellEdit(evt: JQueryEventObject) {
        const hasChanged = $(evt.target).closest('.ui-select-container').length === 0;
        if (hasChanged) $scope.$emit(uiGridEditConstants.events.END_CELL_EDIT);
      }

      function keyUp(evt: JQueryEventObject): void {
        if (evt.keyCode == uiGridConstants.keymap.ESC) cancelCellEdit(evt);
      }



      async function searchPorts(term: string): Promise<void> {

        try {

          if (term.length > 2) {
            blockUI.start();

            let response: IGenericData<any> = await RestService.getObjectAsPromise(
              "/headcargo/maritimePorts/" + term,
              10000
            );

            $scope.ports = response.data;

            $scope.$applyAsync(() => { blockUI.stop(); });

          }
        } catch (ex) {
          $scope.$applyAsync(() => { blockUI.stop(); });
          HandleError.exception(ex);
        }

      };

    }
  };
}
import * as angular from "angular";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog, } from "@services/GridFormService";
import { SSEService } from "@appServices/SSEService";
import { IRestService } from "@services/RestService";
import { ISessionService } from "@services/SessionService";
import { EDIService } from "@services/EDIService";
import { IViewLog, ICustomLogProperties } from "WBA-Model/dist/interface/common/IViewLog";
import { EOperation } from "@enums/GenericData";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { IMonacoColumnDef } from "@services/GridService2";
import { IEdiSession, EDI_SESSION_PARAMS, STYLESHEET, EDI_VALIDATION } from "WBA-Model/dist/interface/edi/EdiSession";
import { ISelectorModel } from "WBA-Model/dist/mongo/SelectorModel";
import { IModalService } from '@services/ModalService';
import { HelperService } from "@services/HelperService";

interface Blob {
    readonly size: number;
    readonly type: string;
    arrayBuffer(): Promise<ArrayBuffer>;
    slice(start?: number, end?: number, contentType?: string): Blob;
    stream(): ReadableStream<Uint8Array>;
    text(): Promise<string>;
}

interface File extends Blob {
    readonly lastModified: number;
    readonly name: string;
    readonly webkitRelativePath: string;
}

interface IEDISessionScope extends IGridFormServiceScope {
    model: IEdiSession;
    log: IViewLog;
    sessionService: ISessionService;
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IEdiSession;
    ediFunctionList: ISelectorModel[];
    gatewayList: ISelectorModel[];
    contextList: ISelectorModel[];
    ediFieldsList: ISelectorModel[];
    eventList: ISelectorModel[];
    taskIntegrationList: ISelectorModel[];
    ediPartnerList: ISelectorModel[];
    addedFile: boolean;
    user: any;
    fileList: File[];

    editEDISession: (EDISession: IEdiSession) => Promise<void>;
    viewEDISession: (EDISession: IEdiSession) => Promise<void>;
    viewLogEDISession: (EDISession: IEdiSession) => Promise<void>;
    copyEDISession: (EDISession: IEdiSession) => Promise<void>;
    fetchData: (id: number, action: string) => Promise<void>;
    addSessionParam: () => void;
    addSessionValidation: () => void;
    removeSessionParam: (index: number) => Promise<void>;
    removeSessionValidation: (index: number) => Promise<void>;
    getEdiPartnerList(search: string): Promise<void>;
    onFileSelected: () => void;
    removeStylesheet: () => void;
    downloadStylesheet: () => void;
}

export class EDISessionRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IEDISessionScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private ModalService: IModalService
    private SSEService: SSEService;
    private ediService: EDIService;
    private gridName: string;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IEDISessionScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.ModalService = $injector.get('ModalService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.ediService = $injector.get('EDIService');
        this.gridName = 'GRID_EDI_SESSION';
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.ediService.$route;
            this.$scope.customLogProperties = this.getCustomLogProperties();

            this.initForm(this, 'form', 'edisession', 'GENERAL.MENU.EDI_SESSION', true);
            await this.initGrid(this.gridName, '/edisession/list', true, true, 120000, true, true);

        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editEDISession = async (EDISession: IEdiSession): Promise<void> => {
            let blockedObject = {
                ID: EDISession.ID,
                NAME: EDISession.ID.toString(),
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || EDISession.ID !== this.$scope.model.ID) this.$scope.fetchData(EDISession.ID, EOperation.VIEW);
                } else if (this.$scope.operation !== EOperation.EDIT || EDISession.ID !== this.$scope.model.ID) {
                    this.$scope.fetchData(EDISession.ID, EOperation.EDIT);
                }
            };
        }

        this.$scope.viewEDISession = async (EDISession: IEdiSession): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(EDISession);
        }

        this.$scope.viewLogEDISession = async (EDISession: IEdiSession): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(EDISession);
        }

        this.$scope.copyEDISession = async (EDISession: IEdiSession): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(EDISession.ID, EOperation.COPY);
        }

        this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
            this.fetchData(id, action);
        }

        this.$scope.addSessionParam = async (): Promise<void> => {
            this.addSessionParam();
        }

        this.$scope.addSessionValidation= async (): Promise<void> => {
            this.addSessionValidation();
        }

        this.$scope.removeSessionParam = async (index: number): Promise<void> => {
            this.removeSessionParam(index);
        }

        this.$scope.removeSessionValidation = async (index: number): Promise<void> => {
            this.removeSessionValidation(index);
        }

        this.$scope.getEdiPartnerList = async (search: string): Promise<void> => {
            let ediPartnerList: ISelectorModel[] = await this.getEdiPartnerList(search);
            this.$scope.ediPartnerList = ediPartnerList;
        }

        this.$scope.onFileSelected = async () => {
            if (this.$scope && this.$scope.fileList && this.$scope.fileList.length) {
                const file: File = this.$scope.fileList[0];

                this.$scope.fileList = null;

                await this.addStylesheet(file);
            }
        }

        this.$scope.removeStylesheet = async (): Promise<void> => {
            this.removeStylesheet();
        }

        this.$scope.downloadStylesheet = async (): Promise<void> => {
            this.downloadStylesheet();
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewEDISession(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editEDISession(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.setCopy(true);grid.appScope.copyEDISession(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogEDISession(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy),
            enableCellEdit: true,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colName: IMonacoColumnDef = { name: "NAME", displayName: "GENERAL.DESCRIPTION", width: 150 };
            const colEDIPartner: IMonacoColumnDef = { name: "ID_EDI_PARTNER.NAME", displayName: "GENERAL.MENU.EDI_PARTNER", width: 100 };
            const colTaskIntegration: IMonacoColumnDef = { name: "TASK_INTEGRATION.NAME", displayName: "GENERAL.TASK_INTEGRATION", width: 100 };
            const colSplitter: IMonacoColumnDef = { name: "SPLITTER", displayName: "GENERAL.SPLITTER", width: 100 };
            const colStylesheet: IMonacoColumnDef = { name: "STYLESHEET.NAME", displayName: "GENERAL.STYLESHEET", width: 150 };
            const colSubSplitter: IMonacoColumnDef = { name: "SUB_SPLITTER", displayName: "GENERAL.SUB_SPLITTER", width: 100 };
            const colBreakline: IMonacoColumnDef = { name: "BREAKLINE", displayName: "GENERAL.BREAKLINE", width: 100 };
            const colContext: IMonacoColumnDef = { name: "CONTEXT.NAME", displayName: "GENERAL.CONTEXT", width: 300, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.CONTEXT, null, "NAME")}}</div>' };
            const colGateway: IMonacoColumnDef = { name: "GATEWAY.NAME", displayName: "GENERAL.GATEWAY", width: 150 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'' };
            const colCreatedBy: IMonacoColumnDef = { name: "CREATED_BY.NAME", displayName: "GENERAL.CREATED_BY", width: 150 };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'' };
            const colUpdatedBy: IMonacoColumnDef = { name: "UPDATED_BY.NAME", displayName: "GENERAL.UPDATED_BY", width: 150 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 100, cellFilter: "YesOrNo" };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'NAME':
                        columnDefs.push(colName);
                        break;
                    case 'ID_EDI_PARTNER':
                        columnDefs.push(colEDIPartner);
                        break;
                    case 'SPLITTER':
                        columnDefs.push(colSplitter);
                        break;
                    case 'STYLESHEET':
                        columnDefs.push(colStylesheet);
                        break;
                    case 'SUB_SPLITTER':
                        columnDefs.push(colSubSplitter);
                        break;
                    case 'BREAKLINE':
                        columnDefs.push(colBreakline);
                        break;
                    case 'TASK_INTEGRATION':
                        columnDefs.push(colTaskIntegration);
                        break;
                    case 'CONTEXT':
                        columnDefs.push(colContext);
                        break;
                    case 'GATEWAY':
                        columnDefs.push(colGateway);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'CREATED_BY':
                        columnDefs.push(colCreatedBy);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                    case 'UPDATED_BY':
                        columnDefs.push(colUpdatedBy);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            NAME: null,
            ID_EDI_PARTNER: null,
            TASK_INTEGRATION: null,
            CONTEXT: null,
            GATEWAY: null,
            STYLESHEET: null,
            SPLITTER: null,
            SUB_SPLITTER: null,
            BREAKLINE: null,
            EDI_SESSION_PARAMS: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            ACTIVE: true,
            EDI_VALIDATION: null,
        }
    }

    initDependencies(): Promise<any> {
        const self: EDISessionRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getEdiFunctionList(),
                self.getGatewayList(),
                self.getContextList(),
                self.getTaskIntegrationList(),
                self.getEdiFieldsList(),
                self.getEventList()
            ]).then((result: any) => {
                self.$scope.ediFunctionList = result[0];
                self.$scope.gatewayList = result[1];
                self.$scope.contextList = result[2];
                self.$scope.taskIntegrationList = result[3];
                self.$scope.ediFieldsList = result[4];
                self.$scope.eventList = result[5];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getEdiFunctionList(): Promise<ISelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/edi_session_params`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getGatewayList(): Promise<ISelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/edi_gateway`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getContextList(): Promise<ISelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/edi_data_context`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getEdiFieldsList(): Promise<ISelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/edi_data_fields`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getEventList(): Promise<ISelectorModel[]> { 
        const { data: generic } = await this.helperService.get(`/generic/value/event`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTaskIntegrationList(): Promise<ISelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/task_integration_param`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getEdiPartnerList(search?: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            const rc = await this.ediService.post(`/edipartner/list`, { param: search });

            if (rc && rc.data && rc.data.data && rc.data.data.data && rc.status == 200) {
                result = rc.data.data.data.map(x => {
                    return { ID: x.ID, NAME: x.NAME }
                });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return result;
        }
    }

    private async addSessionParam() {
        try {
            const sessionParam: EDI_SESSION_PARAMS = {
                EDI_PARAM_FUNCTION: null,
                INIT_PARAM: null,
                FINAL_PARAM: null,
                RESULT: null
            }

            if (!this.$scope.model.EDI_SESSION_PARAMS) this.$scope.model.EDI_SESSION_PARAMS = [];

            this.$scope.model.EDI_SESSION_PARAMS.push(angular.copy(sessionParam));
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async addSessionValidation() {
        try {
            const validationParam: EDI_VALIDATION = {
                EVENT_TYPE: null,
                FIELDS: null,
            }

            if (!this.$scope.model.EDI_VALIDATION) this.$scope.model.EDI_VALIDATION = [];

            this.$scope.model.EDI_VALIDATION.push(angular.copy(validationParam));
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeSessionParam(index: number): Promise<void> {
        try {
            if (!index && index !== 0) throw Error('index is null');
            const thatTranslated = this.formService.getTranslate("GENERAL.GENDER.THAT", null, true);
            const originTranslated = this.formService.getTranslate("BASIC_DATA.ORIGIN", null, true);
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thatTranslated, prop: originTranslated })
            });
            if (!modal) return;

            this.formService.block();

            if (this.$scope.model.EDI_SESSION_PARAMS) {
                this.$scope.model.EDI_SESSION_PARAMS.splice(index, 1);
            }

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeSessionValidation(index: number): Promise<void> {
        try {
            if (!index && index !== 0) throw Error('index is null');
            const thatTranslated = this.formService.getTranslate("GENERAL.GENDER.THAT", null, true);
            const originTranslated = this.formService.getTranslate("BASIC_DATA.ORIGIN", null, true);
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thatTranslated, prop: originTranslated })
            });
            if (!modal) return;

            this.formService.block();

            if (this.$scope.model.EDI_VALIDATION) {
                this.$scope.model.EDI_VALIDATION.splice(index, 1);
            }

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async addStylesheet(file: File): Promise<void> {
        try {
            if (!file) throw Error('file is null');

            const stylesheet: STYLESHEET = {
                NAME: file.name,
                TEXT: await file.text(),
                SIZE: file.size.toString(),
                EXTENSION: file.type.substring(file.type.indexOf("/") + 1)
            }

            this.$scope.model.STYLESHEET = angular.copy(stylesheet);
            this.$scope.addedFile = true;

            this.$scope.$applyAsync();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeStylesheet(): Promise<void> {
        try {
            const stylesheet: STYLESHEET = {
                NAME: null,
                TEXT: null,
                SIZE: null,
                EXTENSION: null
            }

            this.$scope.model.STYLESHEET = angular.copy(stylesheet);
            this.$scope.addedFile = false;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async downloadStylesheet(): Promise<void> {
        try {
            const fileName: string = this.$scope.model.STYLESHEET.NAME;
            const fileData: string = this.$scope.model.STYLESHEET.TEXT;

            let blob = new Blob([fileData], { 'type': 'text/plain' });

            let address = window.URL.createObjectURL(blob);

            let file = angular.element('<a>');
            file.attr("download", fileName);
            file.attr("href", address);
            file.attr("target", '_blank');

            file.ready(function () {
                file[0].click();
                this.timeout(function () {
                    file.detach();
                }, 1000);
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [

            {
                PROPERTY: 'ID',
                LABEL: 'GENERAL.IDENTIFIER'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'ID_EDI_PARTNER',
                LABEL: 'GENERAL.ID_EDI_PARTNER'
            },
            {
                PROPERTY: 'TASK_INTEGRATION',
                LABEL: 'TASK_INTEGRATION'
            },
            {
                PROPERTY: 'CONTEXT',
                LABEL: 'GENERAL.CONTEXT'
            },
            {
                PROPERTY: 'GATEWAY',
                LABEL: 'GENERAL.GATEWAY'
            },
            {
                PROPERTY: 'STYLESHEET',
                LABEL: 'GENERAL.STYLESHEET'
            },
            {
                PROPERTY: 'EDI_SESSION_PARAMS',
                LABEL: 'GENERAL.EDI_SESSION_PARAMS'
            },
            {
                PROPERTY: 'SUB_SPLITTER',
                LABEL: 'GENERAL.SUB_SPLITTER'
            },
            {
                PROPERTY: 'SPLITTER',
                LABEL: 'GENERAL.SPLITTER'
            },
            {
                PROPERTY: 'BREAKLINE',
                LABEL: 'GENERAL.BREAKLINE'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            }
        ]
        return props;
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.addSessionParam();
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            await this.clearFields(this.$scope.model, ['CONCATENATED']);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === "edit" ? "update" : "insert";

        return {
            route: `/edisession/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: this.$scope.scopeBeforeSave
        };
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.ediService.$route}/edisession/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) {
                    this.$scope.edit(model);
                } else if (action === GridColumnBuilderConstants.BTN_COPY) {
                    this.$scope.copy(model);
                } else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }
}
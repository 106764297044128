import angular = require('angular');
import { INotificationService } from '@services/NotificationService';
import { ISelectorModel } from '@models/mongo/SelectorModel';
import { NotificationConstants } from '../../../common/util/NotificationConstants';

interface ILightDataTableCell {
    ID: string,
    LABEL: string,
    STYLE?: {
        width?: string
    }
}

export interface ILightDataTableRow {
    selected?: boolean;
    _index?: number;
    _raw?: any;
}

interface ILightDataTableFilter {
    id: string,
    label: string,
    placeholder: string,
    value?: ISelectorModel,
    onRefresh?: (search: string) => void;
    obtainFilterItems:() => ISelectorModel[];
    onChange?:(search: string) => Promise<void>; 
    obtainFilterExp:(filterId: string) => string;
}

export interface ILightDataTableOptions {
    // Props to configure component
    isEditable?: boolean;
    enableHistory?: boolean;
    columns: ILightDataTableCell[];
    filters?: ILightDataTableFilter[];
    // Events
    onAddBtnClick?: () => void;
    onRowChecked?: (index: number, row: ILightDataTableRow) => void;
    onEditBtnClick?: (index: number, row: ILightDataTableRow) => void;
    onLogBtnClick?: (index: number, row: ILightDataTableRow) => void;
}

interface ILightDataTableScope extends ng.IScope {
    getFilterValues: (filter: ILightDataTableFilter) => ISelectorModel[];
    onRefresh: (filter: ILightDataTableFilter, search: string) => void;
    onRowChecked: (index: number, row: ILightDataTableRow) => void;
    onRowClick: (index: number, row: ILightDataTableRow) => void;
    shouldCallRefresh: (filter: ILightDataTableFilter) => void;
    getCellStyle: (cell: ILightDataTableCell) => object;
    onEditBtnClick: (row: ILightDataTableRow) => void;
    filter: (search: string) => Promise<void>;
    onAddBtnClick: () => void;
    viewLog: (row: ILightDataTableRow) => void;
    getEmptySelectorMsg: () => string;

    viewValue: ILightDataTableRow[];
    options: ILightDataTableOptions;
    data: ILightDataTableRow[];
    emptySelectorMsg: string;
}

lightDataTable.$inject = ['$timeout', 'blockUI', 'NotificationService']
export function lightDataTable($timeout, blockUI: ng.blockUI.BlockUIService, notificationService: INotificationService): ng.IDirective {
    const ddo: ng.IDirective = {
        restrict: 'E',
        transclude: false,
        replace: false,
        scope: {
            readonly: '=',
            options: '=',
            data: '='
        },
        template: require("./LightDataTableView.html"),
        controller: ["$scope", "$element", "$attrs", async function ($scope: ILightDataTableScope, element: JQLite, attributes: ng.IAttributes) {
            const notifyError = (msg: string) => notificationService.error(msg);
            const ensureSingleSelection = (row: ILightDataTableRow, rows: ILightDataTableRow[], index?: number) => {
                const indexValue = row.selected;
                rows.forEach(o => o.selected = false);
                if (index == null) {
                    rows.find(item => item._index === row._index).selected = indexValue;
                } else {
                    rows[index].selected = indexValue;
                }
            };

            if (!$scope.options) notifyError("You must provide the options (ILightDataTableOptions) to be used by LightDataTable");
            if (!$scope.options.columns || !$scope.options.columns.length) notifyError("You must provide the columns array inside options (ILightDataTableCell[])");
            if ($scope.options.filters && !$scope.options.filters.length) notifyError("You must provide the filters array inside options (ILightDataTableFilter[])");

            $scope.emptySelectorMsg = NotificationConstants.EMPTY_SELECTOR_MSG;

            if ($scope.data) {
                $scope.data.forEach((item, index) => item._index = index);
            }

            let modelValue = angular.copy($scope.data);
            $scope.$watch('data', () => {
                if ($scope.data) {
                    $scope.data.forEach((item, index) => item._index = index);
                }

                $scope.viewValue = $scope.data;
                modelValue = angular.copy($scope.data);
            })

            $scope.onRowChecked = (index:number, row: ILightDataTableRow) => {
                ensureSingleSelection(row, modelValue); // updating values in the model value (value to be used internally e.g filter action)
                ensureSingleSelection(row, $scope.data); // updating values in the parent model
                ensureSingleSelection(row, $scope.viewValue, index); // updating values shown in the screen
                if ($scope.options.onRowChecked) $scope.options.onRowChecked(index, row);
            }

            $scope.filter = async (): Promise<void> => {
                const filteredData = angular.copy(modelValue).filter((row) => {
                    const filters = $scope.options.filters.filter(item => item.value != null);
                    const patterns: string[] = filters.map(filter => filter.obtainFilterExp(filter.id));
                    const filterExpression: string = patterns.length ? patterns.join(' && ') : 'true';
                    try {
                        const matched = eval(filterExpression);
                        return matched;
                    } catch (e) {
                        console.error(e);
                    }
                    return false;
                });
                
                $scope.viewValue = filteredData;
            };

            $scope.shouldCallRefresh = (filter: ILightDataTableFilter) => {
                return filter.onRefresh != null ? true : undefined;
            }

            $scope.onRefresh = (filter: ILightDataTableFilter, search: string) => { 
                if (filter.onRefresh) {
                    filter.onRefresh.call(this, search);
                }
            };

            $scope.getFilterValues = (filter: ILightDataTableFilter): ISelectorModel[] => {
                if (filter.obtainFilterItems) {
                    return filter.obtainFilterItems.call(this);
                }
                return [];
            }

            $scope.onAddBtnClick = (): void => {
                if ($scope.options.onAddBtnClick) {
                    $scope.options.onAddBtnClick.call(this);
                }
            }

            $scope.onEditBtnClick = (row: ILightDataTableRow): void => {
                if ($scope.options.onEditBtnClick) {
                    $scope.options.onEditBtnClick.call(this, row._index, row);
                }
            }

            $scope.onRowClick = (index: number, row: ILightDataTableRow): void => {
                if (row && !$scope.options.isEditable) {
                    row.selected = !row.selected;
                    $scope.onRowChecked(index, row);
                }
            }

            $scope.getCellStyle = (cell: ILightDataTableCell) => ({ width: cell.STYLE ? cell.STYLE.width : '20%' });

            $scope.viewLog = (row: ILightDataTableRow): void => {
                if ($scope.options.onLogBtnClick) {
                    $scope.options.onLogBtnClick.call(this, row._index, row);
                }
            }

            $scope.getEmptySelectorMsg = (): string => {
                return $scope.emptySelectorMsg;
            }
        }],
    }

    return ddo;
}
import { SSEService } from "@appServices/SSEService";
import { IGridFormServiceScope, GridFormService } from '@services/GridFormService';
import { IMonacoColumnDef } from '@services/GridService2';
import { IModalService } from "@services/ModalService";
import { DataProcessService } from "@services/DataProcessService";
import { EOperation } from "@enums/GenericData";
import { IConsolidatedProcess } from "WBA-Model/dist/interface/operational/consolidated/ConsolidatedProcess";
import { IConsolidatedLog } from 'WBA-Model/dist/interface/operational/consolidated/ConsolidatedLog';
import { IConsolidatedWizardModalScope } from "./ConsolidatedWizardModalController";
import { IModalInstanceService } from "angular-ui-bootstrap";
import { ISessionService } from "@services/SessionService";
import { IProcessDocumentParameter, IProcessParameter } from "src/ts/common/model/ModelParameter";

export interface IConsolidatedProcessWizardPanelScope extends IGridFormServiceScope {
    // Grid Consolidated Process
    gridVisibleStatus: boolean;
    model: IConsolidatedProcess;
    // Log
    logList: IConsolidatedLog[];
    consolidatedScope: IConsolidatedWizardModalScope;

    addProcess: Function;
    removeProcess: Function;
    disabledConsolidated: Function;
    goToProcess: Function;
    goToProcessDocument: Function;

    sessionService: ISessionService;

    // Modal Actions
    openModalAddProcess: () => Promise<void>
    openModalDisplayTariff: () => Promise<void>;
}

export class ConsolidatedProcessWizardPanelController extends GridFormService {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IConsolidatedProcessWizardPanelScope;
    private $consolidatedScope: IConsolidatedWizardModalScope;
    private SSEService: SSEService;
    private ModalService: IModalService;
    private DataProcessService: DataProcessService;
    private gridName: string;

    constructor($injector: ng.Injectable<any>, $scope: IConsolidatedProcessWizardPanelScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$consolidatedScope = <IConsolidatedWizardModalScope>$scope.$parent.$parent;
        this.ModalService = $injector.get('ModalService');
        this.DataProcessService = $injector.get('DataProcessService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.gridName = 'GRID_CONSOLIDATED_PROCESS_WIZARD';
        this.$baseUrl = this.DataProcessService.$route;
        this.SSEService = new SSEService($injector, $scope, this.formService);
    }

    async $onInit(): Promise<void> {
        try {
            this.gridService.orderMenuItems = false;
            await this.gridService.initGridStandAlone(this.gridName, `/consolidatedProcess/list/${this.$consolidatedScope.model.ID}`, this.buildColumns, true, true, 30000, true, true);
            this.gridService.$gridOptions.valueHAWB = this.$consolidatedScope.model.CARGO ? this.$consolidatedScope.model.CARGO.VALUE_HAWB : 0;
            this.gridService.$gridOptions.enableHAWB = true;
            this.$scope.addProcess = this.addProcess;
            this.$scope.removeProcess = this.removeProcess;
            this.$scope.goToProcess = this.goToProcess;
            this.$scope.goToProcessDocument = this.goToProcessDocument;
            this.$scope.disabledConsolidated = this.$consolidatedScope.disabledConsolidated;
            this.$consolidatedScope.amountProcess = this.$gridService.$gridOptions.totalItems;
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initModel(): void {
        try {
            this.$scope.model = {
                _id: null,
                ID_PROCESS: null,
                ID_CONSOLIDATED: null,
                PROCESS_NUMBER: null,
                CREATED_AT: null,
                CREATED_BY: null,
                UPDATED_AT: null,
                UPDATED_BY: null
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void { }

    buildColumns = (): IMonacoColumnDef[] => {
        const iconSize: number = 70;

        const columnsAvailable = [
            {
                title: "OPERATIONAL.FILE_NUMBER", visibility: true, width: 150, name: "PROCESS.PROCESS_NUMBER",
                cellTemplate: `<span uib-tooltip="{{row.entity.PROCESS.PROCESS_NUMBER}}" class="grid-padding ellipsize" tooltip-placement="auto top" style="max-width: 100%;" 
                tooltip-enable="true" ellipsis-tooltip><u><a ng-click="grid.appScope.goToProcess(row.entity.PROCESS.PROCESS_NUMBER)">{{row.entity.PROCESS.PROCESS_NUMBER}}</a></u></span>`
            },
            { title: "BASIC_DATA.CLIENT", visibility: true, width: 150, name: "PROCESS.CUSTOMER.NAME", content: 'row.entity.PROCESS.CUSTOMER.NAME' },
            { title: "BASIC_DATA.SHIPPER", visibility: true, width: 150, name: "PROCESS.EXPORTER.NAME", content: 'row.entity.PROCESS.EXPORTER.NAME' },
            {
                title: "FINANCIAL.HOUSE", visibility: true, width: 150, name: "PROCESS_DOCUMENT_HOUSE.DOCUMENT",
                cellTemplate: `<span uib-tooltip="{{row.entity.PROCESS_DOCUMENT_HOUSE.DOCUMENT}}" class="grid-padding ellipsize" tooltip-placement="auto top" style="max-width: 100%;" 
                tooltip-enable="true" ellipsis-tooltip><u><a ng-click="grid.appScope.goToProcessDocument(row.entity.PROCESS_DOCUMENT_HOUSE.DOCUMENT)">{{row.entity.PROCESS_DOCUMENT_HOUSE.DOCUMENT}}</a></u></span>`
            },
            { title: "GENERAL.UNIT_VOLUME", visibility: true, width: 150, name: "PROCESS.CARGO_DETAIL.VOLUME", content: 'row.entity.PROCESS.CARGO_DETAIL.VOLUME' },
            { title: "OPERATIONAL.GROSS_WEIGHT", visibility: true, width: 150, name: "PROCESS.CARGO_DETAIL.GROSS_WEIGHT", content: 'row.entity.PROCESS.CARGO_DETAIL.GROSS_WEIGHT | number: 3' },
            { title: "GENERAL.CUBIC_METER", visibility: true, width: 150, name: "PROCESS.CARGO_DETAIL.CBM", content: 'row.entity.PROCESS.CARGO_DETAIL.CBM | number: 3' },
            { title: "GENERAL.CHARGEABLE_WEIGHT", visibility: true, width: 150, name: "PROCESS.CARGO_DETAIL.QUANTITY", content: 'row.entity.PROCESS.CARGO_DETAIL.QUANTITY | number: 4' },
            { title: "FINANCIAL.PLACE_OF_RECEIPT", visibility: true, width: 150, name: "EVENT_PLACE_OF_RECEIPT.EFFECTIVE_DATE", content: 'row.entity.EVENT_PLACE_OF_RECEIPT.EFFECTIVE_DATE | datetimeformated' },
            { title: "FINANCIAL.APPROVAL_DATE", visibility: true, width: 150, name: "PROCESS_DOCUMENT_HOUSE.CLIENT_APPROVAL", content: 'row.entity.PROCESS_DOCUMENT_HOUSE.CLIENT_APPROVAL | datetimeformated' },
            { title: "PRODUCT.SELLING", visibility: true, width: 150, name: "CHARGE_AIR_FREIGHT_SELL.INCOME.UNITARY", content: 'row.entity.CHARGE_AIR_FREIGHT_SELL.INCOME.UNITARY | number:2' },
        ];

        const builtColumns = columnsAvailable.map(columnAvailable => {
            const column: IMonacoColumnDef = {
                headerCellClass: this.gridService.highlightFilteredHeader.bind(this.gridService),
                filter: { condition: this.gridService.filterSelectObject },
                displayName: columnAvailable.title,
                width: columnAvailable.width,
                name: columnAvailable.name,
                filterCellFiltered: true,
                enableFiltering: true,
                enableSorting: false,
                enableHiding: true,
                pinnedLeft: true,
                enablePinning: false,
                enableColumnMoving: true,
                enableColumnResizing: true,
                visible: columnAvailable.visibility,
                cellTemplate: columnAvailable.cellTemplate
            };

            if (columnAvailable.content) {
                column.cellTemplate = `<span uib-tooltip="{{${columnAvailable.content}}}"
                                             class="grid-padding ellipsize"
                                             tooltip-placement="auto top"
                                             style="max-width: 100%;"
                                             tooltip-enable="true"
                                             ellipsis-tooltip
                                            >{{${columnAvailable.content}}}</span>`;
            }

            return column;
        });

        builtColumns.unshift({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: iconSize,
            cellTemplate: `<div class="text-center"><a ng-click="!grid.appScope.disabledConsolidated() && grid.appScope.removeProcess(row.entity.ID_CONSOLIDATED, row.entity.ID_PROCESS, row.entity.PROCESS_NUMBER)" ng-disabled="grid.appScope.disabledConsolidated()" class="text-danger" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.REMOVE_LINE' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-remove icon"></i></a>&nbsp;&nbsp;`,
            filterHeaderTemplate: `<div class="text-center"><a ng-click="!grid.appScope.disabledConsolidated() && grid.appScope.addProcess(row.entity)" ng-disabled="grid.appScope.disabledConsolidated()" class="text-success" tooltip-placement="auto top" uib-tooltip="{{ 'REGISTRATION.ADD' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-plus icon"></i></a>`,
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: true,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        })

        return builtColumns;
    }

    addProcess = async () => {
        try {

            const hasInvalid = this.$consolidatedScope.hasInvalidRequiredElements("consolidatedWizardModalForm");
            if (hasInvalid) return;

            const modalID = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    template: require("../view/wizard/consolidated/consolidatedProcessRegisterModal.html"),
                    modalID: modalID,
                    formService: EOperation.NEW,
                    scope: this.$consolidatedScope,
                    size: 'md',
                },
                {
                    actionButtonClass: 'btn-default',
                    headerText: "GENERAL.ADD",
                    closeButtonText: 'GENERAL.CLOSE'
                },
                {
                    consolidatedProcessListModalId: modalID
                }
            );

            const modalScope = await this.ModalService.getModalScope(modalID);
            await modalScope.$applyAsync();

            const apply = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (apply) {
                this.externalSearch = true;
                await this.updateGrid();
                this.$consolidatedScope.amountProcess = this.$gridService.$gridOptions.totalItems;
            }

            this.$consolidatedScope.model = await this.$consolidatedScope.getConsolidatedById(this.$consolidatedScope.model.ID);
            this.gridService.$gridOptions.valueHAWB = this.$consolidatedScope.model.CARGO ? this.$consolidatedScope.model.CARGO.VALUE_HAWB : 0;
            this.$consolidatedScope.$apply();

        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }


    removeProcess = async (consolidatedId: number, idProcess: string, processNumber: string) => {
        try {
            this.formService.block();
            const responde = await this.DataProcessService.post(`/consolidatedProcess/remove`, { id: consolidatedId, idProcess, processNumber });
            if (responde && responde.data && responde.data.data) {
                const msg = this.formService.getTranslate('GENERAL.REGISTER_DELETED_SUCCESSFULLY');
                this.formService.notifySuccess(msg);
                this.externalSearch = true;
                await this.updateGrid();
                this.$consolidatedScope.amountProcess = this.$gridService.$gridOptions.totalItems;
                this.$consolidatedScope.model = await this.$consolidatedScope.getConsolidatedById(this.$consolidatedScope.model.ID);
                this.gridService.$gridOptions.valueHAWB = this.$consolidatedScope.model.CARGO ? this.$consolidatedScope.model.CARGO.VALUE_HAWB : 0;
                this.$consolidatedScope.$apply();
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    goToProcess = async (process: string) => {
        this.$scope.sessionService.openTab('app.operational.newProcess.list', <IProcessParameter>{ PROCESS_NUMBER: process });
    }

    goToProcessDocument = async (document: string) => {
        this.$scope.sessionService.openTab('app.operational.process.processDocument', <IProcessDocumentParameter>{ DOCUMENT: document });
    }
} 

export function btnActiveDirective() {
  let ddo: any = {};
  ddo.restrict = "E";
  ddo.transclude = true;

  ddo.replace = true;
  ddo.$scope = true;
  ddo.template = require("../view/template/btn-active.html");

  ddo.scope = {
    value: "=ngModel" || true,
    disabled: "=ngDisabled"
  };

  ddo.link = function ($scope) {
    $scope.$watch('value', function (newValue) {
      if ($scope.$parent.$parent.btnActiveModel !== undefined) $scope.$parent.$parent.btnActiveModel = newValue;
    }, true);
  }
  return ddo;
}
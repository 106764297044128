import * as angular from "angular";
import { IRestService } from "@services/RestService";
import { ChargePair } from "@models/interface/finop/ChargePair";
import { ISessionService } from "@services/SessionService";
import { IChargeParameter, IInvoiceParameter } from "../../common/model/ModelParameter";
import { Process } from "@models/interface/operational/Process";
import { HandleError } from "../../common/util/HandleError";
import { noBilling } from "@models/interface/finop/Invoice";

interface IProcessChargeCustomInfo extends ChargePair {
    IDC?: number,
    exchangeDifference?: number,
    monetaryDifference?: number,
    valueTotalDifference?: number,
}

interface IProcessChargeScope extends ng.IScope {
    operation: string;
    chargeInfo: IProcessChargeCustomInfo[];
    analiticsTotal: number;
    summaryTotal: number;
    isProcessForwardingDone: boolean;
    sortingOrder: object;
    reverse: object;
    htmlPopover: string;
    showPopover: boolean;
    updateAndToogleChargeList: () => void;
    goToCharge: (chargepair: ChargePair) => void;
    goToInvoice: (invoiceNumber: string) => void;
    sortBy: (propToSort: string, ident: string) => void;
}

export class ProcessChargeController {
    static $inject: string[] = ['$injector', '$scope', '$sce'];
    private $scope: IProcessChargeScope;
    private $q: ng.IQService;
    private $state: ng.ui.IStateService;
    private blockUI: ng.blockUI.BlockUIService;
    private $filter: ng.FilterFactory;
    private $timeout: ng.ITimeoutService;
    private RestService: IRestService;
    private sessionService: ISessionService;
    private SCEService: ng.ISCEService;
    private process: Process;
    private panelToggle: boolean;
    private summary99: { TOTAL?: number };

    constructor($injector: ng.Injectable<any>, $scope: IProcessChargeScope) {
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$state = $injector.get('$state');
        this.blockUI = $injector.get('blockUI');
        this.$filter = $injector.get('$filter');
        this.$timeout = $injector.get('$timeout');
        this.SCEService = $injector.get('$sce');
        this.RestService = $injector.get('RestService');
        this.sessionService = $injector.get('SessionService');

        this.process = null;
        this.panelToggle = false;
        this.summary99 = null;
    }

    async $onInit() {
        try {
            const start = Date.now();

            this.process = null;
            this.$scope.reverse = {};
            this.$scope.sortingOrder = {};
            this.$scope.isProcessForwardingDone = false;
            this.$scope.operation = this.$state.params.operation;

            this.initScopeFunctions();
            await this.initDependencies();

            this.registerOrderBy(this.$scope.chargeInfo, 'valueTotalDifference', 'charges');

            const loadTime = ((Date.now() - start) - angular.copy(this.$scope.$parent.$parent['loadTime']));
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    async initDependencies(): Promise<boolean> {
        try {
            //get process info from parent controller (process view)
            if (!this.process) {
                await this.$scope.$parent.$parent['loaded'].promise;
                this.process = (this.$scope.$parent.$parent['process']) ? this.$scope.$parent.$parent['process'] : null;

                const chargePairs = (this.process && this.process.CHARGE) ? this.process.CHARGE : [];
                this.summary99 = (this.process.FINANCIAL_SUMMARY) ? this.process.FINANCIAL_SUMMARY.find(item => (!item.CURRENCY) && item.STATUS.ID == '99') : {};
                if (chargePairs) this.$scope.chargeInfo = await this.calculateChargeAnalitycs(chargePairs);

                this.$scope.isProcessForwardingDone = (this.process.FORWARDING_SITUATION && this.process.FORWARDING_SITUATION.SITUATION && this.process.FORWARDING_SITUATION.SITUATION.ID == "5");
                this.$scope.operation = this.$scope.$parent.$parent['operation'];
            }
            if (!this.process) throw new Error(`${this.constructor.name}: Failed to load controller dependencies, process info is NULL`);

            return true;
        } catch (ex) {
            throw ex;
        }
    }

    initScopeFunctions(): void {
        this.$scope.goToCharge = (chargepair: ChargePair) => {
            this.sessionService.openTab('app.finop.charge.register', <IChargeParameter>{ PROCESS_NUMBER: this.process.PROCESS_NUMBER, /*CHARGE_NUMBER: `${c.OUTCOME.CHARGE_NUMBER};${c.INCOME.CHARGE_NUMBER}`,*/['CHARGE.NAME']: chargepair.CHARGE.NAME });
        }
        this.$scope.goToInvoice = (invoiceNumber: string) => {
            this.sessionService.openTab('app.finop.invoice.register', <IInvoiceParameter>{ INVOICE_NUMBER: invoiceNumber });
        }
        this.$scope.updateAndToogleChargeList = () => {
            return this.updateAndToogleChargeList();
        }
        this.$scope.sortBy = (propToSort, ident) => {
            return this.sortBy(propToSort, ident);
        }
    }

    private getChargePair(processNumber: string): Promise<any> {
        if (!processNumber) throw 'Número do processo não informado.';
        return this.RestService.getObjectAsPromise(`/financial/charge/pair/${processNumber}`, 10000);
    }
    private getFinancialSummary(processNumber: string): Promise<any> {
        if (!processNumber) throw 'Número do processo não informado.';
        return this.RestService.getObjectAsPromise(`/financial/summary/${processNumber}`, 10000);
    }

    private async updateAndToogleChargeList(): Promise<void> {
        try {
            if (this.panelToggle) {
                $("#chargeList").toggle(20);
                this.panelToggle = !this.panelToggle;
                return;
            }
            this.block();
            let updatedChargePair: ChargePair[] = [];
            const result = await this.getChargePair(this.process.PROCESS_NUMBER);
            if (result && result.data) updatedChargePair = result.data;

            const updatedFinancialSummary = await this.getFinancialSummary(this.process.PROCESS_NUMBER);
            if (updatedFinancialSummary.data) {
                const summaryList = updatedFinancialSummary.data;
                this.summary99 = (summaryList.BRL_TOTAL['status_99']) ? summaryList.BRL_TOTAL['status_99'] : {}
            }

            if (updatedChargePair && updatedChargePair.length != 0)
                this.$scope.chargeInfo = await this.calculateChargeAnalitycs(updatedChargePair);

            $("#chargeList").toggle(20);
            this.panelToggle = !this.panelToggle;
            this.unblock();
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    private async calculateChargeAnalitycs(chargePairs: ChargePair[]): Promise<IProcessChargeCustomInfo[]> {
        try {
            const analyticData = chargePairs.map(cp => {
                const customInfo: IProcessChargeCustomInfo = Object.assign({}, cp);

                // IMPORTANT! we're not big fans of fixing/rounding values, but what we see on screen needs to correspond to the totals..
                // disconsider TOTAL_BRL in case of noBilling charges as we cant trust it's converstion factor to be the most recent
                const incomeTotalBRL = (!cp.INCOME.INVOICE_NUMBER || cp.INCOME.INVOICE_NUMBER.ID === noBilling.ID) ? 0 : Number(cp.INCOME.TOTAL_BRL.toFixed(2));
                const outcomeTotalBRL = (!cp.OUTCOME.INVOICE_NUMBER || cp.OUTCOME.INVOICE_NUMBER.ID === noBilling.ID) ? 0 : Number(cp.OUTCOME.TOTAL_BRL.toFixed(2));
                const total = (incomeTotalBRL - outcomeTotalBRL);
                customInfo.valueTotalDifference = !isNaN(total) ? total : 0;

                // if charge pair is marked with no-billing do not calculate analitic info
                if (!cp.INCOME.INVOICE_NUMBER || cp.INCOME.INVOICE_NUMBER.ID === noBilling.ID ||
                    !cp.OUTCOME.INVOICE_NUMBER || cp.OUTCOME.INVOICE_NUMBER.ID === noBilling.ID) return customInfo;

                const idc = (((cp.INCOME.CONVERSION_SPREAD / cp.OUTCOME.CONVERSION_SPREAD) - 1) * 100);
                if (cp.INCOME.CONVERSION_SPREAD && cp.OUTCOME.CONVERSION_SPREAD && !isNaN(idc)) customInfo.IDC = idc;

                const exch = (cp.INCOME.CONVERSION_SPREAD - cp.OUTCOME.CONVERSION_SPREAD);
                if (cp.INCOME.CONVERSION_SPREAD && cp.OUTCOME.CONVERSION_SPREAD && !isNaN(exch)) {
                    customInfo.exchangeDifference = exch;

                    const monetary = (Number(cp.OUTCOME.TOTAL.toFixed(2)) * customInfo.exchangeDifference);
                    if (!isNaN(monetary)) customInfo.monetaryDifference = monetary;
                }

                return customInfo;
            });
            this.$scope.analiticsTotal = analyticData.reduce((total, currValues) => total + currValues.valueTotalDifference, 0);
            this.$scope.showPopover = (this.$scope.analiticsTotal != this.$scope.summaryTotal);
            this.$scope.htmlPopover = this.SCEService.trustAsHtml(`O total dos valores da coluna <div class="label label-info">#taxa (BRL)</div> é <i>${this.$filter('number')(this.$scope.analiticsTotal, 2)} BRL</i>, divergindo do valor apresentado no Resumo Financeiro por motivos de arredondamento.`);

            this.$scope.summaryTotal = (this.summary99 && this.summary99.TOTAL) ? this.summary99.TOTAL : 0;

            await this.$scope.$applyAsync();

            return analyticData;
        } catch (ex) {
            throw ex;
        }
    }

    private registerOrderBy(items: object[], propToSort: string, ident: string) {
        try {
            this.$scope.sortingOrder[ident] = propToSort;
            this.$scope.reverse[ident] = false;
            this.$filter('orderBy')(items, this.$scope.sortingOrder, this.$scope.reverse);
        } catch (ex) {
            throw ex;
        }
    }

    private sortBy(propToSort: string, ident: string) {
        try {
            if (this.$scope.sortingOrder[ident] == propToSort)
                this.$scope.reverse[ident] = !this.$scope.reverse[ident];
            this.$scope.sortingOrder[ident] = propToSort;
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    private block(): void {
        this.blockUI.start();
    };

    private unblock(): void {
        const self: ProcessChargeController = this;
        this.$timeout(function () {
            self.blockUI.stop();
        });
    };

}
import * as angular from "angular";
import * as moment from 'moment';
import { SSEService } from "@appServices/SSEService";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IModalService } from "@services/ModalService";
import { ISessionService } from "@services/SessionService";
import { IRoutingPointListCustomFilter } from "@models/interface/product/RoutingPointModel";
import { EProviderTypeId, EOperation } from "@enums/GenericData";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IAirServiceModel, IAirServiceMain, IAirServiceOrigin, IAirServiceDestination } from "@models/interface/product/AirServiceModel";
import { IRoutingPointSelector } from '@models/interface/product/RoutingPoint';
import { SelectorModel } from "../../common/model/SelectorModel";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { IFloatingMenu } from "../../common/interface/IFloatingMenu";
import { ILinkParameter } from "../../common/model/ModelParameter";
import { BrowserTitle } from "../../common/BrowserTitle";
import { IDocumentError } from "WBA-Model/dist/interface/common/IDocumentError";
import { HelperService } from "@services/HelperService";

interface IOriginDestinationTableColStyle {
    originType: Object;
    origin: Object;
    destinationType: Object;
    destination: Object;
    modal: Object;
    frequency: Object;
    ttMin: Object;
    ttMax: Object;
}

interface IAirServiceScope extends IGridFormServiceScope {
    gridOptions: uiGrid.IGridOptions;
    model: IAirServiceModel;
    log: IViewLog;
    productList: SelectorModel[];
    frequencyList: SelectorModel[];
    localList: SelectorModel[];
    providerList: SelectorModel[];
    modalList: SelectorModel[];
    originDestinationTypeList: SelectorModel[];
    terminalDoorTypeList: SelectorModel[];
    terminalAirportTypeList: SelectorModel[];
    weekdaysList: SelectorModel[];
    routingPointList: IRoutingPointSelector[];
    menuFloating: IFloatingMenu;
    originDestinationTableColStyle: IOriginDestinationTableColStyle;
    user: any;
    sessionService: ISessionService;
    editAirService: (airService: IAirServiceModel) => Promise<void>;
    viewAirService: (airService: IAirServiceModel) => Promise<void>;
    viewLogAirService: (airService: IAirServiceModel) => Promise<void>;
    copyAirService: (airService: IAirServiceModel) => Promise<void>;
    addMainService: () => void;
    removeMainService: (index: number) => void;
    addOrigin: (typeId?: string) => void;
    removeOrigin: (index: number) => void;
    addDestination: (panelId: number, typeId?: string) => void;
    removeDestination: (index: number) => void;
    isAirport: (type: SelectorModel) => boolean;
    isTerminalDoor: (type: SelectorModel) => boolean;
    getProviderListByName: (search: string) => Promise<void>;
    getShipListByName: (search: string) => Promise<SelectorModel[]>;
    getRoutingPointListByName: (search: string, type: SelectorModel) => Promise<void>;
    collapseHeader: (elementId: string, state?: string) => void;
    goToProvider: (id: number) => void;
    goToRoutingPoint: (routingPoint: SelectorModel) => void;
    productChange: () => void;
    originMoveOriginTypeChange: (index: number) => void;
    originMoveDestinationTypeChange: (index: number) => void;
    destinationMoveOriginTypeChange: (index: number) => void;
    destinationMoveDestinationTypeChange: (index: number) => void;
    originTerminalDoorTypeFilter: (index: number) => Function;
    originTerminalAirportTypeFilter: (index: number) => Function;
    destinationTerminalDoorTypeFilter: (index: number) => Function;
    destinationTerminalAirportTypeFilter: (index: number) => Function;
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IAirServiceModel;
    fetchData: (id: number, action: string) => Promise<void>;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
}

export class AirServiceRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IAirServiceScope;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private RestService: IRestService;
    private ModalService: IModalService;
    private modalID: number;
    private collapseState: string;
    private gridName: string;
    private formName: string;
    private SSEService: SSEService;
    private sessionService: ISessionService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IAirServiceScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');
        this.collapseState = "hide";
        this.$scope.weekdaysList = [];
        this.$scope.sessionService = $injector.get('SessionService');
        this.gridName = 'GRID_AIR_SERVICE';
        this.formName = 'airService';
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.helperService = $injector.get('HelperService');
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    private getMenuFloatingDefault(): IFloatingMenu {
        return {
            tooltipPlacement: "auto bottom",
            textTooltip: "PRODUCT.AIR_SERVICE_DATA",
            infos: [
                { text: "PRODUCT.AIR_SERVICE", class: "text-rouge font-bold" }
            ],
            options: [
                { click: "collapseHeader", args: ['collapseBasicData'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.BASIC_DATA", iconClass: "fa fa-address-card", iconBodyClass: "text-brown" },
                { click: "collapseHeader", args: ['collapseMainService'], tooltipPlacement: "auto bottom", textTooltip: "PRODUCT.MAIN_SERVICE", iconClass: "fa fa-gear", iconBodyClass: "text-rouge" },
                { click: "collapseHeader", args: ['collapseOriginMovement'], tooltipPlacement: "auto bottom", textTooltip: "ROUTE.ORIGIN_MOVE", iconClass: "fa fa-mail-reply", iconBodyClass: "text-cyano" },
                { click: "collapseHeader", args: ['collapseDestinationMovement'], tooltipPlacement: "auto bottom", textTooltip: "ROUTE.DESTINATION_MOVE", iconClass: "fa fa-mail-forward", iconBodyClass: "text-yellow" },
                { click: "collapseHeader", args: ['collapseAll'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.COLLAPSE_EXPAND_ALL", iconClass: "fa fa-expand", iconBodyClass: "text-danger" }
            ],
            btnActiveDisabled: false
        };
    }

    private initPanels(open: string[] = ['collapseBasicData']): void {
        try {
            const panels = document.getElementsByClassName("toggle-me");
            if (panels && panels.length > 0) {
                for (const item of open) {
                    $("#" + item)["collapse"]("show");
                }
                for (let i = 0; i < panels.length; i++) {
                    const panel = panels[i];
                    if (open.indexOf(panel.id) === -1) {
                        $("#" + panel.id)["collapse"]("hide");
                    }
                }
            }
            this.$scope.navigateBetweenIds(open[0]);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private collapseHeader(elementId: string, state?: string): void {
        try {
            if (elementId === "collapseAll" || elementId[0] === "collapseAll") {
                this.collapseState = this.collapseState == "hide" ? "show" : "hide";
                $('.toggle-me')["collapse"](state ? state : this.collapseState);
            } else if (elementId != "registerBody") {
                $("#" + elementId)["collapse"](state ? state : 'toggle');
            }
            this.$scope.navigateBetweenIds(elementId);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.menuFloating = this.getMenuFloatingDefault();

            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'airService', 'GENERAL.MENU.AIR_SERVICE', true);
            await this.initGrid(this.gridName, '/airService/list', true, true, null, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        try {
            this.$scope.editAirService = async (airService: IAirServiceModel): Promise<void> => {
                let blockedObject = {
                    ID: airService.ID,
                    NAME: airService.LOGISTIC_SOLUTION,
                    EMAIL: this.$scope.user['email'],
                    FORM_NAME: this.gridName
                };
                this.SSEService.closeEvents();
                this.SSEService.setBlockedObject(blockedObject);
                this.SSEService.initEvents();
                this.SSEService.events.onmessage = async (event) => {
                    const parsedData = JSON.parse(event.data);
                    if (!parsedData.status) {
                        const result = await this.SSEService.generate(parsedData);
                        if (result && !result.status) {
                            this.$rootScope.refreshPage();
                            return;
                        }
                        if (this.$scope.operation !== EOperation.VIEW || airService.ID !== this.$scope.model.ID) this.$scope.fetchData(airService.ID, EOperation.VIEW);
                    } else if (this.$scope.operation !== EOperation.EDIT || airService.ID !== this.$scope.model.ID) {
                        this.$scope.fetchData(airService.ID, EOperation.EDIT);
                    }
                };
            }

            this.$scope.viewAirService = async (airService: IAirServiceModel): Promise<void> => {
                this.SSEService.closeEvents();
                this.$scope.fetchData(airService.ID, EOperation.VIEW);
            }

            this.$scope.viewLogAirService = async (airService: IAirServiceModel): Promise<void> => {
                this.SSEService.closeEvents();
                this.$scope.viewLog(airService);
            }

            this.$scope.copyAirService = async (airService: IAirServiceModel): Promise<void> => {
                this.SSEService.closeEvents();
                this.$scope.fetchData(airService.ID, EOperation.COPY);
            }
            this.$scope.addMainService = (): void => {
                this.addMainService();
            }

            this.$scope.removeMainService = (index: number): void => {
                this.removeMainService(index);
            }

            this.$scope.addOrigin = (typeId?: string): void => {
                this.addOrigin(typeId);
            }

            this.$scope.removeOrigin = (index: number): void => {
                this.removeOrigin(index);
            }

            this.$scope.addDestination = (panelId: number, typeId?: string): void => {
                this.addDestination(panelId, typeId);
            }

            this.$scope.removeDestination = (index: number): void => {
                this.removeDestination(index);
            }

            this.$scope.isAirport = (type: SelectorModel): boolean => {
                return type && type.ID == "4";
            }

            this.$scope.isTerminalDoor = (type: SelectorModel): boolean => {
                return !type || type && (type.ID == "3" || type.ID == "5");
            }

            this.$scope.collapseHeader = (elementId: string, state?: string): void => {
                this.collapseHeader(elementId, state);
            }

            this.$scope.getProviderListByName = async (search: string): Promise<void> => {
                return await this.getProviderListByName(search);
            }

            this.$scope.getRoutingPointListByName = async (search: string, type: SelectorModel): Promise<void> => {
                return await this.getRoutingPointListByName({ name: search, types: type ? [type.ID] : null });
            }

            this.$scope.collapseHeader = (elementId: string, state?: string): void => {
                this.collapseHeader(elementId, state);
            }

            this.$scope.goToProvider = (id: number): void => {
                this.goToProvider(id);
            }

            this.$scope.goToRoutingPoint = (routingPoint: SelectorModel): void => {
                this.sessionService.openTab("app.registration.routingPoint", <ILinkParameter>{ ID: routingPoint ? routingPoint.ID.toString() : routingPoint });
            }

            this.$scope.productChange = (): void => {
                this.productChange();
            }

            this.$scope.originMoveOriginTypeChange = (index: number): void => {
                this.originMoveOriginTypeChange(index);
            }

            this.$scope.originMoveDestinationTypeChange = (index: number): void => {
                this.originMoveDestinationTypeChange(index);
            }

            this.$scope.destinationMoveOriginTypeChange = (index: number): void => {
                this.destinationMoveOriginTypeChange(index);
            }

            this.$scope.destinationMoveDestinationTypeChange = (index: number): void => {
                this.destinationMoveDestinationTypeChange(index);
            }

            this.$scope.originTerminalDoorTypeFilter = (index: number): Function => {
                return this.originTerminalDoorTypeFilter(index);
            }

            this.$scope.originTerminalAirportTypeFilter = (index: number): Function => {
                return this.originTerminalAirportTypeFilter(index);
            }

            this.$scope.destinationTerminalDoorTypeFilter = (index: number): Function => {
                return this.destinationTerminalDoorTypeFilter(index);
            }

            this.$scope.destinationTerminalAirportTypeFilter = (index: number): Function => {
                return this.destinationTerminalAirportTypeFilter(index);
            }

            this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
                this.fetchData(id, action);
            }

            this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
                this.openModalIntegration(id, documentError);
            }

        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewAirService(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editAirService(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogAirService(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.setCopy(true);grid.appScope.copyAirService(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy + modalIntegration),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colLogisticSolution: IMonacoColumnDef = { name: "LOGISTIC_SOLUTION", displayName: "BASIC_DATA.SERVICE", width: 150 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.NAME", displayName: "BASIC_DATA.PRODUCT", width: 150 };
            const colTradeOrigin: IMonacoColumnDef = { name: "TRADE_ORIGIN.NAME", displayName: "BASIC_DATA.ORIGIN_TRADE_GROUP", width: 400, cellTemplate: "<div class='grid-padding' >{{grid.appScope.getCONCAT(row.entity.TRADE_ORIGIN)}}</div>" };
            const colTradeDestination: IMonacoColumnDef = { name: "TRADE_DESTINATION.NAME", displayName: "BASIC_DATA.DESTINATION_TRADE_GROUP", width: 400, cellTemplate: "<div class='grid-padding' >{{grid.appScope.getCONCAT(row.entity.TRADE_DESTINATION)}}</div>" };
            const colProvider: IMonacoColumnDef = { name: "PROVIDER.NAME", displayName: "BASIC_DATA.PROVIDER", width: 150, cellTemplate: '<div class="grid-padding" >{{row.entity.PROVIDER.CODE}}</div>' };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 70, cellFilter: "YesOrNo" };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'LOGISTIC_SOLUTION':
                        columnDefs.push(colLogisticSolution);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'TRADE_ORIGIN':
                        columnDefs.push(colTradeOrigin);
                        break;
                    case 'TRADE_DESTINATION':
                        columnDefs.push(colTradeDestination);
                        break;
                    case 'PROVIDER':
                        columnDefs.push(colProvider);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        try {
            this.$scope.model = {
                _id: null,
                ID: null,
                LOGISTIC_SOLUTION: null,
                PRODUCT: null,
                PROVIDER: null,
                ID_PROVIDER: null,
                TRADE_DESTINATION: null,
                TRADE_ORIGIN: null,
                MAIN: null,
                ORIGIN: null,
                DESTINATION: null,
                ACTIVE: true,
                CREATED_AT: null,
                CREATED_BY: null,
                UPDATED_AT: null,
                UPDATED_BY: null,
                SEARCH_FIELDS: null,
                DOCUMENT_ERROR: null
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initDependencies(): Promise<any> {
        try {
            const self: AirServiceRegisterController = this;

            this.$scope.weekdaysList = this.weekDaysList();

            return new Promise(function (resolve, reject) {
                self.initEvents();
                self.setOriginDestinationTableColStyle();
                self.$q.all([
                    self.getProductList(),
                    self.getLocalList(),
                    self.getModalList(),
                    self.getOriginDestinationTypeList()
                ]).then((result: any) => {
                    self.$scope.productList = result[0] && result[0].length ? result[0].filter(obj => obj.ID == "EA" || obj.ID == "IA") : [];
                    self.$scope.localList = result[1];
                    self.$scope.modalList = result[2];
                    self.$scope.originDestinationTypeList = result[3];
                    self.$scope.terminalDoorTypeList = result[3].data && result[3].data.length ? result[3].data.filter(obj => obj.ID == "3" || obj.ID == "5") : [];
                    self.$scope.terminalAirportTypeList = result[3].data && result[3].data.length ? result[3].data.filter(obj => obj.ID == "4" || obj.ID == "5") : [];
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getAirService(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/airService/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const airData = await this.getAirService(id);
                    if (row && airData && airData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = airData.DOCUMENT_ERROR;
                        documentError = airData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "Integration Product/Operation" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number): Promise<boolean> => {
        let sendSync = false;
        let success = false;
        try {
            if (id) {
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/airService/sendSync`, { "idAirService": id }, 120000, false);
                if (syncRequest) sendSync = true;

                success = sendSync;
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_SENDING_REQUEST');
        } finally {
            return success;
        }
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getLocalList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/locals_group`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getModalList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/modal`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getOriginDestinationTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_local_event`, null);
        return generic && generic.data ? generic.data : [];
    }

    private initEvents(): void {
        angular.element(window).on('resize', () => {
            this.setOriginDestinationTableColStyle();
        });
    }

    private setOriginDestinationTableColStyle(): void {
        const originDestinationTableColStyle: IOriginDestinationTableColStyle = { originType: null, origin: null, destinationType: null, destination: null, modal: null, frequency: null, ttMin: null, ttMax: null };
        const winWidth = angular.element(window).width();
        if (winWidth >= 1600) {
            originDestinationTableColStyle.originType = { "width": "10%" };
            originDestinationTableColStyle.origin = { "width": "16%" };
            originDestinationTableColStyle.destinationType = { "width": "10%" };
            originDestinationTableColStyle.destination = { "width": "16%" };
            originDestinationTableColStyle.modal = { "width": "12%" };
            originDestinationTableColStyle.frequency = { "width": "18%" };
            originDestinationTableColStyle.ttMin = { "width": "9%" };
            originDestinationTableColStyle.ttMax = { "width": "9%" };
        } else if (winWidth >= 1280) {
            originDestinationTableColStyle.originType = { "width": "10%" };
            originDestinationTableColStyle.origin = { "width": "14%" };
            originDestinationTableColStyle.destinationType = { "width": "10%" };
            originDestinationTableColStyle.destination = { "width": "14%" };
            originDestinationTableColStyle.modal = { "width": "10%" };
            originDestinationTableColStyle.frequency = { "width": "24%" };
            originDestinationTableColStyle.ttMin = { "width": "9%" };
            originDestinationTableColStyle.ttMax = { "width": "9%" };
        }
        this.$scope.originDestinationTableColStyle = originDestinationTableColStyle;
    }

    private async getRoutingPointListByName(filter: IRoutingPointListCustomFilter): Promise<void> {
        let result = [];
        try {
            if (!filter) throw Error('filter is null');
            if (filter && filter.name && filter.name.length >= 3) {
                if (!filter.types) throw Error('Select type first');
                this.formService.block();
                const routingPoints = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/routingPoint/list/custom/`, filter, 30000, false);
                result = routingPoints ? routingPoints.map((x) => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, DISPLAY_NAME: x.DISPLAY_NAME } }) : []
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.routingPointList = result;
            this.formService.unblock();
        }
    }

    private async getProviderListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 3) {
                if (!this.$scope.model.PRODUCT) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                this.formService.block();
                const providers = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/provider/list/custom`, { types: [EProviderTypeId.AIRLINE], products: [this.$scope.model.PRODUCT.ID], search: search }, 30000, false);
                result = providers ? providers.map(provider => { return { ID: provider.ID.toString(), NAME: provider.NAME, CODE: provider.SCAC_IATA } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.providerList = result;
            this.formService.unblock();
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.initPanels();
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.menuFloating.formOperation = this.$scope.formOperation;
            this.SSEService.closeEvents();
            this.$scope.menuFloating.infos = [{ text: "PRODUCT.AIR_SERVICE", class: "text-rouge font-bold" }];
            this.addMainService();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.disableElements(true);
            this.initPanels();
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.SSEService.closeEvents();
            this.$scope.menuFloating.infos = [{ text: this.$scope.model.LOGISTIC_SOLUTION, class: "text-rouge font-bold" }, { text: " - ", class: "font-bold" }];
            BrowserTitle.$id = this.$scope.model.LOGISTIC_SOLUTION;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.initPanels(['collapseBasicData', 'collapseMainService']);
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.menuFloating.infos = [{ text: ' - ' + this.$scope.model.LOGISTIC_SOLUTION, class: "text-rouge font-bold" }, { text: " - ", class: "font-bold" }];
            BrowserTitle.$id = this.$scope.model.LOGISTIC_SOLUTION;
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        let proceed: boolean = true;
        try {
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
            proceed = false;
        }
        return proceed;
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        try {
            const route = this.$scope.operation == 'register' ? 'insert' : 'update';
            return {
                route: `/airService/${route}`,
                data: angular.copy(this.$scope.model),
                oldData: angular.copy(this.$scope.scopeBeforeSave),
                timeout: 15000
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.initPanels();
            await this.clearFields(this.$scope.model);
            if (this.$scope.model.MAIN && this.$scope.model.MAIN.length > 0) {
                for (const mainService of this.$scope.model.MAIN) {
                    mainService.ID = null;
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private addMainService(): void {
        try {
            const airportType = this.$scope.originDestinationTypeList ? this.$scope.originDestinationTypeList.find(obj => obj.ID == "4") : null;
            const main: IAirServiceMain = {
                _id: null,
                ID: null,
                ORIGIN_TYPE: airportType,
                ROUTING_POINT_ORIGIN: null,
                DESTINATION_TYPE: airportType,
                ROUTING_POINT_DESTINATION: null,
                MODAL: null,
                FREQUENCY: null,
                TRANSIT_TIME_MIN: null,
                TRANSIT_TIME_MAX: null,
                ID_AIR_SERVICE: null,
                ID_ROUTING_POINT_ORIGIN: null,
                ID_ROUTING_POINT_DESTINATION: null
            }

            if (!this.$scope.model.MAIN) this.$scope.model.MAIN = [];
            this.$scope.model.MAIN.push(main);

            this.$timeout(() => {
                const currentIndex = this.$scope.model.MAIN.length - 1;
                this.$scope.selectorValidity('mainServiceOrigin' + currentIndex);
                this.$scope.selectorValidity('mainServiceDestination' + currentIndex);
                this.$scope.selectorValidity('mainServiceTransportModal' + currentIndex);
                this.$scope.selectorValidity('mainServiceFrequency' + currentIndex);
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeMainService(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.$scope.model.MAIN && this.$scope.model.MAIN.length > 0) {
                this.formService.block();
                this.$scope.model.MAIN.splice(index, 1);
                this.formService.unblock();
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private addOrigin(typeId?: string): void {
        try {
            const type = typeId && this.$scope.originDestinationTypeList ? this.$scope.originDestinationTypeList.find(obj => obj.ID == typeId) : null;
            const origin: IAirServiceOrigin = {
                _id: null,
                ID: null,
                ORIGIN_TYPE: type,
                ROUTING_POINT_ORIGIN: null,
                DESTINATION_TYPE: type,
                ROUTING_POINT_DESTINATION: null,
                MODAL: null,
                FREQUENCY: null,
                TRANSIT_TIME_MIN: null,
                TRANSIT_TIME_MAX: null,
                ID_AIR_SERVICE: null,
                ID_ROUTING_POINT_ORIGIN: null,
                ID_ROUTING_POINT_DESTINATION: null
            }

            if (!this.$scope.model.ORIGIN) this.$scope.model.ORIGIN = [];
            this.$scope.model.ORIGIN.push(origin);

            this.$timeout(() => {
                const currentIndex = this.$scope.model.ORIGIN.length - 1;
                if (typeId && typeId == "4") {
                    this.$scope.selectorValidity('originMovementAirportOrigin' + currentIndex);
                    this.$scope.selectorValidity('originMovementAirportDestination' + currentIndex);
                    this.$scope.selectorValidity('originMovementAirportTransportModal' + currentIndex);
                    this.$scope.selectorValidity('originMovementAirportFrequency' + currentIndex);
                } else {
                    this.$scope.selectorValidity('originMovementTerminalDoorOriginType' + currentIndex);
                    this.$scope.selectorValidity('originMovementTerminalDoorOrigin' + currentIndex);
                    this.$scope.selectorValidity('originMovementTerminalDoorDestinationType' + currentIndex);
                    this.$scope.selectorValidity('originMovementTerminalDoorDestination' + currentIndex);
                    this.$scope.selectorValidity('originMovementTerminalDoorTransportModal' + currentIndex);
                    this.$scope.selectorValidity('originMovementTerminalDoorFrequency' + currentIndex);
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeOrigin(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.$scope.model.ORIGIN && this.$scope.model.ORIGIN.length > 0) {
                this.formService.block();
                this.$scope.model.ORIGIN.splice(index, 1);
                this.formService.unblock();
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private addDestination(panelId: number, typeId?: string): void {
        try {
            const type = typeId && this.$scope.originDestinationTypeList ? this.$scope.originDestinationTypeList.find(obj => obj.ID == typeId) : null;
            const destination: IAirServiceDestination = {
                _id: null,
                ID: null,
                PANEL_CONTROL: panelId,
                ORIGIN_TYPE: type,
                ROUTING_POINT_ORIGIN: null,
                DESTINATION_TYPE: type,
                ROUTING_POINT_DESTINATION: null,
                MODAL: null,
                FREQUENCY: null,
                TRANSIT_TIME_MIN: null,
                TRANSIT_TIME_MAX: null,
                ID_AIR_SERVICE: null,
                ID_ROUTING_POINT_ORIGIN: null,
                ID_ROUTING_POINT_DESTINATION: null
            }

            if (!this.$scope.model.DESTINATION) this.$scope.model.DESTINATION = [];
            this.$scope.model.DESTINATION.push(destination);

            this.$timeout(() => {
                const currentIndex = this.$scope.model.DESTINATION.length - 1;
                if (typeId && typeId == "4") {
                    this.$scope.selectorValidity('destinationMovementAirportOrigin' + currentIndex);
                    this.$scope.selectorValidity('destinationMovementAirportDestination' + currentIndex);
                    this.$scope.selectorValidity('destinationMovementAirportTransportModal' + currentIndex);
                    this.$scope.selectorValidity('destinationMovementAirportFrequency' + currentIndex);
                } else {
                    this.$scope.selectorValidity('destinationMovementTerminalDoorOriginType' + currentIndex);
                    this.$scope.selectorValidity('destinationMovementTerminalDoorOrigin' + currentIndex);
                    this.$scope.selectorValidity('destinationMovementTerminalDoorDestinationType' + currentIndex);
                    this.$scope.selectorValidity('destinationMovementTerminalDoorDestination' + currentIndex);
                    this.$scope.selectorValidity('destinationMovementTerminalDoorTransportModal' + currentIndex);
                    this.$scope.selectorValidity('destinationMovementTerminalDoorFrequency' + currentIndex);
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeDestination(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.$scope.model.DESTINATION && this.$scope.model.DESTINATION.length > 0) {
                this.formService.block();
                this.$scope.model.DESTINATION.splice(index, 1);
                this.formService.unblock();
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private goToProvider(id: number): void {
        try {
            this.sessionService.openTab("app.registration.provider", <ILinkParameter>{ ID: id ? id.toString() : id });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private weekDaysList(): SelectorModel[] {
        const days: SelectorModel[] = [];
        try {
            for (let day = 1; day <= 7; day++) { // weekdays
                days.push({ ID: day.toString(), NAME: moment.weekdaysShort(day), CODE: moment.weekdaysShort(day) });
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            return days;
        }
    }

    private productChange(): void {
        this.$scope.model.PROVIDER = null;
        this.$timeout(() => { this.$scope.selectorValidity('provider'); });
    }

    private originMoveOriginTypeChange(index: number): void {
        if (!index && index != 0) throw Error('index is null');
        if (this.$scope.model.ORIGIN && this.$scope.model.ORIGIN.length > 0 && this.$scope.model.ORIGIN[index]) {
            this.$scope.model.ORIGIN[index].ROUTING_POINT_ORIGIN = null;
            this.$timeout(() => { this.$scope.selectorValidity('originMovementTerminalDoorOrigin' + index); });
        }
    }

    private originMoveDestinationTypeChange(index: number): void {
        if (!index && index != 0) throw Error('index is null');
        if (this.$scope.model.ORIGIN && this.$scope.model.ORIGIN.length > 0 && this.$scope.model.ORIGIN[index]) {
            this.$scope.model.ORIGIN[index].ROUTING_POINT_DESTINATION = null;
            this.$timeout(() => { this.$scope.selectorValidity('originMovementTerminalDoorDestination' + index); });
        }
    }

    private destinationMoveOriginTypeChange(index: number): void {
        if (!index && index != 0) throw Error('index is null');
        if (this.$scope.model.DESTINATION && this.$scope.model.DESTINATION.length > 0 && this.$scope.model.DESTINATION[index]) {
            this.$scope.model.DESTINATION[index].ROUTING_POINT_ORIGIN = null;
            this.$timeout(() => { this.$scope.selectorValidity('destinationMovementTerminalDoorOrigin' + index); });
        }
    }

    private destinationMoveDestinationTypeChange(index: number): void {
        if (!index && index != 0) throw Error('index is null');
        if (this.$scope.model.DESTINATION && this.$scope.model.DESTINATION.length > 0 && this.$scope.model.DESTINATION[index]) {
            this.$scope.model.DESTINATION[index].ROUTING_POINT_DESTINATION = null;
            this.$timeout(() => { this.$scope.selectorValidity('destinationMovementTerminalDoorDestination' + index); });
        }
    }

    private originTerminalDoorTypeFilter = (index: number): Function => {
        const terminalId = "5";
        const terminalDoor = this.$scope.model.ORIGIN && this.$scope.model.ORIGIN.length > 0 ? this.$scope.model.ORIGIN[index] : null;
        return function (type: SelectorModel) {
            return type.ID != terminalId || !(type.ID == terminalId && terminalDoor && terminalDoor.DESTINATION_TYPE && terminalDoor.DESTINATION_TYPE.ID == type.ID);
        }
    }

    private originTerminalAirportTypeFilter = (index: number): Function => {
        const terminalId = "5";
        const terminalDoor = this.$scope.model.ORIGIN && this.$scope.model.ORIGIN.length > 0 ? this.$scope.model.ORIGIN[index] : null;
        return function (type: SelectorModel) {
            return type.ID != terminalId || !(type.ID == terminalId && terminalDoor && terminalDoor.ORIGIN_TYPE && terminalDoor.ORIGIN_TYPE.ID == type.ID);
        }
    }

    private destinationTerminalDoorTypeFilter = (index: number): Function => {
        const terminalId = "5";
        const terminalDoor = this.$scope.model.DESTINATION && this.$scope.model.DESTINATION.length > 0 ? this.$scope.model.DESTINATION[index] : null;
        return function (type: SelectorModel) {
            return type.ID != terminalId || !(type.ID == terminalId && terminalDoor && terminalDoor.ORIGIN_TYPE && terminalDoor.ORIGIN_TYPE.ID == type.ID);
        }
    }

    private destinationTerminalAirportTypeFilter = (index: number): Function => {
        const terminalId = "5";
        const terminalDoor = this.$scope.model.DESTINATION && this.$scope.model.DESTINATION.length > 0 ? this.$scope.model.DESTINATION[index] : null;
        return function (type: SelectorModel) {
            return type.ID != terminalId || !(type.ID == terminalId && terminalDoor && terminalDoor.DESTINATION_TYPE && terminalDoor.DESTINATION_TYPE.ID == type.ID);
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        try {
            const props: Array<ICustomLogProperties> = [
                {
                    PROPERTY: 'LOGISTIC_SOLUTION',
                    LABEL: 'BASIC_DATA.SERVICE'
                },
                {
                    PROPERTY: 'PRODUCT',
                    LABEL: 'BASIC_DATA.PRODUCT'
                },
                {
                    PROPERTY: 'TRADE_ORIGIN',
                    LABEL: 'BASIC_DATA.ORIGIN_TRADE_GROUP'
                },
                {
                    PROPERTY: 'TRADE_DESTINATION',
                    LABEL: 'BASIC_DATA.DESTINATION_TRADE_GROUP'
                },
                {
                    PROPERTY: 'PROVIDER',
                    LABEL: 'BASIC_DATA.PROVIDER'
                },
                {
                    PROPERTY: 'MAIN',
                    LABEL: 'PRODUCT.MAIN_SERVICE'
                },
                {
                    PROPERTY: 'ORIGIN_TYPE',
                    LABEL: 'ROUTE.ORIGIN_TYPE'
                },
                {
                    PROPERTY: 'ORIGIN',
                    LABEL: 'BASIC_DATA.ORIGIN'
                },
                {
                    PROPERTY: 'DESTINATION',
                    LABEL: 'BASIC_DATA.DESTINATION'
                },
                {
                    PROPERTY: 'DESTINATION_TYPE',
                    LABEL: 'ROUTE.DESTINATION_TYPE'
                },
                {
                    PROPERTY: 'MODAL',
                    LABEL: 'REGISTRATION.MODE_OF_TRANSPORT'
                },
                {
                    PROPERTY: 'FREQUENCY',
                    LABEL: 'ROUTE.SERVICE_FREQUENCY'
                },
                {
                    PROPERTY: 'TRANSIT_TIME_MIN',
                    LABEL: 'GENERAL.TRANSIT_TIME_MIN'
                },
                {
                    PROPERTY: 'TRANSIT_TIME_MAX',
                    LABEL: 'GENERAL.TRANSIT_TIME_MAX'
                },
                {
                    PROPERTY: 'ACTIVE',
                    LABEL: 'GENERAL.ACTIVE'
                },
                {
                    PROPERTY: 'ROUTING_POINT_ORIGIN',
                    LABEL: 'BASIC_DATA.ORIGIN'
                },
                {
                    PROPERTY: 'ROUTING_POINT_DESTINATION',
                    LABEL: 'BASIC_DATA.DESTINATION'
                },
                {
                    PROPERTY: 'NAME',
                    LABEL: 'GENERAL.NAME'
                },
                {
                    PROPERTY: 'CODE',
                    LABEL: 'GENERAL.CODE'
                },
                {
                    PROPERTY: 'ID',
                    LABEL: 'REGISTRATION.IDENTIFICATION'
                },
                {
                    PROPERTY: 'CREATED_AT',
                    LABEL: 'GENERAL.CREATED_AT'
                },
                {
                    PROPERTY: 'CREATED_BY',
                    LABEL: 'GENERAL.CREATED_BY'
                },
                {
                    PROPERTY: 'UPDATED_AT',
                    LABEL: 'GENERAL.UPDATED_AT'
                },
                {
                    PROPERTY: 'UPDATED_BY',
                    LABEL: 'GENERAL.UPDATED_BY'
                }
            ];
            return props;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/airService/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }
}

import { IConsolidatedModel, IExternalAgent, IRoutingPoint } from "WBA-Model/dist/interface/operational/consolidated/Consolidated";
import { ISelectorModel } from "WBA-Model/dist/mongo/SelectorModel";
import { IMonacoRequest } from "@services/GridFormService";
import { DataProcessService } from "@services/DataProcessService";
import { IModalOptions } from "@services/ModalService";
import { HelperService } from "@services/HelperService";
import { EConsolidatedTariffFields, ITariffList } from "../model/ConsolidatedModel";
import { EProviderTypeId, ETypeLocalId, EPaymentNatureId, EDirectionId, EProductId } from "@enums/GenericData";
import { ProductService } from "@services/ProductService";
import { PermissionService } from '@appServices/PermissionService';
import { IListCustomFilters } from "WBA-Model/dist/interface/product/FreightRoutes";
import { IListCustomFilters as IListCustomFiltersTariffLocal } from "WBA-Model/dist/interface/product/TariffLocal";
import { IEntity } from 'WBA-Model/dist/interface/common/Entity';
import { IFormServiceScope, FormService2 } from "@services/FormService2";
import { ILinkParameter } from "src/ts/common/model/ModelParameter";
import { ISessionService } from "@services/SessionService";

interface IConsolidatedScope extends IFormServiceScope {
    // Model
    model: IConsolidatedModel;

    // Modal
    modalOptions: IModalOptions;

    // Enum
    EConsolidatedTariffFields: typeof EConsolidatedTariffFields;

    // List
    productList: ISelectorModel[];
    routingPointList: IRoutingPoint[];
    consolidatedTariffFieldsList: ISelectorModel[];
    directionList: ISelectorModel[];
    agentList: IExternalAgent[];
    tariffFreightList: ITariffList[];
    tariffList: ITariffList[];
    providerList: IEntity[];

    // Field Changes
    clearTariffsFields: (data: ISelectorModel) => void;
    productChange: () => void;

    // Search
    getProviderListByName: (search: string) => void;
    getRoutingPointListByName: (search: string) => Promise<void>;
    getAgentListByName: (search: string, networkId: number) => Promise<void>;
    getTariffRouteListByName: (search: string) => Promise<void>;
    getTariffLocalListByName: (search: string, direction: string, local: ISelectorModel) => Promise<void>;

    // Request Consolidated
    generateConsolidated: () => Promise<void>;

    goToTariffLocal: (ID: number) => void;
    goToTariffFreight: (ID: number) => void;
    goToProvider: (ID: number) => void;
    goToRoutingPoint: (ID: number) => void;
}

export class ConsolidatedRegisterModalController extends FormService2 {
    static $inject: string[] = ['$injector', '$scope'];
    private $q: ng.IQService;
    private scope: IConsolidatedScope;
    private DataProcessService: DataProcessService;
    private HelperService: HelperService;
    private ProductService: ProductService;
    private PermissionService: PermissionService;
    private sessionService: ISessionService;

    constructor($injector: ng.Injectable<any>, $scope: IConsolidatedScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.$q = $injector.get('$q');
        this.DataProcessService = $injector.get('DataProcessService');
        this.HelperService = $injector.get('HelperService');
        this.ProductService = $injector.get('ProductService');
        this.sessionService = $injector.get('SessionService');

        this.PermissionService = new PermissionService(this.scope, $injector);
    }

    public async $onInit(): Promise<void> {
        try {
            this.block();
            this.initialize();
            this.unblock();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private async initialize(): Promise<void> {
        this.initDependencies();
        this.initEnum();
        this.initModel();
        this.initScopeFunctions();
    }

    private initEnum(): void {
        this.scope.EConsolidatedTariffFields = EConsolidatedTariffFields;
    }

    public initModel(): void {
        if (!this.scope.model) {
            this.scope.model = {
                _id: null,
                ID: null,
                ID_DISPLAY: null,
                ACTIVE: true,
                AWB_NUMBER: null,
                FLIGHT: null,
                DISPLAY_NAME: null,
                SITUATION: null,
                PRODUCT: null,
                PROVIDER: null,
                ORIGIN: null,
                DESTINATION: null,
                EXTERNAL_AGENT: null,
                ETD_DATE: null,
                ETA_DATE: null,
                DEADLINE: null,
                AUTOMATIC_RATE_ACHIEVED: null,
                CONSIDERED_RATE_ACHIEVED: null,
                CARGO: null,
                CONSOLIDATED_CHARGE: null,
                CONSOLIDATED_PROCESS: null,
                CONSOLIDATED_EVENT: null,
                CONSOLIDATED_INSTRUCTION: null,
                CONSOLIDATED_REFERENCE: null,
                TARIFF_LOCAL_ORIGIN: null,
                TARIFF_LOCAL_DESTINATION: null,
                TARIFF_FREIGHT: null,
                PUBLISHED_FARE: 0,
                CREATED_AT: null,
                CREATED_BY: null,
                UPDATED_AT: null,
                UPDATED_BY: null
            }
        }
    }

    public async initDependencies(): Promise<boolean> {
        const self: ConsolidatedRegisterModalController = this;

        const promises = {
            product: self.getGenericValue("product"),
            direction: self.getGenericValue("direction"),
            consolidatedTariffFields: self.getGenericValue("consolidated_tariff_fields"),
        };

        return new Promise((resolve, reject) => {
            self.$q.all(promises).then((result) => {
                self.scope.productList = this.getAirProduct(result.product);
                self.scope.directionList = result.direction;
                self.scope.consolidatedTariffFieldsList = result.consolidatedTariffFields;
                resolve(true);
            }).catch((ex) => {
                reject(ex);
            });
        });
    }

    public initScopeFunctions(): void {
        // Search
        this.scope.getProviderListByName = (search: string) => this.getProviderListByName(search);
        this.scope.getRoutingPointListByName = async (search: string) => {
            this.scope.routingPointList = await this.getRoutingPointListByName(search);
        }
        this.scope.getAgentListByName = (search: string, networkId: number) => this.getAgentListByName(search, networkId);
        this.scope.getTariffRouteListByName = async (search: string) => {
            this.scope.tariffFreightList = await this.getTariffRouteListByName(search);
        };
        this.scope.getTariffLocalListByName = async (search: string, direction: string, local: ISelectorModel) => {
            this.scope.tariffList = await this.getTariffLocalListByName(search, direction, local);
        }

        // Request Consolidated
        this.scope.generateConsolidated = () => this.generateConsolidated();

        // Field Changes
        this.scope.clearTariffsFields = (data: ISelectorModel) => this.clearTariffsFields(data);
        this.scope.productChange = () => this.productChange();

        this.scope.goToTariffLocal = (id: number) => {
            this.sessionService.openTab("app.product.tariffLocal", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.scope.goToTariffFreight = (id: number) => {
            this.sessionService.openTab("app.product.freightRoutes", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.scope.goToProvider = (id: number) => {
            this.sessionService.openTab("app.registration.provider", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.scope.goToRoutingPoint = (id: number) => {
            this.sessionService.openTab("app.registration.routingPoint", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
    }

    private async getProviderListByName(search: string): Promise<void> {
        try {
            if (search && search.length >= 3) {
                this.block();

                const providerModelList: IEntity[] = [];
                const request: IMonacoRequest = {
                    data: {
                        search: search,
                        types: [EProviderTypeId.AIRLINE]
                    },
                    route: `/provider/list/custom/operational`,
                    timeout: 30000,
                };

                const rc = await this.ProductService.post(request, false);
                const providerList = (rc && rc.data && rc.data.data) ? rc.data.data : [];

                for (const item of providerList) {
                    const selectorModel: IEntity = {
                        ID: item.ID,
                        NAME: item.NAME,
                        CODE: item.SCAC_IATA,
                        ADDRESS: item.ADDRESS,
                        CORPORATE_NAME: item.CORPORATE_NAME,
                        ID_LEGAL_PERSON: item.ID_LEGAL_PERSON,
                        ID_PHYSICAL_PERSON: item.ID_PHYSICAL_PERSON,
                        NETWORK: item.NETWORK,
                        NUMBER_IATA: item.NUMBER_IATA,
                        SCAC: item.SCAC,
                        TAXPAYER_NUMBER: item.TAXPAYER_NUMBER,
                        TRADING_NAME: item.TRADING_NAME,
                    }

                    providerModelList.push(selectorModel);
                }

                this.scope.providerList = providerModelList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getRoutingPointListByName(search: string): Promise<IRoutingPoint[]> {
        try {
            if (search && search.length >= 3) {
                this.block();

                const selectorModelList: IRoutingPoint[] = [];
                const request: IMonacoRequest = {
                    data: {
                        name: search,
                        types: [ETypeLocalId.AIRPORT]
                    },
                    route: `/routingPoint/list/custom`,
                    timeout: 30000,
                };

                const rc = await this.ProductService.post(request, false);
                let routingPointList = (rc && rc.data && rc.data.data && rc.data.data.data) ? rc.data.data.data : null;
                for (const item of routingPointList) {
                    const selectorModel: IRoutingPoint = {
                        ID: item.ID,
                        NAME: item.NAME,
                        CODE: item.CODE,
                        TRADE_GROUP: item.TRADE_GROUP,
                        TRADE_LANE: item.TRADE_LANE,
                        ACTIVE: item.ACTIVE,
                        CITY: item.CITY,
                        PROVINCE: {
                            ID: item.ID_PROVINCE,
                            NAME: item.CITY.PROVINCE.NAME,
                            CODE: item.CITY.PROVINCE.CODE
                        },
                        COUNTRY: item.COUNTRY,
                        DISPLAY_NAME: item.DISPLAY_NAME,
                    }

                    selectorModelList.push(selectorModel);
                }

                return selectorModelList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getAgentListByName(search: string, networkId: number): Promise<void> {
        try {
            if (search && search.length >= 3) {
                this.block();

                const agentSelectorList: IExternalAgent[] = [];
                const request: IMonacoRequest = {
                    data: {
                        search,
                        products: (this.scope.model.PRODUCT && this.scope.model.PRODUCT.ID) ? [this.scope.model.PRODUCT.ID] : [],
                        networks: (networkId) ? [networkId] : []
                    },
                    route: `/agent/list/custom`,
                    timeout: 30000,
                };

                const rc = await this.ProductService.post(request, false);
                const agentList = (rc && rc.data && rc.data.data && rc.data.data.data) ? rc.data.data.data : null;

                for (const item of agentList) {
                    const agentSelector: IExternalAgent = {
                        ID: item.ID,
                        NAME: item.NAME,
                        CODE: item.NETWORK_ID,
                        LEGAL_PERSON_ID: item.LEGAL_PERSON_ID,
                        LEGAL_PERSON_NAME: item.LEGAL_PERSON_NAME,
                        NETWORK_ID: item.NETWORK_ID
                    }

                    agentSelectorList.push(agentSelector);
                }

                this.scope.agentList = agentSelectorList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getTariffLocalListByName(search: string, direction: string, local: ISelectorModel): Promise<ITariffList[]> {
        try {
            if (search && search.length >= 3) {
                this.block();

                const products = (this.scope.model.PRODUCT) ? [this.scope.model.PRODUCT.ID] : [];
                const directions = (direction && direction == 'origin') ? [EDirectionId.ORIGIN] : [EDirectionId.DESTINATION];
                const payments = [EPaymentNatureId.PAYMENT];
                const validityDate = (this.scope.model.ETD_DATE) ? this.scope.model.ETD_DATE : null;
                const provider = this.scope.model.PROVIDER ? [this.scope.model.PROVIDER.ID] : null;
                const agents = this.scope.model.EXTERNAL_AGENT ? [this.scope.model.EXTERNAL_AGENT.ID] : null;

                const tariffSelectorList: ITariffList[] = [];

                const filters: IListCustomFiltersTariffLocal = {
                    DIRECTIONS: directions,
                    LOCAL: local ? [local.ID] : null,
                    PAYMENTS: payments,
                    PRODUCTS: products,
                    VALIDITY_DATE: validityDate,
                    PROVIDERS: provider,
                    AGENTS: agents
                }

                const request: IMonacoRequest = {
                    data: {
                        filters
                    },
                    route: `/tariffLocal/list/custom`,
                    timeout: 30000,
                };

                const rc = await this.ProductService.post(request, false);
                const tariffList = (rc && rc.data && rc.data.data) ? rc.data.data : [];

                for (const item of tariffList) {
                    const agentSelector: ITariffList = {
                        ID: item.ID,
                        NAME: item.CONCATENATED,
                    }

                    tariffSelectorList.push(agentSelector);
                }

                return tariffSelectorList
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getTariffRouteListByName(search: string): Promise<ITariffList[]> {
        try {
            if (search && search.length >= 3) {
                this.block();

                const products = (this.scope.model.PRODUCT) ? [this.scope.model.PRODUCT.ID] : [];
                const payments = [EPaymentNatureId.PAYMENT];
                const externalAgent = (this.scope.model.EXTERNAL_AGENT) ? [this.scope.model.EXTERNAL_AGENT.NETWORK_ID] : [];
                const validityDate = (this.scope.model.ETD_DATE) ? this.scope.model.ETD_DATE : null;

                const tariffFreightSelectorList: ITariffList[] = [];

                const filters: IListCustomFilters = {
                    SEARCH: search,
                    PRODUCTS: products,
                    PAYMENTS: payments,
                    EXTERNAL_AGENT: externalAgent,
                    VALIDITY_DATE: validityDate,
                    PROVIDERS: this.scope.model.PROVIDER ? [this.scope.model.PROVIDER.ID] : null,
                    AIRPORT_OF_ORIGIN: this.scope.model.ORIGIN ? [this.scope.model.ORIGIN.ID] : null,
                    AIRPORT_OF_DESTINATION: this.scope.model.DESTINATION ? [this.scope.model.DESTINATION.ID] : null
                }

                const request: IMonacoRequest = {
                    data: { filters },
                    route: `/freightRoutes/list/custom`,
                    timeout: 30000,
                };

                const rc = await this.ProductService.post(request, false);
                const tariffFreightList = (rc && rc.data && rc.data.data) ? rc.data.data : [];

                for (const item of tariffFreightList) {
                    const tariff: ITariffList = {
                        ID: item.ID,
                        NAME: item.CONCATENATED,
                    }

                    tariffFreightSelectorList.push(tariff);
                }

                return tariffFreightSelectorList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getGenericValue(typeGeneric: string, alternative?: boolean): Promise<ISelectorModel[]> {
        try {
            const timeout: number = 10000;
            const route: string = (alternative) ? `/generic/value/${typeGeneric}/${alternative}` : `/generic/value/${typeGeneric}`;

            const rc = await this.HelperService.get(route, null, timeout);
            const result: ISelectorModel[] = (rc && rc.data && rc.data.data) ? rc.data.data : [];

            return result;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getAirProduct(products: ISelectorModel[]): ISelectorModel[] {
        try {
            if (!products) return [];
            if (!products.length) return [];

            return products.filter(product => (product.ID == EProductId.AIR_EXPORT) || (product.ID == EProductId.AIR_IMPORT));
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private clearTariffsFields(data: ISelectorModel): void {
        try {
            if (data != null) {
                this.scope.model.TARIFF_FREIGHT = null;
                this.scope.model.TARIFF_LOCAL_DESTINATION = null;
                this.scope.model.TARIFF_LOCAL_ORIGIN = null;
            }

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private productChange(): void {
        try {
            if (!this.scope.model.PRODUCT) return;

            this.scope.providerList = [];
            this.scope.agentList = [];
            this.scope.tariffFreightList = [];

            this.scope.model.TARIFF_FREIGHT = null;
            this.scope.model.TARIFF_LOCAL_DESTINATION = null;
            this.scope.model.TARIFF_LOCAL_ORIGIN = null;
            this.scope.model.EXTERNAL_AGENT = null;
            this.scope.model.DESTINATION = null;
            this.scope.model.ORIGIN = null;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async generateConsolidated(): Promise<void> {
        try {
            this.block();
            const permission = await this.PermissionService.isRoleAllowed("OPERATIONMENUAIRCONSOLIDATEDINSERT");
            if (!permission) return this.PermissionService.showBlockMessage();

            const request: IMonacoRequest = {
                data: {
                    data: this.scope.model
                },
                route: `/consolidated/insert`,
                timeout: 12000
            }
            const rc = await this.DataProcessService.post(request.route, request.data, request.timeout);
            const consolidated = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            if (consolidated) this.closeModal();
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private closeModal(): void {
        try {
            this.scope.modalOptions.ok();
        } catch (ex) {
            this.handleError(ex);
        }
    }
}
import { extend, isDefined, IAngularEvent } from 'angular';
import { FormService2 } from './FormService2';
import { FollowUpParams } from '@models/interface/common/FollowUpParams';
import { INotificationService } from './NotificationService';
import { FollowUpProcessModel, REFERENCE } from '@models/interface/operational/FollowUpProcessModel';
import { SelectorModel } from '../common/model/SelectorModel';
import { HandleError } from '../common/util/HandleError';
import { ProcessDocument } from "@models/interface/operational/ProcessDocument";
import { Process } from "@models/interface/operational/Process";
import * as NewProcess from "@models/interface/operational/NewProcess";
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { DOCUMENT_TYPE, FILE_GROUP, FILE_SPECS, FileReferenceModel } from '@models/interface/operational/FileReferenceModel';
import { IFiles } from '../app/controllers/FileReferenceModalController';
import { IDocumentError } from '@models/interface/common/IDocumentError';
import { INewIntegrationRedundanceMessageDefault } from '../commercial/controller/NewIntegrationRedundanceModalController';
import { EOperation } from '@enums/GenericData';
import { IOfferOptionChargeWeightRange } from 'WBA-Model/dist/interface/quotation/OfferOptionChargeWeightRange';
import { CONVERSION_REFERENCE, PAYMENT_CONDITION, TRANSACTION } from 'WBA-Model/dist/interface/finop/NewInvoice';

export interface IFileReferenceParams {
    modalID: number;
    categoryName: Array<string>;
    process: Process[] | NewProcess.Process[];
    blNumber?: { number: string, PROCESS_NUMBER: string }[];
    invoiceID?: { ID: string, INVOICE_NUMBER: string, PROCESS_NUMBER: string }[];
    reference?: string;
    processDocument?: ProcessDocument;
    fileOnly?: boolean;
    uploadDisabled?: boolean;
    file?: FileReferenceModel;
    fileModel?: IFiles;
    preSetFields?: IFileReferencePreSetFields;
    disabledFields?: IFileReferenceDisabledFields;
    isEdit?: boolean;
    transaction?: TRANSACTION[];
    paymentCondition?: PAYMENT_CONDITION;
    conversionReference?: CONVERSION_REFERENCE;
}

export interface IFileReferencePreSetFields {
    documentType?: DOCUMENT_TYPE;
    documentValue?: string;
    mainFile?: boolean;
    name?: string;
    fileGroup?: FILE_GROUP;
    fileSpecs?: FILE_SPECS[];
}

export interface IFileReferenceDisabledFields {
    documentType?: boolean;
    documentValue?: boolean;
    fileGroup?: boolean;
    fileSpecs?: boolean;
    name?: boolean;
    templateType?: boolean;
    documentSequence?: boolean;
    configuration?: boolean;
    productType?: boolean;
    telephone?: boolean;
    technicalManager?: boolean;
    email?: boolean;
}

export interface IFollowUpProcess extends IModalDefaults {
    closeModal?: boolean;
    params?: FollowUpParams;
    followUpProcess?: FollowUpProcessModel;
    processes: Array<string>;
}

export interface ITaskProcess extends IModalDefaults {
    modalNeedDecision?: boolean,
    modalAutoDetect?: boolean,
    isModal?: boolean,
    processModal: string,
}

export interface IEditProcess {
    SENDER: SelectorModel;
    RECIPIENT: SelectorModel[];
    COPY_GROUP?: SelectorModel[];
    COPY_HIDDEN_GROUP?: SelectorModel[];
    REFERENCE: REFERENCE[];
}

export interface IProcessComparision extends IModalDefaults {
    currentProcess: NewProcess.Process;
    origin?: string;
}

export interface ITariffFreightChargesModalParams extends IModalDefaults {
    contractId: number;
    tariffId?: number;
}

export interface IChargesPerWeightRangeModalParams extends IModalDefaults, IModalOptions {
    offerId?: number;
    processId?: number;
    offerOptionChargeWeightRangeList?: IOfferOptionChargeWeightRange[];
    isViewMode?: boolean;
}

export interface IOfferWizardModalParams extends IModalDefaults {
    idTariffFreightContract?: number;
    idTariff?: number;
    idPaymentNature?: number;
    idOffer?: number;
    idOfferOpportunity?: number;
    idCombinedPreOffer?: number;
    product?: SelectorModel;
    cargoType?: SelectorModel;
    accounts?: SelectorModel[];
    provider?: SelectorModel;
    newFreighContract?: SelectorModel;
    freightRoutes?: SelectorModel;
}

export interface IIntegrationRedundanceModalParams extends IModalDefaults, IModalOptions {
    integrationId: number | string;
    gridRowId?: number;
    documentErrorList: IDocumentError[];
    messageDefault?: INewIntegrationRedundanceMessageDefault;
    fnSync: (id: number, idSubject?: string) => Promise<boolean>;
    fnUpdateIntegrationGrid?: (id: number) => Promise<IDocumentError[]>;
    notButtonSync?: boolean;
}

export interface IModalService {
    newModal: () => number;
    getModalScope: (id: number) => Promise<any>;
    closeModal: (id: number, result?: any) => void;
    showModalInfo: (customModalDefaults: IModalDefaults, customModalOptions: IModalOptions, params?: any) => Promise<IModalInstanceService>;
    showModalFollowUpProcess: (customModalDefaults: IFollowUpProcess, customModalOptions: IModalOptions) => Promise<IModalInstanceService>;
    showModalProcessEvent: (customModalDefaults: ITaskProcess, customModalOptions: IModalOptions) => Promise<IModalInstanceService>;
    showModalConfirmation: (customModalDefaults: IModalDefaults, customModalOptions: IModalOptions) => Promise<any>;
    showModalUserPic: (customModalDefaults: IModalDefaults, customModalOptions: IModalOptions) => Promise<IModalInstanceService>;
    showModalFileReference: (params: IFileReferenceParams) => Promise<any>;
    showModalCompareProcess: (params: IProcessComparision) => Promise<any>;
    showModalTariffFreightCharges: (params: ITariffFreightChargesModalParams) => Promise<IModalInstanceService>;
    showChargesPerWeightRangeModal: (params: IChargesPerWeightRangeModalParams) => Promise<IModalInstanceService>;
    showModalOfferWizard: (params: IOfferWizardModalParams) => Promise<IModalInstanceService>;
    showModalOfferOldWizard: (params: IOfferWizardModalParams) => Promise<IModalInstanceService>;
    showModalIntegrationRedundance: (params: IIntegrationRedundanceModalParams) => Promise<IModalInstanceService>;
    showModalRegisterBaseFlight: (modalID: number) => Promise<IModalInstanceService>;
    showModalEditBaseFlight: (modalID: number, flightCode: string, flightId: string) => Promise<IModalInstanceService>;
    showModalRegisterSpecificFlight: (modalID: number, flightCode: string) => Promise<IModalInstanceService>;
    showModalEditSpecificFlight: (modalID: number, specificFlightCode: string) => Promise<IModalInstanceService>;
    showModalRegisterPreProcess: (modalID: number) => Promise<IModalInstanceService>;
}

export interface IModalDefaults {
    modalID?: number;
    controller?: ng.IController;
    animation?: boolean;
    keyboard?: boolean;
    modalFade?: boolean;
    formService?: string;
    backdrop?: string;
    size?: string;
    scope?: ng.IController;
    template?: NodeRequire;
    events?: (event: IAngularEvent, reason: Object, closed: boolean) => Promise<void>;
}

export interface IModalOptions {
    closeButtonText?: string;
    actionButtonText?: string;
    actionButtonClass?: string;
    headerText?: string;
    bodyText?: string;
    ok?: (result?: any) => void;
}

ModalService.$inject = ['$uibModal', 'NotificationService', '$q', '$filter'];
export function ModalService($uibModal, NotificationService: INotificationService, $q: ng.IQService, $filter: angular.IFilterService) {
    const modalDefaults: IModalDefaults = {
        modalID: null,
        controller: null,
        animation: false,
        keyboard: false,
        modalFade: false,
        formService: null,
        backdrop: 'static',
        template: require("../app/view/template/modal.html")
    };
    const modalOptions: IModalOptions = {
        closeButtonText: "GENERAL.NO",
        actionButtonText: "GENERAL.YES",
        headerText: "GENERAL.CONFIRM",
        bodyText: $filter("translate")('BASIC_DATA.ARE_YOU_SURE_YOU_WANT_TO_PERFORM_THIS_ACTION')
    };

    let modalScopeList = new Array<any>();
    let loaded: ng.IDeferred<boolean> = null;

    function newModal(modalScope): void {
        modalScopeList.push(modalScope);
        loaded.resolve(true);
    }

    function removeModal(modalID): void {
        const index = modalScopeList.findIndex(x => x.modalID === modalID);
        if (index >= 0) {
            modalScopeList.splice(index, 1);
        }
        if (loaded) loaded = null;
    }

    function show(type: string, customModalDefaults: IModalDefaults, customModalOptions: IModalOptions, scope: any = null): IModalInstanceService {
        try {
            loaded = $q.defer();

            let tempModalDefaults: IModalDefaults = {};
            let tempModalOptions: IModalOptions = {};

            extend(tempModalDefaults, modalDefaults, customModalDefaults);

            extend(tempModalOptions, modalOptions, customModalOptions);

            if (!tempModalDefaults.modalID) tempModalDefaults.modalID = modalScopeList.length + 1;
            if (!tempModalDefaults.controller) {
                tempModalDefaults.controller = ['$scope', '$uibModalInstance', '$injector', function ($scope, $uibModalInstance: ng.ui.bootstrap.IModalInstanceService, $injector: ng.Injectable<any>) {
                    if (scope) extend($scope, scope);

                    $scope.modalOptions = tempModalOptions;
                    $scope.modalID = tempModalDefaults.modalID;

                    if (tempModalDefaults.events) {
                        $scope.$on('modal.closing', function (event, reason, closed) {
                            tempModalDefaults.events(event, reason, closed);
                        });
                    }

                    if (type === 'info') {
                        $scope.modalOptions.ok = function (result?: any) {
                            if (isDefined(result)) $uibModalInstance.close({ $value: result });
                            else $uibModalInstance.close({ $value: true });
                            removeModal($scope.modalID);
                        };
                        if (customModalOptions && customModalOptions.actionButtonText && customModalOptions.closeButtonText) {
                            $scope.modalOptions.close = function (result?: any) {
                                if (isDefined(result)) $uibModalInstance.close({ $value: result });
                                else $uibModalInstance.dismiss({ $value: false });
                                removeModal($scope.modalID);
                            };
                        } else $scope.modalOptions.close = null;

                    } else if (type === 'confirmation') {
                        $scope.modalOptions.ok = function (result?: any) {
                            if (isDefined(result)) $uibModalInstance.close({ $value: result });
                            else $uibModalInstance.close({ $value: true });
                            removeModal($scope.modalID);
                        };
                        $scope.modalOptions.close = function (result?: any) {
                            if (isDefined(result)) $uibModalInstance.close({ $value: result });
                            else $uibModalInstance.dismiss({ $value: false });
                            removeModal($scope.modalID);
                        };
                    } else if (type === 'userpicture') {
                        $scope.modalOptions.ok = function (result?: any) {
                            if (isDefined(result)) $uibModalInstance.close({ $value: result });
                            else $uibModalInstance.close({ $value: $scope.croppedDataUrl });
                            removeModal($scope.modalID);
                        };
                        $scope.modalOptions.close = function (result?: any) {
                            if (isDefined(result)) $uibModalInstance.close({ $value: result });
                            else $uibModalInstance.dismiss({ $value: false });
                            removeModal($scope.modalID);
                        };
                    }
                    if (tempModalDefaults.formService) {
                        const FormService = new FormService2($injector, $scope);
                        $scope.formService = FormService;

                        FormService.initStandAlone('modalForm');
                        if (tempModalDefaults.formService === EOperation.NEW) {
                            $scope.operation = EOperation.NEW;
                            FormService.loadRegisterForm(false);
                        }
                        else if (tempModalDefaults.formService === EOperation.EDIT) {
                            $scope.operation = EOperation.EDIT;
                            FormService.loadEditForm();
                        }
                        else if (tempModalDefaults.formService === EOperation.VIEW) {
                            $scope.operation = EOperation.VIEW;
                            FormService.loadViewForm();
                        } else {
                            $scope.operation = EOperation.VIEW;
                        }
                    }
                    newModal($scope);
                }]
            }
            const uibModalInstance: IModalInstanceService = $uibModal.open(tempModalDefaults);
            return uibModalInstance;
        } catch (ex) {
            HandleError.exception(ex);
            return null;
        }
    };

    this.newModal = function (): number {
        return modalScopeList.length + 1;
    }

    this.showModalInfo = function (customModalDefaults: IModalDefaults, customModalOptions: IModalOptions, params?: any): IModalInstanceService {
        return show('info', customModalDefaults, customModalOptions, params);
    };

    this.showModalFollowUpProcess = function (customModalDefaults: IFollowUpProcess, customModalOptions: IModalOptions): IModalInstanceService {
        try {
            customModalDefaults.keyboard = false;

            const scope: any = {
                closeModal: customModalDefaults.closeModal ? customModalDefaults.closeModal : false,
                processesModal: customModalDefaults.processes,
                paramsModal: customModalDefaults.params,
                followUpProcess: customModalDefaults.followUpProcess
            }

            return show('info', customModalDefaults, customModalOptions, scope);

        } catch (ex) {
            HandleError.exception(ex);
        }
    };

    this.showModalProcessEvent = function (customModalDefaults: ITaskProcess, customModalOptions: IModalOptions): IModalInstanceService {
        try {
            customModalDefaults.keyboard = true;
            customModalDefaults.formService = 'edit';

            const scope: any = {
                processModal: customModalDefaults.processModal,
                isModal: true
            };
            return show('info', customModalDefaults, customModalOptions, scope);

        } catch (ex) {
            HandleError.exception(ex);
        }
    };

    this.showModalFileReference = async function (params: IFileReferenceParams): Promise<any> {
        try {
            const modalDefaults: IModalDefaults = {
                modalID: params.modalID,
                template: require("../operational/view/fileReferenceModal.html"),
                size: 'lg',
                keyboard: true,
            };
            const modalOptions: IModalOptions = {
                actionButtonText: "GENERAL.CLOSE",
                headerText: "GENERAL.ADD_REFERENCE_ATTACHMENT"
            };
            const modalInstance: IModalInstanceService = show('info', modalDefaults, modalOptions, params);
            const modalResponse = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });
            return modalResponse;
        } catch (ex) {
            HandleError.exception(ex);
        }
    };

    this.showModalConfirmation = async function (customModalDefaults: IModalDefaults, customModalOptions: IModalOptions): Promise<any> {
        const modalInstance: IModalInstanceService = show('confirmation', customModalDefaults, customModalOptions);
        const modalResponse = await modalInstance.result.then(function (result) {
            return result.$value;
        }, function (result) {
            return result.$value;
        });
        return modalResponse;
    };

    this.showModalUserPic = function (customModalDefaults: IModalDefaults, customModalOptions: IModalOptions): IModalInstanceService {
        return show('userpicture', customModalDefaults, customModalOptions);
    };

    this.getModalScope = async function (modalID: number): Promise<any> {
        try {
            if (loaded) await loaded.promise;
            const scope = modalScopeList.find(x => x.modalID === modalID);
            return scope;

        } catch (ex) {
            HandleError.exception(ex);
            return null;
        }
    };

    this.closeModal = function (modalID: number, result?: any): void {
        const scope = modalScopeList.find(x => x.modalID === modalID);
        if (scope) scope.modalOptions.ok(result);
    };

    this.showModalCompareProcess = async function (params: IProcessComparision) {
        try {
            const modalDefaults: IModalDefaults = {
                modalID: params.modalID,
                template: require("../operational/view/compareProcessFromProcessModal.html"),
                size: 'md',
                keyboard: true,
            };
            const modalOptions: IModalOptions = {
                actionButtonText: 'GENERAL.CLOSE',
                headerText: `OPERATIONAL.COMPARE`
            };
            return show('info', modalDefaults, modalOptions, params);

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    this.showModalTariffFreightCharges = async function (params: ITariffFreightChargesModalParams) {
        try {
            const modalDefaults: IModalDefaults = {
                modalID: params.modalID,
                template: require("../registration/view/modals/tariffFreightChargesModal.html"),
                size: 'full modal-overflow',
                keyboard: true
            };
            const modalOptions: IModalOptions = {
                actionButtonText: "GENERAL.CLOSE",
                headerText: "PRODUCT.CHARGES_EDIT"
            };
            return show('info', modalDefaults, modalOptions, params);

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    this.showChargesPerWeightRangeModal = async function (params: IChargesPerWeightRangeModalParams) {
        try {
            const modalDefaults: IModalDefaults = {
                modalID: params.modalID,
                template: require("../app/view/modals/chargesPerWeightRangeModal.html"),
                size: params.size ? params.size : 'full modal-overflow',
                keyboard: params.keyboard
            };
            const modalOptions: IModalOptions = {
                actionButtonClass: params.actionButtonClass ? params.actionButtonClass : null,
                actionButtonText: "GENERAL.CANCEL",
                headerText: "PRODUCT.CHARGES_PER_WEIGHT_RANGE"
            };
            return show('info', modalDefaults, modalOptions, params);
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    this.showModalOfferWizard = async function (params: IOfferWizardModalParams) {
        try {
            const modalDefaults: IModalDefaults = {
                modalID: params.modalID,
                formService: 'register',
                template: require("../commercial/view/modals/offerWizardModal.html"),
                size: 'full',
                keyboard: false,
            };
            const modalOptions: IModalOptions = {
                actionButtonText: "GENERAL.CLOSE",
                headerText: "BASIC_DATA.OFFER"
            };
            return show('info', modalDefaults, modalOptions, params);

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    this.showModalOfferOldWizard = async function (params: IOfferWizardModalParams) {
        try {
            const modalDefaults: IModalDefaults = {
                modalID: params.modalID,
                formService: 'register',
                template: require("../commercial/view/modals/offerOldWizardModal.html"),
                size: 'full',
                keyboard: false,
            };
            const modalOptions: IModalOptions = {
                actionButtonText: "GENERAL.CLOSE",
                headerText: "BASIC_DATA.OFFER"
            };
            return show('info', modalDefaults, modalOptions, params);

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    this.showModalIntegrationRedundance = async function (params: IIntegrationRedundanceModalParams) {
        try {

            const modalDefaults: IModalDefaults = {
                modalID: params.modalID,
                formService: 'register',
                template: require("../commercial/view/modals/newIntegrationRedundanceModal.html"),
                keyboard: false
            };

            const modalOptions: IModalOptions = {
                actionButtonText: params && params.actionButtonText ? params.actionButtonText : "Sync now",
                closeButtonText: params && params.closeButtonText ? params.closeButtonText : "Close",
                headerText: params && params.headerText ? params.headerText : "Document Errors"
            };

            if (params) {
                if (params.notButtonSync === undefined) params.notButtonSync = true;
                if (!params.headerText) params.headerText = "Document Errors";
                if (!params.actionButtonText) params.actionButtonText = "Sync now";
                if (!params.closeButtonText) params.closeButtonText = "Close";
                if (!params.documentErrorList || (params.documentErrorList && params.documentErrorList.length === 0)) {
                    params.documentErrorList = [{
                        REASON: null,
                        DATE: null,
                        SUBJECT: null
                    }];
                }
            }

            return show('info', modalDefaults, modalOptions, params);
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    this.showModalRegisterBaseFlight = async function (modalID: number): Promise<IModalInstanceService> {
        try {

            const modalDefaults: IModalDefaults = {
                modalID: modalID,
                formService: 'register',
                template: require("../operational/view/modal/baseAirFlightWizard.html"),
                keyboard: false,
                size: 'full modal-overflow',
            };

            const modalOptions: IModalOptions = {
                actionButtonClass: 'btn-default',
                actionButtonText: 'GENERAL.CANCEL',
                closeButtonText: "GENERAL.CLOSE",
                headerText: 'GENERAL.AIR_FLIGHT'
            };

            return show('info', modalDefaults, modalOptions);
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    this.showModalEditBaseFlight = async function (modalID: number, flightCode: string, flightId: string): Promise<IModalInstanceService> {
        try {

            const modalDefaults: IModalDefaults = {
                modalID: modalID,
                formService: 'edit',
                template: require("../operational/view/modal/baseAirFlightEditModal.html"),
                keyboard: false,
                size: 'full modal-overflow'
            };

            const modalOptions: IModalOptions = {
                actionButtonClass: 'btn-default',
                actionButtonText: 'GENERAL.CANCEL',
                closeButtonText: "GENERAL.CLOSE",
                headerText: "OPERATIONAL.EDITING_BASE_FLIGHT" + ' - ' + flightCode
            };

            return show('info', modalDefaults, modalOptions, { flightCode });
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    this.showModalRegisterSpecificFlight = async function (modalID: number, flightCode: string): Promise<IModalInstanceService> {
        const modalDefaults: IModalDefaults = {
            modalID,
            template: require("../operational/view/modal/specificAirFlightModal.html"),
            formService: 'register',
            size: 'md modal-overflow',
        };

        const modalOptions = {
            actionButtonClass: 'btn-default',
            actionButtonText: 'GENERAL.CLOSE',
            headerText: 'GENERAL.GRID.ADD_A_NEW_SPECIFIC_FLIGHT'
        }

        return show('info', modalDefaults, modalOptions, { flightCode });

    }

    this.showModalEditSpecificFlight = async function (modalID: number, specificFlightCode: string): Promise<IModalInstanceService> {

        const modalDefaults: IModalDefaults = {
            modalID,
            template: require("../operational/view/modal/specificAirFlightEditModal.html"),
            formService: 'edit',
            size: 'vlg modal-overflow',
        };

        const modalOptions = {
            actionButtonClass: 'btn-default',
            actionButtonText: 'GENERAL.CLOSE',
            headerText: specificFlightCode
        }

        return show('info', modalDefaults, modalOptions, { specificFlightCode });
    }

    this.showModalRegisterPreProcess = async function (modalID: number): Promise<IModalInstanceService> {

        const modalDefaults: IModalDefaults = {
            modalID,
            template: require("../operational/view/modal/preProcessRegisterModal.html"),
            formService: 'register',
            size: 'md',
        };

        const modalOptions = {
            actionButtonClass: 'btn-default',
            actionButtonText: 'GENERAL.CLOSE',
            headerText: 'OPERATIONAL.NEW_FILE'
        };

        return show('info', modalDefaults, modalOptions);
    }
}

import * as angular from 'angular';

const managementState = {
    name: 'app.management',
    url: '/management',
    abstract: true,
    data: {
        /* Solução de carregar os termos por módulo
        i18n: ['general', 'registration', 'route', 'product', 'financial', 'basic_data', 'entity', 'operational'],
        */
        requiresAuth: true,
        permissions: {
            except: 'EXTERNAL',
            redirectTo: 'app.denied'
        }
    }
}

const managementMenu = {
    name: 'app.management.menu',
    url: '/menu',
    views: {
        'content@app': {
            template: require("./view/managementMenu.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.MANAGEMENT',
        parent: 'app.management'
    }
}

const activitiesMenu = {
    name: 'app.management.activities',
    url: '/activities',
    views: {
        'content@app': {
            template: require("./view/newTaskRegister.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.ACTIVITY_SCHEDULE',
        parent: 'app.management.menu'
    }
}

const newTaskHistoryState = {
    name: 'app.management.newTaskHistory',
    url: '/newTaskHistory',
    views: {
        'content@app': {
            template: require("./view/newTaskHistory.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.ACTIVITY_SCHEDULE_HISTORY',
        parent: 'app.management.menu'
    }
}

const notificationMonitorState = {
    name: 'app.management.notificationMonitor',
    url: '/notificationMonitor',
    views: {
        'content@app': {
            template: require("./view/notificationMonitor.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.FOLLOW_UP',
        parent: 'app.management.menu'
    }
}

const integrationTaskRegisterState = {
    name: 'app.management.integration',
    url: '/integration',
    views: {
        'content@app': {
            template: require("./view/IntegrationTaskRegister.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.INTEGRATION',
        parent: 'app.management.menu'
    }
}

const notificationMailBoxState = {
    name: 'app.management.notificationMailBox',
    url: '/notificationMailBox',
    views: {
        'content@app': {
            template: require("./view/notificationMailBox.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.NOTIFICATION',
        parent: 'app.management.menu'
    }
}

const externalDataIntegrationState = {
    name: 'app.management.externalDataIntegration',
    url: '/externalDataIntegration',
    views: {
        'content@app': {
            template: require("./view/externalDataIntegration.html")
        },
    },
    ncyBreadcrumb: {
        label: 'Arquivos Externos',
        parent: 'app.management.menu'
    }
}

routeConfig.$inject = ['$uiRouterProvider'];
function routeConfig($uiRouterProvider) {
    const $stateRegistry = $uiRouterProvider.stateRegistry;
    $stateRegistry.register(managementState);
    $stateRegistry.register(managementMenu);

    $stateRegistry.register(activitiesMenu);
    $stateRegistry.register(newTaskHistoryState);
    $stateRegistry.register(notificationMonitorState);
    $stateRegistry.register(integrationTaskRegisterState);
    $stateRegistry.register(notificationMailBoxState);
    $stateRegistry.register(externalDataIntegrationState);
    
}

export default function initRoute(moduleName: string): void {
    angular.module(moduleName).config(routeConfig);
}
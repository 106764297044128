import * as angular from "angular";
import { SelectorModel } from './../../common/model/SelectorModel';
import { StringUtil } from '../../common/util/StringUtil';
import { HandleError } from '../../common/util/HandleError';
import { NotificationConstants } from "../../common/util/NotificationConstants";

interface IOperation extends ng.IScope {
    operation: string;
}

interface IMonacoUiSelect extends ng.IScope {
    $parent: IOperation;
    results: Array<SelectorModel>;
    clear: boolean;
    data: string;
    args: Array<any>;
    changeArgs: Array<any>;
    change: string;
    operation: string;
    match: string;
    choice: string;
    delay: number;
    placeholder: string;
    emptyMsg: string;
    template: string;
    model: Array<SelectorModel>;
    temp: any;
    name: string;
    query: string;
    charlimit: number;

    onChange: (selecteds: Array<SelectorModel>) => void,
    onSelect: (selecteds: Array<SelectorModel>) => void,
    onItemClick: (selecteds: Array<SelectorModel>, item) => void,
    request: (query: string) => void;
    getChoice: (item) => string;
    getMatch: (item, selected) => string;
}

monacoUiSelect.$inject = ['$interpolate']
export function monacoUiSelect($interpolate): ng.IDirective {
    const ddo: ng.IDirective = {
        restrict: 'E',
        scope: {
            model: '=',
            data: '@',
            args: '=',
            changeArgs: '=',
            match: '@',
            choice: '@',
            placeholder: '@',
            delay: '@',
            appendToBody: '=',
            disabled: '=',
            charlimit: '='
        },
        template: "<div ng-include='template'></div>",
        link: ($scope: IMonacoUiSelect, element: JQLite, attributes: ng.IAttributes, ngModel: ng.IController) => {
            $scope.template = 'uiSelectTemplate';
            let isRequiredMultiple = angular.isDefined(attributes.multiple) && angular.isDefined(attributes.required);
            if (isRequiredMultiple) {
                $scope.template = 'uiSelectMultipleRequiredTemplate';
            } else {
                if (angular.isDefined(attributes.required)) $scope.template = 'uiSelectRequiredTemplate';
                if (angular.isDefined(attributes.multiple)) $scope.template = 'uiSelectMultipleTemplate';
            }

            //BUG FIX https://github.com/angular-ui/ui-select/issues/852 TODO RIDER CHECK PERFORMANCE
            $scope.$watch('model', (newObject, oldObject) => {
                $scope.temp = { model: newObject }; $scope.request(null);
            });

            $scope.$watch('temp.model', newObject => { $scope.model = $scope.temp.model; });

            $scope.placeholder = 'GENERAL.UI_SELECT.SELECT';
            $scope.match = '{ID} - {NAME}';
            $scope.choice = '{ID} - {NAME}';
            $scope.emptyMsg = NotificationConstants.EMPTY_SELECTOR_MSG;

            let hasModel = angular.isDefined(attributes.model) && angular.isDefined(attributes.model);
            if (hasModel) $scope.name = attributes.model;

            if (angular.isUndefined(attributes.clear)) $scope.clear = true;
            else if (angular.isDefined(attributes.clear)) $scope.clear = (attributes.clear === 'false') ? false : true;
            if (angular.isUndefined($scope.delay)) $scope.delay = 300; //default value of refresh rate

            $scope.$parent.$watch('operation', (newValue) => {
                if (angular.isUndefined(attributes.operation)) $scope.operation = $scope.$parent.operation;
                else $scope.operation = attributes.operation;
            });

            $scope.request = async (query): Promise<void> => {
                try {
                    query = query ? query : '';

                    let selectorList = undefined;

                    //validate selector data
                    if ($scope.data && $scope.$parent[$scope.data] && $scope.$parent[$scope.data] instanceof Array) {
                        selectorList = $scope.$parent[$scope.data];
                    } else if ($scope.data && $scope.$parent[$scope.data] && typeof ($scope.$parent[$scope.data]) === 'function') {

                        if (angular.isArray($scope.args)) selectorList = await $scope.$parent[$scope.data](query, ...$scope.args);
                        else selectorList = await $scope.$parent[$scope.data](query);

                        const charLimit: number = $scope.charlimit ? $scope.charlimit : 3;
                        if (query.length < charLimit) $scope.emptyMsg = 'Para pesquisar digite no mínimo ' + charLimit + ' letras';
                        else $scope.emptyMsg = NotificationConstants.EMPTY_SELECTOR_MSG;
                        $scope.$applyAsync();

                    }

                    //set results                        
                    $scope.results = selectorList;

                } catch (ex) {
                    HandleError.exception(ex);
                }
            }

            $scope.onChange = async (selecteds: Array<SelectorModel>): Promise<void> => {
                try {
                    $scope.model = selecteds;

                    if (angular.isDefined(attributes.change)) {
                        if ($scope.$parent[attributes.change]) {

                            if (typeof ($scope.$parent[attributes.change]) === 'function') {
                                if (angular.isArray($scope.changeArgs)) await $scope.$parent[attributes.change]($scope.model, ...$scope.changeArgs);
                                else await $scope.$parent[attributes.change]($scope.model);
                            } else {
                                throw new Error(`Método inválido implemente o método ${attributes.change}`);
                            }
                        }
                    }

                    $scope.$applyAsync();
                } catch (ex) {
                    HandleError.exception(ex);
                }
            };

            $scope.onSelect = async (selecteds: Array<SelectorModel>): Promise<void> => {
                try {
                    $scope.model = selecteds;

                    if (angular.isDefined(attributes.select)) {
                        if ($scope.$parent[attributes.select]) {
                            if (typeof ($scope.$parent[attributes.select]) === 'function') await $scope.$parent[attributes.select](selecteds);
                            else throw new Error(`Método inválido implemente o método ${attributes.select}`);
                        }
                    }

                    $scope.$applyAsync();
                } catch (ex) {
                    HandleError.exception(ex);
                }
            };

            $scope.onItemClick = async (selecteds: Array<SelectorModel>, item): Promise<void> => {
                try {
                    $scope.model = selecteds;

                    if (angular.isDefined(attributes.itemclick)) {
                        if ($scope.$parent[attributes.itemclick]) {
                            if (typeof ($scope.$parent[attributes.itemclick]) === 'function') await $scope.$parent[attributes.itemclick](item);
                            else throw new Error(`Método inválido implemente o método ${attributes.itemclick}`);
                        }
                    }

                    $scope.$applyAsync();
                } catch (ex) {
                    HandleError.exception(ex);
                }
            };

            $scope.getChoice = (item): string => {
                try {
                    const choice = StringUtil.replaceAll(StringUtil.replaceAll($scope.choice, '{', '{{'), '}', '}}');
                    return $interpolate(choice)(item);
                } catch (ex) {
                    throw ex;
                }
            }

            $scope.getMatch = (item): string => {
                try {
                    const match = StringUtil.replaceAll(StringUtil.replaceAll($scope.match, '{', '{{'), '}', '}}');
                    return $interpolate(match)(item);
                } catch (ex) {
                    throw ex;
                }
            }
        }
    }

    return ddo;
}

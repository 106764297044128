import * as angular from 'angular';

import initRoute from './RouteConfig';
import { GRID_FORM_MODULE } from '../gridForm/GridFormModule';
import { ProductService } from '@services/ProductService';
import { OperationalService } from '@services/OperationalService';


const CRM_MODULE = angular
    .module('crm', [GRID_FORM_MODULE.name])
    .service('ProductService', ProductService)
    .service('OperationalService', OperationalService);

initRoute(CRM_MODULE.name);

export { CRM_MODULE };
import angular = require('angular');
import { IModalOptions, IModalService } from '@services/ModalService';
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { IRestService } from '@services/RestService';
import { OperationalService } from '@services/OperationalService';
import { ProductService } from '@services/ProductService';
import { IMonacoRequest } from '@services/GridFormService';
import { ISelectorModel, SelectorModel } from "@models/mongo/SelectorModel"
import { SHIP_REFERENCE } from "@models/interface/operational/MaritimeTravel";
import { PROCESS_PEOPLE } from '@models/interface/operational/Process';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { COUNTRY, IWizardVoyageStopoverBaseData, IWizardVoyageStopoverRequest, MaritimeStopover } from '@models/interface/operational/maritime/MaritimeStopover';
import { CARRIER_INFORMATION, IMaritimeVoyage, VESSEL, VESSEL_INFORMATION } from '@models/interface/operational/maritime/MaritimeVoyage';
import { NewProcessEvent, ITransportMode, ITransportModeByVessel } from '@models/interface/operational/NewProcessEvent';
import { INotificationMessageServiceResult } from '@models/interface/operational/INotificationMessageServiceResult';
import { ButtonBar } from '@models/interface/common/DatetimePicker';
import { EMaritimeStopOverLog } from '@enums/MaritimeStopover';
import { ProcessEventHelperController } from '../common/ProcessEventHelperController';
import { WizardDeadlinesPanelController, WIZARD_DEADLINE, IDeadlineListOptions } from './WizardDeadlinesPanelController';
import { WizardMaritimeServicesPanelController } from './WizardMaritimeServicesPanelController';
import { WizardStopoverPanelController, IStopoverListOptions } from './WizardStopoverPanelController';
import { NotificationBox } from '../../common/NotificationBox';
import { INotificationMonitorParameter } from '../../common/model/ModelParameter';
import { ILightDataTableOptions, ILightDataTableRow } from '../../app/components/lightDataTable/LightDataTableController';
import { EOperation } from '@enums/GenericData';
import { HelperService } from "@services/HelperService";

interface IUiTabSteps {
    current: number
    percent: number
}

interface ITransportModeSelector {
    vessel: ISelectorModel;
    stopover: ISelectorModel;
}

export interface IExceptionStopoverPort extends ISelectorModel {
    COUNTRY: COUNTRY
}

export interface IExceptionStopoverModel extends SHIP_REFERENCE {
    PORT: IExceptionStopoverPort[];
}

interface IEventConfirmationData {
    selected: boolean,
    hasLocal: boolean,
    event: NewProcessEvent,
    viewStopover: ISelectorModel
    suggestedStopovers: ISelectorModel[],
    previousTransportMode: ISelectorModel
    previousEstimatedDate: Date,
}

export interface IStopoverModalScope extends IFormServiceScope {
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    modalOptions: IModalOptions;
    eventDetailModalID: number;
    modalID: number;
    steps: IUiTabSteps;
    voyageBaseData: IWizardVoyageStopoverBaseData;
    exceptionStopoverModel: IExceptionStopoverModel[];
    shipCollapseMap: boolean[];
    voyageId: string;
    buttonBar: ButtonBar;
    // flags
    blockSteps: boolean;
    isNewRecord: boolean;
    isEditing: boolean;
    isViewing: boolean;
    showAllStopoversButton: boolean;
    deadlineRecalcOverwriteReviewed: boolean;
    // templates
    defaultTabs: any[];
    lastSelectedTab: object;
    // lists
    stopoverViaList: ISelectorModel[];
    eventChangeMotivesList: ISelectorModel[];
    eventModifiedResponsibleList: ISelectorModel[];
    shipList: ISelectorModel[];
    carriersOptions: { [position: string]: ISelectorModel[] };
    // Maritime services tab models
    maritimeServiceTableOptions: ILightDataTableOptions;
    maritimeServiceLightDataTableRows: Array<ILightDataTableRow>;
    maritimeServiceController: WizardMaritimeServicesPanelController;
    // maritime services filters values
    productList: ISelectorModel[];
    providersList: ISelectorModel[];
    routingPointList: ISelectorModel[];
    maritimeServiceList: ISelectorModel[];
    // process
    processNumber: string;
    transportMode: ITransportModeSelector;
    transportModesByVessel: ITransportModeByVessel[];
    processEvents: NewProcessEvent[];
    processServiceProvider: PROCESS_PEOPLE;
    processProduct: ISelectorModel;
    operationType: ISelectorModel[];
    modalOperation: string;
    //
    deadlinesController: WizardDeadlinesPanelController;
    //
    hasExceptionStopover: boolean;
    //
    stopoversController: WizardStopoverPanelController;
    //
    viewData: {
        travelMainShip?: string,
        showAllStopovers: boolean,
        travelShipownersTravel?: string,
        // stopover
        stopoverList?: MaritimeStopover[],
        stopoverTableData?: MaritimeStopover[],
        stopoverFilterPort?: ISelectorModel,
        stopoverFilterVessel?: ISelectorModel,
        stopoverFilterSituation?: ISelectorModel,
        filteredStopoverList?: MaritimeStopover[],
        stopoverListOptions?: IStopoverListOptions,
        // deadline
        deadlinesTableData?: WIZARD_DEADLINE[],
        deadlineFilterVessel?: string,
        deadlineFilterCarrier?: ISelectorModel,
        deadlineFilterPort?: ISelectorModel,
        deadlineListOptions?: IDeadlineListOptions,
        deadlineList?: WIZARD_DEADLINE[],
        filteredDeadlineList?: WIZARD_DEADLINE[],
        travelLocals?: ISelectorModel[],
        // event
        isRequiredJustification?: boolean,
        isRequiredResponsible?: boolean,
        loadEventChangeMotive?: ISelectorModel,
        loadEventChangeResponsible?: ISelectorModel,
        eventsStopoverTableData?: IEventConfirmationData[],
    }
    affectedProcesses: string[];

    updateEventParentOperation: () => void;

    addShip: () => void;
    removeShip: (shipIndex: number, shipId: string) => void;
    addCarrier: (shipIndex: number) => void;
    removeShipOwner: (shipIndex: number, shipOwnerIndex: number) => void;
    registerTravelStopover: () => void;

    getShip: (term: string) => Promise<any>;
    updateCarriersOptions: (i?: number) => Promise<any>;
    getPort: (term: string) => Promise<IExceptionStopoverPort[]>;

    showAllStopovers: () => void;
    isVesselLinkedWithStopover: (vessel: VESSEL) => boolean;
    checkJustificationIsRequired: (eventData: IEventConfirmationData) => void;
    refreshEventsCompatibleStopovers: (i: number) => Promise<void>;
    refreshEventsSuggestedDateByStopover: (i: number) => Promise<void>;

    back: (event: JQuery.Event<HTMLElement>) => void;
    next: (event: JQuery.Event<HTMLElement>) => void;
    finish: () => void;
    onSelectTab: (tabId: number, event: JQuery.Event<HTMLElement>) => void;
    onDeselectTab: (tabId: number, event: JQuery.Event<HTMLElement>) => void;
    isTabEnabled: (tabIndex: number) => boolean;
    collapseMainShip: (mainShipIndex: number) => void;
    checkMainVessels: (vesselIndex: number) => void;
    viewLogVoyage: (logBlock: string) => Promise<void>;
    viewLogStopover: (logBlock: string) => Promise<void>;
    viewLogDeadlines: (ship: string, carrier: string, port: string, type: string) => Promise<void>;
    isCarrierInformationEmpty: (parentIndex: number, childIndex: number) => boolean;

    // Stopover
    editStopover: (stopoverId: string) => void;
    viewStopoverLog: (stopoverId: string) => void;
    filterStopovers: () => void;
    addStopover: () => void;

    // Deadline
    editDeadline: (deadlineIndex: number) => void;
    viewDeadlineLog: (deadlineIndex: number) => void;
    filterDeadlines: () => void;
}

export class WizardStopoverController extends FormService2 {
    static $inject: string[] = ['$injector', '$scope', '$element'];
    private $injector: ng.Injectable<any>;
    private scope: IStopoverModalScope;
    private $q: ng.IQService;
    private processEventHelper: ProcessEventHelperController = null;

    public tabsValidators: object;
    public wizardDomElement: JQLite;
    public $RestService: IRestService;
    public ModalService: IModalService;
    public timeout: ng.ITimeoutService;
    public productService: ProductService;
    public navigationChangeListeners: object;
    public voyageTabOldValue: IMaritimeVoyage;
    public operationalService: OperationalService;
    public defaultTimeout: number;
    private helperService: HelperService;

    public TABS = {
        VOYAGE: "Viagem",
        STOPOVER: "Escalas",
        DEADLINES: "DeadLines",
        CONFIRMATION: "Confirmação",
        MARITIME_SERVICE: "Serviço Marítimo",
    }

    constructor($injector: ng.Injectable<any>, $scope: IStopoverModalScope, $element: JQLite) {
        super($injector, $scope);
        this.$injector = $injector;
        this.scope = $scope;
        this.wizardDomElement = $element;
        this.operationalService = $injector.get('OperationalService');
        this.productService = $injector.get('ProductService');
        this.$RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');
        this.$q = $injector.get('$q');
        this.timeout = $injector.get('$timeout');
        this.ModalService = $injector.get('ModalService');
        this.defaultTimeout = 1800000; // 30 Min
        this.helperService = $injector.get('HelperService'); 
    }

    async $onInit(): Promise<void> {
        try {
            this.block();
            this.tabsValidators = {
                [`${this.TABS.VOYAGE}`]: () => this.validateVoyageTab()
            };

            this.navigationChangeListeners = {
                [`${this.TABS.CONFIRMATION}`]: {
                    NEW_RECORD: async () => this.handleEventStopoversConfirmationInit(),
                    EDIT: async () => this.handleEventStopoversConfirmationInit()
                }
            };

            this.scope.maritimeServiceController = new WizardMaritimeServicesPanelController(this.scope, this, this.$injector);
            this.scope.deadlinesController = new WizardDeadlinesPanelController(this.scope, this);
            this.scope.stopoversController = new WizardStopoverPanelController(this.scope, this);
            this.processEventHelper = new ProcessEventHelperController(this.$injector);

            this.init("stopoverWizardModalForm", null, null);
            const initialTab = this.scope.defaultTabs.find(tab => tab.initialTab === true);
            this.scope.steps.current = initialTab && initialTab.id != null ? initialTab.id : 1;

            this.scope.blockSteps = this.scope.blockSteps != null ? this.scope.blockSteps : true;
            this.scope.processEvents = this.scope.processEvents != null ? this.scope.processEvents : [];
            this.scope.showAllStopoversButton = this.scope.showAllStopoversButton != null ? this.scope.showAllStopoversButton : true;
            this.scope.shipCollapseMap = [];
            this.scope.viewData = {
                showAllStopovers: false,
            };
            this.scope.hasExceptionStopover = false;
            this.scope.isViewing = this.scope.modalOperation === EOperation.VIEW;
            this.scope.isEditing = this.scope.modalOperation === EOperation.EDIT;
            this.scope.isNewRecord = this.scope.modalOperation === EOperation.NEW;
            this.scope.affectedProcesses = [];

            await this.getOperationType();

            this.initScopeFunctions();
            await this.initDependencies();
            await this.initModel();
            await this.handleOperation();
            this.loadRegisterForm(false);

            this.handleNavigationChange();
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    async initDependencies(): Promise<any> {
        try {
            const self: WizardStopoverController = this;
            return new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getGenericList('stopover_via'),
                    self.getGenericList('modified_type'),
                    self.getGenericList('file_specs'),
                    self.scope.maritimeServiceController.getMaritimeServices(),
                    self.scope.maritimeServiceController.getMultipleGenericList(['product']),
                ]).then((result: any) => {
                    self.scope.stopoverViaList = result[0];
                    self.scope.eventChangeMotivesList = result[1];
                    self.scope.eventModifiedResponsibleList = result[2];
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async initModel(): Promise<void> {
        this.scope.voyageBaseData = {
            voyage: {
                ID: null,
                _id: null,
                FEEDER: null,
                SERVICE: null,
                VOYAGE_NUMBER: null,
                VESSEL_INFORMATION: [],
                MODIFIED_DATE: null,
                CREATED_DATE: null,
                MODIFIED_BY: null,
                PRODUCT: (this.scope.processProduct) ? this.scope.processProduct : null,
            },
            stopovers: []
        };
    }

    private avoidSelectAnotherTab(event: JQuery.Event<HTMLElement>): boolean {
        const tab = (this.scope.defaultTabs.find(t => t.id === this.scope.steps.current) || {}).label;
        if (this.tabsValidators[tab] && !this.tabsValidators[tab]()) {
            event.preventDefault();
            event.stopPropagation();
            event.stopImmediatePropagation();
            return true;
        }
    }

    private initScopeFunctions(): void {
        this.scope.back = async (event: JQuery.Event<HTMLElement>): Promise<void> => {
            if (this.scope && this.scope.steps && this.scope.steps.current > 0) {
                if (this.avoidSelectAnotherTab(event)) return;
                this.scope.steps.current--;
            }
        }
        this.scope.next = async (event: JQuery.Event<HTMLElement>): Promise<void> => {
            if (this.scope.voyageBaseData.voyage && this.scope.voyageBaseData.voyage.VESSEL_INFORMATION) {
                for (const vesselInfo of this.scope.voyageBaseData.voyage.VESSEL_INFORMATION) {
                    for (const carrierInfo of vesselInfo.CARRIER_INFORMATION)
                        if (!carrierInfo.CARRIER || !carrierInfo.VOYAGE) {
                            this.notifyError(this.getTranslate("GENERAL.ALL_FIELDS_MANDATORY"));
                            return;
                        }
                }
            }

            if (this.scope && this.scope.steps) {
                if (this.scope.steps.current < this.scope.defaultTabs.length) {
                    if (this.avoidSelectAnotherTab(event)) return;
                    this.scope.steps.current++;
                }
                const tabViagem = (this.scope.defaultTabs.find(t => t.id === this.scope.steps.current));
                if (tabViagem.id == '2' && tabViagem.label == 'Viagem' && (!this.scope.voyageBaseData.voyage.VESSEL_INFORMATION || !this.scope.voyageBaseData.voyage.VESSEL_INFORMATION.length)) {
                    this.scope.addShip();
                }
            }
        }
        this.scope.onDeselectTab = async (tabId: number, event: JQuery.Event<HTMLElement>): Promise<void> => {
            if (!this.scope.steps || tabId == null) return;
            if (event && this.avoidSelectAnotherTab(event)) return;
            const lastSelectedTab = this.scope.defaultTabs.find(t => t.id == tabId) || {};
            this.scope.lastSelectedTab = lastSelectedTab;
        }
        this.scope.onSelectTab = async (tabId: number): Promise<void> => {
            this.scope.steps.current = tabId;
            await this.handleNavigationChange();
        }
        this.scope.isTabEnabled = (tabIndex: number): boolean => {
            if (!this.scope.blockSteps) return true;
            if (!this.scope.steps || tabIndex == null) return false;
            const previousStep = this.scope.steps.current - 1;
            const nextStep = this.scope.steps.current + 1;
            return (previousStep == tabIndex || nextStep == tabIndex);
        }
        this.scope.collapseMainShip = (mainShipIndex: number): void => {
            this.collapseMainShip(mainShipIndex);
        }
        this.scope.addShip = (): void => {
            this.addShip();
        }
        this.scope.removeShip = (shipIndex): void => {
            this.removeShip(shipIndex);
        }
        this.scope.addCarrier = (shipIndex): void => {
            this.addCarrier(shipIndex);
        }
        this.scope.removeShipOwner = (shipIndex: number, shipOwnerIndex: number) => {
            this.removeShipOwner(shipIndex, shipOwnerIndex);
        }
        this.scope.getShip = (term: string): Promise<any> => {
            return this.getShip(term);
        }
        this.scope.updateCarriersOptions = (i: number): Promise<any> => {
            return this.updateCarriersOptions(i.toString());
        }
        this.scope.getPort = (term: string): Promise<any> => {
            return this.getPort(term);
        }
        this.scope.checkJustificationIsRequired = (eventData: IEventConfirmationData) => {
            //this.scope.viewData.isRequiredJustification = eventData.event.EVENT_TYPE.ID === '4' && eventData.viewStopover.ID !== eventData.event.STOPOVER['ID'];
            this.scope.viewData.isRequiredJustification = false;

            const LOAD_TYPE: string = '4';
            const eventsSelecteds = this.scope.viewData.eventsStopoverTableData.filter(e => e.selected);
            const loadEvent = eventsSelecteds.find(e => e.event.EVENT_TYPE.ID === LOAD_TYPE);

            if (loadEvent != null && loadEvent.event.STOPOVER) {
                const changedEvent = this.scope.processEvents.find(x => x.EVENT_ID === loadEvent.event.EVENT_ID);
                if (changedEvent && changedEvent.STOPOVER && loadEvent.viewStopover) {
                    this.scope.viewData.isRequiredJustification = (loadEvent.viewStopover.ID !== changedEvent.STOPOVER.ID);
                }
            }

            this.timeout(async () => {
                this.scope.selectorValidity('eventModifiedSelector');
                await this.scope.$applyAsync();
            });
        }
        this.scope.refreshEventsCompatibleStopovers = async (i: number): Promise<void> => {
            return this.refreshEventsCompatibleStopovers(i);
        }
        this.scope.refreshEventsSuggestedDateByStopover = async (i: number): Promise<void> => {
            return this.refreshEventsSuggestedDateByStopover(i);
        }
        this.scope.finish = async (): Promise<void> => {
            await this.finish();
        }
        this.scope.checkMainVessels = (vesselIndex: number): void => {
            this.scope.voyageBaseData.voyage.VESSEL_INFORMATION.forEach((vessel, currentIndex) => {
                if (currentIndex != vesselIndex) {
                    vessel.MAIN = false;
                }
            });
        }
        this.scope.isVesselLinkedWithStopover = (vessel: VESSEL) => {
            if (vessel == null) return false;
            return this.scope.voyageBaseData.stopovers.some((stopover) => {
                return stopover.VESSEL_INFORMATION && stopover.VESSEL_INFORMATION.VESSEL.ID === vessel.ID;
            });
        }
        this.scope.viewLogVoyage = (): Promise<void> => {
            const identifier: string = this.scope.voyageBaseData.voyage.VOYAGE_NUMBER;
            return this.viewLog(EMaritimeStopOverLog.VOYAGE, identifier);
        }
        this.scope.viewLogDeadlines = (ship: string, carrier: string, port: string, type: string): Promise<void> => {
            const identifier: string = this.scope.voyageBaseData.voyage.VOYAGE_NUMBER;
            return this.viewLog(EMaritimeStopOverLog.DEADLINES, identifier);
        }
        this.scope.isCarrierInformationEmpty = (parentIndex: number, childIndex: number): boolean => { 
            return !this.scope.voyageBaseData.voyage.VESSEL_INFORMATION[parentIndex].CARRIER_INFORMATION[childIndex].VOYAGE || !this.scope.voyageBaseData.voyage.VESSEL_INFORMATION[parentIndex].CARRIER_INFORMATION[childIndex];
        };
    }

    // tab navigation
    private async handleNavigationChange(): Promise<void> {
        try {
            const tab = this.scope.defaultTabs.find(t => t.id === this.scope.steps.current) || {};
            if (this.navigationChangeListeners[tab.label]) {
                const newRecordListener = this.navigationChangeListeners[tab.label].NEW_RECORD;
                const recordViewingListener = this.navigationChangeListeners[tab.label].VIEW;
                const recordEditListener = this.navigationChangeListeners[tab.label].EDIT;

                if (this.scope.isNewRecord && newRecordListener) {
                    await newRecordListener.call(this, this.scope.lastSelectedTab);
                } else if (this.scope.isEditing && recordEditListener) {
                    await recordEditListener.call(this, this.scope.lastSelectedTab);
                } else if (this.scope.isViewing && recordViewingListener) {
                    await recordViewingListener.call(this, this.scope.lastSelectedTab);
                }
            }
            await this.scope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async validateVoyageTab(): Promise<boolean> {
        try {
            if (!this.scope.voyageBaseData.voyage.VESSEL_INFORMATION || this.scope.voyageBaseData.voyage.VESSEL_INFORMATION.length == 0) throw this.getTranslate("GENERAL.ALL_FIELDS_MANDATORY");
            if (this.scope.voyageBaseData.voyage.VESSEL_INFORMATION && this.scope.voyageBaseData.voyage.VESSEL_INFORMATION.some(item => item.CARRIER_INFORMATION.length == 0)) throw this.getTranslate("OPERATIONAL.ONE_CARRIER_VESSEL");
            if (this.scope.voyageBaseData.voyage.VESSEL_INFORMATION && !this.scope.voyageBaseData.voyage.VESSEL_INFORMATION.some(item => item.MAIN)) throw this.getTranslate("OPERATIONAL.ONE_PRIMARY_VESSEL");

            if (!this.checkPendingFields()) return

            return true;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    // form control

    private collapseHeader(elementId: string, state?: string, navigate?: boolean): void {
        if (elementId != "registerBody") {
            $("#" + elementId)["collapse"](state ? state : 'toggle');
        }
    }

    private collapseMainShip(mainShipIndex: number): void {
        if (!mainShipIndex && mainShipIndex != 0) throw Error("mainShipIndex is null.");
        if (this.scope.voyageBaseData.voyage.VESSEL_INFORMATION && this.scope.voyageBaseData.voyage.VESSEL_INFORMATION.length > 0 && this.scope.voyageBaseData.voyage.VESSEL_INFORMATION[mainShipIndex]) {
            if (this.scope.shipCollapseMap[mainShipIndex]) {
                this.collapseHeader('collapseShipOwner' + mainShipIndex, 'hide');
                this.scope.shipCollapseMap[mainShipIndex] = false;
            }
            else {
                this.collapseHeader('collapseShipOwner' + mainShipIndex, 'show');
                this.scope.shipCollapseMap[mainShipIndex] = true;
            }
        }
    }

    private scrollPanelToBottom() {
        this.timeout(() => {
            const panelsBody: JQLite = this.wizardDomElement.find('.ships-panel-body');
            if (panelsBody.length) {
                panelsBody[0].scrollTop = panelsBody[0].scrollHeight;
            }
        });
    }

    private addShip() {
        try {
            let shipMaster: boolean = false;
            let carrier: CARRIER_INFORMATION[] = null;

            if (!this.scope.voyageBaseData.voyage.VESSEL_INFORMATION || !this.scope.voyageBaseData.voyage.VESSEL_INFORMATION.length) {
                this.scope.voyageBaseData.voyage.VESSEL_INFORMATION = [];
                shipMaster = true;
            } else {
                const hasShipOwners = this.scope.voyageBaseData.voyage.VESSEL_INFORMATION[0] && angular.isArray(this.scope.voyageBaseData.voyage.VESSEL_INFORMATION[0].CARRIER_INFORMATION) && this.scope.voyageBaseData.voyage.VESSEL_INFORMATION[0].CARRIER_INFORMATION.length;
                if (hasShipOwners) carrier = angular.copy(this.scope.voyageBaseData.voyage.VESSEL_INFORMATION[0].CARRIER_INFORMATION);
            }

            this.scope.voyageBaseData.voyage.VESSEL_INFORMATION.push({
                MAIN: shipMaster,
                VESSEL: null,
                CARRIER_INFORMATION: [],
            });

            this.scope.shipCollapseMap[(this.scope.voyageBaseData.voyage.VESSEL_INFORMATION.length - 1)] = true;

            this.scrollPanelToBottom();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private removeShip(shipIndex: number) {
        this.scope.voyageBaseData.voyage.VESSEL_INFORMATION.splice(shipIndex, 1);
        this.scope.shipCollapseMap.splice(shipIndex, 1);
    }

    private addCarrier(shipIndex: number) {
        try {
            if (this.scope.voyageBaseData.voyage.VESSEL_INFORMATION[shipIndex]) {
                if (!this.scope.voyageBaseData.voyage.VESSEL_INFORMATION[shipIndex].CARRIER_INFORMATION) this.scope.voyageBaseData.voyage.VESSEL_INFORMATION[shipIndex].CARRIER_INFORMATION = [];

                this.scope.voyageBaseData.voyage.VESSEL_INFORMATION[shipIndex].CARRIER_INFORMATION.push({
                    VOYAGE: null,
                    CARRIER: null
                });
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private removeShipOwner(shipIndex: number, shipOwnerIndex: number) {
        try {
            if (shipIndex != null && shipOwnerIndex != null) {
                if (this.scope.voyageBaseData.voyage.VESSEL_INFORMATION[shipIndex] && this.scope.voyageBaseData.voyage.VESSEL_INFORMATION[shipIndex].CARRIER_INFORMATION[shipOwnerIndex]) {

                    const removedCarrier = this.scope.voyageBaseData.voyage.VESSEL_INFORMATION[shipIndex].CARRIER_INFORMATION[shipOwnerIndex];

                    this.scope.voyageBaseData.voyage.VESSEL_INFORMATION[shipIndex].CARRIER_INFORMATION.splice(shipOwnerIndex, 1);

                    if (this.scope.voyageBaseData.voyage.VESSEL_INFORMATION[shipIndex].MAIN && removedCarrier && removedCarrier.CARRIER) {

                        for (let i = 0; i < this.scope.voyageBaseData.voyage.VESSEL_INFORMATION.length; i++) {
                            const removedCarriedIndex = this.scope.voyageBaseData.voyage.VESSEL_INFORMATION[i].CARRIER_INFORMATION.findIndex(x => x.CARRIER && x.CARRIER.ID === removedCarrier.CARRIER.ID);
                            if (removedCarriedIndex > -1) this.scope.voyageBaseData.voyage.VESSEL_INFORMATION[i].CARRIER_INFORMATION.splice(removedCarriedIndex, 1);
                        }

                    }

                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }


    private async getGenericList(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, this.defaultTimeout);
        return generic && generic.data ? generic.data : [];
    }

    private async calcMaritimeProcessEventEstimatedDate(event: NewProcessEvent, stopover: MaritimeStopover, product: string): Promise<NewProcessEvent> {
        const params = { event, stopover, product }
        const result = await this.operationalService.post('/event/date/stopover/maritime', params, this.defaultTimeout);
        return result.data ? result.data.data : null;
    }

    private async getMaritimeTravelById(voyageId: string): Promise<any> {
        return this.operationalService.get(`/stopover/wizard/${voyageId}`, this.defaultTimeout);
    }

    public async updateCarriersOptions(i?: string): Promise<void> {
        try {
            const voyageVesselInfo: VESSEL_INFORMATION = this.scope.voyageBaseData.voyage.VESSEL_INFORMATION[i];

            let carriersOptions: ISelectorModel[] = [];

            if (voyageVesselInfo.MAIN) {
                carriersOptions = this.scope.voyageBaseData.voyage.SERVICE.PROVIDERS;
            } else {
                carriersOptions = this.scope.voyageBaseData.voyage.VESSEL_INFORMATION.find(v => v.MAIN).CARRIER_INFORMATION.map(c => c.CARRIER);
            }

            carriersOptions = carriersOptions.filter(x => !voyageVesselInfo.CARRIER_INFORMATION.filter(y => y.CARRIER).some(y => y.CARRIER.ID === x.ID));

            this.scope.carriersOptions = { [i]: carriersOptions };

            await this.scope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getOperationType() {
        try {
            const { data: generic } = await this.helperService.get(`/generic/value/oper`, null, 10000);
            this.scope.operationType = generic && generic.data ? generic.data : [];
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getShip(term: string): Promise<void> {
        try {
            const transform = (ships) => ships.map(ship => { return { ID: ship.ID, NAME: ship.NAME, CODE: `${ship.COUNTRY_FLAG.INITIALS} - ${ship.COUNTRY_FLAG.NAME_INTL}` } });

            if (term.length > 2) {
                this.block();
                const request: IMonacoRequest = {
                    data: { search: term, provider: [] },
                    route: `/ship/list/custom`,
                    timeout: 10000,
                };
                const response = await this.productService.post(request);

                this.unblock();
                this.scope.shipList = (response && response.data && response.data.data) ? transform(response.data.data) : null;
            }
        } catch (ex) {
            this.handleError(ex);
        }
    };

    public hasChanges(newObj: Object, oldObj: Object): boolean {
        let hasChanges = false;
        if (oldObj) {
            for (let property in newObj) {
                if (newObj.hasOwnProperty(property)) {
                    if (JSON.stringify(newObj[property]) != JSON.stringify(oldObj[property])) {
                        hasChanges = true;
                        break;
                    }
                }
            }
        }
        return hasChanges;
    }

    async getPort(term: string): Promise<ISelectorModel[]> {
        let result = [];

        const transform = (routingPoints): Array<IExceptionStopoverPort> => {
            return routingPoints.map((routingPoint) => {
                return {
                    ID: routingPoint.ID,
                    NAME: routingPoint.NAME,
                    CODE: routingPoint.CODE,
                    ALIAS: routingPoint.CODE,
                    COUNTRY: routingPoint.COUNTRY,
                }
            });
        };

        try {
            if (term && term.length >= 3) {
                const request: IMonacoRequest = {
                    data: { term, name: term, types: ['2'] },
                    route: `/routingPoint/list/custom/`,
                    timeout: 10000,
                };
                const routingPoints = await this.productService.post(request);
                result = routingPoints.data && routingPoints.data.data && routingPoints.data.data.data ? transform(routingPoints.data.data.data) : [];
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.scope.$applyAsync();
            return result;
        }
    };

    //

    private async handleOperation(): Promise<void> {
        if (this.scope.isEditing || this.scope.isViewing) {
            try {
                await this.handleMaritimeTravelStopoverInit();
            } catch (ex) {
                this.handleError(ex);
            }
        }
    }

    private getSelectedTransportModeStopover() {
        const selectedVessel = this.scope.transportMode.vessel;
        const selectedStopover = this.scope.transportMode.stopover;
        if (!selectedVessel || !selectedStopover) return;
        // para adicionar (register) pode não haver stopover, apenas navio

        const transportModesByVessel: ITransportModeByVessel[] = this.scope.transportModesByVessel;
        const selectedTransportModeByVessel = (selectedVessel && transportModesByVessel) ? transportModesByVessel.find(x => x.vessel.ID === selectedVessel.ID) : transportModesByVessel.find(x => x.vessel === null);
        if (!selectedTransportModeByVessel) return;

        const transportModeStopovers = selectedTransportModeByVessel.stopovers;
        const selectedTransportModeStopover = transportModeStopovers.find(x => x.stopover && x.stopover.ID === selectedStopover.ID);
        if (!selectedTransportModeStopover) return;
        return selectedTransportModeStopover;
    }

    async handleMaritimeTravelStopoverInit(): Promise<void> {
        try {
            let voyageId = this.scope.voyageId;
            if (!voyageId) {
                const selectedTransportModeStopover = this.getSelectedTransportModeStopover();
                voyageId = selectedTransportModeStopover.maritimeVoyagelId;
            }

            const result = await this.getMaritimeTravelById(voyageId);
            if (!result || !result.data) return this.handleWarning(this.getTranslate("GENERAL.VOYAGE_NOT_FOUND"));
            const maritimeTravel: IWizardVoyageStopoverBaseData = result.data.data;
            if (maritimeTravel) this.scope.voyageBaseData = maritimeTravel;
            // 
            this.scope.shipCollapseMap = this.scope.voyageBaseData.voyage.VESSEL_INFORMATION.map(x => { return false });
            this.scope.hasExceptionStopover = this.scope.voyageBaseData.stopovers.some(x => x.EXCEPTION);
            this.voyageTabOldValue = angular.copy(this.scope.voyageBaseData.voyage);
            //
            const mainShip = this.scope.voyageBaseData.voyage.VESSEL_INFORMATION.find(x => x.MAIN === true);
            this.scope.viewData = {
                showAllStopovers: false,
                travelMainShip: (mainShip) ? mainShip.VESSEL.NAME : null,
                travelShipownersTravel: (mainShip) ? mainShip.CARRIER_INFORMATION.map(x => `${x.CARRIER.CODE}(${x.VOYAGE})`).join(', ') : null,
                stopoverTableData: angular.copy(this.scope.voyageBaseData.stopovers),
                stopoverList: angular.copy(this.scope.voyageBaseData.stopovers),
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    // CONFIRMATION step

    async handleEventStopoversConfirmationInit(): Promise<void> {
        try {

            this.block();

            if (!this.scope.voyageBaseData.stopovers) this.scope.voyageBaseData.stopovers = [];

            this.scope.viewData.travelLocals = angular.copy(this.scope.voyageBaseData.stopovers.filter(x => x.PORT).map(x => ({ ID: x.PORT.ID, NAME: x.PORT.NAME, CODE: x.PORT['ALIAS'] })).filter((x, index, self) => x && index === self.findIndex(t => t.ID === x.ID)));
            this.scope.viewData.eventsStopoverTableData = angular.copy(this.scope.processEvents.map(processEvent => ({
                event: processEvent,
                selected: false,
                hasLocal: (processEvent.LOCAL !== null),
                suggestedStopovers: [],
                viewStopover: null,
                previousEstimatedDate: (processEvent.FORECAST_DATE) ? new Date(processEvent.FORECAST_DATE) : null,
                previousTransportMode: angular.copy(processEvent.TRANSPORT_MODE),
            })));

            this.refreshEventsCompatibleStopovers();

            this.timeout(async () => {
                await this.scope.$applyAsync();
                this.unblock();
            }, 100, true);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async refreshEventsCompatibleStopovers(i?: number): Promise<void> {
        try {
            const isSingleLineChange = (i !== null && typeof i !== 'undefined');
            const serviceProvider = this.scope.processServiceProvider;
            const stopovers = angular.copy(this.scope.voyageBaseData.stopovers);
            const eventTableDataLines = (isSingleLineChange) ? [this.scope.viewData.eventsStopoverTableData[i]] : this.scope.viewData.eventsStopoverTableData;
            for (const tableData of eventTableDataLines) {
                const processEvent = tableData.event;

                // if we changed a specific event local to a port, change LOCAL_TYPE
                if (isSingleLineChange) processEvent.LOCAL_TYPE = { ID: '2', NAME: 'Porto/Aeroporto' }

                let compatibleStopovers: ISelectorModel[] = [];
                if (processEvent.LOCAL_TYPE.ID === '2' && processEvent.LOCAL) {
                    compatibleStopovers = stopovers
                        .filter(stopover => (
                            stopover.PORT && stopover.PORT.CODE === processEvent.LOCAL.CODE &&
                            stopover.SITUATION.NAME !== 'SUGERIDA' &&
                            stopover.VESSEL_INFORMATION &&
                            stopover.VESSEL_INFORMATION.CARRIER_INFORMATION && stopover.VESSEL_INFORMATION.CARRIER_INFORMATION.length &&
                            stopover.VESSEL_INFORMATION.CARRIER_INFORMATION.some(x => x.CARRIER.ID === serviceProvider.ID)
                        ))
                        .map(stopover => ({ ID: stopover.IDENTIFIER, NAME: stopover.VESSEL_INFORMATION.VESSEL.NAME }));
                }
                tableData.suggestedStopovers = compatibleStopovers;
                tableData.viewStopover = compatibleStopovers[0];

                const ind = (isSingleLineChange) ? i : this.scope.viewData.eventsStopoverTableData.findIndex(x => x.event.EVENT_ID === processEvent.EVENT_ID);
                await this.refreshEventsSuggestedDateByStopover(ind);
            }

            if (isSingleLineChange) {
                this.scope.checkJustificationIsRequired(eventTableDataLines[0]);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async refreshEventsSuggestedDateByStopover(i: number): Promise<void> {
        try {
            const eventTableData = this.scope.viewData.eventsStopoverTableData[i];
            const stopover = (eventTableData.viewStopover) ? this.scope.voyageBaseData.stopovers.find(x => x.IDENTIFIER === eventTableData.viewStopover.ID) : null;
            if (!stopover) return;

            // TODO: centralize this on the backend (getTransportMode)
            const shipReference = stopover.VESSEL_INFORMATION;
            if (!shipReference) return;

            const result = await this.calcMaritimeProcessEventEstimatedDate(eventTableData.event, stopover, this.scope.processProduct.CODE);
            const updatedEvent: NewProcessEvent = result;
            const effectiveDate = updatedEvent.EFFECTIVE_DATE;
            const estimatedDate = updatedEvent.FORECAST_DATE;

            const placeboTransportMode: ITransportMode = {
                maritimeVoyagelId: (this.scope.voyageBaseData.voyage._id) ? this.scope.voyageBaseData.voyage._id.toString() : null,
                transportMode: null,
                vessel: shipReference.VESSEL,
                stopover: { ID: stopover.IDENTIFIER, NAME: stopover.CONCAT_REFERENCE },
                stopoverSituation: stopover.SITUATION,
                via: stopover.VIA,
                estimatedDate: estimatedDate,
                effectiveDate: effectiveDate,
                situationToSetEvent: null, // this is unnecessary, updateEventSituation() already handles event situation
                carriers: shipReference.CARRIER_INFORMATION.map(x => ({
                    compatible: (x.CARRIER.ID === this.scope.processServiceProvider.ID),
                    carrierInformation: {
                        CARRIER: x.CARRIER,
                        VOYAGE: x.VOYAGE,
                    },
                    description: `${x.CARRIER.CODE}-${x.VOYAGE}`
                }))
            }

            await this.processEventHelper.applyTransportModeToEvent(eventTableData.event, placeboTransportMode, this.scope.processServiceProvider.ID);

        } catch (ex) {
            this.handleError(ex);
        }
    }

    // 

    private async finish(): Promise<void> {
        try {
            const validFields = this.checkPendingFields('stopoverWizardModalForm');
            if (!validFields) return;

            const operation = this.scope['$parent']['operation'];
            const routerParam = (operation == 'register') ? 'insert' : (operation == 'edit') ? 'update' : null;

            const loadEventChangeMotive: ISelectorModel = this.scope.viewData.loadEventChangeMotive != null ? this.scope.viewData.loadEventChangeMotive : null;
            const loadEventChangeResponsible: ISelectorModel = this.scope.viewData.loadEventChangeResponsible != null ? this.scope.viewData.loadEventChangeResponsible : null;
            const request: IWizardVoyageStopoverRequest = {
                events: null,
                loadChangeMotive: loadEventChangeMotive,
                processNumber: this.scope.processNumber,
                voyage: this.scope.voyageBaseData.voyage,
                stopovers: this.scope.voyageBaseData.stopovers,
                deadlineRecalcOverwriteReviewed: this.scope.deadlineRecalcOverwriteReviewed,
                affectedProcesses: this.scope.affectedProcesses,
                loadChangeResponsible: loadEventChangeResponsible
            }

            if (!operation) this.handleError('operation is null');
            if (!routerParam) this.handleError('routerParam is null');

            if (this.scope.viewData.eventsStopoverTableData) {
                const selectedEvents = this.scope.viewData.eventsStopoverTableData.filter(x => x.selected && x.viewStopover).map(x => x.event);
                const loadIndex = selectedEvents.findIndex(x => x.EVENT_TYPE.ID === '4'); // LOAD
                if (loadIndex > -1) {
                    selectedEvents[loadIndex].MODIFIED_TYPE = loadEventChangeMotive;
                    selectedEvents[loadIndex].MODIFIED_RESPONSIBLE = loadEventChangeResponsible;
                } 
                request.events = selectedEvents;
            }

            this.block();

            let automaticNotificationList: INotificationMessageServiceResult[] = [];

            const objectRequest = {
                data: request,
                timeout: this.defaultTimeout,
            };

            if (operation == 'register') {
                const result = await this.operationalService.put<INotificationMessageServiceResult[]>(`/stopover/wizard`, objectRequest, this.defaultTimeout);
                if (result && result.data && result.data.data) automaticNotificationList = result.data.data;
            } else if (operation == 'edit') {
                const result = await this.operationalService.post<INotificationMessageServiceResult[]>(`/stopover/wizard`, objectRequest, this.defaultTimeout);
                if (result && result.data && result.data.data) automaticNotificationList = result.data.data;
            }

            for (const auto of automaticNotificationList) {
                await NotificationBox.Instance(this.$injector).create(
                    `${auto.processNumber} - ${this.getTranslate("OPERATIONAL.STOPOVER_DEADLINE")}`,
                    (auto.generated) ? this.getTranslate("GENERAL.FOLLOW_SENT") : this.getTranslate("GENERAL.FOLLOW_SAVED"),
                    "fa fa-list-alt",
                    "text-blue",
                    "app.management.notificationMonitor",
                    <INotificationMonitorParameter>{ "MESSAGE_METADATA.VOUCHER": auto.voucher },

                );
            }

            this.ModalService.closeModal(this.scope.eventDetailModalID);

            this.scope.updateEventParentOperation();

            this.notifySuccess(this.getTranslate("GENERAL.OPERATION_SUCCESS"));

            this.scope.modalOptions.ok(false);

            this.unblock();

        } catch (ex) {

            this.ModalService.closeModal(this.scope.eventDetailModalID);

            this.scope.updateEventParentOperation();

            this.scope.modalOptions.ok(false);

            this.unblock();

            this.handleError(ex);

            this.handleWarning(this.getTranslate("GENERAL.PARTIALLY_COMPLETE"));
        }
    }

    private async viewLog(logBlock: string, identifier: string): Promise<void> {
        try {
            const retrieveLog = await this.$RestService.getObjectAsPromise(`${this.operationalService.$route}/stopover/wizard/viewLog/${logBlock}/${identifier}`, this.defaultTimeout, null, false);
            if (retrieveLog && retrieveLog.data) {
                let log: IViewLog = {
                    operation: 'history',
                    number: this.scope.voyageBaseData.voyage.VOYAGE_NUMBER,
                    list: retrieveLog.data,
                    show: true,
                    showCloseButton: false,
                    searchQuery: '',
                    originalList: angular.copy(retrieveLog.data)
                }
                this.scope.log = log;
                this.scope.customLogProperties = this.getCustomLogProperties();
                this.scope.modalID = this.ModalService.newModal();
                this.ModalService.showModalInfo(
                    {
                        modalID: this.scope.modalID,
                        template: require("../view/modal/maritimeStopOverModalLog.html"),
                        formService: 'register',
                        size: 'full modal-overflow',
                        scope: this.scope
                    },
                    {
                        actionButtonClass: 'btn-default',
                        closeButtonText: 'GENERAL.CLOSE',
                        headerText: this.getTranslate('GENERAL.VOYAGE_LOG', { voyageNumber: this.scope.voyageBaseData.voyage.VOYAGE_NUMBER })
                    },
                );

                this.ModalService.closeModal(this.scope.modalID);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: "DRAFT", LABEL: "Draft" },
            { PROPERTY: "DRAFT_IMO", LABEL: "Draft IMO" },
            { PROPERTY: "RELEASE", LABEL: "OPERATIONAL.DEADLINE_RELEASE" },
            { PROPERTY: "VGM", LABEL: "OPERATIONAL.VGM" },
            { PROPERTY: "TYPE", LABEL: "GENERAL.TYPE" },
            { PROPERTY: "VESSEL_INFORMATION", LABEL: "GENERAL.VESSEL_INFO" },
            { PROPERTY: "CARRIER_INFORMATION", LABEL: "GENERAL.CARRIER_INFO" },
            { PROPERTY: "VOYAGE", LABEL: "OPERATIONAL.VOYAGE" },
            { PROPERTY: "CARRIER", LABEL: "BASIC_DATA.SEA_CARRIER" },
            { PROPERTY: "MAIN", LABEL: "GENERAL.MAIN" },
            { PROPERTY: "VESSEL", LABEL: "BASIC_DATA.VESSEL" },
            { PROPERTY: "VOYAGE_NUMBER", LABEL: "OPERATIONAL.VOYAGE" },
            { PROPERTY: "PRODUCT", LABEL: "BASIC_DATA.PRODUCT" },
            { PROPERTY: "CREATED_DATE", LABEL: "GENERAL.CREATED_AT" },
        ];
        return props;
    }
}

import * as angular from 'angular';
import initRoute from './RouteConfig';
import { GRID_FORM_MODULE } from '../gridForm/GridFormModule';
import { GRID_EXCEL_MODULE } from '../gridExcel/GridExcelModule';
import { AdminMenuController } from './controller/AdminMenuController';

import { DataProductService } from '@services/DataProductService';
import { DataProcessService } from '@services/DataProcessService';
import { DataOperationalService } from '@services/DataOperationalService';
import { ScoreService } from '@services/ScoreService';
import { FinopService } from '@services/FinopService';
import { ProductService } from '@services/ProductService';
import { OperationalService } from '@services/OperationalService';

import { ScoreRegisterController } from './controller/ScoreRegisterController';
import { GridSpreadsheetController } from './controller/GridSpreadsheetController';
import { ScoreConfigRegisterController } from './controller/ScoreConfigRegisterController';
import { ConnectedUsersController } from './controller/ConnectedUsersController';
import { ProductConfigurationRegisterController } from './controller/ProductConfigurationRegisterController';
import { GenericRegisterController } from './controller/GenericRegisterController';
import { ServiceStatusController } from './controller/ServiceStatusController';
import { ExchangeRateLogController } from './controller/ExchangeRateLogController';
import { CorporateUnitRegisterController } from './controller/CorporateUnitRegisterController';
import { AnalyzeCollectionDataRegisterController } from './controller/AnalyzeCollectionDataRegisterController';
import { AnalyzeCollectionRegisterController } from './controller/AnalyzeCollectionRegisterController';
import { ErpNatureSetupRegisterController } from './controller/ErpNatureSetupRegisterController';
import { ErpTopSetupRegisterController } from './controller/ErpTopSetupRegisterController';
import { ErpProjectSetupRegisterController } from './controller/ErpProjectSetupRegisterController';
import { ErpNegotiationTypeSetupRegisterController } from './controller/ErpNegotiationTypeSetupRegisterController';
import { ErpPaymentMethodSetupRegisterController } from './controller/ErpPaymentMethodSetupRegisterController';
import { MyAllogOperationalService } from '@services/MyAllogOperationalService';
import { MyAllogProductService } from '@services/MyAllogProductService';

const ADMIN_MODULE = angular
    .module('admin', [GRID_FORM_MODULE.name, GRID_EXCEL_MODULE.name])

    .service('DataProductService', DataProductService)
    .service('DataProcessService', DataProcessService)
    .service('DataOperationalService', DataOperationalService)
    .service('MyAllogOperationalService', MyAllogOperationalService)
    .service('MyAllogProductService', MyAllogProductService)
    .service('ScoreService', ScoreService)
    .service('FinopService', FinopService)
    .service('ProductService', ProductService)
    .service('OperationalService', OperationalService)
    
    .controller('AdminMenuController', AdminMenuController)
    .controller('ConnectedUsersController', ConnectedUsersController)
    .controller('ScoreRegisterController', ScoreRegisterController)
    .controller('ScoreConfigRegisterController', ScoreConfigRegisterController)
    .controller('ProductConfigurationRegisterController', ProductConfigurationRegisterController)
    .controller('GenericRegisterController', GenericRegisterController)
    .controller('ServiceStatusController', ServiceStatusController)
    .controller('ExchangeRateLogController', ExchangeRateLogController)
    .controller('CorporateUnitRegisterController', CorporateUnitRegisterController)
    .controller('AnalyzeCollectionDataRegisterController', AnalyzeCollectionDataRegisterController)
    .controller('AnalyzeCollectionRegisterController', AnalyzeCollectionRegisterController)
    .controller('GridSpreadsheetController', GridSpreadsheetController)

    .controller('ErpNatureSetupRegisterController', ErpNatureSetupRegisterController)
    .controller('ErpTopSetupRegisterController', ErpTopSetupRegisterController)
    .controller('ErpProjectSetupRegisterController', ErpProjectSetupRegisterController)
    .controller('ErpNegotiationTypeSetupRegisterController', ErpNegotiationTypeSetupRegisterController)
    .controller('ErpPaymentMethodSetupRegisterController', ErpPaymentMethodSetupRegisterController)

initRoute(ADMIN_MODULE.name);

export { ADMIN_MODULE };
import { IHttpResponse } from "angular";
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { IMonacoRequest } from '@services/GridFormService';
import { OperationalService } from "@services/OperationalService";
import { OfferCompiled } from "WBA-Model/dist/product/OfferCompiled";
import { ISelectorModel } from "WBA-Model/dist/mongo/SelectorModel";
import { IOfferCompiled } from "WBA-Model/dist/interface/dataProcess/ProcessWizardFilter";
import { Process } from "WBA-Model/dist/interface/operational/NewProcess";
import { ProductService } from "@services/ProductService";
import { DataProcessService } from "@services/DataProcessService";
import { IModalService, IModalOptions } from "@services/ModalService";
import { PermissionService } from '../../app/services/PermissionService';
import { IRestService } from "@services/RestService";
import { IMonacoConfig, IMonacoScope } from '../../common/MonacoInterface';
import { IOfferWizardModalModel } from "src/ts/commercial/model/OfferWizardModalModel";
import { NumberUtil } from "../../common/util/NumberUtil";

interface IPreProcessRegisterScope extends IFormServiceScope {
    register: {
        offerCompiledList: ISelectorModel[];
        selectedOfferCompiled: ISelectorModel;
        processList: ISelectorModel[];
        selectedProcess: ISelectorModel;
        offerCompiledPreProcessList: ISelectorModel[];
        selectOfferCompiledPreProcess: ISelectorModel;
    };
    modalOptions: IModalOptions;

    getOfferCompiledByOfferCodeExhibition: (offerCodeExhibition: string) => Promise<void>;
    getOfferCompiledPreProcessByOfferCodeExhibition: (offerCodeExhibition: string) => Promise<void>;
    getProcessByProcessNumber: (processNumber: string) => Promise<void>;
    generateProcess: () => Promise<void>;
    openManualPreProcessWizard: () => Promise<void>;
}

export class PreProcessRegisterModalController extends FormService2 {
    public static $inject: string[] = ['$injector', '$scope'];
    private scope: IPreProcessRegisterScope;
    private OperationalService: OperationalService;
    private ProductService: ProductService;
    private DataProcessService: DataProcessService;
    private PermissionService: PermissionService;
    private ModalService: IModalService;
    private restService: IRestService;
    private productUrl: string;
    private config: IMonacoConfig;

    constructor($injector: ng.Injectable<any>, $scope: IPreProcessRegisterScope) {
        super($injector, $scope);

        this.scope = $scope;
        this.OperationalService = $injector.get('OperationalService');
        this.ProductService = $injector.get('ProductService');
        this.DataProcessService = $injector.get('DataProcessService');
        this.ModalService = $injector.get('ModalService');
        

        this.PermissionService = new PermissionService($scope, $injector)
    }

    async $onInit(): Promise<void> {
        try {
            this.block();
            this.initializate();
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async initializate() {
        this.init("PreProcessReigster", null, null);
        this.loadRegisterForm(false);
        this.initModel();
        this.initScopeFunctions();
    }


    initModel(): void {
        try {
            this.scope.register = {
                offerCompiledList: null,
                selectedOfferCompiled: null,
                processList: null,
                selectedProcess: null,
                offerCompiledPreProcessList: null,
                selectOfferCompiledPreProcess: null,
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private initScopeFunctions(): void {
        this.scope.getOfferCompiledByOfferCodeExhibition = async (offerCodeExhibition: string): Promise<void> =>
            this.getOfferCompiledByOfferCodeExhibition(offerCodeExhibition);

        this.scope.getProcessByProcessNumber = async (processNumber: string): Promise<void> =>
            this.getProcessByProcessNumber(processNumber);

        this.scope.getOfferCompiledPreProcessByOfferCodeExhibition = async (offerCodeExhibition: string): Promise<void> =>
            this.getOfferCompiledPreProcessByOfferCodeExhibition(offerCodeExhibition)

        this.scope.generateProcess = async (): Promise<void> => this.generateProcess();

        this.scope.openManualPreProcessWizard = async (): Promise<void> => this.openManualPreProcessWizard();
    }

    private async generateProcess(): Promise<void> {
        if (this.scope.register.selectedOfferCompiled) this.openProcessWizard();
        if (this.scope.register.selectedProcess) this.openPreProcessWizard();
        if (this.scope.register.selectOfferCompiledPreProcess) this.openPreProcessOfferWizard();
    }

    private async openPreProcessWizard(): Promise<void> {
        try {
            this.block();

            const timeout: number = 120000;
            const processNumber = this.scope.register.selectedProcess.NAME;

            const permission = await this.PermissionService.isRoleAllowed("PROCESSGENERATEWIZARD");
            if (!permission) return this.PermissionService.showBlockMessage();

            const request: IMonacoRequest = { route: `/preProcessWizardFilter/processToWizard/${processNumber}`, timeout }
            const rc = await this.ProductService.get(request);
            const process: Process = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            this.closeModal();

            const modalId = this.ModalService.newModal();
            await this.ModalService.showModalInfo(
                {
                    modalID: modalId,
                    template: require("../view/wizard/preProcess/preProcessWizardModal.html"),
                    formService: 'register',
                    size: 'full modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: 'REGISTRATION.FILES_WIZARD'
                },
                {
                    model: process
                }
            );
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async openPreProcessOfferWizard(): Promise<void> {
        try {
            this.block();

            const timeout: number = 120000;
            const offerNumber = this.scope.register.selectOfferCompiledPreProcess.ID;

            const permission = await this.PermissionService.isRoleAllowed("PROCESSGENERATEWIZARD");
            if (!permission) return this.PermissionService.showBlockMessage();

            const rc = await this.DataProcessService.get(`/processWizardFilter/preProcessOfferCompiledToWizard/${offerNumber}`, timeout);
            const offerCompiled: OfferCompiled = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            this.closeModal();

            const modalId = this.ModalService.newModal();
            await this.ModalService.showModalInfo(
                {
                    modalID: modalId,
                    template: require("../view/wizard/preProcessOfferCompiled/preProcessOfferCompiledWizardModal.html"),
                    formService: 'register',
                    size: 'full modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: 'REGISTRATION.FILES_WIZARD'
                },
                {
                    model: offerCompiled
                }
            );
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async openProcessWizard(): Promise<void> {
        try {
            this.block();

            const timeout: number = 120000;
            const offerCompiledId = this.scope.register.selectedOfferCompiled.ID;

            const permission = await this.PermissionService.isRoleAllowed("PROCESSGENERATEWIZARD");
            if (!permission) return this.PermissionService.showBlockMessage();

            const rc = await this.DataProcessService.get(`/processWizardFilter/offerCompiledToWizard/${offerCompiledId}`, timeout);
            const offerCompiled: OfferCompiled = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            this.closeModal();

            const modalId = this.ModalService.newModal();
            await this.ModalService.showModalInfo(
                {
                    modalID: modalId,
                    template: require("../view/wizard/process/processWizardModal.html"),
                    formService: 'register',
                    size: 'full modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: 'REGISTRATION.FILES_WIZARD'
                },
                {
                    model: offerCompiled,
                    processRouteRedirect: false
                }
            );
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async openManualPreProcessWizard(): Promise<void> {
        try {
            this.block();

            const permission = await this.PermissionService.isRoleAllowed("PROCESSGENERATEWIZARD");
            if (!permission) return this.PermissionService.showBlockMessage();

            this.closeModal();

            const modalId = this.ModalService.newModal();
            await this.ModalService.showModalInfo(
                {
                    modalID: modalId,
                    template: require("../view/wizard/preProcess/preProcessWizardModal.html"),
                    formService: 'register',
                    size: 'full modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: 'REGISTRATION.FILES_WIZARD'
                }
            );
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getOfferCompiledByOfferCodeExhibition(search: string): Promise<void> {
        try {
            if (search && search.length >= 3) {
                this.block();

                const selectorModelList: ISelectorModel[] = [];
                const request: IMonacoRequest = {
                    data: {
                        search,
                        isValidOffer: true
                    },
                    route: "/offerCompiled/list/custom",
                    timeout: 30000,
                };

                const rc: IHttpResponse<IHttpResponse<IOfferCompiled[]>> = await this.OperationalService.post(request.route, request.data, request.timeout);
                const offerCompiledList = (rc && rc.data && rc.data.data) ? rc.data.data : [];

                for (const item of offerCompiledList) {
                    const selectorModel: ISelectorModel = {
                        ID: item.ID.toString(),
                        NAME: item.OFFER_CODE_EXHIBITION,
                        CODE: item.PRODUCT && item.PRODUCT.CODE
                    }

                    selectorModelList.push(selectorModel);
                }

                this.scope.register.offerCompiledList = selectorModelList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getOfferCompiledPreProcessByOfferCodeExhibition(search: string): Promise<void> {
        try {
            if (search && search.length >= 3) {
                this.block();

                const selectorModelList: ISelectorModel[] = [];
                const request: IMonacoRequest = {
                    data: {
                        search,
                        isValidOffer: true
                    },
                    route: "/offerCompiled/list/custom",
                    timeout: 30000,
                };

                const rc: IHttpResponse<IHttpResponse<IOfferCompiled[]>> = await this.OperationalService.post(request.route, request.data, request.timeout);
                const offerCompiledPreProcessList = (rc && rc.data && rc.data.data) ? rc.data.data : [];

                for (const item of offerCompiledPreProcessList) {
                    const selectorModel: ISelectorModel = {
                        ID: item.ID.toString(),
                        NAME: item.OFFER_CODE_EXHIBITION,
                        CODE: item.PRODUCT && item.PRODUCT.CODE
                    }

                    selectorModelList.push(selectorModel);
                }

                this.scope.register.offerCompiledPreProcessList = selectorModelList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getProcessByProcessNumber(search: string): Promise<void> {
        try {
            if (search && search.length >= 5) {
                this.block();

                const timeout = 120000;

                const request: IMonacoRequest = {
                    data: {
                        term: search,
                        timeout
                    },
                    route: "/process/selector",
                    timeout,
                };

                const rc: IHttpResponse<IHttpResponse<ISelectorModel[]>> = await this.OperationalService.post(request.route, request.data, request.timeout);
                this.scope.register.processList = (rc && rc.data && rc.data.data) ? rc.data.data : [];
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private closeModal(): void {
        this.scope.modalOptions.ok();
    }
}

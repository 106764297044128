CommercialMenuController.$inject = ['$scope', '$window', '$timeout', '$interval', 'RestService', 'blockUI', '$q', '$state', '$stateParams', 'NotificationService', 'config'];

export function CommercialMenuController($scope, $window, $timeout, $interval, RestService, blockUI, $q, $state, $stateParams, NotificationService, config) {
  this.$onInit = () => {
  }

  $scope.goTo = (route) => {
    $state.go(route, { reload: true });
  };
}

import angular = require('angular');
import { IModalOptions, IModalService } from '@services/ModalService';
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { ProductService } from '@services/ProductService';
import { IOfferChargeWeightRange, IOfferChargeWeightListResponse } from '@models/interface/product/OfferChargeWeightRange';
import { IOfferOptionChargeWeightRange } from '@models/interface/quotation/OfferOptionChargeWeightRange';
import { IOfferChargeModel, IChargeName, IPaymentReceivingCharges, IPaymentReceivingCharge } from '@models/interface/product/OfferCharge';
import { IOfferOptionCharge, IChargeName as IOfferOptionChargeName, IPaymentReceivingCharge as IOfferOptionPaymentReceivingCharge, ICharge } from '@models/interface/quotation/OfferOptionCharge';
import { IViewLog, ICustomLogProperties } from '@models/interface/common/IViewLog';
import { IProcessChargeWeightRange } from '@models/interface/operational/ProcessChargeWeightRange';
import { OperationalService } from '@services/OperationalService';

interface IChargesPerWeightRangeModalScope extends IFormServiceScope {
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    modalOptions: IModalOptions;
    isOfferMode: boolean;
    offerId?: number;
    processId?: number;
    offerOptionChargeWeightRangeList?: IOfferOptionChargeWeightRange[];
    offerChargeWeightRangeList: IOfferChargeWeightRange[];
    oldOfferChargeWeightRangeList: IOfferChargeWeightRange[];
    processChargeWeightRangeList: IProcessChargeWeightRange[];
    oldProcessChargeWeightRangeList: IProcessChargeWeightRange[];
    weightRangeActiveIsDisabled: boolean;
    isViewMode?: boolean;
    //general methods
    hasChanges: () => boolean;
    close: () => void;
    save: () => void;
    removeChargeWeightRange: (chargeWeightRangeId: string) => void;
    viewLogChargeWeightRange: (weightRangeId?: string) => void;
    //navigation
    //gets
    //field changes
    chargeWeightRangeActiveChange: (chargeWeightRangeId: string) => void;
    //multilines control
    isRemoveChargeWeightRangeDisabled: (offerChargeWeightRange: IOfferChargeWeightRange) => boolean;
}

export class ChargesPerWeightRangeModalController extends FormService2 {
    static $inject: string[] = ['$injector', '$scope'];
    private scope: IChargesPerWeightRangeModalScope;
    private $q: ng.IQService;
    private ProductService: ProductService;
    private OperationalService: OperationalService;
    private ModalService: IModalService;
    private timeout: ng.ITimeoutService;

    constructor($injector: ng.Injectable<any>, $scope: IChargesPerWeightRangeModalScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.$q = $injector.get('$q');
        this.ProductService = $injector.get('ProductService');
        this.OperationalService = $injector.get('OperationalService');
        this.ModalService = $injector.get('ModalService');
        this.timeout = $injector.get('$timeout');
    }

    async $onInit(): Promise<void> {
        try {
            this.block();
            this.init("chargesPerWeightRangeModalForm", null, null);
            this.initScopeFunctions();
            await this.initDependencies();
            await this.initModel();
            await this.initControl();
            this.openActivePanel();
            this.loadRegisterForm(false);
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    private async initControl() {
        this.scope.isOfferMode = this.scope.offerId != null || this.scope.offerOptionChargeWeightRangeList != null;
        if (this.scope.offerId) await this.getOfferChargeWeightRangeList();
        else if (this.scope.processId) await this.getProcessChargeWeightRangeList();
        else if (this.scope.offerOptionChargeWeightRangeList) this.scope.offerChargeWeightRangeList = this.buildChargeWeightRangeList();
        this.scope.oldOfferChargeWeightRangeList = angular.copy(this.scope.offerChargeWeightRangeList);
        this.scope.oldProcessChargeWeightRangeList = angular.copy(this.scope.processChargeWeightRangeList);
        this.scope.$applyAsync();
    }

    private openActivePanel() {
        this.timeout(() => {
            const activeIndex = this.scope.isOfferMode ? this.scope.offerChargeWeightRangeList.findIndex(chargeWeightRange => chargeWeightRange.ACTIVE) : this.scope.processChargeWeightRangeList.findIndex(chargeWeightRange => chargeWeightRange.ACTIVE);
            if (activeIndex >= 0) angular.element('#collapseWeightRange_' + activeIndex)["collapse"]("show");
        }, 500);
    }

    private initScopeFunctions(): void {

        this.scope.hasChanges = () => {
            return this.hasChanges();
        }

        this.scope.close = () => {
            this.closeOfferChargeWeightRange();
        }

        this.scope.save = () => {
            this.saveOfferChargeWeightRange();
        }

        this.scope.removeChargeWeightRange = (chargeWeightRangeId: string) => {
            this.removeChargeWeightRange(chargeWeightRangeId);
        }

        this.scope.viewLogChargeWeightRange = (weightRangeId?: string) => {
            this.viewLogChargeWeightRange(weightRangeId);
        }

        this.scope.isRemoveChargeWeightRangeDisabled = (offerChargeWeightRange: IOfferChargeWeightRange) => {
            return offerChargeWeightRange && offerChargeWeightRange.ACTIVE;
        }

        this.scope.chargeWeightRangeActiveChange = (chargeWeightRangeId: string) => {
            const chargeWeightRangeList: IOfferChargeWeightRange[] | IProcessChargeWeightRange[] = this.scope.isOfferMode ? this.scope.offerChargeWeightRangeList : this.scope.processChargeWeightRangeList;
            const currentActiveChargeWeightRangeIndex = chargeWeightRangeList && chargeWeightRangeList.length ? chargeWeightRangeList.findIndex(chargeWeightRange => chargeWeightRange.ACTIVE && chargeWeightRange._id != chargeWeightRangeId) : -1;
            if (currentActiveChargeWeightRangeIndex) chargeWeightRangeList[currentActiveChargeWeightRangeIndex].ACTIVE = false;
        }
    }

    async initDependencies(): Promise<any> {
        try {
            const self: ChargesPerWeightRangeModalController = this;
            return new Promise(function (resolve, reject) {
                self.$q.all([

                ]).then((result: any) => {

                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async initModel(): Promise<void> {

    }

    private hasChanges(): boolean {
        return this.scope.isOfferMode ? !angular.equals(this.scope.offerChargeWeightRangeList, this.scope.oldOfferChargeWeightRangeList) : !angular.equals(this.scope.processChargeWeightRangeList, this.scope.oldProcessChargeWeightRangeList);
    }

    private async closeOfferChargeWeightRange(): Promise<void> {
        if (!this.scope.isViewMode && this.hasChanges()) {
            const confirm = await this.ModalService.showModalConfirmation({}, {
                headerText: "GENERAL.CLOSE",
                bodyText: this.getTranslate("REGISTRATION.MESSAGES.ERROR.UPDATE_NOT_SAVED"),
                actionButtonText: "REGISTRATION.SAVE_CONTINUE",
                closeButtonText: "GENERAL.CLOSE"
            });
            if (confirm) return this.saveOfferChargeWeightRange();
        }
        this.scope.modalOptions.ok(false);
    }

    private async saveOfferChargeWeightRange(): Promise<void> {
        let success = false;
        try {
            this.block();
            const request = await this.ProductService.post({ route: "/offer/tabs/chargeWeightRange/update", data: { ID_OFFER: this.scope.offerId, data: this.scope.offerChargeWeightRangeList, oldData: this.scope.oldOfferChargeWeightRangeList } });
            if (request && request.status == 200) {
                success = true;
                this.notifySuccess(this.getTranslate("GENERAL.UPDATE_SUCESSFULLY_PERFORMED"));
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            this.scope.modalOptions.ok(success);
        }
    }

    private async removeChargeWeightRange(chargeWeightRangeId: string): Promise<void> {
        try {
            if (!chargeWeightRangeId) throw Error('chargeWeightRangeId is null');

            const offerChargeWeightRange = this.scope.offerChargeWeightRangeList.find(chargeWeightRange => chargeWeightRange._id == chargeWeightRangeId);
            if (offerChargeWeightRange && (!this.scope.weightRangeActiveIsDisabled || (this.scope.weightRangeActiveIsDisabled && !offerChargeWeightRange.ACTIVE))) {
                const modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: "GENERAL.CONFIRM",
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.CONFIRM_ACTION",
                    bodyText: this.getTranslate("GENERAL.REMOVE_WEIGHT_RANGE")
                });
                if (!modal) return;
                if (offerChargeWeightRange) offerChargeWeightRange.REMOVED = true;
            }

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async viewLogChargeWeightRange(weightRangeId?: string): Promise<void> {
        try {
            this.block();
            let route = this.scope.isOfferMode ? `/offer/tabs/chargeWeightRange/viewLog/${this.scope.offerId}` : `/processChargeWeightRange/viewLog/${this.scope.processId}`;
            if (weightRangeId) route += "/" + weightRangeId;
            const request = this.scope.isOfferMode ? await this.ProductService.get({ route }) : await this.OperationalService.get(route, null);

            const log: IViewLog = {
                operation: 'history',
                number: this.scope.isOfferMode ? this.scope.offerId.toString() : this.scope.processId.toString(),
                list: [],
                show: true,
                showCloseButton: false,
                searchQuery: '',
                originalList: [],
            }

            log.list = request.data.data;
            log.originalList = angular.copy(log.list);
            this.scope.log = log;
            this.scope.customLogProperties = this.getCustomLogProperties();

            const modalId = this.ModalService.newModal();
            this.ModalService.showModalConfirmation(
                {
                    modalID: modalId,
                    scope: this.scope,
                    template: require('../../commercial/view/modals/offerLog.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG"
                }
            );
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getOfferChargeWeightRangeList(): Promise<void> {
        try {
            this.block();
            const request = await this.ProductService.get({ route: "/offer/tabs/chargeWeightRange/list/" + this.scope.offerId });
            if (request && request.data) {
                const response: IOfferChargeWeightListResponse = request.data.data;
                this.scope.weightRangeActiveIsDisabled = response.WEIGHT_RANGE_ACTIVE_IS_DISABLED;
                const sortOfferChargeWeightRange = response.OFFER_CHARGE_WEIGHT_RANGE.sort((x, y) => x.ID < y.ID ? -1 : 1);
                this.scope.offerChargeWeightRangeList = sortOfferChargeWeightRange;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getProcessChargeWeightRangeList(): Promise<void> {
        try {
            this.block();
            const request = await this.OperationalService.get("/processChargeWeightRange/list/" + this.scope.processId, null);
            if (request && request.data) {
                const response: IProcessChargeWeightRange[] = request.data.data;
                const sortProcessChargeWeightRange = response.sort((x, y) => x.ID < y.ID ? -1 : 1);
                this.scope.processChargeWeightRangeList = sortProcessChargeWeightRange;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private buildChargeWeightRangeList(): IOfferChargeWeightRange[] {
        const chargeWeightRangeList: IOfferChargeWeightRange[] = this.scope.offerOptionChargeWeightRangeList && this.scope.offerOptionChargeWeightRangeList.length ?
            this.scope.offerOptionChargeWeightRangeList.map((offerOptionChargeWeightRange) => {
                return {
                    _id: offerOptionChargeWeightRange._id,
                    ID: offerOptionChargeWeightRange.ID,
                    NAME: offerOptionChargeWeightRange.NAME,
                    ACTIVE: offerOptionChargeWeightRange.ACTIVE,
                    REMOVED: false,
                    ID_OFFER: this.scope.offerId,
                    WEIGHT_RANGE_CHARGE: this.buildWeightRangeCharge(offerOptionChargeWeightRange.WEIGHT_RANGE_CHARGE)
                }
            }).sort((x, y) => x.ID < y.ID ? -1 : 1) : null;
        return chargeWeightRangeList;
    }

    private buildWeightRangeCharge(offerOptionChargeList: IOfferOptionCharge[]): IOfferChargeModel[] {
        const weightRangeCharge: IOfferChargeModel[] = offerOptionChargeList && offerOptionChargeList.length ?
            offerOptionChargeList.map((offerOptionCharge) => {
                return {
                    _id: offerOptionCharge._id,
                    ID_OFFER: this.scope.offerId,
                    CHARGE_NAME: this.buildChargeName(offerOptionCharge.CHARGE_NAME),
                    PAYMENT_CHARGE: this.buildPaymentCharge(offerOptionCharge.PAYMENT_CHARGE),
                    RECEIVING_CHARGE: this.buildReceivingCharge(offerOptionCharge.RECEIVING_CHARGE),
                    CHARGE_VALUE: offerOptionCharge.CHARGE_VALUE,
                    CHARGE_VALUE_CURRENT_CURRENCY: offerOptionCharge.CHARGE_VALUE_CURRENT_CURRENCY,
                    EXCHANGE_RATE_INDEX: offerOptionCharge.EXCHANGE_RATE_INDEX,
                    EXCHANGE_BALANCE: offerOptionCharge.EXCHANGE_BALANCE,
                    BALANCE_AMOUNT: offerOptionCharge.BALANCE_AMOUNT,
                    PROFIT_SHARE: offerOptionCharge.PROFIT_SHARE,
                    PROFIT_SHARE_UUID: offerOptionCharge.PROFIT_SHARE_UUID,
                    DATA_ORIGIN_TYPE: offerOptionCharge.DATA_ORIGIN_TYPE,
                    EDITED_AT: null,
                    EDITED_BY: null,
                }
            }) : null;
        return weightRangeCharge;
    }

    private buildChargeName(offerOptionChargeName: IOfferOptionChargeName): IChargeName {
        let charge: IChargeName = null;
        if (offerOptionChargeName) {
            charge = {
                ID: offerOptionChargeName.ID,
                NAME: offerOptionChargeName.NAME,
                TYPE: offerOptionChargeName.TYPE,
                CHARGE_NAME_EXHIBITION: null,
                BILLING: offerOptionChargeName.BILLING,
                DISPLAY_PAYMENT: offerOptionChargeName.DISPLAY_PAYMENT,
                DISPLAY_RECEIVING: offerOptionChargeName.DISPLAY_RECEIVING,
                CONVERT_VALUE_ACTIVE: false,
                CONVERT_VALUE_CURRENCY: null,
            }
        }
        return charge;
    }

    private buildPaymentCharge(offerOptionPaymentCharge: IOfferOptionPaymentReceivingCharge): IPaymentReceivingCharges {
        let charge: IPaymentReceivingCharges = null;
        if (offerOptionPaymentCharge) {
            charge = {
                ...offerOptionPaymentCharge,
                CHARGE: this.buildPaymentReceivingCharge(offerOptionPaymentCharge.CHARGE),
                OFFER_INVOICE: null,
                OBSERVATION: null,
                VALIDATION_RESULT: null,
                TARIFF_COMPOSITION: null,
                PERCENTUAL_COMPOSITION: null,
                HOLDER_CONTRACT: null,
                DATA_ORIGIN_TYPE: null,
            }
        }
        return charge;
    }

    private buildReceivingCharge(offerOptionReceivingCharge: IOfferOptionPaymentReceivingCharge): IPaymentReceivingCharges {
        let charge: IPaymentReceivingCharges = null;
        if (offerOptionReceivingCharge) {
            charge = {
                ...offerOptionReceivingCharge,
                CHARGE: this.buildPaymentReceivingCharge(offerOptionReceivingCharge.CHARGE),
                OFFER_INVOICE: null,
                OBSERVATION: null,
                VALIDATION_RESULT: null,
                TARIFF_COMPOSITION: null,
                PERCENTUAL_COMPOSITION: null,
                HOLDER_CONTRACT: null,
                DATA_ORIGIN_TYPE: null,
            }
        }
        return charge;
    }

    private buildPaymentReceivingCharge(offerOptionPaymentCharge: ICharge): IPaymentReceivingCharge {
        let charge: IPaymentReceivingCharge = null;
        if (offerOptionPaymentCharge) {
            charge = {
                ...offerOptionPaymentCharge,
                EQUIPMENT_ORIGINAL: null,
                WEIGHT_RANGE_ORIGINAL: null,
                VEHICLE_TYPE_ORIGINAL: null,
                ID_MAIN_LEG: null,
                ID_SECONDARY_LEG: null,
                ID_FREIGHT_ROUTES: null,
                ID_INLAND_ROUTES: null,
                ID_TARIFF_COMPLEMENTARY: null,
                TARIFF_COMPLEMENTARY_CONCATENATED: null,
                FREIGHT_ROUTES_CONCATENATED: null,
                INLAND_ROUTES_CONCATENATED: null
            }
        }
        return charge;
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: "ACTIVE", LABEL: "GENERAL.ACTIVE" },
            { PROPERTY: "REMOVED", LABEL: "GENERAL.REMOVED" }
        ];
        return props;
    }
}

import * as config from '../bootstrap/Config';
import { IHttpResponse } from 'angular';
import * as Address from '../communication/Address';

export class HelperService {
    public static $inject: string[] = ['$injector'];
    private unavailableError: string;
    private $http: ng.IHttpService;
    private timeout: number;
    private route: string;

    constructor($injector: ng.Injectable<any>) {
        this.$http = $injector.get('$http');
        this.timeout = 180000; //3minutes
        this.unavailableError = "Serviço WBA-Helper indisponível no momento";

        if (config.default.environment === 'prod') {
            this.route = `${Address.monacoAddressProd.HELPER}/api/v1/helper`;
        } else if (config.default.environment === 'qa') {
            this.route = `${Address.monacoAddressQa.HELPER}/api/v1/helper`;
        } else { //dev
            this.route = `${Address.monacoAddressLocal.HELPER}/api/v1/helper`;
        }
    }

    public get $route(): string {
        return this.route;
    }

    private async request(route: string, data: any, timeout: number = this.timeout, method: string = 'POST') {
        const helper = await this.$http<IHttpResponse<any>>({
            data,
            method,
            timeout,
            cache: false,
            url: `${this.route}${route}`,
        }).catch(err => {
            throw (err.status && err.status == -1) ? new Error(this.unavailableError) : err;
        });
        const response = await helper;
        return response;
    }

    public async get<T = any>(route: string, data: any, timeout: number = this.timeout): Promise<IHttpResponse<IHttpResponse<T>>> {
        try {
            return await this.request(route, data, timeout, 'GET');
        } catch (ex) {
            throw ex;
        }
    }

    public async put<T = any>(route: string, data: any, timeout: number = this.timeout): Promise<IHttpResponse<IHttpResponse<T>>> {
        try {
            return await this.request(route, data, timeout, 'PUT');
        } catch (ex) {
            throw ex;
        }
    }

    public async post<T = any>(route: string, data: any, timeout: number = this.timeout): Promise<IHttpResponse<IHttpResponse<T>>> {
        try {
            return await this.request(route, data, timeout);
        } catch (ex) {
            throw ex;
        }
    }

    public async delete<T = any>(route: string, data: any, timeout: number = this.timeout): Promise<IHttpResponse<IHttpResponse<T>>> {
        try {
            return await this.request(route, data, timeout, 'DELETE');
        } catch (ex) {
            throw ex;
        }
    }
}
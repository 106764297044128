import angular = require('angular');
import * as Address from '../../communication/Address';
import { fileUploader as FileUploader } from 'angular-file-upload';
import { IRestService } from "@services/RestService";
import { FormService2 } from '@services/FormService2';
import { IModalService } from '@services/ModalService';
import { IDownloadParamsReturn } from '@services/DownloadFilesGenericService';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IUploader, IUploadItem, IFileItem, ISuccessItemResponse } from "@models/interface/common/IMonacoUpload";
import { EDocumentTypeId, EFileGroupId, EFileSpecsId, EOperation } from '@enums/GenericData';
import { IOfferReferenceModel, IOfferReference } from '../../commercial/model/OfferModel';
import { IOfferScope, ECollapseState } from '../../commercial/controller/OfferRegisterController';
import { IMonacoConfig } from '../../common/MonacoInterface';
import { SelectorModel } from '../../common/model/SelectorModel';
import { HelperService } from "@services/HelperService";

interface IOfferReferenceScope extends ng.IScope {
    log: IViewLog;
    operation: string;
    model: IOfferReferenceModel;
    oldModel: IOfferReferenceModel;
    documentTypeList: SelectorModel[];
    fileGroupList: SelectorModel[];
    fileSpecsList: SelectorModel[];
    referenceList: IOfferReferenceModel[];
    uploader: IUploader;
    user: any;
    customLogProperties: ICustomLogProperties[];
    collapseReference: () => void;
    addOfferReferenceModal: () => void;
    copyFileLinkToClipboard: (file: IUploadItem[]) => void;
    viewFile: (file: IUploadItem[]) => void;
    downloadFile: (file: IUploadItem[]) => Promise<void>;
    viewOfferReferenceModal: (index: number) => void;
    editOfferReferenceModal: (index: number) => void;
    removeOfferReference: (id: string | object, index: number) => void;
    viewLog: (id?: string) => void;
    isCollapseIn(): boolean;
}

interface IOfferReferenceModalScope extends ng.IScope {
    reference: IOfferReference;
    oldReference: IOfferReference;
    documentTypeList: SelectorModel[];
    fileGroupList: SelectorModel[];
    fileSpecsList: SelectorModel[];
    operation: string;
    closeOfferReferenceModal: () => Promise<void>;
    applyOfferReferenceModal: (close?: boolean) => void;
    documentTypeChange: (documentType: SelectorModel) => void;
    removeUpload: (model: IUploadItem) => boolean;
    viewLog: (id: string) => void;
}


export interface IFormData {
    bucket: string;
    formName: string;
    folder: string;
}

export class OfferReferenceRegisterController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IOfferReferenceScope;
    private $offerScope: IOfferScope;
    private $q: ng.IQService;
    private restService: IRestService;
    private formService: FormService2;
    private modalService: IModalService;
    private modalReferenceId: number;
    private fileUploader: FileUploader;
    private config: IMonacoConfig;
    private formName: string;
    private downloadRoute: string;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IOfferReferenceScope) {
        this.config = $injector.get('config');
        this.$scope = $scope;
        this.$offerScope = <IOfferScope>$scope.$parent.$parent;
        this.$q = $injector.get('$q');
        this.restService = $injector.get('RestService');
        this.formService = this.$offerScope.formService;
        this.modalService = this.$offerScope.modalService;
        this.modalReferenceId = 0;
        this.fileUploader = $injector.get('FileUploader');
        this.initScopeFunctions();
        this.formName = 'offerReference';
        this.helperService = $injector.get('HelperService');

        if (this.config.environment === 'prod') {
            this.downloadRoute = `${Address.monacoAddressProd.FIS}/api/v1/fis/filesGeneric/download`;
        } else if (this.config.environment === 'qa') {
            this.downloadRoute = `${Address.monacoAddressQa.FIS}/api/v1/fis/filesGeneric/download`;
        } else { //dev
            this.downloadRoute = `${Address.monacoAddressLocal.FIS}/api/v1/fis/filesGeneric/download`;
        }
    }

    async $onInit(): Promise<void> {
        try {
            this.initModel();
            await this.initDependencies();
            this.$scope.customLogProperties = this.getCustomLogProperties();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initModel(): void {
        this.$scope.model = {
            REFERENCES: []
        };
    }

    async save(): Promise<boolean> {
        let proceed: boolean = true;
        try {
            if (this.$scope.uploader.queue && this.$scope.uploader.queue.length > 0) {
                proceed = false;
                const msg = this.formService.getTranslate('GENERAL.FILES_IN_THE_QUEUE');
                this.formService.handleError(msg);
            }

        } catch (ex) {
            this.formService.handleError(ex);
            proceed = false;
        }
        return proceed;
    }

    private initScopeFunctions(): void {
        this.$scope.collapseReference = () => {
            this.collapseReference();
        }

        this.$scope.addOfferReferenceModal = async (): Promise<void> => {
            this.addOfferReferenceModal();
        }

        this.$scope.viewOfferReferenceModal = async (index: number): Promise<void> => {
            this.viewOfferReferenceModal(index);
        }

        this.$scope.editOfferReferenceModal = async (index: number): Promise<void> => {
            this.editOfferReferenceModal(index);
        }

        this.$scope.removeOfferReference = (id: string | object, index: number): void => {
            this.removeOfferReference(id, index);
        }

        this.$scope.copyFileLinkToClipboard = (files: IUploadItem[]) => {
            if (files.length > 0) {
                const elmAux = document.createElement("textarea");
                document.body.appendChild(elmAux);
                elmAux.value = files[0].FILE_URL;
                elmAux.select();
                document.execCommand("copy");
                document.body.removeChild(elmAux);
                this.formService.notifySuccess("Link do arquivo foi copiado para o clipboard.");
            }
        }

        this.$scope.viewFile = (files: IUploadItem[]): void => {
            if (files.length > 0) window.open(files[0].FILE_URL, "_blank");
        }

        this.$scope.downloadFile = async (files: IUploadItem[]): Promise<void> => {
            if (files.length > 0) this.downloadFileFromGoogleStorage(files[0].FILE_HASH);
        }

        this.$scope.viewLog = (id: string) => {
            this.viewLog(id);
        }

        this.$scope.isCollapseIn = (): boolean => {
            return this.$offerScope.collapseState.panel == ECollapseState.REFERENCE && !this.$offerScope.collapseState.released;
        }

    }

    getFisFilesGenericRoute(): string {
        const baseRoute = '/fis/filesGeneric';
        const uploadRoute = this.config.fisUrl + baseRoute;
        return uploadRoute;
    }

    async initDependencies(): Promise<any> {
        const self: OfferReferenceRegisterController = this;

        this.initCollapseEvents();

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getDocumentTypeList(),
                self.getFileGroupList(),
                self.getFileSpecsList(),
            ]).then(async (result: any) => {
                self.$scope.documentTypeList = result[0];
                self.$scope.fileGroupList = result[1];
                self.$scope.fileSpecsList = result[2];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async initCollapseEvents() {
        this.$scope.$on('offerReferenceCollapse', () => {
            this.collapseReference();
        });

        const collapseReference = angular.element("#collapseReferences");
        if (collapseReference) {
            collapseReference.on('shown.bs.collapse', (event: JQuery.Event) => {
                angular.element("#referencesLog").show();
                if (event.target == event.currentTarget) {
                    angular.element("#collapseReferenceHeading").attr('aria-expanded', 'true');
                    // update collapse state
                    this.$offerScope.collapseState = { panel: ECollapseState.REFERENCE, released: false, nextState: null };
                    this.$offerScope.repositionPanels('referencesRow', true);
                    this.getOfferTabsReference();
                }
            });
            collapseReference.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                angular.element("#referencesLog").hide();
                this.$scope.model.REFERENCES = [];
                if (event.target == event.currentTarget) {
                    angular.element("#collapseReferenceHeading").attr('aria-expanded', 'false');
                }
            });
        }
    }

    private async collapseReference() {
        try {
            if (this.$offerScope.collapseState.released || this.$offerScope.collapseState.panel == ECollapseState.REFERENCE) {
                const collapseReference = angular.element("#collapseReferences");
                if (collapseReference) {
                    const isCollapsed = angular.element("#collapseReferenceHeading").attr("aria-expanded") == "true";
                    collapseReference['collapse']('toggle');
                    if (isCollapsed) {
                        this.$offerScope.collapseState.released = true;
                        this.$offerScope.repositionPanels('referencesRow');
                    }
                }
            } else {
                this.$offerScope.collapseState.nextState = ECollapseState.REFERENCE;
                this.$offerScope.releaseCollapse();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getOfferTabsReference(): Promise<void> {
        this.formService.block();
        try {
            const referenceTab = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/reference/view/${this.$offerScope.model.ID}`, 30000, null, false);
            if (referenceTab && referenceTab.data) this.$scope.model.REFERENCES = referenceTab.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.oldModel = angular.copy(this.$scope.model);
            this.formService.unblock();
        }
    }

    public async downloadFileFromGoogleStorage(hash: string): Promise<void> {
        try {
            this.formService.block();
            if (!hash) return null;

            const operation = await this.restService.getObjectAsPromise(`${this.downloadRoute}/${hash}`, 30000, null, false);
            const response: angular.IHttpResponse<any> = operation;
            if (response && response.data) {
                const resultFile = <IDownloadParamsReturn>response.data;

                const file = resultFile.file;
                if (!resultFile.buffer) {
                    throw Error('Failed to get file information');
                }
                const buffer = resultFile.buffer.data;
                if (!file || !buffer) {
                    throw Error('Failed to get file information');
                }
                const fileName = file.fileName;
                const fileBuffer = new Uint8Array(buffer);
                const fileType = this.getContentType(file.fileType);
                const fileBlob = new Blob([fileBuffer], { type: fileType });
                const fileURL = window.URL.createObjectURL(fileBlob);

                let link = document.createElement('a');
                link.href = fileURL;
                link.download = fileName;
                link.click();
            } else {
                throw Error('Error on download file')
            }

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private getContentType(fileType: string): string {
        switch (fileType.toLowerCase()) {
            case 'arc':
                return 'application/octet-stream';
            case 'bin':
                return 'application/octet-stream';
            case 'avi':
                return 'video/x-msvideo';
            case 'azw':
                return 'application/vnd.amazon.ebook';
            case 'gif':
                return 'image/gif';
            case 'htm':
                return 'text/html';
            case 'html':
                return 'text/html';
            case 'ico':
                return 'image/x-icon';
            case 'ics':
                return 'text/calendar';
            case 'js':
                return 'application/javascript';
            case 'json':
                return 'application/json';
            case 'mpeg':
                return 'video/mpeg';
            case 'jpeg':
                return 'image/jpeg';
            case 'jpg':
                return 'image/jpg';
            case 'png':
                return 'image/png';
            case 'pdf':
                return 'application/pdf';
            case 'rar':
                return 'application/x-rar-compressed';
            case 'zip':
                return 'application/zip';
            case '7z':
                return 'application/x-7z-compressed';
            case 'ppt':
                return 'application/vnd.ms-powerpoint';
            case 'csv':
                return 'text/csv';
            case 'doc':
                return 'application/msword';
            case 'xls':
                return 'application/vnd.ms-excel';
            case 'xlsx':
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            case 'vsd':
                return 'application/vnd.visio';
            case 'ts':
                return 'application/typescript';
            case 'rtf':
                return 'application/rtf';
            default:
                return 'text/plain';
        }
    }

    private async getDocumentTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/document_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getFileGroupList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/file_group`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getFileSpecsList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/file_specs`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async addOfferReferenceModal(): Promise<void> {
        try {
            this.$scope.uploader = this.getFileUploaderDefault();
            this.modalReferenceId = this.modalService.newModal();

            this.modalService.showModalInfo(
                {
                    modalID: this.modalReferenceId,
                    scope: this.$scope,
                    formService: 'register',
                    template: require("../view/modals/offerReferenceModal.html"),
                    size: 'lg'
                },
                null
            );

            const reference: IOfferReference = {
                _id: null,
                ID_OFFER: null,
                DOCUMENT_TYPE: null,
                REFERENCE: null,
                FILE_GROUP: null,
                FILE_SPECS: null,
                MAIN_REFERENCE: null,
                USER_DATE_INSERT: null,
                DATE: new Date(),
                FILES: null,
                FILES_REMOVE: null,
                FILES_NEW: null,
            }
            await this.buildReferenceModalScope(reference, 'register');
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async viewOfferReferenceModal(index: number): Promise<void> {
        try {
            this.$scope.uploader = this.getFileUploaderDefault();
            this.modalReferenceId = this.modalService.newModal();

            this.modalService.showModalInfo(
                {
                    modalID: this.modalReferenceId,
                    scope: this.$scope,
                    formService: 'register',
                    template: require("../view/modals/offerReferenceModal.html"),
                    size: 'lg'
                },
                null
            );

            const reference: IOfferReference = this.$scope.model.REFERENCES[index];
            await this.buildViewReferenceModalScope(reference);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeOfferReference(id: string | Object, index: number): Promise<void> {
        try {
            if (!id && id != 0) throw Error('id is null');
            if (this.$scope.operation == EOperation.VIEW) return
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            this.formService.block();
            const result = await this.restService.deleteObject(`${this.$offerScope.productUrl}/offer/tabs/reference/${id}`, 30000, false);
            const msg = this.formService.getTranslate('GENERAL.VALUES_NOT_CONVERTED_TRY_AGAIN');
            if (!result)
                return this.formService.notifyError(msg);

            this.$scope.model.REFERENCES.splice(index, 1);
            const msgSuccess = this.formService.getTranslate('GENERAL.REGISTER_DELETED_SUCCESSFULLY');
            this.formService.notifySuccess(msgSuccess);
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async editOfferReferenceModal(index: number): Promise<void> {
        try {
            if (this.$scope.operation == EOperation.VIEW) return
            this.$scope.uploader = this.getFileUploaderDefault();
            this.modalReferenceId = this.modalService.newModal();

            this.modalService.showModalInfo(
                {
                    modalID: this.modalReferenceId,
                    scope: this.$scope,
                    formService: 'register',
                    template: require("../view/modals/offerReferenceModal.html"),
                    size: 'lg'
                },
                null
            );

            const reference: IOfferReference = this.$scope.model.REFERENCES[index];
            await this.buildReferenceModalScope(reference, 'edit');
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async buildReferenceModalScope(reference: IOfferReference, operation: string): Promise<IOfferReferenceModalScope> {
        const modalScope: IOfferReferenceModalScope = await this.modalService.getModalScope(this.modalReferenceId);
        modalScope.operation = operation;
        modalScope.documentTypeList = this.$scope.documentTypeList;
        modalScope.fileGroupList = this.$scope.fileGroupList;
        modalScope.fileSpecsList = this.$scope.fileSpecsList;

        if (modalScope.operation == 'register') {
            reference.MAIN_REFERENCE = false;

            const fileGroup: SelectorModel = modalScope.fileGroupList.find(file => file.ID == EFileGroupId.COMERCIAL);
            if (fileGroup) reference.FILE_GROUP = fileGroup;

            const fileSpec: SelectorModel = modalScope.fileSpecsList.find(specs => specs.ID == EFileSpecsId.CLIENTE);
            if (fileSpec) reference.FILE_SPECS = fileSpec;

            const docType: SelectorModel = modalScope.documentTypeList.find(doc => doc.ID == EDocumentTypeId.COMMERCIAL_INVOICE);
            if (fileSpec) reference.DOCUMENT_TYPE = docType;

            reference.ID_OFFER = this.$offerScope.model.ID;
        }

        modalScope.reference = angular.copy(reference);
        modalScope.oldReference = angular.copy(reference);

        this.$scope.uploader.onSuccessItem = (item: IFileItem, response: ISuccessItemResponse) => this.onSuccessItem(item, response, reference)

        if (modalScope.operation == 'edit') {
            modalScope.applyOfferReferenceModal = async (close?: boolean) => {
                if (!this.$offerScope.hasInvalidRequiredElements('modalReferenceForm')) {
                    if (await this.checkMainReference(modalScope.reference))
                        this.updateOfferReference(modalScope.reference, modalScope.oldReference, modalScope.operation, close)

                }
            };
        } else {
            modalScope.applyOfferReferenceModal = async (close?: boolean) => {
                if (!this.$offerScope.hasInvalidRequiredElements('modalReferenceForm')) {
                    if (await this.checkMainReference(modalScope.reference)) {
                        if (this.applyOfferReference(modalScope.reference, modalScope.operation, close)) {
                            modalScope.oldReference = angular.copy(reference);
                        }
                    }

                }
            };
        }

        modalScope.closeOfferReferenceModal = async (): Promise<void> => {
            this.modalService.closeModal(this.modalReferenceId);
            this.modalReferenceId = 0;
            this.$scope.uploader.destroy();
            $('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
        }

        modalScope.documentTypeChange = (optionDocumentType: SelectorModel) => {
            if (optionDocumentType) {
                if (optionDocumentType.ID == EDocumentTypeId.OFERTA) {
                    const fileGroup: SelectorModel = modalScope.fileGroupList.find(group => group.ID == EFileGroupId.COMERCIAL);
                    if (fileGroup) {
                        modalScope.reference.FILE_GROUP = fileGroup;
                    }
                    const fileSpec: SelectorModel = modalScope.fileSpecsList.find(specs => specs.ID == EFileSpecsId.CLIENTE);
                    if (fileSpec) {
                        modalScope.reference.FILE_SPECS = fileSpec;
                    }
                }
            }
        }

        modalScope.removeUpload = (model: IUploadItem): boolean => {
            return this.removeUpload(model, modalScope.reference);
        }

        return modalScope;
    }

    private async buildViewReferenceModalScope(reference: IOfferReference): Promise<IOfferReferenceModalScope> {
        const modalScope: IOfferReferenceModalScope = await this.modalService.getModalScope(this.modalReferenceId);
        modalScope.operation = EOperation.VIEW;
        modalScope.reference = angular.copy(reference);
        modalScope.closeOfferReferenceModal = async (): Promise<void> => {
            this.modalService.closeModal(this.modalReferenceId);
            this.modalReferenceId = 0;
            $('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
        }

        return modalScope;
    }

    private async applyOfferReference(reference: IOfferReference, operation: string, close?: boolean): Promise<void> {
        try {
            if (!reference) throw Error('reference is null');
            if (operation === "register") {
                const result = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/reference/insert`, { data: reference }, 30000, false);
                const msg = this.formService.getTranslate('GENERAL.VALUES_NOT_CONVERTED_TRY_AGAIN');
                if (!result)
                    return this.formService.notifyError(msg);
            }
            if (close) {
                this.modalService.closeModal(this.modalReferenceId);
                this.modalReferenceId = 0;
                $('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
            }
            await this.getOfferTabsReference();
            const msgSuccess = this.formService.getTranslate('GENERAL.SUCCESSFULLY_REGISTERED');
            this.formService.notifySuccess(msgSuccess);
            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async updateOfferReference(reference: IOfferReference, oldReference: IOfferReference, operation: string, close?: boolean): Promise<void> {
        try {
            if (!reference) throw Error('reference is null');
            if (!oldReference) throw Error('oldReference is null');
            if (operation === "edit") {
                const result = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/reference/update`, { data: reference, oldData: oldReference }, 30000, false);
                const msg = this.formService.getTranslate('GENERAL.VALUES_NOT_CONVERTED_TRY_AGAIN');
                if (!result)
                    return this.formService.notifyError(msg);
            }
            if (close) {
                this.modalService.closeModal(this.modalReferenceId);
                this.modalReferenceId = 0;
                $('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
            }

            await this.getOfferTabsReference();
            const msgSuccess = this.formService.getTranslate('GENERAL.SUCCESSFULLY_REGISTERED');
            this.formService.notifySuccess(msgSuccess);
            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async checkMainReference(reference: IOfferReference): Promise<boolean> {
        try {
            if (!reference) throw Error('reference is null');
            if ((reference.FILE_SPECS.ID == EFileSpecsId.CLIENTE) && reference.MAIN_REFERENCE) {
                const findMainreference: IOfferReference = this.$scope.model.REFERENCES.find(ref => ref.MAIN_REFERENCE == true && (ref.FILE_SPECS.ID == EFileSpecsId.CLIENTE))
                if (findMainreference && findMainreference.REFERENCE != reference.REFERENCE) {
                    const modal = await this.modalService.showModalConfirmation({}, {
                        actionButtonText: 'GENERAL.CONFIRM',
                        headerText: 'GENERAL.CONFIRM_ACTION',
                        bodyText: this.formService.getTranslate('PRODUCT.IF_YOU_PROCEED_CHANGE_MAIN_REFERENCE')
                    });
                    return modal;
                }

            }
            return true;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'offer_files', formName: this.formName, folder: this.formName };
            return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData], onSuccessItem: (item: IFileItem, response: ISuccessItemResponse) => this.onSuccessItem(item, response) });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private removeUpload(model: IUploadItem, reference: IOfferReference): boolean {
        let result = false;
        try {
            const isAdminOrCoordinator = (this.$scope.user.roles && (this.$scope.user.roles.hasOwnProperty('ADMIN') || this.$scope.user.roles.hasOwnProperty('COORDINATOR')));

            if (isAdminOrCoordinator) {
                result = true;
            } else {
                // check if in model.FILES_NEW;
                if (reference.FILES_NEW && reference.FILES_NEW.length > 0) {
                    const index = reference.FILES_NEW.findIndex(file => { return file.FILE_HASH == model.FILE_HASH });
                    if (index > -1) {
                        // Remove from FILES_NEW
                        reference.FILES_NEW.splice(index, 1);
                        result = true;
                    }
                }
            }

            if (result) {
                if (!reference.FILES_REMOVE) reference.FILES_REMOVE = [];
                reference.FILES_REMOVE.push(model);
            } else {
                throw Error('You don\'t have permission to remove this file');
            }
        } catch (ex) {
            result = false;
            this.formService.handleError(ex);
        } finally {
            return result;
        }
    }

    private onSuccessItem(item: IFileItem, response: ISuccessItemResponse, reference?: IOfferReference): void {
        try {
            if (!item) throw Error('item parameter is null');
            if (!response) throw Error('response parameter is null');

            if (response.data && response.data.length > 0) {
                if (!reference.FILES_NEW) reference.FILES_NEW = [];

                for (let dataItem of response.data) {
                    const file: IUploadItem = {
                        FILE_DISPLAY_NAME: dataItem.displayFileName,
                        FILE_NAME: dataItem.fileName,
                        FILE_TYPE: dataItem.fileType,
                        FILE_PATH: dataItem.filePath,
                        FILE_URL: dataItem.fileUrl,
                        FILE_HASH: dataItem.fileHash,
                        FILE_SIZE: dataItem.fileSize,
                        FORM_NAME: dataItem.formName,

                    }
                    reference.FILES_NEW.push(file)
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async viewLog(id?: string) {
        this.formService.block();
        let log: IViewLog = {
            operation: 'history',
            number: this.$offerScope.model.ID.toString(),
            list: [],
            show: true,
            searchQuery: '',
            originalList: [],
        }
        this.requestHistory(id).then(result => {
            log.list = result.data;
            log.originalList = angular.copy(log.list);
            log.showCloseButton = false;
            this.$scope.log = log;
            this.formService.unblock();
        }).catch(ex => {
            this.formService.handleError(ex);
        });

        const modalId = this.modalService.newModal();
        await this.modalService.showModalConfirmation(
            {
                modalID: modalId,
                scope: this.$scope,
                template: require('../view/modals/offerLog.html'),
                size: 'full'
            },
            {
                closeButtonText: "GENERAL.CLOSE",
                headerText: "GENERAL.GRID.LOG",
            }
        );
    }

    private requestHistory(id: string): Promise<any> {
        return this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/reference/viewlog/${this.$offerScope.model.ID}${id ? '/' + id : ''}`, 10000, null, false);
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'ID_OFFER',
                LABEL: 'BASIC_DATA.OFFER'
            },
            {
                PROPERTY: 'DOCUMENT_TYPE',
                LABEL: 'REGISTRATION.DOCUMENT_TYPE'
            },
            {
                PROPERTY: 'REFERENCE',
                LABEL: 'REGISTRATION.REFERENCE'
            },
            {
                PROPERTY: 'FILE_GROUP',
                LABEL: 'GENERAL.GROUP'
            },
            {
                PROPERTY: 'FILE_SPECS',
                LABEL: 'REGISTRATION.CHARACTERISTIC'
            },
            {
                PROPERTY: 'MAIN_REFERENCE',
                LABEL: 'GENERAL.MAIN'
            },
            {
                PROPERTY: 'DATE',
                LABEL: 'GENERAL.DATE'
            },
            {
                PROPERTY: 'USER_DATE_INSERT',
                LABEL: 'GENERAL.USER'
            },
            {
                PROPERTY: 'FILES',
                LABEL: 'REGISTRATION.FILES'
            },
            {
                PROPERTY: 'FILE_DISPLAY_NAME',
                LABEL: 'FINANCIAL.CHARGE_DISPLAY_NAME'
            },
            {
                PROPERTY: 'FILE_NAME',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'FILE_SIZE',
                LABEL: 'PRODUCT.SIZE'
            },
            {
                PROPERTY: 'FILE_TYPE',
                LABEL: 'GENERAL.TYPE'
            },
            {
                PROPERTY: 'FILE_PATH',
                LABEL: 'GENERAL.PATH'
            },
            {
                PROPERTY: 'FILE_URL',
                LABEL: 'REGISTRATION.FILE_URL'
            },
            {
                PROPERTY: 'FILE_HASH',
                LABEL: 'GENERAL.HASH'
            },
            {
                PROPERTY: 'FORM_NAME',
                LABEL: 'GENERAL.FORM_NAME'
            },
            {
                PROPERTY: 'FILES_REMOVE',
                LABEL: 'GENERAL.REMOVED_FILES'
            },
            {
                PROPERTY: 'FILES_NEW',
                LABEL: 'GENERAL.ADDED_FILES'
            },
        ]
        return props;
    }

}

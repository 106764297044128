import angular = require('angular');
import { fileUploader as FileUploader } from 'angular-file-upload';
import { IRestService } from "@services/RestService";
import { FormService2, IFormServiceScope } from '@services/FormService2';
import { IModalService } from '@services/ModalService';
import { IUploader, IUploadItem, IFileItem, ISuccessItemResponse } from "@models/interface/common/IMonacoUpload";
import { ICustomLogProperties } from "@models/interface/common/IViewLog";
import { ICommodity } from '@models/interface/product/CommodityModel';
import { ISelectorModel } from '@models/mongo/SelectorModel';
import { IViewLog } from "@models/interface/common/IViewLog";
import { EDocumentTypeId, EFileGroupId, EFileSpecsId, EDataOriginTypeId, EOperation } from '@enums/GenericData';
import { EOfferLogsName } from '@enums/Offer';
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { IOfferScope, ECollapseState } from '../../commercial/controller/OfferRegisterController';
import { SelectorModel } from "../../common/model/SelectorModel";
import { IOfferMainModel, IRepresentativeEvent, IFreightDomestic, IFreightDomesticLocal, IRepresentatives, IOfferReferenceListCustomFilter, IOfferReference, IAccountReference, IOfferCommodity, IOfferCommoditySummary } from '../../commercial/model/OfferModel';
import { ILegalPersonListCustomFilter, ILegalPersonSelector } from '../../registration/model/LegalPersonModel';
import { ITariffLocalImporterExporterListCustomFilter, ETariffLocalImporterExporterListRoutePath } from '../../product/model/TariffLocalModel';
import { IBrokerListCustomFilter, IAgencyListCustomFilter, IBrokerSelector } from '../../registration/model/BrokerModel';
import { IAgencySelector } from '../../registration/model/AgencyModel';
import { IAgentSelector, IAgentListByNetworkFilter } from '../../registration/model/AgentModel';
import { IPhysicalPersonSelectorModel, IPhysicalPersonListCustomFilter } from '../../registration/model/PhysicalPersonModel';
import { IMonacoConfig } from '../../common/MonacoInterface';
import { ITransporter } from 'WBA-Model/dist/product/Offer';
import { EPhysicalPersonSpecializationId } from "@enums/PhysicalPerson";
import { HelperService } from "@services/HelperService";
import { ISessionService } from '@services/SessionService';
import { ILinkParameter } from 'src/ts/common/model/ModelParameter';

interface IOfferMainModalityResult {
    HOUSE_MODALITY: SelectorModel;
    MASTER_MODALITY: SelectorModel;
}

interface IOfferCommodityRequestViewResult {
    COMMODITY_EXCEPTIONS: ICommodity[];
    COMMODITY_ITEM: IOfferCommodity[];
    COMMODITY_ITEM_WIZARD: IOfferCommodity[];
}

export interface IFormData {
    bucket: string;
    formName: string;
    folder: string;
}

interface IOfferMainScope extends ng.IScope {
    log: IViewLog;
    model: IOfferMainModel;
    oldModel: IOfferMainModel;
    accountReference: IAccountReference;
    eventList: IRepresentativeEvent[];
    incotermList: SelectorModel[];
    exporterList: SelectorModel[];
    importerList: SelectorModel[];
    notifyList: SelectorModel[];
    brokerList: IBrokerSelector[];
    agencyList: IAgencySelector[];
    agentList: IAgentSelector[];
    commercialUnityList: SelectorModel[];
    collaboratorList: IPhysicalPersonSelectorModel[];
    offerReferenceList: SelectorModel[];
    accountTypeList: SelectorModel[];
    corporateBranchList: SelectorModel[];
    documentTypeList: SelectorModel[];
    fileGroupList: SelectorModel[];
    fileSpecsList: SelectorModel[];
    providerList: ITransporter[];
    legalPersonCustomList: ITransporter[];
    uploader: IUploader;
    user: any;
    customLogProperties: ICustomLogProperties[];
    isAir: boolean;
    sessionService: ISessionService;
    openRepresentativesModal: () => void;
    openCommoditiesModal: () => void;
    incotermChange: () => void;
    agentChange: () => void;
    collapseMain: () => void;
    hasChanges: () => boolean;
    getExporterListByName: (search: string) => Promise<void>;
    getIncotermListByName: (search: string) => Promise<void>;
    getImporterListByName: (search: string) => Promise<void>;
    getNotifyListByName: (search: string) => Promise<void>;
    getBrokerListByName: (search: string) => Promise<void>;
    getAgencyListByName: (search: string) => Promise<void>;
    getAgentListByName: (search: string, isExternalAgent: boolean) => Promise<void>;
    getProviderListByName: (search: string) => Promise<void>;
    getLegalPersonListByNameCustom: (search: string) => Promise<void>;
    getCollaboratorListByName: (search: string) => Promise<void>;
    getCorporateBranchListByName: (search: string) => Promise<void>;
    getOfferReferenceListByName: (search: string) => Promise<void>;
    updateAccountType: (selected: ILegalPersonSelector) => void;
    requirementCorporateAccountChange: (value: boolean) => void;
    changeReference: (selected: SelectorModel, oldSelected: string) => void;
    addOfferReferenceModal: () => void;
    viewLog: (logBlock: string) => Promise<void>;
    viewLogMainBasic: () => Promise<void>;
    viewLogMainInvolved: () => Promise<void>;
    viewLogMainAccount: () => Promise<void>;
    buildCommodityView: (commoditySummary: IOfferCommoditySummary) => string;
    buildExceptionsView: (commoditySummary: IOfferCommoditySummary) => string;
    buildExceptionTooltip: (commoditySummary: IOfferCommoditySummary) => string;
    isCollapseIn(): boolean;
    getCommercialUnityListByName: (search: string) => Promise<void>;
    isAirProduct(): boolean;
    goToLegalPerson: (type: string) => void;
}
interface IRepresentativeList extends SelectorModel {
    ID_LEGAL_PERSON: number,
    ID_PHYSICAL_PERSON: number,
}
interface IRepresentativeModalScope extends ng.IScope {
    operation: string;
    representativeList: IRepresentativeList[];
    representatives: IRepresentatives;
    oldRepresentatives: IRepresentatives;
    addRepresentative: () => void;
    removeRepresentative: (index: number) => void;
    applyRepresentative: (close?: boolean) => void;
    closeRepresentativeModal: () => Promise<void>;
    representativeEventSelect: (index: number, selected: IRepresentativeEvent) => void;
    getRepresentativeListByName: (search: string) => Promise<void>;
    getRepresentativeList: (search: string) => Promise<void>;
}

interface ICommodityModalScope extends IFormServiceScope {
    operation: string;
    sectionList: SelectorModel[];
    commodityList: SelectorModel[];
    commodities: IOfferCommodity[];
    oldCommodities: IOfferCommodity[];
    exceptions: ICommodity[];
    oldExceptions: ICommodity[];
    addCommodity: () => void;
    removeCommodity: (index: number) => void;
    applyCommodity: (close?: boolean) => void;
    closeCommodityModal: () => Promise<void>;
    getCommodityListByName: (search: string) => Promise<void>;
    commodityChange: (index: number) => void;
    isCommodityDisabled: (commodity: IOfferCommodity, fieldName: string) => boolean;
}

interface IOfferReferenceModalScope extends ng.IScope {
    reference: IOfferReference;
    oldReference: IOfferReference;
    documentTypeList: SelectorModel[];
    fileGroupList: SelectorModel[];
    fileSpecsList: SelectorModel[];
    operation: string;
    from: string;
    closeOfferReferenceModal: () => Promise<void>;
    applyOfferReferenceModal: (close?: boolean) => void;
    documentTypeChange: (documentType: SelectorModel) => void;
    removeUpload: (model: IUploadItem) => boolean;
    viewLog: (id: string) => void;
}

export class OfferMainRegisterController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IOfferMainScope;
    private $offerScope: IOfferScope;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private restService: IRestService;
    private formService: FormService2;
    private modalService: IModalService;
    private representativeModalId: number;
    private commoditiesModalId: number;
    private fileUploader: FileUploader;
    private modalReferenceId: number;
    private config: IMonacoConfig;
    private formName: string;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IOfferMainScope) {
        this.$scope = $scope;
        this.$offerScope = <IOfferScope>$scope.$parent.$parent;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.restService = $injector.get('RestService');
        this.config = $injector.get('config');
        this.formService = this.$offerScope.formService;
        this.modalService = this.$offerScope.modalService;
        this.representativeModalId = 0;
        this.commoditiesModalId = 0;
        this.fileUploader = $injector.get('FileUploader');
        this.modalReferenceId = 0;
        this.formName = 'offerReferenceMain';
        this.initScopeFunctions();
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.initModel();
            await this.initDependencies();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initModel(): void {
        this.$scope.model = {
            ACCOUNT: null,
            AGENCY: null,
            BROKER: null,
            DESTINATION: null,
            EXPORTERS: null,
            EXTERNAL_AGENT: null,
            HOUSE_MODALITY: null,
            ID_NEW_FREIGHT_CONTRACT: null,
            ID_NEW_FREIGHT_CONTRACT_RECEIVING: null,
            ID_TARIFF_FREIGHT_CONTRACT: null,
            ID_TARIFF_FREIGHT_CONTRACT_RECEIVING: null,
            IMPORTERS: null,
            INCOTERM: null,
            LOAD_REF: null,
            LOCAL_AGENT: null,
            MASTER_DIRECT: null,
            MASTER_MODALITY: null,
            MOVE_TYPE: null,
            NOTIFY: null,
            ORIGIN: null,
            OTHER_AGENT: null,
            TRANSPORTER_PICKUP: null,
            TRANSPORTER_PLACE_RECEPT: null,
            TRANSPORTER_FINAL_DESTINATION: null,
            TRANSPORTER_DELIVERY: null,
            TRANSPORTER_GATE_OUT_EMPTY: null,
            TRANSPORTER_CONTAINER_STUFFING: null,
            FLEXI_FITTING: null,
            ISOTANK_PROVIDER: null,
            PROCESS_TYPE: null,
            PROVIDER: null,
            TYPE_CARGO: null,
            REPRESENTATIVES: null,
            PUP: null,
            DTFS: null,
            OTFS: null,
            PLD: null,
            PODAOD: null,
            POLAOL: null,
            REPLICATION_DATA: null,
            COMMERCIAL_UNITY: null,
            COMMODITY_CURRENCY: null,
            COMMODITY_VALUE: null,
            COMMODITY_INVOICE_VALUE: null
        };
        this.$scope.oldModel = angular.copy(this.$scope.model);

        this.$scope.accountReference = {
            ACCOUNT_REFERENCE: null
        }
    }

    private initScopeFunctions(): void {

        this.$scope.openRepresentativesModal = (): void => {
            this.openRepresentativesModal();
        }

        this.$scope.openCommoditiesModal = (): void => {
            this.openCommoditiesModal();
        }

        this.$scope.incotermChange = (): void => {
            this.incotermChange();
        }

        this.$scope.agentChange = (): void => {
            this.agentChange();
        }

        this.$scope.collapseMain = (): void => {
            this.collapseMain();
        }

        this.$scope.hasChanges = (): boolean => {
            return this.$offerScope && this.$offerScope.hasChanges ? this.$offerScope.hasChanges(this.$scope.model, this.$scope.oldModel) : false;
        }

        this.$scope.getExporterListByName = async (search: string): Promise<void> => {
            let exporterList: SelectorModel[] = [];
            const accountRequirementId = this.$scope.model.ACCOUNT && this.$scope.model.ACCOUNT.ACCOUNT_REQUIREMENT ? this.$scope.model.ACCOUNT.ACCOUNT_REQUIREMENT.ID.toString() : null;
            if (search && search.length >= 3) exporterList = await this.getImporterExporterListByName(ETariffLocalImporterExporterListRoutePath.EXPORTER, { search: search, id: this.$offerScope.model.ID });
            this.$scope.exporterList = exporterList;
        }

        this.$scope.getImporterListByName = async (search: string): Promise<void> => {
            let importerList: SelectorModel[] = [];
            const accountRequirementId = this.$scope.model.ACCOUNT && this.$scope.model.ACCOUNT.ACCOUNT_REQUIREMENT ? this.$scope.model.ACCOUNT.ACCOUNT_REQUIREMENT.ID.toString() : null;
            if (search && search.length >= 3) importerList = await this.getImporterExporterListByName(ETariffLocalImporterExporterListRoutePath.IMPORTER, { search: search, id: this.$offerScope.model.ID });
            this.$scope.importerList = importerList;
        }

        this.$scope.getNotifyListByName = async (search: string): Promise<void> => {
            let notifyList: SelectorModel[] = [];
            if (search && search.length >= 3) notifyList = await this.getLegalPersonListByName({ search: search, specializations: [ELegalPersonSpecializationId.NOTIFY] });
            this.$scope.notifyList = notifyList;
        }

        this.$scope.getBrokerListByName = async (search: string) => {
            let brokerList: IBrokerSelector[] = [];
            if (search && search.length >= 3) brokerList = await this.getBrokerListByName({ products: this.$offerScope.model.PRODUCT ? [this.$offerScope.model.PRODUCT.ID] : [], search: search });
            this.$scope.brokerList = brokerList;
        }

        this.$scope.getIncotermListByName = async (search: string): Promise<void> => {
            let incotermList: SelectorModel[] = [];
            if (search && search.length >= 3) incotermList = await this.getIncotermListByName(this.$offerScope.model && this.$offerScope.model.PRODUCT ? [this.$offerScope.model.PRODUCT.ID] : [], search);
            this.$scope.incotermList = incotermList;
        }

        this.$scope.getAgencyListByName = async (search: string) => {
            let agencyList: IAgencySelector[] = [];
            if (search && search.length >= 3) agencyList = await this.getAgencyListByName({ products: this.$offerScope.model.PRODUCT ? [this.$offerScope.model.PRODUCT.ID] : [], search: search });
            this.$scope.agencyList = agencyList;
        }

        this.$scope.getAgentListByName = async (search: string, isExternalAgent: boolean): Promise<void> => {
            let agentList: IAgentSelector[] = [];
            const accountRequirementId = this.$scope.model.ACCOUNT && this.$scope.model.ACCOUNT.ACCOUNT_REQUIREMENT ? this.$scope.model.ACCOUNT.ACCOUNT_REQUIREMENT.ID.toString() : null;
            const paramsProfitShareId = this.$offerScope.model.ID_PARAMS_PROFIT_SHARE ? this.$offerScope.model.ID_PARAMS_PROFIT_SHARE.toString() : null;
            if (search && search.length >= 3) agentList = await this.getAgentListByName({ search: search, id: this.$offerScope.model.ID, externalAgent: isExternalAgent });
            this.$scope.agentList = agentList;
        }

        this.$scope.getProviderListByName = async (search: string) => {
            let providerList: ITransporter[] = [];
            if (search && search.length >= 3) {
                providerList = await this.getProviderListByName(search);
            }
            this.$scope.providerList = providerList;
        }

        this.$scope.getLegalPersonListByNameCustom = async (search: string) => {
            let legalPersonCustomList: ITransporter[] = [];
            if (search && search.length >= 3) {
                legalPersonCustomList = await this.getLegalPersonListByNameCustom(search);
            }
            this.$scope.legalPersonCustomList = legalPersonCustomList;
        }

        this.$scope.getCollaboratorListByName = async (search: string, roles?: string[]) => {
            let collaboratorList: IPhysicalPersonSelectorModel[] = [];
            if (search && search.length >= 3) {
                collaboratorList = await this.getPhysicalPersonListByName({ specializations: [ELegalPersonSpecializationId.BROKER], roles: roles, search: search });
            }
            this.$scope.collaboratorList = collaboratorList;
        }

        this.$scope.getCorporateBranchListByName = async (search: string) => {
            let corporateBranchList: SelectorModel[] = [];
            if (search && search.length >= 3) corporateBranchList = await this.getCorporateBranchListByName(search, this.$offerScope.model.PRODUCT);
            this.$scope.corporateBranchList = corporateBranchList;
        }

        this.$scope.updateAccountType = (selected: ILegalPersonSelector) => {
            this.updateAccountType(selected);
        }

        this.$scope.requirementCorporateAccountChange = (value: boolean) => {
            this.requirementCorporateAccountChange(value);
        }

        this.$scope.getOfferReferenceListByName = async (search: string) => {
            let offerReferenceList: SelectorModel[] = [];
            if (search && search.length >= 3) offerReferenceList = await this.getOfferReferenceListByName({ search: search, offerId: this.$offerScope.model.ID });
            this.$scope.offerReferenceList = offerReferenceList;
        }

        this.$scope.changeReference = (selected: SelectorModel, oldSelected: string) => {
            this.changeReference(selected, oldSelected);
        }

        this.$scope.addOfferReferenceModal = async (): Promise<void> => {
            this.addOfferReferenceModal();
        }

        this.$scope.viewLog = (logBlock: string): Promise<void> => {
            return this.viewLog(logBlock);
        }

        this.$scope.viewLogMainBasic = (): Promise<void> => {
            return this.viewLog(EOfferLogsName.MAIN_BASIC);
        }

        this.$scope.viewLogMainInvolved = (): Promise<void> => {
            return this.viewLog(EOfferLogsName.MAIN_INVOLVED);
        }

        this.$scope.viewLogMainAccount = (): Promise<void> => {
            return this.viewLog(EOfferLogsName.MAIN_ACCOUNT);
        }

        this.$scope.buildCommodityView = (commoditySummary: IOfferCommoditySummary): string => {
            return this.buildCommodityView(commoditySummary);
        }

        this.$scope.buildExceptionsView = (commoditySummary: IOfferCommoditySummary): string => {
            return this.buildExceptionsView(commoditySummary);
        }

        this.$scope.buildExceptionTooltip = (commoditySummary: IOfferCommoditySummary): string => {
            return this.buildExceptionTooltip(commoditySummary);
        }

        this.$scope.isCollapseIn = (): boolean => {
            return this.$offerScope.collapseState.panel == ECollapseState.MAIN && !this.$offerScope.collapseState.released;
        }

        this.$scope.getCommercialUnityListByName = async (search: string) => {
            let commercialUnityList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                const legalPersonList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.COMMERCIAL_UNITY], search: search });
                if (legalPersonList) commercialUnityList = legalPersonList.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.NAME, CODE: legalPerson.CODE } });
            }
            this.$scope.commercialUnityList = commercialUnityList;
        }

        this.$scope.isAirProduct = (): boolean => {
            this.$scope.isAir = this.$offerScope.model.PRODUCT.ID == "IA" || this.$offerScope.model.PRODUCT.ID == "EA";
            return this.$scope.isAir;
        }

        this.$scope.goToLegalPerson = (type: string) => {
            this.goToLegalPerson(type);
        }
    }

    async initDependencies(): Promise<any> {
        const self: OfferMainRegisterController = this;

        this.initCollapseEvents();

        this.$scope.$on('offerMainSave', () => {
            this.saveOfferTabMain(true);
        });

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericValue("account_type"),
                self.getDocumentTypeList(),
                self.getFileGroupList(),
                self.getFileSpecsList(),
            ]).then(async (result: any) => {
                self.$scope.accountTypeList = result[0];
                self.$scope.documentTypeList = result[1];
                self.$scope.fileGroupList = result[2];
                self.$scope.fileSpecsList = result[3];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    getFisFilesGenericRoute(): string {
        const baseRoute = '/fis/filesGeneric';
        const uploadRoute = this.config.fisUrl + baseRoute;
        return uploadRoute;
    }

    private goToLegalPerson(type: string) {
        try {
            if (type == 'exporter') this.$scope.sessionService.openTab("app.registration.legalPerson", { SHORT_NAME: this.formService.getCONCAT(this.$scope.model.EXPORTERS, null, "NAME") });
            else if (type == 'importer') this.$scope.sessionService.openTab("app.registration.legalPerson", { SHORT_NAME: this.formService.getCONCAT(this.$scope.model.IMPORTERS, null, "NAME") });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initCollapseEvents() {
        this.$scope.$on('offerMainCollapse', () => {
            this.collapseMain();
        });

        const collapseMain = angular.element('#collapseMain');
        if (collapseMain) {
            collapseMain.on('shown.bs.collapse', (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseMainHeading").attr('aria-expanded', 'true');
                    // update collapse state
                    this.$offerScope.collapseState = { panel: ECollapseState.MAIN, released: false, nextState: null };
                    this.$offerScope.repositionPanels('mainRow', true);
                    this.getOfferTabsMain();
                    this.getOfferTabsMainAccountReference();
                    this.$offerScope.disableElements(this.$offerScope.operation == EOperation.VIEW);
                }
            });
            collapseMain.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseMainHeading").attr('aria-expanded', 'false');
                    this.$scope.model = null;
                    this.$scope.oldModel = null;
                }
            });
        }
    }

    private async updateAccountType(selected: ILegalPersonSelector): Promise<void> {
        try {
            if (!selected) return this.formService.handleError("selected is null!");

            if (selected.ID === '1' && this.$scope.model.ACCOUNT && this.$scope.model.ACCOUNT.ACCOUNT_REQUIREMENT && this.$scope.model.ACCOUNT.ACCOUNT_REQUIREMENT.CORPORATE_BRANCH) {
                this.$scope.model.ACCOUNT.ACCOUNT_REQUIREMENT.SALES_HOLDER = this.$scope.model.ACCOUNT.ACCOUNT_REQUIREMENT.CORPORATE_BRANCH;
                this.$scope.model.ACCOUNT.ACCOUNT_REQUIREMENT.SALES_PERSON = null;
            } else {
                this.$scope.model.ACCOUNT.ACCOUNT_REQUIREMENT.SALES_HOLDER = null;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private requirementCorporateAccountChange(value: boolean): void {
        if (value) {
            this.$scope.model.ACCOUNT.ACCOUNT_REQUIREMENT.SALES_HOLDER = this.$scope.model.COMMERCIAL_UNITY;
            this.$scope.model.ACCOUNT.ACCOUNT_REQUIREMENT.SALES_PERSON = null;
        } else {
            this.$scope.model.ACCOUNT.ACCOUNT_REQUIREMENT.SALES_HOLDER = null;
        }
    }


    private async changeReference(selected: SelectorModel, oldSelected: string): Promise<void> {
        try {
            const oldValue: SelectorModel = oldSelected ? JSON.parse(oldSelected) : null;
            const offerMainReference: SelectorModel[] = await this.checkAndListOfferMainReference();
            if (offerMainReference.length > 0 && offerMainReference.find(main => main.ID != selected.ID)) {
                const modal = await this.modalService.showModalConfirmation({}, {
                    actionButtonText: 'GENERAL.CONFIRM',
                    headerText: 'GENERAL.CONFIRM_ACTION',
                    bodyText: this.formService.getTranslate('PRODUCT.IF_YOU_PROCEED_CHANGE_MAIN_REFERENCE')
                });
                if (!modal) {
                    this.$scope.accountReference.ACCOUNT_REFERENCE = oldValue;
                    return;
                }
            }
            await this.updateOfferReferenceMain(selected, oldValue);

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async updateOfferReferenceMain(selected: SelectorModel, oldSelected: SelectorModel): Promise<boolean> {
        try {
            const result = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/reference/updateMain`, { offerId: this.$offerScope.model.ID, id: selected.ID, oldId: oldSelected ? oldSelected.ID : null }, 30000, false);
            const msgSuccess = this.formService.getTranslate('GENERAL.MAIN_TAB_DATA_SAVED_SUCCESSFULLY');
            if (result) this.formService.notifySuccess(msgSuccess);
            return true;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async incotermChange() {
        try {
            const modality: IOfferMainModalityResult = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/main/find/modality`, { incoterm: this.$scope.model.INCOTERM ? this.$scope.model.INCOTERM.ID : null, accountRequirement: this.$scope.model.ACCOUNT ? this.$scope.model.ACCOUNT.ID : null, masterDirect: this.$scope.model.MASTER_DIRECT }, 30000, false);
            if (modality) {
                modality.HOUSE_MODALITY.CODE = modality.HOUSE_MODALITY.CODE ? modality.HOUSE_MODALITY.CODE : null;
                modality.MASTER_MODALITY.CODE = modality.MASTER_MODALITY.CODE ? modality.MASTER_MODALITY.CODE : null;
                this.$scope.model.HOUSE_MODALITY = modality.HOUSE_MODALITY;
                this.$scope.model.MASTER_MODALITY = modality.MASTER_MODALITY;
                this.$scope.$applyAsync();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async collapseMain() {
        try {
            if (this.$offerScope.collapseState.released || this.$offerScope.collapseState.panel == ECollapseState.MAIN) {
                const collapseMain = angular.element("#collapseMain");
                if (collapseMain) {
                    const isCollapsed = angular.element("#collapseMainHeading").attr("aria-expanded") == "true";
                    if (isCollapsed) {
                        if (this.$offerScope.hasChanges(this.$scope.model, this.$scope.oldModel)) {
                            const confirm = await this.$offerScope.modalSaveConfirmation("OPERATIONAL.MAIN_TAB", "GENERAL.CLOSE");
                            if (confirm && !await this.saveOfferTabMain()) return;
                        }
                        this.$offerScope.collapseState.released = true;
                    }
                    collapseMain['collapse']('toggle');
                    if (isCollapsed) this.$offerScope.repositionPanels('mainRow');
                }
            } else {
                this.$offerScope.collapseState.nextState = ECollapseState.MAIN;
                this.$offerScope.releaseCollapse();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getIncotermListByName(products: string[], search: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        this.formService.block();
        try {
            const incoterms = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/incoterm/list/custom`, { products, search }, 30000, false);
            if (incoterms && incoterms.length > 0) result = incoterms.map(incoterm => {
                return {
                    ID: incoterm.ID,
                    NAME: incoterm.NAME,
                    CODE: incoterm.INITIALS
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async openRepresentativesModal(): Promise<void> {
        try {
            await this.getEventList();
            this.representativeModalId = this.modalService.newModal();
            this.modalService.showModalInfo(
                {
                    modalID: this.representativeModalId,
                    scope: this.$scope,
                    formService: 'edit',
                    size: 'lg modal-overflow',
                    template: require("../view/modals/offerMainRepresentativesModal.html"),
                    keyboard: true,
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing") {
                            if (reason.toString() == "escape key press") event.preventDefault();
                            if (!closed) {
                                const modalScope: IRepresentativeModalScope = await this.modalService.getModalScope(this.representativeModalId);
                                await modalScope.closeRepresentativeModal();
                            }
                        }
                    }
                },
                null
            );
            await this.buildRepresentativeModalScope(this.$scope.model.REPRESENTATIVES);
        } catch (ex) {
            this.formService.notifyError(ex);
        }
    }

    private async agentChange() {
        try {
            const agent = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/agent/situation/${this.$scope.model.EXTERNAL_AGENT.ID}`, 30000, null, false);
            if (agent && agent.data) {
                this.$scope.model.EXTERNAL_AGENT.SITUATION = agent.data.SITUATION;
                this.$scope.$applyAsync();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async saveOfferTabMain(runValidation: boolean = false): Promise<boolean> {
        let success = true;
        const isInvalid = this.$offerScope.hasInvalidRequiredElements('collapseMain');
        if (isInvalid) return false;
        try {
            this.formService.block();
            const result = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/main/update`, { id: this.$offerScope.model.ID, data: this.$scope.model, oldData: this.$scope.oldModel, runValidation: runValidation }, 30000, false);
            const msgSuccess = this.formService.getTranslate('GENERAL.MAIN_TAB_DATA_SAVED_SUCCESSFULLY');
            if (result) this.formService.notifySuccess(msgSuccess);
            else success = false;
        } catch (ex) {
            success = false;
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            if (success) this.$scope.oldModel = angular.copy(this.$scope.model);
            else this.$offerScope.collapseState.nextState = null;
            return success;
        }
    }

    private async openCommoditiesModal(): Promise<void> {
        this.$offerScope.lastScroll = $('.app-content-body').scrollTop();
        const commodityRequestResult: IOfferCommodityRequestViewResult = await this.getCommodityList();
        const commodities = commodityRequestResult && commodityRequestResult.COMMODITY_ITEM ? commodityRequestResult.COMMODITY_ITEM : null;
        const exceptions = await this.getCommodityException();
        const oldExceptions = commodityRequestResult && commodityRequestResult.COMMODITY_EXCEPTIONS ? commodityRequestResult.COMMODITY_EXCEPTIONS : null;
        this.commoditiesModalId = this.modalService.newModal();
        this.modalService.showModalInfo(
            {
                modalID: this.commoditiesModalId,
                scope: this.$scope,
                formService: 'register',
                size: 'lg modal-overflow',
                template: require("../view/modals/offerMainCommodityModal.html"),
                keyboard: true,
                events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                    if (event.name == "modal.closing") {
                        if (reason.toString() == "escape key press") event.preventDefault();
                        if (!closed) {
                            const modalScope: ICommodityModalScope = await this.modalService.getModalScope(this.commoditiesModalId);
                            await modalScope.closeCommodityModal();
                        }
                    }
                }
            },
            null
        );
        await this.buildCommodityModalScope(commodities, exceptions, oldExceptions);
    }

    private async getEventList(): Promise<void> {
        this.formService.block();
        try {
            this.$scope.eventList = [];
            const filterId = this.$scope.model.MOVE_TYPE ? this.$scope.model.MOVE_TYPE.ID : null;
            const productId = this.$offerScope.model.PRODUCT ? this.$offerScope.model.PRODUCT.ID : null;
            const orders: number[] = [];
            if (this.$scope.model.PUP) orders.push(1);
            if (this.$scope.model.OTFS) orders.push(2);
            if (this.$scope.model.POLAOL) orders.push(3);
            if (this.$scope.model.PODAOD) orders.push(4);
            if (this.$scope.model.DTFS) orders.push(5);
            if (this.$scope.model.PLD) orders.push(6);
            const resultOperation = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/representative/event/${this.$offerScope.model.ID}`, 30000, null, false);
            if (resultOperation && resultOperation.data.length) {
                this.$scope.eventList = resultOperation.data;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getCommodityList(): Promise<IOfferCommodityRequestViewResult> {
        let commodityResult: IOfferCommodityRequestViewResult = null;
        this.formService.block();
        try {
            const resultOperation = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/commodity/view/${this.$offerScope.model.ID}`, 30000, null, false);
            if (resultOperation && resultOperation.data) commodityResult = resultOperation.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return commodityResult;
        }
    }

    private async getCommodityException(): Promise<ICommodity[]> {
        let exceptions: ICommodity[] = null;
        this.formService.block();
        try {
            const resultOperation = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/list/commodity/exceptions`, { offerId: this.$offerScope.model.ID }, 30000, false);
            if (resultOperation && resultOperation.length) exceptions = resultOperation;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return exceptions;
        }
    }

    private async buildRepresentativeModalScope(representatives: IRepresentatives): Promise<IRepresentativeModalScope> {
        const modalScope: IRepresentativeModalScope = await this.modalService.getModalScope(this.representativeModalId);

        modalScope.operation = this.$offerScope.operation;
        modalScope.representatives = angular.copy(representatives);
        modalScope.oldRepresentatives = angular.copy(representatives);

        modalScope.applyRepresentative = (close?: boolean) => {
            this.applyRepresentative(modalScope.representatives, close);
        };

        modalScope.closeRepresentativeModal = async (): Promise<void> => {
            if (this.$offerScope.hasChanges(modalScope.representatives, modalScope.oldRepresentatives)) {
                const confirm = await this.$offerScope.modalSaveConfirmation("GENERAL.CLOSE", "GENERAL.CLOSE");
                if (confirm && !this.applyRepresentative(modalScope.representatives)) return;
            }
            this.modalService.closeModal(this.representativeModalId);
            this.representativeModalId = 0;
            $('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
        }
        modalScope.getRepresentativeList = async (search: string) => {
            let peopleList = [];
            if (search && search.length >= 3) {
                const legalPersonList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.REPRESENTATIVE], search: search })
                if (legalPersonList && legalPersonList.length) peopleList = peopleList.concat(legalPersonList.map(legalPerson => { return { ID: parseInt(legalPerson.ID), NAME: legalPerson.NAME, ID_LEGAL_PERSON: legalPerson.ID, ID_PHYSICAL_PERSON: null } }));
                const physicalPersonList = await this.getPhysicalPersonListByName({ specializations: [EPhysicalPersonSpecializationId.REPRESENTATIVE], roles: [], search: search });
                if (physicalPersonList && physicalPersonList.length) peopleList = peopleList.concat(physicalPersonList.map(physicalPerson => { return { ID: parseInt(physicalPerson.ID), NAME: physicalPerson.NAME, ID_LEGAL_PERSON: null, ID_PHYSICAL_PERSON: physicalPerson.ID } }));
            }
            modalScope.representativeList = peopleList;
        }

        return modalScope;
    }

    private applyRepresentative(representatives: IRepresentatives, close?: boolean): boolean {
        let success = false;
        try {
            const hasInvalid = this.$offerScope.hasInvalidRequiredElements('modalForm');
            if (hasInvalid) return;
            if (!representatives) throw Error('representatives is null');
            this.$scope.model.REPRESENTATIVES = representatives;
            success = true;
            if (close) {
                this.modalService.closeModal(this.representativeModalId);
                this.representativeModalId = 0;
                $('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
            }
            this.formService.notifySuccess("Aplicado com sucesso");
            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return success;
        }
    }

    private async buildCommodityModalScope(commodities: IOfferCommodity[], exceptions: ICommodity[], oldExceptions: ICommodity[]): Promise<ICommodityModalScope> {
        const modalScope: ICommodityModalScope = await this.modalService.getModalScope(this.commoditiesModalId);

        try {
            modalScope.operation = this.$offerScope.operation;
            modalScope.commodities = commodities;
            modalScope.oldCommodities = angular.copy(commodities);
            modalScope.exceptions = exceptions;
            modalScope.oldExceptions = oldExceptions;

            // get generics
            const result: Array<any> = await this.$q.all([
                this.getCommoditySectionList()
            ]);
            modalScope.sectionList = result[0];

            modalScope.addCommodity = (): void => {
                try {
                    if (!modalScope.commodities) modalScope.commodities = [];
                    const commodity: IOfferCommodity = {
                        COMMODITY: null,
                        COMMODITY_SECTION: null,
                        DATA_ORIGIN_TYPE: { ID: EDataOriginTypeId.MANUAL, NAME: "Manual" }
                    }
                    modalScope.commodities.push(commodity);
                    const lastIndex = modalScope.commodities.length - 1;
                    this.$timeout(() => {
                        modalScope.selectorValidity("commoditySection" + lastIndex);
                        modalScope.selectorValidity("commodityCommodity" + lastIndex);
                    }, 100);
                } catch (ex) {
                    this.formService.handleError(ex);
                }
            }

            modalScope.removeCommodity = async (index: number): Promise<void> => {
                try {
                    if (!index && index != 0) throw Error('index is null');
                    const modal = await this.modalService.showModalConfirmation({}, {
                        actionButtonText: 'GENERAL.CONFIRM',
                        headerText: 'GENERAL.CONFIRM_ACTION',
                        bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
                    });
                    if (!modal) return;

                    if (modalScope.commodities && modalScope.commodities.length > 0) {
                        this.formService.block();
                        modalScope.commodities.splice(index, 1);
                        this.$timeout(() => {
                            modalScope.selectorValidity("commoditySection" + index);
                            modalScope.selectorValidity("commodityCommodity" + index);
                        }, 100);
                    }
                } catch (ex) {
                    this.formService.handleError(ex);
                } finally {
                    this.formService.unblock();
                }
            }

            modalScope.applyCommodity = (close?: boolean) => {
                this.applyCommodity(modalScope.commodities, modalScope.oldCommodities, modalScope.exceptions, modalScope.oldExceptions, close);
            };

            modalScope.closeCommodityModal = async (): Promise<void> => {
                if (this.$offerScope.hasChanges(modalScope.commodities, modalScope.oldCommodities)) {
                    const confirm = await this.$offerScope.modalSaveConfirmation("GENERAL.CLOSE", "GENERAL.CLOSE");
                    if (confirm && !this.applyCommodity(modalScope.commodities, modalScope.oldCommodities, modalScope.exceptions, modalScope.oldExceptions)) return;
                }
                this.modalService.closeModal(this.commoditiesModalId);
                this.commoditiesModalId = 0;
                $('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
            }

            modalScope.getCommodityListByName = async (name: string): Promise<void> => {
                let commodityList = [];
                if (name && name.length >= 2) {
                    commodityList = await this.getCommodityListByName(name);
                }
                modalScope.commodityList = commodityList;
            };

            modalScope.commodityChange = (index: number) => {
                if (!index && index != 0) return this.formService.notifyError("index is null.");
                const commodity = modalScope.commodities && modalScope.commodities.length ? modalScope.commodities[index] : null;
                if (commodity) {
                    if (commodity.COMMODITY_SECTION) {
                        commodity.COMMODITY = null;
                    } else if (commodity.COMMODITY) {
                        commodity.COMMODITY_SECTION = null;
                    }
                    this.$timeout(() => {
                        modalScope.selectorValidity("commoditySection" + index);
                        modalScope.selectorValidity("commodityCommodity" + index);
                    }, 100);
                }
            }

            modalScope.isCommodityDisabled = (commodity: IOfferCommodity, fieldName: string): boolean => {
                let disabled = false;
                try {
                    if (!commodity) throw Error('commodity is null');
                    if (!fieldName) throw Error('fieldName is null');
                    if (
                        (fieldName == 'section' && commodity.COMMODITY) ||
                        (fieldName == 'commodity' && commodity.COMMODITY_SECTION))
                        disabled = true;
                } catch (ex) {
                    this.formService.handleError(ex);
                } finally {
                    return disabled;
                }
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }

        return modalScope;
    }

    private async applyCommodity(commodities: IOfferCommodity[], oldCommodities: IOfferCommodity[], exceptions: ICommodity[], oldExceptions: ICommodity[], close?: boolean): Promise<boolean> {
        let success = false;
        try {
            const hasInvalid = this.$offerScope.hasInvalidRequiredElements('modalForm');
            if (!hasInvalid) {
                this.formService.block();
                success = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/commodity/updateCommodity`, { id: this.$offerScope.model.ID, data: { COMMODITY_ITEM: commodities, COMMODITY_EXCEPTIONS: exceptions }, oldData: { COMMODITY_ITEM: oldCommodities, COMMODITY_EXCEPTIONS: oldExceptions } }, 30000, false);
                if (success) {
                    const resultOperation = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/commodity/replicationData/${this.$offerScope.model.ID}`, 30000, null, false);
                    const summary = resultOperation.data ? resultOperation.data : null;
                    if (summary) {
                        angular.element('#commoditySummary').html(this.buildCommodityView(summary.REPLICATION_DATA.COMMODITY_SUMMARY));
                        angular.element('#commodityExceptionSummary').html(this.buildExceptionsView(summary.REPLICATION_DATA.COMMODITY_SUMMARY));
                        this.$scope.model.REPLICATION_DATA.COMMODITY_SUMMARY = summary.REPLICATION_DATA.COMMODITY_SUMMARY;
                    }
                }
                if (close) {
                    this.modalService.closeModal(this.commoditiesModalId);
                    this.commoditiesModalId = 0;
                    $('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            if (success) {
                const msgSuccess = this.formService.getTranslate('BASIC_DATA.COMMODITY_SUCESFULLY_UPDATED');
                this.formService.notifySuccess(msgSuccess);

            }
            return success;
        }
    }

    private async getGenericValue(type: string, alternative?: boolean): Promise<SelectorModel[]> {
        if (alternative) {
            const { data: generic } = await this.helperService.get(`/generic/value/${type}/${alternative}`, null, 10000);
            return generic && generic.data ? generic.data : [];
        } else {
            const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
            return generic && generic.data ? generic.data : [];
        }
    }

    private async getLegalPersonListByName(filter: ILegalPersonListCustomFilter): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.formService.block();
            const legalPersons = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/legalPerson/list/custom/offer`, filter, 30000, false);
            if (legalPersons) result = legalPersons.map(legalPerson => {
                return {
                    ID: legalPerson.ID,
                    NAME: legalPerson.SHORT_NAME,
                    CODE: legalPerson.CORPORATE_NAME,
                    ID_LEGAL_PERSON: legalPerson.ID_LEGAL_PERSON,
                    LEGAL_PERSON: legalPerson.LEGAL_PERSON
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCorporateBranchListByName(search?: string, product?: SelectorModel): Promise<ILegalPersonSelector[]> {
        let result = [];
        this.formService.block();
        try {
            const corporateBranchs = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/corporateBranch/list/custom`, { search: search, products: product ? [product.ID] : null }, 30000, false);
            if (corporateBranchs) result = corporateBranchs.map(corporateBranch => { return { ID: corporateBranch.ID, NAME: corporateBranch.NAME, CODE: corporateBranch.CODE } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCommodityListByName(name?: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const commodities = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/list/commodity`, { search: name, offerId: this.$offerScope.model.ID }, 30000, false);
            if (commodities && commodities.length > 0) result = commodities.map(commodity => { return { ID: commodity.ID, NAME: commodity.NAME, HS_CODE: commodity.HS_CODE ? commodity.HS_CODE : null } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCommoditySectionList(): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const sections = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/list/commodity/section`, { offerId: this.$offerScope.model.ID }, 30000, false);
            if (sections && sections.length > 0) result = sections;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getBrokerListByName(brokerFilter?: IBrokerListCustomFilter): Promise<IBrokerSelector[]> {
        let result: IBrokerSelector[] = [];
        this.formService.block();
        try {
            const brokers = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/broker/list/custom/offer`, brokerFilter, 30000, false);
            if (brokers) result = brokers.map(broker => {
                return {
                    ID: broker.ID,
                    NAME: broker.NAME,
                    CODE: broker.CODE ? broker.CODE : null,
                    ID_LEGAL_PERSON: broker.ID_LEGAL_PERSON,
                    LEGAL_PERSON: broker.LEGAL_PERSON
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getAgencyListByName(agencyFilter?: IAgencyListCustomFilter): Promise<IAgencySelector[]> {
        let result: IAgencySelector[] = [];
        this.formService.block();
        try {
            const agencies = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/agency/list/custom/offer`, agencyFilter, 30000, false);
            if (agencies) result = agencies.map(agency => {
                return {
                    ID: agency.ID,
                    NAME: agency.NAME,
                    CODE: agency.CODE ? agency.CODE : null,
                    ID_LEGAL_PERSON: agency.ID_LEGAL_PERSON,
                    LEGAL_PERSON: agency.LEGAL_PERSON,
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getAgentListByName(filter: IAgentListByNetworkFilter): Promise<IAgentSelector[]> {
        let result: IAgentSelector[] = [];
        try {
            this.formService.block();
            const agents = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/listAgent/byNetwork`, filter, 30000, false);
            result = agents ? agents.map(agent => {
                return {
                    ID: agent.ID,
                    NAME: agent.NAME,
                    CODE: agent.CODE ? agent.CODE : null,
                    ID_LEGAL_PERSON: agent.ID_LEGAL_PERSON,
                    LEGAL_PERSON: agent.LEGAL_PERSON
                }
            }) : [];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getPhysicalPersonListByName(physicalPersonFilter?: IPhysicalPersonListCustomFilter): Promise<IPhysicalPersonSelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const physicalPersons = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/physicalPerson/list/custom`, physicalPersonFilter, 30000, false);
            if (physicalPersons) result = physicalPersons.map(physicalPerson => { return { ID: physicalPerson.ID, NAME: physicalPerson.NAME, CODE: physicalPerson.CODE, SECTOR: physicalPerson.SECTOR ? { ID: physicalPerson.SECTOR.ID, NAME: physicalPerson.SECTOR.NAME, CODE: physicalPerson.SECTOR.CODE } : null } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getImporterExporterListByName(route: string, filter: ITariffLocalImporterExporterListCustomFilter): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.formService.block();
            const data = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/${route}`, filter, 30000, false);
            if (data) result = data.map(importerExporter => {
                return {
                    ID: importerExporter.ID,
                    NAME: importerExporter.SHORT_NAME,
                    CODE: importerExporter.CORPORATE_NAME,
                    ID_LEGAL_PERSON: importerExporter.ID_LEGAL_PERSON,
                    LEGAL_PERSON: importerExporter.LEGAL_PERSON
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getProviderListByName(search: string): Promise<ITransporter[]> {
        let result: ITransporter[] = [];
        try {
            if (!this.$offerScope.model.PRODUCT) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
            this.formService.block();
            const providers = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/provider/list/custom`, { types: [], products: [this.$offerScope.model.PRODUCT.ID], search: search }, 30000, false);
            result = providers ? providers.map(provider => { return { ID: provider.ID.toString(), NAME: provider.NAME, CODE: provider.SCAC_IATA, ID_LEGAL_PERSON: provider.ID_LEGAL_PERSON, LEGAL_PERSON: null } }) : [];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getLegalPersonListByNameCustom(search: string): Promise<ITransporter[]> {
        let result: ITransporter[] = [];
        try {
            this.formService.block();
            const legalPerson = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/legalPerson/list/custom/operational`, { specializations: [], search: search }, 30000, false);
            result = legalPerson ? legalPerson.map(legalPerson => { return { ID: legalPerson.ID.toString(), NAME: legalPerson.NAME, CODE: legalPerson.SCAC, ID_LEGAL_PERSON: legalPerson.ID_LEGAL_PERSON, LEGAL_PERSON: null } }) : [];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getOfferReferenceListByName(filter: IOfferReferenceListCustomFilter): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.formService.block();
            const referenceList = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/reference/list/custom`, filter, 30000, false);
            if (referenceList) result = referenceList
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async checkAndListOfferMainReference(): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.formService.block();
            const referenceList = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/reference/check`, { offerId: this.$offerScope.model.ID }, 30000, false);
            if (referenceList) result = referenceList
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getOfferTabsMain(): Promise<void> {
        this.formService.block();
        try {
            const mainTab = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/main/${this.$offerScope.operation}/${this.$offerScope.model.ID}`, 30000, null, false);
            if (mainTab && mainTab.data) this.$scope.model = mainTab.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.oldModel = angular.copy(this.$scope.model);
            if (this.$offerScope.operation == 'edit') {
                this.$timeout(() => {
                    this.formService.loadEditForm();
                }, 100);
            }
            this.formService.unblock();
        }
    }

    private async getOfferTabsMainAccountReference(): Promise<void> {
        this.formService.block();
        try {
            const mainTabReference = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/main/reference/${this.$offerScope.model.ID}`, 30000, null, false);
            if (mainTabReference && mainTabReference.data && mainTabReference.data.REPLICATION_DATA) this.$scope.accountReference.ACCOUNT_REFERENCE = mainTabReference.data.REPLICATION_DATA.ACCOUNT_REFERENCE;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            if (this.$offerScope.operation == 'edit') {
                this.$timeout(() => {
                    this.formService.loadEditForm();
                }, 100);
            }
            this.formService.unblock();
        }
    }

    private async getDocumentTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/document_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getFileGroupList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/file_group`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getFileSpecsList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/file_specs`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async addOfferReferenceModal(): Promise<void> {
        try {
            this.$scope.uploader = this.getFileUploaderDefault();
            this.modalReferenceId = this.modalService.newModal();

            this.modalService.showModalInfo(
                {
                    modalID: this.modalReferenceId,
                    scope: this.$scope,
                    formService: 'register',
                    template: require("../view/modals/offerReferenceModal.html"),
                    size: 'lg'
                },
                null
            );

            const reference: IOfferReference = {
                _id: null,
                ID_OFFER: null,
                DOCUMENT_TYPE: null,
                REFERENCE: null,
                FILE_GROUP: null,
                FILE_SPECS: null,
                MAIN_REFERENCE: null,
                USER_DATE_INSERT: null,
                DATE: new Date(),
                FILES: null,
                FILES_REMOVE: null,
                FILES_NEW: null,
            }
            await this.buildReferenceModalScope(reference, 'register');
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async buildReferenceModalScope(reference: IOfferReference, operation: string): Promise<IOfferReferenceModalScope> {
        const modalScope: IOfferReferenceModalScope = await this.modalService.getModalScope(this.modalReferenceId);
        modalScope.operation = operation;
        modalScope.from = 'offer';

        modalScope.documentTypeList = this.$scope.documentTypeList;
        modalScope.fileGroupList = this.$scope.fileGroupList;
        modalScope.fileSpecsList = this.$scope.fileSpecsList;

        if (modalScope.operation == 'register') {
            reference.MAIN_REFERENCE = true;

            const fileGroup: SelectorModel = modalScope.fileGroupList.find(file => file.ID == EFileGroupId.COMERCIAL);
            if (fileGroup) reference.FILE_GROUP = fileGroup;

            const fileSpec: SelectorModel = modalScope.fileSpecsList.find(specs => specs.ID == EFileSpecsId.CLIENTE);
            if (fileSpec) reference.FILE_SPECS = fileSpec;

            const docType: SelectorModel = modalScope.documentTypeList.find(doc => doc.ID == EDocumentTypeId.COMMERCIAL_INVOICE);
            if (fileSpec) reference.DOCUMENT_TYPE = docType;

            reference.ID_OFFER = this.$offerScope.model.ID;
        }

        modalScope.reference = angular.copy(reference);
        modalScope.oldReference = angular.copy(reference);

        this.$scope.uploader.onSuccessItem = (item: IFileItem, response: ISuccessItemResponse) => this.onSuccessItem(item, response, reference)

        modalScope.applyOfferReferenceModal = async (close?: boolean) => {
            if (!this.$offerScope.hasInvalidRequiredElements('modalReferenceForm')) {
                if (await this.checkMainReference(modalScope.reference)) {
                    if (this.applyOfferReference(modalScope.reference, modalScope.operation, close)) {
                        modalScope.oldReference = angular.copy(reference);
                    }
                }
            }
        };

        modalScope.closeOfferReferenceModal = async (): Promise<void> => {
            this.modalService.closeModal(this.modalReferenceId);
            this.modalReferenceId = 0;
            this.$scope.uploader.destroy();
            $('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
        }

        modalScope.documentTypeChange = (optionDocumentType: SelectorModel) => {
            if (optionDocumentType) {
                if (optionDocumentType.ID == EDocumentTypeId.OFERTA) {
                    const fileGroup: SelectorModel = modalScope.fileGroupList.find(group => group.ID == EFileGroupId.COMERCIAL);
                    if (fileGroup) {
                        modalScope.reference.FILE_GROUP = fileGroup;
                    }
                    const fileSpec: SelectorModel = modalScope.fileSpecsList.find(specs => specs.ID == EFileSpecsId.CLIENTE);
                    if (fileSpec) {
                        modalScope.reference.FILE_SPECS = fileSpec;
                    }
                }
            }
        }

        modalScope.removeUpload = (model: IUploadItem): boolean => {
            return this.removeUpload(model, modalScope.reference);
        }

        return modalScope;
    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'offer_files', formName: this.formName, folder: this.formName };
            return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData], onSuccessItem: (item: IFileItem, response: ISuccessItemResponse) => this.onSuccessItem(item, response) });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async checkMainReference(reference: IOfferReference): Promise<boolean> {
        try {
            if (!reference) throw Error('reference is null');
            if ((reference.FILE_SPECS.ID == EFileSpecsId.CLIENTE) && reference.MAIN_REFERENCE) {
                const OfferMainReference: SelectorModel[] = await this.checkAndListOfferMainReference();
                if (OfferMainReference.length > 0 && OfferMainReference.find(main => main.NAME != reference.REFERENCE)) {
                    const modal = await this.modalService.showModalConfirmation({}, {
                        actionButtonText: 'GENERAL.CONFIRM',
                        headerText: 'GENERAL.CONFIRM_ACTION',
                        bodyText: this.formService.getTranslate('PRODUCT.IF_YOU_PROCEED_CHANGE_MAIN_REFERENCE')
                    });
                    return modal;
                }
            }
            return true;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private onSuccessItem(item: IFileItem, response: ISuccessItemResponse, reference?: IOfferReference): void {
        try {
            if (!item) throw Error('item parameter is null');
            if (!response) throw Error('response parameter is null');

            if (response.data && response.data.length > 0) {
                if (!reference.FILES_NEW) reference.FILES_NEW = [];

                for (let dataItem of response.data) {
                    const file: IUploadItem = {
                        FILE_DISPLAY_NAME: dataItem.displayFileName,
                        FILE_NAME: dataItem.fileName,
                        FILE_TYPE: dataItem.fileType,
                        FILE_PATH: dataItem.filePath,
                        FILE_URL: dataItem.fileUrl,
                        FILE_HASH: dataItem.fileHash,
                        FILE_SIZE: dataItem.fileSize,
                        FORM_NAME: dataItem.formName
                    }
                    reference.FILES_NEW.push(file)
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async viewLog(logBlock: string): Promise<void> {
        try {
            const retrieveLog = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/viewLog/${logBlock}/${this.$offerScope.model.ID}`, 30000, null, false);
            if (retrieveLog && retrieveLog.data) {
                this.formService.block();
                let log: IViewLog = {
                    operation: 'history',
                    number: this.$offerScope.model.ID.toString(),
                    list: [],
                    show: true,
                    showCloseButton: false,
                    searchQuery: '',
                    originalList: [],
                }

                log.list = retrieveLog.data;
                log.originalList = angular.copy(log.list);
                this.$scope.log = log;
                this.$scope.customLogProperties = this.getCustomLogProperties();
                angular.element('#log-viewer').removeClass('ng-hide');
                this.formService.unblock();

                const modalId = this.modalService.newModal();
                await this.modalService.showModalConfirmation(
                    {
                        modalID: modalId,
                        scope: this.$scope,
                        template: require('../view/modals/offerLog.html'),
                        size: 'full'
                    },
                    {
                        closeButtonText: 'GENERAL.CLOSE',
                        headerText: 'GENERAL.GRID.LOG'
                    }
                );
                this.modalService.closeModal(modalId);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private buildCommodityView(commoditySummary: IOfferCommoditySummary): string {
        let concatenated = "";
        try {
            if (commoditySummary) {
                const sectionList = commoditySummary.COMMODITY_SECTION && commoditySummary.COMMODITY_SECTION.length ? commoditySummary.COMMODITY_SECTION.map(section => {
                    return { NAME: `${section.ID} - ${section.NAME}` };
                }) : null;
                const sections = sectionList && sectionList.length ? this.formService.getCONCAT(sectionList, null, "NAME", null, null, ',') : "";
                const commoditiesList = commoditySummary.COMMODITY && commoditySummary.COMMODITY.length ? commoditySummary.COMMODITY.map(commodity => {
                    return { NAME: `${commodity.HS_CODE} - ${commodity.NAME}` };
                }) : null;
                const commodities = commoditiesList && commoditiesList.length ? this.formService.getCONCAT(commoditiesList, null, "NAME", null, null, ",") : "";
                concatenated = concatenated.length > 0 ? concatenated.concat("; ", sections) : concatenated.concat(sections);
                concatenated = concatenated.length > 0 ? concatenated.concat("; ", commodities) : concatenated.concat(commodities);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return concatenated;
        }
    }

    private buildExceptionsView(commoditySummary: IOfferCommoditySummary): string {
        let exceptionList = "";
        try {
            if (commoditySummary) {
                const exceptionsList = commoditySummary.COMMODITY_EXCEPTIONS && commoditySummary.COMMODITY_EXCEPTIONS.length ? commoditySummary.COMMODITY_EXCEPTIONS.map(exception => {
                    return { NAME: `${exception.HS_CODE} - ${exception.NAME}` };
                }) : null;
                const exceptions = exceptionsList && exceptionsList.length ? this.formService.getCONCAT(exceptionsList, null, "NAME", null, null, ",") : "";
                exceptionList = exceptionList.concat(exceptions);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return exceptionList;
        }
    }

    private buildExceptionTooltip(commoditySummary: IOfferCommoditySummary): string {
        let exceptionTooltip = "";
        try {
            const exceptionsView = this.buildExceptionsView(commoditySummary);
            if (exceptionsView && exceptionsView != '') exceptionTooltip = `(Exceção: ${exceptionsView})`;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return exceptionTooltip;
        }
    }

    private async applyOfferReference(reference: IOfferReference, operation: string, close?: boolean): Promise<void> {
        try {
            if (!reference) throw Error('reference is null');
            if (operation === "register") {
                const result: IOfferReference = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/reference/insert`, { data: reference }, 30000, false);
                const msg = this.formService.getTranslate('GENERAL.VALUES_NOT_CONVERTED_TRY_AGAIN');
                if (!result)
                    return this.formService.notifyError(msg);
                const mainReference: ISelectorModel = {
                    ID: result._id.toString(), NAME: result.REFERENCE
                }
                this.$scope.accountReference.ACCOUNT_REFERENCE = mainReference
            }
            if (close) {
                this.modalService.closeModal(this.modalReferenceId);
                this.modalReferenceId = 0;
                $('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
            }
            const msgSuccess = this.formService.getTranslate('GENERAL.SUCCESSFULLY_REGISTERED');
            this.formService.notifySuccess(msgSuccess);
            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private removeUpload(model: IUploadItem, reference: IOfferReference): boolean {
        let result = false;
        try {
            const isAdminOrCoordinator = (this.$scope.user.roles && (this.$scope.user.roles.hasOwnProperty('ADMIN') || this.$scope.user.roles.hasOwnProperty('COORDINATOR')));

            if (isAdminOrCoordinator) {
                result = true;
            } else {
                // check if in model.FILES_NEW;
                if (reference.FILES_NEW && reference.FILES_NEW.length > 0) {
                    const index = reference.FILES_NEW.findIndex(file => { return file.FILE_HASH == model.FILE_HASH });
                    if (index > -1) {
                        // Remove from FILES_NEW
                        reference.FILES_NEW.splice(index, 1);
                        result = true;
                    }
                }
            }

            if (result) {
                if (!reference.FILES_REMOVE) reference.FILES_REMOVE = [];
                reference.FILES_REMOVE.push(model);
            } else {
                throw Error('You don\'t have permission to remove this file');
            }
        } catch (ex) {
            result = false;
            this.formService.handleError(ex);
        } finally {
            return result;
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            // Main Tab - Basic data block
            {
                PROPERTY: "MOVE_TYPE",
                LABEL: "BASIC_DATA.SERVICE_TYPE"
            },
            {
                PROPERTY: "ORIGIN",
                LABEL: "BASIC_DATA.ORIGIN"
            },
            {
                PROPERTY: "DESTINATION",
                LABEL: "BASIC_DATA.DESTINATION"
            },
            {
                PROPERTY: "PROCESS_TYPE",
                LABEL: "BASIC_DATA.FILE_TYPE"
            },
            {
                PROPERTY: "LOAD_REF",
                LABEL: "PRODUCT.SHIPMENT_DATE_REFERENCE"
            },
            {
                PROPERTY: "",
                LABEL: "OPERATIONAL.CLIENT_REFERENCE"
            },
            {
                PROPERTY: "",
                LABEL: "GENERAL.COMMODITY_GROUP"
            },
            {
                PROPERTY: "TYPE_CARGO",
                LABEL: "BASIC_DATA.CARGO_TYPE"
            },
            {
                PROPERTY: "INCOTERM",
                LABEL: "BASIC_DATA.INCOTERM"
            },
            {
                PROPERTY: "MASTER_MODALITY",
                LABEL: "BASIC_DATA.MASTER_PAYMENT_MODE"
            },
            {
                PROPERTY: "HOUSE_MODALITY",
                LABEL: "BASIC_DATA.HOUSE_PAYMENT_MODE"
            },
            {
                PROPERTY: "MASTER_DIRECT",
                LABEL: "BASIC_DATA.STRAIGHT_BL"
            },
            {
                PROPERTY: "ACCOUNT",
                LABEL: "BASIC_DATA.CLIENT"
            },
            {
                PROPERTY: "VALIDITY_START",
                LABEL: "PRODUCT.VALIDITY_START"
            },
            {
                PROPERTY: "VALIDITY_END",
                LABEL: "PRODUCT.VALIDITY_END"
            },
            // Main Tab - Involved block
            {
                PROPERTY: "EXPORTERS",
                LABEL: "BASIC_DATA.SHIPPER"
            },
            {
                PROPERTY: "IMPORTERS",
                LABEL: "BASIC_DATA.CONSIGNEE"
            },
            {
                PROPERTY: "ID",
                LABEL: "GENERAL.IDENTIFIER"
            },
            {
                PROPERTY: "NAME",
                LABEL: "GENERAL.NAME"
            },
            {
                PROPERTY: "CODE",
                LABEL: "FINANCIAL.VALUE"
            },
            {
                PROPERTY: "NOTIFY",
                LABEL: "BASIC_DATA.NOTIFY"
            },
            {
                PROPERTY: "BROKER",
                LABEL: "BASIC_DATA.CUSTOMS_BROKER"
            },
            {
                PROPERTY: "PROVIDER",
                LABEL: "BASIC_DATA.PROVIDER"
            },
            {
                PROPERTY: "AGENCY",
                LABEL: "ENTITY.AGENCY"
            },
            {
                PROPERTY: "LOCAL_AGENT",
                LABEL: "BASIC_DATA.LOCAL_AGENT"
            },
            {
                PROPERTY: "EXTERNAL_AGENT",
                LABEL: "BASIC_DATA.OVERSEAS_AGENT"
            },
            {
                PROPERTY: "OTHER_AGENT",
                LABEL: "BASIC_DATA.THIRD_AGENT"
            },
            // Main Tab - Account block
            {
                PROPERTY: "FORWARDED_BY",
                LABEL: "GENERAL.FORWARDED_BY"
            },
            {
                PROPERTY: "CORPORATE_BRANCH",
                LABEL: "BASIC_DATA.BRANCH"
            },
            {
                PROPERTY: "ACTIVITY_FIELD",
                LABEL: "REGISTRATION.CLIENT_SECTOR_TYPE"
            },
            {
                PROPERTY: "ACCOUNT_TYPE",
                LABEL: "REGISTRATION.ACCOUNT_TYPEACCOUNT_TYPE"
            },
            {
                PROPERTY: "ACCOUNT_INDICATION",
                LABEL: "ENTITY.INDICATION"
            },
            {
                PROPERTY: "SALES_PERSON",
                LABEL: "BASIC_DATA.SALES_EXECUTIVE"
            },
            {
                PROPERTY: "INSIDE_SALES_PERSON",
                LABEL: "BASIC_DATA.INSIDE_SALES"
            },
            {
                PROPERTY: "RESPONSIBLE_PRODUCT",
                LABEL: "BASIC_DATA.PRODUCT_RESPONSIBLE"
            },
            {
                PROPERTY: "SPONSOR",
                LABEL: "BASIC_DATA.ACCOUNT_SPONSOR"
            },
            {
                PROPERTY: "REPRESENTATIVES",
                LABEL: "GENERAL.REPRESENTATIVES"
            },
            {
                PROPERTY: "EVENT",
                LABEL: "OPERATIONAL.EVENT"
            },
            {
                PROPERTY: "REPRESENTATIVE",
                LABEL: "GENERAL.REPRESENTATIVE"
            },
            {
                PROPERTY: "LOCAL",
                LABEL: "GENERAL.LOCAL"
            },
            {
                PROPERTY: "ACCOUNT_REQUIREMENT",
                LABEL: "BASIC_DATA.REQUIREMENT"
            },
            {
                PROPERTY: "SECTION",
                LABEL: "REGISTRATION.CLIENT_SECTOR_TYPE"
            },
            {
                PROPERTY: "INDICATION",
                LABEL: "ENTITY.INDICATION"
            },
            {
                PROPERTY: "COMMODITY_CURRENCY",
                LABEL: "GENERAL.COMMODITY_CURRENCY"
            },
            {
                PROPERTY: "COMMODITY_VALUE",
                LABEL: "GENERAL.COMMODITY_VALUE"
            }
        ];

        return props;
    }
}

export const enum ELegalPersonSpecializationId {
    BROKER = "1",
    SHIPPING_COMPANY = "3",
    EXPORTER = "4",
    IMPORTER = "5",
    NOTIFY = "6",
    SALES_PERSON = "7",
    CORPORATE_BRANCH = "8",
    INTERNAL_SALES_PERSON = "9",
    CUSTOMER_SERVICE = "10",
    AGENCY = "11",
    AGENT = "12",
    PORT_TERMINAL = "16",
    EMPTY_TERMINAL = "17",
    INDICATION = "18",
    COURIER = "19",
    OTHER = "20",
    PROVIDER = "21",
    INTERNAL = "22",
    ACCOUNT = "23",
    INSURANCE_BROKER = "24",
    REPRESENTATIVE = "25",
    COMMERCIAL_UNITY = "26",
    EQUIPMENT_PROVIDER = "27"
}
export const enum EInttraSearchDateType {
    DEPARTURE = "ByDepartureDate",
    ARRIVAL = "ByArrivalDate"
}
export const enum EInttraMoveType {
    DOOR_TO_DOOR = "DoorToDoor",
    DOOR_TO_PORT = "DoorToPort",
    PORT_TO_PORT = "PortToPort",
    PORT_TO_DOOR = "PortToDoor"
}

export const enum ETransactionLocationIdentifierType {
    UNLOC = "UNLOC",
    PartnerAlias = "PartnerAlias"
}

export const enum ETransactionLocationType {
    BL_RELEASE_OFFICE = "BLReleaseOffice",
    PLACE_OF_PAYMENT = "PlaceOfPayment",
    GOODS_ORIGIN = "GoodsOrigin",
    PLACE_OF_RECEIPT = "PlaceOfReceipt",
    PLACE_OF_DELIVERY = "PlaceOfDelivery",
    PROHIBITED_TRANSSHIPMENT_LOCATION = "ProhibitedTransshipmentLocation",
    REQUESTED_TRANSSHIPMENT_LOCATION = "RequestedTransshipmentLocation",
    BOOKING_OFFICE = "BookingOffice",
    LAST_NON_US_PORT = "LastNonUSPort",
    FIRST_US_PORT = "FirstUSPort",
    FIRST_FOREIGN_PORT_OF_ACCEPTANCE = "FirstForeignPortOfAcceptance",
    FINAL_PORT_FOR_AMS_DOCUMENTATION = "FinalPortForAMSDocumentation",
    PLACE_OF_LOAD = "PlaceOfLoad",
    PLACE_OF_DISCHARGE = "PlaceOfDischarge"
}

export const enum ETransportStage {
    PRE_CARRIAGE = "PreCarriage",
    MAIN_CARRIAGE = "MainCarriage",
    ON_CARRIAGE = "OnCarriage"
}

export const enum EContactType {
    INFORMATION_CONTACT = "InformationContact",
    ALTERNATE_CONTACT = "AlternateContact",
    NOTIFICATION_CONTACT = "NotificationContact",
    CONFIRMED_WITH = "ConfirmedWith",
    EQUIPMENT_CONTACT = "EquipmentContact",
    EMERGENCY_DANGEROUS_GOODS_CONTACT = "EmergencyDangerousGoodsContact"
}

export const enum EBRReferenceType {
    FREIGHT_TARIFF_NUMBER = "FreightTariffNumber",
    CONTRACT_PARTY_REFERENCE_NUMBER = "ContractPartyReferenceNumber",
    OUT_BOUND_BOOKING_AGENT_REF_NUMBER = "OutboundBookingAgentRefNumber",
    VEHICLE_IDENTIFICATION_NUMBER = "VehicleIdentificationNumber",
    CONSIGNEE_REFERENCE_NUMBER = "ConsigneeReferenceNumber",
    BILL_OF_LADING_NUMBER = "BillOfLadingNumber",
    BOOKING_NUMBER = "BookingNumber",
    CONTRACT_NUMBER = "ContractNumber",
    EXPORT_LICENSE_NUMBER = "ExportLicenseNumber",
    FREIGHT_FORWARDER_REF_NUMBER = "FreightForwarderRefNumber",
    CONTRACT_LINE_ITEM_NUMBER = "ContractLineItemNumber",
    PURCHASE_ORDER_NUMBER = "PurchaseOrderNumber",
    SHIPPER_REFERENCE_NUMBER = "ShipperReferenceNumber",
    CANADIAN_CARGO_CONTROL_NUMBER = "CanadianCargoControlNumber",
    UNIQUE_CONSIGNMENT_NUMBER = "UniqueConsignmentNumber",
    CONSIGNEE_ORDER_NUMBER = "ConsigneeOrderNumber",
    EXPORTERS_REFERENCE_NUMBER = "ExportersReferenceNumber",
    GOVT_ASSIGNED_IDENTIFIER = "GovtAssignedIdentifier",
    INVOICE_NUMBER = "InvoiceNumber",
    INTERNAL_TRANSACTION_NUMBER = "InternalTransactionNumber",
    SHIPMENT_ID = "ShipmentID",
    INTTRA_REFERENCE_NUMBER = "INTTRAReferenceNumber"
}

export const enum EBCReferenceType {
    FREIGHT_TARIFF_NUMBER = "FreightTariffNumber",
    CONTRACT_PARTY_REFERENCE_NUMBER = "ContractPartyReferenceNumber",
    OUT_BOUND_BOOKING_AGENT_REF_NUMBER = "OutboundBookingAgentRefNumber",
    CARRIER_SOURCE_BOOKING_NUMBER = "CarrierSourceBookingNumber",
    VEHICLE_IDENTIFICATION_NUMBER = "VehicleIdentificationNumber",
    CONSIGNEE_REFERENCE_NUMBER = "ConsigneeReferenceNumber",
    BILL_OF_LADING_NUMBER = "BillOfLadingNumber",
    BOOKING_NUMBER = "BookingNumber",
    LOCAL_BOOKING_NUMBER = "LocalBookingNumber",
    CONTRACT_NUMBER = "ContractNumber",
    EXPORT_LICENSE_NUMBER = "ExportLicenseNumber",
    FREIGHT_FORWARDER_REF_NUMBER = "FreightForwarderRefNumber",
    CONTRACT_LINE_ITEM_NUMBER = "ContractLineItemNumber",
    PURCHASE_ORDER_NUMBER = "PurchaseOrderNumber",
    SHIPPER_REFERENCE_NUMBER = "ShipperReferenceNumber",
    CONTAINER_RELEASE_NUMBER = "ContainerReleaseNumber",
    INTTRA_REFERENCE_NUMBER = "INTTRAReferenceNumber",
    CANADIAN_CARGO_CONTROL_NUMBER = "CanadianCargoControlNumber",
    SHIPMENT_ID = "ShipmentID",
    UNIQUE_CONSIGNMENT_NUMBER = "UniqueConsignmentNumber"
}

export const enum ETransactionContactType {
    INFORMATION_CONTACT = "InformationContact",
    NOTIFICATION_CONTACT = "NotificationContact",
    CONFIRMED_WITH = "ConfirmedWith",
    EQUIPMENT_CONTACT = "EquipmentContact",
    EMERGENCY_DANGEROUS_GOODS_CONTACT = "EmergencyDangerousGoodsContact"
}

export const enum EDateFormat {
    CENTURY_DATE_HOUR_SECONDS = "CCYYMMDDHHMMSS",
    CENTURY_DATE_HOUR = "CCYYMMDDHHMM",
    YEAR_MONTH_DATE_HOUR = "YYMMDDHHMM",
    CENTURY_YEAR_MONTH_DAY = "CCYYMMDD"
}

export const enum ELocationDateType {
    EARLIEST_DEPARTURE_DATE = "EarliestDepartureDate",
    LATEST_DELIVERY_DATE = "LatestDeliveryDate",
    BL_RELEASE_DATE = "BLReleaseDate",
    ESTIMATED_ARRIVAL_DATE = "EstimatedArrivalDate",
    AMS_FILLING_DUE_DATE = "AMSFilingDueDate",
    ESTIMATED_DEPARTURE_DATE = "EstimatedDepartureDate",
    CLOSING_DATE = "ClosingDate"
}

export const enum EPartyRole {
    CARRIER = "Carrier",
    BOOKER = "Booker",
    SHIPPER = "Shipper",
    FORWARDER = "Forwarder",
    CONSIGNEE = "Consignee",
    CONTRACT_PARTY = "ContractParty",
    MAIN_NOTIFY_PARTY = "MainNotifyParty",
    FIRST_ADDITIONAL_NOTIFY_PARTY = "FirstAdditionalNotifyParty",
    SECOND_ADDITIONAL_NOTIFY_PARTY = "SecondAdditionalNotifyParty",
    MESSAGE_RECIPIENT = "MessageRecipient",
    BOOKING_OFFICE = "BookingOffice",
    SHIP_FROM = "ShipFrom",
    SHIP_TO = "ShipTo"
}

export const enum EGoodsClassification {
    WCO = "WCO",
    SCHEDB = "SchedB"
}

export const enum EServiceType {
    FCL = "FCL",
    LCL = "LCL"
}

export const enum EPackageType {
    OUTER = "OUTER",
    INNER = "INNER",
    INNERINNER = "INNERINNER",
    SINGLELINE = "SINGLELINE"
}

export const enum EWeight {
    KGM = "KGM",
    LBR = "LBR"
}

export const enum EVolume {
    FTQ = "FTQ",
    MTQ = "MTQ",
    GAL = "GAL",
    LTR = "LTR"
}

export const enum EGoodsReference {
    STOCK_KEEPING_UNIT = "StockKeepingUnit",
    VEHICLE_IDENTIFICATION_NUMBER = "VehicleIdentificationNumber",
    PURCHASE_ORDER_NUMBER = "PurchaseOrderNumber",
    EXPORT_LICENSE_NUMBER = "ExportLicenseNumber",
    CANADIAN_CARGO_CONTROL_NUMBER = "CanadianCargoControlNumber",
    UNIQUE_CONSIGNMENT_NUMBER = "UniqueConsignmentNumber"
}

export const enum EDimension {
    APPLIED = "APPLIED",
    APPLIED_WITH_WARNINGS = "APPLIED_WITH_WARNINGS",
    IGNORED = "IGNORED",
    REJECTED = "REJECTED"
}

export const enum EEdiExternalCodeSetupInttra {
    INTTRA_INTEGRATION_ID = "4",
    CARRIER_TYPE_ID = "6",
    BRANCH_TYPE_ID = "7",
    SHIPOWNER_TYPE_ID = "8",
    EQUIPMENT_TYPE_ID = "9"
}

export const enum EBookingState {
    REQUEST = "REQUEST",
    PENDING = "PENDING",
    CONFIRM = "CONFIRM",
    AMEND = "AMEND",
    DECLINE = "DECLINE",
    CANCEL = "CANCEL",
    REPLACE = "REPLACE"
}

export const enum EBookingMonacoState {
    REQUEST = "Booking Solicitado",
    PENDING = "Booking Pendente",
    CONFIRM = "Booking Confirmado",
    AMEND = "Booking Alterado",
    DECLINE = "Booking Declinado",
    CANCEL = "Booking Cancelado",
    REPLACE = "Booking Substituído"
}

export const enum EValidationErrors {
    MOVE_TYPE_ERROR = "Move Type not informed.",
    TRANSACTION_LOCATION_ERROR = "Transaction Location not informed.",
    VOYAGE_ERROR = "Information from the voyage not informed.",
    CARGO_ERROR = "Cargo information not informed.",
    EQUIPMENT_LIST_ERROR = "Equipment list not informed.",
    CARRIER_NOT_FOUND = "Carrier information not found in Monaco.",
    FORWARDER_NOT_FOUND = "Forwarder information not found in Monaco.",
    CONTRACT_NUMBER = "No contract number was informed.",
    FORWARDER_REFERENCE_NUMBER = "No forwarder reference number was informed",
    PORT_OF_LOAD = "Port of load not informed",
    PORT_OF_DISCHARGE = "Port of Discharge not informed",
    TRANSPORT_MODE_ERROR = "Transport mode not found to convert to Inttra code.",
    CHARGE_TYPE_ERROR = "Charge type not found to convert to Inttra code.",
    PAYMENT_TERM_ERROR = "Payment type not found to convert to Inttra code.",
    CHARGE_PAYER_ERROR = "Charge payer not found to convert to Inttra code.",
    PACKAGE_DANGER_TYPE_ERROR = "Packing Group danger type not found to convert to Inttra code."
}

export const enum EValidationWarnings {
    INSUFICIENT_INFORMATION = "Insuficient information to send request to INTTRA.",
    MISSING_INTTRA_REFERENCE_NUMBER = "Missing Inttra Reference Number from registering.",
    MISSING_BOOKING_STATE = "Booking state was not informed.",
    REQUEST_WITH_CUSTOMER_CHANGE = "Can not send booking request with customerChangeSummary informed.",
    MISSING_REQUEST_BOOKER = "You must provide one booker to send the request.",
    MISSING_REQUEST_CARRIER = "You must provide one carrier to send the request.",
    MISSING_REQUEST_SHIPPER_FORWARDER = "You must provide the shipper or the forwarder to send the request.",
    MISSING_PLACE_OF_RECEIPT = "No place of receipt was informed to this request.",
    MISSING_PLACE_OF_DELIVERY = "No place of delivery was informed to this request.",
    LOAD_EQUALS_DISCHARGE = "Place of Receipt cannot be same as Place of Delivery."
}

export const enum EEquipmentSizeCode {
    ISO = "ISO"
}

export const enum EAirFlowType {
    CBM = "CBM",
    CFT = "CFT"
}

export const enum EAtmosphereGasType {
    NITROGEN = "NITROGEN",
    OXYGEN = "OXYGEN",
    CARBON_DIOXIDE = "CARBON_DIOXIDE",
    HUMIDITY = "HUMIDITY"
}

export const enum ETemperatureType {
    CEL = "CEL",
    FAH = "FAH"
}

export const enum EPayerChargeType {
    ADDITIONAL = "Additional",
    OCEAN_FREIGHT = "OceanFreight",
    DESTINATION_HAULAGE = "DestinationHaulage",
    DESTINATION_TERMINAL_HANDLING = "DestinationTerminalHandling",
    ORIGIN_TERMINAL_HANDLING = "OriginTerminalHandling",
    ORIGIN_HAULAGE = "OriginHaulage",
    ALL_CHARGES = "AllCharges"
}

export const enum ETransportMode {
    MARITIME_TRANSPORT = "MaritimeTransport",
    RAIL_TRANSPORT = "RailTransport",
    ROAD_TRANSPORT = "RoadTransport",
    INLAND_WATER_TRANSPORT = "InlandWaterTransport",
    RAIL_WATER_TRANSPORT = "Rail_WaterTransport",
    RAIL_ROAD_TRANSPORT = "Rail_RoadTransport",
    ROAD_WATER_TRANSPORT = "Road_WaterTransport"
}

export const enum ETransportModeId {
    TRUCK = "1",
    RAIL = "2",
    COMBINED_RAIL = "3",
    BARGE = "4",
    COMBINED_WATERWAY = "5",
    ALL_WATER = "6",
    ALL_AIR = "7"
}

export const enum EPaymentTerm {
    PREPAID = "Prepaid",
    COLLECT = "Collect",
    PAYABLE_ELSEWHERE = "PayableElsewhere"
}

export const enum EPendingReason {
    EQUIPMENT_AVAILABILITY_VERIFICATION = "EquipmentAvailabilityVerification",
    HAZARDOUS_COMMODITY_VERIFICATION = "HazardousCommodityVerification",
    CHARGE_VERIFICATION = "ChargeVerification",
    SLOTS_AVAILABILITY_VERIFICATION = "SlotsAvailabilityVerification"
}

export const enum EManifestFiler {
    CARRIER = "Carrier", // Not Used
    NOT_CARRIER = "NotCarrier", // Not Used
    CUSTOMER = "Customer",
    CARRIER_NVO = "Carrier_NVO"
}

export const enum ESplit {
    DOCUMENTATION = "Documentation",
    CONTAINERS_ROLLED = "ContainersRolled",
    PER_CONTAINER_RELEASE = "PerContainerRelease"
}

export const enum EEquipamentHaulage {
    CARRIER_CARRIER = "CarrierCarrier",
    MERCHANT_MERCHANT = "MerchantMerchant",
    CARRIER_MERCHANT = "CarrierMerchant",
    MERCHANT_CARRIER = "MerchantCarrier"
}

export const enum EEquipmentServiceType {
    FCL_FCL = "FCLFCL",
    FCL_LCL = "FCLLCL"
}

export const enum EPackageDangerType {
    GREAT_DANGER = "GreatDanger",
    MEDIUM_DANGER = "MediumDanger",
    MINOR_DANGER = "MinorDanger"
}

export const enum EBookingContactCell {
    UNIT_CORPORATIVE = "46"
}

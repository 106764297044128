import * as angular from 'angular';
import * as Address from '../../communication/Address';
import moment = require('moment');
import { IGridOptions } from 'ui-grid';
import { fileUploader as FileUploader } from 'angular-file-upload';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { GridFormService, IGridFormController, IGridFormServiceScope, IMonacoRequest } from "@services/GridFormService";
import { IMonacoColumnDef } from "@services/GridService2";
import { FormService2 } from '@services/FormService2';
import { IModalService } from "@services/ModalService";
import { IRestService } from '@services/RestService';
import { ISessionService } from "@services/SessionService";
import { OperationalService } from "@services/OperationalService";
import { ExternalService } from "@services/ExternalService";
import { ProductService } from '@services/ProductService';
import { DataOperationalService } from "@services/DataOperationalService";
import { DataProcessService } from "@services/DataProcessService";
import { Process, OFFER, EVENT, TOTAL_CHARGES } from "@models/interface/operational/NewProcess";
import { UserModel } from "@models/interface/common/UserModel";
import { ISelectorModel, SelectorModel } from '@models/mongo/SelectorModel';
import { EPriorityId, EOperation, EProcessSituation, EProductId, EEventType } from '@enums/GenericData';
import { ValidateUtil } from '../../common/util/ValidateUtil';
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { BrowserTitle } from '../../common/BrowserTitle';
import { IFloatingMenu, IFloatingOption } from '../../common/interface/IFloatingMenu';
import { IFinopService } from '@services/FinopService';
import { IChargeParameter, IInvoiceParameter, IDraftParameter, IProcessDocumentParameter, ITaskParameter, IDeadLineDefaultParameter, IProcessDetDemParameter } from '../../common/model/ModelParameter';
import { IDocumentError } from '@models/interface/common/IDocumentError';
//import { PermissionService } from '../../app/services/PermissionService';
import { HelperService } from "@services/HelperService";

export interface IIntegrationRedundance {
    NAME: string
    REASON: string;
    DATE: Date;
    ID: string | number;
}

export interface IIntegrationRedundanceModal extends ng.IScope {
    integrationReduncance: IIntegrationRedundance;
    parsedErrorMessage: string;
    copyErrorTextToClipboard: (integrationReduncance: IIntegrationRedundance) => void;
    sendSync: (id: number) => void;
}

export interface IProcessKnowledgeModal extends ng.IScope {
    model: IProcess;
    oldModel: IProcess;
    process: IProcess
    type: SelectorModel;

    processDocumentTypeList: () => ISelectorModel[];
    getProcessSelector: (processNumber: string) => void;
    getProcessData: (number: string) => Promise<void>;
    applyProcessDocument: () => Promise<void>;
    closeProcessDocumentModal: () => Promise<void>;
}

export const enum ECollapseState {
    VIEW_PROCESS = "viewProcess",
    EDIT_PROCESS = "editProcess",
    CANCEL_PROCESS = "cancelProcess",
    MAIN = "mainRow",
    CARGO = "cargoRow",
    EVENTS = "eventsRow",
    CHARGES = "chargesRow",
    CHARGE_OUT_DATE = "chargeOutDateRow",
    COMMUNICATION = "communicationRow",
    MANAGEMENT = "managementRow",
    TASKS = "tasksRow",
    PROCESS_DOCUMENT = "documentRow"
}

export interface INewProcessExchangeData {
    OPERATION: string;
    PROCESS_NUMBER: string;
    ID_EVENT?: string;
}

interface IRepositionPanel {
    panelId: string;
    panelElement: JQLite;
    collapseId: string;
    collapseElement: JQLite;
}

interface ICollapseState {
    panel: string;
    released: boolean;
    nextState: string;
}

interface IProcess extends Process {
    OFFER_ACTIVE: OFFER,
    EVENT_DISCH: EVENT,
    EVENT_LOAD: EVENT,
    EVENT_PICKUP: EVENT,
    EVENT_DELIVERY: EVENT
}

interface IValidationResult {
    HAS_ERROR: boolean;
    HAS_WARNING: boolean;
    ERROR_REASONS: IValidationResultErrorReasons[];
    WARNING_REASONS: IValidationResultErrorReasons[];
}

interface IValidationResultErrorReasons {
    VALIDATION_RESULT_ERRO: ISelectorModel;
    VALIDATION_RESULT_REASON: ISelectorModel;
}

interface IAllValidationResult {
    PROCESS_NUMBER: string;
    PROCESS: IValidationResult;
}

export interface IProcessSummaryValidationResult {
    USER: string;
    DATE: Date;
    ERROR: number;
    HAS_ERROR: boolean;
    HAS_WARNING: boolean;
    WARNING: number;
}

export interface INewProcessScope extends IGridFormServiceScope {
    model: IProcess;
    form: ng.IFormController;
    gridOptions: IGridOptions;
    showNewProcessForm: boolean;
    menuFloating: IFloatingMenu;
    processConcatenated: string;
    processConcatenatedHtml: string;
    processIsBookmarked: boolean;
    user: UserModel;
    $q: ng.IQService;
    $filter: ng.FilterFactory;
    $sce: angular.ISCEService;
    $compile: angular.ICompileService;
    $timeout: ng.ITimeoutService;
    formService: FormService2;
    sessionService: ISessionService;
    restService: IRestService;
    operationalService: OperationalService;
    dataOperationalService: DataOperationalService;
    dataProcessService: DataProcessService;
    externalService: ExternalService;
    productService: ProductService;
    finopService: IFinopService;
    //permissionService: PermissionService;
    modalService: IModalService;
    fileUploader: FileUploader;
    lastScroll: number;
    collapseState: ICollapseState;
    downloadRoute: string;
    equipmentConcatenated: string;
    eventTransitTime: string;
    eventTransitTimeDifference: string;
    priorityList: ISelectorModel[];
    totalSumCharges: TOTAL_CHARGES;
    allValidationResult: IAllValidationResult;
    editProcess: (process: Process, idEvent?: string) => Promise<void>;
    viewProcess: (process: Process, idEvent?: string) => Promise<void>;
    verifyOfferError: (entity: Process) => boolean;
    openModalIntegration: (entity: Process, integrationReduncance: IIntegrationRedundance) => void;
    openReplicationModalIntegration: (id: number, documentError: IDocumentError[]) => void;
    goToProcessDetDemManagement: (entity: Process) => void;
    repositionPanels: (currentPanelId: string, isOpenning?: boolean) => void;
    hasChanges: (newObj: Object, oldObj: Object, propertiesToIgnore?: string[]) => boolean;
    hasInvalidRequiredElements: (elementId: string) => boolean;
    hasInvalidElements: (elementId: string) => boolean;
    checkDateValidity: (initialDate: Date, finalDate: Date) => boolean;
    modalSaveConfirmation: (headerText: string, closeButtonText: string) => Promise<boolean>;
    releaseCollapse: (panel?: string) => void;
    showSave: () => boolean;
    saveBookmarkProcess: () => Promise<void>;
    updateProcessPriority: () => void;
    goToCharge: () => void;
    goToInvoice: () => void;
    goToDraftProcess: () => void;
    goToDocument: () => void;
    goToTaskManager: () => void;
    goToProcessDocument: () => void;
    goToDeadLineMasterHouse: () => void;
    openSummaryModal: () => void;
    processEDIAgility: (process: Process) => void;
    buildIntegrationErrorModalScope: (integrationReduncance: IIntegrationRedundance, name: string) => Promise<any>;
    parseErrorMessage: (integrationReduncance: IIntegrationRedundance) => string;
    updateIntegrationGrid: (id: string, modalScope: IIntegrationRedundanceModal, name: string) => Promise<void>;
    openConcatenatedModal: () => void;
    updateGridRow: (id: string) => Promise<void>;
    consistencyValidateProcess: (processNumber: string, process?: Process, loadDetails?: boolean) => Promise<void>;
    viewProcessConsistencyValidateDetails: (process: Process) => Promise<void>;
    buildGridConsistencyTooltip: (summaryValidationResult: IProcessSummaryValidationResult) => string;
    disablePanelDueToPreProcessStatus: () => boolean;
    openProcessKnowledgeModal: () => void;
    generateProcessConcatenated: (isHtml?: boolean) => string;
    openTrackingLink: (processProvider: ISelectorModel) => Promise<void>;
    copyToClipboard: (processNumber: string) => Promise<void>;
}

export class NewProcessController extends GridFormService implements IGridFormController {
    static $inject: string[] = ["$injector", "$scope"];
    private $scope: INewProcessScope;
    private lastGridProcess: Process;
    private preProcessModalId: number;
    private summaryModalId: number;
    private modalIntegrationId: number;
    private processKnowledgeModal: number;
    private $filter: ng.IFilterService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: INewProcessScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$scope.$q = $injector.get('$q');
        this.$scope.$filter = $injector.get('$filter');
        this.$scope.$sce = $injector.get('$sce');
        this.$scope.$compile = $injector.get('$compile');
        this.$scope.$timeout = $injector.get('$timeout');
        this.$scope.formService = this.formService;
        this.$scope.restService = $injector.get('RestService');
        this.$scope.externalService = $injector.get('ExternalService');
        this.$scope.productService = $injector.get('ProductService');
        this.$scope.operationalService = $injector.get('OperationalService');
        this.$scope.modalService = $injector.get('ModalService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.$scope.fileUploader = $injector.get('FileUploader');
        this.$scope.productService = $injector.get('ProductService');
        this.$scope.finopService = $injector.get('FinopService');
        //this.$scope.permissionService = $injector.get('PermissionService');
        this.$scope.collapseState = { panel: null, released: true, nextState: null };
        this.$scope.dataOperationalService = $injector.get('DataOperationalService');
        this.$scope.dataProcessService = $injector.get('DataProcessService');
        this.helperService = $injector.get('HelperService');

        if (this.config.environment === 'prod') {
            this.$scope.downloadRoute = `${Address.monacoAddressProd.FIS}/api/v1/fis/filesGeneric/download`;
        } else if (this.config.environment === 'qa') {
            this.$scope.downloadRoute = `${Address.monacoAddressQa.FIS}/api/v1/fis/filesGeneric/download`;
        } else { //dev
            this.$scope.downloadRoute = `${Address.monacoAddressLocal.FIS}/api/v1/fis/filesGeneric/download`;
        }
    }

    initScopeFunctions(): void {
        this.$scope.repositionPanels = (currentPanelId: string, isOpenning?: boolean) => {
            this.repositionPanels(currentPanelId, isOpenning);
        }

        this.$scope.hasChanges = (newObj: Object, oldObj: Object, propertiesToIgnore?: string[]) => {
            if (propertiesToIgnore) {
                const newAux = newObj ? angular.copy(newObj) : null;
                const oldAux = oldObj ? angular.copy(oldObj) : null;
                for (const property of propertiesToIgnore) {
                    if (newAux && newAux[property]) {
                        delete newAux[property];
                    }
                    if (oldAux && oldAux[property]) {
                        delete oldAux[property];
                    }
                }
                return !angular.equals(JSON.stringify(newAux), JSON.stringify(oldAux));
            }
            return !angular.equals(newObj, oldObj);
        }

        this.$scope.hasInvalidRequiredElements = (elementId: string) => {
            return this.hasInvalidRequiredElements(elementId);
        }

        this.$scope.hasInvalidElements = (elementId: string) => {
            return this.hasInvalidElements(elementId ? "#" + elementId : elementId);
        }

        this.$scope.checkDateValidity = (initialDate: Date, finalDate: Date): boolean => {
            if (!initialDate || typeof initialDate == "string") return true;
            if (!finalDate || typeof finalDate == "string") return true;
            else return ValidateUtil.isValidDateRange(initialDate, finalDate);
        }

        this.$scope.modalSaveConfirmation = async (headerText: string, closeButtonText: string) => {
            return this.modalSaveConfirmation(headerText, closeButtonText);
        }

        this.$scope.releaseCollapse = (panel?: string) => {
            this.releaseCollapse(panel);
        }

        this.$scope.showSave = () => {
            return this.$scope.operation != EOperation.VIEW && (this.$scope.collapseState.panel == ECollapseState.MAIN || this.$scope.collapseState.panel == ECollapseState.CARGO || this.$scope.collapseState.panel == ECollapseState.MANAGEMENT);
        }

        this.$scope.saveBookmarkProcess = async () => {
            try {
                this.formService.block();

                if (this.$scope.processIsBookmarked) {
                    const removed = await this.$rootScope.removeSetting('PROCESS_BOOKMARKED', this.$scope.model.PROCESS_NUMBER, false);
                    if (removed) {
                        this.$scope.processIsBookmarked = false;
                        const msg = this.formService.getTranslate('OPERATIONAL.REMOVE_STAR');
                        this.formService.notifySuccess(msg);
                    } else {
                        const msg = this.formService.getTranslate('OPERATIONAL.REMOVE_STAR_ERROR');
                        this.formService.notifyError(msg);
                    }
                } else {
                    const updated = await this.$rootScope.updateSetting('PROCESS_BOOKMARKED', this.$scope.model._id, this.$scope.model.PROCESS_NUMBER, false);
                    if (updated) {
                        this.$scope.processIsBookmarked = true;
                        const msg = this.formService.getTranslate('OPERATIONAL.ADD_STAR');
                        this.formService.notifySuccess(msg)
                    } else {
                        const msg = this.formService.getTranslate('OPERATIONAL.ADD_STAR_ERROR');
                        this.formService.notifyError(msg);
                    }
                }

                this.formService.unblock();
            } catch (ex) {
                this.formService.handleError(ex);
            }
        }

        this.$scope.updateProcessPriority = (): void => {
            this.updateProcessPriority();
        }

        this.$scope.goToCharge = () => {
            this.$scope.sessionService.openTab('app.finop.charge.register', <IChargeParameter>{ PROCESS_NUMBER: this.$scope.model.PROCESS_NUMBER });
        };

        this.$scope.goToInvoice = () => {
            this.$scope.sessionService.openTab('app.finop.invoice.register', <IInvoiceParameter>{ PROCESS_NUMBER: this.$scope.model.PROCESS_NUMBER });
        }

        this.$scope.goToDraftProcess = () => {
            this.$scope.sessionService.openTab('app.operational.draftRegister', <IDraftParameter>{ PROCESS_NUMBER: this.$scope.model.PROCESS_NUMBER });
        };

        this.$scope.goToDocument = () => {
            this.$scope.sessionService.openTab('app.operational.process.processDocument', <IProcessDocumentParameter>{ PROCESS_NUMBER: this.$scope.model.PROCESS_NUMBER });
        };

        this.$scope.goToTaskManager = () => {
            this.$scope.sessionService.openTab('app.management.activities', <ITaskParameter>{ PROCESS_NUMBER: this.$scope.model.PROCESS_NUMBER });
        };

        this.$scope.goToDeadLineMasterHouse = () => {
            this.$scope.sessionService.openTab('app.operational.voyagesAndDeadlines', <IDeadLineDefaultParameter>{ "PROCESS": this.$scope.model.PROCESS_NUMBER })
        }

        this.$scope.openSummaryModal = () => {
            this.openSummaryModal();
        }

        this.$scope.editProcess = async (process: IProcess, idEvent?: string): Promise<void> => {
            if (await this.permissionService.isRoleAllowed("PROCESSEDIT") === false) {
                this.permissionService.showBlockMessage();
                this.unblock();
                return;
            }

            this.$scope.operation = "edit";
            this.$scope.disableElements(false);
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');
            this.viewProcess(process, idEvent);
        }

        this.$scope.viewProcess = async (process: IProcess, idEvent?: string): Promise<void> => {
            if (await this.permissionService.isRoleAllowed("PROCESSVIEW") === false) {
                this.permissionService.showBlockMessage();
                this.unblock();
                return;
            }
            this.$scope.operation = EOperation.VIEW;
            this.$scope.disableElements(true);
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
            this.viewProcess(process, idEvent);

        }

        this.$scope.verifyOfferError = (entity: Process): boolean => {
            let result = false;
            if ((entity) && (entity.OFFER && entity.OFFER.length > 0)) {
                for (const offer of entity.OFFER) {
                    if (offer.DOCUMENT_ERROR && offer.DOCUMENT_ERROR.REASON) {
                        result = true;
                    }
                }
            }

            return result
        }

        this.$scope.openModalIntegration = async (entity: Process, integrationReduncance: IIntegrationRedundance): Promise<void> => {
            try {
                this.modalIntegrationId = this.$scope.modalService.newModal();

                const offer = entity.OFFER.find(element => element.ACTIVE);

                this.$scope.modalService.showModalInfo(
                    {
                        modalID: this.modalIntegrationId,
                        scope: this.$scope,
                        formService: 'register',
                        template: require("../../commercial/view/modals/integrationRedundance.html")
                    },
                    {
                        actionButtonText: 'GENERAL.CLOSE',
                        headerText: 'OPERATIONAL.PRODUCT_INTEGRATION'
                    });

                if (!integrationReduncance) {
                    integrationReduncance = {
                        NAME: entity.PROCESS_NUMBER,
                        DATE: offer.DOCUMENT_ERROR.DATE,
                        ID: entity.ID,
                        REASON: offer.DOCUMENT_ERROR.REASON
                    }
                } else if (!integrationReduncance.ID) {
                    integrationReduncance.ID = entity.ID;
                }

                await this.$scope.buildIntegrationErrorModalScope(integrationReduncance, entity.PROCESS_NUMBER);

            } catch (ex) {
                this.handleError(ex);
            }
        }

        this.$scope.openProcessKnowledgeModal = async () => {
            this.openProcessKnowledgeModal();
        }

        this.$scope.generateProcessConcatenated = (isHtml?: boolean) => {
            return this.generateProcessConcatenated(isHtml);
        }

        this.$scope.openReplicationModalIntegration = async (id: number, documentError: IDocumentError[]): Promise<void> => {
            try {
                this.modalIntegrationId = this.$scope.modalService.newModal();

                this.modalIntegrationId = this.$scope.modalService.newModal();
                const documentErrorList: IDocumentError[] = documentError;
                this.$scope.modalService.showModalIntegrationRedundance({ modalID: this.modalIntegrationId, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "Integration Process Replication" });
            } catch (ex) {
                this.handleError(ex);
            }
        }

        this.$scope.goToProcessDetDemManagement = (entity: Process) => {
            this.$scope.sessionService.openTab('app.operational.process.detDemProcess', <IProcessDetDemParameter>{ PROCESS_NUMBER: `${entity.PROCESS_NUMBER}DD` });
        }

        this.$scope.buildIntegrationErrorModalScope = async (integrationReduncance: IIntegrationRedundance, name: string): Promise<any> => {
            const modalScope: IIntegrationRedundanceModal = await this.$scope.modalService.getModalScope(this.modalIntegrationId);

            modalScope.integrationReduncance = angular.copy(integrationReduncance);
            modalScope.parsedErrorMessage = this.$scope.parseErrorMessage(integrationReduncance);

            modalScope.copyErrorTextToClipboard = (integrationReduncance: IIntegrationRedundance) => {
                if (integrationReduncance) {
                    const elmAux = document.createElement("textarea");
                    document.body.appendChild(elmAux);
                    elmAux.value = this.$scope.parseErrorMessage(integrationReduncance);
                    elmAux.select();
                    document.execCommand("copy");
                    document.body.removeChild(elmAux);
                    this.formService.notifySuccess("Mensagem de log copiada para o clipboard.");
                }
            }

            modalScope.sendSync = async (id: number): Promise<void> => {
                let result = null;
                try {
                    if (id) {
                        this.formService.block();
                        modalScope.parsedErrorMessage = this.formService.getTranslate('REGISTRATION.MESSAGES.ERROR.SENDING_REQUEST');
                        const rc = await this.$scope.dataOperationalService.post(`/sync/offerUsedProcess`, { "id": [id] }, 120000);

                        if (rc && rc.data && rc.data.data && rc.status == 200) {
                            result = rc.data.data;
                        }

                        if (result) {
                            modalScope.integrationReduncance.REASON = null;
                            modalScope.parsedErrorMessage = this.formService.getTranslate('REGISTRATION.MESSAGES.ERROR.SUCCESS_REQUEST');
                        }
                    }
                } catch (ex) {
                    const msg = this.formService.getTranslate('REGISTRATION.MESSAGES.ERROR.ERROR_SENDING_REQUEST');
                    this.formService.handleError(msg);
                    modalScope.integrationReduncance.DATE = new Date();
                    modalScope.integrationReduncance.REASON = ex;
                    modalScope.parsedErrorMessage = this.$scope.parseErrorMessage(modalScope.integrationReduncance);
                } finally {
                    this.formService.unblock();
                    this.$scope.updateIntegrationGrid(id.toString(), modalScope, name);
                    return result;
                }
            }
            return modalScope;
        }

        this.$scope.parseErrorMessage = (integrationReduncance: IIntegrationRedundance): string => {
            let returnString = ``;
            if (integrationReduncance && integrationReduncance.DATE && integrationReduncance.REASON) {
                returnString = (moment(integrationReduncance.DATE).format("(DD/MM) HH:mm:ss"));
                returnString += (' - ');
                returnString += integrationReduncance.REASON;
            }
            return returnString;
        }

        this.$scope.processEDIAgility = async (processEntity: Process): Promise<void> => {
            try {
                this.block();

                let files: { NAME: string, LINES: Buffer }[] = null;
                const result = await this.getEDIAgility(processEntity.PROCESS_NUMBER);
                if (result && result.data)
                    files = result.data;

                if (!files || files.length == 0) {
                    this.unblock();
                    return;
                }

                const fileData = files[0];
                const fileName = `${fileData.NAME}.txt`;
                const file: any = new File([new Uint8Array(fileData.LINES['data'])], fileName, { type: "text/plain;charset=utf-8" });
                const a = document.createElement('a');
                a.href = URL.createObjectURL(file);
                a.download = fileName;
                a.target = "_blank";
                a.click();

                this.$scope.$timeout(() => {
                    this.unblock();
                    return;
                });

                this.unblock();
            } catch (ex) {
                this.handleError(ex);
            }
        }

        this.$scope.updateIntegrationGrid = async (id: string, modalScope: IIntegrationRedundanceModal, name: string) => {
            try {
                this.formService.block();
                if (modalScope.integrationReduncance) {
                    modalScope.parsedErrorMessage = this.formService.getTranslate('GENERAL.LOADING_SYNC');
                    if (!modalScope.integrationReduncance.REASON) {
                        setTimeout(async () => {
                            try {
                                if (angular.isArray(this.$scope.gridOptions.data)) {
                                    let validUpdate = false;

                                    const processData = await this.getProcessByProcessNumber(id);
                                    if (processData) {
                                        const offer = processData.OFFER.find(element => element.ACTIVE);

                                        const processIndex = this.$scope.gridOptions.data.findIndex(x => x.ID == id);
                                        if (processIndex && offer && offer.DOCUMENT_ERROR !== undefined) {
                                            this.$scope.gridOptions.data[processIndex] = angular.copy(processData);
                                            if (offer.DOCUMENT_ERROR && offer.DOCUMENT_ERROR.REASON) {
                                                modalScope.parsedErrorMessage = this.$scope.parseErrorMessage(offer.DOCUMENT_ERROR);
                                            } else {
                                                modalScope.parsedErrorMessage = this.formService.getTranslate('REGISTRATION.SUCCESSFULLY_SYNCED');
                                            }
                                            validUpdate = true;
                                        }
                                    }

                                    if (!validUpdate) {
                                        const msgError = this.formService.getTranslate('OPERATIONAL.DATA_UPDATE_ERROR')
                                        throw new Error(msgError);
                                    }
                                }
                            } catch (ex) {
                                modalScope.integrationReduncance.ID = id;
                                modalScope.integrationReduncance.DATE = new Date();
                                modalScope.integrationReduncance.REASON = ex;
                                modalScope.parsedErrorMessage = this.$scope.parseErrorMessage(modalScope.integrationReduncance);
                                const msgError = this.formService.getTranslate('REGISTRATION.MESSAGES.ERROR.ERROR_SYNC');
                                this.formService.handleError(msgError);
                            } finally {
                                this.formService.unblock();
                            }
                        }, 3000)
                    } else {
                        modalScope.integrationReduncance.ID = id;
                        modalScope.integrationReduncance.DATE = new Date();
                        modalScope.parsedErrorMessage = this.$scope.parseErrorMessage(modalScope.integrationReduncance);
                        this.formService.unblock();
                    }
                } else {
                    modalScope.integrationReduncance = {
                        NAME: name,
                        ID: id,
                        DATE: new Date(),
                        REASON: this.formService.getTranslate('GENERAL.ERROR_DURING_REQUEST')
                    }
                    modalScope.parsedErrorMessage = this.$scope.parseErrorMessage(modalScope.integrationReduncance);
                    this.formService.unblock();
                }
            } catch (ex) {
                modalScope.parsedErrorMessage = ex;
                this.formService.handleError(this.formService.getTranslate('GENERAL.ERROR_DURING_REQUEST'));
                this.formService.unblock();
            }
        }

        this.$scope.openConcatenatedModal = () => {
            this.openConcatenatedModal();
        }

        this.$scope.updateGridRow = async (id: string): Promise<void> => {
            await this.updateGridRow(id);
        }

        this.$scope.consistencyValidateProcess = async (idProcess: string, process?: Process, loadDetails?: boolean): Promise<void> => {
            this.consistencyValidateProcess(idProcess, process, loadDetails);
        }

        this.$scope.viewProcessConsistencyValidateDetails = async (process: Process): Promise<void> => {
            this.viewProcessConsistencyValidateDetails(process);
        }

        this.$scope.buildGridConsistencyTooltip = (summaryValidationResult: IProcessSummaryValidationResult) => {
            return this.tooltip(summaryValidationResult);
        }

        this.$scope.disablePanelDueToPreProcessStatus = (): boolean => {
            const situation = this.$scope && this.$scope.model && this.$scope.model.SITUATION && this.$scope.model.SITUATION.ID;
            let result = false;

            switch (situation) {
                case EProcessSituation.PRE_PROCESS:
                    break;
                default:
                    result = true;
            }
            return result;
        }

        this.$scope.openTrackingLink = async (processProvider: ISelectorModel): Promise<void> => {
            await this.openTrackingLink(processProvider);
        }

        this.$scope.copyToClipboard = async (processNumber: string): Promise<void> => {
            await this.copyToClipboard(processNumber);
        }
    }

    private async openConcatenatedModal(): Promise<void> {
        const concatenatedComplementBefore = angular.copy(this.$scope.model.CONCATENATED_COMPLEMENT);
        const html = `
        <div class="row">
            <div class="col-lg-12">
                <input type="text" class="form-control" id="concatenatedComplement" name="concatenatedComplement" ng-model="model.CONCATENATED_COMPLEMENT" maxlength="15" ng-readonly="operation == 'view'">
            </div>
        </div>
        `;
        const modalInstance: IModalInstanceService = await this.$scope.modalService.showModalInfo(
            {
                scope: this.$scope,
                size: 'lg'
            },
            {
                closeButtonText: 'GENERAL.CLOSE',
                actionButtonText: 'GENERAL.SAVE',
                headerText: 'GENERAL.UPDATE_CONCATENATED',
                bodyText: this.$scope.$sce.trustAsHtml(html),
            }
        );

        modalInstance.rendered.then(() => {
            const concatenatedComplement = angular.element("#concatenatedComplement");
            if (concatenatedComplement) this.$scope.$compile(concatenatedComplement)(this.$scope);
        });

        const apply = await modalInstance.result.then(function (result) {
            return result.$value;
        }, function (result) {
            return result.$value;
        });

        if (apply) {
            const resultOperation = await this.$scope.operationalService.post('/process/update/concatenatedComplement', { _id: this.$scope.model._id, CONCATENATED_COMPLEMENT: this.$scope.model.CONCATENATED_COMPLEMENT }, 30000);
            if (resultOperation) {
                this.$scope.processConcatenated = this.generateProcessConcatenated();
                this.$scope.processConcatenatedHtml = this.generateProcessConcatenated(true);
                this.$scope.$applyAsync();
                const msgSuccess = this.formService.getTranslate('GENERAL.SUCCESS_UPDATE_CONCATENATED');
                this.formService.notifySuccess(msgSuccess);
            }
            else {
                const msgError = this.formService.getTranslate('REGISTRATION.MESSAGES.ERROR.UPDATE_ERROR');
                this.formService.notifyError(msgError);
                this.$scope.model.CONCATENATED_COMPLEMENT = concatenatedComplementBefore;
            }
        } else this.$scope.model.CONCATENATED_COMPLEMENT = concatenatedComplementBefore;
    }

    async getProcessByProcessNumber(id: string) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const processData = await this.$scope.operationalService.post('/process/list', { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000);
            if (processData && processData.data && processData.data.data && (processData.data.data.data && processData.data.data.data.length > 0)) return processData.data.data.data[0];
            else return null;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    async initDependencies(): Promise<any> {
        try {
            const self: NewProcessController = this;

            // on change released            
            self.$scope.$watch('collapseState.released', function (released: boolean) {
                if (released && self.$scope.collapseState.nextState) self.releaseCollapse(self.$scope.collapseState.nextState);
            });

            return new Promise(function (resolve, reject) {
                self.$scope.$q.all([
                    self.getGenericList('priority')
                ]).then((result: any) => {
                    self.$scope.priorityList = result[0];
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getGenericList(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    async viewProcess(process: IProcess, idEvent?: string) {
        if (!this.$scope.collapseState || this.$scope.collapseState.released) {

            this.$scope.model = process;
            const isBookmarked = await this.getIsBookmarked("BM");
            this.$scope.processIsBookmarked = isBookmarked && isBookmarked.data;
            this.$scope.processConcatenated = this.generateProcessConcatenated();
            this.$scope.processConcatenatedHtml = this.generateProcessConcatenated(true);
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.showForm = true;
            this.$scope.showNewProcessForm = true;
            this.$scope.equipmentConcatenated = this.$scope.model.CARGO_DETAIL ? this.$scope.model.CARGO_DETAIL.EQUIPMENT_CONCATENATED : "";
            this.$scope.eventTransitTime = this.$scope.model.TTIME ? this.$scope.model.TTIME.toString() : "0";
            this.$scope.eventTransitTimeDifference = this.$scope.model.TTIME_DIFFERENCE ? this.$scope.model.TTIME_DIFFERENCE.toString() : "0";
            this.$scope.totalSumCharges = this.$scope.model.TOTAL_CHARGES;

            if (idEvent) {
                const eventIndex = this.$scope.model.EVENT ? this.$scope.model.EVENT.findIndex(event => event.EVENT_ID == idEvent) : -1;
                if (eventIndex >= 0) {
                    this.$scope.$timeout(() => {
                        angular.element("#collapseEvents")["collapse"]('show');
                    }, 500);
                }
            } else {
                this.$scope.$timeout(() => {
                    angular.element("#collapseMain")["collapse"]('show');
                }, 500);
            }

            // Make the row selected in grid.
            this.$gridService.setFixedRow(this.$scope.model._id);

        } else {
            this.lastGridProcess = process;
            this.$scope.collapseState.nextState = this.$scope.operation == EOperation.VIEW ? ECollapseState.VIEW_PROCESS : ECollapseState.EDIT_PROCESS;
            this.releaseCollapse();
        }

        BrowserTitle.$id = this.$scope.model.PROCESS_NUMBER;
    }

    async save(): Promise<boolean> {
        if (!this.$scope.collapseState) this.formService.notifyError("collapseState is null.");
        else if (!this.$scope.collapseState.released) {
            switch (this.$scope.collapseState.panel) {
                case ECollapseState.MAIN:
                    this.$scope.$broadcast("processMainSave");
                    break;
                case ECollapseState.CARGO:
                    this.$scope.$broadcast("processCargoSave");
                    break;
                case ECollapseState.MANAGEMENT:
                    this.$scope.$broadcast("processManagementSave");
                    break;
            }
        }
        return false;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.$scope.operationalService.$route;
            this.$rootScope.setBreadCrumb("GENERAL.MENU.PROCESS", () => this.openPreProcessRegisterModal());
            this.initForm(this, "form", "newProcess", "OPERATIONAL.PROCESS_LIST", false);
            this.block();
            // init grid
            await this.initGrid('gridNewProcess', '/process/list', true, true, 120000, true, true);
            await this.initDependencies();
            this.$scope.showNewProcessForm = false;
            const sessionParameter = this.$gridService.$sessionParameter;
            if (sessionParameter && sessionParameter.data) await this.callSessionFunctions(sessionParameter.data);
            this.unblock();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.$rootScope.clearBreadCrumb();
        super.$onDestroy();
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<a ng-click="grid.appScope.viewProcess(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="row.entity.FORWARDING_SITUATION && row.entity.FORWARDING_SITUATION.SITUATION && row.entity.SITUATION.ID != '5' && (row.entity.FORWARDING_SITUATION.SITUATION.ID != '7' || row.entity.FORWARDING_SITUATION.SITUATION.ID != '5') && grid.appScope.editProcess(row.entity)" ng-class="{'disabled': row.entity.FORWARDING_SITUATION && row.entity.FORWARDING_SITUATION.SITUATION && row.entity.SITUATION.ID == '5' && (row.entity.FORWARDING_SITUATION.SITUATION.ID == '7' || row.entity.FORWARDING_SITUATION.SITUATION.ID == '5')}" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{row.entity.FORWARDING_SITUATION && row.entity.FORWARDING_SITUATION.SITUATION && row.entity.FORWARDING_SITUATION.SITUATION.ID == '7' || row.entity.FORWARDING_SITUATION.SITUATION.ID == '5' ? 'OPERATIONAL.EDIT_CANCEL' : 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true"><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const consistency = `<a ng-click="grid.appScope.consistencyValidateProcess(row.entity.ID, row.entity)" ng-class="{'text-green': !row.entity.SUMMARY_VALIDATION_RESULT || (row.entity.SUMMARY_VALIDATION_RESULT && !row.entity.SUMMARY_VALIDATION_RESULT.HAS_ERROR && !row.entity.SUMMARY_VALIDATION_RESULT.HAS_WARNING), 'text-danger': row.entity.SUMMARY_VALIDATION_RESULT.HAS_ERROR, 'text-warning': row.entity.SUMMARY_VALIDATION_RESULT.HAS_WARNING}" tooltip-placement="auto top" uib-tooltip-html="{{grid.appScope.buildGridConsistencyTooltip(row.entity.SUMMARY_VALIDATION_RESULT)}}" tooltip-append-to-body="true"><i class="fa icon" ng-class="row.entity.SUMMARY_VALIDATION_RESULT && (row.entity.SUMMARY_VALIDATION_RESULT.HAS_ERROR || row.entity.SUMMARY_VALIDATION_RESULT.HAS_WARNING) ? 'fa-exclamation-triangle' : 'fa-check'"></i></a>&nbsp;&nbsp;`;
        const ediAgility = `<span ng-show="(row.entity.SITUATION.ID != \'5\')"><a ng-click="grid.appScope.processEDIAgility(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'OPERATIONAL.EDI' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-download" aria-hidden="true"></i></a></span>&nbsp;&nbsp;`
        const modalIntegration = `<a ng-show="grid.appScope.verifyOfferError(row.entity)" ng-click="grid.appScope.openModalIntegration(row.entity)" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true"><i class="fa fa-refresh icon text-danger"></i></a>&nbsp;&nbsp;`;
        const modalErrorIntegration = `<a ng-click="grid.appScope.openReplicationModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="(row.entity.DOCUMENT_ERROR && row.entity.DOCUMENT_ERROR.length) ? 'text-danger' : 'text-green'" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>`;
        const processDetDemManagement = `<a ng-click="grid.appScope.goToProcessDetDemManagement(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{ 'Ir para Gerenciamento D&D' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-file icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: 130,
            cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${consistency} ${ediAgility} ${modalErrorIntegration} ${modalIntegration} ${processDetDemManagement} </div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false,
            partialCriteria: false,
        };

        gridColumns.addColumn(colActions);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            // process data
            const colProcessNumber: IMonacoColumnDef = { name: "PROCESS_NUMBER", displayName: "OPERATIONAL.FILE_NUMBER", width: 150 };
            const colProcessType: IMonacoColumnDef = { name: "PROCESS_TYPE.NAME", displayName: "BASIC_DATA.FILE_TYPE", width: 150 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.NAME", displayName: "BASIC_DATA.PRODUCT", width: 150 };
            const colSituation: IMonacoColumnDef = { name: "SITUATION.NAME", displayName: "OPERATIONAL.FILE_OPERATIONAL_STATUS", width: 150 };
            const colShipReqOf: IMonacoColumnDef = { name: "SHIPMENT_REQUIRED.DATE_OF", displayName: "OPERATIONAL.REQUESTED_LOADING_DATE_FROM", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colShipReqUP: IMonacoColumnDef = { name: "SHIPMENT_REQUIRED.DATE_UP", displayName: "OPERATIONAL.REQUESTED_LOADING_DATE_TO", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colServiceType: IMonacoColumnDef = { name: "SERVICE_TYPE.NAME", displayName: "GENERAL.SERVICE_TYPE", width: 150 };
            const colForwSituation: IMonacoColumnDef = { name: "FORWARDING_SITUATION.SITUATION.NAME", displayName: "OPERATIONAL.FILE_STATUS", width: 150 };
            const colBookingStatus: IMonacoColumnDef = { name: "BOOKING_STATUS.NAME", displayName: "OPERATIONAL.BOOKING_STATUS", width: 150 };
            const colBookingStatusDate: IMonacoColumnDef = { name: "BOOKING_STATUS_DATE", displayName: "OPERATIONAL.BOOKING_STATUS_DATE_HOUR", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colBooking: IMonacoColumnDef = { name: "BOOKING", displayName: "OPERATIONAL.BOOKING", width: 150 };
            const colBookingInttraReference: IMonacoColumnDef = { name: "BOOKING_INTEGRATION.INTTRA_REFERENCE", displayName: "OPERATIONAL.INTTRA_REFERENCE", width: 150 };
            const colHousePayment: IMonacoColumnDef = { name: "HOUSE_PAYMENT.NAME", displayName: "BASIC_DATA.HOUSE_PAYMENT_MODE", width: 150 };
            const colMasterPayment: IMonacoColumnDef = { name: "MASTER_PAYMENT.NAME", displayName: "BASIC_DATA.MASTER_PAYMENT_MODE", width: 150 };
            const colCargoType: IMonacoColumnDef = { name: "CARGO_TYPE.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 150 };
            const colDangerCargo: IMonacoColumnDef = { name: "CARGO_DETAIL.IS_DANGER_CARGO", displayName: "BASIC_DATA.DANGEROUS_CARGO", width: 150, cellFilter: "YesOrNo" };
            const colIncoterm: IMonacoColumnDef = { name: "INCOTERM.CODE", displayName: "BASIC_DATA.INCOTERM", width: 150 };
            const colMasterDirect: IMonacoColumnDef = { name: "MASTER_DIRECT", displayName: "BASIC_DATA.STRAIGHT_BL", width: 150, cellFilter: "YesOrNo" };
            const colPriority: IMonacoColumnDef = { name: "PRIORITY.NAME", displayName: "REGISTRATION.PRIORITY", width: 150 };
            const colInsurance: IMonacoColumnDef = { name: "CARGO_DETAIL.IS_INSURANCE", displayName: "FINANCIAL.INSURANCE", width: 150, cellFilter: "YesOrNo" };
            // customer
            const colCustomer: IMonacoColumnDef = { name: "CUSTOMER.NAME", displayName: "BASIC_DATA.CLIENT", width: 150 };
            const colNetwCustomer: IMonacoColumnDef = { name: "CUSTOMER.NETWORK.NAME", displayName: "OPERATIONAL.CLIENT_NETWORK", width: 150 };
            const colCustomerQualif: IMonacoColumnDef = { name: "CUSTOMER_QUALIFICATION.NAME", displayName: "GENERAL.CLIENT_QUALIFICATION", width: 100 };
            const colKAM: IMonacoColumnDef = { name: "KAM", displayName: "BASIC_DATA.KAM", width: 100, cellFilter: "YesOrNo" };
            // involveds
            const colImporter: IMonacoColumnDef = { name: "IMPORTER.NAME", displayName: "BASIC_DATA.CONSIGNEE", width: 150 };
            const colExporter: IMonacoColumnDef = { name: "EXPORTER.NAME", displayName: "BASIC_DATA.SHIPPER", width: 150 };
            const colNotify: IMonacoColumnDef = { name: "NOTIFY.NAME", displayName: "BASIC_DATA.NOTIFY", width: 150 };
            const colBroker: IMonacoColumnDef = { name: "BROKER.NAME", displayName: "BASIC_DATA.CUSTOMS_BROKER", width: 150 };
            const colIndication: IMonacoColumnDef = { name: "INDICATION.NAME", displayName: "ENTITY.INDICATION", width: 150 };
            const colExtAgent: IMonacoColumnDef = { name: "EXTERNAL_AGENT.NAME", displayName: "BASIC_DATA.OVERSEAS_AGENT", width: 150 };
            const colExtNetwAgent: IMonacoColumnDef = { name: "EXTERNAL_AGENT.NETWORK.NAME", displayName: "BASIC_DATA.EXTERNAL_AGENT_NETWORK ", width: 150 };
            const colLocalAgent: IMonacoColumnDef = { name: "LOCAL_AGENT.NAME", displayName: "BASIC_DATA.LOCAL_AGENT", width: 150 };
            const colServiceProvider: IMonacoColumnDef = { name: "SERVICE_PROVIDER.NAME", displayName: "BASIC_DATA.PROVIDER", width: 150 };
            const colAgency: IMonacoColumnDef = { name: "AGENCY.NAME", displayName: "ENTITY.AGENCY", width: 150 };
            const colSalesPerson: IMonacoColumnDef = { name: "SALES_PERSON.NAME", displayName: "BASIC_DATA.SALES_EXECUTIVE", width: 150 };
            const colInsideSales: IMonacoColumnDef = { name: "INSIDE_SALES_PERSON.NAME", displayName: "BASIC_DATA.INSIDE_SALES", width: 150 };
            const colCommercialUnity: IMonacoColumnDef = { name: "COMMERCIAL_UNITY.NAME", displayName: "BASIC_DATA.COMMERCIAL_BRANCH", width: 150 };
            const colBranch: IMonacoColumnDef = { name: "BRANCH.NAME", displayName: "BASIC_DATA.BRANCH", width: 150 };
            const colTransPickup: IMonacoColumnDef = { name: "TRANSPORTER_PICKUP.NAME", displayName: "BASIC_DATA.TRANSPORTER_PICKUP", width: 150 };
            const colTransRecept: IMonacoColumnDef = { name: "TRANSPORTER_PLACE_RECEPT.NAME", displayName: "BASIC_DATA.TRANSPORTER_PLACE_RECEPT", width: 150 };
            const colTransDestination: IMonacoColumnDef = { name: "TRANSPORTER_FINAL_DESTINATION.NAME", displayName: "BASIC_DATA.TRANSPORTER_FINAL_DESTINATION", width: 150 };
            const colTransDelivery: IMonacoColumnDef = { name: "TRANSPORTER_DELIVERY.NAME", displayName: "BASIC_DATA.TRANSPORTER_DELIVERY", width: 150 };
            const colTransGateOut: IMonacoColumnDef = { name: "TRANSPORTER_GATE_OUT_EMPTY.NAME", displayName: "BASIC_DATA.TRANSPORTER_GATE_OUT_EMPTY", width: 150 };
            const colTransContainerStuffing: IMonacoColumnDef = { name: "TRANSPORTER_CONTAINER_STUFFING.NAME", displayName: "BASIC_DATA.TRANSPORTER_CONTAINER_STUFFING", width: 150 };
            const colTransFlexiFitting: IMonacoColumnDef = { name: "FLEXI_FITTING.NAME", displayName: "BASIC_DATA.FLEXI_FITTING", width: 150 };
            const colTransIsotakprovider: IMonacoColumnDef = { name: "ISOTANK_PROVIDER.NAME", displayName: "BASIC_DATA.ISOTANK_PROVIDER", width: 150 };
            const colExtBroker: IMonacoColumnDef = { name: "EXTERNAL_BROKER.NAME", displayName: "BASIC_DATA.EXTERNAL_BROKER", width: 150 };
            // locals
            const colPicking: IMonacoColumnDef = { name: "PICKING.DISPLAY_NAME", displayName: "BASIC_DATA.PICK_UP", width: 170, cellTemplate: '<div ng-if=row.entity.PICKING class="ui-grid-cell-contents ng-binding ng-scope">{{row.entity.PICKING.DISPLAY_NAME}}</a></div>' };
            const colEstimatedPickingDate: IMonacoColumnDef = { name: "EVENT_PICKUP.FORECAST_DATE", displayName: "OPERATIONAL.ESTIMATED_PICK_UP", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colActualPickingDate: IMonacoColumnDef = { name: "EVENT_PICKUP.EFFECTIVE_DATE", displayName: "OPERATIONAL.ACTUAL_PICK_UP_DATE", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colReceipt: IMonacoColumnDef = { name: "RECEIPT.DISPLAY_NAME", displayName: "FINANCIAL.PLACE_OF_RECEIPT", width: 170, cellTemplate: '<div ng-if=row.entity.RECEIPT class="ui-grid-cell-contents ng-binding ng-scope">{{row.entity.RECEIPT.DISPLAY_NAME}}</a></div>' };
            const colOrigin: IMonacoColumnDef = { name: "ORIGIN.DISPLAY_NAME", displayName: "BASIC_DATA.MAIN_ORIGIN", width: 170, cellTemplate: '<div ng-if=row.entity.ORIGIN class="ui-grid-cell-contents ng-binding ng-scope">{{row.entity.ORIGIN.DISPLAY_NAME}}</a></div>' };
            const colDestination: IMonacoColumnDef = { name: "DESTINATION.DISPLAY_NAME", displayName: "BASIC_DATA.MAIN_DESTINATION", width: 170, cellTemplate: '<div ng-if=row.entity.DESTINATION class="ui-grid-cell-contents ng-binding ng-scope">{{row.entity.DESTINATION.DISPLAY_NAME}}</a></div>' };
            const colFinalDestin: IMonacoColumnDef = { name: "FINAL_DESTINATION.DISPLAY_NAME", displayName: "ROUTE.FINAL_DESTINATION", width: 150, cellTemplate: '<div ng-if=row.entity.FINAL_DESTINATION class="ui-grid-cell-contents ng-binding ng-scope">{{row.entity.FINAL_DESTINATION.DISPLAY_NAME}}</a></div>' };
            const colDelivery: IMonacoColumnDef = { name: "DELIVERY.DISPLAY_NAME", displayName: "ROUTE.DELIVERY", width: 170, cellTemplate: '<div ng-if=row.entity.DELIVERY class="ui-grid-cell-contents ng-binding ng-scope">{{row.entity.DELIVERY.DISPLAY_NAME ? row.entity.DELIVERY.DISPLAY_NAME : row.entity.DELIVERY.NAME}}</a></div>' };
            const colCreated: IMonacoColumnDef = { name: "CREATED_DATE", displayName: "GENERAL.CREATED_AT", width: 170, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colModified: IMonacoColumnDef = { name: "MODIFIED_DATE", displayName: "GENERAL.UPDATED_AT", width: 170, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colEventLoadEst: IMonacoColumnDef = { name: "EVENT_LOAD.FORECAST_DATE", displayName: "OPERATIONAL.ESTIMATED_LOADING_DATE", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colEventLoadEff: IMonacoColumnDef = { name: "EVENT_LOAD.EFFECTIVE_DATE", displayName: "OPERATIONAL.ACTUAL_LOADING_DATE", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colEventDischEst: IMonacoColumnDef = { name: "EVENT_DISCH.FORECAST_DATE", displayName: "OPERATIONAL.ESTIMATED_DISCHARGE_DATE", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colEventDischEff: IMonacoColumnDef = { name: "EVENT_DISCH.EFFECTIVE_DATE", displayName: "OPERATIONAL.ACTUAL_DISCHARGE_DATE", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colEventDelivEst: IMonacoColumnDef = { name: "EVENT_DELIVERY.FORECAST_DATE", displayName: "OPERATIONAL.ESTIMATED_DELIVERY_DATE", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colEventDelivEff: IMonacoColumnDef = { name: "EVENT_DELIVERY.EFFECTIVE_DATE", displayName: "OPERATIONAL.ACTUAL_DELIVERY_DATE", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            // freight
            const colVessel: IMonacoColumnDef = { name: "EVENT_LOAD.TRANSPORT_MODE.NAME", displayName: "BASIC_DATA.VESSEL", width: 150 };
            const colTtime: IMonacoColumnDef = { name: "TTIME", displayName: "GENERAL.TRANSIT_TIME", width: 150 };
            const colFreightContract: IMonacoColumnDef = { name: "FREIGHT_CONTRACT.NAME", displayName: "BASIC_DATA.FREIGHT_CONTRACT", width: 150 };
            const colContractType: IMonacoColumnDef = { name: "CONTRACT_TYPE.NAME", displayName: "BASIC_DATA.FREIGHT_CONTRACT_TYPE", width: 150 };
            const colValidityUp: IMonacoColumnDef = { name: "VALIDITY_UP", displayName: "PRODUCT.VALIDITY_END", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colValidityOf: IMonacoColumnDef = { name: "VALIDITY_OF", displayName: "PRODUCT.VALIDITY_START", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colConcatenated: IMonacoColumnDef = { name: "OFFER_ACTIVE.CONCATENATED", displayName: "BASIC_DATA.OFFER", width: 150 };
            // others
            const colServices: IMonacoColumnDef = { name: "SERVICES_CONCAT", displayName: "BASIC_DATA.SERVICE", width: 150 };
            const colEquipments: IMonacoColumnDef = { name: "EQUIPMENTS", displayName: "OPERATIONAL.CONTAINERS", width: 150 };
            const colEq40: IMonacoColumnDef = { name: "EQP_40.TOTAL", displayName: "BASIC_DATA.TOTAL_40", width: 150 };
            const colEq20: IMonacoColumnDef = { name: "EQP_20.TOTAL", displayName: "BASIC_DATA.TOTAL_20", width: 150 };
            const colQtdEqp: IMonacoColumnDef = { name: "CARGO_DETAIL.EQUIPMENT_CONCATENATED", displayName: "BASIC_DATA.QTY_EQP", width: 150 };
            const colTeus: IMonacoColumnDef = { name: "TEUS", displayName: "BASIC_DATA.TOTAL_TEUS", width: 150 };
            const colCsProcOwner: IMonacoColumnDef = { name: "CS_PROCESS_OWNER.USER.NAME", displayName: "OPERATIONAL.CUSTOMER_SERVICE", width: 150 };
            const colAgentRef: IMonacoColumnDef = { name: "AGENT_REF", displayName: "OPERATIONAL.AGENT_REFERENCE", width: 150 };
            const colCustomerRef: IMonacoColumnDef = { name: "CUSTOMER_REF", displayName: "OPERATIONAL.CLIENT_REFERENCE", width: 150 };
            const colForecastReadiness: IMonacoColumnDef = { name: "FORECAST_READINESS_REPL", displayName: "OPERATIONAL.FORECAST_READINESS", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colActualtReadiness: IMonacoColumnDef = { name: "ACTUAL_READINESS_REPL", displayName: "OPERATIONAL.ACTUAL_READINESS", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colEstimatedInspection: IMonacoColumnDef = { name: "ESTIMATED_INSPECTION_REPL", displayName: "OPERATIONAL.ESTIMATED_INSPECTION", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colActualInspection: IMonacoColumnDef = { name: "ACTUAL_INSPECTION_REPL", displayName: "OPERATIONAL.ACTUAL_INSPECTION", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colDischargeVesselVoyage: IMonacoColumnDef = { name: "DISCHARGE_VESSEL_VOYAGE_REPL", displayName: "OPERATIONAL.DISCHARGE_VESSEL_VOYAGE", width: 150 };
            const colEstimatedGateInFull: IMonacoColumnDef = { name: "ESTIMATED_GATE_IN_FULL_REPL", displayName: "OPERATIONAL.ESTIMATED_GATE_IN_FULL", width: 180, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colEfectiveGateInFull: IMonacoColumnDef = { name: "EFECTIVE_GATE_IN_FULL_REPL", displayName: "OPERATIONAL.EFECTIVE_GATE_IN_FULL", width: 180, cellFilter: "date:'dd/MM/yyyy HH:mm'" };
            const colintegrationDirection: IMonacoColumnDef = { name: "INTEGRATION_DIRECTION.NAME", displayName: "REGISTRATION.INTEGRATION_DIRECTION", width: 150 };
            const colForwardedBy: IMonacoColumnDef = { name: "FORWARDED_BY.NAME", displayName: "GENERAL.FORWARDED_BY", width: 150 };
            const colProcessSerialNumber: IMonacoColumnDef = { name: "SERIAL_NUMBER", displayName: "OPERATIONAL.SERIAL_NUMBER", width: 150 };
            const colProcessCargoDetailOvertaxed: IMonacoColumnDef = { name: "CARGO_DETAIL.OVERTAXED", displayName: "BASIC_DATA.OVER_CHARGEABLE_WEIGHT", width: 150, cellTemplate: '<div class="ui-grid-cell-contents" >{{row.entity.CARGO_DETAIL.OVERTAXED | number: 4}}</div>' };
            const colProcessCargoDetailGrossWeight: IMonacoColumnDef = { name: "CARGO_DETAIL.GROSS_WEIGHT", displayName: "OPERATIONAL.GROSS_WEIGHT", width: 150, cellTemplate: '<div class="ui-grid-cell-contents" >{{row.entity.CARGO_DETAIL.GROSS_WEIGHT | number: 3}}</div>' };
            const colProcessCargoDetailCBM: IMonacoColumnDef = { name: "CARGO_DETAIL.CBM", displayName: "GENERAL.CUBIC_METER", width: 150, cellTemplate: '<div class="ui-grid-cell-contents" >{{row.entity.CARGO_DETAIL.CBM | number: 3}}</div>' };
            const colProcessCargoDetailVolume: IMonacoColumnDef = { name: "CARGO_DETAIL.VOLUME", displayName: "OPERATIONAL.TOTAL_PIECES", width: 150 };
            const colProcessCargoDetailQuantity: IMonacoColumnDef = { name: "CARGO_DETAIL.QUANTITY", displayName: "GENERAL.CHARGEABLE_WEIGHT", width: 150, cellTemplate: '<div class="ui-grid-cell-contents" >{{row.entity.CARGO_DETAIL.QUANTITY | number: 4}}</div>' };
            const colLevelService: IMonacoColumnDef = { name: "SERVICE_LEVEL", displayName: "BASIC_DATA.SERVICE_LEVEL", width: 150 };
            const colTypeService: IMonacoColumnDef = { name: "SERVICE_TYPE_CONTRACT.NAME", displayName: "BASIC_DATA.SERVICE_TYPE", width: 150 };
            const colGPCurrent: IMonacoColumnDef = { name: "FINANCIAL_SUMMARY.TOTAL", displayName: "FINANCIAL.GP_CURRENT", width: 150, cellTemplate: '<div class="ui-grid-cell-contents" >{{row.entity.GP_CURRENT | number: 2}}</div>' };
            const colThirdAgent: IMonacoColumnDef = { name: "THIRD_AGENT.NAME", displayName: "BASIC_DATA.THIRD_AGENT", width: 150, cellTemplate: '<div ng-if=row.entity.THIRD_AGENT class="ui-grid-cell-contents ng-binding ng-scope">{{row.entity.THIRD_AGENT.NAME}}</a></div>' };
            const colMainDestination: IMonacoColumnDef = { name: "MAIN_DESTINATION.DISPLAY_NAME", displayName: "BASIC_DATA.DESTINATION", width: 150 };
            const colFlexitank: IMonacoColumnDef = { name: "CARGO_DETAIL.FLEXITANK", displayName: "REGISTRATION.FLEXITANK", width: 150, cellFilter: "YesOrNo" };
            const colUnityNumber: IMonacoColumnDef = { name: "UN_NUMBER", displayName: "GENERAL.UN_NUMBER", width: 150 };
            const colMainOrigin: IMonacoColumnDef = { name: "MAIN_ORIGIN.DISPLAY_NAME", displayName: "BASIC_DATA.ORIGIN", width: 150 };
            const colDocumentMaster: IMonacoColumnDef = { name: "DOCUMENT_MASTER", displayName: "OPERATIONAL.MBL", width: 150, cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.DOCUMENT_MASTER)}}</div>' };
            const colDocumentHouse: IMonacoColumnDef = { name: "DOCUMENT_HOUSE", displayName: "OPERATIONAL.HBL", width: 150, cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.DOCUMENT_HOUSE)}}</div>' };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'PROCESS_NUMBER':
                        columnDefs.push(colProcessNumber);
                        break;
                    case 'CARGO_DETAIL.QUANTITY':
                        columnDefs.push(colProcessCargoDetailQuantity);
                        break;
                    case 'CARGO_DETAIL.OVERTAXED':
                        columnDefs.push(colProcessCargoDetailOvertaxed);
                        break;
                    case 'CARGO_DETAIL.GROSS_WEIGHT':
                        columnDefs.push(colProcessCargoDetailGrossWeight);
                        break;
                    case 'CARGO_DETAIL.CBM':
                        columnDefs.push(colProcessCargoDetailCBM);
                        break;
                    case 'CARGO_DETAIL.VOLUME':
                        columnDefs.push(colProcessCargoDetailVolume);
                        break;
                    case 'SERIAL_NUMBER':
                        columnDefs.push(colProcessSerialNumber);
                        break;
                    case 'SERVICE_LEVEL':
                        columnDefs.push(colLevelService);
                        break;
                    case 'SERVICE_TYPE_CONTRACT':
                        columnDefs.push(colTypeService);
                        break;
                    case 'GP_CURRENT':
                        columnDefs.push(colGPCurrent);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'FORWARDING_SITUATION':
                        columnDefs.push(colForwSituation);
                        break;
                    case 'BOOKING_STATUS':
                        columnDefs.push(colBookingStatus);
                        break;
                    case 'BOOKING_STATUS_DATE':
                        columnDefs.push(colBookingStatusDate);
                        break;
                    case 'BOOKING':
                        columnDefs.push(colBooking);
                        break;
                    case 'BOOKING_INTEGRATION':
                        columnDefs.push(colBookingInttraReference);
                        break;
                    case 'HOUSE_PAYMENT':
                        columnDefs.push(colHousePayment);
                        break;
                    case 'MASTER_PAYMENT':
                        columnDefs.push(colMasterPayment);
                        break;
                    case 'CARGO_DETAIL':
                        columnDefs.push(colDangerCargo);
                        columnDefs.push(colInsurance);
                        columnDefs.push(colFlexitank);
                        break;
                    case 'MASTER_DIRECT':
                        columnDefs.push(colMasterDirect);
                        break;
                    case 'PRIORITY':
                        columnDefs.push(colPriority);
                        break;
                    case 'INCOTERM':
                        columnDefs.push(colIncoterm);
                        break;
                    case 'CARGO_TYPE':
                        columnDefs.push(colCargoType);
                        break;
                    case 'KAM':
                        columnDefs.push(colKAM);
                        break;
                    case 'SITUATION':
                        columnDefs.push(colSituation);
                        break;
                    case 'SHIPMENT_REQUIRED':
                        columnDefs.push(colShipReqOf);
                        columnDefs.push(colShipReqUP);
                        break;
                    case 'SERVICE_TYPE':
                        columnDefs.push(colServiceType);
                        break;
                    case 'COMMERCIAL_UNITY':
                        columnDefs.push(colCommercialUnity);
                        break;
                    case 'BRANCH':
                        columnDefs.push(colBranch);
                        break;
                    case 'PROCESS_TYPE':
                        columnDefs.push(colProcessType);
                        break;
                    case 'CUSTOMER':
                        columnDefs.push(colCustomer);
                        columnDefs.push(colNetwCustomer);
                        break;
                    case 'CUSTOMER_QUALIFICATION':
                        columnDefs.push(colCustomerQualif);
                        break;
                    case 'EXPORTER':
                        columnDefs.push(colExporter);
                        break;
                    case 'IMPORTER':
                        columnDefs.push(colImporter);
                        break;
                    case 'NOTIFY':
                        columnDefs.push(colNotify);
                        break;
                    case 'SERVICE_PROVIDER':
                        columnDefs.push(colServiceProvider);
                        break;
                    case 'BROKER':
                        columnDefs.push(colBroker);
                        break;
                    case 'INDICATION':
                        columnDefs.push(colIndication);
                        break;
                    case 'EXTERNAL_AGENT':
                        columnDefs.push(colExtAgent);
                        columnDefs.push(colExtNetwAgent);
                        break;
                    case 'LOCAL_AGENT':
                        columnDefs.push(colLocalAgent);
                        break;
                    case 'AGENCY':
                        columnDefs.push(colAgency);
                        break;
                    case 'SALES_PERSON':
                        columnDefs.push(colSalesPerson);
                        break;
                    case 'INSIDE_SALES_PERSON':
                        columnDefs.push(colInsideSales);
                        break;
                    case 'TRANSPORTER_PICKUP':
                        columnDefs.push(colTransPickup);
                        break;
                    case 'TRANSPORTER_PLACE_RECEPT':
                        columnDefs.push(colTransRecept);
                        break;
                    case 'TRANSPORTER_FINAL_DESTINATION':
                        columnDefs.push(colTransDestination);
                        break;
                    case 'TRANSPORTER_DELIVERY':
                        columnDefs.push(colTransDelivery);
                        break;
                    case 'TRANSPORTER_GATE_OUT_EMPTY':
                        columnDefs.push(colTransGateOut);
                        break;
                    case 'TRANSPORTER_CONTAINER_STUFFING':
                        columnDefs.push(colTransContainerStuffing);
                        break;
                    case 'FLEXI_FITTING':
                        columnDefs.push(colTransFlexiFitting);
                        break;
                    case 'ISOTANK_PROVIDER':
                        columnDefs.push(colTransIsotakprovider);
                        break;
                    case 'EXTERNAL_BROKER':
                        columnDefs.push(colExtBroker);
                        break;
                    case 'PICKING':
                        columnDefs.push(colPicking);
                        columnDefs.push(colEstimatedPickingDate);
                        columnDefs.push(colActualPickingDate);
                        break;
                    case 'RECEIPT':
                        columnDefs.push(colReceipt);
                        break;
                    case 'ORIGIN':
                        columnDefs.push(colOrigin);
                        break;
                    case 'DESTINATION':
                        columnDefs.push(colDestination);
                        break;
                    case 'FINAL_DESTINATION':
                        columnDefs.push(colFinalDestin);
                        break;
                    case 'DELIVERY':
                        columnDefs.push(colDelivery);
                        break;
                    case 'CREATED_DATE':
                        columnDefs.push(colCreated);
                        break;
                    case 'MODIFIED_DATE':
                        columnDefs.push(colModified);
                        break;
                    case 'TTIME':
                        columnDefs.push(colTtime);
                        break;
                    case 'EVENT_LOAD':
                        columnDefs.push(colEventLoadEst);
                        columnDefs.push(colEventLoadEff);
                        columnDefs.push(colVessel);
                        break;
                    case 'EVENT_DISCH':
                        columnDefs.push(colEventDischEst);
                        columnDefs.push(colEventDischEff);
                        break;
                    case 'EVENT_DELIVERY':
                        columnDefs.push(colEventDelivEst);
                        columnDefs.push(colEventDelivEff);
                        break;
                    case 'FREIGHT_CONTRACT':
                        columnDefs.push(colFreightContract);
                        break;
                    case 'CONTRACT_TYPE':
                        columnDefs.push(colContractType);
                        break;
                    case 'VALIDITY_UP':
                        columnDefs.push(colValidityUp);
                        break;
                    case 'VALIDITY_OF':
                        columnDefs.push(colValidityOf);
                        break;
                    case 'OFFER_ACTIVE':
                        columnDefs.push(colConcatenated);
                        break;
                    case 'SERVICES_CONCAT':
                        columnDefs.push(colServices);
                        break;
                    case 'EQUIPMENTS':
                        columnDefs.push(colEquipments);
                        break;
                    case 'EQP_40':
                        columnDefs.push(colEq40);
                        break;
                    case 'EQP_20':
                        columnDefs.push(colEq20);
                        break;
                    case 'QTYXEQP':
                        columnDefs.push(colQtdEqp);
                        break;
                    case 'TEUS':
                        columnDefs.push(colTeus);
                        break;
                    case 'CS_PROCESS_OWNER':
                        columnDefs.push(colCsProcOwner);
                        break;
                    case 'AGENT_REF':
                        columnDefs.push(colAgentRef);
                        break;
                    case 'CUSTOMER_REF':
                        columnDefs.push(colCustomerRef);
                        break;
                    case 'FORECAST_READINESS_REPL':
                        columnDefs.push(colForecastReadiness);
                        break;
                    case 'ACTUAL_READINESS_REPL':
                        columnDefs.push(colActualtReadiness);
                        break;
                    case 'ESTIMATED_INSPECTION_REPL':
                        columnDefs.push(colEstimatedInspection);
                        break;
                    case 'ACTUAL_INSPECTION_REPL':
                        columnDefs.push(colActualInspection);
                        break;
                    case 'DISCHARGE_VESSEL_VOYAGE_REPL':
                        columnDefs.push(colDischargeVesselVoyage);
                        break;
                    case 'ESTIMATED_GATE_IN_FULL_REPL':
                        columnDefs.push(colEstimatedGateInFull);
                        break;
                    case 'EFECTIVE_GATE_IN_FULL_REPL':
                        columnDefs.push(colEfectiveGateInFull);
                        break;
                    case 'INTEGRATION_DIRECTION':
                        columnDefs.push(colintegrationDirection);
                        break;
                    case 'FORWARDED_BY':
                        columnDefs.push(colForwardedBy);
                        break;
                    case 'THIRD_AGENT':
                        columnDefs.push(colThirdAgent);
                        break;
                    case 'MAIN_DESTINATION':
                        columnDefs.push(colMainDestination);
                        break;
                    case 'UN_NUMBER':
                        columnDefs.push(colUnityNumber);
                        break;
                    case 'MAIN_ORIGIN':
                        columnDefs.push(colMainOrigin);
                        break;
                    case 'DOCUMENT_MASTER':
                        columnDefs.push(colDocumentMaster);
                        break;
                    case 'DOCUMENT_HOUSE':
                        columnDefs.push(colDocumentHouse);
                        break;
                };
            }

            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            OFFER_ACTIVE: null,
            EVENT_DISCH: null,
            EVENT_LOAD: null,
            EVENT_PICKUP: null,
            EVENT_DELIVERY: null,
            PROCESS_NUMBER: null,
            PROCESS_TYPE: null,
            SITUATION: null,
            COMMERCIAL_UNITY: null,
            BRANCH: null,
            PRODUCT: null,
            OPERATION: null,
            MODAL: null,
            SERVICE_TYPE: null,
            SHIPMENT_REQUIRED: null,
            CUSTOMER: null,
            CUSTOMER_QUALIFICATION: null,
            KAM: null,
            EXPORTER: null,
            IMPORTER: null,
            NOTIFY: null,
            BROKER: null,
            INDICATION: null,
            AGENCY: null,
            LOCAL_AGENT: null,
            EXTERNAL_AGENT: null,
            THIRD_AGENT: null,
            TRANSPORTER_PICKUP: null,
            TRANSPORTER_PLACE_RECEPT: null,
            TRANSPORTER_FINAL_DESTINATION: null,
            TRANSPORTER_DELIVERY: null,
            TRANSPORTER_GATE_OUT_EMPTY: null,
            TRANSPORTER_CONTAINER_STUFFING: null,
            FLEXI_FITTING: null,
            ISOTANK_PROVIDER: null,
            EXTERNAL_BROKER: null,
            REPRESENTATIVES: null,
            SERVICE_PROVIDER: null,
            REFERENCE: null,
            INCOTERM: null,
            ACCOUNT_TYPE: null,
            ACCOUNT_REQUIREMENT: null,
            RESPONSIBLE_PRODUCT: null,
            SPONSOR: null,
            CONTACT: null,
            MASTER_DIRECT: null,
            INSTRUCTION: null,
            FREIGHT_CONTRACT_RECEIVING: null,
            CONTRACT_TYPE_RECEIVING: null,
            FREIGHT_CONTRACT: null,
            CONTRACT_TYPE: null,
            BOOKING: null,
            BOOKING_STATUS: null,
            BOOKING_STATUS_DATE: null,
            BOOKING_OBS: null,
            BOOKING_INTEGRATION: null,
            IS_BOOKING_INTEGRATION: null,
            MASTER_PAYMENT: null,
            HOUSE_PAYMENT: null,
            CARGO_READINESS: null,
            CARGO_TYPE: null,
            CARGO_DETAIL: null,
            CARGO_LIST: null,
            COMMODITY_SUMMARY: null,
            NCM: null,
            FORWARDED_BY: null,
            SALES_PERSON: null,
            INSIDE_SALES_PERSON: null,
            SECTION: null,
            EVENT: null,
            PICKING: null,
            RECEIPT: null,
            ORIGIN: null,
            DESTINATION: null,
            FINAL_DESTINATION: null,
            DELIVERY: null,
            DEADLINES: null,
            SERVICES: null,
            SERVICES_OFFER: null,
            TTIME: null,
            TTIME_DIFFERENCE: null,
            QUOTATION_TTIME_MIN: null,
            QUOTATION_TTIME_MAX: null,
            DTA: null,
            DTA_INFORMATION: null,
            LI: null,
            LI_DATE: null,
            CREDIT_LETTER: null,
            CREDIT_LETTER_DATE: null,
            OFFER: null,
            REPURCHASE: null,
            DRAFT: null,
            CREATED_DATE: null,
            CREATED_BY: null,
            MODIFIED_DATE: null,
            MODIFIED_BY: null,
            AUDIT: null,
            AGENT_SETTLEMENT_SITUATION: null,
            FORWARDING_SITUATION: null,
            PAYMENT_SITUATION: null,
            RECEIPT_SITUATION: null,
            CHARGE: null,
            PRIORITY: null,
            VALIDITY_OF: null,
            VALIDITY_UP: null,
            SERVICE_CATEGORY: null,
            HC2_INTEGRATION: null,
            PROFIT_SHARE: null,
            COMPARED_BY: null,
            COMPARED_DATE: null,
            FINANCIAL_SUMMARY: null,
            ERP_RESULT_CENTER: null,
            INTEGRATION_DIRECTION: null,
            DOCUMENT_ERROR: null,
            CONCATENATED_COMPLEMENT: null,
            FORECAST_READINESS_REPL: null,
            ACTUAL_READINESS_REPL: null,
            ESTIMATED_INSPECTION_REPL: null,
            ACTUAL_INSPECTION_REPL: null,
            DISCHARGE_VESSEL_VOYAGE_REPL: null,
            TOTAL_CHARGES: null,
            ESTIMATED_GATE_IN_FULL_REPL: null,
            EFECTIVE_GATE_IN_FULL_REPL: null,
            VALIDATION_RESULT: null,
            SUMMARY_VALIDATION_RESULT: null,
            ID_PROCESS_WIZARD_FILTER: null,
            ID_PROCESS_WIZARD_OPTION: null,
            ID_PROCESS_WIZARD_OPTION_STAGES: null,
            READY_TO_DISPLAY: null,
            ID: null,
            LAST_REPLICATION_DATE: null,
            EXTENDED_VALIDITY_DATE: null,
            COMMODITY_CURRENCY: null,
            COMMODITY_VALUE: null,
            RESULTS_ANALYSIS: null,
            CARGO_READINESS_HISTORY: null,
            LOAD_REF: null,
            GET_VOYAGE_BY_INTEGRATION: null,
            ALLOWS_REPURCHASE: false,
            REPURCHASE_BY: null,
            REPURCHASE_DATE: null,
            COMMODITY_INVOICE_VALUE: null,
            ID_CONSOLIDATED: null,
            MAIN_ORIGIN: null,
            MAIN_DESTINATION: null,
            CORPORATE_ACCOUNT: null,
            DOCUMENT_MASTER: null,
            DOCUMENT_HOUSE: null,
        }
    }

    private async openSummaryModal() {
        try {

            this.formService.block();

            const processInfo: Process = this.$scope.model;
            if (!processInfo) return;

            this.formService.unblock();

            this.summaryModalId = this.$scope.modalService.newModal();
            this.$scope.modalService.showModalInfo(
                {
                    modalID: this.summaryModalId,
                    formService: EOperation.VIEW,
                    template: require("../view/processSummary.html"),
                    scope: this.$scope,
                    size: 'vlg'
                },
                {
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: `${this.formService.getTranslate('OPERATIONAL.FILE_SUMMARY')} ${processInfo.PROCESS_NUMBER}`
                }
            );

            // EMBARQUE
            const loadEvent = (processInfo.EVENT) ? processInfo.EVENT.find(x => x.EVENT_TYPE.ID === '4') : null;
            // DESEMBARQUE
            const dischargeEvent = (processInfo.EVENT) ? processInfo.EVENT.find(x => x.EVENT_TYPE.ID === '7') : null;
            // LOAD/DISCHARGE TRANSHIPMENT
            const loadDischargeTranshipment = (processInfo.EVENT) ? processInfo.EVENT.find(x => x.EVENT_TYPE.ID === '6') : null;

            const deliveryEvent = (processInfo.EVENT) ? processInfo.EVENT.find(x => x.EVENT_TYPE.ID === '10') : null;
            const pickingEvent = (processInfo.EVENT) ? processInfo.EVENT.find(x => x.EVENT_TYPE.ID === '2') : null;

            const vesselVoy = (loadEvent && loadEvent.TRANSPORT_MODE) ? loadEvent.TRANSPORT_MODE.NAME : ((dischargeEvent && dischargeEvent.TRANSPORT_MODE) ? dischargeEvent.TRANSPORT_MODE.NAME : null)

            let qtdCntrs = 0;
            if (processInfo.CARGO_DETAIL && processInfo.CARGO_DETAIL.EQUIPMENT_LIST) {
                processInfo.CARGO_DETAIL.EQUIPMENT_LIST.forEach((equipment) => {
                    qtdCntrs += equipment.QUANTITY ? equipment.QUANTITY : 0;
                });
            }

            //init modal scope
            const modalScope = await this.$scope.modalService.getModalScope(this.summaryModalId);
            modalScope.QTD_CNTRS = qtdCntrs;
            modalScope.COMMODITY_CONCATENATED = processInfo.COMMODITY_SUMMARY && processInfo.COMMODITY_SUMMARY.COMMODITY_ITEM ? this.$scope.getCONCAT(processInfo.COMMODITY_SUMMARY.COMMODITY_ITEM, "COMMODITY") : null;
            modalScope.COMMODITY_SECTION_CONCATENATED = processInfo.COMMODITY_SUMMARY && processInfo.COMMODITY_SUMMARY.COMMODITY_ITEM ? this.$scope.getCONCAT(processInfo.COMMODITY_SUMMARY.COMMODITY_ITEM, "COMMODITY_SECTION") : null;
            modalScope.PORT_OF_LOADING = (loadEvent && loadEvent.LOCAL) ? (loadEvent.LOCAL.DISPLAY_NAME) ? (loadEvent.LOCAL.DISPLAY_NAME) : (loadEvent.LOCAL.CODE) ? `${loadEvent.LOCAL.NAME} (${loadEvent.LOCAL.CODE})` : loadEvent.LOCAL.NAME : null;
            modalScope.PLACE_OF_TRANSHIPMENT = (loadDischargeTranshipment && loadDischargeTranshipment.LOCAL) ? (loadDischargeTranshipment.LOCAL.DISPLAY_NAME) ? (loadDischargeTranshipment.LOCAL.DISPLAY_NAME) : (loadDischargeTranshipment.LOCAL.CODE) ? `${loadDischargeTranshipment.LOCAL.NAME} (${loadDischargeTranshipment.LOCAL.CODE})` : loadDischargeTranshipment.LOCAL.NAME : null;
            modalScope.PORT_OF_DISCHARGE = (dischargeEvent && dischargeEvent.LOCAL) ? (dischargeEvent.LOCAL.DISPLAY_NAME) ? `${dischargeEvent.LOCAL.DISPLAY_NAME}` : (dischargeEvent.LOCAL.CODE) ? `${dischargeEvent.LOCAL.NAME} (${dischargeEvent.LOCAL.CODE})` : dischargeEvent.LOCAL.NAME : null;
            modalScope.FINAL_DESTINATION = processInfo.FINAL_DESTINATION ? (processInfo.FINAL_DESTINATION.DISPLAY_NAME) ? `${processInfo.FINAL_DESTINATION.DISPLAY_NAME}` : (processInfo.FINAL_DESTINATION.CODE) ? `${processInfo.FINAL_DESTINATION.NAME} (${processInfo.FINAL_DESTINATION.CODE})` : processInfo.FINAL_DESTINATION.NAME : null;
            modalScope.PICKING = (pickingEvent && pickingEvent.LOCAL) ? (pickingEvent.LOCAL.DISPLAY_NAME) ? `${pickingEvent.LOCAL.DISPLAY_NAME}` : (pickingEvent.LOCAL.CODE) ? `${pickingEvent.LOCAL.NAME} (${pickingEvent.LOCAL.CODE})` : pickingEvent.LOCAL.NAME : null;
            modalScope.DELIVERY = (deliveryEvent && deliveryEvent.LOCAL) ? (deliveryEvent.LOCAL.DISPLAY_NAME) ? `${deliveryEvent.LOCAL.DISPLAY_NAME}` : (deliveryEvent.LOCAL.CODE) ? `${deliveryEvent.LOCAL.NAME} (${deliveryEvent.LOCAL.CODE})` : deliveryEvent.LOCAL.NAME : null;
            modalScope.VESSEL = (vesselVoy) ? vesselVoy.replace('|', '/') : null;
            modalScope.ETD = (loadEvent && loadEvent.FORECAST_DATE) ? loadEvent.FORECAST_DATE : null;
            modalScope.CONTAINER_TYPES = (processInfo.CARGO_DETAIL && processInfo.CARGO_DETAIL.EQUIPMENT_LIST) ? processInfo.CARGO_DETAIL.EQUIPMENT_LIST.map(x => `${x.QUANTITY} x ${x.EQUIPMENT.NAME}`).join(', ') : null;

            let OFTM: string = null;
            const validOFTM = (processInfo.CARGO_DETAIL && processInfo.CARGO_DETAIL.EQUIPMENT_LIST) ? processInfo.CARGO_DETAIL.EQUIPMENT_LIST.filter(x => (x.OFTM)) : null;
            if (validOFTM && validOFTM.length > 0) OFTM = validOFTM.map(x => {
                let result = "";
                const oftm = x.OFTM;
                if (oftm.DET || oftm.DEM) {
                    if (oftm.DET) result = oftm.DET.toString();
                    if (oftm.DEM) result += " + " + oftm.DEM.toString();
                } else if (oftm.COMBINED) result += oftm.COMBINED.toString();
                return result;
            }).join(',');

            let DFTM: string = null;
            const validDFTM = (processInfo.CARGO_DETAIL && processInfo.CARGO_DETAIL.EQUIPMENT_LIST) ? processInfo.CARGO_DETAIL.EQUIPMENT_LIST.filter(x => (x.DFTM)) : null;
            if (validDFTM && validDFTM.length > 0) DFTM = validDFTM.map(x => {
                let result = "";
                const dftm = x.DFTM;
                if (dftm.DET || dftm.DEM) {
                    if (dftm.DET) result = dftm.DET.toString();
                    if (dftm.DEM) result += " + " + dftm.DEM.toString();
                } else if (dftm.COMBINED) result += dftm.COMBINED.toString();
                return result;
            }).join(',');

            modalScope.REMARKS = `${processInfo.INSTRUCTION && processInfo.INSTRUCTION.OPERATIONAL ? processInfo.INSTRUCTION.OPERATIONAL + '\n' : ''}${processInfo.INSTRUCTION && processInfo.INSTRUCTION.CUSTOMER ? processInfo.INSTRUCTION.CUSTOMER + '\n\n' : ''}Free Time Origem Master: ${(OFTM) ? OFTM : ''}\nFree Time Destino Master: ${(DFTM) ? DFTM : ''}`;

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private repositionPanels(currentPanelId: string, isOpenning?: boolean) {
        if (currentPanelId) {
            const panels: IRepositionPanel[] = [];
            panels.push({ panelId: ECollapseState.MAIN, panelElement: angular.element(`#${ECollapseState.MAIN}`), collapseId: "collapseMain", collapseElement: angular.element("#collapseMain") });
            panels.push({ panelId: ECollapseState.PROCESS_DOCUMENT, panelElement: angular.element(`#${ECollapseState.PROCESS_DOCUMENT}`), collapseId: "collapseDocument", collapseElement: angular.element("#collapseDocument") });
            panels.push({ panelId: ECollapseState.CARGO, panelElement: angular.element(`#${ECollapseState.CARGO}`), collapseId: "collapseCargo", collapseElement: angular.element("#collapseCargo") });
            panels.push({ panelId: ECollapseState.EVENTS, panelElement: angular.element(`#${ECollapseState.EVENTS}`), collapseId: "collapseEvents", collapseElement: angular.element("#collapseEvents") });
            panels.push({ panelId: ECollapseState.CHARGES, panelElement: angular.element(`#${ECollapseState.CHARGES}`), collapseId: "collapseCharges", collapseElement: angular.element("#collapseCharges") });
            panels.push({ panelId: ECollapseState.CHARGE_OUT_DATE, panelElement: angular.element(`#${ECollapseState.CHARGE_OUT_DATE}`), collapseId: "collapseChargeOutDate", collapseElement: angular.element("#collapseChargeOutDate") });
            panels.push({ panelId: ECollapseState.COMMUNICATION, panelElement: angular.element(`#${ECollapseState.COMMUNICATION}`), collapseId: "collapseCommunication", collapseElement: angular.element("#collapseCommunication") });
            panels.push({ panelId: ECollapseState.MANAGEMENT, panelElement: angular.element(`#${ECollapseState.MANAGEMENT}`), collapseId: "collapseManagement", collapseElement: angular.element("#collapseManagement") });
            panels.push({ panelId: ECollapseState.TASKS, panelElement: angular.element(`#${ECollapseState.TASKS}`), collapseId: "collapseTasks", collapseElement: angular.element("#collapseTasks") });

            if (!this.$rootScope.app || !this.$rootScope.app.settings || this.$rootScope.app.settings.panelsReorder) {
                if (isOpenning) {
                    // set current panel to be the first
                    const currentPanelIndex = panels.findIndex(panel => panel.panelId == currentPanelId);
                    if (currentPanelIndex >= 0) {
                        panels[currentPanelIndex].panelElement.css('order', '1');
                        // reposition other panels
                        panels.splice(currentPanelIndex, 1);
                        let order = 2;
                        for (const panel of panels) {
                            $("#" + panel.collapseId)["collapse"]('hide');
                            panel.panelElement.css('order', order);
                            order++;
                        }
                        // navigate to first panel
                        this.navigateBetweenIds(currentPanelId);
                    }
                } else {
                    const panelsToReposition = panels.filter(panel => !panel.collapseElement.hasClass("in"));
                    let order = panels.length - panelsToReposition.length + 1;
                    for (const panel of panelsToReposition) {
                        panel.panelElement.css('order', order);
                        order++;
                    }
                }
            }
        }
    }

    private hasInvalidRequiredElements(elementId: string): boolean {
        if (!elementId) return false;
        const isInvalid = FormService2.hasRequiredElements('#' + elementId);
        if (isInvalid) this.formService.notifyError(this.formService.getTranslate("GENERAL.ALL_FIELDS_MANDATORY"));
        return isInvalid;
    }

    private hasInvalidElements(elementId: string): boolean {
        const selector: string = (elementId == '') ? 'input, textarea, select, .ui-select-container' : `${elementId} input, ${elementId} textarea, ${elementId} select, ${elementId} .ui-select-container`;
        const elements = angular.element(selector);

        let isInvalid = false;

        if (elements && elements.length) {
            for (let i = 0; i < elements.length; i++) {
                const element = angular.element(elements[i]);
                isInvalid = element.hasClass('ng-invalid') || element.hasClass('ng-invalid-datetime');
                if (isInvalid) break;
            }
        }

        if (isInvalid) this.formService.notifyError('Verifique os campos inválidos e tente novamente.');
        return isInvalid;
    }

    private async getIsBookmarked(type) {
        const result = await this.$rootScope.getSetting('PROCESS_BOOKMARKED', this.$scope.model.PROCESS_NUMBER);
        return { id: type, data: result };
    }

    private async modalSaveConfirmation(headerText: string, closeButtonText: string): Promise<boolean> {
        return await this.$scope.modalService.showModalConfirmation({}, {
            headerText: headerText,
            bodyText: this.formService.getTranslate('REGISTRATION.MESSAGES.ERROR.UPDATE_NOT_SAVED'),
            actionButtonText: 'REGISTRATION.SAVE_CONTINUE',
            closeButtonText: 'GENERAL.CLOSE',
        });
    }

    private releaseCollapse(panel?: string) {
        if (!this.$scope.collapseState) this.formService.notifyError("collapseState is null.");
        const panelToCollapse = panel ? panel : this.$scope.collapseState.panel;
        switch (panelToCollapse) {
            case ECollapseState.VIEW_PROCESS:
                this.$scope.collapseState.nextState = null;
                this.$scope.viewProcess(this.lastGridProcess)
                break;
            case ECollapseState.EDIT_PROCESS:
                this.$scope.collapseState.nextState = null;
                this.$scope.editProcess(this.lastGridProcess);
                break;
            case ECollapseState.CANCEL_PROCESS:
                this.$scope.collapseState.nextState = null;
                this.cancel();
                break;
            case ECollapseState.MAIN:
                this.$scope.$broadcast("processMainCollapse");
                break;
            case ECollapseState.CARGO:
                this.$scope.$broadcast("processCargoCollapse");
                break;
            case ECollapseState.EVENTS:
                this.$scope.$broadcast("processEventCollapse");
                break;
            case ECollapseState.CHARGES:
                this.$scope.$broadcast("processChargeCollapse");
                break;
            case ECollapseState.CHARGE_OUT_DATE:
                this.$scope.$broadcast("processChargeOutDateCollapse");
                break;
            case ECollapseState.COMMUNICATION:
                this.$scope.$broadcast("processCommunicationCollapse");
                break;
            case ECollapseState.MANAGEMENT:
                this.$scope.$broadcast("processManagementCollapse");
                break;
            case ECollapseState.TASKS:
                this.$scope.$broadcast("processTaskCollapse");
                break;
            case ECollapseState.PROCESS_DOCUMENT:
                this.$scope.$broadcast("processDocumentCollapse");
                break;
        }
    }

    private async updateProcessPriority() {
        try {
            this.formService.block();
            let priority: ISelectorModel = null;
            if (this.$scope.model.PRIORITY) {
                if (this.$scope.model.PRIORITY.ID == EPriorityId.NORMAL) {
                    const highPriority = this.$scope.priorityList && this.$scope.priorityList.length ? this.$scope.priorityList.find(priority => priority.ID == EPriorityId.HIGH) : null;
                    priority = highPriority;
                } else if (this.$scope.model.PRIORITY.ID == EPriorityId.HIGH) {
                    const normalPriority = this.$scope.priorityList && this.$scope.priorityList.length ? this.$scope.priorityList.find(priority => priority.ID == EPriorityId.NORMAL) : null;
                    priority = normalPriority;
                }
                const updateResponse = await this.$scope.operationalService.post("/process/updatePriority", { data: { PRIORITY: priority }, processNumber: this.$scope.model.PROCESS_NUMBER }, 30000);
                if (updateResponse && updateResponse.status == 200) {
                    this.$scope.model.PRIORITY = priority;
                    const msg = this.formService.getTranslate('OPERATIONAL.UPDATE_PRIORITY');
                    this.formService.notifySuccess(msg);
                }
                this.$scope.menuFloating.optionsLeft = [this.buildProcessPriorityOptionMenu()];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    async cancel(): Promise<void> {
        try {
            if (!this.$scope.collapseState) this.formService.notifyError("collapseState is null.");
            else if (this.$scope.collapseState.released) {
                BrowserTitle.$id = null;
                this.$scope.formOperation = "";
                this.$scope.showNewProcessForm = false;
                if (this.gridService && this.gridService.$gridApi && this.gridService.$gridApi.selection) this.gridService.$gridApi.selection.clearSelectedRows();
            } else {
                this.$scope.collapseState.nextState = ECollapseState.CANCEL_PROCESS;
                this.releaseCollapse();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private generateProcessConcatenated(isHtml?: boolean): string {
        let concatenated = "";
        const separator = " | ";

        try {

            if (this.$scope.model.PROCESS_NUMBER && this.$scope.model.PROCESS_NUMBER.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PROCESS_NUMBER) : concatenated.concat(this.$scope.model.PROCESS_NUMBER);
            }

            if (this.$scope.model.BOOKING && this.$scope.model.BOOKING.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.BOOKING) : concatenated.concat(this.$scope.model.BOOKING);
            }

            if (this.$scope.model.CUSTOMER && this.$scope.model.CUSTOMER.NAME && this.$scope.model.CUSTOMER.NAME.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CUSTOMER.NAME) : concatenated.concat(this.$scope.model.CUSTOMER.NAME);
            }

            if (this.$scope.model.ORIGIN && this.$scope.model.ORIGIN.CODE && this.$scope.model.ORIGIN.CODE.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.ORIGIN.CODE) : concatenated.concat(this.$scope.model.ORIGIN.CODE);
            }

            if (this.$scope.model.DESTINATION && this.$scope.model.DESTINATION.CODE && this.$scope.model.DESTINATION.CODE.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.DESTINATION.CODE) : concatenated.concat(this.$scope.model.DESTINATION.CODE);
            }

            if (this.$scope.model.SERVICE_PROVIDER && this.$scope.model.SERVICE_PROVIDER.SCAC && this.$scope.model.SERVICE_PROVIDER.SCAC.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.SERVICE_PROVIDER.SCAC) : concatenated.concat(this.$scope.model.SERVICE_PROVIDER.SCAC);
            }

            if (this.$scope.model.CARGO_DETAIL && this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST && this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST.length) {
                if ((this.$scope.model.CARGO_DETAIL.UNITY && this.$scope.model.CARGO_DETAIL.UNITY.CODE && this.$scope.model.CARGO_DETAIL.UNITY.CODE !== "W/M") || !this.$scope.model.CARGO_DETAIL.UNITY) {
                    const equipments = this.$scope.getCONCAT(this.$scope.model.CARGO_DETAIL.EQUIPMENT_LIST, "EQUIPMENT", null, null, null, ',');
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, equipments) : concatenated.concat(equipments);
                }
            }

            if (this.$scope.model.EVENT_LOAD) {
                if (this.$scope.model.EVENT_LOAD.EFFECTIVE_DATE) concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.$filter("simpleDate")(this.$scope.model.EVENT_LOAD.EFFECTIVE_DATE)) : concatenated.concat(this.$scope.$filter("simpleDate")(this.$scope.model.EVENT_LOAD.EFFECTIVE_DATE));
                else if (this.$scope.model.EVENT_LOAD.FORECAST_DATE) {
                    if (isHtml) concatenated = concatenated.length > 0 ? concatenated.concat(separator, `<i class="text-blue">${this.$scope.$filter("simpleDate")(this.$scope.model.EVENT_LOAD.FORECAST_DATE)}</i>`) : concatenated.concat(`<i class="text-blue">${this.$scope.$filter("simpleDate")(this.$scope.model.EVENT_LOAD.FORECAST_DATE)}</i>`);
                    else concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.$filter("simpleDate")(this.$scope.model.EVENT_LOAD.FORECAST_DATE)) : concatenated.concat(this.$scope.$filter("simpleDate")(this.$scope.model.EVENT_LOAD.FORECAST_DATE));
                }
            }

            if (this.$scope.model.EVENT_DISCH) {
                if (this.$scope.model.EVENT_DISCH.EFFECTIVE_DATE) concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.$filter("simpleDate")(this.$scope.model.EVENT_DISCH.EFFECTIVE_DATE)) : concatenated.concat(this.$scope.$filter("simpleDate")(this.$scope.model.EVENT_DISCH.EFFECTIVE_DATE));
                else if (this.$scope.model.EVENT_DISCH.FORECAST_DATE) {
                    if (isHtml) concatenated = concatenated.length > 0 ? concatenated.concat(separator, `<i class="text-blue">${this.$scope.$filter("simpleDate")(this.$scope.model.EVENT_DISCH.FORECAST_DATE)}</i>`) : concatenated.concat(`<i class="text-blue">${this.$scope.$filter("simpleDate")(this.$scope.model.EVENT_DISCH.FORECAST_DATE)}</i>`);
                    else concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.$filter("simpleDate")(this.$scope.model.EVENT_DISCH.FORECAST_DATE)) : concatenated.concat(this.$scope.$filter("simpleDate")(this.$scope.model.EVENT_DISCH.FORECAST_DATE));
                }
            }

            if (this.$scope.model.PRODUCT.ID == EProductId.ROAD_EXPORT || this.$scope.model.PRODUCT.ID == EProductId.ROAD_IMPORT || this.$scope.model.PRODUCT.ID == EProductId.ROAD_NATIONAL) {
                const eventPickUpDeparture = this.$scope.model.EVENT.find(x => x.EVENT_TYPE.ID == EEventType.PICK_UP_DEPARTURE);
                if (eventPickUpDeparture) {
                    if (eventPickUpDeparture.EFFECTIVE_DATE) concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.$filter("simpleDate")(eventPickUpDeparture.EFFECTIVE_DATE)) : concatenated.concat(this.$scope.$filter("simpleDate")(eventPickUpDeparture.EFFECTIVE_DATE));
                    else if (eventPickUpDeparture.FORECAST_DATE) {
                        if (isHtml) concatenated = concatenated.length > 0 ? concatenated.concat(separator, `<i class="text-blue">${this.$scope.$filter("simpleDate")(eventPickUpDeparture.FORECAST_DATE)}</i>`) : concatenated.concat(`<i class="text-blue">${this.$scope.$filter("simpleDate")(eventPickUpDeparture.FORECAST_DATE)}</i>`);
                        else concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.$filter("simpleDate")(eventPickUpDeparture.FORECAST_DATE)) : concatenated.concat(this.$scope.$filter("simpleDate")(eventPickUpDeparture.FORECAST_DATE));
                    }
                }

                const eventDeliveryDeparture = this.$scope.model.EVENT.find(x => x.EVENT_TYPE.ID == EEventType.DELIVERY_DEPARTURE);
                if (eventDeliveryDeparture) {
                    if (eventDeliveryDeparture.EFFECTIVE_DATE) concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.$filter("simpleDate")(eventDeliveryDeparture.EFFECTIVE_DATE)) : concatenated.concat(this.$scope.$filter("simpleDate")(eventDeliveryDeparture.EFFECTIVE_DATE));
                    else if (eventDeliveryDeparture.FORECAST_DATE) {
                        if (isHtml) concatenated = concatenated.length > 0 ? concatenated.concat(separator, `<i class="text-blue">${this.$scope.$filter("simpleDate")(eventDeliveryDeparture.FORECAST_DATE)}</i>`) : concatenated.concat(`<i class="text-blue">${this.$scope.$filter("simpleDate")(eventDeliveryDeparture.FORECAST_DATE)}</i>`);
                        else concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.$filter("simpleDate")(eventDeliveryDeparture.FORECAST_DATE)) : concatenated.concat(this.$scope.$filter("simpleDate")(eventDeliveryDeparture.FORECAST_DATE));
                    }
                }

            }

            if (this.$scope.model.CONCATENATED_COMPLEMENT && this.$scope.model.CONCATENATED_COMPLEMENT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CONCATENATED_COMPLEMENT) : concatenated.concat(this.$scope.model.CONCATENATED_COMPLEMENT);
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
        return concatenated;
    }

    private buildProcessPriorityOptionMenu(): IFloatingOption {
        if (!this.$scope.model.PRIORITY) return null;
        const option: IFloatingOption = {
            click: "updateProcessPriority",
            args: [],
            tooltipPlacement: "auto bottom",
            textTooltip: "OPERATIONAL.STANDAD_PRIORITY",
            iconClass: "text-warning fa fa-bolt unhover",
            bodyClass: "bg-danger unhover"
        };
        if (this.$scope.model.PRIORITY.ID == EPriorityId.NORMAL) {
            option.textTooltip = "OPERATIONAL.HIGH_PRIORITY";
            option.bodyClass = "unhover";
        }
        return option;
    }

    private getMenuFloatingDefault(): IFloatingMenu {
        const menuOptions = {
            tooltipPlacement: "auto bottom",
            textTooltip: "OPERATIONAL.FILE_DATA",
            infos: [
                { text: this.$scope.model.PROCESS_NUMBER, class: "text-cyano font-bold", click: "openSummaryModal", args: [] }
            ],
            optionsLeft: [
                this.buildProcessPriorityOptionMenu()
            ],
            options: [
                { click: "releaseCollapse", args: [ECollapseState.MAIN], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.MAIN", iconClass: "fa fa-home", iconBodyClass: "text-brown" },
                { click: "releaseCollapse", args: [ECollapseState.PROCESS_DOCUMENT], tooltipPlacement: "auto bottom", textTooltip: "OPERATIONAL.TRANSPORT_BILL", iconClass: "fa fa-file", iconBodyClass: "text-black" },
                { click: "releaseCollapse", args: [ECollapseState.CARGO], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.CARGO", iconClass: "fa fa-truck", iconBodyClass: "text-cyano" },
                { click: "releaseCollapse", args: [ECollapseState.EVENTS], tooltipPlacement: "auto bottom", textTooltip: "OPERATIONAL.EVENT", iconClass: "fa fa-location-arrow", iconBodyClass: "text-rouge" },
                { click: "releaseCollapse", args: [ECollapseState.CHARGES], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.CHARGES", iconClass: "fa fa-usd", iconBodyClass: "text-green" },
                { click: "releaseCollapse", args: [ECollapseState.CHARGE_OUT_DATE], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.CHARGES", iconClass: "fa fa-usd", iconBodyClass: "text-danger" },
                { click: "releaseCollapse", args: [ECollapseState.COMMUNICATION], tooltipPlacement: "auto bottom", textTooltip: "OPERATIONAL.COMMUNICATION", iconClass: "fa fa-paper-plane", iconBodyClass: "text-orange" },
                { click: "releaseCollapse", args: [ECollapseState.MANAGEMENT], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.MANAGEMENT", iconClass: "fa fa-gears", iconBodyClass: "text-green" },
                { click: "releaseCollapse", args: [ECollapseState.TASKS], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.TASKS", iconClass: "fa fa-tasks", iconBodyClass: "text-blue" },
            ],
            optionsModule: [
                { click: "goToCharge", args: [], text: "GENERAL.CHARGES", iconClass: "fa fa-usd text-green" },
                { click: "goToChargeOutDate", args: [], text: "GENERAL.CHARGES", iconClass: "fa fa-usd text-danger" },
                { click: "goToInvoice", args: [], text: "GENERAL.INVOICES", iconClass: "fa fa-money text-green" },
                { click: "goToDraftProcess", args: [], text: "GENERAL.MENU.DRAFT_RECEPTION", iconClass: "fa fa-file-text-o text-bronze" },
                { click: "goToDocument", args: [], text: "OPERATIONAL.PROCESS_DOCUMENT", iconClass: "fa fa-ship text-brown" },
                { click: "goToTaskManager", args: [], text: "GENERAL.TASKS_SCHEDULE", iconClass: "fa fa-play text-orange" },
                { click: "goToDeadLineMasterHouse", args: [], text: "OPERATIONAL.STOPOVER_DEADLINES", iconClass: "fa fa-clock-o text-danger" }
            ]
        };

        return menuOptions;
    }

    private async callSessionFunctions(data: object): Promise<void> {
        try {
            if (!data) return;
            const processExchangeData = <INewProcessExchangeData>data;
            let processModel: IProcess = null;
            for (const gridData of this.$scope.gridOptions.data) {
                if (processExchangeData.PROCESS_NUMBER != null && gridData.PROCESS_NUMBER == processExchangeData.PROCESS_NUMBER) {
                    processModel = gridData;
                    break;
                }
            }
            switch (processExchangeData.OPERATION) {
                case EOperation.VIEW: if (processModel) this.$scope.viewProcess(processModel);
                    break;
                case EOperation.EDIT: {
                    if (processModel) await this.$scope.editProcess(processModel, processExchangeData.ID_EVENT);
                    break;
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private navigateBetweenIds(to: string): boolean {
        if (!to) return false;
        this.$scope.$timeout(function () {
            const element = $("#" + to);
            if (element.length == 0) return;
            const position = $("#" + to).offset().top + $('.app-content-body').scrollTop() - 215;
            $('.app-content-body').animate({
                scrollTop: position
            }, 500);
            return true;
        });
        return false;
    };

    private async getEDIAgility(processNumber: string): Promise<any> {
        return this.$scope.restService.getObjectAsPromise(`/allog/ediagility/${processNumber}`, 10000);
    }

    private async updateGridRow(id: string) {
        try {
            if (id && angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$scope.$timeout(async () => {
                    const processData = await this.getProcessByProcessNumber(id);
                    if (row && processData) {
                        row.ORIGIN = processData.ORIGIN;
                        row.DESTINATION = processData.DESTINATION;
                        row.SERVICE_PROVIDER = processData.SERVICE_PROVIDER;
                        row.BOOKING = processData.BOOKING;
                    }
                    this.$scope.model.ORIGIN = processData.ORIGIN;
                    this.$scope.model.DESTINATION = processData.DESTINATION;
                    this.$scope.model.SERVICE_PROVIDER = processData.SERVICE_PROVIDER;
                }, 1000);
            }
        } catch (ex) {
            this.handleError(ex);
        }

    }

    private tooltip(summaryValidationResult: IProcessSummaryValidationResult): string {
        let result = this.formService.getTranslate('PROCESS.CLICK_VALIDATE_PROCESS');
        if (summaryValidationResult && summaryValidationResult.USER && summaryValidationResult.DATE) {
            result = this.formService.getTranslate('GENERAL.CLICK_VALIDATE_OFFER_MESSAGE', { user: summaryValidationResult.USER, date: summaryValidationResult.DATE })
        }
        return result;
    }

    private async consistencyValidateProcess(idProcess: string, process?: Process, loadDetails?: boolean): Promise<void> {
        if (!idProcess) throw new Error("idProcess is null.");
        this.formService.block();
        try {
            let validateResult = null;
            if (process && process.ID_PROCESS_WIZARD_OPTION) validateResult = await this.$scope.dataProcessService.get(`/processValidation/validate/${idProcess}/false`, 30000);
            // else validateResult = await this.$scope.operationalService.get(`/process/validate/${process.PROCESS_NUMBER}/false`, 30000);

            if (validateResult && validateResult.data.data) {
                if (process) process.SUMMARY_VALIDATION_RESULT = validateResult.data.data;
                if (typeof this.$scope.gridOptions.data == 'object') {
                    const processGrid: Process = this.$scope.gridOptions.data.find(obj => obj.ID_PROCESS == idProcess);
                    if (processGrid) processGrid.SUMMARY_VALIDATION_RESULT = validateResult.data.data;
                }
                const msgSuccess = this.formService.getTranslate('PRODUCT.CONSISTENCY_VALIDATION_PERFORMED_SUCCESSFULLY');
                this.formService.notifySuccess(msgSuccess);
                if (loadDetails) this.viewProcessConsistencyValidateDetails(this.lastGridProcess, true);
            }
            this.viewProcessConsistencyValidateDetails(process);
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async viewProcessConsistencyValidateDetails(process: Process, isReload?: boolean): Promise<void> {
        if (!process) throw new Error("process is null.");
        this.formService.block();
        try {
            let allValidationResultRequest = null;
            if (!process.ID_PROCESS_WIZARD_OPTION) allValidationResultRequest = await this.$scope.operationalService.get(`/process/allValidation/${process.PROCESS_NUMBER}`, 30000);
            else allValidationResultRequest = await this.$scope.dataProcessService.get(`/processValidation/allValidation/${process.ID}`, 30000);

            if (allValidationResultRequest && allValidationResultRequest.data.data) {
                this.lastGridProcess = process;
                this.$scope.allValidationResult = allValidationResultRequest.data.data;

                if ((allValidationResultRequest.data.data.PROCESS.VALIDATION_RESULT && (allValidationResultRequest.data.data.PROCESS.VALIDATION_RESULT.HAS_ERROR || allValidationResultRequest.data.data.PROCESS.VALIDATION_RESULT.HAS_WARNING)) || (allValidationResultRequest.data.data.PROCESS_CARGO && allValidationResultRequest.data.data.PROCESS_CARGO.VALIDATION_RESULT && (allValidationResultRequest.data.data.PROCESS_CARGO.VALIDATION_RESULT.HAS_ERROR || allValidationResultRequest.data.data.PROCESS_CARGO.VALIDATION_RESULT.HAS_WARNING))) {
                    if (!isReload) {
                        const modalId = this.$scope.modalService.newModal();
                        this.$scope.modalService.showModalInfo(
                            {
                                modalID: modalId,
                                scope: this.$scope,
                                formService: EOperation.VIEW,
                                size: 'vlg modal-overflow',
                                template: require("../view/modal/processConsistencyValidateDetailsModal.html"),
                                keyboard: false
                            },
                            null
                        );
                    }
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private sendSync = async (id: number): Promise<boolean> => {
        let success = false;

        try {
            if (id) {
                const processReplication = await this.$scope.dataProcessService.post(`/processReplication`, { idProcess: id }, 30000);
                if (processReplication) success = true;

                const processDetDem = await this.$scope.dataProcessService.post(`/processDetDemManagement/generate/`, { idProcess: id, async: false }, 30000);
                if (processDetDem) success = true;
            }
        } catch (ex) {
            this.formService.handleError(this.formService.getTranslate('GENERAL.ERROR_SENDING_REQUEST'));
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$scope.$timeout(async () => {
                    const processData = await this.getProcessByProcessNumber(id.toString());
                    if (processData) {
                        if (row && processData && processData.DOCUMENT_ERROR !== undefined) {
                            row.DOCUMENT_ERROR = processData.DOCUMENT_ERROR;
                            documentError = processData.DOCUMENT_ERROR;
                        }
                    }

                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async openPreProcessRegisterModal(): Promise<void> {
        try {
            if (this.preProcessModalId) this.$scope.modalService.closeModal(this.preProcessModalId);
            if (!this.preProcessModalId) this.preProcessModalId = this.$scope.modalService.newModal();

            await this.$scope.modalService.showModalRegisterPreProcess(this.preProcessModalId);

            const modalScope = await this.$scope.modalService.getModalScope(this.preProcessModalId);
            await modalScope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openProcessKnowledgeModal(): Promise<void> {
        try {
            this.formService.block();
            this.processKnowledgeModal = this.$scope.modalService.newModal();

            await this.$scope.modalService.showModalInfo(
                {
                    modalID: this.processKnowledgeModal,
                    template: require("../view/modal/processKnowledgeModal.html"),
                    scope: this.$scope,
                    size: 'lg modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: this.$scope.formOperation
                }
            );

            //await this.buildProcessKnowledgeModalScope(this.$scope.model);
            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async openTrackingLink(processProvider: ISelectorModel): Promise<void> {
        try {
            if (!processProvider) throw new Error("ProcessProvider is null.");

            const request = `/trackingLinkSettings/getTrackingLink`;
            const data = {
                provider: processProvider,
                timeout: 30000
            }

            const result = await this.$scope.operationalService.post(request, data)
            if (result && result.data && result.data.data[0] && result.data.data[0].URL && result.status == 200) {
                const resultUrl = result.data.data[0].URL;

                // Extract all tags in the URL
                const tags = resultUrl.match(/{{[^}]+}}/g) || [];

                const externalReq: IMonacoRequest = {
                    route: "/process/translateContentTags",
                    data: {
                        processNumber: this.$scope.model.PROCESS_NUMBER,
                        _tags: tags,
                        contentToTranslate: resultUrl,
                    },
                    timeout: 120000
                }

                const newUrl = await this.$scope.externalService.post(externalReq);
                if (newUrl && newUrl.data && newUrl.data.data) {
                    // Open new window
                    window.open(newUrl.data.data);
                }
            } else {
                this.formService.handleError(this.formService.getTranslate('OPERATIONAL.PROVIDER_HAS_NO_TRACKING_LINK'));
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async copyToClipboard(bookingNumber: string) {
        try {
            // Using new Clipboard API
            await navigator.clipboard.writeText(bookingNumber);
        } catch (err) {
            console.error('Unable to copy text to clipboard', err);
        }
    }

}

import * as angular from "angular";
import { IGridFormServiceScope, GridFormService, IGridFormController, IMonacoRequestLog } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { SSEService } from "@appServices/SSEService";
import { ISessionService } from "@services/SessionService";
import { SelectorModel } from "../../common/model/SelectorModel";
import { IAWBStockModel, IAWBWizardModel } from "@models/interface/operational/AWBStockModel";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { ETypeLocalId, EProductId, EOperation, EProviderTypeId } from "@enums/GenericData";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { IMonacoColumnDef } from "@services/GridService2";
import { OperationalService } from "@services/OperationalService";
import { IModalService, IModalOptions } from "@services/ModalService";
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { BrowserTitle } from '../../common/BrowserTitle';
import { ISelectorModel } from "WBA-Model/dist/mongo/SelectorModel";
import { IProviderListCustomFilter } from '../../registration/model/LegalPersonModel'
import { ProductService } from "@services/ProductService";
import { IGridOptions } from 'ui-grid';

export interface IMonacoRequest<T = any> {
    route?: string;
    operation?: string;
    data?: T;
    timeout?: number;
    user?: any;
}

export interface ISessionParameter {
    param: object;
    data?: object;
}

interface IAWBStockScope extends IGridFormServiceScope {
    model: IAWBStockModel;
    modalOptions: IModalOptions;
    wizardModel: IAWBWizardModel;
    sessionService: ISessionService;
    commoditySectionList: SelectorModel[];
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IAWBStockModel;
    airlineList: ISelectorModel[];
    tabIndex: any;
    lastTab: any;
    user: any;
    gridOptions: IGridOptions;
    $timeout: ng.ITimeoutService;

    editAWBStock: (AWBStock: IAWBStockModel) => Promise<void>;
    fetchData: (id: number, action: string) => Promise<void>;
    viewAWBStock: (AWBStock: IAWBStockModel) => Promise<void>;
    viewLogAWBStock: (AWBStock: IAWBStockModel) => Promise<void>;
    copyAWBStock: (AWBStock: IAWBStockModel) => Promise<void>;
    getAirlineList: (search: string) => void;
    tabClicked: (tabIndex: number) => void;
    changePrefix(prefix: ISelectorModel): void;
    confirm: () => void;
    activeOrInactive: () => void;
    isAWBStockSituationValidity: (model: IAWBStockModel) => boolean;
    inactivateUpdate: (model: IAWBStockModel) => Promise<void>;
}

export class AWBStockRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private scope: IAWBStockScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private SSEService: SSEService;
    private gridName: string;
    private operationalService: OperationalService;
    private modalID: number;
    private ModalService: IModalService;
    private ProductService: ProductService;
    private tabClicked: number = 1;

    constructor($injector: ng.Injectable<any>, $scope: IAWBStockScope) {
        super($injector, $scope);

        this.scope = $scope;
        this.ProductService = $injector.get("ProductService");
        this.ModalService = $injector.get('ModalService');
        this.$q = $injector.get('$q');
        this.scope.$timeout = $injector.get('$timeout');
        this.RestService = $injector.get('RestService');
        this.scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.operationalService = $injector.get('OperationalService');
        this.gridName = 'GRID_AWB_STOCK';
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.operationalService.$route;
            this.scope.customLogProperties = this.getCustomLogProperties();
            this.$rootScope.setBreadCrumb("AWB", () => { this.openWizard(); });
            this.initForm(this, 'form', 'awbstock', 'AWB', false);
            this.initScopeFunctions();
            this.initModel();
            await this.initGrid(this.gridName, '/awbstock/list', true, true, null, true, true);
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.scope.getAirlineList = async (search: string): Promise<void> => {
            let airlineList: ISelectorModel[] = [];
            if (search && search.length >= 3) airlineList = await this.getAirline({ search: search, types: [EProviderTypeId.AIRLINE] });
            this.scope.airlineList = airlineList
        }

        this.scope.changePrefix = (prefix: ISelectorModel) => {
            this.changePrefix(prefix);
        }

        this.scope.viewAWBStock = async (AWBStock: IAWBStockModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.scope.view(AWBStock);
        }

        this.scope.viewLogAWBStock = async (AWBStock: IAWBStockModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.scope.viewLog(AWBStock);
        }

        this.scope.copyAWBStock = async (AWBStock: IAWBStockModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.scope.fetchData(AWBStock.ID, EOperation.COPY);
        }

        this.scope.confirm = (): void => {
            this.confirmWizard();
        }

        this.scope.activeOrInactive = () => {
            this.scope.wizardModel.ACTIVE = (this.scope.wizardModel.ACTIVE == true) ? false : true;
        }

        this.scope.isAWBStockSituationValidity = (model: IAWBStockModel) => {
            return this.isAWBStockSituationValidity(model);
        }

        this.scope.inactivateUpdate = (model: IAWBStockModel) => {
            return this.inactivateUpdate(model);
        }

        this.scope.tabClicked = (tabIndex: number): void => {
            this.tabClicked = tabIndex;

            if (this.tabClicked != this.scope.lastTab) {
                this.scope.lastTab = this.tabClicked
                this.scope.tabIndex = this.tabClicked

                if (this.scope.tabIndex == 1) {
                    this.scope.wizardModel.FOR_QUANTITY_TAB.FIRST_NUMBER = null;
                    this.scope.wizardModel.FOR_QUANTITY_TAB.QUANTITY = null;
                    this.scope.wizardModel.FOR_UNIC_NUMBER.NUMBERS = null;
                } else if (this.scope.tabIndex == 2) {
                    this.scope.wizardModel.FOR_INTERVAL_TAB.FIRST_NUMBER = null;
                    this.scope.wizardModel.FOR_INTERVAL_TAB.LAST_NUMBER = null;
                    this.scope.wizardModel.FOR_UNIC_NUMBER.NUMBERS = null;
                } else {
                    this.scope.wizardModel.FOR_INTERVAL_TAB.FIRST_NUMBER = null;
                    this.scope.wizardModel.FOR_INTERVAL_TAB.LAST_NUMBER = null;
                    this.scope.wizardModel.FOR_QUANTITY_TAB.FIRST_NUMBER = null;
                    this.scope.wizardModel.FOR_QUANTITY_TAB.QUANTITY = null;
                };
            }
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);
        let inactivate = "";
        // if (this.scope && this.scope.model && this.scope.model.SITUATION && this.scope.model.SITUATION.ID == "1") {
        inactivate = `<a ng-if="grid.appScope.isAWBStockSituationValidity(row.entity)" ng-click="grid.appScope.inactivateUpdate(row.entity)"  class="text-rouge" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INACTIVATE' | translate }}" tooltip-append-to-body="true"><i class="fa fa-minus-circle"></i></a>&nbsp;&nbsp;`;
        // }
        const viewLog = `<a ng-click="grid.appScope.viewLogAWBStock(row.entity)" class="text-green log-btn-action-bar" "tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 10,
            maxWidth: 90,
            cellTemplate: `<div class="text-center view-btn-action-bar"> ${viewLog} ${inactivate} </div>`,
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: false
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colProvider: IMonacoColumnDef = { name: "PROVIDER.NAME", displayName: "BASIC_DATA.PROVIDER", width: 100 };
            const colAWBNumber: IMonacoColumnDef = { name: "AWB_NUMBER", displayName: "OPERATIONAL.AWB_NUMBER", width: 100 };
            const colProcessNumber: IMonacoColumnDef = { name: "PROCESS", displayName: "OPERATIONAL.FILE_NUMBER", width: 100 };
            const colSituation: IMonacoColumnDef = { name: "SITUATION.NAME", displayName: "GENERAL.SITUATION", width: 100 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colCreatedBy: IMonacoColumnDef = { name: "CREATED_BY.NAME", displayName: "GENERAL.CREATED_BY", width: 150 };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedBy: IMonacoColumnDef = { name: "UPDATED_BY.NAME", displayName: "GENERAL.UPDATED_BY", width: 150 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 100, cellFilter: "YesOrNo" };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'PROVIDER':
                        columnDefs.push(colProvider);
                        break;
                    case 'AWB_NUMBER':
                        columnDefs.push(colAWBNumber);
                        break;
                    case 'PROCESS':
                        columnDefs.push(colProcessNumber);
                        break;
                    case 'SITUATION':
                        columnDefs.push(colSituation);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'CREATED_BY':
                        columnDefs.push(colCreatedBy);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                    case 'UPDATED_BY':
                        columnDefs.push(colUpdatedBy);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.scope.model = {
            _id: null,
            ID: null,
            PROVIDER: null,
            AWB_NUMBER: null,
            PROCESS: null,
            SITUATION: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            ACTIVE: null
        };
        this.scope.wizardModel = {
            PROVIDER: null,
            PREFIX: null,
            FOR_INTERVAL_TAB: {
                FIRST_NUMBER: null,
                LAST_NUMBER: null
            },
            FOR_QUANTITY_TAB: {
                FIRST_NUMBER: null,
                QUANTITY: null
            },
            FOR_UNIC_NUMBER: {
                NUMBERS: null
            },
            ACTIVE: true
        }
    }

    initDependencies(): Promise<any> {
        const self: AWBStockRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
            ]).then((result: any) => {
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getAirline(filter: IProviderListCustomFilter): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            if (filter && filter.search && filter.search.length >= 2) {
                this.formService.block();
                const request: IMonacoRequest = {
                    data: {
                        ...filter
                    },
                    route: `/provider/list/custom/operational`,
                    timeout: 120000,
                }
                const rc = await this.ProductService.post(request);

                if (rc && rc.data && rc.data.data && rc.status == 200) {
                    result = rc.data.data.map(x => {
                        return { ID: x.ID, NAME: x.NAME, NUMBER_IATA: x.NUMBER_IATA }
                    });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private changePrefix(prefix: any): void {
        if (prefix) {
            this.scope.wizardModel.PREFIX = prefix.NUMBER_IATA;
        }
    }

    private async openWizard(): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    scope: this.scope,
                    formService: EOperation.VIEW,
                    template: require("../view/modal/awbStockModal.html"),
                    size: 'lg modal-overflow'
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: "GENERAL.CANCEL",
                    headerText: "OPERATIONAL.ADD_AWB_NUMBER"
                }
            );

            const modalScope = await this.ModalService.getModalScope(this.modalID);
            await modalScope.$applyAsync();

            const apply = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (apply) {
                const msg = this.formService.getTranslate('GENERAL.REGISTRATION_SUCCESSFULLY_REGISTERED');
                this.formService.notifySuccess(msg);
                this.formService.block();
                await this.updateGrid();
            }

        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async confirmWizard() {
        try {
            const timeout: number = 120000;

            const awbNumbers: IAWBWizardModel = this.scope.wizardModel;
            const request = {
                operation: 'register',
                data: awbNumbers,
                timeout
            }
            if(!this.limitRegister()) return

            const result = await this.operationalService.post<any>('/awbstock/insert', request, timeout);
            if (result && result.data && result.data.data && angular.isArray(this.scope.gridOptions.data)) {
                this.scope.gridOptions.data.push(result.data.data)
                this.ModalService.closeModal(this.modalID);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: 'ID',
                LABEL: 'GENERAL.IDENTIFIER'
            },
            {
                PROPERTY: 'PROVIDER',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'AWB_NUMBER',
                LABEL: 'GENERAL.CODE'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            }
        ]
        return props;
    }

    async register(): Promise<void> {
        try {
            this.scope.scopeBeforeSave = null;
            this.scope.formOperation = 'Novo Cadastro';
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.scope.formOperation = 'Visualizando';
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.scope.formOperation = 'Editando';
            this.scope.scopeBeforeSave = angular.copy(this.scope.model);
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.scope.disableElements(false);
            await this.clearFields(this.scope.model, ['CONCATENATED']);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.scope.operation == 'register' || this.scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.scope.operation === "edit" ? "update" : "insert";

        return {
            route: `/awbstock/${route}`,
            data: angular.copy(this.scope.model),
            oldData: this.scope.scopeBeforeSave
        };
    }

    private isAWBStockSituationValidity(model: IAWBStockModel): boolean {
        try {
            return (model && model.SITUATION && model.SITUATION.ID == '1');
        } catch (ex) {
            this.handleError(ex);
            return false;
        }
    }
    private limitRegister():boolean{
        switch (this.tabClicked) {
            case 1:
                return this.limitRegisterbyInterval();
            case 2:
                return this.limitRegisterbyQuantity();
            case 3:
                return this.limitRegisterbyUnic();    
            default:
                break;
        }
    }

    private limitRegisterbyUnic():boolean{
        const quebrasDeLinha = this.scope.wizardModel.FOR_UNIC_NUMBER.NUMBERS.match(/\n/g); 
        if (quebrasDeLinha && quebrasDeLinha.length > 100) {
            this.$formService.notifyError(this.formService.getTranslate("OPERATIONAL.LIMIT_REGISTERS"))
            return false
        } 
        return true
    }

    private limitRegisterbyInterval():boolean{
        if(this.scope.wizardModel.FOR_INTERVAL_TAB.LAST_NUMBER < this.scope.wizardModel.FOR_INTERVAL_TAB.FIRST_NUMBER){
            this.scope.wizardModel.FOR_INTERVAL_TAB.LAST_NUMBER = null;
            this.$formService.notifyError(this.formService.getTranslate("OPERATIONAL.INVALID_SERIE_NUMBER"))
            return false
        }
        if(this.scope.wizardModel.FOR_INTERVAL_TAB.LAST_NUMBER - this.scope.wizardModel.FOR_INTERVAL_TAB.FIRST_NUMBER > 100){
            this.scope.wizardModel.FOR_INTERVAL_TAB.LAST_NUMBER = null;
            this.$formService.notifyError(this.formService.getTranslate("OPERATIONAL.LIMIT_REGISTERS"))
            return false
        }
        return true
    }

    private limitRegisterbyQuantity():boolean{
        if(this.scope.wizardModel.FOR_QUANTITY_TAB.QUANTITY > 100){
            this.scope.wizardModel.FOR_QUANTITY_TAB.QUANTITY = 100;
            this.$formService.notifyError(this.formService.getTranslate("OPERATIONAL.LIMIT_REGISTERS"))
            return false
        }
        return true
    }

    private async inactivateUpdate(model: IAWBStockModel): Promise<void> {
        try {
            if (!model || !model.AWB_NUMBER) return this.handleError(this.formService.getTranslate("GENERAL.FAILED_TO_GET_DRAFT_NUMBER"));

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.YES",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.ATTENTION_REQUIRED",
                bodyText: "Do you want to inactivate this AWBStock?"
            });

            if (!modal) return;

            this.block();

            const timeout = 120000;
            const request = {
                awbNumber: model.AWB_NUMBER,
                timeout: timeout,
            };

            const rc = await this.operationalService.post<any>('/awbstock/inactivate', request, timeout);
            if (rc.status === 200) {
                await this.updateGrid();
                this.$formService.notifySuccess(this.formService.getTranslate("GENERAL.DRAFT_STATUS_UPDATED"))
            } else {
                this.$formService.notifyError(this.formService.getTranslate("GENERAL.DRAFT_UPDATE_ERROR"))
            }

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }
}
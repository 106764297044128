import * as angular from "angular";
import * as intlTelInput from 'intl-tel-input';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog, } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IModalService } from "@services/ModalService";
import { ISessionService } from "@services/SessionService";
import { ExternalService } from "@services/ExternalService";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { ICity } from "@models/interface/product/CityModel";
import { EPhysicalPersonTaxIdType, EOperation } from "@enums/GenericData";
import { IPhysicalPersonModel } from "../model/PhysicalPersonModel";
import { SelectorModel } from "../../common/model/SelectorModel";
import { IZipCodeResult } from "../../external/interface/ZipCode";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { IFloatingMenu } from "../../common/interface/IFloatingMenu";
import { ILinkParameter } from "../../common/model/ModelParameter";
import { BrowserTitle } from "../../common/BrowserTitle";
import { ICurrentAccount, ICurrentAccountModal } from '../interface/ICurrentAccount';
import { StringUtil } from "../../common/util/StringUtil";
import { ValidateUtil } from "../../common/util/ValidateUtil";
import { DataProductService } from "@services/DataProductService";
import { IDocumentError } from '@models/interface/common/IDocumentError';
import { SSEService } from "@appServices/SSEService";
import { HelperService } from "@services/HelperService";
import { ICountry } from "WBA-Model/dist/interface/common/Entity";

interface ICitySelectorModel extends SelectorModel {
    PROVINCE: IProvince;
}

interface IProvince extends SelectorModel {
    COUNTRY: SelectorModel;
}

interface IPhysicalPersonScope extends IGridFormServiceScope {
    model: IPhysicalPersonModel;
    gridOptions: uiGrid.IGridOptions;
    log: IViewLog;
    taxIdTypeList: SelectorModel[];
    specTypeList: SelectorModel[];
    specSituationList: SelectorModel[];
    pixTypeList: SelectorModel[];
    personRoleList: SelectorModel[];
    sectorList: SelectorModel[];
    cityList: ICitySelectorModel[];
    customLogProperties: ICustomLogProperties[];
    menuFloating: IFloatingMenu;
    scopeBeforeSave: IPhysicalPersonModel;
    isSankhyaError: boolean;
    user: any;
    sessionService: ISessionService;
    editPhysicalPerson: (physicalPerson: IPhysicalPersonModel) => Promise<void>;
    viewPhysicalPerson: (physicalPerson: IPhysicalPersonModel) => Promise<void>;
    viewLogPhysicalPerson: (physicalPerson: IPhysicalPersonModel) => Promise<void>;
    copyPhysicalPerson: (physicalPerson: IPhysicalPersonModel) => Promise<void>;
    getCityListByNameOrInitials: (search: string) => Promise<void>;
    getSectorListByName: (name: string) => Promise<void>;
    getPersonRoleListByName: (name: string) => Promise<void>;
    typeSelect: () => void;
    collapseHeader: (elementId: string, state?: string) => void;
    addCurrentAccount: () => void;
    editCurrentAccount: (index: number, currentAccount: ICurrentAccount) => void;
    removeCurrentAccount: (index: number) => void;
    getZipCode: (zipCode: string) => void;
    initPanels: () => void;
    goToSector: (id: number) => void;
    goToPersonRole: (id: number) => void;
    goToCity: (id: number) => void;
    goToProvince: (id: number) => void;
    goToCountry: (id: number) => void;
    limitLines: (value: string, limit: number) => string;
    validityPhone: (inputName: string) => void;
    getPhoneValidityMessage: (inputName: string) => string;
    formatEmail: (email: string) => string;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
    integrateSankhya: (id: number) => void;
    integrateSankhyaStatus: (id: number) => void;
    buildErrorSankhyaTooltip: (errors: IDocumentError[]) => string;
    fetchData: (id: number, action: string) => Promise<void>;
}

export class PhysicalPersonRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IPhysicalPersonScope;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService
    private RestService: IRestService;
    private ExternalService: ExternalService;
    private ModalService: IModalService;
    private modalID: number;
    private collapseState: string;
    private sessionService: ISessionService;
    private intlPhone: intlTelInput.Plugin;
    private countryChangeListener: EventListenerOrEventListenerObject;
    private dataProductService: DataProductService;
    private gridName: string;
    private SSEService: SSEService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IPhysicalPersonScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.RestService = $injector.get('RestService');
        this.ExternalService = $injector.get('ExternalService');
        this.ModalService = $injector.get('ModalService');
        this.modalID = null;
        this.collapseState = "hide";
        this.dataProductService = $injector.get('DataProductService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.gridName = 'GRID_PHYSICAL_PERSON';
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.customLogProperties = this.getCustomLogProperties();

            this.initForm(this, 'form', 'physicalPerson', 'GENERAL.MENU.PHYSICAL_PERSON', true);
            await this.initGrid(this.gridName, '/physicalPerson/list', true, true, null, true, true);
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
        this.destroyPhoneComponent();
    }

    initScopeFunctions(): void {
        this.$scope.editPhysicalPerson = async (physicalPerson: IPhysicalPersonModel): Promise<void> => {
            let blockedObject = {
                ID: physicalPerson.ID,
                NAME: physicalPerson.NAME,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || physicalPerson.ID !== this.$scope.model.ID) this.$scope.fetchData(physicalPerson.ID, EOperation.VIEW);
                } else if (this.$scope.operation !== EOperation.EDIT || physicalPerson.ID !== this.$scope.model.ID) {
                    this.$scope.fetchData(physicalPerson.ID, EOperation.EDIT);
                }
            };
        }

        this.$scope.viewPhysicalPerson = async (physicalPerson: IPhysicalPersonModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(physicalPerson.ID, EOperation.VIEW);
        }

        this.$scope.viewLogPhysicalPerson = async (physicalPerson: IPhysicalPersonModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(physicalPerson);
        }

        this.$scope.copyPhysicalPerson = async (physicalPerson: IPhysicalPersonModel): Promise<void> => {
            this.SSEService.closeEvents();
            physicalPerson.ERP_INTEGRATION_PARAM = null;
            physicalPerson.DOCUMENT_ERROR = null;
            this.$scope.fetchData(physicalPerson.ID, EOperation.COPY);
        }

        this.$scope.typeSelect = () => {
            this.$scope.model.ID_FISCAL = null;
        }

        this.$scope.collapseHeader = (elementId: string, state?: string) => {
            this.collapseHeader(elementId, state);
        }

        this.$scope.getCityListByNameOrInitials = async (search: string) => {
            let cityList: ICitySelectorModel[] = [];
            if (search && search.length >= 3) {
                cityList = await this.getCityListByNameOrInitials(search);
            }
            this.$scope.cityList = cityList;
        }

        this.$scope.getSectorListByName = async (name: string) => {
            let sectorList: SelectorModel[] = [];
            if (name && name.length >= 2) {
                sectorList = await this.getSectorListByName(name);
            }
            this.$scope.sectorList = sectorList;
        }

        this.$scope.getPersonRoleListByName = async (name: string) => {
            let personRoleList: SelectorModel[] = [];
            if (name && name.length >= 2) {
                personRoleList = await this.getPersonRoleListByName(name);
            }
            this.$scope.personRoleList = personRoleList;
        }

        this.$scope.getZipCode = (zipCode: string) => {
            this.getZipCode(zipCode);
        }

        this.$scope.addCurrentAccount = () => {
            this.addCurrentAccountModal();
        }

        this.$scope.editCurrentAccount = (index: number, currentAccount: ICurrentAccount) => {
            this.editCurrentAccount(index, currentAccount);
        }

        this.$scope.removeCurrentAccount = (index: number) => {
            this.removeCurrentAccount(index);
        }

        this.$scope.initPanels = () => {
            const panels = document.getElementsByClassName("toggle-me");
            if (panels) {
                for (let i = 0; i < panels.length; i++) {
                    const panel = panels[i];
                    if (panel.id === "collapseBasicData") {
                        if (!panel.classList.contains('in')) $("#" + panel.id)["collapse"]("show");
                    } else if (panel.classList.contains('in')) {
                        $("#" + panel.id)["collapse"]("hide");
                    }
                }
            }
            this.$scope.navigateBetweenIds('collapseBasicData');
        }

        this.$scope.goToCity = (id: number) => {
            this.sessionService.openTab("app.registration.city", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToProvince = (id: number) => {
            this.sessionService.openTab("app.registration.province", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToCountry = (id: number) => {
            this.sessionService.openTab("app.registration.country", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToSector = (id: number) => {
            this.sessionService.openTab("app.registration.sector", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToPersonRole = (id: number) => {
            this.sessionService.openTab("app.registration.personRole", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.$scope.limitLines = (value: string, limit: number) => {
            return StringUtil.limitLines(value, limit);
        }
        this.$scope.validityPhone = (inputName: string) => {
            if (!inputName) return;
            ValidateUtil.validityPhone(this.$scope['form'][inputName], inputName == "phone" ? this.intlPhone : null);
        };
        this.$scope.getPhoneValidityMessage = (inputName: string) => {
            return ValidateUtil.getPhoneValidityMessage(inputName && inputName == "phone" ? this.intlPhone : null);
        }
        this.$scope.formatEmail = (email: string): string => {
            return email ? email.replace(/[^A-Za-z@.]/g, '').toLowerCase() : "";
        }
        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }
        this.$scope.integrateSankhya = (id: number) => {
            this.integrateSankhya(id);
        }
        this.$scope.integrateSankhyaStatus = (id: number) => {
            this.integrateSankhyaStatus(id);
        }
        this.$scope.buildErrorSankhyaTooltip = (errors: IDocumentError[]) => {
            return this.buildErrorSankhyaTooltip(errors);
        }
        this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
            this.fetchData(id, action);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewPhysicalPerson(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editPhysicalPerson(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyPhysicalPerson(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogPhysicalPerson(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + copy + viewLog + modalIntegration),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colTypeId: IMonacoColumnDef = { name: "TYPE.NAME", displayName: "ENTITY.TAX_TYPE", width: 100 };
            const colIdFiscal: IMonacoColumnDef = { name: "ID_FISCAL", displayName: "ENTITY.TAX_NUMBER", width: 180, cellTemplate: "<div class='grid-padding' >{{row.entity.ID_FISCAL | cnpj}}</div>" };
            const colName: IMonacoColumnDef = { name: "NAME", displayName: "GENERAL.NAME", width: 400 };
            const colSpec: IMonacoColumnDef = { name: "SPEC.NAME", displayName: "ENTITY.ENTITY_TYPE", width: 400, cellTemplate: "<div class='grid-padding' >{{grid.appScope.getCONCAT(row.entity.SPEC)}}</div>" };
            const colSector: IMonacoColumnDef = { name: "SECTOR.NAME", displayName: "ENTITY.SECTOR", width: 200 };
            const colPersonRole: IMonacoColumnDef = { name: "PERSON_ROLE.NAME", displayName: "ENTITY.JOB_TITLE", width: 200 };
            const colAddressCity: IMonacoColumnDef = { name: "ADDRESS.CITY.NAME", displayName: "ROUTE.CITY", width: 200 };
            const colAddressProvince: IMonacoColumnDef = { name: "ADDRESS.CITY.PROVINCE.NAME", displayName: "ROUTE.STATE", width: 200 };
            const colAddressCountry: IMonacoColumnDef = { name: "ADDRESS.CITY.PROVINCE.COUNTRY.NAME", displayName: "ROUTE.COUNTRY", width: 200 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 100, cellFilter: "YesOrNo" };
            const colIntegrationId: IMonacoColumnDef = { name: "INTEGRATION_ID", displayName: "GENERAL.INTEGRATION_ID", width: 100 };
            const colEmail: IMonacoColumnDef = { name: "EMAIL", displayName: "ENTITY.EMAIL", width: 100 };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "GENERAL.ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colDocumentError: IMonacoColumnDef = { name: "DOCUMENT_ERROR", displayName: "REGISTRATION.INTEGRATION_ERROR", width: 200, cellTemplate: "<div class='grid-padding' >{{ grid.appScope.getCONCAT(row.entity.DOCUMENT_ERROR, null, 'REASON', null, true) }}</div>" };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'TYPE':
                        columnDefs.push(colTypeId);
                        break;
                    case 'ID_FISCAL':
                        columnDefs.push(colIdFiscal);
                        break;
                    case 'NAME':
                        columnDefs.push(colName);
                        break;
                    case 'SPEC':
                        columnDefs.push(colSpec);
                        break;
                    case 'SECTOR':
                        columnDefs.push(colSector);
                        break;
                    case 'PERSON_ROLE':
                        columnDefs.push(colPersonRole);
                        break;
                    case 'ADDRESS.CITY':
                        columnDefs.push(colAddressCity);
                        break;
                    case 'ADDRESS.CITY.PROVINCE':
                        columnDefs.push(colAddressProvince);
                        break;
                    case 'ADDRESS.CITY.PROVINCE.COUNTRY':
                        columnDefs.push(colAddressCountry);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'INTEGRATION_ID':
                        columnDefs.push(colIntegrationId);
                        break;
                    case 'EMAIL':
                        columnDefs.push(colEmail);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                    case 'DOCUMENT_ERROR':
                        columnDefs.push(colDocumentError);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            ACTIVE: true,
            TYPE: null,
            ID_FISCAL: null,
            NAME: null,
            SPEC: null,
            SECTOR: null,
            PERSON_ROLE: null,
            PHONE: null,
            PHONE_COUNTRY_CODE: null,
            ADDRESS: { ZIP_CODE: null, COMPLEMENT: null, NUMBER: null, PUBLIC_PLACE: null, DISTRICT: null, ID_CITY: null, CITY: null, CORRESPONDENCE: null },
            CURRENT_ACCOUNT: [],
            OBSERVATION: null,
            INTEGRATION_ID: null,
            EMAIL: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            SEARCH_FIELDS: null,
            DOCUMENT_ERROR: null,
            ERP_INTEGRATION_PARAM: null
        };
    }

    initDependencies(): Promise<any> {
        const self: PhysicalPersonRegisterController = this;

        angular.element('#idFiscalMask')['mask']('000.000.000-00');
        this.initPhoneComponent();

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getTaxIdTypeList(),
                self.getSpecTypeList(),
                self.getSpecSituationList(),
                self.getGenericList('pix_type'),
            ]).then((result: any) => {
                self.$scope.taxIdTypeList = result[0];
                self.$scope.specTypeList = result[1];
                self.$scope.specSituationList = result[2];
                self.$scope.pixTypeList = result[3];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getGenericList(type: string, alternative: boolean = false): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}/${alternative}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private initPhoneComponent(): void {
        const self: PhysicalPersonRegisterController = this;

        this.destroyPhoneComponent();

        const inputPhone = document.querySelector("#phone");
        self.intlPhone = intlTelInput(inputPhone, {
            utilsScript: "../../../../node_modules/intl-tel-input/build/js/utils.js",
            autoPlaceholder: "aggressive",
            formatOnDisplay: true,
            separateDialCode: true,
            initialCountry: "br"
        });

        if (!self.$scope.model.PHONE_COUNTRY_CODE) self.$scope.model.PHONE_COUNTRY_CODE = "br";
        self.intlPhone.setCountry(self.$scope.model.PHONE_COUNTRY_CODE);

        this.countryChangeListener = () => {
            self.$scope.model.PHONE = null;
            self.$scope.model.PHONE_COUNTRY_CODE = self.intlPhone.getSelectedCountryData().iso2;
            self.intlPhone.setNumber("");
            self.$scope.validityPhone("phone");
            self.$scope.$apply();
        };
        inputPhone.addEventListener("countrychange", this.countryChangeListener, false);
    }

    private destroyPhoneComponent(): void {
        const inputPhone = document.querySelector("#phone");
        if (inputPhone) inputPhone.removeEventListener("countrychange", this.countryChangeListener, false);
        if (this.intlPhone) this.intlPhone.destroy();
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlDataManager = this.config.productUrl + baseRoute;
        return urlDataManager;
    }

    private getMenuFloatingDefault(): IFloatingMenu {
        return {
            tooltipPlacement: "auto bottom",
            textTooltip: "{{ 'GENERAL.MENU.PHYSICAL_PERSON' | translate }}",
            infos: [
                { text: "GENERAL.MENU.PHYSICAL_PERSON", class: "text-rouge font-bold" }
            ],
            options: [
                { click: "collapseHeader", args: ['collapseBasicData'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.BASIC_DATA", iconClass: "fa fa-address-card", iconBodyClass: "text-brown" },
                { click: "collapseHeader", args: ['collapseAddress'], tooltipPlacement: "auto bottom", textTooltip: "ENTITY.ADDRESS", iconClass: "fa fa-address-book-o", iconBodyClass: "text-rouge" },
                { click: "collapseHeader", args: ['collapseCurrentAccount'], tooltipPlacement: "auto bottom", textTooltip: "ENTITY.CHECKING_ACCOUNT", iconClass: "fa fa-credit-card", iconBodyClass: "text-red" },
                { click: "collapseHeader", args: ['collapseAll'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.COLLAPSE_EXPAND_ALL", iconClass: "fa fa-expand", iconBodyClass: "text-danger" }
            ],
            btnActiveDisabled: false
        };
    }

    private buildErrorSankhyaTooltip(errors: IDocumentError[]): string {
        let tooltip = "";
        if (errors && errors.length) {
            const errorsSankhya = errors.filter(e => e.SUBJECT.ID == "1" || e.SUBJECT.ID == "2" || e.SUBJECT.ID == "3");
            if (errorsSankhya) {
                tooltip += "<div align=\"left\">";
                for (const reason of errorsSankhya) {
                    tooltip += `DATA: ${reason.DATE} <br>`;
                    tooltip += `ASSUNTO: ${reason.SUBJECT.NAME} <br>`;
                    tooltip += `RAZÃO: ${reason.REASON} <br><br>`;
                }
                tooltip += "</div>";

            }

        }
        return tooltip;
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            { PROPERTY: "ACTIVE", LABEL: "GENERAL.ACTIVE" },
            { PROPERTY: "TYPE", LABEL: "ENTITY.TAX_TYPE" },
            { PROPERTY: "ID_FISCAL", LABEL: "ENTITY.TAX_NUMBER" },
            { PROPERTY: "NAME", LABEL: "GENERAL.NAME" },
            { PROPERTY: "SPECIALTY", LABEL: "ENTITY.ENTITY_TYPE" },
            { PROPERTY: "SECTOR", LABEL: "ENTITY.SECTOR" },
            { PROPERTY: "PERSON_ROLE", LABEL: "ENTITY.JOB_TITLE" },
            { PROPERTY: "PHONE", LABEL: "ENTITY.PHONE" },
            { PROPERTY: "ADDRESS", LABEL: "ENTITY.ADDRESS" },
            { PROPERTY: "ZIP_CODE", LABEL: "ENTITY.ZIP_CODE" },
            { PROPERTY: "COMPLEMENT", LABEL: "ENTITY.ADDRESS_COMPLEMENT" },
            { PROPERTY: "COUNTRY", LABEL: "ROUTE.COUNTRY" },
            { PROPERTY: "CITY", LABEL: "ROUTE.CITY" },
            { PROPERTY: "DISTRICT", LABEL: "ENTITY.DISTRICT" },
            { PROPERTY: "NUMBER", LABEL: "GENERAL.NUMBER" },
            { PROPERTY: "PUBLIC_PLACE", LABEL: "ENTITY.ADDRESS" },
            { PROPERTY: "PROVINCE", LABEL: "ROUTE.STATE" },
            { PROPERTY: "CORRESPONDENCE", LABEL: "ENTITY.POSTAL_ADDRESS" },
            { PROPERTY: "MAIN", LABEL: "GENERAL.MAIN" },
            { PROPERTY: "CURRENCY", LABEL: "GENERAL.CURRENCY" },
            { PROPERTY: "HOLDER_NAME", LABEL: "GENERAL.HOLDER" },
            { PROPERTY: "BANK", LABEL: "ENTITY.BANK" },
            { PROPERTY: "AGENCY", LABEL: "ENTITY.BANK_AGENCY" },
            { PROPERTY: "ACCOUNT_CODE", LABEL: "ENTITY.ACCOUNT_NUMBER" },
            { PROPERTY: "ACCOUNT_DV", LABEL: "DV" },
            { PROPERTY: "OBSERVATION", LABEL: "GENERAL.REMARKS" },
            { PROPERTY: "ID_INTEGRATION", LABEL: "GENERAL.INTEGRATION_ID" },
            { PROPERTY: "CURRENT_ACCOUNT", LABEL: "ENTITY.CHECKING_ACCOUNT" },
            { PROPERTY: "ID", LABEL: "GENERAL.ID" },
            { PROPERTY: "SPEC", LABEL: "ENTITY.COMPANY_TYPE" },
            { PROPERTY: "INTEGRATION_ID", LABEL: "GENERAL.INTEGRATION_ID" },
            { PROPERTY: "EMAIL", LABEL: "ENTITY.EMAIL" },
            { PROPERTY: "PHONE_COUNTRY_CODE", LABEL: "ENTITY.PHONE" },
            { PROPERTY: 'CREATED_AT', LABEL: 'GENERAL.CREATED_AT' },
            { PROPERTY: 'CREATED_BY', LABEL: 'GENERAL.CREATED_BY' },
            { PROPERTY: 'UPDATED_AT', LABEL: 'GENERAL.UPDATED_AT' },
            { PROPERTY: 'UPDATED_BY', LABEL: 'GENERAL.UPDATED_BY' },
            { PROPERTY: 'CODE', LABEL: 'GENERAL.CODE' },
        ]
        return props;
    }

    private async getCityListByNameOrInitials(search: string, fullName: boolean = false): Promise<ICitySelectorModel[]> {
        let result = [];
        try {
            this.formService.block();

            const cities = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/city/list/custom`, { name: search, fullName }, 30000, false);
            if (cities) result = cities.map(city => { return { ID: city.ID, NAME: city.NAME, CODE: city.INITIALS, PROVINCE: { ID: city.PROVINCE.ID, NAME: city.PROVINCE.NAME, CODE: city.PROVINCE.CODE, COUNTRY: { ID: city.PROVINCE.COUNTRY.ID, NAME: city.PROVINCE.COUNTRY.NAME, CODE: city.PROVINCE.COUNTRY.CODE } } } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCountryListByNameOrInitials(search?: string): Promise<SelectorModel[]> {
        let result = [];
        try {
            this.formService.block();

            const countries = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/country/list/custom`, { search }, 30000, false);
            if (countries) result = countries.map(country => { return { ID: country.ID, NAME: country.NAME, CODE: country.INITIALS } });

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getBankListByName(name?: string): Promise<SelectorModel[]> {
        let result = [];
        try {
            this.formService.block();

            const banks = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/bank/list/custom`, { name }, 30000, false);
            if (banks) result = banks.map(bank => { return { ID: bank.ID, NAME: bank.NAME, CODE: bank.CODE } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getSectorListByName(name?: string): Promise<SelectorModel[]> {
        let result = [];
        try {
            this.formService.block();

            const sectors = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/sector/list/custom`, { name }, 30000, false);
            if (sectors) result = sectors.map(sector => { return { ID: sector.ID, NAME: sector.DISPLAY_NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getPersonRoleListByName(name?: string): Promise<SelectorModel[]> {
        let result = [];
        try {
            this.formService.block();
            const personRoles = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/personrole/list/custom`, { search: name }, 30000, false);
            if (personRoles) result = personRoles.map(personRole => { return { ID: personRole.ID, NAME: personRole.DISPLAY_NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getTaxIdTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/physical_person_tax_id_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getSpecTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/specialization_physical_person`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getSpecSituationList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/specialization_situation`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getZipCodeApi(zipCode: string): Promise<any> {
        if (!zipCode) return this.formService.handleError('ZipCode is null.');
        try {
            const zipCodeFormatted = zipCode.replace(/\./g, '').replace(/-/g, '');
            return this.ExternalService.get({ route: "/zipCode/" + zipCodeFormatted });
        } catch (ex) {
            this.$formService.notifyError(this.formService.getTranslate("REGISTRATION.ZIP_CODE_VALIDATION"));
        }
    }

    private collapseHeader(elementId: string, state?: string): void {
        if (elementId === "collapseAll") {
            this.collapseState = this.collapseState == "hide" ? "show" : "hide";
            $('.toggle-me')["collapse"](state ? state : this.collapseState);
        } else if (elementId != "registerBody") {
            $("#" + elementId)["collapse"](state ? state : 'toggle');
        }
        this.$scope.navigateBetweenIds(elementId);
    }

    private async getZipCode(zipCode: string): Promise<void> {
        if (zipCode) {
            const hasInformationToBeReplaced = [this.$scope.model.ADDRESS.PUBLIC_PLACE, this.$scope.model.ADDRESS.DISTRICT].some(field => field !== null && field !== "");
            const approved = hasInformationToBeReplaced ? await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.YES",
                headerText: "REGISTRATION.ACTUAL_DATA_REPLACEMENT",
                bodyText: `${this.formService.getTranslate("GENERAL.CONTINUE_QUESTION")}`
            }) : true;
            if (approved) {
                this.$formService.block();
                try {
                    const rc = await this.getZipCodeApi(zipCode);
                    if (rc && rc.data) {
                        this.$formService.notifySuccess('REGISTRATION.ZIP_CODE_FOUND');
                        const zipCodeResult: IZipCodeResult = rc.data.data;
                        this.$scope.model.ADDRESS.CITY = null;
                        this.$scope.model.ADDRESS.PUBLIC_PLACE = zipCodeResult.logradouro;
                        this.$scope.model.ADDRESS.DISTRICT = zipCodeResult.bairro;
                        const city: ICity[] = await this.getCityListByNameOrInitials(zipCodeResult.localidade, true);
                        if (city && city.length === 1) {
                            this.$scope.model.ADDRESS.CITY = city[0];
                        } else this.formService.notifyWarning("REGISTRATION.CITY_NOT_FOUND");
                        this.$timeout(() => {
                            this.$scope.selectorValidity('addressCity');
                            this.$scope.selectorValidity('addressProvince');
                            this.$scope.selectorValidity('addressCountry');
                        });
                    }
                } catch (ex) {
                    this.$formService.unblock();
                    if (ex && ex.data && ex.data.message && ex.data.message === "Not Found") {
                        this.$formService.notifyError('REGISTRATION.ZIP_CODE_NOT_FOUND');
                    } else {
                        this.$formService.notifyError(this.formService.getTranslate("REGISTRATION.ZIP_CODE_VALIDATION"));
                    }
                }
                this.$scope.$applyAsync();
                this.$formService.unblock();
            }
        }
    }

    private async addCurrentAccountModal(): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();

            this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    scope: this.$scope,
                    formService: 'register',
                    template: require("../view/modals/currentAccountModal.html"),
                    size: 'lg modal-overflow'
                },
                {
                    actionButtonText: "GENERAL.CLOSE",
                    headerText: "ENTITY.CHECKING_ACCOUNT"
                });

            const currentAccount: ICurrentAccount = {
                COUNTRY: null,
                MAIN: !this.$scope.model.CURRENT_ACCOUNT || (this.$scope.model.CURRENT_ACCOUNT && this.$scope.model.CURRENT_ACCOUNT.length === 0),
                CURRENCY: null,
                HOLDER_NAME: this.$scope.model.NAME,
                BANK: null,
                AGENCY: null,
                ACCOUNT_CODE: null,
                ACCOUNT_DV: null,
                CORRESPONDENCE: null,
                INTEGRATION_ID: null,
                PIX: null,
                PIX_KEY: null,
                SWIFT: null,
                IBAN: null,
                OBSERVATION: null,
                ACTIVE: true,
                ID: null,
            }

            await this.buildCurrentAccountModalScope(0, currentAccount);

            $('#agencyCode')['mask']('0000');
            $('#agencyDV')['mask']('0');
            $('#accountCode')['mask']('00000000000000000');
            $('#accountDV')['mask']('0');

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async editCurrentAccount(index: number, currentAccount: ICurrentAccount): Promise<void> {
        if (!index && index != 0) return this.formService.handleError('Index is null.');
        if (!currentAccount) return this.formService.handleError('CurrentAccount is null.');
        try {
            this.modalID = this.ModalService.newModal();

            this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    scope: this.$scope,
                    formService: 'edit',
                    template: require("../view/modals/currentAccountModal.html"),
                    size: 'lg modal-overflow'
                },
                {
                    actionButtonText: "GENERAL.CLOSE",
                    headerText: "ENTITY.CHECKING_ACCOUNT"
                }
            );

            await this.buildCurrentAccountModalScope(index, currentAccount);

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async buildCurrentAccountModalScope(index: number, currentAccount: ICurrentAccount): Promise<void> {
        if (!index && index != 0) return this.formService.handleError('Index is null.');
        if (!currentAccount) return this.formService.handleError('CurrentAccount is null.');

        try {
            const modalScope: ICurrentAccountModal = await this.ModalService.getModalScope(this.modalID);

            modalScope.currentAccount = angular.copy(currentAccount);

            modalScope.applyCurrentAccount = () => {
                this.applyCurrentAccount(modalScope.currentAccount, modalScope.operation, index);
            };

            modalScope.getCountryListByNameOrInitials = async (search: string) => {
                let countryList: SelectorModel[] = [];
                if (search && search.length >= 2) {
                    countryList = await this.getCountryListByNameOrInitials(search);
                }
                modalScope.countryList = countryList;
            };

            modalScope.getBankListByName = async (search: string) => {
                let bankList: SelectorModel[] = [];
                if (search && search.length >= 3) {
                    bankList = await this.getBankListByName(search);
                }
                modalScope.bankList = bankList;
            };

            modalScope.getCurrencyListByNameOrInitials = async (search: string) => {
                modalScope.currencyList = await this.getCurrencyListByNameOrInitials(search);
            };

            modalScope.goToCountry = (id: number) => {
                this.sessionService.openTab("app.registration.country", <ILinkParameter>{ ID: id ? id.toString() : id });
            }

            modalScope.goToBank = (id: number) => {
                this.sessionService.openTab("app.registration.bank", <ILinkParameter>{ ID: id ? id.toString() : id });
            };

            modalScope.goToCurrency = (id: number) => {
                this.sessionService.openTab("app.registration.currency", <ILinkParameter>{ ID: id ? id.toString() : id });
            };

            modalScope.limitLines = (value: string, limit: number) => {
                return StringUtil.limitLines(value, limit);
            };

            modalScope.verifySwiftField = (country: SelectorModel) => {
                if (country.ID == '1') {
                    modalScope.currentAccount.SWIFT = null;
                }
            };

            modalScope.changePIX = () => {
                this.$timeout(() => {
                    modalScope.selectorValidity('bank');
                })
            };

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private applyCurrentAccount(currentAccount: ICurrentAccount, operation?: string, index?: number): void {
        if (!currentAccount) return this.formService.handleError('CurrentAccount is null.');
        try {
            if (operation === "register") {

                this.$scope.model.CURRENT_ACCOUNT ? this.$scope.model.CURRENT_ACCOUNT.push(currentAccount) : this.$scope.model.CURRENT_ACCOUNT = [currentAccount];

            } else {
                this.$scope.model.CURRENT_ACCOUNT.splice(index, 1, currentAccount);
            }

            this.ModalService.closeModal(this.modalID);
            this.modalID = 0;
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getCurrencyListByNameOrInitials(search: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            if (search && search.length >= 3) {
                const currencies = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/currency/list/custom`, { name: search }, 30000, false);
                if (currencies) result = currencies.map(currency => { return { ID: currency.ID, NAME: currency.NAME, CODE: currency.INITIALS } });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async removeCurrentAccount(index: number): Promise<void> {
        try {
            if (index || index === 0) {
                const modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: "GENERAL.CONFIRM",
                    headerText: "GENERAL.CONFIRM_ACTION",
                    bodyText: `${this.formService.getTranslate("REGISTRATION.REMOVE_CURRENT_ACCOUNT_QUESTION")}`
                });

                if (!modal) return;

                this.formService.block();

                this.$scope.model.CURRENT_ACCOUNT.splice(index, 1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    };

    private sendSync = async (id: number, idSubject: string): Promise<boolean> => {
        let sendSync = false;
        let syncAccountCompiled = false;
        let success = false;
        try {
            if (id) {
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/physicalPerson/sendSync`, { "idPhysicalPerson": id, "idSubject": idSubject }, 120000, false);
                if (syncRequest) sendSync = true;

                const rc = await this.dataProductService.post(`/sync/currentAccountCompiled`, { "idPhysicalPerson": [id] }, 120000);
                if (rc && rc.data && rc.data.data && rc.status == 200) syncAccountCompiled = true;

                success = (sendSync && syncAccountCompiled);
            }
        } catch (ex) {
            this.formService.handleError('REGISTRATION.MESSAGE.ERROR.ERROR_SENDING_REQUEST');
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const physicalPersonData = await this.getPhysicalPersonById(id);
                    if (row && physicalPersonData && physicalPersonData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = physicalPersonData.DOCUMENT_ERROR;
                        documentError = physicalPersonData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async getPhysicalPersonById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/physicalPerson/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "Integration Product/Operation" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async integrateSankhya(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            if (!this.$scope.model.ERP_INTEGRATION_PARAM || this.$scope.model.DOCUMENT_ERROR) {
                const result = await this.dataProductService.post(`/sync/sankhya/physicalPerson`, { "id": [id] }, 120000)
                if (result.data) this.formService.notifySuccess("REGISTRATION.SANKHYA_RECORD_INTEGRATION_WARNING ");
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async integrateSankhyaStatus(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            if (this.$scope.model.ERP_INTEGRATION_PARAM && (!this.$scope.model.ERP_INTEGRATION_PARAM.ERP_STATUS || (this.$scope.model.ERP_INTEGRATION_PARAM.ERP_STATUS && this.$scope.model.ERP_INTEGRATION_PARAM.ERP_STATUS.ID == "N"))) {
                const result = await this.dataProductService.post(`/sync/sankhya/physicalPerson/status`, { "id": [id] }, 120000)
                if (result.data) this.formService.notifySuccess("REGISTRATION.SANKHYA_RECORD_INTEGRATION_WARNING ");
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.initPanels();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW")}`;
            this.SSEService.closeEvents();
            const taxIdType = this.$scope.taxIdTypeList.find(tx => tx.ID == EPhysicalPersonTaxIdType.CPF);
            if (taxIdType) this.$scope.model.TYPE = taxIdType;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.disableElements(true);
            BrowserTitle.$id = this.$scope.model.NAME;
            this.$scope.initPanels();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.SSEService.closeEvents();
            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [
                    { text: this.$scope.model.NAME + " - " + this.$scope.model.TYPE.NAME + ": " + this.$scope.model.ID_FISCAL, class: "text-rouge font-bold" }
                ];
            }
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")} (${this.$scope.model.NAME})`;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            BrowserTitle.$id = this.$scope.model.NAME;
            this.$scope.initPanels();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [
                    { text: this.$scope.model.NAME + " - " + this.$scope.model.TYPE.NAME + ": " + this.$scope.model.ID_FISCAL, class: "text-rouge font-bold" }
                ];
            }
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")} (${this.$scope.model.NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }

        return true;
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
        this.$scope.scopeBeforeSave = angular.copy(this.$scope.model)
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === "edit" ? "update" : "insert";

        return {
            route: `/physicalPerson/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: this.$scope.scopeBeforeSave
        };
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/physicalPerson/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }
}

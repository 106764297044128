import { IUiGridConstants } from "ui-grid";
import { IMonacoColumnDef } from "@services/GridService2";

MaritimeTravelModel.$inject = ['RestService', 'GridService', 'uiGridConstants', 'FormService'];
export function MaritimeTravelModel(RestService, GridService, uiGridConstants: IUiGridConstants, FormService) {

  let MaritimeTravelModel: any = {
    _id: null,
    ID: null,
    FEEDER: false,
    MARITIMETRAVEL_NUMBER: null,
    SHIP: [],
    OPERATION: null,
    SERVICE: null,
    STOPOVER: null,
  };

  /**
   * @returns {array} - Lista de Viagens Marítimas
   */
  MaritimeTravelModel.getList = function () {
    return RestService.getObjectAsPromise("/maritimeTravel/list", 10000);
  };

  MaritimeTravelModel.findById = function (_id) {
    if (typeof _id !== 'string' || _id.length !== 24) throw `Invalid _id value: ${_id}`;
    return RestService.getObjectAsPromise("/maritimeTravel/id/" + _id, 10000);
  };



  //grid
  MaritimeTravelModel.getColumns = function (columns) {

    let columnDefs: IMonacoColumnDef[] = [];

    //action column, contain just buttons and icons
    const view =
      '<a ng-click="grid.appScope.view(row.entity._id)" class="text-info"tooltip-placement="auto top" uib-tooltip="Visualizar" tooltip-append-to-body="true" ><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;';
    const edit =
      '<a ng-click="grid.appScope.edit(row.entity._id)" class="text-especial" tooltip-placement="auto top" uib-tooltip="Editar" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;';
    const copy =
      '<a ng-click="grid.appScope.copy(row.entity._id)" class="text-orange" tooltip-placement="auto top" uib-tooltip="Copiar" tooltip-append-to-body="true" ><i class="fa fa-copy"></i></a>&nbsp;&nbsp;';
    const viewLog =
      '<a ng-click="grid.appScope.viewLog(row.entity.MARITIMETRAVEL_NUMBER)" class="text-green"   tooltip-placement="auto top" uib-tooltip="Histórico" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;';

    columnDefs.push({
      name: "acoes",
      displayName: "Ações",
      width: "88",
      enableFiltering: false,
      cellTemplate:
        '<div class="text-center">' + view + viewLog + copy + edit + "</div>",
      enableSorting: false,
      enableHiding: false,
      enableColumnMoving: false,
      enableColumnResizing: false,
      pinnedLeft: true,
      enablePinning: false
    });

    for (let i = 0; i < columns.length; i++) {
      let name = null;
      let displayName = null;
      let width = 0;
      let cellTemplate = undefined;
      let cellFilter = null;
      let grouping = null;
      let sort = null;
      let filter = { condition: GridService.filterSelectObject };
      let pinned = null;
      let visible = true;
      let searchProps = null;

      let travel = columns[i];

      switch (travel.toUpperCase()) {
        case "MARITIMETRAVEL_NUMBER":
          name = travel;
          displayName = "Código";
          cellTemplate = '<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="Clique para visualizar as Escalas Marítimas" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToStopOver(row.entity.MARITIMETRAVEL_NUMBER)">{{row.entity.MARITIMETRAVEL_NUMBER}}</a></div>';
          break;
        case "SHIP":
          name = `${travel}[0].SHIP.NAME`;
          displayName = "Navio Principal";
          searchProps = [`SHIP.0.SHIP.NAME`];
          //cellFilter = "master";
          break;
        case "OPERATION":
          name = `${travel}.NAME`;
          displayName = "Operação";
          break;
        case "SERVICE":
          name = `${travel}.NAME`;
          displayName = "Serviço";
          break;
        case "FEEDER":
          name = travel;
          displayName = "Feeder";
          cellFilter = 'YesOrNo';
          break;
        default:
          continue;
        /*name = travel;
        visible = false;
        break;*/
      }

      columnDefs.push({
        name: name,
        displayName: displayName,
        filter: filter,
        headerCellClass: GridService.highlightFilteredHeader,
        width: "*",
        cellTemplate: cellTemplate,
        cellFilter: cellFilter,
        visible: visible,
        searchProps: searchProps
      });
    }

    return columnDefs;
  };

  //mutantes e acessores
  MaritimeTravelModel.setCode = function (code) {
    this.code = parseInt(code);

    return this;
  };

  return MaritimeTravelModel;
}

import { IColumnDef } from "ui-grid";
import { IMonacoColumnDef } from "@services/GridService2";
import { IGridFormServiceScope, GridFormService, IGridFormController } from "@services/GridFormService";
import { ISessionService } from "@services/SessionService";
import { IRestService } from "@services/RestService";
import { IModalService } from "@services/ModalService";
import { ITariffFreightExchangeData } from "@models/interface/product/TariffFreightModel";
import { ISelectorModel } from "@models/mongo/SelectorModel";
import { IFreightRatesManagementCharge } from "@models/interface/product/FreightRatesManagementCharge";
import { EFreightRatesManagementChargeType, ECargoTypeId } from "@enums/GenericData";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { ILinkParameter, IOfferParameter } from "../../common/model/ModelParameter";
import { IOfferExchangeData } from "../model/OfferModel";
import { EOperation } from "@enums/GenericData";

interface IBreakdownEquipment {
    equipment: ISelectorModel;
    rates: IFreightRatesManagementCharge[];
    conversionTotal: number;
}

interface IBreakdownAdditional {
    rates: IFreightRatesManagementCharge[];
    conversionTotal: number;
}

interface ITariffFreightFclScope extends IGridFormServiceScope {
    openChargeBreakdown: (_id: string) => Promise<void>;
    goToTariffFreightContract: (contractId: number, tariffId: number) => void;
    goToOfferWizard: (contractId: number, tariffId?: number) => void;
    breakdownEquipments: IBreakdownEquipment[];
    breakdownAdditionals: IBreakdownAdditional;
}

export class TariffFreightFclController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: ITariffFreightFclScope;
    private $q: ng.IQService;
    private modalService: IModalService;
    private restService: IRestService;
    private sessionService: ISessionService;
    private dynamicGridColumns: IMonacoColumnDef[];

    constructor($injector: ng.Injectable<any>, $scope: ITariffFreightFclScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.modalService = $injector.get('ModalService');
        this.restService = $injector.get('RestService');
        this.sessionService = $injector.get('SessionService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.initForm(this, 'form', 'tariffFreight', 'GENERAL.MENU.TARIFF_FREIGHT_FCL', false);

            // Set grid as multiple select;
            this.$gridService.setSelectable(true);
            this.formService.hideAddButton();
            this.dynamicGridColumns = await this.getDynamicGridColumns();
            await this.initGrid("GRID_TARIFF_FREIGHT_FCL", `/freightRatesManagement/list/${ECargoTypeId.FCL}`, true, true, 120000, true, true, `${this.getUrlProduct()}/freightRatesManagement/generateReport`);
        } catch (ex) {
            throw ex;
        }
    }

    private async getDynamicGridColumns(): Promise<IMonacoColumnDef[]> {
        const dynamicColumns: IMonacoColumnDef[] = [];
        try {
            this.block();
            const request = await this.restService.getObjectAsPromise(`${this.getUrlProduct()}/equipment/freightRatesManagement`, 30000, null, false);
            if (request && request.data) {
                const columns: ISelectorModel[] = request.data;
                for (const column of columns) {
                    dynamicColumns.push({ name: "EQUIPMENT_" + column.ID + ".VALUE", displayName: column.CODE, width: 100, cellFilter: "number:2", excelDef: { typeExcel: 'currency', sum: true } });
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return dynamicColumns;
        }
    }

    initModel(): void {
    }

    initGridColumns(columns: string[]): IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<a ng-click="$event.preventDefault(); $event.stopPropagation(); grid.appScope.openChargeBreakdown(row.entity._id)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true"><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="$event.preventDefault(); $event.stopPropagation(); grid.appScope.goToTariffFreightContract(row.entity.ID_TARIFF_FREIGHT_CONTRACT, row.entity.ID_TARIFF_FREIGHT)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true"><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const offer = `<a ng-click="$event.preventDefault(); $event.stopPropagation(); grid.appScope.goToOfferWizard(row.entity.ID_TARIFF_FREIGHT_CONTRACT, row.entity.ID_TARIFF_FREIGHT)" class="text-green edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'PRODUCT.NEW_OFFER' | translate }}" tooltip-append-to-body="true"><i class="fa fa-handshake-o icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes", displayName: "GENERAL.ACTIONS",
            width: 85,
            cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${offer} </div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };

        gridColumns.addColumn(colActions);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colTariffConcat: IMonacoColumnDef = { name: "TARIFF_CONCAT", displayName: "PRODUCT.FREIGHT_TARIFF_CONCATENATED", width: 350 };
            const colRouteConcat: IMonacoColumnDef = { name: "ROUTE_CONCAT", displayName: "PRODUCT.FREIGHT_TARIFF_ROUTE_CONCATENATED", width: 350 };
            const colContract: IMonacoColumnDef = { name: "CONTRACT.NAME", displayName: "BASIC_DATA.FREIGHT_CONTRACT", width: 150 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 100 };
            const colTransaction: IMonacoColumnDef = { name: "TRANSACTION.NAME", displayName: "BASIC_DATA.TRANSACTION", width: 150 };
            const colCarrier: IMonacoColumnDef = { name: "CARRIER.NAME", displayName: "BASIC_DATA.PROVIDER", width: 150 };
            const colAccount: IMonacoColumnDef = { name: "ACCOUNT.NAME", displayName: "BASIC_DATA.CLIENT", width: 150 };
            const colAccountFantasyName: IMonacoColumnDef = { name: "ACCOUNT.LEGAL_PERSON.FANTASY_NAME", displayName: "GENERAL.CUSTOMER_COMMERCIAL_NAME", width: 250 };
            const colNetwork: IMonacoColumnDef = { name: "NETWORK.NAME", displayName: "BASIC_DATA.EXTERNAL_AGENT_NETWORK", width: 150 };
            const colMoveType: IMonacoColumnDef = { name: "MOVE_TYPE.CODE", displayName: "GENERAL.MENU.MOVE_TYPE", width: 150 };
            const colOriginTrade: IMonacoColumnDef = { name: "ORIGIN_TRADE.NAME", displayName: "BASIC_DATA.ORIGIN_TRADE_GROUP", width: 150 };
            const colOriginCountry: IMonacoColumnDef = { name: "ORIGIN_COUNTRY.NAME", displayName: "BASIC_DATA.ORIGIN_COUNTRY", width: 150 };
            const colOrigin: IMonacoColumnDef = { name: "ORIGIN.ROUTING_POINT.DISPLAY_NAME", displayName: "BASIC_DATA.ORIGIN", width: 150 };
            const colPUP: IMonacoColumnDef = { name: "PUP.ROUTING_POINT.DISPLAY_NAME", displayName: "GENERAL.PICK_UP_CODE", width: 150 };
            const colOTFS: IMonacoColumnDef = { name: "OTFS.ROUTING_POINT.DISPLAY_NAME", displayName: "GENERAL.ORIGIN_TERMINAL_CODE", width: 150 };
            const colPOLAOL: IMonacoColumnDef = { name: "POLAOL.ROUTING_POINT.CODE", displayName: "GENERAL.LOADING_PLACE_CODE", width: 150 };
            const colPODAOD: IMonacoColumnDef = { name: "PODAOD.ROUTING_POINT.CODE", displayName: "GENERAL.UNLOADING_PLACE_CODE", width: 150 };
            const colDTFS: IMonacoColumnDef = { name: "DTFS.ROUTING_POINT.DISPLAY_NAME", displayName: "GENERAL.DESTINATION_TERMINAL_CODE", width: 150 };
            const colPLD: IMonacoColumnDef = { name: "PLD.ROUTING_POINT.DISPLAY_NAME", displayName: "GENERAL.PLACE_OF_DELIVERY_CODE", width: 150 };
            const colDestinationTrade: IMonacoColumnDef = { name: "DESTINATION_TRADE.NAME", displayName: "BASIC_DATA.DESTINATION_TRADE_GROUP", width: 150 };
            const colDestinationCountry: IMonacoColumnDef = { name: "DESTINATION_COUNTRY.NAME", displayName: "BASIC_DATA.DESTINATION_COUNTRY", width: 150 };
            const colDestination: IMonacoColumnDef = { name: "DESTINATION.ROUTING_POINT.DISPLAY_NAME", displayName: "BASIC_DATA.DESTINATION", width: 150 };
            const colCommodity: IMonacoColumnDef = { name: "COMMODITY_COMPILED", displayName: "GENERAL.COMMODITY", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.COMMODITY_COMPILED, null, null, null, null, null, true)}}</div>' };
            const colRate: IMonacoColumnDef = { name: "RATE.NAME", displayName: "BASIC_DATA.CHARGE", width: 150 };
            const colCurrency: IMonacoColumnDef = { name: "CURRENCY.CODE", displayName: "GENERAL.CURRENCY", width: 130 };
            const colAdditionals: IMonacoColumnDef = { name: "ADDITIONALS_COMPILED", displayName: "PRODUCT.ADDITIONAL_CHARGES", width: 150 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 130, cellFilter: "simpleDate", excelDef: { typeExcel: 'datetime' } };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 130, cellFilter: "simpleDate", excelDef: { typeExcel: 'datetime' } };
            const colService: IMonacoColumnDef = { name: "SERVICE.NAME", displayName: "PRODUCT.MARITIME_SERVICE", width: 150 };
            const colTypeCargo: IMonacoColumnDef = { name: "TYPE_CARGO.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 150 };
            const colBaseEvent: IMonacoColumnDef = { name: "BASE_EVENT.NAME", displayName: "GENERAL.VALIDITY_BASIS", width: 130 };
            const colFrom: IMonacoColumnDef = { name: "FROM", displayName: "PRODUCT.VALIDITY_START", width: 80, cellFilter: "simpleDate", excelDef: { typeExcel: 'datetime' } };
            const colTo: IMonacoColumnDef = { name: "TO", displayName: "PRODUCT.VALIDITY_END", width: 80, cellFilter: "simpleDate", excelDef: { typeExcel: 'datetime' } };
            const colContractType: IMonacoColumnDef = { name: "CONTRACT_TYPE.NAME", displayName: "PRODUCT.FREIGHT_CONTRACT_TYPE", width: 150 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 80, cellFilter: "YesOrNo", excelDef: { typeExcel: 'boolean'} };
            const colObservation: IMonacoColumnDef = { name: "OBSERVATION", displayName: "GENERAL.REMARKS", width: 300, cellTemplate: '<div class="grid-padding"><span style="max-width: 100%;" class="ellipsize" ellipsis-tooltip tooltip-placement="auto top" uib-tooltip="{{row.entity.OBSERVATION}}" tooltip-enable="true" tooltip-append-to-body="true">{{row.entity.OBSERVATION}}</span></div>' };
            const colId: IMonacoColumnDef = { name: "ID_TARIFF_FREIGHT_CONTRACT", displayName: "GENERAL.ID", width: 70, excelDef: { typeExcel: 'number' } };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'TARIFF_CONCAT':
                        columnDefs.push(colTariffConcat);
                        break;
                    case 'ROUTE_CONCAT':
                        columnDefs.push(colRouteConcat);
                        break;
                    case 'CONTRACT':
                        columnDefs.push(colContract);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'TRANSACTION':
                        columnDefs.push(colTransaction);
                        break;
                    case 'CARRIER':
                        columnDefs.push(colCarrier);
                        break;
                    case 'ACCOUNT':
                        columnDefs.push(colAccount);
                        break;
                    case 'ACCOUNT_FANTASY_NAME':
                        columnDefs.push(colAccountFantasyName);
                        break;
                    case 'NETWORK':
                        columnDefs.push(colNetwork);
                        break;
                    case 'MOVE_TYPE':
                        columnDefs.push(colMoveType);
                        break;
                    case 'ORIGIN_TRADE':
                        columnDefs.push(colOriginTrade);
                        break;
                    case 'ORIGIN_COUNTRY':
                        columnDefs.push(colOriginCountry);
                        break;
                    case 'ORIGIN':
                        columnDefs.push(colOrigin);
                        break;
                    case 'PUP':
                        columnDefs.push(colPUP);
                        break;
                    case 'OTFS':
                        columnDefs.push(colOTFS);
                        break;
                    case 'POLAOL':
                        columnDefs.push(colPOLAOL);
                        break;
                    case 'PODAOD':
                        columnDefs.push(colPODAOD);
                        break;
                    case 'DTFS':
                        columnDefs.push(colDTFS);
                        break;
                    case 'PLD':
                        columnDefs.push(colPLD);
                        break;
                    case 'DESTINATION_TRADE':
                        columnDefs.push(colDestinationTrade);
                        break;
                    case 'DESTINATION_COUNTRY':
                        columnDefs.push(colDestinationCountry);
                        break;
                    case 'DESTINATION':
                        columnDefs.push(colDestination);
                        break;
                    case 'COMMODITY_COMPILED':
                        columnDefs.push(colCommodity);
                        break;
                    case 'RATE':
                        columnDefs.push(colRate);
                        break;
                    case 'CURRENCY':
                        columnDefs.push(colCurrency);
                        if (this.dynamicGridColumns && this.dynamicGridColumns.length) for (const column of this.dynamicGridColumns) columnDefs.push(column);
                        break;
                    case 'ADDITIONALS':
                        columnDefs.push(colAdditionals);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                    case 'SERVICE':
                        columnDefs.push(colService);
                        break;
                    case 'TYPE_CARGO':
                        columnDefs.push(colTypeCargo);
                        break;
                    case 'BASE_EVENT':
                        columnDefs.push(colBaseEvent);
                        break;
                    case 'FROM':
                        columnDefs.push(colFrom);
                        break;
                    case 'TO':
                        columnDefs.push(colTo);
                        break;
                    case 'CONTRACT_TYPE':
                        columnDefs.push(colContractType);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'OBSERVATION':
                        columnDefs.push(colObservation);
                        break;
                    case 'ID_TARIFF_FREIGHT_CONTRACT':
                        columnDefs.push(colId);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    initDependencies(): Promise<any> {
        const self: TariffFreightFclController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([

            ]).then(async (result: any) => {
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    $onDestroy(): void {

    }

    initScopeFunctions(): void {

        this.$scope.openChargeBreakdown = async (_id: string) => {
            this.openChargeBreakdown(_id);
        }

        this.$scope.goToTariffFreightContract = (contractId: number, tariffId: number) => {
            if (contractId == null && tariffId == null) return;
            const endpoint = `${this.config.productUrl}/product/tariffFreight/getCacheById/${contractId}`;
            this.sessionService.openTabByValidity(endpoint, "app.product.tariffFreight", <ILinkParameter>{ ID: contractId ? contractId.toString() : contractId }, <ITariffFreightExchangeData>{ OPERATION: "edit", ID: contractId ? contractId.toString() : null, ID_TARIFF: tariffId });
        }

        this.$scope.goToOfferWizard = (contractId: number, tariffId?: number) => {
            if (contractId == null) throw Error("contractId is null");
            this.sessionService.openTab("app.commercial.offer", <IOfferParameter>{ CONCATENATED: "" }, <IOfferExchangeData>{ OPERATION: "new", ID_NEW_FREIGHT_CONTRACT: null, ID_TARIFF_FREIGHT_CONTRACT: contractId, ID_TARIFF: tariffId });
        }
    }

    private async openChargeBreakdown(_id: string) {
        if (!_id) return;
        try {
            this.block();
            const freightRatesManagementCharges: IFreightRatesManagementCharge[] = await this.restService.newObjectPromise(`${this.getUrlProduct()}/freightRatesManagementCharge/list`, { ID_FREIGHT_RATES_MANAGEMENT: _id, ID_TYPE_CARGO: ECargoTypeId.FCL }, 30000, false);
            const additionalRates = freightRatesManagementCharges.filter(freightRates => freightRates.TYPE && freightRates.TYPE == EFreightRatesManagementChargeType.ADDITIONAL);
            const additionalRatesWithConversion = additionalRates && additionalRates.length ? additionalRates.filter(rate => rate.CURRENCY_CONVERSION) : null;
            let additionalRatesConversionTotal = 0;
            if (additionalRatesWithConversion && additionalRatesWithConversion.length) for (const rate of additionalRatesWithConversion) { additionalRatesConversionTotal += rate.CURRENCY_CONVERSION.TOTAL; }
            if (freightRatesManagementCharges && freightRatesManagementCharges.length) {
                this.$scope.breakdownEquipments = this.buildBreakdownEquipment(freightRatesManagementCharges.filter(freightRates => freightRates.TYPE && freightRates.TYPE == EFreightRatesManagementChargeType.EQUIPMENT));
                this.$scope.breakdownAdditionals = { rates: additionalRates, conversionTotal: additionalRatesConversionTotal };
                this.modalService.showModalInfo(
                    {
                        scope: this.$scope,
                        formService: EOperation.VIEW,
                        template: require("../view/modals/tariffFreightFclManagementChargesModal.html"),
                        size: 'vlg'
                    },
                    {
                        closeButtonText: "GENERAL.CLOSE",
                        headerText: "GENERAL.DETAILS"
                    });
            } else this.formService.notifyInfo(this.formService.getTranslate("PRODUCT.MESSAGE.FREIGHT_RATES_MANAGEMENT_HAS_NO_CHARGES_TO_SHOW"));
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private buildBreakdownEquipment(equipmentRates: IFreightRatesManagementCharge[]): IBreakdownEquipment[] {
        const breakdownEquipment: IBreakdownEquipment[] = [];
        try {
            if (equipmentRates && equipmentRates.length) {
                for (const equipmentRate of equipmentRates) {
                    if (equipmentRate.EQUIPMENT_CHARGE) {
                        const breakdownEqpt = breakdownEquipment.find(equipment => equipment.equipment.ID == equipmentRate.EQUIPMENT_CHARGE.ID);
                        if (breakdownEqpt) {
                            breakdownEqpt.rates.push(equipmentRate);
                            if (equipmentRate.CURRENCY_CONVERSION) breakdownEqpt.conversionTotal += equipmentRate.CURRENCY_CONVERSION.TOTAL;
                        }
                        else breakdownEquipment.push({
                            equipment: equipmentRate.EQUIPMENT_CHARGE,
                            rates: [equipmentRate],
                            conversionTotal: equipmentRate.CURRENCY_CONVERSION ? equipmentRate.CURRENCY_CONVERSION.TOTAL : 0
                        });
                    }
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            return breakdownEquipment;
        }
    }
}

import angular = require('angular');
import { IModalService, IModalOptions } from '@services/ModalService';
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { IRestService } from "@services/RestService";
import { IMonacoConfig } from '../../common/MonacoInterface';
import { SelectorModel } from "../../common/model/SelectorModel";
import { ISelectorModel } from 'WBA-Model/dist/mongo/SelectorModel';
import { ProductService } from '@services/ProductService';
import { INewProcessScope } from './NewProcessController';
import { EEventType, ERoutingPointType, EPaymentNatureId, EDirectionId, EApplicationId, EProviderTypeId } from '@enums/GenericData';
import { IRepurchase, IRepurchaseCharge, IResponseRepurchaseSave, IResponseRepurchaseWizardGet, IRepurchaseWizardConfirmation, IRoutingPoint } from 'WBA-Model/dist/interface/operational/repurchase/Repurchase'
import { IRepurchaseError } from 'WBA-Model/dist/interface/dataProcess/RepurchaseError'
import { OperationalService } from '@services/OperationalService';
import { INewProcessEventModel } from 'WBA-Model/dist/interface/operational/NewProcessTabsModel';
import { DataProcessService } from '@services/DataProcessService';
import { ITableOptions } from "src/ts/app/directives/monaco-data-table";
import { INewProcessMainModel } from '@models/interface/operational/NewProcessTabsModel';
import { ILinkParameter } from 'src/ts/common/model/ModelParameter';
import { ISessionService } from '@services/SessionService';
import { IConsolidatedModel } from 'WBA-Model/dist/interface/operational/consolidated/Consolidated';
import { IListCustomFilters } from "WBA-Model/dist/interface/product/FreightRoutes";
import { IListCustomFilters as IListCustomFiltersTariffLocal } from "WBA-Model/dist/interface/product/TariffLocal";
import { IConsolidatedWizardModalScope } from './ConsolidatedWizardModalController';

const enum ETabStep {
    PARAM = 0,
    VIEW = 1,
    CONFIRMATION = 2
}

interface IUiTabSteps {
    current: number
    percent: number
    lastStep: number
}

interface ISearchBehaviorControl {
    isSearchTabDataListCanceled: boolean;
    searchTabDataError: string[];
    isSearchingTabDataList: boolean;
    searchTabDataListCount: number;
    searchTabDataCurrentAttempt: number;
    searchTabDataMaxAttempt: number;
}

interface IWizardRepurchaseScope extends IFormServiceScope {
    modalOptions: IModalOptions;
    newProcessScope: INewProcessScope;
    newProcessEventModel: INewProcessEventModel;
    newProcessMainModel: INewProcessMainModel;
    consolidatedModel: IConsolidatedModel;
    consolidatedOldModel: IConsolidatedModel;
    errorsOnFilter: IRepurchaseError[];
    model: IRepurchase;
    oldModel: IRepurchase;
    steps: IUiTabSteps;
    hasAnyOption: boolean;
    providerList: SelectorModel[];
    routingPointList: IRoutingPoint[];
    tariffLocalList: SelectorModel[];
    freightRoutesList: SelectorModel[];
    chargesTableOptions: ITableOptions;
    processProvider: ISelectorModel;
    processOrigin: ISelectorModel;
    processDestination: ISelectorModel;
    confirmationTabSearchControl: ISearchBehaviorControl;
    repurchaseWizardConfirmation: IRepurchaseWizardConfirmation[];
    hasAnyConfirmationOption: boolean;
    sessionService: ISessionService;
    isConsolidated: boolean;
    isReprocessingConsolidated: boolean;
    consolidatedId: number;
    shipmentDate: Date;
    //navigation
    back: () => void;
    next: () => void;
    finish: () => void;
    tabClick: (tabIndex: number) => void;
    goToTariff: (type: string, tariffId: number, tariffContractId: number) => void;

    //gets
    getProviderListByName: (search: string) => Promise<void>;
    getRoutingPointListByName: (name: string) => Promise<void>;

    //filters
    routingPointRouteFilter: (index: number, field: string) => Function;

    //multilines control
    selectAll: (clickedIn?: boolean) => void;

    //check methods
    isTabEnabled: (tabIndex: number) => boolean;

    getTariffLocalCustomListByName: (search?: string, direction?: string) => void;
    getFreightRoutesCustomListByName: (search?: string) => void;
    hasInvalidRequiredElements: (elementId: string) => boolean;

    presentOnly: (charge: IRepurchaseCharge) => object;
    valueDifferences: (charge: IRepurchaseCharge, processValue: number, tariffValue: number) => boolean;
    replayRepurchase: (retry?: boolean) => Promise<void>;

    getAndUpdateConfirmationList: () => void;
    hasAnySearchControlInProgress: () => boolean;
    hasAnyConfirmationOptionWithError: () => boolean;
    cancelConfirmationSearch: () => void;

    goToFreightRoutes: (id: string) => void;
    goToTariffLocal: (id: string) => void;
    goToProvider: (id: number) => void;
    goToRoutingPoint: (id: string) => void;
    hasChange: (data: ISelectorModel) => void;
    hasChangeDate: (date?: Date) => void;
}

export class WizardRepurchaseController extends FormService2 {
    static $inject: string[] = ['$injector', '$scope', 'RestService', 'ModalService', 'config'];
    private scope: IWizardRepurchaseScope;
    private $q: ng.IQService;
    private restService: IRestService;
    private config: IMonacoConfig;
    private timeout: ng.ITimeoutService;
    private productService: ProductService;
    private operationalService: OperationalService;
    private processService: DataProcessService;
    private ModalService: IModalService;
    private $translate;
    private $consolidatedScope: IConsolidatedWizardModalScope;

    constructor($injector: ng.Injectable<any>, $scope: IWizardRepurchaseScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.$q = $injector.get('$q');
        this.restService = $injector.get('RestService');
        this.productService = $injector.get('ProductService');
        this.operationalService = $injector.get('OperationalService');
        this.processService = $injector.get('DataProcessService');
        this.config = $injector.get('config');
        this.timeout = $injector.get('$timeout');
        this.ModalService = $injector.get('ModalService');
        this.scope.sessionService = $injector.get('SessionService');
        this.$translate = $injector.get('$translate');
        this.$consolidatedScope = <IConsolidatedWizardModalScope>$scope.$parent.$parent;
    }

    async initDependencies(): Promise<any> {
        const self: WizardRepurchaseController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getProcessTabsEvent(),
                self.getProcessTabsMain(),
            ]).then(async (result: any) => {
                self.scope.newProcessEventModel = result[0];
                self.scope.newProcessMainModel = result[1];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    $onInit = async () => {
        try {
            this.init("repurchaseWizardModalForm", null, null);
            this.initScopeFunctions();
            this.initSearchControl();
            await this.initDependencies();
            await this.initModel();
            await this.initControl();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        return this.config.productUrl + baseRoute;
    }

    private initSearchControl() {
        this.scope.confirmationTabSearchControl = { isSearchTabDataListCanceled: false, isSearchingTabDataList: false, searchTabDataError: null, searchTabDataListCount: null, searchTabDataCurrentAttempt: null, searchTabDataMaxAttempt: null };
    }

    private initScopeFunctions(): void {
        this.getEmptySelectorMsg = () => { return this.getEmptySelectorMsg() };
        this.scope.back = (): void => {
            if (this.scope && this.scope.steps && this.scope.steps.current > ETabStep.PARAM) {
                this.scope.steps.current--;
                this.loadRegisterForm(false);
            }
        }
        this.scope.next = async (): Promise<void> => {
            const lastStep = this.scope.steps.lastStep;

            if (this.scope && this.scope.steps && angular.isDefined(this.scope.steps.current)) {

                this.loadRegisterForm(false);

                // Validate Tabs
                if (this.scope.steps.current == ETabStep.PARAM && !(await this.validateParamTab())) return;
                if (lastStep == this.scope.steps.current) return this.scope.finish();
                else if (this.scope.steps.current < ETabStep.CONFIRMATION) {
                    this.scope.steps.current++;
                }

                // Execute a function before go to next step
                if (this.scope.steps.current == ETabStep.VIEW && !(await this.chargeView())) return;
                if (this.scope.steps.current == ETabStep.CONFIRMATION) {
                    if (this.scope.isReprocessingConsolidated) {
                        this.scope.consolidatedModel.CONSIDERED_RATE_ACHIEVED = null;
                        this.$consolidatedScope.model = this.scope.consolidatedModel;
                        this.$consolidatedScope.oldModel = this.scope.consolidatedOldModel;
                        await this.$consolidatedScope.updateConsolidated(false);
                        await this.$consolidatedScope.$applyAsync();
                    }
                    await this.createrRepurchase();
                    await this.getAndUpdateConfirmationList();
                };
                // Advance Tab / finish

            }
        }
        this.scope.tabClick = (tabIndex: number): void => {
            if (!this.scope.steps || tabIndex == null) return;
            const previousStep = this.scope.steps.current - 1;
            const nextStep = this.scope.steps.current + 1;
            if (previousStep == tabIndex) this.scope.back();
            else if (nextStep == tabIndex) this.scope.next();
        }

        this.scope.getProviderListByName = async (search: string): Promise<void> => {
            let providerList: SelectorModel[] = [];
            if (search && search.length >= 3) providerList = await this.getProviderListByName(search);
            this.scope.providerList = providerList;
        }

        this.scope.getRoutingPointListByName = async (search: string): Promise<void> => {
            let routingPointList: IRoutingPoint[] = [];
            if (search && search.length >= 3) routingPointList = await this.getRoutingPointListByName(search);
            this.scope.routingPointList = routingPointList;
        }

        this.scope.isTabEnabled = (tabIndex: number): boolean => {
            if (!this.scope.steps || tabIndex == null) return false;
            const previousStep = this.scope.steps.current - 1;
            const nextStep = this.scope.steps.current + 1;
            return (previousStep == tabIndex || nextStep == tabIndex);
        }

        this.scope.getTariffLocalCustomListByName = (search?: string, direction?: string) => {
            if (search && search.length >= 3) this.getTariffLocalCustomListByName(direction);
            else this.scope.tariffLocalList = null;
        }

        this.scope.getFreightRoutesCustomListByName = (search?: string) => {
            if (search && search.length >= 3) this.getFreightRoutesCustomListByName(search);
            else this.scope.freightRoutesList = null;
        }

        this.scope.presentOnly = (charge: IRepurchaseCharge): object => {
            return this.presentOnly(charge)
        }

        this.scope.valueDifferences = (charge: IRepurchaseCharge, processValue: number, tariffValue: number): boolean => {
            return this.valueDifferences(charge, processValue, tariffValue)
        }

        this.scope.replayRepurchase = async (retry?: boolean) => {
            await this.createrRepurchase(retry);
            await this.getAndUpdateConfirmationList()
        }

        this.scope.getAndUpdateConfirmationList = () => {
            this.getAndUpdateConfirmationList();
        }

        this.scope.hasAnySearchControlInProgress = () => {
            return this.hasAnySearchControlInProgress();
        }

        this.scope.finish = () => {
            this.finish();
        }

        this.scope.hasAnyConfirmationOptionWithError = () => {
            return this.hasAnyOptionWithError();
        }

        this.scope.cancelConfirmationSearch = () => {
            this.cancelConfirmationSearch();
        }

        this.scope.goToFreightRoutes = (id: string) => {
            this.scope.sessionService.openTab("app.product.freightRoutes", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.scope.goToTariffLocal = (id: string) => {
            this.scope.sessionService.openTab("app.product.tariffLocal", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.scope.goToProvider = (id: number) => {
            this.scope.sessionService.openTab("app.registration.provider", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.scope.goToRoutingPoint = (id: string) => {
            this.scope.sessionService.openTab("app.registration.routingPoint", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.scope.hasChange = (data: ISelectorModel) => {
            if (data != null) {
                this.scope.model.ORIGIN_TARIFF_LOCAL = null;
                this.scope.model.DESTINATION_TARIFF_LOCAL = null;
                this.scope.model.FREIGHT_ROUTE = null;
            }
        }

        this.scope.hasChangeDate = (date?: Date) => {
            const currentDate = new Date();
            currentDate.setHours(0, 0, 0, 0);

            if (!this.scope.shipmentDate) this.scope.shipmentDate = new Date(null);

            if (date.getTime() === currentDate.getTime()) {
                return;
            } else if (date && date.getTime() != this.scope.shipmentDate.getTime()) {
                this.scope.model.ORIGIN_TARIFF_LOCAL = null;
                this.scope.model.DESTINATION_TARIFF_LOCAL = null;
                this.scope.model.FREIGHT_ROUTE = null;
                this.scope.shipmentDate = date;
            }
        }
    }

    private async initModel(): Promise<void> {
        if (this.scope.isReprocessingConsolidated) {
            this.scope.model = {
                _id: null,
                ID_PROCESS: null,
                ID_CONSOLIDATED: this.scope.isConsolidated ? this.scope.consolidatedId : null,
                PROCESS_NUMBER: null,
                PROVIDER: {
                    ID: this.scope.consolidatedModel.PROVIDER.ID,
                    NAME: this.scope.consolidatedModel.PROVIDER.NAME,
                    CODE: this.scope.consolidatedModel.PROVIDER.SCAC
                },
                SHIPMENT_DATE: this.scope.isConsolidated ? this.scope.shipmentDate : new Date(),
                AIRPORT_OF_ORIGIN: this.scope.consolidatedModel.ORIGIN,
                AIRPORT_OF_DESTINATION: this.scope.consolidatedModel.DESTINATION,
                FREIGHT_ROUTE: this.scope.consolidatedModel.TARIFF_FREIGHT,
                ORIGIN_TARIFF_LOCAL: this.scope.consolidatedModel.TARIFF_LOCAL_ORIGIN,
                DESTINATION_TARIFF_LOCAL: this.scope.consolidatedModel.TARIFF_LOCAL_DESTINATION,
                CHARGE: null
            }
            this.scope.model.SHIPMENT_DATE.setHours(0, 0, 0, 0);
            this.scope.processProvider = {
                ID: this.scope.consolidatedOldModel.PROVIDER.ID,
                NAME: this.scope.consolidatedOldModel.PROVIDER.NAME,
                CODE: this.scope.consolidatedOldModel.PROVIDER.SCAC
            };
            this.scope.processOrigin = this.scope.consolidatedOldModel.ORIGIN;
            this.scope.processDestination = this.scope.consolidatedOldModel.DESTINATION

        } else {
            this.scope.model = {
                _id: null,
                ID_PROCESS: this.scope.newProcessScope.model.ID,
                ID_CONSOLIDATED: this.scope.isConsolidated ? this.scope.consolidatedId : null,
                PROCESS_NUMBER: this.scope.newProcessScope.model.PROCESS_NUMBER,
                PROVIDER: this.scope.isConsolidated ? {
                    ID: this.$consolidatedScope.model.PROVIDER.ID,
                    NAME: this.$consolidatedScope.model.PROVIDER.NAME,
                    CODE: this.$consolidatedScope.model.PROVIDER.SCAC
                } : {
                    ID: this.scope.newProcessMainModel.SERVICE_PROVIDER.ID,
                    NAME: this.scope.newProcessMainModel.SERVICE_PROVIDER.NAME,
                    CODE: this.scope.newProcessMainModel.SERVICE_PROVIDER.SCAC
                },
                SHIPMENT_DATE: this.scope.isConsolidated ? this.scope.shipmentDate : new Date(),
                AIRPORT_OF_ORIGIN: this.scope.isConsolidated ? this.$consolidatedScope.model.ORIGIN : this.scope.newProcessScope.model.ORIGIN,
                AIRPORT_OF_DESTINATION: this.scope.isConsolidated ? this.$consolidatedScope.model.DESTINATION : this.scope.newProcessScope.model.DESTINATION,
                FREIGHT_ROUTE: null,
                ORIGIN_TARIFF_LOCAL: null,
                DESTINATION_TARIFF_LOCAL: null,
                CHARGE: null
            }
            this.scope.model.SHIPMENT_DATE.setHours(0, 0, 0, 0);
            this.scope.processProvider = {
                ID: this.scope.newProcessMainModel.SERVICE_PROVIDER.ID,
                NAME: this.scope.newProcessMainModel.SERVICE_PROVIDER.NAME,
                CODE: this.scope.newProcessMainModel.SERVICE_PROVIDER.SCAC
            };
            this.scope.processOrigin = this.scope.newProcessScope.model.ORIGIN;
            this.scope.processDestination = this.scope.newProcessScope.model.DESTINATION
        }
    }

    private async initControl() {
        if (this.scope.isConsolidated) {
            this.scope.steps = { current: 1, percent: 20, lastStep: 2 };
            await this.chargeView();
        } else {
            this.scope.steps = { current: 0, percent: 0, lastStep: 2 };
        }
    }

    private async validateParamTab(): Promise<boolean> {
        this.timeout(() => {
            this.scope.selectorValidity('provider');
            this.scope.selectorValidity('airPortOfOrigin');
            this.scope.selectorValidity('airPortOfDestination');
            this.scope.selectorValidity('freightRoute');
            this.scope.selectorValidity('loadRef');
        }, 100);

        // Validate Fields
        let isValid = this.checkPendingFields("repurchaseWizardModalForm");
        return isValid;
    }

    private async chargeView(): Promise<boolean> {
        let valid = false;
        try {
            this.block();

            const chargeView = await this.processService.post(`/repurchase/chargeView`, { data: this.scope.model, language: this.$translate.use(), reprocessing: this.scope.isReprocessingConsolidated }, 3000);
            if (chargeView && chargeView.data && chargeView.data.data) {
                this.scope.model = chargeView.data.data;
                valid = true;
            }

        } catch (ex) {
            this.handleError(ex);
            valid = false;
        } finally {
            this.unblock();
            return valid;
        }
    }

    private async getProviderListByName(search?: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.block();
            const providers = await this.restService.newObjectPromise(`${this.getUrlProduct()}/provider/list/custom`, { search: search, types: [EProviderTypeId.AIRLINE] }, 30000, false);
            if (providers && providers.length > 0) result = providers.map(provider => { return { ID: provider.ID, NAME: provider.NAME, CODE: provider.SCAC_IATA } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getRoutingPointListByName(name: string): Promise<IRoutingPoint[]> {
        let result: IRoutingPoint[] = [];
        try {
            this.block();
            const routingPoints = await this.productService.post({ route: '/routingPoint/list/custom/', data: { name, types: [ERoutingPointType.AIRPORT], sysConvertIdToString: false } });
            if (routingPoints && routingPoints.data && routingPoints.data.data && routingPoints.data.data.data && routingPoints.data.data.data.length > 0) {
                result = routingPoints.data.data.data.map((routingPoint) => {
                    return {
                        ID: routingPoint.ID,
                        NAME: routingPoint.NAME,
                        CODE: routingPoint.CODE,
                        TRADE_GROUP: routingPoint.TRADE_GROUP,
                        TRADE_LANE: routingPoint.TRADE_LANE,
                        ACTIVE: routingPoint.ACTIVE,
                        CITY: routingPoint.CITY,
                        PROVINCE: {
                            ID: routingPoint.ID_PROVINCE,
                            NAME: routingPoint.CITY.PROVINCE.NAME,
                            CODE: routingPoint.CITY.PROVINCE.CODE
                        },
                        COUNTRY: routingPoint.COUNTRY,
                        DISPLAY_NAME: routingPoint.DISPLAY_NAME
                    }
                });
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getProcessTabsEvent(): Promise<INewProcessEventModel> {
        this.block();
        try {

            if (this.scope.isReprocessingConsolidated) return;

            const timeout: number = 120000;
            const mainEvent = await this.operationalService.get(`/process/panel/event/${this.scope.newProcessScope.model.PROCESS_NUMBER}/${timeout}`, timeout);
            if (mainEvent && mainEvent.data && mainEvent.data.data) return mainEvent.data.data;

        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getProcessTabsMain(): Promise<INewProcessMainModel> {
        this.block();
        try {

            if (this.scope.isReprocessingConsolidated) return;

            const timeout: number = 120000;
            const main = await this.operationalService.get(`/process/panel/main/${this.scope.newProcessScope.model.PROCESS_NUMBER}`, timeout);
            if (main && main.data && main.data.data) return main.data.data;

        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getTariffLocalCustomListByName(direction?: string): Promise<void> {
        let result = [];
        let local: string = null;
        this.block();
        try {

            if (!this.scope.model.PROVIDER) throw Error('Provider is null');
            if (!this.scope.model.SHIPMENT_DATE) throw Error('Shipment date is null');

            switch (direction) {
                case EDirectionId.ORIGIN:
                    local = this.scope.model.AIRPORT_OF_ORIGIN ? this.scope.model.AIRPORT_OF_ORIGIN.ID : null;
                    break;
                case EDirectionId.DESTINATION:
                    local = this.scope.model.AIRPORT_OF_DESTINATION ? this.scope.model.AIRPORT_OF_DESTINATION.ID : null;
                    break;
            }

            const filters: IListCustomFiltersTariffLocal = {
                CUSTOMERS: [this.scope.newProcessScope.model.CUSTOMER.ID],
                DIRECTIONS: [direction],
                LOCAL: [local],
                PAYMENTS: [EPaymentNatureId.PAYMENT],
                PRODUCTS: [this.scope.newProcessScope.model.PRODUCT.ID],
                PROVIDERS: [this.scope.model.PROVIDER.ID],
                VALIDITY_DATE: this.scope.model.SHIPMENT_DATE
            }

            const tariffLocalCustom = await this.productService.post({ route: `/tariffLocal/list/custom`, data: { filters } });
            if (tariffLocalCustom && tariffLocalCustom.data && tariffLocalCustom.data.data) result = tariffLocalCustom.data.data.map(tariffLocal => { return { ID: tariffLocal.ID, NAME: tariffLocal.CONCATENATED } });
            this.scope.tariffLocalList = result;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getFreightRoutesCustomListByName(search?: string): Promise<void> {
        let result = [];
        this.block();
        try {
            if (!this.scope.model.PROVIDER) throw Error('Provider is null');
            if (!this.scope.model.SHIPMENT_DATE) throw Error('Shipment date is null');

            const filters: IListCustomFilters = {
                SEARCH: search,
                PRODUCTS: [this.scope.newProcessScope.model.PRODUCT.ID],
                PAYMENTS: [EPaymentNatureId.PAYMENT],
                VALIDITY_DATE: this.scope.model.SHIPMENT_DATE,
                PROVIDERS: [this.scope.model.PROVIDER.ID],
                AIRPORT_OF_ORIGIN: this.scope.model.AIRPORT_OF_ORIGIN ? [this.scope.model.AIRPORT_OF_ORIGIN.ID] : [],
                AIRPORT_OF_DESTINATION: this.scope.model.AIRPORT_OF_DESTINATION ? [this.scope.model.AIRPORT_OF_DESTINATION.ID] : [],
                EXTERNAL_AGENT: this.scope.newProcessScope.model.EXTERNAL_AGENT && this.scope.newProcessScope.model.EXTERNAL_AGENT.NETWORK ? [parseInt(this.scope.newProcessScope.model.EXTERNAL_AGENT.NETWORK.ID)] : []
            }

            const freightRoutesCustom = await this.productService.post({ route: `/freightRoutes/list/custom`, data: { filters } });
            if (freightRoutesCustom && freightRoutesCustom.data && freightRoutesCustom.data.data) result = freightRoutesCustom.data.data.map(freightRoutes => { return { ID: freightRoutes.ID, NAME: freightRoutes.CONCATENATED } });
            this.scope.freightRoutesList = result;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private presentOnly(charge: IRepurchaseCharge) {
        if (charge.hasOwnProperty('PRESENT_ONLY')) {
            if (charge.PRESENT_ONLY == "1") {
                return { 'background': '#dbe7f6' }
            } else {
                return { 'background': '#fce7e7' }
            }
        }

        if (charge.PROCESS.APPLICATION.ID != charge.TARIFF.APPLICATION.ID) {
            return { 'background': '#ffeb3b' }
        }
    }

    private valueDifferences(charge: IRepurchaseCharge, processValue: number, tariffValue: number) {
        if (charge.TARIFF.APPLICATION.ID != EApplicationId.SCOB && charge.PROCESS.APPLICATION.ID != EApplicationId.SCOB) {
            if (processValue != tariffValue) return true;
        }
    }

    private async createrRepurchase(retry?: boolean): Promise<boolean> {
        let success = true;
        try {
            this.block();
            retry = retry ? retry : this.scope.errorsOnFilter && this.scope.errorsOnFilter.length > 0;
            const resultOperation = await this.processService.post('/repurchase/save', { data: this.scope.model, oldData: this.scope.oldModel ? this.scope.oldModel : null, retry: retry, reprocessing: this.scope.isReprocessingConsolidated }, 30000);
            if (resultOperation && resultOperation.data && resultOperation.data.data) {
                const result: IResponseRepurchaseSave = resultOperation.data.data;
                this.scope.errorsOnFilter = result.REPURCHASE_ERROR_LIST;
                this.scope.model = result.REPURCHASE;
                this.scope.oldModel = angular.copy(this.scope.model);
                if (this.scope.errorsOnFilter && this.scope.errorsOnFilter.length) {
                    this.notifyError(this.getTranslate("GENERAL.ERROR_SENDING_REQUEST"));
                    success = false;
                }
            }
        } catch (ex) {
            success = false;
            this.handleError(ex);
        } finally {
            this.unblock();
            return success;
        }
    }

    private async getAndUpdateConfirmationList(): Promise<void> {
        try {
            const confirmationTabSearchControl = this.scope.confirmationTabSearchControl;
            confirmationTabSearchControl.isSearchTabDataListCanceled = false;
            this.scope.repurchaseWizardConfirmation = null;
            if (!confirmationTabSearchControl.isSearchingTabDataList) {
                confirmationTabSearchControl.searchTabDataListCount = 0;
                confirmationTabSearchControl.searchTabDataCurrentAttempt = 0;
                confirmationTabSearchControl.isSearchingTabDataList = true;
            }

            this.scope.repurchaseWizardConfirmation = [];

            const resultOperation = await this.processService.get(`/repurchase/progressBar/${this.scope.model._id}/${this.scope.isReprocessingConsolidated ? this.scope.model.ID_CONSOLIDATED : null}`, null, 30000);
            if (resultOperation && resultOperation.data && resultOperation.data.data) {
                const result: IResponseRepurchaseWizardGet = resultOperation.data.data;
                if (result.SHOW) {
                    this.scope.errorsOnFilter = result.REPURCHASE_ERROR_LIST;
                    if (this.scope.errorsOnFilter && this.scope.errorsOnFilter.length) {
                        this.notifyError(this.getTranslate("GENERAL.ERROR_SENDING_REQUEST"));
                    }
                    this.scope.repurchaseWizardConfirmation = result.CONFIRMATION;
                    this.scope.hasAnyConfirmationOption = this.scope.repurchaseWizardConfirmation && this.scope.repurchaseWizardConfirmation.length > 0;
                    confirmationTabSearchControl.isSearchingTabDataList = false;
                    confirmationTabSearchControl.searchTabDataCurrentAttempt = 0;
                    this.scope.$applyAsync();
                } else if (confirmationTabSearchControl.searchTabDataCurrentAttempt == confirmationTabSearchControl.searchTabDataMaxAttempt) {
                    confirmationTabSearchControl.isSearchingTabDataList = false;
                    this.scope.$applyAsync();
                    return this.notifyError(this.getTranslate("GENERAL.LIMIT_ATTEMPTS"))
                } else {
                    confirmationTabSearchControl.isSearchingTabDataList = true;
                    confirmationTabSearchControl.searchTabDataCurrentAttempt++;
                    confirmationTabSearchControl.searchTabDataListCount = 0;
                    this.scope.$applyAsync();
                    this.timeout(() => { this.initCountGetConfirmationTabDataList() }, 1000);
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private initCountGetConfirmationTabDataList() {
        if (!this.scope.confirmationTabSearchControl.isSearchTabDataListCanceled) {
            this.timeout(() => {
                this.scope.confirmationTabSearchControl.searchTabDataListCount += 5;
                if (this.scope.confirmationTabSearchControl.searchTabDataListCount < 100) this.initCountGetConfirmationTabDataList();
                else if (!this.scope.confirmationTabSearchControl.isSearchTabDataListCanceled) this.timeout(() => { this.getAndUpdateConfirmationList() }, 1000);
            }, 100);
        }
    }

    private hasAnySearchControlInProgress(): boolean {
        return (this.scope.confirmationTabSearchControl && this.scope.confirmationTabSearchControl.isSearchingTabDataList);
    }

    private hasAnyOptionWithError(): boolean {
        return this.scope.errorsOnFilter && this.scope.errorsOnFilter.length ? true : false;
    }

    private async finish(): Promise<void> {
        try {
            const hasAnyConfirmationWithError = this.hasAnyOptionWithError();
            const confirmed = hasAnyConfirmationWithError ? await this.ModalService.showModalConfirmation({}, {
                bodyText: this.getTranslate("OPERATIONAL.ARE_YOU_SURE_YOU_WANT_TO_FINISH")
            }) : true;
            if (confirmed) {
                this.scope.modalOptions.ok(true);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private cancelConfirmationSearch() {
        this.scope.confirmationTabSearchControl.isSearchTabDataListCanceled = true;
        this.scope.confirmationTabSearchControl.isSearchingTabDataList = false;
        this.scope.confirmationTabSearchControl.searchTabDataCurrentAttempt = 0;
    }
}

import * as angular from 'angular';
import initRoute from './RouteConfig';
import { GRID_FORM_MODULE } from '../gridForm/GridFormModule';
import { GRID_EXCEL_MODULE } from '../gridExcel/GridExcelModule';
import { ProductMenuController } from './controller/ProductMenuController';

import { TariffFreightRegisterController } from './controller/TariffFreightRegisterController';
import { TariffFreightFclController } from './controller/TariffFreightFclController';
import { NewTariffFreightFclController } from './controller/NewTariffFreightFclController';
import { NewTariffFreightAirController } from './controller/NewTariffFreightAirController';
import { TariffFreightAirController } from './controller/TariffFreightAirController';
import { TariffFreightChargesRegisterController } from './controller/TariffFreightChargesRegisterController';
import { TariffLocalRegisterController } from './controller/TariffLocalRegisterController';
import { TariffDetDemRegisterController } from './controller/TariffDetDemRegisterController';
import { TariffDomesticRegisterController } from './controller/TariffDomesticRegisterController';
import { FreightContractRegisterController } from './controller/FreightContractRegisterController';
import { ParamsProfitShareRegisterController } from './controller/ParamsProfitShareRegisterController';
import { AirServiceRegisterController } from './controller/AirServiceRegisterController';
import { DomesticServiceRegisterController } from './controller/DomesticServiceRegisterController';
import { MaritimeServiceRegisterController } from './controller/MaritimeServiceRegisterController';
import { AutoRatingParamRegisterController } from './controller/AutoRatingParamRegisterController';
import { TariffTransactionManagementRegisterController } from './controller/TariffTransactionManagementRegisterController';
import { ProductService } from '@services/ProductService';
import { DataOperationalService } from '@services/DataOperationalService';
import { TariffFreightHistoryRegisterController } from './controller/TariffFreightHistoryRegisterController';
import { TariffDomesticHistoryRegisterController } from './controller/TariffDomesticHistoryRegisterController';
import { TariffLocalHistoryRegisterController } from './controller/TariffLocalHistoryRegisterController';
import { TariffDetDemHistoryRegisterController } from './controller/TariffDetDemHistoryRegisterController';
import { InlandContractRegisterController } from './controller/InlandContractRegisterController';
import { InlandRoutesRegisterController } from './controller/InlandRoutesRegisterController';
import { TablePerDiemRegisterController } from './controller/TablePerDiemRegisterController';
import { MasterBillingSettingsRegisterController } from './controller/MasterBillingSettingsRegisterController';
import { TariffComplementaryRegisterController } from './controller/TariffComplementaryRegisterController';
import { NewFreightContractRegisterController } from './controller/NewFreightContractRegisterController';
import { FreightWizardModalController } from './controller/FreightWizardModalController';
import { FreightRoutesChargesModalController } from './controller/FreightRoutesChargesModalController';
import { FreightRoutesRegisterController } from './controller/FreightRoutesRegisterController';
import { AutoRatingControlRegisterController } from './controller/AutoRatingControlRegisterController';

const PRODUCT_MODULE = angular
    .module('product', [GRID_FORM_MODULE.name, GRID_EXCEL_MODULE.name])
    .controller('ProductMenuController', ProductMenuController)

    // Tariff Freight
    .controller('TariffFreightRegisterController', TariffFreightRegisterController)
    .controller('TariffFreightFclController', TariffFreightFclController)
    .controller('NewTariffFreightFclController', NewTariffFreightFclController)
    .controller('NewTariffFreightAirController', NewTariffFreightAirController)
    .controller('TariffFreightAirController', TariffFreightAirController)
    .controller('TariffFreightChargesRegisterController', TariffFreightChargesRegisterController)
    .controller('TariffLocalRegisterController', TariffLocalRegisterController)
    .controller('TariffDetDemRegisterController', TariffDetDemRegisterController)
    .controller('TariffDomesticRegisterController', TariffDomesticRegisterController)
    .controller('TariffComplementaryRegisterController', TariffComplementaryRegisterController)

    // Contract
    .controller('FreightContractRegisterController', FreightContractRegisterController)
    .controller('ParamsProfitShareRegisterController', ParamsProfitShareRegisterController)
    .controller('NewFreightContractRegisterController', NewFreightContractRegisterController)

    // Services
    .controller('AirServiceRegisterController', AirServiceRegisterController)
    .controller('DomesticServiceRegisterController', DomesticServiceRegisterController)
    .controller('MaritimeServiceRegisterController', MaritimeServiceRegisterController)
    .controller('AutoRatingParamRegisterController', AutoRatingParamRegisterController)
    .controller('TariffTransactionManagementRegisterController', TariffTransactionManagementRegisterController)
    .controller('AutoRatingControlRegisterController', AutoRatingControlRegisterController)

    .service('ProductService', ProductService)
    .service('DataOperationalService', DataOperationalService)

    // Tariff History
    .controller('TariffFreightHistoryRegisterController', TariffFreightHistoryRegisterController)
    .controller('TariffDomesticHistoryRegisterController', TariffDomesticHistoryRegisterController)
    .controller('TariffLocalHistoryRegisterController', TariffLocalHistoryRegisterController)
    .controller('TariffDetDemHistoryRegisterController', TariffDetDemHistoryRegisterController)
    
    .controller('InlandContractRegisterController', InlandContractRegisterController)
    .controller('InlandRoutesRegisterController', InlandRoutesRegisterController)

    // Per Diem
    .controller('TablePerDiemRegisterController', TablePerDiemRegisterController)
    .controller('MasterBillingSettingsRegisterController', MasterBillingSettingsRegisterController)

    // Freight Routes
    .controller('FreightRoutesChargesModalController', FreightRoutesChargesModalController)
    .controller('FreightRoutesRegisterController', FreightRoutesRegisterController)

    // Freight Wizard
    .controller('FreightWizardModalController', FreightWizardModalController)

initRoute(PRODUCT_MODULE.name);


initRoute(PRODUCT_MODULE.name);

export { PRODUCT_MODULE };
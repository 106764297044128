import moment = require('moment');
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { OperationalService } from "@services/OperationalService";
import { IModalService } from "@services/ModalService";
import { FormService2 } from "@services/FormService2";
import { DEADLINES } from "@models/interface/operational/NewProcess";
import { EOperation } from '@enums/GenericData';
import { NotificationService } from "@services/NotificationService";

export interface IProcessDeadlinesModalParameter {
    title: string;
    house: DEADLINES;
    master: DEADLINES;
    readonly: boolean;
    processNumber: string;
    load: Date;
}

interface ProcessDeadlinesModalScope extends ng.IScope { }

export default class ProcessDeadlinesModal extends FormService2 {

    private timeout: ng.ITimeoutService;
    private ModalService: IModalService;
    private scope: ProcessDeadlinesModalScope;
    public operationalService: OperationalService;

    constructor($injector: ng.Injectable<any>, $scope) {
        super($injector, $scope);

        this.scope = $scope;
        this.timeout = $injector.get('$timeout');
        this.ModalService = $injector.get('ModalService');
        this.operationalService = $injector.get('OperationalService');
    }

    private hasChanges(newObj: Object, oldObj: Object): boolean {
        let hasChanges = false;
        if (oldObj) {
            for (let property in newObj) {
                if (newObj.hasOwnProperty(property)) {
                    if (JSON.stringify(newObj[property]) != JSON.stringify(oldObj[property])) {
                        hasChanges = true;
                        break;
                    }
                }
            }
        }
        return hasChanges;
    }

    public async show(parameters: IProcessDeadlinesModalParameter): Promise<boolean> {
        let { house, master, load } = parameters;
        const { title, readonly, processNumber } = parameters;
        const modalID: number = this.ModalService.newModal();

        const applyModalDeadlinesInScope = async () => {
            const modalScope = await this.ModalService.getModalScope(modalID);
            modalScope.master = master.DEADLINE_DATES;
            modalScope.house = house.DEADLINE_DATES;
        }

        const validateMasterDraftImo = (dateValue: Date) => {
            if (!readonly && dateValue && load) {
                const isValidDate = checkDateValidity(dateValue);
                if (!isValidDate) master.DEADLINE_DATES.DRAFT_IMO = null;
            }
        }

        const validateHouseDraftImo = (dateValue: Date) => {
            if (!readonly && dateValue && load) {
                const isValidDate = checkDateValidity(dateValue);
                if (!isValidDate) house.DEADLINE_DATES.DRAFT_IMO = null;
            }
        }

        const validateMasterDraft = (dateValue: Date) => {
            if (!readonly && dateValue && load) {
                const isValidDate = checkDateValidity(dateValue);
                if (!isValidDate) master.DEADLINE_DATES.DRAFT = null;
            }
        }

        const validateHouseDraft = (dateValue: Date) => {
            if (!readonly && dateValue && load) {
                const isValidDate = checkDateValidity(dateValue);
                if (!isValidDate) house.DEADLINE_DATES.DRAFT = null;
            }
        }

        const validateMasterRelease = (dateValue: Date) => {
            if (!readonly && dateValue && load) {
                const isValidDate = checkDateValidity(dateValue);
                if (!isValidDate) master.DEADLINE_DATES.RELEASE = null;
            }
        }

        const validateHouseRelease = (dateValue: Date) => {
            if (!readonly && dateValue && load) {
                const isValidDate = checkDateValidity(dateValue);
                if (!isValidDate) house.DEADLINE_DATES.RELEASE = null;
            }
        }

        const validateMasterVGM = (dateValue: Date) => {
            if (!readonly && dateValue && load) {
                const isValidDate = checkDateValidity(dateValue);
                if (!isValidDate) master.DEADLINE_DATES.VGM = null;
            }
        }

        const validateHouseVGM = (dateValue: Date) => {
            if (!readonly && dateValue && load) {
                const isValidDate = checkDateValidity(dateValue);
                if (!isValidDate) house.DEADLINE_DATES.VGM = null;
            }
        }


        const checkDateValidity = (dateValue: Date): boolean => {
            if (dateValue && load) {
                const loadDate = new Date(load);

                const setInitiDateValue = new Date(loadDate);
                setInitiDateValue.setHours(0, 0, 0, 0);
                setInitiDateValue.setDate(loadDate.getDate() - 31);

                const setFinalDateValue = new Date(dateValue);
                setFinalDateValue.setHours(0, 0, 0, 1);

                const isValidDate = moment(setInitiDateValue).isBefore(setFinalDateValue);

                if (!isValidDate) {
                    NotificationService().error(`The date ${moment(dateValue).format('DD/MM/YYYY')} is less than the docking date ${moment(loadDate).format('DD/MM/YYYY')}`);
                }

                return isValidDate;
            }
        }

        const applyDeadlinesChanges = async () => {
            this.block();

            await this.operationalService.post('/deadline/update', {
                deadlines: [master, house],
                processNumber
            });

            this.ModalService.closeModal(modalID, true);

            this.timeout(async () => {
                await this.scope.$applyAsync();
                this.unblock();
            }, 100, true);
        }

        const discardChanges = () => this.ModalService.closeModal(modalID, false);

        const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo({
            modalID,
            scope: this.scope,
            formService: readonly ? EOperation.VIEW : EOperation.EDIT,
            template: require("../view/wizardDeadlinesEditModal.html"),
            size: 'lg'
        }, {
            actionButtonText: 'Fechar',
            headerText: title,
        }, {
            master: master.DEADLINE_DATES,
            house: house.DEADLINE_DATES,
            applyDeadlinesChanges,
            validateMasterDraftImo,
            validateHouseDraftImo,
            validateMasterDraft,
            validateHouseDraft,
            validateMasterRelease,
            validateHouseRelease,
            validateMasterVGM,
            validateHouseVGM,
            discardChanges,
            readonly,
        });

        const apply = await modalInstance.result.then(function (result) {
            return result.$value;
        }, function (result) {
            return result.$value;
        });

        return apply;
    }
}

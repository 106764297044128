import * as angular from 'angular';
import { GridFormService, IGridFormController, IGridFormServiceScope, IMonacoRequestLog } from '@services/GridFormService';
import { ICurrencyModel } from '@models/interface/product/CurrencyModel';
import { IRestService } from '@services/RestService';
import { IMonacoColumnDef } from '@services/GridService2';
import { IViewLog, ICustomLogProperties } from '@models/interface/common/IViewLog';
import { IModalService } from '@services/ModalService';
import { GridColumnBuilder } from '../../common/GridColumnBuilder';
import { SelectorModel } from '../../common/model/SelectorModel';
import { ISessionService } from "@services/SessionService";
import { BrowserTitle } from "../../common/BrowserTitle";
import { SSEService } from '../../app/services/SSEService';
import { IGridOptions } from 'ui-grid';
import { IDocumentError } from '@models/interface/common/IDocumentError';
import { ILinkParameter } from "../../common/model/ModelParameter";
import { EOperation } from '@enums/GenericData';

interface ICurrencyScope extends IGridFormServiceScope {
    model: ICurrencyModel;
    log: IViewLog;
    countryList: SelectorModel[];
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: ICurrencyModel;
    user: any;
    sessionService: ISessionService;
    editCurrency: (currency: ICurrencyModel) => Promise<void>;
    viewCurrency: (currency: ICurrencyModel) => Promise<void>;
    viewLogCurrency: (currency: ICurrencyModel) => Promise<void>;
    copyCurrency: (currency: ICurrencyModel) => Promise<void>;
    gridOptions: IGridOptions;
    currentCurrencyValidade: () => Promise<void>;
    getCountryData: (query: string) => Promise<SelectorModel[]>;
    goToCountry: (countries: SelectorModel[]) => void;
    openModalIntegration: (id: number, documentErrorList: IDocumentError[], name: string) => void;
}

export class CurrencyRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: ICurrencyScope;
    private RestService: IRestService;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService
    private ModalService: IModalService;
    private sessionService: ISessionService;
    private SSEService: SSEService;
    private gridName: string;
    private quotationCombinedUrl: string;
    private modalID: number;

    constructor($injector: ng.Injectable<any>, $scope: ICurrencyScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.RestService = $injector.get('RestService');
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.gridName = 'GRID_CURRENCY';
        this.ModalService = $injector.get('ModalService');
        this.sessionService = $injector.get('SessionService');
        this.$scope.countryList = [];
        this.modalID = 0;
    }

    async $onInit?(): Promise<void> {
        try {
            this.$baseUrl = this.config.productUrl + '/product';
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.quotationCombinedUrl = this.getUrlQuotationCombined();

            this.initForm(this, 'form', 'currency', 'GENERAL.CURRENCY', true);
            await this.initGrid(this.gridName, '/currency/list', true, true, null, true, true);
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.currentCurrencyValidade = (): Promise<void> => {
            return this.currentCurrencyValidade();
        }

        this.$scope.getCountryData = async (query: string): Promise<SelectorModel[]> => {
            return await this.getCountryData(query);
        }

        this.$scope.goToCountry = (countries: SelectorModel[]): void => {
            this.sessionService.openTab("app.registration.country", <ILinkParameter>{ ID: this.$scope.getCONCAT(countries, null, "ID") });
        }

        this.$scope.editCurrency = async (currency: ICurrencyModel): Promise<void> => {
            let blockedObject = {
                ID: currency.ID,
                NAME: currency.NAME,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || currency.ID !== this.$scope.model.ID) this.$scope.view(currency);
                } else if (this.$scope.operation !== EOperation.EDIT || currency.ID !== this.$scope.model.ID) {
                    this.$scope.edit(currency);
                }
            };
        }

        this.$scope.viewCurrency = async (currency: ICurrencyModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(currency);
        }

        this.$scope.viewLogCurrency = async (currency: ICurrencyModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(currency);
        }

        this.$scope.copyCurrency = async (currency: ICurrencyModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(currency);
        }

        this.$scope.openModalIntegration = (id: number, documentErrorList: IDocumentError[]) => {
            this.openModalIntegration(id, documentErrorList);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            INITIALS: null,
            NUMBER: null,
            NAME: null,
            ACTIVE: true,
            CURRENT: false,
            NAME_INTL: null,
            COUNTRY: null,
            INTEGRATION_ID: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            SEARCH_FIELDS: null,
            DOCUMENT_ERROR: null,
        };
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewCurrency(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editCurrency(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogCurrency(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyCurrency(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy + modalIntegration),
            enableCellEdit: true,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colInitials: IMonacoColumnDef = { name: 'INITIALS', displayName: 'GENERAL.CODE', width: 100 };
            const colNumber: IMonacoColumnDef = { name: 'NUMBER', displayName: 'GENERAL.NUMBER', width: 100 };
            const colName: IMonacoColumnDef = { name: 'NAME', displayName: 'GENERAL.NAME', width: 100 };
            const colInternationalName: IMonacoColumnDef = { name: 'NAME_INTL', displayName: 'GENERAL.NAME_INTL', width: 300 };
            const colCurrent: IMonacoColumnDef = { name: 'CURRENT', displayName: 'REGISTRATION.DEFAULT_CURRENCY', width: 100, cellFilter: 'YesOrNo' };
            const colCountry: IMonacoColumnDef = { name: 'COUNTRY.NAME', displayName: 'ROUTE.COUNTRY', width: 400, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.COUNTRY, null, "CODE")}}</div>' };
            const colActive: IMonacoColumnDef = { name: 'ACTIVE', displayName: 'GENERAL.ACTIVE', width: 100, cellFilter: 'YesOrNo' };
            const colIntegrationId: IMonacoColumnDef = { name: 'INTEGRATION_ID', displayName: 'GENERAL.INTEGRATION_ID', width: 150 };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "GENERAL.ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'INITIALS':
                        columnDefs.push(colInitials);
                        break;
                    case 'NUMBER':
                        columnDefs.push(colNumber)
                        break;
                    case 'NAME':
                        columnDefs.push(colName);
                        break;
                    case 'NAME_INTL':
                        columnDefs.push(colInternationalName);
                        break;
                    case 'CURRENT':
                        columnDefs.push(colCurrent);
                        break;
                    case 'COUNTRY':
                        columnDefs.push(colCountry);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'INTEGRATION_ID':
                        columnDefs.push(colIntegrationId);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                    default:
                        continue;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initDependencies(): Promise<any> {
        const self: CurrencyRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([]).then((result: any) => {
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    async register(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")} (${this.$scope.model.NAME})`;
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")} (${this.$scope.model.NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            await this.clearFields(this.$scope.model, ['CONCATENATED']);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        try {
            const route = this.$scope.operation === 'register' ? 'insert' : 'update';
            return {
                route: `/currency/${route}`,
                data: angular.copy(this.$scope.model),
                oldData: angular.copy(this.$scope.scopeBeforeSave),
                timeout: 15000
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    private async currentCurrencyValidade(): Promise<void> {
        try {
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: `${this.formService.getTranslate("REGISTRATION.MESSAGES.ERROR.CURRENT_CURRENCY")}`
            });
            this.$scope.model.CURRENT = modal;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getCountryData(query?: string): Promise<SelectorModel[]> {
        try {
            let country = [];
            if (this.$scope.countryList.length > 0 && query) {
                country = this.$scope.countryList.filter(g => g.NAME.toLowerCase().search(query.toLowerCase()) > -1);
            }

            if (country && country.length === 0 && query && query.length >= 2) {

                this.block();
                const countries = await this.RestService.newObjectPromise(`${this.$baseUrl}/country/list/custom`, { search: query }, 30000, false);
                this.unblock();

                if (countries) {
                    this.$scope.countryList = await countries.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } });
                }

                this.$scope.countryList = this.$scope.countryList as SelectorModel[];
            }
            this.unblock();
            return this.$scope.countryList;

        } catch (ex) {
            this.unblock();
            this.handleError(ex);
        }
    }

    private async openModalIntegration(id: number, documentErrorList: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            this.ModalService.showModalIntegrationRedundance({ integrationId: id, documentErrorList: documentErrorList, messageDefault: { awaitingRequest: `${this.formService.getTranslate('REGISTRATION.MESSAGES.ERROR.SENDING_REQUEST')}`, successRequest: `${this.formService.getTranslate("REGISTRATION.MESSAGES.ERROR.RECORDS_TO_QUEUE")}`, requestError: `${this.formService.getTranslate('REGISTRATION.MESSAGES.ERROR.ERROR_SENDING_REQUEST')}`, awaitingGridSync: `${this.formService.getTranslate("REGISTRATION.MESSAGES.ERROR.LOADING_SYNC")}`, successGridSync: `${this.formService.getTranslate("REGISTRATION.MESSAGES.ERROR.SUCCESSFULLY_SYNCED")}`, gridSyncError: `${this.formService.getTranslate("REGISTRATION.MESSAGES.ERROR.ERROR_SYNC")}`, logCopyMessage: `${this.formService.getTranslate("REGISTRATION.COPIED")}` }, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: `${this.formService.getTranslate("REGISTRATION.ERROR_CURRENCY")}`, actionButtonText: `${this.formService.getTranslate("GENERAL.SYNC_NOW")}`, closeButtonText: `${this.formService.getTranslate("GENERAL.CLOSE")}` });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number): Promise<boolean> => {
        let success = false;
        let sendSync = false;
        let syncAccountCompiled = false;
        try {
            if (id) {
                this.formService.block();
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/currency/sendSync`, { "idCurrency": id }, 120000, false);
                if (syncRequest) sendSync = true;

                const syncCurrenciesResult = await this.RestService.newObjectPromise(`${this.quotationCombinedUrl}/currency/syncCurrencies`, { currency: [id] }, 120000, false);
                if (syncCurrenciesResult) syncAccountCompiled = true;

                success = (sendSync && syncAccountCompiled);
            }
        } catch (ex) {
            this.formService.handleError(`${this.formService.getTranslate("REGISTRATION.MESSAGES.ERROR.ERROR_SENDING_REQUEST")}`);
        } finally {
            this.formService.unblock();
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const currencyData = await this.getCurrencyById(id);
                    if (row && currencyData && currencyData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = currencyData.DOCUMENT_ERROR;
                        documentError = currencyData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private getUrlQuotationCombined(): string {
        const baseRoute = '/quotationCombined';
        return this.config.quotationCombinedUrl + baseRoute;
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlDataManager = this.config.productUrl + baseRoute;
        return urlDataManager;
    }

    private async getCurrencyById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/currency/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'INITIALS',
                LABEL: 'GENERAL.CODE'
            },
            {
                PROPERTY: 'NUMBER',
                LABEL: 'GENERAL.NUMBER'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'CURRENT',
                LABEL: 'REGISTRATION.DEFAULT_CURRENCY'
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
            {
                PROPERTY: 'NAME_INTL',
                LABEL: 'GENERAL.NAME_INTL'
            },
            {
                PROPERTY: 'COUNTRY',
                LABEL: 'ROUTE.COUNTRY'
            },
            {
                PROPERTY: 'INTEGRATION_ID',
                LABEL: 'GENERAL.INTEGRATION_ID'
            },
            {
                PROPERTY: 'ID',
                LABEL: 'GENERAL.ID'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            }
        ];
        return props;
    }
}

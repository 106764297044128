import '../../../libs/pdf.min.js';
import { IMonacoScope } from '../../common/MonacoInterface';
import { IStateParamsService } from 'angular-ui-router';
import { IDownloadService, IFileDownload } from '../services/DownloadService';
import { HandleError } from '../../common/util/HandleError';

export interface IErrorDatamanagerDownload {
    status: string;
    message: string;
    reason: string;
}

interface IDataManagerDownloadController extends ng.IScope {
    file: IFileDownload;
    type: string;
    pdfName: string;
    pdfUrl: string;
    onError(error: IErrorDatamanagerDownload): void;
    onLoad(): void;
    getNavStyle(scroll: number): string;
    download(): void;
    print(): void;
}

export class DataManagerDownloadController implements ng.IController {
    static $inject: string[] = ['$injector', '$scope', '$rootScope', '$stateParams', 'ErrorService', '$window', '$templateCache', 'blockUI', 'DownloadService'];
    private injector: ng.Injectable<any>;
    private $rootScope: IMonacoScope;
    private $scope: IDataManagerDownloadController;
    private $stateParams: IStateParamsService;
    private $window: ng.IWindowService;
    private $templateCache: ng.ITemplateCacheService;
    private blockUI: ng.blockUI.BlockUIService
    private downloadService: IDownloadService;

    public constructor($injector: ng.Injectable<any>, $scope: IDataManagerDownloadController) {
        this.injector = $injector;
        this.$rootScope = this.injector.get('$rootScope');
        this.$scope = $scope;
        this.$stateParams = this.injector.get('$stateParams');
        this.$window = this.injector.get('$window');
        this.$templateCache = this.injector.get('$templateCache');
        this.blockUI = this.injector.get('blockUI');
        this.downloadService = this.injector.get('DownloadService');

        this.$templateCache.put('pdfViewerMenuTemplate', require('../view/dataManagerPDFViewerMenu.html'));
    }

    async $onInit() {
        this.initScopeFunctions();
        this.sendToDownload();
    }

    $onDestroy(): void { }

    private initScopeFunctions(): void {
        this.$scope.onError = (error: IErrorDatamanagerDownload): void => {
            this.injector.get('ErrorService').setError(error);
        }

        this.$scope.onLoad = (): void => {
            this.blockUI.stop();
        }

        this.$scope.getNavStyle = (scroll: number): string => {
            if (scroll > 100) return 'pdf-controls fixed';
            else return 'pdf-controls';
        }

        this.$scope.download = (): void => {
            this.downloadFile();
        }

        this.$scope.print = (): void => {
            const w = this.$window.open(this.$scope.file.url, '_blank');
            w.focus();
            w.print();
        }
    }

    private async sendToDownload(): Promise<void> {
        try {
            if (!this.$stateParams.hash) {
                return this.injector.get('ErrorService').badRequest('The request contains Null Parameters');
            }

            this.blockUI.start({ message: 'Fazendo download do arquivo' });

            const hash = this.$stateParams.hash;

            this.$scope.file = <IFileDownload>await this.downloadService.previewFileFromGoogleStorage(hash);

            if (this.$scope.file.type.indexOf('image') === 0) {
                this.$scope.type = 'image';
                this.previewImage();
            } else if (this.$scope.file.type === 'application/pdf') {
                this.$scope.type = 'pdf';
                this.previewPdf();
            } else {
                this.$scope.type = 'other';
                const self = this;

                this.$scope.$apply(function () {
                    self.blockUI.stop();
                    self.downloadFile();
                });
            }
        } catch (ex) {
            this.blockUI.stop();
            HandleError.exception(ex);
        }
    }

    private previewImage(): void {
        this.$scope.$apply(function () {
            this.blockUI.stop();
        });
    }

    private previewPdf(): void {
        this.$scope.pdfName = this.$scope.file.name;
        this.$scope.pdfUrl = this.$scope.file.url;

        this.$scope.$apply();
    }

    private downloadFile(): void {
        if (this.$rootScope.isIE) {
            window.navigator.msSaveOrOpenBlob(this.$scope.file.blob, this.$scope.file.name);
        } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([this.$scope.file.blob], { type: this.$scope.file.blob.type }));
            link.download = this.$scope.file.name;
            link.click();
        }
    }
}

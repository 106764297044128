import * as angular from 'angular';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog, } from '@services/GridFormService';
import { IRestService } from '@services/RestService';
import { IModalService } from '@services/ModalService';
import { IIncotermModel } from '@models/interface/product/IncotermModel';
import { IMonacoColumnDef } from '@services/GridService2';
import { SelectorModel } from '../../common/model/SelectorModel';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { GridColumnBuilder } from '../../common/GridColumnBuilder';
import { BrowserTitle } from "../../common/BrowserTitle";
import { ISessionService } from '@services/SessionService';
import { SSEService } from '@appServices/SSEService';
import { EOperation } from '@enums/GenericData';
import { HelperService } from "@services/HelperService";
import { IDocumentError } from '@models/interface/common/IDocumentError';

interface IIncotermScope extends IGridFormServiceScope {
    model: IIncotermModel;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    productList: SelectorModel[];
    originList: SelectorModel[];
    gridOptions: uiGrid.IGridOptions;
    typePaymentList: SelectorModel[];
    incomeList: SelectorModel[];
    outcomeList: SelectorModel[];
    scopeBeforeSave: IIncotermModel;
    user: any;
    sessionService: ISessionService;
    editIncoterm: (incoterm: IIncotermModel) => Promise<void>;
    viewIncoterm: (incoterm: IIncotermModel) => Promise<void>;
    viewLogIncoterm: (incoterm: IIncotermModel) => Promise<void>;
    copyIncoterm: (incoterm: IIncotermModel) => Promise<void>;
    addOrigin: () => void;
    removeOrigin: (index: number) => void;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
}

export class IncotermRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IIncotermScope;
    private $timeout: ng.ITimeoutService
    private $q: ng.IQService;
    private RestService: IRestService;
    private ModalService: IModalService;
    private SSEService: SSEService;
    private gridName: string;
    private helperService: HelperService;
    private modalID: number;

    constructor($injector: ng.Injectable<any>, $scope: IIncotermScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$timeout = $injector.get('$timeout');
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.gridName = 'GRID_INCOTERM';
        this.SSEService = new SSEService($injector, $scope);
        this.helperService = $injector.get('HelperService');
        this.modalID = null;
    }

    getUrlProduct() {
        const baseRout = '/product';
        const urlProduct = this.config.productUrl + baseRout;
        return urlProduct;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = [
                { PROPERTY: 'PRODUCT', READ: 'ID', LABEL: 'GENERAL.MENU.PRODUCT' },
                { PROPERTY: 'ACTIVE', LABEL: 'GENERAL.ACTIVE' },
                { PROPERTY: 'INITIALS', LABEL: 'GENERAL.CODE' },
                { PROPERTY: 'NAME', LABEL: 'GENERAL.NAME' },
                { PROPERTY: 'ORIGIN', LABEL: 'BASIC_DATA.ORIGIN' },
                { PROPERTY: 'TAX', LABEL: 'BASIC_DATA.CHARGE' },
                { PROPERTY: 'INCOME', LABEL: 'FINANCIAL.DEBIT' },
                { PROPERTY: 'OUTCOME', LABEL: 'FINANCIAL.CREDIT' },
                { PROPERTY: 'INTEGRATION_ID', LABEL: 'GENERAL.INTEGRATION_ID' },
                { PROPERTY: 'CREATED_AT', LABEL: 'GENERAL.CREATED_AT' },
                { PROPERTY: 'CREATED_BY', LABEL: 'GENERAL.CREATED_BY' },
                { PROPERTY: 'UPDATED_AT', LABEL: 'GENERAL.UPDATED_AT' },
                { PROPERTY: 'UPDATED_BY', LABEL: 'GENERAL.UPDATED_BY' }
            ];
            this.initForm(this, 'form', 'incoterm', 'GENERAL.MENU.INCOTERM', true);
            await this.initGrid(this.gridName, '/incoterm/list', true, true, null, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editIncoterm = async (incoterm: IIncotermModel): Promise<void> => {
            let blockedObject = {
                ID: incoterm.ID,
                NAME: incoterm.NAME,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || incoterm.ID !== this.$scope.model.ID) this.$scope.view(incoterm);
                } else if (this.$scope.operation !== EOperation.EDIT || incoterm.ID !== this.$scope.model.ID) {
                    this.$scope.edit(incoterm);
                }
            };
        }

        this.$scope.viewIncoterm = async (incoterm: IIncotermModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(incoterm);
        }

        this.$scope.viewLogIncoterm = async (incoterm: IIncotermModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(incoterm);
        }

        this.$scope.copyIncoterm = async (incoterm: IIncotermModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(incoterm);
        }

        this.$scope.addOrigin = () => {
            this.addOrigin();
        }

        this.$scope.removeOrigin = (index: number) => {
            this.removeOrigin(index);
        }

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewIncoterm(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editIncoterm(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogIncoterm(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyIncoterm(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy + modalIntegration),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };

        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    private buildColumns(columns: Array<string>) {

        let gridColumns: Array<IMonacoColumnDef> = [];

        const colInitials: IMonacoColumnDef = { name: 'INITIALS', displayName: "GENERAL.CODE", width: 80 };
        const colName: IMonacoColumnDef = { name: 'NAME', displayName: "GENERAL.NAME_INTL", width: 350 };
        const colProduct: IMonacoColumnDef = { name: 'PRODUCT.ID', displayName: "BASIC_DATA.PRODUCT", width: 350, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PRODUCT, null, "ID")}}</div>' };
        const colActive: IMonacoColumnDef = { name: 'ACTIVE', displayName: "GENERAL.ACTIVE", width: 70, cellFilter: "YesOrNo" };
        const colIntegrationID: IMonacoColumnDef = { name: 'INTEGRATION_ID', displayName: "GENERAL.INTEGRATION_ID", width: 150 };
        const colId: IMonacoColumnDef = { name: "ID", displayName: "GENERAL.ID", width: 80 };
        const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
        const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

        columns.forEach(column => {
            switch (column) {
                case 'INITIALS':
                    gridColumns.push(colInitials);
                    break;
                case 'NAME':
                    gridColumns.push(colName);
                    break;
                case 'PRODUCT':
                    gridColumns.push(colProduct);
                    break;
                case 'ACTIVE':
                    gridColumns.push(colActive);
                    break;
                case 'INTEGRATION_ID':
                    gridColumns.push(colIntegrationID);
                    break;
                case 'ID':
                    gridColumns.push(colId);
                    break;
                case 'CREATED_AT':
                    gridColumns.push(colCreatedAt);
                    break;
                case 'UPDATED_AT':
                    gridColumns.push(colUpdatedAt);
                    break;
            }
        });

        return gridColumns;
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            ACTIVE: true,
            INITIALS: null,
            NAME: null,
            PRODUCT: null,
            ORIGIN: [{ TAX: null, INCOME: null, OUTCOME: null }],
            INTEGRATION_ID: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            SEARCH_FIELDS: null,
            DOCUMENT_ERROR: null
        };
    }

    initDependencies(): Promise<any> {
        const self: IncotermRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getProductList(),
                self.getOriginList(),
                self.getTypePaymentList()
            ]).then((result: any) => {
                self.$scope.productList = result[0];
                self.$scope.originList = result[1];
                self.$scope.typePaymentList = result[2];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async getOriginList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/charge_origin`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async getTypePaymentList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_payment`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private addOrigin(): void {
        try {
            const origin = {
                TAX: null,
                INCOME: null,
                OUTCOME: null
            }

            if (!this.$scope.model.ORIGIN) this.$scope.model.ORIGIN = [];
            this.$scope.model.ORIGIN.push(angular.copy(origin));
            this.$timeout(() => {
                for (let i = 0; i < this.$scope.model.ORIGIN.length; i++) {
                    this.$scope.selectorValidity('tax' + i);
                    this.$scope.selectorValidity('income' + i);
                    this.$scope.selectorValidity('outcome' + i);
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeOrigin(index: number): Promise<void> {
        try {
            const thatTranslated = this.formService.getTranslate("GENERAL.GENDER.THAT", null, true);
            const originTranslated = this.formService.getTranslate("GENERAL.ORIGIN", null, true);
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.CLOSE",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thatTranslated, prop: originTranslated })
            });

            if (!modal) return;

            this.formService.block();
            // remove value from array
            this.$scope.model.ORIGIN.splice(index, 1);

            this.formService.unblock();

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "GENERAL.MENU.INTEGRATION" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number, idSubject: string): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/incoterm/sendSync`, { "idIncoterm": id, "idSubject": idSubject }, 120000, false);
                if (syncRequest) success = true;
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_SENDING_REQUEST');
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const legalPersonData = await this.getIncotermById(id);
                    if (row && legalPersonData && legalPersonData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = legalPersonData.DOCUMENT_ERROR;
                        documentError = legalPersonData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async getIncotermById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/incoterm/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")} (${this.$scope.model.NAME})`;
            BrowserTitle.$id = this.$scope.model.NAME;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")} (${this.$scope.model.NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === 'register' ? 'insert' : 'update';
        return {
            route: `/incoterm/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }
}

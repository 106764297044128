import angular = require('angular');
import { FormService2, IFormServiceScope } from '@services/FormService2';
import { OperationalService } from '@services/OperationalService';
import { INewProcessChargeModel, INewProcessCharge } from '@models/interface/operational/NewProcessTabsModel';
import { OUTCOME, INCOME } from '@models/interface/product/OfferCompiled';
import { EApplicationComplementId, EOperation } from '@enums/GenericData';
import { INewProcessScope, ECollapseState } from './NewProcessController';
import { ITableOptions } from '../../app/directives/monaco-data-table';
import { IModalInstanceService } from 'angular-ui-bootstrap';

interface INewProcessChargeOutDateScope extends ng.IScope {
    model: INewProcessChargeModel;
    chargesTableOptions: ITableOptions;
    chargesTableList: INewProcessCharge[];
    collapseChargeOutDate: () => void;
}

interface IChargeModalScope extends IFormServiceScope {
    currentIndex: number;
    currentDisplayIndex: number;
    charge: INewProcessCharge;
    isOutcomeWeightRangeApplication: boolean;
    isIncomeWeightRangeApplication: boolean;

    hasPreviousCharge: (currentIndex: number) => boolean;
    hasNextCharge: (currentIndex: number) => boolean;
    previousCharge: (currentIndex: number) => void;
    nextCharge: (currentIndex: number) => Promise<void>;
    closeChargeModal: () => void;
}

export class NewProcessChargeOutDateController {
    static $inject: string[] = ['$scope'];
    private $scope: INewProcessChargeOutDateScope;
    private $newProcessScope: INewProcessScope;
    private $filter: ng.FilterFactory;
    private $compile: angular.ICompileService;
    private formService: FormService2;
    private operationalService: OperationalService;
    private modalChargeId: number;

    constructor($scope: INewProcessChargeOutDateScope) {
        this.$scope = $scope;
        this.$newProcessScope = <INewProcessScope>$scope.$parent.$parent;
        this.$filter = this.$newProcessScope.$filter;
        this.$compile = this.$newProcessScope.$compile;
        this.formService = this.$newProcessScope.formService;
        this.operationalService = this.$newProcessScope.operationalService;
        this.modalChargeId = 0;
        this.initScopeFunctions();
    }

    async $onInit(): Promise<void> {
        try {
            this.$scope.chargesTableOptions = this.getChargesTableOptions();
            this.initModel();
            await this.initDependencies();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initModel(): void {
        this.$scope.model = {
            CHARGE: null,
            TOTAL_CHARGES: null
        };
    }

    private initScopeFunctions(): void {

        this.$scope.collapseChargeOutDate = (): void => {
            this.collapseChargeOutDate();
        }
    }

    async initDependencies(): Promise<any> {
        this.initCollapseEvents();
    }

    private initCollapseEvents() {
        this.$scope.$on('processChargeOutDateCollapse', () => {
            this.collapseChargeOutDate();
        });

        const collapseCharge = angular.element('#collapseChargeOutDate');
        if (collapseCharge) {
            collapseCharge.on('shown.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    await this.getProcessTabsCharge();
                    angular.element("#collapseChargeOutDateHeading").attr('aria-expanded', 'true');
                    // update collapse state
                    this.$newProcessScope.collapseState = { panel: ECollapseState.CHARGE_OUT_DATE, released: false, nextState: null };
                    this.$newProcessScope.repositionPanels(ECollapseState.CHARGE_OUT_DATE, true);
                    this.$newProcessScope.disableElements(this.$newProcessScope.operation == EOperation.VIEW);
                }
            });
            collapseCharge.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseChargeOutDateHeading").attr('aria-expanded', 'false');
                }
            });
        }
    }

    private getChargesTableOptions(): ITableOptions {
        return {
            persistName: "newProcessChargeOutDate",
            pagination: false,
            search: true,
            advancedSearch: false,
            showSearchClearButton: true,
            clickToSelect: true,
            singleSelect: true,
            showLoading: true,
            showColumns: true,
            showColumnsSearch: true,
            showColumnsToggleAll: true,
            showFullscreen: true,
            showToggle: false,
            showMultiSort: false,
            showMultiSortButton: false,
            fixedColumns: true,
            fixedNumber: 1,
            filterControl: false,
            crudButtons: {
                edit: { fn: async (index: number, displayIndex: number) => { this.editCharge(index, displayIndex); }, name: "editCharge" },
            },
            showColumnsRename: [
                { dataField: "CHARGE", title: this.formService.getTranslate("BASIC_DATA.CHARGE") },
                { dataField: "OUTCOME.CHARGE_EXHIBITION", title: this.formService.getTranslate("BASIC_DATA.DISPLAY_CHARGE") },
                { dataField: "OUTCOME.CHARGE_EXHIBITION.NAME", title: this.formService.getTranslate("FINANCIAL.CHARGE_DISPLAY_NAME_C") },
                { dataField: "OUTCOME", title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS_C") },
                { dataField: "OUTCOME.AMOUNT", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.QUANTITY_C") },
                { dataField: "OUTCOME.CURRENCY", title: this.formService.getTranslate("GENERAL.CURRENCY_C") },
                { dataField: "OUTCOME.UNITARY", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY_C") },
                { dataField: "OUTCOME.MINIMUM", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM_C") },
                { dataField: "OUTCOME.TOTAL", title: this.formService.getTranslate("GENERAL.TOTAL_C") },
                { dataField: "OUTCOME.INVOICE_NUMBER", title: this.formService.getTranslate("FINANCIAL.TOTAL_BRL_C") },
                { dataField: "OUTCOME.TRANSACTION", title: this.formService.getTranslate("GENERAL.HOLDER_TYPE_C") },
                { dataField: "OUTCOME.PEOPLE", title: this.formService.getTranslate("GENERAL.HOLDER_C") },
                { dataField: "OUTCOME.MODALITY", title: this.formService.getTranslate("FINANCIAL.CHARGE_PAYMENT_METHOD_C") },
                { dataField: "OUTCOME.VALIDITY_OF", title: this.formService.getTranslate("PRODUCT.VALIDITY_START_C") },
                { dataField: "OUTCOME.VALIDITY_UP", title: this.formService.getTranslate("PRODUCT.VALIDITY_END_C") },
                { dataField: "OUTCOME.SHOW_FOR_GROUP", title: this.formService.getTranslate("FINANCIAL.SHOWN_FOR_C") },
                { dataField: "OUTCOME.COMPOSITION_GROUP", title: this.formService.getTranslate("GENERAL.COMPOSITION_C") },
                { dataField: "OUTCOME.OBSERVATION", title: this.formService.getTranslate("GENERAL.REMARKS_C") },
                { dataField: "OUTCOME.CHARGE_ORIGIN.NAME", title: this.formService.getTranslate("BASIC_DATA.ORIGIN_C") },
                { dataField: "INCOME.CHARGE_EXHIBITION", title: this.formService.getTranslate("BASIC_DATA.DISPLAY_CHARGE") },
                { dataField: "INCOME.CHARGE_EXHIBITION.NAME", title: this.formService.getTranslate("FINANCIAL.CHARGE_DISPLAY_NAME_V") },
                { dataField: "INCOME", title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS_V") },
                { dataField: "INCOME.AMOUNT", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.QUANTITY_V") },
                { dataField: "INCOME.CURRENCY", title: this.formService.getTranslate("GENERAL.CURRENCY_V") },
                { dataField: "INCOME.UNITARY", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY_V") },
                { dataField: "INCOME.TOTAL", title: this.formService.getTranslate("FINANCIAL.TOTAL_BRL_V") },
                { dataField: "INCOME.INVOICE_NUMBER", title: this.formService.getTranslate("GENERAL.INVOICES_V") },
                { dataField: "INCOME.TRANSACTION", title: this.formService.getTranslate("GENERAL.HOLDER_TYPE_V") },
                { dataField: "INCOME.PEOPLE", title: this.formService.getTranslate("GENERAL.HOLDER_V") },
                { dataField: "INCOME.MODALITY", title: this.formService.getTranslate("FINANCIAL.CHARGE_PAYMENT_METHOD_V") },
                { dataField: "INCOME.VALIDITY_OF", title: this.formService.getTranslate("PRODUCT.VALIDITY_START_V") },
                { dataField: "INCOME.VALIDITY_UP", title: this.formService.getTranslate("PRODUCT.VALIDITY_END_V") },
                { dataField: "INCOME.SHOW_FOR_GROUP", title: this.formService.getTranslate("FINANCIAL.SHOWN_FOR_V") },
                { dataField: "INCOME.COMPOSITION_GROUP", title: this.formService.getTranslate("GENERAL.COMPOSITION_V") },
                { dataField: "INCOME.OBSERVATION", title: this.formService.getTranslate("GENERAL.REMARKS_V") },
                { dataField: "INCOME.CHARGE_ORIGIN.NAME", title: this.formService.getTranslate("BASIC_DATA.ORIGIN_V") },
            ],
            headerStyle: (column: BootstrapTableColumn) => {
                let headerStyleObj = {};
                if (column.field.search("OUTCOME") >= 0) headerStyleObj = { css: { 'background': "#f2dede" } };
                else if (column.field.search("INCOME") >= 0) headerStyleObj = { css: { 'background': "#dff0d8" } };
                return headerStyleObj;
            },
            onPostBody: (chargeList: any[]) => {
                this.$scope.chargesTableList = chargeList;
                this.$compile(angular.element("#chargeOutDateTable"))(this.$scope);
                return true;
            },
            columns: [
                { field: 'selected', title: this.formService.getTranslate("GENERAL.UI_SELECT.SELECT"), checkbox: true, showSelectTitle: true, cellStyle: () => { return { css: { width: '80px' } } } },
                { field: 'CHARGE', title: this.formService.getTranslate("BASIC_DATA.CHARGE"), formatter: (data) => { return `<span style="max-width: 100%" class="ellipsize" ellipsis-tooltip tooltip-placement="auto top" tooltip-enable="true" tooltip-append-to-body="true" uib-tooltip="${data ? data.NAME : data}">${data ? data.NAME : data}</span>` }, sortable: true, searchable: true, cellStyle: () => { return { css: { 'max-width': '200px' } } } },
                { field: 'OUTCOME.CHARGE_EXHIBITION', title: this.formService.getTranslate("FINANCIAL.CHARGE_CODE"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'OUTCOME.CHARGE_EXHIBITION.NAME', title: this.formService.getTranslate("FINANCIAL.CHARGE_DISPLAY_NAME"), sortable: true, searchable: true, visible: false },
                { field: 'OUTCOME', title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS"), formatter: (data: OUTCOME) => { return `<span style="max-width: 100%" class="ellipsize" ellipsis-tooltip tooltip-placement="auto top" tooltip-enable="true" tooltip-append-to-body="true" uib-tooltip="${this.chargeTableApplicationFormatter(data)}">${this.chargeTableApplicationFormatter(data)}</span>` }, sortable: true, searchable: true, cellStyle: () => { return { css: { 'max-width': '200px' } } } },
                { field: 'OUTCOME.AMOUNT', title: this.formService.getTranslate("GENERAL.QUANTITY"), sortable: true, searchable: true },
                { field: 'OUTCOME.CURRENCY', title: this.formService.getTranslate("GENERAL.CURRENCY"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'OUTCOME.UNITARY', title: this.formService.getTranslate("BASIC_DATA.UNIT"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'OUTCOME.MINIMUM', title: this.formService.getTranslate("BASIC_DATA.MINIMUM"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'OUTCOME.TOTAL', title: this.formService.getTranslate("GENERAL.TOTAL"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'OUTCOME.INVOICE_NUMBER', title: this.formService.getTranslate("GENERAL.INVOICES"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'OUTCOME.TRANSACTION', title: this.formService.getTranslate("GENERAL.HOLDER_TYPE"), formatter: (data) => { return `<span style="max-width: 100%" class="ellipsize" ellipsis-tooltip tooltip-placement="auto top" tooltip-enable="true" tooltip-append-to-body="true" uib-tooltip="${data ? data.NAME : '-'}">${data ? data.NAME : '-'}</span>` }, sortable: true, searchable: true, visible: false, cellStyle: () => { return { css: { 'max-width': '150px' } } } },
                { field: 'OUTCOME.PEOPLE', title: this.formService.getTranslate("GENERAL.HOLDER"), formatter: (data) => { return `<span style="max-width: 100%" class="ellipsize" ellipsis-tooltip tooltip-placement="auto top" tooltip-enable="true" tooltip-append-to-body="true" uib-tooltip="${data ? data.NAME : '-'}">${data ? data.NAME : '-'}</span>` }, sortable: true, searchable: true, visible: false, cellStyle: () => { return { css: { 'max-width': '150px' } } } },
                { field: 'OUTCOME.MODALITY', title: this.formService.getTranslate("FINANCIAL.CHARGE_PAYMENT_METHOD"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'OUTCOME.VALIDITY_OF', title: this.formService.getTranslate("PRODUCT.VALIDITY_START"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true, visible: true },
                { field: 'OUTCOME.VALIDITY_UP', title: this.formService.getTranslate("PRODUCT.VALIDITY_END"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true, visible: true },
                { field: 'OUTCOME.SHOW_FOR_GROUP', title: this.formService.getTranslate("FINANCIAL.SHOWN_FOR"), formatter: (data) => { return `<span style="max-width: 100%" class="ellipsize" ellipsis-tooltip tooltip-placement="auto top" tooltip-enable="true" tooltip-append-to-body="true" uib-tooltip="${data ? this.$newProcessScope.getCONCAT(data, null, "CODE") : data}">${data ? this.$newProcessScope.getCONCAT(data, null, "CODE") : data}</span>` }, sortable: true, searchable: true, visible: false, cellStyle: () => { return { css: { 'max-width': '150px' } } } },
                { field: 'OUTCOME.COMPOSITION_GROUP', title: this.formService.getTranslate("GENERAL.COMPOSITION"), formatter: (data) => data ? this.$newProcessScope.getCONCAT(data) : data, sortable: true, searchable: true, visible: false },
                { field: 'OUTCOME.OBSERVATION', title: this.formService.getTranslate("GENERAL.REMARKS"), sortable: true, searchable: true, visible: false },
                { field: 'OUTCOME.CHARGE_ORIGIN.NAME', title: this.formService.getTranslate("BASIC_DATA.ORIGIN"), sortable: true, searchable: true, visible: false },
                { field: 'INCOME.CHARGE_EXHIBITION', title: this.formService.getTranslate("FINANCIAL.CHARGE_CODE"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'INCOME.CHARGE_EXHIBITION.NAME', title: this.formService.getTranslate("FINANCIAL.CHARGE_DISPLAY_NAME"), sortable: true, searchable: true, visible: false },
                { field: 'INCOME', title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS"), formatter: (data: INCOME) => { return `<span style="max-width: 100%" class="ellipsize" ellipsis-tooltip tooltip-placement="auto top" tooltip-enable="true" tooltip-append-to-body="true" uib-tooltip="${this.chargeTableApplicationFormatter(data)}">${this.chargeTableApplicationFormatter(data)}</span>` }, sortable: true, searchable: true, cellStyle: () => { return { css: { 'max-width': '200px' } } } },
                { field: 'INCOME.AMOUNT', title: this.formService.getTranslate("GENERAL.QUANTITY"), sortable: true, searchable: true },
                { field: 'INCOME.CURRENCY', title: this.formService.getTranslate("GENERAL.CURRENCY"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'INCOME.UNITARY', title: this.formService.getTranslate("BASIC_DATA.UNIT"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'INCOME.MINIMUM', title: this.formService.getTranslate("BASIC_DATA.MINIMUM"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'INCOME.TOTAL', title: this.formService.getTranslate("GENERAL.TOTAL"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'INCOME.INVOICE_NUMBER', title: this.formService.getTranslate("GENERAL.INVOICES"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'INCOME.TRANSACTION', title: this.formService.getTranslate("GENERAL.HOLDER_TYPE"), formatter: (data) => { return `<span style="max-width: 100%" class="ellipsize" ellipsis-tooltip tooltip-placement="auto top" tooltip-enable="true" tooltip-append-to-body="true" uib-tooltip="${data ? data.NAME : '-'}">${data ? data.NAME : '-'}</span>` }, sortable: true, searchable: true, visible: false, cellStyle: () => { return { css: { 'max-width': '150px' } } } },
                { field: 'INCOME.PEOPLE', title: this.formService.getTranslate("GENERAL.HOLDER"), formatter: (data) => { return `<span style="max-width: 100%" class="ellipsize" ellipsis-tooltip tooltip-placement="auto top" tooltip-enable="true" tooltip-append-to-body="true" uib-tooltip="${data ? data.NAME : '-'}">${data ? data.NAME : '-'}</span>` }, sortable: true, searchable: true, visible: false, cellStyle: () => { return { css: { 'max-width': '150px' } } } },
                { field: 'INCOME.MODALITY', title: this.formService.getTranslate("FINANCIAL.CHARGE_PAYMENT_METHOD"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'INCOME.VALIDITY_OF', title: this.formService.getTranslate("PRODUCT.VALIDITY_START"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true, visible: true },
                { field: 'INCOME.VALIDITY_UP', title: this.formService.getTranslate("PRODUCT.VALIDITY_END"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true, visible: true },
                { field: 'INCOME.SHOW_FOR_GROUP', title: this.formService.getTranslate("FINANCIAL.SHOWN_FOR"), formatter: (data) => { return `<span style="max-width: 100%" class="ellipsize" ellipsis-tooltip tooltip-placement="auto top" tooltip-enable="true" tooltip-append-to-body="true" uib-tooltip="${data ? this.$newProcessScope.getCONCAT(data, null, "CODE") : data}">${data ? this.$newProcessScope.getCONCAT(data, null, "CODE") : data}</span>` }, sortable: true, searchable: true, visible: false, cellStyle: () => { return { css: { 'max-width': '150px' } } } },
                { field: 'INCOME.COMPOSITION_GROUP', title: this.formService.getTranslate("GENERAL.COMPOSITION"), formatter: (data) => data ? this.$newProcessScope.getCONCAT(data) : data, sortable: true, searchable: true, visible: false },
                { field: 'INCOME.OBSERVATION', title: this.formService.getTranslate("GENERAL.REMARKS"), sortable: true, searchable: true, visible: false },
                { field: 'INCOME.CHARGE_ORIGIN.NAME', title: this.formService.getTranslate("BASIC_DATA.ORIGIN"), sortable: true, searchable: true, visible: false }
            ],
        };
    }

    private chargeTableApplicationFormatter(charge: OUTCOME | INCOME): string {
        let display = "";
        if (charge && charge.APPLICATION) {
            display += charge.APPLICATION.CODE;
            if (charge.APPLICATION.APPLICATION_COMPLEMENT) {
                if (charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE && charge.WEIGHT_RANGE) display += ` (${charge.WEIGHT_RANGE.NAME})`;
                if (charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.EQUIPMENT && charge.EQUIPMENT) display += ` (${charge.EQUIPMENT.CODE})`;
                if (charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.VEHICLE && charge.VEHICLE_TYPE) display += ` (${charge.VEHICLE_TYPE.NAME})`;
            }
        }
        return display;
    }

    private async collapseChargeOutDate() {
        try {
            if (this.$newProcessScope.collapseState.released || this.$newProcessScope.collapseState.panel == ECollapseState.CHARGE_OUT_DATE) {
                const collapseCharge = angular.element("#collapseChargeOutDate");
                if (collapseCharge) {
                    const isCollapsed = angular.element("#collapseChargeOutDateHeading").attr("aria-expanded") == "true";
                    const product = this.$newProcessScope.model.PRODUCT;
                    if (isCollapsed) {
                        this.$newProcessScope.collapseState.released = true;
                    }
                    collapseCharge['collapse']('toggle');
                    if (isCollapsed) this.$newProcessScope.repositionPanels(ECollapseState.CHARGE_OUT_DATE);
                }
            } else {
                this.$newProcessScope.collapseState.nextState = ECollapseState.CHARGE_OUT_DATE;
                this.$newProcessScope.releaseCollapse();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getProcessTabsCharge(): Promise<void> {
        this.formService.block();
        try {
            const chargeTab = await this.operationalService.get(`/process/panel/chargeOutDate/${this.$newProcessScope.model.ID}`, 30000);
            if (chargeTab && chargeTab.data && chargeTab.data.data) {
                this.$scope.model = {
                    CHARGE: chargeTab.data.data,
                    TOTAL_CHARGES: null
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.chargesTableOptions.load(null, true);
            this.formService.unblock();
        }
    }

    private async buildChargeModalScope(index: number, displayIndex: number, charge: INewProcessCharge): Promise<IChargeModalScope> {
        try {
            const modalScope: IChargeModalScope = await this.$newProcessScope.modalService.getModalScope(this.modalChargeId);

            if (charge.OUTCOME && charge.OUTCOME.WEIGHT_RANGE_CHARGE) charge.OUTCOME.WEIGHT_RANGE_CHARGE = charge.OUTCOME.WEIGHT_RANGE_CHARGE.sort((x, y) => x.WEIGHT_RANGE.ID < y.WEIGHT_RANGE.ID ? -1 : 1);
            if (charge.INCOME && charge.INCOME.WEIGHT_RANGE_CHARGE) charge.INCOME.WEIGHT_RANGE_CHARGE = charge.INCOME.WEIGHT_RANGE_CHARGE.sort((x, y) => x.WEIGHT_RANGE.ID < y.WEIGHT_RANGE.ID ? -1 : 1);

            modalScope.currentIndex = index;
            modalScope.currentDisplayIndex = displayIndex;
            modalScope.charge = angular.copy(charge);
            modalScope.isOutcomeWeightRangeApplication = charge.OUTCOME && charge.OUTCOME.CHARGE && charge.OUTCOME.APPLICATION && charge.OUTCOME.APPLICATION.APPLICATION_COMPLEMENT && charge.OUTCOME.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
            modalScope.isIncomeWeightRangeApplication = charge.INCOME && charge.INCOME.CHARGE && charge.INCOME.APPLICATION && charge.INCOME.APPLICATION.APPLICATION_COMPLEMENT && charge.INCOME.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;

            modalScope.hasPreviousCharge = (currentDisplayIndex: number) => {
                return currentDisplayIndex > 0;
            }

            modalScope.previousCharge = (currentDisplayIndex: number) => {
                this.previousCharge(currentDisplayIndex);
            }

            modalScope.hasNextCharge = (currentDisplayIndex: number) => {
                return (currentDisplayIndex || currentDisplayIndex == 0) && this.$scope.chargesTableList && (this.$scope.chargesTableList.length - 1 > currentDisplayIndex);
            }

            modalScope.nextCharge = async (currentDisplayIndex: number) => {
                await this.nextCharge(currentDisplayIndex);
            }

            modalScope.closeChargeModal = () => {
                this.$newProcessScope.modalService.closeModal(this.modalChargeId);
                this.modalChargeId = 0;
            }

            return modalScope;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async previousCharge(currentDisplayIndex: number) {
        const modalScope: IChargeModalScope = await this.$newProcessScope.modalService.getModalScope(this.modalChargeId);
        modalScope.currentIndex = currentDisplayIndex--;
        modalScope.currentDisplayIndex--;
        modalScope.charge = this.$scope.model.CHARGE[currentDisplayIndex--];

        modalScope.isOutcomeWeightRangeApplication = modalScope.charge.OUTCOME && modalScope.charge.OUTCOME.CHARGE && modalScope.charge.OUTCOME.APPLICATION && modalScope.charge.OUTCOME.APPLICATION.APPLICATION_COMPLEMENT && modalScope.charge.OUTCOME.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
        modalScope.isIncomeWeightRangeApplication = modalScope.charge.INCOME && modalScope.charge.INCOME.CHARGE && modalScope.charge.INCOME.APPLICATION && modalScope.charge.INCOME.APPLICATION.APPLICATION_COMPLEMENT && modalScope.charge.INCOME.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;

        modalScope.$applyAsync();
    }

    private async nextCharge(currentDisplayIndex: number) {
        const modalScope: IChargeModalScope = await this.$newProcessScope.modalService.getModalScope(this.modalChargeId);
        modalScope.currentIndex = currentDisplayIndex++;
        modalScope.currentDisplayIndex++;
        modalScope.charge = this.$scope.model.CHARGE[currentDisplayIndex++];

        modalScope.isOutcomeWeightRangeApplication = modalScope.charge.OUTCOME && modalScope.charge.OUTCOME.CHARGE && modalScope.charge.OUTCOME.APPLICATION && modalScope.charge.OUTCOME.APPLICATION.APPLICATION_COMPLEMENT && modalScope.charge.OUTCOME.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
        modalScope.isIncomeWeightRangeApplication = modalScope.charge.INCOME && modalScope.charge.INCOME.CHARGE && modalScope.charge.INCOME.APPLICATION && modalScope.charge.INCOME.APPLICATION.APPLICATION_COMPLEMENT && modalScope.charge.INCOME.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;

        modalScope.$applyAsync();
    }

    private async editCharge(index: number, displayIndex: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const charge = this.$scope.model.CHARGE && this.$scope.model.CHARGE.length ? this.$scope.model.CHARGE[index] : null;
            if (!charge) throw Error('charge is null');

            this.modalChargeId = this.$newProcessScope.modalService.newModal();
            const modalInstance: IModalInstanceService = await this.$newProcessScope.modalService.showModalInfo(
                {
                    modalID: this.modalChargeId,
                    scope: this.$scope,
                    formService: EOperation.VIEW,
                    template: require("../view/modal/processChargeOutDateModal.html"),
                    size: 'full',
                    keyboard: true,
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing") {
                            if (reason.toString() == "escape key press") event.preventDefault();
                            if (!closed) {
                                const modalScope: IChargeModalScope = await this.$newProcessScope.modalService.getModalScope(this.modalChargeId);
                                await modalScope.closeChargeModal();
                            }
                        }
                    }
                },
                null
            );
            modalInstance.rendered.then(async () => {
                const modalScope = await this.buildChargeModalScope(index, displayIndex, charge);
                modalScope.$applyAsync();
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }
}

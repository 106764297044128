import { IHttpResponse, IScope } from "angular";
import { IModalService } from "./ModalService";
import { FormService2, IFormServiceScope } from "./FormService2";
import { HandleError } from "../common/util/HandleError";
import { EOperation } from "@enums/GenericData";

export type BatchItem<M = object, F = object> = IBatchItem<M, F>;
interface IBatchItem<M = object, F = object> {
    identifier: F,
    document?: M,
    failed: { error: object },
}

export type BatchError = IBatchError;
interface IBatchError {
    identifier: string,
    error: object | string,
}

type BatchFormattingFunction = (batchItems: BatchItem[]) => Promise<BatchError[]>;

interface IBatchErrorScope extends IFormServiceScope {
    operation: string;
    batchErrors: BatchError[];
}

export class BatchViewService {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IBatchErrorScope;
    private ModalService: IModalService;
    private FormService: FormService2;
    //
    private modalID: number;

    constructor($injector: ng.Injectable<any>, $scope: IScope) {
        try {
            this.$scope = <IBatchErrorScope>$scope.$new(true);
            this.ModalService = $injector.get('ModalService');
            this.FormService = new FormService2($injector, this.$scope);
            // declarations
            this.modalID = null;
        } catch (ex) {
            throw ex;
        }
    }

    onDestroy(): void {
        try {
            if (this.modalID) {
                this.ModalService.closeModal(this.modalID);
                this.modalID = null;
            }
        } catch (ex) {
            throw ex;
        }
    }

    public handleBatchResponse(error: IHttpResponse<IHttpResponse<object>> | Error | TypeError, batchFormatingFunction?: BatchFormattingFunction, context?: object) {

        const isException = (!error || error instanceof Error || error instanceof TypeError);
        if (isException) return this.handleError(error);

        const response = <IHttpResponse<IHttpResponse<object>>>error;
        const batchProcessingReturn: BatchItem[] = (typeof response.data.data === 'object' && response.data.data.hasOwnProperty('batch')) ? response.data.data['batch'] : [];
        const warning = ((response.status > 401 && response.status < 500) || batchProcessingReturn.length > 0) ? true : false;

        if (warning)
            if (batchProcessingReturn.length > 0)
                this.handleWarning(`Alguns itens não puderam ser processados. <b>Clique nesta mensagem para visualizar os erros</b>`, () => { return this.formatBatchReturnAndInvokeModal(batchProcessingReturn, batchFormatingFunction, context) });
            else
                this.handleError(response);
        else
            this.handleError(response);
    }

    public async formatBatchReturnAndInvokeModal(batchItems: BatchItem[], batchFormatingFunction?: BatchFormattingFunction, context?: object): Promise<void> {
        try {
            const failedbatchItems = batchItems.filter(x => x.failed && x.identifier);

            let batchErrors: BatchError[] = [];
            if (batchFormatingFunction && context) {
                batchErrors = await batchFormatingFunction.call(context, failedbatchItems);
            } else {
                batchErrors = failedbatchItems.map(x => ({ identifier: x.identifier[Object.keys(x.identifier)[0]], error: x.failed.error }));
            }
            this.invokeBatchReturnView(batchErrors);
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    private async invokeBatchReturnView(batchErrors: BatchError[]) {
        try {
            this.block();

            this.$scope.operation = EOperation.VIEW;
            this.FormService.initStandAlone('batchErrors');

            if (batchErrors.length === 0) return this.handleWarning('Nenhum erro para ser visualizado!');

            // normalize all errors to string
            this.$scope.batchErrors = batchErrors.map(x => {
                let msg: string = null;
                if (typeof x.error === 'string') {
                    msg = x.error
                } else {
                    const data = HandleError.buildErrorMessage(x.error);
                    msg = (data && data.msg) ? data.msg : 'Não foi possível reconhecer o erro';
                }
                return { identifier: x.identifier, error: msg }
            });

            await this.batchErrorsModal();
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }


    private async batchErrorsModal(): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    scope: this.$scope,
                    formService: this.$scope.operation,
                    template: require("../common/view/batchErrorView.html"),
                    size: 'vlg',
                },
                {
                    actionButtonText: 'Fechar',
                    headerText: `Erros encontrados na operação`
                });
            await this.ModalService.getModalScope(this.modalID);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private handleError(error: any): void {
        return this.FormService.handleError(error);
    }
    private handleWarning(error: any, callback?): void {
        return this.FormService.handleWarning(error, callback);
    }
    private block(): void {
        return this.FormService.block();
    }
    private unblock(): void {
        return this.FormService.unblock();
    }


    public static isBatchItemRelated<F = object>(batchItem: BatchItem<object, F>, matchableUniqueFilter: typeof batchItem.identifier): boolean {
        if (!batchItem || !batchItem.identifier) return false;
        if (!matchableUniqueFilter || Object.keys(matchableUniqueFilter).length === 0) return false;

        let isEqual = false;
        let keyMatch = false;

        for (const key of Object.keys(matchableUniqueFilter)) {
            if (batchItem.identifier.hasOwnProperty(key) && typeof batchItem.identifier[key] !== 'undefined' && batchItem.identifier[key]) {
                isEqual = (batchItem.identifier[key] === matchableUniqueFilter[key]);
                keyMatch = true;
            }
        }
        // try matching again, now directly into document (if there's any)
        if (!keyMatch && batchItem.document) {
            for (const key of Object.keys(matchableUniqueFilter)) {
                if (batchItem.document.hasOwnProperty(key) && typeof batchItem.document[key] !== 'undefined' && batchItem.document[key]) {
                    isEqual = (batchItem.identifier[key] === matchableUniqueFilter[key]);
                }
            }
        }
        return isEqual;
    }
}

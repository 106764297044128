export const enum EOperation {
    NEW = "register",
    EDIT = "edit",
    VIEW = "view",
    COPY = "copy",
    LIST = "list",
    LOG = "viewLog",
    REMOVE = 'remove'
}

export const enum EProductId {
    MARITIME_IMPORT = "IM",
    MARITIME_EXPORT = "EM",
    AIR_IMPORT = "IA",
    AIR_EXPORT = "EA",
    ROAD_IMPORT = "IR",
    ROAD_EXPORT = "ER",
    ROAD_NATIONAL = "NR"
}

export const enum ECargoTypeId {
    AIR = "1",
    BREAK_BULK = "2",
    FCL = "3",
    LCL = "4",
    RO_RO = "5",
    ROAD = "6"
}

export const enum ETypeLocalId {
    DEPOT = "1",
    PORT = "2",
    DOOR = "3",
    AIRPORT = "4",
    TERMINAL = "5"
}

export const enum EIntegrationRegisterTypeId {
    CHARGE = "1",
    EVENT = "2",
    ROUTING_POINT = "3",
    AGENT = "4",
    BROKER = "5",
    PROVIDER = "6",
    BRANCH = "7",
    SHIP_OWNER = "8",
    EQUIPMENT = "9"
}

export const enum EPaymentNatureId {
    PAYMENT = "1",
    RECEIVING = "2"
}

export const enum EChargeOriginId {
    FREIGHT = "1",
    ORIGIN = "2",
    DESTINATION = "3",
    COMISSION = "4",
    DET_DEM = "5",
    INSURANCE = "6",
    TAX = "7",
    OTHERS = "8"
}

export const enum EApplicationId {
    SCOB = "1",
    FILE = "2",
    NCW = "3",
    CNTR = "4",
    EQPT = "5",
    PERC = "6",
    HAWB = "7",
    HBL = "8",
    MBL = "9",
    MAWB = "10",
    VOL = "11",
    GW = "12",
    UN = "13",
    INV = "14",
    WM = "15",
    TVEIC = "16",
    VEICU = "17",
    TEU = "18",
    CW = "19",
    IMS = "20",
    PSM = "21",
    LIV = "22",
    CBM = "23",
    PPS = "24",
    COM = "25",
    GWT = "26",
    SGW = "27",
    PSNF = "29"
}

export const enum ETypePaymentId {
    COLLECT = "2",
    COLLECT_ELSEWHERE = "5",
    PREPAID_ABROAD = "4",
    PREPAID = "3"
}

export const enum ETransactionId {
    AGENCY_MARITIME = "1",
    LOCAL_AGENT = "2",
    EXTERNAL_AGENT = "3",
    COMMISSIONED = "4",
    TRANSPORT_COMPANY = "5",
    BROKER = "6",
    SALES_PERSON = "7",
    OTHER = "8",
    ACCOUNT = "9",
    EXPORTER = "10",
    IMPORTER = "11",
    REPRESENTATIVE = "12",
    INTERNATION_ASSOCIATION = "13",
    INSURANCE_BROKER = "14",
    DESTINATION_REPRESENTATIVE = "15",
    OTHER_AGENT = "16",
    NOTIFY = "17"
}

export const enum EAccountTypeId {
    CORPORATIVE_ACCOUNT = "1",
    SALES_FORCE = "2"
}

export const enum EEquipmentSizeId {
    FEET_20 = "1",
    FEET_40 = "2",
    FEET_60 = "3",
}

export const enum EEquipmentGroupId {
    STANDARD = "1",
    REEFER = "2",
    SPECIAL = "3"
}

export const enum EDirectionId {
    ORIGIN = "1",
    DESTINATION = "2"
}

export const enum EOfferFormatedDataTypeId {
    TARIFF_FREIGHT = "1",
    TARIFF_LOCAL = "2",
    TARIFF_DOMESTIC = "3",
    TARIFF_DET_DEM = "4"
}

export const enum EDocumentTypeId {
    COMMERCIAL_INVOICE = "1",
    PACKING_LIST = "2",
    PURCHASE_ORDER = "3",
    DOCUMENTS = "4",
    BOOKING = "5",
    AMS_ISF = "6",
    AGENT_REFERENCE = "7",
    SECOND_REFERENCE = "8",
    FATURA = "9",
    ACERTO_AGENTE = "10",
    ORIGINAL_MASTER = "11",
    ORIGINAL_HOUSE = "12",
    DRAFT_HOUSE = "13",
    PRE_ALERT = "14",
    FATURA_ABROAD = "15",
    DRAFT_MASTER = "16",
    DRAFT_HOUSE_APROVADO = "17",
    DRAFT_MASTER_APROVADO = "18",
    CE_MERCANTE = "19",
    PRE_INVOICE = "20",
    SEGURO_AVERBACAO = "21",
    INVOICE_INTERCOMPANY = "22",
    PRE_INVOICE_INTERCOMPANY = "23",
    BOLETO = "24",
    NOTA_FISCAL = "25",
    FATURA_FINAL = "26",
    TERMO_DEMURRAGE = "27",
    CI = "28",
    OFERTA = "29",
    CONTRATO = "30",
    MEMORANDO = "31",
    BOOKING_ARMADOR = "32",
    ISF = "33",
    DOCUMENTOS_LCL = "34"
}

export const enum EEquipSpecId {
    FOOD = "1",
    REGULAR = "2"
}

export const enum EFileGroupId {
    COMERCIAL = "1",
    OPERACIONAL = "2",
    TRACKING = "3",
    DOCUMENTO = "4",
    BOOKING = "5",
    SHIPPING = "7",
    FATURE = "8",
    ACERTO = "9"
}

export const enum EFileSpecsId {
    DESPACHANTE = "1",
    CLIENTE = "2",
    IMPORTADOR = "3",
    EXPORTADOR = "4",
    INTERNO = "5",
    TRANSPORTADORA = "6",
    PRESTADOR = "7",
    OUTROS = "8",
    AGENTE = "9",
    FORNECEDOR = "10"
}

export const enum EOfferSituationId {
    ABERTA = "1",
    ENVIADA = "2",
    APROVADA = "3",
    EM_NEGOCIACAO = "4",
    REPROVADA = "5",
    VINCULADA = "6"
}

export const enum ESituationId {
    RELEASED = "1",
    EDITING = "2"
}

export const enum EApplicationInternalSequence {
    SCOB = "001",
    FILE = "002",
    NCW = "003",
    CNTR = "004",
    EQPT = "005",
    PERC = "006",
    HAWB = "007",
    HBL = "008",
    MBL = "009",
    MAWB = "010",
    VOL = "011",
    GW = "012",
    UN = "013",
    INV = "014",
    WM = "015",
    TVEIC = "016",
    VEICU = "017",
    TEU = "018",
    CW = "019",
    IMS = "020",
    PSM = "021",
    LIV = "022",
    CBM = "023",
    PPS = "024",
    COM = "025",
    SGW = "027"
}

export const enum EApplicationComplementId {
    NOT_APPLICABLE = "1",
    WEIGHT_RANGE = "2",
    EQUIPMENT = "3",
    VEHICLE = "4"
}

export const enum EDataOriginTypeId {
    AUTOMATICO = "A",
    MANUAL = "M",
    EDITADO = "E"
}

export const enum EDataOriginTypeName {
    AUTOMATICO = "Automatico",
    MANUAL = "Manual",
    EDITADO = "Editado"
}

export const enum EForwardedById {
    COMPANY = "1",
    LOCAL_AGENT = "2",
    EXTERNAL_AGENT = "3",
    OTHER_AGENT = "4"
}

export const enum EErrorReasonId {
    OFFER_INTEGRITY = "1",
    NO_VALUE_TOTAL = "2",
    NO_ACTIVE_SERVICE = "3",
    NO_TRANSIT_TIME_FOUND = "4",
    TRANSIT_TIME_EMPTY = "5",
    ACCOUNT_REQUIREMENT_MISSING = "6",
    PERSON_NOT_FOUND = "7",
    WEIGHT_OUTSIDE_WEIGHT_RANGE_BASE = "8"
}

export const enum EWarningReasonId {
    NO_VALUE_CURRENT_CURRENCY = "1",
    NO_CONTACT_INFORMED = "2",
    VIGENCE_OUT_OF_RANGE = "3",
    RECEIVING_HIGHER_PAYMENT = "4",
    ALL_RECEIVING_FREIGHT_CHARGE_NO_CHARGE = "5",
    NO_VALUE_TOTAL = "6"
}

export const enum EOriginDestinationTypeId {
    TRADE = "1",
    GROUP = "2",
    COUNTRY = "3",
    LOCAL = "4"
}

export const enum EOperId {
    EXPORT = "1",
    IMPORT = "2",
    NATIONAL = "3"
}

export const enum EModalId {
    AIR = "1",
    MARITIME = "2",
    ROAD = "3"
}

export const enum ENegotiationSituationId {
    APPROVED = "1",
    REVALIDATING = "2",
    SUSPENDED = "3",
    CANCELED = "4"
}

export const enum EPriorityId {
    NORMAL = "1",
    HIGH = "2"
}

export const enum ERateTypeId {
    TABLE = "1",
    NEGOTIATED = "2",
    FIXED = "3",
    TRANSFER = "4"
}

export const enum EPhysicalPersonTaxIdType {
    CPF = "1",
    OTHERS = "2"
}

export const enum EDomesticProviderType {
    SHIP_OWNER = "1",
    TRANSPORT_COMPANY = "2",
    EXTERNAL_AGENT = "3",
    AIR_COMPANY = "4"
}

export const enum EProviderTypeId {
    SHIPOWNER = "1",
    AIRLINE = "2",
    SHIPPING_COMPANY = "3",
}

export const enum EEventType {
    GATE_OUT_EMPTY = "1",
    PICK_UP = "2",
    GATE_IN_FULL = "3",
    LOAD = "4",
    LOAD_TRANSHIPMENT = "5",
    DISCHARGE_TRANSHIPMENT = "6",
    DISCHARGE = "7",
    FINAL_DESTINATION = "8",
    GATE_OUT_FULL = "9",
    DELIVERY = "10",
    GATE_IN_EMPTY = "11",
    PLACE_OF_RECEIPT = "12",
    CONNECTION_ARRIVAL = "13",
    CARGO_READINESS = "14",
    CONTAINER_STUFFING = "15",
    WAREHOUSE_DELIVERY = "16",
    TERMINAL_TRANSFER = "17",
    CONTAINER_UNSTUFFING = "18",
    CARGO_MANIFEST = "19",
    LOAD_CUSTOMS_TRANSFER = "20",
    DISCHARGE_CUSTOMS_TRANSFER = "21",
    CARGO_INSPECTION = "22",
    MAPA_INSPECTION = "23",
    CONNECTION_DEPARTURE = "24",
    CARGO_FUMIGATION = "25",
    ORIGIN_BORDER_CROSS = "26",
    DESTINATION_BORDER_CROSS = "27",
    PICK_UP_DEPARTURE = "28",
    DELIVERY_DEPARTURE = "29",
}

export const enum EOfferOpportunitySituation {
    AWAITING_ACTION = "1",
    AWAITING_PRICING = "2",
    SEARCH_AGAIN = "3",
    DECLINED = "4",
    FINISHED = "5",
}
export const enum EReportCategoryId {
    Process = "1",
    Invoice = "2",
    Draft = "3",
    BookingConfirmation = "4",
    ISFCAN = "5",
    ISFUSA = "6",
    PreAdvise = "7",
    AMS = "8",
    BillOfLading = "9",
    DebitNote = "10",
    CreditNote = "11",
    PreInvoice = "12",
    CreditDebitNote = "13",
    Cover = "14",
    Receipt = "15",
    ShippingInstruction = "17",
    PreAlert = "18",
    BillOfLadingCover = "19",
    Memorandum = "20",
    FMC = "21",
    AirWaybill = "23",
    Air_Manifest_EN = "25",
    BillofLadingInstructions = "26",
    SolicitationTC4 = "27",
    SolicitationRefrigerator = "28",
    ExemptionLetter = "29",
    Minuta = "30",
    CartaSeguranca = "31",
    CartaSegurancaAdicional = "32",
    DeclaracaoSeguranca = "33",
    CargoReadinessHistory = "35",
    CartaAutorizacao = "36",
    CartaAutorizacaoAdicional = "37",
    CartaSegurancaDG = "38",
}

export const enum EReportCategoryName {
    Process = "Processo",
    Invoice = "Fatura",
    Draft = "Draft",
    BookingConfirmation = "Booking Confirmation",
    ISFCAN = "ISF CAN",
    ISFUSA = "ISF USA",
    PreAdvise = "Pre Advise",
    AMS = "AMS",
    BillOfLading = "Bill of Lading",
    BillOfLadingForPrint = 'Bill of Lading for Print',
    DebitNote = "Debit Note",
    CreditNote = "Credit Note",
    PreInvoice = "Pre Invoice",
    CreditDebitNote = "Credit_Debit Note",
    Cover = "Cover",
    Receipt = "Recibo",
    ShippingInstruction = "Shipping Instruction",
    PreAlert = "Pre Alert",
    BillOfLadingCover = "Bill of Lading Cover",
    Memorandum = "Memorando",
    FMC = "FMC",
    ProtestLetter = "Carta Contraprotesto",
    ProtestLetterIA = "Carta Contraprotesto IA",
    AirWaybill = "Air Waybill",
    AirLabel = "Label",
    Air_Manifest_EN = "Air Manifest",
    BillofLadingInstructions = "Bill of Lading Instructions",
    SolicitationTC4 = "Solicitação TC4",
    SolicitationRefrigerator = "Solicitação Geladeira",
    ExemptionLetter = "Carta Avaria",
    Minuta = "Minuta",
    CartaSeguranca = "Carta Seguranca",
    CartaSegurancaAdicional = "Carta Seguranca Adicional",
    DeclaracaoSeguranca = "Declaracao Seguranca",
    CargoReadinessHistory = "Historico de Prontidao de Carga",
    CartaAutorizacao = "Carta Autorização",
    CartaAutorizacaoAdicional = "Carta Autorização Adicional",
    CartaSegurancaDG = "Carta Segurança DG",
}

export const enum ESpecSituation {
    PENDING = "0",
    FINISHED = "1",
    NOT_APPLICABLE = "2",
}

export const enum EUnitOfMeasurementId {
    METER = "1",
    FEET = "2"
}

export const enum EIntegrateDirection {
    SANKHYA = "1",
    HC = "2"
}

export const enum ETariffFreightDirection {
    ORIGIN = '1',
    DESTINATION = '2'
}

export const enum ETariffFreighOriginType {
    PUP = 'PUP',
    OTFS = 'OTFS',
    AOL = 'AOL',
    POL = 'POL'
}

export const enum ETariffFreighDestinationType {
    AOD = 'AOD',
    POD = 'POD',
    DTFS = 'DTFS',
    PLD = 'PLD'
}

export const enum ETariffType {
    Freight = '1',
    Local = '2',
    Domestic = '3',
    DetDem = '4',
    Inland = '5',
    Route = '6',
    Complementary = '7',
    DetDemContract = '8',
    DetDemRoute = '9',
}

export const enum EInvoiceStatus {
    ONGOING = "1",
    PRE_INVOICE = "2",
    INVOICED = "3",
    PENDING_INTEGRATION_ORDER = "4",
    PENDING_INTEGRATION_INVOICE = "5",
    LOCKED_GROUP = "6",
    PENDING_LOCK_GROUP = "7"
}

export const enum EErpIntegrationStatus {
    ORDER_CONFIRMED = "1",
    PENDING_CREATE_ORDER = "2",
    PENDING_CONFIRM_ORDER = "3",
    PENDING_CREATE_INVOICE = "4",
    PENDING_CONFIRM_INVOICE = "5",
    PENDING_CANCEL_ORDER = "6",
    PENDING_CANCEL_INVOICE = "7",
    PENDING_UNIFY_INVOICE = "8",
    INVOICED = "9",
    BILLING_CONFIRMED = "10",
    PENDING_UNIFY_MULTIPLE_INVOICE = "11",
    PENDING_LOCK_GROUP = "12"
}
export const enum EMoveTypeId {
    DOOR_TO_DOOR = "1",
    DOOR_TO_TERMINAL = "2",
    DOOR_TO_PORT = "3",
    DOOR_TO_AIRPORT = "4",
    TERMINAL_TO_DOOR = "5",
    TERMINAL_TO_TERMINAL = "6",
    TERMINAL_TO_PORT = "7",
    TERMINAL_TO_AIRPORT = "8",
    PORT_TO_DOOR = "9",
    AIRPORT_TO_DOOR = "10",
    PORT_TO_TERMINAL = "11",
    AIRPORT_TO_TERMINAL = "12",
    PORT_TO_PORT = "13",
    AIRPORT_TO_AIRPORT = "14"
}

export const enum ECancelReason {
    OFFER_ERROR = "1"
}

export const enum EPaymentMethod {
    BOLETO = "1",
    DEPOSIT = "2",
    BANK_CHECK = "3",
    EXCHANGE = "4",
    EXTERIOR = "5",
    CARD = "6"
}

export const enum EFinancialStatus {
    ONGOING = "1",
    PAID = "2",
    PARTIAL_PAID = "3",
    UNIFIED = "4",
    IN_CHARGE = "5",
    IN_CHARGE_JUDITIAL = "6",
    NEGATIVED = "7",
    PROTESTED = "8",
    JUNK = "9",
    CANCELED = "10"
}

export const enum EFreightRatesManagementSituationId {
    IN_PROCESS = "1",
    FINISHED = "2"
}

export const enum EFreightRatesManagementChargeType {
    EQUIPMENT = "Equipment",
    ADDITIONAL = "Additional",
    WEIGHT_RANGE = "WeightRange"
}

export const enum EDeadlineReference { // deadline_reference
    LOAD = '1',
    DISCHARGE = '2',
    INVOICE_ISSUANCE = '3',
    SUPPLIER_INVOICE_ISSUANCE = '4'
}

export const enum EDeadlinePreferenceType { // deadline_preference_type
    DEFAULT = '1',
    WEEKDAY = '2',
    MONTHDAY = '3',
}

export const enum EDeadlinePreferenceParameterization {
    POSTPONE = '1',
    CLOSEST = '2',
    NEXT_MONTH = '3',

}

export const enum EProcessWizardTab {
    OFFER_TAB = "offer",
    DETAILS_TAB = "details",
    CARGO_TAB = "cargo"
}

export const enum ETariffChangeConfirmationReasonValue {
    PREVIOUS_VALUE_INCORRECT = "1",
    NEW_VALUE_APPLIED = "2",
    OTHERS = "3"
}

export const enum ETariffChangeConfirmationReasonValidity {
    FREE_OF_CHARGE = "1",
    OTHERS = "2",
}

export const enum ETariffOrigin {
    TARIFF_LOCAL = "1",
    INLAND_ROUTES = "2",
    FREIGHT_ROUTES = "3",
}

export const enum EOfferChangeConfirmationReason {
    NEGOTIATION_EFFECT_SALE = "1",
    SPECIFIC_PURCHASE_NEGOTIATION = "2",
    DOMESTIC_TARIFF_DOES_NOT_MEET = "3",
    LOCAL_TARIFF_DOES_NOT_MEET = "4",
    SHIPPING_TARIFF_DOES_NOT_MEET = "5",
    OTHERS = "6",
}

export const enum ETariffTransactionManagementSituationId {
    PENDING = "1",
    APPROVED = "2",
    DISAPPROVED = "3",
    CANCELED = "4"
}

export const enum EOfferAdditionalSituationId {
    VINCULADA = "1",
    CANCELADO_DEVIDO_ALTERACAO_TARIFARIO_TARIFF_LOCAL = "2",
    CANCELADO_DEVIDO_ALTERACAO_TARIFARIO_TARIFF_FRETE = "3",
    CANCELADO_DEVIDO_ALTERACAO_TARIFARIO_TARIFF_DOMESTIC = "4",
    BLOQUEADO_POR_INCONSISTENCIA_EM_TAXAS = "5"

}

export const enum EDetDemProviderType {
    EQUIPMENT_PROVIDER = "1",
    PROVIDER = "2"
}

export const enum EPreProcessWizardTab {
    PRE_TAB = "pre",
    ROUTES_TAB = "routes",
    CARGO_TAB = "cargo",
    SPECIFICITY_TAB = "spec"
}

export const enum ETariffFreightPerDiemType {
    DEFAULT_ALLOG = "1",
    TRANSFER_ALL = "2",
    TRANSFER_PLUS_FIXED = "3",
    TRANSFER_PLUS_PERCENTAGE = "4",
    TABLE = "5"
}

export const enum EDDTypeTariff {
    SPLITED_DET_DEM = "1",
    COMBINED_DET_DEM = "2"
}

export const enum EProcessTypeId {
    DEFAULT = "1",
    PROJECT = "2",
    INTL = "3",
    LIQUID = "4",
    BOOKING_AGENT = "5",
    FRANCHISING = "6"
}

export const enum EProcessSituation {
    PENDING = "6",
    PICKED_UP = "8",
    DELIVERED = "9",
    CANCELED = "5",
    AUTHORIZED = "7",
    DISCHARGED = "4",
    LOADED = "3",
    PRE_PROCESS = "1",
    READY_TO_LOAD = "2"
}

export const enum ETariffTypeDetDem {
    DET_DEM = '1',
    COMBINED = '2'
}

export const enum ETypeNameQualification {
    BRONZE = 'Bronze',
    SILVER = 'Silver',
    GOLD = 'Gold',
    DIAMONG = 'Diamond'
}

export const enum EInclusionExemption {
    INCLUSION = "1",
    EXEMPTION = "2"
}

export const enum ERoutingPointType {
    DEPOT = "1",
    PORT = "2",
    DOOR = "3",
    AIRPORT = "4",
    TERMINAL = "5"
}

export const enum ERoutingPoints {
    PUP = "PUP",
    OTFS = "OTFS",
    POLAOL = "POLAOL",
    PODAOD = "PODAOD",
    DTFS = "DTFS",
    PLD = "PLD"
}

export const enum EProcessDocumentType {
    MASTER = "MASTER",
    HOUSE = "HOUSE",
}

export const enum EProcessDocumentTypeId {
    MASTER = "1",
    HOUSE = "2",
}

export const enum EProcessEventSituationId {
    NOT_PROGRAMMED = "1",
    PROGRAMMED = "2",
    PROCESSING = "3",
    EFFECTIVE = "4"
}

export const enum ECountryOfOriginId {
    BR = "1"
}

export const enum EProcessEventTransportModeId {
    TRUCK = "1",
    RAIL = "2",
    COMBINED_RAIL = "3",
    BARGE = "4",
    COMBINED_WATERWAY = "5",
    ALL_WATER = "6",
    ALL_AIR = "7",
}

export const enum EProcessEventTransportModeName {
    TRUCK = "Truck",
    RAIL = "Rail",
    COMBINED_RAIL = "Combined Rail (Rail + Truck)",
    BARGE = "Barge",
    COMBINED_WATERWAY = "Combined Waterway (Barge + Truck)",
    ALL_WATER = "All Water",
    ALL_AIR = "All Air"
}

export const enum ETariffTransactionNotificationtSituationId {
    PENDING = "1",
    APPROVED = "2",
    CANCELED = "3"
}

export const enum EAgentSituationId {
    NOT_HOMOLOGATED = "2"
}

export const enum ECustomerSituationId {
    PROSPECT = "1",
    DECLINED = "3"
}

export const enum ETariffTransactionNotificationTypeId {
    INFO = "1",
    WARNING = "2",
    ERROR = "3"
}

export const enum EForwardingSituation {
    OPEN_FILE = "1",
    BILLING_ALLOWED = "3",
    BILLED = "4",
    FINISHED = "5",
    AUDITED = "6",
    CANCELLED = "7",
    CHARGES_CHECKING = "8"
}

export const enum EConsolidatedStatus {
    OPEN = "1",
    FINISHED = "2",
    APPROVED = "3",
}

export const enum ETypeFromEvent {
    EVENT_WIZARD = "WIZARD",
    EVENT_PROCESS = "PROCESS"
}

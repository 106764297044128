import angular = require('angular');
import { IColumnDef } from "ui-grid";
import { GridFormService, IGridFormController, IGridFormServiceScope } from "@services/GridFormService";
import { IMonacoColumnDef } from "@services/GridService2";
import { OperationalService } from "@services/OperationalService";
import { IModalService } from '@services/ModalService';
import { ISessionService } from "@services/SessionService";
import { DataProcessService } from "@services/DataProcessService";
import { OfferCompiled, IOfferUsedProcess } from "@models/interface/product/OfferCompiled";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { IProcessParameter } from "../../common/model/ModelParameter";
import { ECargoTypeId, EOfferSituationId, EOfferAdditionalSituationId } from "@enums/GenericData";

export interface IMonacoRequest<T = any> {
    route?: string;
    operation?: string;
    data?: T;
    timeout?: number;
    user?: any;
}

export interface IOfferProcessModal extends ng.IScope {
    offerProcess: OfferCompiled;
    processList: IOfferUsedProcess;
    refreshModal: (id: number) => void;
    goToOfferProcess: (processNumber: string) => void;
}

interface IOfferListScope extends IGridFormServiceScope {
    model: OfferCompiled;
    currentUserHasWizardProcessPermission: boolean;
    currentUserHasOldWizardProcessPermission: boolean;
    form: ng.IFormController;

    openProcessWizard: (model: OfferCompiled) => void;
    openWizard: (model: OfferCompiled) => void;
    isValidOffer: (model: OfferCompiled) => void;
    openOfferProcess: (offer: OfferCompiled) => Promise<void>;

}

export class OfferListController extends GridFormService implements IGridFormController {
    static $inject: string[] = ["$injector", "$scope", "$element"];
    private $scope: IOfferListScope;
    private operationalService: OperationalService;
    private modalService: IModalService;
    private sessionService: ISessionService;
    private dataProcessService: DataProcessService;
    private modalID: number;

    constructor($injector: ng.Injectable<any>, $scope: IOfferListScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.operationalService = $injector.get('OperationalService');
        this.modalService = $injector.get('ModalService');
        this.sessionService = $injector.get('SessionService');
        this.dataProcessService = $injector.get('DataProcessService');
    }

    initScopeFunctions(): void {

        this.$scope.openProcessWizard = (model: OfferCompiled) => {
            this.openProcessWizard(model);
        }

        this.$scope.openWizard = (model: OfferCompiled) => {
            this.openWizard(model);
        }

        this.$scope.isValidOffer = (model: OfferCompiled) => {
            try {
                const validCargoType: string[] = [ECargoTypeId.AIR, ECargoTypeId.BREAK_BULK, ECargoTypeId.FCL, ECargoTypeId.LCL, ECargoTypeId.RO_RO, ECargoTypeId.ROAD];
                const currentDate = new Date();
                currentDate.setHours(0, 0, 0, 0);

                if (model.CURRENT_SITUATION.ID !== EOfferSituationId.APROVADA) return false;
                if (!validCargoType.includes(model.CARGO_TYPE.ID)) return false;
                if (!model.ACTIVE) return false;
                if (new Date(model.VALIDITY_UP) < currentDate) return false;
                if (model.ADDITIONAL_SITUATION && model.ADDITIONAL_SITUATION.some(item => item.ID == EOfferAdditionalSituationId.CANCELADO_DEVIDO_ALTERACAO_TARIFARIO_TARIFF_LOCAL)) return false;
                if (model.ADDITIONAL_SITUATION && model.ADDITIONAL_SITUATION.some(item => item.ID == EOfferAdditionalSituationId.BLOQUEADO_POR_INCONSISTENCIA_EM_TAXAS)) return false;
                if (model.PROCESS_DEADLINE_FREIGHT_ROUTES && (new Date(model.PROCESS_DEADLINE_FREIGHT_ROUTES).getTime() < currentDate.getTime())) return false;
                if (model.PROCESS_DEADLINE_INLAND_ROUTES && (new Date(model.PROCESS_DEADLINE_INLAND_ROUTES).getTime() < currentDate.getTime())) return false;
                if (model.PROCESS_DEADLINE_TARIFF_LOCAL && (new Date(model.PROCESS_DEADLINE_TARIFF_LOCAL).getTime() < currentDate.getTime())) return false;

                return true;
            } catch (ex) {
                this.handleError(ex);
            }
        }

        this.$scope.openOfferProcess = async (offer: OfferCompiled): Promise<void> => {
            this.openOfferProcess(offer);
        }
    }

    async $onInit(): Promise<void> {
        try {
            this.$scope.currentUserHasWizardProcessPermission = await this.permissionService.isRoleAllowed("PROCESSGENERATEWIZARD");
            this.$scope.currentUserHasOldWizardProcessPermission = await this.permissionService.isRoleAllowed("OLDPROCESSGENERATEWIZARD");
            this.$baseUrl = this.operationalService.$route;
            this.initForm(this, "form", "offerCompiled", "GENERAL.MENU.APPROVED_OFFER", false);
            this.block();

            // init grid
            await this.initGrid('gridOfferCompiled', '/offerCompiled/list', true, true, 120000, true, true);

            this.unblock();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const columnDefs: Array<IColumnDef> = gridColumns.$columnDefs;


        const processWizard = `<span ng-show="grid.appScope.isValidOffer(row.entity)"><a ng-click="grid.appScope.currentUserHasWizardProcessPermission && grid.appScope.openProcessWizard(row.entity);" ng-disabled="!grid.appScope.currentUserHasWizardProcessPermission" class="text-blue" uib-tooltip="${this.$scope.currentUserHasWizardProcessPermission ? "{{'OPERATIONAL.CREATE_FILE' | translate }}" : "{{'PRODUCT.USER_NO_PERMISSION_NEW_ALTERNATIVE' | translate }}"}" tooltip-placement="auto top" tooltip-append-to-body="true" ><i class="fa fa-share-square-o text-default"></i></a>&nbsp;&nbsp;</span>`
        const oldProcessWizard = `<span ng-show="grid.appScope.isValidOffer(row.entity) && grid.appScope.currentUserHasOldWizardProcessPermission"><a ng-click="grid.appScope.currentUserHasOldWizardProcessPermission && grid.appScope.openWizard(row.entity);" ng-disabled="!grid.appScope.currentUserHasOldWizardProcessPermission" class="text-blue" uib-tooltip="${this.$scope.currentUserHasOldWizardProcessPermission ? "{{'OPERATIONAL.CREATE_FILE' | translate }}" : "{{'PRODUCT.USER_NO_PERMISSION_NEW_ALTERNATIVE' | translate }}"}" tooltip-placement="auto top" tooltip-append-to-body="true" ><i class="fa fa-level-up text-default"></i></a>&nbsp;&nbsp;</span>`
        const process = `<a ng-disabled="!row.entity.OFFER_USED_PROCESS.length"><btn ng-click="row.entity.OFFER_USED_PROCESS.length ? grid.appScope.openOfferProcess(row.entity) : ''" class="text-info" ng-class="{'text-muted': !row.entity.OFFER_USED_PROCESS.length}" tooltip-placement="auto top" uib-tooltip="{{row.entity.OFFER_USED_PROCESS.length ? 'REGISTRATION.VIEW_FILES' : 'REGISTRATION.NO_FILE_LINKED' | translate }}" tooltip-append-to-body="true"><i class="fa fa-list-alt icon"></i></btn></a>&nbsp;&nbsp;</div>`;
        //actions       
        const actions = `<div class="text-center pull-center" style="padding-left: 10px;">${processWizard} ${oldProcessWizard} ${process}</div>`;

        columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: 70,
            cellTemplate: (actions),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });
        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            // visible
            const colNumber: IMonacoColumnDef = { name: "ID", displayName: "GENERAL.NUMBER", width: 150 };
            const colOfferCode: IMonacoColumnDef = { name: "OFFER_CODE", displayName: "GENERAL.CODE", width: 150 };
            const colOfferCodeExhibition: IMonacoColumnDef = { name: "OFFER_CODE_EXHIBITION", displayName: "BASIC_DATA.OFFER", width: 150 };
            const colConcatenated: IMonacoColumnDef = { name: "CONCATENATED", displayName: "GENERAL.CONCATENATED", width: 150 };
            const colBranch: IMonacoColumnDef = { name: "BRANCH.NAME", displayName: "BASIC_DATA.BRANCH", width: 150 };
            const colCommercialUnity: IMonacoColumnDef = { name: "COMMERCIAL_UNITY.NAME", displayName: "BASIC_DATA.COMMERCIAL_BRANCH", width: 150 };
            const colCargoType: IMonacoColumnDef = { name: "CARGO_TYPE.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 100 };
            const colExporter: IMonacoColumnDef = { name: "EXPORTER.ID", displayName: "BASIC_DATA.SHIPPER", width: 150, cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.EXPORTER, null, "ID")}}' };
            const colImporter: IMonacoColumnDef = { name: "IMPORTER.ID", displayName: "BASIC_DATA.CONSIGNEE", width: 150, cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.IMPORTER, null, "ID")}}' };
            const colIncoterm: IMonacoColumnDef = { name: "INCOTERM.CODE", displayName: "BASIC_DATA.INCOTERM", width: 150 };
            const colCustomer: IMonacoColumnDef = { name: "CUSTOMER.NAME", displayName: "BASIC_DATA.CLIENT", width: 300 };
            const colCustomerQualification: IMonacoColumnDef = { name: "CUSTOMER_QUALIFICATION.NAME", displayName: "GENERAL.CLIENT_QUALIFICATION", width: 150 };
            const colProcessType: IMonacoColumnDef = { name: "PROCESS_TYPE.NAME", displayName: "BASIC_DATA.FILE_TYPE", width: 150 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.NAME", displayName: "BASIC_DATA.PRODUCT", width: 150 };
            const colSales: IMonacoColumnDef = { name: "SALES_PERSON.NAME", displayName: "BASIC_DATA.SALES_EXECUTIVE", width: 150 };
            const colService: IMonacoColumnDef = { name: "SERVICE_TYPE.NAME", displayName: "GENERAL.SERVICE_TYPE", width: 150 };
            const colPup: IMonacoColumnDef = { name: "PUP.NAME", displayName: "BASIC_DATA.PICK_UP", width: 150 };
            const colOtfs: IMonacoColumnDef = { name: "OTFS.NAME", displayName: "BASIC_DATA.PLACE_OF_RECEIPT", width: 150 };
            const colPolaol: IMonacoColumnDef = { name: "POLAOL.NAME", displayName: "BASIC_DATA.POL", width: 150 };
            const colPodaod: IMonacoColumnDef = { name: "PODAOD.NAME", displayName: "BASIC_DATA.POD", width: 150 };
            const colDtfs: IMonacoColumnDef = { name: "DTFS.NAME", displayName: "BASIC_DATA.FINAL_DESTINATION", width: 150 };
            const colPld: IMonacoColumnDef = { name: "PLD.NAME", displayName: "ROUTE.DELIVERY", width: 150 };
            const colValOf: IMonacoColumnDef = { name: "VALIDITY_OF", displayName: "PRODUCT.VALIDITY_START", width: 150, cellFilter: "date:'dd/MM/yyyy'" };
            const colValUp: IMonacoColumnDef = { name: "VALIDITY_UP", displayName: "PRODUCT.VALIDITY_END", width: 150, cellFilter: "date:'dd/MM/yyyy'" };
            const colSituation: IMonacoColumnDef = { name: "CURRENT_SITUATION.NAME", displayName: "PRODUCT.COMMERCIAL_STATUS", width: 150 };
            const colAdditionalSituation: IMonacoColumnDef = { name: "ADDITIONAL_SITUATION.NAME", displayName: "PRODUCT.OFFER_OPERATIONAL_STATUS", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ADDITIONAL_SITUATION, null, "NAME")}}</div>' };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 150, cellFilter: "YesOrNo" };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'ID':
                        columnDefs.push(colNumber);
                        break;
                    case 'OFFER_CODE':
                        columnDefs.push(colOfferCode);
                        break;
                    case 'OFFER_CODE_EXHIBITION':
                        columnDefs.push(colOfferCodeExhibition);
                        break;
                    case 'CONCATENATED':
                        columnDefs.push(colConcatenated);
                        break;
                    case 'BRANCH':
                        columnDefs.push(colBranch);
                        break;
                    case 'COMMERCIAL_UNITY':
                        columnDefs.push(colCommercialUnity);
                        break;
                    case 'CARGO_TYPE':
                        columnDefs.push(colCargoType);
                        break;
                    case 'CUSTOMER.NAME':
                        columnDefs.push(colCustomer);
                        break;
                    case 'CUSTOMER_QUALIFICATION.NAME':
                        columnDefs.push(colCustomerQualification);
                        break;
                    case 'EXPORTER':
                        columnDefs.push(colExporter);
                        break;
                    case 'IMPORTER':
                        columnDefs.push(colImporter);
                        break;
                    case 'INCOTERM':
                        columnDefs.push(colIncoterm);
                        break;
                    case 'PROCESS_TYPE':
                        columnDefs.push(colProcessType);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'SALES_PERSON':
                        columnDefs.push(colSales);
                        break;
                    case 'SERVICE_TYPE':
                        columnDefs.push(colService);
                        break;
                    case 'PUP':
                        columnDefs.push(colPup);
                        break;
                    case 'OTFS':
                        columnDefs.push(colOtfs);
                        break;
                    case 'POLAOL':
                        columnDefs.push(colPolaol);
                        break;
                    case 'PODAOD':
                        columnDefs.push(colPodaod);
                        break;
                    case 'DTFS':
                        columnDefs.push(colDtfs);
                        break;
                    case 'PLD':
                        columnDefs.push(colPld);
                        break;
                    case 'VALIDITY_OF':
                        columnDefs.push(colValOf);
                        break;
                    case 'VALIDITY_UP':
                        columnDefs.push(colValUp);
                        break;
                    case 'CURRENT_SITUATION':
                        columnDefs.push(colSituation);
                        break;
                    case 'ADDITIONAL_SITUATION':
                        columnDefs.push(colAdditionalSituation);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                };
            }

            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            OFFER_CODE: null,
            OFFER_CODE_EXHIBITION: null,
            HIERARCHY: null,
            OFFER_CODE_LINK: null,
            CONCATENATED: null,
            CONCATENATED_COMPLEMENT: null,
            CURRENT_SITUATION: null,
            CUSTOMER: null,
            CUSTOMER_QUALIFICATION: null,
            REFERENCE: null,
            COMMODITY_SUMMARY: null,
            PRODUCT: null,
            PROCESS_TYPE: null,
            SERVICE_TYPE: null,
            CARGO_TYPE: null,
            INCOTERM: null,
            KAM: null,
            FORWARDED_BY: null,
            COMMERCIAL_UNITY: null,
            BRANCH: null,
            SALES_PERSON: null,
            INSIDE_SALES_PERSON: null,
            FREIGHT_CONTRACT: null,
            FREIGHT_CONTRACT_RECEIVING: null,
            BOOKING_TYPE: null,
            QUOTATION_TTIME_MIN: null,
            QUOTATION_TTIME_MAX: null,
            SERVICE_PROVIDER: null,
            MASTER_DIRECT: null,
            MASTER_PAYMENT: null,
            HOUSE_PAYMENT: null,
            FOOD_CONTAINER: null,
            DTA: null,
            IMPORTER: null,
            EXPORTER: null,
            NOTIFY: null,
            BROKER: null,
            INDICATION: null,
            AGENCY: null,
            LOCAL_AGENT: null,
            EXTERNAL_AGENT: null,
            REPRESENTATIVES: null,
            TRANSPORTER_PICKUP: null,
            TRANSPORTER_PLACE_RECEPT: null,
            TRANSPORTER_FINAL_DESTINATION: null,
            TRANSPORTER_DELIVERY: null,
            TRANSPORTER_GATE_OUT_EMPTY: null,
            TRANSPORTER_CONTAINER_STUFFING: null,
            FLEXI_FITTING: null,
            ISOTANK_PROVIDER: null,
            VALIDITY_OF: null,
            VALIDITY_UP: null,
            INSTRUCTIONS: null,
            PUP: null,
            OTFS: null,
            POLAOL: null,
            PODAOD: null,
            DTFS: null,
            PLD: null,
            SERVICES: null,
            EVENT: null,
            PROFIT_SHARE: null,
            CHARGE: null,
            CHARGE_OUT_DATE: null,
            CARGO: null,
            SECTION: null,
            ACCOUNT_TYPE: null,
            SALES_HOLDER: null,
            RESPONSIBLE_PRODUCT: null,
            SPONSOR: null,
            THIRD_AGENT: null,
            CONTACT: null,
            ACCOUNT_REQUIREMENT: null,
            ACTIVE: null,
            CONTRACT_TYPE: null,
            CONTRACT_TYPE_RECEIVING: null,
            WEIGHT_RANGE_CHARGE: null,
            OFFER_USED_PROCESS: null,
            COMMODITY_CURRENCY: null,
            COMMODITY_VALUE: null,
            ADDITIONAL_SITUATION: null,
            MAIN_ORIGIN: null,
            MAIN_DESTINATION: null,
            PROCESS_DEADLINE_TARIFF_LOCAL: null,
            PROCESS_DEADLINE_INLAND_ROUTES: null,
            PROCESS_DEADLINE_FREIGHT_ROUTES: null,
            CORPORATE_ACCOUNT: null,
            COMMODITY_INVOICE_VALUE: null,
        }
    }

    private async openProcessWizard(model: OfferCompiled): Promise<void> {
        try {
            this.block();
            const timeout: number = 120000;

            //getByID on start
            const result = await this.dataProcessService.get<any>(`/processWizardFilter/offerCompiledToWizard/${model.ID}`, timeout);
            if (!result || !result.data) return this.handleError(result);
            const offerCompiled: OfferCompiled = result.data.data;

            const offerCompiledModel = { model: offerCompiled, processRouteRedirect: true };

            this.modalID = this.modalService.newModal();
            await this.modalService.showModalInfo(
                {
                    modalID: this.modalID,
                    template: require("../view/wizard/process/processWizardModal.html"),
                    formService: 'register',
                    size: 'full modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: 'REGISTRATION.FILES_WIZARD'
                },
                offerCompiledModel
            );
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async openWizard(model: OfferCompiled): Promise<void> {
        try {
            this.block();
            const timeout: number = 120000;

            //getByID on start
            const result = await this.operationalService.get<any>(`/offerCompiled/getById/${model.ID}/${timeout}`, timeout);
            if (!result || !result.data) return this.handleError(result);
            const offerCompiled: OfferCompiled = result.data.data;

            const offerCompiledModel = { model: offerCompiled };

            this.modalID = this.modalService.newModal();
            this.modalService.showModalInfo(
                {
                    modalID: this.modalID,
                    template: require("../view/wizardOfferModal.html"),
                    formService: 'register',
                    size: 'full modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: 'REGISTRATION.FILES_WIZARD'
                },
                offerCompiledModel

            );

            const modalScope = await this.modalService.getModalScope(this.modalID);
            await modalScope.$applyAsync();

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getOfferProcessListById(offer: OfferCompiled): Promise<IOfferUsedProcess> {
        try {
            const timeout: number = 120000;
            let offerCompiled = null
            const processListRequest = await (await this.operationalService.get<any>(`/offerCompiled/getProcessByOfferId/${offer.ID}/${timeout}`, timeout)).data.data[0];
            offerCompiled = processListRequest ? offerCompiled = processListRequest : [];

            return offerCompiled.OFFER_USED_PROCESS

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async openOfferProcess(offer: OfferCompiled): Promise<void> {
        this.modalID = this.modalService.newModal();
        this.modalService.showModalInfo(
            {
                modalID: this.modalID,
                scope: this.$scope,
                formService: this.$scope.operation,
                size: 'lg modal-overflow',
                template: require("../view/modal/offerProcessModal.html"),
                keyboard: false
            },
            null
        );

        this.buildOfferProcessModalScope(offer);
    }

    private async buildOfferProcessModalScope(offer: OfferCompiled): Promise<IOfferProcessModal> {
        try {
            this.formService.block();
            if (!offer) throw Error('Missing offer parameter in buildOfferProcessModalScope');

            const modalScope: IOfferProcessModal = await this.modalService.getModalScope(this.modalID);
            modalScope.offerProcess = angular.copy(offer);

            modalScope.processList = null;
            const offerProcessList = await this.getOfferProcessListById(offer);
            if (offerProcessList) modalScope.processList = angular.copy(offerProcessList);

            modalScope.goToOfferProcess = (processNumber: string): void => {
                if (!processNumber || (processNumber && processNumber == '')) throw Error('processNumber is null');
                this.sessionService.openTab("app.operational.newProcess.list", <IProcessParameter>{ PROCESS_NUMBER: processNumber });
            }

            modalScope.refreshModal = async (offerID: number): Promise<void> => {
                try {
                    this.formService.block();

                    setTimeout(async () => {
                        const requestList = await this.getOfferProcessListById(offer);
                        if (requestList) modalScope.processList = angular.copy(requestList);
                        this.formService.unblock();
                    }, 1000);
                } catch (ex) {
                    this.formService.unblock();
                    this.formService.handleError(ex);
                }
            }

            return modalScope;
        } catch (ex) {
            this.modalService.closeModal(this.modalID);
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

}

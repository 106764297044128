import { FormService2 } from '@services/FormService2';
import { IOfferScope } from "../commercial/controller/OfferRegisterController";
import { IModalService } from "@services/ModalService";
import { ITariffTransactionManagementErrorLog } from '@models/interface/product/TariffTransactionManagementError';
import { IViewLog } from "@models/interface/common/IViewLog";
import { ProductService } from "@services/ProductService";

export class OfferChargeHelperController {
    static $inject: string[] = ['$injector', '$scope'];
    private $q: ng.IQService;
    private $filter: ng.FilterFactory;
    private $offerScope: IOfferScope;
    private formService: FormService2;
    private modalService: IModalService;
    private productService: ProductService;

    constructor($injector: ng.Injectable<any>, $scope: IOfferScope) {
        try {
            this.$q = $injector.get('$q');
            this.$filter = $injector.get('$filter');
            this.formService = new FormService2($injector.get('$injector'), $injector.get('$rootScope'));
            this.modalService = $injector.get('ModalService');
            this.productService = $injector.get('ProductService');
            this.$offerScope = $scope;

        } catch (ex) {
            throw ex;
        }
    }

    public async viewErrorLog(uuid?: string) {
        try {
            this.formService.block();
            let errors: ITariffTransactionManagementErrorLog[] = []

            await this.requestErrorHistory(this.$offerScope.model.ID.toString(), uuid).then(result => {
                if (result) errors = result;
            }).catch(ex => {
                this.formService.handleError(ex);
            });

            const modalId = this.modalService.newModal();

            await this.modalService.showModalInfo(
                {
                    modalID: modalId,
                    scope: this.$offerScope,
                    template: require('./view/modals/tariffTransactionManagementErrorLog.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG",
                },
                {
                    errorsOnFilter: errors
                }
            );
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    public async countAutoRatingErrors(id: string): Promise<number> {
        try {
            const result = await this.productService.get({ route: `/tariffTransactionManagement/countErrorLog/${id}`, timeout: 10000 });
            if (result.data && result.data.data !== null && result.data.data !== undefined) {
                return result.data.data
            } else {
                this.formService.handleError("Fail to count auto rating errors");
            };
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async requestErrorHistory(id: string, uuid?: string): Promise<any> {
        try {
            const result = await this.productService.get({ route: `/tariffTransactionManagement/viewErrorLog/${id}${uuid ? '/' + uuid : ''}`, timeout: 10000 });
            if (result.data && result.data.data && result.data.data) {
                return result.data.data;
            } else {
                this.formService.handleError("Fail to get error history");
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }
}

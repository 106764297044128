export interface IMonacoAddress {
    DATABUILDER: string;
    DATAMANAGER: string;
    DATAOPERATIONAL: string;
    DATAPRODUCT: string;
    DATASTART: string;
    EDI: string;
    EXTERNAL: string;
    FINOP: string;
    FIS: string;
    FMS: string;
    HELPER: string;
    MAILER: string;
    MONITOR: string;
    OPERATIONAL: string;
    PRODUCT: string;
    QUOTATION: string;
    QUOTATIONCOMBINED: string;
    REPORT: string;
    SSE: string;
    TASK: string;
    TRACKING: string;
    DATALAKE: string;
    DATAPROCESS: string;
    TRACKINGAWB: string;
    MYALLOGOPERATIONAL: string;
    MYALLOGPRODUCT: string;
    RABBITMQ: string;
}

export const loginId: string = "485894650118-asdckfft645f8ql5i7vt3blvea814303.apps.googleusercontent.com";

export const monacoAddressProd: IMonacoAddress = {
    DATABUILDER: "https://newdatabuilder.allog.com.br",
    DATAMANAGER: "https://newdatamanager.allog.com.br",
    DATAOPERATIONAL: "https://newdataoperational.allog.com.br",
    DATAPRODUCT: "https://newdataproduct.allog.com.br",
    DATASTART: "https://newdatastart.allog.com.br",
    EDI: "https://newedi.allog.com.br",
    EXTERNAL: "https://newexternal.allog.com.br",
    FINOP: "https://newfinop.allog.com.br",
    FIS: "https://newfis.allog.com.br",
    FMS: "https://newfms.allog.com.br",
    HELPER: "https://newhelper.allog.com.br",
    MAILER: "https://newmailer.allog.com.br",
    MONITOR: "https://newmonitor.allog.com.br",
    OPERATIONAL: "https://newoperational.allog.com.br",
    PRODUCT: "https://newproduct.allog.com.br",
    QUOTATION: "https://newquotation.allog.com.br",
    QUOTATIONCOMBINED: "https://newquotationcombined.allog.com.br",
    REPORT: "https://newreport.allog.com.br",
    SSE: "https://newsse.allog.com.br",
    TASK: "https://newtask.allog.com.br",
    TRACKING: "https://newtracking.allog.com.br",
    DATALAKE: "https://newdatalake.allog.com.br",
    DATAPROCESS: "https://newdataprocess.allog.com.br",
    TRACKINGAWB: "https://newtrackingawb.allog.com.br",
    MYALLOGOPERATIONAL: "https://newmyallogoperational.allog.com.br",
    MYALLOGPRODUCT: "https://newmyallogproduct.allog.com.br",
    RABBITMQ: "https://rabbitmq.allog.com.br",
};

export const monacoAddressQa: IMonacoAddress = {
    DATABUILDER: "https://newdatabuilderqa.allog.com.br",
    DATAMANAGER: "https://newdatamanagerqa.allog.com.br",
    DATAOPERATIONAL: "https://newdataoperationalqa.allog.com.br",
    DATAPRODUCT: "https://newdataproductqa.allog.com.br",
    DATASTART: "https://newdatastartqa.allog.com.br",
    EDI: "https://newediqa.allog.com.br",
    EXTERNAL: "https://newexternalqa.allog.com.br",
    FINOP: "https://newfinopqa.allog.com.br",
    FIS: "https://newfisqa.allog.com.br",
    FMS: "https://newfmsqa.allog.com.br",
    HELPER: "https://newhelperqa.allog.com.br",
    MAILER: "https://newmailerqa.allog.com.br",
    MONITOR: "https://newmonitorqa.allog.com.br",
    OPERATIONAL: "https://newoperationalqa.allog.com.br",
    PRODUCT: "https://newproductqa.allog.com.br",
    QUOTATION: "https://newquotationqa.allog.com.br",
    QUOTATIONCOMBINED: "https://newquotationcombinedqa.allog.com.br",
    REPORT: "https://newreportqa.allog.com.br",
    SSE: "https://newsseqa.allog.com.br",
    TASK: "https://newtaskqa.allog.com.br",
    TRACKING: "https://newtrackingqa.allog.com.br",
    DATALAKE: "https://newdatalakeqa.allog.com.br",
    DATAPROCESS: "https://newdataprocessqa.allog.com.br",
    TRACKINGAWB: "https://newtrackingawbqa.allog.com.br",
    MYALLOGOPERATIONAL: "https://newmyallogoperationalqa.allog.com.br",
    MYALLOGPRODUCT: "https://newmyallogproductqa.allog.com.br",
    RABBITMQ: "https://rabbitmqqa.allog.com.br",
};

export const monacoAddressLocal: IMonacoAddress = {
    DATABUILDER: "https://localhost:3523",
    DATAMANAGER: "https://localhost:3521",
    DATAOPERATIONAL: "https://localhost:3532",
    DATAPRODUCT: "https://localhost:3531",
    DATASTART: "https://localhost:3522",
    EDI: "https://localhost:3537",
    EXTERNAL: "https://localhost:3526",
    FINOP: "https://localhost:4005",
    FIS: "https://localhost:4001",
    FMS: "https://localhost:4505",
    HELPER: "https://localhost:3535",
    MAILER: "https://localhost:4004",
    MONITOR: "https://localhost:4010",
    OPERATIONAL: "https://localhost:3530",
    PRODUCT: "https://localhost:3525",
    QUOTATION: "https://localhost:3527",
    QUOTATIONCOMBINED: "https://localhost:3539",
    REPORT: "https://localhost:4003",
    SSE: "https://localhost:3533",
    TASK: "https://localhost:3528",
    TRACKING: "https://localhost:3529",
    DATALAKE: "https://localhost:3534",
    DATAPROCESS: "https://localhost:3536",
    TRACKINGAWB: "https://localhost:3538",
    MYALLOGOPERATIONAL: "https://localhost:3541",
    MYALLOGPRODUCT: "https://localhost:3540",
    RABBITMQ: "http://localhost:15672",
};

export function monacoUiMultiLineDirective() {
  let ddo: ng.IDirective = {
    restrict: "E",
    transclude: true,
    replace: true,
    scope: {
      id: "@",
      columns: "@",
      add: "&",
      addDisabled: "=",
      addTooltip: "@",
      onlyView: "="
    },
    template: require("../view/template/monaco-ui-multi-line.html"),
    link: ($scope) => { }
  };

  return ddo;
}
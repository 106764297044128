import angular = require("angular");
import { IHttpResponse } from "angular";
import { fileUploader as FileUploader } from 'angular-file-upload';
import { FormService2 } from '@services/FormService2';
import { IModalService, IFileReferencePreSetFields, IFileReferenceDisabledFields } from "@services/ModalService";
import { IConsolidatedReference as FileReferenceModel } from "@models/interface/operational/consolidated/ConsolidatedReference";
import { IConsolidatedReference as REFERENCE } from "@models/interface/operational/consolidated/ConsolidatedReference";
import { IUploader, IUploadItem, IFileItem, IFormData, ISuccessItemResponse } from "@models/interface/common/IMonacoUpload";
import { IMonacoController, IMonacoConfig } from "../../common/MonacoInterface";
import { SelectorModel } from "../../common/model/SelectorModel";
import { HelperService } from "@services/HelperService";
import { IConsolidatedWizardModalScope } from "./ConsolidatedWizardModalController";
import { IMonacoRequest } from "@services/GridFormService";
import { DataProcessService } from "@services/DataProcessService";

export interface IFiles {
    FILES: IUploadItem[];
}

interface IConsolidatedReferenceModalScope extends ng.IScope {
    modalOptions: any;
    modalID: number;
    model: FileReferenceModel;
    oldModel: FileReferenceModel;
    file: FileReferenceModel;
    fileModel: IFiles;
    uploader: IUploader;
    reference: string;
    preSetFields: IFileReferencePreSetFields;
    isEdit?: boolean;
    documentTypeList: Array<SelectorModel>;
    fileGroupList: Array<SelectorModel>;
    fileSpecsList: Array<SelectorModel>;
    templateList: SelectorModel[];
    disabledFields: IFileReferenceDisabledFields;
    uploadDisabled: boolean;
    newFileReference: () => Promise<void>;
    editFileReference: () => Promise<void>;
    applyOkModal: (result?: boolean) => void;
    cleanModalForm: () => void;
    openLink: (file: FileReferenceModel) => void;
    removeUpload: (model: IUploadItem) => Promise<boolean>;
}

export class ConsolidatedReferenceModalController implements IMonacoController {
    public static $inject: string[] = ['$scope', '$injector'];
    private $scope: IConsolidatedReferenceModalScope;
    private $consolidatedScope: IConsolidatedWizardModalScope;
    private $q: ng.IQService;
    private $http: ng.IHttpService;
    private config: IMonacoConfig;
    private formService: FormService2;
    private ModalService: IModalService;
    private fileUploader: FileUploader;
    private dataProcessService: DataProcessService;
    private helperService: HelperService;

    constructor($scope, $injector: ng.Injectable<any>) {
        this.$scope = $scope;
        this.$consolidatedScope = <IConsolidatedWizardModalScope>$scope.$parent.$parent;
        this.$q = $injector.get('$q');
        this.$http = $injector.get('$http');
        this.config = $injector.get('config');
        this.ModalService = $injector.get('ModalService');
        this.formService = new FormService2($injector, $scope);
        this.fileUploader = $injector.get('FileUploader');
        this.dataProcessService = $injector.get('DataProcessService');
        this.helperService = $injector.get('HelperService');
    }

    async $onInit() {
        try {
            this.$scope.uploader = this.getFileUploaderDefault();
            this.formService.block();
            this.initModel();

            this.formService.initStandAlone('modalForm');
            this.formService.loadRegisterForm(true);

            const result = await this.$q.all([
                this.getGenericType('document_type'),
                this.getGenericType('file_group'),
                this.getGenericType('file_specs'),
            ]);

            this.$scope.documentTypeList = result[0];
            this.$scope.fileGroupList = result[1];
            this.$scope.fileSpecsList = result[2];

            if (!this.$scope.fileModel) this.$scope.fileModel = <IFiles>{ FILES: [] };

            const hasAllGenericDependencies: boolean = !!this.$scope.documentTypeList && !!this.$scope.fileGroupList && !!this.$scope.fileSpecsList;
            if (!hasAllGenericDependencies) throw new Error('Failed to get file reference dependencies');

            this.registerScopeFunctions();
            this.formService.unblock();

        } catch (ex) {
            this.formService.handleError(ex);
            this.applyOkModal();
        }
    }

    $onDestroy() { }

    private registerScopeFunctions(): void {
        try {
            this.$scope.newFileReference = () => {
                return this.newFileReference();
            }

            this.$scope.editFileReference = async () => {
                this.editFileReference();
            }

            this.$scope.applyOkModal = (result?: boolean) => {
                return this.applyOkModal(result);
            }

            this.$scope.cleanModalForm = () => {
                this.cleanModalForm();
            }

            this.$scope.openLink = (file: FileReferenceModel) => {
                this.openLink(file);
            }

            this.$scope.removeUpload = (model: IUploadItem): Promise<boolean> => {
                return this.removeUpload(model);
            }

        } catch (ex) {
            throw ex;
        }
    }

    private initModel(): void {

        if (this.$scope.file) {
            this.$scope.model = this.$scope.file;
            this.$scope.oldModel = angular.copy(this.$scope.model);
        }
        else {
            this.$scope.model = {
                _id: null,
                REFERENCE_ID: null,
                ID_CONSOLIDATED: this.$consolidatedScope.model.ID,
                SOURCE: "MONACO",
                MAIN_FILE: false,
                DOCUMENT_TYPE: (this.$scope.preSetFields && this.$scope.preSetFields.documentType) ? this.$scope.preSetFields.documentType : null,
                DOCUMENT_VALUE: (this.$scope.preSetFields && this.$scope.preSetFields.documentValue) ? this.$scope.preSetFields.documentValue : this.$scope.reference ? this.$scope.reference : null,
                TEMPLATE_TYPE: null,
                TYPE: 'REFERENCE',
                FILE_GROUP: (this.$scope.preSetFields && this.$scope.preSetFields.fileGroup) ? this.$scope.preSetFields.fileGroup : null,
                FILE_SPECS: (this.$scope.preSetFields && this.$scope.preSetFields.fileSpecs) ? this.$scope.preSetFields.fileSpecs : null,
                INSERT_DATE: new Date(),
                USER_REFERENCE: this.$scope['user']._id,
                NAME: (this.$scope.preSetFields && this.$scope.preSetFields.name) ? this.$scope.preSetFields.name : null,
                LINK: null
            }
        }
    }

    private cleanModalForm() {
        this.$scope.model.TEMPLATE_TYPE = null;
        this.$scope.templateList = null;
        this.$scope.model.FILE_GROUP = null;
        this.$scope.model.FILE_SPECS = null;
        this.$scope.model.NAME = null;
        this.$scope.$applyAsync();
    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'allog_file_integration_service', formName: "consolidatedReference", folder: "consolidatedReference" };
            return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData] });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    getFisFilesGenericRoute(): string {
        const baseRoute = '/fis/filesGeneric';
        const uploadRoute = this.config.fisUrl + baseRoute;
        return uploadRoute;
    }

    private async removeUpload(model: IUploadItem): Promise<boolean> {
        let result = false;
        try {
            if (!model) throw Error('item parameter is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;
        } catch (ex) {
            result = false;
            throw (ex);
        } finally {
            return result;
        }
    }

    private openLink(file: FileReferenceModel) {
        try {
            if (!file) return;

            if (file.LINK && file.LINK.startsWith("https://")) {
                window.open(file.LINK, '_blank');
            }
            else {
                this.formService.notifyWarning("Acesso ao arquivo não disponível");
            }
        } catch (ex) {
            throw (ex);
        }
    }

    private applyOkModal(result?: boolean): void {
        try {
            this.$scope.modalOptions.ok(result);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getGenericType(identifier: string) {
        try {
            const { data: generic } = await this.helperService.get(`/generic/value/${identifier}`, null, 10000);
            return generic && generic.data ? generic.data : [];
        } catch (ex) {
            throw ex;
        }
    }

    private async saveConsolidatedReference(): Promise<void> {
        try {

            this.formService.block();

            const consolidatedProcess = this.$consolidatedScope.model.CONSOLIDATED_PROCESS
            const request: IMonacoRequest = {
                route: `/consolidatedReference/add`,
                timeout: 12000
            }

            if (this.$scope.fileModel.FILES.length) {
                for (const dataItem of this.$scope.fileModel.FILES) {
                    this.$scope.model.LINK = dataItem.FILE_URL
                    this.$scope.model.NAME = dataItem.FILE_NAME

                    request.data = {
                        data: this.$scope.model,
                        consolidatedProcess
                    }

                    await this.dataProcessService.post(request.route, request.data, request.timeout);
                }

                this.$scope.modalOptions.ok(true);
            } else {

                request.data = {
                    data: this.$scope.model,
                    consolidatedProcess
                }

                const rc = await this.dataProcessService.post(request.route, request.data, request.timeout);
                const resultOperation = (rc && rc.data && rc.data.data) ? rc.data.data : null;
                if (resultOperation) {
                    this.$scope.modalOptions.ok(true);
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async newFileReference(): Promise<void> {
        try {
            if (!this.formService.checkPendingFields()) return;

            this.formService.block();
            await this.saveConsolidatedReference();
            this.formService.unblock();

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async editFileReference(): Promise<void> {
        try {
            if (!this.formService.checkPendingFields()) return;
            this.formService.block();

            try {
                const result = await this.$http<IHttpResponse<REFERENCE[]>>({
                    method: 'post',
                    url: this.dataProcessService.$route + '/consolidatedReference/edit',
                    data: { data: this.$scope.model, oldData: this.$scope.oldModel, consolidatedProcess: this.$consolidatedScope.model.CONSOLIDATED_PROCESS },
                    cache: false,
                    timeout: 300000
                });

                if (result.status === 200 && result.data.data)
                    this.applyOkModal(true);
                else
                    this.formService.notifyError('<b>Falha</b> ao editar referência');

            } catch (ex) {
                throw ex;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }
}

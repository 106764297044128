'use strict';

GoogleAuthService.$inject = ['$http', '$cookies', 'config'];
export function GoogleAuthService($http, $cookies, config) {
    this.timeout = 30000;
    this.loginId = 'wbaGoogleId';
    this.expirationTime = 5;

    this.setLoginId = function (id) {
        //set if does not exists
        const loginId = $cookies.get(this.loginId);
        if (!loginId) {
            let exp = new Date();
            exp.setMinutes(exp.getMinutes() + this.expirationTime);
            $cookies.put(this.loginId, id, { expires: exp });
        }
    }

    this.getLoginId = function () {
        return $cookies.get(this.loginId);
    }

    this.deleteLoginId = function () {
        $cookies.remove(this.loginId);
    }

    this.redirectGoogle = function (id) {
        return $http({
            method: 'POST',
            url: config.helperUrl + '/helper/auth/google',
            data: { id: id, timeout: this.timeout },
            skipAuthorization: true,
            cache: false,
            timeout: this.timeout
        });
    }

    this.loginGoogle = function (id) {
        return $http({
            method: 'POST',
            url: config.helperUrl + '/helper/auth/google/response',
            data: { id: id, timeout: this.timeout },
            skipAuthorization: true,
            cache: false,
            timeout: this.timeout
        });
    }

}

import angular = require('angular');
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { IRestService } from "@services/RestService";
import { FormService2, IFormServiceScope } from '@services/FormService2';
import { IModalService } from '@services/ModalService';
import { OperationalService } from "@services/OperationalService";
import { ISessionService } from '@services/SessionService';
import { ProductService } from '@services/ProductService';
import { FileReferenceModel } from '@models/interface/operational/FileReferenceModel';
import { ISelectorModel, SelectorModel } from '@models/mongo/SelectorModel';
import { CONTACT, ENTITY, INTERNAL, EXPORTER, IMPORTER, REPRESENTATIVES, COMPANY, EXTERNAL, AGENCY, ENTITY_AGENT, REPRESENTATIVE_DEFAULT } from '@models/interface/operational/NewProcess';
import { IBookingIntegration } from "@models/interface/operational/Booking";
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { EOperation, EProcessSituation } from '@enums/GenericData';
import { IAgencyListCustomFilter, IBrokerListCustomFilter } from '../../registration/model/BrokerModel';
import { ECollapseState } from './NewProcessController';
import { ILegalPersonListCustomFilter } from '../../registration/model/LegalPersonModel';
import { IEntity, IEntityAgent } from 'WBA-Model/dist/interface/common/Entity';
//import { PermissionService } from '../../app/services/PermissionService';
import { IMonacoRequest } from "@services/GridFormService";
import { HelperService } from '@services/HelperService';
import { EPhysicalPersonSpecializationId } from '@enums/PhysicalPerson';
import { IProcessDetDem } from 'WBA-Model/dist/interface/dataProcess/ProcessDetDem';
import { DataProcessService } from '@services/DataProcessService';
import { INewProcessDetDemScope } from './NewProcessDetDemController';
import { IPhysicalPersonListCustomFilter } from 'src/ts/registration/model/PhysicalPersonModel';

const enum EInvolvedField {
    IMPORTER = 1,
    EXPORTER = 2,
    NOTIFY = 3,
    BROKER = 4,
    AGENCY = 5,
    EXTERNAL_BROKER = 7,
    FLEXI_FITTING = 8,
    ISOTANK_PROVIDER = 9,
    SALES_PERSON = 10,
}

interface IContactInternalFull {
    ID: string;
    NAME: string;
    EMAIL: string;
    ROLE: string;
    FULL: INTERNAL;
}

interface IExternalContactCompany extends COMPANY {
    CONTACTS: EXTERNAL[];
}

interface INewProcessMainAuxModel {
    mainReference: FileReferenceModel
}

interface INewProcessDetDemMainScope extends ng.IScope {
    model: IProcessDetDem;
    oldModel: IProcessDetDem;
    auxModel: INewProcessMainAuxModel;
    contactModel: CONTACT;
    exporterLegalPersonList: EXPORTER[];
    salesPersonLegalPersonList: ISelectorModel[];
    importerLegalPersonList: IMPORTER[];
    commercialUnityList: IEntity[];
    corporateBranchList: IEntity[];
    exporterList: EXPORTER[];
    importerList: IMPORTER[];
    salesPersonList: ISelectorModel[];
    representativesList: REPRESENTATIVES;
    notifyList: ISelectorModel[];
    brokerList: ISelectorModel[];
    externalBrokerList: ISelectorModel[];
    agencyList: AGENCY[];
    groupList: ISelectorModel[];
    documentTypeList: ISelectorModel[];
    fileGroupList: ISelectorModel[];
    fileSpecList: ISelectorModel[];
    bookingList: ISelectorModel[];
    providerList: IEntity[];
    legalPersonList: IEntity[];
    bookingStatusList: ISelectorModel[];
    currenciesList: ISelectorModel[];
    processNumber: string;
    idProcess: number;
    dateOptionsValidityOf: { minDate: Date, maxDate: Date }
    dateOptionsValidityUp: { minDate: Date, maxDate: Date }
    localAgentList: IEntityAgent[];
    externalAgentList: IEntityAgent[];
    otherAgentList: IEntityAgent[];
    pickUpList: IEntity[];
    otfsCarrierList: IEntity[];
    dtfsCarrierList: IEntity[];
    deliveryCarrierList: IEntity[];
    processTypeList: ISelectorModel[];
    cargoTypeList: ISelectorModel[];
    moveTypeList: ISelectorModel[];
    typePaymentList: ISelectorModel[];
    incotermList: ISelectorModel[];
    collapseMain: () => void;
    isCollapseIn(): boolean;
    hasChanges: () => boolean;
    openPeopleDetails: (entity: ENTITY, agentEntity?: ENTITY_AGENT) => void;
    salesPersonChange: (oldSalesPersonId: string) => Promise<void>; 
    exporterChange: (oldExporterId: string) => Promise<void>;
    importerChange: (oldImporterId: string) => Promise<void>;
    notifyChange: (oldNotifyId: string) => Promise<void>;
    brokerChange: (oldBrokerId: string) => Promise<void>;
    agencyChange: (oldAgencyId: string) => Promise<void>;
    externalBrokerChange: (oldExternalBrokerId: string) => Promise<void>;
    flexFittingChange: (oldFlexiFittingId: string) => Promise<void>;
    isoTankProviderChange: (oldIsoTankId: string) => Promise<void>;
    copyAccountReferenceToClipboard: () => void;
    getBookingListByName: (search: string) => Promise<void>;
    getExporterListByName: (search: string) => Promise<void>;
    getSalesPersonListByName: (search: string) => Promise<void>;
    getImporterListByName: (search: string) => Promise<void>;
    getNotifyListByName: (search: string) => Promise<void>;
    getBrokerListByName: (search: string) => Promise<void>;
    getAgencyListByName: (search: string) => Promise<void>;
    getProviderOperationalListByName: (search: string) => Promise<void>;
    getLegalPersonOperationalListByName: (search: string) => Promise<void>;
    getPersonOperationalListByName: (search: string) => Promise<void>;

    // Reference
    getNameUser: (id: string) => void;

    // Contacts    
    showInternalContacts: () => void;
    showExternalContacts: () => void;


    disablePanelDueToPreProcessStatus: () => boolean;
    getPickUpListByName: (search: string) => Promise<void>;
    getOtfsCarrierListByName: (search: string) => Promise<void>;
    getDtfsCarrierListByName: (search: string) => Promise<void>;
    getDeliveryCarrierListByName: (search: string) => Promise<void>;

    getCommercialUnityListByName: (search: string) => Promise<void>;
    getCorporateBranchListByName: (search: string) => Promise<void>;

    getMasterDocumentConcat: () => string;
    getHouseDocumentConcat: () => string;
}

export class NewProcessDetDemMainController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: INewProcessDetDemMainScope;
    private $newProcessDetDemScope: INewProcessDetDemScope;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private $sce: angular.ISCEService;
    private $compile: angular.ICompileService;
    private $filter: ng.FilterFactory;
    private restService: IRestService;
    private formService: FormService2;
    private modalService: IModalService;
    private sessionService: ISessionService;
    private operationalService: OperationalService;
    private productService: ProductService;
    private dataProcessService: DataProcessService;
    //private permissionService: PermissionService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: INewProcessDetDemMainScope) {
        this.$scope = $scope;
        this.$scope.auxModel = { mainReference: null };
        this.$newProcessDetDemScope = <INewProcessDetDemScope>$scope.$parent.$parent;
        this.$q = this.$newProcessDetDemScope.$q;
        this.$timeout = this.$newProcessDetDemScope.$timeout;
        this.$q = $injector.get('$q');
        this.$sce = $injector.get('$sce');
        this.$compile = $injector.get('$compile');
        this.helperService = $injector.get('HelperService');
        this.$filter = this.$newProcessDetDemScope.$filter;
        this.restService = this.$newProcessDetDemScope.restService;
        this.formService = this.$newProcessDetDemScope.formService;
        this.modalService = this.$newProcessDetDemScope.modalService;
        this.sessionService = this.$newProcessDetDemScope.sessionService;
        this.operationalService = this.$newProcessDetDemScope.operationalService;
        this.productService = this.$newProcessDetDemScope.productService;
        this.dataProcessService = this.$newProcessDetDemScope.dataProcessService;
        //this.permissionService = this.$newProcessDetDemScope.permissionService;
        this.initScopeFunctions();
    }

    async $onInit(): Promise<void> {
        try {
            this.initModel();
            await this.initDependencies();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initModel(): void {
        this.$scope.model = {
            // Basic Data
            _id: null,
            AGENCY: null,
            BOOKING_INTEGRATION: null,
            BRANCH: null,
            BROKER: null,
            COMMERCIAL_UNITY: null,
            CONCATENATED_COMPLEMENT: null,
            CREATED_DATE: null,
            EXPORTER: null,
            EXTERNAL_AGENT: null,
            FLEXI_FITTING: null,
            HOUSE_DOCUMENT: null,
            ID: null,
            ID_PROCESS: null,
            IMPORTER: null,
            ISOTANK_PROVIDER: null,
            LOCAL_AGENT: null,
            MASTER_DOCUMENT: null,
            NOTIFY: null,
            PROCESS_NUMBER: null,
            PROCESS_TYPE: null,
            REFERENCE: null,
            REPRESENTATIVES: null,
            SALES_PERSON: null,
            SERVICE_PROVIDER: null,
            SITUATION: null,
            THIRD_AGENT: null,
            TRANSPORTER_CONTAINER_STUFFING: null,
            TRANSPORTER_DELIVERY: null,
            TRANSPORTER_FINAL_DESTINATION: null,
            TRANSPORTER_GATE_OUT_EMPTY: null,
            TRANSPORTER_PICKUP: null,
            TRANSPORTER_PLACE_RECEPT: null,
            EXTERNAL_BROKER: null,
            CUSTOMER: null,
            HOUSE_PAYMENT: null,
            MASTER_PAYMENT: null,
            DESTINATION: null,
            ORIGIN: null,
            CONCATENATED: null,
            DOCUMENT_ERROR: null,
            CARGO_TYPE: null,
            CONTAINER_GROUP_LOAD: null,
            EFFECTIVE_DATE_DELIVERY: null,
            EFFECTIVE_DATE_DISCHARGE: null,
            EFFECTIVE_DATE_LOAD: null,
            EMISSION_TYPE: null,
            FORECAST_DATE_DISCHARGE: null,
            FORECAST_DATE_LOAD: null,
            FORECAST_DATE_DELIVERY: null,
            INCOTERM: null,
            ISSUEANCE: null,
            LOCAL_ISSUEANCE: null,
            MASTER_DIRECT: null,
            PRODUCT: null,
            RELEASE: null,
            SERVICE_CATEGORY: null,
            TRANSPORT_MODE_LOAD: null,
            FORWARDING_SITUATION: null,
            FATTURO_FINANCIAL_PROCESS: null,
            PROCESS_BASE_SITUATION: null,
            PROCESS_BASE_PROCESS_NUMBER: null,
            CORPORATE_ACCOUNT: null
        };
        this.$scope.oldModel = angular.copy(this.$scope.model);
    }

    private initScopeFunctions(): void {

        this.$scope.collapseMain = (): void => {
            this.collapseMain();
        }

        this.$scope.isCollapseIn = (): boolean => {
            return this.$newProcessDetDemScope.collapseState.panel == ECollapseState.MAIN && !this.$newProcessDetDemScope.collapseState.released;
        }

        this.$scope.hasChanges = (): boolean => {
            return this.$newProcessDetDemScope.hasChanges(this.$scope.model, this.$scope.oldModel);
        }

        this.$scope.openPeopleDetails = (entity: ENTITY, agentEntity?: ENTITY_AGENT) => {
            this.openPeopleDetails(entity, agentEntity);
        }

        this.$scope.exporterChange = async (oldExporterId: string) => {
            this.involvedChange(oldExporterId, EInvolvedField.EXPORTER);
        }

        this.$scope.exporterChange = async (oldSalesPersonId: string) => {
            this.involvedChange(oldSalesPersonId, EInvolvedField.SALES_PERSON);
        }

        this.$scope.importerChange = async (oldImporterId: string) => {
            this.involvedChange(oldImporterId, EInvolvedField.IMPORTER);
        }

        this.$scope.notifyChange = async (oldNotifyId: string) => {
            this.involvedChange(oldNotifyId, EInvolvedField.NOTIFY);
        }

        this.$scope.brokerChange = async (oldBrokerId: string) => {
            this.involvedChange(oldBrokerId, EInvolvedField.BROKER);
        }

        this.$scope.agencyChange = async (oldAgencyId: string) => {
            this.involvedChange(oldAgencyId, EInvolvedField.AGENCY);
        }

        this.$scope.externalBrokerChange = async (oldExternalBrokerId: string) => {
            this.involvedChange(oldExternalBrokerId, EInvolvedField.EXTERNAL_BROKER);
        }

        this.$scope.flexFittingChange = async (oldFlexiFittingId: string) => {
            this.involvedChange(oldFlexiFittingId, EInvolvedField.FLEXI_FITTING);
        }

        this.$scope.isoTankProviderChange = async (oldIsoTankId: string) => {
            this.involvedChange(oldIsoTankId, EInvolvedField.ISOTANK_PROVIDER);
        }

        this.$scope.copyAccountReferenceToClipboard = () => {
            const elmAux = document.createElement("textarea");
            document.body.appendChild(elmAux);
            elmAux.value = this.$scope.auxModel.mainReference.DOCUMENT_VALUE;
            elmAux.select();
            document.execCommand("copy");
            document.body.removeChild(elmAux);
            this.formService.notifySuccess("Referência do cliente copiada para o clipboard.");
        }

        this.$scope.getBookingListByName = async (search: string): Promise<void> => {
            let bookingList: ISelectorModel[] = [];
            if (search && search.length >= 3) bookingList = await this.getBookingListByName(search);
            this.$scope.bookingList = bookingList;
        }

        this.$scope.getExporterListByName = async (search: string): Promise<void> => {
            let exporterList: EXPORTER[] = [];
            if (search && search.length >= 3) exporterList = await this.getLegalPersonListByName({ search: search, specializations: [ELegalPersonSpecializationId.EXPORTER] });
            this.$scope.exporterLegalPersonList = exporterList;
        }

        this.$scope.getSalesPersonListByName = async (search: string): Promise<void> => {
            let salesPersonList: ISelectorModel[] = [];
            if (search && search.length >= 3) salesPersonList = await this.getPhysicalPersonListByName({ specializations: [ELegalPersonSpecializationId.BROKER], roles: [], search: search });;
            this.$scope.salesPersonLegalPersonList = salesPersonList;
        }

        this.$scope.getImporterListByName = async (search: string): Promise<void> => {
            let importerList: IMPORTER[] = [];
            if (search && search.length >= 3) importerList = await this.getLegalPersonListByName({ search: search, specializations: [ELegalPersonSpecializationId.IMPORTER] });
            this.$scope.importerLegalPersonList = importerList;
        }

        this.$scope.getNotifyListByName = async (search: string): Promise<void> => {
            let notifyList: ISelectorModel[] = [];
            if (search && search.length >= 3) notifyList = await this.getLegalPersonListByName({ search: search, specializations: [ELegalPersonSpecializationId.NOTIFY] });
            this.$scope.notifyList = notifyList;
        }

        this.$scope.getProviderOperationalListByName = async (search: string): Promise<void> => {
            let providerList: IEntity[] = [];
            if (search && search.length >= 3) providerList = await this.getProviderOperationalListByName(search);
            this.$scope.providerList = providerList;
        }

        this.$scope.getLegalPersonOperationalListByName = async (search: string): Promise<void> => {
            let legalPersonList: IEntity[] = [];
            if (search && search.length >= 3) legalPersonList = await this.getLegalPersonOperationalListByName(search);
            this.$scope.legalPersonList = legalPersonList;
        }

        this.$scope.getPersonOperationalListByName = async (search: string): Promise<void> => {
            let peopleList: IEntity[] = [];
            if (search && search.length >= 3) {
                const legalPersonList = await this.getLegalPersonRepresentativeOperationalListByName(search)
                if (legalPersonList && legalPersonList.length) peopleList = peopleList.concat(legalPersonList);
                const physicalPersonList = await this.getPhysicalPersonRepresentativeOperationalListByName(search);
                if (physicalPersonList && physicalPersonList.length) peopleList = peopleList.concat(physicalPersonList);
            }
            this.$scope.legalPersonList = peopleList;
        }

        this.$scope.getNameUser = async (id: string): Promise<void> => {
            const user = await this.getUser(id);
        }

        this.$scope.disablePanelDueToPreProcessStatus = (): boolean => {
            let result = false;
            switch (this.$newProcessDetDemScope.model.SITUATION.ID) {
                case EProcessSituation.PRE_PROCESS:
                    break;
                default:
                    result = true;
            }
            return result;
        }

        this.$scope.getPickUpListByName = async (search: string) => {
            let pickUpList: IEntity[] = [];
            if (search && search.length >= 3) {
                pickUpList = await this.getLegalPersonListByName({ search: search, specializations: [ELegalPersonSpecializationId.SHIPPING_COMPANY] });
            }
            this.$scope.pickUpList = pickUpList;
        }

        this.$scope.getOtfsCarrierListByName = async (search: string) => {
            let otfsCarrierList: IEntity[] = [];
            if (search && search.length >= 3) {
                otfsCarrierList = await this.getLegalPersonListByName({ search: search, specializations: [ELegalPersonSpecializationId.SHIPPING_COMPANY] });
            }
            this.$scope.otfsCarrierList = otfsCarrierList;
        }

        this.$scope.getDtfsCarrierListByName = async (search: string) => {
            let dtfsCarrier: IEntity[] = [];
            if (search && search.length >= 3) {
                dtfsCarrier = await this.getLegalPersonListByName({ search: search, specializations: [ELegalPersonSpecializationId.SHIPPING_COMPANY] });
            }
            this.$scope.dtfsCarrierList = dtfsCarrier;
        }

        this.$scope.getDeliveryCarrierListByName = async (search: string) => {
            let deliveryCarrier: IEntity[] = [];
            if (search && search.length >= 3) {
                deliveryCarrier = await this.getLegalPersonListByName({ search: search, specializations: [ELegalPersonSpecializationId.SHIPPING_COMPANY] });
            }
            this.$scope.deliveryCarrierList = deliveryCarrier;
        }

        this.$scope.getCommercialUnityListByName = async (search: string) => {
            let commercialUnityList: IEntity[] = [];
            if (search && search.length >= 3) {
                commercialUnityList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.COMMERCIAL_UNITY], search: search });
            }
            this.$scope.commercialUnityList = commercialUnityList;
        }

        this.$scope.getCorporateBranchListByName = async (search: string) => {
            let corporateBranchList: IEntity[] = [];
            if (search && search.length >= 3) {
                corporateBranchList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.CORPORATE_BRANCH], search: search });
            }
            this.$scope.corporateBranchList = corporateBranchList;
        }

        this.$scope.getMasterDocumentConcat = () => {
            var masterDocument = "";
            this.$scope.model.MASTER_DOCUMENT.forEach(d => {
                if (masterDocument == "") {
                    masterDocument = d;
                } else {
                    masterDocument += "; " + d;
                }
            })
            return masterDocument;
        }

        this.$scope.getHouseDocumentConcat = () => {
            var houseDocument = "";
            this.$scope.model.HOUSE_DOCUMENT.forEach(d => {
                if (houseDocument == "") {
                    houseDocument = d;
                } else {
                    houseDocument += "; " + d;
                }
            })
            return houseDocument;
        }
    }

    private async involvedChange(oldCompanyId: string, involvedField: EInvolvedField) {
        const companyIds: string[] = this.buildCompanyIds();
        const hasSameCompanyInAnotherObj = companyIds && companyIds.findIndex(companyId => companyId == oldCompanyId) >= 0;
        if (!this.$scope.contactModel.EXTERNAL) this.$scope.contactModel.EXTERNAL = [];
        if (oldCompanyId && !hasSameCompanyInAnotherObj) {
            if (this.$scope.contactModel && this.$scope.contactModel.EXTERNAL && this.$scope.contactModel.EXTERNAL.length) {
                this.$scope.contactModel.EXTERNAL = this.$scope.contactModel.EXTERNAL.filter(contact => !contact.COMPANY || (contact.COMPANY && contact.COMPANY.ID != oldCompanyId));
            }
        }

        let fieldModel = null;
        switch (involvedField) {
            case EInvolvedField.EXPORTER:
                fieldModel = this.$scope.model.EXPORTER;
                break;
            case EInvolvedField.IMPORTER:
                fieldModel = this.$scope.model.IMPORTER;
                break;
            case EInvolvedField.NOTIFY:
                fieldModel = this.$scope.model.NOTIFY;
                break;
            case EInvolvedField.BROKER:
                fieldModel = this.$scope.model.BROKER;
                break;
            case EInvolvedField.AGENCY:
                fieldModel = this.$scope.model.AGENCY;
                break;
            case EInvolvedField.FLEXI_FITTING:
                fieldModel = this.$scope.model.FLEXI_FITTING;
                break;
            case EInvolvedField.ISOTANK_PROVIDER:
                fieldModel = this.$scope.model.ISOTANK_PROVIDER;
                break;
            case EInvolvedField.SALES_PERSON:
                fieldModel = this.$scope.model.SALES_PERSON;
        }

        if (fieldModel) {
            const contacts: EXTERNAL[] = await this.getExternalContact(fieldModel);
            if (contacts && contacts.length) {
                for (const contact of contacts) {
                    const hasContactInList = this.$scope.contactModel.EXTERNAL && this.$scope.contactModel.EXTERNAL.length && this.$scope.contactModel.EXTERNAL.findIndex(contactExternal => contactExternal.ID == contact.ID) >= 0;
                    if (!hasContactInList) this.$scope.contactModel.EXTERNAL.push(contact);
                }
            }
        }
    }

    private async getContactListByName(companyId: string): Promise<EXTERNAL[]> {
        let result: EXTERNAL[] = [];
        try {
            this.formService.block();
            const contacts = await this.productService.post({
                route: "/contact/list/operational",
                data: {
                    filters: {
                        LEGAL_PERSON: parseInt(companyId),
                    }
                }
            });
            if (contacts && contacts.data && contacts.data.data) result = contacts.data.data.map(contact => { return { ID: contact.ID, NAME: contact.NAME, MAIL: contact.EMAIL, GROUP: contact.MESSAGE_GROUP, TYPE: contact.TYPE, COMPANY: contact.COMPANY, DATA_ORIGIN_TYPE: { ID: "M", NAME: "Manual" } } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getProviderOperationalListByName(search?: string): Promise<IEntity[]> {
        let result: IEntity[];
        try {
            this.formService.block();
            const providers = await this.productService.post({
                route: `/provider/list/custom/operational`,
                data: {
                    sysConvertIdToString: true,
                    search
                }
            });
            result = providers.data.data ? providers.data.data.map(x => {
                return {
                    ...x,
                    NAME: x.TRADING_NAME,
                    CODE: x.SCAC,
                    TYPE: x.TYPE
                }
            }) : null;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getLegalPersonOperationalListByName(search?: string, type?: string): Promise<IEntity[]> {
        let result: IEntity[];
        try {
            this.formService.block();
            const legalPerson = await this.productService.post({
                route: `/legalPerson/list/custom/operational`,
                data: {
                    sysConvertIdToString: true,
                    search,
                    types: type ? [type] : null,
                }
            });
            result = legalPerson.data.data ? legalPerson.data.data.map(x => {
                return {
                    ...x,
                    NAME: x.TRADING_NAME,
                    CODE: x.SCAC,
                    TYPE: x.TYPE
                }
            }) : null;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getLegalPersonRepresentativeOperationalListByName(search?: string): Promise<IEntity[]> {
        let result: IEntity[];
        try {
            this.formService.block();
            const legalPerson = await this.productService.post({
                route: `/legalPerson/list/custom/operational`,
                data: {
                    sysConvertIdToString: true,
                    search,
                    specializations: [ELegalPersonSpecializationId.REPRESENTATIVE],
                }
            });
            result = legalPerson.data.data ? legalPerson.data.data.map(x => {
                return {
                    ...x,
                    ID_LEGAL_PERSON: x.ID_LEGAL_PERSON ? x.ID_LEGAL_PERSON.toString() : null,
                    ID_PHYSICAL_PERSON: x.ID_PHYSICAL_PERSON ? x.ID_PHYSICAL_PERSON.toString() : null,
                    NAME: x.TRADING_NAME,
                    CODE: x.SCAC,
                    TYPE: x.TYPE
                }
            }) : null;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getPhysicalPersonRepresentativeOperationalListByName(search?: string): Promise<IEntity[]> {
        let result: IEntity[];
        try {
            this.formService.block();
            const legalPerson = await this.productService.post({
                route: `/physicalPerson/list/custom/operational`,
                data: {
                    sysConvertIdToString: true,
                    search,
                    specializations: [EPhysicalPersonSpecializationId.REPRESENTATIVE],
                }
            });
            result = legalPerson.data.data ? legalPerson.data.data.map(x => {
                return {
                    ...x,
                    NAME: x.NAME,
                    CODE: x.SCAC,
                    TYPE: x.TYPE
                }
            }) : null;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    async initDependencies(): Promise<any> {
        const self: NewProcessDetDemMainController = this;

        this.initCollapseEvents();

        this.$scope.$on('processMainSave', () => {
            this.saveProcessTabMain();
        });

        this.$scope.$watch(() => this.$newProcessDetDemScope.model.PROCESS_NUMBER, () => {
            this.$scope.processNumber = this.$newProcessDetDemScope.model.PROCESS_NUMBER.substring(0, this.$newProcessDetDemScope.model.PROCESS_NUMBER.length - 2);
        });
        this.$scope.$watch(() => this.$newProcessDetDemScope.model.ID_PROCESS, () => {
            this.$scope.idProcess = this.$newProcessDetDemScope.model.ID_PROCESS;
        });

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericList('message_group'),
                self.getGenericList('document_type'),
                self.getGenericList('file_group'),
                self.getGenericList('file_specs'),
                self.getGenericList('booking_status'),
                self.getCurrenciesList(),
                self.getGenericList("process_type"),
                self.getGenericList("type_cargo"),
                self.getMoveTypeList(),
                self.getGenericList('type_payment'),
                self.getIncotermList()
            ]).then(async (result: any) => {
                self.$scope.groupList = result[0];
                self.$scope.documentTypeList = result[1];
                self.$scope.fileGroupList = result[2];
                self.$scope.fileSpecList = result[3];
                self.$scope.bookingStatusList = result[4];
                self.$scope.currenciesList = result[5];
                self.$scope.processTypeList = result[6];
                self.$scope.cargoTypeList = result[7];
                self.$scope.moveTypeList = result[8];
                self.$scope.typePaymentList = result[9];
                self.$scope.incotermList = result[10];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private updateDateOptionsValidity() {
        this.$scope.dateOptionsValidityOf = angular.copy(this.$newProcessDetDemScope.dateOptions);
        this.$scope.dateOptionsValidityUp = angular.copy(this.$newProcessDetDemScope.dateOptions);

        switch (this.$newProcessDetDemScope.model.SITUATION.ID) {
            case EProcessSituation.PRE_PROCESS:
                this.$scope.dateOptionsValidityOf.minDate = new Date();
                this.$scope.dateOptionsValidityUp.minDate = new Date();
                break;
        }
    }

    private initCollapseEvents() {
        this.$scope.$on('processMainCollapse', () => {
            this.collapseMain();
        });

        const collapseMain = angular.element('#collapseMain');
        if (collapseMain) {
            collapseMain.on('shown.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    await this.getProcessTabsMain();
                    await this.getProcessContact();
                    this.updateDateOptionsValidity();
                    this.$scope.exporterList = await this.getExporterList();
                    this.$scope.importerList = await this.getImporterList();
                    this.$scope.salesPersonList = await this.getSalesPersonList();
                    angular.element("#collapseMainHeading").attr('aria-expanded', 'true');
                    // update collapse state
                    this.$newProcessDetDemScope.collapseState = { panel: ECollapseState.MAIN, released: false, nextState: null };
                    this.$newProcessDetDemScope.repositionPanels(ECollapseState.MAIN, true);
                    this.$newProcessDetDemScope.disableElements(this.$newProcessDetDemScope.operation == EOperation.VIEW);
                }
            });

            collapseMain.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseMainHeading").attr('aria-expanded', 'false');
                    this.$scope.model = null;
                    this.$scope.oldModel = null;
                }
            });
        }
    }

    private buildCompanyIds(calledByRepresentativeModal?: boolean): string[] {
        const companyIds: string[] = [];

        if (this.$scope.model.IMPORTER && companyIds.findIndex(companyId => companyId == this.$scope.model.IMPORTER.ID) < 0) companyIds.push(this.$scope.model.IMPORTER.ID);
        if (this.$scope.model.EXPORTER && companyIds.findIndex(companyId => companyId == this.$scope.model.EXPORTER.ID) < 0) companyIds.push(this.$scope.model.EXPORTER.ID);
        if (this.$scope.model.NOTIFY && companyIds.findIndex(companyId => companyId == this.$scope.model.NOTIFY.ID) < 0) companyIds.push(this.$scope.model.NOTIFY.ID);
        if (this.$scope.model.BROKER && companyIds.findIndex(companyId => companyId == this.$scope.model.BROKER.ID) < 0) companyIds.push(this.$scope.model.BROKER.ID);
        if (this.$scope.model.SERVICE_PROVIDER && companyIds.findIndex(companyId => companyId == this.$scope.model.SERVICE_PROVIDER.ID) < 0) companyIds.push(this.$scope.model.SERVICE_PROVIDER.ID);
        if (this.$scope.model.LOCAL_AGENT && companyIds.findIndex(companyId => companyId == this.$scope.model.LOCAL_AGENT.ID) < 0) companyIds.push(this.$scope.model.LOCAL_AGENT.ID);
        if (this.$scope.model.EXTERNAL_AGENT && companyIds.findIndex(companyId => companyId == this.$scope.model.EXTERNAL_AGENT.ID) < 0) companyIds.push(this.$scope.model.EXTERNAL_AGENT.ID);
        if (this.$scope.model.THIRD_AGENT && companyIds.findIndex(companyId => companyId == this.$scope.model.THIRD_AGENT.ID) < 0) companyIds.push(this.$scope.model.THIRD_AGENT.ID);
        if (this.$scope.model.AGENCY && companyIds.findIndex(companyId => companyId == this.$scope.model.AGENCY.ID) < 0) companyIds.push(this.$scope.model.AGENCY.ID);
        if (this.$scope.model.FLEXI_FITTING && companyIds.findIndex(companyId => companyId == this.$scope.model.FLEXI_FITTING.ID_LEGAL_PERSON) < 0) companyIds.push(this.$scope.model.FLEXI_FITTING.ID_LEGAL_PERSON);
        if (this.$scope.model.ISOTANK_PROVIDER && companyIds.findIndex(companyId => companyId == this.$scope.model.ISOTANK_PROVIDER.ID_LEGAL_PERSON) < 0) companyIds.push(this.$scope.model.ISOTANK_PROVIDER.ID_LEGAL_PERSON);
        if (!calledByRepresentativeModal && this.$scope.model.REPRESENTATIVES) {
            if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE.REPRESENTATIVE) {
                if (companyIds.findIndex(companyId => companyId == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE.REPRESENTATIVE.ID) < 0) companyIds.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE.REPRESENTATIVE.ID);
            }

            if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT.REPRESENTATIVE) {
                if (companyIds.findIndex(companyId => companyId == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT.REPRESENTATIVE.ID) < 0) companyIds.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT.REPRESENTATIVE.ID);
            }

            if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_LOAD && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_LOAD.REPRESENTATIVE) {
                if (companyIds.findIndex(companyId => companyId == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_LOAD.REPRESENTATIVE.ID) < 0) companyIds.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_LOAD.REPRESENTATIVE.ID);
            }

            if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PICK_UP && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PICK_UP.REPRESENTATIVE) {
                if (companyIds.findIndex(companyId => companyId == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PICK_UP.REPRESENTATIVE.ID) < 0) companyIds.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PICK_UP.REPRESENTATIVE.ID);
            }

            if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DELIVERY && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DELIVERY.REPRESENTATIVE) {
                if (companyIds.findIndex(companyId => companyId == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DELIVERY.REPRESENTATIVE.ID) < 0) companyIds.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DELIVERY.REPRESENTATIVE.ID);
            }

            if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION.REPRESENTATIVE) {
                if (companyIds.findIndex(companyId => companyId == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION.REPRESENTATIVE.ID) < 0) companyIds.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION.REPRESENTATIVE.ID);
            }
        }

        return companyIds;
    }

    private async getUser(id: string): Promise<any> {
        let result: [];
        try {
            const resultHelper = await this.helperService.get(`/user/id/${id}`, null, 10000);
            const user = resultHelper.data.data;
            if (user && user.data.data) {
                result = user.data ? user.data.map(x => {
                    return {
                        ID: x.ID, NAME: x.SHORT_NAME, CODE: x.CORPORATE_NAME,
                        TRADING_NAME: null, TAXPAYER_NUMBER: null, SCAC: null, ADDRESS: null
                    }
                }) : [];
            }

            return result
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return result;
        }
    }

    private async getExternalContact(involved: ENTITY): Promise<EXTERNAL[]> {
        let result: EXTERNAL[] = [];
        try {
            if (!involved && !involved.ID) return;
            this.formService.block();
            let commodities = [];
            const contacts = await this.productService.post<any>({
                route: '/contact/list/operational', data: {
                    filters: {
                        LEGAL_PERSON: involved.ID_LEGAL_PERSON,
                        COMMODITIES: commodities,
                        EXPORTERS: this.$scope.model.EXPORTER ? [this.$scope.model.EXPORTER.ID] : null,
                        IMPORTERS: this.$scope.model.IMPORTER ? [this.$scope.model.IMPORTER.ID] : null,
                        OUTER_CELLS: null,
                        ORIGIN_AIR: null,
                        DESTINATION_AIR: null,
                        ORIGIN_ROAD: null,
                        DESTINATION_ROAD: null,
                        TRADE_ORIGIN_AIR: null,
                        TRADE_DESTINATION_AIR: null,
                        TRADE_ORIGIN_ROAD: null,
                        TRADE_DESTINATION_ROAD: null,
                        TRADE_LANE_ORIGIN_AIR: null,
                        TRADE_LANE_DESTINATION_AIR: null,
                        TRADE_LANE_ORIGIN_ROAD: null,
                        TRADE_LANE_DESTINATION_ROAD: null,
                        COUNTRY_ORIGIN_AIR: null,
                        COUNTRY_DESTINATION_AIR: null,
                        COUNTRY_ORIGIN_ROAD: null,
                        COUNTRY_DESTINATION_ROAD: null
                    },
                    sysConvertIdToString: true
                }
            });
            if (contacts && contacts.data) {
                result = contacts.data.data ? contacts.data.data.map(x => {
                    return {
                        ID: x.ID,
                        NAME: x.NAME,
                        MAIL: x.EMAIL,
                        GROUP: x.MESSAGE_GROUP,
                        TYPE: x.TYPE,
                        COMPANY: x.COMPANY,
                        DATA_ORIGIN_TYPE: { ID: "A", NAME: "Automático" }
                    }
                }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getPhysicalPersonListByName(filter: IPhysicalPersonListCustomFilter): Promise<ENTITY[]> {
        let result: ENTITY[] = [];
        try {
            this.formService.block();
            const response = await this.productService.post({ route: "/physicalPerson/list/custom", data: filter });
            if (response && response.data && response.data.data) result = response.data.data.map(physicalPerson => {
                return {
                    ID: physicalPerson.ID,
                    NAME: physicalPerson.NAME,
                    CODE: physicalPerson.CODE,
                    ID_PHYSICAL_PERSON: null,
                    TRADING_NAME: physicalPerson.TRADING_NAME,
                    TAXPAYER_NUMBER: physicalPerson.TAXPAYER_NUMBER,
                    SCAC: physicalPerson.SCAC,
                    NUMBER_IATA: physicalPerson.NUMBER_IATA,
                    ADDRESS: physicalPerson.ADDRESS,
                    NETWORK: physicalPerson.NETWORK,
                    CORPORATE_NAME: physicalPerson.CORPORATE_NAME,
                    ID_LEGAL_PERSON: physicalPerson.ID
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getLegalPersonListByName(filter: ILegalPersonListCustomFilter): Promise<ENTITY[]> {
        let result: ENTITY[] = [];
        try {
            this.formService.block();
            const response = await this.productService.post({ route: "/legalPerson/list/custom/operational", data: filter });
            if (response && response.data && response.data.data) result = response.data.data.map(legalPerson => {
                return {
                    ID: legalPerson.ID,
                    NAME: legalPerson.NAME,
                    CODE: legalPerson.CODE,
                    ID_PHYSICAL_PERSON: null,
                    TRADING_NAME: legalPerson.TRADING_NAME,
                    TAXPAYER_NUMBER: legalPerson.TAXPAYER_NUMBER,
                    SCAC: legalPerson.SCAC,
                    NUMBER_IATA: legalPerson.NUMBER_IATA,
                    ADDRESS: legalPerson.ADDRESS,
                    NETWORK: legalPerson.NETWORK,
                    CORPORATE_NAME: legalPerson.CORPORATE_NAME,
                    ID_LEGAL_PERSON: legalPerson.ID
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getBrokerListByName(filter: IBrokerListCustomFilter): Promise<ENTITY[]> {
        let result: ENTITY[] = [];
        try {
            this.formService.block();
            const response = await this.productService.post({ route: "/broker/list/custom/operational", data: filter });
            if (response && response.data && response.data.data && response.data.data.data) result = response.data.data.data.map(broker => {
                return {
                    ID: broker.ID.toString(), NAME: broker.NAME, CODE: broker.CODE, ID_LEGAL_PERSON: broker.ID_LEGAL_PERSON.toString(), ID_PHYSICAL_PERSON: null,
                    TRADING_NAME: broker.TRADING_NAME, TAXPAYER_NUMBER: broker.TAXPAYER_NUMBER, SCAC: broker.SCAC, NUMBER_IATA: broker.NUMBER_IATA,
                    ADDRESS: broker.ADDRESS, NETWORK: broker.NETWORK, CORPORATE_NAME: broker.CORPORATE_NAME
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getBookingListByName(search: string): Promise<IBookingIntegration[]> {
        let result: IBookingIntegration[] = [];
        try {
            this.formService.block();
            const response = await this.operationalService.post("/booking/eligibleBookings", { search: search, processNumber: this.$newProcessDetDemScope.model.PROCESS_NUMBER });
            if (response && response.data) result = response.data.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCurrenciesList(term?: string): Promise<ISelectorModel[]> {
        try {
            const transform = (returnedData) => returnedData.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } });

            const request: IMonacoRequest = {
                data: {},
                route: `/currency/list/custom/`,
                timeout: 10000,
            };
            const response = await this.productService.post(request);

            this.formService.unblock();
            const result: ISelectorModel[] = (response && response.data && response.data.data) ? transform(response.data.data) : [];
            return result;

        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async getAgencyListByName(filter?: IAgencyListCustomFilter): Promise<AGENCY[]> {
        let result: AGENCY[] = [];
        this.formService.block();
        try {
            const response = await this.productService.post({ route: "/agency/list/custom/operational", data: filter });
            if (response && response.data && response.data.data && response.data.data.data) result = response.data.data.data.map(agency => {
                return {
                    ID: agency.ID,
                    NAME: agency.NAME,
                    TRADING_NAME: agency.TRADING_NAME,
                    TAXPAYER_NUMBER: agency.TAXPAYER_NUMBER,
                    SCAC: agency.SCAC,
                    NUMBER_IATA: agency.NUMBER_IATA,
                    ADDRESS: agency.ADDRESS,
                    ID_LEGAL_PERSON: agency.ID_LEGAL_PERSON,
                    NETWORK: agency.NETWORK,
                    CORPORATE_NAME: agency.CORPORATE_NAME,
                    ID_PHYSICAL_PERSON: agency.ID_PHYSICAL_PERSON
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private buildCompanies(externalContacts: EXTERNAL[]): IExternalContactCompany[] {
        const externalContactCompanies: IExternalContactCompany[] = [];

        try {
            const companies: ENTITY[] = [];
            if (this.$scope.model.IMPORTER && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.IMPORTER.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.IMPORTER);
            if (this.$scope.model.EXPORTER && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.EXPORTER.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.EXPORTER);
            if (this.$scope.model.NOTIFY && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.NOTIFY.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.NOTIFY);
            if (this.$scope.model.BROKER && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.BROKER.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.BROKER);
            if (this.$scope.model.SERVICE_PROVIDER && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.SERVICE_PROVIDER.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.SERVICE_PROVIDER);
            if (this.$scope.model.LOCAL_AGENT && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.LOCAL_AGENT.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.LOCAL_AGENT);
            if (this.$scope.model.EXTERNAL_AGENT && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.EXTERNAL_AGENT.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.EXTERNAL_AGENT);
            if (this.$scope.model.THIRD_AGENT && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.THIRD_AGENT.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.THIRD_AGENT);
            if (this.$scope.model.TRANSPORTER_PICKUP && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.TRANSPORTER_PICKUP.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.TRANSPORTER_PICKUP);
            if (this.$scope.model.TRANSPORTER_PLACE_RECEPT && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.TRANSPORTER_PLACE_RECEPT.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.TRANSPORTER_PLACE_RECEPT);
            if (this.$scope.model.TRANSPORTER_FINAL_DESTINATION && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.TRANSPORTER_FINAL_DESTINATION.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.TRANSPORTER_FINAL_DESTINATION);
            if (this.$scope.model.TRANSPORTER_DELIVERY && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.TRANSPORTER_DELIVERY.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.TRANSPORTER_DELIVERY);
            if (this.$scope.model.TRANSPORTER_GATE_OUT_EMPTY && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.TRANSPORTER_GATE_OUT_EMPTY.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.TRANSPORTER_GATE_OUT_EMPTY);
            if (this.$scope.model.TRANSPORTER_CONTAINER_STUFFING && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.TRANSPORTER_CONTAINER_STUFFING.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.TRANSPORTER_CONTAINER_STUFFING);
            if (this.$scope.model.FLEXI_FITTING && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.FLEXI_FITTING.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.FLEXI_FITTING);
            if (this.$scope.model.ISOTANK_PROVIDER && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.ISOTANK_PROVIDER.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.ISOTANK_PROVIDER);
            if (this.$scope.model.AGENCY && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.AGENCY.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.AGENCY);
            // if (this.$scope.model.REPRESENTATIVE && this.$scope.model.REPRESENTATIVE.length) {
            //     for (const representative of this.$scope.model.REPRESENTATIVE) {
            //         if (companies.findIndex(company => company.ID_LEGAL_PERSON == representative.ID_LEGAL_PERSON) < 0) companies.push(representative);
            //     }
            // }

            if (this.$scope.model.REPRESENTATIVES) {
                if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE.REPRESENTATIVE) {
                    if (companies.findIndex(companyId => companyId.ID_LEGAL_PERSON == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE.REPRESENTATIVE.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE.REPRESENTATIVE);
                }

                if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT.REPRESENTATIVE) {
                    if (companies.findIndex(companyId => companyId.ID_LEGAL_PERSON == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT.REPRESENTATIVE.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT.REPRESENTATIVE);
                }

                if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_LOAD && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_LOAD.REPRESENTATIVE) {
                    if (companies.findIndex(companyId => companyId.ID_LEGAL_PERSON == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_LOAD.REPRESENTATIVE.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_LOAD.REPRESENTATIVE);
                }

                if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PICK_UP && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PICK_UP.REPRESENTATIVE) {
                    if (companies.findIndex(companyId => companyId.ID_LEGAL_PERSON == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PICK_UP.REPRESENTATIVE.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PICK_UP.REPRESENTATIVE);
                }

                if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DELIVERY && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DELIVERY.REPRESENTATIVE) {
                    if (companies.findIndex(companyId => companyId.ID_LEGAL_PERSON == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DELIVERY.REPRESENTATIVE.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DELIVERY.REPRESENTATIVE);
                }

                if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION.REPRESENTATIVE) {
                    if (companies.findIndex(companyId => companyId.ID_LEGAL_PERSON == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION.REPRESENTATIVE.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION.REPRESENTATIVE);
                }
            }

            for (const company of companies) {
                const contacts = externalContacts && externalContacts.length ? externalContacts.filter(externalContact => externalContact.COMPANY && externalContact.COMPANY.ID == company.ID_LEGAL_PERSON) : [];
                const externalContactCompany: IExternalContactCompany = {
                    ID: company.ID,
                    NAME: company.NAME,
                    CONTACTS: contacts,
                    ADDRESS: company.ADDRESS,
                    CODE: company.CODE,
                    SCAC: company.SCAC,
                    NUMBER_IATA: company.NUMBER_IATA,
                    TAXPAYER_NUMBER: company.TAXPAYER_NUMBER,
                    TRADING_NAME: company.TRADING_NAME,
                    CORPORATE_NAME: company.CORPORATE_NAME,
                    ID_LEGAL_PERSON: company.ID_LEGAL_PERSON,
                    ID_PHYSICAL_PERSON: company.ID_PHYSICAL_PERSON,
                    NETWORK: company.NETWORK,
                };
                externalContactCompanies.push(externalContactCompany);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
        return externalContactCompanies;
    }

    
    private async getGenericList(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async collapseMain() {
        try {
            if (this.$newProcessDetDemScope.collapseState.released || this.$newProcessDetDemScope.collapseState.panel == ECollapseState.MAIN) {
                const collapseMain = angular.element("#collapseMain");
                if (collapseMain) {
                    const isCollapsed = angular.element("#collapseMainHeading").attr("aria-expanded") == "true";
                    if (isCollapsed) {
                        if (this.$newProcessDetDemScope.hasChanges(this.$scope.model, this.$scope.oldModel)) {
                            const main = this.formService.getTranslate('GENERAL.MAIN');
                            const close = this.formService.getTranslate('GENERAL.CLOSE');
                            const confirm = await this.$newProcessDetDemScope.modalSaveConfirmation(main, close);
                            if (confirm && !await this.saveProcessTabMain()) return;
                        }
                        this.$newProcessDetDemScope.collapseState.released = true;
                    }
                    collapseMain['collapse']('toggle');
                    if (isCollapsed) this.$newProcessDetDemScope.repositionPanels(ECollapseState.MAIN);
                }
            } else {
                this.$newProcessDetDemScope.collapseState.nextState = ECollapseState.MAIN;
                this.$newProcessDetDemScope.releaseCollapse();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getContacts(representative: REPRESENTATIVE_DEFAULT) {
        const companyIds: string[] = this.buildCompanyIds(true);
        const hasSameCompanyInAnotherObj = companyIds && companyIds.findIndex(companyId => companyId == representative.REPRESENTATIVE.ID_LEGAL_PERSON) >= 0;
        if (!hasSameCompanyInAnotherObj) {
            const contacts: EXTERNAL[] = await this.getExternalContact(representative.REPRESENTATIVE);
            if (contacts && contacts.length) {
                for (const contact of contacts) {
                    const hasContactInList = this.$scope.contactModel.EXTERNAL && this.$scope.contactModel.EXTERNAL.length && this.$scope.contactModel.EXTERNAL.findIndex(contactExternal => contactExternal.ID == contact.ID) >= 0;
                    if (!hasContactInList) this.$scope.contactModel.EXTERNAL.push(contact);
                }
            }
        }
    }

    private async openPeopleDetails(entity: ENTITY, agentEntity?: ENTITY_AGENT) {
        try {
            if (!entity && !agentEntity) return;
            const agentFields = agentEntity ? `
            <div class="row form-group">
                <div class="col-lg-6">
                    <label><b>${this.formService.getTranslate('ENTITY.SHIPPER_MASTER')}</b></label>
                    <textarea class="form-control" rows="6" style="resize:none" readonly>${agentEntity.SHIPPER_MASTER ? agentEntity.SHIPPER_MASTER : ''}</textarea>
                </div>
                <div class="col-lg-6">
                    <label><b>${this.formService.getTranslate('ENTITY.CONSIGNEE_MASTER')}</b></label>
                    <textarea class="form-control" rows="6" style="resize:none" readonly>${agentEntity.CNEE_MASTER ? agentEntity.CNEE_MASTER : ''}</textarea>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-lg-6">
                    <label><b>${this.formService.getTranslate('ENTITY.NOTIFY_MASTER')}</b></label>
                    <textarea class="form-control" rows="6" style="resize:none" readonly>${agentEntity.NOTIFY_MASTER ? agentEntity.NOTIFY_MASTER : ''}</textarea>
                </div>
                <div class="col-lg-6">
                    <label><b>${this.formService.getTranslate('ENTITY.REMARKS_MASTER')}</b></label>
                    <textarea class="form-control" rows="6" style="resize:none" readonly>${agentEntity.REMARKS_MASTER ? agentEntity.REMARKS_MASTER : ''}</textarea>
                </div>
            </div>
            ` : '';
            const body = `
            <div class="row form-group">
                <div class="col-lg-12">
                    <label><b>${this.formService.getTranslate('GENERAL.TAX_ID')}</b></label>
                    <span class="form-control" readonly>${agentEntity ? agentEntity.TAXPAYER_NUMBER : entity.TAXPAYER_NUMBER}</span>
                </div>
            </div>
            ${agentFields}
            <div class="row">
                <div class="col-lg-12">
                    <label><b>${this.formService.getTranslate('ENTITY.POSTAL_ADDRESS')}</b></label>
                    <textarea class="form-control" rows="6" style="resize:none" readonly>${agentEntity && agentEntity.AGENT_CORRESPONDENCE ? agentEntity.AGENT_CORRESPONDENCE : entity && entity.ADDRESS && entity.ADDRESS.CORRESPONDENCE ? entity.ADDRESS.CORRESPONDENCE : ''}</textarea>
                </div>
            </div>`;

            this.modalService.showModalInfo({}, {
                actionButtonText: 'GENERAL.CLOSE',
                headerText: agentEntity ? agentEntity.NAME : entity.NAME,
                bodyText: this.$sce.trustAsHtml(body)
            });

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getProcessTabsMain(): Promise<void> {
        this.formService.block();
        try {
            const mainTab = await this.dataProcessService.get(`/processDetDem/${this.$scope.idProcess}`, null, 30000);
            if (mainTab && mainTab.data && mainTab.data.data) {
                this.$scope.model = mainTab.data.data;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.oldModel = angular.copy(this.$scope.model);
            if (this.$newProcessDetDemScope.operation == 'edit') {
                this.$timeout(() => {
                    this.formService.loadEditForm();
                }, 100);
            }
            this.formService.unblock();
        }
    }

    private async getProcessContact(): Promise<void> {
        this.formService.block();
        try {
            // const contactRequest = await this.operationalService.get(`/process/panel/contact/${this.$scope.processNumber}`, null, 30000);
            // if (contactRequest && contactRequest.data && contactRequest.data.data) this.$scope.contactModel = contactRequest.data.data.CONTACT;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getExporterList(): Promise<EXPORTER[]> {
        let exporterList: EXPORTER[] = [];
        const timeout: number = 120000;
        try {
            switch (this.$newProcessDetDemScope.model.SITUATION.ID) {
                case EProcessSituation.PRE_PROCESS:
                    const legalPersons = await this.productService.post({ route: "/legalPerson/list/custom/operational", data: { specializations: [ELegalPersonSpecializationId.EXPORTER] } });
                    if (legalPersons && legalPersons.data && legalPersons.data.data) exporterList = legalPersons.data.data.map(x => {
                        return {
                            ID: x.ID,
                            NAME: x.NAME,
                            CODE: x.CODE,
                            ID_LEGAL_PERSON: x.ID_LEGAL_PERSON,
                            TRADING_NAME: x.CORPORATE_NAME,
                            TAXPAYER_NUMBER: x.TAXPAYER_NUMBER,
                            SCAC: x.SCAC,
                            NUMBER_IATA: x.NUMBER_IATA,
                            ADDRESS: x.ADDRESS,
                            NETWORK: x.NETWORK,
                            CORPORATE_NAME: x.CORPORATE_NAME,
                            ID_PHYSICAL_PERSON: x.ID_PHYSICAL_PERSON
                        }
                    });
                    break;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
        return exporterList;
    }

    private async getSalesPersonList(): Promise<ISelectorModel[]> {
        let salesPersonList: ISelectorModel[] = [];
        const timeout: number = 120000;

        try {
            switch (this.$newProcessDetDemScope.model.SITUATION.ID) {
                case EProcessSituation.PRE_PROCESS:
                    const legalPersons = await this.productService.post({ route: "/legalPerson/list/custom/operational", data: { specializations: [ELegalPersonSpecializationId.SALES_PERSON] } });
                    if (legalPersons && legalPersons.data && legalPersons.data.data) salesPersonList = legalPersons.data.data.map(x => {
                        return {
                            ID: x.ID,
                            NAME: x.NAME,
                            CODE: x.CODE,
                            ID_LEGAL_PERSON: x.ID_LEGAL_PERSON,
                            TRADING_NAME: x.CORPORATE_NAME,
                            TAXPAYER_NUMBER: x.TAXPAYER_NUMBER,
                            SCAC: x.SCAC,
                            NUMBER_IATA: x.NUMBER_IATA,
                            ADDRESS: x.ADDRESS,
                            NETWORK: x.NETWORK,
                            CORPORATE_NAME: x.CORPORATE_NAME,
                            ID_PHYSICAL_PERSON: x.ID_PHYSICAL_PERSON
                        }
                    });
                    break;
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }

        return salesPersonList;
    }

    private async getImporterList(): Promise<IMPORTER[]> {
        let importerList: IMPORTER[] = [];
        const timeout: number = 120000;

        try {
            switch (this.$newProcessDetDemScope.model.SITUATION.ID) {
                case EProcessSituation.PRE_PROCESS:
                    const legalPersons = await this.productService.post({ route: "/legalPerson/list/custom/operational", data: { specializations: [ELegalPersonSpecializationId.IMPORTER] } });
                    if (legalPersons && legalPersons.data && legalPersons.data.data) importerList = legalPersons.data.data.map(x => {
                        return {
                            ID: x.ID,
                            NAME: x.NAME,
                            CODE: x.CODE,
                            ID_LEGAL_PERSON: x.ID_LEGAL_PERSON,
                            TRADING_NAME: x.CORPORATE_NAME,
                            TAXPAYER_NUMBER: x.TAXPAYER_NUMBER,
                            SCAC: x.SCAC,
                            NUMBER_IATA: x.NUMBER_IATA,
                            ADDRESS: x.ADDRESS,
                            NETWORK: x.NETWORK,
                            CORPORATE_NAME: x.CORPORATE_NAME,
                            ID_PHYSICAL_PERSON: x.ID_PHYSICAL_PERSON
                        }
                    });
                    break;
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }

        return importerList;
    }

    private async saveProcessTabMain(): Promise<boolean> {
        let success = true;
        const isInvalid = this.$newProcessDetDemScope.hasInvalidRequiredElements('collapseMain') || this.$newProcessDetDemScope.hasInvalidElements('collapseMain');
        if (isInvalid) return false;
        try {
            this.formService.block();
            const result = await this.dataProcessService.post("/processDetDem/update", { data: this.$scope.model, oldData: this.$scope.oldModel, timeout: 30000 }, 30000);
            const msg = this.formService.getTranslate('OPERATIONAL.MAIN_TAB_DATA_SUCESSFULLY_SAVED');
            if (result && result.data && result.data.data) {
                this.formService.notifySuccess(msg);
                this.$newProcessDetDemScope.updateGridRow(this.$newProcessDetDemScope.model.ID.toString());
            } else success = false;
        } catch (ex) {
            success = false;
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            if (success) this.$scope.oldModel = angular.copy(this.$scope.model);
            else {
                this.$newProcessDetDemScope.collapseState.nextState = null;
            }
            return success;
        }
    }

    private async getMoveTypeList(): Promise<ISelectorModel[]> {
        try {
            const request: IMonacoRequest = {
                route: `/moveType/list/full`,
                timeout: 30000,
            };

            const rc = await this.productService.get(request);
            const result = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            const moveTypeList: ISelectorModel[] = [];
            for (const moveType of result) {
                moveTypeList.push({
                    ID: moveType.ID.toString(),
                    NAME: moveType.NAME,
                    CODE: moveType.CODE
                });
            }

            return moveTypeList;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getIncotermList(): Promise<ISelectorModel[]> {
        try {
            const request: IMonacoRequest = {
                data: null,
                route: `/incoterm/list`,
                timeout: 30000,
            };

            const rc = await this.productService.post(request);
            const result = (rc && rc.data && rc.data.data && rc.data.data.data) ? rc.data.data.data : null;

            const incotermList: ISelectorModel[] = [];
            for (const resultItem of result) {
                incotermList.push({
                    ID: resultItem.ID,
                    CODE: resultItem.INITIALS,
                    NAME: resultItem.NAME
                });
            }

            return incotermList;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }
}

import { IGridApiOf } from "ui-grid";
import * as angular from 'angular';

export abstract class GridExcelUtil {

    public static configureExcel($scope: angular.IScope, gridApi: IGridApiOf<any>) {

        if (gridApi.edit) {

            gridApi.edit.on.beginCellEdit($scope, () => {
                let focus = angular.element('.ui-grid-cell-focus');
                focus.parent().css('overflow', 'visible');
            });

        }

        if (gridApi.cellNav) {
            gridApi.cellNav.on.navigate($scope, function (newCell, oldCell) {

                if (oldCell) {
                    let oldElem = GridExcelUtil.getElementByCell(oldCell).find('.ui-grid-cell-contents');
                    oldElem.removeClass('ui-grid-custom-out-selected');

                    if (oldCell.col.colDef.enableCellEdit) {
                        oldElem.removeClass('ui-grid-custom-selected');
                    }
                }

                let newElem = GridExcelUtil.getElementByCell(newCell).find('.ui-grid-cell-contents');

                if (newCell.col.colDef.enableCellEdit) {
                    newElem.addClass('ui-grid-custom-selected');
                } else {
                    newElem.addClass('ui-grid-custom-out-selected');
                }

            });
        }
    };

    private static getElementByCell(cell) {

        let rowIndex = cell.row.grid.renderContainers.body.renderedRows.indexOf(cell.row);
        let element = angular.element('#' + cell.row.grid.id + '-' + rowIndex + '-' + cell.col.uid + '-cell');

        return element;
    }

}
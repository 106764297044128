import { IException } from './../../common/interface/IException';
import * as angular from "angular";
import { HandleError } from "../../common/util/HandleError";

interface ISearchPanel {
    input?: string,
    maxInput: number;
    wildcard: string;
    request?: string;
    updateInterval: number;
    clearInput?: () => void;
}

interface ISearchCardScope extends ISearchPanel, ng.IScope { }

uiSearchPanel.$inject = ['$interval']
export function uiSearchPanel($interval: ng.IIntervalService): ng.IDirective {

    const ddo: ng.IDirective = {
        template: require("../view/template/ui-search-panel.html"),
        restrict: 'E',
        require: 'ngModel',
        scope: {
            maxInput: '@',
            wildcard: '@',
            updateInterval: '@',
            request: '@',
            placeholder: '@'
        },
        transclude: true,
        link: ($scope: ISearchCardScope, element: JQLite, attributes: ng.IAttributes, ngModel: ng.IController) => {

            let updateInterval = null;
            $scope.$on('$destroy', function () {
                $interval.cancel(updateInterval);
                updateInterval = null;
            });

            const defaultOptions: ISearchPanel = {
                maxInput: 3,
                wildcard: '%%%',
                updateInterval: 300000,
            }

            const directiveOptions: ISearchPanel = {
                maxInput: $scope.maxInput,
                wildcard: $scope.wildcard,
                updateInterval: $scope.updateInterval,
                request: $scope.request,
            }

            const options: ISearchPanel = angular.extend(directiveOptions, defaultOptions);

            $scope.$watch('input', () => {

                const viewModel: ISearchPanel = {
                    input: $scope.input,
                    wildcard: $scope.wildcard,
                    maxInput: $scope.maxInput,
                    updateInterval: $scope.updateInterval,
                    clearInput: () => {
                        $scope.input = '';
                    }
                };

                ngModel.$setViewValue(viewModel);
            });

            $scope.request = options.request;
            $scope.maxInput = options.maxInput;
            $scope.updateInterval = options.updateInterval;
            $scope.wildcard = options.wildcard;

            try {

                if (!$scope.$parent[$scope.request])
                    throw new Error('Implemente o atributo request com a função da sua controller correta! <b>Diretiva ui-search-panel</b>.');

                $scope.$parent[$scope.request]();

                updateInterval = $interval(() => {
                    $scope.$parent[$scope.request]();
                }, options.updateInterval);

            } catch (e) {
                HandleError.exception(e);
            }

        }
    }

    return ddo;
}

import * as angular from 'angular';

monacoSelect.$inject = ['$window', '$timeout', '$compile']
export function monacoSelect($window, $timeout, $compile) {
    let ddo: any = {};

    ddo.restrict = "A";

    ddo.link = (scope, el, attrs) => {
        const isMultiple = angular.isDefined(attrs.multiple);
        const breakWidth = angular.isDefined(attrs.breakWidth) ? parseInt(attrs.breakWidth) : 50;
        if (isNaN(breakWidth) || typeof breakWidth != 'number') throw new Error('BreakWidth must be of integer type.');
        if (isMultiple) {
            el.addClass('monaco-select');
            // when window resize.
            $window.onresize = () => {
                adjustSearchInputWidth(el.width());
            };
            // when change width.
            scope.$watch(function () {
                return el.width();
            }, function (newWidth) {
                adjustSearchInputWidth(newWidth);
            });
            // when change value.
            scope.$watch('ngModel.$modelValue', () => {
                $timeout(() => {
                    adjustSearchInputWidth(el.width());
                });
            });
        }

        function adjustSearchInputWidth(containerWidth: number) {
            const uiSelectMatch = el.find('.ui-select-match');
            const matchItens = uiSelectMatch.find('.ui-select-match-item');
            const uiSelectSearch = el.find('.ui-select-search');
            const searchParent = uiSelectSearch[0].parentNode;
            const wrapperAlreadyExists = angular.element(searchParent).hasClass("search-wrapper");
            const wrapper = wrapperAlreadyExists ? searchParent : document.createElement("div");
            let inputWidth = 0;
            let totalItens = 0;
            if (containerWidth > 0) {
                let firstItem = true;
                if (matchItens && matchItens.length > 0) {
                    for (const item of matchItens) {
                        // necessary to get the total width contemplating the margins
                        const parentItem = angular.element(item).parent();
                        const remainingSpace = inputWidth - parentItem.width();
                        let isNewLine = false;
                        if (!firstItem && remainingSpace < breakWidth) {
                            totalItens = 0;
                            isNewLine = true;
                        }
                        if (!(isNewLine && remainingSpace >= 0)) totalItens += parentItem.width();
                        inputWidth = containerWidth - totalItens - 1;
                        firstItem = false;
                    };
                } else inputWidth = containerWidth;
                if (inputWidth > breakWidth) angular.element(wrapper).width(inputWidth);
                if (!wrapperAlreadyExists && uiSelectSearch && uiSelectSearch[0]) {
                    wrapper.className = "search-wrapper";
                    searchParent.replaceChild(wrapper, uiSelectSearch[0]);
                    wrapper.appendChild(uiSelectSearch[0]);
                }
            }
            if (isMultiple && wrapper && !scope.$select.ngModel.$modelValue && attrs.customTooltip) {
                angular.element(wrapper).attr("uib-tooltip", attrs.customTooltip);
                angular.element(wrapper).attr("tooltip-placement", "auto top");
                angular.element(wrapper).attr("tooltip-append-to-body", "true");
                $compile(wrapper)(scope);
            }
        }
    }
    return ddo;
}

uiButterbar.$inject = ['$rootScope', '$anchorScroll'];
export function uiButterbar($rootScope, $anchorScroll) {
  return {
    restrict: 'AC',
    template: '<span class="bar"></span>',
    link: function (scope, el, attrs) {
      el.addClass('butterbar hide');
      const stateChangeStart = scope.$on('$stateChangeStart', function (event) {
        $anchorScroll();
        el.removeClass('hide').addClass('active');
      });
      const stateChangeSuccess = scope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState) {
        event.targetScope.$watch('$viewContentLoaded', function () {
          el.addClass('hide').removeClass('active');
        })
      });
      scope.$on('$destroy', () => {
        if (stateChangeStart) stateChangeStart();
        if (stateChangeSuccess) stateChangeSuccess();
      });
    }
  };
}
import * as angular from 'angular';

const finopState = {
    name: 'app.finop',
    url: '/finop',
    abstract: true,
    data: {
        /* Solução de carregar os termos por módulo
        i18n: ['general', 'registration', 'route', 'product', 'financial', 'basic_data', 'entity', 'operational'],
        */
        requiresAuth: true,
        permissions: {
            except: 'EXTERNAL',
            redirectTo: 'app.denied'
        }
    }
}

const finopMenuState = {
    name: 'app.finop.menu',
    url: '/menu',
    views: {
        'content@app': {
            template: require("./view/finopMenu.html")
        },
    },
    params: {
        operation: 'register',
        financialcontract: undefined,
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.FINOP',
        parent: 'app.finop'
    }
}

const contractState = {
    name: 'app.finop.contract',
    url: '/contract',
    abstract: true,
    data: {
        requiresAuth: true
    }
}

const chargeState = {
    name: 'app.finop.charge',
    url: '/charge',
    abstract: true,
    data: {
        requiresAuth: true
    }
}

const chargeRegisterState = {
    name: 'app.finop.charge.register',
    url: '/register',
    views: {
        'content@app': {
            template: require("./view/chargeRegister.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.CHARGES',
        parent: 'app.finop.menu'
    }
}

const chargeRegisterProcessState = {
    name: 'app.finop.charge.process',
    url: '/process/:processNumber',
    views: {
        'content@app': {
            template: require("./view/chargeRegister.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.CHARGE_REGISTER',
        parent: 'app.finop.menu'
    }
}

const chargeGroupingState = {
    name: 'app.finop.charge.grouping',
    url: '/grouping',
    abstract: true,
    data: {
        requiresAuth: true
    }
}

const chargeGroupingRegisterState = {
    name: 'app.finop.charge.grouping.register',
    url: '/register',
    views: {
        'content@app': {
            template: require("./view/chargeGroupingParamsRegister.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.CHARGE_GROUPING',
        parent: 'app.finop.menu'
    }
}

const contractRegisterState = {
    name: 'app.finop.contract.financialContract',
    url: '/financialContract',
    views: {
        'content@app': {
            template: require("./view/contractRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.FINANCIAL_CONTRACT',
        parent: 'app.finop.menu'
    }
}

const invoiceState = {
    name: 'app.finop.invoice',
    url: '/invoice',
    abstract: true,
    data: {
        requiresAuth: true
    }
}

const invoiceRegisterState = {
    name: 'app.finop.invoice.register',
    url: '/register',
    views: {
        'content@app': {
            template: require("./view/invoiceRegister.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.INVOICES',
        parent: 'app.finop.menu'
    }
}

const invoiceRegisterStateView = {
    name: 'app.finop.invoice.filter',
    url: '/register/filter/:property/:value',
    views: {
        'content@app': {
            template: require("./view/invoiceRegister.html")
        },
    },
    params: {
        operation: 'register',
        filter: true,
        property: null,
        value: null
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.INVOICES',
        parent: 'app.finop.menu'
    }
}

const billingContactState = {
    name: 'app.finop.billing',
    url: '/billingContact',
    abstract: true,
    data: {
        requiresAuth: true
    }
}

/* TODO: Disabled 3.0 - #557
const billingContactRegisterState = {
    name: 'app.finop.billing.register',
    url: '/register',
    views: {
        'content@app': {
            template: require("./view/billingContactRegister.html")
        },
    },
    ncyBreadcrumb: {
        label: 'Cobranças Financeiras',
        parent: 'app.finop.menu'
    }
}


const billingContactRegisterStateView = {
    name: 'app.finop.billing.filter',
    url: '/register/filter/:property/:value',
    views: {
        'content@app': {
            template: require("./view/billingContactRegister.html")
        },
    },
    params: {
        operation: 'register',
        filter: true,
        property: null,
        value: null
    },
    ncyBreadcrumb: {
        label: 'Cobranças Financeiras',
        parent: 'app.finop.menu'
    }
}
*/
routeConfig.$inject = ['$uiRouterProvider'];
function routeConfig($uiRouterProvider) {
    const $stateRegistry = $uiRouterProvider.stateRegistry;
    $stateRegistry.register(finopState);
    $stateRegistry.register(contractState);
    $stateRegistry.register(chargeState);
    $stateRegistry.register(chargeRegisterState);
    $stateRegistry.register(chargeRegisterProcessState);
    $stateRegistry.register(finopMenuState);
    $stateRegistry.register(chargeGroupingState);
    $stateRegistry.register(chargeGroupingRegisterState);
    $stateRegistry.register(invoiceState);
    $stateRegistry.register(invoiceRegisterState);
    $stateRegistry.register(invoiceRegisterStateView);
    $stateRegistry.register(billingContactState);
    $stateRegistry.register(contractRegisterState);
}

export default function initRoute(moduleName: string): void {
    angular.module(moduleName).config(routeConfig);
}
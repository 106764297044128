import { SelectorModel } from "../../common/model/SelectorModel";
import { IRestService } from "@services/RestService";
import { ReportAutomaticRulesModel } from "@models/interface/operational/ReportAutomaticRulesModel";
import { REFERENCE } from "@models/interface/operational/FollowUpProcessModel";
import { DataManagerService } from "@services/DataManagerService";

export interface IReportAutomaticRulesServiceResultProtocol {
    ruleId: number;
    score: number;
    visible: boolean;
    reference: REFERENCE;
}

export class ReportAutomaticRulesService {
    private processNumber: string;
    private documentTypeList: SelectorModel[];
    private language: SelectorModel;
    private timeout: number;
    private dataManagerService: DataManagerService;

    constructor(processNumber: string, documentTypeList: SelectorModel[], language: SelectorModel, dataManagerService: DataManagerService) {
        this.processNumber = processNumber;
        this.documentTypeList = documentTypeList;
        this.language = language;
        this.dataManagerService = dataManagerService;
        this.timeout = 120000;
    }

    public async getTemplateList(): Promise<ReportAutomaticRulesModel[]> {
        try {
            if (!this.processNumber || !this.documentTypeList) return [];
            let modelList: ReportAutomaticRulesModel[] = [];
            const request = {
                processNumber: this.processNumber,
                documentTypeList: this.documentTypeList,
                language: null,
                timeout: this.timeout
            };

            const rc = await this.dataManagerService.post("/reportAutomaticRules/templates", request, this.timeout);
            if (rc.status !== 200) throw new Error(rc.data.data);

            if (rc.data && rc.data.data && rc.data.data.length > 0) {
                modelList = modelList.concat(rc.data.data);
            }
            return modelList;
        } catch (ex) {
            throw ex;
        }
    }

    public async run(): Promise<IReportAutomaticRulesServiceResultProtocol[]> {
        try {
            if (!this.processNumber || !this.documentTypeList) return [];

            let protocolList: IReportAutomaticRulesServiceResultProtocol[] = [];

            const request = {
                processNumber: this.processNumber,
                documentTypeList: this.documentTypeList,
                language: this.language,
                timeout: this.timeout
            };

            const rc = await this.dataManagerService.post("/reportAutomaticRules/run", request, this.timeout);
            if (rc.status !== 200) throw new Error(rc.data.data);

            if (rc.data && rc.data.data && rc.data.data.length > 0) {
                protocolList = protocolList.concat(rc.data.data);
            }
            return protocolList;
        } catch (ex) {
            throw ex;
        }
    }
}

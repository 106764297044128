import angular = require('angular');
import { SSEService } from '@appServices/SSEService';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from '@services/GridFormService';
import { IMonacoColumnDef } from '@services/GridService2';
import { IRestService } from '@services/RestService';
import { ISessionService } from "@services/SessionService";
import { IModalService } from "@services/ModalService";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { ICityModel } from '@models/interface/product/CityModel';
import { IProvince } from '@models/interface/product/ProvinceModel';
import { ITradeLane, ITradeGroup } from '@models/interface/product/TradeLaneModel';
import { IDocumentError } from '@models/interface/common/IDocumentError';
import { EOperation } from '@enums/GenericData';
import { GridColumnBuilder, GridColumnBuilderConstants } from '../../common/GridColumnBuilder';
import { ILinkParameter } from "../../common/model/ModelParameter";
import { BrowserTitle } from "../../common/BrowserTitle";
import { SelectorModel } from "../../common/model/SelectorModel";
import { DataProductService } from '@services/DataProductService';

interface ITradeLaneSelector extends SelectorModel {
    TRADE_GROUP: SelectorModel;
}

interface ICityScope extends IGridFormServiceScope {
    model: ICityModel;
    gridOptions: uiGrid.IGridOptions;
    scopeBeforeSave: ICityModel;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    provinceList: IProvince[];
    tradeLaneList: ITradeLane[];
    tradeGroupList: ITradeGroup[];
    user: any;
    sessionService: ISessionService;
    editCity: (city: ICityModel) => Promise<void>;
    viewCity: (city: ICityModel) => Promise<void>;
    viewLogCity: (city: ICityModel) => Promise<void>;
    copyCity: (city: ICityModel) => Promise<void>;
    getProvinceData(query: string): Promise<void>;
    getTradeLaneData(): Promise<void>;
    getTradeGroupData(): Promise<void>;
    goToProvince: (id: number) => void;
    goToTradeLane: (tradeLane: SelectorModel) => void;
    updateSelectedProvince(data: IProvince): void;
    updateSelectedTradeLane(data: ITradeLaneSelector): void;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
    fetchData: (id: number, action: string) => Promise<void>;
    updateReferenceIds(data: IProvince): Promise<void>;
}
export class CityRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService
    private $scope: ICityScope;
    private RestService: IRestService;
    private SSEService: SSEService;
    private dataProductService: DataProductService;
    private ModalService: IModalService;
    private modalID: number;
    private gridName: string;

    constructor($injector: ng.Injectable<any>, $scope: ICityScope) {
        super($injector, $scope);
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.$scope = $scope;
        this.$scope.sessionService = $injector.get('SessionService');
        this.RestService = $injector.get('RestService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.dataProductService = $injector.get('DataProductService');
        this.ModalService = $injector.get('ModalService');
        this.modalID = null;
        this.gridName = 'GRID_CITY';
    }

    getUrlProduct() {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    async $onInit?() {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.$scope.provinceList = [];
            this.initForm(this, 'form', 'city', "GENERAL.MENU.CITY", true);
            await this.initGrid(this.gridName, '/city/list', true, true, null, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editCity = async (city: ICityModel): Promise<void> => {
            let blockedObject = {
                ID: city.ID,
                NAME: city.NAME,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || city.ID !== this.$scope.model.ID) this.$scope.fetchData(city.ID, EOperation.VIEW);
                } else if (this.$scope.operation !== EOperation.EDIT || city.ID !== this.$scope.model.ID) {
                    this.$scope.fetchData(city.ID, EOperation.EDIT);
                }
            };
        }

        this.$scope.viewCity = async (city: ICityModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(city.ID, EOperation.VIEW);
        }

        this.$scope.viewLogCity = async (city: ICityModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(city);
        }

        this.$scope.copyCity = async (city: ICityModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(city.ID, EOperation.COPY);
        }

        //province 
        this.$scope.getProvinceData = async (query) => {
            let provinceList: IProvince[] = [];
            if (query && query.length >= 2) {
                provinceList = await this.getProvinceData(query);
            }
            this.$scope.provinceList = provinceList;
        }
        this.$scope.updateSelectedProvince = (data) => this.updateSelectedProvince(data);
        this.$scope.goToProvince = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.province", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        //tradelane
        this.$scope.getTradeLaneData = async () => {
            let tradeLaneList: ITradeLane[] = [];
            tradeLaneList = await this.getTradeLaneData();
            this.$scope.tradeLaneList = tradeLaneList;
        }
        this.$scope.updateSelectedTradeLane = (data: ITradeLaneSelector) => this.updateSelectedTradeLane(data);
        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }
        this.$scope.goToTradeLane = (tradeLane: SelectorModel) => {
            this.$scope.sessionService.openTab("app.registration.tradelane", <ILinkParameter>{ ID: tradeLane ? tradeLane.ID.toString() : null });
        }
        this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
            this.fetchData(id, action);
        }
        this.$scope.updateReferenceIds = (data: IProvince) => this.updateReferenceIds(data);
    }

    initModel() {
        this.$scope.model = {
            _id: null,
            ID: null,
            NAME: null,
            INITIALS: null,
            NAME_INTL: null,
            ID_PROVINCE: null,
            ID_COUNTRY: null,
            PROVINCE: null,
            TRADE_LANE: null,
            TRADE_GROUP: null,
            ACTIVE: true,
            INTEGRATION_ID: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            SEARCH_FIELDS: null,
            DOCUMENT_ERROR: null
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<a ng-click="grid.appScope.viewCity(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editCity(row.entity)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyCity(row.entity)" class="text-orange copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogCity(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: 120,
            cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${copy} ${viewLog} ${modalIntegration}</div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };

        gridColumns.addColumn(colActions);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    private buildColumns(columns: Array<string>) {
        let gridColumns: Array<IMonacoColumnDef> = [];

        const colInitials: IMonacoColumnDef = { name: 'INITIALS', displayName: "ROUTE.LOCAL_CODE", width: 80 };
        const colProvince: IMonacoColumnDef = { name: 'PROVINCE.CODE', displayName: "ROUTE.STATE", width: 80 };
        const colCountry: IMonacoColumnDef = { name: 'PROVINCE.COUNTRY.CODE', displayName: "ROUTE.COUNTRY", width: 80 };
        const colName: IMonacoColumnDef = { name: 'NAME', displayName: "GENERAL.NAME", width: 350 };
        const colExtName: IMonacoColumnDef = { name: 'NAME_INTL', displayName: "GENERAL.NAME_INTL", width: 350 };
        const colTradeLane: IMonacoColumnDef = { name: "TRADE_LANE.CODE", displayName: "ROUTE.TRADE_LANE", width: 200, field: 'TRADE_LANE.CODE' };
        const colTradeGroup: IMonacoColumnDef = { name: "TRADE_GROUP", displayName: "ROUTE.TRADE_GROUP", width: 200, field: 'TRADE_GROUP.NAME' };
        const colActive: IMonacoColumnDef = { name: 'ACTIVE', displayName: "GENERAL.ACTIVE", width: 70, cellFilter: 'YesOrNo' };
        const colIntegrationID: IMonacoColumnDef = { name: 'INTEGRATION_ID', displayName: "GENERAL.INTEGRATION_ID", width: 150 };
        const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
        const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
        const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

        columns.forEach(column => {
            switch (column) {
                case 'INITIALS':
                    gridColumns.push(colInitials);
                    break;
                case 'NAME':
                    gridColumns.push(colName);
                    break;
                case 'NAME_INTL':
                    gridColumns.push(colExtName);
                    break;
                case 'PROVINCE':
                    gridColumns.push(colProvince);
                    break;
                case 'COUNTRY':
                    gridColumns.push(colCountry);
                    break;
                case 'TRADE_LANE':
                    gridColumns.push(colTradeLane);
                    break;
                case 'TRADE_GROUP':
                    gridColumns.push(colTradeGroup);
                    break;
                case 'ACTIVE':
                    gridColumns.push(colActive);
                    break;
                case 'INTEGRATION_ID':
                    gridColumns.push(colIntegrationID);
                    break;
                case 'ID':
                    gridColumns.push(colId);
                    break;
                case 'CREATED_AT':
                    gridColumns.push(colCreatedAt);
                    break;
                case 'UPDATED_AT':
                    gridColumns.push(colUpdatedAt);
                    break;
            }
        });

        return gridColumns;
    }

    initDependencies(): Promise<any> {
        const self: CityRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([]).then((result: any) => {
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    async register(params: ICityModel): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
            this.$scope.disableElements(false);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")} (${this.$scope.model.NAME})`;
            BrowserTitle.$id = this.$scope.model.NAME;
            this.$scope.disableElements(true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")} (${this.$scope.model.NAME})`;
            BrowserTitle.$id = this.$scope.model.NAME;
            this.$scope.disableElements(false);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        return {
            route: `/city/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "GENERAL.MENU.INTEGRATION" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number, idSubject: string): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/city/sendSync`, { "idCity": id, "idSubject": idSubject }, 120000, false);
                if (syncRequest) success = true;
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_SENDING_REQUEST');
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const legalPersonData = await this.getCityById(id);
                    if (row && legalPersonData && legalPersonData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = legalPersonData.DOCUMENT_ERROR;
                        documentError = legalPersonData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async getCityById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/city/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getProvinceData(query: string): Promise<IProvince[]> {
        let result = [];
        this.formService.block();
        try {
            const provincies = await this.RestService.newObjectPromise(`${this.$baseUrl}/province/list/custom/`, { search: query }, 30000, false);
            if (provincies && provincies.length > 0) result = provincies.map(province => { return { ID: province.ID, NAME: province.NAME, CODE: province.INITIALS, COUNTRY: { ID: province.COUNTRY.ID, NAME: province.COUNTRY.NAME, CODE: province.COUNTRY.INITIALS } } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getTradeLaneData(): Promise<ITradeLane[]> {
        let result = [];
        let idCountry = null;

        if (this.$scope.model.PROVINCE.COUNTRY && this.$scope.model.PROVINCE.COUNTRY.ID) idCountry = this.$scope.model.PROVINCE.COUNTRY.ID;
        try {
            this.formService.block();
            //search tradelane in Country 
            const countries = await this.RestService.newObjectPromise(`${this.$baseUrl}/country/list/customById/`, { id: idCountry }, 30000, false);
            if (countries && countries.TRADE_LANE) {
                for (let tradeLaneCountry of countries.TRADE_LANE) {
                    let search = tradeLaneCountry.CODE;
                    const tradeLanes = await this.RestService.newObjectPromise(`${this.$baseUrl}/tradelane/list/custom/`, { name: search }, 30000, false);
                    for (let tradeLane of tradeLanes) {
                        result.push({ ID: tradeLane.ID, NAME: tradeLane.NAME, CODE: tradeLane.CODE, TRADE_GROUP: tradeLane.TRADE_GROUP });
                    }
                }
            }
            if (result.length == 1) {
                this.$scope.model.TRADE_LANE = result[0];
                let tradeGroup = result[0].TRADE_GROUP;
                this.$scope.model.TRADE_GROUP = tradeGroup;
            } else {
                this.$scope.model.TRADE_LANE = null
                this.$scope.model.TRADE_GROUP = null;
            }

            this.$timeout(() => { this.$scope.selectorValidity('cityTradeLaneID') }, 100);

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async updateSelectedProvince(data: IProvince) {
        if (data) {
            this.$scope.model.PROVINCE = data;
            const tradeLaneList: ITradeLane[] = await this.getTradeLaneData();
            this.$scope.tradeLaneList = tradeLaneList;
        }
        this.$timeout(() => {
            this.$scope.selectorValidity('comercialLine');
        });
    }

    private updateSelectedTradeLane(data: ITradeLaneSelector) {
        if (data) {
            if (data) {
                this.$scope.model.TRADE_GROUP = data.TRADE_GROUP;
                this.$scope.tradeGroupList = [data.TRADE_GROUP];
            } else this.$scope.tradeGroupList = [];

            this.$timeout(() => {
                this.$scope.selectorValidity('cityTradeLaneID');
                this.$scope.selectorValidity('comercialLine');
            });
        }
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'INITIALS',
                LABEL: 'GENERAL.CODE'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'NAME_INTL',
                LABEL: 'GENERAL.NAME_INTL'
            },
            {
                PROPERTY: 'FISCAL',
                LABEL: 'REGISTRATION.TAX_HAVEN'
            },
            {
                PROPERTY: 'PROVINCE',
                LABEL: 'GENERAL.MENU.PROVINCE'
            },
            {
                PROPERTY: 'TRADE_LANE',
                LABEL: 'ROUTE.TRADE_LANE'
            },
            {
                PROPERTY: 'TRADE_GROUP',
                LABEL: 'ROUTE.TRADE_GROUP'
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
            {
                PROPERTY: 'INTEGRATION_ID',
                LABEL: 'GENERAL.INTEGRATION_ID'
            },
            {
                PROPERTY: 'ID',
                LABEL: 'REGISTRATION.IDENTIFICATION'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            },
            {
                PROPERTY: 'CODE',
                LABEL: 'GENERAL.CODE'
            },
            {
                PROPERTY: 'COUNTRY',
                LABEL: 'ROUTE.COUNTRY'
            }
        ];
        return props;
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/city/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async updateReferenceIds(data: IProvince): Promise<void> {
        this.$scope.model.ID_PROVINCE = data ? parseInt(data.ID) : null;
        this.$scope.model.ID_COUNTRY = data ? parseInt(data.COUNTRY.ID) : null;
    }
}

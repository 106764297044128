export const enum EAction {
    NEW,
    REMOVE,
    RECALCULATION,
    EQUALIZATION,
    CONTRACT_CHANGE,
    ACCOUNT_CHANGE,
    NEGOTIATION,
    INSTRUCTION,
    OPEN,
    PRE_INVOICE,
    LOCK,
    UNLOCK,
    MANUAL_UNLOCK,
    UNIFY,
    SEPARATE,
    HC2_INTEGRATION_UPDATE,
    PENDING_INTEGRATION_ORDER,
    PENDING_INTEGRATION_BILLING,
    PAID,
    PARTIAL_PAID,
    UNPAID,
    EXCHANGE_CONTRACT,
    EXTERNAL_FILE,
    COMMENT,
    FINANCIAL_MOVEMENTS
}

export const enum EGeneratedBy {
    WIZARD = 'W',
    MANUAL = 'M'
}
import { IGridRow, IColumnDef } from "ui-grid";
import { GridFormService, IGridFormController, IGridFormServiceScope } from "@services/GridFormService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IRestService } from "@services/RestService";
import { IModalService } from '@services/ModalService';
import { ProductService } from '@services/ProductService';

import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { EdiExternalCodeSetup, CODE_INTEGRATION } from "@models/interface/integration/EdiExternalCodeSetup";
import { ISelectorModel, SelectorModel } from "@models/mongo/SelectorModel";
import { IRoutingPointListCustomFilter } from "@models/interface/product/RoutingPointModel";
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { EIntegrationRegisterTypeId, EOperation, EProviderTypeId } from "@enums/GenericData";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { ILegalPersonListCustomFilter, IProviderListCustomFilter } from "../model/LegalPersonModel";
import { IAgentListCustomFilter } from "../model/AgentModel";
import { OperationalService } from "@services/OperationalService";
import { HelperService } from "@services/HelperService";

export interface IMonacoRequest<T = any> {
    route?: string;
    operation?: string;
    data?: T;
    timeout?: number;
    user?: any;
}

interface IEdiExternalCodeSetupScope extends IGridFormServiceScope {
    model: EdiExternalCodeSetup;
    log: IViewLog;
    customerList: ISelectorModel[];
    typeList: ISelectorModel[];
    codeInternalList: ISelectorModel[];
    providerList: ISelectorModel[];
    integrationList: ISelectorModel[];
    customLogProperties: ICustomLogProperties[];
    selectedRows: EdiExternalCodeSetup[];

    typeChange: () => void;
    showActionsSelected: (row: EdiExternalCodeSetup, action: string) => void;
    getCodeInternalList: (search: string) => void;
    getCustomerList: (search: string) => Promise<void>;
    getProvidersList: (search: string) => Promise<void>;
    addEdiCode: () => Promise<void>;
    removeCode: (code: CODE_INTEGRATION) => Promise<void>;
}
export class EdiExternalCodeSetupController extends GridFormService implements IGridFormController {

    static $inject: string[] = ["$injector", "$scope", "$element"];
    private $scope: IEdiExternalCodeSetupScope;
    private $q: ng.IQService;
    private selectedRows: EdiExternalCodeSetup[];
    private RestService: IRestService;
    private ModalService: IModalService;
    private ProductService: ProductService;
    private operationalService: OperationalService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IEdiExternalCodeSetupScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = this.$injector.get("RestService");
        this.ModalService = this.$injector.get('ModalService');
        this.ProductService = $injector.get("ProductService");
        this.operationalService = $injector.get('OperationalService');
        this.helperService = $injector.get('HelperService'); 
    }


    initScopeFunctions(): void {

        this.$scope.typeChange = () => {
            this.$scope.model.CODE_INTEGRATION = [{ CODE_INTERNAL: null, CODE_EXTERNAL: null }];
        }

        this.$scope.addEdiCode = async (): Promise<void> => {
            return await this.addEdiCode();
        }

        this.$scope.removeCode = async (code: CODE_INTEGRATION): Promise<void> => {
            return await this.removeCode(code);
        }

        this.$scope.getCustomerList = async (search: string): Promise<void> => {
            this.$scope.customerList = await this.getLegalPerson({ search: search, specializations: [ELegalPersonSpecializationId.ACCOUNT] });
        }

        this.$scope.getCodeInternalList = async (search: string): Promise<void> => {
            let codeInternalList: ISelectorModel[] = [];
            if (this.$scope.model.TYPE) {
                if (this.$scope.model.TYPE.ID == EIntegrationRegisterTypeId.CHARGE) codeInternalList = await this.getCharges(search);
                if (this.$scope.model.TYPE.ID == EIntegrationRegisterTypeId.EVENT) codeInternalList = await this.getEvents(search);
                if (this.$scope.model.TYPE.ID == EIntegrationRegisterTypeId.ROUTING_POINT) codeInternalList = await this.getRoutingPoints({ name: search });
                if (this.$scope.model.TYPE.ID == EIntegrationRegisterTypeId.AGENT) codeInternalList = await this.getAgents({ search: search, networks: null, products: null });
                if (this.$scope.model.TYPE.ID == EIntegrationRegisterTypeId.BROKER) codeInternalList = await this.getLegalPerson({ search: search, specializations: [ELegalPersonSpecializationId.BROKER] });
                if (this.$scope.model.TYPE.ID == EIntegrationRegisterTypeId.PROVIDER) codeInternalList = await this.getLegalPerson({ search: search, specializations: [ELegalPersonSpecializationId.PROVIDER] });
                if (this.$scope.model.TYPE.ID == EIntegrationRegisterTypeId.BRANCH) codeInternalList = await this.getCorporateBranchListByName(search);
                if (this.$scope.model.TYPE.ID == EIntegrationRegisterTypeId.SHIP_OWNER) codeInternalList = await this.getShipOwner({ search: search, types: [EProviderTypeId.SHIPOWNER] });
                if (this.$scope.model.TYPE.ID == EIntegrationRegisterTypeId.EQUIPMENT) codeInternalList = await this.getEquipment(search);
            }
            this.$scope.codeInternalList = codeInternalList;
        }

        this.$scope.getProvidersList = async (search: string): Promise<void> => {
            this.$scope.providerList = await this.getLegalPerson({ search: search, specializations: [ELegalPersonSpecializationId.PROVIDER] });
        }

    }

    async $onInit(): Promise<void> {
        try {
            this.selectedRows = [];
            this.$scope.selectedRows = [];
            this.$baseUrl = this.config.externalUrl + '/external';
            this.initForm(this, "form", "EdiExternalCodeSetup", "GENERAL.MENU.FROM_TO", true);
            this.block();

            // enable multi row selection
            this.$gridService.setSelectable(true);

            // init grid
            await this.initGrid('gridEdiExternalCodeSetup', '/ediExternalCodeSetup/list', true, true, 120000, true, true);
            this.$gridService.setBackgroundUpdate(120000, [this.selectionReapply, this]);

            // register grid multiple rows selection callback
            this.$gridService.$gridApi.selection.on.rowSelectionChanged(this.$scope, this.selectedRowCallback.bind(this));
            this.$gridService.$gridApi.selection.on.rowSelectionChangedBatch(this.$scope, this.selectedRowBatchCallback.bind(this));

            this.unblock();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initDependencies(): Promise<any> {
        const self: EdiExternalCodeSetupController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericValue("task_integration_param"),
                self.getGenericValue("integration_register_type")
            ]).then(async (result: any) => {
                self.$scope.integrationList = result[0];
                self.$scope.typeList = result[1];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);
        const columnDefs: Array<IColumnDef> = gridColumns.$columnDefs;

        //situations
        const view = `<div class="text-center"><a ng-click="grid.appScope.view(row.entity)" class="text-info"tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`
        const edit = `<a ng-click="grid.appScope.edit(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`
        const actions = `<div class="text-center pull-left" style="padding-left: 10px;">${view} ${edit} </div>`;

        columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: '5%',
            cellTemplate: (actions),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });
        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }
        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            // visible
            const colIntegration: IMonacoColumnDef = { name: "INTEGRATION", displayName: "REGISTRATION.INTEGRATION", width: 150, cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.INTEGRATION)}}</div>' };
            const colType: IMonacoColumnDef = { name: "TYPE", displayName: "GENERAL.TYPE", width: 120, cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.TYPE)}}</div>' };
            const colCodeInternal: IMonacoColumnDef = { name: "CODE_INTEGRATION", displayName: "REGISTRATION.INTERNAL_CODE", width: 300, cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.CODE_INTEGRATION, "CODE_INTERNAL", null, false , true )}}</div>' };
            const colCodeExternal: IMonacoColumnDef = { name: "CODE_INTEGRATION.CODE_EXTERNAL", displayName: "REGISTRATION.EXTERNAL_CODE", width: 180, cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.CODE_INTEGRATION, null, "CODE_EXTERNAL")}}</div>' };
            const colCustomer: IMonacoColumnDef = { name: "CUSTOMER", displayName: "BASIC_DATA.CLIENT", width: 140, cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.CUSTOMER)}}</div>' };
            const colProvider: IMonacoColumnDef = { name: "PROVIDER", displayName: "BASIC_DATA.PROVIDER", width: 400, cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.PROVIDER)}}</div>' };

            for (const column of columns) {

                switch (column.toUpperCase()) {
                    case 'INTEGRATION':
                        columnDefs.push(colIntegration);
                        break;
                    case 'TYPE':
                        columnDefs.push(colType);
                        break;
                    case 'CODE_INTEGRATION':
                        columnDefs.push(colCodeInternal);
                        columnDefs.push(colCodeExternal);
                        break;
                    case 'CUSTOMER':
                        columnDefs.push(colCustomer);
                        break;
                    case 'PROVIDER':
                        columnDefs.push(colProvider);
                        break;
                };
            }

            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async register(): Promise<void> {
        this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
    }

    async view(): Promise<void> {
        this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
    }

    async edit(): Promise<void> {
        this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');
    }

    public async selectionReapply(): Promise<void> {
        const rows = this.$gridService.$gridApi.core.getVisibleRows(this.$gridService.$gridApi.grid);
        const selectedRows = this.$gridService.$gridSelectedRows;

        if (selectedRows && selectedRows.length > 0) {
            const updatedRows: IGridRow[] = [];
            for (const row of selectedRows) {
                const foundRow = rows.find(x => x.entity._id == row._id);
                if (foundRow) {
                    foundRow.setSelected(true);
                    updatedRows.push(foundRow);
                }
            }
            this.selectedRowBatchCallback(updatedRows);
        }
    }

    private async getRoutingPoints(filter: IRoutingPointListCustomFilter): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            if (filter && filter.name && filter.name.length >= 2) {
                this.formService.block();
                const request: IMonacoRequest = {
                    data: {
                        ...filter
                    },
                    route: `/routingPoint/list/custom`,
                    timeout: 30000,
                }
                const rc = await this.ProductService.post(request);
                if (rc && rc.status == 200 && rc.data && rc.data.data && rc.data.data.data) {
                    result = rc.data.data.data.map(x => { return { ID: x.ID, NAME: `${x.CODE} - ${x.NAME}(${x.TYPE.NAME})` } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getLegalPerson(filter: ILegalPersonListCustomFilter): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            if (filter && filter.search && filter.search.length >= 2) {
                this.formService.block();
                const request: IMonacoRequest = {
                    data: {
                        ...filter
                    },
                    route: `/legalPerson/list/custom/operational`,
                    timeout: 120000,
                }
                const rc = await this.ProductService.post(request);
                if (rc && rc.data && rc.data.data && rc.status == 200) {
                    result = rc.data.data.map(x => { return { ID: x.ID, NAME: x.NAME } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCorporateBranchListByName(search?: string, product?: ISelectorModel): Promise<ISelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const corporateBranchs = await this.ProductService.post({ route: `/corporateBranch/list/custom`, data: { search: search, products: product ? [product.ID] : null } });
            if (corporateBranchs && corporateBranchs.data && corporateBranchs.data.data) result = corporateBranchs.data.data.map(corporateBranch => { return { ID: corporateBranch.ID, NAME: corporateBranch.NAME, CODE: corporateBranch.CODE } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getShipOwner(filter: IProviderListCustomFilter): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            if (filter && filter.search && filter.search.length >= 2) {
                this.formService.block();
                const request: IMonacoRequest = {
                    data: {
                        ...filter
                    },
                    route: `/provider/list/custom/operational`,
                    timeout: 120000,
                }
                const rc = await this.ProductService.post(request);

                if (rc && rc.data && rc.data.data && rc.status == 200) {
                    result = rc.data.data.map(x => { return { ID: x.ID, NAME: x.SCAC } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getAgents(filter: IAgentListCustomFilter): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            if (filter && filter.search && filter.search.length >= 3) {
                this.block();
                const request: IMonacoRequest = {
                    data: {
                        ...filter
                    },
                    route: `/agent/list/custom/operational`,
                    timeout: 30000,
                }
                const rc = await this.ProductService.post(request);
                if (rc && rc.status == 200 && rc.data && rc.data.data && rc.data.data.data) {
                    result = rc.data.data.data.map(x => { return { ID: x.ID_LEGAL_PERSON, NAME: x.NAME } });
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getEquipment(search: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            if (search && search.length >= 3) {
                this.block();
                const request: IMonacoRequest = {
                    data: {
                        search
                    },
                    route: `/equipment/list/custom`,
                    timeout: 30000,
                }
                const rc = await this.ProductService.post(request);
                if (rc && rc.status == 200 && rc.data && rc.data.data) {
                    result = rc.data.data.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.ISO } });
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getGenericValue(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async getCharges(search: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();
                const request: IMonacoRequest = {
                    data: {
                        search
                    },
                    route: `/chargeName/list/custom`,
                    timeout: 120000,
                }
                const rc = await this.ProductService.post(request);
                if (rc && rc.data && rc.data.data && rc.status == 200) {
                    result = rc.data.data.map(x => { return { ID: x.ID, NAME: x.NAME } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getEvents(search: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();
                const eventList = await this.operationalService.post("/events/list/custom", { search }, 10000);
                if (eventList.data.data) {
                    result = eventList.data.data.map(x => { return { ID: x.EVENT.ID, NAME: `${x.EVENT_NUMBER} - ${x.EVENT.NAME}` } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private selectedRowCallback(row: IGridRow): void {
        try {
            this.block();

            const ediExternalCodeSetup: EdiExternalCodeSetup = row.entity;

            const i = this.selectedRows.findIndex(x => x._id === ediExternalCodeSetup._id);

            if (row.isSelected && i === -1)
                this.selectedRows.push(ediExternalCodeSetup);
            else
                this.selectedRows.splice(i, 1);

            // update scope
            this.$scope.selectedRows = this.selectedRows.slice();

            if (this.selectedRows.length > 1 && this.$scope.log) this.$scope.log.show = false;

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        try {
            if (this.$scope.model.CODE_INTEGRATION.length > 0) return true;
            this.formService.notifyError(this.formService.getTranslate("GENERAL.ALL_FIELDS_MANDATORY"))
        } catch (ex) {
            this.handleError(ex);
        }
        return false;
    }

    private selectedRowBatchCallback(rows: IGridRow[]): void {
        try {
            rows = this.$gridService.$gridApi.selection.getSelectedGridRows();
            this.block();

            this.selectedRows = rows.filter(x => x.isSelected).map(x => x.entity);
            if (this.selectedRows.length > 1 && this.$scope.log) this.$scope.log.show = false;

            this.$scope.selectedRows = this.selectedRows.slice();

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            INTEGRATION: null,
            TYPE: null,
            CODE_INTEGRATION: [],
            CUSTOMER: null,
            PROVIDER: null,
        }
    }

    private async addEdiCode() {
        try {
            if (this.$scope.operation === EOperation.VIEW) return;
            this.$scope.model.CODE_INTEGRATION.push(<CODE_INTEGRATION>{ CODE_INTERNAL: null, CODE_EXTERNAL: null })

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeCode(code: CODE_INTEGRATION) {

        try {
            if (!this.$scope.model.CODE_INTEGRATION) return;
            this.$scope.model.CODE_INTEGRATION = this.$scope.model.CODE_INTEGRATION
                .filter((item) => item.CODE_INTERNAL != code.CODE_INTERNAL || item.CODE_EXTERNAL != code.CODE_EXTERNAL);
            const thisTranslated = this.formService.getTranslate("GENERAL.GENDER.THIS", null, true);
            const codigoTranslated = this.formService.getTranslate("GENERAL.CODE", null, true);
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thisTranslated, prop: codigoTranslated })
            });

            if (!modal) return;
            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }
}

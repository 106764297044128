import angular = require("angular");
import moment = require("moment");
import { IModalInstanceService } from "angular-ui-bootstrap";
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { IRestService } from "@services/RestService";
import { IModalService, IModalOptions } from "@services/ModalService";
import { IMonacoRequestLog } from "@services/GridFormService";
import { ISessionService } from "@services/SessionService";
import { IApplicationList } from "@models/interface/product/ApplicationModel";
import { IChargeNameList, IParams } from "@models/interface/product/ChargeNameModel";
import { ICharge, ITariffFreightExchangeData } from "@models/interface/product/TariffFreightModel";
import { IEquipmentSelector } from "@models/interface/product/EquipmentModel";
import { IMonacoConfig } from "../../common/MonacoInterface";
import { SelectorModel } from "../../common/model/SelectorModel";
import { IDataFilterRequest } from "../../common/interface/IDataFilterRequest";
import { IChargeColStyle } from "../controller/TariffFreightRegisterController";
import { ILinkParameter } from "../../common/model/ModelParameter";
import { HelperService } from "@services/HelperService";

interface ITariffFreightWizardFilters {
    CHARGE_CODE_EXHIBITION: string;
    CHARGE_NAME_EXHIBITION: string;
    APPLICATION_CODE: string;
    APPLICATION_NAME: string;
    WEIGHT_RANGE: string;
    EQUIPMENT: string;
    VEHICLE_TYPE: string;
    CURRENCY_CODE: string;
    CURRENCY_NAME: string;
    GROUP: string;
    SPECIFICITY: string;
}

interface ITariffFreightWizardRequestFilters {
    ID: number;
    CHARGE_CODE_EXHIBITION: string;
    CHARGE_NAME_EXHIBITION: string;
    APPLICATION_CODE: string;
    APPLICATION_NAME: string;
    WEIGHT_RANGE: string;
    EQUIPMENT: string;
    VEHICLE_TYPE: string;
    CURRENCY_NAME: string;
    CURRENCY_CODE: string;
    GROUP: string;
    SPECIFICITY: string;
}

interface IMassAction {
    TYPE: SelectorModel;
    FIELD: SelectorModel;
    VALUE: string;
}

interface ITariffFreightContractWizard {
    _id: string;
    ID: number;
    CONCATENATED: string;
    PRODUCT: SelectorModel;
    TYPE_CARGO: SelectorModel;
    PAYMENT_NATURE: SelectorModel;
    TARIFF_FREIGHT: ITariffFreightWizard[];
    VALIDITY_START: Date;
    VALIDITY_END: Date;
    NEW_VERSION: boolean;
    NEW_VALIDITY_START: Date;
    NEW_VALIDITY_END: Date;
    NEW_VERSION_ALL: boolean;
}

interface ITariffFreightWizard {
    ID: number;
    CONCATENATED: string;
    CHARGE: ICharge[];
    ID_CHARGE_REMOVE: number[];
    SELECTED: boolean;
}

interface ITariffFreightChargesModalScope extends IFormServiceScope {
    modalID: number;
    modalConfirmID: number;
    modalOptions: IModalOptions;
    contractId: number;
    tariffId: number;
    tariffFreightWizardFilters: ITariffFreightWizardFilters;
    tariffFreightContract: ITariffFreightContractWizard;
    tariffFreightContractBeforeSave: ITariffFreightContractWizard;
    massAction: IMassAction;
    massActionTypeList: SelectorModel[];
    massActionFieldList: SelectorModel[];
    chargeApplicationList: IApplicationList[];
    specificityList: SelectorModel[];
    chargeNameList: SelectorModel[];
    weightRangeList: SelectorModel[];
    equipmentList: IEquipmentSelector[];
    currencyList: SelectorModel[];
    vehicleTypeList: SelectorModel[];
    newVersion: boolean;
    changedTariffs: string[];
    chargeColStyle: IChargeColStyle;
    applyTariffFreightCharges: () => void;
    confirmTariffFreightCharges: () => void;
    addCharge: (tariffIndex: number) => void;
    removeCharge: (tariffIndex: number, chargeIndex: number) => void;
    duplicateCharge: (tariffIndex: number, chargeIndex: number) => void;
    applyMassAction: (tariffIndex: number) => Promise<void>;
    getEmptySelectorMsg: () => string;
    getChargeNameListByName: (search: string) => Promise<void>;
    getChargeNameParamsList: (tariffIndex: number, chargeIndex: number) => Promise<void>;
    getEquipmentListByName: (search: string) => Promise<void>;
    getWeightRangeListByName: (search: string) => Promise<void>;
    getCurrencyListByName: (search: string) => Promise<void>;
    updateParam: (selected: IChargeNameList, tariffIndex: number, chargeIndex: number) => void;
    updatePaymentMin: (tariffIndex: number, chargeIndex: number) => void;
    updateReceivingMin: (tariffIndex: number, chargeIndex: number) => void;
    selectAll: () => void;
    hasAnySelected: () => boolean;
    massActionFilter: (tariffFreight: ITariffFreightWizard) => boolean;
    changeNewVersion: () => void;
    goToTariffFreight: (tariff: ITariffFreightWizard) => void;
    getChargeApplicationComplement: (tariffIndex: number, chargeIndex: number) => string;
}

export class TariffFreightChargesModalController extends FormService2 {
    public static $inject: string[] = ['$injector', '$scope'];
    private scope: ITariffFreightChargesModalScope;
    private $q: ng.IQService;
    private restService: IRestService;
    private timeout: ng.ITimeoutService
    private modalService: IModalService;
    private config: IMonacoConfig;
    private baseUrl: string;
    private contractId: number;
    private tariffId: number;
    private modalID: number;
    private modalConfirmID: number;
    private sessionService: ISessionService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: ITariffFreightChargesModalScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.$q = $injector.get('$q');
        this.restService = $injector.get('RestService');
        this.timeout = $injector.get('$timeout');
        this.modalService = $injector.get('ModalService');
        this.config = $injector.get('config');
        this.contractId = this.scope.contractId;
        this.tariffId = this.scope.tariffId;
        this.modalID = this.scope.modalID;
        this.modalConfirmID = this.scope.modalConfirmID;
        this.sessionService = $injector.get('SessionService');
        this.helperService = $injector.get('HelperService');
    }

    async $onInit() {
        this.baseUrl = this.getUrlProduct();
        this.block();

        this.initModel();
        this.init('tariffFreightChargesModalForm', null, null)
        this.registerScopeFunctions();
        await this.initDependencies();

        this.scope.tariffFreightContract = await this.getTariffFreightWizardList();
        this.scope.tariffFreightContractBeforeSave = angular.copy(this.scope.tariffFreightContract);
        this.unblock();

        angular.element(document).ready(() => {
            this.addFocusEventOnParam();
        });
    }

    private initDependencies(): Promise<any> {
        const self: TariffFreightChargesModalController = this;

        this.scope.massActionTypeList = [{ ID: '1', NAME: this.getTranslate("GENERAL.INCREASE") }, { ID: '2', NAME: this.getTranslate("GENERAL.REDUCE") }, { ID: '3', NAME: this.getTranslate("GENERAL.FIXED_VALUE") }];
        this.scope.massActionFieldList = [{ ID: 'PAYMENT_MIN', NAME: this.getTranslate("PRODUCT.MIN_PAYMENT") }, { ID: 'PAYMENT_UNITARY', NAME: this.getTranslate("PRODUCT.UNIT_PAYMENT") }, { ID: 'RECEIVING_MIN', NAME: this.getTranslate("PRODUCT.MIN_RECEIPT") }, { ID: 'RECEIVING_UNITARY', NAME: this.getTranslate("PRODUCT.UNIT_RECEIPT") }];

        this.initEvents();
        this.setChargeColStyle();

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getSpecificityList(),
                self.getVehicleTypeList()
            ]).then(async (result: any) => {
                self.scope.specificityList = result[0];
                self.scope.vehicleTypeList = result[1];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private initModel(): void {
        this.scope.modalID = this.modalID;
        this.scope.contractId = this.contractId;
        this.scope.tariffId = this.tariffId;
        this.scope.tariffFreightWizardFilters = {
            CHARGE_CODE_EXHIBITION: null,
            CHARGE_NAME_EXHIBITION: null,
            APPLICATION_CODE: null,
            APPLICATION_NAME: null,
            WEIGHT_RANGE: null,
            EQUIPMENT: null,
            VEHICLE_TYPE: null,
            CURRENCY_CODE: null,
            CURRENCY_NAME: null,
            GROUP: null,
            SPECIFICITY: null
        };
        this.scope.massAction = {
            TYPE: null,
            FIELD: null,
            VALUE: null
        }
    }

    private registerScopeFunctions(): void {
        this.scope.getEmptySelectorMsg = () => {
            return this.getEmptySelectorMsg();
        }
        this.scope.applyTariffFreightCharges = () => {
            this.applyTariffFreightCharges();
        }
        this.scope.confirmTariffFreightCharges = () => {
            this.confirmTariffFreightCharges();
        }
        this.scope.addCharge = (tariffIndex: number): void => {
            this.addCharge(tariffIndex);
        }
        this.scope.removeCharge = (tariffIndex: number, chargeIndex: number): void => {
            this.removeCharge(tariffIndex, chargeIndex);
        }
        this.scope.duplicateCharge = (tariffIndex: number, chargeIndex: number): void => {
            this.duplicateCharge(tariffIndex, chargeIndex);
        }
        this.scope.applyMassAction = async (): Promise<void> => {
            await this.applyMassAction();
        }
        this.scope.getChargeNameListByName = async (search: string): Promise<void> => {
            return await this.getChargeNameListByName(search);
        }
        this.scope.getChargeNameParamsList = async (tariffIndex: number, chargeIndex: number): Promise<void> => {
            return await this.getChargeNameParamsList(tariffIndex, chargeIndex);
        }
        this.scope.getEquipmentListByName = async (search: string): Promise<void> => {
            return await this.getEquipmentListByName(search);
        }
        this.scope.getWeightRangeListByName = async (search: string): Promise<void> => {
            return await this.getWeightRangeListByName(search);
        }
        this.scope.getCurrencyListByName = async (search: string): Promise<void> => {
            return await this.getCurrencyListByName(search);
        }
        this.scope.updateParam = (selected: IChargeNameList, tariffIndex: number, chargeIndex: number): void => {
            this.updateParam(selected, tariffIndex, chargeIndex);
        }
        this.scope.updatePaymentMin = (tariffIndex: number, chargeIndex: number): void => {
            this.updatePaymentMin(tariffIndex, chargeIndex);
        }
        this.scope.updateReceivingMin = (tariffIndex: number, chargeIndex: number): void => {
            this.updateReceivingMin(tariffIndex, chargeIndex);
        }
        this.scope.selectAll = (): void => {
            this.selectAll();
        }
        this.scope.hasAnySelected = (): boolean => {
            return this.scope.tariffFreightContract.TARIFF_FREIGHT && this.scope.tariffFreightContract.TARIFF_FREIGHT.some(tariff => tariff.SELECTED);
        }
        this.scope.massActionFilter = (tariffFreight: ITariffFreightWizard): boolean => {
            return tariffFreight && tariffFreight.CHARGE && tariffFreight.CHARGE.length > 0;
        }
        this.scope.changeNewVersion = (): void => {
            this.changeNewVersion();
        }
        this.scope.goToTariffFreight = (tariff: ITariffFreightWizard) => {
            if (!tariff) return;
            const endpoint = `${this.config.productUrl}/product/tariffFreight/getCacheById/${this.scope.tariffFreightContract.ID}`;
            this.sessionService.openTabByValidity(endpoint, "app.product.tariffFreight", <ILinkParameter>{ ID: "" }, <ITariffFreightExchangeData>{ OPERATION: "edit", ID: this.scope.tariffFreightContract.ID.toString(), ID_TARIFF: tariff.ID });
        }
        this.scope.getChargeApplicationComplement = (tariffIndex: number, chargeIndex: number): string => {
            return this.getChargeApplicationComplement(tariffIndex, chargeIndex);
        }
    }

    private setChargeColStyle(): void {
        const chargeColStyle: IChargeColStyle = { charge: null, param: null, flat: null, weightEquipment: null, currency: null, paymentUnitary: null, paymentMin: null, receivingUnitary: null, receivingMin: null, group: null, specificity: null };
        const winWidth = angular.element(window).width();
        if (winWidth >= 1900) {
            chargeColStyle.charge = { "width": "20%" };
            chargeColStyle.param = { "width": "12%" };
            chargeColStyle.weightEquipment = { "width": "12%" };
            chargeColStyle.currency = { "width": "6%" };
            chargeColStyle.paymentUnitary = { "width": "7%" };
            chargeColStyle.paymentMin = { "width": "7%" };
            chargeColStyle.receivingUnitary = { "width": "7%" };
            chargeColStyle.receivingMin = { "width": "7%" };
            chargeColStyle.group = { "width": "10%" };
            chargeColStyle.specificity = { "width": "12%" };
        } else if (winWidth >= 1600) {
            chargeColStyle.charge = { "width": "14%" };
            chargeColStyle.param = { "width": "10%" };
            chargeColStyle.weightEquipment = { "width": "12%" };
            chargeColStyle.currency = { "width": "8%" };
            chargeColStyle.paymentUnitary = { "width": "9%" };
            chargeColStyle.paymentMin = { "width": "9%" };
            chargeColStyle.receivingUnitary = { "width": "9%" };
            chargeColStyle.receivingMin = { "width": "9%" };
            chargeColStyle.group = { "width": "10%" };
            chargeColStyle.specificity = { "width": "10%" };
        } else if (winWidth >= 1280) {
            chargeColStyle.charge = { "width": "12%" };
            chargeColStyle.param = { "width": "11%" };
            chargeColStyle.weightEquipment = { "width": "12%" };
            chargeColStyle.currency = { "width": "9%" };
            chargeColStyle.paymentUnitary = { "width": "9%" };
            chargeColStyle.paymentMin = { "width": "9%" };
            chargeColStyle.receivingUnitary = { "width": "9%" };
            chargeColStyle.receivingMin = { "width": "9%" };
            chargeColStyle.group = { "width": "10%" };
            chargeColStyle.specificity = { "width": "10%" };
        }
        this.scope.chargeColStyle = chargeColStyle;
    }

    private initEvents(): void {
        const self: TariffFreightChargesModalController = this;

        angular.element(window).on('resize', () => {
            this.setChargeColStyle();
        });

        angular.element("#tariffFreightChargesModalForm").on("keypress", (event) => {
            var keyPressed = event.keyCode || event.which;
            if (keyPressed === 13) {
                event.preventDefault();
                return false;
            }
        });

        const inputFilters = document.querySelectorAll('input[name="filter"]');
        if (inputFilters && inputFilters.length > 0) {
            inputFilters.forEach((inputElement: HTMLInputElement) => {
                inputElement.addEventListener('keyup', async function (event) {
                    var key = event.which || event.keyCode;
                    if (key == 13) {
                        self.scope.tariffFreightContract = await self.getTariffFreightWizardList();
                        self.scope.tariffFreightContractBeforeSave = angular.copy(self.scope.tariffFreightContract);

                        if (self.scope.tariffFreightWizardFilters.CHARGE_CODE_EXHIBITION || self.scope.tariffFreightWizardFilters.CHARGE_NAME_EXHIBITION ||
                            self.scope.tariffFreightWizardFilters.APPLICATION_CODE || self.scope.tariffFreightWizardFilters.APPLICATION_NAME ||
                            self.scope.tariffFreightWizardFilters.WEIGHT_RANGE || self.scope.tariffFreightWizardFilters.EQUIPMENT || self.scope.tariffFreightWizardFilters.VEHICLE_TYPE ||
                            self.scope.tariffFreightWizardFilters.CURRENCY_CODE || self.scope.tariffFreightWizardFilters.CURRENCY_NAME || self.scope.tariffFreightWizardFilters.GROUP || self.scope.tariffFreightWizardFilters.SPECIFICITY) {
                            self.timeout(() => {
                                if (self.scope.tariffFreightContract && self.scope.tariffFreightContract.TARIFF_FREIGHT) {
                                    self.scope.tariffFreightContract.TARIFF_FREIGHT.forEach((tariff, index) => {
                                        if (tariff.CHARGE && tariff.CHARGE.length > 0) {
                                            $("#collapseTariffFreight" + index)["collapse"]("show");
                                        } else {
                                            $("#collapseTariffFreight" + index)["collapse"]("hide");
                                        }
                                    });
                                }
                                self.addFocusEventOnParam();
                            });
                        }
                    }
                });
            });
        }
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlDataManager = this.config.productUrl + baseRoute;
        return urlDataManager;
    }

    private async getSpecificityList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/specificity`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getVehicleTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/vehicle_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTariffFreightWizardList(): Promise<ITariffFreightContractWizard> {
        let result: ITariffFreightContractWizard;
        this.block();
        try {
            const filters: ITariffFreightWizardRequestFilters = {
                ID: this.scope.contractId,
                CHARGE_CODE_EXHIBITION: this.scope.tariffFreightWizardFilters.CHARGE_CODE_EXHIBITION,
                CHARGE_NAME_EXHIBITION: this.scope.tariffFreightWizardFilters.CHARGE_NAME_EXHIBITION,
                APPLICATION_CODE: this.scope.tariffFreightWizardFilters.APPLICATION_CODE,
                APPLICATION_NAME: this.scope.tariffFreightWizardFilters.APPLICATION_NAME,
                WEIGHT_RANGE: this.scope.tariffFreightWizardFilters.WEIGHT_RANGE,
                EQUIPMENT: this.scope.tariffFreightWizardFilters.EQUIPMENT,
                VEHICLE_TYPE: this.scope.tariffFreightWizardFilters.VEHICLE_TYPE,
                CURRENCY_CODE: this.scope.tariffFreightWizardFilters.CURRENCY_CODE,
                CURRENCY_NAME: this.scope.tariffFreightWizardFilters.CURRENCY_NAME,
                GROUP: this.scope.tariffFreightWizardFilters.GROUP,
                SPECIFICITY: this.scope.tariffFreightWizardFilters.SPECIFICITY
            };
            const requestParam: IDataFilterRequest = { datafilter: { limits: null, filter: filters } };
            const tariffFreightList = await this.restService.newObjectPromise(`${this.baseUrl}/tariffFreight/wizard/list`, requestParam, 30000, false);

            if (tariffFreightList) result = tariffFreightList;
        } catch (ex) {
            this.scope.modalOptions.ok(false);
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getChargeNameListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 2) {
                this.block();

                let types = ['1'];
                const products = this.scope.tariffFreightContract.PRODUCT ? [this.scope.tariffFreightContract.PRODUCT.ID] : [];
                const paramTypeCargo = this.scope.tariffFreightContract.TYPE_CARGO ? [this.scope.tariffFreightContract.TYPE_CARGO.ID] : [];
                const chargeNames = await this.restService.newObjectPromise(`${this.getUrlProduct()}/chargeName/list/custom/exhibition`, { search, types, products, paramTypeCargo }, 30000, false);
                result = chargeNames ? chargeNames.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PARAMS: x.PARAMS } }) : [];
            }
        } catch (ex) {
            this.scope.modalOptions.ok(false);
            this.handleError(ex);
        } finally {
            this.unblock();
            this.scope.chargeNameList = result;
        }
    }

    private async getChargeNameParamsList(tariffIndex: number, chargeIndex: number): Promise<void> {
        let result: IApplicationList[] = [];
        try {
            if (!tariffIndex && tariffIndex != 0) throw Error('tariffIndex is null');
            if (!chargeIndex && chargeIndex != 0) throw Error('chargeIndex is null');

            if (this.scope.tariffFreightContract.TARIFF_FREIGHT && this.scope.tariffFreightContract.TARIFF_FREIGHT.length > 0 && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex] && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE.length > 0 && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex]) {
                if (!this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].CHARGE_NAME_EXHIBITION) {
                    return this.notifyError(this.getTranslate("PRODUCT.SELECT_CHARGENAME_FIRST"));
                }
                this.block();
                const products = this.scope.tariffFreightContract.PRODUCT ? [this.scope.tariffFreightContract.PRODUCT.ID] : null;
                const paramsTypeCargo = this.scope.tariffFreightContract.TYPE_CARGO ? [this.scope.tariffFreightContract.TYPE_CARGO.ID] : null;
                const params = await this.restService.newObjectPromise(`${this.getUrlProduct()}/application/list/custom`, { products: products, typeCargos: paramsTypeCargo, percent: false }, 30000, false);
                result = params ? params.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PERCENT: x.PERCENT, APPLICATION_COMPLEMENT: x.APPLICATION_COMPLEMENT, INTERNAL_SEQUENCE: x.INTERNAL_SEQUENCE, FREE_TYPING_AMOUNT_CHARGE: x.FREE_TYPING_AMOUNT_CHARGE, CT_WITHOUT_DOC: x.CT_WITHOUT_DOC, NAME_INTL: x.NAME_INTL } }) : [];
            }
        } catch (ex) {
            this.scope.modalOptions.ok(false);
            this.handleError(ex);
        } finally {
            this.unblock();
            this.scope.chargeApplicationList = result;
        }
    }

    private async getEquipmentListByName(search: string): Promise<void> {
        let result: IEquipmentSelector[] = [];
        try {
            if (search && search.length >= 2) {
                if (!this.scope.tariffFreightContract.PRODUCT) throw Error(this.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                this.block();
                const products = this.scope.tariffFreightContract && this.scope.tariffFreightContract.PRODUCT ? [this.scope.tariffFreightContract.PRODUCT.ID] : [];
                const equipmentList = await this.restService.newObjectPromise(`${this.getUrlProduct()}/equipment/list/custom`, { search, products }, 30000, false);
                result = equipmentList ? equipmentList.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, TEU: x.TEU, FEET: x.FEET } }) : [];
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            this.scope.equipmentList = result;
        }
    }

    private async getWeightRangeListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            this.block();
            const products = this.scope.tariffFreightContract.PRODUCT ? [this.scope.tariffFreightContract.PRODUCT.ID] : [];
            const weightRangeList = await this.restService.newObjectPromise(`${this.getUrlProduct()}/weightRange/list/custom`, { search, products }, 30000, false);
            result = weightRangeList ? weightRangeList.map(x => { return { ID: x.ID, NAME: x.NAME } }) : [];
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            this.scope.weightRangeList = result;
        }
    }

    private async getCurrencyListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 2) {
                this.block();

                const currencies = await this.restService.newObjectPromise(`${this.getUrlProduct()}/currency/list/custom`, { name: search }, 30000, false);
                result = (currencies) ? currencies.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } }) : [];
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            this.scope.currencyList = result;
        }
    }

    private addFocusEventOnParam(): void {
        try {
            if (this.scope.tariffFreightContract.TARIFF_FREIGHT && this.scope.tariffFreightContract.TARIFF_FREIGHT.length > 0) {
                for (let i = 0; i < this.scope.tariffFreightContract.TARIFF_FREIGHT.length; i++) {
                    if (this.scope.tariffFreightContract.TARIFF_FREIGHT[i].CHARGE && this.scope.tariffFreightContract.TARIFF_FREIGHT[i].CHARGE.length > 0) {
                        for (let j = 0; j < this.scope.tariffFreightContract.TARIFF_FREIGHT[i].CHARGE.length; j++) {
                            $("#paramSelector-" + i + '-' + j).unbind('focus').on('focus', async (event) => {
                                event.stopPropagation();
                                await this.getChargeNameParamsList(i, j);
                            });
                        }
                    }
                }
            }
        } catch (ex) {
            this.scope.modalOptions.ok(false);
            this.handleLoadError(ex);
        }
    }

    private async applyTariffFreightCharges(): Promise<void> {
        try {
            if (this.checkPendingFields('tariffFreightChargesModalForm')) {

                const request: IMonacoRequestLog = {
                    route: `${this.baseUrl}/tariffFreight/wizard/save`,
                    timeout: 15000,
                    data: {
                        data: this.scope.tariffFreightContract,
                        oldData: this.scope.tariffFreightContractBeforeSave
                    }
                };

                const changedTariffs = this.getChangedTariffs(this.scope.tariffFreightContract.TARIFF_FREIGHT, this.scope.tariffFreightContractBeforeSave.TARIFF_FREIGHT);
                this.scope.changedTariffs = changedTariffs;

                if (changedTariffs && changedTariffs.length > 0) {

                    this.modalConfirmID = this.modalService.newModal();
                    const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                        {
                            modalID: this.modalConfirmID,
                            scope: this.scope,
                            template: require('../view/modals/tariffFreightChargeConfirmModal.html'),
                            size: 'lg'
                        },
                        {
                            actionButtonText: "GENERAL.YES",
                            closeButtonText: "GENERAL.NO",
                            headerText: "GENERAL.CHANGE_TARIFF_CONFIRM"
                        }
                    );

                    const approved = await modalInstance.result.then(function (result) {
                        return result.$value;
                    }, function (result) {
                        return result.$value;
                    });

                    if (approved) {
                        const rc = await this.restService.newObject(request.route, request.data, request.timeout, false);
                        if (rc.status === 200) {
                            this.notifySuccess(this.getTranslate("GENERAL.CHARGES_UPDATED"));
                            this.modalService.closeModal(this.modalID);
                            this.modalID = 0;
                        }
                        this.unblock();
                    }
                } else {
                    this.notifyInfo(this.getTranslate("GENERAL.CHARGES_NO_CHANGE"));
                    this.modalService.closeModal(this.modalID);
                    this.modalID = 0;
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private confirmTariffFreightCharges(): void {
        if (!this.scope.tariffFreightContract.NEW_VERSION || this.scope.tariffFreightContract.NEW_VERSION && this.scope.tariffFreightContract.NEW_VALIDITY_START && this.scope.tariffFreightContract.NEW_VALIDITY_END) {
            const startDate = moment(new Date(this.scope.tariffFreightContract.NEW_VALIDITY_START));
            const endDate = moment(new Date(this.scope.tariffFreightContract.NEW_VALIDITY_END));
            if (startDate.isAfter(endDate)) {
                this.notifyError(this.getTranslate("GENERAL.END_START_DATE"));
                return;
            }
            this.modalService.closeModal(this.modalConfirmID);
        } else {
            this.notifyError(this.getTranslate("GENERAL.NEW_VERSION"));
        }
    }

    private getChangedTariffs(tariffList: ITariffFreightWizard[], tariffListBefore: ITariffFreightWizard[]): string[] {
        let changedTariffs: string[] = [];
        if (tariffList && tariffList.length > 0 && tariffListBefore && tariffListBefore.length > 0) {
            for (let index = 0; index < tariffListBefore.length; index++) {
                const oldTariff = tariffListBefore[index];
                const newTariff = tariffList[index];
                if (!angular.equals(oldTariff, newTariff)) {
                    changedTariffs.push(oldTariff.CONCATENATED);
                }
            }
        }
        return changedTariffs;
    }

    private addCharge(tariffIndex: number): void {
        try {
            if (!tariffIndex && tariffIndex != 0) throw Error('tariffIndex is null');
            const param: ICharge = {
                _id: null,
                ID: null,
                ID_TARIFF_FREIGHT: null,
                ID_CHARGE_NAME_EXHIBITION: null,
                CHARGE_NAME_EXHIBITION: null,
                APPLICATION: null,
                FLAT: false,
                EQUIPMENT: null,
                WEIGHT_RANGE: null,
                VEHICLE_TYPE: null,
                ID_CURRENCY: null,
                CURRENCY: null,
                PAYMENT_MIN: null,
                PAYMENT_UNITARY: null,
                RECEIVING_MIN: null,
                RECEIVING_UNITARY: null,
                NO_CHARGE: false,
                SPECIFICITY: null,
            }

            if (this.scope.tariffFreightContract.TARIFF_FREIGHT && this.scope.tariffFreightContract.TARIFF_FREIGHT.length > 0) {
                if (!this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE) this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE = [];
                this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE.push(angular.copy(param));
                this.timeout(() => {
                    this.addFocusEventOnParam();
                });
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async removeCharge(tariffIndex: number, chargeIndex: number): Promise<void> {
        try {
            if (!tariffIndex && tariffIndex != 0) throw Error('tariffIndex is null');
            if (!chargeIndex && chargeIndex !== 0) throw Error('chargeIndex is null');

            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: this.getTranslate("PRODUCT.REMOVE_CHARGE_QUESTION")
            });

            if (!modal) return;

            this.block();
            if (this.scope.tariffFreightContract.TARIFF_FREIGHT && this.scope.tariffFreightContract.TARIFF_FREIGHT.length > 0 && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex] && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE.length > 0 && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex]) {
                if (!this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].ID_CHARGE_REMOVE) this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].ID_CHARGE_REMOVE = [];
                this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].ID_CHARGE_REMOVE.push(this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].ID);
                this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE.splice(chargeIndex, 1);
            }
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async duplicateCharge(tariffIndex: number, chargeIndex: number): Promise<void> {
        try {
            if (!tariffIndex && tariffIndex != 0) throw Error('tariffIndex is null');
            if (!chargeIndex && chargeIndex != 0) throw Error('chargeIndex is null');

            if (this.scope.tariffFreightContract.TARIFF_FREIGHT && this.scope.tariffFreightContract.TARIFF_FREIGHT.length > 0 && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex] && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE.length > 0 && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex]) {
                this.block();
                const copy = angular.copy(this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex]);
                copy._id = null;
                this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE.push(copy);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private selectAll(): void {
        const checkboxAll = document.getElementsByName('selectAll')[0] as HTMLInputElement;

        if (this.scope.tariffFreightContract.TARIFF_FREIGHT && this.scope.tariffFreightContract.TARIFF_FREIGHT.length > 0) {
            for (let i = 0; i < this.scope.tariffFreightContract.TARIFF_FREIGHT.length; i++) {
                this.scope.tariffFreightContract.TARIFF_FREIGHT[i].SELECTED = checkboxAll.checked;
            }
        }
    }

    private changeNewVersion(): void {
        if (this.scope.tariffFreightContract.NEW_VERSION) {
            if (this.scope.tariffFreightContract.VALIDITY_END) {
                const newStartDate = moment(new Date(this.scope.tariffFreightContract.VALIDITY_END)).add(1, 'days');
                this.scope.tariffFreightContract.NEW_VALIDITY_START = newStartDate.toDate();
            }
        } else {
            this.scope.tariffFreightContract.NEW_VALIDITY_START = null;
            this.scope.tariffFreightContract.NEW_VALIDITY_END = null;
        }
    }

    private getChargeApplicationComplement(tariffIndex: number, chargeIndex: number): string {
        try {
            if (!tariffIndex && tariffIndex != 0) throw Error('tariffIndex is null');
            if (!chargeIndex && chargeIndex != 0) throw Error('chargeIndex is null');

            if (
                this.scope.tariffFreightContract.TARIFF_FREIGHT &&
                this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex] &&
                this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE &&
                this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex] &&
                this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].APPLICATION &&
                this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].APPLICATION.APPLICATION_COMPLEMENT &&
                this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].APPLICATION.APPLICATION_COMPLEMENT.ID
            ) {
                return this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].APPLICATION.APPLICATION_COMPLEMENT.ID;
            }
            return null;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async applyMassAction(): Promise<void> {
        try {
            if (this.scope.tariffFreightContract.TARIFF_FREIGHT && this.scope.tariffFreightContract.TARIFF_FREIGHT.length > 0) {
                const modalID = this.modalService.newModal();

                const tariffChargesToApply = document.getElementsByClassName("tariff-charges collapse");
                for (let i = 0; i < tariffChargesToApply.length; i++) {
                    const id = tariffChargesToApply[i].id.split("collapseTariffFreight")[1];
                    if (id && this.scope.tariffFreightContract.TARIFF_FREIGHT[id]) {
                        if (tariffChargesToApply[i].classList.contains('in')) this.scope.tariffFreightContract.TARIFF_FREIGHT[id].SELECTED = true;
                        else this.scope.tariffFreightContract.TARIFF_FREIGHT[id].SELECTED = false;
                    }
                }

                const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                    {
                        modalID: modalID,
                        scope: this.scope,
                        template: require('../view/modals/tariffFreightChargesMassActionModal.html'),
                        size: 'lg modal-overflow'
                    },
                    {
                        actionButtonText: "REGISTRATION.APPLY",
                        closeButtonText: "GENERAL.CANCEL",
                        headerText: "GENERAL.ROUTES_CHANGE"
                    }
                );

                const approved = await modalInstance.result.then(function (result) {
                    return result.$value;
                }, function (result) {
                    return result.$value;
                });

                if (approved) {
                    const type = this.scope.massAction.TYPE;
                    const field = this.scope.massAction.FIELD;
                    const value = this.scope.massAction.VALUE;
                    const operator = type.ID == "1" ? "+" : type.ID == "2" ? "-" : null;
                    for (let tariffIndex = 0; tariffIndex < this.scope.tariffFreightContract.TARIFF_FREIGHT.length; tariffIndex++) {
                        const tariffFreight = this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex];
                        if (tariffFreight.SELECTED && tariffFreight.CHARGE && tariffFreight.CHARGE.length > 0) {
                            $("#collapseTariffFreight" + tariffIndex)["collapse"]("show");
                            for (let i = 0; i < tariffFreight.CHARGE.length; i++) {
                                let fieldValue = tariffFreight.CHARGE[i][field.ID];
                                if (fieldValue) {
                                    if (operator) fieldValue = eval(`${fieldValue} ${operator} ${value}`);
                                    else fieldValue = value;
                                }
                                tariffFreight.CHARGE[i][field.ID] = fieldValue;
                            }
                        }
                    }
                    this.notifySuccess(this.getTranslate("PRODUCT.MESSAGE.TARIFF_RATES_VALUE_APPLIED_TO_SELECTED_ROUTES", { value: value }));
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private updateParam(selected: IChargeNameList, tariffIndex: number, chargeIndex: number): void {
        let param: IParams = null;
        try {
            if (!selected) throw Error('selected is null');
            if (!tariffIndex && tariffIndex != 0) throw Error('tariffIndex is null');
            if (!chargeIndex && chargeIndex != 0) throw Error('chargeIndex is null');

            if (selected.PARAMS && selected.PARAMS.length > 0) param = selected.PARAMS.find(param => (param.TYPE_CARGO.find(typeCargo => typeCargo.ID === this.scope.tariffFreightContract.TYPE_CARGO.ID) && ((this.scope.tariffFreightContract.PAYMENT_NATURE.ID === '1' && (param.PAYMENT && !param.PAYMENT.PERCENT)) || (this.scope.tariffFreightContract.PAYMENT_NATURE.ID === '2' && (param.RECEIVING && !param.RECEIVING.PERCENT)))));
            if (this.scope.tariffFreightContract.TARIFF_FREIGHT && this.scope.tariffFreightContract.TARIFF_FREIGHT.length > 0 && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex] && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE.length > 0 && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex]) {
                if (param && this.scope.tariffFreightContract.PAYMENT_NATURE) {
                    this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].APPLICATION = this.scope.tariffFreightContract.PAYMENT_NATURE.ID == "2" ? param.RECEIVING : param.PAYMENT;
                }
                if (this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].APPLICATION && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].APPLICATION.APPLICATION_COMPLEMENT && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].APPLICATION.APPLICATION_COMPLEMENT.ID == '1') {
                    this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].EQUIPMENT = null;
                    this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].WEIGHT_RANGE = null;
                }
            }
            this.timeout(() => { this.scope.selectorValidity(`param${tariffIndex}${chargeIndex}`); }, 100);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private updatePaymentMin(tariffIndex: number, chargeIndex: number): void {
        try {
            if (!tariffIndex && tariffIndex != 0) throw Error('tariffIndex is null');
            if (!chargeIndex && chargeIndex != 0) throw Error('chargeIndex is null');

            if (this.scope.tariffFreightContract.TARIFF_FREIGHT && this.scope.tariffFreightContract.TARIFF_FREIGHT.length > 0 && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex] && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE.length > 0 && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex]) {
                this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].PAYMENT_MIN = this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].PAYMENT_UNITARY;
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private updateReceivingMin(tariffIndex: number, chargeIndex: number): void {
        try {
            if (!tariffIndex && tariffIndex != 0) throw Error('tariffIndex is null');
            if (!chargeIndex && chargeIndex != 0) throw Error('chargeIndex is null');

            if (this.scope.tariffFreightContract.TARIFF_FREIGHT && this.scope.tariffFreightContract.TARIFF_FREIGHT.length > 0 && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex] && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE.length > 0 && this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex]) {
                this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].RECEIVING_MIN = this.scope.tariffFreightContract.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].RECEIVING_UNITARY;
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }
}

import * as config from '../../bootstrap/Config';
import { HandleError } from '../../common/util/HandleError';

export interface IFileReturn {
    operation: string;
    identity: string;
    idReport: string;
    fileName: string;
    fileType: string;
}

export interface IBufferReturn {
    type: string;
    data: Array<number>;
}

export interface IDownloadParamsReturn {
    file: IFileReturn;
    buffer: IBufferReturn;
}

export interface IFileDownload {
    url: string;
    name: string;
    type: string;
    blob: Blob;
}

export interface IDownloadService {
    previewFileFromGoogleStorage: (hash: string) => Promise<IFileDownload>;
}

export class DownloadService {
    static $inject: string[] = ['$injector', '$http'];
    private $injector: ng.Injectable<any>;
    private $http: ng.IHttpService;
    private timeout: number;

    public constructor($injector: ng.Injectable<any>) {
        this.$injector = $injector;
        this.$http = this.$injector.get('$http');
        this.timeout = 300000; // 5 minutes
    }

    private getUrlDownload(): string {
        const baseRoute = '/data-builder/download/';
        const urlDataManager = config.default.reportDataBuilderUrl + baseRoute;
        return urlDataManager;
    }

    private getContentType(fileType: string): string {
        switch (fileType.toLowerCase()) {
            case 'arc':
                return 'application/octet-stream';
            case 'bin':
                return 'application/octet-stream';
            case 'avi':
                return 'video/x-msvideo';
            case 'azw':
                return 'application/vnd.amazon.ebook';
            case 'gif':
                return 'image/gif';
            case 'htm':
                return 'text/html';
            case 'html':
                return 'text/html';
            case 'ico':
                return 'image/x-icon';
            case 'ics':
                return 'text/calendar';
            case 'js':
                return 'application/javascript';
            case 'json':
                return 'application/json';
            case 'mpeg':
                return 'video/mpeg';
            case 'jpeg':
                return 'image/jpeg';
            case 'jpg':
                return 'image/jpg';
            case 'png':
                return 'image/png';
            case 'pdf':
                return 'application/pdf';
            case 'rar':
                return 'application/x-rar-compressed';
            case 'zip':
                return 'application/zip';
            case '7z':
                return 'application/x-7z-compressed';
            case 'ppt':
                return 'application/vnd.ms-powerpoint';
            case 'csv':
                return 'text/csv';
            case 'doc':
                return 'application/msword';
            case 'xls':
                return 'application/vnd.ms-excel';
            case 'xlsx':
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            case 'vsd':
                return 'application/vnd.visio';
            case 'ts':
                return 'application/typescript';
            case 'rtf':
                return 'application/rtf';
            default:
                return 'text/plain';
        }
    }

    public async previewFileFromGoogleStorage(hash: string): Promise<IFileDownload> {
        try {
            if (!hash) return null;

            const operation = await this.$http({
                method: 'GET',
                url: `${this.getUrlDownload()}${hash}`,
                cache: false,
                timeout: this.timeout,
                responseType: "arraybuffer"
            });
            
            const response: angular.IHttpResponse<any> = operation;
            const parsedReponse = JSON.parse(new TextDecoder().decode(response.data));
            const resultFile = <IDownloadParamsReturn>parsedReponse.data;

            const file = resultFile.file;
            const buffer = resultFile.buffer.data;

            const fileName = file.fileName;
            const fileBuffer = new Uint8Array(buffer);
            const fileType = this.getContentType(file.fileType);
            const fileBlob = new Blob([fileBuffer], { type: fileType });
            const fileURL = window.URL.createObjectURL(fileBlob);
            const result = <IFileDownload> { url: fileURL, name: fileName, type: fileType, blob: fileBlob };

            return result;

        } catch (ex) {
            HandleError.exception(ex);
        }
    }
}

import * as angular from "angular";
import { ISessionService } from '@services/SessionService';
import { INotificationService } from '@services/NotificationService';
import { IUploadItem, IUploader } from '@models/interface/common/IMonacoUpload';
import { HandleError } from '../../common/util/HandleError';

interface IFilter {
    name: string;
    fn: Function;
}

interface IMonacoUploadScope extends ng.IScope {
    model: IUploadItem[];
    uploader: IUploader;
    removeDisabled: boolean;
    viewDisabled: boolean;
    onlyView: boolean;
    multiple: boolean;
    titleAttachments: string;
    titleQueue: string;
    fnRemove: string;
    view: (url: string) => void;
    remove: (index: number, item: IUploadItem) => Promise<void>;
    selectFile: () => void;
}

monacoUpload.$inject = ['SessionService', 'NotificationService', '$filter']
export function monacoUpload(SessionService: ISessionService, NotificationService: INotificationService, $filter: angular.IFilterService): ng.IDirective {
    const ddo: ng.IDirective = {
        restrict: 'E',
        transclude: false,
        scope: {
            model: '=',
            uploader: '=',
            disabled: '=',
            removeDisabled: '=',
            viewDisabled: '=',
            onlyView: '=',
            multiple: '=',
            fnRemove: '@',
        },
        template: require("../view/template/monacoUpload.html"),
        link: ($scope: IMonacoUploadScope, element: JQLite, attributes: ng.IAttributes) => {

            //TODO - Criar filtro de tamanho padrão para quando filtro não for informado na instância.
            //TODO - Criar lista com names padrões para alguns erros mais frequentes referente ao componente.

            $scope.titleAttachments = "REGISTRATION.FILE_ATTACHMENT";
            $scope.titleQueue = "GENERAL.ATTACHMENT_QUEUE";

            if (angular.isDefined(attributes.titleAttachments)) $scope.titleAttachments = attributes.titleAttachments;
            if (angular.isDefined(attributes.titleQueue)) $scope.titleQueue = attributes.titleQueue;
            if (angular.isDefined(attributes.filterSize)) {
                const fileSize = parseInt(attributes.filterSize);
                if (isNaN(fileSize)) HandleError.exception('Parameter for incorrect filter size, use an integer representing the maximum amount allowed in Megabytes.');
                else $scope.uploader.filters = buildFilter("sizeFilter", (item) => { return item.size <= ((fileSize * 1000) * 1000) }, $scope.uploader.filters);
            }

            const token = SessionService.getToken();
            if (token) $scope.uploader.headers = { Authorization: `Bearer ${token}` };

            if ($scope.model == null) $scope.model = [];

            $scope.view = (url) => {
                if (!$scope.viewDisabled && url) window.open(url, "_blank");
            }

            $scope.remove = async (index, item) => {
                if (!$scope.removeDisabled) {
                    if ($scope.fnRemove && $scope.$parent[$scope.fnRemove]) {
                        const removed = await $scope.$parent[$scope.fnRemove](item);
                        if (typeof removed != 'boolean') {
                            HandleError.exception('Removal function must return Boolean value.');
                        } else if (removed) $scope.model.splice(index, 1);
                        $scope.$applyAsync();
                    }
                    else HandleError.exception('Removal method not implemented.');
                }
            }

            $scope.selectFile = () => {
                angular.element("#inputFile").click();
            }

            const onWhenAddingFileFailed = $scope.uploader.onWhenAddingFileFailed;
            $scope.uploader.onWhenAddingFileFailed = (item, filter) => {
                if (onWhenAddingFileFailed) onWhenAddingFileFailed(item, filter);
                if (filter.name == "sizeFilter") HandleError.exception($filter("translate")("GENERAL.MESSAGE_FILE_SIZE", { fileName: item.name }));
            }

            const onBeforeUploadItem = $scope.uploader.onBeforeUploadItem;
            $scope.uploader.onBeforeUploadItem = (item) => {
                if (onBeforeUploadItem) onBeforeUploadItem(item);
                if (!$scope.multiple && $scope.model && $scope.model.length > 0) {
                    $scope.uploader.isCustomError = true;
                    item.isCustomError = true;
                    item.cancel();
                    $scope.uploader.clearQueue();
                    HandleError.exception($filter("translate")("GENERAL.DELETE_ATTACHMENT"));
                }
                const fileName = item.file.name;
                const fileNameWithoutExtension = fileName.split(".")[0];
                const specialCharRegex = /[^A-Za-z0-9_ -]/;
                if (specialCharRegex.test(fileNameWithoutExtension)) {
                    $scope.uploader.isCustomError = true;
                    item.isCustomError = true;
                    item.cancel();
                    $scope.uploader.clearQueue();
                    HandleError.exception($filter("translate")("GENERAL.MESSAGE_FILE_NAME"));
                }
                else {
                    item.isCustomError = false;
                }
            }

            const onCompleteItem = $scope.uploader.onCompleteItem;
            $scope.uploader.onCompleteItem = (item, response, status) => {
                if (onCompleteItem) onCompleteItem(item, response, status);
                if (!item.isCustomError) {
                    if (item.isCancel) HandleError.warning($filter("translate")("GENERAL.MESSAGE_FILE_CANCELED", { fileName: item.file.name }));
                    else if (status == 200) {
                        try {
                            if ($scope.model == null) $scope.model = [];
                            if (response && response.data) response.data.map(data => { $scope.model.push({ FILE_DISPLAY_NAME: data.displayFileName, FILE_NAME: data.fileName, FILE_TYPE: data.fileType, FILE_PATH: data.filePath, FILE_URL: data.fileUrl, FILE_HASH: data.fileHash, FILE_SIZE: data.fileSize, FORM_NAME: data.formName }) });
                            if (!item.removeAfterUpload && item.isUploaded) item.remove();
                        } catch (ex) {
                            console.log("ex: ", ex);
                        }
                    }
                    else {
                        item.isUploaded = false;
                        HandleError.exception($filter("translate")("GENERAL.MESSAGE_FILE_ATTACHED", { fileName: item.file.name }));
                    }
                }
            }

            const onCompleteAll = $scope.uploader.onCompleteAll;
            $scope.uploader.onCompleteAll = () => {
                if (onCompleteAll) onCompleteAll();
                const inputFile = element.find('input:file');
                if ($scope.uploader.isCustomError) {
                    if (inputFile) inputFile.val('');
                    $scope.uploader.isCustomError = false;
                }
                else if ($scope.uploader.queue.length == 0) {
                    if ($scope.multiple) NotificationService.success($filter("translate")("GENERAL.ATTACHMENT_INSERTED"));
                    else {
                        if (inputFile) inputFile.val('');
                        NotificationService.success($filter("translate")("GENERAL.ATTACHMENT_INSERTED"));
                    }
                }
            }

            $scope.uploader.destroy = () => {
                $scope.$destroy();
                element.remove();
                $scope = null;
            }

        }
    }
    return ddo;
}

function buildFilter(filterName: string, filterFunction: Function, filters: IFilter[]): IFilter[] {
    const filter: IFilter = { name: filterName, fn: filterFunction };

    const filterIndex = filters.findIndex(filter => filter.name == filterName);

    if (filterIndex < 0) {
        filters.push(filter);
    }
    else {
        filters[filterIndex] = filter;
    }

    return filters;
} 
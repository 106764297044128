import * as angular from "angular";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog, } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IProviderModel } from "@models/interface/product/ProviderModel";
import { SelectorModel } from "../../common/model/SelectorModel";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { ISessionService } from "@services/SessionService";
import { ILegalPersonExchangeData, ILegalPersonSelector, ILegalPersonAddress } from "../model/LegalPersonModel";
import { ILegalPersonModel } from "../model/LegalPersonModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { SSEService } from "@appServices/SSEService";
import { ILinkParameter } from "../../common/model/ModelParameter";
import { StringUtil } from "../../common/util/StringUtil";
import { EOperation } from "@enums/GenericData";
import { IModalService } from "@services/ModalService";
import { IDocumentError } from "@models/interface/common/IDocumentError";
import { HelperService } from "@services/HelperService";

interface IProviderScope extends IGridFormServiceScope {
    model: IProviderModel;
    log: IViewLog;
    typeList: SelectorModel[];
    productList: SelectorModel[];
    legalPersonList: ILegalPersonSelector[];
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IProviderModel;
    scacIataLengthValidation: number;
    user: any;
    sessionService: ISessionService;
    getLegalPersonListByName: (search: string) => Promise<void>;
    goToLegalPerson: (id: number) => void;
    limitLines: (value: string, limit: number) => string;
    updateType: (type: SelectorModel) => void;
    selectLegalPerson: (legalPerson: ILegalPersonModel) => void;
    correspondenceGenerate: () => void;
    editProvider: (provider: IProviderModel) => Promise<void>;
    viewProvider: (provider: IProviderModel) => Promise<void>;
    viewLogProvider: (provider: IProviderModel) => Promise<void>;
    copyProvider: (provider: IProviderModel) => Promise<void>;
    openModalIntegration: (id: number, documentErrorList: IDocumentError[], name: string) => void;
}

export class ProviderRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IProviderScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private SSEService: SSEService;
    private gridName: string;
    private ModalService: IModalService;
    private modalID: number;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IProviderScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.gridName = 'GRID_PROVIDER';
        this.modalID = 0;
        this.ModalService = $injector.get('ModalService');
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'provider', 'GENERAL.MENU.PROVIDER', true);
            await this.initGrid(this.gridName, '/provider/list', true, true, null, true, true);

            const sessionParameter = this.$gridService.$sessionParameter;
            if (sessionParameter && sessionParameter.data) this.callSessionFunctions(sessionParameter.data);

        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.getLegalPersonListByName = async (search: string) => {
            let legalPersonSelectorList: ILegalPersonSelector[] = [];
            if (search && search.length >= 3) {
                legalPersonSelectorList = await this.getLegalPersonListByName(search);
            }
            this.$scope.legalPersonList = legalPersonSelectorList;
        }

        this.$scope.goToLegalPerson = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.legalPerson", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.limitLines = (value: string, limit: number) => {
            return StringUtil.limitLines(value, limit);
        }

        this.$scope.selectLegalPerson = (legalPerson: ILegalPersonModel) => {
            if (this.$scope.model.NAME == null || this.$scope.model.NAME == "") this.$scope.model.NAME = legalPerson.SHORT_NAME;
        }

        this.$scope.editProvider = async (provider: IProviderModel): Promise<void> => {
            let blockedObject = {
                ID: provider.ID,
                NAME: provider.NAME,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || provider.ID !== this.$scope.model.ID) this.fetchData(provider.ID, EOperation.VIEW);
                } else if (this.$scope.operation !== EOperation.EDIT || provider.ID !== this.$scope.model.ID) {
                    this.fetchData(provider.ID, EOperation.EDIT);
                }



            };
        }
        this.$scope.viewProvider = async (provider: IProviderModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.fetchData(provider.ID, EOperation.VIEW);
        }

        this.$scope.viewLogProvider = async (provider: IProviderModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(provider);
        }

        this.$scope.copyProvider = async (provider: IProviderModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.fetchData(provider.ID, EOperation.COPY);
            this.$scope.model = angular.copy(provider);
            this.updateType(provider.TYPE);
        }

        this.$scope.correspondenceGenerate = async () => {
            try {
                const legalPerson = this.$scope.model.LEGAL_PERSON
                if (!legalPerson) throw Error(this.formService.getTranslate("PRODUCT.SELECT_LEGAL_PERSON_FIRST"));

                const legalPersonAddress: ILegalPersonAddress = await this.getLegalPersonAddressById(legalPerson.ID)

                if (legalPersonAddress) {
                    const idFiscal = legalPersonAddress.ID_FISCAL;
                    const fantasyName = legalPersonAddress.FANTASY_NAME;
                    const city = legalPersonAddress.ADDRESS.CITY;

                    const publicPlace = legalPersonAddress.ADDRESS.PUBLIC_PLACE;
                    const number = legalPersonAddress.ADDRESS.NUMBER;
                    const zipCode = legalPersonAddress.ADDRESS.ZIP_CODE;
                    const complement = legalPersonAddress.ADDRESS.COMPLEMENT;
                    const district = legalPersonAddress.ADDRESS.DISTRICT;

                    let string = '';
                    if (publicPlace && publicPlace != '') string += `${publicPlace}`;
                    if (number && number !== '') string += `${string !== '' ? ' ' : ''}${number}`;
                    if (complement && complement !== '') string += `${string !== '' ? ' ' : ''}${complement}`;
                    if (district && district !== '') string += `${string !== '' ? ', ' : ''}${district}`;

                    let address = `${fantasyName}` + "\n"
                    if (string !== '') address += string + "\n";
                    address += `${city.NAME}, ${city.PROVINCE.CODE} - ${city.PROVINCE.COUNTRY.NAME}` + (zipCode && zipCode !== '' ? ` CEP: ${zipCode}` : '');
                    address += "\n" + `${legalPersonAddress.TYPE.NAME}: ${idFiscal}`

                    if (!publicPlace || !district) this.formService.notifyWarning(this.formService.getTranslate("REGISTRATION.MESSAGES.ERROR.MISSING_ADDRESS_IN_LEGAL_PERSON_TO_GENERATE_CORRESPONDENCE"));
                    this.$scope.model.CORRESPONDENCE = address;
                } else throw Error(this.formService.getTranslate("REGISTRATION.MESSAGES.ERROR.LEGAL_PERSON_NOT_FOUND"));
            } catch (ex) {
                this.formService.handleError(ex);
            }
        }

        this.$scope.openModalIntegration = (id: number, documentErrorList: IDocumentError[]) => {
            this.openModalIntegration(id, documentErrorList);
        }

        this.$scope.updateType = (type: SelectorModel) => {
            this.updateType(type)
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewProvider(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editProvider(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyProvider(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogProvider(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 125,
            maxWidth: 125,
            cellTemplate: (view + edit + viewLog + copy + modalIntegration),
            enableCellEdit: true,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 100, cellFilter: "YesOrNo" };
            const colCorporateName: IMonacoColumnDef = { name: "LEGAL_PERSON.CORPORATE_NAME", displayName: "ENTITY.CORPORATE_NAME", width: 250 };
            const colScac: IMonacoColumnDef = { name: "SCAC", displayName: "GENERAL.SCAC_CODE", width: 400 };
            const colName: IMonacoColumnDef = { name: "NAME", displayName: "GENERAL.NAME", width: 250 };
            const colType: IMonacoColumnDef = { name: "TYPE.NAME", displayName: "GENERAL.TYPE", width: 250 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT", displayName: "BASIC_DATA.PRODUCT", width: 300, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PRODUCT, null, "ID")}}</div>' };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'ID_LEGAL_PERSON':
                        columnDefs.push(colCorporateName);
                        break;
                    case 'SCAC':
                        columnDefs.push(colScac);
                        break;
                    case 'NAME':
                        columnDefs.push(colName);
                        break;
                    case 'TYPE':
                        columnDefs.push(colType);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            LEGAL_PERSON: null,
            ID_LEGAL_PERSON: null,
            ACTIVE: true,
            USED_OCEAN_SCHEDULE: false,
            SCAC_IATA: null,
            NUMBER_IATA: null,
            TYPE: null,
            NAME: null,
            PRODUCT: null,
            CORRESPONDENCE: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            SEARCH_FIELDS: null,
            DOCUMENT_ERROR: null
        };
    }

    initDependencies(): Promise<any> {
        const self: ProviderRegisterController = this;

        this.updateType(null);
        $("#numberIata")['mask']('000');

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getTypeList(),
                self.getProductList(),
            ]).then((result: any) => {
                self.$scope.typeList = result[0];
                self.$scope.productList = result[1];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private callSessionFunctions(data: object): void {
        const legalPersonExchangeData = <ILegalPersonExchangeData>data;
        switch (legalPersonExchangeData.OPERATION) {
            case "register": this.$scope.register(legalPersonExchangeData.MODEL);
                break;
        }
    }

    private updateType(type: SelectorModel): void {
        if (type) {
            this.$scope.model.SCAC_IATA = null;
        } else {
            type = angular.copy(this.$scope.model.TYPE);
        }

        if (type) {
            switch (type.ID) {
                //Line operator
                case '1':
                    this.$scope.model.NUMBER_IATA = null;
                    this.$scope.scacIataLengthValidation = 4;
                    break;
                //Airline Company
                case '2':
                    this.$scope.scacIataLengthValidation = 2;
                    break;
                //Truck Company
                case '3':
                    this.$scope.model.NUMBER_IATA = null;
                    this.$scope.scacIataLengthValidation = 4;
                    break;
                default:
                    this.$scope.scacIataLengthValidation = 0;
                    break;
            }
        }
    }

    private async getTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/provider_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlDataManager = this.config.productUrl + baseRoute;
        return urlDataManager;
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
            {
                PROPERTY: 'LEGAL_PERSON',
                LABEL: 'ENTITY.CORPORATE_NAME'
            },
            {
                PROPERTY: 'SCAC_IATA',
                LABEL: 'GENERAL.SCAC_CODE'
            },
            {
                PROPERTY: 'NUMBER_IATA',
                LABEL: 'OPERATIONAL.IATA_NUMBER'
            },
            {
                PROPERTY: 'TYPE',
                LABEL: 'GENERAL.TYPE'
            },
            {
                PROPERTY: 'PRODUCT',
                LABEL: 'BASIC_DATA.PRODUCT'
            },
            {
                PROPERTY: 'CORRESPONDENCE',
                LABEL: 'ENTITY.POSTAL_ADDRESS'
            },
            {
                PROPERTY: 'ID',
                LABEL: 'REGISTRATION.IDENTIFICATION'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'CODE',
                LABEL: 'GENERAL.CODE'
            },
            {
                PROPERTY: 'INTEGRATION_ID',
                LABEL: 'GENERAL.INTEGRATION_ID'
            },
            {
                PROPERTY: 'SHORT_NAME',
                LABEL: 'ENTITY.SHORT_NAME'
            },
            {
                PROPERTY: 'CORPORATE_NAME',
                LABEL: 'ENTITY.CORPORATE_NAME'
            },
            {
                PROPERTY: 'FANTASY_NAME',
                LABEL: 'ENTITY.TRADE_NAME'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            }
        ]
        return props;
    }

    private async getLegalPersonListByName(name: string): Promise<ILegalPersonSelector[]> {
        try {
            let result = [];
            if (name && name.length >= 3) {
                const legalPersons = await this.RestService.newObjectPromise(`${this.$baseUrl}/legalPerson/list/custom`, { specializations: [], search: name }, 30000, false);
                if (legalPersons) result = legalPersons.map(legalPerson => { return { ID: legalPerson.ID, CORPORATE_NAME: legalPerson.CORPORATE_NAME, SHORT_NAME: legalPerson.SHORT_NAME } });
            }
            return result;
        } catch (ex) {
            if (!ex.status || ex.status && ex.status !== 404) this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getLegalPersonAddressById(id: string): Promise<ILegalPersonAddress> {
        this.formService.block();
        try {
            if (!id) throw Error('Missing id in getLegalPersonAddressById!');

            const request = await this.RestService.getObjectAsPromise(`${this.$baseUrl}/legalPerson/getAddress/${id}`, 30000, null, false);
            const result = request && request.data && request.data[0] ? request.data[0] : null;

            return result;
        } catch (ex) {
            this.formService.handleError(ex);

        } finally {
            this.formService.unblock();
        }
    }

    private async openModalIntegration(id: number, documentErrorList: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            this.ModalService.showModalIntegrationRedundance({ integrationId: id, documentErrorList: documentErrorList, messageDefault: { awaitingRequest: 'Aguarde, enviando requisição...', successRequest: 'Registros enviados para fila de processamento.', requestError: 'Ocorreu um erro ao enviar a requisição.', awaitingGridSync: 'Aguarde, processando sincronização...', successGridSync: 'Sincronização realizada com sucesso.', gridSyncError: 'Ocorreu um erro durante a sincronização.', logCopyMessage: 'Mensagem de log copiada para o clipboard.' }, fnSync: this.sendSync, headerText: 'Erros de Integração - Fornecedores', actionButtonText: 'Sincronizar agora', closeButtonText: 'Fechar' });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number, idSubject: string): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                this.formService.block();
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/provider/sendSync`, { "idProvider": id, "idSubject": idSubject }, 120000, false);
                if (syncRequest) success = true;
            }
        } catch (ex) {
            this.formService.handleError(this.formService.getTranslate("GENERAL.ERROR_SENDING_REQUEST"));
        } finally {
            this.formService.unblock();
            return success;
        }
    }

    async register(params: IProviderModel): Promise<void> {
        try {
            if (this.$scope.log) this.$scope.log.show = false;

            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');

            if (params && params.LEGAL_PERSON && params.LEGAL_PERSON.CORPORATE_NAME) {
                const legalPerson = await this.getLegalPersonListByName(params.LEGAL_PERSON.CORPORATE_NAME);
                if (legalPerson && legalPerson.length > 0) {
                    this.$scope.model.NAME = legalPerson[0].SHORT_NAME;
                    this.$scope.model.LEGAL_PERSON = legalPerson[0];
                }
            }

            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} (${this.$scope.model.NAME})`;
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} (${this.$scope.model.NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            await this.clearFields(this.$scope.model, ['CONCATENATED']);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === "edit" ? "update" : "insert";

        return {
            route: `/provider/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: this.$scope.scopeBeforeSave
        };
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/provider/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

}

import * as angular from 'angular';
import moment = require("moment");
import { HandleError } from "../../../common/util/HandleError";
import { edit, IGridApi, IGridInstanceOf } from "ui-grid";
import { IUiGrid } from "../../../gridExcel/directive/UiGridExcel";

export interface IElementDatetmePicker extends JQuery {
    calentim: (options: IDatetimePickerOptions) => IDatetimePickerMethods;
    mask: (format: string) => void;
}

export interface IDatetimePickerMethods {
    setStart?: (date: Date | moment.Moment) => void;
    setEnd?: (date: Date | moment.Moment) => void;
    hideDropdown?: () => void;
    updateInput?: (callSelectEvent: boolean, isTimeEvent: boolean) => void;
    reDrawCells?: () => void;
    reDrawCalendars?: () => void;
    destroy?: () => void;
}

export interface IDatetimePicker extends IDatetimePickerMethods {
    options: IDatetimePickerOptions,
    config: IDatetimePickerOptions,
    updateInput: (calendar: boolean, time: boolean) => void,
    updateTimePickerDisplay: () => void
}

export interface IDatetimePickerRange {
    startDate: Date;
    endDate: Date;
}

export enum Key {
    BACKSPACE = 8,
    ENTER = 13,
    ESC = 27,
    DELL = 46,
    F2 = 113,
}

export interface IDatetimePickerOptions {
    format?: string;
    hourFormat?: number;
    singleDate?: boolean;
    ranges?: Array<IRangeDatetimePicker>;
    rangeLabel?: string;
    cancelLabel?: string;
    applyLabel?: string;
    rangeOrientation?: string;
    showHeader?: boolean;
    showFooter?: boolean;
    calendarCount?: number;
    enableKeyboard?: boolean;
    startEmpty?: boolean;
    startDate?: moment.Moment;
    endDate?: moment.Moment;
    autoAlign?: boolean;
    showCalendars?: boolean;
    showTimePickers?: boolean;
    showOn?: string;
    arrowOn?: string;
    container?: string;
    showButtons?: boolean;
    onafterselect?: (datetimePicker: IDatetimePicker, startDate: moment.Moment, endDate: moment.Moment) => void;
    ontimechange?: (datetimePicker: IDatetimePicker, startDate: moment.Moment, endDate: moment.Moment) => void;
    onrangeselect?: (datetimePicker: IDatetimePicker, range: IRangeDatetimePicker) => void;
    onafterhide?: (datetimePicker: IDatetimePicker) => void;
    onaftershow?: (datetimePicker: IDatetimePicker) => void;
    oninit?: (datetimePicker: IDatetimePicker) => void;
    onaftermonthchange?: (datetimePicker: IDatetimePicker, month: moment.Moment) => void;
    onafteryearchange?: (datetimePicker: IDatetimePicker, year: moment.Moment) => void;
    oneCalendarWidth?: number;
}

export interface IRangeDatetimePicker {
    title: string;
    startDate: moment.Moment;
    endDate: moment.Moment;
}

export interface IGrid extends IGridInstanceOf<object> {
    api: IGridApi
}


export class DatetimePickerController implements ng.IController, IDatetimePickerOptions {
    private timeThrottle = 750;
    public $element: JQLite = null;
    public $timeout: angular.ITimeoutService = null;
    public $scope: angular.IScope = null;
    public $injector: angular.Injectable<any> = null;

    public uiGridEditConstants: edit.IUiGridEditConstants = null;
    public uiGrid: IUiGrid = null;

    public ngModel: angular.INgModelController = null;
    public ngDisabled: boolean = null;
    public options: IDatetimePickerOptions = null;
    public throttle = null;

    //options binding
    public singleDate: boolean = false;
    public minimal: boolean = false;
    public count: string = '2';
    public position: string = 'bottom';
    public arrow: string = 'left';

    //handlers
    private input: IElementDatetmePicker;
    private body: any;
    private cancelCellEditHandler: any;
    private endCellEditHandler: any;
    private beginCellEditHandler: any;


    public static $inject: Array<string> = ['$injector', '$element', '$timeout', '$scope', 'uiGridEditConstants'];
    constructor($injector: angular.Injectable<any>, $element: JQLite, $timeout: angular.ITimeoutService, $scope: angular.IScope, uiGridEditConstants: edit.IUiGridEditConstants) {
        this.$injector = $injector;
        this.$element = $element;
        this.$timeout = $timeout;
        this.$scope = $scope;
        this.uiGridEditConstants = uiGridEditConstants;

        this.configDefaultOptions();
    }

    $onInit() {
        try {
            const $ctrl: DatetimePickerController = this;
            this.input = $ctrl.$element.find('input') as IElementDatetmePicker;
            //const input = $ctrl.$element.find('input') as IElementDatetmePicker;

            $ctrl.options.container = ".app-content";

            //listeners          
            this.input.keydown($ctrl.updateComponent.bind($ctrl));

            $ctrl.options.onaftershow = $ctrl.adjustPositionComponent.bind($ctrl);
            $ctrl.options.onaftermonthchange = $ctrl.adjustPositionComponent.bind($ctrl);
            $ctrl.options.onafteryearchange = $ctrl.adjustPositionComponent.bind($ctrl);
            $ctrl.options.onafterselect = $ctrl.adjustPositionComponent.bind($ctrl);
            $ctrl.options.ontimechange = $ctrl.updateComponent.bind($ctrl);
            //$ctrl.options.ontimechange = $ctrl.redrawCalendar.bind($ctrl);

            //apply icon
            this.body = $('body');
            this.body.on('click', '.calentim-apply', $ctrl.updateComponent.bind($ctrl));

            //options by html attributes
            if (angular.isDefined($ctrl.$element.attr('grid-excel'))) {

                if (!this.uiGrid) throw new Error("Please init GridFormService on your Controller before use datetime-picker with uiGridExcel.");

                $ctrl.options.showHeader = true;
                $ctrl.options.showFooter = true;
                $ctrl.options.calendarCount = $ctrl.count && parseInt($ctrl.count) ? parseInt($ctrl.count) : 1;
                $ctrl.options.showOn = $ctrl.position ? $ctrl.position : 'left';
                $ctrl.options.arrowOn = $ctrl.arrow ? $ctrl.position : 'center';

                //receptors                
                const grid = this.uiGrid.grid.api;
                this.cancelCellEditHandler = $ctrl.$scope.$on($ctrl.uiGridEditConstants.events.CANCEL_CELL_EDIT, $ctrl.closeCalendar.bind($ctrl));
                this.endCellEditHandler = $ctrl.$scope.$on($ctrl.uiGridEditConstants.events.END_CELL_EDIT, $ctrl.closeCalendar.bind($ctrl));
                this.beginCellEditHandler = $ctrl.$scope.$on($ctrl.uiGridEditConstants.events.BEGIN_CELL_EDIT, () => this.input.focus());
                this.input.on('click', () => this.input.focus());

                if (grid && grid.core && grid.core.on) grid.core.on.scrollBegin(this.$scope, $ctrl.closeCalendar.bind($ctrl));

            } else {
                this.timeThrottle = 500;
                $ctrl.options.onafterselect = $ctrl.updateComponent.bind($ctrl);
                $ctrl.options.ontimechange = $ctrl.updateComponent.bind($ctrl);
                $ctrl.options.onrangeselect = $ctrl.updateComponent.bind($ctrl);
            }

            if (angular.isDefined($ctrl.$element.attr('time'))) {
                $ctrl.options.showCalendars = false;
                $ctrl.options.format = 'HH:mm';
                $ctrl.options.startEmpty = false;
            }

            if (angular.isDefined($ctrl.$element.attr('date'))) {
                $ctrl.options.format = 'DD/MM/YYYY';
                $ctrl.options.showTimePickers = false;
            }

            if (angular.isDefined($ctrl.$element.attr('minimal'))) {
                $ctrl.options.showHeader = false;
                $ctrl.options.showFooter = false;
                $ctrl.options.calendarCount = $ctrl.count && parseInt($ctrl.count) ? parseInt($ctrl.count) : 1;
            }

            if (angular.isDefined($ctrl.$element.attr('position'))) {
                $ctrl.options.showOn = $ctrl.position;
            }

            if (angular.isDefined($ctrl.$element.attr('arrow'))) {
                $ctrl.options.arrowOn = $ctrl.arrow;
            }

            $ctrl.options.oneCalendarWidth = 100 * $ctrl.options.calendarCount;
            if (angular.isDefined($ctrl.$element.attr('range'))) {
                $ctrl.options.singleDate = false;
                $ctrl.options.calendarCount = $ctrl.count && parseInt($ctrl.count) ? parseInt($ctrl.count) : 2;
                $ctrl.options.oneCalendarWidth += 50;
                this.input.prop('readonly', true)

            } else {
                const formatToMask: string = $ctrl.options.format.replace(/[a-zA-Z]/g, '9');
                this.input.mask(formatToMask);
            }

        } catch (ex) {
            HandleError.exception(ex);
        }
    }


    $postLink() {
        try {
            const $ctrl: DatetimePickerController = this;
            const input = $ctrl.$element.find('input') as IElementDatetmePicker;
            const modelName: string = this.$element.attr('ng-model')

            if (!modelName) throw new Error('Please inform ng-model for two way data biding');

            $ctrl.$scope.$parent.$watch(modelName, (value) => {
                if (value) this.setModelComponent(input, value);
                else this.clearComponent();

                const datetimePicker: IDatetimePicker = input.data('calentim');
                if (!datetimePicker) input.calentim($ctrl.options);
            });

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    $onDestroy() {
        if (this.cancelCellEditHandler) this.cancelCellEditHandler();
        if (this.endCellEditHandler) this.endCellEditHandler();
        if (this.beginCellEditHandler) this.beginCellEditHandler();
        if (this.input) this.input.off();
        if (this.body) this.body.off();
    }

    private clearComponent() {
        const $ctrl: DatetimePickerController = this;
        const input = $ctrl.$element.find('input') as IElementDatetmePicker;
        const datetimePicker: IDatetimePicker = input.data('calentim');
        if (datetimePicker) datetimePicker.destroy();
        $ctrl.options.startEmpty = true;
        input.val('');

        input.calentim($ctrl.options);
    }

    private setModelComponent(input, value) {
        const $ctrl: DatetimePickerController = this;
        $ctrl.ngModel.$modelValue = moment(value);
        $ctrl.options.startEmpty = false;

        if (angular.isDefined($ctrl.$element.attr('range'))) {
            $ctrl.options.startDate = $ctrl.ngModel.$modelValue.startDate;
            $ctrl.options.endDate = $ctrl.ngModel.$modelValue.endDate;
        } else {
            $ctrl.options.startDate = $ctrl.ngModel.$modelValue;
            input.val($ctrl.options.startDate.format($ctrl.options.format))
        }
    }

    private updateComponent(event: JQuery.Event<HTMLElement>): void {

        try {

            const $ctrl: DatetimePickerController = this;
            $ctrl.addCloseButton();

            $ctrl.$timeout(() => {

                const input = this.$element.find('input') as IElementDatetmePicker;


                //keyevents
                let time: number = this.timeThrottle;
                if (event.keyCode === Key.ESC) {
                    $ctrl.closeCalendar();
                    $ctrl.$scope.$emit($ctrl.uiGridEditConstants.events.CANCEL_CELL_EDIT);
                    return;
                } else if (event.keyCode === Key.DELL) {
                    if (angular.isDefined($ctrl.$element.attr('grid-excel'))) return;
                }

                clearTimeout($ctrl.throttle);
                $ctrl.throttle = setTimeout(async () => {
                    //reset component
                    if (input.val() === '') {
                        await $ctrl.ngModel.$setViewValue(null);
                        $ctrl.options.startEmpty = true;
                        const datetimePicker: IDatetimePicker = input.data('calentim');
                        if (datetimePicker) datetimePicker.destroy();
                        input.calentim($ctrl.options);
                    } else {
                        input.focus();
                    }

                    //instance component                    
                    const datetimePicker: IDatetimePickerMethods = input.data('calentim');
                    if ($ctrl.options.singleDate) {
                        const currentDate: moment.Moment = moment(input.val(), $ctrl.options.format);
                        const isValidSingleDate: boolean = currentDate.isValid() && input.val().toString().length === $ctrl.options.format.toString().length;
                        if (isValidSingleDate) {
                            if (datetimePicker) datetimePicker.setStart(currentDate);
                            await $ctrl.ngModel.$setViewValue(currentDate.toDate());

                            $ctrl.emitEndEditEvent(event);
                        }
                    } else {

                        const split: Array<string> = input.val().toString().split('-');
                        if (split.length != 2) throw new Error('Invalid range format!');

                        const start: string = split[0].toString().trim();
                        const end: string = split[1].toString().trim();
                        const startDate: moment.Moment = moment(start, $ctrl.options.format);
                        const endDate: moment.Moment = moment(end, $ctrl.options.format);
                        const isValidRangeDate: boolean = datetimePicker && startDate.isValid() && endDate.isValid() && start.length === $ctrl.options.format.toString().length && end.length === $ctrl.options.format.toString().length;
                        if (isValidRangeDate) {

                            datetimePicker.setStart(startDate);
                            datetimePicker.setEnd(endDate);

                            const range: IDatetimePickerRange = {
                                startDate: startDate.toDate(),
                                endDate: endDate.toDate()
                            }

                            await $ctrl.ngModel.$setViewValue(range);

                            $ctrl.emitEndEditEvent(event);

                        }
                    }

                }, time);

            });

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    private async emitEndEditEvent(event): Promise<void> {

        const $ctrl: DatetimePickerController = this;
        const input = $ctrl.$element.find('input') as IElementDatetmePicker;


        if (angular.isDefined($ctrl.$element.attr('grid-excel'))) {

            const applyButtonClick: boolean = !!($(event.target).hasClass('calentim-apply') || $(event.target).parents('.calentim-apply')) && event.type === 'click';
            if (event.keyCode === Key.ENTER || applyButtonClick) {
                await $ctrl.closeCalendar();
                $ctrl.$scope.$emit($ctrl.uiGridEditConstants.events.END_CELL_EDIT);
            }
        }
    }

    private configDefaultOptions(): void {

        const previousWeek: IRangeDatetimePicker = {
            title: 'Semana Passada',
            startDate: moment().subtract(7, "days"),
            endDate: moment()
        };

        const yesterday: IRangeDatetimePicker = {
            title: 'Ontem',
            startDate: moment().subtract(1, "days"),
            endDate: moment().subtract(1, "days")
        };

        const today: IRangeDatetimePicker = {
            title: 'Hoje',
            startDate: moment(),
            endDate: moment()
        };

        const tomorrow: IRangeDatetimePicker = {
            title: 'Amanhã',
            startDate: moment().add(1, "days"),
            endDate: moment().add(1, "days")
        };

        const nextWeek: IRangeDatetimePicker = {
            title: 'Próxima Semana',
            startDate: moment(),
            endDate: moment().add(7, "days")
        };


        this.options = {
            format: 'DD/MM/YYYY HH:mm',
            rangeLabel: 'Intervalo',
            cancelLabel: 'Cancelar',
            applyLabel: 'Aplicar',
            rangeOrientation: 'vertical',
            hourFormat: 24,
            ranges: [previousWeek, yesterday, today, tomorrow, nextWeek],
            showFooter: true,
            showHeader: true,
            singleDate: true,
            enableKeyboard: true,
            autoAlign: true,
            calendarCount: 2,
        };
    }

    private async closeCalendar(event: JQuery.Event<HTMLElement> = null): Promise<void> {
        try {
            const $ctrl: DatetimePickerController = this;
            const input = $ctrl.$element.find('input') as IElementDatetmePicker;
            const datetimePicker: IDatetimePickerMethods = input.data('calentim');
            if (datetimePicker) datetimePicker.hideDropdown();
            $('.calentim-container.calentim-popup').hide();
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    private redrawCalendar(datetimePicker: IDatetimePicker, startDate: moment.Moment, endDate: moment.Moment): void {
        try {
            if (datetimePicker) datetimePicker.reDrawCalendars();

            const $ctrl: DatetimePickerController = this;
            $ctrl.addCloseButton();
            $ctrl.$element.find('input').focus();
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    private adjustPositionComponent(calentim: IDatetimePicker): void {

        try {
            const $ctrl: DatetimePickerController = this;

            $ctrl.addCloseButton();

            if (!$ctrl.options.autoAlign) return;

            const screenWidth: number = window.innerWidth;
            const popup: JQuery = $('.calentim-popup:visible');
            const leftPosition: number = popup.offset().left;
            const topPosition: number = popup.offset().top;
            const popupWidth: number = popup.outerWidth();

            if (topPosition < 0) {
                $ctrl.options.arrowOn = 'top';
                $ctrl.options.showOn = 'left';
            }
            if (leftPosition < 0) {
                $ctrl.options.arrowOn = 'left';
                $ctrl.options.showOn = 'bootom';
            } else if (screenWidth < (leftPosition + popupWidth)) {
                $ctrl.options.arrowOn = 'right';
                $ctrl.options.showOn = 'bootom';
            }

            calentim.options = $ctrl.options;
            calentim.config.showOn = $ctrl.options.showOn;
            calentim.config.arrowOn = $ctrl.options.arrowOn;

            this.redrawCalendar(calentim, null, null);

        } catch (ex) {
            HandleError.exception(ex);
        }

    }

    private addCloseButton() {

        try {

            const $ctrl: DatetimePickerController = this;

            if (angular.isDefined($ctrl.$element.attr('grid-excel'))) {

                let button: JQuery = null;
                const popup: JQuery<Document> = $(document).find('.calentim-popup:visible');
                if (angular.isDefined($ctrl.$element.attr('minimal'))) {
                    button = $('<button class="btn btn-rounded btn-sm btn-icon btn-success calentim-apply"><i class="fa fa-check"></i></button>');

                    button.css({
                        position: 'absolute',
                        right: '-7px',
                        'z-index': 12,
                        top: '-11px'
                    });

                    let calendar: JQuery<Document> = popup.find('.calentim-input');
                    if (calendar.find('.calentim-apply').length === 0) calendar.prepend(button);
                } else {
                    button = $('<button class="btn btn-success btn-rounded calentim-apply">Aplicar</buton>');

                    let header: JQuery<Document> = popup.find('.calentim-header');
                    if (header.find('.calentim-apply').length === 0) header.append(button);
                }
            }

        } catch (ex) {
            HandleError.exception(ex);
        }

    }
}
import * as angular from 'angular';
import 'angular-websocket';

import { WebSocketService } from '../services/WebSocketService';


const WEBSOCKET_MODULE = angular
    .module('webSocket', ['ngWebSocket'])
    .service('WebSocketService', WebSocketService);

export { WEBSOCKET_MODULE };
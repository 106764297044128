import angular = require('angular');
import { IGridOptions } from "ui-grid";
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { IMonacoColumnDef } from "@services/GridService2";
import { ISessionService } from "@services/SessionService";
import { OperationalService } from "@services/OperationalService";
import { IModalService } from "@services/ModalService";
import { GridFormService, IGridFormController, IGridFormServiceScope } from "@services/GridFormService";
import { ICustomLogProperties } from '@models/interface/common/IViewLog';
import { IAirFlightSpecificModel, IAirFlight } from '@models/interface/operational/AirFlight';
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { IProcessParameter } from "../../common/model/ModelParameter";
import { ISelectorModel } from "WBA-Model/dist/mongo/SelectorModel";
import moment = require("moment");

interface IAirFlightListScope extends IGridFormServiceScope {
    model: IAirFlightSpecificModel;
    baseAirFlight: IAirFlight;
    gridOptions: IGridOptions;
    form: ng.IFormController;
    customLogProperties: ICustomLogProperties[];
    user: any;
    editSpecificAirFlight: (specificAirFlight: IAirFlight) => Promise<void>;
    viewSpecificAirFlight: (specificAirFlight: IAirFlight) => Promise<void>;
    printDays: (frequency: ISelectorModel, day: ISelectorModel[]) => string;
    openWizardBaseFlight: () => void;
    editBaseFlight: (flightId: string, flightCode: string) => void;
    openWizardSpecificFlight: () => void;
    openFlightProcess: (specificAirFlight: IAirFlight) => void;
    goToAirFlightProcess: (processNumber: string) => void;
}

export class AirFlightListController extends GridFormService implements IGridFormController {

    static $inject: string[] = ["$injector", "$scope"];
    private $scope: IAirFlightListScope;
    private $timeout: ng.ITimeoutService;
    private ModalService: IModalService;
    private OperationalService: OperationalService;
    private SessionService: ISessionService;
    private gridName: string;
    private modalID: number;

    constructor($injector: ng.Injectable<any>, $scope: IAirFlightListScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$timeout = $injector.get("$timeout");
        this.ModalService = $injector.get('ModalService');
        this.OperationalService = $injector.get('OperationalService');
        this.SessionService = $injector.get('SessionService');
        this.gridName = "GRID_AIR_FLIGHT";
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.OperationalService.$route;
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.$rootScope.setBreadCrumb("GENERAL.MENU.AIR_FLIGHT", () => { this.openSelectFlightTypeModal(); });
            this.initForm(this, "form", "airFlight", "GENERAL.MENU.AIR_FLIGHT", false);
            await this.initGrid(this.gridName, '/airFlight/listSpecific', true, true, 120000, true, true);

            const self: AirFlightListController = this;
            this.$scope.$on('updateAirFlightGridRow', function (event, params) {
                if (event && event.name == "updateAirFlightGridRow" && params) self.updateAirFlightGridRow(params.bookingId);
            });
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.$rootScope.clearBreadCrumb();
        super.$onDestroy();
    }

    initModel(): void {
        this.$scope.model = {
            ID_FLIGHT: null,
            ACTIVE: null,
            SPECIFIC_FLIGHT_CODE: null,
            ACTUAL_TIME_ARRIVAL: null,
            ACTUAL_TIME_DEPARTURE: null,
            ESTIMATED_TIME_ARRIVAL: null,
            ESTIMATED_TIME_DEPARTURE: null,
            DEADLINE_CARGO_DOCS_HOUSE: null,
            DEADLINE_CARGO_DOCS_MASTER: null,
            FINAL_TIME_ARRIVAL: {
                DATE: null,
                IS_ESTIMATED: null
            },
            FINAL_TIME_DEPARTURE: {
                DATE: null,
                IS_ESTIMATED: null
            }
        }
    }

    initScopeFunctions(): void {
        this.$scope.editSpecificAirFlight = async (airFlightSpecific: IAirFlight): Promise<void> => {
            this.editSpecificAirFlight(airFlightSpecific);
        }

        this.$scope.viewSpecificAirFlight = async (airFlightSpecific: IAirFlight): Promise<void> => {
            this.viewSpecificAirFlight(airFlightSpecific);
        }

        this.$scope.printDays = (frequency: ISelectorModel, days: ISelectorModel[]): string => {
            let returnedString = '';
            let separator = ';';
            if (days) {
                for (const item of days) {
                    if (item.CODE) {
                        if (frequency.ID == '2') {
                            returnedString += moment.weekdaysShort(parseInt(item.CODE)) + separator;
                        } else {
                            returnedString += item.CODE + separator;
                        }
                    }
                }
            }
            return returnedString;
        }

        this.$scope.openWizardBaseFlight = (): void => {
            this.openWizardBaseFlight();
        }

        this.$scope.editBaseFlight = (flightId: string, flightCode: string): void => {
            this.editBaseFlight(flightId, flightCode);
        }

        this.$scope.openWizardSpecificFlight = (): void => {
            this.openWizardSpecificFlight();
        }

        this.$scope.openFlightProcess = (specificAirFlight: IAirFlight): void => {
            this.openFlightProcess(specificAirFlight);
        }

        this.$scope.goToAirFlightProcess = (processNumber: string): void => {
            if (!processNumber || (processNumber && processNumber == '')) throw Error('processNumber is null');
            this.SessionService.openTab("app.operational.newProcess.list", <IProcessParameter>{ PROCESS_NUMBER: processNumber });
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewSpecificAirFlight(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editSpecificAirFlight(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const editBaseFlight = `<a ng-click="grid.appScope.editBaseFlight(null, row.entity.FLIGHT_CODE)" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT_BASE_FLIGHT' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-plane icon"></i></a>&nbsp;&nbsp;`;
        const process = `<a><btn ng-click="grid.appScope.openFlightProcess(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'REGISTRATION.VIEW_FILES' | translate }}" tooltip-append-to-body="true"><i class="fa fa-list-alt icon"></i></btn></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLog(row.entity, row.entity._id)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;

        gridColumns.$columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: '7%',
            cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${editBaseFlight} ${process} ${viewLog}</div>`,
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }
    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colFlightCode: IMonacoColumnDef = { name: "FLIGHT_CODE", displayName: "OPERATIONAL.FLIGHT_CODE", width: 150 };
            const colSpecificFlightCode: IMonacoColumnDef = { name: "SPECIFIC.SPECIFIC_FLIGHT_CODE", displayName: "OPERATIONAL.SPECIFIC_FLIGHT_CODE", width: 150 };
            const colOrigin: IMonacoColumnDef = { name: "ORIGIN.CODE", displayName: "BASIC_DATA.ORIGIN", width: 150, cellTemplate: '<div class="grid-padding">{{row.entity.ORIGIN.CODE + " - " + row.entity.ORIGIN.NAME}}</div>' };
            const colDestination: IMonacoColumnDef = { name: "DESTINATION.CODE", displayName: "BASIC_DATA.DESTINATION", width: 150, cellTemplate: '<div class="grid-padding">{{row.entity.DESTINATION.CODE + " - " + row.entity.DESTINATION.NAME}}</div>' };
            const colDeparture: IMonacoColumnDef = { name: "SPECIFIC.FINAL_TIME_DEPARTURE.DATE", displayName: "OPERATIONAL.DEPARTURE", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm:ss'", cellTemplate: `<div class="wrapper-md" tooltip-placement="auto top" uib-tooltip="{{row.entity.SPECIFIC.FINAL_TIME_DEPARTURE.IS_ESTIMATED ? 'REGISTRATION.ESTIMATED_DATE' : 'OPERATIONAL.ACTUAL_DATE' | translate }}" tooltip-append-to-body="true" ng-class="{'text-blue text-italic' : row.entity.SPECIFIC.FINAL_TIME_DEPARTURE.IS_ESTIMATED == true}">{{row.entity.SPECIFIC.FINAL_TIME_DEPARTURE.DATE | date:'dd/MM/yyyy HH:mm'}}</div>` };
            const colArrival: IMonacoColumnDef = { name: "SPECIFIC.FINAL_TIME_ARRIVAL.DATE", displayName: "OPERATIONAL.ARRIVAL", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm:ss'", cellTemplate: `<div class="wrapper-md" tooltip-placement="auto top" uib-tooltip="{{row.entity.SPECIFIC.FINAL_TIME_ARRIVAL.IS_ESTIMATED ? 'REGISTRATION.ESTIMATED_DATE' : 'OPERATIONAL.ACTUAL_DATE' | translate }}" tooltip-append-to-body="true" ng-class="{'text-blue text-italic' : row.entity.SPECIFIC.FINAL_TIME_ARRIVAL.IS_ESTIMATED == true}">{{row.entity.SPECIFIC.FINAL_TIME_ARRIVAL.DATE | date:'dd/MM/yyyy HH:mm'}}</div>` };
            const colAirline: IMonacoColumnDef = { name: "PROVIDER.NAME", displayName: "BASIC_DATA.SERVICE_PROVIDER", width: 150 };
            const colFileNumber: IMonacoColumnDef = { name: "FILE_NUMBER", displayName: "OPERATIONAL.FILE_NUMBER", width: 150 };
            const colFlightStatus: IMonacoColumnDef = { name: "FLIGHT_STATUS.NAME", displayName: "GENERAL.SITUATION", width: 150 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.NAME", displayName: "BASIC_DATA.PRODUCT", width: 150 };
            const colConcatenated: IMonacoColumnDef = { name: "FLIGHT_CONCATENATED", displayName: "GENERAL.CONCATENATED", width: 150 };
            const colFrequency: IMonacoColumnDef = { name: "FREQUENCY.NAME", displayName: "REGISTRATION.FREQUENCY", width: 150 };
            const colDay: IMonacoColumnDef = { name: "DAY", displayName: "REGISTRATION.DAY", width: 150, cellTemplate: "<div class='grid-padding'> {{grid.appScope.printDays(row.entity.FREQUENCY, row.entity.DAY)}} </div>" };
            const colFiles: IMonacoColumnDef = { name: "FILES", displayName: "OPERATIONAL.FILE_NUMBER", width: 150 };
            const colActive: IMonacoColumnDef = { name: "SPECIFIC.ACTIVE", displayName: "GENERAL.ACTIVE", width: 150, cellFilter: "YesOrNo" };
            const colCreatedDate: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 140, cellFilter: "datetime" };
            const colCreatedBy: IMonacoColumnDef = { name: "CREATED_BY.NAME", displayName: "GENERAL.CREATED_BY", width: 180 };
            const colUpdatedDate: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 140, cellFilter: "datetime" };
            const colUpdatedBy: IMonacoColumnDef = { name: "UPDATED_BY.NAME", displayName: "GENERAL.UPDATED_BY", width: 180 };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 150 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'FLIGHT_CODE':
                        columnDefs.push(colFlightCode);
                        break;
                    case 'ORIGIN':
                        columnDefs.push(colOrigin);
                        break;
                    case 'DESTINATION':
                        columnDefs.push(colDestination);
                        break;
                    case 'SPECIFIC':
                        columnDefs.push(colDeparture);
                        columnDefs.push(colArrival);
                        columnDefs.push(colSpecificFlightCode);
                        columnDefs.push(colActive);
                        break;
                    case 'PROVIDER':
                        columnDefs.push(colAirline);
                        break;
                    case 'FILE_NUMBER':
                        columnDefs.push(colFileNumber);
                        break;
                    case 'FLIGHT_STATUS':
                        columnDefs.push(colFlightStatus);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'FLIGHT_CONCATENATED':
                        columnDefs.push(colConcatenated);
                        break;
                    case 'FREQUENCY':
                        columnDefs.push(colFrequency);
                        break;
                    case 'DAY':
                        columnDefs.push(colDay);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedDate);
                        break;
                    case 'CREATED_BY':
                        columnDefs.push(colCreatedBy);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedDate);
                        break;
                    case 'UPDATED_BY':
                        columnDefs.push(colUpdatedBy);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openSelectFlightTypeModal(): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    scope: this.$scope,
                    template: require("../view/modal/selectAirFlightType.html"),
                    formService: 'register',
                    size: 'md',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: 'OPERATIONAL.NEW_BASE_FLIGHT'
                }
            );
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async openWizardBaseFlight() {
        try {
            // Close the "SelectAirFlightType" modal;
            this.ModalService.closeModal(this.modalID);

            const modalInstance = await this.ModalService.showModalRegisterBaseFlight(this.modalID);
            const modalScope = await this.ModalService.getModalScope(this.modalID);
            await modalScope.$applyAsync();

            const apply = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (apply) {
                const msg = this.formService.getTranslate('OPERATIONAL.FLIGHT_SUCESSFULLY_REGISTERED');
                this.formService.notifySuccess(msg);
                this.formService.block();
                await this.updateGrid();
            }

        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async editBaseFlight(flightId: string, flightCode: string): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const modalInstance = await this.ModalService.showModalEditBaseFlight(this.modalID, flightCode, flightId);

            const modalScope = await this.ModalService.getModalScope(this.modalID);
            await modalScope.$applyAsync();

            const apply = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (apply) {
                const msg = this.formService.getTranslate('OPERATIONAL.FLIGHT_SUCESSFULLY_UPDATED');
                this.formService.notifySuccess(msg);
            }

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openWizardSpecificFlight(): Promise<void> {
        try {
            // Close the "SelectAirFlightType" modal;
            this.ModalService.closeModal(this.modalID);

            this.modalID = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    template: require("../view/modal/specificAirFlightModal.html"),
                    formService: 'register',
                    size: 'md modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: this.formService.getTranslate('GENERAL.GRID.ADD_A_NEW_SPECIFIC_FLIGHT')
                }
            );

            const modalScope = await this.ModalService.getModalScope(this.modalID);
            await modalScope.$applyAsync();

            const apply = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (apply) {
                const msg = this.formService.getTranslate('OPERATIONAL.SPECIFIC_FLIGHT_SUCESSFULLY_REGISTERED');
                this.formService.notifySuccess(msg);
                this.$rootScope.refreshPage();
            }

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async viewSpecificAirFlight(specificAirFlight: IAirFlight): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    template: require("../view/modal/specificAirFlightEditModal.html"),
                    formService: 'view',
                    size: 'vlg modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: this.formService.getTranslate('OPERATIONAL.EDITING_SPECIFIC_FLIGHT') + ' - ' + specificAirFlight.FLIGHT_CONCATENATED
                },
                {
                    specificFlightCode: specificAirFlight.SPECIFIC.SPECIFIC_FLIGHT_CODE
                }
            );

            const modalScope = await this.ModalService.getModalScope(this.modalID);
            await modalScope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async editSpecificAirFlight(specificAirFlight: IAirFlight): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    template: require("../view/modal/specificAirFlightEditModal.html"),
                    formService: 'edit',
                    size: 'vlg modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: this.formService.getTranslate('OPERATIONAL.EDITING_SPECIFIC_FLIGHT') + ' - ' + specificAirFlight.FLIGHT_CONCATENATED
                },
                {
                    specificFlightCode: specificAirFlight.SPECIFIC.SPECIFIC_FLIGHT_CODE
                }
            );

            const modalScope = await this.ModalService.getModalScope(this.modalID);
            await modalScope.$applyAsync();

            const apply = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (apply) {
                const msg = this.formService.getTranslate('OPERATIONAL.SPECIFIC_FLIGHT_SUCESSFULLY_UPDATED');
                this.formService.notifySuccess(msg);
            }

            await this.updateAirFlightGridRow(specificAirFlight.ID);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openFlightProcess(specificAirFlight: IAirFlight): Promise<void> {
        let result: IAirFlight = null;
        try {
            const processList = await this.OperationalService.post('/airFlight/getProcessListByFlightId', { flightId: specificAirFlight.ID });
            if (processList && processList.data && processList.data.data && processList.data.data) {
                this.modalID = this.ModalService.newModal();
                const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                    {
                        modalID: this.modalID,
                        template: require("../view/modal/airFlightProcessListModal.html"),
                        size: 'md modal-overflow',
                        scope: this.$scope
                    },
                    {
                        actionButtonClass: 'btn-default',
                        actionButtonText: 'GENERAL.CLOSE',
                        headerText: this.formService.getTranslate('OPERATIONAL.EDITING_SPECIFIC_FLIGHT') + ' - ' + specificAirFlight.FLIGHT_CONCATENATED
                    },
                    {
                        processList: processList.data.data
                    }
                );
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getAirFlightById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const airFlightResult = await this.OperationalService.post(`/airFlight/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000);
            return airFlightResult.data && airFlightResult.data.data && airFlightResult.data.data.data ? airFlightResult.data.data.data[0] : null;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async updateAirFlightGridRow(id: number) {
        try {
            if (id && angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const flightData = await this.getAirFlightById(id);
                    if (row && flightData) {
                        row.FLIGHT_STATUS = flightData.FLIGHT_STATUS;
                        row.SPECIFIC.ACTUAL_TIME_DEPARTURE = flightData.SPECIFIC.ACTUAL_TIME_DEPARTURE;
                        row.SPECIFIC.ACTUAL_TIME_ARRIVAL = flightData.SPECIFIC.ACTUAL_TIME_ARRIVAL;
                        row.SPECIFIC.ESTIMATED_TIME_DEPARTURE = flightData.SPECIFIC.ESTIMATED_TIME_DEPARTURE;
                        row.SPECIFIC.ESTIMATED_TIME_ARRIVAL = flightData.SPECIFIC.ESTIMATED_TIME_ARRIVAL;
                        row.UPDATED_AT = flightData.UPDATED_AT;
                        row.UPDATED_BY = flightData.UPDATED_BY;
                        row.SPECIFIC.FINAL_TIME_DEPARTURE.DATE = flightData.SPECIFIC.ACTUAL_TIME_DEPARTURE ? flightData.SPECIFIC.ACTUAL_TIME_DEPARTURE : flightData.SPECIFIC.ESTIMATED_TIME_DEPARTURE;
                        row.SPECIFIC.FINAL_TIME_DEPARTURE.IS_ESTIMATED = (flightData.SPECIFIC.ACTUAL_TIME_DEPARTURE == null);
                        row.SPECIFIC.FINAL_TIME_ARRIVAL.DATE = flightData.SPECIFIC.ACTUAL_TIME_ARRIVAL ? flightData.SPECIFIC.ACTUAL_TIME_ARRIVAL : flightData.SPECIFIC.ESTIMATED_TIME_ARRIVAL;
                        row.SPECIFIC.FINAL_TIME_ARRIVAL.IS_ESTIMATED = (flightData.SPECIFIC.ACTUAL_TIME_ARRIVAL == null);
                    }
                }, 1000);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            { PROPERTY: "FLIGHT_CODE", LABEL: "OPERATIONAL.FLIGHT_CODE" }
        ]
        return props;
    }
}

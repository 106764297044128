import moment = require("moment");
import { IHttpResponse, isArray, isObject } from "angular";
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { IModalService } from "@services/ModalService";
import { IRestService } from "@services/RestService";
import { IMonacoRequest } from "@services/GridFormService";
import { IFinopService } from "@services/FinopService";
import { EXCHANGE_RATE, Invoice, INVOICE_CONFIGURATION, NEGOTIATION, NEGOTIATION_COMPOSITION, ORIGINAL } from "@models/interface/finop/NewInvoice";
import { SelectorModel } from "../../common/model/SelectorModel";
import { IInvoiceController, IInvoiceScope } from "./InvoiceRegisterController";
import { HelperService } from "@services/HelperService";
/* interface IValuesBeforeNegotiation extends IInvoiceNegotiation {
    CURRENT_CONVERSION: {
        [currencyCode: string]: { CONVERSION_FACTOR: number; CONVERSION_FACTOR_SPREAD: number; }
    }
} */

type Clonable = (SelectorModel & { CLONABLE?: boolean, EXCHANGE_RATE?: EXCHANGE_RATE[] });

interface INegotiateScope extends IFormServiceScope {
    model: NEGOTIATION;
    composition: NEGOTIATION_COMPOSITION;
    configuration: INVOICE_CONFIGURATION;
    operation: string;
    //currentValues: IValuesBeforeNegotiation;
    alreadyHasNegotiation: boolean;
    multipleInvoiceNegotiation: boolean;
    multipleProcessNegotiation: boolean;
    user: object;
    htmlPersistentNegotiation: string;
    multipleProcessHint: string;
    cloneFrom: Clonable[];
    manualDueDate: Date;
    // lists
    paymentMethodList: SelectorModel[];
    paymentConditionList: SelectorModel[];
    duedateReferenceList: SelectorModel[];
    conversionReferenceList: (SelectorModel & Clonable)[];
    conversionList: SelectorModel[];
    clonableList: Clonable[];
    // functions
    showCurrentNegotiation: () => Promise<void>;
    storeManualDueDate: () => Promise<void>;
    handleDueDate: () => Promise<void>;
    changeDueDateReference: () => Promise<void>;
    //addD0ToDueDate: () => Promise<void>;
    changeConversionDateReference: () => Promise<void>;
    changeConversionDate: () => Promise<void>;
    changeConversion: () => Promise<void>;
    applyNegotiation: () => Promise<void>;
}

export class InvoiceNegotiationController {
    static $inject: string[] = ['$injector', '$scope'];
    private $parentScope: IInvoiceController;
    private $scope: INegotiateScope;
    private $modalScope: IFormServiceScope;
    //
    private $q: ng.IQService;
    private SCEService: ng.ISCEService;
    private $timeout: ng.ITimeoutService
    private RestService: IRestService;
    private ModalService: IModalService;
    private FinopService: IFinopService;
    private FormService: FormService2;
    //
    private invoicesInNegotiation: Invoice[];
    private modalID: number;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $parentScope: IInvoiceScope) {
        try {
            // set parent scope - so that it could be used anywhere
            this.$parentScope = $parentScope;
            // use parent bind to rootscope to create a new internal scope - infer custom scope interface to prevent type-error
            this.$scope = <INegotiateScope>$parentScope.$new(true);
            // modal scope empty - modal not yet openned
            this.$modalScope = null;
            // injections
            this.$q = $injector.get('$q');
            this.SCEService = $injector.get('$sce');
            this.$timeout = $injector.get('$timeout');
            this.RestService = $injector.get('RestService');
            this.ModalService = $injector.get('ModalService');
            this.FinopService = $injector.get('FinopService');
            this.FormService = new FormService2($injector, this.$scope);
            // declarations
            this.invoicesInNegotiation = [];
            this.modalID = null;
            //
            this.$scope.user = $parentScope['user'];
            this.helperService = $injector.get('HelperService'); 
        } catch (ex) {
            throw ex;
        }
    }

    async init(model?: Invoice) {
        try {
            this.block();

            this.$scope.operation = 'edit';
            this.FormService.initStandAlone('negotiation');

            const selectedInvoices = (this.$parentScope.selectedInvoices) ? this.$parentScope.selectedInvoices : [];
            this.invoicesInNegotiation = (selectedInvoices.length > 0) ? selectedInvoices : (model) ? [model] : [];
            this.$scope.clonableList = [];

            if (this.invoicesInNegotiation.length === 0) return this.handleWarning(this.FormService.getTranslate('FINANCIAL.NO_INVOICE_SELECTED_FOR_NEGOTIATION'));

            this.$scope.alreadyHasNegotiation = this.invoicesInNegotiation.some(x => x.NEGOTIATED === true);
            this.$scope.multipleInvoiceNegotiation = (this.invoicesInNegotiation.length > 1);
            this.$scope.multipleProcessNegotiation = !this.invoicesInNegotiation.every(x => x.PROCESS_NUMBER === this.invoicesInNegotiation[0].PROCESS_NUMBER);

            this.$scope.htmlPersistentNegotiation = this.SCEService.trustAsHtml(this.FormService.getTranslate('FINANCIAL.TOOLTIPS.PERSISTENT_NEGOTIATION'));
            this.$scope.multipleProcessHint = this.SCEService.trustAsHtml(this.FormService.getTranslate('FINANCIAL.NEGOTIATION_MULTIPLE_PROCESSES_NO_DATE'));

            this.initScopeFunctions();
            await this.initModel();
            await this.initDependencies();

            if (this.$scope.alreadyHasNegotiation && !this.$scope.multipleInvoiceNegotiation) await this.showCurrentNegotiation();

            await this.negotiateModal();

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    onDestroy(): void {
        try {
            if (this.modalID) {
                this.ModalService.closeModal(this.modalID);
                this.modalID = null;
            }
        } catch (ex) {
            throw ex;
        }
    }

    private async initDependencies(): Promise<void> {
        try {
            const result: Array<any> = await this.$q.all([
                this.getGenericList('payment_method'),
                this.getGenericList('payment_condition'),
                this.getGenericList('deadline_reference'),
                this.getGenericList('conversion_reference'),
                this.getGenericList('exchange_currency_conversion'),
            ]);
            if (!result || !result.length) throw new Error(`${this.constructor.name}: Failed to load controller dependencies`);
            this.$scope.paymentMethodList = result[0];
            this.$scope.paymentConditionList = result[1];
            this.$scope.duedateReferenceList = result[2];
            this.$scope.conversionReferenceList = result[3];
            this.$scope.conversionList = result[4];
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private initScopeFunctions(): void {
        this.$scope.showCurrentNegotiation = (): Promise<void> => {
            return this.showCurrentNegotiation();
        }
        this.$scope.storeManualDueDate = async (): Promise<void> => {
            this.$scope.manualDueDate = this.$scope.configuration.DUE_DATE;
            await this.handleDueDate();
        }
        this.$scope.handleDueDate = (): Promise<void> => {
            return this.handleDueDate();
        }
        this.$scope.changeDueDateReference = (): Promise<void> => {
            return this.changeDueDateReference();
        }
        this.$scope.changeConversionDateReference = (): Promise<void> => {
            return this.changeConversionDateReference();
        }
        this.$scope.changeConversionDate = async (): Promise<void> => {
            if (!this.$scope.configuration.CONVERSION_DATE[0]) {
                this.$scope.composition.CURRENCY_CONVERSION = null;
                await this.initCurrencyConversion();
                await this.$scope.$applyAsync();
            }
        }
        this.$scope.changeConversion = (): Promise<void> => {
            return this.changeConversion();
        }
        this.$scope.applyNegotiation = (): Promise<void> => {
            return this.applyNegotiation();
        }
    }

    private async initModel(): Promise<void> {
        this.$scope.configuration = {
            DUE_DATE: null,
            CONVERSION_DATE: null,
            EXCHANGE_RATE: [],
        }
        this.$scope.composition = {
            PAYMENT_METHOD: null,
            PAYMENT_CONDITION: null,
            DUE_DATE_REFERENCE: null,
            DUE_DATE_D0: null,
            CONVERSION_REFERENCE: null,
            CURRENCY_CONVERSION: null,
            SPREAD: null,
            BASE: null,
            PERIOD_VARIATION: { ID: "2", NAME: "Não" },
            INVOICE_CONFIGURATION: this.$scope.configuration,
        }
        this.$scope.model = {
            NEGOTIATION_COMPOSITION: this.$scope.composition,
            ORIGINAL_INFORMATION: null,
            CREATED_DATE: null,
            CREATED_BY: null,
            PERSISTENT: false,
            OBSERVATION: null,
        }
        await this.initCurrencyConversion();
    }

    private async initCurrencyConversion(): Promise<void> {
        try {
            this.$scope.configuration.EXCHANGE_RATE = this.invoicesInNegotiation
                .reduce((acc, val) => acc.concat(val.CURRENCIES.ORIGINAL), <ORIGINAL[]>[]) // cram invoices currencies together
                .filter((x, index, self) => x && index === self.findIndex(t => t.CURRENCY.ID === x.CURRENCY.ID)) // unique
                .filter(x => x.CURRENCY.ID !== '110') // remove BRL
                .map(x => <EXCHANGE_RATE>{
                    CURRENCY: x.CURRENCY,
                    DATE: null,
                    CURRENCY_CONVERSION: this.$scope.composition.CURRENCY_CONVERSION,
                    CONVERSION_FACTOR: null,
                    CONVERSION_FACTOR_SPREAD: null
                });

            await this.$scope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
    }
    private async getClonableInfo(): Promise<void> {
        try {
            // clonable invoices
            if (!this.$scope.multipleInvoiceNegotiation) {
                const result = await this.getProcessInvoices(this.invoicesInNegotiation[0].PROCESS_NUMBER);
                const processInvoices: Invoice[] = (result) ? result : [];
                const clonableInvoices = processInvoices.filter(x => x.INVOICE_NUMBER !== this.invoicesInNegotiation[0].INVOICE_NUMBER && x.COMPOSITION && x.COMPOSITION.INVOICE_CONFIGURATION);
                for (const invoice of clonableInvoices) {
                    this.$scope.conversionReferenceList.push({
                        ID: invoice._id.toString(),
                        NAME: `[${invoice.TRANSACTION.map(x => x.NAME).join(', ')}] ${invoice.INVOICE_NUMBER} - ${(invoice.PEOPLE) ? invoice.PEOPLE.NAME.substr(0, 35) : ''}`,
                        CLONABLE: true,
                        EXCHANGE_RATE: (
                            invoice.COMPOSITION &&
                            invoice.COMPOSITION.INVOICE_CONFIGURATION &&
                            invoice.COMPOSITION.INVOICE_CONFIGURATION.EXCHANGE_RATE
                        ) ? invoice.COMPOSITION.INVOICE_CONFIGURATION.EXCHANGE_RATE.filter(invEx => this.$scope.configuration.EXCHANGE_RATE.some(neEx => invEx.CURRENCY.ID === neEx.CURRENCY.ID)) : [],
                    })
                }
            }
            await this.$scope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async showCurrentNegotiation(): Promise<void> {
        try {
            this.$scope.alreadyHasNegotiation = false;
            const currentNegotiation = this.invoicesInNegotiation[0].NEGOTIATION;
            if (currentNegotiation) {
                this.$scope.configuration = currentNegotiation.NEGOTIATION_COMPOSITION.INVOICE_CONFIGURATION;

                // dates
                this.$scope.configuration.DUE_DATE = (this.$scope.configuration.DUE_DATE) ? new Date(this.$scope.configuration.DUE_DATE) : null;


                if ((this.$scope.configuration.CONVERSION_DATE && this.$scope.configuration.CONVERSION_DATE[0])) this.$scope.configuration.CONVERSION_DATE[0] = new Date(this.$scope.configuration.CONVERSION_DATE[0]);
                if (this.$scope.configuration.EXCHANGE_RATE && this.$scope.configuration.EXCHANGE_RATE.length > 0 && this.$scope.configuration.EXCHANGE_RATE.some(x => x.DATE)) {
                    this.$scope.configuration.EXCHANGE_RATE = this.$scope.configuration.EXCHANGE_RATE.map(x => {
                        x.DATE = new Date(x.DATE);
                        return x;
                    })
                }

                this.$scope.composition = currentNegotiation.NEGOTIATION_COMPOSITION;
                this.$scope.composition.PERIOD_VARIATION = { ID: "2", NAME: "Não" };
                this.$scope.composition.INVOICE_CONFIGURATION = this.$scope.configuration;

                this.$scope.model = currentNegotiation;
                this.$scope.model.NEGOTIATION_COMPOSITION = this.$scope.composition;

                await this.handleDueDate();
                await this.changeConversionDateReference(false);
                await this.changeConversion();
            }
            else
                await this.initModel();
            await this.$scope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private handleError(error: any): void {
        return this.FormService.handleError(error);
    }
    private handleWarning(error: any): void {
        return this.FormService.handleWarning(error);
    }
    private notifySuccess(msg: string): void {
        return this.FormService.notifySuccess(msg);
    }
    private block(): void {
        return this.FormService.block();
    }
    private unblock(): void {
        return this.FormService.unblock();
    }

    private async getGenericList(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }
    private async getProcessInvoices(processNumber: string): Promise<Invoice[]> {
        try {
            if (!processNumber) return [];
            const finopReq: IMonacoRequest = {
                route: `/invoice/process/${processNumber}/true`,
                timeout: 120000,
            }
            const result = await this.FinopService.get<Invoice[]>(finopReq);
            if (!result || !result.data) {
                this.handleError(result);
                return [];
            }
            const processInvoices = result.data.data;
            return (processInvoices) ? processInvoices : [];
        } catch (ex) {
            this.handleError(ex);
        }
    }
    private async handleDueDate(): Promise<void> {
        try {
            await this.$scope.$applyAsync();

            if (this.$scope.composition.DUE_DATE_REFERENCE) {
                await this.changeDueDateReference();
                return;
            }

            if (this.$scope.configuration.DUE_DATE) {

                if (this.$scope.composition.DUE_DATE_D0 && !this.$scope.manualDueDate) {
                    const manualDueDate = moment(this.$scope.configuration.DUE_DATE, 'DD/MM/YYYY')
                    if (this.$scope.composition.DUE_DATE_D0) manualDueDate.subtract(this.$scope.composition.DUE_DATE_D0, 'day');
                    this.$scope.manualDueDate = manualDueDate.toDate();
                }

                if (this.$scope.manualDueDate) {
                    if (this.$scope.composition.DUE_DATE_D0) {
                        const manualDueDate = moment(this.$scope.manualDueDate, 'DD/MM/YYYY')
                        if (this.$scope.composition.DUE_DATE_D0) manualDueDate.add(this.$scope.composition.DUE_DATE_D0, 'day');
                        this.$scope.configuration.DUE_DATE = manualDueDate.toDate();
                    } else {
                        this.$scope.configuration.DUE_DATE = this.$scope.manualDueDate;
                    }
                }

            } else {
                this.$scope.configuration.DUE_DATE = null;
                this.$scope.composition.PAYMENT_CONDITION = null;
                this.$scope.composition.DUE_DATE_D0 = null;
            }

            await this.$scope.$applyAsync();

        } catch (ex) {
            this.handleError(ex);
        }
    }
    private async changeDueDateReference(): Promise<void> {
        try {
            await this.$scope.$applyAsync();

            if (this.$scope.multipleProcessNegotiation && this.$scope.composition.DUE_DATE_REFERENCE) {
                this.$scope.configuration.DUE_DATE = null;
                return;
            }

            const dateReference = this.$scope.composition.DUE_DATE_REFERENCE;
            if (!dateReference) {
                this.$scope.configuration.DUE_DATE = null;
                this.$scope.composition.PAYMENT_CONDITION = null;
                this.$scope.composition.DUE_DATE_D0 = null;
                await this.$scope.$applyAsync();
                return;
            }

            this.block();

            const finopReq: IMonacoRequest = {
                data: {
                    invoiceId: this.invoicesInNegotiation[0]._id, // we only need an invoice sample as what really matters where is it's process (number)
                    dateReferenceId: dateReference.ID,
                    d0: this.$scope.composition.DUE_DATE_D0,
                },
                route: '/negotiation/duedate',
                timeout: 120000,
            }
            const result = await this.FinopService.post<string>(finopReq);
            if (!result || !result.data) return this.handleError(result);

            //too fast
            this.$timeout((self) => {
                this.unblock();
            }, 250, true, this);

            const dueDate = result.data.data;
            if (!dueDate) return this.handleWarning(this.FormService.getTranslate('FINANCIAL.NO_DATE_FOUND_WITH_REFERENCE'));

            this.$scope.configuration.DUE_DATE = moment(dueDate, 'DD/MM/YYYY').toDate();
            await this.$scope.$applyAsync();

        } catch (ex) {
            this.handleError(ex);
        }
    }
    private async changeConversionDateReference(removeCurrencyConversion: boolean = true): Promise<void> {
        try {
            if (removeCurrencyConversion) {
                this.$scope.composition.CURRENCY_CONVERSION = null;
                this.$scope.configuration.CONVERSION_DATE = null;
                this.$scope.composition.SPREAD = null;
                await this.initCurrencyConversion();
            }

            await this.$scope.$applyAsync();

            const dateReference: SelectorModel & Clonable = this.$scope.composition.CONVERSION_REFERENCE;
            if (!dateReference || this.$scope.multipleProcessNegotiation) return;

            if (dateReference.CLONABLE) {
                this.$scope.configuration.EXCHANGE_RATE = (dateReference.EXCHANGE_RATE.length > 0) ? dateReference.EXCHANGE_RATE : this.$scope.configuration.EXCHANGE_RATE;
                return;
            }

            this.block();

            const finopReq: IMonacoRequest = {
                data: {
                    invoiceId: this.invoicesInNegotiation[0]._id, // we only need an invoice sample as what really matters where is it's process (number)
                    dateReferenceId: dateReference.ID,
                },
                route: '/negotiation/conversiondate',
                timeout: 120000,
            }
            const result = await this.FinopService.post<string>(finopReq);
            if (!result || !result.data) return this.handleError(result);

            //too fast
            this.$timeout(() => {
                this.unblock();
            }, 250, true, this);

            const conversionDate = result.data.data;
            if (!conversionDate) return this.handleWarning(this.FormService.getTranslate('FINANCIAL.NO_DATE_FOUND_WITH_REFERENCE'));

            this.$scope.configuration.CONVERSION_DATE = [moment(conversionDate, 'DD/MM/YYYY').toDate()];
            await this.$scope.$applyAsync();

        } catch (ex) {
            this.handleError(ex);
        }
    }
    private async changeConversion(): Promise<void> {
        try {
            //this.$scope.model.CONVERSION_DATE = null;
            await this.$scope.$applyAsync();

            const conversion = this.$scope.composition.CURRENCY_CONVERSION;
            const conversionDate = this.$scope.configuration.CONVERSION_DATE;
            const currencies = this.$scope.configuration.EXCHANGE_RATE.map(x => x.CURRENCY);
            if (!conversion || !conversionDate || currencies.length === 0) return;

            this.block();

            const finopReq: IMonacoRequest = {
                data: {
                    conversionId: conversion.ID,
                    currencies: currencies,
                    conversionDate: moment(conversionDate[0]).format('DD/MM/YYYY'),
                    spread: this.$scope.composition.SPREAD,
                },
                route: '/negotiation/exchangerate',
                timeout: 120000,
            }
            const result = await this.FinopService.post<EXCHANGE_RATE[]>(finopReq);
            if (!result || !result.data) return this.handleError(result);

            this.unblock();

            const updatedConversions: EXCHANGE_RATE[] = result.data.data;
            if (!updatedConversions) return this.handleWarning(this.FormService.getTranslate('FINANCIAL.NO_CONVERSION_INFORMATION_FOUND'));

            this.$scope.configuration.EXCHANGE_RATE = updatedConversions;
            await this.$scope.$applyAsync();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async negotiateModal(): Promise<void> {
        try {
            const invoicesToNegotiate = this.invoicesInNegotiation;
            if (!invoicesToNegotiate || invoicesToNegotiate.length === 0) return this.handleError(this.FormService.getTranslate('FINANCIAL.SELECT_INVOICES_TO_NEGOTIATE'));
            //Ascending -> REAL-BRAZILIAN CURRENCY  FIRST
            this.$scope.model.NEGOTIATION_COMPOSITION.INVOICE_CONFIGURATION.EXCHANGE_RATE.sort((a,b) => a.CURRENCY.ID < b.CURRENCY.ID ? -1 : a.CURRENCY.ID > b.CURRENCY.ID ? 1 : 0);
            await this.getClonableInfo();

            this.modalID = this.ModalService.newModal();
            this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    scope: this.$scope,
                    formService: this.$scope.operation,
                    template: require("../view/invoiceNegotiate.html"),
                    size: 'vlg',
                },
                {
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: (invoicesToNegotiate.length === 1) ? this.FormService.getTranslate('FINANCIAL.INVOICE_NEGOTIATE', {invoiceNumber: invoicesToNegotiate[0].INVOICE_NUMBER}) : `FINANCIAL.NEGOTIATE_INVOICES`
                });

            const modalScope = await this.ModalService.getModalScope(this.modalID);
            if (modalScope) this.$modalScope = modalScope;

        } catch (ex) {
            this.handleError(ex);
        }
    }
    private async applyNegotiation(): Promise<void> {
        try {
            this.block();

            // fix date format when changed in datepicker
            if (isObject(this.$scope.configuration.CONVERSION_DATE) &&
                !isArray(this.$scope.configuration.CONVERSION_DATE))
                this.$scope.configuration.CONVERSION_DATE = (this.$scope.configuration.CONVERSION_DATE[0]) ? [this.$scope.configuration.CONVERSION_DATE[0]] : null;

            // fix conversion reference when cloning 
            const dateReference: SelectorModel & Clonable = this.$scope.composition.CONVERSION_REFERENCE;
            if (dateReference && dateReference.CLONABLE) {
                this.$scope.model.NEGOTIATION_COMPOSITION.CONVERSION_REFERENCE = null;
            }

            const finopReq: IMonacoRequest = {
                data: {
                    invoiceId: this.invoicesInNegotiation.map(x => x._id),
                    negotiation: this.$scope.model,
                },
                route: '/invoice/negotiate',
                timeout: 300000,
            }

            const result = await this.FinopService.post(finopReq);
            if (!result || !result.data) return this.handleError(result);

            await this.$parentScope.clearSelections();
            await this.$parentScope.updateGrid();

            this.ModalService.closeModal(this.modalID);
            this.modalID = null;

            this.unblock();

            this.notifySuccess(this.FormService.getTranslate('FINANCIAL.NEGOTIATION_SUCCESSFULLY_APPLIED'));

        } catch (ex) {
            this.handleError(ex);
        }
    }
}

import "angular-block-ui/dist/angular-block-ui.min.css";
import '../../css/loading.css';
import * as angular from 'angular';

BlockUIConfig.$inject = ['blockUIConfig'];
function BlockUIConfig(blockUIConfig: ng.blockUI.BlockUIConfig) {
    blockUIConfig.template = require("./view/block.html");
    blockUIConfig.autoBlock = false;
    blockUIConfig.blockBrowserNavigation = true;
    blockUIConfig.delay = 0;
    blockUIConfig.resetOnException = false;
}

export default function initBlockUI(moduleName: string): void {
    angular.module(moduleName).config(BlockUIConfig);
}
import * as angular from 'angular';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from '@services/GridFormService';
import { IRestService } from '@services/RestService';
import { ExternalService } from "@services/ExternalService";
import { IMonacoColumnDef } from '@services/GridService2';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IErpNegotiationTypeSetup } from '../model/ErpNegotiationTypeSetupModel';
import { GridColumnBuilder, GridColumnBuilderConstants } from '../../common/GridColumnBuilder';
import { SelectorModel } from '../../common/model/SelectorModel';
import { BrowserTitle } from "../../common/BrowserTitle";
import { INegotiationType } from 'WBA-Model/dist/interface/finop/ErpNegotiationTypeSetup';
import { HelperService } from "@services/HelperService";

interface IErpNegotiationTypeSetupScope extends IGridFormServiceScope {
    model: IErpNegotiationTypeSetup;
    log: IViewLog;
    paymentMethodList: SelectorModel[];
    erpNegotiationTypeList: INegotiationType[];
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IErpNegotiationTypeSetup;
    getErpNegotiationTypeListByName: (search: string) => Promise<void>;
}

export class ErpNegotiationTypeSetupRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IErpNegotiationTypeSetupScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private ExternalService: ExternalService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IErpNegotiationTypeSetupScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.ExternalService = $injector.get('ExternalService');
        this.helperService = $injector.get('HelperService'); 
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.config.fmsUrl + '/fms';
            this.$scope.customLogProperties = this.getCustomLogProperties();

            this.initForm(this, 'form', 'erpNegotiationTypeSetup', 'REGISTRATION.ERP_NEGOTIATION_TYPE', true);
            await this.initGrid('gridErpNegotiationTypeSetup', '/erpNegotiationTypeSetup/list', true, true, null, true, true);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.getErpNegotiationTypeListByName = async (search: string) => {
            let erpNegotiationTypeList: INegotiationType[] = [];
            if (search && search.length >= 2) erpNegotiationTypeList = await this.getErpNegotiationTypeListByName(search);
            this.$scope.erpNegotiationTypeList = erpNegotiationTypeList;
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        gridColumns.includeActionBar([
            GridColumnBuilderConstants.BTN_VIEW,
            GridColumnBuilderConstants.BTN_EDIT,
            GridColumnBuilderConstants.BTN_COPY,
            GridColumnBuilderConstants.BTN_VIEWLOG
        ], 25);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colErpNegotiationType: IMonacoColumnDef = { name: 'ERP_NEGOTIATION_TYPE.NAME', displayName: 'REGISTRATION.ERP_NEGOTIATION_TYPE ', width: 300 };
            const colPaymentMethod: IMonacoColumnDef = { name: 'PAYMENT_METHOD.NAME', displayName: 'FINANCIAL.PAYMENT_MEANS', width: 200 };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "GENERAL.ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 150, cellFilter: "YesOrNo" };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'ERP_NEGOTIATION_TYPE':
                        columnDefs.push(colErpNegotiationType);
                        break;
                    case 'PAYMENT_METHOD':
                        columnDefs.push(colPaymentMethod);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            ERP_NEGOTIATION_TYPE: null,
            PAYMENT_METHOD: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            ACTIVE: true
        };
    }

    initDependencies(): Promise<any> {
        const self: ErpNegotiationTypeSetupRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericList('payment_method'),
            ]).then((result: any) => {
                self.$scope.paymentMethodList = result[0],
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getGenericList(type: string, alternative: boolean = false): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}/${alternative}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async getErpNegotiationTypeListByName(search: string): Promise<INegotiationType[]> {
        let result = [];
        this.formService.block();
        try {
            const charges = await this.ExternalService.post({ route: "/sankhya/negotiationType/list", data: { search: search } });
            if (charges && charges.data) {
                result = charges.data.data
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")} (${this.$scope.model.ERP_NEGOTIATION_TYPE.NAME})`;
            BrowserTitle.$id = this.$scope.model.ERP_NEGOTIATION_TYPE.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")} (${this.$scope.model.ERP_NEGOTIATION_TYPE.NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.ERP_NEGOTIATION_TYPE.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === 'register' ? 'insert' : 'update';
        return {
            route: `/erpNegotiationTypeSetup/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };

    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'ERP_NEGOTIATION_TYPE',
                LABEL: 'GENERAL.MENU.ERP_NEGOTIATE_TYPE'
            },
            {
                PROPERTY: 'PAYMENT_METHOD',
                LABEL: 'FINANCIAL.CHARGE_PAYMENT_METHOD'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'BASIC_DATA.ACTIVE'
            },
            {
                PROPERTY: 'DHALTER',
                LABEL: 'GENERAL.MODIFIED_DATE'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            }
        ];
        return props;
    }
}
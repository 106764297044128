import * as angular from 'angular';
import * as moment from 'moment';
import { OperationalService } from '@services/OperationalService';
import { ProductService } from '@services/ProductService';
import { IModalService, IModalOptions } from "@services/ModalService";
import { IRestService } from '@services/RestService';
import { FormService2 } from '@services/FormService2';
import { IFollowUpTriggerInput, IFollowUpModalParams } from '@models/interface/common/FollowUpParams';
import { CONTAINER_GROUP, NewProcessEvent, ITransportModeByVessel, ITransportMode, eProcessEventDateStatus, IVehicleTypeDateFrontEnd } from "@models/interface/operational/NewProcessEvent";
import { IViewLog } from "@models/interface/common/IViewLog";
import { EVENT, Process, SERVICE_PROVIDER } from '@models/interface/operational/NewProcess';
import { FollowupReferenceType, FollowupReferenceDate } from '@enums/GenericConstants';
import { ELegalPersonSpecializationId } from '@enums/LegalPerson';
import { HandleError } from '../../common/util/HandleError';
import { SelectorModel } from '../../common/model/SelectorModel';
import { ProcessEventHelperController } from '../common/ProcessEventHelperController';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { ISelectorModel } from '@models/mongo/SelectorModel';
import { INewProcessScope } from './NewProcessController';
import { EOperation, EProductId, EEventType, EDirectionId, EProcessSituation, ECargoTypeId, EProviderTypeId } from '@enums/GenericData';
import { IFlightSpecific, IAirFlight } from 'WBA-Model/dist/interface/operational/AirFlight';
import { HelperService } from "@services/HelperService";
import { IInttraDeadline, IInttraOceanSchedulesRequest, IInttraOceanSchedulesResult } from 'WBA-Model/dist/interface/external/IInttra';
import { EInttraSearchDateType, EEdiExternalCodeSetupInttra } from '@enums/InttraEnum';
import { ExternalService } from '@services/ExternalService';
import { ISessionService } from '@services/SessionService';
import { IVoyage } from 'WBA-Model/dist/interface/operational/voyage/Voyage';
import { IVoyageStopover } from 'WBA-Model/dist/interface/operational/voyage/VoyageStopover';
import { IVoyageProvider } from 'WBA-Model/dist/interface/operational/voyage/VoyageProvider';

interface IServiceProvider extends SelectorModel {
    TYPE: string
}

export interface IDefaultTab {
    initialTab: boolean;
    template: string;
    stringId: string;
    label: string;
    style: string;
    id: number;
}

export interface IWizardTravelStopoverParams {
    updateEventParentOperation: () => void,
    blockSteps: boolean;
    transportMode: object,
    modalOperation: string
    eventDetailModalID: number,
    defaultTabs: Array<IDefaultTab>,
    // optional parameters due to VoyagesAndStopoverRegisterController
    voyageId?: string,
    processNumber?: string,
    processEvents?: EVENT[],
    processProduct?: SelectorModel,
    showAllStopoversButton?: boolean;
    processServiceProvider?: SERVICE_PROVIDER,
    transportModesByVessel?: ITransportModeByVessel[],
}

interface IProcessEventTransportMode {
    vessel: SelectorModel;
    stopover: SelectorModel;
}

interface ITransportModeInttra extends ISelectorModel {
    LOCAL: ISelectorModel;
    SERVICE_PROVIDER: string;
    ETB: Date;
}

interface ImodifiedReasonModal {
    reason: ISelectorModel;
}

interface IContanetededProviderVoyage {
    PROVIDER: string
    VOYAGE_NUMBER: String;
    VOYAGE_ID: number
}

interface IProcessEventScope extends ng.IScope {
    modalOptions: IModalOptions;
    operation: string;
    eventMaritimeServices: SelectorModel[];
    eventModal: NewProcessEvent;
    process: Process;
    eventLog: IViewLog;
    situationList: SelectorModel[];
    processEventTransportModeList: SelectorModel[];
    eventModifiedList: SelectorModel[];
    eventModifiedResponsibleList: SelectorModel[];
    stopoverList: SelectorModel[];
    serviceProviderList: IServiceProvider[];
    eventList: EVENT[];
    processVehicleTypeEventList: IVehicleTypeDateFrontEnd[];
    isRoad: boolean;
    isRequiredJustification: boolean;
    isRequiredEffectiveReason: boolean;
    isRequiredForecastReason: boolean;
    isValidEventConfiguration: boolean;
    transportMode: IProcessEventTransportMode;
    transportModesByVessel: ITransportModeByVessel[];
    transportModeVesselsList: SelectorModel[];
    oceanScheduleOptions: IInttraOceanSchedulesResult[];
    oceanScheduleConcatenated: ITransportModeInttra[];
    oceanScheduleDeadline: IInttraDeadline[];
    transportModeSpecificAirFlight: IFlightSpecific;
    flightsList: IFlightSpecific[];
    specificFlightList: IFlightSpecific[];
    transportModeAirFlight: SelectorModel;
    allTransportModes: ITransportMode[];
    disableTravelWizard: boolean;
    eventDetailModalID: number;
    modifiedReasonModal: ImodifiedReasonModal;
    dateOptionsEffectiveDate: {
        maxDate: Date;
        minDate: Date;
    };
    disableVoyageEdit: boolean;
    openFollowUpModal: (modalParams?: IFollowUpModalParams[]) => Promise<void>;
    getLocalByType: (name: string, localType: string) => Promise<void>;
    getTerminal: (name: string) => Promise<void>;
    getSequenceByEvent: (eventNumber: number) => Promise<void>
    updateEventSituation: () => void;
    updateEventContainer: (type: string, container: CONTAINER_GROUP) => void;
    updateEventTruck: (type: string, vehicle: IVehicleTypeDateFrontEnd, processVehicleTypeEventList: IVehicleTypeDateFrontEnd[]) => void;
    updateEventDateTruck: (eventToSet: NewProcessEvent, type: string, date: Date, processVehicleTypeEventList: IVehicleTypeDateFrontEnd[]) => void;
    updateEventDate: (type: string, newValue: Date) => Promise<void>
    updateTransportMode: (newValue: SelectorModel, oldValue: SelectorModel) => Promise<void>
    updateEventDetail: () => Promise<void>
    changeTransportModeVessel: (previousVessel: string) => Promise<void>;
    stopoverWizard: (operation: string) => Promise<void>;
    collapseHeader: (elementId: string, origin: string) => void;
    viewEventLog: (entity: NewProcessEvent) => Promise<void>;
    isPreProcess: () => boolean;
    disableFieldsPreProcessByEvent: () => boolean;
    refreshWithDelay: (search: string, ms: number) => void;
    applyTransportModeToEventFromInttra: (selectedTransportModeStopover: ISelectorModel) => Promise<void>;

    // Functions for Flight part
    getFlights: (flightCode: string) => Promise<void>;
    updateProviderAndFlightCode: (selectedFlight: IFlightSpecific) => Promise<void>;
    updateSpecificFlightCode: (selectedSpecificAirFlight) => Promise<void>;
    insertSpecificFlightFromEvent: (specificFlightEstimatedDate: string) => Promise<void>;
    flightWizard: (operation: string) => Promise<void>;
    specificFlightWizard: (operation: string) => Promise<void>;
    updateFlightTransportMode: (newValue: IFlightSpecific) => Promise<void>;
    openVoyage: (operation: string) => Promise<void>;
    checkVoyage: () => Promise<IContanetededProviderVoyage>
}

export class ProcessEventController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IProcessEventScope;
    private $filter: ng.FilterFactory;
    private $timeout: ng.ITimeoutService;
    private operationalService: OperationalService;
    private restService: IRestService;
    private productService: ProductService;
    private formService: FormService2;
    private modalService: IModalService;
    private $injector: ng.Injectable<any>;
    private followUpTriggerData: IFollowUpTriggerInput[];
    private eventDetailBeforeEdit: NewProcessEvent;
    private processEventHelper: ProcessEventHelperController = null;
    private wizardStopoverModalID: number = 0;
    private wizardBaseFlightModalId: number = 0;
    private wizardSpecificFlightModalId: number = 0;
    private modalDateModifiedTypeId: number;
    private $sce: angular.ISCEService;
    private $compile: angular.ICompileService;
    private $newProcessScope: INewProcessScope;
    private helperService: HelperService;
    private externalService: ExternalService;
    private providerListForInttraVoyage: ISelectorModel[];
    private externalShipowners: ISelectorModel[];
    private $SessionService: ISessionService;
    private isLandProduct: boolean;

    constructor($injector: ng.Injectable<any>, $scope) {
        this.$scope = $scope;
        this.$injector = $injector;
        this.$filter = $injector.get('$filter');
        this.operationalService = $injector.get('OperationalService');
        this.$timeout = $injector.get('$timeout');
        this.restService = $injector.get('RestService');
        this.productService = $injector.get('ProductService');
        this.formService = new FormService2($injector, $scope);
        this.modalService = $injector.get('ModalService');
        this.helperService = $injector.get('HelperService');
        this.externalService = $injector.get('ExternalService');
        this.$newProcessScope = <INewProcessScope>$scope.$parent.$parent;
        this.$sce = this.$newProcessScope.$sce;
        this.$compile = this.$newProcessScope.$compile;
        this.$scope.dateOptionsEffectiveDate = {
            maxDate: this.getTomorrowDate(),
            minDate: new Date(1970, 1, 1)
        };
        this.$SessionService = $injector.get('SessionService');
        this.isLandProduct = false;
        this.initScopeFunctions();
    }

    $onInit = async () => {
        try {
            this.initModel();
            const hasProcess = this.$scope.process;
            if (!hasProcess) return this.formService.handleError("ProcessId não informado ou inexistente.");

            //INIT FORM SERVICE
            this.formService.initStandAlone('processEventForm');

            this.formService.block();

            this.processEventHelper = new ProcessEventHelperController(this.$injector);

            switch (this.$scope.process.PRODUCT.ID) {
                case EProductId.MARITIME_EXPORT:
                case EProductId.MARITIME_IMPORT:
                    await this.getStopOver(this.$scope.eventModal);
                    break;
                case EProductId.AIR_EXPORT:
                case EProductId.AIR_IMPORT:
                    await this.getFlightRoute(this.$scope.eventModal);
                    break;
                case EProductId.ROAD_EXPORT:
                case EProductId.ROAD_IMPORT:
                case EProductId.ROAD_NATIONAL:
                    this.$scope.isRoad = true;
                    await this.getRoadRoute(this.$scope.eventModal);
                    break;
                default:
                    this.formService.handleWarning('No product id informed.');
                    break;
            }

            await this.initEvents();
            this.initCollapseEvents();

            if (this.$scope.operation == EOperation.VIEW) this.formService.loadViewForm();

            // New voyage structure. Voyage got by Inttra Ocean Schedule.
            this.providerListForInttraVoyage = [];
            this.externalShipowners = [];
            if (this.$scope.process.GET_VOYAGE_BY_INTEGRATION) {
                this.providerListForInttraVoyage = await this.getProviderScacForVoyage();
                this.externalShipowners = await this.getCodeIntegrationForShipowners();
            }

            if (this.$scope.operation == EOperation.EDIT && !this.isLandProduct) this.$scope.checkVoyage();

        } catch (ex) {
            this.formService.handleError('Erro ao carregar Eventos do Processo');
        } finally {
            this.formService.unblock();
        }
    }

    private getTomorrowDate(): Date {
        const today = new Date();
        today.setDate(today.getDate() + 1);
        return today;
    }

    private initModel(): void {
        this.$scope.eventModifiedList = null;
        this.$scope.eventModifiedResponsibleList = null;
        this.$scope.isRequiredJustification = false;
        this.$scope.isRequiredEffectiveReason = true;
        this.$scope.isRequiredForecastReason = true;
        this.$scope.isValidEventConfiguration = true;
        this.$scope.transportMode = {
            vessel: null,
            stopover: null,
        }
        this.$scope.disableTravelWizard = false;
    }

    private initScopeFunctions(): void {
        this.$scope.openFollowUpModal = async function (modalParams?: IFollowUpModalParams[]) {
            this.followUpTriggerData = [];
            for (let modalInfo of modalParams) {
                this.modalService.showModalFollowUpProcess({
                    modalID: this.$scope.modalID,
                    template: require("../view/followUpProcess.html"),
                    scope: this.$scope,
                    animation: true,
                    keyboard: true,
                    modalFade: true,
                    size: 'full',
                    params: modalInfo.params,
                    processes: modalInfo.process,
                },
                    {
                        actionButtonText: 'Cancelar',
                    }
                );
            }
        }

        this.$scope.getLocalByType = async (name: string, localType: string) => {
            if (name.length > 2) {
                try {
                    this.formService.block();
                    const modalScope = await this.modalService.getModalScope(this.$scope.eventDetailModalID);
                    const result = await this.getLocalByType(localType, name);
                    if (result) modalScope.localList = result;
                } catch (ex) {
                    this.formService.handleError(ex);
                } finally {
                    this.formService.unblock();
                }
            }
        }

        this.$scope.getTerminal = async (name: string) => {
            if (name.length > 2) {
                try {
                    this.formService.block();
                    const modalScope = await this.modalService.getModalScope(this.$scope.eventDetailModalID);
                    const result = await this.getTerminal(name);
                    if (result) modalScope.terminalList = result;
                } catch (ex) {
                    this.formService.handleError(ex);
                } finally {
                    this.formService.unblock();
                }
            }
        }

        this.$scope.getSequenceByEvent = async (eventNumber: number): Promise<any> => {
            const route = `/events/sequence/number/${eventNumber}`;
            return this.operationalService.get(route, 10000);
        }

        this.$scope.updateEventSituation = () => {
            this.updateEventSituation();
        };

        this.$scope.changeTransportModeVessel = async (previousVessel: string) => {
            await this.changeTransportModeVessel(previousVessel);
        };

        this.$scope.updateTransportMode = async (newValue: SelectorModel, oldValue: SelectorModel) => {
            await this.updateTransportMode(newValue, oldValue);
        };

        this.$scope.updateEventDetail = async () => {
            await this.updateEventDetail();
        };

        this.$scope.updateEventDate = async (type: string, newValue: Date) => {
            await this.updateEventDate(type, newValue)
        };

        this.$scope.updateEventContainer = (type: string, container: CONTAINER_GROUP) => {
            this.updateEventContainer(type, container);
        };

        this.$scope.updateEventTruck = (type: string, vehicle: IVehicleTypeDateFrontEnd, processVehicleTypeEventList: IVehicleTypeDateFrontEnd[]) => {
            this.updateEventTruck(type, vehicle, processVehicleTypeEventList);
        }

        this.$scope.updateEventDateTruck = (eventToSet: NewProcessEvent, type: string, date: Date, processVehicleTypeEventList: IVehicleTypeDateFrontEnd[]) => {
            this.updateEventDateTruck(eventToSet, type, date, processVehicleTypeEventList);
        }

        this.$scope.stopoverWizard = async (operation: string) => {
            this.stopoverWizard(operation);
        }

        this.$scope.collapseHeader = (elementId: string, origin: string) => {
            if (origin == "MJ") {
                if (elementId !== "ALL") {
                    if ($("#" + elementId).css('display') == 'none') {
                        $("#" + elementId).toggle(20);
                    }
                } else {
                    $(".toggle-me").removeClass("block").toggle();
                }

            } else {
                $("#" + elementId).toggle(20);
            }
        }

        this.$scope.viewEventLog = async (entity: NewProcessEvent) => {
            try {
                const process = this.$scope.process;

                if (this.$scope.eventLog && this.$scope.eventLog.show) {
                    this.$scope.eventLog.show = false;
                    return;
                }

                this.formService.block();

                let log: IViewLog = {
                    operation: 'history',
                    number: entity.EVENT_NUMBER + '-' + process['PROCESS_NUMBER'],
                    list: [],
                    show: true,
                    searchQuery: '',
                    originalList: [],
                    showCloseButton: false
                }

                const response = await this.operationalService.get(`/process/viewLog/15000}/${log.number}`, null);

                log.list = response && response.data && response.data.data;
                log.originalList = angular.copy(log.list);
                log.showCloseButton = true;
                this.$scope.eventLog = log;
            } catch (ex) {
                this.formService.handleError(ex);
            } finally {
                this.formService.unblock();
            }
        }

        this.$scope.getFlights = async (flightCode: string) => {
            return this.getFlights(flightCode);
        }

        this.$scope.updateProviderAndFlightCode = async (selectedFlight: IFlightSpecific) => {
            return this.updateProviderAndFlightCode(selectedFlight);
        }

        this.$scope.updateSpecificFlightCode = async (selectedSpecificAirFlight) => {
            return this.updateSpecificFlightCode(selectedSpecificAirFlight);
        }

        this.$scope.insertSpecificFlightFromEvent = async (specificFlightEstimatedDate: string) => {
            this.insertSpecificFlightFromEvent(specificFlightEstimatedDate);
        }

        this.$scope.flightWizard = async (operation: string) => {
            this.flightWizard(operation);
        }

        this.$scope.specificFlightWizard = async (operation: string) => {
            this.specificFlightWizard(operation);
        }

        this.$scope.updateFlightTransportMode = async (newValue: IFlightSpecific) => {
            this.updateFlightTransportMode(newValue);
        }

        this.$scope.isPreProcess = (): boolean => {
            return this.$scope.process && this.$scope.process.SITUATION && this.$scope.process.SITUATION.ID === EProcessSituation.PRE_PROCESS;
        };

        this.$scope.disableFieldsPreProcessByEvent = (): boolean => {
            return this.$scope.isPreProcess() && [EEventType.PICK_UP, EEventType.LOAD, EEventType.DISCHARGE, EEventType.FINAL_DESTINATION, EEventType.DELIVERY, EEventType.PLACE_OF_RECEIPT].includes(this.$scope.eventModal && this.$scope.eventModal.EVENT_TYPE && this.$scope.eventModal.EVENT_TYPE.ID as EEventType);
        }

        this.$scope.refreshWithDelay = (search: string, ms: number): void => {
            if (search.length >= 3) {
                this.$timeout(() => { this.getTransportFromOceanSchedule() }, ms);
            }
        }

        this.$scope.applyTransportModeToEventFromInttra = async (selectedTransportModeStopover: ISelectorModel): Promise<void> => {
            try {
                const selectedOption = this.$scope.oceanScheduleOptions && this.$scope.oceanScheduleOptions.length > 0 && selectedTransportModeStopover ? this.$scope.oceanScheduleOptions.find(x => x['ID'] == selectedTransportModeStopover.ID) : null;

                if (selectedOption && !selectedOption.imoNumber) {
                    throw new Error('No imoNumber founded for the selected option.');
                }

                await this.processEventHelper.applyTransportModeToEventFromInttra(this.$scope.eventModal, selectedTransportModeStopover, this.$scope.oceanScheduleOptions, this.$scope.process, this.$scope.oceanScheduleDeadline);
            } catch (ex) {
                this.formService.handleError(ex);
            }
        }

        this.$scope.openVoyage = async (operation: string) => {
            await this.openVoyage(operation);
        }

        this.$scope.checkVoyage = async () => {
            return await this.checkVoyage();
        }
    }

    private async updateOperation(operation: string) {
        this.$scope.operation = operation;

        //update process view event part
        if (operation === EOperation.VIEW) this.formService.loadViewForm();
        else if (operation === EOperation.EDIT) this.formService.loadEditForm();
    }

    private async initEvents() {
        this.$scope.eventList = [];
        let events = null;
        try {
            if (this.$scope.process == null || this.$scope.process == undefined) {
                events = await this.getEventsByProcessId(this.$scope.process);
                this.$scope.eventList = events && events.data && events.data.data && events.data.data[0]['EVENT'];
            } else {
                events = await this.getEventsByProcessId(this.$scope.process._id);
                this.$scope.eventList = events && events.data && events.data.data && events.data.data[0]['EVENT'];
            };

            if (this.$scope.eventList && this.$scope.eventList.length > 0) this.$scope.process.EVENT = this.$scope.eventList;
            else return this.formService.handleError("Nenhum evento encontrado!");

            // situation list
            let result = await this.getGenericValuesByType('process_event_situation');
            if (!result) return this.formService.handleError("Falha ao carregar dados genéricos: process_event_situation");
            this.$scope.situationList = result;

            let resultProcessEventTransportModeList = await this.getProcessEventTransportModeList();
            if (!resultProcessEventTransportModeList) return this.formService.handleError("Falha ao carregar dados genéricos: process_event_transport_mode");
            this.$scope.processEventTransportModeList = resultProcessEventTransportModeList;

            this.$scope.process.EVENT = this.$scope.eventList.sort((function (a, b) {
                if (a.SEQUENCE && b.SEQUENCE) {
                    if (a.SEQUENCE > b.SEQUENCE) return 1;
                    if (a.SEQUENCE < b.SEQUENCE) return -1;
                }
            })
            );

        } catch (ex) {
            throw ex;
        }
    }

    private async getLocalByType(type, name) {
        if (!type) return;

        let result: [];
        const productReq = {
            route: `/routingPoint/list/custom`,
            data: { name: name, types: [type], sysConvertIdToString: true }
        }
        const routingPoints = await this.productService.post<any>(productReq);
        result = routingPoints.data && routingPoints.data.data ? routingPoints.data.data.data.map(x => {
            return {
                ID: x.ID,
                NAME: x.NAME,
                CODE: x.CODE,
                DISPLAY_NAME: x.DISPLAY_NAME
            }
        }) : null;

        return result
    }

    private async getTerminal(name) {
        let result: [];
        const productReq = {
            route: `/legalPerson/list/custom/operational`,
            data: { specializations: [ELegalPersonSpecializationId.PORT_TERMINAL], search: name, sysConvertIdToString: true }
        }
        const legalPersons = await this.productService.post<any>(productReq);
        result = legalPersons.data.data ? legalPersons.data.data.map(x => {
            return {
                ID: x.ID, NAME: x.NAME, CODE: x.CODE, ADDRESS: x.ADDRESS && x.ADDRESS.CORRESPONDENCE ? x.ADDRESS.CORRESPONDENCE : ''
            }
        }) : null;

        return result
    }

    private async getEventsByProcessId(id) {
        return this.operationalService.get("/event/list/processId/" + id, null, 10000);
    }

    private async getServiceProvidersList(): Promise<IServiceProvider[]> {
        let result: IServiceProvider[];
        const productReq = {
            route: `/provider/list/custom/operational`,
            data: { products: [this.$scope.process.PRODUCT.ID], sysConvertIdToString: true }
        }

        const providers = await this.productService.post<any>(productReq);
        result = providers.data.data ? providers.data.data.map(x => {
            return {
                ...x,
                NAME: x.TRADING_NAME,
                CODE: x.SCAC,
                TYPE: x.TYPE
            }
        }) : null;

        return result
    }

    private initCollapseEvents() {
        const collapseTerminal = angular.element('#collapseTerminal');
        if (collapseTerminal) {
            collapseTerminal.on('shown.bs.collapse', (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#terminalBadge").hide();
                }
            });
            collapseTerminal.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#terminalBadge").show();
                }
            });
        }

        const collapseTerminalRedirect = angular.element('#collapseTerminalRedirect');
        if (collapseTerminalRedirect) {
            collapseTerminalRedirect.on('shown.bs.collapse', (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#terminalRedirectBadge").hide();
                }
            });
            collapseTerminalRedirect.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#terminalRedirectBadge").show();
                }
            });
        }
    }

    private updateEventSituation() {
        try {
            const updatedSituation = this.processEventHelper.getEventSituation(this.$scope.eventModal);
            if (updatedSituation) this.$scope.eventModal.SITUATION = updatedSituation;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async updateEventDate(type: string, forcedEventDate?: Date) {
        try {
            const updatedEvent = await this.processEventHelper.handleEventContainerDateChange(this.$scope.eventModal, type, forcedEventDate);
            if (updatedEvent) this.$scope.eventModal = updatedEvent;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async updateEventContainer(type: string, container?: CONTAINER_GROUP) {
        try {
            const updatedEvent = await this.processEventHelper.setEventDateBasedOnContainers(this.$scope.eventModal, type, container);
            if (updatedEvent) this.$scope.eventModal = updatedEvent;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private clearEventContainersDate(): void {
        if (!this.$scope.eventModal.CONTAINER_GROUP || this.$scope.eventModal.CONTAINER_GROUP.length === 0) return;

        for (let i = 0; i < this.$scope.eventModal.CONTAINER_GROUP.length; i++) {
            this.$scope.eventModal.CONTAINER_GROUP[i].FORECAST_DATE = null;
            this.$scope.eventModal.CONTAINER_GROUP[i].EFFECTIVE_DATE = null;
        }
    }

    private async changeTransportModeVessel(previousVessel?: string): Promise<void> {
        try {
            const vessel: SelectorModel = this.$scope.transportMode.vessel;
            const stopover: SelectorModel = this.$scope.transportMode.stopover;
            const transportModesByVessel: ITransportModeByVessel[] = this.$scope.transportModesByVessel;
            const vesselStopovers = (vessel && transportModesByVessel) ? transportModesByVessel.find(x => x.vessel && x.vessel.ID === vessel.ID) : transportModesByVessel.find(x => x.vessel === null);
            if (!vesselStopovers) return;

            if (previousVessel && stopover) {
                const cleaned = await this.confirmAndHandleTransportModeClean();
                if (!cleaned) {
                    this.$scope.transportMode.vessel = JSON.parse(previousVessel);
                    return;
                }
                this.$scope.transportMode.stopover = null;
                this.$scope.stopoverList = [];
                this.$scope.allTransportModes = [];
            }

            let stopoverList: SelectorModel[] = [];

            if (vessel) {
                this.$scope.allTransportModes = angular.copy(vesselStopovers.stopovers);

                if (!this.$scope.process.GET_VOYAGE_BY_INTEGRATION) {
                    stopoverList = (vesselStopovers) ? vesselStopovers.stopovers
                        .filter(x => x.stopoverSituation.NAME !== 'Finalizado')
                        .map(x => {
                            const stopoverSituation = (x.stopoverSituation.NAME === 'Sugerida') ? x.stopoverSituation.NAME : `<b><i>${x.stopoverSituation.NAME}</i></b>`;
                            const eta = `ETA: ${(x.estimatedDate) ? this.$filter('date')(x.estimatedDate, "dd/MM/yyyy") : '<b>Sugerida</b>'}`;
                            const shipowners = x.carriers.reduce((val, acc, i) => { return (acc.compatible) ? `${val} | <b><i>${acc.description}</i></b>` : `${val} | ${acc.description}` }, '')
                            return { ID: x.stopover.ID, NAME: `${x.vessel.NAME} | ${stopoverSituation} | ${eta} ${shipowners}` }
                        }) : [];
                } else {
                    stopoverList = (vesselStopovers) ? vesselStopovers.stopovers.map(x => { return { ID: x.vessel.ID, NAME: x.vessel.NAME, CODE: null } }) : [];
                }
            }

            this.$scope.stopoverList = stopoverList;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async confirmAndHandleTransportModeClean(): Promise<boolean> {
        try {
            const confirmed = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });

            if (confirmed) {
                this.$scope.eventModal.TRANSPORT_MODE = null;
                this.$scope.eventModal.STOPOVER = null;
                this.$scope.eventModal.VIA = null;
                this.$scope.eventModal.FORECAST_DATE = null;
                this.$scope.eventModal.EFFECTIVE_DATE = null;
                this.$scope.eventModal.MODIFIED_TYPE = null;
                this.$scope.eventModal.MODIFIED_RESPONSIBLE = null;
                this.$scope.isRequiredJustification = true;
                this.$scope.eventModal.SITUATION = this.$scope.situationList.find(x => x.ID === '1');
                this.clearEventContainersDate();
                return true;
            } else {
                return false;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async updateTransportMode(newValue: any, oldValue: any): Promise<void> {
        try {
            this.followUpTriggerData = [];
            const isLoadEvent = this.$scope.eventModal && this.$scope.eventModal.EVENT_TYPE && this.$scope.eventModal.EVENT_TYPE.ID == EEventType.LOAD;

            if (!newValue) {
                const cleaned = await this.confirmAndHandleTransportModeClean();
                if (!cleaned) this.$scope.eventModal.TRANSPORT_MODE = JSON.parse(oldValue);
                return;
            }

            const modalScope = await this.modalService.getModalScope(this.$scope.eventDetailModalID);
            if (this.$scope.eventModal.FORECAST_DATE && this.$scope.eventModal.EFFECTIVE_DATE) {
                const confirmed = await this.modalService.showModalConfirmation({}, {
                    actionButtonText: 'GENERAL.CONFIRM',
                    headerText: 'GENERAL.CONFIRM_ACTION',
                    bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
                });
                if (confirmed) {
                    this.$scope.eventModal.FORECAST_DATE = null;
                    this.$scope.eventModal.EFFECTIVE_DATE = null;
                    this.clearEventContainersDate();

                } else {
                    this.$scope.eventModal.TRANSPORT_MODE = JSON.parse(oldValue);
                    return;
                }
            }

            let serviceProvider = null;
            const selectedVessel: SelectorModel = this.$scope.transportMode.vessel;
            const selectedStopover: SelectorModel = this.$scope.transportMode.stopover;

            if (!selectedStopover) return;

            if (selectedVessel) {
                // has ship, not default transport mode
                const transportModesByVesselList: ITransportModeByVessel[] = this.$scope.transportModesByVessel;
                const selectedTransportModeByVessel = (selectedVessel && transportModesByVesselList) ? transportModesByVesselList.find(x => x.vessel && x.vessel.ID === selectedVessel.ID) : transportModesByVesselList.find(x => x.vessel === null);
                if (!selectedTransportModeByVessel) return;

                const transportModeStopovers = selectedTransportModeByVessel.stopovers;
                const selectedTransportModeStopover = transportModeStopovers.find(x => x.stopover.ID === selectedStopover.ID);
                if (selectedTransportModeStopover) {

                    const compatibleServiceProvider = (selectedTransportModeStopover.carriers.find(x => x.carrierInformation.CARRIER.ID == this.$scope.process.SERVICE_PROVIDER.ID));
                    const validSituationAndETA = (selectedTransportModeStopover.estimatedDate && selectedTransportModeStopover.stopoverSituation.NAME !== 'Sugerida');

                    if (!compatibleServiceProvider || !validSituationAndETA) {
                        this.$scope.isValidEventConfiguration = false;
                        this.$scope.isRequiredJustification = false;
                        // invalid transport mode, clear dates
                        this.$scope.eventModal.FORECAST_DATE = null;
                        this.$scope.eventModal.EFFECTIVE_DATE = null;
                        this.clearEventContainersDate();
                        //
                        return;
                    } else
                        this.$scope.isValidEventConfiguration = true;

                    serviceProvider = compatibleServiceProvider.carrierInformation.CARRIER;
                    await this.processEventHelper.applyTransportModeToEvent(this.$scope.eventModal, selectedTransportModeStopover, this.$scope.process.SERVICE_PROVIDER.ID);
                    selectedTransportModeStopover.effectiveDate ? this.$scope.isRequiredEffectiveReason = false : true;
                    selectedTransportModeStopover.estimatedDate ? this.$scope.isRequiredForecastReason = false : true;
                }
            } else {
                // default transport modes
                await this.processEventHelper.applyDefaultTransportModeToEvent(this.$scope.eventModal, selectedStopover);
            }

            this.formService.block();

            //enable/disable the service provider selector
            if (modalScope) modalScope.eventServiceProviderSelector = !!(serviceProvider);

            if (isLoadEvent) {
                if (!this.$scope.eventModal.MODIFIED_TYPE || (this.$scope.eventModal.MODIFIED_TYPE && this.$scope.eventModal.MODIFIED_TYPE.ID != '0')) {
                    this.$scope.isRequiredJustification = true;
                }
                this.$scope.eventModal.MODIFIED_TYPE = null;

                let hasCargoReadinessHistory = false;
                hasCargoReadinessHistory = await this.checkCargoReadinessHistory();
                if (this.$scope.process.CARGO_TYPE.ID == ECargoTypeId.FCL && hasCargoReadinessHistory) {
                    this.$scope.isRequiredJustification = true;
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async updateEventContainersConfirmationModal(dataType: string, newValue: Date): Promise<boolean> {
        try {
            return this.modalService.showModalConfirmation({}, {
                headerText: `Atualizar Containers do Evento ${this.$scope.eventModal.EVENT_NUMBER}`,
                bodyText: `Deseja sobrescrever a data ${dataType} de todos os containers ou somente os containers que não tem uma data ${dataType}?<br><br> 
                   <span>Data ${dataType}: <b>${newValue}</b></span><br>`
            });

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getModifiedReasonModal(generic: string, dateType: string): Promise<boolean> {
        let isModifiedOk = false;
        let result = null;
        const processEvent: NewProcessEvent = this.$scope.eventModal;
        if (processEvent) {
            result = await this.getGenericValuesByType(generic);
            if (result) this.$scope.eventModifiedList = result;
            result = await this.getGenericValuesByType('file_specs');
            if (result) this.$scope.eventModifiedResponsibleList = result;
        }

        this.$scope.modifiedReasonModal = { reason: null };

        try {
            const body = `
            <div class="row">
                <div class="col-lg-6">
                <label>` + this.formService.getTranslate('OPERATIONAL.UPDATE_REASON') + `</label>
                    <ui-select name="modifiedReason" id="modifiedReason"
                        ng-model="modifiedReasonModal.reason"
                        theme="bootstrap"
                        ng-change="selectorValidity(this.$select.ngModel.$name);"
                        ng-disabled="selectorDisabled(this.$select.ngModel.$name) || operation == 'view'"
                        ng-click="selectorFocus(this.$select.searchInput[0]);"
                        skip-focusser="true" required>
                        <ui-select-match placeholder="Selecione...">
                            {{$select.selected.NAME}}
                        </ui-select-match>
                        <ui-select-choices
                            repeat="item in eventModifiedList | filter: $select.search track by $index">
                            <div
                                ng-bind-html="item.NAME | highlight: $select.search">
                            </div>
                        </ui-select-choices>
                        <ui-select-no-choice>
                            {{getEmptySelectorMsg()}}
                        </ui-select-no-choice>
                    </ui-select>
                </div>
                <div class="col-lg-6">
                        <label>` + this.formService.getTranslate('GENERAL.RESPONSIBLE') + `</label>
                        <ui-select name="responsibleModifiedSelector" id="responsibleModifiedSelector" ng-model="eventModal.MODIFIED_RESPONSIBLE"
                            theme="bootstrap" ng-change="selectorValidity(this.$select.ngModel.$name);"
                            ng-click="selectorFocus(this.$select.searchInput[0])"
                            ng-disabled="selectorDisabled(this.$select.ngModel.$name)" skip-focusser="true"
                            ng-required="isRequiredJustification">
                            <ui-select-match allow-clear="true">{{$select.selected.ID}} -
                                {{$select.selected.NAME}}
                            </ui-select-match>
                            <ui-select-choices
                                repeat="item in eventModifiedResponsibleList | filter: $select.search track by $index">
                                <div ng-bind-html="item.ID + ' - ' + item.NAME | highlight: $select.search">
                                </div>
                            </ui-select-choices>
                        </ui-select>
                    </div>
            </div>
            `;

            const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                {
                    modalID: this.modalDateModifiedTypeId,
                    scope: this.$scope,
                    formService: 'register',
                    size: 'md',
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing" && closed) {
                            if (!this.$scope.modifiedReasonModal || (this.$scope.modifiedReasonModal && !this.$scope.modifiedReasonModal.reason)) {
                                event.preventDefault();
                                this.formService.notifyError("It is necessary to inform the reason to continue.");
                            }
                        }
                    }
                },
                {
                    closeButtonText: 'Cancelar',
                    actionButtonText: 'Confirmar',
                    headerText: `Informe o motivo da alteração na data ${dateType}`,
                    bodyText: this.$sce.trustAsHtml(body),

                }
            );

            modalInstance.rendered.then(() => {
                const modifiedReasonSelect = angular.element("#modifiedReason");
                if (modifiedReasonSelect) this.$compile(modifiedReasonSelect)(this.$scope);
                const responsibleModifiedSelector = angular.element("#responsibleModifiedSelector");
                if (responsibleModifiedSelector) this.$compile(responsibleModifiedSelector)(this.$scope);
            });

            isModifiedOk = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });
            if (isModifiedOk) {
                if (this.$scope.modifiedReasonModal && this.$scope.modifiedReasonModal.reason && generic == "modified_event_forecast_date") {
                    this.$scope.eventModal.FORECAST_DATE_EDITION_REASON = this.$scope.modifiedReasonModal.reason;
                }
                if (this.$scope.modifiedReasonModal && this.$scope.modifiedReasonModal.reason && generic == "modified_event_effective_date") {
                    this.$scope.eventModal.EFFECTIVE_DATE_EDITION_REASON = this.$scope.modifiedReasonModal.reason;
                }
            }

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            if (isModifiedOk) this.eventDetailBeforeEdit = angular.copy(this.$scope.eventModal);
            else this.$newProcessScope.collapseState.nextState = null;
            return isModifiedOk;
        }
    }

    private async updateEventDetail() {
        try {
            //clear triggers, cause they're only used here so far
            this.followUpTriggerData = [];
            this.$scope.isRequiredJustification = false;

            let openEstimated = true;
            delete this.$scope.eventModal['NEW'];
            const processEvent: NewProcessEvent = this.$scope.eventModal;

            const eventEffectiveDate = processEvent.EFFECTIVE_DATE;
            const oldEventEffectiveDate = this.eventDetailBeforeEdit ? this.eventDetailBeforeEdit.EFFECTIVE_DATE : null;
            let sameEffective = moment(eventEffectiveDate).isSame(oldEventEffectiveDate);
            let hasEffectiveChange = false;

            const eventForecastDate = processEvent.FORECAST_DATE;
            const oldEventForecastDate = this.eventDetailBeforeEdit ? this.eventDetailBeforeEdit.FORECAST_DATE : null;
            let sameForecast = moment(eventForecastDate).isSame(oldEventForecastDate);
            let hasForecastChange = false;

            if (!sameEffective) {
                if (eventEffectiveDate || oldEventEffectiveDate) {
                    this.$scope.eventModal.EFFECTIVE_DATE_ORIGIN_TYPE = eProcessEventDateStatus.MANUAL;
                    this.$scope.eventModal.EFFECTIVE_DATE_EDITION_REASON = null;
                    hasEffectiveChange = true;
                }
            }

            if (!sameForecast) {
                if (eventForecastDate || oldEventForecastDate) {
                    this.$scope.eventModal.FORECAST_DATE_ORIGIN_TYPE = eProcessEventDateStatus.MANUAL;
                    this.$scope.eventModal.FORECAST_DATE_EDITION_REASON = null;
                    hasForecastChange = true;
                }
            }

            if (this.$scope.eventModal.VEHICLE_TYPE_DATE) {
                for (const vehicleTypeDate of this.$scope.eventModal.VEHICLE_TYPE_DATE) {
                    const updated = this.$scope.processVehicleTypeEventList.find(vehicle => vehicle.PROCESS_VEHICLE_TYPE_ID == vehicleTypeDate.PROCESS_VEHICLE_TYPE_ID);
                    vehicleTypeDate.EFFECTIVE_DATE = updated.EFFECTIVE_DATE;
                    vehicleTypeDate.FORECAST_DATE = updated.FORECAST_DATE;
                }
            }

            if ((this.$scope.process.PRODUCT.ID == EProductId.MARITIME_EXPORT || this.$scope.process.PRODUCT.ID == EProductId.MARITIME_IMPORT) && hasForecastChange && processEvent.EVENT_EDI_SETUP !== null && this.$scope.isRequiredForecastReason && !this.$scope.process.GET_VOYAGE_BY_INTEGRATION) {
                this.checkFollowUpTrigger(processEvent.EVENT_NUMBER, 'FORECAST_DATE', openEstimated);
                await this.getModifiedReasonModal("modified_event_forecast_date", "PREVISTA")
            }

            if ((this.$scope.process.PRODUCT.ID == EProductId.MARITIME_EXPORT || this.$scope.process.PRODUCT.ID == EProductId.MARITIME_IMPORT) && hasEffectiveChange && processEvent.EVENT_EDI_SETUP !== null && this.$scope.isRequiredEffectiveReason) {
                this.checkFollowUpTrigger(processEvent.EVENT_NUMBER, 'EFFECTIVE_DATE');
                openEstimated = false;
                await this.getModifiedReasonModal("modified_event_effective_date", "EFETIVA")
            }

            const timeout = 120000;
            const request = {
                processNumber: this.$scope.process.PROCESS_NUMBER,
                event: this.$scope.eventModal,
                timeout: timeout
            };

            this.formService.block();
            const result = await this.operationalService.post("/event/update", request, timeout);
            if (result.status !== 200 || !result.data || !result.data['data']) {
                //FAILED
                const errorData = HandleError.buildErrorMessage(result);
                const msg = `${this.formService.getTranslate("OPERATIONAL.FAILED_TO_UPDATE_EVENTS")}: ${errorData.msg}`;
                this.formService.notifyError(msg);
                return this.formService.unblock();
            }

            if ((this.$scope.process.PRODUCT.ID == EProductId.MARITIME_EXPORT || this.$scope.process.PRODUCT.ID == EProductId.MARITIME_IMPORT)
                && this.$scope.eventModal.TRANSPORT_MODE && this.$scope.eventModal.TRANSPORT_MODE.ID && this.$scope.process.GET_VOYAGE_BY_INTEGRATION) {
                const event = result.data.data.EVENT.find(x => x.EVENT_ID == this.$scope.eventModal.EVENT_ID);
                const requestNewVoyageDeadline = {
                    voyageId: this.$scope.eventModal.TRANSPORT_MODE.ID,
                    stopoverId: event.STOPOVER.ID,
                    processNumber: this.$scope.process.PROCESS_NUMBER,
                    currentEvent: this.$scope.eventModal,
                    deadlines: this.$scope.process.DEADLINES,
                    timeout
                };

                const newVoyageDeadlineResult = await this.operationalService.post("/voyage/updateDeadline", requestNewVoyageDeadline, timeout);

                if (newVoyageDeadlineResult.status == 200) {
                    const msg = this.formService.getTranslate("OPERATIONAL.DEADLINES_SUCESSFULLY_UPDATED");
                    this.formService.notifySuccess(msg);
                }
            }

            if (result.data.data != true) { //when nothing changed, return true

                //main operation response and followup modal return
                const response: Process = result.data.data;

                this.$scope.eventList = response.EVENT;
                this.$scope.process.EVENT = this.$scope.eventList;
                this.$scope.process.SITUATION = response.SITUATION;
                this.$scope.process.TTIME = response.TTIME;
                this.$scope.process.TTIME_DIFFERENCE = response.TTIME_DIFFERENCE;

                const msg = this.formService.getTranslate("OPERATIONAL.EVENT_SUCESSFULLY_UPDATED");
                this.formService.notifySuccess(msg);
            }

            this.applyOkModal(this.$scope.process);
            this.modalService.closeModal(this.$scope.eventDetailModalID);
            this.$scope.eventDetailModalID = 0;
            this.$scope.eventModal = null;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private applyOkModal(result?: Process | true): void {
        try {
            this.$scope.modalOptions.ok(result);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getGenericValuesByType(typeGeneric: string) {
        const { data: generic } = await this.helperService.get(`/generic/value/${typeGeneric}`, null, 45000);
        return generic && generic.data ? generic.data : [];
    }

    private async getProcessEventTransportModeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/process_event_transport_mode`, null);
        return generic && generic.data ? generic.data : [];
    }

    private checkFollowUpTrigger(eventNumber: string, changedField: string, openModal?: boolean) {
        this.followUpTriggerData.push({
            openModal: (openModal !== undefined) ? openModal : true,
            referenceType: FollowupReferenceType.EVENT,
            reference: eventNumber,
            referenceDate: (changedField.includes('FORECAST')) ? FollowupReferenceDate.ESTIMATED : FollowupReferenceDate.EFFECTIVE,
            processReferences: [this.$scope.process.PROCESS_NUMBER]
        });
    }

    private async getEventTransportMode(process, event) {
        const timeout = 15000;
        const request = {
            processNumber: process.PROCESS_NUMBER,
            eventId: event.EVENT_ID,
            processId: process._id,
            timeout: timeout
        };
        return this.operationalService.post("/event/transportmode", request, timeout);
    }

    private stopoverWizard(operation: string) {
        try {
            if (operation === 'edit' && !this.$scope.transportMode.stopover) return;
            if (this.$scope.eventDetailModalID && !this.$scope.disableTravelWizard && this.$scope.transportMode.vessel) {
                const stopoverParams: IWizardTravelStopoverParams = {
                    updateEventParentOperation: async () => {
                        await this.initEvents();
                        await this.updateOperation(this.$scope.operation);
                    },
                    blockSteps: true,
                    defaultTabs: this.getDefaultTabs(operation),
                    processNumber: this.$scope.process.PROCESS_NUMBER,
                    processEvents: this.$scope.process.EVENT,
                    processServiceProvider: this.$scope.process.SERVICE_PROVIDER,
                    processProduct: this.$scope.process.PRODUCT,
                    transportMode: this.$scope.transportMode,
                    transportModesByVessel: this.$scope.transportModesByVessel,
                    eventDetailModalID: this.$scope.eventDetailModalID,
                    modalOperation: operation,
                }

                this.wizardStopoverModalID = this.modalService.newModal();
                this.modalService.showModalInfo(
                    {
                        modalID: this.wizardStopoverModalID,
                        template: require("../view/wizardTravelModal.html"),
                        formService: operation,
                        size: 'full modal-overflow',
                    },
                    {
                        actionButtonClass: 'btn-default',
                        actionButtonText: 'GENERAL.CLOSE',
                        headerText: 'OPERATIONAL.VOYAGE_AND_STOPOVER'
                    },
                    stopoverParams
                );
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getDefaultTabs = (modalOperation: string): Array<IDefaultTab> => {
        let defaultTabs: Array<IDefaultTab> = [];
        defaultTabs.push({ id: null, stringId: 'voyage', initialTab: null, label: "Viagem", style: 'text-brown', template: "wizardTravelTemplate" });
        defaultTabs.push({ id: null, stringId: 'stopovers', initialTab: null, label: "Escalas", style: 'text-cyano', template: "wizardTraverStopoverTemplate" });
        if (this.$scope.process.PRODUCT.ID == 'EM') {
            defaultTabs.push({ id: null, stringId: 'deadlines', initialTab: null, label: "DeadLines", style: 'text-green', template: "wizardDeadlinesTemplate" });
        }
        defaultTabs.push({ id: null, stringId: 'confirmation', initialTab: null, label: "Confirmação", style: 'text-green', template: "wizardTravelStopoverConfirmationTemplate" });

        if (modalOperation === 'register') {
            defaultTabs.unshift(
                { id: null, stringId: 'maritimeServices', initialTab: false, label: "Serviço Marítimo", style: 'text-especial', template: "wizardMaritimeServiceTemplate" }
            );
        }

        defaultTabs.forEach((t, index) => t.id = index + 1);

        return defaultTabs;
    }

    private async getStopOver(processEvent: NewProcessEvent): Promise<void> {
        try {
            this.formService.block();

            let result = null;

            this.followUpTriggerData = [];

            this.$scope.transportMode = {
                vessel: null,
                stopover: null,
            };

            const transportModeResult = await this.getEventTransportMode(this.$scope.process, processEvent);
            if (!transportModeResult || !transportModeResult.data) return this.formService.handleError(`<b>Falha</b> ao recuperar meios de transporte para o evento: ${processEvent}`);
            const transportModesByVessel: ITransportModeByVessel[] = transportModeResult.data.data;

            this.$scope.transportModeVesselsList = transportModesByVessel.map(x => x.vessel);
            this.$scope.transportModesByVessel = transportModesByVessel;

            this.changeTransportModeVessel();

            if (processEvent && processEvent.EVENT_TYPE && (processEvent.EVENT_TYPE.ID !== EEventType.PICK_UP && processEvent.EVENT_TYPE.ID !== EEventType.FINAL_DESTINATION)) {
                this.$scope.serviceProviderList = await this.getServiceProvidersList();
            }

            result = await this.getGenericValuesByType('modified_type');
            if (result) this.$scope.eventModifiedList = result;
            result = await this.getGenericValuesByType('file_specs');
            if (result) this.$scope.eventModifiedResponsibleList = result;

            this.$scope.isRequiredJustification = false;
            this.$scope.isValidEventConfiguration = true;

            //copy the event info to be edited on modal            
            this.$scope.eventModal = angular.copy(processEvent);
            this.eventDetailBeforeEdit = angular.copy(processEvent);

            // load current stopover/vessel info if process had it previously 

            if (processEvent.TRANSPORT_MODE && processEvent.STOPOVER) {
                const currentTransportModeByVessel = (transportModesByVessel) ? transportModesByVessel.find(x => x.vessel && x.vessel.ID == processEvent.TRANSPORT_MODE.ID) : null;
                this.$scope.transportMode.vessel = (currentTransportModeByVessel) ? currentTransportModeByVessel.vessel : null;

                // update stopover list based on event's current selected ship
                this.changeTransportModeVessel();
                const allTransportModes: ITransportMode[] = this.$scope.allTransportModes;
                const currentlySelectedTransportMode = allTransportModes && allTransportModes.length ? allTransportModes.find(x => x.stopover.ID === processEvent.STOPOVER.ID) : null;

                let currentlySelectedStopover = null;
                if (currentlySelectedTransportMode) {
                    if (!this.$scope.process.GET_VOYAGE_BY_INTEGRATION) {
                        const stopoverSituation = (currentlySelectedTransportMode.stopoverSituation.NAME === 'Sugerida') ? currentlySelectedTransportMode.stopoverSituation.NAME : `<b><i>${currentlySelectedTransportMode.stopoverSituation.NAME}</i></b>`;
                        const eta = `ETA: ${(currentlySelectedTransportMode.estimatedDate) ? this.$filter('date')(currentlySelectedTransportMode.estimatedDate, "dd/MM/yyyy") : '<b>Sugerida</b>'}`;
                        const shipowners = currentlySelectedTransportMode.carriers.reduce((val, acc, i) => { return (acc.compatible) ? `${val} | <b><i>${acc.description}</i></b>` : `${val} | ${acc.description}` }, '')
                        currentlySelectedStopover = { ID: currentlySelectedTransportMode.stopover.ID, NAME: `${currentlySelectedTransportMode.vessel.NAME} | ${stopoverSituation} | ${eta} ${shipowners}` }
                    } else {
                        const voyageNumber = processEvent.TRANSPORT_MODE.NAME.split('|')[1];
                        const concatenated: string = `${currentlySelectedTransportMode.vessel.NAME}|${voyageNumber}`;
                        currentlySelectedStopover = { ID: currentlySelectedTransportMode.stopover.ID, NAME: concatenated };
                    }
                }
                this.$scope.transportMode.stopover = currentlySelectedStopover;
            } else if (processEvent.TRANSPORT_MODE) {
                this.$scope.transportMode.stopover = processEvent.TRANSPORT_MODE;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async checkCargoReadinessHistory() {
        let hasCargoHistory: boolean = false;
        const historyRequest = await this.operationalService.post("/cargo/readinessHistory", { idProcess: this.$scope.process.ID });

        if (historyRequest && historyRequest.data && historyRequest.data.data) {
            const historyResult = historyRequest.data.data;
            if (historyResult && historyResult.length > 1) hasCargoHistory = true;
        }
        return hasCargoHistory;
    }

    private async getFlightRoute(processEvent: NewProcessEvent) {
        try {
            if (processEvent && processEvent.TRANSPORT_MODE) {
                let origin = null;
                let destination = null;
                let etd = null;
                let eta = null;
                const rc = await this.operationalService.post(`/airFlight/list/custom`, { id: processEvent.TRANSPORT_MODE.ID, isSpecific: true }, 30000);
                if (rc && rc.status == 200 && rc.data && rc.data.data && rc.data.data.data && rc.data.data.data[0]) {
                    origin = rc.data.data.data[0].ORIGIN;
                    destination = rc.data.data.data[0].DESTINATION;
                    etd = rc.data.data.data[0].ESTIMATED_TIME_DEPARTURE;
                    eta = rc.data.data.data[0].ESTIMATED_TIME_ARRIVAL;
                }

                this.$scope.transportModeSpecificAirFlight = {
                    ID: processEvent.TRANSPORT_MODE.ID,
                    NAME: processEvent.TRANSPORT_MODE.NAME,
                    DESTINATION: destination,
                    ORIGIN: origin,
                    PROVIDER: processEvent.SERVICE_PROVIDER,
                    SPECIFIC_FLIGHT_CODE: processEvent.TRANSPORT_MODE.CODE,
                    SPECIFIC_DATE: processEvent.FORECAST_DATE ? new Date(processEvent.FORECAST_DATE) : null,
                    SPECIFIC_DATE_DISPLAY: moment(processEvent.FORECAST_DATE).format('DD/MM/YYYY HH:mm'),
                    SPECIFIC: {
                        ESTIMATED_TIME_ARRIVAL: eta,
                        ESTIMATED_TIME_DEPARTURE: etd
                    }
                };
                this.getSpecificFlightByDate(this.$scope.transportModeSpecificAirFlight.NAME, this.$scope.transportModeSpecificAirFlight.PROVIDER);
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getFlights(flightCode: string): Promise<void> {
        try {
            this.formService.block();

            let result = null;
            if (this.$scope.eventModal && this.$scope.eventModal.LOCAL && this.$scope.eventModal.LOCAL.CODE && flightCode && flightCode.length >= 3) {
                let local = this.$scope.eventModal && this.$scope.eventModal.LOCAL && this.$scope.eventModal.LOCAL.CODE ? this.$scope.eventModal.LOCAL.CODE : null;
                let searchType = this.getDirectionBasedOnEventType();
                const rc = await this.operationalService.post(`/airFlight/list/custom`, { flightCode, local, searchType, isSpecific: false }, 30000);
                if (rc && rc.status == 200 && rc.data && rc.data.data && rc.data.data.data) {
                    result = rc.data.data.data.map(x => {
                        return {
                            ID: x.ID,
                            NAME: x.FLIGHT_CODE,
                            PROVIDER: x.PROVIDER,
                        }
                    });
                }
            }

            this.$scope.flightsList = result;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private initModelTransportModeSpecific() {
        this.$scope.transportModeSpecificAirFlight = {
            ID: null,
            NAME: null,
            ORIGIN: null,
            DESTINATION: null,
            PROVIDER: null,
            SPECIFIC_DATE: null,
            SPECIFIC_DATE_DISPLAY: null,
            SPECIFIC_FLIGHT_CODE: null,
            SPECIFIC: {
                ESTIMATED_TIME_ARRIVAL: null,
                ESTIMATED_TIME_DEPARTURE: null
            }
        }
    }

    private async updateProviderAndFlightCode(selectedFlight: IFlightSpecific): Promise<void> {
        if (!this.$scope.transportModeSpecificAirFlight) this.initModelTransportModeSpecific();

        this.$scope.transportModeSpecificAirFlight.NAME = selectedFlight ? selectedFlight.NAME : null;
        this.$scope.transportModeSpecificAirFlight.PROVIDER = selectedFlight ? selectedFlight.PROVIDER : null;
        this.$scope.transportModeSpecificAirFlight.ID = selectedFlight.ID ? selectedFlight.ID : null;

        if (!selectedFlight || (selectedFlight.NAME != this.$scope.transportModeSpecificAirFlight.NAME)) {
            this.$scope.transportModeSpecificAirFlight.SPECIFIC_FLIGHT_CODE = null;
            this.$scope.transportModeSpecificAirFlight.SPECIFIC_DATE = null;
        }

        this.$scope.eventModal.SERVICE_PROVIDER = selectedFlight && selectedFlight.PROVIDER ? selectedFlight.PROVIDER : null;
        this.getSpecificFlightByDate(selectedFlight.NAME, selectedFlight.PROVIDER);
    }

    private async getSpecificFlightByDate(flightCode: string, provider: ISelectorModel): Promise<void> {
        try {
            this.formService.block();

            let result = null;
            let local = this.$scope.eventModal && this.$scope.eventModal.LOCAL && this.$scope.eventModal.LOCAL.CODE ? this.$scope.eventModal.LOCAL.CODE : null;
            let searchType = this.getDirectionBasedOnEventType();

            if (this.$scope.eventModal && this.$scope.eventModal.LOCAL && this.$scope.eventModal.LOCAL.CODE && flightCode && flightCode.length >= 3) {
                const rc = await this.operationalService.post(`/airFlight/getBaseFlightForEvent`, { flightCode, local, searchType, airLine: provider.ID }, 30000);
                if (rc && rc.status == 200 && rc.data && rc.data.data && rc.data.data.data) {
                    result = rc.data.data.data.map(x => {
                        return {
                            ID: x.ID,
                            NAME: x.FLIGHT_CODE,
                            ORIGIN: x.ORIGIN,
                            DESTINATION: x.DESTINATION,
                            ESTIMATED_TIME_ARRIVAL: x.ESTIMATED_TIME_ARRIVAL,
                            ESTIMATED_TIME_DEPARTURE: x.ESTIMATED_TIME_DEPARTURE,
                            SPECIFIC_FLIGHT_CODE: x.SPECIFIC.SPECIFIC_FLIGHT_CODE,
                            PROVIDER: x.PROVIDER,
                            SPECIFIC_DATE: x.ORIGIN && x.ORIGIN.CODE == this.$scope.eventModal.LOCAL.CODE ? new Date(x.SPECIFIC.ESTIMATED_TIME_DEPARTURE) : new Date(x.SPECIFIC.ESTIMATED_TIME_ARRIVAL),
                            SPECIFIC_DATE_DISPLAY: x.ORIGIN && x.ORIGIN.CODE == this.$scope.eventModal.LOCAL.CODE ? moment(x.SPECIFIC.ESTIMATED_TIME_DEPARTURE).format('DD/MM/YYYY HH:mm') : moment(x.SPECIFIC.ESTIMATED_TIME_ARRIVAL).format('DD/MM/YYYY HH:mm')
                        }
                    });
                }
            }
            this.$scope.specificFlightList = result;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async updateSpecificFlightCode(specificFlightCode): Promise<void> {
        try {
            this.$scope.eventModal.TRANSPORT_MODE = {
                ID: specificFlightCode ? specificFlightCode.ID : null,
                NAME: specificFlightCode ? specificFlightCode.NAME : null,
                CODE: specificFlightCode ? specificFlightCode.SPECIFIC_FLIGHT_CODE : null
            }
            this.$scope.transportModeSpecificAirFlight.SPECIFIC_FLIGHT_CODE = specificFlightCode ? specificFlightCode.SPECIFIC_FLIGHT_CODE : null;
            this.$scope.transportModeSpecificAirFlight.SPECIFIC_DATE = specificFlightCode ? specificFlightCode.SPECIFIC_DATE : null;
            this.$scope.eventModal.FORECAST_DATE = specificFlightCode ? specificFlightCode.SPECIFIC_DATE : this.$scope.eventModal.FORECAST_DATE;
            this.$scope.$applyAsync();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async insertSpecificFlightFromEvent(specificFlightEstimatedDate: string): Promise<void> {
        try {
            const estimatedDateAsDate = moment(specificFlightEstimatedDate, 'DD/MM/YYYY', true).isValid();
            if (!estimatedDateAsDate) {
                return this.formService.handleError('Could not convert the sent date. Use this format: "DD/MM/YYYY"');
            }

            const newAirFlight = {
                FLIGHT_CODE: {
                    NAME: this.$scope.transportModeSpecificAirFlight.NAME
                },
                SPECIFIC_DATE: moment(specificFlightEstimatedDate, 'DD/MM/YYYY').toDate()
            };

            const result = await this.operationalService.post(`/airFlight/insertSpecific`, { data: newAirFlight, oldData: null }, 30000);
            if (result && result.data && result.data.data) {
                const newSpecificFlight = result.data.data;
                this.$scope.transportModeSpecificAirFlight.SPECIFIC_FLIGHT_CODE = newSpecificFlight.SPECIFIC.SPECIFIC_FLIGHT_CODE;
                this.setForeCastDateFlight(newSpecificFlight);
                this.updateSpecificFlightCode(this.$scope.transportModeSpecificAirFlight);
                const msgSucess = this.formService.getTranslate("OPERATIONAL.SPECIFIC_AIR_FLIGHT_SUCCESSFULLY_ADDED");
                this.formService.notifySuccess(msgSucess);
                this.$scope.$applyAsync();
            }

            this.getSpecificFlightByDate(this.$scope.transportModeSpecificAirFlight.NAME, this.$scope.transportModeSpecificAirFlight.PROVIDER);
            this.$scope.eventModal.FORECAST_DATE = this.$scope.transportModeSpecificAirFlight.SPECIFIC_DATE;

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            // Used to exit the insert box.
            angular.element('#flightSpecificDate').click();
        }
    }

    private async flightWizard(operation: string): Promise<void> {

        try {
            let apply: IFlightSpecific;
            this.wizardBaseFlightModalId = this.modalService.newModal();
            let modalInstance: IModalInstanceService;
            if (operation == 'register') {
                modalInstance = await this.modalService.showModalRegisterBaseFlight(this.wizardBaseFlightModalId);

            } else if (operation == 'edit') {
                modalInstance = await this.modalService.showModalEditBaseFlight(this.wizardBaseFlightModalId, this.$scope.transportModeSpecificAirFlight.NAME, this.$scope.transportModeSpecificAirFlight.ID);
            }

            apply = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (apply) {
                apply.NAME = apply['FLIGHT_CODE'];
                this.updateProviderAndFlightCode(apply);
                this.setTransportModeSpecificAirFlight(apply);
                this.updateFlightTransportMode(apply);
            }

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async specificFlightWizard(operation: string): Promise<void> {
        try {
            let apply: IAirFlight;
            this.wizardSpecificFlightModalId = this.modalService.newModal();
            let modalInstance: IModalInstanceService;

            if (operation == 'register') {
                modalInstance = await this.modalService.showModalRegisterSpecificFlight(this.wizardSpecificFlightModalId, this.$scope.transportModeSpecificAirFlight.NAME);

            } else if (operation == 'edit') {
                modalInstance = await this.modalService.showModalEditSpecificFlight(this.wizardSpecificFlightModalId, this.$scope.transportModeSpecificAirFlight.SPECIFIC_FLIGHT_CODE);
            }

            apply = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (apply) {
                this.setTransportModeSpecificAirFlightDate(apply);
                const msgSucess = this.formService.getTranslate("OPERATIONAL.SPECIFIC_FLIGHT_SUCESSFULLY_UPDATED");
                this.formService.notifySuccess(msgSucess);
            }

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private setTransportModeSpecificAirFlight(apply) {

        if (apply) {
            this.$scope.transportModeSpecificAirFlight = {
                ID: apply.ID,
                NAME: apply.FLIGHT_CODE,
                ORIGIN: apply.ORIGIN,
                DESTINATION: apply.DESTINATION,
                PROVIDER: apply.PROVIDER,
                SPECIFIC_FLIGHT_CODE: null,
                SPECIFIC_DATE: null,
                SPECIFIC_DATE_DISPLAY: null,
                SPECIFIC: {
                    ESTIMATED_TIME_ARRIVAL: apply.SPECIFIC ? apply.SPECIFIC.ESTIMATED_TIME_ARRIVAL : null,
                    ESTIMATED_TIME_DEPARTURE: apply.SPECIFIC ? apply.SPECIFIC.ESTIMATED_TIME_DEPARTURE : null
                }
            };

            this.getSpecificFlightByDate(apply.FLIGHT_CODE, apply.PROVIDER);
        }
    }

    private setTransportModeSpecificAirFlightDate(apply) {
        if (apply) {
            this.$scope.transportModeSpecificAirFlight.SPECIFIC_FLIGHT_CODE = apply.SPECIFIC.SPECIFIC_FLIGHT_CODE;
            this.setForeCastDateFlight(apply);
            const item: IFlightSpecific = {
                ID: apply.ID,
                NAME: apply.NAME,
                ORIGIN: apply.ORIGIN,
                DESTINATION: apply.DESTINATION,
                PROVIDER: apply.PROVIDER,
                SPECIFIC_FLIGHT_CODE: apply.SPECIFIC.SPECIFIC_FLIGHT_CODE,
                SPECIFIC_DATE: this.$scope.transportModeSpecificAirFlight.SPECIFIC_DATE,
                SPECIFIC_DATE_DISPLAY: moment(this.$scope.transportModeSpecificAirFlight.SPECIFIC_DATE).format('DD/MM/YYYY HH:mm'),
                SPECIFIC: {
                    ESTIMATED_TIME_DEPARTURE: apply.SPECIFIC.ESTIMATED_TIME_DEPARTURE,
                    ESTIMATED_TIME_ARRIVAL: apply.SPECIFIC.ESTIMATED_TIME_ARRIVAL
                }
            }

            this.$scope.specificFlightList.push(item);
            this.$scope.eventModal.FORECAST_DATE = this.$scope.transportModeSpecificAirFlight.SPECIFIC_DATE;
            if (!this.$scope.eventModal.TRANSPORT_MODE.CODE) this.$scope.eventModal.TRANSPORT_MODE.CODE = apply.SPECIFIC.SPECIFIC_FLIGHT_CODE;
        }
    }

    private setForeCastDateFlight(specificFlight: IFlightSpecific) {
        if (specificFlight && specificFlight.SPECIFIC && this.$scope.eventModal.LOCAL) {
            if (specificFlight && specificFlight.ORIGIN && specificFlight.ORIGIN.CODE == this.$scope.eventModal.LOCAL.CODE) {
                this.$scope.transportModeSpecificAirFlight.SPECIFIC_DATE = specificFlight ? specificFlight.SPECIFIC.ESTIMATED_TIME_DEPARTURE : this.$scope.eventModal.FORECAST_DATE;
            }
            if (specificFlight && specificFlight.SPECIFIC && specificFlight.DESTINATION && specificFlight.DESTINATION.CODE == this.$scope.eventModal.LOCAL.CODE) {
                this.$scope.transportModeSpecificAirFlight.SPECIFIC_DATE = specificFlight ? specificFlight.SPECIFIC.ESTIMATED_TIME_ARRIVAL : this.$scope.eventModal.FORECAST_DATE;
            }
        }
    }

    private async updateFlightTransportMode(newValue: IFlightSpecific) {
        try {
            if (!newValue) {
                await this.confirmAndHandleFlightTransportModeClean();
                return;
            } else {
                this.$scope.eventModal.TRANSPORT_MODE = {
                    ID: newValue.ID,
                    NAME: newValue.NAME,
                    CODE: newValue.SPECIFIC_FLIGHT_CODE
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async confirmAndHandleFlightTransportModeClean() {
        try {
            const confirmed = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (confirmed) {
                this.$scope.eventModal.TRANSPORT_MODE = null;
                this.$scope.eventModal.SERVICE_PROVIDER = null;
                this.$scope.eventModal.FORECAST_DATE = null;
                this.$scope.eventModal.EFFECTIVE_DATE = null;
                this.$scope.transportModeSpecificAirFlight.SPECIFIC_DATE = null;
                this.$scope.transportModeSpecificAirFlight.SPECIFIC_FLIGHT_CODE = null;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getDirectionBasedOnEventType() {
        let searchType = null;
        const isLoadOrConnectionDepartureEvent = this.$scope.eventModal && this.$scope.eventModal.EVENT_TYPE && (this.$scope.eventModal.EVENT_TYPE.ID == EEventType.LOAD || this.$scope.eventModal.EVENT_TYPE.ID == EEventType.CONNECTION_DEPARTURE);
        const isDischargeOrConnectionArrivalEvent = this.$scope.eventModal && this.$scope.eventModal.EVENT_TYPE && (this.$scope.eventModal.EVENT_TYPE.ID == EEventType.DISCHARGE || this.$scope.eventModal.EVENT_TYPE.ID == EEventType.CONNECTION_ARRIVAL);
        if (isLoadOrConnectionDepartureEvent) searchType = EDirectionId.ORIGIN;
        if (isDischargeOrConnectionArrivalEvent) searchType = EDirectionId.DESTINATION;
        return searchType;
    }

    private async getTransportFromOceanSchedule() {
        try {
            this.formService.block();
            const eventType = this.$scope.eventModal.EVENT_TYPE.ID;
            const local = this.$scope.eventModal.LOCAL && this.$scope.eventModal.LOCAL.CODE ? this.$scope.eventModal.LOCAL.CODE : null;
            const hasTranshipmentEvent = this.$newProcessScope.model.EVENT ? this.$newProcessScope.model.EVENT.find(x => x.EVENT_TYPE.ID == EEventType.LOAD_TRANSHIPMENT || x.EVENT_TYPE.ID == EEventType.DISCHARGE_TRANSHIPMENT) : null;

            let originPort: string = null;
            let destinationPort: string = null;
            let searchDateType: string = null;
            let searchDate: Date = moment(new Date()).toDate();
            let isLoad: boolean = [EEventType.LOAD, EEventType.LOAD_TRANSHIPMENT].includes(eventType as EEventType);
            let isDischarge: boolean = [EEventType.DISCHARGE, EEventType.DISCHARGE_TRANSHIPMENT].includes(eventType as EEventType);

            // Check if the event who is being edited is one of the folliwng:
            if (![EEventType.LOAD, EEventType.DISCHARGE, EEventType.DISCHARGE_TRANSHIPMENT, EEventType.LOAD_TRANSHIPMENT, EEventType.DISCHARGE].includes(eventType as EEventType)) return;

            // Check if the process has an transhipment event
            if (hasTranshipmentEvent) {
                const data = this.checkTranshipmentPortPair();
                originPort = data.originPort;
                destinationPort = data.destinationPort;
                searchDateType = data.searchDateType;
            } else {
                const data = this.checkOriginDestinationPort();
                originPort = data.originPort;
                destinationPort = data.destinationPort;
                searchDateType = data.searchDateType;
            }

            if (this.$scope.eventModal.FORECAST_DATE) {
                searchDate = moment(this.$scope.eventModal.FORECAST_DATE).toDate();
            }

            let scacCodes: string = "";
            if (this.providerListForInttraVoyage && this.providerListForInttraVoyage.length > 0) {
                if (this.$scope.process.SERVICE_PROVIDER) this.providerListForInttraVoyage = this.providerListForInttraVoyage.filter(x => x.CODE == this.$scope.process.SERVICE_PROVIDER.SCAC);
                for (const item of this.providerListForInttraVoyage) {
                    scacCodes += item.CODE;
                    scacCodes += ',';
                }
                scacCodes = scacCodes.slice(0, -1);
            }

            const requestData: IInttraOceanSchedulesRequest = {
                scacs: scacCodes,
                originPort,
                destinationPort,
                searchDate,
                weeksOut: "6",
                searchDateType
            };

            const oceanSchedulesRequest = await this.externalService.post({
                route: '/inttra/oceanSchedule', data: { data: requestData }
            });

            let i: number = 0;
            this.$scope.oceanScheduleConcatenated = [];
            if (oceanSchedulesRequest && oceanSchedulesRequest.data && oceanSchedulesRequest.data.data) {
                this.$scope.oceanScheduleOptions = oceanSchedulesRequest.data.data;
                let transshipmentConcatenated: string[] = [];

                if (this.$scope.oceanScheduleOptions.length > 0) {

                    const arrayDeadline: IInttraDeadline[] = [];
                    this.$scope.oceanScheduleOptions.forEach(result => {
                        ++i;
                        arrayDeadline.push({
                            ID: i.toString(),
                            DRAFT_IMO: result.hazBkCutoff,
                            DRAFT: result.siCutoff,
                            VGM: result.vgmCutoff,
                            RELEASE: result.terminalCutoff,
                            BASE_DATE: new Date()
                        });
                    });

                    this.$scope.oceanScheduleDeadline = arrayDeadline;
                    i = 0;

                    this.$scope.oceanScheduleConcatenated = this.$scope.oceanScheduleOptions.map(result => {
                        const estimatedDate = isLoad ? moment(result.originDepartureDate).format('DD/MM/YYYY') : isDischarge ? moment(result.destinationArrivalDate).format('DD/MM/YYYY') : null;
                        const direct = result.scheduleType == 'transshipment' ? 'TS: ' : 'DIRECT';
                        const scac = this.externalShipowners.find(x => x.CODE == result.scac);
                        ++i;
                        result['ID'] = i;
                        transshipmentConcatenated = [];

                        if (direct == 'TS: ' && result.legs && result.legs.length > 0) {
                            result.legs.forEach(leg => {
                                if (leg.sequence > 1) {
                                    transshipmentConcatenated.push(leg.transportName + ' | ' + leg.departureCityName + ' (' + leg.departureUnloc + ') | ' + moment(leg.departureDate).format('DD/MM/YYYY') + ' (' + moment(leg.departureDate).format('dddd') + ')');
                                }
                            });
                        }

                        return {
                            ID: result['ID'].toString(),
                            NAME: result.vesselName + ' | ' + estimatedDate + ' (' + moment(result.originDepartureDate).format('dddd') + ') | ' + result.carrierName + ' | ' + result.voyageNumber + ' | ' + direct + transshipmentConcatenated.join(' '),
                            CODE: result.imoNumber,
                            LOCAL: {
                                ID: null,
                                NAME: isLoad ? result.originCityName : result.destinationCityName,
                                CODE: isLoad ? result.originUnloc : result.destinationUnloc
                            },
                            SERVICE_PROVIDER: scac.NAME,
                            ETB: isLoad ? result.originDepartureDate : isDischarge ? result.destinationArrivalDate : null
                        }
                    });

                    if (isLoad) {
                        this.$scope.oceanScheduleConcatenated = this.$scope.oceanScheduleConcatenated.filter(x => x.LOCAL.CODE == local);
                    }

                    if (isDischarge) {
                        this.$scope.oceanScheduleConcatenated = this.$scope.oceanScheduleConcatenated.filter(x => x.LOCAL.CODE == local);
                    }

                    if (this.$scope.process.SERVICE_PROVIDER) {
                        this.$scope.oceanScheduleConcatenated = this.$scope.oceanScheduleConcatenated.filter(x => x.SERVICE_PROVIDER == this.$scope.process.SERVICE_PROVIDER.SCAC)
                    }
                }
            }

            // Get the already registered voyages
            const actualVoyageRequest = {
                port: local,
                providerCode: this.$scope.process.SERVICE_PROVIDER.SCAC
            };

            const actualVoyages = await this.operationalService.post('/voyage/getVoyageToEvent', actualVoyageRequest);
            if (actualVoyages && actualVoyages.data && actualVoyages.data.data && actualVoyages.data.data.data) {
                for (const itemVoyage of actualVoyages.data.data.data) {
                    const transportNameConcatenated = itemVoyage.VOYAGE.SHIP.NAME + ' | ' + moment(itemVoyage.ETB).format('DD/MM/YYYY') + ' (' + moment(itemVoyage.ETB).format('dddd') + ') | ' + itemVoyage.VOYAGE_PROVIDER_FULL.PROVIDER.NAME + ' | ' + itemVoyage.VOYAGE_PROVIDER_FULL.VOYAGE_NUMBER;
                    ++i;
                    const transportItemInttra: ITransportModeInttra = {
                        ID: itemVoyage.ID,
                        NAME: transportNameConcatenated,
                        CODE: itemVoyage.VOYAGE.SHIP.CODE,
                        LOCAL: {
                            ID: itemVoyage.PORT_ORIGIN.ID,
                            NAME: itemVoyage.PORT_ORIGIN.NAME,
                            CODE: itemVoyage.PORT_ORIGIN.CODE
                        },
                        SERVICE_PROVIDER: itemVoyage.VOYAGE_PROVIDER_FULL.PROVIDER.CODE,
                        ETB: itemVoyage.ETB
                    };

                    const inttraFakeOceanScheduleItem: IInttraOceanSchedulesResult = {
                        vesselName: itemVoyage.VOYAGE.SHIP.NAME,
                        voyageNumber: itemVoyage.VOYAGE_PROVIDER_FULL.VOYAGE_NUMBER,
                        serviceName: null,
                        terminalCutoff: null,
                        originUnloc: itemVoyage.PORT_ORIGIN.CODE,
                        originDepartureDate: itemVoyage.ETB,
                        destinationUnloc: null,
                        destinationArrivalDate: null,
                        totalDuration: null,
                        imoNumber: itemVoyage.VOYAGE.SHIP.CODE,
                        extraLoader: false,
                        scac: itemVoyage.VOYAGE_PROVIDER_FULL.PROVIDER.CODE,
                        carrierName: itemVoyage.VOYAGE_PROVIDER_FULL.PROVIDER.NAME
                    };
                    inttraFakeOceanScheduleItem["ID"] = itemVoyage.ID;

                    const index = this.$scope.oceanScheduleConcatenated.findIndex(x => x.NAME.split("|")[3].trim() == itemVoyage.VOYAGE_PROVIDER_FULL.VOYAGE_NUMBER);
                    if (index > -1) this.$scope.oceanScheduleConcatenated.splice(index, 1);

                    if (!this.$scope.oceanScheduleConcatenated.find(x => x.ID == itemVoyage.ID)) {
                        this.$scope.oceanScheduleOptions.push(inttraFakeOceanScheduleItem);
                        this.$scope.oceanScheduleConcatenated.push(transportItemInttra);
                    }
                }
            }

            // Sort the array based on date
            this.$scope.oceanScheduleConcatenated.sort(function (a, b) {
                var dateA = new Date(a.ETB).getTime();
                var dateB = new Date(b.ETB).getTime();
                return dateA > dateB ? 1 : -1;
            });

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }


    private async getProviderScacForVoyage(): Promise<ISelectorModel[]> {
        try {
            let result: ISelectorModel[] = [];
            const productReq = {
                route: `/provider/list/custom/operational`,
                data: { products: [this.$scope.process.PRODUCT.ID], sysConvertIdToString: true, types: [EProviderTypeId.SHIPOWNER] }
            }

            const providers = await this.productService.post<any>(productReq);
            if (providers.data.data) {
                providers.data.data.forEach(x => {
                    if (x.USED_OCEAN_SCHEDULE) {
                        const item: ISelectorModel = { ID: x.ID, NAME: x.TRADING_NAME, CODE: x.SCAC };
                        result.push(item);
                    }
                });
            }
            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getCodeIntegrationForShipowners(): Promise<ISelectorModel[]> {
        try {
            let result: ISelectorModel[] = [];

            const providerFromExternal = await this.externalService.post({ route: "/ediExternalCodeSetup/list/byProviderType", data: { integrationId: EEdiExternalCodeSetupInttra.INTTRA_INTEGRATION_ID, typeId: EEdiExternalCodeSetupInttra.SHIPOWNER_TYPE_ID } });
            if (providerFromExternal && providerFromExternal.data && providerFromExternal.data.data) {
                for (const item of providerFromExternal.data.data.CODE_INTEGRATION) {
                    const itemOfResult: ISelectorModel = {
                        ID: item.CODE_INTERNAL[0].ID,
                        NAME: item.CODE_INTERNAL[0].NAME,
                        CODE: item.CODE_EXTERNAL
                    };

                    result.push(itemOfResult);
                }
            }

            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getVoyageById(voyageId: number): Promise<IVoyage> {
        let voyage: IVoyage = null;
        try {
            this.formService.block();
            const result = await this.operationalService.get<any>(`/voyage/getById/${voyageId}`, null);
            if (result && result.data && result.data.data) voyage = result.data.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return voyage;
        }
    }

    private async openVoyage(operation: string) {
        try {
            const voyage = await this.checkVoyage()

            if (operation == EOperation.EDIT && voyage) {
                const modelVoyage = await this.getVoyageById(voyage.VOYAGE_ID);
                const modalID = this.modalService.newModal();
                this.modalService.showModalInfo(
                    {
                        template: require("../view/modal/voyageUpdateModal.html"),
                        modalID: modalID,
                        formService: operation,
                        size: 'full',
                    },
                    {
                        actionButtonClass: 'btn-default',
                        headerText: "OPERATIONAL.VOYAGES",
                        actionButtonText: "REGISTRATION.CONFIRM",
                        closeButtonText: 'GENERAL.CLOSE'
                    },
                    {
                        voyageModalId: modalID,
                        voyageId: voyage.VOYAGE_ID,
                        model: {
                            BASIC_DATA: modelVoyage,
                            VOYAGE_PROVIDER: null,
                            VOYAGE_STOPOVER: null,
                            VOYAGE_DEADLINE: null,
                        },
                        isViewMode: false
                    }
                );

            } else if (operation == EOperation.NEW) {

                let apply: IVoyage;
                const modalID = this.modalService.newModal();
                const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                    {
                        template: require("../view/modal/voyageInsertModal.html"),
                        modalID: modalID,
                        formService: EOperation.NEW,
                        size: 'lg',
                    },
                    {
                        actionButtonClass: 'btn-default',
                        headerText: "OPERATIONAL.VOYAGES",
                        actionButtonText: "REGISTRATION.CONFIRM",
                        closeButtonText: 'GENERAL.CLOSE'
                    },
                    {
                        voyageModalId: modalID
                    }
                );

                const modalScope = await this.modalService.getModalScope(modalID);
                await modalScope.$applyAsync();

                apply = await modalInstance.result.then(function (result) {
                    return result.$value;
                }, function (result) {
                    return result.$value;
                });

                if (apply && apply.ID) {
                    this.formService.block();
                    const stopoverTab = await this.operationalService.get(`/voyage/tab/stopovers/getById/${apply.ID}`, 30000);
                    if (stopoverTab && stopoverTab.data && stopoverTab.data.data) {
                        const voyageStopover: IVoyageStopover = stopoverTab.data.data[0];

                        if (this.$scope.eventModal.LOCAL && this.$scope.eventModal.LOCAL.CODE == voyageStopover.PORT_ORIGIN.CODE) {
                            const providerTab = await this.operationalService.get(`/voyage/tab/voyages/getById/${apply.ID}`, 30000);
                            if (providerTab && providerTab.data && providerTab.data.data) {

                                const voyageProvider: IVoyageProvider[] = providerTab.data.data;
                                for (const provider of voyageProvider) {

                                    if (this.$scope.process.SERVICE_PROVIDER.SCAC == provider.PROVIDER.CODE) {
                                        const transportNameConcatenated = apply.SHIP.NAME + ' | ' + moment(voyageStopover.ETB).format('DD/MM/YYYY') + ' (' + moment(voyageStopover.ETB).format('dddd') + ') | ' + provider.PROVIDER.NAME + ' | ' + provider.VOYAGE_NUMBER;
                                        const transportItemInttra: ITransportModeInttra = {
                                            ID: apply.ID.toString(),
                                            NAME: transportNameConcatenated,
                                            CODE: apply.SHIP.CODE,
                                            LOCAL: {
                                                ID: voyageStopover.PORT_ORIGIN.ID,
                                                NAME: voyageStopover.PORT_ORIGIN.NAME,
                                                CODE: voyageStopover.PORT_ORIGIN.CODE
                                            },
                                            SERVICE_PROVIDER: provider.PROVIDER.CODE,
                                            ETB: voyageStopover.ETB
                                        };

                                        const inttraFakeOceanScheduleItem: IInttraOceanSchedulesResult = {
                                            vesselName: apply.SHIP.NAME,
                                            voyageNumber: provider.VOYAGE_NUMBER,
                                            serviceName: null,
                                            terminalCutoff: null,
                                            originUnloc: voyageStopover.PORT_ORIGIN.CODE,
                                            originDepartureDate: voyageStopover.ETB,
                                            destinationUnloc: null,
                                            destinationArrivalDate: null,
                                            totalDuration: null,
                                            imoNumber: apply.SHIP.CODE,
                                            extraLoader: false,
                                            scac: provider.PROVIDER.CODE,
                                            carrierName: provider.PROVIDER.NAME
                                        };
                                        inttraFakeOceanScheduleItem["ID"] = apply.ID.toString();

                                        if (!this.$scope.oceanScheduleConcatenated) this.$scope.oceanScheduleConcatenated = [];
                                        if (!this.$scope.oceanScheduleOptions) this.$scope.oceanScheduleOptions = [];

                                        this.$scope.oceanScheduleOptions.push(inttraFakeOceanScheduleItem);
                                        this.$scope.oceanScheduleConcatenated.push(transportItemInttra);
                                        this.$scope.applyTransportModeToEventFromInttra(transportItemInttra);
                                        this.$scope.transportMode.stopover = transportItemInttra;

                                        this.$scope.updateTransportMode(this.$scope.transportMode.stopover, null);

                                        this.$scope.disableVoyageEdit = false;
                                    }
                                }
                            }
                        }
                    }
                    this.formService.unblock();
                }
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async checkVoyage(): Promise<IContanetededProviderVoyage> {
        try {
            this.$scope.disableVoyageEdit = false;
            const local = this.$scope.eventModal.LOCAL ? this.$scope.eventModal.LOCAL.CODE : null;
            const actualVoyageRequest = {
                port: local ? local : null,
                providerCode: this.$scope.process.SERVICE_PROVIDER.SCAC
            };

            const actualVoyages = await this.operationalService.post('/voyage/getVoyageToEvent', actualVoyageRequest);
            if (actualVoyages && actualVoyages.data && actualVoyages.data.data && actualVoyages.data.data.data) {
                for (const itemVoyage of actualVoyages.data.data.data) {
                    if (this.$scope.transportMode && this.$scope.transportMode.stopover && this.$scope.transportMode.stopover.ID == itemVoyage.ID) {
                        this.$scope.disableVoyageEdit = true;
                        return {
                            PROVIDER: itemVoyage.VOYAGE_PROVIDER_FULL.PROVIDER.NAME,
                            VOYAGE_NUMBER: itemVoyage.VOYAGE_PROVIDER_FULL.VOYAGE_NUMBER,
                            VOYAGE_ID: itemVoyage.VOYAGE_ID
                        };
                    }
                }
            } else {
                return null;
            }
        }
        catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async updateEventTruck(type: string, vehicle: IVehicleTypeDateFrontEnd, processVehicleTypeEventList: IVehicleTypeDateFrontEnd[]) {
        try {
            const updatedEvent = await this.processEventHelper.setEventDateBasedOnTruck(this.$scope.eventModal, type, vehicle, processVehicleTypeEventList);
            if (updatedEvent) this.$scope.eventModal = updatedEvent;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    public async updateEventDateTruck(eventToSet: NewProcessEvent, type: string, forcedEventDate: Date, processVehicleTypeEventList: IVehicleTypeDateFrontEnd[]): Promise<NewProcessEvent> {
        try {
            if (!this.$scope.isRoad) return
            // only if there are vehicle list
            if (!processVehicleTypeEventList || processVehicleTypeEventList.length === 0) return;

            let dataModel = 'FORECAST_DATE';
            let dataType = 'PREVISTA';

            switch (type) {
                case 'FORECAST':
                    dataModel = 'FORECAST_DATE';
                    dataType = 'PREVISTA';
                    break;
                case 'EFFECTIVE':
                    dataModel = 'EFFECTIVE_DATE';
                    dataType = 'EFETIVA';
                    break;
            }

            const eventNewDate: Date = (typeof forcedEventDate !== 'undefined') ? forcedEventDate : eventToSet[dataModel];

            const datesList = new Array<Date>();
            let allVehicleHasDate = true;

            for (let i = 0; i < processVehicleTypeEventList.length; i++) {
                if (processVehicleTypeEventList[i][dataModel]) datesList.push(new Date(processVehicleTypeEventList[i][dataModel]));
                else allVehicleHasDate = false;
            }
            const maxDate = (datesList.length > 0) ? new Date(Math.max.apply(null, datesList)) : null;

            const headerText = `Atualizar Veículos do Evento ${eventToSet.EVENT_NUMBER}`;

            //new valid date
            if (eventNewDate) {
                //check if any container has date, if yes, lets ask user what to do
                let updateAll = false;

                if (datesList.length > 0) {
                    updateAll = await this.modalService.showModalConfirmation({}, {
                        headerText: headerText,
                        bodyText: `Deseja sobrescrever a data ${dataType} de todos os veículos ou somente dos veículos que não tem uma data ${dataType}?<br><br> 
                    <span>Data ${dataType}: <b>${(eventNewDate) ? this.$filter('date')(eventNewDate, "dd/MM/yyyy HH:mm") : ''}</b></span><br>`,
                        actionButtonText: 'Sobrescrever Todos',
                        closeButtonText: 'Manter'
                    });
                }

                if (updateAll) {
                    //update all vehicles
                    for (let i = 0; i < processVehicleTypeEventList.length; i++) {
                        //set the new value
                        processVehicleTypeEventList[i][dataModel] = eventNewDate;
                    }
                } else {
                    //update just the vehicles that does not have a date
                    for (let i = 0; i < processVehicleTypeEventList.length; i++) {
                        //set the new value just if current value is undefined/null
                        if (!processVehicleTypeEventList[i][dataModel]) processVehicleTypeEventList[i][dataModel] = eventNewDate;
                    }
                }

                //when settings EFFECTIVE date and FORECAST date is null, replicate EFFECTIVE date to FORECAST date
                if (type === 'EFFECTIVE' && !eventToSet['FORECAST_DATE']) {
                    eventToSet['FORECAST_DATE'] = eventNewDate;
                    eventToSet = await this.processEventHelper.handleEventContainerDateChange(eventToSet, 'FORECAST', eventNewDate);
                }

            } else {
                //cleaned the event date
                const deleteAll = await this.modalService.showModalConfirmation({}, {
                    headerText: headerText,
                    bodyText: `Deseja apagar a data ${dataType} de todos os veículos?`,
                    actionButtonText: 'Apagar',
                    closeButtonText: 'Manter'

                });

                if (deleteAll) {
                    for (let i = 0; i < processVehicleTypeEventList.length; i++) {
                        //set the new value
                        processVehicleTypeEventList[i][dataModel] = null;
                    }
                } else {
                    //lets check if all vehicles have dates and if we have a max date
                    if (allVehicleHasDate && maxDate) eventToSet[dataModel] = maxDate;
                }
            }

            return eventToSet;

        } catch (ex) {
            throw ex;
        }
    }

    private async getRoadRoute(processEvent: NewProcessEvent) {
        this.isLandProduct = true;
        if (processEvent && processEvent.EVENT_TYPE && (processEvent.EVENT_TYPE.ID !== EEventType.PICK_UP && processEvent.EVENT_TYPE.ID !== EEventType.FINAL_DESTINATION)) {
            this.$scope.serviceProviderList = await this.getServiceProvidersList();
        }
    }

    private checkTranshipmentPortPair() {
        const eventType = this.$scope.eventModal.EVENT_TYPE.ID;
        const local = this.$scope.eventModal.LOCAL && this.$scope.eventModal.LOCAL.CODE ? this.$scope.eventModal.LOCAL.CODE : null;
        let originPort: string;
        let destinationPort: string;
        let searchDateType: string;

        switch (eventType) {
            case EEventType.LOAD:
                originPort = local;
                destinationPort = this.getLoadDestinationPort();
                searchDateType = EInttraSearchDateType.DEPARTURE;
                break;

            case EEventType.LOAD_TRANSHIPMENT:
                originPort = local;
                destinationPort = this.getLoadTranshipmentDestinationPort();
                searchDateType = EInttraSearchDateType.DEPARTURE;
                break;

            case EEventType.DISCHARGE:
                originPort = this.getDischargeOriginPortWithTranship();
                destinationPort = local;
                searchDateType = EInttraSearchDateType.ARRIVAL;
                break;

            case EEventType.DISCHARGE_TRANSHIPMENT:
                originPort = this.getDischargeTranshipmentOriginPort();
                destinationPort = local;
                searchDateType = EInttraSearchDateType.ARRIVAL;
                break;

            default:
                return null;
        }

        return {
            originPort,
            destinationPort,
            searchDateType,
        };
    }

    private checkOriginDestinationPort() {
        const eventType = this.$scope.eventModal.EVENT_TYPE.ID;
        const local = this.$scope.eventModal.LOCAL && this.$scope.eventModal.LOCAL.CODE ? this.$scope.eventModal.LOCAL.CODE : null;
        let originPort: string;
        let destinationPort: string;
        let searchDateType: string;

        switch (eventType) {
            case EEventType.LOAD:
                originPort = local;
                destinationPort = this.getLoadDestinationPort();
                searchDateType = EInttraSearchDateType.DEPARTURE;
                break;

            case EEventType.DISCHARGE:
                originPort = this.getDischargeOriginPort();
                destinationPort = local;
                searchDateType = EInttraSearchDateType.ARRIVAL;
                break;

            default:
                return null;
        }

        return {
            originPort,
            destinationPort,
            searchDateType,
        };
    }

    private getLoadDestinationPort(): string | null {
        let destinationPort: string;
        const discharge = this.$newProcessScope.model.EVENT ? this.$newProcessScope.model.EVENT.find(x => x.EVENT_TYPE.ID == EEventType.DISCHARGE) : null;

        return destinationPort = discharge && discharge.LOCAL ? discharge.LOCAL.CODE : null;
    }

    private getLoadTranshipmentDestinationPort(): string | null {
        let destinationPort: string;
        const discharge = this.$newProcessScope.model.EVENT ? this.$newProcessScope.model.EVENT.find(x => x.EVENT_TYPE.ID == EEventType.DISCHARGE) : null;

        return destinationPort = discharge && discharge.LOCAL ? discharge.LOCAL.CODE : null;
    }

    private getDischargeOriginPort(): string | null {
        let originPort: string;
        const load = this.$newProcessScope.model.EVENT ? this.$newProcessScope.model.EVENT.find(x => x.EVENT_TYPE.ID == EEventType.LOAD) : null;

        return originPort = load && load.LOCAL ? load.LOCAL.CODE : null;
    }

    private getDischargeOriginPortWithTranship(): string | null {
        const loadTranshipments = this.$newProcessScope.model.EVENT ? [...this.$newProcessScope.model.EVENT].filter(x => x.EVENT_TYPE.ID === EEventType.LOAD_TRANSHIPMENT).reverse() : [];
        const lastLoadTranshipment = loadTranshipments.length > 0 ? loadTranshipments[0] : null;

        return lastLoadTranshipment && lastLoadTranshipment.LOCAL ? lastLoadTranshipment.LOCAL.CODE : null;
    }

    private getDischargeTranshipmentOriginPort(): string | null {
        let originPort: string;
        const load = this.$newProcessScope.model.EVENT ? this.$newProcessScope.model.EVENT.find(x => x.EVENT_TYPE.ID == EEventType.LOAD) : null;

        return originPort = load && load.LOCAL ? load.LOCAL.CODE : null;
    }

}
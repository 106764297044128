import angular = require("angular");
import { IGridOptions } from "ui-grid";
import { IMonacoColumnDef } from "@services/GridService2";
import { OperationalService } from "@services/OperationalService";
import { ProductService } from '@services/ProductService';
import { IRestService } from "@services/RestService";
import { GridFormService, IGridFormController, IGridFormServiceScope, IMonacoRequest, IMonacoRequestLog } from "@services/GridFormService";
import { EOperation } from "@enums/GenericData";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { SelectorModel } from '../../common/model/SelectorModel';
import { ISelectorModel } from "WBA-Model/dist/mongo/SelectorModel";
import { HelperService } from "@services/HelperService";
import { ISessionService } from "@services/SessionService";
import { ICustomLogProperties } from "WBA-Model/dist/interface/common/IViewLog";
import { SSEService } from '@appServices/SSEService';

interface ITrackingLinkSettings extends IGridFormServiceScope {
    model: ITrackingLinkSettingsModel;
    scopeBeforeSave: ITrackingLinkSettingsModel;
    gridOptions: IGridOptions;
    form: ng.IFormController;
    user: any;
    sessionService: ISessionService;
    customLogProperties: ICustomLogProperties[];
    hasTrackingLinkSettingsPermission: boolean;
    providerList: SelectorModel[];

    getProviderListByName: (name: string) => Promise<void>;
    formatInput: (htmlComponent: string) => void;
    editTrackingLinkSettings: (trackingLinkSettings: ITrackingLinkSettingsModel) => Promise<void>;
}

export interface ITrackingLinkSettingsModel {
    _id: string | object;
    ID: number;
    PROVIDER: ISelectorModel[];
    URL: string;
    ACTIVE: boolean;
    CREATED_BY: ISelectorModel;
    CREATED_AT: Date;
    UPDATED_BY: ISelectorModel;
    UPDATED_AT: Date;
}

export class TrackingLinkSettingsController extends GridFormService implements IGridFormController {

    static $inject: string[] = ["$injector", "$scope"];
    private $scope: ITrackingLinkSettings;
    private $q: ng.IQService;
    private RestService: IRestService;
    private productService: ProductService;
    private OperationalService: OperationalService;
    private gridName: string;
    private helperService: HelperService;
    private SSEService: SSEService;

    constructor($injector: ng.Injectable<any>, $scope: ITrackingLinkSettings) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.OperationalService = $injector.get('OperationalService');
        this.productService = $injector.get('ProductService');
        this.gridName = "GRID_TRACKING_LINK_SETTINGS";
        this.helperService = $injector.get('HelperService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.OperationalService.$route;
            this.$scope.customLogProperties = this.getCustomLogProperties();
            await this.initDependencies();
            this.initModel();
            this.initForm(this, "form", "trackingLinkSettings", "GENERAL.MENU.REGISTRATION", true);
            this.$scope.hasTrackingLinkSettingsPermission = await this.permissionService.isRoleAllowed("CRUDMENUOPERATIONALTRACKINGLINKSETTINGS");
            await this.initGrid(this.gridName, '/trackingLinkSettings/list', true, true, 120000, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.$rootScope.clearBreadCrumb();
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    async register(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.scopeBeforeSave = null;
            this.$scope.model.ACTIVE = true;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.SSEService.closeEvents();
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")}`;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")}`;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.SSEService.closeEvents();
            this.$scope.disableElements(false);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        return {
            route: `/trackingLinkSettings/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    private async getProviderListByName(search: string): Promise<void> {
        try {
            this.$scope.providerList = [];
            if (search && search.length >= 3) {
                this.block();

                const request: IMonacoRequest = {
                    data: {
                        search
                    },
                    route: `/provider/list/custom`,
                    timeout: 30000,
                }
                const result = await this.productService.post(request)
                if (result && result.data && result.status == 200) {
                    const providerList = result.data.data;
                    for (const resultItem of providerList) {
                        const serviceProvider: ISelectorModel = { ID: resultItem.ID, CODE: resultItem.SCAC_IATA, NAME: resultItem.NAME }
                        this.$scope.providerList.push(serviceProvider);
                    }
                }
                this.unblock();
            }
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    protected formatInput(htmlComponent: string) {
        try {
            if (!htmlComponent) return;

            this.$scope.form[htmlComponent].$setValidity('$valid', false);
            const formProperty = this.$scope.model ? this.$scope.model.URL : null;

            if (formProperty && formProperty.length > 0 && formProperty.length <= 500) {
                this.$scope.form[htmlComponent].$setValidity('$valid', true);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async initDependencies(): Promise<any> {
        try {
            const self: TrackingLinkSettingsController = this;
            return new Promise(function (resolve, reject) {
                self.$q.all([]).then((result: any) => {
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            PROVIDER: null,
            URL: null,
            ACTIVE: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null
        }
    }

    initScopeFunctions(): void {
        this.$scope.getProviderListByName = async (name: string): Promise<void> => {
            await this.getProviderListByName(name);
        }

        this.$scope.formatInput = (htmlComponent: string) => {
            this.formatInput(htmlComponent);
        }

        this.$scope.editTrackingLinkSettings = async (trackingLinkSettings: ITrackingLinkSettingsModel): Promise<void> => {

            let blockedObject = {
                ID: trackingLinkSettings.ID,
                NAME: null,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || trackingLinkSettings.ID !== this.$scope.model.ID) this.$scope.view(trackingLinkSettings);
                } else if (this.$scope.operation !== EOperation.EDIT || trackingLinkSettings.ID !== this.$scope.model.ID) {
                    this.$scope.edit(trackingLinkSettings);
                }
            };
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.view(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editTrackingLinkSettings(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copy(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLog(row.entity, row.entity._id)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;

        gridColumns.$columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: '7%',
            cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${copy} ${viewLog}</div>`,
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 150, cellFilter: "YesOrNo" };
            const colProvider: IMonacoColumnDef = { name: "PROVIDER.NAME", displayName: "BASIC_DATA.PROVIDER", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PROVIDER, null, "NAME")}}</div>' };
            const colUrl: IMonacoColumnDef = { name: "URL", displayName: "REGISTRATION.URL", width: 1000 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'PROVIDER.NAME':
                        columnDefs.push(colProvider);
                        break;
                    case 'URL':
                        columnDefs.push(colUrl);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
            {
                PROPERTY: 'PROVIDER',
                LABEL: 'BASIC_DATA.PROVIDER'
            },
            {
                PROPERTY: 'URL',
                LABEL: 'REGISTRATION.URL'
            },
        ];
        return props;
    }

}

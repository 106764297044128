import * as angular from 'angular';
import { GridFormService, IGridFormServiceScope, IGridFormController, IMonacoRequest } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IModalService } from '@services/ModalService';
import { IMonacoColumnDef } from '@services/GridService2';
import { ISessionService } from '@services/SessionService';
import { OperationalService } from '@services/OperationalService';
import { TrackingAWBService } from '@services/TrackingAWBService';
import { Process } from "@models/interface/operational/NewProcess";
import { IFileUpload } from '@models/interface/common/IFileUpload';
import { IViewLog } from "@models/interface/common/IViewLog";
import { ProcessDocument } from "@models/interface/operational/ProcessDocument";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { IGenericResponse } from '../../common/interface/IGenericResponse';
import { RouteConstants } from '../../common/util/RouteConstants';
import { GridExcelUtil } from '../../common/util/GridExcelUtil';
import { SelectorModel } from '../../common/model/SelectorModel';
import { IProcessParameter, IDraftParameter } from '../../common/model/ModelParameter';
import { ValidateUtil } from "../../common/util/ValidateUtil";
import { EReportCategoryName, EProductId } from '@enums/GenericData';
import { HelperService } from "@services/HelperService";

interface IGenericScope extends IGridFormServiceScope {
    processes: SelectorModel[];
    model: ProcessDocument;
    type: SelectorModel;
    fileReferenceModalID: number;
    log: IViewLog;
    process: object;
    emissionTypes: Array<SelectorModel>;
    releaseTypes: Array<SelectorModel>;
    inconsistencyList: Array<SelectorModel>;
    inconsistencyPrefList: Array<SelectorModel>;
    processDocumentSituations: Array<SelectorModel>;
    singleTerms: Array<SelectorModel>;
    externalContacts: Array<SelectorModel>;
    getMultNameSelected: (selector: Array<SelectorModel>) => string;
    saveExcel: (cells, field) => Promise<any>;
    openProcessTab: (entity: ProcessDocument) => Promise<void>;
    openChargeTab: (entity: ProcessDocument) => Promise<void>;
    openUploadTab: (entity: ProcessDocument) => Promise<void>;
    processDocumentTypeList: () => Array<SelectorModel>;
    processNumberSearch: (query: string) => Promise<Array<SelectorModel>>;
    goToProcess: (process: string) => void;
    changeProtocolModel: (process: ProcessDocument) => void;
    openAWBWizard: (Process: ProcessDocument) => void;
    goToDraft: (draftID: string) => void;
    goToDraftByProcessNumber: (processNumber: string) => void;
    getProcessSelector: (number: string) => void;
    getConcatUniqueTerm: (uniqueTerm: Array<IFileUpload>) => string;
    getUniqueTermTooltip: (uniqueTerm: Array<IFileUpload>) => Promise<string>;
    goToAWBStock: () => void;
    typeList: SelectorModel[];
}

interface IProtocolExchangeScope extends ng.IScope {
    protocolExchange: {
        modelID: number;
        protocolSelectedValue: any;
        protocolList: Array<string>;
    }

    applyProtocolExchange: () => void;
}

interface IAWBNumberExchangeScope extends ng.IScope {
    awbNumberExchange: {
        modelID: number;
        awbSelectedValue: any;
        awbNumberList: Array<SelectorModel>;
    }

    applyAwbNumberExchange: () => void;
}

export class ProcessDocumentRegisterController extends GridFormService implements IGridFormController {
    static $inject: Array<string> = ['$injector', '$scope', 'SessionService'];
    private RestService: IRestService;
    private ModalService: IModalService;
    private $timeout: angular.ITimeoutService;
    private $scope: IGenericScope;
    private $q: angular.IQService;
    private sessionService: ISessionService;
    private operationalService: OperationalService;
    private trackingAWBService: TrackingAWBService;
    private modalID: number;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IGenericScope, sessionService: ISessionService) {
        super($injector, $scope);
        this.$scope = $scope;
        this.RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');
        this.$timeout = $injector.get('$timeout');
        this.$q = $injector.get('$q');
        this.sessionService = sessionService;
        this.operationalService = $injector.get('OperationalService');
        this.trackingAWBService = $injector.get('TrackingAWBService');
        this.helperService = $injector.get('HelperService');
    }

    //HOOKS
    async $onInit(): Promise<void> {
        try {
            this.initForm(this, 'form', 'processDocument', 'GENERAL.MENU.PROCESS_KNOWLEDGE', true);

            this.block();

            await this.initDependencies();

            this.$gridService.registerBeginCellEditObserver(this.maskInputs.bind(this));
            GridExcelUtil.configureExcel(this.$scope, this.$gridService.$gridApi);

            this.unblock();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    async request() {
        this.$scope.model.TYPE = this.$scope.type.NAME;

        this.masterExist();

        const savedModel: any = angular.copy(this.$scope.model);

        delete savedModel.DOCUMENT_COUNT_HOUSE;
        delete savedModel.DOCUMENT_COUNT_MASTER;
        delete savedModel.PROCESS;
        delete savedModel.SINGLE_TERM;

        savedModel._id = null;

        this.$baseUrl = this.operationalService.$route;

        let request: IMonacoRequest = {
            route: '/document/insert',
            data: savedModel,
            timeout: 120000
        };

        return request;
    }

    async saveSuccess(response: IGenericResponse<ProcessDocument>): Promise<any> {
        try {
            this.$baseUrl = this.config.restfulBaseUrl;

            //to avoid grid update see GridFormService line 325, strategy for multiple users on grid excel
            this.$scope.newRegister = null;

            const data = this.$gridService.$gridOptions.data as Array<object>;
            data.unshift(response.data);

            //reset form
            this.$scope.showForm = null;
            this.$scope.model = null;
            this.$scope.error = null;

        } catch (ex) {
            this.handleError(ex);
        }
    }

    async register(processDocument: ProcessDocument): Promise<void> {
        try {
            if (processDocument && processDocument.PROCESS_NUMBER) this.$scope.model.PROCESS_NUMBER = processDocument.PROCESS_NUMBER;

            this.$scope.$applyAsync();
        } catch (ex) {
            this.unblock();
            this.handleError(ex);
        }
    }

    async edit(processDocument: ProcessDocument): Promise<void> {
        try {
            if (processDocument && processDocument.PROCESS_NUMBER) this.$scope.model.PROCESS_NUMBER = processDocument.PROCESS_NUMBER;

            this.$scope.$applyAsync();
        } catch (ex) {
            this.unblock();
            this.handleError(ex);
        }
    }

    //DEFINE MODEL
    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            REFERENCE_ID: null,
            PROCESS_DOCUMENT_NUMBER: null,
            PROCESS_NUMBER: null,
            DOCUMENT: null,
            TYPE: null,
            COURIER_CIA: null,
            CE_MERCANTE: null,
            PROTOCOL_RETIFICATION: null,
            OBSERVATION: null,
            ORIGINAL_COPY: null,
            ORIGINALS_RECEIPT_RETAINED: null,
            RELEASED_TO: null,
            MASTER: null,
            EMISSION_TYPE: null,
            RELEASE_TYPE: null,
            PEOPLE_RELEASE: null,
            DRAFT_RECEIVED: null,
            CLIENT_APPROVAL: null,
            ALLOG_APPROVAL: null,
            ORIGINAL_COPY_RECEIVED: null,
            ISSUEANCE: null,
            LOCAL_ISSUEANCE: null,
            AVAILABILITY: null,
            COURIER_RECEIVED_BRAZIL: null,
            COURIER_SENT: null,
            COURIER_RECEIVED_ALLOG: null,
            COURIER_RECEIVED_FINOP: null,
            DECONSOLIDATION: null,
            LOI_RECEIVED: null,
            LOI_SENT: null,
            REQUEST_DATE: null,
            DONE: null,
            SENT_DATE: null,
            SISCARGA_MANTRA: null,
            RELEASE: null,
            DRAFT_NUMBER: null,
            AWB_NUMBER: null,
            AWB_PREFIX: null,
            INCONSISTENCY: null,
            INCONSISTENCY_CHARGE_DOCUMENT: null,
            DESTINATION_THC: null,
            PRODUCT: null,
            CARGO_TYPE: null,
            BRANCH: null,
            CUSTOMER_QUALIFICATION: null,
            PROCESS_TYPE: null,
            FORWARDED_BY: null,
            SALES_PERSON: null,
            CUSTOMER: null,
            UNIQUE_TERM: null,
            UNIQUE_TERM_FILE: null,
            CORRECTION: false,
            SUSPENDED: false,
            DELIVERY_DATE: null,
            AVAILABILITY_IMP: null,
            AVAILABILITY_EXP: null,
            CREATED_DATE: null,
            CREATED_BY: null,
            MODIFIED_DATE: null,
            MODIFIED_BY: null,
            ACTIVE: true,
            SITUATION: null
        };
    }

    //DEFINE FUNCTIONS
    initScopeFunctions(): void {
        this.$scope.saveExcel = (cells, field) => this.saveExcel(cells, field);
        this.$scope.viewLog = (entity) => this.viewLog(entity);
        this.$scope.openProcessTab = (entity: ProcessDocument) => this.openProcessTab(entity);
        this.$scope.openChargeTab = (entity: ProcessDocument) => this.openChargeTab(entity);
        this.$scope.openUploadTab = (entity: ProcessDocument) => this.fileReferenceModal(entity);
        this.$scope.processDocumentTypeList = () => this.processDocumentTypeList();
        this.$scope.processNumberSearch = (query: string) => this.processNumberSearch(query);
        this.$scope.getMultNameSelected = (selectors: SelectorModel[]) => this.getMultNameSelected(selectors);
        this.$scope.goToProcess = (process: string) => this.goToProcess(process);
        this.$scope.changeProtocolModel = (process: ProcessDocument) => this.changeProtocolModel(process);
        this.$scope.openAWBWizard = (process: ProcessDocument) => this.openAWBWizard(process);
        this.$scope.goToDraft = (draftID: string) => this.goToDraft(draftID);
        this.$scope.goToDraftByProcessNumber = (processNumber: string) => this.goToDraftByProcessNumber(processNumber);
        this.$scope.getProcessSelector = (number: string) => this.getProcessSelector(number);
        this.$scope.getConcatUniqueTerm = (uniqueTerm: IFileUpload[]) => this.getConcatUniqueTerm(uniqueTerm);
        this.$scope.getUniqueTermTooltip = (uniqueTerm: IFileUpload[]) => this.getUniqueTermTooltip(uniqueTerm);
        this.$scope.goToAWBStock = () => this.goToAWBStock();
        this.$scope.typeList = this.processDocumentTypeList();
    }

    private async getAWBNumbersList(processNumber: string) {
        try {
            const request = { processnumber: processNumber };
            const awbnumberList = await this.operationalService.get<any>(`/awbstock/list/${processNumber}`, request);
            return awbnumberList;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private goToProcess(process: string): void {
        this.sessionService.openTab('app.operational.newProcess.list', <IProcessParameter>{ PROCESS_NUMBER: process });
    }

    private goToDraft(draftID: string): void {
        this.sessionService.openTab('app.operational.draftRegister', <IDraftParameter>{ "DRAFT.DRAFT_NUMBER": draftID });
    }

    private goToDraftByProcessNumber(processNumber: string): void {
        this.sessionService.openTab('app.operational.draftRegister', <IDraftParameter>{ PROCESS_NUMBER: processNumber });
    }

    private goToAWBStock(): void {
        this.sessionService.openTab("app.operational.awbstock");
    }

    private getMultNameSelected(selector: SelectorModel[]): string {
        if (!selector) return;

        let description = selector[0].NAME;

        if (selector.length === 1) {
            return description;
        }
        else {
            for (let i = 1; i < selector.length; i++) {
                description += ' + ' + selector[i].NAME;
            }
        }

        return description;
    }

    private configureMultiSelector(selectors: SelectorModel[]): Array<SelectorModel> {
        const arrayOfArraySelectors = new Array()
        const multSelection = new Array();
        for (const item of selectors) {
            const array = new Array<SelectorModel>();

            array.push(item);
            // Allowing Prepaid and Collect to a multi selection
            if ((item.ID === "2") || (item.ID === "3")) multSelection.push(item);

            arrayOfArraySelectors.push(array);
        }

        arrayOfArraySelectors.push(multSelection);

        return arrayOfArraySelectors;
    }

    //COLUMNS
    async initDependencies(): Promise<boolean> {
        try {
            this.$scope.singleTerms = this.$scope.yesAndNoOptions;
            this.$baseUrl = this.operationalService.$route;

            const result: Array<any> = await this.$q.all([
                this.getGenericValue('document_release'),
                this.getGenericValue('release_type'),
                this.getGenericValue('process_document_situation'),
                this.getGenericValue('inconsistency_process_doc'),
                this.getGenericValue('type_payment'),
                this.initGrid('processDocument', RouteConstants.PROCESS_DOCUMENT_LIST, true, true, 120000, true, true)
            ]);

            this.$scope.emissionTypes = result[0];
            this.$scope.releaseTypes = result[1];
            this.$scope.processDocumentSituations = result[2];
            this.$scope.inconsistencyList = result[3];
            this.$scope.inconsistencyPrefList = this.configureMultiSelector(result[4]);

            return true;
        } catch (ex) {
            throw ex;
        }
    }

    initGridColumns(columns) {
        let builder = new GridColumnBuilder(columns);
        const downloadTemplate = `<a ng-if='row.entity.REFERENCE_ID' ng-repeat='reference in row.entity.PROCESS.REFERENCE | filter : { REFERENCE_ID: row.entity.REFERENCE_ID }' tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.DOWNLOAD' | translate }} {{reference.NAME}}" tooltip-append-to-body="true" target='_blank' href='{{reference.LINK}}'><i class="fa fa-download text-brown"></i>&nbsp;&nbsp</a>`;

        const datetimeTemplate = '<datetime-picker ng-model="MODEL_COL_FIELD" minimal grid-excel></datetime-picker>'

        const masterTemplate = `<ui-select-master-documents>
                                <ui-select ng-model="MODEL_COL_FIELD" theme="bootstrap" append-to-body="true">        
                                <ui-select-match placeholder="{{ 'GENERAL.SELECT' | translate }}">
                                    {{COL_FIELD.ID}} - {{COL_FIELD.NAME}} 
                                </ui-select-match>
                                <ui-select-choices refresh="search($select.search, row.entity)" refresh-delay="250" repeat="item in list | filter: $select.search">
                                    <div ng-bind-html="item.ID + ' - ' + item.NAME  | highlight: $select.search"></div>
                                </ui-select-choices>
                                </ui-select>
                                </ui-select-master-documents>`;

        const externalContactsTemplate = `<ui-select-external-contacts>
                                            <ui-select ng-model="MODEL_COL_FIELD" theme="bootstrap" append-to-body="true">        
                                                <ui-select-match placeholder="{{ 'GENERAL.SELECT' | translate }}">
                                                    {{COL_FIELD.ID}} - {{COL_FIELD.NAME}} 
                                                </ui-select-match>
                                                <ui-select-choices refresh="search($select.search, row.entity)" refresh-delay="250" repeat="item in list | filter: $select.search">
                                                    <div ng-bind-html="item.ID + ' - ' + item.NAME  | highlight: $select.search"></div>
                                                </ui-select-choices>
                                            </ui-select>
                                          </ui-select-external-contacts>`;

        //CONFIGS
        builder.addButtonAction(`<a tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.ADD_TRANSPORT_BILL' | translate }} {{row.entity.PROCESS_NUMBER}}" tooltip-append-to-body="true" ng-click="grid.appScope.edit(row.entity)"><i class="fa fa-plus-circle icon text-primary"></i></a>&nbsp;&nbsp`);
        //builder.addButtonAction(`<a disabled tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.ADD_FOLLOW_UP' | translate }}" tooltip-append-to-body="true"  ng-click="grid.appScope.openFollowupModal(row.entity)"><i class="fa fa-envelope text-orange"></i><a>&nbsp;&nbsp`);
        //builder.addButtonAction('<a tooltip-placement="auto top" uib-tooltip="Ir para Processo" tooltip-append-to-body="true"  ng-click="grid.appScope.openProcessTab(row.entity)"><i class="fa fa-external-link"></i><a>&nbsp;&nbsp');
        builder.addButtonAction(`<a tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.GO_TO_FILE_CHARGES' | translate }}" tooltip-append-to-body="true"  ng-click="grid.appScope.openChargeTab(row.entity)"><i class="fa fa-dollar text-info"></i><a>&nbsp;&nbsp`);
        builder.addButtonAction(`<a tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.ADD_ATTACHMENT' | translate }}" tooltip-append-to-body="true"  ng-click="grid.appScope.openUploadTab(row.entity)"><i class="fa fa-paperclip text-brown"></i><a>&nbsp;&nbsp`);
        builder.addButtonAction(`<a tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.LINK_PROTOCOL' | translate }}" tooltip-append-to-body="true" ng-click="grid.appScope.changeProtocolModel(row.entity)" class="fa fa-id-card-o text-brown"></i><a>&nbsp;&nbsp`)

        builder.addButtonAction(downloadTemplate);
        builder.addButtonAction(`<a ng-if='row.entity.TYPE == "MASTER" && row.entity.PRODUCT.ID == "EA"'tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.ADD_AWB_NUMBER' | translate }}" tooltip-append-to-body="true" ng-click="grid.appScope.openAWBWizard(row.entity)" class="fa fa-plane text-black"></i><a>&nbsp;&nbsp`)
        builder.includeActionBar(GridColumnBuilderConstants.BTN_VIEWLOG);
        builder.removeColumn('OPERATIONAL.REFERENCE_ID');

        let code: IMonacoColumnDef = {};
        code.name = 'PROCESS_DOCUMENT_NUMBER';
        code.displayName = 'GENERAL.CODE';
        code.width = 80;
        code.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(code);

        let processNumber: IMonacoColumnDef = {};
        processNumber.name = 'PROCESS_NUMBER';
        processNumber.displayName = 'OPERATIONAL.FILE_NUMBER';
        processNumber.width = 140;
        processNumber.cellTemplate = `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.PROCESS_VIEW' | translate }} {{row.entity.PROCESS_NUMBER}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToProcess(row.entity.PROCESS_NUMBER)">{{row.entity.PROCESS_NUMBER}}</a></div>`;
        processNumber.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(processNumber);

        let type: IMonacoColumnDef = {};
        type.name = 'TYPE';
        type.displayName = 'GENERAL.TYPE';
        type.width = 95;
        type.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(type);

        let product: IMonacoColumnDef = {};
        product.name = 'PRODUCT';
        product.searchProps = ['PRODUCT.NAME'];
        product.displayName = 'BASIC_DATA.PRODUCT';
        product.width = 160;
        product.cellFilter = 'selectormodel';
        product.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(product);

        let master: IMonacoColumnDef = {};
        master.name = 'MASTER';
        master.searchProps = ['MASTER.NAME'];
        master.displayName = 'FINANCIAL.MASTER';
        master.width = 150;
        master.cellFilter = 'selectormodel';
        master.enableCellEdit = true;
        master.editableCellTemplate = masterTemplate;
        master.filter = { condition: this.gridService.filterSelectObject };
        master.partialCriteria = true;
        builder.addColumn(master);

        let document: IMonacoColumnDef = {};
        document.name = 'DOCUMENT';
        document.displayName = 'OPERATIONAL.TRANSPORT_BILL';
        document.width = 150;
        document.enableCellEdit = true;
        document.cellTemplate = `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW_DRAFT' | translate }}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToDraftByProcessNumber(row.entity.PROCESS_NUMBER)">{{row.entity.DOCUMENT}}</a></div>`;
        document.filter = { condition: this.gridService.filterSelectObject };
        document.partialCriteria = true;
        builder.addColumn(document);

        const draftNumber: IMonacoColumnDef = {};
        draftNumber.name = 'DRAFT_NUMBER';
        draftNumber.displayName = 'BASIC_DATA.PRODUCT';
        draftNumber.cellTemplate = `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW_DRAFT' | translate }}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToDraft(row.entity.DRAFT_NUMBER)">{{row.entity.DRAFT_NUMBER}}</a></div>`;
        draftNumber.width = 165;
        draftNumber.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(draftNumber);

        let documentHouseCount: IMonacoColumnDef = {};
        documentHouseCount.name = 'DOCUMENT_COUNT_HOUSE';
        documentHouseCount.displayName = 'OPERATIONAL.TOTAL_HOUSE';
        documentHouseCount.filter = { condition: this.gridService.filterSelectObject };
        documentHouseCount.width = 150;
        builder.addColumn(documentHouseCount);

        let documentMasterCount: IMonacoColumnDef = {};
        documentMasterCount.name = 'DOCUMENT_COUNT_MASTER';
        documentMasterCount.displayName = 'OPERATIONAL.TOTAL_MASTER';
        documentMasterCount.filter = { condition: this.gridService.filterSelectObject };
        documentMasterCount.width = 150;
        builder.addColumn(documentMasterCount);

        let estimatedLoad: IMonacoColumnDef = {};
        estimatedLoad.name = 'PROCESS.ESTIMATED_LOAD';
        estimatedLoad.displayName = 'OPERATIONAL.ESTIMATED_LOADING_DATE';
        estimatedLoad.width = 170;
        estimatedLoad.cellFilter = 'datetime';
        estimatedLoad.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(estimatedLoad);

        let load: IMonacoColumnDef = {};
        load.name = 'PROCESS.LOAD';
        load.displayName = 'OPERATIONAL.ACTUAL_LOADING_DATE';
        load.width = 150;
        load.cellFilter = 'datetime';
        load.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(load);

        let estimatedDischarge: IMonacoColumnDef = {};
        estimatedDischarge.name = 'PROCESS.ESTIMATED_DISCHARGE';
        estimatedDischarge.displayName = 'OPERATIONAL.ESTIMATED_DISCHARGE_DATE';
        estimatedDischarge.width = 190;
        estimatedDischarge.cellFilter = 'datetime';
        estimatedDischarge.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(estimatedDischarge);

        let operationalSituation: IMonacoColumnDef = {};
        operationalSituation.name = 'PROCESS.SITUATION.NAME';
        operationalSituation.displayName = 'OPERATIONAL.FILE_OPERATIONAL_STATUS';
        operationalSituation.width = 200;
        operationalSituation.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(operationalSituation);

        let discharge: IMonacoColumnDef = {};
        discharge.name = 'PROCESS.DISCHARGE';
        discharge.displayName = 'OPERATIONAL.ACTUAL_DISCHARGE_DATE';
        discharge.width = 150;
        discharge.cellFilter = 'datetime';
        discharge.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(discharge);

        let customerQualification: IMonacoColumnDef = {};
        customerQualification.name = 'CUSTOMER_QUALIFICATION';
        customerQualification.searchProps = ['CUSTOMER_QUALIFICATION.NAME'];
        customerQualification.displayName = 'GENERAL.CLIENT_QUALIFICATION';
        customerQualification.width = 200;
        customerQualification.cellFilter = 'selectormodel';
        customerQualification.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(customerQualification);

        let destinationLocal: IMonacoColumnDef = {};
        destinationLocal.name = 'PROCESS.DESTINATION.NAME';
        destinationLocal.displayName = 'BASIC_DATA.DESTINATION';
        destinationLocal.cellTemplate = '<div class="ui-grid-cell-contents ng-binding ng-scope">{{row.entity.PROCESS.DESTINATION.CODE}} - {{row.entity.PROCESS.DESTINATION.NAME}}</a></div>';
        destinationLocal.width = 200;
        builder.addColumn(destinationLocal);

        let originAgent: IMonacoColumnDef = {};
        originAgent.name = 'PROCESS.LOCAL_AGENT.NAME';
        originAgent.displayName = 'BASIC_DATA.LOCAL_AGENT';
        originAgent.width = 200;
        originAgent.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(originAgent);

        let destinationAgent: IMonacoColumnDef = {};
        destinationAgent.name = 'PROCESS.EXTERNAL_AGENT.NAME';
        destinationAgent.displayName = 'BASIC_DATA.OVERSEAS_AGENT';
        destinationAgent.width = 200;
        destinationAgent.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(destinationAgent);

        let destinationRepresentative: IMonacoColumnDef = {};
        destinationRepresentative.name = 'PROCESS.DESTINATION_REPRESENTATIVE.NAME';
        destinationRepresentative.displayName = 'GENERAL.REPRESENTATIVES';
        destinationRepresentative.width = 200;
        destinationRepresentative.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(destinationRepresentative);

        let booking: IMonacoColumnDef = {};
        booking.name = 'PROCESS.BOOKING';
        booking.displayName = 'OPERATIONAL.BOOKING';
        booking.width = 200;
        booking.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(booking);

        let customer: IMonacoColumnDef = {};
        customer.name = 'CUSTOMER.NAME';
        customer.searchProps = ['CUSTOMER.NAME'];
        customer.displayName = 'BASIC_DATA.CLIENT';
        customer.width = 200;
        customer.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(customer);

        let customerNetWork: IMonacoColumnDef = {};
        customerNetWork.name = 'CUSTOMER.NETWORK.NAME';
        customerNetWork.displayName = 'GENERAL.CLIENT_NETWORK';
        customerNetWork.width = 200;
        customerNetWork.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(customerNetWork);

        let importer: IMonacoColumnDef = {};
        importer.name = 'PROCESS.IMPORTER.NAME';
        importer.displayName = 'BASIC_DATA.CONSIGNEE';
        importer.width = 200;
        importer.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(importer);

        let exporter: IMonacoColumnDef = {};
        exporter.name = 'PROCESS.EXPORTER.NAME';
        exporter.displayName = 'BASIC_DATA.SHIPPER';
        exporter.width = 200;
        exporter.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(exporter);

        let serviceProvider: IMonacoColumnDef = {};
        serviceProvider.name = 'PROCESS.SERVICE_PROVIDER.NAME';
        serviceProvider.displayName = 'BASIC_DATA.PROVIDER';
        serviceProvider.width = 200;
        serviceProvider.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(serviceProvider);

        let incomePaymentCondition: IMonacoColumnDef = {};
        incomePaymentCondition.name = 'PROCESS.INCOME_PAYMENT_CONDITION_ARR';
        incomePaymentCondition.displayName = 'FINANCIAL.RECEIPT_CONDITIONS';
        incomePaymentCondition.width = 200;
        incomePaymentCondition.cellTemplate = '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PROCESS.INCOME_PAYMENT_CONDITION_ARR, null, null, true)}}</div>';
        incomePaymentCondition.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(incomePaymentCondition);

        let incomeReceiptSituation: IMonacoColumnDef = {};
        incomeReceiptSituation.name = 'PROCESS.RECEIPT_SITUATION.SITUATION.NAME';
        incomeReceiptSituation.displayName = 'FINANCIAL.RECEIPT_SITUATION';
        incomeReceiptSituation.width = 200;
        incomeReceiptSituation.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(incomeReceiptSituation);

        let direct: IMonacoColumnDef = {};
        direct.name = 'PROCESS.MASTER_DIRECT';
        direct.displayName = 'BASIC_DATA.STRAIGHT_BL';
        direct.width = 150;
        direct.cellFilter = 'YesOrNo';
        direct.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(direct);

        let draftReceived: IMonacoColumnDef = {};
        draftReceived.name = 'DRAFT_RECEIVED';
        draftReceived.displayName = 'OPERATIONAL.DRAFT_RECEIPT_DATE';
        draftReceived.width = 150;
        draftReceived.cellFilter = 'datetime';
        draftReceived.enableCellEdit = true;
        draftReceived.editableCellTemplate = datetimeTemplate;
        draftReceived.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(draftReceived);

        let clientApproval: IMonacoColumnDef = {};
        clientApproval.name = 'CLIENT_APPROVAL';
        clientApproval.displayName = 'OPERATIONAL.CLIENT_APPROVAL';
        clientApproval.width = 150;
        clientApproval.editableCellTemplate = datetimeTemplate;
        clientApproval.cellFilter = 'datetime';
        clientApproval.enableCellEdit = true;
        clientApproval.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(clientApproval);

        let allogApproval: IMonacoColumnDef = {};
        allogApproval.name = 'ALLOG_APPROVAL';
        allogApproval.displayName = 'OPERATIONAL.ALLOG_APPROVAL';
        allogApproval.width = 150;
        allogApproval.editableCellTemplate = datetimeTemplate;
        allogApproval.cellFilter = 'datetime';
        allogApproval.enableCellEdit = true;
        allogApproval.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(allogApproval);

        let originalCopyReceived: IMonacoColumnDef = {};
        originalCopyReceived.name = 'ORIGINAL_COPY_RECEIVED';
        originalCopyReceived.displayName = 'OPERATIONAL.ORIGINALS_RECEIPT_DATE_BY_EMAIL';
        originalCopyReceived.width = 260;
        originalCopyReceived.editableCellTemplate = datetimeTemplate;
        originalCopyReceived.cellFilter = 'datetime';
        originalCopyReceived.enableCellEdit = true;
        originalCopyReceived.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(originalCopyReceived);

        let issuance: IMonacoColumnDef = {};
        issuance.name = 'ISSUEANCE';
        issuance.displayName = 'OPERATIONAL.ISSUANCE_DATE';
        issuance.width = 150;
        issuance.enableCellEdit = true;
        issuance.editableCellTemplate = datetimeTemplate;
        issuance.cellFilter = 'datetime';
        issuance.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(issuance);

        let localIssuance: IMonacoColumnDef = {};
        localIssuance.name = 'LOCAL_ISSUEANCE';
        localIssuance.displayName = 'OPERATIONAL.ISSUANCE_PLACE';
        localIssuance.width = 150;
        localIssuance.enableCellEdit = true;
        localIssuance.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(localIssuance);

        let availability: IMonacoColumnDef = {};
        availability.name = 'AVAILABILITY';
        availability.displayName = 'OPERATIONAL.AVAILABILITY';
        availability.width = 150;
        availability.enableCellEdit = true;
        availability.editableCellTemplate = datetimeTemplate;
        availability.cellFilter = 'datetime';
        availability.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(availability);

        let emissionType: IMonacoColumnDef = {};
        emissionType.name = 'EMISSION_TYPE';
        emissionType.searchProps = ['EMISSION_TYPE.NAME'];
        emissionType.displayName = 'OPERATIONAL.ISSUANCE_TYPE';
        emissionType.width = 150;
        emissionType.cellFilter = 'selectormodel';
        emissionType.enableCellEdit = true;
        emissionType.editableCellTemplate = `<select ui-grid-edit-dropdown ng-class="'colt' + col.uid" ng-model="MODEL_COL_FIELD" ng-options="item as item.NAME for item in grid.appScope.emissionTypes"></select>`;
        emissionType.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(emissionType);

        let releaseType: IMonacoColumnDef = {};
        releaseType.name = 'RELEASE_TYPE';
        releaseType.searchProps = ['RELEASE_TYPE.NAME'];
        releaseType.displayName = 'OPERATIONAL.RELEASE_TYPE';
        releaseType.width = 230;
        releaseType.cellFilter = 'selectormodel';
        releaseType.enableCellEdit = true;
        releaseType.editableCellTemplate = `<select ui-grid-edit-dropdown ng-class="'colt' + col.uid" ng-model="MODEL_COL_FIELD" ng-options="item as item.NAME for item in grid.appScope.releaseTypes"></select>`;
        releaseType.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(releaseType);

        let sentDate: IMonacoColumnDef = {};
        sentDate.name = 'SENT_DATE';
        sentDate.displayName = 'OPERATIONAL.SEND_RELEASE_DATE';
        sentDate.width = 180;
        sentDate.cellFilter = 'datetime';
        sentDate.enableCellEdit = true;
        sentDate.editableCellTemplate = datetimeTemplate;
        sentDate.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(sentDate);

        let courierCia: IMonacoColumnDef = {};
        courierCia.name = 'COURIER_CIA';
        courierCia.displayName = 'OPERATIONAL.COURIER_CIA';
        courierCia.width = 150;
        courierCia.enableCellEdit = true;
        courierCia.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(courierCia);

        let originalCopy: IMonacoColumnDef = {};
        originalCopy.name = 'ORIGINAL_COPY';
        originalCopy.displayName = 'OPERATIONAL.ORIGINALS_COPIES';
        originalCopy.width = 150;
        originalCopy.enableCellEdit = true;
        originalCopy.editableCellTemplate = `<input type="text" ng-class="'colt' + col.uid" mask="99/99" ui-grid-editor ng-model="MODEL_COL_FIELD">`;
        originalCopy.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(originalCopy);

        let originalsReceiptRetained: IMonacoColumnDef = {};
        originalsReceiptRetained.name = 'ORIGINALS_RECEIPT_RETAINED';
        originalsReceiptRetained.displayName = 'OPERATIONAL.ORIGINALS_RECEIPT_RETAINED';
        originalsReceiptRetained.width = 250;
        originalsReceiptRetained.enableCellEdit = true;
        originalsReceiptRetained.editableCellTemplate = `<input type="text" ng-class="'colt' + col.uid" mask="99/99" ui-grid-editor ng-model="MODEL_COL_FIELD">`;
        originalsReceiptRetained.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(originalsReceiptRetained);

        let peopleRelease: IMonacoColumnDef = {};
        peopleRelease.name = 'PEOPLE_RELEASE';
        peopleRelease.displayName = 'OPERATIONAL.SEND_RELEASE_PERSON';
        peopleRelease.width = 250;
        peopleRelease.enableCellEdit = true;
        peopleRelease.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(peopleRelease);

        let courierSent: IMonacoColumnDef = {};
        courierSent.name = 'COURIER_SENT';
        courierSent.displayName = 'OPERATIONAL.COURIER_SEND_DATE';
        courierSent.width = 150;
        courierSent.enableCellEdit = true;
        courierSent.editableCellTemplate = datetimeTemplate;
        courierSent.cellFilter = 'datetime';
        courierSent.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(courierSent);

        let courierBrazil: IMonacoColumnDef = {};
        courierBrazil.name = 'COURIER_RECEIVED_BRAZIL';
        courierBrazil.displayName = 'OPERATIONAL.COURIER_RECEIVED_IN_BRAZIL';
        courierBrazil.width = 190;
        courierBrazil.enableCellEdit = true;
        courierBrazil.editableCellTemplate = datetimeTemplate;
        courierBrazil.cellFilter = 'datetime';
        courierBrazil.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(courierBrazil);

        let courierReceivedAllog: IMonacoColumnDef = {};
        courierReceivedAllog.name = 'COURIER_RECEIVED_ALLOG';
        courierReceivedAllog.displayName = 'OPERATIONAL.COURIER_RECEIVED_AT_DESTINATION';
        courierReceivedAllog.width = 210;
        courierReceivedAllog.cellFilter = 'datetime';
        courierReceivedAllog.enableCellEdit = true;
        courierReceivedAllog.editableCellTemplate = datetimeTemplate;
        courierReceivedAllog.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(courierReceivedAllog);

        let courierReceivedFinop: IMonacoColumnDef = {};
        courierReceivedFinop.name = 'COURIER_RECEIVED_FINOP';
        courierReceivedFinop.displayName = 'OPERATIONAL.DOCUMENT_RECEIVED_BY_FINOP';
        courierReceivedFinop.width = 230;
        courierReceivedFinop.cellFilter = 'datetime';
        courierReceivedFinop.enableCellEdit = true;
        courierReceivedFinop.editableCellTemplate = datetimeTemplate;
        courierReceivedFinop.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(courierReceivedFinop);

        let ceMercante: IMonacoColumnDef = {};
        ceMercante.name = 'CE_MERCANTE';
        ceMercante.displayName = 'OPERATIONAL.CE_MERCANTE';
        ceMercante.width = 170;
        ceMercante.enableCellEdit = true;
        ceMercante.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(ceMercante);

        let deconsolidation: IMonacoColumnDef = {};
        deconsolidation.name = 'DECONSOLIDATION';
        deconsolidation.displayName = 'OPERATIONAL.DESCONSOLIDATION_DATE';
        deconsolidation.width = 170;
        deconsolidation.cellFilter = 'datetime';
        deconsolidation.enableCellEdit = true;
        deconsolidation.editableCellTemplate = datetimeTemplate;
        deconsolidation.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(deconsolidation);

        let loiReceived: IMonacoColumnDef = {};
        loiReceived.name = 'LOI_RECEIVED';
        loiReceived.displayName = 'OPERATIONAL.LOI_RECEIVE_DATE';
        loiReceived.width = 150;
        loiReceived.cellFilter = 'datetime';
        loiReceived.enableCellEdit = true;
        loiReceived.editableCellTemplate = datetimeTemplate;
        loiReceived.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(loiReceived);

        let loiSent: IMonacoColumnDef = {};
        loiSent.name = 'LOI_SENT';
        loiSent.displayName = 'OPERATIONAL.LOI_SEND_DATE';
        loiSent.width = 150;
        loiSent.cellFilter = 'datetime';
        loiSent.enableCellEdit = true;
        loiSent.editableCellTemplate = datetimeTemplate;
        loiSent.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(loiSent);

        let protocolRetification: IMonacoColumnDef = {};
        protocolRetification.name = 'PROTOCOL_RETIFICATION';
        protocolRetification.displayName = 'OPERATIONAL.RECTIFICATION_PROTOCOL';
        protocolRetification.width = 190;
        protocolRetification.enableCellEdit = true;
        protocolRetification.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(protocolRetification);

        let requestDate: IMonacoColumnDef = {};
        requestDate.name = 'REQUEST_DATE';
        requestDate.displayName = 'OPERATIONAL.REQUEST_DATE';
        requestDate.width = 150;
        requestDate.cellFilter = 'datetime';
        requestDate.enableCellEdit = true;
        requestDate.editableCellTemplate = datetimeTemplate;
        requestDate.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(requestDate);

        let situation: IMonacoColumnDef = {};
        situation.name = 'SITUATION';
        situation.searchProps = ['SITUATION.NAME'];
        situation.displayName = 'GENERAL.SITUATION';
        situation.width = 200;
        situation.cellFilter = 'selectormodel';
        situation.enableCellEdit = true;
        situation.editableCellTemplate = `<select ui-grid-edit-dropdown ng-class="'colt' + col.uid" ng-model="MODEL_COL_FIELD" ng-options="item as item.NAME for item in grid.appScope.processDocumentSituations"></select>`;
        situation.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(situation);

        let done: IMonacoColumnDef = {};
        done.name = 'DONE';
        done.displayName = 'OPERATIONAL.CONCLUDE_DATE';
        done.width = 150;
        done.cellFilter = 'datetime';
        done.enableCellEdit = true;
        done.editableCellTemplate = datetimeTemplate;
        done.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(done);

        let uniqueTerm: IMonacoColumnDef = {};
        uniqueTerm.name = 'UNIQUE_TERM';
        uniqueTerm.displayName = 'OPERATIONAL.UNIQUE_TERM';
        uniqueTerm.width = 150;
        uniqueTerm.cellFilter = 'YesOrNo';
        uniqueTerm.enableCellEdit = false;
        // singleTerm.editableCellTemplate = `<select ui-grid-edit-dropdown ng-class="'colt' + col.uid" ng-model="MODEL_COL_FIELD" ng-options="item as item.NAME for item in grid.appScope.singleTerms"></select>`;
        uniqueTerm.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(uniqueTerm);

        let uniqueTermFile: IMonacoColumnDef = {};
        uniqueTermFile.name = 'UNIQUE_TERM_FILE';
        uniqueTermFile.displayName = 'OPERATIONAL.UNIQUE_TERM_FILE';
        uniqueTermFile.width = 200;
        uniqueTermFile.enableCellEdit = false;
        uniqueTermFile.cellTemplate = `<div class="ui-grid-cell-contents ng-binding ng-scope" tooltip-append-to-body="true" uib-tooltip="{{grid.appScope.getUniqueTermTooltip(row.entity.UNIQUE_TERM_FILE)}}" ng-bind-html="grid.appScope.getConcatUniqueTerm(row.entity.UNIQUE_TERM_FILE)"></div>`;
        uniqueTermFile.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(uniqueTermFile);

        let releaseDate: IMonacoColumnDef = {};
        releaseDate.name = 'RELEASE';
        releaseDate.displayName = 'OPERATIONAL.RELEASE_DATE';
        releaseDate.width = 150;
        releaseDate.cellFilter = 'datetime';
        releaseDate.enableCellEdit = true;
        releaseDate.editableCellTemplate = datetimeTemplate;
        releaseDate.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(releaseDate);

        let releasedTo: IMonacoColumnDef = {};
        releasedTo.name = 'RELEASED_TO';
        releasedTo.displayName = 'OPERATIONAL.RELEASED_TO';
        releasedTo.width = 150;
        releasedTo.enableCellEdit = true;
        releasedTo.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(releasedTo);

        let siscargaMantra: IMonacoColumnDef = {};
        siscargaMantra.name = 'SISCARGA_MANTRA';
        siscargaMantra.displayName = 'OPERATIONAL.SISCOMEX_CARGO_RELEASED_AIMED_MANTRA';
        siscargaMantra.width = 190;
        siscargaMantra.cellFilter = 'datetime';
        siscargaMantra.enableCellEdit = true;
        siscargaMantra.editableCellTemplate = datetimeTemplate;
        siscargaMantra.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(siscargaMantra);

        let observation: IMonacoColumnDef = {};
        observation.name = 'OBSERVATION';
        observation.displayName = 'GENERAL.REMARKS';
        observation.width = 150;
        observation.enableCellEdit = true;
        observation.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(observation);

        let inconsistency: IMonacoColumnDef = {};
        inconsistency.name = 'INCONSISTENCY';
        inconsistency.displayName = 'GENERAL.INCONSISTENCY';
        inconsistency.width = 150;
        inconsistency.cellFilter = 'selectormodel';
        inconsistency.enableCellEdit = true;
        inconsistency.editableCellTemplate = `<select ui-grid-edit-dropdown ng-class="'colt' + col.uid" ng-model="MODEL_COL_FIELD" ng-options="item as item.NAME for item in grid.appScope.inconsistencyList"></select>`;
        inconsistency.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(inconsistency);

        let inconsistencyPreference: IMonacoColumnDef = {};
        inconsistencyPreference.name = 'INCONSISTENCY_CHARGE_DOCUMENT.PREFERENCE';
        inconsistencyPreference.displayName = 'OPERATIONAL.INCONSISTENCY_CHARGE_PREFERENCE';
        inconsistencyPreference.width = 250;
        inconsistencyPreference.cellFilter = 'selectormodel';
        inconsistencyPreference.enableCellEdit = true;
        inconsistencyPreference.cellTemplate = `<div class="grid-padding" >{{grid.appScope.getMultNameSelected(row.entity.INCONSISTENCY_CHARGE_DOCUMENT.PREFERENCE, 0)}}</div>`;
        inconsistencyPreference.editableCellTemplate = `<select ui-grid-edit-dropdown ng-class="'colt' + col.uid" ng-model="MODEL_COL_FIELD" ng-options="item as grid.appScope.getMultNameSelected(item) for item in grid.appScope.inconsistencyPrefList"></select>`;
        inconsistencyPreference.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(inconsistencyPreference);

        let inconsistencyRelease: IMonacoColumnDef = {};
        inconsistencyRelease.name = 'INCONSISTENCY_CHARGE_DOCUMENT.RELEASE.NAME';
        inconsistencyRelease.displayName = 'OPERATIONAL.INCONSISTENCY_CHARGE_DECLARATION';
        inconsistencyRelease.width = 250;
        inconsistencyRelease.enableCellEdit = false;
        inconsistencyRelease.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(inconsistencyRelease);

        let destinationTHC: IMonacoColumnDef = {};
        destinationTHC.name = 'DESTINATION_THC.NAME';
        destinationTHC.displayName = 'OPERATIONAL.DECLARE_DESTINATION_THC_ON_HBL';
        destinationTHC.width = 245;
        destinationTHC.enableCellEdit = false;
        destinationTHC.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(destinationTHC);

        let cargoType: IMonacoColumnDef = {};
        cargoType.name = 'CARGO_TYPE';
        cargoType.searchProps = ['CARGO_TYPE.NAME'];
        cargoType.displayName = 'BASIC_DATA.CARGO_TYPE';
        cargoType.width = 200;
        cargoType.cellFilter = 'selectormodel';
        cargoType.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(cargoType);


        let branch: IMonacoColumnDef = {};
        branch.name = 'BRANCH';
        branch.searchProps = ['BRANCH.NAME'];
        branch.displayName = 'BASIC_DATA.BRANCH';
        branch.width = 200;
        branch.cellFilter = 'selectormodel';
        branch.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(branch);

        let processType: IMonacoColumnDef = {};
        processType.name = 'PROCESS_TYPE';
        processType.searchProps = ['PROCESS_TYPE.NAME'];
        processType.displayName = 'BASIC_DATA.FILE_TYPE';
        processType.width = 200;
        processType.cellFilter = 'selectormodel';
        processType.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(processType);

        let forwardedBy: IMonacoColumnDef = {};
        forwardedBy.name = 'FORWARDED_BY';
        forwardedBy.searchProps = ['FORWARDED_BY.NAME'];
        forwardedBy.displayName = 'GENERAL.FORWARDED_BY';
        forwardedBy.width = 200;
        forwardedBy.cellFilter = 'selectormodel';
        forwardedBy.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(forwardedBy);

        let salesPerson: IMonacoColumnDef = {};
        salesPerson.name = 'SALES_PERSON';
        salesPerson.searchProps = ['SALES_PERSON.NAME'];
        salesPerson.displayName = 'BASIC_DATA.SALES_EXECUTIVE';
        salesPerson.width = 200;
        salesPerson.cellFilter = 'selectormodel';
        salesPerson.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(salesPerson);

        let commercialUnity: IMonacoColumnDef = {};
        commercialUnity.name = 'PROCESS.COMMERCIAL_UNITY';
        commercialUnity.searchProps = ['PROCESS.COMMERCIAL_UNITY.NAME'];
        commercialUnity.displayName = 'BASIC_DATA.COMMERCIAL_BRANCH';
        commercialUnity.width = 200;
        commercialUnity.cellFilter = 'selectormodel';
        commercialUnity.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(commercialUnity)

        let createdDate: IMonacoColumnDef = {};
        createdDate.name = 'CREATED_DATE';
        createdDate.displayName = 'GENERAL.CREATED_AT';
        createdDate.width = 200;
        createdDate.cellFilter = 'datetime';
        createdDate.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(createdDate)

        let modifiedBy: IMonacoColumnDef = {};
        modifiedBy.name = 'MODIFIED_BY';
        modifiedBy.displayName = 'GENERAL.MODIFIED_BY';
        modifiedBy.width = 200;
        modifiedBy.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(modifiedBy)

        let modifiedDate: IMonacoColumnDef = {};
        modifiedDate.name = 'MODIFIED_DATE';
        modifiedDate.displayName = 'GENERAL.MODIFIED_DATE';
        modifiedDate.width = 200;
        modifiedDate.cellFilter = 'datetime';
        modifiedDate.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(modifiedDate)

        let levelService: IMonacoColumnDef = {};
        levelService.name = 'SERVICE_LEVEL';
        levelService.displayName = 'BASIC_DATA.SERVICE_LEVEL';
        levelService.width = 200;
        levelService.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(levelService);

        let typeService: IMonacoColumnDef = {};
        typeService.name = 'SERVICE_TYPE';
        typeService.displayName = 'BASIC_DATA.SERVICE_TYPE';
        typeService.width = 200;
        typeService.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(typeService);

        let customerReference: IMonacoColumnDef = {};
        customerReference.name = 'CUSTOMER_REFERENCE';
        customerReference.displayName = 'OPERATIONAL.CLIENT_REFERENCE';
        customerReference.width = 150;
        customerReference.filter = { condition: this.gridService.filterSelectObject };
        builder.addColumn(customerReference);

        return builder.$columnDefs;
    }

    //REQUEST
    private updateProcessDocument(request: any) {
        return this.operationalService.put(RouteConstants.PROCESS_DOCUMENT_UPDATE, request);
    }

    private async updateProcessDocumentGrid(): Promise<any> {
        await this.updateGrid();
        return await this.$gridService.getGridData();
    }

    private async getGridDataProcessDocument(): Promise<any> {
        return await this.$gridService.getGridData();
    }

    private getProcessByNumber(number) {
        const timeout = 120000;
        return this.operationalService.get(`${RouteConstants.PROCESS_NUMBER}/${number}/${timeout}`, null, timeout);
    }

    private async getGenericValue(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    //AUXS
    private async initGridExcel(): Promise<void> {
        await this.initGrid('processDocument', RouteConstants.PROCESS_DOCUMENT_LIST, true, true, 120000);
        GridExcelUtil.configureExcel(this.$scope, this.$gridService.$gridApi);
    }

    private getProcessDocumentLog(number) {
        return this.operationalService.get(`/document/log/${number}`, null);
    }

    private async saveExcel(cells: Array<ProcessDocument>, field: string): Promise<Array<ProcessDocument>> {
        try {
            this.block();
            let previousAwbNumber: string;
            let productId: string;
            //recover actual protocol
            for (let cell of cells) {
                productId = cell.PRODUCT.ID;
                const _id = cell._id;
                const document = await this.RestService.newObjectPromise(`${this.$baseUrl}/document/getById`, { _id: _id, projection: { "DRAFT_NUMBER": 1, "DOCUMENT": 1 } }, 30000, false);
                if (document && document.length > 0) {
                    cell.DRAFT_NUMBER = document[0].DRAFT_NUMBER;
                    previousAwbNumber = document[0].DOCUMENT;
                }
            }

            const processDocumentRow = cells[0];
            const newAwbNumber = processDocumentRow.DOCUMENT;
            const rowAwbStockNumber = newAwbNumber ? newAwbNumber : previousAwbNumber;

            if ((productId == EProductId.AIR_EXPORT || productId == EProductId.AIR_IMPORT) && processDocumentRow.TYPE == "MASTER" && newAwbNumber !== '' && !ValidateUtil.isAWBNumber(newAwbNumber)) {
                this.formService.handleError(this.formService.getTranslate('REGISTRATION.INVALID_AWB_NUMBER'));
                processDocumentRow.DOCUMENT = previousAwbNumber;
                angular.element(".excel-feedback .panel-heading").html("");
                return [];
            }

            let request = {
                //operation: 'edit',
                data: cells,
                field: field,
                timeout: 120000
            }

            const rc = await this.updateProcessDocument(request);
            if (rc && rc.status == 200) {
                if (field == "DOCUMENT" && processDocumentRow.TYPE == "MASTER" && (productId == EProductId.AIR_EXPORT || productId == EProductId.AIR_IMPORT)) {
                    const processNumber = processDocumentRow.PROCESS_NUMBER;

                    if (productId == EProductId.AIR_EXPORT) {
                        const req = { awbNumber: previousAwbNumber, newAwbNumber: newAwbNumber, processNumber: processNumber };
                        await this.operationalService.post(`/awbstock/update/${rowAwbStockNumber}`, req, 120000);
                    }

                    if (newAwbNumber && (productId == EProductId.AIR_EXPORT || productId == EProductId.AIR_IMPORT)) {
                        const request: IMonacoRequest = {
                            data: {
                                awbNumber: newAwbNumber,
                                processNumber: processNumber
                            },
                            route: '/cargoAI/subscribeAWB',
                            timeout: 120000
                        };
                        const rc = await this.trackingAWBService.post(request);
                        const result = (rc && rc.data && rc.data.data) ? rc.data.data : null;

                        if (result) {
                            if (result.error) this.handleError(result.error)
                            else if (result.message) this.formService.notifySuccess(result.message)
                        }
                    }
                }
            }

            const list = await this.getGridDataProcessDocument();

            await this.$gridService.handleBackgroundUpdate();
            this.$scope.$applyAsync();

            this.unblock();
            return list;
        } catch (ex) {
            const list = await this.updateProcessDocumentGrid();
            this.unblock();
            this.handleError(ex);
            return list;
        }
    }

    private async viewLog(entity: ProcessDocument): Promise<void> {

        try {
            this.block();

            let log: IViewLog = {
                operation: 'GENERAL.GRID.LOG',
                number: entity.PROCESS_DOCUMENT_NUMBER,
                list: [],
                show: true,
                searchQuery: '',
                originalList: [],
            }

            const response = await this.getProcessDocumentLog(log.number);

            if (response && response.data && response.data.data) {

                log.list = response.data.data;
                log.originalList = angular.copy(log.list);

                this.$scope.log = log;

                angular.element('#log-viewer').removeClass('ng-hide');
                const position = angular.element('#log-viewer').offset().top + $('.app-content-body').scrollTop() - 105;
                $('.app-content-body').animate({ scrollTop: position }, 500);

            }

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getProcessListSelector(number: string): Promise<any> {
        let result = [];
        try {
            this.block();
            const timeout = 120000;

            if (number && number.length >= 3) {
                const request = {
                    data: {
                        forwardingSituationToExclude: [], // finalizado ou check de valores
                        term: number,
                        timeout,
                    },
                    route: `/process/selector`,
                    timeout,
                }
                const processes = await this.operationalService.post(request.route, request.data, request.timeout);
                if (processes && processes.data && processes.data.data && Array.isArray(processes.data.data)) {
                    result = processes.data.data.map(process => process.NAME);
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getProcessSelector(number: string): Promise<void> {
        this.$scope.processes = [];
        try {
            if (number.length >= 3) {
                const result = await this.getProcessListSelector(number);
                if (result) {
                    this.$scope.processes = result;
                    await this.$scope.$applyAsync();
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getUniqueTermTooltip(uniqueTerm: IFileUpload[]): Promise<string> {
        return !uniqueTerm || uniqueTerm.length === 0 ? '' : this.formService.getTranslate("GENERAL.GRID.VIEW_ATTACHMENT");
    }

    private getConcatUniqueTerm(uniqueTerm: IFileUpload[]): string {
        try {
            let string = '';
            if (!uniqueTerm || uniqueTerm.length === 0) return string;

            for (let index = 0; index < uniqueTerm.length; index++) {
                string += `<a target="_blank" class="text-u-l" href="${uniqueTerm[index].FILE_URL}">${uniqueTerm[index].FILE_DISPLAY_NAME}</a> ${uniqueTerm.length > 1 && index !== (uniqueTerm.length - 1) ? ", " : ""}`;
            }

            return string;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openChargeTab(processDocument: ProcessDocument): Promise<void> {
        try {
            const tabParameter: IProcessParameter = { PROCESS_NUMBER: processDocument.PROCESS_NUMBER };
            this.sessionService.openTab('app.finop.charge.register', tabParameter);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openProcessTab(processDocument: ProcessDocument): Promise<void> {
        try {
            this.block();


            const response = await this.getProcessByNumber(processDocument.PROCESS_NUMBER);
            const hasData: boolean = response && response.data && response.data.data && response.data.data.length > 0;

            let id: string = null;
            if (hasData) {
                const process = response.data.data[0] as any;
                id = process._id;
            }

            if (!id) throw new Error("Can't find id of process " + processDocument.PROCESS_NUMBER);

            const url = `#allog/app/operational/process/wizard/view/${id}`;
            window.open(url, '_blank');

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    };

    private processDocumentTypeList(): Array<SelectorModel> {

        const master: SelectorModel = {
            ID: '1',
            NAME: 'MASTER'
        }

        const house: SelectorModel = {
            ID: '2',
            NAME: 'HOUSE'
        }

        return [master, house];
    }

    private async processNumberSearch(query: string): Promise<Array<SelectorModel>> {

        try {

            const validSearch = query && query.length > 2;
            if (validSearch) {
                this.block();

                this.unblock();
            }

            return [];

        } catch (ex) {
            this.handleError(ex);
        }
    };

    private async masterExist() {
        try {
            if (this.$scope.model.TYPE == 'HOUSE' && this.$scope.model.PROCESS_NUMBER) {
                this.block();
                const documents = await this.operationalService.get(`/document/number/${this.$scope.model.PROCESS_NUMBER}`, 15000);

                if (!documents || !documents.data || !documents.data.data) return this.handleError(this.formService.getTranslate("GENERAL.FAILED_TO_GET_FILE_INFORMATION"));
                this.unblock();

                let masterExist = false;

                for (let document of documents.data.data) {
                    if (document.TYPE == 'MASTER') masterExist = true;
                }

                if (!masterExist) return this.handleError(this.formService.getTranslate("GENERAL.IT_IS_NECESSARY_TO_HAVE_A_MASTER_BILL_TO_INCLUDE_A_HOUSE_BILL"));
            }

            return (true);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async fileReferenceModal(processDocument: ProcessDocument) {
        try {
            this.block();
            const timeout = 120000;
            const result = await this.operationalService.get(`/process/byNumber/${processDocument.PROCESS_NUMBER}/${timeout}`, timeout);
            if (!result || !result.data || (result.data && !result.data.data)) return this.handleError(this.formService.getTranslate("GENERAL.FAILED_TO_GET_FILE_INFORMATION"));
            const process: Process = result.data.data[0];

            this.unblock();

            this.$scope.fileReferenceModalID = this.ModalService.newModal();
            const modalResponse = await this.ModalService.showModalFileReference({
                modalID: this.$scope.fileReferenceModalID,
                categoryName:
                    [EReportCategoryName.Process,
                    EReportCategoryName.ISFCAN, EReportCategoryName.ISFUSA, EReportCategoryName.PreAdvise,
                    EReportCategoryName.AMS, EReportCategoryName.BillOfLading, EReportCategoryName.BillOfLadingForPrint, EReportCategoryName.BillOfLadingCover,
                    EReportCategoryName.Memorandum, EReportCategoryName.AirWaybill, EReportCategoryName.AirLabel, EReportCategoryName.Air_Manifest_EN],
                blNumber: [{ number: processDocument.DOCUMENT, PROCESS_NUMBER: process.PROCESS_NUMBER }],
                process: [process],
                processDocument: processDocument,
                fileOnly: true
            });

            if (modalResponse) {
                this.$formService.notifySuccess(this.formService.getTranslate("GENERAL.ATTACHMENT_INSERTED") + processDocument.PROCESS_NUMBER);
                await this.updateGrid();
            }

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private maskInputs(): void {

        const element: any = $('[mask]');
        element.mask(element.attr('mask'));

    }

    private async openAWBWizard(process: ProcessDocument) {
        try {
            const selected = (process.DOCUMENT) ? { boo: true, value: process.DOCUMENT } : false;

            this.block();
            const modalID = this.ModalService.newModal();
            this.ModalService.showModalInfo(
                {
                    modalID: modalID,
                    scope: this.$scope,
                    formService: 'edit',
                    template: require("../view/processAWBNumberChangeModal.html"),
                    size: 'md'
                },
                {
                    actionButtonText: "GENERAL.CLOSE",
                    headerText: `${this.formService.getTranslate("OPERATIONAL.SELECT_AWB")}: ${process.PROCESS_DOCUMENT_NUMBER} - ${process.PROCESS_NUMBER}`
                },
                {
                    selected: selected
                }
            );

            const modalScope: IAWBNumberExchangeScope = await this.ModalService.getModalScope(modalID);

            modalScope.awbNumberExchange = {
                modelID: modalID,
                awbSelectedValue: null,
                awbNumberList: []
            }

            const result = (process) ? await this.getAWBNumbersList(process.PROCESS_NUMBER) : null;
            for (let register of result.data.data) {
                if (register.AWB_NUMBER) {
                    let document = { ID: register.ID, NAME: register.AWB_NUMBER };
                    modalScope.awbNumberExchange.awbNumberList.push(document);
                }
            }

            modalScope.applyAwbNumberExchange = async () => {
                if (!modalScope.awbNumberExchange.awbSelectedValue) return;
                try {
                    this.block();

                    const oldDocument = process.DOCUMENT;
                    const newDocument = modalScope.awbNumberExchange.awbSelectedValue.NAME;
                    process.DOCUMENT = newDocument;

                    // Update in processDocument
                    let request = {
                        data: [process],
                        field: "DOCUMENT"
                    }
                    let res = await this.updateProcessDocument(request);
                    if (!res || res.status !== 200) {
                        modalScope.awbNumberExchange.awbSelectedValue.DOCUMENT = newDocument;
                        process.DOCUMENT = oldDocument;
                        throw this.formService.getTranslate("OPERATIONAL.IT_IS_NOT_POSSIBLE_TO_UPDATE_THE_AWB_NUMBER");
                    }

                    // Update in AWB Stock
                    const ID = parseInt(modalScope.awbNumberExchange.awbSelectedValue.ID);
                    const timeout: number = 120000;
                    const req = {
                        data: ID,
                        processNumber: process.PROCESS_NUMBER,
                        timeout
                    }
                    await this.operationalService.post<any>(`/awbstock/update`, req, timeout);

                    const requestSubscribeCargoAI: IMonacoRequest = {
                        data: {
                            awbNumber: newDocument,
                            processNumber: process.PROCESS_NUMBER
                        },
                        route: '/cargoAI/subscribeAWB',
                        timeout: 120000
                    };
                    const rc = await this.trackingAWBService.post(requestSubscribeCargoAI);
                    const result = (rc && rc.data && rc.data.data) ? rc.data.data : null;

                    if (result && result.error) this.handleError(result.error)
                    else if (result && result.message) this.formService.notifySuccess(result.message)

                    modalScope.awbNumberExchange.awbSelectedValue = null;

                    await this.$gridService.handleBackgroundUpdate();
                    this.$scope.$applyAsync();
                } catch (ex) {
                    this.handleError(ex);
                } finally {
                    this.unblock();
                    this.ModalService.closeModal(modalScope.awbNumberExchange.modelID);
                }
            };

            modalScope.$applyAsync();

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async changeProtocolModel(process: ProcessDocument) {
        try {
            this.block();
            const modalID = this.ModalService.newModal();
            this.ModalService.showModalInfo(
                {
                    modalID: modalID,
                    scope: this.$scope,
                    formService: 'edit',
                    template: require("../view/processProtocolChangeModal.html"),
                    size: 'md'
                },
                {
                    actionButtonText: "GENERAL.CLOSE",
                    headerText: `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")}: ${process.PROCESS_DOCUMENT_NUMBER} - ${process.PROCESS_NUMBER}`
                }
            );

            const modalScope: IProtocolExchangeScope = await this.ModalService.getModalScope(modalID);

            modalScope.protocolExchange = {
                modelID: modalID,
                protocolList: [],
                protocolSelectedValue: null
            }

            const result = await this.operationalService.get(`/document/number/${process.PROCESS_NUMBER}`, null);

            if (result && result.data && result.data.data) {
                modalScope.protocolExchange.protocolList = result.data.data.filter((currentItem) => {
                    return currentItem.DRAFT_NUMBER && process.DRAFT_NUMBER != currentItem.DRAFT_NUMBER && process.TYPE == currentItem.TYPE;
                })
            }

            modalScope.applyProtocolExchange = async () => {
                try {
                    if (modalScope.protocolExchange.protocolSelectedValue && modalScope.protocolExchange.protocolSelectedValue.DRAFT_NUMBER) {
                        this.block();

                        const oldProtocol = process.DRAFT_NUMBER;
                        const newProtocol = modalScope.protocolExchange.protocolSelectedValue.DRAFT_NUMBER;

                        process.DRAFT_NUMBER = newProtocol;
                        modalScope.protocolExchange.protocolSelectedValue.DRAFT_NUMBER = null;

                        // Update current document
                        let request = {
                            data: [process],
                            field: "DRAFT_PROTOCOL"
                        }
                        let res = await this.updateProcessDocument(request);
                        if (!res || res.status !== 200) {
                            modalScope.protocolExchange.protocolSelectedValue.DRAFT_NUMBER = newProtocol;
                            process.DRAFT_NUMBER = oldProtocol;
                            throw this.formService.getTranslate("GENERAL.IT_IS_NOT_POSSIBLE_TO_UPDATE_THE_DRAFT_NUMBER");
                        }

                        // Update other document
                        request = {
                            data: [modalScope.protocolExchange.protocolSelectedValue],
                            field: "DRAFT_PROTOCOL"
                        }
                        res = await this.updateProcessDocument(request);
                        if (!res || res.status !== 200) {
                            modalScope.protocolExchange.protocolSelectedValue.DRAFT_NUMBER = newProtocol;
                            process.DRAFT_NUMBER = oldProtocol;
                            throw this.formService.getTranslate("GENERAL.IT_IS_NOT_POSSIBLE_TO_UPDATE_THE_DRAFT_NUMBER");
                        }

                        await this.$gridService.handleBackgroundUpdate();
                        this.$scope.$applyAsync();

                        this.unblock();
                        this.ModalService.closeModal(modalScope.protocolExchange.modelID);
                    }
                } catch (ex) {
                    this.handleError(ex);
                    this.unblock();
                    this.ModalService.closeModal(modalScope.protocolExchange.modelID);
                }
            }

            modalScope.$applyAsync();

            this.unblock();
        } catch (ex) {
            this.handleError(ex)
        }

    }

}
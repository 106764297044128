import * as angular from 'angular';

import 'ngclipboard/dist/ngclipboard.min.js';

// https://github.com/summernote/summernote/
import 'summernote';
import 'summernote/dist/summernote.js';
import 'summernote/dist/summernote.css';
// https://github.com/summernote/angular-summernote
import 'angular-summernote/dist/angular-summernote.js';

import initRoute from './RouteConfig';
import { GRID_FORM_MODULE } from '../gridForm/GridFormModule';
import { GRID_EXCEL_MODULE } from '../gridExcel/GridExcelModule';
import { NewProcessController } from './controller/NewProcessController';
import { NewProcessMainController } from './controller/NewProcessMainController';
import { NewProcessCargoController } from './controller/NewProcessCargoController';
import { NewProcessEventController } from './controller/NewProcessEventController';
import { NewProcessChargeController } from './controller/NewProcessChargeController';
import { NewProcessCommunicationController } from './controller/NewProcessCommunicationController';
import { NewProcessManagementController } from './controller/NewProcessManagementController';
import { NewProcessTaskController } from './controller/NewProcessTaskController';
import { NewProcessDocumentController } from './controller/NewProcessDocumentController';
import { NewProcessFlexitanqController } from './controller/NewProcessFlexitanqController';
import { NewProcessDetDemManagementController } from './controller/NewProcessDetDemManagementController';
import { OperationalMenuController } from './controller/OperationalMenuController';
import { ProcessEventController } from './controller/ProcessEventController';
import { ProcessComparisonController } from './controller/ProcessComparisonController';
import { shipReference, shipOwners, processNumber, isDealdineReviewed } from '../app/filter/filters';
import { NewProcessDocumentRegisterController } from './controller/NewProcessDocumentRegisterController';
import { DeadlineProcessRegisterController } from '../registration/controller/DeadlineProcessRegisterController';
import { ProcessChargeController } from './controller/ProcessChargeController';
import { FinopService } from '@services/FinopService';
import { WizardStopoverController } from './controller/WizardStopoverController';
import { MaritimeTravelModel } from './controller/MaritimeTravelModel';
import { ExternalService } from '@services/ExternalService';
import { VoyagesAndStopoverRegisterController } from './controller/VoyagesAndStopoverRegisterController';
import { OfferListController } from './controller/OfferListController';
import { OfferWizardModalController } from './controller/OfferWizardModalController';
import { ProcessWizardModalController } from './controller/ProcessWizardModalController';
import { BookingListController } from './controller/BookingListController';
import { BookingWizardModalController } from './controller/BookingWizardModalController';
import { BookingDifferencesModalController } from './controller/BookingDifferencesModalController';
import { AirFlightListController } from './controller/AirFlightListController';
import { BaseAirFlightWizardModalController } from './controller/BaseAirFlightWizardModalController';
import { BaseAirFlightEditController } from './controller/BaseAirFlightEditController';
import { SpecificAirFlightController } from './controller/SpecificAirFlightController';
import { SpecificAirFlightEditController } from './controller/SpecificAirFlightEditController';
import { AWBStockRegisterController } from './controller/AWBStockRegisterController';
import { DraftRegisterController } from './controller/DraftRegisterController';
import { OperationalService } from '@services/OperationalService';
import { TrackingAWBService } from '@services/TrackingAWBService';
import { ProductService } from '@services/ProductService';
import { TrackingService } from '@services/TrackingService';
import { TaskService } from '@services/TaskService';
import { PermissionService } from '../app/services/PermissionService';
import { processLogViewer } from './common/ProcessLogViewer';
import { DataOperationalService } from '@services/DataOperationalService';
import { DataProcessService } from '@services/DataProcessService';
import { DataManagerService } from '@services/DataManagerService';
import { PreProcessRegisterModalController } from './controller/PreProcessRegisterModalController';
import { PreProcessWizardModalController } from './controller/PreProcessWizardModalController';
import { OfferCargoPolLbModalController } from '../commercial/controller/OfferCargoPolLbModalController';
import { CargoReadinessHistoryModalController } from '../operational/controller/CargoReadinessHistoryModalController';
import { ProcessDocumentRegisterController } from '../operational/controller/ProcessDocumentRegisterController';
import { VoyageListController } from './controller/VoyageListController';
import { VoyageInsertModalController } from './controller/VoyageInsertModalController';
import { VoyageUpdateModalController } from './controller/VoyageUpdateModalController';
import { NewProcessDetDemController } from './controller/NewProcessDetDemController';
import { NewProcessDetDemMainController } from './controller/NewProcessDetDemMainController';
import { NewProcessDetDemEquipmentController } from './controller/NewProcessDetDemEquipmentController';
import { NewProcessDetDemEventController } from './controller/NewProcessDetDemEventController';
import { NewProcessDetDemContainerController } from './controller/NewProcessDetDemContainerController';
import { ConsolidatedRegisterController } from './controller/ConsolidatedRegisterController';
import { WizardRepurchaseController } from './controller/WizardRepurchaseController';
import { ConsolidatedRegisterModalController } from './controller/ConsolidatedRegisterModalController';
import { ConsolidatedWizardModalController } from './controller/ConsolidatedWizardModalController';
import { ConsolidatedChargeModalController } from './controller/ConsolidatedChargeModalController';
import { ConsolidatedChargeWizardPanelController } from './controller/ConsolidatedChargeWizardPanelController';
import { ConsolidatedInstructionWizardPanelController } from './controller/ConsolidatedInstructionWizardPanelController';
import { ConsolidatedProcessWizardPanelController } from './controller/ConsolidatedProcessWizardPanelController';
import { ConsolidatedProcessRegisterController } from './controller/ConsolidatedProcessRegisterController';
import { ConsolidatedDocumentWizardPanelController } from './controller/ConsolidatedDocumentWizardPanelController';
import { ConsolidatedReferenceModalController } from './controller/ConsolidatedReferenceModalController';
import { NewProcessChargeOutDateController } from './controller/NewProcessChargeOutDate';
import { PreProcessOfferCompiledWizardModalController } from './controller/PreProcessOfferCompiledWizardModalController';

const OPERATIONAL_MODULE = angular.module('operational', [GRID_FORM_MODULE.name, GRID_EXCEL_MODULE.name, 'ngclipboard', 'summernote'])

    .directive('processLogViewer', processLogViewer)

    .filter('shipreference', shipReference)
    .filter('shipowners', shipOwners)
    .filter('processnumber', processNumber)
    .filter('deadlineReviewed', isDealdineReviewed)

    .factory('MaritimeTravelModel', MaritimeTravelModel)

    .controller('NewProcessController', NewProcessController)
    .controller('NewProcessMainController', NewProcessMainController)
    .controller('NewProcessCargoController', NewProcessCargoController)
    .controller('NewProcessEventController', NewProcessEventController)
    .controller('NewProcessChargeController', NewProcessChargeController)
    .controller('NewProcessChargeOutDateController', NewProcessChargeOutDateController)
    .controller('NewProcessCommunicationController', NewProcessCommunicationController)
    .controller('NewProcessManagementController', NewProcessManagementController)
    .controller('NewProcessTaskController', NewProcessTaskController)
    .controller('NewProcessDocumentController', NewProcessDocumentController)
    .controller('NewProcessFlexitanqController', NewProcessFlexitanqController)
    .controller('NewProcessDetDemManagementController', NewProcessDetDemManagementController)
    .controller('WizardRepurchaseController', WizardRepurchaseController)

    .controller('NewProcessDetDemController', NewProcessDetDemController)
    .controller('NewProcessDetDemMainController', NewProcessDetDemMainController)
    .controller('NewProcessDetDemEquipmentController', NewProcessDetDemEquipmentController)
    .controller('NewProcessDetDemEventController', NewProcessDetDemEventController)
    .controller('NewProcessDetDemContainerController', NewProcessDetDemContainerController)

    .controller('OperationalMenuController', OperationalMenuController)
    .controller('ProcessEventController', ProcessEventController)
    .controller('ProcessComparisonController', ProcessComparisonController)
    .controller('ProcessChargeController', ProcessChargeController)
    .controller('DeadlineMasterHouseProcessRegisterController', DeadlineProcessRegisterController)
    .controller('ProcessDocumentRegisterController', ProcessDocumentRegisterController)
    .controller('DraftRegisterController', DraftRegisterController)
    .controller('WizardStopoverController', WizardStopoverController)
    .controller('VoyagesAndStopoverRegisterController', VoyagesAndStopoverRegisterController)
    .controller('OfferListController', OfferListController)
    .controller('OfferWizardModalController', OfferWizardModalController)
    .controller('ProcessWizardModalController', ProcessWizardModalController)
    .controller('BookingListController', BookingListController)
    .controller('BookingWizardModalController', BookingWizardModalController)
    .controller('BookingDifferencesModalController', BookingDifferencesModalController)
    .controller('AWBStockRegisterController', AWBStockRegisterController)
    .controller('AirFlightListController', AirFlightListController)
    .controller('BaseAirFlightWizardModalController', BaseAirFlightWizardModalController)
    .controller('BaseAirFlightEditController', BaseAirFlightEditController)
    .controller('SpecificAirFlightController', SpecificAirFlightController)
    .controller('SpecificAirFlightEditController', SpecificAirFlightEditController)
    .controller('PreProcessRegisterModalController', PreProcessRegisterModalController)
    .controller('PreProcessWizardModalController', PreProcessWizardModalController)
    .controller('OfferCargoPolLbModalController', OfferCargoPolLbModalController)
    .controller('CargoReadinessHistoryModalController', CargoReadinessHistoryModalController)
    .controller('NewProcessDocumentRegisterController', NewProcessDocumentRegisterController)
    .controller('VoyageListController', VoyageListController)
    .controller('VoyageInsertModalController', VoyageInsertModalController)
    .controller('VoyageUpdateModalController', VoyageUpdateModalController)
    .controller('ConsolidatedRegisterController', ConsolidatedRegisterController)
    .controller('ConsolidatedRegisterModalController', ConsolidatedRegisterModalController)
    .controller('ConsolidatedWizardModalController', ConsolidatedWizardModalController)
    .controller('ConsolidatedChargeModalController', ConsolidatedChargeModalController)
    .controller('ConsolidatedChargeWizardPanelController', ConsolidatedChargeWizardPanelController)
    .controller('ConsolidatedInstructionWizardPanelController', ConsolidatedInstructionWizardPanelController)
    .controller('ConsolidatedProcessWizardPanelController', ConsolidatedProcessWizardPanelController)
    .controller('ConsolidatedProcessRegisterController', ConsolidatedProcessRegisterController)
    .controller('ConsolidatedDocumentWizardPanelController', ConsolidatedDocumentWizardPanelController)
    .controller('ConsolidatedReferenceModalController', ConsolidatedReferenceModalController)
    .controller('PreProcessOfferCompiledWizardModalController', PreProcessOfferCompiledWizardModalController)
    .service('FinopService', FinopService)
    .service('ExternalService', ExternalService)
    .service('ProductService', ProductService)
    .service('OperationalService', OperationalService)
    .service('ProductService', ProductService)
    .service('TrackingService', TrackingService)
    .service('TrackingAWBService', TrackingAWBService)
    .service('TaskService', TaskService)
    .service('PermissionService', PermissionService)
    .service('DataOperationalService', DataOperationalService)
    .service('DataProcessService', DataProcessService)
    .service('DataManagerService', DataManagerService)


initRoute(OPERATIONAL_MODULE.name);

export { OPERATIONAL_MODULE };

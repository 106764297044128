import * as angular from 'angular';
import * as moment from 'moment';
import { ICustomActionsMap, IViewLog } from "@models/interface/common/IViewLog";

interface IScopeLogViewer extends angular.IScope {
    log: IViewLog;
    actions: ICustomActionsMap[];
    searchLog: Function;
    showLog: Function;
    teste: (action: string | number) => string;
    isArray: Function;
    include: string;
}

/**
 * @author Ríder Cantuária <rider.cantuaria@allog.com.br>
 * @name Monaco
 * @version 1.0.2 
 * 
 * Diretiva de visualização de Log
 * 
 * Design Ex.: <log-viewer log='log'></log-viewer> 
 * onde "log" é objeto com Interface IViewLog.ts
 * 
 */

logViewerDirective.$inject = ['$sce', '$filter'];
export function logViewerDirective($sce: angular.ISCEService, $filter: angular.IFilterService) {
    let ddo: any = {};
    ddo.restrict = "E";
    ddo.transclude = false;

    ddo.replace = true;
    ddo.$scope = true;
    ddo.template = require("../view/template/log-viewer.html");

    ddo.scope = {
        log: '=',
        custom: '=',
        actions: '=',
        include: '@'
    };

    ddo.link = function ($scope: IScopeLogViewer) {

        $scope.showLog = (elementID, $event) => {
            angular.element($event.currentTarget).parents('.list-group-item').find('#' + elementID).toggle();
        }

        $scope.teste = (action: string | number) => {
            const selectedAction = $scope.actions.find(x => x.ACTION === action);
            return (selectedAction) ?
                $sce.trustAsHtml(`<span class="${(selectedAction.CLASS_COLOR) ? selectedAction.CLASS_COLOR : ''} pull-right">
                    <i class="${(selectedAction.ICON) ? selectedAction.ICON : ''} icon" aria-hidden="true"></i>
                    <span>${$filter("translate")(selectedAction.LABEL)}</span>
                </span>`)
                :
                ``
        }

        $scope.searchLog = function () {
            let changes = angular.copy($scope.log.originalList);

            if ($scope.log.searchQuery.length >= 3) {
                let results = new Array();

                for (let i = 0; i < changes.length; i++) {
                    let str = JSON.stringify(changes[i]).toLowerCase();

                    let index = str.search($scope.log.searchQuery.toLowerCase());

                    let hasText = index >= 0;
                    let hasDate = moment(changes[i].DATE).format('L').toString().search($scope.log.searchQuery) >= 0;

                    if (hasText || hasDate) {
                        results.push(changes[i]);
                    }
                }
                $scope.log.list = results;
            } else {
                $scope.log.list = changes;
            }
        }
        $scope.isArray = angular.isArray;
    }

    return ddo;
}

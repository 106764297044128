import * as angular from "angular";
import { SSEService } from "@appServices/SSEService";
import { IGridFormServiceScope, GridFormService, IMonacoRequestLog, IGridFormController } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { ISessionService } from "@services/SessionService";
import { IModalService } from "@services/ModalService";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { ICountry } from "@models/interface/product/CountryModel";
import { IShipModel } from "@models/interface/product/ShipModel";
import { IDocumentError } from "@models/interface/common/IDocumentError";
import { EOperation } from "@enums/GenericData";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { SelectorModel } from "../../common/model/SelectorModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { ILinkParameter } from "../../common/model/ModelParameter";

interface IShipScope extends IGridFormServiceScope {
    scopeBeforeSave: IShipModel;
    model: IShipModel;
    gridOptions: uiGrid.IGridOptions;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    countryList: SelectorModel[];
    providerList: SelectorModel[];
    user: any;
    sessionService: ISessionService;
    getCountryListByNameOrInitials: (search: string) => Promise<void>;
    getProviderListByName: (search: string) => Promise<void>;
    goToCountry: (id: number) => void;
    goToProvider: (id: number) => void;
    editShip: (ship: IShipModel) => Promise<void>;
    viewShip: (ship: IShipModel) => Promise<void>;
    viewLogShip: (ship: IShipModel) => Promise<void>;
    copyShip: (ship: IShipModel) => Promise<void>;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
}

export class ShipRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IShipScope;
    private $timeout: ng.ITimeoutService;
    private RestService: IRestService;
    private SSEService: SSEService;
    private ModalService: IModalService;
    private gridName: string;
    private modalID: number;

    constructor($injector: ng.Injectable<any>, $scope: IShipScope) {
        super($injector, $scope);
        this.$timeout = $injector.get('$timeout');
        this.$scope = $scope;
        this.$scope.sessionService = $injector.get('SessionService');
        this.RestService = $injector.get('RestService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.ModalService = $injector.get('ModalService');
        this.modalID = null;
        this.gridName = 'GRID_SHIP';
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'ship', 'BASIC_DATA.VESSEL', true);
            await this.initGrid(this.gridName, '/ship/list', true, true, null, true, true);
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleLoadError(ex);
        }
    }

    initDependencies(): Promise<any> {
        return new Promise(function (resolve, reject) {
            $('#construction')['mask']('0000');
            $('#imo')['mask']('0000000');
            $('#mmsi')['mask']('000000000');
            resolve(true);
        });
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.getCountryListByNameOrInitials = async (search: string) => {
            let countryList = [];
            if (search && search.length >= 2) {
                countryList = await this.getCountryListByNameOrInitials(search);
                this.$scope.countryList = countryList;
            }
        }

        this.$scope.getProviderListByName = async (search: string) => {
            this.$scope.providerList = await this.getProviderListByName(search);
        }

        this.$scope.goToCountry = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.country", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToProvider = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.provider", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.editShip = async (ship: IShipModel): Promise<void> => {
            let blockedObject = {
                ID: ship.ID,
                NAME: ship.NAME,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || ship.ID !== this.$scope.model.ID) this.$scope.view(ship);
                } else if (this.$scope.operation !== EOperation.EDIT || ship.ID !== this.$scope.model.ID) {
                    this.$scope.edit(ship);
                }
            };
        }

        this.$scope.viewShip = async (ship: IShipModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(ship);
        }

        this.$scope.viewLogShip = async (ship: IShipModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(ship);
        }

        this.$scope.copyShip = async (ship: IShipModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(ship);
        }

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewShip(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editShip(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogShip(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyShip(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy + modalIntegration),
            enableCellEdit: true,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };

        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colImo: IMonacoColumnDef = { name: "IMO", displayName: "REGISTRATION.IMO_NUMBER", width: 100 };
            const colName: IMonacoColumnDef = { name: "NAME", displayName: "GENERAL.NAME_INTL", width: 250 };
            const colFlag: IMonacoColumnDef = { name: "COUNTRY_FLAG.NAME", displayName: "REGISTRATION.FLAG_STATE", width: 100 };
            const colConstruction: IMonacoColumnDef = { name: "CONSTRUCTION", displayName: "REGISTRATION.VESSEL_BUILT_YEAR", width: 120 };
            const colCallSign: IMonacoColumnDef = { name: "CALL_SIGN", displayName: "REGISTRATION.VESSEL_CALLSIGN", width: 100 };
            const colMmsi: IMonacoColumnDef = { name: "MMSI", displayName: "REGISTRATION.MMSI", width: 100 };
            const colTeus: IMonacoColumnDef = { name: "TEUS", displayName: "REGISTRATION.TEUS_CAPACITY", width: 100, cellTemplate: '<div class="ui-grid-cell-contents">{{row.entity.TEUS | number: 3}}</div>' };
            const colProvider: IMonacoColumnDef = { name: "PROVIDER.NAME", displayName: "BASIC_DATA.SEA_CARRIER", width: 250 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 80, cellFilter: "YesOrNo" };
            const colIdIntegration: IMonacoColumnDef = { name: "INTEGRATION_ID", displayName: "GENERAL.INTEGRATION_ID", width: 120 };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "GENERAL.ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'IMO':
                        columnDefs.push(colImo);
                        break;
                    case 'COUNTRY_FLAG':
                        columnDefs.push(colFlag);
                        break;
                    case 'CONSTRUCTION':
                        columnDefs.push(colConstruction);
                        break;
                    case 'CALL_SIGN':
                        columnDefs.push(colCallSign);
                        break;
                    case 'MMSI':
                        columnDefs.push(colMmsi);
                        break;
                    case 'TEUS':
                        columnDefs.push(colTeus);
                        break;
                    case 'NAME':
                        columnDefs.push(colName);
                        break;
                    case 'PROVIDER':
                        columnDefs.push(colProvider);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'INTEGRATION_ID':
                        columnDefs.push(colIdIntegration);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            IMO: null,
            NAME: null,
            COUNTRY_FLAG: null,
            ID_COUNTRY_FLAG: null,
            CONSTRUCTION: null,
            CALL_SIGN: null,
            MMSI: null,
            PROVIDER: null,
            ID_PROVIDER: null,
            TEUS: null,
            ACTIVE: true,
            INTEGRATION_ID: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            SEARCH_FIELDS: null,
            FEEDER: null,
            DOCUMENT_ERROR: null
        };
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "GENERAL.MENU.INTEGRATION" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number, idSubject: string): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/ship/sendSync`, { "idShip": id, "idSubject": idSubject }, 120000, false);
                if (syncRequest) success = true;
            }
        } catch (ex) {
            this.formService.handleError('REGISTRATION.MESSAGE.ERROR.ERROR_SENDING_REQUEST');
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const shipData = await this.getShipById(id);
                    if (row && shipData && shipData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = shipData.DOCUMENT_ERROR;
                        documentError = shipData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async getShipById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/ship/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getCountryListByNameOrInitials(search: string): Promise<SelectorModel[]> {
        let result = [];
        try {
            this.formService.block();
            const countries = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/country/list/custom`, { search }, 30000, false);
            result = this.buildFlags(countries);
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private buildFlags(countries: ICountry[]): SelectorModel[] {
        try {
            const flags: SelectorModel[] = [];
            if (!countries) return flags;

            for (const country of countries) {
                const flag: SelectorModel = { ID: country.ID.toString(), NAME: country.NAME_INTL, CODE: country.INITIALS };
                flags.push(flag);
            }
            return flags;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getProviderListByName(search: string): Promise<SelectorModel[]> {
        try {
            let result: SelectorModel[] = [];

            if (search && search.length >= 3) {

                this.formService.block();

                const providers = await this.RestService.newObjectPromise(`${this.$baseUrl}/provider/list/custom`, { types: ['1'], search: name }, 30000, false);
                if (providers) result = providers.map(provider => { return { ID: provider.ID, NAME: provider.NAME, CODE: provider.SCAC_IATA } });

                return result;
            }
        } catch (ex) {
            if (!ex.status || ex.status && ex.status !== 404) this.formService.handleError(ex);
        }
        finally {
            this.formService.unblock();
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
            this.SSEService.closeEvents();
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")} (${this.$scope.model.NAME})`;
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")} (${this.$scope.model.NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            await this.clearFields(this.$scope.model, ['CONCATENATED']);
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        return {
            route: `/ship/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: "IMO",
                LABEL: "REGISTRATION.IMO_NUMBER"
            },
            {
                PROPERTY: "NAME",
                LABEL: "GENERAL.NAME_INTL"
            },
            {
                PROPERTY: "COUNTRY_FLAG",
                LABEL: "REGISTRATION.FLAG_STATE"
            },
            {
                PROPERTY: "CONSTRUCTION",
                LABEL: "REGISTRATION.VESSEL_BUILT_YEAR"
            },
            {
                PROPERTY: "CALL_SIGN",
                LABEL: "REGISTRATION.VESSEL_CALLSIGN"
            },
            {
                PROPERTY: "PROVIDER",
                LABEL: "BASIC_DATA.SEA_CARRIER"
            },
            {
                PROPERTY: "TEUS",
                LABEL: "REGISTRATION.TEUS_CAPACITY"
            },
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE"
            },
            {
                PROPERTY: "INTEGRATION_ID",
                LABEL: "GENERAL.INTEGRATION_ID"
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            },
            {
                PROPERTY: 'FEEDER',
                LABEL: 'GENERAL.FEEDER'
            }
        ];
        return props;
    }
}

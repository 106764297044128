import { IColumnDef, IGridOptions } from "ui-grid";
import { IModalInstanceService } from "angular-ui-bootstrap";
import { IGridFormController, IGridFormServiceScope, GridFormService } from "@services/GridFormService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IViewLog } from "@models/interface/common/IViewLog";
import { IApplicationList } from "@models/interface/product/ApplicationModel";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { IModalService } from '@services/ModalService';
import { ISessionService } from "@services/SessionService";
import { SelectorModel } from "../../common/model/SelectorModel";
import { ITariffFreightContractModel, ITariffFreightExchangeData } from "@models/interface/product/TariffFreightModel";
import { IOfferParameter, ILinkParameter } from "../../common/model/ModelParameter";
import { IOfferExchangeData } from "../../commercial/model/OfferModel";

interface IChargeWizard {
    ID_CHARGE_NAME_EXHIBITION: number;
    APPLICATION: IApplicationList;
    ID_CURRENCY: number;
    PAYMENT_MIN: number;
    PAYMENT_UNITARY: number;
    RECEIVING_MIN: number;
    RECEIVING_UNITARY: number;
    GROUP: boolean;
    SPECIFICITY: SelectorModel;
    CHARGE_NAME_EXHIBITION: SelectorModel;
    EQUIP_WEIGHT_RANGE_VEHICLE: SelectorModel[];
    CURRENCY: SelectorModel;
}

interface ITariffFreightWizard {
    ID: number;
    TARIFF_FREIGHT_CONTRACT: ITariffFreightContractModel;
    MOVE_TYPE: SelectorModel;
    PROCESS_EVENT_TRANSPORT_MODE: SelectorModel;
    DTA: boolean;
    CONCATENATED: string;
    ACTIVE: boolean;
    PUP_ROUTING_POINT: SelectorModel[];
    OTFS_ROUTING_POINT: SelectorModel[];
    POL_AOL_ROUTING_POINT: SelectorModel[];
    POD_AOD_ROUTING_POINT: SelectorModel[];
    DTFS_ROUTING_POINT: SelectorModel[];
    PLD_ROUTING_POINT: SelectorModel[];
    LOGISTIC_SOLUTION: SelectorModel[];
    SERVICE_LEVEL: SelectorModel[];
    CHARGE: IChargeWizard;
}

interface ITariffFreightChargesScope extends IGridFormServiceScope {
    gridOptions: IGridOptions;
    log: IViewLog;
    goToTariffFreight: (idContract: number, idTariff?: number) => void;
    goToOfferWizard: (tariff: ITariffFreightWizard) => void;
}

export class TariffFreightChargesRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: ITariffFreightChargesScope;
    private $q: ng.IQService;
    private ModalService: IModalService;
    private formName: string;
    private sessionService: ISessionService;

    constructor($injector: ng.Injectable<any>, $scope: ITariffFreightChargesScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.ModalService = $injector.get('ModalService');
        this.formName = 'tariffFreightCharges';
        this.sessionService = $injector.get('SessionService');
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.initForm(this, 'form', this.formName, 'GENERAL.MENU.TARIFF_FREIGHT', true);
            await this.initGrid('gridTariffFreightCharges', '/tariffFreight/wizard/list/charge', true, true, null, true);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initDependencies(): Promise<any> {
        const self: TariffFreightChargesRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([

            ]).then(async (result: any) => {
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.goToTariffFreight = (contractId: number, tariffId?: number) => {
            if (!contractId && contractId != 0) throw Error('contractId is null');
            const endpoint = `${this.config.productUrl}/product/tariffFreight/getCacheById/${contractId}`;
            this.sessionService.openTabByValidity(endpoint, "app.product.tariffFreight", <ILinkParameter>{ ID: contractId.toString() }, <ITariffFreightExchangeData>{ OPERATION: "edit", ID: contractId.toString(), ID_TARIFF: tariffId });
        }
        this.$scope.goToOfferWizard = (tariff: ITariffFreightWizard) => {
            if (!tariff) return;
            this.sessionService.openTab("app.commercial.offer", <IOfferParameter>{ CONCATENATED: "" }, <IOfferExchangeData>{ OPERATION: "new", ID_TARIFF_FREIGHT_CONTRACT: tariff.TARIFF_FREIGHT_CONTRACT.ID, ID_TARIFF: tariff.ID, ID_PAYMENT_NATURE: tariff.TARIFF_FREIGHT_CONTRACT && tariff.TARIFF_FREIGHT_CONTRACT.PAYMENT_NATURE ? parseInt(tariff.TARIFF_FREIGHT_CONTRACT.PAYMENT_NATURE.ID) : null });
        }
    }

    initGridColumns(columns: string[]): IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const edit = `<a ng-click="grid.appScope.edit(row.entity)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true"><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const offer = `<a ng-click="grid.appScope.goToOfferWizard(row.entity)" class="text-green edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="Nova oferta" tooltip-append-to-body="true"><i class="fa fa-handshake-o icon"></i></a>&nbsp;&nbsp;`;
        const colActions: IMonacoColumnDef = {
            name: "acoes", displayName: "GENERAL.ACTIONS",
            width: 70,
            cellTemplate: `<div class="text-center view-btn-action-bar"> ${edit} ${offer} </div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };
        gridColumns.addColumn(colActions);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colConcatenated: IMonacoColumnDef = { name: "CONCATENATED", displayName: "PRODUCT.FREIGHT_TARIFF_ROUTE_CONCATENATED", width: 350, cellTemplate: '<div class="ui-grid-cell-contents" tooltip-placement="auto top" uib-tooltip="Clique para acessar a rota" tooltip-append-to-body="true"><a href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToTariffFreight(row.entity.TARIFF_FREIGHT_CONTRACT.ID, row.entity.ID)">{{row.entity.CONCATENATED}}</a></div>' };
            const colContractConcatenated: IMonacoColumnDef = { name: "TARIFF_FREIGHT_CONTRACT.CONCATENATED", displayName: "PRODUCT.FREIGHT_TARIFF_CONCATENATED", width: 350, cellTemplate: '<div class="ui-grid-cell-contents" tooltip-placement="auto top" uib-tooltip="Clique para acessar o tarifário" tooltip-append-to-body="true"><a href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToTariffFreight(row.entity.TARIFF_FREIGHT_CONTRACT.ID)">{{row.entity.TARIFF_FREIGHT_CONTRACT.CONCATENATED}}</a></div>' };
            const colChargeName: IMonacoColumnDef = { name: "CHARGE.CHARGE_NAME_EXHIBITION.NAME", displayName: "BASIC_DATA.CHARGE", width: 200 };
            const colChargeInitials: IMonacoColumnDef = { name: "CHARGE.CHARGE_NAME_EXHIBITION.CODE", displayName: "FINANCIAL.CHARGE_CODE", width: 150 };
            const colChargeSubGroup: IMonacoColumnDef = { name: "CHARGE.CHARGE_NAME_SUB_GROUP.NAME", displayName: "FINANCIAL.CHARGE_SUBGROUP", width: 300 };
            const colChargeParam: IMonacoColumnDef = { name: "CHARGE.APPLICATION.CODE", displayName: "Aplicação", width: 200 };
            const colChargeEquipWeightRange: IMonacoColumnDef = { name: "CHARGE.EQUIP_WEIGHT_RANGE_VEHICLE", displayName: "PRODUCT.BASIS_COMPLEMENT", width: 200, cellFilter: 'getConcat', searchProps: ['CHARGE.EQUIP_WEIGHT_RANGE_VEHICLE.NAME'] };
            const colChargeCurrency: IMonacoColumnDef = { name: "CHARGE.CURRENCY.CODE", displayName: "GENERAL.CURRENCY", width: 150 };
            const colChargePayMin: IMonacoColumnDef = { name: "CHARGE.PAYMENT_MIN", displayName: "PRODUCT.MIN_PAYMENT", width: 150 };
            const colChargePayUni: IMonacoColumnDef = { name: "CHARGE.PAYMENT_UNITARY", displayName: "PRODUCT.UNIT_PAYMENT", width: 150 };
            const colChargeRecMin: IMonacoColumnDef = { name: "CHARGE.RECEIVING_MIN", displayName: "PRODUCT.MIN_RECEIPT", width: 150 };
            const colChargeRecUni: IMonacoColumnDef = { name: "CHARGE.RECEIVING_UNITARY", displayName: "PRODUCT.UNIT_RECEIPT", width: 150 };
            const colChargeGroup: IMonacoColumnDef = { name: "CHARGE.GROUP", displayName: "REGISTRATION.AGROUP", width: 150, cellFilter: 'YesOrNo', visible: false };
            const colChargeSpec: IMonacoColumnDef = { name: "CHARGE.SPECIFICITY.NAME", displayName: "GENERAL.SPECIFICITY", width: 150, visible: false };
            const colServiceType: IMonacoColumnDef = { name: "MOVE_TYPE.NAME", displayName: "BASIC_DATA.SERVICE_TYPE", width: 150, visible: false };
            const colMoveType: IMonacoColumnDef = { name: "PROCESS_EVENT_TRANSPORT_MODE.NAME", displayName: "REGISTRATION.MODE_OF_TRANSPORT", width: 150, visible: false };
            const colLogisticSolution: IMonacoColumnDef = { name: "LOGISTIC_SOLUTION", displayName: "BASIC_DATA.SERVICE", width: 150, cellFilter: 'getConcat', searchProps: ['LOGISTIC_SOLUTION.NAME'], visible: false };
            const colServiceLevel: IMonacoColumnDef = { name: "SERVICE_LEVEL", displayName: "BASIC_DATA.SERVICE_LEVEL", width: 150, cellFilter: 'getConcat', searchProps: ['SERVICE_LEVEL.NAME'], visible: false };
            const colDTA: IMonacoColumnDef = { name: "DTA", displayName: "REGISTRATION.BONDED_TRUCK", width: 150, cellFilter: 'YesOrNo', visible: false };
            const colPUP: IMonacoColumnDef = { name: "PUP_ROUTING_POINT", displayName: "GENERAL.PICK_UP_CODE", width: 150, cellFilter: 'getConcat', searchProps: ['PUP_ROUTING_POINT.CODE'], cellTemplate: `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PUP_ROUTING_POINT, null, 'CODE')}}</div>`, visible: false };
            const colOTFS: IMonacoColumnDef = { name: "OTFS_ROUTING_POINT", displayName: "GENERAL.ORIGIN_TERMINAL_CODE", width: 150, cellFilter: 'getConcat', searchProps: ['OTFS_ROUTING_POINT.CODE'], cellTemplate: `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.OTFS_ROUTING_POINT, null, 'CODE')}}</div>`, visible: false };
            const colPOL: IMonacoColumnDef = { name: "POL_AOL_ROUTING_POINT", displayName: "GENERAL.LOADING_PLACE_CODE", width: 150, cellFilter: 'getConcat', searchProps: ['POL_AOL_ROUTING_POINT.CODE'], cellTemplate: `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.POL_AOL_ROUTING_POINT, null, 'CODE')}}</div>`, visible: false };
            const colPOD: IMonacoColumnDef = { name: "POD_AOD_ROUTING_POINT", displayName: "GENERAL.UNLOADING_PLACE_CODE", width: 150, cellFilter: 'getConcat', searchProps: ['POD_AOD_ROUTING_POINT.CODE'], cellTemplate: `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.POD_AOD_ROUTING_POINT, null, 'CODE')}}</div>`, visible: false };
            const colDTFS: IMonacoColumnDef = { name: "DTFS_ROUTING_POINT", displayName: "GENERAL.DESTINATION_TERMINAL_CODE", width: 150, cellFilter: 'getConcat', searchProps: ['DTFS_ROUTING_POINT.CODE'], cellTemplate: `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.DTFS_ROUTING_POINT, null, 'CODE')}}</div>`, visible: false };
            const colPLD: IMonacoColumnDef = { name: "PLD_ROUTING_POINT", displayName: "GENERAL.PLACE_OF_DELIVERY_CODE", width: 150, cellFilter: 'getConcat', searchProps: ['PLD_ROUTING_POINT.CODE'], cellTemplate: `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PLD_ROUTING_POINT, null, 'CODE')}}</div>`, visible: false };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE_ROUTE", width: 150, cellFilter: 'YesOrNo', visible: false };
            const colContractVersion: IMonacoColumnDef = { name: "TARIFF_FREIGHT_CONTRACT.VERSION", displayName: "GENERAL.VERSION", width: 150, visible: false };
            const colContractActive: IMonacoColumnDef = { name: "TARIFF_FREIGHT_CONTRACT.ACTIVE", displayName: "GENERAL.ACTIVE_CONTRACT", width: 150, cellFilter: 'YesOrNo', visible: false };
            const colContract: IMonacoColumnDef = { name: "FREIGHT_CONTRACT.NAME", displayName: "BASIC_DATA.FREIGHT_CONTRACT", width: 150, visible: false };
            const colPaymentNature: IMonacoColumnDef = { name: "TARIFF_FREIGHT_CONTRACT.PAYMENT_NATURE.NAME", displayName: "BASIC_DATA.TRANSACTION", width: 150, visible: false };
            const colContractType: IMonacoColumnDef = { name: "TARIFF_FREIGHT_CONTRACT.CONTRACT_TYPE.NAME", displayName: "PRODUCT.FREIGHT_CONTRACT_TYPE", width: 150, visible: false };
            const colContractProduct: IMonacoColumnDef = { name: "TARIFF_FREIGHT_CONTRACT.PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 150, visible: false };
            const colContractTypeCargo: IMonacoColumnDef = { name: "TARIFF_FREIGHT_CONTRACT.TYPE_CARGO.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 150, visible: false };
            const colContractProvider: IMonacoColumnDef = { name: "TARIFF_FREIGHT_CONTRACT.PROVIDER.NAME", displayName: "BASIC_DATA.PROVIDER", width: 150, visible: false };
            const colContractAccounts: IMonacoColumnDef = { name: "TARIFF_FREIGHT_CONTRACT.ACCOUNTS", displayName: "BASIC_DATA.CLIENT", width: 150, cellFilter: 'getConcat', searchProps: ['TARIFF_FREIGHT_CONTRACT.ACCOUNTS.NAME'], visible: false };
            const colContractNetworks: IMonacoColumnDef = { name: "TARIFF_FREIGHT_CONTRACT.NETWORKS", displayName: "BASIC_DATA.EXTERNAL_AGENT_NETWORK", width: 150, cellFilter: 'getConcat', searchProps: ['TARIFF_FREIGHT_CONTRACT.NETWORKS.NAME'], visible: false };
            const colContractDetDem: IMonacoColumnDef = { name: "TARIFF_FREIGHT_CONTRACT.TARIFF_DET_DEM", displayName: "PRODUCT.DET_DEM", width: 150, cellFilter: 'getConcat', searchProps: ['TARIFF_FREIGHT_CONTRACT.TARIFF_DET_DEM.NAME'], visible: false };
            const colContractValidityEvent: IMonacoColumnDef = { name: "TARIFF_FREIGHT_CONTRACT.VALIDITY_EVENT.NAME", displayName: "GENERAL.VALIDITY_BASIS", width: 150, visible: false };
            const colContractValidityStart: IMonacoColumnDef = { name: "TARIFF_FREIGHT_CONTRACT.DISPLAY_VALIDITY_START", displayName: "PRODUCT.VALIDITY_START", width: 150, cellFilter: 'simpleDate', visible: false };
            const colContractValidityEnd: IMonacoColumnDef = { name: "TARIFF_FREIGHT_CONTRACT.DISPLAY_VALIDITY_END", displayName: "PRODUCT.VALIDITY_END", width: 150, cellFilter: 'simpleDate', visible: false };
            const colContractSituation: IMonacoColumnDef = { name: "TARIFF_FREIGHT_CONTRACT.SITUATION.NAME", displayName: "GENERAL.SITUATION", width: 150, visible: false };
            const colContractCommoditySection: IMonacoColumnDef = { name: "TARIFF_FREIGHT_CONTRACT.COMMODITY_SECTION", displayName: "GENERAL.COMMODITY_GROUP_CONDITION", width: 150, cellFilter: 'getConcat', searchProps: ['TARIFF_FREIGHT_CONTRACT.COMMODITY_SECTION.NAME'], visible: false };
            const colContractCommodityCondition: IMonacoColumnDef = { name: "TARIFF_FREIGHT_CONTRACT.COMMODITY_CONDITION", displayName: "GENERAL.COMMODITY_CONDITION", width: 150, cellFilter: 'getConcat', searchProps: ['TARIFF_FREIGHT_CONTRACT.COMMODITY_CONDITION.NAME'], visible: false };
            const colContractCommodityException: IMonacoColumnDef = { name: "TARIFF_FREIGHT_CONTRACT.COMMODITY_EXCEPTION", displayName: "GENERAL.COMMODITY_EXCEPTION", width: 150, cellFilter: 'getConcat', searchProps: ['TARIFF_FREIGHT_CONTRACT.COMMODITY_EXCEPTION.NAME'], visible: false };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'TARIFF_FREIGHT_CONTRACT.CONCATENATED':
                        columnDefs.push(colContractConcatenated);
                        break;
                    case 'CONCATENATED':
                        columnDefs.push(colConcatenated);
                        break;
                    case 'CHARGE.CHARGE_NAME_EXHIBITION':
                        columnDefs.push(colChargeName);
                        columnDefs.push(colChargeInitials);
                        break;
                    case 'CHARGE.CHARGE_NAME_SUB_GROUP':
                        columnDefs.push(colChargeSubGroup);
                        break;
                    case 'CHARGE.APPLICATION':
                        columnDefs.push(colChargeParam);
                        break;
                    case 'CHARGE.EQUIP_WEIGHT_RANGE_VEHICLE':
                        columnDefs.push(colChargeEquipWeightRange);
                        break;
                    case 'CHARGE.CURRENCY':
                        columnDefs.push(colChargeCurrency);
                        break;
                    case 'CHARGE.PAYMENT_MIN':
                        columnDefs.push(colChargePayMin);
                        break;
                    case 'CHARGE.PAYMENT_UNITARY':
                        columnDefs.push(colChargePayUni);
                        break;
                    case 'CHARGE.RECEIVING_MIN':
                        columnDefs.push(colChargeRecMin);
                        break;
                    case 'CHARGE.RECEIVING_UNITARY':
                        columnDefs.push(colChargeRecUni);
                        break;
                    case 'CHARGE.GROUP':
                        columnDefs.push(colChargeGroup);
                        break;
                    case 'CHARGE.SPECIFICITY':
                        columnDefs.push(colChargeSpec);
                        break;
                    case 'MOVE_TYPE':
                        columnDefs.push(colServiceType);
                        break;
                    case 'PROCESS_EVENT_TRANSPORT_MODE':
                        columnDefs.push(colMoveType);
                        break;
                    case 'LOGISTIC_SOLUTION':
                        columnDefs.push(colLogisticSolution);
                        break;
                    case 'SERVICE_LEVEL':
                        columnDefs.push(colServiceLevel);
                        break;
                    case 'DTA':
                        columnDefs.push(colDTA);
                        break;
                    case 'PUP_ROUTING_POINT':
                        columnDefs.push(colPUP);
                        break;
                    case 'OTFS_ROUTING_POINT':
                        columnDefs.push(colOTFS);
                        break;
                    case 'POL_AOL_ROUTING_POINT':
                        columnDefs.push(colPOL);
                        break;
                    case 'POD_AOD_ROUTING_POINT':
                        columnDefs.push(colPOD);
                        break;
                    case 'DTFS_ROUTING_POINT':
                        columnDefs.push(colDTFS);
                        break;
                    case 'PLD_ROUTING_POINT':
                        columnDefs.push(colPLD);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'TARIFF_FREIGHT_CONTRACT.VERSION':
                        columnDefs.push(colContractVersion);
                        break;
                    case 'TARIFF_FREIGHT_CONTRACT.ACTIVE':
                        columnDefs.push(colContractActive);
                        break;
                    case 'FREIGHT_CONTRACT':
                        columnDefs.push(colContract);
                        break;
                    case 'TARIFF_FREIGHT_CONTRACT.PAYMENT_NATURE':
                        columnDefs.push(colPaymentNature);
                        break;
                    case 'TARIFF_FREIGHT_CONTRACT.CONTRACT_TYPE':
                        columnDefs.push(colContractType);
                        break;
                    case 'TARIFF_FREIGHT_CONTRACT.PRODUCT':
                        columnDefs.push(colContractProduct);
                        break;
                    case 'TARIFF_FREIGHT_CONTRACT.TYPE_CARGO':
                        columnDefs.push(colContractTypeCargo);
                        break;
                    case 'TARIFF_FREIGHT_CONTRACT.PROVIDER':
                        columnDefs.push(colContractProvider);
                        break;
                    case 'TARIFF_FREIGHT_CONTRACT.ACCOUNTS':
                        columnDefs.push(colContractAccounts);
                        break;
                    case 'TARIFF_FREIGHT_CONTRACT.NETWORKS':
                        columnDefs.push(colContractNetworks);
                        break;
                    case 'TARIFF_FREIGHT_CONTRACT.TARIFF_DET_DEM':
                        columnDefs.push(colContractDetDem);
                        break;
                    case 'TARIFF_FREIGHT_CONTRACT.VALIDITY_EVENT':
                        columnDefs.push(colContractValidityEvent);
                        break;
                    case 'TARIFF_FREIGHT_CONTRACT.DISPLAY_VALIDITY_START':
                        columnDefs.push(colContractValidityStart);
                        break;
                    case 'TARIFF_FREIGHT_CONTRACT.DISPLAY_VALIDITY_END':
                        columnDefs.push(colContractValidityEnd);
                        break;
                    case 'TARIFF_FREIGHT_CONTRACT.SITUATION':
                        columnDefs.push(colContractSituation);
                        break;
                    case 'TARIFF_FREIGHT_CONTRACT.COMMODITY_SECTION':
                        columnDefs.push(colContractCommoditySection);
                        break;
                    case 'TARIFF_FREIGHT_CONTRACT.COMMODITY_CONDITION':
                        columnDefs.push(colContractCommodityCondition);
                        break;
                    case 'TARIFF_FREIGHT_CONTRACT.COMMODITY_EXCEPTION':
                        columnDefs.push(colContractCommodityException);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
    }

    async register(): Promise<void> {
        try {
            this.sessionService.openTab("app.product.tariffFreight", null, <ITariffFreightExchangeData>{ OPERATION: 'register' });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
    }

    async edit(model: ITariffFreightWizard): Promise<void> {
        try {
            this.editChargesModal(model.TARIFF_FREIGHT_CONTRACT.ID, model.ID);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async editChargesModal(contractId: number, tariffId: number) {
        if (!contractId && contractId != 0) throw Error('contractId is null');
        if (!tariffId && tariffId != 0) throw Error('tariffId is null');
        try {
            if (this.$scope.log && this.$scope.log.show) this.$scope.log.show = false;

            const modalID = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalTariffFreightCharges(
                {
                    modalID: modalID,
                    contractId: contractId,
                    tariffId: tariffId
                }
            );
            await modalInstance.result.then(async (value) => {
                if (value) await this.$gridService.handleBackgroundUpdate();
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }
}

import * as angular from "angular";
import * as moment from 'moment';
import { StringUtil } from "../../common/util/StringUtil";
import { IGridFormController, IGridFormServiceScope, GridFormService } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IColumnDef, IGridOptions } from "ui-grid";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { IMonacoColumnDef } from "@services/GridService2";
import { EOperation, EProviderTypeId, EDomesticProviderType } from "@enums/GenericData";
import { ITariffDomesticModel, ITariffDomesticExchangeData } from "@models/interface/product/TariffDomesticModel";
import { ISessionService } from "@services/SessionService";
import { IUploader, IFormData, IFileItem, ISuccessItemResponse, IUploadItem } from "@models/interface/common/IMonacoUpload";
import { BrowserTitle } from "../../common/BrowserTitle";
import { IFloatingMenu } from "../../common/interface/IFloatingMenu";
import { SelectorModel } from "../../common/model/SelectorModel";
import { ILinkParameter } from "../../common/model/ModelParameter";
import { fileUploader as FileUploader } from 'angular-file-upload';

interface IAuxModel {
    direction: SelectorModel;
    product: SelectorModel;
}

interface ITariffDomesticScope extends IGridFormServiceScope {
    model: ITariffDomesticModel;
    auxModel: IAuxModel;
    sessionService: ISessionService;
    uploader: IUploader;
    gridOptions: IGridOptions;
    menuFloating: IFloatingMenu;
    collapseHeader: (elementId: string, state?: string, navigate?: boolean) => void;
    generateConcatenatedTariff: (tariffIndex: number) => void;
    viewTariffDomesticHistory: (tariffFreight: ITariffDomesticModel, idTariffFreight?: number) => Promise<void>;
    goToRoutingPoint: (routingPoints: SelectorModel[]) => void;
    goToAccount: () => void;
    goToProvider: (id: number) => void;
    goToLogisticSolution: (id: number) => void;
    goToAgent: (id: number) => void;
    collapseMainLeg: (mainLegIndex: number) => void;
    copyToValidTariffs: (tariffFreight: ITariffDomesticModel) => Promise<void>;
}

export class TariffDomesticHistoryRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: ITariffDomesticScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private collapseState: string;
    private formName: string;
    private gridName: string;
    private fileUploader: FileUploader;
    private $timeout: ng.ITimeoutService

    constructor($injector: ng.Injectable<any>, $scope: ITariffDomesticScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$scope.additionalIndexSelectorValidity = ['$parentIndex'];
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.formName = 'tariffDomesticHistory';
        this.gridName = 'GRID_TARIFF_DOMESTIC_HISTORY'
        this.collapseState = "hide";
        this.fileUploader = $injector.get('FileUploader');
        this.$timeout = $injector.get('$timeout');
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    getFisFilesGenericRoute(): string {
        const baseRoute = '/fis/filesGeneric';
        const uploadRoute = this.config.fisUrl + baseRoute;
        return uploadRoute;
    }

    async $onInit(): Promise<void> {
        try {
            this.$scope.uploader = this.getFileUploaderDefault();
            this.$baseUrl = this.getUrlProduct();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.initForm(this, 'form', this.formName, 'GENERAL.MENU.TARIFF_DOMESTIC', false);
            await this.initGrid(this.gridName, '/tariffDomesticHistory/list', true, true, null, true, true);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initGridColumns(columns: string[]): IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<a ng-click="grid.appScope.viewTariffDomesticHistory(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true"><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyToValidTariffs(row.entity)" class="text-orange v-middle copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true"><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: 75,
            cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${copy}</div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };

        gridColumns.addColumn(colActions);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) {
            column.filter = column.filter
                ? column.filter
                : { condition: this.$gridService.filterSelectObject };

            gridColumns.addColumn(column)
        }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colDirection: IMonacoColumnDef = { name: "DIRECTION.NAME", displayName: "PRODUCT.WAY", width: 100 };
            const colOperatorType: IMonacoColumnDef = { name: "OPERATOR_TYPE.NAME", displayName: "ENTITY.COMPANY_TYPE", width: 150 };
            const colOperator: IMonacoColumnDef = { name: "OPERATOR_REPL.NAME", displayName: "BASIC_DATA.SERVICE_PROVIDER", width: 150 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 100 };
            const colTypeCargo: IMonacoColumnDef = { name: "TYPE_CARGO.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 120 };
            const colAccounts: IMonacoColumnDef = { name: "ACCOUNTS.NAME", displayName: "BASIC_DATA.CLIENT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNTS, null, "NAME")}}</div>' };
            const colAccountsFantasyName: IMonacoColumnDef = { name: "ACCOUNTS.FANTASY_NAME", displayName: "GENERAL.CUSTOMER_COMMERCIAL_NAME", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNTS, null, "FANTASY_NAME")}}</div>' };
            const colDta: IMonacoColumnDef = { name: "DTA", displayName: "REGISTRATION.BONDED_TRANSPORT", width: 80, cellFilter: "YesOrNo" };
            const colVersion: IMonacoColumnDef = { name: "VERSION", displayName: "GENERAL.VERSION", width: 100 };
            const colValidityStart: IMonacoColumnDef = { name: "DISPLAY_VALIDITY_START", displayName: "PRODUCT.VALIDITY_START", width: 150, cellFilter: "simpleDate" };
            const colValidityEnd: IMonacoColumnDef = { name: "DISPLAY_VALIDITY_END", displayName: "PRODUCT.VALIDITY_END", width: 150, cellFilter: "simpleDate" };
            const colLogisticSolution: IMonacoColumnDef = { name: "LOGISTIC_SOLUTION.NAME", displayName: "BASIC_DATA.SERVICE", width: 150 };
            const colSituation: IMonacoColumnDef = { name: "SITUATION.NAME", displayName: "GENERAL.SITUATION", width: 150 };
            const colConcatenated: IMonacoColumnDef = { name: "CONCATENATED", displayName: "GENERAL.CONCATENATED", width: 150 };
            const colUsedOffer: IMonacoColumnDef = { name: "TARIFF_DOMESTIC_USED_OFFER.OFFER_CODE_EXHIBITION", displayName: "Ofertas", width: 160, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TARIFF_DOMESTIC_USED_OFFER, null, "OFFER_CODE_EXHIBITION")}}</div>' };
            const colOrigin: IMonacoColumnDef = { name: "MAIN_LEG_ORIGIN_REPL", displayName: "BASIC_DATA.ORIGIN", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.MAIN_LEG_ORIGIN_REPL)}}</div>' };
            const colDestination: IMonacoColumnDef = { name: "MAIN_LEG_DESTINATION_REPL", displayName: "BASIC_DATA.DESTINATION", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.MAIN_LEG_DESTINATION_REPL)}}</div>' };
            const colSecondaryLeg: IMonacoColumnDef = { name: "SECONDARY_LEG_REPL", displayName: "PRODUCT.ADDITIONAL_LEG_DOMESTIC", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.SECONDARY_LEG_REPL)}}</div>' };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 80, cellFilter: "YesOrNo" };
            const colValidityExpired: IMonacoColumnDef = { name: "VALIDITY_EXPIRED", displayName: "GENERAL.EXPIRED", width: 150, cellFilter: "YesOrNo" };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'DIRECTION':
                        columnDefs.push(colDirection);
                        break;
                    case 'OPERATOR_TYPE':
                        columnDefs.push(colOperatorType);
                        break;
                    case 'OPERATOR':
                        columnDefs.push(colOperator);
                        break;
                    case 'ID_LOGISTIC_SOLUTION':
                        columnDefs.push(colLogisticSolution);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'TYPE_CARGO':
                        columnDefs.push(colTypeCargo);
                        break;
                    case 'ACCOUNTS':
                        columnDefs.push(colAccounts, colAccountsFantasyName);
                        break;
                    case 'DTA':
                        columnDefs.push(colDta);
                        break;
                    case 'VERSION':
                        columnDefs.push(colVersion);
                        break;
                    case 'DISPLAY_VALIDITY_START':
                        columnDefs.push(colValidityStart);
                        break;
                    case 'DISPLAY_VALIDITY_END':
                        columnDefs.push(colValidityEnd);
                        break;
                    case 'SITUATION':
                        columnDefs.push(colSituation);
                        break;
                    case 'TARIFF_DOMESTIC_USED_OFFER':
                        columnDefs.push(colUsedOffer);
                        break;
                    case 'CONCATENATED':
                        columnDefs.push(colConcatenated);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'VALIDITY_EXPIRED':
                        columnDefs.push(colValidityExpired);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                    case 'MAIN_LEG':
                        columnDefs.push(colOrigin);
                        columnDefs.push(colDestination);
                        break;
                    case 'SECONDARY_LEG':
                        columnDefs.push(colSecondaryLeg);
                        break;
                };
            }

            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            VERSION: null,
            CONCATENATED: null,
            CONCATENATED_COMPLEMENT: null,
            OPERATOR_TYPE: null,
            ID_PROVIDER: null,
            PROVIDER: null,
            ID_LOGISTIC_SOLUTION: null,
            LOGISTIC_SOLUTION: null,
            ID_LEGAL_PERSON_AGENT: null,
            LEGAL_PERSON_AGENT: null,
            PRODUCT: null,
            ACCOUNTS: null,
            TYPE_CARGO: null,
            DIRECTION: null,
            VALIDITY_START: null,
            DISPLAY_VALIDITY_START: null,
            VALIDITY_END: null,
            DISPLAY_VALIDITY_END: null,
            SPOT: false,
            DTA: false,
            MAIN_LEG: null,
            OBSERVATION: null,
            FILES: null,
            FILES_REMOVE: null,
            FILES_NEW: null,
            SITUATION: null,
            ACTIVE: true,
            VALIDITY_EXPIRED: true,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            SEARCH_FIELDS: null,
            DOCUMENT_ERROR: null
        };
        this.$scope.auxModel = {
            product: null,
            direction: null
        }
    }

    initScopeFunctions(): void {
        this.$scope.viewTariffDomesticHistory = async (tariffDomestic: ITariffDomesticModel): Promise<void> => {
            this.fetchData(tariffDomestic.ID, EOperation.VIEW);
        }

        this.$scope.copyToValidTariffs = async (tariff: ITariffDomesticModel): Promise<void> => {
            this.$scope.sessionService.openTab('app.product.tariffDomestic', null, <ITariffDomesticExchangeData>{ OPERATION: "register", ID: tariff.ID ? tariff.ID.toString() : null });
        }

        this.$scope.collapseHeader = (elementId: string, state?: string, navigate?: boolean) => {
            this.collapseHeader(elementId, state, navigate);
        }

        this.$scope.generateConcatenatedTariff = (tariffIndex: number): void => {
            this.generateConcatenatedTariff(tariffIndex);
        }

        this.$scope.goToRoutingPoint = (routingPoints: SelectorModel[]): void => {
            this.$scope.sessionService.openTab("app.registration.routingPoint", <ILinkParameter>{ ID: this.$scope.getCONCAT(routingPoints, null, 'ID') });
        }

        this.$scope.goToAccount = () => {
            this.$scope.sessionService.openTab("app.commercial.account", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.ACCOUNTS, null, 'ID') });
        }

        this.$scope.goToProvider = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.provider", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToLogisticSolution = (id: number): void => {
            this.$scope.sessionService.openTab("app.product.domesticService", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToAgent = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.agent", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.uploader.onSuccessItem = (item: IFileItem, response: ISuccessItemResponse): void => {
            this.onSuccessItem(item, response);
        }

        this.$scope.collapseMainLeg = (mainLegIndex: number): void => {
            this.collapseMainLeg(mainLegIndex);
        }
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.getTariffDomesticHistoryById(id);

            if (request && request.data) {
                const model = angular.copy(request.data);

                this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")}`;
            BrowserTitle.$id = this.$scope.model.CONCATENATED;
            this.$scope.disableElements(true);
            if (this.$scope.model.CONCATENATED) {
                this.$scope.menuFloating.infos = [
                    { text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }
                ];
            }
            this.initPanels(['collapseBasicData']);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private initPanels(open: string[] = ['collapseBasicData']): void {
        try {
            const panels = document.getElementsByClassName("toggle-me");
            if (panels && panels.length > 0) {
                for (const item of open) {
                    $("#" + item)["collapse"]("show");
                }
                for (let i = 0; i < panels.length; i++) {
                    const panel = panels[i];
                    if (open.indexOf(panel.id) === -1) {
                        $("#" + panel.id)["collapse"]("hide");
                    }
                }
            }
            this.$scope.navigateBetweenIds(open[0]);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getTariffDomesticHistoryById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();

        try {
            const result = await this.RestService.getObjectAsPromise(
                `${this.getUrlProduct()}/tariffDomesticHistory/getById/${id}`,
                30000,
                null,
                false
            );

            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private collapseHeader(elementId: string, state?: string, navigate?: boolean): void {
        if (elementId === "collapseAll") {
            this.collapseState = this.collapseState == "hide" ? "show" : "hide";
            $('.toggle-me')["collapse"](state ? state : this.collapseState);
        }
        else if (elementId != "registerBody") {
            $("#" + elementId)["collapse"](state ? state : 'toggle');
        }
        if (navigate && state != "hide") this.$scope.navigateBetweenIds(elementId);
    }

    private collapseMainLeg(mainLegIndex: number): void {
        if (!mainLegIndex && mainLegIndex != 0) throw Error("mainLegIndex is null.");
        if (this.$scope.model.MAIN_LEG && this.$scope.model.MAIN_LEG.length > 0 && this.$scope.model.MAIN_LEG[mainLegIndex]) {
            if (this.$scope.model.MAIN_LEG[mainLegIndex].SHOW) {
                this.collapseHeader('collapseMainLeg' + mainLegIndex, 'hide');
                this.$timeout(() => { this.$scope.model.MAIN_LEG[mainLegIndex].SHOW = false; }, 500);
            }
            else {
                this.$scope.model.MAIN_LEG[mainLegIndex].SHOW = true;
                this.collapseHeader('collapseMainLeg' + mainLegIndex, 'show');
            }
        }
    }

    private generateConcatenatedTariff(tariffIndex: number): void {
        if (!tariffIndex && tariffIndex != 0) throw Error('tariffIndex is null');
        try {
            const separator = " | ";
            let concatenated = "";

            if ((this.$scope.model.PROVIDER && this.$scope.model.PROVIDER.CODE && this.$scope.model.PROVIDER.CODE.length) || (this.$scope.model.OPERATOR_TYPE && this.$scope.model.OPERATOR_TYPE.ID === EDomesticProviderType.TRANSPORT_COMPANY)) {
                concatenated = concatenated.length > 0
                    ? concatenated.concat(separator, this.$scope.model.PROVIDER && this.$scope.model.PROVIDER.TYPE && (this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.SHIPOWNER || this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.AIRLINE) ? this.$scope.model.PROVIDER.CODE : this.$scope.model.PROVIDER.NAME)
                    : concatenated.concat(this.$scope.model.PROVIDER && this.$scope.model.PROVIDER.TYPE && (this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.SHIPOWNER || this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.AIRLINE) ? this.$scope.model.PROVIDER.CODE : this.$scope.model.PROVIDER.NAME);
            }

            if (this.$scope.model.LEGAL_PERSON_AGENT && this.$scope.model.LEGAL_PERSON_AGENT.NAME && this.$scope.model.LEGAL_PERSON_AGENT.NAME.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.LEGAL_PERSON_AGENT.NAME) : concatenated.concat(this.$scope.model.LEGAL_PERSON_AGENT.NAME);
            }

            if (this.$scope.model.ACCOUNTS && this.$scope.model.ACCOUNTS.length > 0) {
                concatenated = concatenated.length > 0
                    ? concatenated.concat(separator, this.$scope.model.ACCOUNTS.map(obj => { return obj.NAME }).join(','))
                    : concatenated.concat(this.$scope.model.ACCOUNTS.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.ID && this.$scope.model.PRODUCT.ID.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PRODUCT.ID) : concatenated.concat(this.$scope.model.PRODUCT.ID);
            }

            if (this.$scope.model.TYPE_CARGO && this.$scope.model.TYPE_CARGO.NAME) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.TYPE_CARGO.NAME) : concatenated.concat(this.$scope.model.TYPE_CARGO.NAME);
            }

            if (this.$scope.model.DIRECTION && this.$scope.model.DIRECTION.ID && this.$scope.model.DIRECTION.ID.length > 0) {
                let direction = null;
                if (this.$scope.model.DIRECTION.ID === '1') {
                    direction = 'OR';
                } else if (this.$scope.model.DIRECTION.ID === '2') {
                    direction = 'DE';
                }
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, direction) : concatenated.concat(direction);
            }

            if (this.$scope.model.VERSION) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, 'V' + this.$scope.model.VERSION) : concatenated.concat('V' + this.$scope.model.VERSION);
            }

            if (this.$scope.model.CONCATENATED_COMPLEMENT && this.$scope.model.CONCATENATED_COMPLEMENT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CONCATENATED_COMPLEMENT) : concatenated.concat(this.$scope.model.CONCATENATED_COMPLEMENT);
            }

            if (this.$scope.model.DISPLAY_VALIDITY_START) {
                const validity = this.$scope.model.DISPLAY_VALIDITY_END ? moment(this.$scope.model.DISPLAY_VALIDITY_START).format('DD/MM/YY') + '-' + moment(this.$scope.model.DISPLAY_VALIDITY_END).format('DD/MM/YY') : moment(this.$scope.model.DISPLAY_VALIDITY_START).format('DD/MM/YY');
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, validity) : concatenated.concat(validity);
            }

            this.$scope.model.CONCATENATED = StringUtil.formatConcatenatedChars(concatenated);

            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [
                    { text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }
                ];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getMenuFloatingDefault(): IFloatingMenu {
        return {
            tooltipPlacement: "auto bottom",
            textTooltip: "PRODUCT.TARIFF_DATA",
            infos: [
                { text: "Tarifário", class: "text-rouge font-bold" }
            ],
            btnActiveDisabled: false
        };
    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'tariff_files', formName: this.formName, folder: this.formName };
            return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData] });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private onSuccessItem(item: IFileItem, response: ISuccessItemResponse): void {
        try {
            if (!item) throw Error('item parameter is null');
            if (!response) throw Error('response parameter is null');

            if (response.data && response.data.length > 0) {
                if (!this.$scope.model.FILES_NEW) this.$scope.model.FILES_NEW = [];

                for (let dataItem of response.data) {
                    const file: IUploadItem = {
                        FILE_DISPLAY_NAME: dataItem.displayFileName,
                        FILE_NAME: dataItem.fileName,
                        FILE_TYPE: dataItem.fileType,
                        FILE_PATH: dataItem.filePath,
                        FILE_URL: dataItem.fileUrl,
                        FILE_HASH: dataItem.fileHash,
                        FILE_SIZE: dataItem.fileSize,
                        FORM_NAME: dataItem.formName
                    }
                    this.$scope.model.FILES_NEW.push(file)
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }
}
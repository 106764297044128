export abstract class ArrayUtil {

    public static isArray(object) {
        if (object === Array) return true;
        if (typeof Array.isArray === "function") return Array.isArray(object);
        return !!(object instanceof Array);
    }


    // TODO LUNELLI : THIS HAS TO DIE
    public static indexOfHash(data: any, list: Array<any>) {

        let index = -1;
        list.forEach((item, key: number) => {
            if (data.$$hashKey == item.$$hashKey) {
                index = key;
                return;
            }
        });

        return index;

    }

    // TODO LUNELLI : THIS HAS TO DIE
    public static indexOfMongo(data, list) {

        let index = -1;
        list.forEach((result, key) => {
            if (result._id == data._id) {
                index = key;
                return;
            }
        });

        return index;
    }

    public static isEmptyArray<T>(arrayObj: T[]): boolean {
        return (arrayObj && arrayObj.length == 0 || !arrayObj);
    }
}
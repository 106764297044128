import * as angular from "angular";
import { SSEService } from "@appServices/SSEService";
import { IGridFormServiceScope, GridFormService, IMonacoRequest } from '@services/GridFormService';
import { IMonacoColumnDef } from '@services/GridService2';
import { IModalService } from "@services/ModalService";
import { DataProcessService } from "@services/DataProcessService";
import { ProductService } from "@services/ProductService";
import { ITariffLocalModel } from "@models/interface/product/TariffLocal"
import { EDataOriginTypeId, EDataOriginTypeName, EOperation } from "@enums/GenericData";
import { IConsolidatedCharge } from "WBA-Model/dist/interface/operational/consolidated/ConsolidatedCharge";
import { IConsolidatedLog } from 'WBA-Model/dist/interface/operational/consolidated/ConsolidatedLog';
import { IConsolidatedWizardModalScope } from "./ConsolidatedWizardModalController";
import { ICustomLogProperties } from "WBA-Model/dist/interface/common/IViewLog";
import { IRepurchase, IResponseRepurchaseSave, IResponseRepurchaseWizardGet } from 'WBA-Model/dist/interface/operational/repurchase/Repurchase'
import { IRepurchaseError } from "WBA-Model/dist/interface/dataProcess/RepurchaseError";

interface IConsolidatedChargeWizardPanelScope extends IGridFormServiceScope {
    // Grid Consolidated Charge
    gridVisibleStatus: boolean;

    // Log
    logList: IConsolidatedLog[];
    customLogProperties: ICustomLogProperties[];

    errorsOnFilter: IRepurchaseError[];

    // Grid Actions
    viewConsolidatedCharge: Function;
    viewLogConsolidatedCharge: Function;
    editConsolidatedCharge: Function;
    copyConsolidatedCharge: Function;
    disabledConsolidated: Function;

    // Modal Actions
    openModalAddCharge: () => Promise<void>
    openModalRetryCharge: () => Promise<void>
    openModalDisplayTariff: () => Promise<void>;
}

export class ConsolidatedChargeWizardPanelController extends GridFormService {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IConsolidatedChargeWizardPanelScope;
    private $consolidatedScope: IConsolidatedWizardModalScope;
    private $filter: ng.FilterFactory;
    private SSEService: SSEService;
    private ModalService: IModalService;
    private DataProcessService: DataProcessService;
    private ProductService: ProductService;
    private $sce: angular.ISCEService;
    private formName: string;
    private gridName: string;

    constructor($injector: ng.Injectable<any>, $scope: IConsolidatedChargeWizardPanelScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$consolidatedScope = <IConsolidatedWizardModalScope>$scope.$parent.$parent;
        this.formName = 'consolidatedCharge';
        this.gridName = 'GRID_CONSOLIDATED_CHARGE_WIZARD';
        this.$filter = $injector.get('$filter');
        this.$sce = $injector.get('$sce');
        this.ModalService = $injector.get('ModalService');
        this.DataProcessService = $injector.get('DataProcessService');
        this.ProductService = $injector.get('ProductService');
        this.$scope.customLogProperties = this.getCustomLogProperties();
        this.$baseUrl = this.DataProcessService.$route;
        this.SSEService = new SSEService($injector, $scope, this.formService);
    }

    async $onInit(): Promise<void> {
        try {
            this.initForm(this, 'form', this.formName, null, false);
            await this.gridService.initGridStandAlone(this.gridName, `/consolidatedCharge/list/${this.$consolidatedScope.model.ID}`, this.buildColumns, true, true, 30000, true, true);
            this.$scope.viewConsolidatedCharge = this.viewConsolidatedCharge;
            this.$scope.viewLogConsolidatedCharge = this.viewLogConsolidatedCharge;
            this.$scope.editConsolidatedCharge = this.editConsolidatedCharge;
            this.$scope.copyConsolidatedCharge = this.copyConsolidatedCharge;
            this.$scope.disabledConsolidated = this.$consolidatedScope.disabledConsolidated;
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        // Modal
        this.$scope.openModalAddCharge = () => this.openModalAddCharge();
        this.$scope.openModalRetryCharge = () => this.openModalRetryCharge();
        this.$scope.openModalDisplayTariff = async () => {
            await this.openModalDisplayTariff();
        };
    }

    initModel(): void { }

    buildColumns = (): IMonacoColumnDef[] => {
        const iconSize: number = 100;

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewConsolidatedCharge(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="!grid.appScope.disabledConsolidated() && grid.appScope.editConsolidatedCharge(row.entity)" ng-disabled="grid.appScope.disabledConsolidated()" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogConsolidatedCharge(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="!grid.appScope.disabledConsolidated() && grid.appScope.copyConsolidatedCharge(row.entity)" ng-disabled="grid.appScope.disabledConsolidated()" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;

        const columnsAvailable = [
            { title: "BASIC_DATA.CHARGE", visibility: true, width: 150, name: "CHARGE.NAME", content: 'row.entity.CHARGE.NAME' },
            { title: "BASIC_DATA.CHARGE_DISPLAY", visibility: true, width: 150, name: "CHARGE_EXHIBITION.NAME", content: 'row.entity.CHARGE_EXHIBITION.NAME' },
            { title: "GENERAL.HOLDER_TYPE", visibility: true, width: 150, name: "OUTCOME.TRANSACTION.NAME", content: 'row.entity.OUTCOME.TRANSACTION.NAME' },
            { title: "GENERAL.HOLDER", visibility: true, width: 150, name: "OUTCOME.PEOPLE.NAME", content: 'row.entity.OUTCOME.PEOPLE.NAME' },
            { title: "FINANCIAL.CHARGE_BASIS", visibility: true, width: 150, name: "OUTCOME.APPLICATION.NAME", content: 'row.entity.OUTCOME.APPLICATION.NAME' },
            { title: "GENERAL.CURRENCY", visibility: true, width: 150, name: "OUTCOME.CURRENCY.CODE", content: 'row.entity.OUTCOME.CURRENCY.CODE' },
            { title: "OPERATIONAL.AMOUNT", visibility: true, width: 150, name: "OUTCOME.AMOUNT", content: 'row.entity.OUTCOME.AMOUNT' },
            { title: "BASIC_DATA.UNIT", visibility: true, width: 150, name: "OUTCOME.UNITARY", content: 'row.entity.OUTCOME.UNITARY | number: 2' },
            { title: "BASIC_DATA.MINIMUM", visibility: true, width: 150, name: "OUTCOME.MINIMUM", content: 'row.entity.OUTCOME.MINIMUM | number: 2' },
            { title: "FINANCIAL.ACTUAL_TOTAL_AMOUNT", visibility: true, width: 150, name: "OUTCOME.TOTAL", content: 'row.entity.OUTCOME.TOTAL | number: 2' },
            { title: "GENERAL.REMARKS", visibility: true, width: 150, name: "OUTCOME.OBSERVATION", content: 'row.entity.OUTCOME.OBSERVATION' },
            { title: "FINANCIAL.CHARGE_TYPE", visibility: true, width: 150, name: "OUTCOME.CHARGE_TYPE.ID", content: 'row.entity.OUTCOME.CHARGE_TYPE.ID' },
            { title: "FINANCIAL.SHOWN_FOR", visibility: true, width: 150, name: "OUTCOME.SHOW_FOR_GROUP.NAME", template: "<div class='grid-padding' >{{grid.appScope.getCONCAT(row.entity.OUTCOME.SHOW_FOR_GROUP)}}</div>" }
        ];

        const builtColumns = columnsAvailable.map(columnAvailable => {
            const column: IMonacoColumnDef = {
                headerCellClass: this.gridService.highlightFilteredHeader.bind(this.gridService),
                filter: { condition: this.gridService.filterSelectObject },
                displayName: columnAvailable.title,
                width: columnAvailable.width,
                name: columnAvailable.name,
                filterCellFiltered: true,
                enableFiltering: true,
                enableSorting: false,
                enableHiding: true,
                pinnedLeft: true,
                enablePinning: false,
                enableColumnMoving: true,
                enableColumnResizing: true,
                visible: columnAvailable.visibility,
            };

            if (columnAvailable.content) {
                column.cellTemplate = `<span uib-tooltip="{{${columnAvailable.content}}}"
                                             class="grid-padding ellipsize"
                                             tooltip-placement="auto top"
                                             style="max-width: 100%;"
                                             tooltip-enable="true"
                                             ellipsis-tooltip
                                            >{{${columnAvailable.content}}}</span>`;
            }

            if (columnAvailable.template) {
                column.cellTemplate = columnAvailable.template;
            }

            return column;
        });

        builtColumns.unshift({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: iconSize,
            cellTemplate: (view + edit + viewLog + copy),
            enableCellEdit: true,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        })

        return builtColumns;
    }

    viewConsolidatedCharge = async (consolidatedCharge: IConsolidatedCharge) => {
        try {
            this.openConsolidatedChargeModal(EOperation.VIEW, await this.getConsolidatedChargeById(consolidatedCharge._id));
        } catch (ex) {
            this.handleError(ex);
        }
    }

    viewLogConsolidatedCharge = (consolidatedCharge: IConsolidatedCharge) => {
        this.$scope.viewLog(consolidatedCharge, consolidatedCharge._id.toString());
    }


    editConsolidatedCharge = async (consolidatedCharge: IConsolidatedCharge) => {
        try {
            this.openConsolidatedChargeModal(EOperation.EDIT, await this.getConsolidatedChargeById(consolidatedCharge._id));
        } catch (ex) {
            this.handleError(ex);
        }
    }

    copyConsolidatedCharge = async (consolidatedCharge: IConsolidatedCharge) => {
        try {
            const _consolidatedCharge = await this.getConsolidatedChargeById(consolidatedCharge._id);
            _consolidatedCharge.OUTCOME.CHARGE_TYPE = { ID: EDataOriginTypeId.MANUAL, NAME: EDataOriginTypeName.MANUAL };
            await this.clearFields(_consolidatedCharge);
            this.openConsolidatedChargeModal(EOperation.COPY, _consolidatedCharge);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openConsolidatedChargeModal(operation: EOperation, consolidatedCharge: IConsolidatedCharge): Promise<void> {
        try {
            if (!operation) return;
            if (!consolidatedCharge) return;

            const modalId: number = this.ModalService.newModal();
            const modalInstance = await this.ModalService.showModalInfo(
                {
                    modalID: modalId,
                    template: require("../view/modal/consolidatedWizardChargeModal.html"),
                    size: 'lg',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: 'OPERATIONAL.CONSOL'
                },
                {
                    consolidatedScopeModel: this.$consolidatedScope.model,
                    model: consolidatedCharge,
                    operation,
                }
            );

            const modalScope = await this.ModalService.getModalScope(modalId);
            await modalScope.$applyAsync();

            const apply = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (apply) {
                this.externalSearch = true;
                await this.updateGrid();
                this.formService.notifySuccess(this.formService.getTranslate((operation == EOperation.EDIT) ? 'OPERATIONAL.UPDATE_CONSOLIDATED_CHARGE_SUCCESS' : 'OPERATIONAL.CONSOL_CHARGE_REGISTERED_SUCCESS'));
                this.$scope.$applyAsync();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openModalRetryCharge(): Promise<void> {
        try {

            const repurchase: IRepurchase = {
                _id: null,
                ID_PROCESS: null,
                ID_CONSOLIDATED: this.$consolidatedScope.model.ID,
                PROCESS_NUMBER: null,
                PROVIDER: {
                    ID: this.$consolidatedScope.model.PROVIDER.ID,
                    NAME: this.$consolidatedScope.model.PROVIDER.NAME,
                    CODE: this.$consolidatedScope.model.PROVIDER.SCAC
                },
                SHIPMENT_DATE: this.$consolidatedScope.model.ETD_DATE,
                AIRPORT_OF_ORIGIN: this.$consolidatedScope.model.ORIGIN,
                AIRPORT_OF_DESTINATION: this.$consolidatedScope.model.DESTINATION,
                FREIGHT_ROUTE: null,
                ORIGIN_TARIFF_LOCAL: null,
                DESTINATION_TARIFF_LOCAL: null,
                CHARGE: null
            }
            repurchase.SHIPMENT_DATE.setHours(0, 0, 0, 0);

            this.block();
            const resultOperation = await this.DataProcessService.post('/repurchase/save', { data: repurchase, oldData: null, retry: null, reprocessing: null, consolidatedChargeRetry: true }, 30000);
            if (resultOperation && resultOperation.data && resultOperation.data.data) {
                const result: IResponseRepurchaseSave = resultOperation.data.data;
                this.$scope.errorsOnFilter = result.REPURCHASE_ERROR_LIST;
                if (this.$scope.errorsOnFilter && this.$scope.errorsOnFilter.length) {
                    this.formService.notifyError(this.formService.getTranslate("GENERAL.ERROR_SENDING_REQUEST"));
                }
            }

            let show = false;
            while (!show) {
                await new Promise((resolve) => setTimeout(resolve, 3000));
                show = await this.repurchaseProgressBar();
            }

        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            this.formService.notifySuccess(this.formService.getTranslate('OPERATIONAL.UPDATE_CONSOLIDATED_CHARGE_SUCCESS'));
        }
    }

    private async repurchaseProgressBar(): Promise<boolean> {
        let show = false;
        try {
            const resultOperation = await this.DataProcessService.get(`/repurchase/progressBar/${null}/${this.$consolidatedScope.model.ID}`, null, 30000);
            if (resultOperation && resultOperation.data && resultOperation.data.data) {
                const result: IResponseRepurchaseWizardGet = resultOperation.data.data;
                show = result.SHOW;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            return show
        }
    }

    private async openModalAddCharge(): Promise<void> {
        try {
            const modalId: number = this.ModalService.newModal();
            const modalInstance = await this.ModalService.showModalInfo(
                {
                    modalID: modalId,
                    template: require("../view/modal/consolidatedWizardChargeModal.html"),
                    formService: EOperation.NEW,
                    size: 'lg',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: 'OPERATIONAL.CONSOL'
                },
                {
                    consolidatedScopeModel: this.$consolidatedScope.model
                }
            );

            const modalScope = await this.ModalService.getModalScope(modalId);
            await modalScope.$applyAsync();

            const apply = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (apply) {
                this.externalSearch = true;
                await this.updateGrid();
                this.formService.notifySuccess(this.formService.getTranslate('OPERATIONAL.CONSOL_CHARGE_REGISTERED_SUCCESS'));
                this.$scope.$applyAsync();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openModalDisplayTariff(): Promise<void> {
        try {
            const tariffLocalOrigin = this.$consolidatedScope.model.TARIFF_LOCAL_ORIGIN ? await this.getTarifftLocalById(parseInt(this.$consolidatedScope.model.TARIFF_LOCAL_ORIGIN.ID)) : null;
            const tariffLocalDestination = this.$consolidatedScope.model.TARIFF_LOCAL_DESTINATION ? await this.getTarifftLocalById(parseInt(this.$consolidatedScope.model.TARIFF_LOCAL_DESTINATION.ID)) : null;
            const tariffFreight = this.$consolidatedScope.model.TARIFF_FREIGHT ? await this.getTariffFreightById(parseInt(this.$consolidatedScope.model.TARIFF_FREIGHT.ID)) : null;

            const htmlConsolidatedTariffs = `
                    <div class="row">
                        <div class="col-lg-12">
                            <table class="table gray-border table-bordered m-b-none table-responsive">
                                <thead>
                                    <th width="150px">${this.formService.getTranslate("GENERAL.TYPE")}</th>
                                    <th>${this.formService.getTranslate("PRODUCT.TARIFF")}</th>
                                    <th width="200px">${this.formService.getTranslate("GENERAL.VALIDITY")}
                                    </th>
                                </thead>
                                <tbody>
                                    <tr ng-show="${tariffLocalOrigin}">
                                        <td>${this.formService.getTranslate("PRODUCT.TARIFF_LOCAL_ORIGIN")}</td>
                                        <td>${tariffLocalOrigin ? tariffLocalOrigin.CONCATENATED : ''}</td>
                                        <td>
                                             ${tariffLocalOrigin ? this.$filter("simpleDate")(tariffLocalOrigin.DISPLAY_VALIDITY_START) : ''} - 
                                             ${tariffLocalOrigin ? this.$filter("simpleDate")(tariffLocalOrigin.DISPLAY_VALIDITY_END) : ''}
                                        </td>
                                    </tr>
                                    <tr ng-show="${tariffLocalDestination}">
                                        <td>${this.formService.getTranslate("PRODUCT.TARIFF_LOCAL_DESTINATION")}</td>
                                        <td>${tariffLocalDestination ? tariffLocalDestination.CONCATENATED : ''}</td>
                                        <td>
                                            ${tariffLocalDestination ? this.$filter("simpleDate")(tariffLocalDestination.DISPLAY_VALIDITY_START) : ''} - 
                                            ${tariffLocalDestination ? this.$filter("simpleDate")(tariffLocalDestination.DISPLAY_VALIDITY_END) : ''}
                                        </td>
                                    </tr>
                                    <tr ng-show="${tariffFreight}">
                                        <td>${this.formService.getTranslate("PRODUCT.FREIGHT_TARIFF")}</td>
                                        <td>${tariffFreight ? tariffFreight.CONCATENATED : ''}</td>
                                        <td>
                                            ${tariffFreight ? this.$filter("simpleDate")(tariffFreight.DISPLAY_VALIDITY_START) : ''} - 
                                            ${tariffFreight ? this.$filter("simpleDate")(tariffFreight.DISPLAY_VALIDITY_END) : ''}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                `;

            await this.ModalService.showModalInfo({ size: 'vlg' }, {
                actionButtonText: 'GENERAL.CLOSE',
                actionButtonClass: 'btn-danger',
                headerText: 'PRODUCT.TARIFF_LIST',
                bodyText: this.$sce.trustAsHtml(htmlConsolidatedTariffs)
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getTarifftLocalById(freightLocalId: number): Promise<ITariffLocalModel> {
        try {
            if (!freightLocalId) this.formService.notifyError('Missing freightLocalId in getFreightLocalById');
            this.block();

            const request: IMonacoRequest = {
                route: `/tariffLocal/getById/${freightLocalId}`,
                timeout: 30000,
            };
            const rc = await this.ProductService.get(request);
            const result: ITariffLocalModel = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getTariffFreightById(freightLocalId: number): Promise<any> {
        try {

            if (!freightLocalId) this.formService.notifyError('Missing freightLocalId in getFreightLocalById');
            this.block();

            const request: IMonacoRequest = {
                route: `/freightRoutes/getById/${freightLocalId}`,
                timeout: 30000,
            };
            const rc = await this.ProductService.get(request);
            const result = (rc && rc.data && rc.data.data) ? rc.data.data : null;
            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getConsolidatedChargeById(consolidatedChargeId: string | object): Promise<IConsolidatedCharge> {
        try {
            if (!consolidatedChargeId) this.formService.notifyError('Missing consolidatedId in getConsolidatedById');
            this.block();

            const timeout: number = 120000;
            const rc = await this.DataProcessService.get(`/consolidatedCharge/getById/${consolidatedChargeId}`, timeout);
            const result: IConsolidatedCharge = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: Array<ICustomLogProperties> = [
            { PROPERTY: 'CHARGE', LABEL: 'BASIC_DATA.CHARGE' },
            { PROPERTY: 'CHARGE_NUMBER', LABEL: 'FINANCIAL.CHARGE_IDENTIFICATION' },
            { PROPERTY: 'CHARGE_TYPE', LABEL: 'GENERAL.TYPE' },
            { PROPERTY: 'NATURE', LABEL: 'BASIC_DATA.PAYMENT_NATURE' },
            { PROPERTY: 'AMOUNT', LABEL: 'GENERAL.QUANTITY' },
            { PROPERTY: 'APPLICATION', LABEL: 'FINANCIAL.CHARGE_BASIS' },
            { PROPERTY: 'CURRENCY', LABEL: 'GENERAL.CURRENCY' },
            { PROPERTY: 'MINIMUM', LABEL: 'BASIC_DATA.MINIMUM' },
            { PROPERTY: 'UNITARY', LABEL: 'BASIC_DATA.UNIT' },
            { PROPERTY: 'TOTAL', LABEL: 'FINANCIAL.ACTUAL_TOTAL_AMOUNT' },
            { PROPERTY: 'TOTAL_BRL', LABEL: 'FINANCIAL.TOTAL_AMOUNT_BRL' },
            { PROPERTY: 'CONVERSION_SPREAD', LABEL: 'FINANCIAL.SPREAD' },
            { PROPERTY: 'TRANSACTION', LABEL: 'GENERAL.HOLDER_TYPE' },
            { PROPERTY: 'PEOPLE', LABEL: 'GENERAL.HOLDER' },
            { PROPERTY: 'VALIDITY_OF', LABEL: 'PRODUCT.VALIDITY_START' },
            { PROPERTY: 'VALIDITY_UP', LABEL: 'PRODUCT.VALIDITY_END' },
            { PROPERTY: 'SHOW_FOR_GROUP', LABEL: 'FINANCIAL.SHOWN_FOR' },
            { PROPERTY: 'EQUIPMENT', LABEL: 'GENERAL.EQUIPMENT' },
            { PROPERTY: 'INVOICE_NUMBER', LABEL: 'GENERAL.INVOICES' },
            { PROPERTY: 'COMPOSITION_GROUP', LABEL: 'GENERAL.COMPOSITION' },
            { PROPERTY: 'OBSERVATION', LABEL: 'GENERAL.REMARKS' },
            { PROPERTY: 'HC2COD_CHARGE', LABEL: 'GENERAL.IDENTIFIER' },
            { PROPERTY: 'CHARGE_ORIGIN', LABEL: 'BASIC_DATA.ORIGIN' },
            { PROPERTY: 'MODALITY', LABEL: 'FINANCIAL.CHARGE_PAYMENT_METHOD' },
            { PROPERTY: 'CREATED_AT', LABEL: 'GENERAL.CREATED_AT' },
            { PROPERTY: 'CREATED_BY', LABEL: 'GENERAL.CREATED_BY' },
            { PROPERTY: 'UPDATED_AT', LABEL: 'GENERAL.UPDATED_AT' },
            { PROPERTY: 'UPDATED_BY', LABEL: 'GENERAL.UPDATED_BY' },
            { PROPERTY: 'INCOME', LABEL: 'FINANCIAL.DEBIT' },
            { PROPERTY: 'OUTCOME', LABEL: 'FINANCIAL.CREDIT' },
            { PROPERTY: 'NAME', LABEL: 'GENERAL.NAME' },
            { PROPERTY: 'CODE', LABEL: "GENERAL.FIELD" },
            { PROPERTY: 'NAME_INTL', LABEL: 'GENERAL.NAME_INTL' },
            { PROPERTY: 'CHARGE_EXHIBITION', LABEL: 'BASIC_DATA.CHARGE_DISPLAY' },
            { PROPERTY: 'CHARGE_PAIR_REFERENCE', LABEL: 'REGISTRATION.REFERENCE' },
            { PROPERTY: 'PERCENT', LABEL: 'REGISTRATION.PERCENTAGE' },
            { PROPERTY: 'APPLICATION_COMPLEMENT', LABEL: 'FINANCIAL.CHARGE_BASIS_COMPLEMENT' },
            { PROPERTY: 'CT_WITHOUT_DOC', LABEL: 'REGISTRATION.CONTRIBUTION_WITHOUT_DOCS' },
            { PROPERTY: 'FREE_TYPING_AMOUNT_CHARGE', LABEL: 'REGISTRATION.FREE_FIELD_CHANGE_QUANTITY' },
            { PROPERTY: "VEHICLE_TYPE", LABEL: "GENERAL.VEHICLE_TYPE" },
            { PROPERTY: "WEIGHT_RANGE", LABEL: "GENERAL.MENU.WEIGHT_RANGE" },
            { PROPERTY: "ERP_INTEGRATION_STATUS", LABEL: "REGISTRATION.INTEGRATION_STATUS" },
            { PROPERTY: "ERP_TOP", LABEL: "GENERAL.MENU.ERP_TOP" },
            { PROPERTY: 'ERP_ORDER', LABEL: 'FINANCIAL.ORDER' },
            { PROPERTY: 'ERP_SEQUENCE', LABEL: 'GENERAL.SEQUENCE' },
            { PROPERTY: 'ERP_BILLING', LABEL: 'OPERATIONAL.FLEXITANQ_BILLING_NUMBER' },
            { PROPERTY: "WEIGHT_RANGE_CHARGE", LABEL: "GENERAL.MENU.WEIGHT_RANGE" },
            { PROPERTY: "VALIDITY_EVENT", LABEL: "GENERAL.VALIDITY_BASIS" },
            { PROPERTY: "ORDER", LABEL: "REGISTRATION.FIELD_ORDER" },
            { PROPERTY: "PRODUCT", LABEL: "BASIC_DATA.PRODUCT" },
        ];
        return props;
    }
} 

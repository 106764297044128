OperationalMenuController.$inject = ['$scope', '$state', '$rootScope'];
export function OperationalMenuController($scope, $state, $rootScope) {

  //CONSTRUCTOR
  this.$onInit = function () {
    $rootScope.clearBreadCrumb();
  }

  $scope.goTo = function (route) {
    $state.go(route, { reload: true });
  };

}

setGridOptions.$inject = ['$rootScope']
export function setGridOptions($rootScope) {
    let ddo: any = {
        replace: false,
        restrict: "A"
    };

    ddo.link = (scope) => {
        $rootScope.gridOptions = scope.gridOptions;
        $rootScope.$on('$locationChangeStart', function ($event, next, current) {
            if ($rootScope.gridOptions) delete $rootScope.gridOptions;
        });
    }

    return ddo;
}

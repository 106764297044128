import angular = require("angular");
import { IGridOptions } from "ui-grid";
import { IMonacoColumnDef } from "@services/GridService2";
import { OperationalService } from "@services/OperationalService";
import { IRestService } from '@services/RestService';
import { ProductService } from '@services/ProductService';
import { GridFormService, IGridFormController, IGridFormServiceScope, IMonacoRequest, IMonacoRequestLog } from "@services/GridFormService";
import { IBookingPaymentConfigModel } from '@models/interface/operational/BookingPaymentConfig';
import { BrowserTitle } from "../../common/BrowserTitle";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { SelectorModel } from '../../common/model/SelectorModel';
import { ISelectorModel } from "WBA-Model/dist/mongo/SelectorModel";
import { IServiceProviderSelector } from 'WBA-Model/dist/interface/operational/IProductIntegrationSelector';
import { ETypeLocalId } from "@enums/GenericData";
import { HelperService } from "@services/HelperService";

interface IBookingPaymentConfigScope extends IGridFormServiceScope {
    model: IBookingPaymentConfigModel;
    scopeBeforeSave: IBookingPaymentConfigModel;
    gridOptions: IGridOptions;
    form: ng.IFormController;
    user: any;
    typePaymentList: ISelectorModel;
    serviceProviderList: SelectorModel[];
    originList: SelectorModel[];
    destinationList: SelectorModel[];
    paymentLocalList: SelectorModel[];
    getServiceProviderListByName: (term: string) => Promise<void>;
    getOriginListByName: (term: string) => Promise<void>;
    getDestinationListByName: (term: string) => Promise<void>;
    getPaymentLocalListByName: (term: string) => Promise<void>;
}

export class BookingPaymentConfigController extends GridFormService implements IGridFormController {

    static $inject: string[] = ["$injector", "$scope"];
    private $scope: IBookingPaymentConfigScope;
    private $RestService: IRestService;
    private $q: ng.IQService;
    private productService: ProductService;
    private OperationalService: OperationalService;
    private gridName: string;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IBookingPaymentConfigScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$RestService = $injector.get('RestService');
        this.$q = $injector.get('$q');
        this.OperationalService = $injector.get('OperationalService');
        this.productService = $injector.get('ProductService');
        this.gridName = "GRID_BOOKING_PAYMENT_CONFIG";
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.OperationalService.$route;
            await this.initDependencies();
            this.initModel();
            this.initForm(this, "form", "bookingPaymentConfig", "GENERAL.MENU.BOOKING_PAYMENT_CONFIG", true);
            await this.initGrid(this.gridName, '/bookingPaymentConfig/list', true, true, 120000, true, true);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.$rootScope.clearBreadCrumb();
        super.$onDestroy();
    }

    async register(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.scopeBeforeSave = null;
            this.$scope.model.ACTIVE = true;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")} (${this.$scope.model.PAYMENT_MODALITY.NAME})`;
            BrowserTitle.$id = this.$scope.model.PAYMENT_MODALITY.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")} (${this.$scope.model.PAYMENT_LOCAL.NAME})`;
            BrowserTitle.$id = this.$scope.model.PAYMENT_MODALITY.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            await this.clearFields(this.$scope.model);
            this.$scope.disableElements(false);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        return {
            route: `/bookingPaymentConfig/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    private async getGenericList(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    async initDependencies(): Promise<any> {
        try {
            const self: BookingPaymentConfigController = this;
            return new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getGenericList("type_payment"),
                ]).then((result: any) => {
                    self.$scope.typePaymentList = result[0] && result[0].length ? result[0].map(obj => { return { ID: obj.ID, NAME: obj.NAME } }) : [];
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            SERVICE_PROVIDER: null,
            PAYMENT_MODALITY: null,
            ORIGIN: null,
            DESTINATION: null,
            PAYMENT_LOCAL: null,
            ACTIVE: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null
        }
    }

    initScopeFunctions(): void {

        this.$scope.getServiceProviderListByName = async (term: string): Promise<void> => {
            await this.getServiceProviderListByName(term);
        }

        this.$scope.getOriginListByName = async (term: string): Promise<void> => {
            await this.getOriginListByName(term);
        }

        this.$scope.getDestinationListByName = async (term: string): Promise<void> => {
            await this.getDestinationListByName(term);
        }

        this.$scope.getPaymentLocalListByName = async (term: string): Promise<void> => {
            await this.getPaymentLocalListByName(term);
        }

    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.view(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.edit(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLog(row.entity, row.entity._id)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;

        gridColumns.$columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: '7%',
            cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${viewLog}</div>`,
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colServiceProvider: IMonacoColumnDef = { name: "SERVICE_PROVIDER.NAME", displayName: "BASIC_DATA.SEA_CARRIER", width: 150 };
            const colPaymentModality: IMonacoColumnDef = { name: "PAYMENT_MODALITY.NAME", displayName: "BASIC_DATA.PAYMENT_MODE", width: 150 };
            const colOrigin: IMonacoColumnDef = { name: "ORIGIN.CODE", displayName: "BASIC_DATA.ORIGIN", width: 150 };
            const colDestination: IMonacoColumnDef = { name: "DESTINATION.CODE", displayName: "BASIC_DATA.DESTINATION", width: 150 };
            const colPaymentLocal: IMonacoColumnDef = { name: "PAYMENT_LOCAL.CODE", displayName: "GENERAL.LOCAL", width: 150 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 150, cellFilter: "YesOrNo" };
            const colCreatedDate: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 140, cellFilter: "datetime" };
            const colCreatedBy: IMonacoColumnDef = { name: "CREATED_BY.NAME", displayName: "GENERAL.CREATED_BY", width: 180 };
            const colUpdatedDate: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 140, cellFilter: "datetime" };
            const colUpdatedBy: IMonacoColumnDef = { name: "UPDATED_BY.NAME", displayName: "GENERAL.UPDATED_BY", width: 180 };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 150 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'SERVICE_PROVIDER':
                        columnDefs.push(colServiceProvider);
                        break;
                    case 'PAYMENT_MODALITY':
                        columnDefs.push(colPaymentModality);
                        break;
                    case 'ORIGIN':
                        columnDefs.push(colOrigin);
                        break;
                    case 'DESTINATION':
                        columnDefs.push(colDestination);
                        break;
                    case 'PAYMENT_LOCAL':
                        columnDefs.push(colPaymentLocal);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedDate);
                        break;
                    case 'CREATED_BY':
                        columnDefs.push(colCreatedBy);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedDate);
                        break;
                    case 'UPDATED_BY':
                        columnDefs.push(colUpdatedBy);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getServiceProviderListByName(search: string): Promise<void> {
        try {
            this.$scope.serviceProviderList = [];
            if (search && search.length >= 3) {
                this.block();

                const request: IMonacoRequest = {
                    data: {
                        search
                    },
                    route: `/provider/list/custom`,
                    timeout: 30000,
                }
                const result = await this.productService.post(request)
                if (result && result.data && result.status == 200) {
                    const providerList: IServiceProviderSelector[] = result.data.data;
                    for (const resultItem of providerList) {
                        const serviceProvider: ISelectorModel = { ID: resultItem.ID, CODE: resultItem.SCAC_IATA, NAME: resultItem.NAME }
                        this.$scope.serviceProviderList.push(serviceProvider);
                    }
                }
                this.unblock();
            }
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private async getRoutingPointListByName(search?: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            this.block();
            const routingPoints = await this.productService.post({ route: '/routingPoint/list/custom', data: { name: search, types: [ETypeLocalId.PORT] }, timeout: 30000 });
            if (routingPoints && routingPoints.data && routingPoints.data.data && routingPoints.data.data.data) result = routingPoints.data.data.data.map(routingPoint => { return { ID: routingPoint.ID, NAME: routingPoint.NAME, CODE: routingPoint.CODE, TYPE: routingPoint.TYPE.NAME } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getOriginListByName(term: string) {
        let routingPointList: ISelectorModel[] = [];
        if (term && term.length >= 3) {
            routingPointList = await this.getRoutingPointListByName(term);
        }
        this.$scope.originList = routingPointList;
    }

    private async getDestinationListByName(term: string) {
        let routingPointList: ISelectorModel[] = [];
        if (term && term.length >= 3) {
            routingPointList = await this.getRoutingPointListByName(term);
        }
        this.$scope.destinationList = routingPointList;
    }

    private async getPaymentLocalListByName(term: string) {
        let routingPointList: ISelectorModel[] = [];
        if (term && term.length >= 3) {
            routingPointList = await this.getRoutingPointListByName(term);
        }
        this.$scope.paymentLocalList = routingPointList;
    }
}

import * as angular from "angular";
import { IGridOptions } from "ui-grid";
import { isEmptyObject } from "jquery";
import { fileUploader as FileUploader } from 'angular-file-upload';
import { SSEService } from '@appServices/SSEService';
import { DataProductService } from "@services/DataProductService";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog, } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { ISessionService } from "@services/SessionService";
import { IModalService } from "@services/ModalService";
import { ICustomLogProperties } from "@models/interface/common/IViewLog";
import { ITariffFreightExchangeData } from "@models/interface/product/TariffFreightModel";
import { ITariffDetDemExchangeData } from "@models/interface/product/TariffDetDemModel";
import { IRoutingPointListCustomFilter } from "@models/interface/product/RoutingPointModel";
import { IEquipmentSelector } from "@models/interface/product/EquipmentModel";
import { IProviderSelector } from "@models/interface/product/ProviderModel";
import { IUploader, IFileItem, ISuccessItemResponse } from "@models/interface/common/IMonacoUpload";
import { IUploadItem } from "@models/interface/product/Upload";
import { IDocumentError } from "@models/interface/common/IDocumentError";
import { IFilterReq } from "@models/product/AccountRequirement";
import { IParamsProfitShareExchangeData } from "@models/interface/product/ParamsProfitShareModel";
import { EProductId, EOperation, ETypeNameQualification } from "@enums/GenericData";
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { IAccountModel, IRequirements, ICharge, IBroker, IAccountExchangeData, IPreferencesUniqueTerm, IAccountPotential } from "../model/AccountModel";
import { IBrokerListCustomFilter } from "../../registration/model/BrokerModel";
import { SelectorModel } from "../../common/model/SelectorModel";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { IFloatingMenu } from "../../common/interface/IFloatingMenu";
import { ILinkParameter } from "../../common/model/ModelParameter";
import { ILegalPersonListCustomFilter, ILegalPersonSelector, ILegalPersonExchangeData } from "../../registration/model/LegalPersonModel";
import { IPhysicalPersonListCustomFilter, IPhysicalPersonSelectorModel } from "../../registration/model/PhysicalPersonModel";
import { StringUtil } from "../../common/util/StringUtil";
import { IContractExchangeData } from "../../finop/model/ContractModel";
import { ITariffLocalExchangeData } from "../../product/model/TariffLocalModel";
import { ValidateUtil } from "../../common/util/ValidateUtil";
import { HelperService } from "@services/HelperService";

interface IAccountScope extends IGridFormServiceScope {
    gridOptions: IGridOptions;
    model: IAccountModel;
    legalPersonList: SelectorModel[];
    legalPersonUniqueTermList: ILegalPersonSelector[];
    legalPersonTerminalRedestinationList: ILegalPersonSelector[];
    legalPersonTerminalMooringList: ILegalPersonSelector[];
    legalPersonTerminalDockingList: ILegalPersonSelector[];
    legalPersonTerminalEmptyList: ILegalPersonSelector[];
    qualificationList: SelectorModel[];
    languageList: SelectorModel[];
    sectionList: SelectorModel[];
    localsGroupList: SelectorModel[];
    conditionList: SelectorModel[];
    productList: SelectorModel[];
    exporterList: SelectorModel[];
    importerList: SelectorModel[];
    networkAgentList: SelectorModel[];
    corporateBranchList: SelectorModel[];
    commercialUnityList: SelectorModel[];
    incotermList: SelectorModel[];
    currencyList: SelectorModel[];
    indicationList: SelectorModel[];
    forwardedByList: SelectorModel[];
    chargeOriginList: SelectorModel[];
    paymentNatureList: SelectorModel[];
    typePaymentList: SelectorModel[];
    transactionTypeList: SelectorModel[];
    collaboratorList: IPhysicalPersonSelectorModel[];
    providerList: IProviderSelector[];
    brokerList: SelectorModel[];
    routingPointList: SelectorModel[];
    comparativeGroupList: SelectorModel[];
    accountModalityList: SelectorModel[];
    destinationThcList: SelectorModel[];
    incchargeReleaseList: SelectorModel[];
    countryList: SelectorModel[];
    equipmentList: IEquipmentSelector[];
    accountSituationList: SelectorModel[];
    accountTypeList: SelectorModel[];
    customLogProperties: ICustomLogProperties[];
    menuFloating: IFloatingMenu;
    scopeBeforeSave: IAccountModel;
    uploader: IUploader;
    processTypeList: SelectorModel[];
    commodityGroupList: SelectorModel[];
    volumeTypeList: SelectorModel[];
    recurrenceList: SelectorModel[];
    user: any;
    sessionService: ISessionService;
    showRequirements: boolean;
    showAccountPotentials: boolean;
    showInactiveRequirements: boolean;
    hasEditQualificationPermission: boolean;
    hasEditCorporateUnityPermission: boolean;
    hasEditAccountSituationPermission: boolean;
    editAccount: (account: IAccountModel) => Promise<void>;
    viewAccount: (account: IAccountModel) => Promise<void>;
    viewLogAccount: (account: IAccountModel) => Promise<void>;
    copyAccount: (account: IAccountModel) => Promise<void>;
    getCollaboratorListByName: (search: string, roles?: string[]) => Promise<void>;
    getLegalPersonListByName: (search: string) => Promise<void>;
    getLegalPersonUniqueTermListByName: (search: string) => Promise<void>;
    getLegalPersonTerminalRedestination: (search: string) => Promise<void>;
    getLegalPersonTerminalMooring: (search: string) => Promise<void>;
    getLegalPersonTerminalDocking: (search: string) => Promise<void>;
    getLegalPersonTerminalEmpty: (search: string) => Promise<void>;
    getNetworkAgentListByLegalPersonSpecType: (search: string) => Promise<void>;
    getExporterListByName: (search: string) => Promise<void>;
    getImporterListByName: (search: string) => Promise<void>;
    getComparativeGroupListByName: (search: string) => Promise<void>;
    getRoutingPointListByName: (search: string, product: SelectorModel) => Promise<void>;
    getCorporateBranchListByName: (search: string, product: SelectorModel) => Promise<void>;
    getIndicationListByName: (search: string) => Promise<void>;
    getBrokerListByName: (search: string, product: SelectorModel) => Promise<void>;
    getProviderListByName: (search: string) => Promise<void>;
    getCountryListByNameOrInitials: (search: string) => Promise<void>;
    getEquipmentListByNameOrCode: (search: string) => Promise<void>;
    getCommercialUnityListByName: (search: string) => Promise<void>;
    getIncotermListByName: (search: string, requirementIndex: number) => Promise<void>;
    getIncotermListByNameAccountPotential: (search: string, index: number) => Promise<void>;
    getCurrencyListByName: (name: string) => Promise<void>;
    getCorporateUnit: (requirementIndex: number) => void;
    goToCorporateBranch: (id: number) => void;
    goToLegalPerson: (id: number) => void;
    goToIncoterm: (id: number) => void;
    goToPhysicalPerson: (id: number) => void;
    goToExporter: (exporter: ILegalPersonSelector[]) => void;
    goToImporter: (importer: ILegalPersonSelector[]) => void;
    goToNetwork: (name: SelectorModel[]) => void;
    goToProvider: (id: number) => void;
    goToBroker: (id: number) => void;
    goToRoutingPoint: (routingPoints: SelectorModel | SelectorModel[]) => void;
    goToCountry: (countries: SelectorModel[]) => void;
    goToEquipment: (equipments: SelectorModel[]) => void;
    goToFinancialContract: (id: number) => void;
    goToTariffLocal: (tariff: SelectorModel) => void;
    goToTariffFreightContract: (tariff: SelectorModel) => void;
    goToTariffDetDem: (tariff: SelectorModel) => void;
    goToParamsProfitShare: (profitShare: SelectorModel) => void;
    generateConcatenated: (requirementIndex: number, checkCondition: boolean) => void;
    generateConcatenatedAccountPotential: (accountPotentialIndex: number, checkCondition: boolean) => void;
    addRequirement: (navigate?: boolean) => void;
    copyRequirement: (index: number) => void;
    removeRequirement: (index: number) => void;
    requirementProductChange: (index: number) => void;
    requirementCorporateAccountChange: (index: number, value: boolean) => void;
    addBroker: (requirementIndex: number) => void;
    removeBroker: (requirementIndex: number, brokerIndex: number) => void;
    addCharge: (requirementIndex: number) => void;
    removeCharge: (requirementIndex: number, chargeIndex: number) => void;
    addServiceProvider: (requirementIndex: number) => void;
    removeServiceProvider: (requirementIndex: number, serviceProviderIndex: number) => void;
    addRouteCondition: (requirementIndex: number) => void;
    removeRouteCondition: (requirementIndex: number, routeConditionIndex: number) => void;
    addDockingTerminal: (requirementIndex: number) => void;
    removeDockingTerminal: (requirementIndex: number, dockingTerminalIndex: number) => void;
    addRedestinationDockingTerminal: (requirementIndex: number) => void;
    removeRedestinationDockingTerminal: (requirementIndex: number, redestinationDockingTerminalIndex: number) => void;
    addEmptyTerminal: (requirementIndex: number) => void;
    removeEmptyTerminal: (requirementIndex: number, emptyTerminalIndex: number) => void;
    addUniqueTerm: (requirementIndex: number) => void;
    removeUniqueTerm: (requirementIndex: number, emptyTermIndex: number) => void;
    updateSector: (requirementIndex: number, selected: IPhysicalPersonSelectorModel) => void;
    updateSelectedLegalPerson: (selected: SelectorModel) => void;
    checkDateValidity: (initialDate: Date, finalDate: Date, parentIndex: number, index: number) => void;
    updateUniqueTermSelectedLegalPerson: (selected: ILegalPersonSelector, parentIndex: number, index: number) => void;
    updateUniqueTermSelectedBroker: (selected: SelectorModel, parentIndex: number, index: number) => void;
    updateAccountType: (index: number, selected: ILegalPersonSelector) => void;
    collapseHeader: (elementId: string, state?: string, navigate?: boolean) => void;
    collapseRequirement: (requirementIndex: number) => void;
    updateFinancialContractList: (requirementIndex: number, panelClick: boolean) => void;
    updateTariffLocalList: (requirementIndex: number, panelClick: boolean) => void;
    updateTariffFreightContractList: (requirementIndex: number, panelClick: boolean) => void;
    updateTariffDetDemList: (requirementIndex: number, panelClick: boolean) => void;
    updateProfitShareList: (requirementIndex: number, panelClick: boolean) => void;
    formatConcatenatedChars: (value: string) => string;
    openUniqueTermModal: (uniqueTerm: IPreferencesUniqueTerm, operation: string) => void;
    viewUniqueTermModal: (fileUrl: string) => void;
    initPanels: () => void;
    openModalIntegration: (id: number, legalPersonId: number, documentError: IDocumentError[]) => void;
    setCopy: (value: boolean) => void;
    refreshIncoterms: (requirementsIndex: number) => Promise<void>;
    addAccountPotential: () => void;
    removeAccountPotential: (index: number) => void;
    getCommodityGroupListByName: () => Promise<void>;
    getVolumeTypeListByName: () => Promise<void>;
    getRecurrenceListByName: () => Promise<void>;
}

interface IUniqueTermModalScope extends ng.IScope {
    uniqueTerm: IPreferencesUniqueTerm;
    oldUniqueTerm: IPreferencesUniqueTerm;
    closeUniqueTermModal: () => Promise<void>;
    removeUpload: (model: IUploadItem) => boolean;
}

export interface IFormData {
    bucket: string;
    formName: string;
    folder: string;
}

export class AccountRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IAccountScope;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService
    private RestService: IRestService;
    private ModalService: IModalService;
    private collapseState: string;
    private fileUploader: FileUploader;
    private formName: string;
    private gridName: string;
    private modalUniqueTermId: number;
    private dataProductService: DataProductService;
    private modalID: number;
    private SSEService: SSEService;
    private idAccountOnModal: number;
    private isCopy: boolean;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IAccountScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$scope.additionalIndexSelectorValidity = ['$parentIndex'];
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');
        this.collapseState = "hide";
        this.$scope.sessionService = $injector.get('SessionService');
        this.fileUploader = $injector.get('FileUploader');
        this.formName = 'LegalPersonAccount';
        this.gridName = 'GRID_ACCOUNT';
        this.dataProductService = $injector.get('DataProductService');
        this.modalID = null;
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.helperService = $injector.get('HelperService');
        this.$scope.hasEditQualificationPermission = false;
        this.$scope.hasEditCorporateUnityPermission = false;
        this.$scope.hasEditAccountSituationPermission = false;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'account', 'GENERAL.MENU.ACCOUNT', true);
            await this.initGrid(this.gridName, '/account/list', true, true, null, true, true);

            this.initCollapseEvents();
            await this.getSectionList();

            this.$scope.hasEditQualificationPermission = await this.permissionService.isRoleAllowed("CUSTOMERQUALIFICATIONEDIT");
            this.$scope.hasEditCorporateUnityPermission = await this.permissionService.isRoleAllowed("CUSTOMERCORPORATEUNITYEDIT");

            const sessionParameter = this.$gridService.$sessionParameter;
            if (sessionParameter && sessionParameter.data) this.callSessionFunctions(sessionParameter.data);
            this.SSEService.closeEvents();
            this.setCopy(false);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editAccount = async (account: IAccountModel): Promise<void> => {
            let blockedObject = {
                ID: account.ID,
                NAME: this.$scope.getCONCAT(account.REQUIREMENTS, null, "CONCATENATED"),
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };

            this.$scope.hasEditAccountSituationPermission = await this.permissionService.isRoleAllowed("EDITACCOUNTSITUATION");
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || account.ID !== this.$scope.model.ID) await this.fetchData(account.ID, EOperation.VIEW);
                } else if (this.$scope.operation !== EOperation.EDIT || account.ID !== this.$scope.model.ID) {
                    await this.fetchData(account.ID, EOperation.EDIT);
                }
            };
        }

        this.$scope.viewAccount = async (account: IAccountModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.fetchData(account.ID, EOperation.VIEW);
        }

        this.$scope.viewLogAccount = async (account: IAccountModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(account);
        }

        this.$scope.copyAccount = async (account: IAccountModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.fetchData(account.ID, EOperation.COPY);
        }

        this.$scope.getCollaboratorListByName = async (search: string, roles?: string[]) => {
            let collaboratorList: IPhysicalPersonSelectorModel[] = [];
            if (search && search.length >= 3) {
                collaboratorList = await this.getPhysicalPersonListByName({ specializations: [ELegalPersonSpecializationId.BROKER], roles: roles, search: search });
            }
            this.$scope.collaboratorList = collaboratorList;
        }

        this.$scope.getLegalPersonListByName = async (search: string) => {
            let legalPersonSelectorList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                const legalPersonList = await this.getLegalPersonListByName({ specializations: [], search: search });
                if (legalPersonList) legalPersonSelectorList = legalPersonList.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME } });
            }
            this.$scope.legalPersonList = legalPersonSelectorList;
        }

        this.$scope.getExporterListByName = async (search: string) => {
            let exporterList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                const legalPersonList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.EXPORTER], search: search });
                if (legalPersonList) exporterList = legalPersonList.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME } });
            }
            this.$scope.exporterList = exporterList;
        }

        this.$scope.getImporterListByName = async (search: string) => {
            let importerList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                const legalPersonList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.IMPORTER], search: search });
                if (legalPersonList) importerList = legalPersonList.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME } });
            }
            this.$scope.importerList = importerList;
        }

        this.$scope.getLegalPersonUniqueTermListByName = async (search: string) => {
            let legalPersonUniqueTermSelectorList: ILegalPersonSelector[] = [];
            if (search && search.length >= 3) {
                const legalPersonList = await this.getLegalPersonListByName({ specializations: [], search: search });
                if (legalPersonList) legalPersonUniqueTermSelectorList = legalPersonList.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME, ID_FISCAL: legalPerson.ID_FISCAL, SHORT_NAME: legalPerson.SHORT_NAME, CORPORATE_NAME: legalPerson.CORPORATE_NAME } });
            }
            this.$scope.legalPersonUniqueTermList = legalPersonUniqueTermSelectorList;
        }

        this.$scope.getLegalPersonTerminalRedestination = async (search: string) => {
            let legalPersonTerminalRedestination: ILegalPersonSelector[] = [];
            if (search && search.length >= 3) {
                const legalPersonList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.PORT_TERMINAL], search: search });
                if (legalPersonList) legalPersonTerminalRedestination = legalPersonList.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME, ID_FISCAL: legalPerson.ID_FISCAL, SHORT_NAME: legalPerson.SHORT_NAME, CORPORATE_NAME: legalPerson.CORPORATE_NAME } });
            }
            this.$scope.legalPersonTerminalRedestinationList = legalPersonTerminalRedestination;
        }

        this.$scope.getLegalPersonTerminalMooring = async (search: string) => {
            let legalPersonTerminalMooring: ILegalPersonSelector[] = [];
            if (search && search.length >= 3) {
                const legalPersonList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.PORT_TERMINAL], search: search });
                if (legalPersonList) legalPersonTerminalMooring = legalPersonList.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME, ID_FISCAL: legalPerson.ID_FISCAL, SHORT_NAME: legalPerson.SHORT_NAME, CORPORATE_NAME: legalPerson.CORPORATE_NAME } });
            }
            this.$scope.legalPersonTerminalMooringList = legalPersonTerminalMooring;
        }

        this.$scope.getLegalPersonTerminalDocking = async (search: string) => {
            let legalPersonTerminalDocking: ILegalPersonSelector[] = [];
            if (search && search.length >= 3) {
                const legalPersonList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.PORT_TERMINAL], search: search });
                if (legalPersonList) legalPersonTerminalDocking = legalPersonList.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME, ID_FISCAL: legalPerson.ID_FISCAL, SHORT_NAME: legalPerson.SHORT_NAME, CORPORATE_NAME: legalPerson.CORPORATE_NAME } });
            }
            this.$scope.legalPersonTerminalDockingList = legalPersonTerminalDocking;
        }

        this.$scope.getLegalPersonTerminalEmpty = async (search: string) => {
            let legalPersonTerminalEmpty: ILegalPersonSelector[] = [];
            if (search && search.length >= 3) {
                const legalPersonList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.PORT_TERMINAL], search: search });
                if (legalPersonList) legalPersonTerminalEmpty = legalPersonList.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME, ID_FISCAL: legalPerson.ID_FISCAL, SHORT_NAME: legalPerson.SHORT_NAME, CORPORATE_NAME: legalPerson.CORPORATE_NAME } });
            }
            this.$scope.legalPersonTerminalEmptyList = legalPersonTerminalEmpty;
        }

        this.$scope.getRoutingPointListByName = async (search: string, product: SelectorModel) => {
            let routingPointList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                if (!product) return this.formService.handleError(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                let type = "";
                // porto
                if (product.ID == EProductId.MARITIME_IMPORT || product.ID == EProductId.MARITIME_EXPORT) type = "2";
                // door
                if (product.ID == EProductId.ROAD_IMPORT || product.ID == EProductId.ROAD_EXPORT) type = "3";
                // aeroporto
                if (product.ID == EProductId.AIR_IMPORT || product.ID == EProductId.AIR_EXPORT) type = "4";
                routingPointList = await this.getRoutingPointListByName({ name: search, types: [type], countries: [] });
            }
            this.$scope.routingPointList = routingPointList;
        }

        this.$scope.getComparativeGroupListByName = async (search: string) => {
            let comparativeGroupList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                comparativeGroupList = await this.getRoutingPointListByName({ name: search });
            }
            this.$scope.comparativeGroupList = comparativeGroupList;

        }

        this.$scope.getCorporateBranchListByName = async (search: string, product: SelectorModel) => {
            let corporateBranchList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                corporateBranchList = await this.getCorporateBranchListByName(search, product);
            }
            this.$scope.corporateBranchList = corporateBranchList;
        }

        this.$scope.getIndicationListByName = async (search: string) => {
            let indicationList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                const legalPersonList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.INDICATION], search: search });
                if (legalPersonList) indicationList = legalPersonList.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME } });
            }
            this.$scope.indicationList = indicationList;
        }

        this.$scope.getProviderListByName = async (search: string) => {
            let providerList: IProviderSelector[] = [];
            if (search && search.length >= 3) {
                providerList = await this.getProviderListByName(search);
            }
            this.$scope.providerList = providerList;
        }

        this.$scope.getBrokerListByName = async (search: string, product: SelectorModel) => {
            let brokerList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                if (!product) return this.formService.handleError(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                brokerList = await this.getBrokerListByName({ products: [product.ID], search: search });
            }
            this.$scope.brokerList = brokerList;
        }

        this.$scope.getCountryListByNameOrInitials = async (search: string) => {
            let countryList: SelectorModel[] = [];
            if (search && search.length >= 2) {
                countryList = await this.getCountryListByNameOrInitials(search);
            }
            this.$scope.countryList = countryList;
        }

        this.$scope.getEquipmentListByNameOrCode = async (search: string) => {
            let equipmentList: IEquipmentSelector[] = [];
            if (search && search.length >= 3) {
                equipmentList = await this.getEquipmentListByNameOrCode(search);
            }
            this.$scope.equipmentList = equipmentList;
        }

        this.$scope.getNetworkAgentListByLegalPersonSpecType = async (search: string) => {
            let networkList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                networkList = await this.getNetworkAgentListByLegalPersonSpecType(search);
            }
            this.$scope.networkAgentList = networkList;
        }

        this.$scope.getCommercialUnityListByName = async (search: string) => {
            let commercialUnityList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                const legalPersonList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.COMMERCIAL_UNITY], search: search });
                if (legalPersonList) commercialUnityList = legalPersonList.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME } });
            }
            this.$scope.commercialUnityList = commercialUnityList;
        }

        this.$scope.getIncotermListByName = async (search: string, requirementsIndex: number) => {
            let incoterm: SelectorModel[] = [];
            if (search && search.length >= 3) {
                incoterm = await this.getIncotermListByName(search, requirementsIndex, this.$scope.model.REQUIREMENTS[requirementsIndex].PRODUCT ? [this.$scope.model.REQUIREMENTS[requirementsIndex].PRODUCT.ID] : []);
            }
            this.$scope.incotermList = incoterm;
        }

        this.$scope.getIncotermListByNameAccountPotential = async (search: string, index: number) => {
            let incoterm: SelectorModel[] = [];
            if (search && search.length >= 3) {
                incoterm = await this.getIncotermListByName(search, index, this.$scope.model.ACCOUNT_POTENTIAL[index].PRODUCT ? [this.$scope.model.ACCOUNT_POTENTIAL[index].PRODUCT.ID] : []);
            }
            this.$scope.incotermList = incoterm;
        }

        this.$scope.goToCorporateBranch = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.corporateBranch", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToLegalPerson = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.legalPerson", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToPhysicalPerson = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.physicalPerson", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToExporter = (exporters: ILegalPersonSelector[]) => {
            this.$scope.sessionService.openTab("app.registration.legalPerson", <ILinkParameter>{ ID: this.$scope.getCONCAT(exporters, null, "ID") });
        }

        this.$scope.goToImporter = (importers: ILegalPersonSelector[]) => {
            this.$scope.sessionService.openTab("app.registration.legalPerson", <ILinkParameter>{ ID: this.$scope.getCONCAT(importers, null, "ID") });
        }

        this.$scope.goToNetwork = (networks: SelectorModel[]) => {
            this.$scope.sessionService.openTab("app.registration.network", <ILinkParameter>{ ID: this.$scope.getCONCAT(networks, null, "ID") });
        }

        this.$scope.goToProvider = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.provider", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToBroker = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.broker", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToRoutingPoint = (routingPoints: SelectorModel | SelectorModel[]): void => {
            const ids = angular.isArray(routingPoints) ? this.$scope.getCONCAT(routingPoints, null, "ID") : (routingPoints ? routingPoints.ID.toString() : null);
            this.$scope.sessionService.openTab("app.registration.routingPoint", <ILinkParameter>{ ID: ids });
        }

        this.$scope.goToCountry = (countries: SelectorModel[]): void => {
            this.$scope.sessionService.openTab("app.registration.country", <ILinkParameter>{ ID: this.$scope.getCONCAT(countries, null, "ID") });
        }

        this.$scope.goToEquipment = (equipments: SelectorModel[]): void => {
            this.$scope.sessionService.openTab("app.registration.equipment", <ILinkParameter>{ ID: this.$scope.getCONCAT(equipments, null, "ID") });
        }

        this.$scope.goToFinancialContract = (id: number) => {
            this.$scope.sessionService.openTab("app.finop.contract.financialContract", <ILinkParameter>{ ID: id ? id.toString() : id }, <IContractExchangeData>{ OPERATION: EOperation.VIEW, ID: id ? id.toString() : id });
        }

        this.$scope.goToTariffLocal = (tariff: SelectorModel) => {
            if (!tariff) return;

            const endpoint = `${this.config.productUrl}/product/tariffLocal/getCacheById/${tariff.ID}`;
            this.$scope.sessionService.openTabByValidity(endpoint, "app.product.tariffLocal", <ILinkParameter>{ ID: tariff ? tariff.ID.toString() : tariff }, <ITariffLocalExchangeData>{ OPERATION: EOperation.VIEW, ID: tariff.ID });
        }

        this.$scope.goToTariffFreightContract = (tariff: SelectorModel) => {
            if (!tariff) return;

            const endpoint = `${this.config.productUrl}/product/tariffFreight/getCacheById/${tariff.ID}`;
            this.$scope.sessionService.openTabByValidity(endpoint, "app.product.tariffFreight", <ILinkParameter>{ ID: tariff ? tariff.ID.toString() : tariff }, <ITariffFreightExchangeData>{ OPERATION: EOperation.VIEW, ID: tariff.ID });
        }

        this.$scope.goToTariffDetDem = (tariff: SelectorModel) => {
            if (!tariff) return;

            this.$scope.sessionService.openTab("app.product.tariffDetDem", <ILinkParameter>{ ID: tariff ? tariff.ID.toString() : tariff }, <ITariffDetDemExchangeData>{ OPERATION: EOperation.VIEW, ID: tariff.ID });
        }

        this.$scope.goToParamsProfitShare = (profitShare: SelectorModel) => {
            if (!profitShare) return;

            this.$scope.sessionService.openTab("app.product.paramsProfitShare", <ILinkParameter>{ ID: profitShare ? profitShare.ID.toString() : profitShare }, <IParamsProfitShareExchangeData>{ OPERATION: EOperation.VIEW, ID: profitShare.ID });
        }

        this.$scope.goToIncoterm = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.incoterm", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.generateConcatenated = (requirementIndex: number): void => {
            this.generateConcatenated(requirementIndex);
        }

        this.$scope.generateConcatenatedAccountPotential = (accountPotentialIndex: number): void => {
            this.generateConcatenatedAccountPotential(accountPotentialIndex);
        }

        this.$scope.addRequirement = (navigate?: boolean) => {
            this.SSEService.closeEvents();
            this.addRequirement(navigate);
        }

        this.$scope.copyRequirement = (index: number) => {
            this.copyRequirement(index);
        }

        this.$scope.removeRequirement = (index: number) => {
            this.removeRequirement(index);
        }

        this.$scope.requirementProductChange = (index: number) => {
            this.requirementProductChange(index);
        }

        this.$scope.requirementCorporateAccountChange = (index: number, value: boolean) => {
            this.requirementCorporateAccountChange(index, value);
        }

        this.$scope.addBroker = (requirementIndex: number) => {
            this.addBroker(requirementIndex);
        }

        this.$scope.removeBroker = (requirementIndex: number, brokerIndex: number) => {
            this.removeBroker(requirementIndex, brokerIndex);
        }

        this.$scope.addCharge = (requirementIndex: number) => {
            this.addCharge(requirementIndex);
        }

        this.$scope.addAccountPotential = (navigate?: boolean) => {
            this.SSEService.closeEvents();
            this.addAccountPotential(navigate);
        }

        this.$scope.removeAccountPotential = (index: number) => {
            this.removeAccountPotential(index);
        }

        this.$scope.removeCharge = (requirementIndex: number, chargeIndex: number) => {
            this.removeCharge(requirementIndex, chargeIndex);
        }

        this.$scope.addServiceProvider = (requirementIndex: number) => {
            this.addServiceProvider(requirementIndex);
        }

        this.$scope.removeServiceProvider = (requirementIndex: number, serviceProviderIndex: number) => {
            this.removeServiceProvider(requirementIndex, serviceProviderIndex);
        }

        this.$scope.addRouteCondition = (requirementIndex: number) => {
            this.addRouteCondition(requirementIndex);
        }

        this.$scope.removeRouteCondition = (requirementIndex: number, routeConditionIndex: number) => {
            this.removeRouteCondition(requirementIndex, routeConditionIndex);
        }

        this.$scope.addDockingTerminal = (requirementIndex: number) => {
            this.addDockingTerminal(requirementIndex);
        }

        this.$scope.removeDockingTerminal = (requirementIndex: number, dockingTerminalIndex: number) => {
            this.removeDockingTerminal(requirementIndex, dockingTerminalIndex);
        }

        this.$scope.addRedestinationDockingTerminal = (requirementIndex: number) => {
            this.addRedestinationDockingTerminal(requirementIndex);
        }

        this.$scope.removeRedestinationDockingTerminal = (requirementIndex: number, redestinationDockingTerminalIndex: number) => {
            this.removeRedestinationDockingTerminal(requirementIndex, redestinationDockingTerminalIndex);
        }

        this.$scope.addEmptyTerminal = (requirementIndex: number) => {
            this.addEmptyTerminal(requirementIndex);
        }

        this.$scope.removeEmptyTerminal = (requirementIndex: number, emptyTerminalIndex: number) => {
            this.removeEmptyTerminal(requirementIndex, emptyTerminalIndex);
        }

        this.$scope.addUniqueTerm = (requirementIndex: number) => {
            this.addUniqueTerm(requirementIndex);
        }

        this.$scope.removeUniqueTerm = (requirementIndex: number, emptyTermIndex: number) => {
            this.removeUniqueTerm(requirementIndex, emptyTermIndex);
        }

        this.$scope.updateSector = (requirementIndex: number, selected: IPhysicalPersonSelectorModel) => {
            if (requirementIndex != null && selected && selected.SECTOR && this.$scope.model.REQUIREMENTS[requirementIndex]) this.$scope.model.REQUIREMENTS[requirementIndex].SECTOR = selected.SECTOR.NAME;
        }

        this.$scope.updateSelectedLegalPerson = (selected: SelectorModel) => {
            this.updateSelectedLegalPerson(selected);
        }

        this.$scope.checkDateValidity = (initialDate: Date, finalDate: Date, parentIndex: number, index: number): void => {
            this.checkDateValidity(initialDate, finalDate, parentIndex, index);
        }

        this.$scope.updateUniqueTermSelectedLegalPerson = (selected: ILegalPersonSelector, parentIndex: number, index: number) => {
            this.updateUniqueTermSelectedLegalPerson(selected, parentIndex, index);
        }

        this.$scope.updateUniqueTermSelectedBroker = (selected: SelectorModel, parentIndex: number, index: number) => {
            this.updateUniqueTermSelectedBroker(selected, parentIndex, index);
        }

        this.$scope.openUniqueTermModal = async (uniqueTerm: IPreferencesUniqueTerm, operation: string): Promise<void> => {
            this.openUniqueTermModal(uniqueTerm, operation);
        }

        this.$scope.viewUniqueTermModal = async (fileUrl: string): Promise<void> => {
            this.viewUniqueTermModal(fileUrl);
        }

        this.$scope.updateAccountType = (index: number, selected: ILegalPersonSelector) => {
            this.updateAccountType(index, selected);
        }

        this.$scope.collapseHeader = (elementId: string, state?: string, navigate?: boolean) => {
            this.collapseHeader(elementId, state, navigate);
        }

        this.$scope.collapseRequirement = (requirementIndex: number) => {
            this.collapseRequirement(requirementIndex);
        }

        this.$scope.updateFinancialContractList = (requirementIndex: number, panelClick: boolean) => {
            this.updateFinancialContractList(requirementIndex, panelClick);
        }

        this.$scope.updateTariffLocalList = (requirementIndex: number, panelClick: boolean) => {
            this.updateTariffLocalList(requirementIndex, panelClick);
        }

        this.$scope.updateTariffFreightContractList = (requirementIndex: number, panelClick: boolean) => {
            this.updateTariffFreightContractList(requirementIndex, panelClick);
        }

        this.$scope.updateTariffDetDemList = (requirementIndex: number, panelClick: boolean) => {
            this.updateTariffDetDemList(requirementIndex, panelClick);
        }

        this.$scope.updateProfitShareList = (requirementIndex: number, panelClick: boolean) => {
            this.updateProfitShareList(requirementIndex, panelClick);
        }

        this.$scope.formatConcatenatedChars = (value: string) => {
            return StringUtil.formatConcatenatedChars(value);
        }

        //Integration Log
        this.$scope.openModalIntegration = (id: number, legalPersonId: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, legalPersonId, documentError);
        }

        this.$scope.initPanels = () => {
            try {
                const panels = document.getElementsByClassName("toggle-me");
                if (panels) {
                    for (let i = 0; i < panels.length; i++) {
                        const panel = panels[i];
                        if (panel.id === "collapseBasicData" || panel.id === "collapseRequirements") {
                            if (!panel.classList.contains('in')) {
                                $("#" + panel.id)["collapse"]("show");
                            }
                        } else if (panel.classList.contains('in')) {
                            $("#" + panel.id)["collapse"]("hide");
                        }
                    }
                }
                this.$scope.navigateBetweenIds('collapseBasicData');
            } catch (ex) {
                this.formService.handleError(ex);
            }
        }

        this.$scope.getCorporateUnit = (requirementIndex: number) => {
            this.getCorporateUnit(requirementIndex);
        }

        this.$scope.setCopy = (value: boolean): void => {
            this.setCopy(value);
        }

        this.$scope.refreshIncoterms = async (requirementsIndex: number): Promise<void> => {
            this.refreshIncoterms(requirementsIndex);
        }

        this.$scope.getCurrencyListByName = async (search: string) => {
            let currency: SelectorModel[] = [];
            if (search && search.length >= 3) {
                currency = await this.getCurrencyListByName(search);
            }
            this.$scope.currencyList = currency;
        }

        this.$scope.getCommodityGroupListByName = async (): Promise<void> => {
            let commodityGroupList = [];
            commodityGroupList = await this.getCommodityGroupListByName();
            this.$scope.commodityGroupList = commodityGroupList;
        };

        this.$scope.getVolumeTypeListByName = async (): Promise<void> => {
            let volumeTypeList = [];
            volumeTypeList = await this.getVolumeTypeListByName();
            this.$scope.volumeTypeList = volumeTypeList;
        };

        this.$scope.getRecurrenceListByName = async (): Promise<void> => {
            let recurrenceList = [];
            recurrenceList = await this.getRecurrenceListByName();
            this.$scope.recurrenceList = recurrenceList;
        };
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewAccount(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editAccount(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.setCopy(true);grid.appScope.copyAccount(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogAccount(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.LEGAL_PERSON.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 130,
            maxWidth: 130,
            cellTemplate: (view + edit + copy + viewLog + modalIntegration),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colLegalPerson: IMonacoColumnDef = { name: "LEGAL_PERSON.CODE", displayName: "ENTITY.CORPORATE_NAME", width: 250 };
            const colName: IMonacoColumnDef = { name: "NAME", displayName: "GENERAL.NAME", width: 400 };
            const colSection: IMonacoColumnDef = { name: "SECTION.NAME", displayName: "REGISTRATION.CLIENT_SECTOR_TYPE", width: 300 };
            const colQualification: IMonacoColumnDef = { name: "QUALIFICATION.NAME", displayName: "GENERAL.QUALIFICATION", width: 200 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT_REPL", displayName: "BASIC_DATA.PRODUCT", width: 300, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PRODUCT_REPL, null, "ID")}}</div>' };
            const colSituation: IMonacoColumnDef = { name: "SITUATION.NAME", displayName: "Sit. Cliente", width: 120 };
            const colForwardedBy: IMonacoColumnDef = { name: "FORWARDED_BY.NAME", displayName: "GENERAL.FORWARDED_BY", width: 120 };
            const colLanguage: IMonacoColumnDef = { name: "LANGUAGE.NAME", displayName: "GENERAL.LANGUAGE", width: 120 };
            const colInsideSalesPersonRepl: IMonacoColumnDef = { name: "INSIDE_SALES_PERSON_REPL", displayName: "BASIC_DATA.INSIDE_SALES", width: 120, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.INSIDE_SALES_PERSON_REPL, null, "NAME")}}</div>' };
            const colSalesPersonRepl: IMonacoColumnDef = { name: "SALES_PERSON_REPL", displayName: "BASIC_DATA.SALES_EXECUTIVE", width: 120, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.SALES_PERSON_REPL, null, "NAME")}}</div>' };
            const colNetworkRepl: IMonacoColumnDef = { name: "NETWORK_REPL", displayName: "BASIC_DATA.EXTERNAL_AGENT_NETWORK", width: 120, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.NETWORK_REPL, null, "NAME")}}</div>' };
            const colResponsibleProductRepl: IMonacoColumnDef = { name: "RESPONSIBLE_PRODUCT_REPL.NAME", displayName: "BASIC_DATA.PRODUCT_RESPONSIBLE", width: 120, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.RESPONSIBLE_PRODUCT_REPL, null, "NAME")}}</div>' };
            const colAccountTypeRepl: IMonacoColumnDef = { name: "ACCOUNT_TYPE_REPL", displayName: "REGISTRATION.ACCOUNT_TYPE", width: 120, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNT_TYPE_REPL, null, "NAME")}}</div>' };
            const colKamAvailable: IMonacoColumnDef = { name: "KAM_AVAILABLE", displayName: "BASIC_DATA.KAM", width: 65, cellFilter: "YesOrNo" };
            const colConcatenated: IMonacoColumnDef = { name: "CONCATENATED_REPL", displayName: "GENERAL.CONCATENATED", width: 600, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.CONCATENATED_REPL, null)}}</div>' };
            const colCommercialUnity: IMonacoColumnDef = { name: "COMMERCIAL_UNITY_REPL.SHORT_NAME", displayName: "BASIC_DATA.COMMERCIAL_BRANCH", width: 120, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.COMMERCIAL_UNITY_REPL, null, "SHORT_NAME")}}</div>' };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 100, cellFilter: "YesOrNo" };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "GENERAL.ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 120, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 120, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'LEGAL_PERSON':
                        columnDefs.push(colLegalPerson);
                        break;
                    case 'NAME':
                        columnDefs.push(colName);
                        break;
                    case 'SECTION':
                        columnDefs.push(colSection);
                        break;
                    case 'QUALIFICATION':
                        columnDefs.push(colQualification);
                        break;
                    case 'PRODUCT_REPL':
                        columnDefs.push(colProduct);
                        break;
                    case 'FORWARDED_BY':
                        columnDefs.push(colForwardedBy);
                        break;
                    case 'LANGUAGE':
                        columnDefs.push(colLanguage);
                        break;
                    case 'SALES_PERSON_REPL':
                        columnDefs.push(colSalesPersonRepl);
                        break;
                    case 'INSIDE_SALES_PERSON_REPL':
                        columnDefs.push(colInsideSalesPersonRepl);
                        break;
                    case 'NETWORK_REPL':
                        columnDefs.push(colNetworkRepl);
                        break;
                    case 'RESPONSIBLE_PRODUCT_REPL':
                        columnDefs.push(colResponsibleProductRepl);
                        break;
                    case 'ACCOUNT_TYPE_REPL':
                        columnDefs.push(colAccountTypeRepl);
                        break;
                    case 'KAM_AVAILABLE':
                        columnDefs.push(colKamAvailable);
                        break;
                    case 'SITUATION':
                        columnDefs.push(colSituation);
                        break;
                    case 'CONCATENATED_REPL':
                        columnDefs.push(colConcatenated);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                    case 'COMMERCIAL_UNITY_REPL':
                        columnDefs.push(colCommercialUnity);
                        break;

                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            ACTIVE: true,
            ID_LEGAL_PERSON: null,
            LEGAL_PERSON: null,
            NAME: null,
            SECTION: null,
            QUALIFICATION: null,
            FORWARDED_BY: null,
            LANGUAGE: null,
            KAM_AVAILABLE: false,
            SITUATION: null,
            REQUIREMENTS: [],
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            DOCUMENT_ERROR: null,
            ACCOUNT_TYPE_REPL: null,
            SEARCH_FIELDS: null,
            CONCATENATED_REPL: null,
            PRODUCT_REPL: null,
            COMMERCIAL_UNITY_REPL: null,
            ACCOUNT_POTENTIAL: []
        };
    }

    async initDependencies(): Promise<any> {
        const self: AccountRegisterController = this;

        this.initDateFields();
        await self.getSectionList();

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getQualificationList(),
                self.getLanguageList(),
                self.getLocalsGroupList(),
                self.getConditionList(),
                self.getProductList(),
                self.getForwardedByList(),
                self.getChargeOriginList(),
                self.getPaymentNatureList(),
                self.getTypePaymentList(),
                self.getTransactionTypeList(),
                self.getAccountModalityList(),
                self.getDestinationThcList(),
                self.getInconsistencyChargeReleaseList(),
                self.getAccountSituationList(),
                self.getAccountTypeList(),
                self.getProcessTypeList(),
                self.getVolumeTypeListByName(),
                self.getRecurrenceListByName(),
                self.getCommodityGroupListByName()
            ]).then((result: any) => {
                self.$scope.qualificationList = result[0];
                self.$scope.languageList = result[1];
                self.$scope.localsGroupList = result[2];
                self.$scope.conditionList = result[3];
                self.$scope.productList = result[4];
                self.$scope.forwardedByList = result[5];
                self.$scope.chargeOriginList = result[6];
                self.$scope.paymentNatureList = result[7];
                self.$scope.typePaymentList = result[8];
                self.$scope.transactionTypeList = result[9];
                self.$scope.accountModalityList = result[10];
                self.$scope.destinationThcList = result[11];
                self.$scope.incchargeReleaseList = result[12];
                self.$scope.accountSituationList = result[13];
                self.$scope.accountTypeList = result[14];
                self.$scope.processTypeList = result[15];
                self.$scope.volumeTypeList = result[16];
                self.$scope.recurrenceList = result[17];
                self.$scope.commodityGroupList = result[18];

                if (self.$scope.qualificationList && self.$scope.qualificationList.length > 0 && !self.$scope.model.QUALIFICATION) {
                    self.$scope.model.QUALIFICATION = self.$scope.qualificationList.find(item => item.NAME = ETypeNameQualification.BRONZE)
                }

                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private setCopy(value: boolean): void {
        this.isCopy = value;
    }

    private async refreshIncoterms(requirementsIndex: number): Promise<void> {
        const incotermList = await this.getIncotermListByName('', requirementsIndex, this.$scope.model.REQUIREMENTS[requirementsIndex].PRODUCT ? [this.$scope.model.REQUIREMENTS[requirementsIndex].PRODUCT.ID] : []);
        const charge = this.$scope.model.REQUIREMENTS[requirementsIndex].CHARGE && this.$scope.model.REQUIREMENTS[requirementsIndex].CHARGE.length ? this.$scope.model.REQUIREMENTS[requirementsIndex].CHARGE : null;
        if (charge) {
            charge.forEach((charge, index) => {
                let result: SelectorModel[] = [];
                if (charge.INCOTERM && charge.INCOTERM.length) {
                    charge.INCOTERM.forEach((incotermAccoRequ) => {
                        incotermList.forEach(incotermProduct => {
                            if (incotermAccoRequ.NAME == incotermProduct.NAME) result.push(incotermProduct);
                        });
                    })
                    this.$scope.model.REQUIREMENTS[requirementsIndex].CHARGE[index].INCOTERM = result;
                }
            });
        }
    }

    private async getQualificationList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/account_qualification`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getLanguageList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/language`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getProcessTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/process_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getSectionList(): Promise<void> {
        let result = [];
        try {
            const { data: sector } = await this.helperService.get(`/generic/value/company_sector`, null);

            if (sector && sector.data && sector.data.length > 0) result = sector.data.sort((x, y) => x.NAME < y.NAME ? -1 : 1);
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.sectionList = result;
        }
    }

    private async getLocalsGroupList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/locals_group`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getConditionList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/account_condition`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getForwardedByList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/forwarded_by`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getChargeOriginList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/charge_origin`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getPaymentNatureList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/payment_nature`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTypePaymentList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_payment`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTransactionTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/transaction`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getAccountModalityList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/account_modality`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getDestinationThcList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/destination_thc`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getInconsistencyChargeReleaseList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/inconsistency_charge_release`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getAccountSituationList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/account_situation`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getAccountTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/account_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getBrokerListByName(brokerFilter?: IBrokerListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const brokers = await this.RestService.newObjectPromise(`${this.$baseUrl}/broker/list/custom`, brokerFilter, 30000, false);
            if (brokers) result = brokers.map(broker => { return { ID: broker.ID, NAME: broker.NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getLegalPersonListByName(legalPersonFilter?: ILegalPersonListCustomFilter): Promise<ILegalPersonSelector[]> {
        let result = [];
        this.formService.block();
        try {
            const legalPersons = await this.RestService.newObjectPromise(`${this.$baseUrl}/legalPerson/list/custom`, legalPersonFilter, 30000, false);
            if (legalPersons) result = legalPersons.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.NAME, CODE: legalPerson.CODE, SHORT_NAME: legalPerson.SHORT_NAME, CORPORATE_NAME: legalPerson.CORPORATE_NAME, ID_FISCAL: legalPerson.ID_FISCAL } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCorporateBranchListByName(search?: string, product?: SelectorModel): Promise<ILegalPersonSelector[]> {
        let result = [];
        this.formService.block();
        try {
            const corporateBranchs = await this.RestService.newObjectPromise(`${this.$baseUrl}/corporateBranch/list/custom`, { search: search, products: product ? [product.ID] : null }, 30000, false);
            if (corporateBranchs) result = corporateBranchs.map(corporateBranch => { return { ID: corporateBranch.ID, NAME: corporateBranch.NAME, CODE: corporateBranch.CODE } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getIncotermListByName(search: string, requirementsIndex: number, products: string[]): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            const incoterms = await this.RestService.newObjectPromise(`${this.$baseUrl}/incoterm/list/custom`, { search, products }, 30000, false);
            if (incoterms && incoterms.length > 0) result = incoterms.map(incoterm => { return { ID: incoterm.ID.toString(), NAME: incoterm.NAME, CODE: incoterm.INITIALS } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCurrencyListByName(name: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.formService.block();

            const currencies = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/currency/list/custom`, { name: name }, 10000, false);
            result = (currencies) ? currencies.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } }) : [];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getPhysicalPersonListByName(physicalPersonFilter?: IPhysicalPersonListCustomFilter): Promise<IPhysicalPersonSelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const physicalPersons = await this.RestService.newObjectPromise(`${this.$baseUrl}/physicalPerson/list/custom`, physicalPersonFilter, 30000, false);
            if (physicalPersons) result = physicalPersons.map(physicalPerson => { return { ID: physicalPerson.ID, NAME: physicalPerson.NAME, CODE: physicalPerson.CODE, SECTOR: physicalPerson.SECTOR ? { ID: physicalPerson.SECTOR.ID, NAME: physicalPerson.SECTOR.NAME, CODE: physicalPerson.SECTOR.CODE } : null } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getRoutingPointListByName(filter: IRoutingPointListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const routingPoints = await this.RestService.newObjectPromise(`${this.$baseUrl}/routingPoint/list/custom`, filter, 30000, false);
            if (routingPoints) result = routingPoints.map(routingPoint => { return { ID: routingPoint.ID, NAME: routingPoint.NAME, CODE: routingPoint.CODE } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getProviderListByName(search?: string): Promise<IProviderSelector[]> {
        let result = [];
        this.formService.block();
        try {
            const providers: IProviderSelector[] = await this.RestService.newObjectPromise(`${this.$baseUrl}/provider/list/custom`, { search: search }, 30000, false);
            if (providers) result = providers.map(provider => { return { ID: provider.ID, NAME: provider.NAME, CODE: provider.CODE, TYPE: provider.TYPE } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCountryListByNameOrInitials(search?: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const countries = await this.RestService.newObjectPromise(`${this.$baseUrl}/country/list/custom`, { search }, 30000, false);
            if (countries) result = countries.map(country => { return { ID: country.ID, NAME: country.NAME, CODE: country.INITIALS } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getEquipmentListByNameOrCode(search?: string): Promise<IEquipmentSelector[]> {
        let result = [];
        this.formService.block();
        try {
            const equipments = await this.RestService.newObjectPromise(`${this.$baseUrl}/equipment/list/custom`, { search: search, products: [] }, 30000, false);
            if (equipments) result = equipments.map(equipment => { return { ID: equipment.ID, NAME: equipment.NAME, CODE: equipment.CODE, TEU: equipment.TEU, FEET: equipment.FEET } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getFinancialContractListCustom(filters: IFilterReq): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const contracts = await this.RestService.newObjectPromise(`${this.$baseUrl}/account/list/contracts/custom`, { filters: filters }, 30000, false);
            if (contracts) result = contracts.map(contract => { return { ID: contract.ID, NAME: contract.CONCATENATED } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getTariffLocalListCustom(filters: IFilterReq): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const tariffs = await this.RestService.newObjectPromise(`${this.$baseUrl}/account/list/tariffLocal/custom`, { filters: filters }, 30000, false);
            if (tariffs) result = tariffs.map(tariff => { return { ID: tariff.ID, NAME: tariff.CONCATENATED } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getTariffFreightContractListCustom(filters: IFilterReq): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const tariffs = await this.RestService.newObjectPromise(`${this.$baseUrl}/account/list/tariffFreightContract/custom`, { filters: filters }, 30000, false);
            if (tariffs) result = tariffs.map(tariff => { return { ID: tariff.ID, NAME: tariff.CONCATENATED } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getTariffFreightListCustom(idAccount: number, products?: string[]): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const tariffs = await this.RestService.newObjectPromise(`${this.$baseUrl}/account/list/tariffFreight/custom`, { idAccount: idAccount, products: products }, 30000, false);
            if (tariffs) result = tariffs.map(tariff => { return { ID: tariff.ID, NAME: tariff.CONCATENATED } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getTariffDetDemListCustom(filters: IFilterReq): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const tariffs = await this.RestService.newObjectPromise(`${this.$baseUrl}/account/list/tariffDetDem/custom`, { filters: filters }, 30000, false);
            if (tariffs) result = tariffs.map(tariff => { return { ID: tariff.ID, NAME: tariff.CONCATENATED } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getProfitShareListCustom(filters: IFilterReq): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const profitShares = await this.RestService.newObjectPromise(`${this.$baseUrl}/account/list/profitShare/custom`, { filters: filters }, 30000, false);
            if (profitShares) result = profitShares.map(tariff => { return { ID: tariff.ID, NAME: tariff.CONCATENATED } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getLegalPersonParamsListCustom(products: string[], processType: string[]): Promise<SelectorModel> {
        let result = null;
        this.formService.block();
        try {
            const unit = await this.RestService.newObjectPromise(`${this.$baseUrl}/legalPersonParams/list/custom`, { product: products, processType: processType }, 30000, false);
            if (unit) result = { ID: unit.ID, NAME: unit.NAME, CODE: unit.CODE };
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }

    }

    private async getNetworkAgentListByLegalPersonSpecType(search?: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const networks = await this.RestService.newObjectPromise(`${this.$baseUrl}/network/listByLegalPersonSpecType`, { name: search, types: ['12'] }, 30000, false);
            if (networks) result = networks.map(network => { return { ID: network.ID, NAME: network.NAME, CODE: network.CODE } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private initCollapseEvents() {
        const collapseAccountPotentials = angular.element("#collapseAccountPotentials");
        const collapseRequirements = angular.element("#collapseRequirements");
        const collapseInactiveRequirements = angular.element("#collapseInactiveRequirements");

        if (collapseRequirements) {
            collapseRequirements.on('shown.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    this.$scope.showInactiveRequirements = false;
                    this.$scope.showRequirements = true;
                    if (collapseInactiveRequirements) collapseInactiveRequirements['collapse']('hide');
                    this.$scope.$applyAsync();
                }
            });
        }

        if (collapseAccountPotentials) {
            collapseAccountPotentials.on('shown.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    this.$scope.showAccountPotentials = true;
                    this.$scope.$applyAsync();
                }
            });
        }

        if (collapseInactiveRequirements) {
            collapseInactiveRequirements.on('shown.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    this.$scope.showRequirements = false;
                    this.$scope.showInactiveRequirements = true;
                    if (collapseRequirements) collapseRequirements['collapse']('hide');
                    this.$scope.$applyAsync();
                }
            });
        }
    }

    private generateConcatenated(requirementIndex: number): void {
        if (!requirementIndex && requirementIndex != 0) return this.formService.handleError("Index is null!");
        try {
            const separator = " | ";
            let concatenated = "";
            const requirement = this.$scope.model.REQUIREMENTS[requirementIndex];

            if (this.$scope.model.LEGAL_PERSON && this.$scope.model.LEGAL_PERSON.NAME && this.$scope.model.LEGAL_PERSON.NAME.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.LEGAL_PERSON.NAME) : concatenated.concat(this.$scope.model.LEGAL_PERSON.NAME);
            }

            if (requirement.EXPORTERS && requirement.EXPORTERS.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, requirement.EXPORTERS.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(requirement.EXPORTERS.map(obj => { return obj.NAME }).join(','));
            }

            if (requirement.IMPORTERS && requirement.IMPORTERS.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, requirement.IMPORTERS.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(requirement.IMPORTERS.map(obj => { return obj.NAME }).join(','));
            }

            if (requirement.NETWORKS && requirement.NETWORKS.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, requirement.NETWORKS.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(requirement.NETWORKS.map(obj => { return obj.NAME }).join(','));
            }

            if (requirement.PRODUCT && requirement.PRODUCT.ID) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, requirement.PRODUCT.ID) : concatenated.concat(requirement.PRODUCT.ID);
            }

            if (requirement.TRADE_ORIGIN && requirement.TRADE_ORIGIN.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, requirement.TRADE_ORIGIN.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(requirement.TRADE_ORIGIN.map(obj => { return obj.NAME }).join(','));
            }

            if (requirement.TRADE_DESTINATION && requirement.TRADE_DESTINATION.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, requirement.TRADE_DESTINATION.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(requirement.TRADE_DESTINATION.map(obj => { return obj.NAME }).join(','));
            }

            if (requirement.CONCATENATED_COMPLEMENT && requirement.CONCATENATED_COMPLEMENT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, requirement.CONCATENATED_COMPLEMENT) : concatenated.concat(requirement.CONCATENATED_COMPLEMENT);
            }

            if (requirement.PROCESS_TYPE && requirement.PROCESS_TYPE.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, requirement.PROCESS_TYPE.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(requirement.PROCESS_TYPE.map(obj => { return obj.NAME }).join(','));
            }

            this.$scope.model.REQUIREMENTS[requirementIndex].CONCATENATED = StringUtil.formatConcatenatedChars(concatenated);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }
    private generateConcatenatedAccountPotential(accountPotentialIndex: number): void {
        if (!accountPotentialIndex && accountPotentialIndex != 0) return this.formService.handleError("Index is null!");
        try {
            const separator = " | ";
            let concatenated = "";
            const accountPotential = this.$scope.model.ACCOUNT_POTENTIAL[accountPotentialIndex];


            if (accountPotential.PRODUCT && accountPotential.PRODUCT.ID) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, accountPotential.PRODUCT.ID) : concatenated.concat(accountPotential.PRODUCT.ID);
            }

            if (accountPotential.COMMODITY_GROUP && accountPotential.COMMODITY_GROUP.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, accountPotential.COMMODITY_GROUP.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(accountPotential.COMMODITY_GROUP.map(obj => { return obj.NAME }).join(','));
            }

            this.$scope.model.ACCOUNT_POTENTIAL[accountPotentialIndex].CONCATENATED = StringUtil.formatConcatenatedChars(concatenated);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private addRequirement(navigate?: boolean): void {
        try {
            const requirement: IRequirements = {
                SHOW: true,
                _id: null,
                ID: null,
                NOT_PERSISTED: true,
                ACTIVE: true,
                ID_ACCOUNT: null,
                PRODUCT: null,
                PROCESS_TYPE: null,
                EXPORTERS: null,
                IMPORTERS: null,
                NETWORKS: null,
                TRADE_ORIGIN: null,
                TRADE_DESTINATION: null,
                ACCOUNT_TYPE: { ID: "2", NAME: "Força de Vendas" },
                CORPORATE_ACCOUNT: false,
                ID_CORPORATE_BRANCH: null,
                CORPORATE_BRANCH: null,
                ID_COMMERCIAL_UNITY: null,
                COMMERCIAL_UNITY: null,
                ID_SALES_PERSON: null,
                SALES_PERSON: null,
                ID_SALES_HOLDER: null,
                SALES_HOLDER: null,
                ID_SECTOR: null,
                SECTOR: null,
                ID_INSIDE_SALES_PERSON: null,
                INSIDE_SALES_PERSON: null,
                ID_INDICATION: null,
                INDICATION: null,
                ID_SPONSOR: null,
                SPONSOR: null,
                ID_RESPONSIBLE_PRODUCT: null,
                RESPONSIBLE_PRODUCT: null,
                KAM_ID_RESPONSIBLE: null,
                CONCATENATED: null,
                CONCATENATED_COMPLEMENT: null,
                BROKER: null,
                COMMERCIAL_INSTRUCTION: null,
                PRODUCT_INSTRUCTION: null,
                OPERATIONAL_INSTRUCTION: null,
                FINANCIAL_INSTRUCTION: null,
                CHARGE_PREFERENCE_INCONSISTENCY: null,
                CHARGE_RELEASE_INCONSISTENCY: null,
                CHARGE_DESTINATION_THC: null,
                CHARGE: null,
                PREFERENCES_SERVICE_PROVIDER: null,
                PREFERENCES_ROUTE_CONDITION: null,
                PREFERENCES_DOCKING_TERMINAL: null,
                PREFERENCES_REDESTINATION_DOCKING_TERMINAL: null,
                PREFERENCES_EMPTY_TERMINAL: null,
                PREFERENCES_UNIQUE_TERM: null,
                PREFERENCES_DTA_RESTRICTION: true,
                PREFERENCES_TRANSSHIPMENT_RESTRICTION: null,
                PREFERENCES_EQUIPMENT_RESTRICTION: null,
                FINANCIAL_CONTRACT_LIST: null,
                TARIFF_LOCAL_LIST: null,
                TARIFF_FREIGHT_CONTRACT_LIST: null,
                TARIFF_FREIGHT_LIST: null,
                TARIFF_DET_DEM_LIST: null,
                PROFIT_SHARE_LIST: null,

            };

            if (!this.$scope.model.REQUIREMENTS) this.$scope.model.REQUIREMENTS = [];
            this.$scope.model.REQUIREMENTS.push(requirement);

            this.$timeout(() => {
                this.collapseHeader("collapseRequirements", "show", navigate);
                for (const index in this.$scope.model.REQUIREMENTS) {

                    if ((parseInt(index) + 1) == this.$scope.model.REQUIREMENTS.length) {
                        this.collapseHeader("collapseRequirement" + index, "show", navigate);
                        this.collapseHeader("collapseRequirementData" + index, "show", navigate);
                    } else {
                        this.collapseHeader("collapseRequirement" + index, "hide");
                    }
                    this.$scope.selectorValidity('incChargePrefSel' + index);
                    this.$scope.selectorValidity('destThcSel' + index);
                    this.$scope.selectorValidity('incChargeReleaseSel' + index);
                    this.$scope.selectorValidity('reqProduct' + index);
                    this.$scope.selectorValidity('reqCorporateBranch' + index);
                    this.$scope.selectorValidity('reqForwardedBy' + index);
                    this.$scope.selectorValidity('reqSalesPerson' + index);
                    this.$scope.selectorValidity('reqInsideSalesPerson' + index);
                    this.$scope.selectorValidity('holder' + index);
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async copyRequirement(index: number): Promise<void> {
        try {
            if (index >= 0) {
                const copyRequirement = angular.copy(this.$scope.model.REQUIREMENTS[index]);

                copyRequirement.SHOW = true;
                copyRequirement._id = null;
                copyRequirement.ID = null;
                copyRequirement.NOT_PERSISTED = true;
                if (copyRequirement.PREFERENCES_SERVICE_PROVIDER && copyRequirement.PREFERENCES_SERVICE_PROVIDER.length) copyRequirement.PREFERENCES_SERVICE_PROVIDER.forEach(serviceProvider => { serviceProvider._id = null; serviceProvider.ID = null; });
                if (copyRequirement.PREFERENCES_ROUTE_CONDITION && copyRequirement.PREFERENCES_ROUTE_CONDITION.length) copyRequirement.PREFERENCES_ROUTE_CONDITION.forEach(routeCondition => { routeCondition._id = null; routeCondition.ID = null; });
                if (copyRequirement.PREFERENCES_DOCKING_TERMINAL && copyRequirement.PREFERENCES_DOCKING_TERMINAL.length) copyRequirement.PREFERENCES_DOCKING_TERMINAL.forEach(dockingTerminal => { dockingTerminal._id = null; dockingTerminal.ID = null; });
                if (copyRequirement.PREFERENCES_REDESTINATION_DOCKING_TERMINAL && copyRequirement.PREFERENCES_REDESTINATION_DOCKING_TERMINAL.length) copyRequirement.PREFERENCES_REDESTINATION_DOCKING_TERMINAL.forEach(redestinationDockingTerminal => { redestinationDockingTerminal._id = null; redestinationDockingTerminal.ID = null; });
                if (copyRequirement.PREFERENCES_EMPTY_TERMINAL && copyRequirement.PREFERENCES_EMPTY_TERMINAL.length) copyRequirement.PREFERENCES_EMPTY_TERMINAL.forEach(emptyTerminal => { emptyTerminal._id = null; emptyTerminal.ID = null; });
                if (copyRequirement.PREFERENCES_UNIQUE_TERM && copyRequirement.PREFERENCES_UNIQUE_TERM.length) copyRequirement.PREFERENCES_UNIQUE_TERM.forEach(emptyTerminal => { emptyTerminal._id = null; emptyTerminal.ID = null; });

                this.$scope.model.REQUIREMENTS.push(copyRequirement);

                this.$timeout(() => {
                    for (const index in this.$scope.model.REQUIREMENTS) {
                        if ((parseInt(index) + 1) == this.$scope.model.REQUIREMENTS.length) {
                            this.collapseHeader("collapseRequirement" + index, "show", true);
                            this.collapseHeader("collapseRequirementData" + index, "show", true);
                        } else {
                            this.collapseHeader("collapseRequirement" + index, "hide");
                        }
                    }
                });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeRequirement(index: number): Promise<void> {
        try {
            if (index >= 0) {
                const modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: "GENERAL.CONFIRM",
                    closeButtonText: "GENERAL.NO",
                    headerText: "GENERAL.CONFIRM_ACTION",
                    bodyText: this.formService.getTranslate("BASIC_DATA.REMOVE_REQUIREMENT")
                });

                if (!modal) return;

                this.formService.block();

                this.$scope.model.REQUIREMENTS.splice(index, 1);

                this.formService.unblock();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private requirementProductChange(index: number): void {
        if (!index && index != 0) return this.formService.handleError("Index is null!");
        if (this.$scope.model.REQUIREMENTS && this.$scope.model.REQUIREMENTS.length > 0 && this.$scope.model.REQUIREMENTS[index]) {
            const requirement = this.$scope.model.REQUIREMENTS[index];

            // clear all related fields
            if (requirement.BROKER && requirement.BROKER.length > 0) {
                for (let i = 0; i < requirement.BROKER.length; i++) {
                    requirement.BROKER[i].ROUTING_POINT = null;
                    this.$timeout(() => { this.$scope.selectorValidity(`reqBrokerRoutingPoint${index}_${i}`) });
                }
            }

            if (requirement.PREFERENCES_ROUTE_CONDITION && requirement.PREFERENCES_ROUTE_CONDITION.length > 0) {
                for (let i = 0; i < requirement.PREFERENCES_ROUTE_CONDITION.length; i++) {
                    requirement.PREFERENCES_ROUTE_CONDITION[i].SOURCE_DESTINATION = null;
                    this.$timeout(() => { this.$scope.selectorValidity(`reqRouteConditionSourceDestination${index}_${i}`) });
                }
            }

            if (requirement.PREFERENCES_DOCKING_TERMINAL && requirement.PREFERENCES_DOCKING_TERMINAL.length > 0) {
                for (let i = 0; i < requirement.PREFERENCES_DOCKING_TERMINAL.length; i++) {
                    requirement.PREFERENCES_DOCKING_TERMINAL[i].TERMINAL = null;
                    requirement.PREFERENCES_DOCKING_TERMINAL[i].PORT = null;
                    this.$timeout(() => { this.$scope.selectorValidity(`reqDockingTerminal${index}_${i}`) });
                    this.$timeout(() => { this.$scope.selectorValidity(`reqDockingTerminalPort${index}_${i}`) });
                }
            }

            if (requirement.PREFERENCES_REDESTINATION_DOCKING_TERMINAL && requirement.PREFERENCES_REDESTINATION_DOCKING_TERMINAL.length > 0) {
                for (let i = 0; i < requirement.PREFERENCES_REDESTINATION_DOCKING_TERMINAL.length; i++) {
                    requirement.PREFERENCES_REDESTINATION_DOCKING_TERMINAL[i].REDESTINATION = null;
                    requirement.PREFERENCES_REDESTINATION_DOCKING_TERMINAL[i].DOCKING = null;
                    requirement.PREFERENCES_REDESTINATION_DOCKING_TERMINAL[i].FINAL_DESTINATION = null;
                    this.$timeout(() => { this.$scope.selectorValidity(`reqRedestinationDockingTerminalRedestination${index}_${i}`) });
                    this.$timeout(() => { this.$scope.selectorValidity(`reqRedestinationDockingTerminalDocking${index}_${i}`) });
                    this.$timeout(() => { this.$scope.selectorValidity(`reqRedestinationDockingTerminalFinalDestination${index}_${i}`) });
                }
            }

            if (requirement.PREFERENCES_EMPTY_TERMINAL && requirement.PREFERENCES_EMPTY_TERMINAL.length > 0) {
                for (let i = 0; i < requirement.PREFERENCES_EMPTY_TERMINAL.length; i++) {
                    requirement.PREFERENCES_EMPTY_TERMINAL[i].TERMINAL = null;
                    requirement.PREFERENCES_EMPTY_TERMINAL[i].PORT = null;
                    this.$timeout(() => { this.$scope.selectorValidity(`reqEmptyTerminal${index}_${i}`) });
                    this.$timeout(() => { this.$scope.selectorValidity(`reqEmptyTerminalPort${index}_${i}`) });
                }
            }
        }
    }

    private requirementCorporateAccountChange(index: number, value: boolean): void {
        if (!index && index != 0) return this.formService.handleError("Index is null!");

        if (this.$scope.model.REQUIREMENTS && this.$scope.model.REQUIREMENTS.length > 0 && this.$scope.model.REQUIREMENTS[index]) {
            if (value) {
                this.$scope.model.REQUIREMENTS[index].SALES_HOLDER = this.$scope.model.REQUIREMENTS[index].COMMERCIAL_UNITY;
                this.$scope.model.REQUIREMENTS[index].ID_SALES_PERSON = null;
                this.$scope.model.REQUIREMENTS[index].SALES_PERSON = null;
                this.$scope.model.REQUIREMENTS[index].ID_SECTOR = null;
                this.$scope.model.REQUIREMENTS[index].SECTOR = null;
            } else {
                this.$scope.model.REQUIREMENTS[index].ID_SALES_HOLDER = null;
                this.$scope.model.REQUIREMENTS[index].SALES_HOLDER = null;
                this.$scope.model.REQUIREMENTS[index].ID_SALES_PERSON = null;
                this.$scope.model.REQUIREMENTS[index].SALES_PERSON = null;
            }
        }
    }

    private addBroker(requirementIndex: number): void {
        if (!requirementIndex && requirementIndex != 0) return this.formService.handleError("Index is null!");

        try {
            const broker: IBroker = { _id: null, ID: null, ID_ACCOUNT_REQUIREMENT: null, ID_BROKER: null, BROKER: null, ID_ROUTING_POINT: null, ROUTING_POINT: null };

            if (!this.$scope.model.REQUIREMENTS[requirementIndex].BROKER) this.$scope.model.REQUIREMENTS[requirementIndex].BROKER = [];

            this.$scope.model.REQUIREMENTS[requirementIndex].BROKER.push(broker);
            const newBrokerIndex = this.$scope.model.REQUIREMENTS[requirementIndex].BROKER.length - 1;
            this.$timeout(() => { this.$scope.selectorValidity(`reqBroker${requirementIndex}_${newBrokerIndex}`) });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeBroker(requirementIndex: number, brokerIndex: number): Promise<void> {
        try {
            if (requirementIndex >= 0 && brokerIndex >= 0) {
                const thisTranslated = this.formService.getTranslate("GENERAL.GENDER.THIS");
                const brokerTranslated = this.formService.getTranslate("GENERAL.MENU.BROKER");
                const modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: "GENERAL.CONFIRM",
                    closeButtonText: "GENERAL.NO",
                    headerText: "GENERAL.CONFIRM_ACTION",
                    bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thisTranslated, prop: brokerTranslated })
                });

                if (!modal) return;

                this.formService.block();

                this.$scope.model.REQUIREMENTS[requirementIndex].BROKER.splice(brokerIndex, 1);

                this.formService.unblock();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private addCharge(requirementIndex: number): void {
        if (!requirementIndex && requirementIndex != 0) return this.formService.handleError("Index is null!");

        try {
            const charge: ICharge = { TYPE: null, CURRENCY: null, INCOTERM: null, DIRECT_BL: false, NATURE: this.$scope.paymentNatureList.find(item => item.ID == '2'), MODALITY: null, RESPONSIBLE: null };

            if (!this.$scope.model.REQUIREMENTS[requirementIndex].CHARGE) this.$scope.model.REQUIREMENTS[requirementIndex].CHARGE = [];

            this.$scope.model.REQUIREMENTS[requirementIndex].CHARGE.push(angular.copy(charge));
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeCharge(requirementIndex: number, chargeIndex: number): Promise<void> {
        try {
            if (requirementIndex >= 0 && chargeIndex >= 0) {
                const modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: "GENERAL.CONFIRM",
                    closeButtonText: "GENERAL.NO",
                    headerText: "GENERAL.CONFIRM_ACTION",
                    bodyText: this.formService.getTranslate("PRODUCT.REMOVE_CHARGE_QUESTION")
                });

                if (!modal) return;

                this.formService.block();

                this.$scope.model.REQUIREMENTS[requirementIndex].CHARGE.splice(chargeIndex, 1);

                this.formService.unblock();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private addServiceProvider(requirementIndex: number): void {
        if (!requirementIndex && requirementIndex != 0) return this.formService.handleError("Index is null!");

        try {
            const serviceProvider = { _id: null, ID: null, ID_ACCOUNT_REQUIREMENT: null, ID_PROVIDER: null, PROVIDER: null, CONDITION: null };

            if (!this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_SERVICE_PROVIDER) this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_SERVICE_PROVIDER = [];

            this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_SERVICE_PROVIDER.push(angular.copy(serviceProvider));

            this.$timeout(() => {
                for (const index in this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_SERVICE_PROVIDER) {
                    this.$scope.selectorValidity(`reqServiceProvider${requirementIndex}_${index}`);
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeServiceProvider(requirementIndex: number, serviceProviderIndex: number): Promise<void> {
        try {
            if (requirementIndex >= 0 && serviceProviderIndex >= 0) {
                const providerTranslated = this.formService.getTranslate("BASIC_DATA.PROVIDER");
                const thisTranslated = this.formService.getTranslate("GENERAL.GENDER.THIS");
                const modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: "GENERAL.CONFIRM",
                    closeButtonText: "GENERAL.NO",
                    headerText: "GENERAL.CONFIRM_ACTION",
                    bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thisTranslated, prop: providerTranslated })
                });

                if (!modal) return;

                this.formService.block();

                this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_SERVICE_PROVIDER.splice(serviceProviderIndex, 1);

                this.formService.unblock();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private addRouteCondition(requirementIndex: number): void {
        if (!requirementIndex && requirementIndex != 0) return this.formService.handleError("Index is null!");

        try {
            const routeCondition = { _id: null, ID: null, ID_ACCOUNT_REQUIREMENT: null, ID_SOURCE_DESTINATION: null, SOURCE_DESTINATION: null, COMPARATIVE_GROUPS: null, TYPE: null, DIRECTION: null };

            if (!this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_ROUTE_CONDITION) this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_ROUTE_CONDITION = [];

            this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_ROUTE_CONDITION.push(angular.copy(routeCondition));
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeRouteCondition(requirementIndex: number, routeConditionIndex: number): Promise<void> {
        try {
            if (requirementIndex >= 0 && routeConditionIndex >= 0) {
                const modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: "GENERAL.CONFIRM",
                    closeButtonText: "GENERAL.NO",
                    headerText: "GENERAL.CONFIRM_ACTION",
                    bodyText: this.formService.getTranslate("PRODUCT.REMOVE_THIS_ROUTE")
                });

                if (!modal) return;

                this.formService.block();

                this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_ROUTE_CONDITION.splice(routeConditionIndex, 1);

                this.formService.unblock();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private addDockingTerminal(requirementIndex: number): void {
        if (!requirementIndex && requirementIndex != 0) return this.formService.handleError("Index is null!");

        try {
            const dockingTerminal = { _id: null, ID: null, ID_ACCOUNT_REQUIREMENT: null, ID_TERMINAL: null, TERMINAL: null, TYPE: null, ID_PORT: null, PORT: null };

            if (!this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_DOCKING_TERMINAL) this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_DOCKING_TERMINAL = [];

            this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_DOCKING_TERMINAL.push(angular.copy(dockingTerminal));
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeDockingTerminal(requirementIndex: number, dockingTerminalIndex: number): Promise<void> {
        try {
            if (requirementIndex >= 0 && dockingTerminalIndex >= 0) {
                const mooringTerminalTranslated = this.formService.getTranslate("REGISTRATION.MOORING_TERMINAL");
                const thisTranslated = this.formService.getTranslate("GENERAL.GENDER.THIS");
                const modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: "GENERAL.CONFIRM",
                    closeButtonText: "GENERAL.NO",
                    headerText: "GENERAL.CONFIRM_ACTION",
                    bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thisTranslated, prop: mooringTerminalTranslated })
                });

                if (!modal) return;

                this.formService.block();

                this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_DOCKING_TERMINAL.splice(dockingTerminalIndex, 1);

                this.formService.unblock();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private addRedestinationDockingTerminal(requirementIndex: number): void {
        if (!requirementIndex && requirementIndex != 0) return this.formService.handleError("Index is null!");

        try {
            const redestinationDockingTerminal = { _id: null, ID: null, ID_ACCOUNT_REQUIREMENT: null, ID_REDESTINATION: null, REDESTINATION: null, ID_DOCKING: null, DOCKING: null, ID_FINAL_DESTINATION: null, FINAL_DESTINATION: null };

            if (!this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_REDESTINATION_DOCKING_TERMINAL) this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_REDESTINATION_DOCKING_TERMINAL = [];

            this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_REDESTINATION_DOCKING_TERMINAL.push(angular.copy(redestinationDockingTerminal));
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeRedestinationDockingTerminal(requirementIndex: number, redestinationDockingTerminalIndex: number): Promise<void> {
        try {
            if (requirementIndex >= 0 && redestinationDockingTerminalIndex >= 0) {
                const relocationTerminalTranslated = this.formService.getTranslate("OPERATIONAL.RELOCATION_TERMINAL");
                const thisTranslated = this.formService.getTranslate("GENERAL.GENDER.THIS");
                const modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: "GENERAL.CONFIRM",
                    closeButtonText: "GENERAL.NO",
                    headerText: "GENERAL.CONFIRM_ACTION",
                    bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thisTranslated, prop: relocationTerminalTranslated })
                });

                if (!modal) return;

                this.formService.block();

                this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_REDESTINATION_DOCKING_TERMINAL.splice(redestinationDockingTerminalIndex, 1);

                this.formService.unblock();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private addEmptyTerminal(requirementIndex: number): void {
        if (!requirementIndex && requirementIndex != 0) return this.formService.handleError("Index is null!");

        try {
            const emptyTerminal = { _id: null, ID: null, ID_ACCOUNT_REQUIREMENT: null, ID_TERMINAL: null, TERMINAL: null, MODALITY: null, ID_PORT: null, PORT: null, TYPE: null };

            if (!this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_EMPTY_TERMINAL) this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_EMPTY_TERMINAL = [];

            this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_EMPTY_TERMINAL.push(angular.copy(emptyTerminal));
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeEmptyTerminal(requirementIndex: number, emptyTerminalIndex: number): Promise<void> {
        try {
            if (requirementIndex >= 0 && emptyTerminalIndex >= 0) {
                const emptyTerminalTranslated = this.formService.getTranslate("REGISTRATION.EMPTY_EQPT_TERMINAL");
                const thisTranslated = this.formService.getTranslate("GENERAL.GENDER.THIS");
                const modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: "GENERAL.CONFIRM",
                    closeButtonText: "GENERAL.NO",
                    headerText: "GENERAL.CONFIRM_ACTION",
                    bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thisTranslated, prop: emptyTerminalTranslated })
                });

                if (!modal) return;

                this.formService.block();

                this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_EMPTY_TERMINAL.splice(emptyTerminalIndex, 1);

                this.formService.unblock();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private addUniqueTerm(requirementIndex: number): void {
        if (!requirementIndex && requirementIndex != 0) return this.formService.handleError("Index is null!");

        try {
            const uniqueTerm = {
                _id: null,
                ID: null,
                ID_ACCOUNT_REQUIREMENT: null,
                ID_FISCAL: null,
                ID_LEGAL_PERSON: null,
                ID_BROKER: null,
                BROKER: null,
                LEGAL_PERSON: null,
                VALIDITY_START: null,
                DISPLAY_VALIDITY_START: null,
                VALIDITY_END: null,
                DISPLAY_VALIDITY_END: null,
                EXTEND_BRANCH: false,
                FILES: null,
                FILES_REMOVE: null,
                FILES_NEW: null
            };

            if (!this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_UNIQUE_TERM) this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_UNIQUE_TERM = [];

            this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_UNIQUE_TERM.push(angular.copy(uniqueTerm));
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeUniqueTerm(requirementIndex: number, uniqueTermIndex: number): Promise<void> {
        try {
            if (requirementIndex >= 0 && uniqueTermIndex >= 0) {
                const uniqueTermTranslated = this.formService.getTranslate("OPERATIONAL.UNIQUE_TERM");
                const thisTranslated = this.formService.getTranslate("GENERAL.GENDER.THIS");
                const modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: "GENERAL.CONFIRM",
                    closeButtonText: "GENERAL.NO",
                    headerText: "GENERAL.CONFIRM_ACTION",
                    bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thisTranslated, prop: uniqueTermTranslated })
                });

                if (!modal) return;

                this.formService.block();

                this.$scope.model.REQUIREMENTS[requirementIndex].PREFERENCES_UNIQUE_TERM.splice(uniqueTermIndex, 1);

                this.formService.unblock();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private updateSelectedLegalPerson(selected: SelectorModel) {
        if (selected && (!this.$scope.model.NAME || this.$scope.model.NAME == "")) this.$scope.model.NAME = selected.NAME;
        if (this.$scope.model.REQUIREMENTS && this.$scope.model.REQUIREMENTS.length) for (const index in this.$scope.model.REQUIREMENTS) { this.generateConcatenated(parseInt(index)); };
    }

    private updateUniqueTermSelectedLegalPerson(selected: ILegalPersonSelector, parentIndex: number, index: number) {
        this.$scope.model.REQUIREMENTS[parentIndex].PREFERENCES_UNIQUE_TERM[index].ID_LEGAL_PERSON = parseInt(selected.ID);
        this.$scope.model.REQUIREMENTS[parentIndex].PREFERENCES_UNIQUE_TERM[index].ID_FISCAL = selected.ID_FISCAL;
    }

    private updateUniqueTermSelectedBroker(selected: SelectorModel, parentIndex: number, index: number) {
        this.$scope.model.REQUIREMENTS[parentIndex].PREFERENCES_UNIQUE_TERM[index].ID_BROKER = parseInt(selected.ID);
    }

    private checkDateValidity(initialDate: Date, finalDate: Date, parentIndex: number, index: number): void {
        try {
            let isValid = false;
            if (!initialDate || typeof initialDate == "string") return;
            if (!finalDate || typeof finalDate == "string") return;
            isValid = ValidateUtil.isValidDateRange(initialDate, finalDate);
            if (!isValid) {
                this.$scope.model.REQUIREMENTS[parentIndex].PREFERENCES_UNIQUE_TERM[index].DISPLAY_VALIDITY_END = null;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async openUniqueTermModal(uniqueTerm: IPreferencesUniqueTerm, operation: string): Promise<void> {
        try {
            this.$scope.uploader = this.getFileUploaderDefault();
            this.modalUniqueTermId = this.ModalService.newModal();

            this.ModalService.showModalInfo(
                {
                    modalID: this.modalUniqueTermId,
                    scope: this.$scope,
                    formService: 'register',
                    template: require("../view/modals/accountUniqueTermModal.html"),
                    size: 'lg'
                },
                null
            );

            await this.buildUniqueTermModalScope(uniqueTerm, operation);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async viewUniqueTermModal(fileUrl: string): Promise<void> {
        try {
            if (!fileUrl) throw Error('fileUrl parameter is null');

            window.open(fileUrl, "_blank");
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'account_unique_term_files', formName: this.formName, folder: this.formName };
            return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData], onSuccessItem: (item: IFileItem, response: ISuccessItemResponse) => this.onSuccessItem(item, response) });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    getFisFilesGenericRoute(): string {
        const baseRoute = '/fis/filesGeneric';
        const uploadRoute = this.config.fisUrl + baseRoute;
        return uploadRoute;
    }

    private onSuccessItem(item: IFileItem, response: ISuccessItemResponse, uniqueTerm?: IPreferencesUniqueTerm): void {
        try {
            if (!item) throw Error('item parameter is null');
            if (!response) throw Error('response parameter is null');

            if (response.data && response.data.length > 0) {
                if (!uniqueTerm.FILES_NEW) uniqueTerm.FILES_NEW = [];

                for (let dataItem of response.data) {
                    const file: IUploadItem = {
                        FILE_DISPLAY_NAME: dataItem.displayFileName,
                        FILE_NAME: dataItem.fileName,
                        FILE_TYPE: dataItem.fileType,
                        FILE_PATH: dataItem.filePath,
                        FILE_URL: dataItem.fileUrl,
                        FILE_HASH: dataItem.fileHash,
                        FILE_SIZE: dataItem.fileSize,
                        FORM_NAME: dataItem.formName,

                    }
                    uniqueTerm.FILES_NEW.push(file);
                }

                uniqueTerm.FILES = angular.copy(uniqueTerm.FILES_NEW);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async buildUniqueTermModalScope(uniqueTerm: IPreferencesUniqueTerm, operation: string): Promise<IUniqueTermModalScope> {
        const modalScope: IUniqueTermModalScope = await this.ModalService.getModalScope(this.modalUniqueTermId);

        this.$scope.uploader.onSuccessItem = (item: IFileItem, response: ISuccessItemResponse) => this.onSuccessItem(item, response, uniqueTerm);

        if (operation == 'edit') {
            modalScope.uniqueTerm = angular.copy(uniqueTerm);
            modalScope.oldUniqueTerm = angular.copy(uniqueTerm);
        }

        modalScope.closeUniqueTermModal = async (): Promise<void> => {
            this.ModalService.closeModal(this.modalUniqueTermId);
            this.modalUniqueTermId = 0;
            this.$scope.uploader.destroy();
        }

        modalScope.removeUpload = (model: IUploadItem): boolean => {
            return this.removeUpload(model, uniqueTerm);
        }

        return modalScope;
    }

    private removeUpload(model: IUploadItem, uniqueTerm: IPreferencesUniqueTerm): boolean {
        let result = false;
        try {
            const isAdminOrCoordinator = (this.$scope.user.roles && (this.$scope.user.roles.hasOwnProperty('ADMIN') || this.$scope.user.roles.hasOwnProperty('COORDINATOR')));

            if (isAdminOrCoordinator) {
                // check if in model.FILES_NEW
                if (uniqueTerm.FILES_NEW && uniqueTerm.FILES_NEW.length > 0) {
                    const index = uniqueTerm.FILES_NEW.findIndex(file => { return file.FILE_HASH == model.FILE_HASH });
                    if (index > -1) {
                        // Remove from FILES_NEW
                        uniqueTerm.FILES_NEW.splice(index, 1);
                    }
                }

                // check if in model.FILES
                if (uniqueTerm.FILES && uniqueTerm.FILES.length > 0) {
                    const index2 = uniqueTerm.FILES.findIndex(file => { return file.FILE_HASH == model.FILE_HASH });
                    if (index2 > -1) {
                        // Remove from FILES
                        uniqueTerm.FILES.splice(index2, 1);
                    }
                }

                result = true;
            } else {
                // check if in model.FILES_NEW
                if (uniqueTerm.FILES_NEW && uniqueTerm.FILES_NEW.length > 0) {
                    const index = uniqueTerm.FILES_NEW.findIndex(file => { return file.FILE_HASH == model.FILE_HASH });
                    if (index > -1) {
                        // Remove from FILES_NEW
                        uniqueTerm.FILES_NEW.splice(index, 1);
                        result = true;
                    }
                }
            }

            if (result) {
                if (!uniqueTerm.FILES_REMOVE) uniqueTerm.FILES_REMOVE = [];
                uniqueTerm.FILES_REMOVE.push(model);
            } else {
                throw Error('You don\'t have permission to remove this file');
            }
        } catch (ex) {
            result = false;
            this.formService.handleError(ex);
        } finally {
            return result;
        }
    }

    private initDateFields(): void {
        try {
            if (this.$scope.model && this.$scope.model.REQUIREMENTS && this.$scope.model.REQUIREMENTS.length) {
                for (let requirement of this.$scope.model.REQUIREMENTS) {
                    if (requirement.PREFERENCES_UNIQUE_TERM && requirement.PREFERENCES_UNIQUE_TERM.length) {
                        for (let uniqueTerm of requirement.PREFERENCES_UNIQUE_TERM) {
                            uniqueTerm.DISPLAY_VALIDITY_START = (uniqueTerm.DISPLAY_VALIDITY_START != null) ? new Date(uniqueTerm.DISPLAY_VALIDITY_START) : null;
                            uniqueTerm.VALIDITY_START = (uniqueTerm.VALIDITY_START != null) ? new Date(uniqueTerm.VALIDITY_START) : null;
                            uniqueTerm.DISPLAY_VALIDITY_END = (uniqueTerm.DISPLAY_VALIDITY_END != null) ? new Date(uniqueTerm.DISPLAY_VALIDITY_END) : null;
                            uniqueTerm.VALIDITY_END = (uniqueTerm.VALIDITY_END != null) ? new Date(uniqueTerm.VALIDITY_END) : null;
                        }
                    }
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async updateAccountType(index: number, selected: ILegalPersonSelector): Promise<void> {
        try {
            if (!index && index != 0) return this.formService.handleError("index is null!");
            if (!selected) return this.formService.handleError("selected is null!");

            if (selected.ID === '1' && this.$scope.model.REQUIREMENTS[index] && this.$scope.model.REQUIREMENTS[index].COMMERCIAL_UNITY) {
                this.$scope.model.REQUIREMENTS[index].SALES_HOLDER = this.$scope.model.REQUIREMENTS[index].COMMERCIAL_UNITY;
                this.$scope.model.REQUIREMENTS[index].SALES_PERSON = null;
                this.$scope.model.REQUIREMENTS[index].ID_SALES_PERSON = null;
                this.$scope.model.REQUIREMENTS[index].ID_SECTOR = null;
                this.$scope.model.REQUIREMENTS[index].SECTOR = null;
            } else {
                this.$scope.model.REQUIREMENTS[index].SALES_HOLDER = null;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getFilters(requirementIndex: number): IFilterReq {
        try {

            if (!requirementIndex && requirementIndex != 0 && !this.$scope.model.REQUIREMENTS[requirementIndex]) throw Error("index is null")

            const requirement = this.$scope.model.REQUIREMENTS[requirementIndex];
            const filter: IFilterReq = {
                idAccount: this.$scope.model.ID,
                idLegalPerson: this.$scope.model.ID_LEGAL_PERSON,
                idAccountReq: requirement.ID,
                products: [requirement.PRODUCT.ID],
                exporters: null,
                importers: null,
                networks: null,
                trade_destination: null,
                trade_origin: null
            }
            if (requirement.EXPORTERS) {
                filter.exporters = requirement.EXPORTERS.map((item) => {
                    return parseInt(item.ID)
                })
            }
            if (requirement.IMPORTERS) {
                filter.importers = requirement.IMPORTERS.map((item) => {
                    return parseInt(item.ID)
                })
            }
            if (requirement.NETWORKS) {
                filter.networks = requirement.NETWORKS.map((item) => {
                    return parseInt(item.ID)
                })
            }
            if (requirement.TRADE_DESTINATION) {
                filter.trade_destination = requirement.TRADE_DESTINATION.map((item) => {
                    return item.ID
                })
            }
            if (requirement.TRADE_ORIGIN) {
                filter.trade_origin = requirement.TRADE_ORIGIN.map((item) => {
                    return item.ID
                })
            }
            return filter
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }
    private async updateFinancialContractList(requirementIndex: number, panelClick: boolean): Promise<void> {
        try {
            if (!requirementIndex && requirementIndex != 0) return this.formService.handleError("index is null!");

            const requirement = this.$scope.model.REQUIREMENTS[requirementIndex];
            if (requirement && (!panelClick || (panelClick && !requirement.FINANCIAL_CONTRACT_LIST))) {
                const filters = this.getFilters(requirementIndex);
                const contracts = await this.getFinancialContractListCustom(filters);
                requirement.FINANCIAL_CONTRACT_LIST = contracts;
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async updateTariffLocalList(requirementIndex: number, panelClick: boolean): Promise<void> {
        try {
            if (!requirementIndex && requirementIndex != 0) return this.formService.handleError("index is null!");

            const requirement = this.$scope.model.REQUIREMENTS[requirementIndex];
            if (requirement && (!panelClick || (panelClick && !requirement.TARIFF_LOCAL_LIST))) {
                const filters = this.getFilters(requirementIndex);
                const tariffs = await this.getTariffLocalListCustom(filters);
                requirement.TARIFF_LOCAL_LIST = tariffs;
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async updateTariffFreightContractList(requirementIndex: number, panelClick: boolean): Promise<void> {
        try {
            if (!requirementIndex && requirementIndex != 0) return this.formService.handleError("index is null!");

            const requirement = this.$scope.model.REQUIREMENTS[requirementIndex];
            if (requirement && (!panelClick || (panelClick && !requirement.TARIFF_FREIGHT_CONTRACT_LIST))) {
                const filters = this.getFilters(requirementIndex);
                const tariffs = await this.getTariffFreightContractListCustom(filters);
                requirement.TARIFF_FREIGHT_CONTRACT_LIST = tariffs;
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async updateTariffDetDemList(requirementIndex: number, panelClick: boolean): Promise<void> {
        try {
            if (!requirementIndex && requirementIndex != 0) return this.formService.handleError("index is null!");

            const requirement = this.$scope.model.REQUIREMENTS[requirementIndex];
            if (requirement && (!panelClick || (panelClick && !requirement.TARIFF_DET_DEM_LIST))) {
                const filters = this.getFilters(requirementIndex);
                const tariffs = await this.getTariffDetDemListCustom(filters);
                requirement.TARIFF_DET_DEM_LIST = tariffs;
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async updateProfitShareList(requirementIndex: number, panelClick: boolean): Promise<void> {
        try {
            if (!requirementIndex && requirementIndex != 0) return this.formService.handleError("index is null!");

            const requirement = this.$scope.model.REQUIREMENTS[requirementIndex];
            if (requirement && (!panelClick || (panelClick && !requirement.PROFIT_SHARE_LIST))) {
                const filters = this.getFilters(requirementIndex);
                const tariffs = await this.getProfitShareListCustom(filters);
                requirement.PROFIT_SHARE_LIST = tariffs;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlDataManager = this.config.productUrl + baseRoute;
        return urlDataManager;
    }

    private getMenuFloatingDefault(): IFloatingMenu {
        return {
            tooltipPlacement: "auto bottom",
            textTooltip: "REGISTRATION.CUSTOMER_DATA",
            infos: [
                { text: "GENERAL.MENU.ACCOUNT", class: "text-rouge font-bold" }
            ],
            options: [
                { click: "collapseHeader", args: ['collapseBasicData', 'toggle', true], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.BASIC_DATA", iconClass: "fa fa-address-card", iconBodyClass: "text-brown" },
                { click: "collapseHeader", args: ['collapseRequirements', 'toggle', true], tooltipPlacement: "auto bottom", textTooltip: "BASIC_DATA.REQUIREMENTS", iconClass: "fa fa-vcard-o", iconBodyClass: "text-brown" },
                { click: "collapseHeader", args: ['collapseAccountPotentials', 'toggle', true], tooltipPlacement: "auto bottom", textTooltip: "BASIC_DATA.ACCOUNT_POTENTIAL", iconClass: "fa fa-vcard-o", iconBodyClass: "text-cyano" },
                { click: "collapseHeader", args: ['collapseAll'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.COLLAPSE_EXPAND_ALL", iconClass: "fa fa-expand", iconBodyClass: "text-danger" }
            ],
            btnActiveDisabled: false
        };
    }

    private async getCorporateUnit(requirementIndex: number): Promise<void> {
        if (!requirementIndex && requirementIndex != 0) return this.formService.handleError("Index is null!");
        try {
            const requirement = this.$scope.model.REQUIREMENTS[requirementIndex];
            if ((!requirement.PRODUCT && !requirement.PRODUCT.ID) || (!requirement.PROCESS_TYPE || requirement.PROCESS_TYPE.length <= 0)) {
                requirement.CORPORATE_BRANCH = null;
                return;
            }

            const processTypes = requirement.PROCESS_TYPE.map(({ ID }) => ID);
            const productId = requirement.PRODUCT.ID;

            const corporateUnit = await this.getLegalPersonParamsListCustom([productId], processTypes);

            if (corporateUnit.ID && corporateUnit.NAME && corporateUnit.CODE) {
                const unity: SelectorModel = {
                    ID: corporateUnit.ID,
                    NAME: corporateUnit.NAME,
                    CODE: corporateUnit.CODE
                }
                requirement.CORPORATE_BRANCH = unity;
            } else {
                this.$formService.notifyError(this.formService.getTranslate('OPERATIONAL.SHIPMENT_TYPE_CANNOT_MATCH_UNIT_CORPORATE'));
                requirement.CORPORATE_BRANCH = null;
            }

            this.$timeout(() => { this.$scope.selectorValidity(`reqCorporateBranch${requirementIndex}`) });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            { PROPERTY: "ACTIVE", LABEL: "GENERAL.ACTIVE" },
            { PROPERTY: "LEGAL_PERSON", LABEL: "ENTITY.CORPORATE_NAME" },
            { PROPERTY: "NAME", LABEL: "GENERAL.NAME" },
            { PROPERTY: "CODE", LABEL: "GENERAL.CODE" },
            { PROPERTY: "SECTION", LABEL: "REGISTRATION.CLIENT_SECTOR_TYPE" },
            { PROPERTY: "QUALIFICATION", LABEL: "GENERAL.QUALIFICATION" },
            { PROPERTY: "SITUATION", LABEL: "GENERAL.SITUATION" },
            { PROPERTY: "REQUIREMENTS", LABEL: "BASIC_DATA.REQUIREMENTS" },
            { PROPERTY: "PRODUCT", LABEL: "BASIC_DATA.PRODUCT" },
            { PROPERTY: "EXPORTERS", LABEL: "BASIC_DATA.SHIPPER BASIC_DATA.CONSIGNEE" },
            { PROPERTY: "IMPORTERS", LABEL: "BASIC_DATA.CONSIGNEE" },
            { PROPERTY: "NETWORKS", LABEL: "BASIC_DATA.EXTERNAL_AGENT_NETWORK" },
            { PROPERTY: "TRADE_ORIGIN", LABEL: "BASIC_DATA.ORIGIN_TRADE_GROUP" },
            { PROPERTY: "TRADE_DESTINATION", LABEL: "BASIC_DATA.DESTINATION_TRADE_GROUP" },
            { PROPERTY: "LANGUAGE", LABEL: "GENERAL.LANGUAGE" },
            { PROPERTY: "CORPORATE_BRANCH", LABEL: "BASIC_DATA.BRANCH" },
            { PROPERTY: "FORWARDED_BY", LABEL: "GENERAL.FORWARDED_BY" },
            { PROPERTY: "SALES_PERSON", LABEL: "BASIC_DATA.SALES_EXECUTIVE" },
            { PROPERTY: "SECTOR", LABEL: "ENTITY.SECTOR" },
            { PROPERTY: "INSIDE_SALES_PERSON", LABEL: "BASIC_DATA.INSIDE_SALES" },
            { PROPERTY: "INDICATION", LABEL: "ENTITY.INDICATION" },
            { PROPERTY: "SPONSOR", LABEL: "BASIC_DATA.ACCOUNT_SPONSOR" },
            { PROPERTY: "RESPONSIBLE_PRODUCT", LABEL: "BASIC_DATA.PRODUCT_RESPONSIBLE" },
            { PROPERTY: "KAM_AVAILABLE", LABEL: "BASIC_DATA.KAM" },
            { PROPERTY: "CONCATENATED", LABEL: "GENERAL.CONCATENATED" },
            { PROPERTY: "OBSERVATION", LABEL: "GENERAL.REMARKS" },
            { PROPERTY: "CHARGE_PREFERENCE_INCONSISTENCY", LABEL: "OPERATIONAL.INCONSISTENCY_CHARGE_PREFERENCE" },
            { PROPERTY: "CHARGE_RELEASE_INCONSISTENCY", LABEL: "OPERATIONAL.INCONSISTENCY_CHARGE_DECLARATION" },
            { PROPERTY: "CHARGE_DESTINATION_THC", LABEL: "OPERATIONAL.DECLARE_DESTINATION_THC_ON_HBL" },
            { PROPERTY: "CHARGE", LABEL: "GENERAL.CHARGE" },
            { PROPERTY: "TYPE", LABEL: "GENERAL.TYPE" },
            { PROPERTY: "DIRECT_BL", LABEL: "BASIC_DATA.STRAIGHT_BL" },
            { PROPERTY: "NATURE", LABEL: "BASIC_DATA.PAYMENT_NATURE" },
            { PROPERTY: "MODALITY", LABEL: "FINANCIAL.CHARGE_PAYMENT_METHOD" },
            { PROPERTY: "PREFERENCES_SERVICE_PROVIDER", LABEL: "BASIC_DATA.PROVIDER" },
            { PROPERTY: "PROVIDER", LABEL: "BASIC_DATA.PROVIDER" },
            { PROPERTY: "CONDITION", LABEL: "GENERAL.CONDITION" },
            { PROPERTY: "PREFERENCES_ROUTE_CONDITION", LABEL: "ROUTE.ROUTES" },
            { PROPERTY: "SOURCE_DESTINATION", LABEL: "BASIC_DATA.ROUTING_DETAILS" },
            { PROPERTY: "COMPARATIVE_GROUPS", LABEL: "REGISTRATION.COMPARATIVE_GROUP" },
            { PROPERTY: "DIRECTION", LABEL: "PRODUCT.WAY" },
            { PROPERTY: "PREFERENCES_DOCKING_TERMINAL", LABEL: "REGISTRATION.MOORING_TERMINAL" },
            { PROPERTY: "TERMINAL", LABEL: "OPERATIONAL.TERMINAL" },
            { PROPERTY: "PORT", LABEL: "ROUTE.PORT" },
            { PROPERTY: "PREFERENCES_REDESTINATION_DOCKING_TERMINAL", LABEL: "OPERATIONAL.RELOCATION_TERMINAL" },
            { PROPERTY: "REDESTINATION", LABEL: "OPERATIONAL.RELOCATION" },
            { PROPERTY: "DOCKING", LABEL: "REGISTRATION.MOORING_TERMINAL" },
            { PROPERTY: "FINAL_DESTINATION", LABEL: "ROUTE.FINAL_DESTINATION" },
            { PROPERTY: "PREFERENCES_EMPTY_TERMINAL", LABEL: "REGISTRATION.EMPTY_EQPT_TERMINAL" },
            { PROPERTY: "PREFERENCES_UNIQUE_TERM", LABEL: "OPERATIONAL.UNIQUE_TERM" },
            { PROPERTY: "PREFERENCES_DTA_RESTRICTION", LABEL: "REGISTRATION.BONDED_TRANSPORT_RESTRICTION" },
            { PROPERTY: "PREFERENCES_TRANSSHIPMENT_RESTRICTION", LABEL: "REGISTRATION.TRANSSHIPMENT_RESTRICTION_GROUP" },
            { PROPERTY: "PREFERENCES_EQUIPMENT_RESTRICTION", LABEL: "REGISTRATION.EQUIPMENT_RESTRICTION_GROUP" },
            { PROPERTY: "ACCOUNT_TYPE", LABEL: "REGISTRATION.ACCOUNT_TYPE" },
            { PROPERTY: "SALES_HOLDER", LABEL: "GENERAL.HOLDER" },
            { PROPERTY: "RESPONSIBLE", LABEL: "REGISTRATION.RESPONSIBLE_USER" },
            { PROPERTY: "BROKER", LABEL: "BASIC_DATA.CUSTOMS_BROKER" },
            { PROPERTY: "ROUTING_POINT", LABEL: "ROUTE.ROUTING_POINT" },
            { PROPERTY: "CORPORATE_ACCOUNT", LABEL: "BASIC_DATA.BRANCH" },
            { PROPERTY: "CONCATENATED_COMPLEMENT", LABEL: "GENERAL.CONCATENATED_COMPLEMENT" },
            { PROPERTY: "CREATED_AT", LABEL: "GENERAL.CREATED_AT" },
            { PROPERTY: "CREATED_BY", LABEL: "GENERAL.CREATED_BY" },
            { PROPERTY: "UPDATED_AT", LABEL: "GENERAL.UPDATED_AT" },
            { PROPERTY: "UPDATED_BY", LABEL: "GENERAL.UPDATED_BY" },
        ]
        return props;
    }

    private collapseHeader(elementId: string, state?: string, navigate?: boolean): void {
        if (elementId === "collapseAll") {
            this.collapseState = this.collapseState == "hide" ? "show" : "hide";
            $('.toggle-me')["collapse"](state ? state : this.collapseState);
        } else if (elementId != "registerBody") {
            $("#" + elementId)["collapse"](state ? state : 'toggle');
        }
        if (navigate && state != "hide") this.$scope.navigateBetweenIds(elementId);
    }

    private collapseRequirement(requirementIndex: number): void {
        if (!requirementIndex && requirementIndex != 0) throw Error("requirementIndex is null.");
        if (this.$scope.model.REQUIREMENTS && this.$scope.model.REQUIREMENTS.length > 0 && this.$scope.model.REQUIREMENTS[requirementIndex]) {
            if (this.$scope.model.REQUIREMENTS[requirementIndex].SHOW) {
                this.collapseHeader('collapseRequirement' + requirementIndex, 'hide');
                this.$timeout(() => { this.$scope.model.REQUIREMENTS[requirementIndex].SHOW = false; }, 500);
            }
            else {
                this.$scope.model.REQUIREMENTS[requirementIndex].SHOW = true;
                this.collapseHeader('collapseRequirement' + requirementIndex, 'show');
                this.$scope.disableElements(this.$scope.operation == EOperation.VIEW);
            }
        }
    }

    private hasHiddenPendingFields(): boolean {
        let hasPendingFields = false;

        const hiddenRequirements = this.$scope.model.REQUIREMENTS ? this.$scope.model.REQUIREMENTS.filter(requirement => !requirement.SHOW) : false;
        if (hiddenRequirements && hiddenRequirements.length) {
            hasPendingFields = hiddenRequirements.some((requirement, requirementIndex) => {
                const isSalesHolderRequired = this.$scope.model.REQUIREMENTS[requirementIndex].ACCOUNT_TYPE && this.$scope.model.REQUIREMENTS[requirementIndex].ACCOUNT_TYPE.ID == '1' && this.$scope.model.REQUIREMENTS[requirementIndex].CORPORATE_ACCOUNT;
                const isSalesPersonRequired = this.$scope.model.REQUIREMENTS[requirementIndex].ACCOUNT_TYPE && (this.$scope.model.REQUIREMENTS[requirementIndex].ACCOUNT_TYPE.ID == '2' || this.$scope.model.REQUIREMENTS[requirementIndex].ACCOUNT_TYPE.ID == '1' && !this.$scope.model.REQUIREMENTS[requirementIndex].CORPORATE_ACCOUNT);
                const hasEmptyFieldInBroker = requirement.BROKER && requirement.BROKER.length ? requirement.BROKER.some(broker => isEmptyObject(broker.BROKER)) : false;
                const hasEmptyFieldInCharge = requirement.CHARGE && requirement.CHARGE.length ? requirement.CHARGE.some(charge => {
                    return isEmptyObject(charge.TYPE) ||
                        isEmptyObject(charge.NATURE) ||
                        isEmptyObject(charge.MODALITY) ||
                        isEmptyObject(charge.RESPONSIBLE)
                }) : false;
                return hasEmptyFieldInBroker || hasEmptyFieldInCharge ||
                    isEmptyObject(requirement.PRODUCT) ||
                    isEmptyObject(requirement.CORPORATE_BRANCH) ||
                    isEmptyObject(requirement.INSIDE_SALES_PERSON) ||
                    isEmptyObject(requirement.ACCOUNT_TYPE) ||
                    (isSalesHolderRequired && isEmptyObject(requirement.SALES_HOLDER)) ||
                    (isSalesPersonRequired && isEmptyObject(requirement.SALES_PERSON)) ||
                    isEmptyObject(requirement.CONCATENATED);
            });
            if (hasPendingFields) this.formService.notifyError(this.formService.getTranslate('GENERAL.ALL_FIELDS_MANDATORY'));
        }
        return hasPendingFields;
    }

    private async callSessionFunctions(data: object): Promise<void> {
        if (data) {
            if (data.hasOwnProperty('ID_REQUIREMENT')) {
                const accountExchangeData = <IAccountExchangeData>data;
                let accountModel: IAccountModel = null;
                for (const gridData of this.$scope.gridOptions.data) {
                    if (accountExchangeData.ID != null && gridData.ID == accountExchangeData.ID) {
                        accountModel = gridData;
                        break;
                    } else if (accountExchangeData.ID != null && gridData.ACCOUNT && gridData.ACCOUNT.length && gridData.ACCOUNT.findIndex(account => account.ID == accountExchangeData.ID) >= 0) {
                        accountModel = gridData;
                        break;
                    }
                }

                if (accountExchangeData.OPERATION && (accountExchangeData.OPERATION === "edit" || accountExchangeData.OPERATION === "view")) {
                    if (accountExchangeData.OPERATION === "view" && accountModel) {
                        await this.view();
                        await this.fetchData(accountModel.ID, EOperation.VIEW);
                    } else if (accountExchangeData.OPERATION === "edit" && accountModel) {
                        await this.edit();
                        await this.fetchData(accountModel.ID, EOperation.EDIT);
                    }
                    
                    const requirementIndex = this.$scope.model.REQUIREMENTS ? this.$scope.model.REQUIREMENTS.findIndex(requirement => requirement.ID == accountExchangeData.ID_REQUIREMENT) : -1;
                    if (requirementIndex >= 0) {
                        this.$scope.model.REQUIREMENTS[requirementIndex].SHOW = true;
                        this.$timeout(() => { this.collapseHeader("collapseRequirementData" + requirementIndex, "show", true) }, 1000);
                    }
                }
            } else {
                const legalPersonExchangeData = <ILegalPersonExchangeData>data;
                if (legalPersonExchangeData.OPERATION == "register") this.$scope.register(legalPersonExchangeData.MODEL);
            }
        }
    }

    async register(params: IAccountModel): Promise<void> {
        try {
            this.$scope.hasEditAccountSituationPermission = true;
            this.$scope.disableElements(false);
            this.$scope.initPanels();
            this.$scope.addRequirement();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");

            if (params && params.LEGAL_PERSON.CORPORATE_NAME) {
                const legalPerson = await this.getLegalPersonListByName({ search: params.LEGAL_PERSON.CORPORATE_NAME, specializations: [] });
                if (legalPerson && legalPerson.length > 0) this.$scope.model.LEGAL_PERSON = legalPerson[0];
            }
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.disableElements(true);
            this.$scope.initPanels();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.model.LEGAL_PERSON) this.$scope.menuFloating.infos = [{ text: this.$scope.model.LEGAL_PERSON.NAME + " - " + this.$scope.model.LEGAL_PERSON.ID_FISCAL, class: "text-rouge font-bold" }];
            this.initDateFields();
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW");
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.initPanels();
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.model.LEGAL_PERSON) this.$scope.menuFloating.infos = [{ text: this.$scope.model.LEGAL_PERSON.NAME + " - " + this.$scope.model.LEGAL_PERSON.ID_FISCAL, class: "text-rouge font-bold" }];
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT");
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.initDateFields();
            this.$scope.disableElements(false);
            await this.clearFields(this.$scope.model, ['CONCATENATED']);
            if (this.$scope.model.REQUIREMENTS && this.$scope.model.REQUIREMENTS.length > 0) {
                for (const requirement of this.$scope.model.REQUIREMENTS) {
                    requirement.SHOW = true;
                    requirement.ID = null;
                    requirement.NOT_PERSISTED = true;
                }
            }
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        let proceed: boolean = true;
        try {
            proceed = !this.hasHiddenPendingFields();
            this.SSEService.closeEvents();
            this.setCopy(false);
        } catch (ex) {
            this.handleError(ex);
            proceed = false;
        }
        return proceed;
    }

    async request(): Promise<IMonacoRequestLog> {
        const operation = this.$scope.operation === "edit" ? "update" : "insert";
        this.setCopy(false);

        return {
            route: `/account/${operation}`,
            data: angular.copy(this.$scope.model),
            oldData: this.$scope.scopeBeforeSave
        };
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
        this.setCopy(false);
    }

    private async openModalIntegration(id: number, legalPersonId: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.idAccountOnModal = id;
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: legalPersonId, gridRowId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "Integration Product/Operation" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number, idSubject: string): Promise<boolean> => {
        let sendSync = false;
        let syncAccountCompiled = false;
        let success = false;
        try {
            if (id) {
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/account/sendSync`, { "idAccount": this.idAccountOnModal, "idSubject": idSubject, "idLegalPerson": id }, 120000, false);
                if (syncRequest) sendSync = true;

                const rc = await this.dataProductService.post(`/sync/currentAccountCompiled`, { "idLegalPerson": [id] }, 120000);
                if (rc && rc.data && rc.data.data && rc.status == 200) syncAccountCompiled = true;

                success = (sendSync && syncAccountCompiled);
            }
        } catch (ex) {
            this.formService.handleError('Ocorreu um erro ao enviar a requisição.');
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const legalPersonData = await this.getAccountById(id);
                    if (row && legalPersonData && legalPersonData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = legalPersonData.DOCUMENT_ERROR;
                        documentError = legalPersonData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async getAccountById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/account/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/account/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);
                if (action === GridColumnBuilderConstants.BTN_EDIT) await this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) await this.$scope.copy(model);
                else await this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private addAccountPotential(navigate?: boolean): void {
        try {
            const accountPotential: IAccountPotential = {
                SHOW: true,
                _id: null,
                ID: null,
                ID_ACCOUNT: this.$scope.model.ID,
                PRODUCT: null,
                COMMODITY_GROUP: null,
                CUSTOMS_BROKER: null,
                INCOTERM: null,
                COMPETITORS: null,
                VOLUME: null,
                VOLUME_TYPE: null,
                RECURRENCE: null,
                GROUP_DESTINATION: null,
                GROUP_ORIGIN: null,
                ACTIVE: true,
                CONCATENATED: null,
            }
            if (!this.$scope.model.ACCOUNT_POTENTIAL) this.$scope.model.ACCOUNT_POTENTIAL = [];
            this.$scope.model.ACCOUNT_POTENTIAL.push(accountPotential);

            this.$timeout(() => {
                this.collapseHeader("collapseAccountPotentials", "show", navigate);
                for (const index in this.$scope.model.ACCOUNT_POTENTIAL) {

                    if ((parseInt(index) + 1) == this.$scope.model.ACCOUNT_POTENTIAL.length) {
                        this.collapseHeader("collapseAccountPotentialData" + index, "show", navigate);
                    } else {
                        this.collapseHeader("collapseAccountPotentialData" + index, "hide");
                    }
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeAccountPotential(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.CLOSE',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate('GENERAL.MESSAGES.CONFIRMATION.REMOVAL')
            });
            if (!modal) return;

            if (this.$scope.model.ACCOUNT_POTENTIAL && this.$scope.model.ACCOUNT_POTENTIAL.length > 0) {
                this.formService.block();
                this.$scope.model.ACCOUNT_POTENTIAL.splice(index, 1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getCommodityGroupListByName(): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const commoditiesGroup = await this.helperService.get(`/generic/value/hscode_section`, null, 30000);
            if (commoditiesGroup && commoditiesGroup.data && commoditiesGroup.data.data) result = commoditiesGroup.data.data.map(group => { return { ID: group.ID, NAME: group.NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getVolumeTypeListByName(): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const volumesType = await this.helperService.get(`/generic/value/volume_type`, null, 30000);
            if (volumesType && volumesType.data && volumesType.data.data) result = volumesType.data.data.map(group => { return { ID: group.ID, NAME: group.NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getRecurrenceListByName(): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const recurrences = await this.helperService.get(`/generic/value/recurrence`, null, 30000);
            if (recurrences && recurrences.data && recurrences.data.data) result = recurrences.data.data.map(group => { return { ID: group.ID, NAME: group.NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }
}

uiShift.$inject = ['$timeout'];
export function uiShift($timeout) {
  return {
    restrict: 'A',
    link: function (scope, el, attr) {
      // get the $prev or $parent of this el
      var _el: any = $(el),
        _window = $(window),
        prev = _el.prev(),
        parent,
        width = _window.width()
        ;

      !prev.length && (parent = _el.parent());

      function sm() {
        $timeout(function () {
          var method = attr.uiShift;
          var target = attr.target;
          _el.hasClass('in') || _el[method](target).addClass('in');
        });
      }

      function md() {
        parent && parent['prepend'](el);
        !parent && _el['insertAfter'](prev);
        _el.removeClass('in');
      }

      (width < 768 && <any>sm()) || md();

      _window.resize(function () {
        if (width !== _window.width()) {
          $timeout(function () {
            (_window.width() < 768 && <any>sm()) || md();
            width = _window.width();
          });
        }
      });
    }
  };
}
uiGridFilter.$inject = ['$timeout', '$rootScope']
export function uiGridFilter($timeout:ng.ITimeoutService, $rootScope: ng.IRootScopeService): ng.IDirective {
    const ddo: ng.IDirective = {
        restrict: 'A',
        link: async (scope: ng.IScope, element: JQLite) => {
            $timeout(() => {
                element.ready(() => {
                    scope.$apply(() => {
                        element.bind("keypress", function (event) {
                            const key = event.which || event.keyCode;
                            if (key === 13) {
                                //$rootScope.$broadcast('triggerGridFilter');
                                scope.$emit(`triggerGridFilter${scope['grid']['id']}`);
                            }
                        });
                    });
                });
            });
        }
    }
    return ddo;
}



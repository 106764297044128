import { PermissionService } from '../services/PermissionService';

monacoPermission.$inject = ['$compile', '$injector', 'SessionService', '$rootScope']
export function monacoPermission($compile, $injector, SessionService, $rootScope) {
    let ddo: any = {};

    ddo.restrict = "A";

    ddo.link = async (scope, el, attrs) => {
        if (attrs.role) {
            const permissionService = new PermissionService(scope, $injector);
            if (!await permissionService.isMenuRoleAllowed(attrs.role)) {
                if (attrs.redirectdenied && attrs.redirectdenied === "true") {
                    await SessionService.redirectToDenied();
                    $rootScope.refreshPage();
                    return;
                }
                const child = el.children().attr('ng-if', 'false');
                $compile(child)(scope);
            }
        }
    }
    return ddo;
}

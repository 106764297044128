import * as angular from 'angular';
import { IGridOptions, IGridRow } from 'ui-grid';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from '@services/GridFormService';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { ISessionService } from "@services/SessionService";
import { GridColumnBuilder } from '../../common/GridColumnBuilder';
import { IMonacoColumnDef } from '@services/GridService2';
import { BrowserTitle } from "../../common/BrowserTitle";
import { OperationalService } from '@services/OperationalService';
import { DataProcessService } from "@services/DataProcessService";
import { IModalService } from '@services/ModalService';
import { IProcessDetDemManagement } from '@models/interface/dataProcess/ProcessDetDemManagement'
import { ISelectorModel, SelectorModel } from '@models/mongo/SelectorModel';
import { IPerDiemTable, IProcessDetDemManagementDetail } from '@models/interface/dataProcess/ProcessDetDemManagementDetail';
import { EOperation, EDirectionId, EPaymentNatureId } from '@enums/GenericData';
import { IEvent } from 'WBA-Model/dist/interface/dataProcess/ProcessDetDemManagement';
import { IProcessParameter } from "../../common/model/ModelParameter";
import { IRestService } from "@services/RestService";
import { HelperService } from "@services/HelperService";

interface IDetDemManagementSituationModalScope extends ng.IScope {
    operation: string;
    processDetDemManagement: IProcessDetDemManagement;
    oldProcessDetDemManagement: IProcessDetDemManagement;
    detDemSituationList: ISelectorModel[];
    applyProcessDetDemManagementSituation: (close?: boolean) => void;
    closeProcessDetDemManagementSituationModal: () => Promise<void>;
}

interface IProcessDetDemManagementDetailGrid {
    _id: string | object;
    ID_DET_DEM_MANAGEMENT: string | object;
    ORIGIN: ISelectorModel;
    PAYMENT_NATURE: ISelectorModel;
    TARIFF_TYPE: ISelectorModel;
    TYPE: string;
    CHARGE: ISelectorModel;
    FREE_TIME: number;
    FREE_TIME_ADITIONAL: number;
    REFERENCE_START: ISelectorModel;
    START_RULE: string;
    REFERENCE_END: ISelectorModel;
    END_RULE: string;
    ESTIMATED_END: Date;
    RULE: ISelectorModel;
    PROGRESSIVE: boolean;
    DAYS_USED: number;
    INCIDENCE: number;
    EQUIPMENT_SITUATION: ISelectorModel;
    ID: number;
    PER_DIEM_TABLE: IPerDiemTable[];
    TOTAL_INCIDENCE: number;
    ALL_TOTAL_VALUE: number;
    CREATED_AT: Date;
    CREATED_BY: ISelectorModel;
    UPDATED_AT: Date;
    UPDATED_BY: ISelectorModel;
}

interface INewProcessDetDemManagementScope extends IGridFormServiceScope {
    model: IProcessDetDemManagement;
    gridOptions: IGridOptions;
    log: IViewLog;
    selectedRows: IProcessDetDemManagement[];
    selectedManagements: IProcessDetDemManagement[];
    actionsDisabled: Boolean;
    executeCellAction: Function;
    scopeBeforeSave: IProcessDetDemManagement;
    customLogProperties: ICustomLogProperties[];
    user: any;
    detDemManagementOriginDetails: IProcessDetDemManagementDetail[];
    detDemManagementDestinationDetails: IProcessDetDemManagementDetail[];
    sessionService: ISessionService;
    viewDetDemManagementDetails: (detDemManagement: IProcessDetDemManagement) => Promise<void>;
    buildDynamicEventCell: (event: IEvent) => string;
    goToProcess: (process: string) => void;
    filterPayment: (detail: IProcessDetDemManagementDetail) => boolean;
    filterReceiving: (detail: IProcessDetDemManagementDetail) => boolean;
    hasPayment: (detail: IProcessDetDemManagementDetail[]) => boolean;
    hasReceiving: (detail: IProcessDetDemManagementDetail[]) => boolean;
    openSituationModal: (processDetDemManagement: IProcessDetDemManagement) => void;
    hasChanges: (newObj: IProcessDetDemManagement, oldObj: IProcessDetDemManagement, propertiesToIgnore?: string[]) => boolean;
    modalSaveConfirmation: (headerText: string, closeButtonText: string) => Promise<boolean>;
}

export class NewProcessDetDemManagementController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope', '$element', 'RestService', 'ModalService'];
    private selectedRows: IProcessDetDemManagement[];
    private $element: JQLite;
    private $scope: INewProcessDetDemManagementScope;
    private $q: ng.IQService;
    private $filter: ng.FilterFactory;
    private gridName: string;
    private operationalService: OperationalService;
    private dataProcessService: DataProcessService;
    private modalService: IModalService;
    private dynamicGridColumns: IMonacoColumnDef[];
    private detDemManagementSituationModalId: number;
    private restService: IRestService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: INewProcessDetDemManagementScope, $element: JQLite) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$element = $element;
        this.$q = $injector.get('$q');
        this.$filter = $injector.get('$filter');
        this.$scope.sessionService = $injector.get('SessionService');
        this.dataProcessService = $injector.get('DataProcessService');
        this.modalService = $injector.get('ModalService');
        this.gridName = 'GRID_PROCESS_DET_DEM_MANAGEMENT';
        this.restService = $injector.get('RestService');
        this.helperService = $injector.get('HelperService');
        this.$gridService.setSelectable(true);
        //set max number of query terms
        this.$gridService.MaxQueryCriteria = 25;

        this.$scope.executeCellAction = this.executeCellAction.bind(this);

        // Limit to 25 selectable rows. After this limit callback will be executed
        this.$gridService.setSelectableRowsLimitCallback(25, (limitReached, selectedRowsLength) => {
            this.$scope.actionsDisabled = limitReached;
            const customBreadcrumbArea = this.$element.find('.breadcrum-custom-area');
            customBreadcrumbArea.toggleClass('ng-hide', !limitReached);
            customBreadcrumbArea.html(limitReached ? `<h4 class='selection-limit-reached'>Selection has exceeded the limit of 25 lines. You have ${selectedRowsLength} rows selected.</h4>` : '');
        });
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.dataProcessService.$route;
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.selectedRows = [];
            this.$scope.selectedRows = [];
            this.$scope.selectedManagements = [];

            this.initForm(this, 'form', 'processDetDemManagement', null, true);
            this.dynamicGridColumns = await this.getDynamicGridColumns();

            this.$gridService.$gridApi.selection.on.rowSelectionChanged(this.$scope, this.selectedRowCallback.bind(this));
            this.$gridService.$gridApi.selection.on.rowSelectionChangedBatch(this.$scope, this.selectedRowBatchCallback.bind(this));
            this.$gridService.$gridOptions.enableFullRowSelection = false;

            await this.initGrid(this.gridName, '/processDetDemManagement/list', true, true, null, true, true);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.viewDetDemManagementDetails = async (detDemManagement: IProcessDetDemManagement): Promise<void> => {
            this.viewDetDemManagementDetails(detDemManagement);
        }
        this.$scope.buildDynamicEventCell = (event: IEvent) => {
            if (!event) return '';
            else return `<span class="${event.DATE_FINAL && !event.DATE_FINAL.IS_EFFECTIVE ? 'text-blue text-italic' : ''}">${event.DATE_FINAL && event.DATE_FINAL.DATE != null ? this.$filter("simpleDate")(event.DATE_FINAL.DATE) : ''}</span>`;
        }
        this.$scope.goToProcess = (process: string) => {
            this.goToProcess(process);
        }
        this.$scope.filterPayment = (detail: IProcessDetDemManagementDetail): boolean => {
            return detail.PAYMENT_NATURE.ID == EPaymentNatureId.PAYMENT
        }
        this.$scope.filterReceiving = (detail: IProcessDetDemManagementDetail): boolean => {
            return detail.PAYMENT_NATURE.ID == EPaymentNatureId.RECEIVING
        }
        this.$scope.hasPayment = (details: IProcessDetDemManagementDetail[]): boolean => {
            return details && details.find(x => x.PAYMENT_NATURE.ID == EPaymentNatureId.PAYMENT) ? true : false;
        }
        this.$scope.hasReceiving = (details: IProcessDetDemManagementDetail[]): boolean => {
            return details && details.find(x => x.PAYMENT_NATURE.ID == EPaymentNatureId.RECEIVING) ? true : false;
        }
        this.$scope.openSituationModal = async (detDemManagement: IProcessDetDemManagement): Promise<void> => {
            this.openSituationModal(detDemManagement);
        }
        this.$scope.hasChanges = (newObj: IProcessDetDemManagement, oldObj: IProcessDetDemManagement, propertiesToIgnore?: string[]) => {
            if (propertiesToIgnore) {
                const newAux = newObj ? angular.copy(newObj) : null;
                const oldAux = oldObj ? angular.copy(oldObj) : null;
                for (const property of propertiesToIgnore) {
                    if (newAux && newAux[property]) {
                        delete newAux[property];
                    }
                    if (oldAux && oldAux[property]) {
                        delete oldAux[property];
                    }
                }
                return !angular.equals(JSON.stringify(newAux), JSON.stringify(oldAux));
            }
            return !angular.equals(newObj, oldObj);
        }

        this.$scope.modalSaveConfirmation = async (headerText: string, closeButtonText: string) => {
            return this.modalSaveConfirmation(headerText, closeButtonText);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID:null,
            ID_PROCESS_DET_DEM: null,
            ID_PROCESS: null,
            PROCESS_NUMBER: null,
            PROCESS_TYPE: null,
            BOOKING: null,
            PRODUCT: null,
            CONTAINER_NUMBER: null,
            CONTAINER_TYPE: null,
            CUSTOMER: null,
            DOCUMENT_HBL: null,
            DOCUMENT_MBL: null,
            EVENT: null,
            FTHD: null,
            FTHO: null,
            FTMD: null,
            FTMO: null,
            INCIDENCE_PAYMENT_ORIGIN: null,
            INCIDENCE_PAYMENT_DESTINATION: null,
            INCIDENCE_RECEIVING_ORIGIN: null,
            INCIDENCE_RECEIVING_DESTINATION: null,
            SERVICE_PROVIDER: null,
            SITUATION_DESTINATION_PAYMENT: null,
            SITUATION_DESTINATION_RECEIVING: null,
            SITUATION_ORIGIN_PAYMENT: null,
            SITUATION_ORIGIN_RECEIVING: null,
            VALIDATION_RESULT: null,
            EVENT_CONTAINER_STATUS: null,
            FILE_NUMBER_PAYMENT_STATUS: null,
            FILE_NUMBER_RECEIVING_STATUS: null,
            OBSERVATION_PAYMENT: null,
            OBSERVATION_RECEIVING: null,
            INSTRUCTION: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
        }
    }

    executeCellAction(actionName: string, entity: any) {
        if (this.$scope.actionsDisabled) {
            this.$formService.notifyInfo(`Selection has exceeded the limit of 25 lines`);
        } else if (this[actionName] != null && typeof this[actionName] === 'function') {
            this[actionName].call(this, entity);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-class="grid.appScope.actionsDisabled ? \'disabled\' : \'\'" ng-click="grid.appScope.executeCellAction(\'viewDetDemManagementDetails\', row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-class="grid.appScope.actionsDisabled ? \'disabled\' : \'\'" ng-click="grid.appScope.executeCellAction(\'openSituationModal\', row.entity)" class="text-especial log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 70,
            maxWidth: 100,
            cellTemplate: (view + edit),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };

        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    private buildColumns(columns: string[]): IMonacoColumnDef[] {
        let gridColumns: Array<IMonacoColumnDef> = [];

        const colProcessNumber: IMonacoColumnDef = { name: 'PROCESS_NUMBER', displayName: 'OPERATIONAL.FILE_NUMBER', width: 150, cellTemplate: `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{ 'OPERATIONAL.PROCESS_VIEW' | translate }} {{row.entity.PROCESS_NUMBER}}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToProcess(row.entity.PROCESS_NUMBER)">{{row.entity.PROCESS_NUMBER}}</a></div>` };
        const colProcessType: IMonacoColumnDef = { name: 'PROCESS_TYPE.NAME', displayName: 'OPERATIONAL.FILE_TYPE', width: 150};
        const colBooking: IMonacoColumnDef = { name: 'BOOKING', displayName: 'OPERATIONAL.BOOKING', width: 150};
        const colValidationResult: IMonacoColumnDef = { name: "VALIDATION_RESULT", displayName: "GENERAL.WARNING", width: 350, cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.VALIDATION_RESULT.WARNING_REASONS, null, "VALIDATION_RESULT_REASON", null, true)}}</div>' };
        const colProduct: IMonacoColumnDef = { name: 'PRODUCT.ID', displayName: 'BASIC_DATA.PRODUCT', width: 100 };
        const colCustomer: IMonacoColumnDef = { name: 'CUSTOMER.NAME', displayName: 'BASIC_DATA.CLIENT', width: 350 };
        const colServiceProvider: IMonacoColumnDef = { name: 'SERVICE_PROVIDER.NAME', displayName: 'BASIC_DATA.PROVIDER', width: 350 };
        const colHbl: IMonacoColumnDef = { name: 'DOCUMENT_HBL', displayName: 'OPERATIONAL.HBL', width: 350, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.DOCUMENT_HBL, null, "")}}</div>' };
        const colMbl: IMonacoColumnDef = { name: 'DOCUMENT_MBL', displayName: 'OPERATIONAL.MBL', width: 350, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.DOCUMENT_MBL, null, "")}}</div>' };
        const colContainerNumber: IMonacoColumnDef = { name: 'CONTAINER_NUMBER', displayName: 'OPERATIONAL.CONTAINER', width: 150 };
        const colContainerType: IMonacoColumnDef = { name: 'CONTAINER_TYPE.NAME', displayName: 'REGISTRATION.CONTAINER_TYPE', width: 150 };
        const colSitContainer: IMonacoColumnDef = { name: 'EVENT_CONTAINER_STATUS.NAME', displayName: 'OPERATIONAL.EVENT_CONTAINER_STATUS', width: 350 };
        const colFtmo: IMonacoColumnDef = { name: 'FTMO', displayName: 'OPERATIONAL.ORIG_FT_MASTER', width: 80 };
        const colFtmd: IMonacoColumnDef = { name: 'FTMD', displayName: 'OPERATIONAL.DEST_FT_MASTER', width: 80 };
        const colFtho: IMonacoColumnDef = { name: 'FTHO', displayName: 'OPERATIONAL.ORIG_FT_HOUSE', width: 80 };
        const colFthd: IMonacoColumnDef = { name: 'FTHD', displayName: 'OPERATIONAL.DEST_FT_HOUSE', width: 80 };
        const colIncidencePaymentOrigin: IMonacoColumnDef = { name: 'INCIDENCE_PAYMENT_ORIGIN', displayName: 'OPERATIONAL.GREATER_BUYING_INCIDENCE_ORIGIN', width: 350 };
        const colIncidenceReceivingOrigin: IMonacoColumnDef = { name: 'INCIDENCE_RECEIVING_ORIGIN', displayName: 'OPERATIONAL.GREATER_SELLING_INCIDENCE_ORIGIN', width: 350 };
        const colIncidencePaymentDestination: IMonacoColumnDef = { name: 'INCIDENCE_PAYMENT_DESTINATION', displayName: 'OPERATIONAL.GREATER_BUYING_INCIDENCE_DESTINATION', width: 350 };
        const colIncidenceReceivingDestination: IMonacoColumnDef = { name: 'INCIDENCE_RECEIVING_DESTINATION', displayName: 'OPERATIONAL.GREATER_SELLING_INCIDENCE_DESTINATION', width: 350 };
        const colSitOriginPayment: IMonacoColumnDef = { name: 'SITUATION_ORIGIN_PAYMENT.NAME', displayName: 'OPERATIONAL.ORIGIN_BUYING_STATUS', width: 250 };
        const colSitOriginReceiving: IMonacoColumnDef = { name: 'SITUATION_ORIGIN_RECEIVING.NAME', displayName: 'OPERATIONAL.ORIGIN_SELLING_STATUS', width: 250 };
        const colSitDestPayment: IMonacoColumnDef = { name: 'SITUATION_DESTINATION_PAYMENT.NAME', displayName: 'OPERATIONAL.DESTINATION_BUYING_STATUS', width: 250 };
        const colSitDestReceiving: IMonacoColumnDef = { name: 'SITUATION_DESTINATION_RECEIVING.NAME', displayName: 'OPERATIONAL.DESTINATION_SELLING_STATUS', width: 250 };
        const colFileNumberPaymentStatus: IMonacoColumnDef = { name: 'FILE_NUMBER_PAYMENT_STATUS.NAME', displayName: 'OPERATIONAL.DD_FILE_NUMBER_PAYMENT_STATUS', width: 250 };
        const colFileNumberReceivingStatus: IMonacoColumnDef = { name: 'FILE_NUMBER_RECEIVING_STATUS.NAME', displayName: 'OPERATIONAL.DD_FILE_NUMBER_RECEIVING_STATUS', width: 250 };
        const colOriginPayment: IMonacoColumnDef = { name: 'ORIGIN_PAYMENT_ESTIMATED_END', displayName: 'OPERATIONAL.ORIGIN_PAYMENT_ESTIMATED_END', width: 250, cellFilter: "date:'dd/MM/yyyy'" };
        const colOriginReceiving: IMonacoColumnDef = { name: 'ORIGIN_RECEIVING_ESTIMATED_END', displayName: 'OPERATIONAL.ORIGIN_RECEIVING_ESTIMATED_END', width: 250, cellFilter: "date:'dd/MM/yyyy'" };
        const colDestinationPayment: IMonacoColumnDef = { name: 'DESTINATION_PAYMENT_ESTIMATED_END', displayName: 'OPERATIONAL.DESTINATION_PAYMENT_ESTIMATED_END', width: 250, cellFilter: "date:'dd/MM/yyyy'" };
        const colDestinationReceiving: IMonacoColumnDef = { name: 'DESTINATION_RECEIVING_ESTIMATED_END', displayName: 'OPERATIONAL.DESTINATION_RECEIVING_ESTIMATED_END', width: 250, cellFilter: "date:'dd/MM/yyyy'" };
        const colInstruction: IMonacoColumnDef = { name: "INSTRUCTION.OPERATIONAL", displayName: "GENERAL.OPERATIONAL_INSTRUCTIONS", width: 150 };

        columns.forEach(column => {
            switch (column) {
                case 'VALIDATION_RESULT':
                    gridColumns.push(colValidationResult);
                    break;
                case 'PROCESS_NUMBER':
                    gridColumns.push(colProcessNumber);
                    break;
                case 'PROCESS_TYPE':
                    gridColumns.push(colProcessType);
                    break;
                case 'BOOKING':
                    gridColumns.push(colBooking);
                    break;
                case 'PRODUCT':
                    gridColumns.push(colProduct);
                    break;
                case 'CUSTOMER':
                    gridColumns.push(colCustomer);
                    break;
                case 'SERVICE_PROVIDER':
                    gridColumns.push(colServiceProvider);
                    if (this.dynamicGridColumns && this.dynamicGridColumns.length) for (const column of this.dynamicGridColumns) gridColumns.push(column);
                    break;
                case 'DOCUMENT_HBL':
                    gridColumns.push(colHbl);
                    break;
                case 'DOCUMENT_MBL':
                    gridColumns.push(colMbl);
                    break;
                case 'CONTAINER_NUMBER':
                    gridColumns.push(colContainerNumber);
                    break;
                case 'CONTAINER_TYPE':
                    gridColumns.push(colContainerType);
                    break;
                case 'EVENT_CONTAINER_STATUS':
                    gridColumns.push(colSitContainer);
                    break;
                case 'FTMO':
                    gridColumns.push(colFtmo);
                    break;
                case 'FTMD':
                    gridColumns.push(colFtmd);
                    break;
                case 'FTHO':
                    gridColumns.push(colFtho);
                    break;
                case 'FTHD':
                    gridColumns.push(colFthd);
                    break;
                case 'INCIDENCE_PAYMENT_ORIGIN':
                    gridColumns.push(colIncidencePaymentOrigin);
                    break;
                case 'INCIDENCE_RECEIVING_ORIGIN':
                    gridColumns.push(colIncidenceReceivingOrigin);
                    break;
                case 'INCIDENCE_PAYMENT_DESTINATION':
                    gridColumns.push(colIncidencePaymentDestination);
                    break;
                case 'INCIDENCE_RECEIVING_DESTINATION':
                    gridColumns.push(colIncidenceReceivingDestination);
                    break;
                case 'SITUATION_ORIGIN_PAYMENT':
                    gridColumns.push(colSitOriginPayment);
                    break;
                case 'SITUATION_ORIGIN_RECEIVING':
                    gridColumns.push(colSitOriginReceiving);
                    break;
                case 'SITUATION_DESTINATION_PAYMENT':
                    gridColumns.push(colSitDestPayment);
                    break;
                case 'SITUATION_DESTINATION_RECEIVING':
                    gridColumns.push(colSitDestReceiving);
                    break;
                case 'FILE_NUMBER_PAYMENT_STATUS':
                    gridColumns.push(colFileNumberPaymentStatus);
                    break;
                case 'FILE_NUMBER_RECEIVING_STATUS':
                    gridColumns.push(colFileNumberReceivingStatus);
                    break;
                case 'ORIGIN_PAYMENT_ESTIMATED_END':
                    gridColumns.push(colOriginPayment);
                    break;
                case 'ORIGIN_RECEIVING_ESTIMATED_END':
                    gridColumns.push(colOriginReceiving);
                    break;
                case 'DESTINATION_PAYMENT_ESTIMATED_END':
                    gridColumns.push(colDestinationPayment);
                    break;
                case 'DESTINATION_RECEIVING_ESTIMATED_END':
                    gridColumns.push(colDestinationReceiving);
                    break;
                case 'INSTRUCTION':
                    gridColumns.push(colInstruction);
                    break;
            }
        });

        return gridColumns;
    }

    initDependencies(): Promise<any> {
        const self: NewProcessDetDemManagementController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
            ]).then((result: any) => {
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} (${this.$scope.model.CONTAINER_NUMBER})`;
            BrowserTitle.$id = this.$scope.model.CONTAINER_NUMBER;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async cancel(): Promise<void> {

    }

    private async getGenericList(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    async request(): Promise<IMonacoRequestLog> {
        const operation = this.$scope.operation === 'register' ? 'insert' : 'update';
        return {
            route: `/processDetDemManagement/${operation}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    private async getDynamicGridColumns(): Promise<IMonacoColumnDef[]> {
        const dynamicColumns: IMonacoColumnDef[] = [];
        try {
            this.block();
            const request = await this.dataProcessService.get('/processDetDemManagement/event', 30000);
            if (request && request.data) {
                const columns: ISelectorModel[] = request.data.data;
                for (const column of columns) {
                    dynamicColumns.push({ name: "EVENT_" + column.ID + ".DATE_FINAL.DATE", displayName: column.NAME, width: 180, cellFilter: "date:'dd/MM/yyyy'", cellTemplate: `<div class="wrapper-md" ng-bind-html="grid.appScope.buildDynamicEventCell(row.entity.EVENT_${column.ID})"></div>` /*cellTemplate: `<div class="wrapper-md" ng-class="{'text-blue text-italic' : ((row.entity.EVENT_${column.ID}.EVENT_TYPE.ID == '4' || row.entity.EVENT_${column.ID}.EVENT_TYPE.ID == '7') && row.entity.EVENT_${column.ID}.EFFECTIVE_DATE_FINAL != null) || ((row.entity.EVENT_${column.ID}.EVENT_TYPE.ID != '4' || row.entity.EVENT_${column.ID}.EVENT_TYPE.ID != '7') && row.entity.EVENT_${column.ID}.FORECAST_DATE_FINAL != null)}">{{row.entity.EVENT_${column.ID}.EFFECTIVE_DATE_FINAL ? row.entity.EVENT_${column.ID}.EFFECTIVE_DATE_FINAL : row.entity.EVENT_${column.ID}.FORECAST_DATE_FINAL | date:'dd/MM/yyyy'}}</div>`*/ });
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return dynamicColumns;
        }
    }

    private goToProcess(process: string) {
        this.$scope.sessionService.openTab("app.operational.newProcess.list", <IProcessParameter>{ PROCESS_NUMBER: process });

    }

    private async viewDetDemManagementDetails(detDemManagement: IProcessDetDemManagement): Promise<void> {
        try {
            this.formService.block();
            const request = await this.dataProcessService.get("/processDetDemManagement/details/" + detDemManagement._id, null);
            if (request && request.data && request.data.data) {
                const details: IProcessDetDemManagementDetailGrid[] = request.data.data; //: IProcessDetDemManagementDetail[] 
                if (details && details.length > 0) {
                    details.forEach(item => {
                        if (item.PER_DIEM_TABLE) {
                            item.PER_DIEM_TABLE.forEach(itemPer => {
                                item.TOTAL_INCIDENCE = item.TOTAL_INCIDENCE ? item.TOTAL_INCIDENCE + itemPer.INCIDENCE : itemPer.INCIDENCE;
                                item.ALL_TOTAL_VALUE = item.ALL_TOTAL_VALUE ? item.ALL_TOTAL_VALUE + itemPer.TOTAL_VALUE_INCIDENCE : itemPer.TOTAL_VALUE_INCIDENCE;
                            })
                        }
                        return item;
                    });
                }
                const origin = details.filter(detail => detail.ORIGIN && detail.ORIGIN.ID == EDirectionId.ORIGIN);
                const destination = details.filter(detail => detail.ORIGIN && detail.ORIGIN.ID == EDirectionId.DESTINATION);


                this.$scope.detDemManagementOriginDetails = origin;
                this.$scope.detDemManagementDestinationDetails = destination;
                const modalId = this.modalService.newModal();
                this.modalService.showModalInfo(
                    {
                        modalID: modalId,
                        scope: this.$scope,
                        formService: EOperation.VIEW,
                        size: 'full modal-overflow',
                        template: require("../view/modal/processDetDemManagementDetailsModal.html"),
                        keyboard: false
                    },
                    null
                );
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async openSituationModal(detDemManagement: IProcessDetDemManagement): Promise<void> {
        try {
            this.detDemManagementSituationModalId = this.modalService.newModal();
            this.modalService.showModalInfo(
                {
                    modalID: this.detDemManagementSituationModalId,
                    scope: this.$scope,
                    formService: 'edit',
                    size: 'lg modal-overflow',
                    template: require("../view/modal/detDemManagementSituationModal.html"),
                    keyboard: true,
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing") {
                            if (reason.toString() == "escape key press") event.preventDefault();
                            if (!closed) {
                                const modalScope: IDetDemManagementSituationModalScope = await this.modalService.getModalScope(this.detDemManagementSituationModalId);
                                await modalScope.closeProcessDetDemManagementSituationModal();
                            }
                        }
                    }
                },
                null
            );

            await this.buildProcessDetDemManagementSituationModalScope(detDemManagement);
        } catch (ex) {
            this.formService.notifyError(ex);
        }
    }

    private async buildProcessDetDemManagementSituationModalScope(detDemManagement: IProcessDetDemManagement): Promise<IDetDemManagementSituationModalScope> {
        try {
            const modalScope: IDetDemManagementSituationModalScope = await this.modalService.getModalScope(this.detDemManagementSituationModalId);

            let result = null;
            if (detDemManagement && this.$scope.selectedManagements && this.$scope.selectedManagements.length <= 1) result = await this.dataProcessService.get(`/processDetDemManagement/byId/${detDemManagement._id}`, 30000);

            modalScope.operation = 'edit';
            modalScope.processDetDemManagement = angular.copy(result ? result.data.data : {});
            modalScope.oldProcessDetDemManagement = angular.copy(result ? result.data.data : {});

            const genericsResult: Array<any> = await this.$q.all([
                this.getGenericList('det_dem_management_situation')
            ]);

            modalScope.detDemSituationList = genericsResult[0];

            modalScope.applyProcessDetDemManagementSituation = (close?: boolean) => {
                this.applyProcessDetDemManagementSituation(modalScope.processDetDemManagement, close);
            };

            modalScope.closeProcessDetDemManagementSituationModal = async (): Promise<void> => {
                if (this.$scope.hasChanges(modalScope.processDetDemManagement, modalScope.oldProcessDetDemManagement)) {
                    const confirm = await this.$scope.modalSaveConfirmation("GENERAL.CLOSE", "GENERAL.CLOSE");
                    if (confirm && !this.applyProcessDetDemManagementSituation(modalScope.processDetDemManagement)) return;
                }
                this.modalService.closeModal(this.detDemManagementSituationModalId);
                this.detDemManagementSituationModalId = 0;
            }

            return modalScope;
        } catch (ex) {
            this.formService.notifyError(ex);
        }
    }

    private async applyProcessDetDemManagementSituation(processDetDemManagement: IProcessDetDemManagement, close?: boolean): Promise<boolean> {
        let success = false;
        try {
            this.formService.block();
            const updateReferenceResponse = await this.dataProcessService.post('/processDetDemManagement/update/status', { id: this.$scope.selectedManagements.length ? this.$scope.selectedManagements.map(m => m._id) : [ processDetDemManagement._id ], paymentStatus: processDetDemManagement.FILE_NUMBER_PAYMENT_STATUS, receivingStatus: processDetDemManagement.FILE_NUMBER_RECEIVING_STATUS, obsPayment: processDetDemManagement.OBSERVATION_PAYMENT, obsReceiving: processDetDemManagement.OBSERVATION_RECEIVING }, 30000);
            if (updateReferenceResponse && updateReferenceResponse.status == 200) {
                success = true;
            }
            if (close) {
                this.modalService.closeModal(this.detDemManagementSituationModalId);
                this.detDemManagementSituationModalId = 0;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            await this.clearSelections();
            this.formService.unblock();
            if (success) {
                const msg = this.formService.getTranslate('GENERAL.REGISTERS_SUCESSFULLY_UPDATED');
                this.formService.notifySuccess(msg);
            }
            return success;
        }
    }

    private async modalSaveConfirmation(headerText: string, closeButtonText: string): Promise<boolean> {
        return await this.modalService.showModalConfirmation({}, {
            headerText: headerText,
            bodyText: this.formService.getTranslate('REGISTRATION.MESSAGES.ERROR.UPDATE_NOT_SAVED'),
            actionButtonText: 'REGISTRATION.SAVE_CONTINUE',
            closeButtonText: 'GENERAL.CLOSE',
        });
    }

    private async viewLogEvent(detDemManagement: any): Promise<void> {
        try {
            this.formService.block();
            let route = `/processDetDemManagement/log/${detDemManagement._id.toString()}`;
            const request = await this.operationalService.get(route, null);

            const log: IViewLog = {
                operation: 'history',
                number: detDemManagement._id.toString(),
                list: [],
                show: true,
                showCloseButton: false,
                searchQuery: '',
                originalList: [],
            }

            log.list = request.data.data;
            log.originalList = angular.copy(log.list);
            this.$scope.log = log;
            this.$scope.customLogProperties = this.getCustomLogProperties();

            const modalId = this.modalService.newModal();
            this.modalService.showModalConfirmation(
                {
                    modalID: modalId,
                    scope: this.$scope,
                    template: require('../../common/view/modals/viewLog.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG"
                }
            );
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'EVENT_NUMBER',
                LABEL: 'GENERAL.NUMBER'
            },
            {
                PROPERTY: 'CODE',
                LABEL: 'BASIC_DATA.INITIALS'
            },
            {
                PROPERTY: 'EVENT',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'LOCAL_TYPE',
                LABEL: 'ROUTE.LOCAL_TYPE'
            },
            {
                PROPERTY: 'EVENT_GROUP',
                LABEL: 'GENERAL.EVENT_GROUP'
            },
            {
                PROPERTY: 'PRODUCT',
                LABEL: 'BASIC_DATA.PRODUCT'
            },
            {
                PROPERTY: 'OPERATION_GROUP',
                LABEL: 'REGISTRATION.OPERATION'
            },
            {
                PROPERTY: 'MODAL_GROUP',
                LABEL: 'GENERAL.MODAL'
            },
            {
                PROPERTY: 'FORWARDED_BY_GROUP',
                LABEL: 'GENERAL.FORWARDED_BY'
            },
            {
                PROPERTY: 'PROCESS_TYPE_GROUP',
                LABEL: 'BASIC_DATA.FILE_TYPE'
            },
            {
                PROPERTY: 'SERVICE_TYPE_GROUP',
                LABEL: 'BASIC_DATA.SERVICE_TYPE'
            },
            {
                PROPERTY: 'CARGO_TYPE_GROUP',
                LABEL: 'BASIC_DATA.CARGO_TYPE'
            },
            {
                PROPERTY: 'INCOTERM_GROUP',
                LABEL: 'BASIC_DATA.INCOTERM'
            },
            {
                PROPERTY: 'DTA',
                LABEL: 'REGISTRATION.BONDED_TRUCK'
            },
            {
                PROPERTY: 'REQUIRED',
                LABEL: 'REGISTRATION.REQUIRED'
            },
            {
                PROPERTY: 'DET_DEM_CONTROL',
                LABEL: 'OPERATIONAL.DET_DEM_CONTROL'
            },
            {
                PROPERTY: 'EVENT_CONTAINER_STATUS',
                LABEL: 'OPERATIONAL.EVENT_CONTAINER_STATUS'
            },
            {
                PROPERTY: 'FILE_NUMBER_PAYMENT_STATUS',
                LABEL: 'OPERATIONAL.DD_FILE_NUMBER_PAYMENT_STATUS'
            },
            {
                PROPERTY: 'FILE_NUMBER_RECEIVING_STATUS',
                LABEL: 'OPERATIONAL.DD_FILE_NUMBER_RECEIVING_STATUS'
            },
            {
                PROPERTY: 'OBSERVATION_PAYMENT',
                LABEL: 'GENERAL.REMARKS'
            },
            {
                PROPERTY: 'OBSERVATION_RECEIVING',
                LABEL: 'GENERAL.REMARKS'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            }
        ]
        return props;
    }
    
    private selectedRowCallback(row: IGridRow): void {
        try {
            this.block();

            const invoice: IProcessDetDemManagement = row.entity;
            const i = this.selectedRows.findIndex(x => x._id === invoice._id);
            if (row.isSelected && i === -1)
                this.selectedRows.push(invoice);
            else
                this.selectedRows.splice(i, 1);

            //update scope
            this.$scope.selectedRows = this.selectedRows.slice();
            this.$scope.selectedManagements = this.selectedRows;

            if (this.selectedRows.length > 1 && this.$scope.log) this.$scope.log.show = false;

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private selectedRowBatchCallback(rows: IGridRow[]): void {
        try {
            rows = this.$gridService.$gridApi.selection.getSelectedGridRows();
            this.block();

            this.selectedRows = rows.filter(x => x.isSelected).map(x => x.entity);
            if (this.selectedRows.length > 1 && this.$scope.log) this.$scope.log.show = false;

            this.$scope.selectedRows = this.selectedRows.slice();
            this.$scope.selectedManagements = this.selectedRows;

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async clearSelections(): Promise<void> {
        this.$scope.selectedRows = [];
        this.$scope.selectedManagements = [];
        this.$gridService.$gridApi.selection.clearSelectedRows();

        if (this.$scope.log) this.$scope.log.show = false;
    }
}

import angular = require("angular");
import { IStopoverModalScope, WizardStopoverController } from "./WizardStopoverController";
import { DEADLINES, MaritimeStopover, PORT } from "@models/interface/operational/maritime/MaritimeStopover";
import { ButtonBar } from "@models/interface/common/DatetimePicker";
import { ISelectorModel } from "@models/mongo/SelectorModel";
import { FormService2 } from "@services/FormService2";
import moment = require("moment");
import { NotificationService } from "@services/NotificationService";

export interface WIZARD_DEADLINE extends DEADLINES {
    PORT: PORT;
    STOPOVER_INDEX;
    VESSEL_NAME: string;
    DEADLINES_INDEX: number;
}

export interface IDeadlineListOptions {
    vessel: string[];
    carrier: ISelectorModel[];
    port: ISelectorModel[];
}

export class WizardDeadlinesPanelController {
    private scope: IStopoverModalScope;
    private parent: WizardStopoverController;
    private formService: FormService2;

    constructor(scope: IStopoverModalScope, parent: WizardStopoverController) {
        this.scope = scope;
        this.parent = parent;

        this.parent.navigationChangeListeners[`${this.parent.TABS.DEADLINES}`] = {
            NEW_RECORD: async () => this.handleDeadlinesRegisterInit(),
            EDIT: async () => this.handleDeadlinesRegisterInit(),
            VIEW: async () => this.handleDeadlinesRegisterInit()
        }

        this.scope.editDeadline = (deadlineIndex: number): void => {
            this.editDeadline(deadlineIndex);
        }

        this.scope.filterDeadlines = (): void => {
            this.filterDeadlines();
        }
    }

    private async handleDeadlinesRegisterInit(): Promise<void> {
        this.parent.block();
        return new Promise((resolve) => {
            try {
                this.generateDeadlines().then((deadlines) => {
                    this.scope.viewData.deadlinesTableData = deadlines;

                    // Fetch Deadline List
                    this.scope.viewData.deadlineList = angular.copy(deadlines);
                    this.scope.viewData.filteredDeadlineList = angular.copy(deadlines);

                    // Fetch Deadline Filter Options
                    this.scope.viewData.deadlineListOptions = { vessel: [], carrier: [], port: [] }
                    for (const deadline of deadlines) {
                        if ((deadline.VESSEL_NAME) && !this.scope.viewData.deadlineListOptions.vessel.some(vessel => vessel == deadline.VESSEL_NAME)) this.scope.viewData.deadlineListOptions.vessel.push(deadline.VESSEL_NAME);
                        if ((deadline.CARRIER) && !this.scope.viewData.deadlineListOptions.carrier.some(carrier => carrier.ID == deadline.CARRIER.ID)) this.scope.viewData.deadlineListOptions.carrier.push({ ID: deadline.CARRIER.ID, NAME: deadline.CARRIER.NAME, CODE: deadline.CARRIER.CODE })
                        if ((deadline.PORT) && !this.scope.viewData.deadlineListOptions.port.some(port => port.ID == deadline.PORT.ID)) this.scope.viewData.deadlineListOptions.port.push({ ID: deadline.PORT.ID, NAME: deadline.PORT.NAME, CODE: deadline.PORT.CODE })
                    }

                    const mainShip = this.scope.voyageBaseData.voyage.VESSEL_INFORMATION.find(x => x.MAIN === true);
                    this.scope.viewData.travelShipownersTravel = (mainShip) ? mainShip.CARRIER_INFORMATION.map(x => `${x.CARRIER.CODE}(${x.VOYAGE})`).join(', ') : null;

                    this.parent.timeout(async () => {
                        this.parent.unblock();
                        resolve(null);
                    });
                });
            } catch (ex) {
                this.parent.handleError(ex);
            }
        });
    }

    private filterDeadlines(): void {
        if (this.scope.viewData && this.scope.viewData.filteredDeadlineList) {
            this.scope.viewData.filteredDeadlineList = angular.copy(this.scope.viewData.deadlineList);

            if (this.scope.viewData.deadlineFilterVessel) {
                this.scope.viewData.filteredDeadlineList = this.scope.viewData.filteredDeadlineList.filter(deadline => deadline.VESSEL_NAME == this.scope.viewData.deadlineFilterVessel);
            }
            if (this.scope.viewData.deadlineFilterCarrier) {
                this.scope.viewData.filteredDeadlineList = this.scope.viewData.filteredDeadlineList.filter(deadline => deadline.CARRIER && deadline.CARRIER.ID == this.scope.viewData.deadlineFilterCarrier.ID);
            }
            if (this.scope.viewData.deadlineFilterPort) {
                this.scope.viewData.filteredDeadlineList = this.scope.viewData.filteredDeadlineList.filter(deadline => deadline.PORT && deadline.PORT.ID == this.scope.viewData.deadlineFilterPort.ID);
            }
        }
    };

    private reloadStopoverDeadlines(): void {
        this.scope.viewData.deadlinesTableData = this.getDeadlinesFromStopovers(this.scope.voyageBaseData.stopovers);
        this.scope.viewData.deadlineList = angular.copy(this.scope.viewData.deadlinesTableData);
        this.filterDeadlines();
    }

    private async updateStopoversBasedOnVoyage() {
        const result = await this.parent.operationalService.post('/stopover/maritime/vessel/refresh/reference', {
            "timeout": 10000,
            ...this.scope.voyageBaseData
        });

        if (result.data && result.data.data) {
            this.scope.voyageBaseData.stopovers = result.data.data;
        }

        await this.scope.$applyAsync();
    }

    private async generateDeadlines(): Promise<WIZARD_DEADLINE[]> {

        await this.updateStopoversBasedOnVoyage();

        const result = await this.parent.operationalService.post('/stopover/maritime/deadline/generate', {
            "stopovers": this.scope.voyageBaseData.stopovers
        });

        const builtStopovers: MaritimeStopover[] = result.data.data && result.data.data.length ? result.data.data : [];

        this.scope.voyageBaseData.stopovers = builtStopovers;

        return this.getDeadlinesFromStopovers(builtStopovers);
    }

    private getDeadlinesFromStopovers(stopovers: MaritimeStopover[]): WIZARD_DEADLINE[] {
        const deadlines = [];
        let identificador: number = 0;
        if (stopovers.length) {
            stopovers.forEach((row, index) => {
                if (row.DEADLINES && row.DEADLINES.length) {
                    row.DEADLINES.forEach((deadline) => {
                        const builtDeadlinesRow = {
                            ...deadline,
                            PORT: row.PORT,
                            STOPOVER_INDEX: index,
                            VESSEL_NAME: row.VESSEL_INFORMATION.VESSEL.NAME,
                            VESSEL_ID: row.VESSEL_INFORMATION.VESSEL.ID,
                            DEADLINES_INDEX: identificador++,
                        };
                        deadlines.push(builtDeadlinesRow);
                    });
                }
            })
        }

        return deadlines;
    }

    private showRecalcDeadlineConfirmationMessage(processes: string[]): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                const modalID = this.parent.ModalService.newModal();
                const onCancel = () => {
                    this.parent.ModalService.closeModal(modalID);
                    resolve(null);
                }

                const keepReviewed = () => {
                    this.parent.ModalService.closeModal(modalID);
                    resolve(false);
                }

                const updateReviewed = () => {
                    this.parent.ModalService.closeModal(modalID);
                    resolve(true);
                }

                this.parent.ModalService.showModalInfo({
                    modalID,
                    size: 'md',
                    scope: this.scope,
                    formService: this.scope.operation,
                    template: require("../../registration/view/recalcModal.html"),
                }, {
                    headerText: "REGISTRATION.ERROR_IN_OPERATION"
                }, {
                    onCancel,
                    keepReviewed,
                    updateReviewed,
                    processes: processes.join(', '),
                });

                this.parent.ModalService.getModalScope(modalID);
            } catch (ex) {
                this.parent.handleError(ex);
                reject();
            }
        });
    }

    private async editDeadline(deadlineIndex: number) {
        try {
            const row: WIZARD_DEADLINE = this.scope.viewData.deadlinesTableData.find(data => data.DEADLINES_INDEX == deadlineIndex);
            let stopoverInEdition: MaritimeStopover = angular.copy(this.scope.voyageBaseData.stopovers[row.STOPOVER_INDEX]);
            const load = stopoverInEdition.ESTIMATED_BERTHING;

            const modalID: number = this.parent.ModalService.newModal();

            let hasChangedValues: boolean = false;
            let house = stopoverInEdition.DEADLINES.find(r => r.CARRIER.NAME === row.CARRIER.NAME && r.TYPE === 'HOUSE');
            let master = stopoverInEdition.DEADLINES.find(r => r.CARRIER.NAME === row.CARRIER.NAME && r.TYPE === 'MASTER');

            const applyModalDeadlinesInScope = async () => {
                const modalScope = await this.parent.ModalService.getModalScope(modalID);

                const newHouseValue = stopoverInEdition.DEADLINES.find(r => r.CARRIER.NAME === row.CARRIER.NAME && r.TYPE === 'HOUSE');
                const newMasterValue = stopoverInEdition.DEADLINES.find(r => r.CARRIER.NAME === row.CARRIER.NAME && r.TYPE === 'MASTER');

                modalScope.house = newHouseValue;
                modalScope.master = newMasterValue;

                if (newHouseValue && (newHouseValue.DRAFT || newHouseValue.DRAFT_IMO || newHouseValue.CARRIER || newHouseValue.RELEASE)) newHouseValue.BASE_DATE = new Date();
                if (newMasterValue && (newMasterValue.DRAFT || newMasterValue.DRAFT_IMO || newMasterValue.CARRIER || newMasterValue.RELEASE)) newMasterValue.BASE_DATE = new Date();

                hasChangedValues = true;
            }

            const validateMasterDraftImo = async (dateValue: Date) => {
                const modalScope = await this.parent.ModalService.getModalScope(modalID);

                if (dateValue && load) {
                    const isValidDate = checkDateValidity(dateValue, 'DRAFT_IMO - MASTER');
                    if (isValidDate) {
                        await onMasterDateChange('DRAFT_IMO');
                    } else {
                        modalScope.modalForm[`master_DRAFT_IMO`].$setValidity('$valid', false);
                        modalScope.modalForm[`house_DRAFT_IMO`].$setValidity('$valid', false);
                    }
                }
            }

            const validateHouseDraftImo = async (dateValue: Date) => {
                const modalScope = await this.parent.ModalService.getModalScope(modalID);

                if (dateValue && load) {
                    const isValidDate = checkDateValidity(dateValue, 'DRAFT_IMO - HOUSE');
                    if (isValidDate) {
                        await onHouseDateChange('DRAFT_IMO');
                    } else {
                        modalScope.modalForm[`house_DRAFT_IMO`].$setValidity('$valid', false);
                    }
                }
            }

            const validateMasterDraft = async (dateValue: Date) => {
                const modalScope = await this.parent.ModalService.getModalScope(modalID);

                if (dateValue && load) {
                    const isValidDate = checkDateValidity(dateValue, 'DRAFT - MASTER');
                    if (isValidDate) {
                        await onMasterDateChange('DRAFT');
                    } else {
                        modalScope.modalForm[`master_DRAFT`].$setValidity('$valid', false);
                        modalScope.modalForm[`house_DRAFT`].$setValidity('$valid', false);
                    }
                }
            }

            const validateHouseDraft = async (dateValue: Date) => {
                const modalScope = await this.parent.ModalService.getModalScope(modalID);

                if (dateValue && load) {
                    const isValidDate = checkDateValidity(dateValue, 'DRAFT - HOUSE');
                    if (isValidDate) {
                        await onHouseDateChange('DRAFT');
                    } else {
                        modalScope.modalForm[`house_DRAFT`].$setValidity('$valid', false);
                    }
                }
            }

            const validateMasterRelease = async (dateValue: Date) => {
                const modalScope = await this.parent.ModalService.getModalScope(modalID);

                if (dateValue && load) {
                    const isValidDate = checkDateValidity(dateValue, 'RELEASE - MASTER');
                    if (isValidDate) {
                        await onMasterDateChange('RELEASE');
                    } else {
                        modalScope.modalForm[`master_RELEASE`].$setValidity('$valid', false);
                        modalScope.modalForm[`house_RELEASE`].$setValidity('$valid', false);
                    }
                }
            }

            const validateHouseRelease = async (dateValue: Date) => {
                const modalScope = await this.parent.ModalService.getModalScope(modalID);

                if (dateValue && load) {
                    const isValidDate = checkDateValidity(dateValue, 'RELEASE - HOUSE');
                    if (isValidDate) {
                        await onHouseDateChange('RELEASE');
                    } else {
                        modalScope.modalForm[`house_RELEASE`].$setValidity('$valid', false);
                    }
                }
            }

            const validateMasterVGM = async (dateValue: Date) => {
                const modalScope = await this.parent.ModalService.getModalScope(modalID);

                if (dateValue && load) {
                    const isValidDate = checkDateValidity(dateValue, 'VGM - MASTER');
                    if (isValidDate) {
                        await onMasterDateChange('VGM');
                    } else {
                        modalScope.modalForm[`master_VGM`].$setValidity('$valid', false);
                        modalScope.modalForm[`house_VGM`].$setValidity('$valid', false);
                    }
                }
            }

            const validateHouseVGM = async (dateValue: Date) => {
                const modalScope = await this.parent.ModalService.getModalScope(modalID);

                if (dateValue && load) {
                    const isValidDate = checkDateValidity(dateValue, 'VGM - HOUSE');
                    if (isValidDate) {
                        await onHouseDateChange('VGM');
                    } else {
                        modalScope.modalForm[`house_VGM`].$setValidity('$valid', false);
                    }
                }
            }

            const checkDateValidity = (dateValue: Date, field: string): boolean => {
                if (dateValue && load) {

                    const loadDate = new Date(load);

                    const setInitiDateValue = new Date(loadDate);
                    setInitiDateValue.setHours(0, 0, 0, 0);
                    setInitiDateValue.setDate(loadDate.getDate() - 30);

                    const setFinalDateValue = new Date(dateValue);
                    setFinalDateValue.setHours(0, 0, 0, 1);

                    const isValidDate = moment(setInitiDateValue).isBefore(setFinalDateValue);

                    if (!isValidDate) {
                        NotificationService().error(`[${field}] - The date ${moment(dateValue).format('DD/MM/YYYY')} is less than the docking date ${moment(loadDate).format('DD/MM/YYYY')}`);
                    }

                    return isValidDate;
                }
            }

            const applyDeadlinesChanges = async () => {
                if (hasChangedValues) {
                    // check manually updated deadlines from all the stopover processes
                    const processNumbers: string[] = [];
                    this.scope.voyageBaseData.stopovers.forEach((stopover) => processNumbers.push(...stopover.PROCESS_REFERENCE));
                    const result = await this.parent.operationalService.post(`/deadline/reviewed`, { processNumbers, carrier: row.CARRIER, port: row.PORT }, 15000);
                    if (result.data && result.data.data && result.data.data.length) {
                        const shouldRecalc: boolean = await this.showRecalcDeadlineConfirmationMessage(result.data.data);
                        if (shouldRecalc == null) return;
                        this.scope.deadlineRecalcOverwriteReviewed = shouldRecalc;
                        for (const processItem of result.data.data) {
                            if (!this.scope.affectedProcesses.includes(processItem)) {
                                this.scope.affectedProcesses.push(processItem);
                            }
                        }
                    }
                }

                this.parent.block();
                this.scope.voyageBaseData.stopovers[row.STOPOVER_INDEX] = stopoverInEdition;
                this.reloadStopoverDeadlines();
                this.parent.ModalService.closeModal(modalID);

                this.parent.timeout(async () => {
                    await this.scope.$applyAsync();
                    this.parent.unblock();
                }, 100, true);
            }

            const discardChanges = () => this.parent.ModalService.closeModal(modalID);
            const onKeyPressed = (event) => {
                let keycode = event.keyCode;
                if (keycode == 13) {
                    event.preventDefault();
                }
            }

            const onMasterDateChange = async (date: string) => {
                const modalScope = await this.parent.ModalService.getModalScope(modalID);
                try {
                    this.parent.block();
                    const timeout: number = 120000;
                    const request: object = {
                        stopover: stopoverInEdition,
                        timeout
                    }
                    const result = await this.parent.operationalService.post('/stopover/maritime/deadline/house/calculate', request, timeout);
                    if (result.data) {
                        if (this.parent.hasChanges(stopoverInEdition.DEADLINES, result.data.data.DEADLINES)) {
                            stopoverInEdition = result.data.data;
                            await applyModalDeadlinesInScope();
                        }
                    }
                    modalScope.modalForm[`master_${date}`].$setValidity('$valid', true);
                } catch (error) {
                    modalScope.modalForm[`master_${date}`].$setValidity('$valid', false);
                    this.parent.notifyError(error.data.data);
                } finally {
                    this.parent.unblock();
                    await this.scope.$applyAsync();
                }
            }

            const onHouseDateChange = async (date: string) => {
                const modalScope = await this.parent.ModalService.getModalScope(modalID);
                try {
                    this.parent.block();
                    const timeout: number = 120000;
                    const request: object = {
                        stopover: stopoverInEdition,
                        timeout
                    }
                    const result = await this.parent.operationalService.post('/stopover/maritime/deadline/house/validate', request, timeout);
                    if (result.data) {
                        if (this.parent.hasChanges(stopoverInEdition.DEADLINES, result.data.data.DEADLINES)) {
                            stopoverInEdition = result.data.data;
                            await applyModalDeadlinesInScope();
                        }
                    }
                    modalScope.modalForm[`house_${date}`].$setValidity('$valid', true);
                } catch (error) {
                    if (error.data && error.data.data.includes(date)) {
                        modalScope.modalForm[`house_${date}`].$setValidity('$valid', false);
                        this.parent.notifyError(error.data.data);
                    }
                } finally {
                    this.parent.unblock();
                    await this.scope.$applyAsync();
                }
            }

            this.parent.ModalService.showModalInfo({
                modalID,
                scope: this.scope,
                formService: 'edit',
                template: require("../view/wizardDeadlinesEditModal.html"),
                size: 'lg'
            }, {
                actionButtonText: `GENERAL.CLOSE`,
                headerText: `${(this.scope.voyageBaseData.voyage.VOYAGE_NUMBER) ? this.scope.voyageBaseData.voyage.VOYAGE_NUMBER : 'OPERATIONAL.NEW_VOYAGE'} - ${this.parent.getTranslate("OPERATIONAL.EDIT_DEADLINE")}`
            }, {
                applyDeadlinesChanges,
                onMasterDateChange,
                onHouseDateChange,
                discardChanges,
                onKeyPressed,
                validateMasterDraftImo,
                validateHouseDraftImo,
                validateMasterDraft,
                validateHouseDraft,
                validateMasterRelease,
                validateHouseRelease,
                validateMasterVGM,
                validateHouseVGM,
                master,
                house,
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }
}

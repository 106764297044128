import { GridFormService, IGridFormController, IGridFormServiceScope, IMonacoRequestLog } from "@services/GridFormService";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { IViewLog, ICustomLogProperties } from "WBA-Model/dist/interface/common/IViewLog";
import { User, Password } from "WBA-Model/dist/interface/operational/User";
import { ISessionService } from "@services/SessionService";
import { SSEService } from "@appServices/SSEService";
import { IRestService } from "@services/RestService";
import { HelperService } from "@services/HelperService";
import { EOperation } from "@enums/GenericData";
import * as angular from "angular";
import uiGrid = require("ui-grid");
import { IMonacoColumnDef } from "@services/GridService2";
import { ISelectorModel, SelectorModel } from "WBA-Model/dist/mongo/SelectorModel";
import { IModalService, IModalOptions } from "@services/ModalService";
import { IModalInstanceService } from "angular-ui-bootstrap";
import { IDocumentError } from "WBA-Model/dist/interface/common/IDocumentError";
import { DataOperationalService } from "@services/DataOperationalService";
import { CryptoService } from "../../app/services/CryptoService";

interface IUserScope extends IGridFormServiceScope {
    model: User;
    passwordModel: Password;
    log: IViewLog;
    croppedURL: string;
    currentEmail: string;
    sessionService: ISessionService;
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: User;
    statusList: object[];
    userGroupList: ISelectorModel[];
    user: any;
    gridOptions: uiGrid.IGridOptions;
    
    fetchData: (id: number, action: string) => Promise<void>;
    editUser: (User: User) => Promise<void>;
    viewUser: (User: User) => Promise<void>;
    viewLogUser: (User: User) => Promise<void>;
    copyUser: (User: User) => Promise<void>;
    getDomain: () => void;
    verifyPassword: () => void;
    uploadImage: () => void;
    showUserPictureModal: () => Promise<void>;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
    generateApiSecretKey: () => Promise<void>;
}

export class UserRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IUserScope;
    private RestService: IRestService;
    private SSEService: SSEService;
    private $timeout: ng.ITimeoutService;
    private helperService: HelperService;
    private ModalService: IModalService;
    private gridName: string;
    private modalID: number;
    private $q: ng.IQService;
    private dataOperationalService: DataOperationalService;
    private cryptoService: CryptoService;
    
    constructor($injector: ng.Injectable<any>, $scope: IUserScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.helperService = $injector.get('HelperService');
        this.ModalService = $injector.get('ModalService');
        this.gridName = 'GRID_USER';
        this.dataOperationalService = $injector.get('DataOperationalService');
        this.$timeout = $injector.get("$timeout");
        this.cryptoService = new CryptoService();
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlHelper();

            this.initForm(this, 'form', 'user', 'GENERAL.MENU.USER', true);
            await this.initGrid('GRID_USER', '/user/list', true, true, 120000, true, true);
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editUser = async (User: User): Promise<void> => {
            let blockedObject = {
                ID: User.ID,
                NAME: User.ID.toString(),
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || User.ID !== this.$scope.model.ID) this.$scope.fetchData(User.ID, EOperation.VIEW);
                } else if (this.$scope.operation !== EOperation.EDIT || User.ID !== this.$scope.model.ID) {
                    this.$scope.fetchData(User.ID, EOperation.EDIT);
                }
            }; 
        }

        this.$scope.viewUser = async (User: User): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(User);
        }

        this.$scope.viewLogUser = async (User: User): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(User);
        }

        this.$scope.copyUser = async (User: User): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(User.ID, EOperation.COPY);
        }

        this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
            this.fetchData(id, action);
        }

        this.$scope.getDomain = async (): Promise<void> => {
            this.getDomain();
        }

        this.$scope.verifyPassword = async (): Promise<void> => {
            this.verifyPassword();
        }

        this.$scope.showUserPictureModal = async (): Promise<void> => {
            this.showUserPictureModal();
        }

        this.$scope.uploadImage = (): void => {
            this.uploadImage();
        }

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }

        this.$scope.generateApiSecretKey = async (): Promise<void> =>{
            this.generateApiSecretKey();
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewUser(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editUser(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="(row.entity.DOCUMENT_ERROR && row.entity.DOCUMENT_ERROR.length) ? 'text-danger' : 'text-green'" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;
         
        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + modalIntegration),
            enableCellEdit: true,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colName: IMonacoColumnDef = { name: "name", displayName: "REGISTRATION.FIRST_NAME", width: 150 };
            const colLastName: IMonacoColumnDef = { name: "lastname", displayName: "REGISTRATION.LAST_NAME", width: 100 };
            const colDisplayName: IMonacoColumnDef = { name: "displayName", displayName: "REGISTRATION.NICKNAME", width: 100 };
            const colEmail: IMonacoColumnDef = { name: "email", displayName: "ENTITY.EMAIL", width: 100 };
            const colGroup: IMonacoColumnDef = { name: "Group", displayName: "REGISTRATION.ACCESS_GROUP", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.Group, null, "NAME")}}</div>' };
            const colStatus: IMonacoColumnDef = { name: "status", displayName: "OPERATIONAL.STATUS", width: 100, cellTemplate: `<div class="text-center" style="margin-top: 4px;"><i class="fa icon" ng-class="(row.entity.status) ? 'fa-check text-green' : 'fa-ban text-danger'"></i></div>`};

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'NAME':
                        columnDefs.push(colName);
                        break;
                    case 'LASTNAME':
                        columnDefs.push(colLastName);
                        break;
                    case 'DISPLAYNAME':
                        columnDefs.push(colDisplayName);
                        break;
                    case 'EMAIL':
                        columnDefs.push(colEmail);
                        break;
                    case 'GROUP':
                        columnDefs.push(colGroup);
                        break;
                    case 'STATUS':
                        columnDefs.push(colStatus);
                        break;
                };
            }

            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.currentEmail = null;
        this.$scope.croppedURL = null;
        this.$scope.statusList = [
            { ID: '1', NAME: this.formService.getTranslate('GENERAL.ACTIVE'), VALUE: true},
            { ID: '2', NAME: this.formService.getTranslate('GENERAL.INACTIVE'), VALUE: false}
        ];

        this.$scope.passwordModel = {
            password: null,
            confirmPassword: null
        }

        this.$scope.model = {
            _id: null,
            ID: null,
            name: null,
            lastname: null,
            displayName: null,
            password: null,
            email: null,
            domain: null,
            image: null,
            Role: [{ ID: '2', NAME: 'ADMIN'}],
            Product: null,
            People: null,
            status: true,
            loginAttempts: null,
            lockUntil: null,
            document: null,
            phone: null,
            DOCUMENT_ERROR: null,
            API_SECRET_KEY: null,
        }
    }

    async initDependencies(): Promise<any> {
        this.$scope.currentEmail = this.$scope.model.email;
        const self: UserRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q
            .all([
                self.getUserGroupList()
            ])
            .then((result: any) => {
                self.$scope.userGroupList = result[0].data.data.data;
                resolve(true);
            })
            .catch(ex => {
                reject(ex);
            })
        })
    }

    async getUserGroupList() {
        return this.helperService.get('/userGroup/all', null, 12000);
    }

    async getDomain(): Promise<void> {
        try{
            if (!this.$scope.model.email) throw new Error('Email is null');

            const email = this.$scope.model.email;
            let domain = email.split("@");

            this.$scope.model.domain = (domain[1]) ? domain[1] : '';
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async verifyPassword(): Promise<boolean> {
        try {
            const newPassword = this.$scope.passwordModel.password;
            const repeatPassword = this.$scope.passwordModel.confirmPassword;

            if (repeatPassword === newPassword) {
                if (newPassword) this.$scope.model.newpassword = newPassword;
                return false;
            }

            return true;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    // async checkStatus(): Promise<void> {
    //     try {
    //         const status = this.$scope.model.status;
    //         switch (status.NAME) {
    //             case 'Active':
    //                 this.$scope.model.status = true;
    //                 break;
    //             case 'Inactive':
    //                 this.$scope.model.status = false;
    //                 break;
    //         }
    //     } catch (ex) {
    //         this.formService.handleError(ex);
    //     }
    // }

    async showUserPictureModal(): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalUserPic(
                {
                    modalID: this.modalID,
                    scope: this.$scope,
                    formService: EOperation.VIEW,
                    template: require("../view/modals/imgCrop.html"),
                    size: 'lg modal-overflow'
                },
                {
                    headerText: "REGISTRATION.IMAGE",
                    actionButtonClass: 'btn-default',
                    closeButtonText: this.formService.getTranslate('GENERAL.CANCEL')
                }
            );

            const modalScope = await this.ModalService.getModalScope(this.modalID);

            const apply = await modalInstance.result.then(function (result) {
                return result.value;
            }, function (result) {
                return result.$value;
            });

            if (apply) {
                const msg = this.formService.getTranslate('GENERAL.REGISTRATION_SUCCESSFULLY_REGISTERED');
                this.formService.notifySuccess(msg);
                this.formService.block();
                await this.updateGrid();
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async uploadImage() {
        try {
            // Todo: implement route and code for upload image.
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            await this.clearFields(this.$scope.model, ['CONCATENATED']);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                if (await this.verifyPassword()) {
                    this.handleError(this.formService.getTranslate('REGISTRATION.SAME_PASSWORD'));
                    return false;
                }

                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === "edit" ? "update" : "insert";

        // await this.checkStatus();

        return {
            route: `/user/${route}`,
            operation: this.$scope.operation,
            data: angular.copy(this.$scope.model),
            timeout: 10000,
            oldData: this.$scope.scopeBeforeSave
        };
    }

    private getUrlHelper(): string {
        const baseRoute = '/helper';
        const urlDataManager = this.config.helperUrl + baseRoute;

        return urlDataManager;
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.helperService.get(`/user/id/${id}`, null, 1000);
            if (request && request.data) {
                const model = angular.copy(request.data.data[0]);

                if (action === GridColumnBuilderConstants.BTN_EDIT) {
                    this.$scope.edit(model);
                } else if (action === GridColumnBuilderConstants.BTN_COPY) {
                    this.$scope.copy(model);
                } else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance(
                { 
                    modalID: this.modalID, 
                    integrationId: id, 
                    documentErrorList: documentErrorList, 
                    fnSync: this.sendSync, 
                    fnUpdateIntegrationGrid: this.updateIntegrationGrid, 
                    headerText: "Integration Operation/Product/Fatturo" 
                }
            );
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getUserById(id: number) {
        if (!id) throw new Error(`${this.formService.getTranslate("GENERAL.ID_IS_NULL")}`);
        this.formService.block();
        try {
            const rc = await this.helperService.get(`/user/id/${id}`, null, 30000);
            return rc.data.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private sendSync = async (id: number): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                const rc = await this.dataOperationalService.post(`/sync/user`, { "id": [id] }, 120000);
                if (rc && rc.data && rc.data.data && rc.status == 200) success = true;
            }
        } catch (ex) {
            this.formService.handleError(`${this.formService.getTranslate("GENERAL.ERROR_SENDING_REQUEST")}`);
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const legalPersonData = await this.getUserById(id);
                    if (row && legalPersonData && legalPersonData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = legalPersonData.DOCUMENT_ERROR;
                        documentError = legalPersonData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError(`${this.formService.getTranslate("GENERAL.ERROR_DURING_REQUEST")}`);
        } finally {
            return documentError;
        }
    }

    private async generateApiSecretKey(): Promise<void> {
        try {
            const userKey = await this.cryptoService.generateUniqueRandomId();
            if (!userKey) throw new Error(`${this.formService.getTranslate("GENERAL.ERROR_CRYPTOSERVICE")}`);
            this.$scope.model.API_SECRET_KEY = `${userKey}`;
            this.$scope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
    }
}
import * as angular from "angular";
import * as moment from 'moment';
import { fileUploader as FileUploader } from 'angular-file-upload';
import { IColumnDef, IGridOptions } from "ui-grid";
import { SSEService } from '@appServices/SSEService';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { ISessionService } from "@services/SessionService";
import { IProviderModel, IProviderSelector } from "@models/interface/product/ProviderModel";
import { IUploader, IUploadItem, IFileItem, IFormData, ISuccessItemResponse } from "@models/interface/common/IMonacoUpload";
import { EProviderTypeId, ECargoTypeId, EOperation, EDomesticProviderType, EProductId, EPaymentNatureId } from "@enums/GenericData";
import { IInlandContract } from "@models/interface/product/InlandContract";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { SelectorModel } from "../../common/model/SelectorModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { ILinkParameter, ILinkOperationParameter } from "../../common/model/ModelParameter";
import { StringUtil } from "../../common/util/StringUtil";
import { IFloatingMenu } from "../../common/interface/IFloatingMenu";
import { ValidateUtil } from "../../common/util/ValidateUtil";
import { ProductService } from "@services/ProductService";
import { ICustomLogProperties, IViewLog } from "@models/interface/common/IViewLog";
import { HelperService } from "@services/HelperService";
import { ISelectorModel } from "WBA-Model/dist/mongo/SelectorModel";

const maritimeParams = {
    CARGO_TYPE: [
        ECargoTypeId.FCL, ECargoTypeId.RO_RO, ECargoTypeId.BREAK_BULK, ECargoTypeId.LCL
    ],
    COMPANY_TYPE: [
        EDomesticProviderType.SHIP_OWNER, EDomesticProviderType.TRANSPORT_COMPANY, EDomesticProviderType.EXTERNAL_AGENT
    ]
};

const airParams = {
    CARGO_TYPE: [
        ECargoTypeId.AIR
    ],
    COMPANY_TYPE: [
        EDomesticProviderType.AIR_COMPANY, EDomesticProviderType.EXTERNAL_AGENT, EDomesticProviderType.TRANSPORT_COMPANY
    ]
};

interface IInlandContractScope extends IGridFormServiceScope {
    form: ng.IFormController;
    gridOptions: IGridOptions;
    model: IInlandContract;
    scopeBeforeSave: IInlandContract;
    customLogProperties: ICustomLogProperties[];
    productList: SelectorModel[];
    accountList: SelectorModel[];
    cargoTypeList: SelectorModel[];
    receivingTransaction: boolean;
    wayList: SelectorModel[];
    serviceProviderList: SelectorModel[];
    agentList: SelectorModel[];
    situationList: SelectorModel[];
    companyTypeList: SelectorModel[];
    transactionList: SelectorModel[];
    eventList: SelectorModel[];
    uploader: IUploader;
    user: any;
    menuFloating: IFloatingMenu;
    sessionService: ISessionService;
    log: IViewLog;
    cargoTypeListFiltered: SelectorModel[];
    companyTypeListFiltered: SelectorModel[];

    editInlandContract: (inlandContract: IInlandContract) => Promise<void>;
    viewInlandContract: (inlandContract: IInlandContract) => Promise<void>;
    viewLogInlandContract: (inlandContract: IInlandContract) => Promise<void>;
    copyInlandContract: (inlandContract: IInlandContract) => Promise<void>;
    removeUpload: (model: IUploadItem) => boolean;
    getGridViewProvider: (provider: IProviderSelector, legalPersonAgent: ISelectorModel) => string;
    getServiceProviderListByName: (search: string, operatorType: SelectorModel) => Promise<void>;
    getAgentListByName: (search: string, operatorType: SelectorModel) => Promise<void>;
    getAccountListByName: (search: string) => Promise<void>;
    goToAccount: () => void;
    goToProvider: (id: number) => void;
    goToAgent: (id: number) => void;
    addInlandRoute: (inlandContract: IInlandContract) => void;
    limitLines: (value: string, limit: number) => string;
    formatConcatenatedChars: (value: string) => string;
    collapseHeader: (elementId: string, state?: string, navigate?: boolean) => void;
    generateConcatenated: () => void;
    updateRequired: () => void;
    checkDateValidity: (initialDate: Date, finalDate: Date) => void;
    updateCompanyType: () => void;
    updateProductRelations: () => void;
    setCopy: (value: boolean) => void;
    fetchData: (id: number, action: string) => Promise<void>;
}

export class InlandContractRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IInlandContractScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private $timeout: ng.ITimeoutService
    private fileUploader: FileUploader;
    private formName: string;
    private gridName: string;
    private collapseState: string;
    private SSEService: SSEService;
    private isCopy: boolean;
    private ProductService: ProductService;
    private HelperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IInlandContractScope) {
        super($injector, $scope);
        this.formName = 'inlandContract';
        this.gridName = 'inlandContract';
        this.$scope = $scope;
        this.$scope.additionalIndexSelectorValidity = ['$childIndex', '$parentIndex'];
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.$timeout = $injector.get('$timeout');
        this.$scope.sessionService = $injector.get('SessionService');
        this.fileUploader = $injector.get('FileUploader');
        this.collapseState = "hide";
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.ProductService = $injector.get('ProductService');
        this.HelperService = $injector.get('HelperService');

    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    private getUrlQuotationCombined(): string {
        const baseRoute = '/quotationCombined';
        const urlQuotationCombined = this.config.quotationCombinedUrl + baseRoute;
        return urlQuotationCombined;
    }

    getFisFilesGenericRoute(): string {
        const baseRoute = '/fis/filesGeneric';
        const uploadRoute = this.config.fisUrl + baseRoute;
        return uploadRoute;
    }

    async $onInit(): Promise<void> {
        try {
            this.$scope.uploader = this.getFileUploaderDefault();
            this.$baseUrl = this.getUrlProduct();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.customLogProperties = this.getCustomLogProperties();

            this.initForm(this, 'form', this.formName, 'PRODUCT.INLAND_CONTRACT', true);
            await this.initGrid(this.gridName, '/inlandContract/list', true, true, null, true, true);
            this.SSEService.closeEvents();
            this.setCopy(false);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initDependencies(): Promise<any> {
        const self: InlandContractRegisterController = this;
        return new Promise(function (resolve, reject) {
            self.initDateFields();
            self.$q.all([
                self.getGenericList('product'), //0
                self.getGenericList('direction'), //1
                self.getGenericList('registration_situation'), //2
                self.getGenericList('domestic_provider_type'), //3
                self.getGenericList('type_cargo'), //4
                self.getGenericList('payment_nature'), //5
                self.getGenericList('event'), //5
            ]).then((result: any) => {
                self.$scope.productList = self.filterProductList(result[0]);
                self.$scope.wayList = result[1];
                self.$scope.situationList = result[2];
                self.$scope.companyTypeList = result[3];
                self.$scope.cargoTypeList = result[4];
                self.$scope.transactionList = result[5];
                if (self.$scope.situationList && self.$scope.situationList.length > 0 && !self.$scope.model.SITUATION) {
                    self.$scope.model.SITUATION = self.$scope.situationList.find(item => item.ID = '1');
                };
                self.$scope.eventList = result[6];

                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getGenericList(type: string, alternative: boolean = false): Promise<SelectorModel[]> {
        const { data: generic } = await this.HelperService.get(`/generic/value/${type}/${alternative}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editInlandContract = async (inlandContract: IInlandContract): Promise<void> => {
            let blockedObject = {
                ID: inlandContract.ID,
                NAME: inlandContract.CONCATENATED && inlandContract.CONCATENATED !== '' ? inlandContract.CONCATENATED : this.gridName + " - " + inlandContract.ID,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || inlandContract.ID !== this.$scope.model.ID) this.$scope.fetchData(inlandContract.ID, EOperation.VIEW);
                } else if (this.$scope.operation !== EOperation.EDIT || inlandContract.ID !== this.$scope.model.ID) {
                    this.$scope.fetchData(inlandContract.ID, EOperation.EDIT);
                }
            };
        }

        this.$scope.viewInlandContract = async (inlandContract: IInlandContract): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(inlandContract.ID, EOperation.VIEW);
        }

        this.$scope.viewLogInlandContract = async (inlandContract: IInlandContract): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(inlandContract);
        }

        this.$scope.copyInlandContract = async (inlandContract: IInlandContract): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(inlandContract);
            this.$scope.fetchData(inlandContract.ID, EOperation.COPY);
        }

        this.$scope.goToProvider = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.provider", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.$scope.goToAgent = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.agent", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.$scope.goToAccount = () => {
            this.$scope.sessionService.openTab("app.commercial.account", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.ACCOUNT, null, 'ID') });
        }
        this.$scope.addInlandRoute = (inlandContract: IInlandContract) => {
            this.$scope.sessionService.openTab('app.product.inlandRoutes', null, <ILinkOperationParameter>{ OPERATION: "register", ID: inlandContract.ID ? inlandContract.ID.toString() : null });
        }
        this.$scope.getGridViewProvider = (provider: IProviderSelector, legalPersonAgent: ISelectorModel): string => {
            let gridValue = "";
            if (provider) {
                if (provider.TYPE && (provider.TYPE.ID == EProviderTypeId.SHIPOWNER || provider.TYPE.ID == EProviderTypeId.AIRLINE)) gridValue = provider.CODE;
                else if (provider.TYPE && provider.TYPE.ID == EProviderTypeId.SHIPPING_COMPANY) gridValue = provider.NAME;
                else gridValue = provider.NAME;
            }

            if (legalPersonAgent) gridValue = legalPersonAgent.NAME;

            return gridValue;
        }
        this.$scope.getServiceProviderListByName = async (search: string, operatorType: SelectorModel): Promise<void> => {
            return await this.getServiceProviderListByName(search, operatorType);
        }
        this.$scope.getAgentListByName = async (search: string, operatorType: SelectorModel): Promise<void> => {
            return await this.getAgentListByName(search, operatorType);
        }
        this.$scope.getAccountListByName = async (search: string): Promise<void> => {
            return await this.getAccountListByName(search);
        }
        this.$scope.limitLines = (value: string, limit: number) => {
            return StringUtil.limitLines(value, limit);
        }
        this.$scope.formatConcatenatedChars = (value: string) => {
            return StringUtil.formatConcatenatedChars(value);
        }
        this.$scope.removeUpload = (model: IUploadItem): boolean => {
            return this.removeUpload(model);
        }
        this.$scope.uploader.onSuccessItem = (item: IFileItem, response: ISuccessItemResponse): void => {
            this.onSuccessItem(item, response);
        }
        this.$scope.collapseHeader = (elementId: string, state?: string, navigate?: boolean): void => {
            this.collapseHeader(elementId, state, navigate);
        }
        this.$scope.generateConcatenated = (): void => {
            this.generateConcatenated();
        }
        this.$scope.updateRequired = (): void => {
            this.updateRequired();
        }
        this.$scope.checkDateValidity = (initialDate: Date, finalDate: Date): void => {
            this.checkDateValidity(initialDate, finalDate);
        }
        this.$scope.updateCompanyType = (): void => {
            this.updateCompanyType();
        }
        this.$scope.updateProductRelations = (): void => {
            this.updateProductRelations();
        }
        this.$scope.setCopy = (value: boolean): void => {
            this.setCopy(value);
        }
        this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
            this.fetchData(id, action);
        }
    }

    initGridColumns(columns: string[]): IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<a ng-click="grid.appScope.viewInlandContract(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true"><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editInlandContract(row.entity)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true"><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.setCopy(true);grid.appScope.copyInlandContract(row.entity)" class="text-orange copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true"><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogInlandContract(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true"><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const addRoute = `<a ng-click="row.entity.ACTIVE && grid.appScope.addInlandRoute(row.entity)" ng-disabled="!row.entity.ACTIVE" class="text-green copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'ROUTE.ADD_ROUTE' | translate }}" tooltip-append-to-body="true"><i class="fa fa-plus icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes", displayName: "GENERAL.ACTIONS",
            width: 125,
            cellTemplate: `<div class="text-center view-btn-action-bar"> ${view} ${edit} ${copy} ${addRoute} ${viewLog}</div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };

        gridColumns.addColumn(colActions);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colTransaction: IMonacoColumnDef = { name: "TRANSACTION.NAME", displayName: "BASIC_DATA.TRANSACTION", width: 100 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 100 };
            const colWay: IMonacoColumnDef = { name: "WAY.NAME", displayName: "PRODUCT.WAY", width: 150 };
            const colCargoType: IMonacoColumnDef = { name: "CARGO_TYPE.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 120 };
            const colCompanyType: IMonacoColumnDef = { name: "COMPANY_TYPE.NAME", displayName: "ENTITY.COMPANY_TYPE", width: 120 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 80, cellFilter: "YesOrNo" };
            const colServiceProvider: IMonacoColumnDef = { name: "OPERATOR.NAME", displayName: "BASIC_DATA.SERVICE_PROVIDER", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getGridViewProvider(row.entity.SERVICE_PROVIDER, row.entity.LEGAL_PERSON_AGENT)}}</div>' };
            const colAccount: IMonacoColumnDef = { name: "ACCOUNT.NAME", displayName: "GENERAL.ACCOUNT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNT, null, "NAME")}}</div>' };
            const colSpot: IMonacoColumnDef = { name: "SPOT", displayName: "PRODUCT.SPOT", width: 80, cellFilter: "YesOrNo" };
            const colAllogContract: IMonacoColumnDef = { name: "ALLOG_INLAND_CONTRACT", displayName: "PRODUCT.ALLOG_INLAND_CONTRACT", width: 200 };
            const colProviderContract: IMonacoColumnDef = { name: "PROVIDER_INLAND_CONTRACT", displayName: "PRODUCT.PROVIDER_INLAND_CONTRACT", width: 200 };
            const colSituation: IMonacoColumnDef = { name: "SITUATION.NAME", displayName: "GENERAL.SITUATION", width: 120 };
            const colOrigin: IMonacoColumnDef = { name: "ORIGIN.DISPLAY_NAME", displayName: "BASIC_DATA.ORIGIN", width: 300, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ORIGIN, null, "DISPLAY_NAME")}}</div>' };
            const colDestination: IMonacoColumnDef = { name: "DESTINATION.DISPLAY_NAME", displayName: "BASIC_DATA.DESTINATION", width: 300, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.DESTINATION, null, "DISPLAY_NAME")}}</div>' };
            const colConcatenated: IMonacoColumnDef = { name: "CONCATENATED", displayName: "GENERAL.CONCATENATED", width: 300 };
            const colValidityStart: IMonacoColumnDef = { name: "VALIDITY_START", displayName: "PRODUCT.VALIDITY_START", width: 150, cellFilter: "simpleDate" };
            const colValidityEnd: IMonacoColumnDef = { name: "VALIDITY_END", displayName: "PRODUCT.VALIDITY_END", width: 150, cellFilter: "simpleDate" };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'TRANSACTION':
                        columnDefs.push(colTransaction);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'WAY':
                        columnDefs.push(colWay);
                        break;
                    case 'CARGO_TYPE':
                        columnDefs.push(colCargoType);
                        break;
                    case 'COMPANY_TYPE':
                        columnDefs.push(colCompanyType);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'SERVICE_PROVIDER':
                        columnDefs.push(colServiceProvider);
                        break;
                    case 'ACCOUNT':
                        columnDefs.push(colAccount);
                        break;
                    case 'SPOT':
                        columnDefs.push(colSpot);
                        break;
                    case 'ALLOG_INLAND_CONTRACT':
                        columnDefs.push(colAllogContract);
                        break;
                    case 'PROVIDER_INLAND_CONTRACT':
                        columnDefs.push(colProviderContract);
                        break;
                    case 'SITUATION':
                        columnDefs.push(colSituation);
                        break;
                    case 'ORIGIN':
                        columnDefs.push(colOrigin);
                        break;
                    case 'DESTINATION':
                        columnDefs.push(colDestination);
                        break;
                    case 'CONCATENATED':
                        columnDefs.push(colConcatenated);
                        break;
                    case 'VALIDITY_START':
                        columnDefs.push(colValidityStart);
                        break;
                    case 'VALIDITY_END':
                        columnDefs.push(colValidityEnd);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;

                };
            }

            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            TRANSACTION: null,
            PRODUCT: null,
            WAY: null,
            CARGO_TYPE: null,
            COMPANY_TYPE: null,
            SERVICE_PROVIDER: null,
            LEGAL_PERSON_AGENT: null,
            ACCOUNT: null,
            SPOT: false,
            ALLOG_INLAND_CONTRACT: null,
            PROVIDER_INLAND_CONTRACT: null,
            SITUATION: null,
            CONCATENATED_COMPLEMENT: null,
            CONCATENATED: null,
            VALIDITY_START: null,
            VALIDITY_END: null,
            OBSERVATION: null,
            FILES: null,
            ORIGIN: null,
            DESTINATION: null,
            VALIDITY_BASIS: null,
            ACTIVE: true,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            FILES_NEW: null,
            FILES_REMOVE: null

        };
        this.$scope.receivingTransaction = false;
    }

    async save(): Promise<boolean> {
        let proceed: boolean = true;
        try {
            if (this.$scope.uploader.queue && this.$scope.uploader.queue.length > 0) {
                const msgError = this.formService.getTranslate('GENERAL.MESSAGES.ERROR.FILES_IN_THE_QUEUE')
                proceed = false;
                this.handleError(msgError);
            }
            this.SSEService.closeEvents();
            this.setCopy(false);
        } catch (ex) {
            this.handleError(ex);
            proceed = false;
        }
        return proceed;
    }

    async register(): Promise<void> {
        try {
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.initPanels(["collapseBasicData"], "collapseBasicData");

            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
            this.initPanels(["collapseBasicData"], "collapseBasicData");
            BrowserTitle.$id = this.$scope.model.CONCATENATED;
            this.initDateFields();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
            }

            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.updateRequired();
            BrowserTitle.$id = this.$scope.model.CONCATENATED;
            this.initPanels(["collapseBasicData"], "collapseBasicData");
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.updateProductRelations();
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.initPanels(["collapseBasicData"], "collapseBasicData");
            await this.clearFields(this.$scope.model, ['CONCATENATED']);
            this.$scope.model.CONCATENATED = null;
            this.$scope.model.ID = null;
            this.$scope.model.ALLOG_INLAND_CONTRACT = null;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.updateProductRelations();
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        this.setCopy(false);
        return {
            route: `/inlandContract/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
        this.setCopy(false);
    }

    private filterProductList(productList: SelectorModel[]) {
        return productList.filter(product => product.ID != EProductId.ROAD_EXPORT && product.ID != EProductId.ROAD_IMPORT);
    }

    private setCopy(value: boolean): void {
        this.isCopy = value;
    }

    private async getServiceProviderListByName(search: string, operatorType: SelectorModel): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                if (!operatorType || !operatorType.ID) throw Error(this.formService.getTranslate("PRODUCT.SELECT_SPECIALIZATION_FIRST"));

                let types = [];
                if (operatorType.ID == '1') {
                    types = [EProviderTypeId.SHIPOWNER];
                } else if (operatorType.ID == '2') {
                    types = [EProviderTypeId.SHIPPING_COMPANY];
                } else if (operatorType.ID == '4') {
                    types = [EProviderTypeId.AIRLINE];
                } else {
                    throw Error(`Select a valid specialization`);
                }
                this.formService.block();

                const providers: IProviderModel[] = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/provider/list/custom`, { search, types }, 30000, false);
                result = providers ? providers.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.SCAC_IATA } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.serviceProviderList = result;
        }
    }

    private async getAgentListByName(search: string, operatorType: SelectorModel): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                if (!operatorType || !operatorType.ID) throw Error(this.formService.getTranslate("PRODUCT.SELECT_SPECIALIZATION_FIRST"));
                if (!this.$scope.model.PRODUCT || !this.$scope.model.PRODUCT.ID) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));

                this.formService.block();

                const products = [this.$scope.model.PRODUCT.ID];
                const agents = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/agent/list/custom`, { search, products }, 30000, false);
                result = agents ? agents.map(x => { return { ID: x.ID, NAME: x.NAME } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.agentList = result;
        }
    }

    private async getAccountListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const accounts = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/account/list/custom`, { search: search }, 30000, false);
                result = accounts ? accounts.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.LEGAL_PERSON ? x.LEGAL_PERSON.SHORT_NAME : null } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.accountList = result;
            this.formService.unblock();
        }
    }

    private initDateFields(): void {
        try {
            this.$scope.model.VALIDITY_START = (this.$scope.model.VALIDITY_START != null) ? new Date(this.$scope.model.VALIDITY_START) : null;
            this.$scope.model.VALIDITY_END = (this.$scope.model.VALIDITY_END != null) ? new Date(this.$scope.model.VALIDITY_END) : null;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'tariff_files', formName: this.formName, folder: this.formName };
            return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData] });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private removeUpload(model: IUploadItem): boolean {
        let result = false;
        try {
            if (!model) throw Error('item parameter is null');

            const isAdminOrCoordinator = (this.$scope.user.roles && (this.$scope.user.roles.hasOwnProperty('ADMIN') || this.$scope.user.roles.hasOwnProperty('COORDINATOR')));

            if (isAdminOrCoordinator) {
                result = true;
            } else {
                // check if in model.FILES_NEW;
                if (this.$scope.model.FILES_NEW && this.$scope.model.FILES_NEW.length > 0) {
                    const index = this.$scope.model.FILES_NEW.findIndex(file => { return file.FILE_HASH == model.FILE_HASH });
                    if (index > -1) {
                        // Remove from FILES_NEW
                        this.$scope.model.FILES_NEW.splice(index, 1);
                        result = true;
                    }
                }
            }

            if (result) {
                if (!this.$scope.model.FILES_REMOVE) this.$scope.model.FILES_REMOVE = [];
                this.$scope.model.FILES_REMOVE.push(model);
            } else {
                throw Error('You don\'t have permission to remove this file');
            }
        } catch (ex) {
            result = false;
            this.handleError(ex);
        } finally {
            return result;
        }
    }

    private onSuccessItem(item: IFileItem, response: ISuccessItemResponse): void {
        try {
            if (!item) throw Error('item parameter is null');
            if (!response) throw Error('response parameter is null');

            if (response.data && response.data.length > 0) {
                if (!this.$scope.model.FILES_NEW) this.$scope.model.FILES_NEW = [];

                for (let dataItem of response.data) {
                    const file: IUploadItem = {
                        FILE_DISPLAY_NAME: dataItem.displayFileName,
                        FILE_NAME: dataItem.fileName,
                        FILE_TYPE: dataItem.fileType,
                        FILE_PATH: dataItem.filePath,
                        FILE_URL: dataItem.fileUrl,
                        FILE_HASH: dataItem.fileHash,
                        FILE_SIZE: dataItem.fileSize,
                        FORM_NAME: dataItem.formName
                    }
                    this.$scope.model.FILES_NEW.push(file)
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private generateConcatenated(): void {
        try {
            const separator = " | ";
            let concatenated = "";

            if (this.$scope.model.ALLOG_INLAND_CONTRACT && this.$scope.model.ALLOG_INLAND_CONTRACT.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.ALLOG_INLAND_CONTRACT) : concatenated.concat(this.$scope.model.ALLOG_INLAND_CONTRACT);
            }

            if (this.$scope.model.PROVIDER_INLAND_CONTRACT && this.$scope.model.PROVIDER_INLAND_CONTRACT.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PROVIDER_INLAND_CONTRACT) : concatenated.concat(this.$scope.model.PROVIDER_INLAND_CONTRACT);
            }

            if (this.$scope.model.SERVICE_PROVIDER && this.$scope.model.SERVICE_PROVIDER.NAME && this.$scope.model.SERVICE_PROVIDER.NAME.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.SERVICE_PROVIDER.NAME) : concatenated.concat(this.$scope.model.SERVICE_PROVIDER.NAME);
            }

            if (this.$scope.model.ACCOUNT && this.$scope.model.ACCOUNT.length > 0) {
                concatenated = concatenated.length > 0
                    ? concatenated.concat(separator, this.$scope.model.ACCOUNT.map(obj => { return obj.NAME }).join(','))
                    : concatenated.concat(this.$scope.model.ACCOUNT.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.ID && this.$scope.model.PRODUCT.ID.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PRODUCT.ID) : concatenated.concat(this.$scope.model.PRODUCT.ID);
            }

            if (this.$scope.model.CARGO_TYPE && this.$scope.model.CARGO_TYPE.NAME) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CARGO_TYPE.NAME) : concatenated.concat(this.$scope.model.CARGO_TYPE.NAME);
            }

            if (this.$scope.model.WAY && this.$scope.model.WAY.NAME) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.WAY.NAME) : concatenated.concat(this.$scope.model.WAY.NAME);
            }

            if (this.$scope.model.VALIDITY_START) {
                const validity = this.$scope.model.VALIDITY_END ? moment(this.$scope.model.VALIDITY_START).format('DD/MM/YY') + '-' + moment(this.$scope.model.VALIDITY_END).format('DD/MM/YY') : moment(this.$scope.model.VALIDITY_START).format('DD/MM/YY');
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, validity) : concatenated.concat(validity);
            }

            if (this.$scope.model.CONCATENATED_COMPLEMENT && this.$scope.model.CONCATENATED_COMPLEMENT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CONCATENATED_COMPLEMENT) : concatenated.concat(this.$scope.model.CONCATENATED_COMPLEMENT);
            }

            this.$scope.model.CONCATENATED = StringUtil.formatConcatenatedChars(concatenated);

            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private checkDateValidity(initialDate: Date, finalDate: Date): void {
        let isValid = false;
        if (!initialDate || typeof initialDate == "string") return;
        if (!finalDate || typeof finalDate == "string") return;
        isValid = ValidateUtil.isValidDateRange(initialDate, finalDate);
        if (!isValid) {
            this.$scope.model.VALIDITY_END = null;
        }
    }

    private updateCompanyType(): void {
        try {
            this.$scope.model.SERVICE_PROVIDER = null;
            this.$scope.model.LEGAL_PERSON_AGENT = null;
            this.$timeout(() => { this.$scope.selectorValidity("serviceProvider"); })
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private updateProductRelations(): void {
        try {
            if (!this.$scope.model.PRODUCT) throw Error(`Select Product first`);

            const productType = this.$scope.model.PRODUCT.ID;
            if (productType == EProductId.MARITIME_EXPORT || productType == EProductId.MARITIME_IMPORT) {
                this.$scope.cargoTypeListFiltered = this.$scope.cargoTypeList.filter(cargoType => maritimeParams.CARGO_TYPE.some(cargo => cargo == cargoType.ID));
                this.$scope.companyTypeListFiltered = this.$scope.companyTypeList.filter(companyType => maritimeParams.COMPANY_TYPE.some(cargo => cargo == companyType.ID));
            }
            if (productType == EProductId.AIR_EXPORT || productType == EProductId.AIR_IMPORT) {
                this.$scope.cargoTypeListFiltered = this.$scope.cargoTypeList.filter(cargoType => airParams.CARGO_TYPE.some(cargo => cargo == cargoType.ID));
                this.$scope.companyTypeListFiltered = this.$scope.companyTypeList.filter(companyType => airParams.COMPANY_TYPE.some(cargo => cargo == companyType.ID));
            }
            if (this.$scope.cargoTypeListFiltered.length && this.$scope.model.CARGO_TYPE && !this.$scope.cargoTypeListFiltered.some(cargoType => cargoType.ID == this.$scope.model.CARGO_TYPE.ID)) this.$scope.model.CARGO_TYPE = null;
            if (this.$scope.companyTypeListFiltered.length && this.$scope.model.COMPANY_TYPE && !this.$scope.companyTypeListFiltered.some(companyType => companyType.ID == this.$scope.model.COMPANY_TYPE.ID)) this.$scope.model.COMPANY_TYPE = null;

            this.generateConcatenated();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private updateRequired(): void {
        try {
            const transaction = this.$scope.model.TRANSACTION;
            this.$scope.receivingTransaction = (transaction && transaction.ID == EPaymentNatureId.RECEIVING) ? true : false;

            this.$timeout(() => {
                this.$scope.selectorValidity("company_type");
                this.$scope.selectorValidity("serviceProvider");
                this.$scope.selectorValidity("agent");
            })
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: "CONCATENATED",
                LABEL: "GENERAL.CONCATENATED"
            },
            {
                PROPERTY: "TRANSACTION",
                LABEL: "BASIC_DATA.TRANSACTION"
            },
            {
                PROPERTY: "PRODUCT",
                LABEL: "BASIC_DATA.PRODUCT"
            },
            {
                PROPERTY: "WAY",
                LABEL: "PRODUCT.WAY"
            },
            {
                PROPERTY: "CARGO_TYPE",
                LABEL: "BASIC_DATA.CARGO_TYPE"
            },
            {
                PROPERTY: "COMPANY_TYPE",
                LABEL: "ENTITY.COMPANY_TYPE"
            },
            {
                PROPERTY: "SERVICE_PROVIDER",
                LABEL: "BASIC_DATA.SERVICE_PROVIDER"
            },
            {
                PROPERTY: "ACCOUNT",
                LABEL: "GENERAL.ACCOUNT"
            },
            {
                PROPERTY: "SPOT",
                LABEL: "PRODUCT.SPOT"
            },
            {
                PROPERTY: "ALLOG_INLAND_CONTRACT",
                LABEL: "PRODUCT.ALLOG_INLAND_CONTRACT"
            },
            {
                PROPERTY: "PROVIDER_INLAND_CONTRACT",
                LABEL: "PRODUCT.PROVIDER_INLAND_CONTRACT"
            },
            {
                PROPERTY: "SITUATION",
                LABEL: "GENERAL.SITUATION"
            },
            {
                PROPERTY: "CONCATENATED_COMPLEMENT",
                LABEL: "GENERAL.CONCATENATED_COMPLEMENT"
            },
            {
                PROPERTY: "VALIDITY_START",
                LABEL: "PRODUCT.VALIDITY_START"
            },
            {
                PROPERTY: "VALIDITY_END",
                LABEL: "PRODUCT.VALIDITY_END"
            },
            {
                PROPERTY: "OBSERVATION",
                LABEL: "GENERAL.REMARKS"
            },
            {
                PROPERTY: "FILES",
                LABEL: "REGISTRATION.EMAIL_ATTACHMENT"
            },
            {
                PROPERTY: "FILE_DISPLAY_NAME",
                LABEL: "REGISTRATION.FILE_ATTACHMENT_NAME"
            },
            {
                PROPERTY: "FILE_URL",
                LABEL: "REGISTRATION.FILE_URL"
            },
            {
                PROPERTY: "FILE_TYPE",
                LABEL: "REGISTRATION.FILE_TYPE"
            },
            {
                PROPERTY: "ORIGIN",
                LABEL: "BASIC_DATA.ORIGIN"
            },
            {
                PROPERTY: "DESTINATION",
                LABEL: "BASIC_DATA.DESTINATION"
            },
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE"
            },
            {
                PROPERTY: "CREATED_AT",
                LABEL: "GENERAL.CREATED_AT"
            },
            {
                PROPERTY: "CREATED_BY",
                LABEL: "GENERAL.CREATED_BY"
            },
            {
                PROPERTY: "UPDATED_AT",
                LABEL: "GENERAL.UPDATED_AT"
            },
            {
                PROPERTY: "UPDATED_BY",
                LABEL: "GENERAL.UPDATED_BY"
            },
        ];
        return props;
    }

    private getMenuFloatingDefault(): IFloatingMenu {
        return {
            btnActiveDisabled: false,
            options: [
                {
                    click: "collapseHeader",
                    args: ['collapseBasicData'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.BASIC_DATA",
                    iconClass: "fa fa-address-card",
                    iconBodyClass: "text-brown"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseUploads'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "REGISTRATION.EMAIL_ATTACHMENT",
                    iconClass: "fa fa-files-o",
                    iconBodyClass: "text-gray"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseObservation'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.REMARKS",
                    iconClass: "fa fa-arrows-alt",
                    iconBodyClass: "text-blue"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseAll'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.COLLAPSE_EXPAND_ALL",
                    iconClass: "fa fa-expand",
                    iconBodyClass: "text-danger"
                }
            ]
        };
    }

    private collapseHeader(elementId: string, state?: string, navigate?: boolean): void {
        if (elementId === "collapseAll") {
            this.collapseState = this.collapseState == "hide" ? "show" : "hide";
            $('.toggle-me')["collapse"](state ? state : this.collapseState);
        } else if (elementId != "registerBody") {
            $("#" + elementId)["collapse"](state ? state : 'toggle');
        }
        if (navigate && state != "hide") this.$scope.navigateBetweenIds(elementId);
    }

    private initPanels = (panelsToShow?: string[], panelToNavigate?: string) => {
        try {
            const panels = document.getElementsByClassName("toggle-me");
            if (panels) {
                for (let i = 0; i < panels.length; i++) {
                    const panel = panels[i];
                    if (panelsToShow && panelsToShow.findIndex(obj => obj === panel.id) >= 0) {
                        if (!panel.classList.contains('in')) {
                            $("#" + panel.id)["collapse"]("show");
                        }
                    } else if (panel.classList.contains('in')) {
                        $("#" + panel.id)["collapse"]("hide");
                    }
                }
            }
            if (panelToNavigate) this.$scope.navigateBetweenIds(panelToNavigate);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/inlandContract/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }
}
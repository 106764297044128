import { IColumnDefOf } from 'ui-grid';
import { IMonacoColumnDef } from '@services/GridService2';
import * as angular from 'angular';
import * as moment from 'moment';
import { HandleError } from './util/HandleError';

export abstract class GridColumnBuilderConstants {
    public static readonly BTN_VIEW = 'view';
    public static readonly BTN_VIEWLOG = 'viewLog';
    public static readonly BTN_EDIT = 'edit';
    public static readonly BTN_STATUS = 'toggleStatus';
    public static readonly BTN_COPY = 'copy';
}

export class GridColumnBuilder {
    private columnDefs: Array<IMonacoColumnDef> = [];
    public ACTION_VIEW = null;
    public ACTION_VIEWLOG = null;
    public ACTION_EDIT = null;
    public ACTION_STATUS = null;
    public ACTION_COPY = null;

    private buttonAction: string = null;
    private maxWidth: number = 0;

    constructor(private columns: Array<string>) {
        this.columnDefs = new Array();

        this.ACTION_VIEW = GridColumnBuilderConstants.BTN_VIEW;
        this.ACTION_VIEWLOG = GridColumnBuilderConstants.BTN_VIEWLOG;
        this.ACTION_EDIT = GridColumnBuilderConstants.BTN_EDIT;
        this.ACTION_STATUS = GridColumnBuilderConstants.BTN_STATUS;
        this.ACTION_COPY = GridColumnBuilderConstants.BTN_COPY;
    }

    public removeColumn(column: string): GridColumnBuilder {
        if (Array.isArray(this.columns)) {
            const index = this.columns.indexOf(column);
            if (index > -1) this.columns.splice(index, 1);
        }

        return this;
    }

    public includeActionBar(buttons: Array<string> | string = null, maxWidth: number = 0): GridColumnBuilder {
        if (buttons == null) buttons = this.getDefaultButtons();
        if (angular.isString(buttons)) buttons = [buttons];

        let cellTemplate = '<div class="text-center view-btn-action-bar">';

        const hasView: boolean = buttons.indexOf(GridColumnBuilderConstants.BTN_VIEW) > -1;
        if (hasView) {
            cellTemplate += `<a ng-click="grid.appScope.${this.ACTION_VIEW}(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`
            maxWidth += 20;
        }

        const hasEdit: boolean = buttons.indexOf(GridColumnBuilderConstants.BTN_EDIT) > -1;
        if (hasEdit) {
            cellTemplate += `<a ng-click="grid.appScope.${this.ACTION_EDIT}(row.entity)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
            maxWidth += 20;
        }

        const hasLog: boolean = buttons.indexOf(GridColumnBuilderConstants.BTN_VIEWLOG) > -1;
        if (hasLog) {
            cellTemplate += `<a ng-click="grid.appScope.${this.ACTION_VIEWLOG}(row.entity)" class="text-green log-btn-action-bar"   tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
            maxWidth += 20;
        }

        const hasCopy: boolean = buttons.indexOf(GridColumnBuilderConstants.BTN_COPY) > -1;
        if (hasCopy) {
            cellTemplate += `<a ng-click="grid.appScope.${this.ACTION_COPY}(row.entity)" class="text-orange copy-btn-action-bar"   tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp`;
            maxWidth += 20;
        }

        const hasStatus: boolean = buttons.indexOf(GridColumnBuilderConstants.BTN_STATUS) > -1;
        if (hasStatus) {
            cellTemplate += `<a ng-click="grid.appScope.${this.ACTION_STATUS}(row.entity)" class="status-btn-action-bar" ng-class="row.entity.STATUS ? 'text-green': 'text-danger';"   tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.ACTIVE_OR_INACTIVE' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-circle icon"></i></a>&nbsp;&nbsp`;
            maxWidth += 20;
        }

        if (this.buttonAction) {
            cellTemplate += this.buttonAction;
            maxWidth += this.maxWidth;
        }

        cellTemplate += `</div>`;

        this.columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: maxWidth,
            maxWidth: maxWidth,
            cellTemplate: cellTemplate,
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true,
            filterCellFiltered: true,
        });

        return this;
    }

    public addButtonAction(html) {
        if (!this.buttonAction) {
            this.buttonAction = html;
            this.maxWidth += 20;
        } else {
            this.buttonAction += html;
            this.maxWidth += 20;
        }
    }

    private getDefaultButtons() {
        return [GridColumnBuilderConstants.BTN_VIEW, GridColumnBuilderConstants.BTN_EDIT, GridColumnBuilderConstants.BTN_VIEWLOG, GridColumnBuilderConstants.BTN_COPY];
    }

    public addColumn(column: IColumnDefOf<any>) {
        if (angular.isUndefined(column.enableCellEdit)) column.enableCellEdit = false;

        const isSelectorModel: boolean = column.cellFilter == 'selectormodel' || column.cellFilter == 'mailIDToGrid';
        if (isSelectorModel) column.filter = { condition: this.filterSelectObject };

        const isDatetime: boolean = column.cellFilter == 'datetime';
        if (isDatetime) column.filter = { condition: this.filterDatetime };

        const isDatetimePicker: boolean = column.cellFilter == 'datetimepicker';
        if (isDatetimePicker) column.filter = { condition: this.filterDatetimePicker };

        this.columnDefs.push(column);

        return this;
    }

    private filterDatetime(searchTerm: string, cellValue: any): boolean {
        const value = moment(cellValue).format('DD[/]MM[/]YYYY HH:mm')
        return value.search(searchTerm) >= 0;
    }

    private filterSelectObject(searchTerm: string, cellValue: any): boolean {
        if (cellValue instanceof Array && cellValue.length > 0) {
            return cellValue.some(x => (`${x.ID}${x.NAME}${(x.CODE) ? x.CODE : ''}`).toLowerCase().includes(searchTerm.toLowerCase()));
        } else if (cellValue instanceof Object) {
            return (`${cellValue.ID}${cellValue.NAME}${(cellValue.CODE) ? cellValue.CODE : ''}`).toLowerCase().includes(searchTerm.toLowerCase());
        } else if (typeof (cellValue) === 'string') return cellValue.toLowerCase().includes(searchTerm.toLowerCase());
        else if (typeof (cellValue) === 'boolean') return (cellValue === true && searchTerm.startsWith('s')) || (cellValue === false && searchTerm.startsWith('n'))

        return false;
    }

    filterDatetimePicker(searchTerm: string, cellValue: any): boolean {
        try {

            if (!cellValue || !cellValue) return false;
            const value = moment(cellValue).format('DD[/]MM[/]YYYY HH:mm')
            return value.search(searchTerm) >= 0;

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    public get $columnDefs(): IMonacoColumnDef[] {
        this.columns.forEach((column) => {

            const hasColumns: boolean = this.columnDefs.filter((def: IMonacoColumnDef) => column === def.name).length > 0;
            if (!hasColumns) {
                let col: IColumnDefOf<any> = {};
                col.name = column;
                col.displayName = column;
                col.visible = false;
                col.width = 200;

                this.columnDefs.push(col);
            }
        });

        return this.columnDefs;
    }

    public configureAction() { }
}
import * as config from '../bootstrap/Config';
import * as Address from '../communication/Address';
export interface IRabbitMQServiceResult {
    routed: boolean;
}

export class RabbitMQService {
    public static $inject: string[] = ['$injector'];
    private defaultTimeout: number;
    private route: string;
    private secret: string;

    constructor() {
        this.defaultTimeout = 300000; // 5 Min

        if (config.default.environment === 'prod') {
            this.route = Address.monacoAddressProd.RABBITMQ;
            this.secret = "bmV3bW9uYWNvOjJnc2ZNSXNjREpaelJiZXQ0c01r";
        } else if (config.default.environment === 'qa') {
            this.route = Address.monacoAddressQa.RABBITMQ;
            this.secret = "bmV3bW9uYWNvOjJ1SXg0c2F3WFlsNElEd2I3Tmh5";
        } else {
            this.route = Address.monacoAddressLocal.RABBITMQ;
            this.secret = "YWxsb2c6YWxsb2c=";
        }
    }

    public get $route(): string {
        return this.route;
    }

    public async send<T = any>(queue: string, request: object, timeout?: number): Promise<IRabbitMQServiceResult> {
        try {
            if (!request || !queue) return null;

            const body = {
                properties: {},
                routing_key: queue,
                payload: JSON.stringify(request),
                payload_encoding: "string",
            };

            const options = {
                method: 'POST',
                body: JSON.stringify(body),
                timeout: (timeout) ? timeout : this.defaultTimeout,
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": "Basic " + this.secret,
                    "pragma": "no-cache",
                    "cache-control": "no-cache",
                },
            };

            const result = await fetch(`${this.route}/api/exchanges/%2f/amq.default/publish`, options);
            return await result.json();
        } catch (ex) {
            throw ex;
        }
    }
}

import { IHttpResponse } from "angular";
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { IMonacoRequest } from '@services/GridFormService';
import { OperationalService } from "@services/OperationalService";
import { ISelectorModel } from "WBA-Model/dist/mongo/SelectorModel";
import { Process } from "WBA-Model/dist/interface/operational/NewProcess";
import { EOperation } from "@enums/GenericData";
import { DataProcessService } from "@services/DataProcessService";
import { IModalService, IModalOptions } from "@services/ModalService";
import { IConsolidatedWizardModalScope } from "./ConsolidatedWizardModalController";
import { IModalInstanceService } from "angular-ui-bootstrap";

interface IConsolidatedProcessRegisterScope extends IFormServiceScope {
    consolidatedScope: IConsolidatedWizardModalScope
    register: {
        processList: ISelectorModel[];
        selectedProcess: ISelectorModel;
    };
    modalOptions: IModalOptions;
    consolidatedProcessListModalId: number;

    getProcessByProcessNumber: (processNumber: string) => Promise<void>;
    generateProcess: () => Promise<void>;
}

export class ConsolidatedProcessRegisterController extends FormService2 {
    public static $inject: string[] = ['$injector', '$scope'];
    private scope: IConsolidatedProcessRegisterScope;
    private OperationalService: OperationalService;
    private DataProcessService: DataProcessService;
    private ModalService: IModalService;
    private $consolidatedScope: IConsolidatedWizardModalScope;

    constructor($injector: ng.Injectable<any>, $scope: IConsolidatedProcessRegisterScope) {
        super($injector, $scope);

        this.scope = $scope;
        this.OperationalService = $injector.get('OperationalService');
        this.DataProcessService = $injector.get('DataProcessService');
        this.ModalService = $injector.get('ModalService');
        this.$consolidatedScope = <IConsolidatedWizardModalScope>$scope.$parent.$parent;
    }

    async $onInit(): Promise<void> {
        try {
            this.block();
            this.initializate();
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async initializate() {
        this.init("ConsolidatedProcessRegister", null, null);
        this.loadRegisterForm(false);
        this.initModel();
        this.initScopeFunctions();
    }

    initModel(): void {
        try {
            this.scope.register = {
                processList: null,
                selectedProcess: null
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private initScopeFunctions(): void {

        this.scope.getProcessByProcessNumber = async (processNumber: string): Promise<void> =>
            this.getProcessByProcessNumber(processNumber);

        this.scope.generateProcess = async (): Promise<void> => this.generateProcess();

    }

    private async generateProcess(): Promise<void> {
        if (this.scope.register.selectedProcess) this.repurchaseProcess();
    }

    private async repurchaseProcess(): Promise<void> {
        try {
            const timeout = 120000;

            await this.DataProcessService.post('/repurchase/validate', { processNumber: this.scope.register.selectedProcess.NAME }, 30000);

            const response = await this.DataProcessService.get(`/consolidated/validateDuplicated/${this.$consolidatedScope.model.ID}/${this.scope.register.selectedProcess.NAME}`, null);
            if (response && response.data && response.data.data) {
                const consolidatedValidated: ISelectorModel = response.data.data;

                if (consolidatedValidated) throw Error(this.getTranslate('OPERATIONAL.FILE_IS_ALREADY_IN_A_CONSOL', { processNumber: consolidatedValidated.CODE, consol: consolidatedValidated.NAME }));
            }

            const result = await this.OperationalService.get(`/process/byNumber/${this.scope.register.selectedProcess.NAME}/${timeout}`, timeout);
            const processInfo: Process = result.data.data ? result.data.data[0] : null;

            if (processInfo.EXTERNAL_AGENT && processInfo.EXTERNAL_AGENT.NETWORK && this.$consolidatedScope.model.EXTERNAL_AGENT && this.$consolidatedScope.model.EXTERNAL_AGENT.NETWORK_ID && parseInt(processInfo.EXTERNAL_AGENT.NETWORK.ID) != this.$consolidatedScope.model.EXTERNAL_AGENT.NETWORK_ID) {
                throw Error(this.getTranslate('OPERATIONAL.DIVERGENT_FOREIGN_AGENT'));
            }

            const modalID = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    template: require("../view/wizardRepurchase.html"),
                    modalID: modalID,
                    formService: EOperation.NEW,
                    size: 'vlg modal-overflow',
                    scope: this.$consolidatedScope
                },
                {
                    actionButtonText: "GENERAL.CLOSE",
                    headerText: 'GENERAL.REPURCHASE',
                }, {
                newProcessScope: {
                    model: processInfo,
                    steps: { current: 1, percent: 20, lastStep: 2 }
                },
                consolidatedId: this.$consolidatedScope.model.ID,
                isConsolidated: true,
                shipmentDate: this.$consolidatedScope.model.ETD_DATE
            });

            const modalScope = await this.ModalService.getModalScope(modalID);
            await modalScope.$applyAsync();

            const apply = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (apply) {
                this.ModalService.closeModal(this.scope.consolidatedProcessListModalId, true);
            }

        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getProcessByProcessNumber(search: string): Promise<void> {
        try {
            if (search && search.length >= 5) {
                this.block();

                const timeout = 120000;

                const request: IMonacoRequest = {
                    data: {
                        term: search,
                        timeout,
                    },
                    route: "/process/selector",
                    timeout,
                };

                const rc: IHttpResponse<IHttpResponse<ISelectorModel[]>> = await this.OperationalService.post(request.route, request.data, request.timeout);
                this.scope.register.processList = (rc && rc.data && rc.data.data) ? rc.data.data : [];

            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }
}

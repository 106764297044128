
ErrorController.$inject = ['$scope', '$state'];
export function ErrorController($scope, $state: ng.ui.IStateService) {

  this.$onInit = function () {
    $scope.message = ($state.params.message) ? $state.params.message : 'Erro ao carregar dados!';
    $scope.error = ($state.params.error) ? $state.params.error : null;
    $scope.origin = ($state.params.origin) ? $state.params.origin : null;
  }

}  
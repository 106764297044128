import * as angular from 'angular';
import { IRestService } from './RestService';
import { IMonacoScope } from '../common/MonacoInterface';
import { IModalService } from './ModalService';
import { HandleError } from '../common/util/HandleError';


export class GridService {
    public uiGridConstants: any;
    public gridScope: any;
    public gridApi: any;
    public defaultColumns: any;
    public settingsName: any;
    public gridCustomMenu: any;
    public profileList: Array<any>;
    public selectedProfile: string;


    public static $inject: string[] = ['$rootScope', '$timeout', 'RestService', 'ModalService', 'blockUI'];
    constructor(private $rootScope: IMonacoScope, private $timeout: ng.ITimeoutService, private RestService: IRestService, private ModalService: IModalService, private blockUI: ng.blockUI.BlockUIService) {
        this.uiGridConstants = undefined;
        this.gridScope = undefined;
        this.gridApi = undefined;
        this.defaultColumns = undefined;
        this.settingsName = undefined;
        this.profileList = undefined;
    }

    init(uiGridConstants, gridScope, gridApi, settingsName) {
        this.uiGridConstants = uiGridConstants;
        this.gridScope = gridScope;
        this.gridApi = gridApi;
        this.settingsName = settingsName;

        //register some scope functions
        this.gridScope.getGridHeight = () => {
            return this.getHeight();
        };

        this.gridScope.gridAddProfileModal = async () => {
            await this.addProfileModal();
        };

        this.gridScope.gridAddProfile = async (name, modalContext) => {
            await this.addNewProfile(name, modalContext);
        };

        this.gridScope.gridRemoveProfile = async (name) => {
            await this.removeProfile(name);
        };

        this.gridScope.gridSetProfileDefault = async (name) => {
            await this.gridSetProfileDefault(name);
        }

        this.gridScope.gridSaveProfile = async (name, data) => {
            await this.saveProfileSetting(name, data);
        };

        this.gridScope.gridLoadProfile = async (selectedProfile) => {
            await this.loadProfileData(selectedProfile);
        };


        this.registerGridOptions();
        this.registerColumnSizeEvent();
        this.registerColumnPositionEvent();
        this.registerColumnVisibilityEvent();
        //this.registerColumnPinnedEvent();
        this.notifyDataChange();
    }

    registerGridOptions() {
        this.gridApi.grid.options.footerTemplate = "ui-grid/ui-grid-footer";
        this.gridApi.grid.options.gridFooterTemplate = "ui-grid/ui-grid-grid-footer";
        this.gridApi.grid.options.gridMenuTemplate = "ui-grid/uiGridMenu";
        this.gridApi.grid.options.paginationTemplate = "ui-grid/pagination";
        this.gridApi.grid.options.rowTemplate = "ui-grid/ui-grid-row";
        this.gridApi.grid.options.gridMenuCustomItems = [
            {
                title: "Configuração Padrão",
                action: () => { this.setDefaultColumns() },
                order: 1
            },
            {
                title: "Gerenciar Perfil",
                actions: () => { alert('lalalal') },
                order: 2

            }
        ];

        this.gridApi.grid.options.enableGridMenu = true;
        this.gridApi.grid.options.exporterSuppressMenu = false;
        this.gridApi.grid.options.exporterMenuCsv = true;
        
        this.gridApi.grid.options.exporterMenuPdf = false;
        this.gridApi.grid.options.exporterMenuAllData = true;
        this.gridApi.grid.options.exporterMenuVisibleData = true;
        this.gridApi.grid.options.exporterMenuExcel = false;
        this.gridApi.grid.options.exporterSuppressColumns = ['acoes'];
        this.gridApi.grid.options.exporterHeaderFilterUseName = false;
        this.gridApi.grid.options.exporterOlderExcelCompatibility = false;
        this.gridApi.grid.options.maxVisibleColumnCount = 200;
        this.gridApi.grid.options.enableFiltering = true;
        this.gridApi.grid.options.treeRowHeaderAlwaysVisible = false;
        this.gridApi.grid.options.moveGroupColumns = false;
        this.gridApi.grid.options.enableExpandAll = true;
        this.gridApi.grid.options.enableColumnResizing = true;
        this.gridApi.grid.options.enablePagination = true;
        this.gridApi.grid.options.paginationPageSizes = [30, 60, 120];
        this.gridApi.grid.options.paginationPageSize = 30;
        this.gridApi.grid.options.paginationCurrentPage = 1;
        this.gridApi.grid.options.virtualizationThreshold = 20;
        this.gridApi.grid.options.headerHeight = 30;
        this.gridApi.grid.options.rowHeight = 30;
        this.gridApi.grid.options.gridFooterHeight = 30;

        this.gridApi.grid.options.enableVerticalScrollbar = this.uiGridConstants.scrollbars.ALWAYS;
        this.gridApi.grid.options.enableRowSelection = false;
        this.gridApi.grid.options.enableRowHeaderSelection = false;
        this.gridApi.grid.options.enableSelectAll = false;
        this.gridApi.grid.options.multiSelect = false;
        this.gridApi.grid.options.noUnselect = false;
        this.gridApi.grid.options.showGridFooter = false;
        this.gridApi.grid.options.showColumnFooter = false;
        this.gridApi.grid.options.showHeader = true;
        this.gridApi.grid.options.treeRowHeaderAlwaysVisible = false;
    }

    registerCustomMenuItems() {
        this.gridApi.grid.options.gridMenuCustomItems = this.gridCustomMenu;
    }

    registerApi(gridApi) {
        const self = this;
        let result = new Promise((resolve, reject) => {
            try {
                resolve(gridApi);
            } catch (ex) {
                //console.log(ex);
                reject(ex);
            }
        });
        return result;
    }

    registerColumnSizeEvent() {
        const self = this;
        let newColumnDefs = undefined;
        this.gridApi.colResizable.on.columnSizeChanged(this.gridScope, (colDef, deltaChange) => {
            //$scope.columnVisibilityChanged = { name: changedColumn.colDef.name, visible: changedColumn.colDef.visible };
            newColumnDefs = self.gridApi.grid.options.columnDefs;
            for (let i = 0; i < newColumnDefs.length; i++) {
                if (newColumnDefs[i].name === colDef.name) {
                    //update just the width property
                    let currentValue = undefined;
                    if (isNaN(newColumnDefs[i].width)) currentValue = parseFloat(newColumnDefs[i].width) * 13;
                    else currentValue = newColumnDefs[i].width;
                    currentValue += deltaChange;
                    newColumnDefs[i].width = currentValue;
                    break;
                }
            }
        });
        if (newColumnDefs) this.gridApi.grid.options.columnDefs = newColumnDefs;
    }

    registerColumnPositionEvent() {
        const self = this;
        let newColumnDefs = undefined;
        this.gridApi.colMovable.on.columnPositionChanged(this.gridScope, (colDef, originalPosition, newPosition) => {
            //$scope.columnPositionChanged = { name: colDef.name, originalPosition: originalPosition, newPosition: newPosition };
            newColumnDefs = self.gridApi.grid.options.columnDefs;
            self.swapArrayElements(newColumnDefs, originalPosition, newPosition);
        });
        if (newColumnDefs) this.gridApi.grid.options.columnDefs = newColumnDefs;
    }

    registerColumnVisibilityEvent() {
        const self = this;
        let newColumnDefs = undefined;
        this.gridApi.core.on.columnVisibilityChanged(this.gridScope, (changedColumn) => {
            //$scope.columnVisibilityChanged = { name: changedColumn.colDef.name, visible: changedColumn.colDef.visible };
            newColumnDefs = self.gridApi.grid.options.columnDefs;
            for (let i = 0; i < newColumnDefs.length; i++) {
                if (newColumnDefs[i].name === changedColumn.colDef.name) {
                    //update just the visible property
                    newColumnDefs[i].visible = changedColumn.colDef.visible;
                    break;
                }
            }
        });
        if (newColumnDefs) this.gridApi.grid.options.columnDefs = newColumnDefs;
    }

    registerColumnPinnedEvent() {
        const self = this;
        let newColumnDefs = undefined;
        this.gridApi.pinning.on.columnPinned(this.gridScope, (colDef, container) => {
            newColumnDefs = self.gridApi.grid.options.columnDefs;
            for (let i = 0; i < newColumnDefs.length; i++) {
                if (newColumnDefs[i].name === colDef.name) {
                    //update the enablePinning and direction properties
                    newColumnDefs[i].enablePinning = colDef.enablePinning;
                    if (!container) {
                        newColumnDefs[i].pinnedLeft = false;
                        newColumnDefs[i].pinnedRight = false;
                    } else {
                        if (container === 'left') {
                            newColumnDefs[i].pinnedLeft = true;
                            newColumnDefs[i].pinnedRight = false;
                        }
                        else {
                            newColumnDefs[i].pinnedRight = true;
                            newColumnDefs[i].pinnedLeft = false;
                        }
                    }
                    //TODO: SHOULD WE BREAK?
                    break;
                }
            }
        });
        if (newColumnDefs) this.gridApi.grid.options.columnDefs = newColumnDefs;
    }


    swapArrayElements(array, indexA, indexB) {
        let temp = array[indexA];
        array[indexA] = array[indexB];
        array[indexB] = temp;
    };

    formateDate(dateString) {
        if (!dateString) return;
        const date = dateString.split('-');
        const day = date[2].substring(0, 2);
        const month = date[1];
        const year = date[0];
        return day + '/' + month + '/' + year;
    }

    async setData(defaultColumns, data): Promise<void> {
        try {
            this.blockUI.start();

            this.defaultColumns = defaultColumns;
            await this.loadProfileList();
            this.gridApi.grid.options.data = data;
            await this.refresh();

            this.blockUI.stop();

            /*         this.loadProfileList().then(() => {
                        //set grid data
                        this.gridApi.grid.options.data = data;
                        this.refresh();
            
                        this.blockUI.stop();
            
                    }).catch(ex => {
                        console.log('Failed to get grid setting: ', ex);
                        //set default columns
                        this.gridApi.grid.options.columnDefs = angular.copy(this.defaultColumns);
                        //set data
                        this.gridApi.grid.options.data = data;
                        this.refresh();
            
                        this.blockUI.stop();
                    }); */

        } catch (ex) {
            this.$timeout(() => {
                this.blockUI.stop();
            });
            HandleError.exception(ex);
        }
    }

    async updateData(newData): Promise<void> {
        this.gridApi.grid.options.data = newData;
        await this.refresh();
    }

    async setDefaultColumns(): Promise<void> {
        this.gridApi.grid.options.columnDefs = angular.copy(this.defaultColumns);
        await this.refresh();
    }

    async refresh(): Promise<void> {
        await this.gridApi.core.refresh(true);
    }


    notifyDataChange() {
        this.gridApi.core.notifyDataChange(this.uiGridConstants.dataChange.ALL);
    }

    highlightFilteredHeader(row, rowRenderIndex, col, colRenderIndex) {
        if (col.filters[0].term) {
            return 'header-filtered';
        } else {
            return '';
        }
    }

    formateDateToExport(dateString) {
        if (!dateString) return;
        const date = dateString.split('-');
        const day = date[2].substring(0, 2);
        const month = date[1];
        const year = date[0];
        return day + '/' + month + '/' + year;
    }

    getHeight() {
        if (!this.gridApi || !this.gridApi.grid.options.data || this.gridApi.grid.options.data.length === 0) return;

        const w = angular.element(window);
        let actualHeight = w.height() - 170; //size of breadcrumb menu plus grid header
        const rowsCount = (this.gridApi.grid.options.data && this.gridApi.grid.options.data.length) ? this.gridApi.grid.options.data.length : 0;
        const rowsHeight = (rowsCount * 30);

        //30 is the number of rows per page
        if ((rowsCount > 0 && rowsCount < 30) && actualHeight > rowsHeight) actualHeight = (rowsCount * 30);
        if (actualHeight < 300) actualHeight = 300;

        return {
            height: actualHeight + "px",
            value: actualHeight
        };
    }

    getColumnsDefinitions() {
        return this.gridApi.grid.options.columnDefs;
    }

    getSettingName() {
        return this.settingsName;
    }

    getProfileList() {
        return this.profileList;
    }

    booleanDecode(boolValue) {
        if (boolValue) {
            return 'Sim';
        } else {
            return 'Não';
        }
    }


    async addProfileModal(): Promise<void> {
        const modal = await this.ModalService.showModalInfo(
            {
                template: require("../app/view/template/grid-profile-modal.html"),
                scope: this.gridScope, //passed current scope to the modal
            },
            {
                actionButtonText: 'Fechar',
                headerText: 'Adicionar Perfil',
            });
    }


    async loadProfileList(): Promise<void> {
        try {
            const profileList = await this.$rootScope.getSetting(this.settingsName);
            if (profileList) {
                //set profile list
                this.profileList = new Array<any>();
                const profileKeys = Object.keys(profileList);
                for (let i = 0; i < profileKeys.length; i++) {
                    this.profileList.push({ ID: (i + 1).toString(), NAME: profileKeys[i] });
                }
                //select the last profile on the list
                const lastProfile = (this.profileList.length > 0) ? this.profileList[this.profileList.length - 1] : null;
                if (lastProfile && profileList[lastProfile.NAME]) {
                    let columns = profileList[lastProfile.NAME];

                    //use the original actions columns for update issues
                    const defaultActionColumn = this.defaultColumns.find(x => x.name === 'acoes');
                    const oldActionColumn = columns.find(x => x.name === 'acoes');
                    if (oldActionColumn) columns[columns.indexOf(oldActionColumn)] = defaultActionColumn;

                    //set the grid columns
                    this.gridApi.grid.options.columnDefs = columns;
                    await this.refresh();

                    //update the grid selector scope
                    await this.$timeout(() => {
                        this.gridScope.gridProfileList = angular.copy(this.profileList);
                        this.gridScope.gridSelectedProfile = angular.copy(lastProfile);
                        this.gridScope.$apply();
                    });

                } else { //no profile available
                    this.setDefaultColumns();
                    //update the grid selector scope
                    await this.$timeout(() => {
                        //console.log('TOCSON: ', this.gridScope.gridProfileList);
                        //console.log('TOCSON2: ', this.gridScope.gridSelectedProfile);
                        this.gridScope.gridProfileList = [];
                        this.gridScope.gridSelectedProfile = null;
                        this.gridScope.$apply();
                    });
                }
            } else this.setDefaultColumns();

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    async loadProfileData(profile: object): Promise<void> {
        try {
            this.blockUI.start();

            const profileName = profile['NAME'];
            const loadedProfile = await this.$rootScope.getSetting(this.settingsName, profileName);
            if (loadedProfile && loadedProfile[profileName]) {
                const profileData = loadedProfile[profileName];

                //use the original actions columns for update issues
                const actionColumn = profileData.filter(x => x.name === 'acoes');
                if (actionColumn && actionColumn.length > 0) profileData[profileData.indexOf(actionColumn[0])] = angular.copy(this.defaultColumns[0]);

                //set grid columns
                this.gridApi.grid.options.columnDefs = profileData;
                await this.refresh();
            }
            this.blockUI.stop();

        } catch (ex) {
            this.$timeout(() => {
                this.blockUI.stop();
            });
            HandleError.exception(ex);
        }
    }

    async addNewProfile(profileName: string, modalContext): Promise<void> {
        try {
            if (!profileName) return;

            this.blockUI.start();

            //save the new profile on the db
            const profileData = angular.copy(this.defaultColumns);
            await this.saveProfileSetting(profileName, profileData);
            await this.loadProfileList();

            //close the modal
            modalContext.ok();

            this.blockUI.stop();

        } catch (ex) {
            this.$timeout(() => {
                this.blockUI.stop();
            });
            HandleError.exception(ex);
        }
    }

    async removeProfile(profileName: string): Promise<void> {
        try {
            if (!profileName) return;

            this.blockUI.start();

            await this.$rootScope.removeSetting(this.settingsName, profileName);
            await this.loadProfileList();

            this.blockUI.stop();

        } catch (ex) {
            this.$timeout(() => {
                this.blockUI.stop();
            });
            HandleError.exception(ex);
        }
    }

    async gridSetProfileDefault(profileName: string) {
        if (!profileName) return;

        console.log("this.settingsName: ", this.settingsName);
        await this.$rootScope.updateSetting(this.settingsName, null, profileName);
    }

    async saveProfileSetting(profileName: string, profileData: any): Promise<void> {
        try {
            if (!profileName || !profileData) return;

            //const data = angular.copy(this.gridApi.grid.options.columnDefs);
            await this.$rootScope.updateSetting(this.settingsName, profileData, profileName);

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    findDataById(id: string): any {
        return Array.from(this.gridScope.gridOptions.data).find(x => x['_id'] === id);
    }

    //filter select object and array of objects containing ID, NAME and CODE and boolean values with Sim and Não
    filterSelectObject(searchTerm: string, cellValue: any): boolean {
        if (cellValue instanceof Array && cellValue.length > 0) {
            return cellValue.some(x => (`${x.ID}${x.NAME}${(x.CODE) ? x.CODE : ''}`).toLowerCase().includes(searchTerm.toLowerCase()));
        } else if (cellValue instanceof Object) {
            return (`${cellValue.ID}${cellValue.NAME}${(cellValue.CODE) ? cellValue.CODE : ''}`).toLowerCase().includes(searchTerm.toLowerCase());
        } else if (typeof (cellValue) === 'string') return cellValue.toLowerCase().includes(searchTerm.toLowerCase());
        else if (typeof (cellValue) === 'boolean') return (cellValue === true && searchTerm.startsWith('s')) || (cellValue === false && searchTerm.startsWith('n'))

        return false;
    }

}


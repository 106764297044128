import * as angular from "angular";
import { BrowserTitle } from "../../common/BrowserTitle";
import { IMonacoColumnDef } from "@services/GridService2";
import { ICustomLogProperties, IViewLog } from "@models/interface/common/IViewLog";
import { SelectorModel } from "../../common/model/SelectorModel";
import { IRestService } from "@services/RestService";
import { GridFormService, IGridFormController, IGridFormServiceScope, IMonacoRequest } from "@services/GridFormService";
import { TaskService } from "@services/TaskService";
import { TaskHotspot } from "@models/interface/task/TaskHotspot";
import { TARGET_PARAM_TYPE } from "@enums/TaskHotspot";
import { HelperService } from "@services/HelperService";

interface ITaskHospotScope extends IGridFormServiceScope {
    model: TaskHotspot;
    scopeBeforeSave: TaskHotspot;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];

    meta: {
        targetGivenName: string;
        targetParameterType: SelectorModel;
        targetGenericTypeSelector: SelectorModel;
    }

    targetTypeList: SelectorModel[];
    targetClassList: SelectorModel[];
    targetParameterTypeList: SelectorModel[];
    targetList: SelectorModel[];
    targetParamList: SelectorModel[];
    //
    processDocumentTypeList: SelectorModel[];
    natureList: SelectorModel[];
    eventList: SelectorModel[];
    genericTypeList: SelectorModel[];
    taskOptionsList: SelectorModel[];

    //data / refreshes
    changeTargetType: () => Promise<void>;
    changeTarget: () => Promise<void>;
    refreshTargetParameterType: () => Promise<void>;
    refreshTargetGenericReference: (event: JQuery.Event) => Promise<void>;
}

export class TaskHotspotRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: ITaskHospotScope;
    private $timeout: ng.ITimeoutService
    private $q: ng.IQService;
    private RestService: IRestService;
    private TaskService: TaskService;
    //
    private invoiceCustomLogProperties: ICustomLogProperties[];
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: ITaskHospotScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$timeout = $injector.get('$timeout');
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.TaskService = $injector.get('TaskService');
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.TaskService.$route;
            this.initForm(this, 'form', 'taskHotspot', 'GENERAL.MENU.HOT_SPOT_REGISTRATION', true);
            await this.initGrid('gridTaskHotspot', '/hotspot/list', true, true);

            this.invoiceCustomLogProperties = this.getCustomLogProperties();

            this.$scope.targetParameterTypeList = [
                { ID: '1', NAME: 'Data' },
                { ID: '2', NAME: 'Sim/Não' },
                { ID: '3', NAME: 'Cad. Genérico' },
            ]

        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initGridColumns(columns) {
        const columnDefs: IMonacoColumnDef[] = [];

        const view = `<a ng-click="grid.appScope.view(row.entity)" class="text-info"tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.edit(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copy(row.entity)" class="text-orange"   tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`
        const history = `<a ng-click="grid.appScope.viewLog(row.entity)" class="text-green" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>`;

        const actions =
            `<div class="text-center">
            ${view}
            ${edit}
            ${copy}
            ${history}
        </div>`;

        const actionsColDef: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (actions),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            allowCellFocus: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true,
            visible: true,
        }
        columnDefs.push(actionsColDef);

        const NewColumnDefs = this.buildColunms(columns);
        for (let column of NewColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; columnDefs.push(column) }
        return columnDefs;
    }

    buildColunms(column, prop?): any {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            for (var property in column) {
                if (typeof (column[property]) === 'string') { //finally reached the bottom
                    let currentProperty = (prop) ? `${prop}.${column[property]}` : column[property];
                    let name = null;
                    let displayName = null;
                    let width = 0;
                    let cellTemplate = undefined;
                    let cellFilter = null;
                    let visible = true;
                    let field = null;
                    let filter: uiGrid.IFilterOptions = null;
                    let searchProps = null;

                    switch (currentProperty.toUpperCase()) {
                        case 'HOTSPOT_NUMBER':
                            name = currentProperty;
                            displayName = 'GENERAL.NUMBER';
                            width = currentProperty.length;
                            break;
                        case 'ACTIVE':
                            name = currentProperty;
                            displayName = 'GENERAL.ACTIVE';
                            width = currentProperty.length + 3;
                            cellFilter = "YesOrNo";
                            break;
                        case 'TARGET_TYPE':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.TARGET_TYPE';
                            width = currentProperty.length + 3;
                            break;
                        case 'TARGET_CLASS':
                            searchProps = [`${currentProperty}.ID`, `${currentProperty}.NAME`];
                            name = currentProperty;
                            displayName = 'REGISTRATION.TARGET_CLASS';
                            cellTemplate = '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.TARGET_CLASS)}}</div>';
                            width = currentProperty.length + 12;
                            break;
                        case 'TARGET':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.TARGET';
                            width = currentProperty.length + 20;
                            break;
                        case 'TARGET_PARAM':
                            searchProps = [`${currentProperty}.ID`, `${currentProperty}.NAME`];
                            name = currentProperty;
                            displayName = 'GENERAL.TARGET_SETTINGS';
                            cellTemplate = '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.TARGET_PARAM)}}</div>';
                            width = currentProperty.length + 12;
                            break;
                        case 'TARGET_GENERIC_REFERENCE':
                            name = currentProperty;
                            displayName = 'GENERAL.GENERIC_REF';
                            width = currentProperty.length + 3;
                            break;
                    };

                    const newColumn: IMonacoColumnDef = {
                        name: name,
                        displayName: displayName,
                        headerCellClass: this.$gridService.highlightFilteredHeader.bind(this.$gridService),
                        width: width + '%',
                        cellTemplate: cellTemplate,
                        cellFilter: cellFilter,
                        visible: visible,
                        filter: filter,
                        searchProps: searchProps,
                    };
                    if (field) newColumn.field = field;
                    if (name) columnDefs.push(newColumn);
                } else {
                    const composedPropPath = (prop) ? (isNaN(parseInt(property))) ? `${prop}.${property}` : prop : (isNaN(parseInt(property))) ? property : null;
                    const newCol = this.buildColunms(column[property], composedPropPath);
                    if (newCol)
                        for (let dados of newCol) {
                            columnDefs.push(dados);
                        }
                }
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            ACTIVE: true,
            HOTSPOT_NUMBER: null,
            TARGET_TYPE: null,
            TARGET_CLASS: null,
            TARGET: null,
            TARGET_PARAM: null,
            TARGET_GENERIC_IDENTIFIER: null,
            TARGET_PARAM_TYPE: null,
            TARGET_TRACKER: null,
        };

        this.$scope.meta = {
            targetGivenName: null,
            targetParameterType: null,
            targetGenericTypeSelector: null,
        }
    }

    async initDependencies(): Promise<boolean> {
        try {
            const self: TaskHotspotRegisterController = this;
            return new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getGenericList('task_hotspot_target'),
                    self.getGenericList('process_document_type'),
                    self.getGenericList('payment_nature'),
                    self.getGenericList('event'),
                    self.getGenericTypeList(),
                    self.getGenericList('task_options'),
                ]).then((result: any) => {
                    self.$scope.targetTypeList = result[0];
                    self.$scope.processDocumentTypeList = result[1];
                    self.$scope.natureList = result[2];
                    self.$scope.eventList = result[3];

                    const rawGenericTypes: { TYPE: string }[] = result[4];
                    self.$scope.genericTypeList = rawGenericTypes.map(x => ({ ID: '999', NAME: x.TYPE }));

                    self.$scope.taskOptionsList = result[5];
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            throw ex;
        }
    }

    initScopeFunctions(): void {
        this.$scope.changeTargetType = (): Promise<void> => {

            this.$scope.model.TARGET_CLASS = null;
            this.$scope.model.TARGET = null;
            this.$scope.meta.targetGivenName = null;

            return this.changeTargetType();
        }
        this.$scope.changeTarget = (): Promise<void> => {
            return this.changeTarget();
        }
        this.$scope.refreshTargetParameterType = (): Promise<void> => {
            this.$scope.model.TARGET_PARAM = null;
            return this.refreshTargetParameterType();
        }
        this.$scope.refreshTargetGenericReference = (event: JQuery.Event): Promise<void> => {
            if (event) this.$scope.model.TARGET_PARAM = null;
            return this.refreshTargetGenericReference();
        }
    }

    private async getGenericTypeList(): Promise<any> {
        const { data: generic } = await this.helperService.get(`/generic/type/list`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async getGenericList(type: string, alternative: boolean = false): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}/${alternative}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async changeTargetType(): Promise<void> {
        try {
            const targetType = this.$scope.model.TARGET_TYPE;
            if (!targetType) return;

            if (targetType.ID === '1') {
                this.$scope.targetClassList = this.$scope.eventList;
                // this.$scope.meta.targetParameterType = this.$scope.targetParameterTypeList.find(x => x.ID === '1');
                // await this.refreshTargetParameterType();
            }
            else if (targetType.ID === '4')
                this.$scope.targetClassList = this.$scope.processDocumentTypeList;
            else if (targetType.ID === '5')
                this.$scope.targetClassList = this.$scope.natureList;
            else if (targetType.ID === '6')
                this.$scope.targetClassList = this.$scope.processDocumentTypeList;
            else if (targetType.ID === '7')
                this.$scope.targetClassList = this.$scope.taskOptionsList;
            else
                this.$scope.targetClassList = [];

            const self: TaskHotspotRegisterController = this;
            this.$timeout(() => {
                self.$scope.selectorValidity(`target`);
                self.$scope.selectorValidity(`targetClass`);
            });

            const timeout: number = 15000;

            const taskServiceReq: IMonacoRequest = {
                route: `/hotspot/target/${targetType.ID}/${timeout}`,
                timeout,
            }
            const result = await this.TaskService.get<SelectorModel[]>(taskServiceReq);
            if (!result || !result.data) return this.handleError(result);

            this.$scope.targetList = result.data.data;

        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async changeTarget(): Promise<void> {
        try {
            this.$scope.meta.targetGivenName = null;
            const target = this.$scope.model.TARGET;
            const targetType = this.$scope.model.TARGET_TYPE;
            if (!target) return;

            if (targetType.ID === '5') { // Invoice
                const nameSugestion = this.invoiceCustomLogProperties.find(x => x.PROPERTY === target.NAME.substr((target.NAME.lastIndexOf('.') + 1), target.NAME.length));
                this.$scope.meta.targetGivenName = (nameSugestion && nameSugestion.LABEL) ? nameSugestion.LABEL : null;
            }

            await this.$scope.$applyAsync();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async refreshTargetParameterType(): Promise<void> {
        try {
            const targetParameterType = this.$scope.meta.targetParameterType;
            if (!targetParameterType) return;

            if (targetParameterType.ID === '1')
                this.$scope.meta.targetGenericTypeSelector = this.$scope.genericTypeList.find(x => x.NAME === 'date_type_reference');
            else
                this.$scope.meta.targetGenericTypeSelector = null;

            const self: TaskHotspotRegisterController = this;
            this.$timeout(() => {
                self.$scope.selectorValidity(`targetParam`);
                self.$scope.selectorValidity(`targetGenericIdentifier`);
                self.$scope.selectorValidity(`targetParameterTypeSelector`);
            });

            await this.refreshTargetGenericReference();

        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async refreshTargetGenericReference(): Promise<void> {
        try {
            this.$scope.targetParamList = [];

            const targetParameterType = this.$scope.meta.targetParameterType;
            if (targetParameterType && targetParameterType.ID === '2') {
                this.$scope.model.TARGET_PARAM = this.$scope.getYesNoSelector;
                return;
            }

            const genericReference = this.$scope.meta.targetGenericTypeSelector;
            if (!genericReference) return;

            const result = await this.getGenericList(genericReference.NAME);
            if (!result || !result.length) return;
            const genericList: SelectorModel[] = result;

            if (genericList && genericList.length > 0)
                this.$scope.targetParamList = genericList;

            const self: TaskHotspotRegisterController = this;
            this.$timeout(() => {
                self.$scope.selectorValidity(`targetParam`);
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async organizeHotspot(): Promise<void> {
        try {
            this.$scope.targetParamList = [];

            const model = angular.copy(this.$scope.model);

            let targetParameterType = null;
            if (model.TARGET_PARAM_TYPE === TARGET_PARAM_TYPE.DATE)
                targetParameterType = this.$scope.targetParameterTypeList.find(x => x.ID === '1');
            else if (model.TARGET_PARAM_TYPE === TARGET_PARAM_TYPE.YESORNO)
                targetParameterType = this.$scope.targetParameterTypeList.find(x => x.ID === '2');
            else
                targetParameterType = this.$scope.targetParameterTypeList.find(x => x.ID === '3');

            this.$scope.meta = {
                targetGivenName: model.TARGET.NAME,
                targetParameterType: targetParameterType,
                targetGenericTypeSelector: (model.TARGET_GENERIC_IDENTIFIER) ? { ID: '999', NAME: model.TARGET_GENERIC_IDENTIFIER } : null,
            }
            this.$scope.model.TARGET = (model.TARGET.CODE) ? { ID: '999', NAME: model.TARGET.CODE, } : null;

            await this.changeTargetType();
            await this.refreshTargetGenericReference();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    async register(): Promise<void> {
        try {
            BrowserTitle.$id = null;
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            BrowserTitle.$id = `${this.$scope.model.HOTSPOT_NUMBER}`;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
            await this.organizeHotspot();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            BrowserTitle.$id = `${this.$scope.model.HOTSPOT_NUMBER}`;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');
            // this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            await this.organizeHotspot();

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            BrowserTitle.$id = `${this.$scope.model.HOTSPOT_NUMBER}`;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.COPYING');
            // this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            await this.organizeHotspot();
            await this.clearFields(this.$scope.model);

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        try {
            return true;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequest> {
        try {
            const model = angular.copy(this.$scope.model);

            const reorganizedTarget: SelectorModel = {
                ID: '999',
                NAME: this.$scope.meta.targetGivenName,
                CODE: (model.TARGET) ? model.TARGET.NAME : null,
            }

            model.TARGET = reorganizedTarget;
            model.TARGET_TRACKER = (model.TARGET) ? model.TARGET.CODE : (model.TARGET_CLASS) ? model.TARGET_CLASS.map(x => x.NAME.toLocaleUpperCase()).join('; ') : null;
            model.TARGET_GENERIC_IDENTIFIER = (this.$scope.meta.targetGenericTypeSelector) ? this.$scope.meta.targetGenericTypeSelector.NAME : null;

            const targetParameterTypeID = this.$scope.meta.targetParameterType.ID;
            const paramType = (targetParameterTypeID === '1' || model.TARGET_GENERIC_IDENTIFIER === 'date_type_reference') ? TARGET_PARAM_TYPE.DATE : (targetParameterTypeID === '2') ? TARGET_PARAM_TYPE.YESORNO : TARGET_PARAM_TYPE.GENERIC;
            model.TARGET_PARAM_TYPE = paramType;

            const route = this.$scope.operation === 'register' ? 'insert' : 'update';
            return {
                data: model,
                route: `/hotspot/${route}`,
                timeout: 15000,
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: 'CHARGE', LABEL: 'Taxa' },
            { PROPERTY: 'CHARGE_NUMBER', LABEL: 'Ident. da Taxa' },
            { PROPERTY: 'CHARGE_TYPE', LABEL: 'Tipo de Taxa' },
            { PROPERTY: 'NATURE', LABEL: 'Natureza' },
            { PROPERTY: 'AMOUNT', LABEL: 'Qtd.' },
            { PROPERTY: 'APPLICATION', LABEL: 'Aplicação' },
            { PROPERTY: 'CURRENCY', LABEL: 'Moeda' },
            { PROPERTY: 'MINIMUM', LABEL: 'Mínimo' },
            { PROPERTY: 'UNITARY', LABEL: 'Unitário' },
            { PROPERTY: 'TOTAL', LABEL: 'Total' },
            { PROPERTY: 'TOTAL_BRL', LABEL: 'Total (BRL)' },
            { PROPERTY: 'CONVERSION_SPREAD', LABEL: 'Conversão+Spread' },
            { PROPERTY: 'TRANSACTION', LABEL: 'Transação' },
            { PROPERTY: 'PEOPLE', LABEL: 'Pessoa' },
            { PROPERTY: 'VALIDITY_OF', LABEL: 'Validade de' },
            { PROPERTY: 'VALIDITY_UP', LABEL: 'Validade até' },
            { PROPERTY: 'SHOW_FOR_GROUP', LABEL: 'Exibição' },
            { PROPERTY: 'EQUIPMENT', LABEL: 'Equipamento' },
            { PROPERTY: 'INVOICE_NUMBER', LABEL: 'Fatura' },
            { PROPERTY: 'COMPOSITION_GROUP', LABEL: 'Composição' },
            { PROPERTY: 'OBSERVATION', LABEL: 'Observações' },
            { PROPERTY: 'HC2COD_CHARGE', LABEL: 'Ident. HC2' },
            { PROPERTY: 'CHARGE_ORIGIN', LABEL: 'Origem' },
            { PROPERTY: 'MODALITY', LABEL: 'Modalidade' },
            { PROPERTY: 'PAYMENT_METHOD', LABEL: 'Método de Pagto.' },
            { PROPERTY: 'PAYMENT_CONDITION', LABEL: 'Condição de Pagto.' },
            { PROPERTY: 'DUE_DATE_REFERENCE', LABEL: 'Referência do Venc.' },
            { PROPERTY: 'DUE_DATE_D0', LABEL: 'D0' },
            { PROPERTY: 'CONVERSION_REFERENCE', LABEL: 'Referência da Conversão' },
            { PROPERTY: 'CURRENCY_CONVERSION', LABEL: 'Conversão' },
            { PROPERTY: 'SPREAD', LABEL: 'Spread' },
            { PROPERTY: 'PERIOD_VARIATION', LABEL: 'Variação do Período' },
            { PROPERTY: 'BASE', LABEL: 'Base' },
            { PROPERTY: 'PREFERENCE_TYPE', LABEL: 'Tipo de Preferência' },
            { PROPERTY: 'PREFERENCE', LABEL: 'Preferência' },
            { PROPERTY: 'PREFERENCE_PARAMETERIZATION', LABEL: 'Param. da Preferência' },
            { PROPERTY: 'ANTICIPATED_INVOICE', LABEL: 'Fatura Antecipada' },
            { PROPERTY: 'PAYMENT_COMPROMISE', LABEL: 'Compromisso de Pagamento' },
            { PROPERTY: 'OBSERVATION', LABEL: 'Observações' },
            { PROPERTY: 'PROCESS_NUMBER', LABEL: 'Processo' },
            { PROPERTY: 'INVOICE_NUMBER', LABEL: 'Fatura' },
            { PROPERTY: 'NATURE', LABEL: 'Natureza' },
            { PROPERTY: 'STATUS', LABEL: 'Status Faturamento' },
            { PROPERTY: 'FINANCIAL_SITUATION', LABEL: 'Status Financeiro' },
            { PROPERTY: 'LOCKED', LABEL: 'Cadeada' },
            { PROPERTY: 'LOCKED_DATE', LABEL: 'Data do Cadeamento' },
            { PROPERTY: 'TRANSACTION', LABEL: 'Transação' },
            { PROPERTY: 'GROUPING_SPECS', LABEL: 'Agrupamento' },
            { PROPERTY: 'PEOPLE', LABEL: 'Pessoa' },
            { PROPERTY: 'CONTRACT_NUMBER', LABEL: 'Contrato' },
            { PROPERTY: 'DUE_DATE', LABEL: 'Data de Vencimento' },
            { PROPERTY: 'COMPOSITION', LABEL: 'Composição' },
            { PROPERTY: 'CONTRACT_COMPOSITION', LABEL: 'Informações do Contrato' },
            { PROPERTY: 'CUSTOM_CONTRACT', LABEL: 'Contrato Personalizado' },
            { PROPERTY: 'INVOICE_CONFIGURATION', LABEL: 'Configuração da Fatura' },
            { PROPERTY: 'CONVERSION_DATE', LABEL: 'Data da Conversão' },
            { PROPERTY: 'EXCHANGE_RATE', LABEL: 'Informações de Câmbio' },
            { PROPERTY: 'CURRENCY', LABEL: 'Moeda' },
            { PROPERTY: 'DATE', LABEL: 'Data' },
            { PROPERTY: 'CONVERSION_FACTOR', LABEL: 'Fator' },
            { PROPERTY: 'CONVERSION_FACTOR_SPREAD', LABEL: 'Fator+Spread' },
            { PROPERTY: 'CLIENT_ACCOUNT', LABEL: 'Conta Corrente' },
            { PROPERTY: 'ACCOUNT_NUMBER', LABEL: 'Número da Conta' },
            { PROPERTY: 'ACCOUNT', LABEL: 'Conta' },
            { PROPERTY: 'AGENCY', LABEL: 'Agência' },
            { PROPERTY: 'BANK', LABEL: 'Banco' },
            { PROPERTY: 'OPERATION', LABEL: 'Operação' },
            { PROPERTY: 'HOLDER_NAME', LABEL: 'Nome do Titular' },
            { PROPERTY: 'HOLDER_CNPJ', LABEL: 'CNPJ do Titular' },
            { PROPERTY: 'DEFAULT', LABEL: 'Padrão' },
            { PROPERTY: 'OBSERVATION', LABEL: 'Observação' },
            { PROPERTY: 'DESCRIPTION', LABEL: 'Descrição' },
            { PROPERTY: 'PAYMENT_PERIOD', LABEL: 'Periodo do Pagto.' },
            { PROPERTY: 'LIQUIDATION_DATE', LABEL: 'Data de Liquidação' },
            { PROPERTY: 'ORIGINAL_TOTAL', LABEL: 'Total Original' },
            { PROPERTY: 'PARTIAL_PAYMENT', LABEL: 'Pagamento Parcial' },
            { PROPERTY: 'CURRENCIES', LABEL: 'Moedas' },
            { PROPERTY: 'PROFIT', LABEL: 'Profit' },
            { PROPERTY: 'ORIGINAL', LABEL: 'Originais' },
            { PROPERTY: 'CURRENT', LABEL: 'Atual' },
            { PROPERTY: 'NEGOTIATED', LABEL: 'Negociada' },
            { PROPERTY: 'NEGOTIATION', LABEL: 'Negociação' },
            { PROPERTY: 'NEGOTIATION_COMPOSITION', LABEL: 'Detalhes da Negociação' },
            { PROPERTY: 'ORIGINAL_INFORMATION', LABEL: 'Dados Originais' },
            { PROPERTY: 'CREATED_DATE', LABEL: 'Data de Criação' },
            { PROPERTY: 'CREATED_BY', LABEL: 'Criado por' },
            { PROPERTY: 'PERSISTENT', LABEL: 'Persistente' },
            { PROPERTY: 'EXCHANGE_CONTRACT', LABEL: 'Contrato de Câmbio' },
            { PROPERTY: 'APPROVAL_DATE', LABEL: 'Data de Aprovação' },
            { PROPERTY: 'THIRDPARTY_DATE', LABEL: 'Data Invoice Terceiro' },
            { PROPERTY: 'HC2_FINANCIAL_RECORD', LABEL: 'Registro Integração HC2' },
            { PROPERTY: 'UNIFICATION', LABEL: 'Registro Unificação HC2' },
            { PROPERTY: 'GENERATED_BY', LABEL: 'Gerado por' },
        ]
        return props;
    }

    /* private viewLog(chargeGroupingParams) {
        this.block();
        let log: IViewLog = {
            operation: 'history',
            number: chargeGroupingParams.ID,
            list: [],
            show: true,
            searchQuery: '',
            originalList: [],
        }
        this.requestHistory(log.number).then(result => {
            log.list = result.data;
            log.originalList = angular.copy(log.list);
            this.$scope.log = log;
            angular.element('#log-viewer').removeClass('ng-hide');
            const position = angular.element('#log-viewer').offset().top + $('.app-content-body').scrollTop() - 105;

            $('.app-content-body').animate({
                scrollTop: position
            }, 500);

            this.unblock();
        }).catch(ex => {
            this.handleError(ex);
        });
    }; */

}
import "../../css/filePreview.css";

import * as angular from 'angular';
import "bootstrap/dist/css/bootstrap.min.css";
import 'angular-pdf';
import { ErrorService } from '@services/ErrorService';
import { RestService } from '@services/RestService';
import { DownloadService } from '@services/DownloadService';
import { DownloadFilesGenericService } from '@services/DownloadFilesGenericService';
import { ErrorController } from '../common/ErrorController';
import { StorageDownloadController } from './controllers/StorageDownloadController';
import { StorageDownloadFilesGenericController } from './controllers/StorageDownloadFilesGenericController';
import initRoute from './RouteConfig';

const STORAGE_MODULE = angular
    .module("storage", ['pdf'])
    .service("ErrorService", ErrorService)
    .service("RestService", RestService)
    .service("DownloadService", DownloadService)
    .service("DownloadFilesGenericService", DownloadFilesGenericService)
    .controller('StorageDownloadController', StorageDownloadController)
    .controller('StorageDownloadFilesGenericController', StorageDownloadFilesGenericController)
    .controller('ErrorController', ErrorController);
initRoute(STORAGE_MODULE.name);

export { STORAGE_MODULE };
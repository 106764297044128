import * as angular from "angular";
import { IHttpResponse } from "angular";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequest } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IModalService } from "@services/ModalService";
import { IMonacoColumnDef } from "@services/GridService2";
import { ProductService } from "@services/ProductService";
import { IFinopService } from "@services/FinopService";
import { ISelectorModel } from "@models/mongo/SelectorModel";
import { ILegalPersonSelector, IIncotermSelector } from "@models/interface/operational/IProductIntegrationSelector";
import { ChargeGroupingParams, INVOICE_HOLDER, PROCESS_SPEC, propertyNameMap } from "@models/interface/finop/ChargeGroupingParams";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { EOperId, EModalId, EProductId } from "@enums/GenericData";
import { SelectorModel } from "../../common/model/SelectorModel";
import { ILegalPersonListCustomFilter } from "../../registration/model/LegalPersonModel";
import { HelperService } from "@services/HelperService";

interface IChargeGroupingParamsScope extends IGridFormServiceScope {
    model: ChargeGroupingParams;
    user: any;
    log: IViewLog;
    scopeBeforeSave: ChargeGroupingParams;
    customLogProperties: ICustomLogProperties[];

    operations: SelectorModel[];
    modals: SelectorModel[];
    payment_types_house: SelectorModel[];
    payment_types_master: SelectorModel[];
    transactions: SelectorModel[];
    people: SelectorModel[];
    incoterms: SelectorModel[];
    nature: SelectorModel[];
    charge_origins: SelectorModel[];
    currencies: SelectorModel[];
    specializations: SelectorModel[];
    shownFor: SelectorModel[];
    chargeList: SelectorModel[];

    htmlConditionPopover: string;
    htmlRulePopover: string;
    htmlOtherPopover: string;
    htmlHolderPopover: string;
    htmlSpecPopover: string;
    htmlANDPopover: string;
    htmlORPopover: string;

    refreshPeople: (name?: string) => void;
    refreshCharges: (name: string) => void;
    checkSelected: (model: any, list: string) => void;
    clearPeople: (isOpen: boolean) => void;
    getEmptySelectorMsg: () => string;

    addSpecCondition: (index?: number) => void;
    removeSpecCondition: (index: number) => void;
    addInvoiceHolderCondition: (index?: number) => void;
    removeInvoiceHolderCondition: (index: number) => void;
}

export class ChargeGroupingParamsRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope', '$sce'];
    private $scope: IChargeGroupingParamsScope;
    private $timeout: ng.ITimeoutService
    private $q: ng.IQService;
    private RestService: IRestService;
    private ModalService: IModalService;
    private SCEService: ng.ISCEService;
    private ProductService: ProductService;
    public finopService: IFinopService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IChargeGroupingParamsScope) {
        super($injector, $scope);
        //set $scope
        this.$scope = $scope;
        //get injections
        this.$timeout = $injector.get('$timeout');
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');
        this.ProductService = $injector.get("ProductService");
        this.SCEService = $injector.get('$sce');
        this.finopService = $injector.get('FinopService');
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.finopService.$route();

            this.initForm(this, 'form', 'ChargeGroupingParams', 'FINANCIAL.CHARGES_SPLIT', true);

            this.block();

            await this.initGrid('gridChargeGroupingParams', '/charge/grouping/list', true, true, 15000, true, true);
            // await this.initGrid('gridCharge', '/charge/list', true, true, 60000, true, true);

            this.setCopyable(false);
            this.setDeletable(true);

            this.$scope.htmlConditionPopover = this.SCEService.trustAsHtml(this.formService.getTranslate("REGISTRATION.CONDITION"));
            this.$scope.htmlRulePopover = this.SCEService.trustAsHtml(`${this.formService.getTranslate("REGISTRATION.RULE_DEFINITION")}<br><br>${this.formService.getTranslate("REGISTRATION.DEFAULT_RULE")}`);
            this.$scope.htmlOtherPopover = this.SCEService.trustAsHtml(`${this.formService.getTranslate("REGISTRATION.OTHER_CHARGES")}<br><br>${this.formService.getTranslate("REGISTRATION.DEFAULT_RULE_OTHER")}`);

            this.$scope.htmlHolderPopover = this.SCEService.trustAsHtml(this.formService.getTranslate("REGISTRATION.HOLDER_INSTRUCTION"));
            this.$scope.htmlSpecPopover = this.SCEService.trustAsHtml(this.formService.getTranslate("REGISTRATION.SPECIALITY_INSTRUCTION"));

            this.$scope.htmlANDPopover = this.SCEService.trustAsHtml(this.formService.getTranslate("REGISTRATION.CONDITION_AND_EXPLAINED"));
            this.$scope.htmlORPopover = this.SCEService.trustAsHtml(this.formService.getTranslate("REGISTRATION.CONDITION_OR_EXPLAINED"));

            this.$scope.customLogProperties = propertyNameMap;

            this.unblock();

        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
        //$interval.cancel(updateDataTask);
    }

    initScopeFunctions(): void {
        this.$scope.checkSelected = async (model, list) => {
            await this.checkSelectedAll(model, list);
        }
        this.$scope.addSpecCondition = (index?: number) => {
            this.addSpecCondition(index);
        }
        this.$scope.removeSpecCondition = async (index: number) => {
            await this.removeSpecCondition(index);
        }
        this.$scope.addInvoiceHolderCondition = (index?: number) => {
            this.addInvoiceHolderCondition(index);
        }
        this.$scope.removeInvoiceHolderCondition = async (index: number) => {
            await this.removeInvoiceHolderCondition(index);
        }
        this.$scope.refreshPeople = async (name) => {
            await this.filterPeople(name);
        }
        this.$scope.refreshCharges = async (name) => {
            await this.refreshCharges(name);
        }
        this.$scope.clearPeople = (isOpen: boolean) => {
            if (!isOpen) this.$scope.people = [];
        }
        this.$scope.viewLog = (entity) => {
            this.viewLog(entity);
        }
    }

    getEmptySelectorMsg(): string {
        return 'Nenhum valor disponível';
    }

    initGridColumns(columns) {
        const columnDefs: IMonacoColumnDef[] = new Array();

        //action column, contain just buttons and icons
        const view = `<div class="text-center"><a ng-click="grid.appScope.view(row.entity)" class="text-info"tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`
        const edit = `<a ng-click="grid.appScope.edit(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`
        const history = `<a ng-click="grid.appScope.viewLog(row.entity)" class="text-green" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a></div>`

        columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + history),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        let newColumnDefs = this.buildColunms(columns);
        for (let column of newColumnDefs) { columnDefs.push(column) }
        return columnDefs;
    }

    buildColunms(column, prop?): any {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            for (var property in column) {
                if (typeof (column[property]) === 'string') { //finally reached the bottom
                    let currentProperty = (prop) ? `${prop}.${column[property]}` : column[property];
                    let name = null;
                    let displayName = null;
                    let width = 0;
                    let cellTemplate = undefined;
                    let cellFilter = null;
                    let grouping = null;
                    let sort = null;
                    let pinned = null;
                    let visible = true;
                    let field = null;
                    let filter: uiGrid.IFilterOptions = { condition: this.$gridService.filterSelectObject };
                    let searchProps: string[] = null;

                    switch (currentProperty.toUpperCase()) {
                        case 'ID':
                            name = currentProperty;
                            displayName = 'GENERAL.ID';
                            width = currentProperty.length + 1;
                            visible = false;
                            break;
                        case 'DESCRIPTION':
                            name = currentProperty;
                            field = `${currentProperty}`;
                            displayName = 'GENERAL.NAME';
                            width = currentProperty.length + 7;
                            filter = { condition: this.$gridService.filterSelectObject };
                            break;
                        case 'CONDITION.OPERATION':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.OPERATION';
                            cellTemplate = `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.CONDITION.OPERATION)}}</div>`;
                            width = currentProperty.length + 4;
                            break;
                        case 'CONDITION.MODAL':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.MODE_OF_TRANSPORT';
                            cellTemplate = `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.CONDITION.MODAL)}}</div>`;
                            width = currentProperty.length + 4;
                            break;
                        case 'CONDITION.PAYMENT_HOUSE':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.HOUSE_PAYMENT_MODE';
                            cellTemplate = `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.CONDITION.PAYMENT_HOUSE)}}</div>`;
                            width = currentProperty.length + 3;
                            break;
                        case 'CONDITION.PAYMENT_MASTER':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.MASTER_PAYMENT_MODE';
                            cellTemplate = `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.CONDITION.PAYMENT_MASTER)}}</div>`;
                            width = currentProperty.length + 3;
                            break;
                        case 'CONDITION.INCOTERM':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.INCOTERM';
                            cellTemplate = `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.CONDITION.INCOTERM)}}</div>`;
                            width = currentProperty.length + 2;
                            break;
                        case 'CONDITION.INVOICE_HOLDER.HOLDER':
                            name = currentProperty + '.NAME';
                            searchProps = [`CONDITION.INVOICE_HOLDER.HOLDER.NAME`, `CONDITION.INVOICE_HOLDER.HOLDER.ID`];
                            displayName = 'GENERAL.HOLDER';
                            cellTemplate = `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.CONDITION.INVOICE_HOLDER, 'HOLDER', null, false, true)}}</div>`;
                            width = currentProperty.length + 3;
                            break;
                        case 'CONDITION.INVOICE_HOLDER.TRANSACTION':
                            name = currentProperty + '.NAME';
                            searchProps = [`CONDITION.INVOICE_HOLDER.TRANSACTION.NAME`];
                            displayName = 'GENERAL.HOLDER_TYPE';
                            cellTemplate = `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.CONDITION.INVOICE_HOLDER, 'TRANSACTION', null, false, true)}}</div>`;
                            width = currentProperty.length;
                            break;
                        case 'CONDITION.PROCESS_SPEC.PEOPLE':
                            name = currentProperty + '.NAME';
                            searchProps = [`CONDITION.PROCESS_SPEC.PEOPLE.NAME`, `CONDITION.PROCESS_SPEC.PEOPLE.ID`];
                            displayName = 'GENERAL.STAKEHOLDERS';
                            cellTemplate = `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.CONDITION.PROCESS_SPEC, 'PEOPLE', null, false, true)}}</div>`;
                            width = currentProperty.length + 3;
                            break;
                        case 'CONDITION.PROCESS_SPEC.SPEC':
                            name = currentProperty + '.NAME';
                            searchProps = [`CONDITION.PROCESS_SPEC.SPEC.NAME`];
                            displayName = 'ENTITY.COMPANY_TYPE';
                            cellTemplate = `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.CONDITION.PROCESS_SPEC, 'SPEC', null, false, true)}}</div>`;
                            width = currentProperty.length;
                            break;
                        case 'RULE.NATURE':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.PAYMENT_NATURE';
                            cellTemplate = `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.RULE.NATURE)}}</div>`;
                            width = currentProperty.length + 1;
                            break;
                        case 'RULE.CHARGE_ORIGIN':
                            name = currentProperty + '.NAME';
                            displayName = 'FINANCIAL.CHARGE_TYPE';
                            cellTemplate = `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.RULE.CHARGE_ORIGIN)}}</div>`;
                            width = currentProperty.length + 1;
                            break;
                        case 'RULE.CURRENCY':
                            name = currentProperty + '.NAME';
                            displayName = 'GENERAL.CURRENCY';
                            cellTemplate = `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.RULE.CURRENCY, null, 'CODE')}}</div>`;
                            width = currentProperty.length + 1;
                            break;
                        case 'RULE.CHARGES':
                            name = currentProperty + '.NAME';
                            displayName = 'GENERAL.CHARGES';
                            width = currentProperty.length + 8;
                            cellTemplate = `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.RULE.CHARGES)}}</div>`;
                            break;
                        case 'RULE.SHOW_FOR':
                            name = currentProperty + '.NAME';
                            displayName = 'FINANCIAL.SHOWN_FOR';
                            width = currentProperty.length + 4;
                            cellTemplate = `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.RULE.SHOW_FOR)}}</div>`;
                            break;
                        case 'RULE.GROUP':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.AGROUP';
                            width = currentProperty.length + 2;
                            break;
                        case 'OBSERVATION':
                            name = currentProperty;
                            displayName = 'GENERAL.REMARKS';
                            width = currentProperty.length + 10;
                            break;
                        case 'ACTIVE':
                            name = currentProperty;
                            displayName = 'GENERAL.ACTIVE';
                            width = currentProperty.length;
                            cellFilter = "YesOrNo";
                            break;
                        default:
                            name = currentProperty;
                            width = currentProperty.length;
                            visible = false;
                            break;
                    };
                    const newColumn: IMonacoColumnDef = {
                        name: name,
                        displayName: displayName,
                        headerCellClass: this.$gridService.highlightFilteredHeader.bind(this.$gridService),
                        width: width + '%',
                        cellTemplate: cellTemplate,
                        cellFilter: cellFilter,
                        visible: visible,
                        filter: filter,
                        searchProps: searchProps,
                    };
                    if (field) newColumn.field = field;
                    if (name) columnDefs.push(newColumn);
                } else {
                    const composedPropPath = (prop) ? (isNaN(parseInt(property))) ? `${prop}.${property}` : prop : (isNaN(parseInt(property))) ? property : null;
                    const newCol = this.buildColunms(column[property], composedPropPath);
                    if (newCol)
                        for (let dados of newCol) {
                            columnDefs.push(dados);
                        }
                }
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            DESCRIPTION: null,
            CONDITION: {
                OPERATION: null,
                MODAL: null,
                PAYMENT_HOUSE: null,
                PAYMENT_MASTER: null,
                INCOTERM: null,
                INVOICE_HOLDER: null,
                PROCESS_SPEC: null,
            },
            RULE: {
                NATURE: null,
                CHARGE_ORIGIN: null,
                CURRENCY: null,
                CHARGES: null,
                SHOW_FOR: null,
                GROUP: this.$scope.yesAndNoOptions.find(x => x.ID === '1') // sim
            },
            OBSERVATION: null,
            ACTIVE: true
        }
    }

    async initDependencies(): Promise<boolean> {
        try {
            const self: ChargeGroupingParamsRegisterController = this;
            self.$scope.incoterms = await self.getIncotermList();
            self.$scope.currencies = await self.getCurrencyList();

            return new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getGenericList('transaction'),
                    self.getGenericList('payment_nature'),
                    self.getGenericList('type_payment'),
                    self.getGenericList('oper'),
                    self.getGenericList('modal'),
                    self.getGenericList('charge_origin'),
                    self.getGenericList('specialization'),
                    self.getGenericList('charge_shown_for')
                ]).then((result: any[]) => {
                    self.$scope.transactions = result[0];
                    self.$scope.nature = result[1];
                    self.$scope.payment_types_house = result[2];
                    self.$scope.operations = result[3];
                    self.$scope.modals = result[4];
                    self.$scope.charge_origins = result[5];
                    self.$scope.specializations = result[6];
                    self.$scope.shownFor = result[7];

                    if (!self.$scope.payment_types_master || self.$scope.payment_types_master.length === 0)
                        self.$scope.payment_types_master = angular.copy(self.$scope.payment_types_house);

                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            throw ex;
        }
    }

    private async getIncotermList(): Promise<SelectorModel[]> {
        try {
            let incotermList: SelectorModel[] = [];
            const request: IMonacoRequest = {
                data: null,
                route: `/incoterm/list`,
                timeout: 30000,
            }
            const result = await this.ProductService.post(request)
            if (result && result.data && result.status == 200) {
                const providerList: IIncotermSelector[] = result.data.data.data;
                for (const resultItem of providerList) {
                    const incoterm: ISelectorModel = { ID: resultItem.ID, CODE: resultItem.INITIALS, NAME: resultItem.NAME }
                    incotermList.push(incoterm);
                }
            }
            return incotermList;
        } catch (ex) {
            this.handleError(ex);
        }
    }


    private async getCurrencyList(): Promise<SelectorModel[]> {
        try {
            this.block();
            const request: IMonacoRequest = {
                data: null,
                route: `/currency/list`,
                timeout: 30000,
            }
            const result = await this.ProductService.post(request)
            const currencyResult: ISelectorModel[] = [];
            if (result && result.data && result.status == 200) {
                const currencyList = result.data.data.data;
                for (const resultItem of currencyList) {
                    const currency: ISelectorModel = { ID: resultItem.ID, CODE: resultItem.INITIALS, NAME: resultItem.NAME }
                    currencyResult.push(currency);
                }
            }
            this.unblock();
            return currencyResult
        } catch (ex) {
            this.handleError(ex);
        }
    }


    private async getChargeNameListByName(term?: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            const transform = (returnedData) => returnedData.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE } });
            const productIds = this.buildProductIdsByOperationAndModal();
            if (!productIds || productIds && productIds.length == 0) this.formService.notifyError(this.formService.getTranslate("REGISTRATION.SELECT_OP"));
            else {
                this.block();
                const request: IMonacoRequest = {
                    data: { search: term, products: productIds },
                    route: `/chargeName/list/custom`,
                    timeout: 10000,
                };
                const response = await this.ProductService.post<IHttpResponse<object[]>>(request);
                result = (response && response.data && response.data.data) ? transform(response.data.data) : [];
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    };

    private buildProductIdsByOperationAndModal(): string[] {
        const productIds = [];
        if (this.$scope.model.CONDITION && this.$scope.model.CONDITION.OPERATION && this.$scope.model.CONDITION.MODAL) {
            const hasAir = this.$scope.model.CONDITION.MODAL.findIndex(modal => modal.ID == EModalId.AIR) >= 0;
            const hasMaritime = this.$scope.model.CONDITION.MODAL.findIndex(modal => modal.ID == EModalId.MARITIME) >= 0;
            const hasRoad = this.$scope.model.CONDITION.MODAL.findIndex(modal => modal.ID == EModalId.ROAD) >= 0;
            for (const operation of this.$scope.model.CONDITION.OPERATION) {
                if (operation.ID == EOperId.EXPORT) {
                    if (hasAir) productIds.push(EProductId.AIR_EXPORT);
                    if (hasMaritime) productIds.push(EProductId.MARITIME_EXPORT);
                    if (hasRoad) productIds.push(EProductId.ROAD_EXPORT);
                } else if (operation.ID == EOperId.IMPORT) {
                    if (hasAir) productIds.push(EProductId.AIR_IMPORT);
                    if (hasMaritime) productIds.push(EProductId.MARITIME_IMPORT);
                    if (hasRoad) productIds.push(EProductId.ROAD_IMPORT);
                }
            }
        }
        return productIds;
    }

    private async getGenericList(type): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private requestHistory(id: string): Promise<any> {
        return this.RestService.getObjectAsPromise(`${this.finopService.$route()}/charge/grouping/viewLog/${id}`, 10000, null, false);
    }

    private getRegisteredCharges(name?: string): Promise<any> {
        if (name) return this.RestService.getObjectAsPromise(`/headcargo/charges/${name}`, 10000);
    }

    private async filterPeople(name: string): Promise<void> {
        try {
            // this.$scope.people = [];
            if (name.length >= 3) {
                const result = await this.getLegalPersonListByName({ specializations: null, search: name });
                if (result) this.$scope.people = result;
            }
            await this.$scope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getLegalPersonListByName(filter: ILegalPersonListCustomFilter): Promise<SelectorModel[]> {
        try {
            this.block();
            const request: IMonacoRequest = {
                data: {
                    ...filter
                },
                route: `/legalPerson/list/custom`,
                timeout: 30000,
            }
            const result = await this.ProductService.post(request)
            const legalPersonResult: ISelectorModel[] = [];
            if (result && result.data && result.status == 200) {
                const legalPersonList: ILegalPersonSelector[] = result.data.data;
                for (const resultItem of legalPersonList) {
                    const legalPerson: ISelectorModel = { ID: resultItem.ID, CODE: resultItem.CORPORATE_NAME, NAME: resultItem.SHORT_NAME }
                    legalPersonResult.push(legalPerson);
                }
            }
            this.unblock();
            return legalPersonResult
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async refreshCharges(name: string): Promise<void> {
        try {
            this.$scope.chargeList = [];
            if (name && name.length >= 3) {
                const result = await this.getChargeNameListByName(name);
                this.$scope.chargeList = result;
            }
            await this.$scope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private addSpecCondition(index?: number): void {
        try {
            const copiedPeople = (index != null && this.$scope.model.CONDITION.PROCESS_SPEC.length > 0) ? this.$scope.model.CONDITION.PROCESS_SPEC[index].PEOPLE : null
            const newSpec: PROCESS_SPEC = {
                PEOPLE: copiedPeople,
                SPEC: null,
            }
            if (!this.$scope.model.CONDITION.PROCESS_SPEC) this.$scope.model.CONDITION.PROCESS_SPEC = [];
            this.$scope.model.CONDITION.PROCESS_SPEC.push(angular.copy(newSpec));
            this.$timeout(() => {
                for (let i = 0; i < this.$scope.model.CONDITION.PROCESS_SPEC.length; i++) {
                    this.$scope.selectorValidity(`people${i}`);
                    this.$scope.selectorValidity(`spec${i}`);
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };
    private async removeSpecCondition(index: number): Promise<void> {
        try {
            // remove value from array
            this.$scope.model.CONDITION.PROCESS_SPEC.splice(index, 1);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };
    private addInvoiceHolderCondition(index?: number): void {
        try {
            const copiedHolders = (index != null && this.$scope.model.CONDITION.INVOICE_HOLDER.length > 0) ? this.$scope.model.CONDITION.INVOICE_HOLDER[index].HOLDER : null
            const newHolder: INVOICE_HOLDER = {
                HOLDER: copiedHolders,
                TRANSACTION: null
            }
            if (!this.$scope.model.CONDITION.INVOICE_HOLDER) this.$scope.model.CONDITION.INVOICE_HOLDER = [];
            this.$scope.model.CONDITION.INVOICE_HOLDER.push(angular.copy(newHolder));
            const self: ChargeGroupingParamsRegisterController = this;
            this.$timeout(() => {
                for (let i = 0; i < this.$scope.model.CONDITION.INVOICE_HOLDER.length; i++) {
                    self.$scope.selectorValidity(`holder${i}`);
                    self.$scope.selectorValidity(`transaction${i}`);
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };
    private async removeInvoiceHolderCondition(index: number): Promise<void> {
        try {
            // remove value from array
            this.$scope.model.CONDITION.INVOICE_HOLDER.splice(index, 1);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async checkSelectedAll(model, list?: string): Promise<void> {
        try {
            // not needed anymore
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async register(): Promise<void> {
        this.$scope.scopeBeforeSave = null;
        this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
    }

    async view(): Promise<void> {
        this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW");
    }

    async edit(): Promise<void> {
        this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT");
        this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
    }

    async save(): Promise<boolean> {
        if (this.$scope.model.CONDITION.PROCESS_SPEC && this.$scope.model.CONDITION.PROCESS_SPEC.length > 0) {
            if (!this.$scope.model.CONDITION.INVOICE_HOLDER || this.$scope.model.CONDITION.INVOICE_HOLDER.length === 0) {
                this.handleError(this.formService.getTranslate("REGISTRATION.SELECT_INVOICE_HOLDERS"));
                this.addInvoiceHolderCondition();
                return false;
            }
        }
        return true;
    }

    async request(): Promise<IMonacoRequest> {
        let request;
        const route = this.$scope.operation === 'register' ? 'insert' : 'update';
        if (this.$scope.operation === 'delete') {
            request = {
                route: `/charge/grouping/delete/${this.$scope.model._id}`,
                timeout: 15000
            };
        } else {
            request = {
                route: `/charge/grouping/${route}`,
                data: angular.copy(this.$scope.model),
                oldData: angular.copy(this.$scope.scopeBeforeSave),
                timeout: 15000
            };
        }
        return request;
    }

    private viewLog(chargeGroupingParams) {
        this.block();
        let log: IViewLog = {
            operation: 'history',
            number: chargeGroupingParams.ID,
            list: [],
            show: true,
            searchQuery: '',
            originalList: [],
        }
        this.requestHistory(log.number).then(result => {
            log.list = result.data;
            log.originalList = angular.copy(log.list);
            this.$scope.log = log;
            angular.element('#log-viewer').removeClass('ng-hide');
            const position = angular.element('#log-viewer').offset().top + $('.app-content-body').scrollTop() - 105;
            /* angular.element('#deep-log-viewer').removeClass('ng-hide');
            let topPosition = angular.element('#deep-log-viewer').offset().top; */

            $('.app-content-body').animate({
                scrollTop: position
            }, 500);

            this.unblock();
        }).catch(ex => {
            this.handleError(ex);
        });
    };
}

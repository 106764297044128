import angular = require('angular');
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { FormService2 } from '@services/FormService2';
import { ISessionService } from "@services/SessionService";
import { DataProcessService } from "@services/DataProcessService";
import { IModalService } from '@services/ModalService';
import { IViewLog } from "@models/interface/common/IViewLog";
import { ISelectorModel, SelectorModel } from '@models/mongo/SelectorModel';
import { EOperation } from '@enums/GenericData';
import { ECollapseState } from './NewProcessController';
import { ICargoSummary } from '../../product/model/OfferModel';
import { ILinkParameter } from '../../common/model/ModelParameter';
import { IMonacoConfig } from '../../common/MonacoInterface';
import { HelperService } from "@services/HelperService";
import { IProcessDetDemEquipment } from 'WBA-Model/dist/interface/dataProcess/ProcessDetDemEquipment';
import { INewProcessDetDemScope } from './NewProcessDetDemController';

const TypeNegotiationChange = {
    "FREE_TIME": 1,
    "PER_DIEM": 2,
    "ALL": 3
};
interface IConfirmSaveDetDemDetailsModal {
    detDemEquipmentTotalReason: ISelectorModel;
    detDemEquipmentFreeTimeReason: ISelectorModel;
    detDemEquipmentTotalObservation: string;
    detDemEquipmentFreeTimeObservation: string;
}

interface INewProcessDetDemEquipmentScope extends ng.IScope {
    form: ng.IFormController;
    log: IViewLog;
    model: IProcessDetDemEquipment;
    oldModel: IProcessDetDemEquipment;
    equipmentModel: IProcessDetDemEquipment;
    oldEquipmentModel: IProcessDetDemEquipment;
    processNumber: string;
    confirmSaveDetDemDetailsModal: IConfirmSaveDetDemDetailsModal;
    detDemEquipmentTotalReasonList: ISelectorModel[];
    detDemEquipmentFreeTimeReasonList: ISelectorModel[];
    collapseCargo: () => void;
    isCollapseIn(): boolean;
    buildDetDemValuesView: (values: ICargoSummary) => string;
    openDetDemDetails: () => void;
    openConfirmSaveDetDemDetails: (isEnabled: true, negotationType?: number) => void;
    hasChanges: () => boolean;
    isParamDetDemValid: (isValid: boolean, type: number) => boolean;
    applyDetDemDetails: () => Promise<void>;
    getEquipmentListByName: (search: string) => Promise<void>;
}

export class NewProcessDetDemEquipmentController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: INewProcessDetDemEquipmentScope;
    private $newProcessDetDemScope: INewProcessDetDemScope;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private $sce: angular.ISCEService;
    private $compile: angular.ICompileService;
    private dataProcessService: DataProcessService;
    private formService: FormService2;
    private sessionService: ISessionService;
    private detDemModalId: number;
    private modalService: IModalService;
    private config: IMonacoConfig
    private helperService: HelperService;
    
    private modalID: number;

    constructor($injector: ng.Injectable<any>, $scope: INewProcessDetDemEquipmentScope) {
        this.$scope = $scope;
        this.$newProcessDetDemScope = <INewProcessDetDemScope>$scope.$parent.$parent;
        this.$q = this.$newProcessDetDemScope.$q;
        this.$sce = this.$newProcessDetDemScope.$sce;
        this.$compile = this.$newProcessDetDemScope.$compile;
        this.$timeout = this.$newProcessDetDemScope.$timeout;
        this.dataProcessService = this.$newProcessDetDemScope.dataProcessService;
        this.formService = this.$newProcessDetDemScope.formService;
        this.sessionService = this.$newProcessDetDemScope.sessionService;
        this.detDemModalId = 0;
        this.modalID = 0;
        this.modalService = this.$newProcessDetDemScope.modalService;
        this.initScopeFunctions();
        this.config = $injector.get('config');
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.initModel();
            await this.initDependencies();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initModel(): void {
    
        this.$scope.model = {
            _id: null,
            DET_DEM_DETAILS: null,
            EQUIPMENT_LIST: null,
            ID: null,
            ID_PROCESS_DET_DEM: null,
            REASON_NEGOTIATED: null,
        };
        
        this.$scope.oldModel = angular.copy(this.$scope.model);
    }

    private initScopeFunctions(): void {

        this.$scope.collapseCargo = (): void => {
            this.collapseCargo();
        }

        this.$scope.isCollapseIn = (): boolean => {
            return this.$newProcessDetDemScope.collapseState.panel == ECollapseState.CARGO && !this.$newProcessDetDemScope.collapseState.released;
        }

        this.$scope.buildDetDemValuesView = (values: ICargoSummary): string => {
            return this.buildDetDemValuesView(values);
        }

        this.$scope.openDetDemDetails = () => {
            this.openDetDemDetails();
        }

        this.$scope.openConfirmSaveDetDemDetails = (isEnabled: boolean, negotationType?: number) => {
            this.openConfirmSaveDetDemDetails(isEnabled, negotationType);
        }

        this.$scope.isParamDetDemValid = (isValid: boolean, type: number): boolean => {
            if (isValid && isValid != undefined) return true;
            if (isValid == undefined && type == undefined) return true;
            if (type && type == undefined) return true;
            return false;
        }

        this.$scope.applyDetDemDetails = async (): Promise<void> => {
            await this.applyDetDemDetails('edit', true);
        }
    }

    async initDependencies(): Promise<any> {
        const self: NewProcessDetDemEquipmentController = this;

        this.initCollapseEvents();

        this.$scope.$watch(() => this.$newProcessDetDemScope.model.PROCESS_NUMBER, () => {
            this.$scope.processNumber = this.$newProcessDetDemScope.model.PROCESS_NUMBER;
        });

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericList('det_dem_equipment_total_reason'),
                self.getGenericList('det_dem_equipment_free_time_reason'),
            ]).then(async (result: any) => {
                self.$scope.detDemEquipmentTotalReasonList = result[0];
                self.$scope.detDemEquipmentFreeTimeReasonList = result[1];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getGenericList(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private initCollapseEvents() {
        this.$scope.$on('processCargoCollapse', () => {
            this.collapseCargo();
        });

        const collapseCargo = angular.element('#collapseCargo');
        if (collapseCargo) {
            collapseCargo.on('shown.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseCargoHeading").attr('aria-expanded', 'true');
                    // update collapse state
                    this.$newProcessDetDemScope.collapseState = { panel: ECollapseState.CARGO, released: false, nextState: null };
                    this.$newProcessDetDemScope.repositionPanels(ECollapseState.CARGO, true);
                    this.$newProcessDetDemScope.disableElements(this.$newProcessDetDemScope.operation == EOperation.VIEW);

                    this.$timeout(async () => {
                        await this.getProcessTabsCargo();
                        angular.element("#cargoListCards").hide();
                        const collapseCargoList = angular.element("#collapseCargoList");
                        collapseCargoList.on('shown.bs.collapse', (event: JQuery.Event) => {
                            if (event.target == event.currentTarget) {
                                angular.element("#cargoListCards").hide();
                            }
                        });
                        collapseCargoList.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                            if (event.target == event.currentTarget) {
                                angular.element("#cargoListCards").show();
                            }
                        });

                        const collapseSpec = angular.element("#collapseSpec");
                        collapseSpec.on('shown.bs.collapse', (event: JQuery.Event) => {
                            if (event.target == event.currentTarget) {
                                angular.element("#specCards").hide();
                            }
                        });
                        collapseSpec.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                            if (event.target == event.currentTarget) {
                                angular.element("#specCards").show();
                            }
                        });
                    });
                }
            });
            collapseCargo.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseCargoHeading").attr('aria-expanded', 'false');
                    this.$scope.model = null;
                    this.$scope.oldModel = null;
                }
            });
        }
    }

    private buildDetDemValuesView(values: ICargoSummary) {
        let detDem = "";
        if (values) {
            if (values.DET || values.DEM) {
                if (values.DET) detDem += values.DET.toString();
                if (values.DEM) detDem += " + " + values.DEM.toString();
            } else if (values.COMBINED) {
                detDem += values.COMBINED.toString();
            }
        }
        return detDem;
    }

    private async openDetDemDetails(): Promise<void> {
        try {
            const response = await this.dataProcessService.get(`/processDetDem/${this.$newProcessDetDemScope.model.ID}/equipment`, null, 30000);
            const processDetDemEquipment = response.data && response.data.data ? response.data.data : null;
            if (processDetDemEquipment && !processDetDemEquipment.DET_DEM_DETAILS) return this.formService.notifyError("detDemDetails is null.");

            this.$scope.equipmentModel = processDetDemEquipment;
            this.$scope.oldEquipmentModel = angular.copy(this.$scope.equipmentModel);

            this.detDemModalId = this.modalService.newModal();
            const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                {
                    modalID: this.detDemModalId,
                    scope: this.$scope,
                    formService: this.$newProcessDetDemScope.operation,
                    size: 'lg modal-overflow',
                    template: require("../view/modal/processDetDemEquipmentDetailsModal.html"),
                    keyboard: true,
                },
                null
            );

            const endpoint = `${this.config.productUrl}/product/tariffDetDem/getCacheById`;
            await modalInstance.rendered.then(() => {
                angular.element(".openTariffDetDemOriginPayment").bind('click', () => {
                    const tariffId = this.$scope.model.DET_DEM_DETAILS.DET_DEM_ORIGIN.ID_PAYMENT ? this.$scope.equipmentModel.DET_DEM_DETAILS.DET_DEM_ORIGIN.ID_PAYMENT.toString() : null;
                    this.sessionService.openTabByValidity(`${endpoint}/${tariffId}`, "app.product.tariffDetDem", <ILinkParameter>{ ID: tariffId });
                });

                angular.element(".openTariffDetDemOriginReceiving").bind('click', () => {
                    const tariffId = this.$scope.equipmentModel.DET_DEM_DETAILS.DET_DEM_ORIGIN.ID_RECEIVING ? this.$scope.equipmentModel.DET_DEM_DETAILS.DET_DEM_ORIGIN.ID_RECEIVING.toString() : null;
                    this.sessionService.openTabByValidity(`${endpoint}/${tariffId}`, "app.product.tariffDetDem", <ILinkParameter>{ ID: tariffId });
                });

                angular.element(".openTariffDetDemDestinationPayment").bind('click', () => {
                    const tariffId = this.$scope.equipmentModel.DET_DEM_DETAILS.DET_DEM_DESTINATION ? this.$scope.equipmentModel.DET_DEM_DETAILS.DET_DEM_DESTINATION.ID_PAYMENT.toString() : null;
                    this.sessionService.openTabByValidity(`${endpoint}/${tariffId}`, "app.product.tariffDetDem", <ILinkParameter>{ ID: tariffId });
                });

                angular.element(".openTariffDetDemDestinationReceiving").bind('click', () => {
                    const tariffId = this.$scope.equipmentModel.DET_DEM_DETAILS.DET_DEM_DESTINATION ? this.$scope.equipmentModel.DET_DEM_DETAILS.DET_DEM_DESTINATION.ID_RECEIVING.toString() : null;
                    this.sessionService.openTabByValidity(`${endpoint}/${tariffId}`, "app.product.tariffDetDem", <ILinkParameter>{ ID: tariffId });
                });
            });

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async openConfirmSaveDetDemDetails(isEnabled: boolean, negotationType?: number): Promise<boolean> {
        let proceed = false;
        this.$scope.confirmSaveDetDemDetailsModal = { 
            detDemEquipmentTotalReason: null,
            detDemEquipmentFreeTimeReason: null,
            detDemEquipmentFreeTimeObservation: null,
            detDemEquipmentTotalObservation: null
        };

        try {
            let body = `
            <div id="reasonModal">
                <div class="row" ng-show="${negotationType != TypeNegotiationChange.PER_DIEM}">
                    <div class="col-lg-12">
                        <label> Motivo da Negociação do Free Time </label>
                        <ui-select name="detDemEquipmentFreeTimeReason" id="detDemEquipmentFreeTimeReason"
                            ng-model="${isEnabled ? "confirmSaveDetDemDetailsModal.detDemEquipmentFreeTimeReason" : "model.REASON_NEGOTIATED.FREE_TIME_REASON"}"
                            theme="bootstrap"
                            ng-change="selectorValidity(this.$select.ngModel.$name);"
                            ng-disabled="${isEnabled ? "false" : "true"} || operation == 'view'"
                            ng-click="selectorFocus(this.$select.searchInput[0]);"
                            skip-focusser="true" required>
                            <ui-select-match placeholder="{{'GENERAL.UI_SELECT.SELECT' | translate }}">
                                {{$select.selected.NAME}}
                            </ui-select-match>
                            <ui-select-choices
                                repeat="item in detDemEquipmentFreeTimeReasonList | filter: $select.search track by $index">
                                <div
                                    ng-bind-html="item.NAME | highlight: $select.search">
                                </div>
                            </ui-select-choices>
                            <ui-select-no-choice>
                                {{'GENERAL.UI_SELECT.EMPTY_SELECTOR_MESSAGE' | translate }}
                            </ui-select-no-choice>
                        </ui-select>
                    </div>
                    <div class="col-lg-12">
                        <label> Observações da Negociação do Free Time </label>
                        <textarea class="form-control" name="detDemEquipmentFreeTimeObservation"
                            ng-model="${isEnabled ? "confirmSaveDetDemDetailsModal.detDemEquipmentFreeTimeObservation" : "model.REASON_NEGOTIATED.OBSERVATION_FREE_TIME"}"
                            ng-disabled="${isEnabled ? "false" : "true"} || operation == 'view'"></textarea>
                    </div>
                </div>
                <div class="row" ng-show="${negotationType != TypeNegotiationChange.FREE_TIME}">
                    <div class="col-lg-12">
                        <label> Motivo da Negociação do Per Diem </label>
                        <ui-select name="detDemEquipmentTotalReason" id="detDemEquipmentTotalReason"
                            ng-model="${isEnabled ? "confirmSaveDetDemDetailsModal.detDemEquipmentTotalReason" : "model.REASON_NEGOTIATED.TOTAL_VALUE_REASON"}"
                            theme="bootstrap"
                            ng-change="selectorValidity(this.$select.ngModel.$name);"
                            ng-disabled="${isEnabled ? "false" : "true"} || operation == 'view'"
                            ng-click="selectorFocus(this.$select.searchInput[0]);"
                            skip-focusser="true" required>
                            <ui-select-match placeholder="{{'GENERAL.UI_SELECT.SELECT' | translate }}">
                                {{$select.selected.NAME}}
                            </ui-select-match>
                            <ui-select-choices
                                repeat="item in detDemEquipmentTotalReasonList | filter: $select.search track by $index">
                                <div
                                    ng-bind-html="item.NAME | highlight: $select.search">
                                </div>
                            </ui-select-choices>
                            <ui-select-no-choice>
                                {{'GENERAL.UI_SELECT.EMPTY_SELECTOR_MESSAGE' | translate }}
                            </ui-select-no-choice>
                        </ui-select>
                    </div>
                    <div class="col-lg-12">
                        <label> Observações da Negociação do Per Diem </label>
                        <textarea class="form-control" name="detDemEquipmentTotalObservation"
                            ng-model="${isEnabled ? "confirmSaveDetDemDetailsModal.detDemEquipmentTotalObservation" : "model.REASON_NEGOTIATED.OBSERVATION_TOTAL_VALUE"}"
                            ng-disabled="${isEnabled ? "false" : "true"} || operation == 'view'"></textarea>
                    </div>
                </div>
            </div>
            `;

            const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                {
                    modalID: this.modalID,
                    scope: this.$scope,
                    formService: 'register',
                    size: 'md',
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing" && closed && isEnabled) {
                            if (!this.$scope.confirmSaveDetDemDetailsModal 
                                || (this.$scope.confirmSaveDetDemDetailsModal 
                                    && (!this.$scope.confirmSaveDetDemDetailsModal.detDemEquipmentFreeTimeReason && (negotationType == TypeNegotiationChange.ALL || negotationType == TypeNegotiationChange.FREE_TIME)) 
                                    || (!this.$scope.confirmSaveDetDemDetailsModal.detDemEquipmentTotalReason && (negotationType == TypeNegotiationChange.ALL || negotationType == TypeNegotiationChange.PER_DIEM)))) {
                                event.preventDefault();
                                const msg = this.formService.getTranslate('BASIC_DATA.IT_IS_NECESSARY_TO_INFORM_THE_REASON_TO_CONTINUE');
                                this.formService.notifyError(msg);
                            }
                        }
                    }
                },
                {
                    closeButtonText: isEnabled ? 'GENERAL.CANCEL' : '',
                    actionButtonText: isEnabled ? 'REGISTRATION.APPLY' : 'GENERAL.CLOSE',
                    headerText: 'Motivo das Negociações Do Free Time e Per Diem',
                    bodyText: this.$sce.trustAsHtml(body),

                }
            );

            modalInstance.rendered.then(() => {
                const reasonModal = angular.element("#reasonModal");
                if (reasonModal) this.$compile(reasonModal)(this.$scope);

                const detDemEquipmentTotalReasonSelect = angular.element("#detDemEquipmentTotalReason");
                if (detDemEquipmentTotalReasonSelect) this.$compile(detDemEquipmentTotalReasonSelect)(this.$scope);

                const detDemEquipmentFreeTimeReasonSelect = angular.element("#detDemEquipmentFreeTimeReason");
                if (detDemEquipmentFreeTimeReasonSelect) this.$compile(detDemEquipmentFreeTimeReasonSelect)(this.$scope);
            });

            proceed = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
        return proceed;
    }

    private async collapseCargo() {
        try {
            if (this.$newProcessDetDemScope.collapseState.released || this.$newProcessDetDemScope.collapseState.panel == ECollapseState.CARGO) {
                const collapseCargo = angular.element("#collapseCargo");
                if (collapseCargo) {
                    const isCollapsed = angular.element("#collapseCargoHeading").attr("aria-expanded") == "true";
                    if (isCollapsed) {
                        this.$newProcessDetDemScope.collapseState.released = true;
                    }
                    collapseCargo['collapse']('toggle');
                    if (isCollapsed) this.$newProcessDetDemScope.repositionPanels(ECollapseState.CARGO);
                }
            } else {
                this.$newProcessDetDemScope.collapseState.nextState = ECollapseState.CARGO;
                this.$newProcessDetDemScope.releaseCollapse();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getProcessTabsCargo(): Promise<void> {
        this.formService.block();
        try {
            const cargoTab = await this.dataProcessService.get(`/processDetDem/${this.$newProcessDetDemScope.model.ID}/equipment`, 30000);
            if (cargoTab && cargoTab.data && cargoTab.data.data) this.$scope.model = cargoTab.data.data;

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            
            this.$scope.oldModel = angular.copy(this.$scope.model);
            this.formService.unblock();
        }
    }

    private async saveDetDemDetails(operation: string, closeModal?: boolean): Promise<boolean> {
        let success = false;
        try {
            if (!this.$scope.equipmentModel.DET_DEM_DETAILS) throw Error('detDemDetails is null');
            this.formService.block();
            await this.beforeSaveDetDemDetails();
            if (operation === "edit") {
                this.$scope.equipmentModel.REASON_NEGOTIATED = {
                    FREE_TIME_REASON: (this.$scope.confirmSaveDetDemDetailsModal.detDemEquipmentFreeTimeReason ? this.$scope.confirmSaveDetDemDetailsModal.detDemEquipmentFreeTimeReason : (this.$scope.equipmentModel.REASON_NEGOTIATED && this.$scope.equipmentModel.REASON_NEGOTIATED.FREE_TIME_REASON) ? this.$scope.equipmentModel.REASON_NEGOTIATED.FREE_TIME_REASON : null),
                    TOTAL_VALUE_REASON: (this.$scope.confirmSaveDetDemDetailsModal.detDemEquipmentTotalReason ? this.$scope.confirmSaveDetDemDetailsModal.detDemEquipmentTotalReason : (this.$scope.equipmentModel.REASON_NEGOTIATED && this.$scope.equipmentModel.REASON_NEGOTIATED.TOTAL_VALUE_REASON) ? this.$scope.equipmentModel.REASON_NEGOTIATED.TOTAL_VALUE_REASON : null),
                    OBSERVATION_FREE_TIME: (this.$scope.confirmSaveDetDemDetailsModal.detDemEquipmentFreeTimeObservation ? this.$scope.confirmSaveDetDemDetailsModal.detDemEquipmentFreeTimeObservation : (this.$scope.equipmentModel.REASON_NEGOTIATED && this.$scope.equipmentModel.REASON_NEGOTIATED.OBSERVATION_FREE_TIME) ? this.$scope.equipmentModel.REASON_NEGOTIATED.OBSERVATION_FREE_TIME : null),
                    OBSERVATION_TOTAL_VALUE: (this.$scope.confirmSaveDetDemDetailsModal.detDemEquipmentTotalObservation ? this.$scope.confirmSaveDetDemDetailsModal.detDemEquipmentTotalObservation : (this.$scope.equipmentModel.REASON_NEGOTIATED && this.$scope.equipmentModel.REASON_NEGOTIATED.OBSERVATION_TOTAL_VALUE) ? this.$scope.equipmentModel.REASON_NEGOTIATED.OBSERVATION_TOTAL_VALUE : null),
                }

                const paramsDetDems = await this.dataProcessService.post(`/processDetDem/processDetDemEquipment/update`, { idProcess: this.$newProcessDetDemScope.model.ID_PROCESS, data: this.$scope.equipmentModel, oldData: this.$scope.oldEquipmentModel }, 30000);

                if (paramsDetDems && paramsDetDems.status == 200) {
                    success = true;
                    await this.$timeout(async () => { await this.getProcessTabsCargo() }, 3000);         
                    const msg = this.formService.getTranslate('BASIC_DATA.CARGO_TAB_DATA_SUCESSFULLY_SAVED');
                    this.formService.notifySuccess(msg);
                }
                else success = false;
            }
            if (closeModal) {
                this.modalService.closeModal(this.detDemModalId);
                this.detDemModalId = 0;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return success;
        }
    }

    private async beforeSaveDetDemDetails() {
        try {
            const modalScope = await this.modalService.getModalScope(this.detDemModalId);
            modalScope.$applyAsync();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private encontrarDiferenca(arr1, arr2, property) {
        for (let i = 0; i < arr1.length; i++) {
          if (arr1[i][property] !== arr2[i][property]) {
            return property;
          }
        }
        // Se nenhum objeto com propriedades diferentes for encontrado, retorne null ou uma mensagem indicando que não há diferenças.
        return null; // ou 'Sem diferenças encontradas'
      }

    private async applyDetDemDetails(operation: string, closeModal?: boolean): Promise<void> {
        if (this.$newProcessDetDemScope.hasChanges(JSON.stringify(this.$scope.equipmentModel), JSON.stringify(this.$scope.oldEquipmentModel))) {

            try {
                let negotationType: number = 0;
                let a = (this.$scope.equipmentModel && this.$scope.equipmentModel.DET_DEM_DETAILS && this.$scope.equipmentModel.DET_DEM_DETAILS.DET_DEM_DESTINATION ?  this.$scope.equipmentModel.DET_DEM_DETAILS.DET_DEM_DESTINATION.DETAILS : null);
                let b = (this.$scope.oldEquipmentModel && this.$scope.oldEquipmentModel.DET_DEM_DETAILS && this.$scope.oldEquipmentModel.DET_DEM_DETAILS.DET_DEM_DESTINATION ?  this.$scope.oldEquipmentModel.DET_DEM_DETAILS.DET_DEM_DESTINATION.DETAILS : null);

                if (a && b) {
                    if (this.encontrarDiferenca(a, b, "FREE_TIME_NEGOTIATED")) {
                        negotationType = TypeNegotiationChange.FREE_TIME;
                    }

                    for (let i = 0; i < a.length; i++) {
                        if (a[i].FREE_TIME_DETAILS && b[i].FREE_TIME_DETAILS) {
                            if (this.encontrarDiferenca(a[i].FREE_TIME_DETAILS, b[i].FREE_TIME_DETAILS, "TOTAL_VALUE_NEGOTIATED")) {
                                if (negotationType == TypeNegotiationChange.FREE_TIME) {
                                    negotationType = TypeNegotiationChange.ALL;
                                } else {
                                    negotationType = TypeNegotiationChange.PER_DIEM;
                                }
                            }
                        }
                    }
                }

                if (negotationType != TypeNegotiationChange.ALL) {
                    a = (this.$scope.equipmentModel && this.$scope.equipmentModel.DET_DEM_DETAILS && this.$scope.equipmentModel.DET_DEM_DETAILS.DET_DEM_ORIGIN ?  this.$scope.equipmentModel.DET_DEM_DETAILS.DET_DEM_ORIGIN.DETAILS : null);
                    b = (this.$scope.oldEquipmentModel && this.$scope.oldEquipmentModel.DET_DEM_DETAILS && this.$scope.oldEquipmentModel.DET_DEM_DETAILS.DET_DEM_ORIGIN ?  this.$scope.oldEquipmentModel.DET_DEM_DETAILS.DET_DEM_ORIGIN.DETAILS : null);
                    
                    if (a && b) {
                        if (negotationType != TypeNegotiationChange.FREE_TIME) {
                            if (this.encontrarDiferenca(a, b, "FREE_TIME_NEGOTIATED")) {
                                if (negotationType == TypeNegotiationChange.PER_DIEM) {
                                    negotationType = TypeNegotiationChange.ALL;
                                } else {
                                    negotationType = TypeNegotiationChange.FREE_TIME;
                                }
                            }
                        }
                        
                        if (negotationType != TypeNegotiationChange.ALL && negotationType != TypeNegotiationChange.PER_DIEM) {
                            for (let i = 0; i < a.length; i++) {
                                if (a[i].FREE_TIME_DETAILS && b[i].FREE_TIME_DETAILS) {
                                    if (this.encontrarDiferenca(a[i].FREE_TIME_DETAILS, b[i].FREE_TIME_DETAILS, "TOTAL_VALUE_NEGOTIATED")) {
                                        if (negotationType == TypeNegotiationChange.FREE_TIME) {
                                            negotationType = TypeNegotiationChange.ALL;
                                        } else {
                                            negotationType = TypeNegotiationChange.PER_DIEM;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                const confirm = await this.openConfirmSaveDetDemDetails(true, negotationType);
                if (confirm) {
                    await this.saveDetDemDetails(operation, closeModal);
                    return; 
                } 
            } catch (ex) {
                this.formService.handleError(ex);
            }
        }
    }
}
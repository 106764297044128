import * as angular from 'angular';
import { NotificationService } from "@services/NotificationService";
import { BatchItem } from "@services/BatchViewService";
import config from "../../bootstrap/Config";


export abstract class HandleError {

    public static buildErrorMessage(error: any): any {
        try {
            let errorInfo = undefined;
            let warning = undefined;

            if (config.environment == 'dev' || config.environment == 'qa') console.log(error);

            if (typeof (error) === 'object') {
                warning = (error.status && (error.status > 401 && error.status < 500)) ? true : false;

                if (angular.isUndefined(errorInfo)) {
                    if (error.status && error.status === -1) {
                        errorInfo = '<b>Something went wrong</b>: Server Timeout';
                    } else if (error.status && error.status === 401) {
                        //do not print 401 errors
                        return null;
                    } else if (error.statusText && error.statusText === 'invalid') {
                        errorInfo = `<b>Something went wrong</b>: Invalid Route:\n${error.config.url}`;
                    } else if (error instanceof Error) {
                        errorInfo = `<b>${error.name}</b>: ${error.message}`;
                    } else if (error.data) {
                        const data = error.data;
                        if (data.data && data.data.data) {
                            errorInfo = `<b>Something went wrong</b>: ${(typeof (data.data.data) === 'object' ? JSON.stringify(data.data.data) : data.data.data)}`;
                        } else {
                            if (typeof (data) == 'string')
                                errorInfo = data;
                            else if (typeof (data.data) == 'string') {
                                errorInfo = data.data;
                            } else if (typeof data.data === 'object' && data.data.hasOwnProperty('batch')) {
                                const batchResponse: BatchItem[] = data.data['batch'];
                                const batchError = batchResponse.find(x => x.failed);
                                if (batchError && batchError.failed.error) errorInfo = batchError.failed.error['data'];
                                else errorInfo = `<b>Something went wrong</b>: ${JSON.stringify(data)}`;
                            } else if (data.message) {
                                errorInfo = `<b>${data.message}</b>: ${(data.reason) ? data.reason + ' - ' : ''}Please, contact IT Support`;
                            } else {
                                errorInfo = `<b>Something went wrong</b>: ${JSON.stringify(data)}`;
                            }
                        }

                        if (warning) errorInfo = (error.data.data) ? error.data.data : (error.data.reason) ? error.data.reason : errorInfo;

                    } else {
                        errorInfo = `<b>Something went wrong</b>: ${JSON.stringify(error)}`;
                    }

                }

            } else if (typeof (error) === 'string') {
                errorInfo = error;
            } else {
                errorInfo = (`Typeof error is unknown, type: ${typeof (error)}, error: ${error} `);
            }

            return { type: (warning) ? 'warning' : error, msg: errorInfo };

        } catch (ex) {
            console.log('UNHANDLED EXCEPTION: ', ex);
            return null;
        }
    }

    public static exception(error: any, clickEvent?: Function, options?: object): void {
        try {
            const data = this.buildErrorMessage(error);
            if (!data) return;

            if (data.type === 'warning') NotificationService().warning(data.msg, clickEvent, options);
            else NotificationService().error(data.msg, clickEvent, options);

        } catch (ex) {
            console.log('UNHANDLED EXCEPTION: ', ex);
        }
    }

    public static warning(message: any, clickEvent?: Function, options?: object): void {
        try {

            let warningInfo = undefined;

            if (config.environment == 'dev' || config.environment == 'qa') console.log(message);

            if (typeof (message) === 'string') {
                warningInfo = message;
            } else {
                warningInfo = (`Typeof warning is unknown, type: ${typeof (message)}, error: ${message} `);
            }

            if (warningInfo) NotificationService().warning(warningInfo, clickEvent, options);

        } catch (ex) {
            console.log('UNHANDLED EXCEPTION: ', ex);
        }
    }

}
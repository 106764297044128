import * as angular from 'angular';
import { INotificationService } from '@services/NotificationService';
import { IRestService } from '@services/RestService';
import { FormService2 } from '@services/FormService2';
import { CellRoleModel } from '@models/interface/common/CellModel';
import { IScopeRole } from './../interface/ICellParameterization';
import { HandleError } from '../../common/util/HandleError';
import { NotificationConstants } from '../../common/util/NotificationConstants';
import { OperationalService } from '@services/OperationalService';


RoleRegisterController.$inject = ['$scope', 'NotificationService', 'OperationalService', 'RestService', 'blockUI', '$timeout'];
export function RoleRegisterController($scope: IScopeRole, NotificationService: INotificationService, operationalService: OperationalService, RestService: IRestService, blockUI: ng.blockUI.BlockUIService, $timeout: ng.ITimeoutService) {
    //HOOKS
    this.$onInit = function () {
        resetRole();
    }

    this.$onDestroy = function () { }


    //WATCHES
    $scope.$parent.$watch('role', (newRole: CellRoleModel) => {
        $scope.role = angular.copy(newRole);
    });

    $scope.$parent.$watch('roleOperation', () => {
        $scope.operation = $scope.$parent.roleOperation;
    });

    //CRUD
    $scope.add = async () => {
        try {
            blockUI.start();

            const isInvalid = FormService2.hasRequiredElements('#roleModal');
            if (isInvalid) {
                $scope.$applyAsync(function () { blockUI.stop(); });
                NotificationService.error('Preencha todos os campos obrigatórios (*).');
                return;
            }

            const newRole: CellRoleModel = {
                _id: null,
                ID: null,
                ROLE_NUMBER: null,
                NAME: $scope.role.NAME,
                DESCRIPTION: $scope.role.DESCRIPTION,
                CREATED_DATE: new Date(),
            };

            //send request to server
            const result = await saveCellRole('register', newRole);
            //validate response
            if (!result) {
                $scope.$applyAsync(function () { blockUI.stop(); });
                return NotificationService.error('Falha ao adicionar novo Papel, tente novamente.');
            }

            reloadRoles();
            resetRole();
            closeModal();

            $scope.$applyAsync(function () { blockUI.stop(); });

            NotificationService.success(NotificationConstants.SAVE_SUCCESS);

        } catch (ex) {
            $scope.$applyAsync(function () { blockUI.stop(); });
            HandleError.exception(ex);
        }
    }

    $scope.update = async () => {
        try {
            blockUI.start();

            const isInvalid = FormService2.hasRequiredElements('#roleModal');

            if (isInvalid) {
                NotificationService.error('Preencha todos os campos obrigatórios (*).');
                return;
            }

            //send request to server
            const result = await saveCellRole('edit', $scope.role);
            //validate response
            if (!result) {
                $scope.$applyAsync(function () { blockUI.stop(); });
                return NotificationService.error('Falha ao atualizar o Papel, tente novamente.');
            }

            reloadRoles();
            resetRole();
            closeModal();

            $scope.$applyAsync(function () { blockUI.stop(); });

            NotificationService.success(NotificationConstants.UPDATE_SUCCESS);

        } catch (ex) {
            $scope.$applyAsync(function () { blockUI.stop(); });
            HandleError.exception(ex);
        }
    }


    //AUXS
    async function saveCellRole(operation: string, role: CellRoleModel): Promise<any> {
        if (!operation || !role) return null;

        try {

            blockUI.start();

            //create the request object
            const timeout = 10000;
            const request = {
                operation: operation,
                data: role
            };

            let result = await operationalService.post('/cell/role/insert', request, timeout);
            result = (result && result.data && result.data.data) ? result.data.data : null;

            $scope.$applyAsync(function () { blockUI.stop(); });

            return result;
        } catch (ex) {
            $scope.$applyAsync(function () { blockUI.stop(); });
            HandleError.exception(ex);

            return null;
        }
    }

    function closeModal(): void {
        $scope.$parent.modalOptions.ok();
    }

    function reloadRoles(): void {
        $scope.$parent.loadRoles();
    }

    function resetRole() {

        const role: CellRoleModel = {
            _id: null,
            ID: null,
            ROLE_NUMBER: null,
            NAME: null,
            DESCRIPTION: null,
            CREATED_DATE: null,
        }

        $scope.role = role;
    }

};
export interface ISearchBehaviorControl {
    isSearchTabDataListCanceled: boolean;
    searchTabDataError: string[];
    isSearchingTabDataList: boolean;
    searchTabDataListCount: number;
    searchTabDataCurrentAttempt: number;
    searchTabDataMaxAttempt: number;
}

export interface IDateOptions {
    formatYear: string
    maxDate: Date
    minDate: Date
    startingDay: number
}

export enum EHistoryColor {
    FREIGHT_CONTRACT = "#A3BCD2",
    FREIGHT_ROUTES = "#C3BFC2",
    FREIGHT_ROUTES_CHARGES = "#C7CED5"
}

export interface IChargeColStyle {
    chargeName: Object;
    application: Object;
    weightAndEquipment: Object;
    validityStart: Object;
    validityEnd: Object;
    paymentCurrency: Object;
    paymentUnitary: Object;
    paymentMin: Object;
    receivingCurrency: Object;
    receivingUnitary: Object;
    receivingMin: Object;
    fontSize: Object;
}

export interface IStatusFreightWizardRoutes {
    color: EStatusColor;
    tooltipText?: EStatusTooltipText;
}

export enum EFreightWizardFilterActionsID {
    MODIFY_CHARGE_VALIDITY = "1",
    MODIFY_CHARGE_VALUES = "2",
    MODIFY_CHARGE_VALIDITY_AND_VALUES = "3",
    ADD_CHARGE = "4"
}

export enum EFreightWizardFilterActionsName {
    MODIFY_CHARGE_VALIDITY = "Alteração de validade em taxas",
    MODIFY_CHARGE_VALUES = "Alteração de valores em taxas",
    MODIFY_CHARGE_VALIDITY_AND_VALUES = "Alteração de validade e valores em taxas",
    ADD_CHARGE = "Incluir nova taxa",
}

export const freightWizardFilterActions = {
    MODIFY_CHARGE_VALIDITY: {
        ID: EFreightWizardFilterActionsID.MODIFY_CHARGE_VALIDITY,
        NAME: EFreightWizardFilterActionsName.MODIFY_CHARGE_VALIDITY
    },
    MODIFY_CHARGE_VALUES: {
        ID: EFreightWizardFilterActionsID.MODIFY_CHARGE_VALUES,
        NAME: EFreightWizardFilterActionsName.MODIFY_CHARGE_VALUES
    },
    MODIFY_CHARGE_VALIDITY_AND_VALUES: {
        ID: EFreightWizardFilterActionsID.MODIFY_CHARGE_VALIDITY_AND_VALUES,
        NAME: EFreightWizardFilterActionsName.MODIFY_CHARGE_VALIDITY_AND_VALUES
    },
    ADD_CHARGE: {
        ID: EFreightWizardFilterActionsID.ADD_CHARGE,
        NAME: EFreightWizardFilterActionsName.ADD_CHARGE
    },
}

export interface IFreightWizardUiTabSteps {
    current: EFreightWizardTabStep;
    percent: EFreightWizardTabStep;
    lastStep: EFreightWizardTabStep;
    firstStep: EFreightWizardTabStep;
}

export enum EFreightWizardTabStep {
    ROUTES_TAB = 0,
    VIGENCY_TAB = 1,
    PREVIEW = 2,
    CONFIRMATION = 3
}

export enum EStatusTooltipText {
    ALERT = 'Esta rota está com sua validade expirada',
    WARNING = 'Esta rota não tem sua validade compreendida integralmente com a validade do contrato.',
}

export enum EStatusColor {
    ALERT = 'orangered',
    WARNING = 'yellow',
    VALID = 'transparent'
}
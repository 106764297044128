import * as angular from 'angular';

const registrationState = {
    name: 'app.registration',
    url: '/registration',
    abstract: true,
    data: {
        /* Solução de carregar os termos por módulo
        i18n: ['general', 'registration', 'route', 'product', 'financial', 'basic_data', 'entity', 'operational'],
        */
        requiresAuth: true,
        permissions: {
            except: 'EXTERNAL',
            redirectTo: 'app.denied'
        }
    }
}

const registrationMenu = {
    name: 'app.registration.menu',
    url: '/menu',
    views: {
        'content@app': {
            template: require("./view/registrationMenu.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.REGISTER',
        parent: 'app.registration'
    }
}

const tradeLaneState = {
    name: 'app.registration.tradelane',
    url: '/tradelane',
    views: {
        'content@app': {
            template: require("./view/tradeLaneRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.TRADE_LANE',
        parent: 'app.registration.menu'
    }
}

const incotermState = {
    name: 'app.registration.incoterm',
    url: '/incoterm',
    views: {
        'content@app': {
            template: require("./view/incotermRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.INCOTERM',
        parent: 'app.registration.menu'
    }
}

const equipmentState = {
    name: 'app.registration.equipment',
    url: '/equipment',
    views: {
        'content@app': {
            template: require("./view/equipmentRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.EQUIPMENT',
        parent: 'app.registration.menu'
    }
}

const networkState = {
    name: 'app.registration.network',
    url: '/network',
    views: {
        'content@app': {
            template: require("./view/networkRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.NETWORK',
        parent: 'app.registration.menu'
    }
}

const legalPersonState = {
    name: 'app.registration.legalPerson',
    url: '/legalPerson',
    views: {
        'content@app': {
            template: require("./view/legalPersonRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.LEGAL_PERSON',
        parent: 'app.registration.menu'
    }
}

const physicalPersonState = {
    name: 'app.registration.physicalPerson',
    url: '/physicalPerson',
    views: {
        'content@app': {
            template: require("./view/physicalPersonRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.PHYSICAL_PERSON',
        parent: 'app.registration.menu'
    }
}

const shipState = {
    name: 'app.registration.ship',
    url: '/ship',
    views: {
        'content@app': {
            template: require("./view/shipRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.SHIP',
        parent: 'app.registration.menu'
    }
}

const reportAutomaticRulesState = {
    name: 'app.registration.reportAutomaticRules',
    url: '/reportAutomaticRules',
    views: {
        'content@app': {
            template: require("./view/reportAutomaticRulesRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.AUTOMATION_RULES',
        parent: 'app.registration.menu'
    }
}

const reportTemplateState = {
    name: 'app.registration.reportTemplate',
    url: '/reportTemplate',
    views: {
        'content@app': {
            template: require("./view/reportTemplateRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.REPORT_MODEL',
        parent: 'app.registration.menu'
    }
}

const slaDeadlineState = {
    name: 'app.registration.slaDeadline',
    url: '/slaDeadline',
    views: {
        'content@app': {
            template: require("./view/slaDeadlineRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    lazyLoad: ($transition$) => {
        const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
        return import('../operational/OperationalModule')
            .then(module => {
                $ocLazyLoad.load(module.OPERATIONAL_MODULE)
            })
            .catch(err => {
                throw new Error("Failed to load OPERATIONAL Module: " + err);
            });
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.SLA_DEADLINE',
        parent: 'app.operational.menu'
    }
}

const deadlineMasterHouseProcessState = {
    name: 'app.registration.deadlineProcess',
    url: '/deadlineProcess',
    views: {
        'content@app': {
            template: require("./view/deadlineProcessRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    lazyLoad: ($transition$) => {
        const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
        return import('../operational/OperationalModule')
            .then(module => {
                $ocLazyLoad.load(module.OPERATIONAL_MODULE)
            })
            .catch(err => {
                throw new Error("Failed to load OPERATIONAL Module: " + err);
            });
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.DEADLINE_PROCESS',
        parent: 'app.operational.menu'
    }
}

const userState = {
    name: 'app.registration.user',
    url: '/user',
    views: {
        'content@app': {
            template: require("./view/usersRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.USER',
        parent: 'app.registration.menu'
    }
}

const eventState = {
    name: 'app.registration.event',
    url: '/event',
    views: {
        'content@app': {
            template: require("./view/eventRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.EVENT',
        parent: 'app.registration.menu'
    }
}

const cellParameter = {
    name: 'app.registration.cellParameter',
    url: '/cellParameter',
    views: {
        'content@app': {
            template: require("./view/cellRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
}

const followUpModel = {
    name: 'app.registration.followUpModel',
    url: '/followUpModel',
    views: {
        'content@app': {
            template: require("./view/followUpModelRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.FOLLOW_UP_MODEL',
        parent: 'app.registration.menu'
    }
}

const followUpNotification = {
    name: 'app.registration.followUpNotification',
    url: '/followUpNotification',
    views: {
        'content@app': {
            template: require("./view/followUpNotificationRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.FOLLOW_UP_NOTIFICATION',
        parent: 'app.registration.menu'
    }
}

const notificationType = {
    name: 'app.registration.notificationType',
    url: '/notificationType',
    views: {
        'content@app': {
            template: require("./view/notificationTypeRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.NOTIFICATION_TYPE',
        parent: 'app.registration.menu'
    }
}

const followUpParams = {
    name: 'app.registration.followUpParams',
    url: '/followUpParams',
    views: {
        'content@app': {
            template: require("./view/followUpParamsRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.FOLLOW_UP_PARAMETERIZATION',
        parent: 'app.registration.menu'
    }
}

const holidayState = {
    name: 'app.registration.holiday',
    url: '/holiday',
    views: {
        'content@app': {
            template: require("./view/holidayRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.HOLIDAY',
        parent: 'app.registration.menu'
    }
}

const currencyState = {
    name: 'app.registration.currency',
    url: '/currency',
    views: {
        'content@app': {
            template: require("./view/currencyRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.CURRENCY',
        parent: 'app.registration.menu'
    }
}

const countryState = {
    name: 'app.registration.country',
    url: '/country',
    views: {
        'content@app': {
            template: require("./view/countryRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.COUNTRY',
        parent: 'app.registration.menu'
    }
}

const provinceState = {
    name: 'app.registration.province',
    url: '/province',
    views: {
        'content@app': {
            template: require("./view/provinceRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.PROVINCE',
        parent: 'app.registration.menu'
    }
}

const cityState = {
    name: 'app.registration.city',
    url: '/city',
    views: {
        'content@app': {
            template: require("./view/cityRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.CITY',
        parent: 'app.registration.menu'
    }
}

const contactState = {
    name: 'app.registration.contact',
    url: '/contact',
    views: {
        'content@app': {
            template: require("./view/contactRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.CONTACT',
        parent: 'app.registration.menu'
    }
}

const serviceLevelState = {
    name: 'app.registration.servicelevel',
    url: '/servicelevel',
    views: {
        'content@app': {
            template: require("./view/serviceLevelRegister.html")
        }
    },
    params: {
        peration: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.SERVICE_LEVEL',
        parent: 'app.registration.menu'
    }
}

const serviceTypeState = {
    name: 'app.registration.servicetype',
    url: '/servicetype',
    views: {
        'content@app': {
            template: require("./view/serviceTypeRegister.html")
        }
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.SERVICE_TYPE',
        parent: 'app.registration.menu'
    }
}

const routingPointState = {
    name: 'app.registration.routingPoint',
    url: '/routingPoint',
    views: {
        'content@app': {
            template: require("./view/routingPointRegister.html")
        }
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.ROUTING_POINT',
        parent: 'app.registration.menu'
    }
}

const weightRangeState = {
    name: 'app.registration.weightRange',
    url: '/weightRange',
    views: {
        'content@app': {
            template: require("./view/weightRangeRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.WEIGHT_RANGE',
        parent: 'app.registration.menu'
    }
}

const applicationState = {
    name: 'app.registration.application',
    url: '/application',
    views: {
        'content@app': {
            template: require("./view/applicationRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.APPLICATION',
        parent: 'app.registration.menu'
    }
}

const bankState = {
    name: 'app.registration.bank',
    url: '/bank',
    views: {
        'content@app': {
            template: require("./view/bankRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.BANK',
        parent: 'app.registration.menu'
    }
}

const commodityState = {
    name: 'app.registration.commodity',
    url: '/commodity',
    views: {
        'content@app': {
            template: require("./view/commodityRegister.html")
        }
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.COMMODITY',
        parent: 'app.registration.menu'
    }
}

const sectorState = {
    name: 'app.registration.sector',
    url: '/sector',
    views: {
        'content@app': {
            template: require("./view/sectorRegister.html")
        }
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.SECTOR',
        parent: 'app.registration.menu'
    }
}

const corporateBranchState = {
    name: 'app.registration.corporateBranch',
    url: '/corporateBranch',
    views: {
        'content@app': {
            template: require("./view/corporateBranchRegister.html")
        }
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'BASIC_DATA.BRANCH',
        parent: 'app.registration.menu'
    }
}

const providerState = {
    name: 'app.registration.provider',
    url: '/provider',
    views: {
        'content@app': {
            template: require("./view/providerRegister.html")
        }
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.PROVIDER',
        parent: 'app.registration.menu'
    }
}

const agencyState = {
    name: 'app.registration.agency',
    url: '/agency',
    views: {
        'content@app': {
            template: require("./view/agencyRegister.html")
        }
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.AGENCY',
        parent: 'app.registration.menu'
    }
}

const agentState = {
    name: 'app.registration.agent',
    url: '/agent',
    views: {
        'content@app': {
            template: require("./view/agentRegister.html")
        }
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.AGENT',
        parent: 'app.registration.menu'
    }
}

const brokerState = {
    name: 'app.registration.broker',
    url: '/broker',
    views: {
        'content@app': {
            template: require("./view/brokerRegister.html")
        }
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.BROKER',
        parent: 'app.registration.menu'
    }
}

const personRoleState = {
    name: 'app.registration.personRole',
    url: '/personRole',
    views: {
        'content@app': {
            template: require("./view/personRoleRegister.html")
        }
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.PERSON_ROLE',
        parent: 'app.registration.menu'
    }
}

const chargeNameState = {
    name: 'app.registration.chargeName',
    url: '/chargeName',
    views: {
        'content@app': {
            template: require("./view/chargeNameRegister.html")
        }
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.LOGISTIC_CHARGES',
        parent: 'app.registration.menu'
    }
}

const chargeNameSubGroupState = {
    name: 'app.registration.chargeNameSubGroup',
    url: '/chargeNameSubGroup',
    views: {
        'content@app': {
            template: require("./view/chargeNameSubGroupRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.SUBGROUP_LOGISTIC_CHARGES',
        parent: 'app.registration.menu'
    }
}

const agentsParamsState = {
    name: 'app.registration.agentsParams',
    url: '/agentsParams',
    views: {
        'content@app': {
            template: require("./view/agentsParamsRegister.html")
        }
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.AGENT_PARAMETERIZATION',
        parent: 'app.registration.menu'
    }
}

const representativeParamsState = {
    name: 'app.registration.representativeParams',
    url: '/representativeParams',
    views: {
        'content@app': {
            template: require("./view/representativeParamsRegister.html")
        }
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'Parametrização de Representantes',
        parent: 'app.registration.menu'
    }
}

const paramsDetDemState = {
    name: 'app.registration.paramsDetDem',
    url: '/paramsDetDem',
    views: {
        'content@app': {
            template: require("./view/paramsDetDemRegister.html")
        }
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.DETENTION_DEMURRAGE_PARAMETERIZATION',
        parent: 'app.registration.menu'
    }
}

const outerCellState = {
    name: 'app.registration.outerCell',
    url: '/outerCell',
    views: {
        'content@app': {
            template: require("./view/outerCellRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.OUTER_CELL',
        parent: 'app.registration.menu'
    }
}

const moveTypeState = {
    name: 'app.registration.moveType',
    url: '/moveType',
    views: {
        'content@app': {
            template: require("./view/moveTypeRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.MOVE_TYPE',
        parent: 'app.registration.menu'
    },
}

const userRolesState = {
    name: 'app.registration.userRoles',
    url: '/userRoles',
    views: {
        'content@app': {
            template: require("./view/userRolesRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.ACCESS_RULES',
        parent: 'app.registration.menu'
    }
}

const userGroupState = {
    name: 'app.registration.userGroup',
    url: '/userGroup',
    views: {
        'content@app': {
            template: require("./view/userGroupRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.USER_GROUP',
        parent: 'app.registration.menu'
    }
}

const ediExternalCodeSetupState = {
    name: 'app.registration.ediExternalCodeSetup',
    url: '/ediExternalCodeSetup',
    views: {
        'content@app': {
            template: require("./view/ediExternalCodeSetup.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.FROM_TO',
        parent: 'app.registration.menu'
    }
}

const ediSession = {
    name: 'app.registration.ediSession',
    url: '/edisession',
    views: {
        'content@app': {
            template: require("./view/ediSessionRegister.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.EDI_SESSION',
        parent: 'app.registration.menu'
    }
}

const ediPartner = {
    name: 'app.registration.ediPartner',
    url: '/edipartner',
    views: {
        'content@app': {
            template: require("./view/ediPartnerRegister.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.EDI_PARTNER',
        parent: 'app.registration.menu'
    }
}

const taskParamRegisterState = {
    name: 'app.registration.param',
    url: '/param',
    views: {
        'content@app': {
            template: require("./view/taskParamRegister.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.ACTIVITY_PARAMETERIZATION',
        parent: 'app.registration.menu'
    }
}

const taskHotspotRegisterState = {
    name: 'app.registration.hotspot',
    url: '/hotspot',
    views: {
        'content@app': {
            template: require("./view/taskHotspotRegister.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.HOT_SPOT_REGISTRATION',
        parent: 'app.registration.menu'
    }
}

const responsibleReasonSettingRegisterState = {
    name: 'app.registration.responsibleReasonSetting',
    url: '/responsiblereasonsetting',
    views: {
        'content@app': {
            template: require("./view/responsibleReasonSettingRegister.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.RESPONSIBLE_REASON_SETTINGS',
    }
}

const bookingPaymentConfig = {
    name: 'app.registration.bookingPaymentConfig',
    url: '/bookingPaymentConfig',
    views: {
        'content@app': {
            template: require("./view/bookingPaymentConfig.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.BOOKING_PAYMENT_CONFIG',
        parent: 'app.registration.menu'
    }
}

const bookingRemarksConfig = {
    name: 'app.registration.bookingRemarksConfig',
    url: '/bookingRemarksConfig',
    views: {
        'content@app': {
            template: require("./view/bookingRemarksConfig.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.BOOKING_REMARKS_CONFIG',
        parent: 'app.registration.menu'
    }
}

const trackingLinkSettings = {
    name: 'app.registration.trackingLinkSettings',
    url: '/trackingLinkSettings',
    views: {
        'content@app': {
            template: require("./view/trackingLinkSettings.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.TRACKING_LINK_SETTINGS',
        parent: 'app.registration.menu'
    }
}

routeConfig.$inject = ['$uiRouterProvider'];
function routeConfig($uiRouterProvider) {
    const $stateRegistry = $uiRouterProvider.stateRegistry;

    $stateRegistry.register(registrationState);
    $stateRegistry.register(registrationMenu);
    $stateRegistry.register(tradeLaneState);
    $stateRegistry.register(incotermState);
    $stateRegistry.register(shipState);
    $stateRegistry.register(equipmentState);
    $stateRegistry.register(userState);
    $stateRegistry.register(userGroupState);
    $stateRegistry.register(userRolesState);
    $stateRegistry.register(eventState);
    $stateRegistry.register(cellParameter);
    $stateRegistry.register(slaDeadlineState);
    $stateRegistry.register(deadlineMasterHouseProcessState);
    $stateRegistry.register(followUpModel);
    $stateRegistry.register(followUpNotification);
    $stateRegistry.register(notificationType);
    $stateRegistry.register(followUpParams);
    $stateRegistry.register(reportAutomaticRulesState);
    $stateRegistry.register(reportTemplateState);
    $stateRegistry.register(holidayState);
    $stateRegistry.register(currencyState);
    $stateRegistry.register(networkState);
    $stateRegistry.register(legalPersonState);
    $stateRegistry.register(physicalPersonState);
    $stateRegistry.register(applicationState);
    $stateRegistry.register(bankState);
    $stateRegistry.register(countryState);
    $stateRegistry.register(provinceState);
    $stateRegistry.register(cityState);
    $stateRegistry.register(contactState);
    $stateRegistry.register(commodityState)
    $stateRegistry.register(serviceLevelState);
    $stateRegistry.register(serviceTypeState);
    $stateRegistry.register(routingPointState);
    $stateRegistry.register(weightRangeState);
    $stateRegistry.register(sectorState);
    $stateRegistry.register(corporateBranchState);
    $stateRegistry.register(providerState);
    $stateRegistry.register(agencyState);
    $stateRegistry.register(agentState);
    $stateRegistry.register(personRoleState);
    $stateRegistry.register(chargeNameState);
    $stateRegistry.register(brokerState);
    $stateRegistry.register(chargeNameSubGroupState);
    $stateRegistry.register(agentsParamsState);
    $stateRegistry.register(paramsDetDemState);
    $stateRegistry.register(outerCellState);
    $stateRegistry.register(moveTypeState);
    $stateRegistry.register(ediExternalCodeSetupState);
    $stateRegistry.register(taskParamRegisterState);
    $stateRegistry.register(taskHotspotRegisterState);
    $stateRegistry.register(representativeParamsState);
    $stateRegistry.register(ediSession);
    $stateRegistry.register(ediPartner);
    $stateRegistry.register(responsibleReasonSettingRegisterState);
    $stateRegistry.register(bookingPaymentConfig);
    $stateRegistry.register(bookingRemarksConfig);
    $stateRegistry.register(trackingLinkSettings);
}

export default function initRoute(moduleName: string): void {
    angular.module(moduleName).config(routeConfig);
}

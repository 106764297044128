import { IPreProcessWizardFilter } from '@models/interface/product/PreProcessWizardFilter';
import { IEquipmentSelector } from '@models/interface/product/EquipmentModel';
import { ISelectorModel } from '@models/mongo/SelectorModel';

export interface IPreProcessWizardModel extends IPreProcessWizardFilter { };

export interface ISearchBehaviorControl {
    isSearchTabDataListCanceled: boolean;
    searchTabDataError: string[];
    isSearchingTabDataList: boolean;
    searchTabDataListCount: number;
    searchTabDataCurrentAttempt: number;
    searchTabDataMaxAttempt: number;
};

export interface IAgentSelector extends ISelectorModel {
    LEGAL_PERSON_ID: number;
};

export interface IAccountSelector extends ISelectorModel {
    LEGAL_PERSON_ID: number;
};

export interface ICustomerSelector extends ISelectorModel {
    LEGAL_PERSON_ID: number;
};

export interface IProcessEquipmentSelector {
    EQUIPMENT: IEquipmentSelector
    QUANTITY: number;
};

export interface ICommodityModel extends ISelectorModel {
    HS_CODE: string;
};

export interface IUiTabSteps {
    current: number;
    percent: number;
    lastStep: number;
};

export enum ETabStep {
    PRE_TAB = 0,
    ROUTES_TAB = 1,
    CARGO_TAB = 2,
    SPECIFICITY_TAB = 3,
    PREVIEW_TAB = 4,
    CONFIRMATION_TAB = 5
};

import { NotificationService } from "@services/NotificationService";
import { StringUtil } from "./StringUtil";
import * as moment from 'moment';
import { NotificationConstants } from "./NotificationConstants";

export abstract class ValidateUtil {

    public static isValidEmail(email: string): boolean {
        const regex = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/, 'g');
        return regex.test(email);
    }

    public static isAWBNumber(awbNumber: string): boolean {
        const regex = new RegExp(/^\d{3}-\d{8}$/);

        if (!regex.test(awbNumber)) {
            return false;
        }

        const serialNumber = parseInt(awbNumber.substr(4, 7));
        const checkDigit = parseInt(awbNumber.charAt(awbNumber.length - 1));
        const calculatedCheckDigit = serialNumber % 7;

        return checkDigit === calculatedCheckDigit;
    }

    public static isValidMailList(list: Array<object | string>, property: string = null, suffix: string = ''): boolean {

        list = list ? list : [];

        let invalidMails: Array<string> = [];
        for (let i: number = 0; i < list.length; i++) {

            let email: string = null;
            if (property) email = StringUtil.replaceAll(list[i][property], ' ', '');
            else email = StringUtil.replaceAll(list[i] as string, ' ', '');

            if (!ValidateUtil.isValidEmail(email)) invalidMails.push(email);

        }

        if (invalidMails.length) {
            NotificationService().error(`Email Inválido <b>${invalidMails.join(',')}</b> no ${suffix}`);
            return false;
        }

        return true;
    }

    public static isValidDateRange(initialDate: Date, finalDate: Date, dateFormat?: string): boolean {
        if (!initialDate) return false;
        if (!finalDate) return false;

        let paramDateFormat = dateFormat ? dateFormat : 'DD/MM/YYYY';

        const momentStart = moment(initialDate, paramDateFormat);
        const momentEnd = moment(finalDate, paramDateFormat);

        let validDates: boolean;

        validDates = momentStart.isSameOrBefore(momentEnd);
        if (!validDates) {
            NotificationService().error(`The date ${moment(momentEnd).format(paramDateFormat)} is less then ${moment(momentStart).format(paramDateFormat)}`);
        }
        return validDates;
    }

    public static getPhoneValidityMessage(intlInput: intlTelInput.Plugin): string {
        let message = "";
        if (intlInput) {
            const errorCode = intlInput.getValidationError();
            if (errorCode == -99 || errorCode == intlTelInputUtils.validationError.IS_POSSIBLE || errorCode == intlTelInputUtils.validationError.NOT_A_NUMBER) {
                message = NotificationConstants.PHONE_VALIDITY_INVALID;
            } else if (errorCode == intlTelInputUtils.validationError.TOO_SHORT) {
                message = NotificationConstants.PHONE_VALIDITY_SHORT;
            } else if (errorCode == intlTelInputUtils.validationError.TOO_LONG) {
                message = NotificationConstants.PHONE_VALIDITY_LONG;
            } else if (errorCode == intlTelInputUtils.validationError.INVALID_COUNTRY_CODE) {
                message = NotificationConstants.PHONE_VALIDITY_COUNTRY_CODE;
            }
        }
        return message;
    }

    public static validityPhone(formInput: ng.IFormController, intlInput: intlTelInput.Plugin): void {
        if (formInput && intlInput) {
            const isRequired = formInput.$$attr.required;
            const isValid = intlInput.isValidNumber();
            const isEmpty = intlInput.getNumber().length == 0;
            formInput.$setValidity('$valid', (isEmpty && !isRequired) || isValid, null);
        }
    }
}
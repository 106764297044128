import angular = require('angular');
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { EOfferLogsName } from '@enums/Offer';
import { EProductId, ECargoTypeId, EOperation, EProcessTypeId } from '@enums/GenericData';
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { IRestService } from "@services/RestService";
import { FormService2 } from '@services/FormService2';
import { IModalService } from '@services/ModalService';
import { ISessionService } from '@services/SessionService';
import { IWeightRangeSelector } from '@models/interface/product/WeightRangeModel';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IChargeNameListCustomFilter } from '@models/interface/product/ChargeNameModel';
import { IApplicationList } from '@models/interface/product/ApplicationModel';
import { IProductConfigurationSecure, IProductConfigurationModel } from '@models/interface/product/ProductConfigurationModel';
import { IOfferCargoModel, IOfferRequestOperationWM, IOfferRequestOperationChargeable, ICargo, IDetDemOriginDestination, IDetDemDetails, IDetails, IDangerousCargo, ISecure, ICargoSummary, IFCLCargo, IFTLCargo } from '../../commercial/model/OfferModel';
import { IOfferScope, ECollapseState } from '../../commercial/controller/OfferRegisterController';
import { SelectorModel } from '../../common/model/SelectorModel';
import { ILinkParameter } from '../../common/model/ModelParameter';
import { ILegalPersonListCustomFilter } from '../../registration/model/LegalPersonModel';
import { OfferHelper } from './util/OfferHelper';
import { IMonacoConfig } from '../../common/MonacoInterface';
import { HelperService } from "@services/HelperService";

interface IDetDemModalScope extends ng.IScope {
    operation: string;
    detDemDetails: IDetDemDetails;
    oldDetDemDetails: IDetDemDetails;
    productId: string;
    applyDetDem: (close?: boolean) => void;
    closeDetDemModal: () => Promise<void>;
}

interface IOfferCargoScope extends ng.IScope {
    form: ng.IFormController;
    log: IViewLog;
    model: IOfferCargoModel;
    oldModel: IOfferCargoModel;
    isCargoCalculatedFieldsDisabled: boolean;
    isCargoListGrossWeightFieldsDisabled: boolean;
    weightRangeList: IWeightRangeSelector[];
    packageTypeList: SelectorModel[];
    unClassList: SelectorModel[];
    packingGroupList: SelectorModel[];
    chargeNameList: SelectorModel[];
    applicationList: IApplicationList[];
    productConfigurationList: IProductConfigurationModel[];
    insuranceBrokerList: SelectorModel[];
    oldSecure: ISecure;
    customLogProperties: ICustomLogProperties[];
    airCargoTreatment: SelectorModel[];

    //general methods
    collapseCargo: () => void;
    openDetDemDetails: () => void;
    openChargesPerWeightRangeModal: () => void;
    buildDetDemValuesView: (values: ICargoSummary) => string;
    buildDetDemDetails: (tariffDetDemOriginDestination: IDetDemOriginDestination, direction: string) => string;
    launchCargoPolLb: () => void;
    viewLogDD: (transaction: string, detail: IDetails) => void;
    viewLogSpecs: (uuid: string) => void;
    viewLogCargo: (uuid: string) => void;
    viewLogEquipment: (uuid: string) => void;
    temperatureValidation: (temperature: number) => void;
    enableTotalGrossWeight: () => void;

    //gets
    getWeightRangeMinValue: (weightRangeList: IWeightRangeSelector[]) => number;
    getWeightRangeMaxValue: (weightRangeList: IWeightRangeSelector[]) => number;
    getChargeNameListByName: (search: string) => Promise<void>;
    getApplicationListByName: (search: string) => Promise<void>;
    getInsuranceBrokerListByName: (search: string) => Promise<void>;

    //field changes
    weightBreakdownChange: (value: boolean) => void;
    cargoAirChargeableWeightChange: (amount: number) => void;
    airOverChargeableWeightChange: () => void;
    grossWeightChange: () => void;
    cubicWeightChange: () => void;
    specSecureChange: () => void;
    chargeNameChange: (optionChargeName: SelectorModel) => void;
    specRegrigeratedChange: () => void;
    specCargoTreatmentChange: () => void;
    cargoListGrossWeightFieldsControl: (grossWeightTotal: number) => void;
    updateMask: (field: string, index: number) => void

    //multilines control
    addCargo: () => void;
    removeCargo: (index: number) => void;
    addDangerousCargo: () => void;
    removeDangerousCargo: (index: number) => void;
    removeFclEquipment: (index: number) => void;
    removeBbroroEquipment: (index: number) => void;
    calculateCargoCBW: (index: number) => Promise<void>;
    calculateCargoGrossWeightTotal: (index: number) => void;
    calculateGrossWeightTotal: () => void;
    calculateVolumeTotal: () => void;

    //check methods
    hasChanges: () => boolean;
    isLclAmountDisabled: () => boolean;
    isAirChargeableWeightDisabled: () => boolean;
    isCollapseIn(): boolean;
    isAirProduct: () => boolean;
    isCargoTotalGrossWeightDisabled: () => boolean;
    isLiquidProcessType: () => boolean;
}

export class OfferCargoRegisterController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IOfferCargoScope;
    private $offerScope: IOfferScope;
    private $q: ng.IQService;
    private $filter: ng.IFilterService;
    private restService: IRestService;
    private $timeout: ng.ITimeoutService;
    private formService: FormService2;
    private modalService: IModalService;
    private sessionService: ISessionService;
    private detDemModalId: number;
    private modalCargoPolLbId: number;
    private propertiesToIgnore: string[];
    private config: IMonacoConfig;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IOfferCargoScope) {
        this.$scope = $scope;
        this.$offerScope = <IOfferScope>$scope.$parent.$parent;
        this.$q = $injector.get('$q');
        this.$filter = $injector.get('$filter');
        this.restService = $injector.get('RestService');
        this.$timeout = $injector.get('$timeout');
        this.formService = this.$offerScope.formService;
        this.modalService = this.$offerScope.modalService;
        this.sessionService = this.$offerScope.sessionService;
        this.detDemModalId = 0;
        this.modalCargoPolLbId = 0;
        this.propertiesToIgnore = ['DET_DEM_DETAILS'];
        this.initScopeFunctions();
        this.config = $injector.get('config');
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.initModel();
            await this.initDependencies();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initModel(): void {
        this.$scope.model = {
            ID_OFFER: null,
            TYPE_CARGO: null,
            REPLICATION_DATA: null,
            IS_DANGEROUS_CARGO: null,
            DANGEROUS_CARGO: null,
            IS_INSURANCE: null,
            SECURE: null,
            FCL: null,
            FTL: null,
            BB_RORO: null,
            AIR: null,
            LCL_APPLICATION: null,
            LCL_AMOUNT: null,
            VOLUME: null,
            GROSS_WEIGHT: null,
            CUBIC_WEIGHT: null,
            CARGO_LIST: null,
            OWS: null,
            FOODGRADE: null,
            SUPERTESTED: null,
            STACKABLE: null,
            STANDARD_EQUIPMENT: null,
            DET_DEM_DETAILS: null,
            DTA: null,
            IS_REFRIGERATED_CARGO: null,
            REFRIGERATED_CARGO: null,
            IS_CARGO_TREATMENT: null,
            CARGO_TREATMENT: null,
            FLEXITANK: null,
        };
        this.$scope.oldModel = angular.copy(this.$scope.model);
    }

    private initScopeFunctions(): void {
        this.$scope.collapseCargo = () => {
            this.collapseCargo();
        }
        this.$scope.openDetDemDetails = () => {
            this.openDetDemDetails();
        }
        this.$scope.openChargesPerWeightRangeModal = () => {
            this.openChargesPerWeightRangeModal();
        }
        this.$scope.buildDetDemValuesView = (values: ICargoSummary): string => {
            return this.buildDetDemValuesView(values);
        }
        this.$scope.buildDetDemDetails = (tariffDetDemOriginDestination: IDetDemOriginDestination, direction: string): string => {
            return this.buildDetDemDetails(tariffDetDemOriginDestination, direction);
        }
        this.$scope.launchCargoPolLb = async (): Promise<void> => {
            this.launchCargoPolLb();
        }
        this.$scope.viewLogDD = (transaction: string, detail: IDetails) => {
            this.viewLogDD(transaction, detail);
        }
        this.$scope.viewLogSpecs = (uuid: string) => {
            this.viewLog(EOfferLogsName.CARGO_SPECIFICATIONS, uuid);
        }
        this.$scope.viewLogCargo = (uuid: string) => {
            this.viewLog(EOfferLogsName.CARGO_CARGO_LIST, uuid);
        }
        this.$scope.viewLogEquipment = (uuid: string) => {
            this.viewLog(EOfferLogsName.CARGO_EQUIPMENT, uuid);
        }
        this.$scope.temperatureValidation = (temperature: number) => {
            this.temperatureValidation(temperature);
        }
        this.$scope.enableTotalGrossWeight = () => {
            this.enableTotalGrossWeight();
        }
        this.$scope.getWeightRangeMinValue = (weightRangeList: IWeightRangeSelector[]): number => {
            return this.getWeightRangeMinValue(weightRangeList);
        }
        this.$scope.getWeightRangeMaxValue = (weightRangeList: IWeightRangeSelector[]): number => {
            return this.getWeightRangeMaxValue(weightRangeList);
        }
        this.$scope.getChargeNameListByName = async (search: string) => {
            let chargeNameList: SelectorModel[] = [];
            const products = [this.$offerScope.model.PRODUCT];
            const paramTypeCargo = [this.$scope.model.TYPE_CARGO];

            if (search && search.length >= 2) chargeNameList = await this.getChargeNameListByName({ search, types: ['6'], products: products && products.length > 0 ? products.map(product => product.ID) : null, paramTypeCargo: paramTypeCargo && paramTypeCargo.length > 0 ? paramTypeCargo.map(typeCargo => typeCargo.ID) : null });
            this.$scope.chargeNameList = chargeNameList;
        }
        this.$scope.getApplicationListByName = async (search: string): Promise<void> => {
            this.$scope.applicationList = await this.getApplicationListByName([this.$offerScope.model.PRODUCT], [this.$scope.model.TYPE_CARGO], search);
        }
        this.$scope.getInsuranceBrokerListByName = async (search: string) => {
            let insuranceBrokerList: SelectorModel[] = [];
            if (search && search.length >= 3) insuranceBrokerList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.INSURANCE_BROKER], search: search });
            this.$scope.insuranceBrokerList = insuranceBrokerList;
        }
        this.$scope.weightBreakdownChange = (value: boolean): void => {
            this.weightBreakdownChange(value);
            this.updateWeightRangeBase();
        }
        this.$scope.cargoAirChargeableWeightChange = (amount: number) => {
            this.updateCargoCalculateFieldsDisabledControl(amount);
            this.updateWeightRangeBase();
        }
        this.$scope.airOverChargeableWeightChange = () => {
            this.updateWeightRangeBase();
        }
        this.$scope.grossWeightChange = () => {
            this.grossWeightChange();
        }
        this.$scope.cubicWeightChange = () => {
            this.cubicWeightChange();
        }
        this.$scope.specSecureChange = () => {
            this.specSecureChange();
        }
        this.$scope.chargeNameChange = (optionChargeName: SelectorModel): void => {
            this.chargeNameChange(optionChargeName);
        }
        this.$scope.specRegrigeratedChange = () => {
            this.specRegrigeratedChange();
        }
        this.$scope.specCargoTreatmentChange = () => {
            this.specCargoTreatmentChange();
        }
        this.$scope.cargoListGrossWeightFieldsControl = (grossWeightTotal: number) => {
            this.$scope.isCargoListGrossWeightFieldsDisabled = this.$scope.isCargoTotalGrossWeightDisabled() ? false : grossWeightTotal !== null && angular.isDefined(grossWeightTotal);
        }
        this.$scope.addCargo = (): void => {
            this.addCargo();
        }
        this.$scope.removeCargo = (index: number): void => {
            this.removeCargo(index);
        }
        this.$scope.addDangerousCargo = (): void => {
            this.addDangerousCargo();
        }
        this.$scope.removeDangerousCargo = (index: number): void => {
            this.removeDangerousCargo(index);
        }
        this.$scope.removeFclEquipment = (index: number): void => {
            this.removeFclEquipment(index);
        }
        this.$scope.removeBbroroEquipment = (index: number): void => {
            this.removeBbroroEquipment(index);
        }
        this.$scope.calculateCargoCBW = async (index: number): Promise<void> => {
            if (!index && index !== 0) throw this.formService.notifyError('index is null');
            if (this.$scope.model.CARGO_LIST && this.$scope.model.CARGO_LIST.length > 0 && this.$scope.model.CARGO_LIST[index]) {
                const cbw = OfferHelper.calculateCargoCBW(this.$scope.model.CARGO_LIST[index]);
                this.$scope.model.CARGO_LIST[index].CUBIC_WEIGHT = cbw ? parseFloat(cbw.toFixed(3)) : cbw;
                this.calculateCBWTotal();
                if (this.$scope.model.TYPE_CARGO && this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.AIR) await this.calculateOperationChargeable();
                if (this.$scope.model.TYPE_CARGO && (this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.BREAK_BULK || this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.LCL || this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.RO_RO)) this.calculateOperationWM();
            }
        }
        this.$scope.calculateCargoGrossWeightTotal = (index: number): void => {
            if (!index && index !== 0) throw this.formService.notifyError('index is null');
            if (this.$scope.model.CARGO_LIST && this.$scope.model.CARGO_LIST.length > 0 && this.$scope.model.CARGO_LIST[index] && this.$scope.model.CARGO_LIST[index].GROSS_WEIGHT_UNITARY > 0) {
                const grossWeightTotal = OfferHelper.calculateCargoGrossWeightTotal(this.$scope.model.CARGO_LIST[index]);
                this.$scope.model.CARGO_LIST[index].GROSS_WEIGHT = grossWeightTotal ? parseFloat(grossWeightTotal.toFixed(4)) : grossWeightTotal;
            }
            this.calculateGrossWeightTotal();
        }
        this.$scope.calculateGrossWeightTotal = (): void => {
            this.calculateGrossWeightTotal();
        }
        this.$scope.calculateVolumeTotal = (): void => {
            this.calculateVolumeTotal();
        }
        this.$scope.hasChanges = (): boolean => {
            return this.$offerScope && this.$offerScope.hasChanges ? this.$offerScope.hasChanges(this.$scope.model, this.$scope.oldModel, this.propertiesToIgnore) : false;
        }
        this.$scope.isLclAmountDisabled = (): boolean => {
            return ((this.$scope.model.CARGO_LIST && this.$scope.model.CARGO_LIST.length > 0) || this.$scope.model.VOLUME || this.$scope.model.GROSS_WEIGHT || this.$scope.model.CUBIC_WEIGHT) ? true : false;
        }
        this.$scope.isAirChargeableWeightDisabled = (): boolean => {
            return ((this.$scope.model.CARGO_LIST && this.$scope.model.CARGO_LIST.length > 0) || this.$scope.model.VOLUME || this.$scope.model.GROSS_WEIGHT || this.$scope.model.CUBIC_WEIGHT) ? true : false;
        }
        this.$scope.isCollapseIn = (): boolean => {
            return this.$offerScope.collapseState.panel == ECollapseState.CARGO && !this.$offerScope.collapseState.released;
        }
        this.$scope.isAirProduct = (): boolean => {
            return this.$offerScope.model.PRODUCT && (this.$offerScope.model.PRODUCT.ID == EProductId.AIR_IMPORT || this.$offerScope.model.PRODUCT.ID == EProductId.AIR_EXPORT);
        }
        this.$scope.isCargoTotalGrossWeightDisabled = (): boolean => {
            const cargoList = this.$scope.model ? this.$scope.model.CARGO_LIST : null;
            return cargoList && cargoList.findIndex(cargo => (cargo.GROSS_WEIGHT_UNITARY || cargo.GROSS_WEIGHT_UNITARY == 0) || (cargo.GROSS_WEIGHT || cargo.GROSS_WEIGHT == 0)) >= 0;
        }
        this.$scope.isLiquidProcessType = (): boolean => {
            return this.$offerScope.model.PROCESS_TYPE && this.$offerScope.model.PROCESS_TYPE.ID == EProcessTypeId.LIQUID;
        }
        this.$scope.updateMask = (field: string, index: number): void => {
            this.updateMask(field, index);
        }

    }

    async initDependencies(): Promise<any> {
        const self: OfferCargoRegisterController = this;

        this.initCollapseEvents();

        this.$scope.$on('offerCargoSave', () => {
            this.saveOfferTabCargo(true);
        });

        const promises = {
            weightRange: self.getWeightRangeList(),
            packageType: self.getGenericValue("package_type"),
            unClass: self.getGenericValue("un_class"),
            packingGroup: self.getGenericValue("packing_group"),
            airCargoTreatment: self.getGenericValue("air_cargo_treatment"),
        };

        return new Promise((resolve, reject) => {
            self.$q.all(promises).then(async (result) => {
                self.$scope.weightRangeList = result.weightRange;
                self.$scope.packageTypeList = result.packageType;
                self.$scope.unClassList = result.unClass;
                self.$scope.packingGroupList = result.packingGroup;
                self.$scope.airCargoTreatment = result.airCargoTreatment;
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private initCollapseEvents() {
        this.$scope.$on('offerCargoCollapse', () => {
            this.collapseCargo();
        });

        const collapseCargo = angular.element('#collapseCargo');
        if (collapseCargo) {
            collapseCargo.on('shown.bs.collapse', async (event: JQuery.Event) => {
                const product = this.$offerScope.model.PRODUCT;
                if (event.target == event.currentTarget) {
                    if (product && (product.ID == EProductId.AIR_EXPORT || product.ID == EProductId.AIR_IMPORT)) angular.element("#chargesWeightRange").show();
                    angular.element("#detDemDetails").show();
                    angular.element("#viewLogCargo").show();
                    angular.element("#collapseCargoHeading").attr('aria-expanded', 'true');
                    // update collapse state
                    this.$offerScope.collapseState = { panel: ECollapseState.CARGO, released: false, nextState: null };
                    this.$offerScope.repositionPanels('cargoRow', true);
                    await this.getOfferTabsCargo(true);
                    this.$scope.oldSecure = angular.copy(this.$scope.model.SECURE);
                    this.$offerScope.disableElements(this.$offerScope.operation == EOperation.VIEW);
                }
            });
            collapseCargo.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                const product = this.$offerScope.model.PRODUCT;
                if (event.target == event.currentTarget) {
                    if (product && (product.ID == EProductId.AIR_EXPORT || product.ID == EProductId.AIR_IMPORT)) angular.element("#chargesWeightRange").hide();
                    angular.element("#detDemDetails").hide();
                    angular.element("#viewLogCargo").hide();
                    angular.element("#collapseCargoHeading").attr('aria-expanded', 'false');
                    this.$scope.model = null;
                    this.$scope.oldModel = null;
                }
            });
        }
    }

    private async saveOfferTabCargo(runValidation: boolean = false): Promise<boolean> {
        let success = true;
        try {
            const isValid = this.formService.checkPendingFields();
            if (!isValid) return false;
            if (this.$offerScope.model.PRODUCT && (this.$offerScope.model.PRODUCT.ID == EProductId.AIR_EXPORT || this.$offerScope.model.PRODUCT.ID == EProductId.AIR_IMPORT)) {
                if (this.$scope.model.AIR.OVER_CHARGEABLE_WEIGHT != null && this.$scope.model.AIR.OVER_CHARGEABLE_WEIGHT <= this.$scope.model.AIR.CHARGEABLE_WEIGHT) {
                    success = false;
                    const msgError = this.formService.getTranslate('PRODUCT.OVER_CHARGEABLE_WEIGHT_LESS_QUANTITY');
                    this.formService.notifyError(msgError);
                } else if (success && !this.isAirWeightRangeValid()) {
                    success = false;
                    this.formService.notifyError('The range informed does not correspond to the chargeable weight and/or over chargeable weight informed.');
                }
            }
            if (success) {
                this.formService.block();
                const result = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/cargo/update`, { id: this.$offerScope.model.ID, data: this.$scope.model, oldData: this.$scope.oldModel, runValidation: runValidation }, 30000, false);
                if (result) {
                    this.$scope.model.CARGO_LIST = result.CARGO_LIST;
                    const msgSuccess = this.formService.getTranslate('GENERAL.CARGO_TAB_DATA_SAVED_SUCCESSFULLY');
                    this.formService.notifySuccess(msgSuccess);
                }
            }
        } catch (ex) {
            success = false;
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            if (success) this.$scope.oldModel = angular.copy(this.$scope.model);
            else this.$offerScope.collapseState.nextState = null;
            return success;
        }
    }

    private isAirWeightRangeValid(): boolean {
        let valid = false;
        const chargeableWeight = this.$scope.model.AIR.CHARGEABLE_WEIGHT;
        const overChargeableWeight = this.$scope.model.AIR.OVER_CHARGEABLE_WEIGHT;
        const weightRangeSelected: IWeightRangeSelector[] = this.$scope.weightRangeList && this.$scope.weightRangeList.length ? this.$scope.weightRangeList.filter(weightRange => this.$scope.model.AIR.WEIGHT_RANGE.some(weightRangeToVerify => weightRangeToVerify.ID == weightRange.ID)) : null;
        for (let index = 0; index < weightRangeSelected.length; index++) {
            const weightRange = weightRangeSelected[index];
            const isChargeableWeightValid = chargeableWeight ? this.betweenNumber(chargeableWeight, weightRange.MIN_WEIGHT, weightRange.MAX_WEIGHT) : false;
            const isOverChargeableWeightValid = overChargeableWeight ? this.betweenNumber(overChargeableWeight, weightRange.MIN_WEIGHT, weightRange.MAX_WEIGHT) : false;
            if (isChargeableWeightValid || isOverChargeableWeightValid) {
                valid = true;
                break;
            }
        }
        return valid;
    }

    private async getGenericValue(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private buildDetDemValuesView(values: ICargoSummary) {
        let detDem = "";
        if (values) {
            if (values.DET || values.DEM) {
                if (values.DET) detDem += values.DET.toString();
                if (values.DEM) detDem += " + " + values.DEM.toString();
            } else if (values.COMBINED) {
                detDem += values.COMBINED.toString();
            }
        }
        return detDem;
    }

    private async chargeNameChange(optionChargeName: SelectorModel): Promise<void> {
        if (optionChargeName) {
            await this.getApplicationListByChargeNameId(optionChargeName);
            if (this.$scope.model.IS_INSURANCE)
                this.$scope.model.SECURE.ID_CHARGE_NAME_EXHIBITION = parseInt(optionChargeName.ID)
        }
    }

    private async getApplicationListByChargeNameId(optionChargeName: SelectorModel): Promise<void> {
        try {
            this.formService.block();
            if (!optionChargeName) return this.formService.notifyError("optionChargeName is null");
            if (optionChargeName) {
                const application = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/application/list/custom/charge_name/${optionChargeName.ID}`, 10000, null, false);
                if (application && application.data && application.data.CHARGE_NAME.PARAMS) {
                    const paymentApplicationList = application.data.CHARGE_NAME.PARAMS.map(x => {
                        return {
                            ID: x.PAYMENT.ID,
                            NAME: x.PAYMENT.NAME,
                            CODE: x.PAYMENT.CODE,
                            PERCENT: x.PAYMENT.PERCENT,
                            APPLICATION_COMPLEMENT: x.PAYMENT.APPLICATION_COMPLEMENT,
                            INTERNAL_SEQUENCE: x.PAYMENT.INTERNAL_SEQUENCE,
                            FREE_TYPING_AMOUNT_CHARGE: x.PAYMENT.FREE_TYPING_AMOUNT_CHARGE,
                            CT_WITHOUT_DOC: x.PAYMENT.CT_WITHOUT_DOC,
                            NAME_INTL: x.PAYMENT.NAME_INTL
                        }
                    });
                    const receivingApplicationList = application.data.CHARGE_NAME.PARAMS.map(x => {
                        return {
                            ID: x.RECEIVING.ID,
                            NAME: x.RECEIVING.NAME,
                            CODE: x.RECEIVING.CODE,
                            PERCENT: x.RECEIVING.PERCENT,
                            APPLICATION_COMPLEMENT: x.RECEIVING.APPLICATION_COMPLEMENT,
                            INTERNAL_SEQUENCE: x.RECEIVING.INTERNAL_SEQUENCE,
                            FREE_TYPING_AMOUNT_CHARGE: x.RECEIVING.FREE_TYPING_AMOUNT_CHARGE,
                            CT_WITHOUT_DOC: x.RECEIVING.CT_WITHOUT_DOC,
                            NAME_INTL: x.RECEIVING.NAME_INTL
                        }
                    });
                    if (paymentApplicationList && paymentApplicationList.length > 0) this.$scope.model.SECURE.APPLICATION_PAYMENT = paymentApplicationList[0];
                    if (receivingApplicationList && receivingApplicationList.length > 0) this.$scope.model.SECURE.APPLICATION_RECEIVING = receivingApplicationList[0];
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async collapseCargo() {
        try {
            if (this.$offerScope.collapseState.released || this.$offerScope.collapseState.panel == ECollapseState.CARGO) {
                const collapseCargo = angular.element("#collapseCargo");
                if (collapseCargo) {
                    const isCollapsed = angular.element("#collapseCargoHeading").attr("aria-expanded") == "true";
                    if (isCollapsed) {
                        if (this.$offerScope.hasChanges(this.$scope.model, this.$scope.oldModel, this.propertiesToIgnore)) {
                            const confirm = await this.$offerScope.modalSaveConfirmation("GENERAL.CARGO", "GENERAL.CLOSE");
                            if (confirm && !await this.saveOfferTabCargo()) return;
                        }
                        this.$offerScope.collapseState.released = true;
                    }
                    collapseCargo['collapse']('toggle');
                    if (isCollapsed) this.$offerScope.repositionPanels('cargoRow');
                }
            } else {
                this.$offerScope.collapseState.nextState = ECollapseState.CARGO;
                this.$offerScope.releaseCollapse();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async openDetDemDetails(): Promise<void> {
        try {
            if (!this.$scope.model.DET_DEM_DETAILS) {
                const detDemDetails = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/cargo/detdem/${this.$offerScope.model.ID}`, 30000, null, false);
                if (detDemDetails && detDemDetails.data) this.$scope.model.DET_DEM_DETAILS = detDemDetails.data.DET_DEM_DETAILS;
            }
            if (!this.$scope.model.DET_DEM_DETAILS) return this.formService.notifyError("detDemDetails is null.");

            this.detDemModalId = this.modalService.newModal();
            const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                {
                    modalID: this.detDemModalId,
                    scope: this.$scope,
                    formService: 'edit',
                    size: 'lg modal-overflow',
                    template: require("../view/modals/offerDetDemModal.html"),
                    keyboard: true,
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing") {
                            if (reason.toString() == "escape key press") event.preventDefault();
                            if (!closed) {
                                const modalScope: IDetDemModalScope = await this.modalService.getModalScope(this.detDemModalId);
                                await modalScope.closeDetDemModal();
                            }
                        }
                    }
                },
                null
            );
            await this.buildDetDemModalScope(this.$scope.model.DET_DEM_DETAILS);

            const endpoint = `${this.config.productUrl}/product/tariffDetDem/getCacheById`;
            await modalInstance.rendered.then(() => {
                angular.element(".openTariffDetDemOriginPayment").bind('click', () => {
                    const tariffId = this.$scope.model.DET_DEM_DETAILS.DET_DEM_ORIGIN.ID_PAYMENT ? this.$scope.model.DET_DEM_DETAILS.DET_DEM_ORIGIN.ID_PAYMENT.toString() : null;
                    this.sessionService.openTabByValidity(`${endpoint}/${tariffId}`, "app.product.tariffDetDem", <ILinkParameter>{ ID: tariffId });
                });

                angular.element(".openTariffDetDemOriginReceiving").bind('click', () => {
                    const tariffId = this.$scope.model.DET_DEM_DETAILS.DET_DEM_ORIGIN.ID_RECEIVING ? this.$scope.model.DET_DEM_DETAILS.DET_DEM_ORIGIN.ID_RECEIVING.toString() : null;
                    this.sessionService.openTabByValidity(`${endpoint}/${tariffId}`, "app.product.tariffDetDem", <ILinkParameter>{ ID: tariffId });
                });

                angular.element(".openTariffDetDemDestinationPayment").bind('click', () => {
                    const tariffId = this.$scope.model.DET_DEM_DETAILS.DET_DEM_DESTINATION ? this.$scope.model.DET_DEM_DETAILS.DET_DEM_DESTINATION.ID_PAYMENT.toString() : null;
                    this.sessionService.openTabByValidity(`${endpoint}/${tariffId}`, "app.product.tariffDetDem", <ILinkParameter>{ ID: tariffId });
                });

                angular.element(".openTariffDetDemDestinationReceiving").bind('click', () => {
                    const tariffId = this.$scope.model.DET_DEM_DETAILS.DET_DEM_DESTINATION ? this.$scope.model.DET_DEM_DETAILS.DET_DEM_DESTINATION.ID_RECEIVING.toString() : null;
                    this.sessionService.openTabByValidity(`${endpoint}/${tariffId}`, "app.product.tariffDetDem", <ILinkParameter>{ ID: tariffId });
                });
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async buildDetDemModalScope(detDemDetails: IDetDemDetails): Promise<IDetDemModalScope> {
        const modalScope: IDetDemModalScope = await this.modalService.getModalScope(this.detDemModalId);

        modalScope.operation = this.$offerScope.operation;
        modalScope.detDemDetails = angular.copy(detDemDetails);
        modalScope.oldDetDemDetails = angular.copy(detDemDetails);
        modalScope.productId = this.$offerScope.model.PRODUCT.ID;

        modalScope.applyDetDem = (close?: boolean) => {
            if (this.applyDetDem(modalScope.detDemDetails, modalScope.oldDetDemDetails, close)) modalScope.oldDetDemDetails = angular.copy(detDemDetails);
        };

        modalScope.closeDetDemModal = async (): Promise<void> => {
            if (this.$offerScope.hasChanges(modalScope.detDemDetails, modalScope.oldDetDemDetails, this.propertiesToIgnore)) {
                const confirm = await this.$offerScope.modalSaveConfirmation("GENERAL.CLOSE", "GENERAL.CLOSE");
                if (confirm && !this.applyDetDem(modalScope.detDemDetails, modalScope.oldDetDemDetails)) return;
            }
            this.modalService.closeModal(this.detDemModalId);
            this.detDemModalId = 0;
            $('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
        }

        return modalScope;
    }

    private async applyDetDem(detDemDetails: IDetDemDetails, oldDetDemDetails: IDetDemDetails, close?: boolean): Promise<boolean> {
        let success = false;
        try {
            if (this.$offerScope.hasInvalidRequiredElements('modalForm')) return;
            if (this.hasInvalidMin('#modalForm')) return;
            if (!detDemDetails) throw Error('detDemDetails is null');
            const result = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/cargo/detdem/update`, { id: this.$offerScope.model.ID, data: detDemDetails, oldData: oldDetDemDetails }, 30000, false);

            if (result) {
                const cargoTab = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/cargo/${this.$offerScope.operation}/${this.$offerScope.model.ID}`, 30000, null, false);
                if (cargoTab && cargoTab.data) {
                    this.$scope.model.FCL = cargoTab.data.FCL;
                    this.$scope.model.FTL = cargoTab.data.FTL;
                    this.$scope.model.BB_RORO = cargoTab.data.BB_RORO;
                    this.$scope.oldModel.FCL = this.$scope.model.FCL;
                    this.$scope.oldModel.FTL = this.$scope.model.FTL;
                    this.$scope.oldModel.BB_RORO = this.$scope.model.BB_RORO;
                }
                this.$scope.model.DET_DEM_DETAILS = detDemDetails;
                const msgSuccess = this.formService.getTranslate('PRODUCT.DETENTION_DEMURRAGE_DETAILS_UPDATED');
                this.formService.notifySuccess(msgSuccess);
                success = true;
                if (close) {
                    this.modalService.closeModal(this.detDemModalId);
                    this.detDemModalId = 0;
                    $('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
                }
            }
            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return success;
        }
    }

    private buildDetDemDetails(tariffDetDemOriginDestination: IDetDemOriginDestination, direction: string) {
        let originDetailRow = ``;
        let freeTimeDetailRow = ``;
        let htmlOriginStart = `<div class="row">
        <div class="col-lg-12">
            <div class="panel panel-default">
                <div class="panel-heading">
                    <div class="row" style="display: flex; align-items: center;">
                        <div class="col-lg-3 text-strong text-center"> {{'BASIC_DATA.EQUIPMENT' | translate }} </div>
                        <div class="col-lg-3 text-strong text-center"> {{'GENERAL.TYPE' | translate }} </div>
                        <div class="col-lg-3 text-strong text-center"> {{'BASIC_DATA.PAYMENT_NATURE'  | translate }} </div>
                        <div class="col-lg-3 text-strong text-center"> {{'BASIC_DATA.FREE_TIME' | translate }} (D) </div>
                    </div>
                </div>
                <div class="panel panel-body" style="margin-bottom: 0px; padding-top: 0px; padding-bottom: 0px;">`;
        if (tariffDetDemOriginDestination) {
            for (let index = 0; index < tariffDetDemOriginDestination.DETAILS.length; index++) {
                const detail = tariffDetDemOriginDestination.DETAILS[index];
                originDetailRow += `
                        <div class="row" style="display: flex; align-items: center; background: ${detail.PAYMENT_NATURE.ID == '1' ? '#f2dede' : '#dff0d8'}">
                            <div class="col-lg-3 text-center">${this.$offerScope.getCONCAT(detail.EQUIPMENT)}</div>
                            <div class="col-lg-3 text-center">${detail.TYPE}</div>
                            <div class="col-lg-3 text-center">${detail.PAYMENT_NATURE.ID == '1' ? ' - Pagamento' : ' + Recebimento'}</div>
                            <div class="col-lg-3 text-center">${detail.FREE_TIME} </div>`;

                if (detail.FREE_TIME_DETAILS && detail.FREE_TIME_DETAILS.length > 0) {
                    originDetailRow += `<div class="panel-heading collapse-heading clickable-div collapsed" data-toggle="collapse" data-target="#freeTimeDetails_${direction}_${index}" aria-controls="collapseShareTable" aria-expanded="false"></div>`;
                }

                originDetailRow += `</div>`;

                originDetailRow += `
                    <div id="freeTimeDetails_${direction}_${index}" class="toggle-me collapse" role="tabpanel" tabindex="-1" aria-expanded="false" style="height: 30px; margin-bottom:0px;">
                        <div class="row">
                            <div class="col-lg-12 table-responsive">
                                <table class="table gray-border table-striped monaco-custom-table m-b-sm m-t-sm" style="width: 100%;">
                                    <thead>
                                        <tr>
                                            <th class="text-center">{{'PRODUCT.FT_PERIOD' | translate }}</th>
                                            <th class="text-center">{{'GENERAL.FROM' | translate }}</th>
                                            <th class="text-center">{{'GENERAL.TO' | translate }}</th>
                                            <th class="text-center">{{'GENERAL.DAYS' | translate }}</th>
                                            <th class="text-center">{{'GENERAL.CURRENCY' | translate }}</th>
                                            <th class="text-center">{{'FINANCIAL.VALUE' | translate }}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                `;

                if (detail.FREE_TIME_DETAILS && detail.FREE_TIME_DETAILS.length > 0) {
                    for (let freeTimeInfos = 0; freeTimeInfos < detail.FREE_TIME_DETAILS.length; freeTimeInfos++) {
                        freeTimeDetailRow += `
                            <tr>
                                <td class="text-center">${detail.FREE_TIME_DETAILS[freeTimeInfos].PERIOD}</td>
                                <td class="text-center">${detail.FREE_TIME_DETAILS[freeTimeInfos].FROM}</td>
                                <td class="text-center">${detail.FREE_TIME_DETAILS[freeTimeInfos].UNTIL}</td>
                                <td class="text-center">${detail.FREE_TIME_DETAILS[freeTimeInfos].DAYS}</td>
                                <td class="text-center">${detail.FREE_TIME_DETAILS[freeTimeInfos].CURRENCY.CODE}</td>
                                <td class="text-right">${this.$filter('number')(detail.FREE_TIME_DETAILS[freeTimeInfos].VALUE, 2)}</td>
                            </tr>
                        `;
                    }
                }
                originDetailRow += freeTimeDetailRow;
                originDetailRow += `
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                `;
            }
        }
        htmlOriginStart += originDetailRow;
        htmlOriginStart += `
                    </div>
                </div>
            </div>
        `;
        return htmlOriginStart;
    }

    private async openChargesPerWeightRangeModal(): Promise<void> {
        try {
            if (this.$offerScope.hasChanges(this.$scope.model, this.$scope.oldModel, this.propertiesToIgnore)) {
                const confirm = await this.$offerScope.modalSaveConfirmation("GENERAL.CARGO", "GENERAL.CLOSE");
                if (!confirm || (confirm && !await this.saveOfferTabCargo())) return;
            }
            const modalID = this.modalService.newModal();
            const modalInstance: IModalInstanceService = await this.modalService.showChargesPerWeightRangeModal({ modalID: modalID, offerId: this.$offerScope.model.ID, actionButtonClass: 'btn-default' })

            const apply: boolean = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (apply) await this.getOfferTabsCargo();
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private weightBreakdownChange(value: boolean) {
        if (!this.$scope.model.AIR) throw this.formService.notifyError('Air Cargo was not found.');
        if (value && (!this.$scope.model.CARGO_LIST || this.$scope.model.CARGO_LIST && this.$scope.model.CARGO_LIST.length == 0)) {
            this.$scope.model.AIR.CHARGEABLE_WEIGHT = null;
        }
        else this.$scope.model.AIR.WEIGHT_RANGE = angular.copy(this.$scope.model.AIR.WEIGHT_RANGE_DEFAULT);
    }

    private updateCargoCalculateFieldsDisabledControl(amount: number) {
        this.$scope.isCargoCalculatedFieldsDisabled = amount && amount > 0;
        this.updateWeightRangeBase();
    }

    private getWeightRangeMinValue(weightRangeList: IWeightRangeSelector[]): number {
        let minValue = null;

        if (!this.$scope.model.AIR.OVER_CHARGEABLE_WEIGHT && weightRangeList && weightRangeList.length > 0) {
            for (const weightRange of weightRangeList) {
                const fullWeightRange = this.$scope.weightRangeList.find(obj => obj.ID == weightRange.ID);
                if (fullWeightRange && (minValue == null || (minValue != null && minValue > fullWeightRange.MIN_WEIGHT))) minValue = fullWeightRange.MIN_WEIGHT;
            }
        }

        if (minValue == null) minValue = 0.0001;
        return minValue;
    }

    private getWeightRangeMaxValue(weightRangeList: IWeightRangeSelector[]): number {
        let maxValue = null;

        if (weightRangeList && weightRangeList.length > 0) {
            for (const weightRange of weightRangeList) {
                const fullWeightRange = this.$scope.weightRangeList.find(obj => obj.ID == weightRange.ID);
                if (fullWeightRange && (!maxValue || (maxValue && maxValue < fullWeightRange.MAX_WEIGHT))) maxValue = fullWeightRange.MAX_WEIGHT;
            }
        }
        return maxValue;
    }

    private updateWeightRangeBase(): void {
        if (this.$scope.model.AIR && this.$scope.model.AIR.WEIGHT_RANGE) {
            const numberToVerify = this.$scope.model.AIR.CHARGEABLE_WEIGHT && this.$scope.model.AIR.CHARGEABLE_WEIGHT > this.$scope.model.AIR.OVER_CHARGEABLE_WEIGHT ? this.$scope.model.AIR.CHARGEABLE_WEIGHT : this.$scope.model.AIR.OVER_CHARGEABLE_WEIGHT;
            const weightRangeSelected: IWeightRangeSelector[] = this.$scope.weightRangeList && this.$scope.weightRangeList.length ? this.$scope.weightRangeList.filter(weightRange => this.$scope.model.AIR.WEIGHT_RANGE.some(weightRangeToVerify => weightRangeToVerify.ID == weightRange.ID)) : null;
            let weightRangeBase = null;
            if (weightRangeSelected && weightRangeSelected.length) {
                weightRangeBase = numberToVerify != null && numberToVerify > 0 ? weightRangeSelected.find(weightRange => this.betweenNumber(numberToVerify, weightRange.MIN_WEIGHT, weightRange.MAX_WEIGHT)) : null;
                if (!weightRangeBase) {
                    // get the lowest range
                    weightRangeBase = weightRangeSelected.reduce(function (prev, current) {
                        return (prev.MIN_WEIGHT < current.MIN_WEIGHT) ? prev : current;
                    });
                }
            }
            this.$scope.model.AIR.WEIGHT_RANGE_BASE = weightRangeBase;
        }
    }

    private async grossWeightChange() {
        if (this.$scope.model.GROSS_WEIGHT == 0) this.$scope.model.GROSS_WEIGHT = null;
        if (this.$scope.model.TYPE_CARGO) {
            if (this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.AIR) await this.calculateOperationChargeable();
            else if (this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.BREAK_BULK || this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.LCL || this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.RO_RO) this.calculateOperationWM();
        }
    }

    private async cubicWeightChange() {
        if (this.$scope.model.CUBIC_WEIGHT == 0) this.$scope.model.CUBIC_WEIGHT = null;
        if (this.$scope.model.TYPE_CARGO) {
            if (this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.AIR) await this.calculateOperationChargeable();
            else if (this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.BREAK_BULK || this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.LCL || this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.RO_RO) this.calculateOperationWM();
        }
    }

    private betweenNumber = function (numberToVerify: number, initial: number, final: number): boolean {
        try {
            if (numberToVerify == null) throw Error("numberToVerify is null.");
            if (initial == null) throw Error("initial is null.");
            if (final == null) throw Error("final is null.");
            const min = Math.min.apply(Math, [initial, final]);
            const max = Math.max.apply(Math, [initial, final]);
            return numberToVerify >= min && numberToVerify <= max;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private addCargo(): void {
        try {
            if (!this.$scope.model.CARGO_LIST) this.$scope.model.CARGO_LIST = [];
            const cargo: ICargo = {
                PACKAGE: null,
                VOLUME: null,
                LENGTH: null,
                WIDTH: null,
                HEIGHT: null,
                CUBIC_WEIGHT: null,
                GROSS_WEIGHT_UNITARY: null,
                GROSS_WEIGHT: null,
                UUID: null
            }
            this.$scope.model.CARGO_LIST.push(cargo);
            this.updateCargoCalculatedFields();
            const lastIndex = this.$scope.model.CARGO_LIST.length - 1;
            this.$timeout(() => {
                this.$offerScope.selectorValidity("cargoPackage" + lastIndex);
                this.$offerScope.setDirtyFields(['cargoVolume' + lastIndex, 'cargoLength' + lastIndex, 'cargoWidth' + lastIndex, 'cargoHeight' + lastIndex, 'cargoCBW' + lastIndex, 'cargoGrossWeight' + lastIndex]);
            }, 100);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeCargo(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate('GENERAL.MESSAGES.CONFIRMATION.REMOVAL')
            });
            if (!modal) return;

            if (this.$scope.model.CARGO_LIST && this.$scope.model.CARGO_LIST.length > 0) {
                this.formService.block();
                if (this.$scope.model.CARGO_LIST.length == 1) this.$scope.model.CARGO_LIST = null;
                else this.$scope.model.CARGO_LIST.splice(index, 1);
                this.calculateVolumeTotal();
                this.calculateGrossWeightTotal();
                this.calculateCBWTotal();
                if (this.$scope.model.TYPE_CARGO && this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.AIR) await this.calculateOperationChargeable();
                else if (this.$scope.model.TYPE_CARGO && (this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.BREAK_BULK || this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.LCL || this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.RO_RO)) this.calculateOperationWM();
                this.updateCargoCalculatedFields();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private calculateVolumeTotal(): void {
        this.$scope.model.VOLUME = OfferHelper.calculateVolumeTotal(this.$scope.model.CARGO_LIST);
    }

    private calculateGrossWeightTotal(): void {
        if (this.$scope.isCargoTotalGrossWeightDisabled()) {
            const grossWeightTotal = OfferHelper.calculateGrossWeightTotal(this.$scope.model.CARGO_LIST);
            this.$scope.model.GROSS_WEIGHT = grossWeightTotal;
            this.$scope.model.AIR.CHARGEABLE_WEIGHT = this.$scope.model.GROSS_WEIGHT && this.$scope.model.GROSS_WEIGHT > grossWeightTotal ? Math.ceil((this.$scope.model.GROSS_WEIGHT / 0.5)) * 0.5 : grossWeightTotal;
        }
    }

    private calculateCBWTotal(): void {
        this.$scope.model.CUBIC_WEIGHT = OfferHelper.calculateCBWTotal(this.$scope.model.CARGO_LIST);
    }

    private async updateCargoCalculatedFields() {
        if (this.$scope.model.CARGO_LIST && this.$scope.model.CARGO_LIST.length > 0) {
            this.$scope.isCargoCalculatedFieldsDisabled = true;
            this.$scope.model.LCL_AMOUNT = null;
            this.$scope.model.VOLUME = null;
            this.$scope.model.CUBIC_WEIGHT = null;
            if (this.$scope.model.AIR) {
                this.$scope.model.AIR.CHARGEABLE_WEIGHT = null;
                this.calculateVolumeTotal();
                this.calculateGrossWeightTotal();
                this.calculateCBWTotal();
                if (this.$scope.model.AIR) await this.calculateOperationChargeable();
                if (this.$scope.model.TYPE_CARGO && (this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.BREAK_BULK || this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.LCL || this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.RO_RO)) this.calculateOperationWM();
            }
        } else {
            this.$scope.isCargoCalculatedFieldsDisabled = false;
            if (this.$scope.model.AIR) this.$scope.model.AIR.CHARGEABLE_WEIGHT = null;
            this.$scope.model.LCL_AMOUNT = null;
            this.$scope.model.VOLUME = null;
            this.$scope.model.CUBIC_WEIGHT = null;
        }
    }

    private async getWeightRangeList(): Promise<IWeightRangeSelector[]> {
        let result: IWeightRangeSelector[] = [];
        try {
            const weightRangeList = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/weightRange/list/custom`, {}, 30000, false);
            if (weightRangeList) result = weightRangeList.map(x => { return { ID: x.ID, NAME: x.NAME, MIN_WEIGHT: x.MIN_WEIGHT, MAX_WEIGHT: x.MAX_WEIGHT } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return result;
        }
    }

    private async getOfferTabsCargo(isFirstCall?: boolean): Promise<void> {
        this.formService.block();
        try {
            const cargoTab = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/cargo/${this.$offerScope.operation}/${this.$offerScope.model.ID}`, 30000, null, false);
            if (cargoTab && cargoTab.data) this.$scope.model = cargoTab.data;
            if (this.$scope.model.CARGO_LIST && this.$scope.model.CARGO_LIST.length > 0) this.$scope.isCargoCalculatedFieldsDisabled = true;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            if (!isFirstCall) await this.updateCargoCalculatedFields();
            else this.$scope.cargoListGrossWeightFieldsControl(this.$scope.model.GROSS_WEIGHT);
            this.$scope.oldModel = angular.copy(this.$scope.model);
            this.setCargoListDirty();
            this.formService.unblock();
        }
    }

    private async calculateOperationWM(): Promise<void> {
        let result: number = 0;
        try {
            this.formService.block();
            if (this.$scope.model.GROSS_WEIGHT && this.$scope.model.CUBIC_WEIGHT) {
                const filter: IOfferRequestOperationWM = { GROSS_WEIGHT: this.$scope.model.GROSS_WEIGHT, CUBIC_WEIGHT: this.$scope.model.CUBIC_WEIGHT };
                const resultOperation = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/calculationOperations/wm`, filter, 30000, false);
                if (resultOperation && typeof resultOperation == 'number') result = resultOperation;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.model.LCL_AMOUNT = result && result > 1 ? result : 1;
            this.formService.unblock();
        }
    }

    private async calculateOperationChargeable(): Promise<void> {
        let result: number = 1;
        try {
            this.formService.block();
            if (this.$scope.model.CUBIC_WEIGHT) {
                const filter: IOfferRequestOperationChargeable = { CUBIC_WEIGHT: this.$scope.model.CUBIC_WEIGHT };
                const resultOperation = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/calculationOperations/chargeable`, filter, 30000, false);
                if (resultOperation && typeof resultOperation == 'number') result = resultOperation;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.model.AIR.CHARGEABLE_WEIGHT = this.$scope.model.GROSS_WEIGHT && this.$scope.model.GROSS_WEIGHT > result ? OfferHelper.calculateAirChargeableWeight(this.$scope.model.GROSS_WEIGHT) : result;
            this.updateWeightRangeBase();
            this.formService.unblock();
        }
    }

    private async launchCargoPolLb(): Promise<void> {
        try {

            if (!this.modalCargoPolLbId) this.modalCargoPolLbId = this.modalService.newModal();

            const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                {
                    modalID: this.modalCargoPolLbId,
                    scope: this.$scope,
                    formService: 'register',
                    template: require("../view/modals/offerCargoPollbModal.html"),
                    size: 'full modal-overflow'
                },
                {
                    closeButtonText: 'GENERAL.CANCEL',
                    actionButtonText: 'REGISTRATION.APPLY',
                    headerText: `GENERAL.CARGO_DETAILS`
                }
            );

            const cargoListConverted: ICargo[] = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (cargoListConverted) {
                this.$scope.model.CARGO_LIST = this.$scope.model.CARGO_LIST ? this.$scope.model.CARGO_LIST.concat(cargoListConverted) : cargoListConverted;
                if (this.$scope.model.CARGO_LIST && this.$scope.model.CARGO_LIST.length > 0) {
                    angular.element('#collapseCargoList')["collapse"]("show");
                    this.$timeout(() => {
                        this.formService.loadRegisterForm(false);
                        this.calculateVolumeTotal();
                        this.$scope.model.GROSS_WEIGHT = OfferHelper.calculateGrossWeightTotal(this.$scope.model.CARGO_LIST);
                        this.$scope.model.CUBIC_WEIGHT = OfferHelper.calculateCBWTotal(this.$scope.model.CARGO_LIST);
                        if (this.$scope.model.AIR) this.calculateOperationChargeable();
                        if (this.$scope.model.TYPE_CARGO && (this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.BREAK_BULK || this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.LCL || this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.RO_RO)) this.calculateOperationWM();
                        this.$scope.cargoListGrossWeightFieldsControl(this.$scope.model.GROSS_WEIGHT);
                    });
                }
            }
            this.updateCargoCalculatedFields();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private setCargoListDirty() {
        if (this.$scope.model.CARGO_LIST && this.$scope.model.CARGO_LIST.length) {
            this.$timeout(() => {
                for (const index in this.$scope.model.CARGO_LIST) {
                    this.$offerScope.selectorValidity("cargoPackage" + index);
                    this.$offerScope.setDirtyFields(['cargoVolume' + index, 'cargoLength' + index, 'cargoWidth' + index, 'cargoHeight' + index, 'cargoCBW' + index, 'cargoGrossWeight' + index]);
                }
            }, 100);
        }
    }

    private addDangerousCargo(): void {
        try {
            if (!this.$scope.model.DANGEROUS_CARGO) this.$scope.model.DANGEROUS_CARGO = [];
            const dangerousCargo: IDangerousCargo = {
                UNITY: null,
                CLASS: null,
                PACKING_GROUP: null
            }
            this.$scope.model.DANGEROUS_CARGO.push(dangerousCargo);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeDangerousCargo(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate('GENERAL.MESSAGES.CONFIRMATION.REMOVAL')
            });
            if (!modal) return;

            if (this.$scope.model.DANGEROUS_CARGO && this.$scope.model.DANGEROUS_CARGO.length > 0) {
                this.formService.block();
                if (this.$scope.model.DANGEROUS_CARGO.length == 1) this.$scope.model.DANGEROUS_CARGO = null;
                else this.$scope.model.DANGEROUS_CARGO.splice(index, 1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async removeFclEquipment(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate('GENERAL.MESSAGES.CONFIRMATION.REMOVAL')
            });
            if (!modal) return;

            if (this.$scope.model.FCL && this.$scope.model.FCL.length > 0) {
                this.formService.block();
                if (this.$scope.model.FCL.length == 1) this.$scope.model.FCL = null;
                else this.$scope.model.FCL.splice(index, 1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async removeBbroroEquipment(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate('GENERAL.MESSAGES.CONFIRMATION.REMOVAL')
            });
            if (!modal) return;

            if (this.$scope.model.BB_RORO && this.$scope.model.BB_RORO.length > 0) {
                this.formService.block();
                if (this.$scope.model.BB_RORO.length == 1) this.$scope.model.BB_RORO = null;
                else this.$scope.model.BB_RORO.splice(index, 1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async specSecureChange() {
        if (this.$scope.model.IS_INSURANCE) {
            if (!this.$scope.oldSecure) {
                const offerProduct = this.$offerScope.model.PRODUCT ? this.$offerScope.model.PRODUCT.ID : null;
                // Note: the product configuration registration will always have at most one registration.
                try {
                    this.formService.block();
                    let result = null
                    result = await this.getProductConfigurationList();
                    if (result && result.data) this.$scope.productConfigurationList = result.data;
                } catch (ex) {
                    this.formService.handleError(ex);
                } finally {
                    this.formService.unblock();
                }
                const productConfigurationSecure: IProductConfigurationSecure = this.$scope.productConfigurationList && this.$scope.productConfigurationList.length > 0 && this.$scope.productConfigurationList[0].SECURE && this.$scope.productConfigurationList[0].SECURE.length > 0 ? this.$scope.productConfigurationList[0].SECURE.find(secure => secure.PRODUCT.some(product => product.ID == offerProduct)) : null;
                const secureObject = {
                    CURRENCY: null,
                    INSURED_AMOUNT: null,
                    INSURANCE_PREMIUM: null,
                    INSURANCE_RECEIVING_MIN: null,
                    COMISSION_WITHOUT_PRIZE: null,
                    INSURANCE_PAYMENT_MIN: null,
                    INSURANCE_BROKER: null,
                    ID_CHARGE_NAME_EXHIBITION: null,
                    CHARGE_NAME_EXHIBITION: null,
                    APPLICATION_PAYMENT: null,
                    APPLICATION_RECEIVING: null
                };
                if (productConfigurationSecure) {
                    secureObject.INSURANCE_PREMIUM = productConfigurationSecure.INSURANCE_PREMIUM;
                    secureObject.INSURANCE_RECEIVING_MIN = productConfigurationSecure.INSURANCE_RECEIVING_MIN;
                    secureObject.COMISSION_WITHOUT_PRIZE = productConfigurationSecure.STANDARD_COMISSION_WITHOUT_PRIZE;
                    secureObject.INSURANCE_PAYMENT_MIN = productConfigurationSecure.INSURANCE_PAYMENT_MIN;
                    secureObject.INSURANCE_BROKER = productConfigurationSecure.INSURANCE_BROKER;
                    secureObject.ID_CHARGE_NAME_EXHIBITION = productConfigurationSecure.CHARGE_NAME_EXHIBITION.ID;
                    secureObject.CHARGE_NAME_EXHIBITION = productConfigurationSecure.CHARGE_NAME_EXHIBITION;
                    secureObject.APPLICATION_PAYMENT = productConfigurationSecure.APPLICATION_PAYMENT;
                    secureObject.APPLICATION_RECEIVING = productConfigurationSecure.APPLICATION_RECEIVING;
                }
                this.$scope.model.SECURE = secureObject;
            } else this.$scope.model.SECURE = angular.copy(this.$scope.oldSecure);
        } else this.$scope.model.SECURE = null;
    }

    private specRegrigeratedChange() {
        if (!this.$scope.model.IS_REFRIGERATED_CARGO) this.$scope.model.REFRIGERATED_CARGO = null;
    }

    private specCargoTreatmentChange() {
        if (!this.$scope.model.IS_CARGO_TREATMENT) this.$scope.model.CARGO_TREATMENT = null;
    }

    private async getChargeNameListByName(filter: IChargeNameListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const charges = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/chargeName/list/custom/exhibition`, filter, 30000, false);
            if (charges) result = charges.map(charge => { return { ID: charge.ID, NAME: charge.NAME, CODE: charge.CODE } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getApplicationListByName(product: SelectorModel[], typeCargo: SelectorModel[], search: string): Promise<IApplicationList[]> {
        let result: IApplicationList[] = [];
        let products = [];
        let typeCargos = [];
        try {
            if (search && search.length >= 2) {
                if (!product) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                if (!typeCargo) throw Error('Select type cargo first!');
                this.formService.block();
                products = product.map(item => item.ID);
                typeCargos = typeCargo.map(item => item.ID);
                const application = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/application/list/custom`, { search, products, typeCargos }, 10000, false);
                if (application) {
                    result = application.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PERCENT: x.PERCENT, APPLICATION_COMPLEMENT: x.APPLICATION_COMPLEMENT, INTERNAL_SEQUENCE: x.INTERNAL_SEQUENCE, FREE_TYPING_AMOUNT_CHARGE: x.FREE_TYPING_AMOUNT_CHARGE, CT_WITHOUT_DOC: x.CT_WITHOUT_DOC, NAME_INTL: x.NAME_INTL } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getLegalPersonListByName(filter: ILegalPersonListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        try {
            this.formService.block();
            const legalPersons = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/legalPerson/list/custom/offer`, filter, 30000, false);
            if (legalPersons) result = legalPersons.map(legalPerson => {
                return {
                    ID: legalPerson.ID,
                    NAME: legalPerson.SHORT_NAME,
                    CODE: legalPerson.CORPORATE_NAME,
                    ID_LEGAL_PERSON: legalPerson.ID_LEGAL_PERSON,
                    LEGAL_PERSON: legalPerson.LEGAL_PERSON
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private getProductConfigurationList(): Promise<SelectorModel[]> {
        return this.restService.newObjectPromise(`${this.$offerScope.productUrl}/productConfiguration/list/offer`, {}, 30000, false);
    }
    private hasInvalidMin(elementId: string): boolean {
        if (!elementId) return false;
        const invalidMinElements = angular.element(elementId).filter('.ng-invalid-min');
        const hasNgInvalidMin = invalidMinElements && invalidMinElements.length > 0;
        if (hasNgInvalidMin) this.formService.notifyError('Existem campos com valores mínimos não atendidos.');
        return hasNgInvalidMin;
    }

    private async viewLogDD(transaction: string, detail: IDetails) {
        try {
            let equipment: string = null;
            let type: string = null;
            let paymentNature: string = null;
            let identificador: string = '';
            let visualIdentifier: string = '';

            if (transaction) {
                visualIdentifier += transaction == 'DEST' ? 'Destino' : 'Origem';
            }

            if (detail) {
                equipment = detail.EQUIPMENT.map(x => x.NAME).join('_');
                type = detail.TYPE;
                paymentNature = detail.PAYMENT_NATURE.NAME;
                if (equipment) {
                    visualIdentifier += ` - ${equipment}`;
                }
                if (type) {
                    visualIdentifier += ` - ${type}`;
                }
                if (paymentNature) {
                    visualIdentifier += ` - ${paymentNature}`;
                }
            }

            this.formService.block();

            let log: IViewLog = {
                operation: 'history',
                number: visualIdentifier,
                list: [],
                show: true,
                showCloseButton: false,
                searchQuery: '',
                originalList: [],
            }

            if (transaction) {
                identificador += transaction;
            }

            if (detail) {
                equipment = detail.EQUIPMENT.map(x => x.ID).join('_');
                type = detail.TYPE;
                paymentNature = detail.PAYMENT_NATURE.ID;
                if (equipment) {
                    identificador += `-${equipment}`;
                }
                if (type) {
                    identificador += `-${type}`;
                }
                if (paymentNature) {
                    identificador += `-${paymentNature}`;
                }
            }

            this.requestDDHistory(this.$offerScope.model.ID.toString(), identificador).then(result => {
                log.list = result.data;
                log.originalList = angular.copy(log.list);
                this.$scope.log = log;
                this.$scope.customLogProperties = this.getCustomDDLogProperties();
                angular.element('#log-viewer').removeClass('ng-hide');
                this.formService.unblock();
            }).catch(ex => {
                this.formService.handleError(ex);
            });

            const modalId = this.modalService.newModal();
            await this.modalService.showModalConfirmation(
                {
                    modalID: modalId,
                    scope: this.$scope,
                    template: require('../view/modals/offerLog.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG"
                }
            );
            this.modalService.closeModal(modalId);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async viewLog(identifier: string, uuid?: string) {
        try {

            this.formService.block();
            let log: IViewLog = {
                operation: 'history',
                number: this.$offerScope.model.ID.toString(),
                list: [],
                show: true,
                showCloseButton: false,
                searchQuery: '',
                originalList: [],
            }

            this.requestHistory(log.number, identifier, uuid).then(result => {
                log.list = result.data;
                log.originalList = angular.copy(log.list);
                this.$scope.log = log;
                this.$scope.customLogProperties = this.getCustomLogProperties();
                angular.element('#log-viewer').removeClass('ng-hide');
                this.formService.unblock();
            }).catch(ex => {
                this.formService.handleError(ex);
            });

            const modalId = this.modalService.newModal();
            await this.modalService.showModalConfirmation(
                {
                    modalID: modalId,
                    scope: this.$scope,
                    template: require('../view/modals/offerLog.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG"
                }
            );
            this.modalService.closeModal(modalId);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private requestDDHistory(id: string, identificador: string): Promise<any> {
        return this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/cargo/detdem/viewLog/${id}/${identificador}`, 10000, null, false);
    }

    private temperatureValidation(temperature: number) {
        this.$timeout(() => {
            if (!temperature) return;
            if (this.$scope.model.REFRIGERATED_CARGO.MINIMUM_TEMPERATURE > this.$scope.model.REFRIGERATED_CARGO.MAXIMUM_TEMPERATURE) {
                this.$scope.form['specRefrigeratedCargoMaximumTemperature'].$setValidity('$valid', false);
            } else {
                this.$scope.form['specRefrigeratedCargoMaximumTemperature'].$setValidity('$valid', true);
            }
        }, 100);
    }

    private enableTotalGrossWeight() {
        const cargoList = this.$scope.model.CARGO_LIST;
        if (cargoList) {
            this.$scope.model.GROSS_WEIGHT = null;
            for (const cargo of cargoList) {
                cargo.GROSS_WEIGHT_UNITARY = null;
                cargo.GROSS_WEIGHT = null;
            }
            angular.element("#cargoGrossWeight").focus();
        }
    }

    private updateMask(field: string, index) {
        $(`#${field}_${index}`)['mask']('99:99');
    }

    private getCustomDDLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: "DET_DEM_ORIGIN", LABEL: "Det & Dem Origem" },
            { PROPERTY: "DET_DEM_DESTINATION", LABEL: "Det & Dem Destino" },
            { PROPERTY: "DETAILS", LABEL: "Detalhes" },
            { PROPERTY: "FREE_TIME", LABEL: "Free Time" },
            { PROPERTY: "ID", LABEL: "Id." },
            { PROPERTY: "NAME", LABEL: "Nome" },
            { PROPERTY: "CODE", LABEL: "Código" },
            { PROPERTY: "FEET", LABEL: "Feet" },
            { PROPERTY: "EQUIPMENT", LABEL: "Equipamento" },
            { PROPERTY: "FREE_TIME_DETAILS", LABEL: "Detalhes do Free Time" },
            { PROPERTY: "PERIOD", LABEL: "Período" },
            { PROPERTY: "FROM", LABEL: "De" },
            { PROPERTY: "UNTIL", LABEL: "Até" },
            { PROPERTY: "DAYS", LABEL: "Dias" },
            { PROPERTY: "CURRENCY", LABEL: "Moeda" },
            { PROPERTY: "VALUE", LABEL: "Valor" },
            { PROPERTY: "IMO", LABEL: "Carga Perigosa" },
            { PROPERTY: "INDEX", LABEL: "Ìndice" },
            { PROPERTY: "PAYMENT_NATURE", LABEL: "Natureza" },
            { PROPERTY: "TARIFF_TYPE", LABEL: "Tipo da Tarifa" },
            { PROPERTY: "TYPE", LABEL: "Tipo" },
        ];
        return props;
    }

    private requestHistory(id: string, identifier: string, uuid?: string): Promise<any> {
        return this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/cargo/viewLog/${id}/${identifier}${uuid ? '/' + uuid : ''}`, 10000, null, false);
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: "ACTIVE", LABEL: "Ativo" },
            { PROPERTY: "AMOUNT", LABEL: "Quantidade" },
            { PROPERTY: "EQUIPMENT", LABEL: "Equipamento" },
            { PROPERTY: "COMBINED", LABEL: "Combined" },
            { PROPERTY: "DET", LABEL: "Det" },
            { PROPERTY: "DEM", LABEL: "Dem" },
            { PROPERTY: "FOODGRADE", LABEL: "FoodGrade" },
            { PROPERTY: "OWS", LABEL: "OWS" },
            { PROPERTY: "STACKABLE", LABEL: "REGISTRATION.STACKABLE" },
            { PROPERTY: "SUPERTESTED", LABEL: "Supertestado" },
            { PROPERTY: "SECURE", LABEL: "Seguro" },
            { PROPERTY: "CURRENCY", LABEL: "Moeda corrente" },
            { PROPERTY: "INSURED_AMOUNT", LABEL: "Valor segurado" },
            { PROPERTY: "INSURANCE_PREMIUM", LABEL: "Prêmio segurado" },
            { PROPERTY: "COMISSION_WITHOUT_PRIZE", LABEL: "Comissão sem prêmio" },
            { PROPERTY: "INSURANCE_BROKER", LABEL: "Corretor de seguro" },
            { PROPERTY: "APPLICATION_PAYMENT", LABEL: "Aplicação para compra" },
            { PROPERTY: "APPLICATION_RECEIVING", LABEL: "Aplicação para venda" },
            { PROPERTY: "INSURANCE_PAYMENT_MIN", LABEL: "Mínimo compra" },
            { PROPERTY: "INSURANCE_RECEIVING_MIN", LABEL: "Mínimo Venda" },
            { PROPERTY: "CHARGE_NAME_EXHIBITION", LABEL: "Taxa" },

            // Cargo List
            { PROPERTY: "VOLUME", LABEL: "Volume" },
            { PROPERTY: "CUBIC_WEIGHT", LABEL: "Peso cúbico" },
            { PROPERTY: "GROSS_WEIGHT", LABEL: "Peso bruto" },
            { PROPERTY: "PACKAGE", LABEL: "Embalagem" },
            { PROPERTY: "LENGTH", LABEL: "Comprimento" },
            { PROPERTY: "WIDTH", LABEL: "Largura" },
            { PROPERTY: "HEIGHT", LABEL: "Altura" },
            { PROPERTY: "GROSS_WEIGHT_UNITARY", LABEL: "Peso bruto unitário" },

            // Specifications
            { PROPERTY: "UNITY", LABEL: "Unidade" },
            { PROPERTY: "CLASS", LABEL: "Classe" },
            { PROPERTY: "PACKING_GROUP", LABEL: "Grupo Embalagem" },
        ];
        return props;
    }
}

import { isDefined, IHttpService } from 'angular';
import { ISessionService } from '@services/SessionService';


export interface IRestService {
    getObject: (url: string, timeout?: number, useBaseUrl?: boolean) => any;
    getObjectByParams: (url: string, params: string | object, timeout: number, useBaseUrl?: boolean) => any;
    updateObject: (url: string, data: any, timeout?: number, useBaseUrl?: boolean) => any;
    newObject: (url: string, data: any, timeout?: number, useBaseUrl?: boolean) => any;
    newObjectPromise: (url: string, data: any, timeout?: number, useBaseUrl?: boolean) => any;
    deleteObject: (url: string, timeout: number, useBaseUrl?: boolean) => any;
    getStandAloneObject: (url: string, token: string, timeout: number, useBaseUrl?: boolean) => any;
    getObjectAsPromise: (url: string, timeout?: number, id?: string | number, useBaseUrl?: boolean) => Promise<any>;
    getObjectByParamsAsPromise: (url: string, params: object, timeout: number, id?: string | number, useBaseUrl?: boolean) => Promise<any>;
}

RestService.$inject = ['$http', 'SessionService', 'config'];
export function RestService($http: IHttpService, SessionService: ISessionService, config) {
    //REST GET
    this.getObject = function (_url, _timeout = 15000, baseUrl = true) {
        const url: string = baseUrl ? config.restfulBaseUrl + _url : _url;
        return $http({
            method: 'get',
            url: url,
            cache: false,
            timeout: _timeout
        }).catch(ex => {
            throw ex;
        });
    };

    this.getObjectByParams = function (_url, params, _timeout, baseUrl = true) {
        const url: string = baseUrl ? config.restfulBaseUrl + _url : _url;
        return $http({
            method: 'get',
            url: url,
            params: params,
            cache: false,
            timeout: _timeout
        }).catch(ex => {
            throw ex;
        });
    };

    //REST PUT
    this.updateObject = function (_url, _data, _timeout = 15000, baseUrl = true) {
        const url: string = baseUrl ? config.restfulBaseUrl + _url : _url;
        return $http({
            method: 'put',
            url: url,
            data: _data,
            cache: false,
            timeout: _timeout
        }).catch(ex => {
            throw ex;
        });
    };

    //REST POST
    this.newObject = function (_url, _data, _timeout = 15000, baseUrl = true) {
        const url: string = baseUrl ? config.restfulBaseUrl + _url : _url;
        return $http({
            method: 'post',
            url: url,
            data: _data,
            cache: false,
            timeout: _timeout
        }).catch(ex => {
            throw ex;
        });
    };

    //REST DELETE
    this.deleteObject = function (_url, _timeout = 15000, baseUrl = true) {
        const url: string = baseUrl ? config.restfulBaseUrl + _url : _url;
        return $http({
            method: 'delete',
            url: url,
            cache: false,
            timeout: _timeout
        }).catch(ex => {
            throw ex;
        });
    };

    //REST STANDALONE (no auth) GET
    this.getStandAloneObject = function (_url, _token, _timeout = 15000, baseUrl = true) {
        const url: string = baseUrl ? config.restfulBaseUrl + _url : _url;
        return $http({
            method: 'get',
            headers: {
                'Authorization': 'Bearer ' + _token
            },
            url: url,
            cache: false,
            timeout: _timeout
        }).catch(ex => {
            throw ex;
        });
    };

    //GET PROMISE
    this.getObjectAsPromise = function (_url, _timeout, _id, baseUrl = true) {
        if (!_url) {
            console.log('NULL URL PARAMETER');
            return;
        }
        if (!_timeout) _timeout = 15000;
        const self = this;
        return new Promise(function (resolve, reject) {
            try {
                self.getObject(_url, _timeout, baseUrl).then(function successCallback(successResponse) {
                    try {
                        const data = (isDefined(successResponse.data.data.data)) ? successResponse.data.data.data : successResponse.data.data;
                        const columns = (isDefined(successResponse.data.data.columns)) ? successResponse.data.data.columns : null;
                        resolve({ id: _id, columns: columns, data: data });

                    } catch (ex) {
                        reject(`Rest Service Get Error(${_url}): ${ex.message}`);
                    }

                }, function errorCallback(errorResponse) {
                    if (errorResponse.status === 404 && errorResponse.statusText !== 'invalid') {
                        resolve({ id: _id, columns: null, data: null });
                    } else {
                        reject(errorResponse);
                    }
                }).catch(ex => {
                    reject(ex);
                });
            } catch (ex) {
                reject(ex);
            }
        });
    };

    this.getObjectByParamsAsPromise = function (_url: string, params: object, _timeout: number, _id: string, baseUrl = true) {
        if (!_url) {
            console.log('NULL URL PARAMETER');
            return;
        }
        if (!_timeout) _timeout = 15000;
        const self = this;
        return new Promise(function (resolve, reject) {
            try {
                self.getObjectByParams(_url, params, _timeout, baseUrl).then(function successCallback(successResponse) {
                    try {
                        const data = (isDefined(successResponse.data.data.data)) ? successResponse.data.data.data : successResponse.data.data;
                        const columns = (isDefined(successResponse.data.data.columns)) ? successResponse.data.data.columns : null;
                        //set data
                        resolve({ id: _id, columns: columns, data: data });

                    } catch (ex) {
                        reject(`Rest Service Get Error(${_url}): ${ex.message}`);
                    }

                }, function errorCallback(errorResponse) {
                    if (errorResponse.status === 404 && errorResponse.statusText !== 'invalid') {
                        resolve({ id: _id, columns: null, data: null });
                    } else {
                        reject(errorResponse);
                    }
                }).catch(ex => {
                    reject(ex);
                });
            } catch (ex) {
                reject(ex);
            }
        });
    };

    this.newObjectPromise = function (_url, _data, _timeout = 15000, baseUrl = true) {
        const self = this;
        return new Promise(function (resolve, reject) {
            _data['timeout'] = _timeout;
            self.newObject(_url, _data, _timeout, baseUrl).then(function successCallback(success) {
                const result = (isDefined(success.data.data.data) && !isDefined(success.data.data.columns)) ? success.data.data.data : (isDefined(success.data.data)) ? success.data.data : null;
                resolve(result);
            }, function errorCallback(error) {
                reject(error);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

}

import * as angular from 'angular';
require('oclazyload');

import "@uirouter/angularjs";
import 'angular-block-ui';
import "ngstorage";
import 'angular-sanitize';
import 'angular-translate';
import 'angular-translate-loader-partial';
import 'messageformat';
import 'angular-translate-interpolation-messageformat';
import "angular-jwt";
import config from './Config';
import initLog from './LogConfig';
import initRoute from './RouteConfig';
import initBlockUI from './BlockConfig';
import { SessionService2 } from '@services/SessionService';
import { HttpInterceptor } from './service/HttpInterceptor';
import { BootstrapController } from './controller/BootstrapController';
import { WEBSOCKET_MODULE } from '../websocket/WebSocketModule';
import { permission, uiPermission } from 'angular-permission';
import { ExternalService } from '../services/ExternalService';
import { NotificationService } from '../services/NotificationService';

const BOOTSTRAP_MODULE = angular
    .module("bootstrap", ['ui.router', 'pascalprecht.translate', permission, uiPermission, 'oc.lazyLoad', 'blockUI', 'ngStorage', 'ngSanitize', 'angular-jwt', WEBSOCKET_MODULE.name])
    .constant('config', config)
    .service('SessionService', SessionService2)
    .factory('HttpInterceptor', HttpInterceptor)
    .config(['$compileProvider', '$ocLazyLoadProvider', '$httpProvider', '$permissionProvider', '$translateProvider', '$translatePartialLoaderProvider', function ($compileProvider: ng.ICompileProvider, $ocLazyLoadProvider: oc.ILazyLoadProvider, $httpProvider: ng.IHttpProvider, $permissionProvider, $translateProvider) {
        $compileProvider.debugInfoEnabled(config.debug);
        $ocLazyLoadProvider.config({ debug: config.debugLazyLoad });
        $httpProvider.useApplyAsync(true);
        $httpProvider.interceptors.push('HttpInterceptor');
        $permissionProvider.suppressUndefinedPermissionWarning((config.environment === 'prod'));
        $translateProvider
            .translations('en-US', require('../../i18n/general/en-US.json'))
            .translations('pt-BR', require('../../i18n/general/pt-BR.json'))
            .translations('en-US', require('../../i18n/basic_data/en-US.json'))
            .translations('pt-BR', require('../../i18n/basic_data/pt-BR.json'))
            .translations('en-US', require('../../i18n/entity/en-US.json'))
            .translations('pt-BR', require('../../i18n/entity/pt-BR.json'))
            .translations('en-US', require('../../i18n/financial/en-US.json'))
            .translations('pt-BR', require('../../i18n/financial/pt-BR.json'))
            .translations('en-US', require('../../i18n/operational/en-US.json'))
            .translations('pt-BR', require('../../i18n/operational/pt-BR.json'))
            .translations('en-US', require('../../i18n/product/en-US.json'))
            .translations('pt-BR', require('../../i18n/product/pt-BR.json'))
            .translations('en-US', require('../../i18n/registration/en-US.json'))
            .translations('pt-BR', require('../../i18n/registration/pt-BR.json'))
            .translations('en-US', require('../../i18n/route/en-US.json'))
            .translations('pt-BR', require('../../i18n/route/pt-BR.json'))
            /* Solução de carregar os termos por módulo
            .useLoader('$translatePartialLoader', {
                urlTemplate: 'i18n/{part}/{lang}.json'
            })
            */
            .preferredLanguage('en-US')
            .fallbackLanguage('en-US')
            .addInterpolation('$translateMessageFormatInterpolation')
            .useLoaderCache(true);
    }])
    .controller('BootstrapController', BootstrapController)
    .service('NotificationService', NotificationService)
    .service('ExternalService', ExternalService);

initLog(BOOTSTRAP_MODULE.name);
initRoute(BOOTSTRAP_MODULE.name);
initBlockUI(BOOTSTRAP_MODULE.name);

//bootstrap the app
angular.bootstrap(document, [BOOTSTRAP_MODULE.name], {
    strictDi: true
});
import angular = require('angular');
import { IColumnDef, IGridOptions } from "ui-grid";
import { SSEService } from '@appServices/SSEService';
import { GridFormService, IGridFormController, IGridFormServiceScope, IMonacoRequestLog } from "@services/GridFormService"
import { IMonacoColumnDef } from '@services/GridService2';
import { IRestService } from '@services/RestService';
import { ISessionService } from "@services/SessionService";
import { IModalService } from '@services/ModalService';
import { DataProductService } from '@services/DataProductService';
import { IDocumentError } from '@models/interface/common/IDocumentError';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IAnalyzeCollection } from '@models/interface/product/AnalyzeCollection';
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { BrowserTitle } from "../../common/BrowserTitle";
import { IAnalyzeCollectionDataParameter } from "../../common/model/ModelParameter";
import { EOperation } from '@enums/GenericData';

interface IAnalyzeCollectionScope extends IGridFormServiceScope {
    model: IAnalyzeCollection;
    scopeBeforeSave: IAnalyzeCollection;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    isEditing: boolean;
    gridOptions: IGridOptions;
    user: any;
    editAnalyzeCollection: (analyzeCollection: IAnalyzeCollection) => Promise<void>;
    viewAnalyzeCollection: (analyzeCollection: IAnalyzeCollection) => Promise<void>;
    viewLogAnalyzeCollection: (analyzeCollection: IAnalyzeCollection) => Promise<void>;
    copyAnalyzeCollection: (analyzeCollection: IAnalyzeCollection) => Promise<void>;
    goToAnalyzeCollectionData: (collection: string) => void;
    openModalStatisticCollection: (id: number, documentError: IDocumentError[]) => void;
}

export class AnalyzeCollectionRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IAnalyzeCollectionScope;
    private formName: string;
    private gridName: string;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private restService: IRestService;
    private modalStatisticCollectionId: number;
    private sessionService: ISessionService;
    private modalService: IModalService;
    private dataProductService: DataProductService;
    private SSEService: SSEService;

    constructor($injector: ng.Injectable<any>, $scope: IAnalyzeCollectionScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.restService = $injector.get('RestService');
        this.sessionService = $injector.get('SessionService');
        this.modalService = $injector.get('ModalService');
        this.dataProductService = $injector.get('DataProductService');
        this.$scope.isEditing = false;
        this.formName = 'AnalyzeCollection';
        this.gridName = 'GRID_ANALYZE_COLLECTION';
        this.SSEService = new SSEService($injector, $scope, this.formService);
    }

    getUrlProduct() {
        try {
            const baseRoute = '/product';
            const urlProduct = this.config.productUrl + baseRoute;
            return urlProduct;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', this.formName, 'GENERAL.MENU.COLLECTION_DOCUMENT_STATISTIC', true);
            await this.initGrid(this.gridName, '/analyzeCollection/list', true, true, null, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        try {
            this.$scope.editAnalyzeCollection = async (analyzeCollection: IAnalyzeCollection): Promise<void> => {
                let blockedObject = {
                    ID: analyzeCollection.ID,
                    NAME: analyzeCollection.COLLECTION,
                    EMAIL: this.$scope.user['email'],
                    FORM_NAME: this.gridName
                };
                this.SSEService.closeEvents();
                this.SSEService.setBlockedObject(blockedObject);
                this.SSEService.initEvents();
                this.SSEService.events.onmessage = async (event) => {
                    const parsedData = JSON.parse(event.data);
                    if (!parsedData.status) {
                        const result = await this.SSEService.generate(parsedData);
                        if (result && !result.status) {
                            this.$rootScope.refreshPage();
                            return;
                        }
                        if (this.$scope.operation !== EOperation.VIEW || analyzeCollection.ID !== this.$scope.model.ID) this.$scope.view(analyzeCollection);
                    } else if (this.$scope.operation !== EOperation.EDIT || analyzeCollection.ID !== this.$scope.model.ID) {
                        this.$scope.edit(analyzeCollection);
                    }
                };
            }

            this.$scope.viewAnalyzeCollection = async (analyzeCollection: IAnalyzeCollection): Promise<void> => {
                this.SSEService.closeEvents();
                this.$scope.view(analyzeCollection);
            }

            this.$scope.viewLogAnalyzeCollection = async (analyzeCollection: IAnalyzeCollection): Promise<void> => {
                this.SSEService.closeEvents();
                this.$scope.viewLog(analyzeCollection);
            }

            this.$scope.copyAnalyzeCollection = async (analyzeCollection: IAnalyzeCollection): Promise<void> => {
                this.SSEService.closeEvents();
                this.$scope.copy(analyzeCollection);
            }

            this.$scope.goToAnalyzeCollectionData = (collection: string) => {
                if (collection == null) throw Error("collection is null");
                this.sessionService.openTab("app.admin.analyzeCollectionData", <IAnalyzeCollectionDataParameter>{ COLLECTION: collection });
            }
            this.$scope.openModalStatisticCollection = async (id: number, documentError: IDocumentError[]): Promise<void> => {
                this.openModalStatisticCollection(id, documentError);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        try {
            this.$scope.model = {
                _id: null,
                ID: null,
                COLLECTION: null,
                TOTAL_DOCUMENTS: 0,
                ACTIVE: true,
                CREATED_AT: null,
                CREATED_BY: null,
                UPDATED_AT: null,
                UPDATED_BY: null,
                SEARCH_FIELDS: null
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initGridColumns(columns: string[]): IColumnDef[] {
        try {
            const gridColumns = new GridColumnBuilder([]);

            const view = `<a ng-click="grid.appScope.viewAnalyzeCollection(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
            const edit = `<a ng-click="grid.appScope.editAnalyzeCollection(row.entity)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
            const copy = `<a ng-click="grid.appScope.copyAnalyzeCollection(row.entity)" class="text-orange copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
            const modalIntegration = `<a ng-click="grid.appScope.openModalStatisticCollection(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="(row.entity.DOCUMENT_ERROR && row.entity.DOCUMENT_ERROR.length > 0) ? 'text-danger' : 'text-green'" tooltip-placement="auto top" uib-tooltip="{{ 'REGISTRATION.VIEW_STATISTICS_COLLECTION' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;`;
            const analyzeCollectionData = `<a ng-click="grid.appScope.goToAnalyzeCollectionData(row.entity.COLLECTION)" ng-class="(row.entity.DOCUMENT_ERROR && row.entity.DOCUMENT_ERROR.length > 0) ? 'text-danger' : 'text-green'" tooltip-placement="auto top" uib-tooltip="{{ 'REGISTRATION.GO_STATISTCS_COLLECTION' | translate }}" tooltip-append-to-body="true"><i class="fa fa-bar-chart icon"></i></a>&nbsp;&nbsp;`;
            const viewLog = `<a ng-click="grid.appScope.viewLogAnalyzeCollection(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;

            const colActions: IMonacoColumnDef = {
                name: "acoes",
                displayName: "GENERAL.ACTIONS",
                width: 150,
                cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${copy} ${modalIntegration} ${analyzeCollectionData} ${viewLog}</div>`,
                enableFiltering: false,
                enableSorting: false,
                enableHiding: false,
                enableColumnMoving: false,
                enableColumnResizing: false,
                pinnedLeft: true,
                enablePinning: false
            };

            gridColumns.addColumn(colActions);
            const newColumnDefs: IMonacoColumnDef[] = this.buildColumns(columns);
            for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) };

            return <IColumnDef[]>gridColumns.$columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            let gridColumns: IMonacoColumnDef[] = [];

            const defCols: IMonacoColumnDef[] = [];
            defCols.push(<IMonacoColumnDef>{ name: "COLLECTION", displayName: "GENERAL.COLLECTION", width: 350 });
            defCols.push(<IMonacoColumnDef>{ name: 'TOTAL_DOCUMENTS', displayName: 'GENERAL.COLLECTION_TOTAL_DOCUMENTS', width: 200 });
            defCols.push(<IMonacoColumnDef>{ name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 80, cellFilter: "YesOrNo" });
            defCols.push(<IMonacoColumnDef>{ name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm:ss'" });
            defCols.push(<IMonacoColumnDef>{ name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm:ss'" });
            defCols.push(<IMonacoColumnDef>{ name: 'ID', displayName: 'GENERAL.ID', width: 80 });

            columns.forEach(column => {
                const findCol: IMonacoColumnDef = defCols.find(coll => coll.name == column);
                if (findCol) gridColumns.push(findCol);
            });

            return <IMonacoColumnDef[]>gridColumns;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initDependencies(): Promise<any> {
        const self: AnalyzeCollectionRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([]).then((result: any) => {
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    async register(params: IAnalyzeCollection): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
            this.$scope.disableElements(false);
            this.$scope.isEditing = false;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")} ${this.$scope.model.COLLECTION}`;
            BrowserTitle.$id = `${this.$scope.model.COLLECTION}`;
            this.$scope.disableElements(true);
            this.$scope.isEditing = false;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")} ${this.$scope.model.COLLECTION}`;
            BrowserTitle.$id = `${this.$scope.model.COLLECTION}`;
            this.$scope.disableElements(false);
            this.$scope.isEditing = true;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        let proceed: boolean = true;
        try {
            this.SSEService.closeEvents();
            this.$scope.isEditing = false;
            if (proceed) { }
        } catch (ex) {
            this.handleError(ex);
            return false;
        }
        return proceed;
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        return {
            route: `/analyzeCollection/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    async cancel(): Promise<void> {
        try {
            this.$scope.isEditing = false;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: "ID",
                LABEL: "GENERAL.ID",
            },
            {
                PROPERTY: "COLLECTION",
                LABEL: "GENERAL.COLLECTION",
            },
            {
                PROPERTY: "TOTAL_DOCUMENTS",
                LABEL: "GENERAL.COLLECTION_TOTAL_DOCUMENTS",
            },
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE",
            },
            {
                PROPERTY: "CREATED_AT",
                LABEL: "GENERAL.CREATED_AT",
            },
            {
                PROPERTY: "CREATED_BY",
                LABEL: "GENERAL.CREATED_BY",
            },
            {
                PROPERTY: "UPDATED_AT",
                LABEL: "GENERAL.UPDATED_AT",
            },
            {
                PROPERTY: "UPDATED_BY",
                LABEL: "GENERAL.UPDATED_BY",
            }
        ];
        return <ICustomLogProperties[]>props;
    }

    private async openModalStatisticCollection(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalStatisticCollectionId = this.modalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.modalService.showModalIntegrationRedundance({ modalID: this.modalStatisticCollectionId, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: 'Integration Product/Operation' });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                this.formService.block();
                const rc = await this.dataProductService.post(`/sync/analyzeCollection`, { "id": [id] }, 120000);
                if (rc && rc.data && rc.data.data && rc.status == 200) success = true;
            }
        } catch (ex) {
            this.formService.handleError(this.formService.getTranslate("GENERAL.ERROR_SENDING_REQUEST"));
        } finally {
            this.formService.unblock();
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const collectionData = await this.getAnalyzeCollectionById(id);
                    if (row && collectionData && collectionData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = collectionData.DOCUMENT_ERROR;
                        documentError = collectionData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError(this.formService.getTranslate("GENERAL.ERROR_DURING_REQUEST"));
        } finally {
            return documentError;
        }
    }

    private async getAnalyzeCollectionById(id: number): Promise<IAnalyzeCollection> {
        try {
            if (!id) throw new Error("Missing id parameter in getAnalyzeCollectionById");
            this.formService.block();
            const analyzeCollection = await this.restService.getObject(`${this.$baseUrl}/analyzeCollection/getById/${id}`, 30000, false);
            return <IAnalyzeCollection>analyzeCollection.data.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

}

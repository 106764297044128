import angular = require('angular');
import { IRestService } from "@services/RestService";
import { FormService2, IFormServiceScope } from '@services/FormService2';
import { ISessionService } from '@services/SessionService';
import { IModalService } from '@services/ModalService';
import { IMonacoRequestLog } from '@services/GridFormService';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IApplication, IEquipment } from '@models/interface/product/OfferCharge';
import { IApplicationList } from '@models/interface/product/ApplicationModel';
import { IEquipmentList } from '@models/interface/product/Offer';
import { IParamsProfitShareRulesProfitShare, IParamsProfitShareRulesProfitShareEquipType } from '@models/interface/product/ParamsProfitShareModel'
import { EProductId, ECargoTypeId, EOfferSituationId, EForwardedById, ETransactionId, EApplicationComplementId, EOperation, EOfferAdditionalSituationId, EAgentSituationId } from '@enums/GenericData';
import { IOfferManagementModel, IOfferProfitShareDetail, IHolderSelector, IOfferProfitShareRulesProfitShare, IOfferAgentsResult } from '../../commercial/model/OfferModel';
import { SelectorModel } from '../../common/model/SelectorModel';
import { IOfferScope, ECollapseState } from '../../commercial/controller/OfferRegisterController';
import { ILinkParameter } from "../../common/model/ModelParameter";
import { ILegalPersonListCustomFilter } from '../../registration/model/LegalPersonModel';
import { IPhysicalPersonListCustomFilter } from '../../registration/model/PhysicalPersonModel';
import { IOfferOptionProfitShareDetailApplicationParam } from '../../product/model/OfferModel';
import { HelperService } from "@services/HelperService";

interface IOfferManagementProfitShareAmountResult {
    AMOUNT: number;
    TOTAL_MIN_APPLICATION: number;
}

interface IOfferProfitShareModalScope extends IFormServiceScope {
    profitShareDetails: IOfferProfitShareDetail;
    oldProfitShareDetails: IOfferProfitShareDetail;
    compositionCharges: IOfferManagementProfitShareCharge[];
    oldCompositionCharges: IOfferManagementProfitShareCharge[];
    shareItemIndexControl: number[];
    hierarchyList: number[];
    forwardedByList: SelectorModel[];
    transactionList: SelectorModel[];
    holderList: IHolderSelector[];
    applicationList: IApplicationList[];
    equipmentList: IEquipmentList[];
    feetList: SelectorModel[];
    applyProfitShareModal: () => Promise<void>;
    goToParamsProfitShare: (id: number) => void;
    closeProfitShareModal: () => Promise<void>;
    recalculate: () => Promise<void>;
    addProfitShare: () => void;
    removeProfitShare: (profitShareIndex: number) => Promise<void>;
    addProfitShareEquipType: (profitShareIndex: number) => void;
    removeProfitShareEquipType: (profitShareIndex: number, equipTypeIndex: number) => Promise<void>;
    hierarchyFilter: () => Function;
    forwardedByFilter: (forwardedBy: SelectorModel) => boolean;
    transactionFilter: (transaction: SelectorModel) => boolean;
    equipTypeFilter: (profitShareIndex: number) => Function;
    equipFeetFilter: (profitShareIndex: number) => Function;
    isProfitShareCompany: (profitShare: SelectorModel) => boolean;
    isTransactionOther: (transaction: SelectorModel) => boolean;
    isApplicationEquipment: (application: IApplicationList) => boolean;
    getHolderListByName: (search: string) => Promise<void>;
    getApplicationListByName: (search: string) => Promise<void>;
    transactionChange: (profitShareIndex: number) => Promise<void>;
    updateRuleCompanyHierarchy: () => void;
    updateRuleCompanyPercent: () => void;
    changeApplication: (profitShareIndex: number) => void;
    changeEquipType: (profitShareIndex: number, equipTypeIndex: number) => void;
    changeFeet: (profitShareIndex: number, equipTypeIndex: number) => void;
    updateAmount: (profitShareIndex: number, equipTypeIndex: number) => Promise<void>;
    applicationParamValue: (equipeType: IParamsProfitShareRulesProfitShareEquipType, field: string) => number;
}

interface IOfferManagementProfitSharePaymentReceivingChargeCharge {
    APPLICATION: IApplication;
    EQUIPMENT: IEquipment;
    VEHICLE_TYPE: SelectorModel;
    WEIGHT_RANGE: SelectorModel[];
}

interface IOfferManagementProfitSharePaymentReceivingCharge {
    AMOUNT: number;
    CHARGE: IOfferManagementProfitSharePaymentReceivingChargeCharge;
    CURRENCY: SelectorModel;
    TOTAL: number;
}

interface IOfferManagementProfitShareCharge {
    CHARGE_NAME: SelectorModel;
    CHARGE_VALUE: number;
    PAYMENT_CHARGE: IOfferManagementProfitSharePaymentReceivingCharge;
    RECEIVING_CHARGE: IOfferManagementProfitSharePaymentReceivingCharge;
    PROFIT_SHARE: boolean;
}

interface IOfferManagementScope extends ng.IScope {
    log: IViewLog;
    model: IOfferManagementModel;
    oldModel: IOfferManagementModel;
    offerFcsFciList: SelectorModel[];
    offerFcsList: SelectorModel[];
    offerFciList: SelectorModel[];
    offerSituationList: SelectorModel[];
    offerAdditionalSituationList: SelectorModel[];
    modalService: IModalService;
    customLogProperties: ICustomLogProperties[];
    dateOptionsAccountRequest: {
        formatYear: string
        maxDate: Date
        minDate: Date
        startingDay: number
    };
    collapseManagement: () => void;
    getTypeCargoId: () => string;
    getProduct: () => string;
    getListTypeCargo: (type: string) => string;
    openIndicatorDetails: () => Promise<void>;
    openProfitShareDetails: () => void;
    offerSituationChange: () => void;
    saveOfferTabManagement: () => void;
    isOfferTypeStudyOrSpot: () => boolean;
    isFcsFciRequired: (currentSituation: SelectorModel) => boolean;
    isCollapseIn(): boolean;
    viewLog: () => void;
    viewLogIndicators: () => void;
    viewLogProfitShare: () => void;
}

export class OfferManagementRegisterController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IOfferManagementScope;
    private $offerScope: IOfferScope;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private $sce: angular.ISCEService;
    private $filter: ng.FilterFactory;
    private restService: IRestService;
    private formService: FormService2;
    private modalService: IModalService;
    private sessionService: ISessionService;
    private modalProfitShareId: number;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IOfferManagementScope) {
        this.$scope = $scope;
        this.$offerScope = <IOfferScope>$scope.$parent.$parent;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.$sce = $injector.get('$sce');
        this.$filter = $injector.get('$filter');
        this.restService = $injector.get('RestService');
        this.helperService = $injector.get('HelperService');
        this.formService = this.$offerScope.formService;
        this.modalService = this.$offerScope.modalService;
        this.sessionService = this.$offerScope.sessionService;
        this.modalProfitShareId = 0;
        this.$scope.dateOptionsAccountRequest = {
            formatYear: 'yy',
            maxDate: new Date(),
            minDate: new Date(1970, 1, 1),
            startingDay: 1
        };
        this.initScopeFunctions();
    }

    async $onInit(): Promise<void> {
        try {
            this.initModel();
            await this.initDependencies();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initModel(): void {
        this.$scope.model = {
            ACCOUNT_REQUEST: null,
            INDICATORS: null,
            OFFER_SITUATION: null,
            PROFIT_SHARE_ITEMS: null,
            PROFIT_SHARE_ITEMS_CURRENCY: null,
            ADDITIONAL_SITUATION: null,
            OFFER_CODE_LINK: null,
            AGENT_SITUATION: null
        };
    }

    private initScopeFunctions(): void {
        this.$scope.collapseManagement = () => {
            this.collapseManagement();
        }
        this.$scope.getTypeCargoId = () => {
            return this.$offerScope.model && this.$offerScope.model.TYPE_CARGO ? this.$offerScope.model.TYPE_CARGO.ID : null;
        }

        this.$scope.openIndicatorDetails = async () => {
            await this.openIndicatorDetails();
        }

        this.$scope.openProfitShareDetails = () => {
            this.openProfitShareDetails();
        }

        this.$scope.offerSituationChange = () => {
            // Update Offer Situation Date
            if (this.$scope.model.OFFER_SITUATION.CURRENT_SITUATION.ID !== this.$scope.oldModel.OFFER_SITUATION.CURRENT_SITUATION.ID) this.$scope.model.OFFER_SITUATION.DATE = new Date();
            else this.$scope.model.OFFER_SITUATION.DATE = this.$scope.oldModel.OFFER_SITUATION.DATE;

            if (this.$scope.model.OFFER_SITUATION.CURRENT_SITUATION.ID != EOfferSituationId.APROVADA && this.$scope.model.OFFER_SITUATION.CURRENT_SITUATION.ID != EOfferSituationId.REPROVADA) this.$scope.model.OFFER_SITUATION.FCS_FCI = null;
            if (this.$scope.model.OFFER_SITUATION.CURRENT_SITUATION.ID == EOfferSituationId.REPROVADA) {
                this.$scope.offerFcsFciList = this.$scope.offerFciList;
                this.$scope.model.OFFER_SITUATION.FCS_FCI = null;
            } else if (this.$scope.model.OFFER_SITUATION.CURRENT_SITUATION.ID == EOfferSituationId.APROVADA) {
                this.$scope.offerFcsFciList = this.$scope.offerFcsList;
                this.$scope.model.OFFER_SITUATION.FCS_FCI = null;
            }
        }

        this.$scope.saveOfferTabManagement = () => {
            this.saveOfferTabManagement();
        }

        this.$scope.isOfferTypeStudyOrSpot = () => {
            return this.$offerScope.model && this.$offerScope.model.OFFER_TYPE && (this.$offerScope.model.OFFER_TYPE.ID == '1' || this.$offerScope.model.OFFER_TYPE.ID == '2');
        }

        this.$scope.isFcsFciRequired = (currentSituation: SelectorModel) => {
            return currentSituation && (currentSituation.ID == EOfferSituationId.APROVADA || currentSituation.ID == EOfferSituationId.REPROVADA);
        }

        this.$scope.isCollapseIn = (): boolean => {
            return this.$offerScope.collapseState.panel == ECollapseState.MANAGEMENT && !this.$offerScope.collapseState.released;
        }

        this.$scope.viewLog = () => {
            this.viewLog();
        }

        this.$scope.viewLogIndicators = () => {
            this.viewLogIndicators();
        }

        this.$scope.viewLogProfitShare = () => {
            this.viewLogProfitShare();
        }

        this.$scope.getProduct = () => {
            const productId = this.$offerScope.model && this.$offerScope.model.PRODUCT.ID ? this.$offerScope.model.PRODUCT.ID : null;
            if (productId) {
                switch (productId) {
                    case EProductId.MARITIME_IMPORT:
                        return EProductId.MARITIME_IMPORT;
                    case EProductId.MARITIME_EXPORT:
                        return EProductId.MARITIME_EXPORT;
                    case EProductId.AIR_EXPORT:
                        return EProductId.AIR_EXPORT;
                    case EProductId.AIR_IMPORT:
                        return EProductId.AIR_IMPORT;
                    case EProductId.ROAD_EXPORT:
                        return EProductId.ROAD_EXPORT;
                    case EProductId.ROAD_IMPORT:
                        return EProductId.ROAD_IMPORT;
                    default:
                        return null;
                }
            }
        }

        this.$scope.getListTypeCargo = (type: string) => {
            switch (type) {
                case 'AIR':
                    return ECargoTypeId.AIR;
                case 'BREAK_BULK':
                    return ECargoTypeId.BREAK_BULK;
                case 'FCL':
                    return ECargoTypeId.FCL;
                case 'LCL':
                    return ECargoTypeId.LCL;
                case 'ROAD':
                    return ECargoTypeId.ROAD;
                case 'RO_RO':
                    return ECargoTypeId.RO_RO;
                default:
                    return null;
            }
        }

    }

    async initDependencies(): Promise<any> {
        const self: OfferManagementRegisterController = this;

        this.initCollapseEvents();

        this.$scope.$on('offerManagementSave', () => {
            this.saveOfferTabManagement();
        });

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericValue("offer_fcs"),
                self.getGenericValue("offer_fci"),
                self.getGenericValue("offer_situation"),
                self.getGenericValue("offer_additional_situation"),
            ]).then(async (result: any) => {
                self.$scope.offerFcsList = result[0];
                self.$scope.offerFciList = result[1];
                self.$scope.offerSituationList = result[2];
                self.$scope.offerAdditionalSituationList = result[3];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private initCollapseEvents() {
        this.$scope.$on('offerManagementCollapse', () => {
            this.collapseManagement();
        });

        const collapseManagement = angular.element("#collapseManagement");
        if (collapseManagement) {
            collapseManagement.on('shown.bs.collapse', (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseManagementHeading").attr('aria-expanded', 'true');
                    // update collapse state
                    this.$offerScope.collapseState = { panel: ECollapseState.MANAGEMENT, released: false, nextState: null };
                    this.$offerScope.repositionPanels('managementRow', true);
                    this.getOfferTabsManagement();
                    this.$offerScope.disableElements(this.$offerScope.operation == EOperation.VIEW);
                }
            });
            collapseManagement.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseManagementHeading").attr('aria-expanded', 'false');
                    this.$scope.model = null;
                    this.$scope.oldModel = null;
                }
            });
        }
    }

    private async collapseManagement() {
        try {
            if (this.$offerScope.collapseState.released || this.$offerScope.collapseState.panel == ECollapseState.MANAGEMENT) {
                const collapseManagement = angular.element("#collapseManagement");
                if (collapseManagement) {
                    const isCollapsed = angular.element("#collapseManagementHeading").attr("aria-expanded") == "true";
                    if (isCollapsed) {
                        if (this.$offerScope.hasChanges(this.$scope.model, this.$scope.oldModel)) {
                            const confirm = await this.$offerScope.modalSaveConfirmation("GENERAL.MANAGEMENT", "GENERAL.CLOSE");
                            if (confirm && !await this.saveOfferTabManagement()) return;
                        }
                        this.$offerScope.collapseState.released = true;
                    }
                    collapseManagement['collapse']('toggle');
                    if (isCollapsed) this.$offerScope.repositionPanels('managementRow');
                }
            } else {
                this.$offerScope.collapseState.nextState = ECollapseState.MANAGEMENT;
                this.$offerScope.releaseCollapse();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async saveOfferTabManagement(): Promise<boolean> {
        try {
            let isValid = this.formService.checkPendingFields();
            this.validateOfferSituationInconsistency();
            const isNotHomologatedAgent = this.validateOfferAgentSituation();

            if (!isValid || isNotHomologatedAgent) return false;
            const request: IMonacoRequestLog = {
                route: `${this.$offerScope.productUrl}/offer/tabs/management/update`,
                timeout: 15000,
                data: {
                    id: this.$offerScope.model.ID,
                    data: {
                        ACCOUNT_REQUEST: this.$scope.model.ACCOUNT_REQUEST,
                        OFFER_SITUATION: this.$scope.model.OFFER_SITUATION,
                        ADDITIONAL_SITUATION: this.$scope.model.ADDITIONAL_SITUATION,
                        OFFER_CODE_LINK: this.$scope.model.OFFER_CODE_LINK
                    },
                    oldData: {
                        ACCOUNT_REQUEST: this.$scope.oldModel.ACCOUNT_REQUEST,
                        OFFER_SITUATION: this.$scope.oldModel.OFFER_SITUATION,
                        ADDITIONAL_SITUATION: this.$scope.oldModel.ADDITIONAL_SITUATION,
                    }
                }
            };

            const rc = await this.restService.newObject(request.route, request.data, request.timeout, false);
            if (rc.status === 200) {
                const msgSuccess = this.formService.getTranslate('PRODUCT.OFFER_MANAGEMENT_UPDATED_SUCCESSFULLY');
                this.formService.notifySuccess(msgSuccess);
                this.$scope.oldModel = angular.copy(this.$scope.model);
            }
            return true;
        } catch (ex) {
            this.formService.handleError(ex);
            return false
        }
    }

    private validateOfferSituationInconsistency() {
        if (this.$scope.model.OFFER_SITUATION && this.$scope.model.OFFER_SITUATION.CURRENT_SITUATION && this.$scope.model.ADDITIONAL_SITUATION && this.$scope.model.ADDITIONAL_SITUATION.length > 0 && this.$scope.model.OFFER_SITUATION.CURRENT_SITUATION.ID == EOfferSituationId.APROVADA) {
            const inconsistency = this.$scope.model.ADDITIONAL_SITUATION.find(situation => situation.ID == EOfferAdditionalSituationId.BLOQUEADO_POR_INCONSISTENCIA_EM_TAXAS);
            if (inconsistency) throw Error('Unable to approve offer, additional situation have inconsistency');
        }
    }

    private validateOfferAgentSituation(): boolean {
        const isInvalid = this.$scope.model.OFFER_SITUATION && this.$scope.model.OFFER_SITUATION.CURRENT_SITUATION && this.$scope.model.OFFER_SITUATION.CURRENT_SITUATION.ID == EOfferSituationId.APROVADA && this.$scope.model.AGENT_SITUATION && this.$scope.model.AGENT_SITUATION.ID == EAgentSituationId.NOT_HOMOLOGATED;
        if (isInvalid) this.formService.notifyError(this.formService.getTranslate('GENERAL.AGENT_NOT_HOMOLOGATED'));
        return isInvalid;
    }

    private async getOfferTabsManagement(): Promise<void> {
        this.formService.block();
        try {
            const managementTab = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/management/${this.$offerScope.operation}/${this.$offerScope.model.ID}`, 30000, null, false);
            if (managementTab && managementTab.data) this.$scope.model = managementTab.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.model.ACCOUNT_REQUEST = this.$scope.model.ACCOUNT_REQUEST ? new Date(this.$scope.model.ACCOUNT_REQUEST) : this.$scope.model.ACCOUNT_REQUEST;
            this.$scope.oldModel = angular.copy(this.$scope.model);
            this.formService.unblock();
        }
    }

    private async openIndicatorDetails(): Promise<void> {
        const indicators = this.$scope.model.INDICATORS;
        if (!indicators) return this.formService.notifyError("Could not find indicator information, try again or contact an administrator.");
        let html = "";
        const grossProfitEstimatedTable = `
            <table class="table gray-border table-bordered m-b-none">
                <thead>
                    <th>${this.formService.getTranslate('FINANCIAL.GP_FIRST_OPEN')}</th>
                    <th>${this.formService.getTranslate('FINANCIAL.GP_FIRST_SEND')}</th>
                    <th>${this.formService.getTranslate('FINANCIAL.GP_CURRENT')}</th>
                </thead>
                <tbody>
                    <tr>
                        <td>${indicators.INITIAL_EGP != null ? this.$filter('number')(indicators.INITIAL_EGP, 2) : ''}</td>
                        <td>${indicators.FIRST_SEND_EGP != null ? this.$filter('number')(indicators.FIRST_SEND_EGP, 2) : ''}</td>
                        <td>${indicators.ESTIMATED_GROSS_PROFIT != null ? this.$filter('number')(indicators.ESTIMATED_GROSS_PROFIT, 2) : ''}</td>
                    </tr>
                </tbody>
            </table>
        `;

        let contribuiInit = null;
        let contribui = null;
        let contribuiFirst = null;
        if (this.$scope.getTypeCargoId() == this.$scope.getListTypeCargo('FCL')) {
            contribui = this.$scope.model.INDICATORS.EGP_TOTAL_TEUS ? this.$filter('number')(this.$scope.model.INDICATORS.EGP_TOTAL_TEUS, 2) : '';
            contribuiInit = this.$scope.model.INDICATORS.INITIAL_EGP_TOTAL_TEUS ? this.$filter('number')(this.$scope.model.INDICATORS.INITIAL_EGP_TOTAL_TEUS, 2) : '';
            contribuiFirst = this.$scope.model.INDICATORS.FIRST_SEND_EGP_TOTAL_TEUS ? this.$filter('number')(this.$scope.model.INDICATORS.FIRST_SEND_EGP_TOTAL_TEUS, 2) : '';
        } else if (this.$scope.getTypeCargoId() == this.$scope.getListTypeCargo('AIR')) {
            contribui = this.$scope.model.INDICATORS.EGP_TOTAL_WEIGHT ? this.$filter('number')(this.$scope.model.INDICATORS.EGP_TOTAL_WEIGHT, 2) : '';
            contribuiInit = this.$scope.model.INDICATORS.INITIAL_EGP_TOTAL_WEIGHT ? this.$filter('number')(this.$scope.model.INDICATORS.INITIAL_EGP_TOTAL_WEIGHT, 2) : '';
            contribuiFirst = this.$scope.model.INDICATORS.FIRST_SEND_EGP_TOTAL_WEIGHT ? this.$filter('number')(this.$scope.model.INDICATORS.FIRST_SEND_EGP_TOTAL_WEIGHT, 2) : '';
        } else if (this.$scope.getTypeCargoId() == this.$scope.getListTypeCargo('ROAD')) {
            contribui = this.$scope.model.INDICATORS.EGP_TOTAL_VEHICLES ? this.$filter('number')(this.$scope.model.INDICATORS.EGP_TOTAL_VEHICLES, 2) : '';
            contribuiInit = this.$scope.model.INDICATORS.INITIAL_EGP_TOTAL_VEHICLES ? this.$filter('number')(this.$scope.model.INDICATORS.INITIAL_EGP_TOTAL_VEHICLES, 2) : '';
            contribuiFirst = this.$scope.model.INDICATORS.FIRST_SEND_EGP_TOTAL_VEHICLES ? this.$filter('number')(this.$scope.model.INDICATORS.FIRST_SEND_EGP_TOTAL_VEHICLES, 2) : '';
        } else if (this.$scope.getTypeCargoId() == this.$scope.getListTypeCargo('BREAK_BULK') || this.$scope.getTypeCargoId() == this.$scope.getListTypeCargo('LCL') || this.$scope.getTypeCargoId() == this.$scope.getListTypeCargo('RO_RO')) {
            contribui = this.$scope.model.INDICATORS.EGP_TOTAL_FILE ? this.$filter('number')(this.$scope.model.INDICATORS.EGP_TOTAL_FILE, 2) : '';
            contribuiInit = this.$scope.model.INDICATORS.INITIAL_EGP_TOTAL_FILE ? this.$filter('number')(this.$scope.model.INDICATORS.INITIAL_EGP_TOTAL_FILE, 2) : '';
            contribuiFirst = this.$scope.model.INDICATORS.FIRST_SEND_EGP_TOTAL_FILE ? this.$filter('number')(this.$scope.model.INDICATORS.FIRST_SEND_EGP_TOTAL_FILE, 2) : '';
        }

        const contributionEstimatedTable = `
            <table class="table gray-border table-bordered m-b-none">
                <thead>
                    <th>${this.formService.getTranslate('FINANCIAL.CT_TEU_OPEN')}</th>
                    <th>${this.formService.getTranslate('FINANCIAL.CT_TEU_FIRST_SEND')}</th>
                    <th>${this.formService.getTranslate('FINANCIAL.CT_TEU_FIRST_CURRENT')}</th>
                </thead>
                <tbody>
                    <tr>
                        <td>${contribuiInit}</td>
                        <td>${contribuiFirst}</td>
                        <td>${contribui}</td>
                    </tr>
                </tbody>
            </table>
        `;

        let contribuiInitWD = null;
        let contribuiWD = null;
        let contribuiFirstWD = null;
        let title = '';
        if (this.$scope.getTypeCargoId() == this.$scope.getListTypeCargo('FCL')) {
            contribuiWD = this.$scope.model.INDICATORS.CTWD ? this.$filter('number')(this.$scope.model.INDICATORS.CTWD, 2) : '';
            contribuiInitWD = this.$scope.model.INDICATORS.INITIAL_CTWD ? this.$filter('number')(this.$scope.model.INDICATORS.INITIAL_CTWD, 2) : '';
            contribuiFirstWD = this.$scope.model.INDICATORS.FIRST_SEND_CTWD ? this.$filter('number')(this.$scope.model.INDICATORS.FIRST_SEND_CTWD, 2) : '';
            title = this.formService.getTranslate('FINANCIAL.CT_TEU_WITHOUT_DOCS');
        } else if (this.$scope.getTypeCargoId() == this.$scope.getListTypeCargo('AIR')) {
            contribuiWD = this.$scope.model.INDICATORS.CTWD_WEIGHT ? this.$filter('number')(this.$scope.model.INDICATORS.CTWD_WEIGHT, 2) : '';
            contribuiInitWD = this.$scope.model.INDICATORS.INITIAL_CTWD_WEIGHT ? this.$filter('number')(this.$scope.model.INDICATORS.INITIAL_CTWD_WEIGHT, 2) : '';
            contribuiFirstWD = this.$scope.model.INDICATORS.FIRST_SEND_CTWD_WEIGHT ? this.$filter('number')(this.$scope.model.INDICATORS.FIRST_SEND_CTWD_WEIGHT, 2) : '';
            title = 'FINANCIAL.CT_TON_WITHOUT_DOCS';
        } else if (this.$scope.getTypeCargoId() == this.$scope.getListTypeCargo('ROAD')) {
            contribuiWD = this.$scope.model.INDICATORS.CTWD_VEHICLES ? this.$filter('number')(this.$scope.model.INDICATORS.CTWD_VEHICLES, 2) : '';
            contribuiInitWD = this.$scope.model.INDICATORS.INITIAL_CTWD_VEHICLES ? this.$filter('number')(this.$scope.model.INDICATORS.INITIAL_CTWD_VEHICLES, 2) : '';
            contribuiFirstWD = this.$scope.model.INDICATORS.FIRST_SEND_CTWD_VEHICLES ? this.$filter('number')(this.$scope.model.INDICATORS.FIRST_SEND_CTWD_VEHICLES, 2) : '';
            title = 'FINANCIAL.CT_VEHICILE_WITHOUT_DOCS';
        } else if (this.$scope.getTypeCargoId() == this.$scope.getListTypeCargo('BREAK_BULK') || this.$scope.getTypeCargoId() == this.$scope.getListTypeCargo('LCL') || this.$scope.getTypeCargoId() == this.$scope.getListTypeCargo('RO_RO')) {
            contribuiWD = this.$scope.model.INDICATORS.CTWD_FILE ? this.$filter('number')(this.$scope.model.INDICATORS.CTWD_FILE, 2) : '';
            contribuiInitWD = this.$scope.model.INDICATORS.INITIAL_CTWD_FILE ? this.$filter('number')(this.$scope.model.INDICATORS.INITIAL_CTWD_FILE, 2) : '';
            contribuiFirstWD = this.$scope.model.INDICATORS.FIRST_SEND_CTWD_FILE ? this.$filter('number')(this.$scope.model.INDICATORS.FIRST_SEND_CTWD_FILE, 2) : '';
            title = 'FINANCIAL.CT_FILE_WITHOUT_DOCS';
        }

        const contributionEstimatedWDTable = `
            <table class="table gray-border table-bordered m-b-none">
                <thead>
                    <th>${this.formService.getTranslate(title)} ${this.formService.getTranslate('FINANCIAL.GP_FIRST_OPEN')}</th>
                    <th>${this.formService.getTranslate(title)} ${this.formService.getTranslate('FINANCIAL.GP_FIRST_SEND')}</th>
                    <th>${this.formService.getTranslate(title)} ${this.formService.getTranslate('REGISTRATION.DEFAULT_CURRENCY')}</th>
                </thead>
                <tbody>
                    <tr>
                        <td>${contribuiInitWD}</td>
                        <td>${contribuiFirstWD}</td>
                        <td>${contribuiWD}</td>
                    </tr>
                </tbody>
            </table>
        `;
        html = `
            <div class="row form-group">
                <div class="col-lg-12">
                    <fieldset>
                        <legend class="text-lg">${this.formService.getTranslate('FINANCIAL.ESTIMATED_GROSS_PROFIT')}</legend>
                        ${grossProfitEstimatedTable}
                    </fieldset>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <fieldset>
                        <legend class="text-lg">${this.formService.getTranslate('FINANCIAL.CT_TEU_BRL')}</legend>
                        ${contributionEstimatedTable}
                    </fieldset>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <fieldset>
                        <legend class="text-lg">${this.formService.getTranslate('FINANCIAL.CT_TEU_WITHOUT_DOCS_BRL')}</legend>
                        ${contributionEstimatedWDTable}
                    </fieldset>
                </div>
            </div>
        `;
        this.modalService.showModalInfo(
            {
                scope: this.$scope,
                size: 'lg',
            },
            {
                actionButtonClass: 'btn-default',
                actionButtonText: 'GENERAL.CLOSE',
                headerText: 'FINANCIAL.INDICATORS_DETAILS',
                bodyText: this.$sce.trustAsHtml(html)
            }
        );
    }

    private async getOfferTabsProfitShareDetails(): Promise<IOfferProfitShareDetail> {
        let profitShareDetails: IOfferProfitShareDetail = null;
        this.formService.block();
        try {
            const profitShareDetailsResult = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/management/profitShare/${this.$offerScope.operation}/${this.$offerScope.model.ID}`, 30000, null, false);
            if (profitShareDetailsResult && profitShareDetailsResult.data) profitShareDetails = profitShareDetailsResult.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return profitShareDetails;
        }
    }

    private async getOfferTabsProfitShareCharges(): Promise<IOfferManagementProfitShareCharge[]> {
        let profitShareDetails: IOfferManagementProfitShareCharge[] = null;
        this.formService.block();
        try {
            const profitShareChargesResult = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/management/profitShare/charges/${this.$offerScope.model.ID}`, 30000, null, false);
            if (profitShareChargesResult && profitShareChargesResult.data) profitShareDetails = profitShareChargesResult.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return profitShareDetails;
        }
    }

    private async openProfitShareDetails(): Promise<void> {
        this.modalProfitShareId = this.modalService.newModal();
        this.modalService.showModalInfo(
            {
                modalID: this.modalProfitShareId,
                scope: this.$scope,
                formService: this.$offerScope.operation,
                template: require("../view/modals/offerManagementProfitShareModal.html"),
                size: 'full modal-overflow',
                keyboard: false
            },
            null
        );
        this.buildProfitShareModalScope();
    }

    private async buildProfitShareModalScope(): Promise<void> {
        try {
            const self: OfferManagementRegisterController = this;
            const modalScope: IOfferProfitShareModalScope = await this.modalService.getModalScope(this.modalProfitShareId);
            modalScope.profitShareDetails = await this.getOfferTabsProfitShareDetails();
            const msgError = this.formService.getTranslate('PRODUCT.PROFIT_SHARE_NOT_FOUND');
            if (!modalScope.profitShareDetails) return this.formService.notifyError(msgError);
            modalScope.oldProfitShareDetails = angular.copy(modalScope.profitShareDetails);
            modalScope.compositionCharges = await this.getOfferTabsProfitShareCharges();
            modalScope.oldCompositionCharges = angular.copy(modalScope.compositionCharges);
            modalScope.shareItemIndexControl = [];
            modalScope.hierarchyList = [1, 2, 3, 4];
            modalScope.disableElements(this.$offerScope.operation == EOperation.VIEW);

            // get generics
            new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getGenericValue("forwarded_by"),
                    self.getGenericValue("transaction"),
                    self.getGenericValue("equipment_size"),
                    self.getEquipmentList()
                ]).then(async (result: any) => {
                    modalScope.forwardedByList = result[0];
                    modalScope.transactionList = result[1];
                    modalScope.feetList = result[2];
                    modalScope.equipmentList = result[3];
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });

            if (modalScope.profitShareDetails.RULE && modalScope.profitShareDetails.RULE.PROFIT_SHARES && modalScope.profitShareDetails.SHARE && modalScope.profitShareDetails.SHARE.SHARE_ITEM) {
                for (const profitShare of modalScope.profitShareDetails.RULE.PROFIT_SHARES) {
                    const shareItemIndex = profitShare.PROFIT_SHARE ? modalScope.profitShareDetails.SHARE.SHARE_ITEM.findIndex(shareItem => shareItem.PROFIT_SHARE && shareItem.PROFIT_SHARE.ID == profitShare.PROFIT_SHARE.ID) : null;
                    modalScope.shareItemIndexControl.push(shareItemIndex);
                }
            }

            modalScope.applyProfitShareModal = async () => {
                if (await this.saveOfferTabManagementProfitShare(modalScope.profitShareDetails, modalScope.oldProfitShareDetails, modalScope.compositionCharges, modalScope.oldCompositionCharges)) {
                    await this.getOfferTabsManagement();
                    this.modalService.closeModal(this.modalProfitShareId);
                    this.modalProfitShareId = 0;
                }
            }

            modalScope.goToParamsProfitShare = (id: number) => {
                this.sessionService.openTab("app.registration.paramsProfitShare", <ILinkParameter>{ ID: id ? id.toString() : id });
            }

            modalScope.closeProfitShareModal = async (): Promise<void> => {
                if (this.$offerScope.hasChanges(JSON.stringify(modalScope.profitShareDetails), JSON.stringify(modalScope.oldProfitShareDetails)) || this.$offerScope.hasChanges(JSON.stringify(modalScope.compositionCharges), JSON.stringify(modalScope.oldCompositionCharges))) {
                    const confirm = await this.$offerScope.modalSaveConfirmation("GENERAL.CLOSE", "GENERAL.CLOSE");
                    if (confirm && !await this.saveOfferTabManagementProfitShare(modalScope.profitShareDetails, modalScope.oldProfitShareDetails, modalScope.compositionCharges, modalScope.oldCompositionCharges)) return;
                }
                this.modalService.closeModal(this.modalProfitShareId);
                this.modalProfitShareId = 0;
            }

            modalScope.recalculate = async (): Promise<void> => {
                if (await this.saveOfferTabManagementProfitShare(modalScope.profitShareDetails, modalScope.oldProfitShareDetails, modalScope.compositionCharges, modalScope.oldCompositionCharges)) {
                    this.buildProfitShareModalScope();
                }
            }

            modalScope.addProfitShare = (): void => {
                try {
                    if (!modalScope.profitShareDetails.RULE.PROFIT_SHARES) modalScope.profitShareDetails.RULE.PROFIT_SHARES = [];
                    const company = modalScope.profitShareDetails.RULE.PROFIT_SHARES.find(profitShare => profitShare.PROFIT_SHARE && profitShare.PROFIT_SHARE.ID == EForwardedById.COMPANY);
                    if (company) company.HIERARCHY = null;
                    const availableHierarchies = modalScope.hierarchyList.filter(hierarchy => modalScope.profitShareDetails.RULE.PROFIT_SHARES.findIndex(profitShare => profitShare.HIERARCHY == hierarchy) < 0);
                    const profitShare: IOfferProfitShareRulesProfitShare = {
                        ACTIVE: true,
                        APPLICATION: null,
                        EQUIP_TYPE: null,
                        HIERARCHY: availableHierarchies[0],
                        HOLDER: null,
                        MIN_FILE: null,
                        MIN_PERCENT: null,
                        PROFIT_SHARE: null,
                        TRANSACTION: null,
                        UUID: null
                    }
                    if (modalScope.profitShareDetails.RULE.PROFIT_SHARES.length) modalScope.profitShareDetails.RULE.PROFIT_SHARES.splice(modalScope.profitShareDetails.RULE.PROFIT_SHARES.length - 1, 0, angular.copy(profitShare));
                    else modalScope.profitShareDetails.RULE.PROFIT_SHARES.push(angular.copy(profitShare));
                    modalScope.updateRuleCompanyHierarchy();

                    /*
                    this.$timeout(() => {
                        const currentIndex = this.$scope.model.RULES[ruleIndex].PROFIT_SHARES.length - 1;
                        this.$scope.selectorValidity(`ruleProfitShareHierarchy${ruleIndex}_${currentIndex}`);
                        this.$scope.selectorValidity(`ruleProfitShare${ruleIndex}_${currentIndex}`);
                        this.$scope.selectorValidity(`ruleProfitShareApplication${ruleIndex}_${currentIndex}`);
                    });
                    */
                } catch (ex) {
                    this.formService.handleError(ex);
                }
            }

            modalScope.removeProfitShare = async (profitShareIndex: number): Promise<void> => {
                try {
                    if (!profitShareIndex && profitShareIndex != 0) throw Error('profitShareIndex is null');
                    const modal = await this.modalService.showModalConfirmation({}, {
                        actionButtonText: 'GENERAL.CONFIRM',
                        headerText: 'GENERAL.CONFIRM_ACTION',
                        bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
                    });
                    if (!modal) return;

                    if (modalScope.profitShareDetails.RULE.PROFIT_SHARES && modalScope.profitShareDetails.RULE.PROFIT_SHARES.length && modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex]) {
                        this.formService.block();
                        modalScope.profitShareDetails.RULE.PROFIT_SHARES.splice(profitShareIndex, 1);
                        modalScope.updateRuleCompanyHierarchy();
                        modalScope.updateRuleCompanyPercent();
                    }
                } catch (ex) {
                    this.formService.handleError(ex);
                } finally {
                    this.formService.unblock();
                }
            }

            modalScope.addProfitShareEquipType = (profitShareIndex: number): void => {
                try {
                    if (!profitShareIndex && profitShareIndex != 0) throw Error('profitShareIndex is null');
                    if (modalScope.profitShareDetails.RULE.PROFIT_SHARES && modalScope.profitShareDetails.RULE.PROFIT_SHARES.length && modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex]) {
                        if (!modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE) modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE = [];
                        const equipType: IParamsProfitShareRulesProfitShareEquipType = {
                            TYPE: null,
                            FEET: null,
                            MIN_APPLICATION: null
                        }
                        modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE.push(angular.copy(equipType));
                    }
                } catch (ex) {
                    this.formService.handleError(ex);
                }
            }

            modalScope.removeProfitShareEquipType = async (profitShareIndex: number, equipTypeIndex: number): Promise<void> => {
                try {
                    if (!profitShareIndex && profitShareIndex != 0) throw Error('profitShareIndex is null');
                    if (!equipTypeIndex && equipTypeIndex != 0) throw Error('equipTypeIndex is null');
                    const modal = await this.modalService.showModalConfirmation({}, {
                        actionButtonText: 'GENERAL.CONFIRM',
                        headerText: 'GENERAL.CONFIRM_ACTION',
                        bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
                    });
                    if (!modal) return;

                    if (modalScope.profitShareDetails.RULE.PROFIT_SHARES && modalScope.profitShareDetails.RULE.PROFIT_SHARES.length && modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex] &&
                        modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE && modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE.length && modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex]) {
                        this.formService.block();
                        modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE.splice(equipTypeIndex, 1);
                    }
                } catch (ex) {
                    this.formService.handleError(ex);
                } finally {
                    this.formService.unblock();
                }
            }

            modalScope.hierarchyFilter = (): Function => {
                return this.hierarchyFilter(modalScope.profitShareDetails.RULE.PROFIT_SHARES);
            }

            modalScope.isProfitShareCompany = (profitShare: SelectorModel): boolean => {
                return profitShare && profitShare.ID == EForwardedById.COMPANY;
            }

            modalScope.forwardedByFilter = (forwardedBy: SelectorModel): boolean => {
                return forwardedBy && forwardedBy.ID != EForwardedById.COMPANY;
            }

            modalScope.transactionFilter = (transaction: SelectorModel): boolean => {
                return transaction && (transaction.ID == ETransactionId.LOCAL_AGENT || transaction.ID == ETransactionId.EXTERNAL_AGENT || transaction.ID == ETransactionId.OTHER_AGENT || transaction.ID == ETransactionId.OTHER);
            }

            modalScope.equipTypeFilter = (profitShareIndex: number): Function => {
                if (!profitShareIndex && profitShareIndex != 0) throw Error("profitShareIndex is null!");
                const profitShare = modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex];
                return function (equipType: SelectorModel) {
                    return profitShare && (!profitShare.EQUIP_TYPE || (profitShare.EQUIP_TYPE && (!profitShare.EQUIP_TYPE.some(profitShareEquipType => profitShareEquipType.TYPE && profitShareEquipType.TYPE.some(feet => feet.ID == equipType.ID)))));
                }
            }

            modalScope.equipFeetFilter = (profitShareIndex: number): Function => {
                if (!profitShareIndex && profitShareIndex != 0) throw Error("profitShareIndex is null!");
                const profitShare = modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex];
                return function (equipFeet: SelectorModel) {
                    return profitShare && (!profitShare.EQUIP_TYPE || (profitShare.EQUIP_TYPE && (!profitShare.EQUIP_TYPE.some(profitShareEquipType => profitShareEquipType.FEET && profitShareEquipType.FEET.some(feet => feet.ID == equipFeet.ID)))));
                }
            }

            modalScope.isTransactionOther = (transaction: SelectorModel): boolean => {
                return transaction && transaction.ID == ETransactionId.OTHER;
            }

            modalScope.isApplicationEquipment = (application: IApplicationList): boolean => {
                return application && application.APPLICATION_COMPLEMENT && application.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.EQUIPMENT;
            }

            modalScope.getHolderListByName = async (search: string) => {
                let holderList: IHolderSelector[] = [];
                if (search && search.length >= 3) {
                    const legalPersonList = await this.getLegalPersonListByName({ specializations: [], search: search })
                    if (legalPersonList && legalPersonList.length) holderList = holderList.concat(legalPersonList.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.NAME, ID_LEGAL_PERSON: parseInt(legalPerson.ID), ID_PHYSICAL_PERSON: null } }));
                    const physicalPersonList = await this.getPhysicalPersonListByName({ specializations: [], roles: [], search: search });
                    if (physicalPersonList && physicalPersonList.length) holderList = holderList.concat(physicalPersonList.map(physicalPerson => { return { ID: physicalPerson.ID, NAME: physicalPerson.NAME, ID_LEGAL_PERSON: null, ID_PHYSICAL_PERSON: parseInt(physicalPerson.ID) } }));
                }
                modalScope.holderList = holderList;
            }

            modalScope.getApplicationListByName = async (search: string): Promise<void> => {
                let applicationList: IApplicationList[] = [];
                if (search && search.length >= 2) {
                    applicationList = await this.getApplicationListByName(search);
                }
                modalScope.applicationList = applicationList;
            }

            modalScope.transactionChange = async (profitShareIndex: number) => {
                if (!profitShareIndex && profitShareIndex != 0) return this.formService.notifyError("profitShareIndex is null.");
                let agentToReplace = null;
                if (modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].TRANSACTION && modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].TRANSACTION.ID != ETransactionId.OTHER) {
                    const agents: IOfferAgentsResult = await this.getOfferAgents();
                    if (agents) {
                        if (modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].TRANSACTION.ID == ETransactionId.LOCAL_AGENT) agentToReplace = agents.LOCAL_AGENT;
                        if (modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].TRANSACTION.ID == ETransactionId.EXTERNAL_AGENT) agentToReplace = agents.EXTERNAL_AGENT;
                        if (modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].TRANSACTION.ID == ETransactionId.OTHER_AGENT) agentToReplace = agents.OTHER_AGENT;
                    }
                }
                modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].HOLDER = agentToReplace;
                this.$timeout(() => {
                    modalScope.selectorValidity('profitShareHolder' + profitShareIndex);
                }, 100);
            }

            modalScope.updateRuleCompanyHierarchy = (): void => {
                const company = modalScope.profitShareDetails.RULE.PROFIT_SHARES.find(profitShare => profitShare.PROFIT_SHARE && profitShare.PROFIT_SHARE.ID == EForwardedById.COMPANY);
                if (company) {
                    company.HIERARCHY = null;
                    const availableHierarchies = modalScope.hierarchyList.filter(hierarchy => modalScope.profitShareDetails.RULE.PROFIT_SHARES.findIndex(profitShare => profitShare.HIERARCHY == hierarchy) < 0);
                    company.HIERARCHY = availableHierarchies[0];
                }
            }

            modalScope.updateRuleCompanyPercent = (): void => {
                if (modalScope.profitShareDetails.RULE.PROFIT_SHARES) {
                    const company = modalScope.profitShareDetails.RULE.PROFIT_SHARES.find(profitShare => profitShare.PROFIT_SHARE && profitShare.PROFIT_SHARE.ID == EForwardedById.COMPANY);
                    if (company) {
                        let profitShareTotalPercent = 0;
                        for (const profitShare of modalScope.profitShareDetails.RULE.PROFIT_SHARES) {
                            if (!profitShare.PROFIT_SHARE || profitShare.PROFIT_SHARE && profitShare.PROFIT_SHARE.ID != EForwardedById.COMPANY) profitShareTotalPercent += profitShare.MIN_PERCENT;
                        }
                        company.MIN_PERCENT = (100 - profitShareTotalPercent) < 0 ? 0 : (100 - profitShareTotalPercent);
                    }
                }
            }

            modalScope.changeApplication = (profitShareIndex: number): void => {
                try {
                    if (!profitShareIndex && profitShareIndex != 0) throw Error('profitShareIndex is null');
                    if (modalScope.profitShareDetails.RULE.PROFIT_SHARES && modalScope.profitShareDetails.RULE.PROFIT_SHARES.length > 0 && modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex]) {
                        modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE = [];
                        if (modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].APPLICATION && modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].APPLICATION.APPLICATION_COMPLEMENT) {
                            modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE.push(
                                {
                                    TYPE: null,
                                    FEET: null,
                                    MIN_APPLICATION: null
                                }
                            );
                        }
                    }
                } catch (ex) {
                    this.formService.handleError(ex);
                }
            }

            modalScope.changeEquipType = (profitShareIndex: number, equipTypeIndex: number): void => {
                try {
                    if (!profitShareIndex && profitShareIndex != 0) throw Error('profitShareIndex is null');
                    if (!equipTypeIndex && equipTypeIndex != 0) throw Error('equipTypeIndex is null');

                    if (modalScope.profitShareDetails.RULE.PROFIT_SHARES && modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex] && modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE && modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex]) {
                        if (modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex].TYPE && modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex].TYPE.length) {
                            modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex].FEET = null;
                        }
                    }
                } catch (ex) {
                    this.formService.handleError(ex);
                }
            }

            modalScope.changeFeet = (profitShareIndex: number, equipTypeIndex: number): void => {
                try {
                    if (!profitShareIndex && profitShareIndex != 0) throw Error('profitShareIndex is null');
                    if (!equipTypeIndex && equipTypeIndex != 0) throw Error('equipTypeIndex is null');

                    if (modalScope.profitShareDetails.RULE.PROFIT_SHARES && modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex] &&
                        modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE && modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex]) {
                        if (modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex].FEET && modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex].FEET.length) {
                            modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex].TYPE = null;
                        }
                    }
                } catch (ex) {
                    this.formService.handleError(ex);
                }
            }

            modalScope.updateAmount = async (profitShareIndex: number, equipTypeIndex: number): Promise<void> => {
                if (!profitShareIndex && profitShareIndex != 0) throw Error('profitShareIndex is null');
                if (!equipTypeIndex && equipTypeIndex != 0) throw Error('equipTypeIndex is null');

                try {
                    if (modalScope.profitShareDetails.RULE.PROFIT_SHARES && modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex] &&
                        modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE && modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex]) {
                        const type = modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex].TYPE;
                        const feet = modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex].FEET;
                        const typeIds = type && type.length ? type.map(type => type.ID) : null;
                        const feetIds = feet && feet.length ? feet.map(feet => feet.ID) : null;
                        const minApplication = modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex].MIN_APPLICATION;
                        const result: IOfferManagementProfitShareAmountResult = await this.getOfferManagementProfitShareAmount(typeIds, feetIds, minApplication);
                        if (result) {
                            const shareItem = modalScope.profitShareDetails.SHARE.SHARE_ITEM[modalScope.shareItemIndexControl[profitShareIndex]];
                            const equipType = modalScope.profitShareDetails.RULE.PROFIT_SHARES[profitShareIndex].EQUIP_TYPE[equipTypeIndex];
                            if (shareItem && shareItem.APPLICATION_PARAM && shareItem.APPLICATION_PARAM.length) {
                                let applicationParamToUpdate = null;
                                if (equipType.TYPE && equipType.TYPE.length) {
                                    for (let index = 0; index < equipType.TYPE.length; index++) {
                                        const applicationParam = shareItem.APPLICATION_PARAM.find(applicationParam => applicationParam.EQUIPMENT && applicationParam.EQUIPMENT.ID == equipType.TYPE[index].ID);
                                        if (applicationParam) {
                                            applicationParamToUpdate = applicationParam;
                                            break;
                                        }
                                    }
                                } else if (equipType.FEET && equipType.FEET.length) {
                                    for (let index = 0; index < equipType.FEET.length; index++) {
                                        const applicationParam = shareItem.APPLICATION_PARAM.find(applicationParam => applicationParam.FEET && applicationParam.FEET.ID == equipType.FEET[index].ID);
                                        if (applicationParam) {
                                            applicationParamToUpdate = applicationParam;
                                            break;
                                        }
                                    }
                                }
                                if (applicationParamToUpdate) {
                                    applicationParamToUpdate.AMOUNT = result.AMOUNT;
                                    applicationParamToUpdate.TOTAL_MIN_APPLICATION = result.TOTAL_MIN_APPLICATION;
                                }
                            }
                        }
                    }
                } catch (ex) {
                    this.formService.handleError(ex);
                }
            }

            modalScope.applicationParamValue = (equipType: IParamsProfitShareRulesProfitShareEquipType, field: string): number => {
                let result: number = null;
                if (equipType) {
                    let feets = equipType.FEET ? equipType.FEET.map(feet => feet.ID) : [];
                    feets = feets.concat(equipType.TYPE ? equipType.TYPE.map(type => type.FEET ? type.FEET.ID : null) : [])
                    const equipments = equipType.TYPE ? equipType.TYPE.map(type => type.ID) : [];
                    if (modalScope.profitShareDetails && modalScope.profitShareDetails.SHARE && modalScope.profitShareDetails.SHARE.SHARE_ITEM) {
                        for (const item of modalScope.profitShareDetails.SHARE.SHARE_ITEM) {
                            if (item.APPLICATION_PARAM) {
                                for (const applicationParamItem of item.APPLICATION_PARAM) {
                                    let applicationParam: IOfferOptionProfitShareDetailApplicationParam = null;
                                    if (applicationParamItem.FEET && feets.indexOf(applicationParamItem.FEET.ID) > -1) {
                                        applicationParam = applicationParamItem;
                                    } else if (applicationParamItem.EQUIPMENT && equipments.indexOf(applicationParamItem.EQUIPMENT.ID) > -1) {
                                        applicationParam = applicationParamItem;
                                    }
                                    if (applicationParam) {
                                        result += applicationParam[field];
                                    }
                                }
                            }

                        }
                    }
                }
                return result;
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private hierarchyFilter = (profitShares: IParamsProfitShareRulesProfitShare[]): Function => {
        return function (hierarchy: number) {
            return (!profitShares || (profitShares && !profitShares.some(profitShare => profitShare.HIERARCHY == hierarchy)));
        }
    }

    private async saveOfferTabManagementProfitShare(profitShare: IOfferProfitShareDetail, oldProfitShare: IOfferProfitShareDetail, compositionCharges: IOfferManagementProfitShareCharge[], oldCompositionCharges: IOfferManagementProfitShareCharge[]): Promise<boolean> {
        let success: boolean = false;
        try {
            if (!this.$offerScope.hasInvalidRequiredElements('offerManagementProfitShareModal')) {
                const request: IMonacoRequestLog = {
                    route: `${this.$offerScope.productUrl}/offer/tabs/management/profitShare/update`,
                    timeout: 15000,
                    data: {
                        id: this.$offerScope.model.ID,
                        data: {
                            PROFIT_SHARE: profitShare,
                            CHARGES: compositionCharges
                        },
                        oldData: {
                            PROFIT_SHARE: oldProfitShare,
                            CHARGES: oldCompositionCharges
                        }
                    }
                };
                const rc = await this.restService.newObject(request.route, request.data, request.timeout, false);
                if (rc.status === 200) {
                    success = true;
                    const msgSuccess = this.formService.getTranslate('PRODUCT.PROFIT_SHARE_UPDATED_SUCCESSFULLY');
                    this.formService.notifySuccess(msgSuccess);
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return success;
        }
    }

    private async getOfferManagementProfitShareAmount(equipmentIds: string[], feetIds: string[], minApplication: number): Promise<IOfferManagementProfitShareAmountResult> {
        let result = null;
        try {
            this.formService.block();
            result = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/management/profitShare/getAmounts`, { id: this.$offerScope.model.ID, equipment: equipmentIds, feet: feetIds, value: minApplication }, 30000, false);
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getOfferAgents(): Promise<IOfferAgentsResult> {
        let result = null;
        try {
            this.formService.block();
            const request = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/getAgents/${this.$offerScope.model.ID}`, 30000, null, false);
            if (request && request.data) result = request.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getLegalPersonListByName(legalPersonFilter: ILegalPersonListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        try {
            this.formService.block();
            const legalPersons = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/legalPerson/list/custom`, legalPersonFilter, 30000, false);
            if (legalPersons) result = legalPersons.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getPhysicalPersonListByName(physicalPersonFilter?: IPhysicalPersonListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const physicalPersons = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/physicalPerson/list/custom`, physicalPersonFilter, 30000, false);
            if (physicalPersons) result = physicalPersons.map(physicalPerson => { return { ID: physicalPerson.ID, NAME: physicalPerson.NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getApplicationListByName(search: string): Promise<IApplicationList[]> {
        let result: IApplicationList[] = [];
        try {
            if (!this.$offerScope.model.PRODUCT) throw Error('Product is null.');
            this.formService.block();
            const products = [this.$offerScope.model.PRODUCT.ID];
            const typeCargos = this.$offerScope.model.TYPE_CARGO ? [this.$offerScope.model.TYPE_CARGO.ID] : [];
            const application = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/application/list/custom`, { search, products, typeCargos, percent: false }, 10000, false);
            if (application) {
                result = application.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PERCENT: x.PERCENT, APPLICATION_COMPLEMENT: x.APPLICATION_COMPLEMENT, INTERNAL_SEQUENCE: x.INTERNAL_SEQUENCE, FREE_TYPING_AMOUNT_CHARGE: x.FREE_TYPING_AMOUNT_CHARGE, CT_WITHOUT_DOC: x.CT_WITHOUT_DOC, NAME_INTL: x.NAME_INTL } });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getEquipmentList(): Promise<IEquipmentList[]> {
        let result: IEquipmentList[] = [];
        try {
            this.formService.block();
            const request = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/cargo/equipments/${this.$offerScope.model.ID}`, null, 30000, false);
            if (request && request.data && request.data.length) result = request.data.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, TEU: x.TEU, FEET: x.FEET } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getGenericValue(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private requestHistory(id: string): Promise<any> {
        return this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/management/offerSituation/viewLog/${id}`, 10000, null, false);
    }

    private async viewLog() {
        this.formService.block();
        const log: IViewLog = {
            operation: 'history',
            number: this.$offerScope.model.ID.toString(),
            list: [],
            show: true,
            showCloseButton: false,
            searchQuery: '',
            originalList: [],
        }
        this.requestHistory(log.number).then(result => {
            log.list = result.data;
            log.originalList = angular.copy(log.list);
            this.$scope.log = log;
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.formService.unblock();
        }).catch(ex => {
            this.formService.handleError(ex);
        });

        const modalId = this.$scope.modalService.newModal();
        await this.$scope.modalService.showModalConfirmation(
            {
                modalID: modalId,
                scope: this.$scope,
                template: require('../view/modals/offerLog.html'),
                size: 'full'
            },
            {
                closeButtonText: "GENERAL.CLOSE",
                headerText: "GENERAL.GRID.LOG"
            }
        );
    };

    private async viewLogIndicators() {
        this.formService.block();
        let log: IViewLog = {
            operation: 'history',
            number: this.$offerScope.model.ID.toString(),
            list: [],
            show: true,
            showCloseButton: false,
            searchQuery: '',
            originalList: [],
        }
        this.requestHistoryIndicators(log.number).then(result => {
            log.list = result.data;
            log.originalList = angular.copy(log.list);
            this.$scope.log = log;
            this.$scope.customLogProperties = this.getIndicatorsCustomLogProperties();
            this.formService.unblock();
        }).catch(ex => {
            this.formService.handleError(ex);
        });

        const modalId = this.$scope.modalService.newModal();
        await this.$scope.modalService.showModalConfirmation(
            {
                modalID: modalId,
                scope: this.$scope,
                template: require('../view/modals/offerLog.html'),
                size: 'full'
            },
            {
                closeButtonText: "GENERAL.CLOSE",
                headerText: "GENERAL.GRID.LOG"
            }
        );
    };

    private async viewLogProfitShare() {
        this.formService.block();
        let log: IViewLog = {
            operation: 'history',
            number: this.$offerScope.model.ID.toString(),
            list: [],
            show: true,
            showCloseButton: false,
            searchQuery: '',
            originalList: [],
        }
        this.requestHistoryProfitShare(log.number).then(result => {
            log.list = result.data;
            log.originalList = angular.copy(log.list);
            this.$scope.log = log;
            this.$scope.customLogProperties = this.getProfitShareCustomLogProperties();
            this.formService.unblock();
        }).catch(ex => {
            this.formService.handleError(ex);
        });

        const modalId = this.$scope.modalService.newModal();
        await this.$scope.modalService.showModalConfirmation(
            {
                modalID: modalId,
                scope: this.$scope,
                template: require('../view/modals/offerLog.html'),
                size: 'full'
            },
            {
                closeButtonText: "GENERAL.CLOSE",
                headerText: "GENERAL.GRID.LOG"
            }
        );
    };

    private requestHistoryIndicators(id: string): Promise<any> {
        return this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/management/indicators/viewLog/${id}`, 10000, null, false);
    }

    private requestHistoryProfitShare(id: string): Promise<any> {
        return this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/management/profitshare/viewLog/${id}`, 10000, null, false);
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: "ACCOUNT_REQUEST", LABEL: "PRODUCT.CLIENT_REQUEST_DATE" },
            { PROPERTY: "OFFER_SITUATION", LABEL: "PRODUCT.OFFER_STATUS" },
            { PROPERTY: "CURRENT_SITUATION", LABEL: "OPERATIONAL.CURRENT_FILE_STATUS" },
            { PROPERTY: "DATE", LABEL: "GENERAL.DATE" },
            { PROPERTY: "FCS_FCI", LABEL: "PRODUCT.KEY_FACTOR_SUCCESS_FAILURE" },
            { PROPERTY: "OBSERVATION", LABEL: "GENERAL.REMARKS" },
            { PROPERTY: "ID", LABEL: "Id." },
            { PROPERTY: "NAME", LABEL: "GENERAL.NAME" },
            { PROPERTY: "CODE", LABEL: "GENERAL.CODE" },
        ];
        return props;
    }

    private getIndicatorsCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: "INDICATORS", LABEL: "GENERAL.INDICATORS" },
            { PROPERTY: "ESTIMATED_GROSS_PROFIT", LABEL: "FINANCIAL.ESTIMATED_GROSS_PROFIT" },
            { PROPERTY: "INITIAL_EGP", LABEL: "FINANCIAL.GP_FIRST_OPEN" },
            { PROPERTY: "FIRST_SEND_EGP", LABEL: "FINANCIAL.GP_FIRST_SEND" },
            { PROPERTY: "TOTAL_PAYMENT", LABEL: "FINANCIAL.TOTAL_COST" },
            { PROPERTY: "TOTAL_RECEIVING", LABEL: "FINANCIAL.TOTAL_COST" },
            { PROPERTY: "TOTAL_PROFIT_SHARE", LABEL: "FINANCIAL.TOTAL_PROFIT_SHARE_AGENT" },
            { PROPERTY: "TOTAL_TEUS", LABEL: "BASIC_DATA.TOTAL_TEU" },
            { PROPERTY: "TOTAL_WEIGHT", LABEL: "PRODUCT.TOTAL_TONS" },
            { PROPERTY: "TOTAL_VEHICLES", LABEL: "PRODUCT.TOTAL_VEHICLES" },
            { PROPERTY: "TOTAL_FILE", LABEL: "PRODUCT.TOTAL_FILE" },

            // EGP Block
            { PROPERTY: "EGP_TOTAL_TEUS", LABEL: "FINANCIAL.CT_TEU" },
            { PROPERTY: "INITIAL_EGP_TOTAL_TEUS", LABEL: "FINANCIAL.GP_OPEN_TOTAL_TEU" },
            { PROPERTY: "FIRST_SEND_EGP_TOTAL_TEUS", LABEL: "FINANCIAL.GP_FIRST_SEND_TOTAL_TEU" },
            { PROPERTY: "EGP_TOTAL_WEIGHT", LABEL: "FINANCIAL.CT_WEIGHT_TOTAL" },
            { PROPERTY: "INITIAL_EGP_TOTAL_WEIGHT", LABEL: "FINANCIAL.GP_OPEN_WEIGHT_TOTAL" },
            { PROPERTY: "FIRST_SEND_EGP_TOTAL_WEIGHT", LABEL: "FINANCIAL.GP_FIRST_SEND_WEIGHT_TOTAL" },
            { PROPERTY: "EGP_TOTAL_VEHICLES", LABEL: "FINANCIAL.CT_TOTAL_VEHICLES" },
            { PROPERTY: "INITIAL_EGP_TOTAL_VEHICLES", LABEL: "FINANCIAL.GP_OPEN_VEHICLES_TOTAL" },
            { PROPERTY: "FIRST_SEND_EGP_TOTAL_VEHICLES", LABEL: "FINANCIAL.GP_FIRST_SEND_VEHICLES_TOTAL" },
            { PROPERTY: "EGP_TOTAL_FILE", LABEL: "FINANCIAL.CT_TOTAL_FILE" },
            { PROPERTY: "INITIAL_EGP_TOTAL_FILE", LABEL: "FINANCIAL.GP_OPEN_TOTAL_FILE" },
            { PROPERTY: "FIRST_SEND_EGP_TOTAL_FILE", LABEL: "FINANCIAL.GP_FIRST_SEND_TOTAL_FILE" }
        ];
        return props;
    }

    private getProfitShareCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: "COMPOSITION", LABEL: "GENERAL.COMPOSITION" },
            { PROPERTY: "RULE", LABEL: "REGISTRATION.RULE" },
            { PROPERTY: "SHARE", LABEL: "FINANCIAL.PROFIT_SHARE" },
            { PROPERTY: "CURRENCY", LABEL: "GENERAL.CURRENCY" },
            { PROPERTY: "PROFIT_LOSS", LABEL: "FINANCIAL.TOTAL_PROFIT_LOSS" },
            { PROPERTY: "PROFIT_LOSS_INITIAL", LABEL: "FINANCIAL.TOTAL_PROFIT_LOSS_INITIAL" },
            { PROPERTY: "PROFIT_LOSS_FINAL", LABEL: "FINANCIAL.TOTAL_PROFIT_LOSS_END" },
            { PROPERTY: "PROFIT_SHARES", LABEL: "PRODUCT.COMISSION" },
            { PROPERTY: "ACTIVE", LABEL: "GENERAL.ACTIVE" },
            { PROPERTY: "APPLICATION", LABEL: "FINANCIAL.CHARGE_BASIS" },
            { PROPERTY: "EQUIP_TYPE", LABEL: "GENERAL.EQUIPMENT_TYPE" },
            { PROPERTY: "HIERARCHY", LABEL: "REGISTRATION.HIERARCHY" },
            { PROPERTY: "MIN_FILE", LABEL: "FINANCIAL.FILE_MINIMUM" },
            { PROPERTY: "MIN_PERCENT", LABEL: "REGISTRATION.PERCENTAGE" },
            { PROPERTY: "TRANSACTION", LABEL: "BASIC_DATA.TRANSACTION" },
            { PROPERTY: "PROFIT_SHARE", LABEL: "PRODUCT.COMMISSIONED" },
            { PROPERTY: "HOLDER", LABEL: "GENERAL.HOLDER" },
            { PROPERTY: "PROFIT_SHARE", LABEL: "PRODUCT.COMMISSIONED" },
            { PROPERTY: "CHARGE_NAME", LABEL: "BASIC_DATA.CHARGE" },
            { PROPERTY: "FEET", LABEL: "GENERAL.EQUIPMENT_SIZE" },
            { PROPERTY: "PAYMENT_CHARGE", LABEL: "FINANCIAL.PAYMENT_CHARGE" },
            { PROPERTY: "RECEIVING_CHARGE", LABEL: "FINANCIAL.RECEIPT_CHARGE" },
            { PROPERTY: "AMOUNT", LABEL: "GENERAL.QUANTITY" },
            { PROPERTY: "VALUE_UNITARY", LABEL: "FINANCIAL.UNITARY_VALUE" },
            { PROPERTY: "TOTAL", LABEL: "FINANCIAL.TOTAL_AMOUNT" },
            { PROPERTY: "FACTOR", LABEL: "BASIC_DATA.EXCHANGE_RATE" },
            { PROPERTY: "SHARE_VALUE", LABEL: "FINANCIAL.TOTAL_PROFIT_SHARE" },
            { PROPERTY: "COMPANY_SHARE", LABEL: "FINANCIAL.COMPANY_PROFIT_SHARE" },
            { PROPERTY: "AGENT", LABEL: "BASIC_DATA.AGENT" },
            { PROPERTY: "PERCENTUAL_ORIGINAL", LABEL: "FINANCIAL.ORIGINAL_PERCENT" },
            { PROPERTY: "PERCENTUAL_CALCULATED", LABEL: "FINANCIAL.CALCULATED_PERCENT " },
            { PROPERTY: "PERCENTUAL_RESULT", LABEL: "FINANCIAL.RESULTANT_PERCENT" },
            { PROPERTY: "APPLICATION_PARAM", LABEL: "FINANCIAL.APPLICATION_PARAMETER" },
            { PROPERTY: "TOTAL_MIN_UNITARY", LABEL: "FINANCIAL.TOTAL_MIN_UNIT" },
            { PROPERTY: "CICLIC_REFERENCE", LABEL: "GENERAL.CYCLIC_REFERENCE" },
            { PROPERTY: "VALUE_MIN", LABEL: "FINANCIAL.MIN_VALUE" },
            { PROPERTY: "HAS_ERROR", LABEL: "OPERATIONAL.HAS_ERROR" },
            { PROPERTY: "REASON", LABEL: "ENTITY.CORPORATE_NAME" },
            { PROPERTY: "CALCULATE_ORDER", LABEL: "PRODUCT.CALCULATE_ORDER" },
            { PROPERTY: "SHARE_ITEM", LABEL: "REGISTRATION.ITEMS" },
            { PROPERTY: "MIN_APPLICATION", LABEL: "FINANCIAL.BASIS_MIN_UNIT" },
        ];
        return props;
    }

}

import angular = require('angular');
import moment = require('moment');
import { IRestService } from "@services/RestService";
import { TrackingService } from "@services/TrackingService";
import { FormService2 } from '@services/FormService2';
import { IModalService } from '@services/ModalService';
import { IMonacoRequest } from '@services/GridFormService';
import { OperationalService } from '@services/OperationalService';
import { TrackingAWBService } from '@services/TrackingAWBService';
import { HelperService } from "@services/HelperService";
import { IViewLog } from "@models/interface/common/IViewLog";
import { EVENT, DEADLINES } from '@models/interface/operational/NewProcess';
import { NewProcessEvent, IVehicleTypeDate, IProcessEventPanel, IVehicleTypeDateFrontEnd, INewProcessEventService, IOfferService } from "@models/interface/operational/NewProcessEvent";
import { IEventEDITracking } from "@models/interface/tracking/EventEDITracking";
import { ISelectorModel, SelectorModel } from '@models/mongo/SelectorModel';
import { INewProcessEventModel } from '@models/interface/operational/NewProcessTabsModel'
import { IEventCargoAITracking } from '@models/interface/trackingAWB/EventCargoAITracking';
import { IProcessEvent } from '@models/interface/trackingAWB/StatusEventCargoAITracking';
import { EEventTrackingStatus } from "@enums/EventEDITracking";
import { EEventType, EOperation, EProductId, EProcessSituation, EProcessEventTransportModeId, EProcessEventTransportModeName, ETypeFromEvent } from '@enums/GenericData';
import { INewProcessScope, ECollapseState } from './NewProcessController';
import ProcessDeadlinesModal, { IProcessDeadlinesModalParameter } from '../common/ProcessDeadlinesModal';
import { ProductService } from '@services/ProductService';

interface ISituationEvent {
    COLOR: string;
    TEXT: string;
}

interface IEventEDITrackingProcess extends IEventEDITracking {
    IS_VALID_LOCAL: string;
    IS_VALID_SHIP: string;
    IS_VALID_VOYAGE: string;
    IS_VALID_SERVICE_PROVIDER: string;
}

interface INewVoyageStructure {
    useNewVoyage: boolean;
}

interface INewProcessEventScope extends ng.IScope {
    log: IViewLog;
    model: INewProcessEventModel;
    oldModel: INewProcessEventModel;
    eventMaritimeServices: ISelectorModel[];
    eventModal: NewProcessEvent;
    processVehicleTypeEventList: IVehicleTypeDateFrontEnd[];
    newEventModalID: number;
    newEventsData: EVENT[];
    newEventsOptions: ISelectorModel[];
    eventDetailModalID: number;
    eventStatus: ISituationEvent[];
    processNumber: string;
    eventsColor: string[];
    eventsEDITracking: IEventEDITrackingProcess[];
    monacoEventList: any[];
    eventTrackStatusList: ISelectorModel[];
    product: ISelectorModel;
    deadLineModalID: number;
    eventsAirFlightCargoAI: IEventCargoAITracking[];
    voyageIntegration: INewVoyageStructure;
    transitTimeModalID: number;
    collapseEvent: () => void;
    isCollapseIn(): boolean;
    isTrackingEventUpdateAllowed: (eventEDI: IEventEDITracking) => boolean;
    getEventStatus: (event: NewProcessEvent) => void;
    convertToUTC: (date: Date) => void;
    updateEventByTracking: (eventEDI: IEventEDITracking) => void;
    updateEventEDITracking: () => void;
    rebuildEventEDITracking: () => void;
    getMonacoEventList: () => void;
    openProcessDeadlineModal: (readonly: boolean) => void;
    openProcessFlightDeadlineModal: (readonly: boolean) => void;
    applyDeadlinesChanges: () => void;
    getReviewedDeadlineInformation: (deadline: DEADLINES) => string;
    addEvent: () => void;
    addNewEvents: () => void;
    editEvent: (event: NewProcessEvent) => void;
    viewEvent: (event: NewProcessEvent) => void;
    removeEvent: (event: NewProcessEvent) => void;
    serviceActiveChange: (index: number) => void;
    disablePanelDueToPreProcessStatus: () => boolean;
    isPreProcess: () => boolean;
    isLandProduct: () => boolean;
    disablePreProcessRemoveEvent: (event: NewProcessEvent) => void;
    getConcatenatedProcessEventName: (processEvent: IProcessEvent[]) => string;
    changeVoyageIntegration: () => Promise<void>;
    disableEventProcess: (event: NewProcessEvent) => boolean;
    openTransitTimeDetails: (service: INewProcessEventService, serviceOffer: IOfferService[], operation: string) => void;
    calculateTransitTimeRealTotal: (service: INewProcessEventService) => number;
    isMaritimeService: () => boolean;
}

export class NewProcessEventController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: INewProcessEventScope;
    private $newProcessScope: INewProcessScope;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private operationalService: OperationalService;
    private trackingService: TrackingService;
    private restService: IRestService;
    private formService: FormService2;
    private modalService: IModalService;
    private $injector: ng.Injectable<any>;
    private helperService: HelperService;
    private trackingAWBService: TrackingAWBService;

    constructor($injector: ng.Injectable<any>, $scope: INewProcessEventScope) {
        this.$scope = $scope;
        this.$newProcessScope = <INewProcessScope>$scope.$parent.$parent;
        this.$injector = $injector;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.trackingService = $injector.get('TrackingService');
        this.operationalService = $injector.get('OperationalService');
        this.helperService = $injector.get('HelperService');
        this.trackingAWBService = $injector.get('TrackingAWBService');
        this.restService = this.$newProcessScope.restService;
        this.formService = this.$newProcessScope.formService;
        this.modalService = this.$newProcessScope.modalService;
        this.initScopeFunctions();
    }

    async $onInit(): Promise<void> {
        try {
            this.initModel();
            await this.initDependencies();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initModel(): void {
        if (this.$newProcessScope.model) {
            this.$scope.model = {
                TTIME: this.$newProcessScope.model.TTIME,
                TTIME_DIFFERENCE: this.$newProcessScope.model.TTIME_DIFFERENCE,
                EVENT: this.$newProcessScope.model.EVENT,
                SERVICES: this.$newProcessScope.model.SERVICES,
                DEADLINES: this.$newProcessScope.model.DEADLINES,
                SERVICES_OFFER: this.$newProcessScope.model.SERVICES_OFFER,
                TTIME_REAL: null
            };
        } else {
            this.$scope.model = {
                TTIME: null,
                TTIME_DIFFERENCE: null,
                EVENT: null,
                SERVICES: null,
                SERVICES_OFFER: null,
                DEADLINES: null,
                TTIME_REAL: null
            };
        }
        this.$scope.voyageIntegration = {
            useNewVoyage: false
        };
        this.updateEventStatus(this.$scope.model.EVENT);
        this.$scope.oldModel = angular.copy(this.$scope.model);
    }

    private initScopeFunctions(): void {

        this.$scope.collapseEvent = (): void => {
            this.collapseEvent();
        }

        this.$scope.isCollapseIn = (): boolean => {
            return this.$newProcessScope.collapseState.panel == ECollapseState.EVENTS && !this.$newProcessScope.collapseState.released;
        }

        this.$scope.isTrackingEventUpdateAllowed = (eventEDI: IEventEDITracking) => {
            if (eventEDI) {
                const isEventProcessedOrManuallyValidated = eventEDI.STATUS && (eventEDI.STATUS.ID == EEventTrackingStatus.PROCESSED || eventEDI.STATUS.ID == EEventTrackingStatus.MANUALLY_VALIDATED);
                return eventEDI.PROCESS_EVENT && (eventEDI.EFFECTIVE_DATE != null || eventEDI.FORECAST_DATE != null) && !isEventProcessedOrManuallyValidated;
            }
            return false;
        }

        this.$scope.getEventStatus = (event: NewProcessEvent) => {
            this.getEventStatus(event);
        }

        this.$scope.addEvent = async () => {
            await this.addEvent();
        }

        this.$scope.addNewEvents = async () => {
            await this.addNewEvents();
        }

        this.$scope.editEvent = async (event: NewProcessEvent) => {
            await this.openEventModal(event, EOperation.EDIT);
        }

        this.$scope.viewEvent = async (event: NewProcessEvent) => {
            await this.openEventModal(event, EOperation.VIEW);
        }

        this.$scope.removeEvent = async (event: NewProcessEvent) => {
            await this.removeEvent(event);
        }

        this.$scope.serviceActiveChange = (index: number) => {
            this.serviceActiveChange(index);
        }

        this.$scope.convertToUTC = (date: Date) => {
            if (date) return moment.utc(date).format("DD/MM/YYYY HH:mm:ss");
            return null;
        };

        this.$scope.updateEventByTracking = (eventEDI: IEventEDITracking) => {
            this.updateEventByTracking(eventEDI);
        }

        this.$scope.updateEventEDITracking = async () => {
            await this.updateEventEDITracking();
        }

        this.$scope.rebuildEventEDITracking = async () => {
            await this.rebuildEventEDITracking();
        }

        this.$scope.getMonacoEventList = async () => {
            await this.getMonacoEventList();
        }

        this.$scope.openProcessDeadlineModal = (readonly: boolean) => {
            this.openProcessDeadlineModal(readonly);
        }

        this.$scope.getReviewedDeadlineInformation = (deadline: DEADLINES): string => {
            return Object.keys(deadline.DEADLINE_REVIEWS).filter(x => deadline.DEADLINE_REVIEWS[x]).join(', ');
        }

        this.$scope.openProcessFlightDeadlineModal = (readonly: boolean) => {
            this.openProcessFlightDeadlineModal(readonly);
        }

        this.$scope.applyDeadlinesChanges = () => {
            this.applyDeadlinesChanges();
        }

        this.$scope.disablePanelDueToPreProcessStatus = (): boolean => {
            return this.$newProcessScope.model.SITUATION.ID != EProcessSituation.PRE_PROCESS;
        }

        this.$scope.isPreProcess = (): boolean => {
            return this.$newProcessScope && this.$newProcessScope.model && this.$newProcessScope.model.SITUATION && this.$newProcessScope.model.SITUATION.ID === EProcessSituation.PRE_PROCESS;
        };

        this.$scope.isLandProduct = (): boolean => {
            let landProduct = false;
            if (this.$newProcessScope && this.$newProcessScope.model && this.$newProcessScope.model.PRODUCT) {
                if (this.$newProcessScope.model.PRODUCT.ID === EProductId.ROAD_EXPORT || this.$newProcessScope.model.PRODUCT.ID === EProductId.ROAD_IMPORT || this.$newProcessScope.model.PRODUCT.ID === EProductId.ROAD_NATIONAL) landProduct = true;
            }
            return landProduct;
        }

        this.$scope.disablePreProcessRemoveEvent = (event: NewProcessEvent): boolean => {
            return this.$scope.isPreProcess() && ![EEventType.LOAD, EEventType.DISCHARGE].includes(event && event.EVENT_TYPE && event.EVENT_TYPE.ID as EEventType);
        }

        this.$scope.getConcatenatedProcessEventName = (processEvent: IProcessEvent[]): string => {
            return processEvent && processEvent.length && processEvent.map(event => event && event.EVENT_TYPE && event.EVENT_TYPE.NAME).join(', ');
        }

        this.$scope.changeVoyageIntegration = async (): Promise<void> => {

            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: this.formService.getTranslate("BASIC_DATA.CHANGE_VOYAGE_INTEGRATION")
            });

            if (!modal) {
                this.$scope.voyageIntegration.useNewVoyage = !this.$scope.voyageIntegration.useNewVoyage;
                return;
            }

            this.$newProcessScope.model.GET_VOYAGE_BY_INTEGRATION = this.$scope.voyageIntegration.useNewVoyage;
            await this.operationalService.post('/process/panel/event/voyageIntegration', {
                voyageIntegration: this.$scope.voyageIntegration.useNewVoyage,
                processNumber: this.$scope.processNumber,
            });

            await this.getProcessTabsEvent();
        }

        this.$scope.disableEventProcess = (event: NewProcessEvent): boolean => {
            return this.disableEventProcess(event);
        }

        this.$scope.openTransitTimeDetails = (service: INewProcessEventService, serviceOffer: IOfferService[], operation: string) => {
            this.openTransitTimeDetails(service, serviceOffer, operation);
        }


        this.$scope.calculateTransitTimeRealTotal = (service: INewProcessEventService) => {
            return this.calculateTransitTimeRealTotal(service);
        }

        this.$scope.isMaritimeService = (): boolean => {
            let maritimeProduct = false;
            if (this.$newProcessScope && this.$newProcessScope.model && this.$newProcessScope.model.PRODUCT) {
                if (this.$newProcessScope.model.PRODUCT.ID === EProductId.MARITIME_EXPORT || this.$newProcessScope.model.PRODUCT.ID === EProductId.MARITIME_IMPORT) maritimeProduct = true;
            }
            return maritimeProduct;
        }

    }

    async initDependencies(): Promise<any> {
        const self: NewProcessEventController = this;
        this.initCollapseEvents();

        this.$scope.$watch(() => this.$newProcessScope.model.PROCESS_NUMBER, () => {
            this.$scope.processNumber = this.$newProcessScope.model.PROCESS_NUMBER;
        });

        // get generics
        new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericValue("event_tracking_status")
            ]).then(async (result: any) => {
                self.$scope.eventTrackStatusList = result[0];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private disableEventProcess(event: NewProcessEvent): boolean {
        let disable = false;

        if (this.$newProcessScope.operation == EOperation.VIEW) disable = true;
        else if (this.$newProcessScope && this.$newProcessScope.model && this.$newProcessScope.model.SITUATION && this.$newProcessScope.model.SITUATION.ID === EProcessSituation.PRE_PROCESS) disable = false;
        else if (event && event.FROM === ETypeFromEvent.EVENT_WIZARD) disable = true;

        return disable;
    }

    private async getGenericValue(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private initCollapseEvents() {
        this.$scope.$on('processEventCollapse', () => {
            this.collapseEvent();
        });

        const collapseEvent = angular.element('#collapseEvents');
        if (collapseEvent) {
            collapseEvent.on('shown.bs.collapse', (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    this.getProcessTabsEvent();
                    this.$timeout(() => { this.initEventChildPanelCollapseEvents() }, 100);
                    angular.element("#collapseEventHeading").attr('aria-expanded', 'true');
                    // update collapse state
                    this.$newProcessScope.collapseState = { panel: ECollapseState.EVENTS, released: false, nextState: null };
                    this.$newProcessScope.repositionPanels(ECollapseState.EVENTS, true);
                    this.$newProcessScope.disableElements(this.$newProcessScope.operation == EOperation.VIEW);
                }
            });
            collapseEvent.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseEventHeading").attr('aria-expanded', 'false');
                    this.$scope.model = null;
                    this.$scope.oldModel = null;
                }
            });
        }
    }

    private async getProcessTabsEvent(): Promise<void> {
        this.formService.block();
        try {
            const timeout: number = 120000;

            const mainEvent = await this.operationalService.get(`/process/panel/event/${this.$scope.processNumber}/${timeout}`, timeout);
            this.$scope.product = this.$newProcessScope.model.PRODUCT;
            if (mainEvent && mainEvent.data && mainEvent.data.data) this.$scope.model = mainEvent.data.data;
            this.updateEventStatus(this.$scope.model.EVENT);
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.oldModel = angular.copy(this.$scope.model);
            if (this.$newProcessScope.operation == 'edit') {
                this.$timeout(() => {
                    this.formService.loadEditForm();
                }, 100);
            }
            this.formService.unblock();
        }
    }

    private async collapseEvent() {
        try {
            if (this.$newProcessScope.collapseState.released || this.$newProcessScope.collapseState.panel == ECollapseState.EVENTS) {
                const collapseEvent = angular.element("#collapseEvents");
                if (collapseEvent) {
                    const isCollapsed = angular.element("#collapseEventHeading").attr("aria-expanded") == "true";
                    if (isCollapsed) {
                        this.$newProcessScope.collapseState.released = true;
                    }
                    collapseEvent['collapse']('toggle');
                    if (isCollapsed) this.$newProcessScope.repositionPanels(ECollapseState.EVENTS);
                }
            } else {
                this.$newProcessScope.collapseState.nextState = ECollapseState.EVENTS;
                this.$newProcessScope.releaseCollapse();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async initEventChildPanelCollapseEvents() {
        try {
            const collapseTracking = angular.element('#collapseTracking');
            if (collapseTracking) {
                collapseTracking.on('shown.bs.collapse', (event: JQuery.Event) => {
                    if (event.target == event.currentTarget) {
                        angular.element("#trackingButtons").show();
                        this.initTrackingEvents();
                    }
                });
                collapseTracking.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                    if (event.target == event.currentTarget) angular.element("#trackingButtons").hide();
                });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async openProcessDeadlineModal(readonly: boolean): Promise<void> {
        const oldDeadlines = angular.copy(this.$scope.model.DEADLINES);
        const modalParameters: IProcessDeadlinesModalParameter = {
            title: readonly ? `${this.formService.getTranslate('OPERATIONAL.VIEWING_DEADLINES')}` : `${this.formService.getTranslate('OPERATIONAL.EDITING_DEADLINES')}`,
            master: this.$scope.model.DEADLINES.find(d => d.TYPE === 'MASTER'),
            house: this.$scope.model.DEADLINES.find(d => d.TYPE === 'HOUSE'),
            load: this.$scope.model.EVENT && this.$scope.model.EVENT.length > 0 ? this.$scope.model.EVENT.find(event => event.EVENT_TYPE.ID === EEventType.LOAD).FORECAST_DATE : null,
            processNumber: this.$scope.processNumber,
            readonly,
        }

        const modalInstance = new ProcessDeadlinesModal(this.$injector, this.$scope);
        const result = await modalInstance.show(modalParameters);
        if (!result) this.$scope.model.DEADLINES = oldDeadlines;
    }

    private async openProcessFlightDeadlineModal(readonly: boolean): Promise<void> {
        try {
            this.$scope.deadLineModalID = this.modalService.newModal();
            const master = this.$scope.model.DEADLINES.find(d => d.TYPE === 'MASTER');
            const house = this.$scope.model.DEADLINES.find(d => d.TYPE === 'HOUSE');
            await this.modalService.showModalInfo(
                {
                    modalID: this.$scope.deadLineModalID,
                    scope: this.$scope,
                    template: require("../view/modal/flightDeadlineModal.html"),
                    formService: 'register',
                    size: 'md',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: readonly ? `${this.formService.getTranslate('OPERATIONAL.VIEWING_DEADLINES')}` : `${this.formService.getTranslate('OPERATIONAL.EDITING_DEADLINES')}`
                },
                {
                    house,
                    master,
                    readonly
                }

            );
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async applyDeadlinesChanges(): Promise<void> {
        try {
            this.formService.block();
            const master = this.$scope.model.DEADLINES.find(d => d.TYPE === 'MASTER');
            const house = this.$scope.model.DEADLINES.find(d => d.TYPE === 'HOUSE');
            await this.operationalService.post('/deadline/update', {
                deadlines: [master, house],
                processNumber: this.$scope.processNumber,
            });

            this.modalService.closeModal(this.$scope.deadLineModalID, true);
            await this.$scope.$applyAsync();
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private updateEventStatus(events: Array<NewProcessEvent>): void {
        try {
            this.$scope.eventStatus = [];

            if (this.$scope.model.EVENT)
                if (this.$newProcessScope.model.PRODUCT.ID == EProductId.MARITIME_EXPORT || this.$newProcessScope.model.PRODUCT.ID == EProductId.MARITIME_IMPORT) {
                    this.$scope.model.EVENT.sort(function (first, second) {
                        if (first.SEQUENCE && second.SEQUENCE) {
                            const firstIsDischargeOrLoadTransshipment = first.EVENT_TYPE && (first.EVENT_TYPE.ID == EEventType.DISCHARGE_TRANSHIPMENT || first.EVENT_TYPE.ID == EEventType.LOAD_TRANSHIPMENT);
                            const secondIsDischargeOrLoadTransshipment = second.EVENT_TYPE && (second.EVENT_TYPE.ID == EEventType.DISCHARGE_TRANSHIPMENT || second.EVENT_TYPE.ID == EEventType.LOAD_TRANSHIPMENT);
                            if (firstIsDischargeOrLoadTransshipment && secondIsDischargeOrLoadTransshipment && first.LOCAL && second.LOCAL) {
                                const firstDateToCompare = first.EFFECTIVE_DATE ? first.EFFECTIVE_DATE : first.FORECAST_DATE;
                                const secondDateToCompare = second.EFFECTIVE_DATE ? second.EFFECTIVE_DATE : second.FORECAST_DATE;
                                const firstDateTime = firstDateToCompare ? new Date(firstDateToCompare).getTime() : 0;
                                const secondDateTime = secondDateToCompare ? new Date(secondDateToCompare).getTime() : 0;
                                return firstDateTime - secondDateTime || first.SEQUENCE - second.SEQUENCE; // || first.LOCAL.NAME.localeCompare(second.LOCAL.NAME)
                            }
                            if (first.SEQUENCE > second.SEQUENCE) return 1;
                            if (first.SEQUENCE < second.SEQUENCE) return -1;
                        }
                    });
                } else if (this.$newProcessScope.model.PRODUCT.ID == EProductId.AIR_EXPORT || this.$newProcessScope.model.PRODUCT.ID == EProductId.AIR_IMPORT) {
                    this.$scope.model.EVENT.sort(function (first, second) {
                        if (first.SEQUENCE && second.SEQUENCE) {
                            const firstIsDischargeOrLoadTransshipment = first.EVENT_TYPE && (first.EVENT_TYPE.ID == EEventType.CONNECTION_ARRIVAL || first.EVENT_TYPE.ID == EEventType.CONNECTION_DEPARTURE);
                            const secondIsDischargeOrLoadTransshipment = second.EVENT_TYPE && (second.EVENT_TYPE.ID == EEventType.CONNECTION_DEPARTURE || second.EVENT_TYPE.ID == EEventType.CONNECTION_ARRIVAL);
                            if (firstIsDischargeOrLoadTransshipment && secondIsDischargeOrLoadTransshipment && first.LOCAL && second.LOCAL) {
                                const firstDateToCompare = first.EFFECTIVE_DATE ? first.EFFECTIVE_DATE : first.FORECAST_DATE;
                                const secondDateToCompare = second.EFFECTIVE_DATE ? second.EFFECTIVE_DATE : second.FORECAST_DATE;
                                const firstDateTime = firstDateToCompare ? new Date(firstDateToCompare).getTime() : 0;
                                const secondDateTime = secondDateToCompare ? new Date(secondDateToCompare).getTime() : 0;
                                return firstDateTime - secondDateTime || first.SEQUENCE - second.SEQUENCE; // || first.LOCAL.NAME.localeCompare(second.LOCAL.NAME)
                            }
                            if (first.SEQUENCE > second.SEQUENCE) return 1;
                            if (first.SEQUENCE < second.SEQUENCE) return -1;
                        }
                    });
                } else if (this.$newProcessScope.model.PRODUCT.ID == EProductId.ROAD_EXPORT || this.$newProcessScope.model.PRODUCT.ID == EProductId.ROAD_IMPORT || this.$newProcessScope.model.PRODUCT.ID == EProductId.ROAD_NATIONAL) {
                    this.$scope.model.EVENT.sort(function (first, second) {
                        if (first.SEQUENCE && second.SEQUENCE) {
                            const firstIsDischargeOrLoadTransshipment = first.EVENT_TYPE && (first.EVENT_TYPE.ID == EEventType.DELIVERY || first.EVENT_TYPE.ID == EEventType.PICK_UP);
                            const secondIsDischargeOrLoadTransshipment = second.EVENT_TYPE && (second.EVENT_TYPE.ID == EEventType.DELIVERY || second.EVENT_TYPE.ID == EEventType.PICK_UP);
                            if (firstIsDischargeOrLoadTransshipment && secondIsDischargeOrLoadTransshipment && first.LOCAL && second.LOCAL) {
                                const firstDateToCompare = first.EFFECTIVE_DATE ? first.EFFECTIVE_DATE : first.FORECAST_DATE;
                                const secondDateToCompare = second.EFFECTIVE_DATE ? second.EFFECTIVE_DATE : second.FORECAST_DATE;
                                const firstDateTime = firstDateToCompare ? new Date(firstDateToCompare).getTime() : 0;
                                const secondDateTime = secondDateToCompare ? new Date(secondDateToCompare).getTime() : 0;
                                return firstDateTime - secondDateTime || first.SEQUENCE - second.SEQUENCE; // || first.LOCAL.NAME.localeCompare(second.LOCAL.NAME)
                            }
                            if (first.SEQUENCE > second.SEQUENCE) return 1;
                            if (first.SEQUENCE < second.SEQUENCE) return -1;
                        }
                    });
                }
            //change status
            if (events) {
                for (const event of events) {
                    this.$scope.eventStatus[event.EVENT_ID] = this.getEventStatus(event);
                };
            }
            this.$scope.voyageIntegration.useNewVoyage = this.$newProcessScope.model.GET_VOYAGE_BY_INTEGRATION;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getEventStatus(event: NewProcessEvent): ISituationEvent {
        try {
            /*  Previsão Vencida & Efetivo Nulo = Vermelho
                Efetivo Maior que Previsão = Amarelo
                Efetivo e Previsão mesmo dia = Verde
                Efetivo menor que Previsão = Azul 
            */
            let status: ISituationEvent = { COLOR: 'text-default', TEXT: 'OPERATIONAL.WAITING_FORECAST_DATE' };

            const todayDate = new Date();
            const estimatedDate = (event.FORECAST_DATE) ? new Date(event.FORECAST_DATE) : null;
            const effectiveDate = (event.EFFECTIVE_DATE) ? new Date(event.EFFECTIVE_DATE) : null;

            if (effectiveDate && estimatedDate) {
                if (effectiveDate.getTime() > estimatedDate.getTime()) {
                    //YELLOW - WARNING - fa-circle
                    status.COLOR = 'text-warning';
                    status.TEXT = 'OPERATIONAL.ACTUAL_GREATER_THAN_FORECAST';
                    //$scope.eventList.STATUS = 'YELLOW';
                } else if (effectiveDate.getTime() === estimatedDate.getTime()) {
                    //GREEN - SUCCESS - fa-circle
                    status.COLOR = 'text-success';
                    status.TEXT = 'OPERATIONAL.ACTUAL_FORECAST_SAME_DAY';
                    //$scope.eventList.STATUS = 'GREEN';
                } else if (effectiveDate.getTime() < estimatedDate.getTime()) {
                    //BLUE - PRIMARY - fa-circle
                    status.COLOR = 'text-primary';
                    status.TEXT = 'OPERATIONAL.ACTUAL_LOWER_THAN_FORECAST';
                    //$scope.eventList.STATUS = 'BLUE';
                }
            } else if (estimatedDate && !effectiveDate && todayDate.getTime() > estimatedDate.getTime()) {
                //RED - DANGER - fa-circle
                status.COLOR = 'text-danger';
                status.TEXT = 'OPERATIONAL.ESTIMATED_DATE_EXPIRED';
                //$scope.eventList.STATUS = 'BLUE';
            } else if (estimatedDate && !effectiveDate && todayDate.getTime() <= estimatedDate.getTime()) {
                //ORANGE - ORANGE - fa-circle
                status.COLOR = 'text-orange';
                status.TEXT = 'OPERATIONAL.WAITING_ACTUAL_DATE';
            }

            return status;

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private serviceActiveChange(index: number) {
        if (!index && index != 0) throw Error('index is null');

        const changedService = this.$scope.model.SERVICES && this.$scope.model.SERVICES.length ? this.$scope.model.SERVICES[index] : null;
        const activeServices = this.$scope.model.SERVICES && this.$scope.model.SERVICES.length ? this.$scope.model.SERVICES.filter((service, serviceIndex) => service.ACTIVE && serviceIndex != index) : [];
        if (changedService && changedService.ACTIVE && activeServices.length) {
            activeServices.forEach(reference => {
                reference.ACTIVE = false;
            });
        }
    }

    private async addEvent() {
        try {
            this.formService.block();

            const event: EVENT = null;
            this.$scope.eventModal = angular.copy(event);
            const product = this.$newProcessScope.model.PRODUCT.ID;
            const currentEventList = new Array<any>();
            for (let i = 0; i < this.$scope.model.EVENT.length; i++) {
                currentEventList.push({ EVENT_ID: this.$scope.model.EVENT[i].EVENT_ID, EVENT_NUMBER: this.$scope.model.EVENT[i].EVENT_NUMBER, EVENT_TYPE: this.$scope.model.EVENT[i].EVENT_TYPE });
            }
            const result = await this.getEventListByProduct(product, currentEventList);
            const data = (result.data && result.data.data) ? result.data.data : null;
            if (!data) {
                this.formService.unblock();
                const msg = this.formService.getTranslate('OPERATIONAL.NO_EVENTS_FOUND_CHECK_IF_ALL_EVENTS_ARE_INCLUDED');
                return this.formService.notifyInfo(msg);
            }

            //format newEvent selector options
            this.$scope.newEventsData = data;
            this.$scope.newEventsOptions = new Array<ISelectorModel>();
            const eventIdsToFilter = (this.$scope.isPreProcess()) ? [EEventType.LOAD, EEventType.DISCHARGE] : [EEventType.PICK_UP, EEventType.LOAD, EEventType.DISCHARGE, EEventType.FINAL_DESTINATION, EEventType.DELIVERY, EEventType.PLACE_OF_RECEIPT];
            for (let i = 0; i < data.length; i++) {
                if (!eventIdsToFilter.some(eventId => data[i].EVENT && (eventId == data[i].EVENT.ID))) {
                    this.$scope.newEventsOptions.push({ ID: data[i].EVENT_NUMBER, NAME: data[i].EVENT.NAME, CODE: data[i].LOCAL_TYPE.NAME });
                }
            }

            this.formService.unblock();

            this.$scope.newEventModalID = this.modalService.newModal();
            this.modalService.showModalInfo(
                {
                    modalID: this.$scope.newEventModalID,
                    template: require("../view/processNewEventModal.html"),
                    scope: this.$scope, //passed current scope to the modal
                    formService: 'register'
                },
                {
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: 'BASIC_DATA.FILL_IN_THE_DATA_AND_CLICK_ON_ADD'
                });

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async addNewEvents() {
        try {
            this.formService.block();

            const modalScope = await this.modalService.getModalScope(this.$scope.newEventModalID);
            //TODO: CAN'T GET THE FUCKING NG-MODEL OF THE MULTIPLE SELECTOR INSIDE THE MODAL
            const selectedEventList = modalScope['modalForm']['newEventsSelector'].$modelValue;
            const eventList = new Array<any>();
            for (let i = 0; i < selectedEventList.length; i++) {
                const selectedEvent = this.$scope.newEventsData.find(x => x.EVENT_NUMBER === selectedEventList[i].ID);
                eventList.push(selectedEvent);
            }

            for (const event of eventList) {
                const timeout = 10000;
                const request = {
                    processNumber: this.$scope.processNumber,
                    eventId: event.EVENT.ID,
                    timeout: timeout
                };
                const result = await this.operationalService.post("/event/insert", request, timeout);
                if (result.status === 200 && result.data && result.data.data) {
                    //update process view event table
                    const eventsProcess = await this.getEventsByProcessId();
                    this.$scope.model.EVENT = eventsProcess.data.data[0]['EVENT'];
                    this.updateEventStatus(this.$scope.model.EVENT);
                } else {
                    //FAILED
                    const msg = this.formService.getTranslate('BASIC_DATA.FAILED_TO_ADD_EVENT');
                    this.formService.notifyError(msg);
                }
            }
            //close modal
            this.modalService.closeModal(this.$scope.newEventModalID);
            delete this.$scope.newEventModalID;
            delete this.$scope.newEventsData;
            delete this.$scope.newEventsOptions;
            const msg = this.formService.getTranslate('BASIC_DATA.EVENT_SUCESSFULLY_ADDED');
            this.formService.notifySuccess(msg);

            this.formService.unblock();

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getEventListByProduct(productCode, currentEvents) {
        const timeout = 15000;
        const request = {
            productCode: productCode,
            currentEvents: currentEvents,
            timeout: timeout
        };
        return this.operationalService.post("/event/list", request, timeout);
    }

    private async getEventsByProcessId() {
        return this.operationalService.get("/event/list/processId/" + this.$newProcessScope.model._id.toString(), null, 10000);
    }

    private async openEventModal(event: NewProcessEvent, operation: string) {
        const timeout: number = 120000;
        await this.getEventsByProcessId();

        const eventResult = await this.operationalService.get(`/process/panel/event/${this.$scope.processNumber}/${timeout}`, timeout);
        const mainEvent: IProcessEventPanel = eventResult.data && eventResult.data.data ? eventResult.data.data : null;
        if (!mainEvent) return this.formService.notifyError('Fail to get event data');

        const eventEdit = mainEvent.EVENT.find(y => y.EVENT_ID == event.EVENT_ID);

        const modalSize = [EProductId.ROAD_EXPORT, EProductId.ROAD_IMPORT, EProductId.ROAD_NATIONAL].includes(this.$newProcessScope.model.PRODUCT.ID as EProductId) ? 'vlg' : 'lg';

        this.$scope.eventMaritimeServices = mainEvent.SERVICES ? mainEvent.SERVICES.map(service => service.MARITIME_SERVICE) : null;
        this.$scope.eventModal = angular.copy(eventEdit);
        this.$scope.eventDetailModalID = this.modalService.newModal();
        this.$scope.product = this.$newProcessScope.model.PRODUCT;
        this.$scope.processVehicleTypeEventList = [];

        if (this.$scope.isLandProduct() && this.$scope.eventModal.VEHICLE_TYPE_DATE && this.$scope.eventModal.VEHICLE_TYPE_DATE.length) {
            const vehicleList: IVehicleTypeDateFrontEnd[] = [];
            for (const vehicleDate of this.$scope.eventModal.VEHICLE_TYPE_DATE) {
                const vehicle = mainEvent.CARGO_DETAIL.VEHICLE_TYPE_LIST.find(x => x.ID === vehicleDate.PROCESS_VEHICLE_TYPE_ID);
                if (vehicle) {
                    vehicleList.push({
                        EVENT_ID: vehicleDate.EVENT_ID,
                        PROCESS_VEHICLE_TYPE_ID: vehicleDate.PROCESS_VEHICLE_TYPE_ID,
                        FORECAST_DATE: vehicleDate.FORECAST_DATE,
                        EFFECTIVE_DATE: vehicleDate.EFFECTIVE_DATE,
                        ID: vehicle.ID,
                        ID_PROCESS: vehicle.ID_PROCESS,
                        TRUCK_LICENSE_PLATE: vehicle.TRAILER_LICENSE_PLATE,
                        TRAILER_LICENSE_PLATE: vehicle.TRAILER_LICENSE_PLATE,
                        DRIVER_NAME: vehicle.DRIVER_NAME,
                        DRIVER_ID: vehicle.DRIVER_ID,
                        NCM: vehicle.NCM,
                        NALADI: vehicle.NALADI,
                        UNITY_QUANTITY: vehicle.UNITY_QUANTITY,
                        PACKAGE_QUANTITY: vehicle.PACKAGE_QUANTITY,
                        GROUP_QUANTITY: vehicle.GROUP_QUANTITY,
                        PACKAGE_GROUP: vehicle.PACKAGE_GROUP,
                        GROSS_WEIGHT: vehicle.GROSS_WEIGHT,
                        NET_WEIGHT: vehicle.NET_WEIGHT,
                        CUBIC_METER: vehicle.CUBIC_METER,
                        REFERENCE: vehicle.REFERENCE,
                    });
                }
            }

            this.$scope.processVehicleTypeEventList = vehicleList;
            this.$scope.eventModal.PROCESS_EVENT_TRANSPORT_MODE = {
                ID: EProcessEventTransportModeId.TRUCK,
                NAME: EProcessEventTransportModeName.TRUCK,
                CODE: null
            };
        }

        this.modalService.showModalInfo(
            {
                modalID: this.$scope.eventDetailModalID,
                template: require("../view/processEventDetailModal.html"),
                scope: this.$scope, //passed current scope to the modal
                formService: operation,
                size: modalSize,
                keyboard: true,
                events: async (event: angular.IAngularEvent) => {
                    if (event.name == "modal.closing") {
                        // update events data
                        this.getProcessTabsEvent();
                        // update newprocess main (situation, ttime)
                        const mainTab = await this.operationalService.get(`/process/panel/main/${this.$scope.processNumber}`, null, 30000);
                        if (mainTab && mainTab.data && mainTab.data.data) {
                            this.$newProcessScope.model.SITUATION = mainTab.data.data.SITUATION;
                            this.$newProcessScope.model.TTIME = mainTab.data.data.TTIME;
                            this.$newProcessScope.model.TTIME_DIFFERENCE = mainTab.data.data.TTIME_DIFFERENCE;
                        }
                    }
                }
            },
            {
                actionButtonText: 'GENERAL.CLOSE',
                headerText: `${this.formService.getTranslate('OPERATIONAL.EVENT_DETAILS')} - ${event.EVENT_NUMBER} (${event.EVENT_TYPE.NAME})`,
            },
            {
                process: this.$newProcessScope.model
            }
        );
    }

    private async removeEvent(event: NewProcessEvent) {
        try {
            const remove = await this.modalService.showModalConfirmation({}, {
                headerText: `${this.formService.getTranslate('BASIC_DATA.REMOVE_EVENT')} - ${event.EVENT_NUMBER} (${event.EVENT_TYPE.NAME})`,
                bodyText: `${this.formService.getTranslate('BASIC_DATA.ARE_YOU_SURE_YOU_WANT_TO_REMOVE_THIS_EVENT')} <b>${this.$scope.processNumber}</b>?`,
                closeButtonText: 'GENERAL.CLOSE',
                actionButtonText: 'GENERAL.SAVE'
            });

            if (!remove) return;

            this.formService.block();

            const timeout = 10000;
            const request = {
                processNumber: this.$scope.processNumber,
                eventId: event.EVENT_ID,
                timeout: timeout
            };
            const result = await this.operationalService.post("/event/remove", request, timeout);
            if (result.status === 200 && result.data.data) {
                const eventIndex = this.$scope.model.EVENT.findIndex(x => x.EVENT_ID === event.EVENT_ID);
                this.$scope.model.EVENT.splice(eventIndex, 1);

                const msg = this.formService.getTranslate('BASIC_DATA.EVENT_SUCESSFULLY_REMOVED');
                this.formService.notifySuccess(msg);
            } else {
                //FAILED
                const msg = this.formService.getTranslate('BASIC_DATA.FAILED_TO_REMOVE_EVENT');
                this.formService.notifyError(msg);
            }

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async initTrackingEvents() {
        if (this.$newProcessScope.model.PRODUCT.ID == EProductId.MARITIME_EXPORT || this.$newProcessScope.model.PRODUCT.ID == EProductId.MARITIME_IMPORT) {
            await this.initMaritimeTrackingEvents();
        }
        else if (this.$newProcessScope.model.PRODUCT.ID == EProductId.AIR_EXPORT || this.$newProcessScope.model.PRODUCT.ID == EProductId.AIR_IMPORT) {
            await this.initAirFlightTrackingEvents();
        }
    }

    private async initAirFlightTrackingEvents(): Promise<void> {
        try {
            this.formService.block();

            const { PROCESS_NUMBER } = this.$newProcessScope.model;
            const request: IMonacoRequest = {
                route: `/cargoAI/${PROCESS_NUMBER}`,
                timeout: 30000,
            };

            const rc = await this.trackingAWBService.get(request);
            const airTrackingEvents = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            this.$scope.eventsAirFlightCargoAI = (airTrackingEvents && airTrackingEvents.EVENTS) ? airTrackingEvents.EVENTS : null;
            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
            this.formService.unblock();
        }
    }

    private async initMaritimeTrackingEvents() {
        try {
            this.formService.block();

            let eventsEDITracking: IEventEDITracking[] = [];
            this.$scope.eventsColor = [];

            const trackingReq: IMonacoRequest = {
                data: { processNumber: this.$scope.processNumber },
                route: '/tracking/eventEDITracking/trackingByProcess/',
                timeout: 15000,
            }

            const result = await this.trackingService.post(trackingReq);
            if (result && result.data && result.data.data) {
                eventsEDITracking = result.data.data;
            }
            this.$scope.eventsEDITracking = eventsEDITracking && eventsEDITracking.length ? eventsEDITracking.map((event) => {
                return {
                    ...event,
                    IS_VALID_LOCAL: event.LOCAL_VALIDATION_STATUS ? event.LOCAL_VALIDATION_STATUS.ID == "1" ? "1" : "2" : "3",
                    IS_VALID_SHIP: event.TRANSPORT_MODE_VALIDATION_STATUS ? event.TRANSPORT_MODE_VALIDATION_STATUS.ID == "1" ? "1" : "2" : "3",
                    IS_VALID_VOYAGE: event.VOYAGE_VALIDATION_STATUS ? event.VOYAGE_VALIDATION_STATUS.ID == "1" ? "1" : "2" : "3",
                    IS_VALID_SERVICE_PROVIDER: event.SERVICE_PROVIDER_VALIDATION_STATUS ? event.SERVICE_PROVIDER_VALIDATION_STATUS.ID == "1" ? "1" : "2" : "3",
                }
            }).sort(function (a, b) {
                const processEventA = (a.PROCESS_EVENT) ? a.PROCESS_EVENT.SEQUENCE : Number.MAX_VALUE;
                const processEventB = (b.PROCESS_EVENT) ? b.PROCESS_EVENT.SEQUENCE : Number.MAX_VALUE;
                if (processEventA > processEventB) return 1;
                if (processEventA < processEventB) return -1;
            }) : [];
        } catch (ex) {
            this.$scope.eventsEDITracking = [];
        } finally {
            this.formService.unblock();
        }
    }

    private async updateEventByTracking(eventEDI: IEventEDITracking) {
        if (eventEDI) {
            const isEventValidatedOrError = eventEDI.STATUS && (eventEDI.STATUS.ID == EEventTrackingStatus.VALIDATED || eventEDI.STATUS.ID == EEventTrackingStatus.ERROR);
            const isEventDivergence = eventEDI.STATUS && (eventEDI.STATUS.ID == EEventTrackingStatus.FIELD_DIVERGENCE || eventEDI.STATUS.ID == EEventTrackingStatus.DIVERGING_CONTAINERS || eventEDI.STATUS.ID == EEventTrackingStatus.DIVERGENT_FILL_PARAMETRIZATION);
            const confirmedChange = isEventDivergence ? await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate('OPERATIONAL.THIS_EVENT_HAS_VALIDATION_DIVERGENCES')
            }) : true;
            if (isEventValidatedOrError || confirmedChange) {
                const processEvent = this.$scope.model.EVENT && this.$scope.model.EVENT.length ? angular.copy(this.$scope.model.EVENT.find(event => eventEDI.PROCESS_EVENT && event.EVENT_ID == eventEDI.PROCESS_EVENT.EVENT_ID)) : null;
                if (processEvent) {
                    if (eventEDI.FORECAST_DATE) processEvent.FORECAST_DATE = eventEDI.FORECAST_DATE;
                    if (eventEDI.EFFECTIVE_DATE && !processEvent.EFFECTIVE_DATE) processEvent.EFFECTIVE_DATE = eventEDI.EFFECTIVE_DATE;
                    if (processEvent.CONTAINER_GROUP) {
                        processEvent.CONTAINER_GROUP.forEach((containerGroup) => {
                            const containerGroupEDI = eventEDI.CONTAINERS && eventEDI.CONTAINERS.length ? eventEDI.CONTAINERS.find(containerGroupEDI => containerGroupEDI.NAME == containerGroup.CONTAINER_NUMBER) : null;
                            if (containerGroupEDI) {
                                if (containerGroupEDI.FORECAST_DATE) containerGroup.FORECAST_DATE = containerGroupEDI.FORECAST_DATE;
                                if (containerGroupEDI.EFFECTIVE_DATE && !containerGroup.EFFECTIVE_DATE) containerGroup.EFFECTIVE_DATE = containerGroupEDI.EFFECTIVE_DATE;
                            }
                        });
                    }
                    if (await this.updateProcessEvent(processEvent)) this.getProcessTabsEvent();
                }
            }
        }
    }

    private async updateProcessEvent(processEvent: EVENT): Promise<boolean> {
        let success = false;
        try {
            if (!processEvent) this.formService.notifyError("processEvent is null.");
            else {
                this.formService.block();
                const manuallyValidated = this.$scope.eventTrackStatusList && this.$scope.eventTrackStatusList.length ? this.$scope.eventTrackStatusList.find(status => status.ID == EEventTrackingStatus.MANUALLY_VALIDATED) : null;
                const msgError = this.formService.getTranslate('BASIC_DATA.ERROR_NOT_FOUND');
                if (!manuallyValidated) this.formService.notifyError(msgError);
                else {
                    const request = {
                        processNumber: this.$newProcessScope.model.PROCESS_NUMBER,
                        event: processEvent,
                        statusTracking: manuallyValidated,
                        timeout: 120000
                    };
                    const result = await this.operationalService.post("/event/update", request, 120000);
                    if (result.status !== 200 || !result.data || !result.data['data']) {
                        this.formService.handleError(result);
                    } else {
                        success = true;
                        const msg = this.formService.getTranslate('OPERATIONAL.EVENT_SUCESSFULLY_UPDATED');
                        this.formService.notifySuccess(msg);
                        await this.getProcessTabsEvent();
                        await this.initTrackingEvents();
                    }
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return success;
        }
    }

    private async updateEventEDITracking() {
        try {
            if (!this.$scope.eventsEDITracking) throw this.formService.getTranslate('OPERATIONAL.THIS_FILE_DOES_NOT_HAVE_TRACKING_EVENTS');
            this.formService.block();
            let eventsEDITrackingOld: IEventEDITracking[] = null;
            const trackingReq: IMonacoRequest = {
                data: { processNumber: this.$scope.processNumber },
                route: '/tracking/eventEDITracking/trackingByProcess/',
                timeout: 15000,
            }
            const result = await this.trackingService.post(trackingReq);
            if (result && result.data && result.data.data) {
                eventsEDITrackingOld = result.data.data;
            }
            for (const event of this.$scope.eventsEDITracking) {
                // se é um registro editado
                if (event.PROCESS_EVENT && !event.PROCESS_EVENT.EVENT_ID) {
                    event.PROCESS_EVENT = this.$scope.model.EVENT.find(y => y.EVENT_TYPE.ID == event.PROCESS_EVENT.EVENT_TYPE.ID);
                    const eventUpdate = eventsEDITrackingOld.find(y => y._id == event._id);
                    if (eventUpdate) {
                        eventUpdate.PROCESS_EVENT = event.PROCESS_EVENT;
                        const TrackingReq: IMonacoRequest = {
                            data: {
                                eventEDITracking: eventUpdate,
                                timeout: 120000
                            },
                            route: `/tracking/eventEDITracking/updateMonacoEvents/`,
                            timeout: 120000
                        }
                        const result = await this.trackingService.post(TrackingReq);
                        if (!result || !result.data || !result.data.data) {
                            throw this.formService.getTranslate('OPERATIONAL.FAILED_TO_UPDATE_TRACKING_EVENTS');
                        }
                        // busca se tem igual ele, com tracking vazio
                        const findEventEDI = this.$scope.eventsEDITracking.find(y => y.PROCESS_EVENT && !y.TRACKING_EVENT && y.PROCESS_EVENT.EVENT_TYPE && y.PROCESS_EVENT.EVENT_TYPE.ID == event.PROCESS_EVENT.EVENT_TYPE.ID);
                        if (findEventEDI) {
                            const TaskReq2: IMonacoRequest = {
                                data: {
                                    eventEDITracking: findEventEDI,
                                    timeout: 120000
                                },
                                route: `/tracking/eventEDITracking/deleteEventEDITracking/`,
                                timeout: 120000
                            }
                            const result2 = await this.trackingService.post(TaskReq2);
                            if (!result2 || !result2.data || !result2.data.data) {
                                throw this.formService.getTranslate('OPERATIONAL.FAILED_TO_REMOVE_TRACKING_EVENTS');
                            }
                        }
                    }
                }
            }
            this.formService.unblock();
            await this.initTrackingEvents();
            const msg = this.formService.getTranslate('OPERATIONAL.TRACKING_SUCESSFULLY_UPDATED');
            this.formService.notifySuccess(msg);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async rebuildEventEDITracking() {
        if (this.$newProcessScope.model.PRODUCT.ID == EProductId.MARITIME_EXPORT || this.$newProcessScope.model.PRODUCT.ID == EProductId.MARITIME_IMPORT) {
            await this.rebuildEventEDITrackingMaritime();
        }
    }

    private async rebuildEventEDITrackingMaritime() {
        try {
            if (!this.$scope.processNumber) throw this.formService.getTranslate('OPERATIONAL.FILE_NUMBER_NOT_REPORTED');

            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate('OPERATIONAL.THIS_PROCEDURE_WILL_RECREATE_ALL_TRACKING_EVENTS')
            });

            if (!modal) return;

            this.formService.block();

            const TrackingReq: IMonacoRequest = {
                data: {
                    processNumber: this.$scope.processNumber,
                    timeout: 120000
                },
                route: `/tracking/eventEDITracking/updateEventEDITrackingOnSource/`,
                timeout: 120000
            }

            const result = await this.trackingService.post(TrackingReq);
            if (!result || !result.data || !result.data.data) {
                throw this.formService.getTranslate('OPERATIONAL.FAILED_TO_UPDATE_TRACKING_EVENTS');
            }

            this.$scope.eventsEDITracking = [];
            await this.initTrackingEvents();

            this.formService.unblock();

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getMonacoEventList() {
        this.$scope.monacoEventList = [];

        // monaco Events
        const monacoEvents = this.$scope.model.EVENT;
        const trackingEvents = [];

        if (this.$scope.eventsEDITracking) {
            for (const trackingEvent of this.$scope.eventsEDITracking) {
                if (trackingEvent.PROCESS_EVENT && trackingEvent.TRACKING_EVENT) trackingEvents.push(trackingEvent.PROCESS_EVENT.EVENT_TYPE.ID);
            }

            if (monacoEvents) {
                let eventName = '';
                for (const monacoEvent of monacoEvents) {
                    eventName = monacoEvent.LOCAL ? monacoEvent.EVENT_TYPE.NAME + ' - ' + monacoEvent.LOCAL.NAME : monacoEvent.EVENT_TYPE.NAME
                    if (!trackingEvents.some(y => y == monacoEvent.EVENT_TYPE.ID)) this.$scope.monacoEventList.push({ ID: monacoEvent.EVENT_TYPE.ID, NAME: eventName });
                }
            }
        }
    }

    private async openTransitTimeDetails(service: INewProcessEventService, serviceOffer: IOfferService[], operation: string): Promise<void> {
        if (!service) return;

        this.$scope.transitTimeModalID = this.modalService.newModal();
        this.modalService.showModalInfo(
            {
                modalID: this.$scope.transitTimeModalID,
                scope: this.$newProcessScope,
                formService: operation,
                template: require("../view/modal/processTransitTimeDetailModal.html"),
                size: 'lg'
            },
            {
                headerText: "GENERAL.TRANSIT_TIME"
            }
        );
        await this.buildTransitTimeDetailModalScope(service, serviceOffer, operation);
        this.$scope.$applyAsync();
    }

    private async buildTransitTimeDetailModalScope(service: INewProcessEventService, serviceOffer: IOfferService[], operation: string) {
        try {
            const modalScope = await this.modalService.getModalScope(this.$scope.transitTimeModalID);
            modalScope.operation = operation;

            const productId = this.$newProcessScope.model.PRODUCT ? this.$newProcessScope.model.PRODUCT.ID : null;
            modalScope.isAirService = (productId == EProductId.AIR_EXPORT || productId == EProductId.AIR_IMPORT);
            modalScope.isMaritimeService = (productId == EProductId.MARITIME_EXPORT || productId == EProductId.MARITIME_IMPORT);
            modalScope.isRoadService = (productId == EProductId.ROAD_EXPORT || productId == EProductId.ROAD_IMPORT || productId == EProductId.ROAD_NATIONAL);

            if (service && serviceOffer) {
                const activeServiceOffer = serviceOffer.find(sv => sv.ACTIVE === true);
                modalScope.service = angular.copy(service);
                modalScope.serviceOffer = angular.copy(activeServiceOffer);
                modalScope.ttime_real = this.calculateTransitTimeRealTotal(service);
            }

            modalScope.closeTransitTimeDetailModal = async (): Promise<void> => {
                this.modalService.closeModal(this.$scope.transitTimeModalID);
                this.$scope.transitTimeModalID = 0;
                $('.app-content-body').animate({ scrollTop: this.$newProcessScope.lastScroll }, 0);
            }

            return modalScope;
        } catch (error) {
            this.formService.handleError(error);
        }
    }

    private calculateTransitTimeRealTotal(service: INewProcessEventService): number {
        let total = null;
        if (service && service.TRANSIT_TIMES && service.TRANSIT_TIMES.length > 0) {
            for (const transitTime of service.TRANSIT_TIMES) {
                total += transitTime.TTIME_REAL
            }
        }
        this.$scope.model.TTIME_REAL = total;
        return total;
    }

}
import { HandleError } from "../../common/util/HandleError";

interface IDataManagerMenuScope extends ng.IScope {
    goTo: (route: string) => void;
}

export class DataManagerMenuController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IDataManagerMenuScope;
    private $state: ng.ui.IStateService;

    constructor($injector: ng.Injectable<any>, $scope: IDataManagerMenuScope) {
        this.$scope = $scope;
        this.$state = $injector.get('$state');
        this.initScopeFunctions();
    }

    async $onInit(): Promise<void> {
        try {
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    $onDestroy(): void {
    }

    initScopeFunctions(): void {
        this.$scope.goTo = (route: string) => {
            this.goTo(route);
        }
    }

    private goTo(route: string): void {
        this.$state.go(route, { reload: true });
    }
}


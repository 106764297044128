import { element } from 'angular';
import { TransitionService, StateService, Transition } from '@uirouter/angularjs';
import { IMonacoScope } from '../../common/MonacoInterface';
import { NotificationConstants } from '../../common/util/NotificationConstants';
import config from '../Config';
import { BrowserTitle } from '../../common/BrowserTitle';
import { NotificationBox, NotificationBoxItem } from '../../common/NotificationBox';
import { ISessionService } from '@services/SessionService';
import { INotificationService } from '@services/NotificationService';
import { ExternalService } from '@services/ExternalService';
import { IMonacoRequest } from '@services/GridFormService';
import { NotificationMailBox } from '@models/interface/integration/NotificationMailBox';

export interface IBootstrapControllerScope extends ng.IScope {
    rawNotifications: NotificationMailBox[];
    notificationBoxItemList: NotificationBoxItem[];
    viewReadNotifications: boolean;
    openNotificationBoxLink: (notification: NotificationBoxItem) => void;
    markAllNotificationAsRead: () => Promise<void>;
    refreshNotificationBox: () => void;
    changeViewReadNotifications: () => void;
    goTo: (route) => void;
}

export class BootstrapController {
    public static $inject: string[] = ["$injector", "$scope", /*"$translatePartialLoader",*/ "$translate"];
    public static BootstrapScope: IBootstrapControllerScope = null;
    private $injector: ng.Injectable<any>;
    private $window: ng.IWindowService;
    private $rootScope: IMonacoScope;
    private $transitions: TransitionService;
    private externalService: ExternalService;
    private $templateCache: ng.ITemplateCacheService;
    private notificationService: INotificationService;
    private $state: StateService;
    private $scope: IBootstrapControllerScope;
    private blockUI: ng.blockUI.BlockUIService;
    private sessionService: ISessionService;
    //private $translatePartialLoader;
    private $translate;

    constructor($injector: ng.Injectable<any>, $scope: IBootstrapControllerScope/*, $translatePartialLoader*/, $translate) {
        this.$injector = $injector;
        this.$scope = $scope;
        this.$scope.viewReadNotifications = false;
        //this.$translatePartialLoader = $translatePartialLoader;
        this.$translate = $translate;

        BootstrapController.BootstrapScope = this.$scope;

        this.$window = $injector.get('$window');
        this.$rootScope = $injector.get('$rootScope');
        this.$transitions = $injector.get('$transitions');
        this.$templateCache = $injector.get('$templateCache');
        this.externalService = $injector.get('ExternalService');
        this.notificationService = $injector.get('NotificationService');
        this.$state = $injector.get('$state');
        this.blockUI = $injector.get('blockUI');
        this.sessionService = $injector.get('SessionService');

        this.$rootScope.environment = config.environment;
        this.$rootScope.version = config.version;
        this.$rootScope.isIE = false;
        this.$rootScope.isSmartDevice = false;
        this.$rootScope.hasServiceWorker = false;

        BrowserTitle.$rootScope = this.$rootScope;

        this.init();
        this.configTransitions();
        this.initScopeFunctions();
    }

    private isSmartDevice() {
        // Adapted from http://www.detectmobilebrowsers.com
        const ua = this.$window['navigator']['userAgent'] || this.$window['navigator']['vendor'] || this.$window['opera'];
        if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(ua) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(ua.substr(0, 4))) return true;
        return false;
    }

    private async init(): Promise<void> {
        BrowserTitle.$menu = config.name;

        console.log("Navigator: ", navigator.userAgent);

        this.$rootScope.$on('$translatePartialLoaderStructureChanged', () => { this.$translate.refresh(); });

        // add 'ie' classes to html
        const isIE = (!!navigator.userAgent.match(/MSIE/i)) || (navigator.userAgent.indexOf('Trident/') !== -1);
        if (isIE) {
            element(this.$window.document.body).addClass('ie');
            this.$rootScope.isIE = true;
            console.log('IE USER');
        }
        // add 'smart' classes to html
        if (this.isSmartDevice()) {
            element(this.$window.document.body).addClass('smart');
            this.$rootScope.isSmartDevice = true;
            console.log('SMART DEVICE USER');
        }
        // check service worker
        if ('serviceWorker' in navigator) {
            this.$rootScope.hasServiceWorker = true;
            console.log('SERVICE WORKER AVAILABLE');
        }
    }

    private async markNotificationAsRead(notification: NotificationBoxItem): Promise<void> {
        try {
            const notificationMailBox: NotificationMailBox = this.$scope.rawNotifications.find((n) => n._id === notification.id);
            if (notificationMailBox == null) throw '';

            notificationMailBox.READ = true;
            const externalReq: IMonacoRequest = {
                route: `/notificationMailBox/update`,
                data: { data: [notificationMailBox] },
                timeout: 120000
            }

            const result = await this.externalService.post<any>(externalReq);

            if (!result || !result.data) throw '';
        } catch (e) {
            this.notificationService.warning('There was an error when trying to mark notification as read.');
        }
    }

    private initScopeFunctions() {
        this.$scope.openNotificationBoxLink = async (notification: NotificationBoxItem) => {
            let { link, filter } = notification;

            if (!link) return;

            filter = (filter) ? filter : null;

            this.sessionService.openTab(link, filter);


            this.$scope.goTo = function (route) {
                this.$state.go(route, { reload: true });
            };

            await this.markNotificationAsRead(notification);

            await NotificationBox.Instance(this.$injector).init(BootstrapController.BootstrapScope);
            await this.$scope.$applyAsync();
        }

        this.$scope.markAllNotificationAsRead = async () => {
            await this.markAllNotificationAsRead();
            this.refreshNotificationBox();
        }

        this.$scope.goTo = (route) => {
            this.sessionService.openRoute(route);
        }

        this.$scope.refreshNotificationBox = () => {
            this.refreshNotificationBox();
        }

        this.$scope.changeViewReadNotifications = () => {
            this.$scope.viewReadNotifications = !this.$scope.viewReadNotifications;
            this.refreshNotificationBox();
        }
    }

    private async markAllNotificationAsRead(): Promise<void> {
        try {
            const notificationsToMarkRead: NotificationMailBox[] = this.$scope.rawNotifications.filter(notification => !notification.READ);
            if (notificationsToMarkRead == null) throw '';

            notificationsToMarkRead.forEach((notification) => { notification.READ = true });
            const externalReq: IMonacoRequest = {
                route: `/notificationMailBox/update`,
                data: { data: notificationsToMarkRead },
                timeout: 120000
            }

            const result = await this.externalService.post<any>(externalReq);

            if (!result || !result.data) throw '';
        } catch (e) {
            this.notificationService.warning('There was an error when trying to mark notifications as read.');
        }
    }

    private async refreshNotificationBox(): Promise<void> {
        await NotificationBox.Instance(this.$injector).init(BootstrapController.BootstrapScope);
        await this.$scope.$applyAsync();
    }

    private configTransitions(): void {
        const self: BootstrapController = this;

        //prevent transition to abstract states
        this.$transitions.onBefore({}, function (transition: Transition) {
            //self.$rootScope['currentTransition'] = transition;
            const from = transition.from();
            const to = transition.to();

            //block transition to abstract states
            if (to.abstract) {
                return false;
            }
            //block denied to denied and error to error states
            if ((from.name === 'app.denied' && to.name === 'app.denied') || (from.name === 'app.error' && to.name === 'app.error')) {
                return false;
            }

            //block denied to error states
            if (from.name === 'app.denied' && to.name === 'app.error') {
                return false;
                //return this.$state.target('app.denied', transition.targetState().params());
            }
            /*             //block states on the array to be accessed directly
                        if ((to.name === 'error' || to.name === 'pfcl01') && !from.name) {
                            return this.$state.target("app.signin");
                        } */

            //block external state transition to internal states
            if (from.name.startsWith('app.external') && !to.name.startsWith('app.external') && to.name !== 'app.error' && to.name !== 'app.invalid' && to.name !== 'app.denied' && to.name !== 'app.signin') {
                return false;
            }

            //smartlock logic
            if (from.name === "app.registration.cellParameter") {
                if (self.$rootScope.change) {
                    const result = confirm(NotificationConstants.CHANGE_ROUTE);
                    if (!result) return false;

                    self.$rootScope.change = false;
                }
            }

        });

        //start block on transition start
        this.$transitions.onStart({}, (transition: Transition) => {
            self.blockUI.start();
        });

        //stop block on transition finish
        this.$transitions.onFinish({}, function (transition: Transition) {
            self.blockUI.stop();
        });

        //save the current State
        this.$transitions.onSuccess({}, (transition: Transition) => {
            const current = transition.to();

            self.$rootScope.lastState = transition.from().name;
            self.$rootScope.currentState = current.name;

            //clear route templateUrl cache
            if (current.views) {
                const views = Object.keys(current.views);
                for (let i = 0; i < views.length; i++) {
                    if (current.views[views[i]]['templateUrl']) {
                        //console.log('template cache info', self.$templateCache.get(current.views[views[i]]['templateUrl']));
                        console.log('cleaning template cache', current.views[views[i]]['templateUrl']);
                        self.$templateCache.remove(current.views[views[i]]['templateUrl']);
                    }
                }
            }
        });

        //handle transition errors
        this.$transitions.onError({}, function (transition: Transition) {
            //error type 2 = Superseded
            //error type 3 = Aborted

            const error = transition.error();

            if (error.type === 2) {
                if (transition.targetState().identifier() === 'app.denied') {
                    console.log('Denied state superseded');
                    //return self.$state.target('app.denied', transition.targetState().params());
                }
            } else if (transition._aborted || error.type === 3) {
                console.log('Transition Error: ', error.message);
            }
            // Unblock the user interface
            self.blockUI.stop();
        });

        /* Solução de carregar os termos por módulo
        this.$transitions.onEnter({}, (transition, $state) => { this.enterTransitionHook($state); });
        */

    }

    /* Solução de carregar os termos por módulo
    private enterTransitionHook($state): boolean {
        try {
            if ($state.abstract && $state.data && $state.data.i18n) {
                for (const value of $state.data.i18n) {
                    this.$translatePartialLoader.addPart(value);
                };
            }
        } catch (ex) {
            return false;
        }
    }
    */
}
import * as angular from 'angular';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from '@services/GridFormService';
import { IRestService } from '@services/RestService';
import { ExternalService } from "@services/ExternalService";
import { ProductService } from "@services/ProductService";
import { IMonacoColumnDef } from '@services/GridService2';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { ITop } from '@models/interface/finop/ErpTopSetup';
import { IErpTopSetup, IPeopleSelector } from '../model/ErpTopSetupModel';
import { GridColumnBuilder, GridColumnBuilderConstants } from '../../common/GridColumnBuilder';
import { SelectorModel } from '../../common/model/SelectorModel';
import { BrowserTitle } from "../../common/BrowserTitle";
import { ILegalPersonListCustomFilter, ILegalPersonSelector } from "../../registration/model/LegalPersonModel";
import { IPhysicalPersonListCustomFilter } from '../../registration/model/PhysicalPersonModel';
import { HelperService } from "@services/HelperService";

interface IErpTopSetupScope extends IGridFormServiceScope {
    model: IErpTopSetup;
    log: IViewLog;
    paymentNatureList: SelectorModel[];
    chargeNameList: SelectorModel[];
    erpTopBillingList: ITop[];
    erpTopPurchaseList: ITop[];
    corporateBranchList: SelectorModel[];
    peopleList: IPeopleSelector[];
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IErpTopSetup;
    getErpTopPurchaseListByName: (search: string) => Promise<void>;
    getErpTopBillingListByName: (search: string) => Promise<void>;
    getChargeNameListByName: (search: string) => Promise<void>;
    getCorporateBranchListByName: (search: string) => Promise<void>;
    getPeopleListByName: (search: string) => Promise<void>;
}

export class ErpTopSetupRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IErpTopSetupScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private ExternalService: ExternalService;
    private ProductService: ProductService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IErpTopSetupScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.ExternalService = $injector.get('ExternalService');
        this.ProductService = $injector.get('ProductService');
        this.helperService = $injector.get('HelperService'); 
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.config.fmsUrl + '/fms';
            this.$scope.customLogProperties = this.getCustomLogProperties();

            this.initForm(this, 'form', 'erpTopSetup', 'GENERAL.MENU.ERP_TOP', true);
            await this.initGrid('gridErpTopSetup', '/erpTopSetup/list', true, true, null, true, true);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initScopeFunctions(): void {

        this.$scope.getChargeNameListByName = async (search: string) => {
            let chargeNameList: SelectorModel[] = [];
            if (search && search.length >= 3) chargeNameList = await this.getChargeNameListByName(search);
            this.$scope.chargeNameList = chargeNameList;
        }

        this.$scope.getPeopleListByName = async (search: string) => {
            let peopleList = [];
            if (search && search.length >= 3) {
                const legalPersonList = await this.getLegalPersonListByName({ specializations: [], search: search })
                if (legalPersonList && legalPersonList.length) peopleList = peopleList.concat(legalPersonList.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.NAME, ID_LEGAL_PERSON: legalPerson.ID, ID_PHYSICAL_PERSON: null } }));
                const physicalPersonList = await this.getPhysicalPersonListByName({ specializations: [], roles: [], search: search });
                if (physicalPersonList && physicalPersonList.length) peopleList = peopleList.concat(physicalPersonList.map(physicalPerson => { return { ID: physicalPerson.ID, NAME: physicalPerson.NAME, ID_LEGAL_PERSON: null, ID_PHYSICAL_PERSON: physicalPerson.ID } }));
            }
            this.$scope.peopleList = peopleList;
        }


        this.$scope.getCorporateBranchListByName = async (search: string) => {
            let corporateBranchList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                corporateBranchList = await this.getCorporateBranchListByName(search);
            }
            this.$scope.corporateBranchList = corporateBranchList;
        }

        this.$scope.getErpTopPurchaseListByName = async (search: string) => {
            let erpTopPurchaseList: ITop[] = [];
            if (search && search.length >= 2) erpTopPurchaseList = await this.getErpTopPurchaseListByName(search);
            this.$scope.erpTopPurchaseList = erpTopPurchaseList;
        }

        this.$scope.getErpTopBillingListByName = async (search: string) => {
            let erpTopBillingList: ITop[] = [];
            if (search && search.length >= 2) erpTopBillingList = await this.getErpTopBillingListByName(search);
            this.$scope.erpTopBillingList = erpTopBillingList;
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        gridColumns.includeActionBar([
            GridColumnBuilderConstants.BTN_VIEW,
            GridColumnBuilderConstants.BTN_EDIT,
            GridColumnBuilderConstants.BTN_COPY,
            GridColumnBuilderConstants.BTN_VIEWLOG
        ], 25);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colPaymentNature: IMonacoColumnDef = { name: 'PAYMENT_NATURE.NAME', displayName: 'REGISTRATION.TRANSACTION', width: 200 };
            const colChargeName: IMonacoColumnDef = { name: 'CHARGE_NAME.NAME', displayName: 'BASIC_DATA.CHARGE', width: 300, cellTemplate: "<div class='grid-padding' >{{grid.appScope.getCONCAT(row.entity.CHARGE_NAME)}}</div>" };
            const colPeople: IMonacoColumnDef = { name: 'PEOPLE.NAME', displayName: 'REGISTRATION.PERSON', width: 300, cellTemplate: "<div class='grid-padding' >{{grid.appScope.getCONCAT(row.entity.PEOPLE)}}</div>" };
            const colCorporateBranch: IMonacoColumnDef = { name: 'CORPORATE_BRANCH.NAME', displayName: 'BASIC_DATA.BRANCH', width: 300, cellTemplate: "<div class='grid-padding' >{{grid.appScope.getCONCAT(row.entity.CORPORATE_BRANCH)}}</div>" };
            const colErpTopPurchase: IMonacoColumnDef = { name: 'ERP_TOP_PURCHASE.NAME', displayName: 'FINANCIAL.ORDER', width: 200 };
            const colErpTopBilling: IMonacoColumnDef = { name: 'ERP_TOP_BILLING.NAME', displayName: 'FINANCIAL.BILLING', width: 200 };
            const colErpTopBillingRetention: IMonacoColumnDef = { name: 'ERP_TOP_BILLING_RETENTION.NAME', displayName: 'FINANCIAL.BILLING_TAX_RETENTION', width: 200 };
            const colErpTopBillingRetentionIss: IMonacoColumnDef = { name: 'ERP_TOP_BILLING_RETENTION_ISS.NAME', displayName: 'FINANCIAL.BILLING_TAX_ISS_RETENTION', width: 200 };
            const colErpTopSerie: IMonacoColumnDef = { name: 'ERP_TOP_SERIE', displayName: 'FINANCIAL.SERIAL', width: 150 };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 150, cellFilter: "YesOrNo" };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'PAYMENT_NATURE':
                        columnDefs.push(colPaymentNature);
                        break;
                    case 'CHARGE_NAME':
                        columnDefs.push(colChargeName);
                        break;
                    case 'PEOPLE':
                        columnDefs.push(colPeople);
                        break;
                    case 'CORPORATE_BRANCH':
                        columnDefs.push(colCorporateBranch);
                        break;
                    case 'ERP_TOP_PURCHASE':
                        columnDefs.push(colErpTopPurchase);
                        break;
                    case 'ERP_TOP_BILLING':
                        columnDefs.push(colErpTopBilling);
                        break;
                    case 'ERP_TOP_BILLING_RETENTION':
                        columnDefs.push(colErpTopBillingRetention);
                        break;
                    case 'ERP_TOP_BILLING_RETENTION_ISS':
                        columnDefs.push(colErpTopBillingRetentionIss);
                        break;
                    case 'ERP_TOP_SERIE':
                        columnDefs.push(colErpTopSerie);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            PAYMENT_NATURE: null,
            CHARGE_NAME: null,
            CORPORATE_BRANCH: null,
            PEOPLE: null,
            ERP_TOP_PURCHASE: null,
            ERP_TOP_BILLING: null,
            ERP_TOP_BILLING_RETENTION: null,
            ERP_TOP_BILLING_RETENTION_ISS: null,
            ERP_SERIE: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            ACTIVE: true
        };
    }

    initDependencies(): Promise<any> {
        const self: ErpTopSetupRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericList('payment_nature'),
            ]).then((result: any) => {
                self.$scope.paymentNatureList = result[0],
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getGenericList(type: string, alternative: boolean = false): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}/${alternative}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async getChargeNameListByName(search?: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const charges = await this.ProductService.post({ route: "/chargeName/list/custom", data: { search: search, paramTypeCargo: [], products: [] }});
            if (charges && charges.data && charges.data.data) {
                result = charges.data.data.map(charge => { 
                    return { 
                        ID: charge.ID, 
                        NAME: charge.NAME, 
                        CODE: charge.CODE,
                        PRODUCT: charge.PRODUCT.map(x => x.ID).join(';')
                    } 
                });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getErpTopPurchaseListByName(search: string): Promise<ITop[]> {
        let result = [];
        this.formService.block();
        try {
            const purchases = await this.ExternalService.post({ route: "/sankhya/top/purchase", data: { search: search } });
            if (purchases && purchases.data) {
                result = purchases.data.data
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getErpTopBillingListByName(search: string): Promise<ITop[]> {
        let result = [];
        this.formService.block();
        try {
            const billings = await this.ExternalService.post({ route: "/sankhya/top/billing", data: { search: search } });
            if (billings && billings.data) {
                result = billings.data.data
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCorporateBranchListByName(search?: string): Promise<ILegalPersonSelector[]> {
        let result = [];
        this.formService.block();
        try {
            const corporateBranchs = await this.ProductService.post({ route: `/corporateBranch/list/custom`, data: { search: search } });
            if (corporateBranchs && corporateBranchs.data && corporateBranchs.data.data) result = corporateBranchs.data.data.map(corporateBranch => { return { ID: corporateBranch.ID, NAME: corporateBranch.NAME, CODE: corporateBranch.CODE } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getLegalPersonListByName(legalPersonFilter: ILegalPersonListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        try {
            this.formService.block();
            const legalPersons = await this.ProductService.post({route: "/legalPerson/list/custom", data: legalPersonFilter});
            if (legalPersons && legalPersons.data && legalPersons.data.data) result = legalPersons.data.data.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getPhysicalPersonListByName(physicalPersonFilter?: IPhysicalPersonListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const physicalPersons = await this.ProductService.post({route: "/physicalPerson/list/custom", data: physicalPersonFilter });
            if (physicalPersons && physicalPersons.data && physicalPersons.data.data) result = physicalPersons.data.data.map(physicalPerson => { return { ID: physicalPerson.ID, NAME: physicalPerson.NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
            BrowserTitle.$id = '';
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = '';
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === 'register' ? 'insert' : 'update';
        return {
            route: `/erpTopSetup/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };

    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'PAYMENT_NATURE',
                LABEL: 'REGISTRATION.TRANSACTION'
            },
            {
                PROPERTY: 'CHARGE_NAME',
                LABEL: 'BASIC_DATA.CHARGE'
            },
            {
                PROPERTY: 'PEOPLE',
                LABEL: 'REGISTRATION.PERSON'
            },
            {
                PROPERTY: 'CORPORATE_BRANCH',
                LABEL: 'BASIC_DATA.BRANCH'
            },
            {
                PROPERTY: 'ERP_TOP_PURCHASE',
                LABEL: 'FINANCIAL.ORDER'
            },
            {
                PROPERTY: 'ERP_TOP_BILLING',
                LABEL: 'FINANCIAL.BILLING'
            },
            {
                PROPERTY: 'ERP_TOP_BILLING_RETENTION',
                LABEL: 'FINANCIAL.BILLING_TAX_RETENTION'
            },
            {
                PROPERTY: 'ERP_TOP_BILLING_RETENTION_ISS',
                LABEL: 'FINANCIAL.BILLING_TAX_ISS_RETENTION'
            },
            {
                PROPERTY: 'ERP_TOP_SERIE',
                LABEL: 'FINANCIAL.SERIAL'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'NUM_AUTOMATIC',
                LABEL: 'REGISTRATION.AUTO_NUMBER'
            },
            {
                PROPERTY: 'ERP_SERIE',
                LABEL: 'FINANCIAL.SERIAL'
            },
            {
                PROPERTY: 'PRODUCT',
                LABEL: 'BASIC_DATA.PRODUCT'
            }
        ];
        return props;
    }
}
import angular = require('angular');
import { FormService2 } from '@services/FormService2';
import { ISessionService } from '@services/SessionService';
import { OperationalService } from '@services/OperationalService';
import { IViewLog } from "@models/interface/common/IViewLog";
import { INewProcessChargeModel } from '@models/interface/operational/NewProcessTabsModel';
import { OUTCOME, INCOME } from '@models/interface/product/OfferCompiled';
import { EApplicationComplementId, EIntegrateDirection, EOperation, EProductId } from '@enums/GenericData';
import { INewProcessScope, ECollapseState } from './NewProcessController';
import { ITableOptions } from '../../app/directives/monaco-data-table';
import { IChargeParameter } from '../../common/model/ModelParameter';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { ISelectorModel } from '@models/mongo/SelectorModel';
import { IDocumentError } from '@models/interface/common/IDocumentError';
import { DataOperationalService } from '@services/DataOperationalService';
import { IFinopService } from '@services/FinopService';
import { IMonacoRequest } from '@services/GridFormService';

export interface ISankhyaIntegration {
    ERP_RESULT_CENTER: ISelectorModel;
    DOCUMENT_ERROR: IDocumentError;
    INTEGRATION_DIRECTION: ISelectorModel;
}

interface INewProcessChargeScope extends ng.IScope {
    log: IViewLog;
    model: INewProcessChargeModel;
    sankhyaIntegrationModel: ISankhyaIntegration;
    chargesTableOptions: ITableOptions;
    collapseCharge: () => void;
    goToCharge: (chargeName: string) => void;
    openChargesPerWeightRangeModal: () => void;
    isSankhya: () => boolean;
    erpResultCenter: () => string;
    buildErrorSankhyaTooltip: (errors: IDocumentError[]) => string;
    integrateSankhya: () => void;
    updateChargeCache:() => void;
}

export class NewProcessChargeController {
    static $inject: string[] = ['$scope'];
    private $scope: INewProcessChargeScope;
    private $newProcessScope: INewProcessScope;
    private $filter: ng.FilterFactory;
    private $compile: angular.ICompileService;
    private formService: FormService2;
    private sessionService: ISessionService;
    private operationalService: OperationalService;
    private dataOperationalService: DataOperationalService;
    private finopService: IFinopService;

    constructor($scope: INewProcessChargeScope) {
        this.$scope = $scope;
        this.$newProcessScope = <INewProcessScope>$scope.$parent.$parent;
        this.$filter = this.$newProcessScope.$filter;
        this.$compile = this.$newProcessScope.$compile;
        this.formService = this.$newProcessScope.formService;
        this.sessionService = this.$newProcessScope.sessionService;
        this.operationalService = this.$newProcessScope.operationalService;
        this.dataOperationalService = this.$newProcessScope.dataOperationalService;
        this.finopService = this.$newProcessScope.finopService;
        this.initScopeFunctions();
    }

    async $onInit(): Promise<void> {
        try {
            this.$scope.chargesTableOptions = this.getChargesTableOptions();
            this.initModel();
            await this.initDependencies();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initModel(): void {
        this.$scope.model = {
            CHARGE: null,
            TOTAL_CHARGES: null
        };

        this.$scope.sankhyaIntegrationModel = {
            DOCUMENT_ERROR: this.$newProcessScope.model.DOCUMENT_ERROR,
            ERP_RESULT_CENTER: this.$newProcessScope.model.ERP_RESULT_CENTER,
            INTEGRATION_DIRECTION: this.$newProcessScope.model.INTEGRATION_DIRECTION
        }
    }

    private initScopeFunctions(): void {

        this.$scope.collapseCharge = (): void => {
            this.collapseCharge();
        }

        this.$scope.goToCharge = (chargeName: string): void => {
            const tabParameter: IChargeParameter = { PROCESS_NUMBER: this.$newProcessScope.model.PROCESS_NUMBER, 'CHARGE.NAME': chargeName, 'INVOICE_NUMBER.NAME': null }
            this.sessionService.openTab('app.finop.charge.register', tabParameter);
        }

        this.$scope.openChargesPerWeightRangeModal = () => {
            this.openChargesPerWeightRangeModal();
        }

        this.$scope.isSankhya = (): boolean => {
            return (this.$newProcessScope.model.INTEGRATION_DIRECTION && this.$newProcessScope.model.INTEGRATION_DIRECTION.ID == '1');
        }

        this.$scope.erpResultCenter = (): string => {
            return this.$newProcessScope.model.ERP_RESULT_CENTER ? this.$newProcessScope.model.ERP_RESULT_CENTER.ID : null;
        }

        this.$scope.buildErrorSankhyaTooltip = (errors: IDocumentError[]) => {
            return this.buildErrorSankhyaTooltip(errors);
        }

        this.$scope.integrateSankhya = () => {
            this.integrateSankhya();
        }

        this.$scope.updateChargeCache = () => {
            this.updateChargeCache();
        }

    }

    async initDependencies(): Promise<any> {
        this.initCollapseEvents();
    }

    private initCollapseEvents() {
        this.$scope.$on('processChargeCollapse', () => {
            this.collapseCharge();
            this.getSankhyaIntegrationByProcess();
        });

        const collapseCharge = angular.element('#collapseCharges');
        if (collapseCharge) {
            collapseCharge.on('shown.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    await this.getProcessTabsCharge();
                    angular.element("#collapseChargeHeading").attr('aria-expanded', 'true');
                    // update collapse state
                    this.$newProcessScope.collapseState = { panel: ECollapseState.CHARGES, released: false, nextState: null };
                    this.$newProcessScope.repositionPanels(ECollapseState.CHARGES, true);
                    this.$newProcessScope.disableElements(this.$newProcessScope.operation == EOperation.VIEW);
                }
            });
            collapseCharge.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseChargeHeading").attr('aria-expanded', 'false');
                }
            });
        }
    }

    private async openChargesPerWeightRangeModal(): Promise<void> {
        try {
            const modalID = this.$newProcessScope.modalService.newModal();
            const modalInstance: IModalInstanceService = await this.$newProcessScope.modalService.showChargesPerWeightRangeModal({ modalID: modalID, processId: this.$newProcessScope.model.ID, isViewMode: true, actionButtonClass: 'btn-default' });

            const apply: boolean = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (apply) await this.getProcessTabsCharge();
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private getChargesTableOptions(): ITableOptions {
        return {
            showFooter: true,
            persistName: "newProcessCharges",
            pagination: false,
            search: true,
            advancedSearch: false,
            showSearchClearButton: true,
            clickToSelect: true,
            singleSelect: true,
            showLoading: true,
            showColumns: true,
            showColumnsSearch: true,
            showColumnsToggleAll: true,
            showFullscreen: true,
            showToggle: false,
            showMultiSort: false,
            showMultiSortButton: false,
            fixedColumns: true,
            fixedNumber: 1,
            filterControl: false,
            crudButtons: null,
            showColumnsRename: [
                { dataField: "CHARGE", title: this.formService.getTranslate("BASIC_DATA.CHARGE") },
                { dataField: "OUTCOME.CHARGE_EXHIBITION", title: this.formService.getTranslate("BASIC_DATA.DISPLAY_CHARGE") },
                { dataField: "OUTCOME.CHARGE_EXHIBITION.NAME", title: this.formService.getTranslate("FINANCIAL.CHARGE_DISPLAY_NAME_C") },
                { dataField: "OUTCOME", title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS_C") },
                { dataField: "OUTCOME.AMOUNT", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.QUANTITY_C") },
                { dataField: "OUTCOME.CURRENCY", title: this.formService.getTranslate("GENERAL.CURRENCY_C") },
                { dataField: "OUTCOME.UNITARY", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY_C") },
                { dataField: "OUTCOME.MINIMUM", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM_C") },
                { dataField: "OUTCOME.TOTAL", title: this.formService.getTranslate("GENERAL.TOTAL_C") },
                { dataField: "OUTCOME.CONVERSION_SPREAD", title: this.formService.getTranslate("BASIC_DATA.EXCHANGE_RATE_C") },
                { dataField: "OUTCOME.TOTAL_BRL", title: this.formService.getTranslate("FINANCIAL.TOTAL_BRL_C") },
                { dataField: "OUTCOME.INVOICE_NUMBER", title: this.formService.getTranslate("FINANCIAL.TOTAL_BRL_C") },
                { dataField: "OUTCOME.TRANSACTION", title: this.formService.getTranslate("GENERAL.HOLDER_TYPE_C") },
                { dataField: "OUTCOME.PEOPLE", title: this.formService.getTranslate("GENERAL.HOLDER_C") },
                { dataField: "OUTCOME.MODALITY", title: this.formService.getTranslate("FINANCIAL.CHARGE_PAYMENT_METHOD_C") },
                { dataField: "OUTCOME.VALIDITY_OF", title: this.formService.getTranslate("PRODUCT.VALIDITY_START_C") },
                { dataField: "OUTCOME.VALIDITY_UP", title: this.formService.getTranslate("PRODUCT.VALIDITY_END_C") },
                { dataField: "OUTCOME.SHOW_FOR_GROUP", title: this.formService.getTranslate("FINANCIAL.SHOWN_FOR_C") },
                { dataField: "OUTCOME.COMPOSITION_GROUP", title: this.formService.getTranslate("GENERAL.COMPOSITION_C") },
                { dataField: "OUTCOME.OBSERVATION", title: this.formService.getTranslate("GENERAL.REMARKS_C") },
                { dataField: "OUTCOME.CHARGE_ORIGIN.NAME", title: this.formService.getTranslate("BASIC_DATA.ORIGIN_C") },
                { dataField: "INCOME.CHARGE_EXHIBITION", title: this.formService.getTranslate("BASIC_DATA.DISPLAY_CHARGE") },
                { dataField: "INCOME.CHARGE_EXHIBITION.NAME", title: this.formService.getTranslate("FINANCIAL.CHARGE_DISPLAY_NAME_V") },
                { dataField: "INCOME", title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS_V") },
                { dataField: "INCOME.AMOUNT", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.QUANTITY_V") },
                { dataField: "INCOME.CURRENCY", title: this.formService.getTranslate("GENERAL.CURRENCY_V") },
                { dataField: "INCOME.UNITARY", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY_V") },
                { dataField: "INCOME.TOTAL", title: this.formService.getTranslate("FINANCIAL.TOTAL_BRL_V") },
                { dataField: "INCOME.CONVERSION_SPREAD", title: this.formService.getTranslate("BASIC_DATA.EXCHANGE_RATE_V") },
                { dataField: "INCOME.TOTAL_BRL", title: this.formService.getTranslate("FINANCIAL.TOTAL_BRL_V") },
                { dataField: "INCOME.INVOICE_NUMBER", title: this.formService.getTranslate("GENERAL.INVOICES_V") },
                { dataField: "INCOME.TRANSACTION", title: this.formService.getTranslate("GENERAL.HOLDER_TYPE_V") },
                { dataField: "INCOME.PEOPLE", title: this.formService.getTranslate("GENERAL.HOLDER_V") },
                { dataField: "INCOME.MODALITY", title: this.formService.getTranslate("FINANCIAL.CHARGE_PAYMENT_METHOD_V") },
                { dataField: "INCOME.VALIDITY_OF", title: this.formService.getTranslate("PRODUCT.VALIDITY_START_V") },
                { dataField: "INCOME.VALIDITY_UP", title: this.formService.getTranslate("PRODUCT.VALIDITY_END_V") },
                { dataField: "INCOME.SHOW_FOR_GROUP", title: this.formService.getTranslate("FINANCIAL.SHOWN_FOR_V") },
                { dataField: "INCOME.COMPOSITION_GROUP", title: this.formService.getTranslate("GENERAL.COMPOSITION_V") },
                { dataField: "INCOME.OBSERVATION", title: this.formService.getTranslate("GENERAL.REMARKS_V") },
                { dataField: "INCOME.CHARGE_ORIGIN.NAME", title: this.formService.getTranslate("BASIC_DATA.ORIGIN_V") },
            ],
            headerStyle: (column: BootstrapTableColumn) => {
                let headerStyleObj = {};
                if (column.field.search("OUTCOME") >= 0) headerStyleObj = { css: { 'background': "#f2dede" } };
                else if (column.field.search("INCOME") >= 0) headerStyleObj = { css: { 'background': "#dff0d8" } };
                return headerStyleObj;
            },
            onPostBody: () => {
                this.$compile(angular.element("#chargesTable"))(this.$scope);
                return true;
            },
            columns: [
                { field: 'CHARGE', footerFormatter: this.formService.getTranslate("GENERAL.TOTAL"), title: this.formService.getTranslate("BASIC_DATA.CHARGE"), formatter: (data) => { return `<span style="max-width: 100%" class="ellipsize text-u-l cursor-hand" ellipsis-tooltip tooltip-placement="auto top" tooltip-enable="true" tooltip-append-to-body="true" uib-tooltip="${data ? data.NAME : data}" ng-click="goToCharge('${data ? data.NAME : data}')">${data ? data.NAME : data}</span>` }, sortable: true, searchable: true, cellStyle: () => { return { css: { 'max-width': '200px' } } } },
                { field: 'OUTCOME.CHARGE_EXHIBITION', title: this.formService.getTranslate("FINANCIAL.CHARGE_CODE"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'OUTCOME.CHARGE_EXHIBITION.NAME', title: this.formService.getTranslate("FINANCIAL.CHARGE_DISPLAY_NAME"), sortable: true, searchable: true, visible: false },
                { field: 'OUTCOME', title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS"), formatter: (data: OUTCOME) => { return `<span style="max-width: 100%" class="ellipsize" ellipsis-tooltip tooltip-placement="auto top" tooltip-enable="true" tooltip-append-to-body="true" uib-tooltip="${this.chargeTableApplicationFormatter(data)}">${this.chargeTableApplicationFormatter(data)}</span>` }, sortable: true, searchable: true, cellStyle: () => { return { css: { 'max-width': '200px' } } } },
                { field: 'OUTCOME.AMOUNT', title: this.formService.getTranslate("GENERAL.QUANTITY"), sortable: true, searchable: true },
                { field: 'OUTCOME.CURRENCY', title: this.formService.getTranslate("GENERAL.CURRENCY"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'OUTCOME.UNITARY', title: this.formService.getTranslate("BASIC_DATA.UNIT"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'OUTCOME.MINIMUM', title: this.formService.getTranslate("BASIC_DATA.MINIMUM"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'OUTCOME.TOTAL', title: this.formService.getTranslate("GENERAL.TOTAL"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'OUTCOME.CONVERSION_SPREAD', title: this.formService.getTranslate("BASIC_DATA.EXCHANGE_RATE"), formatter: (data) => this.$filter("number")(data, 6), sortable: true, searchable: true },
                { field: 'OUTCOME.TOTAL_BRL', title: this.formService.getTranslate("FINANCIAL.CONVERTED_VALUE"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'OUTCOME.INVOICE_NUMBER', title: this.formService.getTranslate("GENERAL.INVOICES"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'OUTCOME.TRANSACTION', title: this.formService.getTranslate("GENERAL.HOLDER_TYPE"), formatter: (data) => { return `<span style="max-width: 100%" class="ellipsize" ellipsis-tooltip tooltip-placement="auto top" tooltip-enable="true" tooltip-append-to-body="true" uib-tooltip="${data ? data.NAME : '-'}">${data ? data.NAME : '-'}</span>` }, sortable: true, searchable: true, visible: false, cellStyle: () => { return { css: { 'max-width': '150px' } } } },
                { field: 'OUTCOME.PEOPLE', title: this.formService.getTranslate("GENERAL.HOLDER"), formatter: (data) => { return `<span style="max-width: 100%" class="ellipsize" ellipsis-tooltip tooltip-placement="auto top" tooltip-enable="true" tooltip-append-to-body="true" uib-tooltip="${data ? data.NAME : '-'}">${data ? data.NAME : '-'}</span>` }, sortable: true, searchable: true, visible: false, cellStyle: () => { return { css: { 'max-width': '150px' } } } },
                { field: 'OUTCOME.MODALITY', title: this.formService.getTranslate("FINANCIAL.CHARGE_PAYMENT_METHOD"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'OUTCOME.VALIDITY_OF', title: this.formService.getTranslate("PRODUCT.VALIDITY_START"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true, visible: false },
                { field: 'OUTCOME.VALIDITY_UP', title: this.formService.getTranslate("PRODUCT.VALIDITY_END"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true, visible: false },
                { field: 'OUTCOME.SHOW_FOR_GROUP', title: this.formService.getTranslate("FINANCIAL.SHOWN_FOR"), formatter: (data) => { return `<span style="max-width: 100%" class="ellipsize" ellipsis-tooltip tooltip-placement="auto top" tooltip-enable="true" tooltip-append-to-body="true" uib-tooltip="${data ? this.$newProcessScope.getCONCAT(data, null, "CODE") : data}">${data ? this.$newProcessScope.getCONCAT(data, null, "CODE") : data}</span>` }, sortable: true, searchable: true, visible: false, cellStyle: () => { return { css: { 'max-width': '150px' } } } },
                { field: 'OUTCOME.COMPOSITION_GROUP', title: this.formService.getTranslate("GENERAL.COMPOSITION"), formatter: (data) => data ? this.$newProcessScope.getCONCAT(data) : data, sortable: true, searchable: true, visible: false },
                { field: 'OUTCOME.OBSERVATION', title: this.formService.getTranslate("GENERAL.REMARKS"), sortable: true, searchable: true, visible: false },
                { field: 'OUTCOME.CHARGE_ORIGIN.NAME', title: this.formService.getTranslate("BASIC_DATA.ORIGIN"), sortable: true, searchable: true, visible: false },
                { field: 'INCOME.CHARGE_EXHIBITION', title: this.formService.getTranslate("FINANCIAL.CHARGE_CODE"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'INCOME.CHARGE_EXHIBITION.NAME', title: this.formService.getTranslate("FINANCIAL.CHARGE_DISPLAY_NAME"), sortable: true, searchable: true, visible: false },
                { field: 'INCOME', title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS"), formatter: (data: INCOME) => { return `<span style="max-width: 100%" class="ellipsize" ellipsis-tooltip tooltip-placement="auto top" tooltip-enable="true" tooltip-append-to-body="true" uib-tooltip="${this.chargeTableApplicationFormatter(data)}">${this.chargeTableApplicationFormatter(data)}</span>` }, sortable: true, searchable: true, cellStyle: () => { return { css: { 'max-width': '200px' } } } },
                { field: 'INCOME.AMOUNT', title: this.formService.getTranslate("GENERAL.QUANTITY"), sortable: true, searchable: true },
                { field: 'INCOME.CURRENCY', title: this.formService.getTranslate("GENERAL.CURRENCY"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'INCOME.UNITARY', title: this.formService.getTranslate("BASIC_DATA.UNIT"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'INCOME.MINIMUM', title: this.formService.getTranslate("BASIC_DATA.MINIMUM"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'INCOME.TOTAL', title: this.formService.getTranslate("GENERAL.TOTAL"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'INCOME.CONVERSION_SPREAD', title: this.formService.getTranslate("BASIC_DATA.EXCHANGE_RATE"), formatter: (data) => this.$filter("number")(data, 6), sortable: true, searchable: true },
                { field: 'INCOME.TOTAL_BRL', title: this.formService.getTranslate("FINANCIAL.CONVERTED_VALUE"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'INCOME.INVOICE_NUMBER', title: this.formService.getTranslate("GENERAL.INVOICES"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'INCOME.TRANSACTION', title: this.formService.getTranslate("GENERAL.HOLDER_TYPE"), formatter: (data) => { return `<span style="max-width: 100%" class="ellipsize" ellipsis-tooltip tooltip-placement="auto top" tooltip-enable="true" tooltip-append-to-body="true" uib-tooltip="${data ? data.NAME : '-'}">${data ? data.NAME : '-'}</span>` }, sortable: true, searchable: true, visible: false, cellStyle: () => { return { css: { 'max-width': '150px' } } } },
                { field: 'INCOME.PEOPLE', title: this.formService.getTranslate("GENERAL.HOLDER"), formatter: (data) => { return `<span style="max-width: 100%" class="ellipsize" ellipsis-tooltip tooltip-placement="auto top" tooltip-enable="true" tooltip-append-to-body="true" uib-tooltip="${data ? data.NAME : '-'}">${data ? data.NAME : '-'}</span>` }, sortable: true, searchable: true, visible: false, cellStyle: () => { return { css: { 'max-width': '150px' } } } },
                { field: 'INCOME.MODALITY', title: this.formService.getTranslate("FINANCIAL.CHARGE_PAYMENT_METHOD"), formatter: (data) => data ? data.NAME : data, sortable: true, searchable: true, visible: false },
                { field: 'INCOME.VALIDITY_OF', title: this.formService.getTranslate("PRODUCT.VALIDITY_START"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true, visible: false },
                { field: 'INCOME.VALIDITY_UP', title: this.formService.getTranslate("PRODUCT.VALIDITY_END"), formatter: (data) => this.$filter("simpleDate")(data), sortable: true, searchable: true, visible: false },
                { field: 'INCOME.SHOW_FOR_GROUP', title: this.formService.getTranslate("FINANCIAL.SHOWN_FOR"), formatter: (data) => { return `<span style="max-width: 100%" class="ellipsize" ellipsis-tooltip tooltip-placement="auto top" tooltip-enable="true" tooltip-append-to-body="true" uib-tooltip="${data ? this.$newProcessScope.getCONCAT(data, null, "CODE") : data}">${data ? this.$newProcessScope.getCONCAT(data, null, "CODE") : data}</span>` }, sortable: true, searchable: true, visible: false, cellStyle: () => { return { css: { 'max-width': '150px' } } } },
                { field: 'INCOME.COMPOSITION_GROUP', title: this.formService.getTranslate("GENERAL.COMPOSITION"), formatter: (data) => data ? this.$newProcessScope.getCONCAT(data) : data, sortable: true, searchable: true, visible: false },
                { field: 'INCOME.OBSERVATION', title: this.formService.getTranslate("GENERAL.REMARKS"), sortable: true, searchable: true, visible: false },
                { field: 'INCOME.CHARGE_ORIGIN.NAME', title: this.formService.getTranslate("BASIC_DATA.ORIGIN"), sortable: true, searchable: true, visible: false },
                { field: 'IDC', title: this.formService.getTranslate("FINANCIAL.IDC"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: false },
                { field: 'EXCHANGE_DIFF', title: this.formService.getTranslate("FINANCIAL.CHARGE_EXCHANGE_DIFFERENCE"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: false },
                { field: 'MONETARY_DIFF', title: this.formService.getTranslate("FINANCIAL.MONETARY_VALUE_DIFFERENCE"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true, visible: false },
                { field: 'TOTAL_DIFF', footerFormatter: this.totalPriceFormatter, title: this.formService.getTranslate("FINANCIAL.CHARGE_DIFFERENCE_BRL"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
            ],
        };
    }
    private totalPriceFormatter(data) {
        const resultadoFilter = data.filter((item) => item.TOTAL_DIFF)
        if (!resultadoFilter) {
            return 0
        }
        let resultado = resultadoFilter.reduce(function (total, numero) {

            return total += numero.TOTAL_DIFF;
        }, 0);
        return resultado.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
    }

    private chargeTableApplicationFormatter(charge: OUTCOME | INCOME): string {
        let display = "";
        if (charge && charge.APPLICATION) {
            display += charge.APPLICATION.CODE;
            if (charge.APPLICATION.APPLICATION_COMPLEMENT) {
                if (charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE && charge.WEIGHT_RANGE) display += ` (${charge.WEIGHT_RANGE.NAME})`;
                if (charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.EQUIPMENT && charge.EQUIPMENT) display += ` (${charge.EQUIPMENT.CODE})`;
                if (charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.VEHICLE && charge.VEHICLE_TYPE) display += ` (${charge.VEHICLE_TYPE.NAME})`;
            }
        }
        return display;
    }

    private buildChargeExtraValues() {
        if (this.$scope.model.CHARGE && this.$scope.model.CHARGE.length) {
            for (const charge of this.$scope.model.CHARGE) {
                let monetaryIncome = 0;
                let monetaryOutcome = 0;
                if ((charge.OUTCOME.CURRENCY && charge.INCOME.CURRENCY) && charge.OUTCOME.CURRENCY.ID == charge.INCOME.CURRENCY.ID) {

                    if (charge.INCOME.CONVERSION_SPREAD) monetaryIncome = (Number(charge.OUTCOME.TOTAL.toFixed(2)) * charge.INCOME.CONVERSION_SPREAD);
                    if (charge.OUTCOME.CONVERSION_SPREAD) monetaryOutcome = (Number(charge.OUTCOME.TOTAL.toFixed(2)) * charge.OUTCOME.CONVERSION_SPREAD);

                    if (!isNaN(charge.OUTCOME.CONVERSION_SPREAD) && !isNaN(charge.INCOME.CONVERSION_SPREAD)) {
                        let exchangeDiff = charge.INCOME.CONVERSION_SPREAD - charge.OUTCOME.CONVERSION_SPREAD;
                        if (isNaN(exchangeDiff)) {
                            exchangeDiff = 0;
                        }

                        if ((charge.INCOME.CONVERSION_SPREAD && charge.OUTCOME.CONVERSION_SPREAD)) {
                            charge.EXCHANGE_DIFF = exchangeDiff;
                        }
                        let idc = ((monetaryOutcome / monetaryIncome) - 1) * 100;
                        if (isNaN(idc)) {
                            idc = 0;
                        }
                        if (charge.INCOME.CONVERSION_SPREAD && charge.OUTCOME.CONVERSION_SPREAD) charge.IDC = idc;
                        charge.MONETARY_DIFF = monetaryOutcome - monetaryIncome;
                    }
                } else {
                    charge.IDC = null;
                    charge.EXCHANGE_DIFF = null;
                    charge.MONETARY_DIFF = null;
                }
                //OLD MONETARY CALC
                // const monetary = (Number(charge.OUTCOME.TOTAL.toFixed(2)) * exchangeDiff);
                // if (!isNaN(monetary)) charge.MONETARY_DIFF = monetary;

                let total_brl_income = 0;
                let total_brl_outcome = 0;
                if (!isNaN(charge.INCOME.TOTAL_BRL)) {
                    total_brl_income = charge.INCOME.TOTAL_BRL;
                }
                if (!isNaN(charge.INCOME.TOTAL_BRL)) {
                    total_brl_outcome = charge.OUTCOME.TOTAL_BRL;
                }
                let totalDiff = total_brl_income - total_brl_outcome;
                charge.TOTAL_DIFF = totalDiff;
            }
        }
    }

    private async collapseCharge() {
        try {
            if (this.$newProcessScope.collapseState.released || this.$newProcessScope.collapseState.panel == ECollapseState.CHARGES) {
                const collapseCharge = angular.element("#collapseCharges");
                if (collapseCharge) {
                    const isCollapsed = angular.element("#collapseChargeHeading").attr("aria-expanded") == "true";
                    const product = this.$newProcessScope.model.PRODUCT;
                    if (isCollapsed) {
                        if (product && (product.ID == EProductId.AIR_EXPORT || product.ID == EProductId.AIR_IMPORT)) angular.element("#chargesWeightRangeCharge").hide();
                        this.$newProcessScope.collapseState.released = true;
                    } else if (product && (product.ID == EProductId.AIR_EXPORT || product.ID == EProductId.AIR_IMPORT)) angular.element("#chargesWeightRangeCharge").show();
                    collapseCharge['collapse']('toggle');
                    if (isCollapsed) this.$newProcessScope.repositionPanels(ECollapseState.CHARGES);
                }
            } else {
                this.$newProcessScope.collapseState.nextState = ECollapseState.CHARGES;
                this.$newProcessScope.releaseCollapse();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getProcessTabsCharge(): Promise<void> {
        this.formService.block();
        try {
            const chargeTab = await this.operationalService.get(`/process/panel/charge/${this.$newProcessScope.model.PROCESS_NUMBER}`, 30000);
            if (chargeTab && chargeTab.data && chargeTab.data.data) this.$scope.model = chargeTab.data.data;
            this.buildChargeExtraValues();
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.chargesTableOptions.load(null, true);
            this.formService.unblock();
        }
    }

    private async getSankhyaIntegrationByProcess() {
        try {
            if (!this.$newProcessScope.model.ID) throw new Error("ID está nulo.");

            this.formService.block();

            const result = await this.operationalService.get(`/process/panel/sankhyaIntegration/${this.$newProcessScope.model.ID}`, { "id": this.$newProcessScope.model.ID }, 120000);
    
            if (result && result.data && result.data.data) {
                this.$scope.sankhyaIntegrationModel.ERP_RESULT_CENTER = result.data.data.ERP_RESULT_CENTER ? result.data.data.ERP_RESULT_CENTER : null;
                this.$scope.sankhyaIntegrationModel.DOCUMENT_ERROR = result.data.data.DOCUMENT_ERROR ? result.data.data.DOCUMENT_ERROR : null;
                this.$scope.sankhyaIntegrationModel.INTEGRATION_DIRECTION = result.data.data.INTEGRATION_DIRECTION ? result.data.data.INTEGRATION_DIRECTION : null;
            } 

            this.formService.unblock();
        } catch (ex) {
            this.formService.unblock();
        }
    }

    private async integrateSankhya() {
        this.formService.block();
        try {
            if (this.$scope.sankhyaIntegrationModel.INTEGRATION_DIRECTION && this.$scope.sankhyaIntegrationModel.INTEGRATION_DIRECTION.ID == EIntegrateDirection.SANKHYA && (this.$scope.sankhyaIntegrationModel.DOCUMENT_ERROR || !this.$scope.sankhyaIntegrationModel.ERP_RESULT_CENTER)) {
                const result = await this.dataOperationalService.post(`/sync/sankhya/processResultCenter`, { "id": [this.$newProcessScope.model.ID] }, 120000);
                const msg = this.formService.getTranslate('REGISTRATION.SANKHYA_RECORD_INTEGRATION_WARNING');
                if (result.data) this.formService.notifySuccess(msg);
            } 
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async updateChargeCache() {
        this.formService.block();
        try {
            const finopReq: IMonacoRequest = {
                data: {
                    processNumber: this.$newProcessScope.model.PROCESS_NUMBER,
                    clearBeforeUpdate: true,
                },
                route: '/process/updateChargeViewCache',
                timeout: 120000,
            }
            const result = await this.finopService.post(finopReq);
            const msg = this.formService.getTranslate('REGISTRATION.UPDATE_CHARGE_CACHE');
            if (result.data) this.formService.notifySuccess(msg);
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private buildErrorSankhyaTooltip(errors: IDocumentError[]): string {
        let tooltip = "<div align=\"left\">";
        if (errors && errors.length) {
            const errorsSankhya = errors.filter(e => e.SUBJECT.ID == "1");
            for (const reason of errorsSankhya) {
                tooltip += `DATA: ${reason.DATE} <br>`;
                tooltip += `ASSUNTO: ${reason.SUBJECT.NAME} <br>`;
                tooltip += `RAZÃO: ${reason.REASON} <br><br>`;
            }
        }
        tooltip += "</div>";
        return tooltip;
    }

}
import * as config from '../bootstrap/Config';
import * as Address from '../communication/Address';
import { HandleError } from '../common/util/HandleError';

export interface IFileReturn {
    operation: string;
    identity: string;
    idReport: string;
    fileName: string;
    fileType: string;
}

export interface IBufferReturn {
    type: string;
    data: Array<number>;
}

export interface IDownloadParamsReturn {
    file: IFileReturn;
    buffer: IBufferReturn;
}

export interface IFileDownload {
    url: string;
    name: string;
    type: string;
    blob: Blob;
}

export interface IDownloadFilesGenericService {
    downloadFileFromGoogleStorage: (hash: string) => Promise<void>;
    previewFileFromGoogleStorage: (hash: string) => Promise<IFileDownload>;
}

export class DownloadFilesGenericService {
    static $inject: string[] = ['$injector', '$http'];
    protected injector: ng.Injectable<any>;
    protected downloadRoute: string;
    protected $http: ng.IHttpService;
    private timeout: number;

    constructor($injector: ng.Injectable<any>) {
        this.injector = $injector;
        this.$http = this.injector.get('$http');
        this.timeout = 300000; // 5 minutes

        if (config.default.environment === 'prod') {
            this.downloadRoute = `${Address.monacoAddressProd.FIS}/api/v1/fis/filesGeneric/download`;
        } else if (config.default.environment === 'qa') {
            this.downloadRoute = `${Address.monacoAddressQa.FIS}/api/v1/fis/filesGeneric/download`;
        } else { //dev
            this.downloadRoute = `${Address.monacoAddressLocal.FIS}/api/v1/fis/filesGeneric/download`;
        }
    }

    public async downloadFileFromGoogleStorage(hash: string): Promise<void> {
        try {
            if (!hash) return null;

            const operation = await this.$http({
                method: 'GET',
                url: `${this.downloadRoute}/${hash}`,
                cache: false,
                timeout: this.timeout,
            });

            const response: angular.IHttpResponse<any> = operation;
            if (response && response.data && response.data.data) {
                const resultFile = <IDownloadParamsReturn>response.data.data;

                const file = resultFile.file;
                if (!resultFile.buffer) {
                    throw Error('Failed to get file information');
                }
                const buffer = resultFile.buffer.data;
                if (!file || !buffer) {
                    throw Error('Failed to get file information');
                }
                const fileName = file.fileName;
                const fileBuffer = new Uint8Array(buffer);
                const fileType = this.getContentType(file.fileType);
                const fileBlob = new Blob([fileBuffer], { type: fileType });
                const fileURL = window.URL.createObjectURL(fileBlob);

                let link = document.createElement('a');
                link.href = fileURL;
                link.download = fileName;
                link.click();
            } else {
                throw Error('Error on download file')
            }

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    public async previewFileFromGoogleStorage(hash: string): Promise<IFileDownload> {
        try {
            if (!hash) return null;

            const operation = await this.$http({
                method: 'GET',
                url: `${this.downloadRoute}/${hash}`,
                cache: false,
                timeout: this.timeout,
            });

            const response: angular.IHttpResponse<any> = operation;
            if (response && response.data && response.data.data) {
                const resultFile = <IDownloadParamsReturn>response.data.data;

                const file = resultFile.file;
                if (!resultFile.buffer) {
                    throw Error('Failed to get file information');
                }
                const buffer = resultFile.buffer.data;
                if (!file || !buffer) {
                    throw Error('Failed to get file information');
                }

                const fileName = file.fileName;
                const fileBuffer = new Uint8Array(buffer);
                const fileType = this.getContentType(file.fileType);
                const fileBlob = new Blob([fileBuffer], { type: fileType });
                const fileURL = window.URL.createObjectURL(fileBlob);
                const result = <IFileDownload>{ url: fileURL, name: fileName, type: fileType, blob: fileBlob };

                return result;
            }
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    private getContentType(fileType: string): string {
        switch (fileType.toLowerCase()) {
            case 'arc':
                return 'application/octet-stream';
            case 'bin':
                return 'application/octet-stream';
            case 'avi':
                return 'video/x-msvideo';
            case 'azw':
                return 'application/vnd.amazon.ebook';
            case 'gif':
                return 'image/gif';
            case 'htm':
                return 'text/html';
            case 'html':
                return 'text/html';
            case 'ico':
                return 'image/x-icon';
            case 'ics':
                return 'text/calendar';
            case 'js':
                return 'application/javascript';
            case 'json':
                return 'application/json';
            case 'mpeg':
                return 'video/mpeg';
            case 'jpeg':
                return 'image/jpeg';
            case 'jpg':
                return 'image/jpg';
            case 'png':
                return 'image/png';
            case 'pdf':
                return 'application/pdf';
            case 'rar':
                return 'application/x-rar-compressed';
            case 'zip':
                return 'application/zip';
            case '7z':
                return 'application/x-7z-compressed';
            case 'ppt':
                return 'application/vnd.ms-powerpoint';
            case 'csv':
                return 'text/csv';
            case 'doc':
                return 'application/msword';
            case 'xls':
                return 'application/vnd.ms-excel';
            case 'xlsx':
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            case 'vsd':
                return 'application/vnd.visio';
            case 'ts':
                return 'application/typescript';
            case 'rtf':
                return 'application/rtf';
            default:
                return 'text/plain';
        }
    }
}

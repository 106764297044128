import * as Address from '../communication/Address';

DownloadService.$inject = ['config', '$http', 'ErrorService'];
export function DownloadService(config, $http, ErrorService) {
    let downloadRoute = undefined;
    if (config.environment === 'prod') {
        downloadRoute = `${Address.monacoAddressProd.FIS}/api/v1/fis/download/sync`;
    } else if (config.environment === 'qa') {
        downloadRoute = `${Address.monacoAddressQa.FIS}/api/v1/fis/download/sync`;
    } else { //dev
        downloadRoute = `${Address.monacoAddressLocal.FIS}/api/v1/fis/download/sync`;
    }
    console.log('Download Route: ', downloadRoute);

    this.getContentType = function (fileType) {
        switch (fileType.toLowerCase()) {
            case 'arc':
                return 'application/octet-stream';
            case 'bin':
                return 'application/octet-stream';
            case 'avi':
                return 'video/x-msvideo';
            case 'azw':
                return 'application/vnd.amazon.ebook';
            case 'gif':
                return 'image/gif';
            case 'htm':
                return 'text/html';
            case 'html':
                return 'text/html';
            case 'ico':
                return 'image/x-icon';
            case 'ics':
                return 'text/calendar';
            case 'js':
                return 'application/javascript';
            case 'json':
                return 'application/json';
            case 'mpeg':
                return 'video/mpeg';
            case 'jpeg':
                return 'image/jpeg';
            case 'jpg':
                return 'image/jpg';
            case 'png':
                return 'image/png';
            case 'pdf':
                return 'application/pdf';
            case 'rar':
                return 'application/x-rar-compressed';
            case 'zip':
                return 'application/zip';
            case '7z':
                return 'application/x-7z-compressed';
            case 'ppt':
                return 'application/vnd.ms-powerpoint';
            case 'csv':
                return 'text/csv';
            case 'doc':
                return 'application/msword';
            case 'xls':
                return 'application/vnd.ms-excel';
            case 'xlsx':
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            case 'vsd':
                return 'application/vnd.visio';
            case 'ts':
                return 'application/typescript';
            case 'rtf':
                return 'application/rtf';
            default:
                return 'text/plain';
        }
    }

    this.downloadFileFromGoogleStorage = function (hash) {
        const self = this;

        const timeout = (60 * 1000) * 5; //5min timeout
        //RestService.getObject("/storage/sync/" + hash, timeout).then(function successCallback(successResponse) {
        $http({
            method: 'get',
            url: `${downloadRoute}/${hash}`,
            cache: true,
            timeout: timeout
        }).then(function successCallback(successResponse) {
            const file = successResponse.data.data.file;
            const buffer = successResponse.data.data.buffer.data;
            if (!file || !buffer) {
                return ErrorService.internal('Failed to get file information');
            } else {
                const fileBuffer = new Uint8Array(buffer);
                const fileBlob = new Blob([fileBuffer], { type: self.getContentType(file.fileType) });
                const fileURL = URL.createObjectURL(fileBlob);
                const fileName = file.fileName;
                let link = document.createElement('a');
                link.href = fileURL;
                link.download = fileName;
                link.click();

            }
        }, function errorCallback(errorResponse) {
            console.log('error: ', errorResponse);
            ErrorService.handleError(errorResponse);
        });

    }

    this.previewFileFromGoogleStorage = function (hash) {
        const self = this;

        const timeout = (60 * 1000) * 5; //5min timeout
        let result = new Promise(function (resolve, reject) {
            $http({
                method: 'get',
                url: `${downloadRoute}/${hash}`,
                cache: true,
                timeout: timeout
            }).then(function successCallback(successResponse) {
                const file = successResponse.data.data.file;
                const buffer = successResponse.data.data.buffer.data;
                if (!file || !buffer) {
                    reject(successResponse);
                } else {
                    const fileName = file.fileName;
                    const fileBuffer = new Uint8Array(buffer);
                    const fileType = self.getContentType(file.fileType);
                    const fileBlob = new Blob([fileBuffer], { type: fileType });
                    const fileURL = window.URL.createObjectURL(fileBlob);
                    //const fileURL = window.URL.createObjectURL(fileBlob, { oneTimeOnly: true });
                    const result = { url: fileURL, name: fileName, type: fileType, blob: fileBlob };
                    //window.URL.revokeObjectURL(fileURL);
                    //const trusted = self.$sce.trustAsResourceUrl(fileURL);
                    resolve(result);
                }
            }, function errorCallback(errorResponse) {
                console.log('error: ', errorResponse);
                reject(errorResponse);
            }).catch(function (ex) {
                console.log('Excecao TOCSON: ', ex);
                reject(ex);
            });
        });
        return result;
    }
}
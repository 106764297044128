import * as angular from 'angular';
import { HandleError } from '../../common/util/HandleError';
import { IGridApi, IGridOptions, cellNav } from 'ui-grid';
import { IGrid } from '../../app/components/datetimepicker/DatetimePickerController';


uiGridExcel.$inject = ['$timeout', 'rowSearcher', 'uiGridConstants', 'NotificationService', '$state', 'GridColumn', 'GridRow', 'GridRowColumn', 'blockUI', '$q'];

interface IGridRowColumn {
    row: uiGrid.IGridRow,
    col: uiGrid.IGridColumn
}

export interface IUiGrid {
    cellNav: cellNav.ICellNavApi<object>;
    columnMenuScope: () => void;
    fireEvent: () => void;
    focus: () => void;
    grid: IGrid,
    innerCompile: () => void;
    scrollbars: Array<object>;
}


export function uiGridExcel($timeout, rowSearcher, uiGridConstants, NotificationService, $state, GridColumn, GridRow, GridRowColumn, blockUI, $q) {
    return {
        replace: true,
        require: '^uiGrid',
        scope: false,
        controller: function () { },
        compile: function () {
            return {
                pre: function ($scope, $elm, $attrs, uiGridCtrl) { },
                post: function ($scope, $elm, $attrs, uiGridCtrl) {


                    let _scope = $scope;
                    let grid = uiGridCtrl.grid;
                    let isDateTimePickerEdit = false;
                    let oldValue = null;
                    let gridApi: IGridApi = grid.api;

                    gridApi.cellNav.on.navigate(_scope, dragStart);

                    // Data setup
                    _scope.ugCustomSelect = {

                        hiddenInput: angular.element('<input class="ui-grid-custom-selection-input" type="text" />').appendTo('body'),
                        isDragging: false,
                        selectedCells: [],
                        cellMap: {},
                        copyData: '',
                        dragData: {
                            startCell: {
                                row: null,
                                col: null
                            },
                            endCell: {
                                row: null,
                                col: null
                            }
                        },
                        fields: [],
                        //auxliar para evitar selecao de linha                            
                        firstSelectedCell: null,
                        rowsSelected: [],
                        cellsChange: [],
                        rowsSelectedTemp: [],
                        fieldNameTemp: '',
                    }


                    /*****************************************
                     * ADAPTAÇÕES AO UI-GRID EXCEL
                     * @author Ríder Cantuária <rider.cantuaria>
                     *****************************************/

                    grid.api.edit.on.beginCellEdit($scope, beginCellEdit);
                    grid.api.edit.on.afterCellEdit($scope, afterCellEdit);
                    grid.api.edit.on.cancelCellEdit($scope, cancelCellEdit);
                    grid.options.rowEditWaitInterval = -1;

                    autoSave();

                    /**
                     * @description Deixa células nulas
                     * 
                     * @returns {undefined}
                     */
                    async function deleteMultipleCells(evt): Promise<void> {

                        let panel = $('datetime-picker input:visible');
                        if (panel.length) return;

                        if (_scope.ugCustomSelect.firstSelectedCell && _scope.ugCustomSelect.firstSelectedCell.col) {

                            const value = deepFind(_scope.ugCustomSelect.firstSelectedCell.row.entity, _scope.ugCustomSelect.firstSelectedCell.col.field);
                            if (!value) return;

                            destroyDeleteNotification(); //limpa                                
                            createDeleteNotification(); //mostra

                        }

                    }

                    function deepFind(obj, path) {
                        var paths = path.split('.')
                            , current = obj
                            , i;


                        for (i = 0; i < paths.length; ++i) {
                            if (current[paths[i]] == undefined) {
                                return undefined;
                            } else {
                                current = current[paths[i]];
                            }
                        }

                        return current;
                    }

                    function setValueToPath(obj, value, path) {
                        var i;
                        path = path.split('.');
                        for (i = 0; i < path.length - 1; i++)
                            obj = obj[path[i]];

                        obj[path[i]] = value;
                    }


                    /**
                     * @description Atualiza as células das linhas selecionadas
                     * 
                     * @param {array} rows Linhas da grid
                     * @param {string} property Propriedade desejada
                     * @param {number} value Valor novo
                     * 
                     * @returns {undefined} Vazio
                     */
                    function updateCellsValues(rows, property, value) {

                        try {

                            grid.options.data.map(function (data) {
                                if (rows.indexOf(data.$$hashKey) >= 0) {
                                    data[property] = value;
                                    _scope.ugCustomSelect.fields.push(property);
                                    _scope.ugCustomSelect.cellsChange.push(data);
                                }
                            });

                        } catch (ex) {
                            HandleError.exception(new Error('Erro ao mapear dados da planilha, favor clique aqui para atualizar a página, caso o erro persista entre em contato com a Equipe de TI.'), () => { location.reload() });
                        }

                    }

                    /**
                     * @description Adaptações para o grid e Seleção de células desabilitando colunas na seleção
                     * 
                     * @returns {undefined} Vazio                    
                     */
                    function disableRowSelect() {

                        _scope.ugCustomSelect.rowsSelected = [];

                        //seleciona as linhas somente editáveis e  trata o visual
                        for (let i = 0; i < _scope.ugCustomSelect.selectedCells.length; i++) {
                            let currentCell = _scope.ugCustomSelect.selectedCells[i];

                            if (currentCell.col.colDef.enableCellEdit) {
                                if (_scope.ugCustomSelect.firstSelectedCell.col.field == currentCell.col.name) {
                                    currentCell.elem.find('.ui-grid-cell-contents').addClass('ui-grid-custom-selected');

                                    if (currentCell.row) {
                                        _scope.ugCustomSelect.rowsSelected.push(currentCell.row.entity.$$hashKey);
                                    }

                                } else {
                                    currentCell.elem.find('.ui-grid-cell-contents').addClass('ui-grid-custom-out-selected');
                                }
                            } else {
                                currentCell.elem.find('.ui-grid-cell-contents').addClass('ui-grid-custom-out-selected');
                            }

                        }


                    }

                    /**
                     * @description Evento para destruir por completo a notificação
                     * 
                     * @returns {undefined}
                     */
                    function destroyDeleteNotification() {
                        //prevent max call
                        $(document).off('click', '.notifyjs-container');
                        $(document).off('click', '#removeCells');

                        //$('.notifyjs-container').trigger('click');
                        $('.notifyed').remove();
                        $('.notifyjs-wrapper').remove();
                    }

                    /**
                     * @description Pega o primeiro elemento Selecionado para o Drag & Drop
                     * 
                     * @returns {JQuery}
                     */
                    function getFirstElementSelected() {
                        let element = $('.ui-grid-cell-focus');

                        if (!element.length) {
                            element = $('.ui-grid-custom-selected');
                        }

                        if (element.length > 1) {
                            element = $(element[0]);
                        }

                        return element;
                    }

                    /**
                     * @description Evento para criação da notificação
                     * 
                     * 
                     * @returns {undefined}
                     */
                    function createDeleteNotification() {

                        let element = getFirstElementSelected();
                        if (!element.offset()) return;

                        let notify = `<b>Deseja realmente deletar a seleção?</b>

                                         <button id="removeCells" class="btn btn-warning pull-left">Ok</button><button class="btn btn-default pull-right" sty>Cancelar</button>
                                            `;

                        let left = element.offset().left + (element.width() / 2);
                        let top = element.offset().top + 30;

                        let body = $('body');

                        let container = $('<div class="notifyed"></div>');
                        container.css('position', 'absolute');
                        container.css('top', top);
                        container.css('left', left);

                        body.append(container);

                        NotificationService.element('.notifyed').warning(notify, function () {
                            destroyDeleteNotification();
                        }, {
                                elementPosition: 'bottom center',
                                autoHide: false
                            });



                        angular.element(document).on('click', '#removeCells', deleteSelectedCell);
                        angular.element(document).find('#removeCells').focus();
                    }


                    function deleteSelectedCell(row) {
                        try {

                            const row: uiGrid.IGridRow = _scope.ugCustomSelect.firstSelectedCell.row;
                            const name: string = _scope.ugCustomSelect.firstSelectedCell.col.field;
                            const col: uiGrid.IGridColumn = _scope.ugCustomSelect.firstSelectedCell.col;

                            grid.options.data.map(function (data) {
                                if (row.entity.$$hashKey === data.$$hashKey) {
                                    setValueToPath(data, null, name);
                                    _scope.ugCustomSelect.fields.push(name);
                                    _scope.ugCustomSelect.cellsChange.push(data);
                                }
                            });

                        } catch (ex) {
                            HandleError.exception(new Error('Erro ao mapear dados da planilha, favor clique aqui para atualizar a página, caso o erro persista entre em contato com a Equipe de TI.'), () => { location.reload() });
                        }
                    }


                    /**
                     * @description Deleta seleção de coluna somente se editáveis
                     *                             
                     * @returns {undefined}                         
                     */
                    function deleteSelectedCells() {
                        //remove as celulas
                        updateCellsValues(_scope.ugCustomSelect.rowsSelected, _scope.ugCustomSelect.firstSelectedCell.col.field, undefined);
                    }


                    /**
                     * @description Função com countdown de execução
                     * 
                     * @param {number} time Tempo em ms de descanso
                     */
                    function countdown(time) {
                        return new Promise(resolve => setTimeout(resolve, time));
                    }

                    /**
                     * @description Função polling
                     * 
                     * @param {Promisse} promisse
                     * @param {number} time tempo de intervalo em ms
                     */
                    function polling(promise, time) {
                        promise().then(
                            countdown(time).then(() => polling(promise, time)).catch(ex => {
                                HandleError.exception(ex)
                            })
                        ).catch(ex => {
                            HandleError.exception(ex)
                        });
                    }


                    /**
                     * @description Foca na célula que foi selecionada de forma assíncrona
                     * 
                     * @param {array} data 
                     * 
                     * @returns {undefined}
                     */
                    function focusCellByData(data) {

                        $timeout(function () {
                            let rows = grid.api.grid.getVisibleRows();

                            let rowSelected;
                            rows.forEach((row, key) => {
                                if (row && data) {
                                    if (row.entity && data.row) {

                                        if (row.entity.ID == data.row.entity.ID) {
                                            rowSelected = row.entity;
                                            return;
                                        }
                                    }
                                }
                            })

                            if (rowSelected && data.col) {
                                //grid.api.cellNav.scrollToFocus(rowSelected, data.col.colDef);
                            }

                        });

                    }


                    function clearTextPanelExcel() {
                        $('.excel-feedback .panel-heading .autosave').html("");
                    }

                    /**
                     * @description Auto Save 
                     * detalhe que apenas um watch collection resolveria, mas fui fazendo :( e só depois me toquei
                     * 
                     * @returns {Promisse}
                     */
                    function autoSave() {

                        return polling(() => new Promise(async (resolve, reject) => {

                            try {
                                let list = null;

                                if (_scope.ugCustomSelect.cellsChange.length) {

                                    let autoSave = "<div class='autosave'></div>";
                                    $('.excel-feedback .panel-heading').prepend(autoSave);

                                    const cells = _scope.ugCustomSelect.cellsChange;
                                    const name = _scope.ugCustomSelect.fields[0];
                                    _scope.ugCustomSelect.cellsChange = [];
                                    _scope.ugCustomSelect.fields = [];

                                    $('.excel-feedback .panel-heading .autosave').html("Salvando...");
                                    const elem = $('.ui-grid-custom-selected.ui-grid-cell-focus').parent('.ui-grid-cell');
                                    const result = await $scope.saveExcel(cells, name);
                                    if(result && result.data){
                                        grid.options.data = result.data.data;
                                        list = result.data.data;
                                    }

                                    $('.excel-feedback .panel-heading .autosave').html("Todas as alterações foram Salvas!");

                                    removeSelection();
                                    $scope.$applyAsync();

                                    //to avoid hide notification error or warning
                                    if ($('.notifyjs-arrow:visible').length === 0) {
                                        $timeout(function () {
                                            elem.find('.ui-grid-cell-contents').focus().click();
                                        }, 750);
                                    }

                                    $timeout(function () {
                                        clearTextPanelExcel();
                                    }, 5000);
                                }

                                return resolve(list);

                            } catch (ex) {

                                blockUI.reset();
                                _scope.ugCustomSelect.cellsChange = [];

                                $('.excel-feedback .panel-heading .autosave').html("Operação Cancelada!");
                                clearTextPanelExcel();

                                HandleError.exception(ex);

                                return reject(ex);

                            }

                        }), 1000);



                    }


                    /**
                     * @description Verifica se precisa disparar update para o server [PRINCÍPIO PERFORMANCE]
                     * 
                     * @param {*} newValue 
                     * @param {*} oldValue 
                     * 
                     * @returns {boolean}
                     */
                    function hasUpdate(newValue, oldValue) {

                        if (JSON.stringify(newValue) == JSON.stringify(oldValue)) return false;
                        //previne erros no datetimepicker
                        if (newValue && typeof newValue == 'object') {

                            if (newValue.hasOwnProperty('date')) {
                                newValue = {
                                    date: newValue.date,
                                    open: false,
                                    planned: newValue.planed
                                };

                                oldValue = {
                                    date: oldValue.date,
                                    open: false,
                                    planned: oldValue.planed
                                };

                                return JSON.stringify(newValue) != JSON.stringify(oldValue);
                            }

                            return true;
                        }

                        if (typeof newValue == 'string') return true;

                        return false;

                    }



                    /* EVENTOS */

                    function afterCellEdit(rowEntity, colDef, newValue) {



                        if (colDef.type == 'datetimepicker') {

                            //retira o bloco
                            angular.element('.blockEdit').remove();

                            let toolbar = angular.element('.ui-grid-render-container-left');
                            toolbar.removeClass('blur');
                        }

                        if (hasUpdate(newValue, oldValue)) {
                            updateCellValue(rowEntity, colDef, newValue);
                        }

                    }

                    function updateCellValue(row: any, colDef: uiGrid.IColumnDef, newValue: any) {
                        try {

                            grid.options.data.map(function (data) {
                                if (row.$$hashKey === data.$$hashKey) {
                                    data[colDef.name] = newValue;
                                    _scope.ugCustomSelect.fields.push(colDef.name);

                                    if (colDef.name.split('.').length > 1) delete data[colDef.name];
                                    _scope.ugCustomSelect.cellsChange.push(data);
                                }
                            });

                        } catch (ex) {
                            HandleError.exception(new Error('Erro ao mapear dados da planilha, favor clique aqui para atualizar a página, caso o erro persista entre em contato com a Equipe de TI.'), () => { location.reload() });
                        }
                    }

                    function cancelCellEdit(rowEntity, colDef) {

                        if (colDef.type == 'datetimepicker') {
                            //retira o bloco
                            angular.element('.blockEdit').remove();

                            let toolbar = angular.element('.ui-grid-render-container-left');
                            toolbar.removeClass('blur');
                        }

                        isDateTimePickerEdit = false;

                    }


                    function scrollBegin(ScrollEvent) {

                        $timeout(function () {
                            destroyDeleteNotification();
                        });

                    }

                    function beginCellEdit(rowEntity, colDef) {

                        oldValue = Object.assign({}, rowEntity[colDef.name]);

                        isDateTimePickerEdit = false;



                        if (colDef.type == 'datetimepicker') {

                            let toolbar = angular.element('.ui-grid-render-container-left');
                            toolbar.addClass('blur');


                            //evita duplicidade
                            if (toolbar.find('.blockEdit').length == 0) {

                                let block = document.createElement("div");

                                $(block).height(toolbar.height());
                                $(block).width(toolbar.width());
                                $(block).css('position', 'absolute');
                                $(block).css('z-index', '9999');
                                $(block).addClass('blockEdit');

                                toolbar.prepend(block);

                            }


                            isDateTimePickerEdit = true;
                        }

                        _scope.ugCustomSelect.rowsSelectedTemp = _scope.ugCustomSelect.rowsSelected;

                        if (_scope.ugCustomSelect.firstSelectedCell)
                            _scope.ugCustomSelect.fieldNameTemp = _scope.ugCustomSelect.firstSelectedCell.col.field;

                    }



                    /*****************************************
                     * ORIGINAL
                     * @author brendenjpeterson <brendenjpeterson@gmail.com>
                     *****************************************/


                    // Bind events
                    $timeout(function () {

                        //grid.element.on('mousedown', '.ui-grid-cell-contents', dragStart);

                        angular.element('.ui-grid').on('mouseup', bodyMouseUp);
                        angular.element('.ui-grid').on('keyup', documentKeyUp);
                        angular.element('.ui-grid').on('copy', documentCopyCells);

                        grid.api.core.on.scrollBegin(_scope, gridScrollBegin);
                        grid.api.core.on.scrollEnd(_scope, gridScrollEnd);

                        grid.api.core.on.filterChanged(_scope, clearDragData);
                        grid.api.core.on.columnVisibilityChanged(_scope, clearDragData);
                        grid.api.core.on.rowsVisibleChanged(_scope, clearDragData);
                        grid.api.core.on.sortChanged(_scope, clearDragData);
                    });

                    // Events
                    function dragStart(newRowCol: IGridRowColumn, oldRowCol: IGridRowColumn) {

                        try {

                            destroyDeleteNotification();
                            _scope.ugCustomSelect.firstSelectedCell = newRowCol;

                        } catch (ex) {
                            HandleError.exception(ex);
                        }
                    }

                    /* function dragStart(evt, keyboard) {
 
                         try {
                             destroyDeleteNotification();
 
                             grid.element.off('mouseenter', '.ui-grid-cell-contents');
 
                             let element = null;
 
                             if (keyboard) { //teclado
                                 element = $('.ui-grid-cell-focus');
                             } else { //mouse                                
                                 element = $(this);
                             }
 
                             const cellData: IGridRowColumn = getFocusedCellByElement(element);
                             if (!cellData) return;
 
                             _scope.ugCustomSelect.firstSelectedCell = cellData;
                             //grid.api.cellNav.scrollToFocus(cellData.row.entity, cellData.col.colDef);
 
 
 
                             clearDragData();
                             _scope.ugCustomSelect.isDragging = true;
                             setStartCell(cellData.row, cellData.col);
 
                             setSelectedStates();
                         } catch (ex) {
                             HandleError.exception(ex);
                         }
                     }
 
                     function getFocusedCellByElement(element): IGridRowColumn {
 
                         const id: string = element.parent('div').attr('id');
                         if (id) {
                             const pieces: Array<string> = id.split('-');
 
                             let col: uiGrid.IGridColumn = null;
                             let row: uiGrid.IGridRow = null;
 
                             if (pieces[4]) {
 
                                 const idSelected: string = pieces[3];
                                 Array.from($('span.ui-grid-header-cell-label')).forEach(item => {
                                     const id: string = $(item).attr('id');
                                     if (id) {
                                         const pieces: Array<string> = id.split('-');
                                         if (pieces[2]) {
                                             const colUid = pieces[2];
 
                                             if (colUid === idSelected) {
                                                 const displayName: string = $(item).html();
                                                 const columns: Array<uiGrid.IColumnDefOf<any>> = gridOptions.columnDefs;
 
                                                 if (columns) {
                                                     const filtered: Array<uiGrid.IColumnDefOf<any>> = columns.filter(def => def.displayName === displayName);
                                                     if (filtered.length > 0) {
                                                         const defSelected: uiGrid.IColumnDefOf<any> = filtered[0];
                                                         col = new GridColumn(defSelected, idSelected, grid);
                                                     }
                                                 }
                                             }
                                         }
                                     }
                                 });
 
 
                             }
 
                             if (pieces[1]) {
                                 const index: number = parseInt(pieces[1]);
                                 const rows: Array<uiGrid.IGridRow> = gridApi.core.getVisibleRows(gridApi.grid);
                                 row = rows[index];
                             }
 
 
                             if (col && row) {
                                 return new GridRowColumn(row, col);
                             }
 
                         }
 
                         return null;
                     }
                     */

                    //gamb until solve this issue https://github.com/angular-ui/ui-grid/issues/4467
                    function mouseEnterCell(evt, keyboard) {
                        removeSelection();
                        $(this).addClass('ui-grid-focus').addClass('ui-grid-cell-focus').addClass('ui-grid-custom-selected');
                    }

                    function removeSelection() {
                        $('*').removeClass('ui-grid-focus').removeClass('ui-grid-cell-focus').removeClass('ui-grid-custom-selected');
                    }

                    function bodyMouseUp(evt) {
                        if (_scope.ugCustomSelect.isDragging) {
                            _scope.ugCustomSelect.isDragging = false;
                            setSelectedStates();
                        }
                    }

                    function documentKeyUp(evt) {

                        let cKey = 67;
                        if (evt.keyCode == cKey && evt.ctrlKey && window.getSelection() + '' === '') {
                            _scope.ugCustomSelect.hiddenInput.val(' ').focus().select();
                            document.execCommand('copy');
                            evt.preventDefault();
                        }



                        switch (evt.keyCode) {
                            case uiGridConstants.keymap.ESC:
                                destroyDeleteNotification();
                                break;
                            case uiGridConstants.keymap.F2:
                                destroyDeleteNotification();
                                break;
                            case uiGridConstants.keymap.DEL:
                                deleteMultipleCells(evt);
                                break;
                            case uiGridConstants.keymap.UP:
                            case uiGridConstants.keymap.DOWN:
                            case uiGridConstants.keymap.RIGHT:
                            case uiGridConstants.keymap.LEFT:
                            case uiGridConstants.keymap.ENTER:
                                //feature shift
                                if (evt.shiftKey) {
                                    mouseEnterCell(evt, true);
                                } else {
                                    //dragStart(evt, true);
                                }
                                break;
                            case uiGridConstants.keymap.TAB:
                                //dragStart(evt, true);                                
                                break;

                        }

                    }

                    function documentCopyCells(evt) {


                        let cbData,
                            cbType;

                        if (evt.originalEvent.clipboardData) {
                            cbData = evt.originalEvent.clipboardData;
                            cbType = 'text/plain';
                        } else {
                            cbData = (<any>window).clipboardData;
                            cbType = 'Text';
                        }

                        if (cbData && (window.getSelection() + '' === '' || window.getSelection() + '' === ' ') && _scope.ugCustomSelect.copyData !== '') {
                            cbData.setData(cbType, _scope.ugCustomSelect.copyData);
                            evt.preventDefault();
                        }
                    }

                    function gridScrollBegin() {
                        destroyDeleteNotification();
                        grid.element.addClass('ui-grid-custom-selected-scrolling');
                    }

                    function gridScrollEnd() {
                        angular.element('.ui-grid-custom-selected').removeClass('ui-grid-custom-selected');
                        let visibleCols = grid.renderContainers.body.renderedColumns;
                        let visibleRows = grid.renderContainers.body.renderedRows;

                        for (let ri = 0; ri < visibleRows.length; ri++) {
                            let currentRow = visibleRows[ri];
                            for (let ci = 0; ci < visibleCols.length; ci++) {
                                let currentCol = visibleCols[ci];

                                if (cellIsSelected(currentRow, currentCol)) {
                                    //alterado para mudancas da row
                                    getCellElem(currentCol, ri).find('.ui-grid-cell-contents:not(.ui-grid-custom-out-selected)').addClass('ui-grid-custom-selected');
                                }
                            }
                        }

                        grid.element.removeClass('ui-grid-custom-selected-scrolling');
                    }

                    // Functions
                    function setStartCell(row, col) {
                        _scope.ugCustomSelect.dragData.startCell.row = row;
                        _scope.ugCustomSelect.dragData.startCell.col = col;
                    }

                    function setEndCell(row, col) {
                        _scope.ugCustomSelect.dragData.endCell.row = row;
                        _scope.ugCustomSelect.dragData.endCell.col = col;
                    }

                    function clearDragData() {
                        clearEndCell();
                        clearStartCell();
                        clearSelectedStates();
                        _scope.ugCustomSelect.copyData = '';
                    }

                    function clearStartCell() {
                        _scope.ugCustomSelect.dragData.startCell.row = null;
                        _scope.ugCustomSelect.dragData.startCell.col = null;
                    }

                    function clearEndCell() {
                        _scope.ugCustomSelect.dragData.endCell.row = null;
                        _scope.ugCustomSelect.dragData.endCell.col = null;
                    }

                    // Sets selected styling based on start cell and end cell, including cells in between that range                    
                    function setSelectedStates() {


                        clearSelectedStates();

                        let indexMap = createIndexMap(_scope.ugCustomSelect.dragData.startCell, _scope.ugCustomSelect.dragData.endCell);
                        _scope.ugCustomSelect.selectedCells = getCellsWithIndexMap(indexMap);
                        _scope.ugCustomSelect.cellMap = _scope.ugCustomSelect.selectedCells.reduce(function (map, obj) {

                            if (map[obj.row] != undefined) {
                                if (map[obj.row.uid]) {
                                    map[obj.row.uid].push(obj.col.uid);
                                } else {
                                    map[obj.row.uid] = [obj.col.uid];
                                }
                            }

                            return map;

                        }, {});

                        disableRowSelect();

                    }

                    // Clears selected state from any selected cells
                    function clearSelectedStates() {
                        //angular.element('.ui-grid-custom-selected').removeClass('ui-grid-custom-selected');
                        angular.element('.ui-grid-custom-out-selected').removeClass('ui-grid-custom-out-selected');
                        _scope.ugCustomSelect.selectedCells = [];
                        _scope.ugCustomSelect.cellMap = {};
                    }

                    function createIndexMap(startCell, endCell) {
                        let rowStart = grid.renderContainers.body.renderedRows.indexOf(_scope.ugCustomSelect.dragData.startCell.row),
                            rowEnd = grid.renderContainers.body.renderedRows.indexOf(_scope.ugCustomSelect.dragData.endCell.row),
                            colStart = grid.renderContainers.body.renderedColumns.indexOf(_scope.ugCustomSelect.dragData.startCell.col),
                            colEnd = grid.renderContainers.body.renderedColumns.indexOf(_scope.ugCustomSelect.dragData.endCell.col)

                        if (rowEnd === -1)
                            rowEnd = rowStart;

                        if (colEnd === -1)
                            colEnd = colStart;

                        return {
                            row: {
                                start: (rowStart < rowEnd) ? rowStart : rowEnd,
                                end: (rowEnd > rowStart) ? rowEnd : rowStart
                            },
                            col: {
                                start: (colStart < colEnd) ? colStart : colEnd,
                                end: (colEnd > colStart) ? colEnd : colStart
                            }
                        };
                    }

                    function getCellsWithIndexMap(indexMap) {
                        let visibleCols = grid.renderContainers.body.renderedColumns;
                        let visibleRows = grid.renderContainers.body.renderedRows;
                        let cellsArray = [];

                        for (let ri = indexMap.row.start; ri <= indexMap.row.end; ri++) {
                            let currentRow = visibleRows[ri];
                            for (let ci = indexMap.col.start; ci <= indexMap.col.end; ci++) {
                                let currentCol = visibleCols[ci];
                                let cellElem = getCellElem(currentCol, ri);

                                if (cellElem) {
                                    cellsArray.push({
                                        row: currentRow,
                                        col: currentCol,
                                        elem: cellElem
                                    });
                                }
                            }
                        }

                        return cellsArray;
                    }

                    function cellIsSelected(row, col) {
                        if (row && col) {
                            return _scope.ugCustomSelect.cellMap[row.uid] !== undefined && _scope.ugCustomSelect.cellMap[row.uid].indexOf(col.uid) > -1;
                        }
                        return false;
                    }

                    function getCellElem(col, rowIndex) {
                        return (col && col.uid && typeof rowIndex == 'number') ? angular.element('#' + grid.id + '-' + rowIndex + '-' + col.uid + '-cell') : null;
                    }

                    function createCopyData(cells, numCols) {
                        let copyData = '';

                        for (let i = 0; i < cells.length; i++) {
                            let currentCell = cells[i];
                            let cellValue = grid.getCellDisplayValue(currentCell.row, currentCell.col);

                            copyData += cellValue;

                            if ((i + 1) % numCols === 0 && i !== cells.length - 1) {
                                copyData += '\n';
                            } else if (i !== cells.length - 1) {
                                copyData += '\t';
                            }
                        }

                        return copyData;
                    }
                }
            };
        }
    };
}

configExcel.$inject = ["$provide"];
export function configExcel($provide) {
    $provide.decorator('uiGridEditService', uiGridEditService);
};

uiGridEditService.$inject = ["$delegate", "uiGridConstants"];
function uiGridEditService($delegate, uiGridConstants) {

    $delegate.isStartEditKey = function (evt) {

        if (evt.keyCode != uiGridConstants.keymap.F2) {
            return false;
        }

        return true;
    };

    return $delegate;

}
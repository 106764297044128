import { element } from 'angular'
import * as config from '../../bootstrap/Config';
import * as Address from '../../communication/Address';

export interface IService {
    hostname: string;
    name: string;
    environment: string;
    version: string;
    coreVersion: string;
}

export class MonitorStatusService {
    private static _instance: MonitorStatusService;
    private $injector: ng.Injectable<any>;
    private $http: ng.IHttpService;
    private $timeout: ng.ITimeoutService;
    private timeout: number;
    private route: string;
    private updating: boolean;
    private initialized: boolean;
    private backgroundEnabled: boolean;
    private serviceStatusList: IService[];
    private monitorUpdateInterval: number;
    private monitorUpdateHandler: ng.IPromise<void>;
    private blockUI: ng.blockUI.BlockUIService;
    private loaded: any;

    constructor() {
        this.$timeout = null;
        this.timeout = 180000; // 3minutes
        this.updating = false;
        this.initialized = false;
        this.serviceStatusList = [];
        this.backgroundEnabled = false;
        this.monitorUpdateInterval = 15000;

        if (config.default.environment === 'prod') {
            this.route = `${Address.monacoAddressProd.MONITOR}/api/v1/monitor/status`;
        } else if (config.default.environment === 'qa') {
            this.route = `${Address.monacoAddressQa.MONITOR}/api/v1/monitor/status`;
        } else { //dev
            this.route = `${Address.monacoAddressLocal.MONITOR}/api/v1/monitor/status`;
        }
    }

    public static get Instance(): MonitorStatusService {
        return this._instance || (this._instance = new this());
    }

    public get $initialized(): boolean {
        return this.initialized;
    }

    public get $serviceStatusList(): IService[] {
        return this.serviceStatusList;
    }

    public async getServiceInfo(serviceName: string): Promise<IService> {
        try {
            this.block();
            await this.loaded.promise; // prevent list reading before is ready
            if (!this.backgroundEnabled) await this.updateServiceStatusList(); //if background update is not enabled, updates services list before searching
            this.unblock();
            return this.serviceStatusList.find(serv => serv.name == serviceName && serv.environment == config.default.environment);
        } catch (ex) {
            throw ex;
        }
    }

    public destruct(): void {
        try {
            this.cancelBackGroundUpdate();
        } catch (ex) {
            throw ex;
        }
    }

    public async init($injector: ng.Injectable<any>, enableBackground: boolean = false, interval?: number): Promise<void> {
        try {
            if (this.initialized) {
                await this.updateServiceStatusList();
                return; //no need to initialize again
            }
            this.$injector = $injector;
            this.$http = $injector.get('$http');
            this.$timeout = $injector.get('$timeout');
            this.blockUI = $injector.get('blockUI');
            const $q = $injector.get('$q');
            this.loaded = $q.defer();

            await this.updateServiceStatusList();

            if (enableBackground) {
                await this.checkAvailableServicesMenu();
                this.setBackgroundUpdate(interval);
                this.backgroundEnabled = true;
            }

            this.initialized = true;

            this.loaded.resolve(true);
        } catch (ex) {
            throw ex;
        }
    }

    public async updateServiceStatusList(): Promise<IService[]> {
        try {
            if (!this.$injector || !this.$http) { //unable to perform request to WBA-MONITOR
                this.serviceStatusList = [];
                return this.serviceStatusList;
            }
            const operation = await this.$http({
                method: 'GET',
                url: this.route,
                cache: false,
                timeout: this.timeout,
            }).catch(err => {
                this.serviceStatusList = []; // WBA-MONITOR offline
            });
            const response = await operation;

            if (response && response['data']['data'] && response['data']['data'].length > 0) {
                this.serviceStatusList = response['data']['data'];
            }
            return this.serviceStatusList;
        } catch (ex) {
            throw ex;
        }
    }

    private async checkAvailableServicesMenu(): Promise<any> {
        try {
            //WBA-MONITOR
            const monitorService = await this.getServiceInfo('WBA-MONITOR');
            const monitorMenuItem = element('#navWba');
            this.applyServiceAvailableClass(monitorMenuItem, monitorService);
            //WBA-FINOP
            const finopService = await this.getServiceInfo('WBA-FINOP');
            const finopMenuItem = element('#navFinop');
            this.applyServiceAvailableClass(finopMenuItem, finopService);
        } catch (ex) {
            throw ex
        }
    }

    private applyServiceAvailableClass(element: JQuery<HTMLElement>, service: IService) {
        if (element) {
            if (service) {
                if (element.hasClass('serviceUnavailable'))
                    element.removeClass('serviceUnavailable');
                element.addClass('serviceAvailable');
            } else {
                if (element.hasClass('serviceAvailable'))
                    element.removeClass('serviceAvailable');
                element.addClass('serviceUnavailable');
            }
        }
    }

    //

    private setBackgroundUpdate(interval?: number): void {
        try {
            if (interval) {
                if (interval < 15000) this.monitorUpdateInterval = 15000;
                else this.monitorUpdateInterval = interval;
            }
            if (this.monitorUpdateHandler) {
                const canceled = this.$timeout.cancel(this.monitorUpdateHandler);
                if (!canceled) throw new Error('Failed to cancel monitor background update');
                this.monitorUpdateHandler = null;
            }
            this.monitorUpdateHandler = this.$timeout(this.handleMonitorUpdate.bind(this), this.monitorUpdateInterval);
        } catch (ex) {
            throw ex;
        }
    }

    private async handleMonitorUpdate(): Promise<void> {
        try {
            if (this.updating) return;
            this.updating = true;
            await this.updateServiceStatusList();
            await this.checkAvailableServicesMenu();
            this.updating = false;
            this.monitorUpdateHandler = this.$timeout(this.handleMonitorUpdate.bind(this), this.monitorUpdateInterval);
        } catch (ex) {
            this.updating = false;
            this.monitorUpdateHandler = this.$timeout(this.handleMonitorUpdate.bind(this), this.monitorUpdateInterval);
        }
    }

    private cancelBackGroundUpdate(): void {
        try {
            if (this.monitorUpdateHandler) {
                const canceled = this.$timeout.cancel(this.monitorUpdateHandler);
                if (!canceled) throw new Error('Failed to cancel monitor background update');
                this.monitorUpdateHandler = null;
            }
        } catch (ex) {
            throw ex;
        }
    }

    private block(): void {
        this.blockUI.start();
    };

    private unblock(): void {
        const self: MonitorStatusService = this;
        this.$timeout(function () {
            self.blockUI.stop();
        });
    };


}

import angular = require("angular");
import { IHttpResponse, isArray } from "angular";
import { fileUploader as FileUploader } from 'angular-file-upload';
import { FormService2 } from '@services/FormService2';
import { IRestService } from '@services/RestService';
import { DataManagerService } from '@services/DataManagerService';
import { BatchItem, BatchViewService } from "@services/BatchViewService";
import { IModalService, IFileReferenceDisabledFields, IFileReferencePreSetFields } from "@services/ModalService";
import { FileReferenceModel } from "@models/interface/operational/FileReferenceModel";
import { Process } from "@models/interface/operational/Process";
import { REFERENCE } from "@models/interface/operational/Process";
import { IUploader, IUploadItem, IFileItem, IFormData, ISuccessItemResponse } from "@models/interface/common/IMonacoUpload";
import { IMonacoController, IMonacoConfig } from "../../common/MonacoInterface";
import { SelectorModel } from "../../common/model/SelectorModel";
import { UploadController } from '../../common/UploadController';
import { ProcessDocument } from "../../common/model/ProcessDocument";
import { StringUtil } from "../../common/util/StringUtil";
import { ReportAutomaticRulesService } from '../../app/services/ReportAutomaticRulesService';
import { OperationalService } from "@services/OperationalService";
import { IReportTemplateReferenceParameters } from "WBA-Model/dist/interface/report/ReportTemplateReferenceParameters";
import { HelperService } from "@services/HelperService";
import { CONVERSION_REFERENCE, PAYMENT_CONDITION, TRANSACTION } from "WBA-Model/dist/interface/finop/NewInvoice";

interface IReportItem {
    CATEGORY: SelectorModel;
    CONFIGURATIONS_TAG: SelectorModel[];
    REPORT_GROUP: SelectorModel;
    REPORT_SPECS: SelectorModel[];
    REPORT_TYPE: SelectorModel;
    TEMPLATE: SelectorModel;
    NAME?: string
    ID: string
}

interface IReferenceRequest {
    processNumber: string,
    invoiceID?: string,
    blNumber?: string,
    url?: string,
}

interface ILanguageModel {
    LANGUAGE: SelectorModel
}

export interface IFiles {
    FILES: IUploadItem[];
}

interface IReferenceFields {
    docSequence: boolean;
    productType: boolean;
    telephone: boolean;
    technicalManager: boolean;
    email: boolean;
}

interface IFileReferenceModalScope extends ng.IScope {
    modalOptions: any;
    modalID: number;
    model: FileReferenceModel;
    newReferences: FileReferenceModel[];
    file: FileReferenceModel;
    fileModel: IFiles;
    uploader: IUploader;
    uploadDisabled: boolean;
    referenceParameters: IReportTemplateReferenceParameters;
    process: Process[];
    reference: string;
    processDocument: ProcessDocument;
    fileOnly: boolean;
    preSetFields: IFileReferencePreSetFields;
    disabledFields: IFileReferenceDisabledFields;
    isEdit?: boolean;
    categoryName: Array<string>;
    transaction: TRANSACTION[];
    paymentCondition: PAYMENT_CONDITION;
    conversionReference: CONVERSION_REFERENCE;
    blNumber: { number: string, PROCESS_NUMBER: string }[];
    invoiceID: { ID: string, PROCESS_NUMBER: string }[];
    documentTypeList: Array<SelectorModel>;
    fileGroupList: Array<SelectorModel>;
    fileSpecsList: Array<SelectorModel>;
    reportTemplateList: Array<IReportItem>;
    templateList: SelectorModel[];
    confGenericList: Array<any>;
    confGenericByCategory: SelectorModel[];
    showConfiguration: boolean;
    referenceFields: IReferenceFields;
    showSpecificParams: boolean;
    showFields: (item: any) => void;
    validateConfiguration: (item: SelectorModel) => void;
    updateTemplateType: () => void;
    viewTemplate: () => Promise<void>;
    newFileReference: () => Promise<void>;
    editFileReference: () => Promise<void>;
    applyOkModal: (result?: REFERENCE[] | true) => void;
    getTemplateReport: (calledOnInit?: boolean, showWarning?: boolean) => void;
    cleanModalForm: () => void;
    openLink: (file: FileReferenceModel) => void;
    chooseIcon: (file: FileReferenceModel) => string;
    removeUpload: (model: IUploadItem) => Promise<boolean>;
    referenceTemplate: Array<any>
    languageList: SelectorModel[];
    languageModel: ILanguageModel;
    productTypeList: SelectorModel[];
}

export class FileReferenceModalController implements IMonacoController {
    public static $inject: string[] = ['$scope', '$injector'];
    private $scope: IFileReferenceModalScope;
    private $injector: ng.Injectable<any>;
    private $q: ng.IQService;
    private $http: ng.IHttpService;
    private config: IMonacoConfig;
    private formService: FormService2;
    private RestService: IRestService;
    private ModalService: IModalService;
    private referenceRequest: IReferenceRequest[];
    private fileUploader: FileUploader;
    private dataManagerService: DataManagerService;
    private operationalService: OperationalService;
    private helperService: HelperService;

    constructor($scope, $injector: ng.Injectable<any>) {
        this.$scope = $scope;
        this.$injector = $injector;
        this.$q = $injector.get('$q');
        this.$http = $injector.get('$http');
        this.config = $injector.get('config');
        this.RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');
        this.formService = new FormService2($injector, $scope);
        this.fileUploader = $injector.get('FileUploader');
        this.dataManagerService = $injector.get('DataManagerService');
        this.operationalService = $injector.get('OperationalService');
        this.helperService = $injector.get('HelperService');
        this.referenceRequest = [];
    }

    async $onInit() {
        try {
            if (!this.$scope.process) throw new Error('Failed to get process info');

            this.$scope.uploader = this.getFileUploaderDefault();
            this.formService.block();

            this.initModel();

            this.formService.initStandAlone('modalForm');
            this.formService.loadRegisterForm(true);

            const categoryNames = this.$scope.categoryName;
            const multipleGeneric = await this.getMultipleGenericValuesByType(categoryNames);

            const result = await this.$q.all([
                this.getGenericType('document_type'),
                this.getGenericType('file_group'),
                this.getGenericType('file_specs'),
                this.getGenericType('language'),
                this.getGenericType('product_type'),
            ]);
            this.$scope.documentTypeList = result[0];
            this.$scope.fileGroupList = result[1];
            this.$scope.fileSpecsList = result[2];
            this.$scope.languageList = result[3];
            this.$scope.productTypeList = result[4];
            this.$scope.confGenericList = multipleGeneric;
            this.$scope.showConfiguration = false;
            this.$scope.referenceFields = {
                docSequence: false,
                productType: false,
                telephone: false,
                technicalManager: false,
                email: false
            }

            if (!this.$scope.fileModel) this.$scope.fileModel = <IFiles>{ FILES: [] };
            this.$scope.newReferences = [];

            const hasAllGenericDependencies: boolean = !!this.$scope.documentTypeList && !!this.$scope.fileGroupList && !!this.$scope.fileSpecsList;
            if (!hasAllGenericDependencies) throw new Error('Failed to get file reference dependencies');

            this.getTemplateReport(true, false);

            this.registerScopeFunctions();

            for (const process of this.$scope.process) {
                const blNumber = (this.$scope.blNumber) ? this.$scope.blNumber.find(x => x.PROCESS_NUMBER === process.PROCESS_NUMBER) : null;
                const invoiceIDs = (this.$scope.invoiceID) ? this.$scope.invoiceID.filter(x => x.PROCESS_NUMBER === process.PROCESS_NUMBER) : [];

                if (invoiceIDs.length > 1) {
                    for (const invoiceID of invoiceIDs) {
                        this.referenceRequest.push({ processNumber: process.PROCESS_NUMBER, blNumber: (blNumber) ? blNumber.number : null, invoiceID: invoiceID.ID, url: null });
                    }
                } else
                    this.referenceRequest.push({ processNumber: process.PROCESS_NUMBER, blNumber: (blNumber) ? blNumber.number : null, invoiceID: (invoiceIDs[0]) ? invoiceIDs[0].ID : null, url: null });
            }

            UploadController.Instance.init(this.$injector, {
                scope: this.$scope,
                requests: this.$scope.process.map(x => ({ processNumber: x.PROCESS_NUMBER, customerId: x.CUSTOMER.ID, customerName: x.CUSTOMER.NAME })),
            });

            this.formService.unblock();

        } catch (ex) {
            this.formService.handleError(ex);
            this.applyOkModal();
        }
    }

    $onDestroy() { }

    private registerScopeFunctions(): void {
        try {
            this.$scope.updateTemplateType = () => {
                return this.updateTemplateType();
            }

            this.$scope.viewTemplate = () => {
                return this.viewTemplate();
            }

            this.$scope.newFileReference = () => {
                return this.newFileReference();
            }

            this.$scope.editFileReference = async () => {
                this.editFileReference();
            }

            this.$scope.applyOkModal = (result?: REFERENCE[] | true) => {
                return this.applyOkModal(result);
            }

            this.$scope.validateConfiguration = (item: SelectorModel) => {
                return this.validateConfiguration(item);
            }

            this.$scope.showFields = (item: any) => {
                return this.showFields(item);
            }

            this.$scope.getTemplateReport = (calledOnInit?: boolean, showWarning?: boolean) => {
                this.getTemplateReport(calledOnInit, showWarning);
            }

            this.$scope.cleanModalForm = () => {
                this.cleanModalForm();
            }

            this.$scope.openLink = (file: FileReferenceModel) => {
                this.openLink(file);
            }

            this.$scope.chooseIcon = (file: FileReferenceModel) => {
                return this.chooseIcon(file);
            }

            this.$scope.removeUpload = (model: IUploadItem): Promise<boolean> => {
                return this.removeUpload(model);
            }

        } catch (ex) {
            throw ex;
        }
    }

    private initModel(): void {

        if (this.$scope.file) this.$scope.model = this.$scope.file;
        else {
            this.$scope.model = {
                REFERENCE_ID: null,
                ID_CONSOLIDATED: null,
                SOURCE: null,
                TYPE: null,
                MAIN_FILE: (this.$scope.preSetFields && this.$scope.preSetFields.mainFile) ? this.$scope.preSetFields.mainFile : false,
                NAME: (this.$scope.preSetFields && this.$scope.preSetFields.name) ? this.$scope.preSetFields.name : null,
                LINK: null,
                DOCUMENT_VALUE: (this.$scope.preSetFields && this.$scope.preSetFields.documentValue) ? this.$scope.preSetFields.documentValue : this.$scope.reference ? this.$scope.reference : null,
                DOCUMENT_TYPE: (this.$scope.preSetFields && this.$scope.preSetFields.documentType) ? this.$scope.preSetFields.documentType : null,
                TEMPLATE_TYPE: null,
                FILE_GROUP: (this.$scope.preSetFields && this.$scope.preSetFields.fileGroup) ? this.$scope.preSetFields.fileGroup : null,
                FILE_SPECS: (this.$scope.preSetFields && this.$scope.preSetFields.fileSpecs) ? this.$scope.preSetFields.fileSpecs : null,
                INSERT_DATE: null,
                USER_REFERENCE: (this.$scope['user']) ? this.$scope['user']._id : null,
            };
        }

        this.$scope.referenceParameters = {
            CONFIGURATIONS_TAG: null,
            DOCUMENT_SEQUENCE: null,
            TEMPLATE_FIELD_PARAMS: null,
        }
        this.$scope.languageModel = { LANGUAGE: null }
    }

    private cleanModalForm() {

        this.$scope.referenceParameters.DOCUMENT_SEQUENCE = null;
        this.$scope.referenceParameters.CONFIGURATIONS_TAG = null;
        this.$scope.referenceParameters.TEMPLATE_FIELD_PARAMS = null;
        this.$scope.languageModel.LANGUAGE = null;
        this.$scope.model.TEMPLATE_TYPE = null;
        this.$scope.templateList = null;
        this.$scope.model.FILE_GROUP = null;
        this.$scope.model.FILE_SPECS = null;
        this.$scope.model.NAME = null;
        this.$scope.$applyAsync();
    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'allog_file_integration_service', formName: "processReference", folder: "processReference" };
            return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData], onSuccessItem: (item: IFileItem, response: ISuccessItemResponse, files: IFiles) => this.onSuccessItem(item, response, this.$scope.fileModel) });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    getFisFilesGenericRoute(): string {
        const baseRoute = '/fis/filesGeneric';
        const uploadRoute = this.config.fisUrl + baseRoute;
        return uploadRoute;
    }

    private onSuccessItem(item: IFileItem, response: ISuccessItemResponse, files: IFiles): void {
        try {
            if (!item) throw Error('item parameter is null');
            if (!response) throw Error('response parameter is null');

            if (!this.$scope.newReferences) this.$scope.newReferences = [];

            if (response.data && response.data.length > 0) {
                for (let dataItem of response.data) {
                    const reference = <REFERENCE>{};

                    reference.REFERENCE_ID = null;
                    reference.ID_CONSOLIDATED = null;
                    reference.SOURCE = "MONACO";
                    reference.TYPE = null;
                    reference.MAIN_FILE = false;
                    reference.DOCUMENT_TYPE = null;
                    reference.DOCUMENT_VALUE = null;
                    reference.TEMPLATE_TYPE = null;
                    reference.FILE_GROUP = null;
                    reference.FILE_SPECS = null;
                    reference.INSERT_DATE = null;
                    reference.USER_REFERENCE = this.$scope['user']._id;
                    reference.NAME = dataItem.fileName;
                    reference.LINK = dataItem.fileUrl;

                    this.$scope.newReferences.push(reference);

                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeUpload(model: IUploadItem): Promise<boolean> {
        let result = false;
        try {
            if (!model) throw Error('item parameter is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.$scope.newReferences && this.$scope.newReferences.length > 0) {
                const index = this.$scope.newReferences.findIndex(file => { return file.NAME == model.FILE_NAME });
                if (index > -1) {
                    this.$scope.newReferences.splice(index, 1);
                    result = true;
                }
            }
        } catch (ex) {
            result = false;
            throw (ex);
        } finally {
            return result;
        }
    }

    private openLink(file: FileReferenceModel) {
        try {
            if (!file) return;

            if (file.LINK && file.LINK.startsWith("https://")) {
                window.open(file.LINK, '_blank');
            }
            else {
                this.formService.notifyWarning("Acesso ao arquivo não disponível");
            }
        } catch (ex) {
            throw (ex);
        }
    }

    private chooseIcon(file: FileReferenceModel): string {
        try {
            if (!file) return;

            const extensionRemoveList: string[] = (file && file.NAME) ? file.NAME.split('.') : null;
            const extension: string = (extensionRemoveList && extensionRemoveList.length > 0) ? extensionRemoveList[extensionRemoveList.length - 1] : null;

            switch (extension) {
                case "pdf": return "fa fa-file-pdf-o text-danger link-icon";
                case "xls": return "fa fa-file-excel-o text-green link-icon";
                case "xlsx": return "fa fa-file-excel-o text-green link-icon";
                case "doc": return "fa fa-file-word-o text-blue link-icon";
                case "docx": return "fa fa-file-word-o text-blue link-icon";
                case "docx": return "fa fa-file-word-o text-blue link-icon";
                case "zip": return "fa fa-file-archive-o text-orange link-icon";
                case "eml": return "fa fa-envelope-o text-yellow link-icon";

                default: return "fa fa-file text-grey link-icon";
            }
        } catch (ex) {
            throw (ex);
        }
    }

    private async getTemplateReport(calledOnInit?: boolean, showWarning?: boolean) {
        if (this.$scope.model.DOCUMENT_TYPE || this.$scope.documentTypeList) {
            const data = [];
            const processData = (isArray(this.$scope.process)) ? this.$scope.process : [this.$scope.process];
            const templateListRequests = processData.map(x => (this.getTemplateList({ process: x })))
            const templateListResult = await this.$q.all(templateListRequests);

            if (angular.equals(templateListResult, [[]])) {
                if (!calledOnInit) this.cleanModalForm();

                if (showWarning) this.formService.notifyWarning(`Nenhum template cadastrado para o tipo de documento`);

                this.$scope.$applyAsync();

                return;
            }

            for (let i = 0; i < templateListResult.length; i++) {
                for (let j of templateListResult[i]) {
                    data.push({
                        CATEGORY: j.CATEGORY, CONFIGURATIONS_TAG: j.TAG, REPORT_GROUP: j.GROUP,
                        REPORT_SPECS: j.SPEC, TEMPLATE: j.TEMPLATE, REPORT_TYPE: j.DOCUMENT_TYPE, NAME: j.NAME,
                        ID: j.ID, TRANSACTION: j.TRANSACTION
                    });
                }
            }

            this.$scope.templateList = data.map(x => { return { ID: x.ID, NAME: x.TEMPLATE.NAME, CODE: x.TEMPLATE.CODE, RULE: x.NAME } });
            this.$scope.referenceTemplate = data.map(x => { return { ID: x.TEMPLATE.ID, NAME: x.TEMPLATE.NAME, CODE: x.TEMPLATE.CODE } });
            this.$scope.reportTemplateList = data;
        }
    }

    private applyOkModal(result?: REFERENCE[] | true): void {
        try {
            UploadController.Instance.removeAll();
            this.$scope.modalOptions.ok(result);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getTemplateList(query: { process: Process }) {
        try {
            const DOCUMENT = (this.$scope.model.DOCUMENT_TYPE) ? [this.$scope.model.DOCUMENT_TYPE] : this.$scope.documentTypeList;
            const LANGUAGE = null;
            const reportAutomaticService = new ReportAutomaticRulesService(query.process.PROCESS_NUMBER, DOCUMENT, LANGUAGE, this.dataManagerService);
            let templateList = await reportAutomaticService.getTemplateList();

            // filter
            if (templateList && templateList.length) {
                if (this.$scope.categoryName && this.$scope.categoryName.length)
                    templateList = templateList.filter(template => this.$scope.categoryName.some(categoryName => !template.CATEGORY || (template.CATEGORY && template.CATEGORY.NAME == categoryName)));

                if (this.$scope.transaction && this.$scope.transaction.length) {
                    const templateListTransactions = templateList.filter(template => this.$scope.transaction.some(transactionId => template.TRANSACTION && template.TRANSACTION.find(transaction => transaction.ID == transactionId.ID)));
                    if (templateListTransactions.length) templateList = templateListTransactions;
                }
                if (this.$scope.paymentCondition) {
                    const templateListPaymentConditions = templateList.filter(template => !template.PAYMENT_CONDITION || (template.PAYMENT_CONDITION && template.PAYMENT_CONDITION.find(condition => condition.ID == this.$scope.paymentCondition.ID)));
                    if (templateListPaymentConditions.length) templateList = templateListPaymentConditions;
                }
                if (this.$scope.conversionReference) {
                    const templateListconversionReferences = templateList.filter(template => !template.CONVERSION_REFERENCE || (template.CONVERSION_REFERENCE && template.CONVERSION_REFERENCE.find(conversionReference => conversionReference.ID == this.$scope.conversionReference.ID)));
                    if (templateListconversionReferences.length) templateList = templateListconversionReferences;
                }

            }
            return templateList;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getGenericType(identifier: string) {
        try {
            const { data: generic } = await this.helperService.get(`/generic/value/${identifier}`, null, 10000);
            return generic && generic.data ? generic.data : [];
        } catch (ex) {
            throw ex;
        }
    }

    private async getMultipleGenericValuesByType(genericTypes: string[]): Promise<SelectorModel[]> {
        try {
            if (!genericTypes) return [];
            const formatGeneric = genericTypes.map(item => StringUtil.formatString(item));

            let resultData = null;
            const rc = await this.helperService.post('/generic/value', { types: formatGeneric }, 10000);
            if (rc.status === 200 && rc.data && rc.data.data) {
                const result = rc.data.data;
                resultData = result.filter(item => item.GENERICS != null);
            }

            return resultData;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private filterListByCategory(category: string): SelectorModel[] {
        try {
            if (!category) return [];
            const genericList = this.$scope.confGenericList;
            let genericByCategory: SelectorModel[] = [];

            if (genericList) {
                const genericFiltered = genericList.filter(item => StringUtil.formatString(item.IDENTIFIER) === StringUtil.formatString(category));

                for (const genericItem of genericFiltered) {
                    genericItem.GENERICS.map(item => {
                        genericByCategory.push({
                            "ID": item.ID,
                            "NAME": item.NAME
                        });
                    })
                }
            }

            return genericByCategory;
        } catch (ex) {
            throw ex;
        }
    }

    private updateTemplateType(): void {
        try {
            UploadController.Instance.removeAll();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private validateConfiguration(item: SelectorModel): void {
        try {
            if (!item) {
                this.initModel();
                this.$scope.confGenericByCategory = null;
                this.$scope.showConfiguration = false;
                return
            }

            if (this.$scope.reportTemplateList && this.$scope.reportTemplateList.length > 0) {
                this.$scope.confGenericByCategory = [];

                const rule = this.$scope.reportTemplateList.find(x => x.ID === item.ID);
                if (rule) {
                    this.$scope.referenceParameters.DOCUMENT_SEQUENCE = null;
                    this.$scope.referenceParameters.CONFIGURATIONS_TAG = Object.assign([], rule.CONFIGURATIONS_TAG);
                    this.$scope.model.DOCUMENT_TYPE = rule.REPORT_TYPE;
                    this.$scope.model.FILE_GROUP = rule.REPORT_GROUP;
                    this.$scope.model.FILE_SPECS = rule.REPORT_SPECS;
                    this.$scope.model.NAME = (rule.NAME) ? rule.NAME : ''

                    const categoryName = (rule.CATEGORY && rule.CATEGORY.NAME) ? rule.CATEGORY.NAME : '';
                    const tagList = this.filterListByCategory(categoryName);

                    this.$scope.confGenericByCategory = tagList
                    this.$scope.showConfiguration = (tagList) ? true : false;

                    this.formService.validateForm();

                    this.$scope.$applyAsync();
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private showFields(selectedItem: SelectorModel[]) {
        try {
            if (selectedItem) {
                const showSequence = (selectedItem.some(item => item.NAME.toLocaleLowerCase() === 'sequencia' || item.NAME.toLocaleLowerCase() === 'sequência') ? true : false);
                this.$scope.referenceFields.docSequence = showSequence;

                const showProductType = (selectedItem.some(item => item.NAME.toLocaleLowerCase() === 'classe do produto') ? true : false);
                this.$scope.referenceFields.productType = showProductType;

                const showEmail = (selectedItem.some(item => item.NAME.toLocaleLowerCase() === 'e-mail') ? true : false);
                this.$scope.referenceFields.email = showEmail;

                const showTechnicalManager = (selectedItem.some(item => item.NAME.toLocaleLowerCase() === 'responsável técnico') ? true : false);
                this.$scope.referenceFields.technicalManager = showTechnicalManager;

                const showTelephone = (selectedItem.some(item => item.NAME.toLocaleLowerCase() === 'telefone') ? true : false);
                this.$scope.referenceFields.telephone = showTelephone;

                this.$scope.$applyAsync();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private renderProcessTemplate(templateName: string, processNumber: string, category: string, blNumber: string, invoiceID: string, invoiceReferences: string, referenceParameters: IReportTemplateReferenceParameters) {
        try {
            const timeout = 120000;
            const request = {
                templateName: templateName,
                processNumber: processNumber,
                category: category,
                blNumber: blNumber,
                invoiceID: invoiceID,
                invoiceReferences: invoiceReferences,
                referenceParameters: referenceParameters,
                timeout: timeout
            };

            return this.dataManagerService.post('/reportTemplate/render/process', request, timeout);
        } catch (ex) {
            throw ex;
        }
    }

    private async viewTemplate(): Promise<void> {

        try {
            const templateName = (this.$scope.model.TEMPLATE_TYPE) ? this.$scope.model.TEMPLATE_TYPE.NAME : null;
            const templateCategory = (this.$scope.model.TEMPLATE_TYPE) ? this.$scope.model.TEMPLATE_TYPE.CODE : null;
            const templateReferences = (this.$scope.model.DOCUMENT_VALUE) ? this.$scope.model.DOCUMENT_VALUE : null;

            if (!templateName) return this.formService.handleError('templateName is empty');

            this.formService.block();

            const templatesFileInfos: { fileName: string, buffer: Buffer }[] = [];
            for (const request of this.referenceRequest) {

                const processNumber = request.processNumber;
                if (!processNumber) return this.formService.handleError('processNumber is empty');

                const result = await this.renderProcessTemplate(templateName, processNumber, templateCategory, request.blNumber, request.invoiceID, templateReferences, this.$scope.referenceParameters);
                if (!result || !result.data || !result.data['data']) return this.formService.handleError(`Failed to render template: ${templateName}`);

                templatesFileInfos.push({ fileName: `${processNumber}_${templateName}.pdf`, buffer: result.data['data']['data'] });
            }

            for (const fileInfo of templatesFileInfos) {
                const file = new File([new Uint8Array(fileInfo.buffer)], fileInfo.fileName, { type: "application/pdf" });
                const objectUrl = window.URL.createObjectURL(file);

                const newTab = window.open(objectUrl, '_blank');
                if (!newTab) return this.formService.handleWarning('Pop-up de impressão foi bloqueado, favor desbloquear');
            }

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async newFileReference(): Promise<void> {
        try {
            if (!this.formService.checkPendingFields()) return;

            this.formService.block();
            let categoryTemplate = null;

            if (this.$scope.newReferences && this.$scope.newReferences.length > 0) {
                for (const reference of this.$scope.newReferences) {
                    if (this.$scope.model.TEMPLATE_TYPE) {
                        reference.TYPE = 'TEMPLATE';
                        categoryTemplate = this.$scope.model.TEMPLATE_TYPE.CODE;
                    }
                    else reference.TYPE = 'REFERENCE';

                    reference.DOCUMENT_TYPE = this.$scope.model.DOCUMENT_TYPE;
                    reference.DOCUMENT_VALUE = this.$scope.model.DOCUMENT_VALUE;
                    reference.FILE_GROUP = this.$scope.model.FILE_GROUP;
                    reference.FILE_SPECS = this.$scope.model.FILE_SPECS;
                }

            } else {
                //there isn't upload
                const reference = <REFERENCE>{};
                reference.REFERENCE_ID = null;
                reference.SOURCE = "MONACO";
                reference.TYPE = (this.$scope.model.TEMPLATE_TYPE) ? 'TEMPLATE' : 'REFERENCE';
                reference.MAIN_FILE = this.$scope.model.MAIN_FILE;
                reference.DOCUMENT_TYPE = this.$scope.model.DOCUMENT_TYPE;
                reference.DOCUMENT_VALUE = this.$scope.model.DOCUMENT_VALUE;
                reference.TEMPLATE_TYPE = (this.$scope.model.TEMPLATE_TYPE) ? this.$scope.model.TEMPLATE_TYPE : null;
                reference.FILE_GROUP = this.$scope.model.FILE_GROUP;
                reference.FILE_SPECS = this.$scope.model.FILE_SPECS;
                reference.INSERT_DATE = null;
                reference.USER_REFERENCE = this.$scope['user']._id;
                reference.NAME = this.$scope.model.NAME;
                reference.LINK = null;
                reference.ID_CONSOLIDATED = null;
                if (reference.TYPE == 'TEMPLATE' && reference.TEMPLATE_TYPE) categoryTemplate = this.$scope.model.TEMPLATE_TYPE.CODE;

                this.$scope.newReferences.push(reference);
            }

            // MULTI-REQUEST HANDLING
            const requests = this.referenceRequest.map(x => {
                const referenceModel = angular.copy(this.$scope.model);
                if (x.url) referenceModel.LINK = x.url; // link by process directory
                return {
                    processNumber: x.processNumber,
                    referenceList: this.$scope.newReferences,
                    processDocumentNumber: (this.$scope.processDocument) ? this.$scope.processDocument.PROCESS_DOCUMENT_NUMBER : null,
                    category: (categoryTemplate) ? categoryTemplate : null,
                    blNumber: x.blNumber,
                    invoiceID: x.invoiceID,
                    timeout: 300000,
                    referenceParameters: this.$scope.referenceParameters
                }
            });

            const isSingleRequest = (requests.length === 1);
            const failedbatchItems: BatchItem[] = [];
            for (const request of requests) {
                let err = null;
                try {
                    const result = await this.$http<IHttpResponse<REFERENCE[]>>({
                        method: 'post',
                        url: this.operationalService.$route + '/processReference/add',
                        data: request,
                        cache: false,
                        timeout: 300000
                    });
                    if (isSingleRequest) {
                        if (result.status === 200 && result.data.data)
                            this.applyOkModal(result.data.data);
                        else
                            this.formService.notifyError('<b>Falha</b> ao adicionar referência');
                    }
                } catch (ex) {
                    err = ex;
                    if (isSingleRequest) throw ex;
                } finally {
                    if (err) failedbatchItems.push({ identifier: { PROCESS_NUMBER: request.processNumber }, failed: { error: err } });
                }
            }
            if (!isSingleRequest) {
                if (failedbatchItems.length > 0) {
                    const batchService = new BatchViewService(this.$injector, this.$scope);
                    this.formService.handleWarning(`Houveram problemas ao adicionar referências. <b>Clique nesta mensagem para visualizar os erros</b>`, () => { return batchService.formatBatchReturnAndInvokeModal(failedbatchItems); });
                } else
                    this.applyOkModal(true);
            }
            this.formService.unblock();

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async editFileReference(): Promise<void> {
        try {
            if (!this.formService.checkPendingFields()) return;

            this.formService.block();

            try {
                const result = await this.$http<IHttpResponse<REFERENCE[]>>({
                    method: 'post',
                    url: this.operationalService.$route + '/processReference/edit',
                    data: { processNumber: this.$scope.process && this.$scope.process[0] ? this.$scope.process[0].PROCESS_NUMBER : null, reference: this.$scope.model },
                    cache: false,
                    timeout: 300000
                });

                if (result.status === 200 && result.data.data)
                    this.applyOkModal([this.$scope.model]);
                else
                    this.formService.notifyError('<b>Falha</b> ao editar referência');

            } catch (ex) {
                throw ex;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }
}

import * as angular from "angular";
import { StringUtil } from "../../common/util/StringUtil";
import { IGridFormController, IGridFormServiceScope, GridFormService } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IColumnDef, IGridOptions } from "ui-grid";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { IMonacoColumnDef } from "@services/GridService2";
import { EOperation, EProviderTypeId, EDirectionId } from "@enums/GenericData";
import { ITariffDetDemExchangeData, ITariffDetDemModel } from "WBA-Model/dist/interface/product/TariffDetDemModel";
import { ITariffDetDemHistoryModel } from "WBA-Model/dist/interface/product/TariffDetDemHistoryModel";
import { IProviderSelector } from "@models/interface/product/ProviderModel";
import { ISessionService } from "@services/SessionService";
import { BrowserTitle } from "../../common/BrowserTitle";
import { IFloatingMenu } from "../../common/interface/IFloatingMenu";
import { ILinkParameter } from "../../common/model/ModelParameter";
import { IUploader, ISuccessItemResponse, IUploadItem, IFileItem, IFormData } from '@models/interface/common/IMonacoUpload';
import { fileUploader as FileUploader } from 'angular-file-upload';
import { IMoveTypeModel, IParam } from "@models/interface/product/MoveTypeModel";

interface ITariffDetDemHistoryScope extends IGridFormServiceScope {
    model: ITariffDetDemHistoryModel;
    sessionService: ISessionService;
    gridOptions: IGridOptions;
    menuFloating: IFloatingMenu;
    uploader: IUploader;
    moveTypeFullList: IMoveTypeModel[];
    collapseHeader: (elementId: string, state?: string, navigate?: boolean) => void;
    generateConcatenatedTariff: (tariffIndex: number) => void;
    viewTariffDetDemHistory: (tariffDetDem: ITariffDetDemModel, idTariffDetDem?: number) => Promise<void>;
    getGridViewProvider: (provider: IProviderSelector) => string;
    goToNetwork: () => void;
    goToAccount: () => void;
    goToTariffDetDem: (id: number) => void;
    collapseTariffDetDem: (tariffDetDemIndex: number) => void;
    goToServiceLevel: (id: number) => void;
    goToServiceType: (id: number) => void;
    goToRoutingPoint: (ids: string) => void;
    goToCommodity: (id: number) => void;
    goToProvider: () => void;
    goToLegalPerson: (ids: string) => void;
    goToTablePerDiem: (id: number) => void;
    copyToValidTariffs: (tariffDetDem: ITariffDetDemModel) => Promise<void>;
}

export class TariffDetDemHistoryRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: ITariffDetDemHistoryScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private collapseState: string;
    private formName: string;
    private gridName: string;
    private $timeout: ng.ITimeoutService;
    private fileUploader: FileUploader;

    constructor($injector: ng.Injectable<any>, $scope: ITariffDetDemHistoryScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$scope.additionalIndexSelectorValidity = ['$parentIndex'];
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.formName = 'tariffDetDemHistory';
        this.gridName = 'GRID_TARIFF_DET_DEM_HISTORY'
        this.collapseState = "hide";
        this.$timeout = $injector.get('$timeout');
        this.fileUploader = $injector.get('FileUploader');
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    getFisFilesGenericRoute(): string {
        const baseRoute = '/fis/filesGeneric';
        const uploadRoute = this.config.fisUrl + baseRoute;
        return uploadRoute;
    }

    async $onInit(): Promise<void> {
        try {
            this.$scope.uploader = this.getFileUploaderDefault();
            this.$baseUrl = this.getUrlProduct();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.initForm(this, 'form', this.formName, 'PRODUCT.DetDem_TARIFF', false);
            await this.initGrid(this.gridName, '/tariffDetDemHistory/list', true, true, null, true, true);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initGridColumns(columns: string[]): IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<a ng-click="grid.appScope.viewTariffDetDemHistory(row.entity)" class="text-info v-middle" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true"><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyToValidTariffs(row.entity)" class="text-orange v-middle copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true"><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: 75,
            cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${copy}</div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };

        gridColumns.addColumn(colActions);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) {
            column.filter = column.filter
                ? column.filter
                : { condition: this.$gridService.filterSelectObject };

            gridColumns.addColumn(column)
        }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colSituation: IMonacoColumnDef = { name: "SITUATION.NAME", displayName: "GENERAL.SITUATION", width: 150 };
            const colConcatenated: IMonacoColumnDef = { name: "CONCATENATED", displayName: "GENERAL.CONCATENATED_COMPLEMENT", width: 250 };
            const colVersion: IMonacoColumnDef = { name: "VERSION", displayName: "GENERAL.VERSION", width: 100 };
            const colContract: IMonacoColumnDef = { name: "CONTRACT.NAME", displayName: "PRODUCT.DetDem_CONTRACT", width: 150 };
            const colPaymentNature: IMonacoColumnDef = { name: "PAYMENT_NATURE.NAME", displayName: "BASIC_DATA.TRANSACTION", width: 150 };
            const colContractType: IMonacoColumnDef = { name: "CONTRACT_TYPE.NAME", displayName: "PRODUCT.DetDem_CONTRACT_TYPE", width: 150 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 100 };
            const colUsedOffer: IMonacoColumnDef = { name: "TARIFF_DetDem_CONTRACT_USED_OFFER.OFFER_CODE_EXHIBITION", displayName: "BASIC_DATA.OFFER", width: 160, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TARIFF_DetDem_CONTRACT_USED_OFFER, null, "OFFER_CODE_EXHIBITION")}}</div>' };
            const colTypeCargo: IMonacoColumnDef = { name: "TYPE_CARGO.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 150 };
            const colProvider: IMonacoColumnDef = { name: "PROVIDER.CODE", displayName: "BASIC_DATA.PROVIDER", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getGridViewProvider(row.entity.PROVIDER)}}</div>' };
            const colAccount: IMonacoColumnDef = { name: "ACCOUNTS.NAME", displayName: "BASIC_DATA.CLIENT", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNTS, null, "NAME")}}</div>' };
            const colAccountFantasyName: IMonacoColumnDef = { name: "ACCOUNTS.FANTASY_NAME", displayName: "GENERAL.CUSTOMER_COMMERCIAL_NAME", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNTS, null, "FANTASY_NAME")}}</div>' };
            const colNetwork: IMonacoColumnDef = { name: "NETWORKS.NAME", displayName: "BASIC_DATA.EXTERNAL_AGENT_NETWORK", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.NETWORKS, null, "NAME")}}</div>' };
            const colEvent: IMonacoColumnDef = { name: "VALIDITY_EVENT.NAME", displayName: "GENERAL.VALIDITY_BASIS", width: 100 };
            const colCommoditySection: IMonacoColumnDef = { name: "COMMODITY.SECTION.NAME", displayName: "GENERAL.COMMODITY_GROUP_CONDITION", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.COMMODITY, "SECTION", null, false, true)}}</div>' };
            const colCommodityCondition: IMonacoColumnDef = { name: "COMMODITY.CONDITION.NAME", displayName: "GENERAL.COMMODITY_CONDITION", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.COMMODITY, "CONDITION", null, false, true)}}</div>' };
            const colCommodityException: IMonacoColumnDef = { name: "COMMODITY.EXCEPTION.NAME", displayName: "GENERAL.COMMODITY_EXCEPTION", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.COMMODITY, "EXCEPTION", null, false, true)}}</div>' };
            const colValidityStart: IMonacoColumnDef = { name: "DISPLAY_VALIDITY_START", displayName: "PRODUCT.VALIDITY_START", width: 150, cellFilter: "simpleDate" };
            const colValidityEnd: IMonacoColumnDef = { name: "DISPLAY_VALIDITY_END", displayName: "PRODUCT.VALIDITY_END", width: 150, cellFilter: "simpleDate" };
            const colScore: IMonacoColumnDef = { name: "SCORE", displayName: "BASIC_DATA.SCORE", width: 100 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 90, cellFilter: "YesOrNo" };
            const colValidityExpired: IMonacoColumnDef = { name: "VALIDITY_EXPIRED", displayName: "GENERAL.EXPIRED", width: 150, cellFilter: "YesOrNo" };
            const colMoveType: IMonacoColumnDef = { name: "MOVE_TYPE_REPL", displayName: "GENERAL.MENU.MOVE_TYPE", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.MOVE_TYPE_REPL, null, "CODE")}}</div>' };
            const colTradeGroupOriginRepl: IMonacoColumnDef = { name: "TRADE_GROUP_ORIGIN_REPL", displayName: "BASIC_DATA.ORIGIN_TRADE_LANE", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TRADE_GROUP_ORIGIN_REPL)}}</div>' };
            const colTradeGroupDestinationRepl: IMonacoColumnDef = { name: "TRADE_GROUP_DESTINATION_REPL", displayName: "BASIC_DATA.DESTINATION_TRADE_LANE", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TRADE_GROUP_DESTINATION_REPL)}}</div>' };
            const colRoutingPointOrigin: IMonacoColumnDef = { name: "ROUTING_POINT_ORIGIN_REPL", displayName: "BASIC_DATA.ORIGIN", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ROUTING_POINT_ORIGIN_REPL, null, "CODE")}}</div>' };
            const colRoutingPointDestination: IMonacoColumnDef = { name: "ROUTING_POINT_DESTINATION_REPL", displayName: "BASIC_DATA.DESTINATION", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ROUTING_POINT_DESTINATION_REPL, null, "CODE")}}</div>' };
            const colCountryOrigin: IMonacoColumnDef = { name: "COUNTRY_ORIGIN_REPL", displayName: "BASIC_DATA.ORIGIN_COUNTRY", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.COUNTRY_ORIGIN_REPL, null, "CODE")}}</div>' };
            const colCountryDestination: IMonacoColumnDef = { name: "COUNTRY_DESTINATION_REPL", displayName: "BASIC_DATA.DESTINATION_COUNTRY", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.COUNTRY_DESTINATION_REPL, null, "CODE")}}</div>' };
            const colEquipWeightRange: IMonacoColumnDef = { name: "EQUIP_WEIGHT_RANGE_VEHICLE_REPL", displayName: "PRODUCT.BASIS_COMPLEMENT", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.EQUIP_WEIGHT_RANGE_VEHICLE_REPL)}}</div>' };
            const colChargeName: IMonacoColumnDef = { name: "CHARGE_NAME_EXHIBITION_REPL", displayName: "BASIC_DATA.CHARGE", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.CHARGE_NAME_EXHIBITION_REPL, null, "CODE")}}</div>' };
            const colDetDem: IMonacoColumnDef = { name: "TARIFF_DET_DEM.NAME", displayName: "PRODUCT.DD_TARIFF", width: 150 };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "GENERAL.ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'SITUATION':
                        columnDefs.push(colSituation);
                        break;
                    case 'CONCATENATED':
                        columnDefs.push(colConcatenated);
                        break;
                    case 'VERSION':
                        columnDefs.push(colVersion);
                        break;
                    case 'CONTRACT':
                        columnDefs.push(colContract);
                        break;
                    case 'PAYMENT_NATURE':
                        columnDefs.push(colPaymentNature);
                        break;
                    case 'CONTRACT_TYPE':
                        columnDefs.push(colContractType);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'TARIFF_DetDem_CONTRACT_USED_OFFER':
                        columnDefs.push(colUsedOffer);
                        break;
                    case 'TYPE_CARGO':
                        columnDefs.push(colTypeCargo);
                        break;
                    case 'PROVIDER':
                        columnDefs.push(colProvider);
                        break;
                    case 'ACCOUNTS':
                        columnDefs.push(colAccount, colAccountFantasyName);
                        break;
                    case 'NETWORKS':
                        columnDefs.push(colNetwork);
                        break;
                    case 'COMMODITY':
                        columnDefs.push(colCommoditySection);
                        columnDefs.push(colCommodityCondition);
                        columnDefs.push(colCommodityException);
                        break;
                    case 'VALIDITY_EVENT':
                        columnDefs.push(colEvent);
                        break;
                    case 'VALIDITY_START':
                        columnDefs.push(colValidityStart);
                        break;
                    case 'VALIDITY_END':
                        columnDefs.push(colValidityEnd);
                        break;
                    case 'SCORE':
                        columnDefs.push(colScore);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'VALIDITY_EXPIRED':
                        columnDefs.push(colValidityExpired);
                        break;
                    case 'MOVE_TYPE_REPL':
                        columnDefs.push(colMoveType);
                        break;
                    case 'TRADE_GROUP_ORIGIN_REPL':
                        columnDefs.push(colTradeGroupOriginRepl);
                        break;
                    case 'TRADE_GROUP_DESTINATION_REPL':
                        columnDefs.push(colTradeGroupDestinationRepl);
                        break;
                    case 'ROUTING_POINT_ORIGIN_REPL':
                        columnDefs.push(colRoutingPointOrigin);
                        break;
                    case 'ROUTING_POINT_DESTINATION_REPL':
                        columnDefs.push(colRoutingPointDestination);
                        break;
                    case 'COUNTRY_ORIGIN_REPL':
                        columnDefs.push(colCountryOrigin);
                        break;
                    case 'COUNTRY_DESTINATION_REPL':
                        columnDefs.push(colCountryDestination);
                        break;
                    case 'EQUIP_WEIGHT_RANGE_VEHICLE_REPL':
                        columnDefs.push(colEquipWeightRange);
                        break;
                    case 'CHARGE_NAME_EXHIBITION_REPL':
                        columnDefs.push(colChargeName);
                        break;
                    case 'TARIFF_DET_DEM':
                        columnDefs.push(colDetDem);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }

            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            CONCATENATED: null,
            CONCATENATED_COMPLEMENT: null,
            VERSION: null,
            SCORE: null,
            SCORE_RULE: null,
            SCORE_DATE: null,
            SCORE_ERROR: null,
            CONTRACTS: null,
            PRODUCT: null,
            DIRECTION: null,
            ID_COUNTRY: null,
            COUNTRY: null,
            PROVIDER_TYPE: null,
            EQUIPMENT_PROVIDER: null,
            ID_EQUIPMENT_PROVIDER: null,
            PAYMENT_NATURE: null,
            PROVIDER: null,
            ID_PROVIDER: null,
            PARAMS_DD_DET: null,
            PARAMS_DD_DEM: null,
            PARAMS_DD_COMBINED: null,
            ACCOUNTS: null,
            EXPORTERS: null,
            IMPORTERS: null,
            NETWORKS: null,
            TARIFF_TYPE: null,
            EVENT: null,
            VALIDITY_START: null,
            DISPLAY_VALIDITY_START: null,
            VALIDITY_END: null,
            DISPLAY_VALIDITY_END: null,
            OBSERVATION: null,
            FREE_TIME_DET_DEM: null,
            FILES: null,
            FILES_REMOVE: null,
            FILES_NEW: null,
            COMMODITY_SECTION: null,
            SITUATION: null,
            ACTIVE: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            DOCUMENT_ERROR: null,
        };
    }

    initScopeFunctions(): void {
        this.$scope.viewTariffDetDemHistory = async (tariff: ITariffDetDemModel, idTariff?: number): Promise<void> => {
            this.fetchData(tariff.ID, EOperation.VIEW, idTariff);
        }

        this.$scope.getGridViewProvider = (provider: IProviderSelector): string => {
            let gridValue = "";
            if (provider && provider.TYPE) {
                if (provider.TYPE.ID == EProviderTypeId.SHIPOWNER || provider.TYPE.ID == EProviderTypeId.AIRLINE) gridValue = provider.CODE;
                else if (provider.TYPE.ID == EProviderTypeId.SHIPPING_COMPANY) gridValue = provider.NAME;
            }
            return gridValue;
        }

        this.$scope.copyToValidTariffs = async (tariff: ITariffDetDemModel): Promise<void> => {
            this.$scope.sessionService.openTab('app.product.tariffDetDem', null, <ITariffDetDemExchangeData>{ OPERATION: "register", ID: tariff.ID ? tariff.ID.toString() : null });
        }

        this.$scope.goToNetwork = () => {
            this.$scope.sessionService.openTab("app.registration.network", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.NETWORKS, null, 'ID') });
        }

        this.$scope.goToAccount = () => {
            this.$scope.sessionService.openTab("app.commercial.account", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.ACCOUNTS, null, 'ID') });
        }

        this.$scope.goToTariffDetDem = (id: number) => {
            this.$scope.sessionService.openTab("app.product.tariffDetDem", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToServiceLevel = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.servicelevel", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToServiceType = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.servicetype", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToRoutingPoint = (ids: string) => {
            this.$scope.sessionService.openTab("app.registration.routingPoint", <ILinkParameter>{ ID: ids });
        }

        this.$scope.goToProvider = () => {
            this.$scope.sessionService.openTab("app.registration.provider", <ILinkParameter>{ ID: (this.$scope.model.PROVIDER ? this.$scope.model.PROVIDER.ID.toString() : null) });
        }

        this.$scope.goToTablePerDiem = (id: number): void => {
            this.$scope.sessionService.openTab("app.product.tablePerDiem", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToLegalPerson = (ids: string): void => {
            this.$scope.sessionService.openTab("app.registration.legalPerson", <ILinkParameter>{ ID: ids });
        }
    }

    async fetchData(id: number, action: string, idTariffDetDem?: number): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.getTariffDetDemHistoryById(id);

            if (request && request.data) {
                const model = angular.copy(request.data);

                this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")}`;
            BrowserTitle.$id = this.$scope.model.CONCATENATED;
            this.$scope.disableElements(true);
            if (this.$scope.model.CONCATENATED) {
                this.$scope.menuFloating.infos = [
                    { text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }
                ];
            }

            this.$scope.moveTypeFullList = await this.getMoveTypeList();
            this.initPanels(['collapseBasicData']);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private initPanels(open: string[] = ['collapseBasicData']): void {
        try {
            const panels = document.getElementsByClassName("toggle-me");
            if (panels && panels.length > 0) {
                for (const item of open) {
                    $("#" + item)["collapse"]("show");
                }
                for (let i = 0; i < panels.length; i++) {
                    const panel = panels[i];
                    if (open.indexOf(panel.id) === -1) {
                        $("#" + panel.id)["collapse"]("hide");
                    }
                }
            }
            this.$scope.navigateBetweenIds(open[0]);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getTariffDetDemHistoryById(id: number) {
        this.formService.block();
        try {
            if (!id) throw new Error("id is null.");

            const result = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/tariffDetDemHistory/getById/${id}`, 30000, null, false);

            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private getMenuFloatingDefault(): IFloatingMenu {
        return {
            tooltipPlacement: "auto bottom",
            textTooltip: "PRODUCT.TARIFF_DATA",
            infos: [
                { text: "Tarifário", class: "text-rouge font-bold" }
            ],
            btnActiveDisabled: false
        };
    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'tariff_files', formName: this.formName, folder: this.formName };
            return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData] });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getMoveTypeList(): Promise<IMoveTypeModel[]> {
        const result = await this.RestService.getObjectAsPromise(`${this.$baseUrl}/moveType/list/full`, 30000, null, false);
        return result ? result.data : [];
    }
}

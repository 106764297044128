import * as angular from 'angular';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { IModalOptions, IModalService } from '@services/ModalService';
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { ProductService } from "@services/ProductService";
import { OperationalService } from "@services/OperationalService";
import { IVoyageUpdateModal } from "@models/interface/operational/voyage/Voyage";
import { ISelectorModel, SelectorModel } from 'WBA-Model/dist/mongo/SelectorModel';
import { ERoutingPointType, EProviderTypeId, EProcessDocumentTypeId, ECountryOfOriginId } from "@enums/GenericData";
import { HelperService } from '@services/HelperService';
import { IVoyageStopover } from 'WBA-Model/dist/interface/operational/voyage/VoyageStopover';
import { IVoyageDeadline, IProviderDeadline } from 'WBA-Model/dist/interface/operational/voyage/VoyageDeadline';
import moment = require('moment');
import { isEmptyObject } from 'jquery';
import { IHolidayValues } from '../interfaces/IHoliday';

interface IStopoverModalScope extends IFormServiceScope {
    currentIndex: number;
    stopover: IVoyageStopover;
    operation: string;
}

interface IPort extends ISelectorModel {
    COUNTRY?: ISelectorModel
}

interface IVoyageUpdateModalScope extends IFormServiceScope {
    modalOptions: IModalOptions;
    voyageId: number;
    model: IVoyageUpdateModal;
    voyageModalId: number;
    typeList: ISelectorModel[];
    voyageStopover: IVoyageStopover[];
    voyageDeadline: IVoyageDeadline[];
    routingPointList: ISelectorModel[];
    providerList: ISelectorModel[];
    maritimeServiceList: SelectorModel[];
    stopoverCount: number;
    isViewMode?: boolean;
    addCarrier: () => void;
    removeShipOwner: (shipOwnerIndex: number) => void;
    addStopover: () => void;
    removeStopoverOwner: (stopoverOwnerIndex: number) => void;
    addDeadline: (index: number) => void;
    removeDeadlineOwner: (deadlineIndex: number, providerDeadlineIndex: number) => void;
    getProviderListByName: (search: string, withoutDuplicity: boolean) => Promise<void>;
    getRoutingPointListByName: (search: string, withoutDuplicity: boolean) => Promise<void>;
    openMaritimeModal: () => Promise<void>;
    getMaritimeServiceListByName: (search: string) => Promise<void>;
    getVoyageStopoverCount: (id: string) => Promise<void>;
    updateVoyage: () => Promise<boolean>;
    openStopover: (index: number, operation: string) => void;
    createdHouseMasterDeadline: (providerindex: number, stopoverIndex: number) => Promise<void>;
    calculateHouseDeadlinesBasedOnMaster: (event: KeyboardEvent, voyageDeadlineIndex: number, providerDeadlineIndex: number) => Promise<void>;
}

export class VoyageUpdateModalController extends FormService2 {
    static $inject: string[] = ['$injector', '$scope'];
    private scope: IVoyageUpdateModalScope;
    private $q: ng.IQService;
    private timeout: ng.ITimeoutService
    private ModalService: IModalService;
    private ProductService: ProductService;
    private OperationalService: OperationalService;
    private helperService: HelperService;
    private $sce: angular.ISCEService;
    private $compile: angular.ICompileService;

    constructor($injector: ng.Injectable<any>, $scope: IVoyageUpdateModalScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.ModalService = $injector.get('ModalService');
        this.$q = $injector.get('$q');
        this.timeout = $injector.get('$timeout');
        this.ProductService = $injector.get('ProductService');
        this.OperationalService = $injector.get('OperationalService');
        this.helperService = $injector.get('HelperService');
        this.$sce = $injector.get('$sce');
        this.$compile = $injector.get('$compile');
    }

    async $onInit(): Promise<void> {
        try {
            this.block();
            this.init("voyageUpdateModalForm", null, null);
            await this.getVoyageStopoverCount(String(this.scope.voyageId))
            this.initScopeFunctions();
            await this.initModel();
            await this.initDependencies();
            this.loadRegisterForm(false);
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    private initScopeFunctions(): void {

        this.scope.addCarrier = (): void => {
            this.addCarrier();
        }

        this.scope.removeShipOwner = (shipOwnerIndex: number) => {
            this.removeShipOwner(shipOwnerIndex);
        }

        this.scope.getProviderListByName = async (search: string, withoutDuplicity: boolean): Promise<void> => {
            return await this.getProviderListByName(search, withoutDuplicity);
        }

        this.scope.addStopover = (): void => {
            this.addStopover();
        }

        this.scope.removeStopoverOwner = (stopoverOwnerIndex: number) => {
            this.removeStopoverOwner(stopoverOwnerIndex);
        }

        this.scope.getRoutingPointListByName = async (search: string, withoutDuplicity: boolean): Promise<void> => {
            return await this.getRoutingPointListByName(search, withoutDuplicity);
        }

        this.scope.addDeadline = (index: number): void => {
            this.addDeadline(index);
        }

        this.scope.removeDeadlineOwner = (deadlineIndex: number, providerDeadlineIndex: number) => {
            this.removeDeadlineOwner(deadlineIndex, providerDeadlineIndex);
        }

        this.scope.openMaritimeModal = async (): Promise<void> => {
            this.openMaritimeModal();
        }

        this.scope.getMaritimeServiceListByName = async (search: string): Promise<void> => {
            await this.getMaritimeServiceListByName(search);
        }

        this.scope.getVoyageStopoverCount = async (id: string): Promise<void> => {
            await this.getVoyageStopoverCount(id);
        }

        this.scope.updateVoyage = async (): Promise<boolean> => {
            const success = await this.updateVoyage();
            if (success) {
                this.ModalService.closeModal(this.scope.voyageModalId);
                this.scope.voyageModalId = 0;
                this.loadEditForm();
            }
            return true;
        }

        this.scope.openStopover = (index: number, operation: string): void => {
            this.openStopover(index, operation);
        }

        this.scope.createdHouseMasterDeadline = async (providerindex: number, stopoverIndex: number) => {
            await this.createdHouseMasterDeadline(providerindex, stopoverIndex);
        }

        this.scope.calculateHouseDeadlinesBasedOnMaster = async (event: KeyboardEvent, voyageDeadlineIndex: number, providerDeadlineIndex: number): Promise<void> => {
            await this.calculateHouseDeadlinesBasedOnMaster(event, voyageDeadlineIndex, providerDeadlineIndex);
        }
    }

    private async getGenericList(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async getVoyageStopoverCount(id: string): Promise<void> {
        let count: number = 0;
        try {
            this.block();
            const result = await this.OperationalService.get<any>(`/voyage/header/stopover/${id}`, null, 10000);
            if (result && result.data && result.data.data) count = result.data.data;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.scope.stopoverCount = count;
            this.unblock();

        }
    }

    async initDependencies(): Promise<any> {
        try {
            const self: VoyageUpdateModalController = this;

            self.getTabVoyages();
            self.getTabStopover();
            self.getTabDeadlines();

            return new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getGenericList("process_document_type"),
                ]).then((result: any) => {
                    self.scope.typeList = result[0];
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async initModel(): Promise<void> {
        this.scope.model.VOYAGE_STOPOVER = [];
        this.scope.model.VOYAGE_DEADLINE = [];
    }

    private addCarrier() {
        try {
            if (!this.scope.model.VOYAGE_PROVIDER) this.scope.model.VOYAGE_PROVIDER = [];
            this.scope.model.VOYAGE_PROVIDER.push({
                _id: null,
                ID: null,
                VOYAGE_ID: null,
                PROVIDER: null,
                VOYAGE_NUMBER: null,
                CREATED_AT: null,
                CREATED_BY: null,
                UPDATED_AT: null,
                UPDATED_BY: null
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private removeShipOwner(shipOwnerIndex: number) {
        try {

            const indexDeadline = this.scope.model.VOYAGE_DEADLINE.findIndex(vd => vd.PROVIDER.ID === this.scope.model.VOYAGE_PROVIDER[shipOwnerIndex].PROVIDER.ID);
            if (indexDeadline > -1) {
                this.scope.model.VOYAGE_DEADLINE.splice(indexDeadline, 1);
            }

            this.scope.model.VOYAGE_PROVIDER.splice(shipOwnerIndex, 1);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private addStopover() {
        try {
            if (!this.scope.model.VOYAGE_STOPOVER) this.scope.model.VOYAGE_STOPOVER = [];

            this.scope.model.VOYAGE_STOPOVER.push({
                _id: null,
                ID: null,
                VOYAGE_ID: null,
                PORT_ORIGIN: null,
                ETA: null,
                ATA: null,
                ETB: null,
                ATB: null,
                ETD: null,
                ATD: null,
                ETS: null,
                ATS: null,
                FINAL_ARRIVAL_DATE: null,
                FINAL_BERTHING_DATE: null,
                FINAL_DEPARTURE_DATE: null,
                FINAL_SAILING_DATE: null,
                CREATED_AT: null,
                CREATED_BY: null,
                UPDATED_AT: null,
                UPDATED_BY: null
            });

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private removeStopoverOwner(stopoverOwnerIndex: number) {
        try {
            const stopover = this.scope.model.VOYAGE_STOPOVER[stopoverOwnerIndex];

            if (stopover.PORT_ORIGIN) {
                this.scope.model.VOYAGE_DEADLINE.forEach((deadline, index) => {
                    const deadlineProviders = deadline.PROVIDER_DEADLINE.filter(providerDeadline => providerDeadline.PORT_ORIGIN && providerDeadline.PORT_ORIGIN.ID == stopover.PORT_ORIGIN.ID);
                    if (deadlineProviders.length) {
                        for (const provider of deadlineProviders) {
                            const providerIndex = this.scope.model.VOYAGE_DEADLINE[index].PROVIDER_DEADLINE.indexOf(provider)
                            this.scope.model.VOYAGE_DEADLINE[index].PROVIDER_DEADLINE.splice(providerIndex, 1);
                        }
                    }
                })
            }
            this.scope.model.VOYAGE_STOPOVER.splice(stopoverOwnerIndex, 1);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getRoutingPointListByName(search: string, withoutDuplicity: boolean): Promise<void> {
        let result: ISelectorModel[] = [];
        try {
            if (search && search.length >= 3) {
                this.block();
                const routingPoints = await this.ProductService.post({ route: '/routingPoint/list/custom/', data: { name: search, types: [ERoutingPointType.PORT] } });
                if (routingPoints && routingPoints.data && routingPoints.data.data && routingPoints.data.data.data) result = routingPoints.data.data.data.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, DISPLAY_NAME: x.DISPLAY_NAME, COUNTRY: x.COUNTRY } });

                if (withoutDuplicity) {
                    const voyageStopovers = this.scope.model.VOYAGE_STOPOVER;
                    if (voyageStopovers.length) {
                        voyageStopovers.forEach(voyageStopover => {
                            const index = result.findIndex(routingPoint => voyageStopover.PORT_ORIGIN && routingPoint.ID == voyageStopover.PORT_ORIGIN.ID);
                            if (index > -1) result.splice(index, 1);
                        })
                    }
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.scope.routingPointList = result;
            this.unblock();
        }
    }

    private async getRoutingPoinGetById(id: string): Promise<IPort> {
        try {
            let result: IPort = null;
            this.block();
            const routingPoints = await this.ProductService.get({ route: `/routingPoint/getById/${id}`, data: null });
            if (routingPoints && routingPoints.data && routingPoints.data.data) {
                const routingPoint = routingPoints.data.data;
                result = {
                    ID: routingPoint.ID,
                    NAME: routingPoint.NAME,
                    CODE: routingPoint.CODE,
                    COUNTRY: routingPoint.CITY.PROVINCE.COUNTRY
                }
            }
            this.unblock();
            return result;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async createdHouseMasterDeadline(providerindex: number, stopoverIndex: number) {
        try {
            if (!this.scope.model.VOYAGE_DEADLINE) this.scope.model.VOYAGE_DEADLINE = [];

            const providers = providerindex ? [this.scope.model.VOYAGE_PROVIDER[providerindex]] : this.scope.model.VOYAGE_PROVIDER;
            const stopovers = stopoverIndex ? [this.scope.model.VOYAGE_STOPOVER[stopoverIndex]] : this.scope.model.VOYAGE_STOPOVER;
            const deadlines = this.scope.model.VOYAGE_DEADLINE;

            if (providers && providers.length && stopovers && stopovers.length) {

                for (const provider of providers) {
                    const index = deadlines.findIndex(d => d.PROVIDER.ID == provider.PROVIDER.ID);
                    let providerDeadline: IProviderDeadline[] = []
                    if (index > -1) {
                        for (const stopover of stopovers) {
                            for (const type of this.scope.typeList) {
                                const hasProviderDeadline = deadlines.some(d => d.PROVIDER.ID == provider.PROVIDER.ID && d.PROVIDER_DEADLINE.some(p => p.TYPE && p.TYPE.ID == type.ID && p.PORT_ORIGIN && stopover.PORT_ORIGIN && p.PORT_ORIGIN.ID === stopover.PORT_ORIGIN.ID));
                                const port: IPort = await this.getRoutingPoinGetById(stopover.PORT_ORIGIN.ID);
                                if (!hasProviderDeadline && port.COUNTRY.ID == ECountryOfOriginId.BR) {
                                    const deadline: IProviderDeadline[] = [{
                                        BASE_DATE: null,
                                        DRAFT: null,
                                        DRAFT_IMO: null,
                                        PORT_ORIGIN: stopover.PORT_ORIGIN,
                                        RELEASE: null,
                                        TYPE: type,
                                        VGM: null
                                    }];

                                    providerDeadline = providerDeadline.concat(deadline);
                                }
                            }
                        }

                        deadlines[index].PROVIDER_DEADLINE = deadlines[index].PROVIDER_DEADLINE.concat(providerDeadline)

                    } else {
                        this.timeout(async () => {
                            for (const stopover of stopovers) {
                                const port: IPort = await this.getRoutingPoinGetById(stopover.PORT_ORIGIN.ID);
                                if (port.COUNTRY.ID == ECountryOfOriginId.BR) {
                                    for (const type of this.scope.typeList) {
                                        providerDeadline.push({
                                            BASE_DATE: null,
                                            DRAFT: null,
                                            DRAFT_IMO: null,
                                            PORT_ORIGIN: stopover.PORT_ORIGIN,
                                            RELEASE: null,
                                            TYPE: type,
                                            VGM: null
                                        })
                                    }
                                }
                            }
                        }, 500)

                        deadlines.push({
                            _id: null,
                            ID: null,
                            VOYAGE_ID: null,
                            PROVIDER: provider.PROVIDER,
                            PROVIDER_DEADLINE: providerDeadline,
                            CREATED_AT: null,
                            CREATED_BY: null,
                            UPDATED_AT: null,
                            UPDATED_BY: null
                        });
                    }
                }
            }

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private addDeadline(index: number) {
        try {
            const providerDeadline: IProviderDeadline[] = [{
                BASE_DATE: null,
                DRAFT: null,
                DRAFT_IMO: null,
                PORT_ORIGIN: null,
                RELEASE: null,
                TYPE: null,
                VGM: null
            }];

            this.scope.model.VOYAGE_DEADLINE[index].PROVIDER_DEADLINE = this.scope.model.VOYAGE_DEADLINE[index].PROVIDER_DEADLINE.concat(providerDeadline);

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private removeDeadlineOwner(deadlineIndex: number, providerDeadlineIndex: number) {
        try {

            this.scope.model.VOYAGE_DEADLINE[deadlineIndex].PROVIDER_DEADLINE.splice(providerDeadlineIndex, 1);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getProviderListByName(search: string, withoutDuplicity: boolean): Promise<void> {
        let result: ISelectorModel[] = [];
        this.block();
        try {
            const providers = await this.ProductService.post({ route: '/provider/list/custom', data: { search: search, types: [EProviderTypeId.SHIPOWNER] } });
            if (providers && providers.data && providers.data.data) result = providers.data.data.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.SCAC_IATA } });

            if (withoutDuplicity) {
                const voyageProvider = this.scope.model.VOYAGE_PROVIDER;
                if (voyageProvider.length) {
                    voyageProvider.forEach(voyageProvider => {
                        const index = result.findIndex(provider => voyageProvider.PROVIDER && provider.ID == voyageProvider.PROVIDER.ID);
                        if (index > -1) result.splice(index, 1);
                    })
                }
            }

        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.scope.providerList = result;
            this.unblock();
        }
    }

    private async openMaritimeModal(): Promise<void> {
        const html = `
        <div id="maritimeModal" class="row">
            <div class="col-lg-12">
            <ui-select name="maritimeModal"
            ng-model="model.MARITIME_SERVICE"
            theme="bootstrap" append-to-body="true"
            ng-change="selectorValidity(this.$select.ngModel.$name);"
            ng-disabled="selectorDisabled(this.$select.ngModel.$name)"
            ng-click="selectorFocus(this.$select.searchInput[0]);"
            skip-focusser="true">
            <ui-select-match
                placeholder="{{ 'GENERAL.UI_SELECT.SELECT' | translate }}">
                {{$select.selected.NAME}}
            </ui-select-match>
            <ui-select-choices refresh-delay="1"
                refresh="getMaritimeServiceListByName($select.search)"
                repeat="item in maritimeServiceList track by $index">
                <div
                    ng-bind-html="item.NAME | highlight: $select.search">
                </div>
            </ui-select-choices>

            <ui-select-no-choice>
                {{ 'GENERAL.UI_SELECT.EMPTY_SELECTOR_MESSAGE' | translate }}
            </ui-select-no-choice>
        </ui-select>
            </div>
        </div>
        `;
        const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
            {
                scope: this.scope,
                size: 'lg'
            },
            {
                closeButtonText: "GENERAL.CLOSE",
                actionButtonText: "GENERAL.SAVE",
                headerText: "GENERAL.UPDATE",
                bodyText: this.$sce.trustAsHtml(html),
            }
        );

        modalInstance.rendered.then(() => {
            const maritimeModal = angular.element("#maritimeModal");
            if (maritimeModal) this.$compile(maritimeModal)(this.scope);
        });

        const apply = await modalInstance.result.then(function (result) {
            return result.$value;
        }, function (result) {
            return result.$value;
        });

        if (apply) {
            const msg = this.getTranslate('GENERAL.REGISTRATION_SUCCESSFULLY_REGISTERED');
            this.notifySuccess(msg);
        }
    }

    private async getMaritimeServiceListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 2) {

                this.block();
                const maritimeServices = await this.ProductService.post({ route: '/maritimeService/list/custom/', data: { search, products: [], provider: [] } });
                if (maritimeServices && maritimeServices.data && maritimeServices.data.data && maritimeServices.data.data.data) result = maritimeServices.data.data.data.map(x => { return { ID: x.ID, NAME: x.LOGISTIC_SOLUTION } });
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.scope.maritimeServiceList = result
            this.unblock();
        }
    }

    private async getTabVoyages(): Promise<void> {
        this.block();
        try {
            const providerTab = await this.OperationalService.get(`/voyage/tab/voyages/getById/${this.scope.voyageId}`, 30000);
            if (providerTab && providerTab.data && providerTab.data.data) this.scope.model.VOYAGE_PROVIDER = providerTab.data.data;

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getTabStopover(): Promise<void> {
        this.block();
        try {
            const stopoverTab = await this.OperationalService.get(`/voyage/tab/stopovers/getById/${this.scope.voyageId}`, 30000);
            if (stopoverTab && stopoverTab.data && stopoverTab.data.data) this.scope.model.VOYAGE_STOPOVER = stopoverTab.data.data;
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getTabDeadlines(): Promise<void> {
        this.block();
        try {
            const deadlineTab = await this.OperationalService.get(`/voyage/tab/deadlines/getById/${this.scope.voyageId}`, 30000);
            if (deadlineTab && deadlineTab.data && deadlineTab.data.data) {
                this.scope.model.VOYAGE_DEADLINE = deadlineTab.data.data;
            } else {
                await this.createdHouseMasterDeadline(null, null);
            }
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async modalSaveConfirmation(headerText: string, closeButtonText: string, bodyText?: string, plural?: boolean): Promise<boolean> {
        return await this.ModalService.showModalConfirmation({}, {
            headerText: headerText,
            bodyText: this.getTranslate(plural ? 'OPERATIONAL.DEADLINE_HOLIDAY_DATES_PLURAL' : 'OPERATIONAL.DEADLINE_HOLIDAY_DATES_SINGULAR', { info: bodyText }),
            actionButtonText: "GENERAL.CONFIRMATION",
            closeButtonText: closeButtonText
        });
    }

    private async validationHolidayDeadlinesDate(holidays: IHolidayValues[]): Promise<string[]> {
        const holidaysFinal = []
        const listDates = await this.helperService.get(`/holiday/listDates`, null, 30000);
        if (listDates && listDates.data && listDates.data.data) {
            let holidayDates = listDates.data.data;

            const holidayVariables = holidayDates.filter((holiday) => !isEmptyObject(holiday.variableDates))
            const holidaysFormatted = holidays.filter((item, index, self) =>
                index === self.findIndex((t) => (
                    t.valueFormatted === item.valueFormatted && t.field === item.field
                ))
            );
            holidaysFormatted.map((holiday) => {
                const yearDeadline = moment(holiday.value).format('YYYY')
                const dateFormattedDeadline = moment(holiday.value).format('DD/MM').toString()

                const exist = holidayDates.find((holiday) => holiday.date == dateFormattedDeadline)
                const existVariable = holidayVariables.find((variable) => variable.variableDates[yearDeadline] == dateFormattedDeadline);

                if (exist || existVariable) {
                    holidaysFinal.push(` ${holiday.field}: ${holiday.valueFormatted}`)
                }
            })
        }
        return holidaysFinal
    }

    private async updateVoyage(): Promise<boolean> {
        let success = true;
        try {
            const holidays = [];

            this.scope.model.VOYAGE_DEADLINE.map((deadline) => {
                deadline.PROVIDER_DEADLINE.map((dead) => {
                    if (dead.DRAFT) {
                        holidays.push({ valueFormatted: moment(dead.DRAFT).format('DD[/]MM[/]YYYY'), value: dead.DRAFT, field: this.getTranslate('OPERATIONAL.DRAFT') });
                    }
                    if (dead.DRAFT_IMO) {
                        holidays.push({ valueFormatted: moment(dead.DRAFT_IMO).format('DD[/]MM[/]YYYY'), value: dead.DRAFT_IMO, field: this.getTranslate('OPERATIONAL.DRAFT_IMO') });
                    }
                    if (dead.RELEASE) {
                        holidays.push({ valueFormatted: moment(dead.RELEASE).format('DD[/]MM[/]YYYY'), value: dead.RELEASE, field: this.getTranslate('OPERATIONAL.DEADLINE_RELEASE')  });
                    }
                    if (dead.VGM) {
                        holidays.push({ valueFormatted: moment(dead.VGM).format('DD[/]MM[/]YYYY'), value: dead.VGM, field: this.getTranslate('OPERATIONAL.VGM') });
                    }
                });
            });
            if (holidays.length > 0) {
                const holidayRest = await this.validationHolidayDeadlinesDate(holidays);
                if (holidayRest.length > 0) {
                    const confirm = await this.modalSaveConfirmation("GENERAL.CONFIRM_ACTION", "GENERAL.CANCEL", holidayRest.toString(), holidayRest.length > 1 ? true : false);
                    if (!confirm) success = false;
                }
            }
            if (success) {
                this.block();
                await this.OperationalService.post('/voyage/update', { data: this.scope.model, oldData: [] });
                const msg = this.getTranslate('GENERAL.REGISTRATION_SUCCESSFULLY_REGISTERED');
                this.notifySuccess(msg);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return success
        }
    }

    private async openStopover(index: number, operation: string): Promise<void> {
        try {

            const modalStopoverId = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    modalID: modalStopoverId,
                    scope: this.scope,
                    formService: operation,
                    template: require("../view/modal/stopoverModal.html"),
                    size: 'lg'
                },
                {
                    actionButtonText: this.getTranslate("GENERAL.CLOSE"),
                });

            modalInstance.rendered.then(async () => {
                const modalScope: IStopoverModalScope = await this.ModalService.getModalScope(modalStopoverId);
                const stopover = this.scope.model.VOYAGE_STOPOVER[index];
                modalScope.stopover = stopover;

                modalScope.$applyAsync();
            });

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async calculateHouseDeadlinesBasedOnMaster(event: KeyboardEvent, voyageDeadlineIndex: number, providerDeadlineIndex: number) {
        try {
            if (this.scope.model.VOYAGE_DEADLINE[voyageDeadlineIndex].PROVIDER_DEADLINE[providerDeadlineIndex].TYPE.ID == EProcessDocumentTypeId.MASTER) {
                const stopover = this.scope.model.VOYAGE_STOPOVER.find(stopover => this.scope.model.VOYAGE_DEADLINE[voyageDeadlineIndex].PROVIDER_DEADLINE.find(deadline => deadline.PORT_ORIGIN.ID === stopover.PORT_ORIGIN.ID));

                let voyageDeadline = this.scope.model.VOYAGE_DEADLINE[voyageDeadlineIndex];
                const request = {
                    stopoverId: stopover.ID,
                    deadlines: voyageDeadline,
                    providerDeadlineIndex
                };

                const deadlineTab = await this.OperationalService.post(`/voyage/tab/deadlines/calculate/provider/`, request, 30000);
                if (deadlineTab && deadlineTab.data && deadlineTab.data.data) {
                    const deadline: IVoyageDeadline = deadlineTab.data.data;
                    deadline.PROVIDER_DEADLINE.forEach((providerDeadline, index) => {
                        if (providerDeadline.TYPE.ID == EProcessDocumentTypeId.HOUSE) {
                            this.scope.model.VOYAGE_DEADLINE[voyageDeadlineIndex].PROVIDER_DEADLINE[index] = providerDeadline;
                        }
                    })
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }
}

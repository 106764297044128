import * as angular from 'angular';
import { IUiTabSteps, ETabStep, IPreProcessWizardModel, IAgentSelector, ICustomerSelector, IAccountSelector, ISearchBehaviorControl, ICommodityModel } from "../model/PreProcessWizardModalModel";
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { OperationalService } from "@services/OperationalService";
import { DataProcessService } from "@services/DataProcessService";
import { IModalService, IModalOptions } from "@services/ModalService";
import { IRestService } from "@services/RestService";
import { ProductService } from "@services/ProductService";
import { IMonacoRequest } from "@services/GridFormService";
import { ISessionService } from '@services/SessionService';
import { ISelectorModel } from "@models/mongo/SelectorModel";
import { HelperService } from "@services/HelperService";
import { IRouteEvent, IDangerousCargo, IRoute, ILocalFull } from "@models/interface/product/PreProcessWizardFilter";
import { IPreProcessWizardError } from '@models/interface/product/PreProcessWizardError';
import { IProductConfigurationModel, IProductConfigurationSecure } from '@models/interface/product/ProductConfigurationModel';
import { IMoveTypeModel } from '@models/interface/product/MoveTypeModel';
import { IApplicationList } from '@models/interface/product/ApplicationModel';
import { IWeightRangeSelector } from '@models/interface/product/WeightRangeModel';
import { IEquipmentSelector } from '@models/interface/product/EquipmentModel';
import { IEntity } from '@models/interface/common/Entity';
import { IPreProcess, IVehicleList } from '@models/interface/product/PreProcess';
import { IPreProcessWizardOptionConfirmation, IError } from '@models/interface/product/PreProcessWizard';
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { EPreProcessWizardTab } from '@enums/GenericData';
import { EEventType, EProductId, ETypeLocalId, ECargoTypeId, EOperation, EChargeOriginId, ECustomerSituationId } from "@enums/GenericData";
import { IOfferWizardRequestRouteAccount, IOfferWizardAccountResult, IOfferWizardAgentResult, IOfferWizardRequestIncoterm, IOfferWizardIncotermResult } from 'src/ts/commercial/model/OfferWizardModalModel';
import { IMonacoConfig } from 'src/ts/common/MonacoInterface';
import { SelectorModel } from "src/ts/common/model/SelectorModel";
import { IAccountExchangeData } from 'src/ts/commercial/model/AccountModel';
import { ILinkParameter, IProcessParameter } from 'src/ts/common/model/ModelParameter';

interface IPreProcessWizardModalScope extends IFormServiceScope {
    model: IPreProcessWizardModel;
    oldModel: IPreProcessWizardModel;
    errorsOnFilter: IPreProcessWizardError[];
    steps: IUiTabSteps;

    // Preview and Confirmation Step
    preProcessPreview: IPreProcess;
    confirmationTabSearchControl: ISearchBehaviorControl;
    confirmationOptions: IPreProcessWizardOptionConfirmation[];
    hasAnyConfirmationOption: boolean;

    // Check Variable
    isCargoCalculatedFieldsDisabled: boolean;
    isCargoListGrossWeightFieldsDisabled: boolean;

    // List
    productList: SelectorModel[];
    cargoTypeList: SelectorModel[];
    processTypeList: SelectorModel[];
    commoditySectionList: SelectorModel[];
    incotermList: SelectorModel[];
    routingPointList: SelectorModel[];
    currencyList: SelectorModel[];
    customerList: ICustomerSelector[];
    providerList: IAccountSelector[];
    commodityList: SelectorModel[];
    equipSpecList: SelectorModel[];
    packageTypeList: SelectorModel[];
    packingGroupList: SelectorModel[];
    unClassList: SelectorModel[];
    defaultProductSecure: SelectorModel[];
    chargeNameList: SelectorModel[];
    typePaymentList: SelectorModel[];
    vehicleList: SelectorModel[];
    weightRangeList: IWeightRangeSelector[];
    brokerList: IEntity[];
    exporterList: IEntity[];
    importerList: IEntity[];
    notifyList: IEntity[];
    insuranceBrokerList: IEntity[];
    eventList: IRouteEvent[];
    equipmentList: IEquipmentSelector[];
    moveTypeList: IMoveTypeModel[];
    agentList: IAgentSelector[];
    productConfigurationList: IProductConfigurationModel[];
    applicationList: IApplicationList[];

    // Modal
    modalOptions: IModalOptions;
    openCargoPolLbModal: () => void;

    // Field Changes
    productChange: () => void;
    getEventListByProduct: () => void;
    specSecureChange: () => void;
    specRouteAgentChange: (index: number) => void;
    specDangerousCargoChange: () => void;
    chargeNameChange: (optionChargeName: SelectorModel) => void;
    getAndUpdateRouteAccountValues: (routeIndex: number, masterDirect?: boolean) => Promise<void>;
    updateCargoCalculateFieldsDisabledControl: (amount: number) => void;
    resetCargoTabModel: () => void;
    cargoAirChargeableWeightChange: () => void;
    cargoAirOverChargeableWeightChange: () => void;
    grossWeightAndCBMChange: () => void;
    updateWeightRangeBase: () => void;
    cargoListGrossWeightFieldsControl: (grossWeightTotal: number) => void;
    moveTypeChange: () => void;
    clearRoutingPoint: () => void;

    // Search
    getCustomerListByName: (search: string) => Promise<void>;
    getExporterListByName: (search: string) => Promise<void>;
    getImporterListByName: (search: string) => Promise<void>;
    getProviderListByName: (search: string) => Promise<void>;
    getBrokerListByName: (search: string) => Promise<void>;
    getNotifyListByName: (search: string) => Promise<void>;
    getIncotermListByName: (search: string) => Promise<void>;
    getCommodityListByName: (search: string) => Promise<void>;
    getCommoditySectionListByName: (search: string) => Promise<void>;
    getRoutingPointListByName: (search: string) => Promise<void>;
    getCurrencyListByName: (search: string) => Promise<void>;
    getEquipmentListByName: (search: string) => Promise<void>;
    getChargeNameListByName: (search: string) => Promise<void>;
    getSpecApplicationListByName: (search: string) => Promise<void>;
    getInsuranceBrokerListByName: (search: string) => Promise<void>;
    getAgentListByName: (search: string, networkId: number) => Promise<void>;

    // Navigation
    backStep: () => void;
    nextStep: () => void;
    tabClick: (tabIndex: number) => void;
    goToCustomer: (id?: number) => void;
    goToIncoterm: (id?: number) => void;
    goToExporter: (id?: number) => void;
    goToImporter: (id?: number) => void;
    goToProvider: (id?: number) => void;
    goToAgent: (id?: number) => void;
    goToWeightRange: (weightRanges: SelectorModel[]) => void;
    goToAccountRequirement: (IdAccount: number, IdAccountRequirement: number) => void;
    goToProcess: (processNumber: string) => void;

    // Multilines Control
    selectAllEvents: () => void;
    removeEvent: (index: number) => Promise<void>;
    removeCargo: (index: number) => Promise<void>;
    removeCargoFCL: (index: number) => Promise<void>;
    removeCargoFTL: (index: number) => Promise<void>;
    removeDangerousCargo: (index: number) => void;
    removeCargoBBRORO: (index: number) => void;
    addEvent: () => void;
    addCargo: () => void;
    addCargoFCL: () => void;
    addCargoFTL: () => void;
    addDangerousCargo: () => void;
    addCargoBBRORO: () => void;

    // Check Methods
    isTabEnabled: (tabIndex: number) => boolean;
    isCargoTotalGrossWeightDisabled: () => boolean;
    isAirChargeableWeightDisabled: () => boolean;
    isLclAmountDisabled: () => boolean;
    isProcessMaritimeProduct: () => boolean;
    isProcessAirProduct: () => boolean;
    isProcessRoadProduct: () => boolean;
    isGeneratedAccountRequirement: () => boolean;

    // Cargo Calculations
    calculateCargoGrossWeightTotal: (index: number) => void;
    calculateGrossWeightTotal: () => void;
    calculateCargoCBW: (index: number) => void;
    calculateVolumeTotal: () => void;

    // Preview and Confirmation Step
    getAndUpdateConfirmationList: () => void;
    cancelConfirmationSearch: () => void;
    retryGenerateProcess: () => void;
    finish: () => void;
    viewOptionErrors: (errors: IError[]) => void;
    hasAnySearchControlInProgress: () => boolean;
}

export class PreProcessWizardModalController extends FormService2 {
    public static $inject: string[] = ['$injector', '$scope'];
    private $q: ng.IQService;
    private timeout: ng.ITimeoutService;
    private config: IMonacoConfig;
    private scope: IPreProcessWizardModalScope;

    private OperationalService: OperationalService;
    private DataProcessService: DataProcessService;
    private ProductService: ProductService;
    private ModalService: IModalService;
    private $SessionService: ISessionService;
    private $RestService: IRestService;
    private helperService: HelperService;
    private $sce: angular.ISCEService;

    constructor($injector: ng.Injectable<any>, $scope: IPreProcessWizardModalScope) {
        super($injector, $scope);

        this.scope = $scope;
        this.$q = $injector.get('$q');
        this.config = $injector.get('config');
        this.timeout = $injector.get('$timeout');
        this.DataProcessService = $injector.get('DataProcessService');
        this.OperationalService = $injector.get('OperationalService');
        this.ProductService = $injector.get('ProductService');
        this.ModalService = $injector.get('ModalService');
        this.$SessionService = $injector.get('SessionService');
        this.$RestService = $injector.get('RestService');
        this.helperService = $injector.get('HelperService');
        this.$sce = $injector.get('$sce');
    }

    async $onInit(): Promise<void> {
        try {
            this.block();
            this.initializate();
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async initializate() {
        this.init("preProcessWizardModalForm", null, null);
        this.initScopeFunctions();
        this.initModel();
        this.initSearchControl();

        await this.initDependencies();
    }

    async initDependencies(): Promise<any> {
        const self: PreProcessWizardModalController = this;

        const promises = {
            weightRange: self.getWeightRangeList(),
            moveType: self.getMoveTypeList(),
            product: self.getGenericValue("product"),
            cargoType: self.getGenericValue("type_cargo"),
            processType: self.getGenericValue("process_type"),
            commoditySection: self.getGenericValue("hscode_section"),
            equipSpec: self.getGenericValue("equip_spec"),
            packageType: self.getGenericValue("package_type"),
            packingGroup: self.getGenericValue("packing_group"),
            unClass: self.getGenericValue("un_class"),
            typePayment: self.getGenericValue("type_payment"),
            vehicleList: self.getGenericValue("vehicle_type")
        };

        return new Promise((resolve, reject) => {
            self.$q.all(promises).then((result) => {
                self.scope.moveTypeList = result.moveType;
                self.scope.productList = result.product;
                self.scope.cargoTypeList = result.cargoType;
                self.scope.processTypeList = result.processType;
                self.scope.commoditySectionList = result.commoditySection;
                self.scope.equipSpecList = result.equipSpec;
                self.scope.packageTypeList = result.packageType;
                self.scope.packingGroupList = result.packingGroup;
                self.scope.unClassList = result.unClass;
                self.scope.typePaymentList = result.typePayment;
                self.scope.vehicleList = result.vehicleList;
                resolve(true);
            }).catch((ex) => {
                reject(ex);
            });
        });
    }

    initModel(): void {
        try {
            if (!this.scope.model) {
                this.scope.model = {
                    PROCESS: null,
                    PRE_TAB: {
                        PRODUCT: null,
                        CARGO_TYPE: null,
                        ACCOUNT_CUSTOMER: null,
                        EXPORTER: null,
                        IMPORTER: null,
                        NOTIFY: null,
                        PROVIDER: null,
                        INCOTERM: null,
                        PROCESS_TYPE: null,
                        COMMODITY_VALUE: null,
                        COMMODITY_CURRENCY: null,
                        COMMODITY_SECTION: null,
                        COMMODITY: null,
                        LOAD_REF: null,
                        ACCOUNT_REQUEST: null,
                        BROKER: null,
                    },
                    ROUTES_TAB: {
                        ROUTE_EVENT: null,
                        MOVE_TYPE: null,
                    },
                    CARGO_TAB: {
                        CARGO_LIST: null,
                        CARGO_DETAIL: {
                            UNITY: null,
                            QUANTITY: null,
                            VOLUME: null,
                            GROSS_WEIGHT: null,
                            CBM: null,
                            STAGGERED: null,
                            OVERTAXED: null,
                            WEIGHT_RANGE_LIST: null,
                            WEIGHT_RANGE_BASE: null,
                            EQUIPMENT_LIST: null,
                            VEHICLE_LIST: null,
                            EQUIPMENT_CONCATENATED: null,
                            OWS: null,
                            FOODGRADE: null,
                            SUPERTESTED: null,
                            STACKABLE: null,
                            CARGO_SOC: null,
                            FLEXITANK: null,
                        },
                    },
                    SPECIFICITY_TAB: {
                        ROUTE: null,
                        IS_REFRIGERATED_CARGO: null,
                        REFRIGERATED_CARGO: null,
                        IS_CARGO_TREATMENT: null,
                        CARGO_TREATMENT: null,
                        IS_INSURANCE: null,
                        INSURANCE: null,
                        IS_DANGEROUS_CARGO: null,
                        DANGEROUS_CARGO: null,
                    },
                    UPDATED_AT: null,
                    UPDATED_BY: null,
                    CREATED_AT: null,
                    CREATED_BY: null,
                    _id: null
                };
            }

            this.scope.steps = {
                current: ETabStep.PRE_TAB,
                lastStep: ETabStep.CONFIRMATION_TAB,
                percent: 0
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private initScopeFunctions(): void {
        // Modal
        this.scope.openCargoPolLbModal = () => this.openCargoPolLbModal();

        // Field Changes
        this.scope.productChange = () => this.productChange();
        this.scope.getEventListByProduct = () => this.getEventListByProduct();
        this.scope.getAndUpdateRouteAccountValues = (routeIndex: number, masterDirect: boolean) =>
            this.getAndUpdateRouteAccountValues(routeIndex, masterDirect);
        this.scope.specRouteAgentChange = (index: number): void => this.specRouteAgentChange(index);
        this.scope.specDangerousCargoChange = () => this.specDangerousCargoChange();
        this.scope.specSecureChange = () => this.specSecureChange();
        this.scope.chargeNameChange = (optionChargeName: SelectorModel) => this.chargeNameChange(optionChargeName);
        this.scope.updateCargoCalculateFieldsDisabledControl = (amount) => this.updateCargoCalculateFieldsDisabledControl(amount);
        this.scope.resetCargoTabModel = () => this.resetCargoTabModel();
        this.scope.cargoAirChargeableWeightChange = () => this.cargoAirChargeableWeightChange();
        this.scope.cargoAirOverChargeableWeightChange = () => this.cargoAirOverChargeableWeightChange();
        this.scope.grossWeightAndCBMChange = () => this.grossWeightAndCBMChange();
        this.scope.updateWeightRangeBase = () => this.updateWeightRangeBase();
        this.scope.cargoListGrossWeightFieldsControl = (grossWeightTotal: number) => this.cargoListGrossWeightFieldsControl(grossWeightTotal);
        this.scope.moveTypeChange = () => this.moveTypeChange();
        this.scope.clearRoutingPoint = () => this.clearRoutingPoint();

        // Search
        this.scope.getCustomerListByName = (search: string) => this.getCustomerListByName(search);
        this.scope.getExporterListByName = (search: string) => this.getExporterListByName(search);
        this.scope.getImporterListByName = (search: string) => this.getImporterListByName(search);
        this.scope.getNotifyListByName = (search: string) => this.getNotifyListByName(search);
        this.scope.getBrokerListByName = (search: string) => this.getBrokerListByName(search);
        this.scope.getProviderListByName = (search: string) => this.getProviderListByName(search);
        this.scope.getIncotermListByName = (search: string) => this.getIncotermListByName(search);
        this.scope.getCurrencyListByName = (search: string) => this.getCurrencyListByName(search);
        this.scope.getRoutingPointListByName = (search: string) => this.getRoutingPointListByName(search);
        this.scope.getCommodityListByName = (search: string) => this.getCommodityListByName(search);
        this.scope.getCommoditySectionListByName = (search: string) => this.getCommoditySectionListByName(search);
        this.scope.getEquipmentListByName = (search: string) => this.getEquipmentListByName(search);
        this.scope.getChargeNameListByName = (search: string) => this.getChargeNameListByName(search);
        this.scope.getSpecApplicationListByName = (search: string) => this.getSpecApplicationListByName(search);
        this.scope.getInsuranceBrokerListByName = (search: string) => this.getInsuranceBrokerListByName(search);
        this.scope.getAgentListByName = (search: string, networkId: number) => this.getAgentListByName(search, networkId);

        // Navigation
        this.scope.nextStep = () => this.nextStep();
        this.scope.backStep = () => this.backStep();
        this.scope.tabClick = (tabIndex: number) => this.tabClick(tabIndex);
        this.scope.goToCustomer = (id?: number) => this.goToCustomer(id);
        this.scope.goToIncoterm = (id?: number) => this.goToIncoterm(id);
        this.scope.goToExporter = (id?: number) => this.goToExporter(id);
        this.scope.goToImporter = (id?: number) => this.goToImporter(id);
        this.scope.goToProvider = (id?: number) => this.goToProvider(id);
        this.scope.goToAgent = (id?: number) => this.goToAgent(id);
        this.scope.goToWeightRange = (weightRanges: SelectorModel[]) => this.goToWeightRange(weightRanges);
        this.scope.goToAccountRequirement = (accountId?: number, requirementId?: number) =>
            this.goToAccountRequirement(accountId, requirementId);
        this.scope.goToProcess = (processNumber: string) => this.goToProcesses(processNumber);

        // Multilines Control
        this.scope.addEvent = () => this.addEvent();
        this.scope.removeEvent = (index: number) => this.removeEvent(index);
        this.scope.selectAllEvents = () => this.selectAllEvents();
        this.scope.addCargo = () => this.addCargo();
        this.scope.removeCargo = (index: number) => this.removeCargo(index);
        this.scope.addCargoFCL = () => this.addCargoFCL();
        this.scope.removeCargoFCL = (index: number) => this.removeCargoFCL(index);
        this.scope.addCargoFTL = () => this.addCargoFTL();
        this.scope.removeCargoFTL = (index: number) => this.removeCargoFTL(index);
        this.scope.addDangerousCargo = () => this.addDangerousCargo();
        this.scope.removeDangerousCargo = (index: number) => this.removeDangerousCargo(index);
        this.scope.addCargoBBRORO = () => this.addCargoBBRORO();
        this.scope.removeCargoBBRORO = (index: number) => this.removeCargoBBRORO(index);

        // Check Methods
        this.scope.isTabEnabled = (tabIndex: number) => this.isTabEnabled(tabIndex);
        this.scope.isCargoTotalGrossWeightDisabled = () => this.isCargoTotalGrossWeightDisabled();
        this.scope.isAirChargeableWeightDisabled = () => this.isAirChargeableWeightDisabled();
        this.scope.isLclAmountDisabled = () => this.isLclAmountDisabled();
        this.scope.isProcessMaritimeProduct = () => this.isProcessMaritimeProduct();
        this.scope.isProcessAirProduct = () => this.isProcessAirProduct();
        this.scope.isProcessRoadProduct = () => this.isProcessRoadProduct();
        this.scope.isGeneratedAccountRequirement = () => this.isGeneratedAccountRequirement();

        // Cargo Calculations
        this.scope.calculateCargoGrossWeightTotal = (index: number) => this.calculateCargoGrossWeightTotal(index);
        this.scope.calculateGrossWeightTotal = () => this.calculateGrossWeightTotal();
        this.scope.calculateCargoCBW = (index: number) => this.calculateCargoCBW(index);
        this.scope.calculateVolumeTotal = () => this.calculateVolumeTotal();

        // Preview and Confirmation Step
        this.scope.getAndUpdateConfirmationList = () => this.getAndUpdateConfirmationList();
        this.scope.cancelConfirmationSearch = () => this.cancelConfirmationSearch();
        this.scope.retryGenerateProcess = () => this.retryGenerateProcess();
        this.scope.finish = () => this.finish();
        this.scope.viewOptionErrors = (errors: IError[]) => this.viewOptionErrors(errors);
        this.scope.hasAnySearchControlInProgress = () => this.hasAnySearchControlInProgress();
    }

    private isTabEnabled(tabIndex) {
        if (!this.scope.steps || tabIndex == null) return false;

        const previousStep = this.scope.steps.current - 1;
        const nextStep = this.scope.steps.current + 1;

        return (previousStep == tabIndex || nextStep == tabIndex);
    }

    private isProcessMaritimeProduct() {
        const productId = this.scope.model.PRE_TAB && this.scope.model.PRE_TAB.PRODUCT && this.scope.model.PRE_TAB.PRODUCT.ID;
        if (!productId) return false;

        return [EProductId.MARITIME_EXPORT, EProductId.MARITIME_IMPORT].some(item => item === productId);
    }

    private isProcessAirProduct() {
        const productId = this.scope.model.PRE_TAB && this.scope.model.PRE_TAB.PRODUCT && this.scope.model.PRE_TAB.PRODUCT.ID;
        if (!productId) return false;

        return [EProductId.AIR_EXPORT, EProductId.AIR_IMPORT].some(item => item === productId);
    }

    private isProcessRoadProduct() {
        const productId = this.scope.model.PRE_TAB && this.scope.model.PRE_TAB.PRODUCT && this.scope.model.PRE_TAB.PRODUCT.ID;
        if (!productId) return false;

        return [EProductId.ROAD_EXPORT, EProductId.ROAD_IMPORT, EProductId.ROAD_NATIONAL].some(item => item === productId);
    }

    private isCargoTotalGrossWeightDisabled(): boolean {
        const cargoList = this.scope.model.CARGO_TAB.CARGO_LIST;

        return cargoList && cargoList.findIndex(cargo => (cargo.WEIGHT || cargo.WEIGHT == 0) || (cargo.TOTAL_WEIGHT || cargo.TOTAL_WEIGHT == 0)) >= 0;
    }

    private isAirChargeableWeightDisabled(): boolean {
        return ((this.scope.model.CARGO_TAB.CARGO_LIST && this.scope.model.CARGO_TAB.CARGO_LIST.length > 0) || (this.scope.model.CARGO_TAB && this.scope.model.CARGO_TAB.CARGO_DETAIL.STAGGERED) || this.scope.model.CARGO_TAB.CARGO_DETAIL.VOLUME || this.scope.model.CARGO_TAB.CARGO_DETAIL.GROSS_WEIGHT || this.scope.model.CARGO_TAB.CARGO_DETAIL.CBM) ? true : false;
    }

    private isLclAmountDisabled(): boolean {
        return ((this.scope.model.CARGO_TAB.CARGO_LIST && this.scope.model.CARGO_TAB.CARGO_LIST.length > 0) || (this.scope.model.CARGO_TAB && this.scope.model.CARGO_TAB.CARGO_DETAIL.VOLUME) || this.scope.model.CARGO_TAB.CARGO_DETAIL.GROSS_WEIGHT || this.scope.model.CARGO_TAB.CARGO_DETAIL.CBM) ? true : false;
    }

    private isGeneratedAccountRequirement(): boolean {
        return (this.scope.model.SPECIFICITY_TAB.ROUTE && this.scope.model.SPECIFICITY_TAB.ROUTE.find(route => route.ACCOUNT_REQUIREMENT_ID) ? true : false);
    }

    private tabClick(tabIndex: number) {
        if (!this.scope.steps || tabIndex == null) return;

        const previousStep = this.scope.steps.current - 1;
        const nextStep = this.scope.steps.current + 1;

        if (previousStep == tabIndex) this.scope.backStep();
        else if (nextStep == tabIndex) this.scope.nextStep();
    }

    private goToCustomer(id?: number) {
        const param: ILinkParameter = { ID: (id) ? id.toString() : null };

        this.$SessionService.openTab("app.commercial.account", param);
    }

    private goToIncoterm(id?: number) {
        const param: ILinkParameter = { ID: (id) ? id.toString() : null };

        this.$SessionService.openTab("app.registration.incoterm", param);
    }

    private goToExporter(id?: number) {
        const param: ILinkParameter = { ID: (id) ? id.toString() : null };

        this.$SessionService.openTab("app.registration.legalPerson", param);
    }

    private goToImporter(id?: number) {
        const param: ILinkParameter = { ID: (id) ? id.toString() : null };

        this.$SessionService.openTab("app.registration.legalPerson", param);
    }

    private goToProvider(id?: number) {
        const param: ILinkParameter = { ID: (id) ? id.toString() : null }

        this.$SessionService.openTab("app.registration.provider", param);
    }

    private goToAgent(id?: number) {
        const param: ILinkParameter = { ID: (id) ? id.toString() : null }

        this.$SessionService.openTab("app.registration.agent", param);
    }

    private goToWeightRange(weightRanges: SelectorModel[]) {
        const param: ILinkParameter = { ID: this.getCONCAT(weightRanges, null, "ID") };

        this.$SessionService.openTab("app.registration.weightRange", param);
    }

    private goToAccountRequirement(accountId: number, requirementId: number) {
        const param: ILinkParameter = { ID: (accountId) ? accountId.toString() : null };
        const accountExchange: IAccountExchangeData = { OPERATION: EOperation.VIEW, ID: Number(accountId), ID_REQUIREMENT: Number(requirementId) };

        this.$SessionService.openTab("app.commercial.account", param, accountExchange);
    }

    private goToProcesses(processNumber: string) {
        const param: IProcessParameter = { PROCESS_NUMBER: processNumber };

        this.$SessionService.openTab("app.operational.newProcess.list", param);
    }

    private async getCustomerListByName(search: string): Promise<void> {
        try {
            if (search && search.length >= 3) {
                this.block();

                const customerSelectorList: ICustomerSelector[] = [];
                const request: IMonacoRequest = {
                    data: { search },
                    route: `/account/list/custom`,
                    timeout: 30000,
                };

                const rc = await this.ProductService.post(request, false);
                const accountList = (rc && rc.data && rc.data.data && rc.data.data.data) ? rc.data.data.data : null;

                for (const item of accountList) {
                    const customerSelector: ICustomerSelector = {
                        ID: item.ID,
                        NAME: item.NAME,
                        CODE: item.LEGAL_PERSON && item.LEGAL_PERSON.ID_FISCAL,
                        LEGAL_PERSON_ID: item.LEGAL_PERSON && item.LEGAL_PERSON.ID
                    }

                    customerSelectorList.push(customerSelector);
                }

                this.scope.customerList = customerSelectorList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getExporterListByName(search: string): Promise<void> {
        try {
            if (search && search.length >= 3) {
                this.block();

                const entityList: IEntity[] = [];
                const request: IMonacoRequest = {
                    data: {
                        search,
                        specializations: [ELegalPersonSpecializationId.EXPORTER]
                    },
                    route: `/legalPerson/list/custom/operational`,
                    timeout: 30000,
                };

                const rc = await this.ProductService.post(request, true);
                const legalPersonList = (rc && rc.data && rc.data.data) ? rc.data.data : null;

                for (const item of legalPersonList) {
                    const entity: IEntity = {
                        ID: item.ID,
                        NAME: item.NAME,
                        CODE: item.CODE,
                        TRADING_NAME: item.TRADING_NAME,
                        TAXPAYER_NUMBER: item.TAXPAYER_NUMBER,
                        SCAC: item.SCAC,
                        NUMBER_IATA: item.NUMBER_IATA,
                        ADDRESS: item.ADDRESS,
                        ID_LEGAL_PERSON: item.ID_LEGAL_PERSON,
                        ID_PHYSICAL_PERSON: item.ID_PHYSICAL_PERSON,
                        NETWORK: item.NETWORK,
                        CORPORATE_NAME: item.CORPORATE_NAME
                    }

                    entityList.push(entity);
                }
                this.scope.exporterList = entityList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getImporterListByName(search: string): Promise<void> {
        try {
            if (search && search.length >= 3) {
                this.block();

                const entityList: IEntity[] = [];
                const request: IMonacoRequest = {
                    data: {
                        search,
                        specializations: [ELegalPersonSpecializationId.IMPORTER]
                    },
                    route: `/legalPerson/list/custom/operational`,
                    timeout: 30000,
                };

                const rc = await this.ProductService.post(request, true);
                const legalPersonList = (rc && rc.data && rc.data.data) ? rc.data.data : null;

                for (const item of legalPersonList) {
                    const entity: IEntity = {
                        ID: item.ID,
                        NAME: item.NAME,
                        CODE: item.CODE,
                        TRADING_NAME: item.TRADING_NAME,
                        TAXPAYER_NUMBER: item.TAXPAYER_NUMBER,
                        SCAC: item.SCAC,
                        NUMBER_IATA: item.NUMBER_IATA,
                        ADDRESS: item.ADDRESS,
                        ID_LEGAL_PERSON: item.ID_LEGAL_PERSON,
                        ID_PHYSICAL_PERSON: item.ID_PHYSICAL_PERSON,
                        NETWORK: item.NETWORK,
                        CORPORATE_NAME: item.CORPORATE_NAME
                    }

                    entityList.push(entity);
                }

                this.scope.importerList = entityList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getNotifyListByName(search: string): Promise<void> {
        try {
            if (search && search.length >= 3) {
                this.block();

                const entityList: IEntity[] = [];
                const request: IMonacoRequest = {
                    data: {
                        search,
                        specializations: [ELegalPersonSpecializationId.NOTIFY]
                    },
                    route: `/legalPerson/list/custom/operational`,
                    timeout: 30000,
                };

                const rc = await this.ProductService.post(request, true);
                const legalPersonList = (rc && rc.data && rc.data.data) ? rc.data.data : null;

                for (const item of legalPersonList) {
                    const entity: IEntity = {
                        ID: item.ID,
                        NAME: item.NAME,
                        CODE: item.CODE,
                        TRADING_NAME: item.TRADING_NAME,
                        TAXPAYER_NUMBER: item.TAXPAYER_NUMBER,
                        SCAC: item.SCAC,
                        NUMBER_IATA: item.NUMBER_IATA,
                        ADDRESS: item.ADDRESS,
                        ID_LEGAL_PERSON: item.ID_LEGAL_PERSON,
                        ID_PHYSICAL_PERSON: item.ID_PHYSICAL_PERSON,
                        NETWORK: item.NETWORK,
                        CORPORATE_NAME: item.CORPORATE_NAME
                    }

                    entityList.push(entity);
                }

                this.scope.notifyList = entityList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getBrokerListByName(search: string): Promise<void> {
        try {
            if (search && search.length >= 3) {
                this.block();

                const entityList: IEntity[] = [];
                const request: IMonacoRequest = {
                    data: {
                        search,
                        products: (this.scope.model.PRE_TAB.PRODUCT) ? [this.scope.model.PRE_TAB.PRODUCT.ID] : []
                    },
                    route: `/broker/list/custom/operational`,
                    timeout: 30000,
                };

                const rc = await this.ProductService.post(request, true);
                const legalPersonList = (rc && rc.data && rc.data.data && rc.data.data.data) ? rc.data.data.data : null;

                for (const item of legalPersonList) {
                    const entity: IEntity = {
                        ID: item.ID,
                        NAME: item.NAME,
                        CODE: item.CODE,
                        TRADING_NAME: item.TRADING_NAME,
                        TAXPAYER_NUMBER: item.TAXPAYER_NUMBER,
                        SCAC: item.SCAC,
                        NUMBER_IATA: item.NUMBER_IATA,
                        ADDRESS: item.ADDRESS,
                        ID_LEGAL_PERSON: item.ID_LEGAL_PERSON,
                        ID_PHYSICAL_PERSON: item.ID_PHYSICAL_PERSON,
                        NETWORK: item.NETWORK,
                        CORPORATE_NAME: item.CORPORATE_NAME
                    }

                    entityList.push(entity);
                }

                this.scope.brokerList = entityList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getInsuranceBrokerListByName(search: string): Promise<void> {
        try {
            if (search && search.length >= 3) {
                this.block();

                const entityList: IEntity[] = [];
                const request: IMonacoRequest = {
                    data: {
                        search,
                        specializations: [ELegalPersonSpecializationId.INSURANCE_BROKER]
                    },
                    route: `/legalPerson/list/custom/operational`,
                    timeout: 30000,
                };

                const rc = await this.ProductService.post(request, true);
                const legalPersonList = (rc && rc.data && rc.data.data) ? rc.data.data : null;

                for (const item of legalPersonList) {
                    const entity: IEntity = {
                        ID: item.ID,
                        NAME: item.NAME,
                        CODE: item.CODE,
                        TRADING_NAME: item.TRADING_NAME,
                        TAXPAYER_NUMBER: item.TAXPAYER_NUMBER,
                        SCAC: item.SCAC,
                        NUMBER_IATA: item.NUMBER_IATA,
                        ADDRESS: item.ADDRESS,
                        ID_LEGAL_PERSON: item.ID_LEGAL_PERSON,
                        ID_PHYSICAL_PERSON: item.ID_PHYSICAL_PERSON,
                        NETWORK: item.NETWORK,
                        CORPORATE_NAME: item.CORPORATE_NAME
                    }

                    entityList.push(entity);
                }

                this.scope.insuranceBrokerList = entityList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getProviderListByName(search: string): Promise<void> {
        try {
            if (search && search.length >= 3) {
                this.block();

                const providerModelList: IAccountSelector[] = [];
                const request: IMonacoRequest = {
                    data: { search },
                    route: `/provider/list/custom`,
                    timeout: 30000,
                };

                const rc = await this.ProductService.post(request, false);
                const accountList = (rc && rc.data && rc.data.data) ? rc.data.data : null;

                for (const item of accountList) {
                    const selectorModel: IAccountSelector = {
                        ID: item.ID,
                        NAME: item.NAME,
                        CODE: item.SCAC_IATA,
                        LEGAL_PERSON_ID: item.ID_LEGAL_PERSON
                    }

                    providerModelList.push(selectorModel);
                }

                this.scope.providerList = providerModelList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getIncotermListByName(search: string): Promise<void> {
        try {
            this.block();

            const selectorModelList: ISelectorModel[] = [];
            const products = this.scope.model.PRE_TAB.PRODUCT ? [this.scope.model.PRE_TAB.PRODUCT.ID] : [];
            const request: IMonacoRequest = {
                data: {
                    search,
                    products
                },
                route: `/incoterm/list/custom`,
                timeout: 30000,
            };

            const rc = await this.ProductService.post(request, false);
            const accountList = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            for (const item of accountList) {
                const selectorModel: ISelectorModel = {
                    ID: item.ID,
                    NAME: item.NAME,
                    CODE: item.INITIALS
                }

                selectorModelList.push(selectorModel);
            }

            this.scope.incotermList = selectorModelList;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getCurrencyListByName(search?: string): Promise<void> {
        try {
            this.block();

            const selectorModelList: ISelectorModel[] = [];
            const request: IMonacoRequest = {
                data: { search },
                route: `/currency/list/custom`,
                timeout: 30000,
            };

            const rc = await this.ProductService.post(request, false);
            const currencyList = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            for (const item of currencyList) {
                const selectorModel: ISelectorModel = {
                    ID: item.ID,
                    NAME: item.NAME,
                    CODE: item.INITIALS
                }

                selectorModelList.push(selectorModel);
            }

            this.scope.currencyList = selectorModelList;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getRoutingPointListByName(search: string): Promise<void> {
        try {
            if (search && search.length >= 2) {
                this.block();

                const typeLocal: string[] = [];
                switch (this.scope.model.PRE_TAB.PRODUCT.ID) {
                    case EProductId.MARITIME_EXPORT:
                    case EProductId.MARITIME_IMPORT:
                        typeLocal.push(ETypeLocalId.PORT);
                        break;

                    case EProductId.ROAD_EXPORT:
                    case EProductId.ROAD_IMPORT:
                    case EProductId.ROAD_NATIONAL:
                        typeLocal.push(ETypeLocalId.DOOR)
                        break;

                    case EProductId.AIR_EXPORT:
                    case EProductId.AIR_IMPORT:
                        typeLocal.push(ETypeLocalId.AIRPORT)
                        break;
                };

                const selectorModelList: ISelectorModel[] = [];
                const request: IMonacoRequest = {
                    data: {
                        name: search,
                        types: typeLocal
                    },
                    route: `/routingpoint/list/custom`,
                    timeout: 30000,
                };

                const rc = await this.ProductService.post(request, false);
                let routingPointList = (rc && rc.data && rc.data.data && rc.data.data.data) ? rc.data.data.data : null;

                for (const item of routingPointList) {
                    const selectorModel: ILocalFull = {
                        ID: item.ID,
                        NAME: item.NAME,
                        CODE: item.CODE,
                        DISPLAY_NAME: item.DISPLAY_NAME
                    }

                    selectorModelList.push(selectorModel);
                }

                this.scope.routingPointList = selectorModelList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getCommodityListByName(search?: string): Promise<void> {
        try {
            if (search && search.length >= 2) {

                this.block();

                const commodityModelList: ICommodityModel[] = [];
                const request: IMonacoRequest = {
                    data: { name: search },
                    route: `${this.config.productUrl}/product/commodity/list/custom`,
                    timeout: 30000,
                };

                const commodityList = await this.$RestService.newObjectPromise(request.route, request.data, request.timeout, false);

                for (const item of commodityList) {
                    const commodityModel: ICommodityModel = {
                        ID: item.ID.toString(),
                        NAME: item.NAME,
                        HS_CODE: item.HS_CODE
                    }

                    commodityModelList.push(commodityModel);
                }

                this.scope.commodityList = commodityModelList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getCommoditySectionListByName(search?: string): Promise<void> {
        try {
            if (search && search.length >= 2) {
                this.block();

                const selectorModelList: ISelectorModel[] = [];
                const request: IMonacoRequest = {
                    route: `/generic/value/hscode_section`,
                    timeout: 30000,
                };

                const rc = await this.helperService.get(request.route, null, request.timeout);
                const commoditySectionList = (rc && rc.data && rc.data.data) ? rc.data.data : null;

                for (const item of commoditySectionList) {
                    const selectorModel: ISelectorModel = {
                        ID: item.ID,
                        NAME: item.NAME,
                        CODE: item.HS_CODE
                    }

                    selectorModelList.push(selectorModel);
                }

                this.scope.commoditySectionList = selectorModelList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getWeightRangeList(): Promise<void> {
        try {
            this.block();

            const selectorModelList: IWeightRangeSelector[] = [];
            const data = (this.scope.model.PRE_TAB && this.scope.model.PRE_TAB.PRODUCT && this.scope.model.PRE_TAB.PRODUCT.ID) ? { products: [this.scope.model.PRE_TAB.PRODUCT.ID] } : null
            const request: IMonacoRequest = {
                data,
                route: `/weightRange/list/custom`,
                timeout: 30000,
            };

            const rc = await this.ProductService.post(request, false);
            const weightRangeList = (rc && rc.data && rc.data.data && rc.data.data.data) ? rc.data.data.data : null;

            for (const item of weightRangeList) {
                const selectorModel: IWeightRangeSelector = {
                    ID: item.ID,
                    NAME: item.NAME,
                    CODE: item.CODE,
                    MIN_WEIGHT: item.MIN_WEIGHT,
                    MAX_WEIGHT: item.MAX_WEIGHT
                }

                selectorModelList.push(selectorModel);
            }

            this.scope.weightRangeList = selectorModelList;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getEquipmentListByName(search: string): Promise<void> {
        try {
            this.block();

            const equipmentSelector: IEquipmentSelector[] = [];
            const request: IMonacoRequest = {
                data: {
                    search, products: [this.scope.model.PRE_TAB.PRODUCT.ID]
                },
                route: `/equipment/list/custom`,
                timeout: 30000,
            };

            const rc = await this.ProductService.post(request, false);
            const equipmentList = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            for (const item of equipmentList) {
                const processEquipmentSelector: IEquipmentSelector = {
                    ID: item.ID,
                    NAME: item.NAME,
                    CODE: item.CODE,
                    TEU: item.TEU,
                    FEET: item.FEET
                }

                equipmentSelector.push(processEquipmentSelector);
            }

            this.scope.equipmentList = equipmentSelector;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getApplicationListByName(internalSequence: string) {
        try {
            this.block();

            const request: IMonacoRequest = {
                data: {
                    internalSequence
                },
                route: `/application/list/custom`,
                timeout: 30000,
            };

            const rc = await this.ProductService.post(request, false);
            const aplicationList = (rc && rc.data && rc.data.data) ? rc.data.data : null;
            if (aplicationList && !aplicationList.length) this.notifyError('It was not possible to find the Cubed Weight unit in the application register.');

            const result = {
                ID: aplicationList[0].ID,
                NAME: aplicationList[0].NAME,
                CODE: aplicationList[0].CODE
            }

            return result;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getRouteAccountValues(filter: IOfferWizardRequestRouteAccount): Promise<IOfferWizardAccountResult> {
        try {
            if (!filter) this.notifyError('Missing filter in getRouteAccountValues');

            const request: IMonacoRequest = {
                data: filter,
                route: `/offerWizard/account`,
                timeout: 30000,
            };

            const rc = await this.ProductService.post(request);
            const result: IOfferWizardAccountResult = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            return result;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getIncotermValues(filter: IOfferWizardRequestIncoterm): Promise<IOfferWizardIncotermResult> {
        try {
            if (!filter) this.notifyError('Missing filter in getRouteAccountValues');

            const result = await this.$RestService.newObjectPromise(`${this.config.productUrl}/product/offerWizard/incoterm`, filter, 30000, false);

            return result;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getMoveTypeList(): Promise<IMoveTypeModel[]> {
        try {
            const request: IMonacoRequest = {
                route: `/moveType/list/full`,
                timeout: 30000,
            };

            const rc = await this.ProductService.get(request);
            const result = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            return result;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getAgentListByName(search: string, networkId: number): Promise<void> {
        try {
            if (search && search.length >= 3) {
                this.block();

                const agentSelectorList: IAgentSelector[] = [];
                const request: IMonacoRequest = {
                    data: {
                        search,
                        products: [this.scope.model.PRE_TAB.PRODUCT.ID],
                        networks: (networkId) ? [networkId] : []
                    },
                    route: `/agent/list/custom`,
                    timeout: 30000,
                };

                const rc = await this.ProductService.post(request, false);
                const agentList = (rc && rc.data && rc.data.data && rc.data.data.data) ? rc.data.data.data : null;

                for (const item of agentList) {
                    const agentSelector: IAgentSelector = {
                        ID: item.ID,
                        NAME: item.NAME,
                        CODE: item.NETWORK_ID,
                        LEGAL_PERSON_ID: item.LEGAL_PERSON_ID
                    }

                    agentSelectorList.push(agentSelector);
                }

                this.scope.agentList = agentSelectorList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getProductConfigurationList(): Promise<void> {
        try {
            const result = await this.$RestService.newObjectPromise(`${this.config.productUrl}/product/productConfiguration/list/offer`, {}, 30000, false);

            this.scope.productConfigurationList = result && result.data;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getChargeNameListByName(search: string): Promise<void> {
        try {
            if (search && search.length >= 2) {
                this.block();

                const selectorModelList: SelectorModel[] = [];
                const filter = {
                    search,
                    types: [EChargeOriginId.INSURANCE],
                    products: [this.scope.model.PRE_TAB.PRODUCT.ID],
                    paramTypeCargo: [this.scope.model.PRE_TAB.CARGO_TYPE.ID]
                }

                const chargeList = await this.$RestService.newObjectPromise(`${this.config.productUrl}/product/chargeName/list/custom/exhibition`, filter, 30000, false);

                for (const item of chargeList) {
                    const selectorModel: ISelectorModel = {
                        ID: item.ID,
                        NAME: item.NAME,
                        CODE: item.CODE
                    }

                    selectorModelList.push(selectorModel);
                }

                this.scope.chargeNameList = selectorModelList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getSpecApplicationListByName(search: string): Promise<void> {
        try {
            if (search && search.length >= 2) {
                this.block();

                const aplicationModelList: IApplicationList[] = [];
                const filter = {
                    search,
                    products: [this.scope.model.PRE_TAB.PRODUCT.ID],
                    typeCargos: []
                }

                const specApplicationList = await this.$RestService.newObjectPromise(`${this.config.productUrl}/product/application/list/custom`, filter, 30000, false);

                for (const item of specApplicationList) {
                    const selectorModel = {
                        ID: item.ID,
                        NAME: item.NAME,
                        CODE: item.CODE,
                        PERCENT: item.PERCENT,
                        APPLICATION_COMPLEMENT: item.APPLICATION_COMPLEMENT,
                        INTERNAL_SEQUENCE: item.INTERNAL_SEQUENCE,
                        FREE_TYPING_AMOUNT_CHARGE: item.FREE_TYPING_AMOUNT_CHARGE,
                        CT_WITHOUT_DOC: item.CT_WITHOUT_DOC,
                        NAME_INTL: item.NAME_INTL
                    }

                    aplicationModelList.push(selectorModel);
                }

                this.scope.applicationList = aplicationModelList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getAndUpdateRouteAgentValues() {
        try {
            this.block();

            const preTab = this.scope.model.PRE_TAB;
            const routesTab = this.scope.model.ROUTES_TAB;
            let origin = null;
            let destination = null;

            if (this.isProcessRoadProduct()) {
                destination = routesTab.ROUTE_EVENT.find(event => event.LOCAL && event.SELECTED && event.EVENT_INFO && event.EVENT_INFO.EVENT_TYPE && event.EVENT_INFO.EVENT_TYPE.ID === EEventType.DELIVERY);
                origin = routesTab.ROUTE_EVENT.find(event => event.LOCAL && event.SELECTED && event.EVENT_INFO && event.EVENT_INFO.EVENT_TYPE && event.EVENT_INFO.EVENT_TYPE.ID === EEventType.PICK_UP);
            } else {
                destination = routesTab.ROUTE_EVENT.find(event => event.LOCAL && event.SELECTED && event.EVENT_INFO && event.EVENT_INFO.EVENT_TYPE && event.EVENT_INFO.EVENT_TYPE.ID === EEventType.DISCHARGE);
                origin = routesTab.ROUTE_EVENT.find(event => event.LOCAL && event.SELECTED && event.EVENT_INFO && event.EVENT_INFO.EVENT_TYPE && event.EVENT_INFO.EVENT_TYPE.ID === EEventType.LOAD);
            }
            const moveType = routesTab.MOVE_TYPE;

            const originDestination = [
                {
                    DESTINATION: destination && destination.LOCAL && { ...destination.LOCAL, ID: parseInt(destination.LOCAL.ID) },
                    ORIGIN: origin && origin.LOCAL && { ...origin.LOCAL, ID: parseInt(origin.LOCAL.ID) },
                    MOVE_TYPE: moveType
                }
            ];

            const filter = {
                ORIGIN_DESTINATION: originDestination,
                PRODUCT: (preTab.PRODUCT) ? preTab.PRODUCT.ID : null,
                TYPE_CARGO: (preTab.CARGO_TYPE) ? preTab.CARGO_TYPE.ID : null,
                PROCESS_TYPE: (preTab.PROCESS_TYPE) ? (preTab.PROCESS_TYPE.ID) : null,
                COMMODITY_SECTION: (preTab.COMMODITY) ? preTab.COMMODITY.map(commodity => parseInt(commodity.ID)) : [],
                ACCOUNT: (preTab.ACCOUNT_CUSTOMER) ? parseInt(preTab.ACCOUNT_CUSTOMER.ID) : null
            }

            const offerWizardAgent: IOfferWizardAgentResult[] = await this.$RestService.newObjectPromise(`${this.config.productUrl}/product/offerWizard/agent`, filter, 30000, false);
            const routes = this.getRoutesSpecTab(offerWizardAgent);
            const routesSpecTab: IRoute[] = [];

            for (const item of routes) {
                const filter: IOfferWizardRequestRouteAccount = {
                    ID_ACCOUNT: (preTab.ACCOUNT_CUSTOMER) ? parseInt(preTab.ACCOUNT_CUSTOMER.ID) : null,
                    PROCESS_TYPE: (preTab.PROCESS_TYPE) ? preTab.PROCESS_TYPE.ID : null,
                    ID_ROUTING_POINT_ORIGIN: (item.ORIGIN) ? parseInt(item.ORIGIN.ID) : null,
                    ID_ROUTING_POINT_DESTINATION: (item.DESTINATION) ? parseInt(item.DESTINATION.ID) : null,
                    ID_PRODUCT: (preTab.PRODUCT) ? preTab.PRODUCT.ID : null,
                    ID_INCOTERM: (preTab.INCOTERM) ? parseInt(preTab.INCOTERM.ID) : null,
                    MASTER_DIRECT: (item.MASTER_DIRECT),
                    ID_NETWORK: item.AGENT_NETWORK_ID,
                    ID_EXPORTER: (preTab.EXPORTER) ? parseInt(preTab.EXPORTER.ID) : null,
                    ID_IMPORTER: (preTab.IMPORTER) ? parseInt(preTab.IMPORTER.ID) : null,
                    ID_EXTERNAL_AGENT: (item.ROUTE_EXTERNAL_AGENT) ? parseInt(item.ROUTE_EXTERNAL_AGENT.ID) : null,
                    ID_PROVIDER: (preTab.PROVIDER) ? parseInt(preTab.PROVIDER.ID) : null,
                    ID_TYPE_CARGO: (preTab.CARGO_TYPE) ? preTab.CARGO_TYPE.ID : null,
                };

                const accountResult: IOfferWizardAccountResult = await this.getRouteAccountValues(filter);
                let masterModality = null;
                let houseModality = null;

                if (accountResult) {
                    if (accountResult.MASTER_MODALITY) masterModality = accountResult.MASTER_MODALITY.MODALITY;
                    if (accountResult.HOUSE_MODALITY) houseModality = accountResult.HOUSE_MODALITY.MODALITY;
                }

                if (!masterModality || !houseModality) {
                    const incoterm = await this.getIncotermValues({ ID_INCOTERM: this.scope.model.PRE_TAB && this.scope.model.PRE_TAB.INCOTERM ? parseInt(this.scope.model.PRE_TAB.INCOTERM.ID) : null });

                    if (!masterModality) masterModality = incoterm.INCOME;
                    if (!houseModality) houseModality = incoterm.OUTCOME;
                }

                item.ACCOUNT_REQUIREMENT_ID = (accountResult && accountResult.ID) ? accountResult.ID : null;
                item.ACCOUNT_REQUIREMENT_CONCATENATED = (accountResult && accountResult.CONCATENATED) ? accountResult.CONCATENATED : null;
                item.MASTER_MODALITY = masterModality;
                item.HOUSE_MODALITY = houseModality;

                routesSpecTab.push(item);
            }

            if (routesSpecTab && routesSpecTab.length) {
                this.scope.model.SPECIFICITY_TAB.ROUTE = [];

                for (const index in routesSpecTab) {
                    this.scope.model.SPECIFICITY_TAB.ROUTE.push(routesSpecTab[index]);

                    this.timeout(() => {
                        this.scope.selectorValidity("specRouteLocalAgent" + index);
                        this.scope.selectorValidity("specRouteExternalAgent" + index);
                        this.scope.selectorValidity("specRouteMasterModality" + index);
                        this.scope.selectorValidity("specRouteHouseModality" + index);
                    }, 200);
                }

                if (!this.isGeneratedAccountRequirement()) this.notifyError(this.getTranslate("OPERATIONAL.REQUIREMENT_NOT_FOUND"));
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getAndUpdateRouteAccountValues(routeIndex: number, masterDirect: boolean = false) {
        try {
            if (routeIndex == null) return this.notifyError('Missing routeIndex in getAndUpdateRouteAccountValues');

            const route = this.scope.model.SPECIFICITY_TAB && this.scope.model.SPECIFICITY_TAB.ROUTE && this.scope.model.SPECIFICITY_TAB.ROUTE.length && this.scope.model.SPECIFICITY_TAB.ROUTE[routeIndex] ? this.scope.model.SPECIFICITY_TAB.ROUTE[routeIndex] : null;
            const preTab = this.scope.model.PRE_TAB;

            if (!route) return this.notifyError('Missing route in getAndUpdateRouteAccountValues');
            if (!preTab) return this.notifyError('Missing preTab in getAndUpdateRouteAccountValues');

            this.block();

            const filter: IOfferWizardRequestRouteAccount = {
                ID_ACCOUNT: (preTab.ACCOUNT_CUSTOMER) ? parseInt(preTab.ACCOUNT_CUSTOMER.ID) : null,
                PROCESS_TYPE: (preTab.PROCESS_TYPE) ? preTab.PROCESS_TYPE.ID : null,
                ID_ROUTING_POINT_ORIGIN: (route.ORIGIN) ? parseInt(route.ORIGIN.ID) : null,
                ID_ROUTING_POINT_DESTINATION: (route.DESTINATION) ? parseInt(route.DESTINATION.ID) : null,
                ID_PRODUCT: (preTab.PRODUCT) ? preTab.PRODUCT.ID : null,
                ID_INCOTERM: (preTab.INCOTERM) ? parseInt(preTab.INCOTERM.ID) : null,
                MASTER_DIRECT: (masterDirect) ? masterDirect : route.MASTER_DIRECT,
                ID_NETWORK: route.AGENT_NETWORK_ID,
                ID_EXPORTER: (preTab.EXPORTER) ? parseInt(preTab.EXPORTER.ID) : null,
                ID_IMPORTER: (preTab.IMPORTER) ? parseInt(preTab.IMPORTER.ID) : null,
                ID_EXTERNAL_AGENT: (route.ROUTE_EXTERNAL_AGENT) ? parseInt(route.ROUTE_EXTERNAL_AGENT.ID) : null,
                ID_PROVIDER: (preTab.PROVIDER) ? parseInt(preTab.PROVIDER.ID) : null,
                ID_TYPE_CARGO: (preTab.CARGO_TYPE) ? preTab.CARGO_TYPE.ID : null,
            };

            const accountResult: IOfferWizardAccountResult = await this.getRouteAccountValues(filter);
            let masterModality = null;
            let houseModality = null;

            if (accountResult) {
                if (accountResult.MASTER_MODALITY) masterModality = accountResult.MASTER_MODALITY.MODALITY;
                if (accountResult.HOUSE_MODALITY) houseModality = accountResult.HOUSE_MODALITY.MODALITY;
            }

            if (!masterModality || !houseModality) {
                const incoterm = await this.getIncotermValues({ ID_INCOTERM: this.scope.model.PRE_TAB && this.scope.model.PRE_TAB.INCOTERM ? parseInt(this.scope.model.PRE_TAB.INCOTERM.ID) : null });

                if (!masterModality) masterModality = incoterm.INCOME;
                if (!houseModality) houseModality = incoterm.OUTCOME;
            }

            route.ACCOUNT_REQUIREMENT_ID = (accountResult && accountResult.ID) ? accountResult.ID : null;
            route.ACCOUNT_REQUIREMENT_CONCATENATED = (accountResult && accountResult.CONCATENATED) ? accountResult.CONCATENATED : null;
            route.MASTER_MODALITY = masterModality;
            route.HOUSE_MODALITY = houseModality;

            this.timeout(() => {
                this.scope.selectorValidity("specRouteExternalAgent" + routeIndex);
                this.scope.selectorValidity("specRouteMasterModality" + routeIndex);
                this.scope.selectorValidity("specRouteHouseModality" + routeIndex);
            }, 200)
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private getRoutesSpecTab(offerWizardAgent: IOfferWizardAgentResult[]): IRoute[] {
        try {
            const routes: IRoute[] = [];

            if (offerWizardAgent && offerWizardAgent.length) {
                for (const item of offerWizardAgent) {
                    let networkId = null;

                    if (item.LOCAL_AGENT && item.LOCAL_AGENT.ID_NETWORK) {
                        networkId = item.LOCAL_AGENT.ID_NETWORK;
                    } else if (item.EXTERNAL_AGENT && item.EXTERNAL_AGENT.ID_NETWORK) {
                        networkId = item.EXTERNAL_AGENT.ID_NETWORK;
                    } else if (item.OTHER_AGENT && item.OTHER_AGENT.ID_NETWORK) {
                        networkId = item.OTHER_AGENT.ID_NETWORK;
                    }

                    const origin = (item.ORIGIN_DESTINATION && item.ORIGIN_DESTINATION.ORIGIN) ? item.ORIGIN_DESTINATION.ORIGIN.CODE : null;
                    const destination = (item.ORIGIN_DESTINATION && item.ORIGIN_DESTINATION.DESTINATION) ? item.ORIGIN_DESTINATION.DESTINATION.CODE : null;
                    const concatenatedOriginDestination = `${origin} x ${destination}`;

                    const localAgent = (item.LOCAL_AGENT) && {
                        ID: item.LOCAL_AGENT.ID,
                        NAME: item.LOCAL_AGENT.NAME,
                        CODE: item.LOCAL_AGENT.ID_NETWORK && item.LOCAL_AGENT.ID_NETWORK.toString(),
                        LEGAL_PERSON_ID: item.LOCAL_AGENT.LEGAL_PERSON_ID
                    };
                    const externalAgent = (item.EXTERNAL_AGENT) && {
                        ID: item.EXTERNAL_AGENT.ID,
                        NAME: item.EXTERNAL_AGENT.NAME,
                        CODE: item.EXTERNAL_AGENT.ID_NETWORK && item.EXTERNAL_AGENT.ID_NETWORK.toString(),
                        LEGAL_PERSON_ID: item.EXTERNAL_AGENT.LEGAL_PERSON_ID
                    };
                    const otherAgent = (item.OTHER_AGENT) && {
                        ID: item.OTHER_AGENT.ID,
                        NAME: item.OTHER_AGENT.NAME,
                        CODE: item.OTHER_AGENT.ID_NETWORK && item.OTHER_AGENT.ID_NETWORK.toString(),
                        LEGAL_PERSON_ID: item.OTHER_AGENT.LEGAL_PERSON_ID
                    };

                    routes.push({
                        NAME: concatenatedOriginDestination,
                        ORIGIN: (item.ORIGIN_DESTINATION) ? item.ORIGIN_DESTINATION.ORIGIN : null,
                        DESTINATION: (item.ORIGIN_DESTINATION) ? item.ORIGIN_DESTINATION.DESTINATION : null,
                        MOVE_TYPE: (item.ORIGIN_DESTINATION) ? item.ORIGIN_DESTINATION.MOVE_TYPE : null,
                        MASTER_DIRECT: item.MASTER_DIRECT,
                        AGENT_NETWORK_ID: networkId,
                        ROUTE_LOCAL_AGENT: (localAgent) ? localAgent : null,
                        ROUTE_EXTERNAL_AGENT: (externalAgent) ? externalAgent : null,
                        ROUTE_THIRD_AGENT: (otherAgent) ? otherAgent : null,
                        MASTER_MODALITY: null,
                        HOUSE_MODALITY: null,
                        ACCOUNT_REQUIREMENT_ID: null,
                        ACCOUNT_REQUIREMENT_CONCATENATED: null
                    });

                }
            }

            return routes;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getApplicationListByChargeNameId(optionChargeName: SelectorModel): Promise<void> {
        try {
            if (!optionChargeName) this.notifyError("Missing optionChargeName is null");
            this.block();

            const paymentApplicationList = [];
            const receivingApplicationList = [];
            const applicationList = await this.$RestService.getObjectAsPromise(`${this.config.productUrl}/product/application/list/custom/charge_name/${optionChargeName.ID}`, 10000, null, false);

            for (const item of applicationList) {
                const payment = {
                    ID: item.PAYMENT.ID,
                    NAME: item.PAYMENT.NAME,
                    CODE: item.PAYMENT.CODE,
                    PERCENT: item.PAYMENT.PERCENT,
                    APPLICATION_COMPLEMENT: item.PAYMENT.APPLICATION_COMPLEMENT,
                    INTERNAL_SEQUENCE: item.PAYMENT.INTERNAL_SEQUENCE,
                    FREE_TYPING_AMOUNT_CHARGE: item.PAYMENT.FREE_TYPING_AMOUNT_CHARGE,
                    CT_WITHOUT_DOC: item.PAYMENT.CT_WITHOUT_DOC,
                    NAME_INTL: item.PAYMENT.NAME_INTL
                };
                const receiving = {
                    ID: item.RECEIVING.ID,
                    NAME: item.RECEIVING.NAME,
                    CODE: item.RECEIVING.CODE,
                    PERCENT: item.RECEIVING.PERCENT,
                    APPLICATION_COMPLEMENT: item.RECEIVING.APPLICATION_COMPLEMENT,
                    INTERNAL_SEQUENCE: item.RECEIVING.INTERNAL_SEQUENCE,
                    FREE_TYPING_AMOUNT_CHARGE: item.RECEIVING.FREE_TYPING_AMOUNT_CHARGE,
                    CT_WITHOUT_DOC: item.RECEIVING.CT_WITHOUT_DOC,
                    NAME_INTL: item.RECEIVING.NAME_INTL
                }

                paymentApplicationList.push(payment);
                receivingApplicationList.push(receiving);
            }

            if (paymentApplicationList && paymentApplicationList.length) this.scope.model.SPECIFICITY_TAB.INSURANCE.APPLICATION_PAYMENT = paymentApplicationList[0];
            if (receivingApplicationList && receivingApplicationList.length) this.scope.model.SPECIFICITY_TAB.INSURANCE.APPLICATION_PAYMENT = receivingApplicationList[0];
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async chargeNameChange(optionChargeName: SelectorModel): Promise<void> {
        if (!optionChargeName) return;

        await this.getApplicationListByChargeNameId(optionChargeName);
        if (this.scope.model.SPECIFICITY_TAB && this.scope.model.SPECIFICITY_TAB.IS_INSURANCE) this.scope.model.SPECIFICITY_TAB.INSURANCE.CHARGE_NAME_EXHIBITION = optionChargeName;
    }

    private updateCargoCalculateFieldsDisabledControl(amount: number) {
        this.scope.isCargoCalculatedFieldsDisabled = amount && amount > 0 && amount != 1;
    }

    private grossWeightAndCBMChange() {
        if (this.scope.model.PRE_TAB.CARGO_TYPE.ID == ECargoTypeId.AIR) {
            this.calculateOperationChargeable();
        } else if (
            this.scope.model.PRE_TAB.CARGO_TYPE.ID == ECargoTypeId.BREAK_BULK ||
            this.scope.model.PRE_TAB.CARGO_TYPE.ID == ECargoTypeId.LCL ||
            this.scope.model.PRE_TAB.CARGO_TYPE.ID == ECargoTypeId.RO_RO
        ) {
            this.calculateOperationWM();
        }
    }

    private cargoAirOverChargeableWeightChange() {
        const overtaxed = this.scope.model.CARGO_TAB.CARGO_DETAIL.OVERTAXED;

        if (!this.scope.model.CARGO_TAB.CARGO_DETAIL.STAGGERED)
            this.updateWeightBreakdown([this.scope.model.CARGO_TAB.CARGO_DETAIL.QUANTITY > overtaxed ? overtaxed : overtaxed]);
        this.updateAirApplication();
    }

    private cargoAirChargeableWeightChange() {
        const quantity = this.scope.model.CARGO_TAB.CARGO_DETAIL.QUANTITY;

        this.updateCargoCalculateFieldsDisabledControl(quantity);
        this.updateWeightBreakdown([quantity > this.scope.model.CARGO_TAB.CARGO_DETAIL.OVERTAXED ? quantity : this.scope.model.CARGO_TAB.CARGO_DETAIL.OVERTAXED]);
        this.updateAirApplication();
    }

    private async updateAirApplication(): Promise<void> {
        if (this.scope.model.PRE_TAB.CARGO_TYPE.ID == ECargoTypeId.AIR) {
            const chargeableWeight = this.scope.model.CARGO_TAB.CARGO_DETAIL.QUANTITY;
            const overChargeableWeight = this.scope.model.CARGO_TAB.CARGO_DETAIL.OVERTAXED;
            const taxedWeightInternalSequence = overChargeableWeight && overChargeableWeight > chargeableWeight ? "019" : "003";

            if (this.scope.model.CARGO_TAB.CARGO_DETAIL && this.scope.model.CARGO_TAB.CARGO_DETAIL.UNITY.ID !== taxedWeightInternalSequence) {
                /*
                    this.getApplicationListByName(taxedWeightInternalSequence)
                */
            }
        }
    }

    private updateWeightBreakdown(amounts: number[]): void {
        try {
            const weightRangeList = [];

            if (amounts && amounts.length > 0 && this.scope.weightRangeList && this.scope.weightRangeList.length > 0) {
                for (let i = 0; i < this.scope.weightRangeList.length; i++) {
                    const weightRange = this.scope.weightRangeList[i];
                    if (weightRange) {
                        for (const amount of amounts) {
                            if (amount != null && !weightRangeList.some(weightRangeObj => weightRangeObj.ID == weightRange.ID) && this.betweenNumber(amount, weightRange.MIN_WEIGHT, weightRange.MAX_WEIGHT)) {
                                weightRangeList.push({ ID: weightRange.ID, NAME: weightRange.NAME, CODE: weightRange.CODE });
                                break;
                            }
                        }
                    }
                }
            }

            this.scope.model.CARGO_TAB.CARGO_DETAIL.WEIGHT_RANGE_LIST = weightRangeList;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.updateWeightRangeBase();
        }
    }

    private resetCargoTabModel() {
        this.scope.model.CARGO_TAB.CARGO_LIST = [];
        this.scope.model.CARGO_TAB.CARGO_DETAIL = {
            EQUIPMENT_LIST: null,
            VEHICLE_LIST: null,
            WEIGHT_RANGE_BASE: null,
            WEIGHT_RANGE_LIST: null,
            EQUIPMENT_CONCATENATED: null,
            OWS: null,
            VOLUME: 0,
            GROSS_WEIGHT: 0,
            CBM: 0,
            STACKABLE: true,
            UNITY: null,
            QUANTITY: 0,
            STAGGERED: this.isProcessAirProduct() ? true : false,
            OVERTAXED: 0,
            FOODGRADE: null,
            SUPERTESTED: null,
            CARGO_SOC: true,
            FLEXITANK: false,
        };
    }

    private specRouteAgentChange(index: number): void {
        try {
            if (index == null) return this.notifyError('Missing index in specRouteAgentChange');
            if (this.scope.model.SPECIFICITY_TAB && this.scope.model.SPECIFICITY_TAB.ROUTE && this.scope.model.SPECIFICITY_TAB.ROUTE.length && this.scope.model.SPECIFICITY_TAB.ROUTE[index]) {

                const route = this.scope.model.SPECIFICITY_TAB.ROUTE[index];

                route.MASTER_MODALITY = null;
                route.HOUSE_MODALITY = null;

                this.timeout(() => {
                    this.scope.selectorValidity("specRouteMasterModality" + index);
                    this.scope.selectorValidity("specRouteHouseModality" + index);
                }, 200);

                if (!route.ROUTE_LOCAL_AGENT && !route.ROUTE_EXTERNAL_AGENT && !route.ROUTE_THIRD_AGENT) route.AGENT_NETWORK_ID = null;
                else if (route.ROUTE_LOCAL_AGENT && !route.ROUTE_EXTERNAL_AGENT && !route.ROUTE_THIRD_AGENT) route.AGENT_NETWORK_ID = parseInt(route.ROUTE_LOCAL_AGENT.CODE);
                else if (route.ROUTE_EXTERNAL_AGENT && !route.ROUTE_LOCAL_AGENT && !route.ROUTE_THIRD_AGENT) route.AGENT_NETWORK_ID = parseInt(route.ROUTE_EXTERNAL_AGENT.CODE);
                else if (route.ROUTE_THIRD_AGENT && !route.ROUTE_LOCAL_AGENT && !route.ROUTE_EXTERNAL_AGENT) route.AGENT_NETWORK_ID = parseInt(route.ROUTE_THIRD_AGENT.CODE);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private specDangerousCargoChange() {
        if (this.scope.model.SPECIFICITY_TAB.IS_DANGEROUS_CARGO) {
            this.scope.model.SPECIFICITY_TAB.DANGEROUS_CARGO = [{ UNITY: null, CLASS: null, PACKING_GROUP: null, OUTER_PACKAGE_COUNT: null, OUTER_PACKAGE_TYPE: null, PROPER_SHIPPING: null }];
        } else this.scope.model.SPECIFICITY_TAB.DANGEROUS_CARGO = null;
    }

    private async specSecureChange() {
        try {
            if (this.scope.model.SPECIFICITY_TAB.IS_INSURANCE) {
                await this.getProductConfigurationList();

                const preTabProduct: string = (this.scope.model.PRE_TAB && this.scope.model.PRE_TAB.PRODUCT) ? this.scope.model.PRE_TAB.PRODUCT.ID : null;
                const productConfigurationSecure: IProductConfigurationSecure = this.scope.productConfigurationList && this.scope.productConfigurationList.length > 0 && this.scope.productConfigurationList[0].SECURE && this.scope.productConfigurationList[0].SECURE.length > 0 ? this.scope.productConfigurationList[0].SECURE.find(secure => secure.PRODUCT.some(product => product.ID == preTabProduct)) : null;
                const secureObject = {
                    CURRENCY: null,
                    INSURED_AMOUNT: null,
                    INSURANCE_PREMIUM: null,
                    INSURANCE_RECEIVING_MIN: null,
                    COMISSION_WITHOUT_PRIZE: null,
                    INSURANCE_PAYMENT_MIN: null,
                    INSURANCE_BROKER: null,
                    ID_CHARGE_NAME_EXHIBITION: null,
                    CHARGE_NAME_EXHIBITION: null,
                    APPLICATION_PAYMENT: null,
                    APPLICATION_RECEIVING: null
                };

                if (productConfigurationSecure) {
                    secureObject.INSURANCE_PREMIUM = productConfigurationSecure.INSURANCE_PREMIUM;
                    secureObject.INSURANCE_RECEIVING_MIN = productConfigurationSecure.INSURANCE_RECEIVING_MIN;
                    secureObject.COMISSION_WITHOUT_PRIZE = productConfigurationSecure.STANDARD_COMISSION_WITHOUT_PRIZE;
                    secureObject.INSURANCE_PAYMENT_MIN = productConfigurationSecure.INSURANCE_PAYMENT_MIN;
                    secureObject.INSURANCE_BROKER = null;
                    secureObject.ID_CHARGE_NAME_EXHIBITION = productConfigurationSecure.CHARGE_NAME_EXHIBITION.ID;
                    secureObject.CHARGE_NAME_EXHIBITION = productConfigurationSecure.CHARGE_NAME_EXHIBITION;
                    secureObject.APPLICATION_PAYMENT = productConfigurationSecure.APPLICATION_PAYMENT;
                    secureObject.APPLICATION_RECEIVING = productConfigurationSecure.APPLICATION_RECEIVING;
                }

                this.scope.model.SPECIFICITY_TAB.INSURANCE = secureObject;
            } else this.scope.model.SPECIFICITY_TAB.INSURANCE = null;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private clearRoutingPoint() {
        this.scope.routingPointList = null;
    }

    private moveTypeChange() {
        if (this.scope.model.ROUTES_TAB.ROUTE_EVENT && this.scope.model.ROUTES_TAB.ROUTE_EVENT.length) {

            const routeEvents: IRouteEvent[] = [];
            for (const item of this.scope.model.ROUTES_TAB.ROUTE_EVENT) {
                const route = { ...item, LOCAL: null };

                routeEvents.push(route)
            }

            this.scope.model.ROUTES_TAB.ROUTE_EVENT = routeEvents;
            this.loadRegisterForm(false);
        }
    }

    private async productChange() {
        if (this.scope.model.PRE_TAB && this.scope.model.PRE_TAB.PRODUCT) {
            const moveTypeList = await this.getMoveTypeList();
            const isRoadProduct = this.isProcessRoadProduct();

            if (moveTypeList) this.scope.moveTypeList = moveTypeList.filter(moveType => (isRoadProduct && moveType.APPLY_ROAD) || moveType.PARAMS && (!moveType.PARAMS.some(params => params.PRODUCT) || moveType.PARAMS.some(params => params.PRODUCT && params.PRODUCT.some(paramsProduct => paramsProduct.ID == this.scope.model.PRE_TAB.PRODUCT.ID))));
        }

        this.scope.model.ROUTES_TAB.ROUTE_EVENT = [];

        await this.getIncotermListByName(null);
        await this.getWeightRangeList();
    }

    private backStep(): void {
        try {
            const lastStep = this.scope.steps.lastStep;

            if (this.scope.steps.current == lastStep) return;
            if (this.scope && this.scope.steps && this.scope.steps.current > ETabStep.PRE_TAB) {
                this.scope.steps.current--;
                this.loadRegisterForm(false);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async nextStep(): Promise<void> {
        try {
            if (!this.checkPendingFields("preProcessWizardModalForm")) return;
            const currentStep = this.scope.steps.current;

            switch (currentStep) {
                case ETabStep.PRE_TAB:
                    if (await this.validatePreTab()) this.initRouteTab();
                    break;

                case ETabStep.ROUTES_TAB:
                    if (await this.validateRoutesTab()) this.initCargoTab();
                    break;

                case ETabStep.CARGO_TAB:
                    if (await this.validateCargoTab()) this.initSpecTab();
                    break;

                case ETabStep.SPECIFICITY_TAB:
                    if (await this.validateSpecTab()) this.initPreviewTab();
                    break;

                case ETabStep.PREVIEW_TAB:
                    if (this.validatePreviewTab()) this.initConfirmationTab();
                    break;

                case ETabStep.CONFIRMATION_TAB:
                    if (this.validateConfirmTab()) this.finish();
                    break;
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private next() {
        this.loadRegisterForm(false);
        this.scope.steps.current++;
    }

    private finish() {
        if (this.scope.confirmationOptions) {
            this.scope.modalOptions.ok(true);

            const processNumbers = this.scope.confirmationOptions.map(confirmationOption => confirmationOption.CONFIRMATION ? confirmationOption.CONFIRMATION.PROCESS_NUMBER : null);
            this.refreshProcessRouteParam(processNumbers);
        }
    }

    private async getGenericValue(typeGeneric: string, alternative?: boolean) {
        const timeout: number = 10000;
        let result = [];

        if (alternative) {
            const { data: generic } = await this.helperService.get(`/generic/value/${typeGeneric}/${alternative}`, null, timeout);
            result = generic && generic.data ? generic.data : [];
        } else {
            const { data: generic } = await this.helperService.get(`/generic/value/${typeGeneric}`, null, timeout);
            result = generic && generic.data ? generic.data : [];
        }

        return result;
    }

    private async getEventListByProduct(): Promise<void> {
        try {
            this.block();

            const currentEventsList = [];
            if (this.scope.model.ROUTES_TAB.ROUTE_EVENT) {
                for (const item of this.scope.model.ROUTES_TAB.ROUTE_EVENT) {
                    if (!item.EVENT_INFO) continue;

                    const event = {
                        EVENT_ID: item.EVENT_INFO.EVENT_ID,
                        EVENT_NUMBER: item.EVENT_INFO.EVENT_NUMBER,
                        EVENT_TYPE: item.EVENT_INFO.EVENT_TYPE
                    }

                    currentEventsList.push(event);
                }
            }

            const request: IMonacoRequest = {
                data: {
                    productCode: this.scope.model.PRE_TAB.PRODUCT && this.scope.model.PRE_TAB.PRODUCT.ID,
                    currentEvents: currentEventsList
                },
                route: `/event/list`,
                timeout: 15000,
            };

            const rc = await this.OperationalService.post(request.route, request.data, request.timeout);
            const eventList = (rc && rc.data && rc.data.data) ? rc.data.data : [];

            const events = eventList.map(this.getEventList);

            this.scope.eventList = events;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private getEventList(event): IRouteEvent {
        try {
            if (!event) throw Error('Missing event in getEventList')

            return <IRouteEvent>{
                LOCAL: null,
                EVENT_INFO: {
                    EVENT_ID: event.EVENT_ID,
                    EVENT_NUMBER: event.EVENT_NUMBER,
                    EVENT_TYPE: event.EVENT,
                    EVENT_GROUP: event.EVENT_GROUP,
                    LOCAL_TYPE: event.LOCAL_TYPE,
                    EVENT_SERVICE_PROVIDER: null,
                    PROCESS_EVENT_TRANSPORT_MODE: null
                },
                SELECTED: true
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async validateRoutesTab(): Promise<boolean> {
        const events = this.scope.model.ROUTES_TAB &&
            this.scope.model.ROUTES_TAB.ROUTE_EVENT &&
            this.scope.model.ROUTES_TAB.ROUTE_EVENT.map(event => event.LOCAL && event.SELECTED && event.EVENT_INFO && event.EVENT_INFO.EVENT_TYPE && event.EVENT_INFO.EVENT_TYPE.ID);

        if (!events) {
            this.handleError('Missing LOAD/DISCHARGE event or local in tab');
            return false;
        }
        const requiredMaritimeAirEvents = [EEventType.LOAD, EEventType.DISCHARGE];
        const requiredRoadEvents = [EEventType.PICK_UP, EEventType.DELIVERY];
        let requiredProcessEvents: EEventType[] = [];
        let errorMessage: string = null;
        let validateEvents: boolean = true;

        if (this.isProcessRoadProduct()) {
            requiredProcessEvents = requiredRoadEvents;
            errorMessage = 'Missing PICK UP/DELIVERY event or local in tab';
        } else if (this.isProcessMaritimeProduct() || this.isProcessAirProduct()) {
            requiredProcessEvents = requiredMaritimeAirEvents;
            errorMessage = 'Missing LOAD/DISCHARGE event or local in tab';
        }

        validateEvents = requiredProcessEvents.every(event => events.includes(event));

        if (!validateEvents) {
            this.handleError(errorMessage);
            return false;
        }

        return await this.savePreProcessWizardFilter(EPreProcessWizardTab.ROUTES_TAB);
    }

    private async initRouteTab() {
        await this.getEventListByProduct();

        if (this.scope.eventList && this.scope.eventList.length) {
            for (const event of this.scope.eventList) {
                this.addEvent(event);
            }
        }

        this.next();
    }

    private async initCargoTab() {
        try {
            this.block();

            const cargoType = this.scope.model.PRE_TAB.CARGO_TYPE;

            switch (cargoType.ID) {
                case ECargoTypeId.AIR:
                    this.scope.model.CARGO_TAB.CARGO_DETAIL.UNITY = await this.getApplicationListByName("003");
                    break;
                case ECargoTypeId.BREAK_BULK:
                case ECargoTypeId.LCL:
                case ECargoTypeId.RO_RO:
                    this.scope.model.CARGO_TAB.CARGO_DETAIL.UNITY = await this.getApplicationListByName("015");
                    break;
            }

            if (this.isProcessAirProduct()) {
                this.scope.model.CARGO_TAB.CARGO_DETAIL.WEIGHT_RANGE_LIST = this.scope.weightRangeList;
                this.scope.model.CARGO_TAB.CARGO_DETAIL.OVERTAXED = (this.scope.model.CARGO_TAB.CARGO_DETAIL.OVERTAXED) ? this.scope.model.CARGO_TAB.CARGO_DETAIL.OVERTAXED : null;
            }

            this.next();
        } catch (ex) {
            this.handleError(ex);
            this.backStep()
        } finally {
            this.cargoListGrossWeightFieldsControl(this.scope.model.CARGO_TAB.CARGO_DETAIL.GROSS_WEIGHT);
            this.unblock();
        }
    }

    private async initSpecTab() {
        try {
            await this.getAndUpdateRouteAgentValues();

            this.next();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async initPreviewTab() {
        try {
            await this.getPreProcessPreview();

            this.next();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async initConfirmationTab() {
        try {
            const result = await this.generatePreProcess(this.scope.preProcessPreview);
            await this.getAndUpdateConfirmationList();

            if (result) this.next();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async validatePreTab(): Promise<boolean> {
        const valid = await this.savePreProcessWizardFilter(EPreProcessWizardTab.PRE_TAB);

        return valid;
    }

    private async validateCargoTab(): Promise<boolean> {
        const valid = await this.savePreProcessWizardFilter(EPreProcessWizardTab.CARGO_TAB);

        return valid;
    }

    private async validateSpecTab(): Promise<boolean> {
        const valid = await this.savePreProcessWizardFilter(EPreProcessWizardTab.SPECIFICITY_TAB);

        return valid;
    }

    private validatePreviewTab(): boolean {
        return (this.scope.preProcessPreview) ? true : false
    }

    private validateConfirmTab(): boolean {
        return (this.scope.confirmationOptions) ? true : false
    }

    private async getProcessCustomerSituation(customerId: string): Promise<SelectorModel> {
        try {
            const request: IMonacoRequest = {
                route: `/account/situation/${customerId}`,
                timeout: 30000
            };

            const rc = await this.ProductService.get(request);
            const result: SelectorModel = (rc && rc.data && rc.data.data && rc.data.data.SITUATION) ? rc.data.data.SITUATION : null;

            return result;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async savePreProcessWizardFilter(tab: string, retry?: boolean): Promise<boolean> {
        let success = true;

        try {
            this.block();
            retry = (retry) ? retry : this.scope.errorsOnFilter && this.scope.errorsOnFilter.length > 0;

            const request: IMonacoRequest = {
                data: {
                    data: this.scope.model,
                    oldData: this.scope.oldModel,
                    tab,
                    retry
                },
                route: `/preProcessWizardFilter/save`,
                timeout: 30000,
            };

            const rc = await this.ProductService.post(request, false);
            const resultOperation = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            if (resultOperation) {
                this.scope.errorsOnFilter = resultOperation.PRE_PROCESS_WIZARD_ERROR_LIST;
                this.scope.model = resultOperation.PRE_PROCESS_WIZARD_FILTER;
                this.scope.oldModel = angular.copy(this.scope.model);

                const customerSituation = await this.getProcessCustomerSituation(this.scope.model.PRE_TAB.ACCOUNT_CUSTOMER.ID);
                if (customerSituation && (customerSituation.ID == ECustomerSituationId.PROSPECT || customerSituation.ID == ECustomerSituationId.DECLINED)) {
                    this.notifyError(this.getTranslate("GENERAL.ERROR_CUSTOMER_SITUATION"));
                    success = false;
                }

                if (this.scope.errorsOnFilter && this.scope.errorsOnFilter.length) {
                    this.notifyError(this.getTranslate("GENERAL.ERROR_SENDING_REQUEST"));
                    success = false;
                }
            }
        } catch (ex) {
            success = false;
            this.handleError(ex);
        } finally {
            this.unblock();
            return success;
        }
    }

    private async openCargoPolLbModal(): Promise<void> {
        try {
            const modalId = this.ModalService.newModal();
            const modalInstance = await this.ModalService.showModalInfo(
                {
                    modalID: modalId,
                    formService: 'register',
                    template: require("../../commercial/view/modals/offerCargoPollbModal.html"),
                    size: 'full modal-overflow'
                },
                {
                    closeButtonText: 'GENERAL.CLOSE',
                    actionButtonText: 'REGISTRATION.APPLY',
                    headerText: `GENERAL.CARGO_DETAILS`
                }
            );

            const cargoListConverted = await modalInstance.result.then(
                (result) => result.$value,
                (result) => result.$value
            );

            if (cargoListConverted && this.scope.model.CARGO_TAB) {
                const cargoList = cargoListConverted.map(cargoList => {
                    return {
                        PACKAGE: cargoList.PACKAGE,
                        VOLUME: cargoList.VOLUME,
                        CCM: cargoList.LENGTH,
                        LCM: cargoList.WIDTH,
                        ACM: cargoList.HEIGHT,
                        CBM: cargoList.CUBIC_WEIGHT,
                        WEIGHT: cargoList.GROSS_WEIGHT_UNITARY,
                        TOTAL_WEIGHT: cargoList.GROSS_WEIGHT
                    }
                })

                this.scope.model.CARGO_TAB.CARGO_LIST = (this.scope.model.CARGO_TAB.CARGO_LIST) ? this.scope.model.CARGO_TAB.CARGO_LIST.concat(cargoList) : cargoList;

                if (this.scope.model.CARGO_TAB.CARGO_LIST.length) {
                    angular.element('#collapseCargoList')["collapse"]("show");

                    this.timeout(() => {
                        this.loadRegisterForm(false);
                        this.calculateVolumeTotal();
                        this.calculateGrossWeightTotal();
                        this.calculateCBWTotal();
                        this.updateAirApplication();
                        this.cargoListGrossWeightFieldsControl(this.scope.model.CARGO_TAB.CARGO_DETAIL.GROSS_WEIGHT);
                    })
                }
            }

            this.updateCargoCalculatedFields();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private cargoListGrossWeightFieldsControl(cargoGrossWeight: number) {
        this.scope.isCargoListGrossWeightFieldsDisabled = this.isCargoTotalGrossWeightDisabled() ? false : cargoGrossWeight !== null && angular.isDefined(cargoGrossWeight);
    }

    private updateCargoCalculatedFields() {
        if (this.scope.model.CARGO_TAB && this.scope.model.CARGO_TAB.CARGO_LIST && this.scope.model.CARGO_TAB.CARGO_LIST.length) {
            this.scope.isCargoCalculatedFieldsDisabled = true;
        } else {
            this.scope.isCargoCalculatedFieldsDisabled = false;
        }
    }

    private addCargo() {
        try {
            if (!this.scope.model.CARGO_TAB.CARGO_LIST) this.scope.model.CARGO_TAB.CARGO_LIST = [];

            const cargo = {
                PACKAGE: null,
                VOLUME: null,
                CCM: null,
                LCM: null,
                ACM: null,
                CBM: null,
                WEIGHT: null,
                TOTAL_WEIGHT: null
            };

            this.scope.model.CARGO_TAB.CARGO_LIST.push(cargo);
            this.updateCargoCalculatedFields();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async removeCargo(index: number): Promise<void> {
        try {
            if (index == null) throw Error('Missing index parameter in removeEvent');

            const cargoList = this.scope.model.CARGO_TAB.CARGO_LIST;
            const modalConfirmation = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: `${this.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")}`
            });

            if (cargoList.length && modalConfirmation) {
                this.block();
                this.scope.model.CARGO_TAB.CARGO_LIST.splice(index, 1);
                this.loadRegisterForm(false);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async calculateGrossWeightTotal(): Promise<void> {
        try {
            if (!this.isCargoTotalGrossWeightDisabled()) return;
            let grossWeightTotal: number = 0;

            if (this.scope.model.CARGO_TAB.CARGO_LIST && this.scope.model.CARGO_TAB.CARGO_LIST.length) {
                grossWeightTotal = this.scope.model.CARGO_TAB.CARGO_LIST.reduce((sum, cargo) => {
                    const totalWeight = (cargo.TOTAL_WEIGHT != null) ? cargo.TOTAL_WEIGHT : 0;

                    return sum + totalWeight;
                }, 0);
            }

            if (this.scope.model.CARGO_TAB.CARGO_DETAIL) {
                this.scope.model.CARGO_TAB.CARGO_DETAIL.GROSS_WEIGHT = (grossWeightTotal) ? parseFloat(grossWeightTotal.toFixed(3)) : grossWeightTotal;
                this.scope.model.CARGO_TAB.CARGO_DETAIL.QUANTITY = (this.scope.model.CARGO_TAB.CARGO_DETAIL.GROSS_WEIGHT && this.scope.model.CARGO_TAB.CARGO_DETAIL.GROSS_WEIGHT > grossWeightTotal) ? Math.ceil((this.scope.model.CARGO_TAB.CARGO_DETAIL.GROSS_WEIGHT / 0.5)) * 0.5 : grossWeightTotal;

                if (this.scope.model.PRE_TAB.CARGO_TYPE && (this.scope.model.PRE_TAB.CARGO_TYPE.ID == ECargoTypeId.BREAK_BULK || this.scope.model.PRE_TAB.CARGO_TYPE.ID == ECargoTypeId.LCL || this.scope.model.PRE_TAB.CARGO_TYPE.ID == ECargoTypeId.RO_RO)) await this.calculateOperationWM();
                this.updateWeightRangeBase();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private calculateCargoCBW(index: number) {
        if (!index && index !== 0) return this.notifyError('Missing index in calculateCargoCBW');

        if (this.scope.model.CARGO_TAB.CARGO_LIST && this.scope.model.CARGO_TAB.CARGO_LIST.length > 0 && this.scope.model.CARGO_TAB.CARGO_LIST[index]) {
            const cargo = this.scope.model.CARGO_TAB.CARGO_LIST[index];
            const vol = cargo.VOLUME != null ? cargo.VOLUME : 0;
            const length = cargo.CCM != null ? cargo.CCM : 0;
            const width = cargo.LCM != null ? cargo.LCM : 0;
            const height = cargo.ACM != null ? cargo.ACM : 0;
            const cbw = (vol * length * width * height) / 1000000;

            this.scope.model.CARGO_TAB.CARGO_LIST[index].CBM = cbw ? parseFloat(cbw.toFixed(3)) : cbw;
            this.calculateCBWTotal();
        }
    }

    private async calculateCBWTotal(): Promise<void> {
        try {
            const totalCBW = this.scope.model.CARGO_TAB.CARGO_LIST.reduce((sum, cargo) => {
                const CBM = (cargo.CBM != null) ? cargo.CBM : 0;

                return sum + CBM;
            }, 0);

            this.scope.model.CARGO_TAB.CARGO_DETAIL.CBM = totalCBW;

            switch (this.scope.model.PRE_TAB.CARGO_TYPE.ID) {
                case ECargoTypeId.AIR:
                    await this.calculateOperationChargeable();
                    break;
                case ECargoTypeId.BREAK_BULK:
                case ECargoTypeId.LCL:
                case ECargoTypeId.RO_RO:
                    await this.calculateOperationWM();
                    break;
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async calculateOperationChargeable(): Promise<void> {
        let result: number = 1;
        try {
            this.block();
            if (this.scope.model.CARGO_TAB.CARGO_DETAIL.CBM) {
                const filter = { CUBIC_WEIGHT: this.scope.model.CARGO_TAB.CARGO_DETAIL.CBM };
                const resultOperation = await this.ProductService.post({ route: "/calculationOperations/chargeable", data: filter }, false);

                if (resultOperation && resultOperation.data && resultOperation.data.data && typeof resultOperation.data.data == 'number')
                    result = resultOperation.data.data;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.scope.model.CARGO_TAB.CARGO_DETAIL.QUANTITY = (this.scope.model.CARGO_TAB.CARGO_DETAIL.GROSS_WEIGHT && this.scope.model.CARGO_TAB.CARGO_DETAIL.GROSS_WEIGHT > result) ? Math.ceil((this.scope.model.CARGO_TAB.CARGO_DETAIL.GROSS_WEIGHT / 0.5)) * 0.5 : result;

            this.updateWeightRangeBase();
            this.unblock();
        }
    }

    private async calculateOperationWM(): Promise<void> {
        let result: number = 0;
        try {
            this.block();
            if (this.scope.model.CARGO_TAB.CARGO_DETAIL.GROSS_WEIGHT && this.scope.model.CARGO_TAB.CARGO_DETAIL.CBM) {
                const filter = { GROSS_WEIGHT: this.scope.model.CARGO_TAB.CARGO_DETAIL.GROSS_WEIGHT, CUBIC_WEIGHT: this.scope.model.CARGO_TAB.CARGO_DETAIL.CBM };
                const resultOperation = await this.ProductService.post({ route: "/calculationOperations/wm", data: filter }, false);

                if (resultOperation && resultOperation.data && resultOperation.data.data && typeof resultOperation.data.data == 'number') result = resultOperation.data.data;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.scope.model.CARGO_TAB.CARGO_DETAIL.QUANTITY = result && result > 1 ? result : 1;
            this.unblock();
        }
    }

    private calculateCargoGrossWeightTotal(index: number) {
        if (!index && index !== 0) return this.notifyError('Missing index in calculateCargoGrossWeightTotal');

        if (this.scope.model.CARGO_TAB.CARGO_LIST && this.scope.model.CARGO_TAB.CARGO_LIST.length > 0 && this.scope.model.CARGO_TAB.CARGO_LIST[index] && this.scope.model.CARGO_TAB.CARGO_LIST[index].WEIGHT > 0) {
            const cargo = this.scope.model.CARGO_TAB.CARGO_LIST[index];
            const vol = (cargo.VOLUME != null) ? cargo.VOLUME : 0;
            const pbUn = (cargo.WEIGHT != null) ? cargo.WEIGHT : 0;
            const pbTotal = vol * pbUn;

            this.scope.model.CARGO_TAB.CARGO_LIST[index].TOTAL_WEIGHT = (pbTotal) ? parseFloat(pbTotal.toFixed(4)) : pbTotal;
        }

        this.calculateGrossWeightTotal();
        this.calculateCBWTotal();

        if (this.scope.model.PRE_TAB.CARGO_TYPE.ID == ECargoTypeId.AIR) this.calculateOperationChargeable();
        if (this.scope.model.PRE_TAB.CARGO_TYPE.ID == ECargoTypeId.BREAK_BULK || this.scope.model.PRE_TAB.CARGO_TYPE.ID == ECargoTypeId.LCL || this.scope.model.PRE_TAB.CARGO_TYPE.ID == ECargoTypeId.RO_RO) this.calculateOperationWM();

        this.updateAirApplication();
    }

    private calculateVolumeTotal(): void {
        try {
            if (this.scope.model.CARGO_TAB.CARGO_LIST && this.scope.model.CARGO_TAB.CARGO_LIST.length > 0) {
                const totalVolume = this.scope.model.CARGO_TAB.CARGO_LIST.reduce((sum, cargo) => {
                    const volume = (cargo.CBM != null) ? cargo.VOLUME : 0;

                    return sum + volume;
                }, 0);

                this.scope.model.CARGO_TAB.CARGO_DETAIL.VOLUME = totalVolume;
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private betweenNumber(numberToVerify: number, initial: number, final: number): boolean {
        try {
            if (numberToVerify == null) throw Error("Missing numberToVerify in betweenNumber");
            if (initial == null) throw Error("Missing initial in betweenNumber");
            if (final == null) throw Error("Missing final in betweenNumber");

            const min = Math.min.apply(Math, [initial, final]);
            const max = Math.max.apply(Math, [initial, final]);

            return numberToVerify >= min && numberToVerify <= max;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private updateWeightRangeBase(): void {
        const self: PreProcessWizardModalController = this;

        if (self.scope.model.CARGO_TAB.CARGO_DETAIL && self.scope.model.CARGO_TAB.CARGO_DETAIL.WEIGHT_RANGE_LIST) {
            const weightRangeSelected: IWeightRangeSelector[] = (self.scope.weightRangeList && this.scope.weightRangeList.length) ? self.scope.weightRangeList.filter(weightRange => self.scope.model.CARGO_TAB.CARGO_DETAIL.WEIGHT_RANGE_LIST.some(weightRangeToVerify => weightRangeToVerify.ID == weightRange.ID)) : null;

            const lowestRange = (weightRangeSelected && weightRangeSelected.length) ? weightRangeSelected.reduce(function (prev, current) {
                if (self.scope.model.CARGO_TAB.CARGO_DETAIL.QUANTITY && self.scope.model.CARGO_TAB.CARGO_DETAIL.QUANTITY > 1) {
                    const isBetweenChargeableWeight = self.betweenNumber(self.scope.model.CARGO_TAB.CARGO_DETAIL.QUANTITY, prev.MIN_WEIGHT, prev.MAX_WEIGHT);

                    return (prev.MIN_WEIGHT < current.MIN_WEIGHT && isBetweenChargeableWeight) ? prev : current;
                }

                return (prev.MIN_WEIGHT < current.MIN_WEIGHT) ? prev : current;
            }) : null;

            self.scope.model.CARGO_TAB.CARGO_DETAIL.WEIGHT_RANGE_BASE = lowestRange;
            self.timeout(() => self.scope.selectorValidity("cargoAirWeightRangeBase"));
        }
    }

    private addCargoBBRORO(): void {
        try {
            if (!this.scope.model.CARGO_TAB.CARGO_DETAIL.EQUIPMENT_LIST) this.scope.model.CARGO_TAB.CARGO_DETAIL.EQUIPMENT_LIST = [];

            const bbroro = {
                EQUIPMENT: null,
                QUANTITY: 1,
                OFTM: null,
                OFTH: null,
                DFTM: null,
                DFTH: null,
                MODIFIED_TYPE: null
            }

            this.scope.model.CARGO_TAB.CARGO_DETAIL.EQUIPMENT_LIST.push(bbroro);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async removeCargoBBRORO(index: number): Promise<void> {
        try {
            if (index == null) throw Error('Missing index parameter in removeEvent');

            const equipmentList = this.scope.model.CARGO_TAB.CARGO_DETAIL.EQUIPMENT_LIST;
            const modalConfirmation = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: `${this.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")}`
            });

            if (equipmentList && equipmentList.length && modalConfirmation) {
                this.block();
                this.scope.model.CARGO_TAB.CARGO_DETAIL.EQUIPMENT_LIST.splice(index, 1);
                this.loadRegisterForm(false);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private addCargoFCL(): void {
        try {
            if (!this.scope.model.CARGO_TAB.CARGO_DETAIL.EQUIPMENT_LIST) this.scope.model.CARGO_TAB.CARGO_DETAIL.EQUIPMENT_LIST = [];

            const fcl = {
                EQUIPMENT: null,
                QUANTITY: 1,
                OFTM: null,
                OFTH: null,
                DFTM: null,
                DFTH: null,
                MODIFIED_TYPE: null
            }

            this.scope.model.CARGO_TAB.CARGO_DETAIL.EQUIPMENT_LIST.push(fcl);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private addCargoFTL(): void {
        try {
            if (!this.scope.model.CARGO_TAB.CARGO_DETAIL.VEHICLE_LIST) this.scope.model.CARGO_TAB.CARGO_DETAIL.VEHICLE_LIST = [];

            const ftl: IVehicleList = {
                VEHICLE_TYPE: null,
                QUANTITY: null,
                FREE_TIME_PUP_MASTER: null,
                FREE_TIME_PUP_HOUSE: null,
                ORIGIN_FREE_TIME_CUSTOMS_CLEARANCE_MASTER: null,
                ORIGIN_FREE_TIME_CUSTOMS_CLEARANCE_HOUSE: null,
                ORIGIN_FREE_TIME_BORDER_CROSS_MASTER: null,
                ORIGIN_FREE_TIME_BORDER_CROSS_HOUSE: null,
                DESTINATION_FREE_TIME_CUSTOMS_CLEARANCE_MASTER: null,
                DESTINATION_FREE_TIME_CUSTOMS_CLEARANCE_HOUSE: null,
                DESTINATION_FREE_TIME_BORDER_CROSS_MASTER: null,
                DESTINATION_FREE_TIME_BORDER_CROSS_HOUSE: null,
                FREE_TIME_DELIVERY_MASTER: null,
                FREE_TIME_DELIVERY_HOUSE: null,
            }

            this.scope.model.CARGO_TAB.CARGO_DETAIL.VEHICLE_LIST.push(ftl);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async removeCargoFCL(index: number): Promise<void> {
        try {
            if (index == null) throw Error('Missing index parameter in removeEvent');

            const equipmentList = this.scope.model.CARGO_TAB.CARGO_DETAIL.EQUIPMENT_LIST;
            const modalConfirmation = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: `${this.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")}`
            });

            if (equipmentList && equipmentList.length && modalConfirmation) {
                this.block();
                this.scope.model.CARGO_TAB.CARGO_DETAIL.EQUIPMENT_LIST.splice(index, 1);
                this.loadRegisterForm(false);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async removeCargoFTL(index: number): Promise<void> {
        try {
            if (index == null) throw Error('Missing index parameter in removeCargoFTL');

            const vehicleList = this.scope.model.CARGO_TAB.CARGO_DETAIL.VEHICLE_LIST;
            const modalConfirmation = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: `${this.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")}`
            });

            if (vehicleList && vehicleList.length && modalConfirmation) {
                this.block();
                this.scope.model.CARGO_TAB.CARGO_DETAIL.VEHICLE_LIST.splice(index, 1);
                this.loadRegisterForm(false);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private addDangerousCargo() {
        try {
            if (!this.scope.model.SPECIFICITY_TAB.DANGEROUS_CARGO) this.scope.model.SPECIFICITY_TAB.DANGEROUS_CARGO = [];

            const dangerousCargo: IDangerousCargo = {
                UNITY: null,
                CLASS: null,
                PACKING_GROUP: null,
                OUTER_PACKAGE_COUNT: null,
                OUTER_PACKAGE_TYPE: null,
                PROPER_SHIPPING: null
            }

            this.scope.model.SPECIFICITY_TAB.DANGEROUS_CARGO.push(dangerousCargo);
            this.loadRegisterForm(false);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async removeDangerousCargo(index: number) {
        try {
            if (index == null) throw Error('Missing index parameter in removeEvent');

            const dangerousList = this.scope.model.SPECIFICITY_TAB.DANGEROUS_CARGO;
            const modalConfirmation = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: `${this.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")}`
            });

            if (dangerousList && dangerousList.length && modalConfirmation) {
                this.block();
                this.scope.model.SPECIFICITY_TAB.DANGEROUS_CARGO.splice(index, 1);
                this.loadRegisterForm(false);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private addEvent(routeEvent?: IRouteEvent): void {
        try {
            if (!this.scope.model.ROUTES_TAB.ROUTE_EVENT) this.scope.model.ROUTES_TAB.ROUTE_EVENT = [];

            const event = {
                SELECTED: true,
                EVENT_INFO: (routeEvent && routeEvent.EVENT_INFO) ? routeEvent.EVENT_INFO : null,
                LOCAL: (routeEvent && routeEvent.LOCAL) ? routeEvent.LOCAL : null,
            };

            this.scope.model.ROUTES_TAB.ROUTE_EVENT.push(event);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async removeEvent(index: number): Promise<void> {
        try {
            if (index == null) throw Error('Missing index parameter in removeEvent');

            const events = this.scope.model.ROUTES_TAB.ROUTE_EVENT;
            const modalConfirmation = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: `${this.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")}`
            });

            if (events && events.length && modalConfirmation) {
                this.block();
                this.scope.model.ROUTES_TAB.ROUTE_EVENT.splice(index, 1);
                this.getEventListByProduct();
                this.loadRegisterForm(false);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private selectAllEvents(): void {
        if (!this.scope.model.ROUTES_TAB.ROUTE_EVENT.length) return;
        const checkboxAll = document.getElementsByName('selectAll')[0] as HTMLInputElement;

        for (const item of this.scope.model.ROUTES_TAB.ROUTE_EVENT) {
            item.SELECTED = checkboxAll.checked;
        }
    }

    private initSearchControl() {
        this.scope.confirmationTabSearchControl = {
            isSearchTabDataListCanceled: false,
            isSearchingTabDataList: false,
            searchTabDataError: null,
            searchTabDataListCount: null,
            searchTabDataCurrentAttempt: null,
            searchTabDataMaxAttempt: null
        };
    }

    private cancelConfirmationSearch() {
        this.scope.confirmationTabSearchControl.isSearchTabDataListCanceled = true;
        this.scope.confirmationTabSearchControl.isSearchingTabDataList = false;
        this.scope.confirmationTabSearchControl.searchTabDataCurrentAttempt = 0;
    }

    private hasAnySearchControlInProgress(): boolean {
        return this.scope.confirmationTabSearchControl && this.scope.confirmationTabSearchControl.isSearchingTabDataList;
    }

    private async getPreProcessPreview(): Promise<void> {
        try {
            const request: IMonacoRequest = {
                route: `/preProcessWizardFilter/preview/${this.scope.model._id}`,
                timeout: 30000,
            };

            const rc = await this.ProductService.get(request);
            const preProcess = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            if (!preProcess) return this.notifyError(this.getTranslate("GENERAL.ERROR_SENDING_REQUEST"));

            this.scope.preProcessPreview = preProcess;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async generatePreProcess(preProcess: IPreProcess): Promise<boolean> {
        try {
            if (!preProcess) {
                this.notifyError('Missing preProcess param in generatePreProcess');
                return false;
            }

            const request: IMonacoRequest = {
                route: `/preProcessWizardStages/generate`,
                timeout: 30000,
                data: { data: preProcess }
            };

            const rc = await this.DataProcessService.post(request.route, request.data, request.timeout);
            const result = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            return result;
        } catch (ex) {
            this.handleError(ex);
            return false;
        }
    }

    private async getAndUpdateConfirmationList(): Promise<void> {
        try {
            const confirmationTabSearchControl = this.scope.confirmationTabSearchControl;
            confirmationTabSearchControl.isSearchTabDataListCanceled = false;
            this.scope.confirmationOptions = null;
            if (!confirmationTabSearchControl.isSearchingTabDataList) {
                confirmationTabSearchControl.searchTabDataListCount = 0;
                confirmationTabSearchControl.searchTabDataCurrentAttempt = 0;
                confirmationTabSearchControl.isSearchingTabDataList = true;
            }
            this.scope.confirmationOptions = [];

            const resultOperation = await this.DataProcessService.get('/preProcessWizardStages/confirmation/' + this.scope.model._id, null, 30000);

            if (resultOperation && resultOperation.data && resultOperation.data.data) {
                const result = resultOperation.data.data;
                if (result.SHOW) {
                    this.scope.errorsOnFilter = result.PROCESS_WIZARD_ERROR_LIST;
                    if (this.scope.errorsOnFilter && this.scope.errorsOnFilter.length) {
                        this.notifyError(this.getTranslate("GENERAL.ERROR_SENDING_REQUEST"));
                    }
                    this.scope.confirmationOptions = result.OPTIONS_CONFIRMATION;
                    this.scope.hasAnyConfirmationOption = this.scope.confirmationOptions && this.scope.confirmationOptions.length > 0;
                    confirmationTabSearchControl.isSearchingTabDataList = false;
                    confirmationTabSearchControl.searchTabDataCurrentAttempt = 0;
                    this.scope.$applyAsync();
                } else if (confirmationTabSearchControl.searchTabDataCurrentAttempt == confirmationTabSearchControl.searchTabDataMaxAttempt) {
                    confirmationTabSearchControl.isSearchingTabDataList = false;
                    this.scope.$applyAsync();
                    return this.notifyError(this.getTranslate("GENERAL.LIMIT_ATTEMPTS"))
                } else {
                    confirmationTabSearchControl.isSearchingTabDataList = true;
                    confirmationTabSearchControl.searchTabDataCurrentAttempt++;
                    confirmationTabSearchControl.searchTabDataListCount = 0;
                    this.scope.$applyAsync();
                    this.timeout(() => { this.initCountGetConfirmationTabDataList() }, 1000);
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private initCountGetConfirmationTabDataList() {
        if (!this.scope.confirmationTabSearchControl.isSearchTabDataListCanceled) {
            this.timeout(() => {
                this.scope.confirmationTabSearchControl.searchTabDataListCount += 5;
                if (this.scope.confirmationTabSearchControl.searchTabDataListCount < 100) this.initCountGetConfirmationTabDataList();
                else if (!this.scope.confirmationTabSearchControl.isSearchTabDataListCanceled) this.timeout(() => { this.getAndUpdateConfirmationList() }, 1000);
            }, 100);
        }
    }

    private async retryGenerateProcess() {
        try {
            if (!this.scope.preProcessPreview) return;
            this.block();

            const resultOperation = await this.DataProcessService.post('/preProcessWizardStages/retryGenerate', { data: this.scope.preProcessPreview }, 30000);
            if (resultOperation && resultOperation.data && resultOperation.data.data) {
                this.unblock();
                await this.getAndUpdateConfirmationList();
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private viewOptionErrors(errors: IError[]) {
        if (errors && errors.length) {
            let errorTrs = "";
            for (const error of errors) {
                errorTrs += `
                    <tr>
                        <td>${error.SUBJECT}</td>
                        <td>${error.REASON}</td>
                    </tr>
                `;
            }
            const errorsTable = `
                <table class="table gray-border table-bordered m-b-none table-responsive">
                    <thead>
                        <th width="30%">${this.getTranslate("REGISTRATION.SUBJECT")}</th>
                        <th width="70%">${this.getTranslate("GENERAL.REASON")}</th>
                    </thead>
                    <tbody>
                        ${errorTrs}
                    </tbody>
                </table>
            `;
            const html = `
                <div class="row">
                    <div class="col-lg-12">
                        ${errorsTable}
                    </div>
                </div>
            `;
            this.ModalService.showModalInfo({ size: 'vlg' }, {
                actionButtonText: 'GENERAL.CLOSE',
                actionButtonClass: 'btn-danger',
                headerText: 'GENERAL.ERRORS',
                bodyText: this.$sce.trustAsHtml(html)
            });
        }
    }

    private refreshProcessRouteParam(processNumbers: string[]): void {
        const sessionParameter = <IProcessParameter>{
            param: {
                PROCESS_NUMBER: this.getCONCAT(processNumbers)
            }
        };

        sessionStorage.setItem("app.operational.newProcess.list", JSON.stringify(sessionParameter));

        window.location.reload();
    }
}


import * as angular from "angular";
import * as moment from "moment";
import { INewFreightContract } from "WBA-Model/dist/interface/product/NewFreightContract";
import { IModalOptions, IModalService } from '@services/ModalService';
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { HelperService } from "@services/HelperService";
import { IRestService } from "@services/RestService";
import { EOperation, EPaymentNatureId, EProductId } from "@enums/GenericData";
import { EApplicationComplementId } from "@enums/GenericData";
import { IEquipmentSelector } from "@models/interface/product/EquipmentModel";
import { IParams, IChargeNameList } from "@models/interface/product/ChargeNameModel";
import { IApplicationList } from "@models/interface/product/ApplicationModel";
import { IFreightRoutes as IFreightRoutesModel, IFreightRoutesCharge } from "@models/interface/product/FreightRoutes";
import { ILegalPersonListCustomFilter } from "../model/LegalPersonModel";
import { IPhysicalPersonListCustomFilter } from "../model/PhysicalPersonModel";
import { IMonacoConfig } from '../../common/MonacoInterface';
import { ValidateUtil } from "../../common/util/ValidateUtil";
import { SelectorModel } from "../../common/model/SelectorModel";
import { NotificationService } from "@services/NotificationService";

interface ITariffRoutesChargesScope extends IFormServiceScope {
    // Modal Options
    modalOptions: IModalOptions;
    operation: string;

    // Index
    currentIndex: number;
    currentDisplayIndex: number;

    // Charge
    charges: IFreightRoutesCharge[];
    charge: IFreightRoutesCharge;
    oldCharge: IFreightRoutesCharge;
    chargesListDisplay: IFreightRoutesCharge[]

    // Freight Contract/Route
    freightContract: INewFreightContract;
    freightRoute: IFreightRoutesModel;

    // List
    chargeApplicationList: SelectorModel[];
    chargeNameList: IChargeNameList[];
    currencyList: SelectorModel[];
    equipmentList: IEquipmentSelector[];
    weightRangeList: SelectorModel[];
    vehicleTypeList: SelectorModel[];
    eventList: SelectorModel[];
    holderTypeList: SelectorModel[];
    routeEquipmentList: SelectorModel[];
    holderList: SelectorModel[];

    // Check Variable
    isPaymentNature: boolean;
    isReceivingNature: boolean;
    isAirProduct: boolean;

    // Modal Functions
    applyCharge: () => Promise<void>;
    closeChargeModal: () => void;
    hasPreviousCharge: (currentIndex: number) => boolean;
    hasNextCharge: (currentIndex: number) => boolean;
    previousCharge: (currentIndex: number) => void;
    nextCharge: (currentIndex: number) => void;

    // Check Functions
    isNotApplicableComplement: (applicationComplement: SelectorModel) => boolean;
    isWeightRangeComplement: (applicationComplement: SelectorModel) => boolean;
    isEquipmentComplement: (applicationComplement: SelectorModel) => boolean;
    isVehicleComplement: (applicationComplement: SelectorModel) => boolean;

    // Change Functions
    updateParam: (selected: IChargeNameList) => void;
    setChargeNameId: () => void;
    flatChange: (chargeIndex: number) => void;
    weightRangeChange: () => void;
    freeOfChargePaymentChange: (charge: IFreightRoutesCharge) => void;
    freeOfChargeReceivingChange: (charge: IFreightRoutesCharge) => void;
    checkChargeDateValidity: (initialDate: Date, finalDate: Date, scopeName: string, charge: IFreightRoutesCharge, type: string, lowestRouteValidityEnd?: Date) => void;

    // List Functions
    getChargeNameListByName: (search: string) => Promise<void>;
    getCurrencyListByName: (name: string) => Promise<void>;
    getWeightRangeListByName: (search: string) => Promise<void>;
    getHolderListByName: (search: string, holderId: string) => Promise<void>;

    // Update Functions
    updatePaymentUnit: () => void;
    updateReceivingUnit: () => void;
    updateHolderTypePayment: () => void;
    updateHolderTypeReceiving: () => void;
}

export class FreightRoutesChargesModalController extends FormService2 {
    static $inject: string[] = ['$injector', '$scope'];
    private scope: ITariffRoutesChargesScope;
    private $q: ng.IQService;
    private $RestService: IRestService;
    private timeout: ng.ITimeoutService;
    private ModalService: IModalService;
    private config: IMonacoConfig;
    private helperService: HelperService;
    private propertiesToIgnore: string[];

    constructor($injector: ng.Injectable<any>, $scope: ITariffRoutesChargesScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.$q = $injector.get('$q');
        this.$RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');
        this.config = $injector.get('config');
        this.timeout = $injector.get('$timeout');
        this.helperService = $injector.get('HelperService');

        this.propertiesToIgnore = ["selected", "uniqueIdControl"];
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    async $onInit(): Promise<void> {
        try {
            this.block();
            this.initialize();
            this.unblock();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private initDependencies(): Promise<boolean> {
        const self: FreightRoutesChargesModalController = this;

        const promises = {
            event: self.getGenericValue("event"),
            holderType: self.getGenericValue('holder_type'),
            chargeApplication: self.getChargeNameParamsList(),
            equipment: self.getEquipmentListByName(),
            currency: self.getCurrencyListByName(),
            vehicleList: self.getGenericValue("vehicle_type")
        };

        return new Promise((resolve, reject) => {
            self.$q.all(promises).then((result) => {
                self.scope.eventList = result.event;
                self.scope.holderTypeList = result.holderType;
                self.scope.chargeApplicationList = result.chargeApplication;
                self.scope.equipmentList = result.equipment;
                self.scope.currencyList = result.currency;
                self.scope.vehicleTypeList = result.vehicleList;

                resolve(true);
            }).catch((ex) => {
                reject(ex);
            });
        });
    }

    async initialize() {
        this.init('freightRoutesChargeModal', null, null);
        this.initCollapse();
        this.initValueScope();
        this.initScopeFunctions();

        await this.initDependencies();
    }

    private initCollapse() {
        angular.element('.collapseWeightRanges').on('shown.bs.collapse', (event: JQuery.Event) => {
            if (event.target == event.currentTarget) {
                angular.element("#collapsePaymentPanelHeading").attr('aria-expanded', 'true');
                angular.element("#collapseReceivingPanelHeading").attr('aria-expanded', 'true');
            }
        });

        angular.element('.collapseWeightRanges').on('hidden.bs.collapse', (event: JQuery.Event) => {
            if (event.target == event.currentTarget) {
                angular.element("#collapsePaymentPanelHeading").attr('aria-expanded', 'false');
                angular.element("#collapseReceivingPanelHeading").attr('aria-expanded', 'false');
            }
        });
    }

    private initValueScope() {
        try {
            this.scope.oldCharge = angular.copy(this.scope.charge);
            this.scope.operation = this.scope.operation == EOperation.VIEW ? EOperation.VIEW : (!this.scope.currentIndex && this.scope.currentIndex != 0) ? EOperation.NEW : EOperation.EDIT;
            this.scope.isPaymentNature = this.scope.freightContract.TRANSACTION && this.scope.freightContract.TRANSACTION.ID == EPaymentNatureId.PAYMENT;
            this.scope.isReceivingNature = this.scope.freightContract.TRANSACTION && this.scope.freightContract.TRANSACTION.ID == EPaymentNatureId.RECEIVING;
            this.scope.isAirProduct = this.scope.freightContract.PRODUCT && this.scope.freightContract.PRODUCT.ID == EProductId.AIR_EXPORT || this.scope.freightContract.PRODUCT.ID == EProductId.AIR_IMPORT;
            this.scope.chargesListDisplay = this.scope.charges && this.scope.charges.map((charge, index) => Object.assign(charge, { DISPLAY_INDEX: index }));
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initScopeFunctions(): void {
        try {
            this.scope.applyCharge = async () => {
                if (await this.applyCharge(this.scope.currentIndex, this.scope.charge, this.scope.operation, true)) {
                    this.applyChargeFilterTable();
                }
            }
            this.scope.closeChargeModal = () => {
                this.closeChargeModal();
            }
            this.scope.hasPreviousCharge = (currentDisplayIndex: number) => {
                return currentDisplayIndex > 0;
            }
            this.scope.hasNextCharge = (currentDisplayIndex: number) => {
                return (currentDisplayIndex || currentDisplayIndex == 0) && this.scope.chargesListDisplay && (this.scope.chargesListDisplay.length - 1 > currentDisplayIndex);
            }
            this.scope.previousCharge = (currentDisplayIndex: number) => {
                this.previousCharge(currentDisplayIndex);
            }
            this.scope.nextCharge = (currentDisplayIndex: number) => {
                this.nextCharge(currentDisplayIndex);
            }
            this.scope.isNotApplicableComplement = (applicationComplement: SelectorModel): boolean => {
                return !applicationComplement || (applicationComplement && applicationComplement.ID == EApplicationComplementId.NOT_APPLICABLE);
            }
            this.scope.isWeightRangeComplement = (applicationComplement: SelectorModel): boolean => {
                return applicationComplement && applicationComplement.ID == EApplicationComplementId.WEIGHT_RANGE;
            }
            this.scope.isEquipmentComplement = (applicationComplement: SelectorModel): boolean => {
                return applicationComplement && applicationComplement.ID == EApplicationComplementId.EQUIPMENT;
            }
            this.scope.isVehicleComplement = (applicationComplement: SelectorModel): boolean => {
                return applicationComplement && applicationComplement.ID == EApplicationComplementId.VEHICLE;
            }
            this.scope.updateParam = (selected: IChargeNameList): void => {
                this.updateParam(selected);
            }
            this.scope.setChargeNameId = (): void => {
                this.setChargeNameId();
            }
            this.scope.flatChange = () => {
                this.flatChange();
            }
            this.scope.weightRangeChange = () => {
                this.weightRangeChange();
            }
            this.scope.freeOfChargePaymentChange = (charge: IFreightRoutesCharge) => {
                this.freeOfChargePaymentChange(charge);
                this.timeout(() => { this.scope.selectorValidity("paymentCurrency"); });
            }
            this.scope.freeOfChargeReceivingChange = (charge: IFreightRoutesCharge) => {
                this.freeOfChargeReceivingChange(charge);
                this.timeout(() => { this.scope.selectorValidity("receivingCurrency"); });
            }
            this.scope.getChargeNameListByName = async (search: string): Promise<void> => {
                this.scope.chargeNameList = await this.getChargeNameListByName(search);
            }
            this.scope.getCurrencyListByName = async (name: string): Promise<void> => {
                this.scope.currencyList = await this.getCurrencyListByName(name);
            }
            this.scope.getWeightRangeListByName = async (search: string): Promise<void> => {
                this.scope.weightRangeList = await this.getWeightRangeListByName(search);
            }
            this.scope.getHolderListByName = async (search: string, holderId: string) => {
                let holderList: SelectorModel[] = [];
                if (search && search.length >= 3 && holderId) {
                    holderList = holderId == "1" ? await this.getLegalPersonListByName({ specializations: [], search: search }) : await this.getPhysicalPersonListByName({ specializations: [], roles: [], search: search });
                }
                this.scope.holderList = holderList;
            }
            this.scope.updatePaymentUnit = (): void => {
                this.updatePaymentUnit();
            }
            this.scope.updateReceivingUnit = (): void => {
                this.updateReceivingUnit();
            }
            this.scope.updateHolderTypePayment = (): void => {
                this.updateHolderTypePayment();
            }
            this.scope.updateHolderTypeReceiving = (): void => {
                this.updateHolderTypeReceiving();
            }
            this.scope.checkChargeDateValidity = (initialDate: Date, finalDate: Date, scopeName: string, charge: IFreightRoutesCharge, type: string, lowestRouteValidityEnd?: Date): void => {
                this.checkChargeDateValidity(initialDate, finalDate, scopeName, charge, type, lowestRouteValidityEnd);
            }
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private async getGenericValue(typeGeneric: string, alternative?: boolean) {
        const timeout: number = 10000;
        let result = [];

        if (alternative) {
            const { data: generic } = await this.helperService.get(`/generic/value/${typeGeneric}/${alternative}`, null, timeout);
            result = generic && generic.data ? generic.data : [];
        } else {
            const { data: generic } = await this.helperService.get(`/generic/value/${typeGeneric}`, null, timeout);
            result = generic && generic.data ? generic.data : [];
        }

        return result;
    }

    private checkDateValidity(initialDate: Date, finalDate: Date, scopeName: string, charge: IFreightRoutesCharge = null): void {
        let isValid = false;
        if (!initialDate || typeof initialDate == "string") return;
        if (!finalDate || typeof finalDate == "string") return;
        isValid = ValidateUtil.isValidDateRange(initialDate, finalDate);
        if (!isValid) {
            switch (scopeName) {
                case 'displayValidityDate':
                    this.scope.freightRoute.DISPLAY_VALIDITY_END = null;
                    break;

                case 'chargeValidityDate':
                    charge.VALIDITY_END = null;
                    break;
            }
        }
    }

    private checkChargeDateValidity(initialDate: Date, finalDate: Date, scopeName: string, charge: IFreightRoutesCharge, type: string, lowestRouteValidityEnd): void {
        this.checkDateValidity(initialDate, finalDate, scopeName, charge);

        const format = 'DD/MM/YYYY';

        if (type == 'START') {
            const routeStart = this.scope.freightRoute && this.scope.freightRoute.DISPLAY_VALIDITY_START ? moment(this.scope.freightRoute.DISPLAY_VALIDITY_START) : null;
            const chargeStart = charge.VALIDITY_START ? moment(charge.VALIDITY_START) : null;

            if (routeStart && chargeStart && chargeStart.isBefore(routeStart)) {
                charge.VALIDITY_START = null;

                NotificationService().error(`The charge start date (${moment(chargeStart).format(format)}) is less than the route start date (${moment(routeStart).format(format)})`);
            }
        }

        if (type == 'END') {
            const routeEnd = this.scope.freightRoute && this.scope.freightRoute.DISPLAY_VALIDITY_END ? moment(this.scope.freightRoute.DISPLAY_VALIDITY_END) : null;
            const lowestRouteEnd = lowestRouteValidityEnd ? moment(lowestRouteValidityEnd) : null;
            const chargeEnd = charge.VALIDITY_END ? moment(charge.VALIDITY_END) : null;

            if (routeEnd && chargeEnd && chargeEnd.isAfter(routeEnd)) {
                charge.VALIDITY_END = null;

                NotificationService().error(`The charge end date (${moment(chargeEnd).format(format)}) is greater than the route end date (${moment(routeEnd).format(format)})`);
            }
            if (lowestRouteEnd && chargeEnd && chargeEnd.isAfter(lowestRouteEnd)) {
                charge.VALIDITY_END = null;

                NotificationService().error(`The charge end date (${moment(chargeEnd).format(format)}) is greater than the lowest route end date (${moment(lowestRouteEnd).format(format)})`);
            }
        }
    }

    private hasChanges(newObj: Object[], oldObj: Object[], propertiesToIgnore?: string[]): boolean {
        if (!propertiesToIgnore) propertiesToIgnore = this.propertiesToIgnore;
        if (propertiesToIgnore) {
            const newAux = newObj ? angular.copy(newObj) : null;
            const oldAux = oldObj ? angular.copy(oldObj) : null;
            if (newAux && newAux.length) {
                for (const auxNew of newAux) {
                    for (const property of propertiesToIgnore) {
                        if (auxNew && angular.isDefined(auxNew[property])) delete auxNew[property];
                        if (oldAux[newAux.indexOf(auxNew)] && angular.isDefined(oldAux[newAux.indexOf(auxNew)][property])) delete oldAux[newAux.indexOf(auxNew)][property];
                    }
                }
            }

            return !angular.equals(JSON.stringify(newAux), JSON.stringify(oldAux));
        }
        return !angular.equals(JSON.stringify(newObj), JSON.stringify(oldObj));
    }

    private applyChargeFilterTable() {
        if (this.scope.freightRoute) {
            this.scope.routeEquipmentList = [];
            this.updateRouteChargeList(this.scope.charges);
        }
    }

    private updateRouteChargeList(chargeList: IFreightRoutesCharge[]) {
        if (chargeList && chargeList.length) {
            for (const charge of chargeList) {
                if (charge.EQUIPMENT) this.scope.routeEquipmentList = charge.EQUIPMENT.map(equipment => equipment)
            }
        }
    }

    private async getChargeNameParamsList(): Promise<SelectorModel[]> {
        let result: IApplicationList[] = [];
        try {
            this.block();
            const products = this.scope.freightContract.PRODUCT ? [this.scope.freightContract.PRODUCT.ID] : null;
            const paramsTypeCargo = this.scope.freightContract.CARGO_TYPE ? [this.scope.freightContract.CARGO_TYPE.ID] : null;
            const request = await this.$RestService.newObjectPromise(`${this.getUrlProduct()}/application/list/custom`, { products: products, typeCargos: paramsTypeCargo }, 30000, false);
            result = request ? request.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PERCENT: x.PERCENT, APPLICATION_COMPLEMENT: x.APPLICATION_COMPLEMENT, INTERNAL_SEQUENCE: x.INTERNAL_SEQUENCE, FREE_TYPING_AMOUNT_CHARGE: x.FREE_TYPING_AMOUNT_CHARGE, CT_WITHOUT_DOC: x.CT_WITHOUT_DOC, NAME_INTL: x.NAME_INTL } }) : [];
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getEquipmentListByName(search?: string): Promise<IEquipmentSelector[]> {
        let result: IEquipmentSelector[] = [];
        try {
            if (!this.scope.freightContract.PRODUCT) {
                throw Error(this.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
            }

            this.block();

            const products = [this.scope.freightContract.PRODUCT.ID];
            const equipmentList = await this.$RestService.newObjectPromise(`${this.getUrlProduct()}/equipment/list/custom`, { search, products }, 30000, false);
            result = equipmentList ? equipmentList.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, TEU: x.TEU, FEET: x.FEET } }) : [];
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private hasInvalidRequiredElements(elementId: string): boolean {
        if (!elementId) return false;
        const isInvalid = FormService2.hasRequiredElements('#' + elementId);
        if (isInvalid) this.notifyError(this.getTranslate("GENERAL.ALL_FIELDS_MANDATORY"));
        return isInvalid;
    }

    private async applyCharge(index: number, charge: IFreightRoutesCharge, operation: string, closeModal?: boolean): Promise<boolean> {
        let success = true;
        try {
            if (!charge) throw Error('charge is null');
            const hasInvalid = this.hasInvalidRequiredElements('freightRoutesChargeModal') || !ValidateUtil.isValidDateRange(charge.VALIDITY_START, charge.VALIDITY_END);
            this.validateForm();
            if (hasInvalid) success = false;
            else {
                if (operation == EOperation.NEW) {
                    if (!this.scope.charges) this.scope.charges = [];
                    this.scope.charges.push(charge);
                }
                if (operation == EOperation.EDIT) {
                    charge.ID_CHARGE_NAME_EXHIBITION = parseInt(charge.CHARGE_NAME_EXHIBITION.ID);
                    this.scope.charges.splice(index, 1, charge);
                }
                if (closeModal) {
                    const result = { charge, operation };
                    this.scope.modalOptions.ok(result);
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            const msgSuccess = this.getTranslate('FINANCIAL.CHARGE_DATA_SAVED_SUCCESSFULLY');
            if (success) this.notifySuccess(msgSuccess);
            return success;
        }
    }

    private async modalSaveConfirmation(headerText: string, closeButtonText: string, bodyText?: string): Promise<boolean> {
        return await this.ModalService.showModalConfirmation({}, {
            headerText: headerText,
            bodyText: this.getTranslate(bodyText ? bodyText : 'REGISTRATION.MESSAGES.ERROR.UPDATE_NOT_SAVED'),
            actionButtonText: "GENERAL.SAVE",
            closeButtonText: closeButtonText
        });
    }

    private async closeChargeModal() {
        if (this.hasChanges([this.scope.charge], [this.scope.oldCharge])) {
            const confirm = await this.modalSaveConfirmation("GENERAL.CLOSE", "GENERAL.CLOSE");
            if (confirm && !await this.applyCharge(this.scope.currentIndex, this.scope.charge, this.scope.operation)) return;
            if (!this.scope.charge.VALIDITY_START) this.scope.charge.VALIDITY_START = this.scope.oldCharge.VALIDITY_START;
            if (!this.scope.charge.VALIDITY_END) this.scope.charge.VALIDITY_END = this.scope.oldCharge.VALIDITY_END;
            if (!confirm) this.scope.charges[this.scope.currentIndex] = angular.copy(this.scope.oldCharge);
        }

        this.scope.modalOptions.ok();
    }

    private async previousCharge(currentDisplayIndex: number) {
        const previousDisplayCharge = this.scope.chargesListDisplay[currentDisplayIndex - 1];
        const previousChargeIndex = this.scope.charges && this.scope.charges.length ? this.scope.charges.findIndex((charge, index) => previousDisplayCharge.ID ? charge.ID == previousDisplayCharge.ID : index == (currentDisplayIndex - 1)) : -1;

        if (previousChargeIndex >= 0) {
            if (this.hasChanges([this.scope.charge], [this.scope.oldCharge])) {
                const confirm = await this.modalSaveConfirmation("REGISTRATION.BACK", "GENERAL.CANCEL");
                if (!confirm || confirm && !await this.applyCharge(this.scope.currentIndex, this.scope.charge, this.scope.operation, false)) return;
            }
            this.scope.currentIndex = previousChargeIndex;
            this.scope.currentDisplayIndex--;

            const chargeUpdated = this.scope.charges[previousChargeIndex];
            if (chargeUpdated) {
                this.scope.charge = angular.copy(chargeUpdated);
                this.scope.oldCharge = angular.copy(chargeUpdated);
            }
            this.scope.$applyAsync();
        }
    }

    private async nextCharge(currentDisplayIndex: number) {
        const nextDisplayCharge = this.scope.chargesListDisplay[currentDisplayIndex + 1];
        const nextChargeIndex = nextDisplayCharge && this.scope.charges && this.scope.charges.length ? this.scope.charges.findIndex((charge, index) => nextDisplayCharge.ID ? charge.ID == nextDisplayCharge.ID : index == (currentDisplayIndex + 1)) : -1;

        if (nextChargeIndex >= 0) {
            if (this.hasChanges([this.scope.charge], [this.scope.oldCharge])) {
                const confirm = await this.modalSaveConfirmation("GENERAL.NEXT", "GENERAL.CANCEL");
                if (!confirm || (confirm && !await this.applyCharge(this.scope.currentIndex, this.scope.charge, this.scope.operation, false))) return;
            }
            this.scope.currentIndex = nextChargeIndex;
            this.scope.currentDisplayIndex++;

            const charge = this.scope.charges[nextChargeIndex];
            if (charge) {
                this.scope.charge = angular.copy(charge);
                this.scope.oldCharge = angular.copy(charge);
            }
            this.scope.$applyAsync();
        }
    }

    private async updateParam(selected: IChargeNameList): Promise<void> {
        let param: IParams = null;
        try {
            if (!selected) throw Error('selected is null');
            if (!this.scope.charge) throw Error('charge is null');

            const isPaymentNature = this.scope.freightContract.TRANSACTION && this.scope.freightContract.TRANSACTION.ID == EPaymentNatureId.PAYMENT;
            if (selected.PARAMS && selected.PARAMS.length > 0) param = selected.PARAMS.find(param => param.TYPE_CARGO.find(typeCargo => typeCargo.ID === this.scope.freightContract.CARGO_TYPE.ID));
            if (param && this.scope.freightContract.TRANSACTION) {
                this.scope.charge.APPLICATION = isPaymentNature ? param.PAYMENT : param.RECEIVING;
                this.timeout(() => { this.scope.selectorValidity('param'); }, 100);
            }

            // SCOB Application behavior
            const isScobApplication = this.scope.charge.APPLICATION && this.scope.charge.APPLICATION.INTERNAL_SEQUENCE == "001";
            if (isScobApplication) {
                this.scope.charge.FREIGHT_ROUTES_PAYMENT.FREE_OF_CHARGE = true;
                this.scope.charge.FREIGHT_ROUTES_RECEIVING.FREE_OF_CHARGE = true;
                this.freeOfChargePaymentChange(this.scope.charge);
                this.freeOfChargeReceivingChange(this.scope.charge);
            } else if (isPaymentNature) this.scope.charge.FREIGHT_ROUTES_PAYMENT.FREE_OF_CHARGE = false;
            else this.scope.charge.FREIGHT_ROUTES_RECEIVING.FREE_OF_CHARGE = false;

            const applicationComplement = this.scope.charge.APPLICATION && this.scope.charge.APPLICATION.APPLICATION_COMPLEMENT ? this.scope.charge.APPLICATION.APPLICATION_COMPLEMENT.ID : null;
            if (!applicationComplement || applicationComplement == EApplicationComplementId.NOT_APPLICABLE || applicationComplement == EApplicationComplementId.WEIGHT_RANGE) {
                if (applicationComplement == EApplicationComplementId.WEIGHT_RANGE) {
                    if (!this.scope.charge.FLAT) {
                        this.scope.charge.FLAT = true;
                        this.flatChange();
                    }
                } else {
                    this.scope.charge.FLAT = false;
                    this.scope.charge.WEIGHT_RANGE = null;
                    this.scope.charge.FREIGHT_ROUTES_PAYMENT.WEIGHT_RANGE_CHARGE = null;
                    this.scope.charge.FREIGHT_ROUTES_RECEIVING.WEIGHT_RANGE_CHARGE = null;
                }
                this.scope.charge.EQUIPMENT = null;
                this.scope.charge.VEHICLE_TYPE = null;
                this.timeout(() => { this.scope.selectorValidity('weightRange'); });
            }
            else if (applicationComplement == EApplicationComplementId.EQUIPMENT) {
                this.scope.charge.FLAT = false;
                this.scope.charge.WEIGHT_RANGE = null;
                this.scope.charge.FREIGHT_ROUTES_PAYMENT.WEIGHT_RANGE_CHARGE = null;
                this.scope.charge.FREIGHT_ROUTES_RECEIVING.WEIGHT_RANGE_CHARGE = null;
                this.scope.charge.VEHICLE_TYPE = null;
                this.timeout(() => { this.scope.selectorValidity('equipment'); });
            }
            else if (applicationComplement == EApplicationComplementId.VEHICLE) {
                this.scope.charge.FLAT = false;
                this.scope.charge.WEIGHT_RANGE = null;
                this.scope.charge.FREIGHT_ROUTES_PAYMENT.WEIGHT_RANGE_CHARGE = null;
                this.scope.charge.FREIGHT_ROUTES_RECEIVING.WEIGHT_RANGE_CHARGE = null;
                this.scope.charge.EQUIPMENT = null;
                this.timeout(() => { this.scope.selectorValidity('vehicleType'); });
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async setChargeNameId(): Promise<void> {
        if (!this.scope.charge) throw Error('charge is null');
        if (this.scope.charge.CHARGE_NAME_EXHIBITION) {
            this.scope.charge.ID_CHARGE_NAME_EXHIBITION = parseInt(this.scope.charge.CHARGE_NAME_EXHIBITION.ID)
        }
    }

    private async flatChange() {
        try {
            if (this.scope.charge) {
                if (this.scope.charge.FLAT) {
                    const availableWeightRangeList = await this.getWeightRangeListByName(null);
                    const availableWeightRangeToConcat = availableWeightRangeList.filter(weightRange => (!this.scope.charge.WEIGHT_RANGE || !(this.scope.charge.WEIGHT_RANGE && this.scope.charge.WEIGHT_RANGE.some((chargeWeightRange) => chargeWeightRange.ID == weightRange.ID))));
                    if (this.scope.charge.WEIGHT_RANGE) this.scope.charge.WEIGHT_RANGE = this.scope.charge.WEIGHT_RANGE.concat(availableWeightRangeToConcat);
                    else this.scope.charge.WEIGHT_RANGE = availableWeightRangeToConcat;
                    this.weightRangeChange();
                } else {
                    this.scope.charge.FREIGHT_ROUTES_PAYMENT.UNITARY = null;
                    this.scope.charge.FREIGHT_ROUTES_RECEIVING.UNITARY = null;
                }
                this.scope.$applyAsync();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private isEmptyArray(arrayObj: any[]): boolean {
        return (arrayObj && arrayObj.length == 0 || !arrayObj);
    }

    private async weightRangeChange(onlyPayment?: boolean, onlyReceiving?: boolean) {
        try {
            const chargeWeightRange = this.scope.charge.WEIGHT_RANGE;
            if (this.isEmptyArray(chargeWeightRange)) {
                if (!onlyReceiving) this.scope.charge.FREIGHT_ROUTES_PAYMENT.WEIGHT_RANGE_CHARGE = null;
                if (!onlyPayment) this.scope.charge.FREIGHT_ROUTES_RECEIVING.WEIGHT_RANGE_CHARGE = null;
            } else {
                if (!onlyReceiving && !this.scope.charge.FREIGHT_ROUTES_PAYMENT.WEIGHT_RANGE_CHARGE) this.scope.charge.FREIGHT_ROUTES_PAYMENT.WEIGHT_RANGE_CHARGE = [];
                if (!onlyPayment && !this.scope.charge.FREIGHT_ROUTES_RECEIVING.WEIGHT_RANGE_CHARGE) this.scope.charge.FREIGHT_ROUTES_RECEIVING.WEIGHT_RANGE_CHARGE = [];
                for (const weightRange of chargeWeightRange) {
                    // Payment
                    if (!onlyReceiving && !this.scope.charge.FREIGHT_ROUTES_PAYMENT.FREE_OF_CHARGE) {
                        const hasPaymentWeightRangeAlready = this.scope.charge.FREIGHT_ROUTES_PAYMENT.WEIGHT_RANGE_CHARGE.some(paymentWeightRange => paymentWeightRange.WEIGHT_RANGE && paymentWeightRange.WEIGHT_RANGE.ID == weightRange.ID);
                        // Create the lines with the weight ranges if they don't exist
                        if (this.isEmptyArray(this.scope.charge.FREIGHT_ROUTES_PAYMENT.WEIGHT_RANGE_CHARGE) || !hasPaymentWeightRangeAlready) {
                            this.scope.charge.FREIGHT_ROUTES_PAYMENT.WEIGHT_RANGE_CHARGE.push({ WEIGHT_RANGE: weightRange, UNITARY: this.scope.charge.FLAT ? this.scope.charge.FREIGHT_ROUTES_PAYMENT.UNITARY : null });
                        } else if (this.scope.charge.FLAT) {
                            // Fill in unit values case is FLAT
                            for (const weightRangeCharge of this.scope.charge.FREIGHT_ROUTES_PAYMENT.WEIGHT_RANGE_CHARGE) { weightRangeCharge.UNITARY = this.scope.charge.FREIGHT_ROUTES_PAYMENT.UNITARY }
                        }
                    }

                    // Receiving
                    if (!onlyPayment && !this.scope.charge.FREIGHT_ROUTES_RECEIVING.FREE_OF_CHARGE) {
                        const hasReceivingWeightRangeAlready = this.scope.charge.FREIGHT_ROUTES_RECEIVING.WEIGHT_RANGE_CHARGE.some(receivingWeightRange => receivingWeightRange.WEIGHT_RANGE && receivingWeightRange.WEIGHT_RANGE.ID == weightRange.ID);
                        // Create the lines with the weight ranges if they don't exist
                        if (this.isEmptyArray(this.scope.charge.FREIGHT_ROUTES_RECEIVING.WEIGHT_RANGE_CHARGE) || !hasReceivingWeightRangeAlready) {
                            this.scope.charge.FREIGHT_ROUTES_RECEIVING.WEIGHT_RANGE_CHARGE.push({ WEIGHT_RANGE: weightRange, UNITARY: this.scope.charge.FLAT ? this.scope.charge.FREIGHT_ROUTES_RECEIVING.UNITARY : null });
                        } else if (this.scope.charge.FLAT) {
                            // Fill in unit values case is FLAT
                            for (const weightRangeCharge of this.scope.charge.FREIGHT_ROUTES_RECEIVING.WEIGHT_RANGE_CHARGE) { weightRangeCharge.UNITARY = this.scope.charge.FREIGHT_ROUTES_RECEIVING.UNITARY }
                        }
                    }
                }
                // Keep the correct list in case of removal
                this.scope.charge.FREIGHT_ROUTES_PAYMENT.WEIGHT_RANGE_CHARGE = (this.scope.charge.FREIGHT_ROUTES_PAYMENT.WEIGHT_RANGE_CHARGE && this.scope.charge.FREIGHT_ROUTES_PAYMENT.WEIGHT_RANGE_CHARGE.length) ? this.scope.charge.FREIGHT_ROUTES_PAYMENT.WEIGHT_RANGE_CHARGE.filter(weightRangeCharge => chargeWeightRange && chargeWeightRange.some(weightRange => weightRange.ID == weightRangeCharge.WEIGHT_RANGE.ID)) : null;
                this.scope.charge.FREIGHT_ROUTES_RECEIVING.WEIGHT_RANGE_CHARGE = (this.scope.charge.FREIGHT_ROUTES_RECEIVING.WEIGHT_RANGE_CHARGE && this.scope.charge.FREIGHT_ROUTES_RECEIVING.WEIGHT_RANGE_CHARGE.length) ? this.scope.charge.FREIGHT_ROUTES_RECEIVING.WEIGHT_RANGE_CHARGE.filter(weightRangeCharge => chargeWeightRange && chargeWeightRange.some(weightRange => weightRange.ID == weightRangeCharge.WEIGHT_RANGE.ID)) : null;

                this.scope.$applyAsync();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async freeOfChargePaymentChange(charge: IFreightRoutesCharge): Promise<IFreightRoutesCharge> {
        if (charge && charge.FREIGHT_ROUTES_PAYMENT && charge.FREIGHT_ROUTES_PAYMENT.FREE_OF_CHARGE) {
            charge.FREIGHT_ROUTES_PAYMENT.ID_CURRENCY = null;
            charge.FREIGHT_ROUTES_PAYMENT.CURRENCY = null;
            charge.FREIGHT_ROUTES_PAYMENT.UNITARY = null;
            charge.FREIGHT_ROUTES_PAYMENT.MIN = null;
            charge.FREIGHT_ROUTES_PAYMENT.HOLDER_TYPE = null;
            charge.FREIGHT_ROUTES_PAYMENT.ID_LEGAL_PERSON_HOLDER = null;
            charge.FREIGHT_ROUTES_PAYMENT.LEGAL_PERSON_HOLDER = null;
            charge.FREIGHT_ROUTES_PAYMENT.ID_PHYSICAL_PERSON_HOLDER = null;
            charge.FREIGHT_ROUTES_PAYMENT.PHYSICAL_PERSON_HOLDER = null;
            charge.FREIGHT_ROUTES_PAYMENT.WEIGHT_RANGE_CHARGE = null;
            this.timeout(() => {
                this.scope.selectorValidity("paymentCurrency");
            });
        }
        this.weightRangeChange(true);
        return charge;
    }

    private async freeOfChargeReceivingChange(charge: IFreightRoutesCharge): Promise<IFreightRoutesCharge> {
        if (charge && charge.FREIGHT_ROUTES_RECEIVING && charge.FREIGHT_ROUTES_RECEIVING.FREE_OF_CHARGE) {
            charge.FREIGHT_ROUTES_RECEIVING.ID_CURRENCY = null;
            charge.FREIGHT_ROUTES_RECEIVING.CURRENCY = null;
            charge.FREIGHT_ROUTES_RECEIVING.UNITARY = null;
            charge.FREIGHT_ROUTES_RECEIVING.MIN = null;
            charge.FREIGHT_ROUTES_RECEIVING.HOLDER_TYPE = null;
            charge.FREIGHT_ROUTES_RECEIVING.ID_LEGAL_PERSON_HOLDER = null;
            charge.FREIGHT_ROUTES_RECEIVING.LEGAL_PERSON_HOLDER = null;
            charge.FREIGHT_ROUTES_RECEIVING.ID_PHYSICAL_PERSON_HOLDER = null;
            charge.FREIGHT_ROUTES_RECEIVING.PHYSICAL_PERSON_HOLDER = null;
            charge.FREIGHT_ROUTES_RECEIVING.WEIGHT_RANGE_CHARGE = null;
            this.timeout(() => {
                this.scope.selectorValidity("receivingCurrency");
            });
        }
        this.weightRangeChange(false, true);
        return charge;
    }

    private async getCurrencyListByName(name?: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.block();

            const currencies = await this.$RestService.newObjectPromise(`${this.getUrlProduct()}/currency/list/custom`, { name: name }, 10000, false);
            result = (currencies) ? currencies.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } }) : [];
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getChargeNameListByName(search: string): Promise<IChargeNameList[]> {
        let result: IChargeNameList[] = [];
        try {
            if (search && search.length >= 2) {
                this.block();
                let types = ['1'];
                const products = [this.scope.freightContract.PRODUCT.ID];
                const paramTypeCargo = [this.scope.freightContract.CARGO_TYPE.ID];
                const chargeNames = await this.$RestService.newObjectPromise(`${this.getUrlProduct()}/chargeName/list/custom/exhibition`, { search, types, products, paramTypeCargo }, 30000, false);
                result = chargeNames ? chargeNames.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PARAMS: x.PARAMS } }) : [];
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getWeightRangeListByName(search: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.block();
            const products = this.scope.freightContract.PRODUCT ? [this.scope.freightContract.PRODUCT.ID] : [];
            const weightRangeList = await this.$RestService.newObjectPromise(`${this.getUrlProduct()}/weightRange/list/custom`, { search, products }, 30000, false);
            result = weightRangeList ? weightRangeList.map(x => { return { ID: x.ID, NAME: x.NAME } }) : [];
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getLegalPersonListByName(legalPersonFilter: ILegalPersonListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        try {
            this.block();
            if (legalPersonFilter.search && legalPersonFilter.search.length >= 3) {
                const legalPersons = await this.$RestService.newObjectPromise(`${this.getUrlProduct()}/legalPerson/list/custom`, legalPersonFilter, 30000, false);
                if (legalPersons) result = legalPersons.map(legalPerson => { return { ID: legalPerson.ID, NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME } });
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async updatePaymentUnit(): Promise<void> {
        try {
            if (this.scope.charge.FLAT) {
                const unit = this.scope.charge.FREIGHT_ROUTES_PAYMENT.UNITARY;
                if (this.scope.charge.FREIGHT_ROUTES_PAYMENT.WEIGHT_RANGE_CHARGE) {
                    for (const weightRangeCharge of this.scope.charge.FREIGHT_ROUTES_PAYMENT.WEIGHT_RANGE_CHARGE) {
                        weightRangeCharge.UNITARY = unit;
                    }
                }
                this.scope.$applyAsync();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async updateReceivingUnit(): Promise<void> {
        try {
            if (this.scope.charge.FLAT) {
                const unit = this.scope.charge.FREIGHT_ROUTES_RECEIVING.UNITARY;
                if (this.scope.charge.FREIGHT_ROUTES_RECEIVING.WEIGHT_RANGE_CHARGE) {
                    for (const weightRangeCharge of this.scope.charge.FREIGHT_ROUTES_RECEIVING.WEIGHT_RANGE_CHARGE) {
                        weightRangeCharge.UNITARY = unit;
                    }
                }
                this.scope.$applyAsync();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async updateHolderTypePayment() {
        try {
            this.scope.charge.FREIGHT_ROUTES_PAYMENT.ID_LEGAL_PERSON_HOLDER = null;
            this.scope.charge.FREIGHT_ROUTES_PAYMENT.LEGAL_PERSON_HOLDER = null;
            this.scope.charge.FREIGHT_ROUTES_PAYMENT.ID_PHYSICAL_PERSON_HOLDER = null;
            this.scope.charge.FREIGHT_ROUTES_PAYMENT.PHYSICAL_PERSON_HOLDER = null;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async updateHolderTypeReceiving() {
        try {
            this.scope.charge.FREIGHT_ROUTES_RECEIVING.ID_LEGAL_PERSON_HOLDER = null;
            this.scope.charge.FREIGHT_ROUTES_RECEIVING.LEGAL_PERSON_HOLDER = null;
            this.scope.charge.FREIGHT_ROUTES_RECEIVING.ID_PHYSICAL_PERSON_HOLDER = null;
            this.scope.charge.FREIGHT_ROUTES_RECEIVING.PHYSICAL_PERSON_HOLDER = null;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getPhysicalPersonListByName(physicalPersonFilter?: IPhysicalPersonListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        this.block();
        try {
            const physicalPersons = await this.$RestService.newObjectPromise(`${this.getUrlProduct()}/physicalPerson/list/custom`, physicalPersonFilter, 30000, false);
            if (physicalPersons) result = physicalPersons.map(physicalPerson => { return { ID: physicalPerson.ID, NAME: physicalPerson.NAME } });
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

}

import { ISessionService } from "@services/SessionService";
import * as Address from "../../communication/Address";

LoginController.$inject = ['$scope', '$stateParams', '$window', 'blockUI', 'SessionService', 'RegularAuthService', 'GoogleAuthService', 'NotificationService', 'config'];
export function LoginController($scope, $stateParams, $window, blockUI, SessionService: ISessionService, RegularAuthService, GoogleAuthService, NotificationService, config) {
  this.$onInit = function () {
    $scope.homeImg = '../../../img/monaco-bg.jpg';

    blockUI.start();

    initLogin();
  }

  this.$onDestroy = function () {

  }

  $scope.loginId = Address.loginId;
  
  $scope.login = function () {
    blockUI.start();

    RegularAuthService.login($scope.user.email, $scope.user.password)
      .then(function successCallback(response) {
        handleLoginResponse(response);
      }, function errorCallback(error) {
        handleLoginResponse(error);
      }).catch(function (ex) {
        console.log(ex);
      });
  };

  $scope.redirectGoogle = function () {
    blockUI.start();

    const loginId = GoogleAuthService.getLoginId();
    GoogleAuthService.redirectGoogle(loginId)
      .then(function successCallback(response) {
        const data = response.data.data;
        if (!data || !data.id || !data.url) {
          $scope.authError = 'Ocorreu um erro, por favor tente novamente';
          NotificationService.error($scope.authError);
          blockUI.stop();
          return;
        }
        GoogleAuthService.setLoginId(data.id);
        $window.location.href = data.url;
      }, function errorCallback(error) {
        handleLoginResponse(error);
      }).catch(function (ex) {
        console.log(ex);
      });
  };

  $scope.forgotPassword = function () {
    blockUI.start();

    RegularAuthService.forgotPassword($scope.user.email)
      .then(function successCallback(response) {
        NotificationService.success(`Email enviado para ${$scope.user.email}`);
        blockUI.stop();
      }, function errorCallback(error) {
        console.log('error: ', error);
        if (!error.data || !error.data.reason) {
          $scope.authError = 'Timeout alcançado, tente novamente';
          NotificationService.error($scope.authError);
          blockUI.stop();
          return;
        }

        switch (error.data.reason) {
          case 'User not found':
            $scope.authError = 'Email/Senha Incorretos';
            break;
          case 'Email/Password incorrect':
            $scope.authError = 'Email/Senha incorretos';
            break;
          case 'Account is locked':
            $scope.authError = 'Conta bloqueada. Entre em contato com o administrador.';
            break;
          case 'Account is blocked':
            $scope.authError = 'Conta bloqueada. Entre em contato com o administrador.';
            break;
          case 'Resource not found':
            $scope.authError = 'Email/Senha Incorretos';
            break;
          case 'Failed':
            $scope.authError = 'Ocorreu um erro, por favor tente novamente';
            break;
          case 'Exception':
            $scope.authError = 'Ocorreu uma exceção, por favor tente novamente';
            break;
          default:
            $scope.authError = error.data.reason;
            break;
        }

        NotificationService.error($scope.authError);
        blockUI.stop();
      }).catch(function (ex) {
        console.log(ex);
      });
  };

  $window.handleCredentialResponse = function (response) {
    if (response) {
      SessionService.handleGoogleLogin(response);
    }
  }

  function handleLoginResponse(response) {
    if (response.status == 200 && response.data.data) {
      const token = response.data.data;
      blockUI.stop();
      return SessionService.startSession(token, $stateParams.param == "returnToNewMonacoFinop");

    } else {
      if (!response.data || !response.data.reason) {
        $scope.authError = 'Timeout alcançado, tente novamente';
        NotificationService.error($scope.authError);
        blockUI.stop();
        return;
      }

      switch (response.data.reason) {
        case 'User not found':
          $scope.authError = 'Email/Senha Incorretos';
          break;
        case 'Email/Password incorrect':
          $scope.authError = 'Email/Senha incorretos';
          break;
        case 'Account is locked':
          $scope.authError = 'Conta bloqueada. Entre em contato com o administrador.';
          break;
        case 'Account is blocked':
          $scope.authError = 'Conta bloqueada. Entre em contato com o administrador.';
          break;
        case 'Resource not found':
          $scope.authError = 'Email/Senha Incorretos';
          break;
        case 'Failed':
          $scope.authError = 'Ocorreu um erro, por favor tente novamente';
          break;
        case 'Exception':
          $scope.authError = 'Ocorreu uma exceção, por favor tente novamente';
          break;
        default:
          $scope.authError = response.data.reason;
          break;
      }
      NotificationService.error($scope.authError);

      blockUI.stop();
    }
  };

  function clearUserSession() {
    if ($scope.user) {
      if ($scope.user.email) $scope.user.email = undefined;
      if ($scope.user.password) $scope.user.password = undefined;
    }

    $scope.authError = undefined;
  };

  function initLogin() {
    clearUserSession();

    if ($stateParams.failed || $stateParams.param == 'failed') {
      $scope.authError = 'Ocorreu um erro, por favor tente novamente';
      NotificationService.error($scope.authError);
    } else if ($stateParams.expired) {
      $scope.authError = 'Sua sessão expirou, por favor faça login novamente';
      NotificationService.info($scope.authError);
    }

    blockUI.stop();
  };
}

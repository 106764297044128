import * as angular from 'angular';

LogConfig.$inject = ['$provide'];
function LogConfig($provide) {
    $provide.decorator("$exceptionHandler", ['$delegate', '$log', function ($delegate, $log) {
        return function (exception, cause) {
            $log.debug(`$exceptionHandler: ${JSON.stringify(exception)}\n${(cause) ? cause : ''}`);
            $delegate(exception, cause);
        }
    }])
}

export default function initLog(moduleName: string): void {
    angular.module(moduleName).config(LogConfig);
}
import { IMonacoScope } from '../../common/MonacoInterface';
import { ISessionService } from '@services/SessionService';

HttpInterceptor.$inject = ['$q', '$rootScope', 'SessionService'];
export function HttpInterceptor($q: ng.IQService, $rootScope: IMonacoScope, SessionService: ISessionService) {
    return {
        'request': function (config) {
            const token = SessionService.getToken();
            if (token) config.headers['Authorization'] = `Bearer ${token}`;
            return config;
        },

        /*         // optional method
                'requestError': function (rejection) {
                    // do something on error
                    if (canRecover(rejection)) {
                        return responseOrNewPromise
                    }
                    return $q.reject(rejection);
                },
        
                // optional method
                'response': function (response) {
                    // do something on success
                    return response;
                },*/

        // optional method
        'responseError': function (rejection) {
            //invalid route
            if (rejection.status === 404 && rejection.data && typeof (rejection.data) === 'string' && rejection.data.startsWith('<!DOCTYPE html>')) {
                rejection.statusText = 'invalid';
                console.log(`Invalid route: ${rejection.config.url}`);

            } else if (rejection.status === 401) {
                $rootScope.$emit('unauthRequest', rejection);
            }

            return $q.reject(rejection);
        }

    };
}
import angular = require('angular');
import { IModalOptions } from '@services/ModalService';
import { ISelectorModel, SelectorModel } from '@models/mongo/SelectorModel';
import { EProductId, EProviderTypeId, ETypeLocalId } from '@enums/GenericData';
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { IMonacoRequest } from '@services/GridFormService';
import { IRestService } from '@services/RestService';
import { ProductService } from "@services/ProductService";
import { OperationalService } from "@services/OperationalService";
import { IAirFlight, IAirEstimated } from 'WBA-Model/dist/interface/operational/AirFlight';
import moment = require('moment');
import { HelperService } from "@services/HelperService";

const enum ETabStep {
    GENERAL_DETAILS = 0,
    CONFIRMATION = 1
}

interface IBaseFlightModalScope extends IFormServiceScope {
    model: IAirFlight;
    modalOptions: IModalOptions;
    productList: ISelectorModel[];
    scheduleFrequency: ISelectorModel[];
    daysList: ISelectorModel[];
    airLineList: ISelectorModel[];
    routingPointList: ISelectorModel[];
    flightDuration: string;
    estimatedTimes: IAirEstimated;
    estimatedEtdString: string;
    estimatedEtaString: string;
    getAirLineListByName(airLine: string): Promise<void>;
    getRoutingPointListByName(search: string): Promise<void>;
    updateSchedulingType(): void;
    changePrefix(prefix: ISelectorModel): void;
    checkFlightDuration(): void;

    //navigation
    back: () => void;
    next: () => void;
    finish: () => void;
}

export class BaseAirFlightWizardModalController extends FormService2 {
    static $inject: string[] = ['$injector', '$scope'];
    private scope: IBaseFlightModalScope;
    private $RestService: IRestService;
    private $q: ng.IQService;
    private ProductService: ProductService;
    private OperationalService: OperationalService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IBaseFlightModalScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.$RestService = $injector.get('RestService');
        this.$q = $injector.get('$q');
        this.ProductService = $injector.get('ProductService');
        this.OperationalService = $injector.get('OperationalService');
        this.helperService = $injector.get('HelperService'); 
    }

    async $onInit(): Promise<void> {
        try {
            this.block();
            this.init("baseAirFlightWizardModalForm", null, null);
            this.initScopeFunctions();
            await this.initDependencies();
            await this.initModel();
            this.loadRegisterForm(false);
            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private initScopeFunctions(): void {

        this.scope.getAirLineListByName = async (search: string) => {
            let airLineList: ISelectorModel[] = [];
            if (search && search.length >= 3) airLineList = await this.getAirLineListByName(search);
            this.scope.airLineList = airLineList;
        }

        this.scope.getRoutingPointListByName = async (search: string) => {
            this.scope.routingPointList = await this.getRoutingPointListByName(search);
        }

        this.scope.updateSchedulingType = () => {
            if (this.scope.model.FREQUENCY) {
                this.scope.model.DAY = null;
                this.setSchedulingDays(this.scope.model.FREQUENCY.ID);
            }
        }

        this.scope.changePrefix = (prefix: ISelectorModel) => {
            this.changePrefix(prefix);
        }

        this.scope.checkFlightDuration = () => {
            this.checkFlightDuration();
        }

        this.scope.next = async (): Promise<void> => {
            if (this.scope && this.scope.steps && angular.isDefined(this.scope.steps.current)) {
                const validFields = this.checkPendingFields('baseAirFlightWizardModalForm');
                if (!validFields) return;

                if (this.scope.steps.current < 1 && await this.handleNavigationChange(this.scope.steps.current)) {
                    this.scope.steps.current++;
                }
            }
        }

        this.scope.finish = async (): Promise<void> => {
            this.scope.modalOptions.ok(this.scope.model);
        }
    }

    async initModel(): Promise<void> {
        this.scope.model = {
            _id: null,
            ID: null,
            FLIGHT_CODE: null,
            PRODUCT: null,
            FLIGHT_CONCATENATED: null,
            PROVIDER: null,
            PREFIX: null,
            FLIGHT: null,
            ORIGIN: null,
            DESTINATION: null,
            FREQUENCY: null,
            DAY: null,
            ETD_HOUR: null,
            ETA_HOUR: null,
            FLIGHT_STATUS: null,
            IS_SPECIFIC: null,
            SPECIFIC: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null
        }

        this.scope.estimatedTimes = {
            ETD_HOUR: null,
            ETD_MINUTE: null,
            ETA_HOUR: null,
            ETA_MINUTE: null
        }
    }

    private async getGenericList(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    async initDependencies(): Promise<any> {
        try {
            const self: BaseAirFlightWizardModalController = this;
            return new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getGenericList("product"),
                    self.getGenericList('data_scheduling_types'),
                ]).then((result: any) => {
                    self.scope.productList = result[0] && result[0].length ? result[0].filter(data => data.ID == EProductId.AIR_EXPORT || data.ID == EProductId.AIR_IMPORT) : [];
                    self.scope.scheduleFrequency = result[1] && result[1].length ? result[1].map(obj => { return { ID: obj.ID, NAME: obj.NAME } }) : [];
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getAirLineListByName(airLine: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            if (airLine && airLine.length >= 2) {
                this.block();
                const request: IMonacoRequest = {
                    data: {
                        search: airLine,
                        types: [EProviderTypeId.AIRLINE]
                    },
                    route: `/provider/list/custom/operational`,
                    timeout: 120000,
                }
                const rc = await this.ProductService.post(request);

                if (rc && rc.data && rc.data.data && rc.status == 200) {
                    result = rc.data.data.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.SCAC, TYPE: x.TYPE, ID_LEGAL_PERSON: x.ID_LEGAL_PERSON } });
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getRoutingPointListByName(search?: string): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        try {
            if (search && search.length >= 3) {
                this.block();
                const routingPoints = await this.ProductService.post({ route: '/routingPoint/list/custom', data: { name: search, types: [ETypeLocalId.AIRPORT] }, timeout: 30000 });
                if (routingPoints && routingPoints.data && routingPoints.data.data && routingPoints.data.data.data) result = routingPoints.data.data.data.map(routingPoint => { return { ID: routingPoint.ID, NAME: routingPoint.NAME, CODE: routingPoint.CODE, TYPE: routingPoint.TYPE.NAME } });
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private changePrefix(prefix: ISelectorModel): void {
        if (prefix) {
            this.scope.model.PREFIX = prefix.CODE;
        }
    }

    private setSchedulingDays(type: string): void {
        if (this.scope.daysList) this.scope.daysList = [];
        this.scope.model.DAY = null;
        if (!type || type === '1') return;
        if (type === '2') {
            for (let i = 1; i <= 7; i++) { // weekdays
                this.scope.daysList.push({ ID: i.toString(), NAME: moment.weekdays(i), CODE: i.toString() });
            }
        } else if (type === '3') {
            for (let i = 1; i <= 31; i++) { // month Days
                this.scope.daysList.push({ ID: i.toString(), NAME: i.toString(), CODE: i.toString() });
            }
        }
    }

    private checkFlightDuration(): void {
        if (!this.scope.estimatedTimes) return;
        if (!this.scope.estimatedTimes.ETA_HOUR || !this.scope.estimatedTimes.ETD_HOUR || this.scope.estimatedTimes.ETA_MINUTE == null || this.scope.estimatedTimes.ETD_MINUTE == null) return;

        let hourDuration = this.scope.estimatedTimes.ETA_HOUR - this.scope.estimatedTimes.ETD_HOUR;
        if (hourDuration < 0) {
            hourDuration += 24;
        }

        let minuteDuration = this.scope.estimatedTimes.ETA_MINUTE - this.scope.estimatedTimes.ETD_MINUTE;
        if (minuteDuration < 0) {
            minuteDuration += 60;
            hourDuration--;
        }

        let minuteDurationString = minuteDuration.toString();
        if (minuteDuration < 10) {
            minuteDurationString = minuteDurationString.padStart(2, '0');
        }
        this.scope.flightDuration = hourDuration + ":" + minuteDurationString;
    }

    private async handleNavigationChange(currentStep: number): Promise<boolean> {
        const nextStep = currentStep + 1;
        let success = true;
        try {
            if (success && nextStep === ETabStep.CONFIRMATION) {
                success = await this.initConfirmationTab();
            }
            await this.scope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
        return success;
    }

    private async initConfirmationTab(): Promise<boolean> {
        let success = true;
        try {
            this.scope.model.IS_SPECIFIC = false;
            this.scope.model.FLIGHT_STATUS = {
                ID: "1",
                NAME: "Previsto"
            };

            const etdHour: string = this.scope.estimatedTimes.ETD_HOUR < 10 ? '0' + this.scope.estimatedTimes.ETD_HOUR : this.scope.estimatedTimes.ETD_HOUR.toString();
            const etdMinute: string = this.scope.estimatedTimes.ETD_MINUTE < 10 ? '0' + this.scope.estimatedTimes.ETD_MINUTE : this.scope.estimatedTimes.ETD_MINUTE.toString();
            this.scope.estimatedEtdString = etdHour + ':' + etdMinute;

            const etaHour: string = this.scope.estimatedTimes.ETA_HOUR < 10 ? '0' + this.scope.estimatedTimes.ETA_HOUR : this.scope.estimatedTimes.ETA_HOUR.toString();
            const etaMinute: string = this.scope.estimatedTimes.ETA_MINUTE < 10 ? '0' + this.scope.estimatedTimes.ETA_MINUTE : this.scope.estimatedTimes.ETA_MINUTE.toString();
            this.scope.estimatedEtaString = etaHour + ':' + etaMinute;

            this.scope.model.FLIGHT_CODE = this.scope.model.PREFIX + this.scope.model.FLIGHT;
            this.scope.model.FLIGHT_CONCATENATED = this.generateFlightConcatenated();

            // The date is not a valuable information, so, we can use any date.
            const date = moment('1970-01-01');

            // Set hour and minute for ETD.
            date.set({ hour: this.scope.estimatedTimes.ETD_HOUR, minute: this.scope.estimatedTimes.ETD_MINUTE });
            this.scope.model.ETD_HOUR = date.toDate();

            // Set hour and minute for ETA.
            let hourDuration = this.scope.estimatedTimes.ETA_HOUR - this.scope.estimatedTimes.ETD_HOUR;
            if (hourDuration < 0) {
                hourDuration += 24;
            }
    
            let minuteDuration = this.scope.estimatedTimes.ETA_MINUTE - this.scope.estimatedTimes.ETD_MINUTE;
            if (minuteDuration < 0) {
                minuteDuration += 60;
                hourDuration--;
            }
    
            const minsToAdd = (hourDuration * 60) + minuteDuration;
            const etaDate = moment(this.scope.model.ETD_HOUR).add(minsToAdd, 'minutes').utc();
            this.scope.model.ETA_HOUR = etaDate.toDate();

            const baseFlight = await this.registerBaseFlight();
            if (baseFlight) this.scope.model.ID = baseFlight.ID;
            if (!baseFlight) success = false;
        } catch (ex) {
            success = false;
            this.handleError(ex);
        } finally {
            this.unblock();
        }
        return success;
    }

    private async registerBaseFlight(): Promise<IAirFlight> {
        let baseFlight = null;
        try {
            this.block();
            const registerBaseAirFlight = await this.OperationalService.post('/airFlight/insert', { data: this.scope.model });
            if (registerBaseAirFlight && registerBaseAirFlight.status == 200 && registerBaseAirFlight.data && registerBaseAirFlight.data.data) {
                baseFlight = registerBaseAirFlight.data.data;
            }

        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return baseFlight;
        }
    }

    private generateFlightConcatenated(): string {
        let concatenated = '';

        if (this.scope.model.FLIGHT_CODE) concatenated += this.scope.model.FLIGHT_CODE + ' | ';
        if (this.scope.model.PRODUCT) concatenated += this.scope.model.PRODUCT.ID + ' | ';
        if (this.scope.model.PROVIDER) concatenated += this.scope.model.PROVIDER.CODE + ' | ';
        if (this.scope.model.ORIGIN) concatenated += this.scope.model.ORIGIN.CODE + ' | ';
        if (this.scope.model.DESTINATION) concatenated += this.scope.model.DESTINATION.CODE + ' | ';
        if (this.scope.model.DAY) concatenated += this.scope.model.DAY.map(days => { return days.NAME }) + ' | ';
        if (this.scope.estimatedEtdString) concatenated += this.scope.estimatedEtdString + ' | ';
        if (this.scope.estimatedEtaString) concatenated += this.scope.estimatedEtaString + ' | ';
        concatenated = concatenated.slice(0, -3);
        return concatenated;
    }
}
import { StringUtil } from "../../../common/util/StringUtil";
import { ISelectorModel } from "@models/mongo/SelectorModel";
import { ERouteEventType } from "@enums/MoveTypeModel";
import { ECargoTypeId, EProductId } from "@enums/GenericData";
import { IOfferProfitShareDetail, IOfferProfitShareDetailComposition, IOfferOptionProfitShareDetailShareItem, IOfferOptionCharge } from "../../../commercial/model/OfferModel";
import { ITransitTime, ILegsAir, ILegsMaritime, ILegsDomestic } from "../../model/OfferOldWizardModalModel";
import { ITariffDetDemDetails, IOfferOptionValidationResult, EValidationResultId, IOfferOptionRoutingPoint, IOfferOption, IDetDemOriginDestination, IOfferOptionIndicators } from "../OfferOldWizardModalRegisterController";

export abstract class OfferOldNewAlternativeHelper {

    public static betweenNumber = function (numberToVerify: number, initial: number, final: number): boolean {
        try {
            if (numberToVerify == null) throw Error("numberToVerify is null.");
            if (initial == null) throw Error("initial is null.");
            if (final == null) throw Error("final is null.");
            const min = Math.min.apply(Math, [initial, final]);
            const max = Math.max.apply(Math, [initial, final]);
            return numberToVerify >= min && numberToVerify <= max;
        } catch (ex) {
            throw ex;
        }
    }

    public static buildTransitTimeDetails(transitTime: ITransitTime, $filter: ng.FilterFactory): string {
        try {
            let html = "";
            let transitTimeDetails = `
                <table class="table light-gray-border table-bordered m-b-none">
                    <thead>
                        <th>${$filter('translate')('BASIC_DATA.SERVICE')}</th>
                        <th>${$filter('translate')('BASIC_DATA.PROVIDER')}</th>
                        <th>${$filter('translate')('GENERAL.MOVEMENT_TYPE')}</th>
                        <th>${$filter('translate')('BASIC_DATA.ROUTING_DETAILS')}</th>
                        <th>${$filter('translate')('GENERAL.TRANSIT_TIME')}</th>
                    </thead>
                    <tbody>
            `;

            if (transitTime.TRANSIT_TIME_DOMESTIC_ORIGIN) transitTimeDetails += this.buildTransitTimeDetailsTableRows(transitTime.TRANSIT_TIME_DOMESTIC_ORIGIN.LOGISTIC_SOLUTION, null, null, transitTime.TRANSIT_TIME_DOMESTIC_ORIGIN.LEGS_DOMESTIC, transitTime.TRANSIT_TIME_DOMESTIC_ORIGIN.PROVIDER || transitTime.TRANSIT_TIME_DOMESTIC_ORIGIN.LEGAL_PERSON_AGENT);
            if (transitTime.TRANSIT_TIME_AIR) transitTimeDetails += this.buildTransitTimeDetailsTableRows(`${transitTime.TRANSIT_TIME_AIR.LOGISTIC_SOLUTION}(${transitTime.TRANSIT_TIME_AIR.TRANSSHIPMENT ? 'T' : 'D'})`, transitTime.TRANSIT_TIME_AIR.LEGS_AIR, null, null, transitTime.TRANSIT_TIME_AIR.PROVIDER);
            if (transitTime.TRANSIT_TIME_MARITIME) transitTimeDetails += this.buildTransitTimeDetailsTableRows(`${transitTime.TRANSIT_TIME_MARITIME.LOGISTIC_SOLUTION}(${transitTime.TRANSIT_TIME_MARITIME.TRANSSHIPMENT ? 'T' : 'D'})`, null, transitTime.TRANSIT_TIME_MARITIME.LEGS_MARITIME, null, transitTime.TRANSIT_TIME_MARITIME.PROVIDER);
            if (transitTime.TRANSIT_TIME_DOMESTIC_DESTINATION) transitTimeDetails += this.buildTransitTimeDetailsTableRows(transitTime.TRANSIT_TIME_DOMESTIC_DESTINATION.LOGISTIC_SOLUTION, null, null, transitTime.TRANSIT_TIME_DOMESTIC_DESTINATION.LEGS_DOMESTIC, transitTime.TRANSIT_TIME_DOMESTIC_DESTINATION.PROVIDER || transitTime.TRANSIT_TIME_DOMESTIC_DESTINATION.LEGAL_PERSON_AGENT);

            transitTimeDetails += `
                <tr>
                    <td colspan="4" style="text-align: right;">
                        <strong>${$filter('translate')('GENERAL.TOTAL_TRANSIT_TIME')}</strong>
                    </td>
                    <td><strong>${transitTime.TRANSIT_TIME_AIR ? transitTime.TRANSIT_TIME_MIN + '-' + transitTime.TRANSIT_TIME_MAX : transitTime.TRANSIT_TIME_MIN}</strong></td>
                </tr>
            `;
            transitTimeDetails += "</tbody></table>";
            html = `
                <div class="row">
                    <div class="col-lg-12">
                        ${transitTimeDetails}
                    </div>
                </div>
            `;
            return html;
        } catch (ex) {
            throw ex;
        }
    }

    public static buildTransitTimeDetailsTableRows(logisticSolution: string, legsAir: ILegsAir[], legsMaritime: ILegsMaritime[], legsDomestic: ILegsDomestic[], provider?: ISelectorModel): string {
        try {
            let tableRows = "";
            if (legsAir && legsAir.length > 0) {
                for (let index = 0; index < legsAir.length; index++) {
                    const leg = legsAir[index];
                    const transitTimeMin = leg.TRANSIT_TIME_MIN != null ? leg.TRANSIT_TIME_MIN : 'GENERAL.NOT_COMPLETE';
                    tableRows += `
                        <tr>
                            <td style="width: 20%;">${logisticSolution}</td>
                            <td style="width: 20%;">${provider ? provider.NAME : ''}</td>
                            <td style="width: 20%;">${leg.ORIGIN_TYPE && leg.DESTINATION_TYPE ? leg.ORIGIN_TYPE.NAME + '/' + leg.DESTINATION_TYPE.NAME : ''}</td>
                            <td style="width: 20%;">${leg.ROUTING_POINT_ORIGIN && leg.ROUTING_POINT_DESTINATION ? leg.ROUTING_POINT_ORIGIN.CODE + ' X ' + leg.ROUTING_POINT_DESTINATION.CODE : ''}</td>
                            <td style="width: 20%;">${leg.TRANSIT_TIME_MAX ? transitTimeMin + ' - ' + leg.TRANSIT_TIME_MAX : transitTimeMin}</td>
                        </tr>
                    `;
                }
            } else if (legsMaritime && legsMaritime.length > 0) {
                for (let index = 0; index < legsMaritime.length; index++) {
                    const leg = legsMaritime[index];
                    tableRows += `<tr>
                        <td style="width: 20%;">${logisticSolution}</td>
                        <td style="width: 20%;">${provider ? provider.NAME : ''}</td>
                        <td style="width: 20%;">${leg.TYPE_ORIGIN && leg.TYPE_DESTINATION ? leg.TYPE_ORIGIN.NAME + '/' + leg.TYPE_DESTINATION.NAME : ''}</td>
                        <td style="width: 20%;">${leg.ROUTING_POINT_ORIGIN && leg.ROUTING_POINT_DESTINATION ? leg.ROUTING_POINT_ORIGIN.CODE + ' X ' + leg.ROUTING_POINT_DESTINATION.CODE : ''}</td>
                        <td style="width: 20%;">${leg.TRANSIT_TIME ? leg.TRANSIT_TIME : 'GENERAL.NOT_COMPLETE'}</td>
                    </tr>`;
                }
            } else if (legsDomestic && legsDomestic.length > 0) {
                for (let index = 0; index < legsDomestic.length; index++) {
                    const leg = legsDomestic[index];
                    const transitTimeMin = leg.TRANSIT_TIME_MIN != null ? leg.TRANSIT_TIME_MIN : 'GENERAL.NOT_COMPLETE';
                    tableRows += `
                        <tr>
                            <td style="width: 20%;">${logisticSolution}</td>
                            <td style="width: 20%;">${provider ? provider.NAME : ''}</td>
                            <td style="width: 20%;">${leg.TYPE_LOCAL_ORIGIN && leg.TYPE_LOCAL_DESTINATION ? leg.TYPE_LOCAL_ORIGIN.NAME + '/' + leg.TYPE_LOCAL_DESTINATION.NAME : ''}</td>
                            <td style="width: 20%;">${leg.ROUTING_POINT_ORIGIN && leg.ROUTING_POINT_DESTINATION ? leg.ROUTING_POINT_ORIGIN.CODE + ' X ' + leg.ROUTING_POINT_DESTINATION.CODE : ''}</td>
                            <td style="width: 20%;">${leg.TRANSIT_TIME_MAX ? transitTimeMin + ' - ' + leg.TRANSIT_TIME_MAX : transitTimeMin}</td>
                        </tr>
                    `;
                }
            }
            return tableRows;
        } catch (ex) {
            throw ex;
        }
    }

    public static buildDetDemDetailDirection(product: string, tariffDetDem: ITariffDetDemDetails): ITariffDetDemDetails {
        try {
            let result = null;
            if (tariffDetDem) {
                if (product == 'IM') {
                    result = tariffDetDem.DET_DEM_DESTINATION.SUMMARY;
                } else if (product == 'EM') {
                    result = tariffDetDem.DET_DEM_ORIGIN.SUMMARY;
                }
            }
            return result;
        } catch (ex) {
            throw ex;
        }
    }

    public static buildOfferOptionErrorTooltip(errors: string[]): string {
        try {
            let tooltip = "";
            if (errors && errors.length) {
                for (const reason of errors) {
                    if (tooltip) tooltip += "<br>";
                    tooltip += reason;
                }
            }
            return tooltip;
        } catch (ex) {
            throw ex;
        }
    }

    public static buildOfferOptionInconsistencyHtml(offerOptionInconsistencyList: ISelectorModel[], validationResult: IOfferOptionValidationResult): string {
        try {
            const validationMessages: string[] = [];
            if (!offerOptionInconsistencyList || offerOptionInconsistencyList.length == 0) validationMessages.push("Offer option inconsistency not found.");
            else {
                if (!validationResult.FILTERS_BASE) {
                    const filtersBaseGeneric = offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.FILTERS_BASE);
                    if (filtersBaseGeneric) validationMessages.push(`${filtersBaseGeneric.CODE} - ${filtersBaseGeneric.NAME}`);
                }
                if (!validationResult.TYPE_CARGO) {
                    const typeCargoGeneric = offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.TYPE_CARGO);
                    if (typeCargoGeneric) validationMessages.push(`${typeCargoGeneric.CODE} - ${typeCargoGeneric.NAME}`);
                }
                if (!validationResult.TARIFF_FREIGHT_RECEIVING) {
                    const typeCargoGeneric = offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.TARIFF_FREIGHT_RECEIVING);
                    if (typeCargoGeneric) validationMessages.push(`${typeCargoGeneric.CODE} - ${typeCargoGeneric.NAME}`);
                }
                if (!validationResult.AGENTS) {
                    const agentsGeneric = offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.AGENTS);
                    if (agentsGeneric) validationMessages.push(`${agentsGeneric.CODE} - ${agentsGeneric.NAME}`);
                }
                if (!validationResult.ACCOUNT_REQUIREMENT) {
                    const accountRequirementGeneric = offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.ACCOUNT_REQUIREMENT);
                    if (accountRequirementGeneric) validationMessages.push(`${accountRequirementGeneric.CODE} - ${accountRequirementGeneric.NAME}`);
                }
                if (!validationResult.TARIFF_DET_DEM_PAYMENT) {
                    const tariffDetDemPaymentGeneric = offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.TARIFF_DET_DEM_PAYMENT);
                    if (tariffDetDemPaymentGeneric) validationMessages.push(`${tariffDetDemPaymentGeneric.CODE} - ${tariffDetDemPaymentGeneric.NAME}`);
                }
                if (!validationResult.TARIFF_DET_DEM_RECEIVING) {
                    const tariffDetDemReceivingGeneric = offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.TARIFF_DET_DEM_RECEIVING);
                    if (tariffDetDemReceivingGeneric) validationMessages.push(`${tariffDetDemReceivingGeneric.CODE} - ${tariffDetDemReceivingGeneric.NAME}`);
                }
                if (!validationResult.TARIFF_LOCAL) {
                    const tariffLocalGeneric = offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.TARIFF_LOCAL);
                    if (tariffLocalGeneric) validationMessages.push(`${tariffLocalGeneric.CODE} - ${tariffLocalGeneric.NAME}`);
                }
                if (!validationResult.COMMODITY) {
                    const commodityGeneric = offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.COMMODITY);
                    if (commodityGeneric) validationMessages.push(`${commodityGeneric.CODE} - ${commodityGeneric.NAME}`);
                }
                if (!validationResult.TARIFF_DOMESTIC_ORIGIN) {
                    const tariffDomesticOriginGeneric = offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.TARIFF_DOMESTIC_ORIGIN);
                    if (tariffDomesticOriginGeneric) validationMessages.push(`${tariffDomesticOriginGeneric.CODE} - ${tariffDomesticOriginGeneric.NAME}`);
                }
                if (!validationResult.TARIFF_DOMESTIC_DESTINATION) {
                    const tariffDomesticDestinationGeneric = offerOptionInconsistencyList.find(inconsistency => inconsistency.ID == EValidationResultId.TARIFF_DOMESTIC_DESTINATION);
                    if (tariffDomesticDestinationGeneric) validationMessages.push(`${tariffDomesticDestinationGeneric.CODE} - ${tariffDomesticDestinationGeneric.NAME}`);
                }
            }
            return StringUtil.getCONCAT(validationMessages);
        } catch (ex) {
            throw ex;
        }
    }

    public static buildTooltipRoutes(routes: IOfferOptionRoutingPoint[]): string {
        try {
            let tooltip = "";
            if (routes && routes.length > 0) {
                for (let routeIndex = 0; routeIndex < routes.length; routeIndex++) {
                    const route = routes[routeIndex];
                    if (routeIndex == 0) {
                        tooltip += route.NAME;
                    }
                    else if (((routeIndex + 1) == routes.length)) {
                        tooltip += " - " + route.NAME;
                    } else tooltip += " - " + route.NAME;
                }
            }
            return tooltip;
        } catch (ex) {
            throw ex;
        }
    }

    public static buildRoutesHtml(offerOption: IOfferOption): string {
        try {
            const routes: IOfferOptionRoutingPoint[] = this.getOfferOptionRoutes(offerOption);
            let routesHtml = "";
            const routesSeparator = "<label class='cursor-hand text-strong text-dark padding-xs-horizontal v-middle'>-</label>";
            for (let index = 0; index < routes.length; index++) {
                const route = routes[index];
                if (index == 0) {
                    routesHtml += `<label class="cursor-hand text-strong text-dark" style="vertical-align: sub;">${route.CODE}</label>`
                    if (route.COUNTRY && route.COUNTRY.COUNTRY_IMG) routesHtml += `<span style="margin-left: 2px;"><i class="flag-icon monaco-flag ${route.COUNTRY.COUNTRY_IMG.CODE}"></i></span>`;
                }
                else if (((index + 1) == routes.length)) {
                    routesHtml += `${routesSeparator} <label class="cursor-hand text-strong text-dark" style="vertical-align: sub;">${route.CODE}</label>`;
                    if (route.COUNTRY && route.COUNTRY.COUNTRY_IMG) routesHtml += `<span style="margin-left: 2px;"><i class="flag-icon monaco-flag ${route.COUNTRY.COUNTRY_IMG.CODE}"></i></span>`;
                }
            }
            return routesHtml;
        } catch (ex) {
            throw ex;
        }
    }

    public static buildDetDemDetails($filter: ng.FilterFactory, tariffDetDemOriginDestination: IDetDemOriginDestination, direction: string) {
        try {
            let originDetailRow = ``;
            let freeTimeDetailRow = ``;
            let htmlOriginStart = `<div class="row">
            <div class="col-lg-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <div class="row">
                            <div class="col-lg-3 text-strong text-center">${$filter('translate')('BASIC_DATA.EQUIPMENT')}</div>
                            <div class="col-lg-3 text-strong text-center">${$filter('translate')('GENERAL.TYPE')}</div>
                            <div class="col-lg-3 text-strong text-center">${$filter('translate')('BASIC_DATA.PAYMENT_NATURE')}</div>
                            <div class="col-lg-3 text-strong text-center">${$filter('translate')('BASIC_DATA.FREE_TIME')}</div>
                        </div>
                    </div>
                    <div class="panel panel-body" style="margin-bottom: 0px;">`;
            if (tariffDetDemOriginDestination) {
                for (let index = 0; index < tariffDetDemOriginDestination.DETAILS.length; index++) {
                    const detail = tariffDetDemOriginDestination.DETAILS[index];
                    originDetailRow += `
                            <div class="row">
                                <div class="col-lg-3 text-center">${StringUtil.getCONCAT(detail.EQUIPMENT)}</div>
                                <div class="col-lg-3 text-center">${detail.TYPE}</div>
                                <div class="col-lg-3 text-center">${detail.PAYMENT_NATURE.ID == '1' ? '- ' + $filter('translate')('PRODUCT.BUYING') : ' + ' + $filter('translate')('PRODUCT.SELLING')}</div>
                                <div class="col-lg-3 text-center">${detail.FREE_TIME} </div>`;

                    if (detail.FREE_TIME_DETAILS && detail.FREE_TIME_DETAILS.length > 0) {
                        originDetailRow += `<div class="panel-heading collapse-heading clickable-div collapsed" data-toggle="collapse" data-target="#freeTimeDetails_${direction}_${index}" aria-controls="collapseShareTable" aria-expanded="false"></div>`;
                    }

                    originDetailRow += `</div>`;

                    originDetailRow += `<div id="freeTimeDetails_${direction}_${index}" class="toggle-me collapse" role="tabpanel" tabindex="-1" aria-expanded="false" style="height: 30px; margin-bottom:0px;">
                            <div class="row">
                                <div class="col-lg-12 table-responsive">
                                    <table class="table gray-border table-striped no-border-inside monaco-custom-table m-b-sm m-t-sm" style="width: 100%;">
                                        <thead>
                                            <tr>
                                                <th class="text-center">${$filter('translate')('PRODUCT.FT_PERIOD')}</th>
                                                <th class="text-center">${$filter('translate')('GENERAL.OF')}</th>
                                                <th class="text-center">${$filter('translate')('GENERAL.TO')}</th>
                                                <th class="text-center">${$filter('translate')('GENERAL.DAYS')}</th>
                                                <th class="text-center">${$filter('translate')('GENERAL.CURRENCY')}</th>
                                                <th class="text-center">${$filter('translate')('FINANCIAL.ORIGINAL_VALUE')}</th>
                                                <th class="text-center">${$filter('translate')('FINANCIAL.ACTUAL_TOTAL_AMOUNT')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                    `;

                    if (detail.FREE_TIME_DETAILS && detail.FREE_TIME_DETAILS.length > 0) {
                        for (let freeTimeInfos = 0; freeTimeInfos < detail.FREE_TIME_DETAILS.length; freeTimeInfos++) {
                            freeTimeDetailRow += `<tr>
                                                    <td class="text-center">${detail.FREE_TIME_DETAILS[freeTimeInfos].PERIOD}</td>
                                                    <td class="text-center">${detail.FREE_TIME_DETAILS[freeTimeInfos].FROM}</td>
                                                    <td class="text-center">${detail.FREE_TIME_DETAILS[freeTimeInfos].UNTIL}</td>
                                                    <td class="text-center">${detail.FREE_TIME_DETAILS[freeTimeInfos].DAYS}</td>
                                                    <td class="text-center">${detail.FREE_TIME_DETAILS[freeTimeInfos].CURRENCY.NAME}</td>
                                                    <td class="text-right">${$filter('number')(detail.FREE_TIME_DETAILS[freeTimeInfos].ORIGINAL_VALUE, 2)}</td>
                                                    <td class="text-right">${$filter('number')(detail.FREE_TIME_DETAILS[freeTimeInfos].TOTAL_VALUE, 2)}</td>
                                                </tr>`;
                        }
                    }
                    originDetailRow += freeTimeDetailRow;
                    originDetailRow += `
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>`;
                    freeTimeDetailRow = ``;
                }
            }
            htmlOriginStart += originDetailRow;
            htmlOriginStart += `
                                </div>
                            </div>
                    </div>`;
            return htmlOriginStart;
        } catch (ex) {
            throw ex;
        }
    }

    public static getTariffLocal(route, offerOption) {
        if (!route) return;
        if (!offerOption) return;

        const TARIFF_LOCAL_ORIGIN = {
            TARIFF_LOCAL_ORIGIN_RECEIVING: {
                ID: (offerOption.ID_TARIFF_LOCAL_ORIGIN_RECEIVING) ? offerOption.ID_TARIFF_LOCAL_ORIGIN_RECEIVING : null,
                NAME: (offerOption.TARIFF_LOCAL_ORIGIN_RECEIVING_CONCATENATED) ? offerOption.TARIFF_LOCAL_ORIGIN_RECEIVING_CONCATENATED : null,
                CODE: ERouteEventType.TARIFF_LOCAL_ORIGIN
            },
            TARIFF_LOCAL_ORIGIN_PAYMENT: {
                ID: (offerOption.ID_TARIFF_LOCAL_ORIGIN_PAYMENT) ? offerOption.ID_TARIFF_LOCAL_ORIGIN_PAYMENT : null,
                NAME: (offerOption.TARIFF_LOCAL_ORIGIN_PAYMENT_CONCATENATED) ? offerOption.TARIFF_LOCAL_ORIGIN_PAYMENT_CONCATENATED : null,
                CODE: ERouteEventType.TARIFF_LOCAL_ORIGIN
            },
        }

        const TARIFF_LOCAL_DESTINATION = {
            TARIFF_LOCAL_DESTINATION_RECEIVING: {
                ID: (offerOption.ID_TARIFF_LOCAL_DESTINATION_RECEIVING) ? offerOption.ID_TARIFF_LOCAL_DESTINATION_RECEIVING : null,
                NAME: (offerOption.TARIFF_LOCAL_DESTINATION_RECEIVING_CONCATENATED) ? offerOption.TARIFF_LOCAL_DESTINATION_RECEIVING_CONCATENATED : null,
                CODE: ERouteEventType.TARIFF_LOCAL_DESTINATION
            },
            TARIFF_LOCAL_DESTINATION_PAYMENT: {
                ID: (offerOption.ID_TARIFF_LOCAL_DESTINATION_PAYMENT) ? offerOption.ID_TARIFF_LOCAL_DESTINATION_PAYMENT : null,
                NAME: (offerOption.TARIFF_LOCAL_DESTINATION_PAYMENT_CONCATENATED) ? offerOption.TARIFF_LOCAL_DESTINATION_PAYMENT_CONCATENATED : null,
                CODE: ERouteEventType.TARIFF_LOCAL_DESTINATION
            },
        }

        route.TARIFF_LOCAL_ORIGIN = TARIFF_LOCAL_ORIGIN;
        route.TARIFF_LOCAL_DESTINATION = TARIFF_LOCAL_DESTINATION;
    }

    public static getOfferOptionRoutes(offerOption: IOfferOption): IOfferOptionRoutingPoint[] {
        try {
            const routes: IOfferOptionRoutingPoint[] = [];

            if (offerOption.PUP) {
                let pupRoute = null;
                if (offerOption.PUP.FREIGHT && offerOption.PUP.FREIGHT.ROUTING_POINT) {
                    pupRoute = offerOption.PUP.FREIGHT.ROUTING_POINT;
                    pupRoute.TYPE = ERouteEventType.TARIFF_FREIGHT;
                }
                else if (offerOption.PUP.DOMESTIC && offerOption.PUP.DOMESTIC.ORIGIN && offerOption.PUP.DOMESTIC.ORIGIN.ROUTING_POINT) {
                    pupRoute = offerOption.PUP.DOMESTIC.ORIGIN.ROUTING_POINT;
                    pupRoute.TYPE = ERouteEventType.TARIFF_DOMESTIC_ORIGIN;
                }
                if (pupRoute) {
                    pupRoute.EVENT = ERouteEventType.PICK_UP;
                    this.getTariffLocal(pupRoute, offerOption.PUP);
                    routes.push(pupRoute);
                }
            }
            if (offerOption.OTFS) {
                let otfsRoute = null;
                if (offerOption.OTFS.FREIGHT && offerOption.OTFS.FREIGHT.ROUTING_POINT) {
                    otfsRoute = offerOption.OTFS.FREIGHT.ROUTING_POINT;
                    otfsRoute.TYPE = ERouteEventType.TARIFF_FREIGHT;
                }
                else if (offerOption.OTFS.DOMESTIC && offerOption.OTFS.DOMESTIC.ORIGIN && offerOption.OTFS.DOMESTIC.ORIGIN.ROUTING_POINT) {
                    otfsRoute = offerOption.OTFS.DOMESTIC.ORIGIN.ROUTING_POINT;
                    otfsRoute.TYPE = ERouteEventType.TARIFF_DOMESTIC_ORIGIN;
                }
                if (otfsRoute) {
                    otfsRoute.EVENT = ERouteEventType.ORIGIN_TERMINAL_FREIGHT_STATION;
                    this.getTariffLocal(otfsRoute, offerOption.OTFS);
                    routes.push(otfsRoute);
                }
            }
            if (offerOption.POLAOL) {
                let polaolRoute = null;
                if (offerOption.POLAOL.FREIGHT && offerOption.POLAOL.FREIGHT.ROUTING_POINT) {
                    polaolRoute = offerOption.POLAOL.FREIGHT.ROUTING_POINT;
                    polaolRoute.TYPE = ERouteEventType.TARIFF_FREIGHT;
                }
                else if (offerOption.POLAOL.DOMESTIC && offerOption.POLAOL.DOMESTIC.ORIGIN && offerOption.POLAOL.DOMESTIC.ORIGIN.ROUTING_POINT) {
                    polaolRoute = offerOption.POLAOL.DOMESTIC.ORIGIN.ROUTING_POINT;
                    polaolRoute.TYPE = ERouteEventType.TARIFF_DOMESTIC_ORIGIN;
                }
                if (polaolRoute) {
                    polaolRoute.EVENT = `${ERouteEventType.PORT_OF_LOAD}/${ERouteEventType.AIRPORT_OF_ORIGIN}`;
                    this.getTariffLocal(polaolRoute, offerOption.POLAOL);
                    routes.push(polaolRoute);
                }
            }
            if (offerOption.PODAOD) {
                let podaodRoute = null;
                if (offerOption.PODAOD.FREIGHT && offerOption.PODAOD.FREIGHT.ROUTING_POINT) {
                    podaodRoute = offerOption.PODAOD.FREIGHT.ROUTING_POINT;
                    podaodRoute.TYPE = ERouteEventType.TARIFF_FREIGHT;
                }
                else if (offerOption.PODAOD.DOMESTIC && offerOption.PODAOD.DOMESTIC.DESTINATION && offerOption.PODAOD.DOMESTIC.DESTINATION.ROUTING_POINT) {
                    podaodRoute = offerOption.PODAOD.DOMESTIC.DESTINATION.ROUTING_POINT;
                    podaodRoute.TYPE = ERouteEventType.TARIFF_DOMESTIC_DESTINATION;
                }
                if (podaodRoute) {
                    podaodRoute.EVENT = `${ERouteEventType.PORT_OF_DESTINATION}/${ERouteEventType.AIRPORT_OF_DESTINATION}`;
                    this.getTariffLocal(podaodRoute, offerOption.PODAOD);
                    routes.push(podaodRoute);
                }
            }
            if (offerOption.DTFS) {
                let dtfsRoute = null;
                if (offerOption.DTFS.FREIGHT && offerOption.DTFS.FREIGHT.ROUTING_POINT) {
                    dtfsRoute = offerOption.DTFS.FREIGHT.ROUTING_POINT;
                    dtfsRoute.TYPE = ERouteEventType.TARIFF_FREIGHT;
                }
                else if (offerOption.DTFS.DOMESTIC && offerOption.DTFS.DOMESTIC.DESTINATION && offerOption.DTFS.DOMESTIC.DESTINATION.ROUTING_POINT) {
                    dtfsRoute = offerOption.DTFS.DOMESTIC.DESTINATION.ROUTING_POINT;
                    dtfsRoute.TYPE = ERouteEventType.TARIFF_DOMESTIC_DESTINATION;
                }
                if (dtfsRoute) {
                    dtfsRoute.EVENT = ERouteEventType.DESTINATION_TERMINAL_FREIGHT_STATION;
                    this.getTariffLocal(dtfsRoute, offerOption.DTFS);
                    routes.push(dtfsRoute);
                }
            }
            if (offerOption.PLD) {
                let pldRoute = null;
                if (offerOption.PLD.FREIGHT && offerOption.PLD.FREIGHT.ROUTING_POINT) {
                    pldRoute = offerOption.PLD.FREIGHT.ROUTING_POINT;
                    pldRoute.TYPE = ERouteEventType.TARIFF_FREIGHT;
                }
                else if (offerOption.PLD.DOMESTIC && offerOption.PLD.DOMESTIC.DESTINATION && offerOption.PLD.DOMESTIC.DESTINATION.ROUTING_POINT) {
                    pldRoute = offerOption.PLD.DOMESTIC.DESTINATION.ROUTING_POINT;
                    pldRoute.TYPE = ERouteEventType.TARIFF_DOMESTIC_DESTINATION;
                }
                if (pldRoute) {
                    pldRoute.EVENT = ERouteEventType.PLACE_OF_DELIVERY;
                    this.getTariffLocal(pldRoute, offerOption.PLD);
                    routes.push(pldRoute);
                }
            }
            return routes;
        } catch (ex) {
            throw ex;
        }
    }

    public static buildTariffDetDemDetails($filter: ng.FilterFactory, tariffDetDem: ITariffDetDemDetails): string {
        try {
            let htmlOrigin = '';
            let htmlDestination = '';

            if (tariffDetDem.DET_DEM_ORIGIN) {
                const originTable = this.buildDetDemDetails($filter, tariffDetDem.DET_DEM_ORIGIN, 'origin');

                htmlOrigin += `
            <div class="row">
                <div class="col-lg-12">
                    <div class="panel panel-default">
                    <div class="panel-heading">
                        <div class="row">
                            <div class="col-lg-3 text-left text-strong" style="font-size: 2.5rem;">${$filter('translate')('BASIC_DATA.ORIGIN')}</div>
                            <div class="col-lg-8 text-left">
                                <span class="cursor-hand text-strong openTariffDetDemOriginPayment"
                                tooltip-placement="auto top" tooltip-append-to-body="true"> ${$filter('translate')('PRODUCT.TARRIF_PAYMENT')}: ${tariffDetDem.DET_DEM_ORIGIN.CONCATENATED_PAYMENT}
                                    <i class="fa fa-link text-cyano link-icon"></i>
                                </span>`;
                if (tariffDetDem.DET_DEM_ORIGIN.CONCATENATED_RECEIVING) {
                    htmlOrigin += `<br>                                
                                <span class="cursor-hand text-strong openTariffDetDemOriginReceiving"
                                tooltip-placement="auto top" tooltip-append-to-body="true">${$filter('translate')('PRODUCT.TARRIF_RECEIVING')}: ${tariffDetDem.DET_DEM_ORIGIN.CONCATENATED_RECEIVING}
                                    <i class="fa fa-link text-cyano link-icon"></i>
                                </span>`;
                }

                htmlOrigin += `</div>
                            <div class="col-lg-1 text-right">`;

                for (const summary of tariffDetDem.DET_DEM_ORIGIN.SUMMARY) {
                    for (const equipment of summary.EQUIPMENT) {
                        htmlOrigin += `${equipment.NAME}`;
                    }
                    htmlOrigin += ` =
                    <span class="text-strong">${summary.SUMMARY_PAYMENT.COMBINED ? summary.SUMMARY_PAYMENT.COMBINED : summary.SUMMARY_PAYMENT.DET + '+' + summary.SUMMARY_PAYMENT.DEM} / ${summary.SUMMARY_RECEIVING.COMBINED ? summary.SUMMARY_RECEIVING.COMBINED : summary.SUMMARY_RECEIVING.DET + '+' + summary.SUMMARY_RECEIVING.DEM}</span><br>
                `;
                }
                htmlOrigin += `</div>
                        </div>                        
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 table-responsive">${originTable}</div>
                </div>
            </div>`;
            }

            if (tariffDetDem.DET_DEM_DESTINATION) {
                const destinationTable = this.buildDetDemDetails($filter, tariffDetDem.DET_DEM_DESTINATION, 'destination');
                htmlDestination += `<div class="row">
            <div class="col-lg-12">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <div class="row">
                            <div class="col-lg-3 text-left text-strong" style="font-size: 2.5rem;">${$filter('translate')('BASIC_DATA.DESTINATION')}</div>
                            <div class="col-lg-8 text-left">
                                <span class="cursor-hand text-strong openTariffDetDemDestinationPayment"
                                tooltip-placement="auto top" tooltip-append-to-body="true">${$filter('translate')('PRODUCT.TARRIF_PAYMENT')}: ${tariffDetDem.DET_DEM_DESTINATION.CONCATENATED_PAYMENT}
                                    <i class="fa fa-link text-cyano link-icon"></i>
                                </span>`;
                if (tariffDetDem.DET_DEM_DESTINATION.CONCATENATED_RECEIVING) {
                    htmlDestination += `<br>
                                <span class="cursor-hand text-strong openTariffDetDemDestinationReceiving"
                                tooltip-placement="auto top" tooltip-append-to-body="true">${$filter('translate')('PRODUCT.TARRIF_RECEIVING')}: ${tariffDetDem.DET_DEM_DESTINATION.CONCATENATED_RECEIVING}
                                    <i class="fa fa-link text-cyano link-icon"></i>
                                </span>`;
                }
                htmlDestination += `</div>
                            <div class="col-lg-1 text-right">`;
                for (const summary of tariffDetDem.DET_DEM_DESTINATION.SUMMARY) {
                    for (const equipment of summary.EQUIPMENT) {
                        htmlDestination += `${equipment.NAME}`;
                    }
                    htmlDestination += ` =
                                <span class="text-strong">${summary.SUMMARY_PAYMENT.COMBINED ? summary.SUMMARY_PAYMENT.COMBINED : summary.SUMMARY_PAYMENT.DET + '+' + summary.SUMMARY_PAYMENT.DEM} / ${summary.SUMMARY_RECEIVING.COMBINED ? summary.SUMMARY_RECEIVING.COMBINED : summary.SUMMARY_RECEIVING.DET + '+' + summary.SUMMARY_RECEIVING.DEM}</span>
                                <br>`;
                }
                htmlDestination += `</div>
                        </div>                        
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 table-responsive">${destinationTable}</div>
                </div>
            </div>`;
            }

            let html = ``;
            html += htmlOrigin;
            html += htmlDestination;

            return html;
        } catch (ex) {
            throw ex;
        }
    }

    public static buildProfitShareDetails($filter: ng.FilterFactory, profitShareDetail: IOfferProfitShareDetail): string {
        try {
            const chargeTable = this.buildProfitShareDetailChargeTable($filter, profitShareDetail.COMPOSITION_ORIGINAL, profitShareDetail.COMPOSITION, profitShareDetail.PROFIT_LOSS);
            const profitShareTable = this.buildProfitShareDetailProfitShareTable($filter, profitShareDetail.SHARE ? profitShareDetail.SHARE.SHARE_ITEM : null, profitShareDetail.SHARE != null ? profitShareDetail.SHARE.TOTAL : null, profitShareDetail.SHARE != null ? profitShareDetail.SHARE.SHARE_VALUE : null);

            const html = `
                <div id="modalProfitShareDetail" class="tab-content">
                    <div class="row">
                        <div class="col-lg-12">
                            <label>${$filter('translate')('PRODUCT.AGREEMENT')}: <span id="profitShareDetailAgreement" class="cursor-hand text-u-l">${profitShareDetail.AGREEMENT}</span></label><br>
                            <label>${$filter('translate')('BASIC_DATA.CHARGE')}: ${profitShareDetail.CHARGE_NAME ? profitShareDetail.CHARGE_NAME.NAME : ''}</label><br>
                            <label>${$filter('translate')('FINANCIAL.PROFIT_SHARE_CURRENCY')}: ${profitShareDetail.CURRENCY ? profitShareDetail.CURRENCY.CODE : ''}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="panel panel-default">
                                <div class="panel-heading">${$filter('translate')('PRODUCT.PROFIT_SHARE_DETALING')}</div>
                                <div class="panel-body">
                                    <div class="row"><div class="col-lg-12 table-responsive">${profitShareTable}</div></div>                                
                                </div>
                            </div>                        
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="panel panel-default">
                                <div class="panel-heading collapse-heading clickable-div" data-toggle="collapse"
                                data-target="#collapseShareTable" aria-controls="collapseShareTable"">${$filter('translate')('FINANCIAL.COMPOSITION_CHARGES')}</div>
                                <div id="collapseShareTable" class="panel-body collapse toggle-me"
                                                role="tabpanel" tabindex="-1">
                                    <div class="row"><div class="col-lg-12 table-responsive">${chargeTable}</div></div>                                
                                </div>
                            </div>                        
                        </div>
                    </div>
                </div>
            `;
            return html;
        } catch (ex) {
            throw ex;
        }
    }

    private static buildProfitShareDetailChargeTable($filter: ng.FilterFactory, compositionOriginalList: IOfferProfitShareDetailComposition[], compositionList: IOfferProfitShareDetailComposition[], profitLoss: number): string {
        try {
            let chargeTableRows = "";

            for (const index in compositionOriginalList) {
                const compositionOriginal = compositionOriginalList[index];
                const composition = compositionList && compositionList.length ? compositionList[index] : null;

                let paymentApplicationTd = composition && composition.PAYMENT_CHARGE && composition.PAYMENT_CHARGE.CHARGE && composition.PAYMENT_CHARGE.CHARGE.APPLICATION ? composition.PAYMENT_CHARGE.CHARGE.APPLICATION.CODE : '';
                if (composition && composition.PAYMENT_CHARGE && composition.PAYMENT_CHARGE.CHARGE && composition.PAYMENT_CHARGE.CHARGE.EQUIPMENT) paymentApplicationTd += `(${composition.PAYMENT_CHARGE.CHARGE.EQUIPMENT.CODE})`;
                let receivingApplicationTd = composition && composition.RECEIVING_CHARGE && composition.RECEIVING_CHARGE.CHARGE && composition.RECEIVING_CHARGE.CHARGE.APPLICATION ? composition.RECEIVING_CHARGE.CHARGE.APPLICATION.CODE : '';
                if (composition && composition.RECEIVING_CHARGE && composition.RECEIVING_CHARGE.CHARGE && composition.RECEIVING_CHARGE.CHARGE.EQUIPMENT) receivingApplicationTd += `(${composition.RECEIVING_CHARGE.CHARGE.EQUIPMENT.CODE})`;
                chargeTableRows += `
                <tr>
                    <td>${composition && composition.CHARGE_NAME && composition.CHARGE_NAME.TYPE ? composition.CHARGE_NAME.TYPE.NAME : ''}</td>
                    <td>${composition && composition.CHARGE_NAME ? composition.CHARGE_NAME.CODE : ''}</td>
                    <td>${paymentApplicationTd}</td>
                    <td>${compositionOriginal.PAYMENT_CHARGE ? compositionOriginal.PAYMENT_CHARGE.AMOUNT : ''}</td>
                    <td>${compositionOriginal.PAYMENT_CHARGE && compositionOriginal.PAYMENT_CHARGE.CURRENCY ? compositionOriginal.PAYMENT_CHARGE.CURRENCY.CODE : ''}</td>
                    <td>${compositionOriginal.PAYMENT_CHARGE != null ? $filter('number')(compositionOriginal.PAYMENT_CHARGE.VALUE_MIN, 2) : ''}</td>
                    <td>${compositionOriginal.PAYMENT_CHARGE != null ? $filter('number')(compositionOriginal.PAYMENT_CHARGE.VALUE_UNITARY, 2) : ''}</td>
                    <td>${compositionOriginal.PAYMENT_CHARGE != null ? $filter('number')(compositionOriginal.PAYMENT_CHARGE.TOTAL, 2) : ''}</td>
                    <td>${composition && composition.PAYMENT_CHARGE != null ? $filter('number')(composition.PAYMENT_CHARGE.FACTOR, 4) : ''}</td>
                    <td>${composition && composition.PAYMENT_CHARGE != null ? $filter('number')(composition.PAYMENT_CHARGE.TOTAL, 2) : ''}</td>
                    <td>${receivingApplicationTd}</td>
                    <td>${compositionOriginal.RECEIVING_CHARGE ? compositionOriginal.RECEIVING_CHARGE.AMOUNT : ''}</td>
                    <td>${compositionOriginal.RECEIVING_CHARGE && compositionOriginal.RECEIVING_CHARGE.CURRENCY ? compositionOriginal.RECEIVING_CHARGE.CURRENCY.CODE : ''}</td>
                    <td>${compositionOriginal.RECEIVING_CHARGE != null ? $filter('number')(compositionOriginal.RECEIVING_CHARGE.VALUE_MIN, 2) : ''}</td>
                    <td>${compositionOriginal.RECEIVING_CHARGE != null ? $filter('number')(compositionOriginal.RECEIVING_CHARGE.VALUE_UNITARY, 2) : ''}</td>
                    <td>${compositionOriginal.RECEIVING_CHARGE != null ? $filter('number')(compositionOriginal.RECEIVING_CHARGE.TOTAL, 2) : ''}</td>
                    <td>${composition && composition.RECEIVING_CHARGE != null ? $filter('number')(composition.RECEIVING_CHARGE.FACTOR, 4) : ''}</td>
                    <td>${composition && composition.RECEIVING_CHARGE != null ? $filter('number')(composition.RECEIVING_CHARGE.TOTAL, 2) : ''}</td>
                    <td>${composition && composition.CHARGE_VALUE != null ? $filter('number')(composition.CHARGE_VALUE, 2) : ''}</td>
                </tr>`;
            }

            chargeTableRows += `
                <tr>
                    <td colspan="18" style="text-align: right;">
                        <strong>${$filter('translate')('FINANCIAL.TOTAL_PROFIT_LOSS')}</strong>
                    </td>
                    <td><strong>${$filter('number')(profitLoss, 2)}</strong></td>
                </tr>
            `;

            let chargeTable = `
                <table class="table light-gray-border table-bordered m-b-none">
                    <thead>
                        <tr>
                            <th rowspan="3" class="centered v-middle">${$filter('translate')('GENERAL.TYPE')}</th>
                            <th rowspan="3" class="centered v-middle">${$filter('translate')('BASIC_DATA.CHARGE')}</th>
                            <th colspan="8" class="centered">${$filter('translate')('PRODUCT.BUYING')}</th>
                            <th colspan="8" class="centered">${$filter('translate')('PRODUCT.SELLING')}</th>
                            <th rowspan="3" class="centered v-middle">${$filter('translate')('FINANCIAL.TOTAL_SHARE')}</th>
                        <tr>
                        <tr>
                            <th class="centered">${$filter('translate')('FINANCIAL.CHARGE_BASIS')}</th>
                            <th class="centered">${$filter('translate')('GENERAL.QUANTITY')}</th>
                            <th class="centered">${$filter('translate')('GENERAL.QUANTITY')}</th>
                            <th class="centered">${$filter('translate')('BASIC_DATA.MINIMUM')}</th>
                            <th class="centered">${$filter('translate')('GENERAL.UN_NUMBER')}</th>
                            <th class="centered">${$filter('translate')('GENERAL.TOTAL')}</th>
                            <th class="centered">${$filter('translate')('BASIC_DATA.EXCHANGE_RATE')}</th>
                            <th class="centered">${$filter('translate')('FINANCIAL.VALUE')}</th>
                            <th class="centered">${$filter('translate')('FINANCIAL.CHARGE_BASIS')}</th>
                            <th class="centered">${$filter('translate')('GENERAL.QUANTITY')}</th>
                            <th class="centered">${$filter('translate')('GENERAL.QUANTITY')}</th>
                            <th class="centered">${$filter('translate')('BASIC_DATA.MINIMUM')}</th>
                            <th class="centered">${$filter('translate')('GENERAL.UN_NUMBER')}</th>
                            <th class="centered">${$filter('translate')('GENERAL.TOTAL')}</th>
                            <th class="centered">${$filter('translate')('BASIC_DATA.EXCHANGE_RATE')}</th>
                            <th class="centered">${$filter('translate')('FINANCIAL.VALUE')}</th>
                        </tr>
                    </thead>
                    <tbody>${chargeTableRows}</tbody>
                </table>
            `;
            return chargeTable;
        } catch (ex) {
            throw ex;
        }
    }

    private static buildProfitShareDetailProfitShareTable($filter: ng.FilterFactory, shareItemList: IOfferOptionProfitShareDetailShareItem[], total: number, shareValue: number): string {
        try {
            let profitShareTableRows = "";
            if (shareItemList && shareItemList.length) {
                for (const shareItem of shareItemList) {
                    const isCompany = shareItem.PROFIT_SHARE && shareItem.PROFIT_SHARE.ID == "1";
                    if (shareItem.APPLICATION_PARAM && shareItem.APPLICATION_PARAM.length) {
                        for (let index = 0; index < shareItem.APPLICATION_PARAM.length; index++) {
                            const applicationParam = shareItem.APPLICATION_PARAM[index];
                            profitShareTableRows += '<tr>';
                            if (index == 0) {
                                profitShareTableRows += `
                                    <td rowspan="${shareItem.APPLICATION_PARAM.length}">${shareItem.PROFIT_SHARE ? shareItem.PROFIT_SHARE.NAME : ''}</td>
                                    <td rowspan="${shareItem.APPLICATION_PARAM.length}">${!isCompany && shareItem.PERCENTUAL_ORIGINAL != null ? $filter('number')(shareItem.PERCENTUAL_ORIGINAL, 2) : ''}<input type="hidden" value="${shareItem.PERCENTUAL_CALCULATED}"></input></td>
                                    <td>${!isCompany && applicationParam.MIN_APPLICATION != null ? $filter('number')(applicationParam.MIN_APPLICATION, 2) : ''}</td>
                                    <td>${!isCompany && applicationParam.APPLICATION ? applicationParam.APPLICATION.NAME : ''}</td>
                                    <td>${!isCompany && applicationParam.EQUIPMENT ? applicationParam.EQUIPMENT.CODE : ''}</td>
                                    <td>${!isCompany && applicationParam.FEET ? applicationParam.FEET.NAME : ''}</td>
                                    <td>${!isCompany && applicationParam.AMOUNT}</td>
                                    <td>${!isCompany && applicationParam.TOTAL_MIN_APPLICATION != null ? $filter('number')(applicationParam.TOTAL_MIN_APPLICATION, 2) : ''}</td>
                                    <td rowspan="${shareItem.APPLICATION_PARAM.length}">${!isCompany && shareItem.TOTAL_MIN_UNITARY != null ? $filter('number')(shareItem.TOTAL_MIN_UNITARY, 2) : ''}</td>
                                    <td rowspan="${shareItem.APPLICATION_PARAM.length}">${!isCompany && shareItem.MIN_FILE != null ? $filter('number')(shareItem.MIN_FILE, 2) : ''}</td>
                                    <td rowspan="${shareItem.APPLICATION_PARAM.length}">${!isCompany && shareItem.PERCENTUAL_RESULT != null ? $filter('number')(shareItem.PERCENTUAL_RESULT, 2) : ''}</td>
                                    <td rowspan="${shareItem.APPLICATION_PARAM.length}">${shareItem.SHARE != null ? $filter('number')(shareItem.SHARE, 2) : shareItem.SHARE}</td>
                                `;
                            } else {
                                profitShareTableRows += `
                                    <td>${!isCompany && applicationParam.MIN_APPLICATION != null ? $filter('number')(applicationParam.MIN_APPLICATION, 2) : ''}</td>
                                    <td>${!isCompany && applicationParam.APPLICATION ? applicationParam.APPLICATION.NAME : ''}</td>
                                    <td>${!isCompany && applicationParam.EQUIPMENT ? applicationParam.EQUIPMENT.CODE : ''}</td>
                                    <td>${!isCompany && applicationParam.FEET ? applicationParam.FEET.NAME : ''}</td>
                                    <td>${!isCompany && applicationParam.AMOUNT}</td>
                                    <td>${!isCompany && applicationParam.TOTAL_MIN_APPLICATION != null ? $filter('number')(applicationParam.TOTAL_MIN_APPLICATION, 2) : ''}</td>
                                `;
                            }
                            profitShareTableRows += '</tr>';
                        }
                    } else {
                        profitShareTableRows += `
                            <tr>
                                <td>${shareItem.PROFIT_SHARE ? shareItem.PROFIT_SHARE.NAME : ''}</td>
                                <td>${!isCompany && shareItem.PERCENTUAL_ORIGINAL != null ? $filter('number')(shareItem.PERCENTUAL_ORIGINAL, 2) : ''}<input type="hidden" value="${shareItem.PERCENTUAL_CALCULATED}"></input></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td>${!isCompany && shareItem.TOTAL_MIN_UNITARY != null ? $filter('number')(shareItem.TOTAL_MIN_UNITARY, 2) : ''}</td>
                                <td>${!isCompany && shareItem.MIN_FILE != null ? $filter('number')(shareItem.MIN_FILE, 2) : ''}</td>
                                <td>${!isCompany && shareItem.PERCENTUAL_RESULT != null ? $filter('number')(shareItem.PERCENTUAL_RESULT, 2) : ''}</td>
                                <td>${shareItem.SHARE != null ? $filter('number')(shareItem.SHARE, 2) : shareItem.SHARE}</td>
                            </tr>`;
                    }
                }
            }

            profitShareTableRows += `
                <tr>
                    <td colspan="11" style="text-align: right;">
                        <strong>${$filter('translate')('FINANCIAL.TOTAL_PROFIT_LOSS')}</strong>
                    </td>
                    <td><strong>${total != null ? $filter('number')(total, 2) : ''}</strong></td>
                </tr>
                <tr>
                    <td colspan="11" style="text-align: right;">
                        <strong>${$filter('translate')('FINANCIAL.TOTAL_PROFIT_SHARE')}</strong>
                    </td>
                    <td><strong>${shareValue != null ? $filter('number')(shareValue, 2) : ''}</strong></td>
                </tr>
            `;

            let profitShareTable = `
                <table class="table light-gray-border table-bordered m-b-none">
                    <thead>
                        <tr>    
                            <th>${$filter('translate')('PRODUCT.COMMISSIONED')}</th>
                            <th>%</th>
                            <th>${$filter('translate')('FINANCIAL.BASIS_MIN_UNIT')}</th>
                            <th>${$filter('translate')('FINANCIAL.CHARGE_BASIS')}</th>
                            <th>${$filter('translate')('GENERAL.EQUIPMENT')}</th>
                            <th>${$filter('translate')('GENERAL.EQUIPMENT_SIZE')}</th>
                            <th>${$filter('translate')('GENERAL.QUANTITY')}</th>
                            <th>${$filter('translate')('FINANCIAL.BASIS_MIN_SUBTOTAL')}</th>
                            <th>${$filter('translate')('FINANCIAL.BASIS_MIN_TOTAL')}</th>
                            <th>${$filter('translate')('FINANCIAL.FILE_MINIMUM')}</th>
                            <th>${$filter('translate')('FINANCIAL.PERC_RESULT')}</th>
                            <th>${$filter('translate')('FINANCIAL.SHARE')}</th>
                        </tr>
                    </thead>
                    <tbody>${profitShareTableRows}</tbody>
                </table>
            `;
            return profitShareTable;
        } catch (ex) {
            throw ex;
        }
    }

    public static buildPaymentReceivingChargesDetails($filter: ng.FilterFactory, typeCargo: ISelectorModel, productId: string, currentCurrency: ISelectorModel, charges: IOfferOptionCharge[], indicators: IOfferOptionIndicators): string {
        try {
            const chargeTable = OfferOldNewAlternativeHelper.buildPaymentReceivingChargeDetailsTable($filter, charges);
            const entranceTable = OfferOldNewAlternativeHelper.buildEntranceTable($filter, typeCargo, indicators);
            const indicatorTable = OfferOldNewAlternativeHelper.buildIndicatorTable($filter, typeCargo, productId, currentCurrency, indicators);

            const html = `
            <div class="tab-content">
                <div class="row">
                    <div class="col-lg-6">
                        <div class="panel panel-default">
                            <div class="panel-heading">${$filter('translate')('FINANCIAL.OFFER_TOTALS')}</div>
                            <div class="panel-body">
                                <div class="table-responsive">${entranceTable}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6">
                        <div class="panel panel-default">
                            <div class="panel-heading">${$filter('translate')('GENERAL.INDICATORS')}</div>
                            <div class="panel-body">
                                <div class="table-responsive">${indicatorTable}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div class="panel panel-default">
                            <div class="panel-heading collapse-heading clickable-div" data-toggle="collapse"
                            data-target="#collapseChargeTable" aria-controls="collapseChargeTable">${$filter('translate')('GENERAL.CHARGES')}</div>
                            <div id="collapseChargeTable" class="panel-body collapse toggle-me"
											role="tabpanel" tabindex="-1">
                                <div class="table-responsive">${chargeTable}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            `;
            return html;
        } catch (ex) {
            throw ex;
        }
    }

    private static buildPaymentReceivingChargeDetailsTable($filter: ng.FilterFactory, charges: IOfferOptionCharge[]): string {
        let chargeTableRows = "";
        if (charges && charges.length) {
            for (const charge of charges) {
                let paymentApplicationTd = charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.CHARGE && charge.PAYMENT_CHARGE.CHARGE.APPLICATION ? charge.PAYMENT_CHARGE.CHARGE.APPLICATION.CODE : '';
                if (charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.CHARGE && charge.PAYMENT_CHARGE.CHARGE.EQUIPMENT) paymentApplicationTd += `(${charge.PAYMENT_CHARGE.CHARGE.EQUIPMENT.CODE})`;
                let receivingApplicationTd = charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.CHARGE && charge.RECEIVING_CHARGE.CHARGE.APPLICATION ? charge.RECEIVING_CHARGE.CHARGE.APPLICATION.CODE : '';
                if (charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.CHARGE && charge.RECEIVING_CHARGE.CHARGE.EQUIPMENT) receivingApplicationTd += `(${charge.RECEIVING_CHARGE.CHARGE.EQUIPMENT.CODE})`;
                chargeTableRows += `
                <tr>
                    <td>${charge.CHARGE_NAME && charge.CHARGE_NAME.TYPE ? charge.CHARGE_NAME.TYPE.NAME : ''}</td>
                    <td>${charge.CHARGE_NAME ? charge.CHARGE_NAME.CODE : ''}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.CHARGE && charge.PAYMENT_CHARGE.CHARGE.CHARGE_NAME_EXHIBITION ? charge.PAYMENT_CHARGE.CHARGE.CHARGE_NAME_EXHIBITION.CODE : ''}</td>
                    <td>${paymentApplicationTd}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.CURRENCY ? charge.PAYMENT_CHARGE.CURRENCY.CODE : ''}</td>
                    <td>${charge.PAYMENT_CHARGE ? charge.PAYMENT_CHARGE.AMOUNT : ''}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.VALUE_MIN != null ? $filter('number')(charge.PAYMENT_CHARGE.VALUE_MIN, 2) : ''}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.VALUE_UNITARY != null ? $filter('number')(charge.PAYMENT_CHARGE.VALUE_UNITARY, 2) : ''}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.TOTAL != null ? $filter('number')(charge.PAYMENT_CHARGE.TOTAL, 2) : ''}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.MODALITY ? charge.PAYMENT_CHARGE.MODALITY.NAME : ''}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.TRANSACTION ? charge.PAYMENT_CHARGE.TRANSACTION.NAME : ''} ${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.TRANSACTION_CONTRACT && charge.PAYMENT_CHARGE.TRANSACTION != charge.PAYMENT_CHARGE.TRANSACTION_CONTRACT ? '<i class="fa fa-info-circle text-cyano tooltipModal" aria-hidden="true" tooltip-placement="auto top" uib-tooltip="Transação Contrato: ' + (charge.PAYMENT_CHARGE.TRANSACTION_CONTRACT ? charge.PAYMENT_CHARGE.TRANSACTION_CONTRACT.NAME : '') + '" tooltip-append-to-body="true"></i>' : ''}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.HOLDER ? charge.PAYMENT_CHARGE.HOLDER.NAME : ''} ${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.TRANSACTION_CONTRACT && charge.PAYMENT_CHARGE.TRANSACTION != charge.PAYMENT_CHARGE.TRANSACTION_CONTRACT ? '<i class="fa fa-info-circle text-cyano tooltipModal" aria-hidden="true" tooltip-placement="auto top" uib-tooltip="Pessoa Contrato: ' + (charge.PAYMENT_CHARGE.HOLDER_CONTRACT ? charge.PAYMENT_CHARGE.HOLDER_CONTRACT.NAME : '') + '" tooltip-append-to-body="true"></i>' : ''}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.TOTAL_FACTOR != null ? $filter('number')(charge.PAYMENT_CHARGE.TOTAL_FACTOR, 4) : ''} ${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.CONTRACT && charge.PAYMENT_CHARGE.CURRENCY.ID != charge.PAYMENT_CHARGE.CURRENT_CURRENCY.ID ? '<i class="fa fa-info-circle text-cyano tooltipModal" aria-hidden="true" tooltip-placement="auto top" uib-tooltip="Tabela ' + (charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.TABLE ? charge.PAYMENT_CHARGE.TABLE.NAME : '') + ' com fator ' + (charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.FACTOR ? $filter('number')(charge.PAYMENT_CHARGE.FACTOR, 4) : '') + ' e spread ' + (charge.PAYMENT_CHARGE && (charge.PAYMENT_CHARGE.SPREAD || charge.PAYMENT_CHARGE.SPREAD == 0) ? $filter('number')(charge.PAYMENT_CHARGE.SPREAD, 2) : '') + '%" tooltip-append-to-body="true"></i>' : ''}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.TOTAL_CURRENT_CURRENCY != null ? $filter('number')(charge.PAYMENT_CHARGE.TOTAL_CURRENT_CURRENCY, 2) : ''}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.CHARGE_COMPOSITION && charge.PAYMENT_CHARGE.CHARGE_COMPOSITION.length ? StringUtil.getCONCAT(charge.PAYMENT_CHARGE.CHARGE_COMPOSITION, null, 'CODE') : ''}</td>
                    <td>${charge.PAYMENT_CHARGE && charge.PAYMENT_CHARGE.CONTRACT && charge.PAYMENT_CHARGE.CONTRACT.CONTRACT_NUMBER ? charge.PAYMENT_CHARGE.CONTRACT.CONTRACT_NUMBER : ''}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.CHARGE && charge.RECEIVING_CHARGE.CHARGE.CHARGE_NAME_EXHIBITION ? charge.RECEIVING_CHARGE.CHARGE.CHARGE_NAME_EXHIBITION.CODE : ''}</td>
                    <td>${receivingApplicationTd}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.CURRENCY ? charge.RECEIVING_CHARGE.CURRENCY.CODE : ''}</td>
                    <td>${charge.RECEIVING_CHARGE ? charge.RECEIVING_CHARGE.AMOUNT : ''}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.VALUE_MIN != null ? $filter('number')(charge.RECEIVING_CHARGE.VALUE_MIN, 2) : ''}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.VALUE_UNITARY != null ? $filter('number')(charge.RECEIVING_CHARGE.VALUE_UNITARY, 2) : ''}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.TOTAL != null ? $filter('number')(charge.RECEIVING_CHARGE.TOTAL, 2) : ''}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.MODALITY ? charge.RECEIVING_CHARGE.MODALITY.NAME : ''}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.TRANSACTION ? charge.RECEIVING_CHARGE.TRANSACTION.NAME : ''} ${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.TRANSACTION_CONTRACT && charge.RECEIVING_CHARGE.TRANSACTION != charge.RECEIVING_CHARGE.TRANSACTION_CONTRACT ? '<i class="fa fa-info-circle text-cyano tooltipModal" aria-hidden="true" tooltip-placement="auto top" uib-tooltip="Transação contrato:  ' + (charge.RECEIVING_CHARGE.TRANSACTION_CONTRACT ? charge.RECEIVING_CHARGE.TRANSACTION_CONTRACT.NAME : '') + '" tooltip-append-to-body="true"></i>' : ''}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.HOLDER ? charge.RECEIVING_CHARGE.HOLDER.NAME : ''} ${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.TRANSACTION_CONTRACT && charge.RECEIVING_CHARGE.TRANSACTION != charge.RECEIVING_CHARGE.TRANSACTION_CONTRACT ? '<i class="fa fa-info-circle text-cyano tooltipModal" aria-hidden="true" tooltip-placement="auto top" uib-tooltip="Pessoa contrato: ' + (charge.RECEIVING_CHARGE.HOLDER_CONTRACT ? charge.RECEIVING_CHARGE.HOLDER_CONTRACT.NAME : '') + '" tooltip-append-to-body="true"></i>' : ''}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.FACTOR != null ? $filter('number')(charge.RECEIVING_CHARGE.TOTAL_FACTOR, 4) : ''} ${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.CONTRACT && charge.RECEIVING_CHARGE.CURRENCY.ID != charge.RECEIVING_CHARGE.CURRENT_CURRENCY.ID ? '<i class="fa fa-info-circle text-cyano tooltipModal" aria-hidden="true" tooltip-placement="auto top" uib-tooltip="Tabela ' + (charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.TABLE ? charge.RECEIVING_CHARGE.TABLE.NAME : '') + ' com fator ' + (charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.FACTOR ? $filter('number')(charge.RECEIVING_CHARGE.FACTOR, 4) : '') + ' e spread ' + (charge.RECEIVING_CHARGE && (charge.RECEIVING_CHARGE.SPREAD || charge.RECEIVING_CHARGE.SPREAD == 0) ? $filter('number')(charge.RECEIVING_CHARGE.SPREAD, 2) : '') + '%" tooltip-append-to-body="true"></i>' : ''}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.TOTAL_CURRENT_CURRENCY != null ? $filter('number')(charge.RECEIVING_CHARGE.TOTAL_CURRENT_CURRENCY, 2) : ''}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.CHARGE_COMPOSITION && charge.RECEIVING_CHARGE.CHARGE_COMPOSITION.length ? StringUtil.getCONCAT(charge.RECEIVING_CHARGE.CHARGE_COMPOSITION, null, 'CODE') : ''}</td>
                    <td>${charge.RECEIVING_CHARGE && charge.RECEIVING_CHARGE.CONTRACT && charge.RECEIVING_CHARGE.CONTRACT.CONTRACT_NUMBER ? charge.RECEIVING_CHARGE.CONTRACT.CONTRACT_NUMBER : ''}</td>
                    <td>${charge.EXCHANGE_RATE_INDEX != null ? $filter('number')(charge.EXCHANGE_RATE_INDEX, 2) + '%' : ''}</td>
                    <td>${charge.CHARGE_VALUE != null ? $filter('number')(charge.CHARGE_VALUE, 2) : ''}</td>
                    <td>${charge.EXCHANGE_BALANCE != null ? $filter('number')(charge.EXCHANGE_BALANCE, 2) : ''}</td>
                    <td>${charge.CHARGE_VALUE_CURRENT_CURRENCY != null ? $filter('number')(charge.CHARGE_VALUE_CURRENT_CURRENCY, 2) : ''}</td>
                </tr>`;
            }
        }

        const chargeTable = `
            <table class="table light-gray-border table-bordered m-b-none">
                <thead>
                    <tr>
                        <th rowspan="3" class="centered v-middle">${$filter('translate')('GENERAL.TYPE')}</th>    
                        <th rowspan="3" class="centered v-middle">${$filter('translate')('BASIC_DATA.CHARGE')}</th>
                        <th colspan="14" class="centered">${$filter('translate')('PRODUCT.BUYING')}</th>
                        <th colspan="14" class="centered">${$filter('translate')('PRODUCT.SELLING')}</th>
                        <th rowspan="3" class="centered v-middle">IDC(%)</th>
                        <th rowspan="3" class="centered v-middle">${$filter('translate')('FINANCIAL.CHARGE_DIFFERENCE')}</th>
                        <th rowspan="3" class="centered v-middle">${$filter('translate')('FINANCIAL.CHARGE_EXCHANGE_DIFFERENCE')}</th>
                        <th rowspan="3" class="centered v-middle">${$filter('translate')('FINANCIAL.CHARGE_DIFFERENCE_BRL')}</th>
                    <tr>
                    <tr>
                        <th class="centered">${$filter('translate')('BASIC_DATA.DISPLAY_CHARGE')}</th>
                        <th class="centered">${$filter('translate')('FINANCIAL.CHARGE_BASIS')}</th>
                        <th class="centered">${$filter('translate')('GENERAL.CURRENCY')}</th>
                        <th class="centered">${$filter('translate')('GENERAL.QUANTITY')}</th>
                        <th class="centered">${$filter('translate')('BASIC_DATA.MINIMUM')}</th>
                        <th class="centered">${$filter('translate')('GENERAL.UN_NUMBER')}</th>
                        <th class="centered">${$filter('translate')('GENERAL.TOTAL')}</th>
                        <th class="centered">${$filter('translate')('FINANCIAL.CHARGE_PAYMENT_METHOD')}</th>
                        <th class="centered">${$filter('translate')('GENERAL.HOLDER_TYPE')}</th>
                        <th class="centered">${$filter('translate')('GENERAL.HOLDER')}</th>
                        <th class="centered">${$filter('translate')('BASIC_DATA.EXCHANGE_RATE')}</th>
                        <th class="centered">${$filter('translate')('FINANCIAL.TOTAL_BRL')}</th>
                        <th class="centered">${$filter('translate')('GENERAL.COMPOSITION')}</th>
                        <th class="centered">${$filter('translate')('OPERATIONAL.FINANCIAL_CONTRACT')}</th>
                        <th class="centered">${$filter('translate')('BASIC_DATA.DISPLAY_CHARGE')}</th>
                        <th class="centered">${$filter('translate')('FINANCIAL.CHARGE_BASIS')}</th>
                        <th class="centered">${$filter('translate')('GENERAL.CURRENCY')}</th>
                        <th class="centered">${$filter('translate')('GENERAL.QUANTITY')}</th>
                        <th class="centered">${$filter('translate')('BASIC_DATA.MINIMUM')}</th>
                        <th class="centered">${$filter('translate')('GENERAL.UN_NUMBER')}</th>
                        <th class="centered">${$filter('translate')('GENERAL.TOTAL')}</th>
                        <th class="centered">${$filter('translate')('FINANCIAL.CHARGE_PAYMENT_METHOD')}</th>
                        <th class="centered">${$filter('translate')('GENERAL.HOLDER_TYPE')}</th>
                        <th class="centered">${$filter('translate')('GENERAL.HOLDER')}</th>
                        <th class="centered">${$filter('translate')('BASIC_DATA.EXCHANGE_RATE')}</th>
                        <th class="centered">${$filter('translate')('FINANCIAL.TOTAL_BRL')}</th>
                        <th class="centered">${$filter('translate')('GENERAL.COMPOSITION')}</th>
                        <th class="centered">${$filter('translate')('OPERATIONAL.FINANCIAL_CONTRACT')}</th>
                    </tr>
                </thead>
                <tbody>${chargeTableRows}</tbody>
            </table>
        `;
        return chargeTable;
    }

    private static buildEntranceTable($filter: ng.FilterFactory, typeCargo: ISelectorModel, indicators: IOfferOptionIndicators) {
        let contributionLabel = "";
        let contributionCode = "";
        let contributionTooltip = "";
        let contributionMask = 0;
        if (typeCargo.ID == ECargoTypeId.FCL) {
            contributionLabel = $filter('translate')('BASIC_DATA.TOTAL_TEU');
            contributionCode = 'TOTAL_TEUS';
            contributionTooltip = $filter('translate')('PRODUCT.QTY_TYPE_EQ_COPY_OFFER');
        }
        else if (typeCargo.ID == ECargoTypeId.AIR) {
            contributionLabel = $filter('translate')('GENERAL.TOTAL_TONS');
            contributionCode = 'TOTAL_WEIGHT';
            contributionTooltip = $filter('translate')('PRODUCT.TAXED_WEIGHT_FROM_WIZARD');
            contributionMask = 4;
        }
        else if (typeCargo.ID == ECargoTypeId.ROAD) {
            contributionLabel = $filter('translate')('PRODUCT.TOTAL_VEHICLES');
            contributionCode = "TOTAL_VEHICLES";
            contributionTooltip = $filter('translate')('PRODUCT.VEHICLES_COPY_FROM_WIZARD');
        }
        else if (typeCargo.ID == ECargoTypeId.BREAK_BULK || typeCargo.ID == ECargoTypeId.LCL || typeCargo.ID == ECargoTypeId.RO_RO) {
            contributionLabel = $filter('translate')('GENERAL.FILE_BY_OPTION');
            contributionCode = "TOTAL_FILE";
            contributionTooltip = $filter('translate')('GENERAL.FIXED_BY_OPTION');
        }
        const entranceTable = `
            <table class="table light-gray-border table-bordered m-b-none">
                <thead>
                    <tr>
                        <th>${$filter('translate')('GENERAL.PARTICULARS')} </th>
                        <th>${$filter('translate')('FINANCIAL.VALUE')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>${$filter('translate')('FINANCIAL.TOTAL_COST_BRL')}</td>
                        <td>
                            ${indicators && indicators.TOTAL_PAYMENT != null ? $filter('number')(indicators.TOTAL_PAYMENT, 2) : ''}
                            <i id="tooltipTotalPayment" class="fa fa-info-circle text-cyano" aria-hidden="true"
                                tooltip-placement="auto top"
                                uib-tooltip="${$filter('translate')('PRODUCT.ADD_PURCHASE_TAXES')}"
                                tooltip-append-to-body="true">
                            </i>
                        </td>
                    </tr>
                    <tr>
                        <td>${$filter('translate')('FINANCIAL.TOTAL_REVENUE_BRL')}</td>
                        <td>
                            ${indicators && indicators.TOTAL_RECEIVING != null ? $filter('number')(indicators.TOTAL_RECEIVING, 2) : ''}
                            <i id="tooltipTotalReceiving" class="fa fa-info-circle text-cyano" aria-hidden="true"
                                tooltip-placement="auto top"
                                uib-tooltip="${$filter('translate')('PRODUCT.ADD_SELL_TAXES')}"
                                tooltip-append-to-body="true">
                            </i>
                        </td>
                    </tr>
                    <tr>
                        <td>${$filter('translate')('FINANCIAL.TOTAL_PROFIT_SHARE_AGENT_BRL')}</td>
                        <td>
                            ${indicators && indicators.TOTAL_PROFIT_SHARE != null ? $filter('number')(indicators.TOTAL_PROFIT_SHARE, 2) : ''}
                            <i id="tooltipTotalProfitShare" class="fa fa-info-circle text-cyano" aria-hidden="true"
                                tooltip-placement="auto top"
                                uib-tooltip="${$filter('translate')('PRODUCT.ADD_COMISSION_TAXES')}"
                                tooltip-append-to-body="true">
                            </i>
                        </td>
                    </tr>
                    <tr>
                        <td>${$filter('translate')(contributionLabel)}</td>
                        <td>
                            ${indicators[contributionCode] != null ? $filter('number')(indicators[contributionCode], contributionMask) : ''}
                            <i id="tooltipContribution" class="fa fa-info-circle text-cyano" aria-hidden="true"
                                tooltip-placement="auto top"
                                uib-tooltip="${$filter('translate')(contributionTooltip)}"
                                tooltip-append-to-body="true">
                            </i>
                        </td>
                    </tr>
                </tbody>
            </table>
        `;
        return entranceTable;
    }

    private static buildIndicatorTable($filter: ng.FilterFactory, typeCargo: ISelectorModel, productId: string, currentCurrency: ISelectorModel, indicators: IOfferOptionIndicators) {
        let contributionLabel = "";
        let contributionInitials = "";
        let contributionRecipe = "";
        let contributionCode = "";
        let contributionMask = 2;
        let wdLabel = "";
        let wdInitials = "";
        let wdRecipe = "";
        let wdCode = "";
        if ((productId == EProductId.MARITIME_EXPORT || productId == EProductId.MARITIME_IMPORT) && typeCargo.ID == ECargoTypeId.FCL) {
            contributionLabel = "FINANCIAL.CT_TEU_BRL";
            contributionInitials = "FINANCIAL.CT_TEU_BRL";
            contributionRecipe = "FINANCIAL.CT_TEU_FORMULA";
            contributionCode = "EGP_TOTAL_TEUS";

            wdLabel = "FINANCIAL.CT_TEU_WITHOUT_DOCS_BRL";
            wdInitials = "FINANCIAL.CT_TEU_WITHOUT_DOCS_BRL";
            wdRecipe = "FINANCIAL.CT_TEU_WD_FORMULA";
            wdCode = "CTWD";
        }
        else if ((productId == EProductId.MARITIME_EXPORT || productId == EProductId.MARITIME_IMPORT) && typeCargo.ID == ECargoTypeId.LCL) {
            contributionLabel = "PRODUCT.CONTRIBUTION_BY_FILE";
            contributionInitials = "FINANCIAL.CT_FILE_BRL";
            contributionRecipe = "PRODUCT.GP_FILE";
            contributionCode = "EGP_TOTAL_FILE";

            wdLabel = "FINANCIAL.CT_FILE_WITHOUT_DOCS";
            wdInitials = "OPERATIONAL.FILE_NUMBER";
            wdRecipe = "PRODUCT.CT_WITHOUT_DOC_FILE";
            wdCode = "CTWD_FILE";
        }
        else if ((productId == EProductId.AIR_EXPORT || productId == EProductId.AIR_IMPORT) && typeCargo.ID == ECargoTypeId.AIR) {
            contributionLabel = "PRODUCT.CONTRIBUTION_BY_TON";
            contributionInitials = "PRODUCT.CT_TON";
            contributionRecipe = "PRODUCT.GP_TON";
            contributionCode = "EGP_TOTAL_WEIGHT";

            wdLabel = "PRODUCT.CT_WD_TON";
            wdInitials = "GENERAL.TON";
            wdRecipe = "PRODUCT.CT_WITHOUT_DOCTON";
            wdCode = "CTWD_WEIGHT";
        }
        else if ((productId == EProductId.ROAD_EXPORT || productId == EProductId.ROAD_IMPORT) && typeCargo.ID == ECargoTypeId.ROAD) {
            contributionLabel = "PRODUCT.CONTRIBUTION_VEHICLE";
            contributionRecipe = "PRODUCT.CT_VEHICLE";
            contributionInitials = "C/V";
            contributionCode = "EGP_TOTAL_VEHICLES";

            wdLabel = "PRODUCT.CT_WD_BY_VEHICLE";
            wdInitials = "GENERAL.VEHICLE";
            wdRecipe = "PRODUCT.CT_WITHOUT_DOCVEHICLE";
            wdCode = "CTWD_VEHICLES";
        }
        else if (typeCargo.ID == ECargoTypeId.BREAK_BULK || typeCargo.ID == ECargoTypeId.RO_RO) {
            contributionLabel = "PRODUCT.CONTRIBUTION_BY_FILE";
            contributionInitials = "C/F";
            contributionRecipe = "PRODUCT.GP_FILE";
            contributionCode = "EGP_TOTAL_FILE";

            wdLabel = "FINANCIAL.CT_FILE_WITHOUT_DOCS";
            wdInitials = "OPERATIONAL.FILE_NUMBER";
            wdRecipe = "PRODUCT.CT_WITHOUT_DOC_FILE";
            wdCode = "CTWD_FILE";
        }
        const indicatorTable = `
            <table class="table light-gray-border table-bordered m-b-none">
                <thead>
                    <tr>
                        <th>${$filter('translate')('GENERAL.INDICATORS')}</th>
                        <th>${$filter('translate')('FINANCIAL.FORMULA')}</th>
                        <th>${$filter('translate')('GENERAL.EXAMPLE')}</th>
                        <th>${$filter('translate')('GENERAL.CURRENCY')}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>${$filter('translate')('FINANCIAL.ESTIMATED_GROSS_PROFIT')}</td>
                        <td>${$filter('translate')('PRODUCT.REC_PAY_COM')}</td>
                        <td>${indicators && indicators.ESTIMATED_GROSS_PROFIT != null ? $filter('number')(indicators.ESTIMATED_GROSS_PROFIT, 2) : ''}</td>
                        <td>${currentCurrency ? currentCurrency.CODE : ''}</td>
                    </tr>
                    <tr>
                        <td>${$filter('translate')(contributionLabel)}</td>
                        <td>${$filter('translate')(contributionRecipe)}</td>
                        <td>${indicators[contributionCode] != null ? $filter('number')(indicators[contributionCode], contributionMask) : ''}</td>
                        <td>${currentCurrency ? currentCurrency.CODE : ''}</td>
                    </tr>
                    <tr>
                        <td>${$filter('translate')(wdLabel)}</td>
                        <td>${$filter('translate')(wdRecipe)}</td>
                        <td>${indicators[wdCode] != null ? $filter('number')(indicators[wdCode], contributionMask) : ''}</td>
                        <td>${currentCurrency ? currentCurrency.CODE : ''}</td>
                    </tr>
                </tbody>
            </table>
        `;
        return indicatorTable;
    }
}
'use strict';

import { ngStorage } from 'ngstorage';
import { IMonacoScope, IMonacoConfig } from '../common/MonacoInterface';
import { TransitionService, StateService, TargetState, Transition, Rejection, StateObject } from '@uirouter/angularjs';
import { HandleError } from '../common/util/HandleError';
import { IHttpService, IHttpResponse } from 'angular';

export interface ISessionParameter {
    param: object;
    data?: object;
}

interface IResponse {
    data: {
        data: object;
    }
}

interface IGoogleAuthResponse {
    client_id: string;
    credential: string;
}

export interface ISessionService {
    getToken: () => string;
    isExpired: () => boolean;
    isAuthenticated: () => boolean;
    startSession: (token: string, returnToFatturo?: boolean) => Promise<void>;
    refreshSession: (token: string) => Promise<StateObject>;
    saveSessionOnStorage: () => void;
    deleteSession: () => Promise<void>;
    redirectToLogin: () => Promise<StateObject>;
    redirectToError: (error: any, message?: string) => Promise<StateObject>;
    redirectToDenied: (reason?: string) => Promise<StateObject>;
    redirectToApp: () => Promise<StateObject>;
    logout: () => Promise<StateObject>;
    openTab: (route: string, param?: object, data?: object) => void;
    openTabByValidity: (endpoint: string, route: string, param?: object, data?: object) => void;
    openRoute: (route: string, param?: object, data?: object) => void;
    getParamFromRoute: (route: string) => object;
    redirectToFatturo: () => void;
    handleGoogleLogin: (response: IGoogleAuthResponse) => Promise<void>;
}

export class SessionService2 {
    public static $inject: string[] = ['$injector'];
    private $injector: ng.Injectable<any>;
    private config: IMonacoConfig;
    private $window: ng.IWindowService;
    private $rootScope: IMonacoScope;
    private $q: ng.IQService;
    private $sessionStorage: ngStorage.StorageService;
    private $transitions: TransitionService;
    private $state: StateService;
    private jwtHelper: ng.jwt.IJwtHelper;
    private permStore: ng.permission.PermissionStore;
    private roleStore: ng.permission.RoleStore;
    private token: string;

    constructor($injector: ng.Injectable<any>) {
        this.$injector = $injector;
        this.config = $injector.get('config');
        this.$window = $injector.get('$window');
        this.$rootScope = $injector.get('$rootScope');
        this.$q = $injector.get('$q');
        this.$sessionStorage = $injector.get('$sessionStorage');
        this.$transitions = $injector.get('$transitions');
        this.$state = $injector.get('$state');
        this.jwtHelper = $injector.get('jwtHelper');
        this.permStore = $injector.get('PermPermissionStore');
        this.roleStore = $injector.get('PermRoleStore');
        this.token = null;

        this.$transitions.onBefore(this.authCriteria(), this.authTransitionHook, { priority: 10 });

        this.$rootScope.$on('unauthRequest', this.handleUnauthRequest.bind(this));

        this.initSession().catch((ex) => {
            HandleError.exception(ex);
        });
    }

    private async initSession(): Promise<void> {
        try {
            if (this.$sessionStorage.token) {
                this.token = this.$sessionStorage.token;
                if (this.isAuthenticated()) await this.refreshSession(this.token);
                else await this.redirectToLogin();
            }

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    private authCriteria(): any {
        return {
            to: (state) => state.data && state.data.requiresAuth
        }
    }

    private decodeToken(token: string): boolean {
        try {
            const user = this.jwtHelper.decodeToken(token);
            if (!user) return false;
            this.$rootScope.user = user;
            const userRoles: Array<any> = user['Role'];
            if (!userRoles || userRoles.length === 0) return false;
            for (let i = 0; i < userRoles.length; i++) {
                this.permStore.definePermission(userRoles[i].NAME, () => {
                    return true;
                });
                if (userRoles[i].NAME === 'EXTERNAL') this.$rootScope.user['isExternal'] = true;
            }

            delete user['Role'];
            user['roles'] = this.permStore.getStore();

            return true;

        } catch (ex) {
            HandleError.exception(ex);
            return false;
        }
    }

    private async refreshSession(token: string): Promise<StateObject> {
        try {
            const decoded = this.decodeToken(token);
            if (!decoded) {
                HandleError.exception('Failed to refresh session');
                return this.redirectToLogin();
            }
        } catch (ex) {
            HandleError.exception(ex);
            return this.redirectToLogin();
        }
    }

    private authTransitionHook(transition: Transition): boolean | TargetState {
        try {
            const SessionService = transition.injector().get('SessionService');
            const config = transition.injector().get('config');
            const $state: StateService = transition.router.stateService;

            if (!SessionService.getToken()) return $state.target(config.loginState, undefined, { location: true });
            if (SessionService.isExpired()) return $state.target(config.loginState, { expired: true }, { location: true });

        } catch (ex) {
            HandleError.exception(ex);
            return false;
        }
    }

    private async handleUnauthRequest(event: ng.IAngularEvent, response: any) {
        try {
            event.stopPropagation();
            await this.$state.go('app.denied', { reason: (response.data && response.data.message) ? response.data.message : null }, { reload: false });

        } catch (ex) {
            if (ex instanceof Rejection) {
                if (ex.type === 2 || ex.type === 3) return;
                else HandleError.exception(ex.message);
            } else HandleError.exception(ex);
        }
    }

    public isExpired(): boolean {
        return this.jwtHelper.isTokenExpired(this.token);
    }

    public isAuthenticated(): boolean {
        if (this.token && !this.jwtHelper.isTokenExpired(this.token)) return true;
        return false;
    }

    public async startSession(token: string, returnToFatturo?: boolean): Promise<StateObject> {
        try {
            this.token = token;
            if (this.isAuthenticated()) {
                const decoded = this.decodeToken(token);
                if (decoded) {
                    this.saveSessionOnStorage();

                    if (returnToFatturo) {
                        this.redirectToFatturo();
                        return null;
                    }

                    return this.redirectToApp();
                }
            }
            HandleError.exception('Failed to start session');
            return this.redirectToLogin();

        } catch (ex) {
            HandleError.exception(ex);
            return this.redirectToLogin();
        }
    }

    public saveSessionOnStorage(): void {
        try {
            this.$sessionStorage.token = this.token;

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    public async deleteSession(): Promise<void> {
        try {
            delete this.$sessionStorage.token;
            this.token = null;
            this.permStore.clearStore();
            this.roleStore.clearStore();
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    public openTab(route: string, param?: object, data?: object): void {
        try {
            if (!route) return;

            if (route.length > 0) {
                const split: string = route.split('.').join('/');
                const prefix: string = "/#" + this.config.hashPrefix;
                const sufix: string = (route[0] === "/") ? split : "/" + split;

                const url = prefix + sufix;

                if (param || data) {
                    const sessionParameter = <ISessionParameter>{
                        param: (param) ? param : null,
                        data: (data) ? data : null
                    };

                    sessionStorage.setItem(route, JSON.stringify(sessionParameter));

                    window.open(url, "_blank");

                    sessionStorage.removeItem(route);
                }
                else window.open(url, "_blank");
            }
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    public async openTabByValidity(endpoint: string, route: string, param?: object, data?: object): Promise<void> {
        try {
            if (!route) return;

            if (route.length > 0) {

                const $http: IHttpService = this.$injector.get('$http');
                const response: IResponse = await $http({
                    method: 'GET',
                    url: endpoint,
                    cache: false,
                    timeout: 30000
                });

                if (!response.data.data) route += 'History';

                this.openTab(route, param, data);
            }
        } catch (error) {
            HandleError.exception(error);
        }
    }

    public openRoute(route: string, param?: object, data?: object): void {
        try {
            if (!route) return;

            if (route.length > 0) {
                const split: string = route.split('.').join('/');
                const prefix: string = "/#" + this.config.hashPrefix;
                const sufix: string = (route[0] === "/") ? split : "/" + split;

                const url = prefix + sufix;

                if (param || data) {
                    const sessionParameter = <ISessionParameter>{
                        param: (param) ? param : null,
                        data: (data) ? data : null
                    };

                    sessionStorage.setItem(route, JSON.stringify(sessionParameter));

                    window.open(url, "_self");

                } else window.open(url, "_self");
            }
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    public getParamFromRoute(route: string): object {
        try {
            if (!route) return;

            const item = sessionStorage.getItem(route);

            sessionStorage.removeItem(route);

            if (!item) null;

            return JSON.parse(item);
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    public async redirectToLogin(): Promise<StateObject> {
        try {
            await this.deleteSession();
            return this.$state.go(this.config.loginState, {}, { reload: true });

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    public async redirectToError(error: any, message?: string): Promise<StateObject> {
        try {
            if (error.status && error.status === 401) return;

            const errorData = HandleError.buildErrorMessage(error);
            const isHttpResponse = (error instanceof Object && error.hasOwnProperty('config') && error.hasOwnProperty('headers') && error.hasOwnProperty('status'));
            const requestInfo = (isHttpResponse) ? { url: error.config.url, method: error.config.method, data: JSON.stringify(error.config.data), timeout: error.config.timeout, status: error.status } : null;
            const originData = {
                label: this.$state.current['ncyBreadcrumbLabel'],
                url: this.$state.current['ncyBreadcrumbLink'],
                state: this.$state.current.name,
                stateParams: this.$state.current.params,
                request: requestInfo
            };
            return this.$state.go(this.config.errorState, { origin: originData, message: message, error: (errorData) ? errorData.msg : null }, { reload: false })

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    public redirectToFatturo(): void {
        try {
            window.location.href = this.config.fatturoUrl + "home?userKey=" + this.token;
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    public async redirectToApp(): Promise<StateObject> {
        try {
            if (this.$rootScope.user.isExternal) return this.$state.go(this.config.externalState, {}, { reload: true });
            else return this.$state.go(this.config.appState, {}, { reload: true });

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    public async redirectToDenied(reason?: string): Promise<void> {
        try {
            await this.$state.go('app.denied', { reason: reason }, { reload: false });

        } catch (ex) {
            if (ex instanceof Rejection) {
                if (ex.type === 2) return;
                else HandleError.exception(ex.message);
            } else HandleError.exception(ex);
        }
    }

    public async handleGoogleLogin(response: IGoogleAuthResponse): Promise<void> {
        const $timeout: ng.ITimeoutService = this.$injector.get('$timeout');
        $timeout(async () => {
            try {
                if (!response) throw new Error('google response is null');

                if (!response.credential) throw new Error('Google login failed, payload is null');

                const id_token = response.credential;

                const $http: IHttpService = this.$injector.get('$http');

                const rc = await $http({
                    method: 'POST',
                    url: this.config.helperUrl + '/helper/auth/google',
                    data: { token: id_token, timeout: 30000 },
                    cache: false,
                    timeout: 30000
                });

                this.handleLoginResponse(rc);

            } catch (ex) {
                if (ex instanceof Object && ex.hasOwnProperty('status') && ex.hasOwnProperty('data')) this.handleLoginResponse(ex);
                else HandleError.exception(ex);
            }
        });
    }

    public async logout(): Promise<StateObject> {
        try {
            return this.redirectToLogin();

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    public getToken(): string {
        return this.token;
    }

    private handleLoginResponse(response: IHttpResponse<any>) {
        const blockUI: ng.blockUI.BlockUIService = this.$injector.get('blockUI');
        try {
            if (response.status === 200 && response.data['data']) {
                const token = response.data['data'];

                blockUI.stop();
                return this.startSession(token);

            } else {
                if (response.status === -1) HandleError.exception('Timeout alcançado, tente novamente');
                else {
                    let errorMessage = 'Ocorreu um erro, por favor tente novamente';
                    const data = response.data['data'];
                    const reason = (response.data['reason']) ? response.data['reason'] : null;

                    if (reason) {
                        switch (reason) {
                            case 'User not found':
                                errorMessage = 'Email/Senha Incorretos';
                                break;
                            case 'Email/Password incorrect':
                                errorMessage = 'Email/Senha incorretos';
                                break;
                            case 'Account is locked':
                                errorMessage = 'Conta bloqueada. Entre em contato com o administrador.';
                                break;
                            case 'Account is blocked':
                                errorMessage = 'Conta bloqueada. Entre em contato com o administrador.';
                                break;
                            case 'Resource not found':
                                errorMessage = 'Email/Senha Incorretos';
                                break;
                            case 'Your email must be from allog.com.br':
                                errorMessage = 'Email inválido'
                                break;
                            case 'Failed':
                                errorMessage = 'Ocorreu um erro, por favor tente novamente';
                                break;
                            case 'Exception':
                                errorMessage = 'Ocorreu uma exceção, por favor tente novamente';
                                break;
                            default:
                                errorMessage = reason
                                break;
                        }
                        HandleError.exception(errorMessage);
                    } else HandleError.exception(data);
                }

                this.$rootScope.$apply(() => {
                    blockUI.stop();
                });
            }

        } catch (ex) {
            this.$rootScope.$apply(() => {
                blockUI.stop();
            });
            HandleError.exception(ex);
        }
    }
}
import * as angular from "angular";
import * as moment from "moment";
import * as uiGrid from "ui-grid";
import { HandleError } from '../common/util/HandleError';
import { IMonacoScope, IMonacoConfig } from "../common/MonacoInterface";
import { IModalService } from "./ModalService";
import { IRestService } from "./RestService";
import { IGridSelectedTemplate } from "../common/interface/IGridSelectedTemplate";
import { IGridDataReturn } from "../common/interface/IGridDataReturn";
import { IDataFilterRequest } from "../common/interface/IDataFilterRequest";
import { SelectorModel } from "../common/model/SelectorModel";
import { ISessionService, ISessionParameter } from "@services/SessionService";
import { IGridSpreadsheet } from "@models/interface/product/GridSpreadsheet";
import { INotificationService } from "./NotificationService";
import { PermissionService } from "@appServices/PermissionService";

interface IGridServiceOptions extends uiGrid.IGridOptions {
    enableProfile?: boolean,
    gridProfileList?: Array<any>,
    profile?: {
        gridSelectedProfile: SelectorModel;
    },
    enableBackgroundUpdate?: boolean,
    gridLastUpdate?: Date,
    gridMenuTemplate?: string,
    gridMenuTitleFilter?: Function,
    gridMenuCustomItems?: Array<uiGrid.IMenuItem>
    exporterMenuAllData?: boolean,
    exporterMenuVisibleData?: boolean,
    headerHeight?: number,
    gridFooterHeight?: number,
    saveGrouping: boolean,
    paginateLimit?: boolean,
    enablePaginationNavigation?: boolean,
    enablePaginationTotal?: boolean,
    enableHAWB?: boolean
    valueHAWB: number
}

export interface IMonacoColumnDef extends uiGrid.IColumnDef {
    searchProps?: string[];
    excelDef?: IMonacoExcelColumnDef;
    partialCriteria?: boolean;
    arrayProperty?: string;
}

interface IValidFilterPatterns {
    date: string,
    number: string,
    word: string,
    special: string,
    separator: string
}

interface IGridServiceFilterPatterns {
    date: RegExp,
    number: RegExp,
    word: RegExp,
    special: RegExp,
    separator: string
}

export interface ICellEditCallback {
    function: Function,
    params?: any[]
}

export type IEditCallback = (entity: any, colName: string, newValue: any) => Promise<void>;

export interface ICellAfterEditParams {
    rowEntity: any,
    colDef: uiGrid.IColumnDefOf<any>,
    newValue: any,
    oldValue: any,
    params?: any[]
}

const enum OperationType {
    DEFAULT = 1,
    INTERNAL,
    EXTERNAL
}
export interface IGridConfig<T = any> {
    gridName: string,
    data: {
        gridRoute?: string,
        customData?: T[]
    },
    columns: {
        gridRoute?: string,
        customColumnsList?: (string | object)[],
        customBuilder?: {
            function: (list: (string | object)[]) => IMonacoColumnDef[],
            context?: any
        },
        customColumnsDef?: IMonacoColumnDef[],
    }
    enableProfile: boolean,
    filterType: OperationType,
    sortingType: OperationType,
    paginationType: OperationType,
    timeout: number
}

export interface IMonacoExcelColumnDef {
    typeExcel?: string;
    sum?: boolean;
}

export interface ICustomButton {
    fn: () => void;
    disabled: () => boolean;
    tooltipLabel: string;
    icon: string;
}

export interface IGridServiceScope extends ng.IScope {
    isEdit: boolean;
    getGridHeight: () => any;
    getCustomButton: () => ICustomButton;
    clearFixedRow: () => void;
    setFixedRow: (_id: string) => void;
    gridArrayCellTemplate: (originalValue: any[], propertyName: string) => string;
    gridAddProfileModal: (renameSelectedProfile?: boolean, copySelectedProfile?: boolean) => Promise<void>;
    gridViewFilter: () => Promise<void>;
    gridClearFilters: (modalContext) => Promise<void>;
    gridAddProfile: (profileName: string, modalContext) => Promise<void>;
    gridRemoveProfile: (profileName: string) => Promise<void>;
    gridSaveProfile: (profileName: string) => Promise<void>;
    gridLoadProfile: (profile: object) => Promise<void>;
    gridModalTemplateExcel: () => Promise<void>;
    gridHasSelectedRows: () => boolean;
    gridGetExcelRoute: () => boolean;
}

export interface IGridController {
    preGridFilter?: () => Promise<void>;
    postGridFilter?: () => Promise<void>;
}

export interface IMonacoSavedColumn extends uiGrid.saveState.ISavedColumn {
    displayName: string;
    excelColDef: IMonacoExcelColumnDef;
    cellTemplate: string;
    cellFilter: string;
}

export interface IMonacoGridState extends uiGrid.saveState.IGridSavedState {
    IS_DEFAULT?: boolean;
}

export class GridService2<T = any> {
    public orderMenuItems: boolean;
    private window: ng.IWindowService;
    private scope: IGridServiceScope;
    private rootScope: IMonacoScope
    private timeout: ng.ITimeoutService;
    private blockUI: ng.blockUI.BlockUIService;
    private ModalService: IModalService;
    private sessionService: ISessionService
    private gridConstants: uiGrid.IUiGridConstants;
    private gridMenuService: any;
    private gridMenuItems: Array<uiGrid.IMenuItem>;
    private gridApi: uiGrid.IGridApiOf<any>;
    private gridName: string;
    private gridRoute: string;
    private gridHeight: number;
    private gridOptions: IGridServiceOptions;
    private selectableRowsLimit: number;
    private showMessageInformingLimit: boolean;
    private selectionLimitCallback: Function;
    private $timeout: angular.ITimeoutService;
    private notificationService: INotificationService;
    private notifyInfo: Function;
    private gridOptionsReferenceName: string;
    private defaultColumns: Array<uiGrid.IColumnDef>;
    private RestService: IRestService;
    private loaded: ng.IDeferred<boolean>;
    private initialized: boolean;
    private profiledFilter: boolean;
    private backgroundUpdateInterval: number;
    private backgroundUpdateHandler: ng.IPromise<any>;
    private filterChangeHandler: ng.IPromise<any>;
    private updating: boolean;
    private isCopy: boolean;
    private defaultState: IMonacoGridState;
    private currentState: IMonacoGridState;
    private filterPatterns: IGridServiceFilterPatterns;
    private activeFilter: object;
    private lastActiveFilter: object;
    private activeSort: object;
    private gridSelectedRows: T[];
    private requestGridDataTimeout: number;
    private controllerContext: any;
    private $state: ng.ui.IStateService;
    private $filter: ng.IFilterService;
    private uiGridExporterConstants: uiGrid.exporter.IUiGridExporterConstants;
    private $translate;
    private i18nService: any;
    private baseUrl: string;
    private validPatternsUrl: string;
    private sessionParameter: ISessionParameter;
    private restrictiveSort: boolean;
    private showTotalCount: boolean;
    private actionColumns: string[];
    private excelRoute: string;
    private maxQueryCriteria: number;
    private customButton: ICustomButton;
    private customExportData: () => void;
    private permissionService: PermissionService;

    constructor($injector: ng.Injectable<any>, $scope: IGridServiceScope, controllerContext?: any, gridOptionsReference?: string) {
        this.scope = $scope;
        this.rootScope = $injector.get('$rootScope');
        this.timeout = $injector.get('$timeout');
        this.blockUI = $injector.get('blockUI');
        this.ModalService = $injector.get('ModalService');
        this.sessionService = $injector.get('SessionService');
        this.window = $injector.get('$window');
        this.gridConstants = $injector.get('uiGridConstants');
        this.gridMenuService = $injector.get('uiGridGridMenuService');
        this.RestService = $injector.get('RestService');
        this.$state = $injector.get('$state');
        this.$filter = $injector.get('$filter');
        this.$timeout = $injector.get('$timeout');
        this.notificationService = $injector.get('NotificationService');
        this.loaded = $injector.get('$q').defer();
        this.i18nService = $injector.get('i18nService');
        this.uiGridExporterConstants = $injector.get('uiGridExporterConstants');
        this.$translate = $injector.get("$translate");
        this.initialized = false;
        this.profiledFilter = false;
        this.orderMenuItems = true;
        this.gridHeight = 0;
        this.backgroundUpdateInterval = 60000;
        this.backgroundUpdateHandler = null;
        this.filterChangeHandler = null;
        this.updating = false;
        this.defaultState = null;
        this.currentState = null;
        this.filterPatterns = null;
        this.activeFilter = null;
        this.sessionParameter = null;
        this.lastActiveFilter = null;
        this.activeSort = null;
        this.gridSelectedRows = null;
        this.requestGridDataTimeout = 15000;
        this.controllerContext = (controllerContext) ? controllerContext : null;
        const config: IMonacoConfig = $injector.get('config');
        this.baseUrl = config.helperUrl + '/helper';
        this.validPatternsUrl = `${this.baseUrl}/allog/validpatterns`;
        this.initGridOptions();
        this.restrictiveSort = false;
        this.showTotalCount = true;
        this.actionColumns = ['acoes'];
        this.maxQueryCriteria = null;
        this.permissionService = new PermissionService(this.scope, $injector);

        if (gridOptionsReference) {
            if (this.scope[gridOptionsReference]) throw new Error(`Failed to init gridService: gridOptionsReference ${gridOptionsReference} already in use, choose another reference`);
            this.gridOptionsReferenceName = gridOptionsReference;
            this.scope[gridOptionsReference] = this.gridOptions;
        } else {
            this.gridOptionsReferenceName = 'gridOptions';
            this.scope['gridOptions'] = this.gridOptions;
        }

        this.registerScopeFunctions();
    }

    public dateColumnCellFilter(columnName: string): string {
        return `<div class="grid-padding">{{grid.appScope.formatDate(row.entity.${columnName})}}</div>`;
    }

    public get $gridName(): string {
        return this.gridName;
    }

    public get $gridRoute(): string {
        return this.gridRoute;
    }

    public set $gridRoute(value: string) {
        this.gridRoute = value;
    }

    public get $gridHeight(): number {
        return this.gridHeight;
    }

    public get $gridSelectedRows(): T[] {
        return this.gridSelectedRows;
    }

    public set $customExportData(value: () => void) {
        this.customExportData = value;
    }

    public get $gridApi(): uiGrid.IGridApiOf<T> {
        return this.gridApi;
    }

    public get $gridConstants(): uiGrid.IUiGridConstants {
        return this.gridConstants;
    }

    public set $gridHeight(value: number) {
        this.gridHeight = value;
    }

    public $setGridHeight(value: number) {
        this.gridHeight = value;
    }

    public get $gridOptions(): IGridServiceOptions {
        return this.gridOptions;
    }

    public get $activeFilter(): object {
        return this.activeFilter;
    }

    public get $sessionParameter(): ISessionParameter {
        return this.sessionParameter;
    }

    public get $requestGridDataTimeout(): number {
        return this.requestGridDataTimeout;
    }

    public set $requestGridDataTimeout(value: number) {
        this.requestGridDataTimeout = value;
    }

    public setRequestGridDataTimeout(timeout: number): void {
        this.requestGridDataTimeout = timeout;
    }

    public set $controllerContext(controllerContext: any) {
        this.controllerContext = controllerContext;
    }

    public get $baseUrl(): string {
        return this.baseUrl;
    }

    public set $baseUrl(value: string) {
        this.baseUrl = value;
    }

    public get $validPatternsUrl(): string {
        return this.validPatternsUrl;
    }

    public set $validPatternsUrl(value: string) {
        this.validPatternsUrl = value;
    }

    public setSelectable(multi: boolean = false): void {
        this.gridOptions.enableRowSelection = true;
        this.gridOptions.enableRowHeaderSelection = true;
        if (multi) {
            this.gridOptions.multiSelect = true;
            this.gridOptions.enableSelectAll = true;
        }
    }

    /**
     * It limits the amount of rows the user can select when grid multiselection is enabled.
     * @param limit - the amount of rows that can be selectable
     * @param showMessageInformingLimit - Flag to check if should show or not the limitation message
     * @see selectionChanged method.
     */
    public setSelectableRowsLimit(limit: number, showMessageInformingLimit: boolean = false) {
        this.selectableRowsLimit = limit;
        this.showMessageInformingLimit = showMessageInformingLimit;
    }

    /**
     * It executes the callback passed through parameter when the limit of rows selected is reached
     * @param limit - the amount of rows that can be selectable
     * @param selectionLimitCallback - Callback to be executed when limit of rows selected is reached
     * @see selectionChanged method.
     */
    public setSelectableRowsLimitCallback(limit: number, selectionLimitCallback: Function) {
        this.selectableRowsLimit = limit;
        this.selectionLimitCallback = selectionLimitCallback;
    }

    private notifyDataChange() {
        this.gridApi.core.notifyDataChange(this.gridConstants.dataChange.ALL);
    }

    private notifyColumnsChange() {
        this.gridApi.core.notifyDataChange(this.gridConstants.dataChange.COLUMN);
    }

    private registerScopeFunctions(): void {
        this.scope[this.gridOptionsReferenceName].getGridHeight = (addPx?) => {
            return this.getGridHeight(addPx);
        };

        this.scope[this.gridOptionsReferenceName].getCustomButton = (): ICustomButton => {
            return (this.customButton) ? this.customButton : null;
        }

        this.scope[this.gridOptionsReferenceName].gridArrayCellTemplate = (originalValue, propertyName) => {
            return this.gridArrayCellTemplate(originalValue, propertyName);
        }

        this.scope[this.gridOptionsReferenceName].gridAddProfileModal = async (renameSelectedProfile?: boolean, copySelectedProfile?: boolean) => {
            await this.addProfileModal(renameSelectedProfile, copySelectedProfile);
        };

        this.scope[this.gridOptionsReferenceName].gridViewFilter = async () => {
            await this.viewFilterProfileModal();
        };

        this.scope[this.gridOptionsReferenceName].gridClearFilters = async (modalContext) => {
            const approved = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.YES",
                headerText: "GENERAL.CONFIRM_CLEAR_FILTER",
                bodyText: this.getTranslate('GENERAL.CONTINUE_QUESTION')
            });
            if (approved) {
                await this.gridApi.core.clearAllFilters();
                const newState = this.saveGridState();
                this.currentState = newState;
                await this.handleFilterChange();
                modalContext.ok();
            }
        };

        this.scope[this.gridOptionsReferenceName].gridAddProfile = async (name, modalContext) => {
            if (this.scope.isEdit) await this.editProfile(name, modalContext);
            else await this.addNewProfile(name, modalContext);
        };

        this.scope[this.gridOptionsReferenceName].gridRemoveProfile = async (name) => {
            await this.removeProfile(name);
        };

        this.scope[this.gridOptionsReferenceName].gridSetProfileDefault = async (name) => {
            await this.gridSetProfileDefault(name);
        };

        this.scope[this.gridOptionsReferenceName].gridSaveProfile = async (name) => {
            await this.saveProfile(name);
        };

        this.scope[this.gridOptionsReferenceName].gridLoadProfile = async (selectedProfile) => {
            await this.loadProfileData(selectedProfile);
        };

        this.scope[this.gridOptionsReferenceName].setFixedRow = (_id: string): void => {
            this.setFixedRow(_id);
        }

        this.scope[this.gridOptionsReferenceName].clearFixedRow = (): void => {
            this.clearFixedRow();
        }

        this.scope[this.gridOptionsReferenceName].gridModalTemplateExcel = (): void => {
            this.gridModalTemplateExcel();
        }

        this.scope[this.gridOptionsReferenceName].gridHasSelectedRows = (): boolean => {
            return this.gridApi && this.gridApi.selection && this.gridApi.selection.getSelectedRows().length > 0;
        }

        this.scope[this.gridOptionsReferenceName].gridGetExcelRoute = (): boolean => {
            return this.excelRoute ? true : false;
        }
    }

    private initGridOptions(): void {
        //init gridOptions
        this.gridOptions = {
            footerTemplate: "ui-grid/ui-grid-footer",
            gridFooterTemplate: "ui-grid/ui-grid-grid-footer",
            gridMenuTemplate: "ui-grid/uiGridMenu",
            gridMenuTitleFilter: this.$translate,
            //paginationTemplate: "ui-grid/pagination", // -- default template
            paginationTemplate: require('../app/view/template/monaco-grid-pagination-bar.html'),
            //rowTemplate: "ui-grid/ui-grid-row", // -- default template
            rowTemplate: require('../app/view/template/monaco-grid-row-template.html'),
            enableGridMenu: true,
            gridMenuCustomItems: [
                {
                    title: this.getTranslate("GENERAL.DEFAULT_CONFIG"),
                    action: this.setDefaultView.bind(this),
                    icon: 'fa fa-cog',
                },
                {
                    title: this.getTranslate("GENERAL.SORT_COLUMNS_ASC"),
                    action: this.sortColumns.bind(this, true),
                    icon: "fa fa-sort-alpha-asc",
                },
                {
                    title: this.getTranslate("GENERAL.SORT_COLUMNS_DESC"),
                    action: this.sortColumns.bind(this, false),
                    icon: "fa fa-sort-alpha-desc",
                },
                {
                    title: this.getTranslate("GENERAL.SHOW_COLUMNS"),
                    action: this.showAndHideColumns.bind(this, true),
                    icon: "fa fa-eye",
                },
                {
                    title: this.getTranslate("GENERAL.HIDE_COLUMNS"),
                    action: this.showAndHideColumns.bind(this, false),
                    icon: "fa fa-eye-slash",
                },
                {
                    title: this.getTranslate("GENERAL.EXPORT_DATA"),
                    action: this.exportData.bind(this, this.uiGridExporterConstants.VISIBLE, this.uiGridExporterConstants.VISIBLE),
                    icon: "fa fa-file-excel-o"
                },
            ],
            exporterSuppressMenu: false,
            exporterMenuCsv: false,
            exporterMenuPdf: false,

            exporterMenuAllData: true,
            exporterMenuVisibleData: true,

            exporterHeaderFilterUseName: false,
            exporterOlderExcelCompatibility: false,
            exporterMenuExcel: false,
            maxVisibleColumnCount: 50,
            enableFiltering: true,
            treeRowHeaderAlwaysVisible: false,
            enableColumnMoving: true,
            enableColumnResizing: true,
            enableColumnMenus: true,
            enableSorting: true,
            enablePinning: false,
            enablePagination: true,
            paginationPageSizes: [25, 50, 100, 250],
            paginationPageSize: 25,
            paginationCurrentPage: 1,
            enablePaginationControls: true,
            useExternalPagination: false,
            useExternalFiltering: false,
            useExternalSorting: false,
            virtualizationThreshold: 20,
            headerHeight: 30,
            rowHeight: 30,
            gridFooterHeight: 30,
            enableVerticalScrollbar: this.gridConstants.scrollbars.ALWAYS,
            enableRowSelection: true,
            enableRowHeaderSelection: false,
            enableRowHashing: true,
            enableSelectAll: false,
            enableFullRowSelection: true,
            enableHAWB: false,
            valueHAWB: 0,
            multiSelect: false,
            noUnselect: false,
            showGridFooter: false,
            showColumnFooter: false,
            showHeader: true,
            saveScroll: true,
            saveFocus: true,
            saveWidths: true,
            saveOrder: true,
            saveVisible: true,
            saveSort: true,
            saveFilter: true,
            savePinning: true,
            saveGrouping: false,
            saveGroupingExpandedStates: false,
            saveTreeView: false,
            saveSelection: false,
            saveRowIdentity: false,
            exporterCsvFilename: 'export.csv',
            exporterSuppressColumns: this.actionColumns,
            exporterFieldCallback: (grid, row, col, input) => {
                let value = input;

                if (input && Array.isArray(input) && col && col.colDef && col.colDef['arrayProperty']) {
                    value = this.gridArrayCellTemplate(input, col.colDef['arrayProperty']);
                }

                if (col.colDef.cellFilter) {
                    var args, filter, arg1, arg2;
                    // remove space, single/double to mantein retro-compatibility
                    args = col.colDef.cellFilter.replace(/['"\s]/g, "").split(':');
                    filter = args[0] ? args[0] : null;
                    arg1 = args[1] ? args[1] : null;
                    arg2 = args[2] ? args[2] : null;
                    return this.$filter(filter)(input, arg1, arg2);
                } else {
                    return value;
                }
            },
        };

        this.gridOptions.exporterHeaderFilter = this.$translate.instant;
        this.gridOptions.gridProfileList = [];
        this.gridOptions.profile = { gridSelectedProfile: null };
        this.renameDefaultMenuItemColumns();
        this.registerGridApiEvent();
    }

    async exportData(rowTypes: string, colTypes: string): Promise<void> {
        if (!await this.permissionService.isRoleAllowed('EXPORTGRIDDATA')) {
            this.permissionService.showBlockMessage();
            return;
        }
        if (this.customExportData instanceof Function) this.customExportData();
        if (!this.gridApi) return;
        this.gridApi.exporter.csvExport(rowTypes, colTypes);
    }

    private async renameDefaultMenuItemColumns() {
        if (!this.i18nService) return;

        this.i18nService.get('en').gridMenu.clearAllFilters = this.getTranslate("GENERAL.CLEAN_FILTERS");
        this.i18nService.get('en').gridMenu.columns = this.getTranslate("GENERAL.COLUMNS");
    }

    /*************************************************************************************************************************
        TODO: TODO LUNELLI

        - Separate PAGINATION from FILTER logic in order to be able to apply them individually to grids.

        - Prevent data request from occurring multiple times:

            -> Restructuring is needed to:
                A) turn gridData into no-mandatory param to init() grid and apply profile
                B) Blind listeners (onFilterChanged, onSortChanged, etc) until profile is applied and filter object is built

            -> Routine logic order suggestion:
                1) init() grid, with no mandatory data and/or route
                2) Check for profile and profile filter
                    2.1) Restore profile and apply configurations (filter/sort/etc) to columns
                    2.2) Build filter/pagination/sort object (this.activeFilter)
                3) Request data (route + this.activeFilter)
                4) Register listeners now (to prevent step 2.1 from triggering them)

    *************************************************************************************************************************/

    // TODO: this needs to be updated
    async configGrid(gridConfig: IGridConfig<T>): Promise<void> {
        try {
            await this.blockUI.start();

            this.gridName = gridConfig.gridName;

            // ---- column list acquiring ---- //

            const columnsConfig = gridConfig.columns;
            let baseColumnsList: (string | object)[] = [];
            if (columnsConfig.gridRoute)
                baseColumnsList = await this.requestGridColumnsList(columnsConfig.gridRoute);

            const customColumnsList = (columnsConfig.customColumnsList) ? angular.copy(columnsConfig.customColumnsList) : [];
            const gridColumnsList = (customColumnsList.length > 0) ? baseColumnsList.concat(customColumnsList) : baseColumnsList;

            // ---- column building ---- //

            let gridColumnsDef: IMonacoColumnDef[] = [];
            if (gridColumnsList && gridColumnsList.length > 0) {
                const customBuilder = columnsConfig.customBuilder;
                if (customBuilder && customBuilder.function) {
                    const executionContext = (customBuilder.context) ? customBuilder.context : this.controllerContext;
                    customBuilder.function.call(executionContext, gridColumnsList);
                } else {
                    if (!this.controllerContext || !this.controllerContext.initGridColumns) throw new Error('Failed to init gridService: missing grid columns initialization');
                    gridColumnsDef = this.controllerContext.initGridColumns(gridColumnsList);
                }
            } else
                throw new Error('Failed to init gridService: missing grid columns list');

            const customGridColumns = (columnsConfig.customColumnsDef) ? angular.copy(columnsConfig.customColumnsDef) : [];
            let gridColumns = (customGridColumns.length > 0) ? gridColumnsDef.concat(customGridColumns) : gridColumnsDef;

            this.defaultColumns = gridColumns;

            // ---- options configuration ---- //

            this.gridOptions.columnDefs = gridColumns;

            // filter configuration
            if (!gridConfig.filterType) {
                this.gridOptions.enableFiltering = false;
            } else {
                if (gridConfig.filterType != OperationType.DEFAULT) {
                    this.gridOptions.gridMenuCustomItems.unshift(
                        {
                            title: this.getTranslate('GENERAL.SMARTFILTER_TUTORIAL'),
                            action: async function ($event) { await this.context.viewSmartFilterTutorial(); },
                            icon: 'fa fa-magic',
                            context: this,
                        });

                    await this.setGridFilterPatterns();
                    if (this.filterPatterns) {
                        gridColumns = gridColumns.map(colDef => {
                            colDef.headerCellClass = this.highlightFilterSyntaxHeader.bind(this);
                            return colDef;
                        });
                    }
                }
                this.gridOptions.useExternalFiltering = (gridConfig.filterType == OperationType.EXTERNAL);
            }

            // sort configuration 
            if (!gridConfig.sortingType) {
                this.gridOptions.enableSorting = false;
            } else
                this.gridOptions.useExternalSorting = (gridConfig.sortingType == OperationType.EXTERNAL);

            // pagination configuration
            if (!gridConfig.paginationType) {
                this.gridOptions.enablePagination = false;
                this.gridOptions.enablePaginationControls = false;
            } else if (gridConfig.paginationType == OperationType.EXTERNAL) {
                this.gridOptions.paginateLimit = true;
                this.gridOptions.useExternalPagination = true;
                this.gridOptions.totalItems = null; //totalCount; -> This must be null
            }

            // ---- state registering ---- //

            // await grid api initialization
            await this.loaded.promise; // TODO: IMPLEMENT TIMEOUT, THIS CAN AWAIT FOREVER IF THE GRID API DOES NOT LOAD

            this.registerColumnPositionEvent();
            this.notifyColumnsChange();

            if (this.gridOptions.enableRowSelection)
                this.registerSelectionChangedEvent();

            // save default state
            this.defaultState = this.gridApi.saveState.save();

            // ---- profile restoration ---- //

            if (gridConfig.enableProfile) {
                if (!this.gridApi.saveState) throw new Error('You must include ui-grid-save-state on the grid html to use the grid profile');
                await this.enableProfile(true);
            }

            // ---- data building ---- //

            const dataConfig = gridConfig.data;
            this.gridRoute = (dataConfig.gridRoute) ? dataConfig.gridRoute : null;
            this.requestGridDataTimeout = (gridConfig.timeout) ? gridConfig.timeout : this.requestGridDataTimeout;

            if (dataConfig.customData) {
                const customGridData = angular.copy(dataConfig.customData);
                this.setGridData(customGridData);
            } else {
                if (this.gridRoute) {
                    if (gridConfig.enableProfile && this.profiledFilter) {
                        await this.applyGridSort();
                        await this.handleFilterChange();
                    } else
                        await this.updateGridData();
                } else
                    throw new Error('Failed to init gridService: neither data nor route provided to init grid');
            }
            this.notifyDataChange();

            // ---- register listeners ---- //

            if (gridConfig.filterType && gridConfig.filterType != OperationType.DEFAULT)
                this.registerFilterChangedEvent();

            if (gridConfig.sortingType == OperationType.EXTERNAL)
                this.registerSortChangedEvent();

            if (gridConfig.paginationType == OperationType.EXTERNAL)
                this.registerPaginationChangedEvent();

            //flag to prevent listeners from being triggered before grid finished loading
            this.initialized = true;

            this.unblock();
        } catch (ex) {
            this.initialized = false;
            throw ex;
        }
    }

    async init(gridName: string, gridRoute: string, gridColumns: IMonacoColumnDef[], gridData: any, enableProfile: boolean, externalFilter: boolean = false, externalPagination: boolean = false, localSort?: boolean, excelRoute?: string, customButton?: ICustomButton): Promise<void> {
        try {
            this.gridName = gridName;
            this.gridRoute = gridRoute;
            this.defaultColumns = gridColumns;
            this.excelRoute = excelRoute;
            this.customButton = customButton;

            //await grid api initialization
            await this.loaded.promise; //TODO: IMPLEMENT TIMEOUT, THIS CAN AWAIT FOREVER IF THE GRID API DOES NOT LOAD

            this.registerColumnPositionEvent();

            //overwrite header style to show filter syntax validation
            gridColumns = gridColumns.map(colDef => {
                if (!colDef.headerCellTemplate) colDef.headerCellTemplate = require("../app/view/template/monaco-grid-column-header-translated-template.html");
                return colDef;
            });

            // ----- FILTER, PAGINATE AND SORT ---- //

            if (externalFilter) {

                // this.registerFilterChangedEvent();

                //set server-side pagination, filtering and sorting
                this.gridOptions.paginateLimit = false;
                this.gridOptions.useExternalFiltering = true;
                this.gridOptions.useExternalPagination = false;
                this.gridOptions.useExternalSorting = false;

                this.gridOptions.totalItems = null; //totalCount; -> This must be null
                this.gridOptions.gridMenuCustomItems.unshift(
                    {
                        title: this.getTranslate('GENERAL.SMARTFILTER_TUTORIAL'),
                        action: async function ($event) {
                            await this.context.viewSmartFilterTutorial();
                        },
                        icon: 'fa fa-magic',
                        context: this,
                    });

                gridColumns = gridColumns.map(colDef => {
                    colDef.headerCellClass = this.highlightFilterSyntaxHeader.bind(this);
                    colDef.enableSorting = !this.restrictiveSort;
                    return colDef;
                });

                this.scope.$on(`triggerGridFilter${this.gridApi.grid.id}`, (event) => {
                    this.filterChangedEventCallback();
                });

                //register valid filter patterns
                const rawFilterPatterns = await this.RestService.getObjectAsPromise(this.validPatternsUrl, 15000, null, false);
                const patterns: IValidFilterPatterns = (rawFilterPatterns.data) ? rawFilterPatterns.data : null;
                if (patterns) {
                    this.filterPatterns = {} as IGridServiceFilterPatterns;
                    if (patterns.date) this.filterPatterns.date = new RegExp(patterns.date);
                    if (patterns.number) this.filterPatterns.number = new RegExp(patterns.number);
                    if (patterns.word) this.filterPatterns.word = new RegExp(patterns.word);
                    if (patterns.word) this.filterPatterns.special = new RegExp(patterns.special);
                    if (patterns.separator) this.filterPatterns.separator = patterns.separator;
                }
            }

            if (externalPagination) {
                this.gridOptions.paginateLimit = true;
                this.gridOptions.useExternalPagination = true;

                if (!localSort) {
                    this.gridOptions.useExternalSorting = true;
                    this.registerSortChangedEvent();
                }

                this.registerPaginationChangedEvent();
            }

            // --------- //

            if (this.gridOptions.enableRowSelection) {
                await this.registerSelectionChangedEvent();
            }

            //set columns defs and data
            this.gridOptions.columnDefs = gridColumns;
            this.notifyColumnsChange();
            this.doOrderMenuItems();

            //save default state
            this.defaultState = this.gridApi.saveState.save();
            //this.defaultState = angular.copy(this.gridApi.saveState.save());

            if (enableProfile) {
                if (!this.gridApi.saveState) throw new Error('You must include ui-grid-save-state on the grid html to use the grid profile');
                await this.enableProfile(true);
            }

            const currentState = this.$state.current.name;

            this.sessionParameter = <ISessionParameter>this.sessionService.getParamFromRoute(currentState);

            if (this.sessionParameter || !this.gridOptions.profile.gridSelectedProfile || !enableProfile) await this.restoreDataGridState(gridData);

            if (this.sessionParameter && this.sessionParameter.param) {
                this.clearColumnFilter(this.scope);
                for (const key of Object.keys(this.sessionParameter.param)) {
                    this.setColumnFilter(this.scope, key, this.sessionParameter.param[key]);
                }
            }

            //deal with pre-loaded data
            const externalSearch = (this.gridOptions.useExternalFiltering || this.gridOptions.useExternalPagination || this.gridOptions.useExternalPagination);
            if (externalSearch) {
                if (this.profiledFilter || this.sessionParameter)
                    //if profile has filter saved, force filter apply - sadly, we still need all data because of its columns definitions, as columns/data are inherent to each other.
                    await this.handleFilterChange();
                else {
                    //otherwise just attribute data
                    this.setGridData(gridData);
                }
            } else this.setGridData(gridData);

            this.notifyDataChange();

            this.setActionColumnPosition()

            //flag to prevent listeners from being triggered before grid finished loading            
            this.initialized = true;
        } catch (ex) {
            this.initialized = false;
            throw ex;
        }
    }

    private async restoreDataGridState(gridData: T[]) {
        if (!gridData || gridData.length == 0) return;
        // await this.gridApi.grid.modifyRows(gridData);
        await this.loadGridState(this.defaultState);
    }

    private doOrderMenuItems() {
        if ((!this.gridOptions) || (!this.orderMenuItems) || (this.gridOptions.columnDefs.length === 0)) return;

        let actionColumnsDefs: uiGrid.IColumnDefOf<any>[] = [];
        for (const actionCol of this.actionColumns) {
            const index = this.gridOptions.columnDefs.findIndex(x => x.name === actionCol);
            actionColumnsDefs.push(this.gridOptions.columnDefs[index]);
            this.gridOptions.columnDefs.splice(index, 1);
        }

        this.gridOptions.columnDefs.sort((x, y) => this.getTranslate(x.displayName) > this.getTranslate(y.displayName) ? 1 : -1);

        for (const actionColDef of actionColumnsDefs) {
            this.gridOptions.columnDefs.unshift(actionColDef);
        }
    }

    //gridData as string represents the list route, as object represent the raw data, and must have data and columns properties
    async initGridStandAlone(gridName: string, gridData: string | Object, defaultGridColumnsFunction: Function, enableProfile: boolean, externalFilter: boolean = false, timeout?: number, externalPagination: boolean = false, localSort?: boolean): Promise<void> {
        try {
            //validate parameters
            if (!gridName || !gridData || !defaultGridColumnsFunction) {
                throw new Error('Failed to init gridService: gridName/gridData/defaultGridColumnsFunction is NULL');
            }

            if (typeof (gridData) === 'string') {
                //set timeout for request grid data if available, default is 15000
                if (timeout) this.requestGridDataTimeout = timeout;
                const gridRoute = gridData;
                const externalSearch = (externalFilter || externalPagination);
                const result: IGridDataReturn = await this.requestGridData(gridRoute, externalSearch);

                gridData = result.data;
                const totalItems = (result.totalCount) ? (result.totalCount) : null;
                const gridColumns = defaultGridColumnsFunction(result.columns);
                await this.init(gridName, gridRoute, gridColumns, gridData, enableProfile, externalFilter, externalPagination, localSort);

            } else {
                if (!gridData.hasOwnProperty('data') || !gridData.hasOwnProperty('columns')) {
                    throw new Error('Invalid grid data parameter, must have data/columns');
                    //return HandleError.exception('Invalid grid data parameter, must have data/columns');
                }
                //TODO: GUSTAVO - IMPLEMENT BACKGROUND UPDATE RECEIVING DATA AS PARAMETER FOR THIS CASE
                const gridColumns = defaultGridColumnsFunction(gridData['columns']);
                const data = gridData['data'];
                await this.init(gridName, null, gridColumns, data, enableProfile);
            }
        } catch (ex) {
            throw ex;
        }
    }

    async setGridFilterPatterns(): Promise<void> {
        try {
            const rawFilterPatterns = await this.RestService.getObjectAsPromise(this.validPatternsUrl, 15000, null, false);
            const patterns: IValidFilterPatterns = (rawFilterPatterns.data) ? rawFilterPatterns.data : null;
            if (patterns) {
                this.filterPatterns = {} as IGridServiceFilterPatterns;
                if (patterns.date) this.filterPatterns.date = new RegExp(patterns.date);
                if (patterns.number) this.filterPatterns.number = new RegExp(patterns.number);
                if (patterns.word) this.filterPatterns.word = new RegExp(patterns.word);
                if (patterns.word) this.filterPatterns.special = new RegExp(patterns.special);
                if (patterns.separator) this.filterPatterns.separator = patterns.separator;
            }
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    async getFilterReqParams(): Promise<IDataFilterRequest> {
        try {
            const requestParam = { datafilter: {} } as IDataFilterRequest;

            //Pagination
            const requestedPage = this.gridOptions.paginationCurrentPage;
            const requestedInterval = this.gridOptions.paginationPageSize;
            const start = (requestedPage == 1) ? 0 : (requestedPage - 1) * requestedInterval;
            let interval = requestedInterval;
            // we can only allow no limit when having at least one active filter
            const allowNoLimit: boolean = (!this.gridOptions.paginateLimit && this.activeFilter && (Object.keys(this.activeFilter).length > 0));
            requestParam.datafilter.limits = (allowNoLimit) ? null : [start, interval];

            //Filters
            if (this.activeFilter && Object.keys(this.activeFilter).length > 0)
                requestParam.datafilter.filter = this.activeFilter;

            //Sorting
            if (this.activeSort && Object.keys(this.activeSort).length > 0)
                requestParam.datafilter.sort = this.activeSort;

            return requestParam;
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    async requestGridData(gridRoute: string, externalSearch: boolean = false, customParams?: IDataFilterRequest): Promise<IGridDataReturn> {
        try {
            if (!gridRoute) throw new Error('Missing gridRoute to initialize grid data');

            /*************************************************************************************************************************
             When pagination is requested, we'll operate with a POST route as we need to pass a PAGINATION/FILTER/SORT object parameter.
             It looks very inelegant for a GET request to do so with querystring.
            **************************************************************************************************************************/
            let gridData: IGridDataReturn = null;

            if (externalSearch) {
                const param = (customParams) ? customParams : await this.getFilterReqParams();
                if (!param) throw new Error('Failed to get grid filter/pagination info.');
                gridData = await this.RestService.newObjectPromise(`${this.baseUrl}${gridRoute}`, param, this.requestGridDataTimeout, false);
            } else {
                gridData = await this.RestService.getObjectAsPromise(`${this.baseUrl}${gridRoute}`, this.requestGridDataTimeout, null, false);
            }

            if (!gridData || !gridData.columns) throw new Error('Failed to get grid data/columns from server');
            // couting is no longer used: if (gridData.totalCount != null) this.gridOptions.totalItems = gridData.totalCount;

            return gridData;
        } catch (ex) {
            throw ex;
        }
    }

    async requestGridColumnsList(gridColumnsRoute: string): Promise<(string | object)[]> {
        try {
            if (!gridColumnsRoute) throw new Error('Missing gridColumnsRoute to initialize grid columns');

            let gridColumnsList: (string | object)[] = await this.RestService.getObjectAsPromise(`${this.baseUrl}${gridColumnsRoute}`, this.requestGridDataTimeout, null, false);
            if (gridColumnsList.hasOwnProperty('data') && gridColumnsList['data']) gridColumnsList = gridColumnsList['data'];
            if (gridColumnsList.hasOwnProperty('columns')) gridColumnsList = gridColumnsList['columns'];
            if (!gridColumnsList) throw new Error('Failed to get grid columns from server');

            return gridColumnsList;
        } catch (ex) {
            throw ex;
        }
    }

    destruct(): void {
        try {
            this.cancelBackGroundUpdate();
            this.scope[this.gridOptionsReferenceName] = null;
            delete this.scope[this.gridOptionsReferenceName];

        } catch (ex) {
            throw ex;
        }
    }

    public enableCellNavigation(): void {
        this.registerCellNavigateEvent();
        this.registerCellEditEvents();
    }

    //TODO: WHAT IS DEFAULT VIEW WITHOUT PROFILE?
    async setDefaultView(): Promise<void> {
        this.gridApi.saveState.restore(this.scope, this.defaultState);
        if (this.gridOptions.useExternalFiltering || this.gridOptions.useExternalPagination || this.gridOptions.useExternalSorting) {
            this.blockUI.start();
            await this.updateGridData();
            this.unblock();
        }
    }

    async sortColumns(type: boolean): Promise<void> {
        try {
            this.blockUI.start();
            try {
                const grid = this.gridApi.grid;
                const actionCols: uiGrid.IGridColumn[] = [];
                for (const actionColName of this.actionColumns) {
                    const i = grid.columns.findIndex(x => x.colDef.name === actionColName)
                    if (i > -1) {
                        const actionCol = grid.columns.splice(i, 1);
                        if (actionCol) actionCols.push(actionCol[0]);
                    }
                }
                if (type) {
                    grid.columns.sort((x, y) => x.displayName > y.displayName ? 1 : -1);
                }
                else {
                    grid.columns.sort((x, y) => x.displayName < y.displayName ? 1 : -1);
                }
                actionCols.reverse();
                for (const col of actionCols) {
                    grid.columns.unshift(col);
                }
                await this.refresh();
            } catch (ex) {
                throw ex;
            }
        }
        finally {
            this.unblock();
        }
    }

    async showAndHideColumns(status: boolean): Promise<void> {
        try {
            this.blockUI.start();
            try {
                const grid = this.gridApi.grid;

                for (const column of grid.columns) {
                    if (!this.actionColumns.includes(column.name) && (column.name != 'selectionRowHeaderCol')) column.colDef.visible = status;
                }

                await this.refresh();
            } catch (ex) {
                throw ex;
            }
        }
        finally {
            this.unblock();
        }
    }

    private registerSelectionChangedEvent() {
        if (this.gridApi && this.gridApi.selection) {
            // Selection limit verification must not be async, due to selectionReapply services
            this.gridApi.selection.on.rowSelectionChanged(this.scope, this.verifySelectionLimit.bind(this));
            this.gridApi.selection.on.rowSelectionChangedBatch(this.scope, this.verifySelectionLimit.bind(this));

            this.gridApi.selection.on.rowSelectionChanged(this.scope, this.selectionChanged.bind(this));
            this.gridApi.selection.on.rowSelectionChangedBatch(this.scope, this.selectionChanged.bind(this));
        }
    }

    public verifySelectionLimit(row, evt): void {
        const multipleRowsChecked: boolean = row && row.length > 0;
        const isLimitedSelection: boolean = this.selectableRowsLimit > 0;
        let messageTimeout = null;

        const unselectRow = (row) => {
            if (row.isSelected) {
                if (!this.selectionLimitCallback) row.setSelected(false);
                if (this.showMessageInformingLimit) {
                    this.$timeout.cancel(messageTimeout);
                    messageTimeout = this.$timeout(() => {
                        this.notificationService.info(`Selection has exceeded the limit of ${this.selectableRowsLimit} lines`);
                    });
                }
            }
        }

        // User has marked the 'selectall' checkbox
        if (multipleRowsChecked && isLimitedSelection && this.gridApi.selection.getSelectAllState()) {
            const prospectRows = row;
            const rowsSelected = this.gridSelectedRows;
            const amountAvailableToSelect = this.selectableRowsLimit - (rowsSelected != null ? rowsSelected.length : 0);
            if (amountAvailableToSelect > 0) {
                // It contains rows available to select between the limit.
                for (let i = amountAvailableToSelect; i < prospectRows.length; i++) {
                    unselectRow(prospectRows[i]);
                }
            } else {
                prospectRows.forEach(row => unselectRow(row));
            }
        }

        // User selected only one row (it was not marked the 'selectall' checkbox)
        if (!multipleRowsChecked && isLimitedSelection && (this.gridSelectedRows && this.gridSelectedRows.length >= this.selectableRowsLimit)) {
            unselectRow(row);
        }

        if (this.selectionLimitCallback) this.$timeout(() => this.selectionLimitCallback.call(this, this.gridSelectedRows && this.gridSelectedRows.length > this.selectableRowsLimit, this.gridSelectedRows ? this.gridSelectedRows.length : 0));
    }

    public async selectionChanged(): Promise<void> {
        if (!this.updating) // avoid saving selection state when background-updating
            this.gridSelectedRows = this.gridApi.selection.getSelectedRows();
    }

    // TODO LUNELLI https://github.com/angular-ui/ui-grid/issues/3224#issuecomment-95283887
    public async registerRowRenderEvent(renderCallback: Function, context: any): Promise<void> {
        this.gridApi.core.on.rowsRendered(this.scope, renderCallback.bind(context));
    }

    setBackgroundUpdate(interval?: number, reapplyRowSelection?: [Function, any]): void {
        try {
            if (!this.gridRoute) throw new Error('Failed to set background update, gridRoute is NULL');

            const needsReapplySelection = (this.gridOptions.useExternalFiltering && this.gridOptions.multiSelect);
            if (!reapplyRowSelection && needsReapplySelection) throw new Error('Failed to set background update, missing selection reapply function');

            if (interval) {
                if (interval < 15000) this.backgroundUpdateInterval = 15000;
                else this.backgroundUpdateInterval = interval;
            }
            //check if we have a previous interval registered
            if (this.backgroundUpdateHandler) {
                //const canceled = this.timeout.cancel(this.backgroundUpdateHandler);
                //if (!canceled) throw new Error('Failed to cancel grid background update');
                this.timeout.cancel(this.backgroundUpdateHandler);
                this.backgroundUpdateHandler = null;
                this.gridOptions.enableBackgroundUpdate = false;
            }
            //register the interval timer
            this.backgroundUpdateHandler = this.timeout(this.handleBackgroundUpdate.bind(this), this.backgroundUpdateInterval);
            this.gridOptions.enableBackgroundUpdate = true;

            if (needsReapplySelection)
                this.registerRowRenderEvent(reapplyRowSelection[0], reapplyRowSelection[1]);

        } catch (ex) {
            throw ex;
        }
    }

    async handleBackgroundUpdate(): Promise<void> {
        try {
            if (this.updating) return;

            this.updating = true;

            await this.updateGridData();

            this.updating = false;

            //re-schedule timer
            //this.backgroundUpdateHandler = this.timeout(this.handleBackgroundUpdate.bind(this), this.backgroundUpdateInterval);

        } catch (ex) {
            HandleError.exception(ex);
            //re-schedule timer
            this.updating = false;
            this.backgroundUpdateHandler = this.timeout(this.handleBackgroundUpdate.bind(this), this.backgroundUpdateInterval);
        }
    }

    cancelBackGroundUpdate(): void {
        try {
            if (this.backgroundUpdateHandler) {
                const canceled = this.timeout.cancel(this.backgroundUpdateHandler);
                //if (!canceled) throw new Error('Failed to cancel grid background update');
                this.backgroundUpdateHandler = null;
                this.gridOptions.enableBackgroundUpdate = false;
            }

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    public async updateGridData(): Promise<void> {
        try {
            //if we have a pending background update, reset the background handler, we are updating the data now
            if (this.backgroundUpdateHandler) {
                this.timeout.cancel(this.backgroundUpdateHandler);
            }

            const externalSearch = (this.gridOptions.useExternalFiltering || this.gridOptions.useExternalPagination || this.gridOptions.useExternalPagination);
            const result: IGridDataReturn = await this.requestGridData(this.gridRoute, externalSearch);
            if (!result || !result.columns) throw new Error(`Unable to get data from server`);

            this.setGridData(result.data);

            //set background update timer again
            if (this.backgroundUpdateHandler) {
                this.backgroundUpdateHandler = this.timeout(this.handleBackgroundUpdate.bind(this), this.backgroundUpdateInterval);
            }

        } catch (ex) {
            throw ex;
        }
    }

    public async getGridData<T = any>(): Promise<T[]> {
        return <T[]>this.gridOptions.data;
    }

    public setGridData(newData) {
        this.gridOptions.data = newData;
        this.gridOptions.gridLastUpdate = new Date();

        if (this.showTotalCount && this.gridOptions && this.gridOptions.data && this.gridOptions.data.length >= 0) {
            this.gridOptions.totalItems = this.gridOptions.data.length;
        }
    }

    async enableProfile(value: boolean): Promise<void> {
        if (value) {
            this.gridOptions.enableProfile = true;
            await this.loadProfileList();
        } else this.gridOptions.enableProfile = false;
    }

    private unblock(): void {
        this.timeout((self) => {
            self.blockUI.stop();
        }, 0, true, this);
    }

    private async initGridMenu(): Promise<void> {
        this.gridMenuService.initialize(this.scope, this.gridApi.grid);
        this.gridMenuItems = this.gridMenuService.getMenuItems(this.scope);
        const newMenuItem: uiGrid.IMenuItem = {
            title: "Configuração Padrão",
            action: () => { this.setDefaultView() }
        };
        this.gridMenuItems.push(newMenuItem);
        this.gridMenuService.addToGridMenu = this.gridMenuItems;
        //this.gridApi.core.addToGridMenu(this.gridApi.grid, this.gridMenuItems);
        await this.refresh();
    }

    private async registerGridApiEvent(): Promise<void> {
        this.gridOptions.onRegisterApi = this.gridApiEventCallback.bind(this);
    }

    private gridApiEventCallback(gridApi: uiGrid.IGridApiOf<any>): void {
        const self: GridService2 = this;
        this.gridApi = gridApi;
        if (this.gridApi.cellNav) {
            this.gridApi.cellNav.on.navigate(null, function (newRowCol) {
                self.gridApi.selection.selectRow(newRowCol.row.entity);
            });
        }
        this.loaded.resolve(true);
    }

    private registerSortChangedEvent(): void {
        this.gridApi.core.on.sortChanged(this.scope, this.sortChangedEventCallback.bind(this));
    }

    private async sortChangedEventCallback(): Promise<void> {
        try {
            await this.blockUI.start();
            await this.applyGridSort();
            await this.handleFilterChange();
            this.unblock();
        } catch (ex) {
            this.unblock();
            HandleError.exception(ex);
        }
    }

    highlightFilterSyntaxHeader(row, rowRenderIndex, col, colRenderIndex) {
        if (col.filters[0].term)
            return (this.checkGridFilterValidity(col.filters[0].term)) ? 'header-filtered' : 'invalid-filter';
        else
            return 'header-filtered';
    }

    highlightFilteredHeader(row, rowRenderIndex, col, colRenderIndex) {
        if (col.filters[0].term) return 'header-filtered';
        else return '';
    }

    private checkGridFilterValidity(rawterm: string): boolean {

        //no patterns registered
        if (!this.filterPatterns) return true;

        let searchTerms: string[] = [];
        const patterns = this.filterPatterns;
        const isMultiple = (rawterm.indexOf(patterns.separator) > -1);

        if (isMultiple) {
            searchTerms = rawterm.split(patterns.separator);

            if (this.maxQueryCriteria && searchTerms && searchTerms.length > this.maxQueryCriteria) {
                return false;
            }
        } else
            searchTerms.push(rawterm);

        let validFilterComposition = true;
        for (let term of searchTerms) {
            const dateTestResult = term.match(patterns.date);
            const isDateSearch = (dateTestResult && dateTestResult.length > 0) ? true : false;

            const numberTestResult = term.match(patterns.number);
            const isNumberSearch = (numberTestResult && numberTestResult.length > 0) ? true : false;

            const wordTestResult = term.match(patterns.word);
            const isWordSearch = (wordTestResult && wordTestResult.length > 0) ? true : false;

            const specialTestResult = term.match(patterns.special);
            const isSpecialSearch = (specialTestResult && specialTestResult.length > 0) ? true : false;

            if (!isDateSearch)
                if (!isNumberSearch && !isWordSearch && !isSpecialSearch) validFilterComposition = false;
        }
        return validFilterComposition;
    }

    private registerFilterChangedEvent(): void {
        this.gridApi.core.on.filterChanged(this.scope, this.filterChangedEventCallback.bind(this));
    }

    public async filterChangedEventCallback(): Promise<void> {
        try {
            if (!this.initialized) return;

            //https://github.com/angular-ui/ui-grid/issues/3467
            if (this.filterChangeHandler) {
                this.timeout.cancel(this.filterChangeHandler);
                this.filterChangeHandler = null;
            }

            this.filterChangeHandler = this.timeout(this.handleFilterChange.bind(this), 10 /* 800*/);

        } catch (ex) {
            this.unblock();
            HandleError.exception(ex);
        }
    }

    private async handleFilterChange(): Promise<void> {
        try {
            // pre grid data filter hook
            if (this.controllerContext && this.controllerContext.preGridFilter) {
                await this.controllerContext.preGridFilter();
                await this.scope.$applyAsync();
            }

            await this.applyGridFilterAndUpdate();

            // post grid data filter hook
            if (this.controllerContext && this.controllerContext.postGridFilter) {
                await this.controllerContext.postGridFilter();
                await this.scope.$applyAsync();
            }
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    public clearColumnFilter(scope: angular.IScope) {
        try {
            if (!scope) return;

            const state = this.gridApi.saveState.save();

            for (const column of state.columns) {
                column.sort = {};
                column.filters = [{ term: "" }];
            }
            this.gridApi.saveState.restore(scope, state);
        }
        catch (ex) {
            HandleError.exception(ex);
        }
    }

    public setColumnFilter(scope: angular.IScope, columnName: string, filter: string) {
        try {
            if (!scope || !columnName || !filter) return;

            const state = this.gridApi.saveState.save();

            const columnFiltered = state.columns.filter(x => x.name === columnName);

            if (columnFiltered && columnFiltered.length > 0) {
                columnFiltered[0].filters = [{ term: filter }];

                this.gridApi.saveState.restore(scope, state);
            }
        }
        catch (ex) {
            HandleError.exception(ex);
        }
    }

    private async applyGridFilterAndUpdate(): Promise<void> {
        try {
            this.activeFilter = null;
            const activeFilter = {};
            const activeTerms: string[] = [];
            const gridColumn = this.gridApi.grid.columns;

            //https://github.com/angular-ui/ui-grid/issues/2602
            for (let column of gridColumn) {
                const colDef: IMonacoColumnDef = column.colDef;
                const filterRefProp = (column.field) ? column.field : column.name;
                const partialCriteria = (colDef.partialCriteria) ? true : false;
                if (column.filters[0].term) {
                    const term = (partialCriteria) ? column.filters[0].term + "%" : column.filters[0].term;
                    //check if this column has it's querying properties overwritten by not-default ones
                    if (colDef.searchProps && colDef.searchProps.length > 0) {
                        //if that's the case, we'll compose a object to be queried with a 'or' logic
                        const customSearchProps = [];
                        for (let prop of colDef.searchProps) {
                            customSearchProps.push({ [prop]: term }); //they'll all have the same term search value
                        }
                        if (customSearchProps.length > 0)
                            activeFilter[filterRefProp] = customSearchProps;
                    } else
                        activeFilter[filterRefProp] = term;

                    activeTerms.push(term);
                    colDef.enableSorting = true;
                } else {
                    colDef.enableSorting = !this.restrictiveSort;
                    column.sort = {};
                }
            }

            this.notifyColumnsChange();

            const validFilterComposition = activeTerms.every(term => this.checkGridFilterValidity(term));
            if (validFilterComposition) {
                const currentFilterCounter = (activeFilter) ? Object.keys(activeFilter).length : 0;
                const lastFilterCounter = (this.lastActiveFilter) ? Object.keys(this.lastActiveFilter).length : 0;

                if (currentFilterCounter > 0) {
                    this.activeFilter = activeFilter;
                    this.gridOptions.paginationCurrentPage = 1;
                }

                //update grid data
                this.blockUI.start();
                await this.updateGridData();
                this.unblock();

                //update lastActiveFilter
                this.lastActiveFilter = this.activeFilter;
            }
        } catch (ex) {
            this.unblock();
            HandleError.exception(ex);
        }
    }

    private async applyGridSort(): Promise<void> {
        try {
            this.activeSort = null;

            const columnsSorting: { name: string, priority: number, direction: string }[] = [];
            const gridColumn = this.gridApi.grid.columns;

            for (let column of gridColumn) {
                if (column.sort && Object.keys(column.sort).length > 0)
                    columnsSorting.push({
                        name: column.name,
                        priority: column.sort.priority,
                        direction: column.sort.direction
                    })
            }

            //sort by priority
            columnsSorting.sort((a, b) => (a.priority > b.priority) ? 1 : ((b.priority > a.priority) ? -1 : 0));
            columnsSorting.reverse();

            const activeSort = {};
            for (let sort of columnsSorting) {
                activeSort[sort.name] = (sort.direction == 'asc') ? 1 : -1;
            }

            if (Object.keys(activeSort).length > 0)
                this.activeSort = activeSort;

        } catch (ex) {
            this.unblock();
            HandleError.exception(ex);
        }
    }

    private registerPaginationChangedEvent(): void {
        this.gridApi.pagination.on.paginationChanged(this.scope, this.paginationChangedEventCallback.bind(this));
    }

    private async paginationChangedEventCallback(newPage: number, pageSize: number): Promise<void> {
        try {
            if (!this.initialized) return;
            await this.blockUI.start();
            await this.updateGridData();

            this.unblock();
        } catch (ex) {
            this.unblock();
            HandleError.exception(ex);
        }
    }

    private registerColumnSizeEvent(): void {
        if (this.gridApi.colResizable) this.gridApi.colResizable.on.columnSizeChanged(this.scope, this.columnSizeEventCallback.bind(this));
    }

    private columnSizeEventCallback(colDef: uiGrid.IColumnDef, deltaChange: number): void {
        const newColumnDefs = this.gridOptions.columnDefs;
        for (let i = 0; i < newColumnDefs.length; i++) {
            if (newColumnDefs[i].name === colDef.name) {
                //update just the width property
                let currentValue: any = newColumnDefs[i].width;
                if (!angular.isNumber(currentValue)) currentValue = parseFloat(<string>newColumnDefs[i].width) * 13;
                else currentValue = newColumnDefs[i].width;
                currentValue += deltaChange;
                newColumnDefs[i].width = currentValue;
                break;
            }
        }
    }

    private registerColumnPositionEvent(): void {
        if (this.gridApi.colMovable) this.gridApi.colMovable.on.columnPositionChanged(this.scope, this.columnPositionEventCallback.bind(this));
    }

    private columnPositionEventCallback(colDef: uiGrid.IColumnDef, originalPosition: number, finalPosition: number): void {
        this.setActionColumnPosition();
    }

    private registerColumnVisibilityEvent(): void {
        if (this.gridApi.core) this.gridApi.core.on.columnVisibilityChanged(this.scope, this.columnVisibilityEventCallback.bind(this));
    }

    private setActionColumnPosition(): void {
        //block actions columns to be moved by any other column
        const grid = this.gridApi.grid;
        let i = 0
        for (const actionCol of this.actionColumns) {
            const index = grid.columns.findIndex(x => x.name === actionCol);
            if (index === -1) continue;
            this.swapArrayElements(grid.columns, index, i);
            i++;
        }
    }

    private columnVisibilityEventCallback(changedColumn: uiGrid.IGridColumnOf<any>): void {
        //$scope.columnVisibilityChanged = { name: changedColumn.colDef.name, visible: changedColumn.colDef.visible };
        const newColumnDefs = this.gridOptions.columnDefs;
        for (let i = 0; i < newColumnDefs.length; i++) {
            if (newColumnDefs[i].name === changedColumn.colDef.name) {
                //update just the visible property
                newColumnDefs[i].visible = changedColumn.colDef.visible;
                break;
            }
        }
    }

    private registerCellNavigateEvent(): void {
        if (this.gridApi.cellNav) this.gridApi.cellNav.on.navigate(this.scope, this.cellNavigateEventCallback.bind(this));
    }

    private cellNavigateEventCallback(newRowCol: uiGrid.cellNav.IRowCol<any>, oldRowCol: uiGrid.cellNav.IRowCol<any>): void {
        if (oldRowCol) {
            let oldElem = this.getElementByCell(oldRowCol).find('.ui-grid-cell-contents');
            oldElem.removeClass('ui-grid-custom-out-selected');

            if (oldRowCol.col.colDef.enableCellEdit) {
                oldElem.removeClass('ui-grid-custom-selected');
            }
        }
        let newElem = this.getElementByCell(newRowCol).find('.ui-grid-cell-contents');
        if (newRowCol.col.colDef.enableCellEdit) {
            newElem.addClass('ui-grid-custom-selected');
        } else {
            newElem.addClass('ui-grid-custom-out-selected');
        }
    }

    private registerCellEditEvents(): void {
        if (this.gridApi.edit) {
            this.gridApi.edit.on.beginCellEdit(this.scope, this.beginCellEditCallback.bind(this));
            this.gridApi.edit.on.afterCellEdit(this.scope, this.afterCellEditCallback.bind(this));
        }
    }

    private beginCellEditCallback(rowEntity: any, colDef: uiGrid.IColumnDefOf<any>, triggerEvent: JQueryEventObject): void {
        const focus = angular.element('.ui-grid-cell-focus');
        if (focus) focus.parent().css('overflow', 'visible');
    }

    private afterCellEditCallback(rowEntity: any, colDef: uiGrid.IColumnDefOf<any>, newValue: any, oldValue: any): void {
        //TODO: IMPLEMENT afterCellEditCallback
    }

    private saveGridState(): uiGrid.saveState.IGridSavedState {
        //restore save state options gridService standard before saving
        //this.gridOptions.saveFilter = true;
        //this.gridApi.core.notifyDataChange(this.gridConstants.dataChange.ALL);

        //this.notifyColumnsChange();
        //this.currentState = angular.copy(this.gridApi.saveState.save());
        //return this.currentState;

        return this.gridApi.saveState.save();
    }

    private loadGridState(state: uiGrid.saveState.IGridSavedState): void {
        if (!state) throw new Error('grid state is NULL');

        //TODO: IF EXTERNAL PAGINATION IS ENABLED, LETS REMOVE THE PROFILE SORT DATA
        /*         if (this.gridOptions.useExternalPagination) {
                    for (let i = 0; i < state.columns.length; i++) {
                        if (state.columns[i].sort) state.columns[i].sort = {};
                    }
                } */

        this.gridApi.saveState.restore(this.scope, state);
        //this.notifyDataChange();
    }

    public registerAfterCellEditObservers(context: any, callbackFunction: ICellEditCallback[]) {
        if (this.gridApi.edit && context && callbackFunction.length > 0) {
            for (let target of callbackFunction) {
                this.gridApi.edit.on.afterCellEdit(this.scope, (rowEntity: any, colDef: uiGrid.IColumnDefOf<any>, newValue: any, oldValue: any) => {
                    let gridInfo: ICellAfterEditParams = {
                        rowEntity: rowEntity,
                        colDef: colDef,
                        newValue: newValue,
                        oldValue: oldValue,
                        params: target.params
                    };
                    target.function.call(context, gridInfo);
                });
            }
        }
    }

    public registerAfterCellEditObserver(callback: IEditCallback) {
        try {
            if (!this.gridApi) return;

            if (!this.gridApi.edit) throw Error('Register uiEditGrid directive in your uiGrid Api');
            if (!callback) throw Error('Inform Callback to After Cell Register');

            this.gridApi.edit.on.afterCellEdit(this.scope, (rowEntity: any, colDef: uiGrid.IColumnDefOf<any>, newValue: any, oldValue: any) => {
                callback(rowEntity, colDef.name, newValue);
            });
        } catch (ex) {
            HandleError.exception(ex);
        }

    }

    public registerBeginCellEditObserver(callback: IEditCallback): void {
        try {
            if (!this.gridApi) return;

            if (!this.gridApi.edit) throw Error('Register uiEditGrid directive in your uiGrid Api');
            if (!callback) throw Error('Inform Callback to Begin Cell Register');

            this.gridApi.edit.on.beginCellEdit(this.scope, (rowEntity: any, colDef: uiGrid.IColumnDefOf<any>, event: JQueryEventObject) => {
                callback(rowEntity, colDef.name, event);
            });
        } catch (ex) {
            HandleError.exception(ex);
        }

    }

    private swapArrayElements(array, indexA, indexB): void {
        const temp = array[indexA];
        array[indexA] = array[indexB];
        array[indexB] = temp;
    };

    formateDate(dateString): string {
        if (!dateString) return;
        const date = dateString.split('-');
        const day = date[2].substring(0, 2);
        const month = date[1];
        const year = date[0];
        return day + '/' + month + '/' + year;
    }

    async refresh() {
        this.gridApi.core.queueGridRefresh();
    }

    clearFixedRow(): void {
        const rows = this.$gridApi.core.getVisibleRows(this.$gridApi.grid);

        const foundedFixedRow = rows.find(x => x['isFixedRow']);
        if (foundedFixedRow) foundedFixedRow['isFixedRow'] = false;
    }

    setFixedRow(_id: string): void {
        const rows = this.$gridApi.core.getVisibleRows(this.$gridApi.grid);

        const foundedFixedRow = rows.find(x => x['isFixedRow']);
        if (foundedFixedRow) foundedFixedRow['isFixedRow'] = false;

        const foundedRow = rows && rows.length ? rows.find(x => x.entity['_id'] == _id) : null;
        if (foundedRow) foundedRow['isFixedRow'] = true;
    }

    getGridHeight(addPx?: number): any {
        const window = angular.element(this.window);

        const topHeight = 134;
        const lineHeight = 30;
        const maxRows = 30;
        const minimumHeight = 300;

        let rowsCount = 0;
        let actualHeight = window.height() - topHeight;

        if (!this.gridOptions || !this.gridOptions.data || this.gridOptions.data.length === 0) {
            return { height: actualHeight + "px", value: actualHeight };
        }

        if (this.gridOptions && this.gridOptions.data && this.gridOptions.data.length) {
            rowsCount = this.gridOptions.data.length;
        }

        const rowsHeight = (rowsCount * lineHeight) + topHeight;

        if ((rowsCount > 0 && rowsCount < maxRows) && actualHeight > rowsHeight) {
            actualHeight = (rowsCount * lineHeight) + topHeight;
        }

        if (addPx || this.gridHeight !== 0) {
            if (!addPx && this.gridHeight !== 0) {
                actualHeight += this.gridHeight;
            } else if (addPx) {
                actualHeight += addPx;
            }
        }

        if (actualHeight < minimumHeight) {
            actualHeight = minimumHeight;
        }

        return { height: actualHeight + "px", value: actualHeight };
    }

    async addProfileModal(renameSelectedProfile?: boolean, copySelectedProfile?: boolean): Promise<void> {
        this.scope.isEdit = renameSelectedProfile;
        this.isCopy = copySelectedProfile;
        const modalID = this.ModalService.newModal();
        const modal = await this.ModalService.showModalInfo(
            {
                modalID: modalID,
                template: require("../app/view/template/grid-profile-modal.html"),
                scope: this.scope, //passed current scope to the modal
            },
            {
                actionButtonText: 'GENERAL.CLOSE',
                headerText: renameSelectedProfile ? 'GENERAL.PROFILE_EDIT' : copySelectedProfile ? this.getTranslate("GENERAL.PROFILE_ADD_COPY", { profile: this.gridOptions.profile.gridSelectedProfile.NAME }) : 'GENERAL.PROFILE_ADD',
            });
        modal.rendered.then(async () => {
            const modalScope = await this.ModalService.getModalScope(modalID);
            modalScope.newProfile = angular.copy(this.gridOptions.profile.gridSelectedProfile.NAME);
            this.scope.$applyAsync();
        });
    }

    async viewFilterProfileModal(): Promise<void> {
        const filters = this.gridOptions.columnDefs.filter(column => column.filter && column.filter.term != null && column.filter.term.trim() != "");
        if (filters && filters.length > 0) {
            const modalID = this.ModalService.newModal();
            this.ModalService.showModalInfo(
                {
                    modalID: modalID,
                    template: require("../app/view/template/grid-profile-filters-modal.html"),
                    scope: this.scope, //passed current state to the modal,
                    size: 'lg'
                },
                {
                    closeButtonText: 'GENERAL.CLOSE',
                    actionButtonText: 'GENERAL.CLEAN_FILTERS',
                    headerText: 'GENERAL.ACTIVE_FILTERS',
                });

            const modalScope = await this.ModalService.getModalScope(modalID);
            modalScope.filters = filters;
        } else {
            HandleError.warning(this.getTranslate("GENERAL.NO_ACTIVE_FILTERS_FOUND"));
        }
    }

    private async viewSmartFilterTutorial(): Promise<void> {
        try {
            const modalID = this.ModalService.newModal();
            this.ModalService.showModalInfo(
                {
                    modalID: modalID,
                    template: require("../app/view/template/filterTutorialModal.html"),
                    size: 'vlg',
                    animation: true,
                    modalFade: true,
                },
                {
                    headerText: 'GENERAL.SMARTFILTER_TUTORIAL',
                    actionButtonText: 'GENERAL.CONFIRM',
                });
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    private async loadProfileList(): Promise<void> {
        try {
            const profileList = await this.rootScope.getSetting(this.gridName);
            if (profileList && Object.keys(profileList).length > 0) {
                const profileKeys = Object.keys(profileList);
                this.gridOptions.gridProfileList = new Array<any>();

                //populate profile list selector
                for (let i = 0; i < profileKeys.length; i++) {
                    this.gridOptions.gridProfileList.push({ ID: (i + 1).toString(), NAME: profileKeys[i], IS_DEFAULT: profileList[profileKeys[i]].IS_DEFAULT ? profileList[profileKeys[i]].IS_DEFAULT : false });
                }

                //select the default or the last profile on the list
                const defaultProfileIndex = this.gridOptions.gridProfileList && this.gridOptions.gridProfileList.length ? this.gridOptions.gridProfileList.findIndex(profile => profile.IS_DEFAULT) : null;
                const lastProfileIndex = this.gridOptions.gridProfileList && this.gridOptions.gridProfileList.length ? (this.gridOptions.gridProfileList.length - 1) : null;
                const profileIndexToLoad = (defaultProfileIndex != null && defaultProfileIndex >= 0) ? defaultProfileIndex : lastProfileIndex;
                const profileToLoad = (profileIndexToLoad != null) ? this.gridOptions.gridProfileList[profileIndexToLoad] : null;
                if (profileToLoad && profileList[profileToLoad.NAME]) {
                    /*************************************************************************************************************************
                     DISABLED, FOR NOW TODO: TODO LUNELLI -- If filter in params are detected we must suppress the profile's saved filter, otherwise the params filter will be 
                     rendered useless by the restore() routine as it overwrites everything based on DB-stored profile.
                    *************************************************************************************************************************
                    if (this.$stateParams.filter)
                        this.gridOptions.saveFilter = false;*/

                    await this.loadProfileData(profileToLoad)
                    this.gridOptions.profile.gridSelectedProfile = this.gridOptions.gridProfileList[profileIndexToLoad];
                }
            }
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    private async loadProfileData(profile: object): Promise<void> {
        try {
            const profileName = profile['NAME'];
            const loadedProfile = await this.rootScope.getSetting(this.gridName, profileName);
            if (loadedProfile && loadedProfile[profileName]) {
                //await this.gridApi.core.clearAllFilters(false, true, true);
                //this.gridOptions.columnDefs = angular.copy(this.defaultColumns);
                //this.currentState = angular.copy(loadedProfile[profileName]);
                this.currentState = loadedProfile[profileName];
                this.loadGridState(this.currentState);
                await this.handleFilterChange();
                //await this.gridApi.saveState.restore(this.scope, this.currentState);

                //check for any valid profile filter saved to prevent loading default data on grid
                this.profiledFilter = this.currentState.columns.some(col => col.filters.some(fil => (fil && Object.keys(fil).length > 0 && !!fil.term)));
            }

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    private async editProfile(profileName: string, modalContext): Promise<void> {
        try {
            if (!profileName) return;

            this.blockUI.start();

            const profileListSettings = await this.rootScope.getSetting(this.gridName);
            if (profileListSettings && Object.keys(profileListSettings).length > 0) {
                const profileKeys = Object.keys(profileListSettings);
                for (let i = 0; i < profileKeys.length; i++) {
                    const oldKey = profileKeys[i];
                    if (oldKey.toString() == this.gridOptions.profile.gridSelectedProfile.NAME) {
                        Object.defineProperty(profileListSettings, profileName, Object.getOwnPropertyDescriptor(profileListSettings, oldKey));
                        delete profileListSettings[oldKey];
                    }
                }
            }
            const saved = await this.rootScope.updateSetting(this.gridName, profileListSettings, null, true, true);
            if (!saved) return HandleError.exception(`Failed to rename grid profile : ${profileName}`);
            else this.gridOptions.profile.gridSelectedProfile.NAME = profileName;

            //close the modal
            modalContext.ok();

            this.blockUI.stop();

        } catch (ex) {
            this.blockUI.reset();
            HandleError.exception(ex);
        }
    }

    private async addNewProfile(profileName: string, modalContext): Promise<void> {
        try {
            if (!profileName) return;

            this.blockUI.start();

            //save the new profile on the db
            const newProfileState = this.isCopy ? angular.copy(this.currentState) : this.defaultState;
            if (this.isCopy && newProfileState.IS_DEFAULT) newProfileState.IS_DEFAULT = false;
            await this.saveProfileSetting(profileName, this.isCopy ? newProfileState : this.defaultState);
            const newProfileIndex = this.gridOptions.gridProfileList.length + 1;
            this.gridOptions.gridProfileList.push({ ID: newProfileIndex.toString(), NAME: profileName });
            this.gridOptions.profile.gridSelectedProfile = this.gridOptions.gridProfileList[newProfileIndex - 1];
            await this.loadProfileData(this.gridOptions.gridProfileList[newProfileIndex - 1]);

            //close the modal
            modalContext.ok();

            this.blockUI.stop();

        } catch (ex) {
            this.blockUI.reset();
            HandleError.exception(ex);
        }
    }

    private async removeProfile(profileName: string): Promise<void> {
        try {
            if (!profileName) return;

            const checked = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: this.getTranslate('GENERAL.CONFIRM'),
                headerText: this.getTranslate('GENERAL.CONFIRM_ACTION'),
                bodyText: `${this.getTranslate('GENERAL.CONFIRM_REMOVE')} <span class="text-info"><b>${profileName}</b></span>?<br/><br/>`
            });
            if (checked) {
                const removed = await this.rootScope.removeSetting(this.gridName, profileName);
                if (!removed) return HandleError.exception(`Failed to remove grid profile: ${profileName}`);

                //load the last profile of the list
                const profileIndex = this.gridOptions.gridProfileList.findIndex(x => x.NAME === profileName);
                this.gridOptions.gridProfileList.splice(profileIndex, 1);
                const lastProfileIndex = (this.gridOptions.gridProfileList.length > 0) ? (this.gridOptions.gridProfileList.length - 1) : null;
                if (lastProfileIndex != null) {
                    this.gridOptions.profile.gridSelectedProfile = this.gridOptions.gridProfileList[lastProfileIndex];
                    await this.loadProfileData(this.gridOptions.gridProfileList[lastProfileIndex]);
                } else this.gridOptions.profile.gridSelectedProfile = null;

                this.scope.$applyAsync();
            }

        } catch (ex) {
            this.blockUI.reset();
            HandleError.exception(ex);
        }
    }

    private async gridSetProfileDefault(profileName: string): Promise<void> {
        try {
            if (!profileName) throw new Error('profileName is NULL');

            const profileListSettings = await this.rootScope.getSetting(this.gridName);
            if (profileListSettings && Object.keys(profileListSettings).length > 0) {
                const profileKeys = Object.keys(profileListSettings);
                for (let i = 0; i < profileKeys.length; i++) { profileListSettings[profileKeys[i]].IS_DEFAULT = profileKeys[i].toString() == profileName; }
            }
            const saved = await this.rootScope.updateSetting(this.gridName, profileListSettings, null, false, true);
            if (!saved) return HandleError.exception(`Failed to set grid profile default: ${profileName}`);
            else {
                for (const gridProfile of this.gridOptions.gridProfileList) { gridProfile.IS_DEFAULT = gridProfile.NAME == profileName; }
                this.notificationService.success(this.getTranslate("GENERAL.PROFILE_SET_AS_DEFAULT"));
            }
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    private async saveProfile(profileName: string): Promise<void> {
        try {
            if (!profileName) throw new Error('profileName is NULL');

            const profileData = this.saveGridState();

            for (const column of profileData.columns) {
                for (const filter of column.filters) {
                    if (!filter.term || filter.term.trim() == "") {
                        delete filter.term;
                    }
                }
            }

            this.loadGridState(profileData);

            await this.saveProfileSetting(profileName, profileData);

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    private async saveProfileSetting(profileName: string, profileData: any): Promise<void> {
        try {
            if (!profileName) throw new Error('profileName is NULL');
            if (!profileData) throw new Error('profileData is NULL');

            const saved = await this.rootScope.updateSetting(this.gridName, profileData, profileName);
            if (!saved) return HandleError.exception(`Failed to save grid profile: ${profileName}`);

            //update the current state
            this.currentState = profileData;

        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    private gridArrayCellTemplate(originalValue: any[], propertyName: string): string {
        if (!originalValue || !(originalValue instanceof Array) || !propertyName) return;
        const separator = ';';
        const allData = originalValue;
        let concatData = '';
        for (let i = 0; i < allData.length; i++) {
            if (typeof allData[i] === 'object' && allData[i][propertyName]) concatData += allData[i][propertyName] + separator + ' ';
            else if (typeof allData[i] !== 'object') concatData += allData[i] + separator + ' ';
        }
        const result = concatData.substring(0, (concatData.trim().length - 1));
        return result;
    }

    findDataById(id: string): any {
        return Array.from(this.gridOptions.data).find(x => x['_id'] === id);
    }

    //TODO: Create/Use the correct grid cell interface - uiGrid.cellNav.IRowCol<any
    getElementByCell(cell: any): any {
        const rowIndex = cell.row.grid.renderContainers.body.renderedRows.indexOf(cell.row);
        const element = angular.element('#' + cell.row.grid.id + '-' + rowIndex + '-' + cell.col.uid + '-cell');
        return element;
    }

    //filter select object and array of objects containing ID, NAME and CODE and boolean values with Sim and Não
    filterSelectObject(searchTerm: string, cellValue: any): boolean {
        if (cellValue instanceof Array && cellValue.length > 0) {
            return cellValue.some(x => (`${x.ID}${x.NAME}${(x.CODE) ? x.CODE : ''}`).toLowerCase().includes(searchTerm.toLowerCase()));
        } else if (cellValue instanceof Object) {
            return (`${cellValue.ID}${cellValue.NAME}${(cellValue.CODE) ? cellValue.CODE : ''}`).toLowerCase().includes(searchTerm.toLowerCase());
        } else if (typeof (cellValue) === 'string') return cellValue.toLowerCase().includes(searchTerm.toLowerCase());
        else if (typeof (cellValue) === 'boolean') return (cellValue === true && searchTerm.toLocaleLowerCase().startsWith('s')) || (cellValue === false && searchTerm.toLocaleLowerCase().startsWith('n'))

        return false;
    }

    filterDatetime(searchTerm: string, cellValue: Date): boolean {
        const value = moment(cellValue).format('DD[/]MM[/]YYYY HH:mm')
        return value.search(searchTerm) >= 0;
    }

    filterDatetimePicker(searchTerm: string, cellValue: Date): boolean {

        if (!cellValue) return false;

        const value = moment(cellValue).format('DD[/]MM[/]YYYY HH:mm')
        return value.search(searchTerm) >= 0;
    }

    public set RestrictiveSort(value: boolean) {
        this.restrictiveSort = value;
    }

    public set ShowTotalCount(value: boolean) {
        this.showTotalCount = value;
    }

    public set MaxQueryCriteria(value: number) {
        this.maxQueryCriteria = value;
    }

    public addActionColumn(name: string) {
        if (!name) return;
        this.actionColumns.push(name);
    }

    public async removeAllActionColumn() {
        this.actionColumns = [];
    }

    private async gridModalTemplateExcel(): Promise<void> {

        const modalParamsId = this.ModalService.newModal();
        const modal = await this.ModalService.showModalInfo(
            {
                modalID: modalParamsId,
                formService: 'register',
                size: 'md',
                scope: this.scope,
                template: require('../common/view/modals/excelTemplatesModal.html')
            }, {
            actionButtonText: 'GENERAL.CREATE_SPREADSHEET',
            closeButtonText: 'GENERAL.CLOSE',
            headerText: 'GENERAL.SELECT_TEMPLATE',
        }, { gridName: this.gridName });

        const selectedTemplate: IGridSpreadsheet = await modal.result.then(function (result) {
            return result.$value;
        }, function (result) {
            return result.$value;
        });

        if (selectedTemplate) {
            this.gridGenerateExcel(selectedTemplate);
        }
    }

    private async gridGenerateExcel(selectedTemplate: IGridSpreadsheet): Promise<void> {
        try {
            this.blockUI.start();

            const selectedRows = this.gridApi.selection.getSelectedRows();
            const paginationSize = this.gridOptions.paginationPageSize;
            const filteredColumns = this.orderColumnsToReport();
            const template = selectedTemplate.TEMPLATE.NAME;
            const displayName = selectedTemplate.DISPLAY_NAME;
            const fileName = moment().format("YYYY-MM-DD-HH-mm-ss") + '-' + displayName;

            if (!filteredColumns) return HandleError.exception(`No column was selected.`);
            await this.updateGridUserSetting(displayName, this.gridName);

            const result = await this.RestService.newObjectPromise(this.excelRoute, { template, displayName, gridName: this.gridName, gridSelectedRows: selectedRows, columns: filteredColumns, paginationSize }, 30000, false);
            if (!result) return HandleError.exception(`Failed to render excel template`);

            const file = new File([new Uint8Array(result.content.data)], fileName + '.xlsx', { type: result.meta.contentType, endings: result.meta.fileExternsion });
            const objectUrl = window.URL.createObjectURL(file);

            if (this.rootScope.isIE) {
                this.window.navigator.msSaveOrOpenBlob(result.content.data, fileName + '.xlsx');
            } else {
                if (!objectUrl) return HandleError.exception(`Failed to get object url`);
                let link = document.createElement('a');
                link.href = objectUrl;
                link.download = fileName + '.xlsx';
                link.click();
            }

        } catch (ex) {
            HandleError.exception(ex);
        } finally {
            this.unblock();
        }
    }

    public getTranslate(term: string, params?: object, makeFirstCharLowerCase?: boolean): string {
        let translated: string = term;
        try {
            if (term) translated = this.$filter("translate")(term, params);
            if (makeFirstCharLowerCase) translated = translated.charAt(0).toLowerCase() + translated.slice(1);
        } catch (ex) {
            console.log(`Error to translate(${term}): `, ex);
        } finally {
            return translated;
        }
    }

    private orderColumnsToReport(): uiGrid.saveState.ISavedColumn[] {

        const gridState = this.gridApi.saveState.save();
        const filtered = gridState.columns.filter(x => x.name != 'acoes' && x.name != 'selectionRowHeaderCol');
        const filteredAndWithDisplayName: IMonacoSavedColumn[] = [];

        if (filtered.length == 0) {
            return;
        }

        for (let index = 0; index <= filtered.length - 1; index++) {
            const reportColumn: IMonacoSavedColumn = {
                name: filtered[index].name,
                displayName: null,
                filters: filtered[index].filters,
                pinned: filtered[index].pinned,
                sort: filtered[index].sort,
                visible: filtered[index].visible,
                width: filtered[index].width,
                excelColDef: null,
                cellTemplate: null,
                cellFilter: null,
            }

            const gridColumn: IMonacoColumnDef = this.gridOptions.columnDefs.find(storedCol => storedCol.name == reportColumn.name);

            if (gridColumn) {
                reportColumn.excelColDef = gridColumn.excelDef;
                reportColumn.cellTemplate = gridColumn.cellTemplate;
                reportColumn.cellFilter = gridColumn.cellFilter;
                reportColumn.displayName = this.getTranslate(gridColumn.displayName);
            }

            filteredAndWithDisplayName.push(reportColumn);
        }

        return filteredAndWithDisplayName;
    }

    private async updateGridUserSetting(template: string, gridName: string): Promise<void> {

        const result: IGridSelectedTemplate[] = await this.rootScope.getSetting('GRID_SELECTED_TEMPLATE');

        if (!result) {
            const gridSelectedArray: IGridSelectedTemplate[] = [];
            const newSelectedTemplate: IGridSelectedTemplate = {
                GRID_NAME: gridName,
                TEMPLATE: template
            };
            gridSelectedArray.push(newSelectedTemplate);
            await this.rootScope.updateSetting('GRID_SELECTED_TEMPLATE', gridSelectedArray, null, false, true);
            return;
        }

        const getSelectedTemplate = result.find(x => x.GRID_NAME == gridName);
        if (getSelectedTemplate) {
            getSelectedTemplate.TEMPLATE = template;
            await this.rootScope.updateSetting('GRID_SELECTED_TEMPLATE', result, null, false, true);
        } else {
            const newSelectedTemplate: IGridSelectedTemplate = {
                GRID_NAME: gridName,
                TEMPLATE: template
            };
            result.push(newSelectedTemplate);
            await this.rootScope.updateSetting('GRID_SELECTED_TEMPLATE', result, null, false, true);
        }
        return;
    }
}
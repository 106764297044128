import * as angular from "angular";
import { IFloatingMenu, IFloatingOption, IFloatingInfo } from '../../common/interface/IFloatingMenu';

interface IFloatingMenuScope extends IFloatingMenu, ng.IScope {
  hasBtnOnTheRight: boolean;
  hasFavoriteOption: boolean;
  hasBtnActive: boolean;
  optionsOnTheRight: IFloatingOption[];
  ellipsizeReduceWidthToFitButtons?: string;  
  optionClick: (option: IFloatingOption) => void;
}

export function floatingMenuDirective() {
  let ddo: ng.IDirective = {
    restrict: "E",
    transclude: true,
    replace: true,
    template: require("../view/template/floating-menu.html"),
    scope: {
      formOperation: "@",
      bodyClass: "@",
      tooltipPlacement: "@",
      textTooltip: "@",
      ellipsizeReduceWidthToFitButtons: "@",
      infos: "=",
      options: "=",
      optionsLeft: "=",
      optionsModule: "=",
      optionsOnTheRight: "=",
      isFavorite: "=",
      fnFavorite: "&",
      btnActiveModel: "=",
      btnActiveDisabled: "=",
    },

    link: ($scope: IFloatingMenuScope, element: JQLite, attributes: ng.IAttributes) => {

      $scope.hasBtnOnTheRight = angular.isDefined($scope.optionsOnTheRight) ? $scope.optionsOnTheRight && $scope.optionsOnTheRight.length > 0 : angular.isDefined(attributes.btnActiveModel) && attributes.btnActiveModel.length > 0;
      $scope.hasFavoriteOption = angular.isDefined(attributes.isFavorite);
      $scope.hasBtnActive = angular.isDefined(attributes.btnActiveModel) && attributes.btnActiveModel.length ? true : false;

      $scope.optionClick = async (option: IFloatingOption | IFloatingInfo): Promise<void> => {
        if ($scope.$parent[option.click]) {
          if (angular.isArray(option.args)) await $scope.$parent[option.click](...option.args);
          else await $scope.$parent[option.click](option.args);
        }
      }

      $scope.$watch('btnActiveModel', function (newValue) {
        const model = $scope.$parent['model'];
        if (model && model.ACTIVE !== undefined) model.ACTIVE = newValue;
      }, true);
    }
  };
  return ddo;
}

import * as angular from 'angular';
import { IFile, UploadController } from "../../common/UploadController";
import { HandleError } from "../../common/util/HandleError";

export interface IMonacoUiUpload extends ng.IScope {
    clearAll: () => void,
    remove: (file: File) => void,

    template: string;
    uploading: boolean;
    files: Array<IFile>;
    id: string;
    context: string;
    $parent: any;

    uploadMessage: string;
}

monacoUiUpload.$inject = ['$timeout']
export function monacoUiUpload($timeout: ng.ITimeoutService): ng.IDirective {
    const ddo: ng.IDirective = {
        restrict: 'E',
        transclude: true,
        scope: {
            id: '@',
            name: '@',
            context: '@',
            filenameLimit: '@'
        },
        template: "<div ng-include='template'></div>",
        link: ($scope: IMonacoUiUpload, element: JQLite, attributes: ng.IAttributes, ngModel: ng.IController) => {
            $scope.template = 'uiUploadSingleTemplate';
            if (angular.isDefined(attributes.multiple)) $scope.template = 'uiUploadMultipleTemplate';

            //SIGNATURES
            $scope.files = [];
            $scope.clearAll = clearAll;
            $scope.remove = removeFile;
            $scope.uploading = false;
            $scope.uploadMessage = 'Solte aqui seu arquivo!';
            //THE PLACE WHERE MAGIC HAPPENS            
            const fileInput = document.getElementById(attributes.id);

            fileInput.onclick = validateNewFile;
            fileInput.ondrop = validateNewFile;
            fileInput.onchange = addFiles;

            //DESTROY
            $scope.$on('$destroy', function () {
                UploadController.Instance.removeAll();
                $scope.files = [];
            });

            function validateNewFile(event: Event) {
                if (!angular.isDefined(attributes.multiple)) {
                    if ($scope.uploading) {
                        event.preventDefault();
                        return HandleError.warning('Ja existe um upload de arquivos em andamento, aguarde!');
                    }

                    const currentFiles = UploadController.Instance.getFiles();
                    if (currentFiles.length > 0) {
                        event.preventDefault();
                        //return HandleError.warning('Apenas um arquivo pode ser selecionado!');
                    }
                }
            }

            //AUX
            function preventDups(file) {
                const selectedFiles = UploadController.Instance.getFiles();
                if (selectedFiles.length === 0) return true;

                if (file.type) {
                    return !selectedFiles.some(selectedFile => selectedFile.name === file.name);
                }

                return false;
            }


            async function addFiles(event: Event): Promise<void> {
                try {
                    //is this our file?
                    const id = $(event.target).attr('id');
                    if (id !== $scope.id) {
                        event.preventDefault();
                        return;
                    }

                    const newFiles: Array<File> = Array.from(event.target['files']);
                    $('input#' + attributes.id).val(null);

                    const validFiles = newFiles.filter(preventDups);
                    UploadController.Instance.addFiles(validFiles);
                    $scope.files = UploadController.Instance.getFiles();

                    await UploadController.Instance.startUpload();

                } catch (ex) {
                    HandleError.exception(`Failed to add files to upload: ${ex.message}`);
                }

            }

            function clearAll() {
                UploadController.Instance.removeAll();
            }

            function removeFile(file: File) {
                try {
                    UploadController.Instance.removeFile(file);
                    //$('input#' + attributes.id)[0]['files'];
                    //const files = Array.from($('input#' + attributes.id)[0]['files']);
                    //files.splice(this.files.indexOf(file), 1);
                    //$('input#' + attributes.id)[0]['files'] = files;
                    $('input#' + attributes.id).val(null);

                    //if (UploadController.Instance.getFiles().length === 0) $('input#' + attributes.id).val(null);
                } catch (ex) {
                    HandleError.exception(ex);
                }

            };
        }
    }

    return ddo;
}
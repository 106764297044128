import * as angular from "angular";
import { GridFormService, IGridFormController, IGridFormServiceScope, IMonacoRequestLog } from "@services/GridFormService";
import { IViewLog, ICustomLogProperties } from "WBA-Model/dist/interface/common/IViewLog";
import { IEdiPartner } from "WBA-Model/dist/interface/edi/EdiPartner";
import { ISessionService } from "@services/SessionService";
import { SSEService } from "@appServices/SSEService";
import { IRestService } from "@services/RestService";
import { EDIService } from "@services/EDIService";
import { EOperation } from "@enums/GenericData";
import { IMonacoColumnDef } from "@services/GridService2";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { ISelectorModel, SelectorModel } from "WBA-Model/dist/mongo/SelectorModel";
import { ProductService } from "@services/ProductService";
import { ENTITY } from "WBA-Model/dist/product/OfferCompiled";
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { HelperService } from "@services/HelperService";

export interface IMonacoRequest<T = any> {
    route?: string;
    operation?: string;
    data?: T;
    timeout?: number;
    user?: any;
}

interface IEDIPartnerScope extends IGridFormServiceScope {
    model: IEdiPartner;
    log: IViewLog;
    user: any;
    sessionService: ISessionService;
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IEdiPartner;
    productList: ISelectorModel[];
    customerList: ENTITY[];

    editEDIPartner: (EDIPartner: IEdiPartner) => Promise<void>;
    copyEDIPartner: (EDIPartner: IEdiPartner) => Promise<void>;
    viewEDIPartner: (EDIPartner: IEdiPartner) => Promise<void>;
    viewLogEDIPartner: (EDIPartner: IEdiPartner) => Promise<void>;
    fetchData: (id: number, action: string) => Promise<void>;
    getCustomerList: (search: string) => Promise<void>;
}

export class EDIPartnerRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IEDIPartnerScope;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private RestService: IRestService;
    private SSEService: SSEService;
    private gridName: string;
    private ediService: EDIService;
    private productService: ProductService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IEDIPartnerScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.RestService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.ediService = $injector.get('EDIService');
        this.productService = $injector.get('ProductService');
        this.gridName = 'GRID_EDI_PARTNER';
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.ediService.$route;
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'edipartner', 'GENERAL.MENU.EDI_PARTNER', true);
            this.initScopeFunctions();
            this.initModel();
            await this.initGrid(this.gridName, '/edipartner/list', true, true, 120000, true, true);
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editEDIPartner = async (EDIPartner: IEdiPartner): Promise<void> => {
            let blockedObject = {
                ID: EDIPartner.ID,
                NAME: EDIPartner.ID.toString(),
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || EDIPartner.ID !== this.$scope.model.ID) this.$scope.fetchData(EDIPartner.ID, EOperation.VIEW);
                } else if (this.$scope.operation !== EOperation.EDIT || EDIPartner.ID !== this.$scope.model.ID) {
                    this.$scope.fetchData(EDIPartner.ID, EOperation.EDIT);
                }
            };
        }

        this.$scope.viewEDIPartner = async (EDIPartner: IEdiPartner): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(EDIPartner);
        }

        this.$scope.viewLogEDIPartner = async (EDIPartner: IEdiPartner): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(EDIPartner);
        }

        this.$scope.copyEDIPartner = async (EDIPartner: IEdiPartner): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(EDIPartner.ID, EOperation.COPY);
        }

        this.$scope.getCustomerList = async (search: string): Promise<void> => {
            await this.getCustomer(search);
        }

        this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
            this.fetchData(id, action);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewEDIPartner(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editEDIPartner(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.setCopy(true);grid.appScope.copyEDIPartner(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogEDIPartner(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy),
            enableCellEdit: true,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colName: IMonacoColumnDef = { name: "NAME", displayName: "GENERAL.DESCRIPTION", width: 150 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 300, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PRODUCT, null, "NAME")}}</div>' };
            const colCustomer: IMonacoColumnDef = { name: "CUSTOMER.ID", displayName: "BASIC_DATA.CLIENT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.CUSTOMER, null, "NAME")}}</div>' }; /* Adicionar função concat */
            const colSequence: IMonacoColumnDef = { name: "SEQUENCE", displayName: "GENERAL.SEQUENCE", width: 100 };
            const colLastSequence: IMonacoColumnDef = { name: "LAST_SEQUENCE", displayName: "GENERAL.LAST_SEQUENCE", width: 100 };
            const colPartnerCode: IMonacoColumnDef = { name: "PARTNER_CODE", displayName: "GENERAL.PARTNER_CODE", width: 100 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'' };
            const colCreatedBy: IMonacoColumnDef = { name: "CREATED_BY.NAME", displayName: "GENERAL.CREATED_BY", width: 150 };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'' };
            const colUpdatedBy: IMonacoColumnDef = { name: "UPDATED_BY.NAME", displayName: "GENERAL.UPDATED_BY", width: 150 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 100, cellFilter: "YesOrNo" };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'NAME':
                        columnDefs.push(colName);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'CUSTOMER':
                        columnDefs.push(colCustomer);
                        break;
                    case 'SEQUENCE':
                        columnDefs.push(colSequence);
                        break;
                    case 'LAST_SEQUENCE':
                        columnDefs.push(colLastSequence);
                        break;
                    case 'PARTNER_CODE':
                        columnDefs.push(colPartnerCode);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'CREATED_BY':
                        columnDefs.push(colCreatedBy);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                    case 'UPDATED_BY':
                        columnDefs.push(colUpdatedBy);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            NAME: null,
            PRODUCT: null,
            CUSTOMER: null,
            SEQUENCE: null,
            LAST_SEQUENCE: null,
            PARTNER_CODE: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            ACTIVE: true
        }
    }

    initDependencies(): Promise<any> {
        const self: EDIPartnerRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getProductList(),
            ]).then((result: any) => {
                self.$scope.productList = result[0];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [

            {
                PROPERTY: 'ID',
                LABEL: 'GENERAL.IDENTIFIER'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'PRODUCT',
                LABEL: 'GENERAL.PRODUCT'
            },
            {
                PROPERTY: 'CUSTOMER',
                LABEL: 'BASIC_DATA.CLIENT'
            },
            {
                PROPERTY: 'SEQUENCE',
                LABEL: 'GENERAL.SEQUENCE'
            },
            {
                PROPERTY: 'LAST_SEQUENCE',
                LABEL: 'GENERAL.LAST_SEQUENCE'
            },
            {
                PROPERTY: 'PARTNER_CODE',
                LABEL: 'GENERAL.PARTNER_CODE'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            }
        ]
        return props;
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    private async getCustomer(search: string): Promise<void> {
        let result: ENTITY[] = [];
        try {
            if (search && search.length >= 3) {
                const rc = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/account/list/custom/`, { search: search }, 30000, false)
                if (rc && rc.length > 0) {
                    result = rc.map(x => {
                        return { ID: x.ID, 
                                NAME: x.NAME, 
                                ID_LEGAL_PERSON: x.ID, 
                                CODE: null, 
                                TRADING_NAME: null, 
                                TAXPAYER_NUMBER: null, 
                                SCAC: null, 
                                NUMBER_IATA: null,
                                ADDRESS: null, 
                                NETWORK:null, 
                                CORPORATE_NAME: null, 
                                ID_PHYSICAL_PERSON: null }
                    })
                };
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.customerList = result
        }
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];

    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW");
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT");
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            await this.clearFields(this.$scope.model, ['CONCATENATED']);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === "edit" ? "update" : "insert";

        return {
            route: `/edipartner/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: this.$scope.scopeBeforeSave
        };
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.ediService.$route}/edipartner/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) {
                    this.$scope.edit(model);
                } else if (action === GridColumnBuilderConstants.BTN_COPY) {
                    this.$scope.copy(model);
                } else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }
}
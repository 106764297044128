export abstract class NotificationConstants {
    public static readonly SAVE_SUCCESS = 'Cadastro <b>Salvo</b> com Sucesso';
    public static readonly UPDATE_SUCCESS = 'Cadastro <b>Atualizado</b> com Sucesso';
    public static readonly DELETE_SUCCESS = 'Cadastro <b>Removido</b> com Sucesso';
    public static readonly DELETE_ERROR = 'Cadastro <b>não</b> foi Removido';
    public static readonly CHANGE_ROUTE = 'Deseja realmente sair da página? Você vai perder suas alterações.';

    public static readonly EMPTY_SELECTOR_MSG = 'Nenhum valor encontrado';
    public static readonly INSERT_SELECTOR_MSG = 'GENERAL.CLICK_REGISTER';

    public static readonly SETTING_UPDATE_SUCCESS = 'GENERAL.MESSAGES.SETTING_UPDATE_SUCCESS';
    public static readonly SETTING_UPDATE_ERROR = 'GENERAL.MESSAGES.SETTING_UPDATE_ERROR';
    public static readonly SETTING_UPDATE_INVALID_SEARCH = 'GENERAL.MESSAGES.SETTING_UPDATE_INVALID_SEARCH';
    public static readonly SETTING_DELETE_SUCCESS = 'GENERAL.MESSAGES.SETTING_DELETE_SUCCESS';
    public static readonly SETTING_DELETE_ERROR = 'GENERAL.MESSAGES.SETTING_DELETE_ERROR';

    public static readonly PHONE_VALIDITY_INVALID = 'Número inválido';
    public static readonly PHONE_VALIDITY_SHORT = 'Muito curto';
    public static readonly PHONE_VALIDITY_LONG = 'Muito longo';
    public static readonly PHONE_VALIDITY_COUNTRY_CODE = 'Código do país inválido';
}
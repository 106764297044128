import * as angular from "angular";
import * as moment from 'moment';
import { fileUploader as FileUploader } from 'angular-file-upload';
import { IColumnDef, IGridOptions } from "ui-grid";
import { SSEService } from '@appServices/SSEService';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IModalService } from '@services/ModalService';
import { ISessionService } from "@services/SessionService";
import { ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IProviderModel, IProviderSelector } from "@models/interface/product/ProviderModel";
import { ITariffDetDemModel, IFreeTime, ITariffDetDemExchangeData } from "@models/interface/product/TariffDetDemModel";
import { IUploader, IUploadItem, IFileItem, ISuccessItemResponse } from "@models/interface/common/IMonacoUpload";
import { IEquipmentSelector } from "@models/interface/product/EquipmentModel";
import { IParamsDetDem, IParamsDetDemListRequest } from "@models/interface/product/ParamsDetDem";
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { EProviderTypeId, EOperation, EPaymentNatureId, EDetDemProviderType, ESpecSituation, EDDTypeTariff, ETariffFreightPerDiemType, EProductId } from "@enums/GenericData";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { SelectorModel } from "../../common/model/SelectorModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { ILegalPersonListCustomFilter, ILegalPersonModel } from "../model/LegalPersonModel";
import { ILinkParameter, IOfferParameter } from "../../common/model/ModelParameter";
import { StringUtil } from "../../common/util/StringUtil";
import { ValidateUtil } from "../../common/util/ValidateUtil";
import { IFloatingMenu } from "../../common/interface/IFloatingMenu";
import { IDocumentError } from "@models/interface/common/IDocumentError";
import { HelperService } from "@services/HelperService";
import { IEquipmentFreeTime } from "WBA-Model/dist/interface/product/TariffFreight";
import { ITablePerDiemQueryFields } from "WBA-Model/dist/interface/product/TablePerDiem";

interface IResponseData {
    result: boolean;
    data: string;
}

interface IResponse {
    data: IResponseData;
    message: string;
    reason: string;
    status: number
}

export interface IFormData {
    bucket: string;
    formName: string;
    folder: string;
}

export interface ITariffDetDemCode {
    ID: string;
    VALUE: string;
}

export interface IUsedOfferModal extends ng.IScope {
    tariffDetDem: ITariffDetDemModel;
    usedOfferList: IUsedOffer[];
    goToOffer: (offerNumber: string) => void;
}

export interface IUsedOffer {
    CODE: string;
    VALIDITY_START: any;
    VALIDITY_END: any;
    SITUATION: string;
}

interface ITariffDetDemScope extends IGridFormServiceScope {
    form: ng.IFormController;
    gridOptions: IGridOptions;
    model: ITariffDetDemModel;
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: ITariffDetDemModel;
    freightContractList: SelectorModel[];
    productList: SelectorModel[];
    paymentNatureList: SelectorModel[];
    directionList: SelectorModel[];
    providerList: IProviderSelector[];
    accountList: SelectorModel[];
    exporterList: SelectorModel[];
    importerList: SelectorModel[];
    networkList: SelectorModel[];
    tariffTypeList: SelectorModel[];
    eventList: SelectorModel[];
    situationList: SelectorModel[];
    currencyList: SelectorModel[];
    equipmentList: IEquipmentSelector[];
    commoditySectionList: SelectorModel[];
    paramsDDDetList: SelectorModel[];
    paramsDDDemList: SelectorModel[];
    paramsDDCombinedList: SelectorModel[];
    detDemProviderTypeList: SelectorModel[];
    equipmentProviderList: SelectorModel[];
    countryList: SelectorModel[];
    tablePerDiemList: SelectorModel[];
    perDiemTypeList: SelectorModel[];
    freeTimeTypeList: SelectorModel[];
    uploader: IUploader;
    user: any;
    menuFloating: IFloatingMenu;
    additionalIndexSelectorValidity: string[];
    sessionService: ISessionService;
    customEdit: (tariffDetDem: ITariffDetDemModel) => void;
    viewTariffDetDem: (tariffDetDemId: number) => Promise<void>;
    viewLogTariffDetDem: (tariffDetDem: ITariffDetDemModel) => Promise<void>;
    copyTariffDetDem: (tariffDetDem: ITariffDetDemModel) => Promise<void>;
    tariffTypeChange: (newValue: SelectorModel, oldValue: SelectorModel) => Promise<void>;
    equipmentSelect: (index: number) => void;
    collapseHeader: (elementId: string, state?: string) => void;
    initPanels: (open?: string[]) => void;
    removeUpload: (model: IUploadItem) => boolean;
    getProviderListByName: (search: string) => Promise<void>;
    getAccountListByName: (search: string) => Promise<void>;
    getExporterListByName: (search: string) => Promise<void>;
    getImporterListByName: (search: string) => Promise<void>;
    getNetworkListByName: (search: string) => Promise<void>;
    getGridViewProvider: (provider: IProviderSelector) => string;
    getCurrencyListByName: (search: string) => Promise<void>;
    getEquipmentListByName: (search: string) => Promise<void>;
    getFreeTimeTitle: (freeTime: IFreeTime) => string;
    getParamsDDDemList: () => Promise<void>;
    getParamsDDDetList: () => Promise<void>;
    getParamsDDCombinedList: () => Promise<void>;
    getEquipmentProviderByName: (search: string) => Promise<void>;
    getCountryListByName: (search: string) => Promise<void>;
    goToLegalPerson: (ids: string) => void;
    goToNetwork: () => void;
    goToAccount: () => void;
    goToParamsDetDem: (id: number) => void;
    goToProvider: (id: number) => void;
    goToCountry: (ids: string) => void;
    goToRoutingPoint: (ids: string) => void;
    goToCurrency: (id: number) => void;
    goToEquipment: (equipment: SelectorModel[]) => void;
    goToTablePerDiem: (id: number) => void;
    updateProduct: (selected: SelectorModel) => void;
    addFreeTimeDetDem: () => void;
    addFreeTimeDem: () => void;
    refreshDetDemParamsList: () => void;
    copyFreeTimeDem: (index: number) => void;
    limitLines: (value: string, limit: number) => string;
    generateConcatenated: () => void;
    updateDetDemList: () => void;
    formatConcatenatedChars: (value: string) => string;
    formatedScoreDate: () => string;
    checkDateValidity: (initialDate: Date, finalDate: Date) => void;
    openTariffUsedOffer: (tariffDetDem: ITariffDetDemModel) => Promise<void>;
    setCopy: (value: boolean) => void;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
    changeProviderType: () => void;
    changeFreeTimeReadonly: () => void;
    checkPerDiemTypeChange: () => void;
    getTablePerDiemList: () => void;
    addEquipmentFreeTime: () => void;
    checkReadOnlyFreeTime: (perDiemTypeId: string) => boolean;
    removeFreeTimeDetDem: (index: number) => void;
}

export class TariffDetDemRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: ITariffDetDemScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private $timeout: ng.ITimeoutService
    private ModalService: IModalService;
    private fileUploader: FileUploader;
    private formName: string;
    private gridName: string;
    private collapseState: string;
    private modalFormatedDataId: number;
    private SSEService: SSEService;
    private isCopy: boolean;
    private modalIntegrationErrorId: number;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: ITariffDetDemScope) {
        super($injector, $scope);
        this.formName = 'tariffDetDem';
        this.gridName = 'GRID_TARIFF_DET_DEM';
        this.$scope = $scope;
        this.$scope.additionalIndexSelectorValidity = ['$parentIndex'];
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.$timeout = $injector.get('$timeout');
        this.ModalService = $injector.get('ModalService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.fileUploader = $injector.get('FileUploader');
        this.collapseState = "hide";
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.helperService = $injector.get('HelperService');
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    getFisFilesGenericRoute(): string {
        const baseRoute = '/fis/filesGeneric';
        const uploadRoute = this.config.fisUrl + baseRoute;
        return uploadRoute;
    }

    async $onInit(): Promise<void> {
        try {
            this.$scope.uploader = this.getFileUploaderDefault();
            this.$baseUrl = this.getUrlProduct();

            this.$scope.menuFloating = this.getMenuFloatingDefault();

            this.$scope.customLogProperties = this.getCustomLogProperties();

            const commodityList = await this.getCommoditySectionList('hscode_section');
            this.$scope.commoditySectionList = (commodityList) ? commodityList : [];

            this.initForm(this, 'form', this.formName, 'GENERAL.MENU.TARIFF_DET_DEM', true);
            await this.initGrid(this.gridName, '/tariffDetDem/list', true, true, null, true, true);
            const sessionParameter = this.$gridService.$sessionParameter;
            if (sessionParameter && sessionParameter.data) this.callSessionFunctions(sessionParameter.data);

            this.SSEService.closeEvents();
            this.setCopy(false);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initDependencies(): Promise<any> {
        const self: TariffDetDemRegisterController = this;
        this.refreshDetDemParamsList();

        return new Promise(function (resolve, reject) {
            self.initDateFields();
            self.getParamsDDDetList();
            self.getParamsDDDemList();
            self.getParamsDDCombinedList();

            self.$q.all([
                self.getTypeTariffList(),
                self.getProductList(),
                self.getPaymentNatureList(),
                self.getDirectionList(),
                self.getEventList(),
                self.getSituationList(),
                self.getDetDemProviderTypeList(),
                self.getTariffFreightPerDiemTypeList(),
                self.getTariffFreightFreeTimeTypeList(),
            ]).then((result: any) => {
                self.$scope.tariffTypeList = result[0];
                self.$scope.productList = self.filterProducts(result[1]);
                self.$scope.paymentNatureList = result[2];
                self.$scope.directionList = result[3];
                self.$scope.eventList = result[4];
                self.$scope.situationList = result[5];
                self.$scope.detDemProviderTypeList = result[6];
                self.$scope.perDiemTypeList = result[7];
                self.$scope.freeTimeTypeList = result[8];

                if (self.$scope.situationList && self.$scope.situationList.length > 0 && !self.$scope.model.SITUATION) {
                    self.$scope.model.SITUATION = self.$scope.situationList.find(item => item.ID == '1');
                }
                if (self.$scope.tariffTypeList && self.$scope.tariffTypeList.length > 0 && !self.$scope.model.TARIFF_TYPE) {
                    self.$scope.model.TARIFF_TYPE = self.$scope.tariffTypeList.find(item => item.ID == '2');
                }
                if (self.$scope.eventList && self.$scope.eventList.length > 0 && !self.$scope.model.EVENT) {
                    self.$scope.model.EVENT = self.$scope.eventList.find(event => event.ID == '4');
                }
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.viewTariffDetDem = async (tariffDetDemId: number): Promise<void> => {
            this.SSEService.closeEvents();
            this.fetchData(tariffDetDemId, EOperation.VIEW);
        }

        this.$scope.customEdit = (tariffDetDem: ITariffDetDemModel): void => {
            this.editTariffDetDem(tariffDetDem);
        }

        this.$scope.viewLogTariffDetDem = async (tariffDetDem: ITariffDetDemModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(tariffDetDem);
        }

        this.$scope.copyTariffDetDem = async (tariffDetDem: ITariffDetDemModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.fetchData(tariffDetDem.ID, EOperation.COPY);
        }

        this.$scope.tariffTypeChange = async (newValue: SelectorModel, oldValue: SelectorModel): Promise<void> => {
            let proceed = true;
            if (newValue && oldValue) {
                this.$scope.model.TARIFF_TYPE = oldValue;
                proceed = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: 'GENERAL.CONFIRM',
                    closeButtonText: 'GENERAL.NO',
                    headerText: 'GENERAL.CONFIRM_ACTION',
                    bodyText: this.formService.getTranslate('GENERAL.ALL_DATA_WILL_BE_LOST_DETDEMTARIFF')
                });
            }
            if (proceed) {
                this.$scope.model.TARIFF_TYPE = newValue;
                this.addFreeTimeDetDem();
            }
        }
        this.$scope.equipmentSelect = (index: number): void => {

        }
        this.$scope.addFreeTimeDetDem = (): void => {
            this.addFreeTimeDetDem();
        }

        this.$scope.refreshDetDemParamsList = (): void => {
            this.refreshDetDemParamsList();
        }

        this.$scope.copyFreeTimeDem = (index: number): void => {
            this.copyFreeTimeDem(index);
        }
        this.$scope.goToCountry = (ids: string): void => {
            this.$scope.sessionService.openTab("app.registration.country", <ILinkParameter>{ ID: ids });
        }
        this.$scope.goToProvider = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.provider", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.$scope.goToAccount = (): void => {
            this.$scope.sessionService.openTab("app.commercial.account", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.ACCOUNTS, null, 'ID') });
        }
        this.$scope.goToParamsDetDem = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.paramsDetDem", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.$scope.goToLegalPerson = (ids: string): void => {
            this.$scope.sessionService.openTab("app.registration.legalPerson", <ILinkParameter>{ ID: ids });
        }
        this.$scope.goToNetwork = (): void => {
            this.$scope.sessionService.openTab("app.registration.network", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.NETWORKS, null, 'ID') });
        }
        this.$scope.goToRoutingPoint = (ids: string): void => {
            this.$scope.sessionService.openTab("app.registration.routingPoint", <ILinkParameter>{ ID: ids });
        }
        this.$scope.goToCurrency = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.currency", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.$scope.goToEquipment = (equipments: SelectorModel[]): void => {
            this.$scope.sessionService.openTab("app.registration.equipment", <ILinkParameter>{ ID: this.$scope.getCONCAT(equipments, null, "ID") });
        }

        this.$scope.goToTablePerDiem = (id: number): void => {
            this.$scope.sessionService.openTab("app.product.tablePerDiem", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        
        this.$scope.getGridViewProvider = (provider: IProviderSelector): string => {
            let gridValue = "";
            if (provider && provider.TYPE) {
                if (provider.TYPE.ID == EProviderTypeId.SHIPOWNER || provider.TYPE.ID == EProviderTypeId.AIRLINE) gridValue = provider.CODE;
                else if (provider.TYPE.ID == EProviderTypeId.SHIPPING_COMPANY) gridValue = provider.NAME;
            }
            return gridValue;
        }
        this.$scope.getProviderListByName = async (search: string): Promise<void> => {
            await this.getProviderListByName(search);
        }
        this.$scope.getAccountListByName = async (search: string): Promise<void> => {
            await this.getAccountListByName(search);
        }
        this.$scope.getExporterListByName = async (search: string): Promise<void> => {
            await this.getExporterListByName(search);
        }
        this.$scope.getImporterListByName = async (search: string): Promise<void> => {
            await this.getImporterListByName(search);
        }
        this.$scope.getNetworkListByName = async (search: string): Promise<void> => {
            await this.getNetworkListByName(search);
        }
        this.$scope.getCurrencyListByName = async (search: string): Promise<void> => {
            await this.getCurrencyListByName(search);
        }
        this.$scope.getEquipmentListByName = async (search: string): Promise<void> => {
            await this.getEquipmentListByName(search);
        }
        this.$scope.getFreeTimeTitle = (freeTime: IFreeTime): string => {
            return this.getFreeTimeTitle(freeTime);
        }
        this.$scope.getParamsDDDetList = async (): Promise<void> => {
            await this.getParamsDDDetList();
        }
        this.$scope.getParamsDDDemList = async (): Promise<void> => {
            await this.getParamsDDDemList();
        }
        this.$scope.getParamsDDCombinedList = async (): Promise<void> => {
            await this.getParamsDDCombinedList();
        }
        this.$scope.getEquipmentProviderByName = async (search: string): Promise<void> => {
            await this.getEquipmentProviderByName(search);
        }
        this.$scope.getCountryListByName = async (search: string): Promise<void> => {
            await this.getCountryListByName(search);
        }
        this.$scope.limitLines = (value: string, limit: number) => {
            return StringUtil.limitLines(value, limit);
        }
        this.$scope.removeUpload = (model: IUploadItem): boolean => {
            return this.removeUpload(model);
        }
        this.$scope.uploader.onSuccessItem = (item: IFileItem, response: ISuccessItemResponse): void => {
            this.onSuccessItem(item, response);
        }
        this.$scope.collapseHeader = (elementId: string, state?: string) => {
            this.collapseHeader(elementId, state);
        }
        this.$scope.initPanels = (open?: string[]): void => {
            this.initPanels(open);
        }
        this.$scope.updateProduct = (selected: SelectorModel): void => {
            this.updateProduct(selected);
        }
        this.$scope.generateConcatenated = (): void => {
            this.generateConcatenated();
        }
        this.$scope.updateDetDemList = (): void => {
            this.updateDetDemList();
        }
        this.$scope.formatConcatenatedChars = (value: string) => {
            return StringUtil.formatConcatenatedChars(value);
        }
        this.$scope.formatedScoreDate = (): string => {
            return this.formatedScoreDate();
        }

        this.$scope.checkDateValidity = (initialDate: Date, finalDate: Date): void => {
            this.checkDateValidity(initialDate, finalDate);
        }

        this.$scope.openTariffUsedOffer = async (tariffDetDem: ITariffDetDemModel): Promise<void> => {
            this.openTariffUsedOffer(tariffDetDem);
        }

        this.$scope.setCopy = (value: boolean): void => {
            this.setCopy(value);
        }

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }

        this.$scope.changeProviderType = () => {
            this.changeProviderType();
        }

        this.$scope.changeFreeTimeReadonly = () => {
            this.changeFreeTimeReadonly();
        }

        this.$scope.checkPerDiemTypeChange = () => {
            this.checkPerDiemTypeChange();
        }

        this.$scope.getTablePerDiemList = () => {
            this.getTablePerDiemList();
        }

        this.$scope.addEquipmentFreeTime = () => {
            this.addEquipmentFreeTime();
        }

        this.$scope.checkReadOnlyFreeTime = (perDiemTypeId: string) => {
            return this.checkReadOnlyFreeTime(perDiemTypeId);
        }

        this.$scope.removeFreeTimeDetDem = (index: number) => {
            this.removeFreeTimeDetDem(index);
        }
    }

    private async editTariffDetDem (tariffDetDem: ITariffDetDemModel): Promise<void> {
        let blockedObject = {
            ID: tariffDetDem.ID,
            NAME: tariffDetDem.CONCATENATED,
            EMAIL: this.$scope.user['email'],
            FORM_NAME: this.gridName
        };
        this.SSEService.closeEvents();
        this.SSEService.setBlockedObject(blockedObject);
        this.SSEService.initEvents();
        this.SSEService.events.onmessage = async (event) => {
            const parsedData = JSON.parse(event.data);
            if (!parsedData.status) {
                const result = await this.SSEService.generate(parsedData);
                if (result && !result.status) {
                    this.$rootScope.refreshPage();
                    return;
                }
                if (this.$scope.operation !== EOperation.VIEW || tariffDetDem.ID !== this.$scope.model.ID) this.fetchData(tariffDetDem.ID, EOperation.VIEW);
            } else if (this.$scope.operation !== EOperation.EDIT || tariffDetDem.ID !== this.$scope.model.ID) {
                this.fetchData(tariffDetDem.ID, EOperation.EDIT);
            }
        };
    }

    initGridColumns(columns: string[]): IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<a ng-click="grid.appScope.viewTariffDetDem(row.entity.ID)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true"><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.customEdit(row.entity)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true"><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogTariffDetDem(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true"><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.setCopy(true);grid.appScope.copyTariffDetDem(row.entity)" class="text-orange copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true"><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const offerGenerated = `<a ng-disabled="!row.entity.TARIFF_DET_DEM_USED_OFFER.length"><btn ng-click="row.entity.TARIFF_DET_DEM_USED_OFFER.length ? grid.appScope.openTariffUsedOffer(row.entity) : ''" class="text-info" ng-class="{'text-muted': !row.entity.TARIFF_DET_DEM_USED_OFFER.length}" tooltip-placement="auto top" uib-tooltip="{{row.entity.TARIFF_DET_DEM_USED_OFFER.length ? 'GENERAL.OFFER_VIEW' : 'GENERAL.NO_OFFERS_GENERATED_WITH_THIS_TARIFF' | translate }}" tooltip-append-to-body="true"><i class="fa fa-list-alt icon"></i></btn></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes", displayName: "GENERAL.ACTIONS",
            width: 125,
            cellTemplate: `<div class="text-center view-btn-action-bar"> ${view} ${edit} ${viewLog} ${copy} ${offerGenerated} ${modalIntegration} </div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };

        gridColumns.addColumn(colActions);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colSituation: IMonacoColumnDef = { name: "SITUATION.NAME", displayName: "GENERAL.SITUATION", width: 120 };
            const colConcatenated: IMonacoColumnDef = { name: "CONCATENATED", displayName: "GENERAL.CONCATENATED", width: 350 };
            const colVersion: IMonacoColumnDef = { name: "VERSION", displayName: "GENERAL.VERSION", width: 80 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 90 };
            const colDirection: IMonacoColumnDef = { name: "DIRECTION.NAME", displayName: "PRODUCT.WAY", width: 120 };
            const colCountry: IMonacoColumnDef = { name: "COUNTRY.NAME", displayName: "ROUTE.COUNTRY", width: 120 };
            const colProviderType: IMonacoColumnDef = { name: "PROVIDER_TYPE.NAME", displayName: "PRODUCT.PROVIDER_TYPE", width: 120 };
            const colEquipmentProvider: IMonacoColumnDef = { name: "EQUIPMENT_PROVIDER.NAME", displayName: "PRODUCT.EQUIPMENT_PROVIDER", width: 120 };
            const colPaymentNature: IMonacoColumnDef = { name: "PAYMENT_NATURE.NAME", displayName: "BASIC_DATA.TRANSACTION", width: 120 };
            const colProvider: IMonacoColumnDef = { name: "PROVIDER.NAME", displayName: "BASIC_DATA.PROVIDER", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getGridViewProvider(row.entity.PROVIDER)}}</div>' };
            const colAccount: IMonacoColumnDef = { name: "ACCOUNTS.NAME", displayName: "BASIC_DATA.CLIENT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNTS, null, "NAME")}}</div>' };
            const colAccountNameFantasy: IMonacoColumnDef = { name: "ACCOUNTS.FANTASY_NAME", displayName: "GENERAL.CUSTOMER_COMMERCIAL_NAME ", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNTS, null, "FANTASY_NAME")}}</div>' };
            const colExporter: IMonacoColumnDef = { name: "EXPORTERS.NAME", displayName: "BASIC_DATA.SHIPPER", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.EXPORTERS, null, "NAME")}}</div>' };
            const colExporterNameFantasy: IMonacoColumnDef = { name: "EXPORTERS.FANTASY_NAME", displayName: "GENERAL.SHIPPER_COMMERCIAL_NAME ", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.EXPORTERS, null, "FANTASY_NAME")}}</div>' };
            const colImporter: IMonacoColumnDef = { name: "IMPORTERS.NAME", displayName: "BASIC_DATA.CONSIGNEE", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.IMPORTERS, null, "NAME")}}</div>' };
            const colImporterNameFantasy: IMonacoColumnDef = { name: "IMPORTERS.FANTASY_NAME", displayName: "GENERAL.CNEE_COMMERCIAL_NAME", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.IMPORTERS, null, "FANTASY_NAME")}}</div>' };
            const colNetwork: IMonacoColumnDef = { name: "NETWORKS.NAME", displayName: "BASIC_DATA.NETWORK", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.NETWORKS, null, "NAME")}}</div>' };
            const colUsedOffer: IMonacoColumnDef = { name: "TARIFF_DET_DEM_USED_OFFER.OFFER_CODE_EXHIBITION", displayName: "BASIC_DATA.OFFER", width: 160, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TARIFF_DET_DEM_USED_OFFER, null, "OFFER_CODE_EXHIBITION")}}</div>' };
            const colTariffType: IMonacoColumnDef = { name: "TARIFF_TYPE.NAME", displayName: "PRODUCT.TARIFF_TYPE", width: 150 };
            const colEvent: IMonacoColumnDef = { name: "EVENT.NAME", displayName: "GENERAL.VALIDITY_BASIS", width: 120 };
            const colValidityStart: IMonacoColumnDef = { name: "DISPLAY_VALIDITY_START", displayName: "PRODUCT.VALIDITY_START", width: 150, cellFilter: "simpleDate" };
            const colValidityEnd: IMonacoColumnDef = { name: "DISPLAY_VALIDITY_END", displayName: "PRODUCT.VALIDITY_END", width: 150, cellFilter: "simpleDate" };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 80, cellFilter: "YesOrNo" };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'SITUATION':
                        columnDefs.push(colSituation);
                        break;
                    case 'CONCATENATED':
                        columnDefs.push(colConcatenated);
                        break;
                    case 'VERSION':
                        columnDefs.push(colVersion);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'DIRECTION':
                        columnDefs.push(colDirection);
                        break;
                    case 'ID_COUNTRY':
                        columnDefs.push(colCountry);
                        break;
                    case 'PROVIDER_TYPE':
                        columnDefs.push(colProviderType);
                        break;
                    case 'ID_EQUIPMENT_PROVIDER':
                        columnDefs.push(colEquipmentProvider);
                        break;
                    case 'PAYMENT_NATURE':
                        columnDefs.push(colPaymentNature);
                        break;
                    case 'ID_PROVIDER':
                        columnDefs.push(colProvider);
                        break;
                    case 'ACCOUNTS':
                        columnDefs.push(colAccount, colAccountNameFantasy);
                        break;
                    case 'EXPORTERS':
                        columnDefs.push(colExporter, colExporterNameFantasy);
                        break;
                    case 'IMPORTERS':
                        columnDefs.push(colImporter, colImporterNameFantasy);
                        break;
                    case 'NETWORKS':
                        columnDefs.push(colNetwork);
                        break;
                    case 'TARIFF_DET_DEM_USED_OFFER':
                        columnDefs.push(colUsedOffer);
                        break;
                    case 'TARIFF_TYPE':
                        columnDefs.push(colTariffType);
                        break;
                    case 'EVENT':
                        columnDefs.push(colEvent);
                        break;
                    case 'DISPLAY_VALIDITY_START':
                        columnDefs.push(colValidityStart);
                        break;
                    case 'DISPLAY_VALIDITY_END':
                        columnDefs.push(colValidityEnd);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            CONCATENATED: null,
            CONCATENATED_COMPLEMENT: null,
            VERSION: null,
            SCORE: null,
            SCORE_RULE: null,
            SCORE_DATE: null,
            SCORE_ERROR: null,
            CONTRACTS: null,
            PRODUCT: null,
            DIRECTION: null,
            ID_COUNTRY: null,
            COUNTRY: null,
            PROVIDER_TYPE: null,
            ID_EQUIPMENT_PROVIDER: null,
            EQUIPMENT_PROVIDER: null,
            PAYMENT_NATURE: null,
            PROVIDER: null,
            ID_PROVIDER: null,
            ACCOUNTS: null,
            EXPORTERS: null,
            IMPORTERS: null,
            NETWORKS: null,
            TARIFF_TYPE: null,
            EVENT: null,
            VALIDITY_START: null,
            DISPLAY_VALIDITY_START: null,
            VALIDITY_END: null,
            DISPLAY_VALIDITY_END: null,
            OBSERVATION: null,
            FREE_TIME_DET_DEM: {
                EQUIPMENT_FREE_TIME: null,
                FIXED_ADDITIONAL: null,
                FREE_TIME_TYPE: null,
                ID_TABLE_PER_DIEM: null,
                TABLE_PER_DIEM: null,
                PERCENTAGE_ADDITIONAL: null,
                PER_DIEM_TYPE: null,
            },
            FILES: null,
            FILES_REMOVE: null,
            FILES_NEW: null,
            COMMODITY_SECTION: null,
            SITUATION: null,
            PARAMS_DD_DET: null,
            PARAMS_DD_DEM: null,
            PARAMS_DD_COMBINED: null,
            ACTIVE: true,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            DOCUMENT_ERROR: null
        };
    }

    async saveSuccess(returnedData: IResponse): Promise<void> {
        if (returnedData && returnedData.data && returnedData.data.result === false && returnedData.data.data) {
            this.formService.notifyInfo(returnedData.data.data);
        }
    }

    async save(): Promise<boolean> {
        let proceed: boolean = true;
        try {
            if (this.$scope.uploader.queue && this.$scope.uploader.queue.length > 0) {
                proceed = false;
                this.handleError('As long as there are files in the queue, save procedure is not allowed!');
            }

            if (this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.RECEIVING && !this.$scope.model.ACCOUNTS && !this.$scope.model.EXPORTERS && !this.$scope.model.IMPORTERS) {
                proceed = false;
                this.handleError('At least one of the following must be informed: Account, Exporter or Importer.');
            }

            if (!this.$scope.model.FREE_TIME_DET_DEM.EQUIPMENT_FREE_TIME || this.$scope.model.FREE_TIME_DET_DEM.EQUIPMENT_FREE_TIME.length == 0) {
                proceed = false;
                this.handleError('No equipment was informed for Free Time.');
            }

            this.SSEService.closeEvents();
            this.setCopy(false);
        } catch (ex) {
            this.handleError(ex);
            proceed = false;
        }
        return proceed;
    }

    async register(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.initPanels(['collapseBasicData']);
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');

            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.disableElements(true);
            this.$scope.initPanels();
            this.initDateFields();
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.model.CONCATENATED) this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
            BrowserTitle.$id = this.$scope.model.CONCATENATED;

            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.initPanels(this.$scope.model.TARIFF_TYPE && this.$scope.model.TARIFF_TYPE.ID == "1" ? ['collapseFreeTimeDet', 'collapseFreeTimeDem'] : ['collapseFreeTimeDet']);
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.refreshDetDemParamsList();
            if (this.$scope.model.CONCATENATED) this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
            BrowserTitle.$id = this.$scope.model.CONCATENATED;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.initPanels();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            await this.clearFields(this.$scope.model, ['CONCATENATED', 'SCORE', 'SCORE_DATE', 'SCORE_RULE', 'SCORE_ERROR']);
            this.initDateFields();
            this.generateConcatenated();
            this.refreshDetDemParamsList();
            this.$scope.model.FILES = null;
            this.$scope.model.FILES_NEW = null;
            this.$scope.model.FILES_REMOVE = null;

            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        this.setCopy(false);
        return {
            route: `/tariffDetDem/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
        this.setCopy(false);
    }

    async copyTariffFromHistory(id: number) {
        try {
            const tariffDetDem = await this.getTariffDetDemHistoryById(id);
            if (tariffDetDem) {
                const model: ITariffDetDemModel = angular.copy(tariffDetDem);
                model.ID = null;
                model._id = null;
                this.setCopy(true);
                await this.$scope.copy(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async getTariffDetDemHistoryById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const tariffDetDem = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/tariffDetDemHistory/getById/${id}`, 30000, null, false);
            return tariffDetDem ? tariffDetDem.data : null;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }


    private async callSessionFunctions(data: object): Promise<void> {
        if (!data) return;

        const tariffDetDemExchangeData = <ITariffDetDemExchangeData>data;

        switch (tariffDetDemExchangeData.OPERATION) {
            case EOperation.VIEW:
                this.$scope.viewTariffDetDem(Number(tariffDetDemExchangeData.ID));
                break;
            case EOperation.NEW:
                if (!tariffDetDemExchangeData.ID) this.$rootScope.breadCrumb.action();
                else await this.copyTariffFromHistory(parseInt(tariffDetDemExchangeData.ID));
                break;
        }
    }

    private async getTypeTariffList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/dd_type_tariff`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getPaymentNatureList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/payment_nature`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getDirectionList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/direction`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getEventList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/event`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getSituationList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/registration_situation`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getDetDemProviderTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/det_dem_provider_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTariffFreightPerDiemTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/tariff_freight_per_diem_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTariffFreightFreeTimeTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/tariff_freight_free_time_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getCommoditySectionList(identifier: string): Promise<Array<SelectorModel>> {
        let resultList = new Array<SelectorModel>();

        const { data: generic } = await this.helperService.get(`/generic/getByIdentifier/${identifier}`, null, 15000);

        if ((generic.status !== 200) || (!generic.data)) return null;

        let selectorList = generic && generic.data ? generic.data : [];

        if (name) {
            selectorList = selectorList.filter(x => (x.VALUE == name || x.ALTERNATIVE == name));
        }

        for (const item of selectorList) {
            const selectorModel = <SelectorModel>{};
            selectorModel.ID = item.CODE;
            selectorModel.NAME = item.VALUE;
            selectorModel.CODE = item.ALTERNATIVE;

            resultList.push(selectorModel);
        }

        if (resultList.length > 0) {
            resultList = resultList.sort((x, y) => x.ID < y.ID ? -1 : 1);
        }

        return resultList;

    }

    private addFreeTimeDetDem(): void {
        try {
            if (!this.$scope.model.FREE_TIME_DET_DEM) {
                this.$scope.model.FREE_TIME_DET_DEM = {
                    EQUIPMENT_FREE_TIME: null,
                    FIXED_ADDITIONAL: null,
                    FREE_TIME_TYPE: null,
                    ID_TABLE_PER_DIEM: null,
                    TABLE_PER_DIEM: null,
                    PERCENTAGE_ADDITIONAL: null,
                    PER_DIEM_TYPE: null,
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private setCopy(value: boolean): void {
        this.isCopy = value;
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalIntegrationErrorId = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalIntegrationErrorId, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "Integration Product/Operation" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number, idSubject: string): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                const commodityRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/tariffDetDem/sendSync`, { "idTariffDetDem": id, "idSubject": idSubject }, 120000, false);
                if (commodityRequest) success = true;
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.SENDING_REQUEST_ERROR');
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const tariffDetDemData = await this.getTariffDetDemById(id);
                    if (row && tariffDetDemData && tariffDetDemData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = tariffDetDemData.DOCUMENT_ERROR;
                        documentError = tariffDetDemData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async getTariffDetDemById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/tariffDetDem/getById/${id}`, 30000, null, false);
            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async copyFreeTimeDem(index: number): Promise<void> {
        try {
            if (!index && index !== 0) throw Error('index is null');
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private changeProviderType(): void {            
        if (this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.PAYMENT) {
            const equipmentProvider = this.$scope.detDemProviderTypeList.find(x => x.ID == EDetDemProviderType.EQUIPMENT_PROVIDER);
            this.$scope.model.PROVIDER_TYPE = equipmentProvider;
            
            const perDiemTypeTable = this.$scope.perDiemTypeList.find(x => x.ID == ETariffFreightPerDiemType.TABLE);
            this.$scope.model.FREE_TIME_DET_DEM.PER_DIEM_TYPE = perDiemTypeTable;
        } else if (this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.RECEIVING) {
            this.$scope.model.PROVIDER_TYPE = null;
        }
        this.$timeout(() => { this.$scope.selectorValidity('providerType'); }, 100);
    }

    private changeFreeTimeReadonly(): void {
        if (this.$scope.model.FREE_TIME_DET_DEM.EQUIPMENT_FREE_TIME && this.$scope.model.FREE_TIME_DET_DEM.EQUIPMENT_FREE_TIME.length > 0) {
            for (const equipItem of this.$scope.model.FREE_TIME_DET_DEM.EQUIPMENT_FREE_TIME) {
                if (this.$scope.model.TARIFF_TYPE.ID == EDDTypeTariff.COMBINED_DET_DEM) {
                    equipItem.COMBINED_DET_DEM_MASTER_FREE_TIME = null;
                    equipItem.COMBINED_DET_DEM_HOUSE_FREE_TIME = null;
                }

                if (this.$scope.model.TARIFF_TYPE.ID == EDDTypeTariff.SPLITED_DET_DEM) {
                    equipItem.SPLITTED_DETENTION_MASTER_FREE_TIME = null;
                    equipItem.SPLITTED_DETENTION_HOUSE_FREE_TIME = null;
                    equipItem.SPLITTED_DEMURRAGE_MASTER_FREE_TIME = null;
                    equipItem.SPLITTED_DEMURRAGE_HOUSE_FREE_TIME = null;
                    
                }
            }
        }
    }

    private checkPerDiemTypeChange(): void {
        switch (this.$scope.model.FREE_TIME_DET_DEM.PER_DIEM_TYPE.ID) {
            case ETariffFreightPerDiemType.DEFAULT_ALLOG:
                this.$scope.model.FREE_TIME_DET_DEM.FIXED_ADDITIONAL = null;
                this.$scope.model.FREE_TIME_DET_DEM.PERCENTAGE_ADDITIONAL = null;
                this.$scope.model.FREE_TIME_DET_DEM.ID_TABLE_PER_DIEM = null;
                this.$scope.model.FREE_TIME_DET_DEM.TABLE_PER_DIEM = null;
                break;
            case ETariffFreightPerDiemType.TRANSFER_ALL:
                this.$scope.model.FREE_TIME_DET_DEM.FIXED_ADDITIONAL = null;
                this.$scope.model.FREE_TIME_DET_DEM.PERCENTAGE_ADDITIONAL = null;
                this.$scope.model.FREE_TIME_DET_DEM.ID_TABLE_PER_DIEM = null;
                this.$scope.model.FREE_TIME_DET_DEM.TABLE_PER_DIEM = null;
                break;
            case ETariffFreightPerDiemType.TRANSFER_PLUS_FIXED:
                this.$scope.model.FREE_TIME_DET_DEM.PERCENTAGE_ADDITIONAL = null;
                this.$scope.model.FREE_TIME_DET_DEM.ID_TABLE_PER_DIEM = null;
                this.$scope.model.FREE_TIME_DET_DEM.TABLE_PER_DIEM = null;
                break;
            case ETariffFreightPerDiemType.TRANSFER_PLUS_PERCENTAGE:
                this.$scope.model.FREE_TIME_DET_DEM.FIXED_ADDITIONAL = null;
                this.$scope.model.FREE_TIME_DET_DEM.ID_TABLE_PER_DIEM = null;
                this.$scope.model.FREE_TIME_DET_DEM.TABLE_PER_DIEM = null;
                break;
            case ETariffFreightPerDiemType.TABLE:
                this.$scope.model.FREE_TIME_DET_DEM.FIXED_ADDITIONAL = null;
                this.$scope.model.FREE_TIME_DET_DEM.PERCENTAGE_ADDITIONAL = null;
                break;
            default:
                this.formService.notifyError('No Per Diem Type ID selected.');
                break;
        }

        this.$timeout(() => {
            this.$scope.setDirtyFields([`det_dem_fixed_additional`, `det_dem_percentage_additional`]);
            this.$scope.selectorValidity(`per_diem_type`);
            this.$scope.selectorValidity(`free_time_type`);
            this.$scope.selectorValidity(`tablePerDiem`);
        }, 100);
    }

    private async getTablePerDiemList(): Promise<void> {
        let result: SelectorModel[] = [];
        this.formService.block();
        try {

            if (this.$scope.model.PAYMENT_NATURE && this.$scope.model.DIRECTION && this.$scope.model.COUNTRY) {
                const data: ITablePerDiemQueryFields = {
                    DIRECTION: this.$scope.model.DIRECTION.ID,
                    TRANSACTION: this.$scope.model.PAYMENT_NATURE.ID,
                    COUNTRY_CODE: this.$scope.model.COUNTRY.CODE,
                    ID_TABLE_TYPE: this.$scope.model.TARIFF_TYPE.ID,
                    ID_EQUIPMENT_PROVIDER: this.$scope.model.ID_EQUIPMENT_PROVIDER ? this.$scope.model.ID_EQUIPMENT_PROVIDER : null,
                    ACCOUNTS: this.$scope.model.ACCOUNTS ? this.$scope.model.ACCOUNTS.map(account => parseInt(account.ID)) : null,                    
                    EXPORTERS: this.$scope.model.EXPORTERS ? this.$scope.model.EXPORTERS.map(exporter => exporter.ID) : null,
                    IMPORTERS: this.$scope.model.IMPORTERS ? this.$scope.model.IMPORTERS.map(importer => importer.ID) : null,
                    DEFAULT: false,
                    ACTIVE: true
                };
                
                const tablesPerDiem = await this.RestService.newObjectPromise(`${this.$baseUrl}/tablePerDiem/listByFullName`, { data }, 30000, false);
                if (tablesPerDiem) result = tablesPerDiem.map(tablePerDiem => { return { ID: tablePerDiem.ID, NAME: tablePerDiem.CONCATENATED, CODE: null } });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.tablePerDiemList = result;
        }
    }

    private addEquipmentFreeTime(): void {
        try {
            const equipmentDetDemFreeTime: IEquipmentFreeTime = {
                EQUIPMENT: null,
                COMBINED_DET_DEM_HOUSE_FREE_TIME: null,
                COMBINED_DET_DEM_MASTER_FREE_TIME: null,
                SPLITTED_DEMURRAGE_HOUSE_FREE_TIME: null,
                SPLITTED_DEMURRAGE_MASTER_FREE_TIME: null,
                SPLITTED_DETENTION_HOUSE_FREE_TIME: null,
                SPLITTED_DETENTION_MASTER_FREE_TIME: null,
            };

            if (!this.$scope.model.FREE_TIME_DET_DEM) {
                this.$scope.model.FREE_TIME_DET_DEM = {
                    PER_DIEM_TYPE: null,
                    FREE_TIME_TYPE: null,
                    FIXED_ADDITIONAL: null,
                    PERCENTAGE_ADDITIONAL: null,
                    ID_TABLE_PER_DIEM: null,
                    TABLE_PER_DIEM: null,
                    EQUIPMENT_FREE_TIME: null
                }
            }

            if (!this.$scope.model.FREE_TIME_DET_DEM.EQUIPMENT_FREE_TIME) this.$scope.model.FREE_TIME_DET_DEM.EQUIPMENT_FREE_TIME = [];
            this.$scope.model.FREE_TIME_DET_DEM.EQUIPMENT_FREE_TIME.push(equipmentDetDemFreeTime);

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private checkReadOnlyFreeTime(perDiemTypeId: string): boolean {
        let readOnly = false;
        try {

            if (!this.$scope.model.PAYMENT_NATURE || !this.$scope.model.FREE_TIME_DET_DEM || !this.$scope.model.FREE_TIME_DET_DEM.PER_DIEM_TYPE) {
                readOnly = true;
            }

            if (this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.RECEIVING && this.$scope.model.FREE_TIME_DET_DEM
                && this.$scope.model.FREE_TIME_DET_DEM.PER_DIEM_TYPE && this.$scope.model.FREE_TIME_DET_DEM.PER_DIEM_TYPE.ID && this.$scope.model.FREE_TIME_DET_DEM.PER_DIEM_TYPE.ID != perDiemTypeId) {
                readOnly = true;
            }

            if (this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.PAYMENT) {
                readOnly = true;
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
        return readOnly;

    }

    private async removeFreeTimeDetDem(index: number) {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: `${this.formService.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")}`
            });
            if (!modal) return;

            if (this.$scope.model.FREE_TIME_DET_DEM.EQUIPMENT_FREE_TIME[index] && this.$scope.model.FREE_TIME_DET_DEM.EQUIPMENT_FREE_TIME.length > 0) {
                this.formService.block();
                this.$scope.model.FREE_TIME_DET_DEM.EQUIPMENT_FREE_TIME.splice(index, 1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();   
        }
    }


    private async getProviderListByName(search: string): Promise<void> {
        let result = [];
        try {

            if (search && search.length >= 2) {
                if (!this.$scope.model.PRODUCT) {
                    throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                }
                let types = ['1'];
                this.formService.block();
                const products = [this.$scope.model.PRODUCT.ID];
                const providers: IProviderModel[] = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/provider/list/custom`, { search, types, products }, 30000, false);

                result = providers ? providers.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.SCAC_IATA, TYPE: x.TYPE } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.providerList = result;
        }
    }

    private async getAccountListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const accounts = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/account/list/custom`, { search: search }, 30000, false);
                result = accounts ? accounts.map(x => { return { ID: x.ID, NAME: x.NAME } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.accountList = result;
            this.formService.unblock();
        }
    }

    private async getCurrencyListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const currencies = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/currency/list/custom`, { name: search }, 30000, false);
                result = (currencies && currencies) ? currencies.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.currencyList = result;
        }
    }

    private async getEquipmentListByName(search: string): Promise<void> {
        let result: IEquipmentSelector[] = [];
        try {
            if (search && search.length >= 2) {
                if (!this.$scope.model.PRODUCT) {
                    throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                }

                this.formService.block();

                const products = [this.$scope.model.PRODUCT.ID];
                const equipmentList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/equipment/list/custom`, { search, products }, 30000, false);
                result = equipmentList ? equipmentList.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, TEU: x.TEU, FEET: x.FEET } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.equipmentList = result;
        }
    }

    private async getExporterListByName(search: string): Promise<void> {
        let result = [];
        try {
            const exporterList: SelectorModel[] = [];
            const legalPersonList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.EXPORTER], search: search });
            if (legalPersonList) legalPersonList.map(legalPerson => { exporterList.push({ ID: legalPerson.ID.toString(), NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME }) });
            result = exporterList;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.exporterList = result;
        }
    }

    private async getImporterListByName(search: string): Promise<void> {
        let result = [];
        try {
            const importerList: SelectorModel[] = [];
            const legalPersonList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.IMPORTER], search: search });
            if (legalPersonList) legalPersonList.map(legalPerson => { importerList.push({ ID: legalPerson.ID.toString(), NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME }) });
            result = importerList;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.importerList = result;
        }
    }

    private async getEquipmentProviderByName(search: string): Promise<void> {
        let result = [];
        try {
            const equipmentProviderList: SelectorModel[] = [];
            const legalPersonList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.EQUIPMENT_PROVIDER], search: search });
            if (legalPersonList) legalPersonList.map(legalPerson => { equipmentProviderList.push({ ID: legalPerson.ID.toString(), NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME }) });
            result = equipmentProviderList;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.equipmentProviderList = result;
        }
    }

    private async getCountryListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();
                const countryList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/country/list/custom`, { search }, 30000, false);
                result = (countryList && countryList) ? countryList.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.countryList = result;
            this.formService.unblock();
        }
    }


    private async getLegalPersonListByName(legalPersonFilter: ILegalPersonListCustomFilter): Promise<ILegalPersonModel[]> {
        let result = [];
        try {
            if (legalPersonFilter.search && legalPersonFilter.search.length >= 3) {
                this.formService.block();
                result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/legalPerson/list/custom`, legalPersonFilter, 30000, false);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getNetworkListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const networks = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/network/listByLegalPersonSpecType`, { name: search, types: ['12'] }, 30000, false);
                result = networks ? networks.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.networkList = result;
        }
    }

    private async refreshDetDemParamsList(): Promise<void> {
        try {
            if (this.$scope.model.TARIFF_TYPE && this.$scope.model.TARIFF_TYPE.ID) {
                if (this.$scope.model.TARIFF_TYPE.ID == '2') {
                    await this.getParamsDDCombinedList();
                } else if (this.$scope.model.TARIFF_TYPE.ID == '1') {
                    await this.getParamsDDDetList();
                    await this.getParamsDDDemList();
                }
            }
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private async getParamsDDCombinedList(): Promise<void> {
        let result = [];
        try {
            this.formService.block();

            const accounts = this.$scope.model.ACCOUNTS ? this.$scope.model.ACCOUNTS.map(x => parseInt(x.ID)) : null;

            let equipments = [];

            if (this.$scope.model.FREE_TIME_DET_DEM && this.$scope.model.FREE_TIME_DET_DEM.EQUIPMENT_FREE_TIME && this.$scope.model.FREE_TIME_DET_DEM.EQUIPMENT_FREE_TIME.length > 0) {
                for (const det of this.$scope.model.FREE_TIME_DET_DEM.EQUIPMENT_FREE_TIME) {
                    if (det.EQUIPMENT) {
                        equipments = equipments.concat(det.EQUIPMENT.map(x => x.ID));
                    }
                }
            }

            const filter: IParamsDetDemListRequest = {
                country_data: {
                    country: [this.$scope.model.ID_COUNTRY]
                },
                product: this.$scope.model.PRODUCT ? this.$scope.model.PRODUCT.ID : null,
                type: '1',
                nature: this.$scope.model.PAYMENT_NATURE ? this.$scope.model.PAYMENT_NATURE.ID : null,
                direction: this.$scope.model.DIRECTION ? this.$scope.model.DIRECTION.ID : null,
                provider: this.$scope.model.ID_PROVIDER,
                accounts,
                equipment: equipments.length > 0 ? equipments : null
            }

            const paramsDetDem: IParamsDetDem[] = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/paramsDetDem/list/filter`, { data: filter }, 30000, false);
            result = paramsDetDem ? paramsDetDem.map(x => { return { ID: x.ID, NAME: `${x.PRODUCT.ID} - ${x.REFERENCE_START.NAME} - ${x.REFERENCE_END.NAME}` } }) : [];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.paramsDDCombinedList = result;
            this.formService.unblock();
        }
    }

    private async getParamsDDDetList(): Promise<void> {
        let result = [];
        try {
            this.formService.block();

            const accounts = this.$scope.model.ACCOUNTS ? this.$scope.model.ACCOUNTS.map(x => parseInt(x.ID)) : null;
            let equipments = [];

            if (this.$scope.model.FREE_TIME_DET_DEM && this.$scope.model.FREE_TIME_DET_DEM.EQUIPMENT_FREE_TIME && this.$scope.model.FREE_TIME_DET_DEM.EQUIPMENT_FREE_TIME.length > 0) {
                for (const det of this.$scope.model.FREE_TIME_DET_DEM.EQUIPMENT_FREE_TIME) {
                    if (det.EQUIPMENT) {
                        equipments = equipments.concat(det.EQUIPMENT.map(x => x.ID));
                    }
                }
            }

            const filter: IParamsDetDemListRequest = {
                country_data: {
                    country: [this.$scope.model.ID_COUNTRY]
                },
                product: this.$scope.model.PRODUCT ? this.$scope.model.PRODUCT.ID : null,
                type: '2',
                nature: this.$scope.model.PAYMENT_NATURE ? this.$scope.model.PAYMENT_NATURE.ID : null,
                direction: this.$scope.model.DIRECTION ? this.$scope.model.DIRECTION.ID : null,
                provider: this.$scope.model.ID_PROVIDER,
                accounts,
                equipment: equipments.length > 0 ? equipments : null
            }

            const paramsDetDem: IParamsDetDem[] = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/paramsDetDem/list/filter`, { data: filter }, 30000, false);

            result = paramsDetDem ? paramsDetDem.map(x => { return { ID: x.ID, NAME: `${x.PRODUCT.ID} - ${x.REFERENCE_START.NAME} - ${x.REFERENCE_END.NAME}` } }) : [];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.paramsDDDetList = result;
            this.formService.unblock();
        }
    }

    private async getParamsDDDemList(): Promise<void> {
        let result = [];
        try {
            this.formService.block();

            const accounts = this.$scope.model.ACCOUNTS ? this.$scope.model.ACCOUNTS.map(x => parseInt(x.ID)) : null;
            let equipments = [];

            if (this.$scope.model.FREE_TIME_DET_DEM && this.$scope.model.FREE_TIME_DET_DEM.EQUIPMENT_FREE_TIME && this.$scope.model.FREE_TIME_DET_DEM.EQUIPMENT_FREE_TIME.length > 0) {
                for (const det of this.$scope.model.FREE_TIME_DET_DEM.EQUIPMENT_FREE_TIME) {
                    equipments = equipments.concat(det.EQUIPMENT.map(x => x.ID));
                }
            }

            const filter: IParamsDetDemListRequest = {
                country_data: {
                    country: [this.$scope.model.ID_COUNTRY]
                },
                product: this.$scope.model.PRODUCT ? this.$scope.model.PRODUCT.ID : null,
                type: '3',
                nature: this.$scope.model.PAYMENT_NATURE ? this.$scope.model.PAYMENT_NATURE.ID : null,
                direction: this.$scope.model.DIRECTION ? this.$scope.model.DIRECTION.ID : null,
                provider: this.$scope.model.ID_PROVIDER,
                accounts,
                equipment: equipments.length > 0 ? equipments : null
            }

            const paramsDetDem: IParamsDetDem[] = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/paramsDetDem/list/filter`, { data: filter }, 30000, false);
            result = paramsDetDem ? paramsDetDem.map(x => { return { ID: x.ID, NAME: `${x.PRODUCT.ID} - ${x.REFERENCE_START.NAME} - ${x.REFERENCE_END.NAME}` } }) : [];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.paramsDDDemList = result;
            this.formService.unblock();
        }
    }

    private initDateFields(): void {
        try {
            this.$scope.model.DISPLAY_VALIDITY_START = (this.$scope.model.DISPLAY_VALIDITY_START != null) ? new Date(this.$scope.model.DISPLAY_VALIDITY_START) : null;
            this.$scope.model.VALIDITY_START = (this.$scope.model.VALIDITY_START != null) ? new Date(this.$scope.model.VALIDITY_START) : null;
            this.$scope.model.DISPLAY_VALIDITY_END = (this.$scope.model.DISPLAY_VALIDITY_END != null) ? new Date(this.$scope.model.DISPLAY_VALIDITY_END) : null;
            this.$scope.model.VALIDITY_END = (this.$scope.model.VALIDITY_END != null) ? new Date(this.$scope.model.VALIDITY_END) : null;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'tariff_files', formName: this.formName, folder: this.formName };
            return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData] });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private removeUpload(model: IUploadItem): boolean {
        let result = false;
        try {
            if (!model) throw Error('item parameter is null');

            const isAdminOrCoordinator = (this.$scope.user.roles && (this.$scope.user.roles.hasOwnProperty('ADMIN') || this.$scope.user.roles.hasOwnProperty('COORDINATOR')));

            if (isAdminOrCoordinator) {
                result = true;
            } else {
                // check if in model.FILES_NEW;
                if (this.$scope.model.FILES_NEW && this.$scope.model.FILES_NEW.length > 0) {
                    const index = this.$scope.model.FILES_NEW.findIndex(file => { return file.FILE_HASH == model.FILE_HASH });
                    if (index > -1) {
                        // Remove from FILES_NEW
                        this.$scope.model.FILES_NEW.splice(index, 1);
                        result = true;
                    }
                }
            }

            if (result) {
                if (!this.$scope.model.FILES_REMOVE) this.$scope.model.FILES_REMOVE = [];
                this.$scope.model.FILES_REMOVE.push(model);
            } else {
                throw Error('You don\'t have permission to remove this file');
            }
        } catch (ex) {
            result = false;
            this.handleError(ex);
        } finally {
            return result;
        }
    }

    private onSuccessItem(item: IFileItem, response: ISuccessItemResponse): void {
        try {
            if (!item) throw Error('item parameter is null');
            if (!response) throw Error('response parameter is null');

            if (response.data && response.data.length > 0) {
                if (!this.$scope.model.FILES_NEW) this.$scope.model.FILES_NEW = [];

                for (let dataItem of response.data) {
                    const file: IUploadItem = {
                        FILE_DISPLAY_NAME: dataItem.displayFileName,
                        FILE_NAME: dataItem.fileName,
                        FILE_TYPE: dataItem.fileType,
                        FILE_PATH: dataItem.filePath,
                        FILE_URL: dataItem.fileUrl,
                        FILE_HASH: dataItem.fileHash,
                        FILE_SIZE: dataItem.fileSize,
                        FORM_NAME: dataItem.formName
                    }
                    this.$scope.model.FILES_NEW.push(file)
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private filterProducts(data: SelectorModel[]): SelectorModel[] {
        try {
            if (!data) throw Error('data is null');
            return data.filter(item => item.ID == 'EM' || item.ID == 'IM');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getMenuFloatingDefault(): IFloatingMenu {
        return {
            tooltipPlacement: "auto bottom",
            textTooltip: "GENERAL.DETDEM_TARIFF_DATA",
            infos: [
                { text: "PRODUCT.DD_TARIFF", class: "text-rouge font-bold" }
            ],
            options: [
                { click: "collapseHeader", args: ['collapseBasicData'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.BASIC_DATA", iconClass: "fa fa-archive", iconBodyClass: "text-brown" },
                //{ click: "collapseHeader", args: ['collapseFreeTime'], tooltipPlacement: "auto bottom", textTooltip: "Free Time e Per Diem", iconClass: "fa fa-clock-o", iconBodyClass: "text-brown" },
                { click: "collapseHeader", args: ['collapseFiles'], tooltipPlacement: "auto bottom", textTooltip: "REGISTRATION.EMAIL_ATTACHMENT", iconClass: "fa fa-file", iconBodyClass: "text-orange" },
                { click: "collapseHeader", args: ['collapseObservation'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.REMARKS", iconClass: "fa fa-arrows-alt", iconBodyClass: "text-blue" },
                { click: "collapseHeader", args: ['collapseAll'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.COLLAPSE_EXPAND_ALL", iconClass: "fa fa-expand", iconBodyClass: "text-danger" }
            ],
            btnActiveDisabled: false
        };
    }

    private collapseHeader(elementId: string, state?: string): void {
        try {
            if (elementId === "collapseAll" || elementId[0] === "collapseAll") {
                this.collapseState = this.collapseState == "hide" ? "show" : "hide";
                $('.toggle-me')["collapse"](state ? state : this.collapseState);
            } else if (elementId != "registerBody") {
                $("#" + elementId)["collapse"](state ? state : 'toggle');
            }
            this.$scope.navigateBetweenIds(elementId);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private initPanels(open: string[] = ['collapseBasicData']): void {
        try {
            const panels = document.getElementsByClassName("toggle-me");
            if (panels && panels.length > 0) {
                for (const item of open) {
                    $("#" + item)["collapse"]("show");
                }
                this.$scope.navigateBetweenIds(open[0]);
                for (let i = 0; i < panels.length; i++) {
                    const panel = panels[i];
                    if (open.indexOf(panel.id) === -1) {
                        $("#" + panel.id)["collapse"]("hide");
                    }
                }
            }
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private updateProduct(selected: SelectorModel): void {
        try {
            if (!selected) throw Error('selected is null');

            this.$scope.model.PROVIDER = null;
            if (this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.ID && !this.$scope.model.EVENT) {
                if (this.$scope.model.PRODUCT.ID == 'EM') {
                    const filterEvent = this.$scope.eventList.find(e => { return e.ID == '4' });
                    this.$scope.model.EVENT = filterEvent;
                } else {
                    const filterEvent = this.$scope.eventList.find(e => { return e.ID == '7' });
                    this.$scope.model.EVENT = filterEvent;
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private generateConcatenated(): void {
        try {
            const separator = " | ";
            let concatenated = "";

            if (this.$scope.model.ACCOUNTS && this.$scope.model.ACCOUNTS.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.ACCOUNTS.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(this.$scope.model.ACCOUNTS.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.PROVIDER) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PROVIDER.TYPE && (this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.SHIPOWNER || this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.AIRLINE) ? this.$scope.model.PROVIDER.CODE : this.$scope.model.PROVIDER.NAME) : concatenated.concat(this.$scope.model.PROVIDER.TYPE && (this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.SHIPOWNER || this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.AIRLINE) ? this.$scope.model.PROVIDER.CODE : this.$scope.model.PROVIDER.NAME);
            }

            if (this.$scope.model.CONTRACTS) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CONTRACTS.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(this.$scope.model.CONTRACTS.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.NETWORKS && this.$scope.model.NETWORKS.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.NETWORKS.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(this.$scope.model.NETWORKS.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.IMPORTERS && this.$scope.model.IMPORTERS.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.IMPORTERS.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(this.$scope.model.IMPORTERS.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.EXPORTERS && this.$scope.model.EXPORTERS.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.EXPORTERS.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(this.$scope.model.EXPORTERS.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID && this.$scope.model.PAYMENT_NATURE.ID.length > 0) {
                let paymentNature = null;
                if (this.$scope.model.PAYMENT_NATURE.ID === '1') {
                    paymentNature = 'PGTO';
                } else if (this.$scope.model.PAYMENT_NATURE.ID === '2') {
                    paymentNature = 'RCTO';
                }
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, paymentNature) : concatenated.concat(paymentNature);
            }

            if (this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.ID && this.$scope.model.PRODUCT.ID.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PRODUCT.ID) : concatenated.concat(this.$scope.model.PRODUCT.ID);
            }

            if (this.$scope.model.DIRECTION && this.$scope.model.DIRECTION.ID && this.$scope.model.DIRECTION.ID.length > 0) {
                let direction = null;
                if (this.$scope.model.DIRECTION.ID === '1') {
                    direction = 'OR';
                } else if (this.$scope.model.DIRECTION.ID === '2') {
                    direction = 'DE';
                }
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, direction) : concatenated.concat(direction);
            }

            if (this.$scope.model.COUNTRY && this.$scope.model.COUNTRY.ID) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.COUNTRY.CODE) : concatenated.concat(this.$scope.model.COUNTRY.CODE);
            }

            if (this.$scope.model.CONCATENATED_COMPLEMENT && this.$scope.model.CONCATENATED_COMPLEMENT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CONCATENATED_COMPLEMENT) : concatenated.concat(this.$scope.model.CONCATENATED_COMPLEMENT);
            }

            if (this.$scope.model.VERSION) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, 'V' + this.$scope.model.VERSION) : concatenated.concat('V' + this.$scope.model.VERSION);
            }

            if (this.$scope.model.DISPLAY_VALIDITY_START) {
                const validity = this.$scope.model.DISPLAY_VALIDITY_END ? moment(this.$scope.model.DISPLAY_VALIDITY_START).format('DD/MM/YY') + '-' + moment(this.$scope.model.DISPLAY_VALIDITY_END).format('DD/MM/YY') : moment(this.$scope.model.DISPLAY_VALIDITY_START).format('DD/MM/YY');
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, validity) : concatenated.concat(validity);
            }

            this.$scope.model.CONCATENATED = StringUtil.formatConcatenatedChars(concatenated);

            if (this.$scope.menuFloating) this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private updateDetDemList(): void {
        try {
            this.$scope.model.PARAMS_DD_COMBINED = null;
            this.$scope.model.PARAMS_DD_DET = null;
            this.$scope.model.PARAMS_DD_DEM = null;

            this.$scope.getParamsDDCombinedList();
            this.$scope.getParamsDDDetList();
            this.$scope.getParamsDDDemList();

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private formatedScoreDate(): string {
        try {
            if (this.$scope.model.SCORE_DATE) {
                return moment(this.$scope.model.SCORE_DATE).format('DD/MM/YYYY HH:mm:ss');
            }
            return null;
        } catch (ex) {
            this.handleError(ex);
            return null;
        }
    }

    private getFreeTimeTitle(freeTime: IFreeTime): string {
        let title = "";
        try {
            if (freeTime) {
                if (freeTime.EQUIPMENT_FREE_TIME && freeTime.EQUIPMENT_FREE_TIME.length > 0) {
                    const equipments = this.$scope.getCONCAT(freeTime.EQUIPMENT_FREE_TIME, null, "CODE", null, null, ',');
                    title = title.concat(equipments);
                }
                if (freeTime.EQUIPMENT_FREE_TIME) title = title && title.length > 0 ? title.concat(' - ', freeTime.EQUIPMENT_FREE_TIME.toString()) : freeTime.EQUIPMENT_FREE_TIME.toString();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
        return title;
    }

    private isNumberKey(evt: JQueryEventObject) {
        var charCode = (evt.which) ? evt.which : evt.keyCode
        if (charCode > 31 && (charCode < 48 || charCode > 57))
            return false;
        return true;
    }

    private isEmptyArray(arrayObj: any[]): boolean {
        return (arrayObj && arrayObj.length == 0 || !arrayObj);
    }

    private checkDateValidity(initialDate: Date, finalDate: Date): void {
        let isValid = false;
        if (!initialDate || typeof initialDate == "string") return;
        if (!finalDate || typeof finalDate == "string") return;
        isValid = ValidateUtil.isValidDateRange(initialDate, finalDate);
        if (!isValid) {
            this.$scope.model.DISPLAY_VALIDITY_END = null;
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: "CONCATENATED",
                LABEL: "GENERAL.CONCATENATED"
            },
            {
                PROPERTY: "PAYMENT_NATURE",
                LABEL: "BASIC_DATA.TRANSACTION"
            },
            {
                PROPERTY: "PRODUCT",
                LABEL: "BASIC_DATA.PRODUCT"
            },
            {
                PROPERTY: "DIRECTION",
                LABEL: "GENERAL.TYPE"
            },
            {
                PROPERTY: "PROVIDER",
                LABEL: "BASIC_DATA.PROVIDER"
            },
            {
                PROPERTY: "ACCOUNTS",
                LABEL: "BASIC_DATA.CLIENT"
            },
            {
                PROPERTY: "EXPORTERS",
                LABEL: "BASIC_DATA.SHIPPER"
            },
            {
                PROPERTY: "IMPORTERS",
                LABEL: "BASIC_DATA.CONSIGNEE"
            },
            {
                PROPERTY: "OBSERVATION",
                LABEL: "GENERAL.REMARKS"
            },
            {
                PROPERTY: "EQUIPMENT",
                LABEL: "GENERAL.EQUIPMENT"
            },
            {
                PROPERTY: "CURRENCY",
                LABEL: "GENERAL.CURRENCY"
            },
            {
                PROPERTY: "EVENT",
                LABEL: "GENERAL.VALIDITY_BASIS"
            },
            {
                PROPERTY: "TRADE_LANE_ORIGIN",
                LABEL: "BASIC_DATA.ORIGIN_TRADE_LANE"
            },
            {
                PROPERTY: "TRADE_LANE_DESTINATION",
                LABEL: "BASIC_DATA.DESTINATION_TRADE_LANE"
            },
            {
                PROPERTY: "TRADE_ORIGIN",
                LABEL: "BASIC_DATA.ORIGIN_TRADE_GROUP"
            },
            {
                PROPERTY: "TRADE_DESTINATION",
                LABEL: "BASIC_DATA.DESTINATION_TRADE_GROUP"
            },
            {
                PROPERTY: "COUNTRY_ORIGIN",
                LABEL: "BASIC_DATA.ORIGIN_COUNTRY"
            },
            {
                PROPERTY: "COUNTRY_DESTINATION",
                LABEL: "BASIC_DATA.DESTINATION_COUNTRY "
            },
            {
                PROPERTY: "ROUTING_POINT_ORIGIN",
                LABEL: "BASIC_DATA.ORIGIN_PORT"
            },
            {
                PROPERTY: "ROUTING_POINT_DESTINATION",
                LABEL: "BASIC_DATA.DESTINATION_PORT"
            },
            {
                PROPERTY: "TARIFF_TYPE",
                LABEL: "PRODUCT.TARIFF_TYPE"
            },
            {
                PROPERTY: "FREE_TIME_DETAILS",
                LABEL: "GENERAL.CHARGES"
            },
            {
                PROPERTY: "VALIDITY_START",
                LABEL: "PRODUCT.VALIDITY_START"
            },
            {
                PROPERTY: "DISPLAY_VALIDITY_START",
                LABEL: "PRODUCT.VALIDITY_START"
            },
            {
                PROPERTY: "VALIDITY_END",
                LABEL: "PRODUCT.VALIDITY_END"
            },
            {
                PROPERTY: "DISPLAY_VALIDITY_END",
                LABEL: "PRODUCT.VALIDITY_END"
            },
            {
                PROPERTY: "SITUATION",
                LABEL: "GENERAL.SITUATION"
            },
            {
                PROPERTY: "NAME",
                LABEL: "GENERAL.NAME"
            },
            {
                PROPERTY: "CODE",
                LABEL: "GENERAL.CODE"
            },
            {
                PROPERTY: "FREE_TIME",
                LABEL: "BASIC_DATA.FREE_TIME"
            },
            {
                PROPERTY: "FILES",
                LABEL: "REGISTRATION.EMAIL_ATTACHMENT"
            },
            {
                PROPERTY: "FILE_DISPLAY_NAME",
                LABEL: "REGISTRATION.FILE_ATTACHMENT_NAME"
            },
            {
                PROPERTY: "PERIOD",
                LABEL: "PRODUCT.FT_PERIOD"
            },
            {
                PROPERTY: "FROM",
                LABEL: "GENERAL.FROM"
            },
            {
                PROPERTY: "UNTIL",
                LABEL: "GENERAL.TO"
            },
            {
                PROPERTY: "VALUE",
                LABEL: "FINANCIAL.VALUE"
            },
            {
                PROPERTY: "FILE_URL",
                LABEL: "REGISTRATION.FILE_URL"
            },
            {
                PROPERTY: "FILE_TYPE",
                LABEL: "REGISTRATION.FILE_TYPE"
            },
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE"
            },
            {
                PROPERTY: "IMO",
                LABEL: "BASIC_DATA.DANGEROUS_CARGO"
            },
            {
                PROPERTY: "FREE_TIME_DET",
                LABEL: "PRODUCT.FT_PD_DETENTION"
            },
            {
                PROPERTY: "FREE_TIME_DEM",
                LABEL: "PRODUCT.FT_PD_DETENTION_DEMURRAGE"
            },
            {
                PROPERTY: "COMMODITY_SECTION",
                LABEL: "GENERAL.COMMODITY_GROUP"
            },
            {
                PROPERTY: "VERSION",
                LABEL: "GENERAL.VERSION"
            },
            {
                PROPERTY: "SCORE",
                LABEL: "BASIC_DATA.SCORE"
            },
            {
                PROPERTY: "SCORE_DATE",
                LABEL: "GENERAL.SCORE_CALCULATION_DATA"
            },
            {
                PROPERTY: "SCORE_ERROR",
                LABEL: "GENERAL.SCORE_CALCULATION_ERROR"
            },
            {
                PROPERTY: "NETWORKS",
                LABEL: "BASIC_DATA.NETWORK"
            },
            {
                PROPERTY: "CONCATENATED_COMPLEMENT",
                LABEL: "GENERAL.CONCATENATED_COMPLEMENT"
            },
            {
                PROPERTY: "CONTRACTS",
                LABEL: "GENERAL.FREIGHT_CONTRACT"
            },
            {
                PROPERTY: "CREATED_AT",
                LABEL: "GENERAL.CREATED_AT"
            },
            {
                PROPERTY: "CREATED_BY",
                LABEL: "GENERAL.CREATED_BY"
            },
            {
                PROPERTY: "UPDATED_AT",
                LABEL: "GENERAL.UPDATED_AT"
            },
            {
                PROPERTY: "UPDATED_BY",
                LABEL: "GENERAL.UPDATED_BY"
            }
        ];
        return props;
    }

    private async openTariffUsedOffer(tariffDetDem: ITariffDetDemModel): Promise<void> {
        this.modalFormatedDataId = this.ModalService.newModal();
        this.ModalService.showModalInfo(
            {
                modalID: this.modalFormatedDataId,
                scope: this.$scope,
                formService: this.$scope.operation,
                size: 'lg modal-overflow',
                template: require("../view/modals/tariffDetDemUsedOfferModal.html"),
                keyboard: false
            },
            null
        );

        this.buildOfferProcessModalScope(tariffDetDem);
    }

    private async buildOfferProcessModalScope(tariffDetDem: ITariffDetDemModel): Promise<IUsedOfferModal> {
        try {
            this.formService.block();
            if (!tariffDetDem) throw Error('Missing tariffDetDem parameter in buildOfferProcessModalScope');

            const modalScope: IUsedOfferModal = await this.ModalService.getModalScope(this.modalFormatedDataId);
            modalScope.tariffDetDem = angular.copy(tariffDetDem);

            modalScope.usedOfferList = [];
            const requestList = await this.getUsedOfferList(tariffDetDem.ID);
            if (requestList) modalScope.usedOfferList = angular.copy(requestList);

            modalScope.goToOffer = (offerNumber: string): void => {
                if (!offerNumber || (offerNumber && offerNumber == '')) throw Error('offerNumber is null');
                this.$scope.sessionService.openTab("app.commercial.offer", <IOfferParameter>{ OFFER_CODE_EXHIBITION: offerNumber });
            }

            return modalScope;
        } catch (ex) {
            this.ModalService.closeModal(this.modalFormatedDataId);
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getUsedOfferList(tariffID: number): Promise<IUsedOffer[]> {
        try {
            let result = [];

            const requestList = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/tariffDetDem/usedOffer/${tariffID}`, 30000, null, false,)
            if (requestList && requestList.data) result = requestList.data;

            return result
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.getTariffDetDemById(id);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }
}

import * as angular from "angular";
import { SSEService } from "@appServices/SSEService";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog, } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IModalService } from "@services/ModalService";
import { ISessionService } from "@services/SessionService";
import { IRoutingPointListCustomFilter } from "@models/interface/product/RoutingPointModel";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IMaritimeServiceModel, IDirect, IMaritimeServiceOrigin, IMaritimeServiceDestination } from "@models/interface/product/MaritimeServiceModel";
import { EProviderTypeId, EOperation } from "@enums/GenericData";
import { IRoutingPointSelector } from "@models/interface/product/RoutingPoint";
import { SelectorModel } from "../../common/model/SelectorModel";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { IFloatingMenu } from "../../common/interface/IFloatingMenu";
import { ILinkParameter } from "../../common/model/ModelParameter";
import { BrowserTitle } from "../../common/BrowserTitle";
import { IDocumentError } from "WBA-Model/dist/interface/common/IDocumentError";
import { HelperService } from "@services/HelperService";

interface IMaritimeServiceScope extends IGridFormServiceScope {
    gridOptions: uiGrid.IGridOptions;
    model: IMaritimeServiceModel;
    log: IViewLog;
    productList: SelectorModel[];
    frequencyList: SelectorModel[];
    localList: SelectorModel[];
    directionList: SelectorModel[];
    providerBaseList: SelectorModel[];
    shipList: SelectorModel[];
    providerList: SelectorModel[];
    routingPointList: SelectorModel[];
    directRoutingPointList: SelectorModel[];
    typeList: SelectorModel[];
    originDestinationTypeList: SelectorModel[];
    terminalDoorTypeList: SelectorModel[];
    terminalPortTypeList: SelectorModel[];
    routingPointWayOriginList: SelectorModel[];
    routingPointWayDestinationList: SelectorModel[];
    menuFloating: IFloatingMenu;
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IMaritimeServiceModel;
    user: any;
    sessionService: ISessionService;
    destinationList: IMaritimeServiceDestination[];
    detailsSearchInputOriginMovement: string;
    detailsSearchTextOriginMovement: string;
    detailsSearchInputDestinationMovement: string;
    detailsSearchTextDestinationMovement: string;
    editMaritimeService: (maritimeService: IMaritimeServiceModel) => Promise<void>;
    viewMaritimeService: (maritimeService: IMaritimeServiceModel) => Promise<void>;
    viewLogMaritimeService: (maritimeService: IMaritimeServiceModel) => Promise<void>;
    copyMaritimeService: (maritimeService: IMaritimeServiceModel) => Promise<void>;
    addDirect: () => void;
    removeDirect: (index: number) => void;
    addOrigin: (typeId?: string) => void;
    removeOrigin: (index: number) => void;
    addDestination: (panelId: number, typeId?: string) => void;
    removeDestination: (index: number) => void;
    isPort: (type: SelectorModel) => boolean;
    isTerminalDoor: (type: SelectorModel) => boolean;
    getProviderListByName: (search: string) => Promise<void>;
    getShipListByName: (search: string) => Promise<void>;
    getRoutingPointListByName: (search: string, type: SelectorModel) => Promise<void>;
    getDirectRoutingPointListByName: (search: string) => Promise<void>;
    collapseHeader: (elementId: string, state?: string) => void;
    initRoutingPointWayList: () => void;
    updateRoutingPointWayList: (type: SelectorModel, typeBefore: SelectorModel, selected: SelectorModel, selectedBefore: SelectorModel, remove?: boolean) => void;
    updateProviders: (selecteds: SelectorModel[]) => void;
    tradeChange: (typeId: string) => void;
    originMoveOriginTypeChange: (index: number) => void;
    originMoveWayTypeChange: (index: number) => void;
    destinationMoveWayTypeChange: (index: number) => void;
    destinationMoveDestinationTypeChange: (index: number) => void;
    originTerminalDoorTypeFilter: (index: number) => Function;
    originTerminalPortTypeFilter: (index: number) => Function;
    destinationTerminalDoorTypeFilter: (index: number) => Function;
    destinationTerminalPortTypeFilter: (index: number) => Function;
    initPanels: () => void;
    goToProvider: (providers: SelectorModel[]) => void;
    goToShip: () => void;
    goToRoutingPoint: (id: number) => void;
    fetchData: (id: number, action: string) => Promise<void>;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
    filterOriginMovement: () => void;
    filterDestinationMovement: () => void;
}

export class MaritimeServiceRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IMaritimeServiceScope;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private RestService: IRestService;
    private ModalService: IModalService;
    private collapseState: string;
    private modalID: number;
    // private sessionService: ISessionService;
    private SSEService: SSEService;
    private gridName: string;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IMaritimeServiceScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$scope.additionalIndexSelectorValidity = ['$parentIndex'];
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');
        this.collapseState = "hide";
        this.$scope.sessionService = $injector.get('SessionService');
        this.gridName = 'GRID_MARITIME_SERVICE';
        this.SSEService = new SSEService($injector, $scope);
        this.helperService = $injector.get('HelperService');
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    private getMenuFloatingDefault(): IFloatingMenu {
        return {
            tooltipPlacement: "auto bottom",
            textTooltip: "ROUTE.OCEAN_SERVICE_DATA",
            infos: [
                { text: "{{'GENERAL.MENU.MARITIME_SERVICE' | translate }}", class: "text-rouge font-bold" }
            ],
            options: [
                { click: "collapseHeader", args: ['collapseBasicData'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.BASIC_DATA", iconClass: "fa fa-address-card", iconBodyClass: "text-brown" },
                { click: "collapseHeader", args: ['collapseDirect'], tooltipPlacement: "auto bottom", textTooltip: "ROUTE.DIRECT_SERVICE", iconClass: "fa fa-ship", iconBodyClass: "text-orange" },
                { click: "collapseHeader", args: ['collapseOriginMovement'], tooltipPlacement: "auto bottom", textTooltip: "ROUTE.ORIGIN_MOVE", iconClass: "fa fa-mail-reply", iconBodyClass: "text-cyano" },
                { click: "collapseHeader", args: ['collapseDestinationMovement'], tooltipPlacement: "auto bottom", textTooltip: "ROUTE.DESTINATION_MOVE", iconClass: "fa fa-mail-forward", iconBodyClass: "text-yellow" },
                { click: "collapseHeader", args: ['collapseAll'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.COLLAPSE_EXPAND_ALL", iconClass: "fa fa-expand", iconBodyClass: "text-danger" }
            ],
            btnActiveDisabled: false
        };
    }

    private collapseHeader(elementId: string, state?: string): void {
        try {
            if (elementId === "collapseAll" || elementId[0] === "collapseAll") {
                this.collapseState = this.collapseState == "hide" ? "show" : "hide";
                $('.toggle-me')["collapse"](state ? state : this.collapseState);
            } else if (elementId != "registerBody") {
                $("#" + elementId)["collapse"](state ? state : 'toggle');
            }
            this.$scope.navigateBetweenIds(elementId);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private initPanels(): void {
        try {
            const panels = document.getElementsByClassName("toggle-me");
            if (panels && panels.length > 0) {
                for (let i = 0; i < panels.length; i++) {
                    const panel = panels[i];
                    if (panel.id === "collapseBasicData" || panel.id === "collapseDirect") {
                        if (!panel.classList.contains('in')) $("#" + panel.id)["collapse"]("show");
                    } else if (panel.classList.contains('in')) {
                        $("#" + panel.id)["collapse"]("hide");
                    }
                }
            }
            this.$scope.navigateBetweenIds('collapseBasicData');
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();

            this.$scope.menuFloating = this.getMenuFloatingDefault();

            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'maritimeService', 'GENERAL.MENU.MARITIME_SERVICE', true);
            await this.initGrid(this.gridName, '/maritimeService/list', true, true, null, true, true);
            await this.initDependencies();
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editMaritimeService = async (maritimeService: IMaritimeServiceModel): Promise<void> => {
            let blockedObject = {
                ID: maritimeService.ID,
                NAME: maritimeService.LOGISTIC_SOLUTION,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || maritimeService.ID !== this.$scope.model.ID) this.$scope.fetchData(maritimeService.ID, EOperation.VIEW);
                } else if (this.$scope.operation !== EOperation.EDIT || maritimeService.ID !== this.$scope.model.ID) {
                    this.$scope.fetchData(maritimeService.ID, EOperation.EDIT);
                }
            };
        }

        this.$scope.viewMaritimeService = async (maritimeService: IMaritimeServiceModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(maritimeService.ID, EOperation.VIEW);
        }

        this.$scope.viewLogMaritimeService = async (maritimeService: IMaritimeServiceModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(maritimeService);
        }

        this.$scope.copyMaritimeService = async (maritimeService: IMaritimeServiceModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(maritimeService.ID, 'copy');
        }

        this.$scope.addDirect = () => {
            this.addDirect();
        }

        this.$scope.removeDirect = (index: number) => {
            this.removeDirect(index);
        }

        this.$scope.addOrigin = (typeId?: string): void => {
            this.addOrigin(typeId);
        }

        this.$scope.removeOrigin = (index: number): void => {
            this.removeOrigin(index);
        }

        this.$scope.addDestination = (panelId: number, typeId?: string): void => {
            this.addDestination(panelId, typeId);
        }

        this.$scope.removeDestination = (index: number): void => {
            this.removeDestination(index);
        }

        this.$scope.isPort = (type: SelectorModel): boolean => {
            return type && type.ID == "2";
        }

        this.$scope.isTerminalDoor = (type: SelectorModel): boolean => {
            return !type || type && (type.ID == "3" || type.ID == "5");
        }

        this.$scope.collapseHeader = (elementId: string, state?: string) => {
            this.collapseHeader(elementId, state);
        }

        this.$scope.getProviderListByName = async (search: string): Promise<void> => {
            return await this.getProviderListByName(search);
        }

        this.$scope.getShipListByName = async (search: string): Promise<void> => {
            return await this.getShipListByName(search);
        }

        this.$scope.getRoutingPointListByName = async (search: string, type: SelectorModel): Promise<void> => {
            this.$scope.routingPointList = await this.getRoutingPointListByName({ name: search, types: [type ? type.ID : '2'] });
        }

        this.$scope.getDirectRoutingPointListByName = async (search: string): Promise<void> => {
            this.$scope.routingPointList = await this.getDirectRoutingPointListByName(search);
        }

        this.$scope.initRoutingPointWayList = () => {
            this.initRoutingPointWayList();
        }

        this.$scope.updateRoutingPointWayList = (type: SelectorModel, typeBefore: SelectorModel, selected: SelectorModel, selectedBefore: SelectorModel, remove?: boolean): void => {
            if (selected) this.updateRoutingPointWayList(type, typeBefore, selected, selectedBefore, remove);
        }

        this.$scope.updateProviders = (selecteds: SelectorModel[]) => {
            this.updateProviders(selecteds);
        }

        this.$scope.tradeChange = (typeId: string): void => {
            this.tradeChange(typeId);
        }

        this.$scope.originMoveOriginTypeChange = (index: number): void => {
            this.originMoveOriginTypeChange(index);
        }

        this.$scope.originMoveWayTypeChange = (index: number): void => {
            this.originMoveWayTypeChange(index);
        }

        this.$scope.destinationMoveWayTypeChange = (index: number): void => {
            this.destinationMoveWayTypeChange(index);
        }

        this.$scope.destinationMoveDestinationTypeChange = (index: number): void => {
            this.destinationMoveDestinationTypeChange(index);
        }

        this.$scope.originTerminalDoorTypeFilter = (index: number): Function => {
            return this.originTerminalDoorTypeFilter(index);
        }

        this.$scope.originTerminalPortTypeFilter = (index: number): Function => {
            return this.originTerminalPortTypeFilter(index);
        }

        this.$scope.destinationTerminalDoorTypeFilter = (index: number): Function => {
            return this.destinationTerminalDoorTypeFilter(index);
        }

        this.$scope.destinationTerminalPortTypeFilter = (index: number): Function => {
            return this.destinationTerminalPortTypeFilter(index);
        }

        this.$scope.initPanels = (): void => {
            this.initPanels();
        }

        this.$scope.collapseHeader = (elementId: string, state?: string): void => {
            this.collapseHeader(elementId, state);
        }

        this.$scope.goToProvider = (providers: SelectorModel[]): void => {
            this.goToProvider(providers);
        }

        this.$scope.goToShip = (): void => {
            this.goToShip();
        }

        this.$scope.goToRoutingPoint = (id: number): void => {
            this.goToRoutingPoint(id);
        }

        this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
            this.fetchData(id, action);
        }

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }

        this.$scope.filterOriginMovement = () => {
            this.$scope.detailsSearchTextOriginMovement = angular.copy(this.$scope.detailsSearchInputOriginMovement);
        }

        this.$scope.filterDestinationMovement = () => {
            this.$scope.detailsSearchTextDestinationMovement = angular.copy(this.$scope.detailsSearchInputDestinationMovement);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewMaritimeService(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editMaritimeService(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogMaritimeService(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyMaritimeService(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy + modalIntegration),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colLogisticSolution: IMonacoColumnDef = { name: "LOGISTIC_SOLUTION", displayName: "BASIC_DATA.SERVICE", width: 200 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 100 };
            const colTradeOrigin: IMonacoColumnDef = { name: "TRADE_ORIGIN.NAME", displayName: "BASIC_DATA.ORIGIN_TRADE_GROUP", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TRADE_ORIGIN, null, "NAME")}}</div>' };
            const colTradeDestination: IMonacoColumnDef = { name: "TRADE_DESTINATION.NAME", displayName: "BASIC_DATA.DESTINATION_TRADE_GROUP", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TRADE_DESTINATION, null, "NAME")}}</div>' };
            const colFrequency: IMonacoColumnDef = { name: "FREQUENCY.NAME", displayName: "ROUTE.SERVICE_FREQUENCY", width: 150 };
            const colProviders: IMonacoColumnDef = { name: "PROVIDERS.NAME", displayName: "BASIC_DATA.PROVIDER", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PROVIDERS, null, "CODE")}}</div>' };
            const colServices: IMonacoColumnDef = { name: "SERVICES", displayName: "BASIC_DATA.SERVICE", width: 200 };
            const colShips: IMonacoColumnDef = { name: "SHIPS.NAME", displayName: "BASIC_DATA.VESSEL", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.SHIPS, null, "NAME")}}</div>' };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 100, cellFilter: "YesOrNo" };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'LOGISTIC_SOLUTION':
                        columnDefs.push(colLogisticSolution);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'TRADE_ORIGIN':
                        columnDefs.push(colTradeOrigin);
                        break;
                    case 'TRADE_DESTINATION':
                        columnDefs.push(colTradeDestination);
                        break;
                    case 'FREQUENCY':
                        columnDefs.push(colFrequency);
                        break;
                    case 'PROVIDERS':
                        columnDefs.push(colProviders);
                        break;
                    case 'SERVICES':
                        columnDefs.push(colServices);
                        break;
                    case 'SHIPS':
                        columnDefs.push(colShips);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            LOGISTIC_SOLUTION: null,
            PRODUCT: null,
            TRADE_ORIGIN: null,
            TRADE_DESTINATION: null,
            FREQUENCY: null,
            PROVIDERS: null,
            SERVICES: null,
            SHIPS: null,
            DIRECT: null,
            ORIGIN: null,
            DESTINATION: null,
            ACTIVE: true,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            SEARCH_FIELDS: null,
            DOCUMENT_ERROR: null
        };
    }

    initDependencies(): Promise<any> {
        const self: MaritimeServiceRegisterController = this;

        this.initRoutingPointWayList();
        this.initProviders();

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getProductList(),
                self.getLocalList(),
                self.getFrequencyList(),
                self.getDirectionList(),
                self.getTransshipmentTypeList(),
                self.getOriginDestinationTypeList()
            ]).then((result: any) => {
                self.$scope.productList = result[0] && result[0].length ? result[0].filter(obj => obj.ID == "EM" || obj.ID == "IM") : [];
                self.$scope.localList = result[1];
                self.$scope.frequencyList = result[2];
                self.$scope.directionList = result[3];
                self.$scope.typeList = result[4];
                self.$scope.originDestinationTypeList = result[5];
                self.$scope.terminalDoorTypeList = result[5] && result[5].length ? result[5].filter(obj => obj.ID == "3" || obj.ID == "5") : [];
                self.$scope.terminalPortTypeList = result[5] && result[5].length ? result[5].filter(obj => obj.ID == "2" || obj.ID == "5") : [];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "Integration Product/Operation" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number): Promise<boolean> => {
        let sendSync = false;
        let success = false;
        try {
            if (id) {
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/maritimeService/sendSync`, { "idMaritimeService": id }, 120000, false);
                if (syncRequest) sendSync = true;

                success = sendSync;
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_SENDING_REQUEST');
        } finally {
            return success;
        }
    }

    private async getMaritimeService(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/maritimeService/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const maritimeData = await this.getMaritimeService(id);
                    if (row && maritimeData && maritimeData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = maritimeData.DOCUMENT_ERROR;
                        documentError = maritimeData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async getOriginDestinationTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_local_event`, null)
        return generic && generic.data ? generic.data : [];
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null)
        return generic && generic.data ? generic.data : [];
    }

    private async getLocalList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/locals_group`, null)
        return generic && generic.data ? generic.data : [];
    }

    private async getFrequencyList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/frequency`, null)
        return generic && generic.data ? generic.data : [];
    }

    private async getDirectionList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/direction`, null)
        return generic && generic.data ? generic.data : [];
    }

    private async getTransshipmentTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/transshipment_type`, null)
        return generic && generic.data ? generic.data : [];
    }

    private async getProviderListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 3) {
                if (!this.$scope.model.PRODUCT) {
                    const msg = this.formService.getTranslate('ROUTE.MESSAGES.PRODUCT_FIRST')
                    this.$formService.notifyError(msg);
                } else {
                    this.formService.block();

                    const providers = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/provider/list/custom/`, { types: [EProviderTypeId.SHIPOWNER], products: [this.$scope.model.PRODUCT.ID], search: search }, 30000, false);
                    result = providers ? providers.map(provider => { return { ID: provider.ID, NAME: provider.NAME, CODE: provider.SCAC_IATA } }) : [];
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
        finally {
            this.$scope.providerBaseList = result;
            this.formService.unblock();
        }
    }

    private async getShipListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 3) {
                if (!this.$scope.model.PROVIDERS || this.$scope.model.PROVIDERS.length == 0) {
                    const msg = this.formService.getTranslate('ROUTE.MESSAGES.PROVIDER_FIRST')
                    this.$formService.notifyError(msg);
                } else {
                    this.formService.block();
                    const ships = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/ship/list/custom`, { search, provider: this.$scope.model.PROVIDERS.map(i => parseInt(i.ID)) }, 30000, false);
                    result = ships ? ships.map(ship => { return { ID: ship.ID, NAME: ship.NAME, CODE: ship.CODE } }) : [];
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
        finally {
            this.$scope.shipList = result;
            this.formService.unblock();
        }
    }

    private initRoutingPointWayList(): void {
        let routingPointWayOriginList: SelectorModel[] = [];
        let routingPointWayDestinationList: SelectorModel[] = [];

        if (this.$scope.model.DIRECT && this.$scope.model.DIRECT.length > 0) {
            for (const direct of this.$scope.model.DIRECT) {
                if (direct.ROUTING_POINT) {
                    if (direct.DIRECTION && direct.DIRECTION.ID == "1" && !routingPointWayOriginList.some(routingPoint => routingPoint.ID == direct.ROUTING_POINT.ID)) routingPointWayOriginList.push(direct.ROUTING_POINT);
                    else if (direct.DIRECTION && direct.DIRECTION.ID == "2" && !routingPointWayDestinationList.some(routingPoint => routingPoint.ID == direct.ROUTING_POINT.ID)) routingPointWayDestinationList.push(direct.ROUTING_POINT);
                }
            }
        }
        this.$scope.routingPointWayOriginList = routingPointWayOriginList;
        this.$scope.routingPointWayDestinationList = routingPointWayDestinationList;
    }

    private updateRoutingPointWayList(type: SelectorModel, typeBefore: SelectorModel, selected: SelectorModel, selectedBefore: SelectorModel, remove?: boolean): void {
        try {
            if (!type) throw Error('type is null');
            if (!selected) throw Error('selected is null');
            const routingPointWayListToUpdate = type.ID == "1" ? this.$scope.routingPointWayOriginList : this.$scope.routingPointWayDestinationList;
            const routingPointChanged = selectedBefore && selectedBefore.ID != selected.ID;
            // Remove from waylist
            if (remove || routingPointChanged) {
                const routingPointToRemove = remove ? selected : selectedBefore;
                if (routingPointToRemove) {
                    const directionsEqual = this.$scope.model.DIRECT && this.$scope.model.DIRECT.length > 1 ? this.$scope.model.DIRECT.filter(direct => direct.DIRECTION && direct.DIRECTION.ID == type.ID && direct.ROUTING_POINT && direct.ROUTING_POINT.ID == routingPointToRemove.ID) : null;
                    if (!directionsEqual || directionsEqual && directionsEqual.length == 0) {
                        const index = routingPointWayListToUpdate.findIndex(routingPoint => routingPoint.ID == routingPointToRemove.ID);
                        if (index >= 0) routingPointWayListToUpdate.splice(index, 1);
                    }
                }
            }
            // Add to waylist if doesn't exist.
            if (!remove && !routingPointWayListToUpdate.some(routingPoint => routingPoint.ID == selected.ID)) routingPointWayListToUpdate.push(selected);
            // Origin
            if (type.ID == "1") {
                this.$scope.routingPointWayOriginList = routingPointWayListToUpdate;
                if ((!typeBefore || (typeBefore && typeBefore.ID == type.ID)) && this.$scope.model.ORIGIN && this.$scope.model.ORIGIN.length > 0) {
                    for (const origin of this.$scope.model.ORIGIN) {
                        if (origin.ROUTING_POINT_WAY && !this.$scope.routingPointWayOriginList.some(routingPoint => routingPoint.ID == origin.ROUTING_POINT_WAY.ID)) origin.ROUTING_POINT_WAY = null;
                    }
                }
            }
            // Destination
            else if (type.ID == "2") {
                this.$scope.routingPointWayDestinationList = routingPointWayListToUpdate;
                if ((!typeBefore || (typeBefore && typeBefore.ID == type.ID)) && this.$scope.model.DESTINATION && this.$scope.model.DESTINATION.length > 0) {
                    for (const destination of this.$scope.model.DESTINATION) {
                        if (destination.ROUTING_POINT_WAY && !this.$scope.routingPointWayDestinationList.some(routingPoint => routingPoint.ID == destination.ROUTING_POINT_WAY.ID)) destination.ROUTING_POINT_WAY = null;
                    }
                }
            }
            if (typeBefore && typeBefore.ID != type.ID) this.updateRoutingPointWayList(typeBefore, null, selected, selectedBefore, remove);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async initCollapseEvents() {
        const collapseDestinationMovement = angular.element('#collapseDestinationMovement');

        collapseDestinationMovement.on('shown.bs.collapse', async (event: JQuery.Event) => {
            if (event.target == event.currentTarget) {
                this.$scope.destinationList = this.$scope.model.DESTINATION;
                this.$scope.navigateBetweenIds("collapseDestinationMovement");
            }
        });
    }

    private async getDirectRoutingPointListByName(search: string): Promise<IRoutingPointSelector[]> {
        let result: IRoutingPointSelector[] = [];
        if (search && search.length >= 3) {
            result = await this.getRoutingPointListByName({ name: search, types: ['2'] });
        }
        return result;
    }

    private async getRoutingPointListByName(filter: IRoutingPointListCustomFilter): Promise<IRoutingPointSelector[]> {
        let result: IRoutingPointSelector[] = [];
        try {
            if (filter && filter.name && filter.name.length >= 3) {
                this.formService.block();
                const routingPoints = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/routingPoint/list/custom/`, filter, 30000, false);
                result = routingPoints ? routingPoints.map(routingPoint => { return { ID: routingPoint.ID, NAME: routingPoint.NAME, CODE: routingPoint.CODE, DISPLAY_NAME: routingPoint.DISPLAY_NAME } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
        finally {
            this.formService.unblock();
            return result;
        }
    }

    private initProviders(): void {
        try {
            this.$scope.providerList = [];

            if (this.$scope.model.PROVIDERS) {
                for (const provider of this.$scope.model.PROVIDERS) {
                    if (provider && !this.checkProviderExist(provider.ID))
                        this.$scope.providerList.push({ ID: provider.ID, NAME: provider.NAME, CODE: provider.CODE });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private updateProviders(selecteds: SelectorModel[]): void {
        try {
            if (!selecteds) throw Error('selected is null');

            this.$scope.providerList = [];
            for (const provider of selecteds) {
                if (provider && !this.checkProviderExist(provider.ID))
                    this.$scope.providerList.push({ ID: provider.ID, NAME: provider.NAME, CODE: provider.CODE });
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private tradeChange(typeId: string): void {
        try {
            if (!typeId) throw Error('typeId is null');

            if (this.$scope.model.DIRECT && this.$scope.model.DIRECT.length > 0) {
                for (const direct of this.$scope.model.DIRECT) {
                    if (direct.DIRECTION && direct.DIRECTION.ID == typeId) direct.ROUTING_POINT = null;
                }
                this.initRoutingPointWayList();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private originMoveOriginTypeChange(index: number): void {
        if (!index && index != 0) throw Error('index is null');
        if (this.$scope.model.ORIGIN && this.$scope.model.ORIGIN.length > 0 && this.$scope.model.ORIGIN[index]) {
            this.$scope.model.ORIGIN[index].ROUTING_POINT_ORIGIN = null;
            this.$timeout(() => { this.$scope.selectorValidity('originMovementTerminalDoorOrigin' + index); });
        }
    }

    private originMoveWayTypeChange(index: number): void {
        if (!index && index != 0) throw Error('index is null');
        if (this.$scope.model.ORIGIN && this.$scope.model.ORIGIN.length > 0 && this.$scope.model.ORIGIN[index]) {
            this.$scope.model.ORIGIN[index].ROUTING_POINT_WAY = null;
            this.$timeout(() => { this.$scope.selectorValidity('originMovementTerminalDoorWay' + index); });
        }
    }

    private destinationMoveWayTypeChange(index: number): void {
        if (!index && index != 0) throw Error('index is null');
        if (this.$scope.model.DESTINATION && this.$scope.model.DESTINATION.length > 0 && this.$scope.model.DESTINATION[index]) {
            this.$scope.model.DESTINATION[index].ROUTING_POINT_WAY = null;
            this.$timeout(() => { this.$scope.selectorValidity('destinationMovementTerminalDoorWay' + index); });
        }
    }

    private destinationMoveDestinationTypeChange(index: number): void {
        if (!index && index != 0) throw Error('index is null');
        if (this.$scope.model.DESTINATION && this.$scope.model.DESTINATION.length > 0 && this.$scope.model.DESTINATION[index]) {
            this.$scope.model.DESTINATION[index].ROUTING_POINT_DESTINATION = null;
            this.$timeout(() => { this.$scope.selectorValidity('destinationMovementTerminalDoorDestination' + index); });
        }
    }

    private originTerminalDoorTypeFilter = (index: number): Function => {
        const terminalId = "5";
        const terminalDoor = this.$scope.model.ORIGIN && this.$scope.model.ORIGIN.length > 0 ? this.$scope.model.ORIGIN[index] : null;
        return function (type: SelectorModel) {
            return type.ID != terminalId || !(type.ID == terminalId && terminalDoor && terminalDoor.WAY_TYPE && terminalDoor.WAY_TYPE.ID == type.ID);
        }
    }

    private originTerminalPortTypeFilter = (index: number): Function => {
        const terminalId = "5";
        const terminalDoor = this.$scope.model.ORIGIN && this.$scope.model.ORIGIN.length > 0 ? this.$scope.model.ORIGIN[index] : null;
        return function (type: SelectorModel) {
            return type.ID != terminalId || !(type.ID == terminalId && terminalDoor && terminalDoor.ORIGIN_TYPE && terminalDoor.ORIGIN_TYPE.ID == type.ID);
        }
    }

    private destinationTerminalDoorTypeFilter = (index: number): Function => {
        const terminalId = "5";
        const terminalDoor = this.$scope.model.DESTINATION && this.$scope.model.DESTINATION.length > 0 ? this.$scope.model.DESTINATION[index] : null;
        return function (type: SelectorModel) {
            return type.ID != terminalId || !(type.ID == terminalId && terminalDoor && terminalDoor.WAY_TYPE && terminalDoor.WAY_TYPE.ID == type.ID);
        }
    }

    private destinationTerminalPortTypeFilter = (index: number): Function => {
        const terminalId = "5";
        const terminalDoor = this.$scope.model.DESTINATION && this.$scope.model.DESTINATION.length > 0 ? this.$scope.model.DESTINATION[index] : null;
        return function (type: SelectorModel) {
            return type.ID != terminalId || !(type.ID == terminalId && terminalDoor && terminalDoor.WAY_TYPE && terminalDoor.WAY_TYPE.ID == type.ID);
        }
    }

    private checkProviderExist(id: string): boolean {
        try {
            if (!id) throw Error('id is null');

            if (this.$scope.providerList && this.$scope.providerList.length > 0) {
                for (const item of this.$scope.providerList) {
                    if (item.ID == id) {
                        return true;
                    }
                }
            }
            return false;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.initPanels();
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.SSEService.closeEvents();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.menuFloating.formOperation = this.$scope.formOperation;
            this.$scope.menuFloating.infos = [{ text: "{{'PRODUCT.MARITIME_SERVICE' | translate }}", class: "text-rouge font-bold" }];

            // Add minimal 1 lines
            this.addDirect();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.disableElements(true);
            this.$scope.initPanels();
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
            this.SSEService.closeEvents();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            await this.initCollapseEvents();
            this.$scope.menuFloating.infos = [{ text: this.$scope.model.LOGISTIC_SOLUTION + " - " + this.$scope.model.PRODUCT.ID, class: "text-rouge font-bold" }];

            BrowserTitle.$id = this.$scope.model.LOGISTIC_SOLUTION;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.initPanels();
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.initCollapseEvents();

            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.menuFloating.infos = [{ text: this.$scope.model.LOGISTIC_SOLUTION + " - " + this.$scope.model.PRODUCT.ID, class: "text-rouge font-bold" }];

            BrowserTitle.$id = this.$scope.model.LOGISTIC_SOLUTION;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.initPanels();
            await this.clearFields(this.$scope.model);

            if (this.$scope.model.DIRECT && this.$scope.model.DIRECT.length > 0) {
                for (const direct of this.$scope.model.DIRECT) {
                    direct.ID = null;
                }
            }

            if (this.$scope.model.ORIGIN && this.$scope.model.ORIGIN.length > 0) {
                for (const origin of this.$scope.model.ORIGIN) {
                    origin.ID = null;
                }
            }

            if (this.$scope.model.DESTINATION && this.$scope.model.DESTINATION.length > 0) {
                for (const destination of this.$scope.model.DESTINATION) {
                    destination.ID = null;
                }
            }
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }

    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        return {
            route: `/maritimeService/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 120000
        };
    }

    private addDirect(): void {
        try {
            const direct: IDirect = {
                _id: null,
                ID: null,
                DIRECTION: null,
                ROUTING_POINT: null,
                ID_ROUTING_POINT: null,
                ID_MARITIME_SERVICE: null,
                TRANSIT_TIME: null
            }

            if (!this.$scope.model.DIRECT) this.$scope.model.DIRECT = [];
            this.$scope.model.DIRECT.push(angular.copy(direct));
            this.$timeout(() => {
                const currentIndex = this.$scope.model.DIRECT.length - 1;
                this.$scope.selectorValidity('directDirection' + currentIndex);
                this.$scope.selectorValidity('directRoutingPoint' + currentIndex);
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeDirect(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;
            if (this.$scope.model.DIRECT && this.$scope.model.DIRECT.length > 0) {
                this.formService.block();
                this.$scope.model.DIRECT.splice(index, 1);
                this.initRoutingPointWayList();
            }

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    };

    private addOrigin(typeId?: string): void {
        try {
            const type = typeId && this.$scope.originDestinationTypeList ? this.$scope.originDestinationTypeList.find(obj => obj.ID == typeId) : null;
            const origin: IMaritimeServiceOrigin = {
                _id: null,
                ID: null,
                ID_MARITIME_SERVICE: null,
                TYPE: null,
                ORIGIN_TYPE: type,
                ROUTING_POINT_ORIGIN: null,
                WAY_TYPE: type,
                ROUTING_POINT_WAY: null,
                ID_ROUTING_POINT_ORIGIN: null,
                ID_ROUTING_POINT_WAY: null,
                PROVIDERS: null,
                ADDITIONAL_DAYS: null
            }

            if (!this.$scope.model.ORIGIN) this.$scope.model.ORIGIN = [];
            this.$scope.model.ORIGIN.push(origin);

            this.$timeout(() => {
                const currentIndex = this.$scope.model.ORIGIN.length - 1;
                if (typeId && typeId == "2") {
                    this.$scope.selectorValidity('originMovementPortType' + currentIndex);
                    this.$scope.selectorValidity('originMovementPortOriginType' + currentIndex);
                    this.$scope.selectorValidity('originMovementPortOrigin' + currentIndex);
                    this.$scope.selectorValidity('originMovementPortWayType' + currentIndex);
                    this.$scope.selectorValidity('originMovementPortWay' + currentIndex);
                } else {
                    this.$scope.selectorValidity('originMovementTerminalDoorType' + currentIndex);
                    this.$scope.selectorValidity('originMovementTerminalDoorOriginType' + currentIndex);
                    this.$scope.selectorValidity('originMovementTerminalDoorOrigin' + currentIndex);
                    this.$scope.selectorValidity('originMovementTerminalDoorWayType' + currentIndex);
                    this.$scope.selectorValidity('originMovementTerminalDoorWay' + currentIndex);
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeOrigin(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.$scope.model.ORIGIN && this.$scope.model.ORIGIN.length > 0) {
                this.formService.block();
                this.$scope.model.ORIGIN.splice(index, 1);
                this.formService.unblock();
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private addDestination(panelId: number, typeId?: string): void {
        try {
            const type = typeId && this.$scope.originDestinationTypeList ? this.$scope.originDestinationTypeList.find(obj => obj.ID == typeId) : null;
            const destination: IMaritimeServiceDestination = {
                _id: null,
                ID: null,
                PANEL_CONTROL: panelId,
                ID_MARITIME_SERVICE: null,
                TYPE: null,
                WAY_TYPE: type,
                ID_ROUTING_POINT_WAY: null,
                ROUTING_POINT_WAY: null,
                DESTINATION_TYPE: type,
                ID_ROUTING_POINT_DESTINATION: null,
                ROUTING_POINT_DESTINATION: null,
                PROVIDERS: null,
                ADDITIONAL_DAYS: null
            }

            if (!this.$scope.destinationList) this.$scope.destinationList = [];
            this.$scope.destinationList.push(destination);
            this.$scope.model.DESTINATION = this.$scope.destinationList;

            this.$timeout(() => {
                const currentIndex = this.$scope.destinationList.length - 1;
                if (typeId && typeId == "2") {
                    this.$scope.selectorValidity('destinationMovementPortType' + currentIndex);
                    this.$scope.selectorValidity('destinationMovementPortWayType' + currentIndex);
                    this.$scope.selectorValidity('destinationMovementPortWay' + currentIndex);
                    this.$scope.selectorValidity('destinationMovementPortDestinationType' + currentIndex);
                    this.$scope.selectorValidity('destinationMovementPortDestination' + currentIndex);
                } else {
                    this.$scope.selectorValidity('destinationMovementTerminalDoorType' + currentIndex);
                    this.$scope.selectorValidity('destinationMovementTerminalDoorWayType' + currentIndex);
                    this.$scope.selectorValidity('destinationMovementTerminalDoorWay' + currentIndex);
                    this.$scope.selectorValidity('destinationMovementTerminalDoorDestinationType' + currentIndex);
                    this.$scope.selectorValidity('destinationMovementTerminalDoorDestination' + currentIndex);
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeDestination(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (this.$scope.model.DESTINATION && this.$scope.model.DESTINATION.length > 0) {
                this.formService.block();
                this.$scope.model.DESTINATION.splice(index, 1);
                this.formService.unblock();
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private goToProvider(providers: SelectorModel[]): void {
        this.$scope.sessionService.openTab("app.registration.provider", <ILinkParameter>{ ID: this.$scope.getCONCAT(providers, null, "ID") });
    }

    private goToShip(): void {
        try {
            this.$scope.sessionService.openTab("app.registration.ship", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.SHIPS, null, "ID") });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private goToRoutingPoint(id: number): void {
        try {
            this.$scope.sessionService.openTab("app.registration.routingPoint", <ILinkParameter>{ ID: id ? id.toString() : id });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'LOGISTIC_SOLUTION',
                LABEL: 'GENERAL.DOMESTIC_SERVICE'
            },
            {
                PROPERTY: 'PRODUCT',
                READ: 'ID',
                LABEL: 'BASIC_DATA.PRODUCT'
            },
            {
                PROPERTY: 'TRADE_ORIGIN',
                LABEL: 'BASIC_DATA.ORIGIN_TRADE_GROUP'
            },
            {
                PROPERTY: 'TRADE_DESTINATION',
                LABEL: 'BASIC_DATA.DESTINATION_TRADE_GROUP'
            },
            {
                PROPERTY: 'FREQUENCY',
                LABEL: 'ROUTE.SERVICE_FREQUENCY'
            },
            {
                PROPERTY: 'PROVIDERS',
                READ: 'CODE',
                LABEL: 'BASIC_DATA.PROVIDER'
            },
            {
                PROPERTY: 'SERVICES',
                LABEL: 'BASIC_DATA.SERVICE'
            },
            {
                PROPERTY: 'SHIPS',
                LABEL: 'BASIC_DATA.VESSEL'
            },
            {
                PROPERTY: 'DIRECT',
                LABEL: 'PRODUCT.WAY'
            },
            {
                PROPERTY: 'DIRECTION',
                LABEL: 'PRODUCT.WAY',
                READ: 'NAME'
            },
            {
                PROPERTY: 'ROUTING_POINT',
                LABEL: 'ROUTE.PORT',
                READ: 'CODE'
            },
            {
                PROPERTY: 'TRANSIT_TIME',
                LABEL: 'GENERAL.TRANSIT_TIME'
            },
            {
                PROPERTY: 'TRANSSHIPMENTS',
                LABEL: 'ROUTE.TRANSHIPMENTS'
            },
            {
                PROPERTY: 'TYPE',
                LABEL: 'GENERAL.TYPE'
            },
            {
                PROPERTY: 'HUB',
                LABEL: 'ROUTE.HUB'
            },
            {
                PROPERTY: 'ADDITIONAL_DAYS',
                LABEL: 'PRODUCT.ADDITIONAL_DAYS'
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'CODE',
                LABEL: 'GENERAL.CODE'
            },
            {
                PROPERTY: 'ID',
                LABEL: 'REGISTRATION.IDENTIFICATION'
            },
            {
                PROPERTY: 'ORIGIN_TYPE',
                LABEL: 'ROUTE.ORIGIN_TYPE'
            },
            {
                PROPERTY: 'WAY_TYPE',
                LABEL: 'GENERAL.VIA_TYPE'
            },
            {
                PROPERTY: 'ROUTING_POINT_ORIGIN',
                LABEL: 'BASIC_DATA.ORIGIN'
            },
            {
                PROPERTY: 'ROUTING_POINT_WAY',
                LABEL: 'GENERAL.VIA'
            },
            {
                PROPERTY: 'DESTINATION_TYPE',
                LABEL: 'ROUTE.DESTINATION_TYPE'
            },
            {
                PROPERTY: 'ROUTING_POINT_DESTINATION',
                LABEL: 'BASIC_DATA.DESTINATION'
            },
            {
                PROPERTY: 'ORIGIN',
                LABEL: 'BASIC_DATA.ORIGIN'
            },
            {
                PROPERTY: 'DESTINATION',
                LABEL: 'BASIC_DATA.DESTINATION'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            }
        ];
        return props;
    }

    private async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/maritimeService/getById/${id}`, 120000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }
}

import angular = require('angular');
import { IModalOptions, IModalService } from '@services/ModalService';
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { ProductService } from '@services/ProductService';
import { ICargo, IOfferRequestCargoListConversion } from "../../commercial/model/OfferModel";
import { ISelectorModel } from 'WBA-Model/dist/mongo/SelectorModel';
import { OfferHelper } from './util/OfferHelper';
import { HelperService } from "@services/HelperService";

interface IOfferCargoPolLbModalScope extends IFormServiceScope {
    modalOptions: IModalOptions;
    cargoListPolLb: ICargo[];
    packageTypeList: ISelectorModel[];
    //general methods
    applyCargoListPolLb: (apply: boolean) => void;
    addCargoPolLb: () => void;
    removeCargoPolLb: (index: number) => void;
    calculateCargoPolLbCBW: (index: number) => void;
    calculateCargoPolLbGrossWeightTotal: (index: number) => void;
}

export class OfferCargoPolLbModalController extends FormService2 {
    static $inject: string[] = ['$injector', '$scope'];
    private scope: IOfferCargoPolLbModalScope;
    private $q: ng.IQService;
    private productService: ProductService;
    private modalService: IModalService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IOfferCargoPolLbModalScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.$q = $injector.get('$q');
        this.productService = $injector.get('ProductService');
        this.modalService = $injector.get('ModalService');
        this.helperService = $injector.get('HelperService'); 
    }

    async $onInit(): Promise<void> {
        try {
            this.block();
            this.init("cargoPolLbModalForm", null, null);
            this.initScopeFunctions();
            await this.initDependencies();
            await this.initModel();
            this.loadRegisterForm(false);
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    private initScopeFunctions(): void {
        this.scope.addCargoPolLb = (): void => {
            this.addCargoPolLb();
        }
        this.scope.removeCargoPolLb = (index: number): void => {
            this.removeCargoPolLb(index);
        }
        this.scope.applyCargoListPolLb = (apply: boolean) => {
            this.applyCargoListPolLb(apply);
        }
        this.scope.calculateCargoPolLbCBW = (index: number): void => {
            if (!index && index !== 0) throw this.notifyError('index is null');
            const cargoListModel = this.scope.cargoListPolLb ? this.scope.cargoListPolLb : null;
            if (cargoListModel && cargoListModel.length > 0 && cargoListModel[index]) {
                const cbw = OfferHelper.calculateCargoCBW(cargoListModel[index]);
                this.scope.cargoListPolLb[index].CUBIC_WEIGHT = cbw ? parseFloat(cbw.toFixed(3)) : cbw;
            }
        }
        this.scope.calculateCargoPolLbGrossWeightTotal = (index: number): void => {
            if (!index && index !== 0) throw this.notifyError('index is null');
            const cargoListModel = this.scope.cargoListPolLb ? this.scope.cargoListPolLb : null;
            if (cargoListModel && cargoListModel.length > 0 && cargoListModel[index]) {
                const grossWeightTotal = OfferHelper.calculateCargoGrossWeightTotal(cargoListModel[index]);
                this.scope.cargoListPolLb[index].GROSS_WEIGHT = grossWeightTotal ? parseFloat(grossWeightTotal.toFixed(4)) : grossWeightTotal;
            }
        }
    }

    async initDependencies(): Promise<any> {
        try {
            const self: OfferCargoPolLbModalController = this;
            return new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getGenericValue("package_type"),
                ]).then((result: any) => {
                    self.scope.packageTypeList = result[0];
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async initModel(): Promise<void> {
        this.scope.cargoListPolLb = [{ PACKAGE: null, VOLUME: null, LENGTH: null, WIDTH: null, HEIGHT: null, CUBIC_WEIGHT: null, GROSS_WEIGHT_UNITARY: null, GROSS_WEIGHT: null, UUID: null }];
    }

    private addCargoPolLb(): void {
        try {
            if (!this.scope.cargoListPolLb) this.scope.cargoListPolLb = [];
            const cargo: ICargo = {
                PACKAGE: null,
                VOLUME: null,
                LENGTH: null,
                WIDTH: null,
                HEIGHT: null,
                CUBIC_WEIGHT: null,
                GROSS_WEIGHT_UNITARY: null,
                GROSS_WEIGHT: null,
                UUID: null
            }
            this.scope.cargoListPolLb.push(cargo);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async removeCargoPolLb(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: this.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")
            });
            if (!modal) return;

            if (this.scope.cargoListPolLb && this.scope.cargoListPolLb.length > 0) {
                this.block();
                this.scope.cargoListPolLb.splice(index, 1);
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async applyCargoListPolLb(apply: boolean): Promise<void> {
        try {
            let cargoListConverted: ICargo[] = null;
            if (apply) {
                const isInvalid = await this.hasInvalidRequiredElements('cargoPolLbModalForm');
                if (isInvalid) return;
                if (this.scope.cargoListPolLb && this.scope.cargoListPolLb.length > 0) {
                    const filter: IOfferRequestCargoListConversion = { VOLUMES_LIST: this.scope.cargoListPolLb };
                    const cargoListConvertedRequest = await this.productService.post({ route: '/offerWizard/addCargoListPolLib', data: filter });
                    if (!cargoListConvertedRequest || cargoListConvertedRequest && cargoListConvertedRequest.data && cargoListConvertedRequest.data.data.length == 0) {
                        return this.notifyError(this.getTranslate('GENERAL.VALUES_NOT_CONVERTED_TRY_AGAIN'));
                    } else cargoListConverted = cargoListConvertedRequest.data.data;
                }
            }
            this.scope.modalOptions.ok(apply ? cargoListConverted : null);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async hasInvalidRequiredElements(elementId: string): Promise<boolean> {
        if (!elementId) return false;
        const isInvalid = FormService2.hasRequiredElements('#' + elementId);
        if (isInvalid) this.notifyError(this.getTranslate("GENERAL.ALL_FIELDS_MANDATORY"));
        return isInvalid;
    }

    private async getGenericValue(type: string, alternative?: boolean): Promise<any> {
        if (alternative) {
            const { data: generic } = await this.helperService.get(`/generic/value/${type}/${alternative}`, null, 10000);
            return generic && generic.data ? generic.data : [];
        } else {
            const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
            return generic && generic.data ? generic.data : [];
        }
    }
}

import * as angular from "angular";
import { IColumnDef } from "ui-grid";
import { IGridFormController, IGridFormServiceScope, GridFormService } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { BrowserTitle } from "../../common/BrowserTitle";
import { SelectorModel } from "../../common/model/SelectorModel";
import { StringUtil } from "../../common/util/StringUtil";
import { ReportTemplateModel, ReportTemplateParams } from "../model/ReportTemplateModel";
import { HelperService } from "@services/HelperService";

interface IReportTemplateScope extends IGridFormServiceScope {
    model: ReportTemplateModel;
    reportList: any;
    modalError: string;
    modalSuccess: string;
    scopeBeforeSave: ReportTemplateModel;
    yesNoSelector: any;
    productList: any;
    categoryList: any;
    branchList: any;
    peopleSpecList: SelectorModel[];
    reportTypeList: SelectorModel[];
    reportGroupList: SelectorModel[];
    reportSpecsList: SelectorModel[];
    confReportList: SelectorModel[];
    languages: SelectorModel[];
    decodeBoolean: (value: boolean) => string;
    concatWithIdAndName: (people: any) => string;
    addReportItem: () => void;
    removeReportItem: (index: number) => void;
    getReportConfByCategory: (category: SelectorModel, index: number) => void;
    clearConfTags: (index: number) => void;
}

export class ReportTemplateRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IReportTemplateScope;
    private $q: ng.IQService;
    private $http: ng.IHttpService;
    private RestService: IRestService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IReportTemplateScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$http = $injector.get('$http');
        this.RestService = $injector.get('RestService');
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.initForm(this, 'form', 'reportTemplate', 'Modelos de Relatório', true);
            await this.initGrid('gridReport', '/reportTemplate/list', true);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        let YES_SELECTOR = {
            ID: '1',
            NAME: 'Sim',
            VALUE: true,
        };

        let NO_SELECTOR = {
            ID: '2',
            NAME: 'Não',
            VALUE: false,
        };

        this.$scope.yesNoSelector = [YES_SELECTOR, NO_SELECTOR];

        this.$scope.decodeBoolean = (value): string => {
            return this.decodeBoolean(value);
        }

        this.$scope.concatWithIdAndName = (people): string => {
            return this.concatWithIdAndName(people);
        }

        this.$scope.addReportItem = () => this.addReportItem();

        this.$scope.removeReportItem = (index: number) => this.removeReportItem(index);

        this.$scope.getReportConfByCategory = async (category: SelectorModel) => {
            return await this.getReportConfByCategory(category);
        }

        this.$scope.clearConfTags = (index: number) => this.clearConfTags(index);
    }

    private concatWithIdAndName(list: any) {
        let result = "";
        if (list) {
            if (list.length > 0) {
                for (let i = 0; i < list.length; i++) {
                    result += list[i]['ID'] + ' - ' + list[i]['NAME'];
                    result += (list.length > 1) ? ' | ' : ""
                }
            }
        }
        return result;
    }

    private addReportItem() {
        try {
            if (!this.$scope.model.PARAMS) this.$scope.model.PARAMS = [];

            const paramsItem = this.initializeParamsItems();

            this.$scope.model.PARAMS.push(paramsItem);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private removeReportItem(index: number) {
        try {
            if (this.$scope.model.PARAMS.length > 1 && index >= 0) {
                this.$scope.model.PARAMS.splice(index, 1);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private initializeParamsItems(): ReportTemplateParams {
        try {
            const paramsItem = <ReportTemplateParams>{};
            paramsItem.BRANCH = null;
            paramsItem.CATEGORY = null;
            paramsItem.SPEC = null;
            paramsItem.PEOPLE = null;
            paramsItem.PRODUCT = null;
            paramsItem.CONFIGURATIONS_TAG = null;
            paramsItem.REPORT_GROUP = null;
            paramsItem.REPORT_SPECS = null;
            paramsItem.REPORT_TYPE = null;

            return paramsItem
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initGridColumns(columns) {
        let columnDefs: Array<IColumnDef> = new Array();

        const view = '<div class="text-center"><a ng-click="grid.appScope.view(row.entity)" class="text-info"tooltip-placement="auto top" uib-tooltip="Visualizar" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;'
        const edit = '<a ng-click="grid.appScope.edit(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="Editar" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;'

        columnDefs.push({
            name: "acoes",
            field: name,
            displayName: "Ações",
            minWidth: 80,
            maxWidth: 80,
            cellTemplate: (view + edit),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        for (let i = 0; i < columns.length; i++) {
            let name = null;
            let displayName = null;
            let width = 0;
            let cellTemplate = undefined;
            let cellFilter = null;
            let visible = true;
            let filter = null;

            switch (columns[i].toUpperCase()) {
                case 'TEMPLATE':
                    name = columns[i] + '.NAME';
                    displayName = 'Modelo';
                    width = columns[i].length + 10;
                    filter = { condition: this.$gridService.filterSelectObject };
                    break;
                case 'LANGUAGE':
                    name = columns[i] + '.NAME';
                    displayName = 'Idioma';
                    width = columns[i].length;
                    filter = { condition: this.$gridService.filterSelectObject };
                    break;
                case 'DISABLED':
                    name = columns[i];
                    displayName = 'Desativado';
                    width = columns[i].length;
                    cellTemplate = '<div class="grid-padding" >{{grid.appScope.decodeBoolean(row.entity.DISABLED)}}</div>';
                    filter = { condition: this.$gridService.filterSelectObject };
                    break;
            }

            if (name) {
                const newColumn = {
                    name: name,
                    field: name,
                    displayName: displayName,
                    headerCellClass: 'header-filtered',
                    width: width + '%',
                    cellTemplate: cellTemplate,
                    cellFilter: cellFilter,
                    visible: visible,
                    filter: filter
                };
                columnDefs.push(newColumn);
            }
        }
        return columnDefs;
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            TEMPLATE: null,
            LANGUAGE: null,
            DISABLED: null,
            PARAMS: null
        };
    }

    async initDependencies(): Promise<boolean> {
        try {
            const result: Array<any> = await this.$q.all([
                this.getReportListFromJSReport(),
                this.getGenericType('product'),
                this.getGenericType('report_category'),
                this.getBranchList(),
                this.getGenericType('transaction'),
                this.getGenericType('document_type'),
                this.getGenericType('file_group'),
                this.getGenericType('file_specs'),
                this.getGenericType('language'),
            ]);

            if (!result || result.length === 0) return false;

            this.$scope.reportList = result[0].data.data;
            this.$scope.productList = result[1];
            this.$scope.categoryList = result[2];
            this.$scope.branchList = result[3].data.data;
            this.$scope.peopleSpecList = result[4];
            this.$scope.reportTypeList = result[5];
            this.$scope.reportGroupList = result[6];
            this.$scope.reportSpecsList = result[7];
            this.$scope.languages = result[8];

            if (typeof this.$scope.reportList != 'object') this.handleLoadError("Object type not defined");

            return true;
        } catch (ex) {
            this.handleLoadError(`Falha ao carregar lista de templates. ${JSON.stringify(ex)}`);
        }
    }

    private getReportListFromJSReport() {
        return this.$http({
            method: 'get',
            url: this.config.restfulBaseUrl + '/reportTemplate/report/list',
            cache: false,
            timeout: 10000
        });
    }

    private async getReportConfByCategory(genericTypes: SelectorModel): Promise<void> {
        try {
            if (this.$scope.confReportList && this.$scope.confReportList.length > 0) this.$scope.confReportList = null;
            const category = (genericTypes && genericTypes.NAME) ? genericTypes.NAME : null;
            if (!category) return

            this.$scope.$applyAsync();
            this.block()

            const categoryName = StringUtil.formatString(category);

            const { data: generic } = await this.helperService.get(`/generic/value/${categoryName}`, null, 10000);

            this.$scope.confReportList = generic && generic.data ? generic.data : [];

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private clearConfTags(index: number) {
        try {
            if (this.$scope.model.PARAMS[index].CONFIGURATIONS_TAG) this.$scope.model.PARAMS[index].CONFIGURATIONS_TAG = null;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getBranchList() {
        return this.$http({
            method: 'get',
            url: this.config.restfulBaseUrl + '/allog/branch/list',
            cache: false,
            timeout: 10000
        });
    }

    private async getGenericType(identifier: string) {
        try {
            const { data: generic } = await this.helperService.get(`/generic/value/${identifier}`, null, 10000);
            return generic && generic.data ? generic.data : [];
        } catch (ex) {
            throw ex;
        }
    }

    async view(): Promise<void> {
        const templateName = this.$scope.model.TEMPLATE['NAME']
        this.$scope.formOperation = `Visualizando - ${templateName}`
        BrowserTitle.$id = templateName
    }

    async register(): Promise<void> {
        this.addReportItem();
    }

    async edit(): Promise<void> {
        try {
            const templateName = this.$scope.model.TEMPLATE['NAME']
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.formOperation = `Editando - ${templateName}`;
            BrowserTitle.$id = templateName;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        delete this.$scope.model.TEMPLATE['shortid'];
        delete this.$scope.model.TEMPLATE['name'];

        this.sanitizeParamList(this.$scope.model.PARAMS);

        return true;
    }

    private sanitizeParamList(paramList: ReportTemplateParams[]): void {
        try {
            if (paramList && paramList.length > 0) {
                for (const param of paramList) {
                    param.PEOPLE = (param && param.PEOPLE && param.PEOPLE.length === 0) ? null : param.PEOPLE;
                    param.SPEC = (param && param.SPEC && param.SPEC.length === 0) ? null : param.SPEC;
                    param.PRODUCT = (param && param.PRODUCT && param.PRODUCT.length === 0) ? null : param.PRODUCT;
                    param.CONFIGURATIONS_TAG = (param && param.CONFIGURATIONS_TAG && param.CONFIGURATIONS_TAG.length === 0) ? null : param.CONFIGURATIONS_TAG;
                    param.REPORT_SPECS = (param && param.REPORT_SPECS && param.REPORT_SPECS.length === 0) ? null : param.REPORT_SPECS;
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }
}

import * as angular from "angular";
import * as moment from 'moment';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog, IMonacoRequest } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { ISessionService } from "@services/SessionService";
import { HelperService } from "@services/HelperService";
import { ProductService } from "@services/ProductService";
import { IModalService } from "@services/ModalService";
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { EOperation, EProductId, ESituationId, EInclusionExemption, EApplicationComplementId, EPaymentNatureId, ECargoTypeId, ETariffChangeConfirmationReasonValue, ETariffChangeConfirmationReasonValidity } from "@enums/GenericData";
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { ITariffComplementary as ITariffComplementaryModel, IInvolved, IRoutes } from "@models/interface/product/TariffComplementary";
import { ITariffChangeConfirmation } from "@models/interface/product/TariffChangeConfirmation";
import { IUploader, IUploadItem, IFormData } from "@models/interface/common/IMonacoUpload";
import { ITariffComplementaryCharge, IWeightRange } from "@models/interface/product/TariffComplementaryCharges";
import { IApplicationList } from "@models/interface/product/ApplicationModel";
import { IEquipmentSelector } from "@models/interface/product/EquipmentModel";
import { IChargeNameList, IParams } from "@models/interface/product/ChargeNameModel";
import { SSEService } from '@appServices/SSEService';
import { ITableOptions } from "src/ts/app/directives/monaco-data-table";
import { IModalInstanceService } from "angular-ui-bootstrap";
import { fileUploader as FileUploader } from 'angular-file-upload';
import { IChargeTableControl, IReasonUpdateModal, ITariffComplementaryChargeControl } from "../model/TariffComplementaryModel";
import { IPhysicalPersonListCustomFilter } from "../model/PhysicalPersonModel";
import { ArrayUtil } from '../../common/util/ArrayUtil';
import { SelectorModel } from "../../common/model/SelectorModel";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { ValidateUtil } from "../../common/util/ValidateUtil";
import { StringUtil } from "../../common/util/StringUtil";
import { BrowserTitle } from "../../common/BrowserTitle";
import { IFloatingMenu } from "../../common/interface/IFloatingMenu";
import { ILegalPersonListCustomFilter } from "../../product/model/LegalPersonModel";
import { IBrokerListCustomFilter } from "../../registration/model/BrokerModel";

interface ITariffComplementaryChargeModalScope extends IFormServiceScope {
    currentIndex: number;
    currentDisplayIndex: number;
    charge: ITariffComplementaryCharge;
    oldCharge: ITariffComplementaryCharge;
    operation: string;
    chargeApplicationList: SelectorModel[];
    chargeNameList: IChargeNameList[];
    currencyList: SelectorModel[];
    equipmentList: IEquipmentSelector[];
    weightRangeList: SelectorModel[];
    holderList: SelectorModel[];
    isPaymentNature: boolean;
    isReceivingNature: boolean;
    isExemption: boolean;
    isInclusion: boolean;
    isAirCargoType: boolean;
    isAirProduct: boolean;

    // modal functions
    applyCharge: () => Promise<void>;
    closeChargeModal: () => void;
    hasPreviousCharge: (currentIndex: number) => boolean;
    hasNextCharge: (currentIndex: number) => boolean;
    previousCharge: (currentIndex: number) => void;
    nextCharge: (currentIndex: number) => void;

    // check functions
    isNotApplicableComplement: (applicationComplement: SelectorModel) => boolean;
    isWeightRangeComplement: (applicationComplement: SelectorModel) => boolean;
    isEquipmentComplement: (applicationComplement: SelectorModel) => boolean;
    isVehicleComplement: (applicationComplement: SelectorModel) => boolean;

    // change functions
    updateParam: (selected: IChargeNameList) => void;
    setChargeNameId: () => void;
    flatChange: (chargeIndex: number) => void;
    weightRangeChange: () => void;
    freeOfChargePaymentChange: (charge: ITariffComplementaryCharge) => void;
    freeOfChargeReceivingChange: (charge: ITariffComplementaryCharge) => void;

    // get functions
    getChargeNameListByName: (search: string) => Promise<void>;
    getCurrencyListByName: (name: string) => Promise<void>;
    getWeightRangeListByName: (search: string) => Promise<void>;
    getHolderListByName: (search: string, holderId: string) => Promise<void>;

    // general functions
    updatePaymentUnit: () => void;
    updateReceivingUnit: () => void;
    updateHolderTypePayment: () => void;
    updateHolderTypeReceiving: () => void;
}

interface ITariffComplementaryScope extends IGridFormServiceScope {
    model: ITariffComplementaryModel;
    log: IViewLog;
    uploader: IUploader;
    user: any;
    productList: SelectorModel[];
    cargoTypeList: SelectorModel[];
    chargeTypeList: SelectorModel[];
    freightContractList: SelectorModel[];
    contractTypeList: SelectorModel[];
    providerList: SelectorModel[];
    incotermList: SelectorModel[];
    agentList: SelectorModel[];
    networkList: SelectorModel[];
    accountList: SelectorModel[];
    exporterList: SelectorModel[];
    importerList: SelectorModel[];
    notifyList: SelectorModel[];
    brokerList: SelectorModel[];
    externalBrokerList: SelectorModel[];
    transporterList: SelectorModel[];
    legalPersonCustomList: SelectorModel[];
    countryList: SelectorModel[];
    routingPointList: SelectorModel[];
    detailsList: SelectorModel[];
    eventList: SelectorModel[];
    situationList: SelectorModel[];
    typePaymentList: SelectorModel[];
    trueFalseList: SelectorModel[];
    tariffList: SelectorModel[];
    inclusionTypeList: SelectorModel[];
    holderTypeList: SelectorModel[];
    typePaymentNatureList: SelectorModel[];
    reasonValidityChangeList: SelectorModel[];
    reasonValueChangeList: SelectorModel[];
    processTypeList: SelectorModel[];
    routeEquipmentList: IEquipmentSelector[];
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: ITariffComplementaryModel;
    sessionService: ISessionService;
    menuFloating: IFloatingMenu;
    chargesTableOptions: ITableOptions;
    chargeTableControl: IChargeTableControl;
    chargesListDisplay: ITariffComplementaryChargeControl[];
    reasonUpdateModal: IReasonUpdateModal;
    tariffChangeConfirmation: ITariffChangeConfirmation[];

    editTariffComplementary: (airService: ITariffComplementaryModel) => Promise<void>;
    viewTariffComplementary: (airService: ITariffComplementaryModel) => Promise<void>;
    viewLogTariffComplementary: (airService: ITariffComplementaryModel) => Promise<void>;
    copyTariffComplementary: (airService: ITariffComplementaryModel) => Promise<void>;
    viewLogConfirmation: () => void;
    fetchData: (id: number, action: string) => Promise<void>;
    initPanels: () => void;
    checkDateValidity: (initialDate: Date, finalDate: Date) => void;
    collapseHeader: (elementId: string, state?: string) => void;
    generateConcatenated: () => void;
    formatConcatenatedChars: (value: string) => string;
    productChange: () => void;
    inclusionTypeChange: () => void;
    removeUpload: (model: IUploadItem) => Promise<boolean>;
    limitLines: (value: string, limit: number) => string;
    isExemption: () => boolean;
    showOnlyCurrentChargesChange: () => void;
    chargeFilterLoadReferenceChange: () => void;

    getFreightContractListByName: (name: string) => Promise<void>;
    getIncotermListByName: (search: string) => Promise<void>;
    getProviderListByName: (search: string) => Promise<void>;
    getAgentListByName: (search: string) => Promise<void>;
    getNetworkListByName: (search: string) => Promise<void>;
    getAccountListByName: (search: string) => Promise<void>;
    getExporterListByName: (search: string) => Promise<void>;
    getImporterListByName: (search: string) => Promise<void>;
    getNotifyListByName: (search: string) => Promise<void>;
    getBrokerListByName: (search: string) => Promise<void>;
    getBrokerOperationalListByName: (search: string) => void;
    getTransporterListByName: (search: string) => void;
    getLegalPersonListByNameCustom: (search: string) => Promise<void>;
    getCountryListByName: (search: string) => Promise<void>;
    getRoutingPointListByName(search: string, type?: string): Promise<void>;
}

export class TariffComplementaryRegisterController extends GridFormService implements IGridFormController {

    static $inject: string[] = ['$injector', '$scope'];
    private $scope: ITariffComplementaryScope;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private $filter: ng.IFilterService;
    private $sce: angular.ISCEService;
    private $compile: angular.ICompileService;
    private RestService: IRestService;
    private helperService: HelperService;
    private ProductService: ProductService;
    private collapseState: string;
    private ModalService: IModalService;
    private fileUploader: FileUploader;
    private SSEService: SSEService;

    private propertiesToIgnore: string[];
    private gridName: string;
    private formName: string;
    private modalChargeId: number;
    private modalReasonUpdateId: number;

    constructor($injector: ng.Injectable<any>, $scope: ITariffComplementaryScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.$filter = $injector.get('$filter');
        this.$sce = $injector.get('$sce');
        this.$compile = $injector.get('$compile');
        this.RestService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.helperService = $injector.get('HelperService');
        this.ProductService = $injector.get("ProductService");
        this.helperService = $injector.get('HelperService');
        this.ProductService = $injector.get("ProductService");
        this.fileUploader = $injector.get('FileUploader');
        this.ModalService = $injector.get('ModalService');

        this.modalReasonUpdateId = null;
        this.modalChargeId = null;
        this.collapseState = "show";
        this.gridName = 'GRID_TARIFF_COMPLEMENTARY';
        this.formName = 'tariffComplementary';
        this.propertiesToIgnore = ["DISPLAY_INDEX", "selected", "uniqueIdControl"];

        this.SSEService = new SSEService($injector, $scope, this.formService);
    }

    getUrlProduct(): string {
        const baseRoute: string = '/product';
        const urlProduct: string = this.config.productUrl + baseRoute;

        return urlProduct;
    }

    getFisFilesGenericRoute(): string {
        const baseRoute: string = '/fis/filesGeneric';
        const uploadRoute: string = this.config.fisUrl + baseRoute;

        return uploadRoute;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.uploader = this.getFileUploaderDefault();
            this.$scope.chargesTableOptions = this.getChargesTableOptions();
            this.$scope.chargeTableControl = this.getChargeTableControl();

            this.initForm(this, 'form', this.formName, 'Tariff Complementary ', true);
            await this.initGrid(this.gridName, '/tariffComplementary/list', true, true, null, true, true);

            this.$scope.$watch('model.CHARGE', (newValue: ITariffComplementaryCharge[], oldValue: ITariffComplementaryCharge[]) => {
                if (newValue !== oldValue) {
                    this.$scope.chargeTableControl.data = angular.copy(newValue);
                    this.applyChargeFilterTable();
                }
            })

            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        try {
            this.$scope.editTariffComplementary = async (tariffComplementary: ITariffComplementaryModel): Promise<void> => {
                let blockedObject = {
                    ID: tariffComplementary.ID,
                    NAME: tariffComplementary.CONCATENATED && tariffComplementary.CONCATENATED !== '' ? tariffComplementary.CONCATENATED : this.gridName + " - " + tariffComplementary.ID,
                    EMAIL: this.$scope.user['email'],
                    FORM_NAME: this.gridName
                };

                this.SSEService.closeEvents();
                this.SSEService.setBlockedObject(blockedObject);
                this.SSEService.initEvents();
                this.SSEService.events.onmessage = async (event) => {
                    const parsedData = JSON.parse(event.data);

                    if (!parsedData.status) {
                        const result = await this.SSEService.generate(parsedData);
                        if (result && !result.status) {
                            this.$rootScope.refreshPage();
                            return;
                        }
                        if (this.$scope.operation !== EOperation.VIEW || tariffComplementary.ID !== this.$scope.model.ID) this.$scope.fetchData(tariffComplementary.ID, EOperation.VIEW);
                    } else if (this.$scope.operation !== EOperation.EDIT || tariffComplementary.ID !== this.$scope.model.ID) {
                        this.$scope.fetchData(tariffComplementary.ID, EOperation.EDIT);
                    }
                };
            }

            this.$scope.viewTariffComplementary = async (tariffComplementary: ITariffComplementaryModel): Promise<void> => {
                this.SSEService.closeEvents();
                this.$scope.fetchData(tariffComplementary.ID, EOperation.VIEW);
            }

            this.$scope.viewLogTariffComplementary = async (tariffComplementary: ITariffComplementaryModel): Promise<void> => {
                this.SSEService.closeEvents();
                this.$scope.viewLog(tariffComplementary);
            }

            this.$scope.copyTariffComplementary = async (tariffComplementary: ITariffComplementaryModel): Promise<void> => {
                this.SSEService.closeEvents();
                this.$scope.fetchData(tariffComplementary.ID, EOperation.COPY);
            }

            this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
                this.fetchData(id, action);
            }

            this.$scope.checkDateValidity = (initialDate: Date, finalDate: Date): void => {
                this.checkDateValidity(initialDate, finalDate);
            }

            this.$scope.initPanels = () => {
                try {
                    const panels = document.getElementsByClassName("toggle-me");
                    if (panels) {
                        for (let i = 0; i < panels.length; i++) {
                            const panel = panels[i];
                            if (panel.id === "collapseBasicData" || panel.id === "collapseStakeHolders" || panel.id === "collapseRoutes" || panel.id === "collapseOtherDetails" || panel.id === "collapseCharges") {
                                if (!panel.classList.contains('in')) {
                                    $("#" + panel.id)["collapse"]("show");
                                }
                            } else if (panel.classList.contains('in')) {
                                $("#" + panel.id)["collapse"]("hide");
                            }
                        }
                    }
                    this.$scope.navigateBetweenIds('collapseBasicData');
                } catch (ex) {
                    this.formService.handleError(ex);
                }
            }

            this.$scope.collapseHeader = (elementId: string, state?: string) => {
                if (elementId === "collapseAll") {
                    this.collapseState = (this.collapseState === "hide") ? "show" : "hide";
                    $('.toggle-me')["collapse"](state || this.collapseState);
                } else if (elementId !== "registerBody") {
                    $("#" + elementId)["collapse"](state || 'toggle');
                }

                this.$scope.navigateBetweenIds(elementId);
            }

            this.$scope.getFreightContractListByName = async (search: string): Promise<void> => {
                await this.getFreightContractListByName(search);
            }

            this.$scope.getIncotermListByName = async (search: string) => {
                let incoterm: SelectorModel[] = [];
                if (search && search.length >= 3) {
                    incoterm = await this.getIncotermListByName(search);
                }
                this.$scope.incotermList = incoterm;
            }

            this.$scope.getProviderListByName = async (search: string) => {
                let providerList: SelectorModel[] = [];
                if (search && search.length >= 3) {
                    providerList = await this.getProviderListByName(search);
                }
                this.$scope.providerList = providerList;
            }

            this.$scope.getAgentListByName = async (search: string): Promise<void> => {
                return await this.getAgentListByName(search);
            }

            this.$scope.getNetworkListByName = async (name: string): Promise<void> => {
                return await this.getNetworkListByName(name);
            }

            this.$scope.getAccountListByName = async (search: string): Promise<void> => {
                await this.getAccountListByName(search);
            }

            this.$scope.getExporterListByName = async (search: string): Promise<void> => {
                return await this.getExporterListByName(search);
            }

            this.$scope.getImporterListByName = async (search: string): Promise<void> => {
                return await this.getImporterListByName(search);
            }

            this.$scope.getNotifyListByName = async (search: string): Promise<void> => {
                let notifyList: SelectorModel[] = [];
                if (search && search.length >= 3) notifyList = await this.getLegalPersonListByName({ search: search, specializations: [ELegalPersonSpecializationId.NOTIFY] });
                this.$scope.notifyList = notifyList;
            }

            this.$scope.getBrokerListByName = async (search: string) => {
                let brokerList: SelectorModel[] = [];
                if (search && search.length >= 3) {
                    const products = this.$scope.model.PRODUCT ? [this.$scope.model.PRODUCT.ID] : [];
                    brokerList = await this.getBrokerListByName({ products: products, search: search });
                }
                this.$scope.brokerList = brokerList;
            }

            this.$scope.getBrokerOperationalListByName = async (search: string): Promise<void> => {
                return await this.getExternalBrokerListByName(search);
            }

            this.$scope.getTransporterListByName = async (search: string): Promise<void> => {
                let transporterList: SelectorModel[] = [];
                if (search && search.length >= 3) transporterList = await this.getLegalPersonListByName({ specializations: null, search: search });
                this.$scope.transporterList = transporterList;
            }

            this.$scope.getLegalPersonListByNameCustom = async (search: string) => {
                let legalPersonCustomList: SelectorModel[] = [];
                if (search && search.length >= 3) {
                    legalPersonCustomList = await this.getLegalPersonListByNameCustom(search);
                }
                this.$scope.legalPersonCustomList = legalPersonCustomList;
            }

            this.$scope.getCountryListByName = async (search: string): Promise<void> => {
                await this.getCountryListByName(search);
            }

            this.$scope.getRoutingPointListByName = async (search: string, type?: string) => {
                await this.getRoutingPointListByName(search, type);
            }

            this.$scope.generateConcatenated = (): void => {
                this.generateConcatenated();
            }

            this.$scope.formatConcatenatedChars = (value: string) => {
                return StringUtil.formatConcatenatedChars(value);
            }

            this.$scope.productChange = () => {
                this.productChange();
            }

            this.$scope.inclusionTypeChange = () => {
                this.inclusionTypeChange();
            }

            this.$scope.removeUpload = (model: IUploadItem): Promise<boolean> => {
                return this.removeUpload(model);
            }

            this.$scope.limitLines = (value: string, limit: number) => {
                return StringUtil.limitLines(value, limit);
            }

            this.$scope.isExemption = () => this.$scope.model && this.$scope.model.TYPE && this.$scope.model.TYPE.ID == EInclusionExemption.EXEMPTION;

            this.$scope.showOnlyCurrentChargesChange = () => {
                if (this.$scope.chargeTableControl) {
                    this.$scope.chargeTableControl.chargeFilter.LOAD_REFERENCE = this.$scope.chargeTableControl.chargeFilter.SHOW_ONLY_CURRENT_CHARGES ? new Date() : null;
                    this.applyChargeFilterTable();
                }
            }

            this.$scope.chargeFilterLoadReferenceChange = () => {
                const loadReference = moment(this.$scope.chargeTableControl.chargeFilter.LOAD_REFERENCE, 'DD/MM/YYYY');

                if (!this.$scope.chargeTableControl.chargeFilter.LOAD_REFERENCE || loadReference.isValid()) this.applyChargeFilterTable();
            }

            this.$scope.viewLogConfirmation = () => this.viewLogConfirmation();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewTariffComplementary(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editTariffComplementary(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogTariffComplementary(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.setCopy(true);grid.appScope.copyTariffComplementary(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colType: IMonacoColumnDef = { name: "TYPE.NAME", displayName: "GENERAL.TYPE", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TYPE, null, "NAME")}}</div>' };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PRODUCT, null, "ID")}}</div>' };
            const colTypeCargo: IMonacoColumnDef = { name: "TYPE_CARGO.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TYPE_CARGO, null, "NAME")}}</div>' };
            const colChargeType: IMonacoColumnDef = { name: "CHARGE_TYPE.NAME", displayName: "FINANCIAL.CHARGE_TYPE", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.CHARGE_TYPE, null, "NAME")}}</div>' };
            const colContract: IMonacoColumnDef = { name: "CONTRACT.NAME", displayName: "BASIC_DATA.FREIGHT_CONTRACT", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.CONTRACT, null, "NAME")}}</div>' };
            const colContractType: IMonacoColumnDef = { name: "CONTRACT_TYPE.NAME", displayName: "PRODUCT.FREIGHT_CONTRACT_TYPE", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.CONTRACT_TYPE, null, "NAME")}}</div>' };
            const colIncoterm: IMonacoColumnDef = { name: "INCOTERM.CODE", displayName: "BASIC_DATA.INCOTERM", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.INCOTERM, null, "CODE")}}</div>' };
            const colProvider: IMonacoColumnDef = { name: "INVOLVED.PROVIDER.CODE", displayName: "BASIC_DATA.PROVIDER", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.INVOLVED.PROVIDER, null, "CODE")}}</div>' };
            const colNetwork: IMonacoColumnDef = { name: "INVOLVED.NETWORK.NAME", displayName: "BASIC_DATA.EXTERNAL_AGENT_NETWORK", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.INVOLVED.NETWORK, null, "NAME")}}</div>' };
            const colExternalAgent: IMonacoColumnDef = { name: "INVOLVED.EXTERNAL_AGENT.NAME", displayName: "BASIC_DATA.OVERSEAS_AGENT", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.INVOLVED.EXTERNAL_AGENT, null, "NAME")}}</div>' };
            const colAccounts: IMonacoColumnDef = { name: "INVOLVED.ACCOUNTS.NAME", displayName: "GENERAL.ACCOUNT", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.INVOLVED.ACCOUNTS, null, "NAME")}}</div>' };
            const colExporters: IMonacoColumnDef = { name: "INVOLVED.EXPORTERS.NAME", displayName: "BASIC_DATA.SHIPPER", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.INVOLVED.EXPORTERS, null, "NAME")}}</div>' };
            const colImporters: IMonacoColumnDef = { name: "INVOLVED.IMPORTERS.NAME", displayName: "BASIC_DATA.CONSIGNEE", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.INVOLVED.IMPORTERS, null, "NAME")}}</div>' };
            const colNotify: IMonacoColumnDef = { name: "INVOLVED.NOTIFY.NAME", displayName: "BASIC_DATA.NOTIFY", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.INVOLVED.NOTIFY, null, "NAME")}}</div>' };
            const colCustomsBroker: IMonacoColumnDef = { name: "INVOLVED.CUSTOMS_BROKER.NAME", displayName: "BASIC_DATA.CUSTOMS_BROKER", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.INVOLVED.CUSTOMS_BROKER, null, "NAME")}}</div>' };
            const colExternalBroker: IMonacoColumnDef = { name: "INVOLVED.EXTERNAL_BROKER.NAME", displayName: "BASIC_DATA.EXTERNAL_BROKER", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.INVOLVED.EXTERNAL_BROKER, null, "NAME")}}</div>' };
            const colIsotankProvider: IMonacoColumnDef = { name: "INVOLVED.ISOTANK_PROVIDER.NAME", displayName: "BASIC_DATA.ISOTANK_PROVIDER", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.INVOLVED.ISOTANK_PROVIDER, null, "NAME")}}</div>' };
            const colFlexiFitting: IMonacoColumnDef = { name: "INVOLVED.FLEXI_FITTING.NAME", displayName: "BASIC_DATA.FLEXI_FITTING", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.INVOLVED.FLEXI_FITTING, null, "NAME")}}</div>' };
            const colPup: IMonacoColumnDef = { name: "ROUTES.PUP.CODE", displayName: "GENERAL.PICK_UP_CODE", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.ROUTES.PUP, null, "CODE")}}</div>' };
            const colOtfs: IMonacoColumnDef = { name: "ROUTES.OTFS.CODE", displayName: "GENERAL.ORIGIN_TERMINAL_CODE", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.ROUTES.OTFS, null, "CODE")}}</div>' };
            const colPolAol: IMonacoColumnDef = { name: "ROUTES.POLAOL.CODE", displayName: "GENERAL.LOADING_PLACE_CODE", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.ROUTES.POLAOL, null, "CODE")}}</div>' };
            const colPodAod: IMonacoColumnDef = { name: "ROUTES.PODAOD.CODE", displayName: "GENERAL.UNLOADING_PLACE_CODE", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.ROUTES.PODAOD, null, "CODE")}}</div>' };
            const colDtfs: IMonacoColumnDef = { name: "ROUTES.DTFS.CODE", displayName: "GENERAL.DESTINATION_TERMINAL_CODE", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.ROUTES.DTFS, null, "CODE")}}</div>' };
            const colPld: IMonacoColumnDef = { name: "ROUTES.PLD.CODE", displayName: "GENERAL.PLACE_OF_DELIVERY_CODE", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.ROUTES.PLD, null, "CODE")}}</div>' };
            const colCountryOrigin: IMonacoColumnDef = { name: "ROUTES.COUNTRY_ORIGIN.NAME", displayName: "BASIC_DATA.ORIGIN_COUNTRY", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.ROUTES.COUNTRY_ORIGIN, null, "NAME")}}</div>' };
            const colCountryDestination: IMonacoColumnDef = { name: "ROUTES.COUNTRY_DESTINATION.NAME", displayName: "BASIC_DATA.DESTINATION_COUNTRY", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.ROUTES.COUNTRY_DESTINATION, null, "NAME")}}</div>' };
            const colFoodGrade: IMonacoColumnDef = { name: "FOODGRADE", displayName: "REGISTRATION.FOODGRADE", width: 150, cellFilter: "YesOrNo" };
            const colDetails: IMonacoColumnDef = { name: "DETAILS.NAME", displayName: "GENERAL.DETAILS", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.DETAILS, null, "NAME")}}</div>' };
            const colValidityStart: IMonacoColumnDef = { name: "VALIDITY_START", displayName: "PRODUCT.VALIDITY_START", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'' };
            const colValidityEnd: IMonacoColumnDef = { name: "VALIDITY_END", displayName: "PRODUCT.VALIDITY_END", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'' };
            const colSituation: IMonacoColumnDef = { name: "SITUATION.NAME", displayName: "GENERAL.SITUATION", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.SITUATION, null, "NAME")}}</div>' };
            const colMasterModality: IMonacoColumnDef = { name: "MASTER_MODALITY.NAME", displayName: "BASIC_DATA.MASTER_PAYMENT_MODE", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.MASTER_MODALITY, null, "NAME")}}</div>' };
            const colHouseModality: IMonacoColumnDef = { name: "HOUSE_MODALITY.NAME", displayName: "BASIC_DATA.HOUSE_PAYMENT_MODE", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.HOUSE_MODALITY, null, "NAME")}}</div>' };
            const colStraightBl: IMonacoColumnDef = { name: "STRAIGHT_BL.NAME", displayName: "BASIC_DATA.STRAIGHT_BL", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.STRAIGHT_BL, null, "NAME")}}</div>' };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 90, cellFilter: "YesOrNo" };
            const colTypeTariff: IMonacoColumnDef = { name: "TARIFF_TYPE.NAME", displayName: "PRODUCT.TARIFF_TYPE", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.TARIFF_TYPE, null, "NAME")}}</div>' };
            const colConcatenatedComplement: IMonacoColumnDef = { name: "CONCATENATED_COMPLEMENT", displayName: "GENERAL.CONCATENATED_COMPLEMENT", width: 150 };
            const colScore: IMonacoColumnDef = { name: "SCORE", displayName: "BASIC_DATA.SCORE", width: 120 };
            const colExpired: IMonacoColumnDef = { name: "EXPIRED", displayName: "GENERAL.EXPIRED", width: 100, cellFilter: "YesOrNo" };
            const colTransaction: IMonacoColumnDef = { name: "TRANSACTION.NAME", displayName: "BASIC_DATA.TRANSACTION", width: 150, cellTemplate: '<div class="grid-padding"> {{grid.appScope.getCONCAT(row.entity.TRANSACTION, null, "NAME")}}</div>' };
            const colChargeName: IMonacoColumnDef = { name: "CHARGE_NAME_EXHIBITION.CODE", displayName: "BASIC_DATA.CHARGE", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.CHARGE_NAME_EXHIBITION, null, "CODE")}}</div>' };
            const colValidityEvent: IMonacoColumnDef = { name: "VALIDITY_EVENT.NAME", displayName: "GENERAL.VALIDITY_BASIS", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.VALIDITY_EVENT, null, "NAME")}}</div>' };
            const colEquipWeightRange: IMonacoColumnDef = { name: "EQUIPMENT_WEIGHT_RANGE_VEHICLE.NAME", displayName: "PRODUCT.BASIS_COMPLEMENT", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.EQUIPMENT_WEIGHT_RANGE_VEHICLE, null, "NAME")}}</div>' };

            for (const column of columns) {
                switch (column.toUpperCase()) {

                    case 'TYPE':
                        columnDefs.push(colType);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'TYPE_CARGO':
                        columnDefs.push(colTypeCargo);
                        break;
                    case 'CHARGE_TYPE':
                        columnDefs.push(colChargeType);
                        break;
                    case 'CONTRACT':
                        columnDefs.push(colContract);
                        break;
                    case 'CONTRACT_TYPE':
                        columnDefs.push(colContractType);
                        break;
                    case 'INCOTERM':
                        columnDefs.push(colIncoterm);
                        break;
                    case 'PROVIDER':
                        columnDefs.push(colProvider);
                        break;
                    case 'NETWORK':
                        columnDefs.push(colNetwork);
                        break;
                    case 'EXTERNAL_AGENT':
                        columnDefs.push(colExternalAgent);
                        break;
                    case 'ACCOUNTS':
                        columnDefs.push(colAccounts);
                        break;
                    case 'EXPORTERS':
                        columnDefs.push(colExporters);
                        break;
                    case 'IMPORTERS':
                        columnDefs.push(colImporters);
                        break;
                    case 'NOTIFY':
                        columnDefs.push(colNotify);
                        break;
                    case 'CUSTOMS_BROKER':
                        columnDefs.push(colCustomsBroker);
                        break;
                    case 'EXTERNAL_BROKER':
                        columnDefs.push(colExternalBroker);
                        break;
                    case 'ISOTANK_PROVIDER':
                        columnDefs.push(colIsotankProvider);
                        break;
                    case 'FLEXI_FITTING':
                        columnDefs.push(colFlexiFitting);
                        break;
                    case 'PUP':
                        columnDefs.push(colPup);
                        break;
                    case 'OTFS':
                        columnDefs.push(colOtfs);
                        break;
                    case 'POLAOL':
                        columnDefs.push(colPolAol);
                        break;
                    case 'PODAOD':
                        columnDefs.push(colPodAod);
                        break;
                    case 'DTFS':
                        columnDefs.push(colDtfs);
                        break;
                    case 'PLD':
                        columnDefs.push(colPld);
                        break;
                    case 'COUNTRY_ORIGIN':
                        columnDefs.push(colCountryOrigin);
                        break;
                    case 'COUNTRY_DESTINATION':
                        columnDefs.push(colCountryDestination);
                        break;
                    case 'FOODGRADE':
                        columnDefs.push(colFoodGrade);
                        break;
                    case 'DETAILS':
                        columnDefs.push(colDetails);
                        break;
                    case 'VALIDITY_START':
                        columnDefs.push(colValidityStart);
                        break;
                    case 'VALIDITY_END':
                        columnDefs.push(colValidityEnd);
                        break;
                    case 'SITUATION':
                        columnDefs.push(colSituation);
                        break;
                    case 'MASTER_MODALITY':
                        columnDefs.push(colMasterModality);
                        break;
                    case 'HOUSE_MODALITY':
                        columnDefs.push(colHouseModality);
                        break;
                    case 'STRAIGHT_BL':
                        columnDefs.push(colStraightBl);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'TARIFF_TYPE':
                        columnDefs.push(colTypeTariff);
                        break;
                    case 'CONCATENATED_COMPLEMENT':
                        columnDefs.push(colConcatenatedComplement);
                        break;
                    case 'SCORE':
                        columnDefs.push(colScore);
                        break;
                    case 'EXPIRED':
                        columnDefs.push(colExpired);
                        break;
                    case 'TRANSACTION':
                        columnDefs.push(colTransaction);
                        break;
                    case 'CHARGE_NAME_EXHIBITION':
                        columnDefs.push(colChargeName);
                        break;
                    case 'VALIDITY_EVENT':
                        columnDefs.push(colValidityEvent);
                        break;
                    case 'EQUIPMENT_WEIGHT_RANGE_VEHICLE':
                        columnDefs.push(colEquipWeightRange);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        try {
            this.$scope.model = {
                _id: null,
                ID: null,
                CONCATENATED_COMPLEMENT: null,
                CONCATENATED: null,
                VALIDITY_START: null,
                VALIDITY_END: null,
                PRODUCT: null,
                TYPE_CARGO: null,
                CHARGE_TYPE: null,
                CONTRACT: null,
                CONTRACT_TYPE: null,
                INCOTERM: null,
                INVOLVED: this.initModelInvolved(),
                ROUTES: this.initModelRoutes(),
                DETAILS: null,
                FOODGRADE: false,
                HOUSE_MODALITY: null,
                MASTER_MODALITY: null,
                SITUATION: null,
                STRAIGHT_BL: null,
                FILES: null,
                OBSERVATION: null,
                TYPE: null,
                CHARGE: [],
                TARIFF_TYPE: null,
                TRANSACTION: null,
                ACTIVE: true,
                CREATED_AT: null,
                CREATED_BY: null,
                UPDATED_AT: null,
                UPDATED_BY: null,
                PROCESS_TYPE: null,
                FLEXITANK: false,
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getCargoTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_cargo`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getChargeTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/charge_origin`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getEventList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/event`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getSituationList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/registration_situation`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTypePaymentList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_payment`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getDetailsList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/tariff_complementary_details`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTrueFalse(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/yes_no`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getPaymentNatureList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/payment_nature`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTariffList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/tariff`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getInclusionTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/inclusion_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getHolderTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/holder_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTariffChargeConfirmationReasonValidityList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/tariff_change_confirmation_reason_validity`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTariffChargeConfirmationReasonValueList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/tariff_change_confirmation_reason_value`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getProcessTypeValueList(): Promise<any> {
        const { data: generic } = await this.helperService.get(`/generic/value/process_type`, null, 10000);
        return generic && generic.data ? generic.data : [];
        
    }

    private setDefaultSituation(situationList: SelectorModel[]): void {
        if (situationList && situationList.length > 0 && !this.$scope.model.SITUATION) {
            this.$scope.model.SITUATION = situationList.find(situation => situation.ID = ESituationId.RELEASED);
        }
    }

    private async getFreightContractListByName(search: string): Promise<void> {
        let freightContracts = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const result = await this.ProductService.post({ route: `/newFreightContract/list/custom`, data: { search: search } });
                freightContracts = result && result.data && result.data.data
                    ? result.data.data.map(freightContract => { return { ID: freightContract.ID, NAME: freightContract.ALLOG_FREIGHT_CONTRACT, CODE: null } })
                    : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.freightContractList = freightContracts;
            this.formService.unblock();
        }
    }

    private async getContractTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/contract_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getIncotermListByName(search: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            const scopeIncoterm = this.$scope.model.INCOTERM;
            if (search && search.length >= 3) {
                this.formService.block();
                const products = this.$scope.model.PRODUCT ? [this.$scope.model.PRODUCT.ID] : [];
                const incoterms = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/incoterm/list/custom`, { search, products }, 30000, false);
                if (incoterms && incoterms.length > 0) result = incoterms.map(incoterm => { return { ID: incoterm.ID.toString(), NAME: incoterm.NAME, CODE: incoterm.INITIALS } });

                if (scopeIncoterm) {
                    result = result.filter(item => scopeIncoterm.some(scopeAccount => scopeAccount.ID != item.ID))
                }
            }

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.incotermList = result
            this.formService.unblock();
            return result;
        }
    }

    private async getProviderListByName(search?: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const providers = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/provider/list/custom`, { search: search }, 30000, false);
            if (providers) result = providers.map(provider => { return { ID: provider.ID, NAME: provider.NAME, CODE: provider.SCAC_IATA, TYPE: provider.TYPE } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getAgentListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();
                const products = this.$scope.model.PRODUCT ? [this.$scope.model.PRODUCT.ID] : [];
                const agents = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/agent/list/custom`, { search, products }, 30000, false);
                result = agents ? agents.map(x => { return { ID: x.ID, NAME: x.NAME } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.agentList = result;
        }
    }

    private async getNetworkListByName(name: string): Promise<void> {
        let result = [];
        try {
            if (name && name.length >= 2) {
                this.formService.block();

                const networks = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/network/listByLegalPersonSpecType`, { name: name, types: ['12'] }, 30000, false);
                result = networks ? networks.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.networkList = result;
        }
    }

    private async getAccountListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            const scopeAccounts = this.$scope.model.INVOLVED ? this.$scope.model.INVOLVED.ACCOUNTS : null;
            if (search && search.length >= 3) {
                this.formService.block();
                const accounts = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/account/list/custom/`, { search: search }, 30000, false);
                if (accounts && accounts.length > 0) {
                    result = accounts.map(account => { return { ID: account.ID, NAME: account.NAME, CODE: account.LEGAL_PERSON.SHORT_NAME } });
                }
                if (scopeAccounts) {
                    result = result.filter(item => scopeAccounts.some(scopeAccount => scopeAccount.ID != item.ID))
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.accountList = result
            this.formService.unblock();
        }
    }

    private async getExporterListByName(search: string): Promise<void> {
        let result = [];
        try {
            result = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.EXPORTER], search: search });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.exporterList = result;
        }
    }

    private async getImporterListByName(search: string): Promise<void> {
        let result = [];
        try {
            result = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.IMPORTER], search: search });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.importerList = result;
        }
    }

    private async getLegalPersonListByName(legalPersonFilter: ILegalPersonListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        try {
            this.formService.block();
            if (legalPersonFilter.search && legalPersonFilter.search.length >= 3) {
                const legalPersons = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/legalPerson/list/custom`, legalPersonFilter, 30000, false);
                if (legalPersons) result = legalPersons.map(legalPerson => {
                    return {
                        ID: legalPerson.ID,
                        NAME: legalPerson.SHORT_NAME,
                        CODE: legalPerson.CORPORATE_NAME,
                        ID_LEGAL_PERSON: legalPerson.ID_LEGAL_PERSON,
                        LEGAL_PERSON: legalPerson.LEGAL_PERSON
                    }
                });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getLegalPersonListByNameCustom(search: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.formService.block();
            const legalPerson = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/legalPerson/list/custom/operational`, { specializations: [], search: search }, 30000, false);
            result = legalPerson ? legalPerson.map(legalPerson => { return { ID: legalPerson.ID.toString(), NAME: legalPerson.NAME, CODE: legalPerson.SCAC, ID_LEGAL_PERSON: legalPerson.ID_LEGAL_PERSON, LEGAL_PERSON: null } }) : [];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getBrokerListByName(brokerFilter?: IBrokerListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const brokers = await this.RestService.newObjectPromise(`${this.$baseUrl}/broker/list/custom`, brokerFilter, 30000, false);
            if (brokers) result = brokers.map(broker => { return { ID: broker.ID, NAME: broker.NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getExternalBrokerListByName(search: string): Promise<void> {
        try {
            if (search && search.length >= 3) {
                this.block();

                const productList = this.$scope.model.PRODUCT ? [this.$scope.model.PRODUCT.ID] : [];
                const filter: IBrokerListCustomFilter = { search: search, products: productList }
                const request: IMonacoRequest = {
                    data: {
                        ...filter
                    },
                    route: `/broker/list/custom/operational`,
                    timeout: 30000,
                }
                const result = await this.ProductService.post(request)
                if (result && result.data && result.status == 200) {
                    const externalBrokerList: SelectorModel[] = result.data.data.data;
                    this.$scope.externalBrokerList = externalBrokerList
                }
                this.unblock();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getCountryListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const countryList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/country/list/custom`, { search }, 30000, false);
                result = (countryList && countryList) ? countryList.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.countryList = result;
            this.formService.unblock();
        }
    }

    private async getRoutingPointListByName(search: string, type?: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const product = this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.ID;
                if (!product) return this.formService.handleError(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                let types = []

                if (type) types.push(type)
                else {
                    if (product == EProductId.MARITIME_IMPORT || this.$scope.model.PRODUCT.ID == EProductId.MARITIME_EXPORT) types.push('2');
                    if (product == EProductId.AIR_IMPORT || this.$scope.model.PRODUCT.ID == EProductId.AIR_EXPORT) types.push('4');
                }

                const routingPoints = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/routingPoint/list/custom/`, { name: search, types: types }, 30000, false);
                result = routingPoints ? routingPoints.map(x => { return { ID: x.ID, NAME: x.DISPLAY_NAME, CODE: x.CODE } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.routingPointList = result;
            this.formService.unblock();
        }
    }

    private async getChargeNameParamsList(): Promise<SelectorModel[]> {
        let result: IApplicationList[] = [];
        try {
            this.formService.block();

            const products: string[] = (this.$scope.model && this.$scope.model.PRODUCT) ? [this.$scope.model.PRODUCT.ID] : null;
            const typeCargos: string[] = (this.$scope.model && this.$scope.model.TYPE_CARGO) ? [this.$scope.model.TYPE_CARGO.ID] : null;

            const request = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/application/list/custom`, { products, typeCargos }, 30000, false);
            result = request ? request.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PERCENT: x.PERCENT, APPLICATION_COMPLEMENT: x.APPLICATION_COMPLEMENT, INTERNAL_SEQUENCE: x.INTERNAL_SEQUENCE, FREE_TYPING_AMOUNT_CHARGE: x.FREE_TYPING_AMOUNT_CHARGE, CT_WITHOUT_DOC: x.CT_WITHOUT_DOC, NAME_INTL: x.NAME_INTL } }) : [];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getEquipmentListByName(search?: string): Promise<IEquipmentSelector[]> {
        let equipmentList: IEquipmentSelector[] = [];
        try {
            this.formService.block();

            const equipments = await this.ProductService.post({ route: '/equipment/list/custom', data: { search: search, products: [], sysConvertIdToString: false } }, false);

            if (equipments && equipments.data && equipments.data.data && equipments.data.data.length) {
                const result: IEquipmentSelector[] = equipments.data.data;
                equipmentList = result ? result.map(equipment => { return { ID: equipment.ID, NAME: equipment.NAME, CODE: equipment.CODE, TEU: equipment.TEU, FEET: equipment.FEET } }) : [];
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.formService.unblock();
            return equipmentList;
        }
    }

    private async getChargeNameListByName(search: string): Promise<IChargeNameList[]> {
        let result: IChargeNameList[] = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const products: string[] = (this.$scope.model && this.$scope.model.PRODUCT) ? [this.$scope.model.PRODUCT.ID] : null;
                const paramTypeCargo: string[] = (this.$scope.model && this.$scope.model.TYPE_CARGO) ? [this.$scope.model.TYPE_CARGO.ID] : null;
                const types: string[] = (this.$scope.model && this.$scope.model.CHARGE_TYPE) ? [this.$scope.model.CHARGE_TYPE.ID] : null;

                const chargeNames = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/chargeName/list/custom/exhibition`, { search, types, products, paramTypeCargo }, 30000, false);
                result = chargeNames ? chargeNames.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PARAMS: x.PARAMS } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCurrencyListByName(name: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            if (name && name.length >= 2) {
                this.formService.block();

                const currencies = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/currency/list/custom`, { name: name }, 10000, false);
                result = (currencies) ? currencies.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getPhysicalPersonListByName(physicalPersonFilter?: IPhysicalPersonListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const physicalPersons = await this.RestService.newObjectPromise(`${this.$baseUrl}/physicalPerson/list/custom`, physicalPersonFilter, 30000, false);
            if (physicalPersons) result = physicalPersons.map(physicalPerson => { return { ID: physicalPerson.ID, NAME: physicalPerson.NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getWeightRangeListByName(search?: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.formService.block();
            const products = this.$scope.model.PRODUCT ? [this.$scope.model.PRODUCT.ID] : [];
            const weightRangeList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/weightRange/list/custom`, { search, products }, 30000, false);
            result = weightRangeList ? weightRangeList.map(x => { return { ID: x.ID, NAME: x.NAME } }) : [];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    initDependencies(): Promise<any> {
        try {
            const self: TariffComplementaryRegisterController = this;
            self.initCollapseEvents();

            return new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getProductList(),
                    self.getCargoTypeList(),
                    self.getChargeTypeList(),
                    self.getContractTypeList(),
                    self.getEventList(),
                    self.getSituationList(),
                    self.getTypePaymentList(),
                    self.getDetailsList(),
                    self.getTrueFalse(),
                    self.getPaymentNatureList(),
                    self.getTariffList(),
                    self.getInclusionTypeList(),
                    self.getHolderTypeList(),
                    self.getTariffChargeConfirmationReasonValidityList(),
                    self.getTariffChargeConfirmationReasonValueList(),
                    self.getProcessTypeValueList()
                ]).then((result: any) => {
                    self.$scope.productList = result[0];
                    self.$scope.cargoTypeList = result[1];
                    self.$scope.chargeTypeList = result[2];
                    self.$scope.contractTypeList = result[3];
                    self.$scope.eventList = result[4];
                    self.$scope.situationList = result[5];
                    self.$scope.typePaymentList = result[6];
                    self.$scope.detailsList = result[7];
                    self.$scope.trueFalseList = result[8];
                    self.$scope.typePaymentNatureList = result[9];
                    self.$scope.tariffList = result[10];
                    self.$scope.inclusionTypeList = result[11];
                    self.$scope.holderTypeList = result[12];
                    self.$scope.reasonValidityChangeList = result[13];
                    self.$scope.reasonValueChangeList = result[14];
                    self.$scope.processTypeList = result[15];
                    self.setDefaultSituation(self.$scope.situationList);

                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private initCollapseEvents() {
        angular.element('#collapseCharges').on('shown.bs.collapse', (event: JQuery.Event) => {
            if (event.target == event.currentTarget) {
                angular.element("#chargeTableFilter").addClass("in");
            }
        });

        angular.element('#collapseCharges').on('hidden.bs.collapse', (event: JQuery.Event) => {
            if (event.target == event.currentTarget) {
                angular.element("#chargeTableFilter").removeClass("in");
            }
        });
    }

    private async openReasonUpdateModal(confirmationData: ITariffChangeConfirmation) {
        try {
            if (!this.modalReasonUpdateId) this.modalReasonUpdateId = this.ModalService.newModal();
            this.$scope.reasonUpdateModal = { DESCRIPTION: confirmationData.DESCRIPTION, REASON_VALUE_CHANGE: null, REASON_VALIDITY_CHANGE: null, REASON_OBSERVATION: null };

            const body = `
            <div id="reasonModal">
                <div class="row form-group">
                    <div ng-if="${confirmationData.HAS_VALUE_CHANGE}" ng-class="${confirmationData.HAS_VALIDITY_CHANGE} ? 'col-lg-6' : 'col-lg-12'">
                        <label>{{'PRODUCT.REASON_VALUE_CHANGE' | translate}} <i class="fa fa-asterisk text-danger small-fontawesome" aria-hidden="true"
                        tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.MANDATORY_FIELD' | translate }}"
                        tooltip-append-to-body="true"></i></label>
                        <ui-select name="reasonValueChange" id="reasonValueChange"
                            ng-model="reasonUpdateModal.REASON_VALUE_CHANGE"
                            theme="bootstrap"
                            ng-change="selectorValidity(this.$select.ngModel.$name);"
                            ng-disabled="selectorDisabled(this.$select.ngModel.$name) || operation == 'view'"
                            ng-click="selectorFocus(this.$select.searchInput[0]);"
                            skip-focusser="true" required>
                            <ui-select-match placeholder="{{'GENERAL.UI_SELECT.SELECT' | translate }}">
                                {{$select.selected.NAME}}
                            </ui-select-match>
                            <ui-select-choices
                                repeat="item in reasonValueChangeList | filter: $select.search track by $index">
                                <div
                                    ng-bind-html="item.NAME | highlight: $select.search">
                                </div>
                            </ui-select-choices>
                            <ui-select-no-choice>
                                {{'GENERAL.UI_SELECT.EMPTY_SELECTOR_MESSAGE' | translate }}
                            </ui-select-no-choice>
                        </ui-select>
                    </div>
                    <div ng-if="${confirmationData.HAS_VALIDITY_CHANGE}" ng-class="${confirmationData.HAS_VALUE_CHANGE} ? 'col-lg-6' : 'col-lg-12'">
                        <label>{{'PRODUCT.REASON_VALIDITY_CHANGE' | translate}} <i class="fa fa-asterisk text-danger small-fontawesome" aria-hidden="true"
                        tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.MANDATORY_FIELD' | translate }}"
                        tooltip-append-to-body="true"></i></label>
                        <ui-select name="reasonValidityChange" id="reasonValidityChange"
                            ng-model="reasonUpdateModal.REASON_VALIDITY_CHANGE"
                            theme="bootstrap"
                            ng-change="selectorValidity(this.$select.ngModel.$name);"
                            ng-disabled="selectorDisabled(this.$select.ngModel.$name) || operation == 'view'"
                            ng-click="selectorFocus(this.$select.searchInput[0]);"
                            skip-focusser="true" required>
                            <ui-select-match placeholder="{{'GENERAL.UI_SELECT.SELECT' | translate }}">
                                {{$select.selected.NAME}}
                            </ui-select-match>
                            <ui-select-choices
                                repeat="item in reasonValidityChangeList | filter: $select.search track by $index">
                                <div
                                    ng-bind-html="item.NAME | highlight: $select.search">
                                </div>
                            </ui-select-choices>
                            <ui-select-no-choice>
                                {{'GENERAL.UI_SELECT.EMPTY_SELECTOR_MESSAGE' | translate }}
                            </ui-select-no-choice>
                        </ui-select>
                    </div>
                </div>
                <div class="row form-group" ng-if="reasonUpdateModal.REASON_VALUE_CHANGE.ID == '3' || reasonUpdateModal.REASON_VALIDITY_CHANGE.ID == '2'">
                    <div class="col-lg-12">
                        <label>{{'GENERAL.REMARKS' | translate}} <i class="fa fa-asterisk text-danger small-fontawesome" aria-hidden="true"
                        tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.MANDATORY_FIELD' | translate }}"
                        tooltip-append-to-body="true"></i></label>
                        <textarea class="form-control" name="reasonObservation" ng-model="reasonUpdateModal.REASON_OBSERVATION"></textarea>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12 space-list">
                        <ul class="list-group m-b-none">
                            <li class="list-group-item list-group-item-warning ellipsize full-width"
                                ng-repeat="description in reasonUpdateModal.DESCRIPTION track by $index"
                                style="max-width: 100%;" ellipsis-tooltip tooltip-placement="auto top"
                                uib-tooltip-html="description"
                                tooltip-enable="true" tooltip-append-to-body="true">
                                <span ng-bind-html="description"></span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            `;

            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    modalID: this.modalReasonUpdateId,
                    scope: this.$scope,
                    formService: 'register',
                    size: 'lg modal-overflow',
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing" && closed) {
                            const modalData: IReasonUpdateModal = this.$scope.reasonUpdateModal;
                            const hasOthers = modalData && ((modalData.REASON_VALUE_CHANGE && modalData.REASON_VALUE_CHANGE.ID == ETariffChangeConfirmationReasonValue.OTHERS) || (modalData.REASON_VALIDITY_CHANGE && modalData.REASON_VALIDITY_CHANGE.ID == ETariffChangeConfirmationReasonValidity.OTHERS));
                            if (!modalData || (modalData && (confirmationData.HAS_VALUE_CHANGE && !modalData.REASON_VALUE_CHANGE)) || (confirmationData.HAS_VALIDITY_CHANGE && !modalData.REASON_VALIDITY_CHANGE) || (hasOthers && !modalData.REASON_OBSERVATION)) {
                                event.preventDefault();
                                const msgError = this.formService.getTranslate('GENERAL.ALL_FIELDS_MANDATORY');
                                this.formService.notifyError(msgError);
                            } else if (!await this.customUpdate({
                                _id: null,
                                ID: null,
                                ID_TARIFF_LOCAL: null,
                                ID_INLAND_ROUTE: null,
                                ID_TARIFF_COMPLEMENTARY: this.$scope.model.ID,
                                ID_FREIGHT_ROUTES: null,
                                UPDATED_BY: null,
                                UPDATED_AT: null,
                                CREATED_BY: null,
                                CREATED_AT: null,
                                REASON_VALUE_CHANGE: modalData.REASON_VALUE_CHANGE,
                                REASON_VALIDITY_CHANGE: modalData.REASON_VALIDITY_CHANGE,
                                OBSERVATION: modalData.REASON_OBSERVATION,
                                DESCRIPTION: modalData.DESCRIPTION,
                                HAS_VALUE_CHANGE: confirmationData.HAS_VALUE_CHANGE,
                                HAS_VALIDITY_CHANGE: confirmationData.HAS_VALIDITY_CHANGE,
                            })) event.preventDefault();
                        }
                    }
                },
                {
                    closeButtonText: 'GENERAL.CANCEL',
                    actionButtonText: 'REGISTRATION.APPLY',
                    headerText: 'PRODUCT.TARIFF_CHANGE_CONFIRMATION',
                    bodyText: this.$sce.trustAsHtml(body),

                }
            );

            modalInstance.rendered.then(() => {
                const reasonModal = angular.element("#reasonModal");
                if (reasonModal) this.$compile(reasonModal)(this.$scope);
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }


    private async customUpdate(confirmationToUpdate?: ITariffChangeConfirmation): Promise<boolean> {
        let success = true;
        try {

            this.block();
            const request = await this.ProductService.post({ route: '/tariffComplementary/update', data: { data: this.$scope.model, oldData: this.$scope.scopeBeforeSave, confirmation: confirmationToUpdate, timeout: 30000 } });
            if (request && request.data && request.data.data) {
                const confirmation: boolean = request.data.data.confirmation;
                if (!confirmation) {
                    success = false;
                    if (!confirmationToUpdate) {
                        this.unblock();
                        const confirmationData: ITariffChangeConfirmation = request.data.data.data;
                        this.openReasonUpdateModal(confirmationData);
                    }
                }
                if (success) this.finishUpdateControl();
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return success;
        }
    }

    private async finishUpdateControl() {
        delete this.$scope['newRegister'];
        await this.updateGrid();
        this.$scope.showForm = false;
        this.$scope.model = null;
        this.$scope.error = null;
        this.$scope.operation = null;
        this.$scope.$applyAsync();
        this.$formService.notifySuccess(this.formService.getTranslate("REGISTRATION.SUCCESSFUL_REGISTER"));
    }

    async save(): Promise<boolean> {
        let proceed: boolean = true;
        try {
            if (this.$scope.uploader.queue && this.$scope.uploader.queue.length > 0) {
                const msgError = this.formService.getTranslate('GENERAL.MESSAGES.ERROR.FILES_IN_THE_QUEUE')
                proceed = false;
                this.handleError(msgError);
            }
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
            proceed = false;
        } finally {
            if (this.$scope.operation == EOperation.EDIT && proceed) {
                this.customUpdate();
                return false;
            } else return proceed;
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.initPanels();
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.$scope.chargesTableOptions.load(null, true);
            this.$scope.chargesTableOptions.crudButtons.add.disabled = false;
            this.$scope.chargesTableOptions.crudButtons.remove.disabled = false;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            BrowserTitle.$id = this.$scope.model.CONCATENATED;
            this.$scope.initPanels();
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")}`;
            if (this.$scope.menuFloating) this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
            this.$scope.chargesTableOptions.crudButtons.add.disabled = true;
            this.$scope.chargesTableOptions.crudButtons.remove.disabled = true;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            BrowserTitle.$id = this.$scope.model.CONCATENATED;
            this.$scope.initPanels();
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")}`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            if (this.$scope.menuFloating) this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            BrowserTitle.$id = this.$scope.model.CONCATENATED;
            this.$scope.model._id = null;
            this.$scope.model.ID = null;
            await this.clearFields(this.$scope.model);
            if (this.$scope.model.CHARGE && this.$scope.model.CHARGE.length) {
                this.$scope.model.CHARGE.forEach(charge => {
                    charge._id = null;
                    charge.ID = null;
                });
            }

            this.$scope.scopeBeforeSave = null;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route: string = this.$scope.operation === 'register' ? 'insert' : 'update';

        return {
            route: `/tariffComplementary/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    private getChargesTableOptions(): ITableOptions {
        return {
            persistName: "tariffComplementaryCharges",
            pagination: false,
            search: true,
            advancedSearch: false,
            showSearchClearButton: true,
            clickToSelect: true,
            singleSelect: true,
            showLoading: true,
            showColumns: true,
            showColumnsSearch: true,
            showColumnsToggleAll: true,
            showFullscreen: true,
            showToggle: false,
            showMultiSort: true,
            showMultiSortButton: true,
            height: '100%',
            filterControl: false,
            fixedColumns: true,
            fixedNumber: 3,
            sortable: true,
            serverSort: false,
            crudButtons: {
                add: { fn: async () => this.addCharge(), name: "addCharge" },
                edit: { fn: async (index: number) => this.editCharge(index), name: "editCharge" },
                copy: { fn: async (index: number) => this.copyCharge(index), name: "copyCharge" },
                remove: { fn: async (index: number) => this.removeCharge(index), name: "removeCharge" }
            },
            customToolbarButtons: [
                {
                    fn: async () => this.viewChargeLog(),
                    name: "log",
                    class: "btn btn-xs btn-success",
                    icon: "fa fa-history",
                    disabled: this.$scope.operation == EOperation.VIEW
                }
            ],
            formatAddLevel: () => this.formService.getTranslate("GENERAL.ADD_LEVEL"),
            formatCancel: () => this.formService.getTranslate("GENERAL.CLOSE"),
            formatColumn: () => this.formService.getTranslate("GENERAL.COLUMNS"),
            formatDeleteLevel: () => this.formService.getTranslate("GENERAL.REMOVE_LEVEL"),
            formatMultipleSort: () => this.formService.getTranslate("GENERAL.MULTIPLE_SORT"),
            formatOrder: () => this.formService.getTranslate("GENERAL.ORDER"),
            formatSort: () => this.formService.getTranslate("GENERAL.SORT"),
            formatSortBy: () => this.formService.getTranslate("GENERAL.SORT_BY"),
            formatSortOrders: () => { return { asc: this.formService.getTranslate("GENERAL.ASCENDING_SORT"), desc: this.formService.getTranslate("GENERAL.DESCENDING_SORT") } },
            formatThenBy: () => this.formService.getTranslate("GENERAL.AND_BY"),
            headerStyle: (column: BootstrapTableColumn) => {
                let headerStyleObj = {};
                if (column.field.search("PAYMENT") >= 0) headerStyleObj = { css: { 'background': "#f2dede" } };
                else if (column.field.search("RECEIVING") >= 0) headerStyleObj = { css: { 'background': "#dff0d8" } };
                return headerStyleObj;
            },
            showColumnsRename: [
                { dataField: "CHARGE_NAME_EXHIBITION", title: this.formService.getTranslate("BASIC_DATA.CHARGE") },
                { dataField: "APPLICATION", title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS") },
                { dataField: "APPLICATION.APPLICATION_COMPLEMENT", title: this.formService.getTranslate("PRODUCT.BASIS_COMPLEMENT") },
                { dataField: "PAYMENT.CURRENCY", title: this.formService.getTranslate("GENERAL.CURRENCY") },
                { dataField: "PAYMENT.UNITARY", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY_C") },
                { dataField: "PAYMENT.MIN", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM_C") },
                { dataField: "RECEIVING.CURRENCY", title: this.formService.getTranslate("GENERAL.CURRENCY") },
                { dataField: "RECEIVING.UNITARY", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY_V") },
                { dataField: "RECEIVING.MIN", title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM_V") },
                { dataField: "VALIDITY_START", title: this.formService.getTranslate("PRODUCT.VALIDITY_START") },
                { dataField: "VALIDITY_END", title: this.formService.getTranslate("PRODUCT.VALIDITY_END") },
            ],
            onPostBody: (chargeList: ITariffComplementaryChargeControl[]) => {
                this.$scope.chargesListDisplay = chargeList && chargeList.map((charge: ITariffComplementaryChargeControl, index: number) =>
                    Object.assign(charge, charge.DISPLAY_INDEX = index));

                return true;
            },
            columns: [
                { field: 'selected', title: this.formService.getTranslate("GENERAL.UI_SELECT.SELECT"), checkbox: true, showSelectTitle: true, cellStyle: () => { return { css: { width: '80px' } } } },
                { field: 'CHARGE_NAME_EXHIBITION', title: this.formService.getTranslate("BASIC_DATA.CHARGE"), formatter: (data) => data ? `${data.CODE} - ${data.NAME}` : data, sortable: true, searchable: true },
                { field: 'APPLICATION', title: this.formService.getTranslate("FINANCIAL.CHARGE_BASIS"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'APPLICATION.APPLICATION_COMPLEMENT', title: this.formService.getTranslate("PRODUCT.BASIS_COMPLEMENT"), formatter: (application: IApplicationList, charge: ITariffComplementaryCharge) => this.getChargeApplicationComplementValue(application, charge), sortable: true, searchable: true },
                { field: 'PAYMENT.CURRENCY', title: this.formService.getTranslate("GENERAL.CURRENCY"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'PAYMENT.UNITARY', title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'PAYMENT.MIN', title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'RECEIVING.CURRENCY', title: this.formService.getTranslate("GENERAL.CURRENCY"), formatter: (data) => data ? data.CODE : data, sortable: true, searchable: true },
                { field: 'RECEIVING.UNITARY', title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.UNITARY"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'RECEIVING.MIN', title: this.formService.getTranslate("GENERAL.ABBREVIATIONS.MINIMUM"), formatter: (data) => this.$filter("number")(data, 2), sortable: true, searchable: true },
                { field: 'VALIDITY_START', title: this.formService.getTranslate("PRODUCT.VALIDITY_START"), formatter: (data) => moment(data).locale(['pt', 'br']).format('L'), sortable: true, searchable: true },
                { field: 'VALIDITY_END', title: this.formService.getTranslate("PRODUCT.VALIDITY_END"), formatter: (data) => moment(data).locale(['pt', 'br']).format('L'), sortable: true, searchable: true },
            ],
        };
    }

    private getChargeApplicationComplementValue(applicationComplement: IApplicationList, charge: ITariffComplementaryCharge): string {
        let complementValue: string = "";

        if (applicationComplement.ID == EApplicationComplementId.EQUIPMENT) complementValue = this.$scope.getCONCAT(charge.EQUIPMENT, null, "CODE");
        else if (applicationComplement.ID == EApplicationComplementId.WEIGHT_RANGE) complementValue = this.$scope.getCONCAT(charge.WEIGHT_RANGE);
        else if (applicationComplement.ID == EApplicationComplementId.VEHICLE) complementValue = this.$scope.getCONCAT(charge.VEHICLE_TYPE);

        return complementValue;
    }

    private async addCharge(): Promise<void> {
        try {
            if (!this.$scope.model.CHARGE) this.$scope.model.CHARGE = [];

            const charge: ITariffComplementaryCharge = {
                _id: null,
                ID: null,
                ID_TARIFF_COMPLEMENTARY: null,
                ID_CHARGE_NAME_EXHIBITION: null,
                CHARGE_NAME_EXHIBITION: null,
                APPLICATION: null,
                FLAT: null,
                EQUIPMENT: null,
                VEHICLE_TYPE: null,
                WEIGHT_RANGE: null,
                VALIDITY_START: null,
                VALIDITY_END: null,
                VALIDITY_EVENT: null,
                PAYMENT: {
                    ID_CURRENCY: null,
                    UNITARY: null,
                    MIN: null,
                    FREE_OF_CHARGE: null,
                    ID_LEGAL_PERSON_HOLDER: null,
                    ID_PHYSICAL_PERSON_HOLDER: null,
                    WEIGHT_RANGE_CHARGE: null,
                    HOLDER_TYPE: null,
                    CURRENCY: null,
                    LEGAL_PERSON_HOLDER: null,
                    PHYSICAL_PERSON_HOLDER: null,
                    MODALITY: null,
                    FREIGHT_BILLING: null,
                },
                RECEIVING: {
                    ID_CURRENCY: null,
                    UNITARY: null,
                    MIN: null,
                    FREE_OF_CHARGE: null,
                    ID_LEGAL_PERSON_HOLDER: null,
                    ID_PHYSICAL_PERSON_HOLDER: null,
                    WEIGHT_RANGE_CHARGE: null,
                    HOLDER_TYPE: null,
                    CURRENCY: null,
                    LEGAL_PERSON_HOLDER: null,
                    PHYSICAL_PERSON_HOLDER: null,
                    MODALITY: null,
                    FREIGHT_BILLING: null
                }
            }

            this.openChargeModal(charge);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private editCharge(index: number): void {
        try {
            if (!index && index != 0) throw Error('Missing index in editCharge');

            let charge: ITariffComplementaryCharge = null;
            charge = this.getRatesByTheCorrectIndex(index);
            if (!charge) throw Error('charge is null');

            this.openChargeModal(charge, this.$scope.model.CHARGE.indexOf(charge), charge.DISPLAY_INDEX);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private copyCharge(index: number): void {
        try {
            if (!index && index != 0) throw Error('Missing index in copyCharge');

            const charge: ITariffComplementaryCharge = this.getRatesByTheCorrectIndex(index);
            if (!charge) throw Error('charge is null');

            const copyCharge: ITariffComplementaryCharge = angular.copy(charge);
            copyCharge._id = null;
            copyCharge.ID = null;

            this.openChargeModal(copyCharge);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeCharge(index: number): Promise<void> {
        try {
            if (!index && index !== 0) throw Error('Missing index in removeCharge');

            const thatTranslated: string = this.formService.getTranslate("GENERAL.GENDER.THAT", null, true);
            const chargeTranslated: string = this.formService.getTranslate("BASIC_DATA.CHARGE", null, true);

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thatTranslated, prop: chargeTranslated })
            });

            if (!modal) return;

            this.formService.block();

            if (this.$scope.model.CHARGE) {
                const charge: ITariffComplementaryCharge = this.getRatesByTheCorrectIndex(index);

                this.$scope.model.CHARGE.splice(this.$scope.model.CHARGE.indexOf(charge), 1);
                this.$scope.chargeTableControl.data = this.$scope.model.CHARGE;
                this.$scope.chargesTableOptions.load(null, true);
            }

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getRatesByTheCorrectIndex(index: number): ITariffComplementaryCharge {
        let chargeResult: ITariffComplementaryCharge = null;

        if (Object.is(this.$scope.model.CHARGE[index], this.$scope.chargeTableControl.data[index])) {
            chargeResult = this.$scope.model.CHARGE[index];
        } else {
            for (const fee of this.$scope.model.CHARGE) {
                if (Object.is(fee, this.$scope.chargeTableControl.data[index])) {
                    chargeResult = fee;
                }
            }
        }

        return chargeResult;
    }

    private async viewChargeLog(): Promise<void> {
        try {
            this.formService.block();
            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/tariffComplementary/charge/viewLog/${this.$scope.model.ID.toString()}/CHARGES`, 30000, null, false);
            const log: IViewLog = {
                operation: 'history',
                number: this.$scope.model.ID.toString(),
                list: [],
                show: true,
                showCloseButton: false,
                searchQuery: '',
                originalList: [],
            }
            log.list = request.data;
            log.originalList = angular.copy(log.list);

            const modalId = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    modalID: modalId,
                    template: require('../../common/view/modals/viewLog.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG"
                }
            );

            modalInstance.opened.then(async () => {
                const modalScope = await this.ModalService.getModalScope(modalId);
                modalScope.customLogProperties = this.$scope.customLogProperties;
                modalScope.log = log;
                modalScope.$applyAsync();
            });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async viewLogConfirmation(): Promise<void> {
        try {
            this.formService.block();
            const retrieveLog = await this.ProductService.get({ route: `/tariffChangeConfirmation/tabs/charge/getTariffChangeConfirmation/tariffComplementary/${this.$scope.model.ID}` });
            this.formService.unblock();

            if (retrieveLog && retrieveLog.data && retrieveLog.data.data && retrieveLog.data.data.length) {
                this.$scope.tariffChangeConfirmation = retrieveLog.data.data;

                const modalId: number = this.ModalService.newModal();
                await this.ModalService.showModalConfirmation(
                    {
                        modalID: modalId,
                        scope: this.$scope,
                        template: require('../view/modals/tariffLocalChangeConfirmationLogModal.html'),
                        size: 'vlg modal-overflow'
                    },
                    {
                        closeButtonText: 'GENERAL.CLOSE'
                    }
                );
            } else {
                this.formService.notifyInfo(this.formService.getTranslate("REGISTRATION.NO_RECORD_FOUND"));
            }
        } catch (ex) {
            this.formService.handleError(ex);
            this.formService.unblock();
        }
    }

    private async openChargeModal(charge: ITariffComplementaryCharge, index?: number, displayIndex?: number) {
        try {
            let errorMessage: string = '';

            if (!this.$scope.model.TYPE_CARGO) errorMessage = 'type cargo';
            if (!this.$scope.model.TYPE) errorMessage += (errorMessage ? ', ' : '') + 'type';
            if (!this.$scope.model.CHARGE_TYPE) errorMessage += (errorMessage ? ', ' : '') + 'charge type';
            if (!this.$scope.model.PRODUCT) errorMessage += (errorMessage ? ', ' : '') + 'product';
            if (errorMessage) throw Error(`Select ${errorMessage} first`);

            this.modalChargeId = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    modalID: this.modalChargeId,
                    scope: this.$scope,
                    formService: 'register',
                    template: require("../view/modals/tariffComplementaryChargeModal.html"),
                    size: 'vlg',
                    keyboard: true,
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing") {
                            if (reason.toString() == "escape key press") event.preventDefault();
                            if (!closed) {
                                const modalScope: ITariffComplementaryChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
                                modalScope.closeChargeModal();
                            }
                        }
                    }
                },
                null
            );

            modalInstance.rendered.then(async () => {
                const modalScope: ITariffComplementaryChargeModalScope = await this.buildChargeModalScope(index, displayIndex, charge);

                this.$timeout(() => modalScope.oldCharge = angular.copy(modalScope.charge));
                await modalScope.$applyAsync();

                if (charge && charge.PAYMENT && charge.RECEIVING) {
                    if ((charge.PAYMENT.WEIGHT_RANGE_CHARGE && charge.PAYMENT.WEIGHT_RANGE_CHARGE.length) || (charge.RECEIVING.WEIGHT_RANGE_CHARGE && charge.RECEIVING.WEIGHT_RANGE_CHARGE.length)) {
                        angular.element('.collapseWeightRanges')["collapse"]("show");
                    }
                }

                this.$timeout(() => this.validateChargeFields(modalScope));
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private validateChargeFields(modalScope: ITariffComplementaryChargeModalScope) {
        if (!modalScope) return;

        modalScope.selectorValidity([
            "chargeNameExhibition",
            "param",
            "weightRange",
            "equipment",
            "vehicleType",
            "validityBasis",
            "paymentCurrency",
            "receivingCurrency",
            "validityEvent",
            "paymentChargeHolderType",
            "receivingChargeHolderType",
            "paymentLegalPersonHolder",
            "receivingLegalPersonHolder",
            "paymentModality",
            "receivingModality",
            "receivingMin",
            "paymentMin",
            "paymentUnitary",
            "receivingUnitary",
            "paymentPhysicalPersonHolder",
            "receivingPhysicalPersonHolder"
        ]);
    }

    private async buildChargeModalScope(index: number, displayIndex: number, charge: ITariffComplementaryCharge): Promise<ITariffComplementaryChargeModalScope> {
        try {
            const modalScope: ITariffComplementaryChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
            modalScope.operation = this.$scope.operation == EOperation.VIEW ? EOperation.VIEW : (!index && index != 0) ? EOperation.NEW : EOperation.EDIT;
            modalScope.currentIndex = index;
            modalScope.currentDisplayIndex = displayIndex;
            modalScope.charge = angular.copy(charge);
            modalScope.chargeApplicationList = await this.getChargeNameParamsList();
            modalScope.equipmentList = await this.getEquipmentListByName();
            modalScope.isExemption = this.$scope.model.TYPE && this.$scope.model.TYPE.ID == EInclusionExemption.EXEMPTION;
            modalScope.isInclusion = this.$scope.model.TYPE && this.$scope.model.TYPE.ID == EInclusionExemption.INCLUSION;
            modalScope.isAirCargoType = this.$scope.model.TYPE_CARGO && this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.AIR;
            modalScope.isAirProduct = this.$scope.model.PRODUCT && (this.$scope.model.PRODUCT.ID == EProductId.AIR_IMPORT || this.$scope.model.PRODUCT.ID == EProductId.AIR_EXPORT);
            modalScope.isPaymentNature = this.$scope.model.TRANSACTION && this.$scope.model.TRANSACTION.some(item => item.ID == EPaymentNatureId.PAYMENT);
            modalScope.isReceivingNature = this.$scope.model.TRANSACTION && this.$scope.model.TRANSACTION.some(item => item.ID == EPaymentNatureId.RECEIVING);
            modalScope.applyCharge = async () => {
                if (await this.applyCharge(modalScope.currentIndex, modalScope.charge, modalScope.operation, true)) {
                    this.$scope.chargeTableControl.data = this.$scope.model.CHARGE;
                    this.applyChargeFilterTable();
                }
            }
            modalScope.closeChargeModal = () => {
                this.closeChargeModal();
            }
            modalScope.hasPreviousCharge = (currentDisplayIndex: number) => {
                return currentDisplayIndex > 0;
            }
            modalScope.hasNextCharge = (currentDisplayIndex: number) => {
                return (currentDisplayIndex || currentDisplayIndex == 0) && this.$scope.chargesListDisplay && (this.$scope.chargesListDisplay.length - 1 > currentDisplayIndex);
            }
            modalScope.previousCharge = (currentDisplayIndex: number) => {
                this.previousCharge(currentDisplayIndex);
            }
            modalScope.nextCharge = (currentDisplayIndex: number) => {
                this.nextCharge(currentDisplayIndex);
            }
            modalScope.isNotApplicableComplement = (applicationComplement: SelectorModel): boolean => {
                return !applicationComplement || (applicationComplement && applicationComplement.ID == EApplicationComplementId.NOT_APPLICABLE);
            }
            modalScope.isWeightRangeComplement = (applicationComplement: SelectorModel): boolean => {
                return applicationComplement && applicationComplement.ID == EApplicationComplementId.WEIGHT_RANGE;
            }
            modalScope.isEquipmentComplement = (applicationComplement: SelectorModel): boolean => {
                return applicationComplement && applicationComplement.ID == EApplicationComplementId.EQUIPMENT;
            }
            modalScope.isVehicleComplement = (applicationComplement: SelectorModel): boolean => {
                return applicationComplement && applicationComplement.ID == EApplicationComplementId.VEHICLE;
            }
            modalScope.updateParam = (selected: IChargeNameList): void => {
                this.updateParam(selected);
            }
            modalScope.setChargeNameId = (): void => {
                this.setChargeNameId();
            }
            modalScope.flatChange = () => {
                this.flatChange();
            }
            modalScope.weightRangeChange = () => {
                this.weightRangeChange();
            }
            modalScope.freeOfChargePaymentChange = (charge: ITariffComplementaryCharge) => {
                this.freeOfChargePaymentChange(charge);
                this.$timeout(() => { modalScope.selectorValidity("paymentCurrency"); });
            }
            modalScope.freeOfChargeReceivingChange = (charge: ITariffComplementaryCharge) => {
                this.freeOfChargeReceivingChange(charge);
                this.$timeout(() => { modalScope.selectorValidity("receivingCurrency"); });
            }
            modalScope.getChargeNameListByName = async (search: string): Promise<void> => {
                modalScope.chargeNameList = await this.getChargeNameListByName(search);
            }
            modalScope.getCurrencyListByName = async (name: string): Promise<void> => {
                modalScope.currencyList = await this.getCurrencyListByName(name);
            }
            modalScope.getWeightRangeListByName = async (search: string): Promise<void> => {
                modalScope.weightRangeList = await this.getWeightRangeListByName(search);
            }
            modalScope.getHolderListByName = async (search: string, holderId: string) => {
                let holderList: SelectorModel[] = [];
                if (search && search.length >= 3 && holderId) {
                    holderList = holderId == "1" ? await this.getLegalPersonListByName({ specializations: [], search: search }) : await this.getPhysicalPersonListByName({ specializations: [], roles: [], search: search });
                }
                modalScope.holderList = holderList;
            }
            modalScope.updatePaymentUnit = (): void => {
                this.updatePaymentUnit();
            }
            modalScope.updateReceivingUnit = (): void => {
                this.updateReceivingUnit();
            }
            modalScope.updateHolderTypePayment = (): void => {
                this.updateHolderTypePayment();
            }
            modalScope.updateHolderTypeReceiving = (): void => {
                this.updateHolderTypeReceiving();
            }

            return modalScope;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private applyChargeFilterTable(): void {
        if (!this.$scope.model) return;

        let chargeList = this.$scope.model.CHARGE;
        this.updateRouteChargeList(chargeList);

        const loadReference: moment.Moment = moment(moment(this.$scope.chargeTableControl.chargeFilter.LOAD_REFERENCE).format('YYYY-MM-DD'));
        if (loadReference.isValid()) {
            chargeList = chargeList && chargeList.filter(charge => {
                const start: moment.Moment = moment(moment(charge.VALIDITY_START).format('YYYY-MM-DD'));
                const end: moment.Moment = moment(moment(charge.VALIDITY_END).format('YYYY-MM-DD'));

                return (loadReference.isBetween(start, end) || loadReference.isSame(start) || loadReference.isSame(end));
            });
        }

        this.$scope.routeEquipmentList = [];
        this.$scope.chargeTableControl.data = chargeList;
        this.$scope.chargesTableOptions.load(null, true);
    }

    private updateRouteChargeList(chargeList: ITariffComplementaryCharge[]): void {
        if (chargeList && chargeList.length) {
            for (const charge of chargeList) {
                if (charge.EQUIPMENT) this.$scope.routeEquipmentList = charge.EQUIPMENT.map(equipment => equipment);
            }
        }
    }

    private async applyCharge(index: number, charge: ITariffComplementaryCharge, operation: string, closeModal?: boolean): Promise<boolean> {
        let success: boolean = true;
        try {
            if (!charge) throw Error('Missing charge in applyCharge');

            const hasInvalid: boolean = this.hasInvalidRequiredElements('TariffComplementaryChargeModal') || !ValidateUtil.isValidDateRange(charge.VALIDITY_START, charge.VALIDITY_END);

            if (hasInvalid) {
                success = false;
            } else {
                if (operation == EOperation.NEW) {
                    if (!this.$scope.model.CHARGE) this.$scope.model.CHARGE = [];
                    this.$scope.model.CHARGE.push(charge);
                }
                else if (operation == EOperation.EDIT) {
                    charge.ID_CHARGE_NAME_EXHIBITION = charge.ID_CHARGE_NAME_EXHIBITION;
                    this.$scope.model.CHARGE.splice(index, 1, charge);
                }
                if (closeModal) {
                    this.ModalService.closeModal(this.modalChargeId);
                    this.modalChargeId = 0;
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            const msgSuccess: string = this.formService.getTranslate('FINANCIAL.CHARGE_DATA_SAVED_SUCCESSFULLY');
            if (success) this.formService.notifySuccess(msgSuccess);
            return success;
        }
    }

    private hasInvalidRequiredElements(elementId: string): boolean {
        if (!elementId) return false;

        const isInvalid: boolean = FormService2.hasRequiredElements('#' + elementId);
        if (isInvalid) this.formService.notifyError(this.formService.getTranslate("GENERAL.ALL_FIELDS_MANDATORY"));

        return isInvalid;
    }

    private async closeChargeModal() {
        const modalScope: ITariffComplementaryChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);

        if (this.hasChanges(JSON.stringify(modalScope.charge), JSON.stringify(modalScope.oldCharge))) {
            const confirm = await this.modalSaveConfirmation("GENERAL.CLOSE", "GENERAL.CLOSE");
            if (confirm && !await this.applyCharge(modalScope.currentIndex, modalScope.charge, modalScope.operation)) return;
            else this.$scope.chargesTableOptions.load(null, true);
        }

        this.ModalService.closeModal(this.modalChargeId);
        this.modalChargeId = 0;
    }

    private hasChanges(newObj: Object, oldObj: Object, propertiesToIgnore?: string[]): boolean {
        if (!propertiesToIgnore) propertiesToIgnore = this.propertiesToIgnore;

        if (propertiesToIgnore) {
            const newAux: Object = newObj ? angular.copy(newObj) : null;
            const oldAux: Object = oldObj ? angular.copy(oldObj) : null;

            for (const property of propertiesToIgnore) {
                if (newAux && angular.isDefined(newAux[property])) delete newAux[property];
                if (oldAux && angular.isDefined(oldAux[property])) delete oldAux[property];
            }

            return !angular.equals(JSON.stringify(newAux), JSON.stringify(oldAux));
        }

        return !angular.equals(JSON.stringify(newObj), JSON.stringify(oldObj));
    }

    private async modalSaveConfirmation(headerText: string, closeButtonText: string, bodyText?: string): Promise<boolean> {
        return await this.ModalService.showModalConfirmation({}, {
            headerText: headerText,
            bodyText: this.formService.getTranslate(bodyText ? bodyText : 'REGISTRATION.MESSAGES.ERROR.UPDATE_NOT_SAVED'),
            actionButtonText: "GENERAL.SAVE",
            closeButtonText: closeButtonText
        });
    }

    private async previousCharge(currentDisplayIndex: number): Promise<void> {
        const modalScope: ITariffComplementaryChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
        const previousDisplayCharge: ITariffComplementaryChargeControl = this.$scope.chargesListDisplay[currentDisplayIndex - 1];
        const previousChargeIndex: number = this.$scope.model.CHARGE && this.$scope.model.CHARGE.length ? this.$scope.model.CHARGE.findIndex((charge, index) => previousDisplayCharge.ID ? charge.ID == previousDisplayCharge.ID : index == (currentDisplayIndex - 1)) : -1;

        if (previousChargeIndex === -1) return;

        if (this.hasChanges(JSON.stringify(modalScope.charge), JSON.stringify(modalScope.oldCharge))) {
            const confirm = await this.modalSaveConfirmation("REGISTRATION.BACK", "GENERAL.CANCEL");
            if (!confirm || confirm && !await this.applyCharge(modalScope.currentIndex, modalScope.charge, modalScope.operation, false)) return;
        }

        const chargeUpdated: ITariffComplementaryCharge = this.$scope.model.CHARGE[previousChargeIndex];
        modalScope.currentIndex = previousChargeIndex;
        modalScope.currentDisplayIndex--;

        if (chargeUpdated) {
            modalScope.charge = angular.copy(chargeUpdated);
            modalScope.oldCharge = angular.copy(chargeUpdated);
        }

        await modalScope.$applyAsync();

        this.$timeout(() => {
            this.validateChargeFields(modalScope)
        });
    }

    private async nextCharge(currentDisplayIndex: number): Promise<void> {
        const modalScope: ITariffComplementaryChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
        const nextDisplayCharge: ITariffComplementaryChargeControl = this.$scope.chargesListDisplay[currentDisplayIndex + 1];
        const nextChargeIndex: number = (nextDisplayCharge && this.$scope.model.CHARGE && this.$scope.model.CHARGE.length) ? this.$scope.model.CHARGE.findIndex((charge, index) => nextDisplayCharge.ID ? charge.ID == nextDisplayCharge.ID : index == (currentDisplayIndex + 1)) : -1;

        if (nextChargeIndex === -1) return;

        if (this.hasChanges(JSON.stringify(modalScope.charge), JSON.stringify(modalScope.oldCharge))) {
            const confirm = await this.modalSaveConfirmation("GENERAL.NEXT", "GENERAL.CANCEL");

            if (!confirm || (confirm && !await this.applyCharge(modalScope.currentIndex, modalScope.charge, modalScope.operation, false))) return;
        }

        const charge: ITariffComplementaryCharge = this.$scope.model.CHARGE[nextChargeIndex];
        modalScope.currentIndex = nextChargeIndex;
        modalScope.currentDisplayIndex++;

        if (charge) {
            modalScope.charge = angular.copy(charge);
            modalScope.oldCharge = angular.copy(charge);
        }

        await modalScope.$applyAsync();

        this.$timeout(() => {
            this.validateChargeFields(modalScope)
        });
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: "INVOLVED", LABEL: "GENERAL.STAKEHOLDERS" },
            { PROPERTY: "ROUTES", LABEL: "ROUTE.ROUTES" },
            { PROPERTY: "PRODUCT", LABEL: "BASIC_DATA.PRODUCT" },
            { PROPERTY: "TYPE_CARGO", LABEL: "BASIC_DATA.CARGO_TYPE" },
            { PROPERTY: "CHARGE_TYPE", LABEL: "FINANCIAL.CHARGE_TYPE" },
            { PROPERTY: "CONTRACT", LABEL: "BASIC_DATA.FREIGHT_CONTRACT" },
            { PROPERTY: "CONTRACT_TYPE", LABEL: "PRODUCT.FREIGHT_CONTRACT_TYPE" },
            { PROPERTY: "INCOTERM", LABEL: "BASIC_DATA.INCOTERM" },
            { PROPERTY: "PROVIDER", LABEL: "BASIC_DATA.PROVIDER" },
            { PROPERTY: "NETWORK", LABEL: "BASIC_DATA.EXTERNAL_AGENT_NETWORK" },
            { PROPERTY: "EXTERNAL_AGENT", LABEL: "BASIC_DATA.OVERSEAS_AGENT" },
            { PROPERTY: "ACCOUNTS", LABEL: "GENERAL.ACCOUNT" },
            { PROPERTY: "EXPORTERS", LABEL: "BASIC_DATA.SHIPPER" },
            { PROPERTY: "IMPORTERS", LABEL: "BASIC_DATA.CONSIGNEE" },
            { PROPERTY: "NOTIFY", LABEL: "BASIC_DATA.NOTIFY" },
            { PROPERTY: "CUSTOMS_BROKER", LABEL: "BASIC_DATA.CUSTOMS_BROKER" },
            { PROPERTY: "EXTERNAL_BROKER", LABEL: "BASIC_DATA.EXTERNAL_BROKER" },
            { PROPERTY: "ISOTANK_PROVIDER", LABEL: "BASIC_DATA.ISOTANK_PROVIDER" },
            { PROPERTY: "FLEXI_FITTING", LABEL: "BASIC_DATA.FLEXI_FITTING" },
            { PROPERTY: "PUP", LABEL: "GENERAL.PICK_UP_CODE" },
            { PROPERTY: "OTFS", LABEL: "GENERAL.ORIGIN_TERMINAL_CODE" },
            { PROPERTY: "POLAOL", LABEL: "GENERAL.LOADING_PLACE_CODE" },
            { PROPERTY: "PODAOD", LABEL: "GENERAL.UNLOADING_PLACE_CODE" },
            { PROPERTY: "DTFS", LABEL: "GENERAL.DESTINATION_TERMINAL_CODE" },
            { PROPERTY: "PLD", LABEL: "GENERAL.PLACE_OF_DELIVERY_CODE" },
            { PROPERTY: "COUNTRY_ORIGIN", LABEL: "BASIC_DATA.ORIGIN_COUNTRY" },
            { PROPERTY: "COUNTRY_DESTINATION", LABEL: "BASIC_DATA.DESTINATION_COUNTRY" },
            { PROPERTY: "FOODGRADE", LABEL: "REGISTRATION.FOODGRADE" },
            { PROPERTY: "DETAILS", LABEL: "GENERAL.DETAILS" },
            { PROPERTY: "VALIDITY_START", LABEL: "PRODUCT.VALIDITY_START" },
            { PROPERTY: "VALIDITY_END", LABEL: "PRODUCT.VALIDITY_END" },
            { PROPERTY: "CONCATENATED_COMPLEMENT", LABEL: "GENERAL.CONCATENATED_COMPLEMENT" },
            { PROPERTY: "SITUATION", LABEL: "GENERAL.SITUATION" },
            { PROPERTY: "MASTER_MODALITY", LABEL: "BASIC_DATA.MASTER_PAYMENT_MODE" },
            { PROPERTY: "HOUSE_MODALITY", LABEL: "BASIC_DATA.HOUSE_PAYMENT_MODE" },
            { PROPERTY: "STRAIGHT_BL", LABEL: "BASIC_DATA.STRAIGHT_BL" },
            { PROPERTY: "TRANSACTION", LABEL: "BASIC_DATA.TRANSACTION" },
            { PROPERTY: "TARIFF_TYPE", LABEL: "PRODUCT.TARIFF_TYPE" },
            { PROPERTY: "TYPE", LABEL: "GENERAL.TYPE" },
            { PROPERTY: "FILES", LABEL: "REGISTRATION.EMAIL_ATTACHMENT" },
            { PROPERTY: "CONCATENATED", LABEL: "GENERAL.CONCATENATED" },
            { PROPERTY: "OBSERVATION", LABEL: "GENERAL.REMARKS" },
            { PROPERTY: "NAME", LABEL: "GENERAL.NAME" },
            { PROPERTY: "CODE", LABEL: "GENERAL.CODE" },
            { PROPERTY: "ACTIVE", LABEL: "GENERAL.ACTIVE" },
            { PROPERTY: "RECEIVING", LABEL: "PRODUCT.SELLING" },
            { PROPERTY: "PAYMENT", LABEL: "PRODUCT.BUYING" },
            { PROPERTY: "LEGAL_PERSON_HOLDER", LABEL: "GENERAL.HOLDER" },
            { PROPERTY: "PHYSICAL_PERSON_HOLDER", LABEL: "GENERAL.HOLDER" },
            { PROPERTY: "FLAT", LABEL: "REGISTRATION.FLAT" },
            { PROPERTY: "WEIGHT_RANGE", LABEL: "REGISTRATION.WEIGHT_BREAK" },
            { PROPERTY: "EQUIPMENT", LABEL: "BASIC_DATA.EQUIPMENT" },
            { PROPERTY: "VEHICLE_TYPE", LABEL: "GENERAL.VEHICLE_TYPE" },
            { PROPERTY: "WEIGHT_RANGE_CHARGE", LABEL: "GENERAL.MENU.WEIGHT_RANGE" },
            { PROPERTY: "CURRENCY", LABEL: "GENERAL.CURRENCY" },
            { PROPERTY: "MIN", LABEL: "PRODUCT.MIN_PAYMENT" },
            { PROPERTY: "PAYMENT_UNITARY", LABEL: "PRODUCT.UNIT_PAYMENT" },
            { PROPERTY: "MIN", LABEL: "PRODUCT.MIN_RECEIPT" },
            { PROPERTY: "RECEIVING_UNITARY", LABEL: "PRODUCT.UNIT_RECEIPT" },
            { PROPERTY: "UNITARY", LABEL: "BASIC_DATA.UNIT" },
            { PROPERTY: "FREE_OF_CHARGE", LABEL: "PRODUCT.FREE_OF_CHARGE" },
            { PROPERTY: "HOLDER_TYPE", LABEL: "GENERAL.PERSON_TYPE" },
            { PROPERTY: "CHARGE_NAME_EXHIBITION", LABEL: "BASIC_DATA.CHARGE" },
            { PROPERTY: "TYPE_CARGO", LABEL: "BASIC_DATA.CARGO_TYPE" },
            { PROPERTY: "FREIGHT_BILLING", LABEL: "BASIC_DATA.BILLING_AS_FREIGHT" },
            { PROPERTY: "PERCENT", LABEL: "REGISTRATION.PERCENTAGE" },
            { PROPERTY: "APPLICATION_COMPLEMENT", LABEL: "PRODUCT.BASIS_COMPLEMENT" },
            { PROPERTY: "INTERNAL_SEQUENCE", LABEL: "REGISTRATION.INTERNAL_SEQUENCE" },
            { PROPERTY: "FREE_TYPING_AMOUNT_CHARGE", LABEL: "REGISTRATION.FREE_FIELD_CHANGE_QUANTITY" },
            { PROPERTY: "CT_WITHOUT_DOC", LABEL: "REGISTRATION.CONTRIBUTION_WITHOUT_DOCS" },
            { PROPERTY: "NAME_INTL", LABEL: "GENERAL.NAME_INTL" },
            { PROPERTY: "PARAMS", LABEL: "REGISTRATION.PARAMETERIZATION_NUMBER" },
            { PROPERTY: "MODALITY", LABEL: "BASIC_DATA.HOUSE_PAYMENT_MODE" },
            { PROPERTY: "ORDER", LABEL: "REGISTRATION.FIELD_ORDER" },
            { PROPERTY: "APPLICATION", LABEL: "FINANCIAL.CHARGE_BASIS" },
            { PROPERTY: "FEET", LABEL: "GENERAL.EQUIPMENT_SIZE" },
            { PROPERTY: "TEU", LABEL: "REGISTRATION.TEUS_CAPACITY" },
            { PROPERTY: "VALIDITY_EVENT", LABEL: "GENERAL.VALIDITY_BASIS" },
            { PROPERTY: "CREATED_AT", LABEL: "GENERAL.CREATED_AT" },
            { PROPERTY: "CREATED_BY", LABEL: "GENERAL.CREATED_BY" },
            { PROPERTY: "UPDATED_AT", LABEL: "GENERAL.UPDATED_AT" },
            { PROPERTY: "UPDATED_BY", LABEL: "GENERAL.UPDATED_BY" },
        ];
        return props;
    }

    private getMenuFloatingDefault(): IFloatingMenu {
        return {
            tooltipPlacement: "auto bottom",
            options: [
                {
                    click: "collapseHeader",
                    args: ['collapseBasicData'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.BASIC_DATA",
                    iconClass: "fa fa-address-card",
                    iconBodyClass: "text-brown"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseStakeHolders'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.STAKEHOLDERS",
                    iconClass: "fa fa-users",
                    iconBodyClass: "text-orange"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseRoutes'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "ROUTE.ROUTES",
                    iconClass: "fa fa-map-o",
                    iconBodyClass: "text-brown"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseOtherDetails'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "ROUTE.ROUTES",
                    iconClass: "fa fa-list-alt",
                    iconBodyClass: "text-yellow"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseCharges'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.ACTIONS",
                    iconClass: "fa fa-usd",
                    iconBodyClass: "text-green"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseUploads'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "REGISTRATION.EMAIL_ATTACHMENT",
                    iconClass: "fa fa-files-o",
                    iconBodyClass: "text-gray"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseObservation'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.REMARKS",
                    iconClass: "fa fa-arrows-alt",
                    iconBodyClass: "text-blue"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseAll'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.COLLAPSE_EXPAND_ALL",
                    iconClass: "fa fa-expand",
                    iconBodyClass: "text-danger"
                }
            ],
            btnActiveDisabled: false
        };
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/tariffComplementary/getById/${id}`, 30000, null, false);

            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);

            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private checkDateValidity(initialDate: Date, finalDate: Date): void {
        if (!(initialDate instanceof Date) || !(finalDate instanceof Date)) return;

        const isDateRangeValid = ValidateUtil.isValidDateRange(initialDate, finalDate);
        if (!isDateRangeValid) this.$scope.model.VALIDITY_END = null;
    }

    private initModelInvolved(): IInvolved {
        const involved: IInvolved = {
            ACCOUNTS: null,
            CUSTOMS_BROKER: null,
            EXPORTERS: null,
            IMPORTERS: null,
            EXTERNAL_AGENT: null,
            EXTERNAL_BROKER: null,
            FLEXI_FITTING: null,
            ISOTANK_PROVIDER: null,
            NETWORK: null,
            NOTIFY: null,
            PROVIDER: null
        };

        return involved;
    };

    private initModelRoutes(): IRoutes {
        const routes: IRoutes = {
            COUNTRY_DESTINATION: null,
            COUNTRY_ORIGIN: null,
            DTFS: null,
            OTFS: null,
            PLD: null,
            PODAOD: null,
            POLAOL: null,
            PUP: null
        };

        return routes;
    };

    private productChange(): void {
        this.$scope.model.INCOTERM = null;
        this.$scope.model.TYPE_CARGO = null;
    }

    private inclusionTypeChange(): void {
        this.$scope.model.TRANSACTION = null;
        this.$scope.model.TARIFF_TYPE = null;
    }

    private generateConcatenated(): void {
        try {
            let concatenated: string = "";
            const separator: string = " | ";
            const filter: ITariffComplementaryModel = this.$scope.model;

            if (filter.INVOLVED) {
                const involved = this.$scope.model.INVOLVED;

                if (involved.ACCOUNTS && involved.ACCOUNTS.length) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, involved.ACCOUNTS.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(involved.ACCOUNTS.map(obj => { return obj.NAME }).join(','));
                }
            }

            if (filter.PRODUCT) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, filter.PRODUCT.ID) : concatenated.concat(filter.PRODUCT.ID);
            }

            if (filter.TYPE_CARGO) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, filter.TYPE_CARGO.NAME) : concatenated.concat(filter.TYPE_CARGO.NAME);
            }

            if (filter.CHARGE_TYPE) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CHARGE_TYPE.NAME) : concatenated.concat(this.$scope.model.CHARGE_TYPE.NAME);
            }

            if (filter.CONTRACT && filter.CONTRACT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, filter.CONTRACT.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(filter.CONTRACT.map(obj => { return obj.NAME }).join(','));
            }

            if (filter.INCOTERM && filter.INCOTERM.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, filter.INCOTERM.map(obj => { return obj.CODE }).join(',')) : concatenated.concat(filter.INCOTERM.map(obj => { return obj.CODE }).join(','));
            }

            if (this.$scope.model.CONCATENATED_COMPLEMENT && this.$scope.model.CONCATENATED_COMPLEMENT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CONCATENATED_COMPLEMENT) : concatenated.concat(this.$scope.model.CONCATENATED_COMPLEMENT);
            }

            if (this.$scope.model.VALIDITY_START && this.$scope.model.VALIDITY_END) {
                const validity = this.$scope.model.VALIDITY_START ? moment(this.$scope.model.VALIDITY_START).format('DD/MM/YY') + '-' + moment(this.$scope.model.VALIDITY_END).format('DD/MM/YY') : moment(this.$scope.model.VALIDITY_START).format('DD/MM/YY');
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, validity) : concatenated.concat(validity);
            }

            this.$scope.model.CONCATENATED = StringUtil.formatConcatenatedChars(concatenated);

            if (this.$scope.menuFloating) this.$scope.menuFloating.infos = [{ text: `${this.$scope.model.CONCATENATED}`, class: "text-rouge font-bold" }];
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'tariff_files', formName: this.formName, folder: this.formName };

            return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData] });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getChargeTableControl(): IChargeTableControl {
        return <IChargeTableControl>{
            data: null,
            chargeFilter: { SHOW_ONLY_CURRENT_CHARGES: false, LOAD_REFERENCE: null }
        };
    }

    private async removeUpload(model: IUploadItem): Promise<boolean> {
        let result: boolean = false;
        try {
            if (!model) throw Error('item parameter is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: `${this.formService.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")}`
            });
            if (!modal) return;

            const isAdminOrCoordinator: boolean = (this.$scope.user.roles && (this.$scope.user.roles.hasOwnProperty('ADMIN') || this.$scope.user.roles.hasOwnProperty('COORDINATOR')));

            if (isAdminOrCoordinator) {
                result = true;
            } else {
                if (this.$scope.model.FILES && this.$scope.model.FILES.length > 0) {
                    const index: number = this.$scope.model.FILES.findIndex(file => { return file.FILE_NAME == model.FILE_NAME });

                    if (index > -1) {
                        this.$scope.model.FILES.splice(index, 1);
                        result = true;
                    }
                }
            }
        } catch (ex) {
            result = false;
            this.handleError(ex);
        } finally {
            return result;
        }
    }

    private async setChargeNameId(): Promise<void> {
        const modalScope: ITariffComplementaryChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);

        if (!modalScope.charge) throw Error('Missing charge in setChargeNameId');
        else if (modalScope.charge.CHARGE_NAME_EXHIBITION) modalScope.charge.ID_CHARGE_NAME_EXHIBITION = parseInt(modalScope.charge.CHARGE_NAME_EXHIBITION.ID);
    }

    private async updateParam(selected: IChargeNameList): Promise<void> {
        let param: IParams = null;
        try {
            const modalScope: ITariffComplementaryChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);

            // set payment as default if TYPE is inclusion
            const isPaymentNature = this.$scope.model.TRANSACTION ? this.$scope.model.TRANSACTION && this.$scope.model.TRANSACTION.some(payment => payment.ID === EPaymentNatureId.PAYMENT) : true;

            if (!selected) return;
            if (!modalScope.charge) throw Error('Missing charge in updateParam');

            if (selected.PARAMS && selected.PARAMS.length > 0) param = selected.PARAMS.find(param => param.TYPE_CARGO.find(typeCargo => typeCargo.ID === this.$scope.model.TYPE_CARGO.ID));

            if (param.PAYMENT || param.RECEIVING) {
                modalScope.charge.APPLICATION = isPaymentNature ? param.PAYMENT : param.RECEIVING;
                this.$timeout(() => modalScope.selectorValidity('param'), 100);
            }

            // SCOB Application behavior
            const isScobApplication: boolean = modalScope.charge.APPLICATION && modalScope.charge.APPLICATION.INTERNAL_SEQUENCE == "001";
            if (isScobApplication) {
                modalScope.charge.PAYMENT.FREE_OF_CHARGE = true;
                modalScope.charge.RECEIVING.FREE_OF_CHARGE = true;
                this.freeOfChargePaymentChange(modalScope.charge);
                this.freeOfChargeReceivingChange(modalScope.charge);
            } else if (isPaymentNature) {
                modalScope.charge.PAYMENT.FREE_OF_CHARGE = false;
            } else {
                modalScope.charge.RECEIVING.FREE_OF_CHARGE = false;
            }

            const applicationComplement: string = modalScope.charge.APPLICATION && modalScope.charge.APPLICATION.APPLICATION_COMPLEMENT ? modalScope.charge.APPLICATION.APPLICATION_COMPLEMENT.ID : null;
            if (!applicationComplement || applicationComplement == EApplicationComplementId.NOT_APPLICABLE || applicationComplement == EApplicationComplementId.WEIGHT_RANGE) {
                if (applicationComplement == EApplicationComplementId.WEIGHT_RANGE) {
                    if (!modalScope.charge.FLAT) {
                        modalScope.charge.FLAT = true;
                        this.flatChange();
                    }
                } else {
                    modalScope.charge.FLAT = false;
                    modalScope.charge.WEIGHT_RANGE = null;
                    modalScope.charge.PAYMENT.WEIGHT_RANGE_CHARGE = null;
                    modalScope.charge.RECEIVING.WEIGHT_RANGE_CHARGE = null;
                }
                modalScope.charge.EQUIPMENT = null;
                modalScope.charge.VEHICLE_TYPE = null;
                this.$timeout(() => { modalScope.selectorValidity('weightRange'); });
            }
            else if (applicationComplement == EApplicationComplementId.EQUIPMENT) {
                modalScope.charge.FLAT = false;
                modalScope.charge.WEIGHT_RANGE = null;
                modalScope.charge.PAYMENT.WEIGHT_RANGE_CHARGE = null;
                modalScope.charge.RECEIVING.WEIGHT_RANGE_CHARGE = null;
                modalScope.charge.VEHICLE_TYPE = null;
                this.$timeout(() => { modalScope.selectorValidity('equipment'); });
            }
            else if (applicationComplement == EApplicationComplementId.VEHICLE) {
                modalScope.charge.FLAT = false;
                modalScope.charge.WEIGHT_RANGE = null;
                modalScope.charge.PAYMENT.WEIGHT_RANGE_CHARGE = null;
                modalScope.charge.RECEIVING.WEIGHT_RANGE_CHARGE = null;
                modalScope.charge.EQUIPMENT = null;
                this.$timeout(() => { modalScope.selectorValidity('vehicleType'); });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async updateHolderTypePayment(): Promise<void> {
        try {
            const modalScope: ITariffComplementaryChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
            modalScope.charge.PAYMENT.ID_LEGAL_PERSON_HOLDER = null;
            modalScope.charge.PAYMENT.LEGAL_PERSON_HOLDER = null;
            modalScope.charge.PAYMENT.ID_PHYSICAL_PERSON_HOLDER = null;
            modalScope.charge.PAYMENT.PHYSICAL_PERSON_HOLDER = null;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async updateHolderTypeReceiving(): Promise<void> {
        try {
            const modalScope: ITariffComplementaryChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
            modalScope.charge.RECEIVING.ID_LEGAL_PERSON_HOLDER = null;
            modalScope.charge.RECEIVING.LEGAL_PERSON_HOLDER = null;
            modalScope.charge.RECEIVING.ID_PHYSICAL_PERSON_HOLDER = null;
            modalScope.charge.RECEIVING.PHYSICAL_PERSON_HOLDER = null;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async updatePaymentUnit(): Promise<void> {
        try {
            const modalScope: ITariffComplementaryChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);

            if (modalScope.charge.FLAT) {
                const unit = modalScope.charge.PAYMENT.UNITARY;

                if (modalScope.charge.PAYMENT.WEIGHT_RANGE_CHARGE) {
                    for (const weightRangeCharge of modalScope.charge.PAYMENT.WEIGHT_RANGE_CHARGE) {
                        weightRangeCharge.UNITARY = unit;
                    }
                }

                modalScope.$applyAsync();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async updateReceivingUnit(): Promise<void> {
        try {
            const modalScope: ITariffComplementaryChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);

            if (modalScope.charge.FLAT) {
                const unit: number = modalScope.charge.RECEIVING.UNITARY;

                if (modalScope.charge.RECEIVING.WEIGHT_RANGE_CHARGE) {
                    for (const weightRangeCharge of modalScope.charge.RECEIVING.WEIGHT_RANGE_CHARGE) {
                        weightRangeCharge.UNITARY = unit;
                    }
                }

                modalScope.$applyAsync();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async flatChange(): Promise<void> {
        try {
            const modalScope: ITariffComplementaryChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
            if (!modalScope.charge) return;

            if (modalScope.charge.FLAT) {
                const availableWeightRangeList: SelectorModel[] = await this.getWeightRangeListByName();
                const availableWeightRangeToConcat: SelectorModel[] = availableWeightRangeList.filter(weightRange => (
                    !modalScope.charge.WEIGHT_RANGE || !(modalScope.charge.WEIGHT_RANGE && modalScope.charge.WEIGHT_RANGE.some((chargeWeightRange) => chargeWeightRange.ID == weightRange.ID))
                ));

                modalScope.charge.WEIGHT_RANGE = (modalScope.charge.WEIGHT_RANGE) ? modalScope.charge.WEIGHT_RANGE.concat(availableWeightRangeToConcat) : availableWeightRangeToConcat;
                this.weightRangeChange();
            } else {
                modalScope.charge.PAYMENT.UNITARY = null;
                modalScope.charge.RECEIVING.UNITARY = null;
            }

            modalScope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async weightRangeChange(onlyPayment?: boolean, onlyReceiving?: boolean) {
        try {
            const modalScope: ITariffComplementaryChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);
            const chargeWeightRange: IWeightRange[] = modalScope.charge.WEIGHT_RANGE;

            if (ArrayUtil.isEmptyArray(chargeWeightRange)) {
                if (!onlyReceiving) modalScope.charge.PAYMENT.WEIGHT_RANGE_CHARGE = null;
                if (!onlyPayment) modalScope.charge.RECEIVING.WEIGHT_RANGE_CHARGE = null;
            } else {
                if (!onlyReceiving && !modalScope.charge.PAYMENT.WEIGHT_RANGE_CHARGE) modalScope.charge.PAYMENT.WEIGHT_RANGE_CHARGE = [];
                if (!onlyPayment && !modalScope.charge.RECEIVING.WEIGHT_RANGE_CHARGE) modalScope.charge.RECEIVING.WEIGHT_RANGE_CHARGE = [];

                for (const weightRange of chargeWeightRange) {
                    // Payment
                    if (!onlyReceiving && !modalScope.charge.PAYMENT.FREE_OF_CHARGE) {
                        const hasPaymentWeightRangeAlready = modalScope.charge.PAYMENT.WEIGHT_RANGE_CHARGE && modalScope.charge.PAYMENT.WEIGHT_RANGE_CHARGE.some(paymentWeightRange => paymentWeightRange.WEIGHT_RANGE && paymentWeightRange.WEIGHT_RANGE.ID == weightRange.ID);
                        // Create the lines with the weight ranges if they don't exist
                        if (ArrayUtil.isEmptyArray(modalScope.charge.PAYMENT.WEIGHT_RANGE_CHARGE) || !hasPaymentWeightRangeAlready) {
                            modalScope.charge.PAYMENT.WEIGHT_RANGE_CHARGE.push({ WEIGHT_RANGE: weightRange, UNITARY: modalScope.charge.FLAT ? modalScope.charge.PAYMENT.UNITARY : null });
                        } else if (modalScope.charge.FLAT) {
                            // Fill in unit values case is FLAT
                            for (const weightRangeCharge of modalScope.charge.PAYMENT.WEIGHT_RANGE_CHARGE) { weightRangeCharge.UNITARY = modalScope.charge.PAYMENT.UNITARY }
                        }
                    }

                    // Receiving
                    if (!onlyPayment && !modalScope.charge.RECEIVING.FREE_OF_CHARGE) {
                        const hasReceivingWeightRangeAlready = modalScope.charge.RECEIVING.WEIGHT_RANGE_CHARGE && modalScope.charge.RECEIVING.WEIGHT_RANGE_CHARGE.some(receivingWeightRange => receivingWeightRange.WEIGHT_RANGE && receivingWeightRange.WEIGHT_RANGE.ID == weightRange.ID);
                        // Create the lines with the weight ranges if they don't exist
                        if (ArrayUtil.isEmptyArray(modalScope.charge.RECEIVING.WEIGHT_RANGE_CHARGE) || !hasReceivingWeightRangeAlready) {
                            modalScope.charge.RECEIVING.WEIGHT_RANGE_CHARGE.push({ WEIGHT_RANGE: weightRange, UNITARY: modalScope.charge.FLAT ? modalScope.charge.RECEIVING.UNITARY : null });
                        } else if (modalScope.charge.FLAT) {
                            // Fill in unit values case is FLAT
                            for (const weightRangeCharge of modalScope.charge.RECEIVING.WEIGHT_RANGE_CHARGE) { weightRangeCharge.UNITARY = modalScope.charge.RECEIVING.UNITARY }
                        }
                    }
                }

                // Keep the correct list in case of removal
                modalScope.charge.PAYMENT.WEIGHT_RANGE_CHARGE = modalScope.charge.PAYMENT.WEIGHT_RANGE_CHARGE && modalScope.charge.PAYMENT.WEIGHT_RANGE_CHARGE.filter(weightRangeCharge => chargeWeightRange && chargeWeightRange.some(weightRange => weightRange.ID == weightRangeCharge.WEIGHT_RANGE.ID));
                if (!modalScope.charge.PAYMENT.WEIGHT_RANGE_CHARGE || !modalScope.charge.PAYMENT.WEIGHT_RANGE_CHARGE.length) modalScope.charge.PAYMENT.WEIGHT_RANGE_CHARGE = null;
                modalScope.charge.RECEIVING.WEIGHT_RANGE_CHARGE = modalScope.charge.RECEIVING.WEIGHT_RANGE_CHARGE && modalScope.charge.RECEIVING.WEIGHT_RANGE_CHARGE.filter(weightRangeCharge => chargeWeightRange && chargeWeightRange.some(weightRange => weightRange.ID == weightRangeCharge.WEIGHT_RANGE.ID));
                if (!modalScope.charge.RECEIVING.WEIGHT_RANGE_CHARGE || !modalScope.charge.RECEIVING.WEIGHT_RANGE_CHARGE.length) modalScope.charge.RECEIVING.WEIGHT_RANGE_CHARGE = null;

                modalScope.$applyAsync();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async freeOfChargePaymentChange(charge: ITariffComplementaryCharge): Promise<ITariffComplementaryCharge> {
        const modalScope: ITariffComplementaryChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);

        if (charge && charge.PAYMENT && charge.PAYMENT.FREE_OF_CHARGE) {
            charge.PAYMENT.ID_CURRENCY = null;
            charge.PAYMENT.CURRENCY = null;
            charge.PAYMENT.UNITARY = null;
            charge.PAYMENT.MIN = null;
            charge.PAYMENT.HOLDER_TYPE = null;
            charge.PAYMENT.ID_LEGAL_PERSON_HOLDER = null;
            charge.PAYMENT.LEGAL_PERSON_HOLDER = null;
            charge.PAYMENT.ID_PHYSICAL_PERSON_HOLDER = null;
            charge.PAYMENT.PHYSICAL_PERSON_HOLDER = null;
            charge.PAYMENT.WEIGHT_RANGE_CHARGE = null;
            charge.PAYMENT.MODALITY = null;
            charge.PAYMENT.FREIGHT_BILLING = null;

            this.$timeout(() => {
                modalScope.selectorValidity("paymentCurrency");
            });
        }

        this.weightRangeChange(true);
        return charge;
    }

    private async freeOfChargeReceivingChange(charge: ITariffComplementaryCharge): Promise<ITariffComplementaryCharge> {
        const modalScope: ITariffComplementaryChargeModalScope = await this.ModalService.getModalScope(this.modalChargeId);

        if (charge && charge.RECEIVING && charge.RECEIVING.FREE_OF_CHARGE) {
            charge.RECEIVING.ID_CURRENCY = null;
            charge.RECEIVING.CURRENCY = null;
            charge.RECEIVING.UNITARY = null;
            charge.RECEIVING.MIN = null;
            charge.RECEIVING.HOLDER_TYPE = null;
            charge.RECEIVING.ID_LEGAL_PERSON_HOLDER = null;
            charge.RECEIVING.LEGAL_PERSON_HOLDER = null;
            charge.RECEIVING.ID_PHYSICAL_PERSON_HOLDER = null;
            charge.RECEIVING.PHYSICAL_PERSON_HOLDER = null;
            charge.RECEIVING.WEIGHT_RANGE_CHARGE = null;
            charge.RECEIVING.MODALITY = null;
            charge.RECEIVING.FREIGHT_BILLING = null;

            this.$timeout(() => {
                modalScope.selectorValidity("receivingCurrency");
            });
        }

        this.weightRangeChange(false, true);
        return charge;
    }
}
import { IModalOptions } from '@services/ModalService';
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { IViewLog, ICustomLogProperties } from '@models/interface/common/IViewLog';
import { INewAlternativeCompareWeightRangeCharges } from '@models/interface/product/NewAlternative';
import { IChargesTabData } from '@models/interface/product/NewAlternativeModel';
import { ISelectorModel } from '@models/mongo/SelectorModel';
import { IMonacoConfig } from 'src/ts/common/MonacoInterface';

interface IWeightRangeChargeComparisonData extends IChargesTabData {
    WEIGHT_RANGE: ISelectorModel;
}

interface IChargesPerWeightRangeComparisonModalScope extends IFormServiceScope {
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    modalOptions: IModalOptions;
    weightRangeCharges: INewAlternativeCompareWeightRangeCharges[];
    weightRangeChargeComparisonData: IWeightRangeChargeComparisonData[];
}

export class ChargesPerWeightRangeComparisonModalController extends FormService2 {
    static $inject: string[] = ['$injector', '$scope'];
    private scope: IChargesPerWeightRangeComparisonModalScope;
    private $q: ng.IQService;
    private config: IMonacoConfig;

    constructor($injector: ng.Injectable<any>, $scope: IChargesPerWeightRangeComparisonModalScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.$q = $injector.get('$q');
        this.config = $injector.get('config');
    }

    async $onInit(): Promise<void> {
        try {
            this.block();
            this.init("chargesPerWeightRangeComparisonModalForm", null, null);
            this.initScopeFunctions();
            await this.initDependencies();
            await this.initControl();
            this.loadRegisterForm(false);
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    private async initControl() {
        try {
            const weightRangeChargeData: IWeightRangeChargeComparisonData[] = [];
            if (this.scope.weightRangeCharges && this.scope.weightRangeCharges.length) {
                for (const weightRangeCharge of this.scope.weightRangeCharges) {
                    const chargesData = { WEIGHT_RANGE: weightRangeCharge.WEIGHT_RANGE, MAINTAINED_CHARGES: [], ADDED_CHARGES: [], DELETED_CHARGES: [] };
                    // Freight Charges
                    if (weightRangeCharge.NEW_CHARGES.FREIGHT_CHARGES && weightRangeCharge.CURRENT_CHARGES.FREIGHT_CHARGES) {
                        // Maintained
                        if (weightRangeCharge.NEW_CHARGES.FREIGHT_CHARGES.MAINTAINED_CHARGES && weightRangeCharge.NEW_CHARGES.FREIGHT_CHARGES.MAINTAINED_CHARGES.length) {
                            for (let index = 0; index < weightRangeCharge.NEW_CHARGES.FREIGHT_CHARGES.MAINTAINED_CHARGES.length; index++) {
                                const newMaintainedCharge = weightRangeCharge.NEW_CHARGES.FREIGHT_CHARGES.MAINTAINED_CHARGES[index];
                                const currentMaintainedCharge = weightRangeCharge.CURRENT_CHARGES.FREIGHT_CHARGES.MAINTAINED_CHARGES[index];
                                chargesData.MAINTAINED_CHARGES.push({ CHARGE_NAME: newMaintainedCharge.CHARGE_NAME, CHARGE: newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newMaintainedCharge.RECEIVING_CHARGES });
                                chargesData.MAINTAINED_CHARGES.push({ CHARGE_NAME: currentMaintainedCharge.CHARGE_NAME, CHARGE: currentMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentMaintainedCharge.RECEIVING_CHARGES });
                            }
                        }
                        // Added
                        if (weightRangeCharge.NEW_CHARGES.FREIGHT_CHARGES.ADDED_CHARGES && weightRangeCharge.NEW_CHARGES.FREIGHT_CHARGES.ADDED_CHARGES.length) {
                            for (const newAddedCharge of weightRangeCharge.NEW_CHARGES.FREIGHT_CHARGES.ADDED_CHARGES) {
                                chargesData.ADDED_CHARGES.push({ CHARGE_NAME: newAddedCharge.CHARGE_NAME, CHARGE: newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newAddedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newAddedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newAddedCharge.RECEIVING_CHARGES });
                            }
                        }
                        // Deleted
                        if (weightRangeCharge.CURRENT_CHARGES.FREIGHT_CHARGES.DELETED_CHARGES && weightRangeCharge.CURRENT_CHARGES.FREIGHT_CHARGES.DELETED_CHARGES.length) {
                            for (const currentDeletedCharge of weightRangeCharge.CURRENT_CHARGES.FREIGHT_CHARGES.DELETED_CHARGES) {
                                chargesData.DELETED_CHARGES.push({ CHARGE_NAME: currentDeletedCharge.CHARGE_NAME, CHARGE: currentDeletedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentDeletedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentDeletedCharge.RECEIVING_CHARGES });
                            }
                        }
                    }
                    // Domestic Origin Charges
                    if (weightRangeCharge.NEW_CHARGES.DOMESTIC_ORIGIN_CHARGES && weightRangeCharge.CURRENT_CHARGES.DOMESTIC_ORIGIN_CHARGES) {
                        // Maintained
                        if (weightRangeCharge.NEW_CHARGES.DOMESTIC_ORIGIN_CHARGES.MAINTAINED_CHARGES && weightRangeCharge.NEW_CHARGES.DOMESTIC_ORIGIN_CHARGES.MAINTAINED_CHARGES.length) {
                            for (let index = 0; index < weightRangeCharge.NEW_CHARGES.DOMESTIC_ORIGIN_CHARGES.MAINTAINED_CHARGES.length; index++) {
                                const newMaintainedCharge = weightRangeCharge.NEW_CHARGES.DOMESTIC_ORIGIN_CHARGES.MAINTAINED_CHARGES[index];
                                const currentMaintainedCharge = weightRangeCharge.CURRENT_CHARGES.DOMESTIC_ORIGIN_CHARGES.MAINTAINED_CHARGES[index];
                                chargesData.MAINTAINED_CHARGES.push({ CHARGE_NAME: newMaintainedCharge.CHARGE_NAME, CHARGE: newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newMaintainedCharge.RECEIVING_CHARGES });
                                chargesData.MAINTAINED_CHARGES.push({ CHARGE_NAME: currentMaintainedCharge.CHARGE_NAME, CHARGE: currentMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentMaintainedCharge.RECEIVING_CHARGES });
                            }
                        }
                        // Added
                        if (weightRangeCharge.NEW_CHARGES.DOMESTIC_ORIGIN_CHARGES.ADDED_CHARGES && weightRangeCharge.NEW_CHARGES.DOMESTIC_ORIGIN_CHARGES.ADDED_CHARGES.length) {
                            for (const newAddedCharge of weightRangeCharge.NEW_CHARGES.DOMESTIC_ORIGIN_CHARGES.ADDED_CHARGES) {
                                chargesData.ADDED_CHARGES.push({ CHARGE_NAME: newAddedCharge.CHARGE_NAME, CHARGE: newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newAddedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newAddedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newAddedCharge.RECEIVING_CHARGES });
                            }
                        }
                        // Deleted
                        if (weightRangeCharge.CURRENT_CHARGES.DOMESTIC_ORIGIN_CHARGES.DELETED_CHARGES && weightRangeCharge.CURRENT_CHARGES.DOMESTIC_ORIGIN_CHARGES.DELETED_CHARGES.length) {
                            for (const currentDeletedCharge of weightRangeCharge.CURRENT_CHARGES.DOMESTIC_ORIGIN_CHARGES.DELETED_CHARGES) {
                                chargesData.DELETED_CHARGES.push({ CHARGE_NAME: currentDeletedCharge.CHARGE_NAME, CHARGE: currentDeletedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentDeletedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentDeletedCharge.RECEIVING_CHARGES });
                            }
                        }
                    }
                    // Domestic Destination Charges
                    if (weightRangeCharge.NEW_CHARGES.DOMESTIC_DESTINATION_CHARGES && weightRangeCharge.CURRENT_CHARGES.DOMESTIC_DESTINATION_CHARGES) {
                        // Maintained
                        if (weightRangeCharge.NEW_CHARGES.DOMESTIC_DESTINATION_CHARGES.MAINTAINED_CHARGES && weightRangeCharge.NEW_CHARGES.DOMESTIC_DESTINATION_CHARGES.MAINTAINED_CHARGES.length) {
                            for (let index = 0; index < weightRangeCharge.NEW_CHARGES.DOMESTIC_DESTINATION_CHARGES.MAINTAINED_CHARGES.length; index++) {
                                const newMaintainedCharge = weightRangeCharge.NEW_CHARGES.DOMESTIC_DESTINATION_CHARGES.MAINTAINED_CHARGES[index];
                                const currentMaintainedCharge = weightRangeCharge.CURRENT_CHARGES.DOMESTIC_DESTINATION_CHARGES.MAINTAINED_CHARGES[index];
                                chargesData.MAINTAINED_CHARGES.push({ CHARGE_NAME: newMaintainedCharge.CHARGE_NAME, CHARGE: newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newMaintainedCharge.RECEIVING_CHARGES });
                                chargesData.MAINTAINED_CHARGES.push({ CHARGE_NAME: currentMaintainedCharge.CHARGE_NAME, CHARGE: currentMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentMaintainedCharge.RECEIVING_CHARGES });
                            }
                        }
                        // Added
                        if (weightRangeCharge.NEW_CHARGES.DOMESTIC_DESTINATION_CHARGES.ADDED_CHARGES && weightRangeCharge.NEW_CHARGES.DOMESTIC_DESTINATION_CHARGES.ADDED_CHARGES.length) {
                            for (const newAddedCharge of weightRangeCharge.NEW_CHARGES.DOMESTIC_DESTINATION_CHARGES.ADDED_CHARGES) {
                                chargesData.ADDED_CHARGES.push({ CHARGE_NAME: newAddedCharge.CHARGE_NAME, CHARGE: newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newAddedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newAddedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newAddedCharge.RECEIVING_CHARGES });
                            }
                        }
                        // Deleted
                        if (weightRangeCharge.CURRENT_CHARGES.DOMESTIC_DESTINATION_CHARGES.DELETED_CHARGES && weightRangeCharge.CURRENT_CHARGES.DOMESTIC_DESTINATION_CHARGES.DELETED_CHARGES.length) {
                            for (const currentDeletedCharge of weightRangeCharge.CURRENT_CHARGES.DOMESTIC_DESTINATION_CHARGES.DELETED_CHARGES) {
                                chargesData.DELETED_CHARGES.push({ CHARGE_NAME: currentDeletedCharge.CHARGE_NAME, CHARGE: currentDeletedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentDeletedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentDeletedCharge.RECEIVING_CHARGES });
                            }
                        }
                    }
                    // Local Origin Charges
                    if (weightRangeCharge.NEW_CHARGES.LOCAL_ORIGIN_CHARGES && weightRangeCharge.CURRENT_CHARGES.LOCAL_ORIGIN_CHARGES) {
                        // Maintained
                        if (weightRangeCharge.NEW_CHARGES.LOCAL_ORIGIN_CHARGES.MAINTAINED_CHARGES && weightRangeCharge.NEW_CHARGES.LOCAL_ORIGIN_CHARGES.MAINTAINED_CHARGES.length) {
                            for (let index = 0; index < weightRangeCharge.NEW_CHARGES.LOCAL_ORIGIN_CHARGES.MAINTAINED_CHARGES.length; index++) {
                                const newMaintainedCharge = weightRangeCharge.NEW_CHARGES.LOCAL_ORIGIN_CHARGES.MAINTAINED_CHARGES[index];
                                const currentMaintainedCharge = weightRangeCharge.CURRENT_CHARGES.LOCAL_ORIGIN_CHARGES.MAINTAINED_CHARGES[index];
                                chargesData.MAINTAINED_CHARGES.push({ CHARGE_NAME: newMaintainedCharge.CHARGE_NAME, CHARGE: newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newMaintainedCharge.RECEIVING_CHARGES });
                                chargesData.MAINTAINED_CHARGES.push({ CHARGE_NAME: currentMaintainedCharge.CHARGE_NAME, CHARGE: currentMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentMaintainedCharge.RECEIVING_CHARGES });
                            }
                        }
                        // Added
                        if (weightRangeCharge.NEW_CHARGES.LOCAL_ORIGIN_CHARGES.ADDED_CHARGES && weightRangeCharge.NEW_CHARGES.LOCAL_ORIGIN_CHARGES.ADDED_CHARGES.length) {
                            for (const newAddedCharge of weightRangeCharge.NEW_CHARGES.LOCAL_ORIGIN_CHARGES.ADDED_CHARGES) {
                                chargesData.ADDED_CHARGES.push({ CHARGE_NAME: newAddedCharge.CHARGE_NAME, CHARGE: newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newAddedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newAddedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newAddedCharge.RECEIVING_CHARGES });
                            }
                        }
                        // Deleted
                        if (weightRangeCharge.CURRENT_CHARGES.LOCAL_ORIGIN_CHARGES.DELETED_CHARGES && weightRangeCharge.CURRENT_CHARGES.LOCAL_ORIGIN_CHARGES.DELETED_CHARGES.length) {
                            for (const currentDeletedCharge of weightRangeCharge.CURRENT_CHARGES.LOCAL_ORIGIN_CHARGES.DELETED_CHARGES) {
                                chargesData.DELETED_CHARGES.push({ CHARGE_NAME: currentDeletedCharge.CHARGE_NAME, CHARGE: currentDeletedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentDeletedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentDeletedCharge.RECEIVING_CHARGES });
                            }
                        }
                    }
                    // Local Destination Charges
                    if (weightRangeCharge.NEW_CHARGES.LOCAL_DESTINATION_CHARGES && weightRangeCharge.CURRENT_CHARGES.LOCAL_DESTINATION_CHARGES) {
                        // Maintained
                        if (weightRangeCharge.NEW_CHARGES.LOCAL_DESTINATION_CHARGES.MAINTAINED_CHARGES && weightRangeCharge.NEW_CHARGES.LOCAL_DESTINATION_CHARGES.MAINTAINED_CHARGES.length) {
                            for (let index = 0; index < weightRangeCharge.NEW_CHARGES.LOCAL_DESTINATION_CHARGES.MAINTAINED_CHARGES.length; index++) {
                                const newMaintainedCharge = weightRangeCharge.NEW_CHARGES.LOCAL_DESTINATION_CHARGES.MAINTAINED_CHARGES[index];
                                const currentMaintainedCharge = weightRangeCharge.CURRENT_CHARGES.LOCAL_DESTINATION_CHARGES.MAINTAINED_CHARGES[index];
                                chargesData.MAINTAINED_CHARGES.push({ CHARGE_NAME: newMaintainedCharge.CHARGE_NAME, CHARGE: newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newMaintainedCharge.RECEIVING_CHARGES });
                                chargesData.MAINTAINED_CHARGES.push({ CHARGE_NAME: currentMaintainedCharge.CHARGE_NAME, CHARGE: currentMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentMaintainedCharge.RECEIVING_CHARGES });
                            }
                        }
                        // Added
                        if (weightRangeCharge.NEW_CHARGES.LOCAL_DESTINATION_CHARGES.ADDED_CHARGES && weightRangeCharge.NEW_CHARGES.LOCAL_DESTINATION_CHARGES.ADDED_CHARGES.length) {
                            for (const newAddedCharge of weightRangeCharge.NEW_CHARGES.LOCAL_DESTINATION_CHARGES.ADDED_CHARGES) {
                                chargesData.ADDED_CHARGES.push({ CHARGE_NAME: newAddedCharge.CHARGE_NAME, CHARGE: newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newAddedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newAddedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newAddedCharge.RECEIVING_CHARGES });
                            }
                        }
                        // Deleted
                        if (weightRangeCharge.CURRENT_CHARGES.LOCAL_DESTINATION_CHARGES.DELETED_CHARGES && weightRangeCharge.CURRENT_CHARGES.LOCAL_DESTINATION_CHARGES.DELETED_CHARGES.length) {
                            for (const currentDeletedCharge of weightRangeCharge.CURRENT_CHARGES.LOCAL_DESTINATION_CHARGES.DELETED_CHARGES) {
                                chargesData.DELETED_CHARGES.push({ CHARGE_NAME: currentDeletedCharge.CHARGE_NAME, CHARGE: currentDeletedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentDeletedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentDeletedCharge.RECEIVING_CHARGES });
                            }
                        }
                    }
                    // Insurance
                    if (weightRangeCharge.NEW_CHARGES.INSURANCE_CHARGES && weightRangeCharge.CURRENT_CHARGES.INSURANCE_CHARGES) {
                        // Maintained
                        if (weightRangeCharge.NEW_CHARGES.INSURANCE_CHARGES.MAINTAINED_CHARGES && weightRangeCharge.NEW_CHARGES.INSURANCE_CHARGES.MAINTAINED_CHARGES.length) {
                            for (let index = 0; index < weightRangeCharge.NEW_CHARGES.INSURANCE_CHARGES.MAINTAINED_CHARGES.length; index++) {
                                const newMaintainedCharge = weightRangeCharge.NEW_CHARGES.INSURANCE_CHARGES.MAINTAINED_CHARGES[index];
                                const currentMaintainedCharge = weightRangeCharge.CURRENT_CHARGES.INSURANCE_CHARGES.MAINTAINED_CHARGES[index];
                                chargesData.MAINTAINED_CHARGES.push({ CHARGE_NAME: newMaintainedCharge.CHARGE_NAME, CHARGE: newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newMaintainedCharge.RECEIVING_CHARGES });
                                chargesData.MAINTAINED_CHARGES.push({ CHARGE_NAME: currentMaintainedCharge.CHARGE_NAME, CHARGE: currentMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentMaintainedCharge.RECEIVING_CHARGES });
                            }
                        }
                        // Added
                        if (weightRangeCharge.NEW_CHARGES.INSURANCE_CHARGES.ADDED_CHARGES && weightRangeCharge.NEW_CHARGES.INSURANCE_CHARGES.ADDED_CHARGES.length) {
                            for (const newAddedCharge of weightRangeCharge.NEW_CHARGES.INSURANCE_CHARGES.ADDED_CHARGES) {
                                chargesData.ADDED_CHARGES.push({ CHARGE_NAME: newAddedCharge.CHARGE_NAME, CHARGE: newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newAddedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newAddedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newAddedCharge.RECEIVING_CHARGES });
                            }
                        }
                        // Deleted
                        if (weightRangeCharge.CURRENT_CHARGES.INSURANCE_CHARGES.DELETED_CHARGES && weightRangeCharge.CURRENT_CHARGES.INSURANCE_CHARGES.DELETED_CHARGES.length) {
                            for (const currentDeletedCharge of weightRangeCharge.CURRENT_CHARGES.INSURANCE_CHARGES.DELETED_CHARGES) {
                                chargesData.DELETED_CHARGES.push({ CHARGE_NAME: currentDeletedCharge.CHARGE_NAME, CHARGE: currentDeletedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentDeletedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentDeletedCharge.RECEIVING_CHARGES });
                            }
                        }
                    }
                    // Profit Share
                    if (weightRangeCharge.NEW_CHARGES.PROFIT_SHARE_CHARGES && weightRangeCharge.CURRENT_CHARGES.PROFIT_SHARE_CHARGES) {
                        // Maintained
                        if (weightRangeCharge.NEW_CHARGES.PROFIT_SHARE_CHARGES.MAINTAINED_CHARGES && weightRangeCharge.NEW_CHARGES.PROFIT_SHARE_CHARGES.MAINTAINED_CHARGES.length) {
                            for (let index = 0; index < weightRangeCharge.NEW_CHARGES.PROFIT_SHARE_CHARGES.MAINTAINED_CHARGES.length; index++) {
                                const newMaintainedCharge = weightRangeCharge.NEW_CHARGES.PROFIT_SHARE_CHARGES.MAINTAINED_CHARGES[index];
                                const currentMaintainedCharge = weightRangeCharge.CURRENT_CHARGES.PROFIT_SHARE_CHARGES.MAINTAINED_CHARGES[index];
                                chargesData.MAINTAINED_CHARGES.push({ CHARGE_NAME: newMaintainedCharge.CHARGE_NAME, CHARGE: newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newMaintainedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newMaintainedCharge.RECEIVING_CHARGES });
                                chargesData.MAINTAINED_CHARGES.push({ CHARGE_NAME: currentMaintainedCharge.CHARGE_NAME, CHARGE: currentMaintainedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentMaintainedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentMaintainedCharge.RECEIVING_CHARGES });
                            }
                        }
                        // Added
                        if (weightRangeCharge.NEW_CHARGES.PROFIT_SHARE_CHARGES.ADDED_CHARGES && weightRangeCharge.NEW_CHARGES.PROFIT_SHARE_CHARGES.ADDED_CHARGES.length) {
                            for (const newAddedCharge of weightRangeCharge.NEW_CHARGES.PROFIT_SHARE_CHARGES.ADDED_CHARGES) {
                                chargesData.ADDED_CHARGES.push({ CHARGE_NAME: newAddedCharge.CHARGE_NAME, CHARGE: newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE ? newAddedCharge.PAYMENT_CHARGES.CHARGE_CHARGES.VALUE : newAddedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: true, PAYMENT_CHARGES: newAddedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newAddedCharge.RECEIVING_CHARGES });
                            }
                        }
                        // Deleted
                        if (weightRangeCharge.CURRENT_CHARGES.PROFIT_SHARE_CHARGES.DELETED_CHARGES && weightRangeCharge.CURRENT_CHARGES.PROFIT_SHARE_CHARGES.DELETED_CHARGES.length) {
                            for (const currentDeletedCharge of weightRangeCharge.CURRENT_CHARGES.PROFIT_SHARE_CHARGES.DELETED_CHARGES) {
                                chargesData.DELETED_CHARGES.push({ CHARGE_NAME: currentDeletedCharge.CHARGE_NAME, CHARGE: currentDeletedCharge.RECEIVING_CHARGES.CHARGE_CHARGES.VALUE, IS_NEW: false, PAYMENT_CHARGES: currentDeletedCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentDeletedCharge.RECEIVING_CHARGES });
                            }
                        }
                    }
                    // Manual Charges
                    if (weightRangeCharge.CURRENT_CHARGES && weightRangeCharge.CURRENT_CHARGES.MANUAL_CHARGES && weightRangeCharge.CURRENT_CHARGES.MANUAL_CHARGES.length) {
                        for (let index = 0; index < weightRangeCharge.CURRENT_CHARGES.MANUAL_CHARGES.length; index++) {
                            const currentManualCharge = weightRangeCharge.CURRENT_CHARGES.MANUAL_CHARGES[index];
                            chargesData.MAINTAINED_CHARGES.push({ IS_NEW: false, CHARGE_NAME: currentManualCharge.CHARGE_NAME, PAYMENT_CHARGES: currentManualCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: currentManualCharge.RECEIVING_CHARGES });
                            const newManualCharge = weightRangeCharge.NEW_CHARGES && weightRangeCharge.NEW_CHARGES.MANUAL_CHARGES ? weightRangeCharge.NEW_CHARGES.MANUAL_CHARGES[index] : null;
                            if (newManualCharge) chargesData.MAINTAINED_CHARGES.push({ IS_NEW: true, CHARGE_NAME: newManualCharge.CHARGE_NAME, PAYMENT_CHARGES: newManualCharge.PAYMENT_CHARGES, RECEIVING_CHARGES: newManualCharge.RECEIVING_CHARGES });
                        }
                    }
                    weightRangeChargeData.push(chargesData);
                }
            }
            this.scope.weightRangeChargeComparisonData = weightRangeChargeData;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private initScopeFunctions(): void {
    }

    async initDependencies(): Promise<any> {
        try {
            const self: ChargesPerWeightRangeComparisonModalController = this;
            return new Promise(function (resolve, reject) {
                self.$q.all([

                ]).then((result: any) => {

                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getUrlQuotation(): string {
        const baseRoute = '/quotation';
        return this.config.quotationUrl + baseRoute;
    }
}

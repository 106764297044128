import { IModalOptions } from '@services/ModalService';
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { OperationalService } from "@services/OperationalService";
import { IInttraDifferenceResult, IInttraDifferenceItem } from '@models/interface/external/IInttra';
import { IMonacoScope } from '../../common/MonacoInterface';

interface IBookingDifferencesModalScope extends IFormServiceScope {
    modalOptions: IModalOptions;
    bookingId: string;
    noOperation: boolean;
    inttraDifferenceResult: IInttraDifferenceResult;
    lastUpdateDifference: IInttraDifferenceItem
    //general methods
    hasAnyCriticalDifference: (differenceList: IInttraDifferenceItem[]) => boolean;
    confirmLastInttraUpdate: (bookingDifferencesId: number) => Promise<void>;
    validateData: (bookingId: number) => Promise<void>;
    //navigation
    //gets
    //field changes
    //multilines control
}

export class BookingDifferencesModalController extends FormService2 {
    static $inject: string[] = ['$injector', '$scope'];
    private scope: IBookingDifferencesModalScope;
    private rootScope: IMonacoScope;
    private $q: ng.IQService;
    private OperationalService: OperationalService;

    constructor($injector: ng.Injectable<any>, $scope: IBookingDifferencesModalScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.rootScope = $injector.get('$rootScope');
        this.$q = $injector.get('$q');
        this.OperationalService = $injector.get('OperationalService');
    }

    async $onInit(): Promise<void> {
        try {
            this.block();
            this.init("bookingDifferencesModalForm", null, null);
            this.initScopeFunctions();
            await this.initDependencies();
            await this.initModel();
            await this.getDifferenceList();
            this.loadRegisterForm(false);
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    private initScopeFunctions(): void {
        this.scope.hasAnyCriticalDifference = (differenceList: IInttraDifferenceItem[]) => {
            return differenceList && differenceList.some(difference => difference.IS_CRITICAL);
        }

        this.scope.validateData = async (bookingId: number) => {
            const validated = await this.validateData(bookingId);
            if (validated) this.notifySuccess('Booking sucessfully validated.');
        }

        this.scope.confirmLastInttraUpdate = async (bookingDifferencesId: number) => {
            const updated = await this.confirmLastInttraUpdate(bookingDifferencesId);
            if (updated) {
                this.notifySuccess('Booking sucessfully updated.');
                const validated = await this.validateData(bookingDifferencesId);
                if (validated) await this.getDifferenceList();
            }
        }
    }

    async initDependencies(): Promise<any> {
        try {
            const self: BookingDifferencesModalController = this;
            return new Promise(function (resolve, reject) {
                self.$q.all([

                ]).then((result: any) => {

                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async initModel(): Promise<void> {
        this.scope.inttraDifferenceResult = null;
        this.scope.lastUpdateDifference = null;
    }

    private async getDifferenceList() {
        const differenceRequest = await this.OperationalService.post("/booking/inttraDifferences", { bookingId: this.scope.bookingId }, 30000);
        if (differenceRequest && differenceRequest.data && differenceRequest.data.data) {
            const differenceResult: IInttraDifferenceResult = differenceRequest.data.data;
            this.scope.inttraDifferenceResult = differenceResult;
            this.scope.$applyAsync();
        }
    }

    private async confirmLastInttraUpdate(bookingDifferencesId: number): Promise<boolean> {
        if (!bookingDifferencesId) throw new Error('Missing bookingDifferencesId');
        let updated = null;
        this.block();
        try {
            updated = await this.OperationalService.post("/booking/updateWithLastInttra", { bookingInttraDifferenceId: bookingDifferencesId }, 30000);
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return updated;
        }
    }

    private async validateData(bookingInttraDifferenceId: number): Promise<boolean> {
        if (!bookingInttraDifferenceId) throw new Error('Missing bookingDifferencesId');
        let updated = null;
        this.block();
        try {
            updated = await this.OperationalService.post("/booking/inttraValidateData", { bookingInttraDifferenceId: bookingInttraDifferenceId }, 30000);
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            this.rootScope.$broadcast("updateBookingGridRow", { bookingId: this.scope.bookingId });
            return updated;
        }
    }
}

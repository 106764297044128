import * as angular from "angular";
import { IConsolidatedModel } from "WBA-Model/dist/interface/operational/consolidated/Consolidated";
import { ISelectorModel } from "WBA-Model/dist/mongo/SelectorModel";
import { IConsolidatedCharge } from "WBA-Model/dist/interface/operational/consolidated/ConsolidatedCharge";
import { noBilling } from "@models/interface/finop/Invoice";
import { IWeightRangeResponse } from "@models/interface/operational/ProcessChargeWeightRange";
import { COMPOSITION_GROUP, WEIGHT_RANGE_CHARGE } from "@models/interface/finop/NewCharge";
import { IMonacoRequest } from "@services/GridFormService";
import { DataProcessService } from "@services/DataProcessService";
import { IModalOptions } from "@services/ModalService";
import { HelperService } from "@services/HelperService";
import { ProductService } from "@services/ProductService";
import { IFormServiceScope, FormService2 } from "@services/FormService2";
import { EChargeOriginId, EApplicationComplementId, EOperation, EDataOriginTypeId, EDataOriginTypeName, ETypePaymentId } from "@enums/GenericData";
import { IApplicationSelectorList } from "../model/ConsolidatedModel";

interface IConsolidatedWizardChargeScope extends IFormServiceScope {
    // Model
    model: IConsolidatedCharge;
    oldModel: IConsolidatedCharge;

    // Operation
    operation: EOperation;

    // Parent Scope Model
    consolidatedScopeModel: IConsolidatedModel;

    // Modal
    modalOptions: IModalOptions;

    // 
    isOutcomeWeightRangeApplication: boolean;
    weightRangeActiveId: string;

    // List
    modalityList: ISelectorModel[];
    transactionList: ISelectorModel[];
    chargeShowForList: ISelectorModel[];
    chargeList: ISelectorModel[];
    chargeExihibitionList: ISelectorModel[];
    currencyList: ISelectorModel[];
    applicationList: IApplicationSelectorList[];
    compositionList: COMPOSITION_GROUP[];
    weightRangeList: IWeightRangeResponse[];

    // Search
    getChageListByName: (search: string) => Promise<void>;
    getChargeExhibitionList: (search: string) => Promise<void>;

    // Modal
    closeModal: () => void;

    // Request Consolidated Charge
    saveConsolidatedCharge: () => Promise<void>;

    // Change Field
    setSelectedCharge: () => Promise<void>;
    setChargePairExhibition: () => void;
    weightRangeOutcomeValueUnitaryChange: (weightRangeId: string) => void;
    refreshApplication: () => void;

    hasInvalidRequiredElements: (elementId: string) => boolean;
}

export class ConsolidatedChargeModalController extends FormService2 {
    static $inject: string[] = ['$injector', '$scope'];
    private scope: IConsolidatedWizardChargeScope;
    private $q: ng.IQService;
    private timeout: ng.ITimeoutService
    private DataProcessService: DataProcessService;
    private HelperService: HelperService;
    private ProductService: ProductService;

    constructor($injector: ng.Injectable<any>, $scope: IConsolidatedWizardChargeScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.$q = $injector.get('$q');
        this.timeout = $injector.get('$timeout');

        this.DataProcessService = $injector.get('DataProcessService');
        this.HelperService = $injector.get('HelperService');
        this.ProductService = $injector.get('ProductService');
        this.scope.formService = new FormService2($injector, $scope);
    }

    public async $onInit(): Promise<void> {
        try {
            this.block();
            this.init("consolidatedChargeModalForm", null, null);
            this.initialize();
            this.unblock();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private async initialize(): Promise<void> {
        this.initDependencies();
        this.initScopeFunctions();
        this.initModel();
        this.refreshApplication();
    }

    public async initDependencies(): Promise<boolean> {
        const self: ConsolidatedChargeModalController = this;

        const promises = {
            transaction: self.getGenericValue("transaction"),
            modality: self.getGenericValue("type_payment"),
            chargeShownFor: self.getGenericValue('charge_shown_for'),
            application: self.getChargeApplicationList(),
            currency: self.getCurrencyList()
        };

        return new Promise((resolve, reject) => {
            self.$q.all(promises).then((result) => {
                self.scope.transactionList = this.filterAndSortTransaction(result.transaction);
                self.scope.modalityList = result.modality;
                self.scope.chargeShowForList = result.chargeShownFor;
                self.scope.applicationList = result.application;
                self.scope.currencyList = result.currency;

                for (const modality of self.scope.modalityList) {
                    if (modality.ID == ETypePaymentId.PREPAID) this.scope.model.OUTCOME.MODALITY = modality;
                }
                resolve(true);
            }).catch((ex) => {
                reject(ex);
            });
        });
    }

    public initScopeFunctions(): void {
        // Search
        this.scope.getChageListByName = (search: string) => this.getChageListByName(search);
        this.scope.getChargeExhibitionList = () => this.getChargeExhibitionList();

        // Modal
        this.scope.closeModal = () => this.closeModal();

        // Request Consolidated Charge
        this.scope.saveConsolidatedCharge = async () => {
            await this.saveConsolidatedCharge()
        };

        // Change Field
        this.scope.setSelectedCharge = () => this.setSelectedCharge();
        this.scope.refreshApplication = () => this.refreshApplication();
        this.scope.weightRangeOutcomeValueUnitaryChange = (weightRangeId: string) => this.weightRangeOutcomeValueUnitaryChange(weightRangeId);
        this.scope.setChargePairExhibition = () => this.setChargePairExhibition();

        this.scope.hasInvalidRequiredElements = (elementId: string) => {
            return this.hasInvalidRequiredElements(elementId);
        }
    }

    public initModel(): void {
        if (!this.scope.model) {
            this.scope.model = this.getNewConsolidatedCharge();
        }

        if (this.scope.operation === EOperation.COPY) {
            this.scope.oldModel = this.getNewConsolidatedCharge();
        } else {
            this.scope.oldModel = angular.copy(this.scope.model);
        }
    }

    private getNewConsolidatedCharge(): IConsolidatedCharge {
        return <IConsolidatedCharge>{
            _id: null,
            ID_CONSOLIDATED: this.scope.consolidatedScopeModel.ID,
            CHARGE_PAIR_NUMBER: null,
            CHARGE: null,
            CHARGE_EXHIBITION: null,
            OUTCOME: {
                CHARGE_NUMBER: null,
                CHARGE_PAIR_REFERENCE: null,
                CHARGE_TYPE: { "ID": "M", "NAME": "Manual" },
                CHARGE: null,
                CHARGE_EXHIBITION: null,
                NATURE: { ID: "1", NAME: "Pagamento" },
                AMOUNT: 0,
                APPLICATION: null,
                CURRENCY: null,
                MINIMUM: 0,
                UNITARY: 0,
                TOTAL: 0,
                TOTAL_BRL: 0,
                CONVERSION_SPREAD: 0,
                TRANSACTION: null,
                PEOPLE: null,
                VALIDITY_OF: null,
                VALIDITY_UP: null,
                SHOW_FOR_GROUP: null,
                EQUIPMENT: null,
                VEHICLE_TYPE: null,
                WEIGHT_RANGE: null,
                INVOICE_NUMBER: null,
                COMPOSITION_GROUP: null,
                OBSERVATION: null,
                CHARGE_ORIGIN: null,
                MODALITY: null,
                ERP_INTEGRATION_STATUS: null,
                ERP_ORDER: null,
                ERP_SEQUENCE: null,
                ERP_TOP: null,
                ERP_BILLING: null,
                WEIGHT_RANGE_CHARGE: null,
                PARAMS: null,
                VALIDITY_EVENT: null,
                ID_TARIFF_DOMESTIC: null,
                ID_TARIFF_FREIGHT: null,
                ID_TARIFF_LOCAL: null,
                ID_CONSOLIDATED: this.scope.consolidatedScopeModel.ID,
                ID_FREIGHT_ROUTES: null,
                ID_INLAND_ROUTES: null,
                ID_TARIFF_COMPLEMENTARY: null
            },
            INCOME: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
        };
    }

    private async getWeightRangeList(): Promise<IWeightRangeResponse[]> {
        try {
            this.block();

            if (this.scope.model.OUTCOME.WEIGHT_RANGE_CHARGE && this.scope.model.OUTCOME.WEIGHT_RANGE_CHARGE.length) {
                this.scope.weightRangeList = [];
                this.scope.weightRangeList.push({
                    ID: null,
                    NAME: null,
                    CODE: null,
                    ACTIVE: false
                })
            } else {
                const selectorModelList: IWeightRangeResponse[] = [];

                const data = (this.scope.consolidatedScopeModel && this.scope.consolidatedScopeModel.PRODUCT && this.scope.consolidatedScopeModel.PRODUCT.ID) ? { products: [this.scope.consolidatedScopeModel.PRODUCT.ID] } : null
                const request: IMonacoRequest = {
                    data,
                    route: `/weightRange/list/custom`,
                    timeout: 30000,
                };

                const rc = await this.ProductService.post(request, false);
                const weightRangeList = (rc && rc.data && rc.data.data && rc.data.data.data) ? rc.data.data.data : null;

                for (const item of weightRangeList) {
                    const selectorModel: IWeightRangeResponse = {
                        ID: item.ID,
                        NAME: item.NAME,
                        CODE: item.CODE,
                        ACTIVE: true
                    }

                    selectorModelList.push(selectorModel);
                }

                this.scope.weightRangeList = selectorModelList;
            }

            return this.scope.weightRangeList;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getChageListByName(search: string): Promise<void> {
        try {
            if (search && search.length >= 3) {
                this.block();

                const chargeSelectorList: ISelectorModel[] = [];
                const request: IMonacoRequest = {
                    data: {
                        search,
                        products: (this.scope.consolidatedScopeModel && this.scope.consolidatedScopeModel.PRODUCT.ID) ? [this.scope.consolidatedScopeModel.PRODUCT.ID] : [],
                        types: [
                            EChargeOriginId.FREIGHT,
                            EChargeOriginId.ORIGIN,
                            EChargeOriginId.DESTINATION,
                            EChargeOriginId.DET_DEM,
                            EChargeOriginId.INSURANCE,
                            EChargeOriginId.TAX,
                            EChargeOriginId.OTHERS
                        ]
                    },
                    route: `/chargeName/list/custom/withexhibition`,
                    timeout: 30000,
                };

                const rc = await this.ProductService.post(request, false);
                const chargeList = (rc && rc.data && rc.data.data) ? rc.data.data : [];

                for (const item of chargeList) {
                    const foundNameExihibition = item.CHARGE_NAME_EXHIBITION.find((ex => ex.NAME.toUpperCase().indexOf(search.toUpperCase()) > -1 || ex.NAME_INTL.toUpperCase().indexOf(search.toUpperCase()) > -1));
                    const chargeSelector = {
                        ID: item.ID,
                        NAME: item.NAME,
                        NAME_INTL: item.NAME,
                        CODE: item.CODE,
                        CHARGE_NAME_EXHIBITION: item.CHARGE_NAME_EXHIBITION,
                        CHARGE_NAME_EXHIBITION_DEFAULT: item.CHARGE_NAME_EXHIBITION_DEFAULT,
                        CHARGE_NAME_EXHIBITION_FOUND: (foundNameExihibition) ? foundNameExihibition : item.CHARGE_NAME_EXHIBITION_DEFAULT,
                        DISPLAY_PAYMENT: item.DISPLAY_PAYMENT ? item.DISPLAY_PAYMENT : null,
                        DISPLAY_RECEIVING: item.DISPLAY_RECEIVING ? item.DISPLAY_RECEIVING : null,
                        CHARGE_ORIGIN: item.TYPE,
                        PARAMS: item.PARAMS,
                    }

                    chargeSelectorList.push(chargeSelector);
                }

                this.scope.chargeList = chargeSelectorList;
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getCurrencyList(): Promise<ISelectorModel[]> {
        try {
            this.block();

            const currencylList: ISelectorModel[] = [];
            const request: IMonacoRequest = {
                route: `/currency/list/custom`,
                timeout: 30000,
            };

            const rc = await this.ProductService.post(request, false);
            const currencyList = (rc && rc.data && rc.data.data) ? rc.data.data : [];

            for (const item of currencyList) {
                const selectorModel: ISelectorModel = {
                    ID: item.ID,
                    NAME: item.NAME,
                    CODE: item.INITIALS
                }

                currencylList.push(selectorModel);
            }

            return currencylList;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getChargeApplicationList(): Promise<IApplicationSelectorList[]> {
        try {
            this.block();

            const chargeApplicationList: IApplicationSelectorList[] = [];
            const request: IMonacoRequest = {
                data: {
                    typeCargos: [],
                    products: this.scope.consolidatedScopeModel && this.scope.consolidatedScopeModel.PRODUCT ? [this.scope.consolidatedScopeModel && this.scope.consolidatedScopeModel.PRODUCT.ID] : [],
                },
                route: `/application/list/custom`,
                timeout: 30000,
            };

            const rc = await this.ProductService.post(request, false);
            const currencyList = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            for (const item of currencyList) {
                const applicationModel: IApplicationSelectorList = {
                    ID: item.ID.toString(),
                    NAME: item.NAME,
                    CODE: item.CODE,
                    PERCENT: item.PERCENT,
                    APPLICATION_COMPLEMENT: item.APPLICATION_COMPLEMENT,
                    INTERNAL_SEQUENCE: item.INTERNAL_SEQUENCE,
                    FREE_TYPING_AMOUNT_CHARGE: item.FREE_TYPING_AMOUNT_CHARGE,
                    INTEGRATION_ID: item.INTEGRATION_ID,
                    CT_WITHOUT_DOC: item.CT_WITHOUT_DOC,
                    NAME_INTL: item.NAME_INTL,
                    PRODUCT: item.PRODUCT,
                    TYPE_CARGO: item.TYPE_CARGO,
                    ALLOWS_APPORTIONMENT: item.ALLOWS_APPORTIONMENT,
                    APPORTIONMENT_PARAM: item.APPORTIONMENT_PARAM
                }

                chargeApplicationList.push(applicationModel);
            }

            return chargeApplicationList;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getChargeExhibitionList(): Promise<void> {
        try {
            this.block();

            const chargeExihibitionSelectorList: ISelectorModel[] = [];
            const request: IMonacoRequest = {
                data: {
                    chargeNameId: (this.scope.model.CHARGE && this.scope.model.CHARGE.ID) ? this.scope.model.CHARGE.ID.toString() : null,
                    products: (this.scope.consolidatedScopeModel && this.scope.consolidatedScopeModel.PRODUCT.ID) ? [this.scope.consolidatedScopeModel.PRODUCT.ID] : [],
                },
                route: `/chargeName/list/custom/exhibition`,
                timeout: 30000,
            };

            const rc = await this.ProductService.post(request, false);
            const chargeList = (rc && rc.data && rc.data.data && rc.data.data.data) ? rc.data.data.data : [];

            for (const item of chargeList) {
                const chargeExihibitionSelector = {
                    ID: item.ID,
                    NAME: item.NAME,
                    NAME_INTL: item.NAME_INTL,
                    CODE: item.CODE,
                }

                chargeExihibitionSelectorList.push(chargeExihibitionSelector);
            }

            this.scope.chargeExihibitionList = chargeExihibitionSelectorList;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async saveConsolidatedCharge(): Promise<void> {
        try {

            const hasInvalid = this.hasInvalidRequiredElements("consolidatedChargeModalForm")
            if (hasInvalid) return;

            this.block();

            if (this.scope.operation == EOperation.EDIT && this.scope.model.OUTCOME.CHARGE_TYPE.ID == EDataOriginTypeId.AUTOMATICO) {
                this.scope.model.OUTCOME.CHARGE_TYPE = { ID: EDataOriginTypeId.EDITADO, NAME: EDataOriginTypeName.EDITADO }
            } else {
                this.scope.model.OUTCOME.CHARGE_TYPE = { ID: EDataOriginTypeId.MANUAL, NAME: EDataOriginTypeName.MANUAL }
            }

            const request: IMonacoRequest = {
                data: (this.scope.operation == EOperation.EDIT) ? {
                    data: this.scope.model,
                    oldData: this.scope.oldModel
                } : {
                    data: this.scope.model,
                },
                route: (this.scope.operation == EOperation.EDIT) ? `/consolidatedCharge/update` : `/consolidatedCharge/insert`,
                timeout: 12000
            }
            const rc = await this.DataProcessService.post(request.route, request.data, request.timeout);
            const resultOperation = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            if (resultOperation) this.scope.modalOptions.ok();
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async refreshApplication(): Promise<void> {
        try {
            this.block();

            let chargeToEval = this.scope.model.OUTCOME;

            if (!chargeToEval.APPLICATION) { this.unblock(); return; }

            if (chargeToEval.APPLICATION.INTEGRATION_ID === '1') {
                await this.setNoPayment(chargeToEval);
            } else {
                // properly resets dependent fields
                if (!chargeToEval.AMOUNT) chargeToEval.AMOUNT = 0;
                if (!chargeToEval.UNITARY) chargeToEval.UNITARY = 0;
                if (!chargeToEval.PEOPLE) chargeToEval.PEOPLE = null;
                if (!chargeToEval.CURRENCY) chargeToEval.CURRENCY = null;
                if (!chargeToEval.EQUIPMENT) chargeToEval.EQUIPMENT = null;
                if (!chargeToEval.INVOICE_NUMBER) chargeToEval.INVOICE_NUMBER = null;

                const currencyId = (chargeToEval.CURRENCY && !chargeToEval.CURRENCY.hasOwnProperty('INTEGRATION_ID')) ? chargeToEval.CURRENCY.ID : null;
                if (currencyId) {
                    const currencyIntegrationIdResult = await this.ProductService.get<{ INTEGRATION_ID: string }>({ route: `/currency/integrationId/${currencyId}` });
                    const currencyIntegrationId = (currencyIntegrationIdResult.data && currencyIntegrationIdResult.data.data) ? currencyIntegrationIdResult.data.data.INTEGRATION_ID : null;
                    if (currencyIntegrationId) chargeToEval.CURRENCY['INTEGRATION_ID'] = currencyIntegrationId.toString();
                }

                if (chargeToEval.APPLICATION.INTEGRATION_ID == '13') {

                    //get composition list
                    const compositionList = [];
                    this.scope.compositionList = compositionList;

                } else chargeToEval.COMPOSITION_GROUP = null;
            }

            this.scope.isOutcomeWeightRangeApplication = chargeToEval.APPLICATION && chargeToEval.APPLICATION.APPLICATION_COMPLEMENT && chargeToEval.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;

            if (this.scope.isOutcomeWeightRangeApplication) {
                const processWeightRangeList: IWeightRangeResponse[] = await this.getWeightRangeList();
                if (processWeightRangeList && processWeightRangeList.length) {
                    const weightRangeActive = processWeightRangeList.find(weightRange => weightRange.ACTIVE);
                    if (weightRangeActive) {
                        const weightRangeCharge: WEIGHT_RANGE_CHARGE[] = processWeightRangeList.map(weightRange => { return { ACTIVE: weightRange.ACTIVE, CURRENCY: null, MINIMUM: null, UNITARY: null, WEIGHT_RANGE: { ID: weightRange.ID, NAME: weightRange.NAME } } });
                        this.scope.weightRangeActiveId = weightRangeActive.ID;
                        this.scope.model.OUTCOME.WEIGHT_RANGE = { ID: weightRangeActive.ID, NAME: weightRangeActive.NAME };
                        this.scope.model.OUTCOME.WEIGHT_RANGE_CHARGE = angular.copy(weightRangeCharge);
                    }

                    const isCollapsedIn = angular.element("#collapseIncomeWeightRange").attr("aria-expanded") == "true";
                    angular.element("#collapseOutcomeWeightRange")["collapse"](isCollapsedIn ? "show" : "hide");

                }
            }

            this.unblock();
            await this.scope.$applyAsync();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async setSelectedCharge() {
        try {
            this.scope.compositionList = [];
            this.scope.chargeExihibitionList = [];
            const charge = this.scope.model.CHARGE;

            await this.setShownForCharge();

            let chargeOrigin: ISelectorModel = null;
            if (charge) {

                if (charge.hasOwnProperty('CHARGE_ORIGIN')) {
                    chargeOrigin = angular.copy(charge['CHARGE_ORIGIN']);
                    delete charge['CHARGE_ORIGIN'];
                }

                if (charge.hasOwnProperty('SHOW_CUSTOMER')) delete charge['SHOW_CUSTOMER'];
                if (charge.hasOwnProperty('SHOW_AGENT')) delete charge['SHOW_AGENT'];
                if (charge.hasOwnProperty('SHOW_TRANSPORT_DOC')) delete charge['SHOW_TRANSPORT_DOC'];
                if (charge.hasOwnProperty('NAME_TAXA_LOGISTICA')) delete charge['NAME_TAXA_LOGISTICA'];
            }

            this.scope.model.CHARGE = angular.copy(charge);
            this.scope.model.OUTCOME.CHARGE = angular.copy(charge);

            if (chargeOrigin) {
                this.scope.model.OUTCOME.CHARGE_ORIGIN = chargeOrigin;
            }

            if (this.scope.model.CHARGE) {
                await this.getChargeExhibitionList();
                if (this.scope.chargeExihibitionList && this.scope.chargeExihibitionList.length > 0) {
                    let findedChargeExhibition = null;

                    const selectedCharge = this.scope.chargeList.find(charge => charge.ID === this.scope.model.CHARGE.ID);

                    if (selectedCharge && selectedCharge['CHARGE_NAME_EXHIBITION_FOUND']) findedChargeExhibition = this.scope.chargeExihibitionList.find(chargeExhibition => chargeExhibition.ID === selectedCharge['CHARGE_NAME_EXHIBITION_FOUND']['ID']);

                    const chargeExhibition = findedChargeExhibition ? findedChargeExhibition : this.scope.chargeExihibitionList[0];

                    this.scope.model.OUTCOME.CHARGE_EXHIBITION = chargeExhibition;
                    this.setChargePairExhibition();
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async setShownForCharge() {
        if (!this.scope.model.CHARGE || !this.scope.chargeShowForList || !(this.scope.chargeShowForList.length > 0)) return true;
        try {
            const charge = this.scope.model.CHARGE;
            const showForList = this.scope.chargeShowForList;
            const selectedshowFor = [];

            if (charge.hasOwnProperty('SHOW_CUSTOMER') && charge['SHOW_CUSTOMER']) {
                let genericInfo = showForList.find(chr => (chr.NAME == "Cliente"))
                if (genericInfo) selectedshowFor.push(genericInfo);
            }
            if (charge.hasOwnProperty('SHOW_AGENT') && charge['SHOW_AGENT']) {
                let genericInfo = showForList.find(chr => (chr.NAME == "Agente"))
                if (genericInfo) selectedshowFor.push(genericInfo);
            }
            if (charge.hasOwnProperty('SHOW_TRANSPORT_DOC') && charge['SHOW_TRANSPORT_DOC']) {
                let genericInfo = showForList.find(chr => (chr.NAME == "Conhecimento Master"))
                if (genericInfo) selectedshowFor.push(genericInfo);

                genericInfo = null;

                genericInfo = showForList.find(chr => (chr.NAME == "Conhecimento House"))
                if (genericInfo) selectedshowFor.push(genericInfo);
            }
            this.scope.model.OUTCOME.SHOW_FOR_GROUP = selectedshowFor;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async weightRangeOutcomeValueUnitaryChange(weightRangeId: string) {
        try {
            const weightRangeActive = await this.getWeightRangeOutcomeActive(weightRangeId);
            if (weightRangeActive) {
                this.scope.model.OUTCOME.UNITARY = weightRangeActive.UNITARY;
                await this.refreshApplication();
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private setChargePairExhibition(): void {
        try {
            const validChargeExhibition = (this.scope.model.OUTCOME.CHARGE_EXHIBITION) ? this.scope.model.OUTCOME.CHARGE_EXHIBITION : null;
            if (validChargeExhibition) this.scope.model.CHARGE_EXHIBITION = validChargeExhibition;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getWeightRangeOutcomeActive(weightRangeId: string): Promise<WEIGHT_RANGE_CHARGE> {
        try {
            let weightRangeIdToSearch = null;
            if (this.scope.weightRangeActiveId && this.scope.weightRangeActiveId == weightRangeId) weightRangeIdToSearch = weightRangeId;
            else if (this.scope.model.OUTCOME.WEIGHT_RANGE) weightRangeIdToSearch = this.scope.model.OUTCOME.WEIGHT_RANGE.ID;
            const weightRangeActive = this.scope.model.OUTCOME.WEIGHT_RANGE_CHARGE.find(weightRangeCharge => weightRangeCharge.WEIGHT_RANGE && weightRangeCharge.WEIGHT_RANGE.ID == weightRangeIdToSearch);
            return weightRangeActive && weightRangeActive.WEIGHT_RANGE && weightRangeActive.WEIGHT_RANGE.ID == weightRangeId ? weightRangeActive : null;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async setNoPayment(charge) { // TODO: tipar
        charge.TRANSACTION = { ID: '0', NAME: '(Sem pagamento)' };
        charge.AMOUNT = 0;
        charge.COMPOSITION_GROUP = null;
        charge.CURRENCY = null;
        charge.EQUIPMENT = null;
        charge.MINIMUM = 0;
        charge.PEOPLE = null;
        charge.TOTAL = 0;
        charge.UNITARY = 0;
        charge.INVOICE_NUMBER = noBilling;
    }

    private async updateOutcomeMin() {
        try {
            if (this.scope.model.OUTCOME && this.scope.model.OUTCOME.APPLICATION && this.scope.model.OUTCOME.APPLICATION.PERCENT && !this.scope.model.OUTCOME.MINIMUM) {
                this.scope.model.OUTCOME.MINIMUM = 0;
            }

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getGenericValue(typeGeneric: string, alternative?: boolean): Promise<ISelectorModel[]> {
        try {
            const timeout: number = 10000;
            const route: string = (alternative) ? `/generic/value/${typeGeneric}/${alternative}` : `/generic/value/${typeGeneric}`;

            const rc = await this.HelperService.get(route, null, timeout);
            const result: ISelectorModel[] = (rc && rc.data && rc.data.data) ? rc.data.data : [];

            return result;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private filterAndSortTransaction(transactions: ISelectorModel[]): ISelectorModel[] {
        try {
            if (!transactions) return [];
            if (!transactions.length) return [];

            return transactions.filter(transaction => transaction && transaction.NAME).sort((x, y) => x.NAME > y.NAME ? 1 : -1);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private closeModal(): void {
        try {
            this.scope.modalOptions.ok(false);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private hasInvalidRequiredElements(elementId: string): boolean {
        if (!elementId) return false;
        const isInvalid = FormService2.hasRequiredElements('#' + elementId);

        if (isInvalid) this.scope.formService.notifyError(this.scope.formService.getTranslate("GENERAL.ALL_FIELDS_MANDATORY"));
        return isInvalid;
    }
}
import * as angular from "angular";
import { SSEService } from "@appServices/SSEService";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog, } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { ISessionService } from "@services/SessionService";
import { IModalService } from "@services/ModalService";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { ICorporateBranchModel } from "@models/interface/product/CorporateBranchModel";
import { IDocumentError } from "@models/interface/common/IDocumentError";
import { EOperation, EProductId } from "@enums/GenericData";
import { SelectorModel } from "../../common/model/SelectorModel";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { ILegalPersonExchangeData, ILegalPersonSelector } from "../model/LegalPersonModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { ILinkParameter } from "../../common/model/ModelParameter";
import { HelperService } from "@services/HelperService";

interface ICorporateBranchScope extends IGridFormServiceScope {
    model: ICorporateBranchModel;
    gridOptions: uiGrid.IGridOptions;
    log: IViewLog;
    productList: SelectorModel[];
    legalPersonList: ILegalPersonSelector[];
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: ICorporateBranchModel;
    scacIataLengthValidation: number;
    user: any;
    sessionService: ISessionService;
    getLegalPersonListByName: (search: string) => Promise<void>;
    goToLegalPerson: (id: number) => void;
    updateProduct: (product: SelectorModel[]) => void;
    productListFilter: () => Function;
    editCorporateBranch: (corporateBranch: ICorporateBranchModel) => Promise<void>;
    viewCorporateBranch: (corporateBranch: ICorporateBranchModel) => Promise<void>;
    viewLogCorporateBranch: (corporateBranch: ICorporateBranchModel) => Promise<void>;
    copyCorporateBranch: (corporateBranch: ICorporateBranchModel) => Promise<void>;
    isAirProduct: () => boolean;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
    checkCorporateProducts: () => boolean;
}

export class CorporateBranchRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: ICorporateBranchScope;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private RestService: IRestService;
    private SSEService: SSEService;
    private ModalService: IModalService;
    private gridName: string;
    private modalID: number;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: ICorporateBranchScope) {
        super($injector, $scope);
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.$scope = $scope;
        this.$scope.sessionService = $injector.get('SessionService');
        this.RestService = $injector.get('RestService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.ModalService = $injector.get('ModalService');
        this.modalID = null;
        this.gridName = 'GRID_CORPORATE_BRANCH';
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'corporateBranch', 'BASIC_DATA.BRANCH', true);
            await this.initGrid(this.gridName, '/corporateBranch/list', true, true, null, true, true);

            const sessionParameter = this.$gridService.$sessionParameter;
            if (sessionParameter && sessionParameter.data) this.callSessionFunctions(sessionParameter.data);

        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.getLegalPersonListByName = async (search: string) => {
            let legalPersonSelectorList: ILegalPersonSelector[] = [];
            if (search && search.length >= 3) {
                legalPersonSelectorList = await this.getLegalPersonListByName(search);
            }
            this.$scope.legalPersonList = legalPersonSelectorList;
        }

        this.$scope.goToLegalPerson = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.legalPerson", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.updateProduct = (product: SelectorModel[]) => {
            this.updateProduct(product);
        }

        this.$scope.productListFilter = (): Function => {
            return this.productListFilter();
        }

        this.$scope.editCorporateBranch = async (corporateBranch: ICorporateBranchModel): Promise<void> => {
            let blockedObject = {
                ID: corporateBranch.ID,
                NAME: corporateBranch.LEGAL_PERSON ? corporateBranch.LEGAL_PERSON.CORPORATE_NAME : null,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || corporateBranch.ID !== this.$scope.model.ID) this.$scope.view(corporateBranch);
                } else if (this.$scope.operation !== EOperation.EDIT || corporateBranch.ID !== this.$scope.model.ID) {
                    this.$scope.edit(corporateBranch);
                }
            };
        }
        this.$scope.viewCorporateBranch = async (corporateBranch: ICorporateBranchModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(corporateBranch);
        }

        this.$scope.viewLogCorporateBranch = async (corporateBranch: ICorporateBranchModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(corporateBranch);
        }

        this.$scope.copyCorporateBranch = async (corporateBranch: ICorporateBranchModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(corporateBranch);
        }

        this.$scope.isAirProduct = (): boolean => {
            return this.$scope.model && this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.some(product => product.ID == EProductId.AIR_EXPORT || product.ID == EProductId.AIR_IMPORT);
        }

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }

        this.$scope.checkCorporateProducts = (): boolean => {
            return this.checkCorporateProducts();
        }

    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewCorporateBranch(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editCorporateBranch(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyCorporateBranch(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogCorporateBranch(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + copy + viewLog + modalIntegration),
            enableCellEdit: true,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 100, cellFilter: "YesOrNo" };
            const colCorporateName: IMonacoColumnDef = { name: "LEGAL_PERSON.CORPORATE_NAME", displayName: "ENTITY.CORPORATE_NAME", width: 250 };
            const colScac: IMonacoColumnDef = { name: "SCAC", displayName: "GENERAL.SCAC_CODE", width: 400 };
            const colName: IMonacoColumnDef = { name: "NAME", displayName: "GENERAL.NAME", width: 250 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT", displayName: "BASIC_DATA.PRODUCT", width: 300, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PRODUCT, null, "ID")}}</div>' };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'ID_LEGAL_PERSON':
                        columnDefs.push(colCorporateName);
                        break;
                    case 'SCAC':
                        columnDefs.push(colScac);
                        break;
                    case 'NAME':
                        columnDefs.push(colName);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            LEGAL_PERSON: null,
            ID_LEGAL_PERSON: null,
            ACTIVE: true,
            SCAC_IATA: null,
            NUMBER_IATA: null,
            PRODUCT: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            SEARCH_FIELDS: null,
            DOCUMENT_ERROR: null
        };
    }

    initDependencies(): Promise<any> {
        const self: CorporateBranchRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getProductList(),
            ]).then((result: any) => {
                self.$scope.productList = result[0];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private updateProduct(product: SelectorModel[]): void {
        if (product) this.$scope.model.SCAC_IATA = null;
        else product = angular.copy(this.$scope.model.PRODUCT);

        if (product && product.length) {
            if (product[0].ID == EProductId.MARITIME_EXPORT || product[0].ID == EProductId.MARITIME_IMPORT) {
                this.$scope.model.NUMBER_IATA = null;
                this.$scope.scacIataLengthValidation = 4;
            }
            else if (product[0].ID == EProductId.AIR_EXPORT || product[0].ID == EProductId.AIR_IMPORT) this.$scope.scacIataLengthValidation = 2;
            else if (product[0].ID == EProductId.ROAD_EXPORT || product[0].ID == EProductId.ROAD_IMPORT) {
                this.$scope.model.NUMBER_IATA = null;
                this.$scope.scacIataLengthValidation = 4;
            }
        } else {
            this.$scope.model.SCAC_IATA = null;
            this.$scope.model.NUMBER_IATA = null;
            this.$scope.scacIataLengthValidation = 0;
        }
    }

    private productListFilter = (): Function => {
        const selectedProduct = this.$scope.model ? this.$scope.model.PRODUCT : null;
        const isAir = selectedProduct && selectedProduct.length && (selectedProduct[0].ID == EProductId.AIR_EXPORT || selectedProduct[0].ID == EProductId.AIR_IMPORT);
        const isMaritime = selectedProduct && selectedProduct.length && (selectedProduct[0].ID == EProductId.MARITIME_EXPORT || selectedProduct[0].ID == EProductId.MARITIME_IMPORT);
        const isRoad = selectedProduct && selectedProduct.length && (selectedProduct[0].ID == EProductId.ROAD_EXPORT || selectedProduct[0].ID == EProductId.ROAD_IMPORT);
        return function (product: SelectorModel) {
            if (!selectedProduct || selectedProduct && selectedProduct.length == 0) return true;
            if (isAir && (product.ID == EProductId.AIR_EXPORT || product.ID == EProductId.AIR_IMPORT)) return true;
            if (isMaritime && (product.ID == EProductId.MARITIME_EXPORT || product.ID == EProductId.MARITIME_IMPORT)) return true;
            if (isRoad && (product.ID == EProductId.ROAD_EXPORT || product.ID == EProductId.ROAD_IMPORT)) return true;
            return false;
        }
    }

    private callSessionFunctions(data: object): void {
        const legalPersonExchangeData = <ILegalPersonExchangeData>data;
        switch (legalPersonExchangeData.OPERATION) {
            case "register": this.$scope.register(legalPersonExchangeData.MODEL);
                break;
        }
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlDataManager = this.config.productUrl + baseRoute;
        return urlDataManager;
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
            {
                PROPERTY: 'LEGAL_PERSON',
                LABEL: 'ENTITY.CORPORATE_NAME'
            },
            {
                PROPERTY: 'SCAC_IATA',
                LABEL: 'GENERAL.SCAC_CODE'
            },
            {
                PROPERTY: 'NUMBER_IATA',
                LABEL: 'Número IATA'
            },
            {
                PROPERTY: 'PRODUCT',
                LABEL: 'BASIC_DATA.PRODUCT'
            },
            {
                PROPERTY: 'CORRESPONDENCE',
                LABEL: 'ENTITY.POSTAL_ADDRESS'
            },
            {
                PROPERTY: 'ID',
                LABEL: 'REGISTRATION.IDENTIFICATION'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'CODE',
                LABEL: 'GENERAL.CODE'
            },
            {
                PROPERTY: 'INTEGRATION_ID',
                LABEL: 'GENERAL.INTEGRATION_ID'
            },
            {
                PROPERTY: 'SHORT_NAME',
                LABEL: 'ENTITY.SHORT_NAME'
            },
            {
                PROPERTY: 'CORPORATE_NAME',
                LABEL: 'ENTITY.CORPORATE_NAME'
            },
            {
                PROPERTY: 'FANTASY_NAME',
                LABEL: 'ENTITY.TRADE_NAME'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            }
        ]
        return props;
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "GENERAL.MENU.INTEGRATION" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number, idSubject: string): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/corporateBranch/sendSync`, { "idCorporateBranch": id, "idSubject": idSubject }, 120000, false);
                if (syncRequest) success = true;
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_SENDING_REQUEST');
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const corporateBranchData = await this.getCorporateBranchById(id);
                    if (row && corporateBranchData && corporateBranchData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = corporateBranchData.DOCUMENT_ERROR;
                        documentError = corporateBranchData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async getCorporateBranchById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/corporateBranch/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getLegalPersonListByName(name: string): Promise<ILegalPersonSelector[]> {
        try {
            let result = [];
            if (name && name.length >= 3) {
                const legalPersons = await this.RestService.newObjectPromise(`${this.$baseUrl}/legalPerson/list/custom`, { specializations: [], search: name }, 30000, false);
                if (legalPersons) result = legalPersons.map(legalPerson => { return { ID: legalPerson.ID, CORPORATE_NAME: legalPerson.CORPORATE_NAME, SHORT_NAME: legalPerson.SHORT_NAME } });
            }
            return result;
        } catch (ex) {
            if (!ex.status || ex.status && ex.status !== 404) this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private checkCorporateProducts(): boolean {
        let disabled = false;

        const products = this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.length > 0 ? this.$scope.model.PRODUCT.filter(x => (x.ID == EProductId.MARITIME_EXPORT || x.ID == EProductId.MARITIME_IMPORT)) : null;
        if (products && products.length <= 0) {
            disabled = true;
        }

        return disabled;
    }

    async register(params: ICorporateBranchModel): Promise<void> {
        try {
            if (this.$scope.log) this.$scope.log.show = false;

            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');

            if (params && params.LEGAL_PERSON && params.LEGAL_PERSON.CORPORATE_NAME) {
                const legalPerson = await this.getLegalPersonListByName(params.LEGAL_PERSON.CORPORATE_NAME);
                if (legalPerson && legalPerson.length > 0) {
                    this.$scope.model.LEGAL_PERSON = legalPerson[0];
                }
            }

            this.updateProduct(null);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} (${this.$scope.model.LEGAL_PERSON.CORPORATE_NAME} - ${this.getCONCAT(this.$scope.model.PRODUCT, null, 'ID')})`;
            BrowserTitle.$id = `${this.$scope.model.LEGAL_PERSON.CORPORATE_NAME} - ${this.getCONCAT(this.$scope.model.PRODUCT, null, 'ID')}`;
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} (${this.$scope.model.LEGAL_PERSON.CORPORATE_NAME} - ${this.getCONCAT(this.$scope.model.PRODUCT, null, "ID")})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = `${this.$scope.model.LEGAL_PERSON.CORPORATE_NAME} - ${this.getCONCAT(this.$scope.model.PRODUCT, null, 'ID')}`;
            this.updateProduct(null);
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            await this.clearFields(this.$scope.model, null);
            this.updateProduct(null);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === "edit" ? "update" : "insert";

        return {
            route: `/corporateBranch/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: this.$scope.scopeBeforeSave
        };
    }
}

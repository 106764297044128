import { IAngularEvent } from 'angular';
import angular = require('angular');
import * as moment from 'moment';
import { IColumnDef, IGridOptions } from "ui-grid";
import { SSEService } from '@appServices/SSEService';
import { GridFormService, IGridFormController, IGridFormServiceScope, IMonacoRequestLog } from "@services/GridFormService"
import { IMonacoColumnDef } from '@services/GridService2';
import { IRestService } from '@services/RestService';
import { ISessionService } from "@services/SessionService";
import { IModalService } from '@services/ModalService';
import { DataProductService } from '@services/DataProductService';
import { IDocumentError } from '@models/interface/common/IDocumentError';
import { IAnalyzeCollectionData } from '@models/interface/product/AnalyzeCollectionData';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { BrowserTitle } from "../../common/BrowserTitle";
import { EOperation } from '@enums/GenericData';

export interface IStatisctCollectionError {
    DOCUMENT_ERROR: IDocumentError;
    ID?: number;
    PROPERTY?: string;
}

export interface IStatisctCollection {
    ID: number,
    COLLECTION: string;
    ERRORS?: IStatisctCollectionError[];
    STATUS?: string;
}

export interface IStatisctCollectionModal extends ng.IScope {
    statisctCollection: IStatisctCollection;
    parsedErrorMessage: string;
    copyErrorTextToClipboard: (documentError: IDocumentError) => void;
    sendSync: (statisctCollection: IStatisctCollection) => void;
}

interface IAnalyzeCollectionDataScope extends IGridFormServiceScope {
    model: IAnalyzeCollectionData;
    sessionService: ISessionService;
    scopeBeforeSave: IAnalyzeCollectionData;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    isEditing: boolean;
    modalService: IModalService;
    dataProductService: DataProductService;
    gridOptions: IGridOptions;
    user: any;
    editAnalyzeCollectionData: (analyzeCollectionData: IAnalyzeCollectionData) => Promise<void>;
    viewAnalyzeCollectionData: (analyzeCollectionData: IAnalyzeCollectionData) => Promise<void>;
    viewLogAnalyzeCollectionData: (analyzeCollectionData: IAnalyzeCollectionData) => Promise<void>;
    copyAnalyzeCollectionData: (analyzeCollectionData: IAnalyzeCollectionData) => Promise<void>;
    openModalStatisctCollection: (analyzeCollectionData: IAnalyzeCollectionData, statisctCollection: IStatisctCollection) => void;
}

export class AnalyzeCollectionDataRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IAnalyzeCollectionDataScope;
    private formName: string;
    private gridName: string;
    private $q: ng.IQService;
    private restService: IRestService;
    private $timeout: ng.ITimeoutService
    private modalStatisctCollection: number;
    private SSEService: SSEService;

    constructor($injector: ng.Injectable<any>, $scope: IAnalyzeCollectionDataScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$scope.sessionService = $injector.get('SessionService');
        this.restService = $injector.get('RestService');
        this.$timeout = $injector.get('$timeout');
        this.$scope.modalService = $injector.get('ModalService');
        this.$scope.dataProductService = $injector.get('DataProductService');
        this.$q = $injector.get('$q');
        this.$scope.isEditing = false;
        this.formName = 'AnalyzeCollectionData';
        this.gridName = 'GRID_ANALYZE_COLLECTION_DATA';
        this.SSEService = new SSEService($injector, $scope, this.formService);
    }

    getUrlProduct() {
        try {
            const baseRoute = '/product';
            const urlProduct = this.config.productUrl + baseRoute;
            return urlProduct;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', this.formName, 'GENERAL.MENU.COLLECTION_DOCUMENT_STATISTIC', true);
            await this.initGrid(this.gridName, '/analyzeCollectionData/list', true, true, null, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        try {
            this.$scope.editAnalyzeCollectionData = async (analyzeCollectionData: IAnalyzeCollectionData): Promise<void> => {
                let blockedObject = {
                    ID: analyzeCollectionData.ID,
                    NAME: `(${analyzeCollectionData.COLLECTION}/${analyzeCollectionData.PROPERTY})`,
                    EMAIL: this.$scope.user['email'],
                    FORM_NAME: this.gridName
                };
                this.SSEService.closeEvents();
                this.SSEService.setBlockedObject(blockedObject);
                this.SSEService.initEvents();
                this.SSEService.events.onmessage = async (event) => {
                    const parsedData = JSON.parse(event.data);
                    if (!parsedData.status) {
                        const result = await this.SSEService.generate(parsedData);
                        if (result && !result.status) {
                            this.$rootScope.refreshPage();
                            return;
                        }
                        if (this.$scope.operation !== EOperation.VIEW || analyzeCollectionData.ID !== this.$scope.model.ID) this.$scope.view(analyzeCollectionData);
                    } else if (this.$scope.operation !== EOperation.EDIT || analyzeCollectionData.ID !== this.$scope.model.ID) {
                        this.$scope.edit(analyzeCollectionData);
                    }
                };
            }

            this.$scope.viewAnalyzeCollectionData = async (analyzeCollectionData: IAnalyzeCollectionData): Promise<void> => {
                this.SSEService.closeEvents();
                this.$scope.view(analyzeCollectionData);
            }

            this.$scope.viewLogAnalyzeCollectionData = async (analyzeCollectionData: IAnalyzeCollectionData): Promise<void> => {
                this.SSEService.closeEvents();
                this.$scope.viewLog(analyzeCollectionData);
            }

            this.$scope.copyAnalyzeCollectionData = async (analyzeCollectionData: IAnalyzeCollectionData): Promise<void> => {
                this.SSEService.closeEvents();
                this.$scope.copy(analyzeCollectionData);
            }

            this.$scope.openModalStatisctCollection = async (analyzeCollectionData: IAnalyzeCollectionData): Promise<void> => {
                this.openModalStatisctCollection(analyzeCollectionData);
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        try {
            this.$scope.model = {
                _id: null,
                ID: null,
                COLLECTION: null,
                PROPERTY: null,
                DISTINCT_VALUES: 0,
                NULL_VALUES: 0,
                INDEX_DISTINCT_VALUES: 0,
                INDEX_NULL_VALUES: 0,
                INDEX_SELECTIVITY: 0,
                ACTIVE: true,
                CREATED_AT: null,
                CREATED_BY: null,
                UPDATED_AT: null,
                UPDATED_BY: null,
                SEARCH_FIELDS: null,
                DOCUMENT_ERROR: null
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initGridColumns(columns: string[]): IColumnDef[] {
        try {
            const gridColumns = new GridColumnBuilder([]);

            const view = `<a ng-click="grid.appScope.viewAnalyzeCollectionData(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
            const edit = `<a ng-click="grid.appScope.editAnalyzeCollectionData(row.entity)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
            const copy = `<a ng-click="grid.appScope.copyAnalyzeCollectionData(row.entity)" class="text-orange copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
            const modalIntegration = `<a ng-click="grid.appScope.openModalStatisctCollection(row.entity)" ng-class="(row.entity.DOCUMENT_ERROR && row.entity.DOCUMENT_ERROR.REASON) ? 'text-danger' : 'text-green'" tooltip-placement="auto top" uib-tooltip="{{ 'REGISTRATION.VIEW_STATISTICS_COLLECTION' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;`;
            const viewLog = `<a ng-click="grid.appScope.viewLogAnalyzeCollectionData(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;

            const colActions: IMonacoColumnDef = {
                name: "acoes",
                displayName: "GENERAL.ACTIONS",
                width: 120,
                cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${copy} ${modalIntegration} ${viewLog}</div>`,
                enableFiltering: false,
                enableSorting: false,
                enableHiding: false,
                enableColumnMoving: false,
                enableColumnResizing: false,
                pinnedLeft: true,
                enablePinning: false
            };

            gridColumns.addColumn(colActions);
            const newColumnDefs: IMonacoColumnDef[] = this.buildColumns(columns);
            for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) };

            return <IColumnDef[]>gridColumns.$columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            let gridColumns: IMonacoColumnDef[] = [];

            const defCols: IMonacoColumnDef[] = [];
            defCols.push(<IMonacoColumnDef>{ name: "COLLECTION", displayName: "GENERAL.COLLECTION", width: 200 });
            defCols.push(<IMonacoColumnDef>{ name: "PROPERTY", displayName: "GENERAL.COLLECTION_PROPERTY", width: 350 });
            defCols.push(<IMonacoColumnDef>{ name: 'DISTINCT_VALUES', displayName: 'GENERAL.DISTINCT_VALUES_QTY', width: 200 });
            defCols.push(<IMonacoColumnDef>{ name: "NULL_VALUES", displayName: "GENERAL.NULL_VALUES_QTY", width: 200 });
            defCols.push(<IMonacoColumnDef>{ name: "INDEX_DISTINCT_VALUES", displayName: "GENERAL.DISTINGUISHED_VALUES_INDEX", width: 250, type: "number", cellTemplate: '<div class="ui-grid-cell-contents" >{{row.entity.INDEX_DISTINCT_VALUES | number: 20}}</div>' });
            defCols.push(<IMonacoColumnDef>{ name: "INDEX_NULL_VALUES", displayName: "GENERAL.NULL_VALUE_INDEX", width: 250, cellTemplate: '<div class="ui-grid-cell-contents" >{{row.entity.INDEX_NULL_VALUES | number: 20}}</div>' });
            defCols.push(<IMonacoColumnDef>{ name: "INDEX_SELECTIVITY", displayName: "GENERAL.SELECTIVITY_INDEX", width: 250, cellTemplate: '<div class="ui-grid-cell-contents" >{{row.entity.INDEX_SELECTIVITY | number: 20}}</div>' });
            defCols.push(<IMonacoColumnDef>{ name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 80, cellFilter: "YesOrNo" });
            defCols.push(<IMonacoColumnDef>{ name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm:ss'" });
            defCols.push(<IMonacoColumnDef>{ name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: "date:'dd/MM/yyyy HH:mm:ss'" });
            defCols.push(<IMonacoColumnDef>{ name: 'ID', displayName: 'GENERAL.ID', width: 80 });

            columns.forEach(column => {
                const findCol: IMonacoColumnDef = defCols.find(coll => coll.name == column);
                if (findCol) gridColumns.push(findCol);
            });

            return <IMonacoColumnDef[]>gridColumns;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initDependencies(): Promise<any> {
        const self: AnalyzeCollectionDataRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([]).then((result: any) => {
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    async register(params: IAnalyzeCollectionData): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
            this.$scope.disableElements(false);
            this.$scope.isEditing = false;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")} (${this.$scope.model.COLLECTION}/${this.$scope.model.PROPERTY})`;
            BrowserTitle.$id = `${this.$scope.model.COLLECTION}/${this.$scope.model.PROPERTY}`;
            this.$scope.disableElements(true);
            this.$scope.isEditing = false;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")} (${this.$scope.model.COLLECTION}/${this.$scope.model.PROPERTY})`;
            BrowserTitle.$id = `${this.$scope.model.COLLECTION}/${this.$scope.model.PROPERTY}`;
            this.$scope.disableElements(false);
            this.$scope.isEditing = true;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        let proceed: boolean = true;
        try {
            this.SSEService.closeEvents();
            this.$scope.isEditing = false;
            if (proceed) { }
        } catch (ex) {
            this.handleError(ex);
            return false;
        }
        return proceed;
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        this.$scope.model.PROPERTY = this.$scope.model.PROPERTY.toUpperCase();
        return {
            route: `/analyzeCollectionData/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    async cancel(): Promise<void> {
        try {
            this.$scope.isEditing = false;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: "ID",
                LABEL: "GENERAL.ID",
            },
            {
                PROPERTY: "COLLECTION",
                LABEL: "GENERAL.COLLECTION",
            },
            {
                PROPERTY: "PROPERTY",
                LABEL: "GENERAL.COLLECTION_PROPERTY",
            },
            {
                PROPERTY: "NULL_VALUES",
                LABEL: "GENERAL.NULL_VALUES_QTY",
            },
            {
                PROPERTY: "DISTINCT_VALUES",
                LABEL: "GENERAL.DISTINCT_VALUES_QTY",
            },
            {
                PROPERTY: "INDEX_NULL_VALUES",
                LABEL: "GENERAL.NULL_VALUE_INDEX",
            },
            {
                PROPERTY: "INDEX_DISTINCT_VALUES",
                LABEL: "GENERAL.DISTINGUISHED_VALUES_INDEX",
            },
            {
                PROPERTY: "INDEX_SELECTIVITY",
                LABEL: "GENERAL.SELECTIVITY_INDEX",
            },
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE",
            },
            {
                PROPERTY: "CREATED_AT",
                LABEL: "GENERAL.CREATED_AT",
            },
            {
                PROPERTY: "CREATED_BY",
                LABEL: "GENERAL.CREATED_BY",
            },
            {
                PROPERTY: "UPDATED_AT",
                LABEL: "GENERAL.UPDATED_AT",
            },
            {
                PROPERTY: "UPDATED_BY",
                LABEL: "GENERAL.UPDATED_BY",
            }
        ];
        return <ICustomLogProperties[]>props;
    }

    private openModalStatisctCollection(analyzeCollectionData: IAnalyzeCollectionData): void {
        try {
            this.modalStatisctCollection = this.$scope.modalService.newModal();

            this.$scope.modalService.showModalInfo(
                {
                    modalID: this.modalStatisctCollection,
                    scope: this.$scope,
                    formService: 'register',
                    template: require('../view/modals/statisticCollection.html'),
                    events: async (event: IAngularEvent, reason: Object, closed: boolean): Promise<void> => {
                        this.$rootScope.refreshPage();
                    }
                },
                {
                    actionButtonText: "GENERAL.CLOSE",
                    headerText: this.formService.getTranslate("REGISTRATION.STATISTICS_COLLECTION")
                });

            let statisctCollection: IStatisctCollection = <IStatisctCollection>{
                ID: analyzeCollectionData.ID,
                COLLECTION: analyzeCollectionData.COLLECTION,
                ERRORS: []
            };

            if (analyzeCollectionData.DOCUMENT_ERROR && analyzeCollectionData.DOCUMENT_ERROR) {
                let errorParsed = <IStatisctCollectionError>{
                    ID: analyzeCollectionData.ID,
                    PROPERTY: analyzeCollectionData.PROPERTY,
                    DOCUMENT_ERROR: analyzeCollectionData.DOCUMENT_ERROR
                };
                //errorParsed.DOCUMENT_ERROR.PARSED_ERROR_MESSAGE = this.parseErrorMessage(errorParsed.DOCUMENT_ERROR);
                statisctCollection.ERRORS.push(errorParsed);
            }

            this.buildIntegrationErrorModalScope(statisctCollection);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async buildIntegrationErrorModalScope(statisctCollection: IStatisctCollection): Promise<IStatisctCollectionModal> {
        const modalScope: IStatisctCollectionModal = await this.$scope.modalService.getModalScope(this.modalStatisctCollection);

        modalScope.statisctCollection = angular.copy(statisctCollection);

        modalScope.copyErrorTextToClipboard = (documentError: IDocumentError) => {
            if (documentError) {
                const elmAux = document.createElement("textarea");
                document.body.appendChild(elmAux);
                //elmAux.value = documentError.PARSED_ERROR_MESSAGE;
                elmAux.select();
                document.execCommand("copy");
                document.body.removeChild(elmAux);
                this.formService.notifySuccess("Mensagem de log copiada para o clipboard.");
            }
        }
        modalScope.sendSync = async (statisctCollection: IStatisctCollection): Promise<void> => {
            let result = null;
            try {
                if (statisctCollection.ID) {
                    this.formService.block();
                    modalScope.statisctCollection.STATUS = this.formService.getTranslate("REGISTRATION.MESSAGES.ERROR.SENDING_REQUEST");
                    const rc = await this.$scope.dataProductService.post(`/sync/analyzeCollectionData`, { "id": [statisctCollection.ID] }, 120000);

                    if (rc && rc.data && rc.data.data && rc.status == 200) {
                        result = rc.data.data;
                    }

                    if (result) {
                        modalScope.statisctCollection.ERRORS = null;
                        modalScope.statisctCollection.STATUS = this.formService.getTranslate("REGISTRATION.MESSAGES.ERROR.SUCCESS_REQUEST");
                    }
                }

                this.updateIntegrationGrid(modalScope);
            } catch (ex) {
                const documentError = <IDocumentError>{
                    DATE: new Date(),
                    REASON: ex
                };
                modalScope.statisctCollection.STATUS = this.parseErrorMessage(documentError);
                this.formService.handleError(this.formService.getTranslate("GENERAL.ERROR_SENDING_REQUEST"));
            } finally {
                this.formService.unblock();
                return result;
            }
        }
        return <IStatisctCollectionModal>modalScope;
    }

    private async updateIntegrationGrid(modalScope: IStatisctCollectionModal): Promise<void> {
        try {
            this.formService.block();
            if (modalScope.statisctCollection) {
                modalScope.statisctCollection.STATUS = this.formService.getTranslate("REGISTRATION.STATISTICS_COLLECTION_PROCESSING");
                if (!modalScope.statisctCollection.ERRORS) {
                    setTimeout(async () => {
                        try {
                            if (angular.isArray(this.$scope.gridOptions.data)) {
                                let linha: IAnalyzeCollectionData = this.$scope.gridOptions.data.find(x => x.ID == modalScope.statisctCollection.ID);
                                const analyzeCollectionData: IAnalyzeCollectionData = await this.getAnalyzeCollectionDataById(modalScope.statisctCollection.ID);

                                if (linha && analyzeCollectionData && analyzeCollectionData.DOCUMENT_ERROR !== undefined) {
                                    linha.DOCUMENT_ERROR = analyzeCollectionData.DOCUMENT_ERROR;
                                    if (analyzeCollectionData.DOCUMENT_ERROR && analyzeCollectionData.DOCUMENT_ERROR.REASON) {
                                        modalScope.statisctCollection.STATUS = "";
                                        modalScope.statisctCollection.ERRORS = [];
                                        let errorParsed = <IStatisctCollectionError>{
                                            ID: analyzeCollectionData.ID,
                                            PROPERTY: analyzeCollectionData.PROPERTY,
                                            DOCUMENT_ERROR: analyzeCollectionData.DOCUMENT_ERROR
                                        };
                                        //errorParsed.DOCUMENT_ERROR.PARSED_ERROR_MESSAGE = this.parseErrorMessage(errorParsed.DOCUMENT_ERROR);
                                        modalScope.statisctCollection.ERRORS.push(errorParsed);
                                    } else {
                                        modalScope.statisctCollection.STATUS = this.formService.getTranslate("REGISTRATION.STATISTICS_COLLECTION_SUCCESS");
                                        modalScope.statisctCollection.ERRORS = [];
                                    }
                                }
                            }
                        } catch (ex) {
                            const documentError = <IDocumentError>{
                                DATE: new Date(),
                                REASON: ex
                            };
                            modalScope.statisctCollection.STATUS = this.parseErrorMessage(documentError);
                            this.formService.handleError(this.formService.getTranslate("REGISTRATION.STATISTICS_COLLECTION_ERROR"));
                        } finally {
                            this.formService.unblock();
                        }
                    }, 3000)
                } else {
                    this.formService.unblock();
                }
            } else {
                const documentError = <IDocumentError>{
                    DATE: new Date(),
                    REASON: this.formService.getTranslate("GENERAL.ERROR_DURING_REQUEST")
                };
                modalScope.statisctCollection.STATUS = this.parseErrorMessage(documentError);
                this.formService.unblock();
            }
        } catch (ex) {
            const documentError = <IDocumentError>{
                DATE: new Date(),
                REASON: ex
            };
            modalScope.statisctCollection.STATUS = this.parseErrorMessage(documentError);
            this.formService.handleError(this.formService.getTranslate("GENERAL.ERROR_DURING_REQUEST"));
            this.formService.unblock();
        }
    }

    private parseErrorMessage(documentError: IDocumentError): string {
        let returnString = ``;
        if (documentError && documentError.DATE && documentError.REASON) {
            returnString = (moment(documentError.DATE).format("(DD/MM) HH:mm:ss"));
            returnString += (' - ');
            returnString += documentError.REASON;
        }
        return returnString;
    }

    private async getAnalyzeCollectionDataById(id: number): Promise<IAnalyzeCollectionData> {
        try {
            if (!id) throw new Error("Missing id parameter in getAnalyzeCollectionDataById");
            this.formService.block();
            const analyzeCollectionData = await this.restService.getObject(`${this.$baseUrl}/analyzeCollectionData/getById/${id}`, 30000, false);
            return <IAnalyzeCollectionData>analyzeCollectionData.data.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

}

import angular = require('angular');
import * as Address from '../../communication/Address';
import { IFormServiceScope, FormService2 } from "@services/FormService2";
import { ISessionService } from '@services/SessionService';
import { IGridFormServiceScope, GridFormService } from "@services/GridFormService";
import { DataProcessService } from "@services/DataProcessService";
import { ICustomLogProperties, IViewLog } from "@models/interface/common/IViewLog";
import { INCONSISTENCY_CHARGE_DOCUMENT, ProcessDocument as IProcessDocument, IProcessDocumentModel } from "@models/interface/operational/ProcessDocument";
import { IDraftParameter, IProcessDocumentParameter } from "../../common/model/ModelParameter";
import { INewProcessScope, ECollapseState } from './NewProcessController';
import { EProductId, EOperation, EProcessDocumentType, EProcessDocumentTypeId, EReportCategoryName } from "@enums/GenericData";
import { SSEService } from '@appServices/SSEService';
import { IModalService, IFileReferenceDisabledFields, IFileReferencePreSetFields } from '@services/ModalService';
import { SelectorModel } from '../../common/model/SelectorModel';
import { EProcessDocumentSituationId } from '@models/interface/operational/GenericData';
import { HelperService } from "@services/HelperService";
import { ProductService } from '@services/ProductService';
import { OperationalService } from '@services/OperationalService';
import { Process as IProcess } from 'WBA-Model/dist/interface/operational/NewProcess';
import { Process } from "@models/interface/operational/NewProcess";
import { REFERENCE } from '@models/interface/operational/NewProcess';
import { IFiles } from '../../app/controllers/FileReferenceModalController';
import { IRestService } from "@services/RestService";
import { IDownloadParamsReturn } from '@services/DownloadFilesGenericService';


interface IProcessDocumentModal extends IFormServiceScope {
    model: IProcessDocument;
    oldModel: IProcessDocument;
    type: SelectorModel;
    processDocumentSituationList: SelectorModel[];
    documentReleaseList: SelectorModel[];
    inconsistencyList: SelectorModel[];
    inconsistencyPrefList: SelectorModel[];
    inconsistencyReleaseList: SelectorModel[];
    processDocumentStatusList: SelectorModel[];
    releaseTypeList: SelectorModel[];
    masterList: SelectorModel[];
    cityList: string[];
    getCityListByNameOrInitials: (search?: string) => void;
    getMasterList: () => void;
    processDocumentTypeList: () => SelectorModel[];
    applyProcessDocument: () => Promise<void>;
    closeProcessDocumentModal: () => Promise<void>;
    checkCorrectionSuspended: () => void;
    suspended: boolean;
    correction: boolean;
    awbNumberExchange: {
        modelID: number;
        awbSelectedValue: any;
        awbNumberList: Array<SelectorModel>;
    }

    applyAwbNumberExchange: () => void;
    goToAWBStock: () => void;
}

interface IProcessDocumentType {
    type: SelectorModel;
}
interface IProcessKnowledgeModal extends IFormServiceScope {
    model: IProcessDocument;
    oldModel: IProcessDocument;
    process: IProcess
    processDocumentType: IProcessDocumentType;

    processDocumentTypeList: () => SelectorModel[];
    getProcessSelector: (processNumber: string) => void;
    getProcessData: (number: string) => Promise<void>;
    applyProcessDocument: () => Promise<void>;
    closeProcessDocumentModal: () => Promise<void>;
}

interface IReferenceModalScope extends IFormServiceScope {
    references: REFERENCE[];
    processNumber: string;
    oldReferences: REFERENCE[];
    operation: string;
    closeReferenceModal: () => Promise<void>;
    applyReferenceModal: (close?: boolean) => void;
    viewFile: (file: REFERENCE) => void;
    downloadFile: (file: REFERENCE) => Promise<void>;
    copyFileLinkToClipboard: (file: REFERENCE) => void;
    viewProcessReferenceModal: (file: REFERENCE) => Promise<void>;
    editProcessReferenceModal: (index: number) => Promise<void>;
    fileReferenceModal: () => void;
    removeReference: (index: number) => void;
    mainFileChange: (index: number) => void;
}

interface INewProcessDocumentScope extends IGridFormServiceScope {
    log: IViewLog;
    processDocumentList: IProcessDocument[];
    model: IProcessDocument;
    scopeBeforeSave: IProcessDocument;
    customLogProperties: ICustomLogProperties[];
    processDocumentSituationList: SelectorModel[];
    documentReleaseList: SelectorModel[];
    inconsistencyList: SelectorModel[];
    inconsistencyPrefList: SelectorModel[];
    inconsistencyReleaseList: SelectorModel[];
    processDocumentStatusList: SelectorModel[];
    selectedType: string;
    releaseTypeList: SelectorModel[];
    fileReferenceModalID: number;
    lastScroll: number;
    process: Process;
    oldProcess: Process;

    collapseDocument: () => void;
    goToDocument: (process: string, taskNumber: string) => void;
    viewProcessDocument: (processDocument: IProcessDocument) => void;
    editProcessDocument: (processDocument: IProcessDocument) => void;
    hasChanges: (newObj: Object, oldObj: Object, propertiesToIgnore?: string[]) => boolean;
    modalSaveConfirmation: (headerText: string, closeButtonText: string) => Promise<boolean>;
    hasInvalidRequiredElements: (elementId: string) => boolean;
    goToDraftByProcessNumber: (processNumber: string) => void;
    showReferencesModal: (processDocument: IProcessDocument) => Promise<void>;
    addProcessKnowledge: () => void;
}

export class NewProcessDocumentController extends GridFormService {
    private $q: ng.IQService;
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: INewProcessDocumentScope;
    private $newProcessScope: INewProcessScope;
    private sessionService: ISessionService;
    private DataProcessService: DataProcessService;
    private SSEService: SSEService;
    private ModalService: IModalService;
    private HelperService: HelperService;
    private ProductService: ProductService;
    private OperationalService: OperationalService;
    private RestService: IRestService;
    private downloadRoute: string;
    private fileReferenceModalID: number;
    private modalID: number;

    constructor($injector: ng.Injectable<any>, $scope: INewProcessDocumentScope) {
        super($injector, $scope);
        this.$q = $injector.get('$q');
        this.$scope = $scope;
        this.$newProcessScope = <INewProcessScope>$scope.$parent.$parent;
        this.DataProcessService = $injector.get('DataProcessService');
        this.formService = this.$newProcessScope.formService;
        this.sessionService = this.$newProcessScope.sessionService;
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.ModalService = $injector.get('ModalService');
        this.HelperService = $injector.get('HelperService');
        this.ProductService = $injector.get('ProductService');
        this.OperationalService = $injector.get('OperationalService');
        this.$scope.goToDraftByProcessNumber = (processNumber: string) => this.goToDraftByProcessNumber(processNumber);
        this.$scope.showReferencesModal = (processDocument: IProcessDocument) => this.showReferencesModal(processDocument);
        this.RestService = $injector.get('RestService');
        this.$scope.selectedType = '';

        if (this.config.environment === 'prod') {
            this.downloadRoute = `${Address.monacoAddressProd.FIS}/api/v1/fis/filesGeneric/download`;
        } else if (this.config.environment === 'qa') {
            this.downloadRoute = `${Address.monacoAddressQa.FIS}/api/v1/fis/filesGeneric/download`;
        } else { //dev
            this.downloadRoute = `${Address.monacoAddressLocal.FIS}/api/v1/fis/filesGeneric/download`;
        }
        this.initScopeFunctions();
    }

    async $onInit(): Promise<void> {
        try {
            this.initModel();
            await this.initDependencies();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initModel(): void {
    }

    private initScopeFunctions(): void {

        this.$scope.collapseDocument = (): void => {
            this.collapseDocument();
        }

        this.$scope.goToDocument = async (process: string) => {
            this.sessionService.openTab("app.management.activities", <IProcessDocumentParameter>{ "PROCESS_NUMBER": process });
        }
        this.$scope.viewProcessDocument = (processDocument: IProcessDocument): void => {
            this.viewProcessDocument(processDocument);
        }
        this.$scope.editProcessDocument = (processDocument: IProcessDocument): void => {
            this.editProcessDocument(processDocument);
        }
        this.$scope.hasChanges = (newObj: Object, oldObj: Object, propertiesToIgnore?: string[]) => {
            if (propertiesToIgnore) {
                const newAux = newObj ? angular.copy(newObj) : null;
                const oldAux = oldObj ? angular.copy(oldObj) : null;
                for (const property of propertiesToIgnore) {
                    if (newAux && newAux[property]) {
                        delete newAux[property];
                    }
                    if (oldAux && oldAux[property]) {
                        delete oldAux[property];
                    }
                }
                return !angular.equals(JSON.stringify(newAux), JSON.stringify(oldAux));
            }
            return !angular.equals(newObj, oldObj);
        }
        this.$scope.modalSaveConfirmation = (headerText: string, closeButtonText: string) => this.modalSaveConfirmation(headerText, closeButtonText)
        this.$scope.hasInvalidRequiredElements = (elementId: string) => {
            return this.hasInvalidRequiredElements(elementId);
        }
        this.$scope.viewLog = (entity) => this.viewLog(entity);
        this.$scope.addProcessKnowledge = (): void => {
            this.addProcessKnowledge();
        }

    }

    async initDependencies(): Promise<any> {
        this.initCollapseEvents();
        const self: NewProcessDocumentController = this;

        const promises = {
            process_document_situation: self.getGenericValue("process_document_situation"),
            document_release: self.getGenericValue("document_release"),
            inconsistency_process_doc: self.getGenericValue("inconsistency_process_doc"),
            type_payment: self.getGenericValue("type_payment"),
            inconsistency_charge_release: self.getGenericValue("inconsistency_charge_release"),
            process_document_status: self.getGenericValue("process_document_status"),
            release_type: self.getGenericValue("release_type"),

        };

        return new Promise((resolve, reject) => {
            self.$q.all(promises).then((result) => {
                self.$scope.processDocumentSituationList = result.process_document_situation;
                self.$scope.documentReleaseList = result.document_release;
                self.$scope.inconsistencyList = result.inconsistency_process_doc;
                self.$scope.inconsistencyPrefList = result.type_payment;
                self.$scope.inconsistencyReleaseList = result.inconsistency_charge_release;
                self.$scope.processDocumentStatusList = result.process_document_status;
                self.$scope.releaseTypeList = result.release_type;

                resolve(true);
            }).catch((ex) => {
                reject(ex);
            });
        });
    }

    private initCollapseEvents() {
        this.$scope.$on('processDocumentCollapse', () => {
            this.collapseDocument();
        });

        const collapseDocument = angular.element('#collapseDocument');
        if (collapseDocument) {
            collapseDocument.on('shown.bs.collapse', (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    this.getDocumentByProcess();
                    angular.element("#collapseDocumentHeading").attr('aria-expanded', 'true');
                    // update collapse state
                    this.$newProcessScope.collapseState = { panel: ECollapseState.PROCESS_DOCUMENT, released: false, nextState: null };
                    this.$newProcessScope.repositionPanels(ECollapseState.PROCESS_DOCUMENT, true);
                    this.$newProcessScope.disableElements(this.$newProcessScope.operation == EOperation.VIEW);
                }
            });
            collapseDocument.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseDocumentHeading").attr('aria-expanded', 'false');
                }
            });
        }
    }
    private async getGenericValue(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.HelperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async collapseDocument() {
        try {
            if (this.$newProcessScope.collapseState.released || this.$newProcessScope.collapseState.panel == ECollapseState.PROCESS_DOCUMENT) {
                const collapseDocument = angular.element("#collapseDocument");
                if (collapseDocument) {
                    const isCollapsed = angular.element("#collapseDocumentHeading").attr("aria-expanded") == "true";
                    if (isCollapsed) {
                        this.$newProcessScope.collapseState.released = true;
                    }
                    collapseDocument['collapse']('toggle');
                    if (isCollapsed) this.$newProcessScope.repositionPanels(ECollapseState.PROCESS_DOCUMENT);
                }
            } else {
                this.$newProcessScope.collapseState.nextState = ECollapseState.PROCESS_DOCUMENT;
                this.$newProcessScope.releaseCollapse();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getProcessDocumentByProcessNumber(processNumber: string) {
        return this.DataProcessService.get(`/processDocument/number/${processNumber}`, null);
    }

    private updateProcessDocument(data: IProcessDocument, oldData: IProcessDocument) {
        return this.DataProcessService.post(`/processDocument/update`, { data, oldData }, 30000);
    }

    private getProcessDocumentLog(logNumber: string) {
        return this.DataProcessService.get(`/processDocument/viewlog/${logNumber}`, null);
    }

    private getProcessByNumber(number: string): Promise<any> {
        const timeout = 120000;
        return this.OperationalService.get(`/process/byNumber/${number}/${timeout}`, null, timeout);
    }

    private async getAWBNumbersList(processNumber: string) {
        try {
            const request = { processnumber: processNumber };
            const awbnumberList = await this.OperationalService.get<any>(`/awbstock/list/${processNumber}`, request);
            return awbnumberList;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private insertProcessDocument(data, oldData) {
        try {
            delete data.DOCUMENT_COUNT_HOUSE;
            delete data.DOCUMENT_COUNT_MASTER;
            delete data.PROCESS;
            delete data.SINGLE_TERM;
            data._id = null;

            return this.DataProcessService.post(`/processDocument/insert`, { data, oldData }, 30000);
        } catch (ex) {
            throw ex;
        }
    }

    private async getDocumentByProcess() {
        try {
            if (!this.$newProcessScope.model.PROCESS_NUMBER) throw new Error("Número do Processo está nulo.");

            this.formService.block();
            const processNumber = this.$newProcessScope.model.PROCESS_NUMBER
            const result = await this.getProcessDocumentByProcessNumber(processNumber);

            if (result && result.data && result.data.data) {
                const allProcessDocument: IProcessDocumentModel[] = result.data.data;
                this.$scope.processDocumentList = allProcessDocument.filter(document => document.ACTIVE == true);
            }
            this.formService.unblock();
        } catch (ex) {
            this.$scope.processDocumentList = [];
            this.formService.unblock();
        }
    }

    async viewProcessDocument(model: IProcessDocument): Promise<void> {
        try {
            this.$scope.operation = EOperation.VIEW;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW");
            this.openViewEditModal(model)

        } catch (ex) {
            this.SSEService.closeEvents();
            this.formService.handleError(ex);
        }
    }

    async editProcessDocument(model: IProcessDocument): Promise<void> {
        try {
            this.$scope.operation = EOperation.EDIT;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT");
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.openViewEditModal(model)

        } catch (ex) {
            this.SSEService.closeEvents();
            this.formService.handleError(ex);
        }
    }

    private async openViewEditModal(model: IProcessDocument): Promise<void> {
        const productType = model.PRODUCT.ID;
        if (productType == EProductId.AIR_EXPORT || productType == EProductId.ROAD_EXPORT || productType == EProductId.MARITIME_EXPORT) {
            await this.openExportModal(model)
        } else if (productType == EProductId.AIR_IMPORT || productType == EProductId.ROAD_IMPORT || productType == EProductId.MARITIME_IMPORT) {
            await this.openImportModal(model)
        }
    }

    private async openExportModal(model: IProcessDocument): Promise<void> {
        try {
            this.formService.block();

            this.modalID = this.ModalService.newModal();
            this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    template: require("../view/modal/exportProcessDocument.html"),
                    formService: this.$scope.operation,
                    size: 'full modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: this.$scope.formOperation
                },
                null
            );

            await this.buildProcessDocumentModalScope(model);

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async openImportModal(model: IProcessDocument): Promise<void> {
        try {
            this.formService.block();

            this.modalID = this.ModalService.newModal();
            this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    template: require("../view/modal/importProcessDocument.html"),
                    formService: this.$scope.operation,
                    size: 'full modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: this.$scope.formOperation
                },
                null
            );

            await this.buildProcessDocumentModalScope(model);

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async buildProcessDocumentModalScope(model: IProcessDocument): Promise<IProcessDocumentModal> {
        const modalScope: IProcessDocumentModal = await this.ModalService.getModalScope(this.modalID);

        modalScope.model = angular.copy(model);
        modalScope.oldModel = angular.copy(model);
        modalScope.correction = modalScope.model.CORRECTION;
        modalScope.suspended = modalScope.model.SUSPENDED;

        modalScope.checkCorrectionSuspended = () => {
            modalScope.correction = modalScope.model.CORRECTION;
            modalScope.suspended = modalScope.model.SUSPENDED;
        };

        const typeList = this.processDocumentTypeList();
        modalScope.type = typeList.find(type => type.NAME == model.TYPE);

        modalScope.processDocumentSituationList = this.$scope.processDocumentSituationList;
        modalScope.documentReleaseList = this.$scope.documentReleaseList;
        modalScope.inconsistencyList = this.$scope.inconsistencyList;
        modalScope.inconsistencyPrefList = this.$scope.inconsistencyPrefList;
        modalScope.inconsistencyReleaseList = this.$scope.inconsistencyReleaseList;
        modalScope.processDocumentStatusList = this.$scope.processDocumentStatusList;
        modalScope.masterList = await this.getMasterList(modalScope.model);
        modalScope.releaseTypeList = this.$scope.releaseTypeList;

        modalScope.getMasterList = async () => {
            if (modalScope.model.MASTER) modalScope.model.MASTER = null;
            modalScope.masterList = await this.getMasterList(modalScope.model);
        };
        modalScope.processDocumentTypeList = () => this.processDocumentTypeList();

        modalScope.getCityListByNameOrInitials = async (search?: string) => {
            modalScope.cityList = await this.getCityListByNameOrInitials(search);
        };

        modalScope.applyProcessDocument = async () => {
            if (await this.saveProcessDocument(modalScope.model, modalScope.oldModel, modalScope.type)) {
                this.closeModal();
            }
        };

        modalScope.closeProcessDocumentModal = async () => {
            if (this.$scope.hasChanges(JSON.stringify(modalScope.model), JSON.stringify(modalScope.oldModel))) {
                const confirm = await this.$scope.modalSaveConfirmation("GENERAL.CLOSE", "GENERAL.CLOSE");
                if (confirm) return;
            }
            this.closeModal();
        };

        const awbNumber = modalScope.model.AWB_NUMBER ? modalScope.model.AWB_NUMBER : null;
        modalScope.awbNumberExchange = {
            modelID: null,
            awbSelectedValue: null,
            awbNumberList: []
        }

        modalScope.awbNumberExchange.awbSelectedValue = { ID: null, NAME: awbNumber }
        const result = (process) ? await this.getAWBNumbersList(modalScope.model.PROCESS_NUMBER) : null;
        for (let register of result.data.data) {
            if (register.AWB_NUMBER) {
                let document = { ID: register.ID, NAME: register.AWB_NUMBER };
                modalScope.awbNumberExchange.awbNumberList.push(document);
            }
        }

        modalScope.applyAwbNumberExchange = async () => {
            if (!modalScope.awbNumberExchange.awbSelectedValue) {
                modalScope.model.AWB_NUMBER = null;
            } else {
                const newDocument = modalScope.awbNumberExchange.awbSelectedValue.NAME;
                modalScope.model.DOCUMENT = newDocument;
                modalScope.model.AWB_NUMBER = newDocument;
            }
        };

        modalScope.goToAWBStock = async () => {
            this.sessionService.openTab("app.operational.awbstock");
        }

        await modalScope.$applyAsync();

        return modalScope;
    }

    private processDocumentTypeList(): Array<SelectorModel> {
        const master: SelectorModel = {
            ID: EProcessDocumentTypeId.MASTER,
            NAME: EProcessDocumentType.MASTER
        }

        const house: SelectorModel = {
            ID: EProcessDocumentTypeId.HOUSE,
            NAME: EProcessDocumentType.HOUSE
        }

        return [master, house];
    }

    private async getMasterList(model: IProcessDocument): Promise<SelectorModel[]> {
        let masterList = []
        if (model.TYPE == EProcessDocumentType.HOUSE) {
            this.block();
            const documentsResult = await this.getProcessDocumentByProcessNumber(model.PROCESS_NUMBER)
            const documentsList: IProcessDocument[] = (documentsResult && documentsResult.data && documentsResult.data.data) ? documentsResult.data.data : [];
            this.unblock();
            if (documentsList && documentsList.length) {
                masterList = documentsList.filter(document => document.TYPE == EProcessDocumentType.MASTER && document.ACTIVE).map(document => {
                    return { ID: document.ID, NAME: document.DOCUMENT }
                });
            }
        } else {
            if (model.DOCUMENT != null) masterList.push({ ID: model.ID, NAME: model.DOCUMENT });
        }
        return masterList;
    }

    private async getCityListByNameOrInitials(search?: string, fullName: boolean = false): Promise<string[]> {
        let result = [];
        this.formService.block();
        try {
            if (search && search.length >= 3) {
                result = await this.getStringCityCountryList(search)
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getStringCityCountryList(search: string, fullName: boolean = false) {
        let result = [];
        const cities = await this.ProductService.post({ route: '/city/list/custom', data: { name: search, fullName } });
        if (cities && cities.data && cities.data.data) result = cities.data.data.map(city => { return city.NAME + ' - ' + city.PROVINCE.COUNTRY.NAME });
        return result;
    }

    private async getStringCityCountryById(id: number) {
        let result = '';
        const city = await this.ProductService.get({ route: `/city/getById/${id}` });
        if (city && city.data && city.data.data) result = `${city.data.data.NAME} - ${city.data.data.PROVINCE.COUNTRY.NAME}`;
        return result;
    }

    private async getLocalIssueanceToProductTypeEA(branchName: string) {
        let result = '';
        const name = branchName;
        this.formService.block();
        try {
            const legalPersonResponse = await this.ProductService.post({
                route: "/legalPerson/list/custom",
                data: {
                    ...{ search: name, specializations: [] },
                    sysConvertIdToString: false,
                },
            });
            const legalPerson =
                legalPersonResponse && legalPersonResponse.data && legalPersonResponse.data.data
                    ? legalPersonResponse.data.data.map((legalPerson) => {
                        return {
                            ID_CITY: legalPerson.ADDRESS.ID_CITY,
                        };
                    })
                    : '';
            const cityName = await this.getStringCityCountryById(legalPerson[0].ID_CITY);
            result = cityName ? cityName : '';
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async saveProcessDocument(processDocument: IProcessDocument, oldProcessDocument: IProcessDocument, type: SelectorModel): Promise<boolean> {
        let success: boolean = false;
        try {
            this.formService.block();

            const isInvalid = this.$scope.hasInvalidRequiredElements('processDocumentModalForm')
            if (isInvalid) return;
            this.setMaster(type, processDocument);

            const rc = await this.updateProcessDocument(processDocument, oldProcessDocument);
            if (rc.status === 200) {
                success = true;

                const result = await this.getProcessDocumentByProcessNumber(processDocument.PROCESS_NUMBER);
                if (result && result.data && result.data.data) {
                    this.$scope.processDocumentList = result.data.data;
                    const msgSuccess = "Conhecimento do Processo atualizado com sucesso.";
                    this.formService.notifySuccess(msgSuccess);
                    const response = (rc && rc.data && rc.data.data) ? rc.data.data : null;

                    if (response.error) this.handleError(response.error)
                    else if (response.message) this.formService.notifySuccess(response.message)
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return success
        }
    }

    private async closeModal(): Promise<void> {
        this.ModalService.closeModal(this.modalID);
        this.modalID = 0;
    }

    private async modalSaveConfirmation(headerText: string, closeButtonText: string): Promise<boolean> {
        return await this.ModalService.showModalConfirmation({}, {
            headerText: headerText,
            bodyText: this.formService.getTranslate('REGISTRATION.MESSAGES.ERROR.UPDATE_NOT_SAVED'),
            actionButtonText: "GENERAL.SAVE",
            closeButtonText: closeButtonText
        });
    }

    private hasInvalidRequiredElements(elementId: string): boolean {
        if (!elementId) return false;
        const isInvalid = FormService2.hasRequiredElements('#' + elementId);
        if (isInvalid) this.formService.notifyError(this.formService.getTranslate("GENERAL.ALL_FIELDS_MANDATORY"));
        return isInvalid;
    }

    private async viewLog(entity: IProcessDocument): Promise<void> {
        try {
            this.block();

            let log: IViewLog = {
                operation: 'GENERAL.GRID.LOG',
                number: entity.PROCESS_DOCUMENT_NUMBER,
                list: [],
                show: true,
                searchQuery: '',
                originalList: [],
                showCloseButton: false,
            }
            this.$scope.customLogProperties = this.getCustomLogProperties();

            const response = await this.getProcessDocumentLog(log.number);

            if (response && response.data && response.data.data) {

                log.list = response.data.data;
                log.originalList = angular.copy(log.list);

                this.$scope.log = log;

                angular.element('#log-viewer').removeClass('ng-hide');
                this.$scope.$applyAsync();
            }

            this.unblock();
            const modalId = this.ModalService.newModal();
            await this.ModalService.showModalConfirmation(
                {
                    modalID: modalId,
                    scope: this.$scope,
                    template: require('../view/processLogModal.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG"
                }
            );

            this.ModalService.closeModal(modalId);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async addProcessKnowledge(): Promise<void> {
        try {
            this.formService.block();
            this.modalID = this.ModalService.newModal();
            await this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    template: require("../view/modal/processKnowledgeModal.html"),
                    formService: this.$scope.operation,
                    size: 'lg modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: this.$scope.formOperation
                }
            );
            await this.buildProcessKnowledgeModalScope(this.$newProcessScope.model);

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async buildProcessKnowledgeModalScope(model: IProcess): Promise<IProcessKnowledgeModal> {
        try {
            const modalScope: IProcessKnowledgeModal = await this.ModalService.getModalScope(this.modalID);
            const processNumber = model.PROCESS_NUMBER;

            modalScope.processDocumentType = <IProcessDocumentType>{ type: null };
            modalScope.processDocumentTypeList = () => this.processDocumentTypeList();
            modalScope.oldModel = null;
            modalScope.process = await this.getProcessData(processNumber);

            modalScope.model = <IProcessDocument>{
                REFERENCE_ID: null,
                PROCESS_DOCUMENT_NUMBER: null,
                PROCESS_NUMBER: model.PROCESS_NUMBER,
                DOCUMENT: null,
                TYPE: null,
                COURIER_CIA: null,
                CE_MERCANTE: null,
                PROTOCOL_RETIFICATION: null,
                OBSERVATION: null,
                ORIGINAL_COPY: null,
                ORIGINALS_RECEIPT_RETAINED: null,
                RELEASED_TO: null,
                MASTER: null,
                EMISSION_TYPE: null,
                RELEASE_TYPE: null,
                PEOPLE_RELEASE: null,
                DRAFT_RECEIVED: null,
                CLIENT_APPROVAL: null,
                ALLOG_APPROVAL: null,
                ORIGINAL_COPY_RECEIVED: null,
                ISSUEANCE: null,
                LOCAL_ISSUEANCE: null,
                AVAILABILITY: null,
                COURIER_RECEIVED_BRAZIL: null,
                COURIER_SENT: null,
                COURIER_RECEIVED_ALLOG: null,
                COURIER_RECEIVED_FINOP: null,
                DECONSOLIDATION: null,
                LOI_RECEIVED: null,
                LOI_SENT: null,
                REQUEST_DATE: null,
                DONE: null,
                SENT_DATE: null,
                SISCARGA_MANTRA: null,
                RELEASE: null,
                DRAFT_NUMBER: null,
                AWB_NUMBER: null,
                INCONSISTENCY: null,
                INCONSISTENCY_CHARGE_DOCUMENT: null,
                DESTINATION_THC: null,
                PRODUCT: model.PRODUCT,
                CARGO_TYPE: model.CARGO_TYPE,
                BRANCH: null,
                CUSTOMER_QUALIFICATION: model.CUSTOMER_QUALIFICATION,
                PROCESS_TYPE: model.PROCESS_TYPE,
                FORWARDED_BY: null,
                SALES_PERSON: null,
                CUSTOMER: model.CUSTOMER,
                UNIQUE_TERM: null,
                UNIQUE_TERM_FILE: [],
                CORRECTION: null,
                SUSPENDED: null,
                DELIVERY_DATE: null,
                AVAILABILITY_IMP: null,
                AVAILABILITY_EXP: null,
                ACTIVE: true,
                SITUATION: model.SITUATION,
                CREATED_DATE: null,
                CREATED_BY: null,
                MODIFIED_DATE: null,
                MODIFIED_BY: null,
                AWB_PREFIX: null,
            };

            modalScope.applyProcessDocument = async () => {
                if (await this.newProcessDocument(modalScope.model, modalScope.oldModel, modalScope.processDocumentType.type, modalScope.process.PRODUCT.ID, modalScope.process.BRANCH.NAME)) {
                    await this.getDocumentByProcess();
                    this.closeModal();
                }
            };

            modalScope.closeProcessDocumentModal = async () => {
                if (this.$scope.hasChanges(JSON.stringify(modalScope.model), JSON.stringify(modalScope.oldModel))) {
                    const confirm = await this.$scope.modalSaveConfirmation("GENERAL.CLOSE", "GENERAL.CLOSE");
                    if (confirm) return;
                }
                this.closeModal();
            };

            await modalScope.$applyAsync();
            return modalScope;
        } catch (ex) {
            throw ex;
        }
    }

    private async getProcessData(processNumber: string): Promise<IProcess> {
        try {
            const result = await this.getProcessByNumber(processNumber);
            if (result && result.data && result.data.data) {
                const process = result.data.data[0];
                this.$scope.$applyAsync();
                return process
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async newProcessDocument(model: IProcessDocument, oldModel: IProcessDocument, type: SelectorModel, productId: string, branchName: string): Promise<boolean> {
        let success: boolean = false;
        try {
            this.formService.block();

            const isInvalid = this.$scope.hasInvalidRequiredElements('')
            if (isInvalid) return;
            this.setMaster(type, model);

            if (productId == EProductId.AIR_EXPORT) model.LOCAL_ISSUEANCE = await this.getLocalIssueanceToProductTypeEA(branchName);

            const rc = await this.insertProcessDocument(model, oldModel);
            if (rc.status === 200) {
                success = true;
                const msgSuccess = this.formService.getTranslate('OPERATIONAL.PROCESS_KNOWLEDGE_SUCCESS');
                this.formService.notifySuccess(msgSuccess);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return success
        }
    }

    private async setMaster(type: SelectorModel, processDocument: IProcessDocument) {
        try {
            processDocument.TYPE = type.NAME;
            return (true);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private goToDraftByProcessNumber(processNumber: string): void {
        this.sessionService.openTab('app.operational.draftRegister', <IDraftParameter>{ PROCESS_NUMBER: processNumber });
    }

    private async showReferencesModal(processDocument: IProcessDocument): Promise<void> {
        try {
            this.block();

            this.modalID = this.ModalService.newModal();
            const result = await this.getProcessByNumber(processDocument.PROCESS_NUMBER);
            if (!result || !result.data || (result.data && !result.data.data)) this.formService.handleError(this.formService.getTranslate("GENERAL.FAILED_TO_GET_FILE_INFORMATION"));
            this.$scope.process = result.data.data[0];
            this.$scope.oldProcess = JSON.parse(JSON.stringify(this.$scope.process));
            this.$scope.operation = EOperation.EDIT;

            this.ModalService.showModalInfo(
                {
                    modalID: this.modalID,
                    template: require('../view/NewProcessReferencesModal.html'),
                    formService: this.$scope.operation,
                    size: 'vlg',
                    keyboard: true,
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing") {
                            if (reason.toString() == "escape key press") event.preventDefault();
                        }
                    }
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: 'OPERATIONAL.REFERENCES_AND_ATTACHMENTS'
                },
                null
            );
            await this.buildReferenceModalScope(this.$scope.process.REFERENCE, this.$scope.operation, processDocument);
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async buildReferenceModalScope(references: REFERENCE[], operation: string, processDocument: IProcessDocument): Promise<IReferenceModalScope> {
        const modalScope: IReferenceModalScope = await this.ModalService.getModalScope(this.modalID);
        modalScope.operation = operation;
        modalScope.references = angular.copy(references);
        modalScope.oldReferences = angular.copy(references);
        modalScope.processNumber = this.$scope.process.PROCESS_NUMBER;
        this.$scope.model = angular.copy(processDocument);

        modalScope.viewFile = (file: REFERENCE): void => {
            if (file) window.open(file.LINK, "_blank");
        }

        modalScope.downloadFile = async (file: REFERENCE): Promise<void> => {
            if (file) {
                const linkBroken: string[] = file.LINK.split('/');
                const hash: string = linkBroken[linkBroken.length - 1];
                this.downloadFileFromGoogleStorage(hash);
            }
        }

        modalScope.copyFileLinkToClipboard = (file: REFERENCE) => {
            if (file) {
                const elmAux = document.createElement("textarea");
                document.body.appendChild(elmAux);
                elmAux.value = file.LINK;
                elmAux.select();
                document.execCommand("copy");
                document.body.removeChild(elmAux);
                this.formService.notifySuccess("Link do arquivo foi copiado para o clipboard.");
            }
        }

        modalScope.viewProcessReferenceModal = async (file: REFERENCE) => {
            const disabledFields: IFileReferenceDisabledFields = {
                documentType: true,
                documentValue: true,
                fileGroup: true,
                fileSpecs: true,
                name: true,
                templateType: true,
                documentSequence: true,
                configuration: true,
                productType: true,
                telephone: true,
                technicalManager: true,
                email: true,
            };
            const extensionRemoveList: string[] = file.NAME ? file.NAME.split('.') : null;
            const extension: string = (extensionRemoveList && extensionRemoveList.length > 0) ? extensionRemoveList[extensionRemoveList.length - 1] : null;
            const fileModel: IFiles = {
                FILES: file.LINK && file.LINK.length ? [
                    {
                        FILE_DISPLAY_NAME: file.NAME ? file.NAME : file.DOCUMENT_VALUE,
                        FILE_NAME: file.NAME,
                        FILE_TYPE: extension,
                        FILE_PATH: null,
                        FILE_URL: file.LINK,
                        FILE_HASH: null,
                        FORM_NAME: "processReference"
                    }
                ] : []
            };
            await this.fileReferenceModal(file, null, disabledFields, true, fileModel);
        }

        modalScope.editProcessReferenceModal = async (index: number) => {
            const file = modalScope.references[index];
            const extensionRemoveList: string[] = file.NAME ? file.NAME.split('.') : null;
            const extension: string = (extensionRemoveList && extensionRemoveList.length > 0) ? extensionRemoveList[extensionRemoveList.length - 1] : null;
            const fileModel: IFiles = {
                FILES: file.LINK && file.LINK.length ? [
                    {
                        FILE_DISPLAY_NAME: file.NAME ? file.NAME : file.DOCUMENT_VALUE,
                        FILE_NAME: file.NAME,
                        FILE_TYPE: extension,
                        FILE_PATH: null,
                        FILE_URL: file.LINK,
                        FILE_HASH: null,
                        FORM_NAME: "processReference"
                    }
                ] : []
            };
            const modalResponse = await this.fileReferenceModal(angular.copy(file), null, null, true, fileModel, true);
            if (modalResponse) {
                modalScope.references.splice(index, 1, modalResponse[0]);
                modalScope.oldReferences = angular.copy(modalScope.references);
                const msg = this.formService.getTranslate('BASIC_DATA.REFERENCE_SUCESSFULLY_UPDATED');
                this.formService.notifySuccess(msg);
                modalScope.$applyAsync();
            }
        }

        modalScope.fileReferenceModal = async () => {
            const modalResponse = await this.fileReferenceModal(null);
            //update process view table
            if (modalResponse) {
                const newReferences = modalResponse;
                if (!modalScope.references) modalScope.references = [];
                for (const newReference of newReferences) {
                    modalScope.references.splice(0, 0, newReference);
                    const msg = this.formService.getTranslate('BASIC_DATA.REFERENCE_SUCESSFULLY_ADDED');
                    this.formService.notifySuccess(msg);
                }
            }
        }

        modalScope.applyReferenceModal = async (close?: boolean) => {
            this.applyReference(modalScope.references, processDocument, close);
        };

        modalScope.closeReferenceModal = async (): Promise<void> => {
            modalScope.oldReferences = this.$scope.oldProcess.REFERENCE
            if (this.$scope.hasChanges(modalScope.references, modalScope.oldReferences)) {
                const close = this.formService.getTranslate('GENERAL.CLOSE');
                const confirm = await this.$scope.modalSaveConfirmation(close, close);
                if (confirm && !this.applyReference(modalScope.references)) return;
            }
            this.ModalService.closeModal(this.modalID);
            this.modalID = 0;
            $('.app-content-body').animate({ scrollTop: this.$scope.lastScroll }, 0);
        }

        modalScope.removeReference = async (index: number) => {
            if (!index && index != 0) throw Error('index is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (modalScope.references && modalScope.references.length > 0) modalScope.references.splice(index, 1);
        }

        modalScope.mainFileChange = (index: number) => {
            if (!index && index != 0) throw Error('index is null');

            const changedReference = modalScope.references && modalScope.references.length ? modalScope.references[index] : null;
            const mainFileReferences = modalScope.references && modalScope.references.length ? modalScope.references.filter(reference => reference.MAIN_FILE && reference.REFERENCE_ID != changedReference.REFERENCE_ID) : [];
            if (changedReference && changedReference.MAIN_FILE && mainFileReferences.length) {
                mainFileReferences.forEach(reference => {
                    reference.MAIN_FILE = false;
                });
            }
        }
        await modalScope.$applyAsync();

        return modalScope;
    }

    public async downloadFileFromGoogleStorage(hash: string): Promise<void> {
        try {
            this.formService.block();
            if (!hash) return null;

            const operation = await this.RestService.getObjectAsPromise(`${this.downloadRoute}/${hash}`, 30000, null, false);
            const response: angular.IHttpResponse<any> = operation;
            if (response && response.data) {
                const resultFile = <IDownloadParamsReturn>response.data;

                const file = resultFile.file;
                if (!resultFile.buffer) {
                    throw Error('Failed to get file information');
                }
                const buffer = resultFile.buffer.data;
                if (!file || !buffer) {
                    throw Error('Failed to get file information');
                }
                const fileName = file.fileName;
                const fileBuffer = new Uint8Array(buffer);
                const fileType = this.getContentType(file.fileType);
                const fileBlob = new Blob([fileBuffer], { type: fileType });
                const fileURL = window.URL.createObjectURL(fileBlob);

                let link = document.createElement('a');
                link.href = fileURL;
                link.download = fileName;
                link.click();
            } else {
                throw Error('Error on download file')
            }

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async fileReferenceModal(file: REFERENCE, preSetFields?: IFileReferencePreSetFields, disabledFields?: IFileReferenceDisabledFields, uploadDisabled?: boolean, fileModel?: IFiles, isEdit?: boolean) {
        try {
            this.fileReferenceModalID = this.ModalService.newModal();
            if (!this.$scope.model.DOCUMENT) return this.formService.handleError('É necessário inserir um valor de Waybill antes de gerar o documento.');

            const modalResponse = await this.ModalService.showModalFileReference(
                {
                    modalID: this.fileReferenceModalID,

                    categoryName: [
                        EReportCategoryName.Process,
                        EReportCategoryName.ISFCAN,
                        EReportCategoryName.ISFUSA,
                        EReportCategoryName.PreAdvise,
                        EReportCategoryName.AMS,
                        EReportCategoryName.BillOfLading,
                        EReportCategoryName.BillOfLadingForPrint,
                        EReportCategoryName.BillOfLadingCover,
                        EReportCategoryName.Memorandum,
                        EReportCategoryName.AirWaybill,
                        EReportCategoryName.AirLabel,
                        EReportCategoryName.Air_Manifest_EN
                    ],
                    blNumber: [{ number: this.$scope.model.DOCUMENT, PROCESS_NUMBER: this.$scope.process.PROCESS_NUMBER }],
                    process: [this.$scope.process],
                    processDocument: this.$scope.model,
                    fileOnly: true,
                    isEdit: isEdit,
                    preSetFields: preSetFields,
                    disabledFields: disabledFields,
                    uploadDisabled: uploadDisabled,
                    file: file,
                    fileModel: fileModel,
                }
            );
            return modalResponse;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async applyReference(references: REFERENCE[], processDocument?: IProcessDocument, close?: boolean): Promise<boolean> {
        let success = false;
        try {
            this.formService.block();
            console.log(processDocument)
            const updateReferenceResponse = await this.OperationalService.post('/process/updateReference', { processNumber: this.$scope.process.PROCESS_NUMBER, data: { REFERENCE: references } }, 30000);
            if (updateReferenceResponse && updateReferenceResponse.status == 200) {
                success = true;

                const newReferenceId = references.find(reference => !this.$scope.oldProcess.REFERENCE.find(oldReference => oldReference.REFERENCE_ID == reference.REFERENCE_ID));
                // if newReferenceId is true, then update the processDocument
                if (newReferenceId) {
                    const oldProcessDocument = angular.copy(processDocument);
                    processDocument.REFERENCE_ID = newReferenceId.REFERENCE_ID;
                    await this.updateProcessDocument(processDocument, oldProcessDocument);
                }

                // if reference_id is not found, then remove reference_id
                if (!references.find(reference => reference.REFERENCE_ID == processDocument.REFERENCE_ID)) {
                    const oldProcessDocument = angular.copy(processDocument);
                    processDocument.REFERENCE_ID = null;
                    await this.updateProcessDocument(processDocument, oldProcessDocument);
                }

                this.$scope.process.REFERENCE = references;
                this.$scope.oldProcess.REFERENCE = angular.copy(references);
            }
            if (close) {
                this.ModalService.closeModal(this.fileReferenceModalID);
                this.fileReferenceModalID = 0;
                $('.app-content-body').animate({ scrollTop: this.$scope.lastScroll }, 0);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            if (success) {
                const msg = this.formService.getTranslate('BASIC_DATA.REFERENCE_SUCESSFULLY_UPDATED');
                this.formService.notifySuccess(msg);
            }
            return success;
        }
    }

    private getContentType(fileType: string): string {
        switch (fileType.toLowerCase()) {
            case 'arc':
                return 'application/octet-stream';
            case 'bin':
                return 'application/octet-stream';
            case 'avi':
                return 'video/x-msvideo';
            case 'azw':
                return 'application/vnd.amazon.ebook';
            case 'gif':
                return 'image/gif';
            case 'htm':
                return 'text/html';
            case 'html':
                return 'text/html';
            case 'ico':
                return 'image/x-icon';
            case 'ics':
                return 'text/calendar';
            case 'js':
                return 'application/javascript';
            case 'json':
                return 'application/json';
            case 'mpeg':
                return 'video/mpeg';
            case 'jpeg':
                return 'image/jpeg';
            case 'jpg':
                return 'image/jpg';
            case 'png':
                return 'image/png';
            case 'pdf':
                return 'application/pdf';
            case 'rar':
                return 'application/x-rar-compressed';
            case 'zip':
                return 'application/zip';
            case '7z':
                return 'application/x-7z-compressed';
            case 'ppt':
                return 'application/vnd.ms-powerpoint';
            case 'csv':
                return 'text/csv';
            case 'doc':
                return 'application/msword';
            case 'xls':
                return 'application/vnd.ms-excel';
            case 'xlsx':
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            case 'vsd':
                return 'application/vnd.visio';
            case 'ts':
                return 'application/typescript';
            case 'rtf':
                return 'application/rtf';
            default:
                return 'text/plain';
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: 'PROCESS_DOCUMENT_NUMBER',
                LABEL: 'GENERAL.CODE'
            },
            {
                PROPERTY: 'PROCESS_NUMBER',
                LABEL: 'OPERATIONAL.FILE_NUMBER'
            },
            {
                PROPERTY: 'TYPE',
                LABEL: 'GENERAL.TYPE'
            },
            {
                PROPERTY: 'PRODUCT',
                LABEL: 'BASIC_DATA.PRODUCT'
            },
            {
                PROPERTY: 'MASTER',
                LABEL: 'FINANCIAL.MASTER'
            },
            {
                PROPERTY: 'DOCUMENT',
                LABEL: 'GENERAL.MENU.PROCESS_KNOWLEDGE'
            },
            {
                PROPERTY: 'DRAFT_NUMBER',
                LABEL: 'OPERATIONAL.PROTOCOL'
            },
            {
                PROPERTY: 'CUSTOMER_QUALIFICATION',
                LABEL: 'GENERAL.CLIENT_QUALIFICATION'
            },
            {
                PROPERTY: 'CUSTOMER',
                LABEL: 'BASIC_DATA.CLIENT'
            },
            {
                PROPERTY: 'DRAFT_RECEIVED',
                LABEL: 'OPERATIONAL.DRAFT_RECEIPT_DATE'
            },
            {
                PROPERTY: 'CLIENT_APPROVAL',
                LABEL: 'OPERATIONAL.CLIENT_APPROVAL'
            },
            {
                PROPERTY: 'ALLOG_APPROVAL',
                LABEL: 'OPERATIONAL.ALLOG_APPROVAL'
            },
            {
                PROPERTY: 'ISSUEANCE',
                LABEL: 'OPERATIONAL.ISSUANCE_DATE'
            },
            {
                PROPERTY: 'LOCAL_ISSUEANCE',
                LABEL: 'OPERATIONAL.ISSUANCE_PLACE'
            },
            {
                PROPERTY: 'AVAILABILITY',
                LABEL: 'OPERATIONAL.AVAILABILITY'
            },
            {
                PROPERTY: 'EMISSION_TYPE',
                LABEL: 'OPERATIONAL.ISSUANCE_TYPE'
            },
            {
                PROPERTY: 'RELEASE_TYPE',
                LABEL: 'OPERATIONAL.RELEASE_TYPE"'
            },
            {
                PROPERTY: 'SENT_DATE',
                LABEL: 'OPERATIONAL.SEND_RELEASE_DATE'
            },
            {
                PROPERTY: 'COURIER_CIA',
                LABEL: 'OPERATIONAL.COURIER_CIA'
            },
            {
                PROPERTY: 'ORIGINAL_COPY_RECEIVED',
                LABEL: 'OPERATIONAL.ORIGINALS_RECEIPT_DATE_BY_EMAIL'
            },
            {
                PROPERTY: 'ORIGINAL_COPY',
                LABEL: 'OPERATIONAL.ORIGINALS_COPIES'
            },
            {
                PROPERTY: 'ORIGINALS_RECEIPT_RETAINED',
                LABEL: 'OPERATIONAL.ORIGINALS_RECEIPT_RETAINED'
            },
            {
                PROPERTY: 'PEOPLE_RELEASE',
                LABEL: 'OPERATIONAL.SEND_RELEASE_PERSON'
            },
            {
                PROPERTY: 'COURIER_SENT',
                LABEL: 'OPERATIONAL.COURIER_SEND_DATE'
            },
            {
                PROPERTY: 'COURIER_RECEIVED_BRAZIL',
                LABEL: 'OPERATIONAL.COURIER_RECEIVED_IN_BRAZIL'
            },
            {
                PROPERTY: 'COURIER_RECEIVED_ALLOG',
                LABEL: 'OPERATIONAL.COURIER_RECEIVED_AT_DESTINATION'
            },
            {
                PROPERTY: 'COURIER_RECEIVED_FINOP',
                LABEL: 'OPERATIONAL.DOCUMENT_RECEIVED_BY_FINOP'
            },
            {
                PROPERTY: 'CE_MERCANTE',
                LABEL: 'OPERATIONAL.CE_MERCANTE'
            },
            {
                PROPERTY: 'DECONSOLIDATION',
                LABEL: 'OPERATIONAL.DESCONSOLIDATION_DATE'
            },
            {
                PROPERTY: 'LOI_RECEIVED',
                LABEL: 'OPERATIONAL.LOI_RECEIVE_DATE'
            },
            {
                PROPERTY: 'LOI_SENT',
                LABEL: 'OPERATIONAL.LOI_SEND_DATE'
            },
            {
                PROPERTY: 'SITUATION',
                LABEL: 'GENERAL.SITUATION'
            },
            {
                PROPERTY: 'UNIQUE_TERM',
                LABEL: 'OPERATIONAL.UNIQUE_TERM'
            },
            {
                PROPERTY: 'UNIQUE_TERM_FILE',
                LABEL: 'OPERATIONAL.UNIQUE_TERM_FILE'
            },
            {
                PROPERTY: 'RELEASE',
                LABEL: 'OPERATIONAL.RELEASE_DATE'
            },
            {
                PROPERTY: 'RELEASED_TO',
                LABEL: 'OPERATIONAL.RELEASED_TO'
            },
            {
                PROPERTY: 'SISCARGA_MANTRA',
                LABEL: 'OPERATIONAL.SISCOMEX_CARGO_RELEASED_AIMED_MANTRA'
            },
            {
                PROPERTY: 'OBSERVATION',
                LABEL: 'GENERAL.REMARKS'
            },
            {
                PROPERTY: 'DESTINATION_THC',
                LABEL: 'OPERATIONAL.DECLARE_DESTINATION_THC_ON_HBL'
            },
            {
                PROPERTY: 'CARGO_TYPE',
                LABEL: 'BASIC_DATA.CARGO_TYPE'
            },
            {
                PROPERTY: 'BRANCH',
                LABEL: 'BASIC_DATA.BRANCH'
            },
            {
                PROPERTY: 'PROCESS_TYPE',
                LABEL: 'OPERATIONAL.FILE_TYPE'
            },
            {
                PROPERTY: 'FORWARDED_BY',
                LABEL: 'GENERAL.FORWARDED_BY'
            },
            {
                PROPERTY: 'SALES_PERSON',
                LABEL: 'BASIC_DATA.SALES_EXECUTIVE'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'CREATED_DATE',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'MODIFIED_BY',
                LABEL: 'GENERAL.MODIFIED_BY'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            },
            {
                PROPERTY: 'MODIFIED_DATE',
                LABEL: 'GENERAL.MODIFIED_DATE'
            },
            {
                PROPERTY: 'UPDATED_DATE',
                LABEL: 'GENERAL.MODIFIED_DATE'
            },
            {
                PROPERTY: 'INCONSISTENCY',
                LABEL: 'GENERAL.INCONSISTENCY'
            },
            {
                PROPERTY: 'AVAILABILITY_EXP',
                LABEL: 'OPERATIONAL.AVAILABILITY_SHIPPER'
            },
            {
                PROPERTY: 'AVAILABILITY_IMP',
                LABEL: 'OPERATIONAL.AVAILABILITY_CONSIGNEE'
            },
            {
                PROPERTY: 'DELIVERY_DATE',
                LABEL: 'OPERATIONAL.DELIVERY_DATE'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
            {
                PROPERTY: 'ID',
                LABEL: 'REGISTRATION.IDENTIFICATION'
            },
            {
                PROPERTY: 'CODE',
                LABEL: 'GENERAL.CODE'
            },
            {
                PROPERTY: 'ORDER',
                LABEL: 'GENERAL.ORDER'
            },
        ];
        return props;
    }
}
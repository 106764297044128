import angular = require('angular');
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { fileUploader as FileUploader } from 'angular-file-upload';
import { IRestService } from "@services/RestService";
import { FormService2, IFormServiceScope } from '@services/FormService2';
import { IModalService, IFileReferencePreSetFields, IFileReferenceDisabledFields } from '@services/ModalService';
import { OperationalService } from "@services/OperationalService";
import { ISessionService } from '@services/SessionService';
import { ProductService } from '@services/ProductService';
import { IDownloadParamsReturn } from '@services/DownloadFilesGenericService';
import { FileReferenceModel } from '@models/interface/operational/FileReferenceModel';
import { ISelectorModel, SelectorModel } from '@models/mongo/SelectorModel';
import { CONTACT, ENTITY, REFERENCE, INTERNAL, EXPORTER, IMPORTER, REPRESENTATIVES, COMMODITY_EXCEPTIONS, COMMODITY_ITEM, COMMODITY_MODEL, EVENT, COMPANY, EXTERNAL, AGENCY, ENTITY_AGENT, REPRESENTATIVE_DEFAULT } from '@models/interface/operational/NewProcess';
import { INewProcessMainModel } from '@models/interface/operational/NewProcessTabsModel';
import { LOCAL, EVENT_TYPE } from '@models/interface/operational/NewProcessEvent';
import { IUploader } from '@models/interface/common/IMonacoUpload';
import { IBookingIntegration } from "@models/interface/operational/Booking";
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { EDataOriginTypeId, EFileSpecsId, EDocumentTypeId, EFileGroupId, EReportCategoryName, EOperation, EProcessSituation } from '@enums/GenericData';
import { IAgencyListCustomFilter, IBrokerListCustomFilter } from '../../registration/model/BrokerModel';
import { IOfferParameter, ILinkParameter } from '../../common/model/ModelParameter';
import { INewProcessScope, ECollapseState } from './NewProcessController';
import { ILegalPersonListCustomFilter } from '../../registration/model/LegalPersonModel';
import { IFiles } from '../../app/controllers/FileReferenceModalController';
import { IEntity, IEntityAgent } from 'WBA-Model/dist/interface/common/Entity';
import { IAccountExchangeData } from 'src/ts/commercial/model/AccountModel';
import { PermissionService } from '../../app/services/PermissionService';
import { IMonacoRequest } from "@services/GridFormService";
import { HelperService } from '@services/HelperService';
import { EPhysicalPersonSpecializationId } from '@enums/PhysicalPerson';
import { DataProcessService } from '@services/DataProcessService';
import { EProductId } from '@enums/GenericData';
import { ChargePair } from 'WBA-Model/dist/product/OfferCompiledCharge';

const enum EInvolvedField {
    IMPORTER = 1,
    EXPORTER = 2,
    NOTIFY = 3,
    BROKER = 4,
    AGENCY = 5,
    EXTERNAL_BROKER = 7,
    FLEXI_FITTING = 8,
    ISOTANK_PROVIDER = 9,
}

interface IContactInternalFull {
    ID: string;
    NAME: string;
    EMAIL: string;
    ROLE: string;
    FULL: INTERNAL;
}

interface ICommodityModalScope extends IFormServiceScope {
    operation: string;
    processNumber: string;
    sectionList: ISelectorModel[];
    commodityList: ISelectorModel[];
    commodities: COMMODITY_ITEM[];
    oldCommodities: COMMODITY_ITEM[];
    exceptions: COMMODITY_EXCEPTIONS[];
    oldExceptions: COMMODITY_EXCEPTIONS[];
    addCommodity: () => void;
    removeCommodity: (index: number) => void;
    applyCommodity: (close?: boolean) => void;
    closeCommodityModal: () => Promise<void>;
    getCommodityListByName: (search: string) => Promise<void>;
    commodityChange: (index: number) => void;
    isCommodityDisabled: (commodity: COMMODITY_ITEM, fieldName: string) => boolean;
}

interface IReferenceModalScope extends ng.IScope {
    references: REFERENCE[];
    processNumber: string;
    oldReferences: REFERENCE[];
    operation: string;
    closeReferenceModal: () => Promise<void>;
    applyReferenceModal: (close?: boolean) => void;
    viewFile: (file: REFERENCE) => void;
    downloadFile: (file: REFERENCE) => Promise<void>;
    copyFileLinkToClipboard: (file: REFERENCE) => void;
    viewProcessReferenceModal: (file: REFERENCE) => Promise<void>;
    editProcessReferenceModal: (index: number) => Promise<void>;
    fileReferenceModal: () => void;
    removeReference: (index: number) => void;
    mainFileChange: (index: number) => void;
}

interface IRepresentativeModel {
    EVENT_TYPE: ISelectorModel;
    LOCAL: LOCAL;
    REPRESENTATIVES: REPRESENTATIVES;
}

interface IRepresentativeModalScope extends IFormServiceScope {
    operation: string;
    events: EVENT[];
    processNumber: string;
    eventTypeList: EVENT_TYPE[];
    representativeList: ISelectorModel;
    representatives: REPRESENTATIVES;
    oldRepresentatives: REPRESENTATIVES;
    applyRepresentative: (close?: boolean) => void;
    closeRepresentativeModal: () => Promise<void>;
    representativeEventChange: (index: number) => void;
    getRepresentativeListByName: (search: string) => Promise<void>;
    representativeEventFilter: () => Function;
}

interface IInternalContactModalScope extends IFormServiceScope {
    operation: string;
    contacts: INTERNAL[];
    oldContacts: INTERNAL[];
    newContacts: INewContactFull;
    newContactsOption: IContactInternalFull[];
    newContactList: INTERNAL[];
    addInternalContact: () => void;
    addNewContacts: () => void;
    removeInternalContact: (index: number) => void;
    applyInternalContact: (close?: boolean) => void;
    closeInternalContactModal: () => Promise<void>;
}

interface IExternalContactModalScope extends IFormServiceScope {
    operation: string;
    companies: IExternalContactCompany[];
    filterName: EXTERNAL[];
    filterMail: EXTERNAL[];
    filterGroup: Array<ISelectorModel[]>;
    filteredCompanies: IExternalContactCompany[];
    oldCompanies: IExternalContactCompany[];
    newExternalContactModal: INewExternalContactModal;
    filterCompanies: (companyIndex: number) => void;
    addExternalContact: (companyIndex: number) => Promise<void>;
    removeExternalContact: (companyIndex: number, index: number) => void;
    updateContactGroup: (companyIndex: number, index: number) => void;
    applyExternalContact: (close?: boolean) => void;
    closeExternalContactModal: () => Promise<void>;
    contactExternalCompanyDuplicityFilter: (companyId: string) => Function;
}

interface INewContactFull {
    CONTACTS: IContactInternalFull[];
}

interface IExternalContactCompany extends COMPANY {
    CONTACTS: EXTERNAL[];
}

interface INewExternalContactModal {
    CONTACT: EXTERNAL;
    newContactExternalList: EXTERNAL[];
}

interface INewProcessMainAuxModel {
    mainReference: FileReferenceModel
}

interface INewProcessMainScope extends ng.IScope {
    model: INewProcessMainModel;
    oldModel: INewProcessMainModel;
    auxModel: INewProcessMainAuxModel;
    contactModel: CONTACT;
    exporterLegalPersonList: EXPORTER[];
    importerLegalPersonList: IMPORTER[];
    exporterList: EXPORTER[];
    importerList: IMPORTER[];
    representativesList: REPRESENTATIVES;
    notifyList: ISelectorModel[];
    brokerList: ISelectorModel[];
    externalBrokerList: ISelectorModel[];
    agencyList: AGENCY[];
    groupList: ISelectorModel[];
    documentTypeList: ISelectorModel[];
    fileGroupList: ISelectorModel[];
    fileSpecList: ISelectorModel[];
    bookingList: ISelectorModel[];
    providerList: IEntity[];
    legalPersonList: IEntity[];
    bookingStatusList: ISelectorModel[];
    currenciesList: ISelectorModel[];
    uploader: IUploader;
    processNumber: string;
    dateOptionsValidityOf: { minDate: Date, maxDate: Date }
    dateOptionsValidityUp: { minDate: Date, maxDate: Date }
    localAgentList: IEntityAgent[];
    externalAgentList: IEntityAgent[];
    otherAgentList: IEntityAgent[];
    pickUpList: IEntity[];
    otfsCarrierList: IEntity[];
    dtfsCarrierList: IEntity[];
    deliveryCarrierList: IEntity[];
    processTypeList: ISelectorModel[];
    cargoTypeList: ISelectorModel[];
    moveTypeList: ISelectorModel[];
    typePaymentList: ISelectorModel[];
    incotermList: ISelectorModel[];
    permissionRepurchase: boolean;
    isAir: boolean;
    collapseMain: () => void;
    isCollapseIn(): boolean;
    hasChanges: () => boolean;
    goToOffer: (offer: string) => void;
    goToNewFreightContract: (newFreightContractId: string) => void;
    goToAccountRequirement: (IdAccount: string, IdAccountRequirement: string) => void;
    checkShipmentRequiredDateValidity: (initialDate: Date, finalDate: Date) => void;
    openRepresentativesModal: () => void;
    openCommoditiesModal: () => void;
    openPeopleDetails: (entity: ENTITY, agentEntity?: ENTITY_AGENT) => void;
    exporterChange: (oldExporterId: string) => Promise<void>;
    importerChange: (oldImporterId: string) => Promise<void>;
    notifyChange: (oldNotifyId: string) => Promise<void>;
    brokerChange: (oldBrokerId: string) => Promise<void>;
    agencyChange: (oldAgencyId: string) => Promise<void>;
    externalBrokerChange: (oldExternalBrokerId: string) => Promise<void>;
    flexFittingChange: (oldFlexiFittingId: string) => Promise<void>;
    isoTankProviderChange: (oldIsoTankId: string) => Promise<void>;
    insertBookingIntegrated: () => void;
    insertBookingManual: (name: string) => Promise<void>;
    copyBookingToClipboard: () => void;
    copyAccountReferenceToClipboard: () => void;
    getBookingListByName: (search: string) => Promise<void>;
    getExporterListByName: (search: string) => Promise<void>;
    getImporterListByName: (search: string) => Promise<void>;
    getNotifyListByName: (search: string) => Promise<void>;
    getBrokerListByName: (search: string) => Promise<void>;
    getAgencyListByName: (search: string) => Promise<void>;
    getProviderOperationalListByName: (search: string) => Promise<void>;
    getLegalPersonOperationalListByName: (search: string) => Promise<void>;
    getPersonOperationalListByName: (search: string) => Promise<void>;
    getBrokerOperationalListByName: (search: string) => Promise<void>;
    openDifferencesModal: () => void;
    isAirProduct(): boolean;
    hasConsolidated(): boolean;

    // Reference
    referenceList: REFERENCE[];
    showReferencesModal: () => void;
    addAccountReference: () => void;
    getNameUser: (id: string) => void;
    changeReference: (selected: REFERENCE, oldSelected: string) => void;

    // Contacts    
    showInternalContacts: () => void;
    showExternalContacts: () => void;

    // Compare Process
    compareProcess: () => void;

    disablePanelDueToPreProcessStatus: () => boolean;
    getLocalAgentListByName: (search: string) => Promise<void>
    getExternalAgentListByName: (search: string) => Promise<void>
    getOtherAgentListByName: (search: string) => Promise<void>
    getPickUpListByName: (search: string) => Promise<void>;
    getOtfsCarrierListByName: (search: string) => Promise<void>;
    getDtfsCarrierListByName: (search: string) => Promise<void>;
    getDeliveryCarrierListByName: (search: string) => Promise<void>;

    repurchaseProcess: () => void;
    offerActive: () => boolean;
    repurchaseActive: () => boolean;
}

export class NewProcessMainController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: INewProcessMainScope;
    private $newProcessScope: INewProcessScope;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private $sce: angular.ISCEService;
    private $compile: angular.ICompileService;
    private $filter: ng.FilterFactory;
    private restService: IRestService;
    private formService: FormService2;
    private modalService: IModalService;
    private sessionService: ISessionService;
    private operationalService: OperationalService;
    private productService: ProductService;
    private permissionService: PermissionService;
    private dataProcessService: DataProcessService;
    private fileReferenceModalID: number;
    private referenceModalID: number;
    private newContactModalID: number;
    private internalContactsModalID: number;
    private representativeModalId: number;
    private commoditiesModalId: number;
    private externalContactsModalID: number;
    private modalNewExternalContactId: number;
    private modalBookingDiffId: number;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: INewProcessMainScope) {
        this.$scope = $scope;
        this.$scope.auxModel = { mainReference: null };
        this.$newProcessScope = <INewProcessScope>$scope.$parent.$parent;
        this.$q = this.$newProcessScope.$q;
        this.$timeout = this.$newProcessScope.$timeout;
        this.$q = $injector.get('$q');
        this.$sce = $injector.get('$sce');
        this.$compile = $injector.get('$compile');
        this.helperService = $injector.get('HelperService');
        this.$filter = this.$newProcessScope.$filter;
        this.restService = this.$newProcessScope.restService;
        this.formService = this.$newProcessScope.formService;
        this.modalService = this.$newProcessScope.modalService;
        this.sessionService = this.$newProcessScope.sessionService;
        this.operationalService = this.$newProcessScope.operationalService;
        this.productService = this.$newProcessScope.productService;
        this.dataProcessService = $injector.get('DataProcessService');
        this.permissionService = new PermissionService($scope, $injector);
        this.initScopeFunctions();
    }

    async $onInit(): Promise<void> {
        try {
            this.initModel();
            await this.initDependencies();
            this.$scope.permissionRepurchase = await this.permissionService.isRoleAllowed("BUYBACK");
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initModel(): void {
        this.$scope.model = {
            // Basic Data
            PROCESS_NUMBER: null,
            CREATED_DATE: null,
            PROCESS_TYPE: null,
            CARGO_TYPE: null,
            INCOTERM: null,
            SERVICE_TYPE: null,
            SHIPMENT_REQUIRED: {
                DATE_OF: null,
                DATE_UP: null
            },
            COMMODITY_SUMMARY: null,
            MASTER_PAYMENT: null,
            HOUSE_PAYMENT: null,
            MASTER_DIRECT: null,
            REFERENCE: null,
            PRODUCT: null,
            COMMODITY_INVOICE_VALUE: null,
            // Involved
            EXPORTER: null,
            IMPORTER: null,
            NOTIFY: null,
            BROKER: null,
            SERVICE_PROVIDER: null,
            AGENCY: null,
            LOCAL_AGENT: null,
            EXTERNAL_AGENT: null,
            THIRD_AGENT: null,
            TRANSPORTER_PICKUP: null,
            TRANSPORTER_PLACE_RECEPT: null,
            TRANSPORTER_FINAL_DESTINATION: null,
            TRANSPORTER_DELIVERY: null,
            TRANSPORTER_GATE_OUT_EMPTY: null,
            TRANSPORTER_CONTAINER_STUFFING: null,
            FLEXI_FITTING: null,
            ISOTANK_PROVIDER: null,
            EXTERNAL_BROKER: null,
            REPRESENTATIVES: null,
            CUSTOMER: null,
            CONTACT: {
                EXTERNAL: null,
                INTERNAL: null
            },
            // Booking
            BOOKING: null,
            BOOKING_INTEGRATION: null,
            IS_BOOKING_INTEGRATION: false,
            CONTRACT_TYPE: null,
            BOOKING_STATUS: null,
            BOOKING_STATUS_DATE: null,
            BOOKING_OBS: null,
            // Offer data
            OFFER: null,
            REPURCHASE: null,
            FREIGHT_CONTRACT: null,
            FREIGHT_CONTRACT_RECEIVING: null,
            CONTRACT_TYPE_RECEIVING: null,
            QUOTATION_TTIME_MIN: null,
            QUOTATION_TTIME_MAX: null,
            // Account data
            ACCOUNT_REQUIREMENT: null,
            FORWARDED_BY: null,
            COMMERCIAL_UNITY: null,
            BRANCH: null,
            SECTION: null,
            ACCOUNT_TYPE: null,
            INDICATION: null,
            SALES_PERSON: null,
            INSIDE_SALES_PERSON: null,
            RESPONSIBLE_PRODUCT: null,
            SPONSOR: null,
            CARGO_DETAIL: null,
            EXTENDED_VALIDITY_DATE: null,
            COMMODITY_CURRENCY: null,
            COMMODITY_VALUE: null,
            ALLOWS_REPURCHASE: null
        };
        this.$scope.oldModel = angular.copy(this.$scope.model);
    }

    private initScopeFunctions(): void {

        this.$scope.collapseMain = (): void => {
            this.collapseMain();
        }

        this.$scope.isCollapseIn = (): boolean => {
            return this.$newProcessScope.collapseState.panel == ECollapseState.MAIN && !this.$newProcessScope.collapseState.released;
        }

        this.$scope.hasChanges = (): boolean => {
            return this.$newProcessScope.hasChanges(this.$scope.model, this.$scope.oldModel);
        }

        this.$scope.goToOffer = async (offer: string) => {
            this.sessionService.openTab("app.commercial.offer", <IOfferParameter>{ "OFFER_CODE_EXHIBITION": offer });
        }

        this.$scope.goToNewFreightContract = async (newFreightContractId: string) => {
            this.sessionService.openTab("app.product.newFreightContract", <ILinkParameter>{ ID: newFreightContractId });
        }

        this.$scope.goToAccountRequirement = (IdAccount: string, IdAccountRequirement: string) => {
            this.sessionService.openTab("app.commercial.account", { ID: String(IdAccount) }, <IAccountExchangeData>{ OPERATION: EOperation.VIEW, ID: Number(IdAccount), ID_REQUIREMENT: Number(IdAccountRequirement) });
        }

        this.$scope.checkShipmentRequiredDateValidity = (initialDate: Date, finalDate: Date): void => {
            const isValid = this.$newProcessScope.checkDateValidity(initialDate, finalDate);
            if (!isValid) this.$scope.model.SHIPMENT_REQUIRED.DATE_UP = null;
        }

        this.$scope.openRepresentativesModal = (): void => {
            this.openRepresentativesModal();
        }

        this.$scope.openCommoditiesModal = (): void => {
            this.openCommoditiesModal();
        }

        this.$scope.openPeopleDetails = (entity: ENTITY, agentEntity?: ENTITY_AGENT) => {
            this.openPeopleDetails(entity, agentEntity);
        }

        this.$scope.exporterChange = async (oldExporterId: string) => {
            this.involvedChange(oldExporterId, EInvolvedField.EXPORTER);
        }

        this.$scope.importerChange = async (oldImporterId: string) => {
            this.involvedChange(oldImporterId, EInvolvedField.IMPORTER);
        }

        this.$scope.notifyChange = async (oldNotifyId: string) => {
            this.involvedChange(oldNotifyId, EInvolvedField.NOTIFY);
        }

        this.$scope.brokerChange = async (oldBrokerId: string) => {
            this.involvedChange(oldBrokerId, EInvolvedField.BROKER);
        }

        this.$scope.agencyChange = async (oldAgencyId: string) => {
            this.involvedChange(oldAgencyId, EInvolvedField.AGENCY);
        }

        this.$scope.externalBrokerChange = async (oldExternalBrokerId: string) => {
            this.involvedChange(oldExternalBrokerId, EInvolvedField.EXTERNAL_BROKER);
        }

        this.$scope.flexFittingChange = async (oldFlexiFittingId: string) => {
            this.involvedChange(oldFlexiFittingId, EInvolvedField.FLEXI_FITTING);
        }

        this.$scope.isoTankProviderChange = async (oldIsoTankId: string) => {
            this.involvedChange(oldIsoTankId, EInvolvedField.ISOTANK_PROVIDER);
        }

        this.$scope.insertBookingIntegrated = () => {
            if (!this.$scope.model.BOOKING_INTEGRATION) {
                this.$scope.model.IS_BOOKING_INTEGRATION = false;
                this.$scope.model.BOOKING = null;
            } else this.$scope.model.IS_BOOKING_INTEGRATION = true;
        }

        this.$scope.insertBookingManual = async (name: string) => {
            this.$scope.model.BOOKING = name;
            this.$scope.model.BOOKING_INTEGRATION = { ID: "0", NAME: name, INTTRA_REFERENCE: null, INTTRA_STATUS: null, AWAITING_INTTRA_CONFIRMATION: false, HAS_CRITICAL_DIFFS: false, HAS_DIFFS: false };
            this.$scope.model.IS_BOOKING_INTEGRATION = false;
            const msg = this.formService.getTranslate('OPERATIONAL.MANUAL_BOOKING_INSERTED');
            this.formService.notifySuccess(msg);
            angular.element("#booking").click();
        }

        this.$scope.copyBookingToClipboard = () => {
            const elmAux = document.createElement("textarea");
            document.body.appendChild(elmAux);
            elmAux.value = this.$scope.model.BOOKING;
            elmAux.select();
            document.execCommand("copy");
            document.body.removeChild(elmAux);
            this.formService.notifySuccess("Booking copiado para o clipboard.");
        }

        this.$scope.copyAccountReferenceToClipboard = () => {
            const elmAux = document.createElement("textarea");
            document.body.appendChild(elmAux);
            elmAux.value = this.$scope.auxModel.mainReference.DOCUMENT_VALUE;
            elmAux.select();
            document.execCommand("copy");
            document.body.removeChild(elmAux);
            this.formService.notifySuccess("Referência do cliente copiada para o clipboard.");
        }

        this.$scope.getBookingListByName = async (search: string): Promise<void> => {
            let bookingList: ISelectorModel[] = [];
            if (search && search.length >= 3) bookingList = await this.getBookingListByName(search);
            this.$scope.bookingList = bookingList;
        }

        this.$scope.getExporterListByName = async (search: string): Promise<void> => {
            let exporterList: EXPORTER[] = [];
            if (search && search.length >= 3) exporterList = await this.getLegalPersonListByName({ search: search, specializations: [ELegalPersonSpecializationId.EXPORTER] });
            this.$scope.exporterLegalPersonList = exporterList;
        }

        this.$scope.getImporterListByName = async (search: string): Promise<void> => {
            let importerList: IMPORTER[] = [];
            if (search && search.length >= 3) importerList = await this.getLegalPersonListByName({ search: search, specializations: [ELegalPersonSpecializationId.IMPORTER] });
            this.$scope.importerLegalPersonList = importerList;
        }

        this.$scope.getNotifyListByName = async (search: string): Promise<void> => {
            let notifyList: ISelectorModel[] = [];
            if (search && search.length >= 3) notifyList = await this.getLegalPersonListByName({ search: search, specializations: [ELegalPersonSpecializationId.NOTIFY] });
            this.$scope.notifyList = notifyList;
        }

        this.$scope.getBrokerListByName = async (search: string) => {
            let brokerList: ISelectorModel[] = [];
            if (search && search.length >= 3) brokerList = await this.getBrokerListByName({ search: search, products: [this.$newProcessScope.model.PRODUCT.ID] });
            this.$scope.brokerList = brokerList;
        }

        this.$scope.getAgencyListByName = async (search: string) => {
            let agencyList: AGENCY[] = [];
            if (search && search.length >= 3) agencyList = await this.getAgencyListByName({ products: this.$newProcessScope.model.PRODUCT ? [this.$newProcessScope.model.PRODUCT.ID] : [], providers: this.$scope.model.SERVICE_PROVIDER ? [this.$scope.model.SERVICE_PROVIDER.ID] : [], search: search });
            this.$scope.agencyList = agencyList;
        }

        this.$scope.getProviderOperationalListByName = async (search: string): Promise<void> => {
            let providerList: IEntity[] = [];
            if (search && search.length >= 3) providerList = await this.getProviderOperationalListByName(search);
            this.$scope.providerList = providerList;
        }

        this.$scope.getLegalPersonOperationalListByName = async (search: string): Promise<void> => {
            let legalPersonList: IEntity[] = [];
            if (search && search.length >= 3) legalPersonList = await this.getLegalPersonOperationalListByName(search);
            this.$scope.legalPersonList = legalPersonList;
        }

        this.$scope.getPersonOperationalListByName = async (search: string): Promise<void> => {
            let peopleList: IEntity[] = [];
            if (search && search.length >= 3) {
                const legalPersonList = await this.getLegalPersonRepresentativeOperationalListByName(search)
                if (legalPersonList && legalPersonList.length) peopleList = peopleList.concat(legalPersonList);
                const physicalPersonList = await this.getPhysicalPersonRepresentativeOperationalListByName(search);
                if (physicalPersonList && physicalPersonList.length) peopleList = peopleList.concat(physicalPersonList);
            }
            this.$scope.legalPersonList = peopleList;
        }

        this.$scope.showReferencesModal = () => {
            this.showReferencesModal();
        }

        this.$scope.addAccountReference = () => {
            this.addAccountReference();
        }

        this.$scope.getNameUser = async (id: string): Promise<void> => {
            const user = await this.getUser(id);
        }

        this.$scope.changeReference = (selected: REFERENCE, oldSelected: string) => {
            this.changeReference(selected, oldSelected);
        }

        this.$scope.showInternalContacts = () => {
            this.showInternalContacts();
        }

        this.$scope.showExternalContacts = () => {
            this.showExternalContacts();
        }

        this.$scope.compareProcess = (): void => {
            this.compareProcess();
        }

        this.$scope.disablePanelDueToPreProcessStatus = (): boolean => {
            let result = false;
            switch (this.$newProcessScope.model.SITUATION.ID) {
                case EProcessSituation.PRE_PROCESS:
                    break;
                default:
                    result = true;
            }
            return result;
        }

        this.$scope.getBrokerOperationalListByName = async (search: string) => {
            let externalBrokerList: ISelectorModel[] = [];
            if (search && search.length >= 3) externalBrokerList = await this.getBrokerListByName({ search: search, products: [this.$newProcessScope.model.PRODUCT.ID] });
            this.$scope.externalBrokerList = externalBrokerList;
        }

        this.$scope.openDifferencesModal = () => {
            this.openDifferencesModal();
        }

        this.$scope.getLocalAgentListByName = async (search: string) => {
            let agentLocalList: IEntityAgent[] = [];
            if (search && search.length >= 3) {
                agentLocalList = await this.getLocalAgentListByName(search);
            }
            this.$scope.localAgentList = agentLocalList;
        }

        this.$scope.getExternalAgentListByName = async (search: string) => {
            let agentExternalList: IEntityAgent[] = [];
            if (search && search.length >= 3) {
                agentExternalList = await this.getExternalAgentListByName(search);
            }
            this.$scope.externalAgentList = agentExternalList;
        }

        this.$scope.getOtherAgentListByName = async (search: string) => {
            let agentOtherList: IEntityAgent[] = [];
            if (search && search.length >= 3) {
                agentOtherList = await this.getOtherAgentListByName(search);
            }
            this.$scope.otherAgentList = agentOtherList;
        }

        this.$scope.getPickUpListByName = async (search: string) => {
            let pickUpList: IEntity[] = [];
            if (search && search.length >= 3) {
                pickUpList = await this.getLegalPersonListByName({ search: search, specializations: [ELegalPersonSpecializationId.SHIPPING_COMPANY] });
            }
            this.$scope.pickUpList = pickUpList;
        }

        this.$scope.getOtfsCarrierListByName = async (search: string) => {
            let otfsCarrierList: IEntity[] = [];
            if (search && search.length >= 3) {
                otfsCarrierList = await this.getLegalPersonListByName({ search: search, specializations: [ELegalPersonSpecializationId.SHIPPING_COMPANY] });
            }
            this.$scope.otfsCarrierList = otfsCarrierList;
        }

        this.$scope.getDtfsCarrierListByName = async (search: string) => {
            let dtfsCarrier: IEntity[] = [];
            if (search && search.length >= 3) {
                dtfsCarrier = await this.getLegalPersonListByName({ search: search, specializations: [ELegalPersonSpecializationId.SHIPPING_COMPANY] });
            }
            this.$scope.dtfsCarrierList = dtfsCarrier;
        }

        this.$scope.getDeliveryCarrierListByName = async (search: string) => {
            let deliveryCarrier: IEntity[] = [];
            if (search && search.length >= 3) {
                deliveryCarrier = await this.getLegalPersonListByName({ search: search, specializations: [ELegalPersonSpecializationId.SHIPPING_COMPANY] });
            }
            this.$scope.deliveryCarrierList = deliveryCarrier;
        }

        this.$scope.repurchaseProcess = () => {
            this.repurchaseProcess();
        }

        this.$scope.offerActive = () => {
            return this.$scope.model.OFFER.some(offer => offer && offer.ACTIVE == true);
        }

        this.$scope.repurchaseActive = () => {
            return this.$scope.model.REPURCHASE && this.$scope.model.REPURCHASE.length && this.$scope.model.REPURCHASE.some(repurchase => repurchase && repurchase.ACTIVE == true);
        }

        this.$scope.isAirProduct = (): boolean => {
            this.$scope.isAir = this.$scope.model.PRODUCT && (this.$scope.model.PRODUCT.CODE == EProductId.AIR_IMPORT || this.$scope.model.PRODUCT.CODE == EProductId.AIR_EXPORT);
            return this.$scope.isAir;
        }

        this.$scope.hasConsolidated = (): boolean => {
            const consolidated = this.$scope.model && this.$scope.model.REFERENCE ? this.$scope.model.REFERENCE.filter(reference => reference.ID_CONSOLIDATED != null) : null;
            if (consolidated && consolidated.length > 0) return true;
            else return false;
        }
    }

    private async involvedChange(oldCompanyId: string, involvedField: EInvolvedField) {
        const companyIds: string[] = this.buildCompanyIds();
        const hasSameCompanyInAnotherObj = companyIds && companyIds.findIndex(companyId => companyId == oldCompanyId) >= 0;
        if (!this.$scope.contactModel.EXTERNAL) this.$scope.contactModel.EXTERNAL = [];
        if (oldCompanyId && !hasSameCompanyInAnotherObj) {
            if (this.$scope.contactModel && this.$scope.contactModel.EXTERNAL && this.$scope.contactModel.EXTERNAL.length) {
                this.$scope.contactModel.EXTERNAL = this.$scope.contactModel.EXTERNAL.filter(contact => !contact.COMPANY || (contact.COMPANY && contact.COMPANY.ID != oldCompanyId));
            }
        }

        let fieldModel = null;
        switch (involvedField) {
            case EInvolvedField.EXPORTER:
                fieldModel = this.$scope.model.EXPORTER;
                break;
            case EInvolvedField.IMPORTER:
                fieldModel = this.$scope.model.IMPORTER;
                break;
            case EInvolvedField.NOTIFY:
                fieldModel = this.$scope.model.NOTIFY;
                break;
            case EInvolvedField.BROKER:
                fieldModel = this.$scope.model.BROKER;
                break;
            case EInvolvedField.AGENCY:
                fieldModel = this.$scope.model.AGENCY;
                break;
            case EInvolvedField.EXTERNAL_BROKER:
                fieldModel = this.$scope.model.EXTERNAL_BROKER;
                break;
            case EInvolvedField.FLEXI_FITTING:
                fieldModel = this.$scope.model.FLEXI_FITTING;
                break;
            case EInvolvedField.ISOTANK_PROVIDER:
                fieldModel = this.$scope.model.ISOTANK_PROVIDER;
                break;
        }

        if (fieldModel) {
            const contacts: EXTERNAL[] = await this.getExternalContact(fieldModel);
            if (contacts && contacts.length) {
                for (const contact of contacts) {
                    const hasContactInList = this.$scope.contactModel.EXTERNAL && this.$scope.contactModel.EXTERNAL.length && this.$scope.contactModel.EXTERNAL.findIndex(contactExternal => contactExternal.ID == contact.ID) >= 0;
                    if (!hasContactInList) {
                        this.$scope.contactModel.EXTERNAL.push(contact);
                        this.$scope.model.CONTACT.EXTERNAL.push(contact);
                    }
                }
            }
        }
    }

    private async getContactListByName(companyId: string): Promise<EXTERNAL[]> {
        let result: EXTERNAL[] = [];
        try {
            this.formService.block();
            const contacts = await this.productService.post({
                route: "/contact/list/operational",
                data: {
                    filters: {
                        LEGAL_PERSON: parseInt(companyId),
                        PRODUCTS: [this.$newProcessScope.model.PRODUCT.ID]
                    }
                }
            });
            if (contacts && contacts.data && contacts.data.data) result = contacts.data.data.map(contact => { return { ID: contact.ID, NAME: contact.NAME, MAIL: contact.EMAIL, GROUP: contact.MESSAGE_GROUP, TYPE: contact.TYPE, COMPANY: contact.COMPANY, DATA_ORIGIN_TYPE: { ID: "M", NAME: "Manual" } } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getProviderOperationalListByName(search?: string): Promise<IEntity[]> {
        let result: IEntity[];
        try {
            this.formService.block();
            const providers = await this.productService.post({
                route: `/provider/list/custom/operational`,
                data: {
                    products: [this.$newProcessScope.model.PRODUCT.ID],
                    sysConvertIdToString: true,
                    search
                }
            });
            result = providers.data.data ? providers.data.data.map(x => {
                return {
                    ...x,
                    NAME: x.TRADING_NAME,
                    CODE: x.SCAC,
                    TYPE: x.TYPE
                }
            }) : null;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getLegalPersonOperationalListByName(search?: string, type?: string): Promise<IEntity[]> {
        let result: IEntity[];
        try {
            this.formService.block();
            const legalPerson = await this.productService.post({
                route: `/legalPerson/list/custom/operational`,
                data: {
                    products: [this.$newProcessScope.model.PRODUCT.ID],
                    sysConvertIdToString: true,
                    search,
                    types: type ? [type] : null,
                }
            });
            result = legalPerson.data.data ? legalPerson.data.data.map(x => {
                return {
                    ...x,
                    ID_LEGAL_PERSON: x.ID,
                    NAME: x.TRADING_NAME,
                    CODE: x.SCAC,
                    TYPE: x.TYPE
                }
            }) : null;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getLegalPersonRepresentativeOperationalListByName(search?: string): Promise<IEntity[]> {
        let result: IEntity[];
        try {
            this.formService.block();
            const legalPerson = await this.productService.post({
                route: `/legalPerson/list/custom/operational`,
                data: {
                    products: [this.$newProcessScope.model.PRODUCT.ID],
                    sysConvertIdToString: true,
                    search,
                    specializations: [ELegalPersonSpecializationId.REPRESENTATIVE],
                }
            });
            result = legalPerson.data.data ? legalPerson.data.data.map(x => {
                return {
                    ...x,
                    ID_LEGAL_PERSON: x.ID_LEGAL_PERSON ? x.ID_LEGAL_PERSON.toString() : null,
                    ID_PHYSICAL_PERSON: x.ID_PHYSICAL_PERSON ? x.ID_PHYSICAL_PERSON.toString() : null,
                    NAME: x.TRADING_NAME,
                    CODE: x.SCAC,
                    TYPE: x.TYPE
                }
            }) : null;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getPhysicalPersonRepresentativeOperationalListByName(search?: string): Promise<IEntity[]> {
        let result: IEntity[];
        try {
            this.formService.block();
            const legalPerson = await this.productService.post({
                route: `/physicalPerson/list/custom/operational`,
                data: {
                    products: [this.$newProcessScope.model.PRODUCT.ID],
                    sysConvertIdToString: true,
                    search,
                    specializations: [EPhysicalPersonSpecializationId.REPRESENTATIVE],
                }
            });
            result = legalPerson.data.data ? legalPerson.data.data.map(x => {
                return {
                    ...x,
                    NAME: x.NAME,
                    CODE: x.SCAC,
                    TYPE: x.TYPE
                }
            }) : null;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    async initDependencies(): Promise<any> {
        const self: NewProcessMainController = this;

        this.initCollapseEvents();

        this.$scope.$on('processMainSave', () => {
            this.saveProcessTabMain();
        });

        this.$scope.$watch(() => this.$newProcessScope.model.PROCESS_NUMBER, () => {
            this.$scope.processNumber = this.$newProcessScope.model.PROCESS_NUMBER;
        });

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericList('message_group'),
                self.getGenericList('document_type'),
                self.getGenericList('file_group'),
                self.getGenericList('file_specs'),
                self.getGenericList('booking_status'),
                self.getCurrenciesList(),
                self.getGenericList("process_type"),
                self.getGenericList("type_cargo"),
                self.getMoveTypeList(),
                self.getGenericList('type_payment'),
                self.getIncotermList()
            ]).then(async (result: any) => {
                self.$scope.groupList = result[0];
                self.$scope.documentTypeList = result[1];
                self.$scope.fileGroupList = result[2];
                self.$scope.fileSpecList = result[3];
                self.$scope.bookingStatusList = result[4];
                self.$scope.currenciesList = result[5];
                self.$scope.processTypeList = result[6];
                self.$scope.cargoTypeList = result[7];
                self.$scope.moveTypeList = result[8];
                self.$scope.typePaymentList = result[9];
                self.$scope.incotermList = result[10];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private updateDateOptionsValidity() {
        this.$scope.dateOptionsValidityOf = angular.copy(this.$newProcessScope.dateOptions);
        this.$scope.dateOptionsValidityUp = angular.copy(this.$newProcessScope.dateOptions);

        switch (this.$newProcessScope.model.SITUATION.ID) {
            case EProcessSituation.PRE_PROCESS:
                this.$scope.dateOptionsValidityOf.minDate = new Date();
                this.$scope.dateOptionsValidityUp.minDate = new Date();
                break;
            default:
                this.$scope.dateOptionsValidityOf.minDate = new Date(this.$newProcessScope.model.VALIDITY_OF);
                this.$scope.dateOptionsValidityOf.maxDate = new Date(this.$newProcessScope.model.VALIDITY_UP);
                this.$scope.dateOptionsValidityUp.maxDate = new Date(this.$newProcessScope.model.VALIDITY_UP);
        }
    }

    private initCollapseEvents() {
        this.$scope.$on('processMainCollapse', () => {
            this.collapseMain();
        });

        const collapseMain = angular.element('#collapseMain');
        if (collapseMain) {
            collapseMain.on('shown.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    await this.getProcessTabsMain();
                    await this.getProcessContact();
                    this.updateDateOptionsValidity();
                    this.$scope.exporterList = await this.getExporterList();
                    this.$scope.importerList = await this.getImporterList();
                    this.$scope.representativesList = await this.getRepresentativesList();
                    angular.element("#collapseMainHeading").attr('aria-expanded', 'true');
                    // update collapse state
                    this.$newProcessScope.collapseState = { panel: ECollapseState.MAIN, released: false, nextState: null };
                    this.$newProcessScope.repositionPanels(ECollapseState.MAIN, true);
                    this.$newProcessScope.disableElements(this.$newProcessScope.operation == EOperation.VIEW);
                }
            });

            collapseMain.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseMainHeading").attr('aria-expanded', 'false');
                    this.$scope.model = null;
                    this.$scope.oldModel = null;
                }
            });
        }
    }

    private buildCompanyIds(calledByRepresentativeModal?: boolean): string[] {
        const companyIds: string[] = [];

        if (this.$scope.model.IMPORTER && companyIds.findIndex(companyId => companyId == this.$scope.model.IMPORTER.ID) < 0) companyIds.push(this.$scope.model.IMPORTER.ID);
        if (this.$scope.model.EXPORTER && companyIds.findIndex(companyId => companyId == this.$scope.model.EXPORTER.ID) < 0) companyIds.push(this.$scope.model.EXPORTER.ID);
        if (this.$scope.model.NOTIFY && companyIds.findIndex(companyId => companyId == this.$scope.model.NOTIFY.ID) < 0) companyIds.push(this.$scope.model.NOTIFY.ID);
        if (this.$scope.model.BROKER && companyIds.findIndex(companyId => companyId == this.$scope.model.BROKER.ID) < 0) companyIds.push(this.$scope.model.BROKER.ID);
        if (this.$scope.model.SERVICE_PROVIDER && companyIds.findIndex(companyId => companyId == this.$scope.model.SERVICE_PROVIDER.ID) < 0) companyIds.push(this.$scope.model.SERVICE_PROVIDER.ID);
        if (this.$scope.model.LOCAL_AGENT && companyIds.findIndex(companyId => companyId == this.$scope.model.LOCAL_AGENT.ID) < 0) companyIds.push(this.$scope.model.LOCAL_AGENT.ID);
        if (this.$scope.model.EXTERNAL_AGENT && companyIds.findIndex(companyId => companyId == this.$scope.model.EXTERNAL_AGENT.ID) < 0) companyIds.push(this.$scope.model.EXTERNAL_AGENT.ID);
        if (this.$scope.model.THIRD_AGENT && companyIds.findIndex(companyId => companyId == this.$scope.model.THIRD_AGENT.ID) < 0) companyIds.push(this.$scope.model.THIRD_AGENT.ID);
        if (this.$scope.model.AGENCY && companyIds.findIndex(companyId => companyId == this.$scope.model.AGENCY.ID) < 0) companyIds.push(this.$scope.model.AGENCY.ID);
        if (this.$scope.model.CUSTOMER && companyIds.findIndex(companyId => companyId == this.$scope.model.CUSTOMER.ID) < 0) companyIds.push(this.$scope.model.CUSTOMER.ID);
        if (this.$scope.model.FLEXI_FITTING && companyIds.findIndex(companyId => companyId == this.$scope.model.FLEXI_FITTING.ID_LEGAL_PERSON) < 0) companyIds.push(this.$scope.model.FLEXI_FITTING.ID_LEGAL_PERSON);
        if (this.$scope.model.ISOTANK_PROVIDER && companyIds.findIndex(companyId => companyId == this.$scope.model.ISOTANK_PROVIDER.ID_LEGAL_PERSON) < 0) companyIds.push(this.$scope.model.ISOTANK_PROVIDER.ID_LEGAL_PERSON);
        if (!calledByRepresentativeModal && this.$scope.model.REPRESENTATIVES) {
            if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE.REPRESENTATIVE) {
                if (companyIds.findIndex(companyId => companyId == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE.REPRESENTATIVE.ID) < 0) companyIds.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE.REPRESENTATIVE.ID);
            }

            if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT.REPRESENTATIVE) {
                if (companyIds.findIndex(companyId => companyId == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT.REPRESENTATIVE.ID) < 0) companyIds.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT.REPRESENTATIVE.ID);
            }

            if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_LOAD && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_LOAD.REPRESENTATIVE) {
                if (companyIds.findIndex(companyId => companyId == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_LOAD.REPRESENTATIVE.ID) < 0) companyIds.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_LOAD.REPRESENTATIVE.ID);
            }

            if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PICK_UP && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PICK_UP.REPRESENTATIVE) {
                if (companyIds.findIndex(companyId => companyId == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PICK_UP.REPRESENTATIVE.ID) < 0) companyIds.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PICK_UP.REPRESENTATIVE.ID);
            }

            if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DELIVERY && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DELIVERY.REPRESENTATIVE) {
                if (companyIds.findIndex(companyId => companyId == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DELIVERY.REPRESENTATIVE.ID) < 0) companyIds.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DELIVERY.REPRESENTATIVE.ID);
            }

            if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION.REPRESENTATIVE) {
                if (companyIds.findIndex(companyId => companyId == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION.REPRESENTATIVE.ID) < 0) companyIds.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION.REPRESENTATIVE.ID);
            }
        }

        return companyIds;
    }

    private getContentType(fileType: string): string {
        switch (fileType.toLowerCase()) {
            case 'arc':
                return 'application/octet-stream';
            case 'bin':
                return 'application/octet-stream';
            case 'avi':
                return 'video/x-msvideo';
            case 'azw':
                return 'application/vnd.amazon.ebook';
            case 'gif':
                return 'image/gif';
            case 'htm':
                return 'text/html';
            case 'html':
                return 'text/html';
            case 'ico':
                return 'image/x-icon';
            case 'ics':
                return 'text/calendar';
            case 'js':
                return 'application/javascript';
            case 'json':
                return 'application/json';
            case 'mpeg':
                return 'video/mpeg';
            case 'jpeg':
                return 'image/jpeg';
            case 'jpg':
                return 'image/jpg';
            case 'png':
                return 'image/png';
            case 'pdf':
                return 'application/pdf';
            case 'rar':
                return 'application/x-rar-compressed';
            case 'zip':
                return 'application/zip';
            case '7z':
                return 'application/x-7z-compressed';
            case 'ppt':
                return 'application/vnd.ms-powerpoint';
            case 'csv':
                return 'text/csv';
            case 'doc':
                return 'application/msword';
            case 'xls':
                return 'application/vnd.ms-excel';
            case 'xlsx':
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            case 'vsd':
                return 'application/vnd.visio';
            case 'ts':
                return 'application/typescript';
            case 'rtf':
                return 'application/rtf';
            default:
                return 'text/plain';
        }
    }

    public async downloadFileFromGoogleStorage(hash: string): Promise<void> {
        try {
            this.formService.block();
            if (!hash) return null;

            const operation = await this.restService.getObjectAsPromise(`${this.$newProcessScope.downloadRoute}/${hash}`, 30000, null, false);
            const response: angular.IHttpResponse<any> = operation;
            if (response && response.data) {
                const resultFile = <IDownloadParamsReturn>response.data;

                const file = resultFile.file;
                if (!resultFile.buffer) {
                    throw Error('Failed to get file information');
                }
                const buffer = resultFile.buffer.data;
                if (!file || !buffer) {
                    throw Error('Failed to get file information');
                }
                const fileName = file.fileName;
                const fileBuffer = new Uint8Array(buffer);
                const fileType = this.getContentType(file.fileType);
                const fileBlob = new Blob([fileBuffer], { type: fileType });
                const fileURL = window.URL.createObjectURL(fileBlob);

                let link = document.createElement('a');
                link.href = fileURL;
                link.download = fileName;
                link.click();
            } else {
                throw Error('Error on download file')
            }

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async fileReferenceModal(file: REFERENCE, preSetFields?: IFileReferencePreSetFields, disabledFields?: IFileReferenceDisabledFields, uploadDisabled?: boolean, fileModel?: IFiles, isEdit?: boolean) {
        try {
            this.fileReferenceModalID = this.modalService.newModal();
            const modalResponse = await this.modalService.showModalFileReference(
                {
                    modalID: this.fileReferenceModalID,

                    categoryName: [
                        EReportCategoryName.Process,
                        EReportCategoryName.BookingConfirmation,
                        EReportCategoryName.Cover,
                        EReportCategoryName.ShippingInstruction,
                        EReportCategoryName.PreAlert,
                        EReportCategoryName.FMC,
                        EReportCategoryName.ProtestLetter,
                        EReportCategoryName.ProtestLetterIA,
                        EReportCategoryName.BillofLadingInstructions,
                        EReportCategoryName.SolicitationTC4,
                        EReportCategoryName.SolicitationRefrigerator,
                        EReportCategoryName.ExemptionLetter,
                        EReportCategoryName.Minuta,
                        EReportCategoryName.CartaSeguranca,
                        EReportCategoryName.CartaSegurancaAdicional,
                        EReportCategoryName.DeclaracaoSeguranca,
                        EReportCategoryName.CargoReadinessHistory,
                        EReportCategoryName.CartaAutorizacao,
                        EReportCategoryName.CartaAutorizacaoAdicional,
                        EReportCategoryName.CartaSegurancaDG,
                    ],
                    process: [this.$newProcessScope.model],
                    preSetFields: preSetFields,
                    disabledFields: disabledFields,
                    uploadDisabled: uploadDisabled,
                    file: file,
                    fileModel: fileModel,
                    isEdit: isEdit
                }
            );
            return modalResponse;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async showReferencesModal() {
        try {
            this.referenceModalID = this.modalService.newModal();
            this.modalService.showModalInfo(
                {
                    modalID: this.referenceModalID,
                    scope: this.$scope,
                    formService: this.$newProcessScope.operation,
                    template: require('../view/NewProcessReferencesModal.html'),
                    size: 'vlg',
                    keyboard: true,
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing") {
                            if (reason.toString() == "escape key press") event.preventDefault();
                        }
                    }
                },
                {
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: 'OPERATIONAL.REFERENCES_AND_ATTACHMENTS'
                }
            );
            await this.buildReferenceModalScope(this.$scope.model.REFERENCE, this.$newProcessScope.operation);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async addAccountReference() {
        const documentTypeCommercialInvoice = this.$scope.documentTypeList && this.$scope.documentTypeList.length ? this.$scope.documentTypeList.find(documentType => documentType.ID == EDocumentTypeId.COMMERCIAL_INVOICE) : null;
        const fileGroupDocument = this.$scope.fileGroupList && this.$scope.fileGroupList.length ? this.$scope.fileGroupList.find(fileGroup => fileGroup.ID == EFileGroupId.DOCUMENTO) : null;
        const fileSpecAccount = this.$scope.fileSpecList && this.$scope.fileSpecList.length ? this.$scope.fileSpecList.find(fileSpec => fileSpec.ID == EFileSpecsId.CLIENTE) : null;
        const preSetFields: IFileReferencePreSetFields = { documentType: documentTypeCommercialInvoice, mainFile: true, fileGroup: fileGroupDocument, fileSpecs: fileSpecAccount ? [fileSpecAccount] : null };
        const disabledFields: IFileReferenceDisabledFields = { documentType: true, fileGroup: true, fileSpecs: true, templateType: true };
        const modalResponse = await this.fileReferenceModal(null, preSetFields, disabledFields);
        //update process view table
        if (modalResponse) {
            const newReferences = modalResponse;
            if (!this.$scope.model.REFERENCE) this.$scope.model.REFERENCE = [];
            if (newReferences && newReferences.length) {
                this.$scope.auxModel.mainReference = newReferences[0];
                this.$scope.model.REFERENCE.splice(0, 0, newReferences[0]);
                const mainFileReferences = this.$scope.model.REFERENCE && this.$scope.model.REFERENCE.length ? this.$scope.model.REFERENCE.filter(reference => reference.MAIN_FILE && reference.REFERENCE_ID != newReferences[0].REFERENCE_ID) : [];
                if (mainFileReferences.length) {
                    mainFileReferences.forEach(reference => {
                        reference.MAIN_FILE = false;
                    });
                }
                this.$scope.referenceList = this.$scope.model.REFERENCE && this.$scope.model.REFERENCE.length ? this.$scope.model.REFERENCE.filter(reference => !reference.MAIN_FILE) : [];
                if (await this.applyReference(this.$scope.model.REFERENCE)) this.$scope.oldModel = angular.copy(this.$scope.model);
            }
        }
    }

    private async changeReference(selected: REFERENCE, oldSelected: string): Promise<void> {
        try {
            const oldValue: REFERENCE = oldSelected ? JSON.parse(oldSelected) : null;
            const mainReference: REFERENCE = this.$scope.model.REFERENCE && this.$scope.model.REFERENCE.length ? this.$scope.model.REFERENCE.find(reference => reference.MAIN_FILE && reference.REFERENCE_ID != selected.REFERENCE_ID) : null;
            if (mainReference) {
                const modal = await this.modalService.showModalConfirmation({}, {
                    actionButtonText: 'GENERAL.CONFIRM',
                    headerText: 'GENERAL.CONFIRM_ACTION',
                    closeButtonText: 'GENERAL.CLOSE',
                    bodyText: `${this.formService.getTranslate('OPERATIONAL.IF_YOU_PROCEED_THIS_WILL_BE_THE_MAIN_CUSTOMER_REF')} <span class="text-danger"><b>${this.formService.getTranslate('BASIC_DATA.ARE_YOU_SURE_YOU_WANT_TO_PERFORM_THIS_ACTION')}</b></span>?`
                });
                if (!modal) {
                    this.$scope.auxModel.mainReference = oldValue;
                    return;
                } else {
                    selected.MAIN_FILE = true;
                    mainReference.MAIN_FILE = false;
                }
            } else {
                selected.MAIN_FILE = true;
            }
            this.$scope.referenceList = this.$scope.model.REFERENCE && this.$scope.model.REFERENCE.length ? this.$scope.model.REFERENCE.filter(reference => !reference.MAIN_FILE) : [];
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async buildReferenceModalScope(references: REFERENCE[], operation: string): Promise<IReferenceModalScope> {
        const modalScope: IReferenceModalScope = await this.modalService.getModalScope(this.referenceModalID);
        modalScope.operation = operation;
        modalScope.references = angular.copy(references);
        modalScope.oldReferences = angular.copy(references);
        modalScope.processNumber = this.$scope.processNumber;

        modalScope.viewFile = (file: REFERENCE): void => {
            if (file) window.open(file.LINK, "_blank");
        }

        modalScope.downloadFile = async (file: REFERENCE): Promise<void> => {
            if (file) {
                const linkBroken: string[] = file.LINK.split('/');
                const hash: string = linkBroken[linkBroken.length - 1];
                this.downloadFileFromGoogleStorage(hash);
            }
        }

        modalScope.copyFileLinkToClipboard = (file: REFERENCE) => {
            if (file) {
                const elmAux = document.createElement("textarea");
                document.body.appendChild(elmAux);
                elmAux.value = file.LINK;
                elmAux.select();
                document.execCommand("copy");
                document.body.removeChild(elmAux);
                this.formService.notifySuccess("Link do arquivo foi copiado para o clipboard.");
            }
        }

        modalScope.viewProcessReferenceModal = async (file: REFERENCE) => {
            const disabledFields: IFileReferenceDisabledFields = {
                documentType: true,
                documentValue: true,
                fileGroup: true,
                fileSpecs: true,
                name: true,
                templateType: true,
                documentSequence: true,
                configuration: true,
                productType: true,
                telephone: true,
                technicalManager: true,
                email: true,
            };
            const extensionRemoveList: string[] = file.NAME ? file.NAME.split('.') : null;
            const extension: string = (extensionRemoveList && extensionRemoveList.length > 0) ? extensionRemoveList[extensionRemoveList.length - 1] : null;
            const fileModel: IFiles = {
                FILES: file.LINK && file.LINK.length ? [
                    {
                        FILE_DISPLAY_NAME: file.NAME ? file.NAME : file.DOCUMENT_VALUE,
                        FILE_NAME: file.NAME,
                        FILE_TYPE: extension,
                        FILE_PATH: null,
                        FILE_URL: file.LINK,
                        FILE_HASH: null,
                        FORM_NAME: "processReference"
                    }
                ] : []
            };
            await this.fileReferenceModal(file, null, disabledFields, true, fileModel);
        }

        modalScope.editProcessReferenceModal = async (index: number) => {
            const file = modalScope.references[index];
            const extensionRemoveList: string[] = file.NAME ? file.NAME.split('.') : null;
            const extension: string = (extensionRemoveList && extensionRemoveList.length > 0) ? extensionRemoveList[extensionRemoveList.length - 1] : null;
            const fileModel: IFiles = {
                FILES: file.LINK && file.LINK.length ? [
                    {
                        FILE_DISPLAY_NAME: file.NAME ? file.NAME : file.DOCUMENT_VALUE,
                        FILE_NAME: file.NAME,
                        FILE_TYPE: extension,
                        FILE_PATH: null,
                        FILE_URL: file.LINK,
                        FILE_HASH: null,
                        FORM_NAME: "processReference"
                    }
                ] : []
            };
            const modalResponse = await this.fileReferenceModal(angular.copy(file), null, null, true, fileModel, true);
            if (modalResponse) {
                modalScope.references.splice(index, 1, modalResponse[0]);
                modalScope.oldReferences = angular.copy(modalScope.references);
                const msg = this.formService.getTranslate('BASIC_DATA.REFERENCE_SUCESSFULLY_UPDATED');
                this.formService.notifySuccess(msg);
                modalScope.$applyAsync();
            }
        }

        modalScope.fileReferenceModal = async () => {
            const modalResponse = await this.fileReferenceModal(null);
            //update process view table
            if (modalResponse) {
                const newReferences = modalResponse;
                if (!modalScope.references) modalScope.references = [];
                for (const newReference of newReferences) {
                    modalScope.references.splice(0, 0, newReference);
                    const msg = this.formService.getTranslate('BASIC_DATA.REFERENCE_SUCESSFULLY_ADDED');
                    this.formService.notifySuccess(msg);
                    //FormService.notifySuccess('Referência adicionada com <b>sucesso</b>. <i>Clique aqui</i> para ir até o registro!', function (e) { $scope.navigateBetweenIds('reference' + newReference.REFERENCE_ID) });
                }
            }
        }

        modalScope.applyReferenceModal = async (close?: boolean) => {
            this.applyReference(modalScope.references, close);
        };

        modalScope.closeReferenceModal = async (): Promise<void> => {
            if (this.$newProcessScope.hasChanges(modalScope.references, modalScope.oldReferences)) {
                const close = this.formService.getTranslate('GENERAL.CLOSE');
                const confirm = await this.$newProcessScope.modalSaveConfirmation(close, close);
                if (confirm && !this.applyReference(modalScope.references)) return;
            }
            this.modalService.closeModal(this.referenceModalID);
            this.representativeModalId = 0;
            $('.app-content-body').animate({ scrollTop: this.$newProcessScope.lastScroll }, 0);
        }

        modalScope.removeReference = async (index: number) => {
            if (!index && index != 0) throw Error('index is null');

            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (modalScope.references && modalScope.references.length > 0) modalScope.references.splice(index, 1);
        }

        modalScope.mainFileChange = (index: number) => {
            if (!index && index != 0) throw Error('index is null');

            const changedReference = modalScope.references && modalScope.references.length ? modalScope.references[index] : null;
            const mainFileReferences = modalScope.references && modalScope.references.length ? modalScope.references.filter(reference => reference.MAIN_FILE && reference.REFERENCE_ID != changedReference.REFERENCE_ID) : [];
            if (changedReference && changedReference.MAIN_FILE && mainFileReferences.length) {
                mainFileReferences.forEach(reference => {
                    reference.MAIN_FILE = false;
                });
            }
        }

        return modalScope;
    }

    private async applyReference(references: REFERENCE[], close?: boolean): Promise<boolean> {
        let success = false;
        try {
            this.formService.block();
            const updateReferenceResponse = await this.operationalService.post('/process/updateReference', { processNumber: this.$scope.processNumber, data: { REFERENCE: references } }, 30000);
            if (updateReferenceResponse && updateReferenceResponse.status == 200) {
                success = true;
                this.$scope.model.REFERENCE = references;
                this.$scope.auxModel.mainReference = references && references.length ? references.find(reference => reference.MAIN_FILE) : null;
                this.$scope.referenceList = this.$scope.model.REFERENCE && this.$scope.model.REFERENCE.length ? this.$scope.model.REFERENCE.filter(reference => !reference.MAIN_FILE) : [];
                this.$scope.oldModel.REFERENCE = angular.copy(this.$scope.model.REFERENCE);
            }
            if (close) {
                this.modalService.closeModal(this.referenceModalID);
                this.commoditiesModalId = 0;
                $('.app-content-body').animate({ scrollTop: this.$newProcessScope.lastScroll }, 0);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            if (success) {
                const msg = this.formService.getTranslate('BASIC_DATA.REFERENCE_SUCESSFULLY_UPDATED');
                this.formService.notifySuccess(msg);
            }
            return success;
        }
    }

    private async getUser(id: string): Promise<any> {
        let result: [];
        try {
            const resultHelper = await this.helperService.get(`/user/id/${id}`, null, 10000);
            const user = resultHelper.data.data;
            if (user && user.data.data) {
                result = user.data ? user.data.map(x => {
                    return {
                        ID: x.ID, NAME: x.SHORT_NAME, CODE: x.CORPORATE_NAME,
                        TRADING_NAME: null, TAXPAYER_NUMBER: null, SCAC: null, ADDRESS: null
                    }
                }) : [];
            }

            return result
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return result;
        }
    }

    private async showInternalContacts() {
        try {

            const contacts = angular.copy(this.$scope.contactModel.INTERNAL).sort(function (a, b) {
                if (a.CELL.NAME > b.CELL.NAME) return 1;
                if (a.CELL.NAME < b.CELL.NAME) return -1;

                if (a.ROLE.NAME > b.ROLE.NAME) return 1;
                if (a.ROLE.NAME < b.ROLE.NAME) return -1;

                if (a.USER.NAME > b.USER.NAME) return 1;
                if (a.USER.NAME < b.USER.NAME) return -1;
            });

            this.internalContactsModalID = this.modalService.newModal();
            this.modalService.showModalInfo(
                {
                    modalID: this.internalContactsModalID,
                    scope: this.$scope,
                    formService: this.$newProcessScope.operation,
                    template: require('../view/NewProcessInternalContactsModal.html'),
                    size: 'vlg modal-overflow',
                    keyboard: true,
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing") {
                            if (reason.toString() == "escape key press") event.preventDefault();
                            if (!closed) {
                                const modalScope: IInternalContactModalScope = await this.modalService.getModalScope(this.internalContactsModalID);
                                await modalScope.closeInternalContactModal();
                            }
                        }
                    }
                },
                {
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: 'OPERATIONAL.INTERNAL_RESPONSIBLE'
                }
            );
            await this.buildInternalContactModalScope(contacts);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async buildInternalContactModalScope(contacts: INTERNAL[]): Promise<IInternalContactModalScope> {
        const modalScope: IInternalContactModalScope = await this.modalService.getModalScope(this.internalContactsModalID);

        modalScope.operation = this.$newProcessScope.operation;
        modalScope.contacts = contacts;
        modalScope.oldContacts = angular.copy(contacts);

        modalScope.applyInternalContact = (close?: boolean) => {
            this.applyInternalContact(contacts, close);
        };

        modalScope.closeInternalContactModal = async (): Promise<void> => {
            if (this.$newProcessScope.hasChanges(modalScope.contacts, modalScope.oldContacts)) {
                const close = this.formService.getTranslate('GENERAL.CLOSE');
                const confirm = await this.$newProcessScope.modalSaveConfirmation(close, close);
                if (confirm && !this.applyInternalContact(modalScope.contacts)) return;
            }
            this.modalService.closeModal(this.internalContactsModalID);
            this.internalContactsModalID = 0;
            $('.app-content-body').animate({ scrollTop: this.$newProcessScope.lastScroll }, 0);
        }

        modalScope.addInternalContact = () => {
            this.addInternalContact();
        }

        modalScope.addNewContacts = () => {
            this.addNewContacts();
        }

        modalScope.removeInternalContact = (index: number) => {
            this.removeInternalContact(index);
        }

        return modalScope;
    }

    private async addInternalContact() {
        try {
            const modalScope: IInternalContactModalScope = await this.modalService.getModalScope(this.internalContactsModalID);
            const result = await this.getInternalContacts();
            const msg = this.formService.getTranslate('OPERATIONAL.NO_INTERNAL_CONTACTS_FOUND');

            modalScope.newContactsOption = [];
            modalScope.newContactList = [];
            modalScope.newContacts = null;

            if (!result || !result.data) return this.formService.handleError(msg);
            modalScope.newContactList = result.data.data;

            if (modalScope.newContactList && this.$scope.contactModel.INTERNAL && this.$scope.contactModel.INTERNAL.length > 0) {
                for (let i = 0; i < this.$scope.contactModel.INTERNAL.length; i++) {
                    for (let z = 0; z < modalScope.newContactList.length; z++) {
                        if (modalScope.newContactList[z].USER.EMAIL == this.$scope.contactModel.INTERNAL[i].USER.EMAIL) modalScope.newContactList.splice(z, 1);
                    }
                }
            }

            // format and push to final array
            // I had to format because selector format based in ID and NAME
            for (let i = 0; i < modalScope.newContactList.length; i++) {
                modalScope.newContactsOption.push({
                    "ID": modalScope.newContactList[i].CELL.NAME,
                    "NAME": modalScope.newContactList[i].USER.NAME,
                    "EMAIL": modalScope.newContactList[i].USER.EMAIL,
                    "ROLE": modalScope.newContactList[i].ROLE.NAME,
                    "FULL": modalScope.newContactList[i]
                });
            }

            modalScope.$applyAsync();
            this.formService.unblock();

            modalScope.newContacts = <INewContactFull>{};
            this.newContactModalID = this.modalService.newModal();
            await this.modalService.showModalInfo(
                {
                    modalID: this.newContactModalID,
                    template: require("../view/processNewContactModal.html"),
                    scope: modalScope,
                    formService: 'register'
                },
                {
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: 'BASIC_DATA.FILL_IN_THE_DATA_AND_CLICK_ON_ADD'
                });


        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async addNewContacts() {
        const modalScope: IInternalContactModalScope = await this.modalService.getModalScope(this.internalContactsModalID);
        for (const contact of modalScope.newContacts.CONTACTS) {
            modalScope.contacts.push({ ...contact.FULL, DATA_ORIGIN_TYPE: { ID: "M", NAME: "Manual" } })
        }
        this.modalService.closeModal(this.newContactModalID);
    }

    private async removeInternalContact(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');

            const modalScope: IInternalContactModalScope = await this.modalService.getModalScope(this.internalContactsModalID);
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (modalScope.contacts && modalScope.contacts.length) {
                this.formService.block();
                modalScope.contacts.splice(index, 1);
                this.formService.unblock();
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async applyInternalContact(contacts: INTERNAL[], close?: boolean): Promise<boolean> {
        let success = false;
        try {
            const hasInvalid = this.$newProcessScope.hasInvalidRequiredElements('modalForm');
            if (hasInvalid) return;
            if (!contacts) throw Error('contacts is null');
            if (!hasInvalid) {
                this.formService.block();
                const contactPersistModel = angular.copy(this.$scope.contactModel);
                contactPersistModel.INTERNAL = contacts;
                const updateResponse = await this.operationalService.post(`/process/updateContact`, { processNumber: this.$scope.processNumber, data: { CONTACT: contactPersistModel } }, 30000);
                if (updateResponse && updateResponse.status == 200) {
                    success = true;
                    const msg = this.formService.getTranslate('OPERATIONAL.INTERNAL_CONTACTS_SUCESSFULLY UPDATED');
                    this.formService.notifySuccess(msg);
                    await this.getProcessContact();
                    this.$scope.model.CONTACT.INTERNAL = angular.copy(this.$scope.contactModel.INTERNAL);
                    this.$scope.oldModel.CONTACT.INTERNAL = angular.copy(this.$scope.model.CONTACT.INTERNAL);
                }
                if (close) {
                    this.modalService.closeModal(this.internalContactsModalID);
                    this.internalContactsModalID = 0;
                    $('.app-content-body').animate({ scrollTop: this.$newProcessScope.lastScroll }, 0);
                }
            }
            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return success;
        }
    }

    private async getInternalContacts() {
        const result = await this.operationalService.post('/cell/contact/internal/list', {}, 15000);
        if (!result) return this.formService.handleError(result);
        return result;
    }

    private async showExternalContacts() {
        try {
            this.externalContactsModalID = this.modalService.newModal();
            const companies = this.buildCompanies(this.$scope.contactModel ? this.$scope.contactModel.EXTERNAL : null);
            this.modalService.showModalInfo(
                {
                    modalID: this.externalContactsModalID,
                    scope: this.$scope,
                    formService: this.$newProcessScope.operation,
                    template: require('../view/NewProcessExternalContactsModal.html'),
                    size: 'vlg modal-overflow',
                    keyboard: true,
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing") {
                            if (reason.toString() == "escape key press") event.preventDefault();
                            if (!closed) {
                                const modalScope: IExternalContactModalScope = await this.modalService.getModalScope(this.externalContactsModalID);
                                await modalScope.closeExternalContactModal();
                            }
                        }
                    }
                },
                {
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: 'GENERAL.CONTACT'
                }
            );
            await this.buildExternalContactModalScope(companies);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async buildExternalContactModalScope(companies: IExternalContactCompany[]): Promise<IExternalContactModalScope> {
        const modalScope: IExternalContactModalScope = await this.modalService.getModalScope(this.externalContactsModalID);

        modalScope.operation = this.$newProcessScope.operation;
        modalScope.companies = companies;
        modalScope.oldCompanies = angular.copy(companies);

        // Filter
        modalScope.filterName = [];
        modalScope.filterMail = [];
        modalScope.filterGroup = [];
        modalScope.filteredCompanies = angular.copy(companies);

        // Filter function
        modalScope.filterCompanies = (companyIndex: number) => {
            modalScope.filteredCompanies[companyIndex].CONTACTS = modalScope.companies[companyIndex].CONTACTS;
            if (modalScope.filterName && modalScope.filterName[companyIndex]) {
                modalScope.filteredCompanies[companyIndex].CONTACTS = modalScope.filteredCompanies[companyIndex].CONTACTS.filter(contact => contact.NAME === modalScope.filterName[companyIndex].NAME);
            }
            if (modalScope.filterMail && modalScope.filterMail[companyIndex]) {
                modalScope.filteredCompanies[companyIndex].CONTACTS = modalScope.filteredCompanies[companyIndex].CONTACTS.filter(contact => contact.MAIL == modalScope.filterMail[companyIndex].MAIL);
            }
            if (modalScope.filterGroup && modalScope.filterGroup[companyIndex] && modalScope.filterGroup[companyIndex].length > 0) {
                const filteredByGroup = [];
                for (const contact of modalScope.filteredCompanies[companyIndex].CONTACTS) {
                    let isValid = true;
                    for (const filter of modalScope.filterGroup[companyIndex]) {
                        if (!(contact.GROUP.find(contactGroup => contactGroup.ID === filter.ID))) isValid = false;
                    }
                    if (isValid) filteredByGroup.push(contact)
                }
                modalScope.filteredCompanies[companyIndex].CONTACTS = angular.copy(filteredByGroup);
            }
        };

        modalScope.applyExternalContact = (close?: boolean) => {
            this.applyExternalContact(this.buildExternalContactModelToPersistFormat(modalScope.companies), close);
        };

        modalScope.closeExternalContactModal = async (): Promise<void> => {
            if (this.$newProcessScope.hasChanges(modalScope.companies, modalScope.oldCompanies)) {
                const close = this.formService.getTranslate('GENERAL.CLOSE');
                const confirm = await this.$newProcessScope.modalSaveConfirmation(close, close);
                if (confirm && !this.applyExternalContact(this.buildExternalContactModelToPersistFormat(modalScope.companies))) return;
            }
            this.modalService.closeModal(this.externalContactsModalID);
            this.externalContactsModalID = 0;
            $('.app-content-body').animate({ scrollTop: this.$newProcessScope.lastScroll }, 0);
        }

        modalScope.addExternalContact = async (companyIndex: number) => {
            this.addExternalContact(companyIndex);
        }

        modalScope.removeExternalContact = (companyIndex: number, index: number) => {
            this.removeExternalContact(companyIndex, index);
        }

        modalScope.updateContactGroup = (companyIndex: number, index: number) => {
            this.updateContactGroup(companyIndex, index);
        }

        modalScope.contactExternalCompanyDuplicityFilter = (companyId: string): Function => {
            const company = modalScope.companies && modalScope.companies.length ? modalScope.companies.find(company => company.ID == companyId) : null;
            return function (contactParam: EXTERNAL) {
                return !company.CONTACTS || company.CONTACTS && company.CONTACTS.length == 0 || company.CONTACTS.findIndex(contact => contact.ID == contactParam.ID) < 0;
            }
        }

        return modalScope;
    }

    private async applyExternalContact(contacts: EXTERNAL[], close?: boolean): Promise<boolean> {
        let success = false;
        try {
            const hasInvalid = this.$newProcessScope.hasInvalidRequiredElements('modalForm');
            if (hasInvalid) return;
            if (!contacts) throw Error('contacts is null');
            if (!hasInvalid) {
                this.formService.block();
                const contactPersistModel = angular.copy(this.$scope.contactModel);
                contactPersistModel.EXTERNAL = contacts;
                const updateResponse = await this.operationalService.post(`/process/updateContact`, { processNumber: this.$scope.processNumber, data: { CONTACT: contactPersistModel } }, 30000);
                if (updateResponse && updateResponse.status == 200) {
                    success = true;
                    const msg = this.formService.getTranslate('OPERATIONAL.EXTERNAL_CONTACTS_SUCESSFULLY UPDATED');
                    this.formService.notifySuccess(msg);
                    await this.getProcessContact();
                    this.$scope.model.CONTACT.EXTERNAL = angular.copy(this.$scope.contactModel.EXTERNAL);
                    this.$scope.oldModel.CONTACT.EXTERNAL = angular.copy(this.$scope.model.CONTACT.EXTERNAL);
                }
                if (close) {
                    this.modalService.closeModal(this.externalContactsModalID);
                    this.externalContactsModalID = 0;
                    $('.app-content-body').animate({ scrollTop: this.$newProcessScope.lastScroll }, 0);
                }
            }
            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return success;
        }
    }

    private buildExternalContactModelToPersistFormat(companiesToParse: IExternalContactCompany[]): EXTERNAL[] {
        let contacts: EXTERNAL[] = [];
        if (companiesToParse && companiesToParse.length) {
            for (const company of companiesToParse) {
                if (company.CONTACTS && company.CONTACTS.length) contacts = contacts.concat(company.CONTACTS);
            }
        }
        return contacts;
    }

    private async getExternalContact(involved: ENTITY): Promise<EXTERNAL[]> {
        let result: EXTERNAL[] = [];
        try {
            if (!involved && !involved.ID) return;
            this.formService.block();
            let commodities = [];
            if (this.$scope.model.COMMODITY_SUMMARY) {
                for (const commodityItem of this.$scope.model.COMMODITY_SUMMARY.COMMODITY_ITEM) {
                    if (commodityItem.COMMODITY && commodityItem.COMMODITY.ID) commodities.push(commodityItem.COMMODITY.ID);
                };
            }
            const contacts = await this.productService.post<any>({
                route: '/contact/list/operational', data: {
                    filters: {
                        LEGAL_PERSON: involved.ID_LEGAL_PERSON,
                        PRODUCTS: [this.$newProcessScope.model.PRODUCT.ID],
                        COMMODITIES: commodities,
                        ACCOUNTS: [this.$scope.model.CUSTOMER ? this.$scope.model.CUSTOMER.ID : null],
                        EXPORTERS: this.$scope.model.EXPORTER ? [this.$scope.model.EXPORTER.ID] : null,
                        IMPORTERS: this.$scope.model.IMPORTER ? [this.$scope.model.IMPORTER.ID] : null,
                        OUTER_CELLS: null,
                        ORIGIN_MARITIME: (this.$newProcessScope.model.ORIGIN) ? [this.$newProcessScope.model.ORIGIN.ID] : null,
                        DESTINATION_MARITIME: (this.$newProcessScope.model.DESTINATION) ? [this.$newProcessScope.model.DESTINATION.ID] : null,
                        ORIGIN_AIR: null,
                        DESTINATION_AIR: null,
                        ORIGIN_ROAD: null,
                        DESTINATION_ROAD: null,
                        TRADE_ORIGIN_AIR: null,
                        TRADE_DESTINATION_AIR: null,
                        TRADE_ORIGIN_MARITIME: (this.$newProcessScope.model.ORIGIN) ? [this.$newProcessScope.model.ORIGIN.TRADE_GROUP.ID] : null,
                        TRADE_DESTINATION_MARITIME: (this.$newProcessScope.model.DESTINATION) ? [this.$newProcessScope.model.DESTINATION.TRADE_GROUP.ID] : null,
                        TRADE_ORIGIN_ROAD: null,
                        TRADE_DESTINATION_ROAD: null,
                        TRADE_LANE_ORIGIN_AIR: null,
                        TRADE_LANE_DESTINATION_AIR: null,
                        TRADE_LANE_ORIGIN_MARITIME: (this.$newProcessScope.model.ORIGIN) ? [this.$newProcessScope.model.ORIGIN.TRADE_LANE.ID] : null,
                        TRADE_LANE_DESTINATION_MARITIME: (this.$newProcessScope.model.DESTINATION) ? [this.$newProcessScope.model.DESTINATION.TRADE_LANE.ID] : null,
                        TRADE_LANE_ORIGIN_ROAD: null,
                        TRADE_LANE_DESTINATION_ROAD: null,
                        COUNTRY_ORIGIN_AIR: null,
                        COUNTRY_DESTINATION_AIR: null,
                        COUNTRY_ORIGIN_MARITIME: (this.$newProcessScope.model.ORIGIN) ? [this.$newProcessScope.model.ORIGIN.COUNTRY.ID] : null,
                        COUNTRY_DESTINATION_MARITIME: (this.$newProcessScope.model.DESTINATION) ? [this.$newProcessScope.model.DESTINATION.COUNTRY.ID] : null,
                        COUNTRY_ORIGIN_ROAD: null,
                        COUNTRY_DESTINATION_ROAD: null
                    },
                    sysConvertIdToString: true
                }
            });
            if (contacts && contacts.data) {
                result = contacts.data.data ? contacts.data.data.map(x => {
                    return {
                        ID: x.ID,
                        NAME: x.NAME,
                        MAIL: x.EMAIL,
                        GROUP: x.MESSAGE_GROUP,
                        TYPE: x.TYPE,
                        COMPANY: x.COMPANY,
                        DATA_ORIGIN_TYPE: { ID: "A", NAME: "Automático" }
                    }
                }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }


    private async getLegalPersonListByName(filter: ILegalPersonListCustomFilter): Promise<ENTITY[]> {
        let result: ENTITY[] = [];
        try {
            this.formService.block();
            const response = await this.productService.post({ route: "/legalPerson/list/custom/operational", data: filter });
            if (response && response.data && response.data.data) result = response.data.data.map(legalPerson => {
                return {
                    ID: legalPerson.ID,
                    NAME: legalPerson.NAME,
                    CODE: legalPerson.CODE,
                    ID_PHYSICAL_PERSON: null,
                    TRADING_NAME: legalPerson.TRADING_NAME,
                    TAXPAYER_NUMBER: legalPerson.TAXPAYER_NUMBER,
                    SCAC: legalPerson.SCAC,
                    NUMBER_IATA: legalPerson.NUMBER_IATA,
                    ADDRESS: legalPerson.ADDRESS,
                    NETWORK: legalPerson.NETWORK,
                    CORPORATE_NAME: legalPerson.CORPORATE_NAME,
                    ID_LEGAL_PERSON: legalPerson.ID
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getBrokerListByName(filter: IBrokerListCustomFilter): Promise<ENTITY[]> {
        let result: ENTITY[] = [];
        try {
            this.formService.block();
            const response = await this.productService.post({ route: "/broker/list/custom/operational", data: filter });
            if (response && response.data && response.data.data && response.data.data.data) result = response.data.data.data.map(broker => {
                return {
                    ID: broker.ID.toString(), NAME: broker.NAME, CODE: broker.CODE, ID_LEGAL_PERSON: broker.ID_LEGAL_PERSON.toString(), ID_PHYSICAL_PERSON: null,
                    TRADING_NAME: broker.TRADING_NAME, TAXPAYER_NUMBER: broker.TAXPAYER_NUMBER, SCAC: broker.SCAC, NUMBER_IATA: broker.NUMBER_IATA,
                    ADDRESS: broker.ADDRESS, NETWORK: broker.NETWORK, CORPORATE_NAME: broker.CORPORATE_NAME
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getBookingListByName(search: string): Promise<IBookingIntegration[]> {
        let result: IBookingIntegration[] = [];
        try {
            this.formService.block();
            const response = await this.operationalService.post("/booking/eligibleBookings", { search: search, processNumber: this.$newProcessScope.model.PROCESS_NUMBER });
            if (response && response.data) result = response.data.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCurrenciesList(term?: string): Promise<ISelectorModel[]> {
        try {
            const transform = (returnedData) => returnedData.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } });

            const request: IMonacoRequest = {
                data: {},
                route: `/currency/list/custom/`,
                timeout: 10000,
            };
            const response = await this.productService.post(request);

            this.formService.unblock();
            const result: ISelectorModel[] = (response && response.data && response.data.data) ? transform(response.data.data) : [];
            return result;

        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async getAgencyListByName(filter?: IAgencyListCustomFilter): Promise<AGENCY[]> {
        let result: AGENCY[] = [];
        this.formService.block();
        try {
            const response = await this.productService.post({ route: "/agency/list/custom/operational", data: filter });
            if (response && response.data && response.data.data && response.data.data.data) result = response.data.data.data.map(agency => {
                return {
                    ID: agency.ID,
                    NAME: agency.NAME,
                    TRADING_NAME: agency.TRADING_NAME,
                    TAXPAYER_NUMBER: agency.TAXPAYER_NUMBER,
                    SCAC: agency.SCAC,
                    NUMBER_IATA: agency.NUMBER_IATA,
                    ADDRESS: agency.ADDRESS,
                    ID_LEGAL_PERSON: agency.ID_LEGAL_PERSON,
                    NETWORK: agency.NETWORK,
                    CORPORATE_NAME: agency.CORPORATE_NAME,
                    ID_PHYSICAL_PERSON: agency.ID_PHYSICAL_PERSON
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private buildCompanies(externalContacts: EXTERNAL[]): IExternalContactCompany[] {
        const externalContactCompanies: IExternalContactCompany[] = [];

        try {
            const companies: ENTITY[] = [];
            if (this.$scope.model.IMPORTER && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.IMPORTER.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.IMPORTER);
            if (this.$scope.model.EXPORTER && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.EXPORTER.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.EXPORTER);
            if (this.$scope.model.NOTIFY && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.NOTIFY.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.NOTIFY);
            if (this.$scope.model.BROKER && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.BROKER.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.BROKER);
            if (this.$scope.model.SERVICE_PROVIDER && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.SERVICE_PROVIDER.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.SERVICE_PROVIDER);
            if (this.$scope.model.LOCAL_AGENT && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.LOCAL_AGENT.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.LOCAL_AGENT);
            if (this.$scope.model.EXTERNAL_AGENT && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.EXTERNAL_AGENT.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.EXTERNAL_AGENT);
            if (this.$scope.model.THIRD_AGENT && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.THIRD_AGENT.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.THIRD_AGENT);
            if (this.$scope.model.TRANSPORTER_PICKUP && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.TRANSPORTER_PICKUP.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.TRANSPORTER_PICKUP);
            if (this.$scope.model.TRANSPORTER_PLACE_RECEPT && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.TRANSPORTER_PLACE_RECEPT.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.TRANSPORTER_PLACE_RECEPT);
            if (this.$scope.model.TRANSPORTER_FINAL_DESTINATION && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.TRANSPORTER_FINAL_DESTINATION.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.TRANSPORTER_FINAL_DESTINATION);
            if (this.$scope.model.TRANSPORTER_DELIVERY && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.TRANSPORTER_DELIVERY.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.TRANSPORTER_DELIVERY);
            if (this.$scope.model.TRANSPORTER_GATE_OUT_EMPTY && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.TRANSPORTER_GATE_OUT_EMPTY.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.TRANSPORTER_GATE_OUT_EMPTY);
            if (this.$scope.model.TRANSPORTER_CONTAINER_STUFFING && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.TRANSPORTER_CONTAINER_STUFFING.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.TRANSPORTER_CONTAINER_STUFFING);
            if (this.$scope.model.FLEXI_FITTING && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.FLEXI_FITTING.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.FLEXI_FITTING);
            if (this.$scope.model.ISOTANK_PROVIDER && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.ISOTANK_PROVIDER.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.ISOTANK_PROVIDER);
            if (this.$scope.model.EXTERNAL_BROKER && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.EXTERNAL_BROKER.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.EXTERNAL_BROKER);
            if (this.$scope.model.CARGO_DETAIL && this.$scope.model.CARGO_DETAIL.INSURANCE && this.$scope.model.CARGO_DETAIL.INSURANCE.INSURANCE_BROKER && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.CARGO_DETAIL.INSURANCE.INSURANCE_BROKER.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.CARGO_DETAIL.INSURANCE.INSURANCE_BROKER);
            if (this.$scope.model.AGENCY && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.AGENCY.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.AGENCY);
            if (this.$scope.model.CUSTOMER && companies.findIndex(company => company.ID_LEGAL_PERSON == this.$scope.model.CUSTOMER.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.CUSTOMER);
            // if (this.$scope.model.REPRESENTATIVE && this.$scope.model.REPRESENTATIVE.length) {
            //     for (const representative of this.$scope.model.REPRESENTATIVE) {
            //         if (companies.findIndex(company => company.ID_LEGAL_PERSON == representative.ID_LEGAL_PERSON) < 0) companies.push(representative);
            //     }
            // }

            if (this.$scope.model.REPRESENTATIVES) {
                if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE.REPRESENTATIVE) {
                    if (companies.findIndex(companyId => companyId.ID_LEGAL_PERSON == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE.REPRESENTATIVE.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE.REPRESENTATIVE);
                }

                if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT.REPRESENTATIVE) {
                    if (companies.findIndex(companyId => companyId.ID_LEGAL_PERSON == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT.REPRESENTATIVE.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT.REPRESENTATIVE);
                }

                if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_LOAD && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_LOAD.REPRESENTATIVE) {
                    if (companies.findIndex(companyId => companyId.ID_LEGAL_PERSON == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_LOAD.REPRESENTATIVE.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_LOAD.REPRESENTATIVE);
                }

                if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PICK_UP && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PICK_UP.REPRESENTATIVE) {
                    if (companies.findIndex(companyId => companyId.ID_LEGAL_PERSON == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PICK_UP.REPRESENTATIVE.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_PICK_UP.REPRESENTATIVE);
                }

                if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DELIVERY && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DELIVERY.REPRESENTATIVE) {
                    if (companies.findIndex(companyId => companyId.ID_LEGAL_PERSON == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DELIVERY.REPRESENTATIVE.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_DELIVERY.REPRESENTATIVE);
                }

                if (this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION && this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION.REPRESENTATIVE) {
                    if (companies.findIndex(companyId => companyId.ID_LEGAL_PERSON == this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION.REPRESENTATIVE.ID_LEGAL_PERSON) < 0) companies.push(this.$scope.model.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION.REPRESENTATIVE);
                }
            }

            for (const company of companies) {
                const contacts = externalContacts && externalContacts.length ? externalContacts.filter(externalContact => externalContact.COMPANY && externalContact.COMPANY.ID == company.ID_LEGAL_PERSON) : [];
                const externalContactCompany: IExternalContactCompany = {
                    ID: company.ID,
                    NAME: company.NAME,
                    CONTACTS: contacts,
                    ADDRESS: company.ADDRESS,
                    CODE: company.CODE,
                    SCAC: company.SCAC,
                    NUMBER_IATA: company.NUMBER_IATA,
                    TAXPAYER_NUMBER: company.TAXPAYER_NUMBER,
                    TRADING_NAME: company.TRADING_NAME,
                    CORPORATE_NAME: company.CORPORATE_NAME,
                    ID_LEGAL_PERSON: company.ID_LEGAL_PERSON,
                    ID_PHYSICAL_PERSON: company.ID_PHYSICAL_PERSON,
                    NETWORK: company.NETWORK,
                };
                externalContactCompanies.push(externalContactCompany);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
        return externalContactCompanies;
    }

    private async addExternalContact(companyIndex: number) {
        if (!companyIndex && companyIndex != 0) return this.formService.notifyError("companyIndex is null.");

        const modalScope: IExternalContactModalScope = await this.modalService.getModalScope(this.externalContactsModalID);
        const company = modalScope.companies && modalScope.companies.length ? modalScope.companies[companyIndex] : null;

        modalScope.newExternalContactModal = {
            CONTACT: null,
            newContactExternalList: company ? await this.getContactListByName(company.ID_LEGAL_PERSON) : []
        }

        try {
            const body = `
            <div class="row">
                <div class="col-lg-12">
                    <ui-select name="newExternalContact" id="newExternalContact"
                        ng-model="newExternalContactModal.CONTACT"
                        theme="bootstrap"
                        ng-change="selectorValidity(this.$select.ngModel.$name);"
                        ng-disabled="selectorDisabled(this.$select.ngModel.$name) || operation == 'view'"
                        ng-click="selectorFocus(this.$select.searchInput[0]);"
                        skip-focusser="true" close-on-select="true"
                        multiple required>
                        <ui-select-match allow-clear="false" placeholder="{{'GENERAL.UI_SELECT.SELECT' | translate }}">
                            {{$select.selected[$index].NAME}}
                        </ui-select-match>
                        <ui-select-choices refresh-delay="250"
                            repeat="item in newExternalContactModal.newContactExternalList | filter: contactExternalCompanyDuplicityFilter(${company.ID}) | filter: $select.search track by $index">
                            <div
                                ng-bind-html="item.NAME | highlight: $select.search">
                            </div>
                        </ui-select-choices>
                        <ui-select-no-choice>
                            {{'GENERAL.UI_SELECT.EMPTY_SELECTOR_MESSAGE' | translate }}
                        </ui-select-no-choice>
                    </ui-select>
                </div>
            </div>
            `;

            if (!this.modalNewExternalContactId) this.modalNewExternalContactId = this.modalService.newModal();
            const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                {
                    modalID: this.modalNewExternalContactId,
                    scope: this.$scope,
                    formService: 'register',
                    size: 'md',
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing" && closed) {
                            if (!modalScope.newExternalContactModal || (modalScope.newExternalContactModal && !modalScope.newExternalContactModal.CONTACT)) {
                                event.preventDefault();
                                const msgError = this.formService.getTranslate('OPERATIONAL.IT_IS_NECESSARY_TO_INFORM_THE_NEW_CONTACT_TO_CONTINUE');
                                this.formService.notifyError(msgError);
                            }
                        }
                    }
                },
                {
                    closeButtonText: 'GENERAL.CANCEL',
                    actionButtonText: 'GENERAL.ADD',
                    headerText: 'OPERATIONAL.SELECT_CONTACT',
                    bodyText: this.$sce.trustAsHtml(body),

                }
            );

            modalInstance.rendered.then(() => {
                const modifiedTypeSelect = angular.element("#newExternalContact");
                if (modifiedTypeSelect) this.$compile(modifiedTypeSelect)(modalScope);
            });

            const apply = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (apply) {
                if (!company.CONTACTS) company.CONTACTS = [];
                angular.forEach(modalScope.newExternalContactModal.CONTACT, function (contact) {
                    company.CONTACTS.push(contact);
                });
                modalScope.filterCompanies(companyIndex);
            }

            modalScope.newExternalContactModal = null;

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getGenericList(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async removeExternalContact(companyIndex: number, index: number): Promise<void> {
        try {
            if (!companyIndex && companyIndex != 0) throw Error('companyIndex is null');
            if (!index && index != 0) throw Error('index is null');

            const modalScope: IExternalContactModalScope = await this.modalService.getModalScope(this.externalContactsModalID);
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            if (modalScope.companies && modalScope.companies[companyIndex] && modalScope.companies[companyIndex].CONTACTS && modalScope.companies[companyIndex].CONTACTS.length) {
                const indexFound = modalScope.companies[companyIndex].CONTACTS.findIndex(company => company.ID === modalScope.filteredCompanies[companyIndex].CONTACTS[index].ID)
                modalScope.companies[companyIndex].CONTACTS.splice(indexFound, 1);
                modalScope.filterCompanies(companyIndex);
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async updateContactGroup(companyIndex: number, index: number): Promise<void> {
        try {
            if (!companyIndex && companyIndex != 0) throw Error('companyIndex is null');
            if (!index && index != 0) throw Error('index is null');

            const modalScope: IExternalContactModalScope = await this.modalService.getModalScope(this.externalContactsModalID);
            if (!modalScope) throw Error('modalScope not found');

            if (modalScope.companies && modalScope.companies[companyIndex].CONTACTS && modalScope.companies[companyIndex].CONTACTS.length > 0) {
                const indexFound = modalScope.companies[companyIndex].CONTACTS.findIndex(company => company.ID === modalScope.filteredCompanies[companyIndex].CONTACTS[index].ID)
                if (indexFound) modalScope.companies[companyIndex].CONTACTS[indexFound] = angular.copy(modalScope.filteredCompanies[companyIndex].CONTACTS[index]);
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async collapseMain() {
        try {
            if (this.$newProcessScope.collapseState.released || this.$newProcessScope.collapseState.panel == ECollapseState.MAIN) {
                const collapseMain = angular.element("#collapseMain");
                if (collapseMain) {
                    const isCollapsed = angular.element("#collapseMainHeading").attr("aria-expanded") == "true";
                    if (isCollapsed) {
                        if (this.$newProcessScope.hasChanges(this.$scope.model, this.$scope.oldModel)) {
                            const main = this.formService.getTranslate('GENERAL.MAIN');
                            const close = this.formService.getTranslate('GENERAL.CLOSE');
                            const confirm = await this.$newProcessScope.modalSaveConfirmation(main, close);
                            if (confirm && !await this.saveProcessTabMain()) return;
                        }
                        this.$newProcessScope.collapseState.released = true;
                    }
                    collapseMain['collapse']('toggle');
                    if (isCollapsed) this.$newProcessScope.repositionPanels(ECollapseState.MAIN);
                }
            } else {
                this.$newProcessScope.collapseState.nextState = ECollapseState.MAIN;
                this.$newProcessScope.releaseCollapse();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getEventsByProcessId() {
        let events = null;
        try {
            this.formService.block();
            events = await this.operationalService.get("/event/list/processId/" + this.$newProcessScope.model._id.toString(), null, 10000);
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }

        return events && events.data && events.data.data && events.data.data[0] && events.data.data[0].EVENT ? events.data.data[0].EVENT : [];
    }

    private async openRepresentativesModal(): Promise<void> {
        try {
            const representativesModal = this.$scope.model.REPRESENTATIVES
            this.representativeModalId = this.modalService.newModal();
            this.modalService.showModalInfo(
                {
                    modalID: this.representativeModalId,
                    scope: this.$scope,
                    formService: 'edit',
                    size: 'lg modal-overflow',
                    template: require("../view/newProcessMainRepresentativesModal.html"),
                    keyboard: true,
                    events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                        if (event.name == "modal.closing") {
                            if (reason.toString() == "escape key press") event.preventDefault();
                            if (!closed) {
                                const modalScope: IRepresentativeModalScope = await this.modalService.getModalScope(this.representativeModalId);
                                await modalScope.closeRepresentativeModal();
                            }
                        }
                    }
                },
                null
            );
            await this.buildRepresentativeModalScope(representativesModal);
        } catch (ex) {
            this.formService.notifyError(ex);
        }
    }

    private async buildRepresentativeModalScope(representatives: REPRESENTATIVES): Promise<IRepresentativeModalScope> {
        const modalScope: IRepresentativeModalScope = await this.modalService.getModalScope(this.representativeModalId);
        const events = await this.getEventsByProcessId();

        modalScope.processNumber = this.$scope.processNumber;
        modalScope.operation = this.$newProcessScope.operation;
        modalScope.representatives = angular.copy(representatives);
        modalScope.oldRepresentatives = angular.copy(representatives);
        modalScope.events = events;
        modalScope.eventTypeList = events && events.length ? events.map(event => { return { ID: event.EVENT_TYPE.ID, NAME: event.EVENT_TYPE.NAME } }) : [];

        modalScope.applyRepresentative = (close?: boolean) => {
            this.applyRepresentative(modalScope.representatives, modalScope.oldRepresentatives, close);
        };

        modalScope.closeRepresentativeModal = async (): Promise<void> => {
            if (this.$newProcessScope.hasChanges(modalScope.representatives, modalScope.oldRepresentatives)) {
                const close = this.formService.getTranslate('GENERAL.CLOSE');
                const confirm = await this.$newProcessScope.modalSaveConfirmation(close, close);
                if (confirm && !this.applyRepresentative(modalScope.representatives, modalScope.oldRepresentatives)) return;
            }
            this.modalService.closeModal(this.representativeModalId);
            this.representativeModalId = 0;
            $('.app-content-body').animate({ scrollTop: this.$newProcessScope.lastScroll }, 0);
        }

        return modalScope;
    }

    private async applyRepresentative(representatives: REPRESENTATIVES, oldRepresentatives: REPRESENTATIVES, close?: boolean): Promise<boolean> {
        let success = false;
        try {
            const hasInvalid = this.$newProcessScope.hasInvalidRequiredElements('modalForm');
            if (hasInvalid) return;
            if (!representatives) throw Error('representatives is null');
            if (!hasInvalid) {
                this.formService.block();
                const updateResponse = await this.operationalService.post(`/process/updateRepresentative`, { processNumber: this.$scope.processNumber, data: { REPRESENTATIVES: representatives } }, 30000);
                if (updateResponse && updateResponse.status == 200) {
                    await this.getAndUpdateRepresentativeContact(representatives, oldRepresentatives);

                    success = true;
                    this.$scope.model.REPRESENTATIVES = representatives;
                    this.$newProcessScope.model.REPRESENTATIVES = representatives;
                    const msg = this.formService.getTranslate('OPERATIONAL.REPRESENTATIVES_SUCESSFULLY_UPDATED');
                    this.formService.notifySuccess(msg);
                }
                if (close) {
                    this.modalService.closeModal(this.representativeModalId);
                    this.representativeModalId = 0;
                    $('.app-content-body').animate({ scrollTop: this.$newProcessScope.lastScroll }, 0);
                }
            }
            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return success;
        }
    }
    private async getContacts(representative: REPRESENTATIVE_DEFAULT) {
        const companyIds: string[] = this.buildCompanyIds(true);
        const hasSameCompanyInAnotherObj = companyIds && companyIds.findIndex(companyId => companyId == representative.REPRESENTATIVE.ID_LEGAL_PERSON) >= 0;
        if (!hasSameCompanyInAnotherObj) {
            const contacts: EXTERNAL[] = await this.getExternalContact(representative.REPRESENTATIVE);
            if (contacts && contacts.length) {
                for (const contact of contacts) {
                    const hasContactInList = this.$scope.contactModel.EXTERNAL && this.$scope.contactModel.EXTERNAL.length && this.$scope.contactModel.EXTERNAL.findIndex(contactExternal => contactExternal.ID == contact.ID) >= 0;
                    if (!hasContactInList) this.$scope.contactModel.EXTERNAL.push(contact);
                }
            }
        }
    }
    private async getAndUpdateRepresentativeContact(representatives: REPRESENTATIVES, oldRepresentatives: REPRESENTATIVES) {
        if (!this.$scope.contactModel.EXTERNAL) this.$scope.contactModel.EXTERNAL = [];
        if (representatives) {
            if (representatives.REPRESENTATIVE_DELIVERY && representatives.REPRESENTATIVE_DELIVERY.REPRESENTATIVE && representatives.REPRESENTATIVE_DELIVERY.REPRESENTATIVE.ID_LEGAL_PERSON) {
                await this.getContacts(representatives.REPRESENTATIVE_DELIVERY)
            }

            if (representatives.REPRESENTATIVE_DISCHARGE && representatives.REPRESENTATIVE_DISCHARGE.REPRESENTATIVE && representatives.REPRESENTATIVE_DISCHARGE.REPRESENTATIVE.ID_LEGAL_PERSON) {
                await this.getContacts(representatives.REPRESENTATIVE_DISCHARGE)
            }

            if (representatives.REPRESENTATIVE_FINAL_DESTINATION && representatives.REPRESENTATIVE_FINAL_DESTINATION.REPRESENTATIVE && representatives.REPRESENTATIVE_FINAL_DESTINATION.REPRESENTATIVE.ID_LEGAL_PERSON) {
                await this.getContacts(representatives.REPRESENTATIVE_FINAL_DESTINATION)
            }

            if (representatives.REPRESENTATIVE_LOAD && representatives.REPRESENTATIVE_LOAD.REPRESENTATIVE && representatives.REPRESENTATIVE_LOAD.REPRESENTATIVE.ID_LEGAL_PERSON) {
                await this.getContacts(representatives.REPRESENTATIVE_LOAD)
            }

            if (representatives.REPRESENTATIVE_PICK_UP && representatives.REPRESENTATIVE_PICK_UP.REPRESENTATIVE && representatives.REPRESENTATIVE_PICK_UP.REPRESENTATIVE.ID_LEGAL_PERSON) {
                await this.getContacts(representatives.REPRESENTATIVE_PICK_UP)
            }

            if (representatives.REPRESENTATIVE_PLACE_OF_RECEIPT && representatives.REPRESENTATIVE_PLACE_OF_RECEIPT.REPRESENTATIVE && representatives.REPRESENTATIVE_PLACE_OF_RECEIPT.REPRESENTATIVE.ID_LEGAL_PERSON) {
                await this.getContacts(representatives.REPRESENTATIVE_PLACE_OF_RECEIPT)
            }
            await this.applyExternalContact(this.$scope.contactModel.EXTERNAL)
        }

        if (oldRepresentatives) {
            if (oldRepresentatives.REPRESENTATIVE_DELIVERY && oldRepresentatives.REPRESENTATIVE_DELIVERY.REPRESENTATIVE && oldRepresentatives.REPRESENTATIVE_DELIVERY.REPRESENTATIVE.ID_LEGAL_PERSON) {
                await this.getContacts(oldRepresentatives.REPRESENTATIVE_DELIVERY)
            }

            if (oldRepresentatives.REPRESENTATIVE_DISCHARGE && oldRepresentatives.REPRESENTATIVE_DISCHARGE.REPRESENTATIVE && oldRepresentatives.REPRESENTATIVE_DISCHARGE.REPRESENTATIVE.ID_LEGAL_PERSON) {
                await this.getContacts(oldRepresentatives.REPRESENTATIVE_DISCHARGE)
            }

            if (oldRepresentatives.REPRESENTATIVE_FINAL_DESTINATION && oldRepresentatives.REPRESENTATIVE_FINAL_DESTINATION.REPRESENTATIVE && oldRepresentatives.REPRESENTATIVE_FINAL_DESTINATION.REPRESENTATIVE.ID_LEGAL_PERSON) {
                await this.getContacts(oldRepresentatives.REPRESENTATIVE_FINAL_DESTINATION)
            }

            if (oldRepresentatives.REPRESENTATIVE_LOAD && oldRepresentatives.REPRESENTATIVE_LOAD.REPRESENTATIVE && oldRepresentatives.REPRESENTATIVE_LOAD.REPRESENTATIVE.ID_LEGAL_PERSON) {
                await this.getContacts(oldRepresentatives.REPRESENTATIVE_LOAD)
            }

            if (oldRepresentatives.REPRESENTATIVE_PICK_UP && oldRepresentatives.REPRESENTATIVE_PICK_UP.REPRESENTATIVE && oldRepresentatives.REPRESENTATIVE_PICK_UP.REPRESENTATIVE.ID_LEGAL_PERSON) {
                await this.getContacts(oldRepresentatives.REPRESENTATIVE_PICK_UP)
            }

            if (oldRepresentatives.REPRESENTATIVE_PLACE_OF_RECEIPT && oldRepresentatives.REPRESENTATIVE_PLACE_OF_RECEIPT.REPRESENTATIVE && oldRepresentatives.REPRESENTATIVE_PLACE_OF_RECEIPT.REPRESENTATIVE.ID_LEGAL_PERSON) {
                await this.getContacts(oldRepresentatives.REPRESENTATIVE_PLACE_OF_RECEIPT)
            }
        }
    }

    private async openCommoditiesModal(): Promise<void> {
        this.$newProcessScope.lastScroll = $('.app-content-body').scrollTop();
        this.commoditiesModalId = this.modalService.newModal();
        const commodities = this.$scope.model.COMMODITY_SUMMARY ? this.$scope.model.COMMODITY_SUMMARY.COMMODITY_ITEM : null;
        const exceptions = this.$scope.model.COMMODITY_SUMMARY ? this.$scope.model.COMMODITY_SUMMARY.COMMODITY_EXCEPTIONS : null;
        this.modalService.showModalInfo(
            {
                modalID: this.commoditiesModalId,
                scope: this.$scope,
                formService: 'register',
                size: 'lg modal-overflow',
                template: require("../view/newProcessMainCommodityModal.html"),
                keyboard: true,
                events: async (event: angular.IAngularEvent, reason: Object, closed: boolean) => {
                    if (event.name == "modal.closing") {
                        if (reason.toString() == "escape key press") event.preventDefault();
                        if (!closed) {
                            const modalScope: ICommodityModalScope = await this.modalService.getModalScope(this.commoditiesModalId);
                            await modalScope.closeCommodityModal();
                        }
                    }
                }
            },
            null
        );
        await this.buildCommodityModalScope(commodities, exceptions);
    }

    private async openPeopleDetails(entity: ENTITY, agentEntity?: ENTITY_AGENT) {
        try {
            if (!entity && !agentEntity) return;
            const agentFields = agentEntity ? `
            <div class="row form-group">
                <div class="col-lg-6">
                    <label><b>${this.formService.getTranslate('ENTITY.SHIPPER_MASTER')}</b></label>
                    <textarea class="form-control" rows="6" style="resize:none" readonly>${agentEntity.SHIPPER_MASTER ? agentEntity.SHIPPER_MASTER : ''}</textarea>
                </div>
                <div class="col-lg-6">
                    <label><b>${this.formService.getTranslate('ENTITY.CONSIGNEE_MASTER')}</b></label>
                    <textarea class="form-control" rows="6" style="resize:none" readonly>${agentEntity.CNEE_MASTER ? agentEntity.CNEE_MASTER : ''}</textarea>
                </div>
            </div>
            <div class="row form-group">
                <div class="col-lg-6">
                    <label><b>${this.formService.getTranslate('ENTITY.NOTIFY_MASTER')}</b></label>
                    <textarea class="form-control" rows="6" style="resize:none" readonly>${agentEntity.NOTIFY_MASTER ? agentEntity.NOTIFY_MASTER : ''}</textarea>
                </div>
                <div class="col-lg-6">
                    <label><b>${this.formService.getTranslate('ENTITY.REMARKS_MASTER')}</b></label>
                    <textarea class="form-control" rows="6" style="resize:none" readonly>${agentEntity.REMARKS_MASTER ? agentEntity.REMARKS_MASTER : ''}</textarea>
                </div>
            </div>
            ` : '';
            const body = `
            <div class="row form-group">
                <div class="col-lg-12">
                    <label><b>${this.formService.getTranslate('GENERAL.TAX_ID')}</b></label>
                    <span class="form-control" readonly>${agentEntity ? agentEntity.TAXPAYER_NUMBER : entity.TAXPAYER_NUMBER}</span>
                </div>
            </div>
            ${agentFields}
            <div class="row">
                <div class="col-lg-12">
                    <label><b>${this.formService.getTranslate('ENTITY.POSTAL_ADDRESS')}</b></label>
                    <textarea class="form-control" rows="6" style="resize:none" readonly>${agentEntity && agentEntity.AGENT_CORRESPONDENCE ? agentEntity.AGENT_CORRESPONDENCE : entity && entity.ADDRESS && entity.ADDRESS.CORRESPONDENCE ? entity.ADDRESS.CORRESPONDENCE : ''}</textarea>
                </div>
            </div>`;

            this.modalService.showModalInfo({}, {
                actionButtonText: 'GENERAL.CLOSE',
                headerText: agentEntity ? agentEntity.NAME : entity.NAME,
                bodyText: this.$sce.trustAsHtml(body)
            });

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async buildCommodityModalScope(commodities: COMMODITY_ITEM[], exceptions: COMMODITY_EXCEPTIONS[]): Promise<ICommodityModalScope> {
        const modalScope: ICommodityModalScope = await this.modalService.getModalScope(this.commoditiesModalId);

        try {
            modalScope.processNumber = this.$scope.processNumber;
            modalScope.operation = this.$newProcessScope.operation;
            modalScope.commodities = angular.copy(commodities);
            modalScope.oldCommodities = angular.copy(commodities);
            modalScope.exceptions = angular.copy(exceptions);
            modalScope.oldExceptions = angular.copy(exceptions);

            // get generics
            const result: Array<any> = await this.$q.all([
                this.getCommoditySectionList()
            ]);
            modalScope.sectionList = result[0];

            modalScope.addCommodity = (): void => {
                try {
                    if (!modalScope.commodities) modalScope.commodities = [];

                    const commodity: COMMODITY_ITEM = {
                        COMMODITY: null,
                        COMMODITY_SECTION: null,
                        DATA_ORIGIN_TYPE: { ID: EDataOriginTypeId.MANUAL, NAME: "Manual" }
                    }
                    modalScope.commodities.push(commodity);

                    const lastIndex = modalScope.commodities.length - 1;
                    this.$timeout(() => {
                        modalScope.selectorValidity("commoditySection" + lastIndex);
                        modalScope.selectorValidity("commodityCommodity" + lastIndex);
                    }, 100);
                } catch (ex) {
                    this.formService.handleError(ex);
                }
            }

            modalScope.removeCommodity = async (index: number): Promise<void> => {
                try {
                    if (!index && index != 0) throw Error('index is null');
                    const modal = await this.modalService.showModalConfirmation({}, {
                        actionButtonText: 'GENERAL.CONFIRM',
                        headerText: 'GENERAL.CONFIRM_ACTION',
                        bodyText: "GENERAL.MESSAGES.CONFIRMATION.REMOVAL"
                    });
                    if (!modal) return;

                    if (modalScope.commodities && modalScope.commodities.length > 0) {
                        this.formService.block();
                        modalScope.commodities.splice(index, 1);
                    }
                } catch (ex) {
                    this.formService.handleError(ex);
                } finally {
                    this.formService.unblock();
                }
            }

            modalScope.applyCommodity = (close?: boolean) => {
                this.applyCommodity(modalScope.commodities, modalScope.exceptions, close);
            };

            modalScope.closeCommodityModal = async (): Promise<void> => {
                if (this.$newProcessScope.hasChanges(modalScope.commodities, modalScope.oldCommodities)) {
                    const close = this.formService.getTranslate('GENERAL.CLOSE');
                    const confirm = await this.$newProcessScope.modalSaveConfirmation(close, close);
                    if (confirm && !this.applyCommodity(modalScope.commodities, modalScope.exceptions)) return;
                }
                this.modalService.closeModal(this.commoditiesModalId);
                this.commoditiesModalId = 0;
                $('.app-content-body').animate({ scrollTop: this.$newProcessScope.lastScroll }, 0);
            }

            modalScope.getCommodityListByName = async (name: string): Promise<void> => {
                let commodityList = [];
                if (name && name.length >= 2) {
                    commodityList = await this.getCommodityListByName(name);
                }
                modalScope.commodityList = commodityList;
            };

            modalScope.commodityChange = (index: number) => {
                if (!index && index != 0) return this.formService.notifyError("index is null.");
                const commodity = modalScope.commodities && modalScope.commodities.length ? modalScope.commodities[index] : null;
                if (commodity) {
                    if (commodity.COMMODITY_SECTION) {
                        commodity.COMMODITY = null;
                    } else if (commodity.COMMODITY) {
                        commodity.COMMODITY_SECTION = null;
                    }
                    this.$timeout(() => {
                        modalScope.selectorValidity("commoditySection" + index);
                        modalScope.selectorValidity("commodityCommodity" + index);
                    }, 100);
                }
            }

            modalScope.isCommodityDisabled = (commodity: COMMODITY_ITEM, fieldName: string): boolean => {
                let disabled = false;
                try {
                    if (!commodity) throw Error('commodity is null');
                    if (!fieldName) throw Error('fieldName is null');
                    if (
                        (fieldName == 'section' && commodity.COMMODITY) ||
                        (fieldName == 'commodity' && commodity.COMMODITY_SECTION))
                        disabled = true;
                } catch (ex) {
                    this.formService.handleError(ex);
                } finally {
                    return disabled;
                }
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }

        return modalScope;
    }

    private async getCommoditySectionList(): Promise<ISelectorModel[]> {
        let result: ISelectorModel[] = [];
        this.formService.block();
        try {
            let sections = null;

            switch (this.$newProcessScope.model.SITUATION.ID) {
                case EProcessSituation.PRE_PROCESS:
                    const request: IMonacoRequest = {
                        route: `/generic/value/hscode_section`,
                        timeout: 30000,
                    };

                    sections = await this.helperService.get(request.route, null, request.timeout);
                    break;
                default:
                    const offer = this.$newProcessScope.model.OFFER.find(item => item.ACTIVE);
                    if (offer) sections = await this.productService.post({ route: '/offer/list/commodity/section', data: { offerId: offer.OFFER_ID, sysconvertIdToString: true } });
            }

            if (sections && sections.data && sections.data.data) result = sections.data.data.map(section => { return { ID: section.ID, NAME: section.NAME } });

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCommodityListByName(name?: string): Promise<COMMODITY_MODEL[]> {
        let result: COMMODITY_MODEL[] = [];
        this.formService.block();
        try {

            let commodities = null;
            switch (this.$newProcessScope.model.SITUATION.ID) {
                case EProcessSituation.PRE_PROCESS:
                    commodities = await this.productService.post({ route: '/commodity/list/custom/', data: { name } });
                    break;
                default:
                    const offer = this.$newProcessScope.model.OFFER.find(item => item.ACTIVE);
                    if (offer) commodities = await this.productService.post({ route: '/offer/list/commodity', data: { search: name, offerId: offer.OFFER_ID, sysconvertIdToString: true } });
            }

            if (commodities && commodities.data && commodities.data.data) result = commodities.data.data.map(commodity => { return { ID: commodity.ID, NAME: commodity.NAME, HS_CODE: commodity.HS_CODE ? commodity.HS_CODE : null } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async applyCommodity(commodities: COMMODITY_ITEM[], exceptions: COMMODITY_EXCEPTIONS[], close?: boolean): Promise<boolean> {
        let success = false;
        try {
            const hasInvalid = this.$newProcessScope.hasInvalidRequiredElements('modalForm');
            if (!hasInvalid) {
                this.formService.block();
                const commodityText = this.buildCommodityText(commodities, exceptions);
                const updateCommodityResponse = await this.operationalService.post('/process/updateCommodity', { processNumber: this.$scope.processNumber, data: { COMMODITY_SUMMARY: { COMMODITY_ITEM: commodities, COMMODITY_EXCEPTIONS: exceptions, COMMODITY_TEXT: commodityText } } }, 30000);
                if (updateCommodityResponse && updateCommodityResponse.status == 200 && updateCommodityResponse.data && updateCommodityResponse.data.data) {
                    success = true;
                    this.$scope.model.COMMODITY_SUMMARY = updateCommodityResponse.data.data.COMMODITY_SUMMARY;
                    this.$scope.oldModel = angular.copy(this.$scope.model);
                }
                if (close) {
                    this.modalService.closeModal(this.commoditiesModalId);
                    this.commoditiesModalId = 0;
                    $('.app-content-body').animate({ scrollTop: this.$newProcessScope.lastScroll }, 0);
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            if (success) {
                const msg = this.formService.getTranslate('BASIC_DATA.COMMODITY_SUCESSFULLY_UPDATED');
                this.formService.notifySuccess(msg);

            }
            return success;
        }
    }

    private buildCommodityText(commodities: COMMODITY_ITEM[], exceptions: COMMODITY_EXCEPTIONS[]): string {
        let commodityText = "";
        try {
            if (commodities && commodities.length) {
                const sectionList = [];
                const commodityList = [];
                for (const commodity of commodities) {
                    if (commodity.COMMODITY_SECTION) sectionList.push({ NAME: `${commodity.COMMODITY_SECTION.ID} - ${commodity.COMMODITY_SECTION.NAME}` });
                    if (commodity.COMMODITY) commodityList.push({ NAME: `${commodity.COMMODITY.HS_CODE} - ${commodity.COMMODITY.NAME}` });
                }
                const sectionConcatenated = sectionList && sectionList.length ? this.formService.getCONCAT(sectionList, null, "NAME", null, null, ',') : null;
                const commodityConcatenated = commodityList && commodityList.length ? this.formService.getCONCAT(commodityList, null, "NAME", null, null, ",") : null;
                if (sectionConcatenated) commodityText = commodityText.length > 0 ? commodityText.concat("; ", sectionConcatenated) : commodityText.concat(sectionConcatenated);
                if (commodityConcatenated) commodityText = commodityText.length > 0 ? commodityText.concat("; ", commodityConcatenated) : commodityText.concat(commodityConcatenated);
            }
            if (exceptions && exceptions.length) {
                const exceptionList = [];
                for (const exception of exceptions) {
                    exceptionList.push({ NAME: `${exception.HS_CODE} - ${exception.NAME}` });
                }
                const exceptionConcatenated = this.formService.getCONCAT(exceptionList, null, "NAME", null, null, ",");
                commodityText = commodityText.length > 0 ? commodityText.concat("; ", `(Exceção: ${exceptionConcatenated})`) : commodityText.concat(`(Exceção: ${exceptionConcatenated})`);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return commodityText;
        }
    }

    private async getProcessTabsMain(): Promise<void> {
        this.formService.block();
        try {
            const mainTab = await this.operationalService.get(`/process/panel/main/${this.$scope.processNumber}`, null, 30000);
            if (mainTab && mainTab.data && mainTab.data.data) {
                this.$scope.model = mainTab.data.data;
                this.$scope.auxModel.mainReference = this.$scope.model.REFERENCE && this.$scope.model.REFERENCE.length ? this.$scope.model.REFERENCE.find(reference => reference.MAIN_FILE) : null;
                this.$scope.referenceList = this.$scope.model.REFERENCE && this.$scope.model.REFERENCE.length ? this.$scope.model.REFERENCE.filter(reference => !reference.MAIN_FILE) : [];
                if (this.$scope.model.SHIPMENT_REQUIRED) {
                    this.$scope.model.SHIPMENT_REQUIRED.DATE_OF = this.$scope.model.SHIPMENT_REQUIRED.DATE_OF ? new Date(this.$scope.model.SHIPMENT_REQUIRED.DATE_OF) : null;
                    this.$scope.model.SHIPMENT_REQUIRED.DATE_UP = this.$scope.model.SHIPMENT_REQUIRED.DATE_UP ? new Date(this.$scope.model.SHIPMENT_REQUIRED.DATE_UP) : null
                }
                if (this.$scope.model.IS_BOOKING_INTEGRATION) {
                    this.$scope.model.BOOKING_INTEGRATION = {
                        ID: this.$scope.model.BOOKING_INTEGRATION.ID,
                        NAME: this.$scope.model.BOOKING_INTEGRATION.NAME,
                        INTTRA_REFERENCE: this.$scope.model.BOOKING_INTEGRATION.INTTRA_REFERENCE,
                        INTTRA_STATUS: this.$scope.model.BOOKING_INTEGRATION.INTTRA_STATUS,
                        AWAITING_INTTRA_CONFIRMATION: this.$scope.model.BOOKING_INTEGRATION.AWAITING_INTTRA_CONFIRMATION,
                        HAS_CRITICAL_DIFFS: this.$scope.model.BOOKING_INTEGRATION.HAS_CRITICAL_DIFFS,
                        HAS_DIFFS: this.$scope.model.BOOKING_INTEGRATION.HAS_DIFFS
                    };
                } else {
                    if (this.$scope.model.BOOKING) this.$scope.model.BOOKING_INTEGRATION = { ID: "0", NAME: this.$scope.model.BOOKING, INTTRA_REFERENCE: null, INTTRA_STATUS: null, AWAITING_INTTRA_CONFIRMATION: false, HAS_CRITICAL_DIFFS: false, HAS_DIFFS: false };
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.oldModel = angular.copy(this.$scope.model);
            if (this.$newProcessScope.operation == 'edit') {
                this.$timeout(() => {
                    this.formService.loadEditForm();
                }, 100);
            }
            this.formService.unblock();
        }
    }

    private async getProcessContact(): Promise<void> {
        this.formService.block();
        try {
            const contactRequest = await this.operationalService.get(`/process/panel/contact/${this.$scope.processNumber}`, null, 30000);
            if (contactRequest && contactRequest.data && contactRequest.data.data) this.$scope.contactModel = contactRequest.data.data.CONTACT;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getExporterList(): Promise<EXPORTER[]> {
        let exporterList: EXPORTER[] = [];
        const timeout: number = 120000;
        try {
            switch (this.$newProcessScope.model.SITUATION.ID) {
                case EProcessSituation.PRE_PROCESS:
                    const legalPersons = await this.productService.post({ route: "/legalPerson/list/custom/operational", data: { specializations: [ELegalPersonSpecializationId.EXPORTER] } });
                    if (legalPersons && legalPersons.data && legalPersons.data.data) exporterList = legalPersons.data.data.map(x => {
                        return {
                            ID: x.ID,
                            NAME: x.NAME,
                            CODE: x.CODE,
                            ID_LEGAL_PERSON: x.ID_LEGAL_PERSON,
                            TRADING_NAME: x.CORPORATE_NAME,
                            TAXPAYER_NUMBER: x.TAXPAYER_NUMBER,
                            SCAC: x.SCAC,
                            NUMBER_IATA: x.NUMBER_IATA,
                            ADDRESS: x.ADDRESS,
                            NETWORK: x.NETWORK,
                            CORPORATE_NAME: x.CORPORATE_NAME,
                            ID_PHYSICAL_PERSON: x.ID_PHYSICAL_PERSON
                        }
                    });
                    break;
                default:
                    if (!this.$newProcessScope.model.OFFER || this.$newProcessScope.model.OFFER === null) this.formService.notifyError("there is no offer in the process to list the exporter");
                    else {
                        const offer = this.$newProcessScope.model.OFFER.find(item => item.ACTIVE);
                        let response = null;

                        if (offer) response = await this.operationalService.get(`/offerCompiled/exporter/${offer.OFFER_ID}/${timeout}`, timeout);
                        if (response && response.data && response.data.data.EXPORTER) exporterList = response.data.data.EXPORTER.map(x => {
                            return {
                                ID: x.ID,
                                NAME: x.NAME,
                                CODE: x.CODE,
                                ID_LEGAL_PERSON: x.ID_LEGAL_PERSON,
                                TRADING_NAME: x.TRADING_NAME,
                                TAXPAYER_NUMBER: x.TAXPAYER_NUMBER,
                                SCAC: x.SCAC,
                                NUMBER_IATA: x.NUMBER_IATA,
                                ADDRESS: x.ADDRESS,
                                NETWORK: x.NETWORK,
                                CORPORATE_NAME: x.CORPORATE_NAME,
                                ID_PHYSICAL_PERSON: x.ID_PHYSICAL_PERSON
                            }
                        });
                    }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
        return exporterList;
    }

    private async getRepresentativesList(): Promise<REPRESENTATIVES> {
        let representativesFull: REPRESENTATIVES = null;
        const timeout: number = 120000;
        try {
            switch (this.$newProcessScope.model.SITUATION.ID) {
                case EProcessSituation.PRE_PROCESS:
                    break;
                default:
                    if (!this.$newProcessScope.model.OFFER || this.$newProcessScope.model.OFFER === null) this.formService.notifyError("there is no offer in the process to list the representatives");
                    else {
                        const offer = this.$newProcessScope.model.OFFER.find(item => item.ACTIVE);
                        let response = null;

                        if (offer) response = await this.operationalService.get(`/offerCompiled/representatives/${offer.OFFER_ID}/${timeout}`, timeout);
                        if (response && response.data && response.data.data.REPRESENTATIVES) representativesFull = response.data.data.REPRESENTATIVES
                    }
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
        return representativesFull;
    }

    private async getImporterList(): Promise<IMPORTER[]> {
        let importerList: IMPORTER[] = [];
        const timeout: number = 120000;

        try {
            switch (this.$newProcessScope.model.SITUATION.ID) {
                case EProcessSituation.PRE_PROCESS:
                    const legalPersons = await this.productService.post({ route: "/legalPerson/list/custom/operational", data: { specializations: [ELegalPersonSpecializationId.IMPORTER] } });
                    if (legalPersons && legalPersons.data && legalPersons.data.data) importerList = legalPersons.data.data.map(x => {
                        return {
                            ID: x.ID,
                            NAME: x.NAME,
                            CODE: x.CODE,
                            ID_LEGAL_PERSON: x.ID_LEGAL_PERSON,
                            TRADING_NAME: x.CORPORATE_NAME,
                            TAXPAYER_NUMBER: x.TAXPAYER_NUMBER,
                            SCAC: x.SCAC,
                            NUMBER_IATA: x.NUMBER_IATA,
                            ADDRESS: x.ADDRESS,
                            NETWORK: x.NETWORK,
                            CORPORATE_NAME: x.CORPORATE_NAME,
                            ID_PHYSICAL_PERSON: x.ID_PHYSICAL_PERSON
                        }
                    });
                    break;
                default:
                    if (!this.$newProcessScope.model.OFFER || this.$newProcessScope.model.OFFER === null) this.formService.notifyError("there is no offer in the process to list the importer");
                    else {
                        const offer = this.$newProcessScope.model.OFFER.find(item => item.ACTIVE);
                        let response = null;

                        if (offer) response = await this.operationalService.get(`/offerCompiled/importer/${offer.OFFER_ID}/${timeout}`, timeout);
                        if (response && response.data && response.data.data.IMPORTER) importerList = response.data.data.IMPORTER.map(x => {
                            return {
                                ID: x.ID,
                                NAME: x.NAME,
                                CODE: x.CODE,
                                ID_LEGAL_PERSON: x.ID_LEGAL_PERSON,
                                TRADING_NAME: x.TRADING_NAME,
                                TAXPAYER_NUMBER: x.TAXPAYER_NUMBER,
                                SCAC: x.SCAC,
                                NUMBER_IATA: x.NUMBER_IATA,
                                ADDRESS: x.ADDRESS,
                                NETWORK: x.NETWORK,
                                CORPORATE_NAME: x.CORPORATE_NAME,
                                ID_PHYSICAL_PERSON: x.ID_PHYSICAL_PERSON
                            }
                        });
                    }
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }

        return importerList;
    }

    private async updateProcessChargesBillOfSaleAmount(processNumber: string): Promise<void> {
        try {
            const chargeResult = await this.operationalService.get(`/charge/pair/${processNumber}`, 10000);

            const chargesPair: ChargePair[] = chargeResult.data && chargeResult.data.data && chargeResult.data.data.length ? chargeResult.data.data.filter(charge => {
                if (charge.INCOME && charge.INCOME.APPLICATION && charge.INCOME.APPLICATION.ID == "29")
                    charge.INCOME.AMOUNT = this.$scope.model.COMMODITY_INVOICE_VALUE;

                if (charge.OUTCOME && charge.OUTCOME.APPLICATION && charge.OUTCOME.APPLICATION.ID == "29")
                    charge.OUTCOME.AMOUNT = this.$scope.model.COMMODITY_INVOICE_VALUE;

                return charge.OUTCOME.AMOUNT === this.$scope.model.COMMODITY_INVOICE_VALUE || charge.INCOME.AMOUNT === this.$scope.model.COMMODITY_INVOICE_VALUE;
            }) : [];

            for (const chargePair of chargesPair) this.updateProcessChargesBillOfSale(chargePair);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async updateProcessChargesBillOfSale(chargePair: ChargePair): Promise<void> {
        try {
            await this.operationalService.post(`/charge/pair/register`, {
                data: {
                    processNumber: this.$scope.model.PROCESS_NUMBER,
                    chargepair: chargePair
                },
                operation: "edit",
                timeout: 120000
            }, 120000);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async saveProcessTabMain(): Promise<boolean> {
        let success = true;
        const isInvalid = this.$newProcessScope.hasInvalidRequiredElements('collapseMain') || this.$newProcessScope.hasInvalidElements('collapseMain');
        if (isInvalid) return false;
        try {
            this.prepareBookingToSave();
            this.checkAndUpdateBookingStatusDate();
            this.$scope.model.INDICATION = null;
            this.formService.block();
            const result = await this.operationalService.post("/process/update", { data: this.$scope.model, processNumber: this.$scope.processNumber, timeout: 30000 }, 30000);
            const msg = this.formService.getTranslate('OPERATIONAL.MAIN_TAB_DATA_SUCESSFULLY_SAVED');
            if (result && result.data && result.data.data) {
                this.formService.notifySuccess(msg);
                this.$newProcessScope.updateGridRow(this.$newProcessScope.model.ID.toString());
                if (this.$scope.model.COMMODITY_INVOICE_VALUE) this.updateProcessChargesBillOfSaleAmount(this.$scope.model.PROCESS_NUMBER);
            } else success = false;
            this.$newProcessScope.model.BOOKING_STATUS = result.data.data.BOOKING_STATUS;
        } catch (ex) {
            success = false;
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            if (success) this.$scope.oldModel = angular.copy(this.$scope.model);
            else {
                this.$scope.model.BOOKING_STATUS_DATE = angular.copy(this.$scope.oldModel.BOOKING_STATUS_DATE);
                this.$newProcessScope.collapseState.nextState = null;
            }
            return success;
        }
    }

    private prepareBookingToSave(): void {
        if (this.$scope.model.IS_BOOKING_INTEGRATION && this.$scope.model.BOOKING_INTEGRATION && this.$scope.model.BOOKING_INTEGRATION.NAME.length) this.$scope.model.BOOKING = this.$scope.model.BOOKING_INTEGRATION.NAME;
    }

    private async compareProcess(): Promise<void> {
        try {
            //if (await this.permissionService.isRoleAllowed("PROCESSCOMPARE") === false) {
            //  this.permissionService.showBlockMessage();
            // return;
            //}

            const hasMarkedCheckValues = (this.$newProcessScope.model.AUDIT && this.$newProcessScope.model.AUDIT.CHECK_VALUE && this.$newProcessScope.model.AUDIT.CHECK_VALUE.STATUS === true);
            const msg = this.formService.getTranslate('OPERATIONAL.VALUE_CHECK_IS_PERFORMED_IT_IS_NOT_POSSIBLE_TO_COMPARE');
            if (hasMarkedCheckValues) return this.formService.handleWarning(msg);

            const modalID = this.modalService.newModal();
            this.modalService.showModalCompareProcess(
                {
                    modalID: modalID,
                    currentProcess: this.$newProcessScope.model,
                    origin: 'PROCESSVIEW',
                }
            );
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getFileUploaderDefault(): FileUploader {
        try {
            //const formData: IFormData = { bucket: 'newprocess_files', formName: this.formName, folder: this.formName };
            //return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData], onSuccessItem: (item: IFileItem, response: ISuccessItemResponse) => this.onSuccessItem(item, response) });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private checkAndUpdateBookingStatusDate() {
        if (this.$newProcessScope.hasChanges(this.$scope.model.BOOKING_STATUS, this.$scope.oldModel.BOOKING_STATUS)) {
            this.$scope.model.BOOKING_STATUS_DATE = new Date();
        }
    }

    private async openDifferencesModal(): Promise<void> {
        try {

            if (!this.$scope.model.BOOKING_INTEGRATION || !this.$scope.model.BOOKING_INTEGRATION.INTTRA_REFERENCE) {
                this.formService.notifyError('No booking found for this process.');
                return;
            }

            this.modalBookingDiffId = this.modalService.newModal();
            const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                {
                    modalID: this.modalBookingDiffId,
                    template: require("../view/modal/bookingDifferencesModal.html"),
                    formService: 'register',
                    size: 'full modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    actionButtonText: 'GENERAL.CANCEL',
                    headerText: `${this.formService.getTranslate('OPERATIONAL.BOOKING_VALIDATION')}: ${this.$newProcessScope.model.PROCESS_NUMBER ? this.$newProcessScope.model.PROCESS_NUMBER + ' | ' : ''} ${this.$scope.model.BOOKING_INTEGRATION.NAME} | ${this.$scope.model.BOOKING_INTEGRATION.INTTRA_REFERENCE}`
                },
                {
                    bookingId: parseInt(this.$scope.model.BOOKING_INTEGRATION.ID),
                    noOperation: true
                }
            );

            const modalScope = await this.modalService.getModalScope(this.modalBookingDiffId);
            await modalScope.$applyAsync();

            const apply = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (apply) {
                const msg = this.formService.getTranslate('OPERATIONAL.BOOKING_REGISTERED');
                this.formService.notifySuccess(msg);
                this.formService.block();
            }

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getLocalAgentListByName(search: string): Promise<IEntityAgent[]> {
        let result: IEntityAgent[] = [];
        try {
            if (!this.$newProcessScope.model.PRODUCT) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
            this.formService.block();

            const localAgent = await this.productService.post({ route: "/agent/list/custom/operational/localAgent", data: { products: [this.$newProcessScope.model.PRODUCT.ID], search: search } });
            if (localAgent && localAgent.data && localAgent.data.data && localAgent.data.data.data) {
                localAgent.data.data.data.forEach(item => {
                    if (!result.some(i => i.ID == item.ID)) result = result.concat(item);
                })
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getExternalAgentListByName(search: string): Promise<IEntityAgent[]> {
        let result: IEntityAgent[] = [];
        try {
            if (!this.$newProcessScope.model.PRODUCT) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
            this.formService.block();

            const externalAgent = await this.productService.post({ route: "/agent/list/custom/operational/externalAgent", data: { products: [this.$newProcessScope.model.PRODUCT.ID], search: search } });
            if (externalAgent && externalAgent.data && externalAgent.data.data && externalAgent.data.data.data) {
                externalAgent.data.data.data.forEach(item => {
                    if (!result.some(i => i.ID == item.ID)) result = result.concat(item);
                })
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getOtherAgentListByName(search: string): Promise<IEntityAgent[]> {
        let result: IEntityAgent[] = [];
        try {
            if (!this.$newProcessScope.model.PRODUCT) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
            this.formService.block();

            const otherAgent = await this.productService.post({ route: "/agent/list/custom/operational/otherAgent", data: { products: [this.$newProcessScope.model.PRODUCT.ID], search: search } });
            if (otherAgent && otherAgent.data && otherAgent.data.data && otherAgent.data.data.data) {
                otherAgent.data.data.data.forEach(item => {
                    if (!result.some(i => i.ID == item.ID)) result = result.concat(item);
                })
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getMoveTypeList(): Promise<ISelectorModel[]> {
        try {
            const request: IMonacoRequest = {
                route: `/moveType/list/full`,
                timeout: 30000,
            };

            const rc = await this.productService.get(request);
            const result = (rc && rc.data && rc.data.data) ? rc.data.data : null;

            const moveTypeList: ISelectorModel[] = [];
            for (const moveType of result) {
                moveTypeList.push({
                    ID: moveType.ID.toString(),
                    NAME: moveType.NAME,
                    CODE: moveType.CODE
                });
            }

            return moveTypeList;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getIncotermList(): Promise<ISelectorModel[]> {
        try {
            const request: IMonacoRequest = {
                data: null,
                route: `/incoterm/list`,
                timeout: 30000,
            };

            const rc = await this.productService.post(request);
            const result = (rc && rc.data && rc.data.data && rc.data.data.data) ? rc.data.data.data : null;

            const incotermList: ISelectorModel[] = [];
            for (const resultItem of result) {
                incotermList.push({
                    ID: resultItem.ID,
                    CODE: resultItem.INITIALS,
                    NAME: resultItem.NAME
                });
            }

            return incotermList;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async repurchaseProcess(): Promise<void> {
        try {

            const response = await this.dataProcessService.post('/repurchase/validate', { processNumber: this.$scope.processNumber }, 30000);

            if (this.$scope.model.ALLOWS_REPURCHASE && response.status === 200 && this.$newProcessScope.model.SITUATION.ID == '2' && this.$scope.permissionRepurchase) {
                const modalID = this.modalService.newModal();
                const modalInstance: IModalInstanceService = await this.modalService.showModalInfo(
                    {
                        template: require("../view/wizardRepurchase.html"),
                        modalID: modalID,
                        formService: EOperation.NEW,
                        size: 'vlg'
                    },
                    {
                        actionButtonText: "GENERAL.CLOSE",
                        headerText: 'GENERAL.REPURCHASE',
                    }, {
                    newProcessScope: this.$newProcessScope
                });

                const modalScope = await this.modalService.getModalScope(modalID);
                await modalScope.$applyAsync();

                const apply = await modalInstance.result.then(function (result) {
                    return result.$value;
                }, function (result) {
                    return result.$value;
                });

                if (apply) {
                    this.collapseMain();
                    await this.$newProcessScope.updateGridRow(this.$newProcessScope.model.ID.toString());
                    this.$newProcessScope.processConcatenated = this.$newProcessScope.generateProcessConcatenated();
                    this.$newProcessScope.processConcatenatedHtml = this.$newProcessScope.generateProcessConcatenated(true);
                    this.$newProcessScope.collapseState.nextState = ECollapseState.EDIT_PROCESS;
                    this.$newProcessScope.releaseCollapse();
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }
}

export class CompareUtils {


  private change: boolean = false;

  readonly VALUE_CREATED = "created";
  readonly VALUE_UPDATED = "updated";
  readonly VALUE_DELETED = "deleted";
  readonly VALUE_UNCHANGED = "unchanged";

  /**
   * @description Faz a diferença entre dois objetos de forma profunda
   * {@link https://stackoverflow.com/questions/8572826/generic-deep-diff-between-two-objects}
   *
   * @author sbgoran <> Algorítimo principal
   * @author Ríder Cantuária <rider.cantuaria> Adaptações ao TS e performance
   *
   * @param obj1 Ojeto principal
   * @param obj2 Ojeto secundário
   *
   * @returns {object}
   */
  public deepDiff(obj1: object, obj2: object): any {
    if (this.isFunction(obj1) || this.isFunction(obj2)) {
      throw "Invalid argument. Function given, object expected.";
    }
    if (this.isValue(obj1) || this.isValue(obj2)) {
      let type = this.compareValues(obj1, obj2);

      return {
        type: type,
        data: obj1 === undefined ? obj2 : obj1
      };
    }

    let diff = {};
    for (let key in obj1) {
      if (this.isFunction(obj1[key])) {
        continue;
      }

      let value2 = undefined;

      if ("undefined" != typeof obj2[key]) {
        value2 = obj2[key];
      }

      let result = this.deepDiff(obj1[key], value2);

      if (result) diff[key] = result;
    }

    for (let key in obj2) {
      if (this.isFunction(obj2[key]) || "undefined" != typeof diff[key]) {
        continue;
      }

      let result = this.deepDiff(undefined, obj2[key]);

      if (result) diff[key] = result;
    }

    return diff;
  }

  /**
   * @description Ajuda na comparação da model para criação de logs ou verificação de
   * @author Lucas Lunelli <lucas.lunneli>
   * @author Ríder Cantuária <rider.cantuaria>
   *
   * @param {object} oldObj
   * @param {object} newObj
   */
  public modelDeepDiff(oldObj, newObj) {
    let propsChange = new Array();

    if (oldObj) {

      for (let property in newObj) {
        if (
          JSON.stringify(newObj[property]) != JSON.stringify(oldObj[property])
        ) {
          propsChange.push(property);
        }
      }

    }

    let diffs = this.deepDiff(oldObj, newObj);

    let obj = new Array();

    for (let property in propsChange) {
      let result = this.findPath(diffs, propsChange[property]);
      result.field = propsChange[property];

      obj.push(result);
    }

    return obj;
  }

  /**
   * @description Busca o path informado em um objeto
   * {@link https://stackoverflow.com/questions/8817394/javascript-get-deep-value-from-object-by-passing-path-to-it-as-string}
   * @author Xueqiao Xu <http://xueqiaoxu.me>
   *
   * @param {object} obj
   * @param {string} path
   * 
   * @returns {object} 
   */
  private findPath(obj: object, path: string): any {
    let paths = path.split("."),
      current = obj,
      i;

    for (i = 0; i < paths.length; ++i) {
      if (current[paths[i]] == undefined) {
        return undefined;
      } else {
        current = current[paths[i]];
      }
    }

    return current;
  }

  private isFunction(obj: object): boolean {
    return {}.toString.apply(obj) === "[object Function]";
  }

  private isArray(obj: object): boolean {
    return {}.toString.apply(obj) === "[object Array]";
  }

  private isDate(obj: object): boolean {
    return {}.toString.apply(obj) === "[object Date]";
  }

  private isObject(obj: object): boolean {
    return {}.toString.apply(obj) === "[object Object]";
  }

  private isValue(obj: object): boolean {
    return !this.isObject(obj) && !this.isArray(obj);
  }


  private compareValues(value1: any, value2: any): string {

    if (value1 === value2) return this.VALUE_UNCHANGED;
    if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) return this.VALUE_UNCHANGED;

    this.change = true;

    if ("undefined" == typeof value1) return this.VALUE_CREATED;
    if ("undefined" == typeof value2) return this.VALUE_DELETED;

    return this.VALUE_UPDATED;

  }

  public hasChanged() {
    return this.change;
  }

  public static toArray(data: any | object): Array<any> {
    return Object.keys(data).map(function (key) { return data[key]; });
  }


  public deepFind(obj, path) {
    var paths = path.split('.')
      , current = obj
      , i;

    for (i = 0; i < paths.length; ++i) {
      if (current[paths[i]] == undefined) {
        return undefined;
      } else {
        current = current[paths[i]];
      }
    }
    return current;
  }
}

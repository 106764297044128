import * as angular from "angular";
import * as moment from "moment";
import { SelectorModel } from "../../common/model/SelectorModel";
import { IModalService } from "@services/ModalService";
import { HandleError } from "../../common/util/HandleError";
import { DateUtil } from "../../common/util/DateUtil";

export interface ISortPriority {
    sortName: string;
    sortOrder: string;
}

export interface IShowColumnsRename {
    dataField: string;
    title: string;
}

export interface IDateFilter {
    startDate: Date;
    endDate: Date;
    periodSelect: SelectorModel;
}

export interface ITableOptions extends BootstrapTableOptions {
    persistName: string;
    advancedSearch?: boolean;
    showLoading?: boolean;
    showMultiSort?: boolean;
    showMultiSortButton?: boolean;
    multiSortStrictSort?: boolean;
    sortPriority?: ISortPriority;
    filterControl?: boolean;
    valuesFilterControl?: IValuesFilterControl[];
    fixedColumns?: boolean;
    fixedNumber?: number;
    fixedRightNumber?: number;
    crudButtons?: ICrudButtons;
    customToolbarButtons?: IDataTableButton[];
    viewTariffAirFreight?: IDataTableButton;
    replaceDefaultBehavior?: boolean;
    classes?: any;
    showColumnsRename?: IShowColumnsRename[];
    singleSelect: boolean;
    showDateFilter?: boolean;
    dateFilter?: IDateFilter;
    formatAddLevel?: () => string;
    formatCancel?: () => string;
    formatColumn?: () => string;
    formatDeleteLevel?: () => string;
    formatMultipleSort?: () => string;
    formatOrder?: () => string;
    formatSort?: () => string;
    formatSortBy?: () => string;
    formatSortOrders?: () => any;//{ asc: () => string, desc: () => string };
    formatThenBy?: () => string;
    load?: (data?: any, callUncheckAll?: boolean) => void;
    onCheck?: (row?: any, $element?) => boolean;
    onPostBody?: (data?: any) => boolean;
    //onReorderColumn?: (param) => void; * use after ticket resolution.
    //reorderableColumns?: boolean; * use after ticket resolution.
}

export interface IValuesFilterControl {
    field: string;
    value: string;
    position: number;
    hasFocus: boolean;
}

interface ITableState {
    hiddenColumns: string[];
    pagination: boolean;
    pageNumber: number;
    pageSize: number;
    pageList: number[];
    searchText: string;
    sortOrder: string;
    sortName: string;
    sortPriority: ISortPriority;
    //columnsOrder: string[]; * use after ticket resolution.
    valuesFilterControl: IValuesFilterControl[];
    dateFilter: IDateFilter;
}

export interface ICrudButtons {
    add?: ICrudButton;
    edit?: ICrudButton;
    copy?: ICrudButton;
    remove?: ICrudButton;
    validityRoute?: ICrudButton;
    scob?: ICrudButton;
}

interface ICrudButton extends IDataTableButton {
    disabled?: boolean;
    fn: (index?: number, displayIndex?: number) => Promise<void>;
}

export interface IDataTableButton {
    name: string;
    label?: string;
    disabled?: boolean;
    title?: string;
    icon?: string;
    class?: string;
    fn: () => void;
}

interface IProfile {
    selectedProfile: SelectorModel;
    profileList: SelectorModel[];
}

interface IMonacoDataTableScope extends ng.IScope {
    tableId: string;
    tableOptions: ITableOptions;
    data: any;
    selectedIndex: number;
    selectedDisplayIndex: number;
    hasSelected: boolean;
    profile: IProfile;
    currentState: ITableState;
    useDefaultToolbar: boolean;
    periodSelectList: SelectorModel[];
    showDateFilter: boolean;
    addProfileModal: () => Promise<void>;
    addProfile: (profileName: string, modalContext) => Promise<void>;
    removeProfile: (profileName: string) => Promise<void>;
    saveProfile: (profileName: string) => Promise<void>;
    loadProfile: (profile: object) => Promise<void>;
    filterTableDate: () => void;
}

monacoDataTable.$inject = ['$rootScope', '$timeout', 'ModalService', 'blockUI', '$compile', '$filter']
export function monacoDataTable($rootScope, $timeout, ModalService: IModalService, blockUI: ng.blockUI.BlockUIService, $compile: angular.ICompileService, $filter: ng.IFilterService): ng.IDirective {
    const ddo: ng.IDirective = {
        restrict: 'E',
        transclude: false,
        replace: false,
        scope: {
            tableId: '@',
            tableOptions: '=',
            data: '='
        },
        template: require("../view/template/monaco-data-table.html"),
        controller: ["$scope", "$element", "$attrs", async function ($scope: IMonacoDataTableScope, element: JQLite, attributes: ng.IAttributes) {            

            //let currentColumnsOrder: string[] = []; * use after ticket resolution.
            const useDefaultBehavior: boolean = !$scope.tableOptions || ($scope.tableOptions && !$scope.tableOptions.replaceDefaultBehavior);
            const generateUniqueIds: boolean = !angular.isDefined($scope.tableOptions.uniqueId);
            $scope.tableOptions.uniqueId = generateUniqueIds ? "uniqueIdControl" : $scope.tableOptions.uniqueId;

            $scope.periodSelectList = DateUtil.getDateValuePeriod();
            $scope.profile = { selectedProfile: null, profileList: [] };
            $scope.useDefaultToolbar = true;
            if ($scope.tableOptions && $scope.tableOptions.toolbar) $scope.useDefaultToolbar = false;
            $scope.tableOptions.toolbar = $scope.tableOptions.toolbar ? $scope.tableOptions.toolbar : `.${$scope.tableId}-toolbar`;
            if ($scope.useDefaultToolbar) {
                $scope.tableOptions.toolbarAlign = 'right';
                $scope.tableOptions.searchAlign = ($scope.tableOptions.showDateFilter) ? 'right' : 'left';
                $scope.tableOptions.buttonsAlign = "left";
            }

            if ($scope.tableOptions && $scope.tableOptions.persistName) await loadProfileList($scope.tableOptions.persistName);
            else HandleError.exception('Você precisa informar o atributo persistName no tableOptions enviado para a diretiva!');

            $scope.addProfileModal = async () => {
                await addProfileModal();
            };

            $scope.addProfile = async (name, modalContext) => {
                await addNewProfile(name, modalContext);
            };

            $scope.removeProfile = async (name) => {
                await removeProfile(name);
            }

            $scope.saveProfile = async (name) => {
                await saveProfile(name);
            }

            $scope.loadProfile = async (selectedProfile) => {
                await loadProfileData(selectedProfile);
            };

            $scope.filterTableDate = () => {
                filterTableDate();
            }

            const load = $scope.tableOptions.load;
            $scope.tableOptions.load = (data, callUncheckAll) => {
                if (generateUniqueIds) {
                    if (data && data.length) {
                        let uniqueIdSequence = 0;
                        data = data.map(obj => { obj[$scope.tableOptions.uniqueId] = uniqueIdSequence; uniqueIdSequence++; return obj; });
                    } else if ($scope.data && $scope.data.length) {
                        let uniqueIdSequence = 0;
                        $scope.data = $scope.data.map(obj => { obj[$scope.tableOptions.uniqueId] = uniqueIdSequence; uniqueIdSequence++; return obj; });
                    }
                }
                $timeout(() => {
                    if (load) load(data);
                    const table = angular.element("#" + $scope.tableId);
                    table.bootstrapTable('load', data ? data : $scope.data);
                    if (callUncheckAll) table.bootstrapTable('uncheckAll');
                    if ($scope.profile.selectedProfile) $scope.loadProfile($scope.profile.selectedProfile);
                }, 500);
            };

            const onCheck = $scope.tableOptions.onCheck;
            $scope.tableOptions.onCheck = (row, element) => {
                if (onCheck) onCheck(row, element);
                if (useDefaultBehavior) {
                    $scope.selectedIndex = row[$scope.tableOptions.uniqueId];
                    $scope.selectedDisplayIndex = element && element.length > 0 && element[0].dataset ? parseInt(element[0].dataset.index) : null;
                    $scope.hasSelected = true;
                    $timeout(() => {
                        $scope.$apply(() => $scope.hasSelected = true);
                    });
                }
                return true;
            };
            const onUncheck = $scope.tableOptions.onUncheck;
            $scope.tableOptions.onUncheck = (row) => {
                if (onUncheck) onUncheck(row);
                if (useDefaultBehavior) {
                    $scope.selectedIndex = null;
                    $timeout(() => {
                        $scope.$apply(() => $scope.hasSelected = false);
                    });
                }

                if (!$scope.tableOptions.singleSelect) {
                    const table = angular.element("#" + $scope.tableId);
                    var selectedRows = table.bootstrapTable('getSelections');

                    if (selectedRows.length === 1) table.bootstrapTable('uncheckAll');
                    for (var i = 0; i < selectedRows.length; i++) {
                        var row = selectedRows[i];
                        table.bootstrapTable('check', row.uniqueIdControl, true);
                    }
                }

                return true;
            };
            const onUncheckAll = $scope.tableOptions.onUncheckAll;
            $scope.tableOptions.onUncheckAll = (rows) => {
                if (onUncheckAll) onUncheckAll(rows);
                if (useDefaultBehavior) {
                    $scope.selectedIndex = null;
                    $timeout(() => {
                        $scope.$apply(() => $scope.hasSelected = false);
                    });
                }
                return true;
            };
            const onDblClickRow = $scope.tableOptions.onDblClickRow;
            $scope.tableOptions.onDblClickRow = (row, element) => {
                if (onDblClickRow) onDblClickRow(row, element);
                if (useDefaultBehavior) {
                    if ($scope.tableOptions.crudButtons && $scope.tableOptions.crudButtons.edit) $scope.tableOptions.crudButtons.edit.fn(row[$scope.tableOptions.uniqueId], element.index());
                }
                return true;
            };

            const onPostBody = $scope.tableOptions.onPostBody;
            $scope.tableOptions.onPostBody = (data) => {
                applyFixedTableWorkaround();
                if (onPostBody) onPostBody(data);
                $compile(angular.element("#" + $scope.tableId))($scope);
                if ($scope.tableOptions.showColumnsRename) {
                    for (const columnToRename of $scope.tableOptions.showColumnsRename) angular.element('.columns [data-field="' + columnToRename.dataField + '"]').next().text(columnToRename.title);
                }
                return true;
            };

            /* use after ticket resolution.
            const onReorderColumn = $scope.tableOptions.onReorderColumn;
            $scope.tableOptions.onReorderColumn = (columnsOrder) => {
                if (onReorderColumn) onReorderColumn(columnsOrder);
                currentColumnsOrder = columnsOrder;
            };
            */

            $scope.$watch('data', (newValue: any[]) => {
                if (!$scope.profile.selectedProfile) $scope.currentState = buildCurrentState();
                const table = angular.element("#" + $scope.tableId);
                table.bootstrapTable('destroy');
                if (newValue) {
                    if (generateUniqueIds) {
                        let uniqueIdSequence = 0;
                        newValue = newValue.map(obj => { obj[$scope.tableOptions.uniqueId] = uniqueIdSequence; uniqueIdSequence++; return obj; });
                    }
                    if ($scope.currentState) {
                        $scope.tableOptions.pageNumber = $scope.currentState.pageNumber;
                        $scope.tableOptions.pageSize = $scope.currentState.pageSize;
                        $scope.tableOptions.pageList = $scope.currentState.pageList;
                        $scope.tableOptions.pagination = $scope.currentState.pagination;
                        $scope.tableOptions.searchText = $scope.currentState.searchText;
                        $scope.tableOptions.sortName = $scope.currentState.sortName;
                        $scope.tableOptions.sortOrder = $scope.currentState.sortOrder;
                        $scope.tableOptions.valuesFilterControl = $scope.currentState.valuesFilterControl;
                        $scope.tableOptions.dateFilter = $scope.currentState.dateFilter;
                    }
                    table.bootstrapTable($scope.tableOptions);
                    table.bootstrapTable('load', newValue);
                    if ($scope.currentState) {
                        if ($scope.currentState.hiddenColumns && $scope.currentState.hiddenColumns.length) {
                            table.bootstrapTable('hideColumn', $scope.currentState.hiddenColumns);
                        }
                        /* Use after ticket resolution https://github.com/wenzhixin/bootstrap-table/issues/5243                            
                        const orderColumnsObj = {};
                        if ($scope.currentState.columnsOrder) {
                            $scope.currentState.columnsOrder.forEach((key, index) => {
                                orderColumnsObj[key] = index;
                            });
                            $timeout(() => { table.bootstrapTable('orderColumns', orderColumnsObj); }, 500);
                        }
                        */
                    }
                }
            });

            // Necessary to fix fixed-table bug that duplicate the header of table
            function applyFixedTableWorkaround() {
                const nativeElement = element[0];
                const fixedTableHeader = nativeElement.querySelector('.fixed-table-header');
                const fixedTableBody = nativeElement.querySelector('.fixed-table-body');

                if (fixedTableHeader && window.getComputedStyle(fixedTableHeader).display === 'block') {
                    fixedTableBody.classList.add('workaround-fixed-table');
                }
            }

            function buildCurrentState(): ITableState {
                const $table = angular.element("#" + $scope.tableId);
                let options: ITableOptions = $table.bootstrapTable('getOptions');
                const hiddenColumns: BootstrapTableColumn[] = $table.bootstrapTable('getHiddenColumns');
                return {
                    hiddenColumns: hiddenColumns ? hiddenColumns.map(column => column.field) : [],
                    pageNumber: options.pageNumber ? options.pageNumber : $scope.tableOptions.pageNumber,
                    pageSize: options.pageSize ? options.pageSize : $scope.tableOptions.pageSize,
                    pageList: options.pageList ? options.pageList : $scope.tableOptions.pageList,
                    pagination: options.pagination ? options.pagination : $scope.tableOptions.pagination,
                    searchText: options.searchText ? options.searchText : $scope.tableOptions.searchText,
                    sortName: options.sortName ? options.sortName : $scope.tableOptions.sortName,
                    sortOrder: options.sortOrder ? options.sortOrder : $scope.tableOptions.sortOrder,
                    sortPriority: options.sortPriority ? options.sortPriority : $scope.tableOptions.sortPriority,
                    //columnsOrder: currentColumnsOrder, * use after ticket resolution.
                    valuesFilterControl: options.valuesFilterControl,
                    dateFilter: options.dateFilter
                }
            }

            async function addProfileModal(): Promise<void> {
                await ModalService.showModalInfo(
                    {
                        template: require("../view/template/monaco-data-table-profile-modal.html"),
                        scope: $scope, //passed current scope to the modal
                    },
                    {
                        actionButtonText: 'Fechar',
                        headerText: 'Adicionar Perfil',
                    });
            }

            async function addNewProfile(profileName: string, modalContext): Promise<void> {
                try {

                    if (!profileName) return;

                    blockUI.start();

                    //save the new profile on the db
                    $scope.currentState = buildCurrentState();
                    await saveProfileSetting(profileName, $scope.currentState);
                    const newProfileIndex = $scope.profile.profileList.length + 1;
                    $scope.profile.profileList.push({ ID: newProfileIndex.toString(), NAME: profileName });
                    $scope.profile.selectedProfile = $scope.profile.profileList[newProfileIndex - 1];

                    //close the modal
                    modalContext.ok();

                    blockUI.stop();

                } catch (ex) {
                    blockUI.reset();
                    HandleError.exception(ex);
                }
            }

            async function removeProfile(profileName: string): Promise<void> {
                try {
                    if (!profileName) return;

                    const checked = await ModalService.showModalConfirmation({}, {
                        actionButtonText: 'GENERAL.CONFIRM',
                        headerText: 'GENERAL.CONFIRM_ACTION',
                        bodyText: `${$filter('translate')('GENERAL.PROFILE_REMOVE_CUSTOM', { profileName })}`
                    });
                    if (checked) {
                        const removed = await $rootScope.removeSetting($scope.tableOptions.persistName, profileName);
                        if (!removed) return HandleError.exception(`Failed to remove data table profile: ${profileName}`);

                        //load the last profile of the list                        
                        const profileIndex = $scope.profile.profileList.findIndex(profile => profile.NAME === profileName);
                        if ($scope.profile.profileList && $scope.profile.profileList.length > 0) $scope.profile.profileList.splice(profileIndex, 1);
                        const lastProfileIndex = ($scope.profile.profileList.length > 0) ? ($scope.profile.profileList.length - 1) : null;
                        if (lastProfileIndex != null) {
                            $scope.profile.selectedProfile = angular.copy($scope.profile.profileList[lastProfileIndex]);
                        } else $scope.profile.selectedProfile = null;

                        await loadProfileData($scope.profile.selectedProfile);
                    }

                } catch (ex) {
                    HandleError.exception(ex);
                }
            }

            async function saveProfile(profileName: string): Promise<void> {
                try {
                    if (!profileName) throw new Error('profileName is NULL');

                    const profileData = buildCurrentState();
                    await saveProfileSetting(profileName, profileData);

                } catch (ex) {
                    HandleError.exception(ex);
                }
            }

            async function loadProfileList(settingId: string) {
                const profileList = await $rootScope.getSetting(settingId);
                const profileListKeys = profileList ? Object.keys(profileList) : null;
                if (profileListKeys && profileListKeys.length > 0) {

                    $scope.profile.profileList = [];

                    //populate profile list selector
                    for (let i = 0; i < profileListKeys.length; i++) {
                        $scope.profile.profileList.push({ ID: (i + 1).toString(), NAME: profileListKeys[i] });
                    }

                    //select the last profile on the list
                    const lastProfileIndex = ($scope.profile.profileList.length > 0) ? ($scope.profile.profileList.length - 1) : null;
                    const lastProfile = (lastProfileIndex != null) ? $scope.profile.profileList[lastProfileIndex] : null;
                    if (lastProfile && profileList[lastProfile.NAME]) {
                        await loadProfileData(lastProfile);
                        $scope.profile.selectedProfile = lastProfile;
                    }
                }
            }

            async function loadProfileData(profile: object): Promise<void> {
                try {
                    if (profile) {
                        const profileName = profile['NAME'];
                        const loadedProfile = await $rootScope.getSetting($scope.tableOptions.persistName, profileName);
                        if (loadedProfile && loadedProfile[profileName]) $scope.currentState = loadedProfile[profileName];
                    } else {
                        $scope.currentState = {
                            hiddenColumns: [], pagination: $scope.tableOptions.pagination,
                            pageNumber: $scope.tableOptions.pageNumber,
                            pageSize: $scope.tableOptions.pageSize,
                            pageList: $scope.tableOptions.pageList,
                            searchText: $scope.tableOptions.searchText,
                            sortOrder: $scope.tableOptions.sortOrder,
                            sortName: $scope.tableOptions.sortName,
                            sortPriority: $scope.tableOptions.sortPriority,
                            //columnsOrder: currentColumnsOrder, * use after ticket resolution.
                            valuesFilterControl: $scope.tableOptions.valuesFilterControl,
                            dateFilter: $scope.tableOptions.dateFilter
                        };
                    }
                    const $table = angular.element("#" + $scope.tableId);
                    const columns = $scope.tableOptions.columns;
                    $scope.currentState.hiddenColumns = $scope.currentState.hiddenColumns.length ? $scope.currentState.hiddenColumns.filter(hiddenColumn => columns.some(column => column && column.field === hiddenColumn)) : [];
                    for (const column of columns) {
                        column.visible = !($scope.currentState.hiddenColumns && $scope.currentState.hiddenColumns.findIndex(hiddenColumn => hiddenColumn == column.field) >= 0);
                    }
                    $table.bootstrapTable('refreshOptions', {
                        columns: columns,
                        pageNumber: $scope.currentState.pageNumber,
                        pageSize: $scope.currentState.pageSize,
                        pageList: $scope.currentState.pageList,
                        pagination: $scope.currentState.pagination,
                        searchText: $scope.currentState.searchText,
                        sortName: $scope.currentState.sortName,
                        sortOrder: $scope.currentState.sortOrder,
                        sortPriority: $scope.currentState.sortPriority,
                        valuesFilterControl: $scope.currentState.valuesFilterControl,
                        dateFilter: $scope.currentState.dateFilter
                    });
                    if ($scope.currentState.hiddenColumns && $scope.currentState.hiddenColumns.length > 0) $table.bootstrapTable('hideColumn', $scope.currentState.hiddenColumns);
                    if ($scope.currentState.dateFilter) filterTableDate();
                    /* Use after ticket resolution https://github.com/wenzhixin/bootstrap-table/issues/5243.                    
                    const orderColumnsObj = {};
                    if ($scope.currentState.columnsOrder) {
                        $scope.currentState.columnsOrder.forEach((key, index) => {
                            orderColumnsObj[key] = index;
                        });
                        $timeout(() => { $table.bootstrapTable('orderColumns', orderColumnsObj); }, 500);
                    }
                    */
                } catch (ex) {
                    HandleError.exception(ex);
                }
            }

            async function saveProfileSetting(profileName: string, profileData: any): Promise<void> {
                try {
                    if (!profileName) throw new Error('profileName is NULL');
                    if (!profileData) throw new Error('profileData is NULL');

                    const saved = await $rootScope.updateSetting($scope.tableOptions.persistName, profileData, profileName);
                    if (!saved) return HandleError.exception(`Failed to save monaco data table profile: ${profileName}`);

                    //update the current state                    
                    $scope.currentState = profileData;

                } catch (ex) {
                    HandleError.exception(ex);
                }
            }

            function filterTableDate() {
                try {
                    if (!$scope.tableOptions) return
                    if (!$scope.tableOptions.dateFilter) return

                    const $table = angular.element("#" + $scope.tableId);
                    $table.bootstrapTable('refreshOptions', {
                        filterOptions: {
                            filterAlgorithm: 'and'
                        }
                    });

                    if ($scope.tableOptions.dateFilter.periodSelect) {
                        const periodSelect = DateUtil.getDateFunctionPeriod(new Date(), $scope.tableOptions.dateFilter.periodSelect);

                        $scope.tableOptions.dateFilter.startDate = periodSelect.startDate;
                        $scope.tableOptions.dateFilter.endDate = periodSelect.endDate;
                    }

                    if (!$scope.tableOptions.dateFilter.startDate && !$scope.tableOptions.dateFilter.endDate) {
                        return $table.bootstrapTable('load', $scope.data);
                    }

                    const filterDate = $scope.data.filter((item) => {
                        const endDate = ($scope.tableOptions.dateFilter.endDate) ? new Date($scope.tableOptions.dateFilter.endDate) : moment(new Date()).year(2200).toDate();
                        const startDate = ($scope.tableOptions.dateFilter.startDate) ? new Date($scope.tableOptions.dateFilter.startDate) : moment(new Date()).year(1980).toDate();

                        const validityStart = DateUtil.dateIsBetween(new Date(item.PAYMENT_CHARGE.VALIDITY_START), startDate, endDate);
                        const validityEnd = DateUtil.dateIsBetween(new Date(item.PAYMENT_CHARGE.VALIDITY_END), startDate, endDate);

                        return validityStart && validityEnd;
                    });

                    $table.bootstrapTable('load', filterDate);
                } catch (ex) {
                    HandleError.exception(ex);
                }
            }
        }]
    }
    return ddo;
}
import angular = require('angular');
import { FormService2, IFormServiceScope } from '@services/FormService2';
import { IModalService } from '@services/ModalService';
import { IRestService } from "@services/RestService";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IMoveTypeModel } from '@models/interface/product/MoveTypeModel';
import { IOfferEventEvent, IOfferEventService, ILegs } from '@models/interface/product/OfferEvent';
import { ITransshipment, IProductConfigurationModel } from '@models/interface/product/ProductConfigurationModel';
import { IRoutingPointListCustomFilter } from '@models/interface/product/RoutingPointModel';
import { ERouteEventType } from '@enums/MoveTypeModel';
import { EOfferLogsName } from '@enums/Offer';
import { EProductId, ETypeLocalId, EDataOriginTypeId, EProviderTypeId, EOperation } from '@enums/GenericData';
import { IOfferEventModel } from '../../commercial/model/OfferModel';
import { IOfferScope, ECollapseState } from '../../commercial/controller/OfferRegisterController';
import { SelectorModel } from '../../common/model/SelectorModel';
import { HelperService } from "@services/HelperService";

interface IOfferEventModalScope extends IFormServiceScope {
    event: IOfferEventEvent;
    oldEvent: IOfferEventEvent;
    operation: string;
    isAirService: boolean;
    isMaritimeService: boolean;
    isRoadService: boolean;
    isTransshipment: boolean;
    isDomesticOrigin: boolean;
    isDomesticDestination: boolean;
    providerList: SelectorModel[];
    routingPointList: SelectorModel[];
    logisticSolutionList: SelectorModel[];
    logisticSolutionDisabledControl: boolean;
    applyEvent: () => void;
    closeEventModal: () => Promise<void>;
    getLogisticSolutionList: () => Promise<void>;
    getProviderListByName: (search: string) => Promise<void>;
    getRoutingPointListByName: (search: string) => Promise<void>;
}

interface IOfferTransitTimeDetailModalScope {
    service: IOfferEventService;
    oldService: IOfferEventService;
    isMaritimeService: boolean;
    operation: string;
    applyTransitTimeDetail: () => void;
    closeTransitTimeDetailModal: () => Promise<void>;
}

interface IOfferEventScope extends ng.IScope {
    log: IViewLog;
    model: IOfferEventModel;
    oldModel: IOfferEventModel;
    dataOriginTypeList: SelectorModel[];
    moveTypeParams: IMoveTypeModel;
    operation: string;
    index: number;
    typeLocalList: SelectorModel[];
    localList: SelectorModel[];
    moveList: SelectorModel[];
    transitTimeList: ILegs[];
    customLogProperties: ICustomLogProperties[];
    addEvent: (index: number) => void;
    editEvent: (index: number, event: IOfferEventEvent) => void;
    viewEvent: (index: number, event: IOfferEventEvent) => void;
    copyEvent: (index: number, event: IOfferEventEvent) => void;
    removeEvent: (index: number, uuid: string) => void;
    openTransitTimeDetails: (service: IOfferEventService, operation: string) => void;
    collapseEvents: () => void;
    viewLog: (uuid?: string) => void;
    serviceActiveChange: (uuid: string, active: boolean) => Promise<void>;
    isPolAolTs: (eventCode: string) => boolean;
    viewLogTransitTime: (service: IOfferEventService) => void;
    isCollapseIn(): boolean;
    isAirProduct: () => boolean;
    isTypeEvent: (operation: string, events: IOfferEventEvent) => boolean;
}

export class OfferEventRegisterController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IOfferEventScope;
    private $offerScope: IOfferScope;
    private $q: ng.IQService;
    private restService: IRestService;
    private formService: FormService2;
    private modalService: IModalService;
    private modalEventId: number;
    private modalServiceId: number;
    private productTransshipment: ITransshipment;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IOfferEventScope) {
        this.$scope = $scope;
        this.$offerScope = <IOfferScope>$scope.$parent.$parent;
        this.modalService = this.$offerScope.modalService;
        this.$q = $injector.get('$q');
        this.restService = $injector.get('RestService');
        this.formService = this.$offerScope.formService;
        this.initScopeFunctions();
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.initModel();
            await this.initDependencies();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initModel(): void {
        this.$scope.model = {
            ID_OFFER: null,
            EVENTS: null,
            SERVICES: null
        };
    }

    private initScopeFunctions(): void {
        this.$scope.collapseEvents = () => {
            this.collapseEvents();
        }

        this.$scope.addEvent = (index: number) => {
            this.addEvent(index);
        }

        this.$scope.editEvent = (index: number, event: IOfferEventEvent) => {
            this.editEvent(index, event);
        }

        this.$scope.viewEvent = (index: number, event: IOfferEventEvent): void => {
            this.viewEvent(index, event);
        }

        this.$scope.removeEvent = (index: number, uuid: string): void => {
            this.removeEvent(index, uuid);
        }

        this.$scope.openTransitTimeDetails = (service: IOfferEventService, operation: string) => {
            this.openTransitTimeDetails(service, operation);
        }

        this.$scope.serviceActiveChange = async (uuid: string, active: boolean) => {
            this.updateTransitTimeService(uuid, active);
        }

        this.$scope.isPolAolTs = (eventCode: string): boolean => {
            return eventCode == ERouteEventType.PICK_UP || eventCode == ERouteEventType.PORT_OF_LOAD || eventCode == ERouteEventType.AIRPORT_OF_ORIGIN || eventCode == ERouteEventType.TRANSSHIPMENT || (this.productTransshipment && eventCode == this.productTransshipment.INITIALS);
        }

        this.$scope.viewLog = (uuid?: string) => {
            this.viewLog(uuid);
        }

        this.$scope.viewLogTransitTime = (service: IOfferEventService): void => {
            this.viewLogTransitTime(service);
        }

        this.$scope.isCollapseIn = (): boolean => {
            return this.$offerScope.collapseState.panel == ECollapseState.EVENTS && !this.$offerScope.collapseState.released;
        }

        this.$scope.isAirProduct = (): boolean => {
            return this.$offerScope.model.PRODUCT && (this.$offerScope.model.PRODUCT.ID == EProductId.AIR_IMPORT || this.$offerScope.model.PRODUCT.ID == EProductId.AIR_EXPORT);
        }

        this.$scope.isTypeEvent = (operation: string, event: IOfferEventEvent): boolean => {
            return this.isTypeEvent(operation, event);
        }
    }

    async initDependencies(): Promise<any> {
        const self: OfferEventRegisterController = this;

        this.initCollapseEvents();

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericValue("data_origin_type"),
                self.getGenericValue("type_local_event"),
                self.getGenericValue("locals_group"),
                self.getGenericValue("process_event_transport_mode"),
            ]).then(async (result: any) => {
                self.$scope.dataOriginTypeList = result[0];
                self.$scope.typeLocalList = result[1];
                self.$scope.localList = result[2];
                self.$scope.moveList = result[3];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private initCollapseEvents() {
        this.$scope.$on('offerEventCollapse', () => {
            this.collapseEvents();
        });

        const collapseEvents = angular.element("#collapseEvents");
        if (collapseEvents) {
            collapseEvents.on('shown.bs.collapse', (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseEventHeading").attr('aria-expanded', 'true');
                    // update collapse state
                    this.$offerScope.collapseState = { panel: ECollapseState.EVENTS, released: false, nextState: null };
                    this.$offerScope.repositionPanels('eventsRow', true);
                    this.getOfferTabsEvent();
                }
            });
            collapseEvents.on('hidden.bs.collapse', async (event: JQuery.Event) => {
                if (event.target == event.currentTarget) {
                    angular.element("#collapseEventHeading").attr('aria-expanded', 'false');
                }
            });
        }
    }

    private async getGenericValue(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async getProductConfigurationTransshipmentList(): Promise<ITransshipment[]> {
        let transshipment: ITransshipment[] = [];
        try {
            this.formService.block();
            const operation = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/productConfiguration/list`, {}, 30000, false);
            if (operation && operation.data && operation.data.length) {
                const productConfiguration: IProductConfigurationModel = operation.data[0];
                transshipment = productConfiguration.TRANSSHIPMENT;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return transshipment
        }
    }

    private async getOfferProvider(): Promise<SelectorModel> {
        let provider: SelectorModel = null;
        try {
            this.formService.block();
            const operation = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/event/provider/${this.$offerScope.model.ID}`, 30000, null, false);
            if (operation && operation.data) provider = operation.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return provider;
        }
    }

    private async getOfferMovement(): Promise<SelectorModel> {
        let movement: SelectorModel = null;
        try {
            this.formService.block();
            const operation = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/event/movement/${this.$offerScope.model.ID}`, 30000, null, false);
            if (operation && operation.data) movement = operation.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return movement;
        }
    }

    private async getLogisticSolutionList(): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.formService.block();
            const solutionList = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/event/logisticsolution/${this.$offerScope.model.ID}/${this.$offerScope.model.PRODUCT.ID}`, 30000, null, false);
            if (solutionList && solutionList.data) result = solutionList.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async openTransitTimeDetails(service: IOfferEventService, operation: string): Promise<void> {
        if (!service) return;

        this.$offerScope.lastScroll = $('.app-content-body').scrollTop();
        this.modalServiceId = this.modalService.newModal();
        this.modalService.showModalInfo(
            {
                modalID: this.modalServiceId,
                scope: this.$scope,
                formService: operation,
                template: require("../view/modals/offerTransitTimeDetailModal.html"),
                size: 'lg'
            },
            {
                headerText: "GENERAL.TRANSIT_TIME"
            }
        );
        await this.buildTransitTimeDetailModalScope(service, operation);
    }

    private async buildTransitTimeDetailModalScope(service: IOfferEventService, operation: string): Promise<IOfferTransitTimeDetailModalScope> {
        const modalScope: IOfferTransitTimeDetailModalScope = await this.modalService.getModalScope(this.modalServiceId);
        const productId = this.$offerScope.model.PRODUCT ? this.$offerScope.model.PRODUCT.ID : null;
        modalScope.operation = operation;
        modalScope.service = angular.copy(service);
        modalScope.oldService = angular.copy(service);
        modalScope.isMaritimeService = (productId == EProductId.MARITIME_EXPORT || productId == EProductId.MARITIME_IMPORT);

        modalScope.closeTransitTimeDetailModal = async (): Promise<void> => {
            if (this.$offerScope.hasChanges(modalScope.service, modalScope.oldService)) {
                const confirm = await this.$offerScope.modalSaveConfirmation("GENERAL.CLOSE", "GENERAL.CLOSE");
                if (confirm) {
                    if (!this.$offerScope.hasInvalidRequiredElements("modalTransitTimeDetailForm")) this.applyTransitTimeDetail(modalScope.service);
                    else return;
                }
            }
            this.modalService.closeModal(this.modalServiceId);
            this.modalServiceId = 0;
            $('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
        }

        modalScope.applyTransitTimeDetail = () => {
            if (!this.$offerScope.hasInvalidRequiredElements("modalTransitTimeDetailForm")) this.applyTransitTimeDetail(modalScope.service);
        };

        return modalScope;
    }

    private async updateTransitTimeService(uuid: string, active: boolean) {
        try {
            if (!uuid) throw Error("uuid is null");
            const result = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/event/update/service`, { UUID: uuid, offerId: this.$offerScope.model.ID, active: active }, 30000, false);
            if (result) {
                const msgSuccess = this.formService.getTranslate('GENERAL.TRANSIT_TIME_DETAILS_UPDATED_SUCCESSFULLY');
                this.formService.notifySuccess(msgSuccess);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async collapseEvents() {
        try {
            if (this.$offerScope.collapseState.released || this.$offerScope.collapseState.panel == ECollapseState.EVENTS) {
                const collapseEvents = angular.element("#collapseEvents");
                if (collapseEvents) {
                    const isCollapsed = angular.element("#collapseEventHeading").attr("aria-expanded") == "true";
                    if (isCollapsed) {
                        angular.element("#eventsLog").hide();
                        this.$offerScope.collapseState.released = true;
                    }
                    else if (!this.productTransshipment) {
                        const productId = this.$offerScope.model.PRODUCT ? this.$offerScope.model.PRODUCT.ID : null;
                        const transshipment: ITransshipment[] = await this.getProductConfigurationTransshipmentList();
                        this.productTransshipment = transshipment && transshipment.length ? transshipment.find(obj => obj.PRODUCT.findIndex(y => y.ID == productId) >= 0) : null;
                    }
                    collapseEvents['collapse']('toggle');
                    if (isCollapsed) this.$offerScope.repositionPanels('eventsRow');
                    else angular.element("#eventsLog").show();
                }
            } else {
                this.$offerScope.collapseState.nextState = ECollapseState.EVENTS;
                this.$offerScope.releaseCollapse();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getOfferTabsEvent(): Promise<void> {
        this.formService.block();
        try {
            const eventTab = await this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/event/${this.$offerScope.operation}/${this.$offerScope.model.ID}`, 30000, null, false);
            if (eventTab && eventTab.data) this.$scope.model = eventTab.data;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.oldModel = angular.copy(this.$scope.model);
            this.formService.unblock();
        }
    }

    private async buildEventModalScope(event: IOfferEventEvent, index: number, productId: string, operation: string): Promise<IOfferEventModalScope> {
        const modalScope: IOfferEventModalScope = await this.modalService.getModalScope(this.modalEventId);
        modalScope.operation = operation;
        modalScope.event = angular.copy(event);
        modalScope.oldEvent = angular.copy(event);
        modalScope.isAirService = (productId == EProductId.AIR_EXPORT || productId == EProductId.AIR_IMPORT);
        modalScope.isMaritimeService = (productId == EProductId.MARITIME_EXPORT || productId == EProductId.MARITIME_IMPORT);
        modalScope.isRoadService = (productId == EProductId.ROAD_EXPORT || productId == EProductId.ROAD_IMPORT || productId == EProductId.ROAD_NATIONAL);
        modalScope.isTransshipment = event.CODE && (event.CODE == ERouteEventType.TRANSSHIPMENT || (this.productTransshipment && event.CODE == this.productTransshipment.INITIALS));
        modalScope.isDomesticOrigin = modalScope.event.SERVICE_DOMESTIC_ORIGIN && modalScope.event.SERVICE_DOMESTIC_ORIGIN.length > 0;
        modalScope.isDomesticDestination = modalScope.event.SERVICE_DOMESTIC_DESTINATION && modalScope.event.SERVICE_DOMESTIC_DESTINATION.length > 0;
        modalScope.logisticSolutionDisabledControl = operation == EOperation.VIEW || (
            operation != EOperation.VIEW &&
            (event.DATA_ORIGIN_TYPE && event.DATA_ORIGIN_TYPE.ID == EDataOriginTypeId.AUTOMATICO && ((modalScope.isAirService && event.SERVICE_AIR && event.SERVICE_AIR.length > 0) || (modalScope.isMaritimeService && event.SERVICE_MARITIME && event.SERVICE_MARITIME.length > 0)))
        );

        modalScope.closeEventModal = async (): Promise<void> => {
            const previousEvent: IOfferEventEvent = this.$scope.model.EVENTS && this.$scope.model.EVENTS.length && this.$scope.model.EVENTS[index] ? this.$scope.model.EVENTS[index] : null;
            if (this.$offerScope.hasChanges(modalScope.event, modalScope.oldEvent)) {
                const confirm = await this.$offerScope.modalSaveConfirmation("GENERAL.CLOSE", "GENERAL.CLOSE");
                if (confirm) {
                    if (!this.$offerScope.hasInvalidRequiredElements("modalEventForm")) this.applyEvent(modalScope.event, modalScope.oldEvent, modalScope.operation, index, previousEvent.UUID);
                    else return;
                }
            }
            this.modalService.closeModal(this.modalEventId);
            this.modalEventId = 0;
            $('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
        }

        modalScope.applyEvent = () => {
            const previousEvent: IOfferEventEvent = this.$scope.model.EVENTS && this.$scope.model.EVENTS.length && this.$scope.model.EVENTS[index] ? this.$scope.model.EVENTS[index] : null;
            if (!this.$offerScope.hasInvalidRequiredElements("modalEventForm")) this.applyEvent(modalScope.event, modalScope.oldEvent, modalScope.operation, index, previousEvent.UUID);
        };

        modalScope.getLogisticSolutionList = async (): Promise<void> => {
            modalScope.logisticSolutionList = await this.getLogisticSolutionList();
        }

        modalScope.getProviderListByName = async (search: string) => {
            let providerList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                providerList = await this.getProviderListByName(search);
            }
            modalScope.providerList = providerList;
        }

        modalScope.getRoutingPointListByName = async (search: string) => {
            let routingPointList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                routingPointList = await this.getRoutingPointListByName({ name: search, types: [modalScope.event.TYPE_LOCAL ? modalScope.event.TYPE_LOCAL.ID : null] });
            }
            modalScope.routingPointList = routingPointList;
        }

        return modalScope;
    }

    private async addEvent(index: number): Promise<void> {
        try {
            const productId = this.$offerScope.model.PRODUCT ? this.$offerScope.model.PRODUCT.ID : null;
            if (!productId) throw Error("Product is null.");

            const dataOriginTypeList: SelectorModel = this.$scope.dataOriginTypeList.find(origin => origin.ID == 'M');
            let typeLocal = null;
            if (productId == EProductId.AIR_EXPORT || productId == EProductId.AIR_IMPORT) {
                typeLocal = this.$scope.typeLocalList.find(obj => obj.ID == ETypeLocalId.AIRPORT);
            } else if (productId == EProductId.MARITIME_EXPORT || productId == EProductId.MARITIME_IMPORT) {
                typeLocal = this.$scope.typeLocalList.find(obj => obj.ID == ETypeLocalId.PORT);
            } else if (productId == EProductId.ROAD_EXPORT || productId == EProductId.ROAD_IMPORT || productId == EProductId.ROAD_NATIONAL) {
                typeLocal = this.$scope.typeLocalList.find(obj => obj.ID == ETypeLocalId.DOOR);
            }
            const provider = await this.getOfferProvider();
            const movement = await this.getOfferMovement();
            const event: IOfferEventEvent = {
                UUID: null,
                CODE: null,
                EVENT_TEXT: null,
                EVENT: null,
                EVENT_ARRIVAL: null,
                EVENT_DEPARTURE: null,
                TYPE_LOCAL: typeLocal,
                LOCAL: null,
                SERVICE_TEXT: null,
                SERVICE_MARITIME: null,
                SERVICE_AIR: null,
                SERVICE_ROAD: null,
                SERVICE_DOMESTIC_ORIGIN: null,
                SERVICE_DOMESTIC_DESTINATION: null,
                PROCESS_EVENT_TRANSPORT_MODE: movement,
                PROVIDER: provider,
                LEGAL_PERSON_AGENT: null,
                ZIP_CODE: null,
                ADDRESS: null,
                DATA_ORIGIN_TYPE: dataOriginTypeList,
                CREATED_BY: null,
                CREATED_DATE: null,
                MODIFIED_BY: null,
                MODIFIED_DATE: null,
            };

            if (this.productTransshipment) {
                event.CODE = this.productTransshipment.INITIALS;
                event.EVENT_TEXT = this.productTransshipment.NAME_INTL;
                event.EVENT_DEPARTURE = this.productTransshipment.EVENT_DEPARTURE;
                event.EVENT_ARRIVAL = this.productTransshipment.EVENT_ARRIVAL;
            } else return this.formService.notifyError(this.formService.getTranslate('GENERAL.NO_TRANSSHIPMENT_RECORD_PRODUCT_CONFIG'));

            this.modalEventId = this.modalService.newModal();
            this.modalService.showModalInfo(
                {
                    modalID: this.modalEventId,
                    scope: this.$scope,
                    formService: 'register',
                    template: require("../view/modals/offerEventModal.html"),
                    size: 'lg'
                },
                {
                    headerText: "REGISTRATION.NEW_EVENT"
                }
            );
            await this.buildEventModalScope(event, index, productId, "register");
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async editEvent(index: number, event: IOfferEventEvent): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            if (!event) throw Error('event is null');
            const productId = this.$offerScope.model.PRODUCT ? this.$offerScope.model.PRODUCT.ID : null;
            if (!productId) throw Error("Product is null.");
            this.$offerScope.lastScroll = $('.app-content-body').scrollTop();
            this.modalEventId = this.modalService.newModal();

            this.modalService.showModalInfo(
                {
                    modalID: this.modalEventId,
                    scope: this.$scope,
                    formService: 'edit',
                    template: require("../view/modals/offerEventModal.html"),
                    size: 'lg'
                },
                {
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: `OPERATIONAL.EDIT_EVENT`
                });
            if (!event.PROCESS_EVENT_TRANSPORT_MODE)
                event.PROCESS_EVENT_TRANSPORT_MODE = await this.getOfferMovement();
            if (!event.PROVIDER && !event.LEGAL_PERSON_AGENT)
                event.PROVIDER = await this.getOfferProvider();

            await this.buildEventModalScope(event, index, productId, 'edit');
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async viewEvent(index: number, event: IOfferEventEvent): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            if (!event) throw Error('event is null');
            const productId = this.$offerScope.model.PRODUCT ? this.$offerScope.model.PRODUCT.ID : null;
            if (!productId) throw Error("Product is null.");

            this.modalEventId = this.modalService.newModal();
            this.modalService.showModalInfo(
                {
                    modalID: this.modalEventId,
                    scope: this.$scope,
                    formService: EOperation.VIEW,
                    template: require("../view/modals/offerEventModal.html"),
                    size: 'lg'
                },
                {
                    actionButtonText: 'GENERAL.CLOSE',
                    headerText: `OPERATIONAL.VIEW_EVENT`
                }
            );
            await this.buildEventModalScope(event, index, productId, EOperation.VIEW);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeEvent(index: number, uuid: string): Promise<void> {
        try {
            if (!index) throw Error('index is null');
            if (!uuid) throw Error('uuid is null');
            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate('GENERAL.MESSAGES.CONFIRMATION.REMOVAL')
            });
            if (!modal) return;
            this.formService.block();
            const result = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/event/remove/event`, { offerId: this.$offerScope.model.ID, UUID: uuid }, 30000, false);
            if (result) {
                this.$scope.model = result;
                const msgSuccess = this.formService.getTranslate('BASIC_DATA.EVENT_SUCESSFULLY_REMOVED');
                this.formService.notifySuccess(msgSuccess);
                this.$scope.$applyAsync();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async applyEvent(event: IOfferEventEvent, oldEvent: IOfferEventEvent, operation: string, index: number, previousUUID: string): Promise<boolean> {
        let success = false;
        try {
            if (!event) throw Error('event is null');
            if (!index && index != 0) throw Error('index is null');
            if (!operation) throw Error('operation is null');
            if (!previousUUID) throw Error('previousUUID is null');
            let result = null;
            this.formService.block();
            if (operation === "register") result = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/event/insert`, { offerId: this.$offerScope.model.ID, previousUUId: previousUUID, data: event }, 30000, false);
            else result = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/event/update/event`, { offerId: this.$offerScope.model.ID, previousUUId: previousUUID, data: event, oldData: oldEvent }, 30000, false);
            if (result) {
                success = true;
                this.$scope.model = result;
                this.modalService.closeModal(this.modalEventId);
                this.modalEventId = 0;
                angular.element('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
                this.$scope.$applyAsync();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            const msgSuccess = this.formService.getTranslate('GENERAL.EVENT_DATA_SAVED_SUCCESSFULLY');
            if (success) this.formService.notifySuccess(msgSuccess);
            return success;
        }
    }

    private async applyTransitTimeDetail(service: IOfferEventService): Promise<boolean> {
        let success = false;
        try {
            if (!service) throw Error('service is null');
            this.formService.block();
            const result = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/offer/tabs/event/update/service/detail`, { offerId: this.$offerScope.model.ID, data: service }, 30000, false);
            if (result) {
                success = true;
                this.$scope.model = result;
                this.modalService.closeModal(this.modalServiceId);
                this.modalServiceId = 0;
                angular.element('.app-content-body').animate({ scrollTop: this.$offerScope.lastScroll }, 0);
                this.$scope.$applyAsync();
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            const msgSuccess = this.formService.getTranslate('GENERAL.TRANSIT_TIME_DETAILS_UPDATED_SUCCESSFULLY');
            if (success) this.formService.notifySuccess(msgSuccess);
            return success;
        }
    }

    private async getProviderListByName(search: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            if (!this.$offerScope.model.PRODUCT) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
            this.formService.block();
            const providers = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/provider/list/custom`, { types: [EProviderTypeId.SHIPOWNER, EProviderTypeId.AIRLINE], products: [this.$offerScope.model.PRODUCT.ID], search: search }, 30000, false);
            result = providers ? providers.map(provider => { return { ID: provider.ID.toString(), NAME: provider.NAME, CODE: provider.SCAC_IATA } }) : [];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getRoutingPointListByName(filter: IRoutingPointListCustomFilter): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const routingPoints = await this.restService.newObjectPromise(`${this.$offerScope.productUrl}/routingPoint/list/custom`, filter, 30000, false);
            if (routingPoints) result = routingPoints.map(routingPoint => { return { ID: routingPoint.ID, NAME: routingPoint.NAME, CODE: routingPoint.CODE, DISPLAY_NAME: routingPoint.DISPLAY_NAME } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async viewLog(uuid?: string) {
        try {
            this.formService.block();
            let log: IViewLog = {
                operation: 'history',
                number: this.$offerScope.model.ID.toString(),
                list: [],
                show: true,
                showCloseButton: false,
                searchQuery: '',
                originalList: [],
            }

            this.requestHistory(log.number, uuid).then(result => {
                log.list = result.data;
                log.originalList = angular.copy(log.list);
                this.$scope.log = log;
                this.$scope.customLogProperties = this.getCustomLogProperties();
                angular.element('#log-viewer').removeClass('ng-hide');
                this.formService.unblock();
            }).catch(ex => {
                this.formService.handleError(ex);
            });

            const modalId = this.modalService.newModal();
            await this.modalService.showModalConfirmation(
                {
                    modalID: modalId,
                    scope: this.$scope,
                    template: require('../view/modals/offerLog.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG"
                }
            );
            this.modalService.closeModal(modalId);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private requestHistory(id: string, uuid?: string): Promise<any> {
        return this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/event/viewLog/${EOfferLogsName.EVENTS}/${id}${uuid ? '/' + uuid : ''}`, 10000, null, false);
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: "CODE", LABEL: "GENERAL.CODE" },
            { PROPERTY: "ID", LABEL: "Id." },
            { PROPERTY: "NAME", LABEL: "GENERAL.NAME" },
            { PROPERTY: "EVENT_TEXT", LABEL: "GENERAL.EVENT_TYPE" },
            { PROPERTY: "EVENT", LABEL: "OPERATIONAL.EVENT" },
            { PROPERTY: "EVENT_ARRIVAL", LABEL: "GENERAL.ENTRANCE_EVENT" },
            { PROPERTY: "EVENT_DEPARTURE", LABEL: "GENERAL.EXIT_EVENT" },
            { PROPERTY: "TYPE_LOCAL", LABEL: "ROUTE.LOCAL_TYPE" },
            { PROPERTY: "LOCAL", LABEL: "GENERAL.LOCAL" },
            { PROPERTY: "SERVICE_TEXT", LABEL: "GENERAL.SERVICE" },
            { PROPERTY: "SERVICE_MARITIME", LABEL: "GENERAL.MARITIME_SERVICE" },
            { PROPERTY: "SERVICE_AIR", LABEL: "PRODUCT.AIR_SERVICE" },
            { PROPERTY: "SERVICE_DOMESTIC_ORIGIN", LABEL: "PRODUCT.ORIGIN_DOMESTIC_SERVICE" },
            { PROPERTY: "SERVICE_DOMESTIC_DESTINATION", LABEL: "PRODUCT.DESTINATION_DOMESTIC_SERVICE" },
            { PROPERTY: "PROCESS_EVENT_TRANSPORT_MODE", LABEL: "REGISTRATION.MODE_OF_TRANSPORT" },
            { PROPERTY: "ZIP_CODE", LABEL: "ENTITY.POSTAL_ADDRESS" },
            { PROPERTY: "PROVIDER", LABEL: "BASIC_DATA.SERVICE_PROVIDER" },
            { PROPERTY: "LEGAL_PERSON_AGENT", LABEL: "BASIC_DATA.OVERSEAS_AGENT" },
            { PROPERTY: "ADDRESS", LABEL: "REGISTRATION.ADDRESS" },
            { PROPERTY: "DATA_ORIGIN_TYPE", LABEL: "GENERAL.INFO_ORIGIN" },
            { PROPERTY: "ACTIVE", LABEL: "GENERAL.ACTIVE" },
            { PROPERTY: "CREATED_BY", LABEL: "GENERAL.CREATED_BY" },
            { PROPERTY: "MODIFIED_BY", LABEL: "GENERAL.MODIFIED_BY" },
            { PROPERTY: "CREATED_DATE", LABEL: "GENERAL.CREATED_AT" },
            { PROPERTY: "MODIFIED_DATE", LABEL: "GENERAL.MODIFIED_DATE" },
            { PROPERTY: "FREQUENCY", LABEL: "ROUTE.SERVICE_FREQUENCY" }
        ];
        return props;
    }

    private async viewLogTransitTime(service: IOfferEventService) {
        try {
            const serviceId = service.AIR_SERVICE ? service.AIR_SERVICE.ID : service.MARITIME_SERVICE ? service.MARITIME_SERVICE.ID : service.DOMESTIC_ORIGIN_SERVICE ? service.DOMESTIC_ORIGIN_SERVICE.ID : service.DOMESTIC_DESTINATION_SERVICE ? service.DOMESTIC_DESTINATION_SERVICE.ID : service.ROAD_SERVICE ? service.ROAD_SERVICE.ID : null;
            const serviceType = service.AIR_SERVICE ? 'A' : service.MARITIME_SERVICE ? 'M' : service.DOMESTIC_ORIGIN_SERVICE ? 'DO' : service.DOMESTIC_DESTINATION_SERVICE ? 'DD' : service.ROAD_SERVICE ? 'R' : null;
            this.formService.block();
            let log: IViewLog = {
                operation: 'history',
                number: this.$offerScope.model.ID.toString(),
                list: [],
                show: true,
                showCloseButton: false,
                searchQuery: '',
                originalList: [],
            }

            this.requestHistoryTransitTime(log.number, serviceId, serviceType).then(result => {
                log.list = result.data;
                log.originalList = angular.copy(log.list);
                this.$scope.log = log;
                this.$scope.customLogProperties = this.getCustomLogPropertiesServices();
                angular.element('#log-viewer').removeClass('ng-hide');
                this.formService.unblock();
            }).catch(ex => {
                this.formService.handleError(ex);
            });

            const modalId = this.modalService.newModal();
            await this.modalService.showModalConfirmation(
                {
                    modalID: modalId,
                    scope: this.$scope,
                    template: require('../view/modals/offerLog.html'),
                    size: 'full'
                },
                {
                    closeButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.GRID.LOG"
                }
            );
            this.modalService.closeModal(modalId);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private requestHistoryTransitTime(id: string, serviceType: string, serviceId: string): Promise<any> {
        return this.restService.getObjectAsPromise(`${this.$offerScope.productUrl}/offer/tabs/transitTime/viewLog/${id}/${serviceType}/${serviceId}`, 10000, null, false);
    }

    public getCustomLogPropertiesServices(): ICustomLogProperties[] {
        const prop: ICustomLogProperties[] = [
            { PROPERTY: "SERVICE_ROAD", LABEL: "BASIC_DATA.ROAD_SERVICE" },
            { PROPERTY: "TRANSIT_TIME_MIN", LABEL: "GENERAL.TRANSIT_TIME_MIN" },
            { PROPERTY: "TRANSIT_TIME_MAX", LABEL: "GENERAL.TRANSIT_TIME_MAX" },
            { PROPERTY: "ACTIVE", LABEL: "GENERAL.ACTIVE" },
            { PROPERTY: "SERVICE_MARITIME", LABEL: "BASIC_DATA.MARITIME_SERVICE" },
            { PROPERTY: "SERVICE_AIR", LABEL: "BASIC_DATA.AIR_SERVICE" },
            { PROPERTY: "SERVICE_DOMESTIC_ORIGIN", LABEL: "PRODUCT.ORIGIN_DOMESTIC_SERVICE" },
            { PROPERTY: "SERVICE_DOMESTIC_DESTINATION", LABEL: "PRODUCT.DESTINATION_DOMESTIC_SERVICE" },
            { PROPERTY: "PROVIDER", LABEL: "BASIC_DATA.PROVIDER" },
            { PROPERTY: "TYPE_LOCAL_ORIGIN", LABEL: "BASIC_DATA.ORIGIN" },
            { PROPERTY: "TYPE_LOCAL_DESTINATION", LABEL: "BASIC_DATA.DESTINATION" },
            { PROPERTY: "LOCAL_ORIGIN", LABEL: "BASIC_DATA.ORIGIN_LOCATION" },
            { PROPERTY: "LOCAL_DESTINATION", LABEL: "BASIC_DATA.DESTINATION_LOCATION" },
            { PROPERTY: "DATA_ORIGIN_TYPE", LABEL: "GENERAL.INFO_ORIGIN" },
            { PROPERTY: "ID", LABEL: "Id." },
            { PROPERTY: "NAME", LABEL: "GENERAL.NAME" },
            { PROPERTY: "CODE", LABEL: "GENERAL.CLOSE" },
            { PROPERTY: "FREQUENCY", LABEL: "ROUTE.SERVICE_FREQUENCY" },
        ];
        return prop;
    }

    private isTypeEvent(operation: string, events: IOfferEventEvent): boolean {
        try {
            let disableRemove = true;

            if (operation == 'view') disableRemove = false;
            else if (events.DATA_ORIGIN_TYPE.ID === 'M') disableRemove = false;
            else if (events.CODE === 'T/S' && events.DATA_ORIGIN_TYPE.ID === 'A') disableRemove = false;
            else if (events.CODE === 'CNX' && events.DATA_ORIGIN_TYPE.ID === 'A') disableRemove = false;

            return disableRemove
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }
}


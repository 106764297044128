import * as angular from 'angular';

import initRoute from './RouteConfig';
import { GRID_FORM_MODULE } from '../gridForm/GridFormModule';
import { GRID_EXCEL_MODULE } from '../gridExcel/GridExcelModule';

import { DataManagerReportController } from './controller/DataManagerReportController';
import { DataManagerSchedulingController } from './controller/DataManagerSchedulingController';
import { DataManagerMenuController } from './controller/DataManagerMenuController';

const DATA_MANAGER_MODULE = angular
    .module('dataManager', [GRID_FORM_MODULE.name, GRID_EXCEL_MODULE.name])
    .controller('DataManagerReportController', DataManagerReportController)
    .controller('DataManagerSchedulingController', DataManagerSchedulingController)
    .controller('DataManagerMenuController', DataManagerMenuController)

initRoute(DATA_MANAGER_MODULE.name);

export { DATA_MANAGER_MODULE };

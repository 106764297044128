import { IComponentOptions, IControllerConstructor, Injectable } from "angular";
import { DatetimePickerController } from "./DatetimePickerController";
import './assets/calentim.min.css';
import './assets/custom-calentim.css';
import './assets/calentim.min.js';


export class DatetimePickerComponent implements IComponentOptions {
    public template: string;
    public controller: Injectable<IControllerConstructor>;

    public bindings: { [boundProperty: string]: string } = {
        range: '=',
        minimal: '=',
        count: '=',
        position: '@',
        arrow: '@',
        ngDisabled: '='
    };

    public require: { [controller: string]: string } = {
        ngModel: '^ngModel',
        uiGrid: '?^uiGrid'
    };

    constructor() {
        this.controller = DatetimePickerController;
        this.template = require("./datetime-picker.component.html");
    }

}
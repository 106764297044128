import * as angular from 'angular';
import { ISessionService } from '@services/SessionService';
import { BrowserTitle } from '../common/BrowserTitle';

const appState = {
    name: 'app',
    url: '/app',
    template: require("./view/app.html"),
    controller: 'AppController',
    abstract: true,
    /* Solução de carregar os termos por módulo
    data: {
        i18n: ['general', 'basic_data', 'operational']
    }
    */
}

const invalidState = {
    name: 'app.invalid',
    url: '/invalid',
    views: {
        'content@app': {
            template: require("./view/invalid.html")
        },
    },
}

const loginState = {
    name: 'app.signin',
    url: '/signin?param',
    views: {
        '@': {
            template: require("./view/login.html")
        },
    },
    params: {
        failed: false,
        expired: false
    }
}

const homeState = {
    name: 'app.home',
    url: '/home',
    views: {
        'content@app': {
            template: require("./view/home.html")
        },
    },
    data: {
        requiresAuth: true,
        permissions: {
            except: 'EXTERNAL',
            redirectTo: 'app.denied'
        }
    },
    ncyBreadcrumb: {
        label: 'Home',
        parent: function () {
            BrowserTitle.$menu = 'Home';
        }
    }
}

const errorState = {
    name: 'app.error',
    url: '/error',
    views: {
        'content@app': {
            template: require("./view/error.html")
        },
    },
    params: {
        origin: undefined,
        message: undefined,
        error: undefined,
    },
    data: {
        requiresAuth: true
    }
}

const accessDeniedState = {
    name: 'app.denied',
    url: '/denied',
    views: {
        'content@app': {
            template: require("./view/denied.html")
        },
    },
    params: {
        reason: undefined,
    },
    data: {
        requiresAuth: true
    }
}

const externalFutureState = {
    name: 'app.external.**',
    url: '/external',
    data: {
        requiresAuth: true
    },
    lazyLoad: ($transition$) => {
        const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
        return import('../external/ExternalModule')
            .then(module => {
                $ocLazyLoad.load(module.EXTERNAL_MODULE)
            })
            .catch(err => {
                throw new Error("Failed to load EXTERNAL Module: " + err);
            });
    }
}

const crmFutureState = {
    name: 'app.crm.**',
    url: '/crm',
    data: {
        requiresAuth: true
    },
    lazyLoad: ($transition$) => {
        const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
        return import('../crm/CrmModule')
            .then(module => {
                $ocLazyLoad.load(module.CRM_MODULE)
            })
            .catch(err => {
                throw new Error("Failed to load CRM Module: " + err);
            });
    }
}

const operationalFutureState = {
    name: 'app.operational.**',
    url: '/operational',
    data: {
        requiresAuth: true
    },
    lazyLoad: ($transition$) => {
        const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
        return import('../operational/OperationalModule')
            .then(module => {
                $ocLazyLoad.load(module.OPERATIONAL_MODULE)
            })
            .catch(err => {
                throw new Error("Failed to load OPERATIONAL Module: " + err);
            });
    }
}

const registrationFutureState = {
    name: 'app.registration.**',
    url: '/registration',
    data: {
        requiresAuth: true
    },
    lazyLoad: ($transition$) => {
        const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
        return import('../registration/RegistrationModule')
            .then(module => {
                $ocLazyLoad.load(module.REGISTRATION_MODULE)
            })
            .catch(err => {
                throw new Error("Failed to load REGISTRATION Module: " + err);
            });
    }
}

const productFutureState = {
    name: 'app.product.**',
    url: '/product',
    data: {
        requiresAuth: true
    },
    lazyLoad: ($transition$) => {
        const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
        return import('../product/ProductModule')
            .then(module => {
                $ocLazyLoad.load(module.PRODUCT_MODULE)
            })
            .catch(err => {
                throw new Error("Failed to load PRODUCT Module: " + err);
            });
    }
}

const commercialFutureState = {
    name: 'app.commercial.**',
    url: '/commercial',
    data: {
        requiresAuth: true
    },
    lazyLoad: ($transition$) => {
        const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
        return import('../commercial/CommercialModule')
            .then(module => {
                $ocLazyLoad.load(module.COMMERCIAL_MODULE)
            })
            .catch(err => {
                throw new Error("Failed to load COMMERCIAL Module: " + err);
            });
    }
}

const adminFutureState = {
    name: 'app.admin.**',
    url: '/admin',
    data: {
        requiresAuth: true
    },
    lazyLoad: ($transition$) => {
        const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
        return import('../admin/AdminModule')
            .then(module => {
                $ocLazyLoad.load(module.ADMIN_MODULE)
            })
            .catch(err => {
                throw new Error("Failed to load ADMIN Module: " + err);
            });
    }
}

const reportFutureState = {
    name: 'app.report.**',
    url: '/report',
    data: {
        requiresAuth: true
    },
    lazyLoad: ($transition$) => {
        const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
        return import('../report/ReportModule')
            .then(module => {
                $ocLazyLoad.load(module.REPORT_MODULE)
            })
            .catch(err => {
                throw new Error("Failed to load REPORT Module: " + err);
            });
    }
}

const finopFutureState = {
    name: 'app.finop.**',
    url: '/finop',
    data: {
        requiresAuth: true
    },
    lazyLoad: ($transition$) => {
        const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
        return import('../finop/FinopModule')
            .then(module => {
                $ocLazyLoad.load(module.FINOP_MODULE)
            })
            .catch(err => {
                throw new Error("Failed to load FINOP Module: " + err);
            });
    }
}

const managementFutureState = {
    name: 'app.management.**',
    url: '/management',
    data: {
        requiresAuth: true
    },
    lazyLoad: ($transition$) => {
        const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
        return import('../management/ManagementModule')
            .then(module => {
                $ocLazyLoad.load(module.MANAGEMENT_MODULE)
            })
            .catch(err => {
                throw new Error("Failed to load MANAGEMENT Module: " + err);
            });
    }
}

const dataManagerFutureState = {
    name: 'app.datamanager.**',
    url: '/datamanager',
    data: {
        requiresAuth: true
    },
    lazyLoad: ($transition$) => {
        const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
        return import('../dataManager/DataManagerModule')
            .then(module => {
                $ocLazyLoad.load(module.DATA_MANAGER_MODULE)
            })
            .catch(err => {
                throw new Error("Failed to load DATA MANAGER Module: " + err);
            });
    }
}

const taskFutureState = {
    name: 'app.task.**',
    url: '/task',
    data: {
        requiresAuth: false
    },
    lazyLoad: ($transition$) => {
        const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
        return import('../task/TaskModule')
            .then(module => {
                $ocLazyLoad.load(module.TASK_MODULE)
            })
            .catch(err => {
                throw new Error("Failed to load TASK Module: " + err);
            });
    }
}

routeConfig.$inject = ['$urlRouterProvider', '$uiRouterProvider', 'config'];
function routeConfig($urlRouterProvider, $uiRouterProvider, config) {

    $urlRouterProvider.deferIntercept();
    $urlRouterProvider.otherwise(function ($injector) {
        const $location = $injector.get('$location');
        const SessionService: ISessionService = $injector.get('SessionService');
        if (SessionService.isAuthenticated()) {
            $location.url('/app/invalid');
        } else {
            $location.url('/app/signin');
        }
    });

    const $stateRegistry = $uiRouterProvider.stateRegistry;
    $stateRegistry.register(accessDeniedState);
    $stateRegistry.register(invalidState);
    $stateRegistry.register(errorState);
    $stateRegistry.register(loginState);
    $stateRegistry.register(appState);
    $stateRegistry.register(homeState);
    $stateRegistry.register(externalFutureState);
    $stateRegistry.register(crmFutureState);
    $stateRegistry.register(operationalFutureState);
    $stateRegistry.register(registrationFutureState);
    $stateRegistry.register(reportFutureState);
    $stateRegistry.register(finopFutureState);
    $stateRegistry.register(managementFutureState);
    $stateRegistry.register(dataManagerFutureState);
    $stateRegistry.register(taskFutureState);
    $stateRegistry.register(productFutureState);
    $stateRegistry.register(commercialFutureState);
    $stateRegistry.register(adminFutureState);
}

export default function initRoute(moduleName: string): void {
    angular.module(moduleName).config(routeConfig);
}

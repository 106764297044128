import * as angular from 'angular';

const reportState = {
    name: 'app.report',
    url: '/report',
    abstract: true,
    data: {
        requiresAuth: true,
        permissions: {
            except: 'EXTERNAL',
            redirectTo: 'app.denied'
        }
    }
}

const reportMenuState = {
    name: 'app.report.menu',
    url: '/menu',
    views: {
        'content@app': {
            template: require("./view/reportMenu.html")
        },
    },
    params: {
        operation: 'register',
        financialcontract: undefined,
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.REPORT',
        parent: 'app.report'
    }
}

const reportRegisterState = {
    name: 'app.report.register',
    url: '/reportRegister',
    views: {
        'content@app': {
            template: require("./view/reportDataRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.REPORT_REGISTRATION',
        parent: 'app.report.menu'
    }
}

const reportRegisteScheduleState = {
    name: 'app.report.schedule.register',
    url: '/reportRegister',
    views: {
        'content@app': {
            template: require("./view/reportScheduleRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.SCHEDULING_REGISTRATION',
        parent: 'app.report.menu'
    }
}

routeConfig.$inject = ['$uiRouterProvider'];
function routeConfig($uiRouterProvider) {
    const $stateRegistry = $uiRouterProvider.stateRegistry;

    $stateRegistry.register(reportState);
    $stateRegistry.register(reportMenuState);
    $stateRegistry.register(reportRegisterState);
    $stateRegistry.register(reportRegisteScheduleState);
}

export default function initRoute(moduleName: string): void {
    angular.module(moduleName).config(routeConfig);
}

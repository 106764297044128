import * as angular from "angular";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IAgentsParamsModel } from "@models/interface/product/AgentsParamsModel";
import { ETypeLocalId, EProductId, EOperation } from "@enums/GenericData";
import { IRoutingPointSelector } from "@models/interface/operational/IProductIntegrationSelector";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog, } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { SelectorModel } from "../../common/model/SelectorModel";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { ISessionService } from "@services/SessionService";
import { ILinkParameter } from "../../common/model/ModelParameter";
import { SSEService } from "@appServices/SSEService";
import { HelperService } from "@services/HelperService";

interface IAgentsParamsScope extends IGridFormServiceScope {
    model: IAgentsParamsModel;
    log: IViewLog;
    typeList: SelectorModel[];
    productList: SelectorModel[];
    countryList: SelectorModel[];
    agentList: SelectorModel[];
    typeCargoList: SelectorModel[];
    typeRoutingPointList: SelectorModel[];
    routingPointList: IRoutingPointSelector[];
    processTypeList: SelectorModel[];
    commoditySectionList: SelectorModel[];
    accountList: SelectorModel[];
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IAgentsParamsModel;
    scacIataLengthValidation: number;
    agentSelected: IAgentSelector;
    user: any;
    sessionService: ISessionService;
    editAgentsParams: (agentsParams: IAgentsParamsModel) => Promise<void>;
    viewAgentsParams: (agentsParams: IAgentsParamsModel) => Promise<void>;
    viewLogAgentsParams: (agentsParams: IAgentsParamsModel) => Promise<void>;
    copyAgentsParams: (agentsParams: IAgentsParamsModel) => Promise<void>;
    getCountryData(query: string): Promise<void>;
    getAgentListByName: (search: string) => Promise<void>;
    getRoutingPointListByName: (name: string) => Promise<void>;
    getAccountListByName: (name: string) => Promise<void>;
    getAccountByName: (name: string) => Promise<void>;
    goToCountry: (id: number) => void
    goToAgent: (id: number) => void;
    goToRoutingPoint: () => void;
    goToAccount: () => void;
    masterDirectChange: () => void;
    onAgentChange: () => void;
    changeTypeRoutingPoint: () => void;
    filterTypeRoutingPointList: () => SelectorModel[];
    buildRoutingPointViewGrid: (routingPoint: IRoutingPointSelector[]) => string;
    fetchData: (id: number, action: string) => Promise<void>;
}

interface IAgentSelector extends SelectorModel {
    ID_NETWORK: number;
}

export class AgentsParamsRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IAgentsParamsScope;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private RestService: IRestService;
    private SSEService: SSEService;
    private gridName: string;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IAgentsParamsScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.RestService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.gridName = 'GRID_AGENTS_PARAMS';
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            const commodityList = await this.getCommoditySectionList('hscode_section');
            this.$scope.commoditySectionList = (commodityList) ? commodityList : [];
            this.initForm(this, 'form', 'agentsParams', 'GENERAL.MENU.AGENT_PARAMETERIZATION', true);
            await this.initGrid(this.gridName, '/agentsParams/list', true, true, null, true, true);

        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editAgentsParams = async (agentsParams: IAgentsParamsModel): Promise<void> => {
            let blockedObject = {
                ID: agentsParams.ID,
                NAME: agentsParams.ID.toString(),
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || agentsParams.ID !== this.$scope.model.ID) this.$scope.fetchData(agentsParams.ID, EOperation.VIEW);
                } else if (this.$scope.operation !== EOperation.EDIT || agentsParams.ID !== this.$scope.model.ID) {
                    this.$scope.fetchData(agentsParams.ID, EOperation.EDIT);
                }
            };
        }

        this.$scope.viewAgentsParams = async (agentsParams: IAgentsParamsModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(agentsParams.ID, EOperation.VIEW);
        }

        this.$scope.viewLogAgentsParams = async (agentsParams: IAgentsParamsModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(agentsParams);
        }

        this.$scope.copyAgentsParams = async (agentsParams: IAgentsParamsModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(agentsParams.ID, EOperation.COPY);
        }

        //country
        this.$scope.getCountryData = async (query) => {
            let countryList = [];
            if (query && query.length >= 2) {
                countryList = await this.getCountryData(query);
            }
            this.$scope.countryList = countryList;
        }
        this.$scope.goToCountry = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.country", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.getAgentListByName = async (search: string): Promise<void> => {
            let agentList: SelectorModel[] = [];
            if (search && search.length >= 3) agentList = await this.getAgentListByName(search);
            this.$scope.agentList = agentList;
        }

        //routing point
        this.$scope.goToRoutingPoint = () => {
            this.$scope.sessionService.openTab("app.registration.routingPoint", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.ROUTING_POINTS, null, 'ID') });
        }

        //account
        this.$scope.goToAccount = (): void => {
            this.$scope.sessionService.openTab("app.commercial.account", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.ACCOUNTS, null, "ID") });
        }

        this.$scope.getRoutingPointListByName = async (name: string) => {
            let routingPointList = [];
            if (name && name.length >= 3) {
                routingPointList = await this.getRoutingPointListByName(name);
            }
            this.$scope.routingPointList = routingPointList;
        }

        this.$scope.getAccountListByName = async (name: string) => {
            let accountList = [];
            if (name && name.length >= 3) {
                accountList = await this.getAccountListByName(name);
            }
            this.$scope.accountList = accountList;
        }

        this.$scope.goToAgent = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.agent", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.masterDirectChange = () => {
            this.$timeout(() => {
                this.$scope.selectorValidity('agentsParamsLocalAgentID');
                this.$scope.selectorValidity('agentsParamsExtAgent');
            });
        }

        this.$scope.onAgentChange = (): void => {
            this.onAgentChange();
        }

        this.$scope.filterTypeRoutingPointList = (): SelectorModel[] => {
            return this.filterTypeRoutingPointList();
        }

        this.$scope.changeTypeRoutingPoint = (): void => {
            this.changeTypeRoutingPoint();
        }

        this.$scope.buildRoutingPointViewGrid = (routingPoint: IRoutingPointSelector[]) => {
            if (!routingPoint) return "";
            const routingPointToConcat = routingPoint.map(routingPoint => `${routingPoint.CODE} (${routingPoint.TYPE.NAME})`);
            return this.$scope.getCONCAT(routingPointToConcat);
        }

        this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
            this.fetchData(id, action);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewAgentsParams(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editAgentsParams(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.setCopy(true);grid.appScope.copyAgentsParams(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogAgentsParams(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy),
            enableCellEdit: true,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 100, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PRODUCT, null, "ID")}}</div>' };
            const colCountry: IMonacoColumnDef = { name: "COUNTRY.CODE", displayName: "ROUTE.COUNTRY", width: 100, field: 'COUNTRY.CODE' };
            const colTypeRoutingPoint: IMonacoColumnDef = { name: "TYPE_ROUTING_POINTS.NAME", displayName: "ROUTE.LOCAL_TYPE", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TYPE_ROUTING_POINTS, null, "NAME")}}</div>' };
            const colRoutingPoint: IMonacoColumnDef = { name: "ROUTING_POINTS.CODE", displayName: "GENERAL.LOCAL", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.buildRoutingPointViewGrid(row.entity.ROUTING_POINTS)}}</div>' };
            const colTypeCargo: IMonacoColumnDef = { name: "TYPE_CARGO.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TYPE_CARGO, null, "NAME")}}</div>' };
            const colProcessType: IMonacoColumnDef = { name: "PROCESS_TYPE.NAME", displayName: "BASIC_DATA.FILE_TYPE", width: 100, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PROCESS_TYPE, null, "NAME")}}</div>' };
            const colCommoditySection: IMonacoColumnDef = { name: "COMMODITY_SECTION.NAME", displayName: "GENERAL.COMMODITY_GROUP", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.COMMODITY_SECTION, null, "")}}</div>' };
            const colAccount: IMonacoColumnDef = { name: "ACCOUNTS.NAME", displayName: "BASIC_DATA.CLIENT", width: 300, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNTS, null, "NAME")}}</div>' };
            const colMasterDirect: IMonacoColumnDef = { name: "MASTER_DIRECT", displayName: "BASIC_DATA.STRAIGHT_BL", width: 80, cellFilter: 'YesOrNo' };
            const colLocalAgent: IMonacoColumnDef = { name: "LOCAL_AGENT.NAME", displayName: "BASIC_DATA.LOCAL_AGENT", width: 200, field: 'LOCAL_AGENT.NAME' };
            const colExtAgent: IMonacoColumnDef = { name: "EXTERNAL_AGENT.NAME", displayName: "BASIC_DATA.OVERSEAS_AGENT", width: 200, field: 'EXTERNAL_AGENT.NAME' };
            const colOtherAgent: IMonacoColumnDef = { name: "OTHER_AGENT.NAME", displayName: "BASIC_DATA.THIRD_AGENT", width: 200, field: 'OTHER_AGENT.NAME' };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 100, cellFilter: "YesOrNo" };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'ID_COUNTRY':
                        columnDefs.push(colCountry);
                        break;
                    case 'TYPE_ROUTING_POINTS':
                        columnDefs.push(colTypeRoutingPoint);
                        break;
                    case 'ROUTING_POINTS':
                        columnDefs.push(colRoutingPoint);
                        break;
                    case 'TYPE_CARGO':
                        columnDefs.push(colTypeCargo);
                        break;
                    case 'PROCESS_TYPE':
                        columnDefs.push(colProcessType);
                        break;
                    case 'COMMODITY_SECTION':
                        columnDefs.push(colCommoditySection);
                        break;
                    case 'ACCOUNTS':
                        columnDefs.push(colAccount);
                        break;
                    case 'MASTER_DIRECT':
                        columnDefs.push(colMasterDirect);
                        break;
                    case 'ID_LOCAL_AGENT':
                        columnDefs.push(colLocalAgent);
                        break;
                    case 'ID_EXTERNAL_AGENT':
                        columnDefs.push(colExtAgent);
                        break;
                    case 'ID_OTHER_AGENT':
                        columnDefs.push(colOtherAgent);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            PRODUCT: null,
            ID_COUNTRY: null,
            COUNTRY: null,
            TYPE_ROUTING_POINTS: null,
            ROUTING_POINTS: null,
            TYPE_CARGO: null,
            PROCESS_TYPE: null,
            COMMODITY_SECTION: null,
            ACCOUNTS: null,
            MASTER_DIRECT: false,
            ID_LOCAL_AGENT: null,
            LOCAL_AGENT: null,
            ID_EXTERNAL_AGENT: null,
            EXTERNAL_AGENT: null,
            ID_OTHER_AGENT: null,
            OTHER_AGENT: null,
            ACTIVE: true,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null
        };

        this.onAgentChange();
    }

    initDependencies(): Promise<any> {
        const self: AgentsParamsRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getTypeList(),
                self.getProductList(),
                self.getTypeCargoList(),
                self.getProcessTypeList(),
                self.getTypeLocalEventList(),
            ]).then((result: any) => {
                self.$scope.typeList = result[0];
                self.$scope.productList = result[1];
                self.$scope.typeCargoList = result[2];
                self.$scope.processTypeList = result[3];
                self.$scope.typeRoutingPointList = result[4];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getCountryData(query?: string): Promise<SelectorModel[]> {
        let result = [];
        try {
            this.formService.block();
            const countries = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/country/list/custom/`, { search: query }, 30000, false);
            if (countries) result = countries.map(country => { return { ID: country.ID, NAME: country.NAME, CODE: country.INITIALS } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private onAgentChange(): void {
        let newIDNetwork = null;

        if (this.$scope.model.LOCAL_AGENT && this.$scope.model.LOCAL_AGENT.CODE) newIDNetwork = this.$scope.model.LOCAL_AGENT.CODE;
        else if (this.$scope.model.EXTERNAL_AGENT && this.$scope.model.EXTERNAL_AGENT.CODE) newIDNetwork = this.$scope.model.EXTERNAL_AGENT.CODE;
        else if (this.$scope.model.OTHER_AGENT && this.$scope.model.OTHER_AGENT.CODE) newIDNetwork = this.$scope.model.OTHER_AGENT.CODE;

        if (this.$scope.model.LOCAL_AGENT && this.$scope.model.LOCAL_AGENT.CODE != newIDNetwork) this.$scope.model.LOCAL_AGENT = null;
        if (this.$scope.model.EXTERNAL_AGENT && this.$scope.model.EXTERNAL_AGENT.CODE != newIDNetwork) this.$scope.model.EXTERNAL_AGENT = null;
        if (this.$scope.model.OTHER_AGENT && this.$scope.model.OTHER_AGENT.CODE != newIDNetwork) this.$scope.model.OTHER_AGENT = null;

        if (!this.$scope.agentSelected || this.$scope.agentSelected.ID_NETWORK != newIDNetwork) this.$scope.agentSelected = { ID: "1", NAME: "NETWORK", ID_NETWORK: parseInt(newIDNetwork) };

    }

    private changeTypeRoutingPoint(): void {
        setTimeout(() => {
            this.$scope.selectorValidity('agentsParamsCountryID');
        }, 100);
    }

    private async getAgentListByName(search: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        let networks = [];

        try {
            if (!this.$scope.model.PRODUCT) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
            this.block();
            const products = this.$scope.model.PRODUCT.map(products => { return products.ID });
            if (this.$scope.agentSelected && this.$scope.agentSelected.ID_NETWORK) networks.push(this.$scope.agentSelected.ID_NETWORK);

            const agents = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/agent/list/custom`, { search, products, networks }, 30000, false);
            result = agents ? agents.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.NETWORK_ID } }) : [];
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/agentsParams_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTypeCargoList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_cargo`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getProcessTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/process_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTypeLocalEventList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_local_event`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getCommoditySectionList(identifier: string): Promise<Array<SelectorModel>> {
        let resultList = new Array<SelectorModel>();

        const { data: generic } = await this.helperService.get(`/generic/getByIdentifier/${identifier}`, null, 15000);

        if ((generic.status !== 200) || (!generic.data)) return null;

        let selectorList = generic && generic.data ? generic.data : [];

        if (name) {
            selectorList = selectorList.filter(x => (x.VALUE == name || x.ALTERNATIVE == name));
        }

        for (const item of selectorList) {
            const selectorModel = <SelectorModel>{};
            selectorModel.ID = item.CODE;
            selectorModel.NAME = item.VALUE;
            selectorModel.CODE = item.ALTERNATIVE;

            resultList.push(selectorModel);
        }

        if (resultList.length > 0) {
            resultList = resultList.sort((x, y) => x.ID < y.ID ? -1 : 1);
        }

        return resultList;

    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlDataManager = this.config.productUrl + baseRoute;
        return urlDataManager;
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: 'PRODUCT',
                LABEL: 'BASIC_DATA.PRODUCT'
            },
            {
                PROPERTY: 'COUNTRY',
                LABEL: 'ROUTE.COUNTRY'
            },
            {
                PROPERTY: 'ROUTING_POINTS',
                LABEL: 'ROUTE.ROUTING_POINT'
            },
            {
                PROPERTY: 'TYPE_CARGO',
                LABEL: 'BASIC_DATA.CARGO_TYPE'
            },
            {
                PROPERTY: 'PROCESS_TYPE',
                LABEL: 'BASIC_DATA.FILE_TYPE'
            },
            {
                PROPERTY: 'COMMODITY_SECTION',
                LABEL: 'GENERAL.COMMODITY_GROUP'
            },
            {
                PROPERTY: 'ACCOUNTS',
                LABEL: 'BASIC_DATA.CLIENT'
            },
            {
                PROPERTY: 'MASTER_DIRECT',
                LABEL: 'BASIC_DATA.STRAIGHT_BL'
            },
            {
                PROPERTY: 'LOCAL_AGENT',
                LABEL: 'BASIC_DATA.LOCAL_AGENT'
            },
            {
                PROPERTY: 'EXTERNAL_AGENT',
                LABEL: 'BASIC_DATA.OVERSEAS_AGENT'
            },
            {
                PROPERTY: 'OTHER_AGENT',
                LABEL: 'BASIC_DATA.THIRD_AGENT'
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'ID',
                LABEL: 'GENERAL.IDENTIFIER'
            },
            {
                PROPERTY: 'CODE',
                LABEL: 'GENERAL.CODE'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            },
            {
                PROPERTY: 'TYPE_ROUTING_POINTS',
                LABEL: 'ROUTE.LOCAL_TYPE'
            },
            {
                PROPERTY: 'TYPE',
                LABEL: 'GENERAL.TYPE'
            }
        ]
        return props;
    }

    private filterTypeRoutingPointList(): SelectorModel[] {
        const result: SelectorModel[] = [];
        try {
            if (this.$scope.typeRoutingPointList && this.$scope.typeRoutingPointList.length > 0 && this.$scope.model && this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.length) {
                const terminalType = this.$scope.typeRoutingPointList.find(item => item.ID == ETypeLocalId.TERMINAL);
                const doorType = this.$scope.typeRoutingPointList.find(item => item.ID == ETypeLocalId.DOOR);
                if (terminalType) result.push(terminalType);
                if (doorType) result.push(doorType);
                if (this.$scope.model.PRODUCT.some(product => product.ID == EProductId.AIR_EXPORT || product.ID == EProductId.AIR_IMPORT)) {
                    result.push(this.$scope.typeRoutingPointList.find(item => item.ID == ETypeLocalId.AIRPORT));
                } else if (this.$scope.model.PRODUCT.some(product => product.ID == EProductId.MARITIME_EXPORT || product.ID == EProductId.MARITIME_IMPORT)) {
                    result.push(this.$scope.typeRoutingPointList.find(item => item.ID == ETypeLocalId.PORT));
                }
            }

        } catch (ex) {
            this.handleError(ex);
        } finally {
            return result;
        }
    }

    private async getRoutingPointListByName(name?: string): Promise<IRoutingPointSelector[]> {
        let result = [];
        if (!this.$scope.model.TYPE_ROUTING_POINTS || this.$scope.model.TYPE_ROUTING_POINTS.length === 0) throw Error(`Select a type first`);
        this.formService.block();
        try {
            const types = this.$scope.model.TYPE_ROUTING_POINTS.map(item => item.ID);
            const routingPoints = await this.RestService.newObjectPromise(`${this.$baseUrl}/routingPoint/list/custom`, { name: name, types }, 30000, false);
            if (routingPoints) result = routingPoints.map(routingPoint => { return { ID: routingPoint.ID, NAME: routingPoint.NAME, CODE: routingPoint.CODE, TYPE: routingPoint.TYPE } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getAccountListByName(name?: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const accounts = await this.RestService.newObjectPromise(`${this.$baseUrl}/account/list/custom`, { search: name }, 30000, false);
            if (accounts) result = accounts.map(account => { return { ID: account.ID, NAME: account.NAME, CODE: account.CODE } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.onAgentChange();
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            await this.clearFields(this.$scope.model, ['CONCATENATED']);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === "edit" ? "update" : "insert";

        return {
            route: `/agentsParams/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: this.$scope.scopeBeforeSave
        };
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/agentsParams/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }
}

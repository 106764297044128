import * as angular from 'angular';

const dataManagerPreviewState = {
    name: 'viewDataManager',
    url: '/viewDataManager/:hash',
    template: require("./view/dataManagerPreview.html"),
    params: { hash: undefined }
}

const errorState = {
    name: 'error',
    url: '/error',
    template: require("./view/error.html")
}

routeConfig.$inject = ['$uiRouterProvider'];
function routeConfig($uiRouterProvider) {
    const $urlService = $uiRouterProvider.urlService;
    $urlService.rules.otherwise(function ($injector) {
        const $state = $injector.get("$state");
        $state.go('error');
    });

    const $stateRegistry = $uiRouterProvider.stateRegistry;
    $stateRegistry.register(dataManagerPreviewState);
    $stateRegistry.register(errorState);
}

export default function initRoute(moduleName: string): void {
    angular.module(moduleName).config(routeConfig);
}

import * as angular from "angular";
import { IColumnDef } from "ui-grid";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequest } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { FollowUpModel } from "@models/interface/operational/FollowUpModel";
import Tribute from "tributejs/src";
import { SelectorModel } from "../../common/model/SelectorModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { ExternalService } from "@services/ExternalService";
import { HelperService } from "@services/HelperService";

interface IFollowUpModelScope extends IGridFormServiceScope {
    model: FollowUpModel;
    languages: SelectorModel[];
    log: IViewLog;
    scopeBeforeSave: FollowUpModel;
    customLogProperties: ICustomLogProperties[];
}

export class FollowUpModelRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IFollowUpModelScope;
    private $timeout: ng.ITimeoutService
    private $q: ng.IQService;
    private RestService: IRestService;
    private ExternalService: ExternalService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IFollowUpModelScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$timeout = $injector.get('$timeout');
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.ExternalService = $injector.get('ExternalService');
        this.$baseUrl = this.config.externalUrl + '/external';
        this.helperService = $injector.get('HelperService');
    }

    async $onInit(): Promise<void> {
        try {
            this.initForm(this, 'form', 'FollowUpModel', 'GENERAL.MENU.FOLLOW_UP_MODEL', true);

            this.block();

            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.MENU.FOLLOW_UP_MODEL');
            this.setCopyable(false);
            this.setDeletable(true);
            await this.initGrid('gridFollowUpModel', '/followUpModel/list/', true);

            this.unblock();

        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
        //$interval.cancel(updateDataTask);
    }

    async initScopeFunctions(): Promise<void> {
        try {
            this.$scope.model = <FollowUpModel>{};

            const self: FollowUpModelRegisterController = this;
            this.$scope.viewLog = (entity) => {
                this.viewLog(entity);
            }

            const result = await this.getHint();

            document.getElementById('subject').addEventListener('tribute-replaced', function (e) {
                let select = $("#subject").val().toString().indexOf("[]");
                if (select > -1)
                    self.setCaretToPos($("#subject")[0], (select + 1));
            });
            document.getElementById('modelEditor').addEventListener('tribute-replaced', function (e) {
                //TODO: fix, unable do detect '[]' because listener is too fast. Find a way yo delay it;
                //setTimeout(() => {
                let select = $("#modelEditor").val().toString().indexOf("[]");
                if (select > -1)
                    self.setCaretToPos($("#trix-editor")[0], (select + 1));
                //}, 1000);
            });

            let tribute = new Tribute({
                trigger: '@',
                values: result.data.data,
                selectTemplate: function (item) {
                    return '{{' + item.original.value + '}}';
                },
                noMatchTemplate: function (tribute) {
                    return '<li>Tag inválida</li>';
                },
                menuContainer: document.getElementById('hintContainer')
            });
            tribute.attach($('trix-editor'));
            tribute.attach($('#subject'));
        } catch (ex) {
            this.handleError(ex);
        }
    }

    setSelectionRange(input, selectionStart, selectionEnd) {
        /*if (input.setSelectionRange) {
            input.focus();
            input.setSelectionRange(selectionStart, selectionEnd);
        } else if (input.createTextRange) {
            var range = input.createTextRange();
            range.collapse(true);
            range.moveEnd('character', selectionEnd);
            range.moveStart('character', selectionStart);
            range.select();
        }*/
    }
    setCaretToPos(input, pos) {
        this.setSelectionRange(input, pos, pos);
    }

    initGridColumns(columns) {

        let columnDefs: Array<IColumnDef> = new Array();

        //action column, contain just buttons and icons
        const view = `<div class="text-center"><a ng-click="grid.appScope.view(row.entity)" class="text-info"tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate}}" tooltip-append-to-body="true" ><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`
        const edit = `<a ng-click="grid.appScope.edit(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`
        const history = `<a ng-click="grid.appScope.viewLog(row.entity)" class="text-green" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a></div>`

        columnDefs.push({
            name: "acoes",
            displayName: 'GENERAL.ACTIONS',
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + history),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        let NewColumnDefs = this.buildColunms(columns);
        for (let column of NewColumnDefs) { columnDefs.push(column) }
        return columnDefs;
    }

    buildColunms(column, prop?): any {
        try {
            let columnDefs = new Array();
            for (var property in column) {
                if (typeof (column[property]) === 'string') { //finally reached the bottom
                    let currentProperty = column[property];
                    let name = null;
                    let displayName = null;
                    let width = 0;
                    let cellTemplate = undefined;
                    let cellFilter = null;
                    let grouping = null;
                    let sort = null;
                    let pinned = null;
                    let visible = true;
                    let filter = { condition: this.gridService.filterSelectObject };
                    switch (currentProperty.toUpperCase()) {
                        case 'ID':
                            name = currentProperty;
                            displayName = 'REGISTRATION.IDENTIFICATION';
                            width = currentProperty.length + 3;
                            break;
                        case 'NAME':
                            name = currentProperty;
                            displayName = 'GENERAL.NAME';
                            width = currentProperty.length + 15;
                            break;
                        case 'SUBJECT':
                            name = currentProperty;
                            displayName = 'REGISTRATION.SUBJECT';
                            width = currentProperty.length + 15;
                            break;
                        case 'STATUS':
                            name = currentProperty + '.NAME';
                            displayName = 'GENERAL.ACTIVE';
                            width = currentProperty.length + 4;
                            break;
                        case 'LANGUAGE':
                            name = currentProperty + '.NAME';
                            displayName = 'GENERAL.LANGUAGE';
                            width = currentProperty.length + 1;
                            break;
                        default:
                            name = currentProperty;
                            width = currentProperty.length;
                            visible = false;
                            break;
                    };
                    const newColumn = {
                        name: (prop) ? prop + '.' + name : name,
                        displayName: displayName,
                        headerCellClass: 'header-filtered',
                        width: width + '%',
                        cellTemplate: cellTemplate,
                        cellFilter: cellFilter,
                        visible: visible,
                        filter: filter
                    };
                    columnDefs.push(newColumn);
                } else {
                    let newCol = this.buildColunms(column[property], property);
                    if (newCol)
                        for (let dados of newCol) {
                            columnDefs.push(dados);
                        }
                }
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            NAME: null,
            SUBJECT: null,
            CONTENT: null,
            STATUS: null,
            LANGUAGE: { ID: '1', NAME: 'pt-BR' }
        }
    }

    initDependencies(): Promise<any> {
        const self: FollowUpModelRegisterController = this;
        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericList('language'),
            ]).then((result: Array<any>) => {
                self.$scope.languages = result[0];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getGenericList(type): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private getHint(): Promise<any> {
        const request = {
            "conditionalArrays": true,
            "respectBound": true,
            "models": ["PROCESS", "CHARGE", "DEADLINE", "STOPOVER", "DOCUMENT", "INVOICE", "TASK"]
        };
        return this.ExternalService.post({ route: "/followUpModel/model/hint", data: request })
    }

    private requestHistory(id: string): Promise<any> {
        return this.ExternalService.get({ route: `/followUpModel/viewLog/${id}` });
    }

    async view(): Promise<void> {
        BrowserTitle.$id = this.$scope.model.NAME;

        $('trix-editor').css('pointer-events', 'none');
    }

    async edit(): Promise<void> {
        BrowserTitle.$id = this.$scope.model.NAME;

        $('trix-editor').css('pointer-events', 'all');

        this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
    }

    async request(): Promise<IMonacoRequest> {
        let request;
        if (this.$scope.operation === 'delete') {
            request = {
                route: `/followUpModel/delete/${this.$scope.model._id}`,
                timeout: 15000
            };
        } else {
            request = {
                route: `/followUpModel/insert`,
                data: {
                    model: angular.copy(this.$scope.model),
                    beforeScope: angular.copy(this.$scope.scopeBeforeSave),
                },
                operation: this.$scope.operation,
                timeout: 15000
            };
        }
        return request;
    }

    async save(): Promise<boolean> {
        this.$scope.$applyAsync();

        if (this.$scope.model.CONTENT && this.$scope.model.CONTENT.length > 0) {
            return true;
        } else {
            this.handleWarning('A mensagem do modelo de acompanhamento não pode estar vazia.');
            return false;
        }
    }

    private viewLog(followUpModel) {
        this.block();
        let log: IViewLog = {
            operation: 'history',
            number: followUpModel.ID,
            list: [],
            show: true,
            searchQuery: '',
            originalList: [],
        }
        this.requestHistory(log.number).then(result => {
            log.list = result && result.data && result.data.data;
            log.originalList = angular.copy(log.list);
            this.$scope.log = log;
            angular.element('#log-viewer').removeClass('ng-hide');
            const position = angular.element('#log-viewer').offset().top + $('.app-content-body').scrollTop() - 105;
            $('.app-content-body').animate({
                scrollTop: position
            }, 500);

            this.unblock();
        }).catch(ex => {
            this.handleError(ex);
        });
    };

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'LANGUAGE',
                LABEL: 'GENERAL.LANGUAGE'
            },
            {
                PROPERTY: 'CONTENT',
                LABEL: 'OPERATIONAL.CONTENT'
            },
            {
                PROPERTY: 'SUBJECT',
                LABEL: 'REGISTRATION.SUBJECT'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            },
        ];
        return props;
    }
}
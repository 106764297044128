import angular = require('angular');
import * as Address from '../../communication/Address';
import { IRestService } from "@services/RestService";
import { IModalService, IFileReferencePreSetFields, IFileReferenceDisabledFields } from '@services/ModalService';
import { IDownloadParamsReturn } from '@services/DownloadFilesGenericService';
import { ISelectorModel } from '@models/mongo/SelectorModel';
import { REFERENCE } from '@models/interface/operational/NewProcess';
import { IConsolidatedReference as IConsolidatedReferenceModel } from '@models/interface/operational/consolidated/ConsolidatedReference';
import { IUploader } from '@models/interface/common/IMonacoUpload';
import { EReportCategoryName } from '@enums/GenericData';
import { IConsolidatedWizardModalScope } from './ConsolidatedWizardModalController';
import { IFiles } from '../../app/controllers/FileReferenceModalController';
import { HelperService } from '@services/HelperService';
import { DataProcessService } from '@services/DataProcessService';
import { GridFormService, IGridFormServiceScope } from '@services/GridFormService';
import { EOperation } from '@enums/GenericData';

interface IReferenceModalScope extends IGridFormServiceScope {
    references: REFERENCE[];
    oldReferences: REFERENCE[];
    viewFile: (file: REFERENCE) => void;
    downloadFile: (file: REFERENCE) => Promise<void>;
    copyFileLinkToClipboard: (file: REFERENCE) => void;
    viewConsolidatedReferenceModal: (file: REFERENCE) => Promise<void>;
    editConsolidatedReferenceModal: (index: number) => Promise<void>;
    fileReferenceModal: () => void;
    removeReference: (index: number) => void;
}

interface IConsolidatedDocumentWizardPanelScope extends IReferenceModalScope {
    model: IConsolidatedReferenceModel;
    oldModel: IConsolidatedReferenceModel;
    documentTypeList: ISelectorModel[];
    fileGroupList: ISelectorModel[];
    fileSpecList: ISelectorModel[];
    uploader: IUploader;
    downloadRoute: string;
}

export class ConsolidatedDocumentWizardPanelController extends GridFormService<IConsolidatedReferenceModel>{
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IConsolidatedDocumentWizardPanelScope;
    private $consolidatedScope: IConsolidatedWizardModalScope;
    private $q: ng.IQService;
    private restService: IRestService;
    private modalService: IModalService;
    private fileReferenceModalID: number;
    private helperService: HelperService;
    private dataProcessService: DataProcessService;

    constructor($injector: ng.Injectable<any>, $scope: IConsolidatedDocumentWizardPanelScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$consolidatedScope = <IConsolidatedWizardModalScope>$scope.$parent.$parent;
        this.$q = $injector.get('$q');
        this.helperService = $injector.get('HelperService');
        this.restService = $injector.get('RestService');
        this.modalService = $injector.get('ModalService');
        this.dataProcessService = $injector.get('DataProcessService');
        this.initScopeFunctions();

        if (this.config.environment === 'prod') {
            this.$scope.downloadRoute = `${Address.monacoAddressProd.FIS}/api/v1/fis/filesGeneric/download`;
        } else if (this.config.environment === 'qa') {
            this.$scope.downloadRoute = `${Address.monacoAddressQa.FIS}/api/v1/fis/filesGeneric/download`;
        } else { //dev
            this.$scope.downloadRoute = `${Address.monacoAddressLocal.FIS}/api/v1/fis/filesGeneric/download`;
        }
    }

    async $onInit(): Promise<void> {
        try {
            this.initModel();
            await this.initDependencies();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initModel(): void {
        this.$scope.references = this.$consolidatedScope.model.CONSOLIDATED_REFERENCE;
    }

    private initScopeFunctions(): void {
        this.$scope.viewFile = (file: REFERENCE): void => {
            if (file) window.open(file.LINK, "_blank");
        }

        this.$scope.downloadFile = async (file: REFERENCE): Promise<void> => {
            if (file) {
                const linkBroken: string[] = file.LINK.split('/');
                const hash: string = linkBroken[linkBroken.length - 1];
                this.downloadFileFromGoogleStorage(hash);
            }
        }

        this.$scope.copyFileLinkToClipboard = (file: REFERENCE) => {
            if (file) {
                const elmAux = document.createElement("textarea");
                document.body.appendChild(elmAux);
                elmAux.value = file.LINK;
                elmAux.select();
                document.execCommand("copy");
                document.body.removeChild(elmAux);
                this.formService.notifySuccess("Link do arquivo foi copiado para o clipboard.");
            }
        }

        this.$scope.viewConsolidatedReferenceModal = async (file: REFERENCE) => {
            const disabledFields: IFileReferenceDisabledFields = {
                documentType: true,
                documentValue: true,
                fileGroup: true,
                fileSpecs: true,
                name: true,
                templateType: true,
                documentSequence: true,
                configuration: true,
                productType: true,
                telephone: true,
                technicalManager: true,
                email: true,
            };
            const extensionRemoveList: string[] = file.NAME ? file.NAME.split('.') : null;
            const extension: string = (extensionRemoveList && extensionRemoveList.length > 0) ? extensionRemoveList[extensionRemoveList.length - 1] : null;
            const fileModel: IFiles = {
                FILES: file.LINK && file.LINK.length ? [
                    {
                        FILE_DISPLAY_NAME: file.NAME ? file.NAME : file.DOCUMENT_VALUE,
                        FILE_NAME: file.NAME,
                        FILE_TYPE: extension,
                        FILE_PATH: null,
                        FILE_URL: file.LINK,
                        FILE_HASH: null,
                        FORM_NAME: "consolidatedReference"
                    }
                ] : []
            };
            await this.fileReferenceModal(file, null, disabledFields, true, fileModel);
        }

        this.$scope.editConsolidatedReferenceModal = async (index: number) => {
            const file = this.$scope.references[index];
            const extensionRemoveList: string[] = file.NAME ? file.NAME.split('.') : null;
            const extension: string = (extensionRemoveList && extensionRemoveList.length > 0) ? extensionRemoveList[extensionRemoveList.length - 1] : null;
            const fileModel: IFiles = {
                FILES: file.LINK && file.LINK.length ? [
                    {
                        FILE_DISPLAY_NAME: file.NAME ? file.NAME : file.DOCUMENT_VALUE,
                        FILE_NAME: file.NAME,
                        FILE_TYPE: extension,
                        FILE_PATH: null,
                        FILE_URL: file.LINK,
                        FILE_HASH: null,
                        FORM_NAME: "consolidatedReference"
                    }
                ] : []
            };
            const modalResponse = await this.fileReferenceModal(angular.copy(file), null, null, true, fileModel, true);
            if (modalResponse) {
                const msg = this.formService.getTranslate('BASIC_DATA.REFERENCE_SUCESSFULLY_UPDATED');
                this.formService.notifySuccess(msg);
                this.$scope.$applyAsync();
            }
        }

        this.$scope.fileReferenceModal = async () => {
            const modalResponse = await this.fileReferenceModal(null);
            if (modalResponse) {
                const msg = this.formService.getTranslate('BASIC_DATA.REFERENCE_SUCESSFULLY_ADDED');
                this.formService.notifySuccess(msg);
            }
        }

        this.$scope.removeReference = async (index: number) => {
            if (!index && index != 0) throw Error('index is null');

            const modal = await this.modalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });
            if (!modal) return;

            const resultOperation = await this.dataProcessService.post('/consolidatedReference/delete', { referenceId: this.$scope.references[index].REFERENCE_ID, consolidatedProcess: this.$consolidatedScope.model.CONSOLIDATED_PROCESS }, 30000);
            if (resultOperation && resultOperation.data && resultOperation.data.data) {
                const result = resultOperation.data.data;
                if (result && this.$scope.references && this.$scope.references.length > 0) this.$scope.references.splice(index, 1);
            }
        }
    }

    async initDependencies(): Promise<any> {
        const self: ConsolidatedDocumentWizardPanelController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
            ]).then(async (result: any) => {
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private getContentType(fileType: string): string {
        switch (fileType.toLowerCase()) {
            case 'arc':
                return 'application/octet-stream';
            case 'bin':
                return 'application/octet-stream';
            case 'avi':
                return 'video/x-msvideo';
            case 'azw':
                return 'application/vnd.amazon.ebook';
            case 'gif':
                return 'image/gif';
            case 'htm':
                return 'text/html';
            case 'html':
                return 'text/html';
            case 'ico':
                return 'image/x-icon';
            case 'ics':
                return 'text/calendar';
            case 'js':
                return 'application/javascript';
            case 'json':
                return 'application/json';
            case 'mpeg':
                return 'video/mpeg';
            case 'jpeg':
                return 'image/jpeg';
            case 'jpg':
                return 'image/jpg';
            case 'png':
                return 'image/png';
            case 'pdf':
                return 'application/pdf';
            case 'rar':
                return 'application/x-rar-compressed';
            case 'zip':
                return 'application/zip';
            case '7z':
                return 'application/x-7z-compressed';
            case 'ppt':
                return 'application/vnd.ms-powerpoint';
            case 'csv':
                return 'text/csv';
            case 'doc':
                return 'application/msword';
            case 'xls':
                return 'application/vnd.ms-excel';
            case 'xlsx':
                return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            case 'vsd':
                return 'application/vnd.visio';
            case 'ts':
                return 'application/typescript';
            case 'rtf':
                return 'application/rtf';
            default:
                return 'text/plain';
        }
    }

    public async downloadFileFromGoogleStorage(hash: string): Promise<void> {
        try {
            this.formService.block();
            if (!hash) return null;

            const operation = await this.restService.getObjectAsPromise(`${this.$scope.downloadRoute}/${hash}`, 30000, null, false);
            const response: angular.IHttpResponse<any> = operation;
            if (response && response.data) {
                const resultFile = <IDownloadParamsReturn>response.data;

                const file = resultFile.file;
                if (!resultFile.buffer) {
                    throw Error('Failed to get file information');
                }
                const buffer = resultFile.buffer.data;
                if (!file || !buffer) {
                    throw Error('Failed to get file information');
                }
                const fileName = file.fileName;
                const fileBuffer = new Uint8Array(buffer);
                const fileType = this.getContentType(file.fileType);
                const fileBlob = new Blob([fileBuffer], { type: fileType });
                const fileURL = window.URL.createObjectURL(fileBlob);

                let link = document.createElement('a');
                link.href = fileURL;
                link.download = fileName;
                link.click();
            } else {
                throw Error('Error on download file')
            }

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async fileReferenceModal(file: REFERENCE, preSetFields?: IFileReferencePreSetFields, disabledFields?: IFileReferenceDisabledFields, uploadDisabled?: boolean, fileModel?: IFiles, isEdit?: boolean) {
        try {

            this.fileReferenceModalID = this.modalService.newModal();
            const modalInstance = await this.modalService.showModalInfo(
                {
                    template: require("../view/consolidatedReferenceModal.html"),
                    modalID: this.fileReferenceModalID,
                    formService: EOperation.NEW,
                    size: 'lg',
                    keyboard: true,
                    scope: this.$consolidatedScope
                },
                {
                    actionButtonText: "GENERAL.CLOSE",
                    headerText: "GENERAL.ADD_REFERENCE_ATTACHMENT"
                },
                {
                    categoryName: [
                        EReportCategoryName.Process,
                        EReportCategoryName.BookingConfirmation,
                        EReportCategoryName.Cover,
                        EReportCategoryName.ShippingInstruction,
                        EReportCategoryName.PreAlert,
                        EReportCategoryName.FMC,
                        EReportCategoryName.ProtestLetter,
                        EReportCategoryName.ProtestLetterIA,
                        EReportCategoryName.BillofLadingInstructions,
                        EReportCategoryName.SolicitationTC4,
                        EReportCategoryName.SolicitationRefrigerator,
                        EReportCategoryName.ExemptionLetter,
                        EReportCategoryName.Minuta,
                        EReportCategoryName.CartaSeguranca,
                        EReportCategoryName.CartaSegurancaAdicional,
                        EReportCategoryName.DeclaracaoSeguranca,
                        EReportCategoryName.CargoReadinessHistory
                    ],
                    preSetFields: preSetFields,
                    disabledFields: disabledFields,
                    uploadDisabled: uploadDisabled,
                    file: file,
                    fileModel: fileModel,
                    isEdit: isEdit
                }
            );

            const modalResponse = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (modalResponse || !modalResponse) {
                this.modalService.closeModal(this.fileReferenceModalID);
                this.$consolidatedScope.model = await this.$consolidatedScope.getConsolidatedById(this.$consolidatedScope.model.ID);
                this.$consolidatedScope.$applyAsync();
                this.$scope.references = this.$consolidatedScope.model.CONSOLIDATED_REFERENCE;
            }

            return modalResponse;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    // private async applyReference(references: REFERENCE[], close?: boolean): Promise<boolean> {
    //     let success = false;
    //     try {
    //         this.formService.block();
    //         const updateReferenceResponse = await this.operationalService.post('/process/updateReference', { processNumber: this.$scope.processNumber, data: { REFERENCE: references } }, 30000);
    //         if (updateReferenceResponse && updateReferenceResponse.status == 200) {
    //             success = true;
    //         }
    //         if (close) {
    //             this.modalService.closeModal(this.referenceModalID);
    //         }
    //     } catch (ex) {
    //         this.formService.handleError(ex);
    //     } finally {
    //         this.formService.unblock();
    //         if (success) {
    //             const msg = this.formService.getTranslate('BASIC_DATA.REFERENCE_SUCESSFULLY_UPDATED');
    //             this.formService.notifySuccess(msg);
    //         }
    //         return success;
    //     }
    // }
}

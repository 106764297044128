export const enum EEventTrackingStatus {
    AWAITING_PROCESSING = "0",
    VALIDATED = "1",
    ERROR = "2",
    FIELD_DIVERGENCE = "3",
    DIVERGING_CONTAINERS = "4",
    PROCESSED = "5",
    DIVERGENT_FILL_PARAMETRIZATION = "6",
    MANUALLY_VALIDATED = "7",
}

export const enum EProcessEventTrackingStatus {
    LOCAL = "1",
    SHIP = "2",
    VOYAGE = "3",
    SERVICE_PROVIDER = "4"
}
import * as angular from "angular";
import { IColumnDef } from "ui-grid";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog, } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { GridService2, IMonacoColumnDef } from "@services/GridService2";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { DataManagerReportModel, IReportDataColumnDef, SITUATION, USER, IBatchReportResult } from "../model/DataManagerReportModel";
import { SelectorModel } from "../../common/model/SelectorModel";
import { IGridDataReturn } from "../../common/interface/IGridDataReturn";
import { BrowserTitle } from "../../common/BrowserTitle";
import { HelperService } from "@services/HelperService";

interface IDataManagerReportScope extends IGridFormServiceScope {
    model: DataManagerReportModel;
    data_report_situations: SITUATION;
    data_report_users: USER[];
    scopeBeforeSave: DataManagerReportModel;
    gridDataManagerVisibleStatus: boolean;
    gridDataCount: number;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    batchReportList: IBatchReportResult[];
    batchReportID: number;
    showBatchReport: Boolean;
    originalBatchReportList: IBatchReportResult[];
    batchReportTimer: number;
    batchReportAutoRefresh: boolean;
    batchReportTimeToRefresh: number;
    user: any;
    sendToGenerate(entity: DataManagerReportModel);
    dataReportStatusIcon(value: boolean): string;
    getConcatInformation(value: USER[]): string;
    simulateData: () => void;
    hideBatchReport();
    downloadFile(url: string);
}

export class DataManagerReportController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IDataManagerReportScope;
    private $timeout: ng.ITimeoutService
    private $q: ng.IQService;
    private RestService: IRestService;
    private helperService: HelperService;
    private dataManagerGrid: GridService2;
    private dataManagerGridColDef: uiGrid.IColumnDef[];

    constructor($injector: ng.Injectable<any>, $scope: IDataManagerReportScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$timeout = $injector.get('$timeout');
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.helperService = $injector.get('HelperService');
        this.$scope.batchReportTimeToRefresh = 5000;
    }

    async getGenericValues(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    async getAllUsers() {
        const result = await this.helperService.post('/user/list/', null, 10000);
        return result.data.data;
    }

    requestHistory(id: string): Promise<any> {
        return this.RestService.getObjectAsPromise(`${this.getUrlDataManager()}/report/history/` + id, 10000, null, false);
    }

    sendToGenerate(id: Number) {
        return this.RestService.newObject(`${this.getUrlDataStart()}/send-report-to-generate`, { idReport: id }, 10000, false);
    }

    getUrlDataManager() {
        const baseRoute = '/data-manager';
        const urlDataManager = this.config.reportDataManagerUrl + baseRoute;
        return urlDataManager;
    }

    getUrlDataBuilder() {
        const baseRoute = '/data-builder';
        const urlDataManager = this.config.reportDataBuilderUrl + baseRoute;
        return urlDataManager;
    }

    getUrlDataStart() {
        const baseRoute = '/data-start';
        const urlDataManager = this.config.reportDataStartUrl + baseRoute;
        return urlDataManager;
    }

    getBatchReportById(id: Number) {
        return this.RestService.getObjectAsPromise(`${this.getUrlDataManager()}/batchreport/list/` + id, 10000, null, false);
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlDataManager();
            this.$scope.gridDataManagerVisibleStatus = false;
            this.$scope.gridDataCount = 0;

            this.setDeletable(true);

            this.$scope.customLogProperties = [
                { PROPERTY: 'REPORT_NAME', LABEL: 'GENERAL.REPORT_NAME' },
                { PROPERTY: 'PROCESS', LABEL: 'OPERATIONAL.FILE_NUMBER' },
                { PROPERTY: 'INVOICE', LABEL: 'GENERAL.INVOICES' },
                { PROPERTY: 'CHARGE', LABEL: 'GENERAL.CHARGES' },
                { PROPERTY: 'SITUATION', LABEL: 'GENERAL.SITUATION' },
                { PROPERTY: 'CELLS', LABEL: 'GENERAL.AUTHORIZED_CELLS' },
                { PROPERTY: 'USERS', LABEL: 'GENERAL.AUTHORIZED_USERS' },
                { PROPERTY: 'CREATED_BY', LABEL: 'GENERAL.CREATED_BY' },
                { PROPERTY: 'alias', LABEL: 'GENERAL.FIELDS_LABEL' },
                { PROPERTY: 'filters', LABEL: 'REGISTRATION.FILTERS' },
                { PROPERTY: 'name', LABEL: 'GENERAL.NAME' },
                { PROPERTY: 'sort', LABEL: 'GENERAL.SORT_BY' },
                { PROPERTY: 'visible', LABEL: 'GENERAL.SHOW_FIELD' },
                { PROPERTY: 'width', LABEL: 'REGISTRATION.WIDTH_METERS' },
                { PROPERTY: 'CREATED_DATE', LABEL: 'GENERAL.CREATED_AT' },
                { PROPERTY: 'ID', LABEL: 'GENERAL.ID' },
                { PROPERTY: 'NAME', LABEL: 'GENERAL.NAME' },
                { PROPERTY: 'CODE', LABEL: 'GENERAL.CODE' },
                { PROPERTY: 'COLUMN', LABEL: 'GENERAL.COLUMNS' }
            ];

            this.initForm(this, 'form', 'report', this.formService.getTranslate("GENERAL.NEW_REPORT"), true);
            await this.initGrid('gridDataManager', '/report/list', true, true);

        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.dataReportStatusIcon = (value: boolean) => {
            return this.dataReportStatusIcon(value);
        }

        this.$scope.getConcatInformation = (originalValue: USER[]) => {
            return this.formService.getCONCAT(originalValue);
        }

        this.$scope.simulateData = async () => {
            await this.simulateData();
        }

        this.$scope.viewLog = (entity) => {
            this.viewLog(entity);
        }

        this.$scope.hideBatchReport = () => {
            this.hideBatchReport();
        }

        this.$scope.downloadFile = (url: string) => {
            this.downloadFile(url);
        }

        this.$scope.sendToGenerate = async (entity: DataManagerReportModel) => {
            try {
                this.block();
                const result = await this.sendToGenerate(entity._id);
                this.unblock();
                if (result.data.message == 'Success') {

                    await this.getItemBatchReport(entity);

                    if (this.$scope.batchReportAutoRefresh && !this.$scope.batchReportTimer) {
                        this.$scope.batchReportTimer = window.setInterval(() => {
                            this.manageRefreshItemBatchReport(this.$scope.model);
                        }, this.$scope.batchReportTimeToRefresh);
                    }

                    return this.formService.notifySuccess(this.formService.getTranslate("GENERAL.GENERATING_REPORT"));
                }
                return this.formService.notifyError(this.formService.getTranslate("GENERAL.FAIL_GENERATE_REPORT"));

            } catch (ex) {
                this.handleError(ex);
            }
        };
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            REPORT_NAME: null,
            PROCESS: true,
            INVOICE: null,
            CHARGE: null,
            SITUATION: { ID: '1', NAME: 'Ativo', CODE: null },
            CELLS: null,
            USERS: null,
            COLUMN: null,
            CREATED_BY: { ID: this.$rootScope.user._id, NAME: this.$rootScope.user.email, CODE: null },
            CREATED_DATE: new Date(),
            MODIFIED_BY: null,
            MODIFIED_DATE: null
        };
    }

    initGridColumns(columns) {
        let columnDefs: Array<IColumnDef> = new Array();

        const view = `<div class="text-center"><a ng-click="grid.appScope.view(row.entity)" class="text-info"tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`
        const edit = `<a ng-click="grid.appScope.edit(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`
        const copy = `<a ng-click="grid.appScope.copy(row.entity)" class="text-orange"   tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`
        const history = `<a ng-click="grid.appScope.viewLog(row.entity)" class="text-green"   tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a></div>`

        columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 110,
            maxWidth: 110,
            cellTemplate: (view + edit + copy + history),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        for (let i = 0; i < columns.length; i++) {
            let name = null;
            let displayName = null;
            let width = 0;
            let cellTemplate = undefined;
            let cellFilter = null;
            let grouping = null;
            let sort = null;
            let pinned = null;
            let visible = true;

            switch (columns[i].toUpperCase()) {
                case 'ID':
                    name = columns[i];
                    displayName = 'GENERAL.ID';
                    width = columns[i].length + 2;
                    break;
                case 'REPORT_NAME':
                    name = columns[i];
                    displayName = 'GENERAL.REPORT_NAME';
                    width = columns[i].length + 15;
                    break;
                case 'PROCESS':
                    name = columns[i];
                    displayName = 'OPERATIONAL.FILE_NUMBER';
                    width = columns[i].length + 1;
                    cellTemplate = `<div class="text-center" style="margin-top: 4px;"><i class="fa {{grid.appScope.dataReportStatusIcon(row.entity.PROCESS)}}"></i></div>`;
                    break;
                case 'INVOICE':
                    name = columns[i];
                    displayName = 'GENERAL.INVOICES';
                    width = columns[i].length + 1;
                    cellTemplate = '<div class="text-center" style="margin-top: 4px;"><i class="fa {{grid.appScope.dataReportStatusIcon(row.entity.INVOICE)}}"></i></div>';
                    break;
                case 'CHARGE':
                    name = columns[i];
                    displayName = 'GENERAL.CHARGES';
                    width = columns[i].length + 1;
                    cellTemplate = '<div class="text-center" style="margin-top: 4px;"><i class="fa {{grid.appScope.dataReportStatusIcon(row.entity.CHARGE)}}"></i></div>';
                    break;
                case 'USERS':
                    name = columns[i] + '.NAME';
                    displayName = 'GENERAL.AUTHORIZED_USERS';
                    width = columns[i].length + 10;
                    cellTemplate = '<div class="grid-padding">{{grid.appScope.getConcatInformation(row.entity.USERS)}}</div>';
                    break;
                case 'CELLS':
                    name = columns[i] + '.NAME';
                    displayName = 'GENERAL.AUTHORIZED_CELLS';
                    width = columns[i].length + 10;
                    cellTemplate = '<div class="grid-padding">{{grid.appScope.getConcatInformation(row.entity.CELLS)}}</div>';
                    break;
                case 'SITUATION':
                    name = columns[i] + '.NAME';
                    displayName = 'GENERAL.SITUATION';
                    width = columns[i].length + 1;
                    break;
                case 'CREATED_BY':
                    name = columns[i] + '.NAME';
                    displayName = 'GENERAL.CREATED_BY';
                    width = columns[i].length + 10;
                    break;
                case 'CREATED_DATE':
                    name = columns[i];
                    displayName = 'GENERAL.CREATED_AT';
                    cellFilter = 'date:\'dd/MM/yyyy HH:mm:ss\'';
                    width = columns[i].length;
                    break;
                case 'MODIFIED_BY':
                    name = columns[i] + '.NAME';
                    displayName = 'GENERAL.UPDATED_BY';
                    width = columns[i].length + 10;
                    break;
                case 'MODIFIED_DATE':
                    name = columns[i];
                    displayName = 'GENERAL.UPDATED_AT';
                    cellFilter = 'date:\'dd/MM/yyyy HH:mm:ss\'';
                    width = columns[i].length;
                    break;
                default:
                    name = columns[i];
                    width = columns[i].length;
                    visible = false;
                    break;
            }

            const newColumn: IMonacoColumnDef = {
                name: name,
                displayName: displayName,
                headerCellClass: 'header-filtered',
                width: width + '%',
                cellTemplate: cellTemplate,
                cellFilter: cellFilter,
                visible: visible,
                grouping: grouping,
                sort: sort
            };
            columnDefs.push(newColumn);
        }
        return columnDefs;
    }

    async initDependencies(): Promise<boolean> {
        try {
            const result: Array<any> = await this.$q.all([
                this.getGenericValues('data_report_situation'),
                this.getAllUsers()
            ]);

            this.$scope.data_report_situations = result[0].map(item => ({ ID: item.ID, NAME: item.NAME, CODE: null }));
            this.$scope.data_report_users = result[1].data.map(item => {
                const result: SelectorModel = {
                    ID: item._id,
                    NAME: item.displayName,
                    CODE: item.ID,
                }
                return result;
            });

            return true;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private viewLog(DataManagerReportModel) {
        this.$scope.hideBatchReport();
        this.$scope.gridDataManagerVisibleStatus = false;
        this.block();

        let log: IViewLog = {
            operation: 'history',
            number: DataManagerReportModel.ID,
            list: [],
            show: true,
            searchQuery: '',
            originalList: [],
        }

        this.requestHistory(log.number).then(result => {
            log.list = result.data;
            log.originalList = angular.copy(log.list);
            this.$scope.log = log;
            angular.element('#log-viewer').removeClass('ng-hide');
            const position = $('.app-content-body').scrollTop() + angular.element('#log-viewer').offset().top - 105;

            $('.app-content-body').animate({
                scrollTop: position
            }, 500);

            this.unblock();
        }).catch(ex => {
            this.handleError(ex);
        });
    };

    async register(): Promise<void> {
        try {
            this.$scope.hideBatchReport();
            this.$scope.gridDataManagerVisibleStatus = false;
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            await this.getItemBatchReport(this.$scope.model);
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")} (${this.$scope.model.ID})`;
            this.$scope.gridDataManagerVisibleStatus = false;

            if (this.$scope.batchReportAutoRefresh && !this.$scope.batchReportTimer) {

                this.$scope.batchReportTimer = window.setInterval(() => {
                    this.manageRefreshItemBatchReport(this.$scope.model);
                }, this.$scope.batchReportTimeToRefresh);
            }

            BrowserTitle.$id = this.$scope.model.REPORT_NAME;

        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.hideBatchReport();
            this.$scope.gridDataManagerVisibleStatus = false;
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")} (${this.$scope.model.ID})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);

            BrowserTitle.$id = this.$scope.model.REPORT_NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.hideBatchReport();
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.NEW_REPORT")} (${this.formService.getTranslate("GENERAL.COPIED_FROM")} ${this.$scope.model.REPORT_NAME})`;
            this.$scope.scopeBeforeSave = null;
            this.$scope.model.REPORT_NAME = null;
            await this.clearFields(this.$scope.model);
            this.$scope.gridDataManagerVisibleStatus = false;

            if (this.dataManagerGrid && this.dataManagerGrid.$gridOptions) this.dataManagerGrid.$gridOptions.data = null;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        let customRoute: string = '/report';

        switch (this.$scope.operation) {
            case 'delete':
                customRoute += `/remove/${this.$scope.model._id}`;
                break;
            case 'edit':
                customRoute += '/update';
                break;
            default:
                customRoute += '/insert';
                break;
        }

        const request: IMonacoRequestLog = {
            route: customRoute,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            operation: this.$scope.operation,
            timeout: 15000
        }

        return request;
    }

    private dataReportStatusIcon(value: boolean): string {
        let icon: string;

        switch (value) {
            case true:
                icon = 'fa-check text-green';
                break;
            case false:
                icon = 'fa-ban text-danger';
                break;
            default:
                icon = 'fa-ban text-danger';
                break;
        }
        return icon;
    }

    private async restoreReport() {
        try {
            this.block();
            this.dataManagerGrid.$gridOptions.columnDefs = angular.copy(this.dataManagerGridColDef);
            await this.dataManagerGrid.setDefaultView();
            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initGridColumnsData(columns) {
        const columnDefs: Array<IColumnDef> = new Array();

        for (let i = 0; i < columns.length; i++) {
            let name = null;
            let displayName = null;
            let width = 0;
            let cellTemplate = undefined;
            let cellFilter = null;
            let pinned = null;
            let visible = false;
            let filterCellFiltered = true;
            let searchProps = null;
            let filter: uiGrid.IFilterOptions = null;

            switch (columns[i].toUpperCase()) {
                case 'PROCESS_NUMBER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.FILE_NUMBER')}`;
                    width = columns[i].length / 1.4;
                    visible = true;
                    break;
                case 'QUOTATION_OFFER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('BASIC_DATA.OFFER')}`;
                    width = columns[i].length;
                    break;
                case 'FORWARDED_BY':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('GENERAL.FORWARDED_BY')}`;
                    width = columns[i].length;
                    break;
                case 'CUSTOMER':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('BASIC_DATA.CLIENT')}`;
                    width = columns[i].length + 10;
                    break;
                case 'BRANCH':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('GENERAL.COMPANY')}`;
                    width = columns[i].length + 8;
                    break;
                case 'INCOTERM':
                    name = columns[i] + '.CODE';
                    displayName = `${this.formService.getTranslate('BASIC_DATA.INCOTERM')}`;
                    width = columns[i].length;
                    break;
                case 'CARGO_TYPE':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('BASIC_DATA.CARGO_TYPE')}`;
                    width = columns[i].length;
                    break;
                case 'PROCESS_TYPE':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('BASIC_DATA.FILE_TYPE')}`;
                    width = columns[i].length;
                    visible = true;
                    break;
                case 'PROCESS_BRANCH':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('BASIC_DATA.BRANCH')}`;
                    width = columns[i].length + 8;
                    break;
                case 'SERVICE_PROVIDER':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('BASIC_DATA.PROVIDER')}`;
                    width = columns[i].length;
                    break;
                case 'SERVICE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('BASIC_DATA.SERVICE')}`;
                    width = columns[i].length;
                    break;
                case 'SECTION':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('REGISTRATION.CLIENT_SECTOR_TYPE')}`;
                    width = columns[i].length;
                    break;
                case 'TTIME':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.TRANSIT_TIME')}`;
                    width = columns[i].length + 6;
                    break;
                case 'SERVICE_TYPE':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('GENERAL.SERVICE_TYPE')}`;
                    width = columns[i].length;
                    visible = true;
                    break;
                case 'ORIGIN_COUNTRY':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('BASIC_DATA.ORIGIN_COUNTRY')}`;
                    width = columns[i].length;
                    break;
                case 'DESTINATION_COUNTRY':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('BASIC_DATA.DESTINATION_COUNTRY')}`;
                    width = columns[i].length;
                    break;
                case 'PICKING':
                    name = columns[i] + '.CODE';
                    displayName = `${this.formService.getTranslate('BASIC_DATA.PICK_UP')}`;
                    width = columns[i].length;
                    break;
                case 'RECEIPT_LOCAL':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('OPERATIONAL.PLACE_OF_RECEIPT')}`;
                    width = columns[i].length + 5;
                    break;
                case 'RECEIPT':
                    name = columns[i] + '.CODE';
                    displayName = `${this.formService.getTranslate('OPERATIONAL.PLACE_OF_RECEIPT')}`;
                    width = columns[i].length + 5;
                    break;
                case 'ORIGIN_LOCAL':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ORIGIN_NAME')}`;
                    width = columns[i].length;
                    break;
                case 'ORIGIN':
                    name = columns[i] + '.CODE';
                    displayName = `${this.formService.getTranslate('BASIC_DATA.ORIGIN')}`;
                    width = columns[i].length + 5;
                    visible = true;
                    break;
                case 'DESTINATION_LOCAL':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DESTINATION_NAME')}`;
                    width = columns[i].length + 5;
                    break;
                case 'DESTINATION':
                    name = columns[i] + '.CODE';
                    displayName = `${this.formService.getTranslate('BASIC_DATA.DESTINATION')}`;
                    width = columns[i].length;
                    break;
                case 'FINAL_DESTINATION_LOCAL_NAME':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.FINAL_DESTINATION_NAME')}`;
                    width = columns[i].length + 5;
                    break;
                case 'FINAL_DESTINATION_LOCAL_CODE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('ROUTE.FINAL_DESTINATION')}`;
                    width = columns[i].length;
                    break;
                case 'FINAL_DESTINATION_FORECAST_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ESTIMATED_DTFS')}`;
                    width = columns[i].length + 5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'FINAL_DESTINATION_EFFECTIVE_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ACTUAL_FINAL_DESTINATION_DATE')}`;
                    width = columns[i].length + 5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'GATE_IN_EMPTY_LOCAL_CODE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.GATE_IN_EMPTY')}`;
                    width = columns[i].length + 5;
                    visible = true;
                    break;
                case 'GATE_IN_EMPTY_LOCAL_NAME':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.GATE_IN_EMPTY_NAME')}`;
                    width = columns[i].length + 5;
                    visible = true;
                    break;
                case 'DRAFT_SHIPPER_SEALING':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('BASIC_DATA.SEAL')}`;
                    width = columns[i].length + 5;
                    visible = true;
                    break;
                case 'HBL_ISSUEANCE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ISSUANCE_DATE')}`;
                    width = columns[i].length + 5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    visible = true;
                    break;
                case 'DRAFT_GROSS_WEIGHT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DRAFT_TOTAL_WEIGHT')}`;
                    width = columns[i].length + 5;
                    visible = true;
                    break;
                case 'DRAFT_NCM':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.NCM_GENERIC_DESCRIPTION')}`;
                    width = columns[i].length + 5;
                    visible = true;
                    break;
                case 'DRAFT_QTY':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DRAFT_QUANTITY')}`;
                    width = columns[i].length + 5;
                    visible = true;
                    break;
                case 'DRAFT_CARGO_LIST':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.CNTR_WEIGHT')}`;
                    width = columns[i].length + 5;
                    visible = true;
                    break;
                case 'DRAFT_CARGO_LIST_QTY':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.CNTR_QUANTITY')}`;
                    width = columns[i].length + 5;
                    visible = true;
                    break;
                case 'CARGO_DETAIL_NAME':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.QUANTITY_ADJUST_REASON')}`;
                    width = columns[i].length + 5;
                    visible = true;
                    break;
                case 'GATE_IN_EMPTY_EFFECTIVE_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ACTUAL_GATE_IN_EMPTY')}`;
                    width = columns[i].length + 5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'GATE_IN_EMPTY_FORECAST_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ESTIMATED_GATE_IN_EMPTY')}`;
                    width = columns[i].length + 5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'GATE_IN_FULL_LOCAL_CODE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.GATE_IN_FULL')}`;
                    width = columns[i].length + 5;
                    visible = true;
                    break;
                case 'GATE_IN_FULL_LOCAL_NAME':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.GATE_IN_FULL_NAME')}`;
                    width = columns[i].length + 5;
                    visible = true;
                    break;
                case 'GATE_IN_FULL_FORECAST_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ESTIMATED_GATE_IN_FULL')}`;
                    width = columns[i].length + 5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    visible = true;
                    break;
                case 'GATE_IN_FULL_EFFECTIVE_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.EFECTIVE_GATE_IN_FULL')}`;
                    width = columns[i].length + 5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    visible = true;
                    break;
                case 'CARGO_READINESS_LOCAL_CODE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.CARGO_READINESS')}`;
                    width = columns[i].length + 5;
                    break;
                case 'CARGO_READINESS_LOCAL_NAME':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.CARGO_READINESS_NAME')}`;
                    width = columns[i].length + 5;
                    break;
                case 'CARGO_READINESS_EFFECTIVE_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ACTUAL_READINESS')}`;
                    width = columns[i].length + 5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'CARGO_READINESS_FORECAST_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.FORECAST_READINESS')}`;
                    width = columns[i].length + 5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'CARGO_INSPECTION_LOCAL_CODE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.CARGO_INSPECTION')}`;
                    width = columns[i].length + 5;
                    break;
                case 'CARGO_INSPECTION_LOCAL_NAME':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.CARGO_INSPECTION_NAME')}`;
                    width = columns[i].length + 5;
                    break;
                case 'CARGO_INSPECTION_EFFECTIVE_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ACTUAL_CARGO_INSPECTION')}`;
                    width = columns[i].length + 5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'CARGO_INSPECTION_FORECAST_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ESTIMATED_CARGO_INSPECTION')}`;
                    width = columns[i].length + 5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'LOAD_MODIFIED_TYPE_NAME':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.VESSEL_EDIT_REASON')}`;
                    width = columns[i].length + 5;
                    break;
                case 'DELIVERY_LOCAL_CODE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('ROUTE.DELIVERY')}`;
                    width = columns[i].length + 10;
                    break;
                case 'DELIVERY_ADDRESS':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate(' OPERATIONAL.DELIVERY_PLACE_NAME')}`;
                    width = columns[i].length + 5;
                    break;
                case 'EXTERNAL_AGENT_TRADING_NAME':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('BASIC_DATA.OVERSEAS_AGENT')}`;
                    width = columns[i].length + 5;
                    break;
                case 'EXTERNAL_AGENT_NIF':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.OVERSEAS_AGENT_NIF')}`;
                    width = columns[i].length + 5;
                    break;
                case 'LOCAL_AGENT_TRADING_NAME':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('BASIC_DATA.LOCAL_AGENT')}`;
                    width = columns[i].length + 5;
                    break;
                case 'LOCAL_AGENT_NIF':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.LOCAL_AGENT_NIF')}`;
                    width = columns[i].length + 5;
                    break;
                case 'ORIGIN_AGENT':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('BASIC_DATA.LOCAL_AGENT')}`;
                    width = columns[i].length + 5;
                    break;
                case 'ORIGIN_AGENT_NIF':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.LOCAL_AGENT_NIF')}`;
                    width = columns[i].length;
                    break;
                case 'DESTINATION_AGENT':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('BASIC_DATA.OVERSEAS_AGENT')}`;
                    width = columns[i].length;
                    break;
                case 'DESTINATION_AGENT_NIF':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.OVERSEAS_AGENT_NIF')}`;
                    width = columns[i].length;
                    break;
                case 'EXPORTER':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('BASIC_DATA.SHIPPER')}`;
                    width = columns[i].length + 10;
                    break;
                case 'IMPORTER':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('BASIC_DATA.CONSIGNEE')}`;
                    width = columns[i].length + 10;
                    break;
                case 'NOTIFY':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('BASIC_DATA.NOTIFY')}`;
                    width = columns[i].length + 10;
                    break;
                case 'BROKER':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('BASIC_DATA.CUSTOMS_BROKER')}`;
                    width = columns[i].length + 12;
                    break;
                case 'AGENCY':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('ENTITY.AGENCY')}`;
                    width = columns[i].length + 12;
                    break;
                case 'CARGO_DETAIL_INSURANCE_INSURANCE_BROKER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('ENTITY.INSURANCE_BROKER')}`;
                    width = columns[i].length / 2.1;
                    break;
                case 'EXTERNAL_AGENT_NETWORK':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('BASIC_DATA.EXTERNAL_AGENT_NETWORK')}`;
                    width = columns[i].length;
                    break;
                case 'INDICATION':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('ENTITY.INDICATION')}`;
                    width = columns[i].length;
                    break;
                case 'CUSTOMER_CELL':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('GENERAL.CUSTOMER_OUTER_CELL')}`;
                    width = columns[i].length;
                    break;
                case 'SITUATION':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('GENERAL.SITUATION')}`;
                    width = columns[i].length + 5;
                    visible = true;
                    break;
                case 'ENGLISH_SITUATION':
                    name = columns[i] + '.ALTERNATIVE';
                    displayName = `${this.formService.getTranslate('GENERAL.SITUATION')}`;
                    width = columns[i].length;
                    break;
                case 'PRIORITY':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('REGISTRATION.PRIORITY')}`;
                    width = columns[i].length;
                    break;
                case 'VALIDITY_OF':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('PRODUCT.VALIDITY_START')}`;
                    width = columns[i].length;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'VALIDITY_UP':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('PRODUCT.VALIDITY_END')}`;
                    width = columns[i].length;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'QUOTATION_OFFER_ID':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.OFFER_ID')}`;
                    width = columns[i].length / 2;
                    break;
                case 'HOUSE_PAYMENT':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('BASIC_DATA.HOUSE_PAYMENT_MODE')}`;
                    width = columns[i].length;
                    break;
                case 'MASTER_PAYMENT':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('BASIC_DATA.MASTER_PAYMENT_MODE')}`;
                    width = columns[i].length;
                    break;
                case 'PRODUCT':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('BASIC_DATA.PRODUCT')}`;
                    width = columns[i].length + 5;
                    visible = true;
                    break;
                case 'CREATED_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.CREATED_AT')}`;
                    width = columns[i].length / 1.5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'CREATED_BY':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.CREATED_BY')}`;
                    width = columns[i].length + 10;
                    break;
                case 'MODIFIED_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.UPDATED_AT')}`;
                    width = columns[i].length / 1.5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'MODIFIED_BY':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.UPDATED_BY')}`;
                    width = columns[i].length;
                    break;
                case 'MODAL':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('REGISTRATION.MODE_OF_TRANSPORT')}`;
                    width = columns[i].length + 5;
                    visible = true;
                    break;
                case 'OPERATION':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('REGISTRATION.OPERATION')}`;
                    width = columns[i].length;
                    break;
                case 'FREIGHT_CONTRACT':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('BASIC_DATA.FREIGHT_CONTRACT')}`;
                    width = columns[i].length;
                    break;
                case 'FREIGHT_CONTRACT_RECEIVING':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('OPERATIONAL.SELLING_CONTRACT')}`;
                    width = columns[i].length;
                    break;
                case 'FREIGHT_CONTRACT_SERVICE_LEVEL':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('BASIC_DATA.SERVICE_LEVEL')}`;
                    width = columns[i].length;
                    break;
                case 'FREIGHT_CONTRACT_SERVICE_TYPE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('BASIC_DATA.SERVICE_TYPE')}`;
                    width = columns[i].length;
                    break;
                case 'COMMERCIAL_UNITY':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('BASIC_DATA.COMMERCIAL_BRANCH')}`;
                    width = columns[i].length;
                    break;
                case 'SHIPMENT_REQUIRED_DATE_OF':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.REQUESTED_LOADING_DATE_FROM')}`;
                    width = columns[i].length / 1.5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'SHIPMENT_REQUIRED_DATE_UP':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.REQUESTED_LOADING_DATE_TO')}`;
                    width = columns[i].length / 1.5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'MERCHAN':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.COMMODITY')}`;
                    width = columns[i].length + 5;
                    break;
                case 'MERCHAN_GROUP':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.COMMODITY_GROUP')}`;
                    width = columns[i].length;
                    break;
                case 'COMPARED_BY':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.COMPARED_BY')}`;
                    width = columns[i].length + 3;
                    break;
                case 'COMPARED_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.COMPARED_DATE')}`;
                    width = columns[i].length;
                    cellFilter = `datetimeformated`;
                    break;
                case 'LOAD_VESSEL_VOY':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.LOAD_VESSEL_VOYAGE')}`;
                    width = columns[i].length;
                    break;
                case 'LOAD_VESSEL':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('BASIC_DATA.VESSEL')}`;
                    width = columns[i].length;
                    break;
                case 'LOAD_VOY':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.VOYAGE')}`;
                    width = columns[i].length;
                    break;
                case 'DISCH_TRSHP_VESSEL_VOY':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.VESSEL_VOYAGE_TRANSSHIPMENT_DISCHARGE')}`;
                    width = columns[i].length;
                    break;
                case 'LOAD_TRSHP_VESSEL_VOY':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.VESSEL_VOYAGE_TRANSSHIPMENT_LOAD')}`;
                    width = columns[i].length;
                    break;
                case 'DISCH_VESSEL_VOY':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DISCHARGE_VESSEL_VOYAGE')}`;
                    width = columns[i].length;
                    break;
                case 'LOAD_BOOKING':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.BOOKING')}`;
                    width = columns[i].length / 1.5;
                    break;
                case 'LOAD_FORECAST_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ESTIMATED_LOADING_DATE')}`;
                    width = columns[i].length;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'LOAD_EFFECTIVE_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ACTUAL_LOADING_DATE')}`;
                    width = columns[i].length / 1.5;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'DISCH_FORECAST_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ESTIMATED_DISCHARGE_DATE')}`;
                    width = columns[i].length;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'DISCH_EFFECTIVE_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ACTUAL_DISCHARGE_DATE')}`;
                    width = columns[i].length;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'DISCH_TERMINAL_REDIRECT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.RELOCATION_TERMINAL')}`;
                    width = columns[i].length;
                    break;
                case 'DELIVERY_FORECAST_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ESTIMATED_DELIVERY_DATE')}`;
                    width = columns[i].length / 1.2;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'DELIVERY_EFFECTIVE_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ACTUAL_DELIVERY_DATE')}`;
                    width = columns[i].length / 2;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'TRSHP_DISCH_FORECAST_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ESTIMATED_TRANSSHIPMENT_DISCHARGE')}`;
                    width = columns[i].length;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'TRSHP_DISCH_EFFECTIVE_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.TRANSSHIPMENT_DISCHARGE')}`;
                    width = columns[i].length / 1.2;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'TRSHP_LOAD_FORECAST_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ESTIMATED_TRANSSHIPMENT_LOAD')}`;
                    width = columns[i].length;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'TRSHP_LOAD_EFFECTIVE_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.TRANSSHIPMENT_LOAD')}`;
                    width = columns[i].length / 1.2;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'PICK_FORECAST_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ESTIMATED_PICK_UP')}`;
                    width = columns[i].length / 1.2;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'PICK_EFFECTIVE_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ACTUAL_PICK_UP_DATE')}`;
                    width = columns[i].length / 1.2;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'FORWARDING_SITUATION':
                    name = columns[i] + ".SITUATION.NAME";
                    displayName = `${this.formService.getTranslate('OPERATIONAL.FILE_STATUS')}`;
                    width = columns[i].length;
                    break;
                case 'DOCUMENT_HBL':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.HBL')}`;
                    width = columns[i].length;
                    break;
                case 'CE_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.CE_MERCANTE')}`;
                    width = columns[i].length + 5;
                    break;
                case 'DOCUMENT_MBL':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.MBL')}`;
                    width = columns[i].length;
                    break;
                case 'DESCONSOLIDATION_DATE_MASTER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DESCONSOLIDATION_DATE_MASTER')}`;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'DESCONSOLIDATION_DATE_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DESCONSOLIDATION_DATE_HOUSE')}`;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'QTYXEQP':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('BASIC_DATA.QTY_EQP')}`;
                    width = columns[i].length;
                    break;
                case 'BOOKING_STATUS':
                    name = columns[i] + ".NAME";
                    displayName = `${this.formService.getTranslate('OPERATIONAL.BOOKING_STATUS')}`;
                    width = columns[i].length;
                    visible = true;
                    break;
                case 'ENGLISH_BOOKING_STATUS':
                    name = columns[i] + ".ALTERNATIVE";
                    displayName = `${this.formService.getTranslate('OPERATIONAL.BOOKING_STATUS')}`;
                    width = columns[i].length;
                    break;
                case 'EQUIPMENTS':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.CONTAINERS')}`;
                    width = columns[i].length;
                    break;
                case 'CUSTOMER_REF':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.CLIENT_REFERENCE')}`;
                    width = columns[i].length;
                    break;
                case 'CUSTOMER_OTHER_REF':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ADDITIONAL_CUSTOMER_REFERENCES')}`;
                    width = columns[i].length;
                    break;
                case 'AGENT_REF':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('REGISTRATION.AGENT_REFERENCE')}`;
                    width = columns[i].length + 5;
                    break;
                case 'EQP_40':
                    name = columns[i] + ".TOTAL";
                    displayName = `${this.formService.getTranslate('BASIC_DATA.TOTAL_40')}`;
                    width = columns[i].length + 6;
                    break;
                case 'EQP_20':
                    name = columns[i] + ".TOTAL";
                    displayName = `${this.formService.getTranslate('BASIC_DATA.TOTAL_20')}`;
                    width = columns[i].length + 6;
                    break;
                case 'FREE_TIME_OFTH':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.FT_ORIGIN_HOUSE')}`;
                    width = columns[i].length;
                    break;
                case 'FREE_TIME_OFTM':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.FT_ORIGIN_MASTER')}`;
                    width = columns[i].length;
                    break;
                case 'FREE_TIME_DFTH':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.FT_DESTINATION_HOUSE')}`;
                    width = columns[i].length;
                    break;
                case 'FREE_TIME_DFTM':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.FT_DESTINATION_MASTER')}`;
                    width = columns[i].length;
                    break;
                case 'TEUS':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('BASIC_DATA.TOTAL_TEUS')}`;
                    width = columns[i].length + 6;
                    break;
                case 'CS_PROCESS_OWNER':
                    name = columns[i] + ".USER.NAME";
                    displayName = `${this.formService.getTranslate('OPERATIONAL.CUSTOMER_SERVICE')}`;
                    width = columns[i].length;
                    break;
                case 'SALES_PERSON':
                    name = columns[i] + ".NAME";
                    displayName = `${this.formService.getTranslate('BASIC_DATA.SALES_EXECUTIVE')}`;
                    width = columns[i].length;
                    break;
                case 'INSIDE_SALES_PERSON':
                    name = columns[i] + ".NAME";
                    displayName = `${this.formService.getTranslate('BASIC_DATA.INSIDE_SALES')}`;
                    width = columns[i].length;
                    break;
                case 'BILLING_RELEASE':
                    name = columns[i] + ".DATE";
                    displayName = `${this.formService.getTranslate('OPERATIONAL.BILLING_ALLOWED')}`;
                    width = columns[i].length;
                    cellFilter = `datetimeformated`;
                    break;
                case 'CHECK_VALUE':
                    name = columns[i] + ".DATE";
                    displayName = `${this.formService.getTranslate('OPERATIONAL.VALUE_CHECK')}`;
                    width = columns[i].length;
                    cellFilter = `datetimeformated`;
                    break;
                case 'CANCELLATION':
                    name = columns[i] + ".DATE";
                    displayName = `${this.formService.getTranslate('OPERATIONAL.CANCELATION')}`;
                    width = columns[i].length;
                    cellFilter = `datetimeformated`;
                    break;
                case 'PAYMENT_SITUATION':
                    name = columns[i] + ".SITUATION.NAME";
                    displayName = `${this.formService.getTranslate('FINANCIAL.PAYMENT_STATUS')}`;
                    width = columns[i].length;
                    break;
                case 'RECEIPT_SITUATION':
                    name = columns[i] + ".SITUATION.NAME";
                    displayName = `${this.formService.getTranslate('FINANCIAL.RECEIPT_SITUATION')}`;
                    width = columns[i].length;
                    break;
                case 'AGENT_SETTLEMENT_SITUATION':
                    name = columns[i] + ".SITUATION.NAME";
                    displayName = `${this.formService.getTranslate('FINANCIAL.AGENT_SETTLEMENT_STATUS')}`;
                    width = columns[i].length;
                    break;
                // DEFAULT DEADLINE - HOUSE
                case 'SCAC_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.SCAC_HOUSE')}`;
                    width = columns[i].length;
                    break;
                // DEFAULT DEADLINE - MASTER
                case 'SCAC_MASTER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.SCAC_MASTER')}`;
                    width = columns[i].length;
                    break;
                // PROCESS DEADLINE - HOUSE
                case 'PROCESS_DEADLINE_REGULAR_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DEADLINE_REGULAR_HOUSE')}`;
                    width = columns[i].length / 1.5;
                    cellFilter = `datetimeformated`;
                    break;
                case 'PROCESS_DEADLINE_IMO_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DEADLINE_IMO_HOUSE')}`;
                    width = columns[i].length / 1.5;
                    cellFilter = `datetimeformated`;
                    break;
                case 'PROCESS_DEADLINE_RELEASE_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DEADLINE_RELEASE_HOUSE')}`;
                    width = columns[i].length / 1.5;
                    cellFilter = `datetimeformated`;
                    break;
                case 'PROCESS_DEADLINE_VGM_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DEADLINE_VGM_HOUSE')}`;
                    width = columns[i].length / 1.5;
                    cellFilter = `datetimeformated`;
                    break;
                case 'PROCESS_DEADLINE_DAY_REGULAR_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DEADLINE_REGULAR_HOUSE_DATE')}`;
                    width = columns[i].length / 1.5;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'PROCESS_DEADLINE_DAY_IMO_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DEADLINE_IMO_HOUSE_DATE')}`;
                    width = columns[i].length / 1.5;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'PROCESS_DEADLINE_DAY_RELEASE_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DEADLINE_RELEASE_HOUSE_DATE')}`;
                    width = columns[i].length / 1.5;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'PROCESS_DEADLINE_DAY_VGM_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DEADLINE_VGM_HOUSE_DATE')}`;
                    width = columns[i].length / 1.5;
                    cellFilter = `date:'dd/MM/yyyy'`;
                    break;
                case 'PROCESS_DEADLINE_HOUR_REGULAR_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DEADLINE_REGULAR_HOUSE_TIME')}`;
                    width = columns[i].length / 1.5;
                    cellFilter = `hourminute`;
                    break;
                case 'PROCESS_DEADLINE_HOUR_IMO_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DEADLINE_IMO_HOUSE_TIME')}`;
                    width = columns[i].length / 1.5;
                    cellFilter = `hourminute`;
                    break;
                case 'PROCESS_DEADLINE_HOUR_RELEASE_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DEADLINE_RELEASE_HOUSE_TIME')}`;
                    width = columns[i].length / 1.5;
                    cellFilter = `hourminute`;
                    break;
                case 'PROCESS_DEADLINE_HOUR_VGM_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DEADLINE_VGM_HOUSE_TIME')}`;
                    width = columns[i].length / 1.5;
                    cellFilter = `hourminute`;
                    break;
                // PROCESS DEADLINE - MASTER
                case 'PROCESS_DEADLINE_REGULAR_MASTER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DEADLINE_REGULAR_MASTER')}`;
                    width = columns[i].length / 1.5;
                    cellFilter = `datetimeformated`;
                    break;
                case 'PROCESS_DEADLINE_IMO_MASTER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DEADLINE_IMO_MASTER')}`;
                    width = columns[i].length / 1.5;
                    cellFilter = `datetimeformated`;
                    break;
                case 'PROCESS_DEADLINE_RELEASE_MASTER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DEADLINE_RELEASE_MASTER')}`;
                    width = columns[i].length / 1.5;
                    cellFilter = `datetimeformated`;
                    break;
                case 'PROCESS_DEADLINE_VGM_MASTER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DEADLINE_VGM_MASTER')}`;
                    width = columns[i].length / 1.5;
                    cellFilter = `datetimeformated`;
                    break;
                case 'END_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.FILE_CLOSURE_DATE')}`;
                    width = columns[i].length + 6;
                    cellFilter = `datetimeformated`;
                    break;
                case 'GP_ESTIMATED':
                    name = columns[i] + ".TOTAL";
                    cellFilter = `number: 2`;
                    displayName = `${this.formService.getTranslate('FINANCIAL.ESTIMATED_GROSS_PROFIT')}`;
                    width = columns[i].length + 3;
                    break;
                case 'GP_EFFECTIVE':
                    name = columns[i] + ".TOTAL";
                    cellFilter = `number: 2`;
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ACTUAL_RESULT')}`;
                    width = columns[i].length;
                    break;
                case 'FOLLOW_UP_CONTENT':
                    name = columns[i];
                    cellTemplate = '<div class="ui-grid-cell-contents" ng-bind-html="row.entity.FOLLOW_UP_CONTENT"><div>';
                    displayName = `${this.formService.getTranslate('REGISTRATION.FOLLOW_UP')}`;
                    cellFilter = 'purgeFollowUpHTML';
                    width = columns[i].length + 15;
                    break;
                case 'INVOICE_NUMBER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.INVOICES')}`;
                    width = columns[i].length / 1.5;
                    break;
                case 'INVOICE_STATUS':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('FINANCIAL.BILLING_STATUS')}`;
                    width = columns[i].length + 10;
                    break;
                case 'INVOICE_CURRENT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.EXCHANGE_RATE_PER_CURRENCY')}`;
                    width = columns[i].length + 5;
                    break;
                case 'INVOICE_ORIGINAL':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.ORIGINAL_AMOUNT_PER_CURRENCY')}`;
                    width = columns[i].length + 5;
                    break;
                case 'CONTRACT_PAYMENT_CONDITION':
                    name = columns[i] + ".NAME";
                    displayName = `${this.formService.getTranslate('GENERAL.INVOICE_PAYMENT_TERM')}`;
                    width = columns[i].length / 1.2;
                    break;
                case 'CONTRACT_D0':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.PAYMENT_TERM_DAYS')}`;
                    width = columns[i].length / 1.2;
                    break;
                case 'CONTRACT_NUMBER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('BASIC_DATA.FREIGHT_CONTRACT')}`;
                    width = columns[i].length / 1.2;
                    break;
                case 'PEOPLE':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('GENERAL.HOLDER')}`;
                    width = columns[i].length + 15;
                    break;
                case 'TOTAL':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.TOTAL')}`;
                    cellTemplate = '<div class="ui-grid-cell-contents">{{row.entity.TOTAL | number: 2}}<div>';
                    width = columns[i].length;
                    break;
                case 'PARTIAL_PAYMENT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.PARTIAL_PAYMENT')}`;
                    cellTemplate = '<div class="ui-grid-cell-contents">{{row.entity.PARTIAL_PAYMENT | number: 2}}<div>';
                    width = columns[i].length;
                    break;
                case 'ORIGINAL_TOTAL':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.TOTAL_AMOUNT')}`;
                    cellTemplate = '<div class="ui-grid-cell-contents">{{row.entity.ORIGINAL_TOTAL | number: 2}}<div>';
                    width = columns[i].length / 1.5;
                    break;
                case 'DUE_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.DUE_DATE')}`;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length + 5;
                    break;
                case 'LOCKED':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.LOCKED')}`;
                    cellFilter = 'YesOrNo';
                    width = columns[i].length + 3;
                    break;
                case 'LOCKED_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.ISSUE_DATE')}`;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length;
                    break;
                case 'LIQUIDATION_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.RECEIPT_ACKNOWLEDGEMENT_DATE')}`;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length / 1.2;
                    break;
                case 'NATURE':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('REGISTRATION.TRANSACTION')}`;
                    width = columns[i].length + 2;
                    visible = true;
                    break;
                case 'TRANSACTION':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('GENERAL.HOLDER_TYPE')}`;
                    cellTemplate = '<div class="grid-padding">{{grid.appScope.getConcatInformation(row.entity.TRANSACTION)}}</div>';
                    width = columns[i].length + 4;
                    visible = true;
                    break;
                case 'FINANCIAL_SITUATION':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('FINANCIAL.FINANCIAL_STATUS')}`;
                    width = columns[i].length + 10;
                    visible = true;
                    break;
                case 'APPROVAL_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.PROVIDER_INVOICE_APPROVAL')}`;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length;
                    break;
                case 'THIRDPARTY_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.PROVIDER_INVOICE_ISSUE')}`;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length;
                    break;
                case 'NEGOTIATED':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.NEGOTIATED')}`;
                    cellFilter = 'YesOrNo';
                    width = columns[i].length;
                    break;
                case 'PAYMENT_PERIOD':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('FINANCIAL.PAYMENT_PERIOD')}`;
                    width = columns[i].length + 4;
                    break;
                case 'PAYMENT_METHOD':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('FINANCIAL.PAYMENT_MEANS')}`;
                    width = columns[i].length;
                    break;
                case 'CLIENT_ACCOUNT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('ENTITY.CHECKING_ACCOUNT')}`;
                    width = columns[i].length;
                    break;
                case 'PAYMENT_CONDITION':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('GENERAL.INVOICE_PAYMENT_TERM')}`;
                    width = columns[i].length;
                    break;
                case 'HC2_FINANCIAL_RECORD':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('REGISTRATION.HC2_INTEGRATION_REF')}`;
                    width = columns[i].length + 1;
                    break;
                case 'PROVIDER_INVOICE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.PROVIDER_INVOICE')}`;
                    width = columns[i].length + 1;
                    break;
                case 'SUBMISSION_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DRAFT_SEND_DATE')}`;
                    width = columns[i].length + 1;
                    break;
                case 'EXCH_INVOICE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.INTERNATIONAL_REMITTANCE_INVOICE')}`;
                    width = columns[i].length + 2;
                    break;
                case 'EXCH_CONTRACT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.INTERNATIONAL_REMITTANCE_CONTRACT')}`;
                    width = columns[i].length + 2;
                    break;
                case 'EXCH_COUNTRY':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.INTERNATIONAL_REMITTANCE_COUNTRY')}`;
                    width = columns[i].length + 2;
                    break;
                case 'EXCH_CHARGE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.INTERNATIONAL_REMITTANCE_CHARGE')}`;
                    width = columns[i].length + 2;
                    break;
                case 'EXCH_EXCHANGE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.INTERNATIONAL_REMITTANCE_METHOD')}`;
                    width = columns[i].length + 2;
                    break;
                case 'EXCH_BROKER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.INTERNATIONAL_REMITTANCE_BANK')}`;
                    width = columns[i].length + 2;
                    break;
                case 'EXCH_DATESHP':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.INTERNATIONAL_REMITTANCE_DATE')}`;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length + 2;
                    break;
                case 'EXCH_DATEORD':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.INTERNATIONAL_REMITTANCE_ORDER_DATE')}`;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length + 2;
                    break;
                case 'CHR_NUMBER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.CHARGE_NUMBER')}`;
                    width = columns[i].length + 10;
                    visible = true;
                    break;
                case 'CHR_CURRENCY':
                    name = columns[i] + ".NAME";
                    displayName = `${this.formService.getTranslate('FINANCIAL.CHARGE_CURRENCY')}`;
                    width = columns[i].length + 10;
                    visible = true;
                    break;
                case 'CHR_TYPE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.CHARGE_TYPE')}`;
                    width = columns[i].length + 1;
                    visible = true;
                    break;
                case 'CHR_NAME':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('BASIC_DATA.CHARGE')}`;
                    width = columns[i].length + 1;
                    visible = true;
                    break;
                case 'CHR_AMOUNT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.CHARGE_AMOUNT')}`;
                    width = columns[i].length + 1;
                    break;
                case 'CHR_APPLICATION':
                    name = columns[i] + ".NAME";
                    displayName = `${this.formService.getTranslate('FINANCIAL.CHARGE_BASIS')}`;
                    width = columns[i].length / 1.5;
                    break;
                case 'CHR_MINIMUM':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.CHARGE_MIN')}`;
                    cellFilter = 'number:2';
                    width = columns[i].length / 1.1;
                    break;
                case 'CHR_UNITARY':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.CHARGE_UNIT')}`;
                    cellFilter = 'number:2';
                    width = columns[i].length / 1.1;
                    break;
                case 'CHR_TOTAL':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.CHARGE_TOTAL')}`;
                    cellFilter = 'number:2';
                    width = columns[i].length / 1.1;
                    visible = true;
                    break;
                case 'CHR_CONVERSION_SPREAD':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('BASIC_DATA.EXCHANGE_RATE')}`;
                    cellFilter = 'number:6';
                    width = columns[i].length / 1.5;
                    break;
                case 'CHR_TOTAL_BRL':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.CONVERTED_CHARGE')}`;
                    cellFilter = 'number:6';
                    width = columns[i].length / 1.5;
                    break;
                case 'CHR_OBSERVATION':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.CHARGE_REMARKS')}`;
                    width = columns[i].length + 11;
                    break;
                case 'CHR_NATURE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('REGISTRATION.TRANSACTION')}`;
                    width = columns[i].length + 5;
                    break;
                case 'CHR_EXHIBITION':
                    name = columns[i] + ".NAME";
                    displayName = `${this.formService.getTranslate('BASIC_DATA.CHARGE_DISPLAY')}`;
                    width = columns[i].length / 1.5;
                    visible = true;
                    break;
                case 'PROCESS_INVOICE_CONVERSION_REFERENCE_AGENT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.CONVERSION_REFERENCE_AGENT_SETTLEMENT')}`;
                    width = columns[i].length + 6;
                    break;
                case 'PROCESS_INVOICE_CURRENCIES_VALUES_AGENT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.ORIGINAL_AMOUNT_PER_CURRENCY_AGENT_SETTLEMENT')}`;
                    width = columns[i].length + 6;
                    break;
                case 'PROCESS_INVOICE_CURRENCIES_FACTORS_AGENT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.EXCHANGE_RATE_INDEX_AGENT_SETTLEMENT')}`;
                    width = columns[i].length + 6;
                    break;
                case 'PROCESS_INVOICE_CONVERSION_REFERENCE_PAYMENT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.CONVERSION_REFERENCE_DEBIT')}`;
                    width = columns[i].length + 6;
                    break;
                case 'PROCESS_INVOICE_CURRENCIES_VALUES_PAYMENT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.ORIGINAL_AMOUNT_PER_CURRENCY_DEBIT')}`;
                    width = columns[i].length + 6;
                    break;
                case 'PROCESS_INVOICE_CURRENCIES_FACTORS_PAYMENT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.EXCHANGE_RATE_INDEX_DEBIT')}`;
                    width = columns[i].length + 6;
                    break;
                case 'PROCESS_INVOICE_CONVERSION_REFERENCE_RECEIPT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.CONVERSION_REFERENCE_CREDIT')}`;
                    width = columns[i].length + 6;
                    break;
                case 'PROCESS_INVOICE_CURRENCIES_VALUES_RECEIPT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.ORIGINAL_AMOUNT_PER_CURRENCY_CREDIT')}`;
                    width = columns[i].length + 6;
                    break;
                case 'PROCESS_INVOICE_CURRENCIES_FACTORS_RECEIPT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.EXCHANGE_RATE_INDEX_CREDIT')}`;
                    width = columns[i].length + 6;
                    break;
                case 'IMPORTER_TAXPAYER_NUMBER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.IMPORTER_TAX_ID')}`;
                    width = columns[i].length + 10;
                    break;
                case 'DTA_INFORMATION_LIBERATION_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DTA_CONCESSION_DATE')}`;
                    width = columns[i].length + 10;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'CARGO_DETAIL_GROSS_WEIGHT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.GROSS_WEIGHT')}`;
                    width = columns[i].length + 10;
                    break;
                case 'CARGO_DETAIL_CBM':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.CUBIC_METER')}`;
                    width = columns[i].length + 10;
                    break;
                case 'CARGO_DETAIL_VOLUME':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.PIECES')}`;
                    width = columns[i].length + 10;
                    break;
                case 'CARGO_DETAIL_MINIMUM_TEMPERATURE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.MINIMUM_TEMPERATURE')}`;
                    width = columns[i].length + 10;
                    break;
                case 'CARGO_DETAIL_MAXIMUM_TEMPERATURE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.MAXIMUM_TEMPERATURE')}`;
                    width = columns[i].length + 10;
                    break;
                case 'CARGO_DETAIL_QUANTITY':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.CHARGEABLE_WEIGHT')}`;
                    width = columns[i].length;
                    break;
                case 'CARGO_DETAIL_IS_DANGER_CARGO':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('BASIC_DATA.DANGEROUS_CARGO')}`;
                    cellFilter = 'YesOrNo';
                    width = columns[i].length;
                    break;
                case 'LOAD_CUSTOMS_TRANSFER_EFFECTIVE_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.LOAD_CUSTOMS_TRANSFER_DATE')}`;
                    width = columns[i].length + 10;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'LOAD_CUSTOMS_TRANSFER_LOCAL':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.LOAD_CUSTOMS_TRANSFER_LOCAL')}`;
                    width = columns[i].length + 10;
                    break;
                case 'EXTERNAL_BROKER_TRADING_NAME':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('BASIC_DATA.EXTERNAL_BROKER')}`;
                    width = columns[i].length + 10;
                    break;
                case 'TRANSPORTER_DELIVERY_TRADING_NAME':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('BASIC_DATA.TRANSPORTER_DELIVERY')}`;
                    width = columns[i].length + 10;
                    break;
                case 'TRANSPORTER_FINAL_DESTINATION_TRADING_NAME':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('BASIC_DATA.TRANSPORTER_FINAL_DESTINATION')}`;
                    width = columns[i].length + 10;
                    break;
                case 'EXCHANGE_CONTRACT_LOCK_CONTRACT_NUMBER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.EXCHANGE_LOCK_CONTRACT')}`;
                    width = columns[i].length / 1.5;
                    break;
                case 'EXCHANGE_CONTRACT_UNLOCK_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.EXCHANGE_UNLOCK_DATE')}`;
                    width = columns[i].length + 10;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'EXCHANGE_CONTRACT_LOCK_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.EXCHANGE_LOCK_DATE')}`;
                    width = columns[i].length + 10;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'EXCHANGE_CONTRACT_OBSERVATION':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.REMARKS')}`;
                    width = columns[i].length + 10;
                    break;
                case 'EXCHANGE_CONTRACT_CHARGE_CODE':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('FINANCIAL.INTERNATIONAL_REMITTANCE_TRANSACTION')}`;
                    width = columns[i].length + 10;
                    break;
                case 'INTEGRATION_DIRECTION':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('REGISTRATION.INTEGRATION_DIRECTION')}`;
                    width = columns[i].length / 1.5;
                    break;
                case 'ERP_INTEGRATION_STATUS_MESSAGE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('REGISTRATION.INTEGRATION_MESSAGE')}`;
                    width = columns[i].length + 10;
                    break;
                case 'ERP_INTEGRATION_STATUS':
                    name = columns[i] + '.NAME';
                    displayName = `${this.formService.getTranslate('REGISTRATION.INTEGRATION_STATUS')}`;
                    width = columns[i].length + 10;
                    break;
                case 'PROCESS_DEADLINE_CARGO_DOCS':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DEADLINE_CARGO_DOCS')}`;
                    width = columns[i].length / 1.4;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'DTA_INFORMATION':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('REGISTRATION.BONDED_TRUCK')}`;
                    cellFilter = 'YesOrNo';
                    width = columns[i].length;
                    break;
                case 'CHR_ERP_INTEGRATION_STATUS':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('REGISTRATION.INTEGRATION_CHARGES_STATUS')}`;
                    width = columns[i].length + 10;
                    break;
                case 'EXCHANGE_CONTRACT_PAYMENT_REQUEST':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.PAYMENT_REQUEST')}`;
                    width = columns[i].length / 1.5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'INVOICE_ORIGINAL_CURRENCIES':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.ORIGINAL_CURRENCY_GROUP')}`;
                    width = columns[i].length + 10;
                    break;
                case 'INVOICE_ORIGINAL_VALUES':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.ORIGINAL_VALUE_GROUP')}`;
                    width = columns[i].length + 10;
                    break;
                case 'TRANSIT_TIME_MIN':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.TRANSIT_TIME_MIN')}`;
                    width = columns[i].length + 10;
                    break;
                case 'TRANSIT_TIME_MAX':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.TRANSIT_TIME_MAX')}`;
                    width = columns[i].length + 10;
                    break;
                case 'BUYING_CONTRACT_TYPE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.BUYING_CONTRACT_TYPE')}`;
                    width = columns[i].length + 10;
                    break;
                case 'CONTRACT_TYPE_RECEIVING':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.SELLING_CONTRACT_TYPE')}`;
                    width = columns[i].length + 10;
                    break;
                case 'ORIGINALS_RECEIVED_BY_EMAIL_MASTER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ORIGINALS_RECEIVED_BY_EMAIL_MASTER')}`;
                    width = columns[i].length + 10;
                    break;
                case 'ORIGINALS_RECEIVED_BY_EMAIL_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ORIGINALS_RECEIVED_BY_EMAIL_HOUSE')}`;
                    width = columns[i].length + 10;
                    break;
                case 'ISSUANCE_TYPE_MASTER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ISSUANCE_TYPE_MASTER')}`;
                    width = columns[i].length + 10;
                    break;
                case 'ISSUANCE_TYPE_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ISSUANCE_TYPE_HOUSE')}`;
                    width = columns[i].length + 10;
                    break;
                case 'ORIGINALS_COPIES_MASTER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ORIGINALS_COPIES_MASTER')}`;
                    width = columns[i].length + 10;
                    break;
                case 'ORIGINALS_COPIES_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ORIGINALS_COPIES_HOUSE')}`;
                    width = columns[i].length + 10;
                    break;
                case 'CE_MERCANTE_MASTER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.CE_MERCANTE_MASTER')}`;
                    width = columns[i].length + 10;
                    break;
                case 'AVAILABILITY_MASTER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.AVAILABILITY_MASTER')}`;
                    width = columns[i].length + 10;
                    break;
                case 'AVAILABILITY_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.AVAILABILITY_HOUSE')}`;
                    width = columns[i].length + 10;
                    break;
                case 'SISCOMEX_CARGO_RELEASED_AIMED_MANTRA_MASTER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.SISCOMEX_CARGO_RELEASED_AIMED_MANTRA_MASTER')}`;
                    width = columns[i].length + 10;
                    break;
                case 'SISCOMEX_CARGO_RELEASED_AIMED_MANTRA_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.SISCOMEX_CARGO_RELEASED_AIMED_MANTRA_HOUSE')}`;
                    width = columns[i].length + 10;
                    break;
                case 'ESTIMATED_GATE_OUT_EMPTY':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ESTIMATED_GATE_OUT_EMPTY')}`;
                    width = columns[i].length + 5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'ACTUAL_GATE_OUT_EMPTY':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ACTUAL_GATE_OUT_EMPTY')}`;
                    width = columns[i].length + 5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'GATE_OUT_EMPTY_LOCAL_NAME':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.GATE_OUT_EMPTY_LOCAL')}`;
                    width = columns[i].length + 10;
                    break;
                case 'BOOKING_STATUS_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.BOOKING_STATUS_DATE_HOUR')}`;
                    width = columns[i].length + 5;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    break;
                case 'SHIPPER_CORRESPONDENCE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.SHIPPER_CORRESPONDENCE')}`;
                    width = columns[i].length + 10;
                    break;
                case 'CONSIGNEE_CORRESPONDENCE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.CONSIGNEE_CORRESPONDENCE')}`;
                    width = columns[i].length + 10;
                    break;
                case 'NOTIFY_CORRESPONDENCE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.NOTIFY_CORRESPONDENCE')}`;
                    width = columns[i].length + 10;
                    break;
                case 'DELIVERY_ADDRESS':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('BASIC_DATA.DELIVERY_ADDRESS')}`;
                    width = columns[i].length + 10;
                    break;
                case 'SHIPPER_MASTER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('ENTITY.SHIPPER_MASTER')}`;
                    width = columns[i].length + 10;
                    break;
                case 'CONSIGNEE_MASTER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('ENTITY.CONSIGNEE_MASTER')}`;
                    width = columns[i].length + 10;
                    break;
                case 'NOTIFY_MASTER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('ENTITY.NOTIFY_MASTER')}`;
                    width = columns[i].length + 10;
                    break;
                case 'AGENT_INSTRUCTION':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.AGENT_INSTRUCTION')}`;
                    width = columns[i].length + 10;
                    break;
                case 'CARGO_TREATMENT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.CARGO_TREATMENT')}`;
                    width = columns[i].length + 10;
                    break;
                case 'REFRIGERATED_CARGO':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.REFRIGERATED_CARGO')}`;
                    cellFilter = 'YesOrNo';
                    width = columns[i].length + 10;
                    break;
                case 'EFECTIVE_GATE_IN_FULL_LOCAL_NAME':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.TRANSSHIPMENT')}`;
                    width = columns[i].length + 10;
                    break;
                case 'NAME_ADDRESS':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('BASIC_DATA.DELIVERY_ADDRESS')}`;
                    width = columns[i].length + 10;
                    break;
                case 'INVOICE_COMMENT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('FINANCIAL.INVOICE_REMARKS')}`;
                    width = columns[i].length + 10;
                    break;
                case 'COMMODITY_CODE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.COMMODITY_CURRENCY')}`;
                    width = columns[i].length + 10;
                    break;
                case 'COMMODITY_VALUE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.COMMODITY_VALUE')}`;
                    width = columns[i].length + 10;
                    break;
                case 'ALLOG_APPROVAL_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ALLOG_APPROVAL_HOUSE')}`;
                    width = columns[i].length + 10;
                    break;
                case 'ALLOG_APPROVAL_MASTER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ALLOG_APPROVAL_MASTER')}`;
                    width = columns[i].length + 10;
                    break;
                case 'RELEASE_DATE_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.SEND_RELEASE_DATE_HOUSE')}`;
                    width = columns[i].length + 10;
                    break;
                case 'RELEASE_DATE_MASTER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.SEND_RELEASE_DATE_MASTER')}`;
                    width = columns[i].length + 10;
                    break;
                case 'REMARKS_HOUSE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.REMARKS_HOUSE')}`;
                    width = columns[i].length + 10;
                    break;
                case 'REMARKS_MASTER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.REMARKS_MASTER')}`;
                    width = columns[i].length + 10;
                    break;
                case 'PICK_UP_PROVIDER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.PICK_UP_PROVIDER')}`;
                    width = columns[i].length + 10;
                    break;
                case 'PICK_UP_NAME':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.PICK_UP_NAME')}`;
                    width = columns[i].length + 10;
                    break;
                case 'ORIGIN_TRADE_GROUP_NAME':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('BASIC_DATA.ORIGIN_TRADE_GROUP')}`;
                    width = columns[i].length + 10;
                    break;
                case 'DESTINATION_TRADE_GROUP_NAME':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('BASIC_DATA.DESTINATION_TRADE_GROUP')}`;
                    width = columns[i].length + 10;
                    break;
                case 'AVAILABILITY_MASTER_EXP':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.AVAILABILITY_EXP_MASTER')}`;
                    width = columns[i].length + 10;
                    break;
                case 'AVAILABILITY_HOUSE_EXP':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.AVAILABILITY_EXP_HOUSE')}`;
                    width = columns[i].length + 10;
                    break;
                case 'AVAILABILITY_MASTER_IMP':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.AVAILABILITY_IMP_MASTER')}`;
                    width = columns[i].length + 10;
                    break;
                case 'AVAILABILITY_HOUSE_IMP':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.AVAILABILITY_IMP_HOUSE')}`;
                    width = columns[i].length + 10;
                    break;
                case 'ESTIMATED_PLACE_OF_RECEIPT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ESTIMATED_PLACE_OF_RECEIPT')}`;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length + 10;
                    break;
                case 'ACTUAL_PLACE_OF_RECEIPT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.ACTUAL_PLACE_OF_RECEIPT')}`;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length + 10;
                    break;
                case 'FLEXITANK':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('REGISTRATION.FLEXITANK')}`;
                    cellFilter = 'YesOrNo';
                    width = columns[i].length + 10;
                    break;
                case 'SECOND_REFERENCE_CUSTOMER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.SECOND_REFERENCE_CUSTOMER')}`;
                    break;
                case 'UN_NUMBER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.UN_NUMBER')}`;
                    width = columns[i].length + 10;
                    break;
                case 'DRAFT_NET_WEIGHT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('OPERATIONAL.DRAFT_TOTAL_NET_WEIGHT')}`;
                    width = columns[i].length + 10;
                    visible = true;
                    break;
                case 'TRUCK_LICENSE_PLATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.TRUCK_LICENSE_PLATE')}`;
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                case 'TRAILER_LICENSE_PLATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.TRAILER_LICENSE_PLATE')}`;
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                case 'DRIVER_NAME':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.DRIVER_NAME')}`;
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                case 'DRIVER_ID':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.DRIVER_ID')}`;
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                case 'NCM':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.NCM')}`;
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                case 'NALADI':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.NALADI')}`;
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                case 'UNITY_QUANTITY':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.UNITY_QUANTITY')}`;
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                case 'PACKAGE_QUANTITY':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.PACKAGE_QUANTITY')}`;
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                case 'GROUP_QUANTITY':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.GROUP_QUANTITY')}`;
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                case 'PACKAGE_GROUP':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.PACKAGE_GROUP')}`;
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                case 'GROSS_WEIGHT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.TOTAL_GROSS_WEIGHT_KG')}`;
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                case 'NET_WEIGHT':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.NET_WEIGHT_KG')}`;
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                case 'CUBIC_METER':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.CUBIC_METER')}`;
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                case 'REFERENCE_TRUCK':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.TRUCK_REFERENCE')}`;
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                case 'PICK_UP_FORECAST_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.PICK_UP_FORECAST_DATE')}`;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                case 'PICK_UP_EFFECTIVE_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.PICK_UP_EFFECTIVE_DATE')}`;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                case 'BORDER_NAME':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.BORDER_NAME')}`;
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                case 'ORIGIN_BORDER_CROSS_FORECAST_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.ORIGIN_BORDER_CROSS_FORECAST_DATE')}`;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                case 'ORIGIN_BORDER_CROSS_EFFECTIVE_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.ORIGIN_BORDER_CROSS_EFFECTIVE_DATE')}`;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                case 'DESTINATION_BORDER_CROSS_FORECAST_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.DESTINATION_BORDER_CROSS_FORECAST_DATE')}`;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                case 'DESTINATION_BORDER_CROSS_EFFECTIVE_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.DESTINATION_BORDER_CROSS_EFFECTIVE_DATE')}`;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                case 'DELIVERY_DEPARTURE_FORECAST_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.DELIVERY_DEPARTURE_FORECAST_DATE')}`;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                case 'DELIVERY_DEPARTURE_EFFECTIVE_DATE':
                    name = columns[i];
                    displayName = `${this.formService.getTranslate('GENERAL.DELIVERY_DEPARTURE_EFFECTIVE_DATE')}`;
                    cellFilter = 'date:\'dd/MM/yyyy\'';
                    width = columns[i].length + 10;
                    visible = false;
                    break;
                default:
                    continue;
            }
            const newColumn: IMonacoColumnDef = {
                searchProps: searchProps,
                name: name,
                field: name,
                displayName: displayName,
                headerCellClass: 'header-filtered',
                width: width + '%',
                cellTemplate: cellTemplate,
                cellFilter: cellFilter,
                filterCellFiltered: filterCellFiltered,
                pinnedLeft: pinned,
                visible: visible,
                headerCellTemplate: require("../../app/view/template/monaco-grid-cell-template.html"),
                filter: filter,
            };
            columnDefs.push(newColumn);
        }

        return columnDefs;
    }

    private async simulateData(): Promise<void> {
        try {
            if (!this.$scope.model.PROCESS && !this.$scope.model.INVOICE && !this.$scope.model.CHARGE) {
                this.$scope.gridDataManagerVisibleStatus = false;
                this.$scope.$applyAsync();
                this.$formService.notifyError(this.formService.getTranslate("GENERAL.SELECT_DATA_GROUP"));
                return;
            }

            this.block();

            this.$scope.gridDataManagerVisibleStatus = false;

            this.$timeout(async () => {
                if (this.dataManagerGrid) this.dataManagerGrid.destruct();
                this.dataManagerGrid = null;

                this.$scope.gridDataManagerVisibleStatus = true;
                const showProcess = (this.$scope.model.PROCESS) ? 1 : 0;
                const showInvoice = (this.$scope.model.INVOICE) ? 1 : 0;
                const showCharge = (this.$scope.model.CHARGE) ? 1 : 0;

                this.dataManagerGrid = new GridService2<DataManagerReportModel>(this.$injector, this.$scope, this, 'gridDataManager');
                await this.dataManagerGrid.removeAllActionColumn();
                this.dataManagerGrid.$baseUrl = this.getUrlDataBuilder();
                this.dataManagerGrid.$gridOptions.enablePagination = false;
                this.dataManagerGrid.$gridOptions.enablePaginationControls = false;
                this.dataManagerGrid.$gridOptions.exporterMenuAllData = false;
                this.dataManagerGrid.$gridOptions.exporterMenuExcel = false;
                this.dataManagerGrid.$gridOptions.exporterMenuPdf = false;
                this.dataManagerGrid.$gridOptions.exporterMenuVisibleData = false;
                this.dataManagerGrid.$gridOptions.enableSorting = false;
                this.dataManagerGrid.$gridOptions.exporterMenuCsv = false;
                this.dataManagerGrid.$gridOptions.exporterMenuItemOrder = 0;
                this.dataManagerGrid.RestrictiveSort = true;

                const dataBuilderRouteColumns = this.getUrlDataBuilder() + `/columns/${showProcess}/${showInvoice}/${showCharge}`;
                const result: IGridDataReturn = await this.RestService.getObjectAsPromise(dataBuilderRouteColumns, 10000, null, false);
                if (!result) return this.handleError('Nenhuma listagem de colunas encontrada');
                const columnsList = result.columns;
                const columnsDefList = (columnsList && columnsList.length > 0) ? await this.initGridColumnsData(columnsList) : [];
                if (!columnsDefList || columnsDefList.length == 0) return this.handleError('Nenhuma definição de colunas encontrada.');
                const dataBuilderRouteData = `/simulateReport/${showProcess}/${showInvoice}/${showCharge}`;
                this.dataManagerGrid.$requestGridDataTimeout = 300000;
                await this.dataManagerGrid.init('gridDataManager', dataBuilderRouteData, columnsDefList, [], false, true, true);

                this.dataManagerGrid.$gridOptions.gridMenuCustomItems[1].action = this.restoreReport.bind(this);

                this.dataManagerGridColDef = angular.copy(this.dataManagerGrid.$gridOptions.columnDefs);
                const gridState = this.dataManagerGrid.$gridApi.saveState.save();

                if (this.$scope.model.COLUMN) {
                    for (let index = this.$scope.model.COLUMN.length - 1; index >= 0; index--) {
                        const reportColumn = this.$scope.model.COLUMN[index];
                        const gridColumn = this.dataManagerGrid.$gridOptions.columnDefs.find(storedCol => storedCol.name == reportColumn.name);

                        if (gridColumn) {
                            if (reportColumn.alias) {
                                gridColumn.displayName = reportColumn.alias;
                            }

                            if (reportColumn.visible) {
                                const positionColumn = this.dataManagerGrid.$gridOptions.columnDefs.indexOf(gridColumn);
                                if (positionColumn >= 0) {
                                    this.dataManagerGrid.$gridOptions.columnDefs.splice(positionColumn, 1);
                                    this.dataManagerGrid.$gridOptions.columnDefs.unshift(gridColumn);
                                }
                            }
                        }
                    }
                }

                this.dataManagerGrid.$gridApi.core.notifyDataChange(this.dataManagerGrid.$gridConstants.dataChange.COLUMN);

                gridState.columns = (this.$scope.model.COLUMN) ? this.$scope.model.COLUMN : gridState.columns;

                this.dataManagerGrid.$gridApi.saveState.restore(this.$scope, gridState);

                await this.dataManagerGrid.filterChangedEventCallback();

                this.$scope.$apply();
                this.unblock();
            }).catch(x => {
                this.unblock();
                this.$formService.handleLoadError(x);
            })

        } catch (ex) {
            this.unblock();
            this.$formService.handleLoadError(ex);
        }
    }

    async save(): Promise<boolean> {
        try {
            if (this.dataManagerGrid) {
                const gridState = this.dataManagerGrid.$gridApi.saveState.save();
                this.$scope.model.COLUMN = gridState.columns.map(x => {
                    const colDef = this.dataManagerGrid.$gridOptions.columnDefs.find(col => col.name == x.name);
                    const dataReturn: IReportDataColumnDef = {
                        alias: (colDef) ? colDef.displayName : null,
                        filters: x.filters,
                        name: x.name,
                        pinned: x.pinned,
                        sort: x.sort,
                        visible: x.visible,
                        width: x.width,
                    };
                    return dataReturn;
                });
            }
            return true;
        } catch (ex) {
            this.$formService.handleLoadError(ex);
        }
        return false;
    }

    private async getItemBatchReport(entity: DataManagerReportModel) {
        try {
            this.block();

            this.$scope.batchReportList = [];
            this.$scope.batchReportID = null;
            this.$scope.showBatchReport = true;

            let id = entity.ID;
            const result = <IBatchReportResult[]>await this.requestBatchReport(id);

            this.$scope.batchReportAutoRefresh = false;

            for (const batchUnfinished of result) {
                if (batchUnfinished.SITUATION.CODE != '3') {
                    this.$scope.batchReportAutoRefresh = true;
                    break;
                }
            }

            this.$scope.batchReportList = result;
            this.$scope.originalBatchReportList = angular.copy(this.$scope.batchReportList);

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async manageRefreshItemBatchReport(entity: DataManagerReportModel) {

        await this.getItemBatchReport(this.$scope.model);

        if (!this.$scope.batchReportAutoRefresh) {
            clearInterval(this.$scope.batchReportTimer);
            this.$scope.batchReportTimer = null;
        }
    }

    private hideBatchReport() {
        clearInterval(this.$scope.batchReportTimer);
        this.$scope.batchReportTimer = null;
        this.$scope.batchReportList = [];
        this.$scope.batchReportID = null;
        this.$scope.showBatchReport = false;

    }

    private downloadFile(url: string) {
        window.open(url, '_blank');
    }

    async requestBatchReport(data: number) {
        try {
            if (!data) return null;
            if (typeof (data) === 'number') {
                const result = await this.getBatchReportById(data);
                return <IBatchReportResult[]>result.data;

            }
        } catch (ex) {
            return ex;
        }
    }
}

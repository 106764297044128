import * as angular from 'angular';
import '../../css/less/app.less';
import 'trix/dist/trix.esm.js';
import 'angular-trix/dist/angular-trix.min.js';
import 'moment';
import 'moment/locale/pt-br';
import 'moment/locale/en-ie';
import 'bootstrap';
import 'angular-dynamic-locale';
import 'angular-animate';
import 'angular-aria';
import 'angular-messages';
import 'angular-avatar';
import 'angular-ui-bootstrap';
import 'angular-cookies';
import 'angular-breadcrumb';
import 'angular-sanitize';
import 'ui-select';
import 'bootstrap-ui-datetime-picker';
import 'angular-file-upload';
import 'inputmask/dist/jquery.inputmask';
import '../../../node_modules/jquery-ui-bundle.1.12.1/jquery-ui';
import "../../../node_modules/intl-tel-input/build/js/utils.js";
import '../../libs/jquery.dragtable.js';
import 'bootstrap-table';
import 'bootstrap-table/dist/extensions/filter-control/bootstrap-table-filter-control';
import 'bootstrap-table/dist/extensions/fixed-columns/bootstrap-table-fixed-columns';
import 'bootstrap-table/dist/extensions/reorder-columns/bootstrap-table-reorder-columns';
import 'bootstrap-table/dist/extensions/multiple-sort/bootstrap-table-multiple-sort';
import 'bootstrap-table/dist/locale/bootstrap-table-pt-BR';

import monacoUploadOptions from './directives/values/monaco-upload-options';
import monacoDatetimePickerOptions from './directives/values/monaco-datetime-picker-options';

import { datePTBR, PrimarySecundary, YesOrNo, BytesToMegabytes, datetimePicker, formatLog, plural, fromNow, binaryText, selectorModel, typeName, byteToView, mailToGrid, mailIDToGrid, shipOwners, substring, fileName, selectorModelArray, dateTime, simpleDate, detalhedDate, getIDFromList, getConcat, formatInformationPairs, purgeFollowUpHTML, hourMinute, dateTimeFormated, cnpj, filterWithWildCard } from './filter/filters';

import { setNgAnimate } from './directives/setnganimate';
import { uiButterbar } from './directives/ui-butterbar';
import { uiFocus } from './directives/ui-focus';
import { uiFullscreen } from './directives/ui-fullscreen';
import { uiNav } from './directives/ui-nav';
import { uiScrollTo } from './directives/ui-scroll';
import { uiShift } from './directives/ui-shift';
import { uiToggleClass } from './directives/ui-toggleclass';
import { monacoUiMultiLineDirective } from './directives/monaco-ui-multi-line';
import { multiLineDirective, lineDirective, columnDirective } from './directives/multi-line';
import { btnYesNoDirective } from './directives/btn-yes-no';
import { floatingMenuDirective } from './directives/floating-menu';
import { labelCheckboxDirective } from "./directives/label-checkbox";
import { logViewerDirective } from "./directives/log-viewer";
import { fabDirective } from "./directives/fabDirective";
import { deepLogViewerDirective } from "./directives/deep-log-viewer";
import { smartLockDirective } from "../common/smartLockDirective";
import { uiSelectRequired } from "./directives/ui-select-required";
import { uiSearchPanel } from "./directives/ui-search-panel";
import { monacoUiSelect } from "./directives/monaco-ui-select";
import { monacoSelect } from "./directives/monaco-select";
import { ellipsisTooltip } from "./directives/ellipsis-tooltip";
import { monacoPermission } from "./directives/monaco-permission";
import { monacoInsertOption } from "./directives/monaco-insert-option";
import { setGridOptions } from "./directives/set-grid-options";
import { uiSelectPorts } from "./directives/ui-select-ports";
import { monacoUiUpload } from "./directives/monaco-ui-upload";
import { monacoUiCheckbox } from "./directives/monaco-ui-checkbox";
import { iCountdown } from "./directives/i-countdown";
import { uiSelectExternalContacts } from "./directives/ui-select-external-contacts";
import { uiSelectMasterDocuments } from "./directives/ui-select-master-documents";
import { uiGridFilter } from "./directives/ui-grid-filter";
import { btnActiveDirective } from "./directives/btn-active";
import { monacoUpload } from "./directives/monaco-upload";
import { monacoDataTable } from "./directives/monaco-data-table";

import { DatetimePickerComponent } from "./components/datetimepicker/DatetimePickerComponent";
import { MonacoDateTimePickerComponent } from './components/datetimepicker/MonacoDateTimePickerComponent';

import { ErrorService } from '@services/ErrorService';
import { NotificationService } from '@services/NotificationService';
import { RestService } from '@services/RestService';
import { ModalService } from '@services/ModalService';
import { HelperService } from '@services/HelperService';
import { RegularAuthService } from './services/RegularAuthService';
import { GoogleAuthService } from './services/GoogleAuthService';
import { ErrorController } from './controllers/ErrorController';
import { AppController } from './controllers/AppController';
import { LoginController } from './controllers/LoginController';
import { HomeController } from './controllers/HomeController';
import { AccessDeniedController } from './controllers/AccessDeniedController';
import { FileReferenceModalController } from './controllers/FileReferenceModalController';
import { ExcelTemplateModalController } from './controllers/ExcelTemplateModalController';
import { NewIntegrationRedundanceModalController } from '../commercial/controller/NewIntegrationRedundanceModalController';
import { CompareProcessFromProcessModalController } from '../operational/controller/CompareProcessFromProcessModalController';
import { TariffFreightChargesModalController } from '../product/controller/TariffFreightChargesModalController';
import { ChargesPerWeightRangeModalController } from './controllers/ChargesPerWeightRangeModalController';

import initRoute from './RouteConfig';
import initBreadCrumb from './BreadCrumbConfig';
import { lightDataTable } from './components/lightDataTable/LightDataTableController';
import { selectNgFiles } from './directives/selectNgfiles';
import { OperationalService } from '@services/OperationalService';

const monacoDateTimePicker = new MonacoDateTimePickerComponent();

const APP_MODULE = angular
    .module('app', ['bootstrap', 'ngAnimate', 'ngSanitize', 'ngAria', 'ngMessages', 'ngAvatar', 'ui.bootstrap', 'ui.bootstrap.datetimepicker', 'ui.select', 'ngCookies', 'ncy-angular-breadcrumb', 'angularTrix', 'angularFileUpload', 'tmh.dynamicLocale'])

    .config(['tmhDynamicLocaleProvider', function (tmhDynamicLocaleProvider) {
        tmhDynamicLocaleProvider.localeLocationPattern('../../i18n/datetimepicker/{{locale}}.js');
    }])

    .constant('uiDatetimePickerConfig', monacoDatetimePickerOptions)
    .constant('fileUploaderOptions', monacoUploadOptions)

    .filter('cnpj', cnpj)
    .filter('datePTBR', datePTBR)
    .filter('getIDFromList', getIDFromList)
    .filter('YesOrNo', YesOrNo)
    .filter('BytesToMegabytes', BytesToMegabytes)
    .filter('PrimarySecundary', PrimarySecundary)
    .filter('binaryText', binaryText)
    .filter('datetimepicker', datetimePicker)

    .filter('simpleDate', simpleDate)
    .filter('datetime', dateTime)
    .filter('datetimeformated', dateTimeFormated)
    .filter('hourminute', hourMinute)
    .filter('detalhedDate', detalhedDate)

    .filter('formatLog', formatLog)
    .filter('plural', plural)
    .filter('fromNow', fromNow)
    .filter('substring', substring)
    .filter('selectorModelArray', selectorModelArray)

    .filter('selectormodel', selectorModel)
    .filter('typename', typeName)
    .filter('filename', fileName)
    .filter('byteToView', byteToView)
    .filter('mailToGrid', mailToGrid)
    .filter('mailIDToGrid', mailIDToGrid)
    .filter('getConcat', getConcat)
    .filter('formatKeyValuePairs', formatInformationPairs)
    .filter('purgeFollowUpHTML', purgeFollowUpHTML)
    .filter('filterWithWildCard', filterWithWildCard)

    .directive('setNgAnimate', setNgAnimate)
    .directive('uiButterbar', uiButterbar)
    .directive('uiFocus', uiFocus)

    .directive('uiFullscreen', uiFullscreen)
    .directive('uiNav', uiNav)
    .directive('uiScrollTo', uiScrollTo)
    .directive('uiShift', uiShift)
    .directive('uiToggleClass', uiToggleClass)
    .directive('monacoUiMultiLine', monacoUiMultiLineDirective)
    .directive('multiLine', multiLineDirective)
    .directive('btnYesNo', btnYesNoDirective)
    .directive('floatingMenu', floatingMenuDirective)
    .directive('line', lineDirective)
    .directive('column', columnDirective)
    .directive('labelCheckbox', labelCheckboxDirective)
    .directive('logViewer', logViewerDirective)
    .directive('deepLogViewer', deepLogViewerDirective)
    .directive('fab', fabDirective)
    .directive('smartLock', smartLockDirective)
    .directive('uiSelectRequired', uiSelectRequired)
    .directive('uiSearchPanel', uiSearchPanel)
    .directive('monacoUiSelect', monacoUiSelect)
    .directive('monacoSelect', monacoSelect)
    .directive('ellipsisTooltip', ellipsisTooltip)
    .directive('monacoPermission', monacoPermission)
    .directive("selectNgFiles", selectNgFiles)
    .directive('monacoInsertOption', monacoInsertOption)
    .directive('setGridOptions', setGridOptions)
    .directive('uiSelectPorts', uiSelectPorts)
    .directive('uiSelectExternalContacts', uiSelectExternalContacts)
    .directive('uiSelectMasterDocuments', uiSelectMasterDocuments)
    .directive('monacoUiUpload', monacoUiUpload)
    .directive('monacoUiCheckbox', monacoUiCheckbox)
    .directive('iCountdown', iCountdown)
    .directive('uiGridFilter', uiGridFilter)
    .directive('btnActive', btnActiveDirective)
    .directive('monacoUpload', monacoUpload)
    .directive('monacoDataTable', monacoDataTable)
    .directive('lightDataTable', lightDataTable)

    .component('datetimePicker', new DatetimePickerComponent())
    .component('tocson', monacoDateTimePicker)

    .service('ErrorService', ErrorService)
    .service('NotificationService', NotificationService)
    .service("RestService", RestService)
    .service('RegularAuthService', RegularAuthService)
    .service('GoogleAuthService', GoogleAuthService)
    .service('ModalService', ModalService)
    .service('HelperService', HelperService)
    .service('OperationalService', OperationalService)

    .controller('AppController', AppController)
    .controller('ErrorController', ErrorController)
    .controller('LoginController', LoginController)
    .controller('HomeController', HomeController)
    .controller('AccessDeniedController', AccessDeniedController)
    .controller('FileReferenceModalController', FileReferenceModalController)
    .controller('ExcelTemplateModalController', ExcelTemplateModalController)
    .controller('NewIntegrationRedundanceModalController', NewIntegrationRedundanceModalController)
    .controller('compareProcessFromProcessModalController', CompareProcessFromProcessModalController)
    .controller('TariffFreightChargesModalController', TariffFreightChargesModalController)
    .controller('ChargesPerWeightRangeModalController', ChargesPerWeightRangeModalController)

initRoute(APP_MODULE.name);
initBreadCrumb(APP_MODULE.name);

export { APP_MODULE };

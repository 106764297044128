import * as angular from 'angular';
import { INotificationService } from '@services/NotificationService';
import { FormService2 } from '@services/FormService2';
import { IMonacoRequest } from "@services/GridFormService";
import { IRestService } from '@services/RestService';
import { CellGroupSpecModel, CellGroupModel } from '@models/interface/common/CellModel';
import { ISelectorModel } from "@models/mongo/SelectorModel";
import { IAccountSelector, IServiceProviderSelector } from "@models/interface/operational/IProductIntegrationSelector";
import { SelectorModel } from './../../common/model/SelectorModel';
import { IScopeSpec } from '../interface/ICellParameterization';
import { HandleError } from '../../common/util/HandleError';
import { ILegalPersonSelector } from 'WBA-Model/dist/interface/product/LegalPersonModel';
import { ELegalPersonSpecializationId } from '@enums/LegalPerson';

SpecRegisterController.$inject = ['$scope', '$injector', '$q', 'RestService', 'NotificationService', 'blockUI', 'ProductService', 'HelperService'];
export function SpecRegisterController($scope: IScopeSpec, $injector, $q: ng.IQService, RestService: IRestService, NotificationService: INotificationService, blockUI: ng.blockUI.BlockUIService, ProductService, HelperService) {
    //let formService = new FormService2($injector, <any>$scope);
    let processType = undefined;
    let productList = undefined;

    this.$onInit = async function () {
        try {
            blockUI.start();

            //load dependencies
            const result: Array<any> = await $q.all([
                getGenericList('process_type'),
                getGenericList('product'),
                getGenericList('account_qualification'),
                getGenericList('locals_group'),
                getGenericList('locals_group'),
                getGenericList('type_cargo')
            ]);

            processType = result[0];
            productList = result[1];
            $scope.accountQualificationList = result[2];
            $scope.tradeGroupOriginList = result[3];
            $scope.tradeGroupDestinationList = result[4];
            $scope.cargoTypeList = result[5];

            $scope.$applyAsync(function () { blockUI.stop(); });
        } catch (ex) {
            $scope.$applyAsync(function () { blockUI.stop(); });
            HandleError.exception(ex);
        }
    }

    //WATCHES
    $scope.$parent.$watch('spec', (newSpec: CellGroupSpecModel) => {
        $scope.spec = angular.copy(newSpec);
    });

    $scope.$parent.$watch('specOperation', () => {
        $scope.operation = $scope.$parent.specOperation;
    });


    //CRUD
    $scope.add = ($event) => {
        if (FormService2.hasRequiredElements('#specModal')) {
            NotificationService.error('Preencha todos os campos obrigatórios (*).');
            return;
        } else {
            addSpecGroup($scope.spec);
            resetSpec();
            closeModal();
        }

    }

    $scope.update = () => {
        const isInvalid = FormService2.hasRequiredElements('#specModal');
        if (isInvalid) {
            NotificationService.error('Preencha todos os campos obrigatórios (*).');
            return;
        }

        updateSpecGroup($scope.spec);
        closeModal();
    }

    $scope.remove = function ($event) {
        removeSpecFromGroup($scope.spec)
    }

    //UI SELECT SEARCH
    $scope.searchProduct = async (query): Promise<Array<SelectorModel>> => {
        return productList;
    }

    $scope.searchProcess = async (query): Promise<Array<SelectorModel>> => {
        return processType;
    }

    $scope.getServiceProviderListByName = async (term: string) => {
        await getServiceProviderListByName(term);
    }

    $scope.getCorporateBranchListByName = async (term: string): Promise<void> => {
        $scope.corporateBranchList = [];
        if (term && term.length >= 3) $scope.corporateBranchList = await getCorporateBranchListByName(term, $scope.products);
    }

    $scope.getAgentListByName = async (term: string): Promise<void> => {
        $scope.agentList = [];
        if (term && term.length >= 3) $scope.agentList = await getAgentListByName(term);
    }

    $scope.getCommercialBranchListByName = async (term: string): Promise<void> => {
        $scope.agentList = [];
        if (term && term.length >= 3) $scope.commercialBranchList = await getCommercialBranchListByName(term);
    }

    $scope.getAccountListByName = async (term: string) => {
        await getAccountListByName(term);
    }

    //REQUESTS
    async function getGenericList(type): Promise<SelectorModel[]> {
        try {
            const { data: generic } = await HelperService.get(`/generic/value/${type}`, null, 10000);
            return generic && generic.data ? generic.data : [];
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    async function getServiceProviderListByName(search: string): Promise<void> {
        try {
            $scope.serviceProviderList = [];
            if (search && search.length >= 3) {
                blockUI.start();

                const request: IMonacoRequest = {
                    data: {
                        search
                    },
                    route: `/provider/list/custom`,
                    timeout: 30000,
                }
                const result = await ProductService.post(request)
                if (result && result.data && result.status == 200) {
                    const providerList: IServiceProviderSelector[] = result.data.data;
                    for (const resultItem of providerList) {
                        const serviceProvider: ISelectorModel = { ID: resultItem.ID, CODE: resultItem.SCAC_IATA, NAME: resultItem.NAME }
                        $scope.serviceProviderList.push(serviceProvider);
                    }
                }
                blockUI.stop();
            }
        } catch (ex) {
            $scope.$applyAsync(function () { blockUI.stop(); });
            HandleError.exception(ex);
        }
    }

    async function getAccountListByName(search: string): Promise<void> {
        try {
            $scope.accountList = [];
            if (search && search.length >= 3) {
                blockUI.start();
                const request: IMonacoRequest = {
                    data: {
                        search
                    },
                    route: `/account/list/custom`,
                    timeout: 30000,
                };
                const result = await ProductService.post(request);
                if (result && result.data && result.status == 200) {
                    const accountList: IAccountSelector[] = result.data.data.data;
                    for (const resultItem of accountList) {
                        const account: ISelectorModel = { ID: resultItem.ID, CODE: (resultItem.LEGAL_PERSON && resultItem.LEGAL_PERSON.ID_FISCAL) ? resultItem.LEGAL_PERSON.ID_FISCAL : null, NAME: resultItem.NAME }
                        $scope.accountList.push(account);
                    }
                };
                blockUI.stop();
            }
        } catch (ex) {
            $scope.$applyAsync(function () { blockUI.stop(); });
            HandleError.exception(ex);
        }
    }

    async function getCorporateBranchListByName(search?: string, products?: SelectorModel[]): Promise<SelectorModel[]> {
        try {
            blockUI.start();
            const request: IMonacoRequest = {
                data: {
                    search,
                    products: products && products.length ? products.map(product => product.ID) : null
                },
                route: `/corporateBranch/list/custom`,
                timeout: 30000,
            };

            const result = await ProductService.post(request);
            const corporateBranchResult: ISelectorModel[] = [];
            if (result && result.data && result.status == 200) {
                const corporateBranchList: SelectorModel[] = result.data.data;
                for (const resultItem of corporateBranchList) {
                    const corporateBranch: ISelectorModel = { ID: resultItem.ID, CODE: resultItem.CODE, NAME: resultItem.NAME }
                    corporateBranchResult.push(corporateBranch);
                }
            }
            blockUI.stop();

            return corporateBranchResult;
        } catch (ex) {
            $scope.$applyAsync(function () { blockUI.stop(); });
            HandleError.exception(ex);
        }
    }

    async function getAgentListByName(search: string): Promise<SelectorModel[]> {
        try {
            blockUI.start();
            const request: IMonacoRequest = {
                data: {
                    search
                },
                route: `/agent/list/custom`,
                timeout: 30000,
            };

            const result = await ProductService.post(request);
            const agentResult: ISelectorModel[] = [];
            if (result && result.data && result.status == 200) {
                const agentList: SelectorModel[] = result.data.data.data;
                for (const resultItem of agentList) {
                    const agent: ISelectorModel = { ID: resultItem.ID, CODE: resultItem.CODE, NAME: resultItem.NAME }
                    agentResult.push(agent);
                }
            }
            blockUI.stop();

            return agentResult;
        } catch (ex) {
            $scope.$applyAsync(function () { blockUI.stop(); });
            HandleError.exception(ex);
        }
    }

    async function getCommercialBranchListByName(search: string): Promise<SelectorModel[]> {
        try {
            blockUI.start();
            const request: IMonacoRequest = {
                data: {
                    search,
                    specializations: [ELegalPersonSpecializationId.COMMERCIAL_UNITY]
                },
                route: `/legalPerson/list/custom`,
                timeout: 30000,
            };

            const result = await ProductService.post(request);
            const commercialBranchResult: ISelectorModel[] = [];
            if (result && result.data && result.status == 200) {
                const commercialBranchList: ILegalPersonSelector[] = result.data.data;
                for (const resultItem of commercialBranchList) {
                    const commercialBranch: ISelectorModel = { ID: resultItem.ID, CODE: resultItem.CODE, NAME: resultItem.NAME }
                    commercialBranchResult.push(commercialBranch);
                }
            }
            blockUI.stop();

            return commercialBranchResult;
        } catch (ex) {
            $scope.$applyAsync(function () { blockUI.stop(); });
            HandleError.exception(ex);
        }
    }

    //AUXS
    function getCurrentGroup(): CellGroupModel {
        return $scope.$parent.group;
    }

    function indexOfSpec(spec: CellGroupSpecModel, specs: Array<CellGroupSpecModel>): number {

        let index = -1;
        specs.forEach((item: CellGroupSpecModel, key: number) => {
            if (item.ID == spec.ID) {
                index = key;
                return;
            }
        });

        return index;

    }

    function closeModal(): void {
        $scope.$parent.modalOptions.ok();
    }

    function updateSpecGroup(spec: CellGroupSpecModel): void {
        const index = indexOfSpec(spec, $scope.$parent.group.SPEC);
        const hasIndex = index > -1;

        if (hasIndex) $scope.$parent.group.SPEC[index] = $scope.spec;
    }

    function addSpecGroup(spec: CellGroupSpecModel): void {
        $scope.$parent.group.SPEC.push(spec);
    }

    function removeSpecFromGroup(spec: CellGroupSpecModel) {
        const index = indexOfSpec(spec, $scope.$parent.group.SPEC);
        const hasIndex = index > -1;

        if (hasIndex) $scope.$parent.group.SPEC.splice(index, 1);
    }

    function resetSpec() {
        const spec = {
            ID: null,
            NAME: null,
            BRANCH: [],
            PRODUCT: [],
            CLIENT: [],
            PROCESS_TYPE: [],
            SERVICE_PROVIDER: [],
            AGENT: [],
            CUSTOMER_QUALIFICATION: [],
            COMMERCIAL_UNITY: [],
            TRADE_GROUP_ORIGIN: [],
            TRADE_GROUP_DESTINATION: [],
            CARGO_TYPE: [],
            SINCE: null
        }

        $scope.spec = spec;
    }

};
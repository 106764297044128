import { IMonacoScope } from '../../common/MonacoInterface';
import { FormService2 } from '@services/FormService2';
import { ISessionService } from '@services/SessionService';
import config from '../../bootstrap/Config';
import { BrowserTitle } from '../../common/BrowserTitle';
import { IProcessParameter, IInvoiceParameter, IChargeParameter, IProcessDocumentParameter, IOfferParameter } from "../../common/model/ModelParameter";
import { NotificationBox } from '../../common/NotificationBox';
import { BootstrapController } from '../../bootstrap/controller/BootstrapController';
import { OperationalService } from '@services/OperationalService';
import * as Address from '../../communication/Address';

enum quickSearchOptions {
  PROCESS = "PROCESS",
  BOOKING = "BOOKING",
  CONTAINER = "CONTAINER",
  INVOICE = "INVOICE",
  CHARGE = "CHARGE",
  DOCUMENT = "DOCUMENT",
  QUOTATION = "QUOTATION",
}

interface IQuickSearch {
  processInfo: string,
  bookingInfo: string,
  containerInfo: string,
  invoiceInfo: string,
  chargeInfo: string,
  documentInfo: string,
  quotationInfo: string,
  doSearch: (opt: quickSearchOptions) => void
}

HomeController.$inject = ['$scope', '$state', '$rootScope', 'RestService', '$injector', '$q', "SessionService", "$sce", "OperationalService"];
export function HomeController($scope, $state, $rootScope: IMonacoScope, RestService, $injector, $q: ng.IQService, sessionService: ISessionService, SCEService: ng.ISCEService, OperationalService: OperationalService) {

  let formService = new FormService2($injector, $scope);

  this.$onInit = async function () {

    try {
      formService.block();
      BrowserTitle.$menu = "Home";
      $scope.dateTimeFormat = 'dd/MM/yyyy HH:mm:ss';
      $scope.processBookMarkedList = undefined;
      $scope.processPriorityList = undefined;
      $scope.serviceStatusList = undefined;
      $scope.processOkCount = undefined;
      $scope.processCountIcon = "fa-line-chart text-success";
      $scope.processCancelledCount = undefined;
      $scope.processOkCountLabel = "Quantidade de processos válidos";
      $scope.env = config.environment;
      $scope.version = config.version;
      $scope.quickSearch = quickSearch;

      $scope.htmlPopover = SCEService.trustAsHtml("Insira o número de uma fatura para filtrar as taxas pela fatura!");
      formService.unblock();

      await refreshNotificationBox();

      setInterval(async () => {
        await refreshNotificationBox();
      }, 300000);

    } catch (ex) {
      formService.handleLoadError(ex)
    }
  }

  async function refreshNotificationBox(): Promise<void> {
    if ($injector && BootstrapController && BootstrapController.BootstrapScope) {
      await NotificationBox.Instance($injector).init(BootstrapController.BootstrapScope);
      await $scope.$applyAsync();
    }
  }

  $scope.viewProcess = function (process) {
    sessionService.openTab("app.operational.newProcess.list", <IProcessParameter>{ PROCESS_NUMBER: process });
  };

  $scope.listBookmarked = async () => {
    try {
      const result = await $q.all([getBookmarkedProcess()]);
      let processList = (result[0]['data']) ? result[0]['data'] : null;
      $("#collapseEventsBooks").toggle(20);
      formService.block();
      if (processList.length > 0) {
        $scope.processBookMarkedList = processList;
        $scope.$applyAsync();
      }

      formService.unblock();
    } catch (ex) {
      formService.unblock();
      formService.handleLoadError(ex);
    }
  }

  $scope.listPriority = async () => {
    try {
      const result = await $q.all([getPriorityProcess()]);
      let processList = (result[0]['data']) ? result[0]['data'] : null;
      $("#collapseEvents").toggle(20);
      formService.block();
      if (processList && processList.length > 0) {
        $scope.processPriorityList = processList;
        $scope.$applyAsync();
      }

      formService.unblock();
    } catch (ex) {
      formService.unblock();
      formService.handleLoadError(ex);
    }
  }

  $scope.removeBookmarkProcess = async (processNumber, index) => {
    if (!processNumber) return;
    try {
      formService.block();
      const removed = await $rootScope.removeSetting('PROCESS_BOOKMARKED', processNumber, false);
      if (removed) {
        formService.notifySuccess(formService.getTranslate("OPERATIONAL.REMOVE_STAR"));
        $scope.processBookMarkedList.splice(index, 1);
        $scope.$applyAsync();
      } else {
        formService.notifyError(formService.getTranslate("OPERATIONAL.REMOVE_STAR_ERROR"));
      }

      formService.unblock();
    } catch (ex) {
      formService.unblock();
      formService.handleLoadError(ex);
    }
  }

  async function getBookmarkedProcess() {
    const timeout = 120000;
    const request = await OperationalService.get(`/process/bookmarked/user/${$scope.user._id}/${timeout}`, timeout);

    return request && request.data;
  }

  async function getPriorityProcess() {
    const timeout = 120000;
    const request = await OperationalService.get(`/process/priority/user/${$scope.user._id}/${timeout}`, timeout);

    return request && request.data;
  }

  function getProcessCount(status: string) {
    //return RestService.getObjectAsPromise(`/process/total/${status}`, 120000);
  }

  function getServiceStatus() {
    const config = $injector.get('config');
    let monitorRoute = null;
    if (config.environment === 'prod') {
      monitorRoute = `${Address.monacoAddressProd.MONITOR}/api/v1/monitor/status`;
    } else if (config.environment === 'qa') {
      monitorRoute = `${Address.monacoAddressQa.MONITOR}/api/v1/monitor/status`;
    } else { //dev
      monitorRoute = `${Address.monacoAddressLocal.MONITOR}/api/v1/monitor/status`;
    }
    const $http = $injector.get('$http');
    return $http({
      method: 'get',
      url: monitorRoute,
      cache: false,
      timeout: 10000
    });
  }

  const quickSearch: IQuickSearch = {
    bookingInfo: "",
    chargeInfo: "",
    containerInfo: "",
    documentInfo: "",
    invoiceInfo: "",
    processInfo: "",
    quotationInfo: "",
    doSearch: (option: quickSearchOptions) => DoSearch(option)
  }

  async function DoSearch(searchOption: quickSearchOptions) {
    try {
      switch (searchOption) {
        case quickSearchOptions.PROCESS:
          if (!$scope.quickSearch.processInfo) return formService.notifyError(formService.getTranslate("GENERAL.MESSAGES.NOTHING_TO_SEARCH_TYPE_SOMETHING"));
          sessionService.openTab("app.operational.newProcess.list", <IProcessParameter>{ PROCESS_NUMBER: $scope.quickSearch.processInfo });
          /*
          const process_id = await getProcessByNumber($scope.quickSearch.processInfo)
          if (!process_id || process_id === '') return formService.notifyError('Processo não encontrado!');
          $state.go('app.operational.newProcess.view', {
            process: process_id,
            operation: "view"
          }, {
            reload: false
          });
          */
          break;
        case quickSearchOptions.BOOKING:
          if (!$scope.quickSearch.bookingInfo) return formService.notifyError(formService.getTranslate("GENERAL.MESSAGES.NOTHING_TO_SEARCH_TYPE_SOMETHING"));
          sessionService.openTab("app.operational.newProcess.list", <IProcessParameter>{ BOOKING: $scope.quickSearch.bookingInfo });
          break;
        case quickSearchOptions.CONTAINER:
          if (!$scope.quickSearch.containerInfo) return formService.notifyError(formService.getTranslate("GENERAL.MESSAGES.NOTHING_TO_SEARCH_TYPE_SOMETHING"));
          sessionService.openTab("app.operational.newProcess.list", <IProcessParameter>{ EQUIPMENTS: $scope.quickSearch.containerInfo });
          break;
        case quickSearchOptions.INVOICE:
          if (!$scope.quickSearch.invoiceInfo) return formService.notifyError(formService.getTranslate("GENERAL.MESSAGES.NOTHING_TO_SEARCH_TYPE_SOMETHING"));
          sessionService.openTab("app.finop.invoice.register", <IInvoiceParameter>{ INVOICE_NUMBER: $scope.quickSearch.invoiceInfo });
          break;
        case quickSearchOptions.CHARGE:
          if (!$scope.quickSearch.chargeInfo) return formService.notifyError(formService.getTranslate("GENERAL.MESSAGES.NOTHING_TO_SEARCH_TYPE_SOMETHING"));
          sessionService.openTab("app.finop.charge.register", <IChargeParameter>{ "INVOICE_NUMBER.NAME": $scope.quickSearch.chargeInfo });
          break;
        case quickSearchOptions.DOCUMENT:
          if (!$scope.quickSearch.documentInfo) return formService.notifyError(formService.getTranslate("GENERAL.MESSAGES.NOTHING_TO_SEARCH_TYPE_SOMETHING"));
          sessionService.openTab("app.operational.process.processDocument", <IProcessDocumentParameter>{ DOCUMENT: $scope.quickSearch.documentInfo });
          break;
        case quickSearchOptions.QUOTATION:
          if (!$scope.quickSearch.quotationInfo) return formService.notifyError(formService.getTranslate("GENERAL.MESSAGES.NOTHING_TO_SEARCH_TYPE_SOMETHING"));
          sessionService.openTab("app.commercial.offer", <IOfferParameter>{ OFFER_CODE_EXHIBITION: $scope.quickSearch.quotationInfo });
          break;
      }
    }
    catch (ex) {
      formService.notifyError("Ocorreu um erro no redirecionamento: " + ex)
    }
  }

  async function getProcessByNumber(processNumber: string) {
    try {
      formService.block();
      const processPromise = await RestService.getObjectAsPromise(`/process/number/${processNumber}`, 30000);
      formService.unblock();
      if (!processPromise || !processPromise.data || !processPromise.data.length) return '';
      return processPromise.data[0]._id;
    }
    catch (ex) {
      formService.unblock();
      return formService.notifyError(`Ocorreu um erro ao tentar encontrar o process! ${ex}`);
    }
  }
}

export const enum ESankhyaServiceName {
    AUTH = "MobileLoginSP.login",
    CSP_LOAD_RECORDS = "CRUDServiceProvider.loadRecords",
    CSP_SAVE_RECORD = "CRUDServiceProvider.saveRecord",
    DESP_EXECUTE_QUERY = "DbExplorerSP.executeQuery",
    DSP_SAVE = "DatasetSP.save",
    DSP_LOAD_RECORDS = "DatasetSP.loadRecords",
    CACSP_CONFIRM_INVOICE = "CACSP.confirmarNota",
    CACSP_CANCEL_INVOICE = "CACSP.cancelarNota",
    CACSP_INSERT_INVOICE = "CACSP.incluirNota",
    CACSP_UPDATE_INVOICE = "CACSP.incluirAlterarCabecalhoNota",
    CACSP_UPDATE_INVOICE_ITEM = "CACSP.incluirAlterarItemNota",
    SDSP_BILLING = "SelecaoDocumentoSP.faturar",
    CFSP = "CompensacaoFinanceiraSP.acertar",
    CFSP_CANCEL = "CompensacaoFinanceiraSP.desfazer",
    FDSP_DUPLICATE = "FormacaoDuplicataSP.formarDuplicatas",
    RSP_CANCEL = "RenegociacaoSP.desfazer"
}

export const enum EEndpoints {
    AUTH = "AUTH",
    TOP = "TOP",
    COMPANY = "COMPANY",
    CITY = "CITY",
    RESULT_CENTER = "RESULT_CENTER",
    FINISH_RESULT_CENTER = "FINISH_RESULT_CENTER",
    RESULT_CENTER_INSERT = "RESULT_CENTER_INSERT",
    RESULT_CENTER_FATHER_INSERT = "RESULT_CENTER_FATHER_INSERT",
    PARTNER_PARAM_STATUS = "PARTNER_PARAM_STATUS",
    PARTNER_FIND = "PARTNER_FIND",
    PARTNER_INSERT = "PARTNER_INSERT",
    NEXT_RESULT_CENTER = "NEXT_RESULT_CENTER",
    SERVICE_INSERT = "SERVICE_INSERT",
    SERVICE_PARAM_STATUS = "SERVICE_PARAM_STATUS",
    SERVICE_GET = "SERVICE_GET",
    NATURE = "NATURE",
    PAYMENT_METHOD = "PAYMENT_METHOD",
    PROJECT = "PROJECT",
    NEGOTIATION_TYPE = "NEGOTIATION_TYPE",
    INVOICE_STATUS = "INVOICE_STATUS",
    INVOICE_CANCEL = "INVOICE_CANCEL",
    INVOICE_INSERT = "INVOICE_INSERT",
    INVOICE_UPDATE = "INVOICE_UPDATE",
    INVOICE_CONFIRM = "INVOICE_CONFIRM",
    INVOICE_UPDATE_ITEM = "INVOICE_UPDATE_ITEM",
    BILLING_CREATE = "BILLING_CREATE",
    BILLING_PAID = "BILLING_PAID",
    BILLING_PAID_UPDATE = "BILLING_PAID_UPDATE",
    FINANTIAL_COMPENSATION = "FINANTIAL_COMPENSATION",
    GENERATE_DUPLICATE = "GENERATE_DUPLICATE",
    COMPENSATION_CANCEL = "COMPENSATION_CANCEL",
    RENEGOTIATION_CANCEL = "RENEGOTIATION_CANCEL",
    INVOICE_EXISTS = "INVOICE_EXISTS",
}

export const enum ESankhyaEndpoint {
    MGE = "/mge/service.sbr",
    MEGCOM = "/mgecom/service.sbr",
    MEGFIN = "/mgefin/service.sbr",
}

export const enum ESankhyaStatusCode {
    ERROR = "0",
    SUCCESS = "1",
    NOT_AUTHORIZED = "3",
    ALREADY_CONFIRMED = "2"
}

export const enum ESankhyaStatusMessage {
    EXCLUSIVE_RESTRICTION = "ORA-00001",
    ALREADY_CONFIRMED = "foi confirmada",
    NOT_PENDING = "esta pendente",
    TOP_NO_AUTOMATIC = "TOP marcada para Manual"
}

export const enum ESankhyaEntityName {
    COMPANY = "Empresa",
    PARTNER = "Parceiro",
    OPERATION_TYPE = "TipoOperacao",
    RESULT_CENTER = "CentroResultado",
    CITY = "Cidade",
    SERVICE = "Servico",
    PRODUCT = "Produto",
    NATURE = "Natureza",
    PAYMENT_METHOD = "TipoTitulo",
    NEGOTIATION_TYPE = "TipoNegociacao",
    PROJECT = "Projeto",
}

export const enum ESankhyaCancelCode {
    RELEASED = "L",
    CANCELED = "C",
    PROGRESS = "A",
    PENDING = "P"
}

export const enum EDocType {
    CPF = "CPF",
    CNPJ = "CNPJ",
    VAT = "VAT",
    NIF = "NIF"
}

export const enum ETipMov {
    PURCHASE_ORDER = "O",
    SALES_ORDER = "P",
    PURCHASE_INVOICE = "C",
    SALES_INVOICE = "V"
}

export const enum ESankhyaServiceCodes {
    PURCHASE = "1010000",
    SALES = "2010000"
}

export const enum EGenericPartnerStatus {
    TRUE = "S",
    FALSE = "N"
}

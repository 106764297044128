import * as moment from 'moment';
import { IMonacoController } from "../../common/MonacoInterface";
import { FormService2 } from '@services/FormService2';
import { IDocumentError } from "@models/interface/common/IDocumentError";

export interface INewIntegrationRedundanceMessageDefault {
    awaitingRequest: string;
    successRequest: string;
    awaitingGridSync: string;
    successGridSync: string;
    gridSyncError: string;
    logCopyMessage: string;
    requestError: string;
}

export interface INewIntegrationRedundanceModalScope extends ng.IScope {
    modalID: number;
    integrationId: number;
    gridRowId?: number;
    documentErrorList: IDocumentError[];
    messageDefault: INewIntegrationRedundanceMessageDefault;
    fnSync: (integrationId: number, subjectId?: string) => Promise<boolean>;
    fnUpdateIntegrationGrid?: (id: number) => Promise<IDocumentError[]>;
    copyErrorTextToClipboard: (documentError: IDocumentError) => void;
    parseErrorMessage: (documentError: IDocumentError) => string;
    sendSync: (subjectId?: string) => void;
    notButtonSync?: boolean;
}

export class NewIntegrationRedundanceModalController implements IMonacoController {
    public static $inject: string[] = ['$scope', '$injector'];
    private $scope: INewIntegrationRedundanceModalScope;
    private formService: FormService2;
    private awaitingRequestMessageDefault = "Please wait, sending request...";
    private successRequestMessageDefault = "Request made successfully.";
    private awaitingGridSyncMessageDefault = "Please wait, processing synchronization...";
    private successGridSyncMessageDefault = "Sync done successfully.";
    private successGridSyncErrorMessageDefault = "An error occurred during synchronization.";
    private logCopyMessageDefault = "Log message copied to clipboard.";
    private requestErrorDefault = "There was an error sending the request.";

    constructor($scope, $injector: ng.Injectable<any>) {
        this.$scope = $scope;
        this.formService = new FormService2($injector, $scope);
    }

    async $onInit() {
        try {
            this.registerScopeFunctions();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    $onDestroy() { }

    private registerScopeFunctions(): void {
        try {
            this.$scope.copyErrorTextToClipboard = (documentError: IDocumentError) => {
                if (documentError) {
                    const elmAux = document.createElement("textarea");
                    document.body.appendChild(elmAux);
                    elmAux.value = this.parseErrorMessage(documentError);
                    elmAux.select();
                    document.execCommand("copy");
                    document.body.removeChild(elmAux);
                    this.formService.notifySuccess(this.getLogCopyMessageMessage());
                }
            }

            this.$scope.parseErrorMessage = (documentError: IDocumentError) => {
                return this.parseErrorMessage(documentError);
            }

            this.$scope.sendSync = async (subjectId?: string): Promise<void> => {
                let result = null;

                if (!this.$scope.integrationId) return this.formService.notifyError("IntegrationId is null.");
                if (!subjectId) this.$scope.documentErrorList = [{ SUBJECT: null, REASON: null, DATE: new Date() }];
                const documentError: IDocumentError = this.$scope.documentErrorList.find(x => (x.SUBJECT && x.SUBJECT.ID === subjectId) || !x.SUBJECT);

                try {
                    this.formService.block();
                    if (documentError) {
                        // Awaiting message on screen
                        documentError.REASON = this.getAwaitingRequestMessage();
                        documentError.DATE = new Date();

                        // Sync request
                        const result: boolean = await this.$scope.fnSync(this.$scope.integrationId, subjectId);
                        documentError.REASON = result ? this.getSuccessRequestMessage() : this.getRequestErrorMessageMessage();
                        documentError.DATE = new Date();

                        // Update grid request
                        if (this.$scope.fnUpdateIntegrationGrid) {
                            try {
                                documentError.REASON = this.getAwaitingGridSyncMessage();
                                documentError.DATE = new Date();
                                this.$scope.$applyAsync();
                                const result: IDocumentError[] = await this.$scope.fnUpdateIntegrationGrid(this.$scope.gridRowId ? this.$scope.gridRowId : this.$scope.integrationId);
                                if (result && result.length) this.$scope.documentErrorList = result;
                                else documentError.REASON = this.getSuccessGridSyncMessage();
                            } catch (ex) {
                                documentError.REASON = this.getGridSyncErrorMessage();
                                this.formService.handleError(ex);
                            }
                        }
                    }
                } catch (ex) {
                    this.formService.handleError(this.getRequestErrorMessageMessage());
                } finally {
                    this.formService.unblock();
                    return result;
                }
            }
        } catch (ex) {
            throw ex;
        }
    }

    private parseErrorMessage(documentError: IDocumentError): string {
        let returnString = ``;
        if (documentError && documentError.DATE && documentError.REASON) {
            returnString = (moment(documentError.DATE).format("(DD/MM) HH:mm:ss"));
            returnString += (' - ');
            returnString += documentError.REASON;
        }
        return returnString;
    }

    private getAwaitingRequestMessage(): string {
        return this.$scope.messageDefault && this.$scope.messageDefault.awaitingRequest ? this.$scope.messageDefault.awaitingRequest : this.awaitingRequestMessageDefault;
    }

    private getSuccessRequestMessage(): string {
        return this.$scope.messageDefault && this.$scope.messageDefault.successRequest ? this.$scope.messageDefault.successRequest : this.successRequestMessageDefault;
    }

    private getAwaitingGridSyncMessage(): string {
        return this.$scope.messageDefault && this.$scope.messageDefault.awaitingGridSync ? this.$scope.messageDefault.awaitingGridSync : this.awaitingGridSyncMessageDefault;
    }

    private getSuccessGridSyncMessage(): string {
        return this.$scope.messageDefault && this.$scope.messageDefault.successGridSync ? this.$scope.messageDefault.successGridSync : this.successGridSyncMessageDefault;
    }

    private getLogCopyMessageMessage(): string {
        return this.$scope.messageDefault && this.$scope.messageDefault.logCopyMessage ? this.$scope.messageDefault.logCopyMessage : this.logCopyMessageDefault;
    }

    private getGridSyncErrorMessage(): string {
        return this.$scope.messageDefault && this.$scope.messageDefault.gridSyncError ? this.$scope.messageDefault.gridSyncError : this.successGridSyncErrorMessageDefault;
    }

    private getRequestErrorMessageMessage(): string {
        return this.$scope.messageDefault && this.$scope.messageDefault.requestError ? this.$scope.messageDefault.requestError : this.requestErrorDefault;
    }
}

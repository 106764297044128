import * as angular from 'angular';

const operationalState = {
    name: 'app.operational',
    url: '/operational',
    abstract: true,
    data: {
        //Solução de carregar os termos por módulo
        i18n: ['general', 'registration', 'route', 'product', 'financial', 'basic_data', 'entity', 'operational'],

        requiresAuth: true,
        permissions: {
            except: 'EXTERNAL',
            redirectTo: 'app.denied'
        }
    }
}

const processState = {
    name: 'app.operational.process',
    url: '/process',
    abstract: true,
    data: {
        requiresAuth: true
    }
}

const operationalMenu = {
    name: 'app.operational.menu',
    url: '/menu',
    views: {
        'content@app': {
            template: require('./view/operationalMenu.html'),
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.OPERATIONAL',
        parent: 'app.operational.process'
    }
}

const AWBStockState = {
    name: 'app.operational.awbstock',
    url: '/awbstock',
    views: {
        'content@app': {
            template: require("./view/AWBStock.html")
        },
    },
    ncyBreadcrumb: {
        label: 'Estoque AWB',
        parent: 'app.operational.menu'
    }
}

const processListState = {
    name: 'app.operational.process.list',
    url: '/list',
    views: {
        'content@app': {
            template: require("./view/processList.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.PROCESS',
        parent: 'app.operational.menu'
    }
}

const newProcessState = {
    name: 'app.operational.newProcess',
    url: '/newProcess',
    abstract: true,
    data: {
        requiresAuth: true
    }
}

const newProcessListState = {
    name: 'app.operational.newProcess.list',
    url: '/list',
    views: {
        'content@app': {
            template: require("./view/NewProcess.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.PROCESS',
        parent: 'app.operational.menu'
    }
}

const processViewState = {
    name: 'app.operational.process.view',
    url: '/view/:process?',
    views: {
        'content@app': {
            template: require("./view/processView.html")
        },
    },
    params: {
        operation: "view",
        process: undefined,
        hash: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.PROCESS_VIEW',
        parent: 'app.operational.menu'
    }
}

const processEditState = {
    name: 'app.operational.process.edit',
    url: '/edit/:process?',
    views: {
        'content@app': {
            template: require("./view/processView.html")
        },
    },
    params: {
        operation: "edit",
        process: undefined,
        hash: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.PROCESS_EDIT',
        parent: 'app.operational.menu'
    }
}

const processCompareState = {
    name: 'app.operational.process.compare',
    url: '/compare',
    views: {
        'content@app': {
            template: require("./view/processComparison.html")
        },
    },
    params: {
        quotation: undefined,
        process: undefined,
        origin: undefined,
        offerCode: undefined,
        loadRef: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.PROCESS_COMPARE',
        parent: 'app.operational.newProcess.list'
    }
}

const processDocumentKnowledgeState = {
    name: 'app.operational.process.processDocument',
    url: '/processDocument',
    views: {
        'content@app': {
            template: require("./view/newProcessDocumentRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    lazyLoad: ($transition$) => {
        const $ocLazyLoad = $transition$.injector().get("$ocLazyLoad");
        return import('../operational/OperationalModule')
            .then(module => {
                $ocLazyLoad.load(module.OPERATIONAL_MODULE)
            })
            .catch(err => {
                throw new Error("Failed to load OPERATIONAL Module: " + err);
            });
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.PROCESS_KNOWLEDGE',
        parent: 'app.operational.menu'
    }
}

const processViewStateWizard = {
    name: 'app.operational.process.wizard',
    url: '/:source/:operation/:process',
    views: {
        'content@app': {
            template: require("./view/processView.html")
        },
    },
    params: {
        operation: undefined,
        process: undefined,
        source: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.PROCESS_VIEW',
        parent: 'app.operational.menu'
    }
}

const voyagesAndStopoverState = {
    name: 'app.operational.voyagesAndDeadlines',
    url: '/voyagesAndDeadlines',
    views: {
        'content@app': {
            template: require("./view/voyagesAndDeadlinesRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.VOYAGE_STOPOVER',
        parent: 'app.operational.menu'
    }
}

const processDraftState = {
    name: 'app.operational.draft',
    url: '/draft',
    views: {
        'content@app': {
            template: require("./view/oldDraftForm.html")
        },
    },
    params: {
        quotation: undefined,
        process: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.DRAFT_RECEPTION',
        parent: 'app.operational.menu'
    }
}

const draftRegisterState = {
    name: 'app.operational.draftRegister',
    url: '/draftRegister',
    views: {
        'content@app': {
            template: require("./view/draftRegister.html")
        },
    },
    params: {
        quotation: undefined,
        process: undefined
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.DRAFT_RECEPTION',
        parent: 'app.operational.menu'
    }
}

const approvedOfferState = {
    name: 'app.operational.offer',
    url: '/offer',
    abstract: true,
    data: {
        requiresAuth: true
    }
}

const offerListState = {
    name: 'app.operational.approvedOffer',
    url: '/approvedOffer',
    views: {
        'content@app': {
            template: require("./view/offerList.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.APPROVED_OFFER',
        parent: 'app.operational.menu'
    }
}

const bookingListState = {
    name: 'app.operational.booking',
    url: '/bookingList',
    views: {
        'content@app': {
            template: require("./view/bookingList.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.BOOKING',
        parent: 'app.operational.menu'
    }
}

const airFlightListState = {
    name: 'app.operational.airFlight',
    url: '/airFlightList',
    views: {
        'content@app': {
            template: require("./view/airFlightList.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.AIR_FLIGHT',
        parent: 'app.operational.menu'
    }
}

const detDemManagementState = {
    name: 'app.operational.process.detDemManagement',
    url: '/detDemManagement',
    views: {
        'content@app': {
            template: require("./view/NewProcessDetDemManagement.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.DETENTION_DEMURRAGE_MANAGEMENT',
        parent: 'app.operational.menu'
    }
}

const voyagesState = {
    name: 'app.operational.voyages',
    url: '/voyages',
    views: {
        'content@app': {
            template: require("./view/voyageRegister.html")
        },
    },
    params: {
        operation: 'register',
        data: undefined,
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.VOYAGES',
        parent: 'app.operational.menu'
    }
}

const detDemState = {
    name: 'app.operational.process.detDemProcess',
    url: '/detDemProcess',
    views: {
        'content@app': {
            template: require("./view/NewProcessDetDem.html")
        },
    },
    ncyBreadcrumb: {
        label: 'Processo D&D',
        parent: 'app.operational.menu'
    }
}

const consolidatedState = {
    name: 'app.operational.consolidated',
    url: '/consolidated',
    views: {
        'content@app': {
            template: require("./view/ConsolidatedRegister.html")
        },
    },
    ncyBreadcrumb: {
        label: 'GENERAL.MENU.CONSOLIDATED',
        parent: 'app.operational.menu'
    }
}

routeConfig.$inject = ['$uiRouterProvider'];
function routeConfig($uiRouterProvider) {
    const $stateRegistry = $uiRouterProvider.stateRegistry;
    $stateRegistry.register(operationalState);
    $stateRegistry.register(operationalMenu);
    $stateRegistry.register(processState);
    $stateRegistry.register(processListState);
    $stateRegistry.register(newProcessState);
    $stateRegistry.register(newProcessListState);
    $stateRegistry.register(processViewState);
    $stateRegistry.register(processEditState);
    $stateRegistry.register(processCompareState);
    $stateRegistry.register(processViewStateWizard);
    $stateRegistry.register(processDraftState);
    $stateRegistry.register(draftRegisterState);
    $stateRegistry.register(voyagesAndStopoverState);
    $stateRegistry.register(offerListState);
    $stateRegistry.register(bookingListState);
    $stateRegistry.register(airFlightListState);
    $stateRegistry.register(AWBStockState);
    $stateRegistry.register(detDemManagementState);
    $stateRegistry.register(processDocumentKnowledgeState);
    $stateRegistry.register(voyagesState);
    $stateRegistry.register(detDemState);
    $stateRegistry.register(consolidatedState);
}

export default function initRoute(moduleName: string): void {
    angular.module(moduleName).config(routeConfig);
}
import * as angular from 'angular';
import * as moment from 'moment';
import config from '../bootstrap/Config';
import { IRestService } from './RestService';
import { INotificationService } from './NotificationService';
import { IMonacoScope } from '../common/MonacoInterface';
import { HandleError } from '../common/util/HandleError';
import { ISessionService } from '@services/SessionService';
import { SelectorModel } from "../common/model/SelectorModel";
import { ArrayUtil } from '../common/util/ArrayUtil';
import { BrowserTitle } from '../common/BrowserTitle';
import { isArray } from 'util';
import { EOperation } from '@enums/GenericData';

export interface IFormServiceScope extends ng.IScope {
    operation: string;
    formOperation: string;
    showForm: boolean;
    newRegister: any;
    error: string;
    loadError: boolean;
    loadErrorMsg: string;
    dateFormat: string;
    dateTimeFormat: string;
    dateOptions: {
        formatYear: string
        maxDate: Date
        minDate: Date
        startingDay: number
    };
    steps: {
        percent: number
        current: number
    };
    buttonsTemplate: string;
    isCopyable: boolean;
    isDeletable: boolean;
    yesAndNoOptions: Array<SelectorModel>;
    getYesNoSelector: Array<SelectorModel>;
    emptySelectorMsg: string;
    formService: FormService2;
    $sce: angular.ISCEService;
    additionalIndexSelectorValidity: string[];
    selectorValidity: (elementName: string | string[]) => void;
    setSelectorStatus: (elementName: string, valid: boolean) => void;
    setDirtyFields: (fields: string[]) => void;
    selectorFocus: (element: any) => void;
    selectorDisabled: (elementName: string) => void;
    disableElements: (disable?: boolean, exceptNames?: string[], specifiedNames?: string[] | RegExp) => void;
    hasRequiredElements: (context: string) => boolean;
    selectorSearchBy: (query: string, type: string, variable: string) => any;
    selectorProcessPeople: (query: string, type: string, variable: string) => any;
    navigateBetweenIds: (to: string) => boolean;
    getEmptySelectorMsg: () => string;
    convertBooleanToSelector: (value: boolean) => SelectorModel;
    convertBooleanToString: (value: boolean) => string;
}

export class FormService2 {
    //public static $inject: string[] = ['$rootScope', '$window', '$timeout', '$state', 'blockUI', '$anchorScroll', 'SessionService', 'ModalService', 'RestService', 'NotificationService'];
    private $scope: IFormServiceScope;
    private $rootScope: IMonacoScope;
    private $window: ng.IWindowService;
    private $timeout: ng.ITimeoutService;
    private $state: ng.ui.IStateService;
    private blockUI: ng.blockUI.BlockUIService;
    private $anchorScroll: ng.IAnchorScrollService;
    private SessionService: ISessionService;
    private RestService: IRestService;
    private NotificationService: INotificationService;
    private $filter;
    private formName: string;

    constructor($injector: ng.Injectable<any>, $scope: IFormServiceScope) {
        this.$scope = $scope;
        this.$rootScope = $injector.get('$rootScope');
        this.$window = $injector.get('$window');
        this.$timeout = $injector.get('$timeout');
        this.$state = $injector.get('$state');
        this.blockUI = $injector.get('blockUI');
        this.$anchorScroll = $injector.get('$anchorScroll');
        this.SessionService = $injector.get('SessionService');
        this.RestService = $injector.get('RestService');
        this.NotificationService = $injector.get('NotificationService');
        this.$filter = $injector.get('$filter');


        this.$scope.getYesNoSelector = [{
            ID: '1',
            NAME: 'Sim',
            CODE: "true",
        }, {
            ID: '2',
            NAME: 'Não',
            CODE: "false",
        }];
        this.$scope.yesAndNoOptions = [{
            ID: "1",
            NAME: "Sim",
            CODE: "true"
        }, {
            ID: "2",
            NAME: "Não",
            CODE: "false"
        }];
        this.$scope.emptySelectorMsg = 'Nenhum valor encontrado';
    }


    public async init(formName: string, menuName: string, breadCrumb: boolean): Promise<void> {

        //init variables
        this.formName = formName;

        //init breadCrumb
        if (breadCrumb) this.$rootScope.setBreadCrumb(menuName, this.$scope['register']);

        //set date format options
        this.$scope.dateFormat = 'dd/MM/yyyy';
        this.$scope.dateTimeFormat = 'dd/MM/yyyy HH:mm:ss';
        this.$scope.dateOptions = {
            formatYear: 'yy',
            maxDate: new Date(2030, 1, 1),
            minDate: new Date(1970, 1, 1),
            startingDay: 1
        };
        //set steps for wizards
        this.$scope.steps = {
            percent: 0,
            current: 0
        };
        //set buttons template
        this.$scope.buttonsTemplate = 'buttonsTemplate';

        //init form layout
        //const self: FormService2 = this;
        /* this.$timeout(function () {
            // gotop page
            self.$anchorScroll();
            angular.element(document).ready(function () {
                $(window).scroll(function () {
                    if ($(this).scrollTop() > 50) {
                        $('#back-to-top').fadeIn();
                    } else {
                        $('#back-to-top').fadeOut();
                    }
                });
            });
            //unblock the init block
            self.unblock();
        });*/

        //register scope functions
        this.$scope.selectorValidity = (elementName: string | string[]): void => {
            return (Array.isArray(elementName)) ? elementName.forEach(x => this.selectorValidity(x)) : this.selectorValidity(elementName);
        }
        this.$scope.setSelectorStatus = (elementName: string, valid: boolean): void => {
            return this.setSelectorStatus(elementName, valid);
        }
        this.$scope.setDirtyFields = (fields: string[]): void => {
            this.setDirtyFields(fields);
        }
        this.$scope.selectorFocus = (element: any): void => {
            return this.selectorFocus(element);
        }
        this.$scope.selectorDisabled = (name: string): void => {
            return this.selectorDisabled(name);
        }
        this.$scope.disableElements = (disable?: boolean, exceptNames?: string[], specifiedNames?: string[] | RegExp): void => {
            return this.disableElements(disable, exceptNames, specifiedNames);
        }
        this.$scope.selectorSearchBy = (query: string, type: string, variable: string): any => {
            return this.selectorSearchBy(query, type, variable);
        }
        this.$scope.selectorProcessPeople = (query: string, type: string, variable: string): any => {
            return this.selectorProcessPeople(query, type, variable);
        }
        this.$scope.navigateBetweenIds = (to: string): boolean => {
            return this.navigateBetweenIds(to);
        }
        this.$scope.getEmptySelectorMsg = (): string => {
            return this.$scope.emptySelectorMsg;
        }
        this.$scope.convertBooleanToSelector = (value: boolean): SelectorModel => {
            return this.convertBooleanToSelector(value);
        }
        this.$scope.convertBooleanToString = (value: boolean): string => {
            return this.convertBooleanToString(value);
        }
    }

    $onDestroy() {
        BrowserTitle.$id = null;
    }

    protected selectorSearchBy(query, type, variable) {
        if (query.length >= 3) {
            this.block();
            const self = this;
            this.searchPeopleBySpecs(type, query).then(result => {
                this.$scope[variable] = result.data;
                return self.unblock();
            }).catch(ex => {
                console.log(ex);
                self.handleError(ex);
            });
        };
    };

    private selectorProcessPeople(query, type, variable) {
        if (query.length >= 3) {
            this.block();
            const self = this;
            this.searchPeopleDetails(type, query).then(result => {
                this.$scope[variable] = result.data;
                return self.unblock();
            }).catch(ex => {
                console.log(ex);
                self.handleError(ex);
            });
        };
    };

    private searchPeopleBySpecs(spec, query) {
        const route = (query) ? `/operation/peopleBySpec/${spec}/${query}` : `/operation/peopleBySpec/${spec}`;
        return this.RestService.getObjectAsPromise(route, 10000);
    };

    private searchPeopleDetails(spec, query) {
        const route = (query) ? `/operation/peopledetail/${spec}/${query}` : `/operation/peopledetail/${spec}`;
        return this.RestService.getObjectAsPromise(route, 10000);
    };

    public initStandAlone(formName: string): void {
        //init variables
        this.formName = formName;

        //set date format options
        this.$scope.dateFormat = 'dd/MM/yyyy';
        this.$scope.dateTimeFormat = 'dd/MM/yyyy HH:mm:ss';
        this.$scope.dateOptions = {
            formatYear: 'yy',
            maxDate: new Date(2030, 1, 1),
            minDate: new Date(1970, 1, 1),
            startingDay: 1
        };
        //set steps for wizards
        this.$scope.steps = {
            percent: 0,
            current: 0
        };
        //set buttons template
        this.$scope.buttonsTemplate = 'buttonsTemplate';

        //register scope functions
        //const self: FormService2 = this;
        this.$scope.selectorValidity = (elementName: string | string[]): void => {
            return (Array.isArray(elementName)) ? elementName.forEach(x => this.selectorValidity(x)) : this.selectorValidity(elementName);
        }
        this.$scope.selectorFocus = (element: any): void => {
            return this.selectorFocus(element);
        }
        this.$scope.selectorDisabled = (name: string): void => {
            return this.selectorDisabled(name);
        }
        this.$scope.disableElements = (disable?: boolean, exceptNames?: string[], specifiedNames?: string[] | RegExp): void => {
            return this.disableElements(disable, exceptNames, specifiedNames);
        }
    }

    public hideAddButton(): void {
        this.$rootScope.breadCrumb.show = false;
    }

    public notifyError(msg: string, callback?: Function, options?: any): void {
        this.NotificationService.error(msg, callback, options);
    }

    public notifySuccess(msg: string, callback?: Function, options?: any): void {
        this.NotificationService.success(msg, callback, options);
    }

    public notifyInfo(msg: string, callback?: Function, options?: any): void {
        this.NotificationService.info(msg, callback, options);
    }

    public notifyWarning(msg: string, callback?: Function, options?: any): void {
        this.NotificationService.warning(msg, callback, options);
    }


    public notifyElementError(element: string, msg: string, callback?: Function, options?: any): void {
        this.NotificationService.element(element).error(msg, callback, options);
    }

    public notifyElementSuccess(element: string, msg: string, callback?: Function, options?: any): void {
        this.NotificationService.element(element).success(msg, callback, options);
    }

    public notifyElementInfo(element: string, msg: string, callback?: Function, options?: any): void {
        this.NotificationService.element(element).info(msg, callback, options);
    }

    public notifyElementWarning(element: string, msg: string, callback?: Function, options?: any): void {
        this.NotificationService.element(element).warning(msg, callback, options);
    }

    public numberFormatter(number, digits) { //legacy from FormSevice1.. why do we use this if angular has built-in filters?
        if (!digits) digits = 0;
        return Intl.NumberFormat('pt-BR', { minimumFractionDigits: digits }).format(number);
    }

    public block(): void {
        this.blockUI.start();
    };

    public resetBlock(): void {
        this.blockUI.reset();
    };

    public unblock(): void {
        const self: FormService2 = this;
        this.$timeout(function () {
            self.blockUI.stop();
            //$scope.$apply();
        });
    };

    public navigateBetweenIds(to: string): boolean {
        if (!to) return false;
        this.$timeout(function () {
            const element = $("#" + to);
            if (element.length == 0) return;
            const position = $("#" + to).offset().top + $('.app-content-body').scrollTop() - 105;
            $('.app-content-body').animate({
                scrollTop: position
            }, 500);
            return true;
        });
        return false;
    };

    public handleError(error: any, errorCallback?: Function, options?: object): void {
        this.unblock();
        HandleError.exception(error, errorCallback, options);
    };

    public handleWarning(message: any, warningCallback?: Function, options?: any): void {
        this.unblock();
        HandleError.warning(message, warningCallback, options);
    };


    private interceptErrorServer(errorResponse) {
        let errorServer = false;

        if (errorResponse == null) { //tem response
            errorServer = true;
        } else if (!errorResponse.data) { //tem data no response
            errorServer = true;
        } else if (!errorResponse.data.data) { //tem data no data (verifica se é objeto notifyErrorModel do Back)
            errorServer = true;
        } else if (!errorResponse.data.data.data) {
            if (!errorResponse.data.data.msg)
                errorServer = true;
        }

        return errorServer;
    }

    public async handleLoadError(error: any, message?: string): Promise<void> {
        this.$scope.loadError = true;
        //HandleError.exception(error, function () { this.$state.reload(); }, { autoHide: false, globalPosition: 'top center' });
        this.resetBlock();
        await this.SessionService.redirectToError(error, message);
    };

    //TODO: Rearrenged michael menu
    public initMichaelMenu() {
        /*const self: FormService2 = this;
        // register window resize event
        $(window).resize(function () {
            self.resizeMichaelMenu();
        });
        //init the first time
        this.resizeMichaelMenu();*/
    }

    public checkDateFieldConsistency(element: string, element_limit: string) {
        const self: FormService2 = this;
        const initialDate = self.$scope[self.formName][element].$modelValue;
        const finalDate = self.$scope[self.formName][element_limit].$modelValue;
        const model = self.$scope[self.formName][element_limit].$$attr.ngModel;

        let minDate = new Date(initialDate);
        minDate.setDate(minDate.getDate() + 1);
        self.$scope['dateOptions_' + element_limit] = Object.assign({}, (self.$scope['dateOptions_' + element]) ? (self.$scope['dateOptions_' + element]) : (self.$scope['dateOptions']));
        self.$scope['dateOptions_' + element_limit] = {
            minDate: minDate
        };

        if (Object.prototype.toString.call(initialDate) === "[object Date]" &&
            Object.prototype.toString.call(finalDate) === "[object Date]") {
            if (moment(finalDate).isBefore(initialDate)) {
                self.$scope[model.split('.')[0]][model.split('.')[1]] = null;
            }
        }
    }

    public setCopyable(visible: boolean) {
        this.$scope.isCopyable = visible;
    }
    public setDeletable(visible: boolean) {
        this.$scope.isDeletable = visible;
    }
    public getEmptySelectorMsg(): string {
        return this.$scope.emptySelectorMsg;
    }

    private resizeMichaelMenu() {
        const self: FormService2 = this;
        this.$timeout(function () {
            let differenceLength = 0;
            // check actual size
            if (!self.$rootScope.app.settings.asideFolded) {
                // div align
                differenceLength = 100;
            }
            $('#nav').animate({
                'left': ((($(document).width() - $('#nav').width()) / 2) + differenceLength) + "px"
            }, {
                duration: 500,
                queue: false
            });
        }, 500);
    }

    private setSelectorsPlaceholder(msg: string): void {
        $('.ui-select-search').prop('placeholder', msg);
    };

    private validatePositiveNumber(number: number): boolean {
        if (number != undefined && number != null && !isNaN(number) && number >= 0) return true;
        else return false;
    };

    private setComboBoxValidity(element, index): void {
        if (!element) return;
        let elementName = element;
        if (index !== undefined && index !== null && index >= 0) {
            elementName = (element + index);
        }
        const self: FormService2 = this;
        this.$timeout(function () {
            if (self.$scope[self.formName][elementName].$valid) self.$scope[self.formName][elementName].$setValidity('$valid', true);
            else self.$scope[self.formName][elementName].$setValidity('$valid', false);
        });
    };

    private selectorDisabled(name: string) {
        if (!this.$scope[name]) this.$scope[name] = false;
        return this.$scope[name];
    };

    private disableElements(_disable?: boolean, _exceptNames?: string[], specifiedNames?: string[] | RegExp) {
        const self: FormService2 = this;
        const disable = (typeof _disable !== 'undefined') ? _disable : true; // disable/enable
        const exceptNames = (_exceptNames && _exceptNames.length > 0) ? _exceptNames : [];
        const regex = (specifiedNames) ? (specifiedNames instanceof RegExp) ? specifiedNames : null : new RegExp('.*');
        const includedNames: string[] = (Array.isArray(specifiedNames)) ? specifiedNames : null;
        this.$timeout(function () {
            angular.forEach(self.$scope[self.formName], function (element, name: string) {
                if ((name[0] !== '$') &&
                    (exceptNames.indexOf(name) == -1) &&
                    ((includedNames && includedNames.indexOf(name) > -1) || (regex && regex.test(name))) &&
                    (angular.isDefined(element.$$attr.$attr.multiple) ||
                        angular.isDefined(element.$$attr.class) && element.$$attr.class.startsWith('ui-select-container') ||
                        element.$$attr.type === "text" ||
                        element.$$attr.$$element[0].type === "textarea")
                ) {
                    self.$scope[name] = disable;

                    if (element.$$attr.type === "text")
                        angular.element('input[name=' + name + ']').prop("disabled", disable);

                    if (element.$$attr.$$element[0].type === "textarea")
                        angular.element('textarea[name=' + name + ']').prop("disabled", disable);
                }
            });
        });
    };

    private selectorFocus(element: any): void {
        if (this.$scope.operation === EOperation.VIEW || !(element instanceof HTMLInputElement)) return;

        const self: FormService2 = this;
        this.$timeout(function () {
            // set focus on specified element
            if (element) element.focus();
        });
    };

    private selectorValidity(elementName: string): void {
        if (!elementName || !this.$scope[this.formName] || !this.$scope[this.formName][elementName]) return;
        const stringIndex = '[$index]';

        const self: FormService2 = this;
        const additionalIndex = self.$scope.additionalIndexSelectorValidity ? self.$scope.additionalIndexSelectorValidity : null;

        if (!self.$scope[self.formName] || !self.$scope[self.formName][elementName]) return;

        const isSelector = (angular.isDefined(self.$scope[self.formName][elementName].$$attr.class) && self.$scope[self.formName][elementName].$$attr.class.startsWith('ui-select-container'));
        const isSelectorMultiple = angular.isDefined(self.$scope[self.formName][elementName].$$attr.multiple);
        if (!isSelector) return;
        let model = null;
        let index = -1;
        index = parseInt(elementName.charAt(elementName.length - 1));
        if (isNaN(index)) model = self.$scope[self.formName][elementName].$$attr.ngModel;
        else {
            model = self.$scope[self.formName][elementName].$$attr.ngModel.replace(stringIndex, '');

            if (additionalIndex) {
                for (const additionalIndexString of additionalIndex) {
                    model = model.replace(`[${additionalIndexString}]`, '');
                }
            }
        }

        const modelValue = self.$scope[self.formName][elementName].$modelValue;

        //TODO: IMPROVE FORMAT FOR MORE DATA THAN JUST ID, NAME

        if (modelValue && typeof (modelValue) === 'object') {
            //return if is selector multiple and it is empty
            if (modelValue instanceof Array && modelValue.length === 0) {
                //empty multiple selector, set model null
                return self.formatSelector(model, null, true, index);
            }

            //format selector data if different of {ID, NAME}
            if (isSelectorMultiple && (!modelValue[modelValue.length - 1].ID || !modelValue[modelValue.length - 1].NAME)) {
                let keys = [];
                //get the properties of the the last added item
                Object.keys(modelValue[modelValue.length - 1]).forEach(function (key) {
                    //if (key === 'ID' || key === 'NAME') return;
                    keys.push(key);
                });
                //make sure we have at least 2 attributes to format and set ID, NAME
                if (keys.length >= 2) self.formatSelector(model, keys, true, index);

            } else if (!isSelectorMultiple && (!modelValue.ID || !modelValue.NAME)) {
                let keys = [];
                Object.keys(modelValue).forEach(function (key) {
                    //if (key === 'ID' || key === 'NAME') return;
                    keys.push(key);
                });
                //make sure we have at least 2 attributes to format and set ID, NAME
                if (keys.length >= 2) self.formatSelector(model, keys, false, index);
            }
        }
        //set css validity just for single selector
        if (!isSelectorMultiple) {
            const elementMatch = $("[name='" + elementName + "']").find('div.ui-select-match');
            if (self.$scope[self.formName][elementName].$valid) {
                // span tag to set class validity
                elementMatch.children('span')
                    .removeClass("btn")// Making the function asynchronous to avoid data difference
                    .removeClass("btn-default")
                    .removeClass("ng-invalid")
                    .addClass('ng-valid ng-dirty');

            } else {
                elementMatch.children('span')
                    .removeClass("btn")
                    .removeClass("btn-default").removeClass("ng-valid")
                    .addClass('ng-invalid ng-dirty');
            }
        }
    }

    private setSelectorStatus(elementName: string, valid: boolean): void {
        if (!elementName || !this.$scope[this.formName] || !this.$scope[this.formName][elementName]) return;
        const self: FormService2 = this;
        this.$timeout(function () {
            if (!self.$scope[self.formName] || !self.$scope[self.formName][elementName]) return;
            const isSelector = (angular.isDefined(self.$scope[self.formName][elementName].$$attr.class) && self.$scope[self.formName][elementName].$$attr.class.startsWith('ui-select-container'));
            const isSelectorMultiple = angular.isDefined(self.$scope[self.formName][elementName].$$attr.multiple);
            if (!isSelector) return;
            if (valid) {
                self.$scope[self.formName][elementName].$invalid = false;
                self.$scope[self.formName][elementName].$valid = true;
            } else {
                self.$scope[self.formName][elementName].$invalid = true;
                self.$scope[self.formName][elementName].$valid = false;
            }
            if (!isSelectorMultiple) {
                const elementMatch = $("[name='" + elementName + "']").find('div.ui-select-match');
                if (self.$scope[self.formName][elementName].$valid) {
                    // span tag to set class validity
                    elementMatch.children('span')
                        .removeClass("btn")
                        .removeClass("btn-default")
                        .removeClass("ng-invalid")
                        .addClass('ng-valid ng-dirty');

                } else {
                    elementMatch.children('span')
                        .removeClass("btn")
                        .removeClass("btn-default").removeClass("ng-valid")
                        .addClass('ng-invalid ng-dirty');
                }
            }
        });
        this.selectorValidity(elementName);
    }

    private setDirtyFields(fields: string[]): void {
        if (!fields) return;
        const self: FormService2 = this;
        for (const field of fields) {
            self.$scope[self.formName][field].$setDirty();
        }
    }

    private formatSelector(model: string, keys: Array<string>, isMultiple: boolean, index: number): void {
        if (!model) return;

        let modelStructure = model.split('.');
        //console.log('modelStructure: ', modelStructure);
        switch (modelStructure.length) {
            case 0:
                if (this.validatePositiveNumber(index)) {
                    if (this.$scope[model][index]) {
                        if (isMultiple) {
                            const lastIndex = this.$scope[model][index].length - 1;
                            if (lastIndex === -1) {
                                //multiple selector is empty, set model null
                                this.$scope[model][index] = null;
                                return;
                            }
                            if (!keys || keys.length == 0) return;
                            const id = this.$scope[model][index][lastIndex][keys[0]];
                            this.$scope[model][index][lastIndex]['ID'] = id.toString();
                            this.$scope[model][index][lastIndex]['NAME'] = this.$scope[model][index][lastIndex][keys[1]].trim();

                        } else {
                            if (!keys || keys.length == 0) return;
                            const id = this.$scope[model][index][keys[0]];
                            this.$scope[model][index]['ID'] = id.toString();
                            this.$scope[model][index]['NAME'] = this.$scope[model][index][keys[1]].trim();
                        }
                    }
                } else { //no index (not a table or array of array)
                    if (this.$scope[model]) {
                        if (isMultiple) {
                            const lastIndex = this.$scope[model].length - 1;
                            if (lastIndex === -1) {
                                //multiple selector is empty, set model null
                                this.$scope[model] = null;
                                return;
                            }
                            if (!keys || keys.length == 0) return;
                            const id = this.$scope[model][lastIndex][keys[0]];
                            this.$scope[model][lastIndex]['ID'] = id.toString();
                            this.$scope[model][lastIndex]['NAME'] = this.$scope[model][lastIndex][keys[1]].trim();
                        } else {
                            if (!keys || keys.length == 0) return;
                            const id = this.$scope[model][keys[0]];
                            this.$scope[model]['ID'] = id.toString();
                            this.$scope[model]['NAME'] = this.$scope[model][keys[1]].trim();
                        }
                    }
                }
                break;
            case 1:
                if (this.validatePositiveNumber(index)) {
                    if (this.$scope[modelStructure[0]][index]) {
                        if (isMultiple) {
                            const lastIndex = this.$scope[modelStructure[0]][index].length - 1;
                            if (lastIndex === -1) {
                                //multiple selector is empty, set model null
                                this.$scope[modelStructure[0]][index] = null;
                                return;
                            }
                            if (!keys || keys.length == 0) return;
                            const id = this.$scope[modelStructure[0]][index][lastIndex][keys[0]];
                            this.$scope[modelStructure[0]][index][lastIndex]['ID'] = id;
                            this.$scope[modelStructure[0]][index][lastIndex]['NAME'] = this.$scope[modelStructure[0]][index][lastIndex][keys[1]].trim();
                        } else {
                            if (!keys || keys.length == 0) return;
                            const id = this.$scope[modelStructure[0]][index][keys[0]];
                            this.$scope[modelStructure[0]][index]['ID'] = id;
                            this.$scope[modelStructure[0]][index]['NAME'] = this.$scope[modelStructure[0]][index][keys[1]].trim();
                        }
                    }
                } else { //no index (not a table or array of array)
                    if (this.$scope[modelStructure[0]]) {
                        if (isMultiple) {
                            const lastIndex = this.$scope[modelStructure[0]].length - 1;
                            if (lastIndex === -1) {
                                //multiple selector is empty, set model null
                                this.$scope[modelStructure[0]] = null;
                                return;
                            }
                            if (!keys || keys.length == 0) return;
                            const id = this.$scope[modelStructure[0]][lastIndex][keys[0]];
                            this.$scope[modelStructure[0]][lastIndex]['ID'] = id;
                            this.$scope[modelStructure[0]][lastIndex]['NAME'] = this.$scope[modelStructure[0]][lastIndex][keys[1]].trim();
                        } else {
                            if (!keys || keys.length == 0) return;
                            const id = this.$scope[modelStructure[0]][keys[0]];
                            this.$scope[modelStructure[0]]['ID'] = id;
                            this.$scope[modelStructure[0]]['NAME'] = this.$scope[modelStructure[0]][keys[1]].trim();
                        }
                    }
                }
                break;
            case 2:
                if (this.validatePositiveNumber(index)) {
                    if (this.$scope[modelStructure[0]][index][modelStructure[1]]) {
                        if (isMultiple) {
                            const lastIndex = this.$scope[modelStructure[0]][index][modelStructure[1]].length - 1;
                            if (lastIndex === -1) {
                                //multiple selector is empty, set model null
                                this.$scope[modelStructure[0]][index][modelStructure[1]] = null;
                                return;
                            }
                            if (!keys || keys.length == 0) return;
                            const id = this.$scope[modelStructure[0]][index][modelStructure[1]][lastIndex][keys[0]];
                            this.$scope[modelStructure[0]][index][modelStructure[1]][lastIndex]['ID'] = id;
                            this.$scope[modelStructure[0]][index][modelStructure[1]][lastIndex]['NAME'] = this.$scope[modelStructure[0]][index][modelStructure[1]][lastIndex][keys[1]].trim();
                        } else {
                            if (!keys || keys.length == 0) return;
                            const id = this.$scope[modelStructure[0]][index][modelStructure[1]][keys[0]];
                            this.$scope[modelStructure[0]][index][modelStructure[1]]['ID'] = id;
                            this.$scope[modelStructure[0]][index][modelStructure[1]]['NAME'] = this.$scope[modelStructure[0]][index][modelStructure[1]][keys[1]].trim();
                        }
                    }
                } else { //no index (not a table or array of array)
                    if (this.$scope[modelStructure[0]][modelStructure[1]]) {
                        if (isMultiple) {
                            const lastIndex = this.$scope[modelStructure[0]][modelStructure[1]].length - 1;
                            if (lastIndex === -1) {
                                //multiple selector is empty, set model null
                                this.$scope[modelStructure[0]][modelStructure[1]] = null;
                                return;
                            }
                            if (!keys || keys.length == 0) return;
                            const id = this.$scope[modelStructure[0]][modelStructure[1]][lastIndex][keys[0]];
                            this.$scope[modelStructure[0]][modelStructure[1]][lastIndex]['ID'] = id;
                            this.$scope[modelStructure[0]][modelStructure[1]][lastIndex]['NAME'] = this.$scope[modelStructure[0]][modelStructure[1]][lastIndex][keys[1]].trim();
                        } else {
                            if (!keys || keys.length == 0) return;
                            const id = this.$scope[modelStructure[0]][modelStructure[1]][keys[0]];
                            this.$scope[modelStructure[0]][modelStructure[1]]['ID'] = id;
                            this.$scope[modelStructure[0]][modelStructure[1]]['NAME'] = this.$scope[modelStructure[0]][modelStructure[1]][keys[1]].trim();
                        }
                    }
                }
                break;
            case 3:
                if (this.validatePositiveNumber(index)) {
                    if (this.$scope[modelStructure[0]][modelStructure[1]][index][modelStructure[2]]) {
                        if (isMultiple) {
                            const lastIndex = this.$scope[modelStructure[0]][modelStructure[1]][index][modelStructure[2]].length - 1;
                            if (lastIndex === -1) {
                                //multiple selector is empty, set model null
                                this.$scope[modelStructure[0]][modelStructure[1]][index][modelStructure[2]] = null;
                                return;
                            }
                            if (!keys || keys.length == 0) return;
                            const id = this.$scope[modelStructure[0]][modelStructure[1]][index][modelStructure[2]][lastIndex][keys[0]];
                            this.$scope[modelStructure[0]][modelStructure[1]][index][modelStructure[2]][lastIndex]['ID'] = id;
                            this.$scope[modelStructure[0]][modelStructure[1]][index][modelStructure[2]][lastIndex]['NAME'] = this.$scope[modelStructure[0]][modelStructure[1]][index][modelStructure[2]][lastIndex][keys[1]].trim();
                        } else {
                            if (!keys || keys.length == 0) return;
                            const id = this.$scope[modelStructure[0]][modelStructure[1]][index][modelStructure[2]][keys[0]];
                            this.$scope[modelStructure[0]][modelStructure[1]][index][modelStructure[2]]['ID'] = id;
                            this.$scope[modelStructure[0]][modelStructure[1]][index][modelStructure[2]]['NAME'] = this.$scope[modelStructure[0]][modelStructure[1]][index][modelStructure[2]][keys[1]].trim();
                        }
                    }
                } else { //no index (not a table or array of array)
                    if (this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]]) {
                        if (isMultiple) {
                            const lastIndex = this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]].length - 1;
                            if (lastIndex === -1) {
                                //multiple selector is empty, set model null
                                this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]] = null;
                                return;
                            }
                            if (!keys || keys.length == 0) return;
                            const id = this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][lastIndex][keys[0]];
                            this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][lastIndex]['ID'] = id;
                            this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][lastIndex]['NAME'] = this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][lastIndex][keys[1]].trim();
                        } else {
                            if (!keys || keys.length == 0) return;
                            const id = this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][keys[0]];
                            this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]]['ID'] = id;
                            this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]]['NAME'] = this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][keys[1]].trim();
                        }
                    }
                }
                break;
            case 4:
                if (this.validatePositiveNumber(index)) {
                    if (this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][index][modelStructure[3]]) {
                        if (isMultiple) {
                            const lastIndex = this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][index][modelStructure[3]].length - 1;
                            if (lastIndex === -1) {
                                //multiple selector is empty, set model null
                                this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][index][modelStructure[3]] = null;
                                return;
                            }
                            if (!keys || keys.length == 0) return;
                            const id = this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][index][modelStructure[3]][lastIndex][keys[0]];
                            this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][index][modelStructure[3]][lastIndex]['ID'] = id;
                            this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][index][modelStructure[3]][lastIndex]['NAME'] = this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][index][modelStructure[3]][lastIndex][keys[1]].trim();
                        } else {
                            if (!keys || keys.length == 0) return;
                            const id = this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][index][modelStructure[3]][keys[0]];
                            this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][index][modelStructure[3]]['ID'] = id;
                            this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][index][modelStructure[3]]['NAME'] = this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][index][modelStructure[3]][keys[1]].trim();
                        }
                    }
                } else { //no index (not a table or array of array)
                    if (this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][modelStructure[3]]) {
                        if (isMultiple) {
                            const lastIndex = this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][modelStructure[3]].length - 1;
                            if (lastIndex === -1) {
                                //multiple selector is empty, set model null
                                this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][modelStructure[3]] = null;
                                return;
                            }
                            if (!keys || keys.length == 0) return;
                            const id = this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][lastIndex][keys[0]];
                            this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][modelStructure[3]][lastIndex]['ID'] = id;
                            this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][modelStructure[3]][lastIndex]['NAME'] = this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][lastIndex][keys[1]].trim();
                        } else {
                            if (!keys || keys.length == 0) return;
                            const id = this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][modelStructure[3]][keys[0]];
                            this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][modelStructure[3]]['ID'] = id;
                            this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][modelStructure[3]]['NAME'] = this.$scope[modelStructure[0]][modelStructure[1]][modelStructure[2]][modelStructure[3]][keys[1]].trim();
                        }
                    }
                }
                break;
            default:
                console.log('Profundidade máxima de formatação do objeto do selector é de 4 níveis');
                break;
        }
    };


    /*     private setFormOperation(): void {
            switch (this.$scope.operation) {
                case 'register':
                    this.$scope.formOperation = 'Cadastrar ' + this.menuName;
                    break;
                case 'edit':
                    const id = (this.$scope[this.modelName][this.modelName.toUpperCase() + '_NUMBER']) ? this.$scope[this.modelName][this.modelName.toUpperCase() + '_NUMBER'] : this.$scope[this.modelName]['ID'];
                    this.$scope.formOperation = 'Editar ' + this.menuName + ' (' + id + ')';
                    if (this.modelName == "user") this.$scope.formOperation = 'Editar ' + this.menuName + ' (' + this.$scope[this.modelName].email + ')';
                    break;
                case 'view':
                    this.$scope.formOperation = 'Visualizar ' + this.menuName + ' (' + id + ')';
                    if (this.modelName == "user") this.$scope.formOperation = 'Visualizar ' + this.menuName + ' (' + this.$scope[this.modelName].email + ')';
                    break;
            }
        }; */


    public loadViewForm(): void {
        try {
            this.block();

            this.$scope.showForm = false;
            this.$scope.loadError = null;
            this.$scope.error = null;
            this.$scope.newRegister = null;
            this.$scope.operation = EOperation.VIEW;

            const self: FormService2 = this;
            // TODO: Remove timeout for synchronous execution to avoid data difference
            //this.$timeout(function () {
            angular.forEach(self.$scope[self.formName], function (element: any, name: string) {
                if (name[0] !== '$') {
                    const isSelector = (angular.isDefined(element.$$attr.class) && element.$$attr.class.startsWith('ui-select-container'));
                    const isSelectorMultiple = angular.isDefined(element.$$attr.multiple);
                    const isRequired = (element.$$success.required || element.$error.required);
                    if (isSelector) {
                        //reset single selectors classes
                        if (!isSelectorMultiple) {
                            $("[name='" + name + "']").find('div.ui-select-match').children('span').removeClass("ng-valid").removeClass("ng-invalid");
                        }
                        //initialize selector choices if necessary
                        const repeatElement = $("[name='" + name + "'] ul.ui-select-choices")[0].attributes['repeat'].nodeValue;
                        const choicesName = repeatElement.split("in ")[1].split(" |")[0];

                        if (choicesName != 'results') {
                            //TODO: NEW LOGIC, TESTE IT
                            if (choicesName && !self.$scope[choicesName]) {
                                const query = `item in ${choicesName}`;
                                let key = repeatElement.split(query)[0];
                                if (key) {
                                    key = key.split("item.")[1].split(" as ")[0];
                                    let selectedItem = {};
                                    selectedItem[key] = element.$modelValue;
                                    self.$scope[choicesName] = [selectedItem];
                                } else self.$scope[choicesName] = [];
                            }
                            //disable all selectors
                            self.$scope[name] = true;
                        }
                    }
                    //reset element
                    element.$setPristine();
                }
            });
            //self.setFormOperation();
            self.navigateBetweenIds('registerBody');
            self.$scope.showForm = true;
            self.$rootScope.breadCrumb.show = true;
            self.unblock();
            //});
        } catch (ex) {
            this.handleError(ex);
        }
    };

    public loadEditForm(): void {
        try {
            this.block();

            this.$scope.showForm = false;
            this.$scope.loadError = null;
            this.$scope.error = null;
            this.$scope.newRegister = null;
            this.$scope.operation = 'edit';


            const self: FormService2 = this;
            // TODO: Remove timeout for synchronous execution to avoid data difference
            //this.$timeout(function () {
            angular.forEach(self.$scope[self.formName], function (element: any, name: string) {
                if (name[0] !== '$') {
                    const isSelector = (angular.isDefined(element.$$attr.class) && element.$$attr.class.startsWith('ui-select-container'));
                    const isSelectorMultiple = angular.isDefined(element.$$attr.multiple);
                    const isRequired = (element.$$success.required || element.$error.required);

                    if (isSelector) {
                        //remove classes of single selector
                        if (!isSelectorMultiple) {
                            $("[name='" + name + "']").find('div.ui-select-match').children('span').removeClass("ng-valid").removeClass("ng-invalid");
                        }
                        //initialize selector choices if necessary
                        const choicesName = $("[name='" + name + "'] ul.ui-select-choices")[0].attributes['repeat'].nodeValue.split("in ")[1].split(" |")[0];
                        if (choicesName != 'results') {
                            if (!self.$scope[choicesName]) self.$scope[choicesName] = [];
                            //validate and format all selectors
                            self.$timeout(function () {
                                self.selectorValidity(name);
                            });
                            //enable all selectors
                            self.$scope[name] = false;
                        }
                    }
                    //validate all elements
                    element.$setDirty();
                }
            });
            //self.setFormOperation();
            self.navigateBetweenIds('registerBody');
            self.$scope.showForm = true;
            self.$rootScope.breadCrumb.show = true;
            self.unblock();
            //});
        } catch (ex) {
            this.handleLoadError(ex);
        }
    };


    public loadRegisterForm(validateAll: boolean): void {
        try {
            this.block();

            this.$scope.showForm = false;
            this.$scope.loadError = null;
            this.$scope.error = null;
            this.$scope.newRegister = null;
            this.$scope.operation = 'register';

            const self: FormService2 = this;
            this.$timeout(function () {
                angular.forEach(self.$scope[self.formName], function (element: any, name: string) {
                    if (name[0] !== '$') {
                        const isSelector = (angular.isDefined(element.$$attr.class) && element.$$attr.class.startsWith('ui-select-container'));
                        const isSelectorMultiple = angular.isDefined(element.$$attr.multiple);
                        const isRequired = (element.$$success.required || element.$error.required);

                        if (isSelector) {
                            //remove classes of single selector
                            if (!isSelectorMultiple) {
                                $("[name='" + name + "']").find('div.ui-select-match').children('span').removeClass("ng-valid").removeClass("ng-invalid");
                            }
                            //initialize selector choices if necessary
                            const choicesName = $("[name='" + name + "'] ul.ui-select-choices")[0].attributes['repeat'].nodeValue.split("in ")[1].split(" |")[0];
                            if (choicesName != 'results') {
                                if (!self.$scope[choicesName]) self.$scope[choicesName] = [];
                                //enable all selectors
                                self.$scope[name] = false;
                            }
                        }
                        //validity required elements
                        if (isRequired || validateAll) {
                            //validate required selectors
                            if (isSelector) {
                                self.selectorValidity(name);
                            }
                            //validate other elements
                            element.$setDirty();
                        }
                    }
                });
                //self.setFormOperation();
                self.navigateBetweenIds('registerBody');
                self.$scope.showForm = true;
                self.$rootScope.breadCrumb.show = true;
                self.unblock();
            });
        } catch (ex) {
            this.handleLoadError(ex);
        }
    };

    public clearFormInfo(): void {
        this.$rootScope.breadCrumb.show = true;
        this.$scope.showForm = false;
        this.$scope.loadError = null;
        this.$scope.error = null;
        this.$scope.newRegister = null;
    }

    public cancel(): void {
        this.$rootScope.breadCrumb.show = true;
        this.$scope.showForm = false;
        this.$scope.loadError = null;
        this.$scope.error = null;
        this.$scope.newRegister = null;
        this.$scope['model'] = null;
        this.$scope.operation = 'register';
        this.resetForm();
        this.navigateBetweenIds('breadcrumb');
    };


    private resetForm(): void {
        const self: FormService2 = this;
        this.$timeout(function () {
            angular.forEach(self.$scope[self.formName], function (element: any, name: string) {
                if (name[0] !== '$') {
                    if (element.$$attr && element.$$attr.class && element.$$attr.class.startsWith('ui-select-container')) {
                        let choicesName = null;
                        //reset single selectors classes
                        if (!angular.isDefined(element.$$attr.multiple)) {
                            $("[name='" + name + "']").find('div.ui-select-match').children('span').removeClass("ng-valid").removeClass("ng-invalid");
                        } else {
                            choicesName = $("[name='" + name + "'] ul.ui-select-choices")[0].attributes['repeat'].nodeValue.split("in ")[1].split(" |")[0];
                            if (choicesName != 'results') self.$scope[choicesName] = [];
                        }

                        //re-enable selectors
                        if (choicesName != 'results') self.$scope[name] = false;
                    }
                    element.$setPristine();
                }
            });
        });
    };


    validateForm(): void {
        const self: FormService2 = this;
        this.$timeout(function () {
            angular.forEach(self.$scope[self.formName], function (element: any, name: string) {
                //if (name[0] !== '$' && (element.$$success.required || element.$error.required) && element.$invalid) {
                if (name[0] !== '$' && (element.$$success.required || element.$error.required)) {
                    const isSelector = (angular.isDefined(element.$$attr.class) && element.$$attr.class.startsWith('ui-select-container'));
                    //const isSelectorMultiple = isDefined(element.$$attr.multiple);
                    //validate selectors
                    if (isSelector) self.selectorValidity(name);
                    //validate other elements
                    element.$setDirty();
                }
            });
        });
    };

    resetElement(elementName: string): void {
        if (!elementName) return;

        const self: FormService2 = this;
        this.$timeout(() => {
            self.$scope[self.formName][elementName].$setPristine();
        });
    };

    private getPendingFields(formName?: string): Array<string> {
        const form = (formName) ? formName : this.formName;
        let pendingList: Array<string> = [];

        angular.forEach(this.$scope[form], function (element: any, name: string) {
            if (name[0] !== '$' && (element.$$success.required || element.$error.required || element.$error.uiSelectRequired) && element.$invalid) {
                pendingList.push(name);

                //TODO: GUSTAVO MASSANEIRO - RIDER THIS IS A BUG, CONFIG WAS NOT INJECTED/INITIALIZED
                if (config.environment === 'dev') console.log('CAMPO PENDENTE: ', name);
            }
        });
        return pendingList;
    };


    private getOptinalFields(): Array<string> {
        let optionalList: Array<string> = [];

        angular.forEach(this.$scope[this.formName], function (element: any, name: string) {
            if (name[0] !== '$' && (!element.$$success.required && !element.$error.required)) {
                optionalList.push(name);
                console.log('CAMPO OPICIONAL: ', name);
            }
        });
        return optionalList;
    };


    public checkPendingFields(formName?: string): boolean {
        this.block();

        let result: boolean = undefined;
        const pendingFields = (formName) ? this.getPendingFields(formName) : this.getPendingFields();
        if (pendingFields.length > 0) {
            this.$scope.error = this.getTranslate("GENERAL.ALL_FIELDS_MANDATORY");
            this.NotificationService.error(this.$scope.error);
            result = false;
        } else result = true;

        this.unblock();

        return result;
    };

    public getTranslate(term: string, params?: object, makeFirstCharLowerCase?: boolean): string {
        let translated: string = term;
        try {
            if (!term) {
                const errorMsg = this.getTranslate("MESSAGES.ERROR.NULL", { prop: "term" });
                this.notifyError(errorMsg);
            }
            else translated = this.$filter("translate")(term, params);
            if (makeFirstCharLowerCase) translated = translated.charAt(0).toLowerCase() + translated.slice(1);
        } catch (ex) {
            console.log(`Error to translate(${term}): `, ex);
        } finally {
            return translated;
        }
    }

    public getCONCAT(originalValue: any[], customProp?: string, objToReturn?: string, returnUnique: boolean = false, considerMultiline: boolean = false, customSeparator?: string, limitCharacter?: boolean): string {
        try {
            if (!originalValue || originalValue.length === 0) return;
            const separator = (customSeparator) ? customSeparator : ';';

            let values = originalValue;

            // check for multi-line
            if (considerMultiline) {
                const dataSample = (customProp) ? originalValue[0][customProp] : originalValue[0];
                // if that's the case, we mush all values together as we can't represent multiple lines in a one-line grid (duh).
                if (ArrayUtil.isArray(dataSample)) { // is multi-line
                    // this way we still the user stil enable to filter data
                    values = originalValue.reduce((acc, val) => {
                        if (customProp) return acc.concat(val[customProp]);
                        return acc.concat(val);
                    }, []);
                }
            }

            const allData = (isArray(values) ? values : values = [values]).filter(data => data != null).map(val => {
                let accessedData: string = val;
                if (typeof val === 'object') {
                    if (customProp && val.hasOwnProperty(customProp) && val[customProp] != null) //added a customProp when needed to dive little deeper
                        accessedData = val[customProp][objToReturn ? objToReturn : "NAME"];
                    else
                        accessedData = val[objToReturn ? objToReturn : "NAME"];
                }
                return accessedData;
            });

            let treatedData = allData.filter(data => data != null);
            if (returnUnique) treatedData = treatedData.filter((v, i, a) => a.indexOf(v) === i);

            let concatData: string = treatedData.join(`${separator} `);

            // Limit characters
            const charactersLimit = 200;
            if (limitCharacter && concatData.length > charactersLimit) {
                concatData = concatData.substring(0, charactersLimit) + '...';
            }

            return concatData;
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    //use when search panel
    public static hasRequiredElements = (context = ''): boolean => {
        const selector: string = (context == '') ? 'input, textarea, select, .ui-select-container' : `${context} input, ${context} textarea, ${context} select, ${context} .ui-select-container`;
        const requireds = angular.element(selector).filter('[required]:visible, [ui-select-required]:visible');

        let requiredElements = false;
        Array.from(requireds).forEach(elem => {

            let element = angular.element(elem);
            let isInvalid = element.hasClass('ng-invalid-required') || element.hasClass('ng-invalid-ui-select-required');

            if (isInvalid) {
                requiredElements = true;
                return;
            }

        });
        return requiredElements;
    }

    public convertBooleanToSelector(value: boolean): SelectorModel {
        try {
            if (value == undefined || value == null) return this.$scope.getYesNoSelector[1];
            else if (value) return this.$scope.getYesNoSelector[0];
            else return this.$scope.getYesNoSelector[1];
        } catch (ex) {
            this.handleError(ex);
        }
    }

    public convertBooleanToString(value: boolean): string {
        if (typeof (value) !== 'boolean') return;

        return value ? "Sim" : "Não";
    }
}
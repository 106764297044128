import { IColumnDef } from "ui-grid";
import { GridFormService, IGridFormController, IGridFormServiceScope } from "@services/GridFormService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IRestService } from "@services/RestService";
import { IFinopService } from "@services/FinopService";
import { ExchangeRateLogModel } from "@models/interface/management/ExchangeRateLogModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { SelectorModel } from "../../common/model/SelectorModel";
import { HelperService } from "@services/HelperService";

interface IExchangeRateLogScope extends IGridFormServiceScope {
    model: ExchangeRateLogModel;
    rateInfoList: SelectorModel;
}

export interface IMonacoRequest<T = any> {
    route?: string;
    operation?: string;
    data?: T;
    timeout?: number;
    user?: any;
}

export class ExchangeRateLogController extends GridFormService implements IGridFormController {
    static $inject: string[] = ["$injector", "$scope", "$element"];
    private $scope: IExchangeRateLogScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private finopService: IFinopService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IExchangeRateLogScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.finopService = $injector.get('FinopService');
        this.helperService = $injector.get('HelperService');
    }

    initScopeFunctions(): void { }

    async $onInit(): Promise<void> {
        try {
            this.initForm(this, "form", "exchangeRateLog", "GENERAL.MENU.LOG_UPDATE_CHARGES", true);
            await this.initGrid("exchangeRateLog", "/exchangeRateLog/list", true, true, 120000, true, true);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const columnDefs: Array<IColumnDef> = gridColumns.$columnDefs;

        columnDefs.push({
            name: "acoes",
            displayName: "",
            width: '1%',
            cellTemplate: null,
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) };

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colCreatedDate: IMonacoColumnDef = { name: "CREATED_DATE", displayName: "GENERAL.DATE", width: 150, cellFilter: "date:'dd/MM/yyyy hh:mm:ss'" };
            const colCratedBy: IMonacoColumnDef = { name: "CREATED_BY", displayName: "GENERAL.USER", width: 200 };
            const colUpdateDate: IMonacoColumnDef = { name: "UPDATE_DATE", displayName: "GENERAL.LAST_UPDATE", width: 160, cellFilter: "date:'dd/MM/yyyy'" };
            const colRateInfo: IMonacoColumnDef = { name: "RATEINFO.NAME", displayName: "GENERAL.EXCHANGE_RATE_INDEX", width: 200 };
            const colReturnMessage: IMonacoColumnDef = { name: "RETURN_MESSAGE", displayName: "REGISTRATION.MESSAGE", width: 400 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'CREATED_DATE':
                        columnDefs.push(colCreatedDate);
                        break;
                    case 'CREATED_BY':
                        columnDefs.push(colCratedBy);
                        break;
                    case 'UPDATE_DATE':
                        columnDefs.push(colUpdateDate);
                        break;
                    case 'RATEINFO':
                        columnDefs.push(colRateInfo);
                        break;
                    case 'RETURN_MESSAGE':
                        columnDefs.push(colReturnMessage);
                        break;
                };
            }

            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            CREATED_DATE: null,
            CREATED_BY: null,
            UPDATE_DATE: null,
            RATEINFO: null,
            RETURN_MESSAGE: null
        }
    }

    async initDependencies(): Promise<boolean> {
        try {
            const result: Array<any> = await this.$q.all([
                this.getRateInfoList(),
            ]);

            if (!result || result.length === 0) return false;

            this.$scope.rateInfoList = result[0];

            return true;
        } catch (ex) {
            this.handleLoadError(`${this.formService.getTranslate('GENERAL.DEPENDENCY_LIST_FAILED')} ${JSON.stringify(ex)}`);
        }
    }

    private async getRateInfoList(): Promise<SelectorModel[]> {
        const { data: rateInfo } = await this.helperService.get(`/generic/value/exchange_currency_conversion`, null);
        return rateInfo && rateInfo.data ? rateInfo.data : [];
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} - ${this.$scope.model.UPDATE_DATE}`;
            BrowserTitle.$id = this.$scope.model.RATEINFO.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        try {
            const finopReq: IMonacoRequest = {
                route: `/exchangeRate`,
                data: { "rateInfo": this.$scope.model.RATEINFO, "updateDate": this.$scope.model.UPDATE_DATE.toString() },
                timeout: 120000,
            }

            const result = await this.finopService.post(finopReq);

            if (!result || !result.data || !result.data.data) {
                const msgError = this.formService.getTranslate('GENERAL.UNABLE_UPDATE_CHARGE ');
                this.handleLoadError(msgError);
            }

            if (result.data.data) this.$scope.model.RETURN_MESSAGE = this.formService.getTranslate('GENERAL.CHARGES_SENT_UPDATE');

            return true;
        } catch (ex) {
            this.handleLoadError(ex);
        }
        return false;
    }
}

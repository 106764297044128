import angular = require("angular");
import { GridFormService, IGridFormController, IGridFormServiceScope, IMonacoRequestLog } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IServiceType } from '@models/interface/product/ServiceTypeModel';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { IMonacoColumnDef } from "@services/GridService2";
import { SelectorModel } from "../../common/model/SelectorModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { ISessionService } from "@services/SessionService";
import { SSEService } from "../../app/services/SSEService";
import { EOperation } from "@enums/GenericData";
import { IDocumentError } from "WBA-Model/dist/interface/common/IDocumentError";
import { IModalService } from '@services/ModalService';
import { IGridOptions } from "ui-grid";
import { HelperService } from "@services/HelperService";

export interface IServiceTypeScope extends IGridFormServiceScope {
    model: IServiceType;
    scopeBeforeSave: IServiceType;
    log: IViewLog;
    customLogProperties: Array<ICustomLogProperties>;
    productList: Array<SelectorModel>;
    user: any;
    sessionService: ISessionService;
    gridOptions: IGridOptions;
    editServiceType: (serviceType: IServiceType) => Promise<void>;
    viewServiceType: (serviceType: IServiceType) => Promise<void>;
    viewLogServiceType: (serviceType: IServiceType) => Promise<void>;
    copyServiceType: (serviceType: IServiceType) => Promise<void>;
    openModalIntegration: (id: number, documentErrorList: IDocumentError[], name: string) => void;
}

export class ServiceTypeRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope', '$sce'];
    private RestService: IRestService;
    private $q: ng.IQService;
    private $scope: IServiceTypeScope;
    private SSEService: SSEService;
    private gridName: string;
    private $timeout: ng.ITimeoutService
    private modalID: number;
    private ModalService: IModalService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IServiceTypeScope) {
        super($injector, $scope);

        this.$timeout = $injector.get('$timeout');     
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope);
        this.gridName = 'GRID_SERVICE_TYPE';
        this.ModalService = $injector.get('ModalService');
        this.modalID = null;
        this.helperService = $injector.get('HelperService');
    }

    getUrlProduct() {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    async $onInit?(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();

            this.$scope.customLogProperties = this.getCustomLogFields();

            this.initForm(this, "form", "serviceType", "GENERAL.MENU.SERVICE_TYPE", true);
            await this.initGrid(this.gridName, '/servicetype/list', true, true, null, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initModel() {
        this.$scope.model = {
            _id: null,
            ID: null,
            NAME: null,
            DISPLAY_NAME: null,
            NAME_INTL: null,
            PRODUCT: null,
            ACTIVE: true,
            INTEGRATION_ID: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            SEARCH_FIELDS: null,
            DOCUMENT_ERROR: null
        }

        this.$scope.scopeBeforeSave = null;
    }

    initScopeFunctions(): void {
        this.$scope.editServiceType = async (serviceType: IServiceType): Promise<void> => {
            let blockedObject = {
                ID: serviceType.ID,
                NAME: serviceType.NAME,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || serviceType.ID !== this.$scope.model.ID) this.$scope.view(serviceType);
                } else if (this.$scope.operation !== EOperation.EDIT || serviceType.ID !== this.$scope.model.ID) {
                    this.$scope.edit(serviceType);
                }
            };
        }

        this.$scope.viewServiceType = async (serviceType: IServiceType): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(serviceType);
        }

        this.$scope.viewLogServiceType = async (serviceType: IServiceType): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(serviceType);
        }

        this.$scope.copyServiceType = async (serviceType: IServiceType): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(serviceType);
        }

        this.$scope.openModalIntegration = (id: number, documentErrorList: IDocumentError[]) => {
            this.openModalIntegration(id, documentErrorList);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewServiceType(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editServiceType(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogServiceType(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyServiceType(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy + modalIntegration),
            enableCellEdit: true,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };

        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    private buildColumns(columns: Array<string>): IMonacoColumnDef[] {
        let gridColumns: Array<IMonacoColumnDef> = [];

        const colName: IMonacoColumnDef = { name: "DISPLAY_NAME", displayName: "GENERAL.NAME", width: 250 };
        const colExtName: IMonacoColumnDef = { name: "NAME_INTL", displayName: "GENERAL.NAME_INTL", width: 250 };
        const colProduct: IMonacoColumnDef = { name: "PRODUCT", displayName: "BASIC_DATA.PRODUCT", cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PRODUCT, null, "ID")}}</div>', filter: { condition: this.gridService.filterSelectObject }, width: 200 };
        const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 70, cellFilter: "YesOrNo" };
        const colIntegrationID: IMonacoColumnDef = { name: 'INTEGRATION_ID', displayName: 'GENERAL.INTEGRATION_ID', width: 150 };
        const colId: IMonacoColumnDef = { name: "ID", displayName: "GENERAL.ID", width: 80 };
        const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
        const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

        columns.forEach(column => {
            switch (column) {
                case "DISPLAY_NAME":
                    gridColumns.push(colName);
                    break;
                case "NAME_INTL":
                    gridColumns.push(colExtName);
                    break;
                case "PRODUCT":
                    gridColumns.push(colProduct);
                    break;
                case "ACTIVE":
                    gridColumns.push(colActive);
                    break;
                case 'INTEGRATION_ID':
                    gridColumns.push(colIntegrationID);
                    break;
                case "ID":
                    gridColumns.push(colId);
                    break;
                case 'CREATED_AT':
                    gridColumns.push(colCreatedAt);
                    break;
                case 'UPDATED_AT':
                    gridColumns.push(colUpdatedAt);
                    break;
            }
        })
        return gridColumns;
    }

    initDependencies(): Promise<any> {
        const self: ServiceTypeRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([self.getProduct()]).then((result: any) => {
                self.$scope.productList = result[0].map((x) => ({ ID: x.ID, NAME: x.NAME, CODE: null }) );
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")} (${this.$scope.model.NAME})`;
            BrowserTitle.$id = this.$scope.model.DISPLAY_NAME;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")} (${this.$scope.model.NAME})`;
            BrowserTitle.$id = this.$scope.model.DISPLAY_NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        return {
            route: `/servicetype/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "Integration Product/Operation" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                this.formService.block();
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/servicetype/sendSync`, { "idServiceType": id }, 120000, false);
                if (syncRequest) success = true;
            }
        } catch (ex) {
            this.formService.handleError(`${this.formService.getTranslate("REGISTRATION.MESSAGES.ERROR.ERROR_SENDING_REQUEST")}`);
        } finally {
            this.formService.unblock();
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const serviceTypeData = await this.getServiceTypeById(id);
                    if (row && serviceTypeData && serviceTypeData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = serviceTypeData.DOCUMENT_ERROR;
                        documentError = serviceTypeData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async getServiceTypeById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/servicetype/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private getCustomLogFields(): Array<ICustomLogProperties> {
        return [
            {
                PROPERTY: "ID",
                LABEL: "GENERAL.ID"
            },
            {
                PROPERTY: "NAME",
                LABEL: "GENERAL.NAME"
            },
            {
                PROPERTY: "DISPLAY_NAME",
                LABEL: "GENERAL.NAME"
            },
            {
                PROPERTY: "NAME_INTL",
                LABEL: "GENERAL.NAME_INTL"
            },
            {
                PROPERTY: "PRODUCT",
                READ: "ID",
                LABEL: "BASIC_DATA.PRODUCT"
            },
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE"
            },
            {
                PROPERTY: "INTEGRATION_ID",
                LABEL: "GENERAL.INTEGRATION_ID"
            },
            {
                PROPERTY: "CREATED_AT",
                LABEL: "GENERAL.CREATED_AT"
            },
            {
                PROPERTY: "CREATED_BY",
                LABEL: "GENERAL.CREATED_BY"
            },
            {
                PROPERTY: "UPDATED_AT",
                LABEL: "GENERAL.UPDATED_AT"
            },
            {
                PROPERTY: "UPDATED_BY",
                LABEL: "GENERAL.UPDATED_BY"
            }
        ]
    }

    private async getProduct(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get('/generic/value/product', null, 10000);
        return generic && generic.data ? generic.data : [];
    }
}
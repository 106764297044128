import * as angular from 'angular';

import initRoute from './RouteConfig';
import { GRID_FORM_MODULE } from '../gridForm/GridFormModule';
import { ExternalService } from '@services/ExternalService';

const EXTERNAL_MODULE = angular
    .module("external", [GRID_FORM_MODULE.name])
    .service('ExternalService', ExternalService);

initRoute(EXTERNAL_MODULE.name);

export { EXTERNAL_MODULE };
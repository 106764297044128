import * as angular from "angular";
import * as moment from 'moment';
import { IColumnDef, IGridOptions } from "ui-grid";
import { IModalInstanceService } from "angular-ui-bootstrap";
import { isEmptyObject } from "jquery";
import { fileUploader as FileUploader } from 'angular-file-upload';
import { SSEService } from '@appServices/SSEService';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog, } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IModalService } from '@services/ModalService';
import { ISessionService } from "@services/SessionService";
import { ICustomLogProperties, IViewLog } from "@models/interface/common/IViewLog";
import { ITariffFreightContractModel, ITariffFreightModel, ITariffFreightExchangeData, ICommodity, IDynamicField, ICharge } from "@models/interface/product/TariffFreightModel";
import { IRoutingPointSelector } from "@models/interface/product/RoutingPoint";
import { IApplicationList } from "@models/interface/product/ApplicationModel";
import { IChargeNameList, IParams } from "@models/interface/product/ChargeNameModel";
import { IAirServiceListCustomFilter } from "@models/interface/product/AirServiceModel";
import { IUploader, IUploadItem, IFileItem, IFormData, ISuccessItemResponse } from "@models/interface/common/IMonacoUpload";
import { IMoveTypeModel, IParam } from "@models/interface/product/MoveTypeModel";
import { IEquipmentSelector } from "@models/interface/product/EquipmentModel";
import { IProviderSelector } from "@models/interface/product/ProviderModel";
import { IFreightContractModel } from "@models/interface/product/FreightContractModel";
import { IDocumentError } from "@models/interface/common/IDocumentError";
import { EPaymentNatureId, ESituationId, EOperation, EMoveTypeId, ETariffFreightPerDiemType, ETariffTypeDetDem } from "@enums/GenericData";
import { EProductId, EProviderTypeId, EApplicationComplementId, EDirectionId, ECargoTypeId } from "@enums/GenericData";
import { ETransportModeId } from "@enums/InttraEnum";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { SelectorModel } from "../../common/model/SelectorModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { ILinkParameter, IOfferParameter, ITariffFreightFclParameter, ITariffFreightAirParameter } from "../../common/model/ModelParameter";
import { StringUtil } from "../../common/util/StringUtil";
import { IFloatingMenu } from "../../common/interface/IFloatingMenu";
import { IOfferExchangeData } from "../model/OfferModel";
import { ValidateUtil } from "../../common/util/ValidateUtil";
import { IEquipmentFreeTime } from "WBA-Model/dist/interface/product/TariffFreight";
import { ISelectorModel } from "WBA-Model/dist/mongo/SelectorModel";
import { HelperService } from "@services/HelperService";
import { ITablePerDiemQueryFields } from "WBA-Model/dist/interface/product/TablePerDiem";

export interface IChargeColStyle {
    charge: Object;
    param: Object;
    flat: Object;
    weightEquipment: Object;
    currency: Object;
    paymentUnitary: Object;
    paymentMin: Object;
    receivingUnitary: Object;
    receivingMin: Object;
    group: Object;
    specificity: Object;
}

interface ITariffFreightScope extends IGridFormServiceScope {
    form: ng.IFormController;
    gridOptions: IGridOptions;
    model: ITariffFreightContractModel;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: ITariffFreightContractModel;
    menuFloating: IFloatingMenu;
    paymentNatureList: SelectorModel[];
    contractTypeList: SelectorModel[];
    productList: SelectorModel[];
    cargoTypeList: SelectorModel[];
    providerList: IProviderSelector[];
    accountList: SelectorModel[];
    networkList: SelectorModel[];
    commodityList: SelectorModel[];
    eventList: SelectorModel[];
    situationList: SelectorModel[];
    hsCodeSectionList: SelectorModel[];
    moveTypeList: SelectorModel[];
    moveTypeFullList: IMoveTypeModel[];
    processEventTransportModeList: SelectorModel[];
    routingPointList: IRoutingPointSelector[];
    chargeApplicationList: IApplicationList[];
    chargeNameList: SelectorModel[];
    weightRangeList: SelectorModel[];
    equipmentList: IEquipmentSelector[];
    currencyList: SelectorModel[];
    specificityList: SelectorModel[];
    airServiceList: SelectorModel[];
    maritimeServiceList: SelectorModel[];
    serviceLevelList: SelectorModel[];
    vehicleTypeList: SelectorModel[];
    serviceTypeList: SelectorModel[];
    freightContractList: SelectorModel[];
    uploader: IUploader;
    user: any;
    chargeColStyle: IChargeColStyle;
    additionalIndexSelectorValidity: string[];
    tariffFreightDetDemList: SelectorModel[];
    sessionService: ISessionService;
    isAirProduct: boolean;
    isMaritimeProduct: boolean;
    detDemTypeList: SelectorModel[];
    tablePerDiemList: SelectorModel[];
    equipmentFreeTimeList: SelectorModel[];
    perDiemTypeList: SelectorModel[];
    freeTimeTypeList: SelectorModel[];
    hasDetDemOrigin: boolean;
    hasDetDemDestination: boolean;
    tablePerDiemDefaultContractOrigin: boolean[];
    tablePerDiemDefaultContractDestination: boolean[];
    customEdit: (tariffFreight: ITariffFreightModel, idTariffFreight?: number) => void;
    viewTariffFreight: (tariffFreight: ITariffFreightModel, idTariffFreight?: number) => Promise<void>;
    viewLogTariffFreight: (tariffFreight: ITariffFreightModel) => Promise<void>;
    getGridViewProvider: (provider: IProviderSelector) => string;
    getMoveTypeByProduct: () => void;
    getProviderListByName: (search: string) => Promise<void>;
    getAccountListByName: (search: string) => Promise<void>;
    getNetworkListByName: (search: string) => Promise<void>;
    getCommodityListByName: (search: string) => Promise<void>;
    getRoutingPointListByName: (name: string, types: string[]) => Promise<void>;
    getChargeNameListByName: (search: string) => Promise<void>;
    getEquipmentListByName: (search: string) => Promise<void>;
    getEquipmentListForDetDem: (search: string) => Promise<void>;
    getWeightRangeListByName: (search: string) => Promise<void>;
    getCurrencyListByName: (search: string) => Promise<void>;
    getAirServiceListByName: (search: string) => Promise<void>;
    getMaritimeServiceListByName: (search: string) => Promise<void>;
    getFreightContractListByName: (name: string) => Promise<void>;
    getTablePerDiemList: (direction: string, tariffTypeId: string) => Promise<void>;
    addCommodity: () => void;
    hideRemove: () => boolean;
    removeCommodity: (index: number) => void;
    orderCommodityByException: () => void;
    contractChange: () => void;
    commodityChange: (commodity: ICommodity) => void;
    transactionChange: () => void;
    noChargeChange: (parentIndex: number, index: number, value: boolean) => void;
    limitLines: (value: string, limit: number) => string;
    formatConcatenatedChars: (value: string) => string;
    removeDiacriticAndUpper: (value: string) => string;
    removeUpload: (model: IUploadItem) => Promise<boolean>;
    addTariffFreight: (navigate?: boolean) => void;
    copyTariffFreightContract: (tariffFreight: ITariffFreightModel) => Promise<void>;
    copyTariffFreight: (index: number) => Promise<void>;
    removeTariffFreight: (index: number) => void;
    addCharge: (tariffIndex: number) => void;
    removeCharge: (tariffIndex: number, chargeIndex: number) => void;
    duplicateCharge: (tariffIndex: number, chargeIndex: number) => void;
    generateChargeToAvailableWeightRange: (tariffIndex: number, chargeIndex: number) => void;
    flatChange: (tariffIndex: number, chargeIndex: number) => void;
    goToProvider: () => void;
    goToAccount: () => void;
    goToNetwork: () => void;
    goToCommodity: (id: number) => void;
    goToRoutingPoint: (ids: string) => void;
    goToAirService: (tariffFreight: ITariffFreightModel) => void;
    goToMaritimeService: (tariffFreight: ITariffFreightModel) => void;
    goToServiceLevel: (id: number) => void;
    goToOfferWizard: (contractId: number, tariffId?: number) => void;
    goToServiceType: (id: number) => void;
    goToTariffDetDem: (id: number) => void;
    goToFreightRatesManagement: (contractId: number, typeCargoId: string) => void;
    goToTablePerDiem: (tablePerDiemId: number) => void;
    generateConcatenated: () => void;
    generateConcatenatedTariff: (tariffIndex: number) => void;
    generateAllConcatenatedTariff: () => void;
    isCommodityDisabled: (commodity: ICommodity, fieldName: string) => boolean;
    updateProduct: (selected: SelectorModel) => void;
    updateParam: (selected: IChargeNameList, tariffIndex: number, chargeIndex: number) => void;
    updateProvider: () => void;
    updateMoveType: (selected: SelectorModel, tariffIndex: number) => void;
    updatePaymentMin: (tariffIndex: number, chargeIndex: number) => void;
    updateReceivingMin: (tariffIndex: number, chargeIndex: number) => void;
    isChargeAirFieldsDisabled(chargeList: ICharge[], chargeIndex: number, application: IApplicationList, isPaymentField?: boolean, isReceivingField?: boolean, noCharge?: boolean): boolean;
    chargeAirFieldsChange(tariffIndex: number, chargeIndex: number, isCurrencyField?: boolean, isPaymentField?: boolean, isReceivingField?: boolean): void;
    formatedScoreDate: () => string;
    collapseTariffFreight: (tariffFreightIndex: number) => void;
    collapseHeader: (elementId: string, state?: string, navigate?: boolean) => void;
    editChargesModal: (tariffFreight: ITariffFreightModel) => Promise<void>;
    setCopy: (value: boolean) => void;
    getChargeApplicationComplement: (tariffIndex: number, chargeIndex: number) => string;
    checkDateValidity: (initialDate: Date, finalDate: Date) => void;
    getTarifffreightDetDetmListByName: (search: String) => Promise<void>;
    insertFreightContract: (name: string) => Promise<void>;
    isEditingSituation: () => boolean;
    openTariffUsedOffer: (tariffFreight: ITariffFreightContractModel) => Promise<void>;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
    initDetDem: () => void;
    detDemOriginChange: () => void;
    detDemDestinationChange: () => void;
    addEquipmentFreeTime: (originDestination: number) => void;
    addDetDemRoute: (parentIndex: number) => void;
    removeDetDemRoute: (parentIndex: number) => void;
    addRouteEquipmentFreeTime: (index: number, originDestination: number) => void;
    checkRouteFreeTimeSplittedDetention: (transactionId: string, originDestination: number, parentIndex: number, index: number) => void;
    checkRouteFreeTimeSplittedDemurrage: (transactionId: string, originDestination: number, parentIndex: number, index: number) => void;
    checkRouteFreeTimeCombined: (transactionId: string, originDestination: number, parentIndex: number, index: number) => void;
    checkFreeTimeSplittedDetention: (transactionId: string, originDestination: number, index: number) => void;
    checkFreeTimeSplittedDemurrage: (transactionId: string, originDestination: number, index: number) => void;
    checkFreeTimeCombined: (transactionId: string, originDestination: number, index: number) => void;
    checkPerDiemTypeChangeRoute: (originDestination: number, parentIndex: number) => void;
    checkPerDiemTypeChange: (originDestination: number) => void;
    setTablePerDiemRoute: (originDestination: number, parentIndex: number) => void;
    initFreeTimeTable: (originDestination: number) => void;
    initRouteFreeTimeTable: (originDestination: number, parentIndex: number) => void;
    checkReadOnlyRoute: (parentIndex: number, originDestination: number, perDiemTypeId: string) => boolean;
    checkReadOnly: (originDestination: number, perDiemTypeId: string) => boolean;
    removeDetDemRouteEquipment: (parentIndex: number, index: number, property: string) => void;
    removeDetDemEquipment: (index: number, property: string) => void;
}

interface IResponseData {
    result: boolean;
    data: string;
}

interface IResponse {
    data: IResponseData;
    message: string;
    reason: string;
    status: number;
}

export interface IUsedOfferModal extends ng.IScope {
    tariffFreight: ITariffFreightContractModel;
    usedOfferList: IUsedOffer[];
    goToOffer: (offerNumber: string) => void;
}

export interface IUsedOffer {
    CODE: string;
    VALIDITY_START: any;
    VALIDITY_END: any;
    SITUATION: string;
}

export class TariffFreightRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: ITariffFreightScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private $timeout: ng.ITimeoutService
    private ModalService: IModalService;
    private collapseState: string;
    private fileUploader: FileUploader;
    private formName: string;
    private gridName: string;
    private isCopy: boolean;
    private modalFormatedDataId: number;
    private modalIntegrationErrorId: number;
    private SSEService: SSEService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: ITariffFreightScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$scope.additionalIndexSelectorValidity = ['$parentIndex'];
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.fileUploader = $injector.get('FileUploader');
        this.formName = 'tariffFreight';
        this.gridName = 'GRID_TARIFF_FREIGHT'
        this.collapseState = "hide";
        this.modalFormatedDataId = 0;
        this.modalIntegrationErrorId = 0;
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.helperService = $injector.get('HelperService');
    }

    private getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    private getUrlQuotation(): string {
        const baseRoute = '/quotation';
        return this.config.quotationUrl + baseRoute;
    }

    getFisFilesGenericRoute(): string {
        const baseRoute = '/fis/filesGeneric';
        const uploadRoute = this.config.fisUrl + baseRoute;
        return uploadRoute;
    }

    async $onInit(): Promise<void> {
        try {
            this.$scope.uploader = this.getFileUploaderDefault();
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.setCopy(false);
            this.$scope.hasDetDemOrigin = false;
            this.$scope.hasDetDemDestination = false;

            this.initForm(this, 'form', this.formName, 'PRODUCT.FREIGHT_TARIFF', true);
            await this.initGrid(this.gridName, '/tariffFreight/list', true, true, null, true, true);
            const sessionParameter = this.$gridService.$sessionParameter;
            this.SSEService.closeEvents();
            if (sessionParameter && sessionParameter.data) await this.callSessionFunctions(sessionParameter.data);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initDependencies(): Promise<any> {
        const self: TariffFreightRegisterController = this;
        this.addFocusEventOnParam();
        this.initDateFields();
        this.initEvents();
        this.setChargeColStyle();
        this.initTablePerDiemPerRoute();

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getPaymentNatureList(),
                self.getContractTypeList(),
                self.getProductList(),
                self.getEventList(),
                self.getSituationList(),
                self.getCargoTypeList(),
                self.getHsCodeSectionList(),
                self.getMoveTypeList(),
                self.getProcessEventTransportModeList(),
                self.getSpecificityList(),
                self.getVehicleTypeList(),
                self.getServiceLevelListByName(),
                self.getTypeTariffList(),
                self.getTariffFreightPerDiemTypeList(),
                self.getTariffFreightFreeTimeTypeList(),
            ]).then(async (result: any) => {
                self.$scope.paymentNatureList = result[0];
                self.$scope.contractTypeList = result[1];
                self.$scope.productList = result[2];
                self.$scope.eventList = result[3];
                self.$scope.situationList = result[4];
                self.$scope.cargoTypeList = result[5];
                self.$scope.hsCodeSectionList = result[6];
                self.$scope.moveTypeFullList = result[7];
                self.$scope.processEventTransportModeList = result[8];
                self.$scope.specificityList = result[9];
                self.$scope.vehicleTypeList = result[10];
                self.$scope.serviceLevelList = result[11];
                self.$scope.detDemTypeList = result[12];
                self.$scope.perDiemTypeList = result[13];
                self.$scope.freeTimeTypeList = result[14];
                if (self.$scope.moveTypeFullList && self.$scope.moveTypeFullList.length > 0) {
                    self.$scope.moveTypeList = self.$scope.moveTypeFullList.map(moveType => { return { ID: moveType.ID.toString(), NAME: moveType.NAME, CODE: moveType.CODE } });
                }

                if (self.$scope.situationList && self.$scope.situationList.length > 0 && !self.$scope.model.SITUATION) {
                    self.$scope.model.SITUATION = self.$scope.situationList.find(item => item.ID == '1');
                }
                if (self.$scope.eventList && self.$scope.eventList.length > 0 && !self.$scope.model.VALIDITY_EVENT) {
                    self.$scope.model.VALIDITY_EVENT = self.$scope.eventList.find(event => event.ID == '4');
                }
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.customEdit = (tariffFreight: ITariffFreightModel, idTariffFreight?: number): void => {
            this.editTariffFreight(tariffFreight, idTariffFreight);
        }

        this.$scope.editChargesModal = async (tariffFreight: ITariffFreightModel): Promise<void> => {
            let blockedObject = {
                ID: tariffFreight.ID,
                NAME: tariffFreight.CONCATENATED && tariffFreight.CONCATENATED !== '' ? tariffFreight.CONCATENATED : this.gridName + " - " + tariffFreight.ID,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };

            if (this.$scope.showForm) this.$scope.showForm = false;
            if (this.$scope.log && this.$scope.log.show) this.$scope.log.show = false;
            if (this.$scope.model.ID) this.initModel();

            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    this.fetchData(tariffFreight.ID, EOperation.VIEW, tariffFreight.ID);
                } else {
                    await this.editChargesModal(tariffFreight.ID);
                }
            };
        }

        this.$scope.viewTariffFreight = async (tariffFreight: ITariffFreightModel, idTariffFreight?: number): Promise<void> => {
            this.SSEService.closeEvents();
            this.fetchData(tariffFreight.ID, EOperation.VIEW, idTariffFreight);
        }

        this.$scope.viewLogTariffFreight = async (tariffFreight: ITariffFreightModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(tariffFreight);
        }

        this.$scope.copyTariffFreightContract = async (tariffFreight: ITariffFreightModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.fetchData(tariffFreight.ID, EOperation.COPY);
        }

        this.$scope.copyTariffFreight = async (index: number): Promise<void> => {
            this.copyTariffFreight(index)
        }

        this.$scope.addCommodity = (): void => {
            this.addCommodity();
        }

        this.$scope.hideRemove = (): boolean => {
            return this.hideRemove();
        }

        this.$scope.removeCommodity = (index: number): void => {
            this.removeCommodity(index);
        }

        this.$scope.orderCommodityByException = (): void => {
            this.orderCommodityByException();
        }

        this.$scope.commodityChange = (commodity: ICommodity) => {
            this.commodityChange(commodity);
        }

        this.$scope.transactionChange = (): void => {
            this.transactionChange();
        }

        this.$scope.noChargeChange = (parentIndex: number, index: number, value: boolean): void => {
            this.noChargeChange(parentIndex, index, value);
        }

        this.$scope.contractChange = (): void => {
            this.generateConcatenated();
            this.generateAllConcatenatedTariff();
        }

        this.$scope.getProviderListByName = async (search: string): Promise<void> => {
            await this.getProviderListByName(search);
        }

        this.$scope.getAccountListByName = async (search: string): Promise<void> => {
            await this.getAccountListByName(search);
        }

        this.$scope.getNetworkListByName = async (search: string): Promise<void> => {
            await this.getNetworkListByName(search);
        }

        this.$scope.getCommodityListByName = async (search: string): Promise<void> => {
            await this.getCommodityListByName(search);
        }

        this.$scope.getRoutingPointListByName = async (name: string, types: string[]): Promise<void> => {
            await this.getRoutingPointListByName(name, types);
        }

        this.$scope.getChargeNameListByName = async (search: string): Promise<void> => {
            await this.getChargeNameListByName(search);
        }

        this.$scope.getEquipmentListByName = async (search: string): Promise<void> => {
            await this.getEquipmentListByName(search);
        }

        this.$scope.getEquipmentListForDetDem = async (search: string): Promise<void> => {
            await this.getEquipmentListForDetDem(search);
        }

        this.$scope.getWeightRangeListByName = async (search: string): Promise<void> => {
            this.$scope.weightRangeList = await this.getWeightRangeListByName(search);
        }

        this.$scope.getCurrencyListByName = async (search: string): Promise<void> => {
            await this.getCurrencyListByName(search);
        }

        this.$scope.getAirServiceListByName = async (search: string): Promise<void> => {
            await this.getAirServiceListByName(search);
        }

        this.$scope.getMaritimeServiceListByName = async (search: string): Promise<void> => {
            await this.getMaritimeServiceListByName(search);
        }

        this.$scope.getFreightContractListByName = async (search: string): Promise<void> => {
            await this.getFreightContractListByName(search);
        }

        this.$scope.getTablePerDiemList = async (direction: string, tariffTypeId: string): Promise<void> => {
            await this.getTablePerDiemList(direction, tariffTypeId);
        }

        this.$scope.addTariffFreight = (navigate?: boolean) => {
            this.addTariffFreight(navigate);
        }

        this.$scope.removeTariffFreight = (index: number) => {
            this.removeTariffFreight(index);
        }

        this.$scope.addCharge = (tariffIndex: number): void => {
            this.addCharge(tariffIndex);
        }

        this.$scope.removeCharge = (tariffIndex: number, chargeIndex: number): void => {
            this.removeCharge(tariffIndex, chargeIndex);
        }

        this.$scope.duplicateCharge = (tariffIndex: number, chargeIndex: number): void => {
            this.duplicateCharge(tariffIndex, chargeIndex);
        }

        this.$scope.generateChargeToAvailableWeightRange = (tariffIndex: number, chargeIndex: number) => {
            this.generateChargeToAvailableWeightRange(tariffIndex, chargeIndex);
        }

        this.$scope.flatChange = (tariffIndex: number, chargeIndex: number) => {
            this.flatChange(tariffIndex, chargeIndex);
        }

        this.$scope.goToProvider = () => {
            this.$scope.sessionService.openTab("app.registration.provider", <ILinkParameter>{ ID: (this.$scope.model.PROVIDER ? this.$scope.model.PROVIDER.ID.toString() : null) });
        }

        this.$scope.goToAccount = () => {
            this.$scope.sessionService.openTab("app.commercial.account", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.ACCOUNTS, null, 'ID') });
        }

        this.$scope.goToNetwork = () => {
            this.$scope.sessionService.openTab("app.registration.network", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.NETWORKS, null, 'ID') });
        }

        this.$scope.goToCommodity = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.commodity", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToRoutingPoint = (ids: string) => {
            this.$scope.sessionService.openTab("app.registration.routingPoint", <ILinkParameter>{ ID: ids });
        }

        this.$scope.goToAirService = (tariffFreight: ITariffFreightModel) => {
            this.$scope.sessionService.openTab("app.product.airService", <ILinkParameter>{ ID: this.$scope.getCONCAT(tariffFreight.AIR_SERVICE, null, 'ID') });
        }

        this.$scope.goToMaritimeService = (tariffFreight: ITariffFreightModel) => {
            this.$scope.sessionService.openTab("app.product.maritimeService", <ILinkParameter>{ ID: this.$scope.getCONCAT(tariffFreight.MARITIME_SERVICE, null, 'ID') });
        }

        this.$scope.goToServiceLevel = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.servicelevel", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToOfferWizard = async (contractId: number, tariffId?: number, paymentNatureId?: number) => {
            if (contractId == null) throw Error("contractId is null");
            const hasOfferCombinedWizardPermission = await this.permissionService.isRoleAllowed("OFFERCOMBINEDGENERATEWIZARD");
            if (hasOfferCombinedWizardPermission) this.formService.handleWarning(this.formService.getTranslate("GENERAL.CANNOT_NEW_OFFER_COMBINED"));
            else this.$scope.sessionService.openTab("app.commercial.offer", <IOfferParameter>{ CONCATENATED: "" }, <IOfferExchangeData>{ OPERATION: "new", ID_NEW_FREIGHT_CONTRACT: null, ID_TARIFF_FREIGHT_CONTRACT: contractId, ID_TARIFF: tariffId, ID_PAYMENT_NATURE: paymentNatureId });
        }

        this.$scope.goToServiceType = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.servicetype", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToTariffDetDem = (id: number) => {
            const tariffId = id ? id.toString() : id;
            const endpoint = `${this.config.productUrl}/product/tariffDetDem/getCacheById/${tariffId}`;
            this.$scope.sessionService.openTabByValidity(endpoint, "app.product.tariffDetDem", <ILinkParameter>{ ID: tariffId });
        }

        this.$scope.goToFreightRatesManagement = (contractId: number, typeCargoId: string) => {
            if (contractId == null) throw Error("contractId is null");
            if (contractId == null) throw Error("typeCargoId is null");

            if (typeCargoId == ECargoTypeId.FCL) this.$scope.sessionService.openTab("app.product.tariffFreightFcl", <ITariffFreightFclParameter>{ ID_TARIFF_FREIGHT_CONTRACT: contractId ? contractId.toString() : null });
            else if (typeCargoId == ECargoTypeId.AIR) this.$scope.sessionService.openTab("app.product.tariffFreightAir", <ITariffFreightAirParameter>{ ID_TARIFF_FREIGHT_CONTRACT: contractId ? contractId.toString() : null });
        }

        this.$scope.goToTablePerDiem = (tablePerDiemId: number) => {
            this.$scope.sessionService.openTab("app.product.tablePerDiem", <ILinkParameter>{ ID: tablePerDiemId.toString() });
        }

        this.$scope.generateConcatenated = (): void => {
            this.generateConcatenated();
        }

        this.$scope.generateConcatenatedTariff = (tariffIndex: number): void => {
            this.generateConcatenatedTariff(tariffIndex);
        }

        this.$scope.generateAllConcatenatedTariff = (): void => {
            this.generateAllConcatenatedTariff();
        }

        this.$scope.isCommodityDisabled = (commodity: ICommodity, fieldName: string): boolean => {
            return this.isCommodityDisabled(commodity, fieldName);
        }

        this.$scope.limitLines = (value: string, limit: number): string => {
            return StringUtil.limitLines(value, limit);
        }

        this.$scope.formatConcatenatedChars = (value: string) => {
            return StringUtil.formatConcatenatedChars(value);
        }

        this.$scope.removeDiacriticAndUpper = (value: string): string => {
            return StringUtil.removeDiacriticAndUpper(value);
        }

        this.$scope.removeUpload = (model: IUploadItem): Promise<boolean> => {
            return this.removeUpload(model);
        }

        this.$scope.uploader.onSuccessItem = (item: IFileItem, response: ISuccessItemResponse): void => {
            this.onSuccessItem(item, response);
        }

        this.$scope.updateProduct = (selected: SelectorModel): void => {
            this.updateProduct(selected);
        }

        this.$scope.updateParam = (selected: IChargeNameList, tariffIndex: number, chargeIndex: number): void => {
            this.updateParam(selected, tariffIndex, chargeIndex);
        }

        this.$scope.getGridViewProvider = (provider: IProviderSelector): string => {
            let gridValue = "";
            if (provider && provider.TYPE) {
                if (provider.TYPE.ID == EProviderTypeId.SHIPOWNER || provider.TYPE.ID == EProviderTypeId.AIRLINE) gridValue = provider.CODE;
                else if (provider.TYPE.ID == EProviderTypeId.SHIPPING_COMPANY) gridValue = provider.NAME;
            }
            return gridValue;
        }

        this.$scope.getMoveTypeByProduct = (): void => {
            this.getMoveTypeByProduct();
        }

        this.$scope.updateProvider = (): void => {
            this.updateProvider();
        }

        this.$scope.updateMoveType = (selected: SelectorModel, tariffIndex: number): void => {
            this.updateMoveType(selected, tariffIndex);
        }

        this.$scope.updatePaymentMin = (tariffIndex: number, chargeIndex: number): void => {
            this.updatePaymentMin(tariffIndex, chargeIndex);
        }

        this.$scope.updateReceivingMin = (tariffIndex: number, chargeIndex: number): void => {
            this.updateReceivingMin(tariffIndex, chargeIndex);
        }

        this.$scope.isChargeAirFieldsDisabled = (chargeList: ICharge[], chargeIndex: number, application: IApplicationList, isPaymentField?: boolean, isReceivingField?: boolean, noCharge?: boolean): boolean => {
            return this.isChargeAirFieldsDisabled(chargeList, chargeIndex, application, isPaymentField, isReceivingField, noCharge);
        }

        this.$scope.chargeAirFieldsChange = (tariffIndex: number, chargeIndex: number, isCurrencyField?: boolean, isPaymentField?: boolean, isReceivingField?: boolean): void => {
            this.chargeAirFieldsChange(tariffIndex, chargeIndex, isCurrencyField, isPaymentField, isReceivingField);
        }

        this.$scope.formatedScoreDate = (): string => {
            return this.formatedScoreDate();
        }

        this.$scope.collapseTariffFreight = (tariffFreightIndex: number) => {
            this.collapseTariffFreight(tariffFreightIndex);
        }

        this.$scope.collapseHeader = (elementId: string, state?: string, navigate?: boolean) => {
            this.collapseHeader(elementId, state, navigate);
        }

        this.$scope.setCopy = (value: boolean): void => {
            this.setCopy(value);
        }

        this.$scope.getChargeApplicationComplement = (tariffIndex: number, chargeIndex: number): string => {
            return this.getChargeApplicationComplement(tariffIndex, chargeIndex);
        }

        this.$scope.checkDateValidity = (initialDate: Date, finalDate: Date): void => {
            this.checkDateValidity(initialDate, finalDate);
        }

        this.$scope.getTarifffreightDetDetmListByName = async (search: String): Promise<void> => {
            return await this.getTarifffreightDetDetmListByName(search);
        }

        this.$scope.insertFreightContract = async (name: string) => {
            try {
                const newFreightContractData: IFreightContractModel = {
                    _id: null, ID: null, ACTIVE: true, NAME: name, DISPLAY_NAME: name, CREATED_AT: null,
                    CREATED_BY: null, UPDATED_AT: null, UPDATED_BY: null, SEARCH_FIELDS: null, DESCRIPTION: null,
                    DISPLAY_DESCRIPTION: null, CONCATENATED: null, DOCUMENT_ERROR: null
                };
                const result = await this.RestService.newObjectPromise(`${this.$baseUrl}/freightContract/insert`, { data: newFreightContractData, oldData: null }, 30000, false);
                if (result) this.formService.notifySuccess(this.formService.getTranslate('PRODUCT.CONTRACT_FREIGHT_SUCCESSFULLY_ADDED'));
                await this.getFreightContractListByName(name);
            } catch (ex) {
                this.formService.handleError(ex);
            }
        }

        this.$scope.isEditingSituation = () => {
            return this.$scope.model.ID && this.$scope.model.SITUATION.ID == ESituationId.EDITING;
        }

        this.$scope.openTariffUsedOffer = async (tariffFreight: ITariffFreightContractModel): Promise<void> => {
            this.openTariffUsedOffer(tariffFreight);
        }

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }

        this.$scope.addEquipmentFreeTime = (originDestination: number) => {
            this.addEquipmentFreeTime(originDestination);
        }

        this.$scope.addRouteEquipmentFreeTime = (index: number, originDestination: number) => {
            this.addRouteEquipmentFreeTime(index, originDestination);
        }

        this.$scope.checkRouteFreeTimeSplittedDemurrage = (transactionId: string, originDestination: number, parentIndex: number, index: number) => {
            this.checkRouteFreeTimeSplittedDemurrage(transactionId, originDestination, parentIndex, index);
        }

        this.$scope.checkRouteFreeTimeSplittedDetention = (transactionId: string, originDestination: number, parentIndex: number, index: number) => {
            this.checkRouteFreeTimeSplittedDetention(transactionId, originDestination, parentIndex, index);
        }

        this.$scope.checkRouteFreeTimeCombined = (transactionId: string, originDestination: number, parentIndex: number, index: number) => {
            this.checkRouteFreeTimeCombined(transactionId, originDestination, parentIndex, index);
        }

        this.$scope.checkFreeTimeSplittedDemurrage = (transactionId: string, originDestination: number, index: number) => {
            this.checkFreeTimeSplittedDemurrage(transactionId, originDestination, index);
        }

        this.$scope.checkFreeTimeSplittedDetention = (transactionId: string, originDestination: number, index: number) => {
            this.checkFreeTimeSplittedDetention(transactionId, originDestination, index);
        }

        this.$scope.checkFreeTimeCombined = (transactionId: string, originDestination: number, index: number) => {
            this.checkFreeTimeCombined(transactionId, originDestination, index);
        }
        this.$scope.checkPerDiemTypeChange = (originDestination: number) => {
            this.checkPerDiemTypeChange(originDestination);
        }
        this.$scope.checkPerDiemTypeChangeRoute = (originDestination: number, parentIndex: number) => {
            this.checkPerDiemTypeChangeRoute(originDestination, parentIndex);
        }

        this.$scope.setTablePerDiemRoute = (originDestination: number, parentIndex: number) => {
            this.setTablePerDiemRoute(originDestination, parentIndex);
        }

        this.$scope.addDetDemRoute = (parentIndex: number) => {
            this.addDetDemRoute(parentIndex);
        }

        this.$scope.removeDetDemRoute = (parentIndex: number) => {
            this.removeDetDemRoute(parentIndex);
        }

        this.$scope.initDetDem = () => {
            this.initDetDem();
        }

        this.$scope.detDemOriginChange = () => {
            this.detDemOriginChange();
        }

        this.$scope.detDemDestinationChange = () => {
            this.detDemDestinationChange();
        }

        this.$scope.initFreeTimeTable = (originDestination: number) => {
            this.initFreeTimeTable(originDestination);
        }
        this.$scope.initRouteFreeTimeTable = (originDestination: number, parentIndex: number) => {
            this.initRouteFreeTimeTable(originDestination, parentIndex);
        }
        this.$scope.checkReadOnlyRoute = (parentIndex: number, originDestination: number, perDiemTypeId: string) => {
            return this.checkReadOnlyRoute(parentIndex, originDestination, perDiemTypeId);
        }
        this.$scope.checkReadOnly = (originDestination: number, perDiemTypeId: string) => {
            return this.checkReadOnly(originDestination, perDiemTypeId);
        }
        this.$scope.removeDetDemEquipment = (index: number, property: string) => {
            this.removeDetDemEquipment(index, property);
        }
        this.$scope.removeDetDemRouteEquipment = (parentIndex: number, index: number, property: string) => {
            this.removeDetDemRouteEquipment(parentIndex, index, property);
        }
    }

    initGridColumns(columns: string[]): IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<a ng-click="grid.appScope.viewTariffFreight(row.entity)" class="text-info v-middle" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true"><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.customEdit(row.entity)" class="text-especial v-middle edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true"><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogTariffFreight(row.entity)" class="text-green v-middle log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true"><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.setCopy(true);grid.appScope.copyTariffFreightContract(row.entity)" class="text-orange v-middle copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true"><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const editCharges = `<a ng-click="grid.appScope.editChargesModal(row.entity)" class="text-orange v-middle" tooltip-placement="auto top" uib-tooltip="{{ 'PRODUCT.CHARGES_EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-wrench" aria-hidden="true"></i></a>&nbsp;&nbsp;`
        const offer = `<a ng-click="row.entity.SITUATION.ID == ${ESituationId.RELEASED} && grid.appScope.goToOfferWizard(row.entity.ID)" ng-class="{'disabled': row.entity.SITUATION.ID == ${ESituationId.EDITING}}" class="text-green v-middle edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{row.entity.SITUATION.ID == ${ESituationId.RELEASED} ? 'PRODUCT.NEW_OFFER' : 'PRODUCT.GENERATE_OFFER_BEING_EDITED' | translate }}" tooltip-append-to-body="true"><i class="fa fa-handshake-o icon"></i></a>&nbsp;&nbsp;`;
        const offerGenerated = `<a ng-disabled="!row.entity.TARIFF_FREIGHT_CONTRACT_USED_OFFER.length" ng-click="row.entity.TARIFF_FREIGHT_CONTRACT_USED_OFFER.length ? grid.appScope.openTariffUsedOffer(row.entity) : ''" class="text-info v-middle" ng-class="{'text-muted': !row.entity.TARIFF_FREIGHT_CONTRACT_USED_OFFER.length}" tooltip-placement="auto top" uib-tooltip="{{row.entity.TARIFF_FREIGHT_CONTRACT_USED_OFFER.length ? 'GENERAL.OFFER_VIEW' : 'GENERAL.NO_OFFERS_GENERATED_WITH_THIS_TARIFF' | translate }}" tooltip-append-to-body="true"><i class="fa fa-list-alt icon"></i></a>&nbsp;&nbsp;`;
        const freightRatesManagement = `<a  ng-disabled="!(row.entity.TYPE_CARGO.ID == ${ECargoTypeId.FCL} || row.entity.TYPE_CARGO.ID == ${ECargoTypeId.AIR})" ng-click="grid.appScope.goToFreightRatesManagement(row.entity.ID, row.entity.TYPE_CARGO.ID)" style="vertical-align: text-bottom" tooltip-placement="auto top" uib-tooltip="{{ 'PRODUCT.VIEW_FCL_RATES_MANAGEMENT' | translate }} {{row.entity.TYPE_CARGO.NAME}}" tooltip-append-to-body="true" ><img src="../../../img/icons/container.png" width="14px"></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" class="v-middle" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes", displayName: "GENERAL.ACTIONS",
            width: 200,
            cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${viewLog} ${copy} ${editCharges} ${offer} ${offerGenerated} ${freightRatesManagement} ${modalIntegration}</div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };

        gridColumns.addColumn(colActions);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colSituation: IMonacoColumnDef = { name: "SITUATION.NAME", displayName: "GENERAL.SITUATION", width: 150 };
            const colConcatenated: IMonacoColumnDef = { name: "CONCATENATED", displayName: "GENERAL.CONCATENATED_COMPLEMENT", width: 250 };
            const colVersion: IMonacoColumnDef = { name: "VERSION", displayName: "GENERAL.VERSION", width: 100 };
            const colContract: IMonacoColumnDef = { name: "CONTRACT.CODE", displayName: "PRODUCT.FREIGHT_CONTRACT", width: 150 };
            const colPaymentNature: IMonacoColumnDef = { name: "PAYMENT_NATURE.NAME", displayName: "BASIC_DATA.TRANSACTION", width: 150 };
            const colContractType: IMonacoColumnDef = { name: "CONTRACT_TYPE.NAME", displayName: "PRODUCT.FREIGHT_CONTRACT_TYPE", width: 150 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 100 };
            const colUsedOffer: IMonacoColumnDef = { name: "TARIFF_FREIGHT_CONTRACT_USED_OFFER.OFFER_CODE_EXHIBITION", displayName: "BASIC_DATA.OFFER", width: 160, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TARIFF_FREIGHT_CONTRACT_USED_OFFER, null, "OFFER_CODE_EXHIBITION")}}</div>' };
            const colTypeCargo: IMonacoColumnDef = { name: "TYPE_CARGO.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 150 };
            const colProvider: IMonacoColumnDef = { name: "PROVIDER.CODE", displayName: "BASIC_DATA.PROVIDER", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getGridViewProvider(row.entity.PROVIDER)}}</div>' };
            const colAccount: IMonacoColumnDef = { name: "ACCOUNTS.NAME", displayName: "BASIC_DATA.CLIENT", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNTS, null, "NAME")}}</div>' };
            const colAccountFantasyName: IMonacoColumnDef = { name: "ACCOUNTS.FANTASY_NAME", displayName: "GENERAL.CUSTOMER_COMMERCIAL_NAME", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNTS, null, "FANTASY_NAME")}}</div>' };
            const colNetwork: IMonacoColumnDef = { name: "NETWORKS.NAME", displayName: "BASIC_DATA.EXTERNAL_AGENT_NETWORK", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.NETWORKS, null, "NAME")}}</div>' };
            const colEvent: IMonacoColumnDef = { name: "VALIDITY_EVENT.NAME", displayName: "GENERAL.VALIDITY_BASIS", width: 100 };
            const colCommoditySection: IMonacoColumnDef = { name: "COMMODITY.SECTION.NAME", displayName: "GENERAL.COMMODITY_GROUP_CONDITION", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.COMMODITY, "SECTION", null, false, true)}}</div>' };
            const colCommodityCondition: IMonacoColumnDef = { name: "COMMODITY.CONDITION.NAME", displayName: "GENERAL.COMMODITY_CONDITION", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.COMMODITY, "CONDITION", null, false, true)}}</div>' };
            const colCommodityException: IMonacoColumnDef = { name: "COMMODITY.EXCEPTION.NAME", displayName: "GENERAL.COMMODITY_EXCEPTION", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.COMMODITY, "EXCEPTION", null, false, true)}}</div>' };
            const colValidityStart: IMonacoColumnDef = { name: "DISPLAY_VALIDITY_START", displayName: "PRODUCT.VALIDITY_START", width: 150, cellFilter: "simpleDate" };
            const colValidityEnd: IMonacoColumnDef = { name: "DISPLAY_VALIDITY_END", displayName: "PRODUCT.VALIDITY_END", width: 150, cellFilter: "simpleDate" };
            const colScore: IMonacoColumnDef = { name: "SCORE", displayName: "BASIC_DATA.SCORE", width: 100 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 90, cellFilter: "YesOrNo" };
            const colValidityExpired: IMonacoColumnDef = { name: "VALIDITY_EXPIRED", displayName: "GENERAL.EXPIRED", width: 150, cellFilter: "YesOrNo" };
            const colMoveType: IMonacoColumnDef = { name: "MOVE_TYPE_REPL", displayName: "GENERAL.MENU.MOVE_TYPE", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.MOVE_TYPE_REPL, null, "CODE")}}</div>' };
            const colTradeGroupOriginRepl: IMonacoColumnDef = { name: "TRADE_GROUP_ORIGIN_REPL", displayName: "BASIC_DATA.ORIGIN_TRADE_LANE", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TRADE_GROUP_ORIGIN_REPL)}}</div>' };
            const colTradeGroupDestinationRepl: IMonacoColumnDef = { name: "TRADE_GROUP_DESTINATION_REPL", displayName: "BASIC_DATA.DESTINATION_TRADE_LANE", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TRADE_GROUP_DESTINATION_REPL)}}</div>' };
            const colRoutingPointOrigin: IMonacoColumnDef = { name: "ROUTING_POINT_ORIGIN_REPL", displayName: "BASIC_DATA.ORIGIN", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ROUTING_POINT_ORIGIN_REPL, null, "CODE")}}</div>' };
            const colRoutingPointDestination: IMonacoColumnDef = { name: "ROUTING_POINT_DESTINATION_REPL", displayName: "BASIC_DATA.DESTINATION", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ROUTING_POINT_DESTINATION_REPL, null, "CODE")}}</div>' };
            const colCountryOrigin: IMonacoColumnDef = { name: "COUNTRY_ORIGIN_REPL", displayName: "BASIC_DATA.ORIGIN_COUNTRY", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.COUNTRY_ORIGIN_REPL, null, "CODE")}}</div>' };
            const colCountryDestination: IMonacoColumnDef = { name: "COUNTRY_DESTINATION_REPL", displayName: "BASIC_DATA.DESTINATION_COUNTRY", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.COUNTRY_DESTINATION_REPL, null, "CODE")}}</div>' };
            const colEquipWeightRange: IMonacoColumnDef = { name: "EQUIP_WEIGHT_RANGE_VEHICLE_REPL", displayName: "PRODUCT.BASIS_COMPLEMENT", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.EQUIP_WEIGHT_RANGE_VEHICLE_REPL)}}</div>' };
            const colChargeName: IMonacoColumnDef = { name: "CHARGE_NAME_EXHIBITION_REPL", displayName: "BASIC_DATA.CHARGE", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.CHARGE_NAME_EXHIBITION_REPL, null, "CODE")}}</div>' };
            const colDetDem: IMonacoColumnDef = { name: "TARIFF_DET_DEM.NAME", displayName: "PRODUCT.DD_TARIFF", width: 150 };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "GENERAL.ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'SITUATION':
                        columnDefs.push(colSituation);
                        break;
                    case 'CONCATENATED':
                        columnDefs.push(colConcatenated);
                        break;
                    case 'VERSION':
                        columnDefs.push(colVersion);
                        break;
                    case 'CONTRACT':
                        columnDefs.push(colContract);
                        break;
                    case 'PAYMENT_NATURE':
                        columnDefs.push(colPaymentNature);
                        break;
                    case 'CONTRACT_TYPE':
                        columnDefs.push(colContractType);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'TARIFF_FREIGHT_CONTRACT_USED_OFFER':
                        columnDefs.push(colUsedOffer);
                        break;
                    case 'TYPE_CARGO':
                        columnDefs.push(colTypeCargo);
                        break;
                    case 'PROVIDER':
                        columnDefs.push(colProvider);
                        break;
                    case 'ACCOUNTS':
                        columnDefs.push(colAccount, colAccountFantasyName);
                        break;
                    case 'NETWORKS':
                        columnDefs.push(colNetwork);
                        break;
                    case 'COMMODITY':
                        columnDefs.push(colCommoditySection);
                        columnDefs.push(colCommodityCondition);
                        columnDefs.push(colCommodityException);
                        break;
                    case 'VALIDITY_EVENT':
                        columnDefs.push(colEvent);
                        break;
                    case 'VALIDITY_START':
                        columnDefs.push(colValidityStart);
                        break;
                    case 'VALIDITY_END':
                        columnDefs.push(colValidityEnd);
                        break;
                    case 'SCORE':
                        columnDefs.push(colScore);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'VALIDITY_EXPIRED':
                        columnDefs.push(colValidityExpired);
                        break;
                    case 'MOVE_TYPE_REPL':
                        columnDefs.push(colMoveType);
                        break;
                    case 'TRADE_GROUP_ORIGIN_REPL':
                        columnDefs.push(colTradeGroupOriginRepl);
                        break;
                    case 'TRADE_GROUP_DESTINATION_REPL':
                        columnDefs.push(colTradeGroupDestinationRepl);
                        break;
                    case 'ROUTING_POINT_ORIGIN_REPL':
                        columnDefs.push(colRoutingPointOrigin);
                        break;
                    case 'ROUTING_POINT_DESTINATION_REPL':
                        columnDefs.push(colRoutingPointDestination);
                        break;
                    case 'COUNTRY_ORIGIN_REPL':
                        columnDefs.push(colCountryOrigin);
                        break;
                    case 'COUNTRY_DESTINATION_REPL':
                        columnDefs.push(colCountryDestination);
                        break;
                    case 'EQUIP_WEIGHT_RANGE_VEHICLE_REPL':
                        columnDefs.push(colEquipWeightRange);
                        break;
                    case 'CHARGE_NAME_EXHIBITION_REPL':
                        columnDefs.push(colChargeName);
                        break;
                    case 'TARIFF_DET_DEM':
                        columnDefs.push(colDetDem);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }

            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            CONCATENATED: null,
            CONCATENATED_COMPLEMENT: null,
            VERSION: null,
            ID_CONTRACT: null,
            CONTRACT: null,
            PAYMENT_NATURE: null,
            CONTRACT_TYPE: null,
            PRODUCT: null,
            TYPE_CARGO: null,
            PROVIDER: null,
            ID_PROVIDER: null,
            ACCOUNTS: null,
            NETWORKS: null,
            COMMODITY: null,
            VALIDITY_EVENT: null,
            VALIDITY_START: null,
            DISPLAY_VALIDITY_START: null,
            VALIDITY_END: null,
            DISPLAY_VALIDITY_END: null,
            SITUATION: null,
            FILES: null,
            FILES_NEW: null,
            FILES_REMOVE: null,
            SCORE: null,
            SCORE_RULE: null,
            SCORE_DATE: null,
            SCORE_ERROR: null,
            ACTIVE: true,
            VALIDITY_EXPIRED: true,
            TARIFF_FREIGHT: [],
            MOVE_TYPE_REPL: null,
            EQUIP_WEIGHT_RANGE_VEHICLE_REPL: null,
            TRADE_GROUP_ORIGIN_REPL: null,
            TRADE_GROUP_DESTINATION_REPL: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            DOCUMENT_ERROR: null,
            DET_DEM_DESTINATION: null,
            DET_DEM_ORIGIN: null
        };
    }

    async saveSuccess(returnedData: IResponse): Promise<void> {
        if (returnedData && returnedData.data && returnedData.data.result === false && returnedData.data.data) {
            this.formService.notifyInfo(returnedData.data.data);
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.disableElements(false);
            this.initPanels();
            if (!this.isCopy) this.addTariffFreight(false, ['collapseTariffFreightBasicData', 'collapseTariffFreightCharge']);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        let proceed: boolean = true;
        try {
            if (this.$scope.uploader.queue && this.$scope.uploader.queue.length > 0) {
                proceed = false;
                this.handleError('GENERAL.FILES_IN_THE_QUEUE');
            }

            proceed = !this.hasHiddenPendingFields();

            if (proceed) {
                this.$scope.model.SCORE = null;
                this.$scope.model.SCORE_DATE = null;
                this.$scope.model.SCORE_RULE = null;
                this.$scope.model.SCORE_ERROR = null;
                this.SSEService.closeEvents();
            }
            this.setCopy(false);
        } catch (ex) {
            this.handleError(ex);
            proceed = false;
        }
        return proceed;
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
        this.setCopy(false);
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")}`;
            BrowserTitle.$id = this.$scope.model.CONCATENATED;
            await this.updateControl();
            this.initDateFields();
            this.$scope.disableElements(true);
            this.orderCommodityByException();
            this.$scope.moveTypeFullList = await this.getMoveTypeList();
            if (this.$scope.model.CONCATENATED) this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
            if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length > 0) {
                for (const tariff of this.$scope.model.TARIFF_FREIGHT) {
                    const moveType = tariff.MOVE_TYPE && this.$scope.moveTypeFullList ? this.$scope.moveTypeFullList.find(moveType => moveType.ID == parseInt(tariff.MOVE_TYPE.ID)) : null;
                    if (moveType && moveType.PARAMS) tariff.DYNAMIC_FIELDS = this.buildDynamicFields(moveType.PARAMS);
                }
            }
            this.initPanels(['collapseTariffFreight']);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")}`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.CONCATENATED;
            this.$scope.disableElements(false);
            await this.updateControl();
            this.orderCommodityByException();
            if (this.$scope.model.CONCATENATED) this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
            if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length > 0) {
                this.$scope.model.TARIFF_FREIGHT.forEach((tariff, index) => {
                    $('#collapseTariffFreight' + index).on('shown.bs.collapse', () => {
                        if (this.$scope.operation == 'edit') {
                            tariff.SHOW = true;
                            this.collapseHeader('collapseTariffFreightCharge' + index, 'show', false);
                        }
                        this.addFocusEventOnParam(index);
                    });
                    const moveType = tariff.MOVE_TYPE ? this.$scope.moveTypeFullList.find(moveType => moveType.ID == parseInt(tariff.MOVE_TYPE.ID)) : null;
                    if (moveType && moveType.PARAMS) tariff.DYNAMIC_FIELDS = this.buildDynamicFields(moveType.PARAMS);
                });
            }
            this.initPanels(['collapseTariffFreight']);
            if (this.$scope.model.DET_DEM_ORIGIN) this.$scope.hasDetDemOrigin = true;
            if (this.$scope.model.DET_DEM_DESTINATION) this.$scope.hasDetDemDestination = true;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            await this.clearFields(this.$scope.model, ['CONCATENATED', 'SCORE', 'SCORE_DATE', 'SCORE_RULE', 'SCORE_ERROR']);
            if (this.$scope.model.COMMODITY && this.$scope.model.COMMODITY.length > 0) {
                for (const commodity of this.$scope.model.COMMODITY) {
                    commodity.ID = null;
                }
            }
            this.$scope.model.FILES = null;
            this.$scope.model.FILES_NEW = null;
            this.$scope.model.FILES_REMOVE = null;
            await this.updateControl();
            this.initDateFields();
            if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length > 0) {
                for (let index = 0; index < this.$scope.model.TARIFF_FREIGHT.length; index++) {
                    const tariff = this.$scope.model.TARIFF_FREIGHT[index];
                    tariff._id = null;
                    tariff.ID = null;
                    if (tariff.CHARGE && tariff.CHARGE.length > 0) {
                        for (const charge of tariff.CHARGE) {
                            charge._id = null;
                            charge.ID = null;

                        }
                    }
                    const moveType = tariff.MOVE_TYPE ? this.$scope.moveTypeFullList.find(moveType => moveType.ID == parseInt(tariff.MOVE_TYPE.ID)) : null;
                    if (moveType && moveType.PARAMS) tariff.DYNAMIC_FIELDS = this.buildDynamicFields(moveType.PARAMS);
                    this.generateConcatenatedTariff(index);
                }
            }
            angular.element(document).ready(() => {
                this.addFocusEventOnParam();
            });
            this.initPanels();
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        this.setCopy(false);
        return {
            route: `/tariffFreight/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 30000
        };
    }

    private hasHiddenPendingFields(): boolean {

        let hasPendingFields = false;

        const hiddenRoutes = this.$scope.model.TARIFF_FREIGHT.filter(tariffFreight => !tariffFreight.SHOW);
        if (hiddenRoutes && hiddenRoutes.length) {
            hasPendingFields = hiddenRoutes.some(route => {
                const isAirServiceRequired = this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID != '2' && (this.$scope.model.PRODUCT.ID == EProductId.AIR_IMPORT || this.$scope.model.PRODUCT.ID == EProductId.AIR_EXPORT);
                const isMaritimeServiceRequired = this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID != '2' && (this.$scope.model.PRODUCT.ID == EProductId.MARITIME_IMPORT || this.$scope.model.PRODUCT.ID == EProductId.MARITIME_EXPORT);
                return isEmptyObject(route.MOVE_TYPE) ||
                    isEmptyObject(route.PROCESS_EVENT_TRANSPORT_MODE) ||
                    (isAirServiceRequired && isEmptyObject(route.AIR_SERVICE)) ||
                    (isMaritimeServiceRequired && isEmptyObject(route.MARITIME_SERVICE)) ||
                    (route.DYNAMIC_FIELDS[0].MANDATORY && isEmptyObject(route.PUP_ROUTING_POINT)) ||
                    (route.DYNAMIC_FIELDS[1].MANDATORY && isEmptyObject(route.OTFS_ROUTING_POINT)) ||
                    (route.DYNAMIC_FIELDS[2].MANDATORY && isEmptyObject(route.POL_AOL_ROUTING_POINT)) ||
                    (route.DYNAMIC_FIELDS[3].MANDATORY && isEmptyObject(route.POD_AOD_ROUTING_POINT)) ||
                    (route.DYNAMIC_FIELDS[4].MANDATORY && isEmptyObject(route.DTFS_ROUTING_POINT)) ||
                    (route.DYNAMIC_FIELDS[5].MANDATORY && isEmptyObject(route.PLD_ROUTING_POINT)) ||
                    isEmptyObject(route.CONCATENATED);
            });
            if (hasPendingFields) this.formService.notifyError('GENERAL.ALL_FIELDS_MANDATORY');
        }

        if (this.$scope.model.COMMODITY && this.$scope.model.COMMODITY.length > 0) {
            hasPendingFields = this.$scope.model.COMMODITY.some(commodity => {
                return !commodity.SECTION && !commodity.CONDITION && !commodity.EXCEPTION
            });
            if (hasPendingFields) this.formService.notifyError(this.formService.getTranslate('PRODUCT.ENTER_CONDITION_OR_EXCEPTION_COMMODITY'));
        }

        hasPendingFields = this.checkRulesForDetDem();

        return hasPendingFields;
    }

    private async editTariffFreight(tariffFreight: ITariffFreightModel, idTariffFreight?: number): Promise<void> {
        let blockedObject = {
            ID: tariffFreight.ID,
            NAME: tariffFreight.CONCATENATED && tariffFreight.CONCATENATED !== '' ? tariffFreight.CONCATENATED : this.gridName + " - " + tariffFreight.ID,
            EMAIL: this.$scope.user['email'],
            FORM_NAME: this.gridName
        };
        this.SSEService.closeEvents();
        this.SSEService.setBlockedObject(blockedObject);
        this.SSEService.initEvents();
        this.SSEService.events.onmessage = async (event) => {
            const parsedData = JSON.parse(event.data);
            if (!parsedData.status) {
                const result = await this.SSEService.generate(parsedData);
                if (result && !result.status) {
                    this.$rootScope.refreshPage();
                    return;
                }
                if (this.$scope.operation !== EOperation.VIEW || tariffFreight.ID !== this.$scope.model.ID) this.fetchData(tariffFreight.ID, EOperation.VIEW, idTariffFreight);
            } else if (this.$scope.operation !== EOperation.EDIT || tariffFreight.ID !== this.$scope.model.ID) {
                this.fetchData(tariffFreight.ID, EOperation.EDIT, idTariffFreight);
            }
        };
    }

    private checkRulesForDetDem(): boolean {
        let hasPendingFields: boolean;

        // Find equipments from D&D on routes.
        let equipOnDetDemOrigin: ISelectorModel[] = [];
        let equipOnDetDemDestination: ISelectorModel[] = [];

        const detDemOrigin = this.$scope.model.PRODUCT.ID == EProductId.MARITIME_EXPORT && this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.FCL && this.$scope.model.PAYMENT_NATURE.ID != EPaymentNatureId.RECEIVING
        const detDemdDestination = this.$scope.model.PRODUCT.ID == EProductId.MARITIME_IMPORT && this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.FCL && this.$scope.model.PAYMENT_NATURE.ID != EPaymentNatureId.RECEIVING
        
        if (this.$scope.model.DET_DEM_ORIGIN && this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME && this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME.length > 0) {
            this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME.forEach(equipFreeTime => {
                if (equipFreeTime && equipFreeTime.EQUIPMENT.length > 0) {
                    equipFreeTime.EQUIPMENT.forEach(equip => {
                        const equipItem: ISelectorModel = {
                            ID: equip.ID,
                            NAME: equip.NAME,
                        };
                        equipOnDetDemOrigin.push(equipItem);
                    });
                }
            });
        }

        if (this.$scope.model.DET_DEM_DESTINATION && this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME && this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME.length > 0) {
            this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME.forEach(equipFreeTime => {
                if (equipFreeTime && equipFreeTime.EQUIPMENT.length > 0) {
                    equipFreeTime.EQUIPMENT.forEach(equip => {
                        const equipItem: ISelectorModel = {
                            ID: equip.ID,
                            NAME: equip.NAME,
                        };
                        equipOnDetDemDestination.push(equipItem);
                    });
                }
            });
        }

        if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length > 0 && equipOnDetDemOrigin && equipOnDetDemOrigin.length > 0) {
            this.$scope.model.TARIFF_FREIGHT.forEach(route => {

                // Check all equipments for routes: Origin
                if (route && route.DET_DEM_ORIGIN && route.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME && route.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME.length > 0) {
                    route.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME.forEach(equipFreeTime => {
                        if (equipFreeTime && equipFreeTime.EQUIPMENT.length > 0) {
                            equipFreeTime.EQUIPMENT.forEach(equip => {
                                const foundEquip = equipOnDetDemOrigin.find(x => x.ID == equip.ID);
                                if (!foundEquip && detDemOrigin) {
                                    hasPendingFields = true;
                                    const msg = `Equipment ${equip.NAME} found in D&D origin, but not found on route. Missing route: ${route.CONCATENATED}`;
                                    this.formService.notifyError(msg);
                                }
                            });
                        }
                    });
                }

                // Check all equipments for routes: Destination
                if (route && route.DET_DEM_DESTINATION && route.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME && route.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME.length > 0) {
                    route.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME.forEach(equipFreeTime => {
                        if (equipFreeTime && equipFreeTime.EQUIPMENT.length > 0) {
                            equipFreeTime.EQUIPMENT.forEach(equip => {
                                const foundEquip = equipOnDetDemDestination.find(x => x.ID == equip.ID);
                                if (!foundEquip && detDemdDestination) {
                                    hasPendingFields = true;
                                    const msg = `Equipment ${equip.NAME} found in D&D destination, but not found on route. Missing route: ${route.CONCATENATED}`;
                                    this.formService.notifyError(msg);
                                }
                            });
                        }
                    });
                }
            });
        }

        // Validations for DetDem based on selected product.
        if (detDemdDestination) {

            if (!this.$scope.model.DET_DEM_DESTINATION) {
                hasPendingFields = true;
                const msg = "Please inform Detention & Demurrage for Destination.";
                this.formService.notifyError(msg);
            }

            if (this.$scope.model.DET_DEM_DESTINATION && (!this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME || this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME.length == 0)) {
                hasPendingFields = true;
                const msg = "Please inform at least one equipment for Detention & Demurrage at Destination panel.";
                this.formService.notifyError(msg);
            }
        }

        if (detDemOrigin) {
            if (!this.$scope.model.DET_DEM_ORIGIN) {
                hasPendingFields = true;
                const msg = "Please inform Detention & Demurrage for Origin.";
                this.formService.notifyError(msg);
            }

            if (this.$scope.model.DET_DEM_ORIGIN && (!this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME || this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME.length == 0)) {
                hasPendingFields = true;
                const msg = "Please inform at least one equipment for Detention & Demurrage at Origin panel.";
                this.formService.notifyError(msg);
            }
        }

        if (this.$scope.model.PRODUCT.ID == EProductId.MARITIME_IMPORT && this.$scope.model.DET_DEM_DESTINATION && this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME.length > 0) {
            let msg = '';
            for (const item of this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME) {
                if (this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE.ID == ETariffTypeDetDem.COMBINED && !item.COMBINED_DET_DEM_HOUSE_FREE_TIME) {
                    hasPendingFields = true;
                    msg = "Please inform a Free Time number for the House at Destination panel.";
                }
                if (this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE.ID == ETariffTypeDetDem.DET_DEM && (!item.SPLITTED_DEMURRAGE_HOUSE_FREE_TIME || !item.SPLITTED_DETENTION_HOUSE_FREE_TIME)) {
                    hasPendingFields = true;
                    msg = "Please inform a Free Time number for the House at Destination panel.";
                }
            }
            this.formService.notifyError(msg);
        }

        if (this.$scope.model.PRODUCT.ID == EProductId.MARITIME_EXPORT && this.$scope.model.DET_DEM_ORIGIN && this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME.length > 0) {
            let msg = '';
            for (const item of this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME) {
                if (this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE.ID == ETariffTypeDetDem.COMBINED && !item.COMBINED_DET_DEM_HOUSE_FREE_TIME) {
                    hasPendingFields = true;
                    msg = "Please inform a Free Time number for the House at Destination panel.";
                }
                if (this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE.ID == ETariffTypeDetDem.DET_DEM && (!item.SPLITTED_DEMURRAGE_HOUSE_FREE_TIME || !item.SPLITTED_DETENTION_HOUSE_FREE_TIME)) {
                    hasPendingFields = true;
                    msg = "Please inform a Free Time number for the House at Destination panel.";
                }
            }
            this.formService.notifyError(msg);
        }

        return hasPendingFields;
    }

    private initDetDem() {
        if (this.$scope.model.TYPE_CARGO.ID == ECargoTypeId.FCL) {
            if (this.$scope.model.PRODUCT.ID == EProductId.MARITIME_IMPORT) {
                this.$scope.hasDetDemDestination = true;
                this.$scope.model.DET_DEM_DESTINATION = null;
                this.detDemDestinationChange();
            }

            if (this.$scope.model.PRODUCT.ID == EProductId.MARITIME_EXPORT) {
                this.$scope.hasDetDemOrigin = true;
                this.$scope.model.DET_DEM_ORIGIN = null;
                this.detDemOriginChange();
            }

            this.collapseHeader("collapseDetDem", "show", false);
        }
    }

    private async detDemOriginChange() {
        try {
            const perDiemTypeTable = this.$scope.perDiemTypeList.find(x => x.ID == ETariffFreightPerDiemType.TABLE);
            if (this.$scope.hasDetDemOrigin) {
                this.$scope.model.DET_DEM_ORIGIN = {
                    DET_DEM_TYPE: null,
                    PER_DIEM_TYPE: null,
                    FREE_TIME_TYPE: null,
                    FIXED_ADDITIONAL: null,
                    PERCENTAGE_ADDITIONAL: null,
                    TABLE_PER_DIEM: null,
                    EQUIPMENT_FREE_TIME: null
                };

                if (this.$scope.model.PRODUCT.ID == EProductId.MARITIME_EXPORT && this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.PAYMENT) {
                    this.$scope.model.DET_DEM_ORIGIN.PER_DIEM_TYPE = perDiemTypeTable;
                }
            } else {
                const msg = "Tem certeza que deseja remover os dados de Detention e Demurrage da Origem?";
                const modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: "GENERAL.CONFIRM",
                    closeButtonText: "GENERAL.NO",
                    headerText: "GENERAL.CONFIRM_ACTION",
                    bodyText: msg
                });
                this.$scope.hasDetDemOrigin = !modal;
                if (!modal) return;
                this.$scope.model.DET_DEM_ORIGIN = null;
            }

            if (this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE && this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE.ID == ETariffTypeDetDem.COMBINED) {
                this.$timeout(() => {
                    this.$scope.setDirtyFields([`combinedDetDemHouseFreeTimeOrigin`]);
                });
            }

            if (this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE && this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE.ID == ETariffTypeDetDem.DET_DEM) {
                this.$timeout(() => {
                    this.$scope.setDirtyFields([`splittedDetentionHouseFreeTimeOrigin`, `splittedDemurrageHouseFreeTimeOrigin`]);
                });
            }

            this.$timeout(() => {
                this.$scope.setDirtyFields([`det_dem_fixed_additional_origin`, `det_dem_percentage_additional_origin`]);
                this.$scope.selectorValidity(`det_dem_type_origin`);
                this.$scope.selectorValidity(`per_diem_type_origin`);
                this.$scope.selectorValidity(`free_time_type_origin`);
                this.$scope.selectorValidity(`det_dem_tablePerDiem_origin`);
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async detDemDestinationChange() {
        const perDiemTypeTable = this.$scope.perDiemTypeList.find(x => x.ID == ETariffFreightPerDiemType.TABLE);
        if (this.$scope.hasDetDemDestination) {
            this.$scope.model.DET_DEM_DESTINATION = {
                DET_DEM_TYPE: null,
                PER_DIEM_TYPE: null,
                FREE_TIME_TYPE: null,
                FIXED_ADDITIONAL: null,
                PERCENTAGE_ADDITIONAL: null,
                TABLE_PER_DIEM: null,
                EQUIPMENT_FREE_TIME: null
            };

            if (this.$scope.model.PRODUCT.ID == EProductId.MARITIME_IMPORT && this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.PAYMENT) {
                this.$scope.model.DET_DEM_DESTINATION.PER_DIEM_TYPE = perDiemTypeTable;
            }
        } else {
            const msg = "Tem certeza que deseja remover os dados de Detention e Demurrage do Destino?";
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: msg
            });

            this.$scope.hasDetDemDestination = !modal;
            if (!modal) return;
            this.$scope.model.DET_DEM_DESTINATION = null;
        }

        if (this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE && this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE.ID == ETariffTypeDetDem.COMBINED) {
            this.$timeout(() => {
                this.$scope.setDirtyFields([`combinedDetDemHouseFreeTimeDestination`]);
            });
        }
        if (this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE && this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE.ID == ETariffTypeDetDem.DET_DEM) {
            this.$timeout(() => {
                this.$scope.setDirtyFields([`splittedDetentionHouseFreeTimeDestination`, `splittedDemurrageHouseFreeTimeDestination`]);
            });
        }

        this.$timeout(() => {
            this.$scope.setDirtyFields([`det_dem_fixed_additional_destination`, `det_dem_percentage_additional_destination`]);
            this.$scope.selectorValidity(`det_dem_type_destination`);
            this.$scope.selectorValidity(`per_diem_type_destination`);
            this.$scope.selectorValidity(`free_time_type_destination`);
            this.$scope.selectorValidity(`det_dem_tablePerDiem_destination`);
        }, 100);
    }

    private initFreeTimeTable(originDestination: number): void {
        const freeTimeItem: IEquipmentFreeTime = {
            COMBINED_DET_DEM_HOUSE_FREE_TIME: null,
            COMBINED_DET_DEM_MASTER_FREE_TIME: null,
            EQUIPMENT: [],
            SPLITTED_DEMURRAGE_HOUSE_FREE_TIME: null,
            SPLITTED_DEMURRAGE_MASTER_FREE_TIME: null,
            SPLITTED_DETENTION_HOUSE_FREE_TIME: null,
            SPLITTED_DETENTION_MASTER_FREE_TIME: null
        };

        if (originDestination == 1) {
            if (this.$scope.model.DET_DEM_ORIGIN && !this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME) {
                this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME = [];
                this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME.push(freeTimeItem);

                if (this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE.ID == ETariffTypeDetDem.COMBINED) {
                    this.$timeout(() => {
                        this.$scope.setDirtyFields([`combinedDetDemHouseFreeTimeOrigin`]);
                    });

                }

                if (this.$scope.model.DET_DEM_ORIGIN.DET_DEM_TYPE.ID == ETariffTypeDetDem.DET_DEM) {
                    this.$timeout(() => {
                        this.$scope.setDirtyFields([`splittedDetentionHouseFreeTimeOrigin`, `splittedDemurrageHouseFreeTimeOrigin`]);
                    });
                }
            }
        } else if (originDestination == 2) {
            if (this.$scope.model.DET_DEM_DESTINATION && !this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME) {
                this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME = [];
                this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME.push(freeTimeItem);
            }

            if (this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE.ID == ETariffTypeDetDem.COMBINED) {
                this.$timeout(() => {
                    this.$scope.setDirtyFields([`combinedDetDemHouseFreeTimeDestination`]);
                });
            }

            if (this.$scope.model.DET_DEM_DESTINATION.DET_DEM_TYPE.ID == ETariffTypeDetDem.DET_DEM) {
                this.$timeout(() => {
                    this.$scope.setDirtyFields([`splittedDetentionHouseFreeTimeDestination`, `splittedDemurrageHouseFreeTimeDestination`]);
                });
            }
        }
    }

    private initRouteFreeTimeTable(originDestination: number, parentIndex: number): void {

        const freeTimeItem: IEquipmentFreeTime = {
            COMBINED_DET_DEM_HOUSE_FREE_TIME: null,
            COMBINED_DET_DEM_MASTER_FREE_TIME: null,
            EQUIPMENT: [],
            SPLITTED_DEMURRAGE_HOUSE_FREE_TIME: null,
            SPLITTED_DEMURRAGE_MASTER_FREE_TIME: null,
            SPLITTED_DETENTION_HOUSE_FREE_TIME: null,
            SPLITTED_DETENTION_MASTER_FREE_TIME: null
        };

        if (originDestination == 1) {
            if (this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN && this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME.length == 0) {
                this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME.push(freeTimeItem);
            }

            if (this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.DET_DEM_TYPE.ID == ETariffTypeDetDem.COMBINED) {
                this.$timeout(() => {
                    this.$scope.setDirtyFields([`routeCombinedDetDemHouseFreeTimeOrigin${parentIndex}${parentIndex}`]);
                }, 100);
            }

            if (this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.DET_DEM_TYPE.ID == ETariffTypeDetDem.DET_DEM) {
                this.$timeout(() => {
                    this.$scope.setDirtyFields([`splittedDetentionHouseFreeTimeOrigin${parentIndex}${parentIndex}`, `splittedDemurrageHouseFreeTimeOrigin${parentIndex}${parentIndex}`]);
                }, 100);
            }
            
        } else if (originDestination == 2) {
            if (this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION && this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME.length == 0) {
                this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME.push(freeTimeItem);
            }

            if (this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.DET_DEM_TYPE.ID == ETariffTypeDetDem.COMBINED) {
                this.$timeout(() => {
                    this.$scope.setDirtyFields([`routeCombinedDetDemHouseFreeTimeDestination${parentIndex}${parentIndex}`]);
                }, 100);
            }

            if (this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.DET_DEM_TYPE.ID == ETariffTypeDetDem.DET_DEM) {
                this.$timeout(() => {
                    this.$scope.setDirtyFields([`splittedDetentionHouseFreeTimeDestination${parentIndex}${parentIndex}`, `splittedDemurrageHouseFreeTimeDestination${parentIndex}${parentIndex}`]);
                }, 100);
                
            }
            
        }
    }

    private checkReadOnlyRoute(parentIndex: number, originDestination: number, perDiemTypeId: string): boolean {
        let readOnly = false;
        try {
            if (originDestination == 1) {
                if (!this.$scope.model.PAYMENT_NATURE || !this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN || !this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.PER_DIEM_TYPE) {
                    readOnly = true;
                }

                if (this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.RECEIVING && this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN
                    && this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.PER_DIEM_TYPE && this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.PER_DIEM_TYPE.ID
                    && this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.PER_DIEM_TYPE.ID != perDiemTypeId) {
                    readOnly = true;
                }
            } else if (originDestination == 2) {
                if (!this.$scope.model.PAYMENT_NATURE || !this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION || !this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.PER_DIEM_TYPE) {
                    readOnly = true;
                }

                if (this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.RECEIVING && this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION
                    && this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.PER_DIEM_TYPE && this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.PER_DIEM_TYPE.ID
                    && this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.PER_DIEM_TYPE.ID != perDiemTypeId) {
                    readOnly = true;
                }
            }
            if (this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.PAYMENT) {
                readOnly = true;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
        return readOnly;
    }

    private checkReadOnly(originDestination: number, perDiemTypeId: string): boolean {
        let readOnly = false;

        try {
            if (this.$scope.model) {
                if (originDestination == 1) {
                    if (!this.$scope.model.PAYMENT_NATURE || !this.$scope.model.DET_DEM_ORIGIN || !this.$scope.model.DET_DEM_ORIGIN.PER_DIEM_TYPE) {
                        readOnly = true;
                    }

                    if (this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.RECEIVING && this.$scope.model.DET_DEM_ORIGIN
                        && this.$scope.model.DET_DEM_ORIGIN.PER_DIEM_TYPE && this.$scope.model.DET_DEM_ORIGIN.PER_DIEM_TYPE.ID
                        && this.$scope.model.DET_DEM_ORIGIN.PER_DIEM_TYPE.ID != perDiemTypeId) {
                        readOnly = true;
                    }
                } else if (originDestination == 2) {
                    if (!this.$scope.model.PAYMENT_NATURE || !this.$scope.model.DET_DEM_DESTINATION || !this.$scope.model.DET_DEM_DESTINATION.PER_DIEM_TYPE) {
                        readOnly = true;
                    }

                    if (this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.RECEIVING && this.$scope.model.DET_DEM_DESTINATION
                        && this.$scope.model.DET_DEM_DESTINATION.PER_DIEM_TYPE && this.$scope.model.DET_DEM_DESTINATION.PER_DIEM_TYPE.ID
                        && this.$scope.model.DET_DEM_DESTINATION.PER_DIEM_TYPE.ID != perDiemTypeId) {
                        readOnly = true;
                    }
                }
                if (this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.PAYMENT) {
                    readOnly = true;
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
        return readOnly;
    }

    private formatedScoreDate(): string {
        try {
            if (this.$scope.model.SCORE_DATE) {
                return moment(this.$scope.model.SCORE_DATE).format('DD/MM/YYYY HH:mm:ss');
            }
            return null;
        } catch (ex) {
            this.handleError(ex);
            return null;
        }
    }

    private async callSessionFunctions(data: object): Promise<void> {
        try {
            if (!data) return;
            const tariffFreightExchangeData = <ITariffFreightExchangeData>data;
            let tariffFreightModel: ITariffFreightModel = null;
            for (const gridData of this.$scope.gridOptions.data) {
                if (tariffFreightExchangeData.ID != null && gridData.ID == tariffFreightExchangeData.ID) {
                    tariffFreightModel = gridData;
                    break;
                }
            }
            switch (tariffFreightExchangeData.OPERATION) {
                case EOperation.VIEW: if (tariffFreightModel) this.$scope.viewTariffFreight(tariffFreightModel, tariffFreightExchangeData.ID_TARIFF);
                    break;
                case EOperation.EDIT: {
                    if (tariffFreightModel) {
                        await this.editTariffFreight(tariffFreightModel, tariffFreightExchangeData.ID_TARIFF);
                    }
                    break;
                }
                case EOperation.NEW: {
                    if (!tariffFreightExchangeData.ID) this.$rootScope.breadCrumb.action();
                    else await this.copyTariffFromHistory(parseInt(tariffFreightExchangeData.ID));
                    break;
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getPaymentNatureList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/payment_nature`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getContractTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/contract_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getEventList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/event`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getSituationList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/registration_situation`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getCargoTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_cargo`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getHsCodeSectionList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/hscode_section`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getProcessEventTransportModeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/process_event_transport_mode`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getSpecificityList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/specificity`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getVehicleTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/vehicle_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTypeTariffList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/dd_type_tariff`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTariffFreightPerDiemTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/tariff_freight_per_diem_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTariffFreightFreeTimeTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/tariff_freight_free_time_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getMoveTypeList(): Promise<IMoveTypeModel[]> {
        const result = await this.RestService.getObjectAsPromise(`${this.$baseUrl}/moveType/list/full`, 30000, null, false);
        return result ? result.data : [];
    }

    private async getProviderListByName(search: string): Promise<void> {
        let result: IProviderSelector[] = [];
        try {
            if (search && search.length >= 3) {

                if (!this.$scope.model.PRODUCT || !this.$scope.model.PRODUCT.ID) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                this.formService.block();

                let types = [];
                if (this.$scope.model.PRODUCT.ID == "IM" || this.$scope.model.PRODUCT.ID == "EM") {
                    types = ['1'];
                } else if (this.$scope.model.PRODUCT.ID == "IA" || this.$scope.model.PRODUCT.ID == "EA") {
                    types = ['2'];
                } else {
                    types = ['3'];
                }

                const products = [this.$scope.model.PRODUCT.ID];
                const providers = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/provider/list/custom/`, { search, products, types }, 30000, false);
                if (providers && providers.length > 0) {
                    result = providers.map(provider => { return { ID: provider.ID, NAME: provider.NAME, CODE: provider.SCAC_IATA, TYPE: provider.TYPE } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.providerList = result
            this.formService.unblock();
        }
    }

    private async getAccountListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 3) {
                this.formService.block();
                const accounts = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/account/list/custom/`, { search: search }, 30000, false);
                if (accounts && accounts.length > 0) {
                    result = accounts.map(account => { return { ID: account.ID, NAME: account.NAME, CODE: account.LEGAL_PERSON.SHORT_NAME } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.accountList = result
            this.formService.unblock();
        }
    }

    private async getNetworkListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 3) {
                this.formService.block();
                const networks = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/network/listByLegalPersonSpecType`, { name: search, types: ['12'] }, 30000, false);
                if (networks && networks.length > 0) {
                    result = networks.map(account => { return { ID: account.ID, NAME: account.NAME } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.networkList = result
            this.formService.unblock();
        }
    }

    private async getCommodityListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();
                const commodities = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/commodity/list/custom/`, { name: search }, 30000, false);
                if (commodities) result = commodities.map(commodity => { return { ID: commodity.ID, NAME: commodity.NAME, CODE: commodity.HS_CODE } });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.commodityList = result
            this.formService.unblock();
        }
    }

    private async getRoutingPointListByName(name: string, types: string[]): Promise<void> {
        let result: IRoutingPointSelector[] = [];
        try {
            if (name && name.length >= 2) {
                this.formService.block();
                const routingPoints = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/routingPoint/list/custom/`, { name, types }, 30000, false);
                if (routingPoints && routingPoints.length > 0) {
                    result = routingPoints.map(routingPoint => { return { ID: routingPoint.ID, NAME: routingPoint.NAME, CODE: routingPoint.CODE, DISPLAY_NAME: routingPoint.DISPLAY_NAME } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.routingPointList = result
            this.formService.unblock();
        }
    }

    private async getChargeNameListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 2) {

                if (!this.$scope.model.PRODUCT) { throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST")) }
                this.formService.block();

                let types = ['1'];
                const products = this.$scope.model.PRODUCT ? [this.$scope.model.PRODUCT.ID] : [];
                const paramTypeCargo = this.$scope.model.TYPE_CARGO ? [this.$scope.model.TYPE_CARGO.ID] : [];
                const chargeNames = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/chargeName/list/custom/exhibition`, { search, types, products, paramTypeCargo }, 30000, false);
                result = chargeNames ? chargeNames.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PARAMS: x.PARAMS } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.chargeNameList = result;
        }
    }

    private async getEquipmentListByName(search: string): Promise<void> {
        let result: IEquipmentSelector[] = [];
        try {
            if (search && search.length >= 2) {
                if (!this.$scope.model.PRODUCT) {
                    throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                }

                this.formService.block();

                const products = [this.$scope.model.PRODUCT.ID];
                const equipmentList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/equipment/list/custom`, { search, products }, 30000, false);
                result = equipmentList ? equipmentList.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, TEU: x.TEU, FEET: x.FEET } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.equipmentList = result;
        }
    }

    private async getEquipmentListForDetDem(search: string): Promise<void> {
        let result: IEquipmentSelector[] = [];
        try {
            if (search && search.length >= 2) {
                if (!this.$scope.model.PRODUCT) {
                    throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                }

                this.formService.block();

                const products = [this.$scope.model.PRODUCT.ID];
                const equipmentList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/equipment/list/custom`, { search, products }, 30000, false);
                result = equipmentList ? equipmentList.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, TEU: x.TEU, FEET: x.FEET } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.equipmentFreeTimeList = result;
        }
    }

    private async getMoveTypeByProduct(): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (!this.$scope.model.PRODUCT) { throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST")) }
            this.formService.block();

            const products = this.$scope.model.PRODUCT ? [this.$scope.model.PRODUCT.ID] : [];
            const moveTypeList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/moveType/list/custom`, { products }, 30000, false);
            result = moveTypeList ? moveTypeList.map(x => { return { ID: x.ID.toString(), NAME: x.NAME, CODE: x.CODE } }) : [];
            result.sort(function (a, b) { return (a.NAME > b.NAME) ? 1 : ((b.NAME > a.NAME) ? -1 : 0) });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.moveTypeList = result;
        }
    }

    private async getWeightRangeListByName(search: string, callWithoutSearch?: boolean): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            if (callWithoutSearch || (search && search.length >= 2)) {
                this.formService.block();
                const products = this.$scope.model.PRODUCT ? [this.$scope.model.PRODUCT.ID] : [];
                const weightRangeList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/weightRange/list/custom`, { search, products }, 30000, false);
                result = weightRangeList ? weightRangeList.map(x => { return { ID: x.ID, NAME: x.NAME } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCurrencyListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const currencies = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/currency/list/custom`, { name: search }, 10000, false);
                result = (currencies) ? currencies.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.currencyList = result;
        }
    }

    private async getAirServiceListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 2) {
                if (!this.$scope.model.PRODUCT) { throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST")) }
                if (!this.$scope.model.PROVIDER) { throw Error(this.formService.getTranslate('PRODUCT.SELECT_PROVIDER_FIRST')) }

                this.formService.block();
                const filter: IAirServiceListCustomFilter = { search: search, products: this.$scope.model.PRODUCT ? [this.$scope.model.PRODUCT.ID] : null, providers: this.$scope.model.PROVIDER ? [parseInt(this.$scope.model.PROVIDER.ID)] : null };
                const airServices = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/airService/list/custom/`, filter, 30000, false);
                if (airServices && airServices.length > 0) {
                    result = airServices.map(airService => { return { ID: airService.ID, NAME: airService.LOGISTIC_SOLUTION } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.airServiceList = result
            this.formService.unblock();
        }
    }

    private async getMaritimeServiceListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 2) {
                if (!this.$scope.model.PRODUCT) { throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST")) }
                if (!this.$scope.model.PROVIDER) { throw Error(this.formService.getTranslate('PRODUCT.SELECT_PROVIDER_FIRST')) }

                this.formService.block();

                const product = [this.$scope.model.PRODUCT.ID];
                const provider = [this.$scope.model.PROVIDER.ID];
                const maritimeServices = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/maritimeService/list/custom/`, { search, products: product, providers: provider }, 30000, false);
                if (maritimeServices && maritimeServices.length > 0) {
                    result = maritimeServices.map(maritimeService => { return { ID: maritimeService.ID, NAME: maritimeService.LOGISTIC_SOLUTION } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.maritimeServiceList = result
            this.formService.unblock();
        }
    }

    private async getServiceLevelListByName(search?: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.formService.block();
            const servicesLevel = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/serviceLevel/list/custom/`, { search }, 30000, false);
            if (servicesLevel && servicesLevel.length > 0) {
                result = servicesLevel.map(serviceLevel => { return { ID: serviceLevel.ID, NAME: serviceLevel.DISPLAY_NAME } });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getServiceTypeListByName(search?: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            if (!this.$scope.model.PRODUCT) return result;
            else {
                this.formService.block();
                const products = [this.$scope.model.PRODUCT.ID];
                const serviceTypeList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/serviceType/list/custom`, { search, products }, 30000, false);
                result = serviceTypeList ? serviceTypeList.map(x => { return { ID: x.ID, NAME: x.NAME } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getFreightContractListByName(name?: string): Promise<void> {
        let result: SelectorModel[] = [];
        this.formService.block();
        try {
            if (name && name.length >= 3) {
                const contracts = await this.RestService.newObjectPromise(`${this.$baseUrl}/freightContract/listByFullName`, { name }, 30000, false);
                if (contracts) result = contracts.map(contract => { return { ID: contract.ID, NAME: contract.NAME, CODE: contract.CODE } });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.freightContractList = result;
        }
    }

    private async getTablePerDiemList(direction: string, tariffTypeId: string): Promise<void> {
        let result: SelectorModel[] = [];
        this.formService.block();
        try {
            if (this.$scope.model.PAYMENT_NATURE) {
                const getDefault: boolean = this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.RECEIVING ? false : null;
                const filterEquipmentProvider: object = this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.PAYMENT ? { ID_EQUIPMENT_PROVIDER: { $eq: null } } : null;
                const data: ITablePerDiemQueryFields = {
                    TRANSACTION: this.$scope.model.PAYMENT_NATURE.ID,
                    DIRECTION: direction,
                    PROVIDER: this.$scope.model.PROVIDER ? [parseInt(this.$scope.model.PROVIDER.ID)] : null,
                    COUNTRY_CODE: 'BR',
                    ID_TABLE_TYPE: tariffTypeId,
                    ...filterEquipmentProvider,
                    ACTIVE: true,
                    DEFAULT: getDefault,
                };

                const tablesPerDiem = await this.RestService.newObjectPromise(`${this.$baseUrl}/tablePerDiem/listByFullName`, { data }, 30000, false);
                if (tablesPerDiem) result = tablesPerDiem.map(tablePerDiem => { return { ID: tablePerDiem.ID, NAME: tablePerDiem.CONCATENATED, CODE: null } });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.tablePerDiemList = result;
        }
    }

    private addEquipmentFreeTime(originDestination: number): void {
        try {

            let modelProperty = '';
            if (originDestination == 1) modelProperty = 'DET_DEM_ORIGIN';
            if (originDestination == 2) modelProperty = 'DET_DEM_DESTINATION';

            const msg = 'Select Tariff Type first.';
            if (!this.$scope.model[`${modelProperty}`] || !this.$scope.model[`${modelProperty}`].DET_DEM_TYPE) return this.formService.notifyError(msg);

            const equipmentDetDemFreeTime: IEquipmentFreeTime = {
                EQUIPMENT: null,
                COMBINED_DET_DEM_HOUSE_FREE_TIME: null,
                COMBINED_DET_DEM_MASTER_FREE_TIME: null,
                SPLITTED_DEMURRAGE_HOUSE_FREE_TIME: null,
                SPLITTED_DEMURRAGE_MASTER_FREE_TIME: null,
                SPLITTED_DETENTION_HOUSE_FREE_TIME: null,
                SPLITTED_DETENTION_MASTER_FREE_TIME: null,
            };

            if (!this.$scope.model[`${modelProperty}`].EQUIPMENT_FREE_TIME) this.$scope.model[`${modelProperty}`].EQUIPMENT_FREE_TIME = [];
            this.$scope.model[`${modelProperty}`].EQUIPMENT_FREE_TIME.push(equipmentDetDemFreeTime);

            if (originDestination == 1 && this.$scope.model[`${modelProperty}`].DET_DEM_TYPE.ID == ETariffTypeDetDem.COMBINED) {
                this.$timeout(() => {
                    this.$scope.setDirtyFields([`combinedDetDemHouseFreeTimeOrigin`]);
                }, 100);
            }

            if (originDestination == 1 && this.$scope.model[`${modelProperty}`].DET_DEM_TYPE.ID == ETariffTypeDetDem.DET_DEM) {
                this.$timeout(() => {
                    this.$scope.setDirtyFields([`splittedDetentionHouseFreeTimeOrigin`, `splittedDemurrageHouseFreeTimeOrigin`]);
                }, 100);
            }

            if (originDestination == 2 && this.$scope.model[`${modelProperty}`].DET_DEM_TYPE.ID == ETariffTypeDetDem.COMBINED) {
                this.$timeout(() => {
                    this.$scope.setDirtyFields([`combinedDetDemHouseFreeTimeDestination`]);
                }, 100);
            }

            if (originDestination == 2 && this.$scope.model[`${modelProperty}`].DET_DEM_TYPE.ID == ETariffTypeDetDem.DET_DEM) {
                this.$timeout(() => {
                    this.$scope.setDirtyFields([`splittedDetentionHouseFreeTimeDestination`, `splittedDemurrageHouseFreeTimeDestination`]);
                }, 100);
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private addRouteEquipmentFreeTime(index: number, originDestination: number): void {
        try {
            if (!index && index !== 0) throw Error('index is null');
            if (index >= 0) {
                const msg = 'Select Tariff Type first.';
                if (originDestination == 1 && (!this.$scope.model.TARIFF_FREIGHT[index].DET_DEM_ORIGIN || !this.$scope.model.TARIFF_FREIGHT[index].DET_DEM_ORIGIN.DET_DEM_TYPE)) return this.formService.notifyError(msg);
                if (originDestination == 2 && (!this.$scope.model.TARIFF_FREIGHT[index].DET_DEM_DESTINATION || !this.$scope.model.TARIFF_FREIGHT[index].DET_DEM_DESTINATION.DET_DEM_TYPE)) return this.formService.notifyError(msg);

                const equipmentDetDemFreeTime: IEquipmentFreeTime = {
                    EQUIPMENT: null,
                    COMBINED_DET_DEM_HOUSE_FREE_TIME: null,
                    COMBINED_DET_DEM_MASTER_FREE_TIME: null,
                    SPLITTED_DEMURRAGE_HOUSE_FREE_TIME: null,
                    SPLITTED_DEMURRAGE_MASTER_FREE_TIME: null,
                    SPLITTED_DETENTION_HOUSE_FREE_TIME: null,
                    SPLITTED_DETENTION_MASTER_FREE_TIME: null,
                };

                if (originDestination == 1) {
                    if (!this.$scope.model.TARIFF_FREIGHT[index].DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME) this.$scope.model.TARIFF_FREIGHT[index].DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME = [];
                    this.$scope.model.TARIFF_FREIGHT[index].DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME.push(equipmentDetDemFreeTime);
                } else if (originDestination == 2) {
                    if (!this.$scope.model.TARIFF_FREIGHT[index].DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME) this.$scope.model.TARIFF_FREIGHT[index].DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME = [];
                    this.$scope.model.TARIFF_FREIGHT[index].DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME.push(equipmentDetDemFreeTime);
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private checkRouteFreeTimeSplittedDetention(transactionId: string, originDestination: number, parentIndex: number, index: number): void {
        let master: number;
        let house: number;
        if (transactionId == EPaymentNatureId.PAYMENT) {
            if (originDestination == 1) {
                master = this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_MASTER_FREE_TIME;
                house = this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_HOUSE_FREE_TIME;
            } else if (originDestination == 2) {
                master = this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_MASTER_FREE_TIME;
                house = this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_HOUSE_FREE_TIME;
            }

            if (house > master) {
                if (originDestination == 1) {
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_HOUSE_FREE_TIME = null;
                } else if (originDestination == 2) {
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_HOUSE_FREE_TIME = null;
                }
                const msg = "Não é possível informar um valor maior para o House no Free Time de Splitted Detention.";
                this.formService.notifyError(msg);
            }
        }
    }

    private checkRouteFreeTimeSplittedDemurrage(transactionId: string, originDestination: number, parentIndex: number, index: number): void {
        let master: number;
        let house: number;
        if (transactionId == EPaymentNatureId.PAYMENT) {
            if (originDestination == 1) {
                master = this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_MASTER_FREE_TIME;
                house = this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_HOUSE_FREE_TIME;
            } else if (originDestination == 2) {
                master = this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_MASTER_FREE_TIME;
                house = this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_HOUSE_FREE_TIME;
            }
            if (house > master) {
                if (originDestination == 1) {
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_HOUSE_FREE_TIME = null;
                } else if (originDestination == 2) {
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_HOUSE_FREE_TIME = null;
                }
                const msg = "Não é possível informar um valor maior para o House no Free Time de Splitted Demurrage.";
                this.formService.notifyError(msg);
            }
        }
    }

    private checkRouteFreeTimeCombined(transactionId: string, originDestination: number, parentIndex: number, index: number): void {
        let master: number;
        let house: number;
        if (transactionId == EPaymentNatureId.PAYMENT) {
            if (originDestination == 1) {
                master = this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_MASTER_FREE_TIME;
                house = this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_HOUSE_FREE_TIME;
            } else if (originDestination == 2) {
                master = this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_MASTER_FREE_TIME;
                house = this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_HOUSE_FREE_TIME;
            }

            if (house > master) {
                const msg = "Não é possível informar um valor maior para o House no Free Time Combined.";

                this.formService.notifyError(msg);
                if (originDestination == 1) {
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_HOUSE_FREE_TIME = null;
                } else if (originDestination == 2) {
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_HOUSE_FREE_TIME = null;
                }
            }
        }
    }


    private checkFreeTimeSplittedDetention(transactionId: string, originDestination: number, index: number): void {
        let master: number;
        let house: number;
        if (transactionId == EPaymentNatureId.PAYMENT) {
            if (originDestination == 1) {
                master = this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_MASTER_FREE_TIME;
                house = this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_HOUSE_FREE_TIME;
            } else if (originDestination == 2) {
                master = this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_MASTER_FREE_TIME;
                house = this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_HOUSE_FREE_TIME;
            }

            if (house > master) {
                const msg = "Não é possível informar um valor maior para o House no Free Time de Splitted Detention.";
                this.formService.notifyError(msg);

                if (originDestination == 1) {
                    this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_HOUSE_FREE_TIME = null;
                } else if (originDestination == 2) {
                    this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DETENTION_HOUSE_FREE_TIME = null;
                }
            }
        }
    }

    private checkFreeTimeSplittedDemurrage(transactionId: string, originDestination: number, index: number): void {
        let master: number;
        let house: number;
        if (transactionId == EPaymentNatureId.PAYMENT) {
            if (originDestination == 1) {
                master = this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_MASTER_FREE_TIME;
                house = this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_HOUSE_FREE_TIME;
            } else if (originDestination == 2) {
                master = this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_MASTER_FREE_TIME;
                house = this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_HOUSE_FREE_TIME;
            }
            if (house > master) {
                if (originDestination == 1) {
                    this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_HOUSE_FREE_TIME = null;
                } else if (originDestination == 2) {
                    this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].SPLITTED_DEMURRAGE_HOUSE_FREE_TIME = null;
                }
                const msg = "Não é possível informar um valor maior para o House no Free Time de Splitted Demurrage.";
                this.formService.notifyError(msg);
            }
        }
    }

    private checkFreeTimeCombined(transactionId: string, originDestination: number, index: number): void {
        let master: number;
        let house: number;
        if (transactionId == EPaymentNatureId.PAYMENT) {
            if (originDestination == 1) {
                master = this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_MASTER_FREE_TIME;
                house = this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_HOUSE_FREE_TIME;
            } else if (originDestination == 2) {
                master = this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_MASTER_FREE_TIME;
                house = this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_HOUSE_FREE_TIME;
            }

            if (house > master) {
                if (originDestination == 1) {
                    this.$scope.model.DET_DEM_ORIGIN.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_HOUSE_FREE_TIME = null;
                } else if (originDestination == 2) {
                    this.$scope.model.DET_DEM_DESTINATION.EQUIPMENT_FREE_TIME[index].COMBINED_DET_DEM_HOUSE_FREE_TIME = null;
                }
                const msg = "Não é possível informar um valor maior para o House no Free Time Combined.";
                this.formService.notifyError(msg);
            }
        }
    }

    private addDetDemRoute(parentIndex: number): void {
        const perDiemTypeTable = this.$scope.perDiemTypeList.find(x => x.ID == ETariffFreightPerDiemType.TABLE);
        if (!this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN) {
            this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN = {
                DET_DEM_TYPE: null,
                PER_DIEM_TYPE: null,
                FREE_TIME_TYPE: null,
                FIXED_ADDITIONAL: null,
                PERCENTAGE_ADDITIONAL: null,
                TABLE_PER_DIEM: null,
                EQUIPMENT_FREE_TIME: []
            }
            if (this.$scope.model.DET_DEM_ORIGIN) {
                this.$scope.tablePerDiemDefaultContractOrigin[parentIndex] = true;
                this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.TABLE_PER_DIEM = this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM;
            }
            if (this.$scope.model.PRODUCT.ID == EProductId.MARITIME_EXPORT && this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.PAYMENT) {
                this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.PER_DIEM_TYPE = perDiemTypeTable;
            }
        }

        if (!this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION) {
            this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION = {
                DET_DEM_TYPE: null,
                PER_DIEM_TYPE: null,
                FREE_TIME_TYPE: null,
                FIXED_ADDITIONAL: null,
                PERCENTAGE_ADDITIONAL: null,
                TABLE_PER_DIEM: null,
                EQUIPMENT_FREE_TIME: []
            }

            if (this.$scope.model.DET_DEM_DESTINATION) {
                this.$scope.tablePerDiemDefaultContractDestination[parentIndex] = true;
                this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.TABLE_PER_DIEM = this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM;
            }
            if (this.$scope.model.PRODUCT.ID == EProductId.MARITIME_IMPORT && this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.PAYMENT) {
                this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.PER_DIEM_TYPE = perDiemTypeTable;
            }
        }

        this.collapseHeader("collapseDetDemRoute" + parentIndex, "show");

        let arrayFields: string[] = [];
        arrayFields.push(`route_det_dem_fixed_additional_origin${parentIndex}`);
        arrayFields.push(`route_det_dem_percentage_additional_origin${parentIndex}`);
        arrayFields.push(`route_det_dem_fixed_additional_destination${parentIndex}`);
        arrayFields.push(`route_det_dem_percentage_additional_destination${parentIndex}`);
        this.$timeout(() => {
            this.$scope.setDirtyFields(arrayFields);
        }, 1000);

        this.$timeout(() => {
            this.$scope.selectorValidity(`route_det_dem_type_origin${parentIndex}`);
            this.$scope.selectorValidity(`route_per_diem_type_origin${parentIndex}`);
            this.$scope.selectorValidity(`route_free_time_type_origin${parentIndex}`);
            this.$scope.selectorValidity(`routeTablePerDiemOrigin${parentIndex}`);
        }, 1000);
        this.$timeout(() => {
            this.$scope.selectorValidity(`route_det_dem_type_destination${parentIndex}`);
            this.$scope.selectorValidity(`route_per_diem_type_destination${parentIndex}`);
            this.$scope.selectorValidity(`route_free_time_type_destination${parentIndex}`);
            this.$scope.selectorValidity(`routeTablePerDiemDestination${parentIndex}`);
        }, 1000);
    }

    private async removeDetDemRoute(parentIndex: number): Promise<void> {
        try {
            if (!parentIndex && parentIndex !== 0) throw Error('index is null');
            const msg = "Deseja remover os dados de Detention e Demurrage para essa rota?";
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: msg
            });

            if (!modal) return;

            this.formService.block();
            this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION = null;
            this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN = null;
            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private checkPerDiemTypeChange(originDestination: number): void {
        if (originDestination == 1 && this.$scope.model.DET_DEM_ORIGIN.PER_DIEM_TYPE) {
            switch (this.$scope.model.DET_DEM_ORIGIN.PER_DIEM_TYPE.ID) {
                case '1':
                    this.$scope.model.DET_DEM_ORIGIN.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_ORIGIN.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM = null;
                    break;
                case '2':
                    this.$scope.model.DET_DEM_ORIGIN.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_ORIGIN.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM = null;
                    break;
                case '3':
                    this.$scope.model.DET_DEM_ORIGIN.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM = null;
                    break;
                case '4':
                    this.$scope.model.DET_DEM_ORIGIN.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM = null;
                    break;
                case '5':
                    this.$scope.model.DET_DEM_ORIGIN.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_ORIGIN.PERCENTAGE_ADDITIONAL = null;
                    break;
                default:
                    this.formService.notifyError('No Per Diem Type ID at Route Origin selected.');
                    break;
            }
            this.$timeout(() => {
                this.$scope.setDirtyFields([`det_dem_fixed_additional_origin`, `det_dem_percentage_additional_origin`, `splittedDemurrageHouseFreeTimeOrigin`]);
                this.$scope.selectorValidity(`det_dem_type_origin`);
                this.$scope.selectorValidity(`per_diem_type_origin`);
                this.$scope.selectorValidity(`free_time_type_origin`);
                this.$scope.selectorValidity(`det_dem_tablePerDiem_origin`);
            }, 100);
        } else if (originDestination == 2 && this.$scope.model.DET_DEM_DESTINATION.PER_DIEM_TYPE) {
            switch (this.$scope.model.DET_DEM_DESTINATION.PER_DIEM_TYPE.ID) {
                case '1':
                    this.$scope.model.DET_DEM_DESTINATION.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_DESTINATION.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM = null;
                    break;
                case '2':
                    this.$scope.model.DET_DEM_DESTINATION.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_DESTINATION.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM = null;
                    break;
                case '3':
                    this.$scope.model.DET_DEM_DESTINATION.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM = null;
                    break;
                case '4':
                    this.$scope.model.DET_DEM_DESTINATION.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM = null;
                    break;
                case '5':
                    this.$scope.model.DET_DEM_DESTINATION.FIXED_ADDITIONAL = null;
                    this.$scope.model.DET_DEM_DESTINATION.PERCENTAGE_ADDITIONAL = null;
                    break;
                default:
                    this.formService.notifyError('No Per Diem Type ID at Route Destination selected.');
                    break;
            }
            this.$timeout(() => {
                this.$scope.setDirtyFields([`det_dem_fixed_additional_destination`, `det_dem_percentage_additional_destination`, `splittedDemurrageHouseFreeTimeDestination`]);
                this.$scope.selectorValidity(`det_dem_type_destination`);
                this.$scope.selectorValidity(`per_diem_type_destination`);
                this.$scope.selectorValidity(`free_time_type_destination`);
                this.$scope.selectorValidity(`det_dem_tablePerDiem_destination`);
            }, 100);
        }
    }

    private checkPerDiemTypeChangeRoute(originDestination: number, parentIndex: number): void {
        if (originDestination == 1 && this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN) {
            switch (this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.PER_DIEM_TYPE.ID) {
                case ETariffFreightPerDiemType.DEFAULT_ALLOG:
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.FIXED_ADDITIONAL = null;
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.TABLE_PER_DIEM = null;
                    break;
                case ETariffFreightPerDiemType.TRANSFER_ALL:
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.FIXED_ADDITIONAL = null;
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.TABLE_PER_DIEM = null;
                    break;
                case ETariffFreightPerDiemType.TRANSFER_PLUS_FIXED:
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.TABLE_PER_DIEM = null;
                    break;
                case ETariffFreightPerDiemType.TRANSFER_PLUS_PERCENTAGE:
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.FIXED_ADDITIONAL = null;
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.TABLE_PER_DIEM = null;
                    break;
                case ETariffFreightPerDiemType.TABLE:
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.FIXED_ADDITIONAL = null;
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.PERCENTAGE_ADDITIONAL = null;
                    break;
                default:
                    this.formService.notifyError('No Per Diem Type ID at Route Origin selected.');
                    break;
            }

            this.$timeout(() => {
                this.$scope.setDirtyFields([`route_det_dem_fixed_additional_origin${parentIndex}`, `route_det_dem_percentage_additional_origin${parentIndex}`]);
                this.$scope.selectorValidity(`route_det_dem_type_origin${parentIndex}`);
                this.$scope.selectorValidity(`route_per_diem_type_origin${parentIndex}`);
                this.$scope.selectorValidity(`route_free_time_type_origin${parentIndex}`);
                this.$scope.selectorValidity(`routeTablePerDiemOrigin${parentIndex}`);
            }, 100);
        } else if (originDestination == 2 && this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION) {
            switch (this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.PER_DIEM_TYPE.ID) {
                case ETariffFreightPerDiemType.DEFAULT_ALLOG:
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.FIXED_ADDITIONAL = null;
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.TABLE_PER_DIEM = null;
                    break;
                case ETariffFreightPerDiemType.TRANSFER_ALL:
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.FIXED_ADDITIONAL = null;
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.TABLE_PER_DIEM = null;
                    break;
                case ETariffFreightPerDiemType.TRANSFER_PLUS_FIXED:
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.PERCENTAGE_ADDITIONAL = null;
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.TABLE_PER_DIEM = null;
                    break;
                case ETariffFreightPerDiemType.TRANSFER_PLUS_PERCENTAGE:
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.FIXED_ADDITIONAL = null;
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.TABLE_PER_DIEM = null;
                    break;
                case ETariffFreightPerDiemType.TABLE:
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.FIXED_ADDITIONAL = null;
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.PERCENTAGE_ADDITIONAL = null;
                    break;
                default:
                    this.formService.notifyError('No Per Diem Type ID at Route Destination selected.');
                    break;
            }

            this.$timeout(() => {
                this.$scope.setDirtyFields([`route_det_dem_fixed_additional_destination${parentIndex}`, `route_det_dem_percentage_additional_destination${parentIndex}`]);
                this.$scope.selectorValidity(`route_det_dem_type_destination${parentIndex}`);
                this.$scope.selectorValidity(`route_per_diem_type_destination${parentIndex}`);
                this.$scope.selectorValidity(`route_free_time_type_destination${parentIndex}`);
                this.$scope.selectorValidity(`routeTablePerDiemDestination${parentIndex}`);
            }, 100);
        }
    }

    private async setTablePerDiemRoute(originDestination: number, parentIndex: number): Promise<void> {
        try {
            if (!parentIndex && parentIndex !== 0) throw Error('parentIndex is null');
            if (originDestination == 1) {
                if (this.$scope.tablePerDiemDefaultContractOrigin[parentIndex] && this.$scope.tablePerDiemDefaultContractOrigin[parentIndex] === true) {
                    const msg = "Deseja trocar para a tabela Per Diem do Tarifário de Frete da Origem?"
                    const modal = await this.ModalService.showModalConfirmation({}, {
                        actionButtonText: "GENERAL.CONFIRM",
                        closeButtonText: "GENERAL.NO",
                        headerText: "GENERAL.CONFIRM_ACTION",
                        bodyText: msg
                    });

                    if (!modal) {
                        this.$scope.tablePerDiemDefaultContractOrigin[parentIndex] = !this.$scope.tablePerDiemDefaultContractOrigin[parentIndex];
                        return;
                    }
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.TABLE_PER_DIEM = null;
                } else {
                    this.$scope.tablePerDiemDefaultContractOrigin[parentIndex] = false;
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_ORIGIN.TABLE_PER_DIEM = this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM;
                }
            } else if (originDestination == 2) {
                if (this.$scope.tablePerDiemDefaultContractDestination[parentIndex] && this.$scope.tablePerDiemDefaultContractDestination[parentIndex] === true) {
                    const msg = "Deseja trocar para a tabela Per Diem do Tarifário de Frete de Destino?"
                    const modal = await this.ModalService.showModalConfirmation({}, {
                        actionButtonText: "GENERAL.CONFIRM",
                        closeButtonText: "GENERAL.NO",
                        headerText: "GENERAL.CONFIRM_ACTION",
                        bodyText: msg
                    });

                    if (!modal) {
                        this.$scope.tablePerDiemDefaultContractDestination[parentIndex] = !this.$scope.tablePerDiemDefaultContractDestination[parentIndex];
                        return;
                    }
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.TABLE_PER_DIEM = null;
                } else {
                    this.$scope.tablePerDiemDefaultContractDestination[parentIndex] = false;
                    this.$scope.model.TARIFF_FREIGHT[parentIndex].DET_DEM_DESTINATION.TABLE_PER_DIEM = this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM;

                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private addTariffFreight(navigate?: boolean, openedPanels?: string[]): void {
        try {
            const tariffFreight: ITariffFreightModel = {
                SHOW: true,
                _id: null,
                ID: null,
                ID_TARIFF_FREIGHT_CONTRACT: null,
                MOVE_TYPE: null,
                PROCESS_EVENT_TRANSPORT_MODE: null,
                AIR_SERVICE: null,
                MARITIME_SERVICE: null,
                SERVICE_LEVEL: null,
                SERVICE_TYPE: null,
                PUP_ROUTING_POINT: null,
                OTFS_ROUTING_POINT: null,
                POL_AOL_ROUTING_POINT: null,
                POD_AOD_ROUTING_POINT: null,
                DTFS_ROUTING_POINT: null,
                PLD_ROUTING_POINT: null,
                DTA: false,
                CHARGE: null,
                OBSERVATION: null,
                FILES: null,
                FILES_REMOVE: null,
                FILES_NEW: null,
                CONCATENATED: null,
                CONCATENATED_COMPLEMENT: null,
                ACTIVE: true,
                DYNAMIC_FIELDS: this.buildDynamicFields(),
                DET_DEM_DESTINATION: null,
                DET_DEM_ORIGIN: null,
                CREATED_AT: null,
                CREATED_BY: null,
                UPDATED_AT: null,
                UPDATED_BY: null,
            };

            this.$scope.model.TARIFF_FREIGHT.push(tariffFreight);

            const newTariffIndex = this.$scope.model.TARIFF_FREIGHT.length - 1;
            if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length > 0 && this.$scope.model.TARIFF_FREIGHT[newTariffIndex]) {
                if (this.$scope.model.PRODUCT && (this.$scope.model.PRODUCT.ID == 'IM' || this.$scope.model.PRODUCT.ID == 'EM')) {
                    const portToPort = this.$scope.moveTypeList && this.$scope.moveTypeList.length > 0 ? this.$scope.moveTypeList.find(moveType => moveType.ID == "13") : null;
                    this.$scope.model.TARIFF_FREIGHT[newTariffIndex].MOVE_TYPE = portToPort;
                    this.updateMoveType(portToPort, this.$scope.model.TARIFF_FREIGHT.length - 1);
                }
                if (this.$scope.model.PRODUCT && (this.$scope.model.PRODUCT.ID == 'IA' || this.$scope.model.PRODUCT.ID == 'EA')) {
                    const airportToAirport = this.$scope.moveTypeList && this.$scope.moveTypeList.length > 0 ? this.$scope.moveTypeList.find(moveType => moveType.ID == "14") : null;
                    this.$scope.model.TARIFF_FREIGHT[newTariffIndex].MOVE_TYPE = airportToAirport;
                    this.updateMoveType(airportToAirport, this.$scope.model.TARIFF_FREIGHT.length - 1);
                }
            }

            this.$timeout(() => {
                this.collapseHeader("collapseTariffFreight", "show", navigate);
                for (const index in this.$scope.model.TARIFF_FREIGHT) {
                    if ((parseInt(index) + 1) == this.$scope.model.TARIFF_FREIGHT.length) {
                        this.$scope.model.TARIFF_FREIGHT[index].SHOW = true;
                        this.collapseHeader("collapseTariffFreight" + index, "show", navigate);
                        if (openedPanels && openedPanels.length > 0) {
                            for (const panel of openedPanels) {
                                this.collapseHeader(panel + index, 'show');
                            }
                        } else this.collapseHeader("collapseTariffFreightBasicData" + index, "show", navigate);
                    } else {
                        this.$scope.model.TARIFF_FREIGHT[index].SHOW = false;
                        this.collapseHeader("collapseTariffFreight" + index, "hide");
                    }
                    this.$scope.selectorValidity('moveType' + index);
                    this.$scope.selectorValidity('typeService' + index);
                    this.$scope.selectorValidity('processEventTransportMode' + index);
                    this.$scope.selectorValidity('situation' + index);
                    this.$scope.selectorValidity('porRoutingPoint' + index);
                    this.$scope.selectorValidity('polAolRoutingPoint' + index);
                    this.$scope.selectorValidity('podAodRoutingPoint' + index);
                    this.$scope.selectorValidity('fdnRoutingPoint' + index);
                }

                this.addCharge(newTariffIndex);
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async copyTariffFreight(index: number): Promise<void> {
        try {
            if (!index && index !== 0) throw Error('index is null');
            if (index >= 0) {
                const copyTariffFreight = angular.copy(this.$scope.model.TARIFF_FREIGHT[index]);

                copyTariffFreight.SHOW = true;
                copyTariffFreight._id = null;
                copyTariffFreight.ID = null;
                copyTariffFreight.CONCATENATED = null;

                if (copyTariffFreight.CHARGE && copyTariffFreight.CHARGE.length > 0) {
                    for (const charge of copyTariffFreight.CHARGE) {
                        charge._id = null;
                        charge.ID = null;
                    }
                }

                this.$scope.model.TARIFF_FREIGHT.push(copyTariffFreight);

                this.$timeout(() => {
                    for (const index in this.$scope.model.TARIFF_FREIGHT) {
                        if ((parseInt(index) + 1) == this.$scope.model.TARIFF_FREIGHT.length) {
                            this.$scope.model.TARIFF_FREIGHT[index].SHOW = true;
                            this.collapseHeader("collapseTariffFreight" + index, "show", true);
                            this.collapseHeader("collapseTariffFreightBasicData" + index, "show", true);
                            this.generateConcatenatedTariff(parseInt(index));
                        } else {
                            this.$scope.model.TARIFF_FREIGHT[index].SHOW = false;
                            this.collapseHeader("collapseTariffFreight" + index, "hide");
                        }
                    }
                });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private async removeTariffFreight(index: number): Promise<void> {
        try {
            if (!index && index !== 0) throw Error('index is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: `${this.formService.getTranslate("PRODUCT.REMOVE_THIS_ROUTE")}`
            });

            if (!modal) return;

            this.formService.block();

            this.$scope.model.TARIFF_FREIGHT.splice(index, 1);

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private addCharge(tariffIndex: number): void {
        try {
            if (!tariffIndex && tariffIndex !== 0) throw Error('tariffIndex is null');

            if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length > 0) {
                const param: ICharge = {
                    _id: null,
                    ID: null,
                    ID_TARIFF_FREIGHT: null,
                    ID_CHARGE_NAME_EXHIBITION: null,
                    CHARGE_NAME_EXHIBITION: null,
                    APPLICATION: null,
                    FLAT: false,
                    EQUIPMENT: null,
                    WEIGHT_RANGE: null,
                    VEHICLE_TYPE: null,
                    ID_CURRENCY: null,
                    CURRENCY: null,
                    PAYMENT_MIN: null,
                    PAYMENT_UNITARY: null,
                    RECEIVING_MIN: null,
                    RECEIVING_UNITARY: null,
                    NO_CHARGE: false,
                    SPECIFICITY: null,
                }

                if (!this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE) this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE = [];

                this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE.push(angular.copy(param));

                this.$timeout(() => {
                    for (let i = 0; i < this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE.length; i++) {
                        this.$scope.selectorValidity('chargeName' + tariffIndex + i);
                        this.$scope.selectorValidity('param' + tariffIndex + i);
                        this.$scope.selectorValidity('weightRange' + tariffIndex + i);
                        this.$scope.selectorValidity('equipment' + tariffIndex + i);
                        this.$scope.selectorValidity('currency' + tariffIndex + i);
                        this.$scope.selectorValidity('paymentMin' + tariffIndex + i);
                        this.$scope.selectorValidity('paymentUnitary' + tariffIndex + i);
                        this.$scope.selectorValidity('receivingMin' + tariffIndex + i);
                        this.$scope.selectorValidity('receivingUnitary' + tariffIndex + i);
                        this.$scope.selectorValidity('specificity' + tariffIndex + i);
                    }
                    angular.element(document).ready(() => {
                        this.addFocusEventOnParam();
                    });
                });
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeCharge(tariffIndex: number, chargeIndex: number): Promise<void> {
        try {
            if (!tariffIndex && tariffIndex !== 0) throw Error('tariffIndex is null');
            if (!chargeIndex && chargeIndex !== 0) throw Error('chargeIndex is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: `${this.formService.getTranslate("PRODUCT.REMOVE_CHARGE_QUESTION")}`
            });

            if (!modal) return;

            this.formService.block();

            if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length > 0 && this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE) {
                this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE.splice(chargeIndex, 1);
            }

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async duplicateCharge(tariffIndex: number, chargeIndex: number): Promise<void> {
        try {
            if (!tariffIndex && tariffIndex != 0) throw Error('tariffIndex is null');
            if (!chargeIndex && chargeIndex != 0) throw Error('chargeIndex is null');

            if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length > 0 && this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE && this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE.length > 0 && this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex]) {
                this.formService.block();
                const copy = angular.copy(this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex]);
                const isWeightRangeApplication = copy.APPLICATION && copy.APPLICATION.APPLICATION_COMPLEMENT && copy.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
                copy._id = null;
                copy.ID = null;
                if (isWeightRangeApplication) copy.WEIGHT_RANGE = [];
                this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE.push(copy);

                this.$timeout(() => {
                    angular.element(document).ready(() => {
                        this.addFocusEventOnParam();
                    });
                });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async generateChargeToAvailableWeightRange(tariffIndex: number, chargeIndex: number) {
        try {
            if (!tariffIndex && tariffIndex != 0) throw Error('tariffIndex is null');
            if (!chargeIndex && chargeIndex != 0) throw Error('chargeIndex is null');

            const tariffFreight = this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length ? this.$scope.model.TARIFF_FREIGHT[tariffIndex] : null;
            const tariffCharge = tariffFreight && tariffFreight.CHARGE && tariffFreight.CHARGE.length ? tariffFreight.CHARGE[chargeIndex] : null;
            if (tariffCharge) {
                const isWeightRangeApplication = tariffCharge.APPLICATION && tariffCharge.APPLICATION.APPLICATION_COMPLEMENT && tariffCharge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
                if (isWeightRangeApplication) {
                    const availableWeightRangeList = await this.getWeightRangeListByName(null, true);
                    const availableWeightRangeToGenerateCharge = availableWeightRangeList.filter(weightRange => !tariffFreight.CHARGE.some(charge => charge.CHARGE_NAME_EXHIBITION && tariffCharge.CHARGE_NAME_EXHIBITION && charge.CHARGE_NAME_EXHIBITION.ID == tariffCharge.CHARGE_NAME_EXHIBITION.ID && charge.WEIGHT_RANGE && charge.WEIGHT_RANGE.some((chargeWeightRange) => chargeWeightRange.ID == weightRange.ID)));
                    if (availableWeightRangeToGenerateCharge && availableWeightRangeToGenerateCharge.length) {
                        this.addCharge(tariffIndex);
                        const addedChargeIndex = tariffFreight.CHARGE.length - 1;
                        this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[addedChargeIndex] = { ...tariffCharge, _id: null, WEIGHT_RANGE: availableWeightRangeToGenerateCharge };
                    } else this.formService.notifyInfo("Não existem mais faixas disponíveis para essa taxa.");
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async flatChange(tariffIndex: number, chargeIndex: number) {
        try {
            if (!tariffIndex && tariffIndex != 0) throw Error('tariffIndex is null');
            if (!chargeIndex && chargeIndex != 0) throw Error('chargeIndex is null');

            const tariffFreight = this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length ? this.$scope.model.TARIFF_FREIGHT[tariffIndex] : null;
            const tariffCharge = tariffFreight && tariffFreight.CHARGE && tariffFreight.CHARGE.length ? tariffFreight.CHARGE[chargeIndex] : null;
            if (tariffCharge && tariffCharge.FLAT) {
                const availableWeightRangeList = await this.getWeightRangeListByName(null, true);
                const availableWeightRangeToConcat = availableWeightRangeList.filter(weightRange => !tariffFreight.CHARGE.some(charge => charge.CHARGE_NAME_EXHIBITION && tariffCharge.CHARGE_NAME_EXHIBITION && charge.CHARGE_NAME_EXHIBITION.ID == tariffCharge.CHARGE_NAME_EXHIBITION.ID && charge.WEIGHT_RANGE && charge.WEIGHT_RANGE.some((chargeWeightRange) => chargeWeightRange.ID == weightRange.ID)));
                if (tariffCharge.WEIGHT_RANGE) tariffCharge.WEIGHT_RANGE = tariffCharge.WEIGHT_RANGE.concat(availableWeightRangeToConcat);
                else tariffCharge.WEIGHT_RANGE = availableWeightRangeToConcat;
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private addFocusEventOnParam(tariffIndex?: number): void {
        try {
            if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length > 0) {
                if (tariffIndex || tariffIndex == 0) {
                    if (this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE && this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE.length > 0) {
                        for (let chargeIndex = 0; chargeIndex < this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE.length; chargeIndex++) {
                            $("#paramSelector-" + tariffIndex + "-" + chargeIndex).unbind('focus').on('focus', async (event) => {
                                event.stopPropagation();
                                await this.getChargeNameParamsList(tariffIndex, chargeIndex);
                            });
                        }
                    }
                } else {
                    for (let tariffIndex = 0; tariffIndex < this.$scope.model.TARIFF_FREIGHT.length; tariffIndex++) {
                        if (this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE && this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE.length > 0) {
                            for (let chargeIndex = 0; chargeIndex < this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE.length; chargeIndex++) {
                                $("#paramSelector-" + tariffIndex + "-" + chargeIndex).unbind('focus').on('focus', async (event) => {
                                    event.stopPropagation();
                                    await this.getChargeNameParamsList(tariffIndex, chargeIndex);
                                });
                            }
                        }
                    }
                }
            }

        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private async getChargeNameParamsList(tariffIndex: number, index: number): Promise<void> {
        let result: IApplicationList[] = [];
        try {
            if (!tariffIndex && tariffIndex != 0) throw Error('tariffIndex is null');
            if (!index && index != 0) throw Error('index is null');

            if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length > 0) {
                if (!this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE || !this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[index] || !this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[index].CHARGE_NAME_EXHIBITION || !this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[index].CHARGE_NAME_EXHIBITION.ID) {
                    throw Error(this.formService.getTranslate('PRODUCT.SELECT_CHARGENAME_FIRST'));
                }
                this.formService.block();
                const products = this.$scope.model.PRODUCT ? [this.$scope.model.PRODUCT.ID] : null;
                const paramsTypeCargo = this.$scope.model.TYPE_CARGO ? [this.$scope.model.TYPE_CARGO.ID] : null;

                const params = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/application/list/custom`, { products: products, typeCargos: paramsTypeCargo, percent: false }, 30000, false);
                result = params ? params.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PERCENT: x.PERCENT, APPLICATION_COMPLEMENT: x.APPLICATION_COMPLEMENT, INTERNAL_SEQUENCE: x.INTERNAL_SEQUENCE, FREE_TYPING_AMOUNT_CHARGE: x.FREE_TYPING_AMOUNT_CHARGE, CT_WITHOUT_DOC: x.CT_WITHOUT_DOC, NAME_INTL: x.NAME_INTL } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.chargeApplicationList = result;
        }
    }

    /**
     * If this function needs to be change, the same logic must be applied in Backend WBA-PRODUCT in the file
     * WBA-Product/src/persistence/TariffFreightRepository.ts
     */
    private generateConcatenated(): void {
        try {
            const separator = " | ";
            let concatenated = "";

            if (this.$scope.model.PROVIDER && this.$scope.model.PROVIDER.TYPE) {
                if ((this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.SHIPOWNER || this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.AIRLINE) && this.$scope.model.PROVIDER.CODE && this.$scope.model.PROVIDER.CODE.length) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PROVIDER.CODE) : concatenated.concat(this.$scope.model.PROVIDER.CODE);
                } else if (this.$scope.model.PROVIDER.NAME && this.$scope.model.PROVIDER.NAME.length) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PROVIDER.NAME) : concatenated.concat(this.$scope.model.PROVIDER.NAME);
                }
            }

            if (this.$scope.model.ACCOUNTS && this.$scope.model.ACCOUNTS.length) {
                const account = this.$scope.getCONCAT(this.$scope.model.ACCOUNTS, null, "CODE", null, null, ',')
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, account) : concatenated.concat(account);
            }

            if (this.$scope.model.NETWORKS && this.$scope.model.NETWORKS.length) {
                const networks = this.$scope.getCONCAT(this.$scope.model.NETWORKS, null, null, null, null, ',')
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, networks) : concatenated.concat(networks);
            }

            if (this.$scope.model.CONTRACT && this.$scope.model.CONTRACT.CODE && this.$scope.model.CONTRACT.CODE.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CONTRACT.CODE) : concatenated.concat(this.$scope.model.CONTRACT.CODE);
            }

            if (this.$scope.model.TYPE_CARGO && this.$scope.model.TYPE_CARGO.NAME && this.$scope.model.TYPE_CARGO.NAME.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.TYPE_CARGO.NAME) : concatenated.concat(this.$scope.model.TYPE_CARGO.NAME);
            }

            if (this.$scope.model.CONTRACT_TYPE && this.$scope.model.CONTRACT_TYPE.NAME && this.$scope.model.CONTRACT_TYPE.NAME.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CONTRACT_TYPE.NAME) : concatenated.concat(this.$scope.model.CONTRACT_TYPE.NAME);
            }

            if (this.$scope.model.PAYMENT_NATURE && this.$scope.model.PAYMENT_NATURE.ID && this.$scope.model.PAYMENT_NATURE.ID.length > 0) {
                let paymentNature = null;
                if (this.$scope.model.PAYMENT_NATURE.ID === EPaymentNatureId.PAYMENT) {
                    paymentNature = 'PGTO';
                } else if (this.$scope.model.PAYMENT_NATURE.ID === EPaymentNatureId.RECEIVING) {
                    paymentNature = 'RCTO';
                }
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, paymentNature) : concatenated.concat(paymentNature);
            }

            if (this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.ID && this.$scope.model.PRODUCT.ID.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PRODUCT.ID) : concatenated.concat(this.$scope.model.PRODUCT.ID);
            }

            if (this.$scope.model.CONCATENATED_COMPLEMENT && this.$scope.model.CONCATENATED_COMPLEMENT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CONCATENATED_COMPLEMENT) : concatenated.concat(this.$scope.model.CONCATENATED_COMPLEMENT);
            }

            if (this.$scope.model.VERSION) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, 'V' + this.$scope.model.VERSION) : concatenated.concat('V' + this.$scope.model.VERSION);
            }

            if (this.$scope.model.DISPLAY_VALIDITY_START) {
                const validity = this.$scope.model.DISPLAY_VALIDITY_END ? moment(this.$scope.model.DISPLAY_VALIDITY_START).format('DD/MM/YY') + '-' + moment(this.$scope.model.DISPLAY_VALIDITY_END).format('DD/MM/YY') : moment(this.$scope.model.DISPLAY_VALIDITY_START).format('DD/MM/YY');
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, validity) : concatenated.concat(validity);
            }

            this.$scope.model.CONCATENATED = StringUtil.formatConcatenatedChars(concatenated);

            if (this.$scope.menuFloating) this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private generateConcatenatedTariff(tariffIndex: number): void {
        if (!tariffIndex && tariffIndex != 0) throw Error('tariffIndex is null');
        try {
            const tariffFreight = this.$scope.model.TARIFF_FREIGHT[tariffIndex];
            if (tariffFreight) {
                const separator = " | ";
                let concatenated = "";

                if (this.$scope.model.PROVIDER && this.$scope.model.PROVIDER.TYPE) {
                    if ((this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.SHIPOWNER || this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.AIRLINE) && this.$scope.model.PROVIDER.CODE && this.$scope.model.PROVIDER.CODE.length) {
                        concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PROVIDER.CODE) : concatenated.concat(this.$scope.model.PROVIDER.CODE);
                    } else if (this.$scope.model.PROVIDER.NAME && this.$scope.model.PROVIDER.NAME.length) {
                        concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PROVIDER.NAME) : concatenated.concat(this.$scope.model.PROVIDER.NAME);
                    }
                }

                if (this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.ID && this.$scope.model.PRODUCT.ID.length) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PRODUCT.ID) : concatenated.concat(this.$scope.model.PRODUCT.ID);
                }

                if (tariffFreight.CONCATENATED_COMPLEMENT && tariffFreight.CONCATENATED_COMPLEMENT.length) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, tariffFreight.CONCATENATED_COMPLEMENT) : concatenated.concat(tariffFreight.CONCATENATED_COMPLEMENT);
                }

                if (this.$scope.model.CONTRACT && this.$scope.model.CONTRACT.CODE && this.$scope.model.CONTRACT.CODE.length > 0) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CONTRACT.CODE) : concatenated.concat(this.$scope.model.CONTRACT.CODE);
                }

                if (tariffFreight.PROCESS_EVENT_TRANSPORT_MODE && tariffFreight.PROCESS_EVENT_TRANSPORT_MODE.NAME && tariffFreight.PROCESS_EVENT_TRANSPORT_MODE.NAME.length > 0) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, tariffFreight.PROCESS_EVENT_TRANSPORT_MODE.NAME) : concatenated.concat(tariffFreight.PROCESS_EVENT_TRANSPORT_MODE.NAME);
                }

                if (tariffFreight.SERVICE_LEVEL && tariffFreight.SERVICE_LEVEL.length > 0) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, tariffFreight.SERVICE_LEVEL.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(tariffFreight.SERVICE_LEVEL.map(obj => { return obj.NAME }).join(','));
                }

                if (tariffFreight.PUP_ROUTING_POINT && tariffFreight.PUP_ROUTING_POINT.length > 0) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, tariffFreight.PUP_ROUTING_POINT.map(obj => { return obj.CODE }).join(',')) : concatenated.concat(tariffFreight.PUP_ROUTING_POINT.map(obj => { return obj.CODE }).join(','));
                }

                if (tariffFreight.OTFS_ROUTING_POINT && tariffFreight.OTFS_ROUTING_POINT.length > 0) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, tariffFreight.OTFS_ROUTING_POINT.map(obj => { return obj.CODE }).join(',')) : concatenated.concat(tariffFreight.OTFS_ROUTING_POINT.map(obj => { return obj.CODE }).join(','));
                }

                if (tariffFreight.POL_AOL_ROUTING_POINT && tariffFreight.POL_AOL_ROUTING_POINT.length > 0) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, tariffFreight.POL_AOL_ROUTING_POINT.map(obj => { return obj.CODE }).join(',')) : concatenated.concat(tariffFreight.POL_AOL_ROUTING_POINT.map(obj => { return obj.CODE }).join(','));
                }

                if (tariffFreight.POD_AOD_ROUTING_POINT && tariffFreight.POD_AOD_ROUTING_POINT.length > 0) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, tariffFreight.POD_AOD_ROUTING_POINT.map(obj => { return obj.CODE }).join(',')) : concatenated.concat(tariffFreight.POD_AOD_ROUTING_POINT.map(obj => { return obj.CODE }).join(','));
                }

                if (tariffFreight.DTFS_ROUTING_POINT && tariffFreight.DTFS_ROUTING_POINT.length > 0) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, tariffFreight.DTFS_ROUTING_POINT.map(obj => { return obj.CODE }).join(',')) : concatenated.concat(tariffFreight.DTFS_ROUTING_POINT.map(obj => { return obj.CODE }).join(','));
                }

                if (tariffFreight.PLD_ROUTING_POINT && tariffFreight.PLD_ROUTING_POINT.length > 0) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, tariffFreight.PLD_ROUTING_POINT.map(obj => { return obj.CODE }).join(',')) : concatenated.concat(tariffFreight.PLD_ROUTING_POINT.map(obj => { return obj.CODE }).join(','));
                }

                tariffFreight.CONCATENATED = StringUtil.formatConcatenatedChars(concatenated);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private generateAllConcatenatedTariff(): void {
        if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length) {
            for (let index = 0; index < this.$scope.model.TARIFF_FREIGHT.length; index++) {
                this.generateConcatenatedTariff(index);
            }
        }
    }

    private isCommodityDisabled(commodity: ICommodity, fieldName: string): boolean {
        let disabled = false;
        try {
            if (!commodity) throw Error('commodity is null');
            if (!fieldName) throw Error('fieldName is null');
            if (
                (fieldName == 'section' && (commodity.CONDITION || commodity.EXCEPTION)) ||
                (fieldName == 'condition' && (commodity.SECTION || commodity.EXCEPTION)) ||
                (fieldName == 'exception' && (commodity.SECTION || commodity.CONDITION))
            ) disabled = true;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return disabled;
        }
    }

    private addCommodity(): void {
        try {
            if (!this.$scope.model.COMMODITY) this.$scope.model.COMMODITY = [];
            const commodityToAdd: ICommodity = {
                _id: null,
                ID: null,
                ID_TARIFF_FREIGHT_CONTRACT: null,
                SECTION: null,
                CONDITION: null,
                ID_CONDITION: null,
                EXCEPTION: null,
                ID_EXCEPTION: null
            }
            this.$scope.model.COMMODITY.push(angular.copy(commodityToAdd));

            this.$timeout(() => {
                const currentIndex = this.$scope.model.COMMODITY.length - 1;
                this.$scope.selectorValidity('section' + currentIndex);
                this.$scope.selectorValidity('condition' + currentIndex);
                this.$scope.selectorValidity('exception' + currentIndex);
            });
            this.orderCommodityByException();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private hideRemove(): boolean {
        let disabled = false;
        try {
            if (this.$scope.model.COMMODITY && this.$scope.model.COMMODITY.length == 1) {
                if (this.$scope.model.COMMODITY[0].CONDITION || this.$scope.model.COMMODITY[0].EXCEPTION || this.$scope.model.COMMODITY[0].SECTION) {
                    disabled = true;
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return disabled;
        }
    }

    private async removeDetDemEquipment(index: number, property: string) {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: `${this.formService.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")}`
            });
            if (!modal) return;

            if (this.$scope.model[property] && this.$scope.model[property].EQUIPMENT_FREE_TIME.length > 0) {
                this.formService.block();
                this.$scope.model[property].EQUIPMENT_FREE_TIME.splice(index, 1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async removeDetDemRouteEquipment(parentIndex: number, index: number, property: string) {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: `${this.formService.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")}`
            });
            if (!modal) return;

            if (this.$scope.model.TARIFF_FREIGHT[parentIndex][property] && this.$scope.model.TARIFF_FREIGHT[parentIndex][property].EQUIPMENT_FREE_TIME.length > 0) {
                this.formService.block();
                this.$scope.model.TARIFF_FREIGHT[parentIndex][property].EQUIPMENT_FREE_TIME.splice(index, 1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async removeCommodity(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: `${this.formService.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")}`
            });
            if (!modal) return;

            if (this.$scope.model.COMMODITY && this.$scope.model.COMMODITY.length > 0) {
                this.formService.block();
                this.$scope.model.COMMODITY.splice(index, 1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private orderCommodityByException(): void {
        if (this.$scope.model && this.$scope.model.COMMODITY && this.$scope.model.COMMODITY.length > 0) {
            this.$scope.model.COMMODITY = this.$scope.model.COMMODITY.sort((a, b) => {
                const firstCommodity = a.EXCEPTION ? a.EXCEPTION.NAME.toLowerCase() : "";
                const secondCommodity = b.EXCEPTION ? b.EXCEPTION.NAME.toLowerCase() : "";
                return firstCommodity > secondCommodity ? 1 : firstCommodity < secondCommodity ? -1 : 0;
            });
        }
    }

    private commodityChange(commodity: ICommodity): void {
        if (commodity) {
            if (commodity.SECTION) {
                commodity.CONDITION = null;
                commodity.EXCEPTION = null;
            } else if (commodity.CONDITION) {
                commodity.SECTION = null;
                commodity.EXCEPTION = null;
            } else if (commodity.EXCEPTION) {
                commodity.SECTION = null;
                commodity.CONDITION = null;
            }
        }
    }

    private initDateFields(): void {
        try {
            if (this.$scope.model) {
                this.$scope.model.DISPLAY_VALIDITY_START = (this.$scope.model.DISPLAY_VALIDITY_START != null) ? new Date(this.$scope.model.DISPLAY_VALIDITY_START) : null;
                this.$scope.model.VALIDITY_START = (this.$scope.model.VALIDITY_START != null) ? new Date(this.$scope.model.VALIDITY_START) : null;
                this.$scope.model.DISPLAY_VALIDITY_END = (this.$scope.model.DISPLAY_VALIDITY_END != null) ? new Date(this.$scope.model.DISPLAY_VALIDITY_END) : null;
                this.$scope.model.VALIDITY_END = (this.$scope.model.VALIDITY_END != null) ? new Date(this.$scope.model.VALIDITY_END) : null;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initEvents(): void {
        angular.element(window).on('resize', () => {
            this.setChargeColStyle();
        });
    }

    private setChargeColStyle(): void {
        const chargeColStyle: IChargeColStyle = { charge: null, param: null, flat: null, weightEquipment: null, currency: null, paymentUnitary: null, paymentMin: null, receivingUnitary: null, receivingMin: null, group: null, specificity: null };
        const winWidth = angular.element(window).width();
        if (winWidth >= 1900) {
            chargeColStyle.charge = { "width": "20%" };
            chargeColStyle.param = { "width": "12%" };
            chargeColStyle.flat = { "width": "3%" };
            chargeColStyle.weightEquipment = { "width": "11%" };
            chargeColStyle.currency = { "width": "6%" };
            chargeColStyle.paymentUnitary = { "width": "7%" };
            chargeColStyle.paymentMin = { "width": "7%" };
            chargeColStyle.receivingUnitary = { "width": "7%" };
            chargeColStyle.receivingMin = { "width": "7%" };
            chargeColStyle.group = { "width": "10%" };
            chargeColStyle.specificity = { "width": "10%" };
        } else if (winWidth >= 1600) {
            chargeColStyle.charge = { "width": "12%" };
            chargeColStyle.param = { "width": "10%" };
            chargeColStyle.flat = { "width": "4%" };
            chargeColStyle.weightEquipment = { "width": "12%" };
            chargeColStyle.currency = { "width": "8%" };
            chargeColStyle.paymentUnitary = { "width": "9%" };
            chargeColStyle.paymentMin = { "width": "9%" };
            chargeColStyle.receivingUnitary = { "width": "9%" };
            chargeColStyle.receivingMin = { "width": "9%" };
            chargeColStyle.group = { "width": "9%" };
            chargeColStyle.specificity = { "width": "9%" };
        } else if (winWidth >= 1280) {
            chargeColStyle.charge = { "width": "11%" };
            chargeColStyle.param = { "width": "10%" };
            chargeColStyle.flat = { "width": "3%" };
            chargeColStyle.weightEquipment = { "width": "11%" };
            chargeColStyle.currency = { "width": "9%" };
            chargeColStyle.paymentUnitary = { "width": "9%" };
            chargeColStyle.paymentMin = { "width": "9%" };
            chargeColStyle.receivingUnitary = { "width": "9%" };
            chargeColStyle.receivingMin = { "width": "9%" };
            chargeColStyle.group = { "width": "10%" };
            chargeColStyle.specificity = { "width": "10%" };
        }
        this.$scope.chargeColStyle = chargeColStyle;
    }

    private initTablePerDiemPerRoute(): void {
        this.$scope.tablePerDiemDefaultContractOrigin = [];
        this.$scope.tablePerDiemDefaultContractDestination = [];

        this.$scope.model.TARIFF_FREIGHT.forEach((tariff, index) => {
            if (this.$scope.model.DET_DEM_ORIGIN && tariff.DET_DEM_ORIGIN) {
                this.$scope.tablePerDiemDefaultContractOrigin[index] = true;
                tariff.DET_DEM_ORIGIN.TABLE_PER_DIEM = this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM;
            }
            if (this.$scope.model.DET_DEM_DESTINATION && tariff.DET_DEM_DESTINATION) {
                this.$scope.tablePerDiemDefaultContractDestination[index] = true;
                tariff.DET_DEM_DESTINATION.TABLE_PER_DIEM = this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM;
            }
        });

    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'tariff_files', formName: this.formName, folder: this.formName };
            return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData] });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeUpload(model: IUploadItem): Promise<boolean> {
        let result = false;
        try {
            if (!model) throw Error('item parameter is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                closeButtonText: "GENERAL.NO",
                headerText: "GENERAL.CONFIRM_ACTION",
                bodyText: `${this.formService.getTranslate("GENERAL.DO_YOU_WANT_TO_REMOVE")}`
            });
            if (!modal) return;

            const isAdminOrCoordinator = (this.$scope.user.roles && (this.$scope.user.roles.hasOwnProperty('ADMIN') || this.$scope.user.roles.hasOwnProperty('COORDINATOR')));

            if (isAdminOrCoordinator) {
                result = true;
            } else {
                if (this.$scope.model.FILES_NEW && this.$scope.model.FILES_NEW.length > 0) {
                    const index = this.$scope.model.FILES_NEW.findIndex(file => { return file.FILE_HASH == model.FILE_HASH });
                    if (index > -1) {
                        this.$scope.model.FILES_NEW.splice(index, 1);
                        result = true;
                    }
                }
            }

            if (result) {
                if (!this.$scope.model.FILES_REMOVE) this.$scope.model.FILES_REMOVE = [];
                this.$scope.model.FILES_REMOVE.push(model);
            } else {
                throw Error('You don\'t have permission to remove this file');
            }
        } catch (ex) {
            result = false;
            this.handleError(ex);
        } finally {
            return result;
        }
    }

    private onSuccessItem(item: IFileItem, response: ISuccessItemResponse): void {
        try {
            if (!item) throw Error('item parameter is null');
            if (!response) throw Error('response parameter is null');

            if (response.data && response.data.length > 0) {
                if (!this.$scope.model.FILES_NEW) this.$scope.model.FILES_NEW = [];

                for (let item of response.data) {
                    const file: IUploadItem = {
                        FILE_DISPLAY_NAME: item.displayFileName,
                        FILE_NAME: item.fileName,
                        FILE_TYPE: item.fileType,
                        FILE_PATH: item.filePath,
                        FILE_URL: item.fileUrl,
                        FILE_HASH: item.fileHash,
                        FILE_SIZE: item.fileSize,
                        FORM_NAME: item.formName
                    }
                    this.$scope.model.FILES_NEW.push(file)
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async updateProduct(selected: SelectorModel): Promise<void> {
        try {
            if (!selected) throw Error('selected is null');

            this.$scope.model.PROVIDER = null;
            this.$timeout(() => this.$scope.selectorValidity("provider"));
            await this.updateControl();
            if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length > 0) {
                for (let index = 0; index < this.$scope.model.TARIFF_FREIGHT.length; index++) {
                    const tariff = this.$scope.model.TARIFF_FREIGHT[index];
                    tariff.PUP_ROUTING_POINT = null;
                    tariff.OTFS_ROUTING_POINT = null;
                    tariff.POL_AOL_ROUTING_POINT = null;
                    tariff.POD_AOD_ROUTING_POINT = null;
                    tariff.DTFS_ROUTING_POINT = null;
                    tariff.PLD_ROUTING_POINT = null;
                    const moveType = tariff.MOVE_TYPE ? this.$scope.moveTypeFullList.find(moveType => moveType.ID == parseInt(tariff.MOVE_TYPE.ID)) : null;
                    if (moveType && moveType.PARAMS) tariff.DYNAMIC_FIELDS = this.buildDynamicFields(moveType.PARAMS);
                    if (this.$scope.isAirProduct) {
                        tariff.MARITIME_SERVICE = null;
                        if (this.$scope.operation == "register" && index == 0) {
                            const airportToAirport = this.$scope.moveTypeList && this.$scope.moveTypeList.length > 0 ? this.$scope.moveTypeList.find(moveType => moveType.ID == "14") : null;
                            tariff.MOVE_TYPE = airportToAirport;
                            this.updateMoveType(airportToAirport, 0);
                            this.$timeout(() => { this.$scope.selectorValidity("typeService" + index) });
                        }
                    }
                    else if (this.$scope.isMaritimeProduct) {
                        tariff.SERVICE_TYPE = null;
                        tariff.SERVICE_LEVEL = null;
                        tariff.AIR_SERVICE = null;
                        if (this.$scope.operation == "register" && index == 0) {
                            const portToPort = this.$scope.moveTypeList && this.$scope.moveTypeList.length > 0 ? this.$scope.moveTypeList.find(moveType => moveType.ID == "13") : null;
                            tariff.MOVE_TYPE = portToPort;
                            this.updateMoveType(portToPort, 0);
                            this.$timeout(() => { this.$scope.selectorValidity("typeService" + index) });
                        }
                    }
                    else {
                        tariff.SERVICE_TYPE = null;
                        tariff.SERVICE_LEVEL = null;
                        tariff.AIR_SERVICE = null;
                        tariff.MARITIME_SERVICE = null;
                        this.$timeout(() => { this.$scope.selectorValidity("typeService" + index) });
                    }
                }
            }
            this.$scope.model.VALIDITY_EVENT = this.getValidityEventBasedOnProduct();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private updateProvider(): void {
        if (this.$scope.isAirProduct || this.$scope.isMaritimeProduct) {
            if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length > 0) {
                for (const tariff of this.$scope.model.TARIFF_FREIGHT) {
                    if (this.$scope.isAirProduct) tariff.MARITIME_SERVICE = null;
                    else if (this.$scope.isMaritimeProduct) tariff.AIR_SERVICE = null;
                    else {
                        tariff.AIR_SERVICE = null;
                        tariff.MARITIME_SERVICE = null;
                    }

                    if (tariff.DET_DEM_ORIGIN && tariff.DET_DEM_ORIGIN.TABLE_PER_DIEM && tariff.DET_DEM_ORIGIN.TABLE_PER_DIEM.ID) tariff.DET_DEM_ORIGIN.TABLE_PER_DIEM = null;
                    if (tariff.DET_DEM_DESTINATION && tariff.DET_DEM_DESTINATION.TABLE_PER_DIEM && tariff.DET_DEM_DESTINATION.TABLE_PER_DIEM.ID) tariff.DET_DEM_DESTINATION.TABLE_PER_DIEM = null;
                }
            }
        }

        if (this.$scope.model && this.$scope.model.DET_DEM_ORIGIN && this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM && this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM.ID) this.$scope.model.DET_DEM_ORIGIN.TABLE_PER_DIEM = null;
        if (this.$scope.model && this.$scope.model.DET_DEM_DESTINATION && this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM && this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM.ID) this.$scope.model.DET_DEM_DESTINATION.TABLE_PER_DIEM = null;
    }

    private updateParam(selected: IChargeNameList, tariffIndex: number, chargeIndex: number): void {
        let param: IParams = null;
        try {
            if (!selected) throw Error('selected is null');
            if (!tariffIndex && tariffIndex != 0) throw Error('tariffIndex is null');
            if (!chargeIndex && chargeIndex != 0) throw Error('chargeIndex is null');

            if (selected.PARAMS && selected.PARAMS.length > 0) param = selected.PARAMS.find(param => (param.TYPE_CARGO.find(typeCargo => typeCargo.ID === this.$scope.model.TYPE_CARGO.ID) && ((this.$scope.model.PAYMENT_NATURE.ID === EPaymentNatureId.PAYMENT && (param.PAYMENT && !param.PAYMENT.PERCENT)) || (this.$scope.model.PAYMENT_NATURE.ID === EPaymentNatureId.RECEIVING && (param.RECEIVING && !param.RECEIVING.PERCENT)))));
            if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length > 0 && this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex]) {
                const charge = this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex];
                if (param && this.$scope.model.PAYMENT_NATURE) {
                    charge.APPLICATION = this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.RECEIVING ? param.RECEIVING : param.PAYMENT;
                    this.$timeout(() => { this.$scope.selectorValidity(`param${tariffIndex}${chargeIndex}`); });
                }
                const applicationComplement = this.getChargeApplicationComplement(tariffIndex, chargeIndex);
                if (!applicationComplement || applicationComplement == EApplicationComplementId.NOT_APPLICABLE || applicationComplement == EApplicationComplementId.WEIGHT_RANGE) {
                    if (applicationComplement == EApplicationComplementId.WEIGHT_RANGE) {
                        if (this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.PAYMENT) this.chargeAirFieldsChange(tariffIndex, chargeIndex, false, true);
                        if (this.$scope.model.PAYMENT_NATURE.ID == EPaymentNatureId.RECEIVING) this.chargeAirFieldsChange(tariffIndex, chargeIndex, false, false, true);
                    } else {
                        charge.FLAT = false;
                        charge.WEIGHT_RANGE = null;
                    }
                    charge.EQUIPMENT = null;
                    charge.VEHICLE_TYPE = null;
                    this.$timeout(() => { this.$scope.selectorValidity(`weightRange${tariffIndex}${chargeIndex}`); });
                }
                else if (applicationComplement == EApplicationComplementId.EQUIPMENT) {
                    charge.FLAT = false;
                    charge.WEIGHT_RANGE = null;
                    charge.VEHICLE_TYPE = null;
                    this.$timeout(() => { this.$scope.selectorValidity(`equipment${tariffIndex}${chargeIndex}`); });
                }
                else if (applicationComplement == EApplicationComplementId.VEHICLE) {
                    charge.FLAT = false;
                    charge.WEIGHT_RANGE = null;
                    charge.EQUIPMENT = null;
                    this.$timeout(() => { this.$scope.selectorValidity(`vehicleType${tariffIndex}${chargeIndex}`); });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private updateMoveType(selected: SelectorModel, tariffIndex: number): void {
        try {
            if (!selected) throw Error('selected is null');
            if (!tariffIndex && tariffIndex != 0) throw Error('tariffIndex is null');
            if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length > 0) {
                if (!this.$scope.model.PRODUCT) {
                    this.$scope.model.TARIFF_FREIGHT[tariffIndex].MOVE_TYPE = null;
                    throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                }
                if (selected.ID == EMoveTypeId.AIRPORT_TO_AIRPORT) {
                    const allAirTransportMode = this.$scope.processEventTransportModeList && this.$scope.processEventTransportModeList.length ? this.$scope.processEventTransportModeList.find(transportMode => transportMode.ID == ETransportModeId.ALL_AIR) : null;
                    this.$scope.model.TARIFF_FREIGHT[tariffIndex].PROCESS_EVENT_TRANSPORT_MODE = allAirTransportMode;
                    this.generateConcatenatedTariff(tariffIndex);
                    this.$timeout(() => {
                        this.$scope.selectorValidity("processEventTransportMode" + tariffIndex);
                    });
                }
                this.$scope.model.TARIFF_FREIGHT[tariffIndex].PUP_ROUTING_POINT = null;
                this.$scope.model.TARIFF_FREIGHT[tariffIndex].OTFS_ROUTING_POINT = null;
                this.$scope.model.TARIFF_FREIGHT[tariffIndex].POL_AOL_ROUTING_POINT = null;
                this.$scope.model.TARIFF_FREIGHT[tariffIndex].POD_AOD_ROUTING_POINT = null;
                this.$scope.model.TARIFF_FREIGHT[tariffIndex].DTFS_ROUTING_POINT = null;
                this.$scope.model.TARIFF_FREIGHT[tariffIndex].PLD_ROUTING_POINT = null;
                const moveType = this.$scope.moveTypeFullList.find(moveType => moveType.ID == parseInt(selected.ID));
                if (this.$scope.model.TARIFF_FREIGHT[tariffIndex] && moveType && moveType.PARAMS) this.$scope.model.TARIFF_FREIGHT[tariffIndex].DYNAMIC_FIELDS = this.buildDynamicFields(moveType.PARAMS);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private buildDynamicFields(params?: IParam[]): IDynamicField[] {
        let dynamicFields: IDynamicField[] = [];
        try {
            const originParam1 = params ? params.find(param => param.DIRECTION.ID == EDirectionId.ORIGIN && param.ORDER == 1 && (!this.$scope.model.PRODUCT || !param.PRODUCT || param.PRODUCT.some(product => product.ID == this.$scope.model.PRODUCT.ID))) : null;
            const originParam2 = params ? params.find(param => param.DIRECTION.ID == EDirectionId.ORIGIN && param.ORDER == 2 && (!this.$scope.model.PRODUCT || !param.PRODUCT || param.PRODUCT.some(product => product.ID == this.$scope.model.PRODUCT.ID))) : null;
            const originParam3 = params ? params.find(param => param.DIRECTION.ID == EDirectionId.ORIGIN && param.ORDER == 3 && (!this.$scope.model.PRODUCT || !param.PRODUCT || param.PRODUCT.some(product => product.ID == this.$scope.model.PRODUCT.ID))) : null;
            const destinationParam1 = params ? params.find(param => param.DIRECTION.ID == EDirectionId.DESTINATION && param.ORDER == 1 && (!this.$scope.model.PRODUCT || !param.PRODUCT || param.PRODUCT.some(product => product.ID == this.$scope.model.PRODUCT.ID))) : null;
            const destinationParam2 = params ? params.find(param => param.DIRECTION.ID == EDirectionId.DESTINATION && param.ORDER == 2 && (!this.$scope.model.PRODUCT || !param.PRODUCT || param.PRODUCT.some(product => product.ID == this.$scope.model.PRODUCT.ID))) : null;
            const destinationParam3 = params ? params.find(param => param.DIRECTION.ID == EDirectionId.DESTINATION && param.ORDER == 3 && (!this.$scope.model.PRODUCT || !param.PRODUCT || param.PRODUCT.some(product => product.ID == this.$scope.model.PRODUCT.ID))) : null;
            const originField1: IDynamicField = this.buildDynamicField(originParam1);
            const originField2: IDynamicField = this.buildDynamicField(originParam2);
            const originField3: IDynamicField = this.buildDynamicField(originParam3);
            const destinationField1: IDynamicField = this.buildDynamicField(destinationParam1);
            const destinationField2: IDynamicField = this.buildDynamicField(destinationParam2);
            const destinationField3: IDynamicField = this.buildDynamicField(destinationParam3);
            dynamicFields.push(originField1);
            dynamicFields.push(originField2);
            dynamicFields.push(originField3);
            dynamicFields.push(destinationField1);
            dynamicFields.push(destinationField2);
            dynamicFields.push(destinationField3);
        } catch (ex) {
            this.formService.handleError(ex);
        }
        return dynamicFields;
    }

    private buildDynamicField(param: IParam): IDynamicField {
        const dynamicField: IDynamicField = { ENABLED: false, PLACEHOLDER: "GENERAL.FIELD_WITHOUT_REQUIRED_VALUE", LABEL: "N/A", INITIALS: "", MANDATORY: false, ROUTE_TYPES: [] };
        if (param) {
            dynamicField.PLACEHOLDER = "GENERAL.UI_SELECT.TYPE_TEXT";
            dynamicField.ENABLED = !param.TYPE_RULE || (param.TYPE_RULE && param.TYPE_RULE.ID != "3");
            if (param.NAME && param.NAME.length > 0) dynamicField.LABEL = param.NAME;
            if (param.CODE) dynamicField.INITIALS = param.CODE;
            if (param.TYPE_RULE && param.TYPE_RULE.ID == "1") dynamicField.MANDATORY = true;
            if (param.TYPE_LOCAL_EVENT && param.TYPE_LOCAL_EVENT.length > 0) dynamicField.ROUTE_TYPES = param.TYPE_LOCAL_EVENT.map(typeLocal => typeLocal.ID);
        }
        return dynamicField;
    }

    private updatePaymentMin(tariffIndex: number, chargeIndex: number): void {
        try {
            if (!tariffIndex && tariffIndex != 0) throw Error('tariffIndex is null');
            if (!chargeIndex && chargeIndex != 0) throw Error('chargeIndex is null');

            if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length > 0 && this.$scope.model.TARIFF_FREIGHT[tariffIndex] && this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex] && !this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].APPLICATION.PERCENT) {
                this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].PAYMENT_MIN = this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].PAYMENT_UNITARY;
            } else if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length > 0 && this.$scope.model.TARIFF_FREIGHT[tariffIndex] && this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex] && this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].APPLICATION && this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].APPLICATION.PERCENT && !this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].PAYMENT_MIN) {
                this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].PAYMENT_MIN = 0;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private updateReceivingMin(tariffIndex: number, chargeIndex: number): void {
        try {
            if (!tariffIndex && tariffIndex != 0) throw Error('tariffIndex is null');
            if (!chargeIndex && chargeIndex != 0) throw Error('chargeIndex is null');

            if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length > 0 && this.$scope.model.TARIFF_FREIGHT[tariffIndex] && this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex] && !this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].APPLICATION.PERCENT) {
                this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].RECEIVING_MIN = this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].RECEIVING_UNITARY;
            } else if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length > 0 && this.$scope.model.TARIFF_FREIGHT[tariffIndex] && this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex] && this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].APPLICATION && this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].APPLICATION.PERCENT && !this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].RECEIVING_MIN) {
                this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].RECEIVING_MIN = 0;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private isChargeAirFieldsDisabled(chargeList: ICharge[], chargeIndex: number, application: IApplicationList, isPaymentField?: boolean, isReceivingField?: boolean, noCharge?: boolean): boolean {
        try {
            let isAirConditionDisabled = false;
            const paymentNature = this.$scope.model.PAYMENT_NATURE;
            const isPayment = paymentNature && paymentNature.ID == EPaymentNatureId.PAYMENT;
            if (this.$scope.isAirProduct) {
                const tariffCharge = chargeList[chargeIndex];
                const firstWeightRangeChargeIndex = chargeList && chargeList.length ? chargeList.findIndex(charge => charge.APPLICATION && charge.APPLICATION.APPLICATION_COMPLEMENT && charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE && (charge.CHARGE_NAME_EXHIBITION && tariffCharge.CHARGE_NAME_EXHIBITION && charge.CHARGE_NAME_EXHIBITION.ID == tariffCharge.CHARGE_NAME_EXHIBITION.ID)) : -1;
                const isWeightRangeApplication = application && application.APPLICATION_COMPLEMENT && application.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
                isAirConditionDisabled = isWeightRangeApplication && chargeIndex != firstWeightRangeChargeIndex;
            }
            if (isPaymentField) return !isPayment || isAirConditionDisabled;
            else if (isReceivingField) return !paymentNature || noCharge || isAirConditionDisabled;
            else return isAirConditionDisabled;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private chargeAirFieldsChange(tariffIndex: number, chargeIndex: number, isCurrencyField?: boolean, isPaymentField?: boolean, isReceivingField?: boolean): void {
        try {
            if (!tariffIndex && tariffIndex != 0) throw Error('tariffIndex is null');
            if (!chargeIndex && chargeIndex != 0) throw Error('chargeIndex is null');
            if (this.$scope.isAirProduct) {
                const tariffFreight = this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT[tariffIndex] ? this.$scope.model.TARIFF_FREIGHT[tariffIndex] : null;
                if (tariffFreight && tariffFreight.CHARGE && tariffFreight.CHARGE.length) {
                    const tariffFreightCharge = tariffFreight.CHARGE[chargeIndex];
                    if (tariffFreightCharge && tariffFreightCharge.CHARGE_NAME_EXHIBITION) {
                        const firstWeightRangeChargeIndex = tariffFreight.CHARGE.findIndex(charge => charge.APPLICATION && charge.APPLICATION.APPLICATION_COMPLEMENT && charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE && charge.CHARGE_NAME_EXHIBITION && charge.CHARGE_NAME_EXHIBITION.ID == tariffFreightCharge.CHARGE_NAME_EXHIBITION.ID);
                        for (const tariffCharge of tariffFreight.CHARGE) {
                            if (tariffCharge.CHARGE_NAME_EXHIBITION && tariffCharge.CHARGE_NAME_EXHIBITION.ID == tariffFreightCharge.CHARGE_NAME_EXHIBITION.ID) {
                                const application = tariffCharge.APPLICATION;
                                const isWeightRangeApplication = application && application.APPLICATION_COMPLEMENT && application.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
                                if (isWeightRangeApplication) {
                                    if (isCurrencyField) tariffCharge.CURRENCY = tariffFreight.CHARGE[firstWeightRangeChargeIndex].CURRENCY;
                                    if (isPaymentField) tariffCharge.PAYMENT_MIN = tariffFreight.CHARGE[firstWeightRangeChargeIndex].PAYMENT_MIN;
                                    if (isReceivingField) tariffCharge.RECEIVING_MIN = tariffFreight.CHARGE[firstWeightRangeChargeIndex].RECEIVING_MIN;
                                }
                            }
                        }
                    }
                }
            }

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private getChargeApplicationComplement(tariffIndex: number, chargeIndex: number): string {
        try {
            if (!tariffIndex && tariffIndex != 0) throw Error('tariffIndex is null');
            if (!chargeIndex && chargeIndex != 0) throw Error('chargeIndex is null');

            if (
                this.$scope.model.TARIFF_FREIGHT &&
                this.$scope.model.TARIFF_FREIGHT[tariffIndex] &&
                this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE &&
                this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex] &&
                this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].APPLICATION &&
                this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].APPLICATION.APPLICATION_COMPLEMENT &&
                this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].APPLICATION.APPLICATION_COMPLEMENT.ID
            ) {
                return this.$scope.model.TARIFF_FREIGHT[tariffIndex].CHARGE[chargeIndex].APPLICATION.APPLICATION_COMPLEMENT.ID;
            }
            return null;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private collapseTariffFreight(tariffFreightIndex: number): void {
        if (!tariffFreightIndex && tariffFreightIndex != 0) throw Error("tariffFreightIndex is null.");
        if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length > 0 && this.$scope.model.TARIFF_FREIGHT[tariffFreightIndex]) {
            if (this.$scope.model.TARIFF_FREIGHT[tariffFreightIndex].SHOW) {
                this.collapseHeader('collapseTariffFreight' + tariffFreightIndex, 'hide');
                this.$timeout(() => { this.$scope.model.TARIFF_FREIGHT[tariffFreightIndex].SHOW = false; }, 500);
            }
            else {
                this.$scope.model.TARIFF_FREIGHT[tariffFreightIndex].SHOW = true;
                this.collapseHeader('collapseTariffFreight' + tariffFreightIndex, 'show');
                this.$scope.disableElements(this.$scope.operation == EOperation.VIEW);
            }
        }
    }

    private collapseHeader(elementId: string, state?: string, navigate?: boolean): void {
        if (elementId === "collapseAll") {
            this.collapseState = this.collapseState == "hide" ? "show" : "hide";
            $('.toggle-me')["collapse"](state ? state : this.collapseState);
        }
        else if (elementId != "registerBody") {
            $("#" + elementId)["collapse"](state ? state : 'toggle');
        }
        if (navigate && state != "hide") this.$scope.navigateBetweenIds(elementId);
    }

    private getValidityEventBasedOnProduct(): SelectorModel {
        let event: SelectorModel = null;
        try {
            const chargeItens = ['EM', 'EA', 'ER'];
            const dischargeItens = ['IM', 'IA', 'IR'];

            if (this.$scope.model.PRODUCT && chargeItens.indexOf(this.$scope.model.PRODUCT.ID) > -1) {
                const filterEvent = this.$scope.eventList.find(e => { return e.ID == '4' });
                event = filterEvent;
            }
            if (this.$scope.model.PRODUCT && dischargeItens.indexOf(this.$scope.model.PRODUCT.ID) > -1) {
                const filterEvent = this.$scope.eventList.find(e => { return e.ID == '4' });
                event = filterEvent;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return event;
        }
    }

    private checkDateValidity(initialDate: Date, finalDate: Date): void {
        let isValid = false;
        if (!initialDate || typeof initialDate == "string") return;
        if (!finalDate || typeof finalDate == "string") return;
        isValid = ValidateUtil.isValidDateRange(initialDate, finalDate);
        if (!isValid) {
            this.$scope.model.DISPLAY_VALIDITY_END = null;
        }
    }

    private async getTarifffreightDetDetmListByName(search: String): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 3) {
                this.formService.block();
                let errorMessage: string = '';
                if (!this.$scope.model.PRODUCT || !this.$scope.model.PRODUCT.ID) {
                    errorMessage += (errorMessage ? ', ' : '') + 'product';
                }
                if (!this.$scope.model.PAYMENT_NATURE || !this.$scope.model.PAYMENT_NATURE.ID) {
                    errorMessage += (errorMessage ? ', ' : '') + 'payment nature';
                }
                if (errorMessage) {
                    throw Error(`Select ${errorMessage} first`);
                }

                const produto = this.$scope.model.PRODUCT ? [this.$scope.model.PRODUCT.ID] : [];
                const payment_nature = this.$scope.model.PAYMENT_NATURE ? [this.$scope.model.PAYMENT_NATURE.ID] : [];
                const provider = this.$scope.model.PROVIDER ? [this.$scope.model.PROVIDER.ID] : [];
                const accounts = this.$scope.model.ACCOUNTS ? this.$scope.model.ACCOUNTS.map(product => { return product.ID }) : [];
                const networks = this.$scope.model.NETWORKS ? this.$scope.model.NETWORKS.map(network => { return network.ID }) : [];
                const contract = this.$scope.model.CONTRACT ? [this.$scope.model.CONTRACT.ID] : [];

                let direction = [];
                // If the tariff freight is an exportation tariff, the Det/Dem tariff must be a tariff from the origin;
                if (this.$scope.model.PRODUCT.ID == "EM") {
                    direction = ['1']; // Origin
                    // But, if the tariff freight is an importation tariff, the Det/Dem tariff must be a tariff from the destination;
                } else if (this.$scope.model.PRODUCT.ID == "IM") {
                    direction = ['2']; // Destination
                }

                const tariffDetDem = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/tariffDetDem/list/custom/`, { products: produto, paymentNatures: payment_nature, providers: provider, accounts: accounts, networks: networks, direction: direction, contracts: contract, search: search }, 30000, false);
                if (tariffDetDem && tariffDetDem.length > 0) {
                    result = tariffDetDem.map(singleTariff => { return { ID: singleTariff.ID, NAME: singleTariff.CONCATENATED } });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.tariffFreightDetDemList = result;
            this.formService.unblock();
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: "CONCATENATED",
                LABEL: "GENERAL.CONCATENATED_COMPLEMENT"
            },
            {
                PROPERTY: "VERSION",
                LABEL: "GENERAL.VERSION"
            },
            {
                PROPERTY: "CONTRACT",
                LABEL: "PRODUCT.FREIGHT_CONTRACT"
            },
            {
                PROPERTY: "PAYMENT_NATURE",
                LABEL: "BASIC_DATA.TRANSACTION"
            },
            {
                PROPERTY: "CONTRACT_TYPE",
                LABEL: "PRODUCT.FREIGHT_CONTRACT_TYPE"
            },
            {
                PROPERTY: "PRODUCT",
                LABEL: "BASIC_DATA.PRODUCT"
            },
            {
                PROPERTY: "TYPE_CARGO",
                LABEL: "BASIC_DATA.CARGO_TYPE"
            },
            {
                PROPERTY: "PROVIDER",
                LABEL: "BASIC_DATA.PROVIDER "
            },
            {
                PROPERTY: "ACCOUNTS",
                LABEL: "BASIC_DATA.CLIENT"
            },
            {
                PROPERTY: "NETWORKS",
                LABEL: "BASIC_DATA.EXTERNAL_AGENT_NETWORK"
            },
            {
                PROPERTY: "NAME",
                LABEL: "GENERAL.NAME"
            },
            {
                PROPERTY: "CODE",
                LABEL: "ENTITY.SHORT_NAME"
            },
            {
                PROPERTY: "COMMODITY",
                LABEL: "GENERAL.COMMODITY"
            },
            {
                PROPERTY: "SECTION",
                LABEL: "GENERAL.COMMODITY_GROUP_CONDITION"
            },
            {
                PROPERTY: "CONDITION",
                LABEL: "GENERAL.COMMODITY_CONDITION"
            },
            {
                PROPERTY: "EXCEPTION",
                LABEL: "GENERAL.COMMODITY_EXCEPTION"
            },
            {
                PROPERTY: "VALIDITY_EVENT",
                LABEL: "GENERAL.VALIDITY_BASIS"
            },
            {
                PROPERTY: "DISPLAY_VALIDITY_START",
                LABEL: "PRODUCT.VALIDITY_START"
            },
            {
                PROPERTY: "DISPLAY_VALIDITY_END",
                LABEL: "PRODUCT.VALIDITY_END"
            },
            {
                PROPERTY: "SITUATION",
                LABEL: "GENERAL.SITUATION"
            },
            {
                PROPERTY: "OBSERVATION",
                LABEL: "GENERAL.REMARKS"
            },
            {
                PROPERTY: "FILES",
                LABEL: "REGISTRATION.FILES"
            },
            {
                PROPERTY: "FILE_DISPLAY_NAME",
                LABEL: "REGISTRATION.FILE_ATTACHMENT_NAME"
            },
            {
                PROPERTY: "FILE_TYPE",
                LABEL: "REGISTRATION.FILE_TYPE"
            },
            {
                PROPERTY: "FILE_URL",
                LABEL: "REGISTRATION.FILE_URL"
            },
            {
                PROPERTY: "SCORE",
                LABEL: "BASIC_DATA.SCORE"
            },
            {
                PROPERTY: "SCORE_DATE",
                LABEL: "GENERAL.SCORE_CALCULATION_DATA"
            },
            {
                PROPERTY: "SCORE_ERROR",
                LABEL: "GENERAL.SCORE_CALCULATION_ERROR"
            },
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE"
            },
            {
                PROPERTY: "VALIDITY_EXPIRED",
                LABEL: "GENERAL.VALIDITY_EXPIRED"
            },
            {
                PROPERTY: "TARIFF_FREIGHT",
                LABEL: "ROUTE.ROUTES"
            },
            {
                PROPERTY: "MOVE_TYPE",
                LABEL: "ROUTE.MOVEMENT_TYPE"
            },
            {
                PROPERTY: "PROCESS_EVENT_TRANSPORT_MODE",
                LABEL: "OPERATIONAL.TRANSPORT_MODE"
            },
            {
                PROPERTY: "AIR_SERVICE",
                LABEL: "BASIC_DATA.SERVICE"
            },
            {
                PROPERTY: "MARITIME_SERVICE",
                LABEL: "BASIC_DATA.SERVICE"
            },
            {
                PROPERTY: "SERVICE_LEVEL",
                LABEL: "BASIC_DATA.SERVICE_LEVEL"
            },
            {
                PROPERTY: "PUP_ROUTING_POINT",
                LABEL: "BASIC_DATA.PICK_UP_PLACE"
            },
            {
                PROPERTY: "OTFS_ROUTING_POINT",
                LABEL: "BASIC_DATA.PLACE_OF_RECEIPT"
            },
            {
                PROPERTY: "POL_AOL_ROUTING_POINT",
                LABEL: "BASIC_DATA.LOADING_PORT"
            },
            {
                PROPERTY: "POD_AOD_ROUTING_POINT",
                LABEL: "BASIC_DATA.DISCHARGE_PORT"
            },
            {
                PROPERTY: "DTFS_ROUTING_POINT",
                LABEL: "BASIC_DATA.FINAL_DESTINATION"
            },
            {
                PROPERTY: "PLD_ROUTING_POINT",
                LABEL: "BASIC_DATA.DELIVERY_PLACE"
            },
            {
                PROPERTY: "CHARGE",
                LABEL: "GENERAL.CHARGES"
            },
            {
                PROPERTY: "CHARGE_NAME_EXHIBITION",
                LABEL: "BASIC_DATA.CHARGE"
            },
            {
                PROPERTY: "APPLICATION",
                LABEL: "FINANCIAL.CHARGE_BASIS"
            },
            {
                PROPERTY: "EQUIPMENT",
                LABEL: "GENERAL.EQUIPMENT"
            },
            {
                PROPERTY: "WEIGHT_RANGE",
                LABEL: "REGISTRATION.WEIGHT_BREAK"
            },
            {
                PROPERTY: "CURRENCY",
                LABEL: "GENERAL.CURRENCY"
            },
            {
                PROPERTY: "PAYMENT_MIN",
                LABEL: "PRODUCT.MIN_PAYMENT"
            },
            {
                PROPERTY: "PAYMENT_UNITARY",
                LABEL: "PRODUCT.UNIT_PAYMENT"
            },
            {
                PROPERTY: "RECEIVING_MIN",
                LABEL: "PRODUCT.MIN_RECEIPT"
            },
            {
                PROPERTY: "RECEIVING_UNITARY",
                LABEL: "PRODUCT.UNIT_RECEIPT"
            },
            {
                PROPERTY: "NO_CHARGE",
                LABEL: "SPRODUCT.FREE_OF_CHARGE_SELL"
            },
            {
                PROPERTY: "SPECIFICITY",
                LABEL: "GENERAL.SPECIFICITY"
            },
            {
                PROPERTY: "VEHICLE_TYPE",
                LABEL: "GENERAL.VEHICLE_TYPE"
            },
            {
                PROPERTY: "SERVICE_TYPE",
                LABEL: "BASIC_DATA.SERVICE_TYPE"
            },
            {
                PROPERTY: "TARIFF_DET_DEM",
                LABEL: "PRODUCT.DD_TARIFF"
            },
            {
                PROPERTY: "CONCATENATED_COMPLEMENT",
                LABEL: "GENERAL.CONCATENATED_COMPLEMENT"
            },
            {
                PROPERTY: "EQUIP_WEIGHT_RANGE_VEHICLE",
                LABEL: "PRODUCT.BASIS_COMPLEMENT"
            },
            {
                PROPERTY: "ROUTING_POINT_ORIGIN_REPL",
                LABEL: "BASIC_DATA.ORIGIN"
            },
            {
                PROPERTY: "ROUTING_POINT_DESTINATION_REPL",
                LABEL: "BASIC_DATA.DESTINATION"
            },
            {
                PROPERTY: "COUNTRY_ORIGIN_REPL",
                LABEL: "BASIC_DATA.ORIGIN_COUNTRY"
            },
            {
                PROPERTY: "COUNTRY_DESTINATION_REPL",
                LABEL: "BASIC_DATA.DESTINATION_COUNTRY"
            },
            {
                PROPERTY: "CHARGE_NAME_EXHIBITION_REPL",
                LABEL: "BASIC_DATA.CHARGE"
            },
            {
                PROPERTY: "CREATED_AT",
                LABEL: "GENERAL.CREATED_AT"
            },
            {
                PROPERTY: "CREATED_BY",
                LABEL: "GENERAL.CREATED_BY"
            },
            {
                PROPERTY: "UPDATED_AT",
                LABEL: "GENERAL.UPDATED_AT"
            },
            {
                PROPERTY: "UPDATED_BY",
                LABEL: "GENERAL.UPDATED_BY"
            }
        ];
        return props;
    }

    private getMenuFloatingDefault(): IFloatingMenu {
        return {
            tooltipPlacement: "auto bottom",
            textTooltip: "PRODUCT.TARIFF_DATA",
            infos: [
                { text: "Tarifário", class: "text-rouge font-bold" }
            ],
            options: [
                { click: "collapseHeader", args: ['collapseBasicData', 'toggle', true], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.BASIC_DATA", iconClass: "fa fa-address-card", iconBodyClass: "text-brown" },
                { click: "collapseHeader", args: ['collapseUploads', 'toggle', true], tooltipPlacement: "auto bottom", textTooltip: "REGISTRATION.FILES", iconClass: "fa fa-file", iconBodyClass: "text-orange" },
                { click: "collapseHeader", args: ['collapseTariffFreight', 'toggle', true], tooltipPlacement: "auto bottom", textTooltip: "ROUTE.ROUTES", iconClass: "fa fa-plane", iconBodyClass: "text-cyano" },
                { click: "collapseHeader", args: ['collapseAll'], tooltipPlacement: "auto bottom", textTooltip: "GENERAL.COLLAPSE_EXPAND_ALL", iconClass: "fa fa-expand", iconBodyClass: "text-danger" }
            ],
            btnActiveDisabled: false
        };
    }

    private async updateControl() {
        const product = this.$scope.model.PRODUCT;
        this.$scope.isAirProduct = product && (product.ID == EProductId.AIR_IMPORT || product.ID == EProductId.AIR_EXPORT);
        this.$scope.isMaritimeProduct = product && (product.ID == EProductId.MARITIME_IMPORT || product.ID == EProductId.MARITIME_EXPORT);
        this.$scope.serviceTypeList = await this.getServiceTypeListByName();
    }

    private initPanels(open: string[] = ['collapseBasicData']): void {
        try {
            const panels = document.getElementsByClassName("toggle-me");
            if (panels && panels.length > 0) {
                for (const item of open) {
                    $("#" + item)["collapse"]("show");
                }
                for (let i = 0; i < panels.length; i++) {
                    const panel = panels[i];
                    if (open.indexOf(panel.id) === -1) {
                        $("#" + panel.id)["collapse"]("hide");
                    }
                }
            }
            this.$scope.navigateBetweenIds(open[0]);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async editChargesModal(id: number) {
        if (!id && id != 0) throw Error('id is null');
        try {
            const modalID = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalTariffFreightCharges({
                modalID: modalID,
                contractId: id,
            });
            await modalInstance.result.then(async value => {
                if (value) await this.$gridService.handleBackgroundUpdate();
            });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.SSEService.closeEvents();
        }
    }

    private setCopy(value: boolean): void {
        this.isCopy = value;
    }

    private transactionChange(): void {
        try {
            if (this.$scope.model.PAYMENT_NATURE) {
                const isPayment = this.$scope.model.PAYMENT_NATURE.ID == "1";
                if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length > 0) {
                    for (const tariffFreight of this.$scope.model.TARIFF_FREIGHT) {

                        if (!isPayment) {
                            tariffFreight.AIR_SERVICE = null;
                            tariffFreight.MARITIME_SERVICE = null;
                        }

                        if (tariffFreight.CHARGE && tariffFreight.CHARGE.length > 0) {
                            for (const charge of tariffFreight.CHARGE) {
                                if (isPayment) {
                                    charge.RECEIVING_MIN = null;
                                    charge.RECEIVING_UNITARY = null;
                                } else {
                                    charge.PAYMENT_MIN = null;
                                    charge.PAYMENT_UNITARY = null;
                                    charge.NO_CHARGE = false;
                                }
                            }
                        }
                    }
                }
                this.$scope.model.DET_DEM_ORIGIN = null;
                this.$scope.model.DET_DEM_DESTINATION = null;
                this.$scope.hasDetDemDestination = false;
                this.$scope.hasDetDemOrigin = false;

                if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT.length > 0) {
                    for (const tariffItem of this.$scope.model.TARIFF_FREIGHT) {
                        if (tariffItem.DET_DEM_ORIGIN) tariffItem.DET_DEM_ORIGIN = null;
                        if (tariffItem.DET_DEM_DESTINATION) tariffItem.DET_DEM_DESTINATION = null;
                    }
                }
            }
        } catch (ex) {
            this.formService.notifyError(ex);
        }
    }

    private noChargeChange(parentIndex: number, index: number, value: boolean): void {
        if (!parentIndex && parentIndex != 0) throw Error('parentIndex is null');
        if (!index && index != 0) throw Error('index is null');
        if (!value && value != false) throw Error('value is null');
        if (this.$scope.model.TARIFF_FREIGHT && this.$scope.model.TARIFF_FREIGHT[parentIndex] && this.$scope.model.TARIFF_FREIGHT[parentIndex].CHARGE && this.$scope.model.TARIFF_FREIGHT[parentIndex].CHARGE[index]) {
            this.$scope.model.TARIFF_FREIGHT[parentIndex].CHARGE[index].RECEIVING_UNITARY = null;
            this.$scope.model.TARIFF_FREIGHT[parentIndex].CHARGE[index].RECEIVING_MIN = null;
        }
    }

    private async openTariffUsedOffer(tariffFreight: ITariffFreightContractModel): Promise<void> {
        this.modalFormatedDataId = this.ModalService.newModal();
        this.ModalService.showModalInfo(
            {
                modalID: this.modalFormatedDataId,
                scope: this.$scope,
                formService: this.$scope.operation,
                size: 'lg modal-overflow',
                template: require("../view/modals/tariffFreightContractUsedOfferModal.html"),
                keyboard: false
            },
            null
        );

        this.buildOfferProcessModalScope(tariffFreight);
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalIntegrationErrorId = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalIntegrationErrorId, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "Integration Product/Operation" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number, idSubject: string): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                const commodityRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/tariffFreight/sendSync`, { "idTariffFreightContract": id, "idSubject": idSubject }, 120000, false);
                if (commodityRequest) success = true;
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.SENDING_REQUEST_ERROR');
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const tariffFreightData = await this.getTariffFreightById(id);
                    if (row && tariffFreightData && tariffFreightData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = tariffFreightData.DOCUMENT_ERROR;
                        documentError = tariffFreightData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async getTariffFreightById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/tariffFreight/getById/${id}`, 30000, null, false);
            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async buildOfferProcessModalScope(tariffFreight: ITariffFreightContractModel): Promise<IUsedOfferModal> {
        try {
            this.formService.block();
            if (!tariffFreight) throw Error('Missing tariffFreight parameter in buildOfferProcessModalScope');

            const modalScope: IUsedOfferModal = await this.ModalService.getModalScope(this.modalFormatedDataId);
            modalScope.tariffFreight = angular.copy(tariffFreight);

            modalScope.usedOfferList = [];
            const requestList = await this.getUsedOfferList(tariffFreight.ID);
            if (requestList) modalScope.usedOfferList = angular.copy(requestList);

            modalScope.goToOffer = (offerNumber: string): void => {
                if (!offerNumber || (offerNumber && offerNumber == '')) throw Error('offerNumber is null');
                this.$scope.sessionService.openTab("app.commercial.offer", <IOfferParameter>{ OFFER_CODE_EXHIBITION: offerNumber });
            }

            return modalScope;
        } catch (ex) {
            this.ModalService.closeModal(this.modalFormatedDataId);
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getUsedOfferList(tariffID: number): Promise<IUsedOffer[]> {
        try {
            let result = [];

            const requestList = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/tariffFreight/usedOffer/${tariffID}`, 30000, null, false,)
            if (requestList && requestList.data) result = requestList.data;

            return result
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    async fetchData(id: number, action: string, idTariffFreight?: number): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.getTariffFreightById(id);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) await this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) await this.$scope.copy(model);
                else await this.$scope.view(model);

                if (idTariffFreight) {
                    const tariffIndex = this.$scope.model.TARIFF_FREIGHT ? this.$scope.model.TARIFF_FREIGHT.findIndex(tariff => tariff.ID == idTariffFreight) : -1;
                    if (tariffIndex >= 0) {
                        this.$scope.model.TARIFF_FREIGHT[tariffIndex].SHOW = true;
                        this.$timeout(() => { this.collapseHeader("collapseTariffFreight" + tariffIndex, "show", true) }, 1000);
                    }
                }

                this.$scope.hasDetDemOrigin = false;
                this.$scope.hasDetDemDestination = false;

                if (this.$scope.model.DET_DEM_ORIGIN) {
                    this.$scope.hasDetDemOrigin = true;
                }

                if (this.$scope.model.DET_DEM_DESTINATION) {
                    this.$scope.hasDetDemDestination = true;
                }

            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copyTariffFromHistory(id: number) {
        try {
            const tariffFreight = await this.getTariffFreightContractHistoryById(id);
            if (tariffFreight) {
                const model: ITariffFreightContractModel = angular.copy(tariffFreight);
                model.ID = null;
                model._id = null;
                this.setCopy(true);
                await this.$scope.copy(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async getTariffFreightContractHistoryById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const tariffFreight = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/tariffFreightHistory/getById/${id}`, 30000, null, false);
            return tariffFreight ? tariffFreight.data : null;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }
}

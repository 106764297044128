import * as angular from 'angular';
import { FormService2 } from "@services/FormService2";
import { IRestService } from "@services/RestService";
import { OperationalService } from "@services/OperationalService";
import { INotificationService, INotificationOptions } from "@services/NotificationService";
import { IModalService } from "@services/ModalService";
import { HelperService } from "@services/HelperService";
import { CellGroupUserModel, CellRoleModel, CellModel, CellGroupModel, CellGroupSpecModel } from "@models/interface/common/CellModel";
import { UserModel } from "@models/interface/common/UserModel";
import { IScopeCell, IRootScopeCell } from "../interface/ICellParameterization";
import { HandleError } from "../../common/util/HandleError";
import { ArrayUtil } from "../../common/util/ArrayUtil";
import { IGenericResponse } from "../../common/interface/IGenericResponse";
import { NotificationConstants } from "../../common/util/NotificationConstants";
import { RouteConstants } from "../../common/util/RouteConstants";
import { BrowserTitle } from '../../common/BrowserTitle';
import { EOperation } from '@enums/GenericData';

CellRegisterController.$inject = ['$scope', '$timeout', 'ModalService', 'NotificationService', 'OperationalService', 'RestService', 'HelperService', 'blockUI', '$rootScope', '$q', '$translate'];
export function CellRegisterController($scope: IScopeCell, $timeout: ng.ITimeoutService, ModalService: IModalService, NotificationService: INotificationService, operationalService: OperationalService, RestService: IRestService, helperService: HelperService, blockUI: ng.blockUI.BlockUIService, $rootScope: IRootScopeCell, $q: ng.IQService, $translate) {

    //HOOKS
    let users: Array<CellGroupUserModel> = [];
    this.$onInit = async function () {
        try {
            blockUI.start();

            //first dependencies
            $scope.rolesLimit = 9;
            $scope.usersLimit = 3;
            $scope.operation = 'list';

            const results = await getGenericValuesByType('role_group');
            if (results) $scope.roleGroupList = results;
            else throw new Error('Empty Generic "role_group", please insert some Groups!');

            //load request dependencies
            users = await castUserToUserCell();

            resetCell();
            resetSpec();

            $scope.$applyAsync(function () { blockUI.stop(); });

            BrowserTitle.$menu = 'Celulas';

        } catch (ex) {
            $scope.$applyAsync(function () { blockUI.stop(); });
            HandleError.exception(ex);
        }
    }

    this.$onDestroy = function () { }

    // WATCHES
    $scope.$watch('cell', (newCell: CellRoleModel): void => {
        if (newCell) updateRoles();
    });

    //CELLS
    $scope.loadCells = async () => {
        try {

            blockUI.start();

            let results = await updateCells();

            $scope.$applyAsync(() => { blockUI.stop(); });

            return results;

        } catch (e) {
            $scope.$applyAsync(() => { blockUI.stop(); });
            HandleError.exception(e);
        }

    }

    //CRUD
    $scope.save = async () => {
        try {
            blockUI.start();

            const isInvalid = FormService2.hasRequiredElements('#specModal') || FormService2.hasRequiredElements('#cellForm') || !validateCell();
            if (isInvalid) {
                $scope.$applyAsync(function () { blockUI.stop(); });
                const msgError = await $translate('REGISTRATION.MESSAGES.ERROR.FILL_IN_ALL_MANDATORY_FIELDS');
                NotificationService.error(msgError);
                return;
            }

            //send request to server
            const result = await saveCell($scope.operation, $scope.cell);

            //validate response
            if (!result) {
                $scope.$applyAsync(function () { blockUI.stop(); });
                return;
            }

            if ($scope.operation == 'register') {
                NotificationService.success(NotificationConstants.SAVE_SUCCESS);
            } else if ($scope.operation == 'edit') {
                NotificationService.success(NotificationConstants.UPDATE_SUCCESS);
            }

            resetCell();

            $scope.operation = 'list';

            //update the cells list
            await $scope.loadCells();

            $scope.$applyAsync(function () { blockUI.stop(); });

        } catch (ex) {
            $scope.$applyAsync(function () { blockUI.stop(); });
            HandleError.exception(ex);
        }
    }

    $scope.view = (cell: CellModel) => {
        $scope.operation = EOperation.VIEW;

        $scope.searchRoles.input = '';
        $scope.cell = angular.copy(cell);
    }

    $scope.new = () => {
        $scope.operation = 'register';

        $scope.cell = {
            NAME: '',
            DESCRIPTION: '',
            _id: null,
            ID: null,
            CELL_NUMBER: null,
            CREATED_DATE: new Date(),
            GROUP: []
        }
    }

    $scope.delete = async (cell: CellModel, $event) => {
        try {

            blockUI.start();

            if (cell) {

                const index = ArrayUtil.indexOfMongo(cell, $scope.cells);
                const hasCell = index > -1;

                if (hasCell) {
                    const response = await deleteCell(cell);

                    if (response.status === 200) {
                        NotificationService.success(NotificationConstants.DELETE_SUCCESS);
                    } else {
                        NotificationService.success(NotificationConstants.DELETE_ERROR);
                    }

                    await updateCells();

                    $scope.operation = 'list';

                } else {
                    const msgError = await $translate('REGISTRATION.MESSAGES.ERROR.CELL_NOT_FOUND_IN_THE_LIST');
                    throw new Error(msgError);
                }

                $scope.closeDeletePopover($event);

            } else {
                const msgError = await $translate('REGISTRATION.MESSAGES.ERROR.CELL_INFORMATION_IS_EMPTY');
                throw new Error(msgError);
            }

            $scope.$applyAsync(function () { blockUI.stop(); });

        } catch (ex) {
            $scope.$applyAsync(function () { blockUI.stop(); });
            HandleError.exception(ex);
        }
    }

    $scope.edit = (cell: CellModel) => {
        $scope.cell = angular.copy(cell);
        $scope.operation = 'edit';
    }

    $scope.cancel = () => {
        $scope.operation = 'list';
    }


    //ROLES
    $scope.loadRoles = async () => {
        try {
            let results: Array<CellRoleModel> = [];
            if (angular.isDefined($scope.cell)) results = await updateRoles();

            return results;
        } catch (e) {
            HandleError.exception(e);
        }

    }

    $scope.selectRole = async (role: CellRoleModel): Promise<void> => {
        try {

            if ($scope.cell) {
                if (!angular.isArray($scope.cell.GROUP)) throw new Error('Grupos da célula não foi definida.');

                let roles: Array<CellRoleModel> = [];
                $scope.cell.GROUP.forEach((group) => {
                    roles.push(group.ROLE_REFERENCE);
                });

                //add to selecteds
                let index = ArrayUtil.indexOfMongo(role, roles);
                let hasRole = index > -1;

                if (!hasRole) {

                    const group: CellGroupModel = {
                        ROLE_REFERENCE: role,
                        USER: [],
                        SPEC: [],
                        ROLE_GROUP: null
                    }

                    $scope.cell.GROUP.push(group);
                } else {
                    const msgError = await $translate('REGISTRATION.MESSAGES.ERROR.ROLE_NOT_FOUND');
                    throw new Error(msgError);
                }

                //remove from unselecteds
                index = ArrayUtil.indexOfMongo(role, $scope.roles);
                hasRole = index > -1;
                const msgError = await $translate('REGISTRATION.MESSAGES.ERROR.ROLE_NOT_FOUND');
                if (hasRole) $scope.roles.splice(index, 1);
                else throw new Error(msgError);

            } else {
                const msgError = await $translate('REGISTRATION.MESSAGES.ERROR.CELL_GROUP_IS_NOT_SELECTED');
                throw new Error(msgError);
            }


        } catch (ex) {
            HandleError.exception(ex);
        }

    };

    $scope.unselectedGroup = async (groupSelected: CellGroupModel, $event: Event) => {
        try {
            const index = ArrayUtil.indexOfHash(groupSelected, $scope.cell.GROUP);
            const hasGroup = index > -1;

            if (hasGroup) {

                //remove from dom
                const card = angular.element($event.target).parents('.card');
                $scope.closeDeletePopover($event);

                card.animate({ width: 0, height: 0, opacity: 0 }, 250, () => {
                    card.remove();

                    $timeout(() => {

                        const group: CellGroupModel = {
                            ROLE_REFERENCE: groupSelected.ROLE_REFERENCE,
                            USER: [],
                            SPEC: [],
                            ROLE_GROUP: null
                        }

                        $scope.roles.push(group.ROLE_REFERENCE);
                        $scope.cell.GROUP.splice(index, 1);

                    });

                });

            } else {
                const msgError = await $translate('REGISTRATION.MESSAGES.ERROR.ROLE_CANT_BE_REMOVED_BECAUSE_IT_WAS_NOT_FOUND_IN_THE_LIST', { prop: groupSelected.ROLE_REFERENCE.NAME });
                throw new Error(msgError);
            }

        } catch (e) {

            HandleError.exception(e);

        }


    }

    $scope.addRoleModal = async (): Promise<void> => {
        resetRole();

        $scope.role.CREATED_DATE = new Date();

        //Clear if wildcard
        if ($scope.searchRoles.wildcard == $scope.searchRoles.input) $scope.role.NAME = '';
        else $scope.role.NAME = $scope.searchRoles.input;

        $scope.roleOperation = 'register';

        await openRoleModal();

    }

    $scope.editRoleModal = async (role: CellRoleModel): Promise<void> => {
        $scope.role = role;
        $scope.roleOperation = 'edit';

        await openRoleModal();

    }

    //USERS
    $scope.loadUsers = (): Array<CellGroupUserModel> => {
        return users;
    }

    //SPECS
    $scope.addSpecModal = async (group: CellGroupModel): Promise<void> => {
        $scope.group = group;

        resetSpec();

        $scope.specOperation = 'register';

        await openSpecModal();
    }

    $scope.cloneRole = async (cell: CellModel, group: CellGroupModel): Promise<void> => {
        try {

            let result = await getGroupCell(cell, group);

            if (result) {
                blockUI.start();
                await updateCells();
                $scope.$applyAsync(function () { blockUI.stop(); });

                $scope.cell.GROUP.push(result);
                const msgSuccess = await $translate('REGISTRATION.MESSAGES.ERROR.ROLE_SUCECESSFULLY_COPIED');
                NotificationService.success(msgSuccess);
            }

        } catch (ex) {
            HandleError.exception(ex);
        }

    }

    $scope.editSpecModal = async (group: CellGroupModel, spec: CellGroupSpecModel): Promise<void> => {
        $scope.group = group;
        $scope.spec = spec;

        $scope.specOperation = $scope.operation;

        await openSpecModal();
    }


    //TODO: REMOVE ROOT SCOPE, WE CAN CALL ROOT SCOPE BACK DIRECTLY FROM VIEW
    //DOM
    $scope.back = () => {
        $rootScope.back();
    }

    $scope.closeDeletePopover = ($event: Event): void => {
        const popover = angular.element($event.target).parents('.popover');
        const trash = popover.prev('.fa-trash')
        trash.trigger('click');

        popover.remove();

    }

    $scope.showHideUsers = (users: Array<CellGroupUserModel>) => {
        if (angular.isArray(users)) {

            const list = users.slice(0, - $scope.usersLimit);

            if (angular.isArray(list)) {

                let names = [];
                list.forEach(user => {
                    names.push(user.USER_REFERENCE.name);
                });


                return names.join(', ');
            }
        }

        return '';

    };

    $scope.showHideCells = (cell: CellModel) => {
        let names: Array<string> = [];

        if (cell && angular.isArray(cell.GROUP)) {
            cell.GROUP.slice($scope.rolesLimit).forEach(role => {
                names.push(role.ROLE_REFERENCE.NAME);
            });
        }

        return names.join(', ');

    };

    //REQUESTS
    async function getGenericValuesByType(typeGeneric: string) {
        const { data: generic } = await helperService.get(`/generic/value/${typeGeneric}`, null);
        return generic && generic.data ? generic.data : [];
    }

    async function deleteCell(cell: CellModel): Promise<any> {
        try {
            const result = await operationalService.delete(`/cell/remove/${cell._id}`, null, 10000);
            return result.data;
        } catch (e) {
            HandleError.exception(e);
        }
    }

    async function getCellList(): Promise<any> {
        try {
            const result = await operationalService.get('/cell/list/', null, 12000);
            return result.data.data;
        } catch (e) {
            HandleError.exception(e);
        }
    }

    async function getRoleList(): Promise<any> {
        try {
            const result = await operationalService.get('/cell/role/list', null, 12000);
            return result.data.data;
        } catch (e) {
            HandleError.exception(e);
        }
    }

    async function getUserList(): Promise<IGenericResponse<UserModel>> {
        try {
            const result = await helperService.post(RouteConstants.USER_LIST, null, 12000);
            return result.data.data;
        } catch (e) {
            HandleError.exception(e);
        }
    }

    async function getRoleListFromCell(cellNumber, roleNumber): Promise<CellGroupModel> {
        try {
            blockUI.start();

            const response = await this.operationalService.get(`/cell/number/${cellNumber}/role/number/${roleNumber}`, null, 120000)

            $scope.$applyAsync(function () { blockUI.stop(); });

            return response.data.data;
        } catch (ex) {
            $scope.$applyAsync(function () { blockUI.stop(); });
            HandleError.exception(ex);
        }
    }

    async function getGroupCell(cell: CellModel, group: CellGroupModel): Promise<CellGroupModel> {
        try {
            blockUI.start();

            const cellNumber = cell.CELL_NUMBER;
            const roleNumber = group.ROLE_REFERENCE.ROLE_NUMBER;

            const response = await operationalService.get(`/cell/number/${cellNumber}/role/number/${roleNumber}`, null, 120000)

            $scope.$applyAsync(function () { blockUI.stop(); });

            return response.data.data;
        } catch (ex) {
            $scope.$applyAsync(function () { blockUI.stop(); });
            HandleError.exception(ex);
        }
    }


    //AUXS
    async function castUserToUserCell(): Promise<Array<CellGroupUserModel>> {
        try {
            const response: IGenericResponse<UserModel> = await getUserList();

            let list = [];
            if (response.data) list = response.data;

            let users: Array<CellGroupUserModel> = [];
            list.forEach((user: UserModel) => {

                //only status ok
                if (user.status === true) {
                    users.push({
                        USER_REFERENCE: user,
                        SINCE: null,
                    });
                }

            });

            return users;

        } catch (ex) {
            HandleError.exception(ex);
        }

    }

    function indexOfGroup(group: CellGroupModel, groups: Array<CellGroupModel>) {
        return ArrayUtil.indexOfMongo(group, groups);
    }

    function getUnselectdsRoles(roles: Array<CellRoleModel>): Array<CellRoleModel> {
        const results: Array<CellRoleModel> = [];

        if (roles && angular.isArray(roles)) {
            //get unselecteds
            const tempRoles: Array<CellRoleModel> = [];

            $scope.cell.GROUP.forEach((group) => {
                tempRoles.push(group.ROLE_REFERENCE);
            });

            roles.forEach((role: CellRoleModel) => {
                const index = ArrayUtil.indexOfMongo(role, tempRoles)
                const hasSelected = index > -1;

                if (!hasSelected) results.push(role);
            });
        }

        return results;
    }

    async function saveCell(operation: string, cell: CellModel): Promise<any> {
        if (!operation || !cell) return null;

        try {
            //create the request object
            const request = {
                operation: operation,
                data: cell
            };

            let result = await operationalService.post('/cell/insert', request, 12000);
            result = (result && result.data && result.data.data) ? result.data.data : null;

            return result;
        } catch (ex) {
            HandleError.exception(ex);
            return null;
        }
    }

    async function updateRoles() {
        try {
            blockUI.start();

            const response = await getRoleList();

            $scope.roles = getUnselectdsRoles(response.data);

            $scope.$applyAsync(function () { blockUI.stop(); });

            return response.data;
        } catch (ex) {
            $scope.$applyAsync(function () { blockUI.stop(); });
            HandleError.exception(ex);
        }
    }

    async function updateCells(): Promise<Array<CellModel>> {
        try {
            const response = await getCellList();
            $scope.cells = response.data;

            return response.data;

        } catch (ex) {

            HandleError.exception(ex);
        }

    }

    async function openRoleModal() {
        const modal = ModalService.showModalInfo(
            {
                template: require("../view/roleRegistrationModal.html"),
                scope: $scope,
                animation: true,
                keyboard: true,
                modalFade: true,
                size: 'lg'
            },
            {
                actionButtonText: await $translate('GENERAL.CANCEL'),
            }
        );
    }

    async function openSpecModal(): Promise<void> {
        const modal = ModalService.showModalInfo(
            {
                template: require("../view/specRegistrationModal.html"),
                scope: $scope,
                animation: true,
                keyboard: true,
                modalFade: true,
                size: 'full'
            },
            {
                actionButtonText: await $translate('GENERAL.CANCEL'),
            }
        );

    }

    function resetCell() {
        const cell: CellModel = {
            _id: null,
            ID: null,
            CELL_NUMBER: null,
            NAME: null,
            DESCRIPTION: null,
            GROUP: [],
            CREATED_DATE: null
        }

        $scope.cell = cell;
    }

    function resetSpec() {
        const spec = {
            ID: null,
            NAME: null,
            BRANCH: [],
            PRODUCT: [],
            CLIENT: [],
            PROCESS_TYPE: [],
            SERVICE_PROVIDER: [],
            AGENT: [],
            CUSTOMER_QUALIFICATION: [],
            COMMERCIAL_UNITY: [],
            TRADE_GROUP_ORIGIN: [],
            TRADE_GROUP_DESTINATION: [],
            CARGO_TYPE: [],
            SINCE: null
        }

        $scope.spec = spec;
    }

    function resetRole() {
        const role: CellRoleModel = {
            _id: null,
            ID: null,
            ROLE_NUMBER: null,
            NAME: null,
            DESCRIPTION: null,
            CREATED_DATE: null,
        }

        $scope.role = role;
    }

    function validateCell(): boolean {
        try {
            let isValid = true;
            if ($scope.cell && angular.isArray($scope.cell.GROUP)) {

                //validate role to cell
                if ($scope.cell.GROUP.length) {

                    //validate user to role
                    $scope.cell.GROUP.forEach((group) => {
                        let showError = false;
                        if (angular.isArray(group.USER)) {

                            if (group.USER.length === 0) {
                                showError = true;
                                isValid = false;
                            }

                        } else {
                            showError = true;
                            isValid = false;
                        }

                        if (showError == true) {

                            const options: INotificationOptions = {
                                elementPosition: 'left'
                            }

                            NotificationService.element(`#${group.ROLE_REFERENCE._id} .userIcon`).error('Adicione um usuário a este papel.', null, options);
                        }
                    });
                } else {
                    const options: INotificationOptions = {
                        elementPosition: 'bottom right'
                    }

                    isValid = false;
                    NotificationService.element('#roles').error('Adicione um papel a esta célula.');
                    NotificationService.element('#searchRoles .fa-search').info('Faça uma busca e associe um papel a esta célula.', null, options);
                }
            } else {
                throw new Error('Não foi possível encontrar a Célula para editar.');
            }

            return isValid;

        } catch (ex) {
            HandleError.exception(ex);
        }

    }

}

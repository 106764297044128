import * as angular from "angular";
import { IColumnDef, IGridOptions } from "ui-grid";
import { GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { IGridFormController, IGridFormServiceScope, GridFormService } from "@services/GridFormService";
import { IHoliday } from "WBA-Model/dist/interface/operational/IHoliday";
import moment = require("moment");
import { BrowserTitle } from "../../common/BrowserTitle";
import { EOperation } from "@enums/GenericData";
import { HelperService } from "@services/HelperService";
import { ISessionService } from "@services/SessionService";
import { SSEService } from "@appServices/SSEService";
import { IRestService } from "@services/RestService";
import { DataOperationalService } from "@services/DataOperationalService";
import { IDocumentError } from "@models/interface/common/IDocumentError";
import { IModalService } from "@services/ModalService";

interface IVariableDate {
    year: string;
    date: string;
}

interface IScopeData {
    date: Date;
    variableDates: Array<IVariableDate>;
}

interface IHolidayScope extends IGridFormServiceScope {
    user: any;
    gridOptions: IGridOptions;
    model: IHoliday;
    modalError: string;
    modalSuccess: string;
    scopeData: IScopeData;
    sessionService: ISessionService;
    scopeBeforeSave: IHoliday;

    viewHoliday: (holiday: IHoliday) => Promise<void>;
    customEdit: (holiday: IHoliday) => void;
    copyHoliday: (holiday: IHoliday) => Promise<void>;
    addVariableDate: () => void;
    updateHolidayDate: () => void;
    changeVariableDate: () => void;
    changeVariableDateYear: () => void;
    formatVariableDateField: () => void;
    removeVariableDate: (year: string) => Promise<void>;
    fetchData: (id: number, action: string) => Promise<void>;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
}

export class HolidayRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope', '$element'];
    private $q: ng.IQService;
    private $scope: IHolidayScope;
    private helperService: HelperService;
    private gridName: string;
    private SSEService: SSEService;
    private RestService: IRestService;
    private modalID: number;
    private dataOperationalService: DataOperationalService;
    private ModalService: IModalService;
    private $timeout: ng.ITimeoutService;

    constructor($injector: ng.Injectable<any>, $scope: IHolidayScope, $element: JQLite) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.RestService = $injector.get('RestService');
        this.helperService = $injector.get('HelperService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.$scope.sessionService = $injector.get('SessionService');
        this.dataOperationalService = $injector.get('DataOperationalService');
        this.ModalService = $injector.get('ModalService');
        this.gridName = 'GRID_HOLIDAY';
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlHelper();
            this.initForm(this, 'form', 'holiday', 'GENERAL.MENU.HOLIDAY', true);
            await this.initGrid(this.gridName, '/holiday/list', true, true, 15000, true, true);
            this.$scope.dateFormat = "dd/MM";
            $('#holidayDate')['mask']('00/00');
            this.SSEService.closeEvents();
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.customEdit = (holiday: IHoliday): void => {
            this.editHoliday(holiday);
        }

        this.$scope.viewHoliday = async (holiday: IHoliday): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(holiday._id, EOperation.VIEW);
        }

        this.$scope.copyHoliday = async (holiday: IHoliday): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(holiday._id, EOperation.COPY);
        }

        this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
            this.fetchData(id, action);
        }

        this.$scope.updateHolidayDate = async (): Promise<void> => {
            this.updateHolidayDate();
        }

        this.$scope.removeVariableDate = async (year: string): Promise<void> => {
            this.removeVariableDate(year);
        }

        this.$scope.addVariableDate = async (): Promise<void> => {
            this.addVariableDate();
        }

        this.$scope.changeVariableDate = async (): Promise<void> => {
            this.changeVariableDate();
        }

        this.$scope.formatVariableDateField = async (): Promise<void> => {
            this.formatVariableDateField();
        }

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }
    }

    initGridColumns(columns) {
        const columnDefs: Array<IColumnDef> = new Array();
        const view = `<div class="text-center"><a ng-click="grid.appScope.viewHoliday(row.entity)" class="text-info"tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.customEdit(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyHoliday(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity._id, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 110,
            maxWidth: 110,
            cellTemplate: (view + edit + copy + modalIntegration),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        for (let i = 0; i < columns.length; i++) {
            let name = null;
            let displayName = null;
            let width = 0;
            let cellTemplate = undefined;
            let cellFilter = null;
            let visible = true;
            let filter: uiGrid.IFilterOptions = { condition: this.gridService.filterSelectObject };
            let searchProps = null;

            switch (columns[i].toUpperCase()) {
                case 'TITLE':
                    name = columns[i];
                    displayName = 'GENERAL.NAME';
                    width = columns[i].length + 10;
                    cellTemplate = '<div class="ui-grid-cell-contents">{{ row.entity.title | uppercase}}<div>';
                    break;
                case 'DATE':
                    name = columns[i];
                    displayName = 'GENERAL.DATE';
                    width = 5;
                    cellTemplate = '<div class="ui-grid-cell-contents">{{ row.entity.date ? row.entity.date : "N/A"}}<div>';
                    break;
                case 'DESCRIPTION':
                    name = columns[i];
                    displayName = 'GENERAL.DESCRIPTION';
                    width = 30;
                    cellTemplate = '<div class="ui-grid-cell-contents"> {{ row.entity.description }}<div>';
                    break;
                case 'TYPE':
                    name = columns[i];
                    displayName = 'GENERAL.TYPE';
                    width = 8;
                    cellTemplate = '<div class="ui-grid-cell-contents"> {{ row.entity.type }}<div>';
                    break;
                default:
                    continue;
            }

            const newColumn = {
                name: name,
                displayName: displayName,
                headerCellClass: this.$gridService.highlightFilteredHeader.bind(this.$gridService),
                width: width + '%',
                cellTemplate: cellTemplate,
                cellFilter: cellFilter,
                visible: visible,
                filter: filter,
                searchProps: searchProps,
            };
            columnDefs.push(newColumn);
        }
        return columnDefs;
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            date: null,
            description: null,
            endTime: "",
            legislation: null,
            startTime: "",
            title: null,
            type: "feriado",
            variableDates: null,
            DOCUMENT_ERROR: null
        };

        this.$scope.scopeData = {
            date: null,
            variableDates: []
        };
    }

    initDependencies(): Promise<any> {
        const self: HolidayRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
            ]).then((result: any) => {
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private getUrlHelper(): string {
        const baseRoute = '/helper';
        const urlDataManager = this.config.helperUrl + baseRoute;
        return urlDataManager;
    }

    private editHoliday = async (holiday: IHoliday): Promise<void> => {
        let blockedObject = {
            ID: holiday._id,
            NAME: holiday.title,
            EMAIL: this.$scope.user['email'],
            FORM_NAME: this.gridName
        };
        this.SSEService.closeEvents();
        this.SSEService.setBlockedObject(blockedObject);
        this.SSEService.initEvents();
        this.SSEService.events.onmessage = async (event) => {
            const parsedData = JSON.parse(event.data);
            if (!parsedData.status) {
                const result = await this.SSEService.generate(parsedData);
                if (result && !result.status) {
                    this.$rootScope.refreshPage();
                    return;
                }
                if (this.$scope.operation !== EOperation.VIEW || holiday.ID !== this.$scope.model.ID) this.$scope.fetchData(holiday._id, EOperation.VIEW);
            } else if (this.$scope.operation !== EOperation.EDIT || holiday._id !== this.$scope.model.ID) {
                this.$scope.fetchData(holiday._id, EOperation.EDIT);
            }
        };
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('REGISTRATION.NEW_HOLIDAY');
            this.$scope.disableElements(false);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} (${this.$scope.model.title})`;
            this.loadHolidayDate();
            this.loadVariableDate();
            BrowserTitle.$id = this.$scope.model.title;
            this.$scope.disableElements(true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} (${this.$scope.model.title})`;
            this.loadHolidayDate();
            this.loadVariableDate();
            BrowserTitle.$id = this.$scope.model.title;
            this.$scope.disableElements(false);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request() {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        return {
            route: `/holiday/${route}`,
            data: angular.copy(this.$scope.model),
            timeout: 15000
        };
    }

    async save(): Promise<boolean> {
        try {
            if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
                try {
                    this.SSEService.closeEvents();
                    this.constructVariableDates();
                    return true;
                } catch (ex) {
                    this.handleError(ex);
                    return false;
                }
            }
            return true;
        } catch (ex) {
            this.$formService.handleLoadError(ex);
        }
    }

    private updateHolidayDate() {
        try {
            if (!this.$scope.scopeData.date) return;

            this.$scope.model.date = this.$scope.scopeData.date.getDate().toString().padStart(2, '0') + "/" + (this.$scope.scopeData.date.getMonth() + 1).toString().padStart(2, '0');
            this.$scope.model.variableDates = {};
            this.$scope.scopeData.variableDates = [];
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private loadHolidayDate() {
        try {
            if (!this.$scope.model.date) return;
            let currentDate = moment(this.$scope.model.date, 'DD/MM');
            this.$scope.scopeData.date = currentDate.toDate();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private loadVariableDate() {
        try {
            if (!this.$scope.model.variableDates) return;

            for (let modelDate in this.$scope.model.variableDates) {
                this.$scope.scopeData.variableDates.push(
                    { date: this.$scope.model.variableDates[modelDate], year: modelDate }
                );
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private removeVariableDate(year: string) {
        try {
            if (this.$scope.operation === EOperation.VIEW) return;

            if (!this.$scope.scopeData || !this.$scope.scopeData.variableDates) return;
            this.$scope.scopeData.variableDates = this.$scope.scopeData.variableDates.filter((current) => current.year != year);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private addVariableDate() {
        try {
            if (this.$scope.operation === EOperation.VIEW) return;

            this.$scope.model.date = "";
            this.$scope.scopeData.date = null;

            const currentDates = this.$scope.scopeData.variableDates;
            let newDate = new Date().getFullYear().toString();
            if (currentDates && currentDates[0] && parseInt(newDate) < parseInt(currentDates[0].year)) newDate = currentDates[0].year;
            for (let index = 0; index < currentDates.length; index++) {
                if (currentDates.find((current) => current.year == newDate)) {
                    newDate = (parseInt(newDate) + 1).toString();
                } else {
                    break;
                }
            }
            currentDates.push({ date: null, year: newDate });
            this.$scope.scopeData.variableDates = currentDates.sort((curr, other) => {
                const curYear = parseInt(curr.year);
                const otherYear = parseInt(other.year);
                if (curYear > otherYear) return 1;
                if (curYear < otherYear) return -1;
                return 0;
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private changeVariableDate() {
        this.$scope.model.date = "";
        this.$scope.scopeData.date = null;
    }

    private constructVariableDates() {
        let newDates = {};
        this.$scope.scopeData.variableDates.forEach(element => {
            newDates[element.year] = element.date;
        });
        this.$scope.model.variableDates = newDates;
    }

    private formatVariableDateField() {
        let inputYearFields = $("*[id*=variableDateYear]");
        inputYearFields.each(function () {
            $(this)['mask']('0000');
        });
        let inputDayFields = $("*[id*=variableDateDay]");
        inputDayFields.each(function () {
            $(this)['mask']('00/00');
        });
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "Integration Product/Operation/Fatturo" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                const rc = await this.dataOperationalService.post(`/sync/holiday`, { "id": [id] }, 120000);
                if (rc && rc.data && rc.data.data && rc.status == 200) success = true;
            }
        } catch (ex) {
            const msgError = this.formService.getTranslate('GENERAL.ERROR_SENDING_REQUEST');
            this.formService.handleError(msgError);
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const contractData = await this.getHolidayById(id);
                    if (row && contractData && contractData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = contractData.DOCUMENT_ERROR;
                        documentError = contractData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            const msgError = this.formService.getTranslate('GENERAL.ERROR_DURING_REQUEST');
            this.formService.handleError(msgError);
        } finally {
            return documentError;
        }
    }

    private async getHolidayById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const holidayResult = await this.RestService.newObjectPromise(`${this.getUrlHelper()}/holiday/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return holidayResult.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');
            const request = await this.RestService.getObjectAsPromise(`${this.helperService.$route}/holiday/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);
                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }
}

import * as angular from "angular";
import { IHttpResponse } from "angular";
import { fileUploader as FileUploader } from 'angular-file-upload';
import { GridFormService, IGridFormController, IGridFormServiceScope } from "@services/GridFormService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IModalService } from "@services/ModalService";
import { ISessionService } from "@services/SessionService";
import { IRestService } from "@services/RestService";
import { OperationalService } from '@services/OperationalService';
import { ProductService } from '@services/ProductService';
import { FollowUpProcessModel, PROCESS, MESSAGE_METADATA, FAILED, REFERENCE } from "@models/interface/operational/FollowUpProcessModel";
import { ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IViewLog } from "@models/interface/common/IViewLog";
import { FileReferenceModel } from "@models/interface/operational/FileReferenceModel";
import { IUploader, IUploadItem, IFileItem, IFormData, ISuccessItemResponse } from "@models/interface/common/IMonacoUpload";
import { UserModel } from '@models/interface/common/UserModel';
import { ISelectorModel } from "@models/mongo/SelectorModel";
import { Process } from "@models/interface/operational/NewProcess";
import { BrowserTitle } from "../../common/BrowserTitle";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { SelectorModel } from "../../common/model/SelectorModel";
import { ElasticStatus } from "../../common/interface/IElasticStatus";
import { IProcessParameter } from "../../common/model/ModelParameter";
import { EOperation } from "@enums/GenericData";
import { FormService2 } from "../../services/FormService2";
import { HelperService } from "@services/HelperService";

interface IMailStatus {
    email: string;
    opened: boolean;
    sent: boolean;
    delivered: boolean;
    failed: boolean;
    failedDescription;
}

interface IFiles {
    FILES: IUploadItem[];
}

interface IOperationCallSession {
    operation: string;
    process?: string;
    customer?: ISelectorModel;
}

interface FollowUpProcessModelStatus extends FollowUpProcessModel {
    STATUS_TXT: SelectorModel;
}

interface INotificationMonitorScope extends IGridFormServiceScope {
    model: FollowUpProcessModelStatus;
    log: IViewLog;
    user: UserModel;
    customLogProperties: ICustomLogProperties[];
    recipientList: SelectorModel[];
    copyGroupList: SelectorModel[];
    copyHiddenList: SelectorModel[];
    sendGroupList: SelectorModel[];
    typeList: SelectorModel[];
    peopleList: SelectorModel[];
    destinTypeList: SelectorModel[];
    processReferenceList: REFERENCE[];
    messageOpenedList: SelectorModel[];
    messageSentList: SelectorModel[];
    messageDeliveredList: SelectorModel[];
    messageFailedList: SelectorModel[];
    editorOptions: Summernote.Options;
    mailStatusList: IMailStatus[];
    uploader: IUploader;
    fileModel: IFiles;
    processReference: REFERENCE;
    isRefresh: (model: FollowUpProcessModelStatus) => boolean;
    refresh: (model: FollowUpProcessModelStatus) => void;
    isStop: (model: FollowUpProcessModelStatus) => boolean;
    stop: (model: FollowUpProcessModelStatus) => void;
    isStart: (model: FollowUpProcessModelStatus) => boolean;
    start: (model: FollowUpProcessModelStatus) => void;
    isResend: (model: FollowUpProcessModelStatus) => boolean;
    resend: (model: FollowUpProcessModelStatus) => void;
    goToProcess: (process: string) => void;
    openLink: (file: FileReferenceModel) => void;
    chooseIcon: (file: FileReferenceModel) => string;
    removeUpload: (model: IUploadItem) => Promise<boolean>;
    getProcessReference: (search: string) => Promise<void>;
    addProcessFiles: (reference: REFERENCE) => Promise<void>;
    refreshPeople: (query: string) => Promise<void>;
    getRecipientList: () => Promise<void>;
    getCopyGroupList: () => Promise<void>;
    getcopyHiddenList: () => Promise<void>;
    getAndUpdateContactByTypeAndGroup: (type: string) => Promise<void>;
    clearContacts: (field: string) => void;
}

export interface IMonacoRequest<T = any> {
    route?: string;
    operation?: string;
    data?: T;
    timeout?: number;
    user?: any;
}

export class NotificationMonitorRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ["$injector", "$scope", "$element"];
    private $scope: INotificationMonitorScope;
    private ModalService: IModalService;
    private FormService: FormService2;
    private sessionService: ISessionService;
    private RestService: IRestService;
    private $q: ng.IQService;
    private fileUploader: FileUploader;
    private $http: ng.IHttpService;
    private OperationalService: OperationalService;
    private ProductService: ProductService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: INotificationMonitorScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$baseUrl = this.config.externalUrl + '/external';
        this.ModalService = $injector.get('ModalService');
        this.FormService = $injector.get('FormService');
        this.sessionService = $injector.get('SessionService');
        this.RestService = $injector.get('RestService');
        this.fileUploader = $injector.get('FileUploader');
        this.$http = $injector.get('$http');
        this.OperationalService = $injector.get('OperationalService');
        this.ProductService = $injector.get('ProductService');
        this.helperService = $injector.get('HelperService');

        this.$scope.editorOptions = {
            height: 300,
            toolbar: [
                ['style', ['style']],
                ['fontsize', ['fontsize', 'fontname']],
                ['font', ['bold', 'underline', 'clear']],
                ['color', ['color']],
                ['para', ['ul', 'ol', 'paragraph']],
                ['table', ['table']],
                ['insert', ['link', 'picture']],
                ['view', ['codeview']],
            ],
            dialogsInBody: true,
            minHeight: 300,
            maxHeight: 300,
        }
    }

    async $onInit(): Promise<void> {
        try {
            this.$scope.uploader = this.getFileUploaderDefault();

            this.initForm(this, "form", "notificationMonitor", "GENERAL.MENU.FOLLOW_UP", false);
            await this.initGrid("notificationMonitor", "/notificationMonitor/list", true, true, 120000, true, true);
            this.$gridService.setBackgroundUpdate(60000);

            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.$scope.fileModel = <IFiles>{ FILES: [] };
            this.$scope.processReference = null;
            this.$scope.mailStatusList = [];

            const sessionParameter = this.$gridService.$sessionParameter;
            if (sessionParameter && sessionParameter.data) {
                const data = <IOperationCallSession>sessionParameter.data;
                await this.callSessionFunctions(data)
            }
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.isRefresh = (model: FollowUpProcessModelStatus) => {
            return this.isRefresh(model);
        }

        this.$scope.refresh = async (model: FollowUpProcessModelStatus) => {
            await this.refresh(model);
        }

        this.$scope.isStop = (model: FollowUpProcessModelStatus) => {
            return this.isStop(model);
        }

        this.$scope.stop = async (model: FollowUpProcessModelStatus) => {
            await this.stop(model);
        }

        this.$scope.isStart = (model: FollowUpProcessModelStatus) => {
            return this.isStart(model);
        }

        this.$scope.start = async (model: FollowUpProcessModelStatus) => {
            await this.start(model);
        }

        this.$scope.resend = async (model: FollowUpProcessModelStatus) => {
            await this.resend(model);
        }

        this.$scope.isResend = (model: FollowUpProcessModelStatus): boolean => {
            return this.isResend(model);
        }

        this.$scope.goToProcess = (process: string) => {
            this.goToProcess(process);
        }

        this.$scope.openLink = (file: FileReferenceModel) => {
            this.openLink(file);
        }

        this.$scope.chooseIcon = (file: FileReferenceModel) => {
            return this.chooseIcon(file);
        }

        this.$scope.removeUpload = (model: IUploadItem): Promise<boolean> => {
            return this.removeUpload(model);
        }

        this.$scope.getProcessReference = async (): Promise<void> => {
            let processReferenceList: REFERENCE[] = [];
            processReferenceList = await this.getProcessReference();
            this.$scope.processReferenceList = processReferenceList;
        }

        this.$scope.addProcessFiles = async (reference: REFERENCE) => {
            await this.addProcessFiles(reference);
        }

        this.$scope.refreshPeople = async (query: string): Promise<void> => {
            let peopleList: SelectorModel[] = [];
            peopleList = await this.refreshRecipientInternalContact(query);
            this.$scope.peopleList = peopleList;
        }

        this.$scope.getRecipientList = async (): Promise<void> => {
            let recipientList: ISelectorModel[] = [];
            recipientList = await this.getContacts([this.$scope.model.RECIPIENT_TYPE]);
            this.$scope.recipientList = recipientList;
        }

        this.$scope.getCopyGroupList = async (): Promise<void> => {
            let copyGroupList: ISelectorModel[] = [];
            copyGroupList = await this.getContacts(this.$scope.model.COPY_GROUP_TYPE);
            this.$scope.copyGroupList = copyGroupList;

        }

        this.$scope.getcopyHiddenList = async (): Promise<void> => {
            let copyHiddenList: ISelectorModel[] = [];
            copyHiddenList = await this.getContacts(this.$scope.model.COPY_HIDDEN_GROUP_TYPE);
            this.$scope.copyHiddenList = copyHiddenList;
        }

        this.$scope.getAndUpdateContactByTypeAndGroup = async (type: string): Promise<void> => {

            if (type == 'SEND_GROUP' || type == 'RECIPIENT_TYPE') {
                if (this.$scope.model.RECIPIENT_TYPE) {
                    const types = (this.$scope.model.RECIPIENT_TYPE.ID != '4') ? [this.$scope.model.RECIPIENT_TYPE] : [];
                    const contacts = await this.getContacts(types, this.$scope.model.SEND_GROUP ? this.$scope.model.SEND_GROUP.map(sendGroup => sendGroup.ID) : null);
                    this.$scope.model.PROCESS.RECIPIENT = contacts;
                }
            }

            if (type == 'SEND_GROUP' || type == 'COPY_GROUP_TYPE') {
                if (this.$scope.model.COPY_GROUP_TYPE) {
                    const types = this.$scope.model.COPY_GROUP_TYPE ? this.$scope.model.COPY_GROUP_TYPE.filter(groupType => groupType.ID != "4") : [];
                    const contacts = await this.getContacts(types, this.$scope.model.SEND_GROUP ? this.$scope.model.SEND_GROUP.map(sendGroup => sendGroup.ID) : null);
                    this.$scope.model.PROCESS.COPY_GROUP = contacts;
                }
            }

            if (type == 'SEND_GROUP' || type == 'COPY_HIDDEN_GROUP_TYPE') {
                if (this.$scope.model.COPY_HIDDEN_GROUP_TYPE) {
                    const types = this.$scope.model.COPY_HIDDEN_GROUP_TYPE ? this.$scope.model.COPY_HIDDEN_GROUP_TYPE.filter(groupType => groupType.ID != "4") : [];
                    const contacts = await this.getContacts(types, this.$scope.model.SEND_GROUP ? this.$scope.model.SEND_GROUP.map(sendGroup => sendGroup.ID) : null);
                    this.$scope.model.PROCESS.COPY_HIDDEN_GROUP = contacts;
                }
            }

            this.$scope.$applyAsync();
        }

        this.$scope.clearContacts = (field: string): void => {
            switch (field) {
                case "RECIPIENT":
                    this.$scope.model.PROCESS.RECIPIENT = null;
                    this.$scope.recipientList = [];
                    break;
                case "COPY_GROUP":
                    this.$scope.model.PROCESS.COPY_GROUP = null;
                    this.$scope.copyGroupList = [];
                    break;
                case "COPY_HIDDEN_GROUP":
                    this.$scope.model.PROCESS.COPY_HIDDEN_GROUP = null;
                    this.$scope.copyHiddenList = [];
                    break;
            }

        }

    }

    private async callSessionFunctions(data: IOperationCallSession) {

        let model = angular.copy(this.$scope.model);

        if (data.operation != 'register' && this.$gridService.$gridOptions && this.$gridService.$gridOptions.data.length == 1) {
            model = this.$gridService.$gridOptions.data[0];
        }

        switch (data.operation) {
            case 'register':
                await this.$scope.register();
                this.$scope.model.INSERT_DATE = new Date();
                this.$scope.model.PROCESS = <PROCESS>{};
                this.$scope.model.PROCESS.PROCESS_NUMBER = data.process;
                const timeout = 120000;
                const result = await this.OperationalService.get(`/process/byNumber/${this.$scope.model.PROCESS.PROCESS_NUMBER}/${timeout}`, timeout);
                const processInfo: Process = result.data.data ? result.data.data[0] : null;

                this.$scope.model.PROCESS.BOOKING = processInfo.BOOKING;
                this.$scope.model.PROCESS.CHECKEDTOSEND = true;
                this.$scope.model.PROCESS.COPY_GROUP = null;
                this.$scope.model.PROCESS.COPY_HIDDEN_GROUP = null;
                this.$scope.model.PROCESS.CUSTOMER = data.customer;
                this.$scope.model.PROCESS.DESTINATION = processInfo.DESTINATION;
                this.$scope.model.PROCESS.ORIGIN = processInfo.ORIGIN;
                this.$scope.model.PROCESS.RECIPIENT = null;
                this.$scope.model.PROCESS.SENDER = <ISelectorModel>{
                    ID: this.$scope['user'].email,
                    NAME: this.$scope['user'].displayName,
                    CODE: this.$scope['user'].email
                };
                this.$scope.model.PROCESS.REFERENCE = [];

                this.$scope.model._id = null;
                this.$scope.model.CONTENT = null;
                this.$scope.model.LANGUAGE = processInfo.ACCOUNT_REQUIREMENT.LANGUAGE;

                this.$scope.model.MESSAGE_METADATA = <MESSAGE_METADATA>{
                    TRANSACTION_ID: null,
                    STATUS: null,
                    OPENED: null,
                    SENT: null,
                    DELIVERED: null,
                    FAILED: null,
                    MANUAL: false,
                    VOUCHER: null
                };

                this.$scope.model.EXCEPTION = <ISelectorModel>{
                    ID: "2",
                    NAME: "Não"
                };

                break;
            case EOperation.VIEW:
                await this.$scope.view(model);
                break;
            case EOperation.EDIT:
                await this.$scope.edit(model);
                break;
            case EOperation.COPY:
                await this.$scope.copy(model);
                break;
            case EOperation.LOG:
                await this.$scope.viewLog(model);
                break;
        }

    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'allog_file_integration_service', formName: "notificationMonitor", folder: "notificationMonitor" };
            return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData], onSuccessItem: (item: IFileItem, response: ISuccessItemResponse, files: IFiles) => this.onSuccessItem(item, response, this.$scope.fileModel) });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    getFisFilesGenericRoute(): string {
        const baseRoute = '/fis/filesGeneric';
        const uploadRoute = this.config.fisUrl + baseRoute;
        return uploadRoute;
    }

    private onSuccessItem(item: IFileItem, response: ISuccessItemResponse, files: IFiles): void {
        try {
            if (!item) throw Error('item parameter is null');
            if (!response) throw Error('response parameter is null');

            if (response.data && response.data.length > 0) {
                for (let dataItem of response.data) {
                    const reference = <REFERENCE>{};
                    reference.REFERENCE_ID = this.$scope.model.FOLLOWUPPROCESS_NUMBER + "_" + (this.$scope.model.PROCESS.REFERENCE.length + 1).toString();
                    reference.ID_CONSOLIDATED = null;
                    reference.SOURCE = "MONACO";
                    reference.TYPE = "TEMPLATE";
                    reference.MAIN_FILE = false;
                    reference.DOCUMENT_TYPE = null;
                    reference.DOCUMENT_VALUE = null;
                    reference.TEMPLATE_TYPE = null;
                    reference.FILE_GROUP = null;
                    reference.FILE_SPECS = null;
                    reference.INSERT_DATE = null;
                    reference.USER_REFERENCE = null;
                    reference.NAME = dataItem.fileName;
                    reference.LINK = dataItem.fileUrl;

                    this.$scope.model.PROCESS.REFERENCE.push(reference);
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeUpload(model: IUploadItem): Promise<boolean> {
        let result = false;
        try {
            if (!model) throw Error('item parameter is null');
            const thatTranslated = this.formService.getTranslate("GENERAL.GENDER.THAT", null, true);
            const anexoTranslated = this.formService.getTranslate("REGISTRATION.EMAIL_ATTACHMENT", null, true);
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                headerText: 'GENERAL.CONFIRM_ACTION',
                closeButtonText: 'GENERAL.CLOSE',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thatTranslated, prop: anexoTranslated })
            });
            if (!modal) return;

            if (this.$scope.model.PROCESS.REFERENCE && this.$scope.model.PROCESS.REFERENCE.length > 0) {
                const index = this.$scope.model.PROCESS.REFERENCE.findIndex(file => { return file.NAME == model.FILE_NAME });
                if (index > -1) {
                    this.$scope.model.PROCESS.REFERENCE.splice(index, 1);
                    result = true;
                }
            }
        } catch (ex) {
            result = false;
            this.handleError(ex);
        } finally {
            return result;
        }
    }

    private async addProcessFiles(reference: REFERENCE) {
        const extensionRemoveList: string[] = (reference.NAME) ? reference.NAME.split('.') : null;
        const extension: string = (extensionRemoveList && extensionRemoveList.length > 0) ? extensionRemoveList[extensionRemoveList.length - 1] : null;

        this.$scope.fileModel.FILES.push({
            FILE_DISPLAY_NAME: reference.NAME,
            FILE_NAME: reference.NAME,
            FILE_TYPE: extension,
            FILE_PATH: null,
            FILE_URL: reference.LINK,
            FILE_HASH: null,
            FORM_NAME: "notificationMonitor"
        });
        this.$scope.model.PROCESS.REFERENCE.push(reference);
    }

    private openLink(file: FileReferenceModel) {
        try {
            if (!file) return;

            if (file.LINK && file.LINK.startsWith("https://")) {
                window.open(file.LINK, '_blank');
            }
            else {
                this.formService.notifyWarning("Acesso ao arquivo não disponível");
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private chooseIcon(file: FileReferenceModel): string {
        try {
            if (!file) return;

            const extensionRemoveList: string[] = (file && file.NAME) ? file.NAME.split('.') : null;
            const extension: string = (extensionRemoveList && extensionRemoveList.length > 0) ? extensionRemoveList[extensionRemoveList.length - 1] : null;

            switch (extension) {
                case "pdf": return "fa fa-file-pdf-o text-danger link-icon";
                case "xls": return "fa fa-file-excel-o text-green link-icon";
                case "xlsx": return "fa fa-file-excel-o text-green link-icon";
                case "doc": return "fa fa-file-word-o text-blue link-icon";
                case "docx": return "fa fa-file-word-o text-blue link-icon";
                case "docx": return "fa fa-file-word-o text-blue link-icon";
                case "zip": return "fa fa-file-archive-o text-orange link-icon";
                case "eml": return "fa fa-envelope-o text-yellow link-icon";

                default: return "fa fa-file text-grey link-icon";
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private goToProcess(process: string) {
        this.sessionService.openTab("app.operational.newProcess.list", <IProcessParameter>{ PROCESS_NUMBER: process });
    }

    initGridColumns(): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        gridColumns.includeActionBar(
            [
                GridColumnBuilderConstants.BTN_VIEW,
                GridColumnBuilderConstants.BTN_EDIT,
                GridColumnBuilderConstants.BTN_COPY,
                GridColumnBuilderConstants.BTN_VIEWLOG,
            ], 30);

        const sendStatus = `        
        <span ng-if="row.entity.STATUS_TXT.ID === '1'"><a class="fa fa-square-o text-gray" tooltip-placement="auto top" uib-tooltip="{{row.entity.STATUS_TXT.NAME | translate }}"></a>&nbsp;&nbsp;</span>
        <span ng-if="row.entity.STATUS_TXT.ID === '2'"><a class="fa fa-check-square-o text-gray" tooltip-placement="auto top" uib-tooltip="{{row.entity.STATUS_TXT.NAME}}"></a>&nbsp;&nbsp;</span>
        <span ng-if="row.entity.STATUS_TXT.ID === '3'"><a class="fa fa-check-square-o text-orange" tooltip-placement="auto top" uib-tooltip="{{row.entity.STATUS_TXT.NAME}}"></a>&nbsp;&nbsp;</span>
        <span ng-if="row.entity.STATUS_TXT.ID === '4'"><a class="fa fa-check text-blue" tooltip-placement="auto top" uib-tooltip="{{row.entity.STATUS_TXT.NAME}}"></a>&nbsp;&nbsp;</span>        
        <span ng-if="row.entity.STATUS_TXT.ID === '5'"><a class="fa fa-check text-gray" tooltip-placement="auto top" uib-tooltip="{{row.entity.STATUS_TXT.NAME}}"></a>&nbsp;&nbsp;</span>
        <span ng-if="row.entity.STATUS_TXT.ID === '6'"><a class="fa fa-exclamation-triangle text-danger" tooltip-placement="auto top" uib-tooltip="{{row.entity.STATUS_TXT.NAME}}"></a>&nbsp;&nbsp;</span>
        <span ng-if="row.entity.STATUS_TXT.ID === '7'"><a class="fa fa-check-square-o text-green" tooltip-placement="auto top" uib-tooltip="{{row.entity.STATUS_TXT.NAME}}"></a>&nbsp;&nbsp;</span>
        <span ng-if="row.entity.STATUS_TXT.ID === '8'"><a class="fa fa-exclamation-triangle text-yellow" aria-hidden="true" tooltip-placement="auto top" uib-tooltip="{{row.entity.STATUS_TXT.NAME}}"></a>&nbsp;&nbsp;</span>
        <span ng-if="(row.entity.PROCESS.REFERENCE !== null && row.entity.PROCESS.REFERENCE.length > 0)"><a class="fa fa-paperclip text-gray" uib-tooltip="{{'BASIC_DATA.THERE_ARE_ATTACHMENTS_IN_THIS_FOLLOW_UP' | translate }}">&nbsp;&nbsp;</a></span>
        `;

        const statusColDef: IMonacoColumnDef = {
            name: "status",
            displayName: "OPERATIONAL.STATUS",
            minWidth: 55,
            maxWidth: 55,
            cellTemplate: (`<span>&nbsp;&nbsp;${sendStatus}</span>`),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            allowCellFocus: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true,
            visible: true,
        }
        gridColumns.$columnDefs.push(statusColDef);

        const mailActions = `
        <span ng-if="grid.appScope.isStart(row.entity)"><a ng-click="grid.appScope.start(row.entity)" class="text-green" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.SEND_EMAIL' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-play icon"></i></a>&nbsp;&nbsp;</span>
        <span ng-if="grid.appScope.isResend(row.entity)"><a ng-click="grid.appScope.resend(row.entity)" class="text-black" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.RESEND_EMAIL' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-paper-plane icon"></i></a>&nbsp;&nbsp;</span>
        <span ng-if="grid.appScope.isStop(row.entity)"><a ng-click="grid.appScope.stop(row.entity)" class="text-danger" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.STOP_EMAIL' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-ban icon"></i></a>&nbsp;&nbsp;</span>
        <span ng-if="grid.appScope.isRefresh(row.entity)"><a ng-click="grid.appScope.refresh(row.entity)" class="text-blue" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.UPDATE_EMAIL_STATUS' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</span>
        `;

        const mailActionsColDef: IMonacoColumnDef = {
            name: "mail_actions",
            displayName: "ENTITY.EMAIL",
            minWidth: 55,
            maxWidth: 55,
            cellTemplate: (`<div class="text-center">${mailActions}</div>`),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            allowCellFocus: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true,
            visible: true,
        }
        gridColumns.$columnDefs.push(mailActionsColDef);

        const columnDefs = this.buildColumns();
        for (const column of columnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colStatusTxt: IMonacoColumnDef = { name: "STATUS_TXT.NAME", displayName: "OPERATIONAL.TEXTUAL_STATUS", width: 300 };
            const colInsertDate: IMonacoColumnDef = { name: "INSERT_DATE", displayName: "OPERATIONAL.SENT_DATE", width: 150, cellFilter: "date:'dd/MM/yyyy hh:mm:ss'" };
            const colType: IMonacoColumnDef = { name: "TYPE.NAME", displayName: "GENERAL.TYPE", width: 100 };
            const colSubject: IMonacoColumnDef = { name: "SUBJECT", displayName: "REGISTRATION.SUBJECT", width: 300 };
            const colNumber: IMonacoColumnDef = { name: "FOLLOWUPPROCESS_NUMBER", displayName: "GENERAL.NUMBER", width: 100 };
            const colReference: IMonacoColumnDef = { name: "REFERENCE", displayName: "REGISTRATION.TRIGGERS", width: 200 };
            const colProcessNumber: IMonacoColumnDef = { name: "PROCESS.PROCESS_NUMBER", displayName: "OPERATIONAL.FILE_NUMBER", width: 120, cellTemplate: `<div class="ui-grid-cell-contents ng-binding ng-scope"><a tooltip-placement="auto top" uib-tooltip="{{'OPERATIONAL.PROCESS_VIEW' | translate: {prop: row.entity.PROCESS.PROCESS_NUMBER} }}" tooltip-append-to-body="true" href="javascript:void(0);" style="text-decoration: underline;" ng-click="grid.appScope.goToProcess(row.entity.PROCESS.PROCESS_NUMBER)">{{row.entity.PROCESS.PROCESS_NUMBER}}</a></div>` };
            const colCustomer: IMonacoColumnDef = { name: "PROCESS.CUSTOMER.NAME", displayName: "BASIC_DATA.CLIENT", width: 200 };
            const colRecipient: IMonacoColumnDef = { name: "PROCESS.RECIPIENT.ID", displayName: "REGISTRATION.RECIPIENT", width: 200, cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.PROCESS.RECIPIENT, null, "ID")}}</div>' };
            const colSender: IMonacoColumnDef = { name: "PROCESS.SENDER.ID", displayName: "OPERATIONAL.SENDER", width: 200 };
            const colCopyGroup: IMonacoColumnDef = { name: "PROCESS.COPY_GROUP.ID", displayName: "OPERATIONAL.CARBON_COPY", width: 215, cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.PROCESS.COPY_GROUP, null, "ID")}}</div>' };
            const colCopyHidden: IMonacoColumnDef = { name: "PROCESS.COPY_HIDDEN_GROUP.ID", displayName: "OPERATIONAL.BLIND_CARBON_COPY", width: 200, cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.PROCESS.COPY_HIDDEN_GROUP, null, "ID")}}</div>' };
            const colManualGen: IMonacoColumnDef = { name: "MESSAGE_METADATA.MANUAL", displayName: "OPERATIONAL.MANUAL_FOLLOW", width: 160, cellTemplate: '<div class="ui-grid-cell-contents">{{ (row.entity.MESSAGE_METADATA.MANUAL) ? "Sim" : "Não" }}</div>' };
            const colVoucher: IMonacoColumnDef = { name: "MESSAGE_METADATA.VOUCHER", displayName: "OPERATIONAL.VOUCHER", width: 160, visible: false };

            columnDefs.push(colStatusTxt);
            columnDefs.push(colProcessNumber);
            columnDefs.push(colManualGen);
            columnDefs.push(colNumber);
            columnDefs.push(colCustomer);
            columnDefs.push(colSubject);
            columnDefs.push(colSender);
            columnDefs.push(colRecipient);
            columnDefs.push(colCopyGroup);
            columnDefs.push(colCopyHidden);
            columnDefs.push(colReference);
            columnDefs.push(colType);
            columnDefs.push(colInsertDate);
            columnDefs.push(colVoucher);

            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            FOLLOWUPPROCESS_NUMBER: null,
            SUBJECT: null,
            CONTENT: null,
            MODEL: null,
            TYPE: null,
            REFERENCE: null,
            SEND_GROUP: null,
            RECIPIENT_TYPE: null,
            RECIPIENT_INTERNAL_ROLES: null,
            COPY_GROUP_TYPE: null,
            COPY_GROUP_INTERNAL_ROLES: null,
            COPY_HIDDEN_GROUP_TYPE: null,
            COPY_HIDDEN_GROUP_INTERNAL_ROLES: null,
            NOTIFY: null,
            INTERACTION: null,
            EXCEPTION: null,
            PROCESS: null,
            INSERT_DATE: null,
            LANGUAGE: null,
            SURVEY: null,
            MESSAGE_METADATA: null,
            STATUS_TXT: null
        }
    }

    initDependencies(): Promise<any> {
        const self: NotificationMonitorRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getSendGroupList(),
                self.getTypeList(),
                self.getDestinTypeList(),
            ]).then((result: any) => {
                self.$scope.sendGroupList = result[0];
                self.$scope.typeList = result[1];
                self.$scope.destinTypeList = result[2];

                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getSendGroupList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/message_group`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/followup_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getDestinTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/followup_recipient`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getInternalContacts(query) {
        const result = await this.helperService.get(`/user/field/displayName/${query}`, null, 10000)
        return result.data.data;
    }

    private async getListInternalContacts() {
        const result = await this.helperService.post('/user/list/', null, 1000)
        return result.data.data;
    }

    private async getProcessReference(): Promise<REFERENCE[]> {
        let result: REFERENCE[] = [];

        try {

            this.block();

            if (this.$scope.model.PROCESS && this.$scope.model.PROCESS.PROCESS_NUMBER) {
                const processNumber = this.$scope.model.PROCESS.PROCESS_NUMBER;

                let request: IMonacoRequest = {
                    route: `/notificationMonitor/process/references/${processNumber}/`,
                    timeout: 120000
                };

                const baseUrl = this.config.externalUrl + '/external';
                const references = await this.httpRequest.getObjectAsPromise(`${baseUrl}${request.route}`, request.timeout, null, false);
                result = (references.data) ? references.data[0].REFERENCE : [];
            }

        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async refreshRecipientInternalContact(name: string): Promise<SelectorModel[]> {
        try {
            if (name.length >= 3) {
                const result = await this.getInternalContacts(name);
                if (result && result.data) {
                    const internalContacts: UserModel[] = result.data;
                    return internalContacts.map(x => {
                        return {
                            _id: x.email,
                            ID: x.email,
                            NAME: x.displayName,
                            MAIL: x.email
                        }
                    })
                }
            }

            return [];
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(model: FollowUpProcessModelStatus): Promise<void> {
        try {
            if (this.$scope.model.CONTENT && this.$scope.model.CONTENT.length) this.$scope.model.CONTENT = this.$scope.model.CONTENT.replace(/\n/g, "<br>");
            $("#editorContent").summernote("disable");
            this.$scope.mailStatusList = this.buildMailStatusList(model);
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} - ${this.$scope.model.SUBJECT}`;
            BrowserTitle.$id = this.$scope.model.SUBJECT;
            this.buildReferences();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async register(): Promise<void> {
        try {
            $("#editorContent").summernote("enable");
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.$scope.model.STATUS_TXT = { ID: "1", NAME: "GENERAL.SAVED_EMAIL" };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(model: FollowUpProcessModelStatus): Promise<void> {
        try {
            if (this.$scope.model.CONTENT && this.$scope.model.CONTENT.length) this.$scope.model.CONTENT = this.$scope.model.CONTENT.replace(/\n/g, "<br>");
            $("#editorContent").summernote("enable");
            this.$scope.mailStatusList = this.buildMailStatusList(model);
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} - ${this.$scope.model.SUBJECT}`;
            BrowserTitle.$id = this.$scope.model.SUBJECT;
            this.$scope.processReference = null;
            this.buildReferences();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(model: FollowUpProcessModelStatus): Promise<void> {
        try {
            if (this.$scope.model.CONTENT && this.$scope.model.CONTENT.length) this.$scope.model.CONTENT = this.$scope.model.CONTENT.replace(/\n/g, "<br>");
            $("#editorContent").summernote("enable");
            this.$scope.mailStatusList = this.buildMailStatusList(model);
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.COPYING')} - ${this.$scope.model.SUBJECT}`;
            this.$scope.operation = "insert";
            BrowserTitle.$id = this.$scope.model.SUBJECT;
            this.buildReferences();

            this.$scope.model.ID = null;
            this.$scope.model.STATUS_TXT = { ID: "1", NAME: "GENERAL.SAVED_EMAIL" };
            this.$scope.model.PROCESS.CHECKEDTOSEND = false;
            this.$scope.model.MESSAGE_METADATA.DELIVERED = null;
            this.$scope.model.MESSAGE_METADATA.FAILED = null;
            this.$scope.model.MESSAGE_METADATA.MANUAL = false;
            this.$scope.model.MESSAGE_METADATA.OPENED = null;
            this.$scope.model.MESSAGE_METADATA.SENT = null;
            this.$scope.model.MESSAGE_METADATA.STATUS = null;
            this.$scope.model.MESSAGE_METADATA.TRANSACTION_ID = null;
            this.$scope.model.MESSAGE_METADATA.VOUCHER = null;

            this.$scope.model.PROCESS.SENDER = <ISelectorModel>{
                ID: this.$scope['user'].email,
                NAME: this.$scope['user'].displayName,
                CODE: this.$scope['user'].email
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request() {
        const followUpProcessModel: FollowUpProcessModelStatus = angular.copy(this.$scope.model);
        delete followUpProcessModel.STATUS_TXT;
        this.$scope.fileModel = <IFiles>{ FILES: [] };

        if (followUpProcessModel.PROCESS.REFERENCE && followUpProcessModel.PROCESS.REFERENCE.length > 0) {
            const processReferences = await this.getProcessReference();
            if (processReferences && processReferences.length > 0) {
                for (const reference of followUpProcessModel.PROCESS.REFERENCE) {

                    const index = processReferences.findIndex(file => {
                        return file.LINK == reference.LINK
                            && file.NAME == reference.NAME
                    });
                    if (index < 0) {
                        // add in Process References
                        const data = {
                            processNumber: this.$scope.model.PROCESS.PROCESS_NUMBER,
                            referenceList: [reference],
                            processDocumentNumber: null,
                            category: null,
                            blNumber: null,
                            invoiceID: null,
                            timeout: 60000,
                            referenceParameters: null
                        }

                        const result = await this.$http<IHttpResponse<REFERENCE[]>>({
                            method: 'post',
                            url: this.OperationalService.$route + '/processReference/add',
                            data: data,
                            cache: false,
                            timeout: 60000
                        });
                        const msgError = this.formService.getTranslate('GENERAL.FAILED_TO_ADD_REFERENCE_TO_THIS_FILE');
                        if (!(result.status === 200 && result.data.data)) this.formService.notifyError(msgError);
                    }
                }
            }
        }

        const request = {
            operation: this.$scope.operation,
            followUpProcessModel: followUpProcessModel,
            timeout: 120000
        };

        return request;
    }

    async save(): Promise<boolean> {
        try {
            return true;
        } catch (ex) {
            this.handleLoadError(ex);
        }
        return false;
    }

    private buildReferences() {
        if (this.$scope.model.PROCESS.REFERENCE) {
            const references = this.$scope.model.PROCESS.REFERENCE;
            this.$scope.fileModel = <IFiles>{ FILES: [] };
            for (const reference of references) {

                const extensionRemoveList: string[] = (reference.NAME) ? reference.NAME.split('.') : null;
                const extension: string = (extensionRemoveList && extensionRemoveList.length > 0) ? extensionRemoveList[extensionRemoveList.length - 1] : null;

                this.$scope.fileModel.FILES.push({
                    FILE_DISPLAY_NAME: reference.NAME,
                    FILE_NAME: reference.NAME,
                    FILE_TYPE: extension,
                    FILE_PATH: null,
                    FILE_URL: reference.LINK,
                    FILE_HASH: null,
                    FORM_NAME: "notificationMonitor"
                });
            }
        }
    }

    private buildMailStatusList(model: FollowUpProcessModel): IMailStatus[] {
        if (!model) return [];

        const result: IMailStatus[] = [];
        let mailListModel: SelectorModel[] = [];

        if (model.PROCESS.RECIPIENT) mailListModel = mailListModel.concat(model.PROCESS.RECIPIENT);
        if (model.PROCESS.COPY_GROUP) mailListModel = mailListModel.concat(model.PROCESS.COPY_GROUP);
        if (model.PROCESS.COPY_HIDDEN_GROUP) mailListModel = mailListModel.concat(model.PROCESS.COPY_HIDDEN_GROUP);

        if (mailListModel.length > 0) {
            let mailList = mailListModel.map(x => x.ID);

            mailList = mailList.filter(function (item, pos) {
                return mailList.indexOf(item) == pos;
            });

            for (const email of mailList) {
                const mailStatus: IMailStatus = <IMailStatus>{};
                mailStatus.email = email;
                mailStatus.sent = (model.MESSAGE_METADATA && model.MESSAGE_METADATA.SENT) ? model.MESSAGE_METADATA.SENT.some(x => x === mailStatus.email) : false;
                mailStatus.delivered = (model.MESSAGE_METADATA && model.MESSAGE_METADATA.DELIVERED) ? model.MESSAGE_METADATA.DELIVERED.some(x => x === mailStatus.email) : false;
                mailStatus.opened = (model.MESSAGE_METADATA && model.MESSAGE_METADATA.OPENED) ? model.MESSAGE_METADATA.OPENED.some(x => x === mailStatus.email) : false;

                if ((model.MESSAGE_METADATA && model.MESSAGE_METADATA.FAILED)) {
                    const failedMail = model.MESSAGE_METADATA.FAILED.find(x => x.ADDRESS === mailStatus.email);
                    if (failedMail) {
                        mailStatus.failed = true;
                        mailStatus.failedDescription = failedMail.ERROR;
                    }
                }

                result.push(mailStatus);
            }
        }
        return result;
    }

    private getFollowUpProcessModal(model: FollowUpProcessModelStatus): FollowUpProcessModel {
        const followUpProcessModelCopy: FollowUpProcessModelStatus = angular.copy(model);
        followUpProcessModelCopy.MESSAGE_METADATA = <MESSAGE_METADATA>{};
        followUpProcessModelCopy.MESSAGE_METADATA.TRANSACTION_ID = null;
        followUpProcessModelCopy.MESSAGE_METADATA.STATUS = null;
        followUpProcessModelCopy.MESSAGE_METADATA.SENT = null;
        followUpProcessModelCopy.MESSAGE_METADATA.OPENED = null;
        followUpProcessModelCopy.MESSAGE_METADATA.MANUAL = false;
        followUpProcessModelCopy.MESSAGE_METADATA.FAILED = null;
        followUpProcessModelCopy.MESSAGE_METADATA.DELIVERED = null;
        followUpProcessModelCopy.MESSAGE_METADATA.VOUCHER = (model.MESSAGE_METADATA.VOUCHER) ? model.MESSAGE_METADATA.VOUCHER : null;

        delete followUpProcessModelCopy.STATUS_TXT;

        return followUpProcessModelCopy;
    }

    private async refresh(model: FollowUpProcessModelStatus) {
        try {
            if (await this.permissionService.isRoleAllowed("NOTIFYMONITORUPDATE") === false) {
                this.permissionService.showBlockMessage();
                return;
            }

            try {
                const confirmed = await this.ModalService.showModalConfirmation({}, {
                    bodyText: 'GENERAL.DO_YOU_WANT_TO_UPDATE_THE_FOLLOW_UP_STATUS',
                    actionButtonText: 'OPERATIONAL.UPDATE',
                    closeButtonText: 'GENERAL.CLOSE',
                    headerText: 'GENERAL.UPDATE_STATUS',
                });

                if (!confirmed) return;

                this.block();

                const timeout: number = 120000;

                const baseUrl = this.config.externalUrl + '/external';

                const elasticStatus: ElasticStatus = await this.httpRequest.newObjectPromise(`${baseUrl}/elastic/status`, { transactionid: model.MESSAGE_METADATA.TRANSACTION_ID, }, timeout, false);
                const msgError = this.formService.getTranslate('GENERAL.FAILED_TO_UPDATE_THE_FOLLOW_UP_STATUS');
                if (!elasticStatus) return this.handleWarning(msgError);

                const newModel: FollowUpProcessModel = this.getFollowUpProcessModal(model);
                newModel.MESSAGE_METADATA.TRANSACTION_ID = model.MESSAGE_METADATA.TRANSACTION_ID;
                newModel.MESSAGE_METADATA.STATUS = (elasticStatus.status) ? elasticStatus.status : null;
                newModel.MESSAGE_METADATA.OPENED = (elasticStatus.opened) ? elasticStatus.opened : null;
                newModel.MESSAGE_METADATA.SENT = (elasticStatus.sent) ? elasticStatus.sent : null;
                newModel.MESSAGE_METADATA.DELIVERED = (elasticStatus.delivered) ? elasticStatus.delivered : null;
                newModel.MESSAGE_METADATA.FAILED = null;

                if (elasticStatus.failed) {
                    newModel.MESSAGE_METADATA.FAILED = [];
                    for (const error of elasticStatus.failed) {
                        const failed = <FAILED>{};
                        failed.ADDRESS = error.address;
                        failed.ERROR = error.error;
                        newModel.MESSAGE_METADATA.FAILED.push(failed);
                    }
                }

                const request = {
                    followUpProcessModel: newModel,
                    timeout: timeout
                };

                const result = await this.httpRequest.newObjectPromise(`${baseUrl}/notificationMonitor/insert`, request, request.timeout, false);
                if (!result) return this.handleWarning(msgError);

                await this.updateGrid();
                const msg = this.formService.getTranslate('GENERAL.UPDATE_SUCESSFULLY_PERFORMED');
                this.formService.notifySuccess(msg);
            } catch (ex) {
                this.handleError(ex);
            }
        } finally {
            this.unblock();
        }
    }

    private isRefresh(model: FollowUpProcessModelStatus): boolean {
        return (model.PROCESS && model.PROCESS.CHECKEDTOSEND === true && model.MESSAGE_METADATA && model.MESSAGE_METADATA.TRANSACTION_ID !== null);
    }

    private isStop(model: FollowUpProcessModelStatus): boolean {
        return (model.PROCESS && model.PROCESS.CHECKEDTOSEND === true && model.MESSAGE_METADATA && model.MESSAGE_METADATA.TRANSACTION_ID === null);
    }

    private async stop(model: FollowUpProcessModelStatus) {
        try {
            try {
                if (await this.permissionService.isRoleAllowed("NOTIFYMONITORCANCEL") === false) {
                    this.permissionService.showBlockMessage();
                    return;
                }

                const confirmed = await this.ModalService.showModalConfirmation({}, {
                    bodyText: 'GENERAL.ARE_YOU_SURE_YOU_WANT_TO_STOP_THE_EMAIL',
                    actionButtonText: 'GENERAL.STOP',
                    closeButtonText: 'GENERAL.CLOSE',
                    headerText: 'GENERAL.STOP_EMAIL',
                });

                if (!confirmed) return;

                this.block();

                const newModel: FollowUpProcessModel = this.getFollowUpProcessModal(model);

                newModel.PROCESS.CHECKEDTOSEND = false;

                const timeout: number = 120000;
                const request = {
                    followUpProcessModel: newModel,
                    timeout: timeout
                };

                const baseUrl = this.config.externalUrl + '/external';
                const result = await this.httpRequest.newObjectPromise(`${baseUrl}/notificationMonitor/insert`, request, request.timeout, false);
                const msgError = this.formService.getTranslate('GENERAL.FAILED_TO_STOP_EMAIL');
                if (!result) return this.handleWarning(msgError);

                await this.updateGrid();
                const msg = this.formService.getTranslate('GENERAL.STOP_REQUEST_SUCESSFULLY_REQUEST');
                this.formService.notifySuccess(msg);
            } catch (ex) {
                this.handleError(ex);
            }
        } finally {
            this.unblock();
        }
    }

    private isStart(model: FollowUpProcessModelStatus): boolean {
        return (model.PROCESS && (model.PROCESS.CHECKEDTOSEND === false || model.PROCESS.CHECKEDTOSEND == null));
    }

    async start(model: FollowUpProcessModelStatus) {
        try {
            if (await this.permissionService.isRoleAllowed("NOTIFYMONITORSEND") === false) {
                this.permissionService.showBlockMessage();
                return;
            }

            try {
                const confirmed = await this.ModalService.showModalConfirmation({}, {
                    bodyText: 'GENERAL.ARE_YOU_SURE_YOU_WANT_TO_SEND_THIS_EMAIL',
                    actionButtonText: 'GENERAL.GRID.SEND',
                    closeButtonText: 'GENERAL.CLOSE',
                    headerText: 'GENERAL.SEND_EMAIL',
                });

                if (!confirmed) return;

                this.block();

                const newModel: FollowUpProcessModel = this.getFollowUpProcessModal(model);

                newModel.PROCESS.CHECKEDTOSEND = true;

                const timeout: number = 120000;
                const request = {
                    followUpProcessModel: newModel,
                    timeout: timeout
                };

                const baseUrl = this.config.externalUrl + '/external';
                const result = await this.httpRequest.newObjectPromise(`${baseUrl}/notificationMonitor/insert`, request, request.timeout, false);
                const msgError = this.formService.getTranslate('GENERAL.FAILED_TO_REQUEST_TO_SEND_EMAIL');
                if (!result) return this.handleWarning(msgError);

                await this.updateGrid();
                const msg = this.formService.getTranslate('GENERAL.SEND_REQUEST_SUCESSFULLY_PERFORMED');
                this.formService.notifySuccess(msg);
            } catch (ex) {
                this.handleError(ex);
            }
        } finally {
            this.unblock();
        }
    }

    private isResend(model: FollowUpProcessModelStatus): boolean {
        return (model.PROCESS && model.PROCESS.CHECKEDTOSEND === true && model.MESSAGE_METADATA && model.MESSAGE_METADATA.TRANSACTION_ID !== null);
    }

    private async getContacts(typeInvolveds: ISelectorModel[], messageGroupIds?: string[]) {
        let contacts: ISelectorModel[] = [];
        try {
            if (this.$scope.model.PROCESS && this.$scope.model.PROCESS.PROCESS_NUMBER) {
                const processNumber = this.$scope.model.PROCESS.PROCESS_NUMBER;
                const timeout = 120000;
                const result = await this.OperationalService.get(`/process/byNumber/${processNumber}/${timeout}`, timeout);
                const processInfo: Process = result.data.data ? result.data.data[0] : null;

                let idInvolved = null;

                if (typeInvolveds) {
                    for (const type_involved of typeInvolveds) {
                        switch (type_involved.ID) {
                            case '1':
                                idInvolved = processInfo.CUSTOMER ? processInfo.CUSTOMER.ID_LEGAL_PERSON : null;
                                break;
                            case '2':
                                idInvolved = processInfo.LOCAL_AGENT ? processInfo.LOCAL_AGENT.ID_LEGAL_PERSON : null;
                                break;
                            case '3':
                                idInvolved = processInfo.EXTERNAL_AGENT ? processInfo.EXTERNAL_AGENT.ID_LEGAL_PERSON : null;
                                break;
                            case '4':
                                let userList = await this.getListInternalContacts();
                                if (userList && userList.data) {
                                    const internalContacts = userList.data;
                                    const internalcontactsList = internalContacts.map(x => {
                                        return {
                                            _id: x.email,
                                            ID: x.email,
                                            NAME: x.displayName,
                                            MAIL: x.email
                                        }
                                    })
                                    for (const contact of internalcontactsList) {
                                        contacts.push(contact);
                                    }
                                }
                                break;
                            case '5':
                                idInvolved = processInfo.IMPORTER ? processInfo.IMPORTER.ID_LEGAL_PERSON : null;
                                break;
                            case '6':
                                idInvolved = processInfo.EXPORTER ? processInfo.EXPORTER.ID_LEGAL_PERSON : null;
                                break;
                            case '7':
                                idInvolved = processInfo.NOTIFY ? processInfo.NOTIFY.ID_LEGAL_PERSON : null;
                                break;
                            case '8':
                                idInvolved = processInfo.BROKER ? processInfo.BROKER.ID_LEGAL_PERSON : null;
                                break;
                            case '9':
                                idInvolved = processInfo.INDICATION ? processInfo.INDICATION.ID_LEGAL_PERSON : null;
                                break;
                            case '10':
                                idInvolved = processInfo.SERVICE_PROVIDER ? processInfo.SERVICE_PROVIDER.ID_LEGAL_PERSON : null;
                                break;
                            case '11':
                                idInvolved = processInfo.AGENCY ? processInfo.AGENCY.ID_LEGAL_PERSON : null;
                                break;
                            case '14':
                                idInvolved = processInfo.CARGO_DETAIL && processInfo.CARGO_DETAIL.INSURANCE && processInfo.CARGO_DETAIL.INSURANCE.INSURANCE_BROKER ? processInfo.CARGO_DETAIL.INSURANCE.INSURANCE_BROKER.ID : null;
                                break;
                            case '15':
                                idInvolved = processInfo.THIRD_AGENT ? processInfo.THIRD_AGENT.ID_LEGAL_PERSON : null;
                                break;
                            case '16':
                                idInvolved = processInfo.TRANSPORTER_PICKUP ? processInfo.TRANSPORTER_PICKUP.ID_LEGAL_PERSON : null;
                                break;
                            case '17':
                                idInvolved = processInfo.TRANSPORTER_PLACE_RECEPT ? processInfo.TRANSPORTER_PLACE_RECEPT.ID_LEGAL_PERSON : null;
                                break;
                            case '18':
                                idInvolved = processInfo.TRANSPORTER_FINAL_DESTINATION ? processInfo.TRANSPORTER_FINAL_DESTINATION.ID_LEGAL_PERSON : null;
                                break;
                            case '19':
                                idInvolved = processInfo.TRANSPORTER_DELIVERY ? processInfo.TRANSPORTER_DELIVERY.ID_LEGAL_PERSON : null;
                                break;
                            case '21':
                                idInvolved = processInfo.TRANSPORTER_GATE_OUT_EMPTY ? processInfo.TRANSPORTER_GATE_OUT_EMPTY.ID_LEGAL_PERSON : null;
                                break;
                            case '25':
                                idInvolved = processInfo.REPRESENTATIVES && processInfo.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE
                                    && processInfo.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE.REPRESENTATIVE ? processInfo.REPRESENTATIVES.REPRESENTATIVE_DISCHARGE.REPRESENTATIVE.ID_LEGAL_PERSON : null;
                                break;
                            case '26':
                                idInvolved = processInfo.REPRESENTATIVES && processInfo.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT
                                    && processInfo.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT.REPRESENTATIVE ? processInfo.REPRESENTATIVES.REPRESENTATIVE_PLACE_OF_RECEIPT.REPRESENTATIVE.ID_LEGAL_PERSON : null;
                                break;
                            case '27':
                                idInvolved = processInfo.REPRESENTATIVES && processInfo.REPRESENTATIVES.REPRESENTATIVE_LOAD
                                    && processInfo.REPRESENTATIVES.REPRESENTATIVE_LOAD.REPRESENTATIVE ? processInfo.REPRESENTATIVES.REPRESENTATIVE_LOAD.REPRESENTATIVE.ID_LEGAL_PERSON : null;
                                break;
                            case '28':
                                idInvolved = processInfo.REPRESENTATIVES && processInfo.REPRESENTATIVES.REPRESENTATIVE_PICK_UP
                                    && processInfo.REPRESENTATIVES.REPRESENTATIVE_PICK_UP.REPRESENTATIVE ? processInfo.REPRESENTATIVES.REPRESENTATIVE_PICK_UP.REPRESENTATIVE.ID_LEGAL_PERSON : null;
                                break;
                            case '29':
                                idInvolved = processInfo.REPRESENTATIVES && processInfo.REPRESENTATIVES.REPRESENTATIVE_DELIVERY
                                    && processInfo.REPRESENTATIVES.REPRESENTATIVE_DELIVERY.REPRESENTATIVE ? processInfo.REPRESENTATIVES.REPRESENTATIVE_DELIVERY.REPRESENTATIVE.ID_LEGAL_PERSON : null;
                                break;
                            case '30':
                                idInvolved = processInfo.REPRESENTATIVES && processInfo.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION
                                    && processInfo.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION.REPRESENTATIVE ? processInfo.REPRESENTATIVES.REPRESENTATIVE_FINAL_DESTINATION.REPRESENTATIVE.ID_LEGAL_PERSON : null;
                                break;
                        }
                        //search contacts
                        if (idInvolved) {
                            const productReq: IMonacoRequest = {
                                route: `/contact/list/operational`,
                                data: {
                                    filters: {
                                        LEGAL_PERSON: idInvolved,
                                        PRODUCTS: [processInfo.PRODUCT.ID],
                                        COMMODITIES: null,
                                        ACCOUNTS: null,
                                        EXPORTERS: null,
                                        IMPORTERS: null,
                                        OUTER_CELLS: null,
                                        ORIGIN_MARITIME: null,
                                        DESTINATION_MARITIME: null,
                                        ORIGIN_AIR: null,
                                        DESTINATION_AIR: null,
                                        ORIGIN_ROAD: null,
                                        DESTINATION_ROAD: null
                                    },
                                }
                            }

                            let resultContacts = [];
                            const result = await this.ProductService.post<any>(productReq);
                            if (result && result.data) resultContacts = result.data.data ? result.data.data : [];
                            for (const contact of resultContacts) {
                                if (!messageGroupIds || (messageGroupIds && contact.MESSAGE_GROUP && contact.MESSAGE_GROUP.length && contact.MESSAGE_GROUP.some(messageGroup => messageGroupIds.some(messageGroupId => messageGroupId == messageGroup.ID))))
                                    contacts.push({
                                        ID: contact.EMAIL,
                                        NAME: contact.NAME,
                                        CODE: contact.EMAIL,
                                    });
                            }
                        }
                    }
                }
            }

            return contacts;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async resend(model: FollowUpProcessModelStatus) {
        try {
            try {
                if (await this.permissionService.isRoleAllowed("NOTIFYMONITORRESEND") === false) {
                    this.permissionService.showBlockMessage();
                    return;
                }

                const confirmed = await this.ModalService.showModalConfirmation({}, {
                    bodyText: 'GENERAL.ARE_YOU_SURE_YOU_WANT_TO_RESEND_THIS_EMAIL',
                    actionButtonText: 'GENERAL.RESEND',
                    closeButtonText: 'GENERAL.CLOSE',
                    headerText: 'GENERAL.RESEND_EMAIL',
                });

                if (!confirmed) return;

                this.block();

                const newModel: FollowUpProcessModel = this.getFollowUpProcessModal(model);

                const timeout: number = 120000;
                const request = {
                    followUpProcessModel: newModel,
                    timeout: timeout
                };

                const baseUrl = this.config.externalUrl + '/external';
                const result = await this.httpRequest.newObjectPromise(`${baseUrl}/notificationMonitor/insert`, request, request.timeout, false);
                const msgError = this.formService.getTranslate('GENERAL.FAILED_TO_REQUEST_TO_RESEND_THE_EMAIL');
                if (!result) return this.handleWarning(msgError);

                await this.updateGrid();
                const msg = this.formService.getTranslate('GENERAL.RESEND_REQUEST_SUCESSFULLY_PERFORMED');
                this.formService.notifySuccess(msg);
            } catch (ex) {
                this.handleError(ex);
            }
        } finally {
            this.unblock();
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: "SUBJECT",
                LABEL: "REGISTRATION.SUBJECT"
            },
            {
                PROPERTY: "REFERENCE",
                LABEL: "REGISTRATION.TRIGGERS"
            },
            {
                PROPERTY: "CONTENT",
                LABEL: "OPERATIONAL.CONTENT"
            },
            {
                PROPERTY: "INSERT_DATE",
                LABEL: "OPERATIONAL.SENT_DATE"
            },
            {
                PROPERTY: "FOLLOWUPPROCESS_NUMBER",
                LABEL: "GENERAL.FOLLOW_UP_NUMBER_FILE"
            },
            {
                PROPERTY: "SEND_GROUP",
                LABEL: "REGISTRATION.GROUP_SEND_MESSAGE"
            },
            {
                PROPERTY: "TYPE",
                LABEL: "GENERAL.TYPE"
            },
            {
                PROPERTY: "EXCEPTION",
                LABEL: "REGISTRATION.EXCEPTION"
            },
            {
                PROPERTY: "INTERACTION",
                LABEL: "REGISTRATION.INTERACTION"
            },
            {
                PROPERTY: "NOTIFY",
                LABEL: "ENTITY.NOTIFICATION"
            },
            {
                PROPERTY: "RECIPIENT_TYPE",
                LABEL: "REGISTRATION.RECIPIENT_TYPE"
            },
            {
                PROPERTY: "PROCESS",
                LABEL: "BASIC_DATA.FILE_NUMBER"
            },
            {
                PROPERTY: "CUSTOMER",
                LABEL: "BASIC_DATA.CLIENT"
            },
            {
                PROPERTY: "PROCESS_NUMBER",
                LABEL: "OPERATIONAL.FILE_NUMBER"
            },
            {
                PROPERTY: "RECIPIENT",
                LABEL: "REGISTRATION.RECIPIENT"
            },
            {
                PROPERTY: "COPY_GROUP",
                LABEL: "OPERATIONAL.CARBON_COPY"
            },
            {
                PROPERTY: "COPY_HIDDEN_GROUP",
                LABEL: "OPERATIONAL.BLIND_CARBON_COPY"
            },
            {
                PROPERTY: "ORIGIN",
                LABEL: "BASIC_DATA.ORIGIN"
            },
            {
                PROPERTY: "SENDER",
                LABEL: "OPERATIONAL.SENDER"
            },
            {
                PROPERTY: "DESTINATION",
                LABEL: "REGISTRATION.RECIPIENT"
            },
            {
                PROPERTY: "BOOKING",
                LABEL: "OPERATIONAL.BOOKING"
            },
            {
                PROPERTY: "CHECKEDTOSEND",
                LABEL: "GENERAL.CHECKED_TO_SEND"
            },
            {
                PROPERTY: "ID",
                LABEL: "GENERAL.IDENTIFIER"
            },
            {
                PROPERTY: "NAME",
                LABEL: "GENERAL.NAME"
            }
        ];
        return props;
    }
}

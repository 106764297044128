import "../../css/filePreview.css";

import * as angular from 'angular';
import "bootstrap/dist/css/bootstrap.min.css";
import 'angular-pdf';
import { ErrorService } from '@services/ErrorService';
import { ErrorController } from '../common/ErrorController';
import { DownloadService } from './services/DownloadService';
import { DataManagerDownloadController } from './controllers/DataManagerDownloadController';
import initRoute from './RouteConfig';

const VIEW_DATAMANAGER_MODULE = angular
    .module("storage", ['pdf'])
    .service("ErrorService", ErrorService)
    .service("DownloadService", DownloadService)
    .controller('ErrorController', ErrorController)
    .controller('DataManagerDownloadController', DataManagerDownloadController);
    
initRoute(VIEW_DATAMANAGER_MODULE.name);

export { VIEW_DATAMANAGER_MODULE };
import angular = require('angular');
import { SSEService } from '@appServices/SSEService';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from '@services/GridFormService';
import { IMonacoColumnDef } from '@services/GridService2';
import { IRestService } from '@services/RestService';
import { ISessionService } from "@services/SessionService";
import { EOperation } from '@enums/GenericData';
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IProvinceModel } from '@models/interface/product/ProvinceModel';
import { IDocumentError } from '@models/interface/common/IDocumentError';
import { GridColumnBuilder } from '../../common/GridColumnBuilder';
import { SelectorModel } from '../../common/model/SelectorModel';
import { ILinkParameter } from "../../common/model/ModelParameter";
import { BrowserTitle } from "../../common/BrowserTitle";
import { IModalService } from '@services/ModalService';

interface IProvinceScope extends IGridFormServiceScope {
    model: IProvinceModel;
    gridOptions: uiGrid.IGridOptions;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IProvinceModel;
    user: any;
    sessionService: ISessionService;
    editProvince: (province: IProvinceModel) => Promise<void>;
    viewProvince: (province: IProvinceModel) => Promise<void>;
    viewLogProvince: (province: IProvinceModel) => Promise<void>;
    copyProvince: (province: IProvinceModel) => Promise<void>;
    getCountryData(query: string): Promise<void>;
    updateSelectedCountry(data: SelectorModel): void;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
    goToCountry: (id: number) => void;
    countryList: Array<SelectorModel>;
}

export class ProvinceRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private $scope: IProvinceScope;
    private RestService: IRestService;
    private ModalService: IModalService;
    private gridName: string;
    private sessionService: ISessionService;
    private SSEService: SSEService;
    private modalID: number;

    constructor($injector: ng.Injectable<any>, $scope: IProvinceScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.$scope.sessionService = $injector.get('SessionService');
        this.RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.gridName = 'gridProvince';
        this.modalID = null;
    }

    getUrlProduct() {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    async $onInit?() {
        try {
            this.$baseUrl = this.getUrlProduct();

            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.$scope.countryList = [];
            this.initForm(this, 'form', 'province', 'GENERAL.MENU.PROVINCE', true);
            await this.initGrid('gridProvince', '/province/list', true, true, null, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editProvince = async (province: IProvinceModel): Promise<void> => {
            let blockedObject = {
                ID: province.ID_COUNTRY,
                NAME: province.NAME,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || province.ID !== this.$scope.model.ID) this.$scope.view(province);
                } else if (this.$scope.operation !== EOperation.EDIT || province.ID !== this.$scope.model.ID) {
                    this.$scope.edit(province);
                }
            };
        }

        this.$scope.viewProvince = async (province: IProvinceModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(province);
        }

        this.$scope.viewLogProvince = async (province: IProvinceModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(province);
        }

        this.$scope.copyProvince = async (province: IProvinceModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(province);
        }

        this.$scope.getCountryData = async (query) => {
            let countryList = [];
            if (query && query.length >= 2) {
                countryList = await this.getCountryData(query);
            }
            this.$scope.countryList = countryList;
        }
        this.$scope.updateSelectedCountry = (data) => this.updateSelectedCountry(data);

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }

        this.$scope.goToCountry = (id: number) => {
            this.sessionService.openTab("app.registration.country", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
    }

    initModel() {
        this.$scope.model = {
            _id: null,
            ID: null,
            INITIALS: null,
            NAME: null,
            NAME_INTL: null,
            COUNTRY: null,
            ID_COUNTRY: null,
            ACTIVE: true,
            INTEGRATION_ID: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            SEARCH_FIELDS: null,
            DOCUMENT_ERROR: null
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<a ng-click="grid.appScope.viewProvince(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate}}" tooltip-append-to-body="true"><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editProvince(row.entity)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate}}" tooltip-append-to-body="true"><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogProvince(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate}}" tooltip-append-to-body="true"><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.setCopy(true);grid.appScope.copyProvince(row.entity)" class="text-orange copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate}}" tooltip-append-to-body="true"><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes", displayName: "GENERAL.ACTIONS",
            width: 120,
            cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${viewLog} ${copy} ${modalIntegration}</div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };

        gridColumns.addColumn(colActions);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    private buildColumns(columns: string[]) {
        let gridColumns: IMonacoColumnDef[] = [];

        const colInitials: IMonacoColumnDef = { name: 'INITIALS', displayName: 'GENERAL.CODE', width: 80 };
        const colName: IMonacoColumnDef = { name: 'NAME', displayName: 'GENERAL.NAME', width: 350 };
        const colExtName: IMonacoColumnDef = { name: 'NAME_INTL', displayName: 'GENERAL.NAME_INTL', width: 350 };
        const colCountry: IMonacoColumnDef = { name: 'COUNTRY', displayName: 'ROUTE.COUNTRY', width: 80, field: 'COUNTRY.CODE' };
        const colActive: IMonacoColumnDef = { name: 'ACTIVE', displayName: 'GENERAL.ACTIVE', width: 70, cellFilter: 'YesOrNo' };
        const colIntegrationID: IMonacoColumnDef = { name: 'INTEGRATION_ID', displayName: 'GENERAL.INTEGRATION_ID', width: 150 };
        const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
        const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
        const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

        columns.forEach(column => {
            switch (column) {
                case 'INITIALS':
                    gridColumns.push(colInitials);
                    break;
                case 'NAME':
                    gridColumns.push(colName);
                    break;
                case 'NAME_INTL':
                    gridColumns.push(colExtName);
                    break;
                case 'ID_COUNTRY':
                    gridColumns.push(colCountry);
                    break;
                case 'ACTIVE':
                    gridColumns.push(colActive);
                    break;
                case 'INTEGRATION_ID':
                    gridColumns.push(colIntegrationID);
                    break;
                case 'ID':
                    gridColumns.push(colId);
                    break;
                case 'CREATED_AT':
                    gridColumns.push(colCreatedAt);
                    break;
                case 'UPDATED_AT':
                    gridColumns.push(colUpdatedAt);
                    break;
            }
        });

        return gridColumns;
    }

    initDependencies(): Promise<any> {
        const self: ProvinceRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([]).then((result: any) => {
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")} (${this.$scope.model.NAME})`;
            BrowserTitle.$id = this.$scope.model.NAME;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")} (${this.$scope.model.NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        let proceed: boolean = true;
        try {
            if (proceed) { }
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
            return false;
        }
        return proceed;
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        return {
            route: `/province/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "GENERAL.MENU.INTEGRATION" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number, idSubject: string): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/province/sendSync`, { "idProvince": id, "idSubject": idSubject }, 120000, false);
                if (syncRequest) success = true;
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_SENDING_REQUEST');
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const legalPersonData = await this.getProvinceById(id);
                    if (row && legalPersonData && legalPersonData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = legalPersonData.DOCUMENT_ERROR;
                        documentError = legalPersonData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async getProvinceById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/province/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getCountryData(query?: string): Promise<SelectorModel[]> {
        let result = [];
        try {
            this.formService.block();
            const countries = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/country/list/custom`, { search: query }, 30000, false);
            if (countries) result = countries.map(country => { return { ID: country.ID, NAME: country.NAME, CODE: country.INITIALS } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private updateSelectedCountry(data: SelectorModel) {
        if (data) {
            this.$scope.model.COUNTRY = data;
        }
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'INITIALS',
                LABEL: 'GENERAL.CODE'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'NAME_INTL',
                LABEL: 'GENERAL.NAME_INTL'
            },
            {
                PROPERTY: 'FISCAL',
                LABEL: 'REGISTRATION.TAX_HAVEN'
            },
            {
                PROPERTY: 'COUNTRY',
                LABEL: 'ROUTE.COUNTRY'
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
            {
                PROPERTY: 'INTEGRATION_ID',
                LABEL: 'GENERAL.INTEGRATION_ID'
            },
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            }
        ];
        return props;
    }
} 

import * as uuid from 'uuid';
import { Injectable } from "angular";
import { IRestService } from "@services/RestService";
import { IModalService } from "@services/ModalService";
import { IMonacoColumnDef } from "@services/GridService2";
import { OperationalService } from "@services/OperationalService";
import { ISessionService } from "@services/SessionService";
import { ProductService } from "@services/ProductService";
import { GridFormService, IGridFormServiceScope, IMonacoRequest } from "@services/GridFormService";
import { VESSEL_INFORMATION } from "@models/interface/operational/maritime/MaritimeVoyage";
import { MaritimeStopover } from "@models/interface/operational/maritime/MaritimeStopover";
import { HandleError } from "../../common/util/HandleError";
import { IStopoverAddExceptionModalScope } from "./WizardStopoverPanelController";
import { IDefaultTab, IWizardTravelStopoverParams } from "./ProcessEventController";
import { IExceptionStopoverModel, IExceptionStopoverPort } from "./WizardStopoverController";
import { IProcessParameter } from "../../common/model/ModelParameter";
import { EOperation } from '@enums/GenericData';

interface IVoyagesAndStopoverScope extends IGridFormServiceScope {
    getPort: (term: string) => Promise<IExceptionStopoverPort[]>;
    exceptionStopoverModel: IExceptionStopoverModel[];
    actionButtonClicked: Function;
    showActionsSelected: Function;
    wizardStopoverModalID: number;
    getCarrierCellValue: Function;
    eventDetailModalID: number;
    transportMode: {
        vessel: null,
        stopover: null,
    };
}

export class VoyagesAndStopoverRegisterController extends GridFormService {
    static $inject: string[] = ['$injector', '$scope', '$state'];
    private operationalService: OperationalService;
    private scope: IVoyagesAndStopoverScope;
    private sessionService: ISessionService;
    private modalService: IModalService;
    private restService: IRestService;
    private defaultTabs: Array<IDefaultTab>;
    private productService: ProductService;

    constructor($injector: Injectable<any>, $scope: IVoyagesAndStopoverScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.restService = $injector.get('RestService');
        this.modalService = $injector.get('ModalService');
        this.sessionService = $injector.get('SessionService');
        this.productService = $injector.get('ProductService');
        this.operationalService = $injector.get('OperationalService');
    }

    async $onInit(): Promise<void> {
        try {
            this.scope.getPort = (term) => this.getPort(term);
            this.$baseUrl = this.operationalService.$route;
            this.scope.getCarrierCellValue = this.getCarrierCellValue;
            this.scope.actionButtonClicked = this.actionButtonClicked;
            this.scope.showActionsSelected = this.showActionsSelected;
            this.$rootScope.setBreadCrumb('OPERATIONAL.VOYAGE', () => {
                const modalOperation = 'register';
                this.prepareDefaultTabs(modalOperation);
                this.stopoverWizard(modalOperation, 'maritimeServices');
            });
            await this.gridService.initGridStandAlone('GRID_MARITIME_TRAVEL_AND_DEADLINES', '/stopover/maritime/list', this.buildColumns, true, true, 30000, true, true);
        } catch (ex) {
            throw ex;
        }
    }

    private async exceptionStopoverModal(rowEntity: MaritimeStopover): Promise<void> {
        try {
            let modalStopoverId = this.modalService.newModal();

            this.scope.exceptionStopoverModel = [{
                PORT: null,
                SHIP: rowEntity.VESSEL_INFORMATION.VESSEL,
                MASTER: rowEntity.VESSEL_INFORMATION.MAIN,
                SHIPOWNER: rowEntity.VESSEL_INFORMATION.CARRIER_INFORMATION.map(info => ({
                    MASTER: null,
                    OWNER: info.CARRIER,
                    TRAVEL: info.VOYAGE
                })),
            }];

            this.modalService.showModalInfo(
                {
                    size: 'lg',
                    scope: this.scope,
                    formService: 'register',
                    modalID: modalStopoverId,
                    template: require("../view/exceptionStopoverModal.html")
                },
                {
                    actionButtonText: "GENERAL.CLOSE",
                    headerText: `${rowEntity.VOYAGE_NUMBER} - ${this.formService.getTranslate("OPERATIONAL.ADDITIONAL_STOPOVER")}`
                });

            const modalScope: IStopoverAddExceptionModalScope = await this.modalService.getModalScope(modalStopoverId);
            modalScope.applyExceptionStopover = async (): Promise<void> => {
                try {
                    const exceptionStopover = this.scope.exceptionStopoverModel[0];
                    if (!exceptionStopover || exceptionStopover.PORT == null) return this.handleError("REGISTRATION.INFORM_PLACE");

                    const ports = exceptionStopover.PORT;
                    let hasError = false;
                    for (let i = 0; i < ports.length; i++) {
                        const saved = await this.saveExceptionStopover(rowEntity, ports[i]);
                        if (!saved && !hasError) {
                            hasError = true;
                        }
                    }

                    if (!hasError) {
                        this.modalService.closeModal(modalStopoverId);
                        modalStopoverId = 0;
                        this.$rootScope.refreshPage();
                    }
                } catch (ex) {
                    this.handleError(ex);
                }
            }
            modalScope.closeStopoverModal = async (): Promise<void> => {
                this.modalService.closeModal(modalStopoverId);
                modalStopoverId = 0;
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    /**
     * Method used to get ports by the autocomplete inside exceptionStopoverModal
     * @param term - The term typed inside the autocomplete component
     * @returns 
     */
    async getPort(term: string): Promise<IExceptionStopoverPort[]> {
        let result = [];

        const transform = (routingPoints): Array<IExceptionStopoverPort> => {
            return routingPoints.map((routingPoint) => {
                return {
                    ID: routingPoint.ID,
                    NAME: routingPoint.NAME,
                    CODE: routingPoint.CODE,
                    ALIAS: routingPoint.CODE,
                    COUNTRY: routingPoint.COUNTRY,
                }
            });
        };

        try {
            if (term && term.length >= 3) {
                const request: IMonacoRequest = {
                    data: { term, name: term, types: ['2'] },
                    route: `/routingPoint/list/custom/`,
                    timeout: 10000,
                };
                const routingPoints = await this.productService.post(request);
                result = routingPoints.data && routingPoints.data.data && routingPoints.data.data.data ? transform(routingPoints.data.data.data) : [];
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.scope.$applyAsync();
            return result;
        }
    };

    /**
     * Method to save an exception stopover inside a voyage
     * @param rowEntity - Object representing the grid row
     * @param port - The new port object to be added as a stopover in the voyage
     * @returns true if records was successfully saved, otherwise false
     */
    async saveExceptionStopover(rowEntity: MaritimeStopover, port: IExceptionStopoverPort): Promise<boolean> {
        const stopover: MaritimeStopover = {
            _id: null,
            ID: null,
            SITUATION: null,
            DEADLINES: null,
            CREATED_DATE: null,
            VOYAGE_NUMBER: null,
            VOYAGE_REFERENCE: null,
            CONCAT_REFERENCE: null,
            PROCESS_REFERENCE: null,
            MODIFIED_DATE: null,
            MODIFIED_BY: null,
            DEPARTURE: null,
            ARRIVAL: null,
            VIA: null,
            BERTHING: null,
            UNBERTHING: null,
            ESTIMATED_ARRIVAL: null,
            ESTIMATED_BERTHING: null,
            ESTIMATED_DEPARTURE: null,
            ESTIMATED_UNBERTHING: null,
            TRANSSHIPMENT_DIRECTION: null,
            TRANSSHIPMENT_ROUTING_POINT: null,
            PORT: { ID: port.ID, NAME: port.NAME, CODE: port.CODE },
            VESSEL_INFORMATION: rowEntity.VESSEL_INFORMATION,
            PRODUCT: rowEntity.PRODUCT,
            IDENTIFIER: uuid.v4(),
            COUNTRY: port.COUNTRY,
            TRANSIT_TIME: 0,
            EXCEPTION: true,
        }

        try {
            await this.operationalService.post('/stopover/maritime/insert', {
                voyage: rowEntity.VOYAGE_REFERENCE,
                stopover: stopover,
            }, 10000);
        } catch (error) {
            this.handleError(error);
            return false;
        }

        return true;
    }

    processesDialog = async (rowEntity?: MaritimeStopover) => {
        const modalID = this.modalService.newModal();
        this.modalService.showModalInfo({
            modalID,
            size: 'sm modal-overflow',
            template: require("../view/voyageProcessesModal.html"),
        }, {
            actionButtonClass: 'btn-default',
            actionButtonText: "GENERAL.CLOSE",
            headerText: "OPERATIONAL.FILE_NUMBER"
        }, {
            processes: rowEntity.PROCESS_REFERENCE,
            onClose: () => this.modalService.closeModal(modalID),
            openProcess: async (process) => {
                this.sessionService.openTab("app.operational.newProcess.list", <IProcessParameter>{ PROCESS_NUMBER: process });
            },
        });
    }

    public async getProcessByNumber(processNumber: string) {
        try {
            const timeout = 120000;
            const processPromise = await this.operationalService.get(`/process/byNumber/${processNumber}/${timeout}`, timeout);
            if (!processPromise || !processPromise.data || (processPromise.data && !processPromise.data.data)) return '';
            return processPromise.data.data[0]._id;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    prepareDefaultTabs = (modalOperation: string, productId?: string) => {
        this.defaultTabs = [];
        this.defaultTabs.push({ id: null, stringId: 'voyage', initialTab: false, label: "Viagem", style: 'text-brown', template: "wizardTravelTemplate" });
        this.defaultTabs.push({ id: null, stringId: 'stopovers', initialTab: false, label: "Escalas", style: 'text-cyano', template: "wizardTraverStopoverTemplate" });

        if (!productId || productId == '1' || productId == 'EM') {
            this.defaultTabs.push({ id: null, stringId: 'deadlines', initialTab: false, label: "DeadLines", style: 'text-green', template: "wizardDeadlinesTemplate" });
        }

        if (modalOperation === 'register') {
            this.defaultTabs.unshift(
                { id: null, stringId: 'maritimeServices', initialTab: false, label: "Serviço Marítimo", style: 'text-especial', template: "wizardMaritimeServiceTemplate" }
            );
        }

        this.defaultTabs.forEach((t, index) => t.id = index + 1);
    }

    stopoverWizard = async (modalOperation: string, initialTabId?: string, rowEntity?) => {
        try {
            this.defaultTabs.find(tab => tab.stringId === (initialTabId != null ? initialTabId : 'voyage')).initialTab = true;

            const stopoverParams: IWizardTravelStopoverParams = {
                updateEventParentOperation: async () => {
                    this.$rootScope.refreshPage();
                },
                modalOperation,
                defaultTabs: this.defaultTabs,
                showAllStopoversButton: false,
                transportMode: this.scope.transportMode,
                blockSteps: modalOperation === 'register',
                eventDetailModalID: this.scope.eventDetailModalID,
                voyageId: rowEntity ? rowEntity.VOYAGE_REFERENCE : null,
                processProduct: rowEntity ? rowEntity.PRODUCT.ID : null
            }

            this.scope.wizardStopoverModalID = this.modalService.newModal();
            this.modalService.showModalInfo(
                {
                    template: require("../view/wizardTravelModal.html"),
                    modalID: this.scope.wizardStopoverModalID,
                    formService: modalOperation,
                    size: 'full modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    headerText: "OPERATIONAL.VOYAGE_AND_STOPOVER",
                    actionButtonText: "GENERAL.CLOSE"
                },
                stopoverParams
            );
        } catch (ex) {
            HandleError.exception(ex);
        }
    }

    actionButtonClicked = (_event, entity: MaritimeStopover, buttonId: string) => {
        const wizardTabsRelation = { voyage: 'voyage', stopovers: 'stopovers', deadlines: 'deadlines', search: 'voyage' };
        if (Object.keys(wizardTabsRelation).includes(buttonId)) {
            const modalOperation = buttonId === 'search' ? EOperation.VIEW : EOperation.EDIT;
            this.prepareDefaultTabs(modalOperation, entity.PRODUCT.ID);
            this.stopoverWizard(modalOperation, wizardTabsRelation[buttonId], entity);
        } else {
            switch (buttonId) {
                case 'additionalStopover':
                    this.exceptionStopoverModal(entity);
                    break;
                case 'processes':
                    this.processesDialog(entity);
                    break;
                case 'history':
                    break;
                default:
                    break;
            }
        }
    }

    getCarrierCellValue = (value: VESSEL_INFORMATION) => {
        if (value && value.CARRIER_INFORMATION.length) {
            return value.CARRIER_INFORMATION.map(carrier => carrier ? `${carrier.CARRIER.CODE}(${carrier.VOYAGE})` : '').join(', ');
        }
        return '';
    }

    buildColumns = (): IMonacoColumnDef[] => {
        const iconSize: number = 22;
        const getActionButtonTemplate = (button) => `<span ng-show="grid.appScope.showActionsSelected(row.entity, '${button.id}')"; ng-click="grid.appScope.actionButtonClicked($event, row.entity, '${button.id}')"><a class="${button.color}" tooltip-placement="auto top" uib-tooltip="{{ '${button.title}' | translate }}" tooltip-append-to-body="true" ><i class="fa ${button.icon}" aria-hidden="true"></i></span></a>&nbsp;&nbsp;`;
        const getDatesCellTemplate = (cell: string) => `<div class="wrapper-md" tooltip-placement="auto top" uib-tooltip="{{row.entity.${cell} == null ? 'REGISTRATION.ESTIMATED_DATE' : 'OPERATIONAL.ACTUAL_DATE' | translate }}" tooltip-append-to-body="true" ng-class="{'text-blue text-italic' : row.entity.${cell} == null}">{{row.entity.${cell}_FINAL | datetimeformated}}</div>`;
        const getCarriersCellTemplate = () => '<div class="grid-padding"><span ellipsis-tooltip tooltip-placement="auto top" style="max-width: 100%;" tooltip-enable="true" class="ellipsize" uib-tooltip="{{grid.appScope.getCarrierCellValue(row.entity.VESSEL_INFORMATION)}}">{{grid.appScope.getCarrierCellValue(row.entity.VESSEL_INFORMATION)}}</span></div>';

        const actionBtns = {
            search: getActionButtonTemplate({ id: 'search', icon: 'fa-search', color: 'text-info', title: 'GENERAL.GRID.VIEW' }),
            stopovers: getActionButtonTemplate({ id: 'stopovers', icon: 'fa-pencil', color: 'text-especial', title: 'OPERATIONAL.STOPOVER' }),
            voyage: getActionButtonTemplate({ id: 'voyage', icon: 'fa-ship', color: 'text-black', title: 'OPERATIONAL.VOYAGE' }),
            deadlines: getActionButtonTemplate({ id: 'deadlines', icon: 'fa-clock-o', color: 'text-red', title: 'OPERATIONAL.DEADLINES' }),
            additionalStopover: getActionButtonTemplate({ id: 'additionalStopover', icon: 'fa-plus-circle', color: 'text-brown', title: 'OPERATIONAL.ADDITIONAL_STOPOVER' }),
            processes: getActionButtonTemplate({ id: 'processes', icon: 'fa-file-text', color: 'text-green', title: 'OPERATIONAL.FILE_NUMBER' }),
        };

        const columnsAvailable = [
            { title: "OPERATIONAL.VOYAGE", visibility: true, width: '6%', name: "VOYAGE_NUMBER", content: 'row.entity.VOYAGE_NUMBER' },
            { title: "OPERATIONAL.STOPOVER", visibility: false, width: '10%', name: "CONCAT_REFERENCE", content: 'row.entity.CONCAT_REFERENCE' },
            { title: "GENERAL.SITUATION", visibility: true, width: '6%', name: "SITUATION.NAME", content: 'row.entity.SITUATION.NAME' },
            { title: "BASIC_DATA.VESSEL", visibility: true, width: '10%', name: "VESSEL_INFORMATION.VESSEL.NAME", content: 'row.entity.VESSEL_INFORMATION.VESSEL.NAME' },
            { title: "ROUTE.PORT", visibility: true, width: '10%', name: "PORT.NAME", content: 'row.entity.PORT.NAME' },
            { title: "BASIC_DATA.PRODUCT", visibility: true, width: '10%', name: "PRODUCT.NAME", content: 'row.entity.PRODUCT.NAME' },
            { title: "OPERATIONAL.FILE_NUMBER", visibility: false, width: '10%', name: "PROCESS_REFERENCE", content: 'row.entity.PROCESS_REFERENCE.join(", ")' },
            // Columns with custom template
            { title: "OPERATIONAL.BERTH", visibility: true, width: '10%', name: "BERTHING_FINAL", template: getDatesCellTemplate('BERTHING') },
            { title: "OPERATIONAL.UNBERTH", visibility: true, width: '10%', name: "UNBERTHING_FINAL", template: getDatesCellTemplate('UNBERTHING') },
            { title: "OPERATIONAL.SEA_CARRIER_VOYAGE", visibility: true, width: '23%', name: "VESSEL_INFORMATION2", template: getCarriersCellTemplate(), searchProps: ['VESSEL_INFORMATION.CARRIER_INFORMATION.CARRIER.NAME', 'VESSEL_INFORMATION.CARRIER_INFORMATION.VOYAGE'] },
            { title: "OPERATIONAL.ARRIVAL", visibility: false, width: '10%', name: "ARRIVAL", template: getDatesCellTemplate('ARRIVAL'), searchProps: ['ESTIMATED_ARRIVAL', 'ARRIVAL'] },
            { title: "OPERATIONAL.DEPARTURE", visibility: false, width: '10%', name: "DEPARTURE", template: getDatesCellTemplate('DEPARTURE'), searchProps: ['ESTIMATED_DEPARTURE', 'DEPARTURE'] },
            { title: "GENERAL.IDENTIFIER", visibility: false, width: '10%', name: "IDENTIFIER", content: 'row.entity.IDENTIFIER' },
        ];

        const builtColumns = columnsAvailable.map(columnAvailable => {
            const column: IMonacoColumnDef = {
                headerCellClass: this.gridService.highlightFilteredHeader.bind(this.gridService),
                filter: { condition: this.gridService.filterSelectObject },
                displayName: columnAvailable.title,
                width: columnAvailable.width,
                name: columnAvailable.name,
                filterCellFiltered: true,
                enableFiltering: true,
                enableSorting: false,
                enableHiding: true,
                pinnedLeft: true,
                enablePinning: false,
                enableColumnMoving: true,
                enableColumnResizing: true,
                visible: columnAvailable.visibility,
                searchProps: columnAvailable.searchProps,
            };

            if (columnAvailable.content) {
                column.cellTemplate = `<span uib-tooltip="{{${columnAvailable.content}}}"
                                             class="grid-padding ellipsize"
                                             tooltip-placement="auto top"
                                             style="max-width: 100%;"
                                             tooltip-enable="true"
                                             ellipsis-tooltip
                                            >{{${columnAvailable.content}}}</span>`;
            }

            if (columnAvailable.template) {
                column.cellTemplate = columnAvailable.template;
            }

            return column;
        });

        builtColumns.unshift({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: Object.keys(actionBtns).length * iconSize,
            cellTemplate: `<div class="text-center">${Object.keys(actionBtns).map(btn => actionBtns[btn]).join('')}</div>`,
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        })

        return builtColumns;
    }

    showActionsSelected = (rowEntity: any, action: string): boolean => {
        let show: boolean = true;
        if (action == 'deadlines' && (rowEntity.PRODUCT.ID == '2' || rowEntity.PRODUCT.ID == 'IM')) {
            show = false;
        }
        return show;
    }
}

import * as angular from "angular";
import { SSEService } from "@appServices/SSEService";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IModalService } from '@services/ModalService';
import { ISessionService } from "@services/SessionService";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IMoveTypeModel, IParam } from "@models/interface/product/MoveTypeModel";
import { IDocumentError } from "@models/interface/common/IDocumentError";
import { EOperation } from "@enums/GenericData";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { SelectorModel } from "../../common/model/SelectorModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { HelperService } from "@services/HelperService";

interface IMoveTypeScope extends IGridFormServiceScope {
    scopeBeforeSave: IMoveTypeModel;
    model: IMoveTypeModel;
    gridOptions: uiGrid.IGridOptions;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    directionList: SelectorModel[];
    productList: SelectorModel[];
    typeRuleList: SelectorModel[];
    typeLocalEventList: SelectorModel[];
    eventList: SelectorModel[];
    originTypeList: SelectorModel[];
    destinationTypeList: SelectorModel[];
    user: any;
    sessionService: ISessionService;
    editMoveType: (moveType: IMoveTypeModel) => Promise<void>;
    viewMoveType: (moveType: IMoveTypeModel) => Promise<void>;
    viewLogMoveType: (moveType: IMoveTypeModel) => Promise<void>;
    copyMoveType: (moveType: IMoveTypeModel) => Promise<void>;
    addParam: () => void;
    removeParam: (index: number) => void;
    updateCode: () => void;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
}

export class MoveTypeRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IMoveTypeScope;
    private $q: ng.IQService;
    private $timeout: ng.ITimeoutService;
    private ModalService: IModalService;
    private RestService: IRestService;
    private SSEService: SSEService;
    private gridName: string;
    private modalID: number;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IMoveTypeScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.ModalService = $injector.get('ModalService');
        this.RestService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope);
        this.gridName = 'GRID_MOVE_TYPE';
        this.modalID = null;
        this.helperService = $injector.get('HelperService');
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();

            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'moveType', 'GENERAL.MENU.MOVE_TYPE', true);
            await this.initGrid(this.gridName, '/moveType/list', true, true, null, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initDependencies(): Promise<any> {
        const self: MoveTypeRegisterController = this;
        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getProductList(),
                self.getTypeRuleList(),
                self.getTypeLocalEventList(),
                self.getEventList(),
                self.getDirectionList(),
                self.getOriginTypeList(),
                self.getDestinationTypeList()
            ]).then(async (result: any) => {
                self.$scope.productList = result[0];
                self.$scope.typeRuleList = result[1];
                self.$scope.typeLocalEventList = result[2];
                self.$scope.eventList = result[3];
                self.$scope.directionList = result[4];
                self.$scope.originTypeList = result[5];
                self.$scope.destinationTypeList = result[6];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.addParam = (): void => {
            this.addParam();
        }
        this.$scope.removeParam = (index: number): void => {
            this.removeParam(index);
        }
        this.$scope.updateCode = (): void => {
            this.updateCode();
        }

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }

        this.$scope.editMoveType = async (moveType: IMoveTypeModel): Promise<void> => {
            let blockedObject = {
                ID: moveType.ID,
                NAME: moveType.NAME,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || moveType.ID !== this.$scope.model.ID) this.$scope.view(moveType);
                } else if (this.$scope.operation !== EOperation.EDIT || moveType.ID !== this.$scope.model.ID) {
                    this.$scope.edit(moveType);
                }
            };
        }

        this.$scope.viewMoveType = async (moveType: IMoveTypeModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(moveType);
        }

        this.$scope.viewLogMoveType = async (moveType: IMoveTypeModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(moveType);
        }

        this.$scope.copyMoveType = async (moveType: IMoveTypeModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(moveType);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewMoveType(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editMoveType(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogMoveType(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyMoveType(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy + modalIntegration),
            enableCellEdit: true,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };

        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colName: IMonacoColumnDef = { name: "NAME", displayName: "GENERAL.NAME", width: 250 };
            const colCode: IMonacoColumnDef = { name: "CODE", displayName: "GENERAL.CODE", width: 250 };
            const colNameIntl: IMonacoColumnDef = { name: "NAME_INTL", displayName: "GENERAL.NAME_INTL", width: 250 };
            const colOriginType: IMonacoColumnDef = { name: "ORIGIN_TYPE.NAME", displayName: "ROUTE.ORIGIN_TYPE", width: 200 };
            const colDestinationType: IMonacoColumnDef = { name: "DESTINATION_TYPE.NAME", displayName: "ROUTE.DESTINATION_TYPE", width: 200 };
            const colIdIntegration: IMonacoColumnDef = { name: "INTEGRATION_ID", displayName: "GENERAL.INTEGRATION_ID", width: 120 };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'CODE':
                        columnDefs.push(colCode);
                        break;
                    case 'NAME':
                        columnDefs.push(colName);
                        break;
                    case 'NAME_INTL':
                        columnDefs.push(colNameIntl);
                        break;
                    case 'ORIGIN_TYPE':
                        columnDefs.push(colOriginType);
                        break;
                    case 'DESTINATION_TYPE':
                        columnDefs.push(colDestinationType);
                        break;
                    case 'INTEGRATION_ID':
                        columnDefs.push(colIdIntegration);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            CODE: null,
            APPLY_ROAD: false,
            NAME: null,
            NAME_INTL: null,
            ORIGIN_TYPE: null,
            DESTINATION_TYPE: null,
            PARAMS: null,
            ACTIVE: true,
            INTEGRATION_ID: null,
            DOCUMENT_ERROR: null,
            SEARCH_FIELDS: null
        };
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.SSEService.closeEvents();
            this.addParam();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} (${this.$scope.model.NAME})`;
            BrowserTitle.$id = this.$scope.model.NAME;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} (${this.$scope.model.NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        return {
            route: `/moveType/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "GENERAL.MENU.INTEGRATION" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number, idSubject: string): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/moveType/sendSync`, { "idMoveType": id, "idSubject": idSubject }, 120000, false);
                if (syncRequest) success = true;
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_SENDING_REQUEST');
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const moveTypeData = await this.getMoveTypeById(id);
                    if (row && moveTypeData && moveTypeData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = moveTypeData.DOCUMENT_ERROR;
                        documentError = moveTypeData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async getMoveTypeById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/moveType/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTypeRuleList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_rule`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTypeLocalEventList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_local_event`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getEventList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/event`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getDirectionList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/direction`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getOriginTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_local_event`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getDestinationTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_local_event`, null);
        return generic && generic.data ? generic.data : [];
    }

    private updateCode(): void {
        this.$scope.model.CODE = null;
        if (this.$scope.model.ORIGIN_TYPE && this.$scope.model.ORIGIN_TYPE.NAME &&
            this.$scope.model.DESTINATION_TYPE && this.$scope.model.DESTINATION_TYPE.NAME) {
            this.$scope.model.CODE = this.$scope.model.ORIGIN_TYPE.NAME.substring(0, 1).toUpperCase() + '/' + this.$scope.model.DESTINATION_TYPE.NAME.substring(0, 1).toUpperCase();
        }
    }

    private addParam(): void {
        try {
            if (!this.$scope.model.PARAMS) this.$scope.model.PARAMS = [];

            const param: IParam = {
                DIRECTION: null,
                ORDER: null,
                PRODUCT: null,
                TYPE_RULE: null,
                CODE: null,
                NAME: null,
                NAME_INTL: null,
                TYPE_LOCAL_EVENT: null,
                EVENT: null
            }

            this.$scope.model.PARAMS.push(angular.copy(param));

            this.$timeout(() => {
                const currentIndex = this.$scope.model.PARAMS.length - 1;
                this.$scope.selectorValidity('direction' + currentIndex);
                this.$scope.selectorValidity('order' + currentIndex);
                this.$scope.selectorValidity('product' + currentIndex);
                this.$scope.selectorValidity('typeRule' + currentIndex);
                this.$scope.selectorValidity('code' + currentIndex);
                this.$scope.selectorValidity('name' + currentIndex);
                this.$scope.selectorValidity('nameIntl' + currentIndex);
                this.$scope.selectorValidity('typeLocalEvent' + currentIndex);
                this.$scope.selectorValidity('event' + currentIndex);
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeParam(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.NO',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });

            if (!modal) return;

            if (this.$scope.model.PARAMS && this.$scope.model.PARAMS.length > 0) {
                this.formService.block();
                this.$scope.model.PARAMS.splice(index, 1);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: "CODE",
                LABEL: "GENERAL.CODE"
            },
            {
                PROPERTY: "NAME",
                LABEL: "GENERAL.NAME"
            },
            {
                PROPERTY: "NAME_INTL",
                LABEL: "GENERAL.NAME_INTL"
            },
            {
                PROPERTY: "ORIGIN_TYPE",
                LABEL: "ROUTE.ORIGIN_TYPE"
            },
            {
                PROPERTY: "DESTINATION_TYPE",
                LABEL: "ROUTE.DESTINATION_TYPE"
            },
            {
                PROPERTY: "PARAMS",
                LABEL: "REGISTRATION.PARAMETERIZATION_NUMBER"
            },
            {
                PROPERTY: "DIRECTION",
                LABEL: "REGISTRATION.FIELD_TYPE"
            },
            {
                PROPERTY: "ORDER",
                LABEL: "REGISTRATION.FIELD_ORDER"
            },
            {
                PROPERTY: "PRODUCT",
                LABEL: "BASIC_DATA.PRODUCT"
            },
            {
                PROPERTY: "TYPE_RULE",
                LABEL: "REGISTRATION.FILLING_RULE"
            },
            {
                PROPERTY: "TYPE_LOCAL_EVENT",
                LABEL: "ROUTE.LOCAL_TYPE"
            },
            {
                PROPERTY: "EVENT",
                LABEL: "OPERATIONAL.EVENT"
            },
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE"
            },
            {
                PROPERTY: "INTEGRATION_ID",
                LABEL: "GENERAL.INTEGRATION_ID"
            },
            {
                PROPERTY: "APPLY_ROAD",
                LABEL: "REGISTRATION.ROAD_MODAL_ACCEPTANCE"
            }
        ];
        return props;
    }
}

import { FormService2, IFormServiceScope } from "@services/FormService2";
import { IModalOptions } from '@services/ModalService';
import { OperationalService } from "@services/OperationalService";
import { IAirFlightSpecific } from 'WBA-Model/dist/interface/operational/AirFlight';
import { ISelectorModel } from "WBA-Model/dist/mongo/SelectorModel";

interface ISpecificAirFlightScope extends IFormServiceScope {
    model: IAirFlightSpecific;
    flightsList: IAirFlightSpecific[];
    flightCode: string;
    baseFlightId: number;
    modalOptions: IModalOptions;
    getFlights: (flightCode: string) => Promise<void>;
    updateFlightCode: (selectedFlight: ISelectorModel) => void;
    saveSpeficicFlight: () => Promise<void>;
}

export class SpecificAirFlightController extends FormService2 {
    static $inject: string[] = ['$injector', '$scope'];
    private scope: ISpecificAirFlightScope;
    private OperationalService: OperationalService;

    constructor($injector: ng.Injectable<any>, $scope: ISpecificAirFlightScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.OperationalService = $injector.get('OperationalService');
    }

    async $onInit(): Promise<void> {
        try {
            this.block();
            this.init("specificAirFlightModalForm", null, null);
            this.initScopeFunctions();
            await this.initModel();
            this.loadRegisterForm(true);

            if (this.scope.flightCode) {

                this.scope.model.FLIGHT_CODE = {
                    ID: null,
                    NAME: null,
                    CODE: null,
                };

                this.scope.model.FLIGHT_CODE.NAME = this.scope.flightCode;
            }

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private initScopeFunctions(): void {
        this.scope.getFlights = async (flightCode: string) => {
            return this.getFlights(flightCode);
        }

        this.scope.saveSpeficicFlight = async () => {
            this.saveSpeficicFlight();
        }

        this.scope.updateFlightCode = (selectedFlight: ISelectorModel) => {
            this.updateFlightCode(selectedFlight);
        }
    }

    private async initModel() {
        this.scope.model = {
            FLIGHT_CODE: null,
            SPECIFIC_DATE: null
        }
    }

    private async getFlights(flightCode: string): Promise<void> {
        try {
            this.block();
            let result = null;
            if (flightCode && flightCode.length >= 3) {
                const rc = await this.OperationalService.post(`/airFlight/list/custom`, { flightCode, isSpecific: false }, 30000);
                if (rc && rc.status == 200 && rc.data && rc.data.data && rc.data.data.data) {
                    result = rc.data.data.data.map(x => {
                        return {
                            ID: x.ID,
                            NAME: x.FLIGHT_CODE,
                            CODE: null
                        }
                    });
                }
            }
            this.scope.flightsList = result;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private updateFlightCode(selectedFlight: ISelectorModel) {
        this.scope.flightCode = selectedFlight ? selectedFlight.NAME : null;
    }

    private async saveSpeficicFlight(): Promise<void> {
        try {
            this.block();
            const newSpecificFlight = {
                FLIGHT_CODE: {
                    NAME: this.scope.flightCode
                },
                SPECIFIC_DATE: this.scope.model.SPECIFIC_DATE
            }

            const registerSpecificFlight = await this.OperationalService.post('/airFlight/insertSpecific', { data: newSpecificFlight });
            if (registerSpecificFlight && registerSpecificFlight.status == 200 && registerSpecificFlight.data && registerSpecificFlight.data.data) {
                this.scope.modalOptions.ok(registerSpecificFlight.data.data);
            }

        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return;
        }
    }
}
import angular = require('angular');
import { IMonacoRequest } from "@services/GridFormService";
import { IModalOptions, IModalService } from '@services/ModalService';
import { FormService2, IFormServiceScope } from "@services/FormService2";
import { IRestService } from '@services/RestService';
import { ISessionService } from "@services/SessionService";
import { ProductService } from "@services/ProductService";
import { OperationalService } from "@services/OperationalService";
import { ISelectorModel, SelectorModel } from "@models/mongo/SelectorModel"
import { UserModel } from "@models/interface/common/UserModel";
import { OfferCompiled, CARGO, ENTITY } from "@models/interface/product/OfferCompiled";
import { Process } from "@models/interface/operational/NewProcess";
import { FileReferenceModel } from "@models/interface/operational/FileReferenceModel";
import { IProcessData, IOfferList, TERMINAL_REDIRECT } from "@models/interface/operational/IOfferWizard";
import { ECargoTypeId, EProductId } from '@enums/GenericData';
import { IProcessParameter } from "../../common/model/ModelParameter";
import { IOfferTariffFiles } from '@models/interface/product/Offer';
import { HelperService } from "@services/HelperService";

interface IUiTabSteps {
    current: number;
    percent: number;
}

interface OUTER_CELLS extends ISelectorModel {
    ENTITY: ISelectorModel;
}

interface offerCompiledFull extends OfferCompiled {
    EXPORTERSELECTED: ENTITY;
    IMPORTERSELECTED: ENTITY;
    NOTIFYSELECTED: ENTITY;
    TERMINAL_REDIRECT: TERMINAL_REDIRECT;
    OUTER_CELLS_SELECTED: OUTER_CELLS[];
}

interface IOfferModalScope extends IFormServiceScope {
    modalOptions: IModalOptions;
    eventDetailModalID: number;
    steps: IUiTabSteps;
    model: offerCompiledFull;
    originalCargo: CARGO;
    addressOrigin: string;
    addressDestin: string;
    user: UserModel;
    processes: Process[];

    // templates
    wizardOfferDataTemplate: string;
    wizardOfferCargoTemplate: string;
    wizardOfferInstructionsTemplate: string;
    wizardOfferConfirmationTemplate: string;

    // lists
    exporterList: ENTITY[];
    importerList: ENTITY[];
    notifyList: ENTITY[];
    brokerList: ENTITY[];
    localAgentList: ENTITY[];
    destinAgentList: ENTITY[];
    documentList: ISelectorModel[];
    cargoList: CARGO[];
    processDataList: IProcessData[];
    terminalList: TERMINAL_REDIRECT[];
    outerCellList: OUTER_CELLS[];

    //gets
    getExporterList: (exporter_data: ISelectorModel[], search: string) => Promise<void>;
    getImporterList: (importer_data: ISelectorModel[], search: string) => Promise<void>;
    getNotifyList: (notify_data: ISelectorModel[], search: string) => Promise<void>;
    getBrokerList: (search: string) => Promise<void>;
    getTerminalList: (search: string) => Promise<void>;
    getOuterCellList: (name: string) => Promise<void>;

    //conditions
    isCargoAir: () => boolean;
    isCargoFcl: () => boolean;
    isCargoLclBbRoro: () => boolean;
    isCargoBbRoro: () => boolean;

    //navigation
    back: () => void;
    next: () => void;
    finish: () => void;
    tabClick: (tabIndex: number) => void;
    isTabEnabled: (tabIndex: number) => boolean;
    collapseMainShip: (mainShipIndex: number) => void;
    updateDataOfferCompiled: (id: string) => void;
    openProcesses: () => void;

    //functions
    addCargo: () => void;
    removeCargo: (index: number) => void;
    addReferenceList: (documentType: ISelectorModel, documentValue: string, index: number) => void;
    goToProcess: (process: string) => void;
}

export class OfferWizardModalController extends FormService2 {
    static $inject: string[] = ['$injector', '$scope', 'RestService', 'ModalService', 'config', 'FormService'];
    private scope: IOfferModalScope;
    private $RestService: IRestService;
    private $SessionService: ISessionService;
    private $q: ng.IQService;
    private timeout: ng.ITimeoutService
    private ModalService: IModalService;
    private ProductService: ProductService;
    private OperationalService: OperationalService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IOfferModalScope) {
        super($injector, $scope);
        this.scope = $scope;
        this.$RestService = $injector.get('RestService');
        this.$SessionService = $injector.get('SessionService');
        this.ModalService = $injector.get('ModalService');
        this.$q = $injector.get('$q');
        this.timeout = $injector.get('$timeout');
        this.ProductService = $injector.get('ProductService');
        this.OperationalService = $injector.get('OperationalService');
        this.helperService = $injector.get('HelperService'); 
    }

    async $onInit(): Promise<void> {
        try {
            this.block();

            this.init("offerWizardModalForm", null, null);

            this.scope.wizardOfferDataTemplate = 'wizardOfferDataTemplate';
            this.scope.wizardOfferCargoTemplate = 'wizardOfferCargoTemplate';
            this.scope.wizardOfferInstructionsTemplate = 'wizardOfferInstructionsTemplate';
            this.scope.wizardOfferConfirmationTemplate = 'wizardOfferConfirmationTemplate';

            this.initScopeFunctions();
            this.initDependencies();
            this.loadRegisterForm(false);
            this.loadDatas();

            this.unblock();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    private loadDatas() {

        // if there is just one register, use it

        if (this.scope.model.IMPORTER && this.scope.model.IMPORTER.length == 1) this.scope.model.IMPORTERSELECTED = this.scope.model.IMPORTER[0];
        if (this.scope.model.EXPORTER && this.scope.model.EXPORTER.length == 1) this.scope.model.EXPORTERSELECTED = this.scope.model.EXPORTER[0];
        if (this.scope.model.NOTIFY && this.scope.model.NOTIFY.length == 1) this.scope.model.NOTIFYSELECTED = this.scope.model.NOTIFY[0];

        if (this.scope.model.SERVICES) {
            const indexServiceDesactive = this.scope.model.SERVICES.findIndex(item => item.ACTIVE == false);
            if (indexServiceDesactive >= 0) this.scope.model.SERVICES.splice(indexServiceDesactive, 1)

        }

        this.scope.cargoList = [];
        this.scope.originalCargo = null;
        if (this.scope.model.CARGO) {
            this.scope.cargoList.push(angular.copy(this.scope.model.CARGO));

            //reference
            let mainReference = null;
            let indexOfMainFile = -1;
            if (this.scope.model.REFERENCE) indexOfMainFile = this.scope.model.REFERENCE.findIndex(item => item.MAIN_FILE == true);
            if (indexOfMainFile >= 0) {
                mainReference = angular.copy(this.scope.model.REFERENCE[indexOfMainFile]);
                this.scope.model.REFERENCE.splice(indexOfMainFile, 1)
            }
            this.scope.processDataList = [{
                REFERENCE: mainReference, SHIPMENT_REQUIRED: {
                    DATE_OF: null,
                    DATE_UP: null
                },
                CARGO_READINESS: {
                    ESTIMATED_DATE: null,
                    ACTUAL_DATE: null
                }
            }];
        }

        if (this.scope.model.EVENT) {
            for (const event of this.scope.model.EVENT) {
                if (event.EVENT_TYPE && event.EVENT_TYPE.ID == '2') this.scope.addressOrigin = event.ADDRESS;
                if (event.EVENT_TYPE && event.EVENT_TYPE.ID == '10') this.scope.addressDestin = event.ADDRESS;
            }
        }

        if (!this.scope.model.INSTRUCTIONS) {
            this.scope.model.INSTRUCTIONS = {
                PRODUCT: null,
                COMMERCIAL: null,
                OPERATIONAL: null,
                FINANCIAL: null,
                CUSTOMER: null,
                AGENT: null,
                PROCESS_HOUSE: null,
                PROCESS_MASTER: null,
            }
        }

    }

    private initScopeFunctions(): void {
        this.scope.back = async (): Promise<void> => {
            if (this.scope && this.scope.steps && this.scope.steps.current > 0) {
                this.scope.steps.current--;
                await this.handleNavigationChange(this.scope.steps.current);
            }
        }

        this.scope.next = async (): Promise<void> => {
            if (this.scope && this.scope.steps && angular.isDefined(this.scope.steps.current)) {
                //if (this.scope.steps.current == 0) return;

                const validFields = this.checkPendingFields('offerWizardModalForm');
                if (!validFields) return;

                if (this.scope.steps.current < 3) {
                    this.scope.steps.current++;
                    await this.handleNavigationChange(this.scope.steps.current);
                }
            }
        }

        this.scope.tabClick = (tabIndex: number): void => {
            if (!this.scope.steps || tabIndex == null) return;
            const previousStep = this.scope.steps.current - 1;
            const nextStep = this.scope.steps.current + 1;
            if (previousStep == tabIndex) this.scope.back();
            else if (nextStep == tabIndex) this.scope.next();
        }

        this.scope.isTabEnabled = (tabIndex: number): boolean => {
            if (!this.scope.steps || tabIndex == null) return false;
            const previousStep = this.scope.steps.current - 1;
            const nextStep = this.scope.steps.current + 1;
            return (previousStep == tabIndex || nextStep == tabIndex);
        }

        this.scope.finish = async (): Promise<void> => {
            await this.finish();
        }

        this.scope.openProcesses = async (): Promise<void> => {
            this.$SessionService.openTab("app.operational.newProcess.list", <IProcessParameter>{ "PROCESS_NUMBER": this.getCONCAT(this.scope.processes, null, 'PROCESS_NUMBER') });
        }

        this.scope.goToProcess = async (process: string) => {
            this.$SessionService.openTab("app.operational.newProcess.list", <IProcessParameter>{ "PROCESS_NUMBER": process });
        }

        this.scope.getExporterList = async (exporter_data: ENTITY[], search: string): Promise<void> => {
            let exporterList: ENTITY[] = [];
            if (exporter_data || (search && search.length >= 3)) exporterList = await this.getlegalPersonList(exporter_data, search, ['4']);
            this.scope.exporterList = exporterList;
        }

        this.scope.getImporterList = async (importer_data: ENTITY[], search: string): Promise<void> => {
            let importerList: ENTITY[] = [];
            if (importer_data || (search && search.length >= 3)) importerList = await this.getlegalPersonList(importer_data, search, ['5']);
            this.scope.importerList = importerList;
        }

        this.scope.getNotifyList = async (notify_data: ENTITY[], search: string): Promise<void> => {
            let notifyList: ENTITY[] = [];
            if (notify_data || (search && search.length >= 3)) notifyList = await this.getlegalPersonList(notify_data, search, ['6']);
            this.scope.notifyList = notifyList;
        }

        this.scope.getBrokerList = async (search: string): Promise<void> => {
            let brokerList: ENTITY[] = [];
            if (search && search.length >= 3) brokerList = await this.getlegalPersonList([], search, ['1']);
            this.scope.brokerList = brokerList;
        }

        this.scope.getTerminalList = async (search: string): Promise<void> => {
            let terminalList: TERMINAL_REDIRECT[] = [];
            if (search && search.length >= 3) terminalList = await this.getTerminalList(search);
            this.scope.terminalList = terminalList;
        }

        this.scope.getOuterCellList = async (search: string) => {
            let outerCellList: OUTER_CELLS[] = [];
            if (search && search.length >= 3) outerCellList = await this.getOuterCellList(search);
            this.scope.outerCellList = outerCellList;
        }

        this.scope.isCargoAir = () => {
            return this.scope.model.CARGO_TYPE && this.scope.model.CARGO_TYPE.ID == ECargoTypeId.AIR;
        }

        this.scope.isCargoFcl = () => {
            return this.scope.model.CARGO_TYPE && this.scope.model.CARGO_TYPE.ID == ECargoTypeId.FCL;
        }

        this.scope.isCargoLclBbRoro = () => {
            return this.scope.model.CARGO_TYPE && (this.scope.model.CARGO_TYPE.ID == ECargoTypeId.LCL || this.scope.model.CARGO_TYPE.ID == ECargoTypeId.BREAK_BULK || this.scope.model.CARGO_TYPE.ID == ECargoTypeId.RO_RO)
        }

        this.scope.isCargoBbRoro = () => {
            return this.scope.model.CARGO_TYPE && (this.scope.model.CARGO_TYPE.ID == ECargoTypeId.BREAK_BULK || this.scope.model.CARGO_TYPE.ID == ECargoTypeId.RO_RO)
        }

        this.scope.addCargo = (): void => {
            const newCargo = angular.copy(this.scope.model.CARGO);
            this.scope.cargoList.push(newCargo);

            this.scope.processDataList.push({
                REFERENCE: this.scope.processDataList[0].REFERENCE, SHIPMENT_REQUIRED: {
                    DATE_OF: null,
                    DATE_UP: null
                },
                CARGO_READINESS: {
                    ESTIMATED_DATE: null,
                    ACTUAL_DATE: null
                }
            });

        }

        this.scope.removeCargo = async (index: number): Promise<void> => {
            const msg = this.getTranslate('REGISTRATION.DELETE_FILE_CONFIRMATION')
            const confirmed = await this.ModalService.showModalConfirmation({}, {
                bodyText: `${msg} ` + (index + 1) + '?'
            });

            if (!confirmed) return;

            this.scope.cargoList.splice(index, 1);
        }

        this.scope.addReferenceList = async (documentType: ISelectorModel, documentValue: string, index: number): Promise<void> => {
            await this.addReferenceList(documentType, documentValue, index);
        }

    }

    async initDependencies(): Promise<any> {
        try {
            const self: OfferWizardModalController = this;
            return new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getGenericList('document_type'),
                ]).then((result: any) => {
                    self.scope.documentList = result[0];
                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private addReferenceList(documentType: ISelectorModel, documentValue: string, index: number) {

        const reference = <FileReferenceModel>{};
        reference.REFERENCE_ID = null;
        reference.ID_CONSOLIDATED = null;
        reference.SOURCE = "MONACO";
        reference.TYPE = "REFERENCE";
        reference.NAME = null;
        reference.LINK = null;
        reference.TEMPLATE_TYPE = null;
        reference.DOCUMENT_TYPE = (documentType) ? documentType : null;
        reference.DOCUMENT_VALUE = (documentValue) ? documentValue : null;
        reference.FILE_GROUP = null;
        reference.FILE_SPECS = [{ ID: '2', NAME: "Cliente" }];
        reference.MAIN_FILE = true;
        reference.INSERT_DATE = new Date();
        reference.USER_REFERENCE = this.scope.user._id;

        this.scope.processDataList[index].REFERENCE = reference;

    }

    private async getGenericList(type: string): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async getlegalPersonList(model_data: ENTITY[], search: string, specializations?: string[]): Promise<ENTITY[]> {
        let result: ENTITY[] = [];

        try {
            this.block();
            //se tem dados traz eles
            if (model_data && model_data.length > 0) {
                result = model_data;
            } else {
                const productReq: IMonacoRequest = {
                    route: `/legalPerson/list/custom/operational`,
                    data: { specializations, search }
                }
                const legalPersons = await this.ProductService.post<any>(productReq);
                if (legalPersons && legalPersons.data) {
                    result = legalPersons.data.data ? legalPersons.data.data.map(x => {
                        return {
                            ID: x.ID, NAME: x.NAME, CODE: x.CODE,
                            TRADING_NAME: x.TRADING_NAME, TAXPAYER_NUMBER: x.TAXPAYER_NUMBER, SCAC: x.SCAC, NUMBER_IATA: x.NUMBER_IATA, ADDRESS: x.ADDRESS,
                            ID_LEGAL_PERSON: x.ID, NETWORK: x.NETWORK,
                            CORPORATE_NAME: x.CORPORATE_NAME, ID_PHYSICAL_PERSON: null
                        }
                    }) : [];
                }
            }

            return result
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getTerminalList(search: string): Promise<TERMINAL_REDIRECT[]> {
        let result: TERMINAL_REDIRECT[] = [];

        try {
            this.block();

            const productReq = {
                route: `/legalPerson/list/custom/operational`,
                data: { specializations: ['16'], search: search }
            }
            const legalPersons = await this.ProductService.post<any>(productReq);
            result = legalPersons.data.data ? legalPersons.data.data.map(x => {
                return {
                    ID: x.ID, NAME: x.NAME, CODE: x.CODE,
                    TRADING_NAME: x.TRADING_NAME, CORPORATE_NAME: x.CORPORATE_NAME, ID_LEGAL_PERSON: x.ID_LEGAL_PERSON, ID_PHYSICAL_PERSON: null, TAXPAYER_NUMBER: x.TAXPAYER_NUMBER, SCAC: x.SCAC, NUMBER_IATA: x.NUMBER_IATA,
                    ADDRESS: x.ADDRESS && x.ADDRESS.CORRESPONDENCE ? x.ADDRESS.CORRESPONDENCE : ''
                }
            }) : [];

            return result
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    private async getOuterCellList(search: string): Promise<OUTER_CELLS[]> {
        let result: OUTER_CELLS[] = [];

        try {
            this.block();

            const productReq = {
                route: `/outerCell/list/custom`,
                data: { name: name, entityIds: [] }
            }
            const outerCells = await this.ProductService.post<any>(productReq);
            result = outerCells.data.data ? outerCells.data.data.map(outerCell => { return { ID: outerCell.ID, NAME: outerCell.NAME, CODE: outerCell.CODE ? outerCell.CODE : null, ENTITY: outerCell.ENTITY ? outerCell.ENTITY : null } }) : [];

            return result
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return result;
        }
    }

    // tab navigation
    private async handleNavigationChange(step: number): Promise<void> {
        try {
            if (this.scope.steps.current === 3) { // confirmation
                await this.handleEventOffersConfirmationInit();
            }

            await this.scope.$applyAsync();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    // CONFIRMATION step

    async handleEventOffersConfirmationInit(): Promise<void> {
        try {

            this.block();

            this.timeout(async () => {
                await this.scope.$applyAsync();
                this.unblock();
            }, 100, true);
        } catch (ex) {
            this.handleError(ex);
        }
    }


    private async finish(): Promise<void> {
        try {

            this.block();

            //get external contacts  
            if (this.scope.model.CUSTOMER) await this.getExternalContact(this.scope.model.CUSTOMER, true);
            if (this.scope.model.IMPORTERSELECTED) await this.getExternalContact(this.scope.model.IMPORTERSELECTED);
            if (this.scope.model.EXPORTERSELECTED) await this.getExternalContact(this.scope.model.EXPORTERSELECTED);
            if (this.scope.model.NOTIFYSELECTED) await this.getExternalContact(this.scope.model.NOTIFYSELECTED);
            if (this.scope.model.BROKER) await this.getExternalContact(this.scope.model.BROKER);
            if (this.scope.model.SERVICE_PROVIDER) await this.getExternalContact(this.scope.model.SERVICE_PROVIDER);
            if (this.scope.model.LOCAL_AGENT) await this.getExternalContact(this.scope.model.LOCAL_AGENT);
            if (this.scope.model.EXTERNAL_AGENT) await this.getExternalContact(this.scope.model.EXTERNAL_AGENT);
            if (this.scope.model.THIRD_AGENT) await this.getExternalContact(this.scope.model.THIRD_AGENT);
            if (this.scope.model.CARGO && this.scope.model.CARGO.SECURE && this.scope.model.CARGO.SECURE.INSURANCE_BROKER) await this.getExternalContact(this.scope.model.CARGO.SECURE.INSURANCE_BROKER);

            const processes = new Array<IOfferList>();
            let index = 0;

            this.scope.model.EXPORTER = (this.scope.model.EXPORTERSELECTED) ? [this.scope.model.EXPORTERSELECTED] : null;
            this.scope.model.IMPORTER = (this.scope.model.IMPORTERSELECTED) ? [this.scope.model.IMPORTERSELECTED] : null;
            this.scope.model.NOTIFY = (this.scope.model.NOTIFYSELECTED) ? [this.scope.model.NOTIFYSELECTED] : null;

            const tariffFiles: IOfferTariffFiles = await this.getFilesTariffByOfferId();

            for (const process of this.scope.cargoList) {

                let references = (this.scope.model.REFERENCE) ? [... this.scope.model.REFERENCE] : [];
                if (this.scope.processDataList[index].REFERENCE) {
                    references.push(this.scope.processDataList[index].REFERENCE)
                }

                this.scope.model.CARGO = angular.copy(process);
                processes.push({
                    OFFER: angular.copy(this.scope.model),
                    REFERENCE: references,
                    TARIFF_FILES: tariffFiles,
                    SHIPMENT_REQUIRED: this.scope.processDataList[index].SHIPMENT_REQUIRED,
                    CARGO_READINESS: this.scope.processDataList[index].CARGO_READINESS,
                    TERMINAL_REDIRECT: this.scope.model.TERMINAL_REDIRECT
                });

                index++;
            }

            const timeout: number = 120000;

            const request = {
                operation: 'register',
                data: processes,
                timeout
            };

            const inserProcess = await this.OperationalService.post<any>(`/process/insert`, request, timeout);
            if (inserProcess && inserProcess.data && inserProcess.data.data) {
                const result: Process[] = inserProcess.data.data;
                this.scope.processes = result;
            }

            this.unblock();

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getExternalContact(involved: ENTITY, sendOuterCell: boolean = false) {
        try {
            if (!involved && !involved.ID) return;

            this.block();
            let commodities = [];
            if (this.scope.model.COMMODITY_SUMMARY) {
                for (const commodityItem of this.scope.model.COMMODITY_SUMMARY.COMMODITY_ITEM) {
                    if (commodityItem.COMMODITY && commodityItem.COMMODITY.ID) commodities.push(commodityItem.COMMODITY.ID);
                };
            }

            let result = [];
            const productReq: IMonacoRequest = {
                route: `/contact/list/operational`,
                data: {
                    filters: {
                        LEGAL_PERSON: parseInt(involved.ID_LEGAL_PERSON),
                        PRODUCTS: [this.scope.model.PRODUCT.ID],
                        COMMODITIES: commodities,
                        ACCOUNTS: this.scope.model.CUSTOMER ? [this.scope.model.CUSTOMER.ID] : null,
                        EXPORTERS: this.scope.model.EXPORTERSELECTED ? [this.scope.model.EXPORTERSELECTED.ID] : null,
                        IMPORTERS: this.scope.model.IMPORTERSELECTED ? [this.scope.model.IMPORTERSELECTED.ID] : null,
                        OUTER_CELLS: sendOuterCell && this.scope.model.OUTER_CELLS_SELECTED ? this.scope.model.OUTER_CELLS_SELECTED.map(outerCells => outerCells.ID) : null,
                        ORIGIN_MARITIME: (this.scope.model.POLAOL && this.isMaritimeService()) ? [this.scope.model.POLAOL.ID] : null,
                        DESTINATION_MARITIME: (this.scope.model.PODAOD && this.isMaritimeService()) ? [this.scope.model.PODAOD.ID] : null,
                        ORIGIN_AIR: (this.scope.model.POLAOL && this.isAirService()) ? [this.scope.model.POLAOL.ID] : null,
                        DESTINATION_AIR: (this.scope.model.PODAOD && this.isAirService()) ? [this.scope.model.PODAOD.ID] : null,
                        ORIGIN_ROAD: null,
                        DESTINATION_ROAD: null,
                        TRADE_ORIGIN_AIR: (this.scope.model.POLAOL && this.isAirService()) ? [this.scope.model.POLAOL.TRADE_GROUP.ID] : null,
                        TRADE_DESTINATION_AIR: (this.scope.model.PODAOD && this.isAirService()) ? [this.scope.model.PODAOD.TRADE_GROUP.ID] : null,
                        TRADE_ORIGIN_MARITIME: (this.scope.model.POLAOL && this.isMaritimeService()) ? [this.scope.model.POLAOL.TRADE_GROUP.ID] : null,
                        TRADE_DESTINATION_MARITIME: (this.scope.model.PODAOD && this.isMaritimeService()) ? [this.scope.model.PODAOD.TRADE_GROUP.ID] : null,
                        TRADE_ORIGIN_ROAD: null,
                        TRADE_DESTINATION_ROAD: null,
                        TRADE_LANE_ORIGIN_AIR: (this.scope.model.POLAOL && this.isAirService()) ? [this.scope.model.POLAOL.TRADE_LANE.ID] : null,
                        TRADE_LANE_DESTINATION_AIR: (this.scope.model.PODAOD && this.isAirService()) ? [this.scope.model.PODAOD.TRADE_LANE.ID] : null,
                        TRADE_LANE_ORIGIN_MARITIME: (this.scope.model.POLAOL && this.isMaritimeService()) ? [this.scope.model.POLAOL.TRADE_LANE.ID] : null,
                        TRADE_LANE_DESTINATION_MARITIME: (this.scope.model.PODAOD && this.isMaritimeService()) ? [this.scope.model.PODAOD.TRADE_LANE.ID] : null,
                        TRADE_LANE_ORIGIN_ROAD: null,
                        TRADE_LANE_DESTINATION_ROAD: null,
                        COUNTRY_ORIGIN_AIR: (this.scope.model.POLAOL && this.isAirService()) ? [this.scope.model.POLAOL.COUNTRY.ID] : null,
                        COUNTRY_DESTINATION_AIR: (this.scope.model.PODAOD && this.isAirService()) ? [this.scope.model.PODAOD.COUNTRY.ID] : null,
                        COUNTRY_ORIGIN_MARITIME: (this.scope.model.POLAOL && this.isMaritimeService()) ? [this.scope.model.POLAOL.COUNTRY.ID] : null,
                        COUNTRY_DESTINATION_MARITIME: (this.scope.model.PODAOD && this.isMaritimeService()) ? [this.scope.model.PODAOD.COUNTRY.ID] : null,
                        COUNTRY_ORIGIN_ROAD: null,
                        COUNTRY_DESTINATION_ROAD: null
                    },
                }
            }
            const contacts = await this.ProductService.post<any>(productReq);
            if (contacts && contacts.data) {
                result = contacts.data.data ? contacts.data.data.map(x => {
                    return {
                        ID: x.ID,
                        NAME: x.NAME,
                        EMAIL: x.EMAIL,
                        GROUP: x.MESSAGE_GROUP,
                        TYPE: x.TYPE,
                        COMPANY: x.COMPANY,
                        DATA_ORIGIN_TYPE: { ID: "A", NAME: "Automático" }
                    }
                }) : [];
            }
            for (const contact of result) {
                if (!this.scope.model.CONTACT) this.scope.model.CONTACT = { INTERNAL: [], EXTERNAL: [] }
                let indexId = -1;
                indexId = this.scope.model.CONTACT.EXTERNAL.findIndex(item => item.ID == contact.ID);
                if (!(indexId >= 0)) this.scope.model.CONTACT.EXTERNAL.push(contact);
            }

            this.unblock();

            return;

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private isAirService() {
        return this.scope.model.PRODUCT.ID == EProductId.AIR_IMPORT || this.scope.model.PRODUCT.ID == EProductId.AIR_EXPORT
    }

    private isMaritimeService() {
        return this.scope.model.PRODUCT.ID == EProductId.MARITIME_IMPORT || this.scope.model.PRODUCT.ID == EProductId.MARITIME_EXPORT
    }

    private async getFilesTariffByOfferId(): Promise<IOfferTariffFiles> {
        try {
            const productReq: IMonacoRequest = {
                route: `/offer/list/tariff/files/${this.scope.model.ID}`,
                timeout: 10000
            };

            const result = await this.ProductService.get<any>(productReq);

            if (result && result.data && result.data) return result.data.data;

            return null;
        } catch (ex) {
            this.handleError(ex);
        }
    }
}

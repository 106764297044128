import { IMonacoColumnDef } from "@services/GridService2";
import { IGridFormServiceScope, GridFormService, IGridFormController } from "@services/GridFormService";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { IColumnDef } from "ui-grid";
import { ILinkParameter, IOfferParameter } from "../../common/model/ModelParameter";
import { ISessionService } from "@services/SessionService";
import { IOfferExchangeData } from "../model/OfferModel";
import { IRestService } from "@services/RestService";
import { ITariffFreightExchangeData } from "@models/interface/product/TariffFreightModel";
import { ISelectorModel } from "@models/mongo/SelectorModel";
import { IFreightRatesManagementCharge } from "@models/interface/product/FreightRatesManagementCharge";
import { EFreightRatesManagementChargeType, ECargoTypeId } from "@enums/GenericData";
import { IModalService } from "@services/ModalService";

interface IBreakdownWeightRange {
    weightRange: ISelectorModel;
    rates: IFreightRatesManagementCharge[];
    conversionTotal: number;
}

interface IBreakdownAdditional {
    rates: IFreightRatesManagementCharge[];
    conversionTotal: number;
}

interface ITariffFreightAirScope extends IGridFormServiceScope {
    openChargeBreakdown: (routeId: number) => Promise<void>;
    goToTariffFreightRoute: (contractId: number, tariffId: number) => void;
    goToOfferWizard: (contractId: number, tariffId?: number) => void;
    breakdownWeightRanges: IBreakdownWeightRange[];
    breakdownAdditionals: IBreakdownAdditional;
}

export class NewTariffFreightAirController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: ITariffFreightAirScope;
    private $q: ng.IQService;
    private modalService: IModalService;
    private restService: IRestService;
    private sessionService: ISessionService;
    private dynamicGridColumns: IMonacoColumnDef[];
    private quotationCombinedUrl: String;

    constructor($injector: ng.Injectable<any>, $scope: ITariffFreightAirScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.modalService = $injector.get('ModalService');
        this.restService = $injector.get('RestService');
        this.sessionService = $injector.get('SessionService');
        this.quotationCombinedUrl = this.getUrlQuotationCombined();

    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlQuotationCombined();
            this.initForm(this, 'form', 'tariffFreight', 'GENERAL.MENU.TARIFF_FREIGHT_AIR', false);
            this.formService.hideAddButton();
            this.dynamicGridColumns = await this.getDynamicGridColumns();
            await this.initGrid("GRID_TARIFF_FREIGHT_AIR", `/tariffFreightRatesManagement/list/${ECargoTypeId.AIR}`, true, true, null, true, true);
        } catch (ex) {
            throw ex;
        }
    }

    private async getDynamicGridColumns(): Promise<IMonacoColumnDef[]> {
        const dynamicColumns: IMonacoColumnDef[] = [];
        try {
            this.block();
            const request = await this.restService.getObjectAsPromise(`${this.getUrlProduct()}/weightRange/freightRatesManagement`, 30000, null, false);
            if (request && request.data) {
                const columns: ISelectorModel[] = request.data;
                for (const column of columns) {
                    dynamicColumns.push({ name: "WEIGHT_RANGE_" + column.ID + ".VALUE", displayName: column.NAME, width: 100, cellFilter: "number:2" });
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return dynamicColumns;
        }
    }

    initModel(): void {
    }

    initGridColumns(columns: string[]): IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<a ng-click="grid.appScope.openChargeBreakdown(row.entity._id)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.DETAILS' | translate }}" tooltip-append-to-body="true"><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.goToTariffFreightRoute(row.entity.ROUTE_ID, row.entity.ID_TARIFF_FREIGHT)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true"><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const offer = `<a ng-click="grid.appScope.goToOfferWizard(row.entity.ID_TARIFF_FREIGHT_CONTRACT, row.entity.ID_TARIFF_FREIGHT)" class="text-green edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'PRODUCT.NEW_OFFER' | translate }}" tooltip-append-to-body="true"><i class="fa fa-handshake-o icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes", displayName: "GENERAL.ACTIONS",
            width: 85,
            cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${offer}</div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };

        gridColumns.addColumn(colActions);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colTariffConcat: IMonacoColumnDef = { name: "TARIFF_CONCAT", displayName: "PRODUCT.FREIGHT_TARIFF_CONCATENATED", width: 350 };
            const colRouteConcat: IMonacoColumnDef = { name: "ROUTE_CONCAT", displayName: "PRODUCT.FREIGHT_TARIFF_ROUTE_CONCATENATED", width: 350 };
            const colContract: IMonacoColumnDef = { name: "CONTRACT.NAME", displayName: "BASIC_DATA.FREIGHT_CONTRACT", width: 150 };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 100 };
            const colTransaction: IMonacoColumnDef = { name: "TRANSACTION.NAME", displayName: "BASIC_DATA.TRANSACTION", width: 130 };
            const colCarrier: IMonacoColumnDef = { name: "CARRIER.NAME", displayName: "BASIC_DATA.PROVIDER", width: 120 };
            const colAccount: IMonacoColumnDef = { name: "ACCOUNT.NAME", displayName: "BASIC_DATA.CLIENT", width: 150 };
            const colAccountFantasyName: IMonacoColumnDef = { name: "ACCOUNT.LEGAL_PERSON.FANTASY_NAME", displayName: "GENERAL.CUSTOMER_COMMERCIAL_NAME", width: 250 };
            const colNetwork: IMonacoColumnDef = { name: "NETWORK.NAME", displayName: "BASIC_DATA.EXTERNAL_AGENT_NETWORK", width: 150 };
            const colMoveType: IMonacoColumnDef = { name: "MOVE_TYPE.CODE", displayName: "BASIC_DATA.MOVE_TYPE", width: 120 };
            const colOriginTrade: IMonacoColumnDef = { name: "ORIGIN_TRADE.NAME", displayName: "BASIC_DATA.ORIGIN_TRADE_GROUP", width: 170 };
            const colOriginCountry: IMonacoColumnDef = { name: "ORIGIN_COUNTRY.NAME", displayName: "BASIC_DATA.ORIGIN_COUNTRY", width: 150 };
            const colOrigin: IMonacoColumnDef = { name: "ORIGIN_COMPILED", displayName: "BASIC_DATA.ORIGIN", width: 150 };
            const colPUP: IMonacoColumnDef = { name: "PUP.ROUTING_POINT.DISPLAY_NAME", displayName: "GENERAL.PICK_UP_CODE", width: 150 };
            const colOTFS: IMonacoColumnDef = { name: "OTFS.ROUTING_POINT.DISPLAY_NAME", displayName: "GENERAL.ORIGIN_TERMINAL_CODE", width: 150 };
            const colPOLAOL: IMonacoColumnDef = { name: "POLAOL.ROUTING_POINT.CODE", displayName: "GENERAL.LOADING_PLACE_CODE", width: 150 };
            const colPODAOD: IMonacoColumnDef = { name: "PODAOD.ROUTING_POINT.CODE", displayName: "GENERAL.UNLOADING_PLACE_CODE", width: 150 };
            const colDTFS: IMonacoColumnDef = { name: "DTFS.ROUTING_POINT.DISPLAY_NAME", displayName: "GENERAL.DESTINATION_TERMINAL_CODE", width: 150 };
            const colPLD: IMonacoColumnDef = { name: "PLD.ROUTING_POINT.DISPLAY_NAME", displayName: "GENERAL.PLACE_OF_DELIVERY_CODE", width: 150 };
            const colDestinationTrade: IMonacoColumnDef = { name: "DESTINATION_TRADE.NAME", displayName: "BASIC_DATA.DESTINATION_TRADE_GROUP", width: 170 };
            const colDestinationCountry: IMonacoColumnDef = { name: "DESTINATION_COUNTRY.NAME", displayName: "BASIC_DATA.DESTINATION_COUNTRY", width: 150 };
            const colDestination: IMonacoColumnDef = { name: "DESTINATION_COMPILED", displayName: "BASIC_DATA.DESTINATION", width: 150 };
            const colCommodity: IMonacoColumnDef = { name: "COMMODITY_COMPILED", displayName: "GENERAL.COMMODITY", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.COMMODITY_COMPILED, null, null, null, null, null, true)}}</div>' };
            const colRate: IMonacoColumnDef = { name: "RATE.NAME", displayName: "BASIC_DATA.CHARGE", width: 150 };
            const colCurrency: IMonacoColumnDef = { name: "CURRENCY.CODE", displayName: "GENERAL.CURRENCY", width: 130 };
            const colMinimum: IMonacoColumnDef = { name: "MINIMUM_VALUE", displayName: "GENERAL.MINIMUM", width: 130, cellTemplate: '<div class="ui-grid-cell-contents" >{{row.entity.MINIMUM_VALUE | number: 2 }}</div>' };
            const colAdditionals: IMonacoColumnDef = { name: "ADDITIONALS_COMPILED", displayName: "PRODUCT.ADDITIONAL_CHARGES", width: 150 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 130, cellFilter: "simpleDate" };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 130, cellFilter: "simpleDate" };
            const colService: IMonacoColumnDef = { name: "SERVICE.NAME", displayName: "PRODUCT.MARITIME_SERVICE", width: 150 };
            const colTypeCargo: IMonacoColumnDef = { name: "TYPE_CARGO.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 150 };
            const colContractBaseEvent: IMonacoColumnDef = { name: "CONTRACT_BASE_EVENT.NAME", displayName: "GENERAL.CONTRACT_VALIDITY_BASIS", width: 180 };
            const colContractFrom: IMonacoColumnDef = { name: "CONTRACT_FROM", displayName: "PRODUCT.CONTRACT_VALIDITY_START", width: 180, cellFilter: "simpleDate" };
            const colContractTo: IMonacoColumnDef = { name: "CONTRACT_TO", displayName: "PRODUCT.CONTRACT_VALIDITY_END", width: 180, cellFilter: "simpleDate" };
            const colRouteBaseEvent: IMonacoColumnDef = { name: "ROUTE_BASE_EVENT.NAME", displayName: "GENERAL.ROUTE_VALIDITY_BASIS", width: 180 };
            const colRouteFrom: IMonacoColumnDef = { name: "ROUTE_FROM", displayName: "PRODUCT.ROUTE_VALIDITY_START", width: 180, cellFilter: "simpleDate" };
            const colRouteTo: IMonacoColumnDef = { name: "ROUTE_TO", displayName: "PRODUCT.ROUTE_VALIDITY_END", width: 180, cellFilter: "simpleDate" };
            const colContractType: IMonacoColumnDef = { name: "CONTRACT_TYPE.NAME", displayName: "PRODUCT.FREIGHT_CONTRACT_TYPE", width: 150 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 80, cellFilter: "YesOrNo" };
            const colContractObservation: IMonacoColumnDef = { name: "CONTRACT_OBSERVATION", displayName: "GENERAL.CONTRACT_REMARKS", width: 300, cellTemplate: '<div class="grid-padding"><span style="max-width: 100%;" class="ellipsize" ellipsis-tooltip tooltip-placement="auto top" uib-tooltip="{{row.entity.OBSERVATION}}" tooltip-enable="true" tooltip-append-to-body="true">{{row.entity.OBSERVATION}}</span></div>' };
            const colRouteObservation: IMonacoColumnDef = { name: "ROUTE_OBSERVATION", displayName: "GENERAL.ROUTE_REMARKS", width: 300, cellTemplate: '<div class="grid-padding"><span style="max-width: 100%;" class="ellipsize" ellipsis-tooltip tooltip-placement="auto top" uib-tooltip="{{row.entity.OBSERVATION}}" tooltip-enable="true" tooltip-append-to-body="true">{{row.entity.OBSERVATION}}</span></div>' };
            const colId: IMonacoColumnDef = { name: "ROUTE_ID", displayName: "GENERAL.ID", width: 70 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'TARIFF_CONCAT':
                        columnDefs.push(colTariffConcat);
                        break;
                    case 'ROUTE_CONCAT':
                        columnDefs.push(colRouteConcat);
                        break;
                    case 'CONTRACT':
                        columnDefs.push(colContract);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'TRANSACTION':
                        columnDefs.push(colTransaction);
                        break;
                    case 'CARRIER':
                        columnDefs.push(colCarrier);
                        break;
                    case 'ACCOUNT':
                        columnDefs.push(colAccount);
                        break;
                    case 'ACCOUNT_FANTASY_NAME':
                        columnDefs.push(colAccountFantasyName);
                        break;
                    case 'NETWORK':
                        columnDefs.push(colNetwork);
                        break;
                    case 'MOVE_TYPE':
                        columnDefs.push(colMoveType);
                        break;
                    case 'ORIGIN_TRADE':
                        columnDefs.push(colOriginTrade);
                        break;
                    case 'ORIGIN_COUNTRY':
                        columnDefs.push(colOriginCountry);
                        break;
                    case 'ORIGIN':
                        columnDefs.push(colOrigin);
                        break;
                    case 'PUP':
                        columnDefs.push(colPUP);
                        break;
                    case 'OTFS':
                        columnDefs.push(colOTFS);
                        break;
                    case 'POLAOL':
                        columnDefs.push(colPOLAOL);
                        break;
                    case 'PODAOD':
                        columnDefs.push(colPODAOD);
                        break;
                    case 'DTFS':
                        columnDefs.push(colDTFS);
                        break;
                    case 'PLD':
                        columnDefs.push(colPLD);
                        break;
                    case 'DESTINATION_TRADE':
                        columnDefs.push(colDestinationTrade);
                        break;
                    case 'DESTINATION_COUNTRY':
                        columnDefs.push(colDestinationCountry);
                        break;
                    case 'DESTINATION':
                        columnDefs.push(colDestination);
                        break;
                    case 'COMMODITY_COMPILED':
                        columnDefs.push(colCommodity);
                        break;
                    case 'RATE':
                        columnDefs.push(colRate);
                        break;
                    case 'CURRENCY':
                        columnDefs.push(colCurrency);
                        break
                    case 'MINIMUM_VALUE':
                        columnDefs.push(colMinimum);
                        if (this.dynamicGridColumns && this.dynamicGridColumns.length) for (const column of this.dynamicGridColumns) columnDefs.push(column);
                        break;
                    case 'ADDITIONALS':
                        columnDefs.push(colAdditionals);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                    case 'SERVICE':
                        columnDefs.push(colService);
                        break;
                    case 'TYPE_CARGO':
                        columnDefs.push(colTypeCargo);
                        break;
                    case 'CONTRACT_BASE_EVENT':
                        columnDefs.push(colContractBaseEvent);
                        break;
                    case 'CONTRACT_FROM':
                        columnDefs.push(colContractFrom);
                        break;
                    case 'CONTRACT_TO':
                        columnDefs.push(colContractTo);
                        break;
                    case 'ROUTE_BASE_EVENT':
                        columnDefs.push(colRouteBaseEvent);
                        break;
                    case 'ROUTE_FROM':
                        columnDefs.push(colRouteFrom);
                        break;
                    case 'ROUTE_TO':
                        columnDefs.push(colRouteTo);
                        break;
                    case 'CONTRACT_TYPE':
                        columnDefs.push(colContractType);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'CONTRACT_OBSERVATION':
                        columnDefs.push(colContractObservation);
                        break;
                    case 'ROUTE_OBSERVATION':
                        columnDefs.push(colRouteObservation);
                        break;
                    case 'ROUTE_ID':
                        columnDefs.push(colId);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    getUrlQuotationCombined(): string {
        const baseRoute = '/quotationCombined';
        return this.config.quotationCombinedUrl + baseRoute;
    }

    initDependencies(): Promise<any> {
        const self: NewTariffFreightAirController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([

            ]).then(async (result: any) => {
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    $onDestroy(): void {

    }

    initScopeFunctions(): void {

        this.$scope.openChargeBreakdown = async (routeId: number) => {
            this.openChargeBreakdown(routeId);
        }

        this.$scope.goToTariffFreightRoute = (routeId: number, tariffId: number) => {
            if (routeId == null && tariffId == null) return;
            this.sessionService.openTab("app.product.freightRoutes", <ILinkParameter>{ ID: routeId ? routeId.toString() : routeId }, <ITariffFreightExchangeData>{ OPERATION: "edit", ID: routeId ? routeId.toString() : null, ID_TARIFF: tariffId });
        }

        this.$scope.goToOfferWizard = (contractId: number, tariffId?: number) => {
            if (contractId == null) throw Error("contractId is null");
            this.sessionService.openTab("app.commercial.offer", <IOfferParameter>{ CONCATENATED: "" }, <IOfferExchangeData>{ OPERATION: "new", ID_TARIFF_FREIGHT_CONTRACT: contractId, ID_TARIFF: tariffId });
        }
    }

    private async openChargeBreakdown(routeId: number) {
        if (!routeId) return;
        try {
            this.block();
            const freightRatesManagementCharges: IFreightRatesManagementCharge[] = await this.restService.newObjectPromise(`${this.getUrlQuotationCombined()}/tariffFreightRatesManagementCharge/list`, { ROUTE_ID: routeId, ID_TYPE_CARGO: ECargoTypeId.AIR }, 30000, false);
            const additionalRates = freightRatesManagementCharges.filter(freightRates => freightRates.TYPE && freightRates.TYPE == EFreightRatesManagementChargeType.ADDITIONAL);
            const additionalRatesWithConversion = additionalRates && additionalRates.length ? additionalRates.filter(rate => rate.CURRENCY_CONVERSION) : null;
            let additionalRatesConversionTotal = 0;
            if (additionalRatesWithConversion && additionalRatesWithConversion.length) for (const rate of additionalRatesWithConversion) { additionalRatesConversionTotal += rate.CURRENCY_CONVERSION.TOTAL; }
            if (freightRatesManagementCharges && freightRatesManagementCharges.length) {
                this.$scope.breakdownWeightRanges = this.buildBreakdownWeighRange(freightRatesManagementCharges.filter(freightRates => freightRates.TYPE && freightRates.TYPE == EFreightRatesManagementChargeType.WEIGHT_RANGE));
                this.$scope.breakdownAdditionals = { rates: additionalRates, conversionTotal: additionalRatesConversionTotal };
                this.modalService.showModalInfo(
                    {
                        scope: this.$scope,
                        formService: 'view',
                        template: require("../view/modals/tariffFreightAirManagementChargesModal.html"),
                        size: 'full'
                    },
                    {
                        closeButtonText: 'GENERAL.CLOSE',
                        headerText: 'GENERAL.DETAILS'
                    });
            } else this.formService.notifyInfo(this.formService.getTranslate("PRODUCT.MESSAGE.FREIGHT_RATES_MANAGEMENT_HAS_NO_CHARGES_TO_SHOW"));
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private buildBreakdownWeighRange(weightRangeRates: IFreightRatesManagementCharge[]): IBreakdownWeightRange[] {
        const breakdownWeightRange: IBreakdownWeightRange[] = [];
        try {
            if (weightRangeRates && weightRangeRates.length) {
                for (const weightRangeRate of weightRangeRates) {
                    if (weightRangeRate.WEIGHT_RANGE_CHARGE) {
                        const breakdownEqpt = breakdownWeightRange.find(weightRange => weightRange.weightRange.ID == weightRangeRate.WEIGHT_RANGE_CHARGE.ID);
                        if (breakdownEqpt) {
                            breakdownEqpt.rates.push(weightRangeRate);
                            if (weightRangeRate.CURRENCY_CONVERSION) breakdownEqpt.conversionTotal += weightRangeRate.CURRENCY_CONVERSION.TOTAL;
                        }
                        else breakdownWeightRange.push({
                            weightRange: weightRangeRate.WEIGHT_RANGE_CHARGE,
                            rates: [weightRangeRate],
                            conversionTotal: weightRangeRate.CURRENCY_CONVERSION ? weightRangeRate.CURRENCY_CONVERSION.TOTAL : 0
                        });
                    }
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            return breakdownWeightRange;
        }
    }
}

import * as angular from "angular";
import { SSEService } from "@appServices/SSEService";
import { ISessionService } from "@services/SessionService";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { ILegalPersonParams } from "@models/interface/product/LegalPersonParams";
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { SelectorModel } from "../../common/model/SelectorModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { ILegalPersonListCustomFilter, ILegalPersonSelector } from "../../registration/model/LegalPersonModel";
import { EOperation } from "@enums/GenericData";
import { ICustomLogProperties } from "WBA-Model/dist/interface/common/IViewLog";
import { HelperService } from "@services/HelperService";

interface ICorporateUnitScope extends IGridFormServiceScope {
    model: ILegalPersonParams;
    scopeBeforeSave: ILegalPersonParams;
    productList: SelectorModel[];
    processTypeList: SelectorModel[];
    corporateBranchList: SelectorModel[];
    user: any;
    sessionService: ISessionService;
    editCorporateUnit: (legalPersonParams: ILegalPersonParams) => Promise<void>;
    viewCorporateUnit: (legalPersonParams: ILegalPersonParams) => Promise<void>;
    viewLogCorporateUnit: (legalPersonParams: ILegalPersonParams) => Promise<void>;
    copyCorporateUnit: (legalPersonParams: ILegalPersonParams) => Promise<void>;
    getCorporateBranchListByName: (search: string) => Promise<void>;
    customLogProperties: ICustomLogProperties[];
}

export class CorporateUnitRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: ICorporateUnitScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private SSEService: SSEService;
    private gridName: string;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: ICorporateUnitScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$scope.additionalIndexSelectorValidity = ['$parentIndex'];
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.SSEService = new SSEService($injector, $scope);
        this.gridName = 'GRID_CORPORATE_UNIT';
        this.helperService = $injector.get('HelperService');
    }

    initScopeFunctions(): void {
        this.$scope.getCorporateBranchListByName = async (search: string) => {
            let corporateBranchList: SelectorModel[] = [];
            if (search && search.length >= 3) {
                corporateBranchList = await this.getCorporateBranchListByName(search);
            }
            this.$scope.corporateBranchList = corporateBranchList;
        }

        this.$scope.editCorporateUnit = async (legalPersonParams: ILegalPersonParams): Promise<void> => {
            let blockedObject = {
                ID: legalPersonParams.ID,
                NAME: legalPersonParams.CREATED_AT.toString(),
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || legalPersonParams.ID !== this.$scope.model.ID) this.$scope.view(legalPersonParams);
                } else if (this.$scope.operation !== EOperation.EDIT || legalPersonParams.ID !== this.$scope.model.ID) {
                    this.$scope.edit(legalPersonParams);
                }
            };
        }

        this.$scope.viewCorporateUnit = async (legalPersonParams: ILegalPersonParams): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.view(legalPersonParams);
        }

        this.$scope.viewLogCorporateUnit = async (legalPersonParams: ILegalPersonParams): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(legalPersonParams);
        }

        this.$scope.copyCorporateUnit = async (legalPersonParams: ILegalPersonParams): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(legalPersonParams);
        }
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'legalPersonParams', 'BASIC_DATA.BRANCH', true);
            await this.initGrid(this.gridName, '/legalPersonParams/list', true, true, null, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            PRODUCT: null,
            PROCESS_TYPE: null,
            CORPORATE_BRANCH: null,
            ID_CORPORATE_BRANCH: null,
            ACTIVE: true,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            ID: null,
            _id: null
        }
    }

    initDependencies(): Promise<any> {
        const self: CorporateUnitRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getProductList(),
                self.getProcessTypeList(),
            ]).then((result: any) => {
                self.$scope.productList = result[0];
                self.$scope.processTypeList = result[1];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getProcessTypeList() {
        const { data: generic } = await this.helperService.get(`/generic/value/process_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getCorporateBranchListByName(search?: string): Promise<ILegalPersonSelector[]> {
        let result = [];
        this.formService.block();
        try {
            const productIds = this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.length ? this.$scope.model.PRODUCT.map(product => product.ID) : null;
            const corporateBranchs = await this.RestService.newObjectPromise(`${this.$baseUrl}/corporateBranch/list/custom`, { search: search, products: productIds }, 30000, false);
            if (corporateBranchs) result = corporateBranchs.map(corporateBranch => { return { ID: corporateBranch.ID, NAME: corporateBranch.NAME, CODE: corporateBranch.CODE } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewCorporateUnit(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editCorporateUnit(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogCorporateUnit(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyCorporateUnit(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy),
            enableCellEdit: true,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };

        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colProduct: IMonacoColumnDef = { name: "PRODUCT", displayName: "BASIC_DATA.PRODUCT", width: 250, cellTemplate: '<div class="grid-padding">{{grid.appScope.getCONCAT(row.entity.PRODUCT, null, "NAME")}}</div>' };
            const colProcessType: IMonacoColumnDef = { name: "PROCESS_TYPE", displayName: "BASIC_DATA.FILE_TYPE", width: 250, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PROCESS_TYPE, null, "NAME")}}</div>' };
            const colCorporateBranch: IMonacoColumnDef = { name: "CORPORATE_BRANCH.CODE", displayName: "BASIC_DATA.BRANCH", width: 350 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 150, cellFilter: "YesOrNo" };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 150 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'PROCESS_TYPE':
                        columnDefs.push(colProcessType);
                        break;
                    case 'CORPORATE_BRANCH':
                        columnDefs.push(colCorporateBranch);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} (${this.$scope.model.CORPORATE_BRANCH.NAME})`;
            BrowserTitle.$id = this.$scope.model.CORPORATE_BRANCH.NAME;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} (${this.$scope.model.CORPORATE_BRANCH.NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.CORPORATE_BRANCH.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        return {
            route: `/legalPersonParams/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }


    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: 'CREATED_AT',
                LABEL: 'GENERAL.CREATED_AT'
            },
            {
                PROPERTY: 'CREATED_BY',
                LABEL: 'GENERAL.CREATED_BY'
            },
            {
                PROPERTY: 'UPDATED_AT',
                LABEL: 'GENERAL.UPDATED_AT'
            },
            {
                PROPERTY: 'UPDATED_BY',
                LABEL: 'GENERAL.UPDATED_BY'
            },
            {
                PROPERTY: 'ACTIVE',
                LABEL: 'GENERAL.ACTIVE'
            },
            {
                PROPERTY: 'CORPORATE_BRANCH',
                LABEL: 'BASIC_DATA.BRANCH'
            },
            {
                PROPERTY: 'PROCESS_TYPE',
                LABEL: 'BASIC_DATA.FILE_TYPE'
            },
            {
                PROPERTY: 'PRODUCT',
                LABEL: 'BASIC_DATA.PRODUCT'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            }
        ];
        return props;
    }

}
import * as angular from "angular";
import { IColumnDef } from "ui-grid";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequest } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { ExternalService } from "@services/ExternalService";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { FollowUpNotification } from "@models/interface/common/FollowUpNotification";
import { SelectorModel } from "../../common/model/SelectorModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { HelperService } from "@services/HelperService";

interface IFollowUpNotificationScope extends IGridFormServiceScope {
    model: FollowUpNotification;
    editorOptions: Summernote.Options;
    languageList: SelectorModel[];
    productList: SelectorModel[];
    cargoTypeList: SelectorModel[];
    recipientList: SelectorModel[];
    log: IViewLog;
    scopeBeforeSave: FollowUpNotification;
    htmlEditorPopover: string;
    customLogProperties: ICustomLogProperties[]
}

export class FollowUpNotificationRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IFollowUpNotificationScope;
    private $timeout: ng.ITimeoutService
    private $q: ng.IQService;
    private RestService: IRestService;
    private SCEService: ng.ISCEService;
    private ExternalService: ExternalService;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IFollowUpNotificationScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$timeout = $injector.get('$timeout');
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.SCEService = $injector.get('$sce');
        this.ExternalService = $injector.get('ExternalService');
        this.$baseUrl = this.config.externalUrl + '/external';
        this.helperService = $injector.get('HelperService'); 
    }

    async $onInit(): Promise<void> {
        try {
            this.$scope.htmlEditorPopover = this.SCEService.trustAsHtml(
                `<b>O editor abaixo está habilitado para o uso de <i>tags</i>!</b>
                <br><br>
                Estas tags atuam como <b>"marcações"</b>, explicando ao sistema a posição no texto em que você deseja que sejam postas determinadas informações.
                <br><br>
                No momento de utilizar o conteúdo abaixo o Monaco irá <i>interpretar, traduzir e substituir</i> suas tags por dados reais vindo do 
                <div class="label label-info">Processo</div>, 
                <div class="label label-success">Eventos</div>, 
                <div class="label label-warning">Atividades</div>, 
                <div class="label label-primary">Taxas</div>, 
                <div class="label label-default">Faturas</div> e diversos outros.
                <br><br>
                Utilize o caracter <div class="label label-danger">@</div> para obter auxílio na inserção de tags.
                <br><br>
                <b>Mas atenção!</b>
                Algumas tags são mais complexas do que outras. Em caso de dúvidas é sempre melhor recorrer ao <b>Suporte-TI</b> e pedir uma ajudinha!
                <br>`
            );

            // summernote won't work if we use 'await'
            this.getHint().then((result: angular.IHttpResponse<any>) => {
                const returnedTags: { key: string, value: string }[] = (result.data && result.data.data) ? result.data.data : [];
                const tags = returnedTags.map(x => x.value);
                // inject acquired tags on view element
                const summernoteElem = $('.summernote')[0];
                if (summernoteElem) {
                    const keys = Object.keys(summernoteElem);
                    let summernote = null;
                    if (keys)
                        for (const key of keys) {
                            if (summernoteElem[key] && summernoteElem[key].hasOwnProperty('summernote')) {
                                summernote = summernoteElem[key]['summernote'];
                                break;
                            }
                        }
                    if (summernote && summernote.hasOwnProperty('options'))
                        summernote['options']['hint']['mentions'] = tags;
                }
            });
            this.initForm(this, 'form', 'FollowUpNotification', 'REGISTRATION.FOLLOWUP_NOTIFICATION', true);
            this.block();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.$scope.formOperation = this.formService.getTranslate('REGISTRATION.FOLLOWUP_NOTIFICATION');
            this.setCopyable(false);
            this.setDeletable(true);
            await this.initGrid('gridFollowUpNotification', '/followUpNotification/list/', true, true, 30000, true);
            this.unblock();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    async initScopeFunctions(): Promise<void> {
        try {
            this.$scope.viewLog = (entity) => {
                this.viewLog(entity);
            }
            // https://summernote.org/deep-dive/#initialization-options -> alternative $('.summernote').summernote({<options here>});
            this.$scope.editorOptions = {
                height: 500,
                dialogsInBody: true,
                toolbar: [
                    ['style', ['style']],
                    ['fontsize', ['fontsize', 'fontname']],
                    ['font', ['bold', 'underline', 'clear']],
                    ['color', ['color']],
                    ['para', ['ul', 'ol', 'paragraph']],
                    ['table', ['table']],
                    ['insert', ['link', 'picture']],
                    ['view', ['codeview']],
                ],
                hint: {
                    mentions: [],
                    match: /^\B@(\w*(.)*)$/, // /\B@(\w.*)$/ (original regex)
                    search: function (keyword, callback) {
                        callback($.grep(this.mentions, function (item) {
                            return item.toLocaleLowerCase().indexOf(keyword.toLocaleLowerCase()) != -1;
                        }));
                    },
                    content: function (item) {
                        return `{{${item}}}`;
                    },
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initGridColumns(columns) {

        let columnDefs: Array<IColumnDef> = new Array();

        //action column, contain just buttons and icons
        const view = `<div class="text-center"><a ng-click="grid.appScope.view(row.entity)" class="text-info"tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate}}" tooltip-append-to-body="true" ><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`
        const edit = `<a ng-click="grid.appScope.edit(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`
        const copy = `<a ng-click="grid.appScope.copy(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a> &nbsp;&nbsp;`
        const history = `<a ng-click="grid.appScope.viewLog(row.entity)" class="text-green" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a></div>`

        columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + copy + history),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        let NewColumnDefs = this.buildColunms(columns);
        for (let column of NewColumnDefs) { columnDefs.push(column) }
        return columnDefs;
    }

    buildColunms(column, prop?): any {
        try {
            let columnDefs = new Array();
            for (var property in column) {
                if (typeof (column[property]) === 'string') { //finally reached the bottom
                    let currentProperty = column[property];
                    let name = null;
                    let displayName = null;
                    let width = 0;
                    let cellTemplate = undefined;
                    let cellFilter = null;
                    let grouping = null;
                    let sort = null;
                    let pinned = null;
                    let visible = true;
                    let filter = { condition: this.gridService.filterSelectObject };
                    switch (currentProperty.toUpperCase()) {
                        case 'ID':
                            name = currentProperty;
                            displayName = 'REGISTRATION.IDENTIFICATION';
                            width = currentProperty.length + 3;
                            break;
                        case 'NAME':
                            name = currentProperty;
                            displayName = 'GENERAL.NAME';
                            width = currentProperty.length + 15;
                            break;
                        case 'STATUS':
                            name = currentProperty + '.NAME';
                            displayName = 'GENERAL.ACTIVE';
                            width = currentProperty.length + 4;
                            break;
                        case 'LANGUAGE':
                            name = currentProperty + '.NAME';
                            displayName = 'GENERAL.LANGUAGE';
                            width = currentProperty.length + 1;
                            break;
                        case 'PRODUCT':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.PRODUCT';
                            cellTemplate = `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PRODUCT)}}</div>`;
                            width = currentProperty.length + 4;
                            break;
                        case 'RECIPIENT':
                            name = currentProperty + '.NAME';
                            displayName = 'REGISTRATION.RECIPIENT';
                            cellTemplate = `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.RECIPIENT)}}</div>`;
                            width = currentProperty.length + 4;
                            break;
                        case 'CARGO_TYPE':
                            name = currentProperty + '.NAME';
                            displayName = 'BASIC_DATA.CARGO_TYPE';
                            cellTemplate = `<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.CARGO_TYPE)}}</div>`;
                            width = currentProperty.length + 4;
                            break;
                        default:
                            name = currentProperty;
                            width = currentProperty.length;
                            visible = false;
                            break;
                    };
                    const newColumn = {
                        name: (prop) ? prop + '.' + name : name,
                        displayName: displayName,
                        headerCellClass: 'header-filtered',
                        width: width + '%',
                        cellTemplate: cellTemplate,
                        cellFilter: cellFilter,
                        visible: visible,
                        filter: filter
                    };
                    columnDefs.push(newColumn);
                } else {
                    let newCol = this.buildColunms(column[property], property);
                    if (newCol)
                        for (let dados of newCol) {
                            columnDefs.push(dados);
                        }
                }
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            NAME: null,
            CARGO_TYPE: null,
            PRODUCT: null,
            RECIPIENT: null,
            CONTENT: null,
            STATUS: null,
            LANGUAGE: { ID: '1', NAME: 'pt-BR' }
        }
    }

    initDependencies(): Promise<any> {
        const self: FollowUpNotificationRegisterController = this;
        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getGenericList('product'),
                self.getGenericList('language'),
                self.getGenericList('type_cargo'),
                self.getGenericList('followup_recipient'),
            ]).then((result: Array<any>) => {
                self.$scope.productList = result[0];
                self.$scope.languageList = result[1];
                self.$scope.cargoTypeList = result[2];
                self.$scope.recipientList = result[3];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async getGenericList(type): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/${type}`, null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private getHint(): Promise<any> {
        const request = {
            "conditionalArrays": true,
            "respectBound": true,
            "includeWildtags": true,
            "models": ["PROCESS", "CHARGE", "DEADLINE", "STOPOVER", "DOCUMENT", "INVOICE", "TASK"]
        };
        return this.ExternalService.post({ route: "/followUpModel/model/hint", data: request })
    }

    private requestHistory(id: string): Promise<any> {
        return this.ExternalService.get({ route: `/followUpNotification/viewLog/${id}` });
    }

    async view(): Promise<void> {
        BrowserTitle.$id = this.$scope.model.NAME;
    }

    async edit(): Promise<void> {
        BrowserTitle.$id = this.$scope.model.NAME;
        this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
    }

    async request(): Promise<IMonacoRequest> {
        if (this.$scope.operation === 'delete') {
            return {
                route: `/followUpNotification/delete/${this.$scope.model._id}`,
                timeout: 15000
            };
        } else {
            return {
                route: `/followUpNotification/insert`,
                data: {
                    model: angular.copy(this.$scope.model),
                    beforeScope: angular.copy(this.$scope.scopeBeforeSave),
                },
                operation: this.$scope.operation,
                timeout: 15000
            };
        }
    }

    async save(): Promise<boolean> {

        // use summernote API to make sure we're getting most updated text 
        // as the 'enter' on tag selection doesn't trigger change event, preventing ngModel from being updated ($scope syncronism issue)
        const html: string = String($('.summernote').summernote('code'));
        this.$scope.model.CONTENT = html;

        if (this.$scope.model.CONTENT && this.$scope.model.CONTENT.length > 0) {
            return true;
        } else {
            this.handleWarning('A mensagem da notificação de acompanhamento não pode estar vazia.');
            return false;
        }
    }

    private viewLog(followUpNotification) {
        this.block();
        let log: IViewLog = {
            operation: 'history',
            number: followUpNotification.ID,
            list: [],
            show: true,
            searchQuery: '',
            originalList: [],
        }
        this.requestHistory(log.number).then(result => {
            log.list = result && result.data && result.data.data;
            log.originalList = angular.copy(log.list);
            this.$scope.log = log;
            angular.element('#log-viewer').removeClass('ng-hide');
            const position = angular.element('#log-viewer').offset().top + $('.app-content-body').scrollTop() - 105;
            $('.app-content-body').animate({
                scrollTop: position
            }, 500);

            this.unblock();
        }).catch(ex => {
            this.handleError(ex);
        });
    };

    private getCustomLogProperties() {
        const props: Array<ICustomLogProperties> = [
            {
                PROPERTY: 'LANGUAGE',
                LABEL: 'GENERAL.LANGUAGE'
            },
            {
                PROPERTY: 'CONTENT',
                LABEL: 'OPERATIONAL.CONTENT'
            },
            {
                PROPERTY: 'PRODUCT',
                LABEL: 'BASIC_DATA.PRODUCT'
            },
            {
                PROPERTY: 'NAME',
                LABEL: 'GENERAL.NAME'
            },
            {
                PROPERTY: 'STATUS',
                LABEL: 'OPERATIONAL.STATUS'
            },
            {
                PROPERTY: 'CARGO_TYPE',
                LABEL: 'BASIC_DATA.CARGO_TYPE'
            },
            {
                PROPERTY: 'RECIPIENT',
                LABEL: 'REGISTRATION.RECIPIENT'
            }
        ];
        return props;
    }
}
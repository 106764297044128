import { IApplicationList } from '@models/interface/product/ApplicationModel';
import { MongoModel } from '../../common/model/MongoModel';
import { SelectorModel } from '../../common/model/SelectorModel';
import { IParam } from '@models/interface/product/MoveTypeModel';
import { IBBROROCargo, IFCLCargo, IFTLCargo, IAirCargo, ICargo, IDangerousCargo, ISecure } from '../../commercial/model/OfferModel';
import { ICommodity } from '@models/interface/product/CommodityModel';
import { IOfferOptionAux, IOfferOptionInvalid } from '../controller/OfferOldWizardModalRegisterController';
import { IUploadItem } from '@models/interface/common/IMonacoUpload';

export enum EOfferOpportunitySituation {
    AWAITING_ACTION = "1",
    AWAITING_PRICING = "2",
    SEARCH_AGAIN = "3",
    DECLINED = "4",
    FINISHED = "5"
}

export interface IOfferOpportunityModel extends MongoModel {
    PREOFFER_CODE: string;
    OFFER_TAB: IOfferTab;
    ROUTE_TAB: IRouteTab;
    CARGO_TAB: ICargoTab;
    SPEC_TAB: ISpecTab;
    OPTION_TAB: IOptionTab;
    FOLLOW_UP_TAB: IFollowUpTab;
    ATTACHMENT_TAB: IAttachmentTab;
    COMPLETED: boolean;
    SITUATION: SelectorModel;
    REQUESTER: SelectorModel;
    RECIPIENT: SelectorModel;
    ACTUAL_RESPONSIBLE: SelectorModel;
    REASON: SelectorModel[]
    CREATED_BY: SelectorModel;
    UPDATED_BY: SelectorModel;
    CREATED_AT: Date;
    UPDATED_AT: Date;
}

export interface IOfferOpportunityGridModel extends MongoModel {
    ACTUAL_RESPONSIBLE: SelectorModel;
    OFFER_TAB: IOfferTab;
    ROUTE_TAB: IRouteTab;
    REASON: SelectorModel[];
    RECIPIENT: SelectorModel;
    REQUESTER: SelectorModel;
    SITUATION: SelectorModel;
    CREATED_AT: Date;
}

export interface IRoute {
    SELECTED: boolean;
    MOVE_TYPE: IMoveType;
    ORIGIN: SelectorModel;
    DESTINATION: SelectorModel;
}

export interface IMoveType extends SelectorModel {
    ORIGIN_TYPE: SelectorModel;
    DESTINATION_TYPE: SelectorModel;
    PARAMS: IParam[];
}

export interface ISpecRoute {
    NAME: string;
    ORIGIN: SelectorModel;
    DESTINATION: SelectorModel;
    MOVE_TYPE: IMoveType;
    MASTER_DIRECT: boolean;
    LOCAL_AGENT: IAgent;
    EXTERNAL_AGENT: IAgent;
    OTHER_AGENT: IAgent;
    AGENT_NETWORK_ID: number;
    MASTER_MODALITY: SelectorModel;
    HOUSE_MODALITY: SelectorModel;
    ACCOUNT_REQUIREMENT_ID: number;
    ACCOUNT_REQUIREMENT_CONCATENATED: string;
}

export interface IOfferTab {
    PRODUCT: SelectorModel;
    TYPE_CARGO: SelectorModel;
    LOAD_REF: Date;
    ACCOUNT: SelectorModel;
    ID_ACCOUNT: number;
    EXPORTER: SelectorModel;
    IMPORTER: SelectorModel;
    PROVIDER: SelectorModel;
    ID_PROVIDER: number;
    INCOTERM: SelectorModel;
    OFFER_TYPE: SelectorModel;
    PROCESS_TYPE: SelectorModel;
    CURRENCY: SelectorModel;
    VALUE: string;
    ACCOUNT_REQUEST: Date;
    TARIFF_FREIGHT_CONTRACT_PAYMENT: SelectorModel;
    TARIFF_FREIGHT_CONTRACT_RECEIVING: SelectorModel;
    ID_TARIFF_FREIGHT_PAYMENT: number;
    ID_TARIFF_FREIGHT_RECEIVING: number;
    COMMODITY: ICommodity[];
    COMMODITY_SECTION: SelectorModel[];
    NOTIFY: SelectorModel[];
}

export interface IRouteTab {
    ROUTES: IRoute[];
}

export interface ICargoTab {
    BB_RORO: IBBROROCargo[];
    FCL: IFCLCargo[];
    FTL: IFTLCargo[];
    AIR: IAirCargo;
    LCL_APPLICATION: IApplicationList;
    LCL_AMOUNT: number;
    VOLUME: number;
    GROSS_WEIGHT: number;
    CUBIC_WEIGHT: number;
    CARGO_LIST: ICargo[];
    STACKABLE: boolean;
    STANDARD_EQUIPMENT: SelectorModel;
}

export interface ISpecTab {
    ROUTE: ISpecRoute[];
    IS_DANGEROUS_CARGO: boolean;
    DANGEROUS_CARGO: IDangerousCargo[];
    IS_INSURANCE: boolean;
    SECURE: ISecure;
}

interface IOptionTab {
    OFFER_OPTIONS: IOfferOptionAux[];
    INVALID_OFFER_OPTIONS: IOfferOptionInvalid[];
    HAS_ANY_OPTION: boolean;
}

export interface IFollowUpTab {
    FOLLOW_UP: IOfferOpportunityFollowUp[];    
}

interface IAttachmentTab {
    FILES: IUploadItem[];    
}

export interface IOfferOpportunityFollowUp {
    FILES: IUploadItem[];
    FILES_NEW: IUploadItem[];
    ID_OFFER_OPPORTUNITY: number;
    ACTUAL_RESPONSIBLE: SelectorModel;
    FROM: SelectorModel;
    SITUATION: SelectorModel;
    DATE: Date;
    MESSAGE: string;
}

export interface IRecipientListCustomFilter {
    search?: string;
    groups?: string[];
    roles?: string[];
}

export interface ITransitTime {
    SERVICE: SelectorModel;
    DIRECT: boolean;
    PORT_DAYS: number;
    DAYS: number;
    TRANSIT_TIME_MARITIME: ITransitTimeMaritime;
    TRANSIT_TIME_AIR: ITransitTimeAir;
    TRANSIT_TIME_DOMESTIC_ORIGIN: ITransitTimeDomesticOrigin;
    TRANSIT_TIME_DOMESTIC_DESTINATION: ITransitTimeDomesticDestination;
    COMPLETED: boolean;
    TRANSSHIPMENT: boolean;
    TRANSIT_TIME_MIN: number;
    TRANSIT_TIME_MAX: number;
}

export interface ITransitTimeMaritime {
    LOGISTIC_SOLUTION: string;
    TRANSSHIPMENT: boolean;
    TRANSSHIPMENTS_NUMBER: number;
    COMPLETED: boolean;
    TRANSIT_TIME_TOTAL: number;
    LEGS_MARITIME: ILegsMaritime[];
    FREQUENCY: SelectorModel;
    PROVIDER: SelectorModel;
}

export interface ILegsAir {
    FREQUENCY: SelectorModel[];
    ORIGIN_TYPE: SelectorModel;
    DESTINATION_TYPE: SelectorModel;
    ID_ROUTING_POINT_ORIGIN: number;
    ID_ROUTING_POINT_DESTINATION: number;
    MODAL: SelectorModel;
    ROUTING_POINT_ORIGIN: SelectorModel;
    ROUTING_POINT_DESTINATION: SelectorModel;
    TRANSIT_TIME_MIN: number;
    TRANSIT_TIME_MAX: number;
    PROVIDER: SelectorModel;
}

export interface ILegsMaritime {
    TYPE_ORIGIN: SelectorModel;
    ORIGIN: number;
    ROUTING_POINT_ORIGIN: SelectorModel;
    TYPE_DESTINATION: SelectorModel;
    DESTINATION: number;
    ROUTING_POINT_DESTINATION: SelectorModel;
    TRANSIT_TIME: number;
    TYPE: SelectorModel;
    TRANSSHIPMENT: boolean;
    PROVIDER: SelectorModel;
}

export interface ITransitTimeAir {
    LOGISTIC_SOLUTION: string;
    TRANSSHIPMENT: boolean;
    COMPLETED: boolean;
    TRANSIT_TIME_TOTAL_MIN: number;
    TRANSIT_TIME_TOTAL_MAX: number;
    LEGS_AIR: ILegsAir[];
    PROVIDER: SelectorModel;
}

export interface ILegsDomestic {
    TYPE: SelectorModel;
    TYPE_LOCAL_ORIGIN: SelectorModel;
    TYPE_LOCAL_DESTINATION: SelectorModel;
    ID_ROUTING_POINT_ORIGIN: number;
    ID_ROUTING_POINT_DESTINATION: number;
    FREQUENCY: SelectorModel[];
    TRANSIT_TIME_MIN: number;
    TRANSIT_TIME_MAX: number;
    ROUTING_POINT_ORIGIN: SelectorModel;
    ROUTING_POINT_DESTINATION: SelectorModel;
    PROVIDER: SelectorModel;
}

interface IAgent extends SelectorModel {
    LEGAL_PERSON_ID: number;
}

interface ITransitTimeDomesticOrigin {
    LOGISTIC_SOLUTION: string;
    COMPLETED: boolean;
    TRANSIT_TIME_TOTAL_MIN: number;
    TRANSIT_TIME_TOTAL_MAX: number;
    LEGS_DOMESTIC: ILegsDomestic[];
    PROVIDER: SelectorModel;
    LEGAL_PERSON_AGENT: SelectorModel;
}

interface ITransitTimeDomesticDestination {
    LOGISTIC_SOLUTION: string;
    COMPLETED: boolean;
    TRANSIT_TIME_TOTAL_MIN: number;
    TRANSIT_TIME_TOTAL_MAX: number;
    LEGS_DOMESTIC: ILegsDomestic[];
    PROVIDER: SelectorModel;
    LEGAL_PERSON_AGENT: SelectorModel;
}
import * as angular from "angular";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IChargeNameModel, IParams, IChargeNameExhibition, IMarkup, IBilling } from "@models/interface/product/ChargeNameModel";
import { IApplicationList } from "@models/interface/product/ApplicationModel";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { SelectorModel } from "../../common/model/SelectorModel";
import { IModalService } from '@services/ModalService';
import { ISessionService } from "@services/SessionService";
import { ILinkParameter } from "../../common/model/ModelParameter";
import { IChargeNameSubGroupModel } from "../model/ChargeNameSubGroupModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { DataProductService } from "@services/DataProductService";
import { IDocumentError } from '@models/interface/common/IDocumentError';
import { SSEService } from "../../app/services/SSEService";
import { EOperation } from "@enums/GenericData";
import { HelperService } from "@services/HelperService";

interface IChargeNameScope extends IGridFormServiceScope {
    model: IChargeNameModel;
    gridOptions: uiGrid.IGridOptions;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IChargeNameModel;
    productList: SelectorModel[];
    subGroupList: SelectorModel[];
    financialContractTypeList: SelectorModel[];
    transactionList: SelectorModel[];
    typeList: SelectorModel[];
    typeCargoList: SelectorModel[];
    chargeApplicationList: SelectorModel[];
    applicationList: IApplicationList[];
    chargeShownForList: SelectorModel[];
    chargeNameList: SelectorModel[];
    currencyList: SelectorModel[];
    removableChargeNameExhibitionItem: number[];
    user: any;
    sessionService: ISessionService;
    editChargeName: (chargeName: IChargeNameModel) => Promise<void>;
    viewChargeName: (chargeName: IChargeNameModel) => Promise<void>;
    viewLogChargeName: (chargeName: IChargeNameModel) => Promise<void>;
    copyChargeName: (chargeName: IChargeNameModel) => Promise<void>;
    addParam: () => void;
    removeParam: (index: number) => void;
    addChargeNameExhibition: (defaultName: boolean) => void;
    addMarkup: () => void;
    removeMarkup: (index: number) => void;
    removeChargeNameExhibition: (index: number) => void;
    getCONCATInformation: (data: IParams[]) => string;
    getCurrencyListByName: (search: string) => Promise<void>;
    getApplicationListByName: (index: number, search: string) => Promise<void>;
    changeMarkupChargeApplication: (selected: SelectorModel, index: number) => void;
    checkDisabledMarkupCurrency: (index: number) => boolean;
    checkDisabledAddMarkup: () => boolean;
    checkDisabledAddParam: () => boolean;
    validateMarkupValue: (index: number) => void;
    defaultChargeNameExhibitionValidade: (index: number, action: string) => Promise<void>;
    validateDisableCompositionPayment: () => boolean;
    validateDisableCompositionReceiving: () => boolean;
    getChargeNameList: (search: string) => Promise<void>;
    goToCurrency: (id: number) => void;
    goToChargeNameSubGroup: (id: number) => void;
    goToApplication: (id: number) => void;
    insertSubGroup: (name: string) => Promise<void>;
    getSubGroupListByName: (name: string) => Promise<void>;
    handleExhibitionName: () => void;
    compositionPaymentChange: () => void;
    compositionReceivingChange: () => void;
    integrateSankhya: (id: number) => void;
    integrateSankhyaStatus: (id: number) => void;
    buildErrorSankhyaTooltip: (errors: IDocumentError[]) => string;
    fetchData: (id: number, action: string) => Promise<void>;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
    clearConvertValueCurrency: (action: string) => void;
}

export class ChargeNameRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IChargeNameScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private $timeout: ng.ITimeoutService
    private ModalService: IModalService;
    private dataProductService: DataProductService;
    private gridName: string;
    private SSEService: SSEService;
    private modalID: number;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: IChargeNameScope) {
        super($injector, $scope);

        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$timeout = $injector.get('$timeout');
        this.RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');;
        this.dataProductService = $injector.get('DataProductService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.gridName = 'GRID_CHARGE_NAME';
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.modalID = null;
        this.helperService = $injector.get('HelperService');
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();

            this.$scope.currencyList = [];
            this.$scope.chargeNameList = [];

            this.$scope.customLogProperties = this.getCustomLogProperties();
            const chargeShownForList = await this.getChargeShownForList('charge_shown_for');
            this.$scope.chargeShownForList = (chargeShownForList) ? chargeShownForList : [];
            this.initForm(this, 'form', 'chargeName', 'GENERAL.MENU.CHARGES', true);
            await this.initGrid(this.gridName, '/chargeName/list', true, true, null, true, true);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initDependencies(): Promise<any> {
        const self: ChargeNameRegisterController = this;

        return new Promise(function (resolve, reject) {
            self.$q.all([
                self.getProductList(),
                self.getTypeList(),
                self.getTransactionList(),
                self.getTypePaymentList(),
                self.getTypeCargoList(),
                self.getFinancialContractTypeList(),
            ]).then((result: any) => {
                self.$scope.productList = result[0];
                self.$scope.typeList = result[1];
                self.$scope.transactionList = result[2];
                self.addTypePaymentList(result[3]);
                self.$scope.typeCargoList = result[4];
                self.$scope.financialContractTypeList = result[5];

                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.editChargeName = async (chargeName: IChargeNameModel): Promise<void> => {
            let blockedObject = {
                ID: chargeName.ID,
                NAME: chargeName.NAME,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || chargeName.ID !== this.$scope.model.ID) this.$scope.fetchData(chargeName.ID, EOperation.VIEW);
                } else if (this.$scope.operation !== EOperation.EDIT || chargeName.ID !== this.$scope.model.ID) {
                    this.$scope.fetchData(chargeName.ID, EOperation.EDIT);
                }
            };
        }

        this.$scope.viewChargeName = async (chargeName: IChargeNameModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(chargeName.ID, EOperation.VIEW);
        }

        this.$scope.viewLogChargeName = async (chargeName: IChargeNameModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(chargeName);
        }

        this.$scope.copyChargeName = async (chargeName: IChargeNameModel): Promise<void> => {
            this.SSEService.closeEvents();
            chargeName.ERP_INTEGRATION_PARAM = null;
            chargeName.DOCUMENT_ERROR = null;
            this.$scope.fetchData(chargeName.ID, EOperation.COPY);
        }

        this.$scope.addParam = (): void => {
            this.addParam();
        }

        this.$scope.removeParam = (index: number): void => {
            this.removeParam(index);
        }

        this.$scope.addChargeNameExhibition = (defaultName: boolean): void => {
            this.addChargeNameExhibition(defaultName);
        }

        this.$scope.removeChargeNameExhibition = (index: number): void => {
            this.removeChargeNameExhibition(index);
        }

        this.$scope.addMarkup = (): void => {
            this.addMarkup();
        }

        this.$scope.removeMarkup = (index: number): void => {
            this.removeMarkup(index);
        }

        this.$scope.getCONCATInformation = (data: IParams[]): string => {
            return this.getCONCATInformation(data);
        }

        this.$scope.getCurrencyListByName = async (search: string): Promise<void> => {
            return await this.getCurrencyListByName(search);
        }

        this.$scope.getApplicationListByName = async (index: number, search: string): Promise<void> => {
            return await this.getApplicationListByName(index, search);
        }

        this.$scope.changeMarkupChargeApplication = (selected: IApplicationList, index: number): void => {
            return this.changeMarkupChargeApplication(selected, index);
        }

        this.$scope.checkDisabledMarkupCurrency = (index: number): boolean => {
            return this.checkDisabledMarkupCurrency(index);
        }

        this.$scope.checkDisabledAddMarkup = (): boolean => {
            return this.checkDisabledAddMarkup();
        }

        this.$scope.checkDisabledAddParam = (): boolean => {
            return this.checkDisabledAddParam();
        }

        this.$scope.validateMarkupValue = (index: number): void => {
            return this.validateMarkupValue(index);
        }

        this.$scope.defaultChargeNameExhibitionValidade = (index: number, action: string): Promise<void> => {
            return this.defaultChargeNameExhibitionValidade(index, action);
        }

        this.$scope.validateDisableCompositionPayment = (): boolean => {
            return this.validateDisableCompositionPayment();
        }

        this.$scope.validateDisableCompositionReceiving = (): boolean => {
            return this.validateDisableCompositionReceiving();
        }

        this.$scope.getChargeNameList = async (search: string): Promise<void> => {
            return await this.getChargeNameList(search);
        }

        this.$scope.goToCurrency = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.currency", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToChargeNameSubGroup = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.chargeNameSubGroup", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.goToApplication = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.application", <ILinkParameter>{ ID: id ? id.toString() : id });
        }

        this.$scope.insertSubGroup = async (name: string) => {
            try {
                const newSubGroupData: IChargeNameSubGroupModel = { _id: null, ID: null, ACTIVE: true, NAME: name, DISPLAY_NAME: name, CREATED_AT: null, CREATED_BY: null, UPDATED_AT: null, UPDATED_BY: null };
                const result = await this.RestService.newObjectPromise(`${this.$baseUrl}/chargeNameSubGroup/insert`, { data: newSubGroupData, oldData: null }, 30000, false);
                if (result) this.formService.notifySuccess("SubGroup successfully added!");
                this.$scope.getSubGroupListByName(name);
            } catch (ex) {
                this.formService.handleError(ex);
            }
        }

        this.$scope.getSubGroupListByName = async (name: string): Promise<void> => {
            let subGroupList = [];
            if (name && name.length >= 3) {
                subGroupList = await this.getSubGroupListByName(name);
            }
            this.$scope.subGroupList = subGroupList;
        }

        this.$scope.handleExhibitionName = (): void => {
            this.handleExhibitionName();
        }

        this.$scope.compositionPaymentChange = (): void => {
            if (this.$scope.model.CHARGE_CURRENCY_PAYMENT_LINK && (!this.$scope.model.COMPOSITION_PAYMENT || (this.$scope.model.COMPOSITION_PAYMENT && this.$scope.model.COMPOSITION_PAYMENT.length == 0 || this.$scope.model.COMPOSITION_PAYMENT.findIndex(composition => composition.ID == this.$scope.model.CHARGE_CURRENCY_PAYMENT_LINK.ID) < 0))) this.$scope.model.CHARGE_CURRENCY_PAYMENT_LINK = null;
        }

        this.$scope.compositionReceivingChange = (): void => {
            if (this.$scope.model.CHARGE_CURRENCY_RECEIVING_LINK && (!this.$scope.model.COMPOSITION_RECEIVING || (this.$scope.model.COMPOSITION_RECEIVING && this.$scope.model.COMPOSITION_RECEIVING.length == 0 || this.$scope.model.COMPOSITION_RECEIVING.findIndex(composition => composition.ID == this.$scope.model.CHARGE_CURRENCY_RECEIVING_LINK.ID) < 0))) this.$scope.model.CHARGE_CURRENCY_RECEIVING_LINK = null;
        }

        this.$scope.integrateSankhya = (id: number) => {
            this.integrateSankhya(id);
        }

        this.$scope.integrateSankhyaStatus = (id: number) => {
            this.integrateSankhyaStatus(id);
        }

        this.$scope.buildErrorSankhyaTooltip = (errors: IDocumentError[]) => {
            return this.buildErrorSankhyaTooltip(errors);
        }
        this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
            this.fetchData(id, action);
        }
        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }

        this.$scope.clearConvertValueCurrency = (action: string) => {
            this.clearConvertValueCurrency(action);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewChargeName(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editChargeName(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyChargeName(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogChargeName(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate}}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + copy + viewLog + modalIntegration),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colType: IMonacoColumnDef = { name: "TYPE.NAME", displayName: "FINANCIAL.CHARGE_TYPE", width: 250 };
            const colSubGroup: IMonacoColumnDef = { name: "CHARGE_NAME_SUB_GROUP.NAME", displayName: "FINANCIAL.CHARGE_SUBGROUP", width: 250 };
            const colName: IMonacoColumnDef = { name: "NAME", displayName: "GENERAL.NAME", width: 250 };
            const colCode: IMonacoColumnDef = { name: "CODE", displayName: "GENERAL.CODE", width: 100 };
            const colService: IMonacoColumnDef = { name: "SERVICE.NAME", displayName: "FINANCIAL.PRODUCT_SERVICE_TYPE", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.SERVICE, null, "NAME")}}</div>' };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PRODUCT, null, "ID")}}</div>' };
            const colParams: IMonacoColumnDef = { name: "PARAMS.TYPE_CARGO.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.CHARGE_NAME_REPL, "null", "TYPE_CARGO_NAME")}}</div>' };
            const colExhibition: IMonacoColumnDef = { name: "CHARGE_NAME_EXHIBITION.NAME", displayName: "FINANCIAL.CHARGE_DISPLAY_NAME", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.CHARGE_NAME_EXHIBITION, null, null, false, true)}}</div>' };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 80, cellFilter: "YesOrNo" };
            const colIdIntegration: IMonacoColumnDef = { name: "INTEGRATION_ID", displayName: "GENERAL.INTEGRATION_ID", width: 120 };
            const colAgentComission: IMonacoColumnDef = { name: "AGENT_COMISSION", displayName: "REGISTRATION.AGENT_PROFIT_SHARE", width: 130, cellFilter: "YesOrNo" };
            const colPayment: IMonacoColumnDef = { name: "PARAMS.PAYMENT.NAME", displayName: "FINANCIAL.DEBIT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.CHARGE_NAME_REPL, null, "PAYMENT_NAME")}}</div>' };
            const colReceiving: IMonacoColumnDef = { name: "PARAMS.RECEIVING.NAME", displayName: "FINANCIAL.CREDIT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.CHARGE_NAME_REPL, "null", "RECEIVING_NAME")}}</div>' };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colDocumentError: IMonacoColumnDef = { name: "DOCUMENT_ERROR", displayName: "REGISTRATION.INTEGRATION_ERROR", width: 200, cellTemplate: "<div class='grid-padding' >{{ grid.appScope.getCONCAT(row.entity.DOCUMENT_ERROR, null, 'REASON', null, true) }}</div>" };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'TYPE':
                        columnDefs.push(colType);
                        break;
                    case 'CHARGE_NAME_SUB_GROUP':
                        columnDefs.push(colSubGroup);
                        break;
                    case 'NAME':
                        columnDefs.push(colName);
                        break;
                    case 'CODE':
                        columnDefs.push(colCode);
                        break;
                    case 'SERVICE':
                        columnDefs.push(colService);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'PARAMS':
                        columnDefs.push(colParams);
                        columnDefs.push(colPayment);
                        columnDefs.push(colReceiving);
                        break;
                    case 'CHARGE_NAME_EXHIBITION':
                        columnDefs.push(colExhibition);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'INTEGRATION_ID':
                        columnDefs.push(colIdIntegration);
                        break;
                    case 'AGENT_COMISSION':
                        columnDefs.push(colAgentComission);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                    case 'DOCUMENT_ERROR':
                        columnDefs.push(colDocumentError);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            CHARGE_NAME_SUB_GROUP: null,
            ID_CHARGE_NAME_SUB_GROUP: null,
            TYPE: null,
            NAME: null,
            CODE: null,
            SERVICE: null,
            PRODUCT: null,
            BILLING: null,
            PARAMS: null,
            DISPLAY_PAYMENT: null,
            DISPLAY_RECEIVING: null,
            FISCAL_STATEMENT: false,
            AGENT_COMISSION: false,
            OBSERVATION: null,
            CHARGE_NAME_EXHIBITION: null,
            COMPOSITION_PAYMENT: null,
            COMPOSITION_RECEIVING: null,
            ID_CHARGE_CURRENCY_PAYMENT_LINK: null,
            CHARGE_CURRENCY_PAYMENT_LINK: null,
            ID_CHARGE_CURRENCY_RECEIVING_LINK: null,
            CHARGE_CURRENCY_RECEIVING_LINK: null,
            MARKUP: null,
            ACTIVE: true,
            INTEGRATION_ID: null,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            ERP_INTEGRATION_PARAM: null,
            DOCUMENT_ERROR: null,
            CONVERT_VALUE_ACTIVE: false,
            CONVERT_VALUE_CURRENCY: null,
        };
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.addParam();
            this.addChargeNameExhibition(true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW')} (${this.$scope.model.NAME})`;
            this.SSEService.closeEvents();
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT')} (${this.$scope.model.NAME})`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.SSEService.closeEvents();
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            await this.clearFields(this.$scope.model);
            this.SSEService.closeEvents();
            if (this.$scope.model.CHARGE_NAME_EXHIBITION && this.$scope.model.CHARGE_NAME_EXHIBITION.length > 0) {
                for (const chargeNameExhibition of this.$scope.model.CHARGE_NAME_EXHIBITION) {
                    chargeNameExhibition.ID = null;
                }
            }
            this.$scope.model.ERP_INTEGRATION_PARAM = null; 
            this.$scope.model.DOCUMENT_ERROR = null;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
        this.$scope.scopeBeforeSave = angular.copy(this.$scope.model)
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        return {
            route: `/chargeName/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/charge_origin`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTransactionList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/transaction`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTypePaymentList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_payment`, null);
        return generic && generic.data ? generic.data : [];
    }

    private addTypePaymentList(data: SelectorModel[]): void {
        try {
            if (data && !this.$scope.model.BILLING) {
                this.$scope.model.BILLING = [];
                data.sort((a, b) => (a.NAME > b.NAME) ? 1 : -1);
                for (const item of data) {
                    const billing: IBilling = {
                        TYPE: item,
                        MASTER: {
                            PAYMENT_BILLING: null,
                            RECEIVING_BILLING: null
                        },
                        HOUSE: {
                            PAYMENT_BILLING: null,
                            RECEIVING_BILLING: null
                        }
                    }
                    this.$scope.model.BILLING.push(billing);
                }
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getTypeCargoList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_cargo`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getChargeShownForList(identifier: string): Promise<Array<SelectorModel>> {
        try {
            let resultList = new Array<SelectorModel>();

            const { data: generic } = await this.helperService.get(`/generic/getByIdentifier/${identifier}`, null, 15000);

            if ((generic.status !== 200) || (!generic.data)) return null;

            let selectorList = generic && generic.data ? generic.data : [];

            if (name) {
                selectorList = selectorList.filter(x => (x.VALUE == name || x.ALTERNATIVE == name));
            }

            for (const item of selectorList) {
                const selectorModel = <SelectorModel>{};
                selectorModel.ID = item.CODE;
                selectorModel.NAME = item.VALUE;
                selectorModel.CODE = item.ALTERNATIVE;

                resultList.push(selectorModel);
            }

            if (resultList.length > 0) {
                resultList = resultList.sort((x, y) => x.ID < y.ID ? -1 : 1);
            }

            return resultList;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getFinancialContractTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/financial_contract_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private addParam(): void {
        try {
            const param: IParams = {
                TYPE_CARGO: null,
                PAYMENT: null,
                RECEIVING: null
            }

            if (!this.$scope.model.PARAMS) this.$scope.model.PARAMS = [];
            this.$scope.model.PARAMS.push(angular.copy(param));
            this.$timeout(() => {
                for (let i = 0; i < this.$scope.model.PARAMS.length; i++) {
                    this.$scope.selectorValidity('typeCargo' + i);
                    this.$scope.selectorValidity('payment' + i);
                    this.$scope.selectorValidity('receiving' + i);
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeParam(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const thatTranslated = this.formService.getTranslate("GENERAL.GENDER.THAT", null, true);
            const originTranslated = this.formService.getTranslate("BASIC_DATA.ORIGIN", null, true);
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.NO',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thatTranslated, prop: originTranslated })
            });

            if (!modal) return;

            if (this.$scope.model.PARAMS) {
                this.formService.block();

                this.$scope.model.PARAMS.splice(index, 1);

                if (this.$scope.operation == 'register' && this.$scope.model.MARKUP) this.$scope.model.MARKUP.splice(index, 1);

                this.formService.unblock();
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private addChargeNameExhibition(defaultName: boolean = false): void {
        try {
            const name: IChargeNameExhibition = {
                _id: null,
                ID: null,
                ID_CHARGE_NAME: null,
                NAME: null,
                CODE: null,
                NAME_INTL: null,
                DEFAULT: defaultName,
                ACTIVE: true,
                SEARCH_FIELDS: null
            }
            if (!this.$scope.model.CHARGE_NAME_EXHIBITION) this.$scope.model.CHARGE_NAME_EXHIBITION = [];
            this.$scope.model.CHARGE_NAME_EXHIBITION.push(angular.copy(name));

            this.$timeout(() => {
                for (let i = 0; i < this.$scope.model.CHARGE_NAME_EXHIBITION.length; i++) {
                    this.$scope.selectorValidity('exhibitionNameName' + i);
                    this.$scope.selectorValidity('exhibitionNameNameIntl' + i);
                    this.$scope.selectorValidity('exhibitionNameDefault' + i);
                }
            });

            if (!this.$scope.removableChargeNameExhibitionItem) this.$scope.removableChargeNameExhibitionItem = [];
            this.$scope.removableChargeNameExhibitionItem.push(this.$scope.model.CHARGE_NAME_EXHIBITION.length - 1);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeChargeNameExhibition(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const thatTranslated = this.formService.getTranslate("GENERAL.GENDER.THAT", null, true);
            const originTranslated = this.formService.getTranslate("BASIC_DATA.ORIGIN", null, true);
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.NO',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thatTranslated, prop: originTranslated })
            });

            if (!modal) return;

            if (this.$scope.model.CHARGE_NAME_EXHIBITION) {
                this.formService.block();

                this.$scope.model.CHARGE_NAME_EXHIBITION.splice(index, 1);
                this.formService.unblock();
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private addMarkup(charge_application?: IApplicationList): void {
        try {
            const markup: IMarkup = {
                CHARGE_APPLICATION: charge_application ? charge_application : null,
                CURRENCY: null,
                VALUE: null
            }

            if (!this.$scope.model.MARKUP) this.$scope.model.MARKUP = [];
            this.$scope.model.MARKUP.push(angular.copy(markup));
            this.$timeout(() => {
                for (let i = 0; i < this.$scope.model.MARKUP.length; i++) {
                    this.$scope.selectorValidity('markupChargeApplication' + i);
                    this.$scope.selectorValidity('markupCurrency' + i);
                    this.$scope.selectorValidity('markupValue' + i);
                }
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeMarkup(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            const thatTranslated = this.formService.getTranslate("GENERAL.GENDER.THAT", null, true);
            const originTranslated = this.formService.getTranslate("BASIC_DATA.ORIGIN", null, true);
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.NO',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thatTranslated, prop: originTranslated })
            });

            if (!modal) return;

            if (this.$scope.model.MARKUP) {
                this.formService.block();

                this.$scope.model.MARKUP.splice(index, 1);
                this.formService.unblock();
            }

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getCONCATInformation(data: IParams[]): string {
        try {
            let concatData = '';
            if (data && data.length > 0) {
                concatData = data.map(param => {
                    if (param && param.TYPE_CARGO && param.TYPE_CARGO.length > 0) {
                        let subConcatData = '';
                        subConcatData = param.TYPE_CARGO.map(type_cargo => {
                            return type_cargo.NAME;
                        }).join('; ');
                        return subConcatData;
                    }
                }).join('; ');
            }
            return concatData;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getCurrencyListByName(query: string): Promise<void> {
        let result = [];
        try {
            if (query && query.length >= 2) {
                this.block();
                const currency = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/currency/list/custom`, { name: query }, 10000, false);
                if (currency) result = currency.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } });
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            this.$scope.currencyList = result;
        }
    }

    private async getApplicationListByName(index: number, search: string): Promise<void> {
        let result = [];
        let typeCargos = [];
        try {
            if (search && search.length >= 2) {
                if (!this.$scope.model.PRODUCT) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                if (index) {
                    if (!this.$scope.model.PARAMS || !this.$scope.model.PARAMS[index] || !this.$scope.model.PARAMS[index].TYPE_CARGO) throw Error('Select type cargo first!');
                    typeCargos = this.$scope.model.PARAMS[index].TYPE_CARGO.map(item => item.ID);
                } else {
                    if (!this.$scope.model.PARAMS) throw Error(this.formService.getTranslate("PRODUCT.ADD_PARAMS_FIRST"));
                    typeCargos = [];
                    for (const param of this.$scope.model.PARAMS) {
                        if (param.TYPE_CARGO && param.TYPE_CARGO.length > 0) {
                            for (const item of param.TYPE_CARGO) {
                                typeCargos.push(item.ID);
                            }
                        }
                    }
                }
                this.block();
                const products = this.$scope.model.PRODUCT.map(item => item.ID);
                const application = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/application/list/custom`, { search, products, typeCargos }, 10000, false);
                if (application) {
                    result = application.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PERCENT: x.PERCENT, APPLICATION_COMPLEMENT: x.APPLICATION_COMPLEMENT, INTERNAL_SEQUENCE: x.INTERNAL_SEQUENCE, FREE_TYPING_AMOUNT_CHARGE: x.FREE_TYPING_AMOUNT_CHARGE, CT_WITHOUT_DOC: x.CT_WITHOUT_DOC, NAME_INTL: x.NAME_INTL } });
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            this.$scope.applicationList = result;
        }
    }

    private changeMarkupChargeApplication(selected: IApplicationList, index: number): void {
        try {
            if (!index && index != 0) throw Error('index is null');

            if (selected && selected.ID && (selected.PERCENT || selected.ID == '1')) {
                if (this.$scope.model.MARKUP[index].CURRENCY) this.$scope.model.MARKUP[index].CURRENCY = null;
            }

            if (selected && selected.ID && selected.ID == '1') {
                if (this.$scope.model.MARKUP[index].VALUE) this.$scope.model.MARKUP[index].VALUE = null;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private checkDisabledMarkupCurrency(index: number): boolean {
        let result = false;
        try {
            if (!index && index != 0) throw Error('index is null');

            if (this.$scope.model.MARKUP && this.$scope.model.MARKUP.length > 0 && this.$scope.model.MARKUP[index] && this.$scope.model.MARKUP[index].CHARGE_APPLICATION &&
                (this.$scope.model.MARKUP[index].CHARGE_APPLICATION.PERCENT || this.$scope.model.MARKUP[index].CHARGE_APPLICATION.ID == '1')) {
                result = true;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
        finally {
            return result;
        }
    }

    private checkDisabledAddMarkup(): boolean {
        let result = false;
        try {
            if (this.$scope.model && this.$scope.model.MARKUP && this.$scope.model.MARKUP.length > 0) {
                for (const markup of this.$scope.model.MARKUP) {
                    if (markup.CHARGE_APPLICATION && markup.CHARGE_APPLICATION.PERCENT) {
                        result = true;
                    }
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
        finally {
            return result;
        }
    }

    private checkDisabledAddParam(): boolean {
        let result = false;
        try {
            if (this.$scope.model && this.$scope.model.PARAMS && this.$scope.model.PARAMS.length > 0) {
                for (const param of this.$scope.model.PARAMS) {
                    if ((param.PAYMENT && param.PAYMENT.PERCENT) || (param.RECEIVING && param.RECEIVING.PERCENT)) {
                        result = true;
                    }
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
        finally {
            return result;
        }
    }

    private validateMarkupValue(index: number): void {
        try {
            if (!index && index != 0) throw Error('index is null');

            if (this.$scope.model.MARKUP && this.$scope.model.MARKUP.length > 0 && this.$scope.model.MARKUP[index] && this.$scope.model.MARKUP[index].CHARGE_APPLICATION &&
                this.$scope.model.MARKUP[index].CHARGE_APPLICATION.PERCENT) {
                if (this.$scope.model.MARKUP && this.$scope.model.MARKUP[index] && this.$scope.model.MARKUP[index].VALUE &&
                    this.$scope.model.MARKUP[index].VALUE > 100) {
                    this.$scope.model.MARKUP[index].VALUE = 100
                    throw Error('The max percentual value is 100');
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async defaultChargeNameExhibitionValidade(index: number, action: string): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');
            if (!action) throw Error('action is null');

            if (action == 'yes') {
                this.defaultChargeNameExhibitionValidadeYes(index);
            } else {
                this.defaultChargeNameExhibitionValidadeNo(index);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async defaultChargeNameExhibitionValidadeYes(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');

            let modal = true;
            let actual = true;

            if (this.$scope.model.CHARGE_NAME_EXHIBITION && this.$scope.model.CHARGE_NAME_EXHIBITION.length > 1) {
                if (this.$scope.model.CHARGE_NAME_EXHIBITION[index]) {
                    actual = this.$scope.model.CHARGE_NAME_EXHIBITION[index].DEFAULT;
                }
                modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: 'GENERAL.CONFIRM',
                    closeButtonText: 'GENERAL.NO',
                    headerText: 'GENERAL.CONFIRM_ACTION',
                    bodyText: this.formService.getTranslate('FINANCIAL.MESSAGES.ONLY_ONE_DISPLAY_NAME'),
                });
            }

            if (modal && this.$scope.model.CHARGE_NAME_EXHIBITION) {
                // Set all to false
                if (this.$scope.model.CHARGE_NAME_EXHIBITION.length > 0) {
                    for (let item of this.$scope.model.CHARGE_NAME_EXHIBITION) {
                        item.DEFAULT = false;
                    }
                }
                // Set clicked to true
                if (this.$scope.model.CHARGE_NAME_EXHIBITION[index]) {
                    this.$scope.model.CHARGE_NAME_EXHIBITION[index].DEFAULT = true;
                }

            } else if (this.$scope.model.CHARGE_NAME_EXHIBITION[index]) {
                this.$scope.model.CHARGE_NAME_EXHIBITION[index].DEFAULT = actual;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async defaultChargeNameExhibitionValidadeNo(index: number): Promise<void> {
        try {
            if (!index && index != 0) throw Error('index is null');

            let modal = true;

            if (this.$scope.model.CHARGE_NAME_EXHIBITION && this.$scope.model.CHARGE_NAME_EXHIBITION.length > 1) {
                modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: 'GENERAL.CONFIRM',
                    closeButtonText: 'GENERAL.NO',
                    headerText: 'GENERAL.CONFIRM_ACTION',
                    bodyText: this.formService.getTranslate('FINANCIAL.MESSAGES.FIRST_DISPLAY_NAME'),
                });

                if (modal) {
                    // Set all to false
                    for (let item of this.$scope.model.CHARGE_NAME_EXHIBITION) {
                        item.DEFAULT = false;
                    }

                    // Set first to true
                    if (this.$scope.model.CHARGE_NAME_EXHIBITION[0]) {
                        this.$scope.model.CHARGE_NAME_EXHIBITION[0].DEFAULT = true;
                    }
                } else {
                    if (this.$scope.model.CHARGE_NAME_EXHIBITION[index]) {
                        this.$scope.model.CHARGE_NAME_EXHIBITION[index].DEFAULT = true;
                    }
                }
            }

            if (this.$scope.model.CHARGE_NAME_EXHIBITION && this.$scope.model.CHARGE_NAME_EXHIBITION.length == 1) {
                modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: 'GENERAL.CONFIRM',
                    closeButtonText: 'GENERAL.NO',
                    headerText: 'GENERAL.CONFIRM_ACTION',
                    bodyText: this.formService.getTranslate('FINANCIAL.MESSAGES.AT_LEAST_ONE_DISPLAY_NAME'),
                });
                if (this.$scope.model.CHARGE_NAME_EXHIBITION[0]) {
                    this.$scope.model.CHARGE_NAME_EXHIBITION[0].DEFAULT = true;
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private validateDisableCompositionPayment(): boolean {
        let result = true;
        try {
            if (this.$scope.model.PARAMS && this.$scope.model.PARAMS.length > 0) {
                for (const param of this.$scope.model.PARAMS) {
                    if (param.PAYMENT.PERCENT) {
                        result = false;
                        break;
                    }
                }
            }
            if (this.$scope.model.COMPOSITION_PAYMENT && this.$scope.model.COMPOSITION_PAYMENT.length > 0 && result) {
                this.$scope.model.COMPOSITION_PAYMENT = null;
            }
        } catch (ex) {
            throw ex;
        } finally {
            return result;
        }
    }

    private validateDisableCompositionReceiving(): boolean {
        let result = true;
        try {
            if (this.$scope.model.PARAMS && this.$scope.model.PARAMS.length > 0) {
                for (const param of this.$scope.model.PARAMS) {
                    if (param.RECEIVING.PERCENT) {
                        result = false;
                        break;
                    }
                }
            }
            if (this.$scope.model.COMPOSITION_RECEIVING && this.$scope.model.COMPOSITION_RECEIVING.length > 0 && result) {
                this.$scope.model.COMPOSITION_RECEIVING = null;
            }
        } catch (ex) {
            throw ex;
        } finally {
            return result;
        }
    }

    private async getChargeNameList(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                this.block();
                const products = this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.length > 0 ? this.$scope.model.PRODUCT.map(x => { return x.ID }) : [];
                const excludeSelfId = this.$scope.model.ID;
                const chargeName = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/chargeName/list/custom`, { search, products, excludeSelfId }, 10000, false);
                if (chargeName && chargeName.length > 0) {
                    result = chargeName.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, CHARGE_NAME_EXHIBITION_DEFAULT: x.CHARGE_NAME_EXHIBITION_DEFAULT } });
                }
            }
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.$scope.chargeNameList = result;
            this.unblock();
        }
    }

    private async getSubGroupListByName(name: string): Promise<SelectorModel[]> {
        let result = [];
        try {
            this.formService.block();
            const subGroups = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/chargeNameSubGroup/listByFullName/custom`, { name }, 10000, false);
            if (subGroups) result = subGroups.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: null } });
        } catch (ex) {
            this.formService.handleError(ex);
        }
        finally {
            this.formService.unblock();
            return result;
        }
    }

    private handleExhibitionName() {
        try {
            if (this.$scope.model.CHARGE_NAME_EXHIBITION && this.$scope.model.CHARGE_NAME_EXHIBITION.length > 0 && this.$scope.model.CHARGE_NAME_EXHIBITION[0]) {
                this.$scope.model.CHARGE_NAME_EXHIBITION[0].NAME = this.$scope.model.NAME;
                this.$scope.model.CHARGE_NAME_EXHIBITION[0].NAME_INTL = this.$scope.model.NAME;
                this.$scope.model.CHARGE_NAME_EXHIBITION[0].CODE = this.$scope.model.CODE;
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "GENERAL.MENU.INTEGRATION" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number, idSubject: string): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/chargeName/sendSync`, { "idChargeName": id, "idSubject": idSubject }, 120000, false);
                if (syncRequest) success = true;
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_SENDING_REQUEST');
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const chargeNameData = await this.getChargeNameById(id);
                    if (row && chargeNameData && chargeNameData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = chargeNameData.DOCUMENT_ERROR;
                        documentError = chargeNameData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async getChargeNameById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/chargeName/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async integrateSankhya(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            if (this.$scope.model.ERP_INTEGRATION_PARAM && (!this.$scope.model.ERP_INTEGRATION_PARAM.ERP_PURCHASE_PRODUCT_STATUS || !this.$scope.model.ERP_INTEGRATION_PARAM.ERP_SALES_PRODUCT_STATUS)) {
                const result = await this.dataProductService.post(`/sync/sankhya/chargeName/status`, { "id": [id] }, 120000)
                const msg = this.formService.getTranslate('REGISTRATION.INTEGRATING_ERP');
                if (result.data) this.formService.notifySuccess(msg);
            }

            if (!this.$scope.model.ERP_INTEGRATION_PARAM || this.$scope.model.DOCUMENT_ERROR) {
                const result = await this.dataProductService.post(`/sync/sankhya/chargeName`, { "id": [id] }, 120000)
                const msg = this.formService.getTranslate('REGISTRATION.INTEGRATING_ERP');
                if (result.data) this.formService.notifySuccess(msg);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async integrateSankhyaStatus(id: number) {
        try {
            if (!id) throw new Error("id is null.");
            if (!this.$scope.model.ERP_INTEGRATION_PARAM) throw new Error("You need to Integrate first!");

            this.formService.block();
            if (this.$scope.model.ERP_INTEGRATION_PARAM && ((!this.$scope.model.ERP_INTEGRATION_PARAM.ERP_PURCHASE_PRODUCT_STATUS || (this.$scope.model.ERP_INTEGRATION_PARAM.ERP_PURCHASE_PRODUCT_STATUS && this.$scope.model.ERP_INTEGRATION_PARAM.ERP_PURCHASE_PRODUCT_STATUS.ID == "N"))) || (!this.$scope.model.ERP_INTEGRATION_PARAM.ERP_SALES_PRODUCT_STATUS || (this.$scope.model.ERP_INTEGRATION_PARAM.ERP_SALES_PRODUCT_STATUS && this.$scope.model.ERP_INTEGRATION_PARAM.ERP_SALES_PRODUCT_STATUS.ID == "N"))) {
                const result = await this.dataProductService.post(`/sync/sankhya/chargeName/status`, { "id": [id] }, 120000)
                const msg = this.formService.getTranslate('REGISTRATION.INTEGRATING_ERP');
                if (result.data) this.formService.notifySuccess(msg);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private buildErrorSankhyaTooltip(errors: IDocumentError[]): string {
        let tooltip = "<div align=\"left\">";
        if (errors && errors.length) {
            const errorsSankhya = errors.filter(e => e.SUBJECT.ID == "1" || e.SUBJECT.ID == "2" || e.SUBJECT.ID == "3");
            for (const reason of errorsSankhya) {
                tooltip += `DATA: ${reason.DATE} <br>`;
                tooltip += `ASSUNTO: ${reason.SUBJECT.NAME} <br>`;
                tooltip += `RAZÃO: ${reason.REASON} <br><br>`;
            }
        }
        tooltip += "</div>";
        return tooltip;
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: "TYPE",
                LABEL: "FINANCIAL.CHARGE_TYPE"
            },
            {
                PROPERTY: "NAME",
                LABEL: "GENERAL.NAME"
            },
            {
                PROPERTY: "DEFAULT",
                LABEL: "REGISTRATION.STANDARD"
            },
            {
                PROPERTY: 'NAME_INTL',
                LABEL: 'GENERAL.NAME_INTL'
            },
            {
                PROPERTY: "CODE",
                LABEL: "GENERAL.CODE"
            },
            {
                PROPERTY: "SERVICE",
                LABEL: "FINANCIAL.PRODUCT_SERVICE_TYPE"
            },
            {
                PROPERTY: "PRODUCT",
                LABEL: "BASIC_DATA.PRODUCT"
            },
            {
                PROPERTY: "BILLING",
                LABEL: "FINANCIAL.BILLING"
            },
            {
                PROPERTY: "PAYMENT",
                LABEL: "FINANCIAL.DEBIT"
            },
            {
                PROPERTY: "RECEIVING",
                LABEL: "FINANCIAL.CREDIT"
            },
            {
                PROPERTY: "PARAMS",
                LABEL: "FINANCIAL.CHARGE_BASIS"
            },
            {
                PROPERTY: "TYPE_CARGO",
                LABEL: "BASIC_DATA.CARGO_TYPE"
            },
            {
                PROPERTY: "PAYMENT",
                LABEL: "FINANCIAL.DEBIT"
            },
            {
                PROPERTY: "RECEIVING",
                LABEL: "FINANCIAL.CREDIT"
            },
            {
                PROPERTY: "DISPLAY_PAYMENT",
                LABEL: "FINANCIAL.PAYMENTO_DISPLAY"
            },
            {
                PROPERTY: "DISPLAY_RECEIVING",
                LABEL: "FINANCIAL.RECEIPT_DISPLAY"
            },
            {
                PROPERTY: "FISCAL_STATEMENT",
                LABEL: "REGISTRATION.RECEIPT"
            },
            {
                PROPERTY: "AGENT_COMISSION",
                LABEL: "REGISTRATION.AGENT_PROFIT_SHARE"
            },
            {
                PROPERTY: "OBSERVATION",
                LABEL: "GENERAL.REMARKS"
            },
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE"
            },
            {
                PROPERTY: "INTEGRATION_ID",
                LABEL: "GENERAL.INTEGRATION_ID"
            },
            {
                PROPERTY: "CHARGE_NAME_SUB_GROUP",
                LABEL: "FINANCIAL.CHARGE_SUBGROUP"
            },
            {
                PROPERTY: "CHARGE_NAME_EXHIBITION",
                LABEL: "FINANCIAL.CHARGE_DISPLAY_NAME"
            },
            {
                PROPERTY: "COMPOSITION_PAYMENT",
                LABEL: "FINANCIAL.PAYMENT_COMPOSITION"
            },
            {
                PROPERTY: "COMPOSITION_RECEIVING",
                LABEL: "FINANCIAL.RECEIPT_COMPOSITION"
            },
            {
                PROPERTY: "MARKUP",
                LABEL: "FINANCIAL.SELLING_MARKUP"
            },
            {
                PROPERTY: "CHARGE_APPLICATION",
                LABEL: "FINANCIAL.CHARGE_BASIS"
            },
            {
                PROPERTY: "CURRENCY",
                LABEL: "GENERAL.CURRENCY"
            },
            {
                PROPERTY: "VALUE",
                LABEL: "FINANCIAL.VALUE"
            },
            {
                PROPERTY: 'ID',
                LABEL: 'REGISTRATION.IDENTIFICATION'
            },
            {
                PROPERTY: 'MASTER',
                LABEL: 'FINANCIAL.MASTER'
            },
            {
                PROPERTY: 'HOUSE',
                LABEL: 'FINANCIAL.HOUSE'
            },
            {
                PROPERTY: 'CHARGE_CURRENCY_PAYMENT_LINK',
                LABEL: 'FINANCIAL.CHARGE_DEBIT_LINK_CURRENCY'
            },
            {
                PROPERTY: 'CHARGE_CURRENCY_RECEIVING_LINK',
                LABEL: 'FINANCIAL.CHARGE_CREDIT_LINK_CURRENCY'
            },
            {
                PROPERTY: 'PARAMS.PAYMENT.NAME',
                LABEL: 'FINANCIAL.PAYMENT_BASIS'
            },
            {
                PROPERTY: 'PARAMS.RECEVING.NAME',
                LABEL: 'FINANCIAL.RECEIPT_BASIS'
            },
            {
                PROPERTY: "PAYMENT_BILLING",
                LABEL: "FINANCIAL.DEBIT"
            },
            {
                PROPERTY: "RECEIVING_BILLING",
                LABEL: "FINANCIAL.CREDIT"
            },
            {
                PROPERTY: "CREATED_AT",
                LABEL: "GENERAL.CREATED_AT"
            },
            {
                PROPERTY: "CREATED_BY",
                LABEL: "GENERAL.CREATED_BY"
            },
            {
                PROPERTY: "UPDATED_AT",
                LABEL: "GENERAL.UPDATED_AT"
            },
            {
                PROPERTY: "UPDATED_BY",
                LABEL: "GENERAL.UPDATED_BY"
            },
            {
                PROPERTY: 'CT_WITHOUT_DOC',
                LABEL: 'REGISTRATION.CONTRIBUTION_WITHOUT_DOCS'
            },
            {
                PROPERTY: "FREE_TYPING_AMOUNT_CHARGE",
                LABEL: "REGISTRATION.FREE_FIELD_CHANGE_QUANTITY"
            },
            {
                PROPERTY: "APPLICATION_COMPLEMENT",
                LABEL: "PRODUCT.BASIS_COMPLEMENT"
            },
            {
                PROPERTY: 'PERCENT',
                LABEL: 'REGISTRATION.PERCENTAGE'
            },
            {
                PROPERTY: 'CHARGE_NAME_EXHIBITION_DEFAULT',
                LABEL: 'BASIC_DATA.CHARGE_DISPLAY'
            }
        ];
        return props;
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/chargeName/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    clearConvertValueCurrency(action) {
        if (!action) throw Error('action is null');

        if (action == 'no') {
            this.$scope.model.CONVERT_VALUE_CURRENCY = null
        } 
    }


}

import angular = require("angular");
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IRoutingPointAllData } from "@models/interface/product/RoutingPoint";
import { ICityModel } from "@models/interface/product/CityModel";
import { IMonacoColumnDef } from '@services/GridService2';
import { IRestService } from "@services/RestService";
import { SelectorModel } from "../../common/model/SelectorModel";
import { ISessionService } from "@services/SessionService";
import { ILinkParameter } from "../../common/model/ModelParameter";
import { BrowserTitle } from "../../common/BrowserTitle";
import { SSEService } from '@appServices/SSEService';
import { EOperation } from "@enums/GenericData";
import { IModalService } from "@services/ModalService";
import { IDocumentError } from '@models/interface/common/IDocumentError';
import { HelperService } from "@services/HelperService";

interface ITradeLaneSelector extends SelectorModel {
    TRADE_GROUP: SelectorModel;
}
interface IRoutingPointScope extends IGridFormServiceScope {
    model: IRoutingPointAllData;
    scopeBeforeSave: IRoutingPointAllData;
    cityList: Array<ICityModel>;
    typeList: Array<SelectorModel>;
    tradeLaneList: Array<SelectorModel>;
    gridOptions: uiGrid.IGridOptions;
    log: IViewLog;
    customLogProperties: ICustomLogProperties[];
    user: any;
    sessionService: ISessionService;
    editRoutingPoint: (routingPoint: IRoutingPointAllData) => Promise<void>;
    viewRoutingPoint: (routingPoint: IRoutingPointAllData) => Promise<void>;
    viewLogRoutingPoint: (routingPoint: IRoutingPointAllData) => Promise<void>;
    copyRoutingPoint: (routingPoint: IRoutingPointAllData) => Promise<void>;
    validateCityInitials: () => void;
    getCityData: (query: string) => Promise<ICityModel[]>;
    updateTradeLane(data: ICityModel): Promise<void>;
    getTradeLaneData: (query: string) => Promise<void>;
    updateSelectedTradeLane: (data: ITradeLaneSelector) => void;
    goToCity: (id: number) => void;
    goToTradeLane: (tradeLane: SelectorModel) => void;
    fetchData: (id: number, action: string) => Promise<void>;
    updateDisplayName: () => void;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
    updateReferenceIds(data: ICityModel): Promise<void>;
}
export class RoutingPointRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $timeout: ng.ITimeoutService
    private $scope: IRoutingPointScope;
    private RestService: IRestService;
    private $q: ng.IQService;
    private SSEService: SSEService;
    private gridName: string;
    private helperService: HelperService;
    private ModalService: IModalService;
    private modalID: number;

    constructor($injector: ng.Injectable<any>, $scope: IRoutingPointScope) {
        super($injector, $scope);
        this.RestService = $injector.get('RestService');
        this.$timeout = $injector.get('$timeout');
        this.$q = $injector.get("$q");
        this.$scope = $scope;

        this.$scope.cityList = [];
        this.$scope.sessionService = $injector.get('SessionService');

        this.gridName = 'GRID_ROUTING_POINT';
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.helperService = $injector.get('HelperService');

        this.ModalService = $injector.get('ModalService');
        this.modalID = null;
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    async $onInit?(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'routingPoint', "ROUTE.ROUTING_POINT", true);
            await this.initGrid(this.gridName, '/routingPoint/list', true, true, null, true, true);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initScopeFunctions(): void {
        this.$scope.editRoutingPoint = async (routingPoint: IRoutingPointAllData): Promise<void> => {
            let blockedObject = {
                ID: routingPoint.ID,
                NAME: routingPoint.NAME,
                EMAIL: this.$scope.user['email'],
                FORM_NAME: this.gridName
            };
            this.SSEService.closeEvents();
            this.SSEService.setBlockedObject(blockedObject);
            this.SSEService.initEvents();
            this.SSEService.events.onmessage = async (event) => {
                const parsedData = JSON.parse(event.data);
                if (!parsedData.status) {
                    const result = await this.SSEService.generate(parsedData);
                    if (result && !result.status) {
                        this.$rootScope.refreshPage();
                        return;
                    }
                    if (this.$scope.operation !== EOperation.VIEW || routingPoint.ID !== this.$scope.model.ID) this.$scope.fetchData(routingPoint.ID, EOperation.VIEW);
                } else if (this.$scope.operation !== EOperation.VIEW || routingPoint.ID !== this.$scope.model.ID) {
                    this.$scope.fetchData(routingPoint.ID, EOperation.EDIT);
                }
            };
        }

        this.$scope.viewRoutingPoint = async (routingPoint: IRoutingPointAllData): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(routingPoint.ID, EOperation.VIEW);
        }

        this.$scope.viewLogRoutingPoint = async (routingPoint: IRoutingPointAllData): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(routingPoint);
        }

        this.$scope.copyRoutingPoint = async (routingPoint: IRoutingPointAllData): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(routingPoint.ID, EOperation.COPY);
        }

        this.$scope.getCityData = async (query: string): Promise<ICityModel[]> => {
            return await this.getCityData(query);
        }
        this.$scope.getTradeLaneData = async (query: string): Promise<void> => {
            let tradeLaneList = [];
            if (query && query.length >= 2) {
                tradeLaneList = await this.getTradeLaneData(query);
            }
            this.$scope.tradeLaneList = tradeLaneList;
        }

        this.$scope.updateSelectedTradeLane = (data: ITradeLaneSelector): void => {
            if (data) this.$scope.model.TRADE_GROUP = { ID: data.TRADE_GROUP.ID, NAME: data.TRADE_GROUP.NAME }
        }

        this.$scope.updateTradeLane = (data: ICityModel) => this.updateTradeLane(data);

        this.$scope.validateCityInitials = (): void => {
            if (!this.$scope.model) {
                this.$formService.notifyError("Error in model.");
                return;
            }
            if (this.$scope.model.CITY) {
                this.$scope.model.CODE = this.$scope.model.TYPE ? this.$scope.model.TYPE.ID == '4' ? this.$scope.model.CITY.CODE.substr(2.5) : this.$scope.model.CITY.CODE : this.$scope.model.CITY.CODE;
            }
        }
        this.$scope.goToCity = (id: number) => {
            this.$scope.sessionService.openTab("app.registration.city", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.$scope.goToTradeLane = (tradeLane: SelectorModel) => {
            this.$scope.sessionService.openTab("app.registration.tradelane", <ILinkParameter>{ ID: tradeLane ? tradeLane.ID.toString() : null });
        }
        this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
            this.fetchData(id, action);
        }

        this.$scope.updateDisplayName = (): void => {
            const { CODE, NAME_INTL, CITY } = this.$scope.model;

            if (CODE && NAME_INTL && (CITY['PROVINCE'].CODE || CITY['PROVINCE'].INITIALS))
                this.$scope.model.DISPLAY_NAME = `${CODE} - ${NAME_INTL}/${CITY['PROVINCE'].CODE || CITY['PROVINCE'].INITIALS}`
        }

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }

        this.$scope.updateReferenceIds = (data: ICityModel) => this.updateReferenceIds(data);
    };

    initModel(): void {
        try {
            this.$scope.model = {
                _id: null,
                ID: null,
                TYPE: null,
                ID_CITY: null,
                ID_PROVINCE: null,
                ID_COUNTRY: null,
                CITY: null,
                CODE: null,
                NAME: null,
                NAME_INTL: null,
                TRADE_LANE: null,
                TRADE_GROUP: null,
                ACTIVE: true,
                INTEGRATION_ID: null,
                CREATED_AT: null,
                CREATED_BY: null,
                UPDATED_AT: null,
                UPDATED_BY: null,
                SEARCH_FIELDS: null,
                DISPLAY_NAME: null,
                DOCUMENT_ERROR: null
            }
        } catch (ex) {
            this.handleLoadError(ex);
        }
    };

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        try {
            const gridColumns = new GridColumnBuilder([]);

            const view = `<a ng-click="grid.appScope.viewRoutingPoint(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
            const edit = `<a ng-click="grid.appScope.editRoutingPoint(row.entity)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
            const copy = `<a ng-click="grid.appScope.copyRoutingPoint(row.entity)" class="text-orange copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
            const viewLog = `<a ng-click="grid.appScope.viewLogRoutingPoint(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
            const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

            const colActions: IMonacoColumnDef = {
                name: "acoes",
                displayName: "GENERAL.ACTIONS",
                width: 120,
                cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${copy} ${viewLog} ${modalIntegration}</div>`,
                enableFiltering: false,
                enableSorting: false,
                enableHiding: false,
                enableColumnMoving: false,
                enableColumnResizing: false,
                pinnedLeft: true,
                enablePinning: false
            };

            gridColumns.addColumn(colActions);
            const newColumnDefs: IMonacoColumnDef[] = this.buildColumns(columns);
            for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) };

            return <uiGrid.IColumnDef[]>gridColumns.$columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            let gridColumns: IMonacoColumnDef[] = [];

            const colType: IMonacoColumnDef = { name: "TYPE", displayName: "ROUTE.LOCAL_TYPE", width: 100, field: "TYPE.NAME" }
            const colCity: IMonacoColumnDef = { name: "CITY.NAME", displayName: "ROUTE.CITY", width: 200, field: "CITY.NAME" };
            const colCountry: IMonacoColumnDef = { name: "CITY.PROVINCE.COUNTRY.NAME", displayName: "ROUTE.COUNTRY", width: 150, field: "CITY.PROVINCE.COUNTRY.NAME" };
            const colCode: IMonacoColumnDef = { name: "CODE", displayName: "ROUTE.LOCAL_CODE", width: 125 };
            const colName: IMonacoColumnDef = { name: "NAME", displayName: "GENERAL.NAME", width: 250 };
            const colExtName: IMonacoColumnDef = { name: "NAME_INTL", displayName: "GENERAL.NAME_INTL", width: 250 };
            const colTradeLane: IMonacoColumnDef = { name: "TRADE_LANE", displayName: "ROUTE.TRADE_LANE", width: 200, field: 'TRADE_LANE.CODE' };
            const colTradeGroup: IMonacoColumnDef = { name: "TRADE_GROUP", displayName: "ROUTE.TRADE_GROUP", width: 140, field: 'TRADE_GROUP.NAME' };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 70, cellFilter: "YesOrNo" };
            const colIntegrationID: IMonacoColumnDef = { name: "INTEGRATION_ID", displayName: "GENERAL.INTEGRATION_ID", width: 150 };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "GENERAL.ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            columns.forEach(column => {
                switch (column) {
                    case "TYPE":
                        gridColumns.push(colType);
                        break;
                    case "CITY":
                        gridColumns.push(colCity);
                        gridColumns.push(colCountry);
                        break;
                    case "CODE":
                        gridColumns.push(colCode);
                        break;
                    case "NAME":
                        gridColumns.push(colName);
                        break;
                    case "NAME_INTL":
                        gridColumns.push(colExtName);
                        break;
                    case "TRADE_LANE":
                        gridColumns.push(colTradeLane);
                        break;
                    case "TRADE_GROUP":
                        gridColumns.push(colTradeGroup);
                        break;
                    case "ACTIVE":
                        gridColumns.push(colActive);
                        break;
                    case "INTEGRATION_ID":
                        gridColumns.push(colIntegrationID);
                        break;
                    case "ID":
                        gridColumns.push(colId);
                        break;
                    case 'CREATED_AT':
                        gridColumns.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        gridColumns.push(colUpdatedAt);
                        break;
                }
            });
            return gridColumns;
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initDependencies(): Promise<any> {
        try {
            const self: RoutingPointRegisterController = this;
            return new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getTypeData()
                ]).then((result: any) => {
                    self.$scope.typeList = result[0].map((x) => ({ ID: x.ID, NAME: x.NAME, CODE: null }));

                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "GENERAL.MENU.INTEGRATION" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number, idSubject: string): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/routingPoint/sendSync`, { "idRoutingPoint": id, "idSubject": idSubject }, 120000, false);
                if (syncRequest) success = true;
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_SENDING_REQUEST');
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const legalPersonData = await this.getRoutingPointById(id);
                    if (row && legalPersonData && legalPersonData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = legalPersonData.DOCUMENT_ERROR;
                        documentError = legalPersonData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async getRoutingPointById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/routingPoint/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate("GENERAL.FORM_OPERATION.NEW");
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")} (${this.$scope.model.NAME})`;
            BrowserTitle.$id = this.$scope.model.NAME;
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")} (${this.$scope.model.NAME})`;
            BrowserTitle.$id = this.$scope.model.NAME;
        } catch (ex) {
            this.handleError(ex);
        }
    }


    async copy(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            await this.clearFields(this.$scope.model, ['CONCATENATED']);
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        if (this.$scope.operation == 'register' || this.$scope.operation == 'edit') {
            try {
                this.SSEService.closeEvents();
                return true;
            } catch (ex) {
                this.handleError(ex);
                return false;
            }
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
    }

    async request(): Promise<IMonacoRequestLog> {
        try {
            const route = this.$scope.operation == 'register' ? 'insert' : 'update';
            return {
                route: `/routingPoint/${route}`,
                data: angular.copy(this.$scope.model),
                oldData: angular.copy(this.$scope.scopeBeforeSave),
                timeout: 15000
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getCityData(query?: string): Promise<ICityModel[]> {
        try {
            let city = [];
            if (this.$scope.cityList && this.$scope.cityList.length > 0 && query) {
                city = this.$scope.cityList.filter(g => g.NAME.toLowerCase().search(query.toLowerCase()) > -1);
            }
            let result;
            if (city && city.length === 0 && query && query.length >= 2) {
                this.block();
                result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/city/list/custom`, { name: query }, 30000, false);
                if (result) this.$scope.cityList = result;
            }
            return this.$scope.cityList;

        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
        }
    }

    private async getTypeData(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get('/generic/value/type_local_event', null, 10000);
        return generic && generic.data ? generic.data : [];
    }

    private async getTradeLaneData(query?: string): Promise<ITradeLaneSelector[]> {
        let result = [];
        this.formService.block();
        try {
            const tradeLanes = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/tradelane/getByCode`, { CODE: query }, 30000, false);
            if (tradeLanes) result = tradeLanes.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS, TRADE_GROUP: x.TRADE_GROUP } });
            if (result.length == 1) {
                this.$scope.model.TRADE_LANE = result[0];
                this.$scope.model.TRADE_GROUP = result[0].TRADE_GROUP
                this.$timeout(() => { this.$scope.selectorValidity('tradeLane') }, 100);
                this.$timeout(() => { this.$scope.selectorValidity('tradeGroup') }, 100);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async updateTradeLane(data: ICityModel): Promise<void> {
        if (data) {
            const tradeLaneList: ITradeLaneSelector[] = await this.getTradeLaneData(data.TRADE_LANE.CODE);
            this.$scope.tradeLaneList = tradeLaneList;
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        try {
            const props: ICustomLogProperties[] = [
                {
                    PROPERTY: "TYPE",
                    LABEL: "ROUTE.LOCAL_TYPE"
                },
                {
                    PROPERTY: "CITY",
                    READ: "NAME",
                    LABEL: "ROUTE.CITY"
                },
                {
                    PROPERTY: "CODE",
                    LABEL: "ROUTE.LOCAL_CODE"
                },
                {
                    PROPERTY: "NAME",
                    LABEL: "GENERAL.NAME"
                },
                {
                    PROPERTY: "NAME_INTL",
                    LABEL: "GENERAL.NAME_INTL"
                },
                {
                    PROPERTY: "TRADE_GROUP",
                    READ: "NAME",
                    LABEL: "ROUTE.TRADE_GROUP"
                },
                {
                    PROPERTY: "TRADE_LANE",
                    LABEL: "ROUTE.TRADE_LANE"
                },
                {
                    PROPERTY: "INTEGRATION_ID",
                    LABEL: "GENERAL.INTEGRATION_ID"
                },
                {
                    PROPERTY: "ACTIVE",
                    LABEL: "GENERAL.ACTIVE"
                },
                {
                    PROPERTY: 'CREATED_AT',
                    LABEL: 'GENERAL.CREATED_AT'
                },
                {
                    PROPERTY: 'CREATED_BY',
                    LABEL: 'GENERAL.CREATED_BY'
                },
                {
                    PROPERTY: 'UPDATED_AT',
                    LABEL: 'GENERAL.UPDATED_AT'
                },
                {
                    PROPERTY: 'UPDATED_BY',
                    LABEL: 'GENERAL.UPDATED_BY'

                },
                {
                    PROPERTY: 'DISPLAY_NAME',
                    LABEL: 'REGISTRATION.ROUTE_POINT_DISPLAY_NAME'
                },
                {
                    PROPERTY: 'INITIALS',
                    LABEL: 'GENERAL.CODE'
                },
                {
                    PROPERTY: 'COUNTRY',
                    LABEL: 'ROUTE.COUNTRY'
                },
                {
                    PROPERTY: 'PROVINCE',
                    LABEL: 'GENERAL.MENU.PROVINCE'
                }
            ];
            return props;

        } catch (ex) {
            this.handleError(ex);
        }
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/routingPoint/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model: IRoutingPointAllData = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async updateReferenceIds(data: ICityModel): Promise<void> {
        this.$scope.model.ID_CITY = data ? data.ID : null;
        this.$scope.model.ID_PROVINCE = data ? parseInt(data.PROVINCE.ID) : null;
        this.$scope.model.ID_COUNTRY = data ? parseInt(data.PROVINCE.COUNTRY.ID) : null;
    }

} 

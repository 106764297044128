import * as angular from "angular";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { ISessionService } from "@services/SessionService";
import { EOperation, EProductId, ERoutingPointType } from "@enums/GenericData";
import { IViewLog, ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IMasterBillingSettings } from "@models/interface/product/MasterBillingSettings";
import { SelectorModel } from "../../common/model/SelectorModel";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import moment = require("moment");
import { HelperService } from "@services/HelperService";
import { IDocumentError } from '@models/interface/common/IDocumentError';
import { IModalService } from "@services/ModalService";

interface IMasterBillingSettingsScope extends IGridFormServiceScope {
    model: IMasterBillingSettings;
    log: IViewLog;
    productList: SelectorModel[];
    cargoTypeList: SelectorModel[];
    typeChargeList: SelectorModel[];
    transactionNatureList: SelectorModel[];
    accountList: SelectorModel[];
    placeOfLodingList: SelectorModel[];
    placeOfDischargeList: SelectorModel[];
    processTypeList: SelectorModel[];
    incotermList: SelectorModel[];
    holderList: SelectorModel[];
    providerList: SelectorModel[];
    typePaymentList: SelectorModel[];
    countryList: SelectorModel[];
    agentList: SelectorModel[];
    networkList: SelectorModel[];
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: IMasterBillingSettings;
    sessionService: ISessionService;
    gridOptions: uiGrid.IGridOptions;
    editMasterBillingSettings: (masterBillingSettings: IMasterBillingSettings) => Promise<void>;
    viewMasterBillingSettings: (masterBillingSettings: IMasterBillingSettings) => Promise<void>;
    viewLogMasterBillingSettings: (masterBillingSettings: IMasterBillingSettings) => Promise<void>;
    copyMasterBillingSettings: (masterBillingSettings: IMasterBillingSettings) => Promise<void>;
    getAccountListByName: (search: string) => Promise<void>;
    getPlaceOfLoadingByName: (search: string) => Promise<void>;
    getPlaceOfDischargeByName: (search: string) => Promise<void>;
    fetchData: (id: number, action: string) => Promise<void>;
    getIncotermListByName: (search: string) => Promise<void>;
    getTypeCargoListByName: () => Promise<void>;
    getTypeChargeListByName: () => Promise<void>;
    getProviderListByName: (search: string) => Promise<void>;
    getCountryListByName: (search: string) => Promise<void>;
    getAgentListByName: (search: string) => Promise<void>;
    getNetworkListByName: (search: string) => Promise<void>;
    formatedScoreDate: () => string;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
    updateIncoterm: () => void;
}

export class MasterBillingSettingsRegisterController extends GridFormService implements IGridFormController {

    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IMasterBillingSettingsScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private gridName: string;
    private helperService: HelperService;
    private ModalService: IModalService;
    private modalID: number;
    private $timeout: ng.ITimeoutService;

    constructor($injector: ng.Injectable<any>, $scope: IMasterBillingSettingsScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.gridName = 'GRID_MASTER_BILLING_SETTINGS';
        this.helperService = $injector.get('HelperService');
        this.$timeout = $injector.get('$timeout');
        this.modalID = null;
        this.ModalService = $injector.get('ModalService');
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.initForm(this, 'form', 'masterBillingSettings', 'GENERAL.MASTER_BILLING_SETTINGS', true);
            await this.initGrid(this.gridName, '/masterBillingSettings/list', true, true, null, true, true);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        try {
            this.$scope.editMasterBillingSettings = async (masterBillingSettings: IMasterBillingSettings): Promise<void> => {
                this.$scope.fetchData(masterBillingSettings.ID, EOperation.EDIT);
            }

            this.$scope.viewMasterBillingSettings = async (masterBillingSettings: IMasterBillingSettings): Promise<void> => {
                this.$scope.fetchData(masterBillingSettings.ID, EOperation.VIEW);
            }

            this.$scope.viewLogMasterBillingSettings = async (masterBillingSettings: IMasterBillingSettings): Promise<void> => {
                this.$scope.viewLog(masterBillingSettings);
            }

            this.$scope.copyMasterBillingSettings = async (masterBillingSettings: IMasterBillingSettings): Promise<void> => {
                this.$scope.fetchData(masterBillingSettings.ID, EOperation.COPY);
            }

            this.$scope.getAccountListByName = async (search: string): Promise<void> => {
                await this.getAccountListByName(search);
            }

            this.$scope.getPlaceOfLoadingByName = async (search: string): Promise<void> => {
                await this.getPlaceOfLoadingByName(search);
            }

            this.$scope.getPlaceOfDischargeByName = async (search: string): Promise<void> => {
                await this.getPlaceOfDischargeByName(search);
            }

            this.$scope.getIncotermListByName = async (search: string) => {
                let incoterm: SelectorModel[] = [];
                if (search && search.length >= 3) {
                    incoterm = await this.getIncotermListByName(search);
                }
                this.$scope.incotermList = incoterm;
            }

            this.$scope.getTypeCargoListByName = async () => {
                let cargoType: SelectorModel[] = [];
                cargoType = await this.getCargoTypeList();
                this.$scope.cargoTypeList = cargoType;
            }

            this.$scope.getTypeChargeListByName = async () => {
                let chargeType: SelectorModel[] = [];
                chargeType = await this.getChargeOriginList();
                this.$scope.typeChargeList = chargeType;
            }

            this.$scope.getProviderListByName = async (search: string) => {
                let providerList: SelectorModel[] = [];
                if (search && search.length >= 3) {
                    providerList = await this.getProviderListByName(search);
                }
                this.$scope.providerList = providerList;
            }

            this.$scope.getCountryListByName = async (search: string): Promise<void> => {
                await this.getCountryListByName(search);
            }

            this.$scope.getAgentListByName = async (search: string): Promise<void> => {
                return await this.getAgentListByName(search);
            }

            this.$scope.getNetworkListByName = async (name: string): Promise<void> => {
                return await this.getNetworkListByName(name);
            }

            this.$scope.formatedScoreDate = (): string => {
                return this.formatedScoreDate();
            }

            this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
                this.fetchData(id, action);
            }

            this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
                this.openModalIntegration(id, documentError);
            }

            this.$scope.updateIncoterm = async () => {
                this.$scope.model.INCOTERM = null;
            }

        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<div class="text-center"><a ng-click="grid.appScope.viewMasterBillingSettings(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editMasterBillingSettings(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogMasterBillingSettings(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.copyMasterBillingSettings(row.entity)" class="text-orange" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            minWidth: 100,
            maxWidth: 100,
            cellTemplate: (view + edit + viewLog + copy + modalIntegration),
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        };
        gridColumns.addColumn(colActions);
        const newColumnDefs = this.buildColumns(columns);

        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PRODUCT, null, "ID")}}</div>' };
            const colTypeCargo: IMonacoColumnDef = { name: "TYPE_CARGO.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TYPE_CARGO, null, "NAME")}}</div>' };
            const colTransaction: IMonacoColumnDef = { name: 'NATURE.NAME', displayName: "PRODUCT.TRANSACTION_TYPE", width: 150 };
            const colTypeCharge: IMonacoColumnDef = { name: 'TYPE.NAME', displayName: "FINANCIAL.CHARGE_TYPE", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TYPE, null, "NAME")}}</div>' };
            const colIncoterm: IMonacoColumnDef = { name: 'INCOTERM.CODE', displayName: "BASIC_DATA.INCOTERM", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.INCOTERM, null, "CODE")}}</div>' };
            const colTypeProcess: IMonacoColumnDef = { name: "PROCESS_TYPE.NAME", displayName: "BASIC_DATA.FILE_TYPE", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PROCESS_TYPE, null, "NAME")}}</div>' };
            const colDirectBl: IMonacoColumnDef = { name: "DIRECT_BL", displayName: "BASIC_DATA.STRAIGHT_BL", width: 150, cellFilter: "YesOrNo" };
            const colNetwork: IMonacoColumnDef = { name: "NETWORK.NAME", displayName: "BASIC_DATA.EXTERNAL_AGENT_NETWORK", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.NETWORK, null, "NAME")}}</div>' };
            const colExternalAgent: IMonacoColumnDef = { name: "EXTERNAL_AGENT.NAME", displayName: "BASIC_DATA.OVERSEAS_AGENT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.EXTERNAL_AGENT, null, "NAME")}}</div>' };
            const colProvider: IMonacoColumnDef = { name: "PROVIDER.CODE", displayName: "BASIC_DATA.PROVIDER", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.PROVIDER, null, "CODE")}}</div>' };
            const colAccount: IMonacoColumnDef = { name: "ACCOUNTS.NAME", displayName: "BASIC_DATA.CLIENT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNTS, null, "NAME")}}</div>' };
            const colOriginCountry: IMonacoColumnDef = { name: "ORIGIN_COUNTRY.NAME", displayName: "BASIC_DATA.ORIGIN_COUNTRY", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ORIGIN_COUNTRY, null, "NAME")}}</div>' };
            const colDestinationCountry: IMonacoColumnDef = { name: "DESTINATION_COUNTRY.NAME", displayName: "BASIC_DATA.DESTINATION_COUNTRY", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.DESTINATION_COUNTRY, null, "NAME")}}</div>' };
            const colHouseModality: IMonacoColumnDef = { name: "HOUSE_MODALITY.NAME", displayName: "BASIC_DATA.HOUSE_PAYMENT_MODE", width: 150 };
            const colMasterModality: IMonacoColumnDef = { name: "MASTER_MODALITY.NAME", displayName: "FINANCIAL.CHARGE_PAYMENT_METHOD", width: 150 };
            const colHolder: IMonacoColumnDef = { name: "HOLDER.NAME", displayName: "GENERAL.HOLDER_TYPE", width: 150 };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 150, cellFilter: "YesOrNo" };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'TYPE_CARGO':
                        columnDefs.push(colTypeCargo);
                        break;
                    case 'NATURE':
                        columnDefs.push(colTransaction);
                        break;
                    case 'TYPE':
                        columnDefs.push(colTypeCharge);
                        break;
                    case 'INCOTERM':
                        columnDefs.push(colIncoterm);
                        break;
                    case 'PROCESS_TYPE':
                        columnDefs.push(colTypeProcess);
                        break;
                    case 'DIRECT_BL':
                        columnDefs.push(colDirectBl);
                        break;
                    case 'NETWORK':
                        columnDefs.push(colNetwork);
                        break;
                    case 'ACCOUNTS':
                        columnDefs.push(colAccount);
                        break;
                    case 'EXTERNAL_AGENT':
                        columnDefs.push(colExternalAgent);
                        break;
                    case 'PROVIDER':
                        columnDefs.push(colProvider);
                        break;
                    case 'ORIGIN_COUNTRY':
                        columnDefs.push(colOriginCountry);
                        break;
                    case 'DESTINATION_COUNTRY':
                        columnDefs.push(colDestinationCountry);
                        break;
                    case 'HOUSE_MODALITY':
                        columnDefs.push(colHouseModality);
                        break;
                    case 'MASTER_MODALITY':
                        columnDefs.push(colMasterModality);
                        break;
                    case 'HOLDER':
                        columnDefs.push(colHolder);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        try {
            this.$scope.model = {
                _id: null,
                ID: null,
                PRODUCT: null,
                TYPE_CARGO: null,
                NATURE: null,
                TYPE: null,
                INCOTERM: null,
                PROCESS_TYPE: null,
                DIRECT_BL: null,
                NETWORK: null,
                EXTERNAL_AGENT: null,
                PROVIDER: null,
                ACCOUNTS: null,
                ORIGIN_COUNTRY: null,
                DESTINATION_COUNTRY: null,
                HOUSE_MODALITY: null,
                MASTER_MODALITY: null,
                HOLDER: null,
                SCORE: null,
                SCORE_DATE: null,
                SCORE_ERROR: null,
                SCORE_RULE: null,
                ACTIVE: true,
                CREATED_AT: null,
                CREATED_BY: null,
                UPDATED_AT: null,
                UPDATED_BY: null,
                ALLOG_FREIGHT_CONTRACT: null,
                PLACE_OF_LOADING: null,
                PLACE_OF_DISCHARGE: null
            };
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getCargoTypeList(): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            const scopeCargoType = this.$scope.model.TYPE_CARGO;
            const { data: generic } = await this.helperService.get(`/generic/value/type_cargo`, null);
            if (generic && generic.data.length > 0) result = generic.data.map(cargo => { return { ID: cargo.ID.toString(), NAME: cargo.NAME, ORDER: cargo.ORDER } });

            if (scopeCargoType) result = result.filter(item => scopeCargoType.some(scopeCargo => scopeCargo.ID != item.ID))

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.cargoTypeList = result
            this.formService.unblock();
            return result;
        }
    }

    private async getHolderList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/transaction`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getPaymentNatureList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/payment_nature`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getProcessTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/process_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getChargeOriginList(): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            const scopeChargeType = this.$scope.model.TYPE;
            const { data: generic } = await this.helperService.get(`/generic/value/charge_origin`, null);
            if (generic && generic.data.length > 0) result = generic.data.map(charge => { return { ID: charge.ID.toString(), NAME: charge.NAME, ORDER: charge.ORDER } });

            if (scopeChargeType) result = result.filter(item => scopeChargeType.some(scopeCharge => scopeCharge.ID != item.ID))

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.typeChargeList = result
            this.formService.unblock();
            return result;
        }
    }

    private async getTypePaymentList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_payment`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getAccountListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            const scopeAccounts = this.$scope.model.ACCOUNTS;
            if (search && search.length >= 3) {
                this.formService.block();
                const accounts = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/account/list/custom/`, { search: search }, 30000, false);
                if (accounts && accounts.length > 0) {
                    result = accounts.map(account => { return { ID: account.ID, NAME: account.NAME, CODE: account.LEGAL_PERSON.SHORT_NAME } });
                }
                if (scopeAccounts) {
                    result = result.filter(item => scopeAccounts.some(scopeAccount => scopeAccount.ID != item.ID))
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.accountList = result
            this.formService.unblock();
        }
    }

    private async getPlaceOfLoadingByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 3) {
                this.formService.block();
                const product = this.$scope.model.PRODUCT;
                const types = await this.getProductSpecificTypes(product.ID);

                const placesOfLoading = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/routingPoint/list/custom/`, { name: search, types: types }, 30000, false);
                if (placesOfLoading && placesOfLoading.length > 0) {
                    result = placesOfLoading.map(pol => {
                        return {
                            ID: pol.ID,
                            DISPLAY_NAME: pol.DISPLAY_NAME,
                            CODE: pol.CODE
                        }
                    });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.placeOfLodingList = result;
            this.formService.unblock();
        }
    }

    private async getPlaceOfDischargeByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 3) {
                this.formService.block();
                const product = this.$scope.model.PRODUCT;
                const types = await this.getProductSpecificTypes(product.ID);

                const placesOfDischarge = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/routingPoint/list/custom/`, { name: search, types: types }, 30000, false);
                if (placesOfDischarge && placesOfDischarge.length > 0) {
                    result = placesOfDischarge.map(pod => {
                        return {
                            ID: pod.ID,
                            DISPLAY_NAME: pod.DISPLAY_NAME,
                            CODE: pod.CODE
                        }
                    });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.placeOfDischargeList = result;
            this.formService.unblock();
        }
    }

    private async getProductSpecificTypes(product) {
        switch (product) {
            case EProductId.MARITIME_EXPORT:
            case EProductId.MARITIME_IMPORT:
                return [ERoutingPointType.PORT];
            case EProductId.AIR_EXPORT:
            case EProductId.AIR_IMPORT:
                return [ERoutingPointType.AIRPORT];
            default:
                return [];
        }
    }

    private async getMasterBillingSettingsById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/masterBillingSettings/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const masterBillingSettingsData = await this.getMasterBillingSettingsById(id);
                    if (row && masterBillingSettingsData && masterBillingSettingsData.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = masterBillingSettingsData.DOCUMENT_ERROR;
                        documentError = masterBillingSettingsData.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "Integration Product/Operation" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number): Promise<boolean> => {
        let sendSync = false;
        let success = false;
        try {
            if (id) {
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/masterBillingSettings/sendSync`, { "id": id }, 120000, false);
                if (syncRequest) sendSync = true;

                success = sendSync;
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_SENDING_REQUEST');
        } finally {
            return success;
        }
    }


    private async getIncotermListByName(search: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            const scopeIncoterm = this.$scope.model.INCOTERM;
            if (search && search.length >= 3) {
                this.formService.block();
                const products = this.$scope.model.PRODUCT ? [this.$scope.model.PRODUCT.ID] : [];
                const incoterms = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/incoterm/list/custom`, { search, products }, 30000, false);
                if (incoterms && incoterms.length > 0) result = incoterms.map(incoterm => { return { ID: incoterm.ID.toString(), NAME: incoterm.NAME, CODE: incoterm.INITIALS } });

                if (scopeIncoterm) {
                    result = result.filter(item => scopeIncoterm.some(scopeAccount => scopeAccount.ID != item.ID))
                }
            }

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.incotermList = result
            this.formService.unblock();
            return result;
        }
    }

    private async getProviderListByName(search?: string): Promise<SelectorModel[]> {
        let result = [];
        this.formService.block();
        try {
            const providers = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/provider/list/custom`, { search: search }, 30000, false);
            if (providers) result = providers.map(provider => { return { ID: provider.ID, NAME: provider.NAME, CODE: provider.SCAC_IATA, TYPE: provider.TYPE } });
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCountryListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const countryList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/country/list/custom`, { search }, 30000, false);
                result = (countryList && countryList) ? countryList.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.countryList = result;
            this.formService.unblock();
        }
    }

    private async getAgentListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();
                const products = this.$scope.model.PRODUCT ? [this.$scope.model.PRODUCT.ID] : [];
                const agents = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/agent/list/custom`, { search, products }, 30000, false);
                result = agents ? agents.map(x => { return { ID: x.ID, NAME: x.NAME } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.agentList = result;
        }
    }

    private async getNetworkListByName(name: string): Promise<void> {
        let result = [];
        try {
            if (name && name.length >= 2) {
                this.formService.block();

                const networks = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/network/listByLegalPersonSpecType`, { name: name, types: ['12'] }, 30000, false);
                result = networks ? networks.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.networkList = result;
        }
    }

    private formatedScoreDate(): string {
        try {
            if (this.$scope.model.SCORE_DATE) {
                return moment(this.$scope.model.SCORE_DATE).format('DD/MM/YYYY HH:mm:ss');
            }
            return null;
        } catch (ex) {
            this.handleError(ex);
            return null;
        }
    }

    initDependencies(): Promise<any> {
        try {
            const self: MasterBillingSettingsRegisterController = this;

            return new Promise(function (resolve, reject) {
                self.$q.all([
                    self.getProductList(),
                    self.getPaymentNatureList(),
                    self.getProcessTypeList(),
                    self.getChargeOriginList(),
                    self.getHolderList(),
                    self.getTypePaymentList(),
                ]).then((result: any) => {
                    self.$scope.productList = result[0];
                    self.$scope.transactionNatureList = result[1];
                    self.$scope.processTypeList = result[2];
                    self.$scope.typeChargeList = result[3];
                    self.$scope.holderList = result[4];
                    self.$scope.typePaymentList = result[5];

                    if (self.$scope.transactionNatureList && self.$scope.transactionNatureList.length > 0) {
                        self.$scope.model.NATURE = self.$scope.transactionNatureList.find(item => item.ID == '1');
                    }

                    resolve(true);
                }).catch(ex => {
                    reject(ex);
                });
            });
        } catch (ex) {
            this.handleError(ex);
        }

    }

    async register(): Promise<void> {
        try {
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.VIEW")}`;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = `${this.formService.getTranslate("GENERAL.FORM_OPERATION.EDIT")}`;
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation === 'register' ? 'insert' : 'update';
        return {
            route: `/masterBillingSettings/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };

    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: "PRODUCT", LABEL: "BASIC_DATA.PRODUCT" },
            { PROPERTY: "TYPE_CARGO", LABEL: "BASIC_DATA.CARGO_TYPE" },
            { PROPERTY: 'NATURE', LABEL: "PRODUCT.TRANSACTION_TYPE" },
            { PROPERTY: 'TYPE', LABEL: "FINANCIAL.CHARGE_TYPE" },
            { PROPERTY: 'INCOTERM', LABEL: "BASIC_DATA.INCOTERM" },
            { PROPERTY: "PROCESS_TYPE", LABEL: "BASIC_DATA.CARGO_TYPE" },
            { PROPERTY: "DIRECT_BL", LABEL: "BASIC_DATA.STRAIGHT_BL" },
            { PROPERTY: "NETWORK", LABEL: "BASIC_DATA.EXTERNAL_AGENT_NETWORK" },
            { PROPERTY: "EXTERNAL_AGENT", LABEL: "BASIC_DATA.OVERSEAS_AGENT" },
            { PROPERTY: "PROVIDER", LABEL: "BASIC_DATA.PROVIDER" },
            { PROPERTY: "ACCOUNTS", LABEL: "BASIC_DATA.CLIENT" },
            { PROPERTY: "ORIGIN_COUNTRY", LABEL: "BASIC_DATA.ORIGIN_COUNTRY" },
            { PROPERTY: "DESTINATION_COUNTRY", LABEL: "BASIC_DATA.DESTINATION_COUNTRY" },
            { PROPERTY: "HOUSE_MODALITY", LABEL: "BASIC_DATA.HOUSE_PAYMENT_MODE" },
            { PROPERTY: "MASTER_MODALITY", LABEL: "FINANCIAL.CHARGE_PAYMENT_METHOD" },
            { PROPERTY: "HOLDER", LABEL: "GENERAL.HOLDER_TYPE" },
            { PROPERTY: "ID", LABEL: "ID" },
            { PROPERTY: "CREATED_AT", LABEL: "GENERAL.CREATED_AT" },
            { PROPERTY: "UPDATED_AT", LABEL: "GENERAL.UPDATED_AT" },
            { PROPERTY: "SCORE", LABEL: "BASIC_DATA.SCORE" },
            { PROPERTY: "SCORE_DATE", LABEL: "GENERAL.SCORE_CALCULATION_DATA" },
            { PROPERTY: "SCORE_ERROR", LABEL: "GENERAL.SCORE_CALCULATION_ERROR" },
            { PROPERTY: "ACTIVE", LABEL: "GENERAL.ACTIVE" },
            { PROPERTY: "PLACE_OF_LOADING", LABEL: "BASIC_DATA.POL_AOL_LOADING" },
            { PROPERTY: "PLACE_OF_DISCHARGE", LABEL: "BASIC_DATA.POL_AOL_DISCHARGE" }
        ];
        return props;
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/masterBillingSettings/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }
}
import * as angular from "angular";
import { IColumnDef, IGridOptions } from "ui-grid";
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { ISessionService } from "@services/SessionService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { SSEService } from '@appServices/SSEService';
import { ITablePerDiemModel, ITableFreeTimePerDiem, IFreeTimePerDiem } from "@models/interface/product/TablePerDiem";
import { EOperation, EProductId, EPaymentNatureId, EDDTypeTariff } from "@enums/GenericData";
import { IProviderSelector } from "WBA-Model/dist/interface/product/Provider";
import { ICustomLogProperties } from "WBA-Model/dist/interface/common/IViewLog";
import { IProviderModel } from "WBA-Model/dist/interface/product/ProviderModel";
import { IFloatingMenu } from "src/ts/common/interface/IFloatingMenu";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { SelectorModel } from "../../common/model/SelectorModel";
import { StringUtil } from "../../common/util/StringUtil";
import { BrowserTitle } from "../../common/BrowserTitle";
import { ILinkParameter } from "src/ts/common/model/ModelParameter";
import { ELegalPersonSpecializationId } from "@enums/LegalPerson";
import { ILegalPersonModel, ILegalPersonListCustomFilter, ILegalPersonSelector } from "src/ts/registration/model/LegalPersonModel";
import { ISelectorModel } from "WBA-Model/dist/mongo/SelectorModel";
import { IModalService } from '@services/ModalService';
import { HelperService } from "@services/HelperService";
import { fileUploader as FileUploader } from 'angular-file-upload';
import { IUploader, IUploadItem, IFileItem, IFormData, ISuccessItemResponse } from "@models/interface/common/IMonacoUpload";

interface IPerDiemScope extends IGridFormServiceScope {
    form: ng.IFormController;
    gridOptions: IGridOptions;
    model: ITablePerDiemModel;
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: ITablePerDiemModel;
    paymentNatureList: SelectorModel[];
    directionList: SelectorModel[];
    providerList: IProviderSelector[];
    accountList: SelectorModel[];
    exporterList: SelectorModel[];
    importerList: SelectorModel[];
    countryList: SelectorModel[];
    tariffTypeList: SelectorModel[];
    currencyList: SelectorModel[];
    equipmentList: SelectorModel[];
    equipmentProviderList: SelectorModel[];
    currencySelected: SelectorModel;
    user: any;
    menuFloating: IFloatingMenu;
    sessionService: ISessionService;
    uploader: IUploader;
    customEdit: (tariffDetDem: ITablePerDiemModel) => void;
    viewTablePerDiem: (tablePerDiemId: number) => Promise<void>;
    viewLogTablePerDiem: (tablePerDiem: ITablePerDiemModel) => Promise<void>;
    copyTablePerDiem: (tablePerDiem: ITablePerDiemModel) => Promise<void>;
    getAccountListByName: (search: string) => Promise<void>;
    getProviderListByName: (search: string) => Promise<void>;
    getExporterListByName: (search: string) => Promise<void>;
    getImporterListByName: (search: string) => Promise<void>;
    getCountryListByName: (search: string) => Promise<void>;
    getCurrencyListByName: (search: string) => Promise<void>;
    getEquipmentListByName: (search: string) => Promise<void>;
    getEquipmentProviderListByName: (search: string) => Promise<void>
    goToAccount: (accounts: ISelectorModel[]) => void;
    goToProvider: (id: number) => void;
    goToCountry: (id: number) => void;
    goToExporter: (exporters: ILegalPersonSelector[]) => void;
    goToImporter: (importers: ILegalPersonSelector[]) => void;
    formatConcatenatedChars: (value: string) => string;
    generateConcatenated: () => void;
    copyTableFreeTimeDetPerDiem: (parentIndex: number) => void;
    copyTableFreeTimeDemPerDiem: (parentIndex: number) => void;
    removeTableDetFreeTimePerDiem: (parentIndex: number) => void;
    removeTableDemFreeTimePerDiem: (parentIndex: number) => void;
    addTableFTPDDetItem: () => void;
    addTableFTPDDemItem: () => void;
    addDetFreeTimePerDiemItem: (parentIndex: number) => void;
    addImoDetFreeTimePerDiemItem: (parentIndex: number) => void;
    addImoDemFreeTimePerDiemItem: (parentIndex: number) => void;
    addDemFreeTimePerDiemItem: (parentIndex: number) => void;
    removeTableDetFreeTimePerDiemItem: (parentIndex: number, index: number) => void;
    removeTableImoDetFreeTimePerDiemItem: (parentIndex: number, index: number) => void;
    removeTableDemFreeTimePerDiemItem: (parentIndex: number, index: number) => void;
    setDetImoPerDiem: (parentIndex: number, index: number) => void;
    setDemImoPerDiem: (parentIndex: number, index: number) => void;
    checkDisableFields: (field: string) => boolean;
    checkSelectorValidity: () => void;
    askForDetDemChange: () => void;
    enableFreeTimeImo: () => void;
    disableFreeTimeImo: () => void;
    getCurrency: (currency: SelectorModel) => void;
    removeUpload: (model: IUploadItem) => boolean;
}

export class TablePerDiemRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: IPerDiemScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private formName: string;
    private $timeout: ng.ITimeoutService
    private gridName: string;
    private ModalService: IModalService;
    private SSEService: SSEService;
    private helperService: HelperService;
    private fileUploader: FileUploader;

    constructor($injector: ng.Injectable<any>, $scope: IPerDiemScope) {
        super($injector, $scope);
        this.formName = 'perDiem';
        this.gridName = 'GRID_PER_DIEM';
        this.$scope = $scope;
        this.$q = $injector.get('$q');
        this.$scope.sessionService = $injector.get('SessionService');
        this.RestService = $injector.get('RestService');
        this.ModalService = $injector.get('ModalService');
        this.$timeout = $injector.get('$timeout');
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.helperService = $injector.get('HelperService');
        this.fileUploader = $injector.get('FileUploader');
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.getUrlProduct();
            this.$scope.uploader = this.getFileUploaderDefault();
            this.initForm(this, 'form', 'tablePerDiem', 'PRODUCT.TABLE_PER_DIEM', true);
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            await this.initGrid(this.gridName, '/tablePerDiem/list', true, true, null, true, true);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }
    initScopeFunctions(): void {
        this.$scope.customEdit = (tablePerDiem: ITablePerDiemModel): void => {
            this.editTablePerDiem(tablePerDiem);
        }

        this.$scope.viewTablePerDiem = async (tablePerDiemId: number): Promise<void> => {
            this.SSEService.closeEvents();
            this.fetchData(tablePerDiemId, EOperation.VIEW);
        }

        this.$scope.viewLogTablePerDiem = async (tablePerDiem: ITablePerDiemModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(tablePerDiem);
        }

        this.$scope.copyTablePerDiem = async (tablePerDiem: ITablePerDiemModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.fetchData(tablePerDiem.ID, EOperation.COPY);
        }


        this.$scope.getAccountListByName = async (search: string): Promise<void> => {
            await this.getAccountListByName(search);
        }
        this.$scope.getProviderListByName = async (search: string): Promise<void> => {
            await this.getProviderListByName(search);
        }
        this.$scope.getExporterListByName = async (search: string): Promise<void> => {
            await this.getExporterListByName(search);
        }
        this.$scope.getImporterListByName = async (search: string): Promise<void> => {
            await this.getImporterListByName(search);
        }
        this.$scope.getCountryListByName = async (search: string): Promise<void> => {
            await this.getCountryListByName(search);
        }
        this.$scope.getCurrencyListByName = async (search: string): Promise<void> => {
            await this.getCurrencyListByName(search);
        }
        this.$scope.getEquipmentListByName = async (search: string): Promise<void> => {
            await this.getEquipmentListByName(search);
        }
        this.$scope.getEquipmentProviderListByName = async (search: string): Promise<void> => {
            await this.getEquipmentProviderListByName(search);
        }
        this.$scope.goToCountry = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.country", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.$scope.goToProvider = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.provider", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.$scope.goToAccount = (accounts: ISelectorModel[]): void => {
            this.$scope.sessionService.openTab("app.commercial.account", <ILinkParameter>{ ID: this.$scope.getCONCAT(accounts, null, "ID") });
        }
        this.$scope.goToExporter = (exporters: ILegalPersonSelector[]) => {
            this.$scope.sessionService.openTab("app.registration.legalPerson", <ILinkParameter>{ ID: this.$scope.getCONCAT(exporters, null, "ID") });
        }
        this.$scope.goToImporter = (importers: ILegalPersonSelector[]) => {
            this.$scope.sessionService.openTab("app.registration.legalPerson", <ILinkParameter>{ ID: this.$scope.getCONCAT(importers, null, "ID") });
        }
        this.$scope.formatConcatenatedChars = (value: string) => {
            return StringUtil.formatConcatenatedChars(value);
        }
        this.$scope.generateConcatenated = (): void => {
            this.generateConcatenated();
        }
        this.$scope.addTableFTPDDetItem = (): void => {
            this.addTableFTPDDetItem();
        }
        this.$scope.addTableFTPDDemItem = (): void => {
            this.addTableFTPDDemItem();
        }
        this.$scope.addDetFreeTimePerDiemItem = (parentIndex: number): void => {
            this.addDetFreeTimePerDiemItem(parentIndex);
        }
        this.$scope.addImoDetFreeTimePerDiemItem = (parentIndex: number): void => {
            this.addImoDetFreeTimePerDiemItem(parentIndex);
        }
        this.$scope.addDemFreeTimePerDiemItem = (parentIndex: number): void => {
            this.addDemFreeTimePerDiemItem(parentIndex);
        }
        this.$scope.addImoDemFreeTimePerDiemItem = (parentIndex: number): void => {
            this.addImoDemFreeTimePerDiemItem(parentIndex);
        }
        this.$scope.removeTableDetFreeTimePerDiemItem = (parentIndex: number, index: number): void => {
            this.removeTableDetFreeTimePerDiemItem(parentIndex, index);
        }
        this.$scope.removeTableImoDetFreeTimePerDiemItem = (parentIndex: number, index: number): void => {
            this.removeTableImoDetFreeTimePerDiemItem(parentIndex, index);
        }
        this.$scope.removeTableDemFreeTimePerDiemItem = (parentIndex: number, index: number): void => {
            this.removeTableDemFreeTimePerDiemItem(parentIndex, index);
        }
        this.$scope.setDetImoPerDiem = (parentIndex: number, index: number): void => {
            this.setDetImoPerDiem(parentIndex, index);
        }
        this.$scope.setDemImoPerDiem = (parentIndex: number, index: number): void => {
            this.setDemImoPerDiem(parentIndex, index);
        }
        this.$scope.askForDetDemChange = (): void => {
            this.askForDetDemChange();
        }
        this.$scope.checkDisableFields = (field: string): boolean => {
            return this.checkDisableFields(field);
        }
        this.$scope.checkSelectorValidity = (): void => {
            this.checkSelectorValidity();
        }
        this.$scope.enableFreeTimeImo = (): void => {
            this.enableFreeTimeImo();
        }
        this.$scope.disableFreeTimeImo = (): void => {
            this.disableFreeTimeImo();
        }
        this.$scope.copyTableFreeTimeDetPerDiem = (parentIndex: number): void => {
            this.copyTableFreeTimeDetPerDiem(parentIndex);
        }
        this.$scope.copyTableFreeTimeDemPerDiem = (parentIndex: number): void => {
            this.copyTableFreeTimeDemPerDiem(parentIndex);
        }
        this.$scope.removeTableDetFreeTimePerDiem = (parentIndex: number): void => {
            this.removeTableDetFreeTimePerDiem(parentIndex);
        }
        this.$scope.removeTableDemFreeTimePerDiem = (parentIndex: number): void => {
            this.removeTableDemFreeTimePerDiem(parentIndex);
        }
        this.$scope.uploader.onSuccessItem = (item: IFileItem, response: ISuccessItemResponse): void => {
            this.onSuccessItem(item, response);
        }
        this.$scope.removeUpload = (model: IUploadItem): boolean => {
            return this.removeUpload(model);
        }
        this.$scope.getCurrency = (currencySelected: ISelectorModel): void => {
            this.$scope.model.CURRENCY = currencySelected;
            this.$scope.currencySelected = this.$scope.model.CURRENCY;
            if (currencySelected) {
                if (this.$scope.model.TABLE_IMO_FT_PD_DET.length > 0) {
                    for (const IMO_DET of this.$scope.model.TABLE_IMO_FT_PD_DET) {
                        if (IMO_DET) {
                            IMO_DET.FREE_TIME_PER_DIEM.map(currencyImoDet => {
                                currencyImoDet.CURRENCY = {
                                    CODE: currencySelected.CODE,
                                    NAME: currencySelected.NAME,
                                    ID: currencySelected.ID
                                }
                            })
                        }
                    }
                }
            }
            if (currencySelected) {
                if (this.$scope.model.TABLE_FT_PD_DET.length > 0) {
                    for (const DET of this.$scope.model.TABLE_FT_PD_DET) {
                        if (DET) {
                            DET.FREE_TIME_PER_DIEM.map(currencyDet => {
                                currencyDet.CURRENCY = {
                                    CODE: currencySelected.CODE,
                                    NAME: currencySelected.NAME,
                                    ID: currencySelected.ID
                                };
                            })
                        }
                    }

                }
            }
            if (currencySelected) {
                if (this.$scope.model.TABLE_FT_PD_DEM) {
                    for (const DEM of this.$scope.model.TABLE_FT_PD_DEM) {
                        if (DEM) {
                            DEM.FREE_TIME_PER_DIEM.map(currencyDem => {
                                currencyDem.CURRENCY = {
                                    CODE: currencySelected.CODE,
                                    NAME: currencySelected.NAME,
                                    ID: currencySelected.ID
                                };
                            });
                        }
                    }
                }
            }
            if (currencySelected) {
                if (this.$scope.model.TABLE_IMO_FT_PD_DET) {
                    for (const IMO_DET of this.$scope.model.TABLE_IMO_FT_PD_DET) {
                        if (IMO_DET) {
                            IMO_DET.FREE_TIME_PER_DIEM.map(currencyImoDet => {
                                currencyImoDet.CURRENCY = {
                                    CODE: currencySelected.CODE,
                                    NAME: currencySelected.NAME,
                                    ID: currencySelected.ID
                                }
                            })
                        }
                    }
                }
            }
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            TRANSACTION: null,
            DIRECTION: null,
            COUNTRY: null,
            DEFAULT: false,
            ID_EQUIPMENT_PROVIDER: null,
            EQUIPMENT_PROVIDER: null,
            PROVIDER: null,
            ACCOUNTS: null,
            EXPORTERS: null,
            IMPORTERS: null,
            TABLE_TYPE: null,
            CONCATENATED: null,
            COMPLEMENT_CONCATENATED: null,
            TABLE_FT_PD_DET: null,
            TABLE_FT_PD_DEM: null,
            TABLE_IMO_FT_PD_DET: null,
            TABLE_IMO_FT_PD_DEM: null,
            FREE_TIME_IMO: true,
            ACTIVE: true,
            CREATED_BY: null,
            CREATED_AT: null,
            UPDATED_BY: null,
            UPDATED_AT: null,
            CURRENCY: null,
            FILES: null,
            FILES_REMOVE: null,
            FILES_NEW: null,
        }
    }

    initDependencies(): Promise<any> {
        const self: TablePerDiemRegisterController = this;

        return new Promise(function (resolve, reject) {

            self.$q.all([
                self.getTypeTariffList(),
                self.getPaymentNatureList(),
                self.getDirectionList(),
                self.getCurrencyTable()
            ]).then((result: any) => {
                self.$scope.tariffTypeList = result[0];
                self.$scope.paymentNatureList = result[1];
                self.$scope.directionList = result[2];
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    private async editTablePerDiem(tablePerDiem: ITablePerDiemModel): Promise<void> {
        if (tablePerDiem.DEFAULT && await this.permissionService.isRoleAllowed("TABLEPERDIEMEDITDEFAULT") === false) {
            this.permissionService.showBlockMessage();
            this.formService.unblock();
            return;
        }
        let blockedObject = {
            ID: tablePerDiem.ID,
            NAME: tablePerDiem.CONCATENATED,
            EMAIL: this.$scope.user['email'],
            FORM_NAME: this.gridName
        };
        this.SSEService.closeEvents();
        this.SSEService.setBlockedObject(blockedObject);
        this.SSEService.initEvents();
        this.SSEService.events.onmessage = async (event) => {
            const parsedData = JSON.parse(event.data);
            if (!parsedData.status) {
                const result = await this.SSEService.generate(parsedData);
                if (result && !result.status) {
                    this.$rootScope.refreshPage();
                    return;
                }
                if (this.$scope.operation !== EOperation.VIEW || tablePerDiem.ID !== this.$scope.model.ID) this.fetchData(tablePerDiem.ID, EOperation.VIEW);
            } else if (this.$scope.operation !== EOperation.EDIT || tablePerDiem.ID !== this.$scope.model.ID) {
                this.fetchData(tablePerDiem.ID, EOperation.EDIT);
            }
            if (this.$scope.model.CONCATENATED) this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
        };
    }

    private async getTypeTariffList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/dd_type_tariff`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getPaymentNatureList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/payment_nature`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getDirectionList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/direction`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getCurrencyTable() {
        if (!this.$scope.model.CURRENCY && this.$scope.model.TABLE_FT_PD_DET) {
            for (const item of this.$scope.model.TABLE_FT_PD_DET) {
                item.FREE_TIME_PER_DIEM.map(currency => {
                    this.$scope.model.CURRENCY = currency.CURRENCY
                })
            }
        }
        if (this.$scope.model.CURRENCY) {
            this.$scope.currencySelected = this.$scope.model.CURRENCY;
        }
    }

    initGridColumns(columns: string[]): IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<a ng-click="grid.appScope.viewTablePerDiem(row.entity.ID)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true"><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.customEdit(row.entity)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true"><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogTablePerDiem(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true"><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.setCopy(true);grid.appScope.copyTablePerDiem(row.entity)" class="text-orange copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true"><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;

        const colActions: IMonacoColumnDef = {
            name: "acoes", displayName: "GENERAL.ACTIONS",
            width: 125,
            cellTemplate: `<div class="text-center view-btn-action-bar"> ${view} ${edit} ${viewLog} ${copy} </div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };

        gridColumns.addColumn(colActions);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colPaymentNature: IMonacoColumnDef = { name: "TRANSACTION.NAME", displayName: "BASIC_DATA.TRANSACTION", width: 120 };
            const colConcatenated: IMonacoColumnDef = { name: "CONCATENATED", displayName: "GENERAL.CONCATENATED", width: 350 };
            const colDirection: IMonacoColumnDef = { name: "DIRECTION.NAME", displayName: "PRODUCT.WAY", width: 120 };
            const colProvider: IMonacoColumnDef = { name: "PROVIDER.NAME", displayName: "BASIC_DATA.PROVIDER", width: 150 };
            const colAccount: IMonacoColumnDef = { name: "ACCOUNTS.NAME", displayName: "BASIC_DATA.CLIENT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNTS, null, "NAME")}}</div>' };
            const colExporter: IMonacoColumnDef = { name: "EXPORTERS.NAME", displayName: "BASIC_DATA.SHIPPER", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.EXPORTERS, null, "NAME")}}</div>' };
            const colImporter: IMonacoColumnDef = { name: "IMPORTERS.NAME", displayName: "BASIC_DATA.CONSIGNEE", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.IMPORTERS, null, "NAME")}}</div>' };
            const colCountry: IMonacoColumnDef = { name: "COUNTRY.CODE", displayName: "ROUTE.COUNTRY", width: 150 };
            const colTableType: IMonacoColumnDef = { name: "TABLE_TYPE.NAME", displayName: "PRODUCT.TARIFF_TYPE", width: 150 };
            const colConcatenatedComplement: IMonacoColumnDef = { name: "COMPLEMENT_CONCATENATED", displayName: "GENERAL.CONCATENATED_COMPLEMENT", width: 150 };
            const colDefault: IMonacoColumnDef = { name: "DEFAULT", displayName: "GENERAL.DEFAULT", width: 80, cellFilter: "YesOrNo" };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 80, cellFilter: "YesOrNo" };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'TRANSACTION':
                        columnDefs.push(colPaymentNature);
                        break;
                    case 'CONCATENATED':
                        columnDefs.push(colConcatenated);
                        break;
                    case 'DIRECTION':
                        columnDefs.push(colDirection);
                        break;
                    case 'PROVIDER':
                        columnDefs.push(colProvider);
                        break;
                    case 'ACCOUNTS':
                        columnDefs.push(colAccount);
                        break;
                    case 'EXPORTERS':
                        columnDefs.push(colExporter);
                        break;
                    case 'IMPORTERS':
                        columnDefs.push(colImporter);
                        break;
                    case 'COUNTRY':
                        columnDefs.push(colCountry);
                        break;
                    case 'TABLE_TYPE':
                        columnDefs.push(colTableType);
                        break;
                    case 'COMPLEMENT_CONCATENATED':
                        columnDefs.push(colConcatenatedComplement);
                        break;
                    case 'DEFAULT':
                        columnDefs.push(colDefault);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async register(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.getTablePerDiem(id);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.disableElements(true);
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
            this.initPanels(["collapseBasicData"], "collapseBasicData");
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.model.CONCATENATED) this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
            BrowserTitle.$id = this.$scope.model.CONCATENATED;

            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async edit(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            this.initPanels(["collapseBasicData"], "collapseBasicData");
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.model.CONCATENATED) this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
            BrowserTitle.$id = this.$scope.model.CONCATENATED;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.disableElements(false);
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            await this.clearFields(this.$scope.model, ['CONCATENATED']);
            this.generateConcatenated();
            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async save(): Promise<boolean> {
        let proceed: boolean = true;
        if (this.$scope.model.TRANSACTION.ID == EPaymentNatureId.RECEIVING && !this.$scope.model.DEFAULT && !this.$scope.model.ACCOUNTS && !this.$scope.model.EXPORTERS && !this.$scope.model.IMPORTERS) {
            const msg = "At least one of Accounts, Exporters or Importers must be informed.";
            proceed = false;
            this.formService.notifyError(msg);
        }
        if (this.$scope.model.TABLE_TYPE && this.$scope.model.TABLE_TYPE.ID) {
            if (this.$scope.model.TABLE_TYPE.ID == '1') {
                if (!this.$scope.model.TABLE_FT_PD_DET || this.$scope.model.TABLE_FT_PD_DET.length == 0) {
                    const msg = "Please inform at least one item for the Table Per Diem at Detention.";
                    proceed = false;
                    this.formService.notifyError(msg);
                }
                if (!this.$scope.model.TABLE_FT_PD_DEM || this.$scope.model.TABLE_FT_PD_DEM.length == 0) {
                    const msg = "Please inform at least one item for the Table Per Diem at Demurrage.";
                    proceed = false;
                    this.formService.notifyError(msg);
                }
            } else {
                if (!this.$scope.model.TABLE_FT_PD_DET || this.$scope.model.TABLE_FT_PD_DET.length == 0) {
                    const msg = "Please inform at least one item for the Table Free time and Per Diem at Detention/Demurrage.";
                    proceed = false;
                    this.formService.notifyError(msg);
                }
            }
        } else {
            const msg = "Please fill a table Free Time and Per Diem.";
            proceed = false;
            this.formService.notifyError(msg);
        }

        if (this.$scope.model.TABLE_FT_PD_DET && this.$scope.model.TABLE_FT_PD_DET.length > 0 && this.$scope.model.TABLE_FT_PD_DEM && this.$scope.model.TABLE_FT_PD_DEM.length > 0) {

            let arrayEquipDet: ISelectorModel[] = [];
            let arrayEquipDem: ISelectorModel[] = [];

            for (const tableDet of this.$scope.model.TABLE_FT_PD_DET) {
                if (tableDet.EQUIPMENT) {
                    for (const equipItem of tableDet.EQUIPMENT) {
                        arrayEquipDet.push(equipItem);
                    }
                }
            }

            for (const tableDet of this.$scope.model.TABLE_FT_PD_DEM) {
                if (tableDet.EQUIPMENT) {
                    for (const equipItem of tableDet.EQUIPMENT) {
                        arrayEquipDem.push(equipItem);
                    }
                }
            }

            let detLength = arrayEquipDet.length;
            let demLength = arrayEquipDem.length;
            // If lengths of array are not equal means
            // array are not equal
            if (detLength != demLength) {
                proceed = false;
            }

            if (proceed) {
                arrayEquipDet.sort();
                arrayEquipDem.sort();
                for (let i = 0; i < detLength; i++) {
                    if (arrayEquipDet[i].ID != arrayEquipDem[i].ID) proceed = false;
                }
            }

            if (!proceed) {
                const msg = "Please inform the same equipments on Detention and Demurrage panel.";
                this.formService.notifyError(msg);
            }
        }

        if (proceed) {
            if (this.$scope.model.TABLE_TYPE && this.$scope.model.TABLE_TYPE.ID == EDDTypeTariff.COMBINED_DET_DEM && !this.$scope.model.FREE_TIME_IMO && this.$scope.model.TABLE_FT_PD_DET) {
                for (let i = 0; i <= this.$scope.model.TABLE_FT_PD_DET.length - 1; i++) {
                    const equip = this.$scope.model.TABLE_FT_PD_DET[i].EQUIPMENT;
                    this.$scope.model.TABLE_IMO_FT_PD_DET[i].EQUIPMENT = equip;
                }
            }

            if (this.$scope.model.TABLE_TYPE && this.$scope.model.TABLE_TYPE.ID == EDDTypeTariff.SPLITED_DET_DEM && !this.$scope.model.FREE_TIME_IMO && this.$scope.model.TABLE_FT_PD_DEM) {
                for (let i = 0; i <= this.$scope.model.TABLE_FT_PD_DET.length - 1; i++) {
                    const equip = this.$scope.model.TABLE_FT_PD_DET[i].EQUIPMENT;
                    this.$scope.model.TABLE_IMO_FT_PD_DET[i].EQUIPMENT = equip;
                }
                for (let i = 0; i <= this.$scope.model.TABLE_FT_PD_DEM.length - 1; i++) {
                    const equip = this.$scope.model.TABLE_FT_PD_DEM[i].EQUIPMENT;
                    this.$scope.model.TABLE_IMO_FT_PD_DEM[i].EQUIPMENT = equip;
                }
            }
        }
        return proceed;
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';

        return {
            route: `/tablePerDiem/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    private async getTablePerDiem(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/tablePerDiem/getById/${id}`, 30000, null, false);
            return result;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getCountryListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const countryList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/country/list/custom`, { search }, 30000, false);
                result = (countryList && countryList) ? countryList.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS, COUNTRY_IMG: null } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.countryList = result;
            this.formService.unblock();
        }
    }

    private async getAccountListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const accounts = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/account/list/custom`, { search: search }, 30000, false);
                result = accounts ? accounts.map(x => { return { ID: x.ID, NAME: x.NAME } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.accountList = result;
            this.formService.unblock();
        }
    }

    private async getProviderListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                let types = ['1'];
                this.formService.block();
                const providers: IProviderModel[] = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/provider/list/custom`, { search, types }, 30000, false);
                result = providers ? providers.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.SCAC_IATA, TYPE: x.TYPE } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.providerList = result;
        }
    }

    private async getExporterListByName(search: string): Promise<void> {
        let result = [];
        try {
            const exporterList: SelectorModel[] = [];
            const legalPersonList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.EXPORTER], search: search });
            if (legalPersonList) legalPersonList.map(legalPerson => { exporterList.push({ ID: legalPerson.ID.toString(), NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME }) });
            result = exporterList;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.exporterList = result;
        }
    }

    private async getImporterListByName(search: string): Promise<void> {
        let result = [];
        try {
            const importerList: SelectorModel[] = [];
            const legalPersonList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.IMPORTER], search: search });
            if (legalPersonList) legalPersonList.map(legalPerson => { importerList.push({ ID: legalPerson.ID.toString(), NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME }) });
            result = importerList;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.importerList = result;
        }
    }

    private async getLegalPersonListByName(legalPersonFilter: ILegalPersonListCustomFilter): Promise<ILegalPersonModel[]> {
        let result = [];
        try {
            if (legalPersonFilter.search && legalPersonFilter.search.length >= 3) {
                this.formService.block();
                result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/legalPerson/list/custom`, legalPersonFilter, 30000, false);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private async getCurrencyListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const currencies = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/currency/list/custom`, { name: search }, 30000, false);
                result = (currencies && currencies) ? currencies.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.currencyList = result;
        }
    }

    private async getEquipmentListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 2) {

                this.formService.block();

                const products = [EProductId.MARITIME_IMPORT, EProductId.MARITIME_EXPORT];
                const equipmentList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/equipment/list/custom`, { search, products }, 30000, false);
                result = equipmentList ? equipmentList.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, TEU: x.TEU, FEET: x.FEET } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.equipmentList = result;
        }
    }

    private async getEquipmentProviderListByName(search: string): Promise<void> {
        let result = [];
        try {
            const equipmentProviderList: SelectorModel[] = [];
            const legalPersonList = await this.getLegalPersonListByName({ specializations: [ELegalPersonSpecializationId.EQUIPMENT_PROVIDER], search: search });
            if (legalPersonList) legalPersonList.map(legalPerson => { equipmentProviderList.push({ ID: legalPerson.ID.toString(), NAME: legalPerson.SHORT_NAME, CODE: legalPerson.CORPORATE_NAME }) });
            result = equipmentProviderList;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.equipmentProviderList = result;
        }
    }

    private getMenuFloatingDefault(): IFloatingMenu {
        return {
            btnActiveDisabled: false,
            tooltipPlacement: "auto bottom",
            textTooltip: "GENERAL.TABLE_PER_DIEM",
            infos: [
                { text: "PRODUCT.TABLE_PER_DIEM", class: "text-rouge font-bold" }
            ],
            options: [
                {
                    click: "collapseHeader",
                    args: ['collapseBasicData'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.BASIC_DATA",
                    iconClass: "fa fa-address-card",
                    iconBodyClass: "text-brown"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseUploads'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "REGISTRATION.EMAIL_ATTACHMENT",
                    iconClass: "fa fa-files-o",
                    iconBodyClass: "text-gray"
                }
            ]
        };
    }

    private generateConcatenated(): void {
        try {
            const separator = " | ";
            let concatenated = "";

            if (this.$scope.model.TRANSACTION && this.$scope.model.TRANSACTION.ID && this.$scope.model.TRANSACTION.ID.length > 0) {
                let paymentNature = null;
                if (this.$scope.model.TRANSACTION.ID === '1') {
                    paymentNature = 'PGTO';
                } else if (this.$scope.model.TRANSACTION.ID === '2') {
                    paymentNature = 'RCTO';
                }
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, paymentNature) : concatenated.concat(paymentNature);
            }

            if (this.$scope.model.DIRECTION && this.$scope.model.DIRECTION.ID && this.$scope.model.DIRECTION.ID.length > 0) {
                let direction = null;
                if (this.$scope.model.DIRECTION.ID === '1') {
                    direction = 'OR';
                } else if (this.$scope.model.DIRECTION.ID === '2') {
                    direction = 'DE';
                }
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, direction) : concatenated.concat(direction);
            }

            if (this.$scope.model.COUNTRY && this.$scope.model.COUNTRY.ID) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.COUNTRY.CODE) : concatenated.concat(this.$scope.model.COUNTRY.CODE);
            }

            if (this.$scope.model.PROVIDER) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PROVIDER ? this.$scope.model.PROVIDER.CODE : this.$scope.model.PROVIDER.NAME) : concatenated.concat(this.$scope.model.PROVIDER ? this.$scope.model.PROVIDER.CODE : this.$scope.model.PROVIDER.NAME);
            }

            if (this.$scope.model.EQUIPMENT_PROVIDER) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.EQUIPMENT_PROVIDER.NAME) : concatenated.concat(this.$scope.model.EQUIPMENT_PROVIDER.NAME);
            }

            if (this.$scope.model.ACCOUNTS && this.$scope.model.ACCOUNTS.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.ACCOUNTS.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(this.$scope.model.ACCOUNTS.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.EXPORTERS && this.$scope.model.EXPORTERS.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.EXPORTERS.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(this.$scope.model.EXPORTERS.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.IMPORTERS && this.$scope.model.IMPORTERS.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.IMPORTERS.map(obj => { return obj.NAME }).join(',')) : concatenated.concat(this.$scope.model.IMPORTERS.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.TABLE_TYPE && this.$scope.model.TABLE_TYPE.ID) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.TABLE_TYPE.NAME) : concatenated.concat(this.$scope.model.TABLE_TYPE.NAME);
            }

            if (this.$scope.model.COMPLEMENT_CONCATENATED && this.$scope.model.COMPLEMENT_CONCATENATED.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.COMPLEMENT_CONCATENATED) : concatenated.concat(this.$scope.model.COMPLEMENT_CONCATENATED);
            }

            this.$scope.model.CONCATENATED = StringUtil.formatConcatenatedChars(concatenated);

            if (this.$scope.menuFloating) this.$scope.menuFloating.infos = [{ text: concatenated, class: "text-rouge font-bold" }];
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private copyTableFreeTimeDetPerDiem(parentIndex: number) {
        try {
            if (!parentIndex && parentIndex !== 0) throw Error('index is null');

            if (this.$scope.model.TABLE_FT_PD_DET && this.$scope.model.TABLE_FT_PD_DET.length > 0 && this.$scope.model.TABLE_FT_PD_DET[parentIndex]) {
                const tableItem = angular.copy(this.$scope.model.TABLE_FT_PD_DET[parentIndex]);

                this.$scope.model.TABLE_FT_PD_DET.push(tableItem);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private copyTableFreeTimeDemPerDiem(parentIndex: number) {
        try {
            if (!parentIndex && parentIndex !== 0) throw Error('index is null');

            if (this.$scope.model.TABLE_FT_PD_DEM && this.$scope.model.TABLE_FT_PD_DEM.length > 0 && this.$scope.model.TABLE_FT_PD_DEM[parentIndex]) {
                const tableItem = angular.copy(this.$scope.model.TABLE_FT_PD_DEM[parentIndex]);

                this.$scope.model.TABLE_FT_PD_DEM.push(tableItem);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeTableDetFreeTimePerDiem(parentIndex: number) {
        try {
            if (!parentIndex && parentIndex !== 0) throw Error('index is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.NO',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });

            if (!modal) return;

            this.formService.block();

            if (this.$scope.model.TABLE_FT_PD_DET[parentIndex]) {
                this.$scope.model.TABLE_FT_PD_DET.splice(parentIndex, 1);
            }

            if (!this.$scope.model.FREE_TIME_IMO) {
                if (this.$scope.model.TABLE_IMO_FT_PD_DET[parentIndex]) {
                    this.$scope.model.TABLE_IMO_FT_PD_DET.splice(parentIndex, 1);
                }
            }

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeTableDemFreeTimePerDiem(parentIndex: number) {
        try {
            if (!parentIndex && parentIndex !== 0) throw Error('index is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.NO',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });

            if (!modal) return;

            this.formService.block();

            if (this.$scope.model.TABLE_FT_PD_DEM[parentIndex]) {
                this.$scope.model.TABLE_FT_PD_DEM.splice(parentIndex, 1);
            }

            if (!this.$scope.model.FREE_TIME_IMO) {
                if (this.$scope.model.TABLE_IMO_FT_PD_DEM[parentIndex]) {
                    this.$scope.model.TABLE_IMO_FT_PD_DEM.splice(parentIndex, 1);
                }
            }

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeTableDetFreeTimePerDiemItem(parentIndex: number, index: number) {
        try {
            if (!index && index !== 0) throw Error('index is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.NO',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });

            if (!modal) return;

            this.formService.block();

            if (this.$scope.model.TABLE_FT_PD_DET[parentIndex] && this.$scope.model.TABLE_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM.length > 0) {
                this.$scope.model.TABLE_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM.splice(index, 1);
            }

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeTableImoDetFreeTimePerDiemItem(parentIndex: number, index: number) {
        try {
            if (!index && index !== 0) throw Error('index is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.NO',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });

            if (!modal) return;

            this.formService.block();

            if (this.$scope.model.TABLE_IMO_FT_PD_DET[parentIndex] && this.$scope.model.TABLE_IMO_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM.length > 0) {
                this.$scope.model.TABLE_IMO_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM.splice(index, 1);
            }

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeTableDemFreeTimePerDiemItem(parentIndex: number, index: number) {
        try {
            if (!index && index !== 0) throw Error('index is null');

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.NO',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });

            if (!modal) return;

            this.formService.block();

            if (this.$scope.model.TABLE_FT_PD_DEM[parentIndex] && this.$scope.model.TABLE_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM.length > 0) {
                this.$scope.model.TABLE_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM.splice(index, 1);
            }

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private setDetImoPerDiem(parentIndex: number, index: number) {
        if (this.$scope.model.TRANSACTION.ID == '1') {
            if (this.$scope.model.TABLE_FT_PD_DET[parentIndex] && this.$scope.model.TABLE_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM && this.$scope.model.TABLE_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM[index]
                && this.$scope.model.TABLE_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM[index].PER_DIEM && !this.$scope.model.TABLE_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM[index].IMO_PER_DIEM) {
                this.$scope.model.TABLE_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM[index].IMO_PER_DIEM = this.$scope.model.TABLE_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM[index].PER_DIEM;
            }
        }
    }

    private setDemImoPerDiem(parentIndex: number, index: number) {
        if (this.$scope.model.TRANSACTION.ID == '1') {
            if (this.$scope.model.TABLE_FT_PD_DEM[parentIndex] && this.$scope.model.TABLE_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM && this.$scope.model.TABLE_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM[index]
                && this.$scope.model.TABLE_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM[index].PER_DIEM && !this.$scope.model.TABLE_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM[index].IMO_PER_DIEM) {
                this.$scope.model.TABLE_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM[index].IMO_PER_DIEM = this.$scope.model.TABLE_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM[index].PER_DIEM;
            }
        }
    }

    private async askForDetDemChange() {

        if (this.$scope.model.TABLE_FT_PD_DET || this.$scope.model.TABLE_FT_PD_DEM) {
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.NO',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });

            if (!modal) return;

            this.$scope.model.TABLE_FT_PD_DET = null;
            this.$scope.model.TABLE_FT_PD_DEM = null;
            this.$scope.model.TABLE_IMO_FT_PD_DET = null;
            this.$scope.model.TABLE_IMO_FT_PD_DEM = null;
        }
    }

    private addTableFTPDDetItem() {
        if (!this.$scope.model.TABLE_FT_PD_DET) this.$scope.model.TABLE_FT_PD_DET = [];
        const tableFTPDItem: ITableFreeTimePerDiem = {
            EQUIPMENT: null,
            FREE_TIME_PER_DIEM: null
        };
        this.$scope.model.TABLE_FT_PD_DET.push(tableFTPDItem);

        if (!this.$scope.model.FREE_TIME_IMO) {
            if (!this.$scope.model.TABLE_IMO_FT_PD_DET) this.$scope.model.TABLE_IMO_FT_PD_DET = [];
        }

    }

    private addTableFTPDDemItem() {
        if (!this.$scope.model.TABLE_FT_PD_DEM) this.$scope.model.TABLE_FT_PD_DEM = [];
        const tableFTPDItem: ITableFreeTimePerDiem = {
            EQUIPMENT: null,
            FREE_TIME_PER_DIEM: null
        };
        this.$scope.model.TABLE_FT_PD_DEM.push(tableFTPDItem);

        if (!this.$scope.model.FREE_TIME_IMO) {
            if (!this.$scope.model.TABLE_IMO_FT_PD_DEM) this.$scope.model.TABLE_IMO_FT_PD_DEM = [];
        }
    }

    private addDetFreeTimePerDiemItem(parentIndex: number) {
        if (!this.$scope.model.TABLE_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM) this.$scope.model.TABLE_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM = [];

        let from = 1;
        let until = 999;
        let period = 1;
        if (this.$scope.model.TABLE_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM && this.$scope.model.TABLE_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM.length > 0) {
            const length = this.$scope.model.TABLE_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM.length;
            from = this.$scope.model.TABLE_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM[length - 1].UNTIL + 1;
            period = this.$scope.model.TABLE_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM.length + 1;
        }

        if (until < from) {
            until = from + 1;
        }

        const freeTimePerDiemItem: IFreeTimePerDiem = {
            PERIOD: period,
            FROM: from,
            UNTIL: until,
            CURRENCY: this.$scope.currencySelected,
            PER_DIEM: null,
            IMO_PER_DIEM: null
        };

        this.$scope.model.TABLE_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM.push(angular.copy(freeTimePerDiemItem));

        this.$timeout(() => {
            for (let i = 0; i < this.$scope.model.TABLE_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM.length; i++) {
                this.$scope.selectorValidity(`tableFTPDPeriod${parentIndex}${i}`);
                this.$scope.selectorValidity(`tableFTPDFrom${parentIndex}${i}`);
                this.$scope.selectorValidity(`tableFTPDUntil${parentIndex}${i}`);
                this.$scope.selectorValidity(`tableFTPDCurrency${parentIndex}${i}`);
                this.$scope.selectorValidity(`tableFTPDPerDiem${parentIndex}${i}`);
                this.$scope.selectorValidity(`tableFTPDImoPerDiem${parentIndex}${i}`);
            }
        });
    }

    private addImoDetFreeTimePerDiemItem(parentIndex: number) {

        if (!this.$scope.model.TABLE_IMO_FT_PD_DET[parentIndex]) {
            const tableFTPDItem: ITableFreeTimePerDiem = {
                EQUIPMENT: null,
                FREE_TIME_PER_DIEM: null
            };
            this.$scope.model.TABLE_IMO_FT_PD_DET.splice(parentIndex, 0, tableFTPDItem);
        }

        if (!this.$scope.model.TABLE_IMO_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM) this.$scope.model.TABLE_IMO_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM = [];

        let from = 1;
        let until = 999;
        let period = 1;

        if (this.$scope.model.TABLE_IMO_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM && this.$scope.model.TABLE_IMO_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM.length > 0) {
            const length = this.$scope.model.TABLE_IMO_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM.length;
            from = this.$scope.model.TABLE_IMO_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM[length - 1].UNTIL + 1;
            period = this.$scope.model.TABLE_IMO_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM.length + 1;
        }

        if (until < from) {
            until = from + 1;
        }

        const freeTimePerDiemItem: IFreeTimePerDiem = {
            PERIOD: period,
            FROM: from,
            UNTIL: until,
            CURRENCY: this.$scope.currencySelected,
            PER_DIEM: null,
            IMO_PER_DIEM: null
        };

        this.$scope.model.TABLE_IMO_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM.push(angular.copy(freeTimePerDiemItem));

        this.$timeout(() => {
            for (let i = 0; i < this.$scope.model.TABLE_IMO_FT_PD_DET[parentIndex].FREE_TIME_PER_DIEM.length; i++) {
                this.$scope.selectorValidity(`tableImoFTPDPeriod${parentIndex}${i}`);
                this.$scope.selectorValidity(`tableImoFTPDFrom${parentIndex}${i}`);
                this.$scope.selectorValidity(`tableImoFTPDUntil${parentIndex}${i}`);
                this.$scope.selectorValidity(`tableImoFTPDCurrency${parentIndex}${i}`);
                this.$scope.selectorValidity(`tableImoFTPDPerDiem${parentIndex}${i}`);
                this.$scope.selectorValidity(`tableImoFTPDImoPerDiem${parentIndex}${i}`);
            }
        });
    }

    private addImoDemFreeTimePerDiemItem(parentIndex: number) {
        if (!this.$scope.model.TABLE_IMO_FT_PD_DEM[parentIndex]) {
            const tableFTPDItem: ITableFreeTimePerDiem = {
                EQUIPMENT: null,
                FREE_TIME_PER_DIEM: null
            };
            this.$scope.model.TABLE_IMO_FT_PD_DEM.splice(parentIndex, 0, tableFTPDItem);
        }

        if (!this.$scope.model.TABLE_IMO_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM) this.$scope.model.TABLE_IMO_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM = [];

        let from = 1;
        let until = 999;
        let period = 1;

        if (this.$scope.model.TABLE_IMO_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM && this.$scope.model.TABLE_IMO_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM.length > 0) {
            const length = this.$scope.model.TABLE_IMO_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM.length;
            from = this.$scope.model.TABLE_IMO_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM[length - 1].UNTIL + 1;
            period = this.$scope.model.TABLE_IMO_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM.length + 1;
        }

        if (until < from) {
            until = from + 1;
        }

        const freeTimePerDiemItem: IFreeTimePerDiem = {
            PERIOD: period,
            FROM: from,
            UNTIL: until,
            CURRENCY: this.$scope.currencySelected,
            PER_DIEM: null,
            IMO_PER_DIEM: null
        };

        this.$scope.model.TABLE_IMO_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM.push(angular.copy(freeTimePerDiemItem));

        this.$timeout(() => {
            for (let i = 0; i < this.$scope.model.TABLE_IMO_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM.length; i++) {
                this.$scope.selectorValidity(`tableImoFTPDPeriod${parentIndex}${i}`);
                this.$scope.selectorValidity(`tableImoFTPDFrom${parentIndex}${i}`);
                this.$scope.selectorValidity(`tableImoFTPDUntil${parentIndex}${i}`);
                this.$scope.selectorValidity(`tableImoFTPDCurrency${parentIndex}${i}`);
                this.$scope.selectorValidity(`tableImoFTPDPerDiem${parentIndex}${i}`);
                this.$scope.selectorValidity(`tableImoFTPDImoPerDiem${parentIndex}${i}`);
            }
        });
    }

    private addDemFreeTimePerDiemItem(parentIndex: number) {
        if (!this.$scope.model.TABLE_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM) this.$scope.model.TABLE_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM = [];

        let from = 1;
        let until = 999;
        let period = 1;
        if (this.$scope.model.TABLE_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM && this.$scope.model.TABLE_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM.length > 0) {
            const length = this.$scope.model.TABLE_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM.length;
            from = this.$scope.model.TABLE_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM[length - 1].UNTIL + 1;
            period = this.$scope.model.TABLE_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM.length + 1;
        }

        if (until < from) {
            until = from + 1;
        }

        const freeTimePerDiemItem: IFreeTimePerDiem = {
            PERIOD: period,
            FROM: from,
            UNTIL: until,
            CURRENCY: this.$scope.currencySelected,
            PER_DIEM: null,
            IMO_PER_DIEM: null
        };

        this.$scope.model.TABLE_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM.push(angular.copy(freeTimePerDiemItem));

        this.$timeout(() => {
            for (let i = 0; i < this.$scope.model.TABLE_FT_PD_DEM[parentIndex].FREE_TIME_PER_DIEM.length; i++) {
                this.$scope.selectorValidity(`tableFTPDPeriod${parentIndex}${i}`);
                this.$scope.selectorValidity(`tableFTPDFrom${parentIndex}${i}`);
                this.$scope.selectorValidity(`tableFTPDUntil${parentIndex}${i}`);
                this.$scope.selectorValidity(`tableFTPDCurrency${parentIndex}${i}`);
                this.$scope.selectorValidity(`tableFTPDPerDiem${parentIndex}${i}`);
                this.$scope.selectorValidity(`tableFTPDImoPerDiem${parentIndex}${i}`);
            }
        });
    }

    private checkDisableFields(field: string): boolean {
        let disabled = false;
        if (this.$scope.model) {
            if (this.$scope.operation == 'edit' && this.$scope.model.DEFAULT) {
                disabled = true;
                return disabled;
            }

            if (field == 'ACCOUNTS') {
                if (this.$scope.model.DEFAULT === true && this.$scope.model.TRANSACTION && (this.$scope.model.TRANSACTION.ID == EPaymentNatureId.PAYMENT || this.$scope.model.TRANSACTION.ID == EPaymentNatureId.RECEIVING)) {
                    disabled = true;
                }
                if (!this.$scope.model.DEFAULT && (this.$scope.model.EXPORTERS || this.$scope.model.IMPORTERS)) {
                    disabled = true;
                }
            }

            if (field == 'EXPORTERS') {
                if (this.$scope.model.DEFAULT === true && this.$scope.model.TRANSACTION && (this.$scope.model.TRANSACTION.ID == EPaymentNatureId.PAYMENT || this.$scope.model.TRANSACTION.ID == EPaymentNatureId.RECEIVING)) {
                    disabled = true;
                }
                if (!this.$scope.model.DEFAULT && (this.$scope.model.ACCOUNTS || this.$scope.model.IMPORTERS)) {
                    disabled = true;
                }
            }

            if (field == 'IMPORTERS') {

                if (this.$scope.model.DEFAULT === true && this.$scope.model.TRANSACTION && (this.$scope.model.TRANSACTION.ID == EPaymentNatureId.PAYMENT || this.$scope.model.TRANSACTION.ID == EPaymentNatureId.RECEIVING)) {
                    disabled = true;
                }
                if (!this.$scope.model.DEFAULT && (this.$scope.model.ACCOUNTS || this.$scope.model.EXPORTERS)) {
                    disabled = true;
                }
            }
        }
        return disabled;
    }

    private checkSelectorValidity(): void {
        this.$timeout(() => {
            if (this.$scope.model.TABLE_FT_PD_DET) {
                for (let i = 0; i < this.$scope.model.TABLE_FT_PD_DET.length; i++) {
                    for (let j = 0; j < this.$scope.model.TABLE_FT_PD_DET[i].FREE_TIME_PER_DIEM.length; j++) {
                        this.$scope.selectorValidity(`tableFTPDDetPeriod${i}${j}`);
                        this.$scope.selectorValidity(`tableFTPDDetFrom${i}${j}`);
                        this.$scope.selectorValidity(`tableFTPDDetUntil${i}${j}`);
                        this.$scope.selectorValidity(`tableFTPDDetCurrency${i}${j}`);
                        this.$scope.selectorValidity(`tableFTPDDetPerDiem${i}${j}`);
                        this.$scope.selectorValidity(`tableFTPDDetImoPerDiem${i}${j}`);
                    }
                }
            }
            if (this.$scope.model.TABLE_FT_PD_DEM) {
                for (let i = 0; i < this.$scope.model.TABLE_FT_PD_DEM.length; i++) {
                    for (let j = 0; j < this.$scope.model.TABLE_FT_PD_DEM[i].FREE_TIME_PER_DIEM.length; j++) {
                        this.$scope.selectorValidity(`tableFTPDDemPeriod${i}${j}`);
                        this.$scope.selectorValidity(`tableFTPDDemFrom${i}${j}`);
                        this.$scope.selectorValidity(`tableFTPDDemUntil${i}${j}`);
                        this.$scope.selectorValidity(`tableFTPDDemCurrency${i}${j}`);
                        this.$scope.selectorValidity(`tableFTPDDemPerDiem${i}${j}`);
                        this.$scope.selectorValidity(`tableFTPDDemImoPerDiem${i}${j}`);
                    }
                }
            }

            this.$scope.model.PROVIDER = null;
            this.$scope.model.EQUIPMENT_PROVIDER = null;
            this.$scope.model.ID_EQUIPMENT_PROVIDER = null;
            this.$scope.model.ACCOUNTS = null;
            this.$scope.model.EXPORTERS = null;
            this.$scope.model.IMPORTERS = null;
        }, 100);
    }

    private async enableFreeTimeImo(): Promise<void> {
        if ((this.$scope.model.TABLE_IMO_FT_PD_DET && this.$scope.model.TABLE_IMO_FT_PD_DET.length > 0) || (this.$scope.model.TABLE_IMO_FT_PD_DEM && this.$scope.model.TABLE_IMO_FT_PD_DEM.length > 0)) {

            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: 'GENERAL.CONFIRM',
                closeButtonText: 'GENERAL.NO',
                headerText: 'GENERAL.CONFIRM_ACTION',
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL")
            });

            if (!modal) return;

            this.$scope.model.TABLE_IMO_FT_PD_DET = null;
            this.$scope.model.TABLE_IMO_FT_PD_DEM = null;
        }
    }

    private disableFreeTimeImo(): void {
        if (!this.$scope.model.TABLE_IMO_FT_PD_DET) this.$scope.model.TABLE_IMO_FT_PD_DET = [];
        if (!this.$scope.model.TABLE_IMO_FT_PD_DEM && this.$scope.model.FREE_TIME_IMO) this.$scope.model.TABLE_IMO_FT_PD_DEM = [];
        if (this.$scope.model.TABLE_FT_PD_DET && this.$scope.model.TABLE_FT_PD_DET.length > 0) {
            for (let i = 0; i <= this.$scope.model.TABLE_FT_PD_DET.length - 1; i++) {
                this.$scope.model.TABLE_IMO_FT_PD_DET[i] = null;
            }
        }
        if (this.$scope.model.TABLE_FT_PD_DEM && this.$scope.model.TABLE_FT_PD_DEM.length > 0 && this.$scope.model.FREE_TIME_IMO) {
            for (let i = 0; i <= this.$scope.model.TABLE_FT_PD_DEM.length - 1; i++) {
                this.$scope.model.TABLE_IMO_FT_PD_DEM[i] = null;
            }
        }
    }

    getFisFilesGenericRoute(): string {
        const baseRoute = '/fis/filesGeneric';
        const uploadRoute = this.config.fisUrl + baseRoute;
        return uploadRoute;
    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'tariff_files', formName: this.formName, folder: this.formName };
            return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData] });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private removeUpload(model: IUploadItem): boolean {
        let result = false;
        try {
            if (!model) throw Error('item parameter is null');

            const isAdminOrCoordinator = (this.$scope.user.roles && (this.$scope.user.roles.hasOwnProperty('ADMIN') || this.$scope.user.roles.hasOwnProperty('COORDINATOR')));

            if (isAdminOrCoordinator) {
                result = true;
            } else {
                // check if in model.FILES_NEW;
                if (this.$scope.model.FILES_NEW && this.$scope.model.FILES_NEW.length > 0) {
                    const index = this.$scope.model.FILES_NEW.findIndex(file => { return file.FILE_HASH == model.FILE_HASH });
                    if (index > -1) {
                        // Remove from FILES_NEW
                        this.$scope.model.FILES_NEW.splice(index, 1);
                        result = true;
                    }
                }
            }

            if (result) {
                if (!this.$scope.model.FILES_REMOVE) this.$scope.model.FILES_REMOVE = [];
                this.$scope.model.FILES_REMOVE.push(model);
            } else {
                throw Error('You don\'t have permission to remove this file');
            }
        } catch (ex) {
            result = false;
            this.handleError(ex);
        } finally {
            return result;
        }
    }

    private onSuccessItem(item: IFileItem, response: ISuccessItemResponse): void {
        try {
            if (!item) throw Error('item parameter is null');
            if (!response) throw Error('response parameter is null');

            if (response.data && response.data.length > 0) {
                if (!this.$scope.model.FILES_NEW) this.$scope.model.FILES_NEW = [];

                for (let dataItem of response.data) {
                    const file: IUploadItem = {
                        FILE_DISPLAY_NAME: dataItem.displayFileName,
                        FILE_NAME: dataItem.fileName,
                        FILE_TYPE: dataItem.fileType,
                        FILE_PATH: dataItem.filePath,
                        FILE_URL: dataItem.fileUrl,
                        FILE_HASH: dataItem.fileHash,
                        FILE_SIZE: dataItem.fileSize,
                        FORM_NAME: dataItem.formName
                    }
                    this.$scope.model.FILES_NEW.push(file)
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private initPanels = (panelsToShow?: string[], panelToNavigate?: string) => {
        try {
            const panels = document.getElementsByClassName("toggle-me");
            if (panels) {
                for (let i = 0; i < panels.length; i++) {
                    const panel = panels[i];
                    if (panelsToShow && panelsToShow.findIndex(obj => obj === panel.id) >= 0) {
                        if (!panel.classList.contains('in')) {
                            $("#" + panel.id)["collapse"]("show");
                        }
                    } else if (panel.classList.contains('in')) {
                        $("#" + panel.id)["collapse"]("hide");
                    }
                }
            }
            if (panelToNavigate) this.$scope.navigateBetweenIds(panelToNavigate);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }
}
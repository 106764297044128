import * as angular from 'angular';
import { IGridOptions } from "ui-grid";
import { GridFormService, IGridFormController, IGridFormServiceScope } from "@services/GridFormService";
import { IMonacoColumnDef } from "@services/GridService2";
import { OperationalService } from "@services/OperationalService";
import { ExternalService } from "@services/ExternalService";
import { IModalService } from "@services/ModalService";
import { ICustomLogProperties } from '@models/interface/common/IViewLog';
import { IVoyageProvider } from '@models/interface/operational/voyage/VoyageProvider';
import { GridColumnBuilder } from "../../common/GridColumnBuilder";
import { EOperation } from "@enums/GenericData";
import { IVoyage } from "WBA-Model/dist/interface/operational/voyage/Voyage";
import { ProductService } from "@services/ProductService";
import { IModalInstanceService } from "angular-ui-bootstrap";
import { IFormServiceScope } from "@services/FormService2";
import { IProcessParameter } from "src/ts/common/model/ModelParameter";
import { ISessionService } from "@services/SessionService";
import { IOfferProcess } from "src/ts/commercial/controller/OfferRegisterController";

export interface IProcessNumber {
    PROCESS_NUMBER: string;
}

interface IProcessModalScope extends IFormServiceScope {
    currentIndex: number;
    processList: IOfferProcess[];
    refreshModal: () => void;
    goToProcess: (processNumber: string) => void;
}

export interface IVoyageListScope extends IGridFormServiceScope {
    gridOptions: IGridOptions;
    form: ng.IFormController;
    customLogProperties: ICustomLogProperties[];
    sessionService: ISessionService;
    concatProviderToGrid: (PROVIDER: IVoyageProvider[]) => string;
    concatProcessNumberToGrid: (PROCESS_NUMBER: IProcessNumber[]) => string;
    countProcessQuantity: (PROCESS_NUMBER: IProcessNumber[]) => number;
    editVoyage: (voyageProvider: IVoyageProvider) => Promise<void>;
    viewVoyage: (voyageProvider: IVoyageProvider) => Promise<void>;
    processVoyage: (PROCESS_NUMBER: IProcessNumber[]) => Promise<void>;
    fetchData: (id: number, action: string) => Promise<void>;
}

export class VoyageListController extends GridFormService implements IGridFormController {
    static $inject: string[] = ["$injector", "$scope"];
    private $scope: IVoyageListScope;
    private $timeout: ng.ITimeoutService;
    private productService: ProductService;
    private operationalService: OperationalService;
    private externalService: ExternalService;
    private ModalService: IModalService;
    private modalID: number;

    constructor($injector: ng.Injectable<any>, $scope: IVoyageListScope) {
        super($injector, $scope);
        this.$scope = $scope;
        this.$timeout = $injector.get("$timeout");
        this.operationalService = $injector.get('OperationalService');
        this.ModalService = $injector.get('ModalService');
        this.externalService = $injector.get('ExternalService');
        this.productService = $injector.get('ProductService');
        this.$scope.sessionService = $injector.get('SessionService');
    }

    initScopeFunctions(): void {

        this.$scope.concatProviderToGrid = (PROVIDER: IVoyageProvider[]) => {
            return this.concatProviderToGrid(PROVIDER);
        }

        this.$scope.concatProcessNumberToGrid = (PROCESS_NUMBER: IProcessNumber[]) => {
            return this.concatProcessNumberToGrid(PROCESS_NUMBER);
        }

        this.$scope.countProcessQuantity = (PROCESS_NUMBER: IProcessNumber[]) => {
            return PROCESS_NUMBER.length;
        }

        this.$scope.editVoyage = async (voyageProvider: IVoyageProvider): Promise<void> => {
            this.openUpdateModel(voyageProvider, EOperation.NEW);
        }

        this.$scope.viewVoyage = async (voyageProvider: IVoyageProvider): Promise<void> => {
            this.openUpdateModel(voyageProvider, EOperation.VIEW);
        }
        this.$scope.processVoyage = async (PROCESS_NUMBER: IProcessNumber[]): Promise<void> => {
            this.openProcess(PROCESS_NUMBER)
        }
    }

    async $onInit(): Promise<void> {
        try {
            this.$baseUrl = this.operationalService.$route;
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.$rootScope.setBreadCrumb("GENERAL.MENU.VOYAGES", () => { this.openWizard(); });
            this.initForm(this, "form", "voyage", "GENERAL.MENU.BOOKING", false);
            await this.initGrid('gridVoyage', '/voyage/list', true, true, 120000, true, true);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    $onDestroy(): void {
        this.$rootScope.clearBreadCrumb();
        super.$onDestroy();
    }

    initGridColumns(columns: string[]): uiGrid.IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);
        const view = `<div class="text-center"><a ng-click="grid.appScope.viewVoyage(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.editVoyage(row.entity)" class="text-especial" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const process = `<a ng-disabled="!row.entity.VOYAGE_PROCESS.length" ng-click="row.entity.VOYAGE_PROCESS.length && grid.appScope.processVoyage(row.entity.VOYAGE_PROCESS)" class="text-green" tooltip-placement="auto top" uib-tooltip="{{'OPERATIONAL.FILE_NUMBER' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-file-text icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLog(row.entity, row.entity.VOYAGE._id)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        gridColumns.$columnDefs.push({
            name: "acoes",
            displayName: "GENERAL.ACTIONS",
            width: 140,
            cellTemplate: `<div class="text-center view-btn-action-bar">${view} ${edit} ${process} ${viewLog}</div>`,
            enableCellEdit: false,
            enableCellEditOnFocus: false,
            enableSorting: false,
            enableFiltering: false,
            enableColumnMenus: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            enableColumnMenu: false,
            enableGrouping: false,
            enablePinning: true,
            pinnedLeft: true
        });

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];
            const colVoyageNumber: IMonacoColumnDef = { name: "VOYAGE_NUMBER", displayName: "OPERATIONAL.VOYAGE", width: 150 };
            const colShip: IMonacoColumnDef = { name: "SHIP", displayName: "BASIC_DATA.VESSEL", width: 150 };
            const colPortOrigin: IMonacoColumnDef = { name: "PORT_ORIGIN.NAME", displayName: "REGISTRATION.PORT", width: 150 };
            const colDeparture: IMonacoColumnDef = { name: "FINAL_DEPARTURE_DATE.DATE", displayName: "OPERATIONAL.DEPARTURE", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm\'', cellTemplate: `<div class="wrapper-md" tooltip-placement="auto top" uib-tooltip="{{row.entity.FINAL_DEPARTURE_DATE.IS_ESTIMATED ? 'REGISTRATION.ESTIMATED_DATE' : 'OPERATIONAL.ACTUAL_DATE' | translate }}" tooltip-append-to-body="true" ng-class="{'text-blue text-italic' : row.entity.FINAL_DEPARTURE_DATE.IS_ESTIMATED == true}">{{row.entity.FINAL_DEPARTURE_DATE.DATE | date:'dd/MM/yyyy HH:mm'}}</div>` };
            const colBerthing: IMonacoColumnDef = { name: "FINAL_BERTHING_DATE.DATE", displayName: "OPERATIONAL.ARRIVAL", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm\'', cellTemplate: `<div class="wrapper-md" tooltip-placement="auto top" uib-tooltip="{{row.entity.FINAL_BERTHING_DATE.IS_ESTIMATED ? 'REGISTRATION.ESTIMATED_DATE' : 'OPERATIONAL.ACTUAL_DATE' | translate }}" tooltip-append-to-body="true" ng-class="{'text-blue text-italic' : row.entity.FINAL_BERTHING_DATE.IS_ESTIMATED == true}">{{row.entity.FINAL_BERTHING_DATE.DATE | date:'dd/MM/yyyy HH:mm'}}</div>` };
            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 150, cellFilter: "YesOrNo" };
            const colProvider: IMonacoColumnDef = { name: "VOYAGE_PROVIDER.CONCATENATED", displayName: "BASIC_DATA.PROVIDER", width: 150, cellTemplate: '<div class="grid-padding">{{grid.appScope.concatProviderToGrid(row.entity.VOYAGE_PROVIDER)}}</div>' };
            const colProcessNumber: IMonacoColumnDef = { name: "VOYAGE_PROCESS.PROCESS_NUMBER", displayName: "GENERAL.PROCESS_NUMBER", width: 150, cellTemplate: '<div class="grid-padding">{{grid.appScope.concatProcessNumberToGrid(row.entity.VOYAGE_PROCESS)}}</div>' };
            const colProcessQuantity: IMonacoColumnDef = { name: "PROCESS_QUANTITY", displayName: "OPERATIONAL.FILES_QUANTITY", width: 150, cellTemplate: '<div class="grid-padding">{{grid.appScope.countProcessQuantity(row.entity.VOYAGE_PROCESS)}}</div>' };
            const colTeus: IMonacoColumnDef = { name: "TEUS", displayName: "GENERAL.TEUS", width: 150 };
            const colDataOrigin: IMonacoColumnDef = { name: "DATA_ORIGIN.NAME", displayName: "GENERAL.DATA_ORIGIN", width: 150 };
            const colLastUpdate: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.LAST_UPDATE", width: 150, cellFilter: 'date:\'dd/MM/yyyy\'' };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 150 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'PROVIDER':
                        columnDefs.push(colProvider);
                        break;
                    case 'PROCESS_NUMBER':
                        columnDefs.push(colProcessNumber);
                        break;
                    case 'PROCESS_QUANTITY':
                        columnDefs.push(colProcessQuantity);
                        break;
                    case 'TEUS':
                        columnDefs.push(colTeus);
                        break;
                    case 'DATA_ORIGIN':
                        columnDefs.push(colDataOrigin);
                        break;
                    case 'SHIP':
                        columnDefs.push(colShip);
                        break;
                    case 'PORT_ORIGIN':
                        columnDefs.push(colPortOrigin);
                        break;
                    case 'VOYAGE_NUMBER':
                        columnDefs.push(colVoyageNumber);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'FINAL_DEPARTURE_DATE':
                        columnDefs.push(colDeparture);
                        break;
                    case 'FINAL_BERTHING_DATE':
                        columnDefs.push(colBerthing);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colLastUpdate);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                };
            }
            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {

    }

    private concatProviderToGrid = (PROVIDER: IVoyageProvider[]): string => {
        try {
            let providerConcatenated: string = "";
            if (PROVIDER) {
                PROVIDER.forEach(provider => {
                    if (providerConcatenated.length) providerConcatenated += ", ";
                    providerConcatenated += provider.PROVIDER ? `${provider.PROVIDER.NAME} ${provider.VOYAGE_NUMBER}` : '';
                });
            }
            return providerConcatenated;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private concatProcessNumberToGrid = (VOYAGE_PROCESS: IProcessNumber[]): string => {
        try {
            let processNumberConcatenated: string = "";
            if (VOYAGE_PROCESS) {
                VOYAGE_PROCESS.forEach(processNumber => {
                    if (processNumberConcatenated.length) processNumberConcatenated += ", ";
                    processNumberConcatenated += processNumber.PROCESS_NUMBER;
                });
            }
            return processNumberConcatenated;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async openWizard(): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    template: require("../view/modal/voyageInsertModal.html"),
                    modalID: this.modalID,
                    formService: EOperation.NEW,
                    size: 'lg',
                },
                {
                    actionButtonClass: 'btn-default',
                    headerText: "OPERATIONAL.VOYAGES",
                    actionButtonText: "REGISTRATION.CONFIRM",
                    closeButtonText: 'GENERAL.CLOSE'
                },
                {
                    voyageModalId: this.modalID
                }
            );

            const modalScope = await this.ModalService.getModalScope(this.modalID);
            await modalScope.$applyAsync();

            const apply = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });


            if (apply) {
                await this.updateGrid();
            }

        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async openUpdateModel(voyageProvider: IVoyageProvider, operation: string): Promise<void> {
        try {

            const modelVoyage = await this.getVoyageById(voyageProvider.VOYAGE_ID);

            this.modalID = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    template: require("../view/modal/voyageUpdateModal.html"),
                    modalID: this.modalID,
                    formService: operation,
                    size: 'full',
                },
                {
                    actionButtonClass: 'btn-default',
                    headerText: "OPERATIONAL.VOYAGES",
                    actionButtonText: "REGISTRATION.CONFIRM",
                    closeButtonText: 'GENERAL.CLOSE'
                },
                {
                    voyageModalId: this.modalID,
                    voyageId: voyageProvider.VOYAGE_ID,
                    model: {
                        BASIC_DATA: modelVoyage,
                        VOYAGE_PROVIDER: null,
                        VOYAGE_STOPOVER: null,
                        VOYAGE_DEADLINE: null,
                    },
                    isViewMode: operation == EOperation.VIEW ? true : false
                }
            );

            const modalScope = await this.ModalService.getModalScope(this.modalID);
            await modalScope.$applyAsync();

            const apply = await modalInstance.result.then(function (result) {
                return result.$value;
            }, function (result) {
                return result.$value;
            });

            if (apply) {
                await this.updateGrid();
            }

        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            { PROPERTY: "ACTIVE", LABEL: "GENERAL.ACTIVE" },
            { PROPERTY: "CREATED_AT", LABEL: "GENERAL.CREATED_AT" },
            { PROPERTY: "CREATED_BY", LABEL: "GENERAL.CREATED_BY" },
            { PROPERTY: "UPDATED_AT", LABEL: "GENERAL.UPDATED_AT" },
            { PROPERTY: "UPDATED_BY", LABEL: "GENERAL.UPDATED_BY" },
            { PROPERTY: "DATA_ORIGIN", LABEL: "GENERAL.DATA_ORIGIN" },
            { PROPERTY: "FORECAST_DATE", LABEL: "OPERATIONAL.WAITING_FORECAST_DATE" },
            { PROPERTY: "PORT_ORIGIN", LABEL: "BASIC_DATA.ORIGIN_PORT" },
            { PROPERTY: "MARITIME_SERVICE", LABEL: "BASIC_DATA.MARITIME_SERVICE" },
            { PROPERTY: "CARRIER_INFORMATION", LABEL: "GENERAL.CARRIER_INFO" },
            { PROPERTY: "VOYAGE_NUMBER", LABEL: "GENERAL.NUMBER" },
            { PROPERTY: "PROVIDER", LABEL: "BASIC_DATA.PROVIDER" },
            { PROPERTY: "SHIP", LABEL: "GENERAL.MENU.SHIP" },
            { PROPERTY: "TEUS", LABEL: "GENERAL.TEUS" },
            { PROPERTY: "DISPLAY_NAME", LABEL: "GENERAL.DISPLAY_NAME" },
            { PROPERTY: "NAME", LABEL: "GENERAL.NAME" },
            { PROPERTY: "CODE", LABEL: "GENERAL.CODE" },
            { PROPERTY: "FINAL_BERTHING_DATE", LABEL: "OPERATIONAL.ARRIVAL" },
            { PROPERTY: "DATE", LABEL: "GENERAL.DATE" },
            { PROPERTY: "IS_ESTIMATED", LABEL: "GENERAL.ESTIMATED" },
            { PROPERTY: "FINAL_DEPARTURE_DATE", LABEL: "OPERATIONAL.LOAD_EVENT" },
            { PROPERTY: "FINAL_SAILING_DATE", LABEL: "OPERATIONAL.DEPARTURE" },
            { PROPERTY: "BASIC_DATA", LABEL: "GENERAL.BASIC_DATA" },
            { PROPERTY: "VOYAGE_PROVIDER", LABEL: "BASIC_DATA.PROVIDER" },
            { PROPERTY: "VOYAGE_STOPOVER", LABEL: "GENERAL.MENU.VOYAGE_STOPOVER" },
            { PROPERTY: "VOYAGE_DEADLINE", LABEL: "OPERATIONAL.DEADLINES" },
            { PROPERTY: "TYPE", LABEL: "GENERAL.TYPE" },
            { PROPERTY: "RELEASE", LABEL: "OPERATIONAL.RELEASE_DATE" },
            { PROPERTY: "FINAL_ARRIVAL_DATE", LABEL: "OPERATIONAL.ARRIVAL" },
            { PROPERTY: "BASE_DATE", LABEL: "GENERAL.DATE" },
            { PROPERTY: "PROVIDER_DEADLINE", LABEL: "BASIC_DATA.PROVIDER" },
        ];
        return props;
    }

    private async getVoyageById(voyageId: number): Promise<IVoyage> {
        let voyage: IVoyage = null;
        try {
            this.block();
            const result = await this.operationalService.get<any>(`/voyage/getById/${voyageId}`, null);
            if (result && result.data && result.data.data) voyage = result.data.data;
        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.unblock();
            return voyage;
        }
    }

    private async openProcess(PROCESS_NUMBER: IProcessNumber[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const modalInstance: IModalInstanceService = await this.ModalService.showModalInfo(
                {
                    template: require("../view/voyageProcessModal.html"),
                    modalID: this.modalID,
                    scope: this.$scope,
                    formService: EOperation.VIEW,
                    size: 'lg modal-overflow',
                },
                {
                    actionButtonClass: 'btn-default',
                    headerText: "OPERATIONAL.VOYAGES",
                    actionButtonText: "REGISTRATION.CONFIRM",
                    closeButtonText: 'GENERAL.CLOSE'
                }
            );

            modalInstance.rendered.then(async () => {
                const modalScope: IProcessModalScope = await this.ModalService.getModalScope(this.modalID);
                this.formService.block();

                if (PROCESS_NUMBER.length) {
                    modalScope.processList = await this.getProcessList(PROCESS_NUMBER);
                }

                modalScope.goToProcess = (processNumber: string): void => {
                    if (!processNumber || (processNumber && processNumber == '')) throw Error('processNumber is null');
                    this.$scope.sessionService.openTab("app.operational.newProcess.list", <IProcessParameter>{ PROCESS_NUMBER: processNumber });
                }

                modalScope.refreshModal = async (): Promise<void> => {
                    try {
                        this.formService.block();
                        const modalScope: IProcessModalScope = await this.ModalService.getModalScope(this.modalID);

                        setTimeout(async () => {
                            const requestList = await this.getProcessList(PROCESS_NUMBER);
                            if (requestList) modalScope.processList = angular.copy(requestList);
                            this.formService.unblock();
                        }, 1000);
                    } catch (ex) {
                        this.formService.unblock();
                        this.formService.handleError(ex);
                    }
                }

                modalScope.$applyAsync();
            });

        } catch (ex) {
            this.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getProcessList(PROCESS_NUMBER: IProcessNumber[]): Promise<IOfferProcess[]> {
        try {
            const request = {
                query: { "PROCESS_NUMBER": { $in: PROCESS_NUMBER.map(item => item.PROCESS_NUMBER) } },
                project: { FORWARDING_SITUATION: 1, SITUATION: 1, PROCESS_NUMBER: 1, _id: 0, },
            };

            const result = await this.operationalService.post("/process/query", request, 120000);
            if (result && result.data && result.data.data) return result.data.data;

        } catch (ex) {
            this.formService.handleError(ex);
        }
    }
}

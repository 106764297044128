import * as angular from "angular";
import * as moment from 'moment';
import { isEmptyObject } from "jquery";
import { fileUploader as FileUploader } from 'angular-file-upload';
import { IColumnDef, IGridOptions } from "ui-grid";
import { SSEService } from '@appServices/SSEService';
import { IGridFormController, IGridFormServiceScope, GridFormService, IMonacoRequestLog } from "@services/GridFormService";
import { IRestService } from "@services/RestService";
import { IMonacoColumnDef } from "@services/GridService2";
import { IModalService } from '@services/ModalService';
import { ISessionService } from "@services/SessionService";
import { ICustomLogProperties } from "@models/interface/common/IViewLog";
import { IParams, IChargeNameList } from "@models/interface/product/ChargeNameModel";
import { IApplicationList } from "@models/interface/product/ApplicationModel";
import { IProviderModel, IProviderSelector } from "@models/interface/product/ProviderModel";
import { IDomesticServiceModel } from "@models/interface/product/DomesticServiceModel";
import { IUploader, IUploadItem, IFileItem, IFormData, ISuccessItemResponse } from "@models/interface/common/IMonacoUpload";
import { IEquipmentSelector } from "@models/interface/product/EquipmentModel";
import { EDomesticProviderType, EProviderTypeId, EDirectionId, EApplicationComplementId, EProductId, EOperation, ETypeLocalId, EEventType } from "@enums/GenericData";
import { ITariffDomesticModel, IMainLeg, IChargeMainLeg, ISecondaryLeg, IChargeSecondaryLeg, ITariffDomesticExchangeData } from "@models/interface/product/TariffDomesticModel";
import { GridColumnBuilder, GridColumnBuilderConstants } from "../../common/GridColumnBuilder";
import { SelectorModel } from "../../common/model/SelectorModel";
import { BrowserTitle } from "../../common/BrowserTitle";
import { ILinkParameter, IOfferParameter } from "../../common/model/ModelParameter";
import { StringUtil } from "../../common/util/StringUtil";
import { IFloatingMenu } from "../../common/interface/IFloatingMenu";
import { ValidateUtil } from "../../common/util/ValidateUtil";
import { IDocumentError } from '@models/interface/common/IDocumentError';
import { HelperService } from "@services/HelperService";

interface IChargeColStyle {
    charge: Object;
    application: Object;
    flat: Object;
    weightEquipment: Object;
    currency: Object;
    paymentUnitary: Object;
    paymentMin: Object;
    receivingUnitary: Object;
    receivingMin: Object;
    noCharge: Object;
}

interface ISecondaryLegChargeColStyle {
    charge: Object;
    application: Object;
    flat: Object;
    weightEquipment: Object;
    currency: Object;
    paymentUnitary: Object;
    paymentMin: Object;
    receivingUnitary: Object;
    receivingMin: Object;
    secondaryOperator: Object;
    noCharge: Object;
}

interface IAuxModel {
    direction: SelectorModel;
    product: SelectorModel;
}

export interface IUsedOfferModal extends ng.IScope {
    tariffDomestic: ITariffDomesticModel;
    usedOfferList: IUsedOffer[];
    goToOffer: (offerNumber: string) => void;
}

export interface IUsedOffer {
    CODE: string;
    VALIDITY_START: any;
    VALIDITY_END: any;
    SITUATION: string;
}

interface ITariffDomesticScope extends IGridFormServiceScope {
    form: ng.IFormController;
    gridOptions: IGridOptions;
    model: ITariffDomesticModel;
    customLogProperties: ICustomLogProperties[];
    scopeBeforeSave: ITariffDomesticModel;
    productList: SelectorModel[];
    accountList: SelectorModel[];
    typeCargoList: SelectorModel[];
    paymentNatureList: SelectorModel[];
    directionList: SelectorModel[];
    routingPointList: SelectorModel[];
    providerList: SelectorModel[];
    logisticSolutionList: SelectorModel[];
    agentList: SelectorModel[];
    chargeNameList: IChargeNameList[];
    chargeApplicationList: SelectorModel[];
    currencyList: SelectorModel[];
    equipmentList: IEquipmentSelector[];
    situationList: SelectorModel[];
    weightRangeList: SelectorModel[];
    operatorTypeList: SelectorModel[];
    modalList: SelectorModel[];
    originDestinationTypeList: SelectorModel[];
    vehicleTypeList: SelectorModel[];
    tariffFreightContract: SelectorModel[];
    eventList: SelectorModel[];
    uploader: IUploader;
    user: any;
    menuFloating: IFloatingMenu;
    chargeColStyle: IChargeColStyle;
    secondLegChargeColStyle: ISecondaryLegChargeColStyle;
    auxModel: IAuxModel;
    sessionService: ISessionService;
    isAirProduct: boolean;
    isMaritimeProduct: boolean;
    openModalIntegration: (id: number, documentError: IDocumentError[]) => void;
    customEdit: (tariffDomestic: ITariffDomesticModel) => void;
    viewTariffDomestic: (tariffDomestic: ITariffDomesticModel) => Promise<void>;
    viewLogTariffDomestic: (tariffDomestic: ITariffDomesticModel) => Promise<void>;
    copyTariffDomestic: (tariffDomestic: ITariffDomesticModel) => Promise<void>;
    removeUpload: (model: IUploadItem) => boolean;
    addMainLeg: (navigate?: boolean) => void;
    removeMainLeg: (index: number) => void;
    duplicateMainLeg: (index: number) => void;
    addCharge: (parentIndex: number) => void;
    removeCharge: (parentIndex: number, index: number) => void;
    duplicateCharge: (parentIndex: number, index: number) => void;
    isChargeAirFieldsDisabled(chargeList: IChargeMainLeg[], chargeIndex: number, application: IApplicationList, isReceivingField?: boolean, noCharge?: boolean): boolean;
    isChargeReceivingMinDisabled(chargeList: IChargeMainLeg[], chargeIndex: number, application: IApplicationList, noCharge: boolean): boolean;
    chargeAirFieldsChange(mainLegIndex: number, chargeIndex: number, isCurrencyField?: boolean, isPaymentField?: boolean, isReceivingField?: boolean): void;
    generateChargeToAvailableWeightRange: (mainLegIndex: number, chargeIndex: number, secondaryLegIndex: number) => void;
    flatChange: (mainLegIndex: number, chargeIndex: number, secondaryLegIndex: number) => void;
    addSecondaryLeg: (parentIndex: number) => void;
    removeSecondaryLeg: (parentIndex: number, index: number) => void;
    duplicateSecondaryLeg: (parentIndex: number, index: number) => void;
    addSecondaryLegCharge: (parentIndex: number, index: number) => void;
    removeSecondaryLegCharge: (parentIndex: number, childIndex: number, index: number) => void;
    duplicateSecondaryLegCharge: (parentIndex: number, childIndex: number, index: number) => void;
    isSecondaryLegChargeAirFieldsDisabled(chargeList: IChargeMainLeg[], chargeIndex: number, application: IApplicationList, isReceivingField?: boolean, noCharge?: boolean): boolean;
    secondaryLegChargeAirFieldsChange(mainLegIndex: number, secondaryLegIndex: number, chargeIndex: number, isCurrencyField?: boolean, isPaymentField?: boolean, isReceivingField?: boolean): void;
    getGridViewProvider: (provider: IProviderSelector) => string;
    getRoutingPointListByName: (parentIndex: number, search: string, type: string) => Promise<void>;
    getSecondaryLegRoutingPointListByName: (parentIndex: number, childIndex: number, search: string, type: string) => Promise<void>;
    getProviderListByName: (search: string, operatorType: SelectorModel) => Promise<void>;
    getLogisticSolutionListByName: (search: string) => Promise<void>;
    getAgentListByName: (search: string, operatorType: SelectorModel) => Promise<void>;
    getChargeNameListByName: (search: string) => Promise<void>;
    getAccountListByName: (search: string) => Promise<void>;
    getCurrencyListByName: (search: string) => Promise<void>;
    getEquipmentListByName: (search: string) => Promise<void>;
    getWeightRangeListByName: (search: string) => Promise<void>;
    goToRoutingPoint: (routingPoints: SelectorModel[]) => void;
    goToAccount: () => void;
    goToProvider: (id: number) => void;
    goToLogisticSolution: (id: number) => void;
    goToAgent: (id: number) => void;
    updateParam: (selected: IChargeNameList, parentIndex: number, index: number) => void;
    updateParamSecondaryLeg: (selected: IChargeNameList, parentIndex: number, childIndex: number, index: number) => void;
    updateProduct: (selected: SelectorModel) => Promise<void>;
    updateOperatorType: () => void;
    updateOperator: () => void;
    updateCargoType: () => void;
    limitLines: (value: string, limit: number) => string;
    formatConcatenatedChars: (value: string) => string;
    collapseHeader: (elementId: string, state?: string, navigate?: boolean) => void;
    collapseMainLeg: (mainLegIndex: number) => void;
    generateConcatenated: () => void;
    generateConcatenatedLeg: (parentIndex: number) => void;
    generateAllConcatenatedLegs: () => void;
    updatePaymentMin: (parentIndex: number, index: number) => void;
    updateReceivingMin: (parentIndex: number, index: number) => void;
    updatePaymentMinSecondaryLeg: (parentIndex: number, childIndex: number, index: number) => void;
    updateReceivingMinSecondaryLeg: (parentIndex: number, childIndex: number, index: number) => void;
    filterOriginType: () => SelectorModel[];
    filterDestinationType: (parentIndex: number) => SelectorModel[];
    showSecondaryLeg: (parentIndex: number) => boolean;
    updateDirection: (selected: SelectorModel) => Promise<void>;
    getChargeApplicationComplement: (tariffIndex: number, chargeIndex: number) => string;
    getSecondaryLegChargeApplicationComplement: (tariffIndex: number, secondaryLegIndex: number, chargeIndex: number) => string;
    checkDateValidity: (initialDate: Date, finalDate: Date) => void;
    openTariffUsedOffer: (tariffDomestic: ITariffDomesticModel) => Promise<void>;
    setCopy: (value: boolean) => void;
    noChargeChange: (parentIndex: number, childIndex: number, index: number, value: boolean) => void;
    fetchData: (id: number, action: string) => Promise<void>;
    freightBasisMainLegChange: (parentIndex: number) => void;
    freightBasisSecondaryLegChange: (mainLegIndex: number, childIndex: number) => void;
    checkMainLegValidityEvent: (mainLegIndex: number) => void;
    checkSecondaryLegValidityEvent: (mainLegIndex: number, childIndex: number) => void;

}

export class TariffDomesticRegisterController extends GridFormService implements IGridFormController {
    static $inject: string[] = ['$injector', '$scope'];
    private $scope: ITariffDomesticScope;
    private $q: ng.IQService;
    private RestService: IRestService;
    private $timeout: ng.ITimeoutService
    private ModalService: IModalService;
    private fileUploader: FileUploader;
    private formName: string;
    private gridName: string;
    private collapseState: string;
    private modalFormatedDataId: number;
    private SSEService: SSEService;
    private isCopy: boolean;
    private modalID: number;
    private helperService: HelperService;

    constructor($injector: ng.Injectable<any>, $scope: ITariffDomesticScope) {
        super($injector, $scope);
        this.formName = 'tariffDomestic';
        this.gridName = 'GRID_TARIFF_DOMESTIC';
        this.$scope = $scope;
        this.$scope.additionalIndexSelectorValidity = ['$childIndex', '$parentIndex'];
        this.$q = $injector.get('$q');
        this.RestService = $injector.get('RestService');
        this.$timeout = $injector.get('$timeout');
        this.ModalService = $injector.get('ModalService');
        this.$scope.sessionService = $injector.get('SessionService');
        this.fileUploader = $injector.get('FileUploader');
        this.collapseState = "hide";
        this.SSEService = new SSEService($injector, $scope, this.formService);
        this.modalID = null;
        this.helperService = $injector.get('HelperService');
    }

    getUrlProduct(): string {
        const baseRoute = '/product';
        const urlProduct = this.config.productUrl + baseRoute;
        return urlProduct;
    }

    getFisFilesGenericRoute(): string {
        const baseRoute = '/fis/filesGeneric';
        const uploadRoute = this.config.fisUrl + baseRoute;
        return uploadRoute;
    }

    async $onInit(): Promise<void> {
        try {
            this.$scope.uploader = this.getFileUploaderDefault();
            this.$baseUrl = this.getUrlProduct();
            this.$scope.customLogProperties = this.getCustomLogProperties();
            this.$scope.menuFloating = this.getMenuFloatingDefault();

            this.initForm(this, 'form', this.formName, 'GENERAL.MENU.TARIFF_DOMESTIC', true);
            await this.initGrid(this.gridName, '/tariffDomestic/list', true, true, null, true, true);
            const sessionParameter = this.$gridService.$sessionParameter;
            this.SSEService.closeEvents();
            if (sessionParameter && sessionParameter.data) await this.callSessionFunctions(sessionParameter.data);
            this.setCopy(false);
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    initDependencies(): Promise<any> {
        const self: TariffDomesticRegisterController = this;
        return new Promise(function (resolve, reject) {
            self.initDateFields();
            self.initEvents();
            self.setChargeColStyle();
            self.setSecondaryLegChargeColStyle();
            self.$q.all([
                self.getProductList(),
                self.getDirectionList(),
                self.getSituationList(),
                self.getDomesticProviderTypeList(),
                self.getModalList(),
                self.getOriginDestinationTypeList(),
                self.getTypeCargoList(),
                self.getVehicleTypeList(),
                self.getEventList(),
            ]).then((result: any) => {
                self.$scope.productList = self.filterProductList(result[0]);
                self.$scope.directionList = result[1];
                self.$scope.situationList = result[2];
                self.$scope.operatorTypeList = result[3];
                self.$scope.modalList = result[4];
                self.$scope.originDestinationTypeList = result[5];
                self.$scope.typeCargoList = result[6];
                self.$scope.vehicleTypeList = result[7];
                self.$scope.eventList = result[8];

                if (self.$scope.situationList && self.$scope.situationList.length > 0 && !self.$scope.model.SITUATION) {
                    self.$scope.model.SITUATION = self.$scope.situationList.find(item => item.ID = '1');
                }
                resolve(true);
            }).catch(ex => {
                reject(ex);
            });
        });
    }

    $onDestroy(): void {
        this.SSEService.closeEvents();
        super.$onDestroy();
    }

    initScopeFunctions(): void {
        this.$scope.customEdit = (tariffDomestic: ITariffDomesticModel): void => {
            this.editTariffDomestic(tariffDomestic);
        }

        this.$scope.viewTariffDomestic = async (tariffDomestic: ITariffDomesticModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.fetchData(tariffDomestic.ID, EOperation.VIEW);
        }

        this.$scope.viewLogTariffDomestic = async (tariffDomestic: ITariffDomesticModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.viewLog(tariffDomestic);
        }

        this.$scope.copyTariffDomestic = async (tariffDomestic: ITariffDomesticModel): Promise<void> => {
            this.SSEService.closeEvents();
            this.$scope.copy(tariffDomestic);
            this.$scope.fetchData(tariffDomestic.ID, EOperation.COPY);
        }

        this.$scope.openModalIntegration = (id: number, documentError: IDocumentError[]) => {
            this.openModalIntegration(id, documentError);
        }

        this.$scope.addMainLeg = (navigate?: boolean): void => {
            this.addMainLeg(navigate);
        }
        this.$scope.removeMainLeg = (index: number): void => {
            this.removeMainLeg(index);
        }
        this.$scope.duplicateMainLeg = (index: number) => {
            this.duplicateMainLeg(index);
        }
        this.$scope.addCharge = (parentIndex: number): void => {
            this.addCharge(parentIndex);
        }
        this.$scope.removeCharge = (parentIndex: number, index: number): void => {
            this.removeCharge(parentIndex, index);
        }
        this.$scope.duplicateCharge = (parentIndex: number, index: number): void => {
            this.duplicateCharge(parentIndex, index);
        }
        this.$scope.isChargeAirFieldsDisabled = (chargeList: IChargeMainLeg[], chargeIndex: number, application: IApplicationList, isReceivingField?: boolean, noCharge?: boolean): boolean => {
            return this.isChargeAirFieldsDisabled(chargeList, chargeIndex, application, isReceivingField, noCharge);
        }
        this.$scope.chargeAirFieldsChange = (mainLegIndex: number, chargeIndex: number, isCurrencyField?: boolean, isPaymentField?: boolean, isReceivingField?: boolean): void => {
            this.chargeAirFieldsChange(mainLegIndex, chargeIndex, isCurrencyField, isPaymentField, isReceivingField);
        }
        this.$scope.generateChargeToAvailableWeightRange = (mainLegIndex: number, chargeIndex: number, secondaryLegIndex: number) => {
            this.generateChargeToAvailableWeightRange(mainLegIndex, chargeIndex, secondaryLegIndex);
        }
        this.$scope.flatChange = (mainLegIndex: number, chargeIndex: number, secondaryLegIndex: number) => {
            this.flatChange(mainLegIndex, chargeIndex, secondaryLegIndex);
        }
        this.$scope.addSecondaryLeg = (parentIndex: number): void => {
            this.addSecondaryLeg(parentIndex);
        }
        this.$scope.removeSecondaryLeg = (parentIndex: number, index: number): void => {
            this.removeSecondaryLeg(parentIndex, index);
        }
        this.$scope.duplicateSecondaryLeg = (parentIndex: number, index: number): void => {
            this.duplicateSecondaryLeg(parentIndex, index);
        }
        this.$scope.addSecondaryLegCharge = (parentIndex: number, childIndex: number): void => {
            this.addSecondaryLegCharge(parentIndex, childIndex);
        }
        this.$scope.removeSecondaryLegCharge = (parentIndex: number, childIndex: number, index: number): void => {
            this.removeSecondaryLegCharge(parentIndex, childIndex, index);
        }
        this.$scope.duplicateSecondaryLegCharge = (parentIndex: number, childIndex: number, index: number): void => {
            this.duplicateSecondaryLegCharge(parentIndex, childIndex, index);
        }
        this.$scope.isSecondaryLegChargeAirFieldsDisabled = (chargeList: IChargeMainLeg[], chargeIndex: number, application: IApplicationList, isReceivingField?: boolean, noCharge?: boolean): boolean => {
            return this.isSecondaryLegChargeAirFieldsDisabled(chargeList, chargeIndex, application, isReceivingField, noCharge);
        }
        this.$scope.secondaryLegChargeAirFieldsChange = (mainLegIndex: number, secondaryLegIndex: number, chargeIndex: number, isCurrencyField?: boolean, isPaymentField?: boolean, isReceivingField?: boolean) => {
            this.secondaryLegChargeAirFieldsChange(mainLegIndex, secondaryLegIndex, chargeIndex, isCurrencyField, isPaymentField, isReceivingField);
        }
        this.$scope.goToProvider = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.provider", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.$scope.goToLogisticSolution = (id: number): void => {
            this.$scope.sessionService.openTab("app.product.domesticService", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.$scope.goToAgent = (id: number): void => {
            this.$scope.sessionService.openTab("app.registration.agent", <ILinkParameter>{ ID: id ? id.toString() : id });
        }
        this.$scope.goToRoutingPoint = (routingPoints: SelectorModel[]): void => {
            this.$scope.sessionService.openTab("app.registration.routingPoint", <ILinkParameter>{ ID: this.$scope.getCONCAT(routingPoints, null, 'ID') });
        }
        this.$scope.goToAccount = () => {
            this.$scope.sessionService.openTab("app.commercial.account", <ILinkParameter>{ ID: this.$scope.getCONCAT(this.$scope.model.ACCOUNTS, null, 'ID') });
        }
        this.$scope.getGridViewProvider = (provider: IProviderSelector): string => {
            let gridValue = "";
            if (provider) {
                if (provider.TYPE && (provider.TYPE.ID == EProviderTypeId.SHIPOWNER || provider.TYPE.ID == EProviderTypeId.AIRLINE)) gridValue = provider.CODE;
                else if (provider.TYPE && provider.TYPE.ID == EProviderTypeId.SHIPPING_COMPANY) gridValue = provider.NAME;
                else gridValue = provider.NAME;
            }
            return gridValue;
        }
        this.$scope.getRoutingPointListByName = async (parentIndex: number, search: string, type: string): Promise<void> => {
            return await this.getRoutingPointListByName(parentIndex, search, type);
        }
        this.$scope.getSecondaryLegRoutingPointListByName = async (parentIndex: number, childIndex: number, search: string, type: string): Promise<void> => {
            return await this.getSecondaryLegRoutingPointListByName(parentIndex, childIndex, search, type);
        }
        this.$scope.getProviderListByName = async (search: string, operatorType: SelectorModel): Promise<void> => {
            return await this.getProviderListByName(search, operatorType);
        }
        this.$scope.getLogisticSolutionListByName = async (search: string): Promise<void> => {
            return await this.getLogisticSolutionListByName(search);
        }
        this.$scope.getAgentListByName = async (search: string, operatorType: SelectorModel): Promise<void> => {
            return await this.getAgentListByName(search, operatorType);
        }
        this.$scope.getChargeNameListByName = async (search: string): Promise<void> => {
            return await this.getChargeNameListByName(search);
        }
        this.$scope.getAccountListByName = async (search: string): Promise<void> => {
            return await this.getAccountListByName(search);
        }
        this.$scope.getCurrencyListByName = async (search: string): Promise<void> => {
            return await this.getCurrencyListByName(search);
        }
        this.$scope.getEquipmentListByName = async (search: string): Promise<void> => {
            return await this.getEquipmentListByName(search);
        }
        this.$scope.getWeightRangeListByName = async (search: string): Promise<void> => {
            this.$scope.weightRangeList = await this.getWeightRangeListByName(search);
        }
        this.$scope.updateParam = (selected: IChargeNameList, parentIndex: number, index: number): void => {
            this.updateParam(selected, parentIndex, index);
        }
        this.$scope.updateParamSecondaryLeg = (selected: IChargeNameList, parentIndex: number, childIndex: number, index: number): void => {
            this.updateParamSecondaryLeg(selected, parentIndex, childIndex, index);
        }
        this.$scope.updateOperatorType = (): void => {
            this.updateOperatorType();
        }
        this.$scope.updateOperator = (): void => {
            this.updateOperator();
        }
        this.$scope.updateCargoType = (): void => {
            this.updateCargoType();
        }
        this.$scope.limitLines = (value: string, limit: number) => {
            return StringUtil.limitLines(value, limit);
        }
        this.$scope.formatConcatenatedChars = (value: string) => {
            return StringUtil.formatConcatenatedChars(value);
        }
        this.$scope.removeUpload = (model: IUploadItem): boolean => {
            return this.removeUpload(model);
        }
        this.$scope.uploader.onSuccessItem = (item: IFileItem, response: ISuccessItemResponse): void => {
            this.onSuccessItem(item, response);
        }
        this.$scope.collapseHeader = (elementId: string, state?: string, navigate?: boolean): void => {
            this.collapseHeader(elementId, state, navigate);
        }
        this.$scope.collapseMainLeg = (mainLegIndex: number): void => {
            this.collapseMainLeg(mainLegIndex);
        }
        this.$scope.generateConcatenated = (): void => {
            this.generateConcatenated();
        }
        this.$scope.generateConcatenatedLeg = (parentIndex: number): void => {
            this.generateConcatenatedLeg(parentIndex);
        }
        this.$scope.generateAllConcatenatedLegs = (): void => {
            this.generateAllConcatenatedLegs();
        }
        this.$scope.updatePaymentMin = (parentIndex: number, index: number): void => {
            this.updatePaymentMin(parentIndex, index);
        }
        this.$scope.updateReceivingMin = (parentIndex: number, index: number): void => {
            this.updateReceivingMin(parentIndex, index);
        }
        this.$scope.updatePaymentMinSecondaryLeg = (parentIndex: number, childIndex: number, index: number): void => {
            this.updatePaymentMinSecondaryLeg(parentIndex, childIndex, index);
        }
        this.$scope.updateReceivingMinSecondaryLeg = (parentIndex: number, childIndex: number, index: number): void => {
            this.updateReceivingMinSecondaryLeg(parentIndex, childIndex, index);
        }
        this.$scope.filterOriginType = (): SelectorModel[] => {
            return this.filterOriginType();
        }
        this.$scope.filterDestinationType = (parentIndex: number): SelectorModel[] => {
            return this.filterDestinationType(parentIndex);
        }
        this.$scope.showSecondaryLeg = (parentIndex: number): boolean => {
            return this.showSecondaryLeg(parentIndex);
        }
        this.$scope.updateDirection = async (selected: SelectorModel): Promise<void> => {
            this.updateDirection(selected);
        }
        this.$scope.updateProduct = async (selected: SelectorModel): Promise<void> => {
            await this.updateProduct(selected);
        }
        this.$scope.getChargeApplicationComplement = (tariffIndex: number, chargeIndex: number): string => {
            return this.getChargeApplicationComplement(tariffIndex, chargeIndex);
        }
        this.$scope.getSecondaryLegChargeApplicationComplement = (tariffIndex: number, secondaryLegIndex: number, chargeIndex: number): string => {
            return this.getSecondaryLegChargeApplicationComplement(tariffIndex, secondaryLegIndex, chargeIndex);
        }
        this.$scope.checkDateValidity = (initialDate: Date, finalDate: Date): void => {
            this.checkDateValidity(initialDate, finalDate);
        }
        this.$scope.openTariffUsedOffer = async (tariffDomestic: ITariffDomesticModel): Promise<void> => {
            this.openTariffUsedOffer(tariffDomestic);
        }
        this.$scope.setCopy = (value: boolean): void => {
            this.setCopy(value);
        }
        this.$scope.noChargeChange = (parentIndex: number, childIndex: number, index: number, value: boolean): void => {
            this.noChargeChange(parentIndex, childIndex, index, value);
        }

        this.$scope.fetchData = async (id: number, action: string): Promise<void> => {
            this.fetchData(id, action);
        }
        this.$scope.freightBasisMainLegChange = (parentIndex: number) => {
            this.freightBasisMainLegChange(parentIndex);
        }
        this.$scope.freightBasisSecondaryLegChange = (parentIndex: number, childIndex: number) => {
            this.freightBasisSecondaryLegChange(parentIndex, childIndex);
        }
        this.$scope.checkMainLegValidityEvent = (mainLegIndex: number): void => {
            this.checkMainLegValidityEvent(mainLegIndex);
        }
        this.$scope.checkSecondaryLegValidityEvent = (mainLegIndex: number, childIndex: number): void => {
            this.checkSecondaryLegValidityEvent(mainLegIndex, childIndex);
        }
    }

    initGridColumns(columns: string[]): IColumnDef[] {
        const gridColumns = new GridColumnBuilder([]);

        const view = `<a ng-click="grid.appScope.viewTariffDomestic(row.entity)" class="text-info" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.VIEW' | translate }}" tooltip-append-to-body="true"><i class="fa fa fa-search icon"></i></a>&nbsp;&nbsp;`;
        const edit = `<a ng-click="grid.appScope.customEdit(row.entity)" class="text-especial edit-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.EDIT' | translate }}" tooltip-append-to-body="true"><i class="fa fa-pencil icon"></i></a>&nbsp;&nbsp;`;
        const viewLog = `<a ng-click="grid.appScope.viewLogTariffDomestic(row.entity)" class="text-green log-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.LOG' | translate }}" tooltip-append-to-body="true"><i class="fa fa-history icon"></i></a>&nbsp;&nbsp;`;
        const copy = `<a ng-click="grid.appScope.setCopy(true);grid.appScope.copyTariffDomestic(row.entity)" class="text-orange copy-btn-action-bar" tooltip-placement="auto top" uib-tooltip="{{'GENERAL.GRID.COPY' | translate }}" tooltip-append-to-body="true"><i class="fa fa-copy icon"></i></a>&nbsp;&nbsp;`;
        const offerGenerated = `<a ng-disabled="!row.entity.TARIFF_DOMESTIC_USED_OFFER.length"><btn ng-click="row.entity.TARIFF_DOMESTIC_USED_OFFER.length ? grid.appScope.openTariffUsedOffer(row.entity) : ''" class="text-info" ng-class="{'text-muted': !row.entity.TARIFF_DOMESTIC_USED_OFFER.length}" tooltip-placement="auto top" uib-tooltip="{{row.entity.TARIFF_DOMESTIC_USED_OFFER.length ? 'GENERAL.OFFER_VIEW' : 'GENERAL.NO_OFFERS_GENERATED_WITH_THIS_TARIFF' | translate }}" tooltip-append-to-body="true"><i class="fa fa-list-alt icon"></i></btn></a>&nbsp;&nbsp;`;
        const modalIntegration = `<a ng-click="grid.appScope.openModalIntegration(row.entity.ID, row.entity.DOCUMENT_ERROR)" ng-class="{'text-green': !row.entity.DOCUMENT_ERROR, 'text-danger': row.entity.DOCUMENT_ERROR}" tooltip-placement="auto top" uib-tooltip="{{ 'GENERAL.GRID.INTEGRATION_VIEW' | translate }}" tooltip-append-to-body="true" ><i class="fa fa-refresh icon"></i></a>&nbsp;&nbsp;</div>`;

        const colActions: IMonacoColumnDef = {
            name: "acoes", displayName: "GENERAL.ACTIONS",
            width: 150,
            cellTemplate: `<div class="text-center view-btn-action-bar"> ${view} ${edit} ${viewLog} ${copy} ${offerGenerated} ${modalIntegration}</div>`,
            enableFiltering: false,
            enableSorting: false,
            enableHiding: false,
            enableColumnMoving: false,
            enableColumnResizing: false,
            pinnedLeft: true,
            enablePinning: false
        };

        gridColumns.addColumn(colActions);

        const newColumnDefs = this.buildColumns(columns);
        for (const column of newColumnDefs) { column.filter = column.filter ? column.filter : { condition: this.$gridService.filterSelectObject }; gridColumns.addColumn(column) }

        return gridColumns.$columnDefs;
    }

    buildColumns(columns: string[]): IMonacoColumnDef[] {
        try {
            const columnDefs: IMonacoColumnDef[] = [];

            const colDirection: IMonacoColumnDef = { name: "DIRECTION.NAME", displayName: "PRODUCT.WAY", width: 100 };
            const colOperatorType: IMonacoColumnDef = { name: "OPERATOR_TYPE.NAME", displayName: "ENTITY.COMPANY_TYPE", width: 150 };
            const colOperator: IMonacoColumnDef = { name: "OPERATOR.NAME", displayName: "BASIC_DATA.SERVICE_PROVIDER", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getGridViewProvider(row.entity.OPERATOR)}}</div>' };
            const colProduct: IMonacoColumnDef = { name: "PRODUCT.ID", displayName: "BASIC_DATA.PRODUCT", width: 100 };
            const colTypeCargo: IMonacoColumnDef = { name: "TYPE_CARGO.NAME", displayName: "BASIC_DATA.CARGO_TYPE", width: 120 };
            const colAccounts: IMonacoColumnDef = { name: "ACCOUNTS.NAME", displayName: "BASIC_DATA.CLIENT", width: 150, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNTS, null, "NAME")}}</div>' };
            const colAccountsFantasyName: IMonacoColumnDef = { name: "ACCOUNTS.FANTASY_NAME", displayName: "GENERAL.CUSTOMER_COMMERCIAL_NAME", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.ACCOUNTS, null, "FANTASY_NAME")}}</div>' };
            const colDta: IMonacoColumnDef = { name: "DTA", displayName: "REGISTRATION.BONDED_TRANSPORT", width: 80, cellFilter: "YesOrNo" };
            const colVersion: IMonacoColumnDef = { name: "VERSION", displayName: "GENERAL.VERSION", width: 100 };
            const colValidityStart: IMonacoColumnDef = { name: "DISPLAY_VALIDITY_START", displayName: "PRODUCT.VALIDITY_START", width: 150, cellFilter: "simpleDate" };
            const colValidityEnd: IMonacoColumnDef = { name: "DISPLAY_VALIDITY_END", displayName: "PRODUCT.VALIDITY_END", width: 150, cellFilter: "simpleDate" };
            const colLogisticSolution: IMonacoColumnDef = { name: "LOGISTIC_SOLUTION.NAME", displayName: "BASIC_DATA.SERVICE", width: 150 };
            const colSituation: IMonacoColumnDef = { name: "SITUATION.NAME", displayName: "GENERAL.SITUATION", width: 150 };
            const colConcatenated: IMonacoColumnDef = { name: "CONCATENATED", displayName: "GENERAL.CONCATENATED", width: 150 };
            const colUsedOffer: IMonacoColumnDef = { name: "TARIFF_DOMESTIC_USED_OFFER.OFFER_CODE_EXHIBITION", displayName: "Ofertas", width: 160, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.TARIFF_DOMESTIC_USED_OFFER, null, "OFFER_CODE_EXHIBITION")}}</div>' };
            const colOrigin: IMonacoColumnDef = { name: "MAIN_LEG.ORIGIN", displayName: "BASIC_DATA.ORIGIN", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.MAIN_LEG_ORIGIN)}}</div>' };
            const colDestination: IMonacoColumnDef = { name: "MAIN_LEG.DESTINATION", displayName: "BASIC_DATA.DESTINATION", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.MAIN_LEG_DESTINATION)}}</div>' };
            const colSecondaryLeg: IMonacoColumnDef = { name: "SECONDARY_LEG", displayName: "PRODUCT.ADDITIONAL_LEG_DOMESTIC", width: 200, cellTemplate: '<div class="grid-padding" >{{grid.appScope.getCONCAT(row.entity.SECONDARY_LEG)}}</div>' };

            const colActive: IMonacoColumnDef = { name: "ACTIVE", displayName: "GENERAL.ACTIVE", width: 80, cellFilter: "YesOrNo" };
            const colValidityExpired: IMonacoColumnDef = { name: "VALIDITY_EXPIRED", displayName: "GENERAL.EXPIRED", width: 150, cellFilter: "YesOrNo" };
            const colCreatedAt: IMonacoColumnDef = { name: "CREATED_AT", displayName: "GENERAL.CREATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colUpdatedAt: IMonacoColumnDef = { name: "UPDATED_AT", displayName: "GENERAL.UPDATED_AT", width: 150, cellFilter: 'date:\'dd/MM/yyyy HH:mm:ss\'', };
            const colId: IMonacoColumnDef = { name: "ID", displayName: "ID", width: 80 };

            for (const column of columns) {
                switch (column.toUpperCase()) {
                    case 'DIRECTION':
                        columnDefs.push(colDirection);
                        break;
                    case 'OPERATOR_TYPE':
                        columnDefs.push(colOperatorType);
                        break;
                    case 'OPERATOR':
                        columnDefs.push(colOperator);
                        break;
                    case 'ID_LOGISTIC_SOLUTION':
                        columnDefs.push(colLogisticSolution);
                        break;
                    case 'PRODUCT':
                        columnDefs.push(colProduct);
                        break;
                    case 'TYPE_CARGO':
                        columnDefs.push(colTypeCargo);
                        break;
                    case 'ACCOUNTS':
                        columnDefs.push(colAccounts, colAccountsFantasyName);
                        break;
                    case 'DTA':
                        columnDefs.push(colDta);
                        break;
                    case 'VERSION':
                        columnDefs.push(colVersion);
                        break;
                    case 'DISPLAY_VALIDITY_START':
                        columnDefs.push(colValidityStart);
                        break;
                    case 'DISPLAY_VALIDITY_END':
                        columnDefs.push(colValidityEnd);
                        break;
                    case 'SITUATION':
                        columnDefs.push(colSituation);
                        break;
                    case 'TARIFF_DOMESTIC_USED_OFFER':
                        columnDefs.push(colUsedOffer);
                        break;
                    case 'CONCATENATED':
                        columnDefs.push(colConcatenated);
                        break;
                    case 'ACTIVE':
                        columnDefs.push(colActive);
                        break;
                    case 'VALIDITY_EXPIRED':
                        columnDefs.push(colValidityExpired);
                        break;
                    case 'ID':
                        columnDefs.push(colId);
                        break;
                    case 'CREATED_AT':
                        columnDefs.push(colCreatedAt);
                        break;
                    case 'UPDATED_AT':
                        columnDefs.push(colUpdatedAt);
                        break;
                    case 'MAIN_LEG':
                        columnDefs.push(colOrigin);
                        columnDefs.push(colDestination);
                        break;
                    case 'SECONDARY_LEG':
                        columnDefs.push(colSecondaryLeg);
                        break;
                };
            }

            return columnDefs;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    initModel(): void {
        this.$scope.model = {
            _id: null,
            ID: null,
            VERSION: null,
            CONCATENATED: null,
            CONCATENATED_COMPLEMENT: null,
            OPERATOR_TYPE: null,
            ID_PROVIDER: null,
            PROVIDER: null,
            ID_LOGISTIC_SOLUTION: null,
            LOGISTIC_SOLUTION: null,
            ID_LEGAL_PERSON_AGENT: null,
            LEGAL_PERSON_AGENT: null,
            PRODUCT: null,
            ACCOUNTS: null,
            TYPE_CARGO: null,
            DIRECTION: null,
            VALIDITY_START: null,
            DISPLAY_VALIDITY_START: null,
            VALIDITY_END: null,
            DISPLAY_VALIDITY_END: null,
            SPOT: false,
            DTA: false,
            MAIN_LEG: null,
            OBSERVATION: null,
            FILES: null,
            FILES_REMOVE: null,
            FILES_NEW: null,
            SITUATION: null,
            ACTIVE: true,
            VALIDITY_EXPIRED: true,
            CREATED_AT: null,
            CREATED_BY: null,
            UPDATED_AT: null,
            UPDATED_BY: null,
            SEARCH_FIELDS: null,
            DOCUMENT_ERROR: null
        };
        this.$scope.auxModel = {
            product: null,
            direction: null
        }
    }

    async save(): Promise<boolean> {
        let proceed: boolean = true;
        try {
            if (this.$scope.uploader.queue && this.$scope.uploader.queue.length > 0) {
                const msgError = this.formService.getTranslate('GENERAL.MESSAGES.ERROR.FILES_IN_THE_QUEUE')
                proceed = false;
                this.handleError(msgError);
            }
            proceed = !this.hasHiddenPendingFields();
            this.SSEService.closeEvents();
            this.setCopy(false);
        } catch (ex) {
            this.handleError(ex);
            proceed = false;
        }
        return proceed;
    }

    async register(): Promise<void> {
        try {
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.$scope.scopeBeforeSave = null;
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.NEW');
            this.initPanels(["collapseBasicData", "collapseMainLeg"], "collapseBasicData");
            this.addMainLeg();
            this.updateControl();

            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async view(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.VIEW');
            BrowserTitle.$id = this.$scope.model.CONCATENATED;
            this.initDateFields();
            this.updateControl();
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
            }
            this.$scope.auxModel.product = this.$scope.model.PRODUCT;
            this.$scope.auxModel.direction = this.$scope.model.DIRECTION;

            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async editTariffDomestic(tariffDomestic: ITariffDomesticModel): Promise<void> {
        let blockedObject = {
            ID: tariffDomestic.ID,
            NAME: tariffDomestic.CONCATENATED && tariffDomestic.CONCATENATED !== '' ? tariffDomestic.CONCATENATED : this.gridName + " - " + tariffDomestic.ID,
            EMAIL: this.$scope.user['email'],
            FORM_NAME: this.gridName
        };
        this.SSEService.closeEvents();
        this.SSEService.setBlockedObject(blockedObject);
        this.SSEService.initEvents();
        this.SSEService.events.onmessage = async (event) => {
            const parsedData = JSON.parse(event.data);
            if (!parsedData.status) {
                const result = await this.SSEService.generate(parsedData);
                if (result && !result.status) {
                    this.$rootScope.refreshPage();
                    return;
                }
                if (this.$scope.operation !== EOperation.VIEW || tariffDomestic.ID !== this.$scope.model.ID) this.$scope.fetchData(tariffDomestic.ID, EOperation.VIEW);
            } else if (this.$scope.operation !== EOperation.EDIT || tariffDomestic.ID !== this.$scope.model.ID) {
                this.$scope.fetchData(tariffDomestic.ID, EOperation.EDIT);
            }
        };
    }

    async edit(): Promise<void> {
        try {
            this.$scope.formOperation = this.formService.getTranslate('GENERAL.FORM_OPERATION.EDIT');
            this.$scope.scopeBeforeSave = angular.copy(this.$scope.model);
            BrowserTitle.$id = this.$scope.model.CONCATENATED;
            this.updateControl();
            this.initPanels(["collapseMainLeg"], "collapseMainLeg");
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
            }
            this.$scope.auxModel.product = this.$scope.model.PRODUCT;
            this.$scope.auxModel.direction = this.$scope.model.DIRECTION;
            angular.element(document).ready(() => {
                this.$scope.selectorValidity('direction');
                this.$scope.selectorValidity('product');
                this.$scope.selectorValidity('concatened');
                this.$scope.selectorValidity('concatenedLeg');
            });
            if (this.$scope.model.MAIN_LEG && this.$scope.model.MAIN_LEG.length > 0) {
                this.$scope.model.MAIN_LEG.forEach((mainLeg, index) => {
                    $('#collapseMainLeg' + index).on('shown.bs.collapse', () => {
                        if (this.$scope.operation == 'edit') mainLeg.SHOW = true;
                        this.addFocusEventOnParam(index);
                        this.addFocusEventOnParamSecondaryLeg(index);
                    });
                });
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copy(): Promise<void> {
        try {
            this.$scope.menuFloating = this.getMenuFloatingDefault();
            this.updateControl();
            await this.clearFields(this.$scope.model, ['CONCATENATED']);
            if (this.$scope.model.MAIN_LEG && this.$scope.model.MAIN_LEG.length > 0) {
                for (const firstLeg of this.$scope.model.MAIN_LEG) {
                    firstLeg._id = null;
                    firstLeg.ID = null;
                    firstLeg.CONCATENATED = null;
                    if (firstLeg.CHARGE && firstLeg.CHARGE.length > 0) {
                        for (const charge of firstLeg.CHARGE) {
                            charge._id = null;
                            charge.ID = null;
                        }
                    }
                    if (firstLeg.SECONDARY_LEG && firstLeg.SECONDARY_LEG.length > 0) {
                        for (const secondLeg of firstLeg.SECONDARY_LEG) {
                            secondLeg._id = null;
                            secondLeg.ID = null;

                            if (secondLeg.CHARGE && secondLeg.CHARGE.length > 0) {
                                for (const charge of secondLeg.CHARGE) {
                                    charge._id = null;
                                    charge.ID = null;
                                }
                            }
                        }
                    }
                }
            }
            this.$scope.model.CONCATENATED = null;
            this.$scope.model.ID = null;
            this.$scope.auxModel.product = this.$scope.model.PRODUCT;
            this.$scope.auxModel.direction = this.$scope.model.DIRECTION;
            angular.element(document).ready(() => {
                this.$scope.selectorValidity('direction');
                this.$scope.selectorValidity('product');
                this.$scope.selectorValidity('concatened');
                this.$scope.selectorValidity('concatenedLeg');
            });

            this.SSEService.closeEvents();
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async request(): Promise<IMonacoRequestLog> {
        const route = this.$scope.operation == 'register' ? 'insert' : 'update';
        this.setCopy(false);
        return {
            route: `/tariffDomestic/${route}`,
            data: angular.copy(this.$scope.model),
            oldData: angular.copy(this.$scope.scopeBeforeSave),
            timeout: 15000
        };
    }

    private async openModalIntegration(id: number, documentError: IDocumentError[]): Promise<void> {
        try {
            this.modalID = this.ModalService.newModal();
            const documentErrorList: IDocumentError[] = documentError;
            this.ModalService.showModalIntegrationRedundance({ modalID: this.modalID, integrationId: id, documentErrorList: documentErrorList, fnSync: this.sendSync, fnUpdateIntegrationGrid: this.updateIntegrationGrid, headerText: "GENERAL.MENU.INTEGRATION" });
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private sendSync = async (id: number, idSubject: string): Promise<boolean> => {
        let success = false;
        try {
            if (id) {
                const syncRequest = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/tariffDomestic/sendSync`, { "idDomestic": id, "idSubject": idSubject }, 120000, false);
                if (syncRequest) success = true;
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_SENDING_REQUEST');
        } finally {
            return success;
        }
    }

    private updateIntegrationGrid = async (id: number): Promise<IDocumentError[]> => {
        let documentError: IDocumentError[] = null;
        try {
            if (angular.isArray(this.$scope.gridOptions.data)) {
                const row = this.$scope.gridOptions.data.find(x => x.ID == id);
                await this.$timeout(async () => {
                    const tariffDomestic = await this.getTariffDomesticById(id);
                    if (row && tariffDomestic && tariffDomestic.DOCUMENT_ERROR !== undefined) {
                        row.DOCUMENT_ERROR = tariffDomestic.DOCUMENT_ERROR;
                        documentError = tariffDomestic.DOCUMENT_ERROR;
                    }
                }, 3000);
            }
        } catch (ex) {
            this.formService.handleError('GENERAL.ERROR_DURING_REQUEST');
        } finally {
            return documentError;
        }
    }

    private async getTariffDomesticById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const result = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/tariffDomestic/list`, { "datafilter": { "limits": [0, 50], "filter": { "ID": id.toString() } }, "timeout": 30000 }, 30000, false);
            return result.data[0];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    async cancel(): Promise<void> {
        this.SSEService.closeEvents();
        this.setCopy(false);
    }

    private async getProductList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/product`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getDirectionList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/direction`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getSituationList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/registration_situation`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getDomesticProviderTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/domestic_provider_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getModalList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/process_event_transport_mode`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getOriginDestinationTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_local_event`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getTypeCargoList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/type_cargo`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getVehicleTypeList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/vehicle_type`, null);
        return generic && generic.data ? generic.data : [];
    }

    private async getEventList(): Promise<SelectorModel[]> {
        const { data: generic } = await this.helperService.get(`/generic/value/event`, null);
        return generic && generic.data ? generic.data : [];
    }

    private filterProductList(productList: SelectorModel[]) {
        return productList.filter(product => product.ID != 'ER' && product.ID != 'IR');
    }

    private hasHiddenPendingFields(): boolean {
        let hasPendingFields: boolean = false;

        const mainRoutes = this.$scope.model.MAIN_LEG;
        if (mainRoutes && mainRoutes.length) {
            hasPendingFields = mainRoutes.some((route, routeIndex) => {
                const hasEmptyFieldInCharge = route.CHARGE && route.CHARGE.length ? route.CHARGE.some((charge, chargeIndex) => {
                    const isWeightRangeRequired = this.getChargeApplicationComplement(routeIndex, chargeIndex) === '2';
                    const isEquipmentRequired = this.getChargeApplicationComplement(routeIndex, chargeIndex) === '3';
                    const isVehicleTypeRequired = this.getChargeApplicationComplement(routeIndex, chargeIndex) === '4';
                    return !route.SHOW && // validate only closed routes
                        isEmptyObject(charge.CHARGE_NAME_EXHIBITION) ||
                        isEmptyObject(charge.APPLICATION) ||
                        (isWeightRangeRequired && isEmptyObject(charge.WEIGHT_RANGE)) ||
                        (isEquipmentRequired && isEmptyObject(charge.EQUIPMENT)) ||
                        (isVehicleTypeRequired && isEmptyObject(charge.VEHICLE_TYPE)) ||
                        isEmptyObject(charge.CURRENCY) ||
                        angular.isUndefined(charge.PAYMENT_UNITARY) ||
                        angular.isUndefined(charge.PAYMENT_MIN) ||
                        angular.isUndefined(charge.RECEIVING_UNITARY) ||
                        angular.isUndefined(charge.RECEIVING_MIN);
                }) : false;

                return hasEmptyFieldInCharge ||
                    isEmptyObject(route.ORIGIN_TYPE) ||
                    isEmptyObject(route.ORIGIN) ||
                    isEmptyObject(route.DESTINATION_TYPE) ||
                    isEmptyObject(route.DESTINATION) ||
                    isEmptyObject(route.MODAL) ||
                    isEmptyObject(route.CONCATENATED);
            });

            if (hasPendingFields) this.formService.notifyError(this.formService.getTranslate("GENERAL.ALL_FIELDS_MANDATORY"));
        }
        return hasPendingFields;
    }

    private addMainLeg(navigate?: boolean): void {
        try {
            const firstLeg: IMainLeg = {
                SHOW: true,
                _id: null,
                ID: null,
                ID_TARIFF_DOMESTIC: null,
                CONCATENATED: null,
                CONCATENATED_COMPLEMENT: null,
                ORIGIN_TYPE: null,
                ORIGIN: null,
                DESTINATION_TYPE: null,
                DESTINATION: null,
                MODAL: null,
                CHARGE: null,
                SECONDARY_LEG: null,
                ACTIVE: true,
                VALIDITY_EVENT: null,
                FREIGHT_BASIS: null
            };

            if (!this.$scope.model.MAIN_LEG) this.$scope.model.MAIN_LEG = [];

            this.$scope.model.MAIN_LEG.push(angular.copy(firstLeg));

            this.$timeout(async () => {
                for (let i = 0; i < this.$scope.model.MAIN_LEG.length; i++) {
                    this.$scope.selectorValidity('originTypeMainLeg' + i);
                    this.$scope.selectorValidity('originMainLeg' + i);
                    this.$scope.selectorValidity('destinationTypeMainLeg' + i);
                    this.$scope.selectorValidity('destinationMainLeg' + i);
                    this.$scope.selectorValidity('modalMainLeg' + i);
                    this.$scope.selectorValidity('concatenated' + i);
                    if ((i + 1) == this.$scope.model.MAIN_LEG.length) {
                        this.$scope.model.MAIN_LEG[i].SHOW = true;
                        this.collapseHeader("collapseMainLeg" + i, "show", navigate);
                        this.generateConcatenatedLeg(i);
                    } else {
                        this.$scope.model.MAIN_LEG[i].SHOW = false;
                        this.collapseHeader("collapseMainLeg" + i, "hide");
                    }
                }
            });

            this.addCharge(this.$scope.model.MAIN_LEG.length - 1);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeMainLeg(index: number): Promise<void> {
        try {
            if (!index && index !== 0) throw Error('index is null');

            const thatTranslated = this.formService.getTranslate("GENERAL.GENDER.THAT", null, true);
            const originTranslated = this.formService.getTranslate("BASIC_DATA.ORIGIN", null, true);
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                closeButtonText: "GENERAL.CLOSE",
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thatTranslated, prop: originTranslated })
            });

            if (!modal) return;

            this.formService.block();

            if (this.$scope.model.MAIN_LEG) {
                this.$scope.model.MAIN_LEG.splice(index, 1);
            }

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async duplicateMainLeg(index: number): Promise<void> {
        try {
            if (!index && index !== 0) throw Error('index is null');
            if (index >= 0) {
                const duplicateMainLeg = angular.copy(this.$scope.model.MAIN_LEG[index]);

                duplicateMainLeg.SHOW = true;
                duplicateMainLeg._id = null;
                duplicateMainLeg.ID = null;
                duplicateMainLeg.CONCATENATED = null;

                if (duplicateMainLeg.CHARGE && duplicateMainLeg.CHARGE.length > 0) {
                    for (const charge of duplicateMainLeg.CHARGE) {
                        charge._id = null;
                        charge.ID = null;
                    }
                    if (duplicateMainLeg.SECONDARY_LEG && duplicateMainLeg.SECONDARY_LEG.length > 0) {
                        for (const secondLeg of duplicateMainLeg.SECONDARY_LEG) {
                            secondLeg._id = null;
                            secondLeg.ID = null;

                            for (const charge of secondLeg.CHARGE) {
                                charge._id = null;
                                charge.ID = null;
                            }
                        }
                    }
                }

                this.$scope.model.MAIN_LEG.push(duplicateMainLeg);

                this.$timeout(() => {
                    for (const index in this.$scope.model.MAIN_LEG) {
                        if ((parseInt(index) + 1) == this.$scope.model.MAIN_LEG.length) {
                            this.generateConcatenatedLeg(parseInt(index));
                            this.$scope.model.MAIN_LEG[index].SHOW = true;
                            this.collapseHeader("collapseMainLeg" + index, "show", true);
                        } else {
                            this.$scope.model.MAIN_LEG[index].SHOW = false;
                            this.collapseHeader("collapseMainLeg" + index, "hide");
                        }
                    }
                });
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    };

    private addCharge(parentIndex: number): void {
        try {
            if (!parentIndex && parentIndex !== 0) throw Error('parentIndex is null');

            const charge: IChargeMainLeg = {
                _id: null,
                ID: null,
                ID_MAIN_LEG: null,
                ID_CHARGE_NAME_EXHIBITION: null,
                CHARGE_NAME_EXHIBITION: null,
                APPLICATION: null,
                FLAT: false,
                EQUIPMENT: null,
                VEHICLE_TYPE: null,
                WEIGHT_RANGE: null,
                ID_CURRENCY: null,
                CURRENCY: null,
                PAYMENT_MIN: null,
                PAYMENT_UNITARY: null,
                RECEIVING_MIN: null,
                RECEIVING_UNITARY: null,
                NO_CHARGE: false,
                SHORT_NAME: null,
            };

            if (!this.$scope.model.MAIN_LEG[parentIndex].CHARGE) this.$scope.model.MAIN_LEG[parentIndex].CHARGE = [];

            this.$scope.model.MAIN_LEG[parentIndex].CHARGE.push(angular.copy(charge));

            this.$timeout(async () => {
                for (let i = 0; i < this.$scope.model.MAIN_LEG[parentIndex].CHARGE.length; i++) {
                    this.$scope.selectorValidity('chargeName' + parentIndex + '-' + i);
                    this.$scope.selectorValidity('param' + parentIndex + '-' + i);
                    this.$scope.selectorValidity('weightRange' + parentIndex + '-' + i);
                    this.$scope.selectorValidity('equipment' + parentIndex + '-' + i);
                    this.$scope.selectorValidity('currency' + parentIndex + '-' + i);
                    this.$scope.selectorValidity('paymentUnitary' + parentIndex + '-' + i);
                    this.$scope.selectorValidity('paymentMin' + parentIndex + '-' + i);
                    this.$scope.selectorValidity('receivingUnitary' + parentIndex + '-' + i);
                    this.$scope.selectorValidity('receivingMin' + parentIndex + '-' + i);
                    this.$scope.selectorValidity('shortName' + parentIndex + '-' + i);
                }
                angular.element(document).ready(() => {
                    this.addFocusEventOnParam(parentIndex);
                    this.addFocusEventOnParamSecondaryLeg(parentIndex);
                });
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeCharge(parentIndex: number, index: number): Promise<void> {
        try {
            if (!parentIndex && parentIndex !== 0) throw Error('parentIndex is null');
            if (!index && index !== 0) throw Error('index is null');

            const thatTranslated = this.formService.getTranslate("GENERAL.GENDER.THAT", null, true);
            const originTranslated = this.formService.getTranslate("BASIC_DATA.ORIGIN", null, true);
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                closeButtonText: "GENERAL.CLOSE",
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thatTranslated, prop: originTranslated })
            });

            if (!modal) return;

            this.formService.block();

            if (this.$scope.model.MAIN_LEG[parentIndex].CHARGE) {
                this.$scope.model.MAIN_LEG[parentIndex].CHARGE.splice(index, 1);
            }

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async duplicateCharge(parentIndex: number, index: number): Promise<void> {
        try {
            if (!parentIndex && parentIndex != 0) throw Error('parentIndex is null');
            if (!index && index != 0) throw Error('index is null');

            if (this.$scope.model.MAIN_LEG[parentIndex].CHARGE && this.$scope.model.MAIN_LEG[parentIndex].CHARGE.length > 0 && this.$scope.model.MAIN_LEG[parentIndex].CHARGE[index]) {
                this.formService.block();
                const copyCharge = angular.copy(this.$scope.model.MAIN_LEG[parentIndex].CHARGE[index]);
                copyCharge._id = null;
                copyCharge.ID = null;
                this.$scope.model.MAIN_LEG[parentIndex].CHARGE.push(copyCharge);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private isChargeAirFieldsDisabled(chargeList: IChargeMainLeg[], chargeIndex: number, application: IApplicationList, isReceivingField?: boolean, noCharge?: boolean): boolean {
        try {
            let isAirConditionDisabled = false;
            if (this.$scope.isAirProduct) {
                const tariffCharge = chargeList[chargeIndex];
                const firstWeightRangeChargeIndex = chargeList && chargeList.length ? chargeList.findIndex(charge => charge.APPLICATION && charge.APPLICATION.APPLICATION_COMPLEMENT && charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE && (charge.CHARGE_NAME_EXHIBITION && tariffCharge.CHARGE_NAME_EXHIBITION && charge.CHARGE_NAME_EXHIBITION.ID == tariffCharge.CHARGE_NAME_EXHIBITION.ID)) : -1;
                const isWeightRangeApplication = application && application.APPLICATION_COMPLEMENT && application.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
                isAirConditionDisabled = isWeightRangeApplication && chargeIndex != firstWeightRangeChargeIndex;
            }
            if (isReceivingField) return noCharge || isAirConditionDisabled;
            else return isAirConditionDisabled;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private chargeAirFieldsChange(mainLegIndex: number, chargeIndex: number, isCurrencyField?: boolean, isPaymentField?: boolean, isReceivingField?: boolean): void {
        try {
            if (!mainLegIndex && mainLegIndex != 0) throw Error('mainLegIndex is null');
            if (!chargeIndex && chargeIndex != 0) throw Error('chargeIndex is null');
            if (this.$scope.isAirProduct) {
                const mainLeg = this.$scope.model.MAIN_LEG && this.$scope.model.MAIN_LEG[mainLegIndex] ? this.$scope.model.MAIN_LEG[mainLegIndex] : null;
                if (mainLeg && mainLeg.CHARGE && mainLeg.CHARGE.length) {
                    const mainLegCharge = mainLeg.CHARGE[chargeIndex];
                    if (mainLegCharge && mainLegCharge.CHARGE_NAME_EXHIBITION) {
                        const firstWeightRangeChargeIndex = mainLeg.CHARGE.findIndex(charge => charge.APPLICATION && charge.APPLICATION.APPLICATION_COMPLEMENT && charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE && charge.CHARGE_NAME_EXHIBITION && charge.CHARGE_NAME_EXHIBITION.ID == mainLegCharge.CHARGE_NAME_EXHIBITION.ID);
                        for (const tariffCharge of mainLeg.CHARGE) {
                            if (tariffCharge.CHARGE_NAME_EXHIBITION && tariffCharge.CHARGE_NAME_EXHIBITION.ID == mainLegCharge.CHARGE_NAME_EXHIBITION.ID) {
                                const application = tariffCharge.APPLICATION;
                                const isWeightRangeApplication = application && application.APPLICATION_COMPLEMENT && application.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
                                if (isWeightRangeApplication) {
                                    if (isCurrencyField) tariffCharge.CURRENCY = mainLeg.CHARGE[firstWeightRangeChargeIndex].CURRENCY;
                                    if (isPaymentField) tariffCharge.PAYMENT_MIN = mainLeg.CHARGE[firstWeightRangeChargeIndex].PAYMENT_MIN;
                                    if (isReceivingField) tariffCharge.RECEIVING_MIN = mainLeg.CHARGE[firstWeightRangeChargeIndex].RECEIVING_MIN;
                                }
                            }
                        }
                    }
                }
            }

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async generateChargeToAvailableWeightRange(mainLegIndex: number, chargeIndex: number, secondaryLegIndex: number) {
        try {
            if (!mainLegIndex && mainLegIndex != 0) throw Error('mainLegIndex is null');
            if (!chargeIndex && chargeIndex != 0) throw Error('chargeIndex is null');

            const isSecondaryLeg = secondaryLegIndex >= 0;
            const mainLeg = this.$scope.model.MAIN_LEG && this.$scope.model.MAIN_LEG.length ? this.$scope.model.MAIN_LEG[mainLegIndex] : null;
            const mainLegCharge = !isSecondaryLeg && mainLeg && mainLeg.CHARGE && mainLeg.CHARGE.length ? mainLeg.CHARGE[chargeIndex] : null;
            const secondaryLeg = isSecondaryLeg && mainLeg && mainLeg.SECONDARY_LEG && mainLeg.SECONDARY_LEG.length ? mainLeg.SECONDARY_LEG[secondaryLegIndex] : null;
            const secondaryLegCharge = secondaryLeg && secondaryLeg.CHARGE && secondaryLeg.CHARGE.length ? secondaryLeg.CHARGE[chargeIndex] : null;
            const chargeToVerify = isSecondaryLeg ? secondaryLegCharge : mainLegCharge;
            if (chargeToVerify) {
                const isWeightRangeApplication = chargeToVerify.APPLICATION && chargeToVerify.APPLICATION.APPLICATION_COMPLEMENT && chargeToVerify.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
                if (isWeightRangeApplication) {
                    let chargesToFilter = mainLeg.CHARGE;
                    if (mainLeg.SECONDARY_LEG && mainLeg.SECONDARY_LEG.length) {
                        for (const leg of mainLeg.SECONDARY_LEG) { chargesToFilter = chargesToFilter.concat(leg.CHARGE); }
                    }
                    const availableWeightRangeList = await this.getWeightRangeListByName(null);
                    const availableWeightRangeToGenerateCharge = availableWeightRangeList.filter(weightRange => !chargesToFilter.some(charge => charge.CHARGE_NAME_EXHIBITION && chargeToVerify.CHARGE_NAME_EXHIBITION && charge.CHARGE_NAME_EXHIBITION.ID == chargeToVerify.CHARGE_NAME_EXHIBITION.ID && charge.WEIGHT_RANGE && charge.WEIGHT_RANGE.some((chargeWeightRange) => chargeWeightRange.ID == weightRange.ID)));
                    if (availableWeightRangeToGenerateCharge && availableWeightRangeToGenerateCharge.length) {
                        if (isSecondaryLeg) {
                            this.addSecondaryLegCharge(mainLegIndex, secondaryLegIndex);
                            const addedChargeIndex = secondaryLeg.CHARGE.length - 1;
                            this.$scope.model.MAIN_LEG[mainLegIndex].SECONDARY_LEG[secondaryLegIndex].CHARGE[addedChargeIndex] = { ...secondaryLegCharge, _id: null, WEIGHT_RANGE: availableWeightRangeToGenerateCharge };
                        } else {
                            this.addCharge(mainLegIndex);
                            const addedChargeIndex = mainLeg.CHARGE.length - 1;
                            this.$scope.model.MAIN_LEG[mainLegIndex].CHARGE[addedChargeIndex] = { ...chargeToVerify, _id: null, WEIGHT_RANGE: availableWeightRangeToGenerateCharge };
                        }
                    } else this.formService.notifyInfo("Não existem mais faixas disponíveis para essa taxa.");
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async flatChange(mainLegIndex: number, chargeIndex: number, secondaryLegIndex: number) {
        try {
            if (!mainLegIndex && mainLegIndex != 0) throw Error('mainLegIndex is null');
            if (!chargeIndex && chargeIndex != 0) throw Error('chargeIndex is null');

            const isSecondaryLeg = secondaryLegIndex >= 0;
            const mainLeg = this.$scope.model.MAIN_LEG && this.$scope.model.MAIN_LEG.length ? this.$scope.model.MAIN_LEG[mainLegIndex] : null;
            const mainLegCharge = !isSecondaryLeg && mainLeg && mainLeg.CHARGE && mainLeg.CHARGE.length ? mainLeg.CHARGE[chargeIndex] : null;
            const secondaryLeg = isSecondaryLeg && mainLeg && mainLeg.SECONDARY_LEG && mainLeg.SECONDARY_LEG.length ? mainLeg.SECONDARY_LEG[secondaryLegIndex] : null;
            const secondaryLegCharge = secondaryLeg && secondaryLeg.CHARGE && secondaryLeg.CHARGE.length ? secondaryLeg.CHARGE[chargeIndex] : null;
            const chargeToVerify = isSecondaryLeg ? secondaryLegCharge : mainLegCharge;

            if (chargeToVerify && chargeToVerify.FLAT) {
                let chargesToFilter = mainLeg.CHARGE;
                if (mainLeg.SECONDARY_LEG && mainLeg.SECONDARY_LEG.length) {
                    for (const leg of mainLeg.SECONDARY_LEG) { chargesToFilter = chargesToFilter.concat(leg.CHARGE); }
                }
                const availableWeightRangeList = await this.getWeightRangeListByName(null);
                const availableWeightRangeToConcat = availableWeightRangeList.filter(weightRange => !chargesToFilter.some(charge => charge.CHARGE_NAME_EXHIBITION && chargeToVerify.CHARGE_NAME_EXHIBITION && charge.CHARGE_NAME_EXHIBITION.ID == chargeToVerify.CHARGE_NAME_EXHIBITION.ID && charge.WEIGHT_RANGE && charge.WEIGHT_RANGE.some((chargeWeightRange) => chargeWeightRange.ID == weightRange.ID)));
                if (chargeToVerify.WEIGHT_RANGE) chargeToVerify.WEIGHT_RANGE = chargeToVerify.WEIGHT_RANGE.concat(availableWeightRangeToConcat);
                else chargeToVerify.WEIGHT_RANGE = availableWeightRangeToConcat;
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private addSecondaryLeg(parentIndex: number): void {
        try {
            if (!parentIndex && parentIndex !== 0) throw Error('parentIndex is null');

            const door = this.$scope.originDestinationTypeList.find(item => item.ID === ETypeLocalId.DOOR);
            const terminal = this.$scope.originDestinationTypeList.find(item => item.ID === ETypeLocalId.TERMINAL);
            let originType = null;
            let origin = null;
            let destinationType = null;
            let destination = null;
            let event = null;

            if (this.$scope.model.DIRECTION && this.$scope.model.DIRECTION.ID == EDirectionId.ORIGIN) {
                originType = door;
                destinationType = terminal;
                destination = this.$scope.model.MAIN_LEG[parentIndex].ORIGIN;
                event = this.$scope.eventList.find(event => event.ID == EEventType.PICK_UP);
            } else if (this.$scope.model.DIRECTION && this.$scope.model.DIRECTION.ID == EDirectionId.DESTINATION) {
                originType = terminal;
                destinationType = door;
                origin = this.$scope.model.MAIN_LEG[parentIndex].DESTINATION;
                event = this.$scope.eventList.find(event => event.ID == EEventType.DELIVERY);
            }

            const secondLeg: ISecondaryLeg = {
                _id: null,
                ID: null,
                ID_MAIN_LEG: null,
                ORIGIN_TYPE: originType,
                ORIGIN: origin,
                DESTINATION_TYPE: destinationType,
                DESTINATION: destination,
                MODAL: null,
                CHARGE: null,
                VALIDITY_EVENT: event,
                FREIGHT_BASIS: null
            };

            if (!this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG) this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG = [];

            this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG.push(angular.copy(secondLeg));

            this.$timeout(async () => {
                for (let i = 0; i < this.$scope.model.MAIN_LEG[parentIndex].CHARGE.length; i++) {
                    this.$scope.selectorValidity('originType' + parentIndex + '-' + i);
                    this.$scope.selectorValidity('origin' + parentIndex + '-' + i);
                    this.$scope.selectorValidity('destinationType' + parentIndex + '-' + i);
                    this.$scope.selectorValidity('destination' + parentIndex + '-' + i);
                    this.$scope.selectorValidity('modal' + parentIndex + '-' + i);
                    this.$scope.selectorValidity('event' + parentIndex + '-' + i);
                }

            });

            this.collapseHeader("collapseSecondaryLeg" + parentIndex, "show", true);

            this.addSecondaryLegCharge(parentIndex, (this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG.length - 1));
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeSecondaryLeg(parentIndex: number, index: number): Promise<void> {
        try {
            if (!parentIndex && parentIndex !== 0) throw Error('parentIndex is null');
            if (!index && index !== 0) throw Error('index is null');

            const thatTranslated = this.formService.getTranslate("GENERAL.GENDER.THAT", null, true);
            const originTranslated = this.formService.getTranslate("BASIC_DATA.ORIGIN", null, true);
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                closeButtonText: "GENERAL.CLOSE",
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thatTranslated, prop: originTranslated })
            });

            if (!modal) return;

            this.formService.block();

            if (this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG) {
                this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG.splice(index, 1);
            }

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async duplicateSecondaryLeg(parentIndex: number, index: number): Promise<void> {
        try {
            if (!parentIndex && parentIndex != 0) throw Error('parentIndex is null');
            if (!index && index != 0) throw Error('index is null');

            if (this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG && this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG.length > 0 && this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[index]) {
                this.formService.block();
                const copySecondaryLeg = angular.copy(this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[index]);
                copySecondaryLeg._id = null;
                copySecondaryLeg.ID = null;

                if (copySecondaryLeg.CHARGE) {
                    for (const charge of copySecondaryLeg.CHARGE) {
                        charge._id = null;
                        charge.ID = null;
                    }
                }
                this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG.push(copySecondaryLeg);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private addSecondaryLegCharge(parentIndex: number, childIndex: number): void {
        try {
            if (!parentIndex && parentIndex !== 0) throw Error('parentIndex is null');
            if (!childIndex && childIndex !== 0) throw Error('childIndex is null');

            const secondLegCharge: IChargeSecondaryLeg = {
                _id: null,
                ID: null,
                ID_SECONDARY_LEG: null,
                ID_CHARGE_NAME_EXHIBITION: null,
                CHARGE_NAME_EXHIBITION: null,
                APPLICATION: null,
                FLAT: false,
                EQUIPMENT: null,
                WEIGHT_RANGE: null,
                VEHICLE_TYPE: null,
                ID_CURRENCY: null,
                CURRENCY: null,
                PAYMENT_MIN: null,
                PAYMENT_UNITARY: null,
                RECEIVING_MIN: null,
                RECEIVING_UNITARY: null,
                SHORT_NAME: null,
                OPERATOR_TYPE: null,
                ID_SECONDARY_PROVIDER: null,
                SECONDARY_PROVIDER: null,
                ID_SECONDARY_LEGAL_PERSON_AGENT: null,
                SECONDARY_LEGAL_PERSON_AGENT: null,
                NO_CHARGE: false
            };

            if (!this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE) this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE = [];

            this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE.push(angular.copy(secondLegCharge));

            this.$timeout(async () => {
                for (let i = 0; i < this.$scope.model.MAIN_LEG[parentIndex].CHARGE.length; i++) {
                    this.$scope.selectorValidity('chargeNameExhibition' + parentIndex + '-' + childIndex + '-' + i);
                    this.$scope.selectorValidity('paramSecondaryLeg' + parentIndex + '-' + childIndex + '-' + i);
                    this.$scope.selectorValidity('weightRange' + parentIndex + '-' + childIndex + '-' + i);
                    this.$scope.selectorValidity('equipment' + parentIndex + '-' + childIndex + '-' + i);
                    this.$scope.selectorValidity('currency' + parentIndex + '-' + childIndex + '-' + i);
                    this.$scope.selectorValidity('paymentUnitary' + parentIndex + '-' + childIndex + '-' + i);
                    this.$scope.selectorValidity('paymentMin' + parentIndex + '-' + childIndex + '-' + i);
                    this.$scope.selectorValidity('receivingUnitary' + parentIndex + '-' + childIndex + '-' + i);
                    this.$scope.selectorValidity('receivingMin' + parentIndex + '-' + childIndex + '-' + i);
                    this.$scope.selectorValidity('shortName' + parentIndex + '-' + childIndex + '-' + i);
                    this.$scope.selectorValidity('secondaryOperator' + parentIndex + '-' + childIndex + '-' + i);
                }
                angular.element(document).ready(() => {
                    this.addFocusEventOnParam();
                    this.addFocusEventOnParamSecondaryLeg();
                });
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async removeSecondaryLegCharge(parentIndex: number, childIndex: number, index: number): Promise<void> {
        try {
            if (!parentIndex && parentIndex !== 0) throw Error('parentIndex is null');
            if (!childIndex && childIndex !== 0) throw Error('childIndex is null');
            if (!index && index !== 0) throw Error('index is null');

            const thatTranslated = this.formService.getTranslate("GENERAL.GENDER.THAT", null, true);
            const originTranslated = this.formService.getTranslate("BASIC_DATA.ORIGIN", null, true);
            const modal = await this.ModalService.showModalConfirmation({}, {
                actionButtonText: "GENERAL.CONFIRM",
                headerText: "GENERAL.CONFIRM_ACTION",
                closeButtonText: "GENERAL.CLOSE",
                bodyText: this.formService.getTranslate("GENERAL.MESSAGES.CONFIRMATION.REMOVAL", { gender: thatTranslated, prop: originTranslated })
            });

            if (!modal) return;

            this.formService.block();

            if (this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE) {
                this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE.splice(index, 1);
            }

            this.formService.unblock();
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async duplicateSecondaryLegCharge(parentIndex: number, childIndex: number, index: number): Promise<void> {
        try {
            if (!parentIndex && parentIndex != 0) throw Error('parentIndex is null');
            if (!childIndex && childIndex != 0) throw Error('childIndex is null');
            if (!index && index != 0) throw Error('index is null');

            if (this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE && this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE.length > 0 && this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE[index]) {
                this.formService.block();
                const copyCharge = angular.copy(this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE[index]);
                copyCharge._id = null;
                copyCharge.ID = null;
                this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE.push(copyCharge);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private isSecondaryLegChargeAirFieldsDisabled(chargeList: IChargeMainLeg[], chargeIndex: number, application: IApplicationList, isReceivingField?: boolean, noCharge?: boolean): boolean {
        try {
            let isAirConditionDisabled = false;
            if (this.$scope.isAirProduct) {
                const tariffCharge = chargeList[chargeIndex];
                const firstWeightRangeChargeIndex = chargeList && chargeList.length ? chargeList.findIndex(charge => charge.APPLICATION && charge.APPLICATION.APPLICATION_COMPLEMENT && charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE && (charge.CHARGE_NAME_EXHIBITION && tariffCharge.CHARGE_NAME_EXHIBITION && charge.CHARGE_NAME_EXHIBITION.ID == tariffCharge.CHARGE_NAME_EXHIBITION.ID)) : -1;
                const isWeightRangeApplication = application && application.APPLICATION_COMPLEMENT && application.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
                isAirConditionDisabled = isWeightRangeApplication && chargeIndex != firstWeightRangeChargeIndex;
            }
            if (isReceivingField) return noCharge || isAirConditionDisabled;
            else return isAirConditionDisabled;
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private secondaryLegChargeAirFieldsChange(mainLegIndex: number, secondaryLegIndex: number, chargeIndex: number, isCurrencyField?: boolean, isPaymentField?: boolean, isReceivingField?: boolean): void {
        try {
            if (!mainLegIndex && mainLegIndex != 0) throw Error('mainLegIndex is null');
            if (!secondaryLegIndex && secondaryLegIndex != 0) throw Error('secondaryLegIndex is null');
            if (!chargeIndex && chargeIndex != 0) throw Error('chargeIndex is null');
            if (this.$scope.isAirProduct) {
                const mainLeg = this.$scope.model.MAIN_LEG && this.$scope.model.MAIN_LEG[mainLegIndex] ? this.$scope.model.MAIN_LEG[mainLegIndex] : null;
                const secondaryLeg = mainLeg && mainLeg.SECONDARY_LEG && mainLeg.SECONDARY_LEG.length ? mainLeg.SECONDARY_LEG[secondaryLegIndex] : null;
                if (secondaryLeg && secondaryLeg.CHARGE && secondaryLeg.CHARGE.length) {
                    const secondaryLegCharge = secondaryLeg.CHARGE[chargeIndex];
                    if (secondaryLegCharge && secondaryLegCharge.CHARGE_NAME_EXHIBITION) {
                        const firstWeightRangeChargeIndex = secondaryLeg.CHARGE.findIndex(charge => charge.APPLICATION && charge.APPLICATION.APPLICATION_COMPLEMENT && charge.APPLICATION.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE && charge.CHARGE_NAME_EXHIBITION && charge.CHARGE_NAME_EXHIBITION.ID == secondaryLegCharge.CHARGE_NAME_EXHIBITION.ID);
                        for (const secondaryCharge of secondaryLeg.CHARGE) {
                            if (secondaryCharge.CHARGE_NAME_EXHIBITION && secondaryCharge.CHARGE_NAME_EXHIBITION.ID == secondaryLegCharge.CHARGE_NAME_EXHIBITION.ID) {
                                const application = secondaryCharge.APPLICATION;
                                const isWeightRangeApplication = application && application.APPLICATION_COMPLEMENT && application.APPLICATION_COMPLEMENT.ID == EApplicationComplementId.WEIGHT_RANGE;
                                if (isWeightRangeApplication) {
                                    if (isCurrencyField) secondaryCharge.CURRENCY = secondaryLeg.CHARGE[firstWeightRangeChargeIndex].CURRENCY;
                                    if (isPaymentField) secondaryCharge.PAYMENT_MIN = secondaryLeg.CHARGE[firstWeightRangeChargeIndex].PAYMENT_MIN;
                                    if (isReceivingField) secondaryCharge.RECEIVING_MIN = secondaryLeg.CHARGE[firstWeightRangeChargeIndex].RECEIVING_MIN;
                                }
                            }
                        }
                    }
                }
            }

        } catch (ex) {
            this.handleError(ex);
        }
    }

    private setCopy(value: boolean): void {
        this.isCopy = value;
    }

    private getChargeApplicationComplement(tariffIndex: number, chargeIndex: number): string {
        try {
            if (!tariffIndex && tariffIndex != 0) throw Error('tariffIndex is null');
            if (!chargeIndex && chargeIndex != 0) throw Error('chargeIndex is null');
            const mainRoutes = this.$scope.model.MAIN_LEG;

            if (
                mainRoutes &&
                mainRoutes[tariffIndex] &&
                mainRoutes[tariffIndex].CHARGE &&
                mainRoutes[tariffIndex].CHARGE[chargeIndex] &&
                mainRoutes[tariffIndex].CHARGE[chargeIndex].APPLICATION &&
                mainRoutes[tariffIndex].CHARGE[chargeIndex].APPLICATION.APPLICATION_COMPLEMENT &&
                mainRoutes[tariffIndex].CHARGE[chargeIndex].APPLICATION.APPLICATION_COMPLEMENT.ID
            ) {
                return mainRoutes[tariffIndex].CHARGE[chargeIndex].APPLICATION.APPLICATION_COMPLEMENT.ID;
            }
            return null;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private getSecondaryLegChargeApplicationComplement(tariffIndex: number, secondaryLegIndex: number, chargeIndex: number): string {
        try {
            if (!tariffIndex && tariffIndex != 0) throw Error('tariffIndex is null');
            if (!secondaryLegIndex && secondaryLegIndex != 0) throw Error('secondaryLegIndex is null');
            if (!chargeIndex && chargeIndex != 0) throw Error('chargeIndex is null');
            if (
                this.$scope.model.MAIN_LEG &&
                this.$scope.model.MAIN_LEG[tariffIndex] &&
                this.$scope.model.MAIN_LEG[tariffIndex].SECONDARY_LEG &&
                this.$scope.model.MAIN_LEG[tariffIndex].SECONDARY_LEG[secondaryLegIndex] &&
                this.$scope.model.MAIN_LEG[tariffIndex].SECONDARY_LEG[secondaryLegIndex].CHARGE &&
                this.$scope.model.MAIN_LEG[tariffIndex].SECONDARY_LEG[secondaryLegIndex].CHARGE[chargeIndex] &&
                this.$scope.model.MAIN_LEG[tariffIndex].SECONDARY_LEG[secondaryLegIndex].CHARGE[chargeIndex].APPLICATION &&
                this.$scope.model.MAIN_LEG[tariffIndex].SECONDARY_LEG[secondaryLegIndex].CHARGE[chargeIndex].APPLICATION.APPLICATION_COMPLEMENT &&
                this.$scope.model.MAIN_LEG[tariffIndex].SECONDARY_LEG[secondaryLegIndex].CHARGE[chargeIndex].APPLICATION.APPLICATION_COMPLEMENT.ID
            ) {
                return this.$scope.model.MAIN_LEG[tariffIndex].SECONDARY_LEG[secondaryLegIndex].CHARGE[chargeIndex].APPLICATION.APPLICATION_COMPLEMENT.ID;
            }
            return null;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async getRoutingPointListByName(parentIndex: number, search: string, type: string): Promise<void> {
        let result = [];
        try {
            if (!parentIndex && parentIndex !== 0) throw Error('index is null');
            if (!type) throw Error('type is null');

            if (search && search.length >= 2) {
                this.formService.block();

                let types = [];
                if (type == 'origin') {
                    if (!this.$scope.model.MAIN_LEG[parentIndex] || !this.$scope.model.MAIN_LEG[parentIndex].ORIGIN_TYPE || !this.$scope.model.MAIN_LEG[parentIndex].ORIGIN_TYPE.ID) {
                        throw Error('Select origin type first');
                    }
                    types = [this.$scope.model.MAIN_LEG[parentIndex].ORIGIN_TYPE.ID];
                } else if (type == 'destination') {
                    if (!this.$scope.model.MAIN_LEG[parentIndex] || !this.$scope.model.MAIN_LEG[parentIndex].DESTINATION_TYPE || !this.$scope.model.MAIN_LEG[parentIndex].DESTINATION_TYPE.ID) {
                        throw Error('Select destination type first');
                    }
                    types = [this.$scope.model.MAIN_LEG[parentIndex].DESTINATION_TYPE.ID];
                }
                const routingPoints = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/routingPoint/list/custom/`, { name: search, types }, 30000, false);
                result = routingPoints ? routingPoints.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, DISPLAY_NAME: x.DISPLAY_NAME } }) : [];

            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.routingPointList = result;
            this.formService.unblock();
        }
    }

    private async getSecondaryLegRoutingPointListByName(parentIndex: number, childIndex: number, search: string, type: string): Promise<void> {
        let result = [];
        try {
            if (!parentIndex && parentIndex !== 0) throw Error('parentIndex is null');
            if (!childIndex && childIndex !== 0) throw Error('childIndex is null');
            if (!type) throw Error('type is null');

            if (search && search.length >= 2) {
                this.formService.block();

                let types = [];
                if (type == 'origin') {
                    if (!this.$scope.model.MAIN_LEG[parentIndex] || !this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG || !this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex] || !this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].ORIGIN_TYPE || !this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].ORIGIN_TYPE.ID) {
                        throw Error('Select origin type first');
                    }
                    types = [this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].ORIGIN_TYPE.ID];
                } else if (type == 'destination') {
                    if (!this.$scope.model.MAIN_LEG[parentIndex] || !this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG || !this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex] || !this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].DESTINATION_TYPE || !this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].DESTINATION_TYPE.ID) {
                        throw Error('Select destination type first');
                    }
                    types = [this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].DESTINATION_TYPE.ID];
                }
                const routingPoints = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/routingPoint/list/custom/`, { name: search, types }, 30000, false);
                result = routingPoints ? routingPoints.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, DISPLAY_NAME: x.DISPLAY_NAME } }) : [];

            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.routingPointList = result;
            this.formService.unblock();
        }
    }

    private async getProviderListByName(search: string, operatorType: SelectorModel): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                if (!operatorType || !operatorType.ID) throw Error(this.formService.getTranslate("PRODUCT.SELECT_SPECIALIZATION_FIRST"));

                let types = [];
                if (operatorType.ID == '1') {
                    types = [EProviderTypeId.SHIPOWNER];
                } else if (operatorType.ID == '2') {
                    types = [EProviderTypeId.SHIPPING_COMPANY];
                } else if (operatorType.ID == '4') {
                    types = [EProviderTypeId.AIRLINE];
                } else {
                    throw Error(`Select a valid specialization`);
                }
                this.formService.block();

                const providers: IProviderModel[] = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/provider/list/custom`, { search, types }, 30000, false);
                result = providers ? providers.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.SCAC_IATA } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.providerList = result;
        }
    }

    private async getLogisticSolutionListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {

                if (!this.$scope.model.PRODUCT) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                if (!this.$scope.model.DIRECTION) throw Error(this.formService.getTranslate("PRODUCT.SELECT_DIRECTION_FIRST"));
                if (!this.$scope.model.OPERATOR_TYPE) throw Error(this.formService.getTranslate("PRODUCT.SELECT_SPECIALIZATION_FIRST"));
                if (!this.$scope.model.PROVIDER && !this.$scope.model.LEGAL_PERSON_AGENT) throw Error(this.formService.getTranslate("PRODUCT.SELECT_OPERATOR_FIRST"));

                this.formService.block();

                let products = [this.$scope.model.PRODUCT.ID];
                let direction = this.$scope.model.DIRECTION.ID;
                let operatorType = this.$scope.model.OPERATOR_TYPE.ID;

                let providers = [];
                if (this.$scope.model.PROVIDER && this.$scope.model.PROVIDER.ID) providers = [this.$scope.model.PROVIDER.ID];
                if (this.$scope.model.LEGAL_PERSON_AGENT && this.$scope.model.LEGAL_PERSON_AGENT.ID) providers = [this.$scope.model.LEGAL_PERSON_AGENT.ID];

                const logisticSolutions: IDomesticServiceModel[] = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/domesticService/list/custom`, { search, products, providers, direction, operatorType }, 30000, false);
                result = logisticSolutions ? logisticSolutions.map(x => { return { ID: x.ID, NAME: x.LOGISTIC_SOLUTION } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.logisticSolutionList = result;
        }
    }

    private async getAgentListByName(search: string, operatorType: SelectorModel): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                if (!operatorType || !operatorType.ID) throw Error(this.formService.getTranslate("PRODUCT.SELECT_SPECIALIZATION_FIRST"));
                if (!this.$scope.model.PRODUCT || !this.$scope.model.PRODUCT.ID) throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));

                this.formService.block();

                const products = [this.$scope.model.PRODUCT.ID];
                const agents = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/agent/list/custom`, { search, products }, 30000, false);
                result = agents ? agents.map(x => { return { ID: x.ID, NAME: x.NAME } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.agentList = result;
        }
    }

    private async getCurrencyListByName(search: string): Promise<void> {
        let result: SelectorModel[] = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const currencies = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/currency/list/custom`, { name: search }, 30000, false);
                result = (currencies) ? currencies.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.INITIALS } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.currencyList = result;
        }
    }

    private async getAccountListByName(search: string): Promise<void> {
        let result = [];
        try {
            if (search && search.length >= 2) {
                this.formService.block();

                const accounts = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/account/list/custom`, { search: search }, 30000, false);
                result = accounts ? accounts.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.LEGAL_PERSON ? x.LEGAL_PERSON.SHORT_NAME : null } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.$scope.accountList = result;
            this.formService.unblock();
        }
    }

    private async getChargeNameListByName(search: string): Promise<void> {
        let result: IChargeNameList[] = [];
        try {
            if (search && search.length >= 2) {
                let errorMessage: string = '';
                if (!this.$scope.model.DIRECTION) {
                    errorMessage += (errorMessage ? ', ' : '') + 'direction';
                }
                if (!this.$scope.model.PRODUCT) {
                    errorMessage += (errorMessage ? ', ' : '') + 'product';
                }
                if (!this.$scope.model.TYPE_CARGO) {
                    errorMessage += (errorMessage ? ', ' : '') + 'type cargo';
                }
                if (errorMessage) {
                    throw Error(`Select ${errorMessage} first`);
                }

                this.formService.block();

                let types = ['1'];
                if (this.$scope.model.DIRECTION.ID === '1') {
                    types.push('2');
                } else if (this.$scope.model.DIRECTION.ID === '2') {
                    types.push('3');
                } else {
                    throw Error('Invalid direction');
                }

                const products = [this.$scope.model.PRODUCT.ID];
                const paramTypeCargo = [this.$scope.model.TYPE_CARGO.ID];
                const chargeNames = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/chargeName/list/custom/exhibition`, { search, types, products, paramTypeCargo }, 30000, false);
                result = chargeNames ? chargeNames.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PARAMS: x.PARAMS } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.chargeNameList = result;
        }
    }

    private async getEquipmentListByName(search: string): Promise<void> {
        let result: IEquipmentSelector[] = [];
        try {
            if (search && search.length >= 2) {
                if (!this.$scope.model.PRODUCT) {
                    throw Error(this.formService.getTranslate("PRODUCT.SELECT_PRODUCT_FIRST"));
                }
                this.formService.block();
                const products = [this.$scope.model.PRODUCT.ID];
                const equipmentList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/equipment/list/custom`, { search, products }, 30000, false);
                result = equipmentList ? equipmentList.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, TEU: x.TEU, FEET: x.FEET } }) : [];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.equipmentList = result;
        }
    }

    private async getWeightRangeListByName(search: string): Promise<SelectorModel[]> {
        let result: SelectorModel[] = [];
        try {
            this.formService.block();
            const products = this.$scope.model.PRODUCT ? [this.$scope.model.PRODUCT.ID] : [];
            const weightRangeList = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/weightRange/list/custom`, { search, products }, 30000, false);
            result = weightRangeList ? weightRangeList.map(x => { return { ID: x.ID, NAME: x.NAME } }) : [];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            return result;
        }
    }

    private updateParam(selected: IChargeNameList, parentIndex: number, index: number): void {
        let param: IParams = null;
        try {
            if (!selected) throw Error('selected is null');
            if (!index && index != 0) throw Error('index is null');
            if (!parentIndex && parentIndex != 0) throw Error('parentIndex is null');
            if (selected.PARAMS && selected.PARAMS.length > 0) param = selected.PARAMS.find(param => param.TYPE_CARGO.find(typeCargo => typeCargo.ID === this.$scope.model.TYPE_CARGO.ID));
            if (this.$scope.model.MAIN_LEG && this.$scope.model.MAIN_LEG.length > 0 && this.$scope.model.MAIN_LEG[parentIndex].CHARGE && this.$scope.model.MAIN_LEG[parentIndex].CHARGE[index]) {
                const charge = this.$scope.model.MAIN_LEG[parentIndex].CHARGE[index];
                if (param) {
                    charge.APPLICATION = param.PAYMENT;
                    this.$timeout(() => { this.$scope.selectorValidity(`paramMainLeg${parentIndex}-${index}`); });
                }

                const applicationComplement = this.getChargeApplicationComplement(parentIndex, index);
                if (!applicationComplement || applicationComplement == EApplicationComplementId.NOT_APPLICABLE || applicationComplement == EApplicationComplementId.WEIGHT_RANGE) {
                    if (applicationComplement == EApplicationComplementId.WEIGHT_RANGE) {
                        this.chargeAirFieldsChange(parentIndex, index, false, true);
                        this.chargeAirFieldsChange(parentIndex, index, false, false, true);
                    } else {
                        charge.FLAT = false;
                        charge.WEIGHT_RANGE = null;
                    }
                    charge.EQUIPMENT = null;
                    charge.VEHICLE_TYPE = null;
                    this.$timeout(() => { this.$scope.selectorValidity(`weightRange${parentIndex}${index}`); });
                }
                else if (applicationComplement == EApplicationComplementId.EQUIPMENT) {
                    charge.FLAT = false;
                    charge.WEIGHT_RANGE = null;
                    charge.VEHICLE_TYPE = null;
                    this.$timeout(() => { this.$scope.selectorValidity(`equipment${parentIndex}${index}`); });
                }
                else if (applicationComplement == EApplicationComplementId.VEHICLE) {
                    charge.FLAT = false;
                    charge.WEIGHT_RANGE = null;
                    charge.EQUIPMENT = null;
                    this.$timeout(() => { this.$scope.selectorValidity(`vehicleType${parentIndex}${index}`); });
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private updateParamSecondaryLeg(selected: IChargeNameList, parentIndex: number, childIndex: number, index: number): void {
        let param: IParams = null;
        try {
            if (!selected) throw Error('selected is null');
            if (!parentIndex && parentIndex != 0) throw Error('parentIndex is null');
            if (!childIndex && childIndex != 0) throw Error('childIndex is null');
            if (!index && index != 0) throw Error('index is null');

            if (selected.PARAMS && selected.PARAMS.length) param = selected.PARAMS[0];
            if (this.$scope.model.MAIN_LEG && this.$scope.model.MAIN_LEG.length > 0 && this.$scope.model.MAIN_LEG[parentIndex] && this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG && this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG.length > 0 && this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex] && this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE && this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE.length > 0 && this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE[index]) {
                const charge = this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE[index];
                if (param) {
                    charge.APPLICATION = param.PAYMENT;
                    this.$timeout(() => { this.$scope.selectorValidity(`paramSecondaryLeg${parentIndex}-${childIndex}-${index}`) }, 500);
                }
                const applicationComplement = this.getSecondaryLegChargeApplicationComplement(parentIndex, childIndex, index);
                if (!applicationComplement || applicationComplement == EApplicationComplementId.NOT_APPLICABLE || applicationComplement == EApplicationComplementId.WEIGHT_RANGE) {
                    if (applicationComplement == EApplicationComplementId.WEIGHT_RANGE) {
                        this.secondaryLegChargeAirFieldsChange(parentIndex, childIndex, index, false, true);
                        this.secondaryLegChargeAirFieldsChange(parentIndex, childIndex, index, false, false, true);
                    } else {
                        charge.FLAT = false;
                        charge.WEIGHT_RANGE = null;
                    }
                    charge.EQUIPMENT = null;
                    this.$timeout(() => { this.$scope.selectorValidity(`weightRangeSecondaryLeg${parentIndex}-${childIndex}-${index}`); }, 500);
                }
                else if (applicationComplement == EApplicationComplementId.EQUIPMENT) {
                    charge.FLAT = false;
                    charge.WEIGHT_RANGE = null;
                    this.$timeout(() => { this.$scope.selectorValidity(`equipmentSecondaryLeg${parentIndex}-${childIndex}-${index}`); }, 500);
                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private updateOperatorType(): void {
        try {
            this.$scope.model.PROVIDER = null;
            this.$scope.model.ID_PROVIDER = null;
            this.$scope.model.LOGISTIC_SOLUTION = null;
            this.$scope.model.ID_LOGISTIC_SOLUTION = null;
            this.$scope.model.LEGAL_PERSON_AGENT = null;
            this.$scope.model.ID_LEGAL_PERSON_AGENT = null;
            this.$timeout(() => { this.$scope.selectorValidity("logisticSolution"); })
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private updateOperator(): void {
        try {
            this.$scope.model.LOGISTIC_SOLUTION = null;
            this.$scope.model.ID_LOGISTIC_SOLUTION = null;
            this.$timeout(() => { this.$scope.selectorValidity("logisticSolution"); })
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private updateCargoType(): void {
        try {
            this.$scope.model.LOGISTIC_SOLUTION = null;
            this.$scope.model.ID_LOGISTIC_SOLUTION = null;
            this.$timeout(() => { this.$scope.selectorValidity("logisticSolution"); })
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initDateFields(): void {
        try {
            this.$scope.model.DISPLAY_VALIDITY_START = (this.$scope.model.DISPLAY_VALIDITY_START != null) ? new Date(this.$scope.model.DISPLAY_VALIDITY_START) : null;
            this.$scope.model.VALIDITY_START = (this.$scope.model.VALIDITY_START != null) ? new Date(this.$scope.model.VALIDITY_START) : null;
            this.$scope.model.DISPLAY_VALIDITY_END = (this.$scope.model.DISPLAY_VALIDITY_END != null) ? new Date(this.$scope.model.DISPLAY_VALIDITY_END) : null;
            this.$scope.model.VALIDITY_END = (this.$scope.model.VALIDITY_END != null) ? new Date(this.$scope.model.VALIDITY_END) : null;
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private initEvents(): void {
        angular.element(window).on('resize', () => {
            this.setChargeColStyle();
            this.setSecondaryLegChargeColStyle();
        });
    }

    private getFileUploaderDefault(): FileUploader {
        try {
            const formData: IFormData = { bucket: 'tariff_files', formName: this.formName, folder: this.formName };
            return new this.fileUploader({ url: `${this.getFisFilesGenericRoute()}/upload`, autoUpload: true, formData: [formData] });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private removeUpload(model: IUploadItem): boolean {
        let result = false;
        try {
            if (!model) throw Error('item parameter is null');

            const isAdminOrCoordinator = (this.$scope.user.roles && (this.$scope.user.roles.hasOwnProperty('ADMIN') || this.$scope.user.roles.hasOwnProperty('COORDINATOR')));

            if (isAdminOrCoordinator) {
                result = true;
            } else {
                // check if in model.FILES_NEW;
                if (this.$scope.model.FILES_NEW && this.$scope.model.FILES_NEW.length > 0) {
                    const index = this.$scope.model.FILES_NEW.findIndex(file => { return file.FILE_HASH == model.FILE_HASH });
                    if (index > -1) {
                        // Remove from FILES_NEW
                        this.$scope.model.FILES_NEW.splice(index, 1);
                        result = true;
                    }
                }
            }

            if (result) {
                if (!this.$scope.model.FILES_REMOVE) this.$scope.model.FILES_REMOVE = [];
                this.$scope.model.FILES_REMOVE.push(model);
            } else {
                throw Error('You don\'t have permission to remove this file');
            }
        } catch (ex) {
            result = false;
            this.handleError(ex);
        } finally {
            return result;
        }
    }

    private onSuccessItem(item: IFileItem, response: ISuccessItemResponse): void {
        try {
            if (!item) throw Error('item parameter is null');
            if (!response) throw Error('response parameter is null');

            if (response.data && response.data.length > 0) {
                if (!this.$scope.model.FILES_NEW) this.$scope.model.FILES_NEW = [];

                for (let dataItem of response.data) {
                    const file: IUploadItem = {
                        FILE_DISPLAY_NAME: dataItem.displayFileName,
                        FILE_NAME: dataItem.fileName,
                        FILE_TYPE: dataItem.fileType,
                        FILE_PATH: dataItem.filePath,
                        FILE_URL: dataItem.fileUrl,
                        FILE_HASH: dataItem.fileHash,
                        FILE_SIZE: dataItem.fileSize,
                        FORM_NAME: dataItem.formName
                    }
                    this.$scope.model.FILES_NEW.push(file)
                }
            }
        } catch (ex) {
            this.handleError(ex);
        }
    }

    private async getChargeNameParamsList(parentIndex: number, index: number): Promise<void> {
        let result: IApplicationList[] = [];
        try {
            this.$scope.chargeApplicationList = result;
            if (!parentIndex && parentIndex != 0) throw Error('parentIndex is null');
            if (!index && index != 0) throw Error('index is null');

            if (!this.$scope.model.MAIN_LEG || !this.$scope.model.MAIN_LEG[parentIndex] || !this.$scope.model.MAIN_LEG[parentIndex].CHARGE || !this.$scope.model.MAIN_LEG[parentIndex].CHARGE[index] || !this.$scope.model.MAIN_LEG[parentIndex].CHARGE[index].CHARGE_NAME_EXHIBITION || !this.$scope.model.MAIN_LEG[parentIndex].CHARGE[index].CHARGE_NAME_EXHIBITION.ID) {
                throw Error(this.formService.getTranslate('PRODUCT.SELECT_CHARGENAME_FIRST'));
            }
            this.formService.block();
            const products = this.$scope.model.PRODUCT ? [this.$scope.model.PRODUCT.ID] : null;
            const paramsTypeCargo = this.$scope.model.TYPE_CARGO ? [this.$scope.model.TYPE_CARGO.ID] : null;

            const params = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/application/list/custom`, { products: products, typeCargos: paramsTypeCargo }, 30000, false);
            result = params ? params.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PERCENT: x.PERCENT, APPLICATION_COMPLEMENT: x.APPLICATION_COMPLEMENT, INTERNAL_SEQUENCE: x.INTERNAL_SEQUENCE, FREE_TYPING_AMOUNT_CHARGE: x.FREE_TYPING_AMOUNT_CHARGE, CT_WITHOUT_DOC: x.CT_WITHOUT_DOC, NAME_INTL: x.NAME_INTL } }) : [];
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.chargeApplicationList = result;
        }
    }

    private addFocusEventOnParam(mainLegIndex?: number): void {
        try {
            if (this.$scope.model.MAIN_LEG && this.$scope.model.MAIN_LEG.length > 0) {
                if (mainLegIndex || mainLegIndex == 0) {
                    if (this.$scope.model.MAIN_LEG[mainLegIndex].CHARGE && this.$scope.model.MAIN_LEG[mainLegIndex].CHARGE.length > 0) {
                        for (let index = 0; index < this.$scope.model.MAIN_LEG[mainLegIndex].CHARGE.length; index++) {
                            $("#paramSelector" + mainLegIndex + '-' + index).unbind('focus').on('focus', async (event) => {
                                event.stopPropagation();
                                await this.getChargeNameParamsList(mainLegIndex, index);
                            });
                        }
                    }
                } else {
                    for (let parentIndex = 0; parentIndex < this.$scope.model.MAIN_LEG.length; parentIndex++) {
                        const mainLeg = this.$scope.model.MAIN_LEG[parentIndex];
                        if (mainLeg.CHARGE && mainLeg.CHARGE.length > 0) {
                            for (let index = 0; index < mainLeg.CHARGE.length; index++) {
                                $("#paramSelector" + parentIndex + '-' + index).unbind('focus').on('focus', async (event) => {
                                    event.stopPropagation();
                                    await this.getChargeNameParamsList(parentIndex, index);
                                });
                            }
                        }
                    }
                }
            }
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private async getChargeNameParamsListSecondaryLeg(parentIndex: number, childIndex: number, index: number): Promise<void> {
        let result: IApplicationList[] = [];
        try {
            this.$scope.chargeApplicationList = result;
            if (!parentIndex && parentIndex != 0) throw Error('parentIndex is null');
            if (!childIndex && childIndex != 0) throw Error('childIndex is null');
            if (!index && index != 0) throw Error('index is null');

            if (!this.$scope.model.MAIN_LEG || !this.$scope.model.MAIN_LEG[parentIndex] || !this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG || !this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex] || !this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE || !this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE[index] || !this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE[index].CHARGE_NAME_EXHIBITION || !this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE[index].CHARGE_NAME_EXHIBITION.ID) {
                throw Error(this.formService.getTranslate('PRODUCT.SELECT_CHARGENAME_FIRST'));
            }
            this.formService.block();

            const products = this.$scope.model.PRODUCT ? [this.$scope.model.PRODUCT.ID] : null;
            const paramsTypeCargo = this.$scope.model.TYPE_CARGO ? [this.$scope.model.TYPE_CARGO.ID] : null;

            const params = await this.RestService.newObjectPromise(`${this.getUrlProduct()}/application/list/custom`, { products: products, typeCargos: paramsTypeCargo }, 30000, false);
            result = params ? params.map(x => { return { ID: x.ID, NAME: x.NAME, CODE: x.CODE, PERCENT: x.PERCENT, APPLICATION_COMPLEMENT: x.APPLICATION_COMPLEMENT, INTERNAL_SEQUENCE: x.INTERNAL_SEQUENCE, FREE_TYPING_AMOUNT_CHARGE: x.FREE_TYPING_AMOUNT_CHARGE, CT_WITHOUT_DOC: x.CT_WITHOUT_DOC, NAME_INTL: x.NAME_INTL } }) : [];

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
            this.$scope.chargeApplicationList = result;
        }
    }

    private addFocusEventOnParamSecondaryLeg(mainLegIndex?: number): void {
        try {
            if (this.$scope.model.MAIN_LEG && this.$scope.model.MAIN_LEG.length) {
                if (mainLegIndex || mainLegIndex == 0) {
                    if (this.$scope.model.MAIN_LEG[mainLegIndex].SECONDARY_LEG && this.$scope.model.MAIN_LEG[mainLegIndex].SECONDARY_LEG.length > 0) {
                        for (let childIndex = 0; childIndex < this.$scope.model.MAIN_LEG[mainLegIndex].SECONDARY_LEG.length; childIndex++) {
                            const secondaryLeg = this.$scope.model.MAIN_LEG[mainLegIndex].SECONDARY_LEG[childIndex];
                            if (secondaryLeg.CHARGE && secondaryLeg.CHARGE.length) {
                                for (let index = 0; index < secondaryLeg.CHARGE.length; index++) {
                                    $("#paramSelector" + mainLegIndex + '-' + childIndex + '-' + index).unbind('focus').on('focus', async (event) => {
                                        event.stopPropagation();
                                        await this.getChargeNameParamsListSecondaryLeg(mainLegIndex, childIndex, index);
                                    });
                                }
                            }
                        }
                    }
                } else {
                    for (let parentIndex = 0; parentIndex < this.$scope.model.MAIN_LEG.length; parentIndex++) {
                        const mainLeg = this.$scope.model.MAIN_LEG[parentIndex];
                        if (mainLeg.SECONDARY_LEG && mainLeg.SECONDARY_LEG.length > 0) {
                            for (let childIndex = 0; childIndex < mainLeg.SECONDARY_LEG.length; childIndex++) {
                                const secondaryLeg = mainLeg.SECONDARY_LEG[childIndex];
                                if (secondaryLeg.CHARGE && secondaryLeg.CHARGE.length) {
                                    for (let index = 0; index < secondaryLeg.CHARGE.length; index++) {
                                        $("#paramSelector" + parentIndex + '-' + childIndex + '-' + index).unbind('focus').on('focus', async (event) => {
                                            event.stopPropagation();
                                            await this.getChargeNameParamsListSecondaryLeg(parentIndex, childIndex, index);
                                        });
                                    }
                                }
                            }
                        }
                    }
                }
            }
        } catch (ex) {
            this.handleLoadError(ex);
        }
    }

    private generateConcatenated(): void {
        try {
            const separator = " | ";
            let concatenated = "";

            if ((this.$scope.model.PROVIDER && this.$scope.model.PROVIDER.CODE && this.$scope.model.PROVIDER.CODE.length) || (this.$scope.model.OPERATOR_TYPE && this.$scope.model.OPERATOR_TYPE.ID === EDomesticProviderType.TRANSPORT_COMPANY)) {
                concatenated = concatenated.length > 0
                    ? concatenated.concat(separator, this.$scope.model.PROVIDER && this.$scope.model.PROVIDER.TYPE && (this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.SHIPOWNER || this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.AIRLINE) ? this.$scope.model.PROVIDER.CODE : this.$scope.model.PROVIDER.NAME)
                    : concatenated.concat(this.$scope.model.PROVIDER && this.$scope.model.PROVIDER.TYPE && (this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.SHIPOWNER || this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.AIRLINE) ? this.$scope.model.PROVIDER.CODE : this.$scope.model.PROVIDER.NAME);
            }

            if (this.$scope.model.LEGAL_PERSON_AGENT && this.$scope.model.LEGAL_PERSON_AGENT.NAME && this.$scope.model.LEGAL_PERSON_AGENT.NAME.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.LEGAL_PERSON_AGENT.NAME) : concatenated.concat(this.$scope.model.LEGAL_PERSON_AGENT.NAME);
            }

            if (this.$scope.model.ACCOUNTS && this.$scope.model.ACCOUNTS.length > 0) {
                concatenated = concatenated.length > 0
                    ? concatenated.concat(separator, this.$scope.model.ACCOUNTS.map(obj => { return obj.NAME }).join(','))
                    : concatenated.concat(this.$scope.model.ACCOUNTS.map(obj => { return obj.NAME }).join(','));
            }

            if (this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.ID && this.$scope.model.PRODUCT.ID.length > 0) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PRODUCT.ID) : concatenated.concat(this.$scope.model.PRODUCT.ID);
            }

            if (this.$scope.model.TYPE_CARGO && this.$scope.model.TYPE_CARGO.NAME) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.TYPE_CARGO.NAME) : concatenated.concat(this.$scope.model.TYPE_CARGO.NAME);
            }

            if (this.$scope.model.DIRECTION && this.$scope.model.DIRECTION.ID && this.$scope.model.DIRECTION.ID.length > 0) {
                let direction = null;
                if (this.$scope.model.DIRECTION.ID === '1') {
                    direction = 'OR';
                } else if (this.$scope.model.DIRECTION.ID === '2') {
                    direction = 'DE';
                }
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, direction) : concatenated.concat(direction);
            }

            if (this.$scope.model.VERSION) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, 'V' + this.$scope.model.VERSION) : concatenated.concat('V' + this.$scope.model.VERSION);
            }

            if (this.$scope.model.CONCATENATED_COMPLEMENT && this.$scope.model.CONCATENATED_COMPLEMENT.length) {
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.CONCATENATED_COMPLEMENT) : concatenated.concat(this.$scope.model.CONCATENATED_COMPLEMENT);
            }

            if (this.$scope.model.DISPLAY_VALIDITY_START) {
                const validity = this.$scope.model.DISPLAY_VALIDITY_END ? moment(this.$scope.model.DISPLAY_VALIDITY_START).format('DD/MM/YY') + '-' + moment(this.$scope.model.DISPLAY_VALIDITY_END).format('DD/MM/YY') : moment(this.$scope.model.DISPLAY_VALIDITY_START).format('DD/MM/YY');
                concatenated = concatenated.length > 0 ? concatenated.concat(separator, validity) : concatenated.concat(validity);
            }

            this.$scope.model.CONCATENATED = StringUtil.formatConcatenatedChars(concatenated);

            if (this.$scope.menuFloating) {
                this.$scope.menuFloating.infos = [{ text: this.$scope.model.CONCATENATED, class: "text-rouge font-bold" }];
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private generateAllConcatenatedLegs(): void {
        if (this.$scope.model.MAIN_LEG && this.$scope.model.MAIN_LEG.length) {
            for (let index = 0; index < this.$scope.model.MAIN_LEG.length; index++) {
                this.generateConcatenatedLeg(index);
            }
        }
    }

    private generateConcatenatedLeg(parentIndex: number): void {
        if (!parentIndex && parentIndex != 0) throw Error('parentIndex is null');
        try {
            if (this.$scope.model.MAIN_LEG && this.$scope.model.MAIN_LEG[parentIndex]) {
                let concatenated = "";
                const firstLeg = this.$scope.model.MAIN_LEG[parentIndex];
                const separator = " | ";

                const typeCodes = {
                    '2': 'P',
                    '3': 'D',
                    '4': 'A',
                    '5': 'T'
                };

                if (this.$scope.model.PROVIDER && this.$scope.model.PROVIDER.NAME) {
                    concatenated = concatenated.length > 0
                        ? concatenated.concat(separator, this.$scope.model.PROVIDER.TYPE && (this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.SHIPOWNER || this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.AIRLINE) ? this.$scope.model.PROVIDER.CODE : this.$scope.model.PROVIDER.NAME)
                        : concatenated.concat(this.$scope.model.PROVIDER.TYPE && (this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.SHIPOWNER || this.$scope.model.PROVIDER.TYPE.ID == EProviderTypeId.AIRLINE) ? this.$scope.model.PROVIDER.CODE : this.$scope.model.PROVIDER.NAME);
                }

                if (this.$scope.model.ACCOUNTS && this.$scope.model.ACCOUNTS.length > 0) {
                    concatenated = concatenated.length > 0
                        ? concatenated.concat(separator, this.$scope.model.ACCOUNTS.map(obj => { return obj.NAME }).join(','))
                        : concatenated.concat(this.$scope.model.ACCOUNTS.map(obj => { return obj.NAME }).join(','));
                }

                if (this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.ID && this.$scope.model.PRODUCT.ID.length > 0) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.PRODUCT.ID) : concatenated.concat(this.$scope.model.PRODUCT.ID);
                }

                if (this.$scope.model.TYPE_CARGO && this.$scope.model.TYPE_CARGO.NAME) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, this.$scope.model.TYPE_CARGO.NAME) : concatenated.concat(this.$scope.model.TYPE_CARGO.NAME);
                }

                if (this.$scope.model.DIRECTION && this.$scope.model.DIRECTION.ID && this.$scope.model.DIRECTION.ID.length > 0) {
                    let direction = null;
                    if (this.$scope.model.DIRECTION.ID === '1') {
                        direction = 'OR';
                    } else if (this.$scope.model.DIRECTION.ID === '2') {
                        direction = 'DE';
                    }
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, direction) : concatenated.concat(direction);
                }

                if (this.$scope.model.VERSION) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, 'V' + this.$scope.model.VERSION) : concatenated.concat('V' + this.$scope.model.VERSION);
                }

                if (firstLeg.CONCATENATED_COMPLEMENT && firstLeg.CONCATENATED_COMPLEMENT.length) {
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, firstLeg.CONCATENATED_COMPLEMENT) : concatenated.concat(firstLeg.CONCATENATED_COMPLEMENT);
                }

                if (firstLeg.ORIGIN_TYPE && firstLeg.ORIGIN_TYPE.ID && firstLeg.DESTINATION_TYPE && firstLeg.DESTINATION_TYPE.ID) {
                    concatenated = concatenated.length ? concatenated.concat(separator, typeCodes[firstLeg.ORIGIN_TYPE.ID] + '/' + typeCodes[firstLeg.DESTINATION_TYPE.ID]) : concatenated.concat(typeCodes[firstLeg.ORIGIN_TYPE.ID] + '/' + typeCodes[firstLeg.DESTINATION_TYPE.ID]);
                }

                if (firstLeg.ORIGIN && firstLeg.ORIGIN.length > 0 && firstLeg.DESTINATION && firstLeg.DESTINATION.length > 0) {
                    concatenated = concatenated.length > 0
                        ? concatenated.concat(separator, firstLeg.ORIGIN.map(obj => { return obj.CODE }).join(',')) + '-' + firstLeg.DESTINATION.map(obj => { return obj.CODE }).join(',')
                        : concatenated.concat(firstLeg.ORIGIN.map(obj => { return obj.CODE }).join(',')) + '-' + firstLeg.DESTINATION.map(obj => { return obj.CODE }).join(',');
                }

                if (this.$scope.model.DISPLAY_VALIDITY_START) {
                    const validity = this.$scope.model.DISPLAY_VALIDITY_END ? moment(this.$scope.model.DISPLAY_VALIDITY_START).format('DD/MM/YY') + '-' + moment(this.$scope.model.DISPLAY_VALIDITY_END).format('DD/MM/YY') : moment(this.$scope.model.DISPLAY_VALIDITY_START).format('DD/MM/YY');
                    concatenated = concatenated.length > 0 ? concatenated.concat(separator, validity) : concatenated.concat(validity);
                }
                this.$scope.model.MAIN_LEG[parentIndex].CONCATENATED = concatenated;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private updatePaymentMin(parentIndex: number, index: number): void {
        try {
            if (!parentIndex && parentIndex != 0) throw Error('parentIndex is null');
            if (!index && index != 0) throw Error('index is null');

            if (this.$scope.model.MAIN_LEG[parentIndex] && this.$scope.model.MAIN_LEG[parentIndex].CHARGE && this.$scope.model.MAIN_LEG[parentIndex].CHARGE[index]) {
                this.$scope.model.MAIN_LEG[parentIndex].CHARGE[index].PAYMENT_MIN = this.$scope.model.MAIN_LEG[parentIndex].CHARGE[index].PAYMENT_UNITARY;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private updateReceivingMin(parentIndex: number, index: number): void {
        try {
            if (!parentIndex && parentIndex != 0) throw Error('parentIndex is null');
            if (!index && index != 0) throw Error('index is null');

            if (this.$scope.model.MAIN_LEG[parentIndex] && this.$scope.model.MAIN_LEG[parentIndex].CHARGE && this.$scope.model.MAIN_LEG[parentIndex].CHARGE[index]) {
                this.$scope.model.MAIN_LEG[parentIndex].CHARGE[index].RECEIVING_MIN = this.$scope.model.MAIN_LEG[parentIndex].CHARGE[index].RECEIVING_UNITARY;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private updatePaymentMinSecondaryLeg(parentIndex: number, childIndex: number, index: number): void {
        try {
            if (!parentIndex && parentIndex != 0) throw Error('parentIndex is null');
            if (!childIndex && childIndex != 0) throw Error('childIndex is null');
            if (!index && index != 0) throw Error('index is null');

            if (this.$scope.model.MAIN_LEG[parentIndex] && this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG && this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex] && this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE && this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE[index]) {
                this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE[index].PAYMENT_MIN = this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE[index].PAYMENT_UNITARY;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private updateReceivingMinSecondaryLeg(parentIndex: number, childIndex: number, index: number): void {
        try {
            if (!parentIndex && parentIndex != 0) throw Error('parentIndex is null');
            if (!childIndex && childIndex != 0) throw Error('childIndex is null');
            if (!index && index != 0) throw Error('index is null');

            if (this.$scope.model.MAIN_LEG[parentIndex] && this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG && this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex] && this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE && this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE[index]) {
                this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE[index].RECEIVING_MIN = this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE[index].RECEIVING_UNITARY;
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private filterOriginType(): SelectorModel[] {
        let result: SelectorModel[] = [];
        try {
            if (this.$scope.originDestinationTypeList && this.$scope.originDestinationTypeList.length > 0 && this.$scope.model.DIRECTION && this.$scope.model.PRODUCT) {
                if (this.$scope.model.DIRECTION.ID == EDirectionId.ORIGIN && (this.$scope.isMaritimeProduct || this.$scope.isAirProduct)) {
                    result = this.$scope.originDestinationTypeList.filter(el => el.ID == '3' || el.ID == '5');

                } else if (this.$scope.model.DIRECTION.ID == EDirectionId.DESTINATION && this.$scope.isMaritimeProduct) {
                    result = this.$scope.originDestinationTypeList.filter(el => el.ID == '2' || el.ID == '5');

                } else if (this.$scope.model.DIRECTION.ID == EDirectionId.DESTINATION && this.$scope.isAirProduct) {
                    result = this.$scope.originDestinationTypeList.filter(el => el.ID == '4' || el.ID == '5');

                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return result;
        }
    }

    private filterDestinationType(parentIndex: number): SelectorModel[] {
        let result: SelectorModel[] = [];
        try {
            if (this.$scope.originDestinationTypeList && this.$scope.originDestinationTypeList.length > 0 && this.$scope.model.DIRECTION && this.$scope.model.PRODUCT) {
                if (this.$scope.model.DIRECTION.ID == EDirectionId.ORIGIN && this.$scope.isMaritimeProduct) {
                    if (this.$scope.model.MAIN_LEG[parentIndex].ORIGIN_TYPE && this.$scope.model.MAIN_LEG[parentIndex].ORIGIN_TYPE.ID == '3') {
                        result = this.$scope.originDestinationTypeList.filter(el => el.ID == '2' || el.ID == '5');
                    } else {
                        result = this.$scope.originDestinationTypeList.filter(el => el.ID == '2');
                    }
                } else if (this.$scope.model.DIRECTION.ID == EDirectionId.ORIGIN && this.$scope.isAirProduct) {
                    if (this.$scope.model.MAIN_LEG[parentIndex].ORIGIN_TYPE && this.$scope.model.MAIN_LEG[parentIndex].ORIGIN_TYPE.ID == '3') {
                        result = this.$scope.originDestinationTypeList.filter(el => el.ID == '4' || el.ID == '5');
                    } else {
                        result = this.$scope.originDestinationTypeList.filter(el => el.ID == '4');
                    }

                } else if (this.$scope.model.DIRECTION.ID == EDirectionId.DESTINATION && this.$scope.isMaritimeProduct) {
                    if (this.$scope.model.MAIN_LEG[parentIndex].ORIGIN_TYPE && this.$scope.model.MAIN_LEG[parentIndex].ORIGIN_TYPE.ID == '2') {
                        result = this.$scope.originDestinationTypeList.filter(el => el.ID == '3' || el.ID == '5');
                    } else {
                        result = this.$scope.originDestinationTypeList.filter(el => el.ID == '3');
                    }

                } else if (this.$scope.model.DIRECTION.ID == EDirectionId.DESTINATION && this.$scope.isAirProduct) {
                    if (this.$scope.model.MAIN_LEG[parentIndex].ORIGIN_TYPE && this.$scope.model.MAIN_LEG[parentIndex].ORIGIN_TYPE.ID == '4') {
                        result = this.$scope.originDestinationTypeList.filter(el => el.ID == '3' || el.ID == '5');
                    } else {
                        result = this.$scope.originDestinationTypeList.filter(el => el.ID == '3');
                    }

                }
            }
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return result;
        }
    }

    private showSecondaryLeg(parentIndex: number): boolean {
        let result: boolean = false;
        try {
            if (!parentIndex && parentIndex != 0) throw Error('parentIndex is null');
            if (this.$scope.model.DIRECTION &&
                this.$scope.model.DIRECTION.ID == EDirectionId.ORIGIN &&
                this.$scope.model.MAIN_LEG[parentIndex].ORIGIN_TYPE &&
                this.$scope.model.MAIN_LEG[parentIndex].ORIGIN_TYPE.ID == '5'
            ) {
                result = true;
            } else if (this.$scope.model.DIRECTION &&
                this.$scope.model.DIRECTION.ID == EDirectionId.DESTINATION &&
                this.$scope.model.MAIN_LEG[parentIndex].ORIGIN_TYPE &&
                this.$scope.model.MAIN_LEG[parentIndex].DESTINATION_TYPE &&
                (
                    (this.$scope.model.MAIN_LEG[parentIndex].ORIGIN_TYPE.ID == '2' && this.$scope.model.MAIN_LEG[parentIndex].DESTINATION_TYPE.ID == '5') ||
                    (this.$scope.model.MAIN_LEG[parentIndex].ORIGIN_TYPE.ID == '4' && this.$scope.model.MAIN_LEG[parentIndex].DESTINATION_TYPE.ID == '5')
                )
            ) {
                result = true;
            }
            if (!result)
                this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG = null;

        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            return result;
        }
    }

    private async updateProduct(selected: SelectorModel): Promise<void> {
        try {
            if (!selected) throw Error('selected is null');

            if (this.$scope.model.PRODUCT && this.$scope.model.PRODUCT.ID != selected.ID) {
                const modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: "GENERAL.CONFIRM",
                    headerText: "GENERAL.CONFIRM_ACTION",
                    closeButtonText: "GENERAL.CLOSE",
                    bodyText: this.formService.getTranslate('GENERAL.MESSAGES.CHANGE_PRODUCT_CLEAR_ALL_ROUTES')
                });

                if (!modal) this.$scope.auxModel.product = this.$scope.model.PRODUCT;
                else {
                    this.$scope.model.ID_LOGISTIC_SOLUTION = null;
                    this.$scope.model.LOGISTIC_SOLUTION = null;
                    for (const firstLeg of this.$scope.model.MAIN_LEG) {
                        firstLeg.ORIGIN_TYPE = null;
                        firstLeg.ORIGIN = null;
                        firstLeg.DESTINATION_TYPE = null;
                        firstLeg.DESTINATION = null;
                        firstLeg.SECONDARY_LEG = null;
                    }
                    this.$timeout(() => { this.$scope.selectorValidity("logisticSolution"); })
                }
            }

            this.$scope.model.PRODUCT = this.$scope.auxModel.product;

            if (this.$scope.model.PRODUCT && this.$scope.model.MAIN_LEG && this.$scope.model.MAIN_LEG.length > 0) {
                for (const firstLeg of this.$scope.model.MAIN_LEG) {
                    if (firstLeg.CHARGE && firstLeg.CHARGE.length > 0) {
                        for (const charge of firstLeg.CHARGE) {
                            if (this.$scope.isAirProduct) {
                                charge.EQUIPMENT = null;
                            } else {
                                charge.WEIGHT_RANGE = null;
                            }
                        }
                    }

                    if (firstLeg.SECONDARY_LEG && firstLeg.SECONDARY_LEG.length > 0) {
                        for (const secondLeg of firstLeg.SECONDARY_LEG) {
                            if (secondLeg.CHARGE && secondLeg.CHARGE.length > 0) {
                                for (const charge of secondLeg.CHARGE) {
                                    if (this.$scope.isAirProduct) {
                                        charge.EQUIPMENT = null;
                                    } else {
                                        charge.WEIGHT_RANGE = null;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            this.$timeout(() => {
                this.updateControl();
                this.generateConcatenated();
                this.generateAllConcatenatedLegs();
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async updateDirection(selected: SelectorModel): Promise<void> {
        try {
            if (!selected) throw Error('selected is null');

            if (this.$scope.model.DIRECTION && this.$scope.model.DIRECTION.ID != selected.ID && this.$scope.model.MAIN_LEG && this.$scope.model.MAIN_LEG.length > 0) {
                const modal = await this.ModalService.showModalConfirmation({}, {
                    actionButtonText: "GENERAL.CONFIRM",
                    headerText: "GENERAL.CONFIRM_ACTION",
                    closeButtonText: "GENERAL.CLOSE",
                    bodyText: this.formService.getTranslate("PRODUCT.MESSAGE.CHANGE_DIRECTION"),
                });

                if (!modal) {
                    this.$scope.auxModel.direction = this.$scope.model.DIRECTION;
                    return;
                } else {
                    this.$scope.model.ID_LOGISTIC_SOLUTION = null;
                    this.$scope.model.LOGISTIC_SOLUTION = null;
                    this.$timeout(() => { this.$scope.selectorValidity("logisticSolution"); })
                }
            }

            this.$scope.model.DIRECTION = this.$scope.auxModel.direction;

            for (const firstLeg of this.$scope.model.MAIN_LEG) {
                firstLeg.ORIGIN_TYPE = null;
                firstLeg.ORIGIN = null;
                firstLeg.DESTINATION_TYPE = null;
                firstLeg.DESTINATION = null;
                firstLeg.SECONDARY_LEG = null;
                firstLeg.VALIDITY_EVENT = null;
            }
            this.$timeout(() => {
                this.generateConcatenated();
                this.generateAllConcatenatedLegs();
            });
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private freightBasisMainLegChange(parentIndex: number): void {
        if (!parentIndex && parentIndex != 0) throw Error('parentIndex is null');
        const mainLeg = this.$scope.model.MAIN_LEG[parentIndex];

        mainLeg.FREIGHT_BASIS ? mainLeg.VALIDITY_EVENT = null : this.checkMainLegValidityEvent(parentIndex);
        this.$timeout(() => {
            this.$scope.selectorValidity("event");
        });
    }

    private freightBasisSecondaryLegChange(parentIndex: number, childIndex: number): void {
        if (!parentIndex && parentIndex != 0) throw Error('parentIndex is null');
        if (!childIndex && childIndex != 0) throw Error('childIndex is null');
        const secondaryLeg = this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex];

        secondaryLeg.FREIGHT_BASIS ? secondaryLeg.VALIDITY_EVENT = null : this.checkSecondaryLegValidityEvent(parentIndex, childIndex);
        this.$timeout(() => {
            this.$scope.selectorValidity("event");
        });
    }

    private checkMainLegValidityEvent(parentIndex: number): void {
        try {
            if (!parentIndex && parentIndex != 0) throw Error('parentIndex is null');
            const mainLeg = this.$scope.model.MAIN_LEG[parentIndex];
            if (mainLeg && mainLeg.ORIGIN_TYPE && mainLeg.ORIGIN_TYPE.ID && this.$scope.model.DIRECTION && this.$scope.model.DIRECTION.ID == EDirectionId.ORIGIN) {
                if (mainLeg.ORIGIN_TYPE.ID == ETypeLocalId.DOOR) mainLeg.VALIDITY_EVENT = this.$scope.eventList.find(event => event.ID == EEventType.PICK_UP);
                if (mainLeg.ORIGIN_TYPE.ID == ETypeLocalId.TERMINAL) mainLeg.VALIDITY_EVENT = this.$scope.eventList.find(event => event.ID == EEventType.PLACE_OF_RECEIPT);
            }
            if (mainLeg && mainLeg.DESTINATION_TYPE && mainLeg.DESTINATION_TYPE.ID && this.$scope.model.DIRECTION && this.$scope.model.DIRECTION.ID == EDirectionId.DESTINATION) {
                if (mainLeg.DESTINATION_TYPE.ID == ETypeLocalId.DOOR) mainLeg.VALIDITY_EVENT = this.$scope.eventList.find(event => event.ID == EEventType.DELIVERY);
                if (mainLeg.DESTINATION_TYPE.ID == ETypeLocalId.TERMINAL) mainLeg.VALIDITY_EVENT = this.$scope.eventList.find(event => event.ID == EEventType.FINAL_DESTINATION);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private checkSecondaryLegValidityEvent(parentIndex: number, childIndex: number): void {
        try {
            if (!parentIndex && parentIndex != 0) throw Error('parentIndex is null');
            if (!childIndex && childIndex != 0) throw Error('childIndex is null');
            const secondaryLeg = this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex];
            if (secondaryLeg && secondaryLeg.ORIGIN_TYPE && secondaryLeg.ORIGIN_TYPE.ID && this.$scope.model.DIRECTION && this.$scope.model.DIRECTION.ID == EDirectionId.ORIGIN) {
                if (secondaryLeg.ORIGIN_TYPE.ID == ETypeLocalId.DOOR) secondaryLeg.VALIDITY_EVENT = this.$scope.eventList.find(event => event.ID == EEventType.PICK_UP);
                if (secondaryLeg.ORIGIN_TYPE.ID == ETypeLocalId.TERMINAL) secondaryLeg.VALIDITY_EVENT = this.$scope.eventList.find(event => event.ID == EEventType.PLACE_OF_RECEIPT);
            }
            if (secondaryLeg && secondaryLeg.DESTINATION_TYPE && secondaryLeg.DESTINATION_TYPE.ID && this.$scope.model.DIRECTION && this.$scope.model.DIRECTION.ID == EDirectionId.DESTINATION) {
                if (secondaryLeg.DESTINATION_TYPE.ID == ETypeLocalId.DOOR) secondaryLeg.VALIDITY_EVENT = this.$scope.eventList.find(event => event.ID == EEventType.DELIVERY);
                if (secondaryLeg.DESTINATION_TYPE.ID == ETypeLocalId.TERMINAL) secondaryLeg.VALIDITY_EVENT = this.$scope.eventList.find(event => event.ID == EEventType.FINAL_DESTINATION);
            }
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private async callSessionFunctions(data: object): Promise<void> {
        if (!data) return;
        const tariffDomesticExchangeData = <ITariffDomesticExchangeData>data;
        let tariffDomesticModel: ITariffDomesticModel = null;
        for (const gridData of this.$scope.gridOptions.data) {
            if (gridData.ID == tariffDomesticExchangeData.ID) {
                tariffDomesticModel = gridData;
                break;
            }
        }
        switch (tariffDomesticExchangeData.OPERATION) {
            case EOperation.VIEW: {
                this.$scope.viewTariffDomestic(tariffDomesticModel);
                break;
            }
            case EOperation.EDIT: {
                this.editTariffDomestic(tariffDomesticModel);
                break;
            }
            case EOperation.NEW: {
                if (!tariffDomesticExchangeData.ID) this.$rootScope.breadCrumb.action();
                else await this.copyTariffFromHistory(parseInt(tariffDomesticExchangeData.ID));
                break;
            }
        }
    }

    private checkDateValidity(initialDate: Date, finalDate: Date): void {
        let isValid = false;
        if (!initialDate || typeof initialDate == "string") return;
        if (!finalDate || typeof finalDate == "string") return;
        isValid = ValidateUtil.isValidDateRange(initialDate, finalDate);
        if (!isValid) {
            this.$scope.model.DISPLAY_VALIDITY_END = null;
        }
    }

    private getCustomLogProperties(): ICustomLogProperties[] {
        const props: ICustomLogProperties[] = [
            {
                PROPERTY: "CONCATENATED",
                LABEL: "GENERAL.CONCATENATED"
            },
            {
                PROPERTY: "VERSION",
                LABEL: "GENERAL.VERSION"
            },
            {
                PROPERTY: "OPERATOR_TYPE",
                LABEL: "ENTITY.COMPANY_TYPE"
            },
            {
                PROPERTY: "PRODUCT",
                LABEL: "BASIC_DATA.PRODUCT"
            },
            {
                PROPERTY: "ACCOUNTS",
                LABEL: "BASIC_DATA.CLIENT"
            },
            {
                PROPERTY: "TYPE_CARGO",
                LABEL: "BASIC_DATA.CARGO_TYPE"
            },
            {
                PROPERTY: "DIRECTION",
                LABEL: "PRODUCT.WAY"
            },
            {
                PROPERTY: "PROVIDER",
                LABEL: "BASIC_DATA.SERVICE_PROVIDER"
            },
            {
                PROPERTY: "LOGISTIC_SOLUTION",
                LABEL: "BASIC_DATA.SERVICE"
            },
            {
                PROPERTY: "LEGAL_PERSON_AGENT",
                LABEL: "BASIC_DATA.SERVICE_PROVIDER"
            },
            {
                PROPERTY: "DTA",
                LABEL: "REGISTRATION.BONDED_TRANSPORT"
            },
            {
                PROPERTY: "ORIGIN",
                LABEL: "PRODUCT.VALIDITY_START"
            },
            {
                PROPERTY: "DESTINATION",
                LABEL: "PRODUCT.VALIDITY_END"
            },
            {
                PROPERTY: "MODAL",
                LABEL: "REGISTRATION.MODE_OF_TRANSPORT"
            },
            {
                PROPERTY: "CHARGE",
                LABEL: "GENERAL.CHARGES"
            },
            {
                PROPERTY: "OBSERVATION",
                LABEL: "GENERAL.REMARKS"
            },
            {
                PROPERTY: "CHARGE_NAME_EXHIBITION",
                LABEL: "BASIC_DATA.CHARGE"
            },
            {
                PROPERTY: "APPLICATION",
                LABEL: "FINANCIAL.CHARGE_BASIS"
            },
            {
                PROPERTY: "EQUIPMENT",
                LABEL: "BASIC_DATA.EQUIPMENT"
            },
            {
                PROPERTY: "WEIGHT_RANGE",
                LABEL: "REGISTRATION.WEIGHT_BREAK"
            },
            {
                PROPERTY: "CURRENCY",
                LABEL: "GENERAL.CURRENCY"
            },
            {
                PROPERTY: "PAYMENT_MIN",
                LABEL: "PRODUCT.MIN_PAYMENT"
            },
            {
                PROPERTY: "PAYMENT_UNITARY",
                LABEL: "PRODUCT.UNIT_PAYMENT"
            },
            {
                PROPERTY: "RECEIVING_MIN",
                LABEL: "PRODUCT.MIN_PAYMENT"
            },
            {
                PROPERTY: "RECEIVING_UNITARY",
                LABEL: "PRODUCT.UNIT_PAYMENT"
            },
            {
                PROPERTY: "VALIDITY_START",
                LABEL: "PRODUCT.VALIDITY_START"
            },
            {
                PROPERTY: "DISPLAY_VALIDITY_START",
                LABEL: "PRODUCT.VALIDITY_START"
            },
            {
                PROPERTY: "VALIDITY_END",
                LABEL: "PRODUCT.VALIDITY_END"
            },
            {
                PROPERTY: "DISPLAY_VALIDITY_END",
                LABEL: "PRODUCT.VALIDITY_END"
            },
            {
                PROPERTY: "SHORT_NAME",
                LABEL: "REGISTRATION.NICKNAME"
            },
            {
                PROPERTY: "SITUATION",
                LABEL: "GENERAL.SITUATION"
            },
            {
                PROPERTY: "NAME",
                LABEL: "GENERAL.NAME"
            },
            {
                PROPERTY: "CODE",
                LABEL: "GENERAL.CODE"
            },
            {
                PROPERTY: "MAIN_LEG",
                LABEL: "PRODUCT.MAIN_LEG"
            },
            {
                PROPERTY: "ORIGIN_TYPE",
                LABEL: "ROUTE.ORIGIN_TYPE"
            },
            {
                PROPERTY: "DESTINATION_TYPE",
                LABEL: "ROUTE.DESTINATION_TYPE"
            },
            {
                PROPERTY: "SECONDARY_LEG",
                LABEL: "PRODUCT.ADDITIONAL_LEG_DOMESTIC"
            },
            {
                PROPERTY: "FILES",
                LABEL: "REGISTRATION.EMAIL_ATTACHMENT"
            },
            {
                PROPERTY: "FILE_DISPLAY_NAME",
                LABEL: "REGISTRATION.FILE_ATTACHMENT_NAME"
            },
            {
                PROPERTY: "FILE_URL",
                LABEL: "REGISTRATION.FILE_URL"
            },
            {
                PROPERTY: "FILE_TYPE",
                LABEL: "REGISTRATION.FILE_TYPE"
            },
            {
                PROPERTY: "ACTIVE",
                LABEL: "GENERAL.ACTIVE"
            },
            {
                PROPERTY: "VALIDITY_EXPIRED",
                LABEL: "GENERAL.VALIDITY_EXPIRED"
            },
            {
                PROPERTY: "CONCATENATED_COMPLEMENT",
                LABEL: "GENERAL.CONCATENATED_COMPLEMENT"
            },
            {
                PROPERTY: "CONTRACTS",
                LABEL: "GENERAL.CONTRACTS"
            },
            {
                PROPERTY: "VEHICLE_TYPE",
                LABEL: "GENERAL.VEHICLE_TYPE"
            },
            {
                PROPERTY: "EQUIP_WEIGHT_RANGE_VEHICLE",
                LABEL: "PRODUCT.BASIS_COMPLEMENT"
            },
            {
                PROPERTY: "CREATED_AT",
                LABEL: "GENERAL.CREATED_AT"
            },
            {
                PROPERTY: "CREATED_BY",
                LABEL: "GENERAL.CREATED_BY"
            },
            {
                PROPERTY: "UPDATED_AT",
                LABEL: "GENERAL.UPDATED_AT"
            },
            {
                PROPERTY: "UPDATED_BY",
                LABEL: "GENERAL.UPDATED_BY"
            },
            {
                PROPERTY: "NO_CHARGE",
                LABEL: "PRODUCT.FREE_OF_CHARGE_SELL"
            },
            {
                PROPERTY: "PERCENT",
                LABEL: "REGISTRATION.PERCENTAGE"
            },
            {
                PROPERTY: "APPLICATION_COMPLEMENT",
                LABEL: "PRODUCT.BASIS_COMPLEMENT"
            },
            {
                PROPERTY: "INTERNAL_SEQUENCE",
                LABEL: "REGISTRATION.INTERNAL_CODE"
            },
            {
                PROPERTY: "FREE_TYPING_AMOUNT_CHARGE",
                LABEL: "REGISTRATION.FREE_FIELD_CHANGE_QUANTITY"
            },
            {
                PROPERTY: "CT_WITHOUT_DOC",
                LABEL: "REGISTRATION.CONTRIBUTION_WITHOUT_DOCS"
            },
            {
                PROPERTY: "NAME_INTL",
                LABEL: "GENERAL.NAME_INTL"
            }
        ];
        return props;
    }

    private getMenuFloatingDefault(): IFloatingMenu {
        return {
            btnActiveDisabled: false,
            options: [
                {
                    click: "collapseHeader",
                    args: ['collapseBasicData'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.BASIC_DATA",
                    iconClass: "fa fa-address-card",
                    iconBodyClass: "text-brown"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseMainLeg'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "ROUTE.ROUTES",
                    iconClass: "fa fa-map-o",
                    iconBodyClass: "text-green"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseUploads'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "REGISTRATION.EMAIL_ATTACHMENT",
                    iconClass: "fa fa-files-o",
                    iconBodyClass: "text-gray"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseObservation'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.REMARKS",
                    iconClass: "fa fa-arrows-alt",
                    iconBodyClass: "text-blue"
                },
                {
                    click: "collapseHeader",
                    args: ['collapseAll'],
                    tooltipPlacement: "auto bottom",
                    textTooltip: "GENERAL.COLLAPSE_EXPAND_ALL",
                    iconClass: "fa fa-expand",
                    iconBodyClass: "text-danger"
                }
            ]
        };
    }

    private collapseHeader(elementId: string, state?: string, navigate?: boolean): void {
        if (elementId === "collapseAll") {
            this.collapseState = this.collapseState == "hide" ? "show" : "hide";
            $('.toggle-me')["collapse"](state ? state : this.collapseState);
        } else if (elementId != "registerBody") {
            $("#" + elementId)["collapse"](state ? state : 'toggle');
        }
        if (navigate && state != "hide") this.$scope.navigateBetweenIds(elementId);
    }

    private collapseMainLeg(mainLegIndex: number): void {
        if (!mainLegIndex && mainLegIndex != 0) throw Error("mainLegIndex is null.");
        if (this.$scope.model.MAIN_LEG && this.$scope.model.MAIN_LEG.length > 0 && this.$scope.model.MAIN_LEG[mainLegIndex]) {
            if (this.$scope.model.MAIN_LEG[mainLegIndex].SHOW) {
                this.collapseHeader('collapseMainLeg' + mainLegIndex, 'hide');
                this.$timeout(() => { this.$scope.model.MAIN_LEG[mainLegIndex].SHOW = false; }, 500);
            }
            else {
                this.$scope.model.MAIN_LEG[mainLegIndex].SHOW = true;
                this.collapseHeader('collapseMainLeg' + mainLegIndex, 'show');
            }
        }
    }

    private initPanels = (panelsToShow?: string[], panelToNavigate?: string) => {
        try {
            const panels = document.getElementsByClassName("toggle-me");
            if (panels) {
                for (let i = 0; i < panels.length; i++) {
                    const panel = panels[i];
                    if (panelsToShow && panelsToShow.findIndex(obj => obj === panel.id) >= 0) {
                        if (!panel.classList.contains('in')) {
                            $("#" + panel.id)["collapse"]("show");
                        }
                    } else if (panel.classList.contains('in')) {
                        $("#" + panel.id)["collapse"]("hide");
                    }
                }
            }
            if (panelToNavigate) this.$scope.navigateBetweenIds(panelToNavigate);
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private setChargeColStyle(): void {
        const chargeColStyle: IChargeColStyle = { charge: null, application: null, flat: null, weightEquipment: null, currency: null, paymentUnitary: null, paymentMin: null, receivingUnitary: null, receivingMin: null, noCharge: null };
        const winWidth = angular.element(window).width();
        if (winWidth >= 1900) {
            chargeColStyle.charge = { "width": "21%" };
            chargeColStyle.application = { "width": "11%" };
            chargeColStyle.flat = { "width": "3%" };
            chargeColStyle.weightEquipment = { "width": "11%" };
            chargeColStyle.currency = { "width": "9%" };
            chargeColStyle.paymentUnitary = { "width": "9%" };
            chargeColStyle.paymentMin = { "width": "9%" };
            chargeColStyle.receivingUnitary = { "width": "9%" };
            chargeColStyle.receivingMin = { "width": "9%" };
            chargeColStyle.noCharge = { "width": "9%" };
        } else if (winWidth >= 1600) {
            chargeColStyle.charge = { "width": "22%" };
            chargeColStyle.application = { "width": "10%" };
            chargeColStyle.flat = { "width": "4%" };
            chargeColStyle.weightEquipment = { "width": "10%" };
            chargeColStyle.currency = { "width": "9%" };
            chargeColStyle.paymentUnitary = { "width": "9%" };
            chargeColStyle.paymentMin = { "width": "9%" };
            chargeColStyle.receivingUnitary = { "width": "9%" };
            chargeColStyle.receivingMin = { "width": "9%" };
            chargeColStyle.noCharge = { "width": "9%" };
        } else if (winWidth >= 1260) {
            chargeColStyle.charge = { "width": "14%" };
            chargeColStyle.application = { "width": "11%" };
            chargeColStyle.flat = { "width": "3%" };
            chargeColStyle.weightEquipment = { "width": "11%" };
            chargeColStyle.currency = { "width": "11%" };
            chargeColStyle.paymentUnitary = { "width": "10%" };
            chargeColStyle.paymentMin = { "width": "10%" };
            chargeColStyle.receivingUnitary = { "width": "10%" };
            chargeColStyle.receivingMin = { "width": "10%" };
            chargeColStyle.noCharge = { "width": "10%" };
        }
        this.$scope.chargeColStyle = chargeColStyle;
    }

    private setSecondaryLegChargeColStyle(): void {
        const secondLegChargeColStyle: ISecondaryLegChargeColStyle = { charge: null, application: null, flat: null, weightEquipment: null, currency: null, paymentUnitary: null, paymentMin: null, receivingUnitary: null, receivingMin: null, secondaryOperator: null, noCharge: null };
        const winWidth = angular.element(window).width();
        if (winWidth >= 1900) {
            secondLegChargeColStyle.charge = { "width": "21%" };
            secondLegChargeColStyle.application = { "width": "11%" };
            secondLegChargeColStyle.flat = { "width": "3%" };
            secondLegChargeColStyle.weightEquipment = { "width": "11%" };
            secondLegChargeColStyle.currency = { "width": "9%" };
            secondLegChargeColStyle.paymentUnitary = { "width": "9%" };
            secondLegChargeColStyle.paymentMin = { "width": "9%" };
            secondLegChargeColStyle.receivingUnitary = { "width": "9%" };
            secondLegChargeColStyle.receivingMin = { "width": "9%" };
            secondLegChargeColStyle.noCharge = { "width": "9%" };
        } else if (winWidth >= 1600) {
            secondLegChargeColStyle.charge = { "width": "20%" };
            secondLegChargeColStyle.application = { "width": "11%" };
            secondLegChargeColStyle.flat = { "width": "4%" };
            secondLegChargeColStyle.weightEquipment = { "width": "11%" };
            secondLegChargeColStyle.currency = { "width": "9%" };
            secondLegChargeColStyle.paymentUnitary = { "width": "9%" };
            secondLegChargeColStyle.paymentMin = { "width": "9%" };
            secondLegChargeColStyle.receivingUnitary = { "width": "9%" };
            secondLegChargeColStyle.receivingMin = { "width": "9%" };
            secondLegChargeColStyle.noCharge = { "width": "9%" };
        } else if (winWidth >= 1280) {
            secondLegChargeColStyle.charge = { "width": "14%" };
            secondLegChargeColStyle.application = { "width": "11%" };
            secondLegChargeColStyle.flat = { "width": "3%" };
            secondLegChargeColStyle.weightEquipment = { "width": "11%" };
            secondLegChargeColStyle.currency = { "width": "11%" };
            secondLegChargeColStyle.paymentUnitary = { "width": "10%" };
            secondLegChargeColStyle.paymentMin = { "width": "10%" };
            secondLegChargeColStyle.receivingUnitary = { "width": "10%" };
            secondLegChargeColStyle.receivingMin = { "width": "10%" };
            secondLegChargeColStyle.noCharge = { "width": "10%" };
        }
        this.$scope.secondLegChargeColStyle = secondLegChargeColStyle;
    }

    private async openTariffUsedOffer(tariffDomestic: ITariffDomesticModel): Promise<void> {
        this.modalFormatedDataId = this.ModalService.newModal();
        this.ModalService.showModalInfo(
            {
                modalID: this.modalFormatedDataId,
                scope: this.$scope,
                formService: this.$scope.operation,
                size: 'lg modal-overflow',
                template: require("../view/modals/tariffDomesticUsedOfferModal.html"),
                keyboard: false
            },
            null
        );

        this.buildOfferProcessModalScope(tariffDomestic);
    }

    private async buildOfferProcessModalScope(tariffDomestic: ITariffDomesticModel): Promise<IUsedOfferModal> {
        try {
            this.formService.block();
            if (!tariffDomestic) throw Error('Missing tariffDomestic parameter in buildOfferProcessModalScope');

            const modalScope: IUsedOfferModal = await this.ModalService.getModalScope(this.modalFormatedDataId);
            modalScope.tariffDomestic = angular.copy(tariffDomestic);

            modalScope.usedOfferList = [];
            const requestList = await this.getUsedOfferList(tariffDomestic.ID);
            if (requestList) modalScope.usedOfferList = angular.copy(requestList);

            modalScope.goToOffer = (offerNumber: string): void => {
                if (!offerNumber || (offerNumber && offerNumber == '')) throw Error('offerNumber is null');
                this.$scope.sessionService.openTab("app.commercial.offer", <IOfferParameter>{ OFFER_CODE_EXHIBITION: offerNumber });
            }

            return modalScope;
        } catch (ex) {
            this.ModalService.closeModal(this.modalFormatedDataId);
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }

    private async getUsedOfferList(tariffID: number): Promise<IUsedOffer[]> {
        try {
            let result = [];

            const requestList = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/tariffDomestic/usedOffer/${tariffID}`, 30000, null, false,)
            if (requestList && requestList.data) result = requestList.data;

            return result
        } catch (ex) {
            this.formService.handleError(ex);
        }
    }

    private updateControl() {
        const product = this.$scope.model.PRODUCT;
        this.$scope.isAirProduct = product && (product.ID == EProductId.AIR_IMPORT || product.ID == EProductId.AIR_EXPORT);
        this.$scope.isMaritimeProduct = product && (product.ID == EProductId.MARITIME_IMPORT || product.ID == EProductId.MARITIME_EXPORT);
    }

    private noChargeChange(parentIndex: number, childIndex: number, index: number, value: boolean): void {
        if (!parentIndex && parentIndex != 0) throw Error('parentIndex is null');
        if (!index && index != 0) throw Error('index is null');
        if (!value && value != false) throw Error('value is null');
        if (childIndex !== null && this.$scope.model.MAIN_LEG && this.$scope.model.MAIN_LEG[parentIndex] && this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG && this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex] && this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE && this.$scope.model.MAIN_LEG[parentIndex].CHARGE && this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE[index]) {
            this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE[index].RECEIVING_UNITARY = null;
            this.$scope.model.MAIN_LEG[parentIndex].SECONDARY_LEG[childIndex].CHARGE[index].RECEIVING_MIN = null;
        } else if (this.$scope.model.MAIN_LEG && this.$scope.model.MAIN_LEG[parentIndex] && this.$scope.model.MAIN_LEG[parentIndex].CHARGE && this.$scope.model.MAIN_LEG[parentIndex].CHARGE[index]) {
            this.$scope.model.MAIN_LEG[parentIndex].CHARGE[index].RECEIVING_UNITARY = null;
            this.$scope.model.MAIN_LEG[parentIndex].CHARGE[index].RECEIVING_MIN = null;
        }

    }

    async fetchData(id: number, action: string): Promise<void> {
        try {
            if (!id) throw Error('Missing id parameter in fetchData');
            if (!action || action === '') throw Error('Missing action parameter in fetchData');

            const request = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/tariffDomestic/getById/${id}`, 30000, null, false);
            if (request && request.data) {
                const model = angular.copy(request.data);

                if (action === GridColumnBuilderConstants.BTN_EDIT) this.$scope.edit(model);
                else if (action === GridColumnBuilderConstants.BTN_COPY) this.$scope.copy(model);
                else this.$scope.view(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async copyTariffFromHistory(id: number) {
        try {
            const tariffDomestic = await this.getTariffDomesticHistoryById(id);
            if (tariffDomestic) {
                const model: ITariffDomesticModel = angular.copy(tariffDomestic);
                model.ID = null;
                model._id = null;
                this.setCopy(true);
                await this.$scope.copy(model);
            } else throw Error('No data found.');
        } catch (ex) {
            this.handleError(ex);
        }
    }

    async getTariffDomesticHistoryById(id: number) {
        if (!id) throw new Error("id is null.");
        this.formService.block();
        try {
            const tariffDomestic = await this.RestService.getObjectAsPromise(`${this.getUrlProduct()}/tariffDomesticHistory/getById/${id}`, 30000, null, false);
            return tariffDomestic ? tariffDomestic.data : null;
        } catch (ex) {
            this.formService.handleError(ex);
        } finally {
            this.formService.unblock();
        }
    }
}
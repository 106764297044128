export abstract class ObjectUtil {

    // nav throught object given a path string, e.g 'part3[0].name' 
    public static navObjectByString(object: any, path: string): any {
        try {
            if (!object || !path) return null;
            path = path.replace(/\[(\w+)\]/g, '.$1');
            path = path.replace(/^\./, '');
            const a = path.split('.');
            for (let i = 0, n = a.length; i < n; ++i) {
                const k = a[i];
                if (object === null) return;
                if (k in object) {
                    object = object[k];
                } else {
                    return;
                }
            }
            return object;
        } catch (ex) {
            throw ex;
        }
    }

}
